import { SavingMessage } from '@components/AutoSavingStatus/AutoSavingStatus'
import { MarketingListResponse } from '@graphql/types/query-types'
import { emptySMSListingStatusToast } from '@src/pages/sms/listing/SMSListingContainer'

import { State } from '../edit/EditSMSContainer'
import SegmentedMessage from '../edit/utils/segmentationCalculator'

const title = 'SMS Composer Beta'
const senderCode = '555-5555'
const availableCredits = 89234
const costToSend = 24
const smsNextMonthCredits = 1000
const description = 'An SMS Composer example'

const recipientList1: MarketingListResponse = {
  listId: '1',
  listName: 'list 1',
  listType: 'CRM',
  totalContacts: 27,
  countNotOptedIn: 20,
  countMissingPhone: 3,
  countDuplicate: 2,
  suppressedInternationalContacts: 0,
  headers: ['name', 'jobTitle', 'salutation'],
  sampleContacts: [
    { firstName: 'Person', lastName: '1', recId: '1', emailAddress: 'Manager@act-on.com' },
    { firstName: 'Person', lastName: '2', recId: '2', emailAddress: 'Chef@act-on.com' },
    { firstName: 'Person', lastName: '3', recId: '3', emailAddress: 'Cashier@act-on.com' },
  ],
}

const recipientList2: MarketingListResponse = {
  listId: '2',
  listName: 'list 2',
  listType: 'CRM',
  totalContacts: 127,
  countNotOptedIn: 90,
  countMissingPhone: 13,
  countDuplicate: 2,
  suppressedInternationalContacts: 0,
  headers: ['name', 'jobTitle'],
  sampleContacts: [
    { firstName: 'Person', lastName: '4', recId: '4', emailAddress: 'Manager@act-on.com' },
    { firstName: 'Person', lastName: '5', recId: '5', emailAddress: 'Chef@act-on.com' },
    { firstName: 'Person', lastName: '6', recId: '6', emailAddress: 'Cashier@act-on.com' },
  ],
}

const recipients = [recipientList1, recipientList2]
const recipientsPerCreditMultiplier = [
  {
    contacts: 22,
    contactsPerCountry: [
      {
        country: 'United States',
        contacts: 22,
      },
    ],
    creditMultiplier: 1,
  },
]

const messageText = 'A bunch of text that we will show.'
const segmentedMessage = new SegmentedMessage(messageText)
const suppressions = ['']

const errors = { sizeError: false, incompatibleCharacter: false }
const launchId = ''
const saved = false
const sendMessageStatus = emptySMSListingStatusToast
const messageCaretPosition = 0
const scheduledSendTime = -1
const isMarketerTimezone = true
const savingMessage = SavingMessage.NONE

const totalRecipients = 16

const internationalSendingEnabled = false
const sentInitialMessage = true

export const values: State = {
  smsMessage: {
    launchId,
    title,
    description,
    messageText,
    recipients,
    recipientsPerCreditMultiplier,
    recipientTimezones: [],
  },
  pageError: false,
  senderCode,
  availableCredits,
  smsNextMonthCredits,
  costToSend,
  suppressions,
  segmentedMessage,
  messageCaretPosition,
  errors,
  saved,
  sendMessageStatus,
  scheduledSendTime,
  isMarketerTimezone,
  savingMessage,
  totalRecipients,
  internationalSendingEnabled,
  sentInitialMessage,
}
