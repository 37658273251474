import { TFunction } from 'i18next'

import { SortDropDownOption } from '@components/SortDropDown/sortDropDown.types'
import { EMAIL_SYSTEM_DEFAULT_LAYOUT_ID } from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/constants/EmailLayoutsModal.constants'
import { ColumnSort } from '@tanstack/react-table'

export enum EmailLayoutsModalView {
  LIST = 'list',
  GRID = 'grid',
}

export enum EmailTemplateLayoutType {
  HEADER = 'header',
  FOOTER = 'footer',
}

export const initialEmailLayoutsModalSortState: ColumnSort = {
  id: 'title',
  desc: false,
}

export const emailLayoutsModalSortOptions: SortDropDownOption[] = [
  {
    label: 'Title',
    id: 'title',
    ascLabel: 'A-Z',
    descLabel: 'Z-A',
  },
]

export const isSystemDefaultLayout = (id?: number) => id === EMAIL_SYSTEM_DEFAULT_LAYOUT_ID

export const getSystemDefaultTooltipText = (t: TFunction, layoutsType: EmailTemplateLayoutType) =>
  t('Email.Layout.System.Default.Tooltip', { context: layoutsType })
