import { gql } from '@apollo/client'

export default gql`
  query getAllFragmentsAndAllTags($accountId: Long!, $messageId: String!) {
    getAllFragmentsAndAllTags(accountId: $accountId, messageId: $messageId) {
      fragmentNames
      tagNames
    }
  }
`
