import React, { FC, useEffect, useState } from 'react'
import { useController, useForm } from 'react-hook-form'

import { SelectV2GroupedOption } from '@components/SelectV2/SelectV2.props'
import { SvgNames, SvgType } from '@components/Svg'
import { SvgColor, SvgContainerType } from '@components/Svg/Svg'
import { MapBlockModalForm } from '@src/pages/EmailComposer/utils/Addons/customAddons.types'
import { IAddOnResponseImageExtended } from '@src/pages/EmailComposer/utils/BeeEditor.types'
import { useTranslation } from '@utils/const/globals'
import { logNewRelicError } from '@utils/new-relic.utils'

import { useMapBlockModalRequests } from './GraphQL/MapBlockModal.graphql'
import MapBlockModal, { MapBlockValues, mapRatioConverted, mapRatioOptions, mapZoomOptions } from './MapBlockModal'
import { parseAddress } from './MapBlockModal.utils'

export interface MapCustomFields {
  addressName: string
  address: string
  city: string
  country: string
  state: string
  street: string
  street2: string
  zip: string
  isCustomAddress: boolean
  mapRatio: number
  mapZoom: number
  link: string
}

interface MapBlockModalContainerProps {
  customFieldValues: MapCustomFields
  onClose: () => void
  onAction: (data: IAddOnResponseImageExtended) => void
}

export const MapBlockModalContainer: FC<MapBlockModalContainerProps> = ({ customFieldValues, onClose, onAction }) => {
  const { t } = useTranslation()

  const { getCustomAccountCompanyInfo, getUserAddressInfo } = useMapBlockModalRequests()

  const [addressOptions, setAddressOptions] = useState<SelectV2GroupedOption[]>()
  const [loading, setLoading] = useState<boolean>(false)

  const form = useForm<MapBlockModalForm>({
    mode: 'onChange',
    defaultValues: customFieldValues,
  })

  const { field: selectedAddress } = useController({ control: form.control, name: 'selectedAddress' })

  const getAddressOptions = async () => {
    setLoading(true)
    const { data: accountData, errors: accountError } = await getCustomAccountCompanyInfo()
    const { data: userData, errors: userError } = await getUserAddressInfo()

    if (accountData?.getCustomAccountCompanyInfo && userData?.loadOverviewPage) {
      const { defaultAccountAddress, address: otherAddresses } = accountData.getCustomAccountCompanyInfo
      const userAddressProps = userData.loadOverviewPage
      const defaultAddress =
        customFieldValues?.address && customFieldValues?.addressName
          ? {
              value: customFieldValues?.addressName,
              label: customFieldValues?.addressName,
              subText: customFieldValues?.address,
              extraOptions: {
                street: customFieldValues?.street,
                street2: customFieldValues?.street2,
                city: customFieldValues?.city,
                state: customFieldValues?.state,
                zip: customFieldValues?.zip,
                country: customFieldValues?.country,
              },
            }
          : undefined

      const companyAddress =
        defaultAccountAddress?.street || defaultAccountAddress?.street2
          ? parseAddress(
              { name: SvgNames.buildings, type: SvgType.SELECTV2_OPTION_ICON, containerType: SvgContainerType.LARGE },
              defaultAccountAddress
            )
          : undefined

      const userAddress =
        userAddressProps.street || userAddressProps.street2
          ? parseAddress(
              { name: SvgNames.userCircle, type: SvgType.SELECTV2_OPTION_ICON, containerType: SvgContainerType.LARGE },
              { name: t('EmailComposer.EventModal.UserAddress'), ...userAddressProps }
            )
          : undefined

      const otherAddressData =
        otherAddresses?.map((address) =>
          parseAddress(
            { name: SvgNames.location, fill: SvgColor.BUTTON_GRAY, type: SvgType.SELECTV2_OPTION_ICON, containerType: SvgContainerType.LARGE },
            address
          )
        ) ?? []

      const addressOptions: SelectV2GroupedOption[] = [
        {
          options: [...(companyAddress ? [{ ...companyAddress }] : []), ...(userAddress ? [{ ...userAddress }] : [])],
          label: t('EmailComposer.EventModal.AccountAddresses'),
        },
        {
          options: otherAddressData,
          label: t('EmailComposer.EventModal.OtherAddresses'),
        },
      ]

      form.setValue('selectedAddress', defaultAddress ?? addressOptions[0].options[0])
      setAddressOptions(addressOptions)
      setLoading(false)
    } else if (accountError || userError) {
      logNewRelicError(accountError ?? userError)
    }
  }

  const handleInsert = (mapBlock: MapBlockValues) => {
    const getMapImageUrl = () => {
      const baseUrl = 'https://maps.googleapis.com/maps/api/staticmap'
      const address = encodeURIComponent(selectedAddress.value?.subText ?? '')
      const zoom = mapZoomOptions.find((option) => option.value === mapBlock.mapZoom)?.value
      const size = mapRatioConverted.find((option) => option.label === mapBlock.mapRatio)?.value
      const scale = 2
      const apiKey = process.env.NX_GOOGLE_MAPS_API_KEY

      return `${baseUrl}?center=${address}&zoom=${zoom}&size=${size}&scale=${scale}&markers=${address}&key=${apiKey}`
    }

    onAction({
      type: 'image',
      value: {
        src: getMapImageUrl(),
        alt: 'Map',
        href: '',
        dynamicSrc: '',
        customFields: mapBlock,
      },
    })
  }

  useEffect(() => {
    getAddressOptions()
  }, [])

  return (
    <MapBlockModal
      loading={loading}
      form={form}
      addressOptions={addressOptions}
      mapBlock={{
        addressName: customFieldValues?.addressName,
        address: customFieldValues?.address,
        city: customFieldValues?.city,
        country: customFieldValues?.country,
        state: customFieldValues?.state,
        street: customFieldValues?.street,
        street2: customFieldValues?.street2,
        zip: customFieldValues?.zip,
        isCustomAddress: customFieldValues?.isCustomAddress,
        mapRatio: customFieldValues?.mapRatio ? `${customFieldValues?.mapRatio}` : mapRatioOptions[0].value,
        mapZoom: customFieldValues?.mapZoom ? `${customFieldValues?.mapZoom}` : mapZoomOptions[2].value,
        link: customFieldValues?.link ? customFieldValues?.link : 'google',
      }}
      onClose={onClose}
      onAction={handleInsert}
      isOpen
    />
  )
}
