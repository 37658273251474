import React from 'react'

import { TFunction } from 'i18next'

import { RenderCustomFiltersParams, FilterCounts, Update } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { ListingPageSession } from '@complex/ListingPage/Utils/ListingPage.utils'
import Checkbox from '@components/Checkbox'
import CheckboxGroup from '@components/CheckboxGroup/CheckboxGroup'
import { CollapsibleMenuItemWithHeaderData } from '@components/CollapsibleMenu/CollapsibleMenu'
import { EmptyListingProps } from '@components/EmptyListing/EmptyListing'
import Radio from '@components/Radio/Radio'
import RadioGroup from '@components/RadioGroup/RadioGroup'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { SvgNames } from '@components/Svg'
import { allFormsFilter, createdByMeFilter, favoriteFilter, FilterDefinition } from '@utils/filter'
import { FORM_INFO_LINK, getMoreFiltersSubTypes } from '@utils/forms'
import { getItem } from '@utils/sessionStorage'

import { FormsCustomTableActions } from './FormsListingPage.tables'

export const getCustomEmptyListingProps = (
  setFilter: (filterDefinition: FilterDefinition, customFilterSelected: boolean) => void,
  update: Update,
  goToCatalog: () => void,
  filter?: FilterDefinition
): EmptyListingProps => {
  if (filter === favoriteFilter) {
    return {
      imgSrc: StaticImageNames.emptyFavorites,
      headline: "You don't have any favorites yet",
      text: 'Add hearts to forms that you want to easily find later.',
      buttonText: 'View all forms',
      buttonOnClick: () => setFilter(allFormsFilter, false),
    }
  } else if (filter === createdByMeFilter) {
    return {
      imgSrc: StaticImageNames.emptyCreatedByMe,
      headline: 'You haven’t created any forms yet',
      text: 'You’ll find forms here once you’ve created them.',
      buttonText: 'Create form',
      buttonOnClick: () => update({ showCustomModal: true, customTableAction: FormsCustomTableActions.CREATE_FORM }),
    }
  } else {
    return {
      imgSrc: StaticImageNames.emptyChart,
      headline: "Let's start building!",
      text: 'ListPage.Forms.EmptyState.AllFilter.Text',
      buttonText: 'Check out our template catalog',
      buttonOnClick: () => goToCatalog(),
      linkText: 'Learn more about forms',
      link: FORM_INFO_LINK,
      hideIcon: false,
    }
  }
}

const getMoreFilters = (connectedCrm: string, filterCounts: FilterCounts) => {
  const allSubTypes = getMoreFiltersSubTypes(connectedCrm)

  const filteredSubTypes = allSubTypes
    .filter((subType) => {
      return (filterCounts[subType.name] ?? 0) > 0
    })
    .sort((a, b) => (a.name > b.name ? 1 : -1))

  if (filteredSubTypes.length === 1 && filteredSubTypes[0].name === 'unaffiliated') {
    return []
  } else {
    return filteredSubTypes
  }
}

export const renderCustomFilters = (
  { activeSubTypes, menuActions, filterCounts, renderCustomFilterWithCount }: RenderCustomFiltersParams,
  useOnlyNewForms: boolean,
  connectedCrm: string,
  t: TFunction
): CollapsibleMenuItemWithHeaderData[] => {
  const moreFilters = getMoreFilters(connectedCrm, filterCounts)
  return [
    ...(useOnlyNewForms
      ? []
      : [
          {
            className: 'filter-container',
            header: t('Form Type'),
            isOpen: getItem(ListingPageSession.CUSTOM_MENU_SECTION_CLOSED) !== 'true',
            icon: SvgNames.toggles,
            dataTest: 'menu-items',
            content: (
              <>
                <RadioGroup className={`listing-page__custom-filters`} verticalLayout>
                  {renderCustomFilterWithCount(
                    <Radio
                      label={t(`New Forms`)}
                      checked={activeSubTypes.includes(`new`)}
                      onClick={() =>
                        activeSubTypes?.includes('new') ? menuActions.clickSubType('', ['new']) : menuActions.clickSubType('new', ['classic'])
                      }
                      dataTest={`list-page-container-section-filter`}
                    />,
                    filterCounts['new']
                  )}
                  {renderCustomFilterWithCount(
                    <Radio
                      label={t(`Classic Forms`)}
                      checked={activeSubTypes.includes(`classic`)}
                      onClick={() =>
                        activeSubTypes?.includes('classic') ? menuActions.clickSubType('', ['classic']) : menuActions.clickSubType('classic', ['new'])
                      }
                      dataTest={`list-page-container-section-filter`}
                    />,
                    filterCounts['classic']
                  )}
                </RadioGroup>
              </>
            ),
          },
        ]),
    ...(!moreFilters.length
      ? []
      : [
          {
            className: 'filter-container',
            header: t('More Filters'),
            isOpen: getItem(ListingPageSession.CUSTOM_MENU_SECTION_CLOSED) !== 'true',
            icon: SvgNames.toggles,
            dataTest: 'menu-items',
            content: (
              <CheckboxGroup className={`listing-page__custom-filters`} verticalLayout>
                {moreFilters.map((filter) =>
                  renderCustomFilterWithCount(
                    <Checkbox
                      label={filter.label}
                      checked={activeSubTypes.includes(filter.name)}
                      onChange={() => menuActions.clickSubType(filter.name)}
                      dataTest={`list-page-container-section-filter`}
                      title={filter.tooltip}
                    />,
                    filterCounts[filter.name],
                    filter.name
                  )
                )}
              </CheckboxGroup>
            ),
          },
        ]),
  ]
}
