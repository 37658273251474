import { useCallback } from 'react'

import getUserNotifyOnShare from '@graphql/microservices/thoughtspot/getUserNotifyOnShare'
import updateNotifyOnShare from '@graphql/microservices/thoughtspot/updateNotifyOnShare'
import {
  GetUserNotifyOnShareQuery,
  GetUserNotifyOnShareQueryVariables,
  UpdateNotifyOnShareMutation,
  UpdateNotifyOnShareMutationVariables,
} from '@graphql/types/microservice/thoughtspot-types'
import { useAccountSettings } from '@utils/account/account.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'

export const useAnalyticsGraphQl = () => {
  const { userEmail } = useAccountSettings()
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.THOUGHTSPOT })

  const getUserNotification = useCallback(
    () =>
      client
        .query<GetUserNotifyOnShareQuery, GetUserNotifyOnShareQueryVariables>({
          query: getUserNotifyOnShare,
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
          variables: {
            username: userEmail,
          },
        })
        .then(({ data }) => data.getUserNotifyOnShare),
    [userEmail, client]
  )

  const saveUserNotification = useCallback(
    (notifyOnShare: boolean) =>
      client.mutate<UpdateNotifyOnShareMutation, UpdateNotifyOnShareMutationVariables>({
        mutation: updateNotifyOnShare,
        errorPolicy: 'all',
        variables: {
          username: userEmail,
          notifyOnShare,
        },
      }),
    [userEmail, client]
  )

  return {
    getUserNotification,
    saveUserNotification,
  }
}
