import React, { FC } from 'react'

import classNames from 'classnames'

import Svg, { SvgColor, SvgType } from '@components/Svg/Svg'
import SvgNames from '@components/Svg/SvgNames'

import './ButtonIcon.css'

export enum ButtonIconSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum ButtonIconType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  WHITE = 'white',
  GRAY = 'gray',
  TEAL_ON_HOVER = 'teal-on-hover',
  LIGHT_TEAL_ON_HOVER = 'light-teal-on-hover',
  LIGHTER_TEAL_ON_HOVER = 'lighter-teal-on-hover',
  DELETE = 'delete',
  REMOVE = 'remove',
}

export type ButtonIconSecondaryColor = SvgColor.TEXT_GRAY | SvgColor.TEXT_TEAL | SvgColor.WHITE

export interface ButtonIconProps {
  type?: ButtonIconType
  size?: ButtonIconSize
  icon: SvgNames
  iconSize?: SvgType
  secondaryColor?: ButtonIconSecondaryColor
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  onMouseDown?: (event: React.MouseEvent<HTMLButtonElement>) => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void
  disabled?: boolean
  register?: any
  className?: string
  dataTest?: string
  roundButton?: boolean
  hideBorder?: boolean
}

const rootClass = 'button-icon'

const ButtonIcon: FC<ButtonIconProps> = (props: ButtonIconProps) => {
  const {
    size = ButtonIconSize.MEDIUM,
    type = ButtonIconType.PRIMARY,
    icon,
    iconSize,
    secondaryColor = SvgColor.TEXT_GRAY,
    onClick,
    onMouseDown,
    onKeyDown,
    disabled,
    register,
    dataTest = rootClass,
    className = '',
    roundButton = false,
    hideBorder = false,
  } = props
  const isPrimary = type === ButtonIconType.PRIMARY

  const getFillColor = () => {
    if (disabled) {
      return SvgColor.BUTTON_GRAY
    } else {
      return isPrimary ? SvgColor.WHITE : secondaryColor
    }
  }

  return (
    <button
      ref={register}
      disabled={disabled}
      className={classNames(rootClass, className, `${rootClass}__size-${size}`, `${rootClass}__type-${type}`, {
        [`${rootClass}__round-button`]: roundButton,
        [`${rootClass}__hide-border`]: hideBorder,
      })}
      data-test={`${dataTest}-${type}-${size}`}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onKeyDown={onKeyDown}
    >
      <Svg
        name={icon}
        type={iconSize || (size === ButtonIconSize.LARGE ? SvgType.LARGER_ICON : SvgType.ICON)}
        fill={getFillColor()}
        dataTest={`${dataTest}-svg`}
      />
    </button>
  )
}

export default ButtonIcon
