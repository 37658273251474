import { ExtendedItemDto } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { undefinedReturnedFunction } from '@const/globals'
import { AddContactsFromCRMProps } from '@src/pages/ContactSegments/components/AddContactsFromCRM/AddContactsFromCRM'
import { ItemType } from '@utils/categorization'
import { Segment } from '@utils/contactSegments/contactSegments.utils'

export const crmSourcesMock: ExtendedItemDto[] = [
  {
    id: 'dummy-list-1',
    position: 37,
    name: 'Some test list',
    count: 2,
  },
  {
    id: 'dummy-list-2',
    position: 0,
    name: 'LIST NAME 2',
    count: 1269,
  },
  {
    id: 'dummy-list-3',
    position: 0,
    name: 'Salesforce source with a lot of records',
    count: 2155,
  },
  {
    id: 'dummy-list-4',
    position: 52,
    name: 'Sugar list',
    count: 23,
  },
  {
    id: 'dummy-list-5',
    position: 24,
    name: 'Campaign list',
    count: 123,
  },
]

export const crmSegmentsMock: Segment[] = [
  {
    createdTime: '10/1/2021, 1:34:25 PM',
    externalId: 'segment1',
    id: 1,
    isEditable: true,
    isFavorite: false,
    lastCountDate: '7/8/2022, 10:40:39 AM',
    name: 'Synced segment',
    parent: '',
    position: 0,
    recordsCount: 0,
    tags: [],
    type: ItemType.SEGMENT,
    updatedTime: '7/8/2022, 10:40:39 AM',
    itemType: ItemType.SEGMENT,
  },
  {
    createdTime: '10/1/2021, 1:34:25 PM',
    externalId: 'segment2',
    id: 2,
    isEditable: true,
    isFavorite: false,
    lastCountDate: '7/8/2022, 10:40:39 AM',
    name: 'Not synced segment',
    parent: '',
    position: 1,
    recordsCount: 0,
    tags: [],
    type: ItemType.SEGMENT,
    updatedTime: '7/8/2022, 10:40:39 AM',
    itemType: ItemType.SEGMENT,
  },
  {
    createdTime: '10/1/2021, 1:34:25 PM',
    externalId: 'segment3',
    id: 3,
    isEditable: true,
    isFavorite: false,
    lastCountDate: '7/8/2022, 10:40:39 AM',
    name: 'Over the contacts limit',
    parent: '',
    position: 2,
    recordsCount: 0,
    tags: [],
    type: ItemType.SEGMENT,
    updatedTime: '7/8/2022, 10:40:39 AM',
    itemType: ItemType.SEGMENT,
  },
  {
    createdTime: '10/1/2021, 1:34:25 PM',
    externalId: 'segment4',
    id: 4,
    isEditable: true,
    isFavorite: false,
    lastCountDate: '7/8/2022, 10:40:39 AM',
    name: 'Not connected',
    parent: '',
    position: 3,
    recordsCount: 0,
    tags: [],
    type: ItemType.SEGMENT,
    updatedTime: '7/8/2022, 10:40:39 AM',
    itemType: ItemType.SEGMENT,
  },
]

export const addContactsFromCRMPropsMock: AddContactsFromCRMProps = {
  onDiscard: undefinedReturnedFunction,
  onSave: undefinedReturnedFunction,
  onSyncNow: undefinedReturnedFunction,
}
