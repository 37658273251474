import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router'

import classNames from 'classnames'
import _ from 'lodash'

import ListPickerModal from '@complex/ListPickerModalV2/ListPickerModal'
import { SelectedListsType } from '@complex/ListPickerModalV2/utils/interfaces/ListPickerModalInterfaces'
import { processPersonalizationFields } from '@complex/Personalization/utils/Personalization.utils'
import Container from '@components/Container/Container'
import InfoTooltip from '@components/InfoTooltip/InfoTooltip'
import { LinkTextButton, LinkTextButtonColor } from '@components/LinkTextButton/LinkTextButton'
import SelectAssetV2 from '@components/SelectAssetV2/SelectAssetV2'
import { Status } from '@components/StatusToast/StatusToast'
import { SvgNames } from '@components/Svg/index'
import { TableV2 } from '@components/TableV2/TableV2'
import { TOAST_TEXT_CLASSNAME } from '@components/Toast/Toast'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { List } from '@interface/foldersLists/List'
import QuickAddContactContainer from '@src/pages/EmailComposer/components/SendDetails/components/QuickAddContact/QuickAddContactContainer'
import {
  getAssetReportRecipientColumns,
  getCurrentRecipientsData as getCurrentData,
  getHierarchy,
  getModalRowItems,
  getNoRecipientsColumns,
  getRecipientsColumns,
  getRecipientsDefaultState,
  getRowActions,
  keyMappingToSrcId,
  RecipientModals,
  RecipientsState,
} from '@src/pages/EmailComposer/components/SendDetails/components/SendDetailsRecipients/SendDetailsRecipients.utils'
import SendDetailsSectionSplitHeader from '@src/pages/EmailComposer/components/SendDetails/components/SendDetailsSectionSplitHeader/SendDetailsSectionSplitHeader'
import SendDetailsSelectContactsModal from '@src/pages/EmailComposer/components/SendDetails/components/SendDetailsSelectContactsModal/SendDetailsSelectContactsModal'
import { MessageRecipient } from '@src/pages/EmailComposer/utils/EmailComposer.types'
import { detectEmailType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import {
  ComposerHistoryState,
  EmailComposerContext,
  SendToContact,
  SendtoList,
  ValidationSectionType,
} from '@utils/composer/context/EmailComposer.context'
import { useEmailComposerRequests } from '@utils/composer/emailComposer/GraphQL/EmailComposerRequests.graphQL'
import { logError } from '@utils/env'
import { FilterTypes } from '@utils/filter'
import useCRM from '@utils/hooks/useCRM'
import { ListCategory } from '@utils/lists'
import { removeItem } from '@utils/sessionStorage'

import './SendDetailsRecipients.css'

interface SendDetailsRecipientsProps {
  className?: string
  dataTest?: string
}

const rootClass = 'send-details-recipients'

const saveScrollSessionKey = `${rootClass}-scroll-top`

const RECEPIENTS_LIST_MAX_SIZE = 100
const SendDetailsRecipients: FC<SendDetailsRecipientsProps> = (props: SendDetailsRecipientsProps) => {
  const { dataTest = rootClass, className = '' } = props

  const {
    api: { update },
    values: {
      loading,
      message: { senderUUID, sendto_lists, sendto_contacts, individualRecipientsSourceIds, restricteLists, assetReportRecipientDetails },
      validations: { recipients: recipientsValidations, settingsValidations },
      messageConfiguration: {
        messageType: configMessageType,
        sendDetails: { disableSelectLists },
        reviewAndSend: { disableSelectContacts },
      },
    },
  } = useContext(EmailComposerContext)

  const { t } = useTranslation()
  const { isEmailCRM, isOptInEmail } = detectEmailType(configMessageType)

  const { hasShowActOnContactsTab } = useAccountSettings()
  const history = useHistory<ComposerHistoryState>()
  const showAssetReportRecipientDetails =
    !!assetReportRecipientDetails && !!assetReportRecipientDetails.count && assetReportRecipientDetails.count > 0
  const showValidationErrors = history.location.state?.fromValidationSection === ValidationSectionType.RECIPIENTS
  const recipientsInitialize = useRef(false)
  const selectedSrcIds = useRef<Record<string, string | undefined> | undefined>()
  const { connectorType } = useCRM()

  const { getListContactsRequest } = useEmailComposerRequests()

  const defaultCurrentList = useMemo(() => (hasShowActOnContactsTab ? ListCategory.UNIFIED_LIST : ListCategory.MARKETING), [hasShowActOnContactsTab])

  const restrictedToLegacyLists = useMemo(
    () =>
      getHierarchy(restricteLists)
        .filter((list) => !!list)
        .map((list) => list?.srcId) as string[],
    [restricteLists]
  )
  const [state, setState] = useState<RecipientsState>(getRecipientsDefaultState(sendto_lists, sendto_contacts))
  const [currentList, setCurrentList] = useState<ListCategory>(defaultCurrentList)
  const currentPageRef = useRef(0) // Ref to track current page
  const [allLoaded, setAllLoaded] = useState<boolean>(false)
  const [currentListId, setCurrentListId] = useState<string>('')
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [loadingNextData, setLoadingNextData] = useState<boolean>(false)
  useEffect(() => {
    if (!recipientsInitialize.current && !loading) {
      recipientsInitialize.current = true
      setState(getRecipientsDefaultState(sendto_lists, sendto_contacts))
    }
  }, [loading, sendto_lists, sendto_contacts])

  let recipientCount
  if (showAssetReportRecipientDetails) {
    recipientCount = assetReportRecipientDetails.count ?? 0
  } else if (state.recipients.length > 1) {
    recipientCount = state.recipients?.reduce((acc: number, curr: MessageRecipient) => acc + (curr.size !== -1 ? curr.size : 0), 0)
  } else if (state.recipients.length === 1) {
    recipientCount = state.recipients[0].size
  } else {
    recipientCount = 0
  }

  const [lists, setLists] = useState<List[]>([])

  const hasLists = !!(state.recipients.length && state.recipients.some((recipient) => recipient.isList))
  const hasIndividuals = !!(state.recipients.length && state.recipients.some((recipient) => !recipient.isList))

  const onUpdateLists = useCallback(
    (lists: List[]) => {
      const updatedSendToLists: SendtoList[] = lists.map((lst) => ({
        srcId: lst.id,
        baseId: lst.baseId,
        srcName: lst.name,
        size: lst.size ?? 0,
        isList: true,
      }))
      const updatedRecipients: MessageRecipient[] = lists.map((lst) => ({
        id: lst.id,
        baseId: lst.baseId,
        isList: true,
        name: lst.name,
        size: lst.size ?? 0,
      }))
      setState((state) => {
        const currentIndividualRecipients = state.recipients.filter((recip: MessageRecipient) => !recip.isList)
        const currentRecipients = [...updatedRecipients, ...currentIndividualRecipients]
        const currentData = getCurrentData(0, state.pageSize, currentRecipients ?? [])
        update({
          message: { sendto_lists: updatedSendToLists },
          modalState: { statusToast: { status: Status.SUCCESS, message: t('SendDetails.Recipients.Toast.Added') } },
        })

        return { ...state, currentData, recipients: currentRecipients, activeModal: RecipientModals.NONE }
      })
    },
    [update, t]
  )

  const getListContacts = async (listId: string, searchTerm?: string) => {
    const { data: specificListContactsData, errors } = await getListContactsRequest({
      listId,
      pageNumber: 0,
      pageSize: RECEPIENTS_LIST_MAX_SIZE,
      search: searchTerm,
    })

    if (errors) {
      logError(errors)
    }
    if (specificListContactsData) {
      const allData = processPersonalizationFields({ specificListContactsData })
      const formatedData = allData[FilterTypes.RECIPIENT_SPECIFIC_LIST_CONTACTS].map((contact) => ({ ...contact, isList: false }))
      setAllLoaded(formatedData.length < RECEPIENTS_LIST_MAX_SIZE)
      setState
      return formatedData
    }
  }
  const handleLoadNext = useCallback(async () => {
    if (!allLoaded) {
      try {
        setLoadingNextData(true)
        const { data } = await getListContactsRequest({
          listId: currentListId,
          pageNumber: currentPageRef.current + 1,
          pageSize: RECEPIENTS_LIST_MAX_SIZE,
          search: searchTerm,
        })

        if (data) {
          const allData = processPersonalizationFields({ specificListContactsData: data })
          const formatedData = allData[FilterTypes.RECIPIENT_SPECIFIC_LIST_CONTACTS].map((contact) => ({
            ...contact,
            isList: false,
          }))
          setState((prevState) => {
            const updatedContacts = [
              ...(prevState.selectListContacts || []),
              ...formatedData.map(
                (data) =>
                  ({
                    ...data,
                    name: data.title,
                    size: 1,
                  } as MessageRecipient)
              ),
            ]
            return {
              ...prevState,
              selectListContacts: updatedContacts,
            }
          })

          currentPageRef.current++

          setAllLoaded(formatedData.length < RECEPIENTS_LIST_MAX_SIZE)

          return formatedData
        }
      } finally {
        setLoadingNextData(false)
      }
    }

    return Promise.resolve()
  }, [allLoaded, currentListId, currentPageRef.current, searchTerm])

  useEffect(() => {
    return () => {
      removeItem(saveScrollSessionKey)
    }
  }, [])

  const onSelectListsForContacts = async (lists: List[], searchTerm?: string) => {
    const selectedList = lists[0]
    const selectListForContact: MessageRecipient = {
      id: selectedList?.id,
      isList: true,
      name: selectedList?.name,
      size: selectedList?.size ?? 0,
    }
    const listContacts = (await getListContacts(selectedList?.id, searchTerm)) ?? []
    setCurrentListId(selectedList?.id)
    const selectListContacts: MessageRecipient[] = listContacts.map((contact) => ({
      ...contact,
      name: contact.title,
      size: 1,
      isList: false,
      srcId: selectedList?.id,
    }))

    const selectedListContactsIdsMapping = keyMappingToSrcId('id', selectListContacts)
    const sendtoCidsToSrcId = keyMappingToSrcId('cid', sendto_contacts)

    selectedSrcIds.current = {
      ...selectedSrcIds.current,
      ...sendtoCidsToSrcId,
      ...selectedListContactsIdsMapping,
    }

    setState({
      ...state,
      selectListForContact,
      selectListContacts,
      activeModal: RecipientModals.ADD_CONTACTS_FROM_LIST,
    })
  }
  const hasSearchedRef = useRef(false)
  useEffect(() => {
    if (searchTerm !== '' && !hasSearchedRef.current) {
      hasSearchedRef.current = true
    }
    currentPageRef.current = 0
  }, [searchTerm, hasSearchedRef.current])

  useEffect(() => {
    currentPageRef.current = 0
  }, [state.activeModal])

  useEffect(() => {
    if (state.activeModal === RecipientModals.ADD_CONTACTS_FROM_LIST) {
      if (searchTerm === '' && hasSearchedRef.current) {
        currentPageRef.current = 0
        onSelectListsForContacts(lists, searchTerm)
      } else if (searchTerm !== '' && !hasSearchedRef.current) {
        onSelectListsForContacts(lists, searchTerm)
        hasSearchedRef.current = true
      } else if (searchTerm !== '') {
        onSelectListsForContacts(lists, searchTerm)
      }
    }
    removeItem(saveScrollSessionKey)
  }, [searchTerm, lists, state.activeModal])

  const updateSelectedContacts = useCallback(
    (selectedContacts: MessageRecipient[]) => {
      setState((state) => {
        const updatedRecipients = state.recipients.filter((recip: MessageRecipient) => !recip.isList)

        updatedRecipients.splice(0, updatedRecipients.length, ...selectedContacts)

        const updatedIndividualRecipientsSourceIds: (string | undefined)[] = individualRecipientsSourceIds || []

        const filteredIndividualRecipientsSourceIds = updatedIndividualRecipientsSourceIds.filter((id) =>
          updatedRecipients.some((recipient) => id?.split('::')[0] === recipient.id)
        )

        const updatedSelectedSendToContacts: SendToContact[] = updatedRecipients.map((contact) => {
          filteredIndividualRecipientsSourceIds.push(`${contact.id}::${selectedSrcIds.current?.[contact.id]}`)

          return { id: contact.id, name: contact.name, email: contact.email ?? '', srcId: selectedSrcIds.current?.[contact.id] }
        })

        const recipients = [...updatedRecipients, ...state.recipients.filter((recip: MessageRecipient) => recip.isList)]
        const currentData = getCurrentData(state.currentPage, state.pageSize, recipients)

        update({
          message: {
            sendto_contacts: updatedSelectedSendToContacts,
            individualRecipientsSourceIds: _.uniqBy(filteredIndividualRecipientsSourceIds, (id) => id?.split('::')[0]),
          },
          modalState: { statusToast: { status: Status.SUCCESS, message: t('SendDetails.Recipients.Toast.Added') } },
        })

        setCurrentList(defaultCurrentList)

        return {
          ...state,
          activeModal: RecipientModals.NONE,
          recipients,
          currentData,
          selectListForContact: undefined,
          chosenContacts: undefined,
        }
      })
    },
    [individualRecipientsSourceIds, update, t]
  )

  const handleContactsModalClose = useCallback(() => {
    setState((state) => ({ ...state, chosenContacts: undefined, activeModal: RecipientModals.NONE }))
    setCurrentList(defaultCurrentList)
    currentPageRef.current = 0
  }, [])
  const handleContactsModalBack = useCallback(() => setState((state) => ({ ...state, activeModal: RecipientModals.ADD_LIST_CONTACTS })), [])

  const handleAddContact = useCallback(
    (data?: { listId: string; size: number; count: number }) => {
      const handleFail = () => {
        update({
          modalState: { statusToast: { status: Status.FAIL, message: t('SendDetails.Recipients.AddContacts.Toast.Fail') } },
        })
      }
      if (!data || !sendto_lists) {
        handleFail()
      } else {
        const { listId, size, count } = data
        const listToAddContactsIndex = sendto_lists.findIndex(({ srcId }) => srcId === listId)
        if (listToAddContactsIndex === -1) {
          handleFail()
        } else {
          const updatedSendTo_lists = [...sendto_lists]
          updatedSendTo_lists.splice(listToAddContactsIndex, 1, { ...sendto_lists[listToAddContactsIndex], size })
          update({
            message: { sendto_lists: updatedSendTo_lists },
            modalState: {
              statusToast: {
                status: Status.SUCCESS,
                message: (
                  <Typography
                    text="SendDetails.Recipients.AddContacts.Toast.Success"
                    values={{ listName: sendto_lists[listToAddContactsIndex]?.srcName, count }}
                    tagProps={{ bold: { weight: TextWeight.BOLD, inline: true, className: undefined } }}
                    className={TOAST_TEXT_CLASSNAME}
                  />
                ),
              },
            },
          })
        }
      }
      setState((cur) => ({ ...cur, activeModal: RecipientModals.NONE }))
    },
    [update, t, sendto_lists]
  )

  const removeRow = useCallback(
    (srcId: string, isList: boolean) => {
      if (isList) {
        if (sendto_lists) {
          update({ message: { sendto_lists: [...sendto_lists].filter(({ srcId: id }) => id !== srcId) } })
        }
      } else {
        if (sendto_contacts) {
          const updatedIndividualRecipientsSourceIds: (string | undefined)[] =
            individualRecipientsSourceIds?.filter((id) => id?.split('::')[0] !== srcId) || []
          const newContacts = [...sendto_contacts].filter(({ id }) => id !== srcId)
          update({ message: { sendto_contacts: newContacts, individualRecipientsSourceIds: updatedIndividualRecipientsSourceIds } })
        }
      }

      setState((state) => {
        const { recipients, pageSize, currentPage } = state
        const newRecipients = [...recipients].filter(({ id }) => id !== srcId)
        const isPaginationEnabled = newRecipients.length > 10
        const currentData = isPaginationEnabled ? getCurrentData(currentPage, pageSize, newRecipients) : newRecipients

        return {
          ...state,
          recipients: newRecipients,
          currentData,
          resetPageIndex: !isPaginationEnabled,
        }
      })
    },
    [sendto_lists, sendto_contacts, individualRecipientsSourceIds, update]
  )

  const listPickerDisableSubmitByRowCriteria = useCallback((selectedLists: SelectedListsType) => {
    return Object.values(selectedLists).some((list) => list[0]?.original?.size === 0)
  }, [])

  const fetchData = useCallback((pageIndex: number, pageSize: number) => {
    setState((state) => {
      const currentData = getCurrentData(pageIndex, pageSize, state.recipients)
      return { ...state, currentPage: pageIndex, pageSize, currentData }
    })
  }, [])

  const recipientsTable = useMemo(
    () =>
      showAssetReportRecipientDetails ? (
        <TableV2
          hasDisabledRowStyles
          withoutBorders
          data={[assetReportRecipientDetails]}
          columns={getAssetReportRecipientColumns(assetReportRecipientDetails?.count ?? 0, rootClass, assetReportRecipientDetails, t)}
          styles={{ minWidth: 'auto' }}
          dataTest={`${rootClass}-recipient-table`}
        />
      ) : (
        <TableV2
          hasDisabledRowStyles
          data={state.currentData}
          columns={getRecipientsColumns(
            rootClass,
            senderUUID,
            showValidationErrors ? recipientsValidations : undefined,
            settingsValidations,
            isOptInEmail
          )}
          withoutBorders
          rowActions={getRowActions(connectorType, t, removeRow)}
          enableSorting
          enablePaginate={(state.recipients ?? []).length > 10}
          paginationState={{
            pageIndex: state.currentPage,
            pageSize: state.pageSize,
            controlledPageCount: Math.ceil((state.recipients ?? []).length / state.pageSize),
          }}
          resetPageIndex={state.resetPageIndex}
          fetchData={fetchData}
          styles={{ minWidth: 'auto' }}
          preserveScrollOnPagination={true}
          dataTest={`${rootClass}-recipient-table`}
        />
      ),
    [
      state,
      showValidationErrors,
      recipientsValidations,
      t,
      removeRow,
      fetchData,
      senderUUID,
      settingsValidations,
      connectorType,
      assetReportRecipientDetails,
    ]
  )

  const updateChoosedContacts = useCallback((updatedContacts: MessageRecipient[]) => {
    setState((state) => ({
      ...state,
      chosenContacts: updatedContacts,
    }))
  }, [])

  const noRecipientsTable = useMemo(
    () => <TableV2 data={[{ recipient: '', record: '-' }]} columns={getNoRecipientsColumns()} withoutBorders styles={{ minWidth: 'auto' }} />,
    []
  )

  const hasOnlyQueryBasedAndSearchSegmements = state.recipients.every((recipient) => recipient.id.includes('q-') || recipient.id.includes('s-'))

  const tooltipText = !hasLists ? 'SendDetails.Recipients.QuickAdd.hoverText' : 'SendDetails.Recipients.QuickAdd.hoverTextAlt'

  // Per AO-92320, temporarily hide the Quick Add link for all users
  const renderQuickAddLink =
    true || disableSelectLists ? undefined : (
      <div className={`${rootClass}__quick-link`}>
        {hasLists && !hasOnlyQueryBasedAndSearchSegmements ? (
          <LinkTextButton
            hideIconRight
            text={t('SendDetails.Recipients.QuickAdd.Text')}
            iconNameLeft={SvgNames.plus}
            onClick={() => setState({ ...state, activeModal: RecipientModals.ADD_CONTACTS })}
          />
        ) : (
          <Tooltip
            position={'right'}
            trigger={
              <div>
                <LinkTextButton
                  hideIconRight
                  text={t('SendDetails.Recipients.QuickAdd.Text')}
                  iconNameLeft={SvgNames.plus}
                  onClick={() => setState({ ...state, activeModal: RecipientModals.ADD_CONTACTS })}
                  disabled={true}
                  color={LinkTextButtonColor.GRAY}
                />
              </div>
            }
            className={`${rootClass}__disabled-quick-add`}
          >
            {t(tooltipText)}
          </Tooltip>
        )}
      </div>
    )

  const renderIndividualListPicker = useCallback(
    (type?: string) => {
      return (
        <>
          <ListPickerModal
            isOpen
            headerTitle={t('SendDetails.Recipients.AddContacts.Title')}
            multiSelect={false}
            closeModal={() => {
              setState((state) => ({ ...state, chosenContacts: undefined, activeModal: RecipientModals.NONE }))
              setCurrentList(hasShowActOnContactsTab ? ListCategory.UNIFIED_LIST : ListCategory.MARKETING)
            }}
            onBackArrowClick={() => {
              setState((state) => ({ ...state, chosenContacts: undefined, activeModal: RecipientModals.CHOOSE_TYPE }))
              setCurrentList(currentList)
            }}
            submitLists={(lists) => {
              setLists(lists)
              onSelectListsForContacts(lists)
            }}
            submitButtonText={t('Next')}
            submitDisabledOnSelect
            submitDisabledWithoutSelection
            disableSubmitByRowCriteria={listPickerDisableSubmitByRowCriteria}
            submitTooltipText={t('SendDetails.Recipients.List.No.Contacts')}
            keepListSelection
            setCurrentList={setCurrentList}
            initialList={currentList}
            restrictedToLegacyLists={restrictedToLegacyLists}
          />
          {type === RecipientModals.ADD_CONTACTS_FROM_LIST && (
            <SendDetailsSelectContactsModal
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              saveScrollSessionKey={saveScrollSessionKey}
              handleLoadNext={handleLoadNext}
              allLoaded={allLoaded}
              loadingNextData={loadingNextData}
              isOpen
              onAction={updateSelectedContacts}
              onClose={handleContactsModalClose}
              handleBack={handleContactsModalBack}
              listName={state.selectListForContact?.name ?? ''}
              listContacts={state.selectListContacts ?? []}
              selectedRecipients={state.recipients.filter((recip: MessageRecipient) => !recip.isList)}
              updateChoosedContacts={updateChoosedContacts}
              chosenContacts={state.chosenContacts}
            />
          )}
        </>
      )
    },
    [currentList, state.selectListForContact, state.recipients, state.chosenContacts, state.selectListContacts]
  )

  const renderModal = () => {
    switch (state.activeModal) {
      case RecipientModals.NONE:
        return undefined
      case RecipientModals.CHOOSE_TYPE:
        const disableLists: boolean = hasIndividuals || disableSelectLists || false
        return (
          <SelectAssetV2
            isOpen
            className={`${rootClass}__add-modal`}
            headerText={t('Personalization.addRecipientModal.header')}
            rowItems={getModalRowItems(t, disableLists, hasLists)}
            onAction={(selectedItem) => setState({ ...state, activeModal: selectedItem as RecipientModals })}
            onCancel={() => setState({ ...state, activeModal: RecipientModals.NONE })}
            initialSelectedAsset={hasLists || (!hasLists && !hasIndividuals) ? RecipientModals.LIST_PICKER : RecipientModals.ADD_LIST_CONTACTS}
            extraContent={renderQuickAddLink}
          />
        )
      case RecipientModals.LIST_PICKER:
        return (
          <ListPickerModal
            isOpen
            defaultSelectedLists={state.recipients?.filter((lst: MessageRecipient) => lst.isList).map((lst: MessageRecipient) => lst.id)}
            closeModal={() => setState({ ...state, activeModal: RecipientModals.NONE })}
            onBackArrowClick={isOptInEmail ? undefined : () => setState({ ...state, activeModal: RecipientModals.CHOOSE_TYPE })}
            submitLists={onUpdateLists}
            submitButtonText={t('SendDetails.Recipients.ListPicker.Submit')}
            submitDisabledOnSelect
            submitDisabledWithoutSelection
            restrictedToLegacyLists={restrictedToLegacyLists}
          />
        )
      case RecipientModals.ADD_LIST_CONTACTS:
        return renderIndividualListPicker()
      case RecipientModals.ADD_CONTACTS_FROM_LIST:
        return renderIndividualListPicker(RecipientModals.ADD_CONTACTS_FROM_LIST)
      case RecipientModals.ADD_CONTACTS:
        return (
          <QuickAddContactContainer
            handleCancel={() => setState({ ...state, activeModal: RecipientModals.NONE })}
            options={state.recipients.filter((recipient) => recipient.isList)}
            handleData={handleAddContact}
          />
        )
    }
  }

  const selectContactsHandler = useCallback(() => {
    const activeModal = isOptInEmail ? RecipientModals.LIST_PICKER : RecipientModals.CHOOSE_TYPE
    setState((prevState) => ({ ...prevState, activeModal }))
  }, [isOptInEmail])

  return (
    <Container
      className={classNames(
        rootClass,
        className,
        classNames({
          [`${rootClass}__with-bottom`]: !assetReportRecipientDetails && state.recipients.length === 0 && !showValidationErrors,
        })
      )}
      dataTest={dataTest}
    >
      {renderModal()}
      <SendDetailsSectionSplitHeader
        disableSelectContacts={disableSelectContacts}
        title={'SendDetails.Recipients.Title'}
        subText={
          isEmailCRM
            ? t('SendDetails.Recipients.Sub_CRM', { connectorType })
            : isOptInEmail
            ? 'SendDetails.Recipients.Sub_OptIn'
            : 'SendDetails.Recipients.Sub'
        }
        isOptInEmail={isOptInEmail}
        subtextCount={disableSelectLists ? 1 : 2}
        count={recipientCount}
        onClick={selectContactsHandler}
        showSelectContactsButton={!showAssetReportRecipientDetails}
        link={'https://connect.act-on.com/hc/en-us/articles/360023757134-Handling-Generic-and-Role-based-Email-Addresses'}
      />
      {showAssetReportRecipientDetails && (
        <div className={`${rootClass}__suppress-duplicate-banner`} data-test={`${rootClass}-duplicate-banner`}>
          <div className={`${rootClass}__suppress-duplicate-banner__content`}>
            <div className={`${rootClass}__suppress-duplicate-banner__tooltip`}>
              <InfoTooltip disableTooltip />
            </div>
            <Typography text={t('SendDetails.AssetReportRecipients.DuplicateBanner')} />
          </div>
        </div>
      )}
      {state.recipients.length > 0 || showAssetReportRecipientDetails ? recipientsTable : showValidationErrors ? noRecipientsTable : undefined}
    </Container>
  )
}

export default SendDetailsRecipients
