import React, { FC, ReactNode } from 'react'

import './messageBox.css'

export const rootClass = 'message-box'

interface MessageBoxProps {
  children: ReactNode
}

export const MessageBox: FC<MessageBoxProps> = (props: MessageBoxProps) => {
  return (
    <div data-test={rootClass} className={rootClass}>
      {props.children}
    </div>
  )
}

export default MessageBox
