import React, { FC, useEffect, useMemo, useRef, useState } from 'react'

import ListPickerWrapper from '@complex/ListPickerModalV2/ListPickerWrapper'
import Button, { ButtonType } from '@components/Button'
import Checkbox from '@components/Checkbox'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal/ConfirmationModal'
import Container from '@components/Container'
import FormRow from '@components/FormRow'
import InfoText from '@components/InfoText'
import InfoTooltip, { InfoTooltipIconSize } from '@components/InfoTooltip/InfoTooltip'
import Input from '@components/Input'
import Label from '@components/Label/Label'
import { renderLoader } from '@components/Loader/Loader'
import MoreOptions from '@components/MoreOptions/MoreOptions'
import Radio from '@components/Radio'
import SectionHeadline from '@components/SectionHeadline'
import Select from '@components/Select/Select'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import StatusToast, { Status } from '@components/StatusToast/StatusToast'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { MultipleTables } from '@components/TableV2/components/MultipleTables/MultipleTables'
import TableWithEmptyListing from '@components/TableWithEmptyListing/TableWithEmptyListing'
import TextArea from '@components/TextArea/TextArea'
import TimeInput from '@components/TimeInput/TimeInput'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { AccountSettings, Program, ProgramSchedule } from '@graphql/types/query-types'
import { List } from '@interface/foldersLists/List'
import { useProgramGeneralSettingsRequests } from '@src/pages/programs/edit/components/ProgramGeneralSettings/ProgramGeneralSettings.graphQL'
import {
  getMonthlyScheduleOptions,
  getScheduleIntervalOptions,
  getSourceListData,
  getWeeklyScheduleOptions,
  rowActions,
  sourceListsColumns,
  sourceListsColumnsV2,
} from '@src/pages/programs/edit/components/ProgramGeneralSettings/ProgramGeneralSettings.helpers'
import getProgramGeneralSettingsUtils, {
  ProgramGeneralSettingsUtils,
} from '@src/pages/programs/edit/components/ProgramGeneralSettings/programGeneralSettingsUtils'
import { getStandardFormattedDate } from '@utils/date'
import { getSharedHeaders, hasAOContactsSource, hasLegacyListSource } from '@utils/program/program'

import './programGeneralSettings.css'

const rootClass = 'program-general-settings'

export interface SourceListWithString {
  name: string
  records?: number
}

export interface SourceListWithElement {
  name: JSX.Element
  records: string
}

export interface State {
  confirmingDisableHistory: boolean
  loadingLists: boolean
  showListPicker: boolean
}

export interface ProgramGeneralSettingsProps {
  program: Program
  accountSettings?: AccountSettings
  hasUnsavedChanges?: boolean
  updateProgram(program: Program, hasAOContactsSource?: boolean): void
  dataTest?: string
  programGeneralSettingsUtils?: ProgramGeneralSettingsUtils
}

export enum ProgramScheduleType {
  TRIGGERED = 'TRIGGERED',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  NEVER = 'NEVER',
}

export const ProgramGeneralSettings: FC<ProgramGeneralSettingsProps> = (props: ProgramGeneralSettingsProps) => {
  const { program, accountSettings, dataTest = 'program-general-settings', hasUnsavedChanges = false } = props

  const { t } = useTranslation()

  const [state, setState] = useState<State>({
    showListPicker: false,
    confirmingDisableHistory: false,
    loadingLists: false,
  })
  const { showListPicker, loadingLists } = state

  const [isCheckingLists, setCheckingLists] = useState(false)
  const [disableImmediatelyOption, setDisableImmediatelyOption] = useState<boolean | undefined>()
  const [selected, setSelected] = useState(() => program.sourceList.map((list) => list.id))
  const [enableTableV2, setEnableTableV2] = useState(false)
  const [showScheduleChangeWarning, setShowScheduleChangeWarning] = useState(false)

  const tableData = useMemo(() => getSourceListData(program.sourceList, t), [program.sourceList, t])

  const { isSourceTriggerableRequest } = useProgramGeneralSettingsRequests()

  const initialSourceCheck = useRef(true)

  const checkIfSourceIsImmediatelyTriggerable = async (sourceIds: string[]) => {
    if (sourceIds.length === 1) {
      setCheckingLists(true)
      const isTriggerable = await isSourceTriggerableRequest(sourceIds[0])
      setDisableImmediatelyOption(!isTriggerable)
      setCheckingLists(false)
    } else {
      setDisableImmediatelyOption(sourceIds.length > 1)
    }
  }

  useEffect(() => {
    setSelected(program.sourceList.map((list) => list.id))
  }, [showListPicker, program.sourceList])

  useEffect(() => {
    if (initialSourceCheck.current || !hasUnsavedChanges) {
      checkIfSourceIsImmediatelyTriggerable(program.sourceList.map((list) => list.id))
    }
    initialSourceCheck.current = false
  }, [hasUnsavedChanges])

  const programGeneralSettingsUtils = props.programGeneralSettingsUtils ?? getProgramGeneralSettingsUtils(props, setState)

  useEffect(() => {
    if (disableImmediatelyOption && program.schedule?.type === ProgramScheduleType.TRIGGERED) {
      programGeneralSettingsUtils.updateProgramScheduleField<ProgramSchedule['type']>('type', ProgramScheduleType.NEVER)
      setShowScheduleChangeWarning(true)
    }
  }, [disableImmediatelyOption, program.sourceList])

  const isScheduled =
    program.schedule?.type === ProgramScheduleType.DAILY ||
    program.schedule?.type === ProgramScheduleType.MONTHLY ||
    program.schedule?.type === ProgramScheduleType.WEEKLY

  const startTime = programGeneralSettingsUtils.getTime(program.schedule?.minute, program.schedule?.hour, program.schedule?.ampm)
  const endTime = programGeneralSettingsUtils.getTime(program.schedule?.endMinute, program.schedule?.endHour, program.schedule?.endAmpm)

  const renderHelp = (tip: string) => (
    <InfoTooltip className={`${rootClass}__tip`} iconSize={InfoTooltipIconSize.SMALL}>
      {t(tip)}
    </InfoTooltip>
  )

  const isSelectSourcesDisabled = program.schedule?.type === ProgramScheduleType.TRIGGERED && program.sourceList.length >= 1

  // This button will be have a tooltip if it is disabled,
  // but MouseLeave doesn't trigger if the actual 'disabled' property is set
  const renderSelectSourcesButton = () => (
    <Button
      dataTest={`${dataTest}-add-button`}
      buttonType={ButtonType.INFO}
      onClick={isSelectSourcesDisabled ? undefined : programGeneralSettingsUtils.onAddList}
      className={isSelectSourcesDisabled ? `${rootClass}__sources-select-button--disabled` : undefined}
    >
      <Svg type={SvgType.LARGER_ICON} name={SvgNames.listCheck} />
      <span className={`${rootClass}__sources-select`}>{t('Select Sources')}</span>
    </Button>
  )

  const frequencyOptions: SelectV2SingleOption[] = [
    { value: ProgramScheduleType.DAILY, label: t('Daily') },
    { value: ProgramScheduleType.WEEKLY, label: t('Weekly') },
    { value: ProgramScheduleType.MONTHLY, label: t('Monthly') },
  ]

  const intervalOptions: SelectV2SingleOption[] =
    program.schedule?.type === ProgramScheduleType.DAILY
      ? getScheduleIntervalOptions(t)
      : program.schedule?.type === ProgramScheduleType.WEEKLY
      ? getWeeklyScheduleOptions(t)
      : program.schedule?.type === ProgramScheduleType.MONTHLY
      ? getMonthlyScheduleOptions(t)
      : []

  const frequencySelectedOption = frequencyOptions.find(({ value }) => value === program.schedule?.type)
  const intervalSelectedOption = intervalOptions.find(({ value }) => value === `${program.schedule?.interval}`)

  const onSourceListDelete = (index: number) => {
    const newSourceList = programGeneralSettingsUtils.deleteSourceList(index)
    checkIfSourceIsImmediatelyTriggerable(newSourceList.map(({ id }) => id))
  }

  const onListPickerSubmit = async (lists: List[]) => {
    setState((state) => ({ ...state, loadingLists: true }))
    await checkIfSourceIsImmediatelyTriggerable(lists.map(({ id }) => id))
  }

  return (
    <>
      {(isCheckingLists || loadingLists) && renderLoader('loader--white-background')}
      {showScheduleChangeWarning && (
        <StatusToast
          message={t('Schedule immediately is not valid for this source. Setting schedule to manual.')}
          closeStatus={() => setShowScheduleChangeWarning(false)}
          status={Status.WARNING}
        />
      )}
      <ConfirmationModal
        isYesNo={true}
        isOpen={state.confirmingDisableHistory}
        body={t('Disable program history will remove current step history. Are you sure you want to continue?')}
        onAnswer={(answer) => {
          setState({
            ...state,
            confirmingDisableHistory: false,
          })
          if (answer === YesNo.YES) {
            programGeneralSettingsUtils.updateProgramField<Program['historyDisabled']>('historyDisabled', true)
          }
        }}
      />
      <div className={rootClass} data-test={dataTest}>
        <div className={`${rootClass}__col-left`}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div onClick={() => setEnableTableV2?.((prev) => !prev)}>{' '}</div>
          <Container className={`${rootClass}__sources`} title={'Contact Sources'} withoutDescription>
            <div className={`${rootClass}__sources__header`}>
              <div className={`${rootClass}__sources-add`}>
                {isSelectSourcesDisabled ? (
                  <Tooltip trigger={renderSelectSourcesButton()} position={'right'}>
                    {t(`Please remove the currently selected source before selecting a new one.`)}
                  </Tooltip>
                ) : (
                  renderSelectSourcesButton()
                )}
              </div>
            </div>
            <MultipleTables
              enableTableV2={enableTableV2}
              tableV2Props={{
                loading: false,
                data: tableData,
                columns: sourceListsColumnsV2(program.sourceList),
                rowActions: program.sourceList.length > 0 ? rowActions(onSourceListDelete) : undefined,
                withoutBorders: true,
                styles: { minWidth: 'auto', margin: '0 0 -2rem' },
                emptyState: {
                  text: t("This program doesn't have a source list."),
                  withoutBorder: true,
                },
              }}
              oldTable={
                <TableWithEmptyListing
                  data={tableData}
                  columns={sourceListsColumns()}
                  text={t("This program doesn't have a source list.")}
                  loading={false}
                  rowActions={program.sourceList.length > 0 ? rowActions(onSourceListDelete) : undefined}
                  className={`${rootClass}__table`}
                />
              }
            />
            {showListPicker && (
              <ListPickerWrapper
                programSources={program.sources}
                dataTest={`${dataTest}-list-picker-wrapper`}
                isOpen={showListPicker}
                onClose={programGeneralSettingsUtils.onClose}
                onProgramSourcesUpdate={programGeneralSettingsUtils.onProgramSourcesUpdate}
                onSubmit={onListPickerSubmit}
                defaultSelectedLists={selected}
                multiSelect={program.schedule?.type !== ProgramScheduleType.TRIGGERED}
                disableLegacyLists={hasAOContactsSource(program)}
                disableUnifiedLists={hasLegacyListSource(program)}
                showLoader={false}
                restrictMixingLegacyListsAndUCL
                submitDisabledOnSelect
              />
            )}
          </Container>
          <Container title={t('Contacts from source(s) will enter the program:')} withoutDescription>
            <FormRow>
              <Radio
                label={t('Manually')}
                labelChildren={renderHelp(
                  'As new contacts are added to the program sources, they are not automatically entered into the program. You can enter contacts from the program sources manually into the program by clicking on the Now button on the dashboard.'
                )}
                dataTest={`${dataTest}-manual-radio`}
                checked={program.schedule?.type === ProgramScheduleType.NEVER}
                onChange={(event) => {
                  if (!event.target.checked) return
                  programGeneralSettingsUtils.updateProgramScheduleField<ProgramSchedule['type']>('type', ProgramScheduleType.NEVER)
                }}
              />
            </FormRow>
            <FormRow>
              <Radio
                disabled={disableImmediatelyOption || disableImmediatelyOption === undefined}
                label={t('Immediately')}
                labelChildren={renderHelp(
                  'New contacts added to the program source will be entered into the program immediately. This option requires the selection of a single source. If your source is a segment, it must use profile-based criteria only.'
                )}
                dataTest={`${dataTest}-immediately-radio`}
                checked={program.schedule?.type === ProgramScheduleType.TRIGGERED}
                onChange={(event) => {
                  if (!event.target.checked) return
                  programGeneralSettingsUtils.updateProgramScheduleField<ProgramSchedule['type']>('type', ProgramScheduleType.TRIGGERED)
                }}
              />
            </FormRow>
            <FormRow>
              <Radio
                label={t('On a Schedule')}
                dataTest={`${dataTest}-schedule-radio`}
                checked={isScheduled}
                onChange={(event) => {
                  if (!isScheduled && event.target.checked) {
                    programGeneralSettingsUtils.updateProgramScheduleField<ProgramSchedule['type']>('type', ProgramScheduleType.DAILY)
                  }
                }}
              />
              <MoreOptions>
                <div className={`${rootClass}__schedule-row-options`}>
                  <div className={`${rootClass}__schedule-col ${rootClass}__schedule-col--1`}>
                    <SelectV2
                      key={`${frequencySelectedOption?.value}-${frequencyOptions?.length}`}
                      dataTest={`${dataTest}-frequency-select`}
                      label={t('Frequency')}
                      options={frequencyOptions}
                      defaultValue={isScheduled ? frequencySelectedOption : undefined}
                      isDisabled={!isScheduled}
                      isClearable={false}
                      truncateOptions
                      hideCheckMark
                      onChange={(option) => {
                        programGeneralSettingsUtils.updateProgramScheduleField<ProgramSchedule['type']>('type', option?.value)
                      }}
                    />
                  </div>
                  <div className={`${rootClass}__schedule-col ${rootClass}__schedule-col--2`}>
                    <SelectV2
                      key={`${intervalSelectedOption?.value}-${intervalOptions?.length}`}
                      dataTest={`${dataTest}-interval-select`}
                      label={
                        program.schedule?.type === ProgramScheduleType.DAILY
                          ? t('Run')
                          : program.schedule?.type === ProgramScheduleType.MONTHLY
                          ? t('Day of month to run')
                          : t('Run On')
                      }
                      options={intervalOptions}
                      defaultValue={intervalSelectedOption}
                      isDisabled={!isScheduled}
                      isClearable={false}
                      truncateOptions
                      hideCheckMark
                      onChange={(option) => {
                        programGeneralSettingsUtils.updateProgramScheduleField<ProgramSchedule['interval']>(
                          'interval',
                          parseInt(option?.value ?? '0')
                        )
                      }}
                    />
                  </div>
                  <div className={`${rootClass}__schedule-col ${rootClass}__schedule-col--3`}>
                    <TimeInput
                      value={startTime}
                      onChange={(newValue) => {
                        programGeneralSettingsUtils.timeChange(newValue as string)
                      }}
                      label={t('Start Time')}
                      name="startTime"
                      disabled={!isScheduled}
                      required
                    />
                  </div>
                </div>
                <div className={`${rootClass}__schedule-row-checkbox`}>
                  {program.schedule?.type === ProgramScheduleType.DAILY && (
                    <>
                      <div className={`${rootClass}__schedule-col ${rootClass}__schedule-col--1`}>
                        <Checkbox
                          label={t('Weekdays Only')}
                          dataTest={`${dataTest}-weekdays-only-check`}
                          disabled={!isScheduled}
                          checked={program.schedule?.weekdaysOnly}
                          onChange={(checked) => {
                            programGeneralSettingsUtils.updateProgramScheduleField<ProgramSchedule['weekdaysOnly']>('weekdaysOnly', checked)
                          }}
                        />
                      </div>
                      {program.schedule?.interval !== 0 && (
                        <>
                          <div className={`${rootClass}__schedule-col ${rootClass}__schedule-col--not-after ${rootClass}__schedule-col--2`}>
                            <Checkbox
                              dataTest={`${dataTest}-not-after-check`}
                              label={t('Not After')}
                              disabled={!isScheduled}
                              checked={program.schedule?.hasEndTime}
                              onChange={(checked) => {
                                if (checked) {
                                  programGeneralSettingsUtils.timeChange(endTime, true)
                                } else {
                                  programGeneralSettingsUtils.updateProgramScheduleField<ProgramSchedule['hasEndTime']>('hasEndTime', false)
                                }
                              }}
                            />
                          </div>
                          <div className={`${rootClass}__schedule-col ${rootClass}__schedule-col--3`}>
                            <TimeInput
                              value={endTime}
                              onChange={(newValue: string | Date) => {
                                programGeneralSettingsUtils.timeChange(newValue as string, true)
                              }}
                              name="endTime"
                              disabled={!isScheduled || !program.schedule?.hasEndTime}
                              required
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </MoreOptions>
            </FormRow>
          </Container>
          <Container noBottom title={'Options'} withoutDescription>
            <div className={`${rootClass}__options`}>
              <div className={`${rootClass}__options__item`}>
                <Checkbox
                  dataTest={`${dataTest}-allow-looping`}
                  label={t('Allow contacts to repeat steps')}
                  checked={program.allowLooping}
                  onChange={(checked) => {
                    programGeneralSettingsUtils.updateProgramField<Program['allowLooping']>('allowLooping', checked)
                  }}
                />
                <InfoText>
                  {t(
                    `Contacts will be allowed to repeat previously executed steps as long as there is a Wait or Wait Until step before the repeated step is run.`
                  )}
                </InfoText>
              </div>
              <div className={`${rootClass}__options__item`}>
                <Checkbox
                  dataTest={`${dataTest}-history-disabled`}
                  label={t('Disable program history')}
                  checked={program.historyDisabled}
                  onChange={(event) => {
                    if (event) {
                      setState({
                        ...state,
                        confirmingDisableHistory: true,
                      })
                    } else {
                      programGeneralSettingsUtils.updateProgramField<Program['historyDisabled']>('historyDisabled', event)
                    }
                  }}
                />
                <InfoText>
                  {t(
                    'Contact progression through each step will not be recorded and the program report and dashboards will be disabled for this program.'
                  )}
                </InfoText>
              </div>
              {accountSettings?.reenterProgramContacts && (
                <div className={`${rootClass}__options__item`}>
                  <Checkbox
                    dataTest={`${dataTest}-use-alternate-field`}
                    label={t('Use alternate unique identifier')}
                    checked={program.useAlternateUniqueField}
                    onChange={(event) => {
                      programGeneralSettingsUtils.updateProgramField<Program['useAlternateUniqueField']>('useAlternateUniqueField', event)
                    }}
                  />
                  {program.useAlternateUniqueField && (
                    <Select
                      className={`${rootClass}__alternate-field`}
                      value={program.alternateUniqueFieldHeader ?? ''}
                      onChange={(event) => {
                        programGeneralSettingsUtils.updateProgramField<Program['alternateUniqueFieldHeader']>(
                          'alternateUniqueFieldHeader',
                          event.target.value
                        )
                      }}
                    >
                      <option value="">--- {t('Choose a field')} ---</option>
                      {getSharedHeaders(program.sourceList).map((header) => (
                        <option key={header} value={header}>
                          {header}
                        </option>
                      ))}
                    </Select>
                  )}
                  <InfoText>{t("Select a field name to use to identify unique contacts. (Default is 'Contact's email address').")}</InfoText>
                </div>
              )}
              <div className={`${rootClass}__options__item`}>
                <Checkbox
                  dataTest={`${dataTest}-suppress-bad-emails`}
                  label={t('Suppress entrants on suppression lists')}
                  checked={program.suppressBadEmails}
                  onChange={(checked) => {
                    programGeneralSettingsUtils.updateProgramField<Program['suppressBadEmails']>('suppressBadEmails', checked)
                  }}
                />
                <InfoText>
                  {t(
                    'Contact email addresses on the Opt-Out, Hard-Bounce, Spam Complaint or Email Suppression Domains List will not be entered into the program.'
                  )}
                </InfoText>
              </div>
              {accountSettings?.reenterProgramContacts && (
                <div className={`${rootClass}__options__item`}>
                  <Checkbox
                    dataTest={`${dataTest}-allow-reenter`}
                    label={t('Allow contacts to re-enter')}
                    checked={program.allowReentrantAddresses}
                    onChange={(event) => {
                      programGeneralSettingsUtils.updateProgramField<Program['allowReentrantAddresses']>('allowReentrantAddresses', event)
                    }}
                  />
                  <InfoText>
                    {t(`Contacts may re-enter the program once they have completed or exited. (Warning: Enabling this function will cause the program report
                to become inaccurate. Entrance and exit data will not be available for segmentation or on the Contact Report.)`)}
                  </InfoText>
                </div>
              )}
              {accountSettings?.hasEmailFatigueSuppressionRules && accountSettings?.userSettings.isAdministrator && (
                <div className={`${rootClass}__options__item`}>
                  <Checkbox
                    dataTest={`${dataTest}-bypass-fatigue`}
                    checked={program.allowBypassFatigueRules}
                    label={t('Ignore email fatigue rules')}
                    onChange={(checked) => {
                      programGeneralSettingsUtils.updateProgramField<Program['allowBypassFatigueRules']>('allowBypassFatigueRules', checked)
                    }}
                  />
                  <InfoText>{t(`Allow contacts to receive messages in excess of Email Fatigue rules.`)}</InfoText>
                </div>
              )}
              {program.crm?.crmEnabled && (
                <div className={`${rootClass}__options__item`}>
                  <Checkbox
                    dataTest={`${dataTest}-sforce-note-checkbox`}
                    checked={program.sforceNote}
                    multiline
                    label={t('Attach sent notes to contacts or leads from my CRM lists')}
                    onChange={(checked) => {
                      programGeneralSettingsUtils.updateProgramField<Program['sforceNote']>('sforceNote', checked)
                    }}
                  />
                  <InfoText>
                    {t(`Leave a note or task in your CRM indicating a message was sent to the contact.
                  Applies to all messages in the program regardless of the message setting.`)}
                  </InfoText>
                </div>
              )}
            </div>
          </Container>
        </div>
        <div className={`${rootClass}__col-right`}>
          <SectionHeadline>{t('Program Details')}</SectionHeadline>
          <div className={`${rootClass}__row`}>
            <Input
              dataTest={`${dataTest}-program-name`}
              label={t('Program Name')}
              placeholder={t('Type Name...')}
              defaultValue={program.name === '(Untitled)' ? '' : program.name}
              onBlur={(event) => {
                programGeneralSettingsUtils.updateProgramField<Program['name']>('name', event.target.value)
              }}
            />
          </div>
          <div className={`${rootClass}__row`}>
            <TextArea
              name={'program-description'}
              dataTest={`${dataTest}-program-description`}
              label={t('Program Description')}
              placeholder={t('Enter Description...')}
              defaultValue={program.description}
              resize={false}
              onBlur={(event) => {
                programGeneralSettingsUtils.updateProgramField<Program['description']>('description', event.target.value)
              }}
            />
          </div>
          <div className={`${rootClass}__row`}>
            <Label htmlFor={`${rootClass}__timezone-select`}>
              {t('Time zone')}
              <Tooltip trigger={<Svg name={SvgNames.info} type={SvgType.ICON} className={`${rootClass}__info-icon`} />} inline>
                {t('This time zone is used to interpret all schedule option times and all scheduled delay times in this program.')}
              </Tooltip>
            </Label>
            <Select
              id={`${rootClass}__timezone-select`}
              dataTest={`${dataTest}-timezone-select`}
              className={`${rootClass}__timezone-select`}
              value={program.timeZoneId}
              onChange={(event) => {
                programGeneralSettingsUtils.updateProgramField<Program['timeZoneId']>('timeZoneId', event.target.value)
              }}
            >
              {program.timeZoneNames.map((timezone) => (
                <option key={timezone.id} value={timezone.id}>
                  {timezone.name}
                </option>
              ))}
            </Select>
          </div>
          <div className={`${rootClass}__row`}>
            <Label>{t('Created')}</Label>
            <Typography text={`${getStandardFormattedDate(program.createdOn)} ${t('by')} ${program.creator}`} type={TextType.BODY_TEXT_LIGHT} />
          </div>
          <div className={`${rootClass}__row`}>
            <Label>
              {t('Started')}
              <Tooltip trigger={<Svg name={SvgNames.info} type={SvgType.ICON} className={`${rootClass}__info-icon`} />} inline>
                {t('Date the program status was most recently changed to Running')}
              </Tooltip>
            </Label>
            <Typography
              text={program.startedOn === 0 ? 'Not started' : getStandardFormattedDate(program.startedOn)}
              type={TextType.BODY_TEXT_LIGHT}
            />
          </div>
          <div className={`${rootClass}__row`}>
            <Label>{t('Last updated')}</Label>
            <Typography
              text={`${getStandardFormattedDate(program.lastUpdated)} ${program.lastEditedBy ? `${t('by')} ${program.lastEditedBy}` : ''}`}
              type={TextType.BODY_TEXT_LIGHT}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ProgramGeneralSettings
