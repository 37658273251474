import { gql } from '@apollo/client'

export default gql`
  query getEmailPerformanceResponse(
    $programId: String!
    $unitsAgo: Int!
    $groupByString: String!
    $forceRefresh: Boolean!
    $perPage: Int
    $currentPage: Int
    $sortColumn: String
    $isNumericForSorting: Boolean!
    $sortDirection: Direction
  ) {
    getEmailPerformanceResponse(
      programId: $programId
      unitsAgo: $unitsAgo
      groupByString: $groupByString
      forceRefresh: $forceRefresh
      perPage: $perPage
      currentPage: $currentPage
      sortColumn: $sortColumn
      isNumericForSorting: $isNumericForSorting
      sortDirection: $sortDirection
    ) {
      programMessageSends {
        dateToCount {
          date
          count
        }
      }
      programMessageCountsWithTotals {
        programMessageCountsList {
          messageId
          messageTitle
          stepId
          sent
          uiStepCode
          open
          click
          optOut
          softBounces
          hardBounces
          delivered
          clickToOpen
          uniqueSent
          uniqueOpen
          uniqueClick
          uniqueOptOut
          uniqueSoftBounces
          uniqueHardBounces
          uniqueDelivered
          uniqueClickToOpen
          percentageDelivered
          percentageOpened
          percentageClicked
          percentageClickToOpen
          percentageOptOut
        }
        totalRecords
      }
    }
  }
`
