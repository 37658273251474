import React, { FC, ReactNode, useState } from 'react'

import classNames from 'classnames'
import equal from 'fast-deep-equal/es6/react'

import Container from '@components/Container'
import { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import PageContainer from '@components/PageContainer'
import PageError from '@components/PageError'
import ProgressChart, { Average } from '@components/ProgressChart/ProgressChart'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { TableColumn } from '@components/Table/Table'
import TableWithEmptyListing from '@components/TableWithEmptyListing/TableWithEmptyListing'
import { MIN_TABLE_ROWS_FOR_PAGINATION, useTranslation } from '@const/globals'
import {
  getClickedData,
  getDeliveredData,
  getFailedData,
  getOptedOutData,
  getRepliedData,
} from '@src/pages/sms/report/components/ReportDetail/ReportDetail.utils'
import { ReportTabDataField, ReportTabs } from '@src/pages/sms/report/SMSReport.constants'
import { useAccountSettings } from '@utils/account/account.utils'
import { getStandardFormattedDate } from '@utils/date'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { usePrevious } from '@utils/hooks/usePrevious'
import { logNewRelicError } from '@utils/new-relic.utils'
import { titleCase } from '@utils/strings'

import './ReportDetail.css'

interface ChartDetails {
  title: string
  fields: ReportTabDataField[]
  average?: Average
}

interface TableDetails {
  infoText: string | ReactNode
  columns: TableColumn[]
}

interface EmptyState {
  text: ReactNode
  headline?: string
  graphic?: StaticImageNames
}

export interface Props {
  launchId: string
  isLaunch: boolean
  themeName: ReportTabs
  chartDetails: ChartDetails
  tableDetails: TableDetails
  emptyState: EmptyState
  count: number
  className?: string
  dataTest?: string
}

interface State {
  data: any[]
  loaded: boolean
  searchTerm: string
  pageIndex: number
  pageSize: number
  sortBy: Array<any>
  count: number
  pageCount: number
  pageError: boolean
}

const defaultState: State = {
  data: [],
  loaded: false,
  searchTerm: '',
  pageIndex: 0,
  pageSize: 20,
  sortBy: [
    {
      id: 'contactName',
      desc: false,
    },
  ],
  count: 0,
  pageCount: 0,
  pageError: false,
}

const rootClass = 'report-detail'

const ReportDetail: FC<Props> = (props: Props) => {
  const { launchId, isLaunch, tableDetails, emptyState, chartDetails, themeName, count, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  // @ts-ignore
  const { accountId } = useAccountSettings()
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.SMS_REPORTING })
  const [state, setState] = useState<State>({ ...defaultState, count, pageCount: Math.ceil(count / defaultState.pageSize) })

  //TODO: usePrevious for state?
  const previousSearchTerm = usePrevious(state.searchTerm)
  const previousPageSize = usePrevious(state.pageSize)
  const previousPageIndex = usePrevious(state.pageIndex)
  const previousSortBy = usePrevious(state.sortBy)

  const formatData = (data: any[]) => {
    return data.map((row) => {
      const contactName = row.contactName !== '' ? row.contactName : ''
      const status = row['status'] ? { status: titleCase(row.status) } : {}
      const repliedOnDate = row['repliedOnDate'] ? { repliedOnDate: getStandardFormattedDate(row.repliedOnDate, true) } : {}
      const clickedOnDate = row['clickedOnDate'] ? { clickedOnDate: getStandardFormattedDate(row.clickedOnDate, true) } : {}
      const optedOutOnDate = row['optedOutOnDate'] ? { optedOutOnDate: getStandardFormattedDate(row.optedOutOnDate, true) } : {}
      return { ...row, contactName, ...status, ...repliedOnDate, ...clickedOnDate, ...optedOutOnDate }
    })
  }

  // graphQL table data calls go here
  if (
    client &&
    (!state.loaded ||
      previousSearchTerm !== state.searchTerm ||
      previousPageSize !== state.pageSize ||
      previousPageIndex !== state.pageIndex ||
      !equal(previousSortBy, state.sortBy))
  ) {
    const id = isLaunch ? { launchId } : { messageId: launchId }
    const variables = {
      ...id,
      accountId,
      size: state.pageSize,
      page: state.pageIndex,
      sortDirection: state.sortBy[0].desc ? 'DESC' : ('ASC' as any),
      sortColumn: state.sortBy[0].id.toUpperCase(),
      searchTerm: state.searchTerm,
    }

    switch (themeName) {
      case ReportTabs.DELIVERED:
        getDeliveredData(client, variables, isLaunch)
          .then((data) => setState({ ...state, data: formatData(data?.smsSentReport ?? []), count: data?.totalCount ?? 0, loaded: true }))
          .catch((error) => {
            setState({ ...state, pageError: true, loaded: true })
            logNewRelicError(error)
          })
        break
      case ReportTabs.CLICKED:
        getClickedData(client, variables, isLaunch)
          .then((data) => setState({ ...state, data: formatData(data?.smsClick ?? []), count: data?.totalCount ?? 0, loaded: true }))
          .catch((error) => {
            setState({ ...state, pageError: true, loaded: true })
            logNewRelicError(error)
          })
        break
      case ReportTabs.REPLIED:
        getRepliedData(client, variables, isLaunch)
          .then((data) => setState({ ...state, data: formatData(data?.smsReplies ?? []), count: data?.totalCount ?? 0, loaded: true }))
          .catch((error) => {
            setState({ ...state, pageError: true, loaded: true })
            logNewRelicError(error)
          })
        break
      case ReportTabs.OPTED_OUT:
        getOptedOutData(client, variables, isLaunch)
          .then((data) => setState({ ...state, data: formatData(data?.smsOptedOutReport ?? []), count: data?.totalCount ?? 0, loaded: true }))
          .catch((error) => {
            setState({ ...state, pageError: true, loaded: true })
            logNewRelicError(error)
          })
        break
      case ReportTabs.FAILED:
        getFailedData(client, variables, isLaunch)
          .then((data) => setState({ ...state, data: formatData(data?.smsFailedReport ?? []), count: data?.totalCount ?? 0, loaded: true }))
          .catch((error) => {
            setState({ ...state, pageError: true, loaded: true })
            logNewRelicError(error)
          })
        break
      default:
        throw new Error('Bad tab value sent')
    }
  }

  const handleSearch = (searchTerm: string) => {
    setState({
      ...state,
      searchTerm,
    })
  }

  const fetchData = (pageIndex: number, pageSize: number) => {
    const pageCount = Math.ceil(state.count / pageSize)
    setState({ ...state, pageIndex, pageSize, pageCount })
  }

  const onChangeSort = (sortBy: Array<any>) => {
    const validSortBy = sortBy.length > 0 ? sortBy : [defaultState.sortBy]
    setState({ ...state, sortBy: validSortBy })
  }

  if (!state.loaded) {
    return <Loader loaderType={LoaderTypes.page} center />
  }

  if (state.pageError) {
    return (
      <PageContainer>
        <PageError vertical />
      </PageContainer>
    )
  }

  const columns = tableDetails.columns.map((col) => ({ ...col, Header: col.Header }))

  const fields =
    themeName === ReportTabs.FAILED
      ? chartDetails.fields.sort((a) => (a.label === t('Other') ? 1 : -1))
      : chartDetails.fields.map((field) => ({
          ...field,
          data: field.uniques ?? field.data,
        }))

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {(!!state.data.length || !!state.searchTerm) && (
        <ProgressChart
          fields={fields}
          title={chartDetails.title}
          average={chartDetails.average}
          className={`${rootClass}__chart-container-${themeName}`}
        />
      )}
      <div className={`${rootClass}__table-container`}>
        <Container
          noSidePadding
          className={classNames(
            rootClass,
            { [`${rootClass}__empty`]: !state.data.length },
            { [`${rootClass}__chart-container-${themeName}`]: !state.data.length }
          )}
        >
          <TableWithEmptyListing
            loading={!state.loaded}
            data={state.data}
            columns={columns}
            infoText={tableDetails.infoText}
            className={rootClass}
            filterPlaceholder={`${t('Search Recipients')}...`}
            canFilter
            canPaginate={state.count >= MIN_TABLE_ROWS_FOR_PAGINATION}
            controlledPageCount={state.pageCount}
            fetchData={fetchData}
            initialState={{ pageSize: state.pageSize, pageIndex: state.pageIndex, sortBy: state.sortBy }}
            text={emptyState.text}
            headline={t(emptyState.headline)}
            imgSrc={emptyState.graphic}
            size={EmptyListingSize.MEDIUM}
            handleSearch={handleSearch}
            emptyText={state.searchTerm && !state.data.length ? t('No recipients matched your search') : undefined}
            disablePaginator={!state.loaded && !!state.searchTerm && !state.data.length}
            manualSortBy
            onSort={onChangeSort}
            disableMultiSort
            withoutBorder
          />
        </Container>
      </div>
    </div>
  )
}

export default ReportDetail
