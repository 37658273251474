import React, { FC, useCallback, useMemo, useState } from 'react'

import { RowAction } from '@src/../../../libs/components/common/Table/Table'

import { ApolloQueryResult, FetchResult, MutationFunctionOptions } from '@apollo/client'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import PageContainer from '@components/PageContainer'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import StatusToast from '@components/StatusToast/StatusToast'
import { MultipleTables } from '@components/TableV2/components/MultipleTables/MultipleTables'
import { TableV2Props } from '@components/TableV2/tableV2TS/interfaces'
import TableWithLoaderAndEmptyListing from '@components/TableWithLoaderAndEmptyListing/TableWithLoaderAndEmptyListing'
import Typography, { ModalBodyStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import {
  CreateSenderDomainConfigMutation,
  CreateSenderDomainConfigMutationVariables,
} from '@graphql/types/microservice/email-management-proxy-service-types'
import {
  ChangeDefaultFromAddressMutation,
  DeleteFromAddressMutation,
  FromAddressesInput,
  SaveFromAddressMutation,
  VerifyFromAddressMutation,
} from '@graphql/types/mutation-types'
import { Exact, FromAddresses, LoadFromAddressesPageQuery } from '@graphql/types/query-types'
import { StatusToastType } from '@interface/StatusToast'
import AddSenderModal, { ModalData } from '@src/pages/Settings/OtherSettings/FromAddresses/components/addSenderModal/AddSenderModal'
import FromAddressHeader from '@src/pages/Settings/OtherSettings/FromAddresses/components/fromAddressHeader/FromAddressHeader'
import { LEARN_MORE_LINK, SenderDomainInfo } from '@src/pages/Settings/OtherSettings/FromAddresses/utils/FromAddresses.constants'
import useFilteredState from '@src/pages/Settings/OtherSettings/FromAddresses/utils/FromAddresses.filteredState'
import getRowActions from '@src/pages/Settings/OtherSettings/FromAddresses/utils/FromAddresses.rowActions'
import { useTableColumns, tableV2Columns } from '@src/pages/Settings/OtherSettings/FromAddresses/utils/FromAddresses.tableColumns'
import getTableProps from '@src/pages/Settings/OtherSettings/FromAddresses/utils/FromAddresses.tableProps'
import { useAccountSettings } from '@utils/account/account.utils'
import { FetchPromise } from '@utils/types'

import './fromAddress.css'

const rootClass = 'from-addresses'

interface FromAddressesProps {
  state: FromAddresses[]
  loading: boolean
  isAdmin: boolean
  domainConfigInfo?: SenderDomainInfo
  deleteAddress: (
    options?: MutationFunctionOptions<DeleteFromAddressMutation, Exact<{ uuid: string }>> | undefined
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>
  saveAddress: (
    options?: MutationFunctionOptions<SaveFromAddressMutation, Exact<{ fromAddress: FromAddressesInput; action: string; uuid?: string }>> | undefined
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>
  saveAddressToService: (
    options?: MutationFunctionOptions<CreateSenderDomainConfigMutation, CreateSenderDomainConfigMutationVariables> | undefined
  ) => FetchPromise<CreateSenderDomainConfigMutation>
  sendEmailVerification: (
    options?: MutationFunctionOptions<VerifyFromAddressMutation, Exact<{ uuid: string }>> | undefined
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>
  changeDefault: (
    options?: MutationFunctionOptions<ChangeDefaultFromAddressMutation, Exact<{ uuid: string; action: string }>> | undefined
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>
  refetch: () => Promise<ApolloQueryResult<LoadFromAddressesPageQuery>>
}

const FromAddress: FC<FromAddressesProps> = ({
  state,
  loading,
  isAdmin,
  saveAddress,
  saveAddressToService,
  deleteAddress,
  changeDefault,
  sendEmailVerification,
  refetch,
  domainConfigInfo,
}: FromAddressesProps) => {
  const [openAddSenderModal, setOpenAddSenderModal] = useState<boolean>(false)
  const [openEmailVerificationModal, setOpenEmailVerificationModal] = useState<boolean>(false)
  const [openResendVerificationModal, setOpenResendVerificationModal] = useState<boolean>(false)
  const [openVerificationAlreadySentModal, setOpenVerificationAlreadySentModal] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [toast, setToastStatus] = useState<StatusToastType>()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [senderToEdit, setSenderToEdit] = useState<ModalData | undefined>(undefined)
  const [selectedUUID, setSelectedUUID] = useState<string>('')
  const [verificationEmail, setVerificationEmail] = useState<string>('')
  const [loadingSaveAddress, setLoadingSaveAddress] = useState<boolean>(false)
  const { hasCheckDomainConfig } = useAccountSettings()

  const filteredState = useFilteredState(searchTerm, state)
  const { t } = useTranslation()
  const columns = useTableColumns(rootClass, domainConfigInfo)
  const tableProps = getTableProps(isAdmin, !!state.length, setOpenAddSenderModal)
  const columnsV2 = useMemo(() => tableV2Columns(t, rootClass, domainConfigInfo), [t, rootClass, domainConfigInfo])

  const onEditAction = useCallback((fromAddresses: FromAddresses) => {
    setOpenAddSenderModal(true)
    setSenderToEdit(fromAddresses as ModalData)
  }, [])

  const onDeleteAction = useCallback((fromAddresses: FromAddresses) => {
    fromAddresses.uuid && setSelectedUUID(fromAddresses.uuid)
    setOpenDeleteModal(true)
  }, [])

  const onVerifyAction = useCallback((fromAddresses: FromAddresses) => {
    fromAddresses.uuid && setSelectedUUID(fromAddresses.uuid)
    fromAddresses.email && setVerificationEmail(fromAddresses.email)
    setOpenResendVerificationModal(true)
  }, [])

  const onChangeDefault = useCallback((fromAddress: FromAddresses, action: 'add' | 'remove') => {
    if (fromAddress.uuid) {
      changeDefault({ variables: { uuid: fromAddress.uuid, action } }).then((res) => {
        const response = res.data.setDefaultSender
        const isSuccess = response.status === 'ok'
        const toastMessage = isSuccess
          ? action === 'remove'
            ? "Success! We've removed your default sender."
            : "Success! We've set your default sender."
          : 'Something went wrong on our end. Please try again.'
        setToastStatus({
          showStatus: true,
          statusMessage: response.message || t(toastMessage),
          successStatus: isSuccess,
        })
        refetch()
      })
    }
  }, [])

  const rowActions = useMemo<RowAction[]>(
    () => getRowActions(onEditAction, onDeleteAction, onVerifyAction, onChangeDefault),
    [onChangeDefault, onDeleteAction, onEditAction, onVerifyAction]
  )

  const tableV2Props = useMemo(
    (): TableV2Props<FromAddresses> => ({
      data: filteredState,
      columns: columnsV2,
      loading: loading,
      enableOuterLoader: true,
      enableSorting: true,
      rowActions: isAdmin ? rowActions : [],
      emptyState: {
        size: EmptyListingSize.MEDIUM,
        hideIcon: false,
        headline: t(!!state.length ? 'No results found' : 'No sender addresses yet'),
        text: t(
          !!state.length
            ? 'There were no results matching your search.'
            : isAdmin
            ? 'Add email addresses to send from.'
            : 'You must be an admin user to add new addresses.'
        ),
        imgSrc: !!state.length ? StaticImageNames.emptySearch : StaticImageNames.envelope,
        buttonText: !state.length && isAdmin ? t('Add Sender') : undefined,
        buttonOnClick: !state.length && isAdmin ? () => setOpenAddSenderModal(true) : undefined,
        linkText: !!state.length ? undefined : t('Learn more about adding From Addresses'),
        link: !!state.length ? undefined : LEARN_MORE_LINK,
      },
    }),
    [filteredState, columnsV2, loading, isAdmin, rowActions, state.length]
  )

  const onSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm)
  }

  const onSenderModalClose = useCallback<() => void>(() => {
    setOpenAddSenderModal(false)
    setSenderToEdit(undefined)
    setLoadingSaveAddress(false)
  }, [])

  const handleDeleteModalAnswer = useCallback<(ans: YesNo) => void>(
    (ans) => {
      if (ans === YesNo.YES) {
        deleteAddress({ variables: { uuid: selectedUUID } }).then((res) => {
          const response = res.data.fromAddressDelete
          const isSuccess = response.status === 'ok'
          const toastMessage = isSuccess ? "Success! We've removed the from address." : 'Something went wrong on our end. Please try again.'
          setToastStatus({
            showStatus: true,
            statusMessage: response.message || t(toastMessage),
            successStatus: isSuccess,
          })
          refetch()
        })
      }
      setOpenDeleteModal(false)
      setSelectedUUID('')
    },
    [selectedUUID, deleteAddress]
  )

  const handleSendEmailVerification = useCallback<(uuid: string) => Promise<any>>((uuid) => {
    return sendEmailVerification({ variables: { uuid } }).then((res) => {
      const response = res.data.verifyFromAddress
      if (response.status === 'ok') {
        setOpenEmailVerificationModal(true)
        refetch()
      } else {
        setToastStatus({
          showStatus: true,
          statusMessage: response.message || t('Something went wrong on our end. Please try again.'),
          successStatus: false,
        })
      }
      return res
    })
  }, [])

  const handleResendModalAnswer = useCallback<(ans: YesNo) => void>(
    (ans) => {
      if (ans === YesNo.YES && selectedUUID) {
        handleSendEmailVerification(selectedUUID)
      }
      setOpenResendVerificationModal(false)
      setSelectedUUID('')
    },
    [selectedUUID, sendEmailVerification]
  )

  const addFromAddress = useCallback<(data: ModalData) => void>(
    (data) => {
      const { uuid, ...fromAddress } = data
      const action = uuid ? 'edit' : 'add'
      setLoadingSaveAddress(true)
      saveAddress({ variables: { fromAddress, action, uuid } }).then((res) => {
        const response = res.data.saveFromAddress
        if (response.status === 'ok') {
          if (action === 'edit') {
            setToastStatus({
              showStatus: true,
              statusMessage: response.message || t("Success! We've edited the from address."),
              successStatus: true,
            })
            onSenderModalClose()
            refetch()
          } else if (response.verified) {
            setOpenVerificationAlreadySentModal(true)
            onSenderModalClose()
            refetch()
          } else {
            setVerificationEmail(fromAddress.email)
            handleSendEmailVerification(response.uuid).then(() => {
              onSenderModalClose()
            })
          }
        } else if (response.status === 'error') {
          setToastStatus({
            showStatus: true,
            statusMessage: response.message,
            successStatus: false,
          })
          onSenderModalClose()
        }
        if (hasCheckDomainConfig) {
          saveAddressToService({ variables: { domain: fromAddress.email.substring(fromAddress.email.indexOf('@') + 1) } })
        }
      })
    },
    [saveAddress, onSenderModalClose]
  )

  const verificationSuccessBody = (
    <>
      <Typography text={t("We've sent an email to ")} {...ModalBodyStyle} inline />
      <Typography text={`${verificationEmail}. `} inline weight={TextWeight.MEDIUM} type={TextType.BODY_TEXT_LIGHT} />
      <Typography text={t('Click the link to verify this email address and begin using this sender.')} {...ModalBodyStyle} inline />
    </>
  )

  const resendEmailModalBody = (
    <>
      <Typography text={t('Send another verification email to ')} {...ModalBodyStyle} inline />
      <Typography text={`${verificationEmail}. `} inline weight={TextWeight.MEDIUM} type={TextType.BODY_TEXT_LIGHT} />
    </>
  )

  return (
    <>
      {toast?.showStatus && (
        <StatusToast
          isSuccess={toast.successStatus}
          message={toast.statusMessage}
          title={toast.title}
          closeStatus={() => {
            setToastStatus({ showStatus: false })
          }}
        />
      )}

      <ConfirmationModal
        isOpen={openEmailVerificationModal}
        closeModal={() => {
          setVerificationEmail('')
          setOpenEmailVerificationModal(false)
        }}
        body={verificationSuccessBody}
        title={t('Verification Email Sent')}
      />
      <ConfirmationModal
        isOpen={openVerificationAlreadySentModal}
        closeModal={() => setOpenVerificationAlreadySentModal(false)}
        title={t('Address already verified')}
        body={<Typography text={t("We've previously verified this email address. You can use it right away.")} {...ModalBodyStyle} />}
      />
      <ConfirmationModal
        isYesNo
        isOpen={openResendVerificationModal}
        onAnswer={handleResendModalAnswer}
        body={resendEmailModalBody}
        title={t('Resend verification email?')}
      />
      <ConfirmationModal
        isDelete
        deleteButtonText={t('Yes, delete')}
        isOpen={openDeleteModal}
        onAnswer={handleDeleteModalAnswer}
        title={t('Are you sure?')}
        body={<Typography text={t('This sender will no longer be available for your outbound messages.')} {...ModalBodyStyle} />}
      />
      <AddSenderModal
        isOpen={openAddSenderModal}
        loading={loadingSaveAddress}
        onClose={onSenderModalClose}
        saveFromAddress={addFromAddress}
        dataState={senderToEdit}
      />
      <PageContainer className={rootClass}>
        <PositionContainer>
          <FromAddressHeader
            rootClass={rootClass}
            showActions={!!state.length}
            onAdd={setOpenAddSenderModal}
            onSearch={onSearch}
            searchTerm={searchTerm}
            showInfoAction={state.length > 0}
            isAdmin={isAdmin}
          />
          <MultipleTables
            tableV2Props={tableV2Props}
            oldTable={
              <TableWithLoaderAndEmptyListing
                className={`${rootClass}__table`}
                data={filteredState}
                columns={columns}
                loading={loading}
                rowActions={isAdmin ? rowActions : []}
                {...tableProps}
              />
            }
          />
        </PositionContainer>
      </PageContainer>
    </>
  )
}

export default FromAddress
