import React, { FC } from 'react'

import classNames from 'classnames'

import { LinkTextButton } from '@components/LinkTextButton/LinkTextButton'
import StaticImage from '@components/StaticImage/StaticImage'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Toggle from '@components/Toggle'
import { TextType } from '@components/Typography/Typography'
import Typography from '@components/Typography/Typography'
import { useTranslation } from '@utils/const/globals'

import './BetaBannerCard.css'

interface BetaBannerCardProps {
  onToggle: (isOn: boolean) => void
  isToggleOn?: boolean
  betaLink?: string
  className?: string
}

const BETA_LINK = 'https://connect.act-on.com/hc/en-us/articles/21976145799319-Beta-Release-All-New-Email-Composer'
const rootClass = 'beta-baner-card'

export const BetaBannerCard: FC<BetaBannerCardProps> = (props) => {
  const { onToggle, isToggleOn, betaLink = BETA_LINK, className } = props
  const { t } = useTranslation()
  return (
    <div className={classNames(className, rootClass)}>
      <StaticImage name={StaticImageNames.betaBannerCard} />
      <div className={`${rootClass}__container`}>
        <div className={`${rootClass}__content`}>
          <Typography text={t('CreateLandingPageModal.BetaBannerCard.Title')} type={TextType.DATA_CARD_MODAL_HEADER} />
          <Typography
            text={t('CreateLandingPageModal.BetaBannerCard.Desc')}
            tagComponents={{ LinkTextButton: <LinkTextButton link={betaLink} text="Learn more" inline hideIconLeft /> }}
          />
        </div>
        <div className={`${rootClass}__actions`}>
          <Toggle large noLeftMargin isOn={!!isToggleOn} onToggle={onToggle} label={t('CreateLandingPageModal.BetaBannerCard.Toggle')} />
        </div>
      </div>
    </div>
  )
}
