import React, { FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import StatusToast, { Status } from '@components/StatusToast/StatusToast'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ProgramStep } from '@graphql/types/query-types'
import ProgramErrorsModal from '@src/pages/programs/dashboard/components/ProgramErrorsModal/ProgramErrorsModal'
import ClearHistoryModal from '@src/pages/programs/dashboard/components/ProgramPerformance/components/ClearHistoryModal/ClearHistoryModal'
import ManageContactsOrStartModal from '@src/pages/programs/dashboard/components/ProgramPerformance/components/ManageContactsOrStartModal/ManageContactsOrStartModal'
import { getProgramStepsWithLetters } from '@src/pages/programs/dashboard/ProgramSteps.utils'
import { ProgramManagerContext, ProgramManagerPopupsState } from '@src/pages/programs/manager/context/ProgramManager.context'
import { useAccountSettings } from '@utils/account/account.utils'
import { ProgramErrors, ProgramStepError } from '@utils/program/program.constants'
import { parseErrorSetToRunMessage } from '@utils/program/program.utils'

import './ProgramManagerModals.css'

interface ProgramManagerModalsProps {
  className?: string
  dataTest?: string
}

const rootClass = 'program-manager-modals'

const ProgramManagerModals: FC<ProgramManagerModalsProps> = (props: ProgramManagerModalsProps) => {
  const { dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const { reenterProgramContacts } = useAccountSettings()
  const [errors, setErrors] = useState<ProgramErrors>({} as ProgramErrors)

  const {
    values: {
      program,
      programErrors,
      programCounts,
      errorSetToRunMessage,
      programManagerModalsState: {
        showStartProgram,
        showPauseProgram,
        showAddContactsModal,
        showExitContactsModal,
        showClearHistory,
        showErrors,
        showConfirmSave,
        showCancelEdit,
      },
      programManagerPopupsState: {
        exitSuccess,
        exitFail,
        addSuccess,
        addFail,
        errorSetToPause,
        errorSetToRun,
        moveToNextSuccess,
        moveToNextFail,
        clearHistorySuccess,
        clearHistoryFail,
        saveSuccess,
        saveError,
        loadError,
        copyProgramMessageSuccess,
        copyProgramMessageFail,
      },
    },
    startProgram,
    pauseProgram,
    editProgram,
    cancelEdit,
    saveProgramFinal,
    addContactsToProgram,
    exitContactsFromProgram,
    clearProgramHistory,
    toggleProgramManagerModalsState,
    closePopup,
    fixErrors,
  } = useContext(ProgramManagerContext)

  const wasProgramPreviouslyStarted = useMemo(() => !!program?.startedOn, [program])

  useEffect(() => {
    const steps = getProgramStepsWithLetters(program)
    if (programErrors) {
      setErrors({
        ...programErrors,
        stepErrors: programErrors?.stepErrors
          .map((errorStep: ProgramStepError) => {
            const frontEndStepId = steps?.filter((step: ProgramStep) => step.stepId === errorStep.id)
            return {
              ...errorStep,
              name: `${frontEndStepId && frontEndStepId[0] ? frontEndStepId[0].letter : ''}: ${errorStep.name}`,
              stepType: frontEndStepId && frontEndStepId[0] ? frontEndStepId[0].stepType : 'error',
            }
          })
          .sort((a: any, b: any) => (a.name > b.name ? 1 : -1)),
      })
    }
  }, [programErrors])

  const onAddContacts = () => {
    toggleProgramManagerModalsState('showStartProgram')
    startProgram(false)
  }

  const onAddContactsToProgram = () => {
    toggleProgramManagerModalsState('showAddContactsModal')
    addContactsToProgram()
  }

  const onExitContactsFromProgram = () => {
    toggleProgramManagerModalsState('showAddContactsModal')
    toggleProgramManagerModalsState('showExitContactsModal')
  }

  const onExitContacts = () => {
    toggleProgramManagerModalsState('showStartProgram')
    startProgram(true)
  }

  const onClearProgramHistory = (allowReentrantAddresses: boolean) => {
    toggleProgramManagerModalsState('showClearHistory')
    clearProgramHistory(allowReentrantAddresses)
  }

  const onFixErrors = () => {
    toggleProgramManagerModalsState('showErrors')
    fixErrors()
  }

  const onSaveProgramLast = () => {
    saveProgramFinal()
  }

  const onCancelEdit = (answer: YesNo) => {
    toggleProgramManagerModalsState('showCancelEdit')
    answer === YesNo.YES && cancelEdit()
  }

  const renderShowPopupStatus = (message: string | ReactNode, status: Status, closeField: keyof ProgramManagerPopupsState, className?: string) => (
    <StatusToast
      message={message}
      status={status}
      closeStatus={() => {
        closePopup(closeField)
      }}
      hasTimeout={closeField !== 'exitSuccess'}
      className={className}
    />
  )

  const renderExitSuccessMessage = () => {
    return (
      <div className={`${rootClass}__exit-message`}>
        <Typography text={`${programCounts.pendingSize} contacts exited from the program`} weight={TextWeight.BOLD} />
        <Typography text={t(`You can find exited contacts under suppressions in the Settings tab`)} />
      </div>
    )
  }

  const showPopupStatusMessages = () => {
    return (
      <>
        {errorSetToRun &&
          renderShowPopupStatus(
            parseErrorSetToRunMessage(t, errorSetToRunMessage, rootClass),
            Status.FAIL,
            'errorSetToRun',
            classNames({ [`${rootClass}__error-toast`]: errorSetToRunMessage })
          )}
        {errorSetToPause && renderShowPopupStatus(t('Your program could not be paused'), Status.FAIL, 'errorSetToPause')}
        {addSuccess &&
          renderShowPopupStatus(t('Pending contacts are being added to the program. This may take a few minutes.'), Status.SUCCESS, 'addSuccess')}
        {addFail && renderShowPopupStatus(t('Waiting contacts could not be added to the program'), Status.FAIL, 'addFail')}
        {exitSuccess && renderShowPopupStatus(renderExitSuccessMessage(), Status.SUCCESS, 'exitSuccess')}
        {exitFail && renderShowPopupStatus(t('Waiting contacts could not be exited from the program'), Status.FAIL, 'exitFail')}
        {moveToNextSuccess && renderShowPopupStatus(t('Waiting contacts were moved to the next step'), Status.SUCCESS, 'moveToNextSuccess')}
        {moveToNextFail && renderShowPopupStatus(t('Waiting contacts could not be moved to the next step'), Status.FAIL, 'moveToNextFail')}
        {clearHistorySuccess && renderShowPopupStatus(t('Program history has been cleared'), Status.SUCCESS, 'clearHistorySuccess')}
        {clearHistoryFail && renderShowPopupStatus(t('Program history could not be cleared'), Status.FAIL, 'clearHistoryFail')}
        {saveSuccess && renderShowPopupStatus(t('Program was successfully saved.'), Status.SUCCESS, 'saveSuccess')}
        {saveError && renderShowPopupStatus(t('An error occurred while trying to save your program.'), Status.FAIL, 'saveError')}
        {loadError && renderShowPopupStatus(t('An error occurred while trying to retrieve your program.'), Status.FAIL, 'loadError')}
        {copyProgramMessageSuccess && renderShowPopupStatus(t('Message has been successfully copied.'), Status.SUCCESS, 'copyProgramMessageSuccess')}
        {copyProgramMessageFail && renderShowPopupStatus(t('There was a problem copying the message.'), Status.FAIL, 'copyProgramMessageFail')}
      </>
    )
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {showPopupStatusMessages()}
      {showConfirmSave && (
        <ProgramErrorsModal
          isSaving
          errors={programErrors}
          fixErrors={onSaveProgramLast}
          closeModal={() => {
            toggleProgramManagerModalsState('showConfirmSave')
          }}
        />
      )}
      {showErrors && programErrors && (
        <ProgramErrorsModal
          errors={errors}
          fixErrors={onFixErrors}
          closeModal={() => toggleProgramManagerModalsState('showErrors')}
          isSaving={false}
        />
      )}
      {/* Start Program Modal */}
      {showStartProgram && (
        <ManageContactsOrStartModal
          isOpen
          isFirstRun={!wasProgramPreviouslyStarted}
          isStart
          pendingContacts={programCounts.pendingSize}
          title={'Start Program'}
          onAddContactsToProgram={onAddContacts}
          onExitContactsFromProgram={onExitContacts}
          onClose={() => toggleProgramManagerModalsState('showStartProgram')}
        />
      )}
      {/* Add Contacts Modal */}
      {showAddContactsModal && (
        <ManageContactsOrStartModal
          isOpen
          pendingContacts={programCounts.pendingSize}
          onAddContactsToProgram={onAddContactsToProgram}
          onExitContactsFromProgram={onExitContactsFromProgram}
          onClose={() => toggleProgramManagerModalsState('showAddContactsModal')}
        />
      )}
      <ConfirmationModal
        isOpen={showPauseProgram}
        className={`${rootClass}__confirmation`}
        title={t('Pause your program and edit it?')}
        body={t('Your program must be paused before you can make any changes to it.')}
        isYesNo
        yesButtonText={t('Pause and Edit')}
        onAnswer={(answer: YesNo) => {
          if (answer === YesNo.YES) {
            pauseProgram()
            editProgram()
          }
          toggleProgramManagerModalsState('showPauseProgram')
        }}
      />
      <DeleteConfirmationModal
        isOpen={showExitContactsModal}
        title={`${t('Exit New Contacts')}?`}
        deleteButtonText={t('Exit from program')}
        body={t('New contacts will exit the program')}
        onAnswer={(answer) => {
          if (answer === YesNo.YES) {
            exitContactsFromProgram()
            onExitContacts()
          }
          toggleProgramManagerModalsState('showExitContactsModal')
        }}
      />
      <ClearHistoryModal
        allowReentrantAddresses={program?.allowReentrantAddresses ?? false}
        isOpen={showClearHistory}
        showReentrantCheckbox={reenterProgramContacts}
        onClearHistory={onClearProgramHistory}
        onClose={() => toggleProgramManagerModalsState('showClearHistory')}
      />
      <DeleteConfirmationModal
        isOpen={showCancelEdit}
        title={t('You have unsaved changes')}
        deleteButtonText={t('Discard changes')}
        body={t('If you proceed, you will lose the changes you’ve made. Are you sure you want to continue?')}
        onAnswer={onCancelEdit}
      />
    </div>
  )
}

export default ProgramManagerModals
