import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import PageContainer from '@components/PageContainer'
import PageError from '@components/PageError/PageError'
import dataManagementPageLoad from '@graphql/microservices/crm/dataManagementPageLoad'
import entityTypes from '@graphql/microservices/crm/entityTypes'
import {
  DataManagementPageLoadQuery,
  DataManagementPageLoadQueryVariables,
  EntityTypeDisplay,
  EntityTypesQuery,
  EntityTypesQueryVariables,
  OptInOutVisibility,
} from '@graphql/types/microservice/crm-types'
import { ColumnType } from '@src/pages/datamanagement/components/CrmContacts/components/DynamicList/utils/DynamicList.interfaces'
import { getEnabledFieldsFromMapping } from '@src/pages/datamanagement/components/CrmContacts/CrmContactUtil'
import { useCrmContactsRequests } from '@src/pages/datamanagement/components/CrmContacts/utils/CrmContacts.graphQL'
import {
  DataManagementContext,
  LastSyncResult,
  RunningSyncStatus,
  State,
  SyncSchedule,
} from '@src/pages/datamanagement/context/DataManagementContext'
import {
  getLastSyncResultUtil,
  saveEntityFields,
  saveFieldMappings,
  saveOptInOut,
  saveReportMapping,
  saveSyncScheduleOptions,
} from '@src/pages/datamanagement/context/DataManagementUtil'
import DataManagement, { SyncInfo } from '@src/pages/datamanagement/DataManagement'
import {
  dataManagementDefaultState,
  DataManagementTabs,
  ScheduleAMPM,
  ScheduleType,
  SyncResult,
  syncRunningStatuses,
} from '@src/pages/datamanagement/utils/DataManagement.constants'
import { useAccountSettings } from '@utils/account/account.utils'
import { FieldValidationState } from '@utils/crm.utils'
import useCRM from '@utils/hooks/useCRM'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'

interface Props {
  dataTest?: string
  syncInfo: SyncInfo
}

type AllProps = Props

const rootClass = 'data-management'

const DataManagementContainer: FC<AllProps> = (props: AllProps) => {
  const { dataTest = rootClass } = props

  const { accountTimeZoneId, getConnectedCrm, hasShowActOnContactsTab } = useAccountSettings()

  const [containerValues, setContainerValues] = useState<State>(
    structuredClone({
      ...dataManagementDefaultState,
      currentTab: hasShowActOnContactsTab ? DataManagementTabs.CONTACT_MAPPING : DataManagementTabs.CRM_ENTITIES,
    })
  )
  const { currentTab, pageError, pushPolicy, unifiedListFieldMappings, fieldMappings } = containerValues

  const { setPushPolicy } = useCrmContactsRequests()

  const { token, aoAccountContext } = useSelector((state: any) => state.account.account)
  const { hasCRMConnected, connectorType } = useCRM()

  const runningSync: RunningSyncStatus = {
    isSyncingNow: false,
    syncState: '',
    isCanceling: false,
  }

  containerValues.clients.crmSyncSrvClient = useMicroserviceClient({ serviceName: MicroserviceClients.CRM }).client
  containerValues.clients.listSrvClient = useMicroserviceClient({ serviceName: MicroserviceClients.LIST }).client
  containerValues.clients.hotProspectsClient = useMicroserviceClient({ serviceName: MicroserviceClients.HOT_PROSPECTS }).client
  containerValues.clients.crmEntityMappingClient = useMicroserviceClient({ serviceName: MicroserviceClients.CRM_ENTITY_MAPPING }).client

  const loadCRMData = (isDiscard?: boolean) => {
    setContainerValues((containerValues) => ({
      ...containerValues,
      loading: true,
    }))

    containerValues.clients.crmSyncSrvClient
      .query<DataManagementPageLoadQuery, DataManagementPageLoadQueryVariables>({
        query: dataManagementPageLoad,
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        const entities: EntityTypeDisplay[] = data?.entityTypes?.map((entityType) => {
          return { ...entityType, visible: entityType?.visible ?? true }
        }) || [
          {
            display: '',
            identifier: '',
            nameColumns: [],
            pkColumn: '',
            recordCount: 0,
            visible: false,
          },
        ]

        const scheduleType = data?.syncSchedule?.automaticSchedule
          ? ScheduleType.AUTOMATIC
          : data?.syncSchedule === undefined
          ? ScheduleType.OFF
          : data?.syncSchedule?.mode === 'None'
          ? ScheduleType.OFF
          : ScheduleType.SCHEDULE

        const scheduleAMPM =
          data?.syncSchedule?.ampm?.toString().toLowerCase() === 'am'
            ? ScheduleAMPM.AM
            : data?.syncSchedule?.ampm?.toString().toLowerCase() === 'pm'
            ? ScheduleAMPM.PM
            : ''

        const optInOutVisibility: OptInOutVisibility = {
          errorInstruction: data?.emailOptInOutVisibility?.errorInstruction,
          moreInfoUrl: data?.emailOptInOutVisibility?.moreInfoUrl,
          visibility: data?.emailOptInOutVisibility?.visibility ?? true,
        }

        const schedule: SyncSchedule = {
          frequency: data?.syncSchedule?.mode,
          period: data?.syncSchedule?.hourInterval?.toString(),
          scheduledTime: {
            hour: data?.syncSchedule?.hour,
            minute: data?.syncSchedule?.minute,
            ampm: scheduleAMPM,
          },
          schedule: scheduleType,
          dayOfTheWeek: data?.syncSchedule?.dayOfWeek?.toString(),
          dayOfTheMonth: data?.syncSchedule?.dayOfMonth?.toString(),
          pullOptOut: data?.optInOutSettings?.optInOutPullEnabled,
          pushOptOut: data?.optInOutSettings?.optInOutPushEnabled,
          isOptInOutSupported: data?.optInOutSettings?.optInOutSyncSupported,
          optInOutVisibility: optInOutVisibility,
        }

        if (data?.syncStatus != undefined && syncRunningStatuses.includes(data?.syncStatus)) {
          runningSync.syncState = data?.syncStatus
          runningSync.isSyncingNow = true
        }

        const syncResult = data?.lastSync?.syncResult
        const lastSyncResult = { syncResult, showWarning: syncResult === SyncResult.Failed }

        setContainerValues((containerValues) => ({
          ...(isDiscard ? structuredClone(dataManagementDefaultState) : containerValues),
          currentTab: containerValues.currentTab,
          syncInfo: entities,
          loading: false,
          token,
          accountContext: aoAccountContext,
          syncSchedule: schedule,
          originalSyncSchedule: schedule,
          lastSync: data?.lastSync?.ended,
          lastSyncResult: lastSyncResult as LastSyncResult,
          runningSyncStatus: runningSync,
          accountTimeZone: accountTimeZoneId ?? '',
          connectorType,
        }))
      })
      .catch(() => {
        setContainerValues((containerValues) => ({
          ...containerValues,
          pageError: true,
          loading: false,
        }))
      })
  }

  const savePushPolicy = async (allowPush?: boolean) => {
    if (unifiedListFieldMappings && currentTab === DataManagementTabs.CONTACT_MAPPING) {
      const currentEnabledFields = (pushPolicy?.enabledFields ?? []) as string[]
      const newEnabledFields = getEnabledFieldsFromMapping(unifiedListFieldMappings ?? [], fieldMappings, currentEnabledFields, allowPush)

      update('pushPolicyLoading', true)
      await setPushPolicy({
        enabledFields: [...new Set(newEnabledFields)],
        allowPush: allowPush ?? !!pushPolicy?.allowPush,
      })
      update('pushPolicyLoading', false)
    }
  }

  const loadNonCRMData = () => {
    setContainerValues({
      ...structuredClone(dataManagementDefaultState),
      fetchNonCRMData: true,
    })
  }

  const updateEntityTypes = () => {
    containerValues.clients.crmSyncSrvClient
      .query<EntityTypesQuery, EntityTypesQueryVariables>({
        query: entityTypes,
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        const entities: EntityTypeDisplay[] = data?.entityTypes?.map((entityType) => {
          return { ...entityType, visible: entityType?.visible ?? true }
        }) || [
          {
            display: '',
            identifier: '',
            nameColumns: [],
            pkColumn: '',
            recordCount: 0,
            visible: false,
          },
        ]

        setContainerValues((containerValues) => ({
          ...containerValues,
          syncInfo: entities,
        }))
      })
      .catch(() => {
        setContainerValues((containerValues) => ({
          ...containerValues,
          pageError: true,
        }))
      })
  }

  const getLastSyncResult = () => {
    return getLastSyncResultUtil(setContainerValues, containerValues.clients.crmSyncSrvClient)
  }

  // validate field mappings prior to saving
  const validateFieldMappings = () => {
    let displaySaveBanner = false
    let message = ''

    if (!hasCRMConnected) {
      const mappings = containerValues.nonCrmContactsData.standardRows.concat(containerValues.nonCrmContactsData.customRows)
      message = mappings.reduce((str, row) => {
        if (row.status === FieldValidationState.DUP_STD) {
          displaySaveBanner = true
          return 'Select each Field only once'
        } else if (row.status === FieldValidationState.DUP_CUST && str === '') {
          displaySaveBanner = true
          return 'Field name already exists'
        } else if (row.status === FieldValidationState.CUST_MATCH_STD && str === '') {
          displaySaveBanner = true
          return 'At least one custom field name matches an available Act-On Standard field.'
        }
        return str
      }, '')

      update('showCrmContactValidationToast', { open: displaySaveBanner, text: message })
      return !displaySaveBanner
    }

    // make sure there aren't any last minute issues with text fields
    const textFieldArray: string[] = []
    containerValues.crmContactData.customRows.forEach((row) => {
      const textColumn = row.columns.filter((col) => col.type === ColumnType.EDIT)
      if (textColumn && textColumn.length > 0 && row.id !== -1) {
        textFieldArray.push(textColumn[0].staticValue ?? '')
      }
    })

    // clear anything involving text fields - might be out of date so we're going to recheck it
    containerValues.fieldMappingStatus.forEach((v, k) => {
      if (v === FieldValidationState.DUP_CUST || v === FieldValidationState.CUST_MATCH_STD) {
        containerValues.fieldMappingStatus.set(k, FieldValidationState.OK)
      }
    })

    // any blanks?
    if (textFieldArray.filter((item) => item.length === 0).length > 0) {
      containerValues.fieldMappingStatus.set(10000, FieldValidationState.MISSING_CUSTOM_NAME)
    }

    // duplicate text fields?
    if (new Set(textFieldArray).size !== textFieldArray.length) {
      containerValues.fieldMappingStatus.set(10000, FieldValidationState.DUP_CUST)
    }

    // matching standard field name?
    const existingCustomFields = containerValues.unifiedListFieldMappings.filter((field) => !field.standardFieldKey).map((field) => field.displayName)
    const newFields = textFieldArray.filter((display) => !existingCustomFields.includes(display))
    const stdFieldCheck = newFields.some((o1) =>
      containerValues.crmContactData.stdFields.some((o2) => o1.toLowerCase().localeCompare(o2.display.toLowerCase()) === 0)
    )

    if (stdFieldCheck) {
      containerValues.fieldMappingStatus.set(10000, FieldValidationState.CUST_MATCH_STD)
    }

    const baseText = 'Unable to save just yet. '
    containerValues.fieldMappingStatus.forEach((value) => {
      switch (value) {
        case FieldValidationState.DUP_STD:
          displaySaveBanner = true
          message = 'Act-On Standard fields can only be mapped one time.'
          break
        case FieldValidationState.DUP_CUST:
          displaySaveBanner = true
          message = 'Custom fields must be distinct.'
          break
        case FieldValidationState.FAIL:
          displaySaveBanner = true
          message = 'At least one mapping row exists with incompatible data types.'
          break
        case FieldValidationState.MISSING_CUSTOM_NAME:
          displaySaveBanner = true
          message = 'Custom field mappings must have a destination field name set.'
          break
        case FieldValidationState.MISSING_DATA_TYPE:
          displaySaveBanner = true
          message = 'Custom field mappings must have a data type.'
          break
        case FieldValidationState.DUP_CRM_PRIMARY:
          displaySaveBanner = true
          message = 'A CRM field can only be mapped one time.'
          break
        case FieldValidationState.CUST_MATCH_STD:
          displaySaveBanner = true
          message = 'At least one custom field name matches an available Act-On Standard field.'
          break
      }
    })

    update('showCrmContactValidationToast', { open: displaySaveBanner, text: baseText + message })

    // reset anything we might have changed here
    containerValues.fieldMappingStatus.delete(10000)
    return !displaySaveBanner
  }

  const save = () => {
    if (!validateFieldMappings()) {
      return
    }

    if (!hasCRMConnected) {
      saveFieldMappings(containerValues, setContainerValues, hasShowActOnContactsTab).then((resFieldMappings) => {
        if (resFieldMappings.isComplete) {
          setContainerValues((containerValues) => ({
            ...containerValues,
            hasUpdates: false,
            mutationResultState: { ...containerValues.mutationResultState, attemptToSaveFieldMappingChanges: true },
          }))
        }
      })
    } else {
      containerValues.entityTable.forEach((val) => {
        const entitySaveRes = saveEntityFields(val, containerValues, setContainerValues, containerValues.clients.crmSyncSrvClient)
        entitySaveRes.then((res) => {
          if (res?.isComplete) {
            const entitySavedAlready = containerValues.mutationResultState.attemptToSaveEntityChanges
              ? containerValues.mutationResultState.attemptToSaveEntityChanges
              : []
            if (res.entity !== undefined) {
              entitySavedAlready.push(res.entity)
              const existingResultState = containerValues.mutationResultState
              existingResultState.attemptToSaveEntityChanges = entitySavedAlready
              setContainerValues((containerValues) => ({
                ...containerValues,
                hasUpdates: false,
                mutationResultState: { ...containerValues.mutationResultState, attemptToSaveEntityChanges: entitySavedAlready },
              }))
            }
          }
        })
      })

      saveSyncScheduleOptions(containerValues, setContainerValues, containerValues.clients.crmSyncSrvClient).then((resSchedule) => {
        if (resSchedule.isComplete) {
          const existingResultState = containerValues.mutationResultState
          existingResultState.attemptToSaveScheduleChanges = true
          setContainerValues((containerValues) => ({
            ...containerValues,
            hasUpdates: false,
            mutationResultState: { ...containerValues.mutationResultState, attemptToSaveScheduleChanges: true },
          }))
        }
      })

      saveOptInOut(containerValues, setContainerValues, containerValues.clients.crmSyncSrvClient).then((resOptInOut) => {
        if (resOptInOut.isComplete) {
          const existingResultState = containerValues.mutationResultState
          existingResultState.attemptToSaveOptInOutChanges = true
          setContainerValues((containerValues) => ({
            ...containerValues,
            hasUpdates: false,
            mutationResultState: { ...containerValues.mutationResultState, attemptToSaveOptInOutChanges: true },
          }))
        }
      })

      savePushPolicy()
      saveFieldMappings(containerValues, setContainerValues, hasShowActOnContactsTab, getConnectedCrm).then((resFieldMappings) => {
        if (resFieldMappings.isComplete) {
          const existingResultState = containerValues.mutationResultState
          existingResultState.attemptToSaveFieldMappingChanges = true
          setContainerValues((containerValues) => ({
            ...containerValues,
            hasUpdates: false,
            mutationResultState: { ...containerValues.mutationResultState, attemptToSaveFieldMappingChanges: true },
          }))
        }
      })
    }

    saveReportMapping(containerValues, setContainerValues, containerValues.clients.crmEntityMappingClient).then((resReportMapping) => {
      if (resReportMapping.isComplete) {
        setContainerValues((containerValues) => ({
          ...containerValues,
          hasUpdates: false,
          mutationResultState: { ...containerValues.mutationResultState, attemptToSaveReportMappingsChanges: true },
        }))
      }
    })

    setContainerValues((containerValues) => ({ ...containerValues, hasUpdates: false }))
  }

  const discard = () => {
    if (hasCRMConnected) {
      loadCRMData(true)
    } else {
      loadNonCRMData()
    }
  }

  const update = (field: keyof State, value: any) => {
    setContainerValues((containerValues) => ({ ...containerValues, [field]: value }))
  }

  const userUpdates = (field: string, value: any) => {
    setContainerValues((containerValues) => ({ ...containerValues, [field]: value, hasUpdates: true }))
  }

  const nestedUpdate = (parent: string, field: string, value: any, isSystemUpdate: boolean) => {
    setContainerValues((containerValues) => ({
      ...containerValues,
      [parent]: { ...containerValues[parent], [field]: value },
      ...(isSystemUpdate ? {} : { hasUpdates: true }),
    }))
  }

  useEffect(() => {
    if (hasCRMConnected) {
      loadCRMData()
    }
  }, [])

  if (pageError) {
    return (
      <PageContainer>
        <PageError center />
      </PageContainer>
    )
  }

  return (
    <DataManagementContext.Provider
      value={{
        values: containerValues,
        update,
        nestedUpdate,
        save,
        discard,
        userUpdates,
        getLastSyncResult,
        updateEntityTypes,
        savePushPolicy,
        refreshCRMData: loadCRMData,
      }}
    >
      <DataManagement loading={containerValues.loading} dataTest={dataTest} loadCRMData={loadCRMData} loadNonCRMData={loadNonCRMData} />
    </DataManagementContext.Provider>
  )
}

DataManagementContainer.displayName = 'DataManagementContainer'
export default DataManagementContainer
