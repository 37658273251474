import { EXTERNAL_WINDOW_PATH, isJest, legacyActonContext, rootContext } from '@const/globals'
import { EXTERNAL_WINDOW_FEATURES } from '@utils/iframeWrapperUrls'

export function getWindowWidth() {
  return window.innerWidth
}

export function getWindowHeight() {
  return window.innerHeight
}

export function setLocationHref(url: string) {
  if (!isJest()) {
    window.location.href = url
  }
}

export function addEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions) {
  window.addEventListener(type, listener, options)
}

export function removeEventListener<K extends keyof WindowEventMap>(
  type: K,
  listener: (this: Window, ev: WindowEventMap[K]) => any,
  options?: boolean | EventListenerOptions
) {
  window.removeEventListener(type, listener, options)
}

export function getHostName() {
  return window.location.hostname
}

export function getWindowPathname() {
  return window.location.pathname
}

export function getWindowSearch() {
  return window.location.search
}

export function getSessionStorageItem(key: string) {
  return window.sessionStorage.getItem(key)
}

export function setSessionStorageItem(key: string, value: string) {
  return window.sessionStorage.setItem(key, value)
}

export function getLocalStorageItem(key: string) {
  return window.localStorage.getItem(key)
}

export function setLocalStorageItem(key: string, value: string) {
  return window.localStorage.setItem(key, value)
}

export function removeLocalStorageItem(key: string) {
  return window.localStorage.removeItem(key)
}

export function removeSessionStorageItem(key: string) {
  return window.sessionStorage.removeItem(key)
}

export function isClient() {
  return typeof window === 'object'
}

export function redirectToClassic() {
  if (!isJest()) {
    const parts = window.location.pathname.split('/classic/')
    if (parts.length === 1 || parts[1].trim().length === 0) {
      window.location.href = `${legacyActonContext}/ng-ui/`
    } else {
      const newUrl = parts[1]
      window.location.href = `${legacyActonContext}/ng-ui/${newUrl}${window.location.search}`
    }
  }
}

export function showAlert(message: string) {
  if (!isJest()) {
    alert(message)
  }
}

export function getGlobalValue(globalValue: string) {
  return (window as any)[globalValue]
}

function openComposerWindow(msgId: string, hasReactWrapper?: boolean) {
  const url =
    (hasReactWrapper
      ? `${rootContext}/classic/if${EXTERNAL_WINDOW_PATH}/${EXTERNAL_WINDOW_FEATURES.EMAIL_MESSAGE}`
      : '/acton/_compose/emailMessage.jsp') + `?send=0&id=${msgId}`
  window.open(url, '_newCompose' + msgId)
}

function openBeeComposerWindow(msgId: string, messageConfigurationType?: string) {
  const url = `${rootContext}/content/emails/${msgId}${messageConfigurationType ? `?messageConfigurationType=${messageConfigurationType}` : ''}`
  window.open(url)
}

export function location() {
  return window.location
}

export const sleep = async (timeout: number) => await new Promise((resolve) => setTimeout(resolve, timeout))

export default {
  getWindowWidth,
  getWindowHeight,
  setLocationHref,
  addEventListener,
  removeEventListener,
  getHostName,
  getWindowPathname,
  getWindowSearch,
  getLocalStorageItem,
  setLocalStorageItem,
  isClient,
  redirectToClassic,
  showAlert,
  getGlobalValue,
  openComposerWindow,
  openBeeComposerWindow,
  location,
}
