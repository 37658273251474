import React, { FC, useCallback, useMemo, useState } from 'react'

import classNames from 'classnames'

import { ButtonType } from '@components/Button'
import { YesNo } from '@components/ConfirmationModal/ConfirmationModal'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { HeaderAction } from '@components/Table/Table'
import { MIN_PAGINATION_SIZE } from '@components/TableV2/components/Pagination/TableV2Pagination'
import { TableV2 } from '@components/TableV2/TableV2'
import Typography, { ModalBodyStyle, TextWeight } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import { scheduledMessagesColumns } from '@src/pages/sms/utils/sms.utils'
import { Message } from '@utils/sms.utils'

import './ScheduledMessages.css'

type Props = {
  messages: Message[]
  loading: boolean
  pageSize: number
  pageIndex: number
  totalCount: number
  fetchData: (pageIndex: number, pageSize: number) => void
  onCancelSends: (msgIds: string[]) => void
  className?: string
  dataTest?: string
}

export const rootClass = 'scheduled-messages'

const ScheduledMessages: FC<Props> = (props: Props) => {
  const { messages, loading, pageIndex, pageSize, totalCount, fetchData, onCancelSends, className = '' } = props
  const [selected, setSelected] = useState<string[]>([])
  const [cancelSendConfirmation, setCancelSendConfirmation] = useState<boolean>(false)
  const { t } = useTranslation()

  const onRowSelectionChanged = useCallback((ids: string[]) => setSelected(ids), [])

  const confirmCancelSend = useCallback(
    (answer: YesNo) => {
      if (answer === YesNo.YES) {
        onCancelSends(selected)
      }
      setCancelSendConfirmation(false)
    },
    [onCancelSends, selected]
  )

  const headerActions = useMemo(
    (): HeaderAction[] => [
      {
        label: 'Cancel send',
        buttonType: ButtonType.CANCEL_SEND,
        onClick: () => setCancelSendConfirmation(true),
      },
    ],
    []
  )

  const emptyState = useMemo(
    () => ({
      size: EmptyListingSize.MEDIUM,
      imgSrc: StaticImageNames.scheduledEmpty,
      headline: t('You don’t have any scheduled messages yet.'),
      text: t(
        'Your scheduled SMS messages will appear here. Scheduling allows you to deliver your message at the perfect moment, every time—even in your recipients’ time zones.'
      ),
      buttonText: t('Create SMS message'),
      buttonURL: `${rootContext}/sms/new/edit`,
      linkText: t('Learn more about SMS scheduling'),
      link: 'https://connect.act-on.com/hc/en-us/articles/1500003063802',
      hideIcon: false,
    }),
    [t]
  )
  return (
    <div className={classNames(rootClass, className)}>
      <DeleteConfirmationModal
        dataTest={`${rootClass}-confirmation-modal`}
        isOpen={cancelSendConfirmation}
        cancelButtonText={t('Go back')}
        deleteButtonText={t("Don't send message")}
        body={
          <>
            <Typography
              text={t(
                "You're about to cancel this SMS message from sending at your scheduled time. Cancelled messages can be found in your drafts. "
              )}
              {...ModalBodyStyle}
              inline
            />
            <Typography text={t("This action can't be undone.")} {...ModalBodyStyle} weight={TextWeight.BOLD} inline />
          </>
        }
        onAnswer={confirmCancelSend}
        title={t('Are you sure you want to cancel this scheduled send?')}
        className={`${rootClass}__cancel-send`}
      />
      <TableV2
        data={messages}
        columns={scheduledMessagesColumns}
        loading={loading}
        enableSorting={true}
        enableCheckbox={true}
        emptyState={emptyState}
        rowUniqueIdKey={'launchId'}
        headerActions={headerActions}
        paginationState={{ pageIndex, pageSize, controlledPageCount: Math.ceil(totalCount / pageSize) }}
        enablePaginate={totalCount >= MIN_PAGINATION_SIZE}
        fetchData={fetchData}
        onRowSelectionChanged={onRowSelectionChanged}
      />
    </div>
  )
}

export default ScheduledMessages
