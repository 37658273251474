import { useCallback, useMemo, useRef } from 'react'

import { SvgNames } from '@components/Svg'
import { HeaderAction, TableProps } from '@components/Table/Table'
import { TableV2Props } from '@components/TableV2/tableV2TS/interfaces'
import { HeaderAction as HeaderActionV2 } from '@components/TableV2/tableV2TS/interfaces'
import { useTranslation } from '@const/globals'
import { UserResponse } from '@graphql/types/query-types'
import { useSalesUsers } from '@src/pages/Settings/Users/context/SalesUsersContext'
import { useAllUsers } from '@src/pages/Settings/Users/context/UsersContext'
import getRowActions from '@src/pages/Settings/Users/tabs/SalesUsers/utils/salesUsers.rowActions'

type Props = {
  setUserToEditLaunchPermissions: (user: UserResponse) => void
  handleUpdateCRM: (id: string) => void
}

export const useGetTableAdminProps: (props: Props) => Partial<TableProps> = ({ setUserToEditLaunchPermissions, handleUpdateCRM }) => {
  const { t } = useTranslation()
  const { onDeleteUsers, handleResendVerification, onEditProfile, isAdministrator } = useAllUsers()
  const { refetchRefs, enableLaunch } = useSalesUsers()
  const selectedUsers = useRef<UserResponse[]>([])

  const onRowSelectionChanged = useCallback<(ids: string[], rows: { original: UserResponse; id: string }[]) => void>((ids, rows) => {
    selectedUsers.current = rows.filter(({ id }) => ids.includes(id)).map(({ original }) => original)
  }, [])

  const onDelete = useCallback<(user?: UserResponse) => void>((user) => {
    const refetch = () => {
      Object.values(refetchRefs).forEach((f) => f())
    }
    if (user) {
      onDeleteUsers([user], refetch, true)
    } else if (selectedUsers.current.length) {
      onDeleteUsers(selectedUsers.current, refetch, true)
    }
  }, [])

  const onResend = useCallback(
    (user: UserResponse) => {
      return handleResendVerification([user])
    },
    [handleResendVerification]
  )

  const onEdit = useCallback(
    (user: UserResponse) => {
      onEditProfile(user, refetchRefs.refreshSalesUsers)
    },
    [onEditProfile]
  )

  const headerActions = useMemo<HeaderAction[]>(
    () => [
      {
        label: t('Delete'),
        icon: SvgNames.delete,
        onClick: () => onDelete(),
      },
    ],
    [t, onDelete]
  )

  const rowActions = useMemo(
    () => getRowActions({ t, onEdit, onResend, onDelete, onEditLaunch: setUserToEditLaunchPermissions, handleUpdateCRM, enableLaunch }),
    [onEdit, onResend, onDelete, handleUpdateCRM, enableLaunch]
  )

  return useMemo<Partial<TableProps>>(
    () =>
      isAdministrator
        ? {
            rowActions,
            headerActions,
            useCheckboxes: true,
            useHeaderCheckbox: true,
            onRowSelectionChanged: onRowSelectionChanged,
          }
        : {},
    [rowActions, headerActions, onRowSelectionChanged, isAdministrator]
  )
}

export const useGetTableV2AdminProps: (props: Props) => Partial<TableV2Props<UserResponse>> = ({
  setUserToEditLaunchPermissions,
  handleUpdateCRM,
}) => {
  const { t } = useTranslation()
  const { onDeleteUsers, handleResendVerification, onEditProfile, isAdministrator } = useAllUsers()
  const { refetchRefs, enableLaunch } = useSalesUsers()
  const selectedUsers = useRef<UserResponse[]>([])

  const onRowSelectionChanged = useCallback<(ids: string[], rows: { original: UserResponse; id: string }[]) => void>((ids, rows) => {
    selectedUsers.current = rows.filter(({ id }) => ids.includes(id)).map(({ original }) => original)
  }, [])

  const onDelete = useCallback<(user?: UserResponse) => void>((user) => {
    const refetch = () => {
      Object.values(refetchRefs).forEach((f) => f())
    }
    if (user) {
      onDeleteUsers([user], refetch, true)
    } else if (selectedUsers.current.length) {
      onDeleteUsers(selectedUsers.current, refetch, true)
    }
  }, [])

  const onResend = useCallback(
    (user: UserResponse) => {
      return handleResendVerification([user])
    },
    [handleResendVerification]
  )

  const onEdit = useCallback(
    (user: UserResponse) => {
      onEditProfile(user, refetchRefs.refreshSalesUsers)
    },
    [onEditProfile]
  )

  const headerActions = useMemo<HeaderActionV2[]>(
    () => [
      {
        label: t('Delete'),
        icon: SvgNames.delete,
        onClick: () => onDelete(),
      },
    ],
    [t, onDelete]
  )

  const rowActions = useMemo(
    () => getRowActions({ t, onEdit, onResend, onDelete, onEditLaunch: setUserToEditLaunchPermissions, handleUpdateCRM, enableLaunch }),
    [onEdit, onResend, onDelete, handleUpdateCRM, enableLaunch]
  )

  return useMemo<Partial<TableV2Props<UserResponse>>>(
    () =>
      isAdministrator
        ? {
            rowActions,
            headerActions,
            useCheckboxes: true,
            useHeaderCheckbox: true,
            onRowSelectionChanged: onRowSelectionChanged,
          }
        : {},
    [rowActions, headerActions, onRowSelectionChanged, isAdministrator]
  )
}
