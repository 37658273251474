import { useEffect, useState } from 'react'

import { useAccountSettings } from '@utils/account/account.utils'

interface State {
  hasCRMConnected: boolean
  connectorType: CRMConnectorType
}

export enum CRMConnectorType {
  MS_DYNAMICS = 'MS Dynamics',
  NETSUITE = 'NetSuite',
  SALESFORCE = 'Salesforce',
  SUGAR = 'Sugar',
  SALESLOGIC = 'Saleslogic',
  ZENDESK = 'Zendesk',
  NOT_CONNECTED = 'Not Connected',
}

export const getCrmConnectorType = (isMSDyn: boolean, isNS: boolean, isSF: boolean, isSugar: boolean, isSL: boolean, isZendesk: boolean) => {
  switch (true) {
    case isMSDyn:
      return CRMConnectorType.MS_DYNAMICS
    case isNS:
      return CRMConnectorType.NETSUITE
    case isSF:
      return CRMConnectorType.SALESFORCE
    case isSugar:
      return CRMConnectorType.SUGAR
    case isSL:
      return CRMConnectorType.SALESLOGIC
    case isZendesk:
      return CRMConnectorType.ZENDESK
    default:
      return CRMConnectorType.NOT_CONNECTED
  }
}

const useCRM = () => {
  const { getConnectedCrm, isMSDyn, isNS, isSF, isSugar, isSL, isZendesk } = useAccountSettings()
  const [state, setState] = useState<State>({
    hasCRMConnected: getConnectedCrm !== 'UNSET',
    connectorType: getCrmConnectorType(isMSDyn, isNS, isSF, isSugar, isSL, isZendesk),
  })

  useEffect(() => {
    setState((state) => ({ ...state, hasCRMConnected: getConnectedCrm !== 'UNSET' }))
  }, [getConnectedCrm])

  useEffect(() => {
    setState((state) => ({ ...state, connectorType: getCrmConnectorType(isMSDyn, isNS, isSF, isSugar, isSL, isZendesk) }))
  }, [isMSDyn, isNS, isSF, isSugar, isSL, isZendesk])

  return state
}

export default useCRM
