import React, { FC, useMemo, useState } from 'react'

import classNames from 'classnames'

import { splitActions } from '@components/Table/components/TableActionHeader'
import TableRowDropDownActions from '@components/Table/components/TableRowAction/components/TableRowDropDownActions/TableRowDropDownActions'
import TableRowPrimaryAction from '@components/Table/components/TableRowAction/components/TableRowPrimaryAction/TableRowPrimaryAction'
import { RowAction } from '@components/Table/Table'
import { ActionType } from '@components/TableV2/tableV2TS/enums'

import './TableRowAction.css'

interface Props {
  className?: string
  dataTest?: string
  rowActions: RowAction[]
  row: any
  onToggleDropDown?: (open: boolean) => void
}

const rootClass = 'table-row-action'

const TableRowAction: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '', onToggleDropDown, rowActions = [], row = {} } = props
  const [isVisible, setVisible] = useState(false)

  const [primaryRowActions, secondaryRowActions]: RowAction[][] = useMemo(() => splitActions(rowActions, row, ActionType.Row), [rowActions, row])

  const renderPrimaryActions = useMemo(
    () =>
      primaryRowActions.map((action) => <TableRowPrimaryAction key={`primary-${action.label}`} dataTest={dataTest} rowAction={action} row={row} />),
    [primaryRowActions, row]
  )

  const renderSecondaryActions = useMemo(() => {
    if (secondaryRowActions.length > 0) {
      return (
        <TableRowDropDownActions
          dataTest={dataTest}
          rowActions={secondaryRowActions}
          row={row}
          onToggleDropDown={(open) => {
            onToggleDropDown?.(open)
            setVisible(open)
          }}
        />
      )
    }
  }, [secondaryRowActions, row])

  if (!primaryRowActions.length && !secondaryRowActions.length) {
    return null
  }

  return (
    <span className={`${rootClass}__container`}>
      <div className={classNames(rootClass, className, { [`${rootClass}__visible`]: isVisible })}>
        {renderPrimaryActions}
        {renderSecondaryActions}
      </div>
    </span>
  )
}

export default TableRowAction
