import React, { FC, useMemo } from 'react'

import classNames from 'classnames'

import Svg, { SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'
import { columnStates } from '@src/pages/importcontacts/components/ColumnState/ColumnState.utils'
import { ColumnStateType } from '@src/pages/importcontacts/context/ImportContactsContext'

import './ColumnState.css'

type Props = {
  className?: string
  dataTest?: string
  state: ColumnStateType
}

const rootClass = 'column-state'

const ColumnState: FC<Props> = (props: Props) => {
  const { state, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const { text, textBold, ...svgProps } = useMemo(() => columnStates[state], [state])

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Tooltip textBold={textBold} trigger={<Svg type={SvgType.LARGER_ICON} {...svgProps} />}>
        {t(text)}
      </Tooltip>
    </div>
  )
}

export default ColumnState
