import { TFunction } from 'i18next'

import { ListingPageItem } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { PreProcessedList } from '@complex/ListPickerModalV2/utils/interfaces/ListPickerModalInterfaces'
import { UNIFIED_CONTACTS_ID } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import { Column } from '@components/ColumnsOrderModal/components/DraggableColumn/DraggableColumn'
import { uuidColumns } from '@components/ContactsDetails/utils/ContactsDetails.utils'
import { Status } from '@components/StatusToast/StatusToast'
import { legacyActonContext, rootContext } from '@const/globals'
import { LabelDto, SubTypeDto } from '@graphql/types/microservice/categorization-types'
import { SetHadSuccessfulSyncMutation, SetHadSuccessfulSyncMutationVariables, SyncNowQuery } from '@graphql/types/microservice/crm-types'
import { ExportSyncJobDto, FtpFileDto, ImportSyncJobDto } from '@graphql/types/microservice/entity-upload-types'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { PageInput } from '@graphql/types/microservice/segment-types'
import { ListPageState, ListPageStatusToast } from '@interface/ListPage.context'
import {
  BouncesSegments,
  getSegmentName,
  isBounceSegment,
  ItemSubType,
  ItemType,
  ITEM_SUB_TYPE_FROM_DTO,
  smsBouncesVerbs,
  ItemSubTypeDtoName,
  bouncesVerbs,
} from '@utils/categorization'
import { Contact } from '@utils/contact/contact.constants'
import { DATACARD_LIST } from '@utils/contactSegments/dataCards.utils'
import { CRM_STATUS, CRMSourceOption } from '@utils/crm.utils'
import { formatDate } from '@utils/date'
import { EMPTY_LISTING_OPTIONS } from '@utils/ListPage.context.default'
import { setItem } from '@utils/sessionStorage'
import { FetchPromise } from '@utils/types'

export const TIME_BEFORE_OPENING = 1000
export const CRM_LIMIT_REACHED_WARNING = 'Your CRM segment has reached the Salesforce limit of 2,000 contacts. Some contacts may not sync correctly.'
export const CRM_MISSING_ID_WARNING_INFO = 'Each CRM report requires Lead ID or Contact ID data in order to be synced.'

export const CRM_MISSING_ID_WARNING_TITLE = 'This report is missing info'
export const CRM_MISSING_ID_WARNING = `${CRM_MISSING_ID_WARNING_TITLE}. ${CRM_MISSING_ID_WARNING_INFO}`

export const CRM_ON_GOING_SYNC = 'This segment syncs with CRM'

export enum ContactSegmentsSession {
  FILTER = 'contactSegments:filter',
  FOLDER = 'contactSegments:folder',
  SEGMENT_DETAILS = 'contactSegments:showDetails',
  SEGMENT_DETAILS_TAB = 'contactSegments:showSegmentDetailsTab',
  TAG = 'contactSegments:tag',
  COLLAPSED_MENU = 'contactSegments:collapsedMenu',
  UPGRADE_BANNER = 'contactSegments:upgradeBanner',
  EXPANDED_SEGMENTS = 'contactSegments:expandedSegments',
  EDITED_SEGMENT = 'contactSegments:editedSegment',
  DATACARD_TOTAL_CONTACTS = 'contactSegments:dataCardTotalContacts',
  DATACARD_ENGAGED_CONTACTS = 'contactSegments:dataCardEngagedContacts',
  FORMS_UPDATE_NOTIFICATION = 'contactSegments:formsUpdateNotificationDate',
  FORMS_UPDATE_DONE = 'contactSegments:formsUpdateDone',
  INCOMING_STATUS_TOAST = 'contactSegments:incomingStatusToast',
}

export enum EXPRESSION_TYPE {
  PROFILE = 'profile',
  BEHAVIOR = 'behavior',
  SCORE = 'score',
  SYSTEM = 'system',
  SMS = 'sms',
  CRM = 'crm',
  EXTENDED_FIELD = 'extendedField',
  SUBSCRIPTION = 'subscription',
}

export enum PERIOD_GROUPED_BY {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Quarter = 'QUARTER',
  Yearly = 'YEARLY',
}

export enum EXPRESSION_EVALUATION_TYPE {
  CUSTOM = 'CUSTOM',
  AND = 'AND',
  OR = 'OR',
  SYSTEM = 'SYSTEM',
}

export interface DataCardsModalInfo {
  name: string
  categories: string[]
  contactsUsed: number[]
  lastUpdated: string
}

export interface ActiveContactsModalInfo extends DataCardsModalInfo {
  contactsAvailable: number[]
}

export interface SegmentExpression {
  type: EXPRESSION_TYPE
}

export interface SegmentDefinition {
  expressionEvaluationType: EXPRESSION_EVALUATION_TYPE
  customBooleanExpression: null
  type: string
  recIds?: string[]
  expressions: SegmentExpression[]
  patterns?: string[]
  assetsDisplayNames?: Record<string, string> | null
}

export interface Campaign {
  id: string
  name: string
}

export interface CrmCampaign {
  Id: string
  Name: string
}

export interface CrmEntity {
  entityType: string
  displayName: string
  entityFields: CrmEntityField[]
}

export interface CrmEntityField {
  fieldName: string
  displayName: string
  pickListValues: string[] | null
}

export interface SegmentExpressionProfile {
  condition: {
    comparison: string
    count?: number
    values?: string[]
    value?: SegmentExpressionProfileDateValue & number
    rangeStart?: SegmentExpressionProfileDateValue & number
    rangeEnd?: SegmentExpressionProfileDateValue & number
    type: CONDITION_TYPE
  }
  field: string
  type: string
}

export interface SegmentExpressionProfileDateValue {
  textValue?: string
  type: PROFILE_DATE_VALUE
  relativePeriod?: {
    type: string
    count?: number
    direction?: string
  }
}

export interface SegmentExpressionBehavior {
  behavior: string
  condition: {
    comparison: string
    count?: number
    assetIds?: string[]
    type: BEHAVIOR_CONDITION_TYPE
  }
  timePeriod: {
    selection?: BehaviorTimePeriodSelection
    type: BEHAVIOR_TIME_PERIOD_OPTIONS
  }
  type: string
}

export interface BehaviorTimePeriodSelection {
  daysAgo?: number
  monthsAgo?: number
  type: BEHAVIOR_MONTH_TYPE
  month?: number
  year?: number
}

export interface SegmentExpressionScore {
  condition: {
    type: string
    value: string
    comparison: string
  }
  campaignId: string | null
  scoreSheetId: string
  type: string
}

export interface SegmentExpressionSystem {
  condition: {
    comparison: string
    dateFormat: string
    type: CONDITION_TYPE
    value: SegmentExpressionProfileDateValue
  }
  field: string
}

export interface SegmentExpressionSms {
  condition: string
  field: string
}

export interface SegmentExpressionCrm {
  condition: {
    comparison: string
    values?: string[]
    value?: number
    type: CONDITION_TYPE
  }
  field: string
  entityType: string
}

export interface SegmentExpressionSubscription {
  field: string
  type: string
  value: string
}
export interface SubscriptionField {
  description: string
  displayName: string
  fieldName: string
}

export interface ScoreSheet {
  id: string
  name: string
}

export interface SupportedBehavior {
  behaviorType: string
  displayName: string
}

export enum PROFILE_DATE_VALUE {
  RELATIVE = 'relative',
  ABSOLUTE = 'absolute',
}

export enum BEHAVIOR_CONDITION_TYPE {
  COUNT = 'count',
  NONE = 'none',
  ASSETS = 'assets',
  SOME = 'some',
}

export enum CONDITION_TYPE {
  TEXT_LIST = 'textList',
  TEXT_EMPTY = 'textEmpty',
  TEXT_NOT_EMPTY = 'textNotEmpty',
  NUM_VALUE = 'numericValue',
  NUM_RANGE = 'numericRange',
  DATE_VALUE = 'dateValue',
  DATE_RANGE = 'dateRange',
}

export enum BEHAVIOR_TIME_PERIOD_OPTIONS {
  MONTH = 'priorMonth',
  ALL_TIME = 'allTime',
  SINCE_DAY = 'sinceDay',
}

export enum BEHAVIOR_MONTH_TYPE {
  ABSOLUTE_MONTH = 'absoluteMonth',
  RELATIVE_MONTH = 'relativeMonth',
}

export enum ExecutionStatus {
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING',
}

const bouncesSegments: { [key: string]: string } = {
  [BouncesSegments.SOFT_BOUNCES]:
    'Contacts with delivery failure for typically temporary reasons, such as a recipient mail server being off line. Email delivery will be attempted, until the soft bounce limit (LINK) is reached.',
  [BouncesSegments.HARD_BOUNCES]:
    'Contacts with delivery failure for permanent reasons, such as an invalid email address. No email will be sent to these contacts.',
  [BouncesSegments.OPT_OUT]: 'Contacts who have explicitly opted out. No email will be sent to these contacts.',
  [BouncesSegments.SMS_OPT_IN]: 'Contacts who have explicitly opted in to SMS messages.',
  [BouncesSegments.SMS_OPT_OUT]: 'Contacts who have explicitly opted out of SMS messages. No SMS messages will be sent to these contacts.',
  [BouncesSegments.SPAM_COMPLAINTS]: 'Contacts who have previously reported an email as spam. No email will be sent to these contacts.',
  [BouncesSegments.INACTIVE]: 'Contacts who have reached the specified soft bounce limit. No email will be sent to these contacts.',
  Subscriptions: 'Contacts who have opted in to any of your subscription categories.',
}

export const systemSegments: { [key: string]: string } = {
  'Affiliated With Customers': "Includes all contacts of type 'Customer' synced with your CRM.",
  'Affiliated With Leads': "Includes all contacts of type 'Lead' synced with your CRM.",
  'Affiliated With Prospects': "Includes all contacts of type 'Prospect' synced with your CRM.",
  'All Act-On Contacts': 'Includes all of your contacts.',
  'All MS Dynamics Contacts': "Includes all contacts of type 'Contact' synced with your CRM.",
  'All MS Dynamics Leads': "Includes all contacts of type 'Leads' synced with your CRM.",
  'All Salesforce Contacts': "Includes all contacts of type 'Contact' synced with your CRM.",
  'All Salesforce Leads': "Includes all contacts of type 'Lead' synced with your CRM.",
  'All SugarCRM Contacts': "Includes all contacts of type 'Contact' synced with your CRM.",
  'All SugarCRM Leads': "Includes all contacts of type 'Leads' synced with your CRM.",
  'All SugarCRM Targets': "Includes all contacts of type 'Target' synced with your CRM.",
  'All Zendesk Contacts': "Includes all contacts of type 'Contact' synced with your CRM.",
  'All Zendesk Leads': "Includes all contacts of type 'Leads' synced with your CRM.",
  Unaffiliated: 'Includes all contacts synced with your CRM not identified as a Lead, Prospect or Customer.',
  ...bouncesSegments,
}

export type ContactSegmentsState = ListPageState<Segment> & {
  hasToExpandFolders: number[]
  campaigns: Record<string, string>
  copySegmentsDone?: boolean
  crmSourceOptions?: CRMSourceOption[]
  allContactsUpgradeDone?: boolean
  crmMapping: Record<string, { displayName: string; entityFields: Record<string, string> }>
  dataCardsModalEmptyState: boolean
  dataCardActive: DATACARD_LIST | undefined
  dataCardsModalInfo: ActiveContactsModalInfo
  emptyListingOption: EMPTY_LISTING_OPTIONS
  exportedFile: string
  importContactsCount?: number
  itemTypesUsed: Record<string, number>
  loadingChart: boolean
  loadingSessionData: boolean
  metadataLoading: boolean
  scoreSheets: Record<string, string>
  segmentContacts: PageInput
  segmentDefinition: SegmentDefinition
  showDetails: Segment | undefined
  subscriptionMapping: Record<string, string>
  supportedBehaviors: Record<string, string>
  mappedIdsToUuids: Record<string, string>
  showUpgradeBanner?: boolean
  showDoneCopySegmentsModal?: boolean
  manageFtpSyncSegmentId?: string
  ftpSyncJobs: ImportSyncJobDto[]
  ftpExportSyncJobs: ExportSyncJobDto[]
  ftpFileImportLogTarget?: string
  isFtpConnectorActive: boolean
  ftpFiles: FtpFileDto[]
  loadingFtpFiles: boolean
  selectingFtpFile: boolean
  selectingExportAction: boolean
  selectingFtpExports: boolean
  manageFtpExportSyncSegmentId?: string
  creatingFtpFile: boolean
}

export interface Segment {
  classicExternalId?: string
  id: number
  externalId: string
  ftpData?: {
    import: {
      syncJobs: ImportSyncJobDto[]
      executionStatus: FtpExecutionStatus
    }
    export: {
      syncJobs: ExportSyncJobDto[]
      executionStatus: FtpExportExecutionStatus
    }
  }
  lastCountDate?: string
  formWithCrmPushErrors?: string
  name: string
  parent?: string
  recordsCount: number
  subRows?: Segment[]
  tags: LabelDto[]
  type: string
  isEditable: boolean
  isFavorite: boolean
  position: number
  folderId?: number
  createdTime?: string
  updatedTime?: string
  itemType: ItemType
  subTypes?: ItemSubType[]
  syncedSegmentId?: number
  crmStatus?: CRM_STATUS | null
  crmErrorDetails?: string
  hasSplitsToRegenerate?: boolean
  regenerationTime?: number
  subTypeDTO?: [SubTypeDto]
}

export interface PreviewImportSyncJob {
  importSyncJobId: number
  filePath: string
  lastExecution: number
}

export interface PreviewExportSyncJob {
  exportSyncJobId: number
  filePath: string
  lastExecution: number
}

export interface FtpExecutionStatus {
  lastExecution: number
  running: boolean
  status: ExecutionStatus
  previewImportSyncJobs: PreviewImportSyncJob[]
}

export interface FtpExportExecutionStatus {
  lastExecution: number
  running: boolean
  status: ExecutionStatus
  previewExportSyncJobs: PreviewExportSyncJob[]
}

export const getSegmentPath = (targetExternalId: string, segments: Segment[]): Segment[] =>
  segments.reduce((path: Segment[], segment: Segment) => {
    if (segment.externalId === targetExternalId) {
      return [segment]
    }
    if (segment.subRows) {
      const subPath = getSegmentPath(targetExternalId, segment.subRows)
      return subPath.length > 0 ? [...subPath, segment] : path
    }
    return path
  }, [])

export const sortSegmentsUtils = (selectedSegments: Segment[], insertAt: number, parentRowId: string | undefined, segments: Segment[]) => {
  const replaceSegments = (segmentsToSort: Segment[], segments: Segment[]) => [
    ...segments.slice(0, insertAt).filter((segment) => !segmentsToSort.find(({ id }) => id === segment.id)),
    ...segmentsToSort,
    ...segments.slice(insertAt).filter((segment) => !segmentsToSort.find(({ id }) => id === segment.id)),
  ]

  if (parentRowId) {
    const parentSegment = getSegmentByIndex(parentRowId, segments)
    if (parentSegment && parentSegment.subRows) {
      const subRows = replaceSegments(selectedSegments, parentSegment.subRows)
      return setSegmentByIndex({ ...parentSegment, subRows }, parentRowId, segments)
    }
    return segments
  } else {
    return replaceSegments(selectedSegments, segments)
  }
}

export const setSegmentByIndex = (segment: Segment, index: string | number[], segments: Segment[]): Segment[] => {
  const [currentIndex, ...indexRest] = typeof index !== 'string' ? index : index.split('.').map((index) => parseInt(index))
  const currentSegment = segments[currentIndex]
  return [
    ...segments.slice(0, currentIndex),
    currentSegment.subRows && indexRest.length > 0
      ? { ...currentSegment, subRows: setSegmentByIndex(segment, indexRest, currentSegment.subRows) }
      : { ...segment },
    ...segments.slice(currentIndex + 1),
  ]
}

export const getSegmentByIndex = (index: string | number[], segments: Segment[]): Segment | undefined => {
  const [currentIndex, ...indexRest] = typeof index === 'string' ? index.split('.').map((index) => parseInt(index)) : index
  if (segments.length > currentIndex) {
    const currentSegment = segments[currentIndex]
    if (indexRest.length === 0) {
      return currentSegment
    }
    if (currentSegment.subRows) {
      return getSegmentByIndex(indexRest, currentSegment.subRows)
    }
  }
}

export const buildSegmentHierarchy = (segments: Segment[]) => {
  const toAnalyzeSegments = segments.filter(({ parent }) => !parent)
  const toAnalyzeSubSegments = segments.filter(({ parent }) => parent)
  let orphanSegments: Segment[] = []
  toAnalyzeSubSegments.forEach((subSegment) => {
    const foundParentIndex = toAnalyzeSegments.findIndex(({ externalId }) => externalId === subSegment.parent)
    if (foundParentIndex >= 0) {
      toAnalyzeSegments[foundParentIndex].subRows = [...(toAnalyzeSegments[foundParentIndex].subRows ?? []), subSegment]
    } else {
      const foundParentIndex = toAnalyzeSubSegments.findIndex(({ externalId }) => externalId === subSegment.parent)
      if (foundParentIndex >= 0) {
        toAnalyzeSubSegments[foundParentIndex].subRows = [...(toAnalyzeSubSegments[foundParentIndex].subRows ?? []), subSegment]
      } else {
        orphanSegments = [...orphanSegments, subSegment]
      }
    }
  })
  return [...toAnalyzeSegments, ...orphanSegments]
}

export const buildSegments = (dataList?: any[]): Segment[] => (dataList ? dataList.map(buildSegment) : [])

export const buildSegment = (dataItem: any): Segment => {
  const item = parseItem(dataItem)
  const subTypes = dataItem.subTypeDTO?.map(({ name }: SubTypeDto) => ITEM_SUB_TYPE_FROM_DTO[name as ItemSubTypeDtoName])
  const externalId = dataItem.externalId === 'st' ? smsBouncesVerbs[BouncesSegments.SMS_OPT_OUT] : dataItem.externalId
  const segment = {
    ...item,
    classicExternalId: item.id,
    name: getSegmentName(externalId, item?.name),
    crmStatus: dataItem.crmStatus,
    crmErrorDetails: dataItem.crmErrorDetails,
    syncedSegmentId: dataItem.syncedSegmentId,
    lastCountDate: formatDate(item?.lastCountDate),
    externalId,
    id: dataItem?.id,
    isFavorite: dataItem?.isFavorite,
    position: dataItem?.position,
    recordsCount: parseInt(item?.recordsCount),
    folderId: dataItem?.folderId,
    // Old backend version sends updateTime in seconds, new version will send in milliseconds, the *1000 can be removed later
    createdTime: formatDate(`${dataItem?.createdTime.toString().length === 10 ? dataItem?.createdTime * 1000 : dataItem?.createdTime}`),
    updatedTime: formatDate(`${dataItem?.updatedTime.toString().length === 10 ? dataItem?.updatedTime * 1000 : dataItem?.updatedTime}`),
    tags: dataItem?.labels,
    subTypes,
  } as Segment
  const isBounce = isBounceSegment(externalId)
  return { ...segment, itemType: isBounce ? ItemType.BOUNCE : ItemType.SEGMENT, isEditable: item?.isEditable && !isBounce }
}

export const parseItem = (dataItem: any) => {
  const { item } = dataItem || {}
  return JSON.parse(item ?? 'null')
}

export const goEditSegment = (segment: Segment, currentUrl: string, options?: { search?: string; searchAll?: boolean }) => {
  const { search, searchAll } = { ...options }
  if (segment.isEditable) {
    onEditSegment(segment)
    const searchTerm = search ? `?search=${search}${searchAll ? '&searchAll' : ''}` : ''
    const redirectUrl = encodeURIComponent(`${currentUrl}${searchTerm}`)
    window.open(`${rootContext}/classic/segment/${segment.externalId}/${segment.externalId}?back=${redirectUrl}`, '_self')
  }
}

export const onEditSegment = (segment: Segment) => setItem(ContactSegmentsSession.EDITED_SEGMENT, JSON.stringify(segment))

export const goEditCRMSourcedSegment = (segment?: Segment) =>
  window.open(`${rootContext}/contacts/crm${!!segment ? '?id=' + segment.externalId : ''}`, '_self')

export const goEditCRMSourcedList = (segment?: PreProcessedList) =>
  window.open(`${rootContext}/contacts/crm${!!segment ? '?id=' + segment.id : ''}`, '_self')

export const getListDownloadLink = ({ name = '', externalId = '', classicExternalId }: Segment, isZip?: boolean) => {
  const actualExternalId = Object.values(bouncesVerbs).includes(externalId) && classicExternalId ? classicExternalId : externalId
  return `${legacyActonContext}/downloadlist?name=${name}&srcId=${actualExternalId}${isZip ? '&zipFlag=Y' : ''}`
}

export const syncCrmSegment = async (
  setSuccessfulSyncFlagRequest: (params: SetHadSuccessfulSyncMutationVariables) => FetchPromise<SetHadSuccessfulSyncMutation>,
  syncNowRequest: () => FetchPromise<SyncNowQuery>,
  t: TFunction,
  syncedSegmentId: number | undefined,
  showStatusToast?: (statusToast: ListPageStatusToast) => void
) => {
  if (syncedSegmentId) {
    const { errors: setFlagErrors } = await setSuccessfulSyncFlagRequest({ successful: false, syncedSegmentId })
    const { errors: syncNowErrors } = await syncNowRequest()
    if (showStatusToast) {
      if (!setFlagErrors && !syncNowErrors) {
        showStatusToast?.({
          title: t("We're syncing your segment with CRM now."),
          statusMessage: t('This may take some time. Check back later for an updated contact count.'),
          status: Status.SUCCESS,
          showStatusToast: true,
        })
      } else {
        showStatusToast?.({
          statusMessage: t('Something went wrong on our end. Please try again.'),
          status: Status.FAIL,
          showStatusToast: true,
        })
      }
    }
  }
}

export const saveSelectedListsToLocalStorage = (selectedLists: ListingPageItem[]) => {
  const listData = selectedLists.map((segment) => ({
    isList: true,
    srcId: segment.externalId,
    baseId: UNIFIED_CONTACTS_ID,
    srcName: segment.name,
    size: segment.recordsCount,
  }))

  localStorage.setItem('contactSegments:selectedLists', JSON.stringify(listData))
}

export const saveSelectedContactsToLocalStorage = (
  selectedContacts: Contact[],
  contactHeaders: Column[],
  unifiedListFieldMappings: UnifiedListFieldMapping[]
) => {
  const filteredContactHeaders = contactHeaders.filter(({ name }) => !uuidColumns.includes(name))

  const getColumnIndex = (standardFieldKey: string, defaultDisplayName: string) => {
    const fieldMapping = unifiedListFieldMappings.find((fieldMapping) => fieldMapping.standardFieldKey === standardFieldKey)
    return filteredContactHeaders.findIndex((header) => header.name === (fieldMapping?.displayName ?? defaultDisplayName))
  }

  const firstNameIndex = getColumnIndex('firstName', 'First Name')
  const lastNameIndex = getColumnIndex('lastName', 'Last Name')
  const emailIndex = getColumnIndex('email', 'Email')

  const contactData = selectedContacts.map(({ fields, recId }) => ({
    name: `${firstNameIndex !== -1 ? fields[firstNameIndex] : ''} ${lastNameIndex !== -1 ? fields[lastNameIndex] : ''}`.trim(),
    email: emailIndex !== -1 ? fields[emailIndex] : '',
    srcId: UNIFIED_CONTACTS_ID,
    id: recId,
  }))

  localStorage.setItem('contactSegments:selectedContacts', JSON.stringify(contactData))
}
