import getUnifiedListFieldMappings from '@graphql/microservices/list/getUnifiedListFieldMappings'
import { GetUnifiedListFieldMappingsQuery, GetUnifiedListFieldMappingsQueryVariables } from '@graphql/types/microservice/list-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export type GetUnifiedListFieldMappingsRequestType = () => FetchPromise<GetUnifiedListFieldMappingsQuery>

export interface CopySegmentsListServiceRequests {
  getUnifiedListFieldMappingsRequest: GetUnifiedListFieldMappingsRequestType
}

export const useCopySegmentsListServiceRequests = (): CopySegmentsListServiceRequests => {
  const {
    client: { query },
  } = useMicroserviceClient({ serviceName: MicroserviceClients.LIST })

  const getUnifiedListFieldMappingsRequest = async () =>
    await query<GetUnifiedListFieldMappingsQuery, GetUnifiedListFieldMappingsQueryVariables>({
      query: getUnifiedListFieldMappings,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    })

  return {
    getUnifiedListFieldMappingsRequest,
  }
}
