import getAttendancePercentage from '@graphql/microservices/entity-join/getAttendancePercentage'
import getConversionRate from '@graphql/microservices/entity-join/getConversionRate'
import {
  GetAttendancePercentageQuery,
  GetAttendancePercentageQueryVariables,
  GetConversionRateQuery,
  GetConversionRateQueryVariables,
  WebinarType,
} from '@graphql/types/microservice/entity-join-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

interface GetAttendanceAndConversionRateLineChartQueriesResponse {
  getConversionRateRequest: (id: string) => FetchPromise<GetConversionRateQuery>
  getWebinarAttendanceRequest: (webinarId: string, webinarType: WebinarType) => Promise<number>
}

export const useAttendanceAndConversionRateLineChartQueries = (): GetAttendanceAndConversionRateLineChartQueriesResponse => {
  const { client: entityJoinClient } = useMicroserviceClient({ serviceName: MicroserviceClients.ENTITY_JOIN })
  const getConversionRateRequest = (id: string) =>
    entityJoinClient.query<GetConversionRateQuery, GetConversionRateQueryVariables>({
      query: getConversionRate,
      variables: {
        listId: id,
      },
    })

  const getWebinarAttendanceRequest = (webinarId: string, webinarType: WebinarType) =>
    entityJoinClient
      .query<GetAttendancePercentageQuery, GetAttendancePercentageQueryVariables>({
        query: getAttendancePercentage,
        variables: {
          webinarId,
          webinarType,
        },
      })
      .then(({ data }) => data.getAttendancePercentage as number)

  return {
    getConversionRateRequest,
    getWebinarAttendanceRequest,
  }
}
