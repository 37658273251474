import React from 'react'
import { Cell } from 'react-table'

import { ExtendedItemDto } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { TableColumn } from '@components/Table/Table'
import Typography from '@components/Typography/Typography'
import { InvalidCRMStatus, InvalidSource } from '@src/pages/ContactSegments/components/AddContactsFromCRM/AddContactsFromCRMContext'
import InvalidSourceWarning from '@src/pages/ContactSegments/components/AddContactsFromCRM/components/SelectCRMSources/components/InvalidSourceWarning/InvalidSourceWarning'

const renderSourceName = ({ name }: ExtendedItemDto, crmStatus: InvalidCRMStatus | undefined) => (
  <div className={`select-crm-sources__source-name`}>
    {!!crmStatus && <InvalidSourceWarning crmStatus={crmStatus} />}
    <Typography title={name} text={name} />
  </div>
)

export const getSelectCRMSourcesColumns = (sources: InvalidSource[]): TableColumn[] => [
  {
    Header: 'Source',
    accessor: 'name',
    align: 'left',
    flexColumn: true,
    Cell: ({ row: { original } }: Cell<ExtendedItemDto>) => {
      const crmStatus = sources.find(({ sourceIdentifier }) => sourceIdentifier === original.id)?.status
      return renderSourceName(original, crmStatus)
    },
  },
]
