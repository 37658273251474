import { WebhookSubscription } from '@graphql/types/query-types'
import { useEditOutgoingWebhookStepRequests } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditOutgoingWebhookStep/utils/EditOutgoingWebhookStep.graphQL'

interface OutgoingWebhookStepFunctions {
  loadEndpoints: () => Promise<WebhookSubscription[] | undefined>
  saveEndpoints: (endpoints: WebhookSubscription[]) => Promise<void>
}

export const useOutgoingWebhookStep = (): OutgoingWebhookStepFunctions => {
  const { getEndpoints, saveWebhookEndpoints } = useEditOutgoingWebhookStepRequests()

  const loadEndpoints = async () => {
    const { data } = await getEndpoints()
    if (data) {
      const endpoints = data?.getWebhooksEndpointsWithAPStepEvent as WebhookSubscription[] | undefined
      if (endpoints) {
        return endpoints
      }
    }
  }

  const saveEndpoints = async (endpoints: WebhookSubscription[]) => {
    await saveWebhookEndpoints(endpoints)
  }

  return {
    loadEndpoints,
    saveEndpoints,
  }
}
