import { DocumentNode } from '@apollo/client'
import getAllItems from '@graphql/microservices/categorization/getAllItems'
import getFavoriteItems from '@graphql/microservices/categorization/getFavoriteItems'
import getFolders from '@graphql/microservices/categorization/getFolders'
import getItemChildrenHierarchy from '@graphql/microservices/categorization/getItemChildrenHierarchy'
import getItemHierarchy from '@graphql/microservices/categorization/getItemHierarchy'
import getItemsByAuthor from '@graphql/microservices/categorization/getItemsByAuthor'
import getItemsInFolder from '@graphql/microservices/categorization/getItemsInFolder'
import getItemsInFolderAndLabels from '@graphql/microservices/categorization/getItemsInFolderAndLabels'
import getRecentItems from '@graphql/microservices/categorization/getRecentItems'
import getTags from '@graphql/microservices/categorization/getTags'
import searchByTags from '@graphql/microservices/categorization/searchByTags'
import searchSegments from '@graphql/microservices/categorization/searchSegments'
import searchSegmentsByAuthor from '@graphql/microservices/categorization/searchSegmentsByAuthor'
import searchSegmentsByFavorites from '@graphql/microservices/categorization/searchSegmentsByFavorites'
import searchSegmentsByRecent from '@graphql/microservices/categorization/searchSegmentsByRecent'
import {
  GetAllItemsQuery,
  GetAllItemsQueryVariables,
  GetFavoriteItemsQuery,
  GetFavoriteItemsQueryVariables,
  GetFoldersQuery,
  GetFoldersQueryVariables,
  GetItemChildrenHierarchyQuery,
  GetItemChildrenHierarchyQueryVariables,
  GetItemHierarchyQuery,
  GetItemHierarchyQueryVariables,
  GetItemsByAuthorQuery,
  GetItemsByAuthorQueryVariables,
  GetItemsInFolderAndLabelsQuery,
  GetItemsInFolderAndLabelsQueryVariables,
  GetItemsInFolderQuery,
  GetItemsInFolderQueryVariables,
  GetRecentItemsQuery,
  GetRecentItemsQueryVariables,
  GetTagsQuery,
  GetTagsQueryVariables,
  LabelDto,
  SearchByTagsQuery,
  SearchByTagsQueryVariables,
  SearchQuery,
  SearchSegmentsByAuthorQuery,
  SearchSegmentsByFavoritesQuery,
  SearchSegmentsByRecentQuery,
  SearchSegmentsQuery,
  SearchSegmentsQueryVariables,
} from '@graphql/types/microservice/categorization-types'
import { Folder } from '@interface/Folder'
import { ItemSubType, ItemSubTypeDtoName, ItemType } from '@utils/categorization'
import { FilterTypes } from '@utils/filter'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export const DEFAULT_PAGE_NUMBER = 0
export const PAGE_SIZE = 50
const NAME = 'name'

interface GetUnifiedListBaseParams {
  type?: ItemType
  subTypes?: ItemSubType[]
}

interface GetUnifiedListSearchParams extends GetUnifiedListBaseParams {
  search: string
}

type SearchSegmentsRequestParams = GetUnifiedListSearchParams & { currentFolder?: Folder }
type SearchSegmentsByFilterRequestParams = GetUnifiedListSearchParams & { filter: FilterTypes }
type SearchSegmentsByTagsRequestParams = GetUnifiedListSearchParams & { currentTag: LabelDto }

interface GetAllItemsParams extends GetUnifiedListBaseParams {
  pageNumber?: number
  pageSize?: number
}

type GetFavoriteSegmentsRequestParams = GetAllItemsParams
type GetCreatedByMeSegmentsRequestParams = GetAllItemsParams
type GetRecentSegmentsRequestParams = GetAllItemsParams
type GetFoldersRequestParams = GetAllItemsParams

interface GetSegmentsInFolderRequestParams extends GetAllItemsParams {
  folderId?: number
}

interface GetTagsRequestParams {
  types: ItemType[]
}

interface GetSegmentsInFolderAndTagsRequestParams extends GetAllItemsParams {
  tagId: number
}

interface GetSegmentHierarchyRequestParams {
  segmentId: number
}

type GetItemChildrenHierarchyRequestParams = GetSegmentHierarchyRequestParams

export type SearchSegmentsByFilterTypes = SearchSegmentsByFavoritesQuery | SearchSegmentsByRecentQuery | SearchSegmentsByAuthorQuery | SearchQuery

export type SearchSegmentsRequestType = (params: SearchSegmentsRequestParams) => FetchPromise<SearchSegmentsQuery>
export type SearchSegmentsByFilterRequestType = (params: SearchSegmentsByFilterRequestParams) => FetchPromise<SearchSegmentsByFilterTypes>
export type SearchSegmentsByTagsRequestType = (params: SearchSegmentsByTagsRequestParams) => FetchPromise<SearchByTagsQuery>
export type GetAllItemsRequestType = (params: GetAllItemsParams) => FetchPromise<GetAllItemsQuery>
export type GetFavoriteSegmentsRequestType = (params: GetFavoriteSegmentsRequestParams) => FetchPromise<GetFavoriteItemsQuery>
export type GetCreatedByMeSegmentsRequestType = (params: GetCreatedByMeSegmentsRequestParams) => FetchPromise<GetItemsByAuthorQuery>
export type GetRecentSegmentsRequestType = (params: GetRecentSegmentsRequestParams) => FetchPromise<GetRecentItemsQuery>
export type GetFoldersRequestType = (params: GetFoldersRequestParams) => FetchPromise<GetFoldersQuery>
export type GetSegmentsInFolderRequestType = (params: GetSegmentsInFolderRequestParams) => FetchPromise<GetItemsInFolderQuery>
export type GetTagsRequestType = (params: GetTagsRequestParams) => FetchPromise<GetTagsQuery>
export type GetSegmentsInFolderAndTagsRequestType = (params: GetSegmentsInFolderAndTagsRequestParams) => FetchPromise<GetItemsInFolderAndLabelsQuery>
export type GetSegmentHierarchyRequestType = (params: GetSegmentHierarchyRequestParams) => FetchPromise<GetItemHierarchyQuery>
export type GetItemChildrenHierarchyRequestType = (params: GetItemChildrenHierarchyRequestParams) => FetchPromise<GetItemChildrenHierarchyQuery>

interface UseListPickerModalCategorizationResponse {
  searchSegmentsRequest: SearchSegmentsRequestType
  searchSegmentsByFilterRequest: SearchSegmentsByFilterRequestType
  searchSegmentsByTagsRequest: SearchSegmentsByTagsRequestType
  getAllItemsRequest: GetAllItemsRequestType
  getFavoriteSegmentsRequest: GetFavoriteSegmentsRequestType
  getCreatedByMeSegmentsRequest: GetCreatedByMeSegmentsRequestType
  getRecentSegmentsRequest: GetRecentSegmentsRequestType
  getFoldersRequest: GetFoldersRequestType
  getSegmentsInFolderRequest: GetSegmentsInFolderRequestType
  getTagsRequest: GetTagsRequestType
  getSegmentsInFolderAndTagsRequest: GetSegmentsInFolderAndTagsRequestType
  getSegmentHierarchyRequest: GetSegmentHierarchyRequestType
  getItemChildrenHierarchyRequest: GetItemChildrenHierarchyRequestType
}

export const useListPickerModalCategorizationRequests = (): UseListPickerModalCategorizationResponse => {
  const {
    client: { query },
  } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })

  const searchSegmentsRequest = async ({ type, search, currentFolder }: SearchSegmentsRequestParams) =>
    await query<SearchSegmentsQuery, SearchSegmentsQueryVariables>({
      query: searchSegments,
      fetchPolicy: 'network-only',
      variables: {
        type,
        query: search,
        allSegments: !currentFolder,
        folder: currentFolder?.id,
        field: NAME,
        searchHierarchy: true,
      },
    })

  const searchSegmentsByFilterRequest = async ({ filter, type, search }: SearchSegmentsByFilterRequestParams) => {
    const searchBy: { [key: string]: { accessor: string; query: DocumentNode } } = {
      [FilterTypes.FAVORITES]: { accessor: 'searchByFavorites', query: searchSegmentsByFavorites },
      [FilterTypes.RECENT]: { accessor: 'searchByRecent', query: searchSegmentsByRecent },
      [FilterTypes.CREATED_BY_ME]: { accessor: 'searchByAuthor', query: searchSegmentsByAuthor },
      [FilterTypes.CONTACT_PREFERENCES]: { accessor: 'search', query: searchSegments },
      [FilterTypes.CRM_SOURCED]: { accessor: 'search', query: searchSegments },
    }
    const isContactPreferences = filter === FilterTypes.CONTACT_PREFERENCES
    const isCrmSourced = filter === FilterTypes.CRM_SOURCED
    return await query({
      query: searchBy[filter]?.query,
      fetchPolicy: 'network-only',
      variables: {
        type: isContactPreferences ? ItemType.BOUNCE : type,
        query: search,
        field: NAME,
        allSegments: isCrmSourced,
        searchHierarchy: true,
        subTypes: isCrmSourced ? [ItemSubTypeDtoName.CRM_SOURCED] : [],
      },
    })
  }

  const searchSegmentsByTagsRequest = async ({ currentTag, search, type = ItemType.SEGMENT }: SearchSegmentsByTagsRequestParams) =>
    await query<SearchByTagsQuery, SearchByTagsQueryVariables>({
      query: searchByTags,
      fetchPolicy: 'network-only',
      variables: {
        type,
        query: search,
        labelIds: [currentTag.id],
        field: NAME,
        searchHierarchy: true,
      },
    })

  const getAllItemsRequest = async ({
    pageNumber = DEFAULT_PAGE_NUMBER,
    pageSize = PAGE_SIZE,
    type = ItemType.SEGMENT,
    subTypes,
  }: GetAllItemsParams) =>
    await query<GetAllItemsQuery, GetAllItemsQueryVariables>({
      query: getAllItems,
      fetchPolicy: 'network-only',
      variables: {
        type,
        subTypes,
        pageNumber,
        pageSize,
      },
    })

  const getFavoriteSegmentsRequest = ({
    pageNumber = DEFAULT_PAGE_NUMBER,
    pageSize = PAGE_SIZE,
    type = ItemType.SEGMENT,
  }: GetFavoriteSegmentsRequestParams) =>
    query<GetFavoriteItemsQuery, GetFavoriteItemsQueryVariables>({
      query: getFavoriteItems,
      fetchPolicy: 'network-only',
      variables: {
        type,
        pageNumber,
        pageSize,
        orderBy: NAME,
      },
    })

  const getCreatedByMeSegmentsRequest = async ({
    pageNumber = DEFAULT_PAGE_NUMBER,
    pageSize = PAGE_SIZE,
    type = ItemType.SEGMENT,
  }: GetCreatedByMeSegmentsRequestParams) =>
    await query<GetItemsByAuthorQuery, GetItemsByAuthorQueryVariables>({
      query: getItemsByAuthor,
      fetchPolicy: 'network-only',
      variables: {
        type: type ?? ItemType.SEGMENT,
        pageNumber,
        pageSize,
        orderBy: NAME,
      },
    })

  const getRecentSegmentsRequest = async ({
    pageNumber = DEFAULT_PAGE_NUMBER,
    pageSize = PAGE_SIZE,
    type = ItemType.SEGMENT,
  }: GetRecentSegmentsRequestParams) =>
    await query<GetRecentItemsQuery, GetRecentItemsQueryVariables>({
      query: getRecentItems,
      fetchPolicy: 'network-only',
      variables: {
        type: type ?? ItemType.SEGMENT,
        pageNumber,
        pageSize,
        orderBy: NAME,
      },
    })

  const getFoldersRequest = async ({ type = ItemType.SEGMENT }: GetFoldersRequestParams) =>
    await query<GetFoldersQuery, GetFoldersQueryVariables>({
      query: getFolders,
      fetchPolicy: 'network-only',
      variables: {
        type,
      },
    })

  const getSegmentsInFolderRequest = async ({
    folderId,
    pageNumber = DEFAULT_PAGE_NUMBER,
    pageSize = PAGE_SIZE,
    type = ItemType.SEGMENT,
  }: GetSegmentsInFolderRequestParams) =>
    query<GetItemsInFolderQuery, GetItemsInFolderQueryVariables>({
      query: getItemsInFolder,
      fetchPolicy: 'network-only',
      variables: {
        type,
        folder: folderId,
        pageNumber,
        pageSize,
      },
    })

  const getTagsRequest = async ({ types }: GetTagsRequestParams) =>
    await query<GetTagsQuery, GetTagsQueryVariables>({
      query: getTags,
      fetchPolicy: 'network-only',
      variables: {
        types,
      },
    })

  const getSegmentsInFolderAndTagsRequest = async ({
    tagId,
    pageNumber = DEFAULT_PAGE_NUMBER,
    pageSize = PAGE_SIZE,
    type = ItemType.SEGMENT,
  }: GetSegmentsInFolderAndTagsRequestParams) =>
    await query<GetItemsInFolderAndLabelsQuery, GetItemsInFolderAndLabelsQueryVariables>({
      query: getItemsInFolderAndLabels,
      fetchPolicy: 'network-only',
      variables: {
        types: type === ItemType.SEGMENT ? [ItemType.SEGMENT, ItemType.BOUNCE] : [ItemType.FORM_SUBMISSION],
        labelIds: [tagId],
        pageNumber,
        pageSize,
      },
    })

  const getSegmentHierarchyRequest = async ({ segmentId }: GetSegmentHierarchyRequestParams) =>
    await query<GetItemHierarchyQuery, GetItemHierarchyQueryVariables>({
      query: getItemHierarchy,
      fetchPolicy: 'network-only',
      variables: {
        itemId: segmentId,
        type: ItemType.SEGMENT,
      },
    })

  const getItemChildrenHierarchyRequest = async ({ segmentId }: GetItemChildrenHierarchyRequestParams) =>
    await query<GetItemChildrenHierarchyQuery, GetItemChildrenHierarchyQueryVariables>({
      query: getItemChildrenHierarchy,
      fetchPolicy: 'network-only',
      variables: {
        itemId: segmentId,
        type: ItemType.SEGMENT,
      },
    })

  return {
    searchSegmentsRequest,
    searchSegmentsByFilterRequest,
    searchSegmentsByTagsRequest,
    getAllItemsRequest,
    getCreatedByMeSegmentsRequest,
    getRecentSegmentsRequest,
    getFavoriteSegmentsRequest,
    getFoldersRequest,
    getSegmentHierarchyRequest,
    getSegmentsInFolderRequest,
    getSegmentsInFolderAndTagsRequest,
    getTagsRequest,
    getItemChildrenHierarchyRequest,
  }
}
