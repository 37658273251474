import { gql } from '@apollo/client'

export default gql`
  query getAllSignature($signatureType: String, $pageNumber: Int, $pageSize: Int, $sortDirection: Direction) {
    getAllSignature(signatureType: $signatureType, pageNumber: $pageNumber, pageSize: $pageSize, sortDirection: $sortDirection) {
      user {
        ID
        html
        textHtml
        imageHtml
        imageUrl
        isUserDefault
        isAccountDefault
        isUser
        isAccount
        updatedAt
      }
      account {
        ID
        html
        textHtml
        imageHtml
        imageUrl
        isUserDefault
        isAccountDefault
        isUser
        isAccount
        updatedAt
      }
      signatures {
        ID
        html
        textHtml
        imageHtml
        imageUrl
        isUserDefault
        isAccountDefault
        isUser
        isAccount
        updatedAt
      }
      usersCount
      accountsCount
      totalCount
    }
  }
`
