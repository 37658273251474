import React, { FC, useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { LinkTextButton } from '@components/LinkTextButton/LinkTextButton'
import { renderLoader } from '@components/Loader/Loader'
import PageContainer from '@components/PageContainer'
import PageHeader from '@components/PageHeader'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import StatusToast from '@components/StatusToast/StatusToast'
import Tooltip from '@components/Tooltip/Tooltip'
import { rootContext, useTranslation } from '@const/globals'
import CopyLog from '@src/pages/ContactSegments/components/CopySegments/components/CopyLog/CopyLog'
import ModalHandler from '@src/pages/ContactSegments/components/CopySegments/components/ModalHandler/ModalHandler'
import StepFour from '@src/pages/ContactSegments/components/CopySegments/components/StepFour/StepFour'
import StepOne from '@src/pages/ContactSegments/components/CopySegments/components/StepOne/StepOne'
import StepThree from '@src/pages/ContactSegments/components/CopySegments/components/StepThree/StepThree'
import StepTwo from '@src/pages/ContactSegments/components/CopySegments/components/StepTwo/StepTwo'
import { COPY_SEGMENT_LOG_PARAM, rootText } from '@src/pages/ContactSegments/components/CopySegments/constants/CopySegments.constants'
import { CopySegmentsContext, FieldStatusType, SegmentActions } from '@src/pages/ContactSegments/components/CopySegments/context/CopySegments.context'
import { useUnmappedFieldSegments } from '@src/pages/ContactSegments/components/CopySegments/utils/UseUnmappedFieldSegments'

import './CopySegments.css'

export interface CopySegmentsProps {
  dataTest?: string
}

const rootClass = 'copy-segments'
const CopySegments: FC<CopySegmentsProps> = ({ dataTest = rootClass }: CopySegmentsProps) => {
  const {
    values: { isMigrating, mappedFields, segmentAction, segments, showSegmentCopyLog, statusToast },
    update,
  } = useContext(CopySegmentsContext)

  const { t } = useTranslation()

  const history = useHistory()

  const { showStatusToast, message, status } = statusToast || {}
  const filteredMappedFields = mappedFields.filter(({ automaticallyMapped }) => !automaticallyMapped)
  const unmappedFieldSegments = useUnmappedFieldSegments()
  const reviewButtonDisabled =
    segments?.length === 0 ||
    segments?.length === unmappedFieldSegments?.length ||
    filteredMappedFields.some(
      ({ fieldStatus }) =>
        fieldStatus !== undefined &&
        ![FieldStatusType.SUCCESS, FieldStatusType.NEW_FIELD_DELETED, FieldStatusType.SEGMENT_WITH_UNMAPPED_FIELDS].includes(fieldStatus)
    )

  const onReviewClicked = () => {
    const segmentsWithFieldsUnmapped = filteredMappedFields.filter(
      ({ fieldStatus }) => fieldStatus !== undefined && fieldStatus === FieldStatusType.SEGMENT_WITH_UNMAPPED_FIELDS
    )
    if (segmentsWithFieldsUnmapped.length > 0) {
      update({ showFieldMatchingWarningModal: true })
      return
    }
    if (segmentAction !== SegmentActions.NOTHING) {
      update({ showDependenciesModal: true })
    } else {
      update({ showReviewSegmentsModal: true })
    }
  }

  const onOpenLog = () => {
    const queryParams = new URLSearchParams(history.location.search)
    if (!queryParams.has(COPY_SEGMENT_LOG_PARAM)) {
      queryParams.append(COPY_SEGMENT_LOG_PARAM, '')
      history.replace({ search: queryParams.toString().replace(/=(?=&|$)/gm, '') })
    }
    update({ showSegmentCopyLog: true })
  }

  const reviewButton = useMemo(
    () => (
      <Button
        className={classNames({ [`${rootClass}__review-button`]: !reviewButtonDisabled })}
        buttonType={ButtonType.PRIMARY}
        disabled={reviewButtonDisabled}
        onClick={onReviewClicked}
      >
        {t('Review')}
      </Button>
    ),
    [segmentAction, reviewButtonDisabled, mappedFields]
  )

  return (
    <>
      <ModalHandler />
      {isMigrating && renderLoader('loader--white-background')}
      {showStatusToast && <StatusToast closeStatus={() => update({ statusToast: undefined })} message={message} status={status} />}
      {showSegmentCopyLog && <CopyLog />}
      <PageContainer className={rootClass} dataTest={dataTest}>
        <PositionContainer noOverflow>
          <PageHeader primaryText={t(`${rootText}Header`)} leftContent linkBack linkUrl={`${rootContext}/segments`}>
            <LinkTextButton className={`${rootClass}__header-link`} text={t(`${rootText}Header.Link`)} hideIcons onClick={onOpenLog} />
          </PageHeader>
          <StepOne />
          <StepTwo />
          <StepThree />
          <StepFour />
          <div className={`${rootClass}__review-button-container`}>
            {reviewButtonDisabled ? (
              <Tooltip trigger={reviewButton} triggerClassName={`${rootClass}__review-button`}>
                {segments?.length === unmappedFieldSegments?.length && segments?.length > 0
                  ? t(`${rootText}Review.Button.UnmappedFields.Disabled`)
                  : t(`${rootText}Review.Button.Disabled`)}
              </Tooltip>
            ) : (
              reviewButton
            )}
          </div>
        </PositionContainer>
      </PageContainer>
    </>
  )
}

export default CopySegments
