import { useApolloClient } from '@apollo/client'
import getItem from '@graphql/microservices/categorization/getItem'
import deleteEmailDraft from '@graphql/mutations/deleteEmailDraft'
import duplicateEmailDraft from '@graphql/mutations/duplicateEmailDraft'
import getEmailDraftPreview from '@graphql/queries/getEmailDraftPreview'
import { GetItemQuery, GetItemQueryVariables } from '@graphql/types/microservice/categorization-types'
import {
  DeleteEmailDraftsMutation,
  DeleteEmailDraftsMutationVariables,
  DuplicateEmailDraftMutation,
  DuplicateEmailDraftMutationVariables,
} from '@graphql/types/mutation-types'
import { GetEmailDraftPreviewQuery, GetEmailDraftPreviewQueryVariables } from '@graphql/types/query-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export interface DraftsListingPageRequests {
  deleteDraftsRequest: (variables: DeleteEmailDraftsMutationVariables) => FetchPromise<DeleteEmailDraftsMutation>
  getDraftPreviewRequest: (variabled: GetEmailDraftPreviewQueryVariables) => FetchPromise<GetEmailDraftPreviewQuery /*queryName*/>
  getItemRequest: (variables: GetItemQueryVariables) => FetchPromise<GetItemQuery>
  duplicateDraftRequest: (variables: DuplicateEmailDraftMutationVariables) => FetchPromise<DuplicateEmailDraftMutation>
}

export const useDraftsListingPageRequests = (): DraftsListingPageRequests => {
  const actonClient = useApolloClient()
  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })

  const deleteDraftsRequest = async (variables: DeleteEmailDraftsMutationVariables) => {
    return await actonClient.mutate<DeleteEmailDraftsMutation, DeleteEmailDraftsMutationVariables>({
      mutation: deleteEmailDraft,
      errorPolicy: 'all',
      variables,
    })
  }

  const getDraftPreviewRequest = async (variables: GetEmailDraftPreviewQueryVariables) => {
    return await actonClient.query<GetEmailDraftPreviewQuery, GetEmailDraftPreviewQueryVariables>({
      query: getEmailDraftPreview,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables,
    })
  }
  const getItemRequest = async (variables: GetItemQueryVariables) => {
    return await categorizationClient.query<GetItemQuery, GetItemQueryVariables>({
      query: getItem,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables,
    })
  }

  const duplicateDraftRequest = async (variables: DuplicateEmailDraftMutationVariables) => {
    return await actonClient.mutate<DuplicateEmailDraftMutation, DuplicateEmailDraftMutationVariables>({
      mutation: duplicateEmailDraft,
      errorPolicy: 'all',
      variables,
    })
  }

  return {
    deleteDraftsRequest,
    getDraftPreviewRequest,
    getItemRequest,
    duplicateDraftRequest,
  }
}
