import React, { FC, useEffect, useMemo } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Dots from '@components/Dots/Dots'
import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Spinner from '@components/Spinner/Spinner'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Svg, { SvgNames } from '@components/Svg'
import { TableV2 } from '@components/TableV2/TableV2'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { LineHeight, TextAlign, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { CLICKTHROUGH_LINKS_ARTICLE } from '@src/pages/EmailComposer/EmailModals/components/ClickthroughLinksModal/constants/ClickthroughLinksModal.constants'
import {
  clickthrougLinksTableDefaultSortBy,
  getClickthrougLinksTableColumns,
} from '@src/pages/EmailComposer/EmailModals/components/ClickthroughLinksModal/utils/ClickthroughLinksModalTable.utils'
import { useClickthroughLinksValidations } from '@src/pages/EmailComposer/hooks/useClickthroughLinksValidations'
import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'

import './ClickthroughLinksModal.css'

interface ClickthroughLinksModalProps {
  dataTest?: string
}

const rootClass = 'clickthrough-links-modal'

const ClickthroughLinksModal: FC<ClickthroughLinksModalProps> = ({ dataTest = rootClass }) => {
  const { t } = useTranslation()

  const {
    values: {
      validations: { contentValidations },
      landingPage: { isLandingPage },
    },
    api: { update },
  } = useComposerContext()

  const { isClickthroughLoading: loading, clickthroughLinks, isClickthroughError } = contentValidations
  const getClickthroughLinks = useClickthroughLinksValidations()

  const columns = useMemo(() => getClickthrougLinksTableColumns(t, isLandingPage), [t, isLandingPage])

  useEffect(() => {
    getClickthroughLinks()
  }, [])

  return (
    <Modal
      className={rootClass}
      dataTest={dataTest}
      isOpen
      header={
        <ModalHeader className={`${rootClass}__header`} dataTest={`${dataTest}-header`} headerType={ModalHeaderType.Form}>
          {t('EmailComposer.ClickthroughLinks.Modal.Title', { context: isLandingPage ? 'landingPage' : 'default' })}
        </ModalHeader>
      }
    >
      <ModalBody className={classNames(`${rootClass}__body`, { [`${rootClass}__body-flex`]: loading || isClickthroughError })}>
        <div className={classNames(`${rootClass}__description`, 'inset-x4')}>
          <Typography
            text={t('EmailComposer.ClickthroughLinks.Modal.Description', { context: isLandingPage ? 'landingPage' : 'default' })}
            type={TextType.BODY_TEXT_LIGHT}
            dataTest={`${dataTest}-subHeader`}
          />
          <TextLink
            text={t('EmailComposer.ClickthroughLinks.Modal.Link')}
            link={CLICKTHROUGH_LINKS_ARTICLE}
            size={TextLinkSize.LARGE}
            lineHeight={LineHeight.SMALL}
            className="push-down"
          />
        </div>
        {loading ? (
          <Spinner
            headline="EmailComposer.ClickthroughLinks.Modal.Spinner.Headline"
            text="EmailComposer.ClickthroughLinks.Modal.Spinner.Text"
            dataTest={`${dataTest}-spinner`}
          />
        ) : isClickthroughError ? (
          <EmptyListing
            withoutBorder
            fullHeight
            size={EmptyListingSize.CARD}
            imgSrc={StaticImageNames.warningSmall}
            headlineLineHeight={LineHeight.LARGER}
            headline="EmailComposer.ClickthroughLinks.Modal.Error.Headline"
            text={
              <Typography
                text="EmailComposer.ClickthroughLinks.Modal.Error.Text"
                type={TextType.BODY_TEXT_LIGHT}
                textAlign={TextAlign.CENTER}
                tagComponents={{
                  TextLink: <TextLink size={TextLinkSize.LARGE} hideIcon />,
                }}
                className="push-down-x2"
              />
            }
            buttonText="Try again"
            buttonOnClick={getClickthroughLinks}
            buttonType={ButtonType.OUTLINE}
          />
        ) : (
          <TableV2 data={clickthroughLinks} columns={columns} withoutBorders sortingBy={clickthrougLinksTableDefaultSortBy} />
        )}
      </ModalBody>
      <ModalFooter className={`${rootClass}__footer`}>
        <Button
          buttonType={ButtonType.OUTLINE}
          onClick={getClickthroughLinks}
          disabled={loading}
          className={`${rootClass}__button-check`}
          dataTest={`${dataTest}-button-check`}
        >
          {loading ? (
            <Dots customSize={{ padding: 0, width: '24px', height: '16px' }} className={`${rootClass}__button-check__dots`} />
          ) : (
            <>
              <Svg name={SvgNames.generate} />
              {t('Check again')}
            </>
          )}
        </Button>
        <Button buttonType={ButtonType.TRANSPARENT} onClick={() => update({ isClickthroughLinks: false })} dataTest={`${dataTest}-button-close`}>
          {t('Close')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ClickthroughLinksModal
