export enum FeedbackOption {
  NOT_RELEVANT = 'NOT_RELEVANT',
  DUPLICATE = 'DUPLICATE',
  TOO_FEW_CONTACTS = 'TOO_FEW_CONTACTS',
  TOO_MANY_CONTACTS = 'TOO_MANY_CONTACTS',
  CONFUSING = 'CONFUSING',
}

export const getFeedbackOptionText: { [key in FeedbackOption]: string } = {
  [FeedbackOption.NOT_RELEVANT]: 'Not relevant to our marketing goals',
  [FeedbackOption.DUPLICATE]: 'Already have a segment like this',
  [FeedbackOption.TOO_FEW_CONTACTS]: 'Too few contacts',
  [FeedbackOption.TOO_MANY_CONTACTS]: 'Too many contacts',
  [FeedbackOption.CONFUSING]: 'Segment criteria didn’t make sense',
}
