import React, { useContext, useMemo, useState } from 'react'
import { OptionProps } from 'react-select'

import classNames from 'classnames'

import { SelectV2Context } from '@components/SelectV2/SelectV2.context'
import { SelectV2Option } from '@components/SelectV2/SelectV2.props'
import { isSingleValueType, selectRootClass } from '@components/SelectV2/SelectV2.utils'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { css } from '@emotion/css'
import { renderBoldTextOnFirstMatch } from '@utils/typography'

import './CustomOption.css'

const rootClass = 'custom-option'

const CustomOption = (props: OptionProps<SelectV2Option>) => {
  const { children, data: dataProp, getClassNames, getStyles, isSelected, innerRef, innerProps, options } = props
  const {
    values: { hideCheckMark, infoLabels, inputValue, isPaginator, renderCustomOption, optionsWithLargePadding },
  } = useContext(SelectV2Context)

  const getLastOption = (hasCreateOption = false) => {
    if (Array.isArray(options)) {
      const lastOptionIndex = hasCreateOption ? 2 : 1
      const lastOption = options[options.length - lastOptionIndex]
      if (isSingleValueType(lastOption)) {
        return lastOption
      }
    }
  }

  const [isFocused, setIsFocused] = useState<boolean>()
  const data = isSingleValueType(dataProp) ? dataProp : { value: '', label: '' }
  const isNew = !!data.__isNew__
  const { value } = data
  const optionClassNames = getClassNames('option', props)
  const isTruncated = optionClassNames?.includes(`${selectRootClass}__option-truncated`)
  const hasCreateOption = !!getLastOption()?.__isNew__
  const { infoLabel } = infoLabels?.find(({ optionValue }) => optionValue === value) ?? {}

  const focusProps = {
    onMouseOutCapture: () => setIsFocused(() => false),
    onBlur: () => setIsFocused(() => false),
    onMouseOverCapture: () => setIsFocused(() => true),
    onFocus: () => setIsFocused(() => true),
  }

  const renderOption = useMemo(
    () =>
      !!inputValue && !renderCustomOption ? (
        renderBoldTextOnFirstMatch({
          hideTooltip: true,
          value: children as string,
          search: inputValue,
          overridingProps: {
            className: classNames(`${selectRootClass}__option-value`, { [`${selectRootClass}__option-value-paginator`]: isPaginator }),
            weight: TextWeight.MEDIUM_LIGHT,
            dataTest: 'selectv2-option-value',
          },
        })
      ) : (
        <div className={classNames(`${selectRootClass}__option-value`, { [`${selectRootClass}__option-value-paginator`]: isPaginator })}>
          {renderCustomOption ? (
            renderCustomOption(data, inputValue)
          ) : (
            <>
              <Typography dataTest={'selectv2-option-value'} text={children} weight={TextWeight.MEDIUM_LIGHT} inline />
              {data.subText && <Typography text={data.subText} type={TextType.BODY_TEXT_SMALL_LIGHT} />}
            </>
          )}
        </div>
      ),
    [inputValue, children, isTruncated]
  )

  if (isNew) {
    return <></>
  }

  return (
    <div
      ref={innerRef}
      {...focusProps}
      className={classNames(css(getStyles('option', props)), optionClassNames, `${selectRootClass}__option-selectable`, `option-${value}`, {
        [`${selectRootClass}__option-new`]: isNew,
        [`${selectRootClass}__option-not-focused`]: !isFocused,
        [`${selectRootClass}__option-last`]: value === getLastOption(hasCreateOption)?.value,
        [`${selectRootClass}__option-large-padding`]: optionsWithLargePadding,
      })}
      {...innerProps}
    >
      {isTruncated ? (
        <TextWithTooltipOnEllip typographyProps={{ ...renderOption.props, text: children }} />
      ) : data.renderItemIcon ? (
        <div className={`${rootClass}__option-with-icon`}>
          {data.renderItemIcon && data.renderItemIcon(isSelected)}
          {renderOption}
        </div>
      ) : (
        renderOption
      )}
      <div className={`${selectRootClass}__option-right-side-container`}>
        {!!infoLabel && <Typography text={infoLabel} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.MEDIUM_LIGHT} inline />}
        {isSelected && !hideCheckMark && (
          <div className={`${selectRootClass}__option-selected-icon-container`}>
            <Svg name={SvgNames.check} fill={SvgColor.TEAL} type={SvgType.LARGER_ICON} />
          </div>
        )}
      </div>
    </div>
  )
}

export default CustomOption
