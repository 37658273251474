import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Toggle from '@components/Toggle/index'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'

import './ToggleSection.css'

interface ToggleSectionProps {
  title: string
  infoText: string | ReactNode
  isToggleOn: boolean
  onToggle: (toggle: boolean) => void
  disabled?: boolean
  className?: string
  dataTest?: string
  withTooltip?: boolean
  tooltipText?: string
  toolTipOffset?: number
}

const rootClass = 'toggle-section'

const ToggleSection: FC<ToggleSectionProps> = (props: ToggleSectionProps) => {
  const {
    toolTipOffset,
    withTooltip = false,
    tooltipText,
    isToggleOn,
    onToggle,
    disabled = false,
    title,
    infoText,
    className,
    dataTest = rootClass,
  } = props
  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__info-container`}>
        <Typography text={title} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
        <Typography text={infoText} type={TextType.BODY_TEXT_LIGHT} />
      </div>
      <div className={`${rootClass}__toggle-container`}>
        <Toggle
          tooltipText={withTooltip ? tooltipText : ''}
          isOn={isToggleOn}
          onToggle={onToggle}
          disabled={disabled}
          large
          noLeftMargin
          noRightMargin
          dataTest={`${dataTest}-toggle`}
          tooltipPosition={'top'}
          tooltipSideOffset={toolTipOffset}
        />
      </div>
    </div>
  )
}

export default ToggleSection
