import { ApolloClient } from '@apollo/client'
import createProgramTemplate from '@graphql/mutations/createProgramTemplate'
import deleteAutomatedPrograms from '@graphql/mutations/deleteAutomatedPrograms'
import duplicateProgram from '@graphql/mutations/duplicateProgram'
import startAutomatedProgram from '@graphql/mutations/startAutomatedProgram'
import { ItemDto, LabelDto } from '@graphql/types/microservice/categorization-types'
import {
  CreateProgramTemplateMutation,
  CreateProgramTemplateMutationVariables,
  DeleteAutomatedProgramsMutation,
  DeleteAutomatedProgramsMutationVariables,
  DuplicateProgramMutation,
  DuplicateProgramMutationVariables,
  StartAutomatedProgramMutation,
  StartAutomatedProgramMutationVariables,
  TemplateIdDescription,
} from '@graphql/types/mutation-types'
import { FetchPromise } from '@utils/types'

export interface AutomatedProgramsListingPageRequests {
  deleteItemsRequest: (programs: ItemDto[]) => FetchPromise<DeleteAutomatedProgramsMutation>
  startProgramRequest: (id: string) => FetchPromise<StartAutomatedProgramMutation>
  duplicateProgramRequest: (srcProgramId: string, newName: string, tags: LabelDto[], folderId?: number) => FetchPromise<DuplicateProgramMutation>
  createProgramTemplateRequest: (
    programId: string,
    title: string,
    descriptionMapping: TemplateIdDescription[]
  ) => FetchPromise<CreateProgramTemplateMutation>
}

export const getAutomatedProgramsListingPageRequests = (actonClient: ApolloClient<any>): AutomatedProgramsListingPageRequests => {
  const deleteItemsRequest = async (programs: ItemDto[]) =>
    await actonClient.mutate<DeleteAutomatedProgramsMutation, DeleteAutomatedProgramsMutationVariables>({
      mutation: deleteAutomatedPrograms,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: { programIds: programs.map(({ externalId }) => externalId) },
    })

  const duplicateProgramRequest = async (srcProgramId: string, newName: string, tags: LabelDto[], folderId?: number) =>
    await actonClient.mutate<DuplicateProgramMutation, DuplicateProgramMutationVariables>({
      mutation: duplicateProgram,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        srcProgramId,
        newName,
        tags,
        folderId,
      },
    })

  const createProgramTemplateRequest = async (programId: string, title: string, descriptionMapping: TemplateIdDescription[]) =>
    await actonClient.mutate<CreateProgramTemplateMutation, CreateProgramTemplateMutationVariables>({
      mutation: createProgramTemplate,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        programId,
        title,
        descriptionMapping,
      },
    })

  const startProgramRequest = async (programId: string) =>
    await actonClient.mutate<StartAutomatedProgramMutation, StartAutomatedProgramMutationVariables>({
      mutation: startAutomatedProgram,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        programId,
      },
    })

  return {
    startProgramRequest,
    deleteItemsRequest,
    duplicateProgramRequest,
    createProgramTemplateRequest,
  }
}
