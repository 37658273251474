import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/index'
import { useTranslation } from '@const/globals'

import './SelectV2MenuFooterButtons.css'

export interface SelectV2MenuFooterButtonsProps {
  primaryButtonLabel: string
  primaryButtonOnClick: () => void
  cancelButtonLabel?: string
  cancelOnClick?: () => void
  className?: string
  dataTest?: string
}

const rootClass = 'select-v2-menu-footer-buttons'

const SelectV2MenuFooterButtons: FC<SelectV2MenuFooterButtonsProps> = (props: SelectV2MenuFooterButtonsProps) => {
  const { primaryButtonLabel, primaryButtonOnClick, cancelButtonLabel = 'Cancel', cancelOnClick, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {cancelOnClick && <Button buttonType={ButtonType.FLOAT_GRAY}>{t(cancelButtonLabel)}</Button>}
      <Button buttonType={ButtonType.PRIMARY} fullWidth={!cancelOnClick} onClick={primaryButtonOnClick}>
        {t(primaryButtonLabel)}
      </Button>
    </div>
  )
}

export default SelectV2MenuFooterButtons
