import { gql } from '@apollo/client'

export default gql`
  query testUrls($urls: [String]) {
    testUrls(urls: $urls) {
      url
      isValid
      statusCode
    }
  }
`
