import React, { FC, RefObject, ReactNode, useState, useEffect, useRef } from 'react'

import autosize from 'autosize'
import classNames from 'classnames'

import { MaxCharacterProps } from '@components/InputV2/InputTS/type'
import { LabelType, LabelV2 } from '@components/LabelV2/LabelV2'
import Svg, { SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@utils/const/globals'
import { formatNumber } from '@utils/numbers'

import './textArea.css'

const rootClass = 'textarea'

export enum TextAreaResizeDirection {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export interface TextAreaProps {
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  onBlur?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  onFocus?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  children?: ReactNode
  error?: boolean
  name: string
  label?: ReactNode
  labelType?: LabelType
  rows?: number
  cols?: number
  id?: string
  defaultValue?: string
  register?: any
  inputRef?: RefObject<HTMLTextAreaElement>
  className?: string
  dataTest?: string
  disabled?: boolean
  value?: string
  placeholder?: string
  /** User can manually resize element */
  resize?: boolean
  /** Element automatically resizes as more lines are added */
  autoResize?: boolean
  maxCharacterProps?: MaxCharacterProps
  disableNativeMaxLength?: boolean
  resizeDirection?: TextAreaResizeDirection
  labelWithoutMargin?: boolean
  withMaxLengthIcon?: boolean
  textAreaStyles?: React.CSSProperties
}

const TextArea: FC<TextAreaProps> = (props: TextAreaProps) => {
  const {
    dataTest = rootClass,
    name,
    label,
    rows,
    cols,
    onChange,
    onBlur,
    onFocus,
    defaultValue,
    inputRef,
    register,
    className = rootClass,
    placeholder,
    labelWithoutMargin,
    error,
    disabled,
    value,
    labelType = LabelType.small,
    disableNativeMaxLength = false,
    resize = true,
    autoResize = false,
    resizeDirection,
    maxCharacterProps,
    withMaxLengthIcon = false,
    textAreaStyles,
  } = props

  const internalRef = useRef<HTMLTextAreaElement>(null)
  const textAreaRef = inputRef ?? internalRef

  const { t } = useTranslation()
  const [characterCount, setCharacterCount] = useState<number>(defaultValue?.length ?? 0)

  useEffect(() => {
    if (autoResize) {
      textAreaRef.current && autosize(textAreaRef.current)
    }
  }, [autoResize, textAreaRef])

  const renderMaxLength = () => {
    const { maxLength, hideLengthIfEmpty, lengthTextType, lengthTextLineHeight } = maxCharacterProps ?? {}

    if (!maxLength) {
      return null
    }

    const shouldShowLength = !hideLengthIfEmpty || (hideLengthIfEmpty && characterCount !== 0)

    return (
      <div className={`${rootClass}__max-length`}>
        {withMaxLengthIcon && maxCharacterProps?.maxLength === characterCount && (
          <Tooltip trigger={<Svg name={SvgNames.warningSolidNoFill} type={SvgType.ICON} fill={SvgColor.YELLOW_CAUTION} />}>
            {t("You've reached the max character limit.")}
          </Tooltip>
        )}
        {shouldShowLength && (
          <Typography
            type={lengthTextType ?? TextType.BODY_TEXT_SMALL_LIGHT}
            text={`${formatNumber(characterCount)}/${formatNumber(maxLength)}`}
            lineHeight={lengthTextLineHeight ?? LineHeight.MEDIUM}
          />
        )}
      </div>
    )
  }

  return (
    <>
      <div className={`${rootClass}__label`}>
        {label && (
          <LabelV2 htmlFor={name} labelType={labelType} withoutMargin={labelWithoutMargin}>
            {label}
          </LabelV2>
        )}
        {renderMaxLength()}
      </div>
      <textarea
        className={classNames(rootClass, className, {
          [`${rootClass}__error`]: error,
          [`${rootClass}__no-resize`]: !resize,
          [`${rootClass}__resize-direction-${resizeDirection}`]: resize && resizeDirection,
        })}
        style={textAreaStyles}
        name={name}
        id={name}
        data-test={dataTest}
        rows={rows}
        cols={cols}
        onChange={
          maxCharacterProps?.maxLength
            ? (e) => {
                setCharacterCount(e.target.value.length)
                onChange && onChange(e)
              }
            : onChange
        }
        disabled={disabled}
        value={value}
        maxLength={disableNativeMaxLength ? undefined : maxCharacterProps?.maxLength}
        onBlur={onBlur}
        onFocus={onFocus}
        defaultValue={defaultValue}
        placeholder={placeholder}
        ref={textAreaRef}
        {...register}
      >
        {props.children}
      </textarea>
    </>
  )
}

export default TextArea
