import { gql } from '@apollo/client'

export default gql`
  query getFragments(
    $accountId: Long!
    $sortDirection: Direction
    $sortColumn: FragmentNameClickSortColumns
    $size: Int!
    $groupByFragment: Boolean!
    $messageId: String
    $page: Int!
    $tagNames: [String]
    $fragmentNames: [String]
  ) {
    getFragments(
      accountId: $accountId
      sortDirection: $sortDirection
      sortColumn: $sortColumn
      size: $size
      groupByFragment: $groupByFragment
      messageId: $messageId
      page: $page
      tagNames: $tagNames
      fragmentNames: $fragmentNames
    ) {
      fragmentNameLists {
        fragmentName
        linkName
        totalClicks
        uniqueClicks
      }
      totalClickCount
      totalCount
      totalUniqueCount
    }
  }
`
