import { TFunction } from 'i18next'

import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import { renderRelativeDate } from '@components/Table/components/tableColumns'
import { TableV2Props } from '@components/TableV2/tableV2TS/interfaces'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { ListContactGq } from '@graphql/types/query-types'
import { getStandardFormattedDate, relativeDate } from '@utils/date'

import { TestSegment } from '../../graphQL/SendTestEmailModal.graphQL'

export const getTestListContactsTableColumns = (t: TFunction, searchTerm?: string): ColumnDefWithAdditionalProps<ListContactGq>[] => [
  {
    header: t('Name' ?? searchTerm),
    accessorKey: 'name',
    textAlign: 'left',
    enableCustomCellValue: true,
    cell: ({ row }) => (searchTerm ? renderBoldTextOnMatch(row.original.name ?? '', searchTerm) : row.original.name),
  },
  {
    header: t('Email Address'),
    accessorKey: 'email',
    textAlign: 'left',
    enableCustomCellValue: true,
    cell: ({ row }) => (searchTerm ? renderBoldTextOnMatch(row.original.email ?? '', searchTerm) : row.original.email),
  },
]

export const getTestListSegmentsTableColumns = (t: TFunction, searchTerm?: string): ColumnDefWithAdditionalProps<TestSegment>[] => [
  {
    header: t('Name' ?? searchTerm),
    accessorKey: 'name',
    textAlign: 'left',
    cell: ({ row }) => (searchTerm ? renderBoldTextOnMatch(row.original.name ?? '', searchTerm) : row.original.name),
    enableFlexCell: true,
  },
  {
    header: t('Record Count'),
    accessorKey: 'count',
    textAlign: 'left',
    maxSize: 175,
    fieldType: 'integer',
  },
  {
    header: t('Last Counted'),
    accessorKey: 'lastCounted',
    textAlign: 'left',
    maxSize: 175,
    fieldType: 'TIMESTAMP',
    cell: ({ row }) => {
      // The root Test List will never have a valid lastCounted date
      const value = row.original.lastCounted || row.original.lastModified
      return renderRelativeDate(relativeDate(value, true, false), getStandardFormattedDate(value))
    },
  },
]

export const getCommonTestListTableProps = <T extends {}>(): Pick<
  TableV2Props<T>,
  'enableStickyHeader' | 'withoutBorders' | 'enableSorting' | 'enableInsideLoader'
> => ({
  enableStickyHeader: true,
  withoutBorders: true,
  enableSorting: true,
  enableInsideLoader: true,
})
