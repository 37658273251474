import { PersonalizationItem } from '@complex/Personalization/utils/Personalization.context'
import { PersonalizationFieldsErrorsForSources } from '@graphql/types/query-types'

import { FilterTypes } from './filter'

export type PersonalizationValidationType = 'MALFORMED' | 'CRM' | 'MISSING' | 'RECIPIENTS'

export const getPersonalizationValidationType = (error: PersonalizationFieldsErrorsForSources): PersonalizationValidationType => {
  if (error.isCrmField) {
    return 'CRM'
  } else if (error.isInvalidFormat) {
    return 'MALFORMED'
  } else if (!!error.lists.length) {
    return 'RECIPIENTS'
  } else {
    return 'MISSING'
  }
}

export type PersonalizationSyntax = `{{${string}}}`

export interface PersonalizationCommon {
  fieldId: string
  displayText: string
  fallbackText?: string
  group?: FilterTypes
  syntax: string
  mappingName: string
}

export const isPersonalizationSyntax = (text: string): text is PersonalizationSyntax => {
  return text.startsWith('{{') && text.endsWith('}}')
}

export const detectPersonalizationSyntaxes = (originalText: string): PersonalizationSyntax[] => {
  const START = '\u00A0'
  const END = '\u00AD'
  // Replace all the double curly braces so we can ignore any stray single curly braces
  const text = originalText.replace(/\}\}/g, END).replace(/\{\{/g, START)

  // Iterate through the characters and find matching opening/closing tags
  const personalizations: PersonalizationSyntax[] = []
  let startIndex = 0
  let openCount = 0
  let closeCount = 0
  for (let i = 0; i < text.length; ++i) {
    if (text[i] === START) {
      if (openCount === 0) {
        startIndex = i + 1
      }
      ++openCount
    } else if (text[i] === END) {
      if (openCount > closeCount) {
        ++closeCount
      }
    }

    if (openCount > 0 && openCount === closeCount) {
      // Restore double curly braces
      const tagText = text.substring(startIndex, i).replace(new RegExp(START, 'g'), '{{').replace(new RegExp(END, 'g'), '}}')
      personalizations.push(`{{${tagText}}}`)
      openCount = 0
      closeCount = 0
      startIndex = 0
    }
  }
  return personalizations
}

export const getMappingNameAndFallback = (
  fullSyntax: string,
  allPersonalizations?: PersonalizationItem[]
): { mappingName: string; fallback?: string } => {
  const syntax = isPersonalizationSyntax(fullSyntax) ? fullSyntax.slice(2, -2) : fullSyntax
  const [mappingName, fallback] = syntax.split('|')
  const trimmedMappingName = mappingName.replace(/^=?\{\{|\}\}$/g, '').trim()

  // Try to find existing personalization to correct letter case
  const foundMappingName = allPersonalizations?.find(
    (item) =>
      item.mapping?.toLocaleLowerCase().trim() === trimmedMappingName.toLocaleLowerCase() ||
      (!item.mapping && item.title.toLocaleLowerCase().trim() === trimmedMappingName.toLocaleLowerCase())
  )
  const finalMappingName = foundMappingName?.mapping ?? foundMappingName?.title ?? trimmedMappingName
  return {
    mappingName: finalMappingName,
    fallback,
  }
}

export const getPersonalizationCommon = (item: PersonalizationItem): PersonalizationCommon => {
  const fallback = item.fallbackText ? `|${item.fallbackText}` : ''
  const mappingValue = item?.mapping || item?.title

  const insertValue = fallback ? `{{${mappingValue}}}${fallback}` : `{{${mappingValue}}}`
  const syntax = fallback ? `{{=${insertValue}}}` : insertValue

  return {
    displayText: item.title,
    fieldId: item.id,
    fallbackText: item.fallbackText,
    group: item.group,
    syntax,
    mappingName: mappingValue,
  }
}
