import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Row } from 'react-table'

import ListPickerModalBody from '@complex/ListPickerModalV2/components/ListPickerModalBody/ListPickerModalBody'
import ListPickerModalFooter, { ListPickerFooterToggle } from '@complex/ListPickerModalV2/components/ListPickerModalFooter/ListPickerModalFooter'
import ListPickerModalHeader from '@complex/ListPickerModalV2/components/ListPickerModalHeader/ListPickerModalHeader'
import {
  checkAndSetUCLFavorites,
  getUnifiedListFolderItemsUtils,
  getUnifiedListFoldersUtils,
  getUnifiedListTagItemsUtils,
  getUnifiedListTagsUtils,
  setSegmentAncestorsTreeUtils,
  setSegmentChildrenTreeUtils,
} from '@complex/ListPickerModalV2/utils/clients/ListPickerModalCategorizationClientUtils'
import {
  checkAndSetFavoritesLists,
  getFavoritesListsUtils,
  getFoldersListUtils,
  getFoldersWithCountUtils,
  getMainFilterListUtils,
  getUnifiedListUtils,
  searchDefaultSelectedListsUtils,
  searchListsUtils,
  searchRestrictedLegacyLists,
  setClassicSegmentAncestorsTreeUtils,
  setClassicSegmentChildrenTreeUtils,
} from '@complex/ListPickerModalV2/utils/clients/ListPickerModalClientUtils'
import { getCRMUnsupportedTypes } from '@complex/ListPickerModalV2/utils/clients/ListPickerModalCRMClientUtils'
import { getSegmentDefinitionUtils, setSegmentsMetadataUtils } from '@complex/ListPickerModalV2/utils/clients/ListPickerModalSegmentClientUtils'
import { useListPickerModalCategorizationRequests } from '@complex/ListPickerModalV2/utils/graphql/ListPickerModalCategorizationRequests.graphQL'
import { useListPickerModalClassicRequests } from '@complex/ListPickerModalV2/utils/graphql/ListPickerModalClassicRequests.graphQL'
import { useListPickerModalCRMRequests } from '@complex/ListPickerModalV2/utils/graphql/ListPickerModalCRMRequests.graphQL'
import { useListPickerModalSegmentRequests } from '@complex/ListPickerModalV2/utils/graphql/ListPickerModalSegmentRequests.graphQL'
import { ListPickerModalState, PreProcessedList } from '@complex/ListPickerModalV2/utils/interfaces/ListPickerModalInterfaces'
import { setInitialStateUtils } from '@complex/ListPickerModalV2/utils/ListPickerModal.helper'
import { ListPickerModalProps } from '@complex/ListPickerModalV2/utils/ListPickerModal.props'
import { getHasToExpandFunction } from '@complex/ListPickerModalV2/utils/ListPickerModal.utils.expand'
import { getDefaultState, ListPickerModalContext, unifiedListTypes } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import { getFilterRequest, UCLFiltersActions } from '@complex/ListPickerModalV2/utils/ListPickerModalFiltersUtils'
import { getCRMSourcedSegmentDetailsUtils, getMainFilter, useMenuItems } from '@complex/ListPickerModalV2/utils/ListPickerModalMenuUtils'
import { getTableProps, getUCLTableProps } from '@complex/ListPickerModalV2/utils/ListPickerModalTableUtils'
import {
  areLegacyListsDisabled,
  areUnifiedListsDisabled,
  checkAllListsDisabledUtils,
  closeStatusToast,
  doSearchUtils,
  getOnClickTagInRowFunction,
  getOnRowSelectionChangedFunction,
  hasLegacyListsSelectedUtils,
  searchInAllListsUtils,
  searchUCLUtils,
  setFilter,
  setFolder,
} from '@complex/ListPickerModalV2/utils/ListPickerModalUtils'
import { ActionableNestedTableWithLazyLoadingProps } from '@components/ActionableNestedTableWithLazyLoading/ActionableNestedTableWithLazyLoading'
import Modal from '@components/Modal'
import StatusToast from '@components/StatusToast/StatusToast'
import { useTranslation } from '@const/globals'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import { Folder } from '@interface/Folder'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import { FilterDefinition, FilterTypes } from '@utils/filter'
import { DEFAULT_FOLDER_NAME } from '@utils/folders'
import { useCRMService } from '@utils/hooks/microservices/useCRMService'
import useCRM from '@utils/hooks/useCRM'
import { ListCategory } from '@utils/lists'
import { getItem } from '@utils/sessionStorage'

import './ListPickerModal.css'

const rootClass = 'list-picker-modal'

export const ListPickerModal: FC<ListPickerModalProps> = (props: ListPickerModalProps) => {
  const accountSettings = useAccountSettings()
  const { hasPurchasedSMS, hasShowActOnContactsTab, exclusivelyActOnContacts, getConnectedCrm, phase3FormSubmission, hasAOCWebinars } =
    accountSettings
  const {
    cancelButtonText = 'Cancel',
    closeModal,
    customBanner,
    dataTest = rootClass,
    defaultSelectedLists = [],
    disableTopListCount = false,
    hideAllUCLListTypes = false,
    hideUCL = false,
    hideLegacyLists = false,
    headerTitle,
    initialList,
    isListsAndUCLOnly = false,
    isOpen,
    multiSelect = true,
    onBackArrowClick,
    restrictedToLegacyLists = [],
    restrictMixingLegacyListsAndUCL = false,
    submitButtonText = 'Submit',
    submitDisabledWithoutSelection = false,
    submitDisabledOnSelect = false,
    submitLists,
    hasUnifiedFormSubmissions = phase3FormSubmission && !hideUCL,
    hasUnifiedWebinarLists = hasAOCWebinars && !hideUCL,
    hideLegacyListToggle = false,
    submitTooltipText,
    disableSubmitByRowCriteria,
    selectAllSegmentsOfSelectedList = false,
    showTooltipByCriteria,
    disableRowByCriteria,
    hideContactPreferences = false,
    selectSubRows = false,
    keepListSelection = false,
    setCurrentList,
    isViewingSelected = false,
  } = props
  const { t } = useTranslation()

  const isListsOnly = !!props.isListsOnly || isListsAndUCLOnly
  const showUnifiedFormSubmissions = hasUnifiedFormSubmissions && hasShowActOnContactsTab
  const showUnifiedWebinarLists = hasUnifiedWebinarLists && hasShowActOnContactsTab
  const hideAllUCLList = hideAllUCLListTypes || !hasShowActOnContactsTab
  const initialListCategory = initialList ?? (exclusivelyActOnContacts ? ListCategory.ACCOUNTS : ListCategory.MARKETING)

  const savedToggle = getItem(ListPickerFooterToggle.STATUS)
  const [state, setState] = useState<ListPickerModalState>({
    ...getDefaultState(initialListCategory),
    disableTopListCount,
    hasUnifiedFormSubmissions: showUnifiedFormSubmissions,
    hideUCL,
    hideAllUCLListTypes: hideAllUCLList,
    disabledLists: props.disabledLists ?? [],
    disabledListTooltipText: props.disabledListTooltipText ?? '',
    isLegacyForms:
      hideAllUCLList || (savedToggle && !hideLegacyListToggle && !exclusivelyActOnContacts ? savedToggle === 'true' : !showUnifiedFormSubmissions),
    isLegacyWebinars: !showUnifiedWebinarLists,
    multiSelect,
    viewingSelectedLists: isViewingSelected,
    selectAllSegmentsOfSelectedList,
  })
  const {
    allLoaded,
    currentFilter,
    currentFolder,
    currentPage,
    currentTag,
    disableLegacyLists,
    disableUnifiedLists,
    disabledLists,
    disabledListTooltipText,
    foldersState,
    foldersState: { foldersLoading },
    hasToSetDefaultFolder,
    headerShadow,
    lazyLoading,
    listsState,
    listsState: { lists },
    listType,
    loading,
    preSelectedListsInit,
    search,
    segmentTreePage,
    selectedLists,
    statusToast: { message, status, showToast },
    tagsState,
    tagsState: { tags },
    viewingSelectedLists,
    isLegacyForms,
    unifiedListData,
    isLegacyWebinars,
  } = state
  const { loading: searchLoading } = search || {}
  const { hasCRMConnected } = useCRM()
  const {
    getAllItemsRequest,
    getCreatedByMeSegmentsRequest,
    getFavoriteSegmentsRequest,
    getFoldersRequest,
    getRecentSegmentsRequest,
    getSegmentsInFolderRequest,
    getSegmentsInFolderAndTagsRequest,
    getSegmentHierarchyRequest,
    getItemChildrenHierarchyRequest,
    getTagsRequest,
    searchSegmentsRequest,
    searchSegmentsByFilterRequest,
    searchSegmentsByTagsRequest,
  } = useListPickerModalCategorizationRequests()
  const { getSegmentFilterRequest, getSegmentMetadataRequest } = useListPickerModalSegmentRequests()
  const { getCRMNotSupportedEntitiesRequest, getEntityTypeCampaignRequest, getCRMSegmentsInfoRequest, syncedSegmentDetailsRequest } =
    useListPickerModalCRMRequests()
  const {
    getFolderListsRequest,
    getFavoritesListsRequest,
    getFolderListsByTypeRequest,
    getFoldersWithCountRequest,
    listPollRequest,
    searchLegacyListsByIdsRequest,
    searchListsRequest,
  } = useListPickerModalClassicRequests()
  const { getSourceOptionsRequest } = useCRMService()

  const defaultSelectedListsInitRef = useRef<boolean>(false)
  const scrollableElementRef = useRef<HTMLDivElement | null>(null)
  const searchInputRef = useRef<HTMLInputElement>(null)

  const [disableSubmit, setDisableSubmit] = useState<boolean>(false)
  const [disableSubmitByCriteria, setDisableSubmitByCriteria] = useState<boolean>(false)

  const hasUnifiedLists =
    (!hideUCL && !hideAllUCLList && (!isListsOnly || isListsAndUCLOnly) && !!unifiedListData && unifiedListData.foldersWithCount[0].count > 0) ||
    false

  const hasLegacyListsSelected = hasLegacyListsSelectedUtils(selectedLists)
  const disableLists = disableUnifiedLists || disableLegacyLists
  const allListsDisabled = checkAllListsDisabledUtils(lists as unknown as PreProcessedList[])

  const onClearSearch = () => {
    if (searchInputRef.current) {
      searchInputRef.current.value = ''
    }
    setState((state) => ({ ...state, search: undefined, headerShadow: false }))
  }

  const clearSegmentTreePage = () => setState((state) => ({ ...state, segmentTreePage: undefined }))

  useEffect(() => {
    getSourceOptionsRequest().then((crmSourceOptions) => {
      setState((state) => ({ ...state, crmSourceOptions }))
    })
    if (!restrictedToLegacyLists.length) {
      const getUnifiedList = async () => {
        await getUnifiedListUtils({ setState }, getFoldersWithCountRequest)
      }
      getUnifiedList()
    }
  }, [])

  useEffect(() => {
    setState((state) => ({
      ...state,
      disableUnifiedLists: areUnifiedListsDisabled(listType, !!props.disableUnifiedLists, restrictMixingLegacyListsAndUCL, hasLegacyListsSelected),
      disableLegacyLists: areLegacyListsDisabled(listType, !!props.disableLegacyLists, restrictMixingLegacyListsAndUCL, selectedLists),
    }))
  }, [listType, hasLegacyListsSelected, selectedLists])

  const menuItems = useMenuItems({
    rootClass: `${rootClass}-body`,
    state,
    setState,
    clearSearch: onClearSearch,
    exclusivelyActOnContacts,
    hasLegacyLists: !hideLegacyLists,
    hasUnifiedLists,
    isLegacyForms,
    isLegacyWebinars,
    hideAllUCLListTypes: hideAllUCLList,
    isRestrictedToLegacyLists: !!restrictedToLegacyLists.length,
    t,
    hideCrmSynced: !hasCRMConnected,
    hideContactPreferences,
    hideLegacyListToggle,
    phase3FormSubmission,
  })

  const isUnifiedList = (!isListsOnly || isListsAndUCLOnly) && unifiedListTypes.includes(listType)
  const isAccountList = listType === ListCategory.ACCOUNTS
  const isFormSubmissionsUCL = listType === ListCategory.FORM_SUBMISSIONS_UCL
  const isWebinarUCL = listType === ListCategory.WEBINAR_UCL

  const baseParams = {
    setState,
    listType,
    disabledLists,
    disableLists,
    isListsOnly,
  }

  useEffect(() => {
    setCurrentList?.(listType)
  }, [listType, setCurrentList])

  useEffect(() => {
    if (!!unifiedListData && loading) {
      const updatedListType = hasUnifiedLists && !hideUCL && !hideAllUCLList && !keepListSelection ? ListCategory.UNIFIED_LIST : initialListCategory
      if (!restrictedToLegacyLists.length && listType !== updatedListType && initialListCategory !== updatedListType) {
        setState((state: ListPickerModalState) => ({
          ...state,
          listType: updatedListType,
        }))
      } else if (preSelectedListsInit && !search) {
        loadListType()
      }
    }
  }, [unifiedListData])

  useEffect(() => {
    if (foldersLoading) {
      if (isUnifiedList) {
        fetchFoldersAndTags()
      } else {
        getFoldersWithCountUtils(baseParams, getFoldersWithCountRequest, disableRowByCriteria)
      }
    }
  }, [foldersLoading])

  const setInitialState = (filter?: FilterDefinition) => setInitialStateUtils(setState, isUnifiedList, filter)

  const loadListType = () => {
    onClearSearch()
    !!segmentTreePage && clearSegmentTreePage()
    const hasSelectedLists = Object.entries(selectedLists).some(([type, lists]) => lists.length > 0 && listType === type)
    if (hasSelectedLists || isAccountList) {
      setInitialState(getMainFilter(listType))
    } else if (isUnifiedList) {
      setInitialState()
      checkAndSetUCLFavorites({
        ...baseParams,
        request: getFavoriteSegmentsRequest,
        syncedSegmentDetailsRequest,
        getCRMSegmentsInfoRequest,
        hasCRMConnected,
        pageNumber: 0,
        type: isFormSubmissionsUCL ? ItemType.FORM_SUBMISSION : isWebinarUCL ? ItemType.WEBINAR_SUBMISSION : ItemType.SEGMENT,
        hasSMS: hasPurchasedSMS,
        hasDefaultSelectedLists: !!defaultSelectedLists.length,
        disableRowByCriteria,
      }).then((hasFavorites) => {
        if (!hasFavorites) {
          setFilter(setState, getMainFilter(listType))
        }
      })
    } else {
      setInitialState()
      checkAndSetFavoritesLists(baseParams, getFavoritesListsRequest, disableRowByCriteria).then((hasFavorites: boolean) =>
        setState((state) => ({ ...state, hasToSetDefaultFolder: !hasFavorites, loading: false }))
      )
    }
  }

  useEffect(() => {
    if ((hideUCL || hideAllUCLList || !restrictedToLegacyLists.length) && preSelectedListsInit && !!unifiedListData && !search) {
      loadListType()
    }
  }, [listType, preSelectedListsInit])

  useEffect(() => {
    if (hasToSetDefaultFolder && foldersState.folders.length) {
      setFolder(setState, foldersState.folders.find((folder) => folder?.name === DEFAULT_FOLDER_NAME) as Folder)
      setState((state) => ({ ...state, hasToSetDefaultFolder: false }))
    }
  }, [foldersState.folders, hasToSetDefaultFolder])

  useEffect(() => {
    if (currentFilter && !restrictedToLegacyLists.length && !searchLoading) {
      search && onClearSearch()
      !!segmentTreePage && clearSegmentTreePage()
      loadFilter(0, true)
    }
  }, [currentFilter])

  useEffect(() => {
    if (currentFolder) {
      search && onClearSearch()
      loadFolder(0, true)
    }
  }, [currentFolder])

  useEffect(() => {
    if (currentTag) {
      search && onClearSearch()
      !!segmentTreePage && clearSegmentTreePage()
      loadTag(0, true)
    }
  }, [currentTag])

  useEffect(() => {
    !!segmentTreePage && clearSegmentTreePage()
    searchLoading && performSearch(false, false)
  }, [searchLoading])

  useEffect(() => {
    if (lists?.length && isUnifiedList) {
      setSegmentsMetadataUtils(setState, getSegmentMetadataRequest)
      if (getConnectedCrm !== 'UNSET') {
        getCRMUnsupportedTypes(setState, getConnectedCrm, getCRMNotSupportedEntitiesRequest, getEntityTypeCampaignRequest)
      }
    }
  }, [lists])

  useEffect(() => {
    if (!defaultSelectedListsInitRef.current && !listsState.listsLoading && defaultSelectedLists.length && !restrictedToLegacyLists.length) {
      defaultSelectedListsInitRef.current = true
      searchDefaultSelectedListsUtils(
        searchLegacyListsByIdsRequest,
        getAllItemsRequest,
        setState,
        defaultSelectedLists,
        listType,
        disableRowByCriteria,
        accountSettings
      )
    } else if (!defaultSelectedLists.length) {
      setState((state) => ({
        ...state,
        preSelectedListsInit: true,
      }))
    }
  }, [listsState.listsLoading, loading, listType])

  useEffect(() => {
    if (viewingSelectedLists) {
      clearSearchAndRefresh()
    } else if (headerShadow) {
      setState((state: ListPickerModalState) => ({ ...state, headerShadow: false }))
    }
  }, [viewingSelectedLists])

  useEffect(() => {
    if (!!restrictedToLegacyLists.length) {
      searchRestrictedLegacyLists(
        searchLegacyListsByIdsRequest,
        setState,
        defaultSelectedLists,
        restrictedToLegacyLists,
        disableRowByCriteria,
        showUnifiedFormSubmissions
      )
    }
  }, [restrictedToLegacyLists])

  useEffect(() => {
    setTimeout(() => {
      setDisableSubmit(false)
    }, 500)
  }, [selectedLists])

  const fetchFoldersAndTags = () =>
    getUnifiedListFoldersUtils(
      getFoldersRequest,
      setState,
      isFormSubmissionsUCL ? ItemType.FORM_SUBMISSION : isWebinarUCL ? ItemType.WEBINAR_SUBMISSION : ItemType.SEGMENT
    ).then(() =>
      getUnifiedListTagsUtils(
        getTagsRequest,
        setState,
        isFormSubmissionsUCL ? [ItemType.FORM_SUBMISSION] : isWebinarUCL ? [ItemType.WEBINAR_SUBMISSION] : [ItemType.SEGMENT, ItemType.BOUNCE]
      )
    )

  const onScroll = ({ target }: any) => {
    scrollableElementRef.current = target
    headerShadow
      ? target.scrollTop === 0 && setState((state: ListPickerModalState) => ({ ...state, headerShadow: false }))
      : target.scrollTop > 0 && setState((state: ListPickerModalState) => ({ ...state, headerShadow: true }))
  }

  const loadingLists = (pageNumber: number, showLoading: boolean) =>
    showLoading
      ? setState((state: ListPickerModalState) => ({ ...state, allLoaded: true, listsState: { ...state.listsState, listsLoading: true } }))
      : setState((state: ListPickerModalState) => ({ ...state, lazyLoading: pageNumber > 0 }))

  const loadFilter = (pageNumber: number, showLoading = false) => {
    const { name } = currentFilter as FilterDefinition
    if (isUnifiedList && !UCLFiltersActions[name]) {
      return null
    }

    loadingLists(pageNumber, showLoading)
    const request = getFilterRequest(name, getAllItemsRequest, getFavoriteSegmentsRequest, getRecentSegmentsRequest, getCreatedByMeSegmentsRequest)
    if (isUnifiedList) {
      UCLFiltersActions[name]({
        ...baseParams,
        disableRowByCriteria,
        request,
        syncedSegmentDetailsRequest,
        getCRMSegmentsInfoRequest,
        hasCRMConnected,
        pageNumber,
        type:
          name === FilterTypes.CONTACT_PREFERENCES
            ? ItemType.BOUNCE
            : isFormSubmissionsUCL
            ? ItemType.FORM_SUBMISSION
            : isWebinarUCL
            ? ItemType.WEBINAR_SUBMISSION
            : ItemType.SEGMENT,
        hasSMS: hasPurchasedSMS,
        hasDefaultSelectedLists: !!defaultSelectedLists.length,
        accountSettings,
      })
    } else if (!isAccountList) {
      currentFilter?.name === FilterTypes.FAVORITES
        ? getFavoritesListsUtils(baseParams, getFavoritesListsRequest, disableRowByCriteria)
        : getMainFilterListUtils(baseParams, pageNumber, getFolderListsByTypeRequest, disableRowByCriteria)
    }
  }

  const loadFolder = (pageNumber: number, showLoading = false) => {
    loadingLists(pageNumber, showLoading)
    const { id } = currentFolder as Folder
    isUnifiedList
      ? getUnifiedListFolderItemsUtils(
          {
            ...baseParams,
            getSegmentsInFolderRequest,
            syncedSegmentDetailsRequest,
            getCRMSegmentsInfoRequest,
            hasCRMConnected,
            pageNumber,
            type: isFormSubmissionsUCL ? ItemType.FORM_SUBMISSION : isWebinarUCL ? ItemType.WEBINAR_SUBMISSION : ItemType.SEGMENT,
            hasDefaultSelectedLists: !!defaultSelectedLists.length,
            disableRowByCriteria,
          },
          id
        )
      : getFoldersListUtils(baseParams, currentFolder, getFolderListsRequest, disableRowByCriteria)
  }

  const loadTag = (pageNumber: number, showLoading = false) => {
    loadingLists(pageNumber, showLoading)
    const { id } = currentTag as LabelDto
    getUnifiedListTagItemsUtils(
      {
        ...baseParams,
        getSegmentsInFolderAndTagsRequest,
        syncedSegmentDetailsRequest,
        getCRMSegmentsInfoRequest,
        hasCRMConnected,
        pageNumber,
        type: isFormSubmissionsUCL ? ItemType.FORM_SUBMISSION : isWebinarUCL ? ItemType.WEBINAR_SUBMISSION : ItemType.SEGMENT,
        hasDefaultSelectedLists: !!defaultSelectedLists.length,
        disableRowByCriteria,
        accountSettings,
      },
      id
    )
  }

  const refreshList = (page: number) => {
    if (unifiedListTypes.includes(listType)) {
      currentFilter && loadFilter(page)
      currentFolder && loadFolder(page)
      currentTag && loadTag(page)
    } else if (currentFilter?.name === getMainFilter(listType).name) {
      currentFilter && loadFilter(page)
    }
  }

  const getSegmentDefinition = (externalId: string) => getSegmentDefinitionUtils(externalId, setState, getSegmentFilterRequest)

  const onSubmitLists = () =>
    submitLists(
      Object.values(selectedLists)
        .flatMap((lists) => lists)
        .map(({ original }) => original)
    )

  const clearSearchAndRefresh = () => {
    onClearSearch()
    refreshList(0)
  }

  const setSegmentTreeLoading = (loading: boolean) =>
    setState((state) => ({ ...state, segmentTreePage: { ...state.segmentTreePage, segmentTreeLoading: loading } }))

  const setClassicSegmentAncestorsTree = (segmentId: string, listType: ListCategory) => {
    setSegmentTreeLoading(true)
    setClassicSegmentAncestorsTreeUtils({ segmentId, listType, setState, searchLegacyListsByIdsRequest, disableRowByCriteria })
  }

  const setClassicSegmentChildrenTree = (segmentId: string, listType: ListCategory) =>
    setClassicSegmentChildrenTreeUtils({ segmentId, listType, setState, listPollRequest, disableRowByCriteria }).finally(() =>
      setSegmentTreeLoading(false)
    )

  const setSegmentAncestorsTree = (segmentId: number, listType: ListCategory) => {
    setSegmentTreeLoading(true)
    setSegmentAncestorsTreeUtils({ segmentId, listType, setState, getSegmentHierarchyRequest })
  }
  const setSegmentChildrenTree = (segmentId: number, listType: ListCategory) =>
    setSegmentChildrenTreeUtils({ segmentId, listType, setState, getItemChildrenHierarchyRequest, disableRowByCriteria }).finally(() =>
      setSegmentTreeLoading(false)
    )

  const toggleSelectedLists = () => setState((state: ListPickerModalState) => ({ ...state, viewingSelectedLists: !viewingSelectedLists }))

  const doSearch = (value: string) => value.length >= 3 && doSearchUtils(state, setState, value)

  const performSearch = (searchAll: boolean, searchInAllLists: boolean) => {
    const type = isFormSubmissionsUCL ? ItemType.FORM_SUBMISSION : isWebinarUCL ? ItemType.WEBINAR_SUBMISSION : ItemType.SEGMENT
    setState((state: ListPickerModalState) => ({ ...state, search: { ...state.search, filterLoading: true, count: 0 } }))
    if (searchInAllLists) {
      searchInAllListsUtils(
        baseParams,
        state,
        search?.query as string,
        hideUCL,
        hideAllUCLList,
        hideLegacyLists,
        restrictMixingLegacyListsAndUCL,
        phase3FormSubmission && !isLegacyForms,
        hasAOCWebinars && !isLegacyWebinars,
        type,
        searchSegmentsRequest,
        searchListsRequest,
        disableRowByCriteria
      )
    } else if (isUnifiedList) {
      searchUCLUtils(
        state,
        setState,
        searchSegmentsRequest,
        searchSegmentsByFilterRequest,
        searchSegmentsByTagsRequest,
        search?.query as string,
        type,
        disableRowByCriteria,
        accountSettings,
        searchAll
      )
    } else {
      searchListsUtils(baseParams, state, searchAll, restrictMixingLegacyListsAndUCL, searchListsRequest, disableRowByCriteria)
    }
  }

  useEffect(() => {
    const disableByCriteria = disableSubmitByRowCriteria?.(selectedLists)
    setDisableSubmitByCriteria(!!disableByCriteria)
  }, [disableSubmitByRowCriteria, selectedLists])

  const tableDeps = [
    allLoaded,
    currentFilter,
    currentFolder,
    currentPage,
    currentTag,
    disabledLists,
    disableLists,
    foldersState,
    headerShadow,
    lazyLoading,
    listsState,
    listType,
    search,
    selectedLists,
    tagsState,
    viewingSelectedLists,
    disableSubmitByRowCriteria,
  ]

  const tableProps = useMemo(() => {
    const tableParams = {
      disabledListTooltipText,
      disableCheckboxHeader: disableLegacyLists || disableUnifiedLists || allListsDisabled,
      expandAll: false,
      hasToExpand: getHasToExpandFunction(state),
      multiSelect,
      onRowSelectionChanged: (selectedRows: Row[], rows?: Row[], selectedRowIds?: any) => {
        !!selectedRows.length && setDisableSubmit(true)
        getOnRowSelectionChangedFunction(setState, state, multiSelect)(selectedRows, rows, selectedRowIds)
      },
      onScroll,
      refreshList,
      scrollableElement: scrollableElementRef.current ? scrollableElementRef : undefined,
      state,
      showTooltipByCriteria,
      selectSubRows,
    }
    const UCLTableParams = { ...tableParams, onClickTagInRow: getOnClickTagInRowFunction(setState, state, tags) }
    return {
      ...(isUnifiedList ? getUCLTableProps(UCLTableParams) : getTableProps(tableParams)),
    }
  }, tableDeps) as ActionableNestedTableWithLazyLoadingProps

  const getCRMSourcedSegmentDetails = (segmentExternalIds: string[]) =>
    getCRMSourcedSegmentDetailsUtils(segmentExternalIds, syncedSegmentDetailsRequest)

  return (
    <ListPickerModalContext.Provider
      value={{
        values: state,
        setState,
        getSegmentDefinition,
        getCRMSourcedSegmentDetails,
        performSearch,
        setClassicSegmentAncestorsTree,
        setClassicSegmentChildrenTree,
        setSegmentAncestorsTree,
        setSegmentChildrenTree,
        showTooltipByCriteria,
      }}
    >
      {showToast && <StatusToast message={message} status={status} closeStatus={() => closeStatusToast(setState)} />}
      <Modal
        dataTest={dataTest}
        className={rootClass}
        isOpen={isOpen}
        useCustomScrollAreas={`${rootClass}__custom-scroll-area`}
        noPadding
        header={
          <ListPickerModalHeader
            doSearch={doSearch}
            multiSelect={multiSelect}
            onClearSearch={clearSearchAndRefresh}
            onBackArrowClick={onBackArrowClick}
            title={headerTitle}
            hideSearchBar={!!restrictedToLegacyLists.length}
          />
        }
      >
        <ListPickerModalBody
          customBanner={customBanner}
          hideLegacyLists={hideLegacyLists}
          menuItems={menuItems}
          restrictMixingLegacyListsAndUCL={restrictMixingLegacyListsAndUCL}
          showCaution={disableLists}
          tableProps={tableProps}
        />
        <ListPickerModalFooter
          dataTest={`${dataTest}-footer`}
          cancelButtonText={cancelButtonText}
          closeModal={closeModal}
          selectedLists={Object.values(selectedLists)}
          submitButtonText={submitButtonText}
          submitLists={onSubmitLists}
          toggleSelectedLists={toggleSelectedLists}
          submitTooltipText={submitTooltipText}
          submitDisabledWithoutSelection={submitDisabledWithoutSelection}
          isMultiSelect={multiSelect}
          isRestrictedToLegacyLists={!!restrictedToLegacyLists.length}
          disableSubmit={disableSubmit}
          disableSubmitByCriteria={disableSubmitByCriteria}
          submitDisabledOnSelect={submitDisabledOnSelect}
          hideLegacyListToggle={hideLegacyListToggle}
        />
      </Modal>
    </ListPickerModalContext.Provider>
  )
}

export default ListPickerModal
