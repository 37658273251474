export const personalizationPattern = /{{(={{)?[^}}]+}}(([^\s}}])+}})?/g
const numberPattern = /\d+(?=(\)}}))/g
export const replacePersonalizationWithX = (message: string) => {
  const regexMatches = message.match(personalizationPattern)
  if (regexMatches && regexMatches.length) {
    const result = regexMatches.reduce((acc: string, regexMatch) => {
      const numberMatch = regexMatch.match(numberPattern)
      // By regex this should only match digits. This will currently only match 1 number based on specs
      // But other combinations exist than the above examples and this will need updating as we add permutations
      const number = numberMatch ? parseInt(numberMatch[0]) : 0
      if (number == 0) return acc
      const charactersToAdd = `(${new Array(number - 1).join('X')})`
      return acc.replace(regexMatch, charactersToAdd)
    }, message)
    return result
  }

  return message
}
