import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ImagePickerView } from '@components/AssetPickers/ImagePickerModal/utils/ImagePickerModal.utils'
import TemplateCatalogModalGridView from '@components/AssetPickers/TemplateCatalogModal/components/TemplateCatalogModalContent/TemplateCatalogModalGridView'
import TemplateCatalogModalTableView from '@components/AssetPickers/TemplateCatalogModal/components/TemplateCatalogModalContent/TemplateCatalogModalTableView'
import Button, { ButtonType, ButtonWeight } from '@components/Button/Button'
import Chip from '@components/Chip/Chip'
import LazyLoadOnScrollContainer from '@components/LazyLoadOnScrollContainer/LazyLoadOnScrollContainer'
import { SvgNames } from '@components/Svg'
import SwitchButtons from '@components/SwitchButtons/SwitchButtons'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import { BeeTemplate } from '@graphql/types/microservice/email-management-types'

import TemplateCatalogModalEmptyListing from './TemplateCatalogModalEmptyListing'
import TemplateCatalogModalSearchResults from './TemplateCatalogModalSearchResults'
import { useTemplateCatalogModalContentContext } from '../../TemplateCatalogModal.context'

import './TemplateCatalogModalContent.css'

interface TemplateCatalogModalContentModalContentProps {
  onLoadNext: () => Promise<void>
  allLoaded: boolean
  saveScrollSessionKey?: string
  dataTest?: string
  view: ImagePickerView
  handleViewChange: (view: ImagePickerView) => void
}

const rootClass = 'template-catalog-modal-content'

const TemplateCatalogModalContent: FC<TemplateCatalogModalContentModalContentProps> = ({
  onLoadNext,
  allLoaded,
  view,
  saveScrollSessionKey,
  handleViewChange,
  dataTest = rootClass,
}) => {
  const isListView = view === ImagePickerView.LIST

  const { t } = useTranslation()

  const { catalogItems, templatesLoading, activeSubTypes, loadingNextData, onTemplateSelect, onSubTypeChange, onSubTypesClear } =
    useTemplateCatalogModalContentContext()

  const handleSelect = useCallback((item: BeeTemplate) => {
    onTemplateSelect(item)
  }, [])

  const emptyState = !templatesLoading && !catalogItems.length

  return (
    <>
      <div className={`${rootClass}__filters`} data-test={`${dataTest}-filters`}>
        <TemplateCatalogModalSearchResults dataTest={`${dataTest}-search-results`} />

        {!!activeSubTypes.length && (
          <>
            <div className={`${rootClass}__sub-types-wrapper`}>
              <div className={`${rootClass}__sub-types`}>
                <Typography
                  text={'Filters:'}
                  type={TextType.SUB_HEADER}
                  lineHeight={LineHeight.MEDIUM_SMALL}
                  className={`${rootClass}__sub-types-header`}
                />
                <div className={`${rootClass}__sub-types-chips`}>
                  {activeSubTypes.map((subType) => (
                    <Chip
                      key={subType.name}
                      id={subType.name}
                      name={subType.name}
                      onRemove={() => onSubTypeChange?.(subType)}
                      className={`${rootClass}__sub-types-chip`}
                    />
                  ))}
                </div>
              </div>
              <Button
                buttonType={ButtonType.TEXT_TEAL}
                onClick={onSubTypesClear}
                weight={ButtonWeight.MEDIUM}
                className={`${rootClass}__sub-types-clear`}
              >
                {t('Clear all')}
              </Button>
            </div>
            <div className={`${rootClass}__sub-types-divider`} />
          </>
        )}

        {!emptyState && (
          <SwitchButtons
            onChange={handleViewChange}
            icons={[
              { icon: SvgNames.gridView, key: ImagePickerView.GRID },
              { icon: SvgNames.listView, key: ImagePickerView.LIST },
            ]}
            defaultActive={view}
            dataTest={`${dataTest}-switch`}
          />
        )}
      </div>
      {emptyState ? (
        <TemplateCatalogModalEmptyListing dataTest={`${dataTest}-empty-listing`} />
      ) : (
        <LazyLoadOnScrollContainer
          onLoadMoreData={onLoadNext}
          hideSpinner={isListView}
          loadingNextData={loadingNextData}
          className={`${rootClass}__container`}
          allLoaded={allLoaded}
          saveScrollSessionKey={saveScrollSessionKey}
        >
          {isListView ? (
            <TemplateCatalogModalTableView onSelect={handleSelect} />
          ) : (
            <TemplateCatalogModalGridView dataTest={`${dataTest}-grid`} onSelect={handleSelect} />
          )}
        </LazyLoadOnScrollContainer>
      )}
    </>
  )
}

export default TemplateCatalogModalContent
