import React, { FC } from 'react'

import Button, { ButtonType } from '@components/Button'
import { ModalBody, ModalFooter } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { CrmNames } from '@const/crmNames'
import { useTranslation } from '@const/globals'
import { Program } from '@graphql/types/query-types'
import AddToCRMDetail from '@src/pages/programs/dashboard/components/ProgramSteps/components/AddToCRMDetail/AddToCRMDetail'
import { ProgramSForceCampaignStepExt, Step } from '@utils/program/program.constants'

import EditMSDynamicsStep from './msDynamics/EditMSDynamicsStep'
import EditAddCrmSalesforceStep from './salesforce/EditAddCrmSalesforceStep'

interface Props {
  step: Step
  program: Program
  isRunning: boolean
  closeModal(): void
  saveStepAndProgram(step: Step | null, program?: Program): void
  dataTest?: string
}

const rootClass = 'edit-add-crm'

const EditAddCrmStep: FC<Props> = (props: Props) => {
  const { step, program, isRunning, closeModal, saveStepAndProgram, dataTest = rootClass } = props
  const { t } = useTranslation()

  if (isRunning) {
    const { campaignName, status } = step as ProgramSForceCampaignStepExt
    return (
      <>
        <ModalBody>
          <Typography text={step.displayName} weight={TextWeight.MEDIUM} type={TextType.SECTION_HEADER} />
          <AddToCRMDetail campaignName={campaignName ?? ''} status={status ?? ''} />
        </ModalBody>
        <ModalFooter footerType={ModalFooterType.Form} flexEnd>
          <Button buttonType={ButtonType.TERTIARY} onClick={closeModal} dataTest={`${dataTest}-close-button`}>
            {t('Close')}
          </Button>
        </ModalFooter>
      </>
    )
  }

  if (program.crm?.crmName === CrmNames.SALESFORCE) {
    return <EditAddCrmSalesforceStep step={step} program={program} closeModal={closeModal} saveStepAndProgram={saveStepAndProgram} />
  } else if (program.crm?.crmName === CrmNames.MSDYNAMICS) {
    return <EditMSDynamicsStep step={step} crm={program.crm} saveStepAndProgram={saveStepAndProgram} closeModal={closeModal} />
  }
  return null
}

export default EditAddCrmStep
