import { CRMTableCriteriaType } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/CRMExpression/CRMExpression.interfaces'

export const crmTableCategoryMock: string[] = ['Table Category 1', 'Table Category 2', 'Table Category 3', 'Table Category 4', 'Table Category 5']

export const crmTableDataMock: { name: string; type: CRMTableCriteriaType }[] = [
  {
    name: 'Text-based data',
    type: 'TEXT',
  },
  {
    name: 'Numeric based data',
    type: 'NUMERIC',
  },
  {
    name: 'Date-based data',
    type: 'DATE',
  },
]
