import { Dispatch, SetStateAction } from 'react'

import {
  GetCRMNotSupportedEntitiesRequestType,
  GetEntityTypeCampaignRequestType,
} from '@complex/ListPickerModalV2/utils/graphql/ListPickerModalSegmentRequests.graphQL'
import { ListPickerModalState } from '@complex/ListPickerModalV2/utils/interfaces/ListPickerModalInterfaces'
import { EntityTypeQuery } from '@graphql/types/microservice/crm-types'
import { CrmCampaign } from '@utils/contactSegments/contactSegments.utils'
import { logNewRelicError } from '@utils/new-relic.utils'

export const getCRMUnsupportedTypes = (
  setState: Dispatch<SetStateAction<ListPickerModalState>>,
  connectedCRM: string,
  getCRMNotSupportedEntitiesRequest: GetCRMNotSupportedEntitiesRequestType,
  getEntityTypeCampaignRequest: GetEntityTypeCampaignRequestType
) =>
  getCRMNotSupportedEntitiesRequest({ connectedCRM })
    .then(({ data }) => {
      if (data) {
        const unsupportedTypes = data.crmDefinition?.unsupportedTypes?.map((type) => type?.name)
        if (unsupportedTypes?.every((type) => type !== 'Campaign')) {
          setCRMCampaignsUtils(setState, getEntityTypeCampaignRequest)
        }
      }
    })
    .catch((error) => {
      logNewRelicError(error)
    })

const getEntityDataMapping = ({ entityType }: EntityTypeQuery) =>
  entityType?.entityTypeDataMapping?.reduce((campaigns: Record<string, string>, element: CrmCampaign) => {
    campaigns[element.Id] = element.Name
    return campaigns
  }, {})

export const setCRMCampaignsUtils = (setState: Function, getEntityTypeCampaignRequest: GetEntityTypeCampaignRequestType) => {
  setState((state: ListPickerModalState) => ({
    ...state,
    metadataLoading: state.metadataLoading ?? true,
  }))
  getEntityTypeCampaignRequest()
    .then(({ data }) => {
      if (data) {
        setState((state: ListPickerModalState) => ({
          ...state,
          campaigns: {
            ...state.campaigns,
            ...getEntityDataMapping(data),
          },
        }))
      }
    })
    .catch((e) => logNewRelicError(e))
    .finally(() => setState((state: ListPickerModalState) => ({ ...state, metadataLoading: false })))
}
