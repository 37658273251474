import React, { MouseEvent, ReactNode } from 'react'
import { Row } from 'react-table'

import { renderSegmentNameWithInfoHover } from '@complex/ContactSegments/segmentInfoHover.utils'
import SegmentInfoHoverCard from '@complex/ContactSegments/SegmentInfoHoverCard/SegmentInfoHoverCard'
import SegmentNameWithFtpSyncAndInfoHover from '@complex/ContactSegments/SegmentNameWithFtpSyncAndInfoHover/SegmentNameWithFtpSyncAndInfoHover'
import { DeleteConfirmationModals } from '@complex/ListingPage/Components/ListingPageModals/ListingPageModals'
import { TableActions } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.constants'
import { getActionProps } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.utils'
import {
  ListingPageItem,
  ListPageAPI,
  ListPageCommonState,
  ListPageTableActionCustomProps,
  TableProps,
  Update,
} from '@complex/ListingPage/Context/ListingPageCommon.context'
import DependenciesContentModal, {
  DependenciesContentModalItem,
  DependenciesItemType,
} from '@components/DependenciesContentModal/DependenciesContentModal'
import DuplicateFolderModal from '@components/DuplicateModal/DuplicateFolderModal'
import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import { SegmentDetailsContainerState } from '@components/SegmentDetails/context/SegmentDetails.context'
import { sendEmailToSegment } from '@components/SegmentDetails/SegmentDetails.utils'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { Status } from '@components/StatusToast/StatusToast'
import { SvgNames, SvgType } from '@components/Svg'
import Svg from '@components/Svg/Svg'
import { HeaderAction, RowAction } from '@components/Table/Table'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Tooltip from '@components/Tooltip/Tooltip'
import { rootContext } from '@const/globals'
import { ItemDto, LabelDto } from '@graphql/types/microservice/categorization-types'
import { FormSubmissionSummaryDto } from '@graphql/types/microservice/entity-join-types'
import { RegenerateSplitsMutation } from '@graphql/types/microservice/segment-types'
import { RefreshRecordCountMutation } from '@graphql/types/mutation-types'
import { AccountSettings } from '@graphql/types/query-types'
import { goCreateSegment } from '@src/pages/ContactSegments/utils/ContactSegmentsContainerUtils'
import CreateWebinarSegmentsModal, {
  BeforeRules,
  FollowUpRules,
} from '@src/pages/listingPages/FormsJoinView/components/CreateWebinarSegmentsModal/CreateWebinarSegmentsModal'
import DeleteContactsConfirmationModal, {
  ActionType,
} from '@src/pages/listingPages/FormsJoinView/components/DeleteContactsConfirmationModal/DeleteContactsConfirmationModal'
import { WebinarType } from '@src/pages/listingPages/FormsJoinView/components/DetailsCardWrapper/utils/DetailsCardWrapper.utils'
import FormSelectorModal, { FormSelectorType } from '@src/pages/listingPages/FormsJoinView/components/FormSelectorModal/FormSelectorModal'
import AttendanceAndConversionRateLineChart from '@src/pages/listingPages/FormsJoinView/components/FormSubmissionInfoHoverCard/components/AttendanceAndConversionRateLineChart/AttendanceAndConversionRateLineChart'
import FormSubmissionInfoHoverCard, {
  formSubmissionInfoHoverCardRootClass,
} from '@src/pages/listingPages/FormsJoinView/components/FormSubmissionInfoHoverCard/FormSubmissionInfoHoverCard'
import WebinarRegistrationInfoHoverCard from '@src/pages/listingPages/FormsJoinView/components/WebinarRegistrationInfoHoverCard/WebinarRegistrationInfoHoverCard'
import {
  FORM_SUBMISSION_LIST_URL,
  FormsJoinViewListingPageContainerState,
  WEBINAR_SUBMISSION_LIST_URL,
} from '@src/pages/listingPages/FormsJoinView/FormsJoinViewListingPageContainer.constants'
import {
  FormSubmissionList,
  onEditForm,
  onEditSegment,
  onViewFormReport,
} from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.utils'
import { ItemType } from '@utils/categorization'
import { FtpExecutionStatus, FtpExportExecutionStatus, saveSelectedListsToLocalStorage, Segment } from '@utils/contactSegments/contactSegments.utils'
import { relativeDate } from '@utils/date'
import { allFormSubmissionsFilter, allWebinarSubmissionsFilter } from '@utils/filter'
import { CRMConnectorType } from '@utils/hooks/useCRM'
import { logNewRelicError } from '@utils/new-relic.utils'
import { getFormattedNumber } from '@utils/numbers'
import { renderPathToFolder } from '@utils/searchUtils'
import { FetchPromise } from '@utils/types'

interface WebinarDto extends ListingPageItem {
  webinarId?: string
  parent?: string
  subRows?: Segment[]
}

export enum FormsJoinViewCustomModals {
  CLEAR_RECORDS = 'CLEAR_RECORDS',
  CREATE_WEBINAR_SEGMENTS = 'CREATE_WEBINAR_SEGMENTS',
  DELETE_ALL_CONTACTS = 'DELETE_ALL_CONTACTS',
  DELETE_SEGMENT = 'DELETE_SEGMENT',
  DUPLICATE = 'DUPLICATE_SEGMENT',
  EDIT_FORM = 'EDIT_FORM',
  PREVIEW_FORM = 'PREVIEW_FORM',
  VIEW_FORM_REPORT = 'VIEW_FORM_REPORT',
}

export enum FormsJoinViewCustomTableActions {
  CLEAR_RECORDS = 'CLEAR_RECORDS',
  CREATE_SEGMENT = 'CREATE_SEGMENT',
  CREATE_WEBINAR_SEGMENTS = 'CREATE_WEBINAR_SEGMENTS',
  DELETE = 'DELETE',
  DELETE_ALL_CONTACTS = 'DELETE_ALL_CONTACTS',
  DUPLICATE = 'DUPLICATE_SEGMENT',
  EDIT = 'EDIT',
  EDIT_FORM = 'EDIT_FORM',
  EXPORT = 'EXPORT',
  GENERATE_SPLIT = 'GENERATE_SPLIT',
  PREVIEW_FORM = 'PREVIEW_FORM',
  REFRESH_RECORDS = 'REFRESH_RECORDS',
  REGENERATE_SPLIT = 'REGENERATE_SPLIT',
  SEND_EMAIL = 'SEND_EMAIL',
  VIEW_FORM_REPORT = 'VIEW_FORM_REPORT',
  VIEW_WEBINAR_DETAILS = 'VIEW_WEBINAR_DETAILS',
}

const rowActionCustomProps: ListPageTableActionCustomProps[] = [
  { name: 'ADD_TO_FAVORITES', position: 0, hasTopSection: false, isInDropdown: false },
  { name: FormsJoinViewCustomTableActions.REFRESH_RECORDS, position: 1, isInDropdown: false },
  { name: FormsJoinViewCustomTableActions.PREVIEW_FORM, position: 2, isInDropdown: false },
  { name: FormsJoinViewCustomTableActions.SEND_EMAIL, position: 3, hasTopSection: false, isInDropdown: true },
  { name: FormsJoinViewCustomTableActions.EDIT, position: 4, hasTopSection: false, isInDropdown: true },
  { name: FormsJoinViewCustomTableActions.EDIT_FORM, position: 5, hasTopSection: false, isInDropdown: true },
  { name: FormsJoinViewCustomTableActions.VIEW_WEBINAR_DETAILS, position: 5, hasTopSection: false, isInDropdown: true },
  { name: FormsJoinViewCustomTableActions.VIEW_FORM_REPORT, position: 6, hasTopSection: false, isInDropdown: true },
  { name: FormsJoinViewCustomTableActions.CREATE_WEBINAR_SEGMENTS, position: 6, hasTopSection: false, isInDropdown: true },
  { name: FormsJoinViewCustomTableActions.CREATE_SEGMENT, position: 7, hasTopSection: true, isInDropdown: true },
  { name: FormsJoinViewCustomTableActions.GENERATE_SPLIT, position: 8, hasTopSection: false, isInDropdown: true },
  { name: FormsJoinViewCustomTableActions.REGENERATE_SPLIT, position: 9, hasTopSection: false, isInDropdown: true },
  { name: FormsJoinViewCustomTableActions.DUPLICATE, position: 10, hasTopSection: false, isInDropdown: true },
  { name: FormsJoinViewCustomTableActions.EXPORT, position: 11, hasTopSection: false, isInDropdown: true },
  { name: 'MOVE_TO_FOLDER', position: 12, hasTopSection: true, isInDropdown: true },
  { name: 'REMOVE_FROM_FOLDER', position: 13, hasTopSection: false, isInDropdown: true },
  { name: FormsJoinViewCustomTableActions.CLEAR_RECORDS, position: 14, hasTopSection: true, isInDropdown: true },
  { name: FormsJoinViewCustomTableActions.DELETE_ALL_CONTACTS, position: 15, hasTopSection: true, isInDropdown: true },
  { name: 'DELETE', position: 16, hasTopSection: false, isInDropdown: true },
]

const headerActionCustomProps: ListPageTableActionCustomProps[] = [
  { name: 'ADD_TO_FAVORITES', position: 0, isInDropdown: false },
  { name: 'MANAGE_TAGS', position: 1, isInDropdown: false },
  { name: 'MOVE_TO_FOLDER', position: 2, isInDropdown: false },
  { name: 'REMOVE_FROM_FOLDER', position: 3, isInDropdown: false },
  { name: 'SEND_EMAIL', position: 4, isInDropdown: false },
  { name: FormsJoinViewCustomTableActions.REFRESH_RECORDS, position: 5, isInDropdown: false },
  { name: FormsJoinViewCustomTableActions.CLEAR_RECORDS, position: 6, isInDropdown: true },
  { name: 'DELETE', position: 7, isInDropdown: true },
]

const onCustomTableAction = (
  customTableAction: FormsJoinViewCustomTableActions,
  update: Update,
  listPageValues: ListPageCommonState,
  t: Function,
  urlPush: Function,
  regenerateSplit: (id: string) => FetchPromise<RegenerateSplitsMutation>,
  refreshRecords: (ids: string[]) => FetchPromise<RefreshRecordCountMutation>,
  itemType: ItemType,
  onExport: (segment: Segment) => void,
  {}: AccountSettings
) => {
  const { selectedRows, activeFolderId } = listPageValues
  const selectedList = selectedRows[0] as FormSubmissionList
  const customAction: { [key in FormsJoinViewCustomTableActions]: () => void } = {
    [FormsJoinViewCustomTableActions.DELETE]: () => {
      update({ showCustomModal: true, customTableAction: FormsJoinViewCustomModals.DELETE_SEGMENT })
    },
    [FormsJoinViewCustomTableActions.DELETE_ALL_CONTACTS]: () => {
      update({ showCustomModal: true, customTableAction: FormsJoinViewCustomModals.DELETE_ALL_CONTACTS })
    },
    [FormsJoinViewCustomTableActions.DUPLICATE]: () => {
      update({ showCustomModal: true, customTableAction: FormsJoinViewCustomModals.DUPLICATE })
    },
    [FormsJoinViewCustomTableActions.EXPORT]: () => onExport(selectedRows[0] as Segment),
    [FormsJoinViewCustomTableActions.GENERATE_SPLIT]: () => {
      urlPush(`${rootContext}/segments/splits/${selectedList?.externalId}/${itemType}`)
    },
    [FormsJoinViewCustomTableActions.REGENERATE_SPLIT]: () => {
      if (selectedList?.externalId) {
        regenerateSplit(selectedList.externalId)
          .then(() => {
            update({
              statusToast: {
                statusMessage: t('Splits successfully regenerated'),
                status: Status.SUCCESS,
                showStatusToast: true,
              },
            })
          })
          .catch((error) => {
            update({
              statusToast: {
                statusMessage: t('Something went wrong on our end. Please try again.'),
                status: Status.FAIL,
                showStatusToast: true,
              },
            })
            logNewRelicError(error)
          })
      }
    },
    [FormsJoinViewCustomTableActions.CREATE_SEGMENT]: () => {
      goCreateSegment(itemType === ItemType.WEBINAR_SUBMISSION ? WEBINAR_SUBMISSION_LIST_URL : FORM_SUBMISSION_LIST_URL, {
        parentId: selectedList?.externalId ?? '',
        ...(itemType === ItemType.WEBINAR_SUBMISSION ? { isWebinarRegistration: true } : { isFormSubmission: true }),
        activeFolderId,
      })
    },
    [FormsJoinViewCustomTableActions.SEND_EMAIL]: () => {
      saveSelectedListsToLocalStorage([selectedList])

      sendEmailToSegment(selectedList?.externalId ?? '')
    },
    [FormsJoinViewCustomTableActions.REFRESH_RECORDS]: () => {
      const ids = selectedRows.reduce((lists: string[], row) => (row.externalId ? [...lists, row.externalId] : lists), [])
      if (ids.length) {
        refreshRecords(ids)
          .then(() => {
            update({
              statusToast: {
                statusMessage: "We're counting your records now. This may take some time. Check back later for an updated record count.",
                status: Status.SUCCESS,
                showStatusToast: true,
              },
              fetchItems: true,
              loading: true,
            })
          })
          .catch((error) => {
            update({
              statusToast: {
                statusMessage: 'Something went wrong on our end. Please try again.',
                status: Status.FAIL,
                showStatusToast: true,
              },
            })
            logNewRelicError(error)
          })
      }
    },
    [FormsJoinViewCustomTableActions.PREVIEW_FORM]: () => {
      update({ showCustomModal: true, customTableAction: FormsJoinViewCustomModals.PREVIEW_FORM })
    },
    [FormsJoinViewCustomTableActions.EDIT]: () => {
      onEditSegment(selectedList?.externalId ?? '', window.location.pathname)
    },
    [FormsJoinViewCustomTableActions.EDIT_FORM]: () => {
      update({ showCustomModal: true, customTableAction: FormsJoinViewCustomModals.EDIT_FORM })
    },
    [FormsJoinViewCustomTableActions.VIEW_FORM_REPORT]: () => {
      update({ showCustomModal: true, customTableAction: FormsJoinViewCustomModals.VIEW_FORM_REPORT })
    },
    [FormsJoinViewCustomTableActions.CREATE_WEBINAR_SEGMENTS]: () => {
      update({ showCustomModal: true, customTableAction: FormsJoinViewCustomModals.CREATE_WEBINAR_SEGMENTS })
    },
    [FormsJoinViewCustomTableActions.VIEW_WEBINAR_DETAILS]: () => {
      const selectedWebinar = selectedList as WebinarDto
      if (selectedWebinar.webinarId && selectedWebinar.subTypeDTO && selectedWebinar.subTypeDTO.length > 0) {
        const webinarId = selectedWebinar.webinarId
        const webinarType = selectedWebinar.subTypeDTO[0]?.name || ''
        urlPush(`${rootContext}/classic/if/webinar/listing.jsp?id=${webinarId}&${webinarType}`)
      }
    },
    [FormsJoinViewCustomTableActions.CLEAR_RECORDS]: () => {
      update({ showCustomModal: true, customTableAction: FormsJoinViewCustomModals.CLEAR_RECORDS })
    },
  }
  customAction[customTableAction]()
}

const getCustomRowActions = (
  tableActions: TableActions,
  t: Function,
  itemType: ItemType,
  { userAllowedToDelete, disableSegmentModifications, isUsingExternalSegmentationService, userAllowedToDownload }: AccountSettings
): RowAction[] => [
  {
    label: t('Send email'),
    icon: SvgNames.letter,
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(FormsJoinViewCustomTableActions.SEND_EMAIL, row),
    ...getActionProps(FormsJoinViewCustomTableActions.SEND_EMAIL, rowActionCustomProps),
  },
  {
    label: (row) => t(`Create ${!!row.original.parent ? 'subsegment' : 'segment'}`),
    icon: SvgNames.createSubsegment,
    hidden: isUsingExternalSegmentationService,
    disabled: disableSegmentModifications,
    tooltipMessage: disableSegmentModifications ? 'Segment.Tooltip.Disabled.Migration' : undefined,
    hasTooltip: true,
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(FormsJoinViewCustomTableActions.CREATE_SEGMENT, row),
    ...getActionProps(FormsJoinViewCustomTableActions.CREATE_SEGMENT, rowActionCustomProps),
  },
  {
    label: t('Generate A/B split'),
    icon: SvgNames.arrowDiverge,
    hidden: isUsingExternalSegmentationService,
    disabled: disableSegmentModifications,
    tooltipMessage: disableSegmentModifications ? 'Segment.Tooltip.Disabled.Migration' : undefined,
    hasTooltip: true,
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(FormsJoinViewCustomTableActions.GENERATE_SPLIT, row),
    ...getActionProps(FormsJoinViewCustomTableActions.GENERATE_SPLIT, rowActionCustomProps),
  },
  {
    label: t('Regenerate splits'),
    icon: SvgNames.reload,
    disabled: disableSegmentModifications,
    tooltipMessage: disableSegmentModifications ? 'Segment.Tooltip.Disabled.Migration' : undefined,
    hasTooltip: true,
    hidden: ({ original: { hasSplitsToRegenerate } }: Row<Segment>) => isUsingExternalSegmentationService || !hasSplitsToRegenerate,
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(FormsJoinViewCustomTableActions.REGENERATE_SPLIT, row),
    ...getActionProps(FormsJoinViewCustomTableActions.REGENERATE_SPLIT, rowActionCustomProps),
  },
  {
    disabled: ({ original: { isEditable } }: Row<Segment>) => disableSegmentModifications || !isEditable,
    hidden: ({ original: { parent } }: Row<Segment>) => !parent,
    tooltipMessage: disableSegmentModifications ? 'Segment.Tooltip.Disabled.Migration' : 'Cannot edit a system generated segment',
    hasTooltip: true,
    label: t('Edit'),
    icon: SvgNames.pencil,
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(FormsJoinViewCustomTableActions.EDIT, row),
    ...getActionProps(FormsJoinViewCustomTableActions.EDIT, rowActionCustomProps),
  },
  {
    label: t('Duplicate'),
    icon: SvgNames.cloneSegment,
    disabled: disableSegmentModifications,
    tooltipMessage: disableSegmentModifications ? 'Segment.Tooltip.Disabled.Migration' : undefined,
    hasTooltip: true,
    hidden: ({ original: { parent, type } }: Row<Segment>) => !parent || type === 'Direct Select',
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(FormsJoinViewCustomTableActions.DUPLICATE, row),
    ...getActionProps(FormsJoinViewCustomTableActions.DUPLICATE, rowActionCustomProps),
  },
  {
    label: t('Export'),
    icon: SvgNames.export,
    disabled: !userAllowedToDownload,
    hasTooltip: true,
    tooltipMessage: !userAllowedToDownload ? 'Ask your administrator for permission to do this' : undefined,
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(FormsJoinViewCustomTableActions.EXPORT, row),
    ...getActionProps(FormsJoinViewCustomTableActions.EXPORT, rowActionCustomProps),
  },
  {
    label: t('Refresh count'),
    icon: SvgNames.refresh,
    disabled: disableSegmentModifications,
    tooltipMessage: disableSegmentModifications ? 'Segment.Tooltip.Disabled.Migration' : undefined,
    hasTooltip: true,
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(FormsJoinViewCustomTableActions.REFRESH_RECORDS, row),
    ...getActionProps(FormsJoinViewCustomTableActions.REFRESH_RECORDS, rowActionCustomProps),
  },
  {
    label: t('Preview form'),
    icon: SvgNames.zoom,
    hidden: itemType === ItemType.WEBINAR_SUBMISSION,
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(FormsJoinViewCustomTableActions.PREVIEW_FORM, row),
    ...getActionProps(FormsJoinViewCustomTableActions.PREVIEW_FORM, rowActionCustomProps),
  },
  ...(itemType === ItemType.FORM_SUBMISSION
    ? [
        {
          label: t('Edit form'),
          icon: SvgNames.pencil,
          onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(FormsJoinViewCustomTableActions.EDIT_FORM, row),
          ...getActionProps(FormsJoinViewCustomTableActions.EDIT_FORM, rowActionCustomProps),
          hidden: (row: Row<ItemDto>) => 'parent' in row.original && !!row.original.parent,
        },
      ]
    : [
        {
          label: t('View webinar details'),
          icon: SvgNames.eventDetails,
          onClick: (row: Row<WebinarDto>) => tableActions.customTableRowAction(FormsJoinViewCustomTableActions.VIEW_WEBINAR_DETAILS, row),
          ...getActionProps(FormsJoinViewCustomTableActions.VIEW_WEBINAR_DETAILS, rowActionCustomProps),
          hidden: (row: Row<WebinarDto>) => !!row.original.parent || !row.original.webinarId,
        },
      ]),
  {
    label: t('View form report'),
    icon: SvgNames.graphBar,
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(FormsJoinViewCustomTableActions.VIEW_FORM_REPORT, row),
    hidden: (row) => !!row.original.parent || itemType === ItemType.WEBINAR_SUBMISSION,
    ...getActionProps(FormsJoinViewCustomTableActions.VIEW_FORM_REPORT, rowActionCustomProps),
  },
  {
    label: t('Create webinar segments'),
    icon: SvgNames.createWebinar,
    disabled: disableSegmentModifications,
    tooltipMessage: disableSegmentModifications ? 'Segment.Tooltip.Disabled.Migration' : undefined,
    hasTooltip: true,
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(FormsJoinViewCustomTableActions.CREATE_WEBINAR_SEGMENTS, row),
    hidden: (row) => itemType === ItemType.FORM_SUBMISSION || row.original.parent,
    ...getActionProps(FormsJoinViewCustomTableActions.CREATE_WEBINAR_SEGMENTS, rowActionCustomProps),
  },
  {
    label: t('Clear records'),
    icon: SvgNames.cancelSend,
    hidden: (row) => !!row.original.parent || itemType === ItemType.WEBINAR_SUBMISSION,
    disabled: disableSegmentModifications || !userAllowedToDelete,
    tooltipMessage: disableSegmentModifications ? 'Segment.Tooltip.Disabled.Migration' : 'Ask your administrator for permission to do this',
    hasTooltip: true,
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(FormsJoinViewCustomTableActions.CLEAR_RECORDS, row),
    ...getActionProps(FormsJoinViewCustomTableActions.CLEAR_RECORDS, rowActionCustomProps),
  },
  {
    label: t('Delete all contacts'),
    icon: SvgNames.removeContact,
    hidden: (row) => !row.original.parent,
    disabled: disableSegmentModifications,
    tooltipMessage: disableSegmentModifications ? 'Segment.Tooltip.Disabled.Migration' : undefined,
    hasTooltip: true,
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(FormsJoinViewCustomTableActions.DELETE_ALL_CONTACTS, row),
    ...getActionProps(FormsJoinViewCustomTableActions.DELETE_ALL_CONTACTS, rowActionCustomProps),
  },
  {
    label: t('Delete'),
    icon: SvgNames.delete,
    disabled: disableSegmentModifications || !userAllowedToDelete,
    tooltipMessage: disableSegmentModifications ? 'Segment.Tooltip.Disabled.Migration' : 'Ask your administrator for permission to do this',
    hasTooltip: true,
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(FormsJoinViewCustomTableActions.DELETE, row),
    ...getActionProps(FormsJoinViewCustomTableActions.DELETE, rowActionCustomProps),
  },
]

const getCustomHeaderActions = (
  tableActions: TableActions,
  t: Function,
  itemType: ItemType,
  { userAllowedToDelete, disableSegmentModifications }: AccountSettings
): HeaderAction[] => [
  {
    icon: SvgNames.refresh,
    disabled: disableSegmentModifications,
    tooltipMessage: disableSegmentModifications ? 'Segment.Tooltip.Disabled.Migration' : undefined,
    hasTooltip: true,
    label: 'Refresh records',
    onClick: (_event: MouseEvent<HTMLButtonElement> | Event) => tableActions.customTableHeaderAction(FormsJoinViewCustomTableActions.REFRESH_RECORDS),
    ...getActionProps(FormsJoinViewCustomTableActions.REFRESH_RECORDS, headerActionCustomProps),
  },
  {
    label: t('Clear records'),
    icon: SvgNames.cancelSend,
    hidden: itemType === ItemType.WEBINAR_SUBMISSION,
    disabled: disableSegmentModifications || !userAllowedToDelete,
    tooltipMessage: disableSegmentModifications ? 'Segment.Tooltip.Disabled.Migration' : 'Ask your administrator for permission to do this',
    hasTooltip: true,
    onClick: (_event: MouseEvent<HTMLButtonElement> | Event) => tableActions.customTableHeaderAction(FormsJoinViewCustomTableActions.CLEAR_RECORDS),
    ...getActionProps(FormsJoinViewCustomTableActions.CLEAR_RECORDS, headerActionCustomProps),
  },
  {
    label: t('Delete'),
    icon: SvgNames.delete,
    disabled: disableSegmentModifications || !userAllowedToDelete,
    tooltipMessage: disableSegmentModifications ? 'Segment.Tooltip.Disabled.Migration' : 'Ask your administrator for permission to do this',
    hasTooltip: true,
    onClick: (_event: MouseEvent<HTMLButtonElement> | Event) => tableActions.customTableHeaderAction(FormsJoinViewCustomTableActions.DELETE),
    ...getActionProps(FormsJoinViewCustomTableActions.DELETE, headerActionCustomProps),
  },
]

const renderFormSelector = (
  item: ListingPageItem,
  type: FormSelectorType,
  onAction: (selectedForm: FormSubmissionSummaryDto, totalForms: FormSubmissionSummaryDto[]) => void,
  onClose: () => void,
  setStatusToast: (message: string, status: Status) => void,
  showPreview?: boolean
) => (
  <FormSelectorModal list={item} type={type} onClose={onClose} onAction={onAction} setStatusToast={setStatusToast} showPreview={showPreview} isOpen />
)

export const renderDetailsPageCustomModal = (
  customAction: FormsJoinViewCustomModals,
  item: ListingPageItem,
  update: (fields: Partial<SegmentDetailsContainerState>) => void,
  itemType: ItemType,
  hasReactWrapper?: boolean
) => {
  const { externalId = '' } = item
  const onClose = () => update({ showCustomModal: false })
  const setStatusToast = (statusMessage: string, status: Status) => {
    update({
      statusToast: {
        status,
        statusMessage,
        showStatusToast: true,
      },
    })
  }
  const customModals: { [key in FormsJoinViewCustomModals]: () => ReactNode } = {
    [FormsJoinViewCustomModals.EDIT_FORM]: () =>
      renderFormSelector(item, FormSelectorType.EDIT, (form) => onEditForm(form, hasReactWrapper), onClose, setStatusToast),
    [FormsJoinViewCustomModals.VIEW_FORM_REPORT]: () =>
      renderFormSelector(item, FormSelectorType.VIEW_REPORT, onViewFormReport, onClose, setStatusToast),
    [FormsJoinViewCustomModals.CREATE_WEBINAR_SEGMENTS]: () => null,
    [FormsJoinViewCustomModals.CLEAR_RECORDS]: () => (
      <DeleteContactsConfirmationModal update={update} ids={[externalId]} actionType={ActionType.CLEAR} itemType={itemType} />
    ),
    [FormsJoinViewCustomModals.DELETE_SEGMENT]: () => false,
    [FormsJoinViewCustomModals.PREVIEW_FORM]: () => false,
    [FormsJoinViewCustomModals.DELETE_ALL_CONTACTS]: () => false,
    [FormsJoinViewCustomModals.DUPLICATE]: () => false,
  }
  return customModals[customAction]()
}

export const renderCustomModal = (
  customTableAction: FormsJoinViewCustomModals,
  listPageValues: ListPageCommonState,
  listPageAPI: ListPageAPI,
  onDuplicateSegment: (
    newName: string,
    tags: LabelDto[],
    folder: number | undefined,
    sourceId: string,
    listPageValues: ListPageCommonState,
    listingPageAPI: ListPageAPI
  ) => Promise<void>,
  hasReactWrapper?: boolean
) => {
  const { selectedRows, showPreview, itemType = ItemType.FORM_SUBMISSION } = listPageValues
  const item = selectedRows.length ? selectedRows[0] : {}
  const { externalId = '', name = '', tags = [], folderId }: ListingPageItem = item
  const ids: string[] = selectedRows.reduce((lists: string[], row) => (row.externalId ? [...lists, row.externalId] : lists), [])

  const { update } = listPageAPI

  const setStatusToast = (statusMessage: string, status: Status) => {
    update({
      statusToast: {
        status,
        statusMessage,
        showStatusToast: true,
      },
    })
  }

  const onClose = () => listPageAPI.update?.({ showCustomModal: false })
  const onPreviewForm = (selectedForm: FormSubmissionSummaryDto, totalForms: FormSubmissionSummaryDto[]) => {
    listPageAPI.update?.({
      showPreview: true,
      showBackButtonOnFormsPreviewModal: totalForms.length > 1,
      selectedRows: [{ name: selectedForm.formName, externalId: selectedForm.formId }],
    })
  }

  const customModals: { [key in FormsJoinViewCustomModals]: () => ReactNode } = {
    [FormsJoinViewCustomModals.DUPLICATE]: () => (
      <DuplicateFolderModal
        activeFolderId={folderId ?? listPageValues.activeFolderId}
        folderAllOptionLabel={itemType === ItemType.FORM_SUBMISSION ? allFormSubmissionsFilter.name : allWebinarSubmissionsFilter.name}
        descriptionKey={'ListPage.ContactSegments.Duplicate.Description'}
        folders={listPageValues.folders}
        headerTitleKey={'Duplicate Segment'}
        itemName={name}
        itemToCloneTags={tags}
        namePlaceholder={'Segment name'}
        onCancel={() => update({ showCustomModal: false })}
        onClone={(newName, tags, folder) => onDuplicateSegment(newName, tags, folder, externalId, listPageValues, listPageAPI)}
        onTagCreate={listPageAPI.createTag}
        tags={listPageValues.tags}
      />
    ),
    [FormsJoinViewCustomModals.EDIT_FORM]: () =>
      renderFormSelector(item, FormSelectorType.EDIT, (form) => onEditForm(form, hasReactWrapper), onClose, setStatusToast),
    [FormsJoinViewCustomModals.PREVIEW_FORM]: () =>
      renderFormSelector(item, FormSelectorType.PREVIEW, onPreviewForm, onClose, setStatusToast, showPreview),
    [FormsJoinViewCustomModals.VIEW_FORM_REPORT]: () =>
      renderFormSelector(item, FormSelectorType.VIEW_REPORT, onViewFormReport, onClose, setStatusToast),
    [FormsJoinViewCustomModals.CREATE_WEBINAR_SEGMENTS]: () => {
      const webinar = selectedRows.length && itemType === ItemType.WEBINAR_SUBMISSION ? (selectedRows[0] as WebinarDto) : undefined
      const existingFollowUpSegments =
        webinar?.subRows
          ?.map(({ externalId }) => {
            const match = externalId.match(/-([A-Z])-0[0-9a-z]+$/)
            return match ? match[1] : ''
          })
          .filter((letter) => letter !== '') ?? []

      const beforeRules: BeforeRules = {
        approved: existingFollowUpSegments.includes('J'),
        rejected: existingFollowUpSegments.includes('K'),
        pending: existingFollowUpSegments.includes('L'),
      }

      const followUpRules: FollowUpRules = {
        signedApprovedAttended: existingFollowUpSegments.includes('B'),
        notSignedAttended: existingFollowUpSegments.includes('A'),
        signedApprovedNoShow: existingFollowUpSegments.includes('F'),
      }

      if (webinar && webinar.subTypeDTO && webinar.subTypeDTO.length > 0 && webinar.webinarId) {
        const { subTypeDTO } = webinar
        return (
          <CreateWebinarSegmentsModal
            update={update}
            webinarType={(subTypeDTO[0]?.name ?? WebinarType.Zoom) as WebinarType}
            webinarId={webinar.webinarId}
            disabledBeforeSegments={beforeRules}
            disabledFollowUpSegments={followUpRules}
          />
        )
      }
    },
    [FormsJoinViewCustomModals.CLEAR_RECORDS]: () => (
      <DeleteContactsConfirmationModal update={update} ids={ids} actionType={ActionType.CLEAR} itemType={itemType} />
    ),
    [FormsJoinViewCustomModals.DELETE_ALL_CONTACTS]: () => (
      <DeleteContactsConfirmationModal update={update} ids={ids} actionType={ActionType.DELETE} itemType={itemType} />
    ),
    [FormsJoinViewCustomModals.DELETE_SEGMENT]: () => (
      <DependenciesContentModal
        selectedItems={selectedRows.map(({ externalId }) => ({ id: externalId, name })) as DependenciesContentModalItem[]}
        onDelete={(items: DependenciesContentModalItem[]) => {
          update({
            showCustomModal: false,
            deleteConfirmationData: selectedRows.filter(({ externalId }) => items.some(({ id }) => id === externalId)),
            showDeleteConfirmationModal: true,
            confirmationModal: DeleteConfirmationModals.DELETE_ITEMS,
          })
        }}
        onCancel={() => update({ showCustomModal: false })}
        itemType={DependenciesItemType.SEGMENT}
      />
    ),
  }
  return customModals[customTableAction]()
}

const clickableColumnOptions = {
  colIndex: 0,
  action: {},
}

const renderSearchColumns = (
  searchInAllItems: boolean | undefined,
  currentFolder: FolderData,
  search: string,
  folders: FolderData[],
  t: Function,
  itemType: ItemType
): ColumnDefWithAdditionalProps<Segment>[] => {
  return [
    {
      header: 'Name',
      accessorKey: 'name',
      textAlign: 'left',
      enableFlexCell: true,
      cell: (row) => (
        <>
          <Svg name={SvgNames.signUp} type={SvgType.MEDIUM} />
          <Tooltip trigger={renderBoldTextOnMatch(row.cell.getValue<string>(), search)}>{row.cell.getValue<string>()}</Tooltip>
        </>
      ),
    },
    {
      header: 'Tags',
      accessorKey: 'tags',
      textAlign: 'left',
      maxSize: 150,
      disableSorting: true,
      enableCustomCellValue: true,
    },
    {
      header: 'Location',
      accessorKey: 'folderId',
      textAlign: 'left',
      enableFlexCell: true,
      disableSorting: true,
      cell: (row) =>
        renderPathToFolder(
          ItemType.FORM_SUBMISSION,
          row.cell.getValue<number>(),
          folders,
          searchInAllItems,
          'list-page-table-container',
          t,
          currentFolder,
          `ListPage.FormsJoinView.${itemType}.AllItemsFilter`
        ),
    },
    {
      header: 'Last updated',
      accessorKey: 'lastUpdated',
      textAlign: 'left',
      cell: (row) => relativeDate(row.cell.getValue<number>() ?? 0, true),
    },
  ]
}

const buildSubRows = (items: Segment[]) => {
  const { toAnalyzeSegments, toAnalyzeSubSegments } = items.reduce(
    (segmentedItems: { toAnalyzeSegments: Segment[]; toAnalyzeSubSegments: Segment[] }, item) => {
      const isChild = item.parent
      const hasParentInList = items.find(({ externalId }) => externalId === item.parent)
      return isChild && hasParentInList
        ? { ...segmentedItems, toAnalyzeSubSegments: [...segmentedItems.toAnalyzeSubSegments, item] }
        : { ...segmentedItems, toAnalyzeSegments: [...segmentedItems.toAnalyzeSegments, item] }
    },
    { toAnalyzeSegments: [], toAnalyzeSubSegments: [] }
  )
  const priorityOrder = ['J', 'K', 'L', 'B', 'A', 'F']
  const getPriorityChar = (externalId: string): string | undefined => {
    const splitExternalId = externalId.split('-')
    if (splitExternalId.length > 2 && priorityOrder.includes(splitExternalId[2])) {
      return splitExternalId[2]
    }
  }
  toAnalyzeSubSegments.forEach((subItem) => {
    const foundParentIndex = toAnalyzeSegments.findIndex(({ externalId }) => externalId === subItem.parent)
    if (foundParentIndex >= 0) {
      toAnalyzeSegments[foundParentIndex].subRows = [...(toAnalyzeSegments[foundParentIndex].subRows ?? []), subItem]
    } else {
      const foundParentIndex = toAnalyzeSubSegments.findIndex(({ externalId }) => externalId === subItem.parent)
      if (foundParentIndex >= 0) {
        toAnalyzeSubSegments[foundParentIndex].subRows = [...(toAnalyzeSubSegments[foundParentIndex].subRows ?? []), subItem]
      }
    }
  })
  toAnalyzeSegments.forEach((segment) => {
    if (segment.subRows) {
      segment.subRows.sort((a, b) => {
        const aChar = getPriorityChar(a.externalId)
        const bChar = getPriorityChar(b.externalId)
        const aPriority = aChar ? priorityOrder.indexOf(aChar) : priorityOrder.length
        const bPriority = bChar ? priorityOrder.indexOf(bChar) : priorityOrder.length
        return aPriority - bPriority
      })
    }
  })
  return toAnalyzeSegments
}

export const tableProps = (
  t: Function,
  onRowClicked: (row: Row<ItemDto>) => void,
  urlPush: Function,
  regenerateSplit: (id: string) => FetchPromise<RegenerateSplitsMutation>,
  refreshRecords: (ids: string[]) => FetchPromise<RefreshRecordCountMutation>,
  itemType: ItemType,
  crmName: CRMConnectorType,
  isWebinarRegistration: boolean,
  onExport: (segment: Segment) => void,
  ftpSegmentsExecutionDetails: Record<string, FtpExecutionStatus>,
  ftpExportSegmentsExecutionDetails: Record<string, FtpExportExecutionStatus>,
  update: (values: Partial<FormsJoinViewListingPageContainerState>) => void,
  accountSettings: AccountSettings,
  onCRMPushErrorsClick?: (formId: string, listId: string) => void
): TableProps => ({
  columns: getTableV2Columns(
    isWebinarRegistration,
    ftpSegmentsExecutionDetails,
    ftpExportSegmentsExecutionDetails,
    update,
    itemType,
    crmName,
    t,
    onCRMPushErrorsClick
  ),
  hasAutoSelectedRows: false,
  hasExpander: true,
  buildSubRows,
  rowActionCustomProps,
  renderSearchColumns: (searchInAllItems: boolean | undefined, currentFolder: FolderData, search: string, folders: FolderData[]) =>
    renderSearchColumns(searchInAllItems, currentFolder, search, folders, t, itemType),
  headerActionCustomProps,
  listPage: `FormsJoinView.${itemType}`,
  getCustomHeaderActions: (tableActions) => getCustomHeaderActions(tableActions, t, itemType, accountSettings),
  getCustomRowActions: (tableActions) => getCustomRowActions(tableActions, t, itemType, accountSettings),
  onCustomTableAction: (customAction, update, listPageValues) =>
    onCustomTableAction(customAction, update, listPageValues, t, urlPush, regenerateSplit, refreshRecords, itemType, onExport, accountSettings),
  clickableColumnOptions,
  actonAssetType: 'FORMS_JOIN_VIEW',
  onRowClicked,
})

const renderFtpSegmentWithInfoHover = (
  segment: Segment,
  ftpExportStatus: Record<string, FtpExportExecutionStatus>,
  update: (values: Partial<FormsJoinViewListingPageContainerState>) => void,
  isWebinarRegistration: boolean,
  syncsWithFtp: boolean
) => {
  const isExport = !!ftpExportStatus[segment.externalId]
  const onFtpViewLogAction = (target: string | undefined) => update && update({ ftpFileImportLogTarget: target })
  return (
    <SegmentNameWithFtpSyncAndInfoHover
      segmentData={segment}
      cardBody={
        isWebinarRegistration ? (
          <WebinarRegistrationInfoHoverCard
            segment={segment}
            syncsWithFtp={syncsWithFtp}
            hasExport={isExport}
            onFtpViewLogAction={onFtpViewLogAction}
          />
        ) : (
          <FormSubmissionInfoHoverCard segment={segment} syncsWithFtp={syncsWithFtp} hasExport={isExport} onFtpViewLogAction={onFtpViewLogAction} />
        )
      }
      ftpExportSyncData={ftpExportStatus[segment.externalId]}
    />
  )
}

const renderSubmissionName = (
  segment: Segment,
  isWebinarRegistration: boolean,
  syncsWithFtp: boolean,
  itemType: ItemType,
  onFtpViewLogAction?: (target: string | undefined) => void
) => {
  return renderSegmentNameWithInfoHover(
    { ...segment, isEditable: true },
    {
      body: segment.parent ? (
        <SegmentInfoHoverCard segmentData={segment} syncsWithFtp={syncsWithFtp} onFtpViewLogAction={onFtpViewLogAction} />
      ) : isWebinarRegistration ? (
        <WebinarRegistrationInfoHoverCard segment={segment} />
      ) : (
        <FormSubmissionInfoHoverCard segment={segment} />
      ),
      footer: !segment.parent && <AttendanceAndConversionRateLineChart segment={segment} isWebinarRegistration={isWebinarRegistration} />,
      className: formSubmissionInfoHoverCardRootClass,
    },
    itemType
  )
}

const getTableV2Columns = (
  isWebinarRegistration: boolean,
  ftpSegmentsExecutionDetails: Record<string, FtpExecutionStatus>,
  ftpExportSegmentsExecutionDetails: Record<string, FtpExportExecutionStatus>,
  update: (values: Partial<FormsJoinViewListingPageContainerState>) => void,
  itemType: ItemType,
  crmName: CRMConnectorType,
  t: Function,
  onCRMPushErrorsClick?: (formId: string, listId: string) => void
): ColumnDefWithAdditionalProps<Segment>[] => {
  const handleCRMPushErrorsClick = (formId: string, listId: string) => onCRMPushErrorsClick && onCRMPushErrorsClick(formId, listId)

  return [
    {
      header: 'Name',
      accessorKey: 'name',
      textAlign: 'left',
      enableFlexCell: true,
      cell: ({ row: { original } }) => {
        const { externalId, formWithCrmPushErrors } = original
        return (
          <>
            {formWithCrmPushErrors && (
              <Tooltip
                trigger={
                  <div
                    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                      event.preventDefault()
                      event.stopPropagation()
                      handleCRMPushErrorsClick(formWithCrmPushErrors, externalId)
                    }}
                    role={'button'}
                    tabIndex={0}
                    onKeyDown={(keyDownEvent) =>
                      keyDownEvent.key === 'Enter' ? handleCRMPushErrorsClick(formWithCrmPushErrors, externalId) : undefined
                    }
                  >
                    <Svg
                      className={`forms-join-view-listing-page-container-crm-error-icon`}
                      name={SvgNames.cautionYellow}
                      type={SvgType.LARGER_ICON}
                    />
                  </div>
                }
                align={'center'}
              >
                {t('ListPage.FormsJoinView.FORM_SUBMISSION.CrmPushErrorsTooltip', { crmName })}
              </Tooltip>
            )}
            {ftpSegmentsExecutionDetails[externalId] || ftpExportSegmentsExecutionDetails[externalId]
              ? renderFtpSegmentWithInfoHover(original, ftpExportSegmentsExecutionDetails, update, isWebinarRegistration, true)
              : renderSubmissionName(original, isWebinarRegistration, false, itemType)}
          </>
        )
      },
    },
    {
      header: 'Tags',
      accessorKey: 'tags',
      textAlign: 'left',
      maxSize: 180,
      enableFlexCell: true,
      enableCustomCellValue: true,
      disableSorting: true,
    },
    {
      header: 'Records',
      accessorKey: 'recordsCount',
      textAlign: 'left',
      fieldType: 'integer',
      maxSize: 100,
      cell: ({ cell }) => <TextWithTooltipOnEllip typographyProps={{ text: getFormattedNumber(cell.getValue<number>()) }} />,
    },
    {
      header: 'Last updated',
      accessorKey: 'lastUpdated',
      textAlign: 'left',
      maxSize: 150,
      cell: (row) => relativeDate(row.cell.getValue<number>() ?? 0, true),
    },
  ]
}
