import React from 'react'

import { SvgNames } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { LogoItem } from '@graphql/types/query-types'

export enum ActionTypes {
  EDIT,
  PREVIEW,
}

export const getGridViewActions = (
  logo: LogoItem,
  handleEdit: (logo: LogoItem, isDuplicate: boolean) => void,
  handleDelete: (logo: LogoItem) => void,
  t: Function
) => {
  return [
    {
      key: t('Edit'),
      text: t('Edit logo'),
      icon: SvgNames.pencil,
      tooltip: t('Edit'),
      onClick: () => handleEdit(logo, false),
    },
    {
      key: t('Delete'),
      text: t('Delete logo'),
      icon: SvgNames.delete,
      tooltip: t('Delete'),
      onClick: () => handleDelete(logo),
    },
  ]
}

export const getAllActions = (
  logo: LogoItem,
  handleOpenWithEditor: (logo: LogoItem) => void,
  handleUpdateLogoDefault: (logo: LogoItem) => void,
  handleEdit: (logo: LogoItem, isDuplicate: boolean) => void,
  handleDelete: (logo: LogoItem) => void,
  t: Function,
  actionType: ActionTypes | undefined
) => {
  return [
    {
      key: t('Edit'),
      text: t('Edit logo'),
      icon: SvgNames.pencil,
      tooltip: t('Edit'),
      hidden: actionType === ActionTypes.EDIT,
      onClick: () => handleEdit(logo, false),
    },
    {
      key: t('Duplicate logo'),
      text: t('Duplicate logo'),
      tooltip: t('Duplicate logo'),
      icon: SvgNames.cloneSegment,
      topSection: false,
      hidden: actionType === ActionTypes.EDIT,
      onClick: () => handleEdit(logo, true),
    },
    {
      key: t('Open with image editor'),
      text: t('Open with image editor'),
      tooltip: t('Open with image editor'),
      icon: SvgNames.image,
      topSection: false,
      onClick: () => handleOpenWithEditor(logo),
    },
    {
      key: t('Delete'),
      text: t('Delete logo'),
      icon: SvgNames.delete,
      tooltip: t('Delete'),
      onClick: () => handleDelete(logo),
    },
    {
      key: logo.isDefault ? t('Remove as default') : t('Set as default'),
      text: logo.isDefault ? t('Remove as default') : t('Set as default'),
      topSection: true,
      tooltip: logo.isDefault ? t('Remove as default') : t('Set as default'),
      onClick: () => handleUpdateLogoDefault(logo),
    },
  ]
}

export const getGridViewDropDownActions = (
  logo: LogoItem,
  handleDuplicate: (logo: LogoItem, isDuplicate: boolean) => void,
  handleOpenWithEditor: (logo: LogoItem) => void,
  handleUpdateLogoDefault: (logo: LogoItem) => void,
  t: Function
) => {
  return [
    {
      key: t('Duplicate logo'),
      text: t('Duplicate logo'),
      tooltip: t('Duplicate logo'),
      icon: SvgNames.cloneSegment,
      topSection: false,
      onClick: () => handleDuplicate(logo, true),
    },
    {
      key: t('Open with image editor'),
      text: t('Open with image editor'),
      tooltip: t('Open with image editor'),
      icon: SvgNames.image,
      topSection: false,
      onClick: () => handleOpenWithEditor(logo),
    },
    {
      key: logo.isDefault ? t('Remove as default') : t('Set as default'),
      text: logo.isDefault ? t('Remove as default') : t('Set as default'),
      topSection: true,
      tooltip: logo.isDefault ? t('Remove as default') : t('Set as default'),
      onClick: () => handleUpdateLogoDefault(logo),
    },
  ]
}

export const getConfirmationBody = (t: Function, isDuplicate: boolean) => (
  <>
    <Typography
      inline
      text={
        isDuplicate
          ? t('If you cancel now, you’ll lose all unsaved changes.')
          : t('Deleting will permanently remove this logo from the system. This will impact any account-wide content currently using this logo.')
      }
      type={TextType.BODY_TEXT_LIGHT}
    />
    <Typography inline text={t('You cannot undo this action.')} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.BOLD} />
  </>
)
