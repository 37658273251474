import React, { MutableRefObject } from 'react'

import dayjs from 'dayjs'
import * as HistoryInterface from 'history'
import { t, TFunction } from 'i18next'
import pretty from 'pretty'
import { DebouncedState } from 'use-debounce/lib/useDebouncedCallback'

import Bee from '@beefree.io/sdk'
import Caution from '@components/Caution/Caution'
import { YesNo } from '@components/ConfirmationModal'
import { LoaderSize } from '@components/Spinner/Spinner'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { EmailContentEditSessionEntity } from '@graphql/types/microservice/email-management-types'
import { PersonalizationFieldsErrorsForSources } from '@graphql/types/mutation-types'
import { LandingPageCssFile, LandingPageJsFile, ListContactGq } from '@graphql/types/query-types'
import { prevPageLinks } from '@src/pages/EmailComposer/utils/EmailComposer.constants'
import { ContainerValuesRefType, PrevPageParamsEnum } from '@src/pages/EmailComposer/utils/EmailComposer.types'
import { detectEmailType, getValidTab } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { filterNotEmptyArray } from '@utils/array'
import { BeeEventHooks, IEntityContentJsonExtended } from '@utils/composer/beeEditor/beeEditorTypes'
import { CommonComposerAPI, CommonComposerTab, SaveComposerCommonType } from '@utils/composer/commonComposer/CommonComposer.context'
import { ComposerHistoryState, EmailComposerState } from '@utils/composer/context/EmailComposer.context'
import { LandingPageVisibilityStatus } from '@utils/composer/context/LandingPageComposer.context'
import { EmailComposerRequests } from '@utils/composer/emailComposer/GraphQL/EmailComposerRequests.graphQL'
import { ExtendedMessageType } from '@utils/composer/emailComposer/types'
import { isCustomerCareLogin } from '@utils/cookie'
import { logError } from '@utils/env'
import { logNewRelicError } from '@utils/new-relic.utils'
import { setLocalStorageItem } from '@utils/window'

import { saveEmailPrecheck } from './EmailComposer.utils'

export const onWebinarMessageSave = () => {
  setLocalStorageItem('messageSaved', `${Date.now()}`)
}

export const joinSession = (containerValuesRef: ContainerValuesRefType, beeEditorRef: MutableRefObject<Bee | undefined>) => () => {
  const { sessionId, beeConfig } = containerValuesRef.current
  if (beeEditorRef.current && beeConfig) {
    const newSessionId = prompt(`Enter new session ID:`, sessionId)
    if (newSessionId) {
      beeEditorRef.current?.join(beeConfig, newSessionId)
    }
  }
}

export const tabChange =
  (containerValuesRef: ContainerValuesRefType, history: HistoryInterface.History<ComposerHistoryState>, update: CommonComposerAPI['update']) =>
  (newTab: CommonComposerTab, state?: ComposerHistoryState) => {
    const updatedTab = getValidTab(newTab)
    history.replace(
      { pathname: newTab, search: history.location.search },
      {
        ...state,
        previousTab: containerValuesRef.current.tab,
        visitedTabs: { ...(history.location.state?.visitedTabs ?? {}), [updatedTab]: true },
      }
    )
    update({ tab: updatedTab, cancelHeaderTitleEdit: true })
  }

export const createCampaign =
  (
    containerValuesRef: ContainerValuesRefType,
    createCampaignRequest: EmailComposerRequests['createCampaignRequest'],
    update: CommonComposerAPI['update']
  ) =>
  async (name: string) => {
    const { data, errors } = await createCampaignRequest({ name })
    if (errors) {
      logNewRelicError(errors, 'Creating a campaign')
    } else if (data) {
      update({ campaigns: [...containerValuesRef.current.campaigns, data.createActOnCampaign] })
      return data.createActOnCampaign
    }
  }

export const createSubscriptionCategory =
  (
    containerValuesRef: ContainerValuesRefType,
    createSubscriptionCategoryRequest: EmailComposerRequests['createSubscriptionCategoryRequest'],
    update: CommonComposerAPI['update']
  ) =>
  async (name: string) => {
    const { data, errors } = await createSubscriptionCategoryRequest({ name, description: '', checked: false })
    if (errors) {
      logNewRelicError(errors, 'Creating a subscription category')
    } else if (data) {
      update({ subscriptionCategories: [...containerValuesRef.current.subscriptionCategories, data.createSubscriptionCategory] })
      return data.createSubscriptionCategory
    }
  }

export const createEmailContentEditSession =
  (createEmailContentEditSessionRequest: EmailComposerRequests['createEmailContentEditSessionRequest']) =>
  async (emailContentId: string, sessionId: string) => {
    const { data, errors } = await createEmailContentEditSessionRequest({
      emailContentId,
      sessionId,
    })
    if (errors) {
      logNewRelicError(errors, 'Creating email content edit session')
    } else if (data) {
      return data.createEmailContentEditSession
    }
  }

export const checkSubjectAndPreviewText =
  (
    containerValuesRef: ContainerValuesRefType,
    message: EmailComposerState['message'],
    checkSubjectAndPreviewTextRequest: EmailComposerRequests['checkSubjectAndPreviewTextRequest'],
    update: CommonComposerAPI['update']
  ) =>
  async () => {
    update({ validations: { settingsValidations: { loadingPersonalizationErrors: true } } })
    return checkSubjectAndPreviewTextRequest({ msgId: containerValuesRef.current.message.id }).then(({ data }) => {
      if (data?.checkSubjectAndPreviewText) {
        const {
          checkSubjectAndPreviewText: { subject, previewText },
        } = data

        const { isEmailTemplate } = detectEmailType(message.messageType)

        const templateListsFilter = (error: PersonalizationFieldsErrorsForSources) => (!isEmailTemplate ? error : { ...error, lists: [] })
        const doesNotExistFilter = (error: PersonalizationFieldsErrorsForSources) => {
          if (error.isMissingForAll) {
            const existsInAccountMapping = containerValuesRef.current.personalizations?.find((item) => {
              return error.field.includes((item.mapping ?? item.title).toLowerCase().trim())
            })
            if (!existsInAccountMapping) {
              return { ...error, lists: [] }
            }
          }
          return error
        }

        update({
          autoSaveSuccess: undefined,
          validations: {
            settingsValidations: {
              subjectPersonalizationErrors: subject?.filter(filterNotEmptyArray).map(templateListsFilter).map(doesNotExistFilter),
              previewPersonalizationErrors: previewText?.filter(filterNotEmptyArray).map(templateListsFilter).map(doesNotExistFilter),
              loadingPersonalizationErrors: false,
              failedPersonalizationErrors: false,
            },
          },
        })
      } else {
        update({ validations: { settingsValidations: { loadingPersonalizationErrors: false, failedPersonalizationErrors: true } } })
      }
    })
  }

const handleClose = (
  isSaved: boolean,
  containerValuesRef: ContainerValuesRefType,
  history: HistoryInterface.History,
  enableEmailDraftsReact: boolean
) => {
  if (isSaved) {
    const {
      message: { messageType, id, title },
      messageConfiguration: { messageType: messageConfigurationType },
      isCreatedNewMessage,
      haveUnsavedChanges,
    } = containerValuesRef.current
    const { isEmailTemplate, isEmailCRM, isEmailWebinar, isEmailABTest, isEmailRSS, isEmailBlankMessage, isLandingPage } =
      detectEmailType(messageType)

    if (window.opener) {
      if (isEmailWebinar) {
        onWebinarMessageSave()
      } else if (isLandingPage && haveUnsavedChanges === false) {
        window.opener.postMessage({ lpId: id, title })
      } else if (!isEmailBlankMessage) {
        window.opener.postMessage('Email Composer Saved', '*')
        window.opener.postMessage({ emailId: id, emailTitle: title, isEditing: !isCreatedNewMessage })
      }
      window.close()
    } else {
      let url = ''

      switch (true) {
        case isEmailCRM:
          window.close()
          return
        case isEmailWebinar:
          url = prevPageLinks[PrevPageParamsEnum.webinar]
          break
        case isEmailABTest:
          url = prevPageLinks[PrevPageParamsEnum.abTest]
          break
        case isEmailTemplate:
          url = prevPageLinks[PrevPageParamsEnum.emailTemplate]
          break
        case isEmailRSS:
          url = prevPageLinks[PrevPageParamsEnum.rss]
          break
        case isLandingPage:
          url = prevPageLinks[PrevPageParamsEnum.landingPage]
          break
        case enableEmailDraftsReact:
          if (messageConfigurationType === 'FORM_AUTO_RESPONSE') {
            url = prevPageLinks[PrevPageParamsEnum.forms]
          } else {
            url = prevPageLinks[PrevPageParamsEnum.drafts]
          }
          break

        default:
          url = prevPageLinks[PrevPageParamsEnum.classicDrafts]
          break
      }

      history.push(url)
    }
  }
}

export const saveAndClose =
  (
    containerValuesRef: ContainerValuesRefType,
    history: HistoryInterface.History,
    enableEmailDraftsReact: boolean,
    saveComposerEmail: SaveComposerCommonType,
    updateModal: CommonComposerAPI['updateModal'],
    t: TFunction
  ) =>
  async () => {
    const containerValues = saveEmailPrecheck(containerValuesRef.current, updateModal, t)
    if (!containerValues) {
      return
    }

    if (containerValuesRef.current.message.title) {
      if (containerValuesRef.current.isSaving) {
        new Promise(() => {
          const checkIsSaving = () => {
            if (!containerValuesRef.current.isSaving) {
              handleClose(true, containerValuesRef, history, enableEmailDraftsReact)
            } else {
              setTimeout(checkIsSaving, 100)
            }
          }
          checkIsSaving()
        })
      } else {
        saveComposerEmail(containerValuesRef.current).then(() => handleClose(true, containerValuesRef, history, enableEmailDraftsReact))
      }
    }
  }

export const closeComposer = async (
  containerValuesRef: ContainerValuesRefType,
  history: HistoryInterface.History,
  enableEmailDraftsReact: boolean
) => {
  handleClose(true, containerValuesRef, history, enableEmailDraftsReact)
}

export const deleteAndClose = async (
  containerValuesRef: ContainerValuesRefType,
  history: HistoryInterface.History,
  enableEmailDraftsReact: boolean,
  deleteEmailDrafts: EmailComposerRequests['deleteEmailDrafts']
) => {
  await deleteEmailDrafts(containerValuesRef.current.message.id)
  handleClose(true, containerValuesRef, history, enableEmailDraftsReact)
}

export const updateOrAddStylesAndScripts = (
  selectedCss: (LandingPageCssFile & { index?: number })[],
  selectedScripts: (LandingPageJsFile & { index?: number })[],
  templateHtml?: string,
  mode?: 'javascript' | 'css'
) => {
  const parser = new DOMParser()
  const doc = templateHtml ? parser.parseFromString(templateHtml, 'text/html') : null

  if (!mode || mode === 'css') {
    doc?.querySelectorAll('style').forEach((style) => {
      if (style.getAttribute('data-css-index') && style.id !== 'custom-css') {
        style.remove()
      }
    })

    selectedCss?.forEach((css, index) => {
      const styleTag = document.createElement('style')
      styleTag.textContent = css.content ?? ''
      styleTag.setAttribute('data-css-index', index.toString())
      doc?.head.appendChild(styleTag)
    })

    const customCss = doc?.querySelector('#custom-css')
    if (customCss) {
      doc?.head.appendChild(customCss)
    }
  }

  if (!mode || mode === 'javascript') {
    doc?.querySelectorAll('script').forEach((script) => {
      if (script.getAttribute('data-script-index') && script.id !== 'custom-javascript') {
        script.remove()
      }
    })

    selectedScripts?.forEach((script, index) => {
      const scriptTag = document.createElement('script')
      scriptTag.setAttribute('data-script-index', index.toString())
      scriptTag.type = 'text/javascript'
      scriptTag.textContent = script.content ?? ''
      doc?.documentElement.appendChild(scriptTag)
    })

    const customJs = doc?.querySelector('#custom-javascript')
    if (customJs) {
      doc?.documentElement.appendChild(customJs)
    }
  }

  return doc?.documentElement.outerHTML ?? ''
}

export const updateOrCreateMetaTag = (html: string, tag: string, metaType: string, metaNameOrProperty: string, contentValue: string) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')

  const metaSelector = `${metaType}="${metaNameOrProperty}"`
  let metaTag = metaType && metaNameOrProperty ? doc.querySelector(`${tag}[${metaSelector}]`) : doc.querySelector(tag)

  if (tag === 'title') {
    if (metaTag) {
      if (contentValue) {
        metaTag.textContent = contentValue
      } else {
        metaTag.remove()
      }
    } else if (contentValue) {
      metaTag = doc.createElement(tag)
      metaTag.textContent = contentValue
      doc.head.appendChild(metaTag)
    }
  } else if (tag === 'link' && metaType === 'rel' && metaNameOrProperty === 'icon') {
    // Handle favicon updates
    let faviconTag = doc.querySelector("link[rel='icon']")
    if (contentValue) {
      if (!faviconTag) {
        faviconTag = doc.createElement('link')
        faviconTag.setAttribute('rel', 'icon')
        doc.head.appendChild(faviconTag)
      }
      faviconTag.setAttribute('href', contentValue)
    } else {
      faviconTag?.remove()
    }
  } else {
    if (metaTag && contentValue) {
      metaTag.setAttribute('content', contentValue)
    } else if (metaTag && !contentValue) {
      metaTag.remove()
    } else if (!metaTag && contentValue) {
      metaTag = doc.createElement(tag)
      metaType && metaNameOrProperty && metaTag.setAttribute(metaType, metaNameOrProperty)
      metaTag.setAttribute('content', contentValue)
      doc.head.appendChild(metaTag)
    }
  }

  return doc.documentElement.outerHTML
}

export const beeEventHooks = (
  containerValuesRef: ContainerValuesRefType,
  onCreateEmailContentEditSession: (emailContentId: string, sessionId: string) => Promise<EmailContentEditSessionEntity | undefined>,
  update: CommonComposerAPI['update'],
  onAutoSave: CommonComposerAPI['onAutoSave']
): BeeEventHooks => {
  const isLandingPage = containerValuesRef.current.landingPage.isLandingPage

  return {
    onLoadWithSessionStatus: (_json: IEntityContentJsonExtended, isJoined?: boolean) => {
      // In EmailComposer.tsx there is a check for design tab to show loader until bee initialization is finished
      if (!isJoined || containerValuesRef.current.tab !== CommonComposerTab.DESIGN) {
        update({ isBeeLoading: false })
      }
    },
    onConfigLoaded: (beeConfig) => {
      update({ beeConfig })
    },
    onChange: (json) => {
      update({ message: { templateJson: JSON.parse(json) }, validations: { litmusSpamResults: {} } })
    },
    onSessionFull: (usernames: string[]) => {
      update({ isCoEditing: true, editingUsernames: usernames, isCoeditingPrimaryUser: false })
    },
    onPreviewSend: (
      html,
      landingPageMetaImage,
      landingPageMetaTitle,
      landingPageMetaDescription,
      landingPageVisibility,
      customJavaScript,
      customCss,
      selectedCss,
      selectedScripts,
      brandingFavicon
    ) => {
      let updatedTemplateHtmlString = pretty(html.replace(/{%.*?%}/g, ''))

      const updateOrCreateScriptTag = (scriptId: string, script: string) => {
        const parser = new DOMParser()
        const doc = parser.parseFromString(updatedTemplateHtmlString, 'text/html')

        const existingScript = doc.getElementById(scriptId)

        if (existingScript) {
          existingScript.textContent = script || ''
        } else {
          const newScript = doc.createElement('script')
          newScript.id = scriptId
          newScript.type = 'text/javascript'
          newScript.textContent = script || ''

          doc.documentElement.appendChild(newScript)
        }

        updatedTemplateHtmlString = doc.documentElement.outerHTML
      }

      const updateOrCreateStyleTag = (styleId: string, style: string) => {
        const parser = new DOMParser()
        const doc = parser.parseFromString(updatedTemplateHtmlString, 'text/html')

        const existingStyle = doc.getElementById(styleId)

        if (existingStyle) {
          existingStyle.textContent = style || ''
        } else {
          const newStyle = doc.createElement('style')
          newStyle.id = styleId
          newStyle.textContent = style

          doc.head.appendChild(newStyle)
        }

        updatedTemplateHtmlString = doc.documentElement.outerHTML
      }

      updatedTemplateHtmlString = updateOrCreateMetaTag(updatedTemplateHtmlString, 'meta', 'property', 'og:image', landingPageMetaImage?.url || '')
      updatedTemplateHtmlString = updateOrCreateMetaTag(updatedTemplateHtmlString, 'meta', 'name', 'twitter:image', landingPageMetaImage?.url || '')

      updatedTemplateHtmlString = updateOrCreateMetaTag(updatedTemplateHtmlString, 'title', '', '', landingPageMetaTitle || '')
      updatedTemplateHtmlString = updateOrCreateMetaTag(updatedTemplateHtmlString, 'meta', 'property', 'og:title', landingPageMetaTitle || '')
      updatedTemplateHtmlString = updateOrCreateMetaTag(updatedTemplateHtmlString, 'meta', 'property', 'twitter:title', landingPageMetaTitle || '')

      updatedTemplateHtmlString = updateOrCreateMetaTag(updatedTemplateHtmlString, 'meta', 'name', 'description', landingPageMetaDescription || '')
      updatedTemplateHtmlString = updateOrCreateMetaTag(
        updatedTemplateHtmlString,
        'meta',
        'property',
        'og:description',
        landingPageMetaDescription || ''
      )
      updatedTemplateHtmlString = updateOrCreateMetaTag(
        updatedTemplateHtmlString,
        'meta',
        'name',
        'twitter:description',
        landingPageMetaDescription || ''
      )

      updatedTemplateHtmlString = updateOrCreateMetaTag(updatedTemplateHtmlString, 'link', 'rel', 'icon', brandingFavicon || '')
      updatedTemplateHtmlString = updateOrCreateMetaTag(
        updatedTemplateHtmlString,
        'meta',
        'name',
        'ROBOTS',
        landingPageVisibility === LandingPageVisibilityStatus.HIDE ? 'NOINDEX, NOFOLLOW' : ''
      )

      updatedTemplateHtmlString = updateOrAddStylesAndScripts(selectedCss ?? [], selectedScripts ?? [], updatedTemplateHtmlString)
      updateOrCreateScriptTag('custom-javascript', customJavaScript || '')
      updateOrCreateStyleTag('custom-css', customCss || '')

      update({
        loading: false,
        landingPage: { isNewHtmlGenerated: true },
        message: { templateHtml: updatedTemplateHtmlString },
        preview: { html: updatedTemplateHtmlString },
      })

      //!This part is necessary for html content to be synched. We need to call save after BEE html generation.
      if (!isLandingPage) {
        onAutoSave()
      }
    },
    onSessionStarted: (sessionInfo) => {
      const msgId = containerValuesRef.current.message.publishId || containerValuesRef.current.message.id
      msgId && onCreateEmailContentEditSession(msgId, sessionInfo.sessionId)
      update({ sessionId: sessionInfo.sessionId, isBeeLoading: false })
    },
  }
}

export const handleEditPlainText = (
  containerValuesRef: ContainerValuesRefType,
  updateModal: CommonComposerAPI['updateModal'],
  saveComposerEmail: SaveComposerCommonType,
  onConfirm?: VoidFunction
) => {
  const { webinar } = containerValuesRef.current.message
  const { messageType } = containerValuesRef.current.messageConfiguration
  const shouldShowModal = containerValuesRef.current.messageConfiguration?.saveButton?.separateSaveAndClose || isCustomerCareLogin()
  const haveUnsavedChanges = containerValuesRef.current.haveUnsavedChanges
  const isCustomerCare = isCustomerCareLogin()

  const { isEmailForm, isEmailWebinar, isEmailProgram } = detectEmailType(messageType)

  const shouldPassMessageType = isEmailForm || isEmailWebinar || isEmailProgram

  if (shouldShowModal && haveUnsavedChanges) {
    updateModal('confirmation', {
      title: t(`EmailComposer.Preview.EditPlainText.UnsavedChanges.Modal.Title${shouldPassMessageType ? `.${messageType}` : ''}`, {
        context: webinar?.scheduledTime ? 'scheduled' : 'default',
      }),
      body: (
        <>
          <Typography
            text={t(`EmailComposer.Preview.EditPlainText.UnsavedChanges.Modal.BodyText${shouldPassMessageType ? `.${messageType}` : ''}`, {
              context: webinar?.scheduledTime ? 'scheduled' : 'default',
              date: dayjs(webinar?.scheduledTime).format('MMMM D, YYYY'),
              time: dayjs(webinar?.scheduledTime).format('HH:MMA'),
            })}
            type={TextType.BODY_TEXT_LIGHT}
            tagProps={{ bold: { weight: TextWeight.BOLD, inline: true }, medium: { weight: TextWeight.MEDIUM, inline: true } }}
            className="push-down-x2 push-up-x2"
          />
          {!isCustomerCare && (
            <Caution
              message={
                <Typography
                  text={t(`EmailComposer.Preview.EditPlainText.UnsavedChanges.Modal.Caution.${messageType}`)}
                  tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
                />
              }
            />
          )}
        </>
      ),
      yesButtonText: t('Save and continue'),
      noButtonText: t('Cancel'),
      isYesNo: true,
      onAnswer: (answer) => {
        if (answer === YesNo.YES) {
          updateModal('spinner', { size: LoaderSize.LARGE })
          saveComposerEmail(containerValuesRef.current).then(() => {
            updateModal('spinner', undefined)
            onConfirm?.()
          })
        } else {
          updateModal('confirmation', undefined)
        }
      },
    })
  }
}

export const testMessage = (
  containerValuesRef: ContainerValuesRefType,
  update: CommonComposerAPI['update'],
  updateModal: CommonComposerAPI['updateModal'],
  saveComposerEmail: SaveComposerCommonType
) => {
  const { webinar } = containerValuesRef.current.message
  const { messageType } = containerValuesRef.current.messageConfiguration
  const shouldShowModal = containerValuesRef.current.messageConfiguration?.saveButton?.separateSaveAndClose
  const haveUnsavedChanges = containerValuesRef.current.haveUnsavedChanges
  if (shouldShowModal && haveUnsavedChanges) {
    const isWebinarMessage = containerValuesRef.current.messageConfiguration.messageType === 'WEBINAR_MESSAGE'
    updateModal('confirmation', {
      title: t(`EmailComposer.TestEmail.UnsavedChanges.Modal.Title${isWebinarMessage ? `.${messageType}` : ''}`, {
        context: webinar?.scheduledTime ? 'scheduled' : 'default',
      }),
      body: (
        <>
          <Typography
            text={t(`EmailComposer.TestEmail.UnsavedChanges.Modal.BodyText.${messageType}`, {
              context: webinar?.scheduledTime ? 'scheduled' : 'default',
              date: dayjs(webinar?.scheduledTime).format('MMMM D, YYYY'),
              time: dayjs(webinar?.scheduledTime).format('HH:MMA'),
            })}
            type={TextType.BODY_TEXT_LIGHT}
            tagProps={{ bold: { weight: TextWeight.BOLD, inline: true }, medium: { weight: TextWeight.MEDIUM, inline: true } }}
            className="push-down-x2 push-up-x2"
          />

          <Caution
            message={
              <Typography
                text={t(`EmailComposer.TestEmail.UnsavedChanges.Modal.Caution.${messageType}`)}
                tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
              />
            }
          />
        </>
      ),
      yesButtonText: t('Save and continue'),
      noButtonText: t('Cancel'),
      isYesNo: true,
      onAnswer: (answer) => {
        if (answer === YesNo.YES) {
          update({ loading: true })
          updateModal('confirmation', undefined)
          saveComposerEmail(containerValuesRef.current)
          update({ isTestSend: true, loading: false })
        } else {
          updateModal('confirmation', undefined)
        }
      },
    })
  } else {
    saveComposerEmail(containerValuesRef.current)
    update({ isTestSend: true })
  }
}

export const discardEmail = async (
  containerValuesRef: ContainerValuesRefType,
  history: HistoryInterface.History<ComposerHistoryState>,
  enableEmailDraftsReact: boolean,
  deleteEmailDrafts: EmailComposerRequests['deleteEmailDrafts'],
  updateModal: CommonComposerAPI['updateModal']
) => {
  const { isOptInEmail } = detectEmailType(containerValuesRef.current.message.messageType)
  updateModal('deleteConfirmation', {
    title: t(isOptInEmail ? 'bouncesAndOptOuts.discardModal.title' : 'EmailComposer.SaveButton.Delete.Title'),
    cancelButtonText: t('Keep editing'),
    body: (
      <Typography
        text={t(isOptInEmail ? 'bouncesAndOptOuts.discardModal.bodyText' : 'EmailComposer.SaveButton.Delete.Body')}
        type={TextType.BODY_TEXT_LIGHT}
        tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
      />
    ),
    onAnswer: (answer: YesNo) => {
      if (answer === YesNo.YES) {
        deleteAndClose(containerValuesRef, history, enableEmailDraftsReact, deleteEmailDrafts)
      }
      updateModal('deleteConfirmation', undefined)
    },
  })
}

export const closeMessage = async (
  containerValuesRef: ContainerValuesRefType,
  history: HistoryInterface.History<ComposerHistoryState>,
  enableEmailDraftsReact: boolean,
  updateModal: CommonComposerAPI['updateModal'],
  discardEmailChanges: EmailComposerRequests['discardEmailChangesRequest']
) => {
  const {
    message: { id },
    messageConfiguration: { messageType, saveButton, canPublish },
  } = containerValuesRef.current
  const { separateSaveAndClose } = saveButton ?? {}

  const { isEmailForm, isEmailABTest, isEmailRSS, isEmailWebinar, isEmailBlankMessage, isEmailProgram } = detectEmailType(messageType)
  const showDeleteConfirmation = !isEmailForm && !isEmailProgram && separateSaveAndClose

  if (containerValuesRef.current.haveUnsavedChanges || showDeleteConfirmation) {
    updateModal('deleteConfirmation', {
      title: `${t(separateSaveAndClose ? 'Discard changes' : 'You have unsaved changes')}${separateSaveAndClose ? '?' : ''}`,
      cancelButtonText: t('Keep editing'),
      body: (
        <Typography
          style={{ marginTop: '1.5rem' }}
          type={TextType.BODY_TEXT_LIGHT}
          text={t(
            `EmailComposer.Close.Modal.Body${
              isEmailBlankMessage || isEmailABTest || isEmailRSS || (isEmailWebinar && canPublish) ? `.${messageType}` : ''
            }`
          )}
          tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
        />
      ),
      onAnswer: (answer: YesNo) => {
        if (answer === YesNo.YES) {
          if (isEmailABTest || isEmailRSS || canPublish) {
            discardEmailChanges(id).then((data) => {
              if (data?.discardEmailChanges) {
                closeComposer(containerValuesRef, history, enableEmailDraftsReact)
              }
            })
          } else {
            closeComposer(containerValuesRef, history, enableEmailDraftsReact)
          }
        }
        updateModal('deleteConfirmation', undefined)
      },
    })
  } else closeComposer(containerValuesRef, history, enableEmailDraftsReact)
}

export const handleSpamCheckModal = (
  messageType: ExtendedMessageType,
  updateModal: CommonComposerAPI['updateModal'],
  onSave: CommonComposerAPI['onSave'],
  litmusQuery: (testId?: string) => Promise<void>,
  testId?: string,
  isCustomerCare?: boolean,
  webinarScheduledTime?: number
) => {
  const isWebinarMessage = messageType === 'WEBINAR_MESSAGE'
  updateModal('confirmation', {
    title: t(`EmailComposer.ConfirmationModal.Title${isWebinarMessage ? `.${messageType}` : ''}`),
    body: (
      <>
        <Typography
          text={t(`EmailComposer.ConfirmationModals.${isCustomerCare ? 'CUSTOMER_CARE' : messageType}.BodyText`, {
            context: !!webinarScheduledTime ? 'scheduled' : 'default',
            date: dayjs(webinarScheduledTime).format('MMMM D, YYYY'),
            time: dayjs(webinarScheduledTime).format('HH:MMA'),
          })}
          type={TextType.BODY_TEXT_LIGHT}
          tagProps={{ bold: { weight: TextWeight.BOLD, inline: true }, medium: { weight: TextWeight.MEDIUM, inline: true } }}
          className="push-down-x2 push-up-x2"
        />
        {!isCustomerCare && (
          <Caution
            message={
              <Typography
                text={t(`EmailComposer.ConfirmationModals.${messageType}.Caution`)}
                type={TextType.BODY_TEXT_LIGHT}
                tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
              />
            }
          />
        )}
      </>
    ),
    yesButtonText: t('Save and continue'),
    noButtonText: t('Cancel'),
    isYesNo: true,
    onAnswer: (answer) => {
      if (answer === YesNo.YES) {
        onSave(true)
        litmusQuery(testId)
        updateModal('spinner', { size: LoaderSize.LARGE })
      } else updateModal('confirmation', undefined)
    },
  })
}

export const onPreview = (
  containerValuesRef: ContainerValuesRefType,
  beeEditorRef: React.MutableRefObject<Bee | undefined>,
  update: CommonComposerAPI['update'],
  isLandingPage?: boolean,
  autoSaveDebounce?: DebouncedState<() => Promise<void>>
) => {
  const {
    isCreatedNewMessage,
    isStory,
    detectedURLChanges,
    message: { isPlainTextOnly, isUploadHtmlOnly },
    disabledFeatures: { autoSave },
  } = containerValuesRef.current

  // The send event is the only event where we receive the HTML
  // so we need to trigger this for preview mode

  try {
    if (isLandingPage) {
      beeEditorRef.current?.save()
    } else {
      beeEditorRef.current?.send()
    }
  } catch (error) {
    logError(error)
  }

  update({ loading: true })

  //* When existing message opened or need to create new message with start id - call save on preview
  //* For other message types if autosave is disabled these types will be handled differently (e.g form, program etc. requires save)
  if (!autoSave && (!isCreatedNewMessage || detectedURLChanges.hasStartId)) {
    autoSaveDebounce?.()
  }

  if (isStory || isPlainTextOnly || isUploadHtmlOnly) {
    update({ loading: false })
  }
}

const getContactsFromCrmFilter = async (
  listId: string,
  crmNamespace: string,
  crmFilter: string,
  getContactsFromCrmFilterRequest: EmailComposerRequests['getContactsFromCrmFilterRequest']
) => {
  const { data, errors } = await getContactsFromCrmFilterRequest({ listId, crmNamespace, crmFilter })

  if (data) {
    return data.getContactsFromCrmFilter
  } else if (errors) {
    logNewRelicError(errors)
  }
}

const getContactsFromCrmAccounts = async (
  listId: string,
  crmAccountsIds: string[],
  getContactsFromCrmAccountsRequest: EmailComposerRequests['getContactsFromCrmAccountsRequest']
) => {
  const { data, errors } = await getContactsFromCrmAccountsRequest({ listId, crmAccountsIds })

  if (data) {
    return data.getContactsFromCrmAccounts
  } else if (errors) {
    logNewRelicError(errors)
  }
}

const getContactsFromCrmEmails = async (
  listId: string,
  crmEmails: string[],
  getContactsFromCrmEmailsRequest: EmailComposerRequests['getContactsFromCrmEmailsRequest']
) => {
  const { data, errors } = await getContactsFromCrmEmailsRequest({ listId, crmEmails })

  if (data && data.getContactsFromCrmEmails) {
    return data.getContactsFromCrmEmails
  } else if (errors) {
    logNewRelicError(errors)
  }
}

export const handleContactsFromCRM = async (
  queryParams: URLSearchParams,
  update: CommonComposerAPI['update'],
  requests: {
    getContactsFromCrmFilterRequest: EmailComposerRequests['getContactsFromCrmFilterRequest']
    getContactsFromCrmAccountsRequest: EmailComposerRequests['getContactsFromCrmAccountsRequest']
    getContactsFromCrmEmailsRequest: EmailComposerRequests['getContactsFromCrmEmailsRequest']
  }
) => {
  const { getContactsFromCrmFilterRequest, getContactsFromCrmAccountsRequest, getContactsFromCrmEmailsRequest } = requests

  const crmEmailList = queryParams.get('crmemaillist')
  const crmFilterNamespace = queryParams.get('filternamespace')
  const crmFilter = queryParams.get('filtername')
  const crmAccountIds = queryParams.get('crmAccountIds')
  const crmContactEmails = queryParams.get('crmcontact')
  let contactList: Partial<ListContactGq[] | undefined> = []

  if (crmEmailList && crmFilterNamespace && crmFilter) {
    contactList = await getContactsFromCrmFilter(crmEmailList, crmFilterNamespace, crmFilter, getContactsFromCrmFilterRequest)
  } else if (crmEmailList && crmAccountIds) {
    contactList = await getContactsFromCrmAccounts(crmEmailList, crmAccountIds.split(','), getContactsFromCrmAccountsRequest)
  } else if (crmEmailList && crmContactEmails) {
    contactList = await getContactsFromCrmEmails(crmEmailList, crmContactEmails.split(','), getContactsFromCrmEmailsRequest)
  }

  if (contactList) {
    update({
      message: {
        individualRecipientsSourceIds: contactList.map((contact) => contact?.id),
        sendto_contacts: contactList.map((contact) => ({
          email: contact?.email ?? '',
          id: contact?.id ?? '',
          name: contact?.name ?? '',
          srcId: crmEmailList ?? '',
        })),
      },
    })
  }
}
