import React, { FC } from 'react'

import { CollapsibleBannerCollapseButtonProps } from '@components/CollapsibleBanner/components/CollapsibleBannerCollapseButton/CollapsibleBannerCollapseButton'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'

import './CollapsibleBannerCollapsedContent.css'

interface CollapsibleBannerCollapsedContentProps extends CollapsibleBannerCollapseButtonProps {
  collapsed: boolean
  customTooltipText?: string
  icon: SvgNames
  iconSize?: SvgType
  triggerClassName?: string
}

const rootClass = 'collapsible-banner-collapsed-content'

const CollapsibleBannerCollapsedContent: FC<CollapsibleBannerCollapsedContentProps> = ({
  collapsed,
  customTooltipText,
  onClick,
  icon,
  iconSize = SvgType.LARGE_ICON,
}: CollapsibleBannerCollapsedContentProps) => {
  const { t } = useTranslation()

  return (
    <Tooltip
      className={`${rootClass}__tooltip`}
      trigger={
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
        <div
          data-test={`${rootClass}__expand-button`}
          className={`${rootClass}__icon-container`}
          role={'button'}
          onClick={() => onClick(!collapsed ?? false)}
        >
          <Svg name={icon} type={iconSize} />
        </div>
      }
      position={'top'}
      align={'center'}
    >
      <div>{customTooltipText ?? (collapsed ? t('Expand') : t('Collapse'))} </div>
    </Tooltip>
  )
}

export default CollapsibleBannerCollapsedContent
