import React, { FC, useCallback, useMemo, useState } from 'react'

import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import LabelWithSvgTooltip from '@components/LabelWithTooltipIcon/LabelWithSvgTooltip'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Svg, { SvgNames } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { UserPermissionsInput } from '@graphql/types/mutation-types'
import AllPermissionsForm from '@src/pages/Settings/Users/components/AllPermissionsForm/AllPermissionsForm'
import CopyPermissionsModal, { CopyFromUser } from '@src/pages/Settings/Users/components/CopyPermissionsModal/CopyPermissionsModal'
import { UserTypeValue } from '@src/pages/Settings/Users/components/InviteUsersModal/const/userType.const'
import { InviteUsersValueChangesFuncType } from '@src/pages/Settings/Users/components/InviteUsersModal/InviteUsersModalContent'
import UserLaunchApprovalSelect from '@src/pages/Settings/Users/components/UserLaunchApprovalSelect/UserLaunchApprovalSelect'
import { NEED_MORE_LINK } from '@src/pages/Settings/Users/constants/users.constants'
import { useSalesUsers } from '@src/pages/Settings/Users/context/SalesUsersContext'
import { SALES_USERS_CAN_DO_LINK } from '@src/pages/Settings/Users/tabs/SalesUsers/constants/salesUsers.constants'

import './InviteUsersPermissionsForm.css'

interface Props {
  userType: UserTypeValue
  onValueChange: InviteUsersValueChangesFuncType
  maxCount: number
  haveAvailable: boolean
  loading: boolean
  dataTest?: string
}

const rootClass = 'invite-users-permissions-form'
const defaultLaunchApproval = 'Allowed to launch'

const emptyListingCommonProps = {
  headlineLineHeight: LineHeight.LARGER,
  headlineType: TextType.MODAL_HEADLINE,
  size: EmptyListingSize.MEDIUM,
  className: `${rootClass}__empty-listing`,
}

const InviteUsersPermissionsForm: FC<Props> = (props: Props) => {
  const { userType, onValueChange, maxCount, haveAvailable, loading, dataTest = rootClass } = props
  const { enableLaunch } = useSalesUsers()
  const { t } = useTranslation()
  const type = useMemo<'marketing' | 'sales'>(() => (userType === UserTypeValue.SALES ? 'sales' : 'marketing'), [userType])
  const showLaunchApproval = useMemo(() => userType !== UserTypeValue.MARKETING, [userType])

  const [launchApprovals, setLaunchApprovals] = useState<{ launchApproval?: string; launchApprovalId?: string }>({
    launchApproval: defaultLaunchApproval,
  })
  const [permissions, setPermissions] = useState<UserPermissionsInput | undefined>(undefined)
  const [openCopyPermissions, setOpenCopyPermissions] = useState<boolean>(false)
  const handleCopyPermissions = useCallback(() => setOpenCopyPermissions(true), [])
  const cancelCopyPermissions = useCallback(() => setOpenCopyPermissions(false), [])

  const getCopyPermissions = useCallback(
    (data?: CopyFromUser) => {
      cancelCopyPermissions()
      if (data) {
        // Delete 'launchApproval' prop which doesn't exist in UserPermissionsInput
        delete data.permissions?.launchApproval
        setPermissions(data.permissions)
        setLaunchApprovals({ launchApproval: data.launchApproval, launchApprovalId: data.launchApprovalId })
      }
    },
    [cancelCopyPermissions]
  )
  if (!enableLaunch && userType === UserTypeValue.SALES) {
    return (
      <EmptyListing
        headline={t('Sales.Users.Limited.Access.Title')}
        imgSrc={StaticImageNames.noProgramHistory}
        text={t('Sales.Users.Limited.Access.Text')}
        link={SALES_USERS_CAN_DO_LINK}
        linkText={'Sales.Users.Can.Do.Link.Text'}
        {...emptyListingCommonProps}
        dataTest={`${dataTest}-sales-limited`}
      />
    )
  }

  if (!loading && (!haveAvailable || !maxCount)) {
    return (
      <EmptyListing
        headline={t('Invite.Users.Permissions.Empty.Listing.Title', { type })}
        imgSrc={StaticImageNames.errorStateAllContacts}
        text={t('Invite.Users.Permissions.Empty.Listing.Text', { count: maxCount, type })}
        link={NEED_MORE_LINK}
        linkText={'Invite.Users.Permissions.Empty.Listing.Link.Text'}
        {...emptyListingCommonProps}
        dataTest={`${dataTest}-empty-listing`}
      />
    )
  }

  if (showLaunchApproval) {
    const launchClassName = `${rootClass}__launch`
    return (
      <div className={launchClassName}>
        <Typography text={t('Email Permissions')} weight={TextWeight.BOLD} lineHeight={LineHeight.TINY} />
        <div className={`${launchClassName}__content`}>
          <LabelWithSvgTooltip
            label={t('Allowed to launch')}
            tooltipContent={t('Allowed.To.Launch.Tooltip')}
            className={`${launchClassName}__content-title`}
            alignTextCenter
          />
          <UserLaunchApprovalSelect onSubmit={(approval) => onValueChange({ launchApproval: approval })} launchApproval={defaultLaunchApproval} />
        </div>
      </div>
    )
  }

  return (
    <div className={rootClass}>
      <Tooltip
        position="right"
        alignTextCenter
        className={`${rootClass}__copy-permissions-tooltip`}
        triggerClassName={`${rootClass}__copy-permissions-trigger`}
        sideOffset={9}
        trigger={
          <Button
            buttonType={ButtonType.INFO}
            iconPosition={ButtonIconPosition.LEFT}
            onClick={handleCopyPermissions}
            dataTest={`${dataTest}-copy-permissions-button`}
            className={`${rootClass}__copy-permissions-button`}
          >
            <Svg name={SvgNames.cloneSegment} />
            {t('Copy permissions')}
          </Button>
        }
      >
        {t('Copy.Permissions.Button.Tooltip')}
      </Tooltip>
      <CopyPermissionsModal isOpen={openCopyPermissions} onSave={getCopyPermissions} onCancel={cancelCopyPermissions} />
      <AllPermissionsForm onValueChange={onValueChange} useGetDefaultValues permissions={permissions} {...launchApprovals} />
    </div>
  )
}

export default InviteUsersPermissionsForm
