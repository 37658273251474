import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import SidebarMenu from '@components/SidebarMenu/SidebarMenu'
import { ListItem } from '@components/SimpleList/SimpleList'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import { TableV2 } from '@components/TableV2/TableV2'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './DataReviewSidebar.css'

interface DataReviewSidebarProps {
  className?: string
  dataTest?: string
  fieldIndex: number
  fieldName: string
  fieldsAmount: number
  fieldsStatus: ListItem[]
  setPreviewIndex: (index?: number) => void
}

const rootClass = 'data-review-sidebar'

const DataReviewSidebar: FC<DataReviewSidebarProps> = (props: DataReviewSidebarProps) => {
  const { dataTest = rootClass, className = '', fieldName = '', fieldsStatus, fieldsAmount = 0, fieldIndex = 0, setPreviewIndex } = props

  const { t } = useTranslation()

  const tableColumns: ColumnDefWithAdditionalProps<ListItem>[] = [
    {
      header: t('Field'),
      maxSize: 180,
      accessorKey: 'value',
      textAlign: 'left',
    },
    {
      header: t('Status'),
      maxSize: 235,
      accessorKey: 'hasError',
      textAlign: 'left',
      cell: ({ cell, getValue }) => {
        const hasError = getValue()
        const { icon, text } = hasError
          ? { icon: SvgNames.errorSolid, text: 'Field value do not match selected data type' }
          : cell.row.original.value
          ? { icon: SvgNames.checkSuccess, text: 'Success' }
          : { icon: SvgNames.warningSolidNoFill, text: 'Empty field value' }
        return (
          <Tooltip
            trigger={
              <div data-test={`${dataTest}-status`} className={`${rootClass}__status`}>
                <Svg name={icon} type={SvgType.ICON} {...(icon === SvgNames.warningSolidNoFill ? { fill: SvgColor.YELLOW_CAUTION } : {})} />
                <Typography className={'ellip'} text={t(text)} />
              </div>
            }
            inline={false}
          >
            {t(text)}
          </Tooltip>
        )
      },
    },
  ]

  const renderButton = (caretDirection: SvgNames.caretLeft | SvgNames.caretRight, disabled?: boolean) => {
    const isIncreasing = caretDirection === SvgNames.caretRight
    return (
      <Button
        className={`${rootClass}__pagination-button`}
        buttonType={ButtonType.OUTLINE}
        disabled={disabled}
        onClick={() => !disabled && setPreviewIndex(isIncreasing ? fieldIndex + 1 : fieldIndex - 1)}
      >
        <Svg name={caretDirection} fill={SvgColor.TEXT_GRAY} />
      </Button>
    )
  }

  return (
    <SidebarMenu
      className={classNames(rootClass, className)}
      data-test={dataTest}
      icon={SvgNames.previewOn}
      header={t('Data Preview')}
      onClose={setPreviewIndex}
    >
      <div data-test={`${dataTest}-header`} className={`${rootClass}__header`}>
        <TextWithTooltipOnEllip typographyProps={{ text: fieldName, type: TextType.DATA_CARD_MODAL_HEADER }} />
        <div className={`${rootClass}__pagination`}>
          {renderButton(SvgNames.caretLeft, fieldIndex <= 0)}
          <Typography text={'{{current}} of {{total}}'} values={{ current: fieldIndex + 1, total: fieldsAmount }} />
          {renderButton(SvgNames.caretRight, fieldIndex >= fieldsAmount - 1)}
        </div>
      </div>
      <TableV2 data={fieldsStatus} columns={tableColumns} />
    </SidebarMenu>
  )
}

export default DataReviewSidebar
