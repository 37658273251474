import React, { RefCallback, useContext, useMemo, FC } from 'react'
import { OptionProps } from 'react-select'

import classNames from 'classnames'

import { SelectV2Context } from '@components/SelectV2/SelectV2.context'
import { selectRootClass } from '@components/SelectV2/SelectV2.utils'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { undefinedReturnedFunction, useTranslation } from '@const/globals'
import { ITEM_TYPES, ItemType } from '@utils/categorization'

import './CustomCreateOption.css'

const rootClass = 'custom-create-option'

export interface CustomCreateOptionProps extends OptionProps<any> {
  outerRef?: RefCallback<HTMLDivElement>
}

const CustomCreateOption: FC<CustomCreateOptionProps> = (props) => {
  const { getClassNames, innerRef, innerProps, children, outerRef } = props
  const {
    values: { inputValue, itemType, enableCreateOptionWithoutTyping, hasSearchOnClick },
  } = useContext(SelectV2Context)
  const { t } = useTranslation()
  const optionClassNames = getClassNames('option', props)
  const isTruncated = optionClassNames?.includes(`${selectRootClass}__option-new-truncated`)

  const childrenSize = Array.isArray(children) ? children.length : 0
  const hideMenuList = childrenSize === 1

  const getText = () => {
    if (hasSearchOnClick) {
      return t(`Add <bold>{{inputValue}}</bold>`, { inputValue })
    } else if (!inputValue && !enableCreateOptionWithoutTyping) {
      return t(`Type to create a new {{itemType}}...`, { itemType: t(ITEM_TYPES[itemType as ItemType].itemText) })
    } else {
      return t(`Create {{itemType}} <bold>{{inputValue}}</bold>`, {
        inputValue: enableCreateOptionWithoutTyping ? '' : inputValue,
        itemType: t(ITEM_TYPES[itemType as ItemType].itemText),
      })
    }
  }

  const text = getText()

  const renderNewOption = useMemo(
    () => (
      <Typography
        className={isTruncated ? `${selectRootClass}__option-new-truncated` : ''}
        text={text}
        lineHeight={LineHeight.MEDIUM}
        weight={TextWeight.MEDIUM_LIGHT}
        tagProps={{ bold: { weight: TextWeight.BOLD } }}
        inline
      />
    ),
    [text, isTruncated]
  )
  const updatedProps = {
    ...innerProps,
    onClick: !inputValue && !enableCreateOptionWithoutTyping ? undefinedReturnedFunction : innerProps.onClick,
  }

  return (
    <div
      className={classNames(rootClass, `${selectRootClass}__option-new-container`, {
        [`${rootClass}__disabled`]: !inputValue && !enableCreateOptionWithoutTyping,
        [`${rootClass}__hidden-menu`]: hideMenuList,
      })}
      ref={outerRef}
    >
      <div ref={innerRef} className={classNames(`${selectRootClass}__option`, `${selectRootClass}__option-new`)} {...updatedProps}>
        <div className={classNames(`${selectRootClass}__option-new-create-item`, { ['ellip']: isTruncated })}>
          <div className={`${selectRootClass}__option-new-icon`}>
            <Svg name={SvgNames.plus} type={SvgType.ICON} fill={SvgColor.LIGHT_GRAY} />
          </div>
          {isTruncated ? (
            <TextWithTooltipOnEllip
              typographyProps={renderNewOption.props}
              tooltipProps={{ align: 'start', alignOffset: 102, triggerClassName: 'ellip' }}
            />
          ) : (
            renderNewOption
          )}
        </div>
        {!!inputValue && !enableCreateOptionWithoutTyping && (
          <div className={`${selectRootClass}__option-new-enter`}>
            <Typography text={t('Enter')} lineHeight={LineHeight.MEDIUM} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.MEDIUM_LIGHT} inline />
          </div>
        )}
      </div>
    </div>
  )
}

export default CustomCreateOption
