import { FilterQueryParams } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import getAllItems from '@graphql/microservices/categorization/getAllItems'
import getCountForFavoritesAndCreatedByMeAndRecent from '@graphql/microservices/categorization/getCountForFavoritesAndCreatedByMeAndRecent'
import getCountQuery from '@graphql/microservices/categorization/getCountQuery'
import getFavoriteItems from '@graphql/microservices/categorization/getFavoriteItems'
import getItems from '@graphql/microservices/categorization/getItems'
import getItemsByAuthor from '@graphql/microservices/categorization/getItemsByAuthor'
import getItemsInFolder from '@graphql/microservices/categorization/getItemsInFolder'
import getItemsInFolderAndLabels from '@graphql/microservices/categorization/getItemsInFolderAndLabels'
import getRecentItems from '@graphql/microservices/categorization/getRecentItems'
import getSubTypesByTypes from '@graphql/microservices/categorization/getSubTypesByTypes'
import {
  GetAllItemsQuery,
  GetAllItemsQueryVariables,
  GetCountForFavoritesAndCreatedByMeAndRecentQuery,
  GetCountForFavoritesAndCreatedByMeAndRecentQueryVariables,
  GetCountQueryQuery,
  GetCountQueryQueryVariables,
  GetFavoriteItemsQuery,
  GetFavoriteItemsQueryVariables,
  GetItemsByAuthorQuery,
  GetItemsByAuthorQueryVariables,
  GetItemsInFolderAndLabelsQuery,
  GetItemsInFolderAndLabelsQueryVariables,
  GetItemsInFolderQuery,
  GetItemsInFolderQueryVariables,
  GetItemsQuery,
  GetItemsQueryVariables,
  GetRecentItemsQuery,
  GetRecentItemsQueryVariables,
  GetSubTypesByTypesQuery,
  GetSubTypesByTypesQueryVariables,
  SubTypeConditionDtoInput,
} from '@graphql/types/microservice/categorization-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

import { ListPageCommonState } from '../Context/ListingPageCommon.context'

export interface GetFilterRequests {
  getAllItemsRequest: (params: FilterQueryParams) => FetchPromise<GetAllItemsQuery>
  getFavoriteItemsRequest: (params: FilterQueryParams) => FetchPromise<GetFavoriteItemsQuery>
  getItemsInFolderRequest: (params: FilterQueryParams) => FetchPromise<GetItemsInFolderQuery>
  getCreatedByMeItemsRequest: (params: FilterQueryParams) => FetchPromise<GetItemsByAuthorQuery>
  getRecentItemsRequest: (params: FilterQueryParams) => FetchPromise<GetRecentItemsQuery>
  getItemsInTagRequest: (params: FilterQueryParams) => FetchPromise<GetItemsInFolderAndLabelsQuery>
  getCustomFilterItemsRequest: (params: FilterQueryParams, field: string, query: string) => FetchPromise<GetItemsQuery>
  getSubTypesByTypesRequest: (variables: GetSubTypesByTypesQueryVariables) => FetchPromise<GetSubTypesByTypesQuery>
  getCountQueryRequest: (variables: GetCountQueryQueryVariables) => FetchPromise<GetCountQueryQuery>
  getCountForFavoritesAndCreatedByMeAndRecentRequest: (
    variables: GetCountForFavoritesAndCreatedByMeAndRecentQueryVariables
  ) => FetchPromise<GetCountForFavoritesAndCreatedByMeAndRecentQuery>
}

export const useFilterRequests = (
  containerValues: ListPageCommonState,
  folderSubTypes: string[] = [],
  subTypeCondition?: SubTypeConditionDtoInput
): GetFilterRequests => {
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })
  const { selectedCustomSource } = containerValues

  const getAllItemsRequest = (params: FilterQueryParams) => {
    return client.query<GetAllItemsQuery, GetAllItemsQueryVariables>({
      query: getAllItems,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        ...params,
        folderSubTypes,
        subTypeCondition,
      },
    })
  }

  const getFavoriteItemsRequest = (params: FilterQueryParams) => {
    return client.query<GetFavoriteItemsQuery, GetFavoriteItemsQueryVariables>({
      query: getFavoriteItems,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        ...params,
        folderSubTypes,
        subTypeCondition,
      },
    })
  }

  const getItemsInFolderRequest = (params: FilterQueryParams) => {
    return client.query<GetItemsInFolderQuery, GetItemsInFolderQueryVariables>({
      query: getItemsInFolder,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        ...params,
        folderSubTypes,
        subTypeCondition,
      },
    })
  }

  const getCreatedByMeItemsRequest = (params: FilterQueryParams) => {
    return client.query<GetItemsByAuthorQuery, GetItemsByAuthorQueryVariables>({
      query: getItemsByAuthor,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        ...params,
        folderSubTypes,
        subTypeCondition,
      },
    })
  }

  const getRecentItemsRequest = (params: FilterQueryParams) => {
    return client.query<GetRecentItemsQuery, GetRecentItemsQueryVariables>({
      query: getRecentItems,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        ...params,
        folderSubTypes,
      },
    })
  }

  const getItemsInTagRequest = (params: FilterQueryParams) => {
    const { activeTagId } = params

    return client.query<GetItemsInFolderAndLabelsQuery, GetItemsInFolderAndLabelsQueryVariables>({
      query: getItemsInFolderAndLabels,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        ...params,
        labelIds: [activeTagId],
        folderSubTypes,
        subTypeCondition,
      },
    })
  }

  const getCustomFilterItemsRequest = (params: FilterQueryParams, field: string, query: string) => {
    return client.query<GetItemsQuery, GetItemsQueryVariables>({
      query: getItems,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        ...params,
        field,
        query,
        folderSubTypes,
      },
    })
  }

  const getSubTypesByTypesRequest = (variables: GetSubTypesByTypesQueryVariables) => {
    return client.query<GetSubTypesByTypesQuery, GetSubTypesByTypesQueryVariables>({
      query: getSubTypesByTypes,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables,
    })
  }

  const getCountQueryRequest = (variables: GetCountQueryQueryVariables) => {
    return client.query<GetCountQueryQuery, GetCountQueryQueryVariables>({
      query: getCountQuery,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables,
    })
  }

  const getCountForFavoritesAndCreatedByMeAndRecentRequest = (variables: GetCountForFavoritesAndCreatedByMeAndRecentQueryVariables) => {
    return client.query<GetCountForFavoritesAndCreatedByMeAndRecentQuery, GetCountForFavoritesAndCreatedByMeAndRecentQueryVariables>({
      query: getCountForFavoritesAndCreatedByMeAndRecent,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables,
    })
  }

  const custom = selectedCustomSource?.customRequestGeneral

  return {
    getAllItemsRequest,
    getFavoriteItemsRequest,
    getItemsInFolderRequest: custom?.getItemsInFolderRequest ?? getItemsInFolderRequest,
    getCreatedByMeItemsRequest,
    getRecentItemsRequest,
    getItemsInTagRequest,
    getCustomFilterItemsRequest: custom?.getCustomFilterItemsRequest ?? getCustomFilterItemsRequest,
    getSubTypesByTypesRequest: custom?.getSubTypesByTypesRequest ?? getSubTypesByTypesRequest,
    getCountQueryRequest: custom?.getCountQueryRequest ?? getCountQueryRequest,
    getCountForFavoritesAndCreatedByMeAndRecentRequest:
      custom?.getCountForFavoritesAndCreatedByMeAndRecentRequest ?? getCountForFavoritesAndCreatedByMeAndRecentRequest,
  }
}
