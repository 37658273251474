import { gql } from '@apollo/client'

export default gql`
  query getSmsMessage($smsMessageId: Long!, $accountId: Long!) {
    getSmsMessage(smsMessageId: $smsMessageId, accountId: $accountId) {
      accountId
      description
      messageText
      smsMessageId
      status
      title
    }
  }
`
