import { createContext } from 'react'

import { SwitchOptions } from '@components/Switch/Switch'
import { List } from '@interface/foldersLists/List'
import { StatusToastType } from '@interface/StatusToast'
import { mockSegment } from '@src/pages/ContactSegments/components/ABSplit/ABSplit.mock'
import { Segment } from '@utils/contactSegments/contactSegments.utils'

export interface Split {
  name: string
  value: string
  autoUpdate: boolean
  isValid: boolean
}

export interface SuppressedSource {
  id: string
  name: string
  size: number | undefined
  suppressed: number
}
export interface ABSplitContainerState {
  splitName: string
  numberOfSplits: number
  statusToast?: StatusToastType
  doRegenerate: boolean
  suppressNonDeliverable: boolean
  selectedDistribution: SwitchOptions
  showListPicker: boolean
  sources: SuppressedSource[]
  sourceToRemove?: List
  splits: Split[]
  suppressedSplits: Split[]
  availableContacts: number
  nonDeliverableContacts: Set<string>
  suppressedContacts: Set<string>
  fixedSplits: number
  inputError: boolean
  changeDistribution: boolean
  baseSegment: Segment | undefined
}

export const ABSplitContainerInitialState: ABSplitContainerState = {
  splitName: '',
  numberOfSplits: 2,
  doRegenerate: false,
  suppressNonDeliverable: false,
  selectedDistribution: SwitchOptions.PERCENT,
  showListPicker: false,
  sources: [],
  splits: [
    { name: 'A', value: '50', autoUpdate: true, isValid: true },
    { name: 'B', value: '50', autoUpdate: true, isValid: true },
  ],
  suppressedSplits: [],
  availableContacts: 0,
  nonDeliverableContacts: new Set(),
  suppressedContacts: new Set(),
  fixedSplits: 0,
  inputError: false,
  changeDistribution: false,
  baseSegment: undefined,
}

type Update = (field: keyof ABSplitContainerState, value: any) => void
type OnAddOrRemoveSource = (lists: SuppressedSource[]) => void
type RecalculateSplits = (splits: Split[], suppressing?: boolean) => Split[]

export interface ABSplitAPI {
  values: ABSplitContainerState
  update: Update
  onAddOrRemoveSource: OnAddOrRemoveSource
  recalculateSplits: RecalculateSplits
}

export const ABSplitContextMock: ABSplitAPI = {
  values: {
    ...ABSplitContainerInitialState,
    baseSegment: mockSegment,
  },
  update: () => undefined,
  onAddOrRemoveSource: () => undefined,
  recalculateSplits: () => [],
}

export const ABSplitContext = createContext<ABSplitAPI>({ values: ABSplitContainerInitialState } as any)
