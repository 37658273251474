export default `
section:has(button.delete) #nav {
  display: none;
}

.search svg {
  width: 1.3334rem;
  height: 1.3334rem;
}

.search .icon {
  transform: translateY(-53%);
}

.search input[type="search"] {
  border-radius: 0.3334rem;
  font-size: var(--large-font-size);
  padding-left: var(--spacing-unit-x6);
}

.search input[type="search"]:focus {
  border-color: var(--dark-blue);
  outline: var(--input-thin-outline-border);
}

.category > div:first-child {
  font-weight: var(--font-weight-medium);
}`
