import { PersonalizationItem } from '../utils/Personalization.context'
import { processPersonalizationFields } from '../utils/Personalization.utils'
import { usePersonalizationRequests } from '../utils/usePersonalizationRequests.graphQL'

export const useFetchPersonalizations = (isAutomatedPrograms = false, hideAccountPersonalizations = false) => {
  const { getPersonalizationsRequest, getListFieldsRequest, searchListByIds } = usePersonalizationRequests()

  const fetchPersonalizations = async (selectedListIds?: string): Promise<PersonalizationItem[] | undefined> => {
    const personalizationData = await getPersonalizationsRequest()
    if (personalizationData) {
      if (selectedListIds) {
        const getListsFields = await getListFieldsRequest(selectedListIds)
        const searchByIdsField = await searchListByIds(selectedListIds)

        const allData = getListsFields
          ? processPersonalizationFields({
              fields: personalizationData,
              isAutomatedPrograms,
              selectedListFields: getListsFields,
              searchByIdsField,
              hideAccountPersonalizations,
            })
          : processPersonalizationFields({ fields: personalizationData, isAutomatedPrograms, hideAccountPersonalizations })

        return Object.values(allData).flat()
      } else {
        const allData = processPersonalizationFields({ fields: personalizationData, isAutomatedPrograms, hideAccountPersonalizations })
        return Object.values(allData).flat()
      }
    }
  }
  return fetchPersonalizations
}
