import { TableV2RowData } from '@components/TableV2/tableV2TS/interfaces'
import { RecordErrorsByOriginQuery } from '@graphql/types/microservice/crm-sync-types'
import { SortingState } from '@tanstack/react-table'

export interface CrmPushErrorsModalState {
  crmPushErrors: CrmPushError[]
  loading: boolean
  showStatusToast: boolean
}

export const crmPushErrorsModalInitialState: CrmPushErrorsModalState = {
  crmPushErrors: [],
  loading: true,
  showStatusToast: false,
}

export interface CrmPushError extends TableV2RowData<CrmPushError> {
  timestamp: number
  email: string
  description: string
}

export const defaultSortingBy: SortingState = [
  {
    id: 'timestamp',
    desc: true,
  },
]

export const getPushErrorsFromResponse = (response?: RecordErrorsByOriginQuery): CrmPushError[] => {
  return (
    response?.recordErrorsByOrigin?.map((error) => {
      return {
        email: error?.email ?? '',
        description: error?.error ?? '',
        timestamp: error?.lastErrorTime ?? 0,
      }
    }) ?? []
  )
}
