import React, { FC } from 'react'

import AssetPickerSidebar from '@complex/AssetPickerModal/Components/Sidebar/AssetPickerSidebar'
import Checkbox from '@components/Checkbox'
import { CollapsibleMenuItemWithHeaderData } from '@components/CollapsibleMenu/CollapsibleMenu'
import FilterContainer from '@components/FilterContainer/FilterContainer'
import Menu from '@components/Menu/Menu'
import { SvgNames } from '@components/Svg'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { caseInsensitiveAlphabeticSort } from '@utils/array'
import { useTranslation } from '@utils/const/globals'
import { allCatalogTemplatesFilter, recentlyViewedFilter } from '@utils/filter'

import { templateCatalogFilters, TemplateCatalogSubType, useTemplateCatalogModalContentContext } from './TemplateCatalogModal.context'

const rootClass = 'template-catalog-modal'

export const TemplateCatalogModalSidebarContainer: FC = () => {
  const { t } = useTranslation()

  const { filterCounts, activeSubTypes, allCategories, activeFilter, onFilterChange, onSubTypeChange } = useTemplateCatalogModalContentContext()

  const getDefaultFilters = (): CollapsibleMenuItemWithHeaderData => {
    return {
      header: '',
      content: (
        <FilterContainer
          filters={[allCatalogTemplatesFilter, recentlyViewedFilter]}
          activeFilter={templateCatalogFilters.find((filter) => filter.name === activeFilter)}
          filterCounts={filterCounts}
          filterAction={(filter) => onFilterChange(filter.name)}
          hasRecent={true}
          hasRecommended={false}
          hasCreatedByMe={false}
          hasFavorites={false}
          hideCount={false}
          className={`${rootClass}__sidebar__menu-filter-container`}
        />
      ),
      icon: SvgNames.segmentFilters,
      dataTest: 'menu-items-filters',
    }
  }

  const onCategoryClick = (category: TemplateCatalogSubType) => {
    onSubTypeChange(category)
  }

  const getCategories = (): CollapsibleMenuItemWithHeaderData[] => {
    return allCategories.map((filter, index) => {
      const matchedIndex = allCategories.findIndex((category) =>
        category.categories?.some((category) => activeSubTypes.some((subType) => subType.id === category.id))
      )

      return {
        header: t('Template.Catalog.Modal.FilterGroup', {
          count: filter.categories?.length,
          context: filter.parent,
        }),

        icon: SvgNames.toggles,
        isOpen: activeSubTypes.length ? index === matchedIndex : index === 0,
        content: filter.categories
          ?.sort((a, b) => caseInsensitiveAlphabeticSort(a.name ?? '', b.name ?? ''))
          .map((category) => {
            const singleCategory: TemplateCatalogSubType = { id: category.id ?? '', name: category.name ?? '', parent: category.parent }
            return (
              <div key={category.id} className={`${rootClass}__sidebar__menu-filter-item`}>
                <Checkbox
                  key={category.id}
                  label={<TextWithTooltipOnEllip typographyProps={{ text: category.name }} />}
                  checked={activeSubTypes.some((subType) => subType.id === singleCategory.id)}
                  dataTest={`${rootClass}-${category.name!}-filter`}
                  className={`${rootClass}__sidebar__menu-filter-item-name`}
                  onChange={() => onCategoryClick(singleCategory)}
                />
                <Typography text={category.count} type={TextType.BODY_TEXT_SMALL_LIGHT} lineHeight={LineHeight.MEDIUM} weight={TextWeight.MEDIUM} />
              </div>
            )
          }),
      }
    })
  }

  const items = [getDefaultFilters(), ...getCategories()]

  return (
    <AssetPickerSidebar>
      <Menu items={items} className={`${rootClass}__sidebar__menu`} />
    </AssetPickerSidebar>
  )
}
