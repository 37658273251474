import { SvgNames } from '@components/Svg'
import { FilterDefinition } from '@utils/filter'

export const allWebPagesFilter: FilterDefinition = {
  name: 'All web pages',
  svgSelected: SvgNames.segmentFilters,
  svgUnselected: SvgNames.segmentFiltersUnselected,
}

export const webPageDomainsFilter: FilterDefinition = {
  name: 'Domain filters',
  svgSelected: SvgNames.world,
  svgUnselected: SvgNames.world,
}
