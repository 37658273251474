import React from 'react'

import classNames from 'classnames'

import { FilterCounts, FilterInfoHoverText } from '@complex/ListingPage/Context/ListingPageCommon.context'
import ActionableNestedTableWithEmptyListing, {
  ActionableNestedTableWithEmptyListingProps,
} from '@components/ActionableNestedTableWithEmptyListing/ActionableNestedTableWithEmptyListing'
import { EmptyListingProps } from '@components/EmptyListing/EmptyListing'
import FolderSearch, { FolderSearchProps } from '@components/FolderSearch/FolderSearch'
import FolderTableLabel, { FolderTableLabelProps } from '@components/FolderTableLabel/FolderTableLabel'
import Search, { SearchType } from '@components/Search/Search'
import { MultipleTables } from '@components/TableV2/components/MultipleTables/MultipleTables'
import { TableV2Props } from '@components/TableV2/tableV2TS/interfaces'
import { checkUrlForTableV2 } from '@components/TableV2/utils/tableV2Utils'
import { TextAlign, TextType } from '@components/Typography/Typography'
import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from '@utils/const/globals'
import { recentFilter } from '@utils/filter'

import './ListPageTable.css'

interface Props<T> {
  className?: string
  dataTest?: string
  loading: boolean
  itemType?: string
  clearOnChange?: any[]
  filterInfoHoverText?: FilterInfoHoverText
  filterCounts?: FilterCounts
  enableTableV2?: boolean
  onChangeHandler: (value: string) => void
  hasToExpandV2?: TableV2Props<T>['hasToExpand']
  onRowExpandedV2?: TableV2Props<T>['onRowExpanded']
  rowDisabled?: TableV2Props<T>['rowDisabled']
  isRowSelectionDisabled?: TableV2Props<T>['isRowSelectionDisabled']
  searchPlaceholder?: string
}

export type ListPageTableProps<T = {}> = Props<T> &
  Omit<FolderTableLabelProps, 'allItemsFilter'> &
  ActionableNestedTableWithEmptyListingProps &
  EmptyListingProps &
  FolderSearchProps<T>

const rootClass = 'list-page-table'

const ListPageTable = <T extends {}>(props: ListPageTableProps<T>) => {
  const {
    dataTest = rootClass,
    className = '',
    folderPath,
    search,
    searchResults,
    tags,
    defaultTagsNumber,
    forceResetSelectedRows,
    resetSelectedRowsOnChange,
    onColumnSort,
    enableSort,
    manualSortBy,
    initialState,
    onFolderClick,
    onChangeHandler,
    clearOnChange,
    loading,
    itemType,
    headerActions,
    data,
    columns,
    onRowClicked,
    onRowSelectionChanged,
    rowActions,
    onRowsSort,
    hasExpander,
    canDrop,
    onApplyAndRemoveTags,
    hasDragDrop,
    onCreateTag,
    tagAction,
    onLoading,
    allLoaded,
    useCheckboxes,
    onRowExpanded,
    onRowExpandedV2,
    hasToExpand,
    hasToExpandV2,
    reference,
    onRowSelect,
    onSearch,
    currentTag,
    currentFolder,
    filterInfoHoverText,
    currentFilter,
    searchAllFilter,
    searchAll,
    onAllItemsFilterClick,
    filterCounts,
    scrollableElement,
    enableTableV2,
    rowDisabled,
    isRowSelectionDisabled,
    onRemoveSubType,
    subTypes,
    searchPlaceholder,
    ...EmptyListingProps
  } = props

  const ActionableNestedTableWithEmptyListingProps: ActionableNestedTableWithEmptyListingProps = {
    tags,
    data,
    loading,
    columns,
    allLoaded,
    reference,
    rowActions,
    hasDragDrop,
    hasExpander,
    headerActions,
    useCheckboxes,
    hideIcon: false,
    fixedHeader: true,
    checkboxWidth: 12,
    useHeaderCheckbox: true,
    textAlign: TextAlign.LEFT,
    selectedTagId: currentTag?.id,
    className: `${rootClass}__table`,
    textType: TextType.BODY_TEXT_LIGHT,
    canDrop,
    forceResetSelectedRows,
    resetSelectedRowsOnChange,
    enableSort,
    initialState,
    tagAction,
    onLoading,
    onRowsSort,
    onColumnSort,
    onCreateTag,
    hasToExpand,
    onRowExpanded,
    onRowClicked,
    onApplyAndRemoveTags,
    onRowSelectionChanged,
  }

  const { t } = useTranslation()

  const enableSorting = !search && enableSort && currentFilter !== recentFilter

  const tableV2Props: TableV2Props<T> = {
    data,
    loading,
    allLoaded,
    forceResetSelectedRows,
    enableLazyLoading: !search,
    enableStickyHeader: true,
    enableCalculatedRow: false,
    isDraggable: !search && hasDragDrop,
    scrollableElementRef: scrollableElement,
    enableSorting,
    stickyReference: reference,
    stickyHeaderTopPosition: 62,
    styles: { minWidth: 'auto' },
    enableCheckbox: useCheckboxes,
    emptyState: EmptyListingProps,
    enableSubRow: !search && (hasExpander || !!hasToExpand || !!hasToExpandV2),
    sortingBy: enableSorting && initialState?.sortBy,
    manualSorting: !search && (manualSortBy || !!onColumnSort),
    columns: columns as unknown as ColumnDef<T>[],
    canDrop: canDrop as TableV2Props<T>['canDrop'],
    rowActions: rowActions as TableV2Props<T>['rowActions'],
    headerActions: headerActions as TableV2Props<T>['headerActions'],
    tagProps: {
      tags,
      selectedTagId: currentTag?.id,
      defaultTagsNumber,
      tagAction,
      onCreateTag,
      onApplyAndRemoveTags,
    },
    resetSelectedRowsOnChange: resetSelectedRowsOnChange as TableV2Props<T>['resetSelectedRowsOnChange'],
    onLoading,
    hasToExpand: hasToExpandV2,
    onRowExpanded: onRowExpandedV2,
    rowDisabled,
    onRowClicked,
    onSort: onColumnSort,
    isRowSelectionDisabled,
    onRowsSort: onRowsSort as TableV2Props<T>['onRowsSort'],
    onRowSectionSelectionChanged: onRowSelectionChanged as TableV2Props<T>['onRowSectionSelectionChanged'],
    enableInsideLoader: enableTableV2 || checkUrlForTableV2(),
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__header`}>
        <FolderTableLabel
          className={`${rootClass}__header-folder-path`}
          folderPath={folderPath}
          search={search}
          searchResults={searchResults}
          currentFilter={currentFilter}
          subTypes={subTypes}
          onRemoveSubType={onRemoveSubType}
          onFolderClick={onFolderClick}
          currentTag={currentTag}
          currentFolder={currentFolder}
          filterInfoHoverText={filterInfoHoverText}
          filterCounts={filterCounts}
          allItemsFilter={searchAllFilter}
          onAllItemsFilterClick={onAllItemsFilterClick}
        />
        <Search
          className={`${rootClass}__header-search`}
          incomingValue={search}
          onChangeHandler={onChangeHandler}
          placeholder={searchPlaceholder || t('Search')}
          searchType={SearchType.LARGE}
          clearOnChange={clearOnChange}
          canClear
        />
      </div>
      {search ? (
        <FolderSearch
          className={`${rootClass}__table`}
          headerActions={headerActions}
          rowActions={rowActions}
          itemType={itemType}
          onRowSelect={onRowSelect}
          onRowClicked={onRowClicked}
          search={search}
          data={data}
          loading={loading}
          onSearch={onSearch}
          columns={columns}
          resetSelectedRowsOnChange={resetSelectedRowsOnChange}
          hasExpander={hasExpander}
          currentTag={currentTag}
          currentFolder={currentFolder}
          currentFilter={currentFilter}
          searchAllFilter={searchAllFilter}
          searchAll={searchAll}
          reference={reference}
          tableV2Props={tableV2Props}
          enableTableV2={enableTableV2}
          {...EmptyListingProps}
        />
      ) : (
        <MultipleTables
          tableV2Props={tableV2Props}
          oldTable={<ActionableNestedTableWithEmptyListing {...ActionableNestedTableWithEmptyListingProps} {...EmptyListingProps} />}
          enableTableV2={enableTableV2}
        />
      )}
    </div>
  )
}

export default ListPageTable
