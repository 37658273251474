import { useApolloClient } from '@apollo/client'
import isWebinarCompleted from '@graphql/queries/isWebinarCompleted'
import { WebinarType } from '@graphql/types/microservice/entity-join-types'
import { IsWebinarCompletedQuery, IsWebinarCompletedQueryVariables } from '@graphql/types/query-types'

interface GetSegmentNameWithInfoHoverQueriesResponse {
  getWebinarCompletedRequest: (sessionKey: string, webinarType: WebinarType) => Promise<boolean>
}

export const useSegmentNameWithInfoHoverQueries = (): GetSegmentNameWithInfoHoverQueriesResponse => {
  const actonClient = useApolloClient()
  const getWebinarCompletedRequest = (sessionKey: string, webinarType: WebinarType) =>
    actonClient
      .query<IsWebinarCompletedQuery, IsWebinarCompletedQueryVariables>({
        query: isWebinarCompleted,
        fetchPolicy: 'network-only',
        variables: {
          sessionKey,
          webinarType,
        },
      })
      .then(({ data }) => data.isWebinarCompleted as boolean)

  return {
    getWebinarCompletedRequest,
  }
}
