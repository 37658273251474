import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Button, { ButtonType } from '@components/Button'
import { ModalBody } from '@components/Modal'
import { ScopedModal } from '@components/ScopedModal/ScopedModal'
import Svg, { SvgColor, SvgNames } from '@components/Svg'
import Typography from '@components/Typography/Typography'

import './RefreshPreviewModal.css'

interface RefreshPreviewModal {
  onRefresh: () => void
}

const rootClass = 'refresh-preview-modal'

export const RefreshPreviewModal: FC<RefreshPreviewModal> = ({ onRefresh }) => {
  const { t } = useTranslation()

  return (
    <ScopedModal isOpen className={rootClass}>
      <ModalBody>
        <Typography text={t('LandingPageComposer.CustomCode.Refresh.Modal.Text')} className={`${rootClass}__text`} />
        <Button buttonType={ButtonType.PRIMARY} className={`${rootClass}__button`} onClick={onRefresh}>
          <Svg name={SvgNames.reload} fill={SvgColor.WHITE} />
          {t('LandingPageComposer.CustomCode.Refresh.Modal.Button')}
        </Button>
      </ModalBody>
    </ScopedModal>
  )
}
