import React, { FC, ReactNode, useContext, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import classNames from 'classnames'

import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2Props, SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { SvgNames } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { InputDefinition } from '@graphql/types/microservice/webhooks-incoming-management-types'
import IncomingWebhookAuthenticationDisabledSection from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/components/IncomingWebhookAuthentication/components/IncomingWebhookAuthenticationDisabledSection/IncomingWebhookAuthenticationDisabledSection'
import IncomingWebhookAuthenticationToggleSection from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/components/IncomingWebhookAuthentication/components/IncomingWebhookAuthenticationToggleSection/IncomingWebhookAuthenticationToggleSection'
import IncomingWebhookBasicAuthentication from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/components/IncomingWebhookAuthentication/components/IncomingWebhookBasicAuthentication/IncomingWebhookBasicAuthentication'
import IncomingWebhookHMACAuthentication from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/components/IncomingWebhookAuthentication/components/IncomingWebhookHMACAuthentication/IncomingWebhookHMACAuthentication'
import IncomingWebhookTokenAuthentication from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/components/IncomingWebhookAuthentication/components/IncomingWebhookTokenAuthentication/IncomingWebhookTokenAuthentication'
import {
  AuthenticationType,
  IncomingWebhooksContext,
  InputAuthentication,
  Source,
} from '@src/pages/Webhooks/IncomingWebhooks/IncomingWebhooksContext'

import './IncomingWebhookAuthentication.css'

interface IncomingWebhookAuthenticationProps {
  className?: string
  dataTest?: string
  onChange?: (authentication: InputAuthentication) => void
  authentication?: InputAuthentication
}

export const webhookAuthenticationRootClass = 'incoming-webhook-authentication'
const rootTranslation = 'Incoming.Webhooks.CreateModal.Authentication'

type AuthenticationContentType = Exclude<AuthenticationType, AuthenticationType.None>

const IncomingWebhookAuthentication: FC<IncomingWebhookAuthenticationProps> = (props: IncomingWebhookAuthenticationProps) => {
  const { dataTest = webhookAuthenticationRootClass, className = '', authentication } = props
  const {
    values: { enableAuthentication = false },
  } = useContext(IncomingWebhooksContext)

  const {
    formState: { errors },
    getValues,
    register,
    setValue,
  } = useFormContext<InputDefinition>()

  const { type = AuthenticationType.Basic } = { ...authentication }

  const source = getValues('source')

  const { t } = useTranslation()

  useEffect(() => {
    register('authentication.type')
  }, [])

  useEffect(() => {
    if (source === Source.Calendly && enableAuthentication) {
      setValue('authentication.type', AuthenticationType.Signature)
    }
  }, [source])

  useEffect(() => {
    if (enableAuthentication && type === AuthenticationType.None) {
      setValue('authentication.type', source === Source.Calendly ? AuthenticationType.Signature : AuthenticationType.Basic)
    }
  }, [enableAuthentication])

  const onSelectChange = (newType: AuthenticationType) => setValue('authentication.type', newType, { shouldValidate: true, shouldDirty: true })

  const options: SelectV2SingleOption[] = [
    {
      value: AuthenticationType.Basic,
      label: t('Basic'),
    },
    {
      value: AuthenticationType.Bearer,
      label: t('Token'),
    },
    {
      value: AuthenticationType.Signature,
      label: t('Digital Signature (HMAC)'),
    },
  ]

  const contentByType: { [key in AuthenticationContentType]: () => ReactNode } = {
    [AuthenticationType.Basic]: () => <IncomingWebhookBasicAuthentication dataTest={`${dataTest}-basic`} />,
    [AuthenticationType.Bearer]: () => <IncomingWebhookTokenAuthentication dataTest={`${dataTest}-token`} token={authentication?.token} />,
    [AuthenticationType.Signature]: () => <IncomingWebhookHMACAuthentication dataTest={`${dataTest}-hmac`} />,
  }

  const currentOption = options?.find(({ value }) => value === type)

  const selectProps: SelectV2Props = {
    className: `${webhookAuthenticationRootClass}__type-select`,
    defaultValue: currentOption,
    error: !!errors?.authentication?.type,
    inputIcon: SvgNames.shieldCheckSolid,
    insideModal: true,
    isClearable: false,
    isSearchable: false,
    onChange: (option?: SelectV2SingleOption) => onSelectChange(option?.value as AuthenticationType),
    options,
  }

  return (
    <div className={classNames(webhookAuthenticationRootClass, className)} data-test={dataTest}>
      <IncomingWebhookAuthenticationToggleSection />
      {enableAuthentication ? (
        <>
          {source === Source.Custom && (
            <div className={`${webhookAuthenticationRootClass}__type`}>
              <Typography
                text={t(`${rootTranslation}.Select.Label`)}
                tagProps={{ bold: { weight: TextWeight.MEDIUM, type: TextType.BODY_TEXT } }}
                type={TextType.BODY_TEXT_LIGHT}
                inline
              />
              <SelectV2 key={type} {...selectProps} />
            </div>
          )}
          {type !== AuthenticationType.None && contentByType[type as AuthenticationContentType]()}
        </>
      ) : (
        <IncomingWebhookAuthenticationDisabledSection />
      )}
    </div>
  )
}

export default IncomingWebhookAuthentication
