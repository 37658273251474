import { gql } from '@apollo/client'

export default gql`
  query crmIntersectionSchema($entity: String!) {
    crmIntersectionSchema(entity: $entity) {
      key
      fieldDefinition {
        fieldName
        displayName
        dataType
        referenceEntity
        pickListValues
        labelLookups {
          key
          value
        }
        lengthLimit
        defaultValue
        defaultedOnCreate
        required
        creatable
        updateable
        hidden
        alwaysShown
        picklistDependentOn
        pickListEntity
        pickListField
      }
    }
  }
`
