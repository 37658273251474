import React, { FC, useCallback, useState } from 'react'

import classNames from 'classnames'

import Container from '@components/Container/Container'
import SectionHeadline, { SectionHeadlineType } from '@components/SectionHeadline/SectionHeadline'
import Tab from '@components/TabsAO/TabAO'
import Tabs, { TabStyle } from '@components/TabsAO/TabsAO'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import EmbedCodes from '@src/pages/Content/Forms/FormManager/components/EmbedCodes/EmbedCodes'
import LandingPages from '@src/pages/Content/Forms/FormManager/components/LandingPages/LandingPages'

import './LandingPageEmbedCodesContainer.css'

interface LandingPageEmbedCodesContainerProps {
  className?: string
  dataTest?: string
}

export enum LandingPageEmbedCodesTabs {
  LANDING_PAGES = 'landingPages',
  EMBED_CODES = 'embedCodes',
}

const TAB_OPTIONS = [
  {
    index: LandingPageEmbedCodesTabs.LANDING_PAGES,
    label: 'Landing Pages',
  },
  {
    index: LandingPageEmbedCodesTabs.EMBED_CODES,
    label: 'Embed Codes',
  },
]

const rootClass = 'landing-page-embed-codes-container'

const LandingPageEmbedCodesContainer: FC<LandingPageEmbedCodesContainerProps> = (props: LandingPageEmbedCodesContainerProps) => {
  const { dataTest = rootClass, className = '' } = props
  const [tab, setTab] = useState<LandingPageEmbedCodesTabs>(LandingPageEmbedCodesTabs.LANDING_PAGES)
  const { t } = useTranslation()

  const handleTabChange = useCallback((index: string) => setTab(index as LandingPageEmbedCodesTabs), [])

  return (
    <Container className={classNames(rootClass, className)} data-test={dataTest}>
      <SectionHeadline type={SectionHeadlineType.SMALL}>{t('Form Locations')}</SectionHeadline>
      <Typography
        text={t('This form is embedded on the following Act-On landing pages and on external websites with the listed embed codes.')}
        type={TextType.BODY_TEXT_LIGHT}
        className={`${rootClass}__info`}
      />
      <Tabs defaultValue={tab} tabStyle={TabStyle.CARD} childData={TAB_OPTIONS} onChange={handleTabChange} className={`${rootClass}__tabs`}>
        <Tab index={LandingPageEmbedCodesTabs.LANDING_PAGES}>
          <LandingPages />
        </Tab>
        <Tab index={LandingPageEmbedCodesTabs.EMBED_CODES}>
          <EmbedCodes />
        </Tab>
      </Tabs>
    </Container>
  )
}

export default LandingPageEmbedCodesContainer
