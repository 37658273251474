import { gql } from '@apollo/client'

export default gql`
  mutation assignAccounts($managerId: String!, $assignAccountIdList: [String]) {
    assignAccounts(managerId: $managerId, assignAccountIdList: $assignAccountIdList) {
      successfullyAssignedIdList
      errorAssigningIdList
    }
  }
`
