import React, { Dispatch, SetStateAction } from 'react'
import { Cell } from 'react-table'

import { SwitchOptions } from '@components/Switch/Switch'
import { TableColumn } from '@components/Table/Table'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import Typography from '@components/Typography/Typography'
import { List } from '@interface/foldersLists/List'
import { MAX_SPLITS, MIN_SPLITS } from '@src/pages/ContactSegments/components/ABSplit/ABSplit.constants'
import {
  ABSplitContainerInitialState,
  ABSplitContainerState,
  Split,
  SuppressedSource,
} from '@src/pages/ContactSegments/components/ABSplit/ABSplitContext'

export const getSplitSuffix = (splitNumber: number): string => {
  const quotient = Math.floor(splitNumber / 26)
  const letter = String.fromCharCode('A'.charCodeAt(0) + (splitNumber % 26))
  return (quotient === 0 ? '' : getSplitSuffix(quotient - 1)) + letter
}

export const getTotals = (splits: Split[]) =>
  splits.reduce(
    ({ fixedTotalAmount, fixedSplitsNumber, total }, split: Split) => {
      const splitValid = !split.autoUpdate && split.isValid
      return {
        fixedTotalAmount: fixedTotalAmount + (splitValid ? Number(split.value) : 0),
        fixedSplitsNumber: fixedSplitsNumber + (splitValid ? 1 : 0),
        total: total + (split.isValid ? Number(split.value) : 0),
      }
    },
    { fixedTotalAmount: 0, fixedSplitsNumber: 0, total: 0 }
  )

export const getSplitInputError = (
  inputValue: string,
  selectedDistribution: SwitchOptions,
  availableContacts: number,
  splits: Split[],
  index: number,
  t: Function
) => {
  const { fixedTotalAmount } = getTotals(splits)
  const isPercentage = selectedDistribution === SwitchOptions.PERCENT
  const hardLine = isPercentage ? 100 : availableContacts
  const maxSplit = hardLine - fixedTotalAmount + (!splits[index].autoUpdate && splits[index].isValid ? Number(splits[index].value) : 0)
  if (inputValue === '') {
    return t('Split cannot be empty')
  }
  const numberValue = Number(inputValue)
  if ((!maxSplit && !numberValue && !splits[index].autoUpdate) || (!numberValue && !splits[index].isValid && splits[index].value !== '0')) {
    return false
  }
  if (
    isNaN(numberValue) ||
    numberValue < 0 ||
    numberValue >= hardLine ||
    !maxSplit ||
    (maxSplit && numberValue > maxSplit) ||
    !(isPercentage || Number.isInteger(numberValue))
  ) {
    return t('Invalid input')
  }
}

export const getSplitsNumeralInitialState = (availableContacts: number) => [
  { name: 'A', value: Math.floor(availableContacts / ABSplitContainerInitialState.numberOfSplits), autoUpdate: true, isValid: true },
  {
    name: 'B',
    value: Math.floor(availableContacts / ABSplitContainerInitialState.numberOfSplits) + (availableContacts % 2),
    autoUpdate: true,
    isValid: true,
  },
]

export const isNumberOfSplitsValid = (numberOfSplits: number) => numberOfSplits >= MIN_SPLITS && numberOfSplits <= MAX_SPLITS

export const removeABSplitSource = (source: List, setState: Dispatch<SetStateAction<ABSplitContainerState>>) => {
  setState(({ sources, ...state }) => ({
    ...state,
    sources: sources.filter(({ id }) => id != source.id),
  }))
}

export const getSelectABSplitSourcesColumns = (): TableColumn[] => [
  {
    Header: 'Source',
    accessor: 'name',
    align: 'left',
    flexColumn: true,
    Cell: ({ row: { original } }: Cell<List>) => <Typography title={original.name} text={original.name} />,
  },
  {
    Header: 'Suppressed',
    accessor: 'suppressed',
    align: 'right',
    maxWidth: 100,
    className: 'ab-split__suppressed',
  },
]

export const columnsV2: ColumnDefWithAdditionalProps<SuppressedSource>[] = [
  {
    header: 'Source',
    accessorKey: 'name',
    textAlign: 'left',
    cell: ({ row: { original } }) => <Typography title={original.name} text={original.name} />,
  },
  {
    header: 'Suppressed',
    accessorKey: 'suppressed',
    textAlign: 'right',
    maxSize: 200,
  },
]
