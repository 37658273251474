import React, { FC, useState, useEffect, useContext } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import CodeBlockV2, { CodeBlockBackgroundColor, CodeBlockInputType } from '@components/CodeBlockV2/CodeBlockV2'
import InputWithStatus from '@components/InputWithStatus/InputWithStatus'
import Label from '@components/Label'
import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import Modal, { ModalBody, ModalHeader, ModalFooter } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import TextArea from '@components/TextArea/TextArea'
import { useTranslation } from '@const/globals'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { useEmailComposerRequests } from '@utils/composer/emailComposer/GraphQL/EmailComposerRequests.graphQL'
import { checkEmailValidity } from '@utils/formUtils'

import { apiDetailsJson } from './SendApiDetailsModal.utils'

import './SendApiDetailsModal.css'

interface SendApiDetailsModalProps {
  className?: string
  dataTest?: string
  id?: string
  onClose: () => void
  onSend: (email: string, details: string, note: string) => Promise<void>
}

const rootClass = 'send-api-details-modal'

const SendApiDetailsModal: FC<SendApiDetailsModalProps> = ({ dataTest = rootClass, className = '', onSend, onClose, id }) => {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [note, setNote] = useState('')
  const [notValidated, setNotValidated] = useState(true)
  const [eteSendDetailsJson, setEteSendDetailsJson] = useState<string | null>(null)
  const { t } = useTranslation()
  const {
    api: { update },
  } = useContext(EmailComposerContext)

  const { getEteDetailsRequest } = useEmailComposerRequests()

  useEffect(() => {
    const fetchData = async () => {
      const templateIdObject = { templateId: id }
      const response = await getEteDetailsRequest(templateIdObject)

      if (response?.data?.getETEEmailDetails) {
        setEteSendDetailsJson(response.data.getETEEmailDetails)
        update({
          message: {
            apiDetailsPayload: response.data.getETEEmailDetails,
          },
        })
      }
    }

    fetchData()
  }, [id, update])

  const handleSend = async () => {
    if (!checkEmailValidity(email)?.errorMessageKey) {
      setLoading(true)
      await onSend(email, eteSendDetailsJson ?? apiDetailsJson, note)
      setLoading(false)
    }
  }

  const checkValidity = (email: string) => {
    const result = checkEmailValidity(email)
    setNotValidated(!!result?.errorMessageKey)
    return result
  }

  const errorMessages = {
    emailInvalid: t('Invalid email address'),
    required: t('Email address is missing'),
  }

  return (
    <Modal
      className={classNames(rootClass, className)}
      data-test={dataTest}
      isOpen={true}
      header={
        <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__header`}>
          {t('EmailComposer.Settings.EventTriggered.API.Title')}
        </ModalHeader>
      }
    >
      <ModalBody className={`${rootClass}__body`}>
        <div>
          <Label required>{t('EmailComposer.Settings.EventTriggered.API.Email.Label')}</Label>
          <InputWithStatus
            validityFunctions={[checkValidity]}
            tooltipErrorMessages={errorMessages}
            placeholder={t('EmailComposer.Settings.EventTriggered.API.Email.Placeholder')}
            required
            className={`${rootClass}__email-input`}
            dataTest={`${dataTest}-email-input`}
            name="email"
            disabled={loading}
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>
        <div>
          <TextArea
            label={'Note'}
            name="note"
            placeholder={t('EmailComposer.Settings.EventTriggered.API.Note.Placeholder')}
            dataTest={`${dataTest}-note-input`}
            onChange={(event) => setNote(event.target.value)}
            disabled={loading}
            className={`${rootClass}__notes`}
          />
        </div>
        <CodeBlockV2
          content={JSON.stringify(JSON.parse(eteSendDetailsJson ?? apiDetailsJson), null, 2) ?? apiDetailsJson}
          language="json"
          inputType={CodeBlockInputType.FORMATTED_CODE}
          label={t('EmailComposer.Settings.EventTriggered.API.Code.Label')}
          isRequired={false}
          backgroundColor={CodeBlockBackgroundColor.WHITE}
        />
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form} className={`${rootClass}__footer`}>
        <Button buttonType={ButtonType.TERTIARY} onClick={onClose} dataTest={`${dataTest}-button-tertiary`} disabled={loading}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} onClick={handleSend} dataTest={`${dataTest}-button-primary`} disabled={loading || notValidated}>
          {loading ? <Loader loaderType={LoaderTypes.row} /> : t('Send')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default SendApiDetailsModal
