import { gql } from '@apollo/client'

export default gql`
  mutation createSegment($name: String!) {
    createSegment(name: $name) {
      id
      name
    }
  }
`
