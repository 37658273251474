import getActivityManualExport from '@graphql/microservices/segment/getActivityManualExport'
import getAllActivity from '@graphql/microservices/segment/getAllActivity'
import { GetActivityManualExportQuery, GetActivityManualExportQueryVariables, SegmentActivityDto } from '@graphql/types/microservice/segment-types'
import { ItemType } from '@utils/categorization'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'

export interface SegmentDetailsRequests {
  getActivitiesRequest: (id: string) => Promise<SegmentActivityDto[]>
}

const DEFAULT_PAGE_SIZE = 50
const DEFAULT_PAGE = 0

export const useSegmentDetailsRequests = (): SegmentDetailsRequests => {
  const { client: segmentServiceClient } = useMicroserviceClient({ serviceName: MicroserviceClients.SEGMENT })

  const getAllActivities = (segmentId: string): Promise<SegmentActivityDto[]> => {
    return segmentServiceClient
      .query({
        query: getAllActivity,
        fetchPolicy: 'network-only',
        variables: {
          segmentId,
          type: ItemType.SEGMENT,
          pageSize: DEFAULT_PAGE_SIZE,
          pageNumber: DEFAULT_PAGE,
        },
      })
      .then(({ data }) => [
        ...(data.getActivityAudit as SegmentActivityDto[]),
        ...(data.getActivityAutomatedPrograms as SegmentActivityDto[]),
        ...(data.getActivityMessages as SegmentActivityDto[]),
        ...(data.getActivitySMS as SegmentActivityDto[]),
        ...(data.getActivityImport as SegmentActivityDto[]),
        ...(data.getActivityListMaintenancePrograms as SegmentActivityDto[]),
        ...(data.getActivityFTP as SegmentActivityDto[]),
      ])
  }

  const getManualExportActivity = async (segmentId: string): Promise<SegmentActivityDto[]> => {
    return segmentServiceClient
      .query<GetActivityManualExportQuery, GetActivityManualExportQueryVariables>({
        query: getActivityManualExport,
        fetchPolicy: 'network-only',
        variables: {
          segmentId,
          pageSize: DEFAULT_PAGE_SIZE,
          pageNumber: DEFAULT_PAGE,
        },
      })
      .then(({ data }) => data.getActivityManualExport as SegmentActivityDto[])
  }

  const getActivitiesRequest = (id: string) =>
    Promise.all([getAllActivities(id), getManualExportActivity(id)]).then(([allActivitiesResponse, getManualExportActivityResponse]) => [
      ...allActivitiesResponse,
      ...getManualExportActivityResponse,
    ])

  return {
    getActivitiesRequest,
  }
}
