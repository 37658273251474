import { gql } from '@apollo/client'

export default gql`
  query getAssets($segmentIds: [String]) {
    getAssets(segmentIds: $segmentIds) {
      body
      message
      objectId
      statusCode
    }
  }
`
