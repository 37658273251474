import { useApolloClient } from '@apollo/client'
import getFormPreview from '@graphql/queries/getFormPreview'
import { GetFormPreviewQuery, GetFormPreviewQueryVariables } from '@graphql/types/query-types'
import { FetchPromise } from '@utils/types'

export interface FormsPickerRequests {
  getFormPreviewRequest: (variables: GetFormPreviewQueryVariables) => FetchPromise<GetFormPreviewQuery>
}

export const useFormsPickerRequests = (): FormsPickerRequests => {
  const actonClient = useApolloClient()

  const getFormPreviewRequest = async (variables: GetFormPreviewQueryVariables) =>
    await actonClient.query<GetFormPreviewQuery, GetFormPreviewQueryVariables>({
      query: getFormPreview,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables,
    })

  return {
    getFormPreviewRequest,
  }
}
