import { gql } from '@apollo/client'

export default gql`
  mutation createProgramMessages($createMessageInput: ProgramCreateMessageInput) {
    createProgramMessages(createMessageInput: $createMessageInput) {
      status
      succeedMsgIds {
        originalId
        newId
        isBeeComposer
      }
      errorMsgIds
    }
  }
`
