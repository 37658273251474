import { useState } from 'react'

const useLoading = (action: any) => {
  const [loading, setLoading] = useState(false)

  const doAction = (...args: any) => {
    setLoading(true)
    try {
      return action(...args)
    } finally {
      setLoading(false)
    }
  }

  return [doAction, loading] as const
}

export default useLoading
