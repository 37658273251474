import { createContext } from 'react'

import { CustomObject } from '@src/pages/listingPages/CustomDataJoinView/utils/CustomDataJoinViewListingPage.interfaces'
import { DeepUpdateState } from '@utils/types'

export interface CustomDataJoinViewListingPageState {
  selectedCustomObject?: CustomObject
}

export interface CustomDataJoinViewListingPageAPI<T> {
  values: T
  update: DeepUpdateState<T>
}

export const customDataJoinViewListingPageInitialState: CustomDataJoinViewListingPageState = {}

export const CustomDataJoinViewListingPageContext = createContext<CustomDataJoinViewListingPageAPI<CustomDataJoinViewListingPageState>>({
  values: customDataJoinViewListingPageInitialState,
} as any)
