import React, { FC, useCallback, useMemo, useState } from 'react'

import classNames from 'classnames'

import Container from '@components/Container'
import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import Search from '@components/Search/Search'
import SingleSelectDropdown from '@components/SingleSelectDropdown/SingleSelectDropdown'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { TableV2 } from '@components/TableV2/TableV2'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { CampaignSectionData } from '@graphql/types/microservice/data-lake-query-types'
import { SelectOption } from '@interface/Select'

import { CampaignTableFilters, CampaignTableRowData } from './CampaignTable.types'
import DownloadButton from './components/DownloadButton'
import getFilteredData, { allowAllOptionsKey, CAMPAIGN_STATUSES_OPTIONS } from './utils/campaignTable.filterData'
import getFormattedData from './utils/campaignTable.formattedData'
import getCampaignTableProps from './utils/campaignTable.tableProps'

import './CampaignTable.css'

export interface CampaignTableProps {
  loading: boolean
  data: CampaignSectionData
  onUpdateCRMFields?: () => void
  className?: string
  dataTest?: string
}

const emptyRows = Array(5).fill({})
const rootClass = 'campaign-table'

const CampaignTable: FC<CampaignTableProps> = (props: CampaignTableProps) => {
  const { loading, data, onUpdateCRMFields, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const statusOptions = useMemo<SelectOption[]>(
    () => [
      { value: allowAllOptionsKey, label: t('All status') },
      ...Array.from(CAMPAIGN_STATUSES_OPTIONS.entries()).map(([value, label]) => ({ value, label })),
    ],
    []
  )

  const formattedData = useMemo<CampaignTableRowData[]>(() => getFormattedData(data), [data])
  const noCampaignData = !loading && !formattedData.length

  const campaignOptions = useMemo<SelectOption[]>(() => {
    const campaigns = new Set<string>()
    let noNameCampaign: string | undefined
    formattedData.forEach(({ name, missingName }) => {
      name && campaigns.add(name)
      if (missingName) {
        noNameCampaign = name
      }
    })
    const options: SelectOption[] = [
      { value: allowAllOptionsKey, label: t('All campaign types') },
      ...Array.from(campaigns).map((campaign) => ({
        value: campaign,
        labelNode:
          campaign === noNameCampaign ? (
            <span>
              [<Typography text={campaign} weight={TextWeight.ITALIC} inline />]
            </span>
          ) : undefined,
      })),
    ]
    return options
  }, [formattedData])

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [filters, setFilters] = useState<CampaignTableFilters>({
    campaign: allowAllOptionsKey,
    status: allowAllOptionsKey,
  })

  const filteredData = getFilteredData(formattedData, filters, searchTerm)

  const resetAll = useCallback(() => {
    setFilters({ campaign: allowAllOptionsKey, status: allowAllOptionsKey })
    setSearchTerm('')
  }, [])

  const handleStatusSubmit = useCallback((status?: string) => setFilters((current) => ({ ...current, status })), [])
  const handleCampaignSubmit = useCallback(
    (campaign?: string) =>
      setFilters((current) => ({
        ...current,
        campaign,
      })),
    []
  )

  const tableProps = getCampaignTableProps(resetAll, searchTerm, loading, t)

  return (
    <Container className={classNames(rootClass, className, { [`${rootClass}__loading`]: loading })} dataTest={dataTest}>
      <div className={`${rootClass}__header`} data-test={`${dataTest}-header`}>
        <Typography text={t('Campaign Performance')} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
        <Typography text={t('Campaign.Performance.Sub.Header')} type={TextType.NORMAL_TEXT_GRAY_LARGE} className={`${rootClass}__header-subtitle`} />
        {!noCampaignData && (
          <div className={`${rootClass}__filters`}>
            <div className={`${rootClass}__filters-dropdowns`}>
              <SingleSelectDropdown
                options={campaignOptions}
                value={filters.campaign}
                onSubmit={handleCampaignSubmit}
                allowDeselect={false}
                dataTest={`${dataTest}-campaign-dropdown`}
              />
              <SingleSelectDropdown
                options={statusOptions}
                value={filters.status}
                onSubmit={handleStatusSubmit}
                allowDeselect={false}
                dataTest={`${dataTest}-status-dropdown`}
              />
            </div>
            <div className={`${rootClass}__filters-search`}>
              <DownloadButton data={filteredData} withTotal={!searchTerm} />
              <Search
                className={`${rootClass}__search`}
                dataTest={`${dataTest}-search`}
                incomingValue={searchTerm}
                onChangeHandler={setSearchTerm}
                placeholder={t('Search')}
                canClear
              />
            </div>
          </div>
        )}
      </div>
      {noCampaignData ? (
        <>
          <EmptyListing
            headline={t('Campaign.Table.NoData.HeadLine')}
            text={t('Campaign.Table.NoData.Text')}
            size={EmptyListingSize.MEDIUM}
            imgSrc={StaticImageNames.emptyChartMedium}
            buttonText={t('Campaign.Table.NoData.Button')}
            buttonOnClick={onUpdateCRMFields}
            dataTest={`${rootClass}-empty-listing`}
            withoutBorder
          />
        </>
      ) : (
        <TableV2 data={loading ? emptyRows : filteredData} {...tableProps} stickyColumns={['id']} />
      )}
    </Container>
  )
}

export default CampaignTable
