import React, { FC, useContext } from 'react'

import { ProgramRecordSetStep, ProgramSource } from '@graphql/types/query-types'
import { List } from '@interface/foldersLists/List'
import { SourceReplacement } from '@src/pages/programs/upgradeManager/components/SourceReplacement/SourceReplacement'
import { getUpgradedStepSources, useUpgradedSource } from '@src/pages/programs/upgradeManager/components/steps/Steps.utils'
import {
  UpgradedProgramRecordSetStep,
  UpgradedStepCondition,
  UpgradeManagerContext,
} from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'

interface ChangeFieldValueSourceUpgradeProps {
  className?: string
  dataTest?: string
  condition: UpgradedStepCondition
  source: ProgramSource
}

const ChangeFieldValueSourceUpgrade: FC<ChangeFieldValueSourceUpgradeProps> = (props: ChangeFieldValueSourceUpgradeProps) => {
  const { condition, source } = props

  const {
    values: { currentStep, recommendedSegments, upgradedCurrentStep, upgradedStepSources },
    update,
  } = useContext(UpgradeManagerContext)

  const step = currentStep as ProgramRecordSetStep
  const upgradedChangeFieldValueStep = upgradedCurrentStep as UpgradedProgramRecordSetStep

  const recommended = Object.entries(recommendedSegments).find(([key, _]) => key === step?.listId)

  const sourceToUpgrade = useUpgradedSource(source, !!recommended ? recommended[1] : undefined, `source-${condition.conditionId}`)

  const onSourceSubmit = (_: string, newSource: List, upgradeId: string) => {
    if (upgradedChangeFieldValueStep) {
      const conditions = upgradedChangeFieldValueStep.conditions.map((actualCondition) =>
        actualCondition.conditionId === condition.conditionId ? { ...actualCondition, srcId: newSource.id, upgraded: true } : actualCondition
      )
      const upgradedCurrentStep: UpgradedProgramRecordSetStep = { ...upgradedChangeFieldValueStep, conditions, upgraded: true }
      update({
        upgradedStepSources: getUpgradedStepSources(upgradedStepSources, upgradedChangeFieldValueStep, upgradeId, newSource),
        upgradedCurrentStep,
      })
    }
  }

  return <SourceReplacement sourceToUpgrade={sourceToUpgrade} onSubmitSource={onSourceSubmit} />
}

export default ChangeFieldValueSourceUpgrade
