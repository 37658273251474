import React, { ChangeEvent, FC, useCallback, useState } from 'react'

import classNames from 'classnames'

import { Checkbox } from '@components/Checkbox/Checkbox'
import RadioGroup from '@components/RadioGroup'
import RadioWithOptions from '@components/RadioWithOptions/RadioWithOptions'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import ImmediatelyOptions from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertSchedule/components/ImmediatelyOptions/ImmediatelyOptions'
import ScheduleOptions from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertSchedule/components/ScheduleOptions/ScheduleOptions'
import {
  AlertFrequency,
  SendAlertOn,
} from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertSchedule/utils/AlertSchedule.utils'

import './AlertSchedule.css'

interface AlertScheduleProps {
  className?: string
  dataTest?: string
}

export interface AlertScheduleState {
  frequency: AlertFrequency
  isScheduled?: boolean
  sendOn: SendAlertOn
}

const defaultSchedule = {
  frequency: AlertFrequency.DAILY,
  sendOn: SendAlertOn.EVERY_DAY,
}

const rootClass = 'alert-schedule'

const AlertSchedule: FC<AlertScheduleProps> = (props: AlertScheduleProps) => {
  const { dataTest = rootClass, className = '' } = props

  const [state, setState] = useState<AlertScheduleState>(defaultSchedule)
  const { frequency, isScheduled, sendOn } = state

  const { t } = useTranslation()

  const changeScheduleOption = useCallback(({ target: { id } }: ChangeEvent<HTMLInputElement>) => {
    setState((state) => ({ ...state, ...defaultSchedule, isScheduled: id === 'scheduled' }))
  }, [])

  const onChange = useCallback((values: Partial<AlertScheduleState>) => setState((state) => ({ ...state, ...values })), [state])

  const getScheduleDetails = () => {
    const sendOnKey = {
      [SendAlertOn.EVERY_DAY]: 'EveryDay',
      [SendAlertOn.WEEKDAYS]: 'Weekdays',
      [SendAlertOn.CUSTOM]: 'Custom',
    }

    const frequencyKey = {
      [AlertFrequency.DAILY]: `Daily.${sendOnKey[sendOn]}`,
      [AlertFrequency.WEEKLY]: 'Weekly',
    }

    return t(`AlertSchedule.ScheduleDetails.${isScheduled ? `Scheduled.${frequencyKey[frequency]}` : `Immediately.${sendOnKey[sendOn]}`}`, {
      from: '8:00 AM',
      to: '6:00 PM',
      hour: '3:00 AM',
      days: isScheduled && frequency === AlertFrequency.WEEKLY ? 'Monday' : 'Monday, Tuesdays and Fridays',
    })
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <RadioGroup verticalLayout>
        <RadioWithOptions
          dataTest={`${dataTest}__immediately-radio-button`}
          id={'immediately'}
          checked={isScheduled !== undefined && !isScheduled}
          onChange={changeScheduleOption}
          label={t('Immediately')}
        >
          <ImmediatelyOptions sendOn={sendOn} onChange={onChange} />
        </RadioWithOptions>
        <RadioWithOptions
          dataTest={`${dataTest}__scheduled-radio-button`}
          id={'scheduled'}
          checked={isScheduled}
          onChange={changeScheduleOption}
          label={t('On a schedule')}
        >
          <ScheduleOptions frequency={frequency} sendOn={sendOn} onChange={onChange} />
        </RadioWithOptions>
      </RadioGroup>
      {isScheduled && (
        <div className={`${rootClass}__checkbox-container`}>
          {frequency === AlertFrequency.WEEKLY && (
            <div className={`${rootClass}__checkbox-weekly-statistics`}>
              <Checkbox label={t('Include weekly visitor statistics')} />
              <Tooltip trigger={<Svg name={SvgNames.info} type={SvgType.LARGER_ICON} />}>
                {t('Weekly visitor statistics offer a quick summary view of how your website traffic is performing compared to the week before.')}
              </Tooltip>
            </div>
          )}
          <Checkbox label={t('Email this alert notification, even if there are no visitors matching the criteria')} />
        </div>
      )}
      {isScheduled !== undefined && (
        <div className={`${rootClass}__schedule-details`}>
          <Svg name={SvgNames.bellTime} type={SvgType.LARGER_ICON} />
          <Typography text={getScheduleDetails()} />
        </div>
      )}
    </div>
  )
}

export default AlertSchedule
