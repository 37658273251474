import React, { FC, useMemo } from 'react'

import EmptyListing, { EmptyListingProps } from '@components/EmptyListing/EmptyListing'

import TableWithLoader, { TableWithLoaderProps } from '../TableWithLoader/TableWithLoader'

type Props = TableWithLoaderProps & EmptyListingProps

const TableWithLoaderAndEmptyListing: FC<Props> = (props: Props) => {
  const { data, emptyText, loading, className } = props

  const tableProps: TableWithLoaderProps = { ...props, className }
  const emptyListingProps: EmptyListingProps = { ...props, className }

  const loadingg = useMemo(() => loading, [loading])

  return data.length > 0 || emptyText || loadingg ? (
    <TableWithLoader className={className} {...tableProps} />
  ) : (
    <EmptyListing className={className} {...emptyListingProps} />
  )
}

export default TableWithLoaderAndEmptyListing
