import i18n from 'i18next'
import Backend from 'i18next-http-backend'

import { CACHE_BUSTER, getPath } from '@const/globals'

i18n.use(Backend).init({
  backend: {
    loadPath: `${getPath()}/static/locales/{{lng}}.json?v=${CACHE_BUSTER}`,
    allowMultiLoading: false,
    reloadInterval: false,
  },
  lng: 'en',
  fallbackLng: 'en',
  debug: false,

  // have a common namespace used around the full App
  ns: ['translations'],
  defaultNS: 'translations',

  nsSeparator: false,
  keySeparator: false,

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },

  react: {
    useSuspense: false,
  },
})

export default i18n
