import React from 'react'

import { SelectOption } from '@components/MultiSelect/MultiSelect'
import { ColWithTooltip } from '@components/Table/components/tableColumns'
import { TableColumn } from '@components/Table/Table'
import { SortByState } from '@utils/sms.utils'

export type LinkPerformanceLinkLocationState = {
  tab: string
  fragmentTags: string[]
  fragmentFragments: string[]
  fragmentGroupBy: boolean
  fragmentSortDirection: Direction
  fragmentSortColumn: FragmentNameClickSortColumns
  fragmentPageSize: number
  fragmentPage: number
  tagTags: string[]
  tagSortDirection: Direction
  tagSortColumn: TagNameClickSortColumns
  tagPageSize: number
  tagPage: number
}

export type SharedTabProps = {
  tagOptions: SelectOption[]
  tagOnsubmit(selected: string[]): void
  totalCount: number
  pageSize: number | 50
  pageIndex: number | 0
  totalClicks: number | 0
  uniqueClicks: number | 0
  loading: boolean
  data: any[]
  fetchData: (pageIndex: number, pageSize: number) => void
  onDownload: () => void
  sortBy?: SortByState[]
  onSort?: (sortBy: Array<any>) => void
  className?: string
  dataTest?: string
  resetPageIndex?: boolean
}

export interface LinkPerformanceTabProps {
  fetchData: (pageIndex: number, pageSize: number) => void
  onSort: (sortBy: SortByState[]) => void
  tableData: any[]
  isLoading: boolean
  className?: string
  dataTest?: string
  tagOptions: SelectOption[]
  tagOnsubmit(selected: SelectOption[]): void
  downloadUrl: string
  linkTitle: string
  onCheck?: () => void
  totalCount: number
  pageSize: number | 50
  pageIndex: number | 0
  sortBy?: SortByState[]
}

export enum Direction {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum TagNameClickSortColumns {
  Tagname = 'TAGNAME',
  Totalclicks = 'TOTALCLICKS',
  Uniqueclicks = 'UNIQUECLICKS',
}

export enum FragmentNameClickSortColumns {
  Fragmentname = 'FRAGMENTNAME',
  Linkname = 'LINKNAME',
  Totalclicks = 'TOTALCLICKS',
  Uniqueclicks = 'UNIQUECLICKS',
}

const renderCell = (row: any) => <ColWithTooltip row={row} />
export const tagColumns = (totalClicks: number, uniqueClicks: number) => {
  return [
    {
      Header: 'TAGS',
      accessor: 'tagName',
      align: 'left',
      Cell: renderCell,
      minWidth: 200,
      flexColumn: true,
      Footer: 'All Tags',
    },
    {
      Header: 'TOTAL CLICKS',
      accessor: 'totalClicks',
      align: 'right',
      Cell: renderCell,
      minWidth: 150,
      maxWidth: 150,
      Footer: totalClicks,
    },
    {
      Header: 'UNIQUE CLICKS',
      accessor: 'uniqueClicks',
      align: 'right',
      Cell: renderCell,
      minWidth: 150,
      maxWidth: 150,
      Footer: uniqueClicks,
    },
  ] as TableColumn[]
}

export const fragColumns = (totalClicks: number, uniqueClicks: number) => {
  return [
    {
      Header: 'FRAGMENT',
      accessor: 'fragmentName',
      align: 'left',
      Cell: renderCell,
      minWidth: 200,
      flexColumn: true,
      Footer: 'All Fragments',
    },
    {
      Header: 'LINK',
      accessor: 'linkName',
      align: 'left',
      Cell: renderCell,
      minWidth: 150,
      maxWidth: 150,
      sortType: 'statusDateTime',
    },
    {
      Header: 'TOTAL CLICKS',
      accessor: 'totalClicks',
      align: 'right',
      Cell: renderCell,
      minWidth: 150,
      maxWidth: 150,
      Footer: totalClicks,
    },
    {
      Header: 'UNIQUE CLICKS',
      accessor: 'uniqueClicks',
      align: 'right',
      Cell: renderCell,
      minWidth: 150,
      maxWidth: 150,
      Footer: uniqueClicks,
    },
  ] as TableColumn[]
}
