import { gql } from '@apollo/client'

export default gql`
  mutation listingRunScheduledDripProgramNow($programId: String!, $skipPending: Boolean) {
    listingRunScheduledDripProgramNow(programId: $programId, skipPending: $skipPending) {
      status
      valid
      isRunning
      message
      reasons
    }
  }
`
