import React, { FC, useState } from 'react'

import classNames from 'classnames'

import { ModalHeader } from '@components/Modal'
import ModalBody from '@components/Modal'
import SyncErrors from '@src/pages/datamanagement/components/SyncErrors/SyncErrors'
import SyncResults from '@src/pages/datamanagement/components/SyncResults/SyncResults'

import './SyncResultModal.css'

type Props = {
  className?: string
  setShowResults?: Function
}

const rootClass = 'sync-result-modal'

const SyncResultModal: FC<Props> = (props: Props) => {
  const { className = '', setShowResults } = props
  const [openModal, setOpenModal] = useState<boolean>(true)
  const closeModal = () => {
    setOpenModal(false)
    if (setShowResults !== undefined) {
      setShowResults(false)
    }
  }

  return (
    <ModalBody className={classNames(rootClass, className)} isOpen={openModal} header={<ModalHeader closeButton={closeModal}>{}</ModalHeader>}>
      <div className={`${rootClass}__parent`}>
        <div className={`${rootClass}__float-child-left`}>
          <SyncResults />
        </div>
        <div className={`${rootClass}__float-child-right`}>
          <SyncErrors />
        </div>
      </div>
    </ModalBody>
  )
}

export default SyncResultModal
