import { createContext, ReactNode } from 'react'

import { AssetPickerInfoType } from '@complex/AssetPickerModal/Components/Info/AssetPickerInfoContainer'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { RowSelectionState } from '@tanstack/react-table'
import { UpdateState } from '@utils/types'

import {
  CustomSourceItems,
  ListingPageProps,
  ListPageCommonState,
  SidebarProps,
  TablePropsV2,
} from '../../ListingPage/Context/ListingPageCommon.context'
import { listPageContextCommonValues } from '../../ListingPage/Utils/ListingPage.constants'

export interface InfoBannerValuesType {
  text?: string | ReactNode
  title: string
  type: AssetPickerInfoType
  showBorderBottom?: boolean
  hideOnSearch?: boolean
}

export interface InfoBannerType {
  customInfo?: ReactNode
  values?: InfoBannerValuesType
}

export interface AssetPickerCommonState {
  listingPageState: ListPageCommonState
  isSingleSelect: boolean
  isViewingSelected: boolean
  /** If not all data is fetched upfront we will need to load pre-selected item metadata before the table initializes */
  loadingPreSelectedItems: boolean
  /** All the items that are selected across all sources and filters */
  selectedViewItems: ItemDto[]
  /** All the items that are selected categorized by source */
  customSourceSelectedViewItems?: CustomSourceItems
  /** Rows to be selected when the table loads */
  defaultSelectedRows: RowSelectionState
  tableProps: AssetPickerTableProps
  viewTableProps: AssetPickerTableProps
  hasPreview?: boolean
  hasSidebar?: boolean
  hasTags?: boolean
  infoBanner?: InfoBannerType
  searchInputText: string
}

const defaultTableProps: TablePropsV2 = {
  hasAutoSelectedRows: false,
  hasExpander: false,
  columns: [],
  headerActionCustomProps: [],
  renderSearchColumns: () => [],
  listPage: '',
  rowActionCustomProps: [],
  onCustomTableAction: () => undefined,
}

export const assetPickerContextCommonValues: AssetPickerCommonState = {
  listingPageState: { ...listPageContextCommonValues },
  isSingleSelect: false,
  isViewingSelected: false,
  customSourceSelectedViewItems: {},
  selectedViewItems: [],
  defaultSelectedRows: {},
  loadingPreSelectedItems: true,
  hasSidebar: true,
  hasTags: true,
  hasPreview: true,
  searchInputText: '',
  tableProps: defaultTableProps,
  viewTableProps: defaultTableProps,
}

export interface AssetPickerContextAPI<T> {
  values: T
  update: UpdateState<AssetPickerCommonState>
  onRowSelectionChanged: (rowIds: string[], rows: any) => void
}

export type AssetPickerListingPageProps = Partial<
  Pick<
    ListingPageProps,
    | 'onFilterChanged'
    | 'customRequestFilters'
    | 'getCustomActiveFilter'
    | 'getCustomFilterParams'
    | 'customPreviewItemCall'
    | 'hasCustomRequests'
    | 'subTypes'
    | 'customFilters'
    | 'defaultActiveSubTypes'
    | 'onApiAvailable'
    | 'externalDataLoading'
    | 'addCustomData'
  >
> & {
  sidebarProps: AssetPickerSidebarProps
}

export type AssetPickerSidebarProps = Omit<SidebarProps, 'sidebarHeader'>

export type AssetPickerTableProps = Pick<
  TablePropsV2,
  | 'columns'
  | 'renderSearchColumns'
  | 'sortingBy'
  | 'listPage'
  | 'rowDisabled'
  | 'rowTooltip'
  | 'rowDisabledTitle'
  | 'headerCheckboxDisabled'
  | 'hasDisabledRowStyles'
  | 'enableLazyLoading'
  | 'onLoading'
> & {
  enableSorting?: boolean
}

export type AssetPickerCommonContext = AssetPickerContextAPI<AssetPickerCommonState>

export const AssetPickerContext = createContext<AssetPickerCommonContext>({
  values: { ...assetPickerContextCommonValues },
} as AssetPickerCommonContext)

export type CustomRequestType = Promise<{ data: ItemDto[]; error?: string }>
