import { ProgramSchedule } from '@graphql/types/query-types'
import { daysOfWeek, minutePrependZero } from '@utils/date'

export enum ScheduleType {
  MANUALLY = 'NEVER',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export const DEFAULT_SCHEDULE: ProgramSchedule = {
  ampm: 0,
  hour: 12,
  interval: 0,
  minute: 0,
  type: ScheduleType.MANUALLY,
  weekdaysOnly: false,
  hasEndTime: false,
  isScheduled: false,
  isUnscheduled: true,
}

export const formatScheduleTime = (hour: number, minutes: number, toPM = false) => {
  return `${toPM ? hour + 12 : hour % 12}:${minutePrependZero(minutes)}`
}

const getOptions = (fields: object) =>
  Object.entries(fields).map(([key, value]) => ({
    label: value,
    value: key,
  }))

const getDailyInterval = (t: Function) => [
  t('Once daily'),
  t('Every hour'),
  ...[...Array(11).keys()].map((hour) => t(`Every {{hours}} hours`, { hours: hour + 2 })),
]

const getMonthlyInterval = () => [...Array(31).keys()].reduce((days, day) => ({ ...days, [day + 1]: day + 1 }), {})

const getWeeklyInterval = (t: Function) => daysOfWeek(t, true).reduce((days, day, index) => ({ ...days, [index + 1]: day }), {})

export const getScheduledIntervalOptions = (frequency: ScheduleType, t: Function) => {
  const frequencies: { [key in ScheduleType]: any } = {
    [ScheduleType.MANUALLY]: '',
    [ScheduleType.DAILY]: getDailyInterval(t),
    [ScheduleType.MONTHLY]: getMonthlyInterval(),
    [ScheduleType.WEEKLY]: getWeeklyInterval(t),
  }
  return getOptions(frequencies[frequency])
}

export const renderScheduledIntervalHeader = (frequency: ScheduleType) => {
  const frequencies: { [key in ScheduleType]: string } = {
    [ScheduleType.MANUALLY]: '',
    [ScheduleType.DAILY]: 'Run',
    [ScheduleType.MONTHLY]: 'Day of month to run',
    [ScheduleType.WEEKLY]: 'Run on',
  }
  return frequencies[frequency]
}
