import { gql } from '@apollo/client'

export default gql`
  query getAutomatedProgramCounts($programId: String!) {
    getAutomatedProgramCounts(programId: $programId) {
      programCounts {
        enteredContacts
        earlyExitCount
        doneCount
        deletedCount
      }
      programStepCounts {
        stepId
        inStepCount
        completedStepCount
        deletedStepCount
        exitCount
      }
      totalSuppressions
      totalEarlyExits
      pendingSize
      reEnterContacts
      exitConditions {
        evaluationId
        count
        not
        score
        listName
        filterable
      }
      isHistoryDisabled
    }
  }
`
