import React, { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import classNames from 'classnames'
import * as yup from 'yup'

import { useApolloClient, useQuery } from '@apollo/client'
import { MessageType } from '@complex/MessagePickerModal/messagePickerModalUtils'
import Button, { ButtonType } from '@components/Button/Button'
import Checkbox from '@components/Checkbox/Checkbox'
import FormGroup from '@components/FormGroup/FormGroup'
import FormRow from '@components/FormRow'
import Input from '@components/Input/Input'
import Label from '@components/Label/Label'
import Loader from '@components/Loader/Loader'
import { ModalBody } from '@components/Modal'
import PageError from '@components/PageError/PageError'
import Select from '@components/Select/Select'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import globals, { useTranslation } from '@const/globals'
import unifiedListFieldMappings from '@graphql/microservices/list/getUnifiedListFieldMappings'
import UnifiedContactValidateRecord from '@graphql/microservices/list/unifiedContactValidateRecord'
import campaignsQuery from '@graphql/queries/campaigns'
import multipleScoreSheets from '@graphql/queries/multipleScoreSheets'
import {
  GetUnifiedListFieldMappingsQuery,
  GetUnifiedListFieldMappingsQueryVariables,
  UnifiedContactValidateRecordQuery,
  UnifiedContactValidateRecordQueryVariables,
  ValidationResult,
} from '@graphql/types/microservice/list-types'
import {
  AccountSettings,
  Campaign,
  CampaignsQuery,
  CampaignsQueryVariables,
  MultipleScoresheetsQuery,
  MultipleScoresheetsQueryVariables,
  Program,
  ProgramMessage,
  ProgramSetFieldChoice,
  ProgramSetFieldOperation,
  ProgramSource,
  ScoreSheets,
} from '@graphql/types/query-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { ALL_ACTON_CONTACTS_SEGMENT_NAME } from '@src/pages/ContactSegments/utils/ContactSegments.constants'
import ChangeFieldStepDetail from '@src/pages/programs/dashboard/components/ProgramSteps/components/ChangeFieldStepDetail/ChangeFieldStepDetail'
import {
  convertOperationtoCFSOperation,
  convertToCFSConditions,
  findListName,
} from '@src/pages/programs/dashboard/components/ProgramSteps/ProgramSteps.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { logNewRelicError } from '@utils/new-relic.utils'
import {
  getAOContactsSource,
  getIsListSegment,
  getNewSegment,
  getNewSegmentName,
  getSegmentAndNotValue,
  getUpdatedProgramMessages,
  getUpdatedProgramSources,
  hasAOContactsSource,
  hasMirroredListSource,
} from '@utils/program/program'
import { ProgramRecordSetStepExt, Step, Track } from '@utils/program/program.constants'
import { ProgramWithStepData } from '@utils/program/ProgramSteps.constants'

import { getEditFieldSetStepUtils } from './editFieldSetStepUtils'
import ConditionalSelect from '../../../ConditionalSelect/ConditionalSelect'
import ListSelect from '../../../ListSelect/ListSelect'

import './editFieldSetStep.css'

const rootClass = 'edit-field-set-step'

export interface Props {
  step: Step
  program: Program
  tracks: Track[]
  isRunning: boolean
  saveStepAndProgram(step: Step | null, program?: Program): void
  submitId: string
  dataTest?: string
  accountSettings?: AccountSettings
}

interface ProgramSetFieldChoiceExt extends ProgramSetFieldChoice {
  id: string
}

interface ProgramSetFieldOperationExt extends ProgramSetFieldOperation {
  id: string
}

interface UCLMapping {
  columnIndex: number
  dataType: string
  displayName: string
  disabled: boolean
}

export interface State {
  step: ProgramRecordSetStepExt
  conditions?: ProgramSetFieldChoiceExt[]
  listHeaders?: string[]
  operations: { [key: string]: ProgramSetFieldOperationExt[] }
  uclLoading: boolean
  uclError: boolean
  uclMappings: UCLMapping[]
  uclErrors: ValidationResult
}

const DEFAULT_OPERATION = 'default'

export enum FieldSetOptions {
  INCR = 'INCR',
  DECR = 'DECR',
  CONST = 'CONST',
  SCORE = 'SCORE',
  CAMPAIGN = 'CAMPAIGN',
  UID = 'UID',
  NOOP = 'NOP',
}

function getFieldSetOptions(t: Function) {
  const options = getOptions(t)
  return [
    options[FieldSetOptions.INCR],
    options[FieldSetOptions.DECR],
    options[FieldSetOptions.CONST],
    options[FieldSetOptions.SCORE],
    options[FieldSetOptions.CAMPAIGN],
    options[FieldSetOptions.UID],
  ]
}

const textDataType = 'TEXT'
export const numberDataType = 'NUMBER'
const scoreDataType = 'SCORE'

const getUnifiedListOptions = (t: Function, dataType: string) => {
  const options = getOptions(t)

  if (dataType === numberDataType) {
    return [options[FieldSetOptions.INCR], options[FieldSetOptions.DECR], options[FieldSetOptions.CONST], options[FieldSetOptions.UID]]
  } else if (dataType === textDataType) {
    return [options[FieldSetOptions.CONST], options[FieldSetOptions.UID]]
  }
  return [options[FieldSetOptions.CONST]]
}

const getOptions = (t: Function) => ({
  [FieldSetOptions.INCR]: (
    <option key={FieldSetOptions.INCR} value={FieldSetOptions.INCR}>
      {t('Increase the value by')}
    </option>
  ),
  [FieldSetOptions.DECR]: (
    <option key={FieldSetOptions.DECR} value={FieldSetOptions.DECR}>
      {t('Decrease the value by')}
    </option>
  ),
  [FieldSetOptions.CONST]: (
    <option key={FieldSetOptions.CONST} value={FieldSetOptions.CONST}>
      {t('Set the value to')}
    </option>
  ),
  [FieldSetOptions.SCORE]: (
    <option key={FieldSetOptions.SCORE} value={FieldSetOptions.SCORE}>
      {t('Set value to behavioral score')}
    </option>
  ),
  [FieldSetOptions.CAMPAIGN]: (
    <option key={FieldSetOptions.CAMPAIGN} value={FieldSetOptions.CAMPAIGN}>
      {t('Set value to campaign score')}
    </option>
  ),
  [FieldSetOptions.UID]: (
    <option key={FieldSetOptions.UID} value={FieldSetOptions.UID}>
      {t('Assign unique number in list')}
    </option>
  ),
})

export const handleFormSubmit = (data: any, state: State, props: Props) => {
  const { saveStepAndProgram, program } = props

  const localSegmentDetails = Array.isArray(program.localSegmentDetails) ? [...program.localSegmentDetails] : []

  const conditions = (state.conditions ?? [])?.map((currentCondition) => {
    const condition = data.conditions[currentCondition.id]
    const checkedCondition = typeof condition === 'string' ? condition : condition.segmentMode
    const { segmentMode, not } = getSegmentAndNotValue(checkedCondition)
    const isListSegment = getIsListSegment(segmentMode)
    let srcId = condition.srcId
    if (!isListSegment && condition.newSegment === 'true') {
      srcId = getNewSegmentName(localSegmentDetails)
      localSegmentDetails.push(getNewSegment(srcId, condition.messageIds, segmentMode))
    }
    const operations = (state.operations[currentCondition.id] ?? []).map((currentOperation) => {
      const operation = data.operations[currentCondition.id][currentOperation.id]
      // Workaround: This is not being registered to the form data when a list is selected, cause unknown
      operation.rule = currentOperation.rule
      return {
        ...operation,
      }
    })

    return {
      srcId,
      not,
      operations,
    }
  })

  const operations = (state.operations ?? []).default.map((currentOperation) => {
    const operation = data.default[currentOperation.id]
    operation.rule = currentOperation.rule
    if (operation.fieldName === FieldSetOptions.NOOP) {
      return {
        campaignId: '',
        fieldName: FieldSetOptions.NOOP,
        rule: FieldSetOptions.NOOP,
        value: '',
      }
    }
    return { ...operation }
  })

  saveStepAndProgram(
    {
      ...state.step,
      displayName: data.displayName,
      listId: data.listId,
      canPushSF: data.canPushSF ?? false,
      conditions,
      operations,
    },
    {
      ...program,
      localSegmentDetails,
    }
  )
}

export function getListOptions(program: Program, t: Function) {
  return program.sources?.reduce(
    (acc, cur) => {
      return [
        ...acc,
        <option key={cur.id} value={cur.id}>
          {cur.name}
        </option>,
      ]
    },
    [
      <option key="empty" value="">
        --- {t('Select List')} ---
      </option>,
    ]
  )
}

const schema = yup.object().shape({
  displayName: yup.string().required('Step Name is required.'),
})

export const EditFieldSetStep: FC<Props> = (props: Props) => {
  const { step: baseStep, program, isRunning, saveStepAndProgram, submitId, dataTest = 'edit-change-field-step', accountSettings } = props
  const client = useApolloClient()
  const defaultColumnIndex = -1000

  const { client: listClient } = useMicroserviceClient({ serviceName: MicroserviceClients.LIST })

  const setFieldStep = baseStep as ProgramRecordSetStepExt
  const getInitialState = (): State => {
    const conditions = setFieldStep.conditions?.map((condition) => ({
      ...condition,
      id: globals.getUUID(),
    }))

    const operations: { [key: string]: ProgramSetFieldOperationExt[] } = {}
    conditions?.forEach((condition) => {
      operations[condition.id] =
        condition.operations && condition.operations.length > 0
          ? condition.operations?.map((operation) => ({
              ...operation,
              id: globals.getUUID(),
            }))
          : [
              {
                id: globals.getUUID(),
              },
            ]
    })
    operations[DEFAULT_OPERATION] =
      setFieldStep.operations && setFieldStep.operations.length > 0
        ? setFieldStep.operations?.map((operation) => ({
            ...operation,
            id: globals.getUUID(),
          }))
        : [
            {
              id: globals.getUUID(),
            },
          ]

    return {
      step: setFieldStep,
      conditions,
      operations: operations,
      listHeaders: program.sources.find((source) => source.id === setFieldStep.listId)?.headers,
      uclLoading: false,
      uclMappings: [
        {
          columnIndex: defaultColumnIndex,
          dataType: '',
          displayName: '',
          disabled: false,
        },
      ],
      uclError: false,
      uclErrors: {
        id: 0,
      },
    }
  }

  const [state, setState] = React.useState<State>(getInitialState())
  const { step, listHeaders, conditions, operations } = state

  const editFieldSteStepUtils = getEditFieldSetStepUtils(setState)
  const isUnifiedList = hasAOContactsSource(program)
  const hasAllContactsList = getAOContactsSource(ALL_ACTON_CONTACTS_SEGMENT_NAME, program.sources)
  const hasMirroredList = hasMirroredListSource(program)

  useEffect(() => {
    if (!listHeaders && step.listId) {
      setState((state) => ({
        ...state,
        listHeaders: program.sources.find((source) => source.id === step.listId)?.headers,
      }))
    }
  }, [listHeaders, program.sources, step.listId])

  useEffect(() => {
    if (hasAllContactsList || hasMirroredList) {
      setState((state) => {
        const { step } = state
        return {
          ...state,
          step: {
            ...step,
            listId: hasAllContactsList ? hasAllContactsList.id : 'l-unified-contacts',
          },
          listHeaders: undefined,
        }
      })
    }
  }, [])

  useEffect(() => {
    const callQuery = async () => {
      const data = await listClient.query<GetUnifiedListFieldMappingsQuery, GetUnifiedListFieldMappingsQueryVariables>({
        query: unifiedListFieldMappings,
        fetchPolicy: 'network-only',
      })

      if (data.data.unifiedListFieldMappings) {
        const uclMappings: UCLMapping[] = data.data.unifiedListFieldMappings
          .filter((mapping) => mapping !== undefined)
          .filter((mapping) => !mapping?.deleted && !mapping?.hidden)
          .filter((mapping) => mapping?.dataType !== scoreDataType)
          .map((mapping) => {
            if (mapping) {
              return {
                columnIndex: (mapping.columnIndex as number) ?? defaultColumnIndex,
                displayName: mapping.displayName ?? '',
                dataType: mapping.dataType ?? '',
                disabled: (mapping.readOnly || mapping.hidden) ?? false,
              }
            }
            return {
              columnIndex: defaultColumnIndex,
              displayName: '',
              dataType: '',
              disabled: false,
            }
          })
        setState((state) => ({
          ...state,
          listHeaders: uclMappings.filter((mapping) => !mapping.disabled).map((mapping) => mapping.displayName),
          uclLoading: false,
          uclMappings,
        }))
      } else {
        setState((state) => ({
          ...state,
          uclLoading: false,
          uclError: true,
        }))
      }
    }
    if (isUnifiedList) {
      setState((state) => ({
        ...state,
        listHeaders: undefined,
        uclLoading: true,
      }))
      callQuery()
    }
  }, [defaultColumnIndex, isUnifiedList, listClient])

  const campaignResults = useQuery<CampaignsQuery, CampaignsQueryVariables>(campaignsQuery, {
    client: client as any,
  })
  const campaigns: Campaign[] =
    campaignResults.data && campaignResults.data.campaigns && campaignResults.data.campaigns.length ? campaignResults.data.campaigns : []

  const ssResults = useQuery<MultipleScoresheetsQuery, MultipleScoresheetsQueryVariables>(multipleScoreSheets, {
    client: client as any,
  })
  const scoresheets: ScoreSheets[] =
    ssResults.data && ssResults.data.multipleScoresheets && ssResults.data.multipleScoresheets.length ? ssResults.data.multipleScoresheets : []

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    if (errors) {
      logNewRelicError(errors)
    }
  }, [errors])

  const { t } = useTranslation()

  const validateUnified = (validationFields: any, submitData: any) => {
    const validateFields = async (validationFields: any) => {
      const data = await listClient.query<UnifiedContactValidateRecordQuery, UnifiedContactValidateRecordQueryVariables>({
        query: UnifiedContactValidateRecord,
        fetchPolicy: 'network-only',
        variables: {
          columnIndexDataValueList: validationFields,
        },
      })

      if (data.data.unifiedContactValidateRecord && data.data.unifiedContactValidateRecord.status !== 'Success') {
        setState({
          ...state,
          uclErrors: data.data.unifiedContactValidateRecord,
        })
      } else {
        setState({
          ...state,
          uclErrors: {
            id: 0,
          },
        })

        handleFormSubmit(submitData, state, props)
      }
    }

    validateFields(validationFields)
  }

  const onSubmit = (data: any) => {
    if (isUnifiedList) {
      const validationFields = []
      for (const key in data.default) {
        const { fieldName, value } = data.default[key]
        const selectedMapping = state.uclMappings.find((mapping) => fieldName === mapping.displayName)
        const columnIndex = selectedMapping?.columnIndex
        if (columnIndex) {
          validationFields.push({
            dataValue: value,
            columnIndex: columnIndex,
          })
        }
      }

      for (const key in data.operations) {
        for (const operationKey in data.operations[key]) {
          const { fieldName, value } = data.operations[key][operationKey]
          const selectedMapping = state.uclMappings.find((mapping) => fieldName === mapping.displayName)
          const columnIndex = selectedMapping?.columnIndex
          if (columnIndex) {
            validationFields.push({
              dataValue: value,
              columnIndex: columnIndex,
            })
          }
        }
      }

      validateUnified(validationFields, data)
    } else {
      handleFormSubmit(data, state, props)
    }
  }

  const onProgramSourcesUpdate = (sources: ProgramSource[]) => {
    saveStepAndProgram(null, getUpdatedProgramSources(program, sources))
  }

  const onMessageUpdate = (messages: ProgramMessage) => {
    saveStepAndProgram(null, getUpdatedProgramMessages(program, [messages]))
  }

  const getOperations = (rowOperations?: ProgramSetFieldOperationExt[], id?: string, optionalOperations?: { [key: string]: string }) => {
    if (!listHeaders || !rowOperations) return null

    return rowOperations.map((operation, i) => {
      const conditionId = id ?? DEFAULT_OPERATION
      const selectedMapping = state.uclMappings.find((mapping) => operation?.fieldName === mapping.displayName)

      const selectedMappingType = selectedMapping ? selectedMapping.dataType : ''
      const selectName = `${id ? `operations[${id}]` : 'default'}.[${operation.id}]`

      return (
        <FormRow className={`${rootClass}__operation-row`} key={operation.id}>
          <Select
            register={register(selectName + '.fieldName', {
              onChange: (event) => {
                editFieldSteStepUtils.updateOperationValue<ProgramSetFieldOperation['fieldName']>(
                  conditionId,
                  operation.id,
                  'fieldName',
                  event.target.value
                )
              },
            })}
            defaultValue={operation?.fieldName}
          >
            <option value="">{t('Select a Field')}</option>
            {optionalOperations &&
              Object.keys(optionalOperations).map((key) => (
                <option key={key} value={key}>
                  {optionalOperations[key]}
                </option>
              ))}
            {listHeaders?.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
          {operation?.fieldName !== FieldSetOptions.NOOP && (
            <Select
              onChange={(event) => {
                editFieldSteStepUtils.updateOperationValue<ProgramSetFieldOperation['rule']>(conditionId, operation.id, 'rule', event.target.value)
              }}
              value={operation?.rule ?? FieldSetOptions.INCR}
            >
              {isUnifiedList ? getUnifiedListOptions(t, selectedMappingType) : getFieldSetOptions(t)}
            </Select>
          )}
          {(operation?.rule === undefined ||
            operation?.rule === FieldSetOptions.INCR ||
            operation?.rule === FieldSetOptions.CONST ||
            operation?.rule === FieldSetOptions.DECR) &&
            operation.fieldName !== FieldSetOptions.NOOP && (
              <Input
                name={selectName + '.value'}
                register={register(selectName + '.value')}
                defaultValue={operation?.value}
                onBlur={(event) => {
                  editFieldSteStepUtils.updateOperationValue<ProgramSetFieldOperation['value']>(
                    conditionId,
                    operation.id,
                    'value',
                    event.target.value
                  )
                }}
              />
            )}
          {!campaignResults.loading && operation?.rule === FieldSetOptions.CAMPAIGN && (
            <Select
              register={register(selectName + 'campaignId', {
                onChange: (event) => {
                  editFieldSteStepUtils.updateOperationValue<ProgramSetFieldOperation['campaignId']>(
                    conditionId,
                    operation.id,
                    'campaignId',
                    event.target.value
                  )
                },
              })}
              defaultValue={operation.campaignId}
            >
              <option value="">{t('Select a Campaign')}</option>
              {campaigns?.map((campaign) => {
                return (
                  campaign.id && (
                    <option key={campaign.id} value={campaign.id}>
                      {campaign.name}
                    </option>
                  )
                )
              })}
            </Select>
          )}
          {(operation?.rule === FieldSetOptions.SCORE || operation?.rule === FieldSetOptions.CAMPAIGN) &&
            !ssResults.loading &&
            accountSettings?.multipleScoreSheets &&
            scoresheets && (
              <Select
                register={register(selectName + '.scoreSheetId', {
                  onChange: (event) => {
                    editFieldSteStepUtils.updateOperationValue<ProgramSetFieldOperation['scoreSheetId']>(
                      conditionId,
                      operation.id,
                      'scoreSheetId',
                      event.target.value
                    )
                  },
                })}
                defaultValue={operation.scoreSheetId}
              >
                {scoresheets?.map((scoresheet) => (
                  <option key={scoresheet.id} value={scoresheet.id}>
                    {scoresheet.name}
                  </option>
                ))}
              </Select>
            )}
          {i > 0 && (
            <Button
              className={classNames(`${rootClass}__delete-operation`, {
                [`${rootClass}__delete-operation--extra-width`]: operation.rule === FieldSetOptions.SCORE || operation.rule === FieldSetOptions.UID,
              })}
              buttonType={ButtonType.REMOVE}
              onClick={() => {
                setState({
                  ...state,
                  operations: {
                    ...operations,
                    [conditionId]: operations[conditionId].filter((curOperation) => curOperation.id !== operation.id),
                  },
                })
              }}
            >
              <Svg name={SvgNames.delete} />
            </Button>
          )}
        </FormRow>
      )
    })
  }

  const addOperation = (id: string) => {
    operations[id] = [
      ...operations[id],
      {
        id: globals.getUUID(),
      },
    ]
    setState({
      ...state,
      operations,
    })
  }

  const showPushSF = step.listId && step.listId.startsWith('l-sf-') && !step.listId.startsWith('l-sf-rpt')
  const hasConditions = conditions && conditions.length > 0

  const renderForm = () => (
    <form data-test={dataTest} onSubmit={handleSubmit(onSubmit)} className={rootClass}>
      {(ssResults.loading || campaignResults.loading || state.uclLoading) && <Loader center />}
      {(ssResults.error || campaignResults.error || state.uclError) && <PageError />}
      {errors?.displayName && <span className="error">{t(errors.displayName.message)}</span>}
      {state.uclErrors?.columnValidationResultList &&
        state.uclErrors.columnValidationResultList.map((value, i) => {
          const error = value?.validationResult
          if (error != 'VALIDATION_SUCCESS') {
            return (
              <FormRow key={i}>
                <span className="error">{t(error)}</span>
              </FormRow>
            )
          }
        })}
      <FormRow>
        <Input label={t('Step Name')} defaultValue={step.displayName} name="displayName" register={register('displayName')} />
        {errors?.displayName && <span className="error">{t(errors.displayName.message)}</span>}
      </FormRow>
      {(hasAllContactsList || hasMirroredList) && (
        <Input
          name={'listId'}
          value={hasAllContactsList ? hasAllContactsList.id : 'l-unified-contacts'}
          register={register('listId')}
          type={'hidden'}
        />
      )}
      {!hasAllContactsList && !hasMirroredList && (
        <FormRow>
          <Label>{t('Select List to Modify')}</Label>
          <ListSelect
            name="listId"
            onProgramSourcesUpdate={onProgramSourcesUpdate}
            register={register}
            program={program}
            srcId={step.listId}
            isListsAndUCLOnly
            onChange={(listId: string) => {
              // since fields don't change in AO Contacts sources, no need to reset them here
              const listHeaders = isUnifiedList ? state.listHeaders : undefined
              setState({
                ...state,
                step: {
                  ...step,
                  listId,
                },
                listHeaders,
              })
            }}
          />
        </FormRow>
      )}
      {showPushSF && (
        <FormRow>
          <Checkbox
            label={`Push to ${program.crm?.crmName}`}
            register={register('pushToSf')}
            name="pushToSf"
            checked={step.pushToSf}
            onChange={(checked) => {
              setState({
                ...state,
                step: {
                  ...step,
                  pushToSf: checked,
                },
              })
            }}
          />
        </FormRow>
      )}
      {conditions?.map((condition, i) => {
        return (
          <React.Fragment key={condition.id}>
            <div className={`${rootClass}__conditions_header`}>
              <Label>{i === 0 ? t('If prospect') : t('Else if prospect')}</Label>
              <div className={`${rootClass}__condition-buttons`}>
                <Button
                  buttonType={ButtonType.REMOVE}
                  className={`${rootClass}__delete-condition`}
                  onClick={(e) => {
                    setState({
                      ...state,
                      conditions: [...conditions.slice(0, i), ...conditions.slice(i + 1)],
                    })
                    e.preventDefault()
                  }}
                >
                  <Svg name={SvgNames.delete} />
                  Delete
                </Button>
                {i > 0 && (
                  <button
                    type="button"
                    onClick={(e) => {
                      setState({
                        ...state,
                        conditions: [...conditions.slice(0, i - 1), conditions[i], conditions[i - 1], ...conditions.slice(i + 1)],
                      })
                      e.preventDefault()
                    }}
                  >
                    <Svg name={SvgNames.upArrow} />
                  </button>
                )}
                {i < conditions.length - 1 && (
                  <button
                    type="button"
                    onClick={(e) => {
                      setState({
                        ...state,
                        conditions: [...conditions.slice(0, i), conditions[i + 1], conditions[i], ...conditions.slice(i + 2)],
                      })
                      e.preventDefault()
                    }}
                  >
                    <Svg name={SvgNames.downArrow} />
                  </button>
                )}
              </div>
            </div>
            <FormGroup key={condition.id}>
              <ConditionalSelect
                dataTest={`${dataTest}-conditional-select`}
                groupedInputs={{
                  name: 'conditions',
                  index: condition.id,
                }}
                step={condition}
                register={register}
                formReset={reset}
                formData={getValues}
                program={program}
                onProgramSourcesUpdate={onProgramSourcesUpdate}
                onMessageUpdate={onMessageUpdate}
                allowedMessageCategories={[MessageType.SENT, MessageType.SCHEDULED, MessageType.PROGRAM]}
                source={hasAllContactsList}
              />
              {getOperations(operations[condition.id], condition.id)}
              <Button
                buttonType={ButtonType.FLOAT}
                onClick={() => {
                  addOperation(condition.id)
                }}
              >
                <Svg name={SvgNames.plus} type={SvgType.LARGER_ICON} />
                {t('Add')}
              </Button>
            </FormGroup>
          </React.Fragment>
        )
      })}

      {hasConditions && <Label>{t('Otherwise')}</Label>}
      {listHeaders && (
        <FormGroup className={hasConditions ? 'push-down-none' : ''}>
          {getOperations(operations[DEFAULT_OPERATION], undefined, { [FieldSetOptions.NOOP]: `--${t('Does Not Change')}--` })}

          <Button
            buttonType={ButtonType.FLOAT}
            onClick={() => {
              addOperation(DEFAULT_OPERATION)
            }}
          >
            <Svg name={SvgNames.plus} type={SvgType.LARGER_ICON} />
            {t('Add')}
          </Button>
        </FormGroup>
      )}
      {listHeaders && (
        <Button
          buttonType={ButtonType.FLOAT}
          onClick={() => {
            const id = globals.getUUID()
            operations[id] = [
              {
                id: globals.getUUID(),
              },
            ]
            setState({
              ...state,
              conditions: [
                ...(conditions ?? []),
                {
                  id,
                  not: false,
                  srcId: '',
                },
              ],
              operations,
            })
          }}
        >
          <Svg name={SvgNames.plus} type={SvgType.LARGER_ICON} />
          {t('Add Condition')}
        </Button>
      )}
      <button type="submit" id={submitId} hidden />
    </form>
  )

  const renderView = () => {
    const listName = findListName(step.listId ?? '', program.sources)
    const conditions = step.conditions ? convertToCFSConditions(step.conditions, program as ProgramWithStepData) : []
    return (
      <>
        <Typography text={step.displayName} weight={TextWeight.MEDIUM} type={TextType.SECTION_HEADER} />
        <ChangeFieldStepDetail
          programId={program.id}
          listName={listName}
          conditions={conditions}
          operations={step?.operations?.map(convertOperationtoCFSOperation) ?? []}
          className={`${rootClass}__change-detail`}
        />
      </>
    )
  }

  return <ModalBody>{isRunning ? renderView() : renderForm()}</ModalBody>
}

export default EditFieldSetStep
