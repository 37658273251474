import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'
import { useDebouncedCallback } from 'use-debounce'

import Input, { InputProps } from '@components/Input/Input'
import Loader from '@components/Loader'
import { SvgNames, SvgType } from '@components/Svg'
import Tooltip, { TooltipProps } from '@components/Tooltip/Tooltip'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './InputWithStatus.css'

export const REQUIRED_MESSAGE_KEY = 'required'
export const DEFAULT_MESSAGE_KEY = 'defaultMessage'

export type ValidityReturnType = { errorMessageKey: string }

export interface InputWithStatusProps extends InputProps {
  className?: string
  validityFunctions?: ((value: any) => undefined | ValidityReturnType)[]
  tooltipErrorMessages?: Record<string, string | ReactNode>
  required?: boolean
  markPristine?: boolean
  customTooltipErrorMessages?: string | ReactNode
  customErrorMessage?: string
  errorMessages?: Record<string, string>
  dataTest?: string
  iconType?: SvgType
  hasCustomError?: boolean
  showStatus?: boolean
  showErrorOnChange?: boolean
  validationDebounceTime?: number
  reload?: boolean
  onReload?: () => void
  successDebounceTime?: number
  showIconOnValid?: boolean
  tooltipProps?: Partial<TooltipProps>
  errorText?: string
  fullWidth?: boolean
}

const rootClass = 'input-with-status'

/**
 * @deprecated use <InputV2 instead
 */
const InputWithStatus: FC<InputWithStatusProps> = (props: InputWithStatusProps) => {
  const {
    className = '',
    validityFunctions = [],
    tooltipErrorMessages = { [DEFAULT_MESSAGE_KEY]: 'Provide a valid value' },
    customTooltipErrorMessages,
    customErrorMessage,
    reload = false,
    markPristine = false,
    onReload,
    errorMessages,
    value = '',
    onChange,
    onBlur,
    onFocus,
    dataTest = rootClass,
    hasCustomError,
    showStatus = true,
    successDebounceTime = 500,
    validationDebounceTime = 1200,
    showErrorOnChange,
    autoFocus,
    register,
    showIconOnValid = true,
    icon,
    tooltipProps = {},
    errorText,
    fullWidth,
    ...inputProps
  } = props
  const { required } = inputProps

  const inputRef = useRef<HTMLInputElement>()
  const [inputValue, setInputValue] = useState<string>(value)
  const [isValid, setIsValid] = useState<boolean>()
  const [isTouched, setIsTouched] = useState<boolean>()
  const [statusVisible, setStatusVisible] = useState<boolean>(showStatus)
  const [errorKey, setErrorKey] = useState<string>('required')
  const [activeIcon, setActiveIcon] = useState<SvgNames | undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  const [isInitialLoad, setIsInitalLoad] = useState(true)

  useEffect(() => {
    setInputValue(value)
  }, [value])

  useEffect(() => {
    if (showStatus) {
      if (hasCustomError) {
        cancelDelayedValidation()
        setLoading(false)
        setIsValid(false)
        setActiveIcon(SvgNames.inputStatusInvalid)
      } else if (isTouched) {
        refreshStatus()
      }
    }
  }, [hasCustomError])

  useEffect(() => {
    cancelDelayedValidation()
    setStatusVisible((prev) => {
      if (showStatus !== prev) {
        refreshStatus()
      }

      return showStatus
    })
  }, [showStatus])

  useEffect(() => {
    if (autoFocus && !!inputRef.current) {
      inputRef.current?.focus()
    }
  }, [autoFocus])

  useEffect(() => {
    if (reload) {
      cancelDelayedValidation()
      refreshStatus()
    }
    onReload && onReload()
  }, [reload])

  useEffect(() => {
    if (markPristine) {
      setIsTouched(false)
      setActiveIcon(undefined)
    }
  }, [markPristine])

  const { t } = useTranslation()

  const getErrorKey = (value: string) => {
    if (emptyValidator(value)) {
      return REQUIRED_MESSAGE_KEY
    }
    const validationFunc = validityFunctions.find((f) => f(value))
    return validationFunc ? validationFunc(value)?.errorMessageKey : ''
  }

  const cancelDelayedValidation = () => {
    showErrorOnChange && errorValidationDelayed.cancel()
    validationDelayed.cancel()
  }

  const setIcon = (isValid: boolean | undefined) => {
    if (isValid && showIconOnValid) {
      setActiveIcon(SvgNames.inputStatusSuccess)
      return
    }
    if (!isValid) {
      setActiveIcon(SvgNames.inputStatusInvalid)
    }
  }

  const validationDelayed = useDebouncedCallback((isLoading, isValid, errorKey?) => {
    setLoading(isLoading)
    setIsValid(isValid)
    setIcon(isValid)
    setErrorKey(errorKey)
  }, successDebounceTime)

  const errorValidationDelayed = useDebouncedCallback((errorKey: string) => {
    setLoading(false)
    setIsValid(false)
    setErrorKey(errorKey)
    setActiveIcon(SvgNames.inputStatusInvalid)
  }, validationDebounceTime)

  const errorValidation = (value: string) => {
    cancelDelayedValidation()
    setLoading(true)
    setActiveIcon(undefined)
    const errorKey = getErrorKey(value) ?? ''
    errorValidationDelayed(errorKey)
  }

  const emptyValidator = (value: string) => {
    return required && !value
  }

  const isEmptyValid = (value: string) => {
    return !required && !value
  }

  const refreshStatus = (e?: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.target.value || inputValue
    const invalid = emptyValidator(value) || validityFunctions.some((f) => !!f(value)) || hasCustomError
    if (showErrorOnChange && invalid) {
      errorValidation(value)
    } else {
      setLoading(true)
      setActiveIcon(undefined)
      validationDelayed(false, !invalid, getErrorKey(value))
    }
  }

  const handleInputChanges = (value: string) => {
    if (!showStatus) {
      return
    }

    cancelDelayedValidation()
    const invalid = emptyValidator(value) || validityFunctions.some((f) => !!f(value))

    if (!invalid && !hasCustomError) {
      setActiveIcon(undefined)
      setLoading(true)
      validationDelayed(false, true)
    } else if (showErrorOnChange) {
      errorValidation(value)
    } else {
      validationDelayed(false, undefined, getErrorKey(value))
    }
  }

  const onInputChange = (value: string): void => {
    !isTouched && setIsTouched(true)
    handleInputChanges(value)
    setInputValue(value)
  }

  const onInputFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    onFocus && onFocus(e)
    if (!statusVisible) {
      return
    }

    if (emptyValidator(inputValue) || validityFunctions.some((f) => !!f(inputValue))) {
      const showError = showErrorOnChange && !emptyValidator(inputValue)
      setActiveIcon(showError ? SvgNames.inputStatusInvalid : SvgNames.inputStatusActive)
      setIsValid(showError ? false : undefined)
      return
    }

    !hasCustomError && handleInputChanges(inputValue)
    !isTouched && setIsTouched(true)
  }

  const onInputBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    onBlur && onBlur(e)
    setIsInitalLoad(false)
    if (!statusVisible) {
      return
    }

    if (isEmptyValid(e.target.value)) {
      return
    }

    if (!isValid) {
      refreshStatus(e)
    }
  }

  const getTooltipText = () => {
    const customMessage = typeof customTooltipErrorMessages === 'string' ? t(customTooltipErrorMessages) : customTooltipErrorMessages
    const key = errorKey ?? DEFAULT_MESSAGE_KEY
    const message = typeof tooltipErrorMessages[key] === 'string' ? t(tooltipErrorMessages[key]) : tooltipErrorMessages[key]

    return hasCustomError ? customMessage : message
  }

  const showErrorIcon = statusVisible && !isValid && !isInitialLoad
  const getInput = (
    <>
      <div className={classNames(rootClass, className)}>
        <Input
          dataTest={dataTest}
          className={`${rootClass}__input`}
          {...inputProps}
          register={register ?? inputRef}
          icon={icon && !activeIcon && !loading ? icon : (statusVisible && inputValue) || isInitialLoad ? activeIcon : undefined}
          iconType={SvgType.LARGER_ICON}
          value={inputValue}
          showSuccess
          onBlur={onInputBlur}
          error={showErrorIcon || hasCustomError}
          onChange={(e) => {
            setIsInitalLoad(false)
            onInputChange(e.target.value)
            onChange && onChange(e)
          }}
          onFocus={onInputFocus}
        />
        {statusVisible && loading && <Loader className={`${rootClass}__loader`} />}
      </div>
      {errorText && statusVisible && inputValue && !isValid && (
        <Typography text={errorText} type={TextType.ERROR} className={`${rootClass}__err-msg`} />
      )}
    </>
  )

  const errorDisplayText = errorMessages && !hasCustomError ? t(errorMessages[errorKey]) : t(customErrorMessage)

  return (
    <>
      <Tooltip
        className={`${rootClass}__tooltip`}
        trigger={getInput}
        hide={(isValid || isValid === undefined || !errorKey) && !hasCustomError}
        position="right"
        triggerRef={inputRef}
        fullWidth={fullWidth}
        {...tooltipProps}
      >
        {getTooltipText()}
      </Tooltip>
      {errorDisplayText && ((isValid !== undefined && !isValid) || hasCustomError) && (!!errorMessages || !!customErrorMessage) && (
        <Typography text={errorDisplayText} type={TextType.VALIDATION_ERROR} className={`${rootClass}__input-error-message`} />
      )}
    </>
  )
}

export default InputWithStatus
