import getItem from '@graphql/microservices/categorization/getItem'
import { GetItemQuery, GetItemQueryVariables } from '@graphql/types/microservice/categorization-types'
import { ItemType } from '@utils/categorization'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

interface GetSegmentDetailsQueriesResponse {
  getItemDetailsRequest: (type: ItemType, itemId?: number | string, externalId?: string) => FetchPromise<GetItemQuery>
}

export const useSegmentDetailsQueries = (): GetSegmentDetailsQueriesResponse => {
  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })

  const getItemDetailsRequest = (type: ItemType, itemId?: number | string, externalId?: string) =>
    categorizationClient.query<GetItemQuery, GetItemQueryVariables>({
      query: getItem,
      fetchPolicy: 'network-only',
      variables: {
        itemId,
        externalId,
        type,
      },
    })

  return {
    getItemDetailsRequest,
  }
}
