import React, { FC, useContext } from 'react'

import classNames from 'classnames'

import AccountLimitsWarning from '@src/pages/EmailComposer/components/SendDetails/components/AccountLimitsWarning/AccountLimitsWarning'
import SendDetailsAttachNote from '@src/pages/EmailComposer/components/SendDetails/components/SendDetailsAttachNote/SendDetailsAttachNote'
import SendDetailsRecipients from '@src/pages/EmailComposer/components/SendDetails/components/SendDetailsRecipients/SendDetailsRecipients'
import SendDetailsSendMethod from '@src/pages/EmailComposer/components/SendDetails/components/SendDetailsSendMethod/SendDetailsSendMethod'
import SendDetailsSuppressions from '@src/pages/EmailComposer/components/SendDetails/components/SendDetailsSuppressions/SendDetailsSuppressions'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'

import './SendDetails.css'

interface SendDetailsProps {
  className?: string
  dataTest?: string
}

const rootClass = 'send-details'

const SendDetails: FC<SendDetailsProps> = (props: SendDetailsProps) => {
  const { dataTest = rootClass, className = '' } = props
  const {
    values: {
      loading,
      messageConfiguration: {
        sendDetails: { showAttachNote, hideSendMethods, hideSuppression, hideRecipients },
      },
    },
  } = useContext(EmailComposerContext)

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__left`}>
        {!loading && <AccountLimitsWarning />}
        {!hideRecipients && (
          <div className={`${rootClass}__left-section`}>
            <SendDetailsRecipients />
          </div>
        )}
        {!hideSuppression && (
          <div className={`${rootClass}__left-section`}>
            <SendDetailsSuppressions />
          </div>
        )}
        {showAttachNote && (
          <div className={`${rootClass}__left-section`}>
            <SendDetailsAttachNote />
          </div>
        )}
      </div>
      {!hideSendMethods && (
        <div className={`${rootClass}__right`}>
          <div className={`${rootClass}__tight-section`}>
            <SendDetailsSendMethod />
          </div>
        </div>
      )}
    </div>
  )
}

export default SendDetails
