import React, { FC } from 'react'

import classNames from 'classnames'

import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { useTranslation } from '@const/globals'

import './ProgramHistoryDisabled.css'

interface Props {
  className?: string
  dataTest?: string
}

const rootClass = 'program-history-disabled'

const ProgramHistoryDisabled: FC<Props> = (props: Props) => {
  const { className, dataTest = rootClass } = props
  const { t } = useTranslation()

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <EmptyListing
        headline={t(`Program History Unavailable`)}
        imgSrc={StaticImageNames.noProgramHistory}
        size={EmptyListingSize.MEDIUM}
        text={t(
          `You've disabled the history for your program so we do not have a dashboard to show you. To re-enable the history, edit this program and uncheck "Disable program history" from General Settings. (Note that your program will begin tracking history as of the time it is enabled.)`
        )}
      />
    </div>
  )
}

export default ProgramHistoryDisabled
