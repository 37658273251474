import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Svg, { SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { ItemType } from '@utils/categorization'
import { formatDateWithAbbreviations } from '@utils/date'

import { ACTIVITY_STATUS, ActivityPreviewIcon, activityPreviewTooltip, StatusIcons } from '../ActivityCard/utils/ActivityCard.constants'

import './ActivityCard.css'

interface ActivityCardProps {
  className?: string
  dataTest?: string
  title: string
  date: number
  status: ACTIVITY_STATUS
  onPreview: VoidFunction
  itemType: ItemType
}

const rootClass = 'activity-card'

const ActivityCard: FC<ActivityCardProps> = (props: ActivityCardProps) => {
  const { dataTest = rootClass, className = '', title, status, date, onPreview, itemType } = props
  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__information`}>
        <Svg name={StatusIcons[status]} type={SvgType.LARGE_ICON} dataTest={`${dataTest}-status-icon-${StatusIcons[status]}`} />
        <div className={`${rootClass}__information-text`}>
          <Typography
            text={title}
            weight={TextWeight.MEDIUM}
            className={classNames(`${rootClass}__information-text-${itemType === ItemType.WEBINAR_SUBMISSION ? 'webinar' : 'form'}`, 'ellip')}
          />
          <Typography type={TextType.BODY_TEXT_SMALL_LIGHT} text={`${status}: ${formatDateWithAbbreviations(date, true, 'at')}`} />
        </div>
      </div>
      {[ACTIVITY_STATUS.VIEWED, ACTIVITY_STATUS.REGISTERED, ACTIVITY_STATUS.SUBMITTED].includes(status) &&
        itemType !== ItemType.WEBINAR_SUBMISSION && (
          <Tooltip
            trigger={
              <Button buttonType={ButtonType.ICON} className={`${rootClass}__preview`} onClick={onPreview}>
                <Svg name={ActivityPreviewIcon[status]} type={SvgType.LARGER_ICON} className={`${rootClass}__preview-icon`} />
              </Button>
            }
            position={'right'}
            sideOffset={7}
          >
            {activityPreviewTooltip[status]}
          </Tooltip>
        )}
    </div>
  )
}

export default ActivityCard
