import { gql } from '@apollo/client'

export default gql`
  query getWebPages {
    getWebPages {
      name
      url
    }
  }
`
