import api from '@utils/api'

const AccountService = {
  getDynamicNav: (url: string) =>
    api.doFetch(url, {
      usePassedInURL: true,
    }),
  logout: () => api.doFetch('logout'),
}

export default AccountService
