import { AccountState } from '@utils/account/reducer'
import { getAccount } from '@utils/account/selectors'
import { NavigationState, getNavigation } from '@utils/navigation/navigation.utils'

export interface AppStateProps {
  account: AccountState
  navigation: NavigationState
  token: string
}

function mapStateToProps(state: any): AppStateProps {
  return { account: getAccount(state), navigation: getNavigation(state), token: state?.account?.account?.token }
}

export default mapStateToProps
