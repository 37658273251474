import { TFunction } from 'i18next'

import {
  AssetPickerTableType,
  renderDefaultLocationColumn,
  renderDefaultNameColumn,
  renderDefaultRelativeDateColumn,
  renderDefaultTagsColumn,
} from '@complex/AssetPickerModal/Components/Table/AssetPickerTable.utils'
import { ItemDtoRow } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { TABLEV2_CELL_PADDING } from '@components/TableV2/utils/commonUtils'
import { allEmailTemplatesFilter } from '@utils/filter'

import { renderEmailNameCell } from '../EmailPickerModal/utils/EmailPicker.tables.utils'

const rootClass = 'email-templates-picker-modal'

export const renderTableColumns = (t: TFunction): ColumnDefWithAdditionalProps<ItemDtoRow, any>[] => [
  ...[renderDefaultNameColumn(t('Email Title'), '', rootClass, (original, search, rootClass) => renderEmailNameCell(original, search, rootClass, t))],
  ...[renderDefaultTagsColumn(AssetPickerTableType.DEFAULT, t('Tags'))],
  ...[renderDefaultRelativeDateColumn(AssetPickerTableType.DEFAULT, t('Last Updated'), 'lastUpdated')],
]

const firstCellWidth = 360 - TABLEV2_CELL_PADDING * 2

export const renderSearchTableColumns = (search: string, folders: FolderData[], t: TFunction): ColumnDefWithAdditionalProps<ItemDtoRow, any>[] => [
  ...[
    {
      ...renderDefaultNameColumn(t('Email Title'), search, rootClass, (original, search, rootClass) =>
        renderEmailNameCell(original, search, rootClass, t)
      ),
      minSize: firstCellWidth,
      maxSize: firstCellWidth,
    },
  ],
  ...[
    {
      ...renderDefaultLocationColumn(AssetPickerTableType.SEARCH, t('Location'), folders, t(allEmailTemplatesFilter.name)),
      minSize: 185,
      maxSize: 185,
    },
  ],
  ...[renderDefaultTagsColumn(AssetPickerTableType.SEARCH, t('Tags'))],
  ...[
    {
      ...renderDefaultRelativeDateColumn(AssetPickerTableType.SEARCH, t('Last Updated'), 'lastUpdated'),
      minSize: 150,
      maxSize: 150,
    },
  ],
]
