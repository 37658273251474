import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { StandardField } from '@graphql/types/microservice/list-types'
import { Program } from '@graphql/types/query-types'
import { FieldSetOptions } from '@src/pages/programs/dashboard/components/ProgramSteps/components/ChangeFieldStepDetail/ChangeFieldStepDetail.constants'
import { hasSourceList } from '@src/pages/programs/upgradeManager/components/steps/ChangeFieldValueUpgradeStep/ChangeFieldValueUpgradeStep'
import {
  UpgradedProgramAppendToSegmentStep,
  UpgradedProgramRecordSetStep,
  UpgradedProgramSendStep,
  UpgradedProgramStep,
  UpgradedSourceUpgradeStepType,
  UpgradedStepOperation,
  UpgradedStepSources,
} from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'
import { UpgradedStepsType } from '@src/pages/programs/upgradeManager/helper/UpgradeManager.helper'
import { ProgramStepType } from '@utils/program/program.constants'

const sourceUpgradeStepValidation = (step: UpgradedProgramStep, upgradedStepSources: UpgradedStepSources): boolean => {
  const sourceUpgradeStep = step as UpgradedSourceUpgradeStepType

  return (
    sourceUpgradeStep.removed ||
    Object.values(upgradedStepSources[sourceUpgradeStep?.stepId ?? ''] ?? {}[sourceUpgradeStep?.upgradeId] ?? {}).some(
      (source) => source.id === sourceUpgradeStep.srcId
    )
  )
}

const appendToSegmentStepValidation = (step: UpgradedProgramStep, upgradedStepSources: UpgradedStepSources): boolean => {
  const appendToSegmentStep = step as UpgradedProgramAppendToSegmentStep

  return (
    appendToSegmentStep.removed ||
    Object.values(upgradedStepSources[appendToSegmentStep?.stepId ?? ''] ?? {}[appendToSegmentStep?.upgradeId] ?? {}).some(
      (source) => source.id === appendToSegmentStep.listId
    )
  )
}

const fieldSetStepValidation = (
  step: UpgradedProgramStep,
  _: UpgradedStepSources,
  program: Program,
  standardFields: StandardField[],
  actonContactFieldsOptions?: SelectV2SingleOption[]
): boolean => {
  const changeFieldValueStep = step as UpgradedProgramRecordSetStep
  if (changeFieldValueStep.removed) {
    return true
  }

  const isNewFieldValid = (newField: UpgradedStepOperation['newField']) => {
    const isMatchingUCLField = actonContactFieldsOptions && actonContactFieldsOptions.some(({ label }) => label === newField?.name)
    const isMatchingStandardField = standardFields.some(({ label }) => label === newField?.name)
    return !!newField?.name && (!newField?.isNew || (newField?.isNew && !isMatchingUCLField && !isMatchingStandardField))
  }

  const checkOperations = (operations: UpgradedStepOperation[]) =>
    operations.filter((operation) => operation.fieldName !== FieldSetOptions.NOOP).every(({ newField }) => isNewFieldValid(newField))

  const areOperationsValid = checkOperations(changeFieldValueStep.operations)
  const areConditionsValid = changeFieldValueStep.conditions.every(
    (condition) => (hasSourceList(condition, program) ? condition.upgraded : true) && checkOperations(condition.operations)
  )

  return areOperationsValid && areConditionsValid
}

const sendEmailStepValidation = (step: UpgradedProgramStep, upgradedStepSources: UpgradedStepSources, program: Program): boolean => {
  const sendEmailStep = step as UpgradedProgramSendStep

  return (
    sendEmailStep.removed ||
    sendEmailStep.sendChoices.every((choice) =>
      Object.values(upgradedStepSources[sendEmailStep?.stepId ?? ''] ?? {}[choice.choiceId ?? ''] ?? {}).some(
        (source) => source.id === choice.newSrcId || !program.sources?.some((source) => source.id === choice.srcId)
      )
    )
  )
}

export const getStepValidation: {
  [key in UpgradedStepsType | ProgramStepType.COPY]: (
    step: UpgradedProgramStep,
    upgradedStepSources: UpgradedStepSources,
    program: Program,
    standardFields: StandardField[],
    actonContactFieldsOptions?: SelectV2SingleOption[]
  ) => boolean
} = {
  [ProgramStepType.BRANCH]: sourceUpgradeStepValidation,
  [ProgramStepType.APPEND_TO_SEGMENT]: appendToSegmentStepValidation,
  [ProgramStepType.COPY]: appendToSegmentStepValidation,
  [ProgramStepType.FIELD_SET]: fieldSetStepValidation,
  [ProgramStepType.SEND]: sendEmailStepValidation,
  [ProgramStepType.WAIT_UNTIL_IN_SEGMENT]: sourceUpgradeStepValidation,
}
