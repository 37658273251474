import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import classNames from 'classnames'
import { bindActionCreators, compose } from 'redux'

import { TopBarSearch } from '@src/components/TopBar/components/Search/TopBarSearch'
import actions, { AccountActions } from '@utils/account/actions'
import iFrameActions, { FrameViewActions } from '@utils/iframe/state/actions'
import mapStateToProps, { NavigationStateProps } from '@utils/navigation/state/mapStateToProps'
import windowUtils from '@utils/window'

import ForethoughtWidget from './components/ForethoughtWidget/ForethoughtWidget'
import NewFeatureToggle from './components/NewFeatureToggle/NewFeatureToggle'
import ProfileDropDown from './components/ProfileDropDown'

import './topBar.css'

type AllProps = NavigationStateProps & AccountActions & FrameViewActions
const rootClass = 'top-bar'

export class TopBar extends PureComponent<AllProps> {
  componentDidUpdate(props: AllProps) {
    if (props.account.language === null) {
      const lang = windowUtils.getLocalStorageItem('i18nextLng')
      if (lang) {
        props.setLanguage(lang)
      }
    }
  }

  render() {
    const {
      account,
      navigation: { visible },
      setLanguage,
      logOut,
      setTheme,
      setPendingIFrameRequest,
      setIframeVisibility,
    } = this.props

    if (!this.props.account.accountSettings) return null

    const { enableForethoughtWidget, userSettings, contactSearch } = account.accountSettings || {}

    return (
      <div
        className={classNames(`${rootClass}`, 'no-print', {
          [`${rootClass}--hidden`]: !visible,
        })}
      >
        <TopBarSearch
          setIframeVisibility={setIframeVisibility}
          setPendingIFrameRequest={setPendingIFrameRequest}
          hasSearch={!userSettings?.userDesktop}
          hasContactSearch={contactSearch ?? false}
        />
        <NewFeatureToggle />
        {enableForethoughtWidget && <ForethoughtWidget />}
        <ProfileDropDown
          setLanguage={setLanguage}
          account={account}
          setTheme={setTheme}
          logOut={logOut}
          setPendingIFrameRequest={setPendingIFrameRequest}
        />
      </div>
    )
  }
}

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: any) => bindActionCreators({ ...actions, ...iFrameActions }, dispatch)

export default compose(connect(mapStateToProps, mapDispatchToProps))(TopBar)
