import React, { ComponentType, FC, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import classNames from 'classnames'
import { bindActionCreators, compose } from 'redux'

import { useApolloClient } from '@apollo/client'
import { BodySection, getMessagePickerModalUtil, MessagePickerModalUtils, MessageType } from '@complex/MessagePickerModal/messagePickerModalUtils'
import Button, { ButtonType } from '@components/Button'
import ChooserScreen, { ChooserButton } from '@components/ChooserScreen'
import FolderListPicker, { FoldersState } from '@components/FolderListPicker/FolderListPicker'
import { LegacyBanner } from '@components/LegacyBanner/LegacyBanner'
import Loader from '@components/Loader/Loader'
import MessagePicker, { MessageFolderState, MessagesState } from '@components/MessagePicker/MessagePicker'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Select from '@components/Select/Select'
import StatusToast from '@components/StatusToast/StatusToast'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { getUUID, useTranslation } from '@const/globals'
import createNewBlankMessage from '@graphql/mutations/createNewBlankMessage'
import messageListingQuery from '@graphql/queries/messageListing'
import programMessagesQuery from '@graphql/queries/programMessages'
import scheduledMessagesQuery from '@graphql/queries/scheduledMessages'
import sentMessageDatesQuery from '@graphql/queries/sentMessageDates'
import sentMessagesQuery from '@graphql/queries/sentMessages'
import { CreateNewBlankMessageMutation, CreateNewBlankMessageMutationVariables } from '@graphql/types/mutation-types'
import {
  MessageListingQuery,
  MessageListingQueryVariables,
  ProgramMessagesQuery,
  ProgramMessagesQueryVariables,
  ProgramMessage,
  ScheduledMessagesQuery,
  ScheduledMessagesQueryVariables,
  SentMessageDatesQuery,
  SentMessageDatesQueryVariables,
  SentMessagesQuery,
  SentMessagesQueryVariables,
} from '@graphql/types/query-types'
import { Folder, MessageFolder } from '@interface/foldersLists/Folder'
import { Message } from '@interface/messages/Message'
import { useGetFeatureFlag } from '@utils/account/account.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import { SHOULD_FILL_MESSAGE_ID_STORAGE_KEY, PROGRAM_MESSAGE_STORAGE_KEY } from '@utils/composer/settings.constants'
import eventHook from '@utils/hooks/useEvent'
import mapStateToProps, { EditProgramStateProps } from '@utils/program/mapStateToProps'
import { getProgramMessageName } from '@utils/program/program'
import editProgramActions, { EditProgramActions } from '@utils/program/programActions'
import { SPACING_UNIT } from '@utils/sizes'
import { setLocalStorageItem } from '@utils/window'

import './messagePickerModal.css'
export interface Props {
  isOpen: boolean
  programId?: string
  programName?: string
  dataTest?: string
  multiSelect?: boolean
  closeModal(): void
  onSubmitMessage(messages: ProgramMessage[]): void
  messagePickerModalUtil?: MessagePickerModalUtils
  allowedMessageCategories?: MessageType[]
  programWillOwnMessage?: boolean
}
export interface State {
  messageType?: MessageType
  folders?: FoldersState
  messageFolders?: MessageFolderState
  messages?: MessagesState
  currentFolder?: Folder
  messageListings?: { [key: string]: Message[] }
  messageFolderListings?: { [key: string]: MessageFolder[] }
  selectedMessages?: Message[]
  previewingMessage: boolean
  previewMessage?: Message
  previewHeight: number
  previewWidth: number
}
const rootClass = 'message-picker-modal'
type AllProps = Props & EditProgramActions & EditProgramStateProps
export const MessagePickerModal: FC<AllProps> = (props: AllProps) => {
  const {
    allowedMessageCategories,
    onSubmitMessage,
    closeModal,
    clearProgramMessage,
    programId,
    programName,
    isOpen,
    dataTest = 'message-picker-modal',
    // When using MessagePicker through the Modal, default to true
    multiSelect = true,
  } = props
  const hasReactWrapper = useGetFeatureFlag('hasReactWrapper')
  const { t } = useTranslation()
  const { enableEmailDraftsReact, hasEmailTemplatesReact, gaLegacyEmail } = useAccountSettings()
  const [state, setState] = useState<State>({
    previewingMessage: false,
    previewHeight: 0,
    previewWidth: 0,
  })
  const [showAddError, setShowAddError] = useState('')
  const client = useApolloClient()
  const message = eventHook.useEvent('message') as any
  const messagePickerModalUtils = props.messagePickerModalUtil ?? getMessagePickerModalUtil(props, state, setState, t)

  useEffect(() => {
    if (!message) return
    if (message.data?.actonPreviewMessageWidth) {
      setState({
        ...state,
        previewWidth: message.data?.actonPreviewMessageWidth,
        previewHeight: message.data?.height,
      })
    } else if (message.data?.actonPreviewMessageHeight) {
      setState({
        ...state,
        previewHeight: message.data?.actonPreviewMessageHeight,
      })
    }
  }, [message])

  useEffect(() => {
    if (state.messageType && !state.currentFolder && programId) {
      if ([MessageType.BLANK, MessageType.BEE_BLANK].includes(state.messageType)) {
        const isBeeBlank = state.messageType === MessageType.BEE_BLANK

        client
          .mutate<CreateNewBlankMessageMutation, CreateNewBlankMessageMutationVariables>({
            mutation: createNewBlankMessage,
            variables: {
              programId,
              beeComposer: isBeeBlank,
            },
          })
          .then((data) => {
            messagePickerModalUtils.loadBlankMessageSuccess(data, hasReactWrapper, isBeeBlank)
            if (data.data && isBeeBlank) {
              setLocalStorageItem(SHOULD_FILL_MESSAGE_ID_STORAGE_KEY, data.data.createNewBlankMessage.id)
              setLocalStorageItem(
                PROGRAM_MESSAGE_STORAGE_KEY,
                JSON.stringify({
                  programTitle: programName,
                  programSubject: `Thank you for signing up for ${programName}`,
                  programId,
                })
              )
            }
          })
          .catch((error) => messagePickerModalUtils.loadBlankMessageError(error))
      } else {
        setState((state) => ({
          ...state,
          folders: { loading: true },
          messages: undefined,
          messageFolders: undefined,
          messageListings: undefined,
          messageFolderListings: undefined,
        }))
      }
    }
  }, [state.messageType])

  useEffect(() => {
    if (state.messageType && state.folders?.loading) {
      if (state.messageType === MessageType.SENT) {
        client
          .query<SentMessageDatesQuery, SentMessageDatesQueryVariables>({
            query: sentMessageDatesQuery,
            fetchPolicy: 'network-only',
          })
          .then((data) => messagePickerModalUtils.loadSentMessagesDatesSuccess(data))
          .catch((error) => messagePickerModalUtils.loadFoldersError(error))
      } else if (state.messageType === MessageType.SCHEDULED) {
        client
          .query<ScheduledMessagesQuery, ScheduledMessagesQueryVariables>({
            query: scheduledMessagesQuery,
            fetchPolicy: 'network-only',
          })
          .then((data) => messagePickerModalUtils.loadScheduledMessagesSuccess(data))
          .catch((error) => messagePickerModalUtils.loadFoldersError(error))
      } else if (state.messageType === MessageType.PROGRAM) {
        client
          .query<ProgramMessagesQuery, ProgramMessagesQueryVariables>({
            query: programMessagesQuery,
            fetchPolicy: 'network-only',
          })
          .then((data) => messagePickerModalUtils.loadProgramMessagesSuccess(data))
          .catch((error) => messagePickerModalUtils.loadFoldersError(error))
      } else {
        client
          .query<MessageListingQuery, MessageListingQueryVariables>({
            query: messageListingQuery,
            fetchPolicy: 'network-only',
            variables: {
              type: state.messageType,
            },
          })
          .then((data) => messagePickerModalUtils.loadMessageListingsSuccess(data, enableEmailDraftsReact, hasEmailTemplatesReact))
          .catch((error) => messagePickerModalUtils.loadFoldersError(error))
      }
    }
  }, [state.folders])

  useEffect(() => {
    if (state.currentFolder) {
      if (state.messageType === MessageType.SCHEDULED) {
        return
      }
      if (state.messageType === MessageType.SENT) {
        setState({
          ...state,
          messages: {
            loading: true,
          },
        })
      } else if (state.messageListings) {
        setState({
          ...state,
          messages: {
            messages: state.messageListings[state.currentFolder.id],
          },
        })
      } else if (state.messageFolderListings) {
        setState({
          ...state,
          messageFolders: {
            folders: state.messageFolderListings[state.currentFolder.id],
          },
        })
      }
    }
  }, [state.currentFolder])

  useEffect(() => {
    if (state.messages?.loading && state.currentFolder && state.messageType === MessageType.SENT) {
      client
        .query<SentMessagesQuery, SentMessagesQueryVariables>({
          query: sentMessagesQuery,
          fetchPolicy: 'network-only',
          variables: {
            dateRangeCode: `${state.currentFolder.id}`,
          },
        })
        .then((data) => messagePickerModalUtils.loadSentMessagesSuccess(data))
        .catch((error) => messagePickerModalUtils.loadMessagesError(error))
    }
  }, [state.messages])

  useEffect(() => {
    const {
      editProgram: { createdMessage },
    } = props
    if (createdMessage) {
      if (createdMessage.message && state.selectedMessages) {
        const submittableMessages = state.selectedMessages.map((msg) => {
          const newMessage = createdMessage?.message?.results?.find((newMsg) => newMsg.originalId === msg.id)
          return {
            id: newMessage ? newMessage.newId : msg.id,
            name: getProgramMessageName(msg),
            isNewComposer: false,
            beeComposer: msg.beeComposer,
            __typename: 'ProgramMessage',
          } as ProgramMessage
        })
        onSubmitMessage(submittableMessages)

        clearProgramMessage()
      } else if (createdMessage.error) {
        closeModal()
        setShowAddError(createdMessage.error)
        clearProgramMessage()
      }
    }
  }, [props.editProgram.createdMessage])

  const toggleMessagePreview = (previewMessage?: Message) => {
    setState({
      ...state,
      previewingMessage: !state.previewingMessage,
      previewMessage,
    })
  }

  const saveMessagesToProgram = () => {
    const { programId = 'Default' } = props
    if (state.selectedMessages) {
      if (props.programId && props.programWillOwnMessage) {
        props.createProgramMessageRequest({
          client,
          programId,
          messageIds: state.selectedMessages.map((msg) => msg.id),
        })
      } else {
        const msgs = state.selectedMessages.map((selMsg) => {
          return {
            id: selMsg.id,
            name: getProgramMessageName(selMsg),
            isNewComposer: false,
            beeComposer: selMsg.beeComposer,
            __typename: 'ProgramMessage',
          } as ProgramMessage
        })
        onSubmitMessage(msgs)
      }
    }
  }

  const iframeId = getUUID()
  const bodySection = messagePickerModalUtils.getBodySection()
  const closeButton = bodySection === BodySection.PICKER ? { closeButton: closeModal } : {}
  const isFoldersSection = bodySection === BodySection.FOLDERS

  return (
    <>
      {showAddError !== '' && <StatusToast message={showAddError} closeStatus={() => setShowAddError('')} />}
      <Modal
        isOpen={isOpen}
        useCustomScrollAreas={`${rootClass}__custom-scroll-area`}
        noPadding={state.messageType !== undefined}
        header={
          <ModalHeader {...closeButton} headerType={bodySection === BodySection.PICKER ? ModalHeaderType.Form : ModalHeaderType.List}>
            {t('Add Message To Program')}
          </ModalHeader>
        }
        fitModalBody={{
          id: iframeId,
          suggestedHeight: state.previewHeight,
          suggestedWidth: state.previewWidth,
          verticalPadding: SPACING_UNIT * 4,
          horizontalPadding: SPACING_UNIT * 4,
        }}
      >
        <ModalBody
          className={classNames(`${rootClass}__body`, {
            [`${rootClass}__body--picker`]: state.messageType !== undefined,
            [`${rootClass}__body--preview`]: state.previewingMessage,
            [`${rootClass}__body--folders`]: isFoldersSection,
            [`${rootClass}__body--minimal-messages`]:
              bodySection === BodySection.PICKER && allowedMessageCategories && allowedMessageCategories.length <= 3,
          })}
        >
          {props.editProgram.createdMessage?.loading && <Loader center />}
          {bodySection === BodySection.LOADING && <Loader center />}
          {bodySection === BodySection.PICKER && !state.previewingMessage && (
            <>
              <ChooserScreen className={`${rootClass}__chooser`}>
                <ChooserButton
                  render={programId !== undefined && (!allowedMessageCategories || allowedMessageCategories.includes(MessageType.BLANK))}
                  dataTest={`${dataTest}-blank-button`}
                  onClick={() => {
                    setState({
                      ...state,
                      messageType: MessageType.BEE_BLANK,
                      currentFolder: undefined,
                    })
                  }}
                  icon={<Svg name={SvgNames.blankMessage} type={SvgType.VERY_LARGE_ICON} />}
                  title={t('Blank Message')}
                />
                <ChooserButton
                  render={!allowedMessageCategories || allowedMessageCategories.includes(MessageType.SCHEDULED)}
                  dataTest={`${dataTest}-scheduled-button`}
                  onClick={() => {
                    setState({
                      ...state,
                      messageType: MessageType.SCHEDULED,
                      currentFolder: undefined,
                    })
                  }}
                  icon={<Svg name={SvgNames.clock} type={SvgType.VERY_LARGE_ICON} />}
                  title={t('Scheduled Message')}
                />
                <ChooserButton
                  render={!allowedMessageCategories || allowedMessageCategories.includes(MessageType.TEMPLATE)}
                  dataTest={`${dataTest}-template-button`}
                  onClick={() => {
                    setState({
                      ...state,
                      messageType: MessageType.TEMPLATE,
                      currentFolder: undefined,
                    })
                  }}
                  icon={<Svg name={SvgNames.template} type={SvgType.VERY_LARGE_ICON} />}
                  title={t('Message Template')}
                />
                <ChooserButton
                  render={!allowedMessageCategories || allowedMessageCategories.includes(MessageType.DRAFT)}
                  dataTest={`${dataTest}-draft-button`}
                  onClick={() => {
                    setState({
                      ...state,
                      messageType: MessageType.DRAFT,
                      currentFolder: undefined,
                    })
                  }}
                  icon={<Svg name={SvgNames.draft} type={SvgType.VERY_LARGE_ICON} />}
                  title={t('Draft Message')}
                />
                <ChooserButton
                  render={!allowedMessageCategories || allowedMessageCategories.includes(MessageType.SENT)}
                  dataTest={`${dataTest}-sent-button`}
                  onClick={() => {
                    setState({
                      ...state,
                      messageType: MessageType.SENT,
                      currentFolder: undefined,
                    })
                  }}
                  icon={<Svg name={SvgNames.airplaneOutline} type={SvgType.VERY_LARGE_ICON} />}
                  title={t('Sent Message')}
                />
                <ChooserButton
                  render={!allowedMessageCategories || allowedMessageCategories.includes(MessageType.PROGRAM)}
                  dataTest={`${dataTest}-program-button`}
                  onClick={() => {
                    setState({
                      ...state,
                      messageType: MessageType.PROGRAM,
                      currentFolder: undefined,
                    })
                  }}
                  icon={<Svg name={SvgNames.automation} type={SvgType.VERY_LARGE_ICON} />}
                  title={t('Program Message')}
                />
              </ChooserScreen>
              {gaLegacyEmail && (
                <LegacyBanner
                  className={`${rootClass}__legacy-banner`}
                  isSingleButton
                  onClick={() =>
                    setState({
                      ...state,
                      messageType: MessageType.BLANK,
                      currentFolder: undefined,
                    })
                  }
                />
              )}
            </>
          )}
          {isFoldersSection && (
            <FolderListPicker
              dataTest={`${dataTest}-folder-list-picker`}
              customScrollClass={`${rootClass}__custom-scroll-area`}
              folders={state.folders}
              currentFolderId={state.currentFolder?.id}
              changeFolder={(folder: Folder) => {
                setState({
                  ...state,
                  currentFolder: folder,
                })
              }}
              folderHeader={
                <Select
                  dataTest={`${dataTest}-message-change-select`}
                  defaultValue={state.messageType}
                  onChange={(event) => {
                    setState({
                      ...state,
                      messageType: event.target.value as MessageType,
                      currentFolder: undefined,
                    })
                  }}
                >
                  {messagePickerModalUtils.getMessageTypeOptions().map((listCategory) => (
                    <option key={listCategory.type} value={listCategory.type}>
                      {t(listCategory.text)}
                    </option>
                  ))}
                </Select>
              }
            >
              <MessagePicker
                messages={state.messages}
                folders={state.messageFolders}
                isProgramMessagePicker={state.messageType === MessageType.PROGRAM}
                customScrollClass={`${rootClass}__custom-scroll-area`}
                customColumn={messagePickerModalUtils.getCustomColumn()}
                multiSelect={multiSelect}
                selectedMessages={state.selectedMessages ?? []}
                updateSelectedMessages={messagePickerModalUtils.updateSelectedMessages}
                toggleMessagePreview={toggleMessagePreview}
              />
            </FolderListPicker>
          )}
          {bodySection === BodySection.PREVIEW && state.previewMessage && (
            <div className={`${rootClass}__message-preview`}>
              {(state.previewHeight === 0 || state.previewWidth === 0) && <Loader center />}
              <Button buttonType={ButtonType.FLOAT} onClick={() => toggleMessagePreview()} title={t('Close Preview')}>
                <Svg name={SvgNames.close} type={SvgType.LARGER_ICON} />
              </Button>
              <iframe
                title={'message-preview'}
                id={iframeId}
                className={`${rootClass}__message-preview-iframe`}
                src={state.previewMessage.previewUrl}
              />
            </div>
          )}
        </ModalBody>
        {isFoldersSection && (
          <ModalFooter footerType={ModalFooterType.List}>
            <Button buttonType={ButtonType.TERTIARY} onClick={closeModal} dataTest={`${dataTest}-close-button`}>
              {t('Cancel')}
            </Button>
            <Button
              disabled={!state.selectedMessages || state.selectedMessages.length === 0}
              buttonType={ButtonType.PRIMARY}
              dataTest={`${dataTest}-save-button`}
              onClick={() => {
                if (!state.selectedMessages) return
                saveMessagesToProgram()
              }}
            >
              {t('Submit')}
            </Button>
          </ModalFooter>
        )}
      </Modal>
    </>
  )
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({ ...editProgramActions }, dispatch)

export default compose<ComponentType<Props>>(connect(mapStateToProps, mapDispatchToProps))(MessagePickerModal)
