import React, { FC } from 'react'

import classNames from 'classnames'

import { LinkTextButton } from '@components/LinkTextButton/LinkTextButton'
import Popover from '@components/Popover/Popover'
import Svg, { SvgNames, SvgType } from '@components/Svg/index'
import Toggle from '@components/Toggle/Toggle'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './DropdownWithToggles.css'

export interface DropdownWithToggle {
  id: string
  labelEnabled: string
  labelDisabled: string
  description?: string
  value: boolean
}

interface DropdownWithTogglesProps {
  name: string
  currentValue: string
  moreInfoLink?: string
  toggles: DropdownWithToggle[]
  onChange: (toggleId: string, value: boolean) => void
  className?: string
  dataTest?: string
}

const rootClass = 'dropdown-with-toggles'

const DropdownWithToggles: FC<DropdownWithTogglesProps> = (props: DropdownWithTogglesProps) => {
  const { name, currentValue, toggles, moreInfoLink, onChange, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = React.useState(false)

  const renderMoreInfoLink = (link: string) => (
    <div className={`${rootClass}__more-info`}>
      <LinkTextButton link={link} text={t('More info')} hideIconLeft />
    </div>
  )

  const renderToggle = (toggle: DropdownWithToggle) => (
    <div className={`${rootClass}__toggle`} key={toggle.id}>
      <div className={`${rootClass}__toggle-left`}>
        <Typography text={toggle.value ? toggle.labelEnabled : toggle.labelDisabled} />
        <Typography text={toggle.description} type={TextType.BODY_TEXT_SMALL_LIGHT} />
      </div>
      <div className={`${rootClass}__toggle-right`}>
        <Toggle isOn={toggle.value} onToggle={() => onChange(toggle.id, !toggle.value)} dataTest={`${dataTest}-toggle-${toggle.id}`} />
      </div>
    </div>
  )

  const trigger = (
    <div className={`${rootClass}__baseline`}>
      <Typography text={t(name)} weight={TextWeight.BOLD} />
      <Typography text={currentValue} />
      <Svg name={SvgNames.caretFillDown} type={SvgType.ICON} />
    </div>
  )

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Popover trigger={trigger} isOpen={isOpen} onOpenChange={(open) => setIsOpen(open)}>
        <div className={`${rootClass}__toggles`}>
          {toggles.map((toggle) => renderToggle(toggle))}
          {moreInfoLink && renderMoreInfoLink(moreInfoLink)}
        </div>
      </Popover>
    </div>
  )
}

export default DropdownWithToggles
