import React, { FC, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import ActivityGaugeChart from '@components/ActivityGaugeChart/ActivityGaugeChart'
import { getTrackColor } from '@components/ActivityGaugeChart/ActivityGaugeChartUtils'
import { DataCardHeaderOptionType } from '@components/DataCard/components/DataCardHeaderWithOptions/DataCardHeaderWithOptions'
import DividedContentDataCard from '@components/DividedContentDataCard/DividedContentDataCard'
import { LinkTextButton, LinkTextButtonSize } from '@components/LinkTextButton/LinkTextButton'
import SimpleLineChart from '@components/SimpleLineChart/SimpleLineChart'
import SingleContentDataCard from '@components/SingleContentDataCard/SingleContentDataCard'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { ChartFieldData } from '@const/Chart.constants'
import { rootContext, useTranslation } from '@const/globals'
import { Segment } from '@graphql/types/microservice/ml-audience-types'
import { TotalContactsPeriodValues } from '@src/pages/ContactSegments/components/DataCardsContainer/utils/DataCardsContainerConstants'
import {
  getActiveContactsUtils,
  getTotalContactsUtils,
  renderActiveContactsLabelTooltip,
  renderTotalContactsLabelTooltip,
} from '@src/pages/ContactSegments/components/DataCardsContainer/utils/DataCardsContainerUtils'
import { useContactSegmentsRequest } from '@src/pages/ContactSegments/GraphQL/ContactSegments.graphQL'
import { useAIAudienceInsights } from '@src/pages/RecommendedSegments/utils/useAIAudienceInsights'
import { ContactSegmentsSession } from '@utils/contactSegments/contactSegments.utils'
import { buildContactsPeriodsUtils, DATACARD_LIST, TotalContactsPeriodType } from '@utils/contactSegments/dataCards.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { formatNumber } from '@utils/numbers'
import { getItem, setItem } from '@utils/sessionStorage'

import './DataCardsContainer.css'

interface Props {
  className?: string
  dataTest?: string
  onClick?: (dataCardId: DATACARD_LIST) => void
}

interface ActiveContacts {
  actualValue: number
  total: number
  isCPMAccount: boolean | undefined
  remainingDays: number
}

export interface TotalContacts {
  name: string[]
  y: number[]
}

export interface State {
  activeContacts: ActiveContacts
  totalContacts: { chartData: ChartFieldData[]; labelValue: number; labelPercentage: number; description: string }
  loading: boolean
  segmentsInsights?: Segment[]
}

const rootClass = 'data-cards-container'

const DataCardsContainer: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '', onClick } = props
  const [state, setState] = useState<State>({
    activeContacts: { actualValue: 0, total: 0, isCPMAccount: undefined, remainingDays: 0 },
    totalContacts: { chartData: [{ name: '', y: 0 }], labelValue: 0, labelPercentage: 0, description: '' },
    loading: true,
  })
  const { activeContacts, totalContacts, segmentsInsights } = state

  const { t } = useTranslation()

  const { isRegistered, loading: aiAudienceLoading } = useAIAudienceInsights()

  const { actualValue = 0, isCPMAccount, total = 1 } = activeContacts
  const { chartData, description, labelPercentage, labelValue } = totalContacts
  const noContacts = total === 0
  const activeContactsPercentage = parseFloat(((actualValue / total) * 100).toFixed(2))
  const { totalContactsAmount, totalContactsGroupBy, totalContactsValue, totalContactsValueOnSelect } = JSON.parse(
    getItem(ContactSegmentsSession.DATACARD_TOTAL_CONTACTS) ?? '{}'
  )

  const { getNewInsights } = useContactSegmentsRequest()
  const { client: segmentClient } = useMicroserviceClient({ serviceName: MicroserviceClients.SEGMENT }) || {}

  useEffect(() => {
    const { amount: totalContactsAmountDefaultValue, groupBy: totalContactsGroupByDefaultValue } =
      TotalContactsPeriodValues[TotalContactsPeriodType.SEVEN_DAYS]
    getActiveContactsUtils(setState, segmentClient)
    getTotalContactsUtils(
      setState,
      segmentClient,
      totalContactsAmount ?? totalContactsAmountDefaultValue,
      totalContactsGroupBy ?? totalContactsGroupByDefaultValue
    )
  }, [])

  useEffect(() => {
    if (!aiAudienceLoading && isRegistered) {
      getNewInsights().then((segments) => {
        if (segments.length) {
          setState((state) => ({ ...state, segmentsInsights: segments }))
        }
      })
    }
  }, [aiAudienceLoading])

  const setTotalContactsPeriod = ({ key, value, valueOnSelect }: DataCardHeaderOptionType) => {
    if (key) {
      const { amount, groupBy } = TotalContactsPeriodValues[key as TotalContactsPeriodType]
      getTotalContactsUtils(setState, segmentClient, amount, groupBy)
      setItem(
        ContactSegmentsSession.DATACARD_TOTAL_CONTACTS,
        JSON.stringify({
          totalContactsAmount: amount,
          totalContactsGroupBy: groupBy,
          totalContactsKey: key as TotalContactsPeriodType,
          totalContactsValue: value,
          totalContactsValueOnSelect: valueOnSelect,
        })
      )
    }
  }
  const buildTotalContactsPeriod = () =>
    buildContactsPeriodsUtils({
      setPeriod: setTotalContactsPeriod,
      t,
      periodKeys: Object.keys(TotalContactsPeriodType),
      value: t('Past 90 days'),
      valueOnSelect: `(${t('90 days')})`,
    })

  const renderActiveContactsLeftContent = useMemo(() => {
    return (
      <div>
        {noContacts ? (
          <Typography
            text={t('Loading contact data now')}
            type={TextType.BODY_TEXT_LARGE}
            weight={TextWeight.BOLD}
            lineHeight={LineHeight.MEDIUM_LARGE}
            inline
          />
        ) : (
          <div className={`${rootClass}__active-contacts-detail`}>
            {(isCPMAccount ? activeContactsPercentage >= 100 : activeContactsPercentage >= 95) ? (
              <Svg className={`${rootClass}__active-contacts-error-icon`} name={SvgNames.warningSolidNoFill} type={SvgType.MEDIUM_LARGE_ICON} />
            ) : (
              activeContactsPercentage >= 90 && (
                <Svg className={`${rootClass}__active-contacts-warning-icon`} name={SvgNames.warningSolidNoFill} type={SvgType.MEDIUM_LARGE_ICON} />
              )
            )}
            <Tooltip
              className={`${rootClass}__active-contacts-detail`}
              trigger={
                <>
                  <Typography text={formatNumber(actualValue)} type={TextType.DATA_CARD_TEXT} weight={TextWeight.BOLD} inline />
                  <Typography text={`/${formatNumber(total)}`} type={TextType.DATA_CARD_TEXT_LIGHT} weight={TextWeight.BOLD} inline />
                </>
              }
            >
              {renderActiveContactsLabelTooltip(t, actualValue, activeContactsPercentage)}
            </Tooltip>
          </div>
        )}
      </div>
    )
  }, [activeContactsPercentage, actualValue, isCPMAccount, noContacts, total])

  const renderActiveContactsRightContent = useMemo(() => {
    return (
      <ActivityGaugeChart
        actualValue={noContacts ? 0 : actualValue}
        total={noContacts ? 1 : total}
        isCPMAccount={isCPMAccount}
        fields={[
          {
            name: isCPMAccount ? 'Monthly Sends Used' : 'Active Contacts Used',
            data: [
              {
                color: getTrackColor(activeContactsPercentage, isCPMAccount),
                radius: '140%',
                innerRadius: '90%',
                y: activeContactsPercentage,
                dataLabels: { shadow: true },
              },
            ] as ChartFieldData[],
          },
        ]}
      />
    )
  }, [activeContactsPercentage, actualValue, isCPMAccount, noContacts, total])

  const renderTotalContactsRightContent = useMemo(() => {
    return (
      <SimpleLineChart
        noContainer
        hideLegend
        disableMenu
        chartWidth={150}
        horizontalAlign={9}
        fields={[
          {
            name: '',
            data: chartData,
            color: '#7B9FED',
          },
        ]}
      />
    )
  }, [chartData])

  const renderTotalContactsLeftContent = useMemo(() => {
    const noContacts = chartData === undefined
    const zeroValue = labelPercentage === 0
    return (
      <div className={classNames(`${rootClass}__total-contacts-left-content`, { [`${rootClass}__total-contacts-left-content-empty`]: noContacts })}>
        {noContacts ? (
          <Typography text={t('No contacts added')} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.BOLD} lineHeight={LineHeight.MEDIUM_LARGE} />
        ) : (
          <>
            <Tooltip
              trigger={
                <Typography
                  text={formatNumber(labelValue)}
                  type={TextType.DATA_CARD_INFO}
                  weight={TextWeight.BOLD}
                  lineHeight={LineHeight.EXTRA_LARGE}
                  inline
                />
              }
            >
              {renderTotalContactsLabelTooltip(t, labelValue, labelPercentage, description ?? totalContactsValueOnSelect)}
            </Tooltip>
            {!zeroValue && (
              <Svg
                className={`${rootClass}__total-contacts-percentage-svg${labelPercentage < 0 ? '-negative' : ''}`}
                name={labelPercentage > 0 ? SvgNames.arrowUpPlain : SvgNames.arrowDownPlain}
                type={SvgType.ICON_MEDIUM}
              />
            )}
            <Typography
              className={`${rootClass}__total-contacts-percentage${labelPercentage < 0 ? '-negative' : ''}`}
              text={`${formatNumber(labelPercentage)}%`}
              type={TextType.BODY_TEXT_SMALL}
              weight={TextWeight.MEDIUM}
              inline
            />
          </>
        )}
      </div>
    )
  }, [chartData, description, labelPercentage, labelValue, totalContactsValueOnSelect])

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <DividedContentDataCard
        className={classNames(
          `${rootClass}__data-card`,
          `${rootClass}__active-contacts`,
          { [`${rootClass}__active-contacts-error`]: activeContactsPercentage >= (isCPMAccount ? 100 : 95) },
          { [`${rootClass}__active-contacts-warning`]: activeContactsPercentage < (isCPMAccount ? 100 : 95) },
          { [`${rootClass}__active-contacts-default`]: activeContactsPercentage < 90 }
        )}
        headerText={activeContacts.isCPMAccount ? 'Monthly Sends' : 'Active Contacts'}
        leftContent={renderActiveContactsLeftContent}
        rightContent={renderActiveContactsRightContent}
        onClick={() => onClick && onClick(activeContacts.isCPMAccount ? DATACARD_LIST.ACTIVE_CONTACTS_CPM : DATACARD_LIST.ACTIVE_CONTACTS)}
      />
      <DividedContentDataCard
        className={classNames(`${rootClass}__data-card`, `${rootClass}__total-contacts`)}
        headerText={'Total Contacts'}
        options={buildTotalContactsPeriod()}
        leftContent={renderTotalContactsLeftContent}
        rightContent={renderTotalContactsRightContent}
        onClick={() => onClick && onClick(DATACARD_LIST.TOTAL_CONTACTS)}
        defaultOption={totalContactsValue ? { value: totalContactsValue, valueOnSelect: totalContactsValueOnSelect } : undefined}
      />
      {segmentsInsights ? (
        <SingleContentDataCard
          className={classNames(`${rootClass}__data-card`, `${rootClass}__engaged-contacts`)}
          headerText={t('{{amount}} New AI recommended audiences', { amount: segmentsInsights.length })}
          image={`${rootContext}/static/image/empty-tag.png`}
          imageVerticalAlign={'bottom'}
          imageHorizontalAlign={'right'}
        >
          <LinkTextButton
            className={`${rootClass}__text-link`}
            text={t('Check them out')}
            link={`${rootContext}/contacts/recommendations?redirect`}
            size={LinkTextButtonSize.LARGE}
            target={'_self'}
            hideIconLeft
            hideIconRight
          />
        </SingleContentDataCard>
      ) : (
        <SingleContentDataCard
          className={classNames(`${rootClass}__data-card`, `${rootClass}__engaged-contacts`)}
          headerText={t('Getting started with Act-On Contacts')}
          image={`${rootContext}/static/image/card-branding-guide.png`}
          imageVerticalAlign={'bottom'}
          imageHorizontalAlign={'right'}
        >
          <TextLink
            className={`${rootClass}__text-link`}
            text={t('Read the guide')}
            link={'https://connect.act-on.com/hc/en-us/articles/4411645287447'}
            size={TextLinkSize.LARGE}
            lineHeight={LineHeight.MEDIUM_LARGE}
          />
        </SingleContentDataCard>
      )}
    </div>
  )
}

export default DataCardsContainer
