import React, { useState } from 'react'

import DropDown from '@components/DropDown'
import { DropDownType } from '@components/DropDown/DropDown'
import DropDownActions, { MenuItem } from '@components/DropDownActions/DropDownActions'

import './TableV2HeaderMenuActions.css'

interface DotMenuProps {
  dataTest?: string
  className?: string
  menuItems: MenuItem[]
  loading?: boolean
}

const rootClass = 'tableV2-header-menu-actions__menu'

export const TableV2HeaderMenuActions = ({ dataTest = rootClass, menuItems = [], loading }: DotMenuProps) => {
  const [openMenu, setOpenMenu] = useState(false)

  return (
    <>
      {!!menuItems?.length && !loading && (
        <div className={rootClass} data-test={dataTest}>
          <DropDown isOpen={openMenu} toggleOpen={setOpenMenu} type={DropDownType.STYLED} hasOverflowIcon>
            <DropDownActions closeDropDown={() => setOpenMenu(false)} menuItems={menuItems} />
          </DropDown>
        </div>
      )}
    </>
  )
}
