import React, { FC, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import PageContainer from '@components/PageContainer/PageContainer'
import PageHeader from '@components/PageHeader'
import { PageHeaderType } from '@components/PageHeadline/PageHeadline'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import StatusToast from '@components/StatusToast/StatusToast'
import Tabs from '@components/TabsAO/TabsAO'
import { useTranslation } from '@const/globals'
import { StatusToastType } from '@interface/StatusToast'
import { useAccountSettings } from '@utils/account/account.utils'
import { MatchParams } from '@utils/types'

import NotificationsContainer from './components/Notifications/NotificationsContainer'
import OverviewContainer from './components/Overview/OverviewContainer'
import SecurityContainer from './components/Security/SecurityContainer'

type ProfileContainerProps = RouteComponentProps<MatchParams>

const rootClass = 'profile'

const DEFAULT_TAB = 'overview'

const ProfileContainer: FC<ProfileContainerProps> = ({ match }: ProfileContainerProps) => {
  const [currentTab, setCurrentTab] = useState<string>(match.params.tab || DEFAULT_TAB)
  const [toast, setToastStatus] = useState<StatusToastType>()

  const { t } = useTranslation()
  const { hideSecurityTabForSalesUser } = useAccountSettings()

  const getTabsData = () => {
    const tabs = [
      {
        index: 'overview',
        label: t('Overview'),
        content: <OverviewContainer setToastStatus={setToastStatus} />,
      },
      {
        index: 'notifications',
        label: t('Notifications'),
        content: <NotificationsContainer setToastStatus={setToastStatus} />,
      },
    ]

    if (!hideSecurityTabForSalesUser) {
      tabs.splice(1, 0, {
        index: 'security',
        label: t('Security'),
        content: <SecurityContainer setToastStatus={setToastStatus} />,
      })
    }

    return tabs
  }

  return (
    <PageContainer className={`${rootClass}__container`}>
      {toast?.showStatus && (
        <StatusToast
          isSuccess={toast.successStatus}
          message={toast.statusMessage}
          title={toast.title}
          closeStatus={() => {
            setToastStatus({ showStatus: false })
          }}
        />
      )}
      <PositionContainer>
        <PageHeader primaryText={t('Profile')} headlineType={PageHeaderType.SUB_HEADER} leftContent />
        <Tabs
          childData={getTabsData()}
          defaultValue={currentTab}
          onChange={(newTab: string) => {
            setCurrentTab(newTab)
          }}
        />
      </PositionContainer>
    </PageContainer>
  )
}

export default ProfileContainer
