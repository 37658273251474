import React from 'react'

import { t } from 'i18next'

import PillLabel from '@components/PillLabel/PillLabel'
import { TableColumn } from '@components/Table/Table'
import Typography from '@components/Typography/Typography'
import { rootContext } from '@const/globals'

const noImage = `${rootContext}/static/image/no-image.png`

export const tableColumns: (rootClass: string) => TableColumn[] = (rootClass) => {
  return [
    {
      Header: 'LOGO NAME',
      accessor: 'title',
      align: 'left',
      className: 'logo-title',
      dataTest: (row: any) => `${rootClass}-${row.dataTest}`,
      Cell: (row) => renderLogoName(row.row.original, t, rootClass),
    },
  ]
}

const renderLogoName = (row: any, t: Function, rootClass: string) => {
  return (
    <>
      <div className={`${rootClass}__img-container`}>
        <img
          className={`${rootClass}__img`}
          src={row.url}
          alt={row.title}
          onError={({ currentTarget }) => {
            const { className } = currentTarget
            currentTarget.onerror = null
            currentTarget.className = `${className} ${className}-invalid-list`
            currentTarget.src = noImage
          }}
        />
      </div>
      <Typography text={row.title} />
      {row.isDefault && <PillLabel text={t('Default')} />}
    </>
  )
}
