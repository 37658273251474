import React, { FC, useContext, useMemo } from 'react'

import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'
import { FieldReplacement } from '@src/pages/programs/upgradeManager/components/steps/ChangeFieldValueUpgradeStep/components/FieldReplacement/FieldReplacement'
import { ConditionType, getConditionText } from '@src/pages/programs/upgradeManager/components/steps/Steps.utils'
import { BaseList, UpgradedStepCondition, UpgradeManagerContext } from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'

import './Condition.css'

interface ConditionProps {
  condition: UpgradedStepCondition
  type: ConditionType
}

const rootClass = 'condition'
const parentCommonClass = 'change-field-value-upgrade-step__common-condition'

export const Condition: FC<ConditionProps> = ({ condition, type }: ConditionProps) => {
  const {
    values: { upgradedCurrentStep, upgradedStepSources },
  } = useContext(UpgradeManagerContext)

  const {
    values: { program },
  } = useContext(ProgramManagerContext)

  const { t } = useTranslation()
  const { localSegmentDetails, messages, sources } = program
  const messageRelated = localSegmentDetails.find((details) => details.id === condition.srcId)

  const conditionText = useMemo(
    () =>
      type === ConditionType.OTHERWISE
        ? t('Otherwise')
        : type === ConditionType.IF
        ? getConditionText(messageRelated, condition?.not)
        : getConditionText(messageRelated, condition?.not).replace('If', 'Else if'),
    [messageRelated]
  )

  const conditionUpgradedSource: BaseList | undefined = upgradedStepSources[upgradedCurrentStep?.stepId ?? '']?.[`source-${condition.conditionId}`]

  let source
  let messageSources
  if (!messageRelated) {
    source = conditionUpgradedSource ?? sources.find((source) => source.id === condition.srcId)
  } else {
    messageSources = messageRelated.idset?.map((messageId) => messages.find((message) => message.id === messageId))
  }

  return (
    <div className={rootClass}>
      <div className={`${rootClass}__conditions`}>
        {messageSources ? (
          messageSources?.map((message, index) => (
            <>
              {index === 0 ? (
                <div key={index} className={`${parentCommonClass}-container`}>
                  <Typography className={parentCommonClass} text={t(conditionText)} lineHeight={LineHeight.MEDIUM} />
                  <TextWithTooltipOnEllip
                    typographyProps={{
                      className: `${parentCommonClass}-source`,
                      text: message?.name,
                      type: TextType.BODY_TEXT_SMALL,
                      lineHeight: LineHeight.MEDIUM_SMALL,
                    }}
                  />
                </div>
              ) : (
                <div key={index} className={`${parentCommonClass}-container`}>
                  <Typography text={t('OR')} lineHeight={LineHeight.MEDIUM} weight={TextWeight.BOLD} />
                  <TextWithTooltipOnEllip
                    typographyProps={{
                      className: `${parentCommonClass}-source`,
                      text: message?.name,
                      type: TextType.BODY_TEXT_SMALL,
                      lineHeight: LineHeight.MEDIUM_SMALL,
                    }}
                  />
                </div>
              )}
            </>
          ))
        ) : (
          <div className={`${parentCommonClass}-container`}>
            <Typography className={parentCommonClass} text={t(conditionText)} lineHeight={LineHeight.MEDIUM} />
            <TextWithTooltipOnEllip
              typographyProps={{
                className: `${parentCommonClass}-source`,
                text: source?.name,
                type: TextType.BODY_TEXT_SMALL,
                lineHeight: LineHeight.MEDIUM_SMALL,
              }}
            />
          </div>
        )}
      </div>
      {condition?.operations?.map((operation, index) => (
        <div className={`${rootClass}__operations`} key={operation.operationId}>
          <FieldReplacement operation={operation} conditionId={condition.conditionId} />
          {index !== condition.operations.length - 1 && <div className={`${rootClass}__divide-line`} />}
        </div>
      ))}
    </div>
  )
}
