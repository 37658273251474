import React, { useContext } from 'react'

import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import ButtonCopy from '@components/ButtonCopy/ButtonCopy'
import CodeBlockV2, { CodeBlockInputType } from '@components/CodeBlockV2/CodeBlockV2'
import Modal, { ModalBody, ModalFooter } from '@components/Modal'
import ModalHeader, { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography, { LineHeight, ModalHeaderStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { downloadHtml } from '@src/pages/EmailComposer/components/EmailComposerPreview/EmailComposerPreview.utils'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { useTranslation } from '@utils/const/globals'

import './ViewHtmlCodeModal.css'

const rootClass = 'view-html-code-modal'

export const ViewHtmlCodeModal = () => {
  const {
    values: {
      preview: { html },
    },
    api: { updatePreview },
  } = useContext(EmailComposerContext)

  const { t } = useTranslation()

  const copyCode = (code: string) => {
    navigator.clipboard.writeText(code)
  }

  const header = (
    <ModalHeader headerType={ModalHeaderType.List} className={`${rootClass}__header`}>
      <div className={`${rootClass}__header-title`}>
        <Svg name={SvgNames.codeIcon} />
        <Typography text={t('View HTML code')} {...ModalHeaderStyle} inline />
      </div>
    </ModalHeader>
  )

  return (
    <Modal className={rootClass} dataTest={rootClass} isOpen header={header}>
      <ModalBody className={`${rootClass}__body`}>
        <div className={`${rootClass}__body-header`}>
          <Typography text={t('View source code')} {...ModalHeaderStyle} inline />
          <div className={`${rootClass}__body-actions`}>
            <Button
              className={`${rootClass}__body-actions-download`}
              iconPosition={ButtonIconPosition.LEFT}
              buttonType={ButtonType.FLOAT_TEAL}
              onClick={() => downloadHtml(html)}
            >
              <Svg name={SvgNames.download} type={SvgType.LARGER_ICON} fill={SvgColor.TEXT_TEAL} />
              <Typography text={t('Download')} type={TextType.LINK} weight={TextWeight.MEDIUM} lineHeight={LineHeight.MEDIUM_LARGE} />
            </Button>
            <ButtonCopy onClick={() => copyCode(html)} />
          </div>
        </div>
        <CodeBlockV2 footer={false} maxHeight={568} content={html} inputType={CodeBlockInputType.FORMATTED_CODE} />
      </ModalBody>
      <ModalFooter>
        <Button buttonType={ButtonType.PRIMARY} onClick={() => updatePreview({ isCodeModalOpen: false })}>
          {t('Done')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
