import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import DropDownActionItem from '@components/DropDownActionItem/DropDownActionItem'
import DropDownActionItemWithTooltip from '@components/DropDownActionItemWithTooltip/DropDownActionItemWithTooltip'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { TextType } from '@components/Typography/Typography'
import { isFunction } from '@utils/utils'

import './DropDownActions.css'

interface Props {
  className?: string
  dataTest?: string
  closeDropDown: () => void
  menuItems: MenuItem[]
  hasEllipsis?: boolean
  type?: DropDownActionsType
}

export interface MenuItem {
  disabled?: ((row?: any) => boolean) | boolean
  tooltipMessage?: ((row?: any) => string) | string
  hasTooltip?: boolean
  icon?: (() => ReactNode) | SvgNames
  onClick: (row?: any) => void
  row?: any
  title?: string
  text: string | ((row?: any) => string)
  topSection?: boolean
  footerSectionLine?: boolean
  hidden?: ((row?: any) => boolean) | boolean
  inFooter?: ((row?: any) => boolean) | boolean
}

export enum DropDownActionsType {
  REGULAR,
  SMALL,
}

const rootClass = 'drop-down-actions'

const DropDownActions: FC<Props> = (props: Props) => {
  const { closeDropDown, menuItems, hasEllipsis = false, type = DropDownActionsType.REGULAR, dataTest = rootClass, className = '' } = props
  const footerActionIdx = menuItems.findIndex((item) => item.inFooter)
  const noneFooterActions = menuItems.filter((item) => !item.inFooter)

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {menuItems
        .filter(({ hidden, row }) => !(isFunction(hidden) ? hidden(row) : hidden))
        .map(
          (
            { hasTooltip, disabled, tooltipMessage, icon: menuIcon, topSection, onClick, row, text: menuText, title: menuTitle, footerSectionLine },
            index
          ) => {
            const icon = menuIcon && (isFunction(menuIcon) ? menuIcon() : <Svg name={menuIcon} type={SvgType.LARGER_ICON} />)
            const text = isFunction(menuText) ? menuText(row) : menuText
            const isDisabled = isFunction(disabled) ? disabled(row) : !!disabled
            const typographyType =
              type === DropDownActionsType.REGULAR ? (isDisabled ? TextType.BODY_TEXT_LIGHT : TextType.BODY_TEXT) : TextType.BODY_TEXT_SMALL_LIGHT
            const itemClassName = isDisabled ? `${rootClass}__item-disabled` : `${rootClass}__item`
            const itemProps = {
              className: itemClassName,
              closeDropDown,
              icon,
              isDisabled,
              text,
              typographyType,
              type,
              onClick,
              row,
              hasEllipsis,
              title: menuTitle,
            }
            return (
              <div
                key={`menu-item-${index}`}
                className={classNames(`${rootClass}__list`, {
                  [`${rootClass}__footer-top-section-line`]: (footerActionIdx === index && !!noneFooterActions.length) || footerSectionLine,
                })}
              >
                {index !== 0 && topSection && <div className={`${rootClass}__top-section`} />}
                {hasTooltip ? (
                  <DropDownActionItemWithTooltip
                    className={`${rootClass}__tooltip`}
                    key={`drop-down-item-${text}`}
                    tooltipMessage={isFunction(tooltipMessage) ? tooltipMessage(row) : tooltipMessage}
                    itemProps={itemProps}
                  />
                ) : (
                  <DropDownActionItem key={`drop-down-item-${text}`} {...itemProps} />
                )}
              </div>
            )
          }
        )}
    </div>
  )
}

export default DropDownActions
