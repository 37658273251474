import { TFunction } from 'i18next'

import { SegmentError } from '@components/SegmentSaveModal/SegmentSaveModal'
import { rootContext } from '@const/globals'
import { SegmentInput, SegmentSyncType } from '@interface/Segment'

export const RATE_SYMBOL = '%'
export enum SaveType {
  SAVE,
  SAVE_AND_CLOSE,
  SAVE_AS_COPY,
}

export enum CombineRowsType {
  AND = 'and',
  OR = 'or',
}

export enum SegmentComposerTab {
  BUILD = 'build',
  SETTINGS = 'settings',
  REVIEW = 'review',
}

export enum SegmentComposerSearchParam {
  BACK_TO = 'backTo',
}

export const segmentInputInitialValues: SegmentInput = {
  labels: [],
  name: '',
  description: '',
  syncType: SegmentSyncType.DYNAMIC,
  isDirectSelect: false,
}

export enum SegmentDefinitionType {
  SEARCH = 'SEARCH',
  DIRECT_SELECT = 'DIRECT_SELECT',
  QUERY = 'QUERY',
}

export const tabByError: { [key in SegmentError]: SegmentComposerTab } = {
  [SegmentError.NAME_REQUIRED]: SegmentComposerTab.SETTINGS,
  [SegmentError.DUPLICATE_NAME]: SegmentComposerTab.SETTINGS,
}

export const SEGMENT_COMPOSER_URL = `${rootContext}/segments/composer`
export const SEGMENT_COMPOSER_EDIT_URL = `${SEGMENT_COMPOSER_URL}/edit`
export const SEGMENT_COMPOSER_CREATE_URL = `${SEGMENT_COMPOSER_URL}/new`

export enum EXPRESSION_EVALUATION_TYPE {
  CUSTOM = 'CUSTOM',
  AND = 'AND',
  OR = 'OR',
  SYSTEM = 'SYSTEM',
}
export type Comparison = TextComparison | NumericComparison | DateComparison | NumericRangeComparison | DateRangeComparison
export enum TextComparison {
  CONTAINS = 'CONTAINS',
  EQUALS = 'EQUALS',
  NOT_CONTAINS = 'NOT_CONTAINS',
  NOT_EQUALS = 'NOT_EQUALS',
  ENDS_WITH = 'ENDS_WITH',
  STARTS_WITH = 'STARTS_WITH',
  NOT_STARTS_WITH = 'NOT_STARTS_WITH',
  NOT_ENDS_WITH = 'NOT_ENDS_WITH',
}

export enum NumericRangeComparison {
  IS_BETWEEN_NUMBERS_EXCLUSIVE = 'IS_BETWEEN_NUMBERS_EXCLUSIVE',
  IS_BETWEEN_NUMBERS_INCLUSIVE = 'IS_BETWEEN_NUMBERS_INCLUSIVE',
}

export enum NumericComparison {
  NUMBER_EQUALS = 'NUMBER_EQUALS',
  NUMBER_NOT_EQUALS = 'NUMBER_NOT_EQUALS',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
  AT_MOST = 'AT_MOST',
  AT_LEAST = 'AT_LEAST',
}

export enum DateRangeComparison {
  IS_BETWEEN_DATES_EXCLUSIVE = 'IS_BETWEEN_DATES_EXCLUSIVE',
  IS_BETWEEN_DATES_INCLUSIVE = 'IS_BETWEEN_DATES_INCLUSIVE',
  IS_BETWEEN_INCLUSIVE = 'IS_BETWEEN_INCLUSIVE',
  IS_BETWEEN_EXCLUSIVE = 'IS_BETWEEN_EXCLUSIVE',
}

export enum DateComparison {
  IS_AFTER = 'IS_AFTER',
  IS_BEFORE = 'IS_BEFORE',
  IS_EQUAL = 'IS_EQUAL',
}

export enum TextEmptyComparison {
  TEXT_EMPTY = 'TEXT_EMPTY',
}

export enum TextNotEmptyComparison {
  TEXT_NOT_EMPTY = 'TEXT_NOT_EMPTY',
}

export enum NextLastDate {
  THE_NEXT = 'THE_NEXT',
  THE_LAST = 'THE_LAST',
}

export enum AfterBeforeDate {
  IS_AFTER = 'IS_AFTER',
  IS_BEFORE = 'IS_BEFORE',
}
export enum SpecificDate {
  BEFORE_SPECIFIC_DATE = 'BEFORE_SPECIFIC_DATE',
  AFTER_SPECIFIC_DATE = 'AFTER_SPECIFIC_DATE',
  SPECIFIC_DATE = 'SPECIFIC_DATE',
  SPECIFIC_MONTH = 'SPECIFIC_MONTH',
  SPECIFIC_QUARTER = 'SPECIFIC_QUARTER',
}
export const CUSTOM_DATE = 'CUSTOM_DATE_RANGE'
export const CUSTOM_RELATIVE_DATE = 'CUSTOM_RELATIVE_RANGE'
export const MORE_THAN = 'MORE_THAN'

export enum ConditionType {
  DATE_VALUE = 'dateValue',
  DATE_RANGE = 'dateRange',
  NUMERIC_VALUE = 'numericValue',
  NUMERIC_RANGE = 'numericRange',
  TEXT_EMPTY = 'textEmpty',
  TEXT_NOT_EMPTY = 'textNotEmpty',
  TEXT_LIST = 'textList',
}

export interface TextListCondition {
  type: ConditionType.TEXT_LIST
  comparison: TextComparison
  values: string[]
}

export interface TextNotEmptyCondition {
  type: ConditionType.TEXT_NOT_EMPTY
}
export interface TextEmptyCondition {
  type: ConditionType.TEXT_EMPTY
}
export interface NumericRangeCondition {
  type: ConditionType.NUMERIC_RANGE
  comparison: NumericRangeComparison
  rangeStart: number
  rangeEnd: number
}

export interface NumericValueCondition {
  type: ConditionType.NUMERIC_VALUE
  comparison: NumericComparison
  value: number
}

export enum RelativeDirection {
  PAST = 'PAST',
  FUTURE = 'FUTURE',
}

export enum RelativeType {
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAYS = 'days',
  WEEKS = 'weeks',
  MONTHS = 'months',
  QUARTERS = 'quarters',
}
export interface RelativePeriod {
  type: RelativeType
  count?: number
  direction: RelativeDirection
}
export interface DateRangeCondition {
  type: ConditionType.DATE_RANGE
  comparison: DateRangeComparison
  rangeStart: Date
  rangeEnd: Date
  dateFormat: string
}

export interface DateValueAbsolute {
  type: 'absolute'
  textValue: Date
}

export interface DateValueRelative {
  type: 'relative'
  relativePeriod: RelativePeriod
}

export const getDateConditionOptions = (t: TFunction) => [
  {
    value: 'IS_EQUAL',
    label: t('Is equal to'),
  },
  {
    value: 'IS_BETWEEN_DATES_INCLUSIVE',
    label: t('Is within'),
  },
  {
    value: 'IS_BEFORE',
    label: t('Is before'),
  },
  {
    value: 'IS_AFTER',
    label: t('Is after'),
  },
  {
    value: 'TEXT_EMPTY',
    label: t('Is empty'),
  },
  {
    value: 'TEXT_NOT_EMPTY',
    label: t('Is not empty'),
  },
]

export const getNumericConditionOptions = (t: TFunction) => {
  return [
    {
      value: 'NUMBER_EQUALS',
      label: t('Equals'),
    },
    {
      value: 'NUMBER_NOT_EQUALS',
      label: t('Does not equal'),
    },
    {
      value: 'IS_BETWEEN_NUMBERS_INCLUSIVE',
      label: t('Is between'),
    },
    {
      value: 'LESS_THAN',
      label: t('Is less than'),
    },
    {
      value: 'GREATER_THAN',
      label: t('Is greater than'),
    },
    {
      value: 'AT_MOST',
      label: t('Is at most'),
    },
    {
      value: 'AT_LEAST',
      label: t('Is at least'),
    },
    {
      value: 'TEXT_EMPTY',
      label: t('Is empty'),
    },
    {
      value: 'TEXT_NOT_EMPTY',
      label: t('Is not empty'),
    },
  ]
}

export const getTextConditionOptions = (t: TFunction) => {
  return [
    {
      label: '1',
      options: [
        {
          value: 'EQUALS',
          label: t('Equals'),
        },
        {
          value: 'CONTAINS',
          label: t('Contains'),
        },
        {
          value: 'STARTS_WITH',
          label: t('Starts with'),
        },
        {
          value: 'ENDS_WITH',
          label: t('Ends with'),
        },
      ],
    },
    {
      label: '2',
      options: [
        {
          value: 'NOT_EQUALS',
          label: t('Does not equal'),
        },
        {
          value: 'NOT_CONTAINS',
          label: t('Does not contain'),
        },
        {
          value: 'NOT_STARTS_WITH',
          label: t('Does not start with'),
        },
        {
          value: 'NOT_ENDS_WITH',
          label: t('Does not end with'),
        },
      ],
    },
    {
      label: '3',
      options: [
        {
          value: 'TEXT_EMPTY',
          label: t('Is empty'),
        },
        {
          value: 'TEXT_NOT_EMPTY',
          label: t('Is not empty'),
        },
      ],
    },
  ]
}

export const getBooleanConditionOptions = (t: TFunction) => [
  {
    value: 'IS_EQUAL',
    label: t('Is equal to'),
  },
  {
    value: 'NOT_EQUALS',
    label: t('Does not equal'),
  },
  {
    value: 'TEXT_EMPTY',
    label: t('Is empty'),
  },
  {
    value: 'TEXT_NOT_EMPTY',
    label: t('Is not empty'),
  },
]

export const getBooleanValueOptions = (t: TFunction) => [
  {
    value: '1',
    label: t('True'),
  },
  {
    value: '0',
    label: t('False'),
  },
]
