import { useApolloClient } from '@apollo/client'
import getFormPublicURLs from '@graphql/queries/getFormPublicURLs'
import getLandingPage from '@graphql/queries/getLandingPage'
import {
  GetFormPublicUrLsQuery,
  GetFormPublicUrLsQueryVariables,
  GetLandingPageQuery,
  GetLandingPageQueryVariables,
} from '@graphql/types/query-types'
import { FetchPromise } from '@utils/types'

export interface GetAssetLinkModalRequests {
  getFormPublicUrlsRequest: (params: GetFormPublicUrLsQueryVariables) => FetchPromise<GetFormPublicUrLsQuery>
  getLandingPageRequest: (params: GetLandingPageQueryVariables) => FetchPromise<GetLandingPageQuery>
}

export const useAssetLinkModalRequests = (): GetAssetLinkModalRequests => {
  const client = useApolloClient()

  const getFormPublicUrlsRequest = (params: GetFormPublicUrLsQueryVariables) => {
    return client.query<GetFormPublicUrLsQuery, GetFormPublicUrLsQueryVariables>({
      query: getFormPublicURLs,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: params,
    })
  }

  const getLandingPageRequest = (params: GetLandingPageQueryVariables) => {
    return client.query<GetLandingPageQuery, GetLandingPageQueryVariables>({
      query: getLandingPage,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: params,
    })
  }

  return {
    getFormPublicUrlsRequest,
    getLandingPageRequest,
  }
}
