import hasToCopySegment from '@graphql/microservices/segment/hasToCopySegment'
import setHasToCopySegment from '@graphql/microservices/segment/setHasToCopySegment'
import {
  HasToCopySegmentQuery,
  HasToCopySegmentQueryVariables,
  SetHasToCopySegmentMutation,
  SetHasToCopySegmentMutationVariables,
} from '@graphql/types/microservice/segment-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export type GetHasToCopySegmentRequestType = () => FetchPromise<HasToCopySegmentQuery>
export type SetHasToCopySegmentRequestType = () => FetchPromise<SetHasToCopySegmentMutation>

export interface ContactSegmentsSegmentServiceRequests {
  getHasToCopySegmentRequest: GetHasToCopySegmentRequestType
  setHasToCopySegmentRequest: SetHasToCopySegmentRequestType
}

export const useContactSegmentsSegmentServiceRequest = (): ContactSegmentsSegmentServiceRequests => {
  const {
    client: { query, mutate },
  } = useMicroserviceClient({ serviceName: MicroserviceClients.SEGMENT })

  const getHasToCopySegmentRequest = () =>
    query<HasToCopySegmentQuery, HasToCopySegmentQueryVariables>({
      query: hasToCopySegment,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    })

  const setHasToCopySegmentRequest = () =>
    mutate<SetHasToCopySegmentMutation, SetHasToCopySegmentMutationVariables>({
      mutation: setHasToCopySegment,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        hasToCopySegment: true,
      },
    })

  return {
    getHasToCopySegmentRequest,
    setHasToCopySegmentRequest,
  }
}
