import { TFunction } from 'i18next'

import { RecommendedSegments, Update } from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'
import {
  GetAllCopiedSegmentsByLegacyExternalIdsRequestParams,
  GetAllCopiedSegmentsByLegacyExternalIdsRequestType,
} from '@src/pages/programs/upgradeManager/graphQL/UpgradeManagerRequests.segment.graphQL'
import { showStatusToast } from '@src/pages/programs/upgradeManager/helper/UpgradeManager.helper'
import { logNewRelicError } from '@utils/new-relic.utils'

interface BaseParams {
  t: TFunction
  update: Update
}

interface GetAllCopiedSegmentsByLegacyExternalIdsUtilsParams extends GetAllCopiedSegmentsByLegacyExternalIdsRequestParams, BaseParams {
  getAllCopiedSegmentsByLegacyExternalIdsRequest: GetAllCopiedSegmentsByLegacyExternalIdsRequestType
}

export const getAllCopiedSegmentsByLegacyExternalIdsUtils = async ({
  getAllCopiedSegmentsByLegacyExternalIdsRequest,
  legacyExternalIds,
  t,
  update,
}: GetAllCopiedSegmentsByLegacyExternalIdsUtilsParams) => {
  try {
    const { data, errors } = await getAllCopiedSegmentsByLegacyExternalIdsRequest({ legacyExternalIds })
    if (!!data?.getAllCopiedSegmentsByLegacyExternalIds) {
      const recommendedSegments = Object.entries(data.getAllCopiedSegmentsByLegacyExternalIds).reduce(
        (acc, [key, value]: any) =>
          value?.length !== 1 ? { ...acc } : { ...acc, [key]: { id: value[0].segment.id, name: value[0].segment.name, size: value[0].size } },
        {} as RecommendedSegments
      )
      update({ recommendedSegments })
    }
    if (errors) {
      logNewRelicError(errors)
      showStatusToast(update, t('AutomatedPrograms.UpgradeManager.Requests.Segment.GetAllCopiedSegmentsByLegacyExternalIds.Error'))
      return {}
    }
  } catch (e) {
    logNewRelicError(e)
    showStatusToast(update, t('AutomatedPrograms.UpgradeManager.Requests.Segment.GetAllCopiedSegmentsByLegacyExternalIds.Error'))
    return {}
  }
}
