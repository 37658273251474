import { gql } from '@apollo/client'

export default gql`
  mutation addUrlGroup($pageId: String!, $name: String!) {
    addUrlGroup(pageId: $pageId, name: $name) {
      id
      name
      publicUrl
      seoFriendlyUrl
      shortUrl
    }
  }
`
