import React, { FC } from 'react'

import AssetPickerModal, { AssetPickerModalBaseProps, AssetPickerModalProps } from '@complex/AssetPickerModal/AssetPickerModal'
import { AssetPickerListingPageProps, AssetPickerSidebarProps, AssetPickerTableProps } from '@complex/AssetPickerModal/Context/AssetPicker.context'
import { ListPageCommonState, RenderSearchColumnsV2, Update } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { useTranslation } from '@const/globals'
import { ItemType } from '@utils/categorization'
import { allLandingPageTemplates } from '@utils/filter'
import { getLandingPagePreviewUrl, landingPageTemplateSubTypes } from '@utils/landingPages'

import { renderSearchTableColumns, renderTableColumns } from './LandingPageTemplatesPickerModal.tables'

const rootClass = 'landing-page-templates-picker-modal'

export const createLandingPageFromTemplateModalProps = {
  isOpen: true,
  titleText: 'ListPage.LandingPages.TemplatePicker.Title',
  primaryButtonText: 'ListPage.LandingPages.TemplatePicker.PrimaryButton',
  secondaryButtonText: 'ListPage.LandingPages.TemplatePicker.SecondaryButton',
  viewSelectedLabel: 'ListPage.LandingPages.TemplatePicker.ViewSelectedLabel',
  isSingleSelect: true,
}

const LandingPageTemplatesPickerModal: FC<AssetPickerModalBaseProps> = (props) => {
  const { t } = useTranslation()

  const customPreviewItemCall = async (listPageValues: ListPageCommonState, update: Update) => {
    const { selectedRows } = listPageValues
    update({ previewUrl: getLandingPagePreviewUrl(selectedRows[0].externalId ?? '', true) })
  }

  const renderSearchColumns: RenderSearchColumnsV2 = (
    _searchInAllItems: boolean | undefined,
    _currentFolder: FolderData,
    search: string,
    folders: FolderData[]
  ) => renderSearchTableColumns(search, folders, t)

  const tableProps: AssetPickerTableProps = {
    columns: renderTableColumns(t),
    renderSearchColumns,
    listPage: 'LandingPageTemplates',
    sortingBy: [{ id: 'lastUpdated', desc: true }],
  }

  const sidebarProps: AssetPickerSidebarProps = {
    hasRecent: false,
    hasCreatedByMe: true,
    allItemFilter: allLandingPageTemplates,
  }

  const listingPageProps: AssetPickerListingPageProps = {
    sidebarProps,
    subTypes: landingPageTemplateSubTypes,
    customPreviewItemCall,
  }

  const assetPickerProps: AssetPickerModalProps = {
    className: rootClass,
    listingPageProps,
    tableProps,
    hasTags: true,
    hasCustomFilters: false,
    hasSidebar: true,
    hasPreview: true,
    itemType: ItemType.LANDING_PAGE_TEMPLATE,
    ...props,
  }

  return <AssetPickerModal {...assetPickerProps} />
}

export default LandingPageTemplatesPickerModal
