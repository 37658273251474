import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { ItemDtoWithBeeComposer, ListingPageCommonContext } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import Pill, { PillSize, PillType } from '@components/Pill/Pill'
import Svg, { SvgNames, SvgType } from '@components/Svg/index'
import Tooltip from '@components/Tooltip/Tooltip'
import { EmailTemplatesCustomTableActions, onCustomTableAction } from '@src/pages/listingPages/EmailTemplates/utils/EmailTemplatesListingPage.tables'

interface TemplateNameColumnProps {
  original: ItemDtoWithBeeComposer
  svgName: SvgNames | null
  name: string
  search: string
  rootClass: string
}

const TemplateNameColumn: FC<TemplateNameColumnProps> = (props: TemplateNameColumnProps) => {
  const { original, svgName, name, search, rootClass } = props
  const {
    update,
    values,
    values: { canCreate },
  } = useContext(ListingPageCommonContext)

  const { t } = useTranslation()

  const handleAction = () => {
    canCreate && onCustomTableAction(EmailTemplatesCustomTableActions.EDIT, update, { ...values, selectedRows: [original] })
  }

  return (
    <div className={classNames(`${rootClass}__search-name`, { [`${rootClass}__search-name-no-action`]: !canCreate })}>
      {svgName && <Svg name={svgName} type={SvgType.MEDIUM} />}
      <Tooltip
        ellipOnTrigger
        trigger={
          <div role={canCreate ? 'button' : 'div'} tabIndex={0} onClick={handleAction} onKeyDown={handleAction}>
            <div className={'ellip'}>{search ? renderBoldTextOnMatch(name, search) : name}</div>
          </div>
        }
      >
        {name}
      </Tooltip>

      {!original.beeComposer && <Pill text={t('Legacy')} type={PillType.GRAY} noMargin size={PillSize.SMALL} />}
    </div>
  )
}

export default TemplateNameColumn
