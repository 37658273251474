import { CRM_FIELDS } from '@src/pages/Contacts/StandardFieldNames/utils/StandardFieldNames.constants'
import { FieldNamesDataType, StandardFieldNameSelectOption } from '@utils/standardFieldNames.utils'

export const getRowSelectOptions = (row: FieldNamesDataType, tableData: FieldNamesDataType[]): StandardFieldNameSelectOption[] => {
  const selectedMappings = tableData.filter((tableRow) => tableRow.id !== row.id).map((row) => row.mapping)
  return Array.from(CRM_FIELDS).map(([mapping, name], idx) => {
    const selected = selectedMappings.indexOf(mapping) > -1 && mapping !== ''
    const id = `${mapping !== '' ? mapping : 'selectOption'}-${idx}`
    return { id, mapping, name, selected }
  })
}
