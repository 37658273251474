import React, { FC, useMemo, useState } from 'react'

import classNames from 'classnames'

import { useFrontendFiltering, FilterFunctionMap } from '@complex/ListingPage/Hooks/useFrontendFiltering'
import EmptyListing from '@components/EmptyListing/EmptyListing'
import { LinkTextButtonSize } from '@components/LinkTextButton/LinkTextButton'
import Modal, { ModalBody } from '@components/Modal'
import ModalFooterV2 from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import ModalHeaderV2 from '@components/Modal/components/ModalHeaderV2/ModalHeaderV2'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { SvgNames } from '@components/Svg'
import { PersistedRowStateValues } from '@components/TableV2/hooks/usePersistedRowState'
import { TableV2 } from '@components/TableV2/TableV2'
import { TableV2Props } from '@components/TableV2/tableV2TS/interfaces'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import {
  getCommonTestListTableProps,
  getTestListSegmentsTableColumns,
} from '@src/pages/EmailComposer/EmailModals/components/SendTestEmailModal/components/TestListModal/TestListTable.utils'
import { Row } from '@tanstack/react-table'
import { partialMatch } from '@utils/searchUtils'

import { TestListModalContainerProps } from './TestListModalContainer'
import { MANAGE_TEST_LIST_CONTACTS_URL } from '../../constants/SendTestEmailModal.constants'
import { TestSegment } from '../../graphQL/SendTestEmailModal.graphQL'

import './TestListSegmentsModal.css'

interface TestListSegmentsModalProps extends Omit<TestListModalContainerProps, 'onFinish'> {
  defaultSegmentId?: string
  tableProps: PersistedRowStateValues<TestSegment>['tableProps']
  onNext: (segment: TestSegment) => void
  onFinish: (isCancel?: boolean) => void
}

const rootClass = 'test-list-segments-modal'

const TestListSegmentsModal: FC<TestListSegmentsModalProps> = ({
  onFinish,
  defaultSegmentId,
  tableProps,
  segments,
  loading,
  dataTest = rootClass,
  onNext,
}) => {
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')
  const [submitDisabled, setSubmitDisabled] = useState(!defaultSegmentId)

  const { data, flatData, filterLoading } = useFrontendFiltering({
    data: useMemo(() => segments, [segments]),
    searchText: searchTerm,
    filterFunction: useMemo<FilterFunctionMap<TestSegment>>(() => ({ search: (item, search) => partialMatch(item, 'name', search) }), []),
    loading,
  })
  const [selectedSegment, setSelectedSegment] = useState<TestSegment | undefined>(() => flatData.find((segment) => segment.id === defaultSegmentId))

  const handleRowSelectionChanged = (rowIds: string[], rows: Row<TestSegment>[]) => {
    tableProps.onRowSelectionChanged(rowIds, rows)
    const segment = rows.find((row) => rowIds.includes(`${row.id}`))?.original
    setSelectedSegment(segment)
    if (!segment?.count) {
      setSubmitDisabled(true)
    } else {
      setSubmitDisabled(rowIds.length === 0)
    }
  }

  const handleNext = () => {
    if (selectedSegment) {
      onNext(selectedSegment)
      setSearchTerm('')
    }
  }

  const renderTable = () => {
    const segmentTable: TableV2Props<TestSegment> = {
      data,
      columns: getTestListSegmentsTableColumns(t, searchTerm),
      enableCheckbox: false,
      enableRadio: true,
      enableSubRow: !searchTerm,
      enableSubRowSelection: true,
      enableSorting: false,
      ...tableProps,
      defaultExpandedRows: tableProps.defaultExpandedRows ?? { '0': true },
      onRowSelectionChanged: handleRowSelectionChanged,
    }
    return <TableV2 {...getCommonTestListTableProps<TestSegment>()} {...segmentTable} />
  }

  return (
    <Modal
      className={rootClass}
      dataTest={dataTest}
      isOpen
      noPadding
      header={
        <ModalHeaderV2
          headerType={'list'}
          headerText={t('Test.List.Segments.Modal.Header')}
          actionButtonProps={{
            linkTextButton: {
              iconNameLeft: SvgNames.gear,
              text: t('Manage Test List'),
              size: LinkTextButtonSize.MEDIUM,
              onClick: () => window.open(MANAGE_TEST_LIST_CONTACTS_URL, '_blank'),
            },
          }}
          searchProps={{
            placeholder: t(`Search.Segments.Placeholder`),
            onChangeHandler: setSearchTerm as any,
            incomingValue: searchTerm,
          }}
          iconProps={{
            hasBackButton: !!searchTerm,
            onClick: onFinish,
          }}
          className={`${rootClass}__header`}
          dataTest={`${rootClass}-header`}
        />
      }
    >
      <ModalBody className={`${rootClass}__body`}>
        {loading || filterLoading || data.length ? (
          <>
            {searchTerm !== '' && (
              <Typography
                className={classNames('inset-x4 inset', `${rootClass}__search-results`)}
                text={'AssetPicker.Search.Results'}
                values={{ count: data.length, searchTerm }}
                type={TextType.SECTION_HEADER}
                weight={TextWeight.BOLD}
                dataTest={`${rootClass}-results`}
              />
            )}
            {renderTable()}
          </>
        ) : (
          <EmptyListing
            imgSrc={!segments.length ? StaticImageNames.errorNothingFound : StaticImageNames.emptySearch}
            headline={!segments.length ? t('Test.List.Contacts.Empty.Listing.Headline') : t('No results found')}
            text={
              !flatData.length ? (
                <Typography text={t('Test.List.Segments.Empty.Listing.Text')} type={TextType.BODY_TEXT_LIGHT} className="push-down-x2" />
              ) : (
                t('There were no results matching your search.')
              )
            }
            dataTest={`${dataTest}-empty-listing`}
            fullHeight
            withoutBorder
          />
        )}
      </ModalBody>
      <ModalFooterV2
        footerType={'list'}
        showTopBorder
        textContent={{
          text: t('Selected.Segment.Count', { name: selectedSegment?.name, count: selectedSegment ? 1 : 0 }),
        }}
        buttons={{
          actionButtonLabel: t('Next'),
          actionButtonOnClick: handleNext,
          actionButtonDisabled: submitDisabled,
          actionButtonTooltip: selectedSegment?.count === 0 ? t('Test.List.Segments.NoContacts.Tooltip') : undefined,
          cancelButtonLabel: t('Cancel'),
        }}
        onClose={() => onFinish(true)}
        className={`${rootClass}__footer`}
        dataTest={`${dataTest}-footer`}
      />
    </Modal>
  )
}

export default TestListSegmentsModal
