import { gql } from '@apollo/client'

export default gql`
  mutation deleteUrlGroup($pageId: String!, $urlId: String!) {
    deleteUrlGroup(pageId: $pageId, urlId: $urlId) {
      id
      name
      publicUrl
      seoFriendlyUrl
      shortUrl
    }
  }
`
