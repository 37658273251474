import { gql } from '@apollo/client'

export default gql`
  query getBeeTemplates($filter: [String], $search: String, $pageNumber: Int!, $pageSize: Int!) {
    getBeeTemplates(filter: $filter, search: $search, pageNumber: $pageNumber, pageSize: $pageSize) {
      count
      results {
        categories
        collections
        context
        description
        designer
        id
        is_blank
        order
        published_at
        short_description
        tags
        template_type
        thumbnail
        thumbnail_large
        title
      }
    }
  }
`
