import React, { FC, ReactNode } from 'react'

import { TFunction } from 'i18next'

import ColumnChart from '@components/ColumnChart/ColumnChart'
import Container from '@components/Container'
import { DataCardHeaderOptionType } from '@components/DataCard/components/DataCardHeaderWithOptions/DataCardHeaderWithOptions'
import { MenuItem } from '@components/DropDownActions/DropDownActions'
import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import { PERIOD_GROUPED_BY } from '@utils/contactSegments/contactSegments.utils'
import { DateType } from '@utils/date'
import { EmptyState } from '@utils/program/program.constants'

export enum TotalContactsPeriodType {
  SEVEN_DAYS = 'SEVEN_DAYS',
  QUARTER = 'QUARTER',
  HALF_YEAR = 'HALF_YEAR',
  YEAR = 'YEAR',
}

export enum DATACARD_LIST {
  ACTIVE_CONTACTS = 'ACTIVE_CONTACTS',
  ACTIVE_CONTACTS_CPM = 'ACTIVE_CONTACTS_CPM',
  TOTAL_CONTACTS = 'TOTAL_CONTACTS',
  ENGAGED_CONTACTS = 'ENGAGED_CONTACTS',
}

export interface BuildContactsPeriods {
  setPeriod: Function
  t: Function
  periodKeys: string[]
  value: string
  valueOnSelect: string
}

export const buildPeriodsUtils = (setPeriod: Function, t: Function): DataCardHeaderOptionType[] => [
  {
    onClick: (option: DataCardHeaderOptionType) => setPeriod(option),
    value: `${t('Past')} 7 ${t('days')}`,
    valueOnSelect: `(7 ${t('days')})`,
  },
  {
    onClick: (option: DataCardHeaderOptionType) => setPeriod(option),
    value: `${t('Past')} 6 ${t('months')}`,
    valueOnSelect: `(6 ${t('months')})`,
  },
  {
    onClick: (option: DataCardHeaderOptionType) => setPeriod(option),
    value: `${t('Past year')} (12 ${t('months')})`,
    valueOnSelect: `(${t('year')})`,
  },
]

export const buildContactsPeriodsUtils = ({ setPeriod, t, periodKeys, value, valueOnSelect }: BuildContactsPeriods) => {
  let period = buildPeriodsUtils(setPeriod, t)

  period = [
    ...period.slice(0, 1),
    {
      onClick: (option: DataCardHeaderOptionType) => setPeriod(option),
      value,
      valueOnSelect,
    },
    ...period.slice(1),
  ]

  return period.map((option, index) => {
    return { ...option, key: periodKeys[index] }
  })
}

export const LastUpdatedLabel: FC<any> = (props: { loadingChart: boolean; rootClass: string; lastUpdated: boolean }) => {
  const { t } = useTranslation()
  const { loadingChart, rootClass, lastUpdated } = props
  if (!loadingChart) {
    return (
      <>
        <div className={`${rootClass}__last-updated`}>
          <Typography
            text={t('Last Updated: ')}
            weight={TextWeight.BOLD}
            inline
            type={TextType.BODY_TEXT_GRAY_SMALL}
            lineHeight={LineHeight.MEDIUM_SMALL}
          />
          <Typography text={lastUpdated} type={TextType.BODY_TEXT_SMALL} inline lineHeight={LineHeight.MEDIUM_SMALL} />
        </div>
      </>
    )
  }
  return <></>
}

export const activeContactsFooterLink =
  'https://success.act-on.com/acton/attachment/9883/u-2cf6acc6-f5fc-4a4c-9464-d799a483661b/0/-/-/-/-/In-App%3A%20%20All%20Contacts%20Active%20Contacts%20Data%20Modal%20AJB%20Guide'
export const activeContactsHeaderLink =
  'https://success.act-on.com/acton/attachment/9883/u-c1fc034c-3a56-483c-a20f-90bec36880d3/0/-/-/-/-/In-App%3A%20%20All%20Contacts%20Active%20Contacts%20Guide'
export const totalContactsHeaderLink =
  'https://success.act-on.com/acton/attachment/9883/u-1a2884e2-ffe6-4b37-8d90-600e9ba51013/0/-/-/-/-/In-App%3A%20All%20Contacts%20Total%20Contacts%20Data%20Modal%20Growth'
export const activeContactsTitle = 'Active Contacts'
export const cpmActiveContactsTitle = 'Monthly Sends'
export const activeContactsHeaderText = `This chart shows how many of your contacts you’ve sent to during the time period, for billing purposes.  You can send multiple messages to a contact in the same month and it only counts as one Active Contact. `
export const activeContactsFooterText = `Act-On’s marketing automation is a powerful tool that allows you to put your data to work by streamlining your workflows so you can effectively target your prospects and nurture your leads with highly personalized content that helps convert your customers to loyal advocates of your brand. `
export const cpmActiveContactsHeaderText =
  'This chart shows how many messages you’ve sent during the time period, for billing purposes. Messages include marketing emails, transactional emails and automated program emails.'
export const totalContactsFooterLink =
  'https://success.act-on.com/acton/attachment/9883/u-ca1d6db3-59fd-420c-a602-ce405b762c4e/0/-/-/-/-/In-App%3A%20%20All%20Contacts%20Total%20Contacts%20Data%20Modal%20SMS'
export const totalContactsTitle = 'Total Contacts'
export const totalContactsHeaderText = `Here you’ll see the total number of contacts on your All Contacts list over the selected time period. This includes all contacts from any source, including bounced and opted-out contacts. Looking to fuel more growth? `
export const totalContactsFooterText = ` for higher performing segments and more engaged customers. Act-On’s SMS marketing platform allows you to improve customer communication with text message marketing on any device — driving new sales, better adoption, repeat business, and customer loyalty. `
export const engagedContactsTitle = 'Engaged Contacts'
export const engagedContactsHeaderText = `Engaged contacts include anyone who has recorded any of the behaviors that your score sheet awards points for! We’ve grouped them to show how many of your total contacts are not engaged at all, how many are slightly, more so, and very engaged based on your overall account averages. Want to get contacts more engaged? `
export const engagedContactsFooterText = `To make the biggest impact, you have to deliver perfect messaging at the perfect time. Set up an automated nurture program to ensure your messaging is always on brand, on time, and targeted at your customers’ direct needs based on digital behaviors and engagements. `

export const customText = (text: string) => <Typography text={text} inline type={TextType.MODAL_HEADER} lineHeight={LineHeight.MEDIUM} />
export const customTextTiny = (text: string) => <Typography text={text} inline type={TextType.BODY_TEXT_LIGHT} lineHeight={LineHeight.MEDIUM} />
export const customLink = (text: string, link: string) => (
  <TextLink text={text} link={link} lineHeight={LineHeight.MEDIUM} size={TextLinkSize.LARGE} />
)
export const customTextBold = (text: string) => (
  <Typography text={text} inline lineHeight={LineHeight.MEDIUM} type={TextType.EMPHASIZED_TEXT_TEAL} weight={TextWeight.MEDIUM} />
)

export const activeContactsHeader = (t: TFunction) => (
  <>
    {customTextTiny(t(activeContactsHeaderText))}
    {customLink(t('Learn more about Active Contacts'), activeContactsHeaderLink)}
  </>
)

export const cpmActiveContactsHeader = (t: Function) => <>{customTextTiny(t(cpmActiveContactsHeaderText))}</>

export const activeContactsFooter = (t: Function) => (
  <>
    {customTextBold(t('Keep your business growing with marketing automation. '))}
    {customText(t(activeContactsFooterText))}
    {customLink(t('More info '), activeContactsFooterLink)}
  </>
)

export const totalContactsHeader = (t: Function) => (
  <>
    {customTextTiny(t(totalContactsHeaderText))}
    {customLink(t('Check out this guide!'), totalContactsHeaderLink)}
  </>
)
export const totalContactsFooter = (t: Function) => (
  <>
    {customTextBold(t('Add SMS to your marketing mix '))}
    {customText(t(totalContactsFooterText))}
    {customLink(t('More info '), totalContactsFooterLink)}
  </>
)

export const engagedContactsHeader = (t: Function) => (
  <>
    {customTextTiny(t(engagedContactsHeaderText))}
    {customLink(t('Try an automated nurture program!'), totalContactsHeaderLink)}
  </>
)
export const engagedContactsFooter = (t: Function) => (
  <>
    {customTextBold(t('Use marketing automation to nurture leads and increase your ROI. '))}
    {customText(t(engagedContactsFooterText))}
    {customLink(t('More info '), totalContactsFooterLink)}
  </>
)

export enum PERIOD_MODAL {
  Week = 'Past 7 days',
  Quarter = 'Past 90 days',
  Half_Year = 'Past 6 months',
  Year = 'Past year (12 months)',
}

export const textDropDown: { [index in PERIOD_MODAL]: { text: PERIOD_MODAL; value: number } } = {
  [PERIOD_MODAL.Week]: { text: PERIOD_MODAL.Week, value: 7 },
  [PERIOD_MODAL.Quarter]: { text: PERIOD_MODAL.Quarter, value: 3 },
  [PERIOD_MODAL.Half_Year]: { text: PERIOD_MODAL.Half_Year, value: 6 },
  [PERIOD_MODAL.Year]: { text: PERIOD_MODAL.Year, value: 12 },
}

export const getDropDownOptions = (query: Function): MenuItem[] => [
  {
    text: PERIOD_MODAL.Week,
    onClick: () => query(7, PERIOD_GROUPED_BY.Daily, PERIOD_MODAL.Week),
  },
  {
    text: PERIOD_MODAL.Quarter,
    onClick: () => query(3, PERIOD_GROUPED_BY.Monthly, PERIOD_MODAL.Quarter),
  },
  {
    text: PERIOD_MODAL.Half_Year,
    onClick: () => query(6, PERIOD_GROUPED_BY.Monthly, PERIOD_MODAL.Half_Year),
  },
  {
    text: PERIOD_MODAL.Year,
    onClick: () => query(12, PERIOD_GROUPED_BY.Monthly, PERIOD_MODAL.Year),
  },
]

const activeContactsEmptyState: EmptyState = {
  imgSrc: StaticImageNames.emptyChartMedium,
  headline: 'Nothing to see here...',
  text: 'You’ll see detailed contact information when it becomes available.',
  linkText: 'Need help getting started? Send an email!',
  link: 'https://success.act-on.com/acton/attachment/9883/u-0131b140-8550-4698-a8f5-5f0da4804482/0/-/-/-/-/In-App%3A%20%20All%20Contacts%20Active%20Contacts%20Data%20Modal%20Empty%20State%20Create%20Email',
}

const totalContactsEmptyState: EmptyState = {
  imgSrc: StaticImageNames.lineChartEmpty,
  headline: 'Nothing to see here...',
  text: 'You’ll see detailed contact information when it becomes available.',
  linkText: 'Add some contacts! Import a List.',
  link: `${rootContext}/importContacts`,
}

export const renderEmptyState = (dataCardActive: string) => {
  if (dataCardActive == DATACARD_LIST.ACTIVE_CONTACTS || dataCardActive == DATACARD_LIST.ACTIVE_CONTACTS_CPM) {
    return renderActiveContactsEmptyState()
  } else if (dataCardActive == DATACARD_LIST.TOTAL_CONTACTS) {
    return renderTotalContactsEmptyState()
  }
}

export const renderActiveContactsEmptyState = () => {
  return (
    <Container>
      <EmptyListing
        headline={activeContactsEmptyState.headline}
        imgSrc={activeContactsEmptyState.imgSrc}
        size={EmptyListingSize.MEDIUM}
        text={activeContactsEmptyState.text}
        linkText={activeContactsEmptyState.linkText}
        link={activeContactsEmptyState.link}
        hideIcon={false}
        withoutBorder
      />
    </Container>
  )
}

const renderTotalContactsEmptyState = () => {
  return (
    <Container>
      <EmptyListing
        headline={totalContactsEmptyState.headline}
        imgSrc={totalContactsEmptyState.imgSrc}
        size={EmptyListingSize.MEDIUM}
        text={totalContactsEmptyState.text}
        linkText={totalContactsEmptyState.linkText}
        link={totalContactsEmptyState.link}
        hideIcon={false}
        withoutBorder
      />
    </Container>
  )
}

export const chartDateDataBuilder = (groupBy: DateType, unitsAgo: number, year?: boolean) => {
  return Array(unitsAgo)
    .fill('')
    .map((_entry: any, idx: number) => {
      if (year) {
        return `${new Date(new Date().getUTCFullYear(), new Date().getUTCMonth() - idx).toLocaleDateString([], { month: 'short' })} 1, ${new Date(
          new Date().getUTCFullYear(),
          new Date().getUTCMonth() - idx
        ).toLocaleDateString([], { year: 'numeric' })}`
      } else {
        return groupBy === DateType.MONTH
          ? new Date(new Date().getUTCFullYear(), new Date().getUTCMonth() - idx).toLocaleDateString([], { month: 'short' })
          : new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate() - idx).toLocaleDateString([], {
              day: '2-digit',
              month: 'short',
            })
      }
    })
    .reverse()
}

export const dataCardModalValues: {
  [index in DATACARD_LIST]: { title: string; textHeader: (t: TFunction) => ReactNode; textFooter: (t: TFunction) => ReactNode }
} = {
  [DATACARD_LIST.ACTIVE_CONTACTS]: { title: activeContactsTitle, textHeader: activeContactsHeader, textFooter: activeContactsFooter },
  [DATACARD_LIST.ACTIVE_CONTACTS_CPM]: { title: cpmActiveContactsTitle, textHeader: cpmActiveContactsHeader, textFooter: activeContactsFooter },
  [DATACARD_LIST.TOTAL_CONTACTS]: { title: totalContactsTitle, textHeader: totalContactsHeader, textFooter: totalContactsFooter },
  [DATACARD_LIST.ENGAGED_CONTACTS]: { title: engagedContactsTitle, textHeader: engagedContactsHeader, textFooter: engagedContactsFooter },
}

export const chartTextValues: { [index in DATACARD_LIST]: { title: string; subtitle: string } } = {
  [DATACARD_LIST.ACTIVE_CONTACTS]: { title: 'Active Contacts Usage', subtitle: 'A look at your cumulative contact usage over the time period.' },
  [DATACARD_LIST.ACTIVE_CONTACTS_CPM]: {
    title: 'Monthly Send Limits',
    subtitle: 'A look at your cumulative sent messages over the selected time period.',
  },
  [DATACARD_LIST.TOTAL_CONTACTS]: { title: 'Total Contacts Growth', subtitle: 'A look at your total contacts growth over the selected time period.' },
  [DATACARD_LIST.ENGAGED_CONTACTS]: {
    title: 'Contact Engagements',
    subtitle: 'A look at your contact engagement activities over the selected time period.',
  },
}

export const createChartBar = (
  title: string,
  loadingChart: boolean,
  subtitle: string,
  contactsUsed: number[],
  categories: string[],
  rootClass: string,
  lastUpdated: string,
  dataCardID: DATACARD_LIST
) => (
  <ColumnChart
    fields={[
      {
        name: dataCardID == DATACARD_LIST.TOTAL_CONTACTS ? 'Total' : 'Used',
        data: contactsUsed,
        color: '#00BABE',
      },
    ]}
    categories={categories}
    title={title}
    subtitle={subtitle}
    ExtraElement={<LastUpdatedLabel loadingChart={loadingChart} rootClass={rootClass} lastUpdated={lastUpdated} />}
    loading={loadingChart}
    hideLegend
    disablePrint
    chartInModal
  />
)
