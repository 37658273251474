import React, { FC, useContext, useMemo } from 'react'

import classNames from 'classnames'

import Container from '@components/Container'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ColumnStateType } from '@src/pages/importcontacts/context/ImportContactsContext'
import { ImportContactsV2Context } from '@src/pages/ImportContactsV2/utils/ImportContactsV2.context'

import './ReviewImportSummary.css'

interface ReviewImportSummaryProps {
  className?: string
  dataTest?: string
}

export enum SummaryStatus {
  FIELDS_MAPPED = 'Fields mapped',
  NEW_FIELDS = 'New fields',
  WARNINGS = 'Has warnings',
  UNMAPPED = 'Won’t import',
}

const rootClass = 'review-import-summary'

const ReviewImportSummary: FC<ReviewImportSummaryProps> = (props: ReviewImportSummaryProps) => {
  const { dataTest = rootClass, className = '' } = props

  const { t } = useTranslation()

  const {
    values: { mappingPreview, selectedFile },
  } = useContext(ImportContactsV2Context)

  const { newFields, warnings, mapped, unmapped } = useMemo(() => {
    const { newFields, warnings, mapped } = mappingPreview.reduce(
      (acc, { state, isCustom }) => {
        if (state === ColumnStateType.WARNING) {
          acc.warnings++
          acc.mapped++
        }
        if (state === ColumnStateType.SUCCESS) {
          acc.mapped++
        }
        if (isCustom) {
          acc.newFields++
        }
        return acc
      },
      { newFields: 0, warnings: 0, mapped: 0 }
    )
    return { newFields, warnings, mapped, unmapped: mappingPreview.length - mapped }
  }, [mappingPreview])

  const renderStatus = (status: SummaryStatus, value: string | number) => {
    const contentByStatus: { [key in SummaryStatus]: { icon: SvgNames; className?: string } } = {
      [SummaryStatus.FIELDS_MAPPED]: { icon: SvgNames.checkSuccess },
      [SummaryStatus.NEW_FIELDS]: { icon: SvgNames.addCircle, className: `${rootClass}__status-icon-new-fields` },
      [SummaryStatus.WARNINGS]: { icon: SvgNames.warningSolidNoFill, className: `${rootClass}__status-icon-warnings` },
      [SummaryStatus.UNMAPPED]: { icon: SvgNames.cancelCircle, className: `${rootClass}__status-icon-unmapped` },
    }
    const { icon, className } = contentByStatus[status]
    return (
      <div className={`${rootClass}__status`} data-test={`${dataTest}-status-${status}`}>
        <div className={`${rootClass}__status-header`}>
          <Svg className={className} name={icon} type={SvgType.LARGE_ICON} />
          <Typography text={t(status)} type={TextType.BODY_TEXT_LARGE_LIGHT} lineHeight={LineHeight.MEDIUM_LARGE} />
        </div>
        <Typography text={value} type={TextType.PAGE_HEADLINE} weight={TextWeight.MEDIUM} lineHeight={LineHeight.VERY_LARGE} />
      </div>
    )
  }

  return (
    <Container className={classNames(rootClass, className)} dataTest={dataTest}>
      <div className={`${rootClass}__header`}>
        <Typography text={t('Import summary')} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.MEDIUM} lineHeight={LineHeight.MEDIUM_LARGE} />
        <Typography text={t('Review sync summary before submitting your import to All Contacts.')} type={TextType.BODY_TEXT_LIGHT} />
      </div>
      <div className={`${rootClass}__file`} data-test={`${dataTest}-file`}>
        <Svg name={SvgNames.csvFile} fill={SvgColor.LIGHT_GRAY} type={SvgType.LARGE_ICON} />
        <Typography
          className={`${rootClass}__file-name`}
          dataTest={`${dataTest}-filename`}
          text={selectedFile?.name ?? 'No file selected'}
          weight={TextWeight.MEDIUM}
        />
      </div>
      <div className={`${rootClass}__status-container`}>
        {renderStatus(SummaryStatus.FIELDS_MAPPED, mapped)}
        {renderStatus(SummaryStatus.NEW_FIELDS, newFields)}
        {renderStatus(SummaryStatus.WARNINGS, warnings)}
        {renderStatus(SummaryStatus.UNMAPPED, unmapped)}
      </div>
    </Container>
  )
}

export default ReviewImportSummary
