import { gql } from '@apollo/client'

export default gql`
  mutation upsertSubscriptionChildToParentCategoryIdsMap(
    $parentAccountId: Long!
    $mapping: [SubscriptionChildToParentCategoryIdsMappingInput]
    $childAccountId: Long!
  ) {
    upsertSubscriptionChildToParentCategoryIdsMap(parentAccountId: $parentAccountId, mapping: $mapping, childAccountId: $childAccountId)
  }
`
