import React, { FC, ReactNode, useMemo } from 'react'

import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { StepPill } from '@src/pages/programs/UpgradeCommonComponents/StepPill/StepPill'
import {
  UpgradedProgramAppendToSegmentStep,
  UpgradedProgramRecordSetStep,
  UpgradedProgramSendStep,
  UpgradedProgramStep,
  UpgradedSourceUpgradeStepType,
} from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'
import { UpgradedStepsType } from '@src/pages/programs/upgradeManager/helper/UpgradeManager.helper'
import { ReviewChangeFieldValueStep } from '@src/pages/programs/UpgradePrograms/components/ReviewUpgrade/sections/FixStepIssuesSection/components/ReviewCurrentStep/steps/ReviewChangeFieldValueStep/ReviewChangeFieldValueStep'
import { ReviewCopyToListStep } from '@src/pages/programs/UpgradePrograms/components/ReviewUpgrade/sections/FixStepIssuesSection/components/ReviewCurrentStep/steps/ReviewCopyToListStep/ReviewCopyToListStep'
import { ReviewSendEmailMessageStep } from '@src/pages/programs/UpgradePrograms/components/ReviewUpgrade/sections/FixStepIssuesSection/components/ReviewCurrentStep/steps/ReviewSendEmailMessageStep/ReviewSendEmailMessageStep'
import { ReviewSourceStep } from '@src/pages/programs/UpgradePrograms/components/ReviewUpgrade/sections/FixStepIssuesSection/components/ReviewCurrentStep/steps/ReviewSourceStep/ReviewSourceStep'
import { ProgramStepType } from '@utils/program/program.constants'

import './ReviewCurrentStep.css'

interface ReviewCurrentStepProps {
  step: UpgradedProgramStep
}

const rootClass = 'review-current-step'

export const ReviewCurrentStep: FC<ReviewCurrentStepProps> = ({ step }: ReviewCurrentStepProps) => {
  const { t } = useTranslation()

  const steps: { [key in UpgradedStepsType]: () => ReactNode } = useMemo(
    () => ({
      [ProgramStepType.FIELD_SET]: () => <ReviewChangeFieldValueStep step={step as UpgradedProgramRecordSetStep} />,
      [ProgramStepType.APPEND_TO_SEGMENT]: () => <ReviewCopyToListStep step={step as UpgradedProgramAppendToSegmentStep} />,
      [ProgramStepType.SEND]: () => <ReviewSendEmailMessageStep step={step as UpgradedProgramSendStep} />,
      [ProgramStepType.BRANCH]: () => <ReviewSourceStep step={step as UpgradedSourceUpgradeStepType} />,
      [ProgramStepType.WAIT_UNTIL_IN_SEGMENT]: () => <ReviewSourceStep step={step as UpgradedSourceUpgradeStepType} />,
    }),
    [step.stepId]
  )

  return (
    <div className={rootClass}>
      <div className={`${rootClass}__step-container`}>
        <Typography text={t('Step')} type={TextType.BODY_TEXT} lineHeight={LineHeight.MEDIUM} />
        <StepPill step={step} />
      </div>
      {steps[step?.stepType as UpgradedStepsType]?.()}
    </div>
  )
}
