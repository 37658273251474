export enum BehaviorEmailOption {
  WAS_SENT_EMAILS = 'was_sent_emails',
  WAS_SENT_EMAIL_FROM = 'was_sent_email_from',
  LAST_EMAIL_SENT_DATE = 'last_email_send_date',
  FIRST_EMAIL_SEND_DATE = 'first_email_send_date',
  LAST_EMAIL_SENT_NAME = 'last_email_sent_name',
  FIRST_EMAIL_SENT_NAME = 'first_email_sent_name',
  OPENED_EMAILS = 'opened_emails',
  OPEN_RATE = 'open_rate',
  CHANGE_IN_OPEN_RATE = 'change_in_open_rate',
  LAST_EMAIL_OPEN_DATE = 'last_email_open_date',
  FIRST_EMAIL_OPEN_DATE = 'first_email_open_date',
  CLICKED_ON_EMAILS = 'clicked_on_emails',
  CLICKED_ON_A_SPECIFIC_LINK = 'clicked_on_a_specific_link',
  CLICK_RATE = 'click_rate',
  CHANGE_IN_CLICK_RATE = 'change_in_click_rate',
  LAST_EMAIL_CLICK_DATE = 'last_email_click_date',
  FIRST_EMAIL_CLICK_DATE = 'first_email_click_date',
}

export enum BehaviorProgramOption {
  ENTERED_PROGRAMS = 'entered_programs',
  EXITED_PROGRAMS = 'exited_programs',
  EARLY_EXITED_PROGRAMS = 'early_exited_programs',
  CURRENTLY_IN_PROGRAM = 'currently_in_a_program',
  NUMBER_OF_PROGRAMS_ENROLLED_IN = 'number_of_programs_enrolled_in',
  LAST_PROGRAM_ENROLLED_IN = 'last_program_enrolled_in',
  LAST_PROGRAM_ENROLLED_DATE = 'last_program_enrolled_date',
  LAST_PROGRAM_END_DATE = 'last_program_end_date',
}
export enum BehaviorWebsiteOption {
  VISITED_WEBSITE = 'visited_website',
  VISITED_WEB_PAGES = 'visited_web_pages',
  AVERAGE_PAGE_VIEWS_PER_SESSION = 'average_page_views_per_session',
  FIRST_WEB_PAGE_VISITED = 'first_web_page_visited',
  LAST_WEB_PAGE_VISITED = 'last_web_page_visited',
  SEARCHED = 'searched',
  DOWNLOADED_MEDIA = 'downloaded_media',
  VISITED_LANDING_PAGES = 'visited_landing_pages',
  CLICKED_ON_A_LANDING_PAGE_LINK = 'clicked_on_a_landing_page_link',
}

export enum BehaviorEngagementOption {
  FIRST_ENGAGEMENT_DATE = 'first_engagement_date',
  TYPE_OF_FIRST_ENGAGEMENT = 'type_of_first_engagement',
  LAST_ENGAGEMENT_DATE = 'last_engagement_date',
  TYPE_OF_LAST_ENGAGEMENT = 'type_of_last_engagement',
  NUMBER_OF_ENGAGEMENTS = 'number_of_engagements',
  NUMBER_OF_SENDS_SINCE_LAST_ENGAGEMENT = 'number_of_sends_since_last_engagement',
}

export enum BehaviorExpressionConditionType {
  ENGAGEMENT_MULTISELECT = 'ENGAGEMENT_MULTISELECT',
  ENGAGEMENT_MULTISELECT_WITH_NUMERIC = 'ENGAGEMENT_MULTISELECT_WITH_NUMERIC',
  MULTISELECT = 'MULTISELECT',
  NUMERIC = 'NUMERIC',
  QUANTITY_ASSETS = 'QUANTITY_ASSETS',
  QUANTITY_TEXT = 'QUANTITY_TEXT',
  STATUS = 'STATUS',
  TIMEFRAMES = 'TIMEFRAMES',
}

export enum QuantityOptions {
  AT_LEAST = 'at_least',
  AT_MOST = 'at_most',
  EXACTLY = 'exactly',
  NONE = 'none',
  AT_LEAST_ONE_THESE = 'at_least_1_of_these',
  AT_LEAST_THESE = 'at_least_n_of_these',
  EXACTLY_THESE = 'exactly_n_of_these',
  ALL_THESE = 'all_of_these',
  NONE_THESE = 'none_of_these',
}

export enum EngagementActivity {
  EMAIL_OPENS = 'email_opens',
  EMAIL_CLICKS = 'email_clicks',
  WEBSITE_VISITS = 'website_visits',
  LANDING_PAGE_VISITS = 'landing_page_visits',
  FORM_SUBMITS = 'form_submits',
  MEDIA_DOWNLOADS = 'media_downloads',
  WEBINAR_REGISTRATIONS = 'webinar_registrations',
  WEBINAR_ATTENDANCE = 'webinar_attendance',
  SMS_CLICKS = 'sms_clicks',
  SMS_REPLIES = 'sms_replies',
}
