import React, { FC } from 'react'

import ContactsReentryWarning from '@complex/ProgramHeader/ContactsReentryWarning/ContactsReentryWarning'
import DownloadButton from '@components/DownloadButton/DownloadButton'
import PageHeader from '@components/PageHeader'
import Search, { SearchType } from '@components/Search/Search'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './ProgramHeader.css'

interface Props {
  className?: string
  dataTest?: string
  linkUrl?: string
  linkOptions?: { [key: string]: string }
  primaryText?: string
  totalCount?: number
  onSearch?: (query: string) => void
  searchIncomingValue: string
  tableTitle?: string
  downloadUrl: string
  searchPlaceholder: string
  changeToolTipText?: boolean
  hasSubtitle?: boolean
  tableSubtitle?: string
  isProgramDashboard?: boolean
  headerText?: string
  tooltipText?: string
  showReentryWarning: boolean
}

const rootClass = 'program-header'

const ProgramHeader: FC<Props> = (props: Props) => {
  const {
    dataTest = rootClass,
    className = '',
    primaryText = '',
    totalCount,
    onSearch,
    searchIncomingValue = '',
    downloadUrl = '',
    tableTitle = '',
    tableSubtitle,
    searchPlaceholder = '',
    showReentryWarning,
    isProgramDashboard,
    headerText,
    tooltipText = 'Download Full List',
  } = props
  const { t } = useTranslation()

  const renderLeftSideText = () => {
    return isProgramDashboard ? (
      <div>
        <Typography type={TextType.SECTION_HEADER} text={headerText} weight={TextWeight.MEDIUM} />
        <Typography className={`${rootClass}__sub-title`} type={TextType.BODY_TEXT_SMALL_LIGHT} text={tableSubtitle} lineHeight={LineHeight.MEDIUM} />
      </div>
    ) : (
      <Typography text={`${totalCount} contacts`} weight={TextWeight.BOLD} />
    )
  }

  return (
    <div data-test={dataTest} className={className}>
      {!isProgramDashboard && <PageHeader leftContent linkBack primaryText={primaryText} />}
      <div className={`${rootClass}__table-actions`}>
        {renderLeftSideText()}
        <div className={`${rootClass}__table-actions__search-container`}>
          {onSearch && (
            <>
              <DownloadButton tooltipText={t(tooltipText)} url={downloadUrl} filename={tableTitle} className={`${rootClass}__download`} />
              <Search
                incomingValue={searchIncomingValue}
                onChangeHandler={onSearch}
                placeholder={searchPlaceholder}
                searchType={SearchType.LARGE}
                className={`${rootClass}__table-actions__search-container__search-bar`}
                canClear
              />
            </>
          )}
        </div>
      </div>
      {showReentryWarning && <ContactsReentryWarning className={`${rootClass}__warning`} />}
    </div>
  )
}

export default ProgramHeader
