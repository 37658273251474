// NOTE: the query was manually updated to fix the latest level of subfolder bug

import { gql } from '@apollo/client'
import { FOLDER_FIELDS } from '@graphql/microservices/categorization/fragments'

export default gql`
  ${FOLDER_FIELDS}
  query getFolders($folderSubTypes: [String], $subTypes: [String], $type: String) {
    getFolders(folderSubTypes: $folderSubTypes, subTypes: $subTypes, type: $type) {
      ...FolderFields
      subFolders {
        ...FolderFields
        subFolders {
          ...FolderFields
          subFolders {
            ...FolderFields
          }
        }
      }
    }
  }
`
