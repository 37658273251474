import React, { FC, ReactElement, ReactNode } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Modal, { ModalBody, ModalFooter, ModalFooterAction, ModalHeader } from '@components/Modal'
import Svg, { SvgNames } from '@components/Svg'
import { SvgColor, SvgType } from '@components/Svg/Svg'
import Typography, { ModalBodyStyle, ModalHeaderStyle, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './confirmationModal.css'

const rootClass = 'confirmation-modal'

export enum YesNo {
  YES,
  NO,
}

export interface ConfirmationModalProps {
  isOpen: boolean
  closeModal?(): void
  titleIcon?: SvgNames
  titleIconFillColor?: SvgColor
  title?: string | ReactElement
  centerTitle?: boolean
  body: ReactNode
  disabledPrimary?: boolean
  okButtonText?: string
  yesButtonText?: string
  yesButtonIcon?: SvgNames
  noButtonText?: string
  deleteButtonText?: string
  removeButtonText?: string
  deleteButtonType?: ButtonType
  removeButtonType?: ButtonType
  cancelButtonText?: string
  deleteDisabled?: boolean
  removeDisabled?: boolean
  isYesNo?: boolean
  isDelete?: boolean
  isRemove?: boolean
  hideFooter?: boolean
  onAnswer?(answer: YesNo): void
  footerActionElement?: ReactNode
  showDivider?: boolean
  className?: string
  dataTest?: string
}

export const ConfirmationModal: FC<ConfirmationModalProps> = (props: ConfirmationModalProps) => {
  const {
    titleIcon,
    titleIconFillColor,
    title,
    centerTitle = true,
    body,
    isOpen,
    okButtonText = 'Ok',
    yesButtonText = 'Ok',
    yesButtonIcon,
    noButtonText = 'Cancel',
    deleteButtonText = 'Delete',
    removeButtonText = 'Remove',
    deleteButtonType = ButtonType.DELETE,
    removeButtonType = ButtonType.PRIMARY,
    cancelButtonText = 'Cancel',
    deleteDisabled = false,
    removeDisabled = false,
    closeModal,
    isYesNo = false,
    isDelete = false,
    isRemove = false,
    onAnswer,
    disabledPrimary = false,
    hideFooter = false,
    footerActionElement,
    showDivider = false,
    className,
    dataTest = 'confirmation-modal',
  } = props

  const { t } = useTranslation()

  const bodyContent = typeof body === 'string' ? <Typography text={body} {...ModalBodyStyle} /> : body
  const headerContent =
    typeof title === 'string' || title === undefined ? (
      <ModalHeader
        className={classNames({
          [`${rootClass}__header--center`]: centerTitle,
          [`${rootClass}__header--no-title`]: title === undefined,
          [`${rootClass}__header--with-icon`]: titleIcon,
        })}
        hideBorder
      >
        {titleIcon && <Svg type={SvgType.VERY_LARGE_ICON} className={`${rootClass}__title-icon`} name={titleIcon} fill={titleIconFillColor} />}
        <Typography text={title} {...ModalHeaderStyle} type={TextType.MODAL_HEADLINE_CONFIRMATION} />
      </ModalHeader>
    ) : (
      title
    )

  return (
    <Modal className={classNames(rootClass, className)} isOpen={isOpen} dataTest={dataTest} header={headerContent}>
      <ModalBody>{bodyContent}</ModalBody>
      <ModalFooter showDivider={showDivider} className={classNames({ [`${rootClass}__hide-footer`]: hideFooter })}>
        <ModalFooterAction>{footerActionElement}</ModalFooterAction>
        <Button
          render={isYesNo && !isDelete}
          dataTest={`${dataTest}-no-button`}
          buttonType={ButtonType.TERTIARY}
          onClick={() => {
            onAnswer?.(YesNo.NO)
          }}
        >
          {t(noButtonText)}
        </Button>
        <Button
          render={isDelete}
          dataTest={`${dataTest}-cancel-button`}
          buttonType={ButtonType.TERTIARY}
          onClick={() => {
            onAnswer?.(YesNo.NO)
          }}
        >
          {t(cancelButtonText)}
        </Button>
        <Button
          render={isYesNo && !isDelete && !isRemove}
          dataTest={`${dataTest}-yes-button`}
          buttonType={ButtonType.PRIMARY}
          disabled={disabledPrimary}
          onClick={() => {
            onAnswer?.(YesNo.YES)
          }}
        >
          {yesButtonIcon && <Svg name={yesButtonIcon} fill={SvgColor.WHITE} />}
          {t(yesButtonText)}
        </Button>
        <Button render={!isYesNo && !isDelete} dataTest={`${dataTest}-ok-button`} buttonType={ButtonType.PRIMARY} onClick={closeModal}>
          {t(okButtonText)}
        </Button>
        <Button
          render={isDelete}
          disabled={deleteDisabled}
          dataTest={`${dataTest}-delete-button`}
          buttonType={deleteButtonType}
          onClick={() => {
            onAnswer?.(YesNo.YES)
          }}
        >
          {t(deleteButtonText)}
        </Button>
        <Button
          render={isRemove}
          disabled={removeDisabled}
          dataTest={`${dataTest}-remove-button`}
          buttonType={removeButtonType}
          onClick={() => {
            onAnswer?.(YesNo.YES)
          }}
        >
          {t(removeButtonText)}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ConfirmationModal
