import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import ListingPageWithTabs from '@complex/ListingPageWithTabs/ListingPageWithTabs'
import ErrorAlertBanner from '@components/ErrorAlertBanner/ErrorAlertBanner'
import { rootContext, useTranslation } from '@const/globals'
import ContactSegmentsContainer from '@src/pages/ContactSegments/ContactSegmentsContainer'
import { useContactSegmentsRequest } from '@src/pages/ContactSegments/GraphQL/ContactSegments.graphQL'
import { CONTACTS_DETAILS_URL, SEGMENT_ACTIVITY_URL } from '@src/pages/ContactSegments/utils/ContactSegmentsSession.utils'
import { CustomDataJoinViewListingPageContainer } from '@src/pages/listingPages/CustomDataJoinView/CustomDataJoinViewListingPageContainer'
import FormsJoinViewListingPageContainer from '@src/pages/listingPages/FormsJoinView/FormsJoinViewListingPageContainer'
import {
  CUSTOM_DATA_LIST_URL,
  FORM_SUBMISSION_LIST_URL,
  WEBINAR_SUBMISSION_LIST_URL,
} from '@src/pages/listingPages/FormsJoinView/FormsJoinViewListingPageContainer.constants'
import { getConnectorType, goToReconnect } from '@src/pages/listingPages/TabbedContainers/AudienceCenter/AudienceCenterTabbedContainer.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import useCRM from '@utils/hooks/useCRM'

import './AudienceCenterTabbedContainer.css'

interface AudienceCenterTabbedContainerProps {
  className?: string
  dataTest?: string
}

const rootClass = 'audience-center-tabbed-container'

const AudienceCenterTabbedContainer: FC<AudienceCenterTabbedContainerProps> = (props: AudienceCenterTabbedContainerProps) => {
  const { dataTest = `audienceCenterTabbedContainer` } = props
  const [showAlertBanner, setShowAlertBanner] = useState(false)
  const { t } = useTranslation()
  const { phase3FormSubmission, hasAOCWebinars, hasCustomObjectsListingPage } = useAccountSettings()
  const { hasCRMConnected, connectorType } = useCRM()
  const { getAccountSettingsRequest } = useContactSegmentsRequest()
  const tabProps = [
    {
      label: t('All Contacts'),
      component: <ContactSegmentsContainer />,
      pathName: `${rootContext}/segments`,
      pathnameId: 'segments',
    },
  ]

  if (phase3FormSubmission) {
    tabProps.push({
      label: t('Form Submissions'),
      component: <FormsJoinViewListingPageContainer />,
      pathName: `${rootContext}/content/formSubmissions`,
      pathnameId: 'formSubmissions',
    })
  }

  if (hasAOCWebinars) {
    tabProps.push({
      label: t('Webinar Registrations'),
      component: <FormsJoinViewListingPageContainer />,
      pathName: `${rootContext}/content/webinarSubmissions`,
      pathnameId: 'webinarSubmissions',
    })
  }

  if (hasCustomObjectsListingPage) {
    tabProps.push({
      label: t('Custom Data'),
      component: <CustomDataJoinViewListingPageContainer />,
      pathName: `${rootContext}/customData`,
      pathnameId: 'customData',
    })
  }

  const isSegmentDetails = location.pathname.includes(CONTACTS_DETAILS_URL) || location.pathname.includes(SEGMENT_ACTIVITY_URL)
  const isFormSubmissionDetails = location.pathname.includes(`${FORM_SUBMISSION_LIST_URL}/details`)
  const isWebinarSubmissionDetails = location.pathname.includes(`${WEBINAR_SUBMISSION_LIST_URL}/details`)
  const isCustomDataDetails = location.pathname.includes(`${CUSTOM_DATA_LIST_URL}/details`)

  useEffect(() => {
    if (hasCRMConnected) {
      getAccountSettingsRequest().then(({ data }) => {
        const hasValidCRMCreds = data?.accountSettings?.hasValidCrmCreds
        setShowAlertBanner(hasValidCRMCreds !== undefined ? !hasValidCRMCreds : false)
      })
    }
  }, [hasCRMConnected])

  if (isSegmentDetails) {
    return <ContactSegmentsContainer />
  } else if (isFormSubmissionDetails || isWebinarSubmissionDetails) {
    return <FormsJoinViewListingPageContainer />
  } else if (isCustomDataDetails) {
    return <CustomDataJoinViewListingPageContainer />
  } else {
    return (
      <>
        {showAlertBanner && (
          <ErrorAlertBanner
            primaryButtonAction={() => {
              goToReconnect(getConnectorType(connectorType))
              setShowAlertBanner(false)
            }}
            secondaryButtonAction={() => setShowAlertBanner(false)}
            primaryButtonText={t('Reconnect now')}
            secondaryButtonText={t('Dismiss')}
            textPrimary={t('We’re unable to connect to your {{connectorType}} CRM', { connectorType })}
            textSecondary={t('Please update your CRM credentials to restore regular data syncing and avoid any further inconvenience.')}
          />
        )}
        <ListingPageWithTabs
          className={classNames(rootClass, { [`${rootClass}__error-banner-displayed`]: showAlertBanner })}
          tabProps={tabProps}
          dataTest={dataTest}
        />
      </>
    )
  }
}

export default AudienceCenterTabbedContainer
