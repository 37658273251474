import React, { FC } from 'react'

import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { useTranslation } from '@const/globals'
import { FilterTypes } from '@utils/filter'

import { useTemplateCatalogModalContentContext } from '../../TemplateCatalogModal.context'

const rootClass = 'template-catalog-modal-content__empty-listing'

interface TemplateCatalogModalEmptyListingProps {
  dataTest?: string
}

const TemplateCatalogModalEmptyListing: FC<TemplateCatalogModalEmptyListingProps> = ({ dataTest = rootClass }) => {
  const { t } = useTranslation()

  const { searchText, activeSubTypes, activeFilter, onFilterChange } = useTemplateCatalogModalContentContext()

  const getProps = () => {
    if (searchText) {
      return {
        imgSrc: StaticImageNames.emptySearch,
        headline: t('Empty.Listing.No.Results.Headline', { term: searchText }),
        text: 'Empty.Listing.No.Results.Text',
      }
    } else if (activeSubTypes.length) {
      return {
        imgSrc: StaticImageNames.emptyFilter,
        headline: t('AssetPicker.Common.Table.EmptyListing.SubTypes.Headline'),
        text: t('AssetPicker.EmailTemplates.Table.EmptyListing.SubTypes.Text'),
        buttonText: t('AssetPicker.EmailTemplates.Table.EmptyListing.SubTypes.ButtonText'),
        buttonOnClick: () => {
          onFilterChange(FilterTypes.ALL_CATALOG_TEMPLATES)
        },
      }
    } else if (activeFilter === FilterTypes.RECENTLY_VIEWED) {
      return {
        imgSrc: StaticImageNames.emptySearch,
        headline: t('Template.Catalog.Modal.No.Templates.Headline'),
        text: 'Template.Catalog.Modal.No.Templates.Body',
      }
    }
  }

  return <EmptyListing {...getProps()} size={EmptyListingSize.LARGE} withoutBorder fullHeight dataTest={dataTest} className={rootClass} />
}

export default TemplateCatalogModalEmptyListing
