import React, { FC, ReactNode, RefObject, useRef } from 'react'

import classNames from 'classnames'

export interface ModalBodyProps {
  children: ReactNode
  ref?: RefObject<HTMLDivElement> | null
  onScroll?: (ref: RefObject<HTMLDivElement> | null) => void
  className?: string
  dataTest?: string
}

const rootClass = 'modal-body'

export const ModalBody: FC<ModalBodyProps> = (props: ModalBodyProps) => {
  const { ref, onScroll, dataTest } = props
  const modalRef = useRef<HTMLDivElement | null>(ref?.current ?? null)

  return (
    <div
      className={classNames(rootClass, props.className)}
      ref={modalRef}
      data-test={dataTest || rootClass}
      id={rootClass}
      onScroll={() => onScroll && onScroll(modalRef)}
    >
      {props.children}
    </div>
  )
}

export default ModalBody
