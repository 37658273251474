import React, { FC } from 'react'

import ListingPageWithTabs from '@complex/ListingPageWithTabs/ListingPageWithTabs'
import { rootContext, useTranslation } from '@const/globals'
import LandingPagesListingPageContainer from '@src/pages/listingPages/LandingPages/LandingPagesListingPageContainer'
import LandingPageTemplatesListingPageContainer from '@src/pages/listingPages/LandingPageTemplates/LandingPageTemplatesListingPageContainer'

interface LandingPagesAndTemplatesTabbedContainerProps {
  className?: string
  dataTest?: string
}

const LandingPagesAndTemplatesTabbedContainer: FC<LandingPagesAndTemplatesTabbedContainerProps> = (
  props: LandingPagesAndTemplatesTabbedContainerProps
) => {
  const { dataTest = `LandingPagesAndTemplatesTabbedContainer` } = props
  const { t } = useTranslation()
  const tabProps = [
    {
      label: t('Landing Pages'),
      component: <LandingPagesListingPageContainer />,
      pathName: `${rootContext}/content/landingPages`,
      pathnameId: 'landingPages',
    },
    {
      label: t('Templates'),
      component: <LandingPageTemplatesListingPageContainer />,
      pathName: `${rootContext}/content/landingPageTemplates`,
      pathnameId: 'landingPageTemplates',
    },
  ]

  return <ListingPageWithTabs tabProps={tabProps} dataTest={dataTest} />
}

export default LandingPagesAndTemplatesTabbedContainer
