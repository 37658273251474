import React, { FC, useCallback, useEffect, useMemo } from 'react'

import ImagePickerModalContainer from '@components/AssetPickers/ImagePickerModal/ImagePickerModalContainer'
import { ImagePickerModalInsertCallback, ImagePickerSource } from '@components/AssetPickers/ImagePickerModal/utils/ImagePickerModal.utils'
import { EditModal, EditModalProps } from '@components/EditModal/EditModal'
import Loader from '@components/Loader'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import StatusToast from '@components/StatusToast/StatusToast'
import Typography, { ModalHeaderFormStyle } from '@components/Typography/Typography'
import { ImageWithType } from '@components/UploadImage/UploadImage'
import { useTranslation } from '@const/globals'
import { StatusToastType } from '@interface/StatusToast'

import InsertImageURLBody from './components/InsertImageURLBody/InsertImageURLBody'
import UploadImageModalBody from './components/UploadImageBody/UploadImageModalBody'
import useFixModalPointerEvents from './hooks/useFixModalPointerEvents'
import { ImagePickerWithActionsModalContainerProps } from './ImagePickerWithActionsModalContainer'
import { InsertImageFlow, getAddImageFlowActions, getSourceMetadataFromImageURl } from './utils/ImagePickerWithActionsModal.utils'

import './ImagePickerWithActionsModal.css'

export interface ImageToInsertParams extends Partial<ImageWithType> {
  title?: string
  url?: string
  validUrl?: string
  folder?: SelectV2SingleOption
}

interface ImagePickerWithActionsModalProps extends ImagePickerWithActionsModalContainerProps {
  folders: SelectV2SingleOption[]
  loading?: boolean
  showInsertButtonEnabled?: boolean
  dataTest?: string
  setFlow: React.Dispatch<React.SetStateAction<InsertImageFlow>>
  handleInsert: () => void
  isUploadFlow: boolean
  isURLFlow: boolean
  isBrowseFlow: boolean
  imageParams: ImageToInsertParams
  setImageParams: React.Dispatch<React.SetStateAction<ImageToInsertParams>>
  setToastStatus: React.Dispatch<React.SetStateAction<StatusToastType | undefined>>
  toast: StatusToastType | undefined
  handleInsertFromPicker: ImagePickerModalInsertCallback
  showImagePickerBackButton?: boolean
  hideUploadFromURL?: boolean
  accept?: string
  imageMaxSize?: number
  allowSvg?: boolean
}

const rootClass = 'image-picker-with-actions-modal'

const ImagePickerWithActionsModal: FC<ImagePickerWithActionsModalProps> = ({
  folders,
  loading,
  dataTest = rootClass,
  isStory,
  showInsertButtonEnabled,
  toast,
  isURLFlow,
  isUploadFlow,
  isBrowseFlow,
  imageParams,
  imageUrlToReplace,
  showImagePickerBackButton,
  hideUploadFromURL,
  allowSvg,
  setFlow,
  handleInsert,
  setToastStatus,
  setImageParams,
  handleInsertFromPicker,
  handleImagePickerDone,
  accept,
  imageMaxSize,
}) => {
  const { t } = useTranslation()
  const { source, url: urlToReplace, id: urlIdToReplace } = getSourceMetadataFromImageURl(imageUrlToReplace)

  useFixModalPointerEvents(rootClass)

  useEffect(() => {
    folders[0] && setImageParams((cur) => ({ ...cur, folder: folders[0] }))
  }, [folders.length])

  const handleCancel = useCallback(() => {
    return handleImagePickerDone(undefined)
  }, [handleImagePickerDone])

  const handleBack = useCallback(() => setFlow(InsertImageFlow.BROWSE), [])
  const handleCloseToast = useCallback(() => setToastStatus({ showStatus: false }), [])
  const getHeaderAction = useCallback((flow: InsertImageFlow) => () => setFlow(flow), [])

  const headerActions = useMemo(() => {
    return getAddImageFlowActions(hideUploadFromURL).map(({ flow, ...rest }) => ({
      onClick: getHeaderAction(flow),
      ...rest,
    }))
  }, [])

  const activateInsert = (isUploadFlow && imageParams.imageBase64) || (isURLFlow && imageParams.validUrl && imageParams.validUrl !== urlToReplace)

  const modalProps: EditModalProps['modalProps'] = useMemo(
    (): EditModalProps['modalProps'] => ({
      rootModalProps: {
        dataTest,
        className: rootClass,
        isOpen: !isBrowseFlow,
      },
      header: {
        props: {
          className: `${rootClass}__header`,
          dataTest: `${dataTest}-header`,
        },
        content: <Typography text={t(isUploadFlow ? 'Upload image' : isURLFlow ? 'Add image from URL' : 'Add image')} {...ModalHeaderFormStyle} />,
      },
      body: {
        props: { className: `${rootClass}__body` },
        content: (
          <>
            {loading && <Loader blackout className={`${rootClass}__loader`} />}
            {isUploadFlow ? (
              <UploadImageModalBody
                imageParams={imageParams}
                setImageParams={setImageParams}
                folders={folders}
                maxSize={imageMaxSize}
                accept={accept}
              />
            ) : isURLFlow ? (
              <InsertImageURLBody setImageParams={setImageParams} url={imageParams.url} />
            ) : null}
          </>
        ),
      },
      footer: {
        props: {
          onClose: handleCancel,
          buttons: {
            cancelButtonLabel: t('Cancel'),
            actionButtonLabel: t('Insert'),
            actionButtonDisabled: !activateInsert,
            actionButtonOnClick: handleInsert,
          },
        },
      },
    }),
    [dataTest, isBrowseFlow, t, isUploadFlow, isURLFlow, loading, imageParams, setImageParams, folders, handleCancel, activateInsert, handleInsert]
  )

  return (
    <>
      {toast?.showStatus && (
        <StatusToast isSuccess={toast.successStatus} message={toast.statusMessage} title={toast.title} closeStatus={handleCloseToast} />
      )}
      <EditModal onBack={handleBack} modalProps={modalProps} />
      {isBrowseFlow && (
        <ImagePickerModalContainer
          showInsertButtonEnabled={showInsertButtonEnabled}
          onImageInsert={handleInsertFromPicker}
          headerActions={headerActions}
          urlIdToReplace={urlIdToReplace}
          isFromLogos={source === ImagePickerSource.LOGOS}
          isStory={isStory}
          showImagePickerBackButton={showImagePickerBackButton}
          accept={accept}
          imageMaxSize={imageMaxSize}
          allowSvg={allowSvg}
        />
      )}
    </>
  )
}

export default ImagePickerWithActionsModal
