import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import PageContainer from '@components/PageContainer'
import PageHeader from '@components/PageHeader'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import VisitorAlerts from '@src/pages/WebsiteProspectorAlerts/components/VisitorAlerts/VisitorAlerts'
import { VisitorAlertsTooltips } from '@src/pages/WebsiteProspectorAlerts/VisitorAlerts.constants'

import './WebsiteProspectorAlerts.css'

interface WebsiteProspectorAlertsProps {
  className?: string
  dataTest?: string
}

const rootClass = 'website-prospector-alerts'

const WebsiteProspectorAlerts: FC<WebsiteProspectorAlertsProps> = (props: WebsiteProspectorAlertsProps) => {
  const { dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()

  return (
    <PageContainer className={classNames(rootClass, className)} dataTest={dataTest}>
      <PageHeader primaryText={t('Website Prospector Alerts')} leftContent />
      <div className={`${rootClass}__filters-header`}>
        <Svg name={SvgNames.settingsTwoLines} type={SvgType.LARGER_ICON} />
        <Typography text={t('Global filters')} weight={TextWeight.MEDIUM} type={TextType.BODY_TEXT_LARGE} />
        <Tooltip trigger={<Svg name={SvgNames.info} type={SvgType.LARGER_ICON} />} position={'right'}>
          {t(VisitorAlertsTooltips.GLOBAL_FILTERS)}
        </Tooltip>
      </div>
      <Button buttonType={ButtonType.DASHED_PILL} className={`${rootClass}__button-global-filters`}>
        <Svg name={SvgNames.plusLight} type={SvgType.ICON} />
        {t('Add global filter')}
      </Button>
      <VisitorAlerts />
    </PageContainer>
  )
}

export default WebsiteProspectorAlerts
