import React, { ReactNode, useContext } from 'react'

import Checkbox from '@components/Checkbox'
import { LabelType, LabelV2 } from '@components/LabelV2/LabelV2'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { FieldDefinition, ProgramAdditionalEntity } from '@graphql/types/query-types'
import CRMDateField from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/components/CRMDateField/CRMDateField'
import CRMPickListField from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/components/CRMPickListField/CRMPickListField'
import CRMStringField from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/components/CRMStringField/CRMStringField'
import { DetailsDataType } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.constants'
import { EditCRMStepV2Context } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.context'
import { FieldDefinitionWithValue } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.interfaces'

interface RenderersCommonProps {
  field: FieldDefinition
  value: string
  onChange: (field: FieldDefinitionWithValue) => void
  register?: (instance: HTMLElement) => void
}

interface UseFieldInputRendererProps {
  className?: string
  dataTest?: string
  hasHelpers?: boolean
  entityIndex: number
  entity?: ProgramAdditionalEntity
}

export const useFieldInputRenderer = (props: UseFieldInputRendererProps) => {
  const { className, dataTest = className, hasHelpers = false, entityIndex, entity } = props

  const {
    values: { entitiesReferences },
  } = useContext(EditCRMStepV2Context)

  const renderDateField = ({ field, value = '', onChange }: RenderersCommonProps) => {
    return (
      <CRMDateField
        className={`${className}-date`}
        dataTest={`${dataTest}-radio`}
        field={field}
        onChange={(value) => onChange?.({ field, value })}
        value={value}
      />
    )
  }

  const renderReferenceField = ({ field, value = '', onChange }: RenderersCommonProps) => {
    const { displayName, required, fieldName, referenceEntity = '' } = field
    const options = entitiesReferences.get(referenceEntity)?.map<SelectV2SingleOption>(({ key = '', value = '' }) => ({ label: value, value: key }))
    if (options) {
      return (
        <div className={`${className}-select`} data-test={`${dataTest}-reference`}>
          <LabelV2 label={displayName} required={required} labelType={LabelType.input} />
          <SelectV2
            options={options}
            dataTest={`${dataTest}-field-${fieldName}`}
            isRequired={required}
            isClearable={!required}
            onChange={(option) => onChange?.({ field, value: option?.value ?? '' })}
            defaultValue={value}
            insideModal
          />
        </div>
      )
    }
  }

  const renderPickListField = ({ field, value = '', onChange }: RenderersCommonProps) => {
    return (
      <CRMPickListField
        className={className}
        field={field}
        onChange={(option) => onChange?.({ field, value: option?.value ?? '' })}
        value={value}
        entityIndex={entityIndex}
        entity={entity}
      />
    )
  }

  const renderStringField = ({ field, value = '', onChange, register }: RenderersCommonProps) => {
    return (
      <CRMStringField
        register={register}
        dataTest={`${dataTest}-string`}
        className={`${className}-input`}
        field={field}
        onChange={(value) => onChange?.({ field, value })}
        hasHelper={hasHelpers}
        value={value}
      />
    )
  }

  const renderBooleanField = ({ field, value = '', onChange }: RenderersCommonProps) => {
    const { displayName, fieldName } = field
    return (
      <Checkbox
        className={`${className}-checkbox`}
        dataTest={`${dataTest}-field-${fieldName}`}
        label={displayName}
        checked={value === 'true'}
        onChange={(value) => onChange?.({ field, value: value.toString() })}
      />
    )
  }

  return (parameters: RenderersCommonProps): ReactNode => {
    const { field } = parameters
    switch (field.dataType) {
      case DetailsDataType.BOOLEAN:
        return renderBooleanField(parameters)
      case DetailsDataType.DATETIME:
      case DetailsDataType.DATE:
        return renderDateField(parameters)
      case DetailsDataType.PICKLIST:
        return renderPickListField(parameters)
      case DetailsDataType.REFERENCE:
        return renderReferenceField(parameters)
      default:
        return renderStringField(parameters)
    }
  }
}
