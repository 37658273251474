import { AccountState } from '@utils/account/reducer'
import { getAccount } from '@utils/account/selectors'
import { NavigationState, getNavigation } from '@utils/navigation/navigation.utils'

export interface NavigationStateProps {
  navigation: NavigationState
  account: AccountState
}

function mapStateToProps(state: any): NavigationStateProps {
  return {
    navigation: getNavigation(state),
    account: getAccount(state),
  }
}

export default mapStateToProps
