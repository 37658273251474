import React from 'react'

import { ChartEventsOptions, FormatterCallbackFunction, Point, TooltipOptions } from 'highcharts'

import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { SwitchOptions } from '@components/Switch/Switch'
import { ColWithTooltip } from '@components/Table/components/tableColumns'
import { TableColumn } from '@components/Table/Table'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { ColWithTooltip as ColWithTooltipV2 } from '@components/TableV2/utils/tableV2ColumnUtils'
import { LegendLocation } from '@const/Chart.constants'
import { AJB_HELP_LINK } from '@src/pages/programs/dashboard/components/ProgramPerformance/ProgramPerformance.constants'
import { monthsForLocale } from '@utils/date'
import { titleCase } from '@utils/strings'

import {
  AllContactsData,
  CommonContactData,
  EarlyExitsContactsData,
  InProgramContactsData,
  StandardExistsContactsData,
} from './components/ProgramPerformanceTableContainer/ProgramPerformanceDetailTableContainer.utils'
import { labelFormatter } from './ProgramPerformanceDetail.utils'

export const TABLE_ROW_HEIGHT = 45
export const TABLE_HEIGHT = 134
export const ERROR_TABLE_DIFF = 18
export const EMPTY_TABLE_DIFF = 33
export const EMPTY_SEARCH_DIFF = 31

const colWithTooltip = (row: any) => <ColWithTooltip row={row} />

const commonColumns: TableColumn[] = [
  {
    Header: 'Name',
    accessor: 'name',
    align: 'left',
    minWidth: 200,
    maxWidth: 200,
    sortType: 'caseInsensitive',
    Cell: colWithTooltip,
  },
  {
    Header: 'Email',
    accessor: 'email',
    align: 'left',
    minWidth: 220,
    flexColumn: true,
    sortType: 'caseInsensitive',
    Cell: colWithTooltip,
  },
  {
    Header: 'Source List',
    accessor: 'sourceList',
    align: 'left',
    minWidth: 130,
    maxWidth: 130,
    sortType: 'caseInsensitive',
    Cell: colWithTooltip,
  },
]

export const allContactsColumns: TableColumn[] = [
  ...commonColumns,
  {
    Header: 'Entry Date & Time',
    accessor: 'entryDate',
    align: 'left',
    minWidth: 230,
    maxWidth: 230,
    sortType: 'statusDateTime',
    Cell: colWithTooltip,
  },
  {
    Header: 'Status',
    accessor: 'status',
    align: 'left',
    minWidth: 90,
    maxWidth: 90,
    sortType: 'caseInsensitive',
    Cell: colWithTooltip,
  },
]

export const inProgramColumns: TableColumn[] = [
  ...commonColumns,
  {
    Header: 'Entry Date & Time',
    accessor: 'entryDate',
    align: 'left',
    minWidth: 230,
    maxWidth: 230,
    sortType: 'statusDateTime',
    Cell: colWithTooltip,
  },
  {
    Header: 'Current Step',
    accessor: 'step',
    align: 'left',
    minWidth: 120,
    maxWidth: 120,
    sortType: 'caseInsensitive',
    Cell: (row: any) => colWithTooltip(row),
  },
]

export const standardExitsColumns: TableColumn[] = [
  ...commonColumns,
  {
    Header: 'Exit Date & Time',
    accessor: 'exitDate',
    align: 'left',
    minWidth: 230,
    maxWidth: 230,
    sortType: 'statusDateTime',
    Cell: colWithTooltip,
  },
  {
    Header: 'Exit Step',
    accessor: 'step',
    align: 'left',
    minWidth: 120,
    maxWidth: 120,
    sortType: 'caseInsensitive',
    Cell: colWithTooltip,
  },
]

export const earlyExitsColumns: TableColumn[] = [
  ...commonColumns,
  {
    Header: 'Exit Date & Time',
    accessor: 'exitDate',
    align: 'left',
    minWidth: 200,
    maxWidth: 200,
    Cell: colWithTooltip,
  },
  {
    Header: 'Early Exit Reason',
    accessor: 'exitReason',
    align: 'left',
    minWidth: 150,
    maxWidth: 150,
    Cell: colWithTooltip,
  },
]

const commonColumnsV2: ColumnDefWithAdditionalProps<CommonContactData>[] = [
  {
    header: 'Name',
    accessorKey: 'name',
    textAlign: 'left',
    enableCustomCellValue: true,
    sortingFn: 'caseInsensitive',
    cell: (cell) => ColWithTooltipV2({ cell }),
  },
  {
    header: 'Email',
    accessorKey: 'email',
    textAlign: 'left',
    maxSize: 250,
    enableCustomCellValue: true,
    sortingFn: 'caseInsensitive',
    cell: (cell) => ColWithTooltipV2({ cell }),
  },
  {
    header: 'Source List',
    accessorKey: 'sourceList',
    textAlign: 'left',
    maxSize: 180,
    enableCustomCellValue: true,
    sortingFn: 'caseInsensitive',
    cell: (cell) => ColWithTooltipV2({ cell }),
  },
]

export const allContactsColumnsV2: ColumnDefWithAdditionalProps<AllContactsData>[] = [
  ...(commonColumnsV2 as ColumnDefWithAdditionalProps<AllContactsData>[]),
  {
    header: 'Entry Date & Time',
    accessorKey: 'entryDate',
    textAlign: 'left',
    maxSize: 220,
    sortingFn: 'statusDateTime',
    enableCustomCellValue: true,
    cell: (cell) => ColWithTooltipV2({ cell }),
  },
  {
    header: 'Status',
    accessorKey: 'status',
    textAlign: 'left',
    maxSize: 90,
    enableCustomCellValue: true,
    sortingFn: 'caseInsensitive',
    cell: (cell) => ColWithTooltipV2({ cell }),
  },
]

export const inProgramColumnsV2: ColumnDefWithAdditionalProps<InProgramContactsData>[] = [
  ...(commonColumnsV2 as ColumnDefWithAdditionalProps<InProgramContactsData>[]),
  {
    header: 'Entry Date & Time',
    accessorKey: 'entryDate',
    textAlign: 'left',
    maxSize: 220,
    sortingFn: 'statusDateTime',
    enableCustomCellValue: true,
    cell: (cell) => ColWithTooltipV2({ cell }),
  },
  {
    header: 'Current Step',
    accessorKey: 'step',
    textAlign: 'left',
    maxSize: 120,
    sortingFn: 'caseInsensitive',
    enableCustomCellValue: true,
    cell: (cell) => ColWithTooltipV2({ cell }),
  },
]

export const standardExitsColumnsV2: ColumnDefWithAdditionalProps<StandardExistsContactsData>[] = [
  ...(commonColumnsV2 as ColumnDefWithAdditionalProps<StandardExistsContactsData>[]),
  {
    header: 'Exit Date & Time',
    accessorKey: 'exitDate',
    textAlign: 'left',
    maxSize: 230,
    sortingFn: 'statusDateTime',
    enableCustomCellValue: true,
    cell: (cell) => ColWithTooltipV2({ cell }),
  },
  {
    header: 'Exit Step',
    accessorKey: 'step',
    textAlign: 'left',
    maxSize: 120,
    sortingFn: 'caseInsensitive',
    enableCustomCellValue: true,
    cell: (cell) => ColWithTooltipV2({ cell }),
  },
]

export const earlyExitsColumnsV2: ColumnDefWithAdditionalProps<EarlyExitsContactsData>[] = [
  ...(commonColumnsV2 as ColumnDefWithAdditionalProps<EarlyExitsContactsData>[]),
  {
    header: 'Exit Date & Time',
    accessorKey: 'exitDate',
    textAlign: 'left',
    maxSize: 200,
    enableCustomCellValue: true,
    cell: (cell) => ColWithTooltipV2({ cell }),
  },
  {
    header: 'Early Exit Reason',
    accessorKey: 'exitReason',
    textAlign: 'left',
    maxSize: 150,
    enableCustomCellValue: true,
    cell: (cell) => ColWithTooltipV2({ cell }),
  },
]

const months = monthsForLocale().map((mon) => titleCase(mon))
type ChartFieldData = number[]

export const allContactsDetail = {
  chartType: SwitchOptions.BAR,
  tableTitle: 'Contact Entries',
  emptyState: {
    imgSrc: StaticImageNames.emptyChartMedium,
    headline: 'No one’s here!',
    text: `You'll see detailed contact information here when it becomes available.`,
    linkText: 'Need help with Automated Programs?',
    link: AJB_HELP_LINK,
  },
  tableSubtitle: `All current and past contacts in this program's history.`,
  showDuplicatesWarning: false,
  columns: allContactsColumns,
  canPaginate: true,
  initialState: {
    pageIndex: 0,
    pageSize: 20,
  },
}

export const allContactsChartDataC = {
  title: 'All Contacts',
  categories: months,
  fields: [
    {
      name: 'Entries',
      data: [] as ChartFieldData,
      color: '#7B9FED',
    },
    {
      name: 'Exits',
      data: [] as ChartFieldData,
      color: '#D188B6',
    },
  ],
}

export const inProgramDetail = {
  tableTitle: 'Contacts in Program',
  emptyState: {
    imgSrc: StaticImageNames.emptyContacts,
    headline: 'There are no contacts in the program',
    text: 'Detailed information appears here when contacts are in the program.',
    linkText: 'Need help with Automated Programs?',
    link: AJB_HELP_LINK,
  },
  tableSubtitle: 'All contacts currently in this program.',
  hideChart: true,
  showDuplicatesWarning: false,
  columns: inProgramColumns,
  canPaginate: true,
  initialState: {
    pageIndex: 0,
    pageSize: 20,
  },
}

export const inProgramChartDataC = {
  title: 'Contacts in Program',
  categories: months,
  chartType: SwitchOptions.GRAPH,
  hideLegend: true,
  fields: [
    {
      name: 'Entries',
      data: [] as ChartFieldData,
      color: '#7B9FED',
    },
  ],
}

export const earlyExitsChartDataLabels = {
  enabled: true,
  useHTML: true,
  crop: false,
  allowOverlap: true,
  padding: 0,
  distance: -20,
  formatter: labelFormatter,
  style: {
    color: '#000',
    fontSize: '12px',
    fontWeight: '400',
    whiteSpace: 'pre',
  },
}

export const earlyExitsChartTooltip: TooltipOptions = {
  enabled: true,
  outside: true,
  useHTML: true,
  shadow: false,
  hideDelay: 0.1,
  borderWidth: 0,
  borderRadius: 0,
  backgroundColor: 'none',
  headerFormat: '',
  pointFormatter: labelFormatter as FormatterCallbackFunction<Point>,
  style: {
    color: '#000',
    fontSize: '12px',
    fontWeight: '400',
    whiteSpace: 'pre',
  },
}

export const earlyExitsChartEvents: ChartEventsOptions = {
  render: function (this) {
    const legendItems = this.legend.allItems
    this.series.forEach((series) => {
      series.points.forEach((point: any) => {
        point.dataLabel?.hide()
      })
    })

    legendItems.forEach((item: any) => {
      const group = item.legendItem.element
      group.onmouseover = () => {
        item.dataLabel?.show()
      }
      group.onmouseout = () => {
        item.dataLabel?.hide()
      }
    })
  },
}

export const earlyExitsChartDataC = {
  title: 'Early Exit Contacts by Condition',
  legendLocation: LegendLocation.RIGHT,
  wideLegend: true,
  chartHeight: 300,
  chartType: SwitchOptions.PIE,
  isDonut: true,
  showValuePercentage: true,
  fields: [
    {
      name: 'Early Exits by Condition',
      colorByPoint: true,
      data: [],
    },
  ],
  showTooltip: true,
  tooltip: earlyExitsChartTooltip,
  dataLabels: earlyExitsChartDataLabels,
  events: earlyExitsChartEvents,
}

export const earlyExitsDetail = {
  tableTitle: 'Early Exit Contacts',
  emptyState: {
    imgSrc: StaticImageNames.emptyContacts,
    headline: 'There are no early exits',
    text: 'Detailed information appears here when contacts have exited the program early.',
    linkText: 'Need help with Automated Programs?',
    link: AJB_HELP_LINK,
  },
  tableSubtitle: 'These contacts exited the program based on early exit conditions.',
  showDuplicatesWarning: false,
  columns: earlyExitsColumns,
  canPaginate: true,
  initialState: {
    pageIndex: 0,
    pageSize: 20,
  },
}

export const standardExitsDetail = {
  tableTitle: 'Standard Exit Contacts',
  emptyState: {
    imgSrc: StaticImageNames.emptyContacts,
    headline: 'There are no standard exits',
    text: 'Detailed information appears here when contacts have exited the program.',
    linkText: 'Need help with Automated Programs?',
    link: AJB_HELP_LINK,
  },
  tableSubtitle: 'All contacts who left the program from an exit step.',
  showDuplicatesWarning: false,
  columns: standardExitsColumns,
  canPaginate: true,
  initialState: {
    pageIndex: 0,
    pageSize: 20,
  },
  hideChart: false,
}

export const standardExitsChartDataC = {
  title: 'Standard Exit Contacts',
  categories: months,
  chartType: SwitchOptions.BAR,
  hideLegend: true,
  fields: [
    {
      name: 'Exits',
      data: [] as ChartFieldData,
      color: '#D188B6',
    },
  ],
}
