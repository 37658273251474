import React, { FC, useState } from 'react'
import { useController, UseFormReturn } from 'react-hook-form'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import InputV2 from '@components/InputV2/InputV2'
import { LabelType, LabelV2 } from '@components/LabelV2/LabelV2'
import { SelectV2GroupedOption, SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import SelectV2Popover from '@components/SelectV2Popover/SelectV2Popover'
import Svg, { SvgNames } from '@components/Svg'
import { SvgColor, SvgContainerType, SvgType } from '@components/Svg/Svg'
import TooltipButton from '@components/TooltipButton/TooltipButton'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { CustomAddonModalForm } from '@src/pages/EmailComposer/utils/Addons/customAddons.types'
import { checkURLValidity } from '@utils/formUtils'
import { renderBoldTextOnFirstMatch } from '@utils/typography'

import {
  EventModalTabs,
  PLACEHOLDER_VIDEO_CONFERENCE_OPTION,
  URL_VALIDATION_MESSAGES,
  validateEventUrl,
  webinarIcons,
  WebinarType,
} from '../../utils/EventModal.utils'

import './WebinarDetails.css'

interface WebinarDetailsProps {
  form: UseFormReturn<CustomAddonModalForm>
  webinarOptions: SelectV2GroupedOption[]
  webinarTypeOptions: SelectV2SingleOption[]
  selectedTab: EventModalTabs
  withHeader?: boolean
  onHeaderClose?: () => void
  className?: string
  dataTest?: string
}

const rootClass = 'webinar-details'

const WebinarDetails: FC<WebinarDetailsProps> = (props: WebinarDetailsProps) => {
  const { form, selectedTab, webinarTypeOptions, withHeader = false, onHeaderClose } = props

  const {
    details: { addVideoConferencing },
    webinarDetails: { webinarLink: webinarLinkValue, webinarPasscode: webinarPasscodeValue },
  } = form.getValues()

  const { t } = useTranslation()
  const [webinarLinkError, setWebinarLinkError] = useState<string | undefined>()

  const { field: selectedWebinarType } = useController({
    control: form.control,
    name: 'webinarDetails.selectedWebinarType',
    rules: {
      required: addVideoConferencing || selectedTab === EventModalTabs.ONLINE,
      validate: () => (form.getValues().webinarDetails.selectedWebinarType?.label === PLACEHOLDER_VIDEO_CONFERENCE_OPTION.label ? '' : undefined),
    },
  })
  const { field: webinarLink } = useController({
    control: form.control,
    name: 'webinarDetails.webinarLink',
    rules: {
      validate: (url: string) => validateEventUrl(url, t, setWebinarLinkError),
    },
  })
  const { field: webinarPasscode } = useController({ control: form.control, name: 'webinarDetails.webinarPasscode' })

  const buttonProps = { buttonType: ButtonType.INFO, className: `${rootClass}__select-popover__trigger` }
  const isPlaceholderOption = selectedWebinarType.value?.label === PLACEHOLDER_VIDEO_CONFERENCE_OPTION.label

  const getInputLabel = () => {
    if (selectedWebinarType.value?.label === WebinarType.ZOOM_MEETING) {
      return 'Zoom invite link'
    } else if (selectedWebinarType.value?.label === WebinarType.EXTERNAL_EVENT_LINK) {
      return 'Event link'
    } else {
      return `${selectedWebinarType.value?.label} link`
    }
  }

  const renderHeader = () => (
    <div className={`${rootClass}__select-card-header`}>
      <LabelV2 label={t('EmailComposer.EventModal.VideoConferenceDetails.Header.Label')} labelType={LabelType.section} withoutMargin />
      <TooltipButton
        onClick={() => onHeaderClose && onHeaderClose()}
        title={t('EmailComposer.EventModal.VideoConferenceDetails.Header.Tooltip')}
        buttonType={ButtonType.NAVIGATION_BUTTON}
        dataTest={`${rootClass}-remove-video-conferencing`}
      >
        <Svg name={SvgNames.close} />
      </TooltipButton>
    </div>
  )

  const renderAnchor = () => (
    <div className={`${rootClass}__select-popover-selected`}>
      <div className={classNames({ [`${rootClass}__select-popover-selected-webinar`]: isPlaceholderOption })}>
        <Svg
          name={webinarIcons[selectedWebinarType.value?.label ?? '']}
          type={isPlaceholderOption ? SvgType.LARGE_ICON : SvgType.VERY_LARGE_ICON}
          fill={webinarIcons[selectedWebinarType.value?.label ?? ''] === SvgNames.url ? SvgColor.TEXT_TEAL : SvgColor.LIGHT_GRAY}
          containerType={SvgContainerType.LARGE}
        />
      </div>
      <div>
        <LabelV2 label={selectedWebinarType?.value?.label} labelType={LabelType.medium} required />
        <Typography text={selectedWebinarType?.value?.subText} type={TextType.BODY_TEXT_SMALL_LIGHT} />
      </div>
    </div>
  )

  const renderCustomOption = (data: SelectV2SingleOption, search?: string) => (
    <>
      <Typography
        inline
        dataTest={'selectv2-option-value'}
        text={search ? renderBoldTextOnFirstMatch({ search, value: data.label }) : data.label}
        weight={TextWeight.MEDIUM}
      />
      {data.subText && <Typography text={data.subText} type={TextType.BODY_TEXT_LIGHT} lineHeight={LineHeight.MEDIUM_SMALL} />}
    </>
  )

  return (
    <div className={`${rootClass}__select-card`}>
      {withHeader && renderHeader()}
      <SelectV2Popover
        renderTrigger={() => <Button {...buttonProps}>{t(isPlaceholderOption ? 'Select' : 'Change')}</Button>}
        renderAnchor={renderAnchor}
        selectProps={{
          options: webinarTypeOptions,
          onChange: (option) => {
            form.setValue('webinarDetails.webinarLink', '')
            form.setValue('webinarDetails.webinarPasscode', '')
            form.setValue('webinarDetails.selectedWebinar', undefined)
            selectedWebinarType.onChange(option)
          },
          insideModal: true,
          value: selectedWebinarType.value,
          renderCustomOption,
          optionsWithLargePadding: true,
        }}
        className={`${rootClass}__select-popover`}
      />
      {!isPlaceholderOption && (
        <>
          <div>
            <InputV2
              handleValueChangeFromOuter
              placeholder={'https://'}
              leadingIcon={SvgNames.linkedUnselected}
              labelProps={{ label: getInputLabel(), labelType: LabelType.input }}
              value={webinarLinkValue}
              onChange={webinarLink.onChange}
              statusProps={{ showErrorOnChange: true, validityFunctions: [checkURLValidity], tooltipErrorMessages: URL_VALIDATION_MESSAGES }}
              inputInfo={{ enabled: !!webinarLinkError, errorText: webinarLinkError }}
            />
          </div>
          <div>
            <InputV2
              handleValueChangeFromOuter
              labelProps={{ label: t('EmailComposer.EventModal.VideoConferenceDetails.Passcode'), labelType: LabelType.input }}
              leadingIcon={SvgNames.lockLight}
              value={webinarPasscodeValue}
              onChange={webinarPasscode.onChange}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default WebinarDetails
