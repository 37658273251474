import React, { FC, ReactNode, useMemo } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import DraggableColumn, { Column, ColumnStatus, ColumnType } from '@components/ColumnsOrderModal/components/DraggableColumn/DraggableColumn'
import { isHidden, isLocked } from '@components/ColumnsOrderModal/utils/ColumnsOrderModal.utils'
import DragLayer from '@components/DragLayer/DragLayer'
import ScrollArea from '@components/ScrollArea/ScrollArea'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './EditColumnsListing.css'

export enum EditColumnsAction {
  DROP,
  PIN,
  REMOVE,
}

interface EditColumnsListingProps {
  className?: string
  dataTest?: string
  columns: Column[]
  lockedLimit?: number
  onChange: (column: Column, action: EditColumnsAction, droppedAt?: number) => void
  onReset?: VoidFunction
  inDropdown?: boolean
}

const DEFAULT_LOCKED_COLUMNS_LIMIT = 3
const rootClass = 'edit-columns-listing'

const EditColumnsListing: FC<EditColumnsListingProps> = (props: EditColumnsListingProps) => {
  const { dataTest = rootClass, className = '', lockedLimit = DEFAULT_LOCKED_COLUMNS_LIMIT, onChange, onReset, columns, inDropdown = true } = props

  const { t } = useTranslation()

  const lockedColumnsSize = useMemo(() => columns.filter((column) => isLocked(column)).length, [columns])
  const hiddenColumnsSize = useMemo(() => columns.filter((column) => isHidden(column)).length, [columns])

  const onDrop = (column: Column, droppedAt: number) => {
    onChange(column, EditColumnsAction.DROP, droppedAt)
  }

  const onLock = (column: Column) => {
    onChange(column, EditColumnsAction.PIN)
  }

  const onRemove = (column: Column) => {
    onChange(column, EditColumnsAction.REMOVE)
  }

  const renderColumns = () => {
    const renderColumn = (column: Column, listingPosition: number, index: number) => {
      const isFormField = column.type === ColumnType.FORM
      const isLocked = column.status === ColumnStatus.LOCKED
      return (
        <DraggableColumn
          key={`${column.name}-${index}`}
          className={`${rootClass}__column`}
          dataTest={`${dataTest}__column`}
          column={{ ...column, index }}
          onDrop={onDrop}
          onLock={onLock}
          onRemove={!isFormField && !isLocked ? onRemove : undefined}
          disableLock={lockedColumnsSize >= lockedLimit}
          disableHide={hiddenColumnsSize === columns.length - 1}
          listingPosition={listingPosition}
        />
      )
    }

    return columns.reduce(
      (renderedColumns: { components: ReactNode[]; listingPosition: number }, column, index) => {
        if (column.status === ColumnStatus.HIDDEN) {
          return renderedColumns
        }
        return {
          components: [...renderedColumns.components, renderColumn(column, renderedColumns.listingPosition, index)],
          listingPosition: renderedColumns.listingPosition + 1,
        }
      },
      { components: [], listingPosition: 1 }
    ).components
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={classNames(`${rootClass}__header`, { [`${className}-header`]: className })}>
        <Typography
          text={t(`Selected column fields ({{selectedColumns}})`, { selectedColumns: columns.length - hiddenColumnsSize })}
          weight={TextWeight.MEDIUM}
          type={TextType.BODY_TEXT_LARGE}
          lineHeight={LineHeight.MEDIUM_LARGE}
        />
        {onReset && (
          <Button dataTest={`${dataTest}-reset-button`} buttonType={ButtonType.TEXT_TEAL} onClick={onReset}>
            <Svg name={SvgNames.reset} type={SvgType.MEDIUM} />
            {t('Reset to default')}
          </Button>
        )}
      </div>
      <DndProvider backend={HTML5Backend}>
        <DragLayer />
        <div className={classNames(`${rootClass}__body`, { [`${className}-body`]: className, [`${rootClass}__scrollbar`]: inDropdown })}>
          {inDropdown ? (
            renderColumns()
          ) : (
            <ScrollArea className={`${rootClass}__scroll`} showOnEvent={'scroll'}>
              {renderColumns()}
            </ScrollArea>
          )}
        </div>
      </DndProvider>
    </div>
  )
}

export default EditColumnsListing
