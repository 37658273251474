import { gql } from '@apollo/client'

export default gql`
  query CrmReferenceEntity($type: String!) {
    crmReferenceEntities(entity: $type) {
      key
      value
    }
  }
`
