import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'

import { ACCOUNT_DATE_LEARN_MORE, AccountDateFormats, PERSONALIZATION_LEARN_MORE } from '@complex/Personalization/Personalization.constants'
import { Locales } from '@complex/Personalization/utils/locales-2'
import { PersonalizationItem } from '@complex/Personalization/utils/Personalization.context'
import AssetSelect from '@components/AssetSelect/AssetSelect'
import { EditModal } from '@components/EditModal/EditModal'
import FormRow from '@components/FormRow'
import { default as Input, default as InputV2 } from '@components/InputV2/InputV2'
import { LabelType, LabelV2 } from '@components/LabelV2/LabelV2'
import { LinkTextButton, LinkTextButtonSize } from '@components/LinkTextButton/LinkTextButton'
import { getAccountDateFieldText } from '@components/PersonalizationTagInput/PersonalizationTagInput.utils'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { SvgNames } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './PersonalizationFallbackModal.css'

export interface PersonalizationFallbackModalProps {
  onInsert: (fallbackText: string, dateFormat?: string, locale?: string, offset?: string) => void
  onCancel: () => void
  onPickerModalOpen: () => void
  onBack?: () => void
  activeFilterSvg?: SvgNames
  selectedItem?: PersonalizationItem
  dataTest?: string
  isStory?: boolean
  learnMoreLink?: string
}

const getFormatHelperMessage = (format: string, includeOffset: boolean) => {
  const formatWithDefault = format || 'DEFAULT'
  return `PersonalizationModal.FallbackModal.${includeOffset ? 'AccountDateOffsetField' : 'AccountDateField'}.HelperMessage.${formatWithDefault}`
}

const rootClass = 'personalization-fallback-modal'

export const PersonalizationFallbackModal: FC<PersonalizationFallbackModalProps> = (props: PersonalizationFallbackModalProps) => {
  const { selectedItem, activeFilterSvg, onBack, onInsert, onCancel, onPickerModalOpen, dataTest = rootClass, learnMoreLink } = props

  const { t } = useTranslation()

  const isAccountDateField = selectedItem?.title.includes('Account Date') || selectedItem?.title.includes('Account.DATE')
  const includeOffset = (selectedItem?.title.includes('Account Date with Offset') || selectedItem?.title.includes('Account.DATEWITHOFFSET')) ?? false

  const [canSubmit, setCanSubmit] = useState(true)
  const [fallbackText, setFallbackText] = useState(selectedItem?.fallbackText ?? '')
  const [dateFormat, setDateFormat] = useState<SelectV2SingleOption>()
  const [offset, setOffset] = useState<string>()
  const [offsetError, setOffsetError] = useState<string>()

  const [locale, setLocale] = useState<SelectV2SingleOption | undefined>(Locales[0])

  useEffect(() => {
    if (isAccountDateField && selectedItem?.mapping) {
      const parts = selectedItem.mapping.split('(')
      if (parts.length > 1) {
        const params = parts[1].replace(')', '').split(',')
        const dFormat = AccountDateFormats.find((format) => format.value === params[0].trim())
        setDateFormat(dFormat)

        if (includeOffset) {
          setOffset(params[1].trim())
        }

        const rLocale = Locales.find((l) => l.value === (includeOffset ? params[2] : params[1]).trim())
        setLocale(rLocale)
      }
    }
  }, [includeOffset, isAccountDateField, selectedItem])

  useEffect(() => {
    if (isAccountDateField) {
      setCanSubmit(!!dateFormat && (!includeOffset || !!offset))
    }
  }, [dateFormat, includeOffset, isAccountDateField, offset])

  const accountDateTitle = useMemo(() => {
    if (selectedItem?.title.startsWith('^')) {
      return getAccountDateFieldText(selectedItem?.title ?? '')
    }

    return selectedItem?.title
  }, [selectedItem])

  const handleCancel = useCallback(() => {
    onCancel()
  }, [onCancel])

  const handleInsert = useCallback(() => {
    onInsert(fallbackText, dateFormat?.value, locale?.value ?? 'en-US', offset)
  }, [onInsert, fallbackText, dateFormat?.value, locale?.value, offset])

  const formatOptionLabel = useCallback(
    () => (
      <Typography
        text={t(`PersonalizationModal.FallbackModal.PersonalizationField.Selected`, {
          groupName: t(selectedItem?.group),
          personalization: selectedItem?.title,
        })}
        tagProps={{ group: { type: TextType.BODY_TEXT_LIGHT, weight: TextWeight.MEDIUM } }}
        inline
      />
    ),
    [selectedItem]
  )

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setFallbackText(e.target.value), [setFallbackText])

  const handleOffsetChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value
      const isOnlyMinus = val.length === 1 && val === '-'
      const hasDot = val.includes('.')
      if (hasDot || (!isOnlyMinus && (isNaN(Number(val)) || val == ''))) {
        setOffsetError(t('PersonalizationModal.FallbackModal.AccountDateOffset.Error'))
      } else {
        setOffsetError(undefined)
        setOffset(val)
      }
    },
    [t]
  )

  const renderFallbackRow = useMemo(
    () => (
      <FormRow>
        <Input
          labelProps={{
            label: t('PersonalizationModal.FallbackModal.Input.Label'),
            labelType: 'medium',
            tooltip: { content: t('PersonalizationModal.FallbackModal.Tooltip'), position: 'right' },
          }}
          placeholder={t('PersonalizationModal.FallbackModal.Input.Placeholder')}
          value={fallbackText}
          dataTest={`${dataTest}-input`}
          onChange={handleInputChange}
        />
      </FormRow>
    ),
    [dataTest, fallbackText, handleInputChange, t]
  )

  const renderAccountDateRows = useMemo(
    () => (
      <>
        <FormRow>
          <LabelV2 label={t('PersonalizationModal.FallbackModal.AccountDateField.Label')} labelType={LabelType.medium} />
          <SelectV2
            value={dateFormat}
            placeholder={t('PersonalizationModal.FallbackModal.AccountDateField.Placeholder')}
            options={AccountDateFormats}
            helperMessage={t(getFormatHelperMessage(dateFormat?.value ?? '', includeOffset))}
            insideModal
            onChange={setDateFormat}
            dataTest={`${dataTest}-date-format`}
          />
        </FormRow>
        {includeOffset && (
          <FormRow>
            <InputV2
              labelProps={{
                label: t('PersonalizationModal.FallbackModal.AccountDateOffset.Label'),
                labelType: 'medium',
                tooltip: { content: t('PersonalizationModal.FallbackModal.AccountDateOffset.Tooltip'), position: 'right' },
              }}
              placeholder={t('PersonalizationModal.FallbackModal.AccountDateOffset.Placeholder')}
              value={offset}
              error={!!offsetError}
              inputInfo={{
                enabled: true,
                helperText: t('PersonalizationModal.FallbackModal.AccountDateOffset.HelperMessage'),
                errorText: offsetError,
              }}
              dataTest={`${dataTest}-offset`}
              onChange={handleOffsetChange}
              onBlur={handleOffsetChange}
            />
          </FormRow>
        )}
        <FormRow>
          <LabelV2
            label={
              <>
                <Typography text={t('PersonalizationModal.FallbackModal.Locale.Label')} weight={TextWeight.MEDIUM} inline />
                <Typography
                  inline
                  text={t('PersonalizationModal.FallbackModal.Locale.Label.Optional')}
                  type={TextType.BODY_TEXT_LIGHT}
                  weight={TextWeight.REGULAR}
                />
              </>
            }
            labelType={LabelType.medium}
          />
          <SelectV2
            value={locale}
            options={Locales}
            helperMessage={t('PersonalizationModal.FallbackModal.Locale.HelperMessage')}
            insideModal
            onChange={setLocale}
            dataTest={`${dataTest}-locale`}
          />
        </FormRow>
      </>
    ),
    [dataTest, dateFormat, handleOffsetChange, includeOffset, locale, offset, offsetError, t]
  )

  const modalProps = useMemo(
    () => ({
      header: { content: <Typography text={t('Personalize')} type={TextType.DATA_CARD_MODAL_HEADER} /> },
      body: {
        content: (
          <>
            <FormRow>
              <LabelV2 className={`${rootClass}__label`} labelType={LabelType.medium}>
                {t('PersonalizationModal.FallbackModal.PersonalizationField.Placeholder')}
              </LabelV2>
              <AssetSelect
                selectedAssets={isAccountDateField ? accountDateTitle : selectedItem?.title}
                renderCustomSelectedAsset={formatOptionLabel}
                placeholder={t('PersonalizationModal.FallbackModal.PersonalizationField.Text')}
                svgIcon={activeFilterSvg ?? SvgNames.usersUnselectedFull}
                onClick={onPickerModalOpen}
              />
            </FormRow>

            {isAccountDateField ? renderAccountDateRows : renderFallbackRow}
          </>
        ),
        description: (
          <>
            <Typography
              text={t(
                includeOffset
                  ? 'PersonalizationModal.FallbackModal.Text.AccountDateWithOffset'
                  : isAccountDateField
                  ? 'PersonalizationModal.FallbackModal.Text.AccountDate'
                  : 'PersonalizationModal.FallbackModal.Text'
              )}
              type={TextType.NORMAL_TEXT_GRAY_LARGE}
              inline
              className={`${rootClass}__description`}
              tagComponents={{
                LinkTextButton: (
                  <LinkTextButton
                    inline
                    hideIconLeft
                    size={LinkTextButtonSize.MEDIUM}
                    text={t('PersonalizationModal.FallbackModal.Link')}
                    link={isAccountDateField ? ACCOUNT_DATE_LEARN_MORE : learnMoreLink || PERSONALIZATION_LEARN_MORE}
                  />
                ),
              }}
            />
          </>
        ),
      },
    }),
    [
      accountDateTitle,
      activeFilterSvg,
      formatOptionLabel,
      includeOffset,
      isAccountDateField,
      onPickerModalOpen,
      renderAccountDateRows,
      renderFallbackRow,
      selectedItem,
      t,
    ]
  )

  return (
    <EditModal
      canSubmit={canSubmit}
      dataTest={rootClass}
      onBack={onBack}
      handleCancel={handleCancel}
      handlePrimaryButton={handleInsert}
      modalProps={modalProps}
    />
  )
}
