import React, { useContext } from 'react'
import { components, InputProps } from 'react-select'

import { SelectV2Context } from '@components/SelectV2/SelectV2.context'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'

import './CustomInput.css'

const rootClass = 'custom-input'

const CustomInput = (props: InputProps<any>) => {
  const {
    values: { hiddenChipsCount = 0 },
  } = useContext(SelectV2Context)

  return (
    <div className={rootClass}>
      {hiddenChipsCount > 0 && (
        <Typography className={`${rootClass}__counter`} type={TextType.NORMAL_TEXT_TEAL} weight={TextWeight.MEDIUM} text={`+${hiddenChipsCount}`} />
      )}
      <components.Input {...props} />
    </div>
  )
}

export default CustomInput
