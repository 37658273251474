import React, { FC } from 'react'

import ModalBody, { ModalBodyProps } from '@components/Modal/components/ModalBody'
import ModalFooterV2, { ModalFooterV2Props } from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import ModalHeaderV2, { ModalHeaderV2Props } from '@components/Modal/components/ModalHeaderV2/ModalHeaderV2'
import Modal, { ModalProps } from '@components/Modal/Modal'
import ScrollArea from '@components/ScrollArea/ScrollArea'
import { useTranslation } from '@const/globals'

import './EditModal.css'

export interface EditModalProps {
  isStory?: boolean
  dataTest?: string
  useScroll?: boolean
  canSubmit?: boolean
  footerActionButtonLabel?: string
  modalProps: {
    rootModalProps?: Partial<ModalProps>
    footer?: { props?: Partial<ModalFooterV2Props> }
    header: { content: JSX.Element; props?: Partial<ModalHeaderV2Props> }
    body: {
      description?: JSX.Element
      content: JSX.Element
      props?: Partial<ModalBodyProps>
    }
  }
  onBack?: (backFromFallback?: boolean) => void
  handlePrimaryButton?: () => void
  handleCancel?: () => void
}

export const rootClass = 'edit-modal'

export const EditModal: FC<EditModalProps> = ({
  canSubmit,
  dataTest = rootClass,
  modalProps,
  useScroll,
  footerActionButtonLabel,
  onBack,
  handlePrimaryButton,
  handleCancel,
}: EditModalProps) => {
  const { t } = useTranslation()

  const {
    body: { content: bodyContent, description, props: bodyProps },
    header: { content: headerContent, props: headerProps },
    footer: { props: footerProps } = {},
    rootModalProps,
  } = modalProps

  const modalBody = (
    <ModalBody className={`${rootClass}__body`} {...bodyProps}>
      {description}
      <div className={`${rootClass}__content`}>{bodyContent}</div>
    </ModalBody>
  )

  const computedBody = useScroll ? (
    <ScrollArea showOnEvent={'always'} className={`${rootClass}__scroll`}>
      {modalBody}
    </ScrollArea>
  ) : (
    modalBody
  )

  return (
    <Modal
      isOpen
      dataTest={dataTest}
      className={rootClass}
      header={
        <ModalHeaderV2
          headerType="form"
          headerText={headerContent}
          iconProps={onBack ? { onClick: () => onBack(false), hasBackButton: true } : undefined}
          className={`${rootClass}__modal-header`}
          {...headerProps}
        />
      }
      {...rootModalProps}
    >
      {computedBody}

      <ModalFooterV2
        onClose={handleCancel}
        footerType="form"
        buttons={{
          cancelButtonLabel: t('Cancel'),
          actionButtonLabel: t(footerActionButtonLabel) || t('Insert'),
          actionButtonDisabled: !canSubmit,
          actionButtonOnClick: handlePrimaryButton,
        }}
        {...footerProps}
      />
    </Modal>
  )
}
