import { SavingMessage } from '@components/AutoSavingStatus/AutoSavingStatus'
import { State } from '@src/pages/sms/edit/EditSMSContainer'
import SegmentedMessage from '@src/pages/sms/edit/utils/segmentationCalculator'
import { emptySMSListingStatusToast } from '@src/pages/sms/listing/SMSListingContainer'

export const smsStateDefaultValues: State = {
  smsMessage: {
    launchId: '',
    description: '',
    title: '',
    messageText: '',
    recipients: [],
    recipientsPerCreditMultiplier: [
      {
        contacts: 0,
        contactsPerCountry: [
          {
            country: 'United States',
            contacts: 0,
          },
        ],
        creditMultiplier: 0,
      },
    ],
    recipientTimezones: [],
  },
  senderCode: '',
  availableCredits: -1,
  smsNextMonthCredits: -1,
  costToSend: 0,
  suppressions: [],
  segmentedMessage: new SegmentedMessage(''),
  messageCaretPosition: 0,
  errors: {},
  pageError: false,
  loading: false,
  saved: false,
  sendMessageStatus: emptySMSListingStatusToast,
  scheduledSendTime: -1,
  isMarketerTimezone: true,
  savingMessage: SavingMessage.NONE,
  totalRecipients: 0,
  internationalSendingEnabled: false,
  sentInitialMessage: false,
}
