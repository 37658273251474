import { gql } from '@apollo/client'

export default gql`
  query getListContacts($listId: String!, $limit: Int, $pageNumber: Int, $pageSize: Int, $search: String) {
    getListContacts(listId: $listId, limit: $limit, pageNumber: $pageNumber, pageSize: $pageSize, search: $search) {
      id
      name
      email
    }
  }
`
