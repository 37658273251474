import React, { FC, useEffect, useState } from 'react'

import { YesNo } from '@components/ConfirmationModal/ConfirmationModal'
import getAllowedSendWindow from '@graphql/microservices/sms-management/getAllowedSendWindow'
import { GetAllowedSendWindowQuery, GetAllowedSendWindowQueryVariables } from '@graphql/types/microservice/sms-management-types'
import { SMSContextAPI } from '@src/pages/sms/context/SMSContext'
import Sendability from '@src/pages/sms/edit/components/Sendability/Sendability'
import { AllowedSendWindowProcessed, defaultAllowedSendWindowState, processAllowedWindowMessageData } from '@src/pages/sms/edit/utils/EditSMSUtils'
import { useAccountSettings } from '@utils/account/account.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'
import { logNewRelicError } from '@utils/new-relic.utils'

interface Props {
  context: SMSContextAPI
  isInitialMessage?: boolean
}

interface State {
  recipients: number
  sufficientBalance: boolean
  canSend: boolean
  canSchedule: boolean
  deliveryTime: number
  sendConfirmationOpen: boolean
  sendingLoader: boolean
  isScheduleModalOpen: boolean
  allowedSendWindow: AllowedSendWindowProcessed
}

const defaultState: State = {
  recipients: 0,
  sufficientBalance: false,
  canSend: false,
  canSchedule: false,
  deliveryTime: 0,
  sendConfirmationOpen: false,
  sendingLoader: false,
  isScheduleModalOpen: false,
  allowedSendWindow: defaultAllowedSendWindowState,
}

const carrierAdjustmentFactor = 0.8

const SendabilityContainer: FC<Props> = ({ context, isInitialMessage = false }: Props) => {
  const { values, sendSMSMessage, canSend, canSchedule } = context
  const [state, setState] = useState<State>(defaultState)

  const { accountId } = useAccountSettings()
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.SMS_MANAGEMENT })
  const { data, error } = useQueryOnMount<GetAllowedSendWindowQuery, GetAllowedSendWindowQueryVariables>(getAllowedSendWindow, {
    client,
    fetchPolicy: 'network-only',
    variables: {
      accountId,
    },
  })

  const getUpdatedAllowedSendWindowValues = () => {
    if (data?.getAllowedSendWindow) {
      const sendTime = new Date()
      const timezoneOffset = sendTime.getHours() - sendTime.getUTCHours()
      const updatedAllowedSendWindow = processAllowedWindowMessageData(
        data.getAllowedSendWindow,
        defaultState.allowedSendWindow,
        values.smsMessage.recipientTimezones,
        timezoneOffset,
        sendTime.getHours(),
        sendTime.getMinutes(),
        false
      )
      const allowedSendWindow = {
        ...state.allowedSendWindow,
        ...updatedAllowedSendWindow,
      }

      setState({ ...state, allowedSendWindow })
    }
  }

  useEffect(() => {
    getUpdatedAllowedSendWindowValues()
  }, [data])

  useEffect(() => {
    if (error) {
      logNewRelicError(error)
    }
  }, [error])

  const updateState = () => {
    const recipients = values.totalRecipients
    const sufficientBalance = values.availableCredits - values.costToSend > 0
    const deliveryTime = Math.ceil(((values.costToSend / values.segmentedMessage.segments.length) * carrierAdjustmentFactor) / 60)
    setState({
      ...state,
      recipients,
      deliveryTime,
      canSend: canSend() && ((values.sentInitialMessage ?? false) || isInitialMessage),
      canSchedule: canSchedule() && ((values.sentInitialMessage ?? false) || isInitialMessage),
      sufficientBalance,
    })
  }

  useEffect(() => {
    updateState()
  }, [values, isInitialMessage])

  useEffect(() => {
    if (state.recipients > 0) {
      getUpdatedAllowedSendWindowValues()
      updateState()
    }
  }, [state.recipients])

  const openSendConfirmation = () => setState({ ...state, sendConfirmationOpen: true })

  const confirmSendClicked = (answer: YesNo) => {
    if (answer === YesNo.YES) {
      setState({ ...state, sendingLoader: false, canSend: false })
      sendSMSMessage()
    } else {
      setState({ ...state, sendConfirmationOpen: false })
    }
  }

  return (
    <Sendability
      context={context}
      allowedSendWindow={state.allowedSendWindow}
      canSend={state.canSend}
      canSchedule={state.canSchedule}
      recipients={state.recipients}
      sendConfirmationOpen={state.sendConfirmationOpen}
      openSendConfirmation={openSendConfirmation}
      confirmSendClicked={confirmSendClicked}
      deliveryTime={state.deliveryTime}
      sendingLoader={state.sendingLoader}
      sufficientBalance={state.sufficientBalance}
      isInitialMessage={isInitialMessage}
    />
  )
}

export default SendabilityContainer
