import React, { FC, useEffect, useMemo, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'

import { useTranslation } from '@const/globals'
import { ExportDefinitionDtoInput, ExportMappingDtoInput, FtpFileDto } from '@graphql/types/microservice/entity-upload-types'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { ExportFirstLineOption } from '@src/pages/ExportContacts/components/ExportOptionsScreen/utils/ExportOptionsScreen.constants'
import ExportContacts from '@src/pages/ExportContacts/ExportContacts'
import { useExportContactsRequest } from '@src/pages/ExportContacts/GraphQL/ExportContacts.graphql'
import { ExportContactsSteps } from '@src/pages/ExportContacts/utils/ExportContacts.constants'
import {
  exportContactsContainerInitialState,
  ExportContactsContainerState,
  ExportContactsContext,
} from '@src/pages/ExportContacts/utils/ExportContacts.context'
import { changeUrlPathOnStepChange, getExportContactsListingTabs } from '@src/pages/ExportContacts/utils/ExportContacts.utils'
import { getProgramScheduleFromCron } from '@src/pages/ImportContactsV2/utils/ImportContactsV2.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import { filterNotEmptyArray } from '@utils/array'
import { Segment } from '@utils/contactSegments/contactSegments.utils'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'

type ExportContactsContainerProps = RouteComponentProps<{ job?: string; section?: string }>

const ExportContactsContainer: FC<ExportContactsContainerProps> = ({ match }: ExportContactsContainerProps) => {
  const { t } = useTranslation()
  const history = useHistory<{ selectedFtpFile?: FtpFileDto; exportSegment: Segment; backUrl?: string }>()
  const [state, setState] = useState<ExportContactsContainerState>({
    ...exportContactsContainerInitialState,
    steps: getExportContactsListingTabs(t, !!match.params.job, match.params.section),
  })
  const { accountId, userId, userName } = useAccountSettings()
  const {
    getUnifiedListFieldMappingsRequest,
    createExportDefinitionRequest,
    updateExportDefinitionRequest,
    getExportSyncJobRequest,
    getFtpFilesRequest,
    getExportDefinitionRequest,
  } = useExportContactsRequest()

  const update = useDeepUpdate(setState)

  const {
    steps,
    fieldsSeparator,
    fieldQuotes,
    updateExportDefinition,
    exportDefinitionId,
    firstLineContains,
    selectedFtpFile,
    exportFields,
    statusToast,
    schedule,
    exportSyncJobId,
    loadingExportFields,
    backUrl,
  } = state

  useEffect(() => {
    if (history.location.state?.selectedFtpFile) {
      update({
        selectedFtpFile: history.location.state?.selectedFtpFile,
        exportSegment: history.location.state?.exportSegment,
        backUrl: history.location.state?.backUrl ?? backUrl,
      })
    }
  }, [])

  const currentStep = useMemo(() => steps.find(({ isActive }) => isActive), [steps])

  const handleOnSectionChange = (newTab: string, currentStepCompleted = false) => {
    if (match.params.job) {
      changeUrlPathOnStepChange(newTab as ExportContactsSteps)
    }
    if (currentStep?.key === ExportContactsSteps.EXPORT_OPTIONS) {
      createOrUpdateExportDefinition()
    }
    if (currentStep?.key === ExportContactsSteps.CHOOSE_FIELDS) {
      update({ updateExportDefinition: true })
    }
    update({
      steps: steps.map((step) => {
        if (step.key === currentStep?.key) {
          return { ...step, isActive: false, isCompleted: currentStepCompleted || step.isCompleted }
        } else if (step.key === newTab) {
          return { ...step, isActive: true }
        } else {
          return step
        }
      }),
    })
  }

  const closeStatusToast = () => {
    update({ statusToast: { ...statusToast, showStatusToast: false } })
  }

  const loadFields = () => {
    getUnifiedListFieldMappingsRequest(false).then((fields) => {
      const visibleFields: UnifiedListFieldMapping[] = fields
        .filter((field: UnifiedListFieldMapping) => !field.hidden && !field.deleted)
        .sort((a: UnifiedListFieldMapping, b: UnifiedListFieldMapping) =>
          (a.displayName ? a.displayName : '') < (b.displayName ? b.displayName : '') ? -1 : 1
        )
      update({
        visibleFields,
        exportFields: visibleFields.map((field) => {
          return { field, selected: false }
        }),
        loadingExportFields: false,
      })
    })
  }

  const createOrUpdateExportDefinition = () => {
    const mappings: ExportMappingDtoInput[] = exportFields
      .filter((field) => field.selected)
      .map<ExportMappingDtoInput>((field) => {
        return { actonColumn: field.field.displayName, actonColumnId: field.field.columnIndex }
      })

    const exportOptions: ExportDefinitionDtoInput = {
      accountId,
      userId,
      userName,
      delimiter: fieldsSeparator,
      quote: fieldQuotes,
      hasHeader: firstLineContains === ExportFirstLineOption.NAMES,
      exportDefinitionId,
      exportDefinitionName: selectedFtpFile?.name,
      mappings,
    }
    if (!exportDefinitionId) {
      createExportDefinitionRequest(exportOptions).then(({ exportDefinitionId }) => update({ exportDefinitionId }))
    } else {
      updateExportDefinitionRequest(exportOptions).then(() => update({ updateExportDefinition: false }))
    }
  }

  const loadExportSyncJob = async (
    exportSyncJobId: number
  ): Promise<{ exportDefinitionId: number | undefined; selectedFtpFile: FtpFileDto | undefined } | undefined> => {
    const exportSyncJob = await getExportSyncJobRequest(exportSyncJobId)
    if (exportSyncJob) {
      const { filePath, exportDefinitionId, exportSyncJobId, cron } = exportSyncJob
      const ftpFiles = await getFtpFilesRequest()
      const selectedFtpFile = ftpFiles.find(({ urlPath }) => urlPath === filePath) ?? { urlPath: filePath, name: filePath?.substring(1), size: 0 }
      update({
        selectedFtpFile,
        exportSyncJobId,
        exportDefinitionId,
        schedule: cron ? getProgramScheduleFromCron(cron) : { ...schedule, isScheduled: false, isUnscheduled: true, type: 'NEVER' },
      })
      return { exportDefinitionId, selectedFtpFile }
    }
    return
  }

  const loadExportDefinition = async (exportDefinitionId: number, selectedFtpFile?: FtpFileDto) => {
    const exportDefinition = await getExportDefinitionRequest(exportDefinitionId)
    if (exportDefinition) {
      const { exportDefinitionId, delimiter, quote, hasHeader, mappings } = exportDefinition
      const firstLineContains = hasHeader ? ExportFirstLineOption.NAMES : ExportFirstLineOption.VALUES
      update({
        fieldQuotes: quote,
        exportDefinitionId,
        fieldsSeparator: delimiter,
        loading: false,
        firstLineContains,
      })
      if (selectedFtpFile) {
        const selectedFields = mappings?.filter(filterNotEmptyArray)
        const fields = exportFields.map((field) => ({
          ...field,
          selected: !!selectedFields?.find((selectedField) => selectedField.actonColumnId === field.field.columnIndex),
        }))
        update({
          exportFields: fields,
        })
      }
    }
  }

  useEffect(() => {
    if (!exportFields.length) {
      loadFields()
    }
  }, [])

  useEffect(() => {
    if (updateExportDefinition) {
      createOrUpdateExportDefinition()
      update({ updateExportDefinition: false })
    }
  }, [updateExportDefinition])

  useEffect(() => {
    if (!loadingExportFields) {
      if (match.params.job && exportDefinitionId === undefined && exportSyncJobId === undefined) {
        loadExportSyncJob(parseInt(match.params.job)).then((data) => {
          if (data?.exportDefinitionId) {
            const { exportDefinitionId, selectedFtpFile } = data
            loadExportDefinition(exportDefinitionId, selectedFtpFile)
          }
        })
      } else {
        update({ loading: false })
      }
    }
  }, [match.params.job, loadingExportFields])

  return (
    <ExportContactsContext.Provider value={{ values: state, handleOnSectionChange, update, closeStatusToast }}>
      <ExportContacts currentStep={currentStep ?? steps[0]} />
    </ExportContactsContext.Provider>
  )
}

export default ExportContactsContainer
