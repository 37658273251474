import { createContext } from 'react'

import { renderFtpSegmentWithInfoHover, renderSegmentNameWithInfoHover } from '@complex/ContactSegments/segmentInfoHover.utils'
import { ItemTypesUsedCount } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import { SyncedSegment } from '@graphql/types/microservice/crm-types'
import {
  ApplyAndRemoveTags,
  CreateFolder,
  CreateTag,
  DeleteFolder,
  DeleteTag,
  listPageContextValues,
  ListPageStatusToast,
  RenameFolder,
  SetFilter,
  SetFolder,
  SetTag,
} from '@interface/ListPage.context'
import { Row } from '@tanstack/react-table'
import { ItemType } from '@utils/categorization'
import {
  ContactSegmentsState,
  DataCardsModalInfo,
  EXPRESSION_EVALUATION_TYPE,
  PERIOD_GROUPED_BY,
  Segment,
} from '@utils/contactSegments/contactSegments.utils'
import { PERIOD_MODAL } from '@utils/contactSegments/dataCards.utils'
import { CRMSourceOption } from '@utils/crm.utils'
import { relativeDate } from '@utils/date'
import { allSegmentsFilter } from '@utils/filter'
import { EMPTY_LISTING_OPTIONS } from '@utils/ListPage.context.default'
import { getFormattedNumber } from '@utils/numbers'
import { VoidFunction } from '@utils/types'

export interface ActionResult {
  message: string
  objectId: string
  statusCode: number
}

export type TotalContactsModalInfo = DataCardsModalInfo

export type Update = (fields: Partial<ContactSegmentsState>) => void
type CloneSegment = (segment: any, newSegmentName: string, segmentTagsToClone: LabelDto[], folderId?: number) => any
type DeleteAllContacts = (segments: Segment[]) => void
type DeleteFromFolder = (segment: Segment[]) => void
type DeleteSegments = (segments: any) => void
type GetDataCardModalInfo = (days: number, groupBy: PERIOD_GROUPED_BY, caller: PERIOD_MODAL) => void
type GetItem = (itemId: number, type?: ItemType) => AbortController
type GetTotalContactsModalInfo = (days: number, groupBy: PERIOD_GROUPED_BY, caller: PERIOD_MODAL) => void
type GetSegmentDefinition = (segmentExternalId: string) => void
type GetCRMSourcedSegmentDetails = (segmentExternalIds: string[]) => Promise<SyncedSegment[] | undefined>
type RefreshRecordCount = (segments: string[]) => void
type RefreshSegments = (options?: { page?: number; needsReRender?: boolean }) => void
type SortSegments = (selectedRows: Row<Segment>[], droppedOnRow: Row<Segment>, above: boolean, parentRowId?: string) => void
type RegenerateSplits = (id: string) => void
type DoFinishCopySegments = VoidFunction
type GetFtpSyncJobsList = (segment: Segment) => void
type GetFtpExportSyncJobsList = (segment: Segment) => void

export interface ContactSegmentsContextAPI {
  applyAndRemoveTags: ApplyAndRemoveTags
  createFolder: CreateFolder
  renameFolder: RenameFolder
  createTag: CreateTag
  closeStatusToast: VoidFunction
  getItemTypesUsedInTags: (tagId: number) => Promise<ItemTypesUsedCount>
  setFavoriteItems: (itemsIds: number[], isFavorite: boolean, itemType?: ItemType) => void
  moveItemsIntoFolder: (items: Segment[], folderId: number, addToFolder: boolean) => void
  deleteFolder: DeleteFolder
  deleteTag: DeleteTag
  setFolder: SetFolder
  setTag: SetTag
  setFilter: SetFilter
  cloneSegment: CloneSegment
  deleteAllContacts: DeleteAllContacts
  deleteFromFolder: DeleteFromFolder
  deleteSegments: DeleteSegments
  getDataCardModalInfo: GetDataCardModalInfo
  getItem: GetItem
  getTotalContactsModalInfo: GetTotalContactsModalInfo
  getCRMSourcedSegmentDetails: GetCRMSourcedSegmentDetails
  getSegmentDefinition: GetSegmentDefinition
  refreshRecordCount: RefreshRecordCount
  refreshSegments: RefreshSegments
  sortSegments: SortSegments
  regenerateSplits: RegenerateSplits
  doFinishCopySegments: DoFinishCopySegments
  getFtpSyncJobsList: GetFtpSyncJobsList
  getFtpExportSyncJobsList: GetFtpExportSyncJobsList
  values: ContactSegmentsState
  update: Update
}

export const segmentDefinitionEmptyValue = {
  type: '',
  customBooleanExpression: null,
  expressions: [],
  expressionEvaluationType: 'AND' as EXPRESSION_EVALUATION_TYPE,
}

export const values: ContactSegmentsState = {
  ...listPageContextValues,
  loading: true,
  loadingSessionData: true,
  hasToExpandFolders: [],
  campaigns: {},
  crmMapping: {},
  dataCardsModalEmptyState: false,
  dataCardActive: undefined,
  dataCardsModalInfo: {
    name: 'Past 7 days' as PERIOD_MODAL,
    categories: [''],
    contactsAvailable: [0],
    contactsUsed: [0],
    lastUpdated: '',
  },
  emptyListingOption: EMPTY_LISTING_OPTIONS.EMPTY_FOLDER,
  exportedFile: '',
  filterActive: allSegmentsFilter,
  loadingChart: true,
  metadataLoading: false,
  scoreSheets: {},
  segmentContacts: {},
  segmentDefinition: segmentDefinitionEmptyValue,
  showDetails: undefined,
  subscriptionMapping: {},
  supportedBehaviors: {},
  mappedIdsToUuids: {},
  ftpSyncJobs: [],
  isFtpConnectorActive: false,
  ftpFiles: [],
  loadingFtpFiles: false,
  selectingFtpFile: false,
  selectingExportAction: false,
  selectingFtpExports: false,
  ftpExportSyncJobs: [],
  creatingFtpFile: false,
}

export const getContactSegmentsColumns = (update: Update, crmSourceOptions?: CRMSourceOption[]): ColumnDefWithAdditionalProps<Segment>[] => {
  const showStatusToast = (statusToast: ListPageStatusToast) => {
    update({ statusToast })
  }

  return [
    {
      header: 'Segments',
      textAlign: 'left',
      accessorKey: 'segments',
      disableSorting: true,
      enableClickableCell: true,
      enableFlexCell: true,
      cell: ({ row: { original } }) =>
        original.ftpData?.export.syncJobs.length || original.ftpData?.import.syncJobs.length
          ? renderFtpSegmentWithInfoHover(original, update, showStatusToast)
          : renderSegmentNameWithInfoHover(original, { showStatusToast, crmSourceOptions }),
    },
    {
      header: 'Tags',
      accessorKey: 'tags',
      textAlign: 'left',
      maxSize: 180,
      disableSorting: true,
      enableCustomCellValue: true,
    },
    {
      header: 'Records',
      accessorKey: 'recordsCount',
      textAlign: 'left',
      maxSize: 100,
      disableSorting: true,
      cell: ({ cell }) => getFormattedNumber(cell.getValue<number>()),
    },
    {
      header: 'Last Count',
      accessorKey: 'lastCountDate',
      textAlign: 'left',
      maxSize: 120,
      disableSorting: true,
      cell: ({ cell }) => {
        const date = relativeDate(cell.getValue<number>())
        return date === 'undefined' ? '' : date
      },
    },
  ]
}

export const clearMenuSections = {
  activeTagId: undefined,
  activeFolderId: undefined,
  filterActive: undefined,
  folderPath: [],
}

export const ContactSegmentsContext = createContext<ContactSegmentsContextAPI>({ values } as any)
