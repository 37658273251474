import React, { FC, useEffect, useState, ReactElement } from 'react'
import { UseFormRegister, FieldValues, UseFormReset, UseFormGetValues } from 'react-hook-form'

import { MessageType } from '@complex/MessagePickerModal/messagePickerModalUtils'
import MessageSelect from '@complex/MessageSelect'
import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import FormRow, { FormActionPosition } from '@components/FormRow'
import Input from '@components/Input'
import Label from '@components/Label'
import Select from '@components/Select/Select'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { useTranslation } from '@const/globals'
import { Program, ProgramMessage, ProgramSource } from '@graphql/types/query-types'
import ListSelect from '@src/pages/programs/edit/components/ProgramFlow/components/ListSelect/ListSelect'
import programUtils, { getIsListSegment } from '@utils/program/program'

import { ConditionalSelectUtils, getConditionalSelectUtils } from './conditionalSelectUtils'
import { SEGMENT_OPTIONS, SEGMENT_TYPES } from './segmentOptions'

interface StepCondition {
  srcId?: string
  not?: boolean
}

interface GroupedInputs {
  name: string
  index: number | string
}

interface Props {
  step: StepCondition
  program: Program
  register: UseFormRegister<FieldValues>
  formReset: UseFormReset<FieldValues>
  formData: UseFormGetValues<FieldValues>
  dataTest?: string
  onProgramSourcesUpdate(programSources: ProgramSource[]): void
  onMessageUpdate(message: ProgramMessage): void
  allowedMessageCategories?: MessageType[]
  conditionalSelectUtils?: ConditionalSelectUtils
  disableAddMessage?: boolean
  groupedInputs?: GroupedInputs
  onModeChange?(segmentMode: string): void
  source?: ProgramSource
  position?: number
}

export interface State {
  segmentOptions?: ReactElement[]
  segment: string
  messageIds: string[]
  newSegment: boolean
}

const ConditionalSelect: FC<Props> = (props: Props) => {
  const { disableAddMessage, onMessageUpdate, onProgramSourcesUpdate, step, program, source, register, dataTest = 'conditional-select' } = props
  const { t } = useTranslation()

  const localSegmentDetails = programUtils.getLocalSegment(program, step.srcId)
  const groupedInputs = props.groupedInputs ? `${props.groupedInputs.name}[${props.groupedInputs.index}].` : ''

  const [state, setState] = useState<State>({
    messageIds: localSegmentDetails?.idset && localSegmentDetails.idset.length > 0 ? localSegmentDetails?.idset : [''],
    newSegment: false,
    segment: '',
  })

  useEffect(() => {
    const getSegmentOptions = () => {
      const options = [...SEGMENT_OPTIONS]
      if (source) {
        const inSource = SEGMENT_OPTIONS.findIndex((option) => option.type == SEGMENT_TYPES.IN)
        options[inSource] = {
          ...options[inSource],
          text: `${options[inSource].text} ${source.name}`,
        }

        const notInSource = SEGMENT_OPTIONS.findIndex((option) => option.type == SEGMENT_TYPES.NOT_IN)
        options[notInSource] = {
          ...options[notInSource],
          text: `${options[notInSource].text} ${source.name}`,
        }
      }
      return options
    }

    const segmentOptions = getSegmentOptions().map((segment) => {
      return (
        <option key={segment.type} value={segment.type}>
          {t(segment.text)}
        </option>
      )
    })

    let segment: string
    if (!localSegmentDetails) {
      segment = step.not ? 'NOT_IN' : 'IN'
    } else {
      segment = localSegmentDetails.name
      if (segment === 'MESSAGE_SEND' && step.not) {
        segment = 'NOT_MESSAGE_SEND'
      }
    }

    setState((state) => ({
      ...state,
      segmentOptions,
      segment,
    }))
  }, [localSegmentDetails, source, step.not, t])

  useEffect(() => {
    register(`${groupedInputs}newSegment`, { value: `${state.newSegment}` })
    register(`${groupedInputs}position`, { value: props.position })
  }, [state.newSegment, groupedInputs, props.position, register])

  useEffect(() => {
    register(`${groupedInputs}messageIds`, { value: [...state.messageIds] })
  }, [state.messageIds])

  if (!state.segmentOptions) return null

  const conditionalSelectUtils = props.conditionalSelectUtils ?? getConditionalSelectUtils(state, setState)
  const isListSegment = getIsListSegment(state.segment)

  return (
    <>
      <FormRow>
        <Select
          dataTest={`${dataTest}-segment-select`}
          defaultValue={state.segment}
          register={register(`${groupedInputs}segmentMode`, {
            onChange: (event) => {
              conditionalSelectUtils.segmentChange(event.target.value)
              if (props.onModeChange) {
                props.onModeChange(event.target.value)
              }
            },
          })}
          name={`${groupedInputs}segmentMode`}
        >
          {state.segmentOptions}
        </Select>
      </FormRow>

      {isListSegment && source && (
        <Input name={`${groupedInputs}srcId`} value={source.id} register={register(`${groupedInputs}srcId`)} type={'hidden'} />
      )}
      {isListSegment && !source && (
        <FormRow>
          <ListSelect
            onProgramSourcesUpdate={(programSources: ProgramSource[]) => {
              onProgramSourcesUpdate(programSources)
              setState({
                ...state,
                newSegment: true,
              })
            }}
            srcId={step.srcId}
            register={register}
            program={program}
            name={`${groupedInputs}srcId`}
          />
        </FormRow>
      )}
      {!isListSegment && (
        <>
          <Input type="hidden" name={`${groupedInputs}srcId`} value={step.srcId ?? undefined} register={register(`${groupedInputs}srcId`)} />
          {state.messageIds.map((messageId, i) => {
            const canDelete = i !== 0
            return (
              <FormRow noPadding={i !== state.messageIds.length - 1} hasAction={!disableAddMessage ? FormActionPosition.RIGHT : undefined} key={i}>
                {canDelete && <Label className="push-right-x2">OR</Label>}
                <MessageSelect
                  dataTest={`${dataTest}-message-select`}
                  register={register}
                  name={`${groupedInputs}messageIds[${i}]`}
                  value={messageId}
                  onChange={(event) => conditionalSelectUtils.messageChange(i, event)}
                  onMessageUpdate={(message: ProgramMessage) => {
                    onMessageUpdate(message)
                    setState({
                      ...state,
                      newSegment: true,
                    })
                  }}
                  className={!canDelete ? 'inset-left-x6 push-down-x2' : ''}
                  programId={program.id}
                  programName={program.name}
                  messages={program.messages}
                  allowedMessageCategories={props.allowedMessageCategories}
                />
                {canDelete && (
                  <Button
                    buttonType={ButtonType.REMOVE}
                    iconPosition={ButtonIconPosition.FLOAT}
                    data-test={`${dataTest}-delete-row-${i}`}
                    type="button"
                    title={t('Delete Row')}
                    onClick={() => {
                      conditionalSelectUtils.deleteMessage(i)
                    }}
                  >
                    <Svg name={SvgNames.delete} type={SvgType.ICON} />
                  </Button>
                )}
              </FormRow>
            )
          })}
          {!disableAddMessage && (
            <FormRow>
              <Button
                data-test={`${dataTest}-add-row`}
                title={t('Add Message')}
                buttonType={ButtonType.FLOAT}
                onClick={conditionalSelectUtils.addMessage}
              >
                <Svg name={SvgNames.plus} />
                {t('Add Message')}
              </Button>
            </FormRow>
          )}
        </>
      )}
    </>
  )
}

export default ConditionalSelect
