import React, { FC, useState } from 'react'

import classNames from 'classnames'

import { getUUID } from '@const/globals'
import * as CheckboxRadix from '@radix-ui/react-checkbox'

import './checkbox.css'

const rootClass = 'checkbox'
export type CheckedState = CheckboxRadix.CheckedState

export interface CheckboxProps {
  ellipOnLabel?: boolean
  onChange?: (event: boolean) => void
  register?: any
  showTickOnDisabled?: boolean
  label?: string | JSX.Element
  name?: string
  value?: string
  checked?: boolean
  title?: string
  id?: string
  dataTest?: string
  disabled?: boolean
  defaultChecked?: boolean
  multiline?: boolean
  indeterminate?: boolean
  hasSmallLabel?: boolean
  className?: string
  withoutLabel?: boolean
  tabIndex?: number
}

export const Checkbox: FC<CheckboxProps> = (props: CheckboxProps) => {
  const {
    ellipOnLabel = false,
    name,
    value,
    checked,
    defaultChecked,
    onChange,
    register,
    showTickOnDisabled,
    title = 'Toggle Row Selected',
    label,
    disabled,
    id = getUUID(),
    dataTest = 'checkbox',
    className = '',
    multiline = false,
    indeterminate,
    hasSmallLabel = false,
    withoutLabel,
    tabIndex,
  } = props
  const [isChecked, setIsChecked] = useState(defaultChecked)
  const RadixCheckbox = (
    <CheckboxRadix.Root
      data-test={dataTest}
      id={id}
      name={name}
      className={classNames(rootClass, {
        [`${rootClass}--indeterminate`]: indeterminate && (checked === undefined ? !isChecked : !checked),
        [`${rootClass}--checked`]: checked === undefined ? isChecked : checked,
        [`${rootClass}--disabled`]: disabled,
      })}
      aria-label={title}
      disabled={disabled}
      defaultChecked={defaultChecked}
      checked={disabled && !showTickOnDisabled ? false : indeterminate && !checked ? 'indeterminate' : checked}
      ref={register}
      {...register}
      value={value}
      onClick={(event) => event.stopPropagation()}
      onCheckedChange={(checked: boolean) => {
        setIsChecked(checked as boolean)
        onChange && onChange(checked as boolean)
      }}
      tabIndex={tabIndex}
    >
      <CheckboxRadix.Indicator className={`${rootClass}__indicator`} />
    </CheckboxRadix.Root>
  )
  return (
    <>
      {withoutLabel ? (
        RadixCheckbox
      ) : (
        <div
          className={classNames(`${rootClass}__container`, className, {
            [`${rootClass}__container-multiline`]: multiline,
          })}
        >
          {RadixCheckbox}
          {label && (
            <label
              htmlFor={id}
              aria-label={title}
              data-test={`${dataTest}-label`}
              className={classNames(`${rootClass}__label`, {
                [`${rootClass}__label-multiline`]: multiline,
                [`${rootClass}__label--small`]: hasSmallLabel,
                ['ellip']: ellipOnLabel,
              })}
            >
              {label}
            </label>
          )}
        </div>
      )}
    </>
  )
}
export default React.memo(Checkbox)
