import React, { FC, useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'

import StatusToast, { Status } from '@components/StatusToast/StatusToast'
import { SwitchOptions } from '@components/Switch/Switch'
import { useTranslation } from '@const/globals'
import { ABSplitSession, GENERIC_SPLIT_ERROR, USED_SPLIT_NAME_ERROR } from '@src/pages/ContactSegments/components/ABSplit/ABSplit.constants'
import {
  getTotals,
  getSplitSuffix,
  getSplitsNumeralInitialState,
  isNumberOfSplitsValid,
} from '@src/pages/ContactSegments/components/ABSplit/ABSplit.utils'
import {
  ABSplitContainerInitialState,
  ABSplitContainerState,
  ABSplitContext,
  Split,
  SuppressedSource,
} from '@src/pages/ContactSegments/components/ABSplit/ABSplitContext'
import { useABSplitQueries } from '@src/pages/ContactSegments/components/ABSplit/utils/ABSplit.graphQL'
import { SEGMENTS_LIST_URL } from '@src/pages/ContactSegments/utils/ContactSegmentsSession.utils'
import { FORM_SUBMISSION_LIST_URL } from '@src/pages/listingPages/FormsJoinView/FormsJoinViewListingPageContainer.constants'
import { ItemType } from '@utils/categorization'
import { buildSegment } from '@utils/contactSegments/contactSegments.utils'
import { setItem } from '@utils/sessionStorage'

import ABSplit from './ABSplit'

type ABSplitContainerProps = RouteComponentProps<{ segmentId: string; type: string }>

const ABSplitContainer: FC<ABSplitContainerProps> = ({ match }: ABSplitContainerProps) => {
  const [containerValues, setContainerValues] = useState<ABSplitContainerState>(ABSplitContainerInitialState)
  const { t } = useTranslation()
  const { push } = useHistory()

  const {
    numberOfSplits,
    availableContacts,
    splits,
    selectedDistribution,
    baseSegment,
    suppressNonDeliverable,
    suppressedContacts,
    nonDeliverableContacts,
    statusToast,
    suppressedSplits,
    splitName,
    sources,
    doRegenerate,
  } = containerValues
  const { getSegmentDetailsRequest, getNonDeliverableContacts, getSuppressedContacts, createSplitsRequest } = useABSplitQueries()
  const update = (field: keyof ABSplitContainerState, value: any) => {
    setContainerValues((state) => ({
      ...state,
      [field]: value,
    }))
  }

  const recalculateSplits = (newSplits: Split[], suppressing = false) => {
    const validSplits = newSplits.every((split) => split.isValid)
    !suppressing && update('inputError', !validSplits)
    if (validSplits) {
      const { fixedTotalAmount, fixedSplitsNumber } = getTotals(newSplits)
      const totalSuppressed = new Set([...suppressedContacts, ...nonDeliverableContacts]).size
      let actualContacts = availableContacts - fixedTotalAmount - (suppressing ? totalSuppressed : 0)
      const actualSplits = numberOfSplits - fixedSplitsNumber
      newSplits = newSplits.map((split) => {
        return split.autoUpdate
          ? {
              ...split,
              value:
                selectedDistribution === SwitchOptions.PERCENT
                  ? ((100 - fixedTotalAmount) / actualSplits).toString()
                  : suppressing && actualContacts < 0
                  ? Math.ceil(actualContacts / actualSplits).toString()
                  : Math.floor(actualContacts / actualSplits).toString(),
            }
          : {
              ...split,
              autoUpdate: split.value === '0',
            }
      })
      if (selectedDistribution === SwitchOptions.NUMBER) {
        const lastValidSplit = [...newSplits].reverse().find((split) => split.autoUpdate)
        if (lastValidSplit) {
          lastValidSplit.value = (Number(lastValidSplit.value) + (actualContacts % actualSplits)).toString()
        }
      }
      if (
        suppressing &&
        (selectedDistribution === SwitchOptions.PERCENT ? Math.round((fixedTotalAmount * availableContacts) / 100) : fixedTotalAmount) >
          availableContacts - totalSuppressed
      ) {
        actualContacts = Math.abs(actualContacts)
        newSplits = newSplits
          .reverse()
          .map((split) => {
            const splitValue = Number(split.value)
            if (actualContacts > 0 && !split.autoUpdate) {
              let value
              if (actualContacts > splitValue) {
                value = '0'
                actualContacts -= splitValue
              } else {
                value = (splitValue - actualContacts).toString()
                actualContacts = 0
              }
              return { ...split, value }
            }
            return split.autoUpdate ? { ...split, value: '0' } : split
          })
          .reverse()
      }
    }
    return newSplits
  }

  useEffect(() => {
    update('inputError', !splits.every((split) => split.isValid))
  }, [splits])

  useEffect(() => {
    getSegmentDetailsRequest(match.params.segmentId, match.params.type).then(({ data }) => {
      const segment = buildSegment(data?.getItem)
      setContainerValues((containerValues: any) => ({
        ...containerValues,
        baseSegment: segment,
        availableContacts: segment.recordsCount,
      }))
    })
  }, [])

  useEffect(() => {
    let newSplits =
      selectedDistribution === SwitchOptions.PERCENT ? ABSplitContainerInitialState.splits : getSplitsNumeralInitialState(availableContacts)
    if (splits.every((split) => split.autoUpdate)) {
      newSplits = recalculateSplits(splits)
    }
    update('splits', newSplits)
    update('numberOfSplits', newSplits.length)
  }, [selectedDistribution])

  useEffect(() => {
    if (suppressedContacts.size || nonDeliverableContacts.size) {
      update('suppressedSplits', recalculateSplits(splits, true))
    } else {
      update('suppressedSplits', [])
    }
  }, [suppressedContacts, nonDeliverableContacts, splits])

  useEffect(() => {
    if (suppressNonDeliverable) {
      getNonDeliverableContacts(match.params.segmentId).then(({ data }) => update('nonDeliverableContacts', new Set(data?.countNonDeliverable?.body)))
    } else {
      update('nonDeliverableContacts', new Set())
    }
  }, [suppressNonDeliverable])

  useEffect(() => {
    if (isNumberOfSplitsValid(numberOfSplits)) {
      let newSplits = splits
      if (numberOfSplits > splits.length) {
        for (let i = splits.length; i < numberOfSplits; i++) {
          newSplits = [...newSplits, { name: getSplitSuffix(i), value: '1', autoUpdate: true, isValid: true }]
        }
      } else {
        newSplits.splice(numberOfSplits)
      }
      update('splits', recalculateSplits(newSplits))
    }
  }, [numberOfSplits])

  const onAddOrRemoveSource = (lists: SuppressedSource[]) => {
    if (lists.length) {
      Promise.all(
        lists.reduce((result: any[], { id }) => {
          return baseSegment?.externalId ? [...result, getSuppressedContacts(baseSegment.externalId, id)] : result
        }, [])
      ).then((data) => {
        const promises = data.reduce((items: any[], { data: { countSuppressed } }) => [...items, ...countSuppressed.body], [])
        update('suppressedContacts', new Set([...promises]))
        update(
          'sources',
          lists.map((source, index) => {
            return { ...source, suppressed: data[index].data.countSuppressed.body.length }
          })
        )
      })
    } else {
      update('sources', [])
      update('suppressedContacts', new Set())
    }
  }

  const onSubmit = () => {
    const finalSplits = suppressedSplits.length > 0 ? suppressedSplits : splits
    createSplitsRequest(
      {
        sourceListId: match.params.segmentId,
        baseName: splitName,
        usesPercentages: selectedDistribution === SwitchOptions.PERCENT,
        intendedSizes: finalSplits.map(({ value }) => value).filter((value) => value !== '0'),
        suppressedListsIds: sources.map((source) => source.id),
        regenerate: doRegenerate,
        suppressNonDeliverable: suppressNonDeliverable,
      },
      match.params.type
    ).then(({ data }) => {
      if (data?.createSplits?.statusCode === 200) {
        setItem(
          ABSplitSession.TOAST,
          JSON.stringify({
            statusMessage: t('Success! We’ve created an A/B split for {{segment}}.', { segment: baseSegment?.name }),
            status: Status.SUCCESS,
            showStatusToast: true,
          })
        )
        push(match.params.type === ItemType.FORM_SUBMISSION ? FORM_SUBMISSION_LIST_URL : SEGMENTS_LIST_URL)
      } else {
        const message = data?.createSplits?.message?.includes('same name') ? USED_SPLIT_NAME_ERROR : GENERIC_SPLIT_ERROR
        setContainerValues((containerValues: any) => ({
          ...containerValues,
          statusToast: { statusMessage: t(message), successStatus: false, showStatus: true },
        }))
      }
    })
  }

  return (
    <ABSplitContext.Provider
      value={{
        values: containerValues,
        update,
        onAddOrRemoveSource,
        recalculateSplits,
      }}
    >
      {statusToast?.showStatus && (
        <StatusToast
          isSuccess={statusToast.successStatus}
          message={statusToast.statusMessage}
          title={statusToast.title}
          closeStatus={() => {
            update('statusToast', { showStatus: false })
          }}
        />
      )}
      <ABSplit onSubmit={onSubmit} />
    </ABSplitContext.Provider>
  )
}

export default ABSplitContainer
