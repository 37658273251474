import { gql } from '@apollo/client'

export default gql`
  mutation updateShortUrl($id: Int!, $longUrl: String!, $shortUrl: String!) {
    updateShortUrl(id: $id, longUrl: $longUrl, shortUrl: $shortUrl) {
      id
      longUrl
      shortPath
      shortUrl
      domainName
      assetId
      assetType
      accountId
      https
    }
  }
`
