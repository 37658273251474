import React, { FC } from 'react'

import { LabelType, LabelV2 } from '@components/LabelV2/LabelV2'
import Spinner, { LoaderSize } from '@components/Spinner/Spinner'
import StaticImage from '@components/StaticImage/StaticImage'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { CheckCardStatus, CheckCardType, getCheckCardImage } from '@src/pages/EmailComposer/components/common/CheckStatusCard/CheckStatusCard.utils'

interface CheckStatusModalMessageProps {
  type: CheckCardType
  status: CheckCardStatus
  className: string
  dataTest?: string
}

export const CheckStatusModalMessage: FC<CheckStatusModalMessageProps> = ({ type, status, className, dataTest = className }) => {
  const { t } = useTranslation()
  const isSpam = type === CheckCardType.SPAM
  const loading = status === CheckCardStatus.LOADING
  const noContent = status === CheckCardStatus.NO_CONTENT

  return (
    <div className={className} data-test={dataTest}>
      {loading ? (
        <>
          <Spinner size={LoaderSize.XLARGE} className={`${className}__spinner`} dataTest={`${dataTest}-spinner`} />
          <LabelV2 labelType={LabelType.large}>{t(`EmailComposer.Check.Status.${type}.Modal.Message.Header`, { context: status })}</LabelV2>
        </>
      ) : (
        <>
          <StaticImage name={getCheckCardImage(type, status)} className="push-up-x4" />
          <Typography
            text={t(`EmailComposer.Check.Status.${type}.Modal.Message.Header`, { context: status })}
            type={TextType.MODAL_HEADER_SMALL}
            lineHeight={LineHeight.LARGE}
          />
        </>
      )}
      <Typography
        text={t(`EmailComposer.Check.Status.${type}.Modal.Message.Text`, { context: status })}
        type={TextType.BODY_TEXT_LIGHT}
        tagComponents={{
          TextLink: <TextLink hideIcon size={TextLinkSize.LARGE} />,
        }}
        className="push-down-x2"
      />
      {(isSpam || (!isSpam && noContent)) && (
        <TextLink
          text={t(`EmailComposer.Check.Status.${type}.Modal.Message.Link`, { context: status })}
          size={TextLinkSize.LARGE}
          className="push-down-x2"
          lineHeight={LineHeight.SMALL}
        />
      )}
    </div>
  )
}
