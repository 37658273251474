import React, { FC, ReactNode, useMemo } from 'react'

import classNames from 'classnames'

import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'

import './InfoCards.css'

export interface CardItemData {
  label: string | ReactNode
  tooltip?: string
  labelContent?: {
    primary: string | ReactNode
    secondary?: string | ReactNode
  }
}

export interface CardsOptions {
  cardsTitle: string
  cardsSubTitle?: string
  cards: CardItemData[]
}

interface InfoCardsProps {
  className?: string
  dataTest?: string
  cardsOptions: CardsOptions
}

const rootClass = 'info-cards'

const InfoCards: FC<InfoCardsProps> = (props: InfoCardsProps) => {
  const {
    dataTest = rootClass,
    className = '',
    cardsOptions: { cardsTitle, cardsSubTitle, cards },
  } = props

  const renderCard = (card: CardItemData) => (
    <>
      <Typography text={card.label} type={TextType.BODY_TEXT_LIGHT_TINY} weight={TextWeight.BOLD} lineHeight={LineHeight.SMALL} />
      {card.labelContent?.primary && (
        <div className={`${rootClass}__label-content`}>
          <Typography text={card.labelContent?.primary} type={TextType.BODY_TEXT_LARGE} className={`${rootClass}__label-content-primary`} />
          <Typography text={card.labelContent?.secondary} type={TextType.BODY_TEXT_SMALL_LIGHT} />
        </div>
      )}
    </>
  )

  const renderAllCards = () => {
    return cards.map(({ tooltip, ...card }, index) =>
      tooltip ? (
        <Tooltip key={`tooltip-card-${index}`} triggerClassName={`${rootClass}__card-container`} trigger={renderCard(card)}>
          {tooltip}
        </Tooltip>
      ) : (
        <div key={`card-${index}`} className={`${rootClass}__card-container`}>
          {renderCard(card)}
        </div>
      )
    )
  }

  const renderedCards = useMemo(renderAllCards, [cards])

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Typography text={cardsTitle} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
      <Typography text={cardsSubTitle} type={TextType.BODY_TEXT_SMALL_LIGHT} />
      <div className={`${rootClass}__cards`}>{renderedCards}</div>
    </div>
  )
}

export default InfoCards
