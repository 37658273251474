import { useHistory as reactUseHistory } from 'react-router-dom'

import { EXTERNAL_WINDOW_PATH, legacyActonContext, rootContext } from '@const/globals'
import allRoutes from '@src/components/Routes/allRoutes'
import { AccountState } from '@utils/account/reducer'
import { FEATURE_FLAGS } from '@utils/const/features/availableFeatures'
import featureFlags from '@utils/const/features/featureFlags'
import { BASE_IFRAME_PATH, CLASSIC_WINDOW_URLS, EXTERNAL_WINDOW_FEATURES, EXTERNAL_WINDOW_TITLES, IFRAME_PATH } from '@utils/iframeWrapperUrls'
import windowUtils from '@utils/window'

/* istanbul ignore next */
export const useHistory = () => {
  return reactUseHistory()
}

//If the classic route exists in our Routing table, then we should not go through this path
export const isClassicUrl = (url: string): boolean => url.startsWith(`${rootContext}/classic/`) && !allRoutes.some((route) => route.path == url)

export const isSalesUser = (userDesktop?: string) => !!userDesktop && userDesktop !== 'cust/top.jsp'
export const isTransPerfectUser = (userDesktop?: string) => !!userDesktop && userDesktop === 'cust/top.jsp'

export const getInternalAddressFromCurrent = (
  isMicrosoftStartPage: boolean,
  currentLocation: string,
  forIFrameSrc: boolean,
  userDesktop?: string
): string | null => {
  if (new RegExp(`^${rootContext}\/classic\/?$`).test(currentLocation)) {
    let iframeUrl = isMicrosoftStartPage ? 'microsoftStart' : 'dashboard'
    if (isSalesUser(userDesktop)) {
      iframeUrl = 'if/_wvt/index.jsp'
    } else if (isTransPerfectUser(userDesktop)) {
      iframeUrl = 'if/cust/listListing.jsp'
    }
    if (!forIFrameSrc) {
      iframeUrl = 'classic/' + iframeUrl
    }
    return iframeUrl
  } else if (isClassicUrl(currentLocation)) {
    return currentLocation.split(`${rootContext}/classic/`)[1]
  }
  return null
}

export const getIframeUrl = (account: AccountState, visible: boolean) => {
  let setIframeVisibility
  const { accountSettings, isAuthorized } = account
  let iframeUrl: string | null = `${legacyActonContext}/ng-ui/`
  if (isAuthorized === false) {
    setIframeVisibility = true
    iframeUrl = null
  } else if (accountSettings) {
    const internalAddress = getInternalAddressFromCurrent(
      accountSettings.isMicrosoftStartPage,
      windowUtils.getWindowPathname() + windowUtils.getWindowSearch(),
      true,
      accountSettings.userSettings?.userDesktop
    )
    if (internalAddress) {
      iframeUrl = `${legacyActonContext}/ng-ui/${internalAddress}`
    } else {
      iframeUrl = null
    }
  }
  if (iframeUrl === null && visible) {
    setIframeVisibility = false
  }

  return {
    iframeUrl,
    setIframeVisibility,
  }
}

const ON_CREATE_PARAM = '&onCreate'
const buildOnCreateParamUrl = () => {
  const arr = window.location.search.split(ON_CREATE_PARAM)
  return arr[0] + ON_CREATE_PARAM + encodeURIComponent(arr[1])
}

const buildUrl = (feature: EXTERNAL_WINDOW_FEATURES, extraParams: string) =>
  (feature === EXTERNAL_WINDOW_FEATURES.FORMS || feature === EXTERNAL_WINDOW_FEATURES.LANDING_PAGES ? BASE_IFRAME_PATH : IFRAME_PATH) +
  CLASSIC_WINDOW_URLS[feature] +
  extraParams +
  (window.location.search.includes(ON_CREATE_PARAM) ? buildOnCreateParamUrl() : window.location.search)

const getFormUrl = (feature: EXTERNAL_WINDOW_FEATURES) => {
  const splitFormUrl = feature.split(EXTERNAL_WINDOW_FEATURES.FORMS)
  return { formFeature: splitFormUrl[0] + EXTERNAL_WINDOW_FEATURES.FORMS, splitFormUrl }
}

const getLandingPageUrl = (feature: EXTERNAL_WINDOW_FEATURES) => {
  const splitLPUrl = feature.split(EXTERNAL_WINDOW_FEATURES.LANDING_PAGES)
  return { LPFeature: splitLPUrl[0] + EXTERNAL_WINDOW_FEATURES.LANDING_PAGES, splitLPUrl }
}

const getFeature = () => {
  const feature = location.pathname.split(`${EXTERNAL_WINDOW_PATH}/`)[1]
  if (feature.includes(EXTERNAL_WINDOW_FEATURES.FORMS)) {
    const { formFeature, splitFormUrl } = getFormUrl(feature as EXTERNAL_WINDOW_FEATURES)
    return { feature: formFeature, extraParams: splitFormUrl[1] }
  } else if (feature.includes(EXTERNAL_WINDOW_FEATURES.LANDING_PAGES)) {
    const { LPFeature, splitLPUrl } = getLandingPageUrl(feature as EXTERNAL_WINDOW_FEATURES)
    return { feature: LPFeature, extraParams: splitLPUrl[1] }
  }
  return { feature, extraParams: '' }
}

export const buildIFrameViewUrl = (iframeUrl: string) => {
  const classicUrl = Object.values(CLASSIC_WINDOW_URLS).find((url) => location.pathname.includes(url))
  if (location.pathname.includes(`${EXTERNAL_WINDOW_PATH}/`)) {
    const { feature, extraParams } = getFeature()
    document.title = `Act-On ${EXTERNAL_WINDOW_TITLES[feature as EXTERNAL_WINDOW_FEATURES]}`
    return buildUrl(feature as EXTERNAL_WINDOW_FEATURES, extraParams)
  } else if (classicUrl) {
    return IFRAME_PATH + classicUrl + window.location.search
  } else if (featureFlags.getFeatureFlag(FEATURE_FLAGS.MOCK) === true) {
    return `${rootContext}/static/mocks/classic2.html?requestedUrl=${escape(iframeUrl ?? '')}`
  }

  return iframeUrl
}

export default {
  buildIFrameViewUrl,
  isClassicUrl,
  getInternalAddressFromCurrent,
  getIframeUrl,
  useHistory,
}
