import { CustomRequestType } from '@complex/AssetPickerModal/Context/AssetPicker.context'
import { CustomRequestFilter } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { FilterDefinition, recommendedFilter } from '@utils/filter'

interface GetCustomRequestFiltersParams {
  customAllItemsFilter: FilterDefinition
  mainFilterRequest: (currentPage?: number) => CustomRequestType
  recommendedRequest: (currentPage?: number) => CustomRequestType
  searchRequest: (query: string, currentPage?: number) => CustomRequestType
  recommendedSearchRequest: (query: string, currentPage?: number) => CustomRequestType
}

export const getCustomRequestFilters = ({
  customAllItemsFilter,
  mainFilterRequest,
  recommendedRequest,
  searchRequest,
  recommendedSearchRequest,
}: GetCustomRequestFiltersParams): CustomRequestFilter[] => [
  {
    filter: customAllItemsFilter,
    request: mainFilterRequest,
    searchRequest,
  },
  {
    filter: recommendedFilter,
    request: recommendedRequest,
    searchRequest: recommendedSearchRequest,
  },
]
