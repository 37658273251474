import {
  Recipient,
  RecipientsGroup,
} from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertRecipients/components/RecipientsList/RecipientsList'

export const recipientsListMock: Recipient[] = [
  {
    id: 0,
    name: 'Amy Morrison',
    email: 'amy.morrison@example.com',
    isMuted: false,
    userType: 'User Type',
    group: RecipientsGroup.ADMIN,
  },
  {
    id: 1,
    name: 'Nick Hanson',
    email: 'nick.hanson@example.com',
    isMuted: true,
    userType: 'User Type',
    group: RecipientsGroup.ADMIN,
  },
  {
    id: 2,
    name: 'Bethany McNeil',
    email: 'bethany.mcneil@example.com',
    isMuted: false,
    userType: 'User Type',
    group: RecipientsGroup.MARKETING,
  },
  {
    id: 3,
    name: 'Zaidee Thomas',
    email: 'zaidee.thomas@example.com',
    isMuted: false,
    userType: 'User Type',
    group: RecipientsGroup.MARKETING,
  },
  {
    id: 4,
    name: 'Ahmad Vaccaro',
    email: 'ahmad.vaccaron@example.com',
    isMuted: true,
    userType: 'User Type',
    group: RecipientsGroup.SALES,
  },
  {
    id: 5,
    name: 'Mariah Mathew',
    email: 'mariah.mathew@example.com',
    isMuted: true,
    userType: 'User Type',
    group: RecipientsGroup.SALES,
  },
  {
    id: 6,
    name: 'Skylar Culhane',
    email: 'skylar.culhane@example.com',
    isMuted: false,
    userType: 'User Type',
    group: RecipientsGroup.SALES,
  },
]
