import { ItemType } from '@utils/categorization'
import { Segment } from '@utils/contactSegments/contactSegments.utils'

export const mockSegment: Segment = {
  id: 1,
  isEditable: true,
  isFavorite: false,
  externalId: 'q-0001',
  lastCountDate: '',
  name: 'Test Segment',
  parent: undefined,
  recordsCount: 10,
  tags: [],
  type: 'Segment',
  updatedTime: '',
  position: 1,
  itemType: ItemType.SEGMENT,
}
export const CUSTOM_SPLITS_MOCK = [
  { name: 'A', value: '10', autoUpdate: true, isValid: true },
  { name: 'B', value: '30', autoUpdate: false, isValid: true },
  { name: 'C', value: '50', autoUpdate: true, isValid: true },
  { name: 'D', value: '5', autoUpdate: false, isValid: true },
  { name: 'E', value: '5', autoUpdate: true, isValid: true },
]

export const getItemResponse = {
  authorId: '',
  createdTime: '',
  enabled: true,
  externalId: 'q-0001',
  folderId: null,
  id: 1,
  isFavorite: false,
  item: '{"id": "q-0001", "name": "Test Segment", "type": "Query", "parent": "", "isEditable": true, "recordsCount": 50, "lastCountDate": 1660762016846}',
  labels: [],
  parentId: null,
  position: 1,
  updatedTime: '',
  views: 1,
  __typename: 'ItemDTO',
}

export const getCountNonDeliverableResponse = {
  body: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  message: '',
  objectId: null,
  statusCode: 200,
  __typename: 'ActionResultDTO',
}

export const getCountSuppressedResponse = {
  body: ['1', '2', '4', '6', '7', '9', 'b', 'c', 'd', 'e', 'f', '10', '11', '12'],
  message: '',
  objectId: null,
  statusCode: 200,
  __typename: 'ActionResultDTO',
}

export const getCreateSplitsResponse = {
  body: null,
  message: 'SplitResource - Can\'t create split "split Split A" because userId 0000 already has a list or segment with the same name',
  objectId: null,
  statusCode: 400,
  __typename: 'ActionResultDTO',
}
