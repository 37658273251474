import listImportSyncJob from '@graphql/microservices/entity-upload/listImportSyncJob'
import { ImportSyncJobDto, ListImportSyncJobQuery, ListImportSyncJobQueryVariables } from '@graphql/types/microservice/entity-upload-types'
import { filterNotEmptyArray } from '@utils/array'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'

interface EntityUploadServiceRequests {
  getAllImportSyncJobs: () => Promise<ImportSyncJobDto[]>
}

export const useEntityUploadService = (): EntityUploadServiceRequests => {
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.ENTITY_UPLOAD })

  const getAllImportSyncJobs = async () => {
    return client
      .query<ListImportSyncJobQuery, ListImportSyncJobQueryVariables>({
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        query: listImportSyncJob,
      })
      .then(({ data }) => {
        return (data?.listImportSyncJob || []).filter(filterNotEmptyArray)
      })
  }

  return {
    getAllImportSyncJobs,
  }
}
