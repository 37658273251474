import React, { Children, FC, Key, ReactElement, useMemo } from 'react'

import classNames from 'classnames'

import { SvgColor } from '@components/Svg/Svg'
import { useTranslation } from '@const/globals'

import Accordion from '../Accordion/Accordion'
import { RadioCardProps } from '../RadioCard/RadioCard'
import Typography, { TextWeight } from '../Typography/Typography'

import './RadioCardGroup.css'

interface RadioCardGroupProps {
  className?: string
  dataTest?: string
  children: ReactElement<RadioCardProps>[]
  showAdditionalOptions?: boolean
  selectedOption?: Key
  onSelect: (selectedOption: Key) => void
}

const rootClass = 'radio-card-group'

const RadioCardGroup: FC<RadioCardGroupProps> = (props: RadioCardGroupProps) => {
  const { onSelect, showAdditionalOptions, selectedOption, children, dataTest = rootClass, className = '' } = props

  const { t } = useTranslation()

  const mapChildren = (evaluate: (child: ReactElement<RadioCardProps>) => boolean) => {
    return Children.map(
      children.filter((child) => evaluate(child)),
      (child) => {
        const props = { ...child.props, onClick: () => onSelect(child.key ?? 0), isSelected: child.key === selectedOption }
        return { ...child, props }
      }
    )
  }
  const mainOptions = useMemo(() => mapChildren((child) => !child.props.isAdditionalOption), [children, selectedOption])
  const additionalOptions = useMemo(() => mapChildren((child) => !!child.props.isAdditionalOption), [children, selectedOption])

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {mainOptions}
      {!!additionalOptions.length && showAdditionalOptions && (
        <Accordion
          childData={[
            {
              header: <Typography text={t('Additional options')} weight={TextWeight.MEDIUM} />,
              content: additionalOptions,
              index: '0',
            },
          ]}
          chevronLeft
          chevronFillColor={SvgColor.LIGHT_GRAY}
          className={`${rootClass}__additional-options`}
        />
      )}
    </div>
  )
}

export default RadioCardGroup
