import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import { ComposerHeader, ComposerHeaderProps } from '@complex/GlobalComposerHeader/components/ComposerHeader/ComposerHeader'
import ComposerHeaderBottom, { ComposerHeaderBottomProps } from '@complex/GlobalComposerHeader/components/ComposerHeaderBottom/ComposerHeaderBottom'
import { ButtonType } from '@components/Button'
import { SvgNames } from '@components/Svg'

import './GlobalComposerHeader.css'

export type ComponserHeaderTabsButtonProps = {
  text: string
  icon?: SvgNames
  tooltipContent?: ReactNode
  disabled?: boolean
  buttonType?: ButtonType
  hasLoader?: boolean
  onClick: () => void
}

export type ComposerHeaderReviewBarProps = {
  onBackClick: () => void
  backButtonText: string
}

interface GlobalComposerHeaderProps {
  className?: string
  dataTest?: string
  isCustomerCareLogin?: boolean
  headerProps: ComposerHeaderProps
  headerBottomProps?: ComposerHeaderBottomProps
}

const rootClass = 'global-composer-header'

const GlobalComposerHeader: FC<GlobalComposerHeaderProps> = (props: GlobalComposerHeaderProps) => {
  const { dataTest = rootClass, className = '', headerProps, headerBottomProps, isCustomerCareLogin } = props

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <ComposerHeader dataTest={`${dataTest}-composer-top`} {...headerProps} isCustomerCareLogin={isCustomerCareLogin} />
      {headerBottomProps && (
        <ComposerHeaderBottom dataTest={`${dataTest}-header-bottom`} {...headerBottomProps} isCustomerCareLogin={isCustomerCareLogin} />
      )}
    </div>
  )
}

export default GlobalComposerHeader
