import React, { FC } from 'react'

import classNames from 'classnames'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ProgramCopyFromStep } from '@graphql/types/query-types'
import { UpdateType } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/components/ListProgramStep/components/StepTypeSelector/utils/StepTypeSelector.constants'
import {
  deletedListError,
  missingSourceError,
} from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/components/ListProgramStep/utils/ListProgramStep.utils'

import './CopyFromSegmentDetails.css'

interface CopyFromSegmentDetailsProps {
  className?: string
  dataTest?: string
  step: ProgramCopyFromStep
}

export const updateTypes: { [key in UpdateType]: { mergeOnly: boolean; update: boolean } } = {
  [UpdateType.APPEND_AND_MERGE_WITH_UPDATE]: { mergeOnly: false, update: true },
  [UpdateType.APPEND_AND_MERGE]: { mergeOnly: false, update: false },
  [UpdateType.MERGE_WITH_UPDATE]: { mergeOnly: true, update: true },
  [UpdateType.MERGE]: { mergeOnly: true, update: false },
}

const rootClass = 'copy-from-segment-details'

const CopyFromSegmentDetails: FC<CopyFromSegmentDetailsProps> = (props: CopyFromSegmentDetailsProps) => {
  const { dataTest = rootClass, className = '', step } = props
  const { updateType = '', pruneRecords = false, srcName, srcId } = step

  const { t } = useTranslation()

  const { mergeOnly, update } = updateTypes[updateType as UpdateType]

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Typography
        className={`${rootClass}__segment`}
        text={`CopyFromSegmentDetails.SourceName`}
        tagComponents={
          srcName
            ? { Svg: <Svg name={SvgNames.contacts} type={SvgType.LARGER_ICON} /> }
            : {
                Svg: (
                  <Tooltip
                    trigger={<Svg name={SvgNames.warningSolid} type={SvgType.ICON} />}
                    textBold={srcId ? deletedListError : missingSourceError}
                  />
                ),
              }
        }
        tagProps={{ bold: { weight: TextWeight.BOLD }, italic: { weight: TextWeight.ITALIC } }}
        values={{ segment: srcName }}
      />
      <Typography text={t(mergeOnly ? 'CopyFromSegmentDetails.Merged' : 'CopyFromSegmentDetails.AppendedAndMerged')} />
      {update && <Typography text={t('CopyFromSegmentDetails.Updated')} />}
      {pruneRecords && <Typography text={t('CopyFromSegmentDetails.Removed')} />}
    </div>
  )
}

export default CopyFromSegmentDetails
