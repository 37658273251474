import React, { FC, useMemo } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import { TableV2 } from '@components/TableV2/TableV2'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { ColWithTooltip } from '@components/TableV2/utils/tableV2ColumnUtils'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { CellContext } from '@tanstack/react-table'
import { relativeDate } from '@utils/date'
import { Form } from '@utils/forms'

import './UpgradedFormsSummaryModal.css'

interface UpgradedFormsSummaryModalProps {
  className?: string
  dataTest?: string
  forms: Form[]
  onClose: () => void
  isOpen: boolean
}

const rootClass = 'upgraded-forms-summary-modal'

const UpgradedFormsSummaryModal: FC<UpgradedFormsSummaryModalProps> = (props: UpgradedFormsSummaryModalProps) => {
  const { dataTest = rootClass, className = '', forms = [], onClose, isOpen } = props

  const { t } = useTranslation()

  const tableColumns: ColumnDefWithAdditionalProps<Form>[] = useMemo(
    () => [
      {
        header: 'Form Name',
        accessorKey: 'name',
        textAlign: 'left',
        cell: (cell: CellContext<Form, any>) => (
          <div className={`${rootClass}__cell-name`}>
            <Svg name={SvgNames.formSimple} type={SvgType.LARGER_ICON} fill={SvgColor.TEXT_TEAL} />
            <ColWithTooltip cell={cell} />
          </div>
        ),
      },
      {
        header: 'Submits',
        accessorKey: 'submits',
        textAlign: 'center',
        maxSize: 180,
      },
      {
        header: 'Last updated',
        accessorKey: 'lastUpdated',
        textAlign: 'right',
        maxSize: 180,
        cell: ({ cell }: CellContext<Form, any>) => {
          const { lastUpdated } = cell.row.original
          if (lastUpdated) {
            return relativeDate(lastUpdated, true, true)
          }
        },
      },
    ],
    []
  )

  const header = (
    <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__header`}>
      {t('Upgraded forms')}
    </ModalHeader>
  )

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
      <ModalBody className={`${rootClass}__body`}>
        <Typography text={t(`{{count}} upgraded forms`, { count: forms.length })} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.MEDIUM} />
        <TableV2 data={forms} columns={tableColumns} />
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form} className={`${rootClass}__footer`}>
        <Button buttonType={ButtonType.PRIMARY} onClick={onClose} dataTest={`${dataTest}-button-primary`}>
          {t('Done')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default UpgradedFormsSummaryModal
