import React, { useEffect } from 'react'

import Container from '@components/Container'
import Spinner from '@components/Spinner/Spinner'
import { Status } from '@components/StatusToast/StatusToast'
import LandingPageManageURLs from '@src/pages/Content/LandingPages/LandingPagesManager/components/LandingPageManageURLs/LandingPageManageURLs'
import LandingPageManagerContext from '@src/pages/Content/LandingPages/LandingPagesManager/context/LandingPagesManager.context'
import useAccountSlugs from '@src/pages/Content/LandingPages/LandingPagesManager/hooks/useAccountSlugs'
import useLandingPage from '@src/pages/Content/LandingPages/LandingPagesManager/hooks/useLandingPage'
import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'

export const LandingPageComposerSettingsURLManagerContainer = () => {
  const {
    values: {
      message: { id },
    },
    api: { update, updateModal },
  } = useComposerContext()

  const { loadingLP, updatingLP, landingPage, updateLandingPage, refetchLP } = useLandingPage(id)
  const { accountSlugs, refetchAccountSlugs } = useAccountSlugs()

  useEffect(() => {
    update({
      publicUrlManager: {
        landingPagePublicUrl: landingPage?.defaultUrlGroup.publicUrl || '',
        landingPageSeoFriendlyUrl: landingPage?.defaultUrlGroup.seoFriendlyUrl || '',
      },
    })
  }, [landingPage])

  return (
    <LandingPageManagerContext.Provider
      value={{
        loadingLP,
        updatingLP,
        landingPage,
        updateLandingPage,
        refetchLP,
        accountSlugs,
        refetchAccountSlugs,
        setToastStatus: (value) => {
          const { showStatus, statusMessage, successStatus, title } = value
          showStatus &&
            updateModal('statusToast', {
              status: successStatus ? Status.SUCCESS : Status.FAIL,
              title: title,
              message: statusMessage,
            })
        },
      }}
    >
      <Container>{!loadingLP && landingPage ? <LandingPageManageURLs /> : <Spinner />}</Container>
    </LandingPageManagerContext.Provider>
  )
}
