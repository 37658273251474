import React from 'react'

import classNames from 'classnames'

import {
  AssetPickerTableType,
  renderDefaultLocationColumn,
  renderDefaultRelativeDateColumn,
  renderDefaultSubmitsColumn,
  renderDefaultTagsColumn,
} from '@complex/AssetPickerModal/Components/Table/AssetPickerTable.utils'
import { ItemDtoRow } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { FormType } from '@complex/ListingPage/Utils/ListingPage.constants'
import { disableRowByCriteria } from '@components/AssetPickers/FormsAndTemplatesPickerModal/utils/FormsAndTemplatesPickerModal.utils'
import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { CellContext } from '@tanstack/react-table'
import { allFormsFilter } from '@utils/filter'
import { Form } from '@utils/forms'

const rootClass = 'forms-picker-modal'

export const getDisabledTooltip = (form: Form, t: Function) => {
  return (
    <>
      <Typography text={t('Forms.Templates.Picker.Modal.Disabled.Title')} weight={TextWeight.BOLD} type={TextType.BODY_TEXT_WHITE} />
      {form.formType === FormType.CLASSIC ? (
        <Typography text={t('Forms.Templates.Picker.Modal.Disabled.ClassicText')} type={TextType.BODY_TEXT_WHITE} />
      ) : (
        <div>
          <Typography
            className={`${rootClass}__disabled-tooltip`}
            text={t('Forms.Templates.Picker.Modal.Disabled.NewText')}
            type={TextType.BODY_TEXT_WHITE}
          />
        </div>
      )}
    </>
  )
}

const renderItemNameVal = (form: Form, search: string, rootClass: string, t: Function) => {
  const itemName = form.name ?? ''
  const trigger = search ? renderBoldTextOnMatch(itemName, search) : itemName
  const isDisabled = disableRowByCriteria(form)
  return (
    <div className={classNames(`${rootClass}__item-name`, { [`${rootClass}__item-name-disabled`]: isDisabled })}>
      <div className={`${rootClass}__item-name-typography`}>
        {isDisabled ? (
          <Tooltip trigger={<Typography className={'ellip'} text={itemName} />} inline={false} withoutTail alwaysShowOnHover>
            {getDisabledTooltip(form, t)}
          </Tooltip>
        ) : (
          <TextWithTooltipOnEllip
            typographyProps={{
              text: trigger,
            }}
            tooltipProps={{ children: itemName }}
          />
        )}
      </div>
    </div>
  )
}

const renderNameColumnWithTooltip = (
  header: string,
  search: string,
  rootClass: string,
  renderItemNameVal: (row: Form, search: string, rootClass: string, t: Function) => JSX.Element | '',
  t: Function
): ColumnDefWithAdditionalProps<ItemDtoRow, string> => ({
  header,
  accessorKey: 'name',
  textAlign: 'left',
  cell: (cell: CellContext<ItemDtoRow, string>) => renderItemNameVal(cell.row.original as Form, search, rootClass, t),
})

export const renderTableColumns = (t: Function): ColumnDefWithAdditionalProps<ItemDtoRow, any>[] => [
  ...[renderNameColumnWithTooltip(t('Form Title'), '', rootClass, renderItemNameVal, t)],
  ...[renderDefaultTagsColumn(AssetPickerTableType.DEFAULT, t('Tags'))],
  ...[renderDefaultSubmitsColumn(AssetPickerTableType.DEFAULT, t('Submits'), 'submits')],
  ...[renderDefaultRelativeDateColumn(AssetPickerTableType.DEFAULT, t('Last Updated'), 'lastUpdated')],
]

export const renderTemplatesTableColumns = (t: Function): ColumnDefWithAdditionalProps<ItemDtoRow, any>[] => [
  ...[renderNameColumnWithTooltip(t('Template Title'), '', rootClass, renderItemNameVal, t)],
  ...[renderDefaultTagsColumn(AssetPickerTableType.DEFAULT, t('Tags'))],
]

export const renderSearchTableColumns = (search: string, folders: FolderData[], t: Function): ColumnDefWithAdditionalProps<ItemDtoRow, any>[] => [
  ...[renderNameColumnWithTooltip(t('Form Title'), search, rootClass, renderItemNameVal, t)],
  ...[renderDefaultLocationColumn(AssetPickerTableType.SEARCH, t('Location'), folders, t(allFormsFilter.name))],
  ...[renderDefaultTagsColumn(AssetPickerTableType.SEARCH, t('Tags'))],
  ...[renderDefaultSubmitsColumn(AssetPickerTableType.DEFAULT, t('Submits'), 'submits')],
  ...[renderDefaultRelativeDateColumn(AssetPickerTableType.SEARCH, t('Last Updated'), 'lastUpdated')],
]

export const renderSearchTemplatesTableColumns = (
  search: string,
  folders: FolderData[],
  t: Function
): ColumnDefWithAdditionalProps<ItemDtoRow, any>[] => [
  ...[renderNameColumnWithTooltip(t('Template Title'), search, rootClass, renderItemNameVal, t)],
  ...[renderDefaultLocationColumn(AssetPickerTableType.SEARCH, t('Location'), folders, t(allFormsFilter.name))],
  ...[renderDefaultTagsColumn(AssetPickerTableType.SEARCH, t('Tags'))],
]
