import React, { ChangeEvent, FC, Fragment, useEffect, useState } from 'react'
import { UseFormRegister } from 'react-hook-form'

import classNames from 'classnames'

import AddButton, { TooltipPosition } from '@components/AddButton/AddButton'
import Input from '@components/Input/Input'
import InputWithStatus from '@components/InputWithStatus/InputWithStatus'
import Label from '@components/Label'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import globals, { useTranslation } from '@const/globals'
import { checkEmailValidity } from '@utils/formUtils'

import './keyValuePicker.css'

const ROW_LIMIT = 9

export interface CustomField {
  header: string
  placeholder: string
  required?: boolean
  id: string
  value?: string
  inputWithStatus?: boolean
}

export interface KeyValue {
  key?: string
  value?: string
  id?: string
}

interface GroupedInputs {
  name: string
  index?: number | string
}

export interface State {
  keyValues: KeyValue[]
}

export interface KeyValuePickerProps {
  defaultValues?: KeyValue[]
  values?: KeyValue[]
  groupedInputs?: GroupedInputs
  dataTest?: string
  register?: UseFormRegister<any>
  onChange?: (keyValues: KeyValue[]) => void
  customFieldsOnchange?: (customFields: CustomField[][] | undefined) => void
  validityFunctions?: Record<string, (value: KeyValue) => string>
  keyHeader?: string
  valueHeader?: string
  keyPlaceholder?: string
  valuePlaceholder?: string
  addButtonText?: string
  minRowCount?: number
  maxInputLength?: number
  customFields?: CustomField[] | undefined
  handleData?: (customFields: CustomField[]) => void
  disableAdd?: boolean
  disabled?: boolean
  className?: string
  tooltipPosition?: TooltipPosition
}

const rootClass = 'key-value-picker'

const KeyValuePicker: FC<KeyValuePickerProps> = (props) => {
  const {
    dataTest = 'key-value-picker',
    keyPlaceholder = 'enter key',
    valuePlaceholder = 'enter value',
    addButtonText = 'Add Key/Value Pair',
    keyHeader,
    valueHeader,
    defaultValues = [],
    values,
    register = () => ({}),
    onChange,
    customFieldsOnchange,
    validityFunctions = {},
    minRowCount = 0,
    maxInputLength,
    customFields,
    disableAdd,
    className = '',
    tooltipPosition = 'left',
    disabled,
  } = props

  const { t } = useTranslation()

  const maxLengthProp = maxInputLength ? { maxLength: maxInputLength } : {}

  const assignKeyValueIds = (keyValues: KeyValue[]): KeyValue[] => {
    const bufferValues: KeyValue[] =
      keyValues.length < minRowCount ? Array.from({ length: minRowCount - keyValues.length }).map(() => ({ key: undefined, value: undefined })) : []

    return [...keyValues, ...bufferValues].map((keyValue) => ({
      ...keyValue,
      id: keyValue.id ?? globals.getUUID(),
    }))
  }

  const [state, setState] = React.useState<State>({
    keyValues: assignKeyValueIds(values ?? defaultValues),
  })

  const [stateCustomFields, setStateCustomFields] = useState(customFields ? [customFields] : [])

  const keyValues = state.keyValues ?? []

  const addRow = () => {
    const newKeyValues = [
      ...keyValues,
      {
        key: undefined,
        value: undefined,
        id: globals.getUUID(),
      },
    ]
    setState({ ...state, keyValues: newKeyValues })
    onChange?.(newKeyValues)
  }

  const addCustomRow = () => {
    if (!customFields) {
      return
    }
    const newRow = [...stateCustomFields, customFields]
    setStateCustomFields(newRow)
  }

  const deleteRow = (index: number) => {
    const newKeyValues =
      keyValues.length > minRowCount
        ? [...state.keyValues.slice(0, index), ...state.keyValues.slice(index + 1)]
        : state.keyValues.map((keyValue, curIndex) => (curIndex === index ? {} : keyValue))
    setState({ ...state, keyValues: newKeyValues })
    onChange?.(newKeyValues)
  }

  const deleteCustomFieldsRow = (index: number) => {
    const newFields = [...stateCustomFields.slice(0, index), ...stateCustomFields.slice(index + 1)]
    setStateCustomFields(newFields)
    if (customFieldsOnchange) {
      customFieldsOnchange(newFields)
    }
  }

  useEffect(() => {
    if (values) {
      const newKeyValues = assignKeyValueIds(values)
      setState({ ...state, keyValues: newKeyValues })
    }
  }, [values])

  let groupedInputs = ''
  if (props.groupedInputs) {
    groupedInputs = props.groupedInputs.name
    if (props.groupedInputs.index) {
      groupedInputs += `[${props.groupedInputs.index}]`
    }
    groupedInputs += '.'
  }

  const updateKeyValues = (isKey: boolean, value: string, index: number) => {
    const keyValue = keyValues[index]
    const newKeyValues = [
      ...state.keyValues.slice(0, index),
      {
        ...keyValue,
        key: isKey ? value : keyValue.key,
        value: !isKey ? value : keyValue.value,
      },
      ...state.keyValues.slice(index + 1),
    ]
    setState({ ...state, keyValues: newKeyValues })
    onChange?.(newKeyValues)
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>, rowIndex: number, columnIndex: number) => {
    const { value } = e.target

    const newState = stateCustomFields.map((row, rowI) => {
      if (rowI === rowIndex) {
        return row?.map((cell, cellI) => {
          if (cellI === columnIndex) {
            return { ...cell, value }
          }
          return cell
        })
      }

      return row
    })
    setStateCustomFields(newState)
    if (newState) {
      customFieldsOnchange?.(newState)
    }
  }
  const disableRemove = disabled || customFields ? stateCustomFields.length <= minRowCount : keyValues.length <= minRowCount
  const renderValidation = (key: string, value: KeyValue) => {
    const message = validityFunctions[key] ? validityFunctions[key](value) : ''
    return message ? (
      <div className={`${rootClass}__validation`}>
        <Svg name={SvgNames.errorSolid} type={SvgType.ICON} />
        <Typography text={t(message)} type={TextType.ERROR_SMALL} />
      </div>
    ) : null
  }

  const renderCustomFields = () => {
    return stateCustomFields?.map((row, rowIndex) => {
      const isLastRow = rowIndex + 1 === stateCustomFields.length
      const renderHeaders = row?.map((col) => (
        <div className={classNames(`${rootClass}__header__cell`, { [`${rootClass}__header__cell-required`]: col.required })} key={col.id}>
          <Label>
            {`${t(col.header)}`}
            {col.required && (
              <Typography text={` ${t('(required)')}`} type={TextType.BODY_TEXT_SMALL_LIGHT} lineHeight={LineHeight.MEDIUM_SMALL} inline />
            )}
          </Label>
        </div>
      ))
      return (
        <Fragment key={rowIndex}>
          {rowIndex === 0 && (
            <div className={`${rootClass}__header`}>
              {renderHeaders}
              <div className={`${rootClass}__header__cell`}></div>
            </div>
          )}
          <div data-test={`${rootClass}__row`} className={classNames(`${rootClass}__row`, { [`${rootClass}__row__last`]: isLastRow })}>
            {row?.map((col, colIndex) => (
              <div key={col.header} className={classNames(`${rootClass}__row__cell`, { [`${rootClass}__row__cell-required`]: col.required })}>
                {col.inputWithStatus ? (
                  <InputWithStatus
                    {...maxLengthProp}
                    value={col.value}
                    validityFunctions={[checkEmailValidity]}
                    onChange={(event) => handleInputChange(event, rowIndex, colIndex)}
                    dataTest={`${dataTest}-input-email`}
                    className={`${rootClass}__input-email`}
                    placeholder={t(col.placeholder)}
                  />
                ) : (
                  <Input
                    {...maxLengthProp}
                    value={col.value}
                    dataTest={`${dataTest}-field-input-${col.id}`}
                    register={register(`${groupedInputs}[${col.id}].key`)}
                    name={`${groupedInputs}[${col.id}].key`}
                    placeholder={t(col.placeholder)}
                    required={col.required}
                    onChange={(event) => handleInputChange(event, rowIndex, colIndex)}
                  />
                )}
              </div>
            ))}
            <div className={`${rootClass}__row__cell`}>
              <Tooltip
                hide={!disabled}
                position={'top'}
                trigger={
                  <AddButton
                    dataTest={`${dataTest}-delete-button-`}
                    className={`${rootClass}__delete`}
                    onClick={() => deleteCustomFieldsRow(rowIndex)}
                    tooltip={t('Remove')}
                    isDelete
                    disabled={disabled || disableRemove}
                    position={tooltipPosition}
                  />
                }
              >
                <Typography text={t('EmailComposer.Resend.Disabled')} type={TextType.BODY_TEXT_WHITE} />
              </Tooltip>
            </div>
          </div>
        </Fragment>
      )
    })
  }

  return (
    <div data-test={dataTest} className={classNames(rootClass, className)}>
      {customFields ? (
        renderCustomFields()
      ) : (
        <>
          {(keyHeader || valueHeader) && (
            <div className={`${rootClass}__header`}>
              <div className={`${rootClass}__header__cell`}>
                <Label>{keyHeader}</Label>
              </div>
              <div className={`${rootClass}__header__cell`}>
                <Label>{valueHeader}</Label>
              </div>
              <div className={`${rootClass}__header__cell`}></div>
            </div>
          )}
          {keyValues.map((keyValue, i) => {
            const keyValidation = renderValidation('key', keyValue)
            const valueValidation = renderValidation('value', keyValue)
            const isInvalid = keyValidation || valueValidation
            return (
              <Fragment key={keyValue.id}>
                <div className={classNames(`${rootClass}__row`, { [`${rootClass}__row--has-validation`]: isInvalid })}>
                  <div className={`${rootClass}__row__cell`}>
                    <Tooltip
                      hide={!disabled}
                      position={'top'}
                      trigger={
                        <Input
                          {...maxLengthProp}
                          disabled={disabled}
                          dataTest={`${dataTest}-key-input-${i}`}
                          register={register(`${groupedInputs}[${keyValue.id}].key`)}
                          name={`${groupedInputs}[${keyValue.id}].key`}
                          defaultValue={keyValue.key}
                          placeholder={t(keyPlaceholder)}
                          required
                          onBlurCapture={(event) => {
                            updateKeyValues(true, event.target.value, i)
                          }}
                          error={!!keyValidation}
                        />
                      }
                    >
                      <Typography text={t('EmailComposer.Resend.Disabled')} type={TextType.BODY_TEXT_WHITE} />
                    </Tooltip>
                  </div>
                  <div className={`${rootClass}__row__cell`}>
                    <Tooltip
                      hide={!disabled}
                      position={'top'}
                      trigger={
                        <Input
                          {...maxLengthProp}
                          disabled={disabled}
                          dataTest={`${dataTest}-value-input-${i}`}
                          register={register(`${groupedInputs}[${keyValue.id}].value`)}
                          name={`${groupedInputs}[${keyValue.id}].value`}
                          defaultValue={keyValue.value}
                          placeholder={t(valuePlaceholder)}
                          onBlurCapture={(event) => {
                            updateKeyValues(false, event.target.value, i)
                          }}
                          error={!!valueValidation}
                        />
                      }
                    >
                      <Typography text={t('EmailComposer.Resend.Disabled')} type={TextType.BODY_TEXT_WHITE} />
                    </Tooltip>
                  </div>
                  <div className={`${rootClass}__row__cell`}>
                    <AddButton
                      dataTest={`${dataTest}-delete-button-${i}`}
                      className={`${rootClass}__delete`}
                      onClick={() => deleteRow(i)}
                      tooltip={disabled ? t('EmailComposer.Resend.Disabled') : t('Remove')}
                      isDelete
                      disabled={disabled}
                    />
                  </div>
                </div>
                {isInvalid && (
                  <div className={`${rootClass}__row`}>
                    <div className={`${rootClass}__row__cell`} data-test={`${dataTest}-key-validation-${i}`}>
                      {keyValidation}
                    </div>
                    <div className={`${rootClass}__row__cell`} data-test={`${dataTest}-value-validation-${i}`}>
                      {valueValidation}
                    </div>
                    <div className={`${rootClass}__row__cell`} />
                  </div>
                )}
              </Fragment>
            )
          })}
        </>
      )}
      <Tooltip
        hide={!disabled}
        position={'top'}
        trigger={
          <AddButton
            label={t(addButtonText)}
            dataTest={`${dataTest}-add-button`}
            className={`${rootClass}__add`}
            onClick={() => (customFields ? addCustomRow() : addRow())}
            disabled={disabled || disableAdd || stateCustomFields.length === ROW_LIMIT}
          />
        }
      >
        <Typography text={t('EmailComposer.Resend.Disabled')} type={TextType.BODY_TEXT_WHITE} />
      </Tooltip>
    </div>
  )
}

export default KeyValuePicker
