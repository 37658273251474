import React, { ComponentProps, FC, useRef } from 'react'

import Input from '@src/../../../libs/components/common/Input'
import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'

import './InputWithCopy.css'

interface InputWithCopyProps extends ComponentProps<typeof Input> {
  className?: string
  dataTest?: string
  copyButtonTooltip?: string
  onCopy?: (value: string) => void
}

const rootClass = 'input-with-copy'

const InputWithCopy: FC<InputWithCopyProps> = (props: InputWithCopyProps) => {
  const { dataTest = rootClass, className = '', copyButtonTooltip, onCopy, register, name, ...inputProps } = props

  const inputRef = useRef<HTMLInputElement | null>(null)
  const { value } = inputProps

  const onCopyToClipboard = async () => {
    await navigator.clipboard.writeText(value ?? '')
    onCopy?.(value ?? '')
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Input
        dataTest={`${dataTest}-input`}
        readOnlyAllowCopy
        readOnly
        register={(ref: any) => {
          register(name ?? '')
          inputRef.current = ref
        }}
        {...inputProps}
      />
      <Tooltip
        hide={!copyButtonTooltip}
        trigger={
          <Button
            className={`${rootClass}__button-copy`}
            dataTest={`${dataTest}-copy`}
            buttonType={ButtonType.TRANSPARENT}
            onClick={onCopyToClipboard}
          >
            <Svg name={SvgNames.cloneSegment} type={SvgType.LARGER_ICON} fill={SvgColor.TEXT_GRAY} />
          </Button>
        }
      >
        {copyButtonTooltip}
      </Tooltip>
    </div>
  )
}

export default InputWithCopy
