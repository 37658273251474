import { gql } from '@apollo/client'

export default gql`
  query getImages(
    $folderId: Int
    $pageNumber: Int
    $pageSize: Int
    $onlyFavorite: Boolean
    $includeSvg: Boolean
    $search: String
    $sortColumn: String
    $sortDirection: Direction
  ) {
    getImages(
      folderId: $folderId
      pageNumber: $pageNumber
      pageSize: $pageSize
      includeSvg: $includeSvg
      onlyFavorite: $onlyFavorite
      search: $search
      sortColumn: $sortColumn
      sortDirection: $sortDirection
    ) {
      id
      title
      previewUrl
      isFavorite
      linkUrl
      modified
      created
      doctype
      size
      thumbNailUrl
      dimensions
    }
  }
`
