import React, { FC, Key, useContext, useMemo } from 'react'
import { useController, useFieldArray, useFormContext } from 'react-hook-form'

import classNames from 'classnames'

import AccordionStep from '@components/AccordionStep/AccordionStep'
import { ButtonType } from '@components/Button'
import CheckboxCard from '@components/CheckboxCard/CheckboxCard'
import { CheckboxCardGroup } from '@components/CheckboxCardGroup/CheckboxCardGroup'
import { YesNo } from '@components/ConfirmationModal'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import InfoTooltip from '@components/InfoTooltip/InfoTooltip'
import { LinkTextButton } from '@components/LinkTextButton/LinkTextButton'
import { ModalBody } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import ModalFooterV2 from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import Spinner, { LoaderSize } from '@components/Spinner/Spinner'
import { SvgNames } from '@components/Svg'
import TooltipButton from '@components/TooltipButton/TooltipButton'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { Program, ProgramAdditionalEntity } from '@graphql/types/query-types'
import CRMEntitySelector from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/components/CRMEntitySelector/CRMEntitySelector'
import EntityDetails from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/components/EntityDetails/EntityDetails'
import { StepDetails } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/components/StepDetails/StepDetails'
import {
  opportunitiesEntities,
  STEP_DETAILS_KEY,
  SYNC_SCHEDULE_URL,
} from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.constants'
import { EditCRMStepV2Context } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.context'
import {
  EditCRMStepV2EntityStep,
  SelectableCardData,
} from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.interfaces'
import {
  getEntityTitle,
  getIconByEntity,
} from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.utils'
import useCRM from '@utils/hooks/useCRM'
import { ProgramCreateInCRMStepExt } from '@utils/program/program.constants'

import './EditCRMStepV2.css'

interface EditCRMStepV2Props {
  className?: string
  dataTest?: string
  onSubmit: (step: ProgramCreateInCRMStepExt) => void
  closeModal?: VoidFunction
  program: Program
}

const rootClass = 'edit-crm-step-v2'
export const MATCH_UPPERCASE_LETTERS_REGEX = /([A-Z])/g

const EditCRMStepV2: FC<EditCRMStepV2Props> = (props: EditCRMStepV2Props) => {
  const { dataTest = rootClass, className = '', onSubmit, closeModal, program } = props

  const { t } = useTranslation()
  const { connectorType } = useCRM()

  const {
    removeStep,
    update,
    values: { entities, loadingInitialValues, loadingReferences, showSteps, steps, stepToRemove, showErrorBanner },
  } = useContext(EditCRMStepV2Context)

  const entitiesCards = useMemo<SelectableCardData[]>(
    () =>
      entities.map(({ key = '', value = '' }) => ({
        title: getEntityTitle(key),
        iconLeft: getIconByEntity(value, connectorType) ?? SvgNames.crmCloudLineNoFill,
        value,
      })),
    [connectorType, entities]
  )

  const canSync = !program.crm?.crmModelNotCurrent
  const selectedEntitiesSteps = useMemo(() => steps.filter(({ key }) => key !== STEP_DETAILS_KEY), [steps])

  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid, isDirty, defaultValues },
  } = useFormContext<ProgramCreateInCRMStepExt>()

  const { append } = useFieldArray<ProgramCreateInCRMStepExt>({ control, name: 'additionalEntities' })
  const { field: pushNewRecords } = useController({ control, name: 'pushNewRecords' })
  const { field: canUpdateExistingContacts } = useController({ control, name: 'canUpdateExistingContacts' })
  const fields = watch('additionalEntities')

  const saveDisabled = showSteps
    ? (showErrorBanner && pushNewRecords.value) || !isValid || (!!defaultValues?.letter && !isDirty)
    : (steps.length === 1 && !pushNewRecords.value) || !canSync

  const renderDivider = () => {
    return (
      <div className={`${rootClass}__divider-container`}>
        <div className={`${rootClass}__divider`}></div>
        <Typography
          className={`${rootClass}__divider-text`}
          type={TextType.BODY_TEXT_SMALL_LIGHT}
          weight={TextWeight.BOLD}
          lineHeight={LineHeight.MEDIUM_LARGE}
          text={t('AND/OR')}
        />
        <div className={`${rootClass}__divider`}></div>
      </div>
    )
  }

  const onCheckboxSelect = (selectedEntity: Key) => {
    const entity = entities.find(({ value }) => value === selectedEntity)

    if (opportunitiesEntities.includes(selectedEntity.toString())) {
      const { matchingEntities, matchingKeys, allSelected } = entities.reduce(
        (acc, { value, key }) => {
          if (value && key && opportunitiesEntities.includes(value)) {
            acc.matchingEntities.push({ value: value, key: key })
            acc.matchingKeys.push(value)
            if (!steps.some((step) => step.key === value)) {
              acc.allSelected = false
            }
          }
          return acc
        },
        { matchingEntities: [] as { value: string; key: string }[], matchingKeys: [] as string[], allSelected: true }
      )

      if (allSelected) {
        update({ steps: steps.filter(({ key }) => !matchingKeys.includes(key as string)) })
      } else {
        const newSteps = matchingEntities.reduce<typeof steps>((result, { value, key }) => {
          if (!steps.some((step) => step.key === value)) {
            result.push({
              title: key as string,
              key: value,
              open: false,
              completed: false,
              newStep: true,
            })
          }
          return result
        }, [])

        update({ steps: [...steps, ...newSteps] })
      }
    } else {
      const isSelected = steps.some((step) => step.key === selectedEntity)
      update({
        steps: isSelected
          ? steps.filter(({ key }) => key !== selectedEntity)
          : [
              ...steps,
              {
                title: entity?.key ?? selectedEntity.toString(),
                key: selectedEntity,
                open: false,
                completed: false,
                newStep: true,
              },
            ],
      })
    }
  }

  const onCreateContacts = () => {
    pushNewRecords.onChange(!pushNewRecords.value)
  }

  const onFirstScreenContinue = () => {
    const additionalEntities: ProgramAdditionalEntity[] = steps
      .filter(({ key }) => key !== STEP_DETAILS_KEY)
      .map(({ key }) => ({
        entityType: key as string,
        fields: [],
      }))
    const opportunitySelected = additionalEntities.some(({ entityType }) => opportunitiesEntities.includes(entityType as string))
    append(additionalEntities)
    canUpdateExistingContacts.onChange(pushNewRecords.value)
    update({ showSteps: true, showErrorBanner: opportunitySelected && !!pushNewRecords.value })
  }

  const onContinue = (currentStep: EditCRMStepV2EntityStep) => () => {
    const currentStepIndex = steps.findIndex((entity) => entity === currentStep)
    const nextStep = steps.length > currentStepIndex + 1 ? steps[currentStepIndex + 1] : null
    const updatedSteps = steps.map((step) => {
      if (step === currentStep) {
        return { ...step, open: false, completed: true }
      }
      if (step === nextStep) {
        return { ...step, open: true }
      }
      return step
    })
    update({ steps: updatedSteps })
  }

  const onAccordionClick = (index: number) => () => {
    const currentOpenStepIndex = steps.findIndex(({ open }) => open)
    if (currentOpenStepIndex !== index) {
      const lastCompletedStepIndex = steps.reduce((lastCompletedIndex, step, index) => (step.completed ? index : lastCompletedIndex), -1)
      if (lastCompletedStepIndex + 1 >= index) {
        update({
          steps: steps.map((step, stepIndex) => ({
            ...step,
            open: index === stepIndex ? !step.open : false,
            newStep: index === stepIndex ? false : step.newStep,
          })),
        })
      }
    }
  }

  const renderEntitiesSelector = () => (
    <div className={`${rootClass}__selector`}>
      <CheckboxCard
        onSelect={onCreateContacts}
        iconLeft={SvgNames.addContactsNoFill}
        iconRight={SvgNames.arrowRight}
        title={t('Create new contacts')}
        isSelected={pushNewRecords.value}
      />
      {renderDivider()}
      <div className={`${rootClass}__cards-header`}>
        <Typography
          text={t('EditCrmStepV2.SelectEntities')}
          weight={TextWeight.MEDIUM}
          type={TextType.BODY_TEXT_LARGE}
          lineHeight={LineHeight.MEDIUM_LARGE}
        />
        <InfoTooltip text={t('EditCrmStepV2.SelectEntities.Tooltip')} />
      </div>
      <CheckboxCardGroup
        className={`${rootClass}__cards-group`}
        onSelect={onCheckboxSelect}
        selectedOption={selectedEntitiesSteps.map(({ key }) => key)}
      >
        {entitiesCards.map((card) => (
          <CheckboxCard {...card} key={card.value} />
        ))}
      </CheckboxCardGroup>
    </div>
  )

  const renderSteps = () =>
    steps.map((step, index) => {
      if (step.key === STEP_DETAILS_KEY) {
        return (
          <AccordionStep
            key={step.key}
            className={`${rootClass}__step`}
            stepIcon={SvgNames.infoHover}
            label={t('Step details')}
            onClick={onAccordionClick(index)}
            completed={step.completed}
            isOpen={step.open}
            error={step.error}
            {...(index < steps.length - 1 && { onContinue: onContinue(step) })}
          >
            <StepDetails />
          </AccordionStep>
        )
      } else {
        if (fields) {
          const key = step.key as string
          const additionalEntityIndex = fields.findIndex(({ entityType }) => entityType === key)
          return (
            <AccordionStep
              className={`${rootClass}__step`}
              key={step.key}
              stepIcon={getIconByEntity(key, connectorType) ?? SvgNames.crmCloudLineNoFill}
              label={getEntityTitle(step.title)}
              onRemove={() => update({ stepToRemove: step })}
              onClick={onAccordionClick(index)}
              completed={step.completed}
              isOpen={step.open}
              error={step.error}
              {...(index < steps.length - 1 && { onContinue: onContinue(step) })}
            >
              <EntityDetails entity={fields[additionalEntityIndex]} index={additionalEntityIndex} />
            </AccordionStep>
          )
        }
      }
    })

  if ((loadingInitialValues || loadingReferences) && canSync) {
    return <Spinner size={LoaderSize.MEDIUM} />
  }

  return (
    <>
      <ModalBody className={classNames(rootClass, className)} dataTest={dataTest}>
        {stepToRemove && (
          <DeleteConfirmationModal
            className={`${rootClass}__confirmation-modal`}
            isOpen
            title={t('Remove entity?')}
            body={
              <Typography
                text={t(`EditCrmStepV2.RemoveEntity`, { key: getEntityTitle(stepToRemove.title) })}
                tagProps={{ medium: { weight: TextWeight.MEDIUM } }}
                inline
              />
            }
            cancelButtonText={t('Cancel')}
            deleteButtonText={t('Remove')}
            onAnswer={(answer) => {
              if (answer === YesNo.YES) {
                removeStep(stepToRemove)
              }
              update({ stepToRemove: undefined })
            }}
          />
        )}
        {!canSync ? (
          <Typography
            className={`${rootClass}__sync-error-message`}
            text={t('EditCrmStepV2.NotSyncedError', { crmName: program.crm?.crmName })}
            tagProps={{ medium: { weight: TextWeight.MEDIUM } }}
            tagComponents={{ LinkTextButton: <LinkTextButton link={SYNC_SCHEDULE_URL} hideIconLeft hideIconRight /> }}
            inline
          />
        ) : showSteps ? (
          renderSteps()
        ) : (
          renderEntitiesSelector()
        )}
      </ModalBody>
      <ModalFooterV2
        footerType={ModalFooterType.Form}
        onClose={closeModal}
        buttons={{
          renderCustomActionButton: () => (
            <TooltipButton
              buttonType={ButtonType.PRIMARY}
              disabled={saveDisabled}
              onClick={showSteps ? handleSubmit(onSubmit) : onFirstScreenContinue}
              hideTooltip={!saveDisabled}
              title={t(
                !isDirty && !!defaultValues?.letter
                  ? 'EditCrmStepV2.Footer.ActionButton.NoChanges'
                  : showSteps
                  ? 'EditCrmStepV2.Footer.ActionButton.MissingSections'
                  : 'EditCrmStepV2.Footer.ActionButton.NoEntity'
              )}
            >
              {t(showSteps ? 'Save' : 'Continue')}
            </TooltipButton>
          ),
          cancelButtonLabel: t('Cancel'),
        }}
        customContent={showSteps && <CRMEntitySelector />}
      />
    </>
  )
}

export default EditCRMStepV2
