import SvgNames from '@components/Svg/SvgNames'
import { ProgramStepType } from '@utils/program/program.constants'

export const getIconAndColor = (switchType: string, overrideColorAndHeight?: boolean) => {
  let iconName = SvgNames.delete // some default value needed
  let color

  switch (switchType) {
    case ProgramStepType.START:
      iconName = SvgNames.start
      color = 'green'
      break
    case ProgramStepType.BRANCH:
      iconName = SvgNames.branch
      color = 'teal'
      break
    case ProgramStepType.GOTO:
      iconName = SvgNames.goToStep
      color = 'orange'
      break
    case ProgramStepType.OPT_OUT:
      iconName = SvgNames.block
      color = 'red'
      break
    case ProgramStepType.TASK:
      iconName = SvgNames.chat
      color = 'crm'
      break
    case ProgramStepType.CRM_CAMPAIGN:
      iconName = SvgNames.uploadCloud
      color = 'crm'
      break
    case ProgramStepType.CRM_CREATE:
      iconName = SvgNames.addCloud
      color = 'crm'
      break
    case ProgramStepType.FIELD_SET:
      iconName = SvgNames.pencil
      color = 'gray'
      break
    case ProgramStepType.ALERT:
      iconName = SvgNames.megaphone
      color = 'gray'
      break
    case ProgramStepType.COPY:
    case ProgramStepType.COPY_FROM:
      iconName = SvgNames.copy
      color = 'gray'
      break
    case ProgramStepType.APPEND_TO_SEGMENT:
      iconName = SvgNames.segment
      color = 'gray'
      break
    case ProgramStepType.EXIT:
      iconName = SvgNames.exitProgram
      color = 'magenta'
      break
    case ProgramStepType.SEND:
      iconName = SvgNames.airplane
      color = 'green'
      break
    case ProgramStepType.WAIT:
    case ProgramStepType.WAIT_UNTIL:
    case ProgramStepType.WAIT_UNTIL_IN_SEGMENT:
      iconName = SvgNames.hourglass
      color = 'blue'
      break
    case ProgramStepType.OUTGOING_WEBHOOK:
      iconName = SvgNames.outgoingWebhook
      color = 'gray'
      break
    case ProgramStepType.EXTERNAL_API:
      iconName = SvgNames.world
      color = 'gray'
      break
    case ProgramStepType.SMS:
      iconName = SvgNames.smsSelected
      color = 'sms'
      break
    case ProgramStepType.UPDATE_SUBSCRIPTION:
      iconName = SvgNames.newspaper
      color = 'gray'
      break
  }

  if (overrideColorAndHeight) {
    color = 'override'
  }

  return { color, iconName }
}
