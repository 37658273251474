import { gql } from '@apollo/client'

export default gql`
  query loadOverviewPage {
    loadOverviewPage {
      accountId
      firstName
      lastName
      jobTitle
      street
      street2
      city
      state
      zip
      country
      bizPhone
      callPhone
      companyName
      login
      fax
      userType
      userRole
      timeZone
    }
  }
`
