import React, { FC } from 'react'

import classNames from 'classnames'

import InfoStatus, { InfoStatusTypes } from '@components/InfoStatus/InfoStatus'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './RequiredFieldsMissingModalBody.css'

interface Props {
  errors: string[]
}

const rootClass = 'required-fields-missing-modal-body'

const RequiredFieldsMissingModalBody: FC<Props> = ({ errors }) => {
  const { t } = useTranslation()

  return (
    <div className={rootClass}>
      <InfoStatus svgName={SvgNames.cautionYellow} status={InfoStatusTypes.Caution} noWrapperMargin className={`${rootClass}__info-status`}>
        <Typography text={t('EmailComposer.Required.Fields.Missing.Modal.Caution')} />
      </InfoStatus>
      <div className={classNames(`${rootClass}__errors`, 'push-down-x4')}>
        <div className={classNames(`${rootClass}__errors-header`, 'flex-align-center')}>
          <Svg name={SvgNames.errorSolid} type={SvgType.LARGER_ICON} />
          <Typography text="EmailComposer.Required.Fields.Missing.Modal.Header" weight={TextWeight.MEDIUM} values={{ count: errors?.length }} />
        </div>
        <div className="inset-right-x4 inset-left-x4">
          {errors.map((error, index) => (
            <Typography key={index} text={`${index + 1}. ${t(error)}`} className="push-up-x2 push-down-x2" />
          ))}
        </div>
      </div>
    </div>
  )
}

export default RequiredFieldsMissingModalBody
