import { gql } from '@apollo/client'

export default gql`
  mutation unScheduleWebinarMessage($programId: String, $groupId: String, $taskId: String) {
    unScheduleWebinarMessage(programId: $programId, groupId: $groupId, taskId: $taskId) {
      success
      message
    }
  }
`
