import { createContext } from 'react'

export interface UpgradeProgramsBannerContainerState {
  showAutomatedProgramsUpgradeBanner: boolean
  upgradeCompleted: boolean
}

export const UpgradeProgramsBannerContainerInitialState: UpgradeProgramsBannerContainerState = {
  showAutomatedProgramsUpgradeBanner: true,
  upgradeCompleted: true,
}

export type Update = (fieldsToUpdate: Partial<UpgradeProgramsBannerContainerState>) => void

export interface UpgradeProgramsBannerAPI {
  values: UpgradeProgramsBannerContainerState
  update: Update
}

export const UpgradeProgramsBannerContext = createContext<UpgradeProgramsBannerAPI>({ values: UpgradeProgramsBannerContainerInitialState } as any)
