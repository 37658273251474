import { Context, useContext } from 'react'

import { CommonComposerState } from '@utils/composer/commonComposer/CommonComposer.context'
import { ComposerContext, ComposerContextType } from '@utils/composer/context/ComposerContext.context'
import { EmailComposerAPI, EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { LandingPageComposerAPI, LandingPageComposerContext, LandingPageComposerState } from '@utils/composer/context/LandingPageComposer.context'

export const useComposerContext = <
  V extends CommonComposerState,
  A = V extends LandingPageComposerState ? LandingPageComposerAPI : EmailComposerAPI
>() => {
  const { type } = useContext(ComposerContext)
  const isLandingPage = type === ComposerContextType.LANDING_PAGE

  const context = isLandingPage
    ? (LandingPageComposerContext as unknown as Context<{ values: V; api: A }>)
    : (EmailComposerContext as unknown as Context<{ values: V; api: A }>)

  return useContext(context)
}
