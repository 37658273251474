import React, { FC, useCallback } from 'react'

import classNames from 'classnames'

import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { LitmusPreviewGroups } from '@src/pages/EmailComposer/components/EmailComposerPreview/EmailComposerPreview.utils'
import { LitmusPreview } from '@utils/composer/context/EmailComposer.context'

import { InboxSidebarEmptyState } from '../InboxSidebarEmptyState/InboxSidebarEmptyState'

import './InboxSidebarPreviewsList.css'

interface InboxSidebarPreviewsListProps {
  searchValue: string
  type: LitmusPreviewGroups
  previews: LitmusPreview[]
  selectedPreview: LitmusPreview | null
  onSelect: (preview: LitmusPreview) => void
}

const rootClass = 'inbox-sidebar-previews-list'

const InboxSidebarPreviewsList: FC<InboxSidebarPreviewsListProps> = ({ searchValue, type, previews, selectedPreview, onSelect }) => {
  const scrollToSelectedElement = useCallback(
    (element: HTMLDivElement | null) => {
      if (selectedPreview && element) {
        element.scrollIntoView?.({ behavior: 'auto', block: 'nearest' })
      }
    },
    [selectedPreview]
  )

  return !previews.length ? (
    <InboxSidebarEmptyState isChild isSearch={!!searchValue} type={type} />
  ) : (
    <div className={rootClass}>
      {previews.map((preview) => {
        const isSelected = selectedPreview?.index === preview.index

        return (
          <div
            ref={isSelected ? (element) => scrollToSelectedElement(element) : null}
            tabIndex={0}
            role="button"
            key={`${preview.title}-${preview.platform}`}
            className={classNames(`${rootClass}__card`, {
              [`${rootClass}__card--selected`]: isSelected,
            })}
            data-test={`${rootClass}-card${isSelected ? '--selected' : ''}`}
            onClick={() => !isSelected && onSelect(preview)}
            onKeyDown={(keyDownEvent) => !isSelected && (keyDownEvent.key === ' ' || keyDownEvent.key === 'Enter' ? onSelect(preview) : undefined)}
          >
            {searchValue ? (
              renderBoldTextOnMatch(preview.title || '', searchValue, true, { type: TextType.SECTION_HEADER, weight: TextWeight.MEDIUM })
            ) : (
              <Typography text={preview.title} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
            )}
            <Typography text={preview.platform} type={TextType.BODY_TEXT_LIGHT} />
          </div>
        )
      })}
    </div>
  )
}

export default InboxSidebarPreviewsList
