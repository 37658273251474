import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { MultipleTables } from '@components/TableV2/components/MultipleTables/MultipleTables'
import { TableV2Props } from '@components/TableV2/tableV2TS/interfaces'
import TableWithLoaderAndEmptyListing from '@components/TableWithLoaderAndEmptyListing/TableWithLoaderAndEmptyListing'
import { useTranslation } from '@const/globals'
import { UserResponse } from '@graphql/types/query-types'
import { useSalesUsers } from '@src/pages/Settings/Users/context/SalesUsersContext'
import { useAllUsers } from '@src/pages/Settings/Users/context/UsersContext'
import EditLaunchPermissionsModal from '@src/pages/Settings/Users/tabs/SalesUsers/components/EditLaunchPermissionsModal/EditLaunchPermissionsModal'
import SalesUsersSubHeader from '@src/pages/Settings/Users/tabs/SalesUsers/components/SalesUsersSubHeader'
import SalesUsersTableInfoBar from '@src/pages/Settings/Users/tabs/SalesUsers/components/SalesUsersTableInfoBar'
import useUpdateFromCRM from '@src/pages/Settings/Users/tabs/SalesUsers/hooks/useUpdateFromCRM'
import { useGetTableAdminProps, useGetTableV2AdminProps } from '@src/pages/Settings/Users/tabs/SalesUsers/utils/salesUsers.tableAdminProps'
import { tableColumns, tableV2Columns } from '@src/pages/Settings/Users/tabs/SalesUsers/utils/salesUsers.tableColumns'
import getTableProps from '@src/pages/Settings/Users/tabs/SalesUsers/utils/salesUsers.tableProps'

import './SalesUsers.css'

interface SalesUsersProps {
  className?: string
  dataTest?: string
}

const defaultSortBy = { sortBy: [{ id: 'fullName', desc: false }] }
const rootClass = 'sales-users'

const SalesUsers: FC<SalesUsersProps> = (props: SalesUsersProps) => {
  const { dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const { isAdministrator, onAddUsers } = useAllUsers()
  const { salesUsers, loading: loadingSales, refetchRefs, enableLaunch } = useSalesUsers()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [userToEditLaunchPermissions, setUserToEditLaunchPermissions] = useState<UserResponse | undefined>(undefined)

  const filteredUsers = useMemo<UserResponse[]>(() => {
    const term = searchTerm.toLowerCase()
    return term
      ? salesUsers.filter((user: UserResponse) => {
          const { fullName, firstName, lastName, email, title, isCRM } = user
          const type = isCRM ? 'CRM' : 'Portal'
          return [fullName, firstName, lastName, email, title, type].some((attr) => attr && attr.toLowerCase().includes(term))
        })
      : salesUsers
  }, [salesUsers, searchTerm])

  const { handleUpdateCRM, loading: loadingUpdate } = useUpdateFromCRM({ refetch: refetchRefs.refreshSalesUsers, salesUsers })
  const loading = useMemo<boolean>(() => loadingSales || loadingUpdate, [loadingUpdate, loadingSales])

  const handleLaunchModalCancel = useCallback(() => setUserToEditLaunchPermissions(undefined), [])
  const columns = useMemo(() => tableColumns(t), [t])
  const tableProps = useMemo(
    () => getTableProps({ t, isAdmin: isAdministrator, haveData: !!salesUsers.length, searchTerm, onAddUsers }),
    [isAdministrator, salesUsers.length, searchTerm, onAddUsers]
  )
  const adminProps = useGetTableAdminProps({ setUserToEditLaunchPermissions, handleUpdateCRM })
  const adminPropsV2 = useGetTableV2AdminProps({ setUserToEditLaunchPermissions, handleUpdateCRM })

  const tableV2Props: TableV2Props<UserResponse> = useMemo(
    () => ({
      data: filteredUsers,
      columns: tableV2Columns(t),
      loading,
      enableCheckbox: true,
      enableOuterLoader: true,
      sortingBy: defaultSortBy.sortBy,
      rowUniqueIdKey: 'id',
      emptyState: {
        size: EmptyListingSize.MEDIUM,
        hideIcon: false,
        headline: !!salesUsers.length ? t('Sales.Users.Table.Empty.Search.Headline', { searchTerm }) : t('Sales.Users.Table.Empty.Headline'),
        text: t(!!salesUsers.length ? 'Sales.Users.Table.Empty.Search.Text' : 'Sales.Users.Table.Empty.Text'),
        imgSrc: !!salesUsers.length ? StaticImageNames.emptySearch : StaticImageNames.emptyContacts,
        buttonText: !salesUsers.length && isAdministrator ? t('Sales.Users.Table.Empty.Button.Text') : undefined,
        buttonOnClick: !salesUsers.length && isAdministrator ? onAddUsers : undefined,
      },
      ...adminPropsV2,
    }),
    [dataTest, rootClass, filteredUsers, t, loading, salesUsers.length, isAdministrator, onAddUsers]
  )

  useEffect(() => {
    refetchRefs.refreshSalesUsers()
  }, [])

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <SalesUsersSubHeader />
      {!loading && <SalesUsersTableInfoBar onSearch={setSearchTerm} searchTerm={searchTerm} updateFromCRM={handleUpdateCRM} />}
      <MultipleTables
        tableV2Props={tableV2Props}
        oldTable={
          <TableWithLoaderAndEmptyListing
            dataTest={`${dataTest}-table`}
            className={`${rootClass}__table`}
            data={filteredUsers}
            columns={columns}
            loading={loading}
            headerAddMargin
            {...tableProps}
            {...adminProps}
            initialState={defaultSortBy}
          />
        }
      />
      {isAdministrator && enableLaunch && <EditLaunchPermissionsModal user={userToEditLaunchPermissions} onCancel={handleLaunchModalCancel} />}
    </div>
  )
}

export default SalesUsers
