import { ListingPageItem } from '@complex/ListingPage/Context/ListingPageCommon.context'
import getItem from '@graphql/microservices/categorization/getItem'
import searchSegments from '@graphql/microservices/categorization/searchSegments'
import accountRegistration from '@graphql/microservices/ml-audience/accountRegistration'
import config from '@graphql/microservices/ml-audience/config'
import featuresCatDefault from '@graphql/microservices/ml-audience/featuresCatDefault'
import featuresNumDefault from '@graphql/microservices/ml-audience/featuresNumDefault'
import job from '@graphql/microservices/ml-audience/job'
import problems from '@graphql/microservices/ml-audience/problems'
import resetConfig from '@graphql/microservices/ml-audience/resetConfig'
import segment from '@graphql/microservices/ml-audience/segment'
import setConfig from '@graphql/microservices/ml-audience/setConfig'
import setSelectedParentSegment from '@graphql/microservices/ml-audience/setSelectedParentSegment'
import { default as startJobMutation } from '@graphql/microservices/ml-audience/startJob'
import { default as updateSegmentMutation } from '@graphql/microservices/ml-audience/updateSegment'
import {
  GetItemQuery,
  GetItemQueryVariables,
  ItemDto,
  SearchSegmentsQuery,
  SearchSegmentsQueryVariables,
} from '@graphql/types/microservice/categorization-types'
import {
  AccountRegistrationQuery,
  AccountRegistrationQueryVariables,
  ConfigInput,
  ConfigQuery,
  ConfigQueryVariables,
  FeaturesCatDefaultQuery,
  FeaturesCatDefaultQueryVariables,
  FeaturesNumDefaultQuery,
  FeaturesNumDefaultQueryVariables,
  JobQuery,
  JobQueryVariables,
  Problem,
  ProblemsQuery,
  ProblemsQueryVariables,
  ResetConfigMutation,
  ResetConfigMutationVariables,
  Segment,
  SegmentFeedback,
  SegmentQuery,
  SegmentQueryVariables,
  SegmentStatus,
  SetConfigMutation,
  SetConfigMutationVariables,
  SetSelectedParentSegmentMutation,
  SetSelectedParentSegmentMutationVariables,
  StartJobMutation,
  StartJobMutationVariables,
  UpdateSegmentMutation,
  UpdateSegmentMutationVariables,
} from '@graphql/types/microservice/ml-audience-types'
import { ALL_ACTON_CONTACTS_SEGMENT_NAME } from '@src/pages/ContactSegments/utils/ContactSegments.constants'
import { FeedbackOption } from '@src/pages/RecommendedSegments/components/DiscardedSegmentFeedbackModal/utils/DiscardedSegmentFeedbackModal.constants'
import { FeaturesCategories } from '@src/pages/RecommendedSegments/utils/RecommendedSegments.constants'
import { SegmentationCriteria } from '@src/pages/RecommendedSegments/utils/RecommendedSegments.interfaces'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export interface RecommendedSegmentsRequests {
  getAccountRegistration: () => FetchPromise<AccountRegistrationQuery>
  getConfiguration: (configId?: string) => FetchPromise<ConfigQuery>
  getDefaultFeatures: () => Promise<SegmentationCriteria[]>
  getJob: (executionId?: string) => FetchPromise<JobQuery>
  getAllContactsSegment: () => Promise<(ListingPageItem & { recordsCount: number }) | undefined>
  getProblems: () => Promise<Problem[]>
  getSegment: (segmentId?: string) => Promise<Segment[]>
  getSegmentItemById: (externalId: string) => FetchPromise<GetItemQuery>
  resetConfiguration: () => FetchPromise<ResetConfigMutation>
  setConfiguration: (config: Partial<ConfigInput>) => FetchPromise<SetConfigMutation>
  setSelectedParentRequest: (segmentId?: string) => FetchPromise<SetSelectedParentSegmentMutation>
  startJob: () => FetchPromise<StartJobMutation>
  updateSegment: (segmentId: string, status: SegmentStatus, feedback?: FeedbackOption[]) => FetchPromise<UpdateSegmentMutation>
}

export const useRecommendedSegmentsRequests = (): RecommendedSegmentsRequests => {
  const { client: mlAudienceClient } = useMicroserviceClient({ serviceName: MicroserviceClients.ML_AUDIENCE })
  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })
  const { accountId } = useAccountSettings()

  const getAccountRegistration = () =>
    mlAudienceClient.query<AccountRegistrationQuery, AccountRegistrationQueryVariables>({
      fetchPolicy: 'network-only',
      query: accountRegistration,
      variables: {
        accountId,
      },
    })

  const getDefaultNumericalFeatures = () =>
    mlAudienceClient.query<FeaturesNumDefaultQuery, FeaturesNumDefaultQueryVariables>({
      query: featuresNumDefault,
    })

  const getDefaultCategoricalFeatures = () =>
    mlAudienceClient.query<FeaturesCatDefaultQuery, FeaturesCatDefaultQueryVariables>({
      query: featuresCatDefault,
    })

  const getDefaultFeatures = () => {
    return Promise.all([getDefaultNumericalFeatures(), getDefaultCategoricalFeatures()]).then(
      ([{ data: numericalFeaturesData }, { data: categoricalFeaturesData }]) => {
        const categorizedFeatures: SegmentationCriteria[] = [
          { category: FeaturesCategories.CATEGORICAL, features: categoricalFeaturesData?.featuresCatDefault as string[] },
          { category: FeaturesCategories.NUMERICAL, features: numericalFeaturesData?.featuresNumDefault as string[] },
        ]
        return categorizedFeatures
      }
    )
  }

  const getConfiguration = (configId?: string) => {
    return mlAudienceClient.query<ConfigQuery, ConfigQueryVariables>({
      fetchPolicy: 'network-only',
      query: config,
      variables: {
        configId,
        accountId,
      },
    })
  }

  const setConfiguration = (config: Partial<ConfigInput>) => {
    return mlAudienceClient.mutate<SetConfigMutation, SetConfigMutationVariables>({
      mutation: setConfig,
      variables: {
        config,
        accountId,
      },
    })
  }

  const resetConfiguration = () => {
    return mlAudienceClient.mutate<ResetConfigMutation, ResetConfigMutationVariables>({
      mutation: resetConfig,
      variables: {
        accountId,
      },
    })
  }

  const getAllContactsSegment = () => {
    return categorizationClient
      .query<SearchSegmentsQuery, SearchSegmentsQueryVariables>({
        fetchPolicy: 'network-only',
        query: searchSegments,
        variables: {
          allSegments: true,
          field: 'name',
          query: ALL_ACTON_CONTACTS_SEGMENT_NAME,
          type: ItemType.SEGMENT,
        },
      })
      .then(({ data }) => {
        const items = data.search?.items as ItemDto[]
        return items
          .filter((segment) => {
            const parsedItem = JSON.parse(segment.item ?? '{}')
            return 'name' in parsedItem && parsedItem.name === ALL_ACTON_CONTACTS_SEGMENT_NAME
          })
          .reduce((_: (ListingPageItem & { recordsCount: number }) | undefined, segment) => {
            const parsedItem = JSON.parse(segment.item ?? '{}')
            return { ...segment, ...parsedItem }
          }, undefined)
      })
  }

  const getSegment = (segmentId?: string) => {
    const todayDate = new Date()
    const lastMonthTimestamp = todayDate.setDate(todayDate.getDate() - 30)
    return mlAudienceClient
      .query<SegmentQuery, SegmentQueryVariables>({
        fetchPolicy: 'network-only',
        query: segment,
        variables: {
          segmentId,
          accountId,
        },
      })
      .then(({ data }) => (data.segment ?? [])?.filter((segment) => parseInt(segment?.updateTime ?? '0') > lastMonthTimestamp) as Segment[])
  }

  const getSegmentItemById = (externalId?: string) => {
    return categorizationClient.query<GetItemQuery, GetItemQueryVariables>({
      query: getItem,
      variables: {
        externalId,
        type: ItemType.SEGMENT,
      },
    })
  }

  const updateSegment = (segmentDbId: string, status: SegmentStatus, feedback?: string[]) => {
    return mlAudienceClient.mutate<UpdateSegmentMutation, UpdateSegmentMutationVariables>({
      mutation: updateSegmentMutation,
      variables: {
        segmentDbId,
        status,
        accountId,
        segmentFeedback: feedback as SegmentFeedback[],
      },
    })
  }

  const setSelectedParentRequest = (segmentId?: string) => {
    return mlAudienceClient.mutate<SetSelectedParentSegmentMutation, SetSelectedParentSegmentMutationVariables>({
      mutation: setSelectedParentSegment,
      variables: {
        segmentId,
        accountId,
      },
    })
  }

  const getJob = (executionId?: string) => {
    return mlAudienceClient.query<JobQuery, JobQueryVariables>({
      fetchPolicy: 'network-only',
      query: job,
      variables: {
        executionId,
        accountId,
      },
    })
  }

  const getProblems = () =>
    mlAudienceClient
      .query<ProblemsQuery, ProblemsQueryVariables>({
        query: problems,
        variables: {
          accountId,
        },
      })
      .then(({ data }) => data.problems as Problem[])

  const startJob = () => {
    return mlAudienceClient.mutate<StartJobMutation, StartJobMutationVariables>({
      mutation: startJobMutation,
      variables: {
        accountId,
      },
    })
  }

  return {
    getAccountRegistration,
    getConfiguration,
    getDefaultFeatures,
    getJob,
    getProblems,
    getAllContactsSegment,
    getSegment,
    getSegmentItemById,
    resetConfiguration,
    setConfiguration,
    setSelectedParentRequest,
    startJob,
    updateSegment,
  }
}
