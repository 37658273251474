import { gql } from '@apollo/client'

export default gql`
  mutation updateComposerState($smsMessage: SmsComposerState!, $initialMessage: Boolean) {
    updateSmsComposerState(composerState: $smsMessage, initialMessage: $initialMessage) {
      launchId
      title
      description
      marketingLists {
        listId
        listType
        listName
        totalContacts
        countMissingPhone
        countNotOptedIn
        countDuplicate
        suppressedInternationalContacts
        headers
        sampleContacts {
          firstName
          lastName
          emailAddress
          recId
        }
      }
      messageText
      recipientsPerCreditMultiplier {
        creditMultiplier
        contacts
        contactsPerCountry {
          country
          contacts
        }
      }
      recipientTimezones {
        tzOffset
        recipients
      }
    }
  }
`
