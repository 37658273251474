import React, { useContext, useEffect } from 'react'

import classNames from 'classnames'

import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import InfoAction from '@components/InfoAction/InfoAction'
import Loader from '@components/Loader'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { SvgNames } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { List } from '@interface/foldersLists/List'
import StepContent from '@src/pages/ContactSegments/components/CopySegments/components/StepContent/StepContent'
import MappingFieldsList from '@src/pages/ContactSegments/components/CopySegments/components/StepThree/components/MappingFieldsList/MappingFieldsList'
import MappingHeaders from '@src/pages/ContactSegments/components/CopySegments/components/StepThree/components/MappingHeaders/MappingHeaders'
import { stepBaseText } from '@src/pages/ContactSegments/components/CopySegments/constants/CopySegments.constants'
import { CopySegmentsContext, Steps } from '@src/pages/ContactSegments/components/CopySegments/context/CopySegments.context'
import { stepProps } from '@src/pages/ContactSegments/components/CopySegments/utils/CopySegments.utils'
import { SegmentType } from '@utils/segments'

import './StepThree.css'

const rootClass = 'step-three'
export const stepThreeText = `${stepBaseText}Three.`

const StepThree = () => {
  const {
    values: { loadingFields, mappedFields, segments, parentType, parentLocation },
    checkForUnmappedSegmentFields,
    getSegmentDefinitionFields,
    getUnifiedListFieldMappings,
    getUnifiedListFieldsFromSource,
  } = useContext(CopySegmentsContext)
  const automaticallyMappedFields = mappedFields.filter(({ automaticallyMapped }) => automaticallyMapped)

  const { t } = useTranslation()

  const updateFields = () => {
    checkForUnmappedSegmentFields()
    getSegmentDefinitionFields()
    if (parentType === SegmentType.SUBMISSION) {
      getUnifiedListFieldsFromSource()
    } else {
      getUnifiedListFieldMappings()
    }
  }

  useEffect(() => {
    const segmentIds = segments.map(({ id }: List) => id).join(',')
    if (segmentIds.length > 0) {
      updateFields()
    }
  }, [segments, parentLocation])

  return (
    <StepContent
      className={classNames(rootClass, { [`${rootClass}__mapping-empty`]: mappedFields.length === 0 })}
      data-test={rootClass}
      {...stepProps[Steps.THREE]}
    >
      {loadingFields ? (
        <Loader className={`${rootClass}__loader`} />
      ) : mappedFields.length === 0 && segments.length === 0 ? (
        <>
          <MappingHeaders />
          <MappingFieldsList />
          <Typography
            className={`${rootClass}__mapping-empty-text`}
            text={t(`${stepThreeText}Mapping.Empty`)}
            type={TextType.BODY_TEXT_LIGHT}
            weight={TextWeight.ITALIC}
          />
        </>
      ) : mappedFields.length === automaticallyMappedFields.length ? (
        <EmptyListing
          className={`${rootClass}__mapping-empty-listing`}
          imgSrc={StaticImageNames.fieldMatching}
          size={EmptyListingSize.MEDIUM}
          withoutBorder
          headline={t(`${stepThreeText}Mapping.Empty.Success.Headline`)}
          text={t(`${stepThreeText}Mapping.Empty.Success.Text`, {
            count: automaticallyMappedFields.length,
            segmentCount: segments.length,
          })}
        />
      ) : (
        <>
          <InfoAction
            className={`${rootClass}__mapping-info-action`}
            svgName={SvgNames.lightBulb}
            message={
              <Typography
                text={t(`${stepThreeText}Mapping.Empty.Success.Text`, {
                  count: automaticallyMappedFields.length,
                  segmentCount: segments.length,
                })}
              />
            }
          />
          <MappingHeaders />
          <MappingFieldsList />
        </>
      )}
    </StepContent>
  )
}

export default StepThree
