import React, { FC, ReactNode, RefObject } from 'react'
import { useHistory } from 'react-router-dom'

import classNames from 'classnames'

import BackButton from '@components/BackButton/BackButton'
import Button, { ButtonType } from '@components/Button'
import PageHeadline, { PageHeaderType } from '@components/PageHeadline/PageHeadline'
import PositionContainer, { PositionContainerTypes } from '@components/PositionContainer/PositionContainer'
import { rootContext, useTranslation } from '@const/globals'

import './PageHeader.css'

interface Props {
  primaryText: string
  primaryTextChildren?: ReactNode
  linkBack?: boolean
  linkUrl?: string
  linkOptions?: { [key: string]: string }
  customLinkCallback?: () => void
  noLinkSvg?: boolean
  headlineType?: PageHeaderType
  secondaryText?: string
  centered?: boolean
  centerContent?: boolean
  centerContainer?: boolean
  leftContent?: boolean
  background?: boolean
  restoreMargin?: boolean
  noInnerPadding?: boolean
  children?: ReactNode
  className?: string
  headlineClassName?: string
  headlineReference?: RefObject<HTMLDivElement>
  dataTest?: string
}

const rootClass = 'page-header'

const PageHeader: FC<Props> = (props: Props) => {
  const {
    primaryText,
    primaryTextChildren,
    linkBack = false,
    linkUrl,
    linkOptions = {},
    customLinkCallback,
    noLinkSvg = false,
    headlineType = PageHeaderType.SUB_HEADER,
    secondaryText,
    centerContent = false,
    leftContent = false,
    centered,
    background = false,
    centerContainer = false,
    restoreMargin = false,
    noInnerPadding = false,
    children,
    className,
    headlineClassName,
    headlineReference,
    dataTest = rootClass,
  } = props
  const { t } = useTranslation()
  const history = useHistory()

  const renderLinkHeader = () => {
    const buttonProps = customLinkCallback
      ? { onClick: customLinkCallback }
      : linkBack
      ? { onClick: () => history.goBack() }
      : { isLink: true, to: `${rootContext}/${linkUrl}`, linkState: linkOptions }
    return (
      <>
        {noLinkSvg ? (
          <Button buttonType={ButtonType.TEXT} className={`${rootClass}__back-button-text`} {...buttonProps}>
            {primaryText}
          </Button>
        ) : (
          <BackButton className={`${rootClass}__back-button-svg`} {...buttonProps} />
        )}
        <PageHeadline title={primaryText} type={PageHeaderType.SUB_HEADER} className={headlineClassName} dataTest={`${rootClass}-header-name`}>
          {noLinkSvg ? (
            <>/ {t(secondaryText)}</>
          ) : (
            <>
              {primaryText}
              {primaryTextChildren}
            </>
          )}
        </PageHeadline>
      </>
    )
  }

  const renderTextHeader = () => (
    <PageHeadline
      title={primaryText}
      type={headlineType}
      className={headlineClassName}
      reference={headlineReference}
      dataTest={`${rootClass}-header-name`}
    >
      {primaryText}
      {primaryTextChildren}
    </PageHeadline>
  )

  const renderContent = () => {
    return (
      <>
        {linkBack || linkUrl || customLinkCallback ? renderLinkHeader() : renderTextHeader()}
        {centered ? <span className={`${rootClass}__centered-children`}>{children}</span> : children}
      </>
    )
  }

  return (
    <div
      className={classNames(rootClass, className, 'no-print', {
        [`${rootClass}__not-centered`]: !centerContent && !leftContent,
        [`${rootClass}__left`]: leftContent,
        [`${rootClass}-background`]: background,
        [`${rootClass}__center-container`]: centerContainer,
      })}
      data-test={`${dataTest}-header`}
    >
      {centerContent ? (
        <PositionContainer displayType={PositionContainerTypes.flex} restoreMargin={restoreMargin} noInnerPadding={noInnerPadding} childrenFlexAdjust>
          {renderContent()}
        </PositionContainer>
      ) : (
        renderContent()
      )}
    </div>
  )
}

export default PageHeader
