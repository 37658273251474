import { gql } from '@apollo/client'
import { folderLists } from '@graphql/queries/fragments/lists'

export default gql`
  ${folderLists}

  query FavoritesLists($type: String!, $noMirroredJoinLists: Boolean) {
    getFavoritesLists(type: $type, noMirroredJoinLists: $noMirroredJoinLists) {
      ...FolderListsFragment
    }
  }
`
