import { TFunction } from 'i18next'

import { RibbonColor, RibbonMedals } from '@components/CarouselDataCard/CarouselDataCardUtils'
import { SvgNames } from '@components/Svg'

export const getEmptyPerformanceCard = (t: TFunction): RibbonMedals => ({
  title: t('No form submission data'),
  subTitle: t('Once you have submissions, your top performing forms will show up here.'),
  svg: SvgNames.warning,
  background: RibbonColor.Empty,
})
