import React from 'react'

import classNames from 'classnames'

import { useStickyColWidths } from '@components/TableV2/hook/tableV2.hook'
import { Selection } from '@components/TableV2/tableV2TS/enums'
import { TableV2Props } from '@components/TableV2/tableV2TS/interfaces'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { isColumnIdEqual } from '@components/TableV2/utils/tableV2ColumnUtils'
import { getCellStyles, sortDirection } from '@components/TableV2/utils/tableV2Utils'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { HeaderGroup, SortDirection, defaultColumnSizing, flexRender } from '@tanstack/react-table'
import { isFunction } from '@utils/utils'

import './THead.css'

interface THeadProps<T = {}> {
  enableSorting?: boolean
  enableStickyHeader?: boolean
  headerGroups: HeaderGroup<T>[]
  stickyHeaderTopPosition?: number
  stickyColumns?: TableV2Props<T>['stickyColumns']
}

const rootClass = 'tableV2-head'

export const THead = <T extends {}>({ headerGroups, stickyColumns, enableSorting, enableStickyHeader, stickyHeaderTopPosition }: THeadProps<T>) => {
  const { t } = useTranslation()
  const stickyColsWidths = useStickyColWidths(stickyColumns)

  return (
    <thead
      style={{ top: `${stickyHeaderTopPosition}px` }}
      className={classNames(`${rootClass}__thead`, {
        [`${rootClass}__sticky-header`]: enableStickyHeader,
      })}
    >
      {headerGroups.map((headerGroup) => (
        <tr className={`${rootClass}__row`} key={headerGroup.id}>
          {headerGroup.headers.map((header, i) => {
            const { column } = header
            const { columnDef }: { columnDef: ColumnDefWithAdditionalProps<T> } = column
            const cellRadio = header.id === Selection.Radio
            const cellCheckbox = header.id === Selection.Checkbox
            const { maxSize, minSize } = defaultColumnSizing
            const { maxSize: maxWidth, minSize: minWidth } = columnDef
            const headerValue = flexRender(columnDef.header, header.getContext())
            const typographyText = typeof headerValue === 'string' ? t(headerValue) : headerValue
            const cellStyles = getCellStyles(i, columnDef, maxWidth, maxSize, minWidth, minSize, stickyColumns, stickyColsWidths)
            const canSort = enableSorting && !columnDef.disableSorting

            return (
              <th
                data-sticky
                key={header.id}
                onClick={(canSort && column.getToggleSortingHandler()) || undefined}
                className={classNames(`${rootClass}__th`, {
                  [`${rootClass}__radio`]: cellRadio,
                  [`${rootClass}__checkbox`]: cellCheckbox,
                  [`${rootClass}__sticky-column`]: !!stickyColumns?.length && isColumnIdEqual(stickyColumns, header),
                })}
                onKeyPress={() => canSort && column.toggleSorting()}
                style={cellStyles}
              >
                {columnDef.enableCustomCellValue && cellCheckbox ? (
                  <>{headerValue}</>
                ) : (
                  <div
                    className={classNames({
                      [`${rootClass}__header-with-sort`]: canSort,
                    })}
                  >
                    <Typography text={header.isPlaceholder ? null : typographyText} key={headerGroup.id} type={TextType.TABLE_HEADER} />
                    {canSort && isFunction(column.getIsSorted) && column.getIsSorted() && (
                      <div
                        className={classNames(`${rootClass}__header-with-sort-icon`, {
                          [`${rootClass}__header-with-sort-icon--sorted`]: column.getIsSorted(),
                        })}
                        aria-live="polite"
                        aria-label={`Sorted by column ${column.id} in ${column.getIsSorted()} order`}
                      >
                        {sortDirection[column.getIsSorted() as SortDirection]}
                      </div>
                    )}
                  </div>
                )}
              </th>
            )
          })}
        </tr>
      ))}
    </thead>
  )
}
