import React from 'react'

import classNames from 'classnames'
import isToday from 'date-fns/isToday'
import isYesterday from 'date-fns/isYesterday'

import { ApolloClient } from '@apollo/client'
import { getFolderById } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { ListingPageSession } from '@complex/ListingPage/Utils/ListingPage.utils'
import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType } from '@components/Typography/Typography'
import searchByTags from '@graphql/microservices/categorization/searchByTags'
import searchSegments from '@graphql/microservices/categorization/searchSegments'
import searchSegmentsByAuthor from '@graphql/microservices/categorization/searchSegmentsByAuthor'
import searchSegmentsByFavorites from '@graphql/microservices/categorization/searchSegmentsByFavorites'
import searchSegmentsByRecent from '@graphql/microservices/categorization/searchSegmentsByRecent'
import {
  ItemDto,
  SearchByTagsQuery,
  SearchByTagsQueryVariables,
  SearchSegmentsQuery,
  SearchSegmentsQueryVariables,
} from '@graphql/types/microservice/categorization-types'
import { AccountSettings } from '@graphql/types/query-types'
import { Folder } from '@interface/Folder'
import { doFilterBounce, doFilterSms, ItemSubType, ItemType } from '@utils/categorization'
import { buildSegments, Segment } from '@utils/contactSegments/contactSegments.utils'
import { Update } from '@utils/contactSegments/context/ContactSegmentsContext'
import { allSegmentsFilter, FilterDefinition, FilterTypes } from '@utils/filter'
import { buildHeader } from '@utils/folderUtils'
import { removeItem } from '@utils/sessionStorage'

export const searchColumnsUtilsV2 = (
  t: Function,
  search: string,
  rootClass: string,
  dataTest: string,
  folders: FolderData[],
  searchInAllSegments: boolean | undefined,
  currentFolder: FolderData
): ColumnDefWithAdditionalProps<Segment>[] => [
  {
    header: 'Name',
    accessorKey: 'name',
    textAlign: 'left',
    enableCustomCellValue: true,
    cell: (row) => renderSegmentName(row.cell.getValue<string>(), search, rootClass),
  },
  {
    header: 'Tags',
    accessorKey: 'tags',
    textAlign: 'left',
    maxSize: 150,
    disableSorting: true,
    enableCustomCellValue: true,
  },
  {
    header: 'Location',
    accessorKey: 'folderId',
    textAlign: 'left',
    maxSize: 150,
    cell: (row) => renderFolderPath(row.cell.getValue<number>(), folders, searchInAllSegments, rootClass, t, currentFolder),
  },
  {
    header: 'Last Modified',
    accessorKey: 'updatedTime',
    textAlign: 'left',
    maxSize: 150,
    cell: (row) => renderUpdatedTime(row.cell.getValue<string>(), dataTest, t),
  },
]

export const clearContactSegmentsSearch = (update: Update) => {
  update({ search: undefined, searchAllItems: undefined })
  removeItem(`${ItemType.SEGMENT}:${ListingPageSession.SEARCH_QUERY}`)
  removeItem(`${ItemType.SEGMENT}:${ListingPageSession.SEARCH_SHOW_ALL}`)
}

export const onSearchEmptyButtonClicked = (setFilter: (filter: FilterDefinition) => void, update: Update) => {
  setFilter(allSegmentsFilter)
  clearContactSegmentsSearch(update)
}

export const renderFolderPath = (
  folderId: number,
  folders: FolderData[],
  inAllSegments: boolean | undefined,
  rootClass: string,
  t: Function,
  activeFolder?: FolderData
) => {
  const allSegmentsLocation = t('All Segments')
  const path = inAllSegments
    ? buildHeader(folderId, folders).join('/') || allSegmentsLocation
    : activeFolder?.name ?? (getFolderById(folderId, folders as Folder[])?.name || allSegmentsLocation)
  return <Typography title={path} className={classNames(`${rootClass}__search-path`, `ellip`)} text={path} type={TextType.BODY_TEXT_SMALL_LIGHT} />
}

export const renderSegmentName = (name: string, search: string, rootClass: string) => (
  <div className={`${rootClass}__search-name`}>
    <Svg name={SvgNames.segmentsUnselected} type={SvgType.MEDIUM} />
    <div className={`${rootClass}__search-name-typography`}>{renderBoldTextOnMatch(name, search)}</div>
  </div>
)

export const renderUpdatedTime = (timestamp: string, dataTest: string, t: Function) => {
  const date = new Date(timestamp)
  const dateToString = date.toLocaleString('en-US')
  const label = isToday(date) ? t('Today') : isYesterday(date) ? t('Yesterday') : ''

  return label ? (
    <Tooltip position={'top'} trigger={<Typography dataTest={`${dataTest}__updated-time`} text={label} />}>
      {dateToString}
    </Tooltip>
  ) : (
    <Typography dataTest={`${dataTest}__updated-time`} text={dateToString} />
  )
}

export const doSearchSegments = (
  searchAll: boolean,
  activeFilter: FilterDefinition | undefined,
  activeFolderId: number | undefined,
  activeTagId: number | undefined,
  search: string,
  update: Update,
  categorizationClient: ApolloClient<any>,
  accountSettings: AccountSettings
) => {
  if (
    searchAll ||
    !activeFilter ||
    [FilterTypes.ALL_SEGMENTS, FilterTypes.CONTACT_PREFERENCES, FilterTypes.CRM_SOURCED].includes(activeFilter?.name as FilterTypes)
  ) {
    const searchInAllSegments = (!activeTagId && !activeFolderId) || searchAll
    if (activeFolderId || searchInAllSegments) {
      const itemType = activeFilter?.name === FilterTypes.CONTACT_PREFERENCES && !searchAll ? ItemType.BOUNCE : ItemType.SEGMENT
      const itemSubType = activeFilter?.name === FilterTypes.CRM_SOURCED && !searchAll ? ItemSubType.CRM_SOURCED : undefined
      doSearchSegmentsUtils(searchInAllSegments, search, update, categorizationClient, itemType, itemSubType, accountSettings, activeFolderId)
    } else {
      activeTagId && doSearchSegmentsByTagUtils(search, update, categorizationClient, activeTagId)
    }
  } else {
    doSearchSegmentsByFilterUtils(search, activeFilter, update, categorizationClient)
  }
}

export const doSearchSegmentsUtils = (
  inAllSegments: boolean,
  search: string,
  update: Update,
  client: ApolloClient<any>,
  type: ItemType,
  itemSubType: ItemSubType | undefined,
  { hasPurchasedSMS, isUsingExternalSegmentationService }: AccountSettings,
  activeFolderId?: number
) => {
  update({ searchItemsLoading: true })
  client
    .query<SearchSegmentsQuery, SearchSegmentsQueryVariables>({
      query: searchSegments,
      fetchPolicy: 'network-only',
      variables: {
        type,
        query: search,
        allSegments: inAllSegments || !activeFolderId,
        folder: activeFolderId,
        field: 'name',
        subTypes: !!itemSubType ? [itemSubType] : undefined,
      },
    })
    .then(({ data }) => {
      const { search } = data
      let items = search?.items as ItemDto[]

      if (type === ItemType.BOUNCE) {
        if (!hasPurchasedSMS) {
          items = doFilterSms(items)
        }
        if (isUsingExternalSegmentationService) {
          items = doFilterBounce(items)
        }
      }

      update({
        searchItemsResults: buildSegments(items),
        searchItemsLoading: false,
      })
    })
    .catch(() => update({ searchItemsLoading: false }))
}

export const doSearchSegmentsByTagUtils = (search: string, update: Update, client: ApolloClient<any>, activeTagId: number) => {
  update({ searchItemsLoading: true })
  client
    .query<SearchByTagsQuery, SearchByTagsQueryVariables>({
      query: searchByTags,
      fetchPolicy: 'network-only',
      variables: {
        type: ItemType.SEGMENT,
        query: search,
        labelIds: [activeTagId],
        field: 'name',
      },
    })
    .then(({ data }) => {
      update({
        searchItemsResults: buildSegments(data.searchByLabels),
        searchItemsLoading: false,
      })
    })
    .catch(() => update({ searchItemsLoading: false }))
}

export const doSearchSegmentsByFilterUtils = (search: string, { name: filter }: FilterDefinition, update: Update, client: ApolloClient<any>) => {
  const searchBy: { [key: string]: any } = {
    [FilterTypes.FAVORITES]: { query: searchSegmentsByFavorites, field: 'searchByFavorites' },
    [FilterTypes.RECENT]: { query: searchSegmentsByRecent, field: 'searchByRecent' },
    [FilterTypes.CREATED_BY_ME]: { query: searchSegmentsByAuthor, field: 'searchByAuthor' },
    [FilterTypes.ALL_SEGMENTS]: undefined,
    [FilterTypes.CONTACT_PREFERENCES]: undefined,
  }

  const searchQueryValues = searchBy[filter]
  if (searchQueryValues) {
    const { query, field } = searchQueryValues
    update({ searchItemsLoading: true })
    client
      .query({
        query,
        fetchPolicy: 'network-only',
        variables: {
          type: ItemType.SEGMENT,
          query: search,
          field: 'name',
        },
      })
      .then(({ data }) => {
        update({
          searchItemsResults: buildSegments(data[field]),
          searchItemsLoading: false,
        })
      })
      .catch(() => update({ searchItemsLoading: false }))
  }
}
