import recommendedSources from '@graphql/microservices/crm/recommendedSources'
import segmentSources from '@graphql/microservices/crm/segmentSources'
import {
  RecommendedSourcesQuery,
  RecommendedSourcesQueryVariables,
  SegmentSourcesQuery,
  SegmentSourcesQueryVariables,
} from '@graphql/types/microservice/crm-types'
import { CRMSourceOption } from '@utils/crm.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

type GetBaseRequestParams = { connectedCrmName: string }
type CRMSourceOptionParam = { crmSourceOption: CRMSourceOption }

type GetRecommendedSourcesRequestParams = CRMSourceOptionParam & { searchTerm?: string }
export type GetRecommendedSourcesRequestType = (params: GetRecommendedSourcesRequestParams) => FetchPromise<RecommendedSourcesQuery>

type GetSearchSegmentSourcesRequestParams = GetSegmentSourcesRequestParams & { searchTerm: string }

export type GetSearchSegmentSourcesRequestType = (params: GetSearchSegmentSourcesRequestParams) => FetchPromise<SegmentSourcesQuery>

type GetSegmentSourcesRequestParams = CRMSourceOptionParam & GetBaseRequestParams
export type GetSegmentSourcesRequestType = (params: GetSegmentSourcesRequestParams) => FetchPromise<SegmentSourcesQuery>

export interface CRMSourcePickerModalRequests {
  getRecommendedSourcesRequest: GetRecommendedSourcesRequestType
  getSearchSegmentSourcesRequest: GetSearchSegmentSourcesRequestType
  getSegmentSourcesRequest: GetSegmentSourcesRequestType
}

export const useCRMSourcePickerModalRequests = (): CRMSourcePickerModalRequests => {
  const {
    client: { query },
  } = useMicroserviceClient({ serviceName: MicroserviceClients.CRM })

  const getRecommendedSourcesRequest = async ({ crmSourceOption: { name: segmentSourceTypeName }, searchTerm }: GetRecommendedSourcesRequestParams) =>
    await query<RecommendedSourcesQuery, RecommendedSourcesQueryVariables>({
      query: recommendedSources,
      variables: { segmentSourceTypeName, searchTerm },
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    })

  const getSegmentSourcesRequest = (params: GetSegmentSourcesRequestParams) => getSearchSegmentSourcesRequest({ ...params, searchTerm: '' })

  const getSearchSegmentSourcesRequest = async ({
    connectedCrmName,
    crmSourceOption: { name: segmentSourceTypeName },
    searchTerm,
  }: GetSearchSegmentSourcesRequestParams) =>
    await query<SegmentSourcesQuery, SegmentSourcesQueryVariables>({
      query: segmentSources,
      variables: { connectedCrmName, segmentSourceTypeName, searchTerm },
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    })

  return {
    getRecommendedSourcesRequest,
    getSearchSegmentSourcesRequest,
    getSegmentSourcesRequest,
  }
}
