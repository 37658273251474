import { ReactNode } from 'react'

import { PersonalizationItem } from '@complex/Personalization/utils/Personalization.context'
import { AssetReportRecipientDetails, MessageType } from '@graphql/types/query-types'
import { isCustomerCareLogin } from '@utils/cookie'

import { EmailComposerState } from '../context/EmailComposer.context'

export interface DisplayConditionSetPredicate {
  /** Personalization name */
  field: string
  /** Extra personalization meta-data */
  uiField?: PersonalizationItem
  /** Operator to use for field */
  type: string
  /** A unique key for operators that are shared between SelectV2 groups */
  uiType: string
  /** Value or lower bound */
  criteria: string
  /** Upper bound, if applicable */
  criteria_max: string
  /** Format for date field */
  dateFormat: string
}

export interface ComposerFormTypeModalProps {
  body?: ReactNode
  title?: string
  onCancel?: VoidFunction
  onConfirm?: VoidFunction
  cancelLabel?: string
  confirmLabel?: string
  className?: string
  dataTest?: string
}

export type ExtendedMessageType = MessageType | 'BLANK_MESSAGE'

export enum MessageConfigurationType {
  TEMPLATE = 'TEMPLATE',
  DRAFT = 'DRAFT',
  FORM_AUTO_RESPONSE = 'FORM',
  PROGRAM_MESSAGE = 'PROGRAM',
  WEBINAR_SINGLE = 'WEBINAR_SINGLE',
  CRM = 'CRM',
  AB_TEST_MESSAGE = 'ABTEST',
  RSS2EMAIL = 'RSS2EMAIL',
  WEBEX_MULTI = 'WEBEX_MULTI',
  GOTOWEBINAR_MULTI = 'GOTOWEBINAR_MULTI',
  RESEND = 'RESEND',
  BLANK_MESSAGE = 'BLANK_MESSAGE',
  OPT_IN = 'OPT_IN',
}

export type MessageConfigurationSettingsType = Record<MessageConfigurationType, MessageConfiguration>

export const messageConfigurationSettings: MessageConfigurationSettingsType = {
  [MessageConfigurationType.DRAFT]: {
    messageType: 'DRAFT',
    settings: {
      hideEte: false,
      hideTransactional: false,
      hidePreviewText: false,
      hideMessageTitle: false,
      hideSender: false,
      hideSubscriptionCategory: false,
      hideCampaign: false,
    },
    sendDetails: {
      showAttachNote: true,
      hideSendDetails: false,
    },
    reviewAndSend: {
      hideSendNow: false,
      displayAsTab: false,
    },
    preview: {
      hideToRecipients: false,
      hidePreviewPersonalization: false,
    },
    saveButton: {
      saveAndClose: true,
      copyToDraft: false,
      saveAsCopy: true,
      saveAsTemplate: true,
      separateSaveAndClose: false,
    },
  },
  [MessageConfigurationType.TEMPLATE]: {
    messageType: 'TEMPLATE',
    settings: {
      hideCampaign: true,
      hideSubscriptionCategory: false,
      hideTransactional: true,
      hideEte: false,
    },
    sendDetails: {
      hideSendDetails: true,
    },
    preview: {
      hideToRecipients: true,
      hidePreviewPersonalization: true,
    },
    reviewAndSend: {
      hideSendNow: true,
      displayAsTab: false,
    },
    saveButton: {
      saveAndClose: true,
      saveAsCopy: true,
      saveAsTemplate: false,
      copyToDraft: true,
      separateSaveAndClose: false,
    },
  },
  [MessageConfigurationType.FORM_AUTO_RESPONSE]: {
    messageType: 'FORM_AUTO_RESPONSE',
    settings: {
      hideTransactional: true,
      hideEte: false,
    },
    sendDetails: {
      hideSendDetails: true,
    },
    preview: {
      hidePreviewPersonalization: true,
      hideToRecipients: true,
    },
    reviewAndSend: {
      displayAsTab: true,
      reviewAndSendLabel: 'Review',
      hideRecipientsValidation: true,
      hideSendMethodValidation: true,
      hideSendNow: true,
      hideCheckFullPersonalization: true,
      keepTabs: true,
    },
    saveButton: {
      saveAndClose: false,
      saveAsCopy: false,
      saveAsTemplate: false,
      copyToDraft: false,
      separateSaveAndClose: true,
    },
  },
  [MessageConfigurationType.RSS2EMAIL]: {
    messageType: 'RSS_MESSAGE',
    settings: {
      hideEte: true,
    },
    content: {
      showRSSBlock: true,
      rssBlockRequired: true,
    },
    sendDetails: {
      hideSendMethods: true,
      hideSendDetails: false,
    },
    reviewAndSend: {
      displayAsTab: true,
      reviewAndSendLabel: 'Review',
      hideSendMethodValidation: true,
      hideSendNow: true,
      rssBlockRequired: true,
      keepTabs: true,
    },
    preview: {},
    saveButton: {
      saveAndClose: false,
      saveAsCopy: false,
      saveAsTemplate: false,
      copyToDraft: false,
      separateDiscardAndPublish: true,
      separateSaveAndClose: isCustomerCareLogin(),
      autoSaveWithSeparateSaveAndClose: !isCustomerCareLogin(),
    },
  },
  [MessageConfigurationType.AB_TEST_MESSAGE]: {
    messageType: 'AB_TEST_MESSAGE',
    settings: {
      hideEte: false,
    },
    sendDetails: {
      hideSendDetails: true,
    },
    preview: {
      hidePreviewPersonalization: true,
      hideToRecipients: true,
    },
    reviewAndSend: {
      displayAsTab: true,
      reviewAndSendLabel: 'Review',
      hideRecipientsValidation: true,
      hideSendMethodValidation: true,
      hideCheckFullPersonalization: true,
      hideSendNow: true,
      keepTabs: true,
    },
    saveButton: {
      saveAndClose: false,
      saveAsCopy: false,
      saveAsTemplate: false,
      copyToDraft: false,
      separateDiscardAndPublish: true,
      separateSaveAndClose: isCustomerCareLogin(),
      autoSaveWithSeparateSaveAndClose: !isCustomerCareLogin(),
    },
  },
  [MessageConfigurationType.PROGRAM_MESSAGE]: {
    messageType: 'PROGRAM_MESSAGE',
    settings: {
      hideEte: false,
    },
    sendDetails: {
      hideSendDetails: true,
    },
    preview: {
      hidePreviewPersonalization: true,
      hideToRecipients: true,
    },
    reviewAndSend: {
      displayAsTab: true,
      reviewAndSendLabel: 'Review',
      hideRecipientsValidation: true,
      hideSendMethodValidation: true,
      hideSendNow: true,
      hideCheckFullPersonalization: true,
      keepTabs: true,
    },
    saveButton: {
      saveAndClose: true,
      saveAsCopy: false,
      saveAsTemplate: false,
      copyToDraft: false,
      separateSaveAndClose: true,
      autoSaveWithSeparateSaveAndClose: false,
    },
  },
  [MessageConfigurationType.CRM]: {
    messageType: 'CRM_MESSAGE',
    settings: {
      hideEte: true,
      hideTransactional: true,
      hideMessageTitle: false,
      hideSender: false,
      senderReadOnly: true,
      hideSubscriptionCategory: true,
    },
    sendDetails: {
      hideSendDetails: false,
      showAttachNote: true,
      disableSelectLists: true,
      hideSendMethods: false,
      hideSendMethodTimezone: true,
      hideSendMethodManuallyTimezone: true,
      hideSendMethodStaggered: true,
      hideSuppression: true,
      enableAttachSentNoteToggle: true,
    },
    reviewAndSend: {
      displayAsTab: false,
      reviewAndSendLabel: 'Review and send',
      disableEditSenderBtn: true,
      disableEditRecipientsBtn: true,
      disableSelectContacts: true,
      disableDeleteRecipient: true,
      hideCheckEmails: true,
      hideFatigueRules: true,
      hasCrmNoteRules: true,
      hideAccountLimitWarning: true,
      hideSuppressDups: true,
    },
    preview: {},
    saveButton: {
      saveAndClose: false,
      saveAsCopy: false,
      saveAsTemplate: false,
      copyToDraft: false,
      autoSaveWithSeparateSaveAndClose: false,
      separateSaveAndClose: false,
    },
    showOnlyDiscardButton: true,
  },
  [MessageConfigurationType.RESEND]: {
    messageType: 'RESEND_MESSAGE',
    settings: {
      hideEte: true,
      hideTransactional: false,
      hideMessageTitle: false,
      hideSender: false,
      hideSubscriptionCategory: false,
      disableAnalyticsTracking: true,
      disableInternalTracking: true,
      disableTransactionalEmail: true,
      disableSender: true,
      disableReplyTo: true,
      disableInternalTitle: true,
      disableEditings: true,
    },
    sendDetails: {
      hideSendDetails: false,
      showAttachNote: true,
      hideSendMethods: false,
      hideSendMethodTimezone: true,
      hideSendMethodManuallyTimezone: true,
      hideSendMethodStaggered: true,
      hideSuppression: false,
    },
    reviewAndSend: {
      displayAsTab: false,
      reviewAndSendLabel: 'Review and send',
      disableEditSenderBtn: false,
      disableEditRecipientsBtn: false,
      disableSelectContacts: false,
      disableDeleteRecipient: false,
      hideCheckEmails: true,
      hideFatigueRules: true,
      hasCrmNoteRules: true,
      hideAccountLimitWarning: true,
      hideSuppressDups: true,
      disableEditings: true,
    },
    preview: {
      disableTestEmail: true,
      disableEditPlainText: true,
    },
    saveButton: {
      saveAndClose: false,
      saveAsCopy: false,
      saveAsTemplate: false,
      copyToDraft: false,
      autoSaveWithSeparateSaveAndClose: false,
      separateSaveAndClose: false,
    },
    showOnlyDiscardButton: true,
    design: {
      lockDesignTab: true,
    },
  },
  [MessageConfigurationType.WEBINAR_SINGLE]: {
    messageType: 'WEBINAR_MESSAGE',
    settings: {
      hideEte: true,
      hideTransactional: false,
      hideSubscriptionCategory: false,
    },
    sendDetails: {
      hideSendDetails: false,
      hideSendMethodStaggered: true,
      hideSendMethodTimezone: true,
      hideSendMethodManuallyTimezone: true,
    },
    preview: {
      hidePreviewPersonalization: false,
      hideToRecipients: true,
    },
    reviewAndSend: {
      displayAsTab: false,
      reviewAndSendLabel: 'Review and send',
      hideRecipientsValidation: false,
      hideSendMethodValidation: false,
      hideSendNow: false,
    },
    saveButton: {
      saveAndClose: true,
      saveAsCopy: false,
      saveAsTemplate: false,
      copyToDraft: false,
      separateSaveAndClose: isCustomerCareLogin(),
      autoSaveWithSeparateSaveAndClose: !isCustomerCareLogin(),
      pureSave: true,
    },
  },
  [MessageConfigurationType.WEBEX_MULTI]: {
    messageType: 'WEBINAR_MESSAGE',
    settings: {
      hideEte: true,
    },
    sendDetails: {
      hideSendDetails: true,
    },
    preview: {
      hidePreviewPersonalization: true,
      hideToRecipients: true,
    },
    reviewAndSend: {
      displayAsTab: true,
      reviewAndSendLabel: 'Review',
      hideRecipientsValidation: true,
      hideCheckFullPersonalization: true,
      hideSendMethodValidation: true,
      hideSendNow: true,
      keepTabs: true,
      hideTransactionalEmailValidation: false,
    },
    saveButton: {
      saveAndClose: false,
      saveAsCopy: false,
      saveAsTemplate: false,
      copyToDraft: false,
      separateSaveAndClose: true,
    },
  },
  [MessageConfigurationType.GOTOWEBINAR_MULTI]: {
    messageType: 'WEBINAR_MESSAGE',
    settings: {
      hideTransactional: true,
      hideSubscriptionCategory: true,
    },
    sendDetails: {
      hideSendDetails: true,
      hideSendMethodStaggered: true,
      hideSendMethodTimezone: true,
      hideSendMethodManuallyTimezone: true,
    },
    preview: {
      hidePreviewPersonalization: true,
      hideToRecipients: true,
    },
    reviewAndSend: {
      displayAsTab: true,
      reviewAndSendLabel: 'Review',
      hideRecipientsValidation: true,
      hideSendMethodValidation: true,
      hideCheckFullPersonalization: true,
      hideTransactionalEmailValidation: true,
      hideSendNow: true,
      keepTabs: true,
    },
    saveButton: {
      saveAndClose: false,
      saveAsCopy: false,
      saveAsTemplate: false,
      copyToDraft: false,
      separateSaveAndClose: true,
      separateDiscardAndPublish: false,
    },
  },
  [MessageConfigurationType.BLANK_MESSAGE]: {
    messageType: 'BLANK_MESSAGE',
    settings: {
      hideTransactional: true,
      hideMessageTitle: true,
      hideCampaign: true,
      hideEte: true,
      disableTransactionalEmail: true,
    },
    sendDetails: {
      hideSendDetails: true,
      hideSendMethodStaggered: true,
      hideSendMethodTimezone: true,
      hideSendMethodManuallyTimezone: true,
    },
    preview: {
      hidePreviewPersonalization: true,
      hideToRecipients: true,
    },
    reviewAndSend: {
      hidePreview: true,
      hideRecipientsValidation: true,
      hideSendMethodValidation: true,
      hideCheckFullPersonalization: true,
      hideTransactionalEmailValidation: true,
      hideSendNow: true,
      keepTabs: true,
    },
    saveButton: {
      pureSave: false,
      saveAndClose: false,
      saveAsCopy: false,
      saveAsTemplate: false,
      copyToDraft: false,
      separateSaveAndClose: false,
      separateDiscardAndPublish: true,
    },
    hideInternalEmailTitle: true,
    canPublish: true,
  },
  [MessageConfigurationType.OPT_IN]: {
    messageType: 'OPT_IN_MESSAGE',
    hideAutoSave: true,
    showOnlyDiscardButton: true,
    settings: {
      hideEte: true,
      hideInternalTracking: true,
    },
    sendDetails: {
      hideSuppression: true,
    },
    reviewAndSend: { hideSuppressDups: true, hideFatigueRules: true, hasCrmNoteRules: false, hideAccountLimitWarning: true },
    preview: {
      hideToRecipients: false,
      hidePreviewPersonalization: false,
    },
    saveButton: {
      saveAndClose: false,
      copyToDraft: false,
      saveAsCopy: false,
      saveAsTemplate: false,
      separateSaveAndClose: false,
    },
  },
}

export interface MessageConfigurationSettings {
  hideEte?: boolean
  hideInternalTracking?: boolean
  hideTransactional?: boolean
  isTransactionalEnabled?: boolean
  hideMessageTitle?: boolean
  hidePreviewText?: boolean
  hideSender?: boolean
  senderReadOnly?: boolean
  hideSubscriptionCategory?: boolean
  hideCampaign?: boolean
  disableIndividualRecipientsPersonalization?: boolean
  disableAnalyticsTracking?: boolean
  disableInternalTracking?: boolean
  disableTransactionalEmail?: boolean
  disableSender?: boolean
  disableReplyTo?: boolean
  disableInternalTitle?: boolean
  disableEditings?: boolean
}

export interface MessageConfigurationContent {
  showRSSBlock?: boolean
  rssBlockRequired?: boolean
  webinarBlockRequired?: boolean
  eventBlockRequired?: boolean
}

export interface MessageConfigurationDesign {
  lockDesignTab?: boolean
}

export interface MessageConfigurationSendDetails {
  showAttachNote?: boolean
  hideSendDetails?: boolean
  hideRecipients?: boolean
  hideSuppression?: boolean
  hideSendMethods?: boolean
  disableSelectLists?: boolean
  hideSendMethodTimezone?: boolean
  hideSendMethodManuallyTimezone?: boolean
  hideSendMethodStaggered?: boolean
  enableAttachSentNoteToggle?: boolean
}

export interface MessageConfigurationPreview {
  hidePreviewPersonalization?: boolean
  hideToRecipients?: boolean
  disableTestEmail?: boolean
  disableEditPlainText?: boolean
}

export interface MessageConfigurationReviewAndSend {
  displayAsTab?: boolean
  reviewAndSendLabel?: string
  hideRecipientsValidation?: boolean
  hideSendMethodValidation?: boolean
  hideSendNow?: boolean
  rssBlockRequired?: boolean
  webinarBlockRequired?: boolean
  hideCheckFullPersonalization?: boolean
  hideAccountLimitWarning?: boolean
  hideTransactionalEmailValidation?: boolean
  keepTabs?: boolean
  disableEditSenderBtn?: boolean
  disableEditRecipientsBtn?: boolean
  disableSelectContacts?: boolean
  disableDeleteRecipient?: boolean
  hideCheckEmails?: boolean
  hideFatigueRules?: boolean
  hideSuppressDups?: boolean
  hasCrmNoteRules?: boolean
  hideCheckPersonalizations?: boolean
  hideDeliverability?: boolean
  disableEditings?: boolean
  hidePreview?: boolean
}

interface MessageConfigurationSaveButton {
  saveAndClose: boolean
  copyToDraft: boolean
  saveAsCopy: boolean
  saveAsTemplate: boolean
  separateSaveAndClose: boolean
  separateDiscardAndPublish?: boolean
  autoSaveWithSeparateSaveAndClose?: boolean
  pureSave?: boolean
}

export interface MessageConfiguration {
  canPublish?: boolean
  hideAutoSave?: boolean
  showOnlyDiscardButton?: boolean
  hideInternalEmailTitle?: boolean
  messageType: ExtendedMessageType
  settings: MessageConfigurationSettings
  content?: MessageConfigurationContent
  design?: MessageConfigurationDesign
  sendDetails: MessageConfigurationSendDetails
  preview: MessageConfigurationPreview
  reviewAndSend: MessageConfigurationReviewAndSend
  saveButton?: MessageConfigurationSaveButton
}

export type SettingsForm = Pick<
  EmailComposerState['message'],
  | 'title'
  | 'subject'
  | 'previewText'
  | 'sender'
  | 'senderUUID'
  | 'fallbackSender'
  | 'replyToSender'
  | 'analyticsFields'
  | 'campaignId'
  | 'subscriptionCategoryId'
  | 'isTransactional'
  | 'isTriggered'
>

export interface DisplayConditionSet {
  /** Group of field conditions */
  condition?: {
    description: string
    html: string
    text: string
    useHtmlText: boolean
    /** Match all or some subconditions */
    matchType: 'any' | 'all'
    /** Field conditions */
    predicate: DisplayConditionSetPredicate[]
  }[]
}

export interface CreateMessageProps {
  messageType: ExtendedMessageType
  fromDefault?: boolean
  isPlainTextOnly?: boolean
  startId?: string
  beeBlankSetup?: boolean
  isCustomHtml?: boolean
  assetReportRecipientDetails?: AssetReportRecipientDetails
}
