import { gql } from '@apollo/client'

export default gql`
  mutation uploadLandingPageCode($fileBase64: String, $fileName: String) {
    uploadLandingPageCode(fileBase64: $fileBase64, fileName: $fileName) {
      id
      status
      msg
    }
  }
`
