import { gql } from '@apollo/client'

export default gql`
  query getWebhookDefinitionList {
    getWebhookDefinitionList {
      webhookId
      name
      description
      enabled
      tested
      url
      source
      createdTimestamp
      updatedTimestamp
      lastRequestTimestamp
      authentication {
        type
        username
        passwordHash
        tokenHash
        signature {
          signatureAlgorithm
          hashAlgorithm
          headerName
          headerFormat
          payloadFormat
          keyEncoding
          key
          signatureEncoding
          timestampCheck
          timestampHeaderName
          timestampHeaderFormat
          timestampLeeway
          timestampFormat
        }
      }
      channel
      channelModifier
      channelModifierParams
      contentMappings {
        sourceField
        destinationField
        mappingFunction
        mappingFunctionParams
        mappingMetadata
      }
    }
  }
`
