import React, { FC, useCallback, useState } from 'react'

import DropDown from '@components/DropDown'
import { DropDownType } from '@components/DropDown/DropDown'
import DropDownActions from '@components/DropDownActions/DropDownActions'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { useTranslation } from '@const/globals'
import { CampaignTableRowData } from '@src/pages/reports/revenueImpactNew/components/campaignTable/CampaignTable.types'
import { downloadCSV, downloadExcel } from '@src/pages/reports/revenueImpactNew/components/campaignTable/components/utils'

interface DownloadButtonProps {
  data: CampaignTableRowData[]
  withTotal: boolean
}

const rootClass = 'campaign-table-button-download'

const DownloadButton: FC<DownloadButtonProps> = ({ data, withTotal }) => {
  const { t } = useTranslation()
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)

  const handleClose = useCallback(() => setDropdownOpen(false), [])
  const handleDownloadCsv = useCallback(() => downloadCSV(data, withTotal), [data, withTotal])
  const handleDownloadExcel = useCallback(() => downloadExcel(data, withTotal), [data, withTotal])

  const dropdownActions = [
    { key: 'csv', icon: SvgNames.csvFile, text: t('Download CSV'), onClick: handleDownloadCsv },
    { key: 'excel', icon: SvgNames.excelFile, text: t('Download Excel'), onClick: handleDownloadExcel },
  ]

  return (
    <DropDown
      isOpen={dropdownOpen}
      toggleOpen={setDropdownOpen}
      trigger={<Svg name={SvgNames.download} type={SvgType.LARGER_ICON} />}
      hasOverflowIcon
      align="end"
      type={DropDownType.STYLED}
      className={rootClass}
      dataTest={rootClass}
    >
      <DropDownActions menuItems={dropdownActions} closeDropDown={handleClose} />
    </DropDown>
  )
}

export default DownloadButton
