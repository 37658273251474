import React, { FC } from 'react'

import classNames from 'classnames'

import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { getCountDisplay, getFormattedNumber } from '@utils/numbers'

import './NumberBadge.css'

export enum NumberBadgeType {
  DEFAULT = 'DEFAULT',
  IMPORTANT = 'IMPORTANT',
  PRIMARY = 'PRIMARY',
  PRIMARY_INVERTED = 'PRIMARY_INVERTED',
  ADDED = 'ADDED',
  REMOVED = 'REMOVED',
}

export interface NumberBadgeProps {
  value: number
  maxValue?: number | false
  type?: NumberBadgeType
  roundValue?: boolean
  withoutXMargin?: boolean
  className?: string
  dataTest?: string
}

export const NUMBER_BADGE_DEFAULT_MAX_VALUE = 100
const rootClass = 'number-badge'

const NumberBadge: FC<NumberBadgeProps> = (props: NumberBadgeProps) => {
  const {
    value,
    maxValue = NUMBER_BADGE_DEFAULT_MAX_VALUE,
    type = NumberBadgeType.DEFAULT,
    roundValue = true,
    withoutXMargin,
    dataTest = rootClass,
    className,
  } = props
  // Negative numbers automatically have their type changed to REMOVED.
  const _type = value < 0 ? NumberBadgeType.REMOVED : type

  // If the max prop is set to false then no + will be appended, and the value will be displayed as it was passed.
  const _maxValue: number = maxValue === false ? Infinity : maxValue > 0 ? maxValue : NUMBER_BADGE_DEFAULT_MAX_VALUE

  const prefix = _type === NumberBadgeType.ADDED ? '+' : _type === NumberBadgeType.REMOVED ? '-' : ''
  const isAddedOrRemoved = !!prefix
  const suffix = !isAddedOrRemoved && value > _maxValue ? '+' : ''

  // For ADDED and REMOVED badges, the max value should not have any effect
  const _value = Math.abs(isAddedOrRemoved ? value : Math.min(value, _maxValue))
  // 'roundValue' prop does not apply for badges with the max value prop enabled.
  const displayValue = (maxValue === false || isAddedOrRemoved) && roundValue ? getCountDisplay(_value) : getFormattedNumber(_value)

  return (
    <Typography
      text={`${prefix}${displayValue}${suffix}`}
      type={TextType.NORMAL_TEXT_GRAY}
      weight={TextWeight.MEDIUM}
      className={classNames(rootClass, className, {
        [`${rootClass}__default`]: _type === NumberBadgeType.DEFAULT,
        [`${rootClass}__important`]: _type === NumberBadgeType.IMPORTANT,
        [`${rootClass}__primary`]: _type === NumberBadgeType.PRIMARY,
        [`${rootClass}__primary-inverted`]: _type === NumberBadgeType.PRIMARY_INVERTED,
        [`${rootClass}__added`]: _type === NumberBadgeType.ADDED,
        [`${rootClass}__removed`]: _type === NumberBadgeType.REMOVED,
        [`${rootClass}__x-margin`]: !withoutXMargin,
      })}
      dataTest={dataTest}
    />
  )
}

export default NumberBadge
