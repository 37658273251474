import { UserPermissionsInput } from '@graphql/types/mutation-types'

export type PermissionsGroupName = keyof UserPermissionsInput

export enum PermissionOption {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  VIEW = 'VIEW',
  DOWNLOAD = 'DOWNLOAD',
  MANAGE = 'MANAGE',
}

export type PermissionOptionsType = Partial<{ [key in PermissionOption]: { value: boolean; order: number } }>

const PermissionOptionOrderMapping = new Map<PermissionOption, number>([
  [PermissionOption.EDIT, 0],
  [PermissionOption.VIEW, 1],
  [PermissionOption.MANAGE, 2],
  [PermissionOption.DELETE, 3],
  [PermissionOption.DOWNLOAD, 4],
])

export const getPermissionOptionOrder = (option: PermissionOption): number =>
  PermissionOptionOrderMapping.get(option) ?? PermissionOptionOrderMapping.size

export interface PermissionsGroupItem {
  name: PermissionsGroupName
  options: PermissionOptionsType
  headerKey: string
  titleKey: string
  tooltipKey: string
}

export const PermissionItemPropsMapping = new Map<
  PermissionsGroupName,
  {
    tooltipKey: string
    headerKey: string
    titleKey: string
  }
>([
  ['contactLists', { tooltipKey: 'Permission.Options.Tooltip.List', headerKey: 'Contact Permissions', titleKey: 'Contact Lists' }],
  ['allContent', { tooltipKey: 'Permission.Options.Tooltip.Content', headerKey: 'Content Permissions', titleKey: 'All Content' }],
  ['accountWideEmails', { tooltipKey: 'Permission.Options.Tooltip.Email', headerKey: 'Email Permissions', titleKey: 'Account-wide Emails' }],
  ['automatedPrograms', { tooltipKey: 'Permission.Options.Tooltip.Program', headerKey: 'Program Permissions', titleKey: 'Automated Programs' }],
])
