export const runForethought = (config: { FORETHOUGHT_API_KEY?: string; userId?: string; userName?: string; userEmail?: string }) => {
  const currentURL = document.URL
  const head = document.head || document.getElementsByTagName('head')[0],
    forethoughtscript = document.createElement('script')
  forethoughtscript.setAttribute('offset-x', '28px')
  forethoughtscript.setAttribute('offset-y', '64px')
  forethoughtscript.setAttribute('position-y', 'top')
  forethoughtscript.setAttribute('type', 'application/javascript')
  forethoughtscript.setAttribute('src', 'https://solve-widget.forethought.ai/embed.js')
  forethoughtscript.setAttribute('id', 'forethought-widget-embed-script')
  forethoughtscript.setAttribute('data-ft-Embed-Script-Language', 'EN')
  forethoughtscript.setAttribute('data-api-key', `${config.FORETHOUGHT_API_KEY}`)
  forethoughtscript.setAttribute('data-ft-ZD-ID', `${config.userId || '0'}`)
  forethoughtscript.setAttribute('data-ft-User-Id', `${config.userId || '0'}`)
  forethoughtscript.setAttribute('data-ft-User', `${config.userName || 'there'}`)
  forethoughtscript.setAttribute('data-ft-User-Email', `${config.userEmail || 'unknown@act-on.com'}`)
  forethoughtscript.setAttribute('data-ft-Org', 'unknown')
  forethoughtscript.setAttribute('data-ft-Support-Level', 'unknown')
  forethoughtscript.setAttribute('data-ft-embed-url', currentURL)
  head.appendChild(forethoughtscript)
}
