import React, { FC } from 'react'

import classNames from 'classnames'

import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './CustomizePreview.css'

interface CustomizePreviewProps {
  pageTitle: string
  logo: string
  dataTest?: string
}

const rootClass = 'customize-preview'

const CustomizePreview: FC<CustomizePreviewProps> = (props: CustomizePreviewProps) => {
  const { pageTitle, logo, dataTest = rootClass } = props
  const { t } = useTranslation()

  const renderTabBar = () => (
    <div className={`${rootClass}__tab-bar`}>
      <div className={`${rootClass}__dots`}>
        <span /> <span /> <span />
      </div>
      <div className={`${rootClass}__tab`} data-test={`${dataTest}-tab`}>
        <Typography text={`${pageTitle} :: ${t('Start')}`} inline lineHeight={LineHeight.MEDIUM_LARGE} className={'ellip'} />
      </div>
    </div>
  )

  const renderContent = () => (
    <div className={`${rootClass}__content`}>
      <div className={`${rootClass}__nav`}>
        <img className={`${rootClass}__img`} src={logo} alt={'logo'} />
        <div className={`${rootClass}__nav-section`}>
          <span className={`${rootClass}__circle`} />
          <span className={`${rootClass}__rectangle`} />
        </div>
      </div>
      <div className={`${rootClass}__main`}>
        <div className={`${rootClass}__main-topbar`}>
          <div className={`${rootClass}__main-topbar-item`}>
            <span className={`${rootClass}__circle`} />
            <span className={classNames(`${rootClass}__rectangle`, `${rootClass}__rectangle-long`)} />
          </div>
          <div className={classNames(`${rootClass}__main-topbar-item`, `${rootClass}__main-topbar-item-right`)}>
            <span className={classNames(`${rootClass}__rectangle`, `${rootClass}__rectangle-medium`)} />
            <span className={classNames(`${rootClass}__circle`, `${rootClass}__circle-small`)} />
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div className={rootClass}>
      <Typography
        text={t('App Preview')}
        weight={TextWeight.BOLD}
        type={TextType.BODY_TEXT_LIGHT}
        lineHeight={LineHeight.MEDIUM_SMALL}
        className={`${rootClass}__preview-text`}
      />
      <div className={`${rootClass}__preview-content`}>
        {renderTabBar()}
        {renderContent()}
      </div>
    </div>
  )
}

export default CustomizePreview
