import { t } from 'i18next'

import { ImagePickerSource } from '@components/AssetPickers/ImagePickerModal/utils/ImagePickerModal.utils'
import { SplitButtonOptionsProps } from '@components/SplitButton/SplitButton'
import { SvgNames } from '@components/Svg'

export enum InsertImageFlow {
  BROWSE = 'browse',
  URL = 'url',
  UPLOAD = 'upload',
}

interface AddImageFlowActions extends SplitButtonOptionsProps {
  flow: InsertImageFlow
}

export const getAddImageFlowActions = (hideUploadFromURL?: boolean): AddImageFlowActions[] => {
  const actions = [
    {
      key: 'upload',
      icon: SvgNames.upload,
      title: t('Upload image'),
      flow: InsertImageFlow.UPLOAD,
    },
    ...(hideUploadFromURL
      ? []
      : [
          {
            key: 'link',
            icon: SvgNames.url,
            title: t('Add image from URL'),
            flow: InsertImageFlow.URL,
          },
        ]),
  ]
  return actions
}

enum MetadataFields {
  SOURCE_METADATA = 'actOnSrc',
  ID_METADATA = 'id',
  TS_METADATA = 'ts',
  FIELD_METADATA = 'dynamicField',
  LIST_ID = 'listId',
  FALLBACK_TITLE = 'fbTitle',
}

export const getSourceMetadataFromImageURl = (
  url?: string
): {
  url?: string
  source?: ImagePickerSource
  id?: string
  dynamicField?: string | null
  listId?: string | null
  fallbackTitle?: string | null
} => {
  if (!url) {
    return {}
  }
  try {
    const urlObj = new URL(url)
    const { searchParams, pathname, origin } = urlObj
    const _source = searchParams.get(MetadataFields.SOURCE_METADATA)
    const dynamicField = searchParams.get(MetadataFields.FIELD_METADATA)
    const listId = searchParams.get(MetadataFields.LIST_ID)
    const fallbackTitle = searchParams.get(MetadataFields.FALLBACK_TITLE)
    let source = undefined

    if (Object.values(ImagePickerSource).includes(_source as ImagePickerSource)) {
      source = _source as ImagePickerSource
    }

    return {
      url: `${origin}${pathname}`,
      source,
      id: searchParams.get(MetadataFields.ID_METADATA) ?? undefined,
      dynamicField,
      listId,
      fallbackTitle,
    }
  } catch (e) {
    return {}
  }
}

export const imageUrlToBase64 = async (url: string) => {
  const data = await fetch(url)
  const blob = await data.blob()
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      const base64data = reader.result
      resolve(base64data)
    }
    reader.onerror = reject
  })
}
