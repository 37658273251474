import SvgNames from '@components/Svg/SvgNames'
import { NavigationInterface } from '@utils/navigation/navigation.utils'

const navigation: NavigationInterface[] = [
  {
    id: 'home',
    icon: SvgNames.home,
    iconSelected: SvgNames.homeSelected,
    label: 'Home',
    items: [
      {
        id: 'home-lists',
        label: 'Lists',
        url: 'classic/if/cust/listListing.jsp?tab=1',
      },
      {
        id: 'home-downloads',
        label: 'Downloads',
        url: 'classic/if/cust/downloadsListing.jsp?tab=2',
      },
    ],
  },
]

export default navigation
