export const titleCase = (str: string) => str.substr(0, 1).toUpperCase() + str.substr(1).toLocaleLowerCase()

export const escapeRegExp = (str: string) => str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')

export const replaceAll = (str: string, find: string, replace: string) => str.replace(new RegExp(find, 'g'), replace)

export const ensureFirstLetterIsCapitalized = function (inputString?: string) {
  if (inputString) {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1)
  }
}

export const getKeyName = (value: string, objEnum: object) => {
  return Object.entries(objEnum).find((entry) => entry[1] === value)?.[0]
}

export const getValueByKey = (key: string, objEnum: object) => {
  return Object.entries(objEnum).find((entry) => entry[0] === key)?.[1]
}

export const ellip = (text: string, maxlen: number) => {
  return text.length > maxlen ? text.substring(0, maxlen) + '...' : text
}

export const ipToInt = function (x: string) {
  let res, y, _i, _len
  res = 0
  const _ref = x.split('.')
  for (_i = 0, _len = _ref.length; _i < _len; _i++) {
    y = _ref[_i]
    res = res * 256 + Number(y)
  }
  return res
}

export const toCamelCase = (text?: string, separator = '-') => {
  const pattern = new RegExp(`${separator}([a-z])`, 'g')
  return text?.replace(pattern, (_, letter) => letter.toUpperCase()) ?? ''
}

export const snakeCaseToTitleCase = (text: string) => {
  const words = text.split('_')
  return words.map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word.toLowerCase())).join(' ')
}

export const toTrainCase = (text: string | undefined) => {
  return (
    text
      ?.trim()
      .replace(/(.+)([A-Z])/g, '$1 $2')
      .replace(/ +/g, '-')
      .replace(/^-/, '')
      .replace(/\./g, '-')
      .toLowerCase() ?? ''
  )
}

export const wordwrap = (text: string, maxlen: number) => {
  const words = text.split(' ')
  return words.reduce((prev, cur) => {
    const lineLength = (prev.length % maxlen) + cur.length + 1
    const curWordSep = lineLength > maxlen ? '\n' : ' '
    const nextWordSep = lineLength === maxlen ? '\n' : ''
    return `${prev}${curWordSep}${cur}${nextWordSep}`
  })
}

export const isStringArray = (value: unknown): value is string[] => Array.isArray(value) && value.every((item) => typeof item === 'string')

export const converterHtmlToBytes = (html: string): number => {
  const encoder = new TextEncoder()
  const htmlBytes = encoder.encode(html)
  return htmlBytes.length
}

export const replaceSpaces = (value: string) => {
  return value.trimStart().replace(/(\s{2,})(?=\S)/g, ' ')
}
