import { useApolloClient } from '@apollo/client'
import getFormsToMigrate from '@graphql/microservices/form/getFormsToMigrate'
import migrateForms from '@graphql/microservices/form/migrateForms'
import todaySegments from '@graphql/microservices/ml-audience/todaySegments'
import accountSettings from '@graphql/queries/accountSettings'
import getAllContactsUpgradeCompleted from '@graphql/queries/getAllContactsUpgradeCompleted'
import {
  GetFormsToMigrateQuery,
  GetFormsToMigrateQueryVariables,
  MigrateFormsMutation,
  MigrateFormsMutationVariables,
} from '@graphql/types/microservice/form-types'
import { Segment as SegmentInsight, TodaySegmentsQuery, TodaySegmentsQueryVariables } from '@graphql/types/microservice/ml-audience-types'
import {
  AccountSettingsQuery,
  AccountSettingsQueryVariables,
  GetAllContactsUpgradeCompletedQuery,
  GetAllContactsUpgradeCompletedQueryVariables,
} from '@graphql/types/query-types'
import { useAccountSettings } from '@utils/account/account.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export interface ContactSegmentsRequests {
  getFormsToMigrateRequest: () => FetchPromise<GetFormsToMigrateQuery>
  migrateFormsRequest: (formIds: string[]) => FetchPromise<MigrateFormsMutation>
  getAccountSettingsRequest: () => FetchPromise<AccountSettingsQuery>
  getNewInsights: () => Promise<SegmentInsight[]>
  getAllContactsUpgrade: () => FetchPromise<GetAllContactsUpgradeCompletedQuery>
}

export const useContactSegmentsRequest = (): ContactSegmentsRequests => {
  const { client: formClient } = useMicroserviceClient({ serviceName: MicroserviceClients.FORM })
  const { client: mlAudienceClient } = useMicroserviceClient({ serviceName: MicroserviceClients.ML_AUDIENCE })
  const actonClient = useApolloClient()

  const { accountId } = useAccountSettings()

  const getNewInsights = () =>
    mlAudienceClient
      .query<TodaySegmentsQuery, TodaySegmentsQueryVariables>({
        query: todaySegments,
        fetchPolicy: 'network-only',
        variables: {
          accountId,
          additionalDaysBack: 7,
        },
      })
      .then(({ data }) => {
        return (
          data.todaySegments?.reduce((segmentsInsights: SegmentInsight[], segment) => {
            return segment ? [...segmentsInsights, segment] : segmentsInsights
          }, []) ?? []
        )
      })

  const getFormsToMigrateRequest = () => {
    return formClient.query<GetFormsToMigrateQuery, GetFormsToMigrateQueryVariables>({
      query: getFormsToMigrate,
      fetchPolicy: 'network-only',
    })
  }

  const migrateFormsRequest = (formIds: string[]) => {
    return formClient.mutate<MigrateFormsMutation, MigrateFormsMutationVariables>({
      mutation: migrateForms,
      variables: {
        formIds,
      },
    })
  }
  const getAccountSettingsRequest = () => {
    return actonClient.query<AccountSettingsQuery, AccountSettingsQueryVariables>({
      query: accountSettings,
      fetchPolicy: 'network-only',
    })
  }

  const getAllContactsUpgrade = () => {
    return actonClient.query<GetAllContactsUpgradeCompletedQuery, GetAllContactsUpgradeCompletedQueryVariables>({
      query: getAllContactsUpgradeCompleted,
      fetchPolicy: 'network-only',
    })
  }

  return {
    getFormsToMigrateRequest,
    migrateFormsRequest,
    getAccountSettingsRequest,
    getNewInsights,
    getAllContactsUpgrade,
  }
}
