import { GetAllContactsUpgradeCompletedQuery } from '@graphql/types/query-types'
import { UpgradeItemType } from '@src/pages/UpgradeAssistant/UpgradeAssistant.contants'
import { logNewRelicError } from '@utils/new-relic.utils'
import { FetchPromise } from '@utils/types'

export const getAllContactsUpgradeStatus = (
  getAllContactsUpgrade: () => FetchPromise<GetAllContactsUpgradeCompletedQuery>,
  itemType: UpgradeItemType,
  isCompleted: (isCompleted: boolean) => void
) => {
  getAllContactsUpgrade()
    .then((response) => {
      const { data } = response
      const completedItems = (data?.getAllContactsUpgradeCompleted.filter((item) => !!item) as string[]) ?? []
      isCompleted(completedItems.includes(itemType))
    })
    .catch((e) => {
      logNewRelicError(e)
    })
}
