import React, { FC } from 'react'

import ActionableNestedTable, { ActionableNestedTableProps } from '@components/ActionableNestedTable/ActionableNestedTable'
import ActionableNestedTableWithLazyLoading, {
  ActionableNestedTableWithLazyLoadingProps,
} from '@components/ActionableNestedTableWithLazyLoading/ActionableNestedTableWithLazyLoading'
import EmptyListing, { EmptyListingProps } from '@components/EmptyListing/EmptyListing'

export type ActionableNestedTableWithEmptyListingProps = ActionableNestedTableProps &
  Partial<ActionableNestedTableWithLazyLoadingProps> &
  EmptyListingProps

/**
 * @deprecated use <TableV2 instead
 */
const ActionableNestedTableWithEmptyListing: FC<ActionableNestedTableWithEmptyListingProps> = (props: ActionableNestedTableWithEmptyListingProps) => {
  const { className, data, loading = false } = props

  const tableProps = { ...props, className }
  const emptyListingProps: EmptyListingProps = { ...props, className }

  const renderTable = () => {
    const { onLoading, allLoaded } = tableProps
    if (onLoading && allLoaded !== undefined) {
      return <ActionableNestedTableWithLazyLoading {...tableProps} onLoading={onLoading} allLoaded={allLoaded} className={className} />
    }
    return <ActionableNestedTable {...tableProps} className={className} />
  }

  return data?.length === 0 && !loading ? <EmptyListing {...emptyListingProps} /> : renderTable()
}

export default ActionableNestedTableWithEmptyListing
