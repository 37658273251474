import React, { FC, useEffect, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { Update } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { AutomatedProgramsCustomModals, StartProgramResult } from '@src/pages/listingPages/AutomatedPrograms/AutomatedProgramsListingPage.constants'
import { onStartProgram, startProgram } from '@src/pages/listingPages/AutomatedPrograms/AutomatedProgramsListingPage.helpers'
import ManageContactsOrStartModal, {
  ManageContactsOrStartModalProps,
} from '@src/pages/programs/dashboard/components/ProgramPerformance/components/ManageContactsOrStartModal/ManageContactsOrStartModal'

interface ManageContactsOrStartModalContainerProps
  extends Omit<ManageContactsOrStartModalProps, 'pendingContacts' | 'isStart' | 'onClose' | 'onAddContactsToProgram' | 'onExitContactsFromProgram'> {
  programId: string
  update: Update
}

const ManageContactsOrStartModalContainer: FC<ManageContactsOrStartModalContainerProps> = (props: ManageContactsOrStartModalContainerProps) => {
  const { programId, isFirstRun, update } = props
  const [state, setState] = useState({
    pending: 0,
    lastRowId: 0,
    loading: true,
    submitting: false,
  })
  const apolloClient = useApolloClient()

  useEffect(() => {
    onStartProgram(programId, apolloClient).then((result) => {
      if (result) {
        const {
          startAutomatedProgram: { pending = 0, lastRowId = 0 },
        } = result
        setState({
          pending,
          lastRowId,
          loading: false,
          submitting: false,
        })
      } else {
        update({ showCustomModal: true, customTableAction: AutomatedProgramsCustomModals.START_ERROR })
      }
    })
  }, [apolloClient, programId, update])

  const { pending, lastRowId, loading } = state

  const onAddContactsToProgram = async () => {
    setState({ ...state, submitting: true })
    const result = await startProgram(programId, false, pending > 0, apolloClient, lastRowId, update)
    const customTableAction =
      result === StartProgramResult.FAILED ? AutomatedProgramsCustomModals.START_ERROR : AutomatedProgramsCustomModals.START_ADD
    update({ showCustomModal: true, customTableAction })
  }

  const onExitContactsFromProgram = async () => {
    setState({ ...state, submitting: true })
    const result = await startProgram(programId, true, pending > 0, apolloClient, lastRowId, update)
    const customTableAction =
      result === StartProgramResult.FAILED ? AutomatedProgramsCustomModals.START_ERROR : AutomatedProgramsCustomModals.START_EXIT
    update({ showCustomModal: true, customTableAction })
  }

  return (
    <ManageContactsOrStartModal
      isOpen
      isFirstRun={isFirstRun}
      isStart
      pendingContacts={pending}
      loading={loading || state.submitting}
      title={'Start Program'}
      onAddContactsToProgram={onAddContactsToProgram}
      onExitContactsFromProgram={onExitContactsFromProgram}
      onClose={() => update && update({ showCustomModal: false })}
    />
  )
}

export default ManageContactsOrStartModalContainer
