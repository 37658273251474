import React, { FC, useState } from 'react'

import Button, { ButtonType } from '@components/Button'
import Container from '@components/Container'
import { renderLoader } from '@components/Loader/Loader'
import PageContainer from '@components/PageContainer'
import PageError from '@components/PageError'
import PageHeader from '@components/PageHeader'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import Search, { SearchType } from '@components/Search/Search'
import StatusToast from '@components/StatusToast/StatusToast'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import InitialMessageSending from '@src/pages/sms/initialMessage/components/InitialMessageSending/InitialMessageSending'
import { FormOptInsListsContainer } from '@src/pages/sms/marketingListOptIns/components/FormOptInsLists/FormOptInsListsContainer'
import OptInsSourceListsContainer, { AddListStatus } from '@src/pages/sms/marketingListOptIns/components/OptInsSourceLists/OptInsSourceListsContainer'
import OptInSearchResults from '@src/pages/sms/marketingListOptIns/components/SMSOptinViewAll/OptInSearchResults'
import OptInViewAllModal from '@src/pages/sms/marketingListOptIns/components/SMSOptinViewAll/OptInViewAllModal'
import StartPage from '@src/pages/sms/marketingListOptIns/components/StartPage/StartPage'
import WelcomeMessageContainer from '@src/pages/sms/marketingListOptIns/components/WelcomeMessage/WelcomeMessageContainer'

import './MarketingListOptIn.css'

const rootClass = 'marketing-list-opt-ins'

interface MarketingListOptIns {
  dataTest?: string
  loading?: boolean
  error?: Error
  isInitialMessageInSending?: boolean
  totalContacts: number
  searchTerm: string
  isViewAllOpen: boolean
  onOpenViewAll: () => void
  onSearchChange: (term: string) => void
  hasPurchasedSMS: boolean
}

export const MarketingListOptIns: FC<MarketingListOptIns> = (props: MarketingListOptIns) => {
  const {
    totalContacts = 0,
    searchTerm,
    error,
    isViewAllOpen,
    onOpenViewAll,
    onSearchChange,
    loading = false,
    isInitialMessageInSending,
    dataTest,
    hasPurchasedSMS,
  } = props
  const [status, setStatus] = useState({
    result: AddListStatus.none,
    sendWelcomeMessageChange: AddListStatus.none,
  })
  const { t } = useTranslation()

  const onChange = (value: string) => {
    onSearchChange(value)
  }

  const clearSearch = () => onSearchChange('')

  const updateStatus = (result: AddListStatus) => setStatus({ ...status, result })
  const closeStatus = () =>
    setStatus({
      ...status,
      result: AddListStatus.none,
      sendWelcomeMessageChange: AddListStatus.none,
    })

  const updateSendWelcomeMessageChange = (sendWelcomeMessageChange: AddListStatus) =>
    setStatus({
      ...status,
      sendWelcomeMessageChange,
    })

  const statusMessage =
    status.result === AddListStatus.success
      ? 'Source list(s) added'
      : status.result === AddListStatus.error
      ? 'Something went wrong on our end. The source list(s) could not be added.'
      : status.sendWelcomeMessageChange === AddListStatus.success
      ? 'Send welcome message was updated'
      : status.sendWelcomeMessageChange === AddListStatus.error
      ? 'Send welcome message could not be updated'
      : ''
  const statusSuccess = status.result === AddListStatus.success || status.sendWelcomeMessageChange === AddListStatus.success

  const renderNoContacts = () => (
    <PositionContainer noOverflow>
      <StartPage hasPurchasedSMS={hasPurchasedSMS} />
    </PositionContainer>
  )

  const containerDescription = (
    <>
      <div className={`${rootClass}__overview`}>
        <div className={`${rootClass}__info`}>
          <Typography text={t('Total Contacts Opted in')} type={TextType.BODY_TEXT} />
        </div>
        <Search incomingValue={searchTerm} placeholder={'Search Opt-ins'} onChangeHandler={onChange} searchType={SearchType.LARGE} />
      </div>
    </>
  )

  const renderWithContacts = () => (
    <>
      {isViewAllOpen && <OptInViewAllModal isOpen={isViewAllOpen} onOpenViewAll={onOpenViewAll} />}
      {statusMessage && <StatusToast isSuccess={statusSuccess} message={t(statusMessage)} closeStatus={closeStatus} />}
      <Container className={`${rootClass}__overview-and-search`} description={containerDescription}>
        <div className={`${rootClass}__total-and-view`}>
          <Typography type={TextType.SECTION_HEADER} text={totalContacts} weight={TextWeight.MEDIUM} className={`${rootClass}__total`} />
          <Button buttonType={ButtonType.TEXT} onClick={onOpenViewAll} dataTest={`${dataTest}__view-all`}>
            <Typography type={TextType.LINK} text={t('View all')} weight={TextWeight.MEDIUM} />
          </Button>
        </div>
        {searchTerm && <OptInSearchResults search={searchTerm} onClear={clearSearch} />}
      </Container>
      {hasPurchasedSMS && <WelcomeMessageContainer />}
      {hasPurchasedSMS && <OptInsSourceListsContainer setStatus={updateStatus} updateSendWelcomeMessageChange={updateSendWelcomeMessageChange} />}
      <FormOptInsListsContainer />
    </>
  )

  if (error) {
    return (
      <PageContainer>
        <PageError center />
      </PageContainer>
    )
  }

  return (
    <PageContainer className={rootClass} dataTest={dataTest}>
      {loading && renderLoader('loader--white-background')}
      {!loading && isInitialMessageInSending && <InitialMessageSending />}
      {!loading && !isInitialMessageInSending && (
        <PositionContainer>
          <PageHeader primaryText={t('SMS Opt-In List')} leftContent dataTest={rootClass} />
          {totalContacts > 0 ? renderWithContacts() : renderNoContacts()}
        </PositionContainer>
      )}
    </PageContainer>
  )
}
