import { gql } from '@apollo/client'

export default gql`
  query getAllSegmentGrowth($amount: Int, $groupBy: GroupBy) {
    getAllSegmentGrowth(amount: $amount, groupBy: $groupBy) {
      date
      description
      externalId
      percentageValue
      value
      values {
        date
        description
        externalId
        percentageValue
        value
        values {
          date
          description
          externalId
          percentageValue
          value
        }
      }
    }
  }
`
