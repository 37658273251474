import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import DataCardHeader, { DataCardHeaderProps } from '@components/DataCard/components/DataCardHeader/DataCardHeader'
import DataCardHeaderWithOptions, {
  DataCardHeaderOptionType,
} from '@components/DataCard/components/DataCardHeaderWithOptions/DataCardHeaderWithOptions'
import DataCard, { DataCardProps } from '@components/DataCard/DataCard'

import './DividedContentDataCard.css'

type Props = DataCardHeaderProps &
  DataCardProps & {
    options?: DataCardHeaderOptionType[]
    leftContent: ReactNode
    rightContent: ReactNode
    onClick?: (dataCardId: string) => void
    defaultOption?: DataCardHeaderOptionType
  }

const rootClass = 'divided-content-data-card'

const DividedContentDataCard: FC<Props> = (props: Props) => {
  const { headerText, leftContent, defaultOption, options, rightContent, dataTest = rootClass, className = '', ...dataCardProps } = props
  return (
    <DataCard {...dataCardProps} className={classNames(rootClass, className)} dataTest={dataTest}>
      <div className={`${rootClass}__wrapper`}>
        <div className={`${rootClass}__left-content`}>
          {options && options.length > 0 ? (
            <DataCardHeaderWithOptions options={options} headerText={headerText} defaultOption={defaultOption} />
          ) : (
            <DataCardHeader headerText={headerText} />
          )}
          {leftContent}
        </div>
        <div className={`${rootClass}__right-content`}>{rightContent}</div>
      </div>
    </DataCard>
  )
}

export default DividedContentDataCard
