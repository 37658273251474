import { gql } from '@apollo/client'

export default gql`
  query checkPersonalizationFields($msgId: String!, $html: String!) {
    checkPersonalizationFields(msgId: $msgId, html: $html) {
      field
      fieldType
      isCrmField
      isInvalidFormat
      totalContactCount
      totalErrorCount
      hasFallbackText
      listErrors {
        listId
        name
        listType
        contactCount
        errorCount
      }
    }
  }
`
