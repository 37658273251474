import { useApolloClient } from '@apollo/client'
import getAllContactsUpgradeCompleted from '@graphql/queries/getAllContactsUpgradeCompleted'
import { GetAllContactsUpgradeCompletedQuery, GetAllContactsUpgradeCompletedQueryVariables } from '@graphql/types/query-types'
import { FetchPromise } from '@utils/types'

export interface TabbedContainerRequests {
  getAllContactsUpgrade: () => FetchPromise<GetAllContactsUpgradeCompletedQuery>
}
export const useTabbedContainerRequests = (): TabbedContainerRequests => {
  const actonClient = useApolloClient()

  const getAllContactsUpgrade = () => {
    return actonClient.query<GetAllContactsUpgradeCompletedQuery, GetAllContactsUpgradeCompletedQueryVariables>({
      query: getAllContactsUpgradeCompleted,
      fetchPolicy: 'no-cache',
    })
  }

  return {
    getAllContactsUpgrade,
  }
}
