import React, { FC, useContext, useMemo } from 'react'

import { useEmailValidations } from '@src/pages/EmailComposer/hooks/useEmailValidations'
import { EmailComposerContext, ValidationSectionType } from '@utils/composer/context/EmailComposer.context'

import ValidationSection from '../ValidationSection/ValidationSection'
import ValidationsList, { Validation, ValidationSectionStatus } from '../ValidationsList/ValidationsList'

import './DeliverabilityValidations.css'

interface DeliverabilityValidationsProps {
  getValidations: (isInReview?: boolean) => Validation[]
  isInReview?: boolean
}

const rootClass = 'deliverability-validations'

const DeliverabilityValidations: FC<DeliverabilityValidationsProps> = ({ getValidations, isInReview }) => {
  const {
    values: {
      validations: { isValidationsLoading, isContentMissing },
      messageConfiguration: {
        reviewAndSend: { hideDeliverability },
      },
    },
    api: { onStatusChange },
  } = useContext(EmailComposerContext)

  const { getDeliverabilityStatus } = useEmailValidations()

  const validations = useMemo(() => (!isValidationsLoading ? getValidations() : undefined), [getValidations, isValidationsLoading])

  const renderValidations = useMemo(
    () => !isContentMissing && getDeliverabilityStatus() !== ValidationSectionStatus.SUCCESS,
    [isContentMissing, getDeliverabilityStatus]
  )

  return renderValidations ? (
    isInReview && !hideDeliverability ? (
      <ValidationSection
        type={ValidationSectionType.DELIVERABILITY}
        title="EmailComposer.Preview.Validations.Deliverability.Title"
        validations={validations}
        isLoading={isValidationsLoading}
        onStatusChange={onStatusChange}
      />
    ) : (
      <div className={rootClass}>
        <ValidationsList validations={validations} />
      </div>
    )
  ) : null
}

export default DeliverabilityValidations
