import React, { FC, useContext, useRef } from 'react'

import classNames from 'classnames'

import CustomPrompt from '@components/CustomPrompt/CustomPrompt'
import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import Loader from '@components/Loader'
import PageContainer from '@components/PageContainer/PageContainer'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Tab from '@components/TabsAO/TabAO'
import Tabs, { TabsType } from '@components/TabsAO/TabsAO'
import { useTranslation } from '@const/globals'
import { ProgramStepInput } from '@graphql/types/query-types'
import EmailSMSPerformanceContainer from '@src/pages/programs/dashboard/components/EmailSMSPerformance/EmailSMSPerformanceContainer'
import SMSPerformanceContainer from '@src/pages/programs/dashboard/components/EmailSMSPerformance/SMSPerformanceContainer'
import NoReportAvailable from '@src/pages/programs/dashboard/components/NoReportAvailable/NoReportAvailable'
import ProgramHistoryDisabled from '@src/pages/programs/dashboard/components/ProgramHistoryDisabled/ProgramHistoryDisabled'
import ProgramPerformanceContainer from '@src/pages/programs/dashboard/components/ProgramPerformance/ProgramPerformanceContainer'
import ProgramSettings from '@src/pages/programs/dashboard/components/ProgramSettings/ProgramSettings'
import ProgramStepsContainer from '@src/pages/programs/dashboard/components/ProgramSteps/ProgramStepsContainer'
import ProgramEarlyExitConditions from '@src/pages/programs/edit/components/ProgramEarlyExitConditions'
import { ProgramFlow } from '@src/pages/programs/edit/components/ProgramFlow/ProgramFlow'
import ProgramGeneralSettings from '@src/pages/programs/edit/components/ProgramGeneralSettings/ProgramGeneralSettings'
import ProgramListsAndSegments from '@src/pages/programs/edit/components/ProgramListsAndSegments'
import ProgramMessages from '@src/pages/programs/edit/components/ProgramMessages'
import ProgramManagerHeader from '@src/pages/programs/manager/components/ProgramManagerHeader/ProgramManagerHeader'
import ProgramManagerModals from '@src/pages/programs/manager/components/ProgramManagerModals/ProgramManagerModals'
import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'
import {
  getProgramManagerTabsStructure,
  PROGRAM_LIST_URL,
  ProgramManagerTabs,
  REACT_PROGRAM_LIST_URL,
} from '@src/pages/programs/manager/ProgramManager.constants'
import { UpgradeManagerContainer } from '@src/pages/programs/upgradeManager/UpgradeManagerContainer'
import { useAccountSettings } from '@utils/account/account.utils'
import { ProgramStepType, ProgramFlowTree } from '@utils/program/program.constants'
import { ProgramState } from '@utils/program/program.constants'

interface ProgramManagerProps {
  className?: string
  dataTest?: string
}

const rootClass = 'program-manager'

const ProgramManager: FC<ProgramManagerProps> = (props: ProgramManagerProps) => {
  const { dataTest = rootClass, className = '' } = props
  const {
    values: {
      program,
      programState,
      programErrors,
      programUrlId,
      programCounts,
      tab,
      unsavedChanges,
      loading,
      saving,
      isEditing,
      isNew,
      navigateFromCancelModal,
      programManagerErrors: { programNotFound },
    },
    tabChange,
    updateProgramAndSetUnsaved,
    navigation,
    toggleNavigationDisabled,
    openPopup,
  } = useContext(ProgramManagerContext)
  const { isReportingTooLarge } = program
  const { t } = useTranslation()
  const pageContainerRef = useRef<HTMLDivElement>(null)
  const accountSettings = useAccountSettings()
  const { hasAutomatedProgramsUpgrade } = accountSettings

  const getActiveSteps = (tree: ProgramFlowTree) => {
    const steps: ProgramStepInput[] = []
    Object.values(tree.nodePositions).forEach((node) => {
      const trackIndex = node.trackIndex
      const stepIndex = node.stepIndex

      if (tree.tracks[trackIndex].steps) {
        const curStep = tree.tracks[trackIndex].steps[stepIndex] as ProgramStepInput
        if (curStep) {
          if (!steps.includes(curStep) && curStep.stepType !== ProgramStepType.START) {
            steps.push(curStep)
          }
        }
      }
    })
    return steps
  }

  const hasSms =
    accountSettings.hasPurchasedSMS &&
    program.tracks.reduce((acc, track) => {
      return (
        acc ||
        track.steps.reduce((acc, step) => {
          return acc || step.stepType === ProgramStepType.SMS
        }, false)
      )
    }, false)

  if (loading) {
    return (
      <PageContainer>
        <Loader center />
      </PageContainer>
    )
  }

  const programManagerTabsStructure = getProgramManagerTabsStructure({
    t,
    hasFlowErrors: programErrors.stepErrors.length > 0,
    hasExitErrors: programErrors.exitErrors.length > 0,
    hasSettingsError: program.sourceList.length === 0,
    isReportDisabled: isNew,
    hasSms,
  })

  const canClearHistory = programState !== ProgramState.RUNNING && program.startedOn > 0

  const programsPage = (
    <>
      <CustomPrompt
        when={unsavedChanges && !navigateFromCancelModal}
        title={t('You have unsaved changes')}
        body={t('Leave.And.Lose.Changes.Message')}
        cancelButtonText={t('Keep editing')}
        okButtonText={t('Discard changes')}
        showOnLocationChange={({ pathname }) => !pathname.includes(programUrlId || 'new')}
      />
      <PageContainer noPadding noPrintClass className={classNames(rootClass, className)} dataTest={dataTest}>
        {!programNotFound && program && (
          <>
            <ProgramManagerModals />
            <ProgramManagerHeader />
            {saving && <Loader center />}
            <Tabs
              defaultValue={tab}
              onChange={tabChange}
              childData={programManagerTabsStructure}
              tabsType={TabsType.UNIFIED}
              className={`${rootClass}__header-tabs`}
            >
              <Tab index={ProgramManagerTabs.SETTINGS}>
                <PositionContainer>
                  {isEditing ? (
                    <ProgramGeneralSettings
                      program={program}
                      updateProgram={updateProgramAndSetUnsaved}
                      accountSettings={accountSettings}
                      hasUnsavedChanges={unsavedChanges}
                    />
                  ) : (
                    <ProgramSettings loading={false} program={program} isShownInManager />
                  )}
                </PositionContainer>
              </Tab>
              <Tab index={ProgramManagerTabs.PROGRAM_MESSAGES}>
                <PositionContainer scrollElementRef={pageContainerRef}>
                  <ProgramMessages program={program} updateProgram={updateProgramAndSetUnsaved} isEditing={isEditing} openPopup={openPopup} />
                </PositionContainer>
              </Tab>
              <Tab index={ProgramManagerTabs.LISTS_SEGMENTS}>
                <PositionContainer scrollElementRef={pageContainerRef}>
                  <ProgramListsAndSegments program={program} updateProgram={updateProgramAndSetUnsaved} isEditing={isEditing} />
                </PositionContainer>
              </Tab>
              <Tab index={ProgramManagerTabs.EARLY_EXITS}>
                <PositionContainer scrollElementRef={pageContainerRef}>
                  <ProgramEarlyExitConditions program={program} updateProgram={updateProgramAndSetUnsaved} isEditing={isEditing} />
                </PositionContainer>
              </Tab>
              <Tab index={ProgramManagerTabs.JOURNEY_BUILDER}>
                {tab === ProgramManagerTabs.JOURNEY_BUILDER && (
                  <ProgramFlow
                    t={t}
                    isViewOnly={!isEditing}
                    navigation={navigation}
                    program={program}
                    programUrlId={programUrlId}
                    programErrors={programErrors.stepErrors}
                    toggleNavigationDisabled={toggleNavigationDisabled}
                    updateProgram={updateProgramAndSetUnsaved}
                    accountSettings={accountSettings}
                    getActiveSteps={getActiveSteps}
                    setCurrentTab={() => tabChange(ProgramManagerTabs.SETTINGS)}
                    unsavedChanges={unsavedChanges}
                  />
                )}
              </Tab>
              <Tab index={ProgramManagerTabs.PROGRAM_PERFORMANCE}>
                <PositionContainer scrollElementRef={pageContainerRef}>
                  {isReportingTooLarge ? (
                    <NoReportAvailable canClearHistory={canClearHistory} />
                  ) : programCounts.isHistoryDisabled ? (
                    <ProgramHistoryDisabled />
                  ) : (
                    <ProgramPerformanceContainer />
                  )}
                </PositionContainer>
              </Tab>
              <Tab index={ProgramManagerTabs.STEPS}>
                <PositionContainer scrollElementRef={pageContainerRef}>
                  <ProgramStepsContainer isShownInManager tabChange={tabChange} />
                </PositionContainer>
              </Tab>
              <Tab index={ProgramManagerTabs.EMAIL_PERFORMANCE}>
                <PositionContainer scrollElementRef={pageContainerRef}>
                  {isReportingTooLarge ? (
                    <NoReportAvailable canClearHistory={canClearHistory} />
                  ) : programCounts.isHistoryDisabled ? (
                    <ProgramHistoryDisabled />
                  ) : (
                    <EmailSMSPerformanceContainer emailPerformance />
                  )}
                </PositionContainer>
              </Tab>
              {accountSettings.hasPurchasedSMS && (
                <Tab index={ProgramManagerTabs.SMS_PERFORMANCE}>
                  <PositionContainer scrollElementRef={pageContainerRef}>
                    {isReportingTooLarge ? (
                      <NoReportAvailable canClearHistory={canClearHistory} />
                    ) : programCounts.isHistoryDisabled ? (
                      <ProgramHistoryDisabled />
                    ) : (
                      <SMSPerformanceContainer />
                    )}
                  </PositionContainer>
                </Tab>
              )}
            </Tabs>
          </>
        )}
        {programNotFound && (
          <PositionContainer scrollElementRef={pageContainerRef}>
            <EmptyListing
              headline={t(`We couldn't find that program ID`)}
              text={t(`Sorry, there's nothing to see here`)}
              imgSrc={StaticImageNames.errorNothingFound}
              buttonText={t('View Automated Programs')}
              buttonURL={accountSettings.hasAPListingReact ? REACT_PROGRAM_LIST_URL : PROGRAM_LIST_URL}
              size={EmptyListingSize.LARGE}
            />
          </PositionContainer>
        )}
      </PageContainer>
    </>
  )

  return hasAutomatedProgramsUpgrade ? <UpgradeManagerContainer>{programsPage}</UpgradeManagerContainer> : programsPage
}

export default ProgramManager
