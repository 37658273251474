import React, { FC } from 'react'

import { useApolloClient, useMutation } from '@apollo/client'
import InfoAction from '@components/InfoAction/InfoAction'
import SvgNames from '@components/Svg/SvgNames'
import { useTranslation } from '@const/globals'
import setRedirect from '@graphql/mutations/setRedirect'
import { SetRedirectMutation, SetRedirectMutationVariables } from '@graphql/types/mutation-types'
import { StatusToastType } from '@interface/StatusToast'
import AccountRedirectsInput from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/AccountRedirects/components/AccountRedirectsInput'
import {
  ACCOUNT_REDIRECTS_LINK_1,
  BROKEN_INPUT_URL_KEY,
  UNPUBLISHED_INPUT_URL_KEY,
} from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/AccountRedirects/constants/accountRedirects.constants'

import './AccountRedirects.css'

interface Props {
  setToastStatus: (value: StatusToastType) => void
  unpublishedUrl: string
  brokenClickUrl: string
  brokenInitialInputValue: string
  brokenIsEditing: boolean
  brokenFocused: boolean
  unpublishedInitialInputValue: string
  unpublishedIsEditing: boolean
  unpublishedFocused: boolean
  updateInitialState: (state: any) => void
  dataTest?: string
}

export interface AccountRedirectsInitialValues {
  brokenInitialInputValue: string | undefined
  brokenIsEditing: boolean
  brokenFocused: boolean
  unpublishedInitialInputValue: string | undefined
  unpublishedIsEditing: boolean
  unpublishedFocused: boolean
}

const UNPUBLISHED_KEY = 'UNPUBLISHED'
const BROKEN_KEY = 'BROKEN_CLICKTHROUGH'
const rootClass = 'account-redirects'

const AccountRedirects: FC<Props> = (props: Props) => {
  const {
    dataTest,
    unpublishedUrl,
    brokenClickUrl,
    brokenInitialInputValue,
    brokenIsEditing,
    brokenFocused,
    unpublishedFocused,
    unpublishedInitialInputValue,
    unpublishedIsEditing,
    updateInitialState,
    setToastStatus,
  } = props

  const { t } = useTranslation()

  const client = useApolloClient()

  const [saveRedirectUrl] = useMutation<SetRedirectMutation, SetRedirectMutationVariables>(setRedirect, {
    client,
    fetchPolicy: 'no-cache',
  })

  const saveUnpublishedUrl = (url: string): Promise<any> => {
    return saveRedirectUrl({ variables: { key: UNPUBLISHED_KEY, value: url } })
  }

  const saveBrokenClickUrl = (url: string): Promise<any> => {
    return saveRedirectUrl({ variables: { key: BROKEN_KEY, value: url } })
  }

  return (
    <div data-test={dataTest} className={rootClass}>
      <InfoAction
        svgName={SvgNames.lightBulb}
        className={`${rootClass}__info-action`}
        message={t('Customize your brand experience by redirecting users when they click a broken link or access an unpublished landing page.')}
      />
      <div className={`${rootClass}__section`}>
        <AccountRedirectsInput
          setToastStatus={setToastStatus}
          onSave={saveUnpublishedUrl}
          url={unpublishedUrl}
          urlKey={UNPUBLISHED_INPUT_URL_KEY}
          header={t('Unpublished Landing Page')}
          moreInfoText={t('Set a Global Redirect URL for your unpublished Act-On landing pages.')}
          moreInfoLink={ACCOUNT_REDIRECTS_LINK_1}
          initialInputValue={unpublishedInitialInputValue}
          isEditing={unpublishedIsEditing}
          isFocused={unpublishedFocused}
          dataTest={`account-redirects-input-${UNPUBLISHED_INPUT_URL_KEY}`}
          onFocus={() => {
            updateInitialState((prev: AccountRedirectsInitialValues) => ({
              ...prev,
              unpublishedFocused: true,
            }))
          }}
          onBlur={() => {
            updateInitialState((prev: AccountRedirectsInitialValues) => ({
              ...prev,
              unpublishedFocused: false,
            }))
          }}
          updateInputValue={(v) =>
            updateInitialState((prev: AccountRedirectsInitialValues) => ({
              ...prev,
              unpublishedInitialInputValue: v,
            }))
          }
          updateIsEditing={(v) =>
            updateInitialState((prev: AccountRedirectsInitialValues) => ({
              ...prev,
              unpublishedIsEditing: v,
            }))
          }
        />
        <AccountRedirectsInput
          setToastStatus={setToastStatus}
          onSave={saveBrokenClickUrl}
          url={brokenClickUrl}
          urlKey={BROKEN_INPUT_URL_KEY}
          header={t('Broken Personalized Link Redirect')}
          moreInfoText={t('Set a redirect URL for links with missing personalization values.')}
          initialInputValue={brokenInitialInputValue}
          isEditing={brokenIsEditing}
          isFocused={brokenFocused}
          dataTest={`account-redirects-input-${BROKEN_INPUT_URL_KEY}`}
          onFocus={() => {
            updateInitialState((prev: AccountRedirectsInitialValues) => ({
              ...prev,
              brokenFocused: true,
            }))
          }}
          onBlur={() => {
            updateInitialState((prev: AccountRedirectsInitialValues) => ({
              ...prev,
              brokenFocused: false,
            }))
          }}
          updateInputValue={(v) =>
            updateInitialState((prev: AccountRedirectsInitialValues) => ({
              ...prev,
              brokenInitialInputValue: v,
            }))
          }
          updateIsEditing={(v) =>
            updateInitialState((prev: AccountRedirectsInitialValues) => ({
              ...prev,
              brokenIsEditing: v,
            }))
          }
        />
      </div>
    </div>
  )
}

export default AccountRedirects
