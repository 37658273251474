import { gql } from '@apollo/client'

export default gql`
  query getContactsFromCrmAccounts($listId: String!, $crmAccountsIds: [String!]) {
    getContactsFromCrmAccounts(listId: $listId, crmAccountsIds: $crmAccountsIds) {
      id
      name
      email
    }
  }
`
