import React, { FC, useCallback, useEffect, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import { ButtonSize } from '@components/Button/Button'
import { YesNo } from '@components/ConfirmationModal/index'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import Input from '@components/Input/Input'
import InputWithStatus, { ValidityReturnType } from '@components/InputWithStatus/InputWithStatus'
import LabelWithSvgTooltip from '@components/LabelWithTooltipIcon/LabelWithSvgTooltip'
import Loader, { LoaderTypes } from '@components/Loader/Loader'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import SingleSelectDropdown from '@components/SingleSelectDropdown/SingleSelectDropdown'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import StatusToast from '@components/StatusToast/StatusToast'
import { SvgNames, SvgType } from '@components/Svg/index'
import Svg, { SvgColor } from '@components/Svg/Svg'
import TextArea from '@components/TextArea/TextArea'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Toggle from '@components/Toggle/Toggle'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { ModalBodyStyle, ModalHeaderStyle, TextAlign, TextType, TextWeight } from '@components/Typography/Typography'
import { SERVICES_AGREEMENT_LINK, useTranslation } from '@const/globals'
import {
  contentTypeOptions,
  defaultState,
  GenerativeEmailModalProps,
  GenerativeEmailModalState,
  LengthTypes,
  marketingIntentOptions,
  MAX_SUMMARY_CHARACTERS,
  OPEN_AI_API_ERRORS,
  OutputLengths,
  toneOptions,
} from '@src/pages/Content/Email/Editor/components/GenerativeEmailModal/GenerativeEmailModal.constants'
import {
  getChatgptResponseRequest,
  getCompanyInfoRequest,
} from '@src/pages/Content/Email/Editor/components/GenerativeEmailModal/GenerativeEmailModal.utils'
import { checkURLWithoutHTTPValidity } from '@utils/formUtils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { logNewRelicError } from '@utils/new-relic.utils'

import './GenerativeEmailModal.css'

const rootClass = 'generative-email-modal'

const GenerativeEmailModal: FC<GenerativeEmailModalProps> = (props: GenerativeEmailModalProps) => {
  const { dataTest = rootClass, className = '', onAction, onClose, isOpen, subject, doneCallBack } = props
  const [errorToast, setErrorToast] = useState('')
  const [confirmCancel, setConfirmCancel] = useState(false)
  const [state, setState] = useState<GenerativeEmailModalState>({ ...defaultState, includeSubject: !!subject })
  const {
    contentType,
    marketingIntent,
    tone,
    companyName,
    companyUrl,
    summary,
    output,
    outputLength,
    contentGenerated,
    generating,
    includeSubject,
    generatedError,
  } = state
  const hasSummaryLengthError = summary.length > MAX_SUMMARY_CHARACTERS
  const cannotGenerate = summary === '' || contentType === '' || outputLength === LengthTypes.NONE || generating

  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.CHATGPT_EMAIL })

  const { t } = useTranslation()

  const generatorTooltip = useCallback(() => {
    if (summary === '') {
      return 'NoContent'
    } else if (contentType === '') {
      return 'ContentType'
    } else if (outputLength === LengthTypes.NONE) {
      return 'ContentLength'
    } else if (generating) {
      return 'Generating'
    }
  }, [contentType, generating, outputLength, summary])

  const getCompanyInfo = async () => {
    try {
      const { data, error } = await getCompanyInfoRequest(client)
      if (data.getAccountDetails) {
        setState({
          ...state,
          companyName: data.getAccountDetails.companyName,
          companyUrl: data.getAccountDetails.url ?? '',
        })
      }

      if (error) {
        logNewRelicError(error)
        setErrorToast(error.message)
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  useEffect(() => {
    getCompanyInfo()
  }, [])

  const handleAction = () => {
    if (output) {
      const insertable = output.replace(/<br \/>/gi, '</p><p>')
      onAction(insertable)
    }
  }

  const handleClose = () => {
    setState({ ...defaultState })
    onClose()
  }

  const generate = async () => {
    setState({ ...state, generating: true })

    const trackedParameters = [
      {
        key: 'content_type',
        value: contentType,
      },
      {
        key: 'company_name',
        value: companyName,
      },
      {
        key: 'company_url',
        value: companyUrl,
      },
      {
        key: 'tone',
        value: tone,
      },
      {
        key: 'marketing_intent',
        value: marketingIntent,
      },
      {
        key: 'summary',
        value: summary,
      },
      {
        key: 'email_subject',
        value: includeSubject ? subject : '',
      },
    ]
    const { data, errors } = await getChatgptResponseRequest(outputLength, contentGenerated, trackedParameters, client)

    if (data) {
      const {
        getChatgptResponse: { body },
      } = data
      if (body) {
        // need to swap \n\n in response with <br>, except for first one
        const bodyReplace = body.replace('\n\n', '').replace(/\n\n/g, '<br />')
        setState({ ...state, output: bodyReplace, generating: false, contentGenerated: true, generatedError: undefined })
        if (doneCallBack) {
          doneCallBack({
            type: 'mixed',
            value: [
              {
                type: 'paragraph',
                // Bee's built-in typedef for this is incorrect
                // See: https://github.com/BeefreeSDK/beefree-sdk-npm-official/blob/master/src/types/bee.ts#L1661
                value: {
                  html: `<p>${bodyReplace}</p>`,
                } as unknown as string,
              },
            ],
          })
        }
      }
    }

    if (errors) {
      logNewRelicError(errors)
      const newError = OPEN_AI_API_ERRORS.find((apiError) => apiError.error_code === errors[0].extensions?.code)
      if (newError) {
        setState({ ...state, generating: false, contentGenerated: true, generatedError: { solution: newError.solution, cause: newError.cause } })
      }
    }
  }

  const renderConfirmCancel = () => (
    <DeleteConfirmationModal
      isOpen
      title={t('Are you sure?')}
      deleteButtonText={t('Yes, cancel')}
      cancelButtonText={t('Continue editing')}
      body={
        <Typography
          text={t('GenerativeEmail.Cancel.Body')}
          type={TextType.BODY_TEXT_LIGHT}
          tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
        />
      }
      onAnswer={(answer) => {
        if (answer === YesNo.YES) {
          handleClose()
        }
        setConfirmCancel(false)
      }}
    />
  )

  const renderOutput = () => (
    <Typography
      text={output}
      className={classNames(`${rootClass}__code-block`, {
        [`${rootClass}__code-block-generating`]: generating,
      })}
      dataTest={`${dataTest}-generated-text`}
      tagComponents={{
        br: (
          <>
            <br />
            <br />
          </>
        ),
      }}
    />
  )

  const renderEmpty = () => (
    <EmptyListing
      size={EmptyListingSize.SMALL}
      imgSrc={StaticImageNames.aiIntegration}
      text={
        <>
          <Typography
            text={t('Generated content will appear here')}
            type={TextType.BODY_TEXT}
            weight={TextWeight.MEDIUM}
            className={`${rootClass}__empty-headline`}
          />
          <Typography
            text={t('Answer the prompts to experience the magic of Act-On with OpenAI.')}
            type={TextType.BODY_TEXT_LIGHT}
            textAlign={TextAlign.CENTER}
          />
        </>
      }
    />
  )

  const renderGenerateError = () => (
    <EmptyListing
      dataTest={`${dataTest}-generated-error`}
      size={EmptyListingSize.SMALL}
      imgSrc={StaticImageNames.aiError}
      text={
        <>
          <Typography
            text={t('GenerativeEmail.API.Headline')}
            type={TextType.BODY_TEXT_LARGE}
            weight={TextWeight.MEDIUM}
            textAlign={TextAlign.CENTER}
            className={`${rootClass}__empty-headline`}
          />
          <Typography
            text={t(`GenerativeEmail.API.Error`, { cause: generatedError?.cause ?? '', solution: generatedError?.solution ?? '' })}
            type={TextType.BODY_TEXT}
            textAlign={TextAlign.CENTER}
            className={`${rootClass}__generated-error-body`}
          />
          <Typography
            text={t(`GenerativeEmail.API.Error.Footer`)}
            tagComponents={{
              TextLink: (
                <TextLink
                  link={'https://connect.act-on.com/hc/en-us'}
                  size={TextLinkSize.LARGE}
                  hideIcon
                  dataTest={`${dataTest}-customer-support-link`}
                />
              ),
            }}
            type={TextType.BODY_TEXT}
            textAlign={TextAlign.CENTER}
            className={`${rootClass}__generated-error-footer`}
          />
        </>
      }
    />
  )

  const renderOutputLength = (lengthType: LengthTypes, label: string) => (
    <Button
      buttonType={outputLength === lengthType ? ButtonType.INFO : ButtonType.SECONDARY}
      buttonSize={ButtonSize.SMALL}
      disabled={generating}
      onClick={() => setState({ ...state, outputLength: lengthType })}
      ariaLabel={t(label)}
      className={classNames({
        [`${rootClass}__output-length-disabled`]: generating,
        [`${rootClass}__output-length-disabled-selected`]: generating && outputLength === lengthType,
      })}
      dataTest={`${rootClass}-length-${label.toLowerCase()}`}
      key={label}
    >
      {lengthType}
    </Button>
  )

  const notRequiredContainer =
    (validity: (url: string) => undefined | ValidityReturnType) =>
    (url: string): undefined | ValidityReturnType => {
      if (url) {
        return validity(url)
      }
    }

  const generateButton = (
    <Button
      buttonType={ButtonType.PRIMARY}
      onClick={() => generate()}
      iconPosition={ButtonIconPosition.LEFT}
      disabled={cannotGenerate}
      className={classNames(`${rootClass}__generate-button`, {
        [`${rootClass}__generate-button-generating`]: generating,
      })}
      dataTest={`${rootClass}-generate-button`}
    >
      {!generating && (
        <>
          <Svg name={SvgNames.magicWand} type={SvgType.LARGE_ICON} className={`${rootClass}__generate-button-svg`} />
          {t(contentGenerated ? 'Try again' : 'Create content')}
        </>
      )}
      {generating && (
        <>
          <Loader loaderType={LoaderTypes.row} className={`${rootClass}__generate-button-loader`} />
        </>
      )}
    </Button>
  )

  const generateButtonWithTooltip = cannotGenerate ? (
    <Tooltip trigger={generateButton}>{t(`GenerativeEmail.Generate.Tooltip.${generatorTooltip()}`)}</Tooltip>
  ) : (
    generateButton
  )

  const header = (
    <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__header`}>
      <Svg name={SvgNames.magicWand} type={SvgType.LARGE_ICON} />
      <Typography text={t('Generate AI Content')} {...ModalHeaderStyle} />
    </ModalHeader>
  )

  const toggle = !subject ? (
    <Tooltip
      trigger={<Toggle isOn={includeSubject} disabled onToggle={() => setState({ ...state, includeSubject: !includeSubject })} />}
      disabledTrigger={generating}
      disableTooltip={generating}
    >
      {t(`You haven’t added a subject line yet. To enable this feature, please add a subject line in the “General” tab and come back.`)}
    </Tooltip>
  ) : (
    <Toggle isOn={includeSubject} disabled={generating} onToggle={() => setState({ ...state, includeSubject: !includeSubject })} />
  )

  return (
    <>
      {confirmCancel && !cannotGenerate && renderConfirmCancel()}
      {errorToast && <StatusToast message={errorToast} closeStatus={() => setErrorToast('')} />}
      <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
        <ModalBody className={`${rootClass}__body`}>
          <div className={`${rootClass}__left`}>
            <div>
              <LabelWithSvgTooltip
                label={<Typography text={t('Content type')} weight={TextWeight.MEDIUM} />}
                alignOffset={-1}
                changeOnHover={false}
                tooltipContent={t('GenerativeEmail.Tooltip.ContentType')}
                disabledTrigger={generating}
                disableTooltip={generating}
                svgName={SvgNames.info}
                required
                requiredTextType={TextType.BODY_TEXT_LIGHT}
              />
              <SingleSelectDropdown
                value={contentType}
                disabled={generating}
                options={contentTypeOptions.sort((a, b) => (a.label > b.label ? 1 : -1))}
                placeholder={t('Select a content type')}
                onSubmit={(value?: string) => setState({ ...state, contentType: value ?? '' })}
              />
            </div>
            <div className={`${rootClass}__row`}>
              <div>
                <LabelWithSvgTooltip
                  label={<Typography text={t('Marketing intent')} weight={TextWeight.MEDIUM} />}
                  alignOffset={-1}
                  changeOnHover={false}
                  tooltipContent={t('GenerativeEmail.Tooltip.MarketingIntent')}
                  disabledTrigger={generating}
                  disableTooltip={generating}
                  svgName={SvgNames.info}
                />
                <SingleSelectDropdown
                  value={marketingIntent}
                  disabled={generating}
                  options={marketingIntentOptions.sort((a, b) => (a.label > b.label ? 1 : -1))}
                  placeholder={t('Select a goal')}
                  onSubmit={(value?: string) => setState({ ...state, marketingIntent: value ?? '' })}
                />
              </div>
              <div>
                <LabelWithSvgTooltip
                  label={<Typography text={t('Tone of voice')} weight={TextWeight.MEDIUM} />}
                  alignOffset={-1}
                  changeOnHover={false}
                  tooltipContent={t('GenerativeEmail.Tooltip.Tone')}
                  disabledTrigger={generating}
                  disableTooltip={generating}
                  svgName={SvgNames.info}
                />
                <SingleSelectDropdown
                  value={tone}
                  disabled={generating}
                  options={toneOptions.sort((a, b) => (a.label > b.label ? 1 : -1))}
                  placeholder={t('Select a tone')}
                  onSubmit={(value?: string) => setState({ ...state, tone: value ?? '' })}
                />
              </div>
            </div>
            <div className={`${rootClass}__row`}>
              <div>
                <LabelWithSvgTooltip
                  label={<Typography text={t('Company name')} weight={TextWeight.MEDIUM} />}
                  tooltipContent={t('GenerativeEmail.Tooltip.CompanyName')}
                  changeOnHover={false}
                  disabledTrigger={generating}
                  disableTooltip={generating}
                  alignOffset={-1}
                  svgName={SvgNames.info}
                />
                <Input
                  value={companyName}
                  placeholder={t('Add your company name')}
                  disabled={generating}
                  dataTest={`${dataTest}-company-name`}
                  onBlur={({ target: { value } }) => setState({ ...state, companyName: value })}
                />
              </div>
              <div>
                <LabelWithSvgTooltip
                  label={<Typography text={t('Company URL')} weight={TextWeight.MEDIUM} />}
                  alignOffset={-1}
                  changeOnHover={false}
                  tooltipContent={t('GenerativeEmail.Tooltip.CompanyUrl')}
                  disabledTrigger={generating}
                  disableTooltip={generating}
                  svgName={SvgNames.info}
                />
                <InputWithStatus
                  value={companyUrl}
                  placeholder={t('Add your company URL')}
                  disabled={generating}
                  validityFunctions={[notRequiredContainer(checkURLWithoutHTTPValidity)]}
                  tooltipErrorMessages={{
                    invalidUrl: 'Provide valid URL',
                  }}
                  dataTest={`${dataTest}-company-url`}
                  onBlur={({ target: { value } }) => setState({ ...state, companyUrl: value })}
                />
              </div>
            </div>
            <div>
              <div className={`${rootClass}__summary-label`}>
                <LabelWithSvgTooltip
                  label={<Typography text={t('Email summary')} weight={TextWeight.MEDIUM} />}
                  alignOffset={-1}
                  changeOnHover={false}
                  tooltipContent={t('GenerativeEmail.Tooltip.Summary')}
                  disabledTrigger={generating}
                  disableTooltip={generating}
                  svgName={SvgNames.info}
                  required
                  requiredTextType={TextType.BODY_TEXT_LIGHT}
                />
                <Typography
                  text={t('GenerativeEmail.Summary.Characters', { current: summary?.length ?? 0, maxCharacters: MAX_SUMMARY_CHARACTERS })}
                  type={TextType.BODY_TEXT_SMALL_LIGHT}
                  className={classNames({
                    [`${rootClass}__char-count-disabled`]: generating,
                  })}
                />
              </div>
              <TextArea
                value={summary}
                name={'ai_summary'}
                rows={5}
                resize={false}
                disabled={generating}
                error={hasSummaryLengthError}
                placeholder={t('What is your email about?')}
                onChange={({ target: { value } }) => setState({ ...state, summary: value })}
                dataTest={`${dataTest}-summary`}
              />
              {hasSummaryLengthError && (
                <Typography
                  text={t('GenerativeEmail.Summary.LengthError', {
                    maxChars: MAX_SUMMARY_CHARACTERS,
                    overage: summary.length - MAX_SUMMARY_CHARACTERS,
                  })}
                  tagProps={{ medium: { weight: TextWeight.MEDIUM, inline: true } }}
                  type={TextType.ERROR}
                  dataTest={`${dataTest}-summary-error`}
                />
              )}
            </div>
            <div className={`${rootClass}__include-subject`}>
              {toggle}
              <Typography
                text={t('Include subject line in email summary')}
                inline
                className={classNames({ [`${rootClass}__typography-disabled`]: generating })}
              />
              <Tooltip
                trigger={<Svg name={SvgNames.info} type={SvgType.ICON} fill={SvgColor.LIGHT_GRAY} hoverFill={SvgColor.TEXT_GRAY} />}
                triggerClassName={`${rootClass}__include-subject-tooltip`}
                disabledTrigger={generating}
                disableTooltip={generating}
              >
                {t('GenerativeEmail.Tooltip.IncludeSubject')}
              </Tooltip>
            </div>
            <div className={`${rootClass}__row`}>
              <div>
                <LabelWithSvgTooltip
                  label={<Typography text={t('Content length')} />}
                  changeOnHover={false}
                  tooltipContent={t('GenerativeEmail.Tooltip.OutputLength')}
                  disabledTrigger={generating}
                  disableTooltip={generating}
                  svgName={SvgNames.info}
                  required
                  requiredTextType={TextType.BODY_TEXT_LIGHT}
                />
                <div className={`${rootClass}__lengths`}>{OutputLengths.map((outputLen) => renderOutputLength(...outputLen))}</div>
              </div>
              <div className={`${rootClass}__generate-button-ctr`}>{generateButtonWithTooltip}</div>
            </div>
          </div>
          <div className={`${rootClass}__right`}>
            <LabelWithSvgTooltip
              label={<Typography text={t('Generated content')} weight={TextWeight.MEDIUM} />}
              alignOffset={-1}
              changeOnHover={false}
              tooltipContent={
                <Typography
                  text={t('GenerativeEmail.Tooltip.GeneratedContent')}
                  type={TextType.BODY_TEXT_WHITE}
                  tagProps={{ medium: { weight: TextWeight.MEDIUM, inline: true } }}
                />
              }
              disabledTrigger={generating}
              disableTooltip={generating}
              svgName={SvgNames.info}
            />
            {output && renderOutput()}
            {!output && !generatedError && renderEmpty()}
            {!output && generatedError && renderGenerateError()}
          </div>
        </ModalBody>
        <ModalFooter footerType={ModalFooterType.Form} className={`${rootClass}__footer`}>
          <div className={`${rootClass}__footer__extra`}>
            <Typography
              {...ModalBodyStyle}
              type={TextType.BODY_TEXT_SMALL_LIGHT}
              text={t('GenerativeEmail.Footer.Terms')}
              tagComponents={{
                TextLink: <TextLink link={SERVICES_AGREEMENT_LINK} hideIcon />,
              }}
            />
            <Tooltip
              trigger={<Svg name={SvgNames.info} type={SvgType.ICON} fill={SvgColor.LIGHT_GRAY} hoverFill={SvgColor.TEXT_GRAY} />}
              triggerClassName={`${rootClass}__footer__extra-tooltip`}
              disabledTrigger={generating}
              disableTooltip={generating}
            >
              {t('GenerativeEmail.Footer.Tooltip')}
            </Tooltip>
          </div>
          <Button
            buttonType={ButtonType.TERTIARY}
            onClick={() => (cannotGenerate ? handleClose() : setConfirmCancel(true))}
            dataTest={`${dataTest}-button-tertiary`}
          >
            {t('Cancel')}
          </Button>
          <Button buttonType={ButtonType.PRIMARY} disabled={!output || generating} onClick={handleAction} dataTest={`${dataTest}-button-primary`}>
            {t('Insert')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default GenerativeEmailModal
