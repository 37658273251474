import React from 'react'
import { Row } from 'react-table'

import { TableActions } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.constants'
import { getActionProps } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.utils'
import {
  ItemDtoWithBeeComposer,
  ListPageCommonState,
  ListPageTableActionCustomProps,
  TableProps,
  Update,
} from '@complex/ListingPage/Context/ListingPageCommon.context'
import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import Pill, { PillSize, PillType } from '@components/Pill/Pill'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import { renderRelativeDate } from '@components/Table/components/tableColumns'
import { RowAction } from '@components/Table/Table'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import Toggletip from '@components/Toggletip/Toggletip'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { PrevPageParamsEnum } from '@src/pages/EmailComposer/utils/EmailComposer.types'
import { ItemType } from '@utils/categorization'
import { getStandardFormattedDate, relativeDate } from '@utils/date'
import { openDraftEditorWindow } from '@utils/drafts'
import { renderPathToFolder } from '@utils/searchUtils'

import { Drafts } from './DraftsListingPage.constants'
import DraftsInfoHover from '../components/DraftsInfoHover/DraftsInfoHover'

export enum DraftsCustomTableActions {
  EDIT = 'EDIT',
  CREATE_LANDING_PAGE = 'CREATE_LANDING_PAGE',
  CREATE_TEMPLATE = 'CREATE_TEMPLATE',
}

const rowActionCustomProps: ListPageTableActionCustomProps[] = [
  { name: DraftsCustomTableActions.EDIT, position: 0, isInDropdown: false },
  { name: 'PREVIEW', position: 1, isInDropdown: false },
  { name: 'ADD_TO_FAVORITES', position: 2, isInDropdown: false },
  { name: DraftsCustomTableActions.CREATE_TEMPLATE, position: 3, isInDropdown: false },
  { name: 'DUPLICATE', position: 4, hasTopSection: true, isInDropdown: true },
  { name: DraftsCustomTableActions.CREATE_LANDING_PAGE, position: 5, hasTopSection: false, isInDropdown: true },
  { name: 'MOVE_TO_FOLDER', position: 6, hasTopSection: true, isInDropdown: true },
  { name: 'REMOVE_FROM_FOLDER', position: 7, hasTopSection: false, isInDropdown: true },
  { name: 'DELETE', position: 8, hasTopSection: true, isInDropdown: true },
]

const headerActionCustomProps: ListPageTableActionCustomProps[] = [
  { name: 'ADD_TO_FAVORITES', position: 0 },
  { name: 'MANAGE_TAGS', position: 1 },
  { name: 'MOVE_TO_FOLDER', position: 2 },
  { name: 'REMOVE_FROM_FOLDER', position: 3 },
  { name: 'DELETE', position: 5, isInDropdown: true },
]

const renderNoteIcon = (t: Function, note: string, createdAt: number, noteCreator?: string, rootClass?: string) => (
  <Toggletip
    className={`${rootClass}__draft-note`}
    hasTail
    sideOffset={3}
    trigger={<Svg name={SvgNames.chatSolid} type={SvgType.ICON} fill={SvgColor.TEXT_TEAL} />}
    title={'Email Returned to Drafts'}
    description={
      <div className={`${rootClass}__draft-note-content`}>
        <Typography
          text={noteCreator ? t(`ListPage.Drafts.Note.Date_creator`) : t(`ListPage.Drafts.Note.Date`)}
          values={{ noteCreator, createdAt: getStandardFormattedDate(Number(createdAt), true) }}
          tagProps={{ bold: { weight: TextWeight.BOLD } }}
          inline
        />
        <Typography text={t(`ListPage.Drafts.Note.Description`)} values={{ note }} tagProps={{ bold: { weight: TextWeight.BOLD } }} inline />
      </div>
    }
  />
)

const renderItemNameVal = (original: Drafts, t: Function, search: string, rootClass: string) => {
  const { name, description, note, noteCreator, createdAt, createdBy, lastUpdated, lastUpdatedBy, beeComposer } = original

  return (
    <>
      <div className={`${rootClass}__draft-name`}>
        <div className={`${rootClass}__draft-name-wrapper`}>
          {note && renderNoteIcon(t, note, createdAt, noteCreator, rootClass)}
          <Tooltip ellipOnTrigger trigger={<div className={'ellip'}>{search ? renderBoldTextOnMatch(name, search) : name}</div>}>
            {name}
          </Tooltip>
          {beeComposer && <Pill text={t('BETA')} type={PillType.SECONDARY} noMargin size={PillSize.SMALL} />}
          <DraftsInfoHover
            name={name}
            description={description}
            createdAt={createdAt}
            createdBy={createdBy}
            lastUpdated={lastUpdated}
            updatedBy={lastUpdatedBy}
          />
        </div>
      </div>
    </>
  )
}

const onCustomTableAction = (
  customTableAction: DraftsCustomTableActions,
  update: Update,
  listPageValues: ListPageCommonState,
  selectedItem?: ItemDtoWithBeeComposer
) => {
  const { selectedRows } = listPageValues
  const selectedRow = selectedItem ?? selectedRows[0]
  const selectedRowId = selectedRow?.externalId ?? '-1'
  const isBeeComposer = selectedRow?.beeComposer

  const showCustomModal = (action: DraftsCustomTableActions, refetchCountsOnAction = false) =>
    update({
      customTableAction: action,
      showCustomModal: true,
      refetchCountsOnAction,
    })

  const customAction: { [key in DraftsCustomTableActions]: () => void } = {
    [DraftsCustomTableActions.EDIT]: () => openDraftEditorWindow(selectedRowId, '', isBeeComposer, PrevPageParamsEnum.drafts),
    [DraftsCustomTableActions.CREATE_LANDING_PAGE]: () => showCustomModal(DraftsCustomTableActions.CREATE_LANDING_PAGE),
    [DraftsCustomTableActions.CREATE_TEMPLATE]: () => showCustomModal(DraftsCustomTableActions.CREATE_TEMPLATE),
  }
  customAction[customTableAction]()
}

const getCustomRowActions = (tableActions: TableActions, t: Function, canCreate: boolean): RowAction[] => [
  {
    label: t('Edit'),
    icon: SvgNames.pencil,
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(DraftsCustomTableActions.EDIT, row),
    ...getActionProps(DraftsCustomTableActions.EDIT, rowActionCustomProps),
  },
  {
    label: t('Create landing page'),
    icon: SvgNames.appendedList,
    disabled: (row?: Row<ItemDtoWithBeeComposer>) => row?.original?.beeComposer || !canCreate,
    hasTooltip: true,
    tooltipMessage: (row?: Row<ItemDtoWithBeeComposer>) =>
      row?.original?.beeComposer
        ? t('Beta.Emails.Tooltip.Message', {
            context: row?.original?.isPlainTextOnly ? 'plainText' : row?.original?.isCustomMessage ? 'uploadHtml' : 'default',
          })
        : !canCreate
        ? 'Ask your administrator for permission to do this'
        : '',
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(DraftsCustomTableActions.CREATE_LANDING_PAGE, row),
    ...getActionProps(DraftsCustomTableActions.CREATE_LANDING_PAGE, rowActionCustomProps),
  },
  {
    label: t('Create template from draft'),
    icon: SvgNames.mailAdd,
    hidden: !canCreate,
    hasTooltip: !canCreate,
    tooltipMessage: 'Ask your administrator for permission to do this',
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(DraftsCustomTableActions.CREATE_TEMPLATE, row),
    ...getActionProps(DraftsCustomTableActions.CREATE_TEMPLATE, rowActionCustomProps),
  },
]

const clickableColumnOptions = {
  colIndex: 0,
  action: {},
}

export const tableColumns = (t: Function): ColumnDefWithAdditionalProps<Drafts, any>[] => [
  {
    header: 'Draft Title',
    accessorKey: 'name',
    textAlign: 'left',
    cell: ({ row: { original } }) => renderItemNameVal(original, t, '', 'drafts-listing-page-container'),
  },
  {
    header: 'Tags',
    accessorKey: 'tags',
    textAlign: 'left',
    enableCustomCellValue: true,
    disableSorting: true,
  },
  {
    header: 'Created',
    accessorKey: 'createdAt',
    textAlign: 'left',
    maxSize: 150,
    fieldType: 'TIMESTAMP',
    cell: (cell) => renderRelativeDate(relativeDate(cell.getValue(), true, false), getStandardFormattedDate(cell.getValue())),
  },
  {
    header: t('Last Updated'),
    accessorKey: 'lastUpdated',
    textAlign: 'left',
    fieldType: 'TIMESTAMP',
    cell: (cell) => renderRelativeDate(relativeDate(cell.getValue(), true, false), getStandardFormattedDate(cell.getValue())),
    maxSize: 150,
  },
]

const renderSearchColumns = (
  searchInAllItems: boolean | undefined,
  currentFolder: FolderData,
  search: string,
  folders: FolderData[],
  t: Function
): ColumnDefWithAdditionalProps<Drafts, any>[] => [
  {
    header: 'Draft Title',
    accessorKey: 'name',
    textAlign: 'left',
    cell: ({ row: { original } }) => renderItemNameVal(original, t, search, 'drafts-listing-page-container'),
  },
  {
    header: 'Tags',
    accessorKey: 'tags',
    textAlign: 'left',
    maxSize: 150,
    disableSorting: true,
    enableCustomCellValue: true,
  },
  {
    header: 'Location',
    accessorKey: 'folderId',
    textAlign: 'left',
    maxSize: 150,
    cell: (cell) =>
      renderPathToFolder(ItemType.EMAIL_DRAFT, cell.getValue<number>(), folders, searchInAllItems, 'listing-page-container', t, currentFolder),
  },
  {
    header: 'Last Updated',
    accessorKey: 'lastUpdated',
    textAlign: 'right',
    maxSize: 150,
    cell: (cell) => renderRelativeDate(relativeDate(cell.getValue<number>(), true, false), getStandardFormattedDate(cell.getValue<number>())),
  },
]

export const tableProps = (t: Function, canCreate: boolean): TableProps => ({
  columns: tableColumns(t),
  hasAutoSelectedRows: false,
  hasExpander: false,
  rowActionCustomProps,
  renderSearchColumns: (searchInAllItems: boolean | undefined, currentFolder: FolderData, search: string, folders: FolderData[]) =>
    renderSearchColumns(searchInAllItems, currentFolder, search, folders, t),
  headerActionCustomProps,
  listPage: 'Drafts',
  getCustomRowActions: (tableActions) => getCustomRowActions(tableActions, t, canCreate),
  onCustomTableAction,
  onRowClicked: (selectedItem) =>
    openDraftEditorWindow(selectedItem.original.externalId, '', selectedItem.original.beeComposer, PrevPageParamsEnum.drafts),
  clickableColumnOptions,
  actonAssetType: 'EMAIL_DRAFT',
  shareModalText: '',
})
