import React, { FC, useContext, useEffect } from 'react'

import classNames from 'classnames'

import Paginator from '@components/Paginator/Paginator'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'
import { CurrentStep } from '@src/pages/programs/upgradeManager/components/CurrentStep/CurrentStep'
import { getStepValidation } from '@src/pages/programs/upgradeManager/components/StepIssues/StepIssues.utils'
import { UpgradeManagerContext } from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'
import { UpgradedStepsType } from '@src/pages/programs/upgradeManager/helper/UpgradeManager.helper'

import './StepIssues.css'

const rootClass = 'step-issues'
const upgradeManagerClass = 'upgrade-manager'
const rootText = 'AutomatedPrograms.UpgradeManager.StepIssues.'

export const StepIssues: FC = () => {
  const {
    values: { allContactsFieldsOptions, currentStep, upgradedCurrentStep, standardFields, stepsToUpgrade, upgradedSteps, upgradedStepSources },
    goToNextOrPreviousStep,
    saveStep,
    update,
  } = useContext(UpgradeManagerContext)

  const {
    values: { program },
  } = useContext(ProgramManagerContext)

  const { t } = useTranslation()

  const hasUpgradableSteps = !!upgradedSteps && upgradedSteps.length > 0

  useEffect(() => {
    if (upgradedSteps?.length > 0 && !!upgradedStepSources) {
      const enabled = upgradedSteps?.every((step) =>
        getStepValidation[step?.stepType as UpgradedStepsType](step, upgradedStepSources, program, standardFields, allContactsFieldsOptions)
      )
      update({ submitButtonDisabled: !enabled })
    }
  }, [upgradedSteps])

  useEffect(() => {
    saveStep()
  }, [upgradedCurrentStep])

  const onChange = (current?: number) => {
    if (current !== undefined) {
      saveStep()
      goToNextOrPreviousStep(current)
    }
  }

  const onStepChange = (removed: boolean) => {
    if (!!upgradedCurrentStep) {
      update({ upgradedCurrentStep: { ...upgradedCurrentStep, removed } })
    } else if (!!currentStep) {
      update({ upgradedCurrentStep: { ...currentStep, removed } })
    }
  }

  const renderPaginator = () => (
    <div className={`${rootClass}__paginator-container`}>
      <Paginator defaultCurrent={hasUpgradableSteps ? 1 : 0} total={stepsToUpgrade.length} onBack={onChange} onNext={onChange} />
    </div>
  )

  return (
    <div className={classNames(rootClass, `${upgradeManagerClass}__step`)}>
      <div className={`${upgradeManagerClass}__title-container`}>
        <Typography text={t(`${rootText}Title`)} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.MEDIUM} />
        <Typography text={t(`${rootText}Description`)} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.REGULAR} />
      </div>
      <div className={`${rootClass}__steps-container`}>
        {hasUpgradableSteps ? (
          <CurrentStep currentStep={currentStep} upgradedCurrentStep={upgradedCurrentStep} onChange={onStepChange}>
            {renderPaginator()}
          </CurrentStep>
        ) : (
          renderPaginator()
        )}
      </div>
    </div>
  )
}
