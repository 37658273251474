import { ActionType, ColumnInstance, HeaderGroup, Row, TableCellProps, TableHeaderProps, TableInstance, TableState } from 'react-table'

export const getColumnProps = (column: HeaderGroup | ColumnInstance, rawProps: TableCellProps | TableHeaderProps): TableHeaderProps => {
  const { flexColumn } = { flexColumn: false, ...column }
  return {
    ...rawProps,
    style: {
      ...rawProps.style,
      flex: flexColumn ? `1 0 auto` : '',
      width: `${flexColumn ? 0 : column.maxWidth}px`,
      maxWidth: `${column.maxWidth}px`,
      minWidth: `${column.minWidth}px`,
    },
  }
}

export const getRowSelectedReducerActionState = (state: TableState, action: ActionType, isSingleSelect: boolean) =>
  isSingleSelect ? { ...state, selectedRowIds: { [action.id]: state.selectedRowIds[action.id] || false } } : state

const getDifferentKeys = (previous: { [key: string]: boolean }, current: { [key: string]: boolean }): { select: string[]; deselect: string[] } => {
  const currentEntries = Object.entries(current)
  const currentKeys = Object.keys(current)
  const previousEntries = Object.entries(previous)
  const previousKeys = Object.keys(previous)
  const select = currentEntries.filter(([key, value]) => !previousKeys.includes(key) && value).map(([key, _]) => key)
  const deselect = previousEntries.filter(([key, value]) => !currentKeys.includes(key) && value).map(([key, _]) => key)
  return { select, deselect }
}

const getSubRows = (rows: Row[]): Row[] => {
  let subRows: Row[] = []

  rows.forEach((row) => {
    subRows.push(row)
    if (row.subRows.length > 0) {
      const subSubRows = getSubRows(row.subRows)
      subRows = subRows.concat(subSubRows)
    }
  })

  return subRows
}

export const selectOrDeselectSubRows = (state: TableState, previousState: TableState, instance: TableInstance) => {
  const { select, deselect } = getDifferentKeys(previousState.selectedRowIds ?? {}, state.selectedRowIds ?? {})
  const selectRows = instance?.rows.filter((row) => select.includes(row.id) && !(row.original as any)?.disabled)
  const selectSubRows = getSubRows(selectRows ?? []).filter(({ id }) => id.includes('.'))

  if (select[0]?.includes('.')) {
    select.forEach((id) => instance?.toggleRowSelected(id.split('.')[0], true))
    selectSubRows.forEach((subRow) => instance?.toggleRowSelected((subRow as any).id, true))
  } else if (!Object.keys(state.selectedRowIds).find((id) => id.startsWith(select[0]) && id !== select[0])) {
    selectSubRows.forEach((subRow) => instance?.toggleRowSelected((subRow as any).id, true))
  }

  const deselectRows = instance?.rows.filter((row) => deselect.includes(row.id) && !(row.original as any)?.disabled)
  const deselectSubRows = getSubRows(deselectRows ?? [])
  deselectSubRows.map((subRow) => instance?.toggleRowSelected((subRow as any).id, false))
}
