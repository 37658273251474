import React, { ReactNode } from 'react'

import { AccountSettings } from '@graphql/types/query-types'
import { AccountContext } from '@utils/account/account.utils'

type Props = {
  accountSettings: AccountSettings | undefined
  children: ReactNode
}
type AllProps = Props
const AccountProvider = (props: AllProps) => {
  const { children, accountSettings } = props

  if (!accountSettings) {
    return null
  }
  return <AccountContext.Provider value={accountSettings}>{children}</AccountContext.Provider>
}

export default AccountProvider
