import { SidebarProps } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { FORMS_SUBMISSIONS_URL, SEGMENTS_URL } from '@src/pages/listingPages/FormsJoinView/FormsJoinViewListingPageContainer.constants'
import { ItemSubType, ItemType } from '@utils/categorization'
import { allFormSubmissionsFilter, allWebinarSubmissionsFilter } from '@utils/filter'

interface FormsJoinViewData {
  sidebarProps: SidebarProps
  itemType: ItemType
  currentItemSubType: ItemSubType | undefined
}

const getItemType = (url: string): { itemType: ItemType; itemSubType?: ItemSubType } => {
  if (url.includes(SEGMENTS_URL)) {
    return { itemType: ItemType.SEGMENT }
  } else if (url.includes(FORMS_SUBMISSIONS_URL)) {
    return { itemType: ItemType.FORM_SUBMISSION }
  } else {
    return { itemType: ItemType.WEBINAR_SUBMISSION }
  }
}

export const useFormsJoinViewDataByItemType = (storyUrl?: string): FormsJoinViewData => {
  const itemTypes = getItemType(storyUrl ?? window.location.pathname)
  const { itemSubType, itemType } = itemTypes

  const isWebinarSubmissions = itemType === ItemType.WEBINAR_SUBMISSION

  const sidebarProps: SidebarProps = {
    sidebarHeader: isWebinarSubmissions ? 'Webinar Registrations' : 'Form Submissions',
    hasRecent: true,
    hasCreatedByMe: true,
    allItemFilter: isWebinarSubmissions ? allWebinarSubmissionsFilter : allFormSubmissionsFilter,
  }

  return {
    itemType,
    currentItemSubType: itemSubType,
    sidebarProps,
  }
}
