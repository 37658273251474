import { gql } from '@apollo/client'

export default gql`
  query getUserSettings {
    getUserSettings {
      userSettings {
        settingName
        settingType
        settingValue
      }
    }
  }
`
