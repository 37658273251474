import React, { FC, useContext, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { List } from '@interface/foldersLists/List'
import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'
import { SourceReplacement } from '@src/pages/programs/upgradeManager/components/SourceReplacement/SourceReplacement'
import {
  EarlyExitTempSource,
  UpgradedEarlyExitCondition,
  UpgradedSource,
  UpgradeManagerContext,
} from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'

const rootClass = 'sources-upgrade'
const upgradeManagerClass = 'upgrade-manager'
const rootText = 'AutomatedPrograms.UpgradeManager.EarlyExitsUpgrade.'

export const EarlyExitsUpgrade: FC = () => {
  const {
    values: { recommendedSegments, upgradedEarlyExitSources },
    update,
  } = useContext(UpgradeManagerContext)
  const {
    values: { program },
  } = useContext(ProgramManagerContext)

  const upgradedEarlyExitConditions: UpgradedEarlyExitCondition[] = useMemo(
    () =>
      (program?.exit?.exitChoices ?? []).map((exit, index) => ({
        ...exit,
        upgradeId: `early-exit-${index}`,
      })),
    [program?.exit?.exitChoices]
  )

  const earlyExitSegments: { id: string; upgradeId: string }[] = useMemo(
    () =>
      upgradedEarlyExitConditions?.reduce(
        (acc: { id: string; upgradeId: string }[], exit) => [
          ...acc,
          { id: exit?.srcId ?? '', upgradeId: exit.upgradeId },
          { id: exit?.listId ?? '', upgradeId: exit.upgradeId },
        ],
        []
      ),
    [upgradedEarlyExitConditions]
  )

  const earlyExitSources: EarlyExitTempSource[] = useMemo(
    () =>
      earlyExitSegments
        .map((segment) => {
          const source = program?.sources?.find((source) => source.id === segment.id)
          return source ? { ...source, upgradeId: segment.upgradeId } : undefined
        })
        .filter((segment) => segment !== undefined) as EarlyExitTempSource[],
    [earlyExitSegments, program?.sources]
  )

  const [earlyExitSourcesToUpgrade, setEarlyExitSourcesToUpgrade] = useState<UpgradedSource[]>(
    upgradedEarlyExitSources ||
      earlyExitSources?.map(({ id, name, count, upgradeId }) => {
        const recommended = Object.entries(recommendedSegments)?.find(([key]) => key === id)
        return {
          source: { id, sourceName: name ?? '', count: count as number },
          recommended: recommended ? recommended[1] : undefined,
          removed: false,
          upgradeId,
        }
      })
  )

  const { t } = useTranslation()

  useEffect(() => {
    const submitButtonDisabled = earlyExitSourcesToUpgrade.filter(({ removed }) => !removed).some(({ newSource }) => !newSource)
    if (!submitButtonDisabled) {
      updateEarlyExitConditions()
    }
    update({ submitButtonDisabled })
  }, [earlyExitSourcesToUpgrade])

  const updateEarlyExitConditions = () => {
    const exitConditions = upgradedEarlyExitConditions.map((condition) => {
      const listIdFound = earlyExitSourcesToUpgrade.find(
        (source) => source.upgradeId === condition.upgradeId && source.source.id === condition.listId
      )
      const srcIdFound = earlyExitSourcesToUpgrade.find((source) => source.upgradeId === condition.upgradeId && source.source.id === condition.srcId)
      return {
        ...condition,
        listId: listIdFound && !listIdFound.removed ? listIdFound.newSource?.id ?? '' : '',
        srcId: srcIdFound && !srcIdFound.removed ? srcIdFound.newSource?.id ?? '' : '',
      }
    })
    update({ upgradedEarlyExitSources: earlyExitSourcesToUpgrade, upgradedEarlyExitConditions: exitConditions })
  }

  const updateEarlyExitSource = (id: string, sourceData: Partial<UpgradedSource>, upgradeId?: string) => {
    setEarlyExitSourcesToUpgrade((earlyExitSourcesToUpgrade) =>
      earlyExitSourcesToUpgrade.map((source) => (source.source.id === id && source.upgradeId === upgradeId ? { ...source, ...sourceData } : source))
    )
  }

  const onSubmitSource = (id: string, newSource: List, upgradeId?: string) => {
    updateEarlyExitSource(id, { newSource }, upgradeId)
  }

  const onCheckboxChange = (id: string, isChecked: boolean, upgradeId?: string) => {
    updateEarlyExitSource(id, { removed: isChecked }, upgradeId)
  }

  return (
    <div className={classNames(rootClass, `${upgradeManagerClass}__step`)}>
      <div className={`${upgradeManagerClass}__title-container`}>
        <Typography text={t(`${rootText}Title`)} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.MEDIUM} />
        <Typography text={t(`${rootText}Description`)} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.REGULAR} />
      </div>
      <div className={`${upgradeManagerClass}__content-container`}>
        {earlyExitSourcesToUpgrade?.map((sourceToUpgrade) => (
          <SourceReplacement
            key={`${sourceToUpgrade.upgradeId}-${sourceToUpgrade.source.id}`}
            sourceToUpgrade={sourceToUpgrade}
            onSubmitSource={onSubmitSource}
            onCheckboxChange={onCheckboxChange}
            canCreateSegment
          />
        ))}
      </div>
    </div>
  )
}
