import { MutableRefObject, useCallback, useEffect } from 'react'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { IMAGES_DEFAULT_PAGE_SIZE } from '@components/AssetPickers/ImagePickerModal/constants/ImagePickerModal.constants'
import { IImagePickerItem, ImagePickerView } from '@components/AssetPickers/ImagePickerModal/utils/ImagePickerModal.utils'
import updateImagePickerViewType from '@graphql/mutations/updateImagePickerViewType'
import getImageQuery from '@graphql/queries/getImage'
import getImageLibraryFolders from '@graphql/queries/getImageLibraryFolders'
import getImagesQuery from '@graphql/queries/getImages'
import getLogoQuery from '@graphql/queries/getLogo'
import getLogosQuery from '@graphql/queries/getLogos'
import { UpdateImagePickerViewTypeMutation, UpdateImagePickerViewTypeMutationVariables } from '@graphql/types/mutation-types'
import {
  GetImagesQuery,
  GetImageQuery,
  GetImagesQueryVariables,
  GetLogosQuery,
  GetLogoQuery,
  GetLogosQueryVariables,
  GetLogoQueryVariables,
  GetImageLibraryFoldersQuery,
  GetImageLibraryFoldersQueryVariables,
  ImagesFolderResponse,
  GetImageQueryVariables,
} from '@graphql/types/query-types'
import { AccountActions } from '@utils/account/actions'
import { filterNotEmptyArray } from '@utils/array'

interface IImageLibraryFolders {
  loading: boolean
  folders: ImagesFolderResponse[]
}

export const useImageLibraryFolders = (includeSvg?: boolean): IImageLibraryFolders => {
  const client = useApolloClient()

  const { data, loading } = useQuery<GetImageLibraryFoldersQuery, GetImageLibraryFoldersQueryVariables>(getImageLibraryFolders, {
    client,
    fetchPolicy: 'network-only',
    variables: { includeSvg: !!includeSvg },
  })

  const folders = data?.getImageLibraryFolders ? data.getImageLibraryFolders.filter(filterNotEmptyArray) : []

  return {
    loading,
    folders,
  }
}
export type GetImagesPromiseType = (variables: GetImagesQueryVariables) => Promise<IImagePickerItem[]>
export type GetImagePromiseType = (variables: GetImageQueryVariables) => Promise<IImagePickerItem>
export type GetLogosPromiseType = (variables: GetLogosQueryVariables) => Promise<{ logos: IImagePickerItem[]; totalCount: number }>
export type GetLogoPromiseType = (variables: GetLogoQueryVariables) => Promise<IImagePickerItem>

interface IGetImages {
  getImages: GetImagesPromiseType
  getImageById: GetImagePromiseType
  getLogos: GetLogosPromiseType
  getLogoById: GetLogoPromiseType
}

export const useGetImages = (): IGetImages => {
  const client = useApolloClient()

  const getImages = useCallback(
    (variables: GetImagesQueryVariables) =>
      client
        .query<GetImagesQuery, GetImagesQueryVariables>({
          variables: { ...variables, pageSize: IMAGES_DEFAULT_PAGE_SIZE },
          query: getImagesQuery,
          fetchPolicy: 'network-only',
        })
        .then(({ data }) => {
          if (data?.getImages) {
            return data.getImages.filter(filterNotEmptyArray).map(({ previewUrl, ...rest }) => ({ ...rest, url: previewUrl }))
          } else {
            return Promise.reject()
          }
        }),
    [client]
  )

  const getImageById = useCallback(
    (variables: GetImageQueryVariables) =>
      client
        .query<GetImageQuery, GetImageQueryVariables>({
          variables,
          query: getImageQuery,
          fetchPolicy: 'network-only',
        })
        .then(({ data }) => {
          if (data?.getImage) {
            return { ...data.getImage, url: data.getImage.previewUrl }
          } else {
            return Promise.reject()
          }
        }),
    [client]
  )

  const getLogos = useCallback(
    (variables: GetLogosQueryVariables) =>
      client
        .query<GetLogosQuery, GetLogosQueryVariables>({
          variables: { ...variables, pageSize: IMAGES_DEFAULT_PAGE_SIZE },
          query: getLogosQuery,
          fetchPolicy: 'network-only',
        })
        .then(({ data }) => {
          if (data?.getLogos?.logos) {
            const logos = data.getLogos.logos
              .filter(filterNotEmptyArray)
              .filter(({ id, title, url }) => !!id && !!title && !!url) as IImagePickerItem[]
            return { logos, totalCount: data.getLogos.totalRecords ?? logos.length }
          } else {
            return Promise.reject()
          }
        }),
    [client]
  )

  const getLogoById = useCallback(
    (variables: GetLogoQueryVariables) =>
      client
        .query<GetLogoQuery, GetLogoQueryVariables>({
          variables,
          query: getLogoQuery,
          fetchPolicy: 'network-only',
        })
        .then(({ data }) => {
          const { id, url, title } = { ...data?.getLogo }
          if (id && url && title) {
            return { ...data.getLogo, id, url, title }
          } else {
            return Promise.reject()
          }
        }),
    [client]
  )

  return { getImages, getLogos, getImageById, getLogoById }
}

export const useUpdateViewType = (
  viewTypeRef: MutableRefObject<ImagePickerView>,
  initialView: string,
  loadAccount: AccountActions['loadAccount']
) => {
  const client = useApolloClient()

  const [uploadImageMutation] = useMutation<UpdateImagePickerViewTypeMutation, UpdateImagePickerViewTypeMutationVariables>(
    updateImagePickerViewType,
    {
      client,
      fetchPolicy: 'no-cache',
    }
  )

  const handleUpdateViewType = useCallback(
    (viewType: string) =>
      uploadImageMutation({ variables: { viewType } }).then(({ data }) => {
        if (!data) {
          return Promise.reject()
        }
        return data
      }),
    [uploadImageMutation]
  )

  useEffect(() => {
    return () => {
      if (initialView !== viewTypeRef.current) {
        // Updating ImagePickerView in AccountSettings if changed
        handleUpdateViewType(viewTypeRef.current).then(
          ({ updateImagePickerViewType }) => updateImagePickerViewType && loadAccount({ client, loading: false })
        )
      }
    }
  }, [handleUpdateViewType, viewTypeRef, initialView, loadAccount, client])
}
