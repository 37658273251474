import React, { FC } from 'react'

import classNames from 'classnames'

import Container from '@components/Container'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { List } from '@interface/foldersLists/List'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import { ListProgramSource } from '@utils/listingPage/listMaintenancePrograms.utils'

import ListProgramSourceSelector from './components/ListProgramSourceSelector/ListProgramSourceSelector'

import './ListProgramSources.css'

interface ListProgramSourcesProps {
  className?: string
  dataTest?: string
  onChange: (source?: ListProgramSource) => void
  source?: ListProgramSource
}

const rootClass = 'list-program-sources'

const ListProgramSources: FC<ListProgramSourcesProps> = (props: ListProgramSourcesProps) => {
  const { dataTest = rootClass, className = '', source, onChange } = props

  const { t } = useTranslation()

  const { userAllowedToCreatePrograms } = useAccountSettings()

  const onSubmit = (list: (List & { itemType?: ItemType }) | undefined) => {
    if (list) {
      const { baseId, id, name, size, itemType } = { ...list }
      const programSource: ListProgramSource = { baseId, id, name, count: size, itemType }
      onChange(programSource)
    } else {
      onChange()
    }
  }

  return (
    <Container className={classNames(rootClass, className)} dataTest={dataTest}>
      <Typography className={`${rootClass}__header`} text={t('Source List or Segment')} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
      <Typography
        className={`${rootClass}__subheader`}
        text={t('The list or segment that will be affected by this maintenance program.')}
        type={TextType.BODY_TEXT_LIGHT}
      />
      <ListProgramSourceSelector defaultSource={source} isEditing={userAllowedToCreatePrograms} onSubmit={onSubmit} showErrorOnUnselectedSource />
    </Container>
  )
}

export default ListProgramSources
