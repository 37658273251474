import React, { FC, Key, useEffect, useState } from 'react'

import { renderToString } from 'react-dom/server'

import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import Svg, { SvgNames } from '@components/Svg'
import { SvgColor, SvgType } from '@components/Svg/Svg'
import { RssItem } from '@graphql/types/query-types'
import { IAddOnResponseHTMLExtended } from '@src/pages/EmailComposer/utils/BeeEditor.types'
import { getUUID, useTranslation } from '@utils/const/globals'
import { logNewRelicError } from '@utils/new-relic.utils'

import { RssBlock } from './components/RssBlock/RssBlock'
import { useRssModalRequests } from './graphql/RssModal.graphql'
import RssModal from './RSSModal'

interface RssModalContainerProps {
  onClose: () => void
  onAction: (data: IAddOnResponseHTMLExtended) => void
  customFieldValues?: RssState
  isPlainText?: boolean
  isLandingPage?: boolean
  className?: string
  dataTest?: string
}

export enum RssLayout {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum RssFormat {
  FULL_CONTENT = 'FULL_CONTENT',
  SUMMARY = 'SUMMARY',
  TITLE = 'TITLE',
}

export enum RssAlignment {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export const DEFAULT_NUM_ITEM_OPTION = { label: '1', value: '1' }
export const Rss_CONNECT_ARTICLE = 'https://connect.act-on.com/hc/en-us/articles/360023759434-Send-Automated-Emails-using-RSS-Feeds'
export const OPTIONS = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' },
]

export interface RssState {
  url: string
  isValidUrl: boolean
  rssFeedItems: RssItem[]
  includeDate: boolean
  showRssLayoutBody: boolean
  formatOption: Key
  includeAuthor: boolean
  includeReadMoreLink: boolean
  id: string
  textAlignment: RssAlignment
  plainText?: string
  plainTextId?: number
  numItemsToDisplay?: SelectV2SingleOption
  selectedLayoutOption?: SelectV2SingleOption
}

const defaultState: RssState = {
  url: '',
  isValidUrl: false,
  rssFeedItems: [],
  includeDate: false,
  showRssLayoutBody: false,
  formatOption: RssFormat.FULL_CONTENT,
  includeAuthor: false,
  id: getUUID(true),
  includeReadMoreLink: false,
  textAlignment: RssAlignment.LEFT,
  numItemsToDisplay: DEFAULT_NUM_ITEM_OPTION,
}

const rootClass = 'rss-modal-container'

const RssModalContainer: FC<RssModalContainerProps> = (props: RssModalContainerProps) => {
  const { onClose, onAction, customFieldValues, isPlainText = false, isLandingPage } = props

  const { getRssFeedQuery, getRssFeedPlainTextQuery } = useRssModalRequests()
  const { t } = useTranslation()

  const layoutOptions: SelectV2SingleOption[] = [
    {
      value: RssLayout.TOP,
      label: t('EmailComposer.RssModal.Layout.Top.Label'),
      subText: t('EmailComposer.RssModal.Layout.Top.SubText'),
      renderItemIcon: () => <Svg name={SvgNames.rssImageTop} fill={SvgColor.TEXT_TEAL} type={SvgType.VERY_LARGE_ICON} />,
    },
    {
      value: RssLayout.BOTTOM,
      label: t('EmailComposer.RssModal.Layout.Bottom.Label'),
      subText: t('EmailComposer.RssModal.Layout.Bottom.SubText'),
      renderItemIcon: () => <Svg name={SvgNames.rssImageBottom} fill={SvgColor.TEXT_TEAL} type={SvgType.VERY_LARGE_ICON} />,
    },
    {
      value: RssLayout.LEFT,
      label: t('EmailComposer.RssModal.Layout.Left.Label'),
      subText: t('EmailComposer.RssModal.Layout.Left.SubText'),
      renderItemIcon: () => <Svg name={SvgNames.rssImageLeft} fill={SvgColor.TEXT_TEAL} type={SvgType.VERY_LARGE_ICON} />,
    },
    {
      value: RssLayout.RIGHT,
      label: t('EmailComposer.RssModal.Layout.Right.Label'),
      subText: t('EmailComposer.RssModal.Layout.Right.SubText'),
      renderItemIcon: () => <Svg name={SvgNames.rssImageRight} fill={SvgColor.TEXT_TEAL} type={SvgType.VERY_LARGE_ICON} />,
    },
  ]

  const [state, setState] = useState<RssState>(
    customFieldValues
      ? {
          ...customFieldValues,
          selectedLayoutOption: layoutOptions.find((option) => option.value === customFieldValues?.selectedLayoutOption?.value),
        }
      : {
          ...defaultState,
          selectedLayoutOption: layoutOptions[0],
        }
  )

  const {
    rssFeedItems,
    includeDate,
    includeAuthor,
    includeReadMoreLink,
    formatOption,
    numItemsToDisplay,
    selectedLayoutOption,
    url,
    textAlignment,
    isValidUrl,
    id,
    plainText,
  } = state

  const updateState = (toUpdate: Partial<RssState>) => setState((curState) => ({ ...curState, ...toUpdate }))

  const getRssPlainText = async () => {
    const { data, errors } = await getRssFeedPlainTextQuery({
      rssFeedPlainTextArgs: {
        url,
        includeDate,
        includeAuthor,
        includeReadMore: includeReadMoreLink,
        numItemsToDisplay: parseInt(numItemsToDisplay?.value ?? ''),
        format: `${formatOption}`,
      },
    })

    if (data?.getRssFeedPlainText) {
      updateState({ plainText: data.getRssFeedPlainText.text, isValidUrl: true, url })
    } else if (errors) {
      logNewRelicError(errors)
    }
  }

  const validateAndGetRssFeed = async (url: string) => {
    if (url === '') {
      return
    }

    const { data, errors } = await getRssFeedQuery(url)

    if (data?.getRssFeed) {
      const rssFeedItems = data.getRssFeed.feedItems.map((item) => ({
        url: item?.url,
        author: item?.author,
        description: item?.description,
        content: item?.content,
        title: item?.title,
        link: item?.link,
        date: item?.date,
      }))
      updateState({ rssFeedItems, url, isValidUrl: true })
    } else if (errors) {
      updateState({ isValidUrl: false })
      logNewRelicError(errors)

      return { errorMessageKey: t('EmailComposer.RssModal.Input.Label.Error') }
    }
  }

  useEffect(() => {
    if (customFieldValues) {
      validateAndGetRssFeed(customFieldValues.url)
    }
  }, [])

  const handleInsert = () => {
    const rssHtml = renderToString(
      <RssBlock
        id={id}
        rssFeedItems={rssFeedItems.slice(0, parseInt(numItemsToDisplay?.value ?? ''))}
        includeReadMoreLink={includeReadMoreLink}
        includeDate={includeDate}
        includeAuthor={includeAuthor}
        selectedLayoutOption={selectedLayoutOption}
        textAlignment={textAlignment}
        formatOption={formatOption as RssFormat}
        isPlainText={isPlainText}
      />
    )

    onAction({
      type: 'html',
      value: {
        html: isPlainText ? plainText ?? '' : rssHtml,
        customFields: {
          rssCustomFields: {
            ...state,
            selectedLayoutOption: {
              value: selectedLayoutOption?.value ?? RssLayout.TOP,
            },
          },
        },
      },
    })
  }

  return (
    <div className={rootClass}>
      <RssModal
        url={url}
        id={id}
        rssFeedItems={rssFeedItems ?? []}
        formatOption={formatOption}
        numItemsToDisplay={numItemsToDisplay?.value}
        includeDate={includeDate}
        includeAuthor={includeAuthor}
        includeReadMoreLink={includeReadMoreLink}
        textAlignment={textAlignment}
        selectedLayoutOption={selectedLayoutOption}
        layoutOptions={layoutOptions}
        isValidUrl={isValidUrl}
        updateState={updateState}
        validateAndGetRssFeed={validateAndGetRssFeed}
        onClose={onClose}
        handleInsert={handleInsert}
        isPlainText={isPlainText}
        plainText={plainText}
        isLandingPage={isLandingPage}
        getRssPlainText={getRssPlainText}
      />
    </div>
  )
}

export default RssModalContainer
