import React, { FC, ReactNode, useState } from 'react'

import classNames from 'classnames'

import { WebinarType } from '@complex/ContactSegments/segmentInfoHover.utils'
import { ALL_ACTON_CONTACTS_SEGMENT_NAME } from '@complex/ContactSegments/SegmentInfoHoverCard/utils/SegmentInfoHoverCard.utils'
import { useSegmentNameWithInfoHoverQueries } from '@complex/ContactSegments/SegmentNameWithInfoHover/SegmentNameWithInfoHover.GraphQL'
import InfoHoverCardWrapper from '@complex/ListPickerModalV2/components/InfoHoverCardWrapper/InfoHoverCardWrapper'
import InfoHoverCard from '@components/InfoHoverCard/InfoHoverCard'
import InfoHoverHeader from '@components/InfoHoverHeader/InfoHoverHeader'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ItemType } from '@utils/categorization'
import { Segment } from '@utils/contactSegments/contactSegments.utils'
import { relativeDate } from '@utils/date'

import './SegmentNameWithInfoHover.css'

interface Props {
  className?: string
  dataTest?: string
  segmentData: Segment
  cardBody: ReactNode
  cardFooter?: ReactNode
  itemType?: ItemType
}

const rootClass = 'segment-name-with-info-hover'

const SegmentNameWithInfoHover: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '', segmentData, cardBody, cardFooter, itemType } = props

  const { t } = useTranslation()

  const { getWebinarCompletedRequest } = useSegmentNameWithInfoHoverQueries()

  const isAllContactsSegment = segmentData.name === ALL_ACTON_CONTACTS_SEGMENT_NAME
  const isWebinarSubmission = itemType === ItemType.WEBINAR_SUBMISSION

  const [showFooter, setShowFooter] = useState<boolean>(!isWebinarSubmission)

  const onCardOpenChange = (state: boolean) => {
    if (isWebinarSubmission && state) {
      const webinarId = 'webinarId' in segmentData ? (segmentData.webinarId as string) : ''
      const webinarType =
        segmentData.subTypeDTO && segmentData.subTypeDTO[0] && ((segmentData.subTypeDTO[0].name as string).toUpperCase() as WebinarType)
      if (webinarType) {
        getWebinarCompletedRequest(webinarId, webinarType)
          .then((completed) => setShowFooter(completed))
          .catch(() => setShowFooter(false))
      }
    }
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <InfoHoverCardWrapper>
        {(loading) => (
          <div className={`${rootClass}__info-hover-icon`}>
            <InfoHoverCard
              dataTest={`${dataTest}-info-hover`}
              isHidden
              loading={loading}
              header={
                <InfoHoverHeader
                  title={
                    <Typography
                      text={segmentData.name}
                      weight={TextWeight.MEDIUM}
                      className={classNames(`${rootClass}__header-title`, { ['ellip-clamp']: true })}
                    />
                  }
                  subtitle={
                    segmentData.isEditable && segmentData.updatedTime ? (
                      <>
                        <Typography
                          text={t('Last modified:')}
                          type={TextType.BODY_TEXT_LIGHT_TINY}
                          weight={TextWeight.BOLD}
                          inline
                          className={`${rootClass}__header-subtitle-main`}
                        />
                        <Typography
                          text={relativeDate(segmentData.updatedTime, true)}
                          type={TextType.BODY_TEXT_LIGHT_TINY}
                          className={`${rootClass}__header-subtitle-secondary`}
                          inline
                        />
                      </>
                    ) : undefined
                  }
                />
              }
              footer={showFooter && cardFooter}
              openDelay={350}
              closeDelay={150}
              sideOffset={6}
              className={classNames(segmentData.isEditable ? `${rootClass}__regular` : `${rootClass}__system`, `${className}__info-hover`)}
              scrollAreaClassName={segmentData.isEditable || isAllContactsSegment ? `${rootClass}__info-hover-scroll` : undefined}
              onOpenChange={(open) => onCardOpenChange(open)}
            >
              {cardBody}
            </InfoHoverCard>
          </div>
        )}
      </InfoHoverCardWrapper>
    </div>
  )
}

export default SegmentNameWithInfoHover
