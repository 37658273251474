import React from 'react'
import { SvgNames } from '@components/Svg'
import Svg, { SvgColor, SvgProps } from '@components/Svg/Svg'

interface Address {
  name?: string
  street?: string
  street2?: string
  state?: string
  city?: string
  zip?: string
  country?: string
}

export const renderSelectOptionIcon = (isSelected?: boolean, svgProps?: Partial<SvgProps>) => {
  const { name: svgName = SvgNames.buildings, fill = SvgColor.BUTTON_GRAY, type, containerType } = svgProps ?? {}

  return (
    <div>
      <Svg name={svgName} fill={isSelected ? SvgColor.TEXT_TEAL : fill} type={type} containerType={containerType} />
    </div>
  )
}

export const parseAddress = (svgProps?: Partial<SvgProps>, address?: Address) => {
  const { name = '', street, street2, city, state, zip, country } = address ?? {}

  const subText =
    street || city || state ? `${street}${street2 ? ` ${street2}` : ''}${street ? ', ' : ''}${city}${state ? ', ' : ''}${state} ${zip}` : undefined
  return {
    label: name,
    value: name,
    renderItemIcon: (isSelected?: boolean) => renderSelectOptionIcon(isSelected, svgProps),
    subText,
    extraOptions: {
      street: street,
      street2: street2,
      city: city,
      state: state,
      zip: zip,
      country: country,
    },
  }
}
