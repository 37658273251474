import React, { FC, useContext, useEffect, useState } from 'react'

import classNames from 'classnames'

import Caution from '@components/Caution/Caution'
import ContentWarning, {
  ItemAsset,
  ItemAssetState,
  ItemAssetType,
} from '@components/DependenciesContentModal/components/ContentWarning/ContentWarning'
import { renderLoader } from '@components/Loader/Loader'
import Modal, { ModalBody } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import ModalFooterV2 from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import ModalHeaderV2 from '@components/Modal/components/ModalHeaderV2/ModalHeaderV2'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import TooltipButton from '@components/TooltipButton/TooltipButton'
import { rootContext, useTranslation } from '@const/globals'
import { checkFieldUsage } from '@src/pages/datamanagement/components/CrmContacts/CrmContactUtil'
import { DataManagementContext } from '@src/pages/datamanagement/context/DataManagementContext'
import { useFormService } from '@utils/hooks/microservices/useFormService'

import './FieldMappingUsageModal.css'

interface FieldMappingUsageModalProps {
  className?: string
  dataTest?: string
}

const rootClass = 'field-mapping-usage-modal'

const FieldMappingUsageModal: FC<FieldMappingUsageModalProps> = (props: FieldMappingUsageModalProps) => {
  const { dataTest = rootClass, className = '' } = props

  const {
    update,
    values: { fieldMappingUsageWarning },
  } = useContext(DataManagementContext)

  const [isBeingUsed, setIsBeingUsed] = useState(true)

  const { t } = useTranslation()

  const { getFormsByColumnNameRequest } = useFormService()

  const { fieldMapping, forms = [], loading = false } = { ...fieldMappingUsageWarning }

  const onClose = () => {
    update('fieldMappingUsageWarning', undefined)
  }

  const onRefresh = async () => {
    if (fieldMappingUsageWarning && fieldMapping) {
      const isBeingUsed = await checkFieldUsage(fieldMapping, update, getFormsByColumnNameRequest, fieldMappingUsageWarning)
      setIsBeingUsed(isBeingUsed)
    }
  }

  useEffect(() => {
    if (!fieldMappingUsageWarning) {
      setIsBeingUsed(true)
    }
  }, [fieldMappingUsageWarning])

  if (loading) {
    return renderLoader('loader--white-background')
  }

  const itemAsset: ItemAsset = {
    objectId: fieldMapping?.displayName ?? '',
    body: forms.map((form) => ({
      isCritical: true,
      name: form.name ?? '',
      path: `${rootContext}/content/forms/${form.id}/details`,
      state: ItemAssetState.NONE,
      type: ItemAssetType.FORM,
    })),
  }

  const header = <ModalHeaderV2 headerType={ModalHeaderType.Form} headerText={t('Dependent Content Warning')} />

  return (
    <Modal className={classNames(rootClass, className)} dataTest={dataTest} isOpen={!!fieldMappingUsageWarning} header={header}>
      <ModalBody>
        <Caution
          dataTest={`${dataTest}__caution`}
          className={`${rootClass}__body-caution`}
          message={t(
            isBeingUsed
              ? 'The field that you wish to delete is in use. Please review the details below.'
              : 'The field has no more dependent content and can be deleted.'
          )}
        />
        {isBeingUsed && (
          <ContentWarning
            dataTest={`${dataTest}__content-warning`}
            key={fieldMapping?.columnIndex}
            asset={itemAsset}
            title={fieldMapping?.displayName ?? ''}
            customCautionInfoMessage={t(
              'This field can not be deleted because it is in use in the forms listed below. Remove this field or convert it to a form field instead of a contact field.'
            )}
            customAction={
              <TooltipButton dataTest={`${dataTest}__refresh-button`} title={t('Refresh')} onClick={onRefresh}>
                <Svg name={SvgNames.refresh} type={SvgType.ICON} />
              </TooltipButton>
            }
            hideCheckbox
          />
        )}
      </ModalBody>
      <ModalFooterV2
        footerType={ModalFooterType.Form}
        buttons={{
          actionButtonOnClick: onClose,
          actionButtonLabel: t(isBeingUsed ? 'Close' : 'Continue'),
        }}
      />
    </Modal>
  )
}

export default FieldMappingUsageModal
