import React, { ChangeEvent, FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/Button'
import ButtonIcon, { ButtonIconSize, ButtonIconType } from '@components/ButtonIcon/ButtonIcon'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TypographyProps } from '@components/Typography/Typography'
import { useWindowBlur } from '@utils/hooks/useWindowBlur'

import './InlineEditing.css'

export enum INLINE_EDITING_TRIGGER {
  BUTTON = 'BUTTON',
  ICON = 'ICON',
}

export enum INLINE_EDITING_TYPE {
  GRID = 'grid',
  FLEX = 'flex',
}

export interface InlineEditingProps {
  children?: ReactNode
  name?: string
  highlightOnFocus?: boolean
  title: string
  maxWidth?: number
  minWidth?: number
  triggerCancel?: boolean
  maxLength?: number
  disabled?: boolean
  className?: string
  dataTest?: string
  onSave?: (value: string) => void
  onCancel?: () => void
  onChange: (value: string) => void
  inlineEditingTrigger?: INLINE_EDITING_TRIGGER
  triggerTooltipText?: string
  type?: INLINE_EDITING_TYPE
  typographyProps?: TypographyProps
}

const rootClass = 'inline-editing'

const InlineEditing: FC<InlineEditingProps> = ({
  children,
  className = '',
  title,
  maxWidth,
  minWidth,
  triggerCancel,
  dataTest = rootClass,
  onSave,
  onCancel,
  onChange,
  name,
  highlightOnFocus,
  maxLength,
  disabled,
  inlineEditingTrigger = INLINE_EDITING_TRIGGER.BUTTON,
  type = INLINE_EDITING_TYPE.GRID,
  triggerTooltipText,
  typographyProps,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [currentValue, setCurrentValue] = useState<string>(title)
  const [reachInputMaxWidth, setReachInputMaxWidth] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const closeButtonRef = useRef<HTMLButtonElement>(null)

  useWindowBlur(isEditing, setIsEditing)

  const onCancelClick = useCallback(() => {
    setIsEditing(false)
    onCancel && onCancel()
  }, [onCancel])

  useEffect(() => {
    if (triggerCancel && isEditing) {
      onCancelClick()
    }
  }, [triggerCancel, onCancelClick, isEditing])

  useEffect(() => {
    setCurrentValue(title)
    setIsDisabled(!title.trim().length)
  }, [title, isEditing])

  const onSaveClick = useCallback(() => {
    setIsEditing(false)
    onSave && onSave(currentValue)
  }, [onSave, currentValue])

  const startEditing = useCallback(() => setIsEditing(true), [])

  const handleFocus = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      highlightOnFocus && e.target.select()
    },
    [highlightOnFocus]
  )

  useEffect(() => {
    if (isEditing) {
      const handleClickOutside = (event: MouseEvent) => {
        if (!wrapperRef.current?.contains(event.target as Node) && !closeButtonRef.current?.contains(event.target as Node)) {
          isDisabled ? onCancelClick() : onSaveClick()
        }
      }

      document.addEventListener('mousedown', handleClickOutside)

      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
  }, [isDisabled, onCancelClick, onSaveClick, isEditing])

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (isDisabled && e.key === 'Tab') {
        e.preventDefault()
      }
      if (!isDisabled && e.key === 'Enter') {
        onSaveClick()
      }
      if (e.key === 'Escape') {
        onCancelClick()
      }
    },
    [isDisabled, onCancelClick, onSaveClick]
  )

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      setIsDisabled(!value.trim().length)
      onChange(value)
      setCurrentValue(value)
      setReachInputMaxWidth(inputRef.current?.offsetWidth === maxWidth)
    },
    [onChange, maxWidth]
  )

  const handleTabFromCancelButton = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'Tab') {
      e.preventDefault()
      inputRef.current?.focus()
    }
  }, [])

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {isEditing ? (
        <div className={classNames(`${rootClass}__container`, `${rootClass}__container-${type}`)}>
          <div
            className={classNames(`${rootClass}__input--container`, {
              [`${rootClass}__input--gradient`]: reachInputMaxWidth,
            })}
            style={{ maxWidth, minWidth }}
            ref={wrapperRef}
          >
            <span className={classNames(`${rootClass}__input--size`, { [`${rootClass}__input--max-length`]: !!maxLength })}>{currentValue}</span>
            <input
              ref={inputRef}
              className={classNames(`${rootClass}__input`, `${className}__input`, { [`${rootClass}__input--max-length`]: !!maxLength })}
              name={name}
              value={currentValue}
              maxLength={maxLength}
              data-test={`${dataTest}-input`}
              onChange={handleOnChange}
              onKeyDown={handleKeyDown}
              onFocus={handleFocus}
              disabled={disabled}
              /* eslint-disable-next-line jsx-a11y/no-autofocus */
              autoFocus
            />
            {maxLength && (
              <div className={`${rootClass}__character-count`}>
                <Typography type={TextType.BODY_TEXT_GRAY_SMALL} text={`${currentValue.length}/${maxLength}`} />
              </div>
            )}
          </div>
          <div className="flex-align-center push-right-x2">
            <ButtonIcon
              icon={SvgNames.check}
              disabled={isDisabled}
              size={ButtonIconSize.SMALL}
              onClick={onSaveClick}
              className="push-left"
              dataTest={`${dataTest}-save-button`}
            />
            <ButtonIcon
              icon={SvgNames.close}
              size={ButtonIconSize.SMALL}
              type={ButtonIconType.GRAY}
              onClick={onCancelClick}
              onKeyDown={handleTabFromCancelButton}
              dataTest={`${dataTest}-cancel-button`}
              register={closeButtonRef}
            />
          </div>
        </div>
      ) : inlineEditingTrigger === INLINE_EDITING_TRIGGER.BUTTON ? (
        <Button
          buttonType={ButtonType.FLOAT}
          className={classNames(`${rootClass}__input--button`, `${className}__input--button`)}
          onClick={startEditing}
          disabled={disabled}
          dataTest={`${dataTest}-title-button`}
        >
          <TextWithTooltipOnEllip
            typographyProps={{
              text: title,
              className: `${rootClass}__title`,
              lineHeight: LineHeight.LARGER,
              type: TextType.PAGE_HEADER_SEMI_BOLD_LARGE,
              ...typographyProps,
            }}
            tooltipProps={{
              position: 'top',
            }}
          />
          {!disabled && (
            <Svg name={SvgNames.pencil} type={SvgType.ICON} className={`${rootClass}__input--button-icon`} dataTest={`${dataTest}-edit-icon`} />
          )}
        </Button>
      ) : (
        <div className={`${rootClass}__trigger-${inlineEditingTrigger}`}>
          <TextWithTooltipOnEllip
            typographyProps={{
              text: title,
              className: `${rootClass}__title`,
              lineHeight: LineHeight.LARGER,
              type: TextType.PAGE_HEADER_SEMI_BOLD_LARGE,
              ...typographyProps,
            }}
            tooltipProps={{
              position: 'top',
            }}
          />
          {!disabled && (
            <Tooltip
              trigger={
                <ButtonIcon
                  icon={SvgNames.pencil}
                  type={ButtonIconType.LIGHT_TEAL_ON_HOVER}
                  onClick={startEditing}
                  iconSize={SvgType.ICON}
                  className={`${rootClass}__input--button-icon`}
                  dataTest={`${dataTest}-edit-icon`}
                  hideBorder
                />
              }
            >
              {triggerTooltipText}
            </Tooltip>
          )}
        </div>
      )}
      {!isEditing && children}
    </div>
  )
}

export default InlineEditing
