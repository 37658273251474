import { ProgramStepsAndDetailsState } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/ProgramStepsAndDetails'

export enum ProgramStepsAndDetailsTabs {
  PROGRAM_STEPS = 'steps',
  PROGRAM_DETAILS = 'details',
}

export const programStepsAndDetailsInitialState = (): ProgramStepsAndDetailsState => {
  const searchParams = new URLSearchParams(window.location.search)
  const currentTab = searchParams.get('tab') || ProgramStepsAndDetailsTabs.PROGRAM_STEPS
  return {
    currentTab,
    showDeleteModal: false,
    showCancelConfirmation: false,
  }
}
