import React, { FC } from 'react'

import classNames from 'classnames'

import SpinnerStatus from '@components/SpinnerStatus/SpinnerStatus'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ProgramStatusOptions } from '@src/pages/programs/dashboard/components/ProgramStatus/ProgramStatus.constants'
import { ProgramState } from '@utils/program/program.constants'

import './ProgramStatus.css'

export interface ProgramStatusProps {
  programState: ProgramState
  programStarting?: boolean
  isEditing?: boolean
  className?: string
  dataTest?: string
}

const rootClass = 'program-status'

const ProgramStatus: FC<ProgramStatusProps> = (props: ProgramStatusProps) => {
  const { programState, programStarting, isEditing = false, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()

  const currentOption = ProgramStatusOptions[programState]

  const renderLabelImage = () =>
    isEditing ? (
      <Svg name={SvgNames.pencil} type={SvgType.ICON} className={`${rootClass}__label-image`} />
    ) : programState === ProgramState.ERROR ? (
      <Svg name={SvgNames.warningSolid} type={SvgType.ICON} className={`${rootClass}__label-image`} />
    ) : (
      <div
        className={classNames(`${rootClass}__circle`, `${rootClass}__label-image`, `${rootClass}__circle-${programState}`)}
        data-test={`${dataTest}-circle`}
      >
        &nbsp;
      </div>
    )

  const programStatusText = isEditing ? t('Unsaved changes') : currentOption.main

  return (
    <div
      className={classNames(rootClass, className, {
        [`${rootClass}__editing`]: isEditing,
      })}
    >
      {programStarting ? (
        <SpinnerStatus text={t('Starting Program...')} />
      ) : (
        <>
          {renderLabelImage()}
          <Typography text={programStatusText} weight={TextWeight.MEDIUM} />
        </>
      )}
    </div>
  )
}

export default ProgramStatus
