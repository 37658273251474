import React from 'react'

import DonutChartSimple from '@components/DonutChartSimple/DonutChartSimple'
import { AutomatedProgramCountsResponse, Program, ProgramCounts } from '@graphql/types/query-types'
import { EarlyExitSegmentInfo, ProgramExitType } from '@src/pages/programs/dashboard/ProgramDashboard.constants'
import { getConditionalText, getDisplayText } from '@src/pages/programs/edit/components/ProgramEarlyExitConditions/ProgramEarlyExitConditions.utils'
import { SEGMENT_OPTIONS } from '@src/pages/programs/edit/components/ProgramFlow/components/ConditionalSelect/segmentOptions'
import { getFormattedNumber } from '@utils/numbers'
import { hasAOContactsSource } from '@utils/program/program'

import { findListName, findMessageName } from '../ProgramSteps/ProgramSteps.utils'

const getTabPercent = (rawPercent: number) => {
  if (rawPercent === Math.floor(rawPercent)) {
    return rawPercent
  }

  return rawPercent < 1 || (rawPercent > 99 && rawPercent < 100) ? rawPercent.toFixed(2) : Math.round(rawPercent)
}

export const getInProgramLabelContent = (currentlyInProgram: number, totalContacts: number) => {
  const secondary = currentlyInProgram === 0 ? '0' : `${getTabPercent((currentlyInProgram / totalContacts) * 100)}%`
  return {
    primary: getFormattedNumber(currentlyInProgram),
    secondary,
    split: (
      <DonutChartSimple
        chartColor={'#50BEFF'}
        backgroundColor={'#E9F7FF'}
        percentage={parseInt(secondary)}
        className={'program-performance__donut'}
      />
    ),
  }
}

export const getEarlyExitLabelContent = (earlyExitCount: number, totalContacts: number) => {
  const primary = getFormattedNumber(earlyExitCount)
  const secondary = totalContacts === 0 || primary === '0' ? '0' : `${getTabPercent((earlyExitCount / totalContacts) * 100)}%`
  return {
    primary,
    secondary,
    split: (
      <DonutChartSimple
        chartColor={'#EB8126'}
        backgroundColor={'#FDEBDC'}
        percentage={parseInt(secondary)}
        className={'program-performance__donut'}
      />
    ),
  }
}

export const getStandardExitLabelContent = (programCounts: ProgramCounts, totalContacts: number) => {
  const primary = getFormattedNumber(
    programCounts.doneCount - programCounts.earlyExitCount < 0 ? 0 : programCounts.doneCount - programCounts.earlyExitCount
  )
  const secondary =
    totalContacts === 0 || primary === '0'
      ? '0'
      : `${getTabPercent(((programCounts.doneCount - programCounts.earlyExitCount) / totalContacts) * 100)}%`

  return {
    primary,
    secondary,
    split: (
      <DonutChartSimple
        chartColor={'#D286B6'}
        backgroundColor={'#FEEBE7'}
        percentage={parseInt(secondary)}
        className={'program-performance__donut'}
      />
    ),
  }
}

export const getAllContactsLabelContent = (totalContacts: number) => {
  const secondary = totalContacts === 0 ? '0' : '100%'
  return {
    primary: getFormattedNumber(totalContacts),
    secondary,
    split: (
      <DonutChartSimple
        chartColor={'#7B9FED'}
        backgroundColor={'#F2F7FF'}
        percentage={parseInt(secondary)}
        className={'program-performance__donut'}
      />
    ),
  }
}

export const getEarlyExitSegmentInfo = (program: Program, programCounts: AutomatedProgramCountsResponse) => {
  const hasActOnContacts = hasAOContactsSource(program)
  return programCounts.exitConditions
    .map((exitCondition): EarlyExitSegmentInfo => {
      const getRadioSelection = (update: boolean, listId: string) => {
        if (update) {
          return hasActOnContacts ? 'appended to' : 'copied/updated to'
        } else {
          return listId.length > 0 ? 'appended to' : ''
        }
      }

      const { localSegmentDetails, sources, messages } = program
      const localSegment = localSegmentDetails.find((lsd) => lsd.id === exitCondition.evaluationId)
      const exitChoice = program.exit.exitChoices.find((exitChoice) => exitChoice.srcId === exitCondition.evaluationId.replace(/^n-/, ''))
      const exitList = exitChoice ? sources.find((source) => source.baseId === exitChoice.listId)?.name ?? '' : ''
      const secondary = exitChoice
        ? {
            secondAction: getRadioSelection(exitChoice.update ?? false, exitChoice.listId),
            secondExitType: 'segment' as ProgramExitType,
            secondList: exitList,
          }
        : {}

      if (localSegment) {
        const segmentOption = SEGMENT_OPTIONS.find((segmentOption) => segmentOption.type === localSegment?.name)
        const condition = segmentOption?.text.replace(/If prospect/, '').toLowerCase() ?? ''
        const srcId = localSegment.idset ? localSegment.idset[0] : '-1'
        const name = findMessageName(srcId, messages)
        const exitType = 'email' as ProgramExitType
        return {
          ...secondary,
          id: exitCondition.evaluationId,
          name,
          condition,
          exitType,
        }
      } else {
        const source = program.localSegmentDetails.find((source) => exitCondition.evaluationId.includes(source.id))
        const conditionalText = getConditionalText(exitCondition.not, source)
        const displayText = getDisplayText(program, source?.id ?? '')
        const messageText = displayText.length ? displayText[0].text : ''

        const listId = exitCondition.evaluationId.replace(/^n-/, '')
        const listName = findListName(listId, sources)
        const name = listName !== '' ? listName : source?.name.includes('MESSAGE') ? messageText : '[Source not Found]'
        return {
          ...secondary,
          id: exitCondition.evaluationId,
          name,
          condition: conditionalText,
          exitType: 'segment',
        }
      }
    })
    .filter((segment) => segment.name !== '')
}
