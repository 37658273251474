export const MORE_INFO = 'https://connect.act-on.com/hc/en-us/articles/360023943233'

export const getBannerInfo = (crmType: string) => {
  switch (crmType) {
    case 'Salesforce':
      return {
        text: 'Optimize your fields for {{connectorType}}. Import and map {{connectorType}} Contact & Lead fields to help Act-On push data correctly to your CRM. This may reset existing field mappings. You can preview changes before saving.',
        linkText: 'Optimize for {{connectorType}}',
      }
    case 'NetSuite':
    case 'MS Dynamics':
    case 'Sugar':
      return {
        text: 'Optimize your fields for {{connectorType}}. Select {{connectorType}} fields to import and map to Act-On. This will help push data correctly to your CRM.',
        linkText: 'Optimize for {{connectorType}}',
      }
    default:
      return {
        text: '',
        linkText: '',
      }
  }
}

export const CRM_FIELDS = new Map<string, string>([
  ['', 'None'],
  ['vc.EMAIL', 'Email Address'],
  ['vc.FIRSTNAME', 'First Name'],
  ['vc.MIDDLENAME', 'Middle Name'],
  ['vc.LASTNAME', 'Last Name'],
  ['vc.COMPANY', 'Company'],
  ['vc.JOBTITLE', 'Title'],
  ['vc.DEPT', 'Department'],
  ['vc.BIZ_PHONE', 'Business Phone'],
  ['vc.BIZ_FAX', 'Business Fax'],
  ['vc.CELL_PHONE', 'Mobile Phone'],
  ['vc.HOME_PHONE', 'Home Phone'],
  ['vc.HOME_FAX', 'Home Fax'],
  ['vc.BIZ_WEB', 'Business Website'],
  ['vc.HOME_WEB', 'Personal Website'],
  ['vc.BIZ_STREET', 'Business Address - Street'],
  ['vc.BIZ_CITY', 'Business Address - City'],
  ['vc.BIZ_STATE', 'Business Address - State'],
  ['vc.BIZ_ZIP', 'Business Address - Postal Code'],
  ['vc.BIZ_COUNTRY', 'Business Address - Country'],
  ['vc.HOME_STREET', 'Home Address - Street'],
  ['vc.HOME_CITY', 'Home Address - City'],
  ['vc.HOME_STATE', 'Home Address - State'],
  ['vc.HOME_ZIP', 'Home Address - Postal Code'],
  ['vc.HOME_COUNTRY', 'Home Address - Country'],
  ['vc.PICTURE', 'Picture'],
  ['vc.TIMEZONE', 'Timezone'],
])
