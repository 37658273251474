import { WebinarType } from '../../utils/EventModal.utils'

export enum CalendarOption {
  APPLE = 'apple',
  GOOGLE = 'google',
  OUTLOOK = 'outlook',
  YAHOO = 'yahoo',
}

export const getVideoConferenceLabel = (selectedVideoConference: string) =>
  `Join ${selectedVideoConference === WebinarType.EXTERNAL_EVENT_LINK ? 'online' : selectedVideoConference}`

export const getVideoConferenceIconName = (selectedVideoConference: string) => {
  if (selectedVideoConference === WebinarType.ZOOM_MEETING) {
    return 'zoom-logo'
  } else if (selectedVideoConference === WebinarType.GOOGLE_MEET) {
    return 'google-meet-logo'
  } else if (selectedVideoConference === WebinarType.WEBEX_MEETING) {
    return 'webex-logo'
  } else {
    return 'webinar-outline'
  }
}
