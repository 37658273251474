import React, { FC, useRef, useEffect, useState, useCallback } from 'react'

import classNames from 'classnames'

import Tooltip, { TooltipProps } from '@components/Tooltip/Tooltip'
import Typography, { TypographyProps } from '@components/Typography/Typography'

interface Props {
  typographyProps: TypographyProps
  tooltipProps?: Omit<TooltipProps, 'trigger'>
  forceTooltip?: boolean
  className?: string
}

const TextWithTooltipOnEllip: FC<Props> = ({ typographyProps, tooltipProps, forceTooltip, className = '' }) => {
  const [isEllip, setIsEllip] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)

  const detectIsEllip = useCallback(() => {
    const textContainer = ref.current
    setIsEllip(!!textContainer && textContainer.scrollWidth > textContainer.clientWidth)
  }, [])

  useEffect(() => {
    detectIsEllip()
  }, [typographyProps.text, detectIsEllip])

  useEffect(() => {
    window.addEventListener('resize', detectIsEllip)

    return () => {
      window.removeEventListener('resize', detectIsEllip)
    }
  }, [detectIsEllip])

  const Text = <Typography {...typographyProps} reference={ref} className={classNames(typographyProps.className, 'ellip')} />

  if (!isEllip && !forceTooltip) {
    return Text
  }

  return (
    <Tooltip className={className} trigger={Text} ellipOnTrigger alwaysShowOnHover inline={false} {...tooltipProps}>
      {tooltipProps?.children ?? typographyProps.text}
    </Tooltip>
  )
}

export default TextWithTooltipOnEllip
