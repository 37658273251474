import { useCallback } from 'react'

import { GraphQLError } from 'graphql'

import { Status } from '@components/StatusToast/StatusToast'
import { SubscriptionCategoryGq } from '@graphql/types/query-types'
import { caseInsensitiveAlphabeticSort } from '@utils/array'
import { EmailComposerAPI, EmailSender } from '@utils/composer/context/EmailComposer.context'
import { useEmailComposerRequests } from '@utils/composer/emailComposer/GraphQL/EmailComposerRequests.graphQL'
import { useTranslation } from '@utils/const/globals'
import { logNewRelicError } from '@utils/new-relic.utils'

export const useFetchSettingsData = (userId: string, update: EmailComposerAPI['update'], updateModal: EmailComposerAPI['updateModal']) => {
  const { t } = useTranslation()
  const {
    loadFromAddressesRequest,
    getAnalyticsSettingsRequest,
    getCampaignsRequest,
    getSubscriptionCategoriesRequest,
    getMarketingUserRequest,
    getAllSalesUsersRequest,
    getSavedRowCategoriesRequest,
  } = useEmailComposerRequests()

  const handleError = (errors: readonly GraphQLError[], description: string) => {
    logNewRelicError(errors, description)
    updateModal('statusToast', { message: t('EmailComposer.Settings.FetchError'), status: Status.FAIL })
  }

  const loadSenders = async () => {
    const { data, errors } = await loadFromAddressesRequest({ sortColumn: 'name' })
    if (errors) {
      handleError(errors, 'Fetching email senders for composer')
    } else if (data) {
      const senders: EmailSender[] = data.loadFromAddressesPage
        .filter((sender) => sender.isVerified)
        .map((sender) => ({
          name: sender.name ?? '',
          email: sender.email ?? '',
          uuid: sender.uuid ?? '',
          isDefault: sender.isDefault ?? false,
        }))
      update({ emailSenders: senders.sort((a, b) => caseInsensitiveAlphabeticSort(a.name, b.name)) })
    }
  }

  const loadSubscriptionCategories = async () => {
    const { data, errors } = await getSubscriptionCategoriesRequest({})
    if (errors) {
      handleError(errors, 'Fetching email senders for composer')
    } else if (data) {
      const categories = data.getSubscriptionCategories.filter((category) => !!category) as SubscriptionCategoryGq[]
      update({ subscriptionCategories: categories.sort((a, b) => caseInsensitiveAlphabeticSort(a.name, b.name)) })
    }
  }

  const loadCampaigns = async () => {
    const { data, errors } = await getCampaignsRequest({})
    if (errors) {
      handleError(errors, 'Fetching campaigns for composer')
    } else if (data) {
      const campaigns = [...data.campaigns].filter((campaign) => !!campaign.id)
      update({ campaigns: campaigns.sort((a, b) => caseInsensitiveAlphabeticSort(a.name ?? '', b.name ?? '')) })
    }
  }

  const loadDomains = async () => {
    const { data, errors } = await getAnalyticsSettingsRequest({})
    if (errors) {
      handleError(errors, 'Fetching analytics domains for composer')
    } else if (data) {
      const domains = [...data.getAnalyticsSettings.domains].filter((domain) => !!domain)
      update({ analyticsDomains: domains.sort((a, b) => caseInsensitiveAlphabeticSort(a, b)) })
    }
  }

  const getLaunchApproval = async () => {
    const { data: marketingUserData, errors: marketingUserDataErrors } = await getMarketingUserRequest()
    if (marketingUserDataErrors) {
      handleError(marketingUserDataErrors, 'Fetching marketing user for composer')
    } else if (marketingUserData?.getMarketingUser) {
      const launchApproval = marketingUserData?.getMarketingUser?.launchApproval
      update({ launchApproval })
    } else {
      const { data: allSalesUsersData, errors: allSalesUsersErrors } = await getAllSalesUsersRequest()
      if (allSalesUsersErrors) {
        handleError(allSalesUsersErrors, 'Fetching sales users for composer')
      } else {
        const launchApproval = allSalesUsersData?.getAllSalesUsers?.users?.find((user) => user?.id === userId)?.launchApproval
        update({ launchApproval })
      }
    }
  }

  const loadSaveRowCategories = async () => {
    const { data, errors } = await getSavedRowCategoriesRequest()
    if (errors) {
      handleError(errors, 'Fetching saved row categories for composer')
    } else if (data) {
      const savedRowCategories = data.getEmailComposerRowsCategories
      update({ savedRowCategories })
    }
  }

  return useCallback(async () => {
    if (!Promise.allSettled) {
      Promise.allSettled = function (promises: any) {
        return Promise.all(
          promises.map((p: any) =>
            Promise.resolve(p).then(
              (value) => ({
                status: 'fulfilled',
                value,
              }),
              (reason) => ({
                status: 'rejected',
                reason,
              })
            )
          )
        )
      }
    }
    await Promise.allSettled([
      loadSenders(),
      loadDomains(),
      loadCampaigns(),
      loadSubscriptionCategories(),
      loadSaveRowCategories(),
      getLaunchApproval(),
    ])
  }, [])
}
