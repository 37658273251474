import { Action } from '@interface/Action'

import actionTypes from './actionTypes'

export type SetIFrameVisibilityAction = Action<boolean>

export function setIframeVisibility(visible: boolean): SetIFrameVisibilityAction {
  return {
    type: actionTypes.setIframeVisibility,
    payload: visible,
  }
}

export interface FrameViewActions {
  setIframeVisibility(visible: boolean): Action<boolean>
}

const actions: FrameViewActions = {
  setIframeVisibility,
}

export default actions
