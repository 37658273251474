import React from 'react'

import classNames from 'classnames'
import { FieldNamesMarkedBoolean } from 'react-hook-form/dist/types/form'
import * as yup from 'yup'

import HoverSVG from '@components/HoverSVG/HoverSVG'
import Loader from '@components/Loader'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import {
  InputContentMapping,
  InputDefinition,
  InputSignature,
  SignatureAlgorithm,
} from '@graphql/types/microservice/webhooks-incoming-management-types'
import {
  AuthenticationType,
  Channel,
  ContentMapping,
  Encoding,
  IncomingWebhook,
  InputAuthentication,
  SignatureHashAlgorithm,
  SignatureTemplate,
  SignatureTemplateList,
  Source,
} from '@src/pages/Webhooks/IncomingWebhooks/IncomingWebhooksContext'

import { webhookAuthenticationRootClass } from '../components/IncomingWebhookAuthentication/IncomingWebhookAuthentication'

export const renderInfoTooltip = (text: string) => (
  <Tooltip
    triggerClassName={`create-incoming-webhook-modal__info-tooltip`}
    trigger={
      <HoverSVG
        svg={SvgNames.info}
        hoverSvg={SvgNames.infoHover}
        fill={SvgColor.LIGHT_GRAY}
        hoverFill={SvgColor.TEXT_TEAL}
        type={SvgType.LARGER_ICON}
      />
    }
  >
    {text}
  </Tooltip>
)

export const parseIncomingWebhookToInputDefinition = (webhook: IncomingWebhook): InputDefinition => {
  const { name, description, enabled, tested, id, source, channel, contentMappings, authentication } = webhook
  return {
    webhookId: id,
    name,
    description,
    enabled,
    tested,
    source,
    contentMappings: !!contentMappings.length
      ? contentMappings.map(({ sourceField, destinationField, mappingFunction = '', mappingFunctionParams = '', mappingMetadata = '' }) => ({
          sourceField,
          destinationField,
          mappingFunction,
          mappingFunctionParams,
          mappingMetadata,
        }))
      : [{ destinationField: '', sourceField: '', mappingFunction: '', mappingFunctionParams: '', mappingMetadata: '' }],
    channel: contentMappings.some((mapping) => !!mapping.sourceField) ? channel : undefined,
    authentication: {
      type: authentication.type,
      username: authentication.username,
      password: authentication.passwordHash,
      token: authentication.tokenHash,
      signature: authentication.signature,
    },
  }
}

interface AuthenticationContainerProps {
  loading?: boolean
  text: string
  dashed?: boolean
  error?: boolean
  className?: string
}

export const renderAuthenticationContainer = ({ loading, text = '', dashed, className = '', error }: AuthenticationContainerProps) => (
  <div
    className={classNames(`${webhookAuthenticationRootClass}__authentication-container`, {
      [`${webhookAuthenticationRootClass}__authentication-container-dashed`]: dashed && !loading,
      [`${webhookAuthenticationRootClass}__authentication-container-loading`]: loading,
      [`${webhookAuthenticationRootClass}__authentication-container-error`]: error && !loading,
      [className]: className,
    })}
  >
    {loading && <Loader />}
    <Typography text={text} type={loading ? TextType.BODY_TEXT : TextType.BODY_TEXT_LIGHT} />
  </div>
)

export const renderShowButton = (showPassword: boolean, onClick: VoidFunction, t: Function) => (
  <button className={`${webhookAuthenticationRootClass}__show-password`} onClick={onClick}>
    <Svg name={showPassword ? SvgNames.hide : SvgNames.show} type={SvgType.LARGER_ICON} />
    <Typography
      text={t(showPassword ? 'Hide' : 'Show')}
      weight={TextWeight.MEDIUM}
      type={TextType.BODY_TEXT_SMALL}
      lineHeight={LineHeight.MEDIUM_SMALL}
    />
  </button>
)

const contentMappingValidator = yup.array().of(
  yup
    .object()
    .shape<Partial<InputContentMapping>>(
      {
        destinationField: yup.string().when('sourceField', {
          is: (sourceField: string) => sourceField !== '',
          then: yup.string().required('Please select a field'),
          otherwise: yup.string().notRequired(),
        }),
        sourceField: yup.string().when('destinationField', {
          is: (destinationField: string) => destinationField !== '',
          then: yup.string().required('Please enter a valid name'),
          otherwise: yup.string().notRequired(),
        }),
      },
      [['destinationField', 'sourceField']]
    )
    .required()
)

const customTouchPointBehaviorsMappingValidator = yup.array().of(
  yup
    .object()
    .shape<Partial<InputContentMapping>>(
      {
        sourceField: yup.string().when('destinationField', {
          is: (destinationField: string) => destinationField !== 'note',
          then: yup.string().required(),
          otherwise: yup.string().notRequired(),
        }),
      },
      [['destinationField', 'sourceField']]
    )
    .required()
)

const authenticationBaseFieldsValidation = {
  type: yup
    .mixed<AuthenticationType>()
    .oneOf([AuthenticationType.None, AuthenticationType.Basic, AuthenticationType.Bearer, AuthenticationType.Signature])
    .required(),
  username: yup.string().when('type', {
    is: AuthenticationType.Basic,
    then: yup.string().required('Please enter a username'),
    otherwise: yup.string().nullable().notRequired(),
  }),
  password: yup.string().when('type', {
    is: AuthenticationType.Basic,
    then: yup.string().required('Please enter a password'),
    otherwise: yup.string().nullable().notRequired(),
  }),
  token: yup.string().when('type', {
    is: AuthenticationType.Bearer,
    then: yup.string().required('A token is required for authentication'),
    otherwise: yup.string().nullable().notRequired(),
  }),
}

const authenticationValidation = yup.object().shape<InputAuthentication>({
  ...authenticationBaseFieldsValidation,
  signature: yup.object<InputSignature>().when('type', {
    is: AuthenticationType.Signature,
    then: yup.object<InputSignature>().shape({
      hashAlgorithm: yup.string<SignatureHashAlgorithm>().required('Algorithm is required'),
      headerName: yup.string().required('Signature header is required'),
      keyEncoding: yup.string<Encoding>().required('Encoding is required'),
      key: yup.string().required('Signing secret is required'),
    }),
    otherwise: yup.object<InputSignature>().shape({
      hashAlgorithm: yup.string().nullable().notRequired(),
      headerName: yup.string().nullable().notRequired(),
      keyEncoding: yup.string().nullable().notRequired(),
      key: yup.string().nullable().notRequired(),
    }),
  }),
})

const authenticationCalendlyValidation = yup.object().shape<InputAuthentication>({
  ...authenticationBaseFieldsValidation,
  signature: yup.object<InputSignature>().when('type', {
    is: AuthenticationType.Signature,
    then: yup.object<InputSignature>().shape({
      hashAlgorithm: yup.string().nullable().notRequired(),
      headerName: yup.string().nullable().notRequired(),
      keyEncoding: yup.string().nullable().notRequired(),
      key: yup.string().required('Signing secret is required'),
    }),
    otherwise: yup.object<InputSignature>().shape({
      hashAlgorithm: yup.string().nullable().notRequired(),
      headerName: yup.string().nullable().notRequired(),
      keyEncoding: yup.string().nullable().notRequired(),
      key: yup.string().nullable().notRequired(),
    }),
  }),
})

export const createIncomingWebhookValidators = yup.object().shape<Partial<InputDefinition>>({
  name: yup.string().required('Please enter a webhook name'),
  source: yup.string().oneOf(Object.values(Source)).required(),
  contentMappings: yup.array<ContentMapping>().when(['source'], {
    is: (source) => source === Source.Custom,
    then: yup.array<ContentMapping>().when(['channel'], {
      is: (channel) => channel === Channel.ActOnContacts,
      then: contentMappingValidator,
      otherwise: yup.array<ContentMapping>().when(['channel'], {
        is: (channel) => channel === Channel.CustomTouchPoints,
        then: customTouchPointBehaviorsMappingValidator,
      }),
    }),
  }),
  authentication: yup.object<InputAuthentication>().when('source', {
    is: Source.Custom,
    then: authenticationValidation,
    otherwise: authenticationCalendlyValidation,
  }),
})

export const getInputSignatureFromTemplate = ({
  algorithms,
  headerFormat,
  headerName,
  payloadFormat,
  timestampCheck,
  timestampFormat,
  timestampHeaderFormat,
  timestampHeaderName,
  timestampLeeway,
}: SignatureTemplate): Partial<InputSignature> => ({
  headerFormat,
  headerName,
  payloadFormat,
  signatureAlgorithm: (algorithms as SignatureAlgorithm[])[0] as SignatureAlgorithm.Hmac,
  timestampCheck,
  timestampFormat,
  timestampHeaderFormat,
  timestampHeaderName,
  timestampLeeway,
})

export const buildSignature = (source: Source, signatureTemplateList: SignatureTemplateList, signature: InputSignature): Partial<InputSignature> => {
  const defaultValues = getInputSignatureFromTemplate(signatureTemplateList[source])
  if (source === Source.Custom) {
    const { hashAlgorithm, headerName, key, keyEncoding } = signature
    return { ...defaultValues, hashAlgorithm, headerName, key, keyEncoding }
  } else if (source === Source.Calendly) {
    const { hashAlgorithms, keyEncodings, signatureEncoding } = signatureTemplateList[source]
    return {
      ...defaultValues,
      hashAlgorithm: (hashAlgorithms as SignatureHashAlgorithm[])[0],
      key: signature.key,
      keyEncoding: (keyEncodings as Encoding[])[0],
      signatureEncoding: signatureEncoding as Encoding,
    }
  }
  return {}
}

const authenticationUndefinedAttributes: { [key in AuthenticationType]: Partial<InputAuthentication> } = {
  [AuthenticationType.None]: { signature: undefined, username: undefined, password: undefined, token: undefined },
  [AuthenticationType.Basic]: { signature: undefined, token: undefined },
  [AuthenticationType.Bearer]: { signature: undefined, username: undefined, password: undefined },
  [AuthenticationType.Signature]: { username: undefined, password: undefined, token: undefined },
}

export const buildWebhook = (
  webhook: Partial<InputDefinition>,
  contentMappings: InputContentMapping[],
  signature: Partial<InputSignature> | undefined,
  enableContentMapping: boolean,
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<InputDefinition>>>
): InputDefinition => {
  const { authentication, channel } = webhook
  const baseWebhook: Partial<InputDefinition> = {
    ...webhook,
    channel: channel === undefined ? Channel.ActOnContacts : channel,
    channelModifier: 'add',
    contentMappings: enableContentMapping ? contentMappings?.filter((content) => content?.sourceField && content.destinationField) : [],
  }

  let inputAuthentication: Partial<InputAuthentication> = {
    ...authentication,
  }

  if (inputAuthentication?.type === AuthenticationType.Signature) {
    inputAuthentication = { ...inputAuthentication, signature: signature as InputSignature }
  }

  inputAuthentication = { ...inputAuthentication, ...authenticationUndefinedAttributes[inputAuthentication?.type as AuthenticationType] }

  if (inputAuthentication?.type === AuthenticationType.Bearer && !dirtyFields?.authentication?.token) {
    delete inputAuthentication.token
  }

  if (inputAuthentication?.type === AuthenticationType.Basic && !dirtyFields?.authentication?.password) {
    delete inputAuthentication.password
  }

  return { ...baseWebhook, authentication: inputAuthentication } as InputDefinition
}
