import React, { useCallback, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import classNames from 'classnames'
import { TFunction } from 'i18next'

import { AssetPickerTableType, DEFAULT_PICKER_COLUMN_SIZES } from '@complex/AssetPickerModal/Components/Table/AssetPickerTable.utils'
import { IImagePickerItem } from '@components/AssetPickers/ImagePickerModal/utils/ImagePickerModal.utils'
import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import PillLabel from '@components/PillLabel/PillLabel'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import { renderRelativeDate } from '@components/Table/components/tableColumns'
import { RowAction } from '@components/TableV2/tableV2TS/interfaces'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import { getStandardFormattedDate, relativeDate } from '@utils/date'

const renderDefaultRelativeDateColumn = (header: string, accessorKey: string): ColumnDefWithAdditionalProps<IImagePickerItem, any> => ({
  header,
  accessorKey,
  textAlign: 'left',
  ...DEFAULT_PICKER_COLUMN_SIZES[AssetPickerTableType.DEFAULT].date,
  cell: (cell) =>
    cell.getValue() ? renderRelativeDate(relativeDate(cell.getValue(), true, false), getStandardFormattedDate(cell.getValue().valueOf())) : '',
})

const imgClass = 'image-picker-modal-content__cell-image'

const TitleWithImage = ({
  title,
  url,
  thumbNailUrl,
  isDefault,
  t,
  searchTerm,
}: {
  title?: string
  url?: string
  thumbNailUrl?: string
  isDefault?: boolean
  t: TFunction
  searchTerm?: string
}) => {
  const { ref: imgRef, inView } = useInView({ threshold: 0, triggerOnce: true })
  const [loadError, setLoadError] = useState<boolean>(false)
  const handleLoadError = useCallback(() => setLoadError(true), [])

  const text = title && searchTerm ? renderBoldTextOnMatch(title, searchTerm) : title

  return (
    <div className="flex-align-center">
      <div className={classNames(`${imgClass}-wrapper`, { [`${imgClass}-wrapper-error`]: loadError })}>
        {loadError ? (
          <Svg name={SvgNames.labelIcon} type={SvgType.LARGE_ICON} fill={SvgColor.BACKGROUND_GRAY} />
        ) : (
          <img
            loading="lazy"
            ref={imgRef}
            src={inView ? thumbNailUrl ?? url ?? '' : undefined}
            alt={''}
            className={imgClass}
            onError={handleLoadError}
          />
        )}
      </div>
      <TextWithTooltipOnEllip typographyProps={{ text, className: 'push-right-x2' }} tooltipProps={{ children: title }} />
      {isDefault && <PillLabel text={t('Default')} className={`${imgClass}-pill`} />}
    </div>
  )
}

export const getImagePickerTableColumns = (t: TFunction, isLogos: boolean, searchTerm?: string): ColumnDefWithAdditionalProps<IImagePickerItem>[] => [
  {
    header: t(isLogos ? 'Logo Title' : 'Image title'),
    accessorKey: 'title',
    textAlign: 'left',
    enableCustomCellValue: true,
    cell: (cell) => <TitleWithImage {...cell.row.original} t={t} searchTerm={searchTerm} />,
  },
  {
    header: t('Dimensions'),
    accessorKey: 'dimensions',
    textAlign: 'left',
    ...DEFAULT_PICKER_COLUMN_SIZES[AssetPickerTableType.DEFAULT].date,
  },
  {
    header: t('File Size'),
    textAlign: 'left',
    accessorKey: 'size',
    ...DEFAULT_PICKER_COLUMN_SIZES[AssetPickerTableType.DEFAULT].date,
  },
  ...(!isLogos
    ? [renderDefaultRelativeDateColumn(t('Date Created'), 'created'), renderDefaultRelativeDateColumn(t('Last Updated'), 'modified')]
    : []),
]

export const getImagePickerRowActions = (t: TFunction, onPreview: (item: IImagePickerItem) => void): RowAction<IImagePickerItem>[] => [
  {
    label: t('Preview'),
    icon: SvgNames.zoom,
    iconSize: SvgType.ICON,
    tooltipMessage: t('Preview'),
    onClick: (row) => onPreview(row.original),
  },
]
