import React from 'react'

import { ApolloError } from '@apollo/client'
import PageContainer from '@components/PageContainer/PageContainer'
import PageError from '@components/PageError'

interface WithLoadingProps {
  pageError?: ApolloError
}

const withError =
  <P extends {}>(Component: React.ComponentType<P>): React.FC<P & WithLoadingProps> =>
  ({ pageError = undefined, ...props }: WithLoadingProps) => {
    if (pageError) {
      return (
        <PageContainer>
          <PageError message={pageError.message} center />
        </PageContainer>
      )
    } else {
      return <Component {...(props as P)} />
    }
  }

export default withError
