import { gql } from '@apollo/client'

export default gql`
  query messageListing($type: String!) {
    messageListing(type: $type) {
      id
      isDefault
      name
      count
      entries {
        id
        isCustomMessage
        isNewComposer
        isTemplateAvailableInEBE
        beeComposer
        modified
        created
        scheduled
        previewUrl
        publicUrl
        title
        subject
        to
      }
      folders {
        id
        isDefault
        name
        count
        entries {
          id
          isCustomMessage
          isNewComposer
          isTemplateAvailableInEBE
          beeComposer
          modified
          created
          scheduled
          previewUrl
          publicUrl
          title
          subject
          to
        }
        folders {
          id
          isDefault
          name
          count
        }
      }
    }
  }
`
