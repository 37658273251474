import React, { FC, RefObject, useState } from 'react'

import classNames from 'classnames'

import EmojiPicker from '@components/EmojiPicker/EmojiPicker'
import Svg, { SvgNames } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import { DropdownMenuContentProps } from '@radix-ui/react-dropdown-menu'
import * as Popover from '@radix-ui/react-popover'
import { insertTextAtCursor } from '@utils/formUtils'

import './EmojiButton.css'

import { SvgColor } from '@components/Svg/Svg'

export type EmojiButtonProps = Pick<DropdownMenuContentProps, 'align'> & {
  className?: string
  dataTest?: string
  inputRef?: RefObject<HTMLInputElement | HTMLTextAreaElement | null>
  onSelect: (newText: string) => void
  disabled?: boolean
  tooltip?: string
}

const rootClass = 'emoji-button'

const EmojiButton: FC<EmojiButtonProps> = (props: EmojiButtonProps) => {
  const { dataTest = rootClass, inputRef, onSelect, className = '', disabled, tooltip, ...dropDownProps } = props
  const [isOpen, setOpen] = useState(false)

  const handleSelect = (item: string) => {
    if (inputRef?.current) {
      const { selectionStart, value } = inputRef.current
      const cursorPosition = (selectionStart ?? value.length) + item.length
      onSelect(insertTextAtCursor(inputRef.current, item))
      setTimeout(() => {
        inputRef.current?.setSelectionRange(cursorPosition, cursorPosition)
        inputRef.current?.focus()
      }, 100)
    } else {
      onSelect(item)
    }
    setOpen(false)
  }

  const button = (
    <div
      role={'button'}
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === ' ') {
          setOpen(!isOpen)
          e.preventDefault()
        }
      }}
      className={classNames(`${rootClass}__button`, { [`${rootClass}__button--hover`]: isOpen, [`${rootClass}__button--disabled`]: disabled })}
      data-test={`${dataTest}-input`}
    >
      <Svg fill={!disabled ? SvgColor.TEXT_GRAY : SvgColor.TAB_GRAY} name={SvgNames.emoji} />
    </div>
  )

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Popover.Root open={isOpen} onOpenChange={setOpen}>
        <Popover.Trigger disabled={disabled}>{tooltip ? <Tooltip trigger={button}>{tooltip}</Tooltip> : button}</Popover.Trigger>
        <Popover.Content className={`${rootClass}__drop-down`} {...dropDownProps}>
          <EmojiPicker onSelect={(item) => handleSelect(item.native)} />
        </Popover.Content>
      </Popover.Root>
    </div>
  )
}

export default EmojiButton
