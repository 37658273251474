import { gql } from '@apollo/client'

export default gql`
  query findSentMessagesByName($search: String!) {
    findSentMessagesByName(search: $search) {
      id
      msgTitle
      sentCount
      senderName
      sentTime
      previewUrl
    }
  }
`
