import React, { FC, useCallback, useState } from 'react'

import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import CompanyInfoSection from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Company/components/CompanyInfoSection/CompanyInfoSection'
import CompanyNameModal from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Company/components/Modals/CompanyNameModal/CompanyNameModal'
import { CAN_SPAM_ACT_LINK } from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Company/constants/company.constants'

interface Props {
  name: string
  onChangeName: (name: string) => void
  dataTest?: string
}

const CompanyName: FC<Props> = (props: Props) => {
  const { name, onChangeName, dataTest = 'company-name' } = props
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { t } = useTranslation()

  const onSave = useCallback(
    (newName: string) => {
      onChangeName(newName)
      setModalOpen(false)
    },
    [onChangeName]
  )

  return (
    <>
      <CompanyInfoSection
        title={t('Company name')}
        required
        description={
          <>
            <Typography text={t('Company.Name.Description.1stSentence')} inline />
            <TextLink text={t('CAN-SPAM Act')} link={CAN_SPAM_ACT_LINK} hideIcon size={TextLinkSize.LARGE} />
            .&nbsp;
            <Typography text={t('Company.Name.Description.2ndSentence')} inline />
          </>
        }
        value={name}
        onEdit={() => setModalOpen(true)}
        dataTest={`${dataTest}__section`}
      />
      <CompanyNameModal name={name} isOpen={modalOpen} onSave={onSave} onCancel={() => setModalOpen(false)} />
    </>
  )
}

export default CompanyName
