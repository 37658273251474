import { gql } from '@apollo/client'

export const TAG_FIELDS = gql`
  fragment TagFields on LabelDTO {
    color
    createdTime
    enabled
    id
    itemCount
    name
    updatedTime
    views
  }
`

export const ITEM_FIELDS = gql`
  ${TAG_FIELDS}
  fragment ItemFields on ItemDTO {
    authorId
    createdTime
    enabled
    externalId
    folderId
    labels {
      ...TagFields
    }
    id
    isFavorite
    item
    position
    subTypeDTO {
      itemType
      name
    }
    updatedTime
    views
  }
`
export const FOLDER_FIELDS = gql`
  fragment FolderFields on FolderDTO {
    accountId
    enabled
    createdTime
    depth
    id
    itemCount
    name
    parentId
    position
    subTypeDTOS {
      count
      itemType
      name
    }
  }
`
