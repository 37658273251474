import React, { FC, useEffect } from 'react'
import { FieldValues, UseFormRegister } from 'react-hook-form'

import { useApolloClient, useQuery } from '@apollo/client'
import Button, { ButtonType } from '@components/Button/Button'
import { Checkbox } from '@components/Checkbox/Checkbox'
import FormRow from '@components/FormRow/FormRow'
import Loader from '@components/Loader/Loader'
import PageError from '@components/PageError/PageError'
import Radio from '@components/Radio/Radio'
import Select from '@components/Select/Select'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { useTranslation } from '@const/globals'
import globals from '@const/globals'
import crmCreateTypes from '@graphql/queries/crmCreateTypes'
import crmIntersectionSchema from '@graphql/queries/crmIntersectionSchema'
import crmPeopleFields from '@graphql/queries/crmPeopleFields'
import {
  CrmCreateTypesQuery,
  CrmCreateTypesQueryVariables,
  CrmIntersectionSchema,
  CrmIntersectionSchemaQuery,
  CrmIntersectionSchemaQueryVariables,
  CrmPeopleFieldsQuery,
  CrmPeopleFieldsQueryVariables,
  ProgramAdditionalEntity,
  StringKeyValue,
} from '@graphql/types/query-types'
import { logNewRelicError } from '@utils/new-relic.utils'
import { ensureFirstLetterIsCapitalized } from '@utils/strings'

import { ProgramCreateInCrmStepExtKV } from '../../EditCreateInCRMStep'
import AdditionalEntity from '../components/AdditionalEntity'
import StepComponentMapper from '../components/StepComponentMapper'

import './editCRMStep.css'

const rootClass = 'edit-crm-step'

export interface State {
  entitySelectValue: string
  addingEntity: boolean
  pushType: string
}

interface Props {
  step: ProgramCreateInCrmStepExtKV
  dataTest?: string
  register: UseFormRegister<FieldValues>
  updateStep(key: string, value: any): void
  hasAOContactsSource: boolean
  updateStepWithMultipleValues(fields: {}): void
}

const DEFAULT_TEXT = 'If fields are blank, set a default value in your CRM (This setting will not overwrite existing values):'

export const EditCRMStep: FC<Props> = (props: Props) => {
  const { step, updateStep, updateStepWithMultipleValues, register, hasAOContactsSource, dataTest = rootClass } = props
  const [state, setState] = React.useState<State>({
    entitySelectValue: '',
    addingEntity: false,
    pushType: step.pushType ?? '',
  })
  const client = useApolloClient()

  const { entitySelectValue, addingEntity } = state
  const { t } = useTranslation()

  const {
    loading: loadingPeopleStatus,
    error: peopleErrors,
    data: peopleData,
  } = useQuery<CrmPeopleFieldsQuery, CrmPeopleFieldsQueryVariables>(crmPeopleFields, { client: client as any })

  const {
    loading: loadingCreateTypes,
    error: createTypesErrors,
    data: createTypesData,
  } = useQuery<CrmCreateTypesQuery, CrmCreateTypesQueryVariables>(crmCreateTypes, { client: client as any })

  const {
    loading: intersectionStatus,
    error: intersectionError,
    data: intersectionData,
  } = useQuery<CrmIntersectionSchemaQuery, CrmIntersectionSchemaQueryVariables>(crmIntersectionSchema, {
    client: client as any,
    variables: {
      entity: state.pushType,
    },
  })

  const peopleFields: StringKeyValue[] | undefined = peopleData?.crmPeopleFields
  const crmIntersectionSchemaFields: CrmIntersectionSchema[] = intersectionData?.crmIntersectionSchema ? intersectionData.crmIntersectionSchema : []
  const crmCreateTypeFields: StringKeyValue[] = createTypesData?.crmCreateTypes ? createTypesData.crmCreateTypes : []

  useEffect(() => {
    if (peopleFields && peopleFields.length === 1) {
      setState({
        ...state,
        pushType: peopleFields[0].value ?? '',
      })
      updateStep('pushType', peopleFields[0].value)
    }
  }, [peopleFields])

  const getCrmCreateTypeOptions = () =>
    crmCreateTypeFields.reduce((acc: any, cur) => {
      return [
        ...acc,
        <option key={cur.key} value={cur.value}>
          {`${ensureFirstLetterIsCapitalized(cur.key)}`}
        </option>,
      ]
    }, [])

  const getCrmCreateTypeOptionKeyFromValue = function (value?: string) {
    let key: string | undefined = ''
    crmCreateTypeFields.forEach(function (stringKeyValue) {
      if (value === stringKeyValue.value && stringKeyValue.key) {
        key = ensureFirstLetterIsCapitalized(stringKeyValue.key)
      }
    })
    return key
  }

  const updateAdditionalEntities = (i: number) => (entity: ProgramAdditionalEntity) => {
    const additionalEntities = [...step.additionalEntities.slice(0, i), entity, ...step.additionalEntities.slice(i + 1)]
    updateStep('additionalEntities', additionalEntities)
  }

  const loading = loadingPeopleStatus || intersectionStatus || loadingCreateTypes
  const error = peopleErrors || intersectionError || createTypesErrors

  if (error) {
    logNewRelicError(error)
  }

  return (
    <>
      {loading && <Loader center />}
      {error && <PageError />}
      {!loading && !error && (
        <>
          <FormRow className={`${rootClass}__new-records`}>
            {peopleFields && peopleFields.length > 1 ? (
              <>
                <Checkbox
                  dataTest={`${dataTest}__create-new-records`}
                  label={t('Create new records as:')}
                  checked={step.pushNewRecords}
                  onChange={() => updateStep('pushNewRecords', !step.pushNewRecords)}
                />
                {step.pushNewRecords &&
                  peopleFields.map((field: StringKeyValue) => (
                    <Radio
                      key={field.key}
                      dataTest={`${dataTest}-radio-${field.key}`}
                      label={t(field.key)}
                      value={field.value}
                      checked={step.pushType === field.value}
                      onChange={() => {
                        updateStep('pushType', field.value)
                        setState({
                          ...state,
                          pushType: field.value ?? '',
                        })
                      }}
                    />
                  ))}{' '}
              </>
            ) : (
              <Checkbox
                dataTest={`${dataTest}__create-new-records`}
                label={`${t('Create new records as:')} ${peopleFields ? peopleFields[0].value : ''}`}
                checked={step.pushNewRecords}
                onChange={() => updateStep('pushNewRecords', !step.pushNewRecords)}
              />
            )}
          </FormRow>
          {step.pushNewRecords && (
            <FormRow dataTest={dataTest} className={`${rootClass}__indent`}>
              <Checkbox
                dataTest={`${dataTest}__new-record-dupes`}
                label={t('Create a new record every time, allowing duplicates')}
                checked={step.insertAlways}
                onChange={() => {
                  updateStepWithMultipleValues({
                    insertAlways: !step.insertAlways,
                    updateExisting: step.updateExisting && !step.insertAlways ? false : step.updateExisting,
                  })
                }}
              />
            </FormRow>
          )}
          {step.staticFields && step.pushNewRecords && (
            <FormRow className={`${rootClass}__indent`}>
              {step.staticFields.length ? <div className={`${rootClass}__header-text`}>{t(DEFAULT_TEXT)}</div> : null}
              <div className={step.staticFields.length ? `${rootClass}__section` : ''}>
                <StepComponentMapper
                  fieldName={'staticFields'}
                  fields={step.staticFields}
                  updateStep={updateStep}
                  register={register}
                  rootClass={rootClass}
                  crmIntersections={crmIntersectionSchemaFields}
                  showAdd={step.pushNewRecords}
                  addText={'Add field to set on new records'}
                  dataTest={`${dataTest}__static-fields`}
                />
              </div>
            </FormRow>
          )}
          <FormRow dataTest={dataTest} className={`${rootClass}__existing-records`}>
            <Checkbox
              dataTest={`${dataTest}__update-existing`}
              label={t('Update existing records')}
              checked={step.updateExisting}
              onChange={() => {
                updateStepWithMultipleValues({
                  insertAlways: step.insertAlways && !step.updateExisting ? false : step.insertAlways,
                  updateExisting: !step.updateExisting,
                })
              }}
            />
          </FormRow>
          {step.updateExisting && (
            <>
              {!hasAOContactsSource && (
                <FormRow dataTest={dataTest} className={`${rootClass}__indent`}>
                  <Checkbox
                    dataTest={`${dataTest}__push-policy`}
                    label={t("Follow list's Push Policy")}
                    checked={step.useListPushPolicy}
                    onChange={() => updateStep('useListPushPolicy', !step.useListPushPolicy)}
                  />
                </FormRow>
              )}
              <FormRow dataTest={dataTest} className={`${rootClass}__indent`}>
                <Checkbox
                  dataTest={`${dataTest}__multiple-matches`}
                  label={t('Update when multiple matches are found in CRM')}
                  checked={step.updateMultipleMatches}
                  onChange={() => updateStep('updateMultipleMatches', !step.updateMultipleMatches)}
                />
              </FormRow>
            </>
          )}
          <FormRow className={`${rootClass}__additional`}>
            {step.additionalEntities &&
              step.additionalEntities.map(
                (additionalEntity, i) =>
                  additionalEntity.entityType && (
                    <div key={additionalEntity.id}>
                      <div className={`${rootClass}__entity-header`}>
                        {`Create ${
                          additionalEntity.entitySingular
                            ? ensureFirstLetterIsCapitalized(additionalEntity.entitySingular)
                            : getCrmCreateTypeOptionKeyFromValue(additionalEntity.entityType)
                        }`}
                        <Button
                          buttonType={ButtonType.REMOVE}
                          dataTest={`${dataTest}-delete-entity-${i}`}
                          type="button"
                          title={t('Delete')}
                          onClick={() =>
                            updateStep(
                              'additionalEntities',
                              step.additionalEntities ? [...step.additionalEntities.slice(0, i), ...step.additionalEntities.slice(i + 1)] : []
                            )
                          }
                        >
                          <Svg name={SvgNames.delete} type={SvgType.ICON} />
                          {t('Delete Entity')}
                        </Button>
                      </div>
                      <div className={`${rootClass}__section`} data-test={`${dataTest}__additional-entity`}>
                        <AdditionalEntity
                          additionalEntity={additionalEntity}
                          updateAdditionalEntities={updateAdditionalEntities(i)}
                          register={register}
                          rootClass={rootClass}
                        />
                      </div>
                    </div>
                  )
              )}
          </FormRow>
          <FormRow>
            {addingEntity && (
              <div className={`${rootClass}__section ${rootClass}__add-row`}>
                <Select
                  className={`${rootClass}__add-entity`}
                  dataTest={`${dataTest}__add-select`}
                  register={register('selectValue', {
                    onChange: (e) => {
                      setState({
                        ...state,
                        entitySelectValue: e.target.value,
                      })
                    },
                  })}
                  name="selectValue"
                  value={entitySelectValue}
                >
                  {getCrmCreateTypeOptions()}
                </Select>
                <Button
                  dataTest={`${dataTest}__add-entity`}
                  buttonType={ButtonType.FLOAT}
                  onClick={() => {
                    const entityType = entitySelectValue ? entitySelectValue : crmCreateTypeFields[0].value
                    updateStep(
                      'additionalEntities',
                      step.additionalEntities
                        ? [...step.additionalEntities, { entityType, id: globals.getUUID() }]
                        : [{ entityType, id: globals.getUUID() }]
                    )
                    setState({
                      ...state,
                      addingEntity: false,
                    })
                  }}
                >
                  <Svg name={SvgNames.check} type={SvgType.ICON} />
                  {t('Add')}
                </Button>
                <Button
                  buttonType={ButtonType.REMOVE}
                  dataTest={`${dataTest}__cancel-add-entity`}
                  onClick={() => {
                    setState({
                      ...state,
                      addingEntity: false,
                    })
                  }}
                >
                  {t('Cancel')}
                </Button>
              </div>
            )}
          </FormRow>
          <FormRow className={`${rootClass}__indent`}>
            <Button
              buttonType={ButtonType.FLOAT}
              dataTest={`${dataTest}__add-button`}
              type="button"
              title={t('Add Entity')}
              onClick={() => {
                setState({
                  ...state,
                  addingEntity: true,
                })
              }}
            >
              <Svg name={SvgNames.plus} type={SvgType.ICON} />
              {t('Add Entity')}
            </Button>
          </FormRow>
        </>
      )}
    </>
  )
}

export default EditCRMStep
