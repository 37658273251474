import React, { FC, ReactNode, useMemo } from 'react'

import classNames from 'classnames'

import {
  DEFAULT_SCHEDULE,
  getScheduledIntervalOptions,
  renderScheduledIntervalHeader,
  ScheduleType,
} from '@complex/ScheduleSelector/utils/ScheduleSelector.utils'
import { Checkbox } from '@components/Checkbox/Checkbox'
import MoreOptions from '@components/MoreOptions/MoreOptions'
import Radio from '@components/Radio'
import RadioGroup from '@components/RadioGroup'
import SelectV2 from '@components/SelectV2/SelectV2'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import TimePickerV2, { TimePickerV2Format, TimePickerV2Props } from '@components/TimePickerV2/TimePickerV2'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ProgramSchedule } from '@graphql/types/query-types'
import { titleCase } from '@utils/strings'

import './ScheduleSelector.css'

interface ScheduleSelectorProps {
  className?: string
  dataTest?: string
  disabled?: boolean
  onChange: (schedule: ProgramSchedule) => void
  defaultValue?: Partial<ProgramSchedule>
  showOptionsOnManual?: boolean
  header?: ReactNode
  manualLabel?: string
  manualScheduleTooltip?: { show: boolean; text?: string }
  showHeader?: boolean
}

const rootClass = 'schedule-selector'

const ScheduleSelector: FC<ScheduleSelectorProps> = (props: ScheduleSelectorProps) => {
  const {
    dataTest = rootClass,
    className = '',
    onChange,
    defaultValue,
    disabled = false,
    showOptionsOnManual = false,
    header,
    manualLabel,
    showHeader = true,
    manualScheduleTooltip = { show: true, text: 'This program will be unscheduled and must be started manually by choosing “Run now.”' },
  } = props

  const schedule = { ...DEFAULT_SCHEDULE, ...defaultValue }
  const { type = ScheduleType.MANUALLY, interval = 0, hour = 0, minute = 0, ampm = 0, weekdaysOnly = false } = schedule
  const runsManually = type === ScheduleType.MANUALLY

  const { t } = useTranslation()

  const onFieldChange = <T extends keyof ProgramSchedule>(field: T, value: ProgramSchedule[T]) => {
    onChange({
      ...schedule,
      [field]: value,
      ...(field === 'type' ? { interval: value === ScheduleType.MONTHLY || value === ScheduleType.WEEKLY ? 1 : 0 } : {}),
    })
  }

  const defaultTime = `${hour}:${minute} ${ampm === 0 ? 'AM' : 'PM'}`

  const handleSetTime: TimePickerV2Props['onSubmit'] = (time) => {
    if (!time) {
      return
    }
    const _hour = time.get('hour')
    const minute = time.get('minute')
    const ampm = _hour > 11 ? 1 : 0
    const hour = _hour % 12

    onChange({ ...schedule, minute, ampm, hour })
  }

  const frequencyOptions = useMemo(
    () =>
      Object.entries(ScheduleType)
        .filter(([_, type]) => type !== ScheduleType.MANUALLY)
        .map(([key, value]) => ({
          label: t(titleCase(value)),
          value: key,
        })),
    []
  )

  const scheduledIntervalOptions = getScheduledIntervalOptions(type as ScheduleType, t)

  const frequencySelectedOption = frequencyOptions.find(({ value }) => value === type)
  const scheduledIntervalSelectedOption = scheduledIntervalOptions.find(({ value }) => value === `${interval}`)

  const renderScheduledOptions = () => {
    const type = runsManually ? ScheduleType.WEEKLY : schedule.type
    if (!runsManually || showOptionsOnManual) {
      return (
        <MoreOptions dataTest={`${dataTest}-more-options`} className={`${rootClass}__more-options`}>
          <div className={classNames(`${rootClass}__run-schedule-container`, { [`${rootClass}__run-schedule-container-disabled`]: runsManually })}>
            <div className={`${rootClass}__run-schedule-frequency`}>
              <SelectV2
                key={`${frequencyOptions.length}-${frequencySelectedOption?.value}`}
                dataTest={`${dataTest}-select-frequency`}
                label={t('Frequency')}
                options={frequencyOptions}
                defaultValue={frequencySelectedOption}
                insideModal
                hideCheckMark
                truncateOptions
                isClearable={false}
                isDisabled={runsManually || disabled}
                onChange={(option) => onFieldChange('type', option?.value)}
              />
            </div>
            <div className={`${rootClass}__run-schedule-interval`}>
              <SelectV2
                key={`${frequencyOptions.length}-${frequencySelectedOption?.value}`}
                dataTest={`${dataTest}-select-interval`}
                label={t(renderScheduledIntervalHeader(type as ScheduleType))}
                options={scheduledIntervalOptions}
                defaultValue={scheduledIntervalSelectedOption}
                hideCheckMark
                insideModal
                truncateOptions
                isClearable={false}
                isDisabled={runsManually || disabled}
                onChange={(option) => onFieldChange('interval', parseInt(option?.value || ''))}
              />
            </div>
            <TimePickerV2
              onSubmit={handleSetTime}
              labelKey={'Start at'}
              disabled={runsManually || disabled}
              className={`${rootClass}__run-schedule-hour`}
              format={TimePickerV2Format.HH_MM}
              defaultValue={defaultTime}
              isParentModal
              smallLabel
            />
          </div>
          {type === ScheduleType.DAILY && (
            <Checkbox
              dataTest={`${dataTest}-weekdays-only`}
              checked={weekdaysOnly}
              onChange={(checked) => onFieldChange('weekdaysOnly', checked)}
              className={`${rootClass}__run-schedule-checkbox`}
              label={t('Weekdays only')}
              disabled={runsManually || disabled}
            />
          )}
        </MoreOptions>
      )
    }
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {showHeader &&
        (header ?? (
          <Typography
            className={`${rootClass}__header`}
            text={t('How often do you want the program to run?')}
            type={TextType.BODY_TEXT_SMALL}
            weight={TextWeight.MEDIUM}
          />
        ))}
      <RadioGroup verticalLayout>
        <div className={`${rootClass}__manually-radio-container`}>
          <Radio
            dataTest={`${dataTest}-radio-manually`}
            checked={runsManually}
            disabled={disabled}
            onChange={() => onFieldChange('type', ScheduleType.MANUALLY)}
            label={manualLabel ?? t('Manually')}
          />
          {manualScheduleTooltip?.show && (
            <Tooltip trigger={<Svg name={SvgNames.info} type={SvgType.LARGER_ICON} />}>{t(manualScheduleTooltip.text)}</Tooltip>
          )}
        </div>
        <div>
          <Radio
            dataTest={`${dataTest}-radio-scheduled`}
            checked={!runsManually}
            disabled={disabled}
            onChange={() => {
              onFieldChange('type', defaultValue?.type && defaultValue.type !== ScheduleType.MANUALLY ? defaultValue?.type : ScheduleType.WEEKLY)
            }}
            label={t('On a schedule')}
          />
          {renderScheduledOptions()}
        </div>
      </RadioGroup>
    </div>
  )
}

export default ScheduleSelector
