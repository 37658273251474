import React, { FC, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import TextLink from '@components/TextLink/TextLink'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './ExpandableBreadCrumbs.css'

interface State {
  isExpanded: boolean
  numberOfCrumbsToDisplay: number
  limitedCrumbs: string[]
}

interface Props {
  className?: string
  dataTest?: string
  crumbs: string[]
  separator?: SvgNames
  baseCrumb?: string
}

const rootClass = 'bread-crumbs'

const ExpandableBreadCrumbs: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '', crumbs, separator = SvgNames.paginationControlsNext, baseCrumb } = props
  const { t } = useTranslation()
  const [state, setState] = useState<State>({
    isExpanded: false,
    numberOfCrumbsToDisplay: 1,
    limitedCrumbs: [],
  })
  const { isExpanded, numberOfCrumbsToDisplay, limitedCrumbs } = state
  const crumbsRef = useRef<HTMLDivElement>(null)
  const crumbRef = useRef<HTMLDivElement>(null)

  const renderCrumbs = (actualCrumbs: string[]) => {
    return (
      <>
        {actualCrumbs.map((crumb, index) => {
          return (
            <div key={index} className={`${rootClass}__crumb`}>
              <Typography type={TextType.BODY_TEXT_SMALL} text={crumb} inline className={classNames(`${rootClass}__name`, 'ellip')} />
              <Svg className={`${rootClass}__separator`} name={separator} type={SvgType.TINY_ICON} />
            </div>
          )
        })}
      </>
    )
  }

  useEffect(() => {
    if (crumbsRef.current && !isExpanded) {
      const crumbsWidth = [...crumbsRef.current.children].reduce((prev, curr) => prev + curr.clientWidth, 0)
      if (crumbRef.current && crumbsWidth + crumbRef.current.clientWidth < crumbsRef.current.clientWidth) {
        setState({
          ...state,
          numberOfCrumbsToDisplay: numberOfCrumbsToDisplay + 1,
          limitedCrumbs: [...limitedCrumbs, crumbs[numberOfCrumbsToDisplay]],
        })
      }
    }
  }, [crumbsRef.current])

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {crumbs[numberOfCrumbsToDisplay] && (
        <div ref={crumbRef} className={`${rootClass}__hidden-crumb`}>
          <Typography type={TextType.BODY_TEXT_SMALL} text={crumbs[numberOfCrumbsToDisplay]} inline />
          <Svg className={`${rootClass}__separator`} name={separator} type={SvgType.TINY_ICON} />
        </div>
      )}
      <div className={classNames(`${rootClass}__crumbs`, { [`${rootClass}__crumbs-wrap`]: isExpanded })} ref={crumbsRef}>
        {isExpanded ? (
          renderCrumbs(baseCrumb ? [...crumbs.slice(1), baseCrumb].reverse() : crumbs.slice(1).reverse())
        ) : (
          <>
            {baseCrumb && (
              <div className={`${rootClass}__overflow-dots`}>
                <Typography type={TextType.BODY_TEXT} text={'...'} inline dataTest={`${rootClass}-overflow-dots`} />
                <Svg className={`${rootClass}__separator`} name={separator} type={SvgType.TINY_ICON} />
              </div>
            )}
            {renderCrumbs(limitedCrumbs.slice().reverse())}
          </>
        )}
        <Typography type={TextType.BODY_TEXT_SMALL} weight={TextWeight.MEDIUM} text={crumbs[0]} className={'ellip'} />
      </div>
      <div>
        <TextLink
          text={state.isExpanded ? t('- Show less') : t('+ Show more')}
          hideIcon
          onClick={() => {
            setState({ ...state, isExpanded: !isExpanded })
          }}
          dataTest={`${rootClass}-control`}
        />
      </div>
    </div>
  )
}

export default ExpandableBreadCrumbs
