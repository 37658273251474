import React, { FC } from 'react'

import classNames from 'classnames'

import './dots.css'

export enum LoaderSize {
  XSMALL = 'XSMALL',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  XLARGE = 'XLARGE',
  CUSTOM = 'CUSTOM',
}

export interface DotsProps {
  className?: string
  dataTest?: string
  size?: LoaderSize
  customSize?: React.CSSProperties
}

const rootClass = 'dots'

const Dots: FC<DotsProps> = (props: DotsProps) => {
  const { size, customSize, className, dataTest = rootClass } = props
  return (
    <div className={classNames(`${rootClass}__wrapper`, className)} data-test={dataTest}>
      <svg
        className={classNames(`${rootClass}__svg`, {
          [`${rootClass}__svg--xsmall`]: size === LoaderSize.XSMALL,
          [`${rootClass}__svg--small`]: size === LoaderSize.SMALL,
          [`${rootClass}__svg--medium`]: size === LoaderSize.MEDIUM,
          [`${rootClass}__svg--large`]: size === LoaderSize.LARGE,
          [`${rootClass}__svg--xlarge`]: size === LoaderSize.XLARGE,
        })}
        style={customSize}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 12"
        aria-live={'polite'}
        aria-busy={'true'}
        aria-label="dots"
      >
        <circle cx="2.5" cy="6" r="2.25">
          <animate attributeName="opacity" dur="1s" values="0.25;1;0.25" repeatCount="indefinite" begin="0.1" />
        </circle>
        <circle cx="9" cy="6" r="2.25">
          <animate attributeName="opacity" dur="1s" values="0.25;1;0.25" repeatCount="indefinite" begin="0.2" />
        </circle>
        <circle cx="15.5" cy="6" r="2.25">
          <animate attributeName="opacity" dur="1s" values="0.25;1;0.25" repeatCount="indefinite" begin="0.3" />
        </circle>
      </svg>
    </div>
  )
}

export default Dots
