import React, { FC, useEffect, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import MessagePickerModal from '@complex/MessagePickerModal'
import Button, { ButtonType } from '@components/Button/Button'
import ConfirmationModal from '@components/ConfirmationModal'
import Container from '@components/Container/Container'
import EmptyList from '@components/EmptyList/EmptyList'
import Pill, { PillSize, PillType } from '@components/Pill/Pill'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Typography from '@components/Typography/Typography'
import globals, { legacyActonContext, OnComposeAutoResponse, useTranslation } from '@const/globals'
import { Program, ProgramMessage } from '@graphql/types/query-types'
import { getLetter } from '@src/pages/programs/dashboard/ProgramSteps.utils'
import { ProgramManagerPopupsState } from '@src/pages/programs/manager/context/ProgramManager.context'
import { getProgramManagerQueries } from '@src/pages/programs/manager/graphQL/ProgramManager.graphQL'
import { useAccountSettings } from '@utils/account/account.utils'
import { logNewRelicError } from '@utils/new-relic.utils'
import { getProgramMessageName } from '@utils/program/program'
import windowUtils from '@utils/window'

import './programMessages.css'

import { MessageConfigurationType } from '@utils/composer/emailComposer/types'

interface ProgramMessagesProps {
  program: Program
  dataTest?: string
  isEditing: boolean
  openPopup: (fieldToClose: keyof ProgramManagerPopupsState) => void
  updateProgram(program: Program, hasAOContactsSource?: boolean, unsavedChanges?: boolean): void
}

export interface State {
  addingMessage: boolean
  showRemoveMessage: boolean
  removeMessageSteps?: string
}

function messageIsUsedBy(messageId: string, program: Program) {
  const inUseBy: string[] = []
  for (const localSegment of program.localSegmentDetails) {
    if (localSegment.idset?.includes(messageId)) {
      inUseBy.push(localSegment.id)
    }
  }

  const active: string[] = []
  program.tracks.forEach((track, i) => {
    track.steps.forEach((step, j) => {
      const stepIndex = j + 1

      let jsonStep = JSON.stringify(step)
      jsonStep = jsonStep.replace(/"ab_winner" *: *"[^"]*"/, '')
      if (jsonStep.indexOf(messageId) > -1) {
        active.push(`${getLetter(i)}-${stepIndex}`)
      } else if (inUseBy.length > 0) {
        for (const inUse of inUseBy) {
          if (jsonStep.indexOf(inUse) > -1) {
            active.push(`${getLetter(i)}-${stepIndex}`)
            break
          }
        }
      }
    })
  })

  program.exit.exitChoices.forEach((exit, i) => {
    const exitJson = JSON.stringify(exit)
    if (exitJson.indexOf(messageId) > -1) {
      active.push('Exit-' + (i + 1))
    } else if (inUseBy.length > 0) {
      for (let i = 0; i < inUseBy.length; i++) {
        if (exitJson.indexOf(inUseBy[i]) > -1) {
          active.push('Exit-' + (i + 1))
          break
        }
      }
    }
  })

  return active
}

const rootClass = 'program-messages'

const ProgramMessages: FC<ProgramMessagesProps> = (props: ProgramMessagesProps) => {
  const { program, isEditing, openPopup, dataTest = 'program-messages' } = props
  const { hasReactWrapper } = useAccountSettings()

  const { t } = useTranslation()
  const [state, setState] = useState<State>({
    addingMessage: false,
    showRemoveMessage: false,
  })
  const { messages } = program
  const globalProgram = program

  const client = useApolloClient()
  const { copyProgramMessageRequest } = getProgramManagerQueries(client)

  const onMessageSave = (response: OnComposeAutoResponse) => {
    const messageIndex = globalProgram.messages.findIndex((message) => message.id === response.msgid)
    if (response.title) {
      const messages =
        messageIndex >= 0
          ? [
              ...globalProgram.messages.slice(0, messageIndex),
              {
                ...globalProgram.messages[messageIndex],
                name: getProgramMessageName(response),
              },
              ...globalProgram.messages.slice(messageIndex + 1),
            ]
          : [...globalProgram.messages, { id: response.msgid, name: getProgramMessageName(response) }]

      props.updateProgram({
        ...globalProgram,
        messages,
      })
    }
  }

  useEffect(() => {
    globals.listenToComposerResponse('ProgramMessages', onMessageSave)

    return () => {
      globals.removeComposerResponseListener('ProgramMessages')
    }
  }, [program])

  const removeMessage = (messageId: string, i: number) => {
    const active = messageIsUsedBy(messageId, program)
    if (active.length > 0) {
      setState({
        ...state,
        showRemoveMessage: true,
        removeMessageSteps: active.join(', '),
      })
    } else {
      const updatedMessages = [...messages.slice(0, i), ...messages.slice(i + 1)]
      props.updateProgram({
        ...props.program,
        messages: updatedMessages,
      })
    }
  }

  const previewMessage = (id: string) => {
    window.open(`${legacyActonContext}/messages/preview.jsp?msgid=${id}`, 'previewMessage', 'width=1000,height=600')
  }

  const editMessage = (id: string, redirectToBeeComposer?: boolean) =>
    redirectToBeeComposer
      ? windowUtils.openBeeComposerWindow(id, MessageConfigurationType.PROGRAM_MESSAGE)
      : windowUtils.openComposerWindow(id, hasReactWrapper)

  const renderRemoveMessageBody = () => (
    <>
      <Typography text={`${t('This message is currently in use by the following program steps')}: `} />
      <Typography text={state.removeMessageSteps} />
      <Typography text={`. ${t('Uses of this message must be removed before it can be deleted from the program.')}`} />
    </>
  )

  const closeRemoveMessage = () => setState({ ...state, showRemoveMessage: false, removeMessageSteps: undefined })

  const copyProgramMessage = async (messageId: string) => {
    const { data, errors } = await copyProgramMessageRequest(program.id, messageId)

    if (data?.copyProgramMessage?.id) {
      openPopup('copyProgramMessageSuccess')
      const newMessage: ProgramMessage = {
        id: data.copyProgramMessage.id,
        name: data.copyProgramMessage.title ?? '',
        beeComposer: data.copyProgramMessage.isBeeComposer,
      }
      const updatedMessages = [...messages, newMessage]
      props.updateProgram(
        {
          ...props.program,
          messages: updatedMessages,
        },
        program.hasActOnContactList,
        false
      )
    } else {
      openPopup('copyProgramMessageFail')
      logNewRelicError(errors)
    }
  }

  return (
    <>
      {state.showRemoveMessage && (
        <ConfirmationModal isOpen body={renderRemoveMessageBody()} title={t('Message in use')} closeModal={closeRemoveMessage} />
      )}
      {state.addingMessage && (
        <MessagePickerModal
          dataTest={`${dataTest}-message-picker-modal`}
          programId={program.id}
          programName={program.name}
          isOpen
          closeModal={() => {
            setState({
              ...state,
              addingMessage: false,
            })
          }}
          onSubmitMessage={(updatedMessages: ProgramMessage[]) => {
            const filteredMessages = updatedMessages.filter((msg) => !messages.find((currMessage) => currMessage.id === msg.id))
            const newMessages = [...messages, ...filteredMessages]
            setState({
              ...state,
              addingMessage: false,
            })
            props.updateProgram({
              ...props.program,
              messages: newMessages,
            })
          }}
          programWillOwnMessage
        />
      )}
      <div className={`${rootClass}`}>
        <Container
          noBottom
          title={'Program Messages'}
          description={
            'This is a listing of email messages sent by this program. You can create the email messages here, and refer to them while setting up the program flow.'
          }
        >
          {messages.length === 0 && <EmptyList message={t('No Program Messages have been added yet.')} />}
          <div>
            {messages.map((message, i) => (
              <Button
                buttonType={ButtonType.TEXT}
                key={message.id}
                onClick={() => previewMessage(message.id)}
                className={`${rootClass}__message-row`}
              >
                <div className={`${rootClass}__message-row-subject`}>
                  <Svg name={SvgNames.letter} type={SvgType.LARGER_ICON} />
                  {message.name}
                  {!message.beeComposer && (
                    <Pill text={t('Legacy')} type={PillType.GRAY} size={PillSize.EXTRA_SMALL} noMargin className={`${rootClass}__message-row-pill`} />
                  )}
                </div>
                <Button
                  buttonType={ButtonType.FLOAT}
                  onClick={(e) => {
                    previewMessage(message.id)
                    e.stopPropagation()
                  }}
                  className={`${rootClass}__row-button`}
                  data-test={`${dataTest}-preview-message-${i}`}
                  title={t('Preview Message')}
                >
                  <Svg name={SvgNames.show} />
                  {t('Preview')}
                </Button>
                {isEditing && (
                  <>
                    <Button
                      buttonType={ButtonType.FLOAT}
                      onClick={(e) => {
                        editMessage(message.id, message.beeComposer)
                        e.stopPropagation()
                      }}
                      className={`${rootClass}__row-button`}
                      data-test={`${dataTest}-edit-message-${i}`}
                      title={t('Edit Message')}
                    >
                      <Svg name={SvgNames.pencil} />
                      {t('Edit')}
                    </Button>
                    <Button
                      buttonType={ButtonType.FLOAT}
                      onClick={(e) => {
                        e.stopPropagation()
                        copyProgramMessage(message.id)
                      }}
                      className={`${rootClass}__row-button`}
                      data-test={`${dataTest}-edit-message-${i}`}
                      title={t('Copy message')}
                    >
                      <Svg name={SvgNames.copy} />
                      {t('Copy')}
                    </Button>
                    <Button
                      buttonType={ButtonType.REMOVE}
                      onClick={(e) => {
                        removeMessage(message.id, i)
                        e.stopPropagation()
                      }}
                      className={`${rootClass}__row-button ${rootClass}__row-button--delete`}
                      data-test={`${dataTest}-delete-message-${i}`}
                      title={t('Delete Message')}
                    >
                      <Svg name={SvgNames.delete} />
                      {t('Remove')}
                    </Button>
                  </>
                )}
              </Button>
            ))}
          </div>
          {isEditing && (
            <div className={`${rootClass}__list-button`}>
              <Button
                data-test={`${dataTest}-add-message`}
                buttonType={ButtonType.FLOAT}
                title={t('Add Message')}
                onClick={() => {
                  setState({ ...state, addingMessage: true })
                }}
              >
                <Svg name={SvgNames.plus} type={SvgType.ICON} />
                {t('Add Message')}
              </Button>
            </div>
          )}
        </Container>
      </div>
    </>
  )
}

export default ProgramMessages
