import { TFunction } from 'i18next'

import {
  AssetPickerTableType,
  renderDefaultLocationColumn,
  renderDefaultNameColumn,
  renderDefaultRelativeDateColumn,
  renderDefaultStandardDateColumn,
} from '@complex/AssetPickerModal/Components/Table/AssetPickerTable.utils'
import { ItemDtoRow } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { TABLEV2_CELL_PADDING } from '@components/TableV2/utils/commonUtils'
import { allEmailTemplatesFilter } from '@utils/filter'

import { renderEmailNameCell } from '../utils/EmailPicker.tables.utils'

const rootClass = 'email-picker-modal'

export const renderDraftsTableColumns = (t: TFunction): ColumnDefWithAdditionalProps<ItemDtoRow, any>[] => [
  ...[renderDefaultNameColumn(t('Email Title'), '', rootClass, (row, search, rootClass) => renderEmailNameCell(row, search, rootClass, t))],
  ...[renderDefaultStandardDateColumn(AssetPickerTableType.DEFAULT, t('Created'), 'createdAt')],
  ...[renderDefaultRelativeDateColumn(AssetPickerTableType.DEFAULT, t('Last Edited'), 'lastUpdated')],
]

// Needs to be 360px including the padding for this cell and the next cell
const firstCellWidth = 360 - TABLEV2_CELL_PADDING * 2

export const renderDraftsSearchTableColumns = (
  search: string,
  folders: FolderData[],
  t: TFunction
): ColumnDefWithAdditionalProps<ItemDtoRow, any>[] => [
  ...[
    {
      ...renderDefaultNameColumn(t('Email Title'), search, rootClass, (row, search, rootClass) => renderEmailNameCell(row, search, rootClass, t)),
      minSize: firstCellWidth,
      maxSize: firstCellWidth,
    },
  ],
  ...[
    {
      ...renderDefaultLocationColumn(AssetPickerTableType.SEARCH, t('Location'), folders, t(allEmailTemplatesFilter.name)),
      minSize: 185,
      maxSize: 185,
    },
  ],
  ...[
    {
      ...renderDefaultStandardDateColumn(AssetPickerTableType.SEARCH, t('Created'), 'createdAt'),
      minSize: 150 - TABLEV2_CELL_PADDING,
      maxSize: 150 - TABLEV2_CELL_PADDING,
    },
  ],
  ...[
    {
      ...renderDefaultRelativeDateColumn(AssetPickerTableType.SEARCH, t('Last Edited'), 'lastUpdated'),
      minSize: 150 - TABLEV2_CELL_PADDING,
      maxSize: 150 - TABLEV2_CELL_PADDING,
    },
  ],
]
