import React, { ChangeEvent, FC, ReactNode, useCallback } from 'react'
import { Controller, UseFieldArrayReturn, useFormContext, useWatch } from 'react-hook-form'

import classNames from 'classnames'
import dayjs from 'dayjs'
import equal from 'fast-deep-equal/es6/react'

import { PersonalizationContainerTagInputProps } from '@complex/Personalization/PersonalizationContainer'
import AssetSelect from '@components/AssetSelect/AssetSelect'
import ButtonIcon, { ButtonIconType } from '@components/ButtonIcon/ButtonIcon'
import DatePicker from '@components/DatePicker/DatePicker'
import DateRangePicker from '@components/DateRangePicker/DateRangePicker'
import Input from '@components/Input'
import { getPersonalizationSvg } from '@components/PersonalizationTagInput/PersonalizationTagInput.utils'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { isGroupedOptionArrayType, isSingleValueArrayType } from '@components/SelectV2/SelectV2.utils'
import { SvgNames } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import {
  dateFormatOptions,
  findDateFormat,
  findDateFormatOption,
} from '@src/pages/EmailComposer/EmailModals/components/DynamicContent/components/DisplayConditions/DisplayConditions.dates'
import {
  findFieldOperator,
  useFieldOperators,
} from '@src/pages/EmailComposer/EmailModals/components/DynamicContent/components/DisplayConditions/DisplayConditions.operators'
import {
  buttonProps,
  renderValidation,
  typographyProps,
} from '@src/pages/EmailComposer/EmailModals/components/DynamicContent/components/DisplayConditions/DisplayConditions.render'
import {
  DisplayConditionsForm,
  FieldOperator,
  MultiFieldError,
  TogglePersonalizationsModal,
} from '@src/pages/EmailComposer/EmailModals/components/DynamicContent/components/DisplayConditions/DisplayConditions.types'
import { useTranslation } from '@utils/const/globals'
import { timeZoneData } from '@utils/timezones'

import './DisplayConditionsField.css'

interface DisplayConditionsFieldProps {
  groupId: string
  fieldId: string
  groupController: UseFieldArrayReturn<DisplayConditionsForm, 'groups', 'id'>
  fieldController: UseFieldArrayReturn<DisplayConditionsForm, `groups.${number}.fields`, 'id'>
  getPersonalizationProps: (index: number) => PersonalizationContainerTagInputProps
  togglePersonalizations: TogglePersonalizationsModal
  hasGroupError?: boolean
  className?: string
  dataTest?: string
}

const rootClass = 'display-conditions-field'

const DisplayConditionsField: FC<DisplayConditionsFieldProps> = (props: DisplayConditionsFieldProps) => {
  const {
    togglePersonalizations,
    getPersonalizationProps,
    fieldController,
    groupController,
    groupId,
    fieldId,
    hasGroupError = false,
    dataTest = rootClass,
    className = '',
  } = props

  const { t } = useTranslation()
  const { register, control, formState, trigger, setValue } = useFormContext<DisplayConditionsForm>()

  const groupIndex = groupController.fields.findIndex((group) => group.id === groupId)
  const group = groupController.fields[groupIndex]

  const { fields, insert, remove, update } = fieldController
  const fieldIndex = fields.findIndex((field) => field.id === fieldId)
  const field = useWatch({ control, name: `groups.${groupIndex}.fields.${fieldIndex}` })
  const valueState = (index?: number) => {
    if (hasGroupError) {
      return { error: { message: formState.errors.duplicateGroups?.message } }
    } else if (index !== undefined) {
      return control.getFieldState(`groups.${groupIndex}.fields.${fieldIndex}.values.${index}`)
    }
    return control.getFieldState(`groups.${groupIndex}.fields.${fieldIndex}.values`)
  }

  const baseFieldId = `groups.${groupIndex}.fields.${fieldIndex}` as const
  const groupingOperator = fieldIndex === 0 ? t('if') : group.groupingOperator === 'all' ? t('and') : t('or')

  const { options, operator } = useFieldOperators(field)

  const dupFields = (formState.errors.groups?.[groupIndex]?.groupError as MultiFieldError)?.message?.fields ?? []
  const isDuplicateField = dupFields.some((dupField) => equal(field, dupField))

  const renderTimeZoneSelect = (index: number) => {
    const timeZoneOptions = timeZoneData.map((timeZone) => ({ label: timeZone.name, value: timeZone.id }))

    return (
      <Controller
        name={`${baseFieldId}.values.${index}`}
        control={control}
        rules={{ validate: () => !!valueState(index).error }}
        defaultValue={''}
        render={({ field }) => (
          <SelectV2
            defaultValue={timeZoneOptions.find((timeZone) => timeZone.value === field.value)}
            options={timeZoneOptions}
            onChange={(option) => field.onChange(option?.value ?? '')}
            error={!!valueState(index).error || isDuplicateField}
            placeholder={t('Choose a time zone')}
            className={`${rootClass}__value`}
            insideModal
          />
        )}
      />
    )
  }

  const renderDateInputs = (index: number, operatorValueCount: number) => {
    // Render picker outside of modal so that it does not get cut off on small screens
    const getDatePortalTarget = () => document.getElementById('modal-root') as HTMLElement

    return operatorValueCount === 1 ? (
      <Controller
        name={`${baseFieldId}.values.${index}`}
        control={control}
        rules={{ validate: () => !!valueState(index).error }}
        render={({ field: formField }) => {
          const date = new Date(formField.value)
          const defaultDate = !isNaN(date.valueOf()) ? date : undefined
          const jsDateFormat = findDateFormat(field.dateFormat).jsFormat
          return (
            <DatePicker
              onChange={(date) => {
                if (date) {
                  formField.onChange(date.toISOString())
                } else {
                  formField.onChange('')
                }
                trigger(`${baseFieldId}.values.${index}`)
                trigger(`groups.${groupIndex}.groupError`)
                trigger('duplicateGroups')
              }}
              menuPortalTarget={getDatePortalTarget}
              format={jsDateFormat}
              renderCustomFooter={renderDateFormatOptions}
              value={defaultDate}
              errorMessage={valueState(index).error?.message || String(isDuplicateField)}
              hideError
              className={`${rootClass}__value`}
              placeholder={t('Select a date')}
            />
          )
        }}
      />
    ) : (
      <Controller
        name={`${baseFieldId}.values.${index}`}
        control={control}
        rules={{ validate: () => !!valueState(0).error || !!valueState(1).error }}
        render={() => {
          const date1 = new Date(field.values[0])
          const date2 = new Date(field.values[1])
          const value1 = isNaN(date1.valueOf()) ? null : dayjs(date1)
          const value2 = isNaN(date2.valueOf()) ? null : dayjs(date2)
          const jsDateFormat = findDateFormat(field.dateFormat).jsFormat
          return (
            <DateRangePicker
              allowAnyDate
              hideError
              errorMessage={valueState().error?.message || isDuplicateField}
              format={jsDateFormat}
              renderCustomFooter={renderDateFormatOptions}
              showValueTooltip={jsDateFormat.length > 10}
              menuPortalTarget={getDatePortalTarget}
              onCalendarSubmit={(dates) => {
                if (dates) {
                  const startDate = dates[0] ? dates[0].toISOString() : ''
                  const endDate = dates[1] ? dates[1].toISOString() : ''
                  // TS is saying the type of this field is 'never' for some reason, but it works
                  // @ts-expect-error
                  setValue(`${baseFieldId}.values.0`, startDate)
                  // @ts-expect-error
                  setValue(`${baseFieldId}.values.1`, endDate)
                } else {
                  // @ts-expect-error
                  setValue(`${baseFieldId}.values.0`, '')
                  // @ts-expect-error
                  setValue(`${baseFieldId}.values.1`, '')
                }
                trigger(`${baseFieldId}.values`)
                trigger(`groups.${groupIndex}.groupError`)
                trigger('duplicateGroups')
              }}
              defaultRange={[value1, value2]}
              className={`${rootClass}__value`}
            />
          )
        }}
      />
    )
  }

  const renderDateFormatValue = useCallback((option: SelectV2SingleOption) => option.label, [])

  const renderDateFormatOptions = (existingFooter: ReactNode, closeDropdown?: () => void) => {
    if (operator.valueType !== 'date' || operator.valueCount === 0) {
      return null
    }
    const defaultValue = findDateFormatOption(field.dateFormat)
    return (
      <>
        <div className={classNames(`${rootClass}__date-format`, { [`${rootClass}__date-format--range`]: operator.valueCount === 2 })}>
          <div className={`${rootClass}__date-format__wrapper`}>
            <Typography text={t('Date format:')} weight={TextWeight.MEDIUM} type={TextType.BODY_TEXT} />
            <Controller
              name={`${baseFieldId}.dateFormat`}
              control={control}
              defaultValue={defaultValue.value}
              render={({ field }) => (
                <SelectV2
                  groupedOptions={dateFormatOptions}
                  defaultValue={defaultValue}
                  isClearable={false}
                  isSearchable={false}
                  insideModal
                  onMenuClose={closeDropdown}
                  showGroupsWithoutLabel
                  maxMenuHeight={340}
                  renderSingleValue={renderDateFormatValue}
                  onChange={(option) => field.onChange(option?.value ?? '')}
                  className={`${rootClass}__date-format__select`}
                />
              )}
            />
          </div>
          {existingFooter}
        </div>
      </>
    )
  }

  const renderValueInput = (index: number) => {
    let input: ReactNode

    // This will likely be revisited in the future
    // if (field.personalization?.title === 'Timezone') {
    if (false) {
      input = renderTimeZoneSelect(index)
    } else if (operator.valueType === 'date') {
      input = renderDateInputs(index, operator.valueCount)
    } else {
      input = (
        <Input
          register={{
            ...register(`${baseFieldId}.values.${index}`),
            onBlur: (e: ChangeEvent) => {
              register(`${baseFieldId}.values.${index}`).onBlur(e)
              trigger(`groups.${groupIndex}.groupError`)
              trigger('duplicateGroups')
            },
          }}
          className={classNames({ [`${rootClass}__value`]: operator.valueCount === 1, [`${rootClass}__value--multiple`]: operator.valueCount === 2 })}
          placeholder={t('Enter value')}
          error={!!valueState(index).error || isDuplicateField}
          dataTest={`${dataTest}-value-${index}`}
        />
      )
    }

    return input
  }

  const fieldErrors = formState.errors.groups?.[groupIndex]?.fields?.[fieldIndex] ?? {}
  const hasErrors = !!fieldErrors.values || !!fieldErrors.operator || !!fieldErrors.personalization

  const renderOperatorAndValues = () => (
    <>
      <SelectV2
        options={isSingleValueArrayType(options) ? options : undefined}
        groupedOptions={isGroupedOptionArrayType(options) ? options : undefined}
        className={classNames(`${rootClass}__operator`, {
          [`${rootClass}__operator--multiple-value`]: operator.valueCount === 2 && operator.valueType !== 'date',
          [`${rootClass}__operator--no-value`]: operator.valueCount === 0,
        })}
        hideNumberBadge
        defaultValue={operator.key}
        onChange={(option) => {
          if (option) {
            let newValues: string[] = []
            const newOperator = findFieldOperator(option.value)
            if (operator.valueType !== newOperator?.valueType && (operator.valueType === 'date' || newOperator?.valueType === 'date')) {
              // Persisting values that are not dates can cause unpredictable behavior
              newValues = ['', '']
            } else if (operator.valueCount === 1) {
              newValues = [field.values[0] ?? '']
            } else if (operator.valueCount === 2) {
              newValues = [field.values[0] ?? '', field.values[1] ?? '']
            }

            update(fieldIndex, { ...field, operator: option.value, values: newValues })
            trigger(`groups.${groupIndex}.groupError`)
            trigger('duplicateGroups')
          }
        }}
        error={isDuplicateField || hasGroupError}
        isClearable={false}
        isSearchable={false}
        insideModal
        dataTest={`${dataTest}-operator`}
      />
      {operator.valueCount === 2 ? (
        <>
          {operator.valueType === 'date' ? (
            renderDateInputs(0, operator.valueCount)
          ) : (
            <>
              {renderValueInput(0)}
              <Typography text={t('and')} {...typographyProps} />
              {renderValueInput(1)}
            </>
          )}
        </>
      ) : operator.valueCount === 1 ? (
        renderValueInput(0)
      ) : null}
    </>
  )

  const isDateRangeOperator = (operator: FieldOperator) => {
    return operator.valueCount === 2 && operator.valueType === 'date'
  }

  const renderValidationErrors = () => {
    if (!hasErrors) {
      return null
    }
    const isDateRange = isDateRangeOperator(operator)
    const baseClass = `${rootClass}__field-validations` as const
    const fullWidthValidation = !!fieldErrors.values?.message && !isDateRange
    return (
      <div className={baseClass}>
        <div className={`${baseClass}__grouping-operator`} />
        <div className={classNames(`${baseClass}__personalization`, { [`${baseClass}__personalization--blank`]: !field.personalization })}>
          {renderValidation(fieldErrors.personalization?.message)}
        </div>
        <div
          className={classNames(`${baseClass}__operator`, {
            [`${baseClass}__operator--multiple-value`]: operator.valueCount === 2 && !isDateRange,
            [`${baseClass}__operator--no-value`]: operator.valueCount === 0,
            [`${baseClass}__operator--date-range`]: isDateRange,
          })}
        >
          {renderValidation(fieldErrors.operator?.message)}
        </div>
        {operator.valueCount !== 0 && (
          <div
            className={classNames(`${baseClass}__value`, {
              [`${baseClass}__value--multiple`]: operator.valueCount === 2 && !isDateRange,
              [`${baseClass}__value--full-width`]: fullWidthValidation && !isDateRange,
              [`${baseClass}__value--date-range`]: isDateRange,
            })}
          >
            {renderValidation(fieldErrors.values?.message ?? fieldErrors.values?.[0]?.message)}
          </div>
        )}
        {operator.valueCount === 2 && !fullWidthValidation && (
          <>
            <div className={classNames(`${baseClass}__value`, { [`${baseClass}__value--multiple`]: operator.valueCount === 2 })}>
              {renderValidation(fieldErrors.values?.[1]?.message)}
            </div>
          </>
        )}
      </div>
    )
  }

  if (!group || !field) {
    return null
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__field`}>
        <div className={`${rootClass}__grouping-operator`}>
          <Typography text={groupingOperator} {...typographyProps} />
        </div>
        <AssetSelect
          replaceText={field.personalization ? t('Change') : t('Choose')}
          svgIcon={field.personalization ? getPersonalizationSvg(field.personalization) : undefined}
          key={field.personalization?.title ?? 'select-asset'}
          selectedAssets={field.personalization?.title}
          placeholder={t('Select a condition')}
          onClick={() => togglePersonalizations(getPersonalizationProps(fieldIndex))}
          className={classNames(`${rootClass}__personalization`, { [`${rootClass}__personalization--blank`]: !field.personalization })}
          error={!!fieldErrors.personalization || isDuplicateField || hasGroupError}
        />
        {!!field.personalization && renderOperatorAndValues()}
        <div className={`${rootClass}__icons`}>
          <Tooltip
            trigger={
              <ButtonIcon
                {...buttonProps}
                icon={SvgNames.cloneSegment}
                onClick={() => {
                  insert(fieldIndex, { ...field })
                }}
                dataTest={`${dataTest}-duplicate`}
                roundButton
                hideBorder
              />
            }
          >
            {t('Duplicate condition')}
          </Tooltip>
          <Tooltip
            trigger={
              <ButtonIcon
                {...buttonProps}
                icon={SvgNames.deleteRounded}
                disabled={fields.length === 1}
                onClick={() => {
                  remove(fieldIndex)
                  trigger()
                }}
                dataTest={`${dataTest}-delete`}
                type={ButtonIconType.DELETE}
                roundButton
                hideBorder
              />
            }
          >
            {fields.length === 1 ? t('Groups must include at least one display condition') : t('Remove condition')}
          </Tooltip>
        </div>
      </div>
      {renderValidationErrors()}
    </div>
  )
}

export default DisplayConditionsField
