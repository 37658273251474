import { DeepRequired } from './types'

/**
 * Parses a json string and fills in default values where missing or where types differ.
 * Caveats:
 * - This will ensure the object structure and primitive types are the same as defaults,
 * but cannot validate values constrained with enums or union types
 * - The defaults cannot have any explicitly undefined values,
 *   because then we can't make any assumption about what the type is supposed to be at runtime
 */
export const safeParsedJsonObject = <T>(jsonObject: string, defaults: DeepRequired<T>) => {
  const MAX_DEPTH = 50

  let parsedObject: any
  try {
    parsedObject = JSON.parse(jsonObject)
  } catch {
    return defaults
  }

  const processParsedJsonObject = (parsedValue: any, defaults: any, depth = 0) => {
    if (depth > MAX_DEPTH) {
      // Guard against an object that contains itself
      return defaults
    }

    if (!(defaults instanceof Object)) {
      return typeof parsedValue === typeof defaults ? parsedValue : defaults
    }

    const newObject: any = Array.isArray(defaults) ? [] : {}
    if (Array.isArray(defaults)) {
      if (!Array.isArray(parsedValue)) {
        return []
      }
      // With arrays 'defaults' should only contain one element
      // and we want to make sure each element in the parsedValue matches it
      parsedValue.forEach((_, index) => {
        newObject.push(processParsedJsonObject(parsedValue[index], defaults[0], depth + 1))
      })
    } else {
      Object.keys(defaults).forEach((key) => {
        const k = key as keyof T
        if (parsedValue instanceof Object && k in parsedValue) {
          newObject[k] = processParsedJsonObject(parsedValue[k], defaults[k], depth + 1)
        } else {
          newObject[k] = defaults[k]
        }
      })
    }
    return newObject
  }
  return processParsedJsonObject(parsedObject, defaults) as T
}
