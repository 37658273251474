import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'

export enum UpsertContactSetting {
  AddNewContact = 'ADD_NEW_CONTACT',
  AllContacts = 'ALL_CONTACTS',
  NewestContact = 'NEWEST_CONTACT',
  OldestContact = 'OLDEST_CONTACT',
  SkipExistingContact = 'SKIP_EXISTING_CONTACT',
  SkipNewContact = 'SKIP_NEW_CONTACT',
}

export enum ContactStatus {
  HardBounce = 'HARD_BOUNCE',
  OptOut = 'OPT_OUT',
  SoftBounce = 'SOFT_BOUNCE',
}

export const contactPreferencesOptions: SelectV2SingleOption[] = [
  { value: '', label: 'None set' },
  { value: ContactStatus.HardBounce, label: 'Hard Bounce' },
  { value: ContactStatus.OptOut, label: 'Opt Out' },
  { value: ContactStatus.SoftBounce, label: 'Soft Bounce' },
]

export enum FirstLineOption {
  NAMES = 'NAMES',
  VALUES = 'VALUES',
}

export enum MergeStrategy {
  Email = 'EMAIL',
  ExternalId = 'EXTERNAL_ID',
}

export enum Delimiter {
  Comma = 'COMMA',
  SemiColon = 'SEMI_COLON',
  Tab = 'TAB',
}

export enum Quote {
  Quote = 'QUOTE',
  SimpleQuote = 'SIMPLE_QUOTE',
}

export interface ImportContactsRejectRules {
  rejectOptOut: boolean
  rejectHardBounces: boolean
  rejectSuppressedDomains: boolean
}

export enum ColumnMergeType {
  IfEmpty = 'IF_EMPTY',
  Overwrite = 'OVERWRITE',
}

export const advancedOptionsText: { [key: string]: string } = {
  [FirstLineOption.NAMES]: 'ImportContactsV2.Sections.ImportOptions.Fields.Names',
  [FirstLineOption.VALUES]: 'ImportContactsV2.Sections.ImportOptions.Fields.Values',
  [Delimiter.Comma]: 'Commas',
  [Delimiter.Tab]: 'Tabs',
  [Delimiter.SemiColon]: 'Semi-colons',
  [Quote.SimpleQuote]: 'Single-quoted',
  [Quote.Quote]: 'Double-quoted',
  [UpsertContactSetting.OldestContact]: 'ImportContactsV2.Sections.ImportOptions.ContactsUpdate.Oldest',
  [UpsertContactSetting.SkipExistingContact]: 'ImportContactsV2.Sections.ImportOptions.ContactsUpdate.SkipExisting',
  [UpsertContactSetting.SkipNewContact]: 'ImportContactsV2.Sections.ImportOptions.ContactsUpdate.SkipNew',
  [ColumnMergeType.IfEmpty]: 'ImportContactsV2.Sections.ImportOptions.Merge.Blank',
  [ColumnMergeType.Overwrite]: 'ImportContactsV2.Sections.ImportOptions.Update.Overwrite',
  [ContactStatus.OptOut]: 'ImportContactsV2.Sections.ImportOptions.RejectRules.OptOut',
  [ContactStatus.HardBounce]: 'ImportContactsV2.Sections.ImportOptions.RejectRules.HardBounce',
  [ContactStatus.SoftBounce]: 'ImportContactsV2.Sections.ImportOptions.RejectRules.EmailSuppression',
  [MergeStrategy.Email]: 'Email',
  [MergeStrategy.ExternalId]: 'External id',
}
