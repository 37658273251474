import React, { ChangeEvent, FC, useContext, useRef, useState } from 'react'

import classNames from 'classnames'

import { ButtonType } from '@components/Button'
import EditableContainer from '@components/EditableContainer/EditableContainer'
import ListSelector from '@components/ListSelector/ListSelector'
import Radio from '@components/Radio'
import RadioGroup from '@components/RadioGroup'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { List } from '@interface/foldersLists/List'
import { ALL_ACTON_CONTACTS_SEGMENT_NAME } from '@src/pages/ContactSegments/utils/ContactSegments.constants'
import { rootTranslation } from '@src/pages/RecommendedSegments/components/SegmentsInsightsSettingsContainer/SegmentsInsightsSettingsContainer'
import { DEFAULT_AI_AUDIENCE_LIST } from '@src/pages/RecommendedSegments/utils/RecommendedSegments.constants'
import { RecommendedSegmentsContext } from '@src/pages/RecommendedSegments/utils/RecommendedSegments.context'

import './SegmentsInsightsAudienceSettings.css'

interface SegmentsInsightsAudienceSettingsProps {
  className?: string
  dataTest?: string
  onShowListPickerChange?: (show: boolean) => void
}

interface SegmentsInsightsAudienceSettingsState {
  selectAllContacts: boolean
  selectedList: List
}

const rootClass = 'segments-insights-audience-settings'

const SegmentsInsightsAudienceSettings: FC<SegmentsInsightsAudienceSettingsProps> = (props: SegmentsInsightsAudienceSettingsProps) => {
  const { dataTest = rootClass, className = '', onShowListPickerChange } = props

  const {
    setSelectedParent,
    values: { allContactsSegment, loadingSettings, selectedParent },
  } = useContext(RecommendedSegmentsContext)

  const allContactsSegmentAsList: List = {
    name: allContactsSegment?.name ?? DEFAULT_AI_AUDIENCE_LIST.name,
    size: allContactsSegment?.recordsCount ?? DEFAULT_AI_AUDIENCE_LIST.size,
    id: allContactsSegment?.externalId ?? DEFAULT_AI_AUDIENCE_LIST.id,
  } as List

  const [state, setState] = useState<SegmentsInsightsAudienceSettingsState>({
    selectAllContacts: !selectedParent || selectedParent.name === ALL_ACTON_CONTACTS_SEGMENT_NAME,
    selectedList: selectedParent ?? allContactsSegmentAsList,
  })
  const { selectAllContacts, selectedList } = state

  const hasUnsavedChangesRef = useRef(false)
  const nonAllActonContactsSegmentSelected = useRef(selectedList.name !== ALL_ACTON_CONTACTS_SEGMENT_NAME ? selectedList : undefined)

  const { t } = useTranslation()

  const onSubmit = (selectedList: List) => {
    if (selectedList.name === ALL_ACTON_CONTACTS_SEGMENT_NAME) {
      setState((state) => ({ ...state, selectedList: allContactsSegmentAsList, selectAllContacts: true }))
      nonAllActonContactsSegmentSelected.current = undefined
    } else {
      setState((state) => ({ ...state, selectedList }))
      nonAllActonContactsSegmentSelected.current = selectedList
    }
    hasUnsavedChangesRef.current = true
  }

  const onRadioChange = ({ target: { id } }: ChangeEvent<HTMLInputElement>) => {
    const isAllContactsSegment = id === ALL_ACTON_CONTACTS_SEGMENT_NAME
    setState(() => ({
      ...state,
      selectAllContacts: isAllContactsSegment,
      selectedList:
        !isAllContactsSegment && nonAllActonContactsSegmentSelected.current ? nonAllActonContactsSegmentSelected.current : allContactsSegmentAsList,
    }))
    hasUnsavedChangesRef.current = true
  }

  const onSave = (selectedParentId = selectedList.id) => {
    setSelectedParent(selectedParentId)
    hasUnsavedChangesRef.current = false
  }

  const onCancel = () => {
    setState((state) => ({
      ...state,
      selectedList: selectedParent ?? allContactsSegmentAsList,
      selectAllContacts: !selectedParent || selectedParent.name === ALL_ACTON_CONTACTS_SEGMENT_NAME,
    }))
    nonAllActonContactsSegmentSelected.current = selectedParent.name !== ALL_ACTON_CONTACTS_SEGMENT_NAME ? selectedParent : undefined
    hasUnsavedChangesRef.current = false
  }

  const onReset = () => {
    onSave(allContactsSegmentAsList.id)
    setState((state) => ({
      ...state,
      selectedList: allContactsSegmentAsList,
      selectAllContacts: true,
    }))
    nonAllActonContactsSegmentSelected.current = undefined
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <EditableContainer onSave={onSave} onReset={onReset} onCancel={onCancel} saveDisabled={!hasUnsavedChangesRef.current || !selectedList}>
        {(isEditing) => (
          <>
            <Typography
              text={t(`${rootTranslation}.Audience.Header`)}
              type={TextType.BODY_TEXT_LARGE}
              weight={TextWeight.MEDIUM}
              lineHeight={LineHeight.MEDIUM_LARGE}
            />
            <Typography className={`${rootClass}__subheader`} text={t(`${rootTranslation}.Audience.Subheader`)} />
            {isEditing ? (
              <RadioGroup verticalLayout>
                <Radio
                  id={allContactsSegmentAsList.name}
                  checked={selectAllContacts}
                  label={t(`${rootTranslation}.Audience.AllContactsOption`)}
                  onChange={onRadioChange}
                />
                <div>
                  <Radio checked={!selectAllContacts} label={t(`${rootTranslation}.Audience.SelectListsOption`)} onChange={onRadioChange} />
                  {!selectAllContacts && (
                    <ListSelector
                      className={`${rootClass}__list-selector`}
                      onSubmit={onSubmit}
                      selectedList={selectedList}
                      buttonType={ButtonType.INFO}
                      onShowListPickerChange={onShowListPickerChange}
                    />
                  )}
                </div>
              </RadioGroup>
            ) : (
              !loadingSettings && (
                <Typography
                  text={`${rootTranslation}.Audience.List`}
                  values={{ count: selectedParent?.size, listName: selectedParent?.name }}
                  tagProps={{ bold: { weight: TextWeight.MEDIUM } }}
                  type={TextType.BODY_TEXT_LIGHT}
                  inline
                />
              )
            )}
          </>
        )}
      </EditableContainer>
    </div>
  )
}

export default SegmentsInsightsAudienceSettings
