import { ItemDtoRow } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { renderLocationBreadcrumbs } from '@components/FolderSearch/FolderSearch.utils'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { renderRelativeDate } from '@components/Table/components/tableColumns'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { CellContext, ColumnDef, Row } from '@tanstack/react-table'
import { longDateFormat, relativeDateFormat, standardDateFormat } from '@utils/date/dateUtils'

interface ColumnSize {
  minSize: number
  maxSize: number
}

export enum AssetPickerTableType {
  DEFAULT = 'DEFAULT',
  SEARCH = 'SEARCH',
}

export type AssetPickerDefaultColumn = 'location' | 'date' | 'tags' | 'submits'

export const DEFAULT_PICKER_COLUMN_SIZES: { [key in AssetPickerTableType]: { [key in AssetPickerDefaultColumn]: ColumnSize } } = {
  [AssetPickerTableType.DEFAULT]: {
    tags: {
      minSize: 0,
      maxSize: 180,
    },
    location: {
      minSize: 0,
      maxSize: 0,
    },
    date: {
      minSize: 150,
      maxSize: 150,
    },
    submits: {
      minSize: 150,
      maxSize: 150,
    },
  },
  [AssetPickerTableType.SEARCH]: {
    tags: {
      minSize: 0,
      maxSize: 180,
    },
    location: {
      minSize: 250,
      maxSize: 250,
    },
    date: {
      minSize: 150,
      maxSize: 150,
    },
    submits: {
      minSize: 150,
      maxSize: 150,
    },
  },
}

export const filterTagsColumn = (columns: ColumnDef<ItemDtoRow>[], hasTags = true) => {
  return hasTags ? columns : columns.filter((column) => (column as any).accessorKey !== 'tags')
}

export const renderDefaultNameColumn = (
  header: string,
  search: string,
  rootClass: string,
  renderItemNameVal: (row: ItemDtoRow, search: string, rootClass: string, disabled?: boolean) => JSX.Element | '',
  disableRowByCriteria?: (row: Row<any>) => boolean
): ColumnDefWithAdditionalProps<ItemDtoRow, string> => ({
  header,
  accessorKey: 'name',
  textAlign: 'left',
  cell: (cell: CellContext<ItemDtoRow, string>) => {
    const isDisabled = !!disableRowByCriteria?.(cell.row)
    return renderItemNameVal(cell.row.original, search, rootClass, cell.row.original?.disabled || isDisabled)
  },
})

export const renderDefaultTagsColumn = (tableType: AssetPickerTableType, header: string): ColumnDefWithAdditionalProps<ItemDtoRow, any> => ({
  header,
  accessorKey: 'tags',
  textAlign: 'left',
  enableCustomCellValue: true,
  ...DEFAULT_PICKER_COLUMN_SIZES[tableType].tags,
  disableSorting: true,
})

export const renderDefaultRelativeDateColumn = (
  tableType: AssetPickerTableType,
  header: string,
  accessorKey: string
): ColumnDefWithAdditionalProps<ItemDtoRow, any> => ({
  header,
  accessorKey,
  textAlign: 'left',
  ...DEFAULT_PICKER_COLUMN_SIZES[tableType].date,
  cell: (cell: CellContext<ItemDtoRow, Date>) =>
    cell.getValue() ? renderRelativeDate(relativeDateFormat(cell.getValue().valueOf()), longDateFormat(cell.getValue().valueOf())) : '',
})

export const renderDefaultStandardDateColumn = (
  tableType: AssetPickerTableType,
  header: string,
  accessorKey: string
): ColumnDefWithAdditionalProps<ItemDtoRow, any> => ({
  header,
  accessorKey,
  textAlign: 'left',
  ...DEFAULT_PICKER_COLUMN_SIZES[tableType].date,
  cell: (cell: CellContext<ItemDtoRow, Date>) => (cell.getValue() ? standardDateFormat(cell.getValue().valueOf()) : ''),
})

export const renderDefaultDateColumn = (
  tableType: AssetPickerTableType,
  header: string,
  accessorKey: string,
  dateFormatFunction: (timestamp: number) => string
): ColumnDefWithAdditionalProps<ItemDtoRow, any> => ({
  header,
  accessorKey,
  textAlign: 'left',
  ...DEFAULT_PICKER_COLUMN_SIZES[tableType].date,
  cell: (cell: CellContext<ItemDtoRow, Date>) => (cell.getValue() ? dateFormatFunction(cell.getValue().valueOf()) : ''),
})

export const renderDefaultSubmitsColumn = (
  tableType: AssetPickerTableType,
  header: string,
  accessorKey: string
): ColumnDefWithAdditionalProps<ItemDtoRow, number> => ({
  header,
  ...DEFAULT_PICKER_COLUMN_SIZES[tableType].date,
  accessorKey,
  textAlign: 'left',
  cell: (cell: CellContext<ItemDtoRow, number>) => cell.getValue(),
  sortingFn: 'caseInsensitive',
})

export const renderDefaultLocationColumn = (
  tableType: AssetPickerTableType,
  header: string,
  folders: FolderData[],
  defaultText: string
): ColumnDefWithAdditionalProps<ItemDtoRow, number> => ({
  header,
  ...DEFAULT_PICKER_COLUMN_SIZES[tableType].location,
  accessorKey: 'folderId',
  textAlign: 'left',
  cell: (cell: CellContext<ItemDtoRow, number>) => renderLocationBreadcrumbs(cell.getValue(), folders, defaultText),
  sortingFn: 'caseInsensitive',
})
