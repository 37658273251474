import React, { FC, useCallback } from 'react'

import { renderToString } from 'react-dom/server'

import { IAddOnResponseHTMLExtended } from '@src/pages/EmailComposer/utils/BeeEditor.types'

import { PollBlock } from './components/PollBlock/PollBlock'
import PollBlockModal, { PollBlockValues } from './PollBlockModal'

export interface PollCustomFields {
  prompt: string
  choices: string[]
  oneOfN: boolean
  allowComment: boolean
  commentLabel: string
  publicVis: boolean
  image: string
  showImage: boolean
  blockId: string
}

interface PollBlockContainerProps {
  customFieldValues: PollCustomFields
  onClose: () => void
  onAction: (data: IAddOnResponseHTMLExtended) => void
}

const defaultChoices = [
  { name: 'Yes', index: 0 },
  { name: 'No', index: 1 },
  { name: 'Maybe', index: 2 },
]

export const PollBlockContainer: FC<PollBlockContainerProps> = ({ customFieldValues, onClose, onAction }) => {
  const handleInsert = useCallback((pollBlock: PollBlockValues) => {
    const choices = pollBlock.choices.map((choice) => choice.name)

    const pollHtml = renderToString(
      <PollBlock
        image={pollBlock.image}
        showImage={pollBlock.includeImageInEmail}
        prompt={pollBlock.prompt}
        choices={choices}
        canSelectMultiple={pollBlock.canSelectMultiple}
        blockId={customFieldValues.blockId}
      />
    )

    onAction({
      type: 'html',
      value: {
        html: pollHtml,
        customFields: {
          image: pollBlock.image,
          showImage: pollBlock.includeImageInEmail,
          prompt: pollBlock.prompt,
          choices: choices,
          oneOfN: !pollBlock.canSelectMultiple,
          allowComment: pollBlock.hasCommentPrompt,
          commentLabel: pollBlock.commentPrompt,
          publicVis: pollBlock.canSeeResponses,
        },
      },
    })
  }, [])

  return (
    <PollBlockModal
      pollBlock={{
        image: customFieldValues?.image,
        includeImageInEmail: customFieldValues?.showImage,
        prompt: customFieldValues?.prompt,
        choices: customFieldValues?.choices?.length ? customFieldValues.choices.map((name, index) => ({ name, index })) : defaultChoices,
        canSelectMultiple: customFieldValues?.oneOfN === false ? true : false,
        commentPrompt: customFieldValues?.commentLabel,
        hasCommentPrompt: customFieldValues?.allowComment,
        canSeeResponses: customFieldValues?.publicVis,
      }}
      onClose={onClose}
      onAction={handleInsert}
      isOpen
    />
  )
}
