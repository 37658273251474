import React, { FC, ReactNode, useRef } from 'react'

import classNames from 'classnames'

import MenuItem from '@components/Menu/components/MenuItem/MenuItem'
import MenuItemWithHeader from '@components/Menu/components/MenuItemWithHeader/MenuItemWithHeader'
import ScrollArea from '@components/ScrollArea/ScrollArea'

import './Menu.css'

interface Props {
  className?: string
  dataTest?: string
  items: MenuItemData[]
  menuSize?: string
}

export interface MenuItemData {
  content: ReactNode
  header?: string
  isOpen?: boolean
  isSelect?: boolean
}

const rootClass = 'menu'

const Menu: FC<Props> = (props: Props) => {
  const { items, dataTest = rootClass, className = '', menuSize = '25rem' } = props
  const scrollAreaRef = useRef<HTMLDivElement>(null)

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest} style={{ width: menuSize }}>
      <ScrollArea className={`${rootClass}__items`} register={scrollAreaRef}>
        {items.map(({ content, header, isOpen, isSelect }, index) =>
          !!header ? (
            <MenuItemWithHeader
              className={`${rootClass}__item`}
              key={`menu-item${index}`}
              content={content}
              header={header}
              isOpen={isOpen}
              scrollAreaRef={scrollAreaRef}
            />
          ) : (
            <MenuItem
              className={classNames(`${rootClass}__item`, { [`${rootClass}__item__select`]: isSelect })}
              key={`menu-item${index}`}
              content={content}
            />
          )
        )}
      </ScrollArea>
    </div>
  )
}

export default Menu
