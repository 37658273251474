import React, { FC } from 'react'

import classNames from 'classnames'

import { ApolloError } from '@apollo/client'
import DownloadButton from '@components/DownloadButton/DownloadButton'
import Loader from '@components/Loader/Loader'
import PageContainer from '@components/PageContainer'
import PageHeader from '@components/PageHeader'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import Tabs, { TabItemData, TabStyle } from '@components/TabsAO/TabsAO'
import SegmentedMessage from '@src/pages/sms/edit/utils/segmentationCalculator'
import MessageDetails from '@src/pages/sms/report/components/MessageDetails/MessageDetails'
import { MessageDetailsType } from '@src/pages/sms/report/SMSReport.constants'
import { getStandardFormattedDate } from '@utils/date'

import './SMSReport.css'

type Props = {
  tabs: TabItemData[]
  messageName: string
  messageDetails?: MessageDetailsType
  currentTab: string
  onTabChange: (tab: string) => void
  getDownloadUrl: () => Promise<string>
  isLaunch: boolean
  loading: boolean
  pageError?: ApolloError
  className?: string
}

const rootClass = 'sms-report'

const SMSReport: FC<Props> = (props: Props) => {
  const { tabs, currentTab, onTabChange, messageDetails, getDownloadUrl, loading, isLaunch, messageName, className = '' } = props

  const messageText = messageDetails ? messageDetails.messageText : ''
  const rawDate = new Date()
  const downloadDate = getStandardFormattedDate(rawDate.valueOf())
  return (
    <PageContainer className={classNames(rootClass, className)}>
      {loading ? (
        <Loader center />
      ) : (
        <PositionContainer>
          <PageHeader linkBack primaryText={messageName} centerContent restoreMargin noInnerPadding dataTest={rootClass}>
            <DownloadButton
              className={`${rootClass}__download-btn`}
              label={'Download CSV'}
              getDownloadUrl={getDownloadUrl}
              filename={`SMS Report for message ${messageName.replace(' ', '_')}.${downloadDate}}.csv`}
            />
          </PageHeader>
          <div className={`${rootClass}__main`}>
            <div className={`${rootClass}__content`}>
              <Tabs childData={tabs} tabStyle={TabStyle.BOXY} defaultValue={currentTab} onChange={onTabChange} />
            </div>
            <div className={`${rootClass}__sidebar`}>
              <MessageDetails messageDetails={messageDetails} isLaunch={isLaunch} segmentedMessage={new SegmentedMessage(messageText)} />
            </div>
          </div>
        </PositionContainer>
      )}
    </PageContainer>
  )
}

export default SMSReport
