import { gql } from '@apollo/client'

export default gql`
  query getSubscriptionCategories {
    getSubscriptionCategories {
      id
      name
      description
      checked
    }
  }
`
