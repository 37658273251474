import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import { Button, ButtonType } from '@components/Button/Button'
import { CLOSE_INFO_HOVER_EVENT } from '@components/InfoHoverCard/InfoHoverCard.constants'
import Loader from '@components/Loader'
import ScrollArea from '@components/ScrollArea/ScrollArea'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import * as HoverCard from '@radix-ui/react-hover-card'
import { useTranslation } from '@utils/const/globals'

import './InfoHoverCard.css'

interface Props {
  avoidCollisions?: boolean
  className?: string
  dataTest?: string
  children: ReactNode
  header?: ReactNode
  footer?: ReactNode
  description?: string
  descriptionTitle?: string
  descriptionExpanderMinLength?: number
  openDelay?: number
  closeDelay?: number
  scrollAreaClassName?: string
  sideOffset?: number
  side?: 'bottom' | 'top' | 'right' | 'left'
  isHidden?: boolean
  loading?: boolean
  icon?: ReactNode
  controlledOpen?: boolean
  onOpenChange?: (open: boolean) => void
}

interface State {
  isHovering: boolean
  isIconActive: boolean
  open: boolean
}

const rootClass = 'info-hover-card'

const DESCRIPTION_EXPANDER_MIN_LENGTH = 100
const REMOVING_CONTENT_ELEMENT_TIME = 100

const InfoHoverCard: FC<Props> = (props: Props) => {
  const {
    avoidCollisions = true,
    dataTest = rootClass,
    className,
    openDelay = 0,
    closeDelay = 0,
    children,
    scrollAreaClassName,
    sideOffset = 0,
    side = 'bottom',
    isHidden = false,
    header,
    description,
    descriptionTitle,
    descriptionExpanderMinLength = DESCRIPTION_EXPANDER_MIN_LENGTH,
    footer,
    loading = false,
    icon,
    onOpenChange,
  } = props
  const [expandedDesc, setExpandedDesc] = useState(false)
  const [state, setState] = useState<State>({
    isHovering: false,
    isIconActive: false,
    open: false,
  })
  const { isHovering, isIconActive, open } = state

  const cardContentRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()
  const longDescription = description && description.length > descriptionExpanderMinLength

  const renderDescription = (
    <div className={`${rootClass}__description`}>
      <Typography text={t(descriptionTitle || 'Description')} type={TextType.TABLE_HEADER} className={`${rootClass}__description-title`} />
      <Typography
        text={description}
        type={TextType.BODY_TEXT_SMALL}
        className={classNames(`${rootClass}__description-line`, {
          [`${rootClass}__description-long`]: longDescription,
          [`${rootClass}__description-long-expended`]: longDescription && expandedDesc,
        })}
      />
      {longDescription && (
        <Button buttonType={ButtonType.TEXT_TEAL} className={`${rootClass}__description-show-link`} onClick={() => setExpandedDesc(!expandedDesc)}>
          <Typography text={`${t(expandedDesc ? 'Show less' : 'Show more')}`} type={TextType.NORMAL_TEXT_TEAL} weight={TextWeight.MEDIUM} inline />
        </Button>
      )}
    </div>
  )

  useEffect(() => {
    const infoHoverCard = document.getElementsByClassName('info-hover-card__content ')[0]

    const preventScroll = (e: WheelEvent) => {
      if ((isIconActive || isHovering) && !infoHoverCard?.contains(e.target as Node)) {
        e.preventDefault()
        e.stopPropagation()
      }
    }

    document?.addEventListener('wheel', preventScroll, { passive: false })
    return () => {
      document?.removeEventListener('wheel', preventScroll)
    }
  }, [isIconActive, isHovering])

  useEffect(() => {
    const close = () => {
      setState((state) => ({ ...state, open: false, isHovering: false }))
    }
    document?.addEventListener(CLOSE_INFO_HOVER_EVENT, close)
    return () => {
      document?.removeEventListener(CLOSE_INFO_HOVER_EVENT, close)
    }
  }, [])

  const doOnOpenChange = (open: boolean) => {
    setState((state) => ({ ...state, open }))
    onOpenChange?.(open)
  }

  useEffect(() => {
    const infoHoverContainer = document.querySelector('.info-hover-card__content') as HTMLDivElement
    const content = infoHoverContainer
    if (content) {
      const scrollAreaRoot = content.querySelector('.scroll-area') as HTMLDivElement
      const rect = content.getBoundingClientRect()
      const mouseY = rect.y
      const windowHeight = window.innerHeight
      const tooltipHeight = infoHoverContainer.clientHeight || 0
      const distanceToBottom = windowHeight - mouseY

      //Fix when open to top
      if (rect.top <= 0) {
        infoHoverContainer.style.height = String(tooltipHeight + rect.top) + 'px'
      }
      //Fix when open to bottom
      if (distanceToBottom - tooltipHeight <= 0) {
        infoHoverContainer.style.height = String(distanceToBottom - 10) + 'px'
      }
      scrollAreaRoot.style.overflowY = 'auto'
    }
  }, [isHovering, open])

  const onIconHoverLeave = () => {
    setState((state) => ({ ...state, isHovering: false, isIconActive: false }))
    setTimeout(() => {
      const cardContent = cardContentRef.current
      if (cardContent && !cardContent.matches(':hover')) {
        doOnOpenChange(false)
      }
    }, openDelay + REMOVING_CONTENT_ELEMENT_TIME)
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      data-test={dataTest}
      style={{ cursor: 'pointer' }}
      className={classNames(className, rootClass, { [`${rootClass}__visible`]: isHovering, [`${rootClass}__hidden`]: isHidden })}
      onClick={(e) => e.stopPropagation()}
    >
      <HoverCard.Root openDelay={openDelay} closeDelay={closeDelay} onOpenChange={doOnOpenChange} open={open}>
        <HoverCard.Trigger
          className={classNames(`${className}__trigger`, `${rootClass}__trigger`)}
          onMouseEnter={() => setState((state) => ({ ...state, isIconActive: true }))}
          onMouseLeave={onIconHoverLeave}
          data-test={`${dataTest}-trigger`}
        >
          {icon ?? (
            <Svg
              className={`${rootClass}__trigger-icon`}
              name={isIconActive ? SvgNames.infoHover : SvgNames.info}
              type={SvgType.LARGER_ICON}
              dataTest={`${dataTest}-trigger-icon`}
            />
          )}
        </HoverCard.Trigger>
        <HoverCard.Portal>
          <HoverCard.Content
            onMouseEnter={() => setState((state) => ({ ...state, isHovering: true, isIconActive: true }))}
            onMouseLeave={() => setState((state) => ({ ...state, isHovering: false, isIconActive: false, open: false }))}
            sideOffset={sideOffset}
            side={side}
            avoidCollisions={avoidCollisions}
            className={classNames(`${rootClass}__content`, `${className}__card-content`)}
            ref={cardContentRef}
          >
            {header && <div className={classNames(`${rootClass}__header`, { [`${rootClass}__header-hidden`]: loading })}>{header}</div>}
            <ScrollArea showOnEvent={'always'} className={classNames(`${rootClass}__scroll`, `${className}-scroll`, scrollAreaClassName)}>
              {loading && <Loader className={`${rootClass}__loader`} />}
              {description && renderDescription}
              <div className={classNames(`${rootClass}__children`, { [`${rootClass}__hidden`]: loading, [`${className}__children`]: className })}>
                <div className={classNames(`${rootClass}__gradient`, `${rootClass}__gradient-top`)} />
                {children}
                <div className={classNames(`${rootClass}__gradient`, `${rootClass}__gradient-bottom`)} />
              </div>
            </ScrollArea>
            {footer && (
              <div className={classNames(`${rootClass}__footer`, { [`${rootClass}__footer-hidden`]: loading, [`${className}__footer`]: className })}>
                {footer}
              </div>
            )}
            <HoverCard.Arrow className={`${rootClass}__arrow`} />
          </HoverCard.Content>
        </HoverCard.Portal>
      </HoverCard.Root>
    </div>
  )
}

export default InfoHoverCard
