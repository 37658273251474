import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/index'
import Svg, { SvgNames, SvgType } from '@components/Svg/index'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import useCRM from '@utils/hooks/useCRM'
import { formatNumber } from '@utils/numbers'

import './SendDetailsSectionSplitHeader.css'

interface SendDetailsSectionSplitHeaderProps {
  title: string
  subText?: string
  subtextCount?: number
  count: number
  onClick: VoidFunction
  showSelectContactsButton: boolean
  className?: string
  dataTest?: string
  disableSelectContacts?: boolean
  isOptInEmail?: boolean
  link?: string
}

const rootClass = 'send-details-section-split-header'

const SendDetailsSectionSplitHeader: FC<SendDetailsSectionSplitHeaderProps> = (props: SendDetailsSectionSplitHeaderProps) => {
  const {
    title,
    subText = '',
    showSelectContactsButton,
    subtextCount = 2,
    count,
    onClick,
    dataTest = rootClass,
    className = '',
    disableSelectContacts = false,
    isOptInEmail,
    link,
  } = props
  const { t } = useTranslation()
  const { connectorType } = useCRM()

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__left`}>
        <Typography
          text={t(title, { context: isOptInEmail && 'OptIn', count: count === -1 ? '--' : formatNumber(count) })}
          type={TextType.PAGE_HEADER}
          tagProps={{ plain: { type: TextType.BODY_TEXT_LARGE, weight: TextWeight.REGULAR, inline: true } }}
        />
        {subText !== '' && (
          <Typography
            text={t(subText, { count: subtextCount })}
            type={isOptInEmail ? TextType.BODY_TEXT_LIGHT : undefined}
            tagComponents={{ TextLink: <TextLink link={link} size={TextLinkSize.LARGE} /> }}
          />
        )}
      </div>
      {showSelectContactsButton && (
        <div className={`${rootClass}__right`}>
          <Tooltip
            hide={!disableSelectContacts}
            triggerClassName={`${rootClass}__tooltip-trigger`}
            position="top"
            trigger={
              <Button
                disabled={disableSelectContacts}
                buttonType={ButtonType.INFO}
                className={`${rootClass}__button`}
                dataTest={`${dataTest}-button`}
                onClick={onClick}
                cursorIsNotAllowed={disableSelectContacts}
              >
                <Svg name={SvgNames.createSubsegment} type={SvgType.ICON} />
                {t('Select contacts')}
              </Button>
            }
          >
            {t('SendDetails.Recipients.CRM_SelectButton', { connectorType })}
          </Tooltip>
        </div>
      )}
    </div>
  )
}

export default SendDetailsSectionSplitHeader
