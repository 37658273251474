import { gql } from '@apollo/client'

export default gql`
  mutation addUsers($userInput: UserInput, $accountDomain: String) {
    addUsers(userInput: $userInput, accountDomain: $accountDomain) {
      status
      message
      count
      deletedUsersIds
      newUsersIds
      importedUsers
      noImportedUsers
      duplicatedUsers
    }
  }
`
