import React from 'react'

import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { TextWeight } from '@components/Typography/Typography'

import { ValidationOption } from '../../ValidationsList'

import './OptionsBlock.css'

export interface OptionsBlockProps {
  options: ValidationOption[]
}

const rootClass = 'options-block'

const OptionsBlock = (props: OptionsBlockProps) => {
  const { options } = props
  return (
    <div className={`${rootClass} push-right-x5`}>
      {options.map((option, index) => {
        const typographyProps = typeof option === 'string' ? { text: option } : option
        const { link, values, list, onClick, ...rest } = typographyProps
        return (
          <div className={`${rootClass}__item push-down`} key={index}>
            <Typography
              tagComponents={{
                TextLink: <TextLink link={link} onClick={onClick} size={TextLinkSize.LARGE} hideIcon />,
              }}
              {...rest}
              values={{ interpolation: { escapeValue: true }, ...values }}
              shouldUnescape
            />
            {list ? (
              <div className="push-right">
                {list.map((item, index) => (
                  <div key={index} className={`${rootClass}__item`}>
                    <Typography text={item} weight={TextWeight.MEDIUM} />
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        )
      })}
    </div>
  )
}

export default OptionsBlock
