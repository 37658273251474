import React, { FC, useState } from 'react'

import classNames from 'classnames'

import InfoTooltip from '@components/InfoTooltip/InfoTooltip'
import Toggle from '@components/Toggle/Toggle'
import { useTranslation } from '@const/globals'

import './SelectV2MenuFooterToggle.css'

export interface SelectV2MenuFooterToggleProps {
  label: string
  tooltip?: string
  onClick: () => void
  className?: string
  dataTest?: string
  defaultValue?: boolean
  disabled?: boolean
}

const rootClass = 'select-v2-menu-footer-toggle'

const SelectV2MenuFooterToggle: FC<SelectV2MenuFooterToggleProps> = (props: SelectV2MenuFooterToggleProps) => {
  const { label, tooltip, onClick, dataTest = rootClass, className = '', defaultValue = false, disabled = false } = props
  const { t } = useTranslation()
  const [isOn, setIsOn] = useState<boolean>(defaultValue)

  const handleClick = () => {
    setIsOn(!isOn)
    onClick()
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Toggle isOn={isOn} onToggle={handleClick} label={label} className={`${rootClass}__toggle`} disabled={disabled} />
      {tooltip && <InfoTooltip>{t(tooltip)}</InfoTooltip>}
    </div>
  )
}

export default SelectV2MenuFooterToggle
