import { gql } from '@apollo/client'

export default gql`
  mutation updateLogoAttributes($id: String, $data: LogoAttributesInput) {
    updateLogoAttributes(id: $id, data: $data) {
      status
      errorMsg
      item {
        id
        title
        url
        alt
        target
        tooltip
        isDefault
        isLocal
        duplicated
        dimensions
        size
      }
    }
  }
`
