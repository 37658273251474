import React, { FC, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import { FilterFunctionMap, useFrontendFiltering } from '@complex/ListingPage/Hooks/useFrontendFiltering'
import Button, { ButtonType } from '@components/Button'
import Modal, { ModalBody } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import ModalFooterV2 from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import ModalHeaderV2 from '@components/Modal/components/ModalHeaderV2/ModalHeaderV2'
import PreviewAssetModal, { PreviewModalSize } from '@components/PreviewAssetModal/PreviewAssetModal'
import { getAssetPreviewMode } from '@components/PreviewAssetModal/PreviewAssetModal.utils'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { LineHeight, ModalBodyStyle, ModalHeaderStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { useAccountSettings } from '@utils/account/account.utils'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'
import { MediaFileFlow } from '@utils/media'
import { getFormattedNumber } from '@utils/numbers'

import {
  MediaItem,
  MediaPickerModalContext,
  MediaPickerModalState,
  getDefaultMediaPickerModalState,
  getMediaFilterFunctions,
} from './MediaPickerModal.context'
import { useMediaPickerModalRequests } from './MediaPickerModal.graphQL'
import { getMediaActions, getMediaItemsFromResponse, MediaPickerModalType } from './MediaPickerModal.utils'
import { MediaPickerModalSidebarContainer } from './MediaPickerModalSidebarContainer'
import { MediaPickerModalTableContainer } from './MediaPickerModalTableContainer'

import './MediaPickerModal.css'

interface PreSelectedItem {
  id: string
  name: string
}

export interface MediaPickerModalProps {
  className?: string
  dataTest?: string
  preSelectedItem?: MediaItem
  preSelectedItems?: PreSelectedItem[]
  onClose: () => void
  onSingleSubmit?: (item: MediaItem) => void
  onMultipleSubmit?: (items: MediaItem[]) => void
  onBack: () => void
  onAction?: (action: MediaFileFlow) => void
  isOpen: boolean
  cssOrJsMediaType?: MediaPickerModalType
}

const rootClass = 'media-picker-modal'

const MediaPickerModal: FC<MediaPickerModalProps> = (props: MediaPickerModalProps) => {
  const {
    dataTest = rootClass,
    className = '',
    onSingleSubmit,
    onMultipleSubmit,
    onClose,
    isOpen,
    onAction,
    onBack,
    preSelectedItem,
    preSelectedItems,
    cssOrJsMediaType,
  } = props

  const isCssOrJsMode = !!cssOrJsMediaType

  const [state, setState] = useState<MediaPickerModalState>({
    ...getDefaultMediaPickerModalState(isCssOrJsMode),
  })
  const update = useDeepUpdate(setState)
  const { searchText } = state

  const { t } = useTranslation()
  const { userAllowedToCreate } = useAccountSettings()
  const { retrieveMediaFilesByFolderRequest } = useMediaPickerModalRequests()

  const fetchItems = async () => {
    const { data, errors } = await retrieveMediaFilesByFolderRequest(cssOrJsMediaType)

    if (errors) {
      update({ loading: false })
    } else if (data) {
      const { folders, items } = getMediaItemsFromResponse(data)

      update({ folders, allData: items, selectedItem: preSelectedItem, loading: false })
    }
  }

  useEffect(() => {
    const defaultSelectedItems = state.allData?.filter((item) => preSelectedItems?.some((preSelected) => preSelected.id === item.id))

    if (!!state.allData.length) {
      update({ selectedItems: defaultSelectedItems })
    }
  }, [preSelectedItems, state.allData])

  useEffect(() => {
    fetchItems()
  }, [])

  const filterFunctions: FilterFunctionMap<MediaItem> = useMemo(() => getMediaFilterFunctions(isCssOrJsMode), [isCssOrJsMode])

  const { selectedItems, isViewingSelected } = state

  const filteringAPI = useFrontendFiltering({
    data: state.allData,
    searchText: state.searchText,
    activeFilter: state.activeFilter,
    activeSubFilters: state.activeSubTypes,
    activeFolder: state.activeFolder,
    filterFunction: filterFunctions,
    sortingBy: state.sortingBy,
    itemsPerPage: 50,
    searchAllData: state.searchAllData,
    loading: state.loading,
  })

  const handleSearchChange = (value: string) => {
    update({ searchText: value })
  }

  const handleBack = () => {
    if (state.previewItem) {
      update({ previewItem: undefined })
    } else {
      onBack()
    }
  }

  const header = (
    <ModalHeaderV2
      className={`${rootClass}__header`}
      headerType={ModalHeaderType.List}
      headerText={
        <Typography
          text={t(isCssOrJsMode ? (isViewingSelected ? 'You selected the following' : 'Select Media') : 'AssetPicker.Media.Header.Title')}
          {...ModalHeaderStyle}
        />
      }
      iconProps={{ hasBackButton: !isCssOrJsMode, onClick: handleBack }}
      {...(!isViewingSelected && {
        searchProps: {
          onChangeHandler: handleSearchChange,
          canClear: true,
          incomingValue: searchText,
          placeholder: t('AssetPicker.Media.Search.Placeholder'),
          dataTest: `${dataTest}-search`,
        },
      })}
      actionButtonProps={{ splitButton: onAction && getMediaActions(t, onAction, userAllowedToCreate) }}
    />
  )

  const renderSelectedItem = useMemo(() => {
    const canPreview = getAssetPreviewMode(state.selectedItem?.linkUrl) !== 'unsupported'
    return (
      <div className={`${rootClass}__footer__section`}>
        {canPreview && (
          <Button buttonType={ButtonType.INFO} onClick={() => update({ previewItem: state.selectedItem })}>
            {t('Preview selection')}
          </Button>
        )}
        <Typography
          text={t(`AssetPicker.Media.SelectedItem`, { count: state.selectedItem ? 1 : 0, selectedItem: state.selectedItem?.title })}
          tagProps={{ bold: { weight: TextWeight.MEDIUM } }}
          {...ModalBodyStyle}
          inline
        />
      </div>
    )
  }, [state.selectedItem])

  const handleViewSelected = () => {
    update({ ...state, isViewingSelected: !isViewingSelected })
  }

  const fileInfoText =
    !!selectedItems?.length && t(`{{count}} file${selectedItems.length > 1 ? 's' : ''} selected`, { count: getFormattedNumber(selectedItems.length) })

  const preSelectedItemList = preSelectedItems ?? []

  const isSelectionUnchanged =
    !!preSelectedItemList.length &&
    preSelectedItemList.length === state.selectedItems?.length &&
    preSelectedItemList.every((pre) => state.selectedItems?.some((item) => pre.id === item.id)) &&
    state.selectedItems.every((item) => preSelectedItemList.some((pre) => pre.id === item.id))

  const disableSubmit = isCssOrJsMode ? !state.selectedItems?.length || isSelectionUnchanged : !state.selectedItem

  const handleSelectFromPreview = () => {
    if (state.previewItem) {
      if (isCssOrJsMode) {
        update({ selectedItems: [...(state.selectedItems ?? []), state.previewItem], previewItem: undefined })
      } else {
        onSingleSubmit?.(state.previewItem)
      }
    }
  }

  return (
    <>
      {state.previewItem && (
        <PreviewAssetModal
          title={state.previewItem.title}
          assetSrc={state.previewItem?.linkUrl}
          onBackButtonClick={() => handleBack()}
          onClose={() => handleBack()}
          closeButtonText={'Cancel'}
          submitButtonText={'Select'}
          onSubmit={handleSelectFromPreview}
          size={PreviewModalSize.MEDIUM}
          isOpen
        />
      )}
      <Modal
        className={classNames(rootClass, className, { [`${rootClass}--hidden`]: state.previewItem })}
        data-test={dataTest}
        isOpen={isOpen}
        header={header}
      >
        <ModalBody className={`${rootClass}__body`}>
          <MediaPickerModalContext.Provider value={{ ...filteringAPI, ...state, update }}>
            {!isViewingSelected && <MediaPickerModalSidebarContainer isCssOrJs={isCssOrJsMode} />}
            <MediaPickerModalTableContainer isCssOrJs={isCssOrJsMode} />
          </MediaPickerModalContext.Provider>
        </ModalBody>
        <ModalFooterV2
          customContent={
            isCssOrJsMode ? (
              <>
                <Button
                  className={`${rootClass}__view-selected-button push-left-x4`}
                  buttonType={ButtonType.INFO}
                  onClick={handleViewSelected}
                  disabled={!selectedItems?.length && !isViewingSelected}
                >
                  <Svg type={SvgType.LARGER_ICON} name={isViewingSelected ? SvgNames.arrowLeft : SvgNames.zoom} />
                  <Typography
                    text={t(isViewingSelected ? 'View all media' : 'View selected')}
                    type={TextType.NORMAL_TEXT_TEAL_LARGE}
                    weight={TextWeight.MEDIUM}
                  />
                </Button>
                <Typography
                  lineHeight={LineHeight.MEDIUM_LARGE}
                  text={fileInfoText}
                  type={TextType.BODY_TEXT_SMALL_LIGHT}
                  tagProps={{
                    bold: { weight: TextWeight.MEDIUM, lineHeight: LineHeight.MEDIUM_SMALL, inline: true },
                    medium: { lineHeight: LineHeight.MEDIUM, type: TextType.BODY_TEXT_LIGHT, inline: true },
                  }}
                  inline
                />
              </>
            ) : (
              renderSelectedItem
            )
          }
          footerType={ModalFooterType.List}
          onClose={onClose}
          buttons={{
            cancelButtonLabel: t(!!cssOrJsMediaType ? 'Cancel' : 'Back'),
            actionButtonLabel: t(!!cssOrJsMediaType ? 'Submit' : 'Select'),
            actionButtonOnClick: () =>
              state.selectedItem
                ? onSingleSubmit?.(state.selectedItem)
                : !!state.selectedItems?.length && onMultipleSubmit?.(state.selectedItems ?? []),
            actionButtonDisabled: disableSubmit,
          }}
        />
      </Modal>
    </>
  )
}

export default MediaPickerModal
