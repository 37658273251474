import React, { FC } from 'react'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { Box } from '@src/pages/programs/UpgradeCommonComponents/Box/Box'
import { UpgradedSource } from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'

import './ReviewUpgradeSegmentReplacement.css'

interface ReviewUpgradeSegmentReplacementProps {
  source: UpgradedSource
}

const rootClass = 'review-upgrade-segment-replacement'

export const ReviewUpgradeSegmentReplacement: FC<ReviewUpgradeSegmentReplacementProps> = ({
  source: {
    source: { sourceName, count },
    newSource,
  },
}: ReviewUpgradeSegmentReplacementProps) => {
  const { t } = useTranslation()
  return (
    <>
      <Box isSourceValue isCurrent>
        <TextWithTooltipOnEllip typographyProps={{ text: sourceName, type: TextType.BODY_TEXT }} />
        <Typography text={t(`AutomatedPrograms.UpgradeManager.SourceReplacement.Contacts`, { count })} type={TextType.BODY_TEXT_LIGHT} />
      </Box>
      <Svg className={`${rootClass}__arrow`} name={SvgNames.arrowRight} type={SvgType.LARGE_ICON} />
      <Box isSourceValue>
        <TextWithTooltipOnEllip typographyProps={{ text: newSource?.name, type: TextType.BODY_TEXT }} />
        <Typography
          text={t(`AutomatedPrograms.UpgradeManager.SourceReplacement.Contacts`, { count: newSource?.size })}
          type={TextType.BODY_TEXT_LIGHT}
        />
      </Box>
    </>
  )
}
