import React, { ReactNode } from 'react'

import { TFunction } from 'i18next'

import Pill from '@components/Pill/Pill'
import Radio from '@components/Radio'
import RadioGroup from '@components/RadioGroup'
import { TextType, TextWeight, TypographyProps } from '@components/Typography/Typography'
import { CronDtoInput, DayOfWeek } from '@graphql/types/microservice/entity-upload-types'
import { ProgramSchedule } from '@graphql/types/query-types'
import { ExportContactsContainerState, Update } from '@src/pages/ExportContacts/utils/ExportContacts.context'
import { WEEK_DAYS } from '@utils/date'

import { Delimiter, ExportFirstLineOption, exportOptionsDefaultValues, exportOptionsText, Quote } from '../utils/ExportOptionsScreen.constants'

export interface ExportOptionsScreenSection {
  title: string
  content: ReactNode
  tooltip?: string
  hidden?: boolean
}

const rootClass = 'export-options-screen'

export const renderExamplePill = (text: string): ReactNode => (
  <Pill className={`${rootClass}__pill`} text={text} noMargin textWeight={TextWeight.BOLD} />
)

export const getTypographyProps = (option: string, example: string): Partial<TypographyProps> => {
  return {
    values: { option: option },
    tagProps: {
      light: { type: TextType.BODY_TEXT_LIGHT },
    },
    tagComponents: {
      Example: renderExamplePill(example),
    },
  }
}

export const getExportOptions = (values: Partial<ExportContactsContainerState>, update: Update, t: TFunction): ExportOptionsScreenSection[] => {
  const { firstLineContains, fieldsSeparator, fieldQuotes } = values
  return [
    {
      title: 'ExportContacts.Sections.ExportOptions.Fields.Title',
      content: (
        <RadioGroup verticalLayout>
          <Radio
            className={rootClass}
            label={t('ExportContacts.Sections.ExportOptions.Fields')}
            checked={firstLineContains === ExportFirstLineOption.NAMES}
            onChange={() => update({ firstLineContains: ExportFirstLineOption.NAMES })}
            labelTypography={{
              values: { option: t(exportOptionsText[ExportFirstLineOption.NAMES]) },
              tagProps: {
                light: { type: TextType.BODY_TEXT_LIGHT },
              },
              tagComponents: {
                FirstName: renderExamplePill('FirstName'),
                LastName: renderExamplePill('LastName'),
                State: renderExamplePill('State'),
              },
            }}
          />
          <Radio
            className={rootClass}
            label={t('ExportContacts.Sections.ExportOptions.Fields')}
            checked={firstLineContains === ExportFirstLineOption.VALUES}
            onChange={() => update({ firstLineContains: ExportFirstLineOption.VALUES })}
            labelTypography={{
              values: { option: t(exportOptionsText[ExportFirstLineOption.VALUES]) },
              tagProps: {
                light: { type: TextType.BODY_TEXT_LIGHT },
              },
              tagComponents: {
                FirstName: renderExamplePill('John'),
                LastName: renderExamplePill('Doe'),
                State: renderExamplePill('CA'),
              },
            }}
          />
        </RadioGroup>
      ),
    },
    {
      title: 'ExportContacts.Sections.ExportOptions.FieldsSeparator.Title',
      content: (
        <RadioGroup verticalLayout>
          <Radio
            className={rootClass}
            label={t('ExportContacts.Sections.ExportOptions.FieldsSeparator')}
            checked={fieldsSeparator === Delimiter.Comma}
            onChange={() => update({ fieldsSeparator: Delimiter.Comma })}
            labelTypography={getTypographyProps(exportOptionsText[Delimiter.Comma], ',')}
          />
          <Radio
            className={rootClass}
            label={t('ExportContacts.Sections.ExportOptions.FieldsSeparator')}
            checked={fieldsSeparator === Delimiter.Tab}
            onChange={() => update({ fieldsSeparator: Delimiter.Tab })}
            labelTypography={getTypographyProps(exportOptionsText[Delimiter.Tab], 'Tab')}
          />
          <Radio
            className={rootClass}
            label={t('ExportContacts.Sections.ExportOptions.FieldsSeparator')}
            checked={fieldsSeparator === Delimiter.SemiColon}
            onChange={() => update({ fieldsSeparator: Delimiter.SemiColon })}
            labelTypography={getTypographyProps(exportOptionsText[Delimiter.SemiColon], ';')}
          />
        </RadioGroup>
      ),
    },
    {
      title: 'ImportContactsV2.Sections.ImportOptions.Quoted.Title',
      content: (
        <RadioGroup verticalLayout>
          <Radio
            className={rootClass}
            label={t('ImportContactsV2.Sections.ImportOptions.Quoted')}
            checked={fieldQuotes === Quote.SimpleQuote}
            onChange={() => update({ fieldQuotes: Quote.SimpleQuote })}
            labelTypography={getTypographyProps(exportOptionsText[Quote.SimpleQuote], "'")}
          />
          <Radio
            className={rootClass}
            label={t('ImportContactsV2.Sections.ImportOptions.Quoted')}
            checked={fieldQuotes === Quote.Quote}
            onChange={() => update({ fieldQuotes: Quote.Quote })}
            labelTypography={getTypographyProps(exportOptionsText[Quote.Quote], '"')}
          />
        </RadioGroup>
      ),
    },
  ]
}

export const getCronFromProgramSchedule = (schedule: ProgramSchedule): CronDtoInput | undefined => {
  const { isScheduled, hour, minute, interval, ampm, type } = schedule
  if (isScheduled) {
    let cron: CronDtoInput = {
      seconds: '0',
      minutes: minute?.toString(),
      hours: ((hour ?? 0) + (ampm ?? 0) * 12).toString(),
      dayOfMonth: '*',
      month: '*',
    }
    if (type === 'DAILY') {
      cron = {
        ...cron,
        hours: typeof interval === 'number' && interval !== 0 && !isNaN(interval) ? `${cron.hours}/${interval}` : cron.hours,
        dayOfMonth: '?',
        dayOfWeek: 'ALL' as DayOfWeek,
      }
    }
    if (type === 'WEEKLY') {
      const weekDays: DayOfWeek[] = WEEK_DAYS as DayOfWeek[]
      cron = {
        ...cron,
        dayOfMonth: '?',
        dayOfWeek: weekDays[(interval ?? exportOptionsDefaultValues.schedule.interval ?? 1) - 1],
      }
    }
    if (type === 'MONTHLY') {
      cron = {
        ...cron,
        dayOfMonth: interval?.toString(),
        dayOfWeek: 'NONE' as DayOfWeek,
      }
    }
    return cron
  }
}
