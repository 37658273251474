import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import './infoText.css'

const rootClass = 'info-text'

interface Props {
  children: ReactNode
  dataTest?: string
  title?: string
  className?: string
}

const InfoText: FC<Props> = (props: Props) => {
  const { dataTest = 'info-text', title, className } = props
  return (
    <h2 data-test={dataTest} className={classNames(rootClass, className)} title={title}>
      {props.children}
    </h2>
  )
}

export default InfoText
