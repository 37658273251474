import getExportSyncJobBySegmentIds from '@graphql/microservices/entity-upload/getExportSyncJobBySegmentIds'
import getFtpConnectionStatus from '@graphql/microservices/entity-upload/getFtpConnectionStatus'
import getFtpFiles from '@graphql/microservices/entity-upload/getFtpFiles'
import getImportSyncJobBySegmentIds from '@graphql/microservices/entity-upload/getImportSyncJobBySegmentIds'
import getLastSegmentExecution from '@graphql/microservices/entity-upload/getLastSegmentExecution'
import getLastSegmentExportExecution from '@graphql/microservices/entity-upload/getLastSegmentExportExecution'
import listExportSyncJobBySegmentId from '@graphql/microservices/entity-upload/listExportSyncJobBySegmentId'
import listImportSyncJobBySegmentId from '@graphql/microservices/entity-upload/listImportSyncJobBySegmentId'
import {
  ExportSyncJobDto,
  FtpFileDto,
  GetExportSyncJobBySegmentIdsQuery,
  GetExportSyncJobBySegmentIdsQueryVariables,
  GetFtpConnectionStatusQuery,
  GetFtpConnectionStatusQueryVariables,
  GetFtpFilesQuery,
  GetFtpFilesQueryVariables,
  GetImportSyncJobBySegmentIdsQuery,
  GetImportSyncJobBySegmentIdsQueryVariables,
  GetLastSegmentExecutionQuery,
  GetLastSegmentExecutionQueryVariables,
  GetLastSegmentExportExecutionQuery,
  GetLastSegmentExportExecutionQueryVariables,
  ImportSyncJobDto,
  ListExportSyncJobBySegmentIdQuery,
  ListExportSyncJobBySegmentIdQueryVariables,
  ListImportSyncJobBySegmentIdQuery,
  ListImportSyncJobBySegmentIdQueryVariables,
} from '@graphql/types/microservice/entity-upload-types'
import { filterNotEmptyArray } from '@utils/array'
import { FtpExecutionStatus, FtpExportExecutionStatus } from '@utils/contactSegments/contactSegments.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'

export interface ContactSegmentsEntityUploadServiceRequest {
  getLastSegmentExecutionRequest: (allContactsId?: string) => Promise<Record<string, FtpExecutionStatus>>
  getImportSyncJobListRequest: (segmentId?: string) => Promise<ImportSyncJobDto[]>
  getFtpConnectionStatusRequest: () => Promise<boolean>
  getFtpFilesRequest: () => Promise<FtpFileDto[]>
  getFtpSyncsBySegmentRequest: (params: GetImportSyncJobBySegmentIdsQueryVariables) => Promise<Record<string, ImportSyncJobDto[]>>
  getFtpExportSyncsBySegmentRequest: (params: GetExportSyncJobBySegmentIdsQueryVariables) => Promise<Record<string, ExportSyncJobDto[]>>
  getExportSyncJobListRequest: (segmentId?: string) => Promise<ExportSyncJobDto[]>
  getLastSegmentExportExecutionRequest: (allContactsId?: string) => Promise<Record<string, FtpExportExecutionStatus>>
}

export const useContactSegmentsEntityUploadServiceRequest = (): ContactSegmentsEntityUploadServiceRequest => {
  const { client: entityUploadClient } = useMicroserviceClient({ serviceName: MicroserviceClients.ENTITY_UPLOAD })

  const getFtpSyncsBySegmentRequest = (variables: GetImportSyncJobBySegmentIdsQueryVariables) => {
    return entityUploadClient
      .query<GetImportSyncJobBySegmentIdsQuery, GetImportSyncJobBySegmentIdsQueryVariables>({
        query: getImportSyncJobBySegmentIds,
        variables,
      })
      .then(({ data }) => data.getImportSyncJobBySegmentIds)
  }

  const getFtpExportSyncsBySegmentRequest = (variables: GetExportSyncJobBySegmentIdsQueryVariables) => {
    return entityUploadClient
      .query<GetExportSyncJobBySegmentIdsQuery, GetExportSyncJobBySegmentIdsQueryVariables>({
        query: getExportSyncJobBySegmentIds,
        variables,
      })
      .then(({ data }) => data.getExportSyncJobBySegmentIds)
  }

  const getLastSegmentExecutionRequest = (allContactsId?: string) =>
    entityUploadClient
      .query<GetLastSegmentExecutionQuery, GetLastSegmentExecutionQueryVariables>({
        query: getLastSegmentExecution,
        variables: {
          allContactsId,
        },
      })
      .then(({ data }) => {
        const ids = Object.keys(data.getLastSegmentExecution)
        const executions: Record<string, FtpExecutionStatus> = ids.reduce((mappedIds: Record<string, FtpExecutionStatus>, id: string) => {
          mappedIds[id] = {
            lastExecution: data.getLastSegmentExecution[id].lastExecution,
            running: data.getLastSegmentExecution[id].running,
            status: data.getLastSegmentExecution[id].status,
            previewImportSyncJobs: data.getLastSegmentExecution[id].previewImportSyncJobs,
          }
          return mappedIds
        }, {})
        return executions
      })

  const getImportSyncJobListRequest = (segmentId?: string) =>
    entityUploadClient
      .query<ListImportSyncJobBySegmentIdQuery, ListImportSyncJobBySegmentIdQueryVariables>({
        query: listImportSyncJobBySegmentId,
        variables: {
          segmentId: segmentId,
        },
      })
      .then(
        ({ data }) =>
          (data.listImportSyncJobBySegmentId?.filter((syncJob) => filterNotEmptyArray(syncJob) && syncJob.enabled) as ImportSyncJobDto[]) ?? []
      )

  const getFtpConnectionStatusRequest = () =>
    entityUploadClient
      .query<GetFtpConnectionStatusQuery, GetFtpConnectionStatusQueryVariables>({
        query: getFtpConnectionStatus,
      })
      .then(({ data }) => data.getFtpConnectionStatus)

  const getFtpFilesRequest = () =>
    entityUploadClient
      .query<GetFtpFilesQuery, GetFtpFilesQueryVariables>({
        fetchPolicy: 'network-only',
        query: getFtpFiles,
      })
      .then(({ data }) => data.getFtpFiles as FtpFileDto[])

  const getLastSegmentExportExecutionRequest = (allContactsId?: string) =>
    entityUploadClient
      .query<GetLastSegmentExportExecutionQuery, GetLastSegmentExportExecutionQueryVariables>({
        query: getLastSegmentExportExecution,
        variables: {
          allContactsId,
        },
      })
      .then(({ data }) => {
        const ids = Object.keys(data.getLastSegmentExportExecution)
        const executions: Record<string, FtpExportExecutionStatus> = ids.reduce((mappedIds: Record<string, FtpExportExecutionStatus>, id: string) => {
          mappedIds[id] = {
            lastExecution: data.getLastSegmentExportExecution[id].lastExecution,
            running: data.getLastSegmentExportExecution[id].running,
            status: data.getLastSegmentExportExecution[id].status,
            previewExportSyncJobs: data.getLastSegmentExportExecution[id].previewExportSyncJobs,
          }
          return mappedIds
        }, {})
        return executions
      })

  const getExportSyncJobListRequest = (segmentId?: string) =>
    entityUploadClient
      .query<ListExportSyncJobBySegmentIdQuery, ListExportSyncJobBySegmentIdQueryVariables>({
        query: listExportSyncJobBySegmentId,
        variables: {
          segmentId,
        },
      })
      .then(
        ({ data }) =>
          (data.listExportSyncJobBySegmentId?.filter((syncJob) => filterNotEmptyArray(syncJob) && syncJob.enabled) as ExportSyncJobDto[]) ?? []
      )

  return {
    getLastSegmentExecutionRequest,
    getImportSyncJobListRequest,
    getFtpConnectionStatusRequest,
    getFtpFilesRequest,
    getFtpExportSyncsBySegmentRequest,
    getFtpSyncsBySegmentRequest,
    getExportSyncJobListRequest,
    getLastSegmentExportExecutionRequest,
  }
}
