import { SelectOption } from '@components/NestedDropDown/NestedDropDown'

export const findSelectedOption = (options: SelectOption[], value: string): SelectOption | undefined => {
  for (const option of options) {
    if (option.mapping === value) {
      return option
    }
    if (option.nestedOptions) {
      const matchingOption = findSelectedOption(option.nestedOptions, value)
      if (matchingOption) {
        return matchingOption
      }
    }
  }
}

export const findSelectedOptionPath = (options: SelectOption[], value: string): SelectOption[] | undefined => {
  if (!value || options.length === 0) {
    return
  }

  const selectOption = options.find((opt) => opt.mapping === value)
  if (selectOption) {
    return [selectOption]
  }

  for (const option of options) {
    if (option.nestedOptions) {
      const nestedPath = findSelectedOptionPath(option.nestedOptions, value)
      if (nestedPath) {
        return [option, ...nestedPath]
      }
    }
  }
  return
}

export const searchNestedOptions = (options: SelectOption[], searchQuery: string): SelectOption[] => {
  const result: SelectOption[] = []
  for (const option of options) {
    if (option.name.toLowerCase().includes(searchQuery.toLowerCase())) {
      result.push({ ...option, filtered: false })
    }
    option.nestedOptions && searchNestedOptions(option.nestedOptions, searchQuery).forEach((option) => result.push(option))
  }
  return result
}
