import { default as getItem } from '@graphql/microservices/categorization/getItem'
import { GetItemQuery, GetItemQueryVariables } from '@graphql/types/microservice/categorization-types'
import { ItemType } from '@utils/categorization'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

interface useAssetPickerQueriesResponse {
  getItemRequest: (itemId: string) => FetchPromise<GetItemQuery>
}

export const useAssetPickerQueries = (type: ItemType = ItemType.FRAGMENT): useAssetPickerQueriesResponse => {
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })

  const getItemRequest = (itemId: string) => {
    return client.query<GetItemQuery, GetItemQueryVariables>({
      query: getItem,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        externalId: itemId,
        type,
      },
    })
  }

  return { getItemRequest }
}
