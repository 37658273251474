import React, { ComponentProps, FC, useContext } from 'react'

import classNames from 'classnames'

import ListingPagePreview from '@complex/ListingPage/Components/ListingPageModals/LisitingPagePreview/ListingPagePreview'
import ListingPageShareModalContainer from '@complex/ListingPage/Components/ListingPageModals/ListingPageShareModal/ListingPageShareModalContainer'
import { isInvalidTagName } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.utils'
import { ListingPageCommonContext } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { ListingPageStatusToast, removeItemFromFolderMessage } from '@complex/ListingPage/Utils/ListingPage.constants'
import BulkActionsModal from '@components/BulkActionsModal/BulkActionsModal'
import Checkbox from '@components/Checkbox'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import DeleteConfirmation, { DeleteConfirmationProps } from '@components/DeleteConfirmation/DeleteConfirmation'
import DuplicateFolderModal, { DuplicateFolderModalProps } from '@components/DuplicateModal/DuplicateFolderModal'
import ManageTagModal from '@components/ManageTagModal/ManageTagModal'
import MoveToFolderModal from '@components/MoveToFolderModal/MoveToFolderModal'
import NewAddFolderModal from '@components/NewAddFolder/NewAddFolderModal'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import StatusToast, { Status } from '@components/StatusToast/StatusToast'
import { TOAST_TEXT_CLASSNAME } from '@components/Toast/Toast'
import Typography, { ModalBodyStyle, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { FolderDto, ItemDto, LabelDto } from '@graphql/types/microservice/categorization-types'
import { useAccountSettings } from '@utils/account/account.utils'
import { ITEM_TYPES, ItemType } from '@utils/categorization'
import { useFetchLandingPageLazy } from '@utils/landingPages'
import { setItem } from '@utils/sessionStorage'

import { ListingPageSession } from '../../Utils/ListingPage.utils'

export interface ListPageModalsProps {
  className?: string
}

export enum DeleteConfirmationModals {
  DELETE_FOLDER = 'DELETE_FOLDER',
  DELETE_FOLDER_WITH_PARENT = 'DELETE_FOLDER_WITH_PARENT',
  DELETE_TAG = 'DELETE_TAG',
  DELETE_ITEMS = 'DELETE_ITEMS',
  DELETE_FROM_FOLDER = 'DELETE_FROM_FOLDER',
}

const listPageIncludesEmailSettings = ['Drafts']

const rootClass = 'list-page-modals'

const ListingPageModals: FC<ListPageModalsProps> = (props: ListPageModalsProps) => {
  const { className = '' } = props

  const {
    values,
    values: {
      showRemoveFromFolder,
      showShareToCatalog,
      showSalesUsersEnabled,
      folders,
      creatingFolder,
      showManageTag,
      itemTypesUsed,
      showMoveToFolder,
      addToFolder,
      tags,
      showDuplicate,
      tagToEdit,
      showBackButtonOnFormsPreviewModal,
      showCustomModal,
      showDeleteConfirmationModal,
      dataTest,
      statusToast,
      confirmationModal = DeleteConfirmationModals.DELETE_FOLDER,
      deleteConfirmationData,
      activeFolderId,
      hoveredFolder,
      isEditingFolder,
      selectedRows,
      customTableAction,
      showPreview,
      showShare,
      showBulkResponse,
      bulkResponseProps,
      errorMessage,
      listingPageProps: {
        renderCustomModal,
        tableProps: { listPage },
        sidebarProps: { allItemFilter },
      },
      statusToast: { showStatusToast, statusMessage, folderName },
    },
    ...listPageAPI
  } = useContext(ListingPageCommonContext)

  const { newLPComposerCreateBlank } = useAccountSettings()
  const {
    update,
    createFolder,
    renameFolder,
    deleteFolder,
    createTag,
    deleteTag,
    deleteItems,
    moveItemsToFolder,
    removeItemsFromFolder,
    shareToCatalog,
    duplicateItem,
  } = listPageAPI

  const { t } = useTranslation()
  const isLandingListingPage = window.location.href.includes('landingPages')
  const { showToggle, loading: isLPLoading } = useFetchLandingPageLazy(selectedRows[0]?.externalId, newLPComposerCreateBlank && isLandingListingPage)

  const onFolderCreate = (folder: FolderDto, editMode?: boolean) => {
    const isEditing = editMode ?? isEditingFolder
    !isEditing ? createFolder(folder) : renameFolder(folder)
    update({
      hoveredFolder: undefined,
      isEditingFolder: false,
    })
  }

  const onDeleteFolder = (deleteConfirmationData: FolderData) => {
    deleteFolder(deleteConfirmationData)
    onDeleteConfirmationClose()
  }

  const onDeleteTag = (deleteConfirmationData: LabelDto) => {
    deleteTag(deleteConfirmationData)
    onDeleteConfirmationClose()
  }

  const onDeleteItems = (deleteConfirmationData: ItemDto[]) => {
    deleteItems(deleteConfirmationData)
    onDeleteConfirmationClose()
  }

  const moveToFolderProps = {
    addToFolder,
    folders,
    isOpen: showMoveToFolder ?? false,
    movableItems: selectedRows.map((item) => ({ id: item.id, name: item.name ?? '', folderId: item.folderId })),
    onMoveClick: (folderId: number) => onMoveClick(folderId),
    onClose: () => update({ showMoveToFolder: false }),
  }

  const moveItemsIntoFolder = (folderId: number) => moveItemsToFolder(folderId)

  const onMoveClick = (folderId: number) => {
    if (selectedRows.length) {
      moveItemsIntoFolder(folderId)
    }
    update({ showMoveToFolder: false })
  }

  const onDeleteConfirmationClose = () => {
    update({
      deleteConfirmationData: undefined,
      confirmationModal: undefined,
      showDeleteConfirmationModal: false,
    })
  }

  const previewModalProps = () => {
    return {
      itemName: selectedRows[0]?.name ?? '',
      itemId: selectedRows[0]?.externalId ?? '',
      isOpen: showPreview ?? false,
      onClose: () =>
        update({
          showPreview: false,
          previewHtml: undefined,
          previewUrl: undefined,
          ...(showBackButtonOnFormsPreviewModal ? { showCustomModal: false } : {}),
        }),
      onBack: showBackButtonOnFormsPreviewModal ? () => update({ showPreview: false, previewHtml: undefined, previewUrl: undefined }) : undefined,
    }
  }

  const shareModalProps = () => ({
    selectedItems: {
      id: selectedRows[0]?.id ?? '',
      name: selectedRows[0]?.name ?? '',
    },
    isOpen: showShare ?? false,
    onClose: () => update({ showShare: false }),
  })

  const addFolderProps: ComponentProps<typeof NewAddFolderModal> = {
    folders,
    hoveredFolder,
    isEditing: isEditingFolder,
    isOpen: creatingFolder && !isEditingFolder,
    nestedWithin: hoveredFolder ? (isEditingFolder ? hoveredFolder.parentId : hoveredFolder.id) : activeFolderId,
    onCreate: onFolderCreate,
    onModalSave: (statusToast: object) => update({ statusToast: { ...(statusToast as ListingPageStatusToast) } }),
    onClose: () => {
      update({
        creatingFolder: false,
        hoveredFolder: undefined,
        isEditingFolder: false,
      })
    },
  }

  const renameFolderProps = {
    ...addFolderProps,
    isOpen: isEditingFolder && !creatingFolder,
    onClose: () => {
      update({
        hoveredFolder: undefined,
        isEditingFolder: false,
      })
    },
  }

  const duplicateProps: DuplicateFolderModalProps = {
    newLPComposerCreateBlank,
    activeFolderId: selectedRows[0]?.folderId ?? activeFolderId,
    isBee: selectedRows[0]?.beeComposer,
    isLandingListingPage,
    folderAllOptionLabel: allItemFilter.name,
    descriptionKey: `ListPage.${listPage}.Duplicate.Description`,
    folders,
    loading: isLPLoading,
    headerTitleKey: `ListPage.${listPage}.Duplicate.Title`,
    itemName: selectedRows[0]?.name ?? '',
    itemToCloneTags: selectedRows[0]?.tags,
    namePlaceholder: `ListPage.${listPage}.Duplicate.NamePlaceholder`,
    includeEmailSettings: listPageIncludesEmailSettings.includes(listPage),
    showLPToggleToIncludeChanges: showToggle,
    onCancel: () => update({ showDuplicate: false }),
    onClone: async (newName, tags, folder, includeRecipients, includeSuppressionRules, includeUnPublishedChanges) => {
      await duplicateItem(
        {
          listingPageItem: selectedRows[0],
          newName,
          tags,
          folderId: folder,
          includeRecipients,
          includeSuppressionRules,
          includeUnPublishedChanges,
        },
        listPageAPI
      )
      update({ fetchFilterCounts: true, fetchFolders: true, fetchTags: true })
    },
    onTagCreate: createTag,
    tags,
  }

  const getStatusMessage = () => {
    if (folderName) {
      return (
        <>
          <Typography className={'ellip'} text={folderName} weight={TextWeight.MEDIUM} inline />
          <Typography text={` ${t(statusMessage)}`} inline />
        </>
      )
    } else if (typeof statusMessage === 'string') {
      return <Typography text={` ${t(statusMessage)}`} />
    } else {
      return statusMessage
    }
  }

  const showStatusProps = () => {
    const navWidth = document.getElementsByClassName('navigation__body')[0]?.clientWidth

    return {
      ...statusToast,
      message: <div className={classNames(TOAST_TEXT_CLASSNAME, { [`${className}__toast`]: className })}>{getStatusMessage()}</div>,
      closeStatus: () => update({ statusToast: { ...statusToast, showStatusToast: false } }),
      style: {
        marginLeft: navWidth ? navWidth / 2 : 0,
      },
    }
  }

  const getDeleteTagProps = () => {
    const itemTypesUsedEntries = Object.entries(itemTypesUsed)
    let text
    if (!!itemTypesUsedEntries.length) {
      text = itemTypesUsedEntries.reduce((curr, entry, index) => {
        const completeItemText = ITEM_TYPES[entry[0] as ItemType]?.completeItemText
        return `${curr + (index > 0 ? (index === itemTypesUsedEntries.length - 1 ? ` ${t('and')}` : ',') : '')} ${t(completeItemText)} (${entry[1]})${
          index === itemTypesUsedEntries.length - 1 ? '. ' : ''
        }`
      }, t('ListPage.Common.Tags.Delete.Used'))
      text += t('ListPage.Common.Tags.Delete.Used.Info')
    } else {
      text = 'ListPage.Common.Tags.Delete.NotUsed'
    }

    return {
      title: 'Are you sure?',
      text,
      deleteAction: () => onDeleteTag(deleteConfirmationData),
      onClose: () => onDeleteConfirmationClose(),
    }
  }

  const getDeleteConfirmationModalProps = (): DeleteConfirmationProps => {
    const deleteDataText = deleteConfirmationData
      ? deleteConfirmationData?.length === 1
        ? deleteConfirmationData[0].name
        : deleteConfirmationData.length
      : selectedRows.length === 1
      ? selectedRows[0]?.name
      : selectedRows.length
    const length = deleteConfirmationData ? deleteConfirmationData.length : selectedRows.length
    const haveDifferentFolders = [...new Set(selectedRows.map(({ folderId }) => folderId))].length > 1 ? '.DifferentFolders' : ''

    const deleteConfirmationModalsItems: { [key: string]: DeleteConfirmationProps } = {
      [DeleteConfirmationModals.DELETE_FOLDER]: {
        title: 'ListPage.Common.DeleteFolder.Title',
        text: 'ListPage.Common.DeleteFolder.Text',
        textBold: t(`ListPage.${listPage}.DeleteFolder.BoldText`),
        deleteAction: () => onDeleteFolder(deleteConfirmationData),
        onClose: () => onDeleteConfirmationClose(),
      },
      [DeleteConfirmationModals.DELETE_FOLDER_WITH_PARENT]: {
        title: 'ListPage.Common.DeleteFolder.Title',
        text: `ListPage.Common.DeleteFolder.Text`,
        textBold: t(`ListPage.${listPage}.DeleteFolder.BoldText_other`),
        deleteAction: () => onDeleteFolder(deleteConfirmationData),
        onClose: () => onDeleteConfirmationClose(),
      },
      [DeleteConfirmationModals.DELETE_TAG]: getDeleteTagProps(),
      [DeleteConfirmationModals.DELETE_ITEMS]: {
        title: t(`ListPage.${listPage}.DeleteItem.Title`, { count: length }),
        text: t(`ListPage.${listPage}.DeleteItem`, { count: length, deleteDataText }),
        textBold: t('You cannot undo this action.'),
        deleteAction: () => onDeleteItems(deleteConfirmationData),
        onClose: () => onDeleteConfirmationClose(),
      },
      [DeleteConfirmationModals.DELETE_FROM_FOLDER]: {
        title: t(`ListPage.${listPage}.DeleteFromFolder.Title`, { count: selectedRows.length }),
        isRemove: true,
        deleteAction: removeItemsFromFolder,
        onClose: () => update({ showRemoveFromFolder: false }),
        content: removeItemFromFolderMessage(
          selectedRows,
          folders,
          `ListPage.${listPage}.DeleteFromFolder${haveDifferentFolders}`,
          haveDifferentFolders,
          activeFolderId
        ),
      },
    }

    return deleteConfirmationModalsItems[confirmationModal]
  }

  const deleteConfirmationProps = {
    ...getDeleteConfirmationModalProps(),
    buttonText: t('Delete'),
    className: `${rootClass}__delete-confirmation`,
  }

  const manageTagProps = {
    tag: tagToEdit,
    isOpen: showManageTag,
    onClose: () => {
      update({ showManageTag: false })
    },
    onCreate: (tag: LabelDto) => {
      if (isInvalidTagName(tags, tag)) {
        update({
          showManageTag: false,
          statusToast: {
            statusMessage: 'ListPage.Common.Tags.AlreadyExists',
            status: Status.FAIL,
            showStatusToast: true,
          },
        })
      } else {
        createTag(tag)
      }
    },
  }

  const shareToCatalogProps = () => {
    return {
      title: t('ListPage.Common.ShareToCatalog.Title', { count: selectedRows.length }),
      isOpen: showShareToCatalog,
      body: (
        <Typography
          text={t('ListPage.Common.ShareToCatalog', { count: selectedRows.length, itemName: selectedRows[0]?.name })}
          tagProps={{ bold: { weight: TextWeight.BOLD } }}
          inline
          {...ModalBodyStyle}
        />
      ),
      onAnswer: (answer: YesNo) => {
        if (answer === YesNo.YES) {
          shareToCatalog()
        }
        update({ showShareToCatalog: false })
      },
    }
  }

  const showSalesUsersEnabledProps = () => {
    return {
      title: t('ListPage.Common.SalesUsers.Enabled.Title'),
      body: t('ListPage.Common.SalesUsers.Enabled'),
      okButtonText: t('Accept'),
      closeModal: () => update({ showSalesUsersEnabled: false }),
      footerActionElement: (
        <Checkbox
          label={t(`Don't show this message again`)}
          onChange={(isChecked) => setItem(ListingPageSession.SALES_USERS_ENABLED_CONFIRMATION, `${!isChecked}`)}
          dataTest={`${dataTest}-footer-checkbox`}
        />
      ),
      isOpen: true,
    }
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {showDeleteConfirmationModal && <DeleteConfirmation {...deleteConfirmationProps} />}
      {showStatusToast && <StatusToast {...showStatusProps()} />}
      {(creatingFolder || isEditingFolder) && <NewAddFolderModal {...(isEditingFolder ? renameFolderProps : addFolderProps)} />}
      {showManageTag && <ManageTagModal {...manageTagProps} />}
      {showMoveToFolder && <MoveToFolderModal {...moveToFolderProps} />}
      {showShareToCatalog && <ConfirmationModal {...shareToCatalogProps()} isYesNo />}
      {showRemoveFromFolder && <DeleteConfirmation {...deleteConfirmationProps} />}
      {showPreview && <ListingPagePreview {...previewModalProps()} />}
      {showShare && <ListingPageShareModalContainer {...shareModalProps()} />}
      {showSalesUsersEnabled && <ConfirmationModal {...showSalesUsersEnabledProps()} />}
      {showBulkResponse && bulkResponseProps && <BulkActionsModal {...bulkResponseProps} />}
      {showDuplicate && <DuplicateFolderModal {...duplicateProps} />}
      {showCustomModal && renderCustomModal && renderCustomModal(customTableAction, values, listPageAPI, errorMessage)}
    </div>
  )
}

export default ListingPageModals
