import React, { FC } from 'react'

import classNames from 'classnames'

import Typography, { TextType } from '@components/Typography/Typography'

import './EmptyList.css'

interface Props {
  message: string
  isPicker?: boolean
  required?: boolean
}

const EmptyList: FC<Props> = ({ message, isPicker, required }: Props) => {
  const containerClasses = classNames('empty-list-ctr', {
    ['empty-list-ctr__picker']: isPicker,
  })
  return (
    <div className={containerClasses} aria-required={required} data-test="empty-list">
      <Typography text={`${message}`} className={'empty-list'} type={TextType.BODY_TEXT_GRAY} />
    </div>
  )
}

export default EmptyList
