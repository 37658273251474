import React, { BaseSyntheticEvent, FC, useCallback, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import FormRow from '@components/FormRow'
import InputWithStatus from '@components/InputWithStatus/InputWithStatus'
import Label from '@components/Label'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import ImageURLChecker from '@components/UploadImage/components/ImageURLChecker'
import { imageUrlIsValid } from '@components/UploadImage/components/utils'
import { useTranslation } from '@const/globals'

import './InsertURLModal.css'

interface Props {
  isOpen: boolean
  imgUrl?: string
  onRemove?: (imgUrl: string) => void
  onClose: () => void
  onSave: (imgUrl: string) => void
  dataTest?: string
}

const rootClass = 'insert-url__modal'

const InsertURLModal: FC<Props> = (props: Props) => {
  const { isOpen, imgUrl = '', onRemove, onClose, onSave, dataTest = rootClass } = props
  const { t } = useTranslation()
  const [isValid, setIsValid] = useState<boolean>(false)
  const [url, setURL] = useState<string>('')

  const handleSave = useCallback(() => url && onSave(url), [onSave, url])
  const handleClose = useCallback(() => {
    setIsValid(false)
    setURL('')
    onClose()
  }, [onClose])

  const handleRemove = useCallback(() => {
    onRemove?.(url)
  }, [onRemove, url])

  const handleURLChange = useCallback((event: BaseSyntheticEvent) => {
    const url = event.target.value
    setIsValid(false)
    setURL(url)
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      className={rootClass}
      dataTest={dataTest}
      header={
        <ModalHeader headerType={ModalHeaderType.Form} dataTest={`${dataTest}-header`}>
          {t('Insert.URL.Modal.Title')}
        </ModalHeader>
      }
    >
      <ModalBody>
        {imageUrlIsValid(url) ? <ImageURLChecker url={url} onStatusChange={setIsValid} /> : null}
        <FormRow>
          <Label>{t('Source URL')}</Label>
          <InputWithStatus
            fullWidth
            onChange={handleURLChange}
            showStatus={!!url}
            hasCustomError={url ? !isValid : undefined}
            customTooltipErrorMessages={'Insert.Image.Invalid.URL'}
            tooltipProps={{ position: 'top', align: 'end' }}
            onChangeDebounce={500}
            className={`${rootClass}-input`}
            dataTest={`${dataTest}-input`}
          />
          <Typography text="Valid.Image.Formats.Message" inline type={TextType.BODY_TEXT_LIGHT} tagProps={{ bold: { weight: TextWeight.BOLD } }} />
        </FormRow>
      </ModalBody>
      <ModalFooter
        footerType={ModalFooterType.Form}
        className={classNames(rootClass, {
          [`${rootClass}__remove-left`]: imgUrl,
        })}
      >
        <>
          {imgUrl && (
            <div>
              <Button buttonType={ButtonType.REMOVE} onClick={handleRemove} dataTest={`${dataTest}-remove-button`}>
                {t('Remove')}
              </Button>
            </div>
          )}
          <div className={`${rootClass}-right-actions`}>
            <Button buttonType={ButtonType.TERTIARY} onClick={handleClose} dataTest={`${dataTest}-cancel-button`}>
              {t('Cancel')}
            </Button>
            <Button buttonType={ButtonType.PRIMARY} onClick={handleSave} disabled={!url || !isValid} dataTest={`${dataTest}-save-button`}>
              {t('Save')}
            </Button>
          </div>
        </>
      </ModalFooter>
    </Modal>
  )
}

export default InsertURLModal
