import { gql } from '@apollo/client'

export default gql`
  mutation setWebhookEnabledStatus($webhookId: String!, $enabled: Boolean!) {
    setWebhookEnabledStatus(webhookId: $webhookId, enabled: $enabled) {
      status
      body
    }
  }
`
