import { Folder } from '@interface/foldersLists/Folder'

export const CONTACTS_FOLDER_ID = -3
export const FAVORITES_FOLDER_ID = -2
export const DEFAULT_FOLDER_ID = -1
export const DEFAULT_FOLDER_NAME = 'Default Folder'

export function getFolderName(folder: Folder, t: Function) {
  if (folder.isContacts || folder.id === CONTACTS_FOLDER_ID) {
    return t('Contacts')
  }
  if (folder.id === FAVORITES_FOLDER_ID) {
    return t('Favorites')
  }
  if (folder.isDefault || folder.id === DEFAULT_FOLDER_ID) {
    return t('Default Folder')
  }
  return folder.name
}

export default {
  getFolderName,
}
