import { gql } from '@apollo/client'

export default gql`
  mutation declineInboxAssets($inboxAssets: [InboxAsset]) {
    declineInboxAssets(inboxAssets: $inboxAssets) {
      successDeclinedIds
      failedDeclinedIds
      sharedAccountId
    }
  }
`
