import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { Program, ProgramSegmentDetails } from '@graphql/types/query-types'
import { SEGMENT_TYPES } from '@src/pages/programs/edit/components/ProgramFlow/components/ConditionalSelect/segmentOptions'

interface MessageIcon {
  text: string
  icon?: string
  isList: boolean
}

export function getConditionalText(not: boolean, segmentDetails?: ProgramSegmentDetails) {
  if (!segmentDetails) {
    if (not) {
      return 'Is not in'
    } else {
      return 'Is in'
    }
  }
  if (segmentDetails.name === SEGMENT_TYPES.MESSAGE_OPEN) {
    if (not) {
      return 'Was sent and did not open or click'
    }
    return 'Opened'
  }
  if (segmentDetails.name === SEGMENT_TYPES.MESSAGE_CLICK) {
    if (not) {
      return 'Was sent and did not click on'
    }
    return 'Clicked on'
  }
  if (segmentDetails.name === SEGMENT_TYPES.MESSAGE_SEND) {
    if (not) {
      return 'Was not sent'
    }
    return 'Was sent'
  }
  if (segmentDetails.name === SEGMENT_TYPES.MESSAGE_SENT_NOT_OPEN) {
    return 'Was sent and did not open'
  }
  if (segmentDetails.name === SEGMENT_TYPES.MESSAGE_SENT_NOT_CLICK) {
    return 'Was sent and did not click on'
  }
  if (segmentDetails.name === SEGMENT_TYPES.MESSAGE_SENT_NOT_OPEN_CLICK) {
    return 'Was sent and did not open or click'
  }
  return 'Was sent'
}

export function getSourceIcon(srcId: string) {
  if (
    srcId.substr(0, 'l-sf-'.length) == 'l-sf-' ||
    srcId.substr(0, 'l-sg-'.length) == 'l-sg-' ||
    srcId.substr(0, 'l-dyn-'.length) == 'l-dyn-' ||
    srcId.substr(0, 'l-sl-'.length) == 'l-sl-' ||
    srcId.substr(0, 'l-ns-'.length) == 'l-ns-'
  ) {
    return 'crm'
  } else if (srcId.substr(0, 'g-'.length) == 'g-') {
    return 'segment'
  } else if (srcId.substr(0, 'e-'.length) == 'e-') {
    return 'extension'
  }
  return 'uploaded'
}

export function getMessageDisplayText(program: Program, srcId: string): MessageIcon {
  const message = program.messages.find((message) => message.id === srcId)
  if (message) {
    return {
      text: message.name,
      icon: 'letter',
      isList: false,
    }
  }
  const conditionalMessage = program.conditionalMessages?.find((message) => message.id === srcId)
  if (conditionalMessage) {
    return {
      text: conditionalMessage.name,
      icon: 'letter',
      isList: false,
    }
  }
  if (srcId === 'nosend') {
    return {
      text: 'Do Not Send',
      icon: 'letter-no-send',
      isList: false,
    }
  }

  return {
    text: '--- message ---',
    icon: 'letter',
    isList: false,
  }
}

export function getDisplayText(program: Program, exitChoiceSrcId: string): MessageIcon[] {
  const message = program.messages.find((message) => message.id === exitChoiceSrcId)
  if (message) {
    return [
      {
        text: message.name,
        icon: 'emailSolid',
        isList: false,
      },
    ]
  }
  const segment = program.localSegmentDetails.find((segment) => segment.id === exitChoiceSrcId)
  if (segment) {
    const displayText = segment.idset?.map((id) => {
      return getMessageDisplayText(program, id)
    })
    if (!displayText || displayText.length === 0) {
      return [
        {
          text: '-- none selected --',
          isList: true,
        },
      ]
    }
    return displayText
  }
  const source = program.sources.find((source) => source.id === exitChoiceSrcId)
  if (source) {
    return [
      {
        text: source.name,
        icon: getSourceIcon(source.id),
        isList: true,
      },
    ]
  }
  return [
    {
      text: '-- none selected --',
      isList: true,
    },
  ]
}

export const getSelectedSegment = async (getSegmentById: (segmentId: string) => Promise<ItemDto | undefined>, segmentId?: string) => {
  if (segmentId) {
    const selectedSegment = await getSegmentById(segmentId)
    if (selectedSegment) {
      const { name = '' } = JSON.parse(selectedSegment?.item ?? '{}')
      return { label: name, value: segmentId }
    }
  }
}
