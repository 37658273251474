import React from 'react'

import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import { TypographyProps } from '@components/Typography/Typography'
import { OptInsSourceList } from '@src/pages/sms/marketingListOptIns/components/OptInsSourceLists/OptInsSourceLists.constants'
import { CellContext } from '@tanstack/react-table'
import { getStandardFormattedDate } from '@utils/date'

export const renderDateCell = (row: any) => getStandardFormattedDate(new Date(row.cell.value).valueOf(), true)

export const renderDateCellV2 = <T,>(cell: CellContext<T, unknown>) => {
  return getStandardFormattedDate(new Date(cell.cell.getValue<number>()).valueOf(), true)
}

export const renderWithIcon = (
  className: string,
  row: CellContext<OptInsSourceList, unknown>,
  icon: JSX.Element,
  weight?: TypographyProps['weight']
) => (
  <div className={className}>
    <>{icon}</>
    <TextWithTooltipOnEllip
      typographyProps={{
        weight,
        text: `${row.cell.getValue()}`,
      }}
    />
  </div>
)

export const renderDate = (row: CellContext<OptInsSourceList, unknown>) => {
  return getStandardFormattedDate(new Date(row.renderValue() as string).valueOf(), true)
}
