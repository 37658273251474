import { useCallback } from 'react'

import { useApolloClient } from '@apollo/client'
import retrieveMediaFilesByFolder from '@graphql/queries/retrieveMediaFilesByFolder'
import { RetrieveMediaFilesByFolderQuery, RetrieveMediaFilesByFolderQueryVariables } from '@graphql/types/query-types'
import { FetchPromise } from '@utils/types'

import { MediaPickerModalType } from './MediaPickerModal.utils'

export interface MediaPickerModalRequests {
  retrieveMediaFilesByFolderRequest: (contentType?: MediaPickerModalType) => FetchPromise<RetrieveMediaFilesByFolderQuery>
}

export const useMediaPickerModalRequests = (): MediaPickerModalRequests => {
  const actonClient = useApolloClient()

  const retrieveMediaFilesByFolderRequest = useCallback(
    async (contentType?: MediaPickerModalType) =>
      await actonClient.query<RetrieveMediaFilesByFolderQuery, RetrieveMediaFilesByFolderQueryVariables>({
        query: retrieveMediaFilesByFolder,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        variables: {
          mediaType: 'DOC',
          favoriteOnly: false,
          contentType,
        },
      }),
    [actonClient]
  )

  return {
    retrieveMediaFilesByFolderRequest,
  }
}
