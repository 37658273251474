import { useCallback, useContext } from 'react'

import { useApolloClient } from '@apollo/client'
import { NO_PERSONALIZATION_FOUND_ERROR_CODE } from '@const/globals'
import checkPersonalizationFields from '@graphql/queries/checkPersonalizationFields'
import { CheckPersonalizationFieldsQuery, CheckPersonalizationFieldsQueryVariables } from '@graphql/types/query-types'
import { filterNotEmptyArray } from '@utils/array'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'

import { useAddDynamicImagePersonalizationToHtml } from './useAddDynamicImagePersonalizationToHtml'

const useCheckPersonalizationFieldsRequest = () => {
  const {
    values: {
      message: { id },
    },
    api: { updateValidations },
  } = useContext(EmailComposerContext)
  const client = useApolloClient()

  const updatedDynamicHtml = useAddDynamicImagePersonalizationToHtml()

  return useCallback(() => {
    const handleError = () => updateValidations({ personalization: { personalizationErrors: null, noContent: false } })
    const lastChecked = Date.now()

    return client
      .query<CheckPersonalizationFieldsQuery, CheckPersonalizationFieldsQueryVariables>({
        query: checkPersonalizationFields,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        variables: {
          msgId: id,
          html: updatedDynamicHtml.replace(/\s+/g, ' '),
        },
      })
      .then(({ data, errors }) => {
        if (data?.checkPersonalizationFields) {
          updateValidations({
            personalization: { personalizationErrors: data.checkPersonalizationFields.filter(filterNotEmptyArray), lastChecked, noContent: false },
          })
        } else {
          const errorCode = errors ? (errors[0] ? errors[0].extensions?.errorCode : null) : null
          if (errorCode === NO_PERSONALIZATION_FOUND_ERROR_CODE) {
            updateValidations({ personalization: { noContent: true, personalizationErrors: undefined, lastChecked } })
          } else {
            handleError()
          }
        }
      })
      .catch(() => handleError())
  }, [client, updateValidations, id, updatedDynamicHtml])
}

export default useCheckPersonalizationFieldsRequest
