import { useCallback } from 'react'

import { ApolloError, useApolloClient, useMutation, useQuery } from '@apollo/client'
import { TableV2RowData } from '@components/TableV2/tableV2TS/interfaces'
import sendTestMessage from '@graphql/mutations/sendTestMessage'
import getPersonalizationsMissingFallback from '@graphql/queries/getPersonalizationsMissingFallback'
import getSendTestEmailInfo from '@graphql/queries/getSendTestEmailInfo'
import { SendTestEmailInput, SendTestMessageMutation, SendTestMessageMutationVariables } from '@graphql/types/mutation-types'
import {
  GetPersonalizationsMissingFallbackQuery,
  GetPersonalizationsMissingFallbackQueryVariables,
  GetSendTestEmailInfoQuery,
  GetSendTestEmailInfoQueryVariables,
  ListContactGq,
  SendTestEmailInfo as SendTestEmailInfoResponse,
  SendTestEmailSegment,
} from '@graphql/types/query-types'
import { PersonalizationField } from '@src/pages/EmailComposer/EmailModals/components/SendTestEmailModal/components/SendTestEmailPersonalizationModal/SendTestEmailPersonalizationModal'
import { TEST_EMAIL_DEFAULT_SUBJECT_LINE } from '@src/pages/EmailComposer/EmailModals/components/SendTestEmailModal/constants/SendTestEmailModal.constants'
import { useWaitForEmailSaveEnd } from '@src/pages/EmailComposer/hooks/useWaitForEmailSaveEnd'
import { filterNotEmptyArray } from '@utils/array'
import { FilterTypes } from '@utils/filter'
import { logNewRelicError } from '@utils/new-relic.utils'

export interface TestSegment extends TableV2RowData<TestSegment> {
  id: string
  baseId: string
  count: number
  lastCounted: number
  lastModified: number
  name: string
  contacts: ListContactGq[]
}

export interface SendTestEmailInfo {
  loading: boolean
  error: ApolloError | undefined
  subjectPrefix: string
  segments: TestSegment[]
}

const buildTestSegmentHierarchy = (
  currentSegment: TestSegment | undefined,
  segments: GetSendTestEmailInfoQuery['getSendTestEmailInfo']['segments'] | undefined,
  depth = 0,
  maxDepth = 10
): TestSegment | undefined => {
  if (!currentSegment || !segments || depth >= maxDepth) {
    return undefined
  }
  const subSegments = segments
    .filter(filterNotEmptyArray)
    .filter((segment) => segment.baseId === currentSegment.id)
    .map((segment) => buildTestSegmentHierarchy(getTestSegment(segment), segments, depth + 1))
    .filter(filterNotEmptyArray)
  return { ...currentSegment, subRows: subSegments }
}

const getTestSegment = (segment: SendTestEmailSegment | SendTestEmailInfoResponse | undefined): TestSegment | undefined => {
  if (!segment) {
    return
  }
  return {
    id: segment.id,
    baseId: segment.baseId,
    count: segment.count,
    lastCounted: Number(segment.lastCounted),
    lastModified: Number(segment.lastModified),
    name: segment.name,
    contacts: segment.testContacts.filter(filterNotEmptyArray) ?? [],
  }
}

export const useSendTestEmailInfo = () => {
  const client = useApolloClient()

  const { data, error, loading } = useQuery<GetSendTestEmailInfoQuery, GetSendTestEmailInfoQueryVariables>(getSendTestEmailInfo, {
    client,
    fetchPolicy: 'network-only',
  })
  const info = data?.getSendTestEmailInfo
  const segmentHierarchy = [buildTestSegmentHierarchy(getTestSegment(info), info?.segments.filter(filterNotEmptyArray) ?? [])]
  const result: SendTestEmailInfo = {
    loading,
    error,
    subjectPrefix: info?.subjectPrefix || TEST_EMAIL_DEFAULT_SUBJECT_LINE,
    segments: segmentHierarchy.filter(filterNotEmptyArray),
  }
  return result
}

export interface SendTestMessageProps {
  handleTestSend: (vars: SendTestEmailInput, uuidsForDisplayConditions: string[]) => Promise<boolean>
  loadingSend: boolean
}

export const useSendTestEmail = (): SendTestMessageProps => {
  const client = useApolloClient()
  const [sendTestMutation] = useMutation<SendTestMessageMutation, SendTestMessageMutationVariables>(sendTestMessage, {
    client,
    fetchPolicy: 'no-cache',
  })

  const { waitForSaveEnd, loading: loadingSend } = useWaitForEmailSaveEnd<boolean>()

  const handleTestSend = useCallback(
    (sendTestEmailInput: SendTestEmailInput, uuidsForDisplayCondition: string[]) =>
      waitForSaveEnd(() =>
        sendTestMutation({ variables: { sendTestEmailInput, uuidsForDisplayCondition } }).then(({ data }) => {
          if (!data) {
            return Promise.reject(false)
          }
          return data.sendTestMessage
        })
      ),
    [sendTestMutation, waitForSaveEnd]
  )
  return { handleTestSend, loadingSend }
}

export interface CheckForPersonalizationProps {
  handleCheckPersonalization: (vars: GetPersonalizationsMissingFallbackQueryVariables) => Promise<PersonalizationField[] | undefined>
  loadingSend: boolean
}

export const useCheckForPersonalization = (): CheckForPersonalizationProps => {
  const client = useApolloClient()

  const { waitForSaveEnd, loading: loadingSend } = useWaitForEmailSaveEnd<PersonalizationField[] | undefined>()

  const handleCheckPersonalization = useCallback(
    (variables: GetPersonalizationsMissingFallbackQueryVariables) =>
      waitForSaveEnd(() =>
        client
          .query<GetPersonalizationsMissingFallbackQuery, GetPersonalizationsMissingFallbackQueryVariables>({
            query: getPersonalizationsMissingFallback,
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
            variables,
          })
          .then((result) => {
            const { data, errors } = result
            if (!data || errors) {
              if (errors) {
                logNewRelicError(errors)
              }
              return undefined
            }
            return data.getPersonalizationsMissingFallback.map(
              (personalization): PersonalizationField => ({
                name: personalization.personalization,
                type: personalization.groupId as FilterTypes,
                value: '',
              })
            )
          })
      ),
    [client, waitForSaveEnd]
  )

  return { handleCheckPersonalization, loadingSend }
}
