import React, { ChangeEvent, FC } from 'react'
import { FieldValues, UseFormRegister } from 'react-hook-form'

import Checkbox from '@components/Checkbox/Checkbox'
import Input from '@components/Input/Input'
import Select from '@components/Select/Select'
import { useTranslation } from '@const/globals'
import { FieldDefinition, StringKeyValue } from '@graphql/types/query-types'

import ReferenceSelect from './ReferenceSelect'

export enum DATA_TYPE {
  PRIMARYKEY = 'PRIMARYKEY',
  REFERENCE = 'REFERENCE',
  STRING = 'STRING',
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  BOOLEAN = 'BOOLEAN',
  INTEGER = 'INTEGER',
  NUMERIC = 'NUMERIC',
  CURRENCY = 'CURRENCY',
  PERCENT = 'PERCENT',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  URL = 'URL',
  PICKLIST = 'PICKLIST',
  MUILTIPICKLIST = 'MULTIPICKLIST',
  IMAGE = 'IMAGE',
}

const DATE_ITEMS: { [key: string]: string } = {
  day_0: 'Immediately',
  day_1: 'One Day',
  day_2: 'Two Days',
  day_3: 'Three Days',
  week_1: 'One Week',
  week_2: 'Two Weeks',
  week_3: 'Three Weeks',
  month_1: 'One Month',
  month_2: 'Two Months',
  month_3: 'Three Months',
}

interface Props {
  fieldDefinition: FieldDefinition
  register: UseFormRegister<FieldValues>
  value: any
  updateField(field: StringKeyValue): void
  dataTest?: string
}

const getPickListOptions = (pickListOptions: string[], t: Function) =>
  pickListOptions &&
  pickListOptions.reduce(
    (acc, cur) => {
      return [
        ...acc,
        <option key={cur} value={cur}>
          {`${cur}`}
        </option>,
      ]
    },
    [
      <option key="empty" value="">
        --- {t('Select')} ---
      </option>,
    ]
  )

const getDateOptions = (t: Function) =>
  Object.keys(DATE_ITEMS).reduce(
    (acc, key: string) => {
      return [
        ...acc,
        <option key={key} value={key}>
          {`${DATE_ITEMS[key]}`}
        </option>,
      ]
    },
    [
      <option key="empty" value="">
        --- {t('Select Date')} ---
      </option>,
    ]
  )

export const CRMStepComponent: FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { fieldDefinition, register, value, updateField } = props

  const handleChange = (e: any) => {
    updateField(e.target.value)
  }

  if (!fieldDefinition) {
    return null
  }
  const handleCheckChange = (checked: any) => {
    const newValue = checked
    updateField(typeof value === 'string' ? newValue.toString() : value)
  }

  switch (fieldDefinition.dataType) {
    // Currency and Integer do not have 1 to 1 mapping and can include multiple values with CRM-set separators (such as ^)
    case DATA_TYPE.BOOLEAN:
      const checkboxValue = typeof value === 'string' ? value === 'true' : value
      return (
        <Checkbox
          label={fieldDefinition.displayName}
          name={fieldDefinition.fieldName}
          register={register(fieldDefinition.fieldName)}
          checked={checkboxValue}
          onChange={(checked) => handleCheckChange(checked)}
          dataTest={props.dataTest}
        />
      )
    case DATA_TYPE.CURRENCY:
    case DATA_TYPE.INTEGER:
    case DATA_TYPE.STRING:
      return (
        <Input
          label={fieldDefinition.displayName}
          name={fieldDefinition.fieldName}
          register={
            (register(fieldDefinition.fieldName),
            {
              onChange: (event: ChangeEvent<HTMLSelectElement>) => handleChange(event),
            })
          }
          defaultValue={value}
          dataTest={props.dataTest}
        />
      )
    // Creates Task Due Dates in a specified timeframe
    case DATA_TYPE.DATE:
    case DATA_TYPE.DATETIME:
      return (
        <Select
          label={fieldDefinition.displayName}
          name={fieldDefinition.fieldName}
          register={register(fieldDefinition.fieldName, { onChange: handleChange })}
          defaultValue={value}
          dataTest={props.dataTest}
        >
          {getDateOptions(t)}
        </Select>
      )
    case DATA_TYPE.PICKLIST:
      return (
        <Select
          label={fieldDefinition.displayName}
          name={fieldDefinition.fieldName}
          register={register(fieldDefinition.fieldName, { onChange: handleChange })}
          defaultValue={value}
          dataTest={props.dataTest}
        >
          {fieldDefinition.pickListValues && getPickListOptions(fieldDefinition.pickListValues, t)}
        </Select>
      )
    case DATA_TYPE.REFERENCE:
      return (
        <ReferenceSelect
          fieldDefinition={fieldDefinition}
          register={register}
          defaultValue={value}
          handleUpdate={handleChange}
          dataTest={props.dataTest}
        />
      )
    default:
      return (
        <Input
          label={fieldDefinition.displayName}
          name={fieldDefinition.fieldName}
          register={
            (register(fieldDefinition.fieldName),
            {
              onChange: (event: ChangeEvent<HTMLSelectElement>) => handleChange(event),
            })
          }
          defaultValue={value}
          dataTest={props.dataTest}
        />
      )
  }
}

export default CRMStepComponent
