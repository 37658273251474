import React, { FC, useContext, useState } from 'react'

import classNames from 'classnames'

import DividedContentDataCard from '@components/DividedContentDataCard/DividedContentDataCard'
import DropDown from '@components/DropDown'
import { DropDownType } from '@components/DropDown/DropDown'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { DropdownMenuItem, DropdownMenuLabel } from '@radix-ui/react-dropdown-menu'
import { ProgramStepsAndDetailsTabs } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/utils/ProgramStepsAndDetails.constants'
import { ListMaintenanceProgramsContext } from '@src/pages/listingPages/ListMaintenancePrograms/context/ListMaintenancePrograms.context'
import { getNextRunDate } from '@src/pages/listingPages/ListMaintenancePrograms/utils/ListMaintenancePrograms.tables'
import { useAccountSettings } from '@utils/account/account.utils'

import './NextRunDataCard.css'

interface NextRunDataCardProps {
  className?: string
  dataTest?: string
  loading?: boolean
  switchTab: (newTab: ProgramStepsAndDetailsTabs) => void
}

const rootClass = 'next-run-data-card'

const NextRunDataCard: FC<NextRunDataCardProps> = (props: NextRunDataCardProps) => {
  const { dataTest = rootClass, className = '', loading, switchTab } = props

  const {
    runProgram,
    values: { program, programDetails },
  } = useContext(ListMaintenanceProgramsContext)

  const [openDropdown, setOpenDropdown] = useState(false)

  const { userAllowedToCreatePrograms } = useAccountSettings()

  const { t } = useTranslation()

  const { nextTS, isScheduled } = { ...programDetails?.schedule }

  const renderNextRun = () => {
    if ((nextTS || !isScheduled) && !loading) {
      return (
        <div className={`${rootClass}__next-run`}>
          <Svg name={SvgNames.clock} type={SvgType.MEDIUM_LARGE_ICON} />
          <Typography
            text={nextTS ? getNextRunDate(nextTS) : t('Manual')}
            type={TextType.BODY_TEXT_LARGE}
            weight={TextWeight.BOLD}
            lineHeight={LineHeight.EXTRA_LARGE}
          />
        </div>
      )
    }
  }

  const renderMenuOption = ({
    text,
    icon,
    onSelect,
    disabled,
    disabledTooltip,
  }: {
    text: string
    icon: SvgNames
    onSelect: () => void
    disabled: boolean
    disabledTooltip: string
  }) => (
    <DropdownMenuItem onSelect={onSelect} disabled={disabled}>
      <Tooltip
        hide={!disabled}
        trigger={
          <DropdownMenuLabel className={`${rootClass}__dropdown-row`}>
            <Svg name={icon} type={SvgType.LARGER_ICON} />
            <Typography text={t(text)} />
          </DropdownMenuLabel>
        }
      >
        {t(disabledTooltip)}
      </Tooltip>
    </DropdownMenuItem>
  )

  const renderMenu = () => (
    <DropDown className={`${rootClass}__dropdown`} type={DropDownType.STYLED} hasOverflowIcon isOpen={openDropdown} toggleOpen={setOpenDropdown}>
      {renderMenuOption({
        text: 'Run program now',
        icon: SvgNames.playNoFill,
        disabled: !program?.valid,
        disabledTooltip: "You can't run a program that contains errors",
        onSelect: () => program && runProgram(program),
      })}
      {renderMenuOption({
        text: 'Edit schedule',
        icon: SvgNames.pencil,
        disabled: !userAllowedToCreatePrograms,
        disabledTooltip: 'Ask your administrator for permission to do this',
        onSelect: () => switchTab(ProgramStepsAndDetailsTabs.PROGRAM_DETAILS),
      })}
    </DropDown>
  )

  return (
    <DividedContentDataCard
      className={classNames(rootClass, className)}
      dataTest={dataTest}
      headerText={t('Next run')}
      leftContent={renderNextRun()}
      rightContent={renderMenu()}
    />
  )
}

export default NextRunDataCard
