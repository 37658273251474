import React, { FC, useCallback, useContext, useEffect, useState } from 'react'

import equal from 'fast-deep-equal/es6/react'

import Loader from '@components/Loader'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import NonCrmContacts from '@src/pages/datamanagement/components/NonCrmContacts/NonCrmContacts'
import {
  getScoreSheets,
  getStandardFields,
  getUnifiedListFieldMappings,
} from '@src/pages/datamanagement/components/NonCrmContacts/NonCrmContacts.utils'
import { DataManagementContext } from '@src/pages/datamanagement/context/DataManagementContext'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'

interface Props {
  dataTest?: string
  setShowSaveBanner: React.Dispatch<boolean>
}

interface State {
  defaultMappings: UnifiedListFieldMapping[]
  showFirstTime: boolean
  loading: boolean
}

const rootClass = 'non-crm-contacts'

const NonCrmContactsContainer: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, setShowSaveBanner } = props
  const {
    update,
    values: { fieldMappings, mutationResultState, fetchNonCRMData },
  } = useContext(DataManagementContext)

  const [state, setState] = useState<State>({
    defaultMappings: [],
    showFirstTime: false,
    loading: true,
  })
  const { defaultMappings, showFirstTime, loading } = state

  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.LIST })

  const getNonCRMData = useCallback(() => {
    if (loading || fetchNonCRMData) {
      getScoreSheets(client).then((scoreSheets) => update('scoreSheetsList', scoreSheets))
      getStandardFields(client).then((standardFields) => {
        update('standardFieldsMap', standardFields)
        getUnifiedListFieldMappings(standardFields, client)
          .then((data) => {
            if (data) {
              const { mappings, nonCrmContactsData } = data
              update('nonCrmContactsData', nonCrmContactsData)
              update('fieldMappings', mappings)
              update('unifiedListFieldMappings', mappings)
              setState((state: any) => ({ ...state, defaultMappings: mappings, loading: false, showFirstTime: mappings.length === 0 }))
            }
          })
          .finally(() => {
            update('fetchNonCRMData', false)
          })
      })
    }
  }, [fetchNonCRMData, loading])

  useEffect(() => {
    getNonCRMData()
  }, [fetchNonCRMData, getNonCRMData])

  // Each time fieldMappings changes, this method compares the mappings with the list-service mappings to show/hide the DirtyBanner if there is any difference to save
  useEffect(() => {
    if (!loading && !fetchNonCRMData && Array.isArray(fieldMappings) && !mutationResultState.areFieldMappingsSaved) {
      const hasUpdates = fieldMappings.length !== defaultMappings.length || !equal(fieldMappings, defaultMappings)
      update('hasUpdates', hasUpdates)
      setShowSaveBanner(hasUpdates)
    }
  }, [fieldMappings, defaultMappings])

  if (loading) {
    return <Loader center />
  }

  return (
    <NonCrmContacts
      setShowFirstTime={(showFirstTime) => setState((state) => ({ ...state, showFirstTime }))}
      showFirstTime={showFirstTime}
      loading={loading || fetchNonCRMData}
      dataTest={dataTest}
    />
  )
}

export default NonCrmContactsContainer
