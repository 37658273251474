import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

import PaneDrawer, { PaneDrawerItem } from '@components/PaneDrawer/PaneDrawer'
import { SvgNames } from '@components/Svg'
import { getMessageConfigurationType } from '@src/pages/EmailComposer/utils/EmailComposer.utils'
import { detectEmailType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import { ComposerHistoryState, EmailComposerContext, ValidationSectionType } from '@utils/composer/context/EmailComposer.context'
import { MessageConfigurationType } from '@utils/composer/emailComposer/types'
import { useTranslation } from '@utils/const/globals'

import AnalyticsTrackingPane from './components/AnalyticsTrackingPane/AnalyticsTrackingPane'
import EventTriggeredEmailPane from './components/EventTriggeredEmailPane/EventTriggeredEmailPane'
import InternalTrackingPane from './components/InternalTrackingPane/InternalTrackingPane'
import TransactionalEmailPane from './components/TransactionalEmailPane/TransactionalEmailPane'
import { getTransactionEmailDisabledTooltip } from './components/TransactionalEmailPane/TransactionalEmailPane.utils'
import { CampaignPanelFields, getComputedPanel, getSidebarStatusIcon } from './EmailComposerSettingsSidebar.utils'
import { SettingsFormChangeHandler, isAnalyticsFieldsIncomplete } from '../../EmailComposerSettings.utils'

interface EmailComposerSettingsSidebarProps {
  className?: string
  dataTest?: string
  onFieldChange: SettingsFormChangeHandler
  onConvertToTemplate: (discardDraft: boolean) => void
  onSendAPIDetails: (email: string, details: string, note: string) => Promise<void>
}

const EmailComposerSettingsSidebar: FC<EmailComposerSettingsSidebarProps> = (props: EmailComposerSettingsSidebarProps) => {
  const { className = '', dataTest = className, onFieldChange, onConvertToTemplate, onSendAPIDetails } = props
  const {
    values: {
      message: { analyticsFields, isTriggered, campaignId, subscriptionCategoryId, isTransactional, messageType },
      messageConfiguration: {
        settings: { hideInternalTracking, hideEte, hideTransactional, hideSubscriptionCategory },
      },
    },
  } = useContext(EmailComposerContext)

  const { t } = useTranslation()
  const { isEmailTemplate, isEmailBlankMessage, isEmailWebinar } = detectEmailType(messageType)
  const { subscriptionManagementEnabled, subscriptionManagementIsLive, eventBasedEmail } = useAccountSettings()
  const messageConfigurationType = getMessageConfigurationType().toLocaleUpperCase()
  const isOriginatedFromDraft = isEmailWebinar && messageConfigurationType === MessageConfigurationType.DRAFT
  const getAnalyticsIcon = () => getSidebarStatusIcon([!!analyticsFields.length], isAnalyticsFieldsIncomplete(analyticsFields))
  const [analyticsIcon, setAnalyticsIcon] = useState(getAnalyticsIcon())
  const [isAnalyticsDirty, setAnalyticsDirty] = useState(false)
  const { hasTransactionalEmailControl, isAccountAdmin } = useAccountSettings()
  const hideInternalTrackingForBlankMessage = isEmailBlankMessage && !(subscriptionManagementEnabled && subscriptionManagementIsLive)

  const history = useHistory<ComposerHistoryState>()

  useEffect(() => {
    const icon = getAnalyticsIcon()
    if (isAnalyticsDirty || icon === SvgNames.checkboxTeal) {
      setAnalyticsIcon(icon)
    }
  }, [isAnalyticsDirty, analyticsFields])

  const eventTriggeredPane: PaneDrawerItem = {
    title: t('EmailComposer.Settings.EventTriggered.Title'),
    content: <EventTriggeredEmailPane onFieldChange={onFieldChange} onConvertToTemplate={onConvertToTemplate} onSendAPIDetails={onSendAPIDetails} />,
    icon: getSidebarStatusIcon([isTriggered]),
  }

  const transactionalPane: PaneDrawerItem = {
    title: t('EmailComposer.Settings.TransactionalEmail.Title'),
    content: <TransactionalEmailPane onFieldChange={onFieldChange} isDisabled={!isAccountAdmin} />,
    icon: getSidebarStatusIcon([isTransactional]),
    disabled: !hasTransactionalEmailControl,
    disabledTooltip: getTransactionEmailDisabledTooltip(t),
  }

  const openPane = useMemo(() => {
    let result: string
    switch (true) {
      case history.location.state?.fromValidationSection === ValidationSectionType.TRACKING && isAnalyticsFieldsIncomplete(analyticsFields):
        result = t('EmailComposer.Settings.AnalyticsTracking.Title')
        break
      case hideInternalTrackingForBlankMessage:
        result = t('EmailComposer.Settings.AnalyticsTracking.Title')
        break
      default:
        result = t('EmailComposer.Settings.InternalTracking.Title')
        break
    }
    return [result]
  }, [analyticsFields, hideInternalTrackingForBlankMessage, history.location.state?.fromValidationSection, t])

  const campaignPanel: CampaignPanelFields = {
    title: t('EmailComposer.Settings.InternalTracking.Title'),
    content: <InternalTrackingPane onFieldChange={onFieldChange} />,
    icon: getSidebarStatusIcon(
      isEmailTemplate
        ? [!!subscriptionCategoryId]
        : !hideSubscriptionCategory && subscriptionManagementEnabled && subscriptionManagementIsLive
        ? [!!campaignId, !!subscriptionCategoryId]
        : [!!campaignId]
    ),
  }

  return (
    <PaneDrawer
      dataTest={dataTest}
      defaultOpenPanes={openPane}
      panes={[
        ...getComputedPanel({
          campaignPanel,
          hideInternalTracking: hideInternalTrackingForBlankMessage || hideInternalTracking,
          isEmailTemplate,
          subscriptionManagementEnabled,
          subscriptionManagementIsLive,
        }),
        {
          title: t('EmailComposer.Settings.AnalyticsTracking.Title'),
          content: (
            <AnalyticsTrackingPane
              onFieldChange={onFieldChange}
              onActive={() => setAnalyticsDirty(false)}
              onInactive={() => setAnalyticsDirty(true)}
            />
          ),
          icon: analyticsIcon,
        },
        ...(!hideEte && eventBasedEmail && !isOriginatedFromDraft ? [eventTriggeredPane] : []),
        ...(!hideTransactional ? [transactionalPane] : []),
      ]}
    />
  )
}

export default EmailComposerSettingsSidebar
