import React, { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { MediaItem, MediaPickerModalContext } from '@components/AssetPickers/MediaPickerModal/MediaPickerModal.context'
import { renderMediaTableColumns } from '@components/AssetPickers/MediaPickerModal/MediaPickerModal.table'
import { TableV2 } from '@components/TableV2/TableV2'
import { TableV2Props } from '@components/TableV2/tableV2TS/interfaces'
import { getRowSelectionState } from '@components/TableV2/utils/commonUtils'
import { Row, RowSelectionState } from '@tanstack/react-table'

interface ViewSelectedMediaProps {
  tableV2Props: TableV2Props<MediaItem>
}

interface State {
  allItems: MediaItem[]
  init: boolean
  updatedSelectedItems: MediaItem[]
  visibleItems: MediaItem[]
}

export const ViewSelectedMedia: FC<ViewSelectedMediaProps> = (props: ViewSelectedMediaProps) => {
  const { tableV2Props } = props
  const { selectedItems, update } = useContext(MediaPickerModalContext)
  const [selectionState, setSelectionState] = useState<RowSelectionState>({})
  const [state, setState] = useState<State>({ allItems: selectedItems ?? [], init: true, updatedSelectedItems: [], visibleItems: [] })
  const { allItems, init, updatedSelectedItems } = state

  const { t } = useTranslation()

  const rowSelectionChanged = (selectedRowIds: string[], rows: Row<MediaItem>[]) => {
    const updatedSelections = rows.filter((row) => selectedRowIds.some((id) => id === row.id)).map((row) => row.original)
    setState((state: State) => ({
      ...state,
      init: false,
      updatedSelectedItems: updatedSelections,
    }))
    update({ selectedItems: updatedSelections })
  }

  useEffect(() => {
    const selection = getRowSelectionState(init ? (selectedItems?.length ? selectedItems : []) : updatedSelectedItems)
    setSelectionState(selection)
  }, [allItems, selectedItems])

  return (
    <TableV2
      {...tableV2Props}
      onRowSelectionChanged={rowSelectionChanged}
      data={allItems}
      columns={renderMediaTableColumns(t)}
      withoutBorders
      enableCheckbox
      defaultSelectedRows={selectionState}
    />
  )
}
