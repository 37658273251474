export const wrapWithPointerEventsNone = (htmlString?: string) => {
  if (!htmlString) return ''
  return `<div style="pointer-events: none; user-select: none;">${htmlString}</div>`
}

export const removePointerEventsNone = (htmlString?: string) => {
  if (!htmlString) return ''
  return htmlString.replace(/pointer-events:\s*none;?/gi, '')
}

export const replaceUserSelectNone = (htmlString?: string) => {
  if (!htmlString) return ''
  return htmlString.replace(/user-select:\s*none;?/gi, 'pointer-events: none; user-select: none;')
}
