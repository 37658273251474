import { GetImportCountRequestType } from '@src/pages/ContactSegments/GraphQL/ContactSegments.list.graphQL'
import { showStatusToast } from '@src/pages/ContactSegments/GraphQL/utils/ContactSegmentsRequests.helper'
import { Update } from '@utils/contactSegments/context/ContactSegmentsContext'
import { logNewRelicError } from '@utils/new-relic.utils'

const rootText = 'ListPage.ContactSegments.CopySegments.Requests.List'

interface GetImportCountUtilsParams {
  getImportCountRequest: GetImportCountRequestType
  update: Update
}

export const getImportCountUtils = async ({ getImportCountRequest, update }: GetImportCountUtilsParams) => {
  try {
    const { data, errors } = await getImportCountRequest()
    if (!!data?.importDetailsStatus) {
      update({ importContactsCount: data?.importDetailsStatus.count })
    } else if (errors) {
      showStatusToast(update, `${rootText}.ImportContactsCount`)
      logNewRelicError(errors)
    }
  } catch (e) {
    showStatusToast(update, `${rootText}.ImportContactsCount`)
    logNewRelicError(e)
  }
}
