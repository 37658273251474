import React, { FC } from 'react'

import classNames from 'classnames'

import Svg from '@components/Svg'
import { getIconAndColor } from '@src/pages/programs/edit/components/ProgramFlow/components/ProgramStepIcon/ProgramStepIcon.helper'
import { ProgramStepType, Step } from '@utils/program/program.constants'

import './programStepIcon.css'

interface ProgramStepIconProps {
  step?: Step
  stepType?: ProgramStepType
  aOContactsSource?: boolean
  iconOnly?: boolean
  overrideColorAndHeight?: boolean
  dataTest?: string
  className?: string
}

const rootClass = 'program-step-icon'

const ProgramStepIcon: FC<ProgramStepIconProps> = (props: ProgramStepIconProps) => {
  const { step, stepType, overrideColorAndHeight, iconOnly, dataTest = rootClass, className } = props
  const switchType: string | undefined = step?.stepType ?? stepType

  if (!switchType) return null

  const { color, iconName } = getIconAndColor(switchType, overrideColorAndHeight ?? false)

  if (iconOnly) {
    return (
      <Svg name={iconName} dataTest={dataTest} className={classNames(`${rootClass}__icon-only`, `${rootClass}__icon-only--${color}`, className)} />
    )
  }

  return (
    <span className={classNames(`${rootClass}`, `${rootClass}--${color}`, className)}>
      <Svg name={iconName} dataTest={dataTest} />
    </span>
  )
}

export default ProgramStepIcon
