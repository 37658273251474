import React, { FC } from 'react'
import { FieldError, useFormContext, UseFormRegister } from 'react-hook-form'

import Container from '@components/Container'
import TextArea from '@components/TextArea/TextArea'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ContactSettingsFields, getInvalidDomains } from '@src/pages/Settings/OtherSettings/ContactSettings/ContactSettings.utils'

import { ContactSettings } from '../../ContactSettings'

import './EmailSuppressionDomains.css'

interface Props {
  className?: string
  dataTest?: string
  disabled: boolean
  register: UseFormRegister<ContactSettings>
  error?: FieldError
}

const rootClass = 'email-suppression-domains'

const EmailSuppressionDomains: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '', disabled, register, error } = props
  const { watch } = useFormContext()
  const { t } = useTranslation()
  const { emailSuppressionDomain } = watch()
  const invalidDomains = getInvalidDomains(emailSuppressionDomain).join(', ')

  return (
    <Container className={`${className}__container`} dataTest={dataTest}>
      <Typography
        dataTest={`${dataTest}__header`}
        className={`${className}__header`}
        text={t('Email Suppression Domains')}
        type={TextType.SECTION_HEADER}
        weight={TextWeight.MEDIUM}
      />
      <Typography text={t('Email will not be sent to any of these domains. Enter ')} type={TextType.BODY_TEXT_LIGHT} inline />
      <Typography text={t('example.com')} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.BOLD} inline />
      <Typography
        text={t(' rather than http://www.example.com. You can add them one per line or separate each domain by commas, semicolons, or spaces.')}
        type={TextType.BODY_TEXT_LIGHT}
        inline
      />
      <div className={`${rootClass}__textarea-section`}>
        <TextArea
          disabled={disabled}
          rows={5}
          className={`${rootClass}__textarea`}
          dataTest={`${dataTest}__textarea`}
          placeholder={t('Enter domains (e.g. example.com)')}
          name={ContactSettingsFields.EMAIL_SUPPRESSION_DOMAIN}
          register={register(ContactSettingsFields.EMAIL_SUPPRESSION_DOMAIN)}
        />
        {error && (
          <div data-test={`${dataTest}__error`}>
            <Typography text={t(error.message)} type={TextType.ERROR} inline />
            <Typography text={t('example.com')} type={TextType.ERROR} inline weight={TextWeight.MEDIUM} />
            {invalidDomains && (
              <>
                <Typography text={t('Invalid domains:')} type={TextType.ERROR} />
                <Typography text={` ${invalidDomains}`} inline type={TextType.ERROR} />
              </>
            )}
          </div>
        )}
      </div>
    </Container>
  )
}

export default EmailSuppressionDomains
