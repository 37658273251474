import React, { FC, useContext, useMemo } from 'react'

import { CheckCardStatus, CheckCardType } from '@src/pages/EmailComposer/components/common/CheckStatusCard/CheckStatusCard.utils'
import {
  CheckStatusCardContainer,
  CheckStatusCardContainerProps,
} from '@src/pages/EmailComposer/components/common/CheckStatusCard/CheckStatusCardContainer'
import { getPersonalizationErrorsCount } from '@src/pages/EmailComposer/components/common/PersonalizationCheckCard/utils/PersonalizationCheck.utils'
import useCheckPersonalizationFieldsRequest from '@src/pages/EmailComposer/hooks/useCheckPersonalizationFieldsRequest'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'

type PersonalizationCheckCardProps = Pick<CheckStatusCardContainerProps, 'className' | 'dataTest'>

const rootClass = 'personalization-check-card'

export const PersonalizationCheckCard: FC<PersonalizationCheckCardProps> = (props) => {
  const {
    values: {
      validations: {
        personalization: { personalizationErrors, lastChecked, noContent },
        isContentMissing,
      },
      messageConfiguration: {
        reviewAndSend: { hideCheckPersonalizations, disableEditings },
      },
      recipientsCount,
    },
  } = useContext(EmailComposerContext)

  const { status, issuesCount } = useMemo(() => {
    if (!recipientsCount || isContentMissing) {
      return { status: CheckCardStatus.DEFAULT }
    }
    if (noContent) {
      return { status: CheckCardStatus.NO_CONTENT }
    }
    if (personalizationErrors === null) {
      return { status: CheckCardStatus.ERROR }
    } else if (!personalizationErrors) {
      return { status: CheckCardStatus.DEFAULT }
    } else {
      const issuesCount = personalizationErrors.reduce((acc, error) => {
        return acc + getPersonalizationErrorsCount(error)
      }, 0)

      const hasOnlyFallbackTextErrors = personalizationErrors.every((err) => !err.listErrors?.length)

      return {
        status: issuesCount ? (hasOnlyFallbackTextErrors ? CheckCardStatus.WARNING : CheckCardStatus.FAILURE) : CheckCardStatus.SUCCESS,
        issuesCount,
      }
    }
  }, [personalizationErrors, noContent, isContentMissing, recipientsCount])

  const handleCheck = useCheckPersonalizationFieldsRequest()

  return (
    <>
      {!hideCheckPersonalizations && (
        <CheckStatusCardContainer
          dataTest={rootClass}
          {...props}
          checkApi={handleCheck}
          status={status}
          issuesCount={issuesCount}
          type={CheckCardType.PERSONALIZATION}
          personalizationErrors={personalizationErrors ?? undefined}
          lastChecked={lastChecked}
          disableCheck={!recipientsCount || isContentMissing || disableEditings}
          tooltipContentKey={
            disableEditings
              ? 'EmailComposer.Resend.Disabled'
              : !recipientsCount
              ? 'EmailComposer.Check.Status.Personalization.Tooltip.NoRecipients'
              : undefined
          }
        />
      )}
    </>
  )
}
