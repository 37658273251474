import React from 'react'
import { Row } from 'react-table'

import { ApolloClient } from '@apollo/client'
import { TableActions } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.constants'
import { getActionProps } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.utils'
import { RenderCustomFiltersParams, Update } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { CatalogLink } from '@complex/ListingPage/Utils/ListingPage.constants'
import { ListingPageSession } from '@complex/ListingPage/Utils/ListingPage.utils'
import { MIRRORED_LISTS_ID, UNIFIED_CONTACTS_ID } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import { CollapsibleMenuItemWithHeaderData } from '@components/CollapsibleMenu/CollapsibleMenu'
import { EmptyListingProps } from '@components/EmptyListing/EmptyListing'
import Radio from '@components/Radio'
import RadioGroup from '@components/RadioGroup'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { SvgNames } from '@components/Svg'
import { RowAction } from '@components/Table/Table'
import { FAILED_TO_START_ERROR } from '@const/globals'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { Program, TemplateIdDescription } from '@graphql/types/query-types'
import {
  AP_HELP_START_PAUSE_LINK,
  AutomatedProgramsCustomTableActions,
  rowActionCustomProps,
  StartProgramResult,
} from '@src/pages/listingPages/AutomatedPrograms/AutomatedProgramsListingPage.constants'
import { getAutomatedProgramsListingPageRequests } from '@src/pages/listingPages/AutomatedPrograms/GraphQL/AutomatedProgramsListingPageRequests.graphQL'
import { getProgramManagerQueries } from '@src/pages/programs/manager/graphQL/ProgramManager.graphQL'
import {
  allProgramsFilter,
  createdByMeFilter,
  draftProgramsFilter,
  favoriteFilter,
  FilterDefinition,
  FilterTypes,
  pausedProgramsFilter,
  recentFilter,
  runningProgramsFilter,
} from '@utils/filter'
import { logNewRelicError } from '@utils/new-relic.utils'
import { ProgramState } from '@utils/program/program.constants'
import { getItem } from '@utils/sessionStorage'

export const getCustomEmptyListingProps = (
  setFilter: (filterDefinition: FilterDefinition, customFilterSelected: boolean) => void,
  _update: Update,
  filter?: FilterDefinition
): EmptyListingProps => {
  if (filter === favoriteFilter) {
    return {
      imgSrc: StaticImageNames.emptyFavorites,
      headline: "You don't have any favorites yet",
      text: 'Add hearts to programs that you want to easily find later.',
      buttonText: 'Create Program',
      buttonOnClick: () => setFilter(allProgramsFilter, false),
    }
  } else if (filter === recentFilter) {
    return {
      imgSrc: StaticImageNames.emptyRecent,
      headline: 'You haven’t created any automated programs yet',
      text: 'You’ll find programs here once you’ve created them.',
      buttonText: 'Create Program',
      buttonOnClick: () => setFilter(recentFilter, false),
    }
  } else if (filter === createdByMeFilter) {
    return {
      imgSrc: StaticImageNames.emptyCreatedByMe,
      headline: 'You haven’t created any automated programs yet',
      text: 'You’ll find programs here once you’ve created them',
      buttonText: 'Create Program',
      buttonOnClick: () => setFilter(createdByMeFilter, false),
    }
  } else if (filter === runningProgramsFilter) {
    return {
      imgSrc: StaticImageNames.clock,
      headline: 'No programs currently running',
      text: 'Once you start a program it will show up in this list',
      buttonText: 'View all programs',
      buttonOnClick: () => setFilter(allProgramsFilter, false),
      linkText: 'Need help starting a program?',
      link: AP_HELP_START_PAUSE_LINK,
    }
  } else if (filter === pausedProgramsFilter) {
    return {
      imgSrc: StaticImageNames.pausedProgram,
      headline: 'No programs currently paused',
      text: 'When you pause a program it will show up in this list',
      buttonText: 'View all programs',
      buttonOnClick: () => setFilter(allProgramsFilter, false),
      linkText: 'Need help pausing a program?',
      link: AP_HELP_START_PAUSE_LINK,
    }
  } else if (filter === draftProgramsFilter) {
    return {
      imgSrc: StaticImageNames.checkmark,
      headline: 'There are no programs with errors',
      text: "There's nothing to see here!",
      buttonText: 'View all programs',
      buttonOnClick: () => setFilter(allProgramsFilter, false),
    }
  } else {
    return {
      imgSrc: StaticImageNames.emptyChart,
      headline: "Let's start building",
      text: 'ListPage.AutomatedPrograms.EmptyState.AllFilter.Text',
      buttonText: 'Check out program templates',
      buttonOnClick: () => window.open(CatalogLink.PROGRAM, '_blank'),
      link: 'https://success.act-on.com/acton/attachment/9883/u-c560c221-a9b0-4c0a-a04e-cca4a6691df8/0/-/-/-/-/In%20app%3A%20New%20AP%20listing%20page%20empty%20state_%20no%20programs',
      linkText: 'Learn more about building automated programs',
    }
  }
}

export const onClearProgramHistory = async (programUrlId: string, client: ApolloClient<any>, allowReentrantAddresses = false) => {
  const { clearProgramHistoryRequest } = getProgramManagerQueries(client)
  const { data, errors } = await clearProgramHistoryRequest(programUrlId, allowReentrantAddresses)
  if (errors) {
    logNewRelicError(errors)
  }

  return { data, errors }
}

export const startProgram = async (
  programUrlId: string,
  skipPending: boolean,
  hasPending: boolean,
  client: ApolloClient<any>,
  lastRowId = 0,
  update?: Update
) => {
  const { startProgramRequest } = getProgramManagerQueries(client)
  const { data, errors } = await startProgramRequest(skipPending, lastRowId, programUrlId)

  if (errors) {
    logNewRelicError(errors)
    return StartProgramResult.FAILED
  }

  if (data?.startProgram) {
    if (data.startProgram.errorCode === FAILED_TO_START_ERROR) {
      update && update({ errorMessage: data.startProgram.message })
      return StartProgramResult.FAILED
    } else {
      if (!skipPending && hasPending) {
        return StartProgramResult.ADDED
      } else if (skipPending) {
        return StartProgramResult.EXITED
      }
    }
  }
}

export const pauseProgram = async (programUrlId: string, client: ApolloClient<any>) => {
  const { pauseProgramRequest } = getProgramManagerQueries(client)
  const { data, errors } = await pauseProgramRequest(programUrlId)

  if (errors) {
    logNewRelicError(errors)
  }

  return { data, errors }
}

export const onStartProgram = async (programId: string, apolloClient: ApolloClient<any>) => {
  const { startProgramRequest } = getAutomatedProgramsListingPageRequests(apolloClient)
  const { data, errors } = await startProgramRequest(programId)

  if (errors) {
    logNewRelicError(errors)
    return false
  } else {
    return data
  }
}

export const onCreateTemplate = async (
  programId: string,
  title: string,
  descriptionMapping: TemplateIdDescription[],
  apolloClient: ApolloClient<any>
) => {
  const { createProgramTemplateRequest } = getAutomatedProgramsListingPageRequests(apolloClient)
  const { data, errors } = await createProgramTemplateRequest(programId, title, descriptionMapping)

  if (errors) {
    logNewRelicError(errors)
  }

  return { data, errors }
}

export const renderCustomFilters = ({
  activeFilter,
  menuActions,
  filterCounts,
  renderCustomFilterWithCount,
  t,
}: RenderCustomFiltersParams): CollapsibleMenuItemWithHeaderData[] => [
  {
    header: t('Program Status'),
    isOpen: getItem(ListingPageSession.CUSTOM_MENU_SECTION_CLOSED) !== 'true',
    icon: SvgNames.toggles,
    dataTest: 'menu-items',
    content: (
      <RadioGroup className={`listing-page__custom-filters`} verticalLayout>
        {renderCustomFilterWithCount(
          <Radio
            label={t(FilterTypes.RUNNING)}
            checked={activeFilter?.name === runningProgramsFilter.name}
            onChange={() => menuActions.clickCustomFilter(runningProgramsFilter)}
            onClick={() => menuActions.clickCustomFilter(runningProgramsFilter)}
            dataTest={`list-page-container-section-filter`}
            boldOnChecked
            className={'ellip'}
          />,
          filterCounts[FilterTypes.RUNNING]
        )}
        {renderCustomFilterWithCount(
          <Radio
            label={t(FilterTypes.PAUSED)}
            checked={activeFilter?.name === pausedProgramsFilter.name}
            onChange={() => menuActions.clickCustomFilter(pausedProgramsFilter)}
            onClick={() => menuActions.clickCustomFilter(pausedProgramsFilter)}
            dataTest={`list-page-container-section-filter`}
            boldOnChecked
            className={'ellip'}
          />,
          filterCounts[FilterTypes.PAUSED]
        )}
        {renderCustomFilterWithCount(
          <Radio
            label={t(FilterTypes.DRAFT)}
            checked={activeFilter?.name === draftProgramsFilter.name}
            onChange={() => menuActions.clickCustomFilter(draftProgramsFilter)}
            onClick={() => menuActions.clickCustomFilter(draftProgramsFilter)}
            dataTest={`list-page-container-section-filter`}
            boldOnChecked
            className={'ellip'}
          />,
          filterCounts[FilterTypes.DRAFT]
        )}
      </RadioGroup>
    ),
  },
]

export const getCustomRowActionsUtils = (
  tableActions: TableActions,
  t: Function,
  exclusivelyActOnContacts: boolean,
  hasShowActOnContactsTab: boolean,
  canEdit: boolean
): RowAction[] => {
  return [
    {
      label: t('Show dashboard'),
      icon: SvgNames.graphBar,
      onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(AutomatedProgramsCustomTableActions.SHOW_DASHBOARD, row),
      ...getActionProps(AutomatedProgramsCustomTableActions.SHOW_DASHBOARD, rowActionCustomProps),
    },
    {
      label: t('Edit'),
      icon: SvgNames.pencil,
      hidden: () => !canEdit,
      onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(AutomatedProgramsCustomTableActions.EDIT, row),
      ...getActionProps(AutomatedProgramsCustomTableActions.EDIT, rowActionCustomProps),
    },
    {
      label: t('Start program'),
      icon: SvgNames.play,
      hidden: (row: Row<ItemDto>) => {
        const item = row.original as Program
        return [ProgramState.PAUSED].indexOf(item.status as ProgramState) === -1
      },
      onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(AutomatedProgramsCustomTableActions.START, row),
      ...getActionProps(AutomatedProgramsCustomTableActions.START, rowActionCustomProps),
    },
    {
      label: t('Pause program'),
      icon: SvgNames.pause,
      hidden: (row: Row<ItemDto>) => {
        const item = row.original as Program
        return item.status !== ProgramState.RUNNING
      },
      onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(AutomatedProgramsCustomTableActions.PAUSE, row),
      ...getActionProps(AutomatedProgramsCustomTableActions.PAUSE, rowActionCustomProps),
    },
    {
      label: t('Classic editor'),
      icon: SvgNames.editClassic,
      disabled: !canEdit,
      hasTooltip: !canEdit,
      tooltipMessage: 'Ask your administrator for permission to do this',
      hidden: (row: Row<ItemDto>) => {
        const item = row.original as Program
        const hasAOContactsSources =
          hasShowActOnContactsTab &&
          (item.sourceList.length === 0 ||
            item.sourceList.some(
              (sourceList) =>
                sourceList.baseId === UNIFIED_CONTACTS_ID ||
                sourceList.baseId.startsWith(MIRRORED_LISTS_ID) ||
                sourceList.id.startsWith(MIRRORED_LISTS_ID)
            ))

        return exclusivelyActOnContacts || hasAOContactsSources || item.status === ProgramState.RUNNING
      },
      onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(AutomatedProgramsCustomTableActions.CLASSIC_EDITOR, row),
      ...getActionProps(AutomatedProgramsCustomTableActions.CLASSIC_EDITOR, rowActionCustomProps),
    },
    {
      label: t('Create template'),
      icon: SvgNames.appendedList,
      disabled: !canEdit,
      hasTooltip: !canEdit,
      tooltipMessage: 'Ask your administrator for permission to do this',
      hidden: (row: Row<ItemDto>) => {
        const item = row.original as Program
        return item.status === ProgramState.DRAFT || item.status === ProgramState.ERROR
      },
      onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(AutomatedProgramsCustomTableActions.CREATE_TEMPLATE, row),
      ...getActionProps(AutomatedProgramsCustomTableActions.CREATE_TEMPLATE, rowActionCustomProps),
    },
  ]
}

export const getCustomFilterParams = (activeFilter?: FilterDefinition) => {
  const query =
    activeFilter?.name === runningProgramsFilter.name
      ? ProgramState.RUNNING
      : activeFilter?.name === pausedProgramsFilter.name
      ? ProgramState.PAUSED
      : ProgramState.DRAFT
  return { field: 'status', query }
}

export const getProgramId = (fullId: string) => {
  const idParts = fullId.split('-')
  const len = idParts.length
  return `${idParts[len - 2]}-${idParts[len - 1]}`
}
