import React, { FC } from 'react'

import classNames from 'classnames'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { LineHeight, TextAlign, TextType, TextWeight } from '@components/Typography/Typography'

import './StatusMessage.css'

interface StatusMessageProps {
  className?: string
  dataTest?: string
  header: string
  subheader: string
  success?: boolean
  action?: {
    text: string
    onClick: VoidFunction
  }
  hasSmallHeader?: boolean
}

const rootClass = 'status-message'

const StatusMessage: FC<StatusMessageProps> = (props: StatusMessageProps) => {
  const { dataTest = rootClass, className = '', header, subheader, success = true, action, hasSmallHeader } = props

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Svg className={`${rootClass}__icon`} name={success ? SvgNames.success : SvgNames.failure} type={SvgType.HUGE_ICON} />
      <Typography
        className={`${rootClass}__title`}
        text={header}
        type={hasSmallHeader ? TextType.DATA_CARD_MODAL_HEADER : TextType.MODAL_HEADLINE_LARGE}
        lineHeight={LineHeight.LARGER}
        weight={TextWeight.MEDIUM}
        textAlign={TextAlign.CENTER}
      />
      <Typography
        className={`${rootClass}__subtitle`}
        text={subheader}
        type={TextType.BODY_TEXT}
        weight={TextWeight.REGULAR}
        textAlign={TextAlign.CENTER}
      />
      {action && (
        <TextLink
          className={`${rootClass}__link`}
          dataTest={`${dataTest}-button`}
          text={action.text}
          size={TextLinkSize.LARGE}
          onClick={action.onClick}
        />
      )}
    </div>
  )
}

export default StatusMessage
