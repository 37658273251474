import React, { useEffect, useMemo, useRef } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import classNames from 'classnames'
import equal from 'fast-deep-equal/es6/react'
import { bindActionCreators } from 'redux'

import { useApolloClient } from '@apollo/client'
import { EXTERNAL_WINDOW_PATH, useTranslation } from '@const/globals'
import { AccountSettings } from '@graphql/types/query-types'
import ClassicModalHandler, { ClassicModalType } from '@src/components/ClassicModalHandler/ClassicModalHandler'
import accountActions, { AccountActions } from '@utils/account/actions'
import documentUtils from '@utils/document'
import useDeepEqualState from '@utils/hooks/useDeepEqualsState'
import eventHook from '@utils/hooks/useEvent'
import useWindowSize from '@utils/hooks/useWindowSize'
import { CLASSIC_WINDOW_URLS } from '@utils/iframeWrapperUrls'
import { NavigationInterface } from '@utils/navigation/navigation.utils'
import navigationActions, { NavigationActions } from '@utils/navigation/state/actions'
import mapStateToProps, { NavigationStateProps } from '@utils/navigation/state/mapStateToProps'

import Logo from './components/Logo'
import NavigationCollapser from './components/NavigationCollapser'
import NavigationMenuItems from './components/NavigationMenuItems'
import { PoweredBy, renderPoweredBy } from './components/PoweredBy'
import navigationUtils, { getCurrentMenu } from './navigationUtils'

import './navigation.css'
import './navigationDark.css'

export const rootClass = 'navigation'

export enum AnimationType {
  menuOut,
  menuIn,
}

export interface AnimationState {
  currentlySelectedMenu?: string
  menuItemSelectedByUserClick?: boolean
  menuItemSelectedByUserHover?: boolean
  windowWidth?: number
  expanded?: boolean
  animation?: AnimationType
  userInitiatedNavigationCollapse?: boolean
  animationFinalState?: {}
  animationTime?: number
}

export interface NavigationState {
  currentlySelectedMenu: string
  clickedMenu?: string
  stopIFrameFromOverridingState: boolean
  currentURL: string
  menuItemSelectedByUserClick: boolean
  menuItemSelectedByUserHover: boolean
  navigation?: NavigationInterface[]
  windowWidth?: number
  animation?: AnimationType
  animating?: boolean
  userInitiatedNavigationCollapse?: boolean
  lastSelectedMenu?: string
  mouseMovedOutsideOfMobileMenu: boolean
  animationFinalState?: AnimationState
  animationTime?: number
  hideOverflow?: string
  openModal?: { showModal: boolean; messageFromClassic: any; type?: ClassicModalType }
}

export type NavigationAllProps = AccountActions & NavigationActions & NavigationStateProps

const propsAreEqual = (prevProps: NavigationAllProps, nextProps: NavigationAllProps) => equal(prevProps, nextProps)

export const Navigation: React.FC<NavigationAllProps> = (props: NavigationAllProps) => {
  const {
    loadAccount,
    showNavigation,
    hideMenu,
    toggleModalOpened,
    account,
    navigation: { visible, expanded, disabled, hiddenMenu },
    setPendingIFrameRequest,
  } = props
  const { accountSettings, dynamicNav, theme } = account
  const client = useApolloClient()
  const history = useHistory()

  const [state, setDeepEqualState] = useDeepEqualState<NavigationState>({
    currentlySelectedMenu: '',
    stopIFrameFromOverridingState: false,
    currentURL: '/',
    menuItemSelectedByUserClick: false,
    menuItemSelectedByUserHover: false,
    mouseMovedOutsideOfMobileMenu: false,
  })

  const { openModal: { showModal, messageFromClassic, type } = {} } = state

  const mouseMovedOutsideOfMobileMenu = React.useRef(state.mouseMovedOutsideOfMobileMenu)
  mouseMovedOutsideOfMobileMenu.current = state.mouseMovedOutsideOfMobileMenu

  const { t } = useTranslation()
  const navigationRef = useRef<HTMLDivElement>(null)
  const mobileBodyRef = useRef<HTMLDivElement>(null)
  const size = useWindowSize()
  const message = eventHook.useEvent('message') as any
  const blur = eventHook.useEvent('blur') as Event
  const focus = eventHook.useEvent('focus') as Event
  const click = eventHook.useEvent('click')

  useEffect(() => {
    if (focus) {
      navigationUtils.checkIfContextMenuShouldBeClosedByClick(focus, navigationRef, expanded, state, setDeepEqualState)
    }
  }, [focus])

  useEffect(() => {
    if (blur) {
      navigationUtils.checkIfContextMenuShouldBeClosedByClick(blur, navigationRef, expanded, state, setDeepEqualState)
    }
  }, [blur])

  useEffect(() => {
    if (click) {
      navigationUtils.checkIfContextMenuShouldBeClosedByIframe(expanded, state, setDeepEqualState)
    }
  }, [click])

  useEffect(() => {
    navigationUtils.toggleNavigationCollapseStateOnWindowResize(expanded, state, setDeepEqualState)
  }, [size])

  useEffect(() => {
    navigationUtils.handleMessagesReceivedFromIFrame(
      client,
      message,
      account,
      state,
      setDeepEqualState,
      loadAccount,
      showNavigation,
      toggleModalOpened,
      setPendingIFrameRequest,
      visible,
      t,
      history
    )
    account && message?.data?.modalData && navigationUtils.handleClassicModalCall(state, setDeepEqualState, message)
  }, [message])

  useEffect(() => {
    if (accountSettings) {
      showNavigation()
    }
  }, [accountSettings])

  useEffect(() => {
    if (!expanded) {
      navigationUtils.adjustContextMenuToFitScreenForMobile(expanded, state, mobileBodyRef.current)
      setTimeout(() => {
        navigationUtils.adjustContextMenuToFitScreenForMobile(expanded, state, mobileBodyRef.current)
      }, 140)
    } else {
      navigationUtils.clearContextMenuStyles()
    }
  }, [state.currentlySelectedMenu, expanded])

  useEffect(() => {
    const body = documentUtils.getDocumentBody()
    if (!body) return
    if (visible) {
      body.classList.add('root--navigation-visible')
      body.classList.remove('root--navigation-hidden')
    } else {
      body.classList.remove('root--navigation-visible')
      body.classList.add('root--navigation-hidden')
    }
  }, [visible])

  useEffect(() => {
    setDeepEqualState({
      ...state,
      navigation: navigationUtils.getNavigationConfiguration(accountSettings, dynamicNav),
    })
    if (location.pathname.includes(EXTERNAL_WINDOW_PATH) || Object.values(CLASSIC_WINDOW_URLS).some((url) => location.pathname.includes(url))) {
      hideMenu()
    }
  }, [accountSettings, state.currentURL])

  useEffect(() => {
    if (state.animation !== undefined && !state.animating) {
      setDeepEqualState({
        ...state,
        animating: true,
      })
      setTimeout(() => {
        if (state.animation === AnimationType.menuIn || state.animation === AnimationType.menuOut) {
          props.toggleExpanded(state.animation !== AnimationType.menuIn)
        }
        setDeepEqualState({
          ...state,
          ...state.animationFinalState,
          animating: false,
          animationFinalState: undefined,
        })
      }, state.animationTime)
    }
  }, [state.animation, state.windowWidth])

  useEffect(() => {
    if (state.hideOverflow) {
      setTimeout(() => {
        setDeepEqualState({
          ...state,
          hideOverflow: undefined,
        })
      }, 140)
    }
  }, [state.hideOverflow])

  useEffect(() => {
    if (props.navigation.navigationPath) {
      setDeepEqualState({
        ...state,
        currentURL: props.navigation.navigationPath,
        currentlySelectedMenu: getCurrentMenu(state, props.navigation.navigationPath, accountSettings as AccountSettings),
      })
      props.setNavigationPath(undefined)
    }
  }, [props.navigation.navigationPath])

  const closeModal = () => setDeepEqualState({ ...state, openModal: { showModal: false, messageFromClassic: null } })

  const classicModalHandler = useMemo(
    () => showModal && type && <ClassicModalHandler message={messageFromClassic} onClose={closeModal} type={type} t={t} showModal />,
    [messageFromClassic, showModal, type]
  )

  if (!accountSettings || !state.navigation) return null

  return (
    <div
      id="navigation"
      data-test="navigation"
      ref={navigationRef}
      className={classNames(rootClass, 'no-print', [
        {
          [`${rootClass}--hidden`]: !visible || hiddenMenu,
          [`${rootClass}--collapsing`]: state.animation === AnimationType.menuIn,
          [`${rootClass}--expanding`]: state.animation === AnimationType.menuOut,
          [`${rootClass}--expanded`]: expanded,
          [`${rootClass}--disabled`]: disabled,
          [`${rootClass}--powered-by`]: renderPoweredBy(
            accountSettings.newLogo,
            accountSettings.isCustomLogo,
            accountSettings.hidePoweredBy,
            expanded
          ),
        },
      ])}
      onMouseLeave={() => {
        setDeepEqualState({
          ...state,
          mouseMovedOutsideOfMobileMenu: true,
        })
        setTimeout(() => {
          if (mouseMovedOutsideOfMobileMenu.current) {
            navigationUtils.checkIfContextMenuShouldBeClosed(expanded, state, setDeepEqualState)
          }
        }, 500)
      }}
      onMouseEnter={() => {
        setDeepEqualState({
          ...state,
          mouseMovedOutsideOfMobileMenu: false,
        })
      }}
    >
      <Logo
        expanded={expanded && state.animation !== AnimationType.menuIn}
        theme={theme}
        isCustomLogo={accountSettings.isCustomLogo}
        appLogo={accountSettings.appLogo}
        isMicrosoftStartPage={accountSettings.isMicrosoftStartPage}
        userDesktop={accountSettings.userSettings.userDesktop}
        customLogos={accountSettings.customLogos}
      />
      {classicModalHandler}
      {!expanded && (
        <div className={`${rootClass}__body-mobile`} ref={mobileBodyRef} tabIndex={-1}>
          <ul className={`${rootClass}__main`}>
            <NavigationMenuItems
              expanded={expanded}
              disabled={disabled}
              navigation={state.navigation}
              state={state}
              setState={setDeepEqualState}
              t={t}
              accountSettings={accountSettings}
              setPendingIFrameRequest={setPendingIFrameRequest}
              iconsOnly={true}
            />
          </ul>
        </div>
      )}
      <nav className={`${rootClass}__body`}>
        <ul className={`${rootClass}__main`}>
          <NavigationMenuItems
            expanded={expanded}
            disabled={disabled}
            navigation={state.navigation}
            state={state}
            setState={setDeepEqualState}
            t={t}
            accountSettings={accountSettings}
            setPendingIFrameRequest={setPendingIFrameRequest}
          />
        </ul>
      </nav>
      <PoweredBy
        theme={theme}
        expanded={expanded}
        newLogo={accountSettings.newLogo}
        isCustomLogo={accountSettings.isCustomLogo}
        hidePoweredBy={accountSettings.hidePoweredBy}
        navFontColor={accountSettings.customSkinParams?.navFontColor ?? undefined}
      />
      <NavigationCollapser state={state} setState={setDeepEqualState} t={t} expanded={expanded} />
    </div>
  )
}

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: any) => bindActionCreators({ ...navigationActions, ...accountActions }, dispatch)

Navigation.displayName = 'Navigation'
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Navigation, propsAreEqual))
