import React, { FC, useCallback, useRef, useState } from 'react'

import Button, { ButtonType } from '@components/Button'
import Loader from '@components/Loader'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './SecuritySection.css'

export type SecurityOptionEditContentRefType = { data: any }

export type EditContent = {
  contentRef: SecurityOptionEditContentRefType
  setIsEnabled: (enabled: boolean) => void
  onReset?: (data: any) => void
}

export interface SecuritySectionProps {
  titleKey: string
  descriptionKey: string
  onSave: (data: any) => void
  loading: boolean
  EditingContent: FC<EditContent>
  moreInfoLink?: string
  displayValue?: string
  dataTest?: string
}

const rootClass = 'security__section'

const SecuritySection = (props: SecuritySectionProps) => {
  const { titleKey, descriptionKey, onSave, loading, EditingContent, displayValue, moreInfoLink, dataTest = rootClass } = props
  const contentRef = useRef<SecurityOptionEditContentRefType>({ data: undefined })
  const [editing, setEditing] = useState<boolean>(false)
  const [saveEnabled, setSaveEnabled] = useState<boolean>(false)
  const { t } = useTranslation()

  const onEditClicked = useCallback(() => {
    setEditing(true)
  }, [])

  const onSaveClicked = useCallback(() => {
    onSave(contentRef.current.data)
    setEditing(false)
  }, [onSave])

  const onCancel = useCallback(() => {
    setEditing(false)
  }, [])

  return (
    <div data-test={dataTest} className={rootClass}>
      <div className={`${rootClass}__header`}>
        <Typography text={t(titleKey)} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} dataTest={`${dataTest}__header`} />
        {!editing ? (
          <Button
            buttonType={ButtonType.FLOAT_TEAL}
            onClick={onEditClicked}
            className={`${rootClass}__edit-button`}
            dataTest={`${dataTest}__edit-button`}
          >
            {t(`Edit`)}
          </Button>
        ) : (
          <div className={`${rootClass}__header-status`}>
            <Svg name={SvgNames.pencil} type={SvgType.LIGHT_SMALLER_ICON} />
            <Typography text={t(`Editing`)} type={TextType.BODY_TEXT_LIGHT} lineHeight={LineHeight.MEDIUM_LARGE} />
          </div>
        )}
      </div>
      <div className={`${rootClass}__description`}>
        <Typography text={t(descriptionKey)} type={TextType.BODY_TEXT_LIGHT} dataTest={`${dataTest}__description`} inline />
        {!!moreInfoLink && (
          <TextLink
            className={`${rootClass}__header-info-link`}
            dataTest={`${dataTest}__header-info-link`}
            text={t('More info')}
            link={moreInfoLink}
            size={TextLinkSize.LARGE}
          />
        )}
      </div>
      {!loading && !editing && !!displayValue && (
        <Typography text={displayValue} type={TextType.BODY_TEXT_LIGHT} className={`${rootClass}__display-value`} />
      )}
      {loading ? (
        <Loader />
      ) : (
        editing && (
          <>
            <div className={`${rootClass}__content`}>
              <EditingContent
                contentRef={contentRef.current}
                setIsEnabled={setSaveEnabled}
                onReset={(data) => {
                  onSave(data)
                  setEditing(false)
                }}
              />
            </div>
            <div className={`${rootClass}__actions`}>
              <Button buttonType={ButtonType.PRIMARY} onClick={onSaveClicked} dataTest={`${dataTest}__save-button`} disabled={!saveEnabled}>
                {t('Save')}
              </Button>
              <Button buttonType={ButtonType.TEXT} onClick={onCancel} dataTest={`${dataTest}__cancel-button`}>
                {t('Cancel')}
              </Button>
            </div>
          </>
        )
      )}
    </div>
  )
}

export default SecuritySection
