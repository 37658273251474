import React, { FC, useEffect, useMemo, useState } from 'react'

import { ButtonType } from '@components/Button'
import ButtonWithLoader from '@components/ButtonWithLoader/ButtonWithLoader'
import Loader, { LoaderTypes } from '@components/Loader/Loader'
import PageContainer from '@components/PageContainer/PageContainer'
import StatusToast, { Status } from '@components/StatusToast/StatusToast'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useAccountSettings } from '@utils/account/account.utils'
import { useTranslation } from '@utils/const/globals'
import { StatusToastType } from '@utils/interface/StatusToast'

import { useSavedRowsCopyRequests } from './graphql/SavedRowsCopy.requests'
import { savedRowsCopyInfo } from './utils/SavedRowsCopy.constants'

import './SavedRowsCopy.css'

const rootClass = 'saved-rows-copy'

interface SavedRowsCopyProps {
  dataTest?: string
}

const SavedRowsCopy: FC<SavedRowsCopyProps> = ({ dataTest = rootClass }) => {
  const { t } = useTranslation()
  const { parentId, accountId: originalAccountId, userId } = useAccountSettings()
  const parentAccountId = parentId === '0' ? originalAccountId : parentId

  const { getAccountDataRequest, getSavedRowsCountRequest, pushToChildAccountRequest } = useSavedRowsCopyRequests(userId, parentAccountId ?? '0')
  const [savedRowsCount, setSavedRowsCount] = useState<number>(0)
  const [childAccountsCount, setChildAccountsCount] = useState<number>(0)
  const [isSavedRowsCountLoading, setIsSavedRowsCountLoading] = useState(true)
  const [isAccountDataLoading, setIsAccountDataLoading] = useState(true)
  const [isPushToChildAccountLoading, setIsPushToChildAccountLoading] = useState(false)

  const [toast, setToast] = useState<StatusToastType | undefined>(undefined)

  useEffect(() => {
    const getAccountData = async () => {
      try {
        const { data, errors } = await getAccountDataRequest()
        if (errors) {
          setIsAccountDataLoading(false)
        } else if (data?.accountData) {
          setChildAccountsCount(data.accountData.currentChildAccounts)
        }
      } finally {
        setIsAccountDataLoading(false)
      }
    }

    const getSavedRowsCount = async () => {
      try {
        const { data, errors } = await getSavedRowsCountRequest()
        if (errors) {
          setIsSavedRowsCountLoading(false)
        } else if (data?.getEmailComposerRowsCount) {
          setSavedRowsCount(data.getEmailComposerRowsCount)
        }
      } finally {
        setIsSavedRowsCountLoading(false)
      }
    }

    getAccountData()
    getSavedRowsCount()
  }, [])

  const overviewData = useMemo(
    () => [
      {
        label: t('SavedRows.Copy.ContentRows'),
        value: savedRowsCount,
      },
      {
        label: t('SavedRows.Copy.TotalAccounts'),
        value: childAccountsCount,
      },
    ],
    [savedRowsCount, childAccountsCount, t]
  )

  const handlePushToChildAccount = async () => {
    setIsPushToChildAccountLoading(true)
    try {
      const { data } = await pushToChildAccountRequest()
      if (data?.sharedSavedRows) {
        if (data.sharedSavedRows.status === 'ok') {
          setToast({
            showStatus: true,
            successStatus: true,
            statusMessage: (
              <Typography tagProps={{ bold: { weight: TextWeight.BOLD } }} text={t('SavedRows.Copy.PushSuccess', { count: childAccountsCount })} />
            ),
          })
        } else {
          setToast({
            showStatus: true,
            successStatus: false,
            statusMessage: t('SavedRows.Copy.PushError', { errorMessage: data.sharedSavedRows.errorMessage ?? '' }),
          })
        }
      }
    } finally {
      setIsPushToChildAccountLoading(false)
    }
  }

  return (
    <PageContainer className={rootClass} dataTest={dataTest}>
      {toast?.showStatus && (
        <StatusToast
          status={toast.successStatus ? Status.SUCCESS : Status.FAIL}
          isSuccess={toast.successStatus}
          message={toast.statusMessage}
          closeStatus={() => {
            setToast({ ...toast, showStatus: false })
          }}
        />
      )}
      <div className={`${rootClass}__content`}>
        <Typography type={TextType.PAGE_HEADER} dataTest={`${dataTest}-header`}>
          {t('SavedRows.Copy.Header')}
        </Typography>
        <div className={`${rootClass}__overview`}>
          <Typography type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} dataTest={`${dataTest}-overview`}>
            {t('SavedRows.Copy.Overview')}
          </Typography>
          <div className={`${rootClass}__overview-content`}>
            {overviewData.map(({ label, value }) => (
              <div className={`${rootClass}__overview-content-item`} key={label}>
                <Typography dataTest={`${dataTest}-overview-content-${label}`}>{label}</Typography>
                <Typography
                  type={TextType.EXTRA_LARGE}
                  weight={TextWeight.MEDIUM}
                  className={`${rootClass}__overview-content-item-count`}
                  dataTest={`${dataTest}-overview-content-${label}-count`}
                >
                  {isSavedRowsCountLoading || isAccountDataLoading ? <Loader loaderType={LoaderTypes.row} /> : value}
                </Typography>
              </div>
            ))}
          </div>
          <ButtonWithLoader
            buttonType={ButtonType.PRIMARY}
            className={`${rootClass}__overview-button`}
            dataTest={`${dataTest}-overview-button`}
            onClick={handlePushToChildAccount}
            loading={isPushToChildAccountLoading}
            disabled={isSavedRowsCountLoading || isAccountDataLoading}
          >
            {t('SavedRows.Copy.Push')}
          </ButtonWithLoader>
          <div className={`${rootClass}__info`}>
            <Typography>{t('SavedRows.Copy.Info')}</Typography>
            <ul>
              {savedRowsCopyInfo.map(({ text, subInfo }) => (
                <li key={text}>
                  <Typography text={t(text)} />
                  {subInfo && (
                    <ul>
                      {subInfo.map(({ text }) => (
                        <li key={text}>
                          <Typography text={t(text)} />
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </PageContainer>
  )
}

export default SavedRowsCopy
