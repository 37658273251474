import { UserResponse } from '@graphql/types/query-types'
import { RefreshSalesUsersRef } from '@src/pages/Settings/Users/tabs/SalesUsers/SalesUsersContextProvider'
import { createSafeContext, useSafeContext } from '@utils/account/account.utils'

export interface SalesUsersContextAPI {
  salesUsers: UserResponse[]
  maxAllowedCountForPortal: number
  maxAllowedCountForCrm: number
  enableImportCRM: boolean
  enableLaunch: boolean
  crmUsersCount: number
  portalUsersCount: number
  loading: boolean
  onEditSalesPermissions: () => void
  onImportSalesUsers: () => void
  allowEditSalesPermission: boolean
  refetchRefs: RefreshSalesUsersRef
}

const SalesUsersContext = createSafeContext<SalesUsersContextAPI>()
export const useSalesUsers = () => useSafeContext(SalesUsersContext)

export default SalesUsersContext
