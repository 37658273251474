import React, { FC, useContext } from 'react'

import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import { ModalHeader } from '@components/Modal'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import { TableV2 } from '@components/TableV2/TableV2'
import Typography from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { columns } from '@src/pages/ContactSegments/components/CopySegments/components/ReviewSegmentsModal/ReviewSegmentsModal.table.utils'
import { rootText } from '@src/pages/ContactSegments/components/CopySegments/constants/CopySegments.constants'
import { CopySegmentsContext } from '@src/pages/ContactSegments/components/CopySegments/context/CopySegments.context'
import { useUnmappedFieldSegments } from '@src/pages/ContactSegments/components/CopySegments/utils/UseUnmappedFieldSegments'

import './ReviewSegmentsModal.css'

const rootClass = 'review-segments-modal'
const modalText = `${rootText}Modal.ReviewSegmentsModal.`

const ReviewSegmentsModal: FC = () => {
  const {
    values: { segments, parentLocation },
    migrateClassicSegments,
    update,
  } = useContext(CopySegmentsContext)
  const { t } = useTranslation()

  const unmappedFieldsSegments = useUnmappedFieldSegments()

  const onAnswer = (answer: YesNo) => {
    if (answer === YesNo.YES) {
      migrateClassicSegments()
    }
    update({ showReviewSegmentsModal: false })
  }

  return (
    <ConfirmationModal
      isOpen
      className={rootClass}
      body={
        <>
          <Typography className={`${rootClass}__info`} text={`${t(`${modalText}Info`)}${parentLocation?.name ?? 'All Act-On Contacts'}`} />
          <TableV2
            columns={columns}
            data={segments.filter((segment) => unmappedFieldsSegments.every((unmappedFieldsSegment) => unmappedFieldsSegment.id !== segment.id))}
            withoutBorders
            showBottomBorder
          />
        </>
      }
      title={
        <ModalHeader className={`${rootClass}__header`} headerType={ModalHeaderType.Form}>
          {t(`${modalText}Title`)}
        </ModalHeader>
      }
      isYesNo
      noButtonText={t('Cancel')}
      yesButtonText={t('Submit')}
      onAnswer={onAnswer}
      showDivider
    />
  )
}

export default ReviewSegmentsModal
