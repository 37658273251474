import SvgNames from '@components/Svg/SvgNames'
import { sendBroadcastMessage } from '@utils/composer/commonComposer/broadcastChannel'
import { EXTERNAL_WINDOW_FEATURES } from '@utils/iframeWrapperUrls'
import { NavigationInterface } from '@utils/navigation/navigation.utils'
const allContactsItems = {
  id: 'contact-segments',
  label: 'All Contacts',
  url: 'segments?clearDetails',
  settings: ['hasShowActOnContactsTab'],
  alternateUrls: [
    'segments',
    'customData',
    'content/formSubmissions',
    'content/webinarSubmissions',
    'classic/segment/_new_/*/?back=%2Fapp%2Fsegments',
    'classic/segment/_new_/*/?isFormSubmission=true&back=%2Fapp%2Fcontent%2FformsSubmissions',
    'classic/segment/*/*/?back=%2Fapp%2Fsegments',
  ],
}

const accountsItems = {
  id: 'contacts-accounts',
  label: 'Accounts',
  url: 'classic/entity/filters?model=abm&namespace=abm&type=Account',
  settings: ['accountBasedMarketing', 'crmServiceEnabled'],
}

const scoringRulesItems = [
  {
    id: 'contacts-scoring-rules',
    label: 'Scoring Rules',
    url: 'classic/scoring',
    settings: ['!nimbusScoringUI'],
  },
  {
    id: 'contacts-scoring-rules-new',
    label: 'Scoring Rules',
    url: 'classic/of/sc/new',
    settings: ['nimbusScoringUI'],
  },
]

const subscriptionManagementItems = [
  {
    id: 'contacts-subscription-management',
    label: 'Subscription Management',
    url: 'classic/if/optinout/settings.jsp',
    settings: ['subscriptionManagementEnabled', 'isAccountAdmin', '!hasSubscriptionManagementMultilanguage'],
  },
  {
    id: 'contacts-subscription-management',
    label: 'Subscription Management',
    url: 'subscriptionManagement',
    settings: ['subscriptionManagementEnabled', 'isAccountAdmin', 'hasSubscriptionManagementMultilanguage'],
  },
]

const marketingListItem = {
  id: 'contacts-marketing-lists',
  label: 'Marketing Lists',
  url: 'classic/if/_lists/marketingListing.jsp',
  alternateUrls: ['classic/segment/_new_/*', 'classic/segment/*/*'],
  excludeUrls: [
    'classic/segment/_new_/*/?back=%2Fapp%2Fsegments',
    'classic/segment/_new_/*/?isFormSubmission=true&back=%2Fapp%2Fcontent%2FformsSubmissions',
    'classic/segment/*/*/?back=%2Fapp%2Fsegments',
  ],
  settings: ['!exclusivelyActOnContacts'],
}

const otherListsItem = {
  id: 'contacts-other-lists',
  label: 'Other Lists',
  items: [
    {
      id: 'contacts-other-lists-account-lists',
      label: 'Account Lists',
      url: 'classic/accountLists',
    },
    {
      id: 'contacts-other-lists-form-submissions',
      label: 'Form Submissions',
      url: 'classic/if/_lists/formSubmissionListing.jsp?full=1',
      settings: ['!exclusivelyActOnContacts'],
    },
    {
      id: 'contacts-other-lists-webinar-lists',
      label: 'Webinar Lists',
      url: 'classic/if/_lists/webinarListing.jsp?full=1',
      settings: ['!exclusivelyActOnContacts'],
    },
    {
      id: 'contacts-bounces-opt-outs',
      label: 'Bounces & Opt-Outs',
      url: 'classic/if/lists/systemListsListing.jsp',
    },
    {
      id: 'contacts-other-lists-custom-touch-points',
      label: 'Custom Touch Points',
      url: 'classic/if/custom/customEventsListing.jsp',
      settings: ['customEventsEnabled'],
    },
    {
      id: 'contacts-other-lists-extension-lists',
      label: 'Extension Lists',
      url: 'classic/if/lists/listing.jsp?tab=4',
      settings: ['extendedListsSupported', '!exclusivelyActOnContacts'],
    },
    {
      id: 'contacts-other-lists-need-attention',
      label: 'Needs Attention',
      url: 'classic/if/lists/listing.jsp?tab=5',
      settings: ['quarantineEnabled'],
    },
    {
      id: 'contacts-other-lists-trash',
      label: 'Trash',
      url: 'classic/if/_lists/recyclebinListing.jsp',
      settings: ['!exclusivelyActOnContacts'],
    },
  ],
}

const standardFieldsItem = {
  id: 'contacts-standard-field-names',
  label: 'Standard Field Names',
  url: 'contacts/standardFieldNames',
  settings: ['!isZendesk', 'isAccountAdmin', '!exclusivelyActOnContacts'],
}

const navigation: NavigationInterface[] = [
  {
    id: 'home',
    icon: SvgNames.home,
    iconSelected: SvgNames.homeSelected,
    label: 'Home',
    items: [
      {
        id: 'home-start',
        label: 'Start',
        settings: ['!isMicrosoftStartPage'],
        url: 'classic/dashboard',
      },
      {
        id: 'home-start-microsoft',
        label: 'Start',
        settings: ['isMicrosoftStartPage'],
        url: 'classic/microsoftStart',
      },
      {
        id: 'home-activity',
        label: 'Activity',
        url: 'classic/if/home/actonToday.jsp',
      },
      {
        id: 'home-dashboard',
        label: 'Dashboard',
        settings: ['!isMicrosoftStartPage'],
        url: 'classic/if/home/dashboard.jsp',
      },
      {
        id: 'home-dashboard-microsoft',
        label: 'Dashboard',
        settings: ['isMicrosoftStartPage'],
        url: 'classic/dashboard/tab/microsoft',
      },
      {
        id: 'home-inbox-react-nav',
        label: 'Inbox',
        url: 'home/inbox',
        settings: ['canSubscribe', '!enableMarketingNetworkNav'],
      },
      {
        id: 'home-inbox-react-nav',
        label: 'Inbox',
        url: 'home/inbox',
        settings: ['canSubscribe', 'enableMarketingNetworkNav', '!isAgencyAccount', '!isClientAccount', '!isManager'],
      },
    ],
  },
  {
    id: 'audience-center',
    icon: SvgNames.audienceCenter,
    iconSelected: SvgNames.audienceCenterSelected,
    label: 'Audience Center',
    settings: ['hasAudienceCenterNav'],
    items: [allContactsItems, accountsItems, ...scoringRulesItems, ...subscriptionManagementItems],
  },
  {
    id: 'lists',
    icon: SvgNames.lists,
    iconSelected: SvgNames.listsSelected,
    label: 'Lists',
    settings: ['hasAudienceCenterNav', '!exclusivelyActOnContacts'],
    items: [marketingListItem, otherListsItem, ...scoringRulesItems, standardFieldsItem, ...subscriptionManagementItems],
  },
  {
    id: 'lists',
    icon: SvgNames.lists,
    iconSelected: SvgNames.listsSelected,
    label: 'Lists',
    settings: ['hasAudienceCenterNav', 'exclusivelyActOnContacts'],
    items: [
      {
        id: 'contacts-other-lists-account-lists',
        label: 'Account Lists',
        url: 'classic/accountLists',
      },
      {
        id: 'contacts-other-lists-custom-touch-points',
        label: 'Custom Touch Points',
        url: 'classic/if/custom/customEventsListing.jsp',
        settings: ['customEventsEnabled'],
      },
      {
        id: 'contacts-bounces-opt-outs',
        label: 'Bounces & Opt-Outs',
        url: 'classic/if/lists/systemListsListing.jsp',
      },
    ],
  },
  {
    id: 'contacts',
    icon: SvgNames.contacts,
    iconSelected: SvgNames.contactsSelected,
    label: 'Contacts',
    items: [
      allContactsItems,
      marketingListItem,
      accountsItems,
      otherListsItem,
      ...scoringRulesItems,
      standardFieldsItem,
      ...subscriptionManagementItems,
      {
        id: 'contacts-search',
        label: 'Search',
        url: 'classic/if/lists/grep.jsp',
        settings: ['!disableBetaSearch'],
      },
    ],
    settings: ['!hasAudienceCenterNav'],
  },
  {
    id: 'content',
    icon: SvgNames.content,
    iconSelected: SvgNames.contentSelected,
    label: 'Content',
    items: [
      {
        id: 'content-catalog',
        label: 'Catalog',
        url: 'classic/sharing',
      },
      {
        id: 'content-email-templates',
        label: 'Email Templates',
        url: 'classic/templateLists',
        settings: ['!hasEmailTemplatesReact'],
      },
      {
        id: 'content-email-templates-listing',
        label: 'Email Templates',
        url: 'content/emailTemplates',
        alternateUrls: ['content/emailTemplates/invalid-email/:id'],
        settings: ['hasEmailTemplatesReact'],
      },
      {
        id: 'content-landing-pages',
        label: 'Landing Pages',
        url: 'classic/if/_messages/landingPageListing.jsp',
        settings: ['!landingPageListing'],
      },
      {
        id: 'content-landing-pages-listing',
        label: 'Landing Pages',
        url: 'classic/landingPageLists',
        settings: ['landingPageListing', '!enableLandingPageSectionReact'],
      },
      {
        id: 'content-landing-page-templates',
        label: 'Landing Page Templates',
        url: 'classic/landingPageTemplates',
        settings: ['!enableLandingPageSectionReact'],
      },
      {
        id: 'content-landing-pages',
        label: 'Landing Pages',
        url: 'content/landingPages',
        alternateUrls: ['content/landingPageTemplates'],
        settings: ['enableLandingPageSectionReact'],
      },
      {
        id: 'content-forms',
        label: 'Forms',
        url: 'classic/formsListing',
        settings: ['!enableFormsSectionReact'],
      },
      {
        id: 'content-forms',
        label: 'Forms',
        url: 'content/forms',
        settings: ['enableFormsSectionReact'],
        alternateUrls: ['content/forms/*', 'content/formTemplates'],
      },
      {
        id: 'content-form-templates',
        label: 'Form Templates',
        url: 'classic/formTemplateLists',
        settings: ['!enableFormsSectionReact'],
      },
      {
        id: 'content-content-fragments',
        label: 'Content Fragments',
        url: 'classic/fragmentsLists',
        settings: ['!hasContactFragmentsReact'],
      },
      {
        id: 'content-content-fragments',
        label: 'Content Fragments',
        url: 'content/fragments',
        settings: ['hasContactFragmentsReact'],
      },
      {
        id: 'content-ab-test',
        label: 'A/B Test Content',
        url: 'classic/if/_absplit/ablisting.jsp',
      },
      {
        id: 'content-progressive-profiling',
        label: 'Progressive Profiling',
        url: 'classic/if/forms/profileFormsListing.jsp',
        settings: ['!useOnlyNewForms'],
      },
      {
        id: 'content-form-post-urls',
        label: 'Form Post URLs',
        url: 'classic/if/account/externalURLS.jsp',
      },
      {
        id: 'content-email-headers',
        label: 'Email Headers',
        url: 'classic/if/account/headers.jsp',
      },
      {
        id: 'content-email-footers',
        label: 'Email Footers',
        url: 'classic/if/account/footers.jsp',
      },
      {
        id: 'content-stationery',
        label: 'Stationery',
        url: 'classic/if/account/stationery.jsp',
      },
      {
        id: 'content-branding',
        label: 'Branding',
        url: 'classic/branding',
        settings: ['disableBrandingReactPage'],
      },
      {
        id: 'content-branding',
        label: 'Branding',
        url: 'content/branding',
        settings: ['!disableBrandingReactPage'],
      },
      {
        id: 'content-branding-signatures',
        label: 'Signatures',
        url: 'classic/branding/tab/signatures',
        settings: ['!disableBrandingReactPage'],
      },
      {
        id: 'content-image-library',
        label: 'Image Library',
        url: 'classic/imageLibrary',
      },
      {
        id: 'content-media-library',
        label: 'Media Library',
        url: 'classic/if/_docs/mediaListing.jsp',
      },
    ],
  },
  {
    id: 'inbound',
    icon: SvgNames.inbound,
    iconSelected: SvgNames.inboundSelected,
    label: 'Inbound',
    items: [
      {
        id: 'inbound-website-prospector',
        label: 'Website Prospector',
        url: 'classic/if/_wvt/index.jsp',
        alternateUrls: ['classic/if/_wvt/index.jsp?*'],
      },
      {
        id: 'inbound-twitter-prospector',
        label: 'Twitter Prospector',
        url: 'classic/if/smedia/tweetDashboard.jsp',
        settings: ['!hideTwitterProspector'],
      },
      {
        id: 'inbound-seo-audit',
        label: 'SEO Audit',
        settings: ['seoEnabled'],
        items: [
          {
            id: 'inbound-seo-audit-web-pages',
            label: 'SEO Audit Web Pages',
            url: 'classic/if/_seo/seoListing.jsp',
          },
          {
            id: 'inbound-seo-audit-landing-pages',
            label: 'SEO Audit Landing Pages',
            url: 'classic/if/_seo/seoLPListing.jsp',
          },
          {
            id: 'inbound-seo-audit-forms',
            label: 'SEO Audit Forms',
            url: 'classic/if/_seo/seoFormsListing.jsp',
          },
        ],
      },
      {
        id: 'inbound-google-adwords-report',
        label: 'Google Ads Report',
        url: 'classic/if/_reports/googleAdwords.jsp',
        settings: ['adwordsEnabled'],
      },
      {
        id: 'inbound-website-prospector-alerts',
        label: 'Website Prospector Alerts',
        url: 'classic/if/beacon/alerts.jsp',
      },
    ],
  },
  {
    id: 'outbound',
    icon: SvgNames.outbound,
    iconSelected: SvgNames.outboundSelected,
    label: 'Outbound',
    items: [
      {
        id: 'outbound-new-message-new-composer',
        label: 'New Message',
        openWindow: {
          url: '../_compose/start.jsp?isDraft=1',
        },
        settings: ['isNewComposerOnly'],
      },
      {
        id: 'outbound-new-message',
        label: 'New Message',
        openWindow: {
          url: '../compose/start.jsp',
          width: 800,
          popup: true,
        },
        settings: ['!isNewComposerOnly'],
      },
      {
        id: 'outbound-drafts',
        label: 'Drafts',
        url: 'classic/if/_messages/emailDraftsListing.jsp',
        settings: ['!draftListing', '!enableEmailDraftsReact'],
      },
      {
        id: 'outbound-drafts-listing',
        label: 'Drafts',
        url: 'classic/draftLists',
        alternateUrls: ['content/emailDrafts/invalid-email/:id'],
        settings: ['draftListing', '!enableEmailDraftsReact'],
      },
      {
        id: 'outbound-drafts',
        label: 'Drafts',
        url: 'outbound/drafts',
        alternateUrls: ['outbound/drafts/invalid-email/:id'],
        settings: ['enableEmailDraftsReact'],
      },
      {
        id: 'outbound-sent-messages',
        label: 'Sent Messages',
        url: 'classic/if/_messages/sentMessageListing.jsp',
      },
      {
        id: 'outbound-test-messages',
        label: 'Test Messages',
        url: 'classic/if/messages/listingSent.jsp?type=TEST',
      },
      {
        id: 'outbound-other-messages',
        label: 'Other Messages',
        items: [
          {
            id: 'outbound-other-messages-awaiting-approval',
            label: 'Awaiting Approval',
            url: 'classic/if/messages/listingScheduled.jsp?approve=1',
          },
          {
            id: 'outbound-other-messages-scheduled',
            label: 'Scheduled',
            url: 'classic/if/messages/listingScheduled.jsp?scheduled=1',
          },
          {
            id: 'outbound-other-messages-failed',
            label: 'Failed',
            url: 'classic/if/messages/listingScheduled.jsp?failed=1',
          },
          {
            id: 'outbound-other-messages-triggered-messages',
            label: 'Triggered Messages',
            url: 'classic/if/messages/listingAutoResponse.jsp',
          },
        ],
      },
      {
        id: 'outbound-ab-test-messages',
        label: 'A/B Test Messages',
        url: 'classic/if/messages/listingABTest.jsp',
      },
      {
        id: 'outbound-rss-to-email',
        label: 'RSS to Email',
        url: 'classic/if/_rss/rssListing.jsp',
        settings: ['isNewComposerOnly'],
      },
      {
        id: 'outbound-social-publish',
        label: 'Social Publish',
        url: 'classic/if/socialpub/listingPublished.jsp?type=PUBLISHED',
      },
    ],
  },
  {
    id: 'sms',
    icon: SvgNames.sms,
    iconSelected: SvgNames.smsSelected,
    label: 'SMS',
    settings: ['!disableSMSForAll'],
    items: [
      {
        id: 'sms-opt-ins',
        label: 'Opt-ins',
        url: 'sms/optins',
        settings: ['!hasPurchasedSMS'],
      },
      {
        id: 'sms-learn-more',
        label: 'Learn More',
        afterImg: SvgNames.externalLink,
        openWindow: {
          url: 'https://act-on.com/sms-for-customers',
          isExternalUrl: true,
        },
        settings: ['!hasPurchasedSMS', '!disableSMSForAll'],
      },
      {
        id: 'sms-sent-sms',
        label: 'Messages',
        url: 'sms/messages',
        settings: ['hasPurchasedSMS'],
        alternateUrls: ['sms/*/edit', 'sms/*/report', 'sms/*/report', 'sms/messages/*'],
      },
      {
        id: 'sms-opt-ins',
        label: 'Opt-ins',
        url: 'sms/optins',
        settings: ['hasPurchasedSMS'],
      },
      {
        id: 'sms-opt-outs',
        label: 'Opt-outs',
        url: 'sms/optouts',
        settings: ['hasPurchasedSMS'],
      },
      {
        id: 'sms-settings',
        label: 'Settings',
        url: 'sms/settings',
        settings: ['userSettings.isAdministrator', 'hasPurchasedSMS'],
      },
    ],
  },
  {
    id: 'automation',
    icon: SvgNames.automation,
    iconSelected: SvgNames.automationSelected,
    label: 'Automation',
    items: [
      {
        id: 'automation-automated-programs',
        label: 'Automated Programs',
        url: 'classic/programList',
        alternateUrls: [
          'programs/*/edit',
          'programs/*/dashboard',
          'programs/*/dashboard/*',
          'automation/programs/:id',
          'classic/if/programs4/edit.jsp?*',
          'classic/program?*',
        ],
        settings: ['!hasAPListingReact'],
      },
      {
        id: 'automation-automated-programs',
        label: 'Automated Programs',
        url: 'automation/programs',
        alternateUrls: ['programs/*/edit', 'programs/*/dashboard', 'programs/*/dashboard/*', 'automation/programs/:id'],
        settings: ['hasAPListingReact'],
      },
      {
        id: 'new-automation-list-maintenance-programs',
        label: 'List Maintenance Programs',
        url: 'automation/listMaintenance',
      },
      {
        id: 'automation-program-templates',
        label: 'Program Templates',
        url: 'classic/programTemplateList',
        settings: ['!hasAPListingReact'],
      },
      {
        id: 'automation-webinars',
        label: 'Webinars',
        url: 'classic/if/webinar/listing.jsp',
      },
      {
        id: 'automation-campaigns',
        label: 'Campaigns',
        url: 'classic/if/_reports/unifiedCampaigns.jsp',
        settings: ['campaignsEnabled', 'unifiedCampaigns'],
      },
      {
        id: 'automation-campaigns-listings',
        label: 'Campaigns',
        url: 'classic/if/campaigns/listing.jsp',
        settings: ['campaignsEnabled', '!unifiedCampaigns'],
      },
      {
        id: 'automation-campaigns-listing',
        label: 'Campaigns',
        url: 'classic/campaign',
        beta: true,
        settings: ['connectedCampaignsEnabled', 'crmServiceEnabled'],
      },
      {
        id: 'automation-projects',
        label: 'Projects',
        url: 'classic/if/projects/listing.jsp',
        settings: ['projectsEnabled'],
      },
      {
        id: 'automation-marketing-calendar',
        label: 'Marketing Calendar',
        url: 'classic/of/mc/',
        settings: ['!hideMarketingCalendar'],
      },
    ],
  },
  {
    id: 'reports',
    icon: SvgNames.reports,
    iconSelected: SvgNames.reportsSelected,
    label: 'Reports',
    settings: ['reportsEnabled'],
    items: [
      {
        id: 'reports-website-visitors',
        label: 'Website Visitors',
        url: 'report/websiteVisitors',
      },
      {
        id: 'reports-email-messages',
        label: 'Email Messages',
        url: 'report/emailMessages',
        settings: ['!hasEmbeddedAnalytics', '!hasAdvancedAnalytics'],
      },
      {
        id: 'reports-email-performance',
        label: 'Email Performance',
        url: 'report/emailPerformance',
        settings: ['hasEmbeddedAnalytics', '!hasAdvancedAnalytics'],
      },
      {
        id: 'reports-analytics',
        label: 'Analytics',
        url: 'report/analytics',
        settings: ['hasAdvancedAnalytics'],
      },
      {
        id: 'reports-forms-lps-media',
        label: 'Forms, Landing Pages, Media',
        url: 'report/forms-lps-media',
        settings: ['hasEmbeddedAnalytics', '!hasAdvancedAnalytics'],
      },
      {
        id: 'reports-revenue-attribution',
        label: 'Revenue Attribution',
        url: 'classic/if/_reports/revenueAttributionReport.jsp',
        settings: ['revenueAttributionEnabled'],
      },
      {
        id: 'reports-revenue-impact',
        label: 'Revenue Impact',
        url: 'report/revenueImpact',
        settings: ['revenueImpactEnabled', '!enableRevenueReportMappingTab'],
      },
      {
        id: 'reports-revenue-impact-new',
        label: 'Revenue Impact',
        url: 'report/impact/revenue',
        settings: ['revenueImpactEnabled', 'enableRevenueReportMappingTab'],
      },
      {
        id: 'reports-funnel-reports',
        label: 'Funnel Reports',
        url: 'classic/if/sandbox/funnel/list.jsp',
        settings: ['funnelEnabled'],
      },
      {
        id: 'reports-data-studio',
        label: 'Data Studio',
        openWindow: {
          url: '/acton/_export/',
          name: '_dataStudio',
          externalWindowWrapper: EXTERNAL_WINDOW_FEATURES.DATA_STUDIO,
        },
        settings: ['dataStudioEnabled'],
      },
    ],
  },
  // NOTE: Saving since this will probably come back in the near future when the start page/home project is started
  // {
  //   id: 'network-marketing-child-inbox',
  //   label: 'Inbox',
  //   url: 'home/inbox',
  //   icon: SvgNames.inbox,
  //   settings: ['canSubscribe', 'enableMarketingNetworkNav', '!isAgencyAccount', '!isClientAccount'],
  // },
  {
    id: 'marketing-network-agency',
    label: 'Marketing Network',
    icon: SvgNames.marketingNetwork,
    iconSelected: SvgNames.marketingNetworkSelected,
    settings: ['enableMarketingNetworkNav', 'isAgencyAccount'],
    items: [
      {
        id: 'home-inbox-react',
        label: 'Inbox',
        url: 'home/inbox',
        settings: ['canSubscribe'],
      },
      {
        id: 'account-management',
        label: 'Account Management',
        url: 'marketingNetwork/accountManagement',
      },
      {
        id: 'contacts-usage',
        label: 'Contacts Usage',
        url: 'marketingNetwork/contactsUsage',
        settings: ['userSettings.isAdministrator'],
      },
      {
        id: 'message-usage',
        label: 'Message Usage',
        url: 'marketingNetwork/messageUsage',
        settings: ['userSettings.isAdministrator', 'showAgencyMessageUsage'],
      },
      {
        id: 'customize',
        label: 'Customize',
        url: 'marketingNetwork/customize',
        settings: ['userSettings.isAdministrator', 'hasAgencyWhiteLabel'],
      },
    ],
  },
  {
    id: 'marketing-network-client',
    label: 'Marketing Network',
    icon: SvgNames.threeUsers,
    iconSelected: SvgNames.threeUsers,
    settings: ['enableMarketingNetworkNav', 'isClientAccount', '!isManager'],
    items: [
      {
        id: 'marketing-network-home-inbox',
        label: 'Inbox',
        url: 'home/inbox',
        settings: ['canSubscribe'],
      },
      {
        id: 'back-to-parent',
        label: 'Back To Parent',
        url: 'backToParent',
        callBack: (accountId?: string) => {
          sendBroadcastMessage({ type: 'LOGIN', payload: accountId })
        },
      },
    ],
  },
  {
    id: 'marketing-network-manager',
    label: 'Marketing Network',
    icon: SvgNames.threeUsers,
    iconSelected: SvgNames.threeUsers,
    settings: ['enableMarketingNetworkNav', '!isAgencyAccount', 'isManager'],
    items: [
      {
        id: 'marketing-network-home-inbox',
        label: 'Inbox',
        url: 'home/inbox',
        settings: ['canSubscribe'],
      },
      {
        id: 'account-management',
        label: 'Account Management',
        url: 'marketingNetwork/accountManagement',
      },
    ],
  },
  {
    id: 'settings',
    icon: SvgNames.settingsThreeLines,
    iconSelected: SvgNames.settingsThreeLinesSelected,
    label: 'Settings',
    items: [
      {
        id: 'settings-ops',
        label: 'Ops',
        url: 'classic/if/ops/ops.jsp',
        settings: ['isRootAccount', 'isLocalHost'],
        settingsAnd: false,
      },
      {
        id: 'settings-users',
        label: 'Users',
        url: 'settings/users',
        settings: ['enableUsersReactPage'],
      },
      {
        id: 'settings-users',
        label: 'Users',
        url: 'classic/if/account/users.jsp',
        settings: ['!enableUsersReactPage'],
      },
      {
        id: 'settings-social-media-team',
        label: 'Social Media Team',
        url: 'classic/if/smedia/sPageSetup.jsp',
        settings: ['!hideTwitterProspector'],
      },
      {
        id: 'settings-other-settings',
        label: 'Other Settings',
        items: [
          {
            id: 'contact-settings',
            label: 'Contact Settings',
            url: 'contactSettings',
            settings: ['hasShowActOnContactsTab'],
          },
          {
            id: 'settings-other-settings-from-addresses',
            label: 'From Addresses',
            url: 'settings/fromAddresses',
          },
          {
            id: 'settings-other-settings-internal-ips',
            label: 'IP Addresses to Ignore',
            url: 'ipAddressesToIgnore',
          },
          {
            id: 'settings-other-settings-beacon-settings',
            label: 'Beacon Settings',
            url: 'beaconSettings',
          },
          {
            id: 'settings-other-settings-paid-search',
            label: 'Paid Search Settings',
            url: 'classic/if/_referraldetector/index.jsp',
          },
          {
            id: 'settings-other-settings-hide-all-folders',
            label: 'Hide All Folders',
            hideAllListingFolders: true,
            settings: ['hideAllFoldersEnabled'],
          },
          {
            id: 'settings-other-settings-erase-contacts',
            label: 'Erase Contact',
            url: 'eraseContact',
            settings: ['isAccountAdmin'],
          },
          {
            id: 'settings-other-settings-custom-account-settings',
            label: 'Custom Account Settings',
            url: 'settings/customAccountSettings',
            settings: ['isAccountAdmin'],
          },
          {
            id: 'settings-other-settings-true-open-settings',
            label: 'True Open Settings',
            url: 'settings/trueOpenSettings',
            settings: ['enableEmailListingTrueOpenFilter'],
          },
        ],
      },
      {
        id: 'settings-apps',
        label: 'Apps',
        url: 'classic/if/oauth/OAuthSettings.jsp',
        settings: ['oauthEnabled'],
      },
      {
        id: 'settings-non-crm-administration-react-ui',
        label: 'Data Management',
        url: 'datamanagement',
        settings: ['hasShowActOnContactsTab', '!isSF', '!isMSDyn', '!isNS', '!isSL', '!isSugar', '!isZendesk'],
      },
      {
        id: 'settings-crm-administration-react-ui-sf',
        label: 'Data Management',
        url: 'datamanagement',
        settings: ['crmServiceEnabled', 'isSF', '!isMSDyn', '!isNS', '!isSL', '!isSugar', '!isZendesk'],
      },
      {
        id: 'settings-crm-administration-react-ui-msdyn',
        label: 'Data Management',
        url: 'datamanagement',
        settings: ['crmServiceEnabled', '!isSF', 'isMSDyn', '!isNS', '!isSL', '!isSugar', '!isZendesk'],
      },
      {
        id: 'settings-crm-administration-react-ui-ns',
        label: 'Data Management',
        url: 'datamanagement',
        settings: ['crmServiceEnabled', '!isSF', '!isMSDyn', 'isNS', '!isSL', '!isSugar', '!isZendesk'],
      },
      {
        id: 'settings-crm-administration-react-ui-sl',
        label: 'Data Management',
        url: 'datamanagement',
        settings: ['crmServiceEnabled', '!isSF', '!isMSDyn', '!isNS', 'isSL', '!isSugar', '!isZendesk'],
      },
      {
        id: 'settings-crm-administration-react-ui-sugar',
        label: 'Data Management',
        url: 'datamanagement',
        settings: ['crmServiceEnabled', '!isSF', '!isMSDyn', '!isNS', '!isSL', 'isSugar', '!isZendesk'],
      },
      {
        id: 'settings-crm-administration-react-ui-zendesk',
        label: 'Data Management',
        url: 'datamanagement',
        settings: ['crmServiceEnabled', '!isSF', '!isMSDyn', '!isNS', '!isSL', '!isSugar', 'isZendesk'],
      },
      {
        id: 'settings-connectors',
        label: 'Connectors',
        url: 'classic/if/_account/connectors.jsp',
      },
      {
        id: 'settings-system-status',
        label: 'System Status',
        openWindow: {
          url: 'https://status.act-on.com/',
          amc: true,
        },
        settings: ['isAccountAdmin', 'showStatusDashboard'],
      },
    ],
  },
  {
    id: 'ai-in-site',
    icon: SvgNames.mopoNav,
    iconSelected: SvgNames.mopoNav,
    label: 'AI InSite',
    settings: ['isMopoVisible'],
    items: [
      {
        id: 'insite-settings',
        label: 'Settings',
        openWindow: {
          url: 'https://insite.actonsoftware.com/',
          isExternalUrl: true,
        },
        settings: ['enableMopoCopilot'],
      },
      {
        id: 'insite-learnmore',
        label: 'Learn More',
        openWindow: {
          url: 'https://act-on.com/act-on-insite-for-customers/',
          isExternalUrl: true,
        },
        afterImg: SvgNames.externalLink,
        settings: ['!enableMopoCopilot'],
      },
    ],
  },
]

export default navigation
