import React, { ReactNode } from 'react'

import { TFunction } from 'i18next'

import SelectV2 from '@components/SelectV2/SelectV2'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { ColWithTooltip } from '@components/TableV2/utils/tableV2ColumnUtils'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import {
  FormFieldDataType,
  FormFieldUsage,
  FormsUpgradeAPI,
} from '@src/pages/UpgradeAssistant/components/FormsUpgradeModal/context/FormsUpgradeModal.context'
import { CellContext, SortingFn } from '@tanstack/react-table'
import { ensureFirstLetterIsCapitalized } from '@utils/strings'
import { editElement } from '@utils/utils'

const UNMAPPED_PLACEHOLDER = 'Don’t map to contact field'
const TOGGLE_TOOLTIP =
  'Some form fields may not match your contact field mappings, and that’s okay. Unmapped fields will remain on your form and be recorded as “form specific” fields in your contacts’ submission data.'

export const checkFormFieldErrors = (formFields: FormFieldUsage[]): FormFieldUsage[] => {
  const mappingsByForm = new Map<string, string[]>()
  formFields.forEach(({ fieldMapping, formsInfo, isMapped }) => {
    if (fieldMapping && isMapped) {
      formsInfo.forEach((form) => {
        if (form) {
          const currentMappings = mappingsByForm.get(form.id) || []
          mappingsByForm.set(form.id, [...currentMappings, fieldMapping])
        }
      })
    }
  })

  const getDuplicatedMappings = () => {
    const duplicatedMappings: string[] = []
    mappingsByForm.forEach((mappings) => {
      const duplicated = mappings.filter((mapping) => mappings.indexOf(mapping) !== mappings.lastIndexOf(mapping))
      duplicatedMappings.push(...duplicated)
    })
    return [...new Set(duplicatedMappings)]
  }

  return formFields.map((formField) => ({
    ...formField,
    isValid: !getDuplicatedMappings().includes(formField.fieldMapping || ''),
  }))
}

const statusSortingFunction: SortingFn<FormFieldUsage> = ({ original: formFieldA }, { original: formFieldB }) => {
  if (!formFieldA.isMapped) {
    return -1
  } else if (formFieldA.dataType === FormFieldDataType.FileField && formFieldB.dataType !== FormFieldDataType.FileField) {
    return -1
  } else if (!formFieldB.isMapped || !formFieldA.isValid) {
    return 1
  } else if (!formFieldB.isValid || (formFieldA.fieldMapping && !formFieldB.fieldMapping)) {
    return -1
  } else if (!formFieldA.fieldMapping && formFieldB.fieldMapping) {
    return 1
  } else {
    return 1
  }
}

const selectFieldMappingsOptions = (unifiedListFieldMappings: UnifiedListFieldMapping[]) =>
  unifiedListFieldMappings
    .filter(({ dataType, readOnly }) => dataType !== FormFieldDataType.Score && !readOnly)
    .sort((a, b) => ((a.displayName || '') > (b.displayName || '') ? 1 : -1))
    .map(({ displayName = '', dataType, columnIndex }) => ({
      label: displayName,
      value: displayName,
      extraOptions: { dataType, columnIndex },
    }))

const getOptionsByType = (dataTypes: FormFieldDataType[], unifiedListFieldMappings: UnifiedListFieldMapping[]) => {
  return selectFieldMappingsOptions(unifiedListFieldMappings).map((option) => {
    return {
      ...option,
      isDisabled: !dataTypes.includes(option.extraOptions?.dataType as FormFieldDataType),
    }
  })
}

const getFilteredOptions = (formFieldDataType: FormFieldDataType, unifiedListFieldMappings: UnifiedListFieldMapping[]) => {
  if (formFieldDataType === FormFieldDataType.Date || formFieldDataType === FormFieldDataType.Datetime) {
    return getOptionsByType([FormFieldDataType.Date, FormFieldDataType.Datetime], unifiedListFieldMappings)
  }
  if (formFieldDataType === FormFieldDataType.Email) {
    return getOptionsByType([FormFieldDataType.Email], unifiedListFieldMappings)
  }
  return getOptionsByType(
    [FormFieldDataType.Boolean, FormFieldDataType.MultiText, FormFieldDataType.Number, FormFieldDataType.Text],
    unifiedListFieldMappings
  )
}

export const renderMapToCell = (
  { cell, table }: CellContext<FormFieldUsage, unknown>,
  unifiedListFieldMappings: UnifiedListFieldMapping[],
  update: FormsUpgradeAPI['update'],
  t: TFunction
) => {
  const {
    index,
    original: { fieldMapping, fieldName, dataType, isMapped = dataType === FormFieldDataType.FileField && false, isValid },
  } = cell.row

  const onFieldMappingChange = (formFieldIndex: number, formFieldValues: Partial<FormFieldUsage>) => {
    const tempFormFields = editElement(formFieldIndex, formFieldValues, table.options.data)
    update({ formFields: checkFormFieldErrors(tempFormFields) })
  }

  const isFileField = dataType === FormFieldDataType.FileField

  const filteredOptions =
    isMapped && !isFileField
      ? getFilteredOptions(dataType as FormFieldDataType, unifiedListFieldMappings)
      : getFilteredOptions(dataType as FormFieldDataType, unifiedListFieldMappings).map((option) => ({ ...option, isDisabled: true }))
  const selectedFieldMapping = filteredOptions.find((option) => option.value === fieldMapping)

  return (
    <SelectV2
      footer={{
        toggle: {
          label: t(UNMAPPED_PLACEHOLDER),
          tooltip: t(TOGGLE_TOOLTIP),
          defaultValue: isFileField || !isMapped,
          disabled: isFileField,
          onClick: () => onFieldMappingChange(index, { isMapped: !isMapped }),
        },
      }}
      key={`${fieldName}-mapping-select`}
      value={isMapped && !isFileField ? selectedFieldMapping : { label: t(UNMAPPED_PLACEHOLDER), value: UNMAPPED_PLACEHOLDER }}
      defaultValue={selectedFieldMapping}
      onChange={(selectedOption) =>
        onFieldMappingChange(index, {
          fieldMapping: selectedOption?.value,
          uclColumnIndex: selectedOption?.extraOptions?.columnIndex !== undefined ? parseInt(selectedOption?.extraOptions?.columnIndex) : undefined,
          isMapped: true,
        })
      }
      options={filteredOptions}
      placeholder={t('Select matching field')}
      insideModal
      isClearable={isMapped}
      error={isMapped && !isValid}
      errorMessage={t('Contact field is already mapped on selected form(s).')}
      maxMenuHeight={350}
    />
  )
}

const renderStatusIconCell = ({ cell: { row } }: CellContext<FormFieldUsage, unknown>) => {
  const { fieldMapping, isMapped, isValid, dataType } = row.original
  const getIcon = () => {
    if (fieldMapping) {
      return isMapped && !isValid ? SvgNames.errorSolid : SvgNames.checkSuccess
    } else {
      return isMapped && dataType !== FormFieldDataType.FileField ? SvgNames.checkCircle : SvgNames.checkSuccess
    }
  }
  return <Svg key={`${row.original.fieldName}-status-icon`} type={SvgType.LARGE_ICON} name={getIcon()} fill={SvgColor.BUTTON_GRAY} />
}

export const formsUpgradeMapFieldsColumns = (
  renderMapToCell: ({ cell }: CellContext<FormFieldUsage, unknown>) => ReactNode
): ColumnDefWithAdditionalProps<FormFieldUsage>[] => [
  {
    accessorKey: 'status',
    cell: renderStatusIconCell,
    enableCustomCellValue: true,
    header: 'Status',
    minSize: 100,
    sortingFn: statusSortingFunction,
    textAlign: 'center',
  },
  {
    accessorKey: 'fieldName',
    header: 'Field name',
    minSize: 228,
    textAlign: 'left',
  },
  {
    accessorKey: 'dataType',
    cell: ({ cell }: CellContext<FormFieldUsage, unknown>) => ensureFirstLetterIsCapitalized(cell.row.original.dataType.toLowerCase()),
    header: 'Field type',
    minSize: 102,
    textAlign: 'left',
  },
  {
    accessorKey: 'formsInfo',
    cell: (cell: CellContext<FormFieldUsage, unknown>) => (
      <ColWithTooltip cell={cell} typographySpecifiedTitle={cell.row.original.formsInfo.map((form) => form?.name).join(', ')} />
    ),
    header: 'Used in form(s)',
    textAlign: 'left',
  },
  {
    accessorKey: 'fieldMappingArrow',
    cell: ({ cell }: CellContext<FormFieldUsage, unknown>) => (
      <Svg key={`${cell.row.original.fieldName}-arrow-icon`} type={SvgType.LARGER_ICON} name={SvgNames.arrowRight} fill={SvgColor.BUTTON_GRAY} />
    ),
    disableSorting: true,
    enableCustomCellValue: true,
    header: undefined,
    minSize: 40,
    padding: '12px',
    textAlign: 'center',
  },
  {
    accessorKey: 'fieldMapping',
    cell: renderMapToCell,
    disableSorting: true,
    enableCustomCellValue: true,
    header: 'Map to',
    textAlign: 'left',
  },
]
