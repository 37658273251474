import { gql } from '@apollo/client'

export default gql`
  mutation createSplits($splitInput: SplitInputDTOInput, $type: String) {
    createSplits(splitInput: $splitInput, type: $type) {
      body
      message
      objectId
      statusCode
    }
  }
`
