import { InvalidCRMStatus } from '@src/pages/ContactSegments/components/AddContactsFromCRM/AddContactsFromCRMContext'
import { CRM_STATUS } from '@utils/crm.utils'

interface InvalidSourceTextValues {
  headerText: string
  contentText: string
  contentLink?: string
}

export const InvalidSourceValues: { [key in InvalidCRMStatus]: InvalidSourceTextValues } = {
  [CRM_STATUS.SF_REPORT_MAX_RECORDS_REACHED]: {
    headerText: 'AddContactsFromCRM.SelectCRMSources.MaxRecordsReached.Header',
    contentText: 'AddContactsFromCRM.SelectCRMSources.MaxRecordsReached.Content',
    contentLink:
      'https://success.act-on.com/acton/attachment/9883/u-c7702e20-7378-4351-91f5-8c0dea7f387e/0/-/-/-/-/In-App%3A%20%20SF%20Report%20Sync%20Limitations',
  },
  [CRM_STATUS.SF_REPORT_ID_FIELD_MISSING]: {
    headerText: 'AddContactsFromCRM.SelectCRMSources.FieldMissing.Header',
    contentText: 'AddContactsFromCRM.SelectCRMSources.FieldMissing.Content',
  },
  [CRM_STATUS.SF_REPORT_UNSUPPORTED_FORMAT]: {
    headerText: 'AddContactsFromCRM.SelectCRMSources.Unsupported.Header',
    contentText: 'AddContactsFromCRM.SelectCRMSources.Unsupported.Content',
  },
  [CRM_STATUS.SF_REPORT_UNSUPPORTED_TYPE]: {
    headerText: 'AddContactsFromCRM.SelectCRMSources.Unsupported.Header',
    contentText: 'AddContactsFromCRM.SelectCRMSources.Unsupported.Content',
  },
  [CRM_STATUS.SF_REPORT_NOT_FOUND]: {
    headerText: 'AddContactsFromCRM.SelectCRMSources.NotFound.Header',
    contentText: 'AddContactsFromCRM.SelectCRMSources.NotFound.Content',
  },
}
