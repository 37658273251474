import React, { FC, useCallback, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import caretDownSVG from '@assets/inline/caret-down.svg'
import { useTranslation } from '@const/globals'

import Button, { ButtonType } from '../Button'
import Checkbox from '../Checkbox'
import { InputProps } from '../Input/Input'
import InputWithClear from '../InputWithClear/InputWithClear'
import { SvgNames } from '../Svg'

import './MultiSelect.css'

interface Props {
  title: string
  options: SelectOption[]
  onSubmit(selected: string[]): void
  className?: string
  dataTest?: string
}

export interface SelectOption {
  value: string
  label: string
  selected?: boolean
  filtered?: boolean
}

const rootClass = 'multi-select'

const MultiSelect: FC<Props> = (props: Props) => {
  const { onSubmit, title, options = [], dataTest = rootClass, className = '' } = props

  const [searchQuery, setSearchQuery] = useState<string>('')
  const [isActive, setIsActive] = useState<boolean>(false)
  const [cleanOptions, setCleanOptions] = useState<string[]>(options.filter((option) => option.selected).map((opt) => opt.value))

  const [tempOptions, setTempOptions] = useState<SelectOption[]>(
    options.map((option) => ({ ...option, selected: option.selected || false, filtered: true }))
  )

  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement>(null)

  const escapeListener = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setIsActive(false)
    }
  }, [])

  const clickListener = useCallback(
    (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setIsActive(false)
      }
    },
    [ref.current]
  )

  const tabListener = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Tab') {
      setIsActive(false)
    }
  }, [])

  const searchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = e.target.value

    const filteredOptions = tempOptions.map((option) => ({
      ...option,
      filtered: option.label.toLowerCase().includes(searchQuery.toLowerCase()) ? true : false,
    }))

    setSearchQuery(searchQuery)
    setTempOptions(filteredOptions)
  }

  const activateSelect = () => {
    setIsActive((isActive) => !isActive)
  }

  const clearSearch = () => {
    setSearchQuery('')
    setTempOptions(tempOptions.map((option) => ({ ...option, filtered: true })))
  }

  const onToggleSelected = (value: string) => {
    setTempOptions(tempOptions.map((option) => (option.value === value ? { ...option, selected: !option.selected } : option)))
  }

  const getTempSelected = () => {
    return tempOptions.filter((option) => {
      return option.selected
    })
  }

  const submitSelected = () => {
    setIsActive((isActive) => !isActive)

    const selected = tempOptions.filter((option) => option.selected).map((opt) => opt.value)

    clearSearch()
    setCleanOptions(selected)
    onSubmit(selected)
  }

  const inputProps: InputProps = {
    value: searchQuery,
    className: classNames(`${rootClass}__search`),
    placeholder: `${t('Search')} ${title}`,
    icon: SvgNames.search,
    dataTest,
    inputAriaLabel: 'searchInput',
    onChange: searchChange,
  }

  useEffect(() => {
    // Attach the listeners on component mount.
    document.addEventListener('click', clickListener)
    document.addEventListener('keyup', escapeListener)
    document.addEventListener('keydown', tabListener)
    // Detach the listeners on component unmount.
    return () => {
      document.removeEventListener('click', clickListener)
      document.removeEventListener('keyup', escapeListener)
      document.removeEventListener('keydown', tabListener)
    }
  }, [])

  useEffect(() => {
    const temp = options.map((option) => ({ ...option, selected: option.selected || false, filtered: true }))
    const clean = options.filter((option) => option.selected).map((opt) => opt.value)
    setTempOptions(temp)
    setCleanOptions(clean)
  }, [options])

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest} ref={ref}>
      <div className={classNames(`${rootClass}__wrapper`)}>
        <div
          role={'listbox'}
          tabIndex={0}
          className={classNames(`${rootClass}__faux-select`, {
            [`${rootClass}__faux-select--active`]: isActive,
          })}
          data-test={`${dataTest}-select`}
          style={{ backgroundImage: `url(${caretDownSVG})` }}
          onKeyDown={(keyDownEvent) => {
            if (keyDownEvent.key === ' ' || keyDownEvent.key === 'Enter' || keyDownEvent.key === 'ArrowDown') {
              activateSelect()
            }
          }}
          onClick={activateSelect}
        >
          {cleanOptions && cleanOptions.length > 0 ? `${title} (${cleanOptions.length})` : `${t('All')} ${title}`}
        </div>
        <div
          className={classNames(`${rootClass}__dropdown-wrapper`, {
            [`${rootClass}__dropdown-wrapper--active`]: isActive,
          })}
        >
          <div className={classNames(`${rootClass}__search-wrapper`)}>
            <InputWithClear inputProps={inputProps} onClear={clearSearch} />
          </div>
          <div className={classNames(`${rootClass}__dropdown-container`)}>
            <div className={classNames(`${rootClass}__options-wrapper`)}>
              <div className={classNames(`${rootClass}__options`)}>
                {tempOptions.map((option) => {
                  if (option.filtered) {
                    return (
                      <Checkbox
                        key={option.value}
                        title={option.value}
                        label={option.label}
                        checked={option.selected}
                        className={`${rootClass}__checkbox`}
                        onChange={() => onToggleSelected(option.value)}
                      />
                    )
                  }
                })}
              </div>
            </div>
            <div className={classNames(`${rootClass}__button-wrapper`)}>
              <Button dataTest={`${dataTest}__apply`} fullWidth buttonType={ButtonType.PRIMARY} onClick={submitSelected}>
                {t('Apply')} {getTempSelected().length ? `(${getTempSelected().length})` : ''}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MultiSelect
