import React, { useEffect, useState } from 'react'

import { Status } from '@components/StatusToast/StatusToast'
import { useTranslation } from '@const/globals'
import getSubscriptionChildToParentCategories from '@graphql/microservices/dev-email-suppression/getSubscriptionChildToParentCategories'
import upsertSubscriptionChildToParentCategoryIdsMap from '@graphql/microservices/dev-email-suppression/upsertSubscriptionChildToParentCategoryIdsMap'
import { Query, QueryGetSubscriptionChildToParentCategoriesArgs } from '@graphql/types/microservice/dev-email-suppression-types'
import SubscriptionCategoryMappings, { Mapping, Subscription } from '@src/pages/SubscriptionCategoryMappings/SubscriptionCategoryMappings'
import { useAccountSettings } from '@utils/account/account.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'
import { logNewRelicError } from '@utils/new-relic.utils'

interface ToastState {
  toastMessage: string
  toastSuccess: boolean
  status?: Status
}

interface State {
  mapping: Mapping[]
  childCategories?: Subscription[]
  parentCategories?: Subscription[]
}

const defaultToastState: ToastState = {
  toastMessage: '',
  toastSuccess: false,
}

const defaultState: State = {
  mapping: [{ childCategoryId: 5, parentCategoryId: 5 }],
  childCategories: [],
  parentCategories: [],
}

const SubscriptionCategoryMappingsContainer = () => {
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.EMAIL_SUPPRESSION })
  const { accountId, parentId } = useAccountSettings()
  const [state, setState] = useState<State>(defaultState)
  const [toast, setToast] = useState<ToastState>(defaultToastState)
  const { t } = useTranslation()

  const { data } = useQueryOnMount<Query, QueryGetSubscriptionChildToParentCategoriesArgs>(getSubscriptionChildToParentCategories, {
    client,
    fetchPolicy: 'network-only',
    variables: {
      childAccountId: accountId,
      parentAccountId: parentId,
    },
  })

  const fetchData = () => {
    if (data && data.getSubscriptionChildToParentCategories) {
      const mapping =
        data.getSubscriptionChildToParentCategories.mapping?.map((child) => {
          return {
            childCategoryId: child?.childCategoryId,
            parentCategoryId: child?.parentCategoryId,
          }
        }) ?? []

      const transformData = (category: Subscription[]) => {
        return (
          category.map((cat) => {
            return {
              accountId: cat?.accountId,
              classicId: cat?.classicId,
              deleted: cat?.deleted,
              description: cat?.description,
              id: cat?.id,
              subscriptionCategory: cat?.subscriptionCategory,
            }
          }) ?? []
        )
      }

      const childCategories = transformData(data.getSubscriptionChildToParentCategories.childCategories as Subscription[])
      const parentCategories = transformData(data.getSubscriptionChildToParentCategories.parentCategories as Subscription[])
      const SelectCategoryOption = {
        accountId: -1,
        classicId: -1,
        deleted: false,
        description: 'Select Category',
        id: -1,
        subscriptionCategory: 'Select Category',
        isLinked: false,
        title: 'Select Category',
      }
      childCategories.unshift(SelectCategoryOption)

      setState({
        ...state,
        mapping,
        childCategories,
        parentCategories,
      })
    }
  }

  useEffect(() => {
    fetchData()
  }, [data])

  const updateLink = async (childCategoryId: number, parentCategoryId: number) => {
    const newItem = { parentCategoryId, childCategoryId }
    const selectedCategoryIsInMap = state.mapping.some((stateMap: Mapping) => {
      return stateMap.parentCategoryId === newItem.parentCategoryId || stateMap.childCategoryId === childCategoryId
    })

    const removeParentFromMap = state.mapping.filter((map) => {
      return parentCategoryId !== map.parentCategoryId
    })

    const dataToSend = selectedCategoryIsInMap ? [...removeParentFromMap] : [...removeParentFromMap, newItem]
    const { data } = await client.mutate({
      mutation: upsertSubscriptionChildToParentCategoryIdsMap,
      variables: {
        childAccountId: accountId,
        parentAccountId: parentId,
        mapping: dataToSend,
      },
    })

    if (data && data.upsertSubscriptionChildToParentCategoryIdsMap) {
      if (selectedCategoryIsInMap) {
        setState({ ...state, mapping: dataToSend })
        setToast({ ...toast, toastMessage: t('Your parent and child categories are unlinked'), toastSuccess: true, status: Status.WARNING })
      } else {
        setState({ ...state, mapping: dataToSend })
        setToast({ ...toast, toastMessage: t('Success! Your parent and child categories are linked'), toastSuccess: true, status: Status.SUCCESS })
      }
    } else {
      setToast({ ...state, toastMessage: t('We couldnt link your categories just now. Please try again.'), toastSuccess: false, status: Status.FAIL })
      logNewRelicError(data.errors)
    }
  }

  const clearToast = () => setToast({ ...toast, toastMessage: '' })

  return (
    <SubscriptionCategoryMappings
      parentCategories={state.parentCategories}
      childCategories={state.childCategories}
      mappings={state.mapping}
      onSubmit={updateLink}
      toastMessage={toast.toastMessage}
      toastSuccess={toast.toastSuccess}
      clearToast={clearToast}
      status={toast.status ?? Status.SUCCESS}
    />
  )
}

export default SubscriptionCategoryMappingsContainer
