import React, { useCallback, useContext, useMemo } from 'react'

import classNames from 'classnames'

import Container from '@components/Container'
import Spinner from '@components/Spinner/Spinner'
import Svg, { SvgNames } from '@components/Svg'
import { SvgColor, SvgType } from '@components/Svg/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { splitDynamicPlainTextParts, PlainTextPart } from '@src/pages/EmailComposer/utils/DisplayConditions.utils'
import { EmailComposerContext, EmailPreviewPlatform } from '@utils/composer/context/EmailComposer.context'

import './EmailComposerPreviewPlainText.css'

const rootClass = 'email-composer-preview-plain-text'

const EmailComposerPreviewPlainText = ({ isRotated }: { isRotated?: boolean }) => {
  const {
    values: {
      message,
      message: { customTextPart },
      preview: { dynamicContentList, loadingPlainText, isDarkMode, plainText, formattedPlainText, platform },
    },
  } = useContext(EmailComposerContext)

  const isMobile = platform === EmailPreviewPlatform.MOBILE

  const renderDynamicPlainTextBlock = useCallback(
    (plainText: PlainTextPart) => {
      return (
        <Container className={`${rootClass}__dynamic-text-block`} key={plainText.key}>
          <div className={`${rootClass}__dynamic-text-block__title`}>
            <Svg fill={isDarkMode ? SvgColor.WHITE : SvgColor.TEXT_TEAL} type={SvgType.LARGE_ICON} name={SvgNames.arrowDiverge} />
            <Typography
              text={plainText.dynamicContent?.label}
              lineHeight={LineHeight.MEDIUM}
              weight={TextWeight.MEDIUM}
              type={isDarkMode ? TextType.NORMAL_TEXT_WHITE_LARGE : TextType.NORMAL_TEXT_TEAL_LARGE}
            />
          </div>
          <div className={`${rootClass}__dynamic-text-block__content`}>{plainText.text}</div>
        </Container>
      )
    },
    [isDarkMode]
  )

  const getDynamicContentPreview = useCallback(
    (inputText: string) => {
      if (dynamicContentList.length) {
        return splitDynamicPlainTextParts(message, inputText, dynamicContentList).map((part) => {
          if (part.dynamicContent) {
            return renderDynamicPlainTextBlock(part)
          }
          return part.text === '' ? null : (
            <div key={part.key} className={`${rootClass}__static-text-block`}>
              {part.text}
            </div>
          )
        })
      }
      return inputText
    },
    [message, dynamicContentList, renderDynamicPlainTextBlock]
  )

  const plainTextDisplay = useMemo(() => {
    return customTextPart ? customTextPart : getDynamicContentPreview(formattedPlainText || plainText)
  }, [customTextPart, plainText, formattedPlainText, getDynamicContentPreview])

  return (
    <div
      data-test={rootClass}
      className={classNames(rootClass, {
        [`${rootClass}--dark-mode`]: isDarkMode,
        [`${rootClass}--mobile`]: isMobile,
        [`${rootClass}--mobile-rotated`]: isRotated,
      })}
    >
      {loadingPlainText ? <Spinner /> : <pre>{plainTextDisplay}</pre>}
    </div>
  )
}

export default EmailComposerPreviewPlainText
