import React, { FC } from 'react'

import FormRow from '@components/FormRow/FormRow'
import Input from '@components/Input/Input'
import Label from '@components/Label/Label'
import Select from '@components/Select/Select'
import { useTranslation } from '@const/globals'
import { ProgramWaitStepExt } from '@utils/program/program.constants'

import './waitStep.css'

const rootClass = 'wait-step'

interface Props {
  step: ProgramWaitStepExt
  register: any
  errors: any
  dataTest?: string
  updateWaitStep: (step: ProgramWaitStepExt) => void
}

export interface State {
  stepType: string | null
  scheduleType?: string
  scheduleInterval?: string
  time: string
  date: Date | null
}

const WaitStep: FC<Props> = (props: Props) => {
  const { step, register, errors, updateWaitStep } = props
  const { t } = useTranslation()

  const delayChange = (e: any) => {
    const { value } = e.target

    updateWaitStep({ ...step, delay: parseInt(value) })
  }

  return (
    <div className={`${rootClass}`}>
      <FormRow>
        <Label>{t('Wait For')}</Label>
        <div className={`${rootClass}__row`}>
          <Input defaultValue={step.delay ? `${step.delay}` : '0'} name="delay" register={register('delay')} onChange={delayChange} />
          <Select className={`${rootClass}__select-small`} name="delayUnit" defaultValue={step.delayUnit} register={register('delayUnit')}>
            <option value="minute">{t('Minutes')}</option>
            <option value="hour">{t('Hours')}</option>
            <option value="day">{t('Days')}</option>
            <option value="week">{t('Weeks')}</option>
          </Select>
        </div>
        {errors.delay && <span className="error">{t(errors.delay.message)}</span>}
        {errors.delayUnit && <span className="error">{t(errors.delayUnit.message)}</span>}
      </FormRow>
    </div>
  )
}

export default WaitStep
