import { useApolloClient } from '@apollo/client'
import getEmailTemplatePreview from '@graphql/queries/getEmailTemplatePreview'
import { GetEmailTemplatePreviewQuery, GetEmailTemplatePreviewQueryVariables } from '@graphql/types/query-types'
import { FetchPromise } from '@utils/types'

export interface EmailTemplatesPickerRequests {
  getItemPreviewRequest: (templateId: string) => FetchPromise<GetEmailTemplatePreviewQuery>
}

export const useEmailTemplatesPickerRequests = (): EmailTemplatesPickerRequests => {
  const actonClient = useApolloClient()

  const getItemPreviewRequest = async (templateId: string) =>
    await actonClient.query<GetEmailTemplatePreviewQuery, GetEmailTemplatePreviewQueryVariables>({
      query: getEmailTemplatePreview,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: { templateId },
    })

  return {
    getItemPreviewRequest,
  }
}
