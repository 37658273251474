import React, { FC, useState } from 'react'

import BackButton from '@components/BackButton/BackButton'
import Button, { ButtonType } from '@components/Button'
import Checkbox from '@components/Checkbox/Checkbox'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import TextBubble, { BackgroundColor } from '@components/TextBubble/TextBubble'
import Typography, { LineHeight, ModalBodyStyle, ModalHeaderListStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './WelcomeMessageModal.css'

type Props = {
  onClose: () => void
  onBack: () => void
  onSubmit: (sendInitialWelcomeMessage: boolean, sendOngoingWelcomeMessage: boolean) => void
  message: string
  dataTest?: string
}

interface State {
  sendInitialWelcomeMessage: boolean
  sendOngoingWelcomeMessage: boolean
}
const rootClass = 'welcome-message-modal'

const WelcomeMessageModal: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, onClose, onSubmit, onBack, message } = props

  const [state, setState] = useState<State>({
    sendInitialWelcomeMessage: true,
    sendOngoingWelcomeMessage: true,
  })
  const { t } = useTranslation()

  return (
    <Modal
      className={rootClass}
      isOpen
      dataTest={dataTest}
      header={
        <ModalHeader headerType={ModalHeaderType.Form}>
          <div className={`${rootClass}__header-title`}>
            <BackButton onClick={onBack} />
            <Typography text={t('Welcome Message')} {...ModalHeaderListStyle} inline />
          </div>
        </ModalHeader>
      }
    >
      <ModalBody>
        <div className={`${rootClass}__body`}>
          <div className={`${rootClass}__info-text`}>
            <Typography
              text={t(`When contacts opt-in, it's good to send a welcome message that confirms they have successfully opted in`)}
              {...ModalBodyStyle}
              inline
            />
            <div className={`${rootClass}__preview-label`}>
              <Typography
                className={`${rootClass}__preview-label__text`}
                text={t('Message Preview')}
                type={TextType.BODY_TEXT_SMALL_LIGHT}
                weight={TextWeight.BOLD}
                lineHeight={LineHeight.MEDIUM}
                inline
              />
            </div>
            <div className={`${rootClass}__message-bubble`}>
              <TextBubble key={'test'} text={message} backgroundColor={BackgroundColor.TEAL} dataTest={`${dataTest}__sms-text`} />
            </div>
            <div className={`${rootClass}__checkbox-wrapper`}>
              <div className={`${rootClass}__checkbox-wrapper-row`}>
                <div className={`${rootClass}__checkbox-wrapper-checkbox`}>
                  <Checkbox
                    label={t('Send welcome message to these contacts')}
                    name={'setSendWelcomeMessageNow'}
                    checked={state.sendInitialWelcomeMessage}
                    disabled={false}
                    onChange={() => setState({ ...state, sendInitialWelcomeMessage: !state.sendInitialWelcomeMessage })}
                  />
                </div>
              </div>
              <div className={`${rootClass}__checkbox-wrapper-second-row`}>
                <div className={`${rootClass}__checkbox-wrapper-checkbox`}>
                  <Checkbox
                    label={t('Send to contacts added from this list in the future')}
                    name={'setSendWelcomeMessageFuture'}
                    checked={state.sendOngoingWelcomeMessage}
                    disabled={false}
                    onChange={() => setState({ ...state, sendOngoingWelcomeMessage: !state.sendOngoingWelcomeMessage })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form}>
        <Button dataTest={`${dataTest}-no-button`} buttonType={ButtonType.TERTIARY} onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} onClick={() => onSubmit(state.sendInitialWelcomeMessage, state.sendOngoingWelcomeMessage)}>
          {t('Add List')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default WelcomeMessageModal
