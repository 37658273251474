import React, { FC, useMemo } from 'react'

import classNames from 'classnames'

import PreviewAssetModal from '@components/PreviewAssetModal/PreviewAssetModal'
import { legacyActonContext, useTranslation } from '@const/globals'
import { SubscriptionManagementDTO } from '@src/pages/SubscriptionManagement/SubcriptionManagement.constants'

import './PreviewVersionModal.css'

interface PreviewVersionModalProps {
  className?: string
  dataTest?: string
  onClose: () => void
  version?: SubscriptionManagementDTO
}

const rootClass = 'preview-version-modal'

const PreviewVersionModal: FC<PreviewVersionModalProps> = (props: PreviewVersionModalProps) => {
  const { dataTest = rootClass, onClose, version } = props

  const param = useMemo(() => {
    if (version?.default) {
      return ''
    }

    const versionValue = version?.version || version?.name
    return versionValue ? `&languageVersion=${versionValue}` : ''
  }, [version?.default, version?.version, version?.name])
  const { t } = useTranslation()
  return (
    <PreviewAssetModal
      dataTest={dataTest}
      className={classNames(rootClass)}
      isOpen={true}
      onClose={onClose}
      title={t('Preview')}
      useTitlePrefix={false}
      assetSrc={`${legacyActonContext}/optinout/preview.jsp?newPreview=1` + param}
    />
  )
}

export default PreviewVersionModal
