import React from 'react'

import { RenderCustomFiltersParams } from '@complex/ListingPage/Context/ListingPageCommon.context'
import Radio from '@components/Radio'
import RadioGroup from '@components/RadioGroup'
import { SvgNames } from '@components/Svg'
import { draftProgramsFilter, FilterTypes, pausedProgramsFilter, runningProgramsFilter } from '@utils/filter'

const rootClass = 'automated-program-picker-modal'

export const renderCustomFilters = (
  { activeFilter, menuActions, filterCounts, renderCustomFilterWithCount }: RenderCustomFiltersParams,
  t: Function
) => [
  {
    header: t('Program Status'),
    isOpen: true,
    icon: SvgNames.toggles,
    dataTest: 'menu-items',
    content: (
      <RadioGroup className={`${rootClass}__custom-filters`} verticalLayout>
        {renderCustomFilterWithCount(
          <Radio
            label={t(FilterTypes.RUNNING)}
            checked={activeFilter?.name === runningProgramsFilter.name}
            onChange={() => menuActions.clickCustomFilter(runningProgramsFilter)}
            onClick={() => menuActions.clickCustomFilter(runningProgramsFilter)}
            dataTest={`${rootClass}-running-filter`}
            boldOnChecked
          />,
          filterCounts[FilterTypes.RUNNING],
          FilterTypes.RUNNING
        )}
        {renderCustomFilterWithCount(
          <Radio
            label={t(FilterTypes.PAUSED)}
            checked={activeFilter?.name === pausedProgramsFilter.name}
            onChange={() => menuActions.clickCustomFilter(pausedProgramsFilter)}
            onClick={() => menuActions.clickCustomFilter(pausedProgramsFilter)}
            dataTest={`${rootClass}-paused-filter`}
            boldOnChecked
          />,
          filterCounts[FilterTypes.PAUSED],
          FilterTypes.PAUSED
        )}
        {renderCustomFilterWithCount(
          <Radio
            label={t(FilterTypes.DRAFT)}
            checked={activeFilter?.name === draftProgramsFilter.name}
            onChange={() => menuActions.clickCustomFilter(draftProgramsFilter)}
            onClick={() => menuActions.clickCustomFilter(draftProgramsFilter)}
            dataTest={`${rootClass}-draft-filter`}
            boldOnChecked
          />,
          filterCounts[FilterTypes.DRAFT],
          FilterTypes.DRAFT
        )}
      </RadioGroup>
    ),
  },
]
