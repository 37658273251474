import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { ExportDataEventObject } from 'highcharts'
import { cloneDeep, zip } from 'lodash'

import ColumnChart from '@components/ColumnChart/ColumnChart'
import { DateRangeValueType } from '@components/DateRangePicker/DateRangePicker'
import LineChart from '@components/LineChart/LineChart'
import SingleSelectDropdown from '@components/SingleSelectDropdown/SingleSelectDropdown'
import Toggle from '@components/Toggle'
import Typography from '@components/Typography/Typography'
import { exportingOptionsWithIcons } from '@const/Chart.constants'
import { useTranslation } from '@const/globals'
import { LeadsSectionData } from '@graphql/types/microservice/data-lake-query-types'
import { UserRevenueInput } from '@graphql/types/query-types'
import ChartSection from '@src/pages/reports/revenueImpactNew/components/ChartSection/ChartSection'
import {
  columnChartPlotOptions,
  defaultLegendOptions,
  defaultOptions,
  getXAxisCommonOptions,
  isOneDayRange,
  lineChartPlotOptions,
  xAxisOtherUIGroupingKey,
} from '@src/pages/reports/revenueImpactNew/components/ChartSection/ChartSection.utils'
import getNewLeadsFormattedData from '@src/pages/reports/revenueImpactNew/components/NewLeads/utils/newLeads.formattedData'

import { getNewLeadsOptions, NewLeadsOption } from './NewLeads.constants'
import { tooltip, yAxisLabels } from './utils/newLeads.chartUtils'
import { BEFORE_PRINT_SIZE } from '../../RevenueImpactNew.utils'

import './NewLeads.css'

export interface NewLeadsProps {
  loading: boolean
  onPreserveDataChange: (obj: Partial<UserRevenueInput>) => void
  data: LeadsSectionData
  range: DateRangeValueType
  isToggleOn?: boolean
  dataTest?: string
}
const defaultOption = NewLeadsOption.BY_SOURCE

const rootClass = 'new-leads'

const NewLeads: FC<NewLeadsProps> = (props) => {
  const { loading, onPreserveDataChange, data, range, isToggleOn, dataTest = rootClass } = props

  const { t } = useTranslation()

  const [selectedValue, setSelectedValue] = useState<NewLeadsOption>(defaultOption)
  const [showOther, setShowOther] = useState<boolean>(!!isToggleOn)
  const isColumnChart = selectedValue === NewLeadsOption.BY_SOURCE

  const { analyticsData, bySource, byDate, startTime, endTime } = useMemo(() => getNewLeadsFormattedData(data, range, t), [data, range])

  const Chart = isColumnChart ? ColumnChart : LineChart

  const otherCategories = useMemo(() => bySource?.categories?.slice(7) || [], [bySource])
  const otherData = useMemo(() => bySource.fields.map((field) => (field.data as number[]).slice(7)), [bySource])
  const hasOtherCategory = otherData[0]?.length > 1
  const showToggle = hasOtherCategory && isColumnChart

  const hideOverTime = isOneDayRange(startTime, endTime)

  const selectOptions = useMemo(() => getNewLeadsOptions(hideOverTime, t), [hideOverTime, t])

  useEffect(() => {
    if (hideOverTime) {
      setSelectedValue((cur) => (cur === NewLeadsOption.OVER_TIME ? NewLeadsOption.BY_SOURCE : cur))
    }
  }, [hideOverTime])

  const columnCategories = useMemo(
    () =>
      hasOtherCategory
        ? showOther
          ? [...(bySource?.categories?.slice(0, 7) || []), xAxisOtherUIGroupingKey]
          : bySource?.categories?.slice(0, 7)
        : bySource?.categories,
    [bySource, hasOtherCategory, showOther]
  )

  const columnFields = useMemo(
    () =>
      bySource.fields.map((field) => ({
        ...field,
        data: hasOtherCategory
          ? showOther
            ? [...(field.data as number[]).slice(0, 7), (field.data as number[]).slice(7).reduce((a, c) => a + c, 0)]
            : (field.data as number[]).slice(0, 7)
          : field.data,
      })),
    [bySource, hasOtherCategory, showOther]
  )

  const onSelectChange = useCallback((value?: NewLeadsOption) => {
    if (value) {
      setSelectedValue(value)
    }
  }, [])

  const onToggleChange = useCallback(
    (isOn: boolean) => {
      setShowOther(isOn)
      onPreserveDataChange({ newLeadShowOther: isOn })
    },
    [onPreserveDataChange]
  )

  const options = {
    ...defaultOptions,
    showTicksOnEmptyData: true,
    hideXAxisLine: true,
    isDateTime: !isColumnChart,
    fields: loading ? [] : isColumnChart ? columnFields : cloneDeep(byDate.fields),
    categories: isColumnChart ? columnCategories : undefined,
    plotOptions: isColumnChart ? columnChartPlotOptions() : lineChartPlotOptions,
    legendOptions: { ...defaultLegendOptions, x: showToggle ? 348 : 210 },
    tooltip: tooltip(startTime, endTime, isColumnChart, otherData),
    exportingOptions: { ...exportingOptionsWithIcons(t), chartOptions: { legend: { x: 6 } } },
    yAxisLabels,
    xAxisOptions: getXAxisCommonOptions({ missingLabelReplacer: t('No lead source'), isColumnChart, startTime, endTime }),
    events: {
      exportData: function (data: ExportDataEventObject) {
        if (showOther && hasOtherCategory) {
          data.dataRows.pop()
        }
        data.dataRows.push(...zip<any>(otherCategories, otherData[0]))
      },
      beforePrint: function (this: any) {
        this.exportSVGElements[0].box.hide()
        this.exportSVGElements[1].hide()
        this.setSize(BEFORE_PRINT_SIZE)
      },
      afterPrint: function (this: any) {
        this.exportSVGElements[0].box.show()
        this.exportSVGElements[1].show()
        this.setSize(null)
      },
    },
  }

  return (
    <ChartSection className={rootClass} title={t('New Leads')} analyticsData={analyticsData} loading={loading} dataTest={dataTest}>
      <Chart
        {...options}
        ExtraElement={
          <div className={`${rootClass}__actions`} data-test={`${dataTest}__actions`}>
            <SingleSelectDropdown
              options={selectOptions}
              defaultValue={selectedValue}
              onSubmit={onSelectChange}
              className={`${rootClass}__actions-select`}
              dataTest={`${dataTest}__actions-select`}
            />
            {showToggle && (
              <div className={`${rootClass}__actions-toggle`} data-test={`${dataTest}__actions-toggle`}>
                <Toggle isOn={showOther && hasOtherCategory} onToggle={onToggleChange} />
                <Typography text={t('Show Other')} inline />
              </div>
            )}
          </div>
        }
      />
    </ChartSection>
  )
}

export default NewLeads
