import { EmptyListingProps, EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { TableProps } from '@components/Table/Table'
import { SforceUserResponse } from '@graphql/types/query-types'

interface Props {
  t: (s: any, options?: any) => any
  refreshCRM: () => void
  users: SforceUserResponse[]
}

const getTableProps: (props: Props) => Partial<TableProps & EmptyListingProps> = ({ t, refreshCRM, users }) => {
  // Selecting by default Sales users
  const selectedRowIds: Record<string, boolean> = {}
  users.forEach((user, index) => {
    if (user.role === 'Sales') {
      selectedRowIds[index] = true
    }
  })
  return {
    size: EmptyListingSize.MEDIUM,
    hideIcon: false,
    headerAddMargin: true,
    useCheckboxes: true,
    useHeaderCheckbox: true,
    headline: t('Import.Sales.Users.Table.Empty.Headline'),
    text: t('Import.Sales.Users.Table.Empty.Text'),
    imgSrc: StaticImageNames.emptyContacts,
    buttonText: t('Import.Sales.Users.Table.Empty.Button.Text'),
    buttonOnClick: refreshCRM,
    initialState: {
      selectedRowIds,
    },
  }
}

export default getTableProps
