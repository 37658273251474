import React from 'react'

import classNames from 'classnames'
import { filesize } from 'filesize'
import { TFunction } from 'i18next'

import { AssetPickerTableType, DEFAULT_PICKER_COLUMN_SIZES } from '@complex/AssetPickerModal/Components/Table/AssetPickerTable.utils'
import { EmptyListingProps, EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import { renderBoldTextOnMatch, renderLocationBreadcrumbs } from '@components/FolderSearch/FolderSearch.utils'
import { getAssetPreviewMode } from '@components/PreviewAssetModal/PreviewAssetModal.utils'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import { renderRelativeDate } from '@components/Table/components/tableColumns'
import { RowAction } from '@components/TableV2/tableV2TS/interfaces'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import Tooltip from '@components/Tooltip/Tooltip'
import { CellContext } from '@tanstack/react-table'
import { getStandardFormattedDate, relativeDate } from '@utils/date'
import { allMediaFilter, FilterTypes } from '@utils/filter'
import { UpdateState } from '@utils/types'

import { MediaItem, MediaPickerModalState } from './MediaPickerModal.context'

const rootClass = 'media-picker-modal'

export const getMediaRowActions = (update: UpdateState<MediaPickerModalState>): RowAction<MediaItem>[] => [
  {
    label: `Preview`,
    icon: SvgNames.zoom,
    iconSize: SvgType.ICON,
    hidden: (row) => Array.isArray(row) || row.original.docType === 'URL',
    onClick: (row) => update({ previewItem: row.original }),
    disabled: (row) => getAssetPreviewMode(row?.original.linkUrl) === 'unsupported',
    tooltipMessage: (row) => (getAssetPreviewMode(row?.original.linkUrl) === 'unsupported' ? 'No preview available' : 'Preview'),
  },
  {
    label: `Open link`,
    icon: SvgNames.externalLink,
    iconSize: SvgType.ICON,
    hidden: (row) => Array.isArray(row) || row.original.docType !== 'URL',
    onClick: (row) => window.open(row.original.linkUrl),
  },
]

export const renderDefaultRelativeDateColumn = (
  tableType: AssetPickerTableType,
  header: string,
  accessorKey: string
): ColumnDefWithAdditionalProps<MediaItem, any> => ({
  header,
  accessorKey,
  textAlign: 'left',
  ...DEFAULT_PICKER_COLUMN_SIZES[tableType].date,
  cell: (cell: CellContext<MediaItem, Date>) =>
    cell.getValue() ? renderRelativeDate(relativeDate(cell.getValue(), true, false), getStandardFormattedDate(cell.getValue().valueOf())) : '',
})

const renderItemNameVal = (item: MediaItem, search: string) => {
  const itemName = item?.title ?? ''
  const svgName = item.docType === 'URL' ? SvgNames.url : SvgNames.blankMessage

  return (
    <div className={classNames(`${rootClass}__item-name`, 'ellip')}>
      <Svg name={svgName} type={SvgType.MEDIUM} fill={SvgColor.TEXT_GRAY} />
      <Tooltip
        ellipOnTrigger
        trigger={
          <div className={`${rootClass}item-name-typography`}>
            <div className={'ellip'}>{search ? renderBoldTextOnMatch(itemName, search) : itemName}</div>
          </div>
        }
      >
        {itemName}
      </Tooltip>
    </div>
  )
}

const fileSizeColumn: ColumnDefWithAdditionalProps<MediaItem, MediaItem['size']> = {
  header: 'File Size',
  accessorKey: 'size',
  textAlign: 'left',
  ...DEFAULT_PICKER_COLUMN_SIZES[AssetPickerTableType.DEFAULT].date,
  cell: (cell: CellContext<MediaItem, MediaItem['size']>) => {
    if (cell.row.original.docType === 'URL') {
      return '-'
    }
    const value = cell.getValue()
    const fileSize = filesize(Number(value), { precision: 3, output: 'array' })
    const size = Math.round(Number(fileSize[0]) * 100) / 100
    const unit = fileSize[1].toUpperCase()
    return `${size}${unit}`
  },
  sortingFn: (rowA, rowB) => Number(rowA.original.size) - Number(rowB.original.size),
}

const getNameColumn = (t: TFunction, search: string): ColumnDefWithAdditionalProps<MediaItem, any> => ({
  header: t('Media Title'),
  accessorKey: 'title',
  textAlign: 'left',
  cell: (cell: CellContext<MediaItem, string>) => renderItemNameVal(cell.row.original, search),
})

export const renderMediaTableColumns = (t: TFunction): ColumnDefWithAdditionalProps<MediaItem, any>[] => [
  getNameColumn(t, ''),
  fileSizeColumn,
  ...[renderDefaultRelativeDateColumn(AssetPickerTableType.DEFAULT, t('Date Created'), 'created')],
  ...[renderDefaultRelativeDateColumn(AssetPickerTableType.DEFAULT, t('Last Updated'), 'modified')],
]

export const renderMediaSearchTableColumns = (
  search: string,
  folders: FolderData[],
  t: TFunction
): ColumnDefWithAdditionalProps<MediaItem, any>[] => [
  getNameColumn(t, search),
  {
    ...DEFAULT_PICKER_COLUMN_SIZES[AssetPickerTableType.SEARCH].location,
    header: 'Location',
    accessorKey: 'folderId',
    textAlign: 'left',
    disableSorting: true,
    cell: (cell: CellContext<MediaItem, number>) => renderLocationBreadcrumbs(cell.getValue(), folders, allMediaFilter.name),
  },
  renderDefaultRelativeDateColumn(AssetPickerTableType.SEARCH, t('Last Updated'), 'modified'),
]

export const getMediaEmptyListingProps = (state: MediaPickerModalState, setAllMedia: () => void, t: TFunction): EmptyListingProps => {
  const listPage = 'Media'
  if (state.activeSubTypes.length) {
    return {
      imgSrc: StaticImageNames.emptyFilter,
      headline: `AssetPicker.Common.Table.EmptyListing.SubTypes.Headline`,
      text: `AssetPicker.${listPage}.Table.EmptyListing.SubTypes.Text`,
      size: EmptyListingSize.LARGE,
      buttonText: `AssetPicker.${listPage}.Table.EmptyListing.SubTypes.ButtonText`,
      buttonOnClick: () => setAllMedia(),
      withoutBorder: true,
      fullHeight: true,
    }
  } else if (state.searchText) {
    return {
      imgSrc: StaticImageNames.emptySearch,
      headline: t('No results found'),
      text: t('There were no results matching your search.'),
      size: EmptyListingSize.LARGE,
      withoutBorder: true,
      fullHeight: true,
    }
  } else if (state.activeFolder) {
    return {
      imgSrc: StaticImageNames.emptyFolder,
      headline: t('This folder is empty'),
      text: t(`You haven’t stored anything in <bold>{{activeFolderName}}</bold>`, {
        activeFolderName: state.folders.find((folder) => folder.id == state.activeFolder)?.name,
      }),
      size: EmptyListingSize.LARGE,
      withoutBorder: true,
      fullHeight: true,
    }
  } else if (state.activeFilter === FilterTypes.FAVORITES) {
    return {
      imgSrc: StaticImageNames.emptyFavorites,
      headline: t('You don’t have any favorites yet'),
      text: t('Add hearts to items that you want to easily find later.'),
      size: EmptyListingSize.LARGE,
      withoutBorder: true,
      fullHeight: true,
    }
  } else {
    return {
      imgSrc: StaticImageNames.emptyChart,
      headline: t('You haven’t created any of these yet'),
      text: t(`AssetPicker.${listPage}.Table.EmptyListing.Text`),
      size: EmptyListingSize.LARGE,
      withoutBorder: true,
      fullHeight: true,
    }
  }
}
