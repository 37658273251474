import { useApolloClient } from '@apollo/client'
import getCompanyInfo from '@graphql/queries/getCustomAccountCompanyInfo'
import loadOverviewPage from '@graphql/queries/loadOverviewPage'
import {
  GetCustomAccountCompanyInfoQuery,
  GetCustomAccountCompanyInfoQueryVariables,
  LoadOverviewPageQuery,
  OverviewResponse,
} from '@graphql/types/query-types'
import { FetchPromise } from '@utils/types'

export interface EventModalRequests {
  getCustomAccountCompanyInfo: () => FetchPromise<GetCustomAccountCompanyInfoQuery>
  getUserAddressInfo: () => FetchPromise<LoadOverviewPageQuery>
}

export const useEventModalRequests = (): EventModalRequests => {
  const client = useApolloClient()

  const getCustomAccountCompanyInfo = async () =>
    await client.query<GetCustomAccountCompanyInfoQuery, GetCustomAccountCompanyInfoQueryVariables>({
      query: getCompanyInfo,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
    })

  const getUserAddressInfo = async () =>
    await client.query<{ loadOverviewPage: OverviewResponse }>({
      query: loadOverviewPage,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    })

  return {
    getCustomAccountCompanyInfo,
    getUserAddressInfo,
  }
}
