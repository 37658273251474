import { Dispatch, SetStateAction } from 'react'

import { ApolloClient } from '@apollo/client'
import initUnifiedList from '@graphql/microservices/crm/initUnifiedList'
import lastSyncResult from '@graphql/microservices/crm/lastSyncResult'
import saveOptInOutSettings from '@graphql/microservices/crm/saveOptInOutSettings'
import setFieldsForEntityType from '@graphql/microservices/crm/setFieldsForEntityType'
import setSyncSchedule from '@graphql/microservices/crm/setSyncSchedule'
import saveCurrentFieldMappings from '@graphql/microservices/crm-entity-mapping/saveCurrentFieldMappings'
import unifiedListFieldMapping from '@graphql/microservices/list/unifiedListFieldMapping'
import {
  SaveCurrentFieldMappingsMutation,
  SaveCurrentFieldMappingsMutationVariables,
  StandardFieldToCrmFieldMapping,
} from '@graphql/types/microservice/crm-entity-mapping-types'
import {
  FieldsPerEntityInput,
  InitUnifiedListMutation,
  InitUnifiedListMutationVariables,
  LastSyncQuery,
  LastSyncQueryVariables,
  Mode,
  OptInOutSettingsBeanInput,
  SetFieldsForEntityTypeMutation,
  SetFieldsForEntityTypeMutationVariables,
  SetOptInOutMutation,
  SetOptInOutMutationVariables,
  SetSyncScheduleMutation,
  SetSyncScheduleMutationVariables,
} from '@graphql/types/microservice/crm-types'
import {
  UnifiedListFieldMappingInput,
  UnifiedListFieldMappingMutation,
  UnifiedListFieldMappingMutationVariables,
} from '@graphql/types/microservice/list-types'
import { FieldsByEntityType, LastSyncResult, ResultPerEntity, State } from '@src/pages/datamanagement/context/DataManagementContext'
import {
  CrmEntityType,
  FrequencyType,
  RowHistoryState,
  ScheduleAMPM,
  ScheduleType,
  SyncResult,
} from '@src/pages/datamanagement/utils/DataManagement.constants'

export const saveFieldMappings = async (
  containerValues: State,
  setContainerValues: Dispatch<SetStateAction<State>>,
  showActonContactControlledFeature: boolean,
  crmShortName?: string
): Promise<{ isComplete: boolean }> => {
  try {
    const {
      clients: { listSrvClient, crmSyncSrvClient },
      fieldMappings,
      rowHistoryStatus,
      saveUCLChanges,
    } = containerValues
    if (showActonContactControlledFeature && (saveUCLChanges || !crmShortName)) {
      // need to sanitize the field mappings to remove our internal numbering
      const unifiedListFieldMappingInput: UnifiedListFieldMappingInput[] = fieldMappings.map((mapping) => {
        if (rowHistoryStatus.get(mapping.columnIndex) === RowHistoryState.NEW) {
          const newMapping = { ...mapping }
          delete newMapping.columnIndex
          return newMapping
        } else {
          return mapping
        }
      })

      const { data } = await listSrvClient.mutate<UnifiedListFieldMappingMutation, UnifiedListFieldMappingMutationVariables>({
        mutation: unifiedListFieldMapping,
        variables: {
          unifiedListFieldMapping: unifiedListFieldMappingInput,
        },
      })

      const areFieldMappingsSaved = !!data?.unifiedListFieldMapping

      setContainerValues((state) => ({
        ...state,
        hasUpdates: false,
        mutationResultState: { ...state.mutationResultState, areFieldMappingsSaved },
        ...(areFieldMappingsSaved ? { fieldMappings: [] } : {}),
      }))

      // call initUnifiedList endpoint
      if (crmShortName) {
        await crmSyncSrvClient.mutate<InitUnifiedListMutation, InitUnifiedListMutationVariables>({
          mutation: initUnifiedList,
          variables: {
            connectedCrmName: crmShortName,
          },
        })
      }
    } else {
      setContainerValues((state) => ({
        ...state,
        hasUpdates: false,
        mutationResultState: { ...state.mutationResultState, areFieldMappingsSaved: true },
      }))
    }
  } catch (err) {
    alert(err)
    setContainerValues((state) => ({
      ...state,
      hasUpdates: false,
      mutationResultState: { ...state.mutationResultState, areFieldMappingsSaved: false },
    }))
  }
  return { isComplete: true }
}

export const saveOptInOut = async (
  containerValues: State,
  setContainerValues: Dispatch<SetStateAction<State>>,
  client: ApolloClient<any>
): Promise<{ isComplete: boolean }> => {
  try {
    const optInOutSettingsBeanInput: OptInOutSettingsBeanInput = {
      optInOutPullEnabled: containerValues.syncSchedule.pullOptOut,
      optInOutPushEnabled: containerValues.syncSchedule.pushOptOut,
      optInOutSyncSupported: containerValues.syncSchedule.isOptInOutSupported,
    }
    const data = await client.mutate<SetOptInOutMutation, SetOptInOutMutationVariables>({
      mutation: saveOptInOutSettings,
      variables: {
        optInOutSettingsBean: optInOutSettingsBeanInput,
      },
    })
    if (data && data.data?.setOptInOutSettings) {
      setContainerValues((state) => ({
        ...state,
        hasUpdates: false,
        mutationResultState: { ...state.mutationResultState, isOptInOutSaved: true },
      }))
    } else {
      setContainerValues((state) => ({
        ...state,
        hasUpdates: false,
        mutationResultState: { ...state.mutationResultState, isOptInOutSaved: false },
      }))
    }
  } catch {
    setContainerValues((state) => ({
      ...state,
      hasUpdates: false,
      mutationResultState: { ...state.mutationResultState, isOptInOutSaved: false },
    }))
  }
  return { isComplete: true }
}

export const saveSyncScheduleOptions = async (
  containerValues: State,
  setContainerValues: Dispatch<SetStateAction<State>>,
  client: ApolloClient<any>
): Promise<{ isComplete: boolean }> => {
  const {
    syncSchedule: { schedule, dayOfTheWeek, frequency, period, dayOfTheMonth, scheduledTime },
  } = containerValues
  try {
    const none = 'None' as Mode

    const getMode = (): Mode => {
      if (schedule === ScheduleType.SCHEDULE) {
        switch (frequency) {
          case FrequencyType.DAILY: {
            return 'Daily' as Mode
          }
          case FrequencyType.HOURLY: {
            return 'Hourly' as Mode
          }
          case FrequencyType.MONTHLY: {
            return 'Monthly' as Mode
          }
          case FrequencyType.WEEKLY: {
            return 'Weekly' as Mode
          }
        }
      }
      return none
    }

    const formatAMPM = (ampm: string): string => {
      return ampm == ScheduleAMPM.AM || ampm === '0' ? 'am' : 'pm'
    }

    const isAutomaticScheduleSelected = (): boolean => {
      return schedule === ScheduleType.AUTOMATIC
    }

    const setHourInterval = (): number => {
      const mode = getMode()
      return mode === 'Hourly' && !isAutomaticScheduleSelected() && period !== undefined ? parseInt(period) : 0
    }

    const setDayOfWeek = (): number => {
      const mode = getMode()
      return mode === 'Weekly' && !isAutomaticScheduleSelected() && dayOfTheWeek !== undefined ? parseInt(dayOfTheWeek) : 0
    }

    const setDayOfMonth = (): number => {
      const mode = getMode()
      return mode === 'Monthly' && !isAutomaticScheduleSelected() && dayOfTheMonth !== undefined ? parseInt(dayOfTheMonth) : 0
    }

    const data = await client.mutate<SetSyncScheduleMutation, SetSyncScheduleMutationVariables>({
      mutation: setSyncSchedule,
      variables: {
        setSyncSchedule: {
          mode: getMode(),
          hour: scheduledTime.hour,
          minute: scheduledTime.minute,
          ampm: scheduledTime.ampm !== undefined ? formatAMPM(scheduledTime.ampm) : 'am',
          hourInterval: setHourInterval(),
          dayOfWeek: setDayOfWeek(),
          dayOfMonth: setDayOfMonth(),
          automaticSchedule: isAutomaticScheduleSelected(),
        },
      },
    })
    if (data && data.data?.setSyncSchedule) {
      setContainerValues((state) => ({
        ...state,
        hasUpdates: false,
        mutationResultState: { ...state.mutationResultState, isSyncScheduleSaved: true },
      }))
    } else {
      setContainerValues((state) => ({
        ...state,
        hasUpdates: false,
        mutationResultState: { ...state.mutationResultState, isSyncScheduleSaved: false },
      }))
    }
  } catch {
    setContainerValues((state) => ({
      ...state,
      hasUpdates: false,
      mutationResultState: { ...state.mutationResultState, isSyncScheduleSaved: false },
    }))
  }
  return { isComplete: true }
}

export const saveEntityFields = async (
  fieldsByEntityType: FieldsByEntityType,
  containerValues: State,
  setContainerValues: Dispatch<SetStateAction<State>>,
  client: ApolloClient<any>
): Promise<{ entity?: string; isComplete: boolean }> => {
  const fieldsPerEntityInput: FieldsPerEntityInput = {
    entityType: fieldsByEntityType.entity,
    columns: fieldsByEntityType.columns,
  }

  if (
    fieldsPerEntityInput !== undefined &&
    fieldsPerEntityInput.columns != undefined &&
    fieldsPerEntityInput.entityType !== undefined &&
    fieldsPerEntityInput.columns.length > 0
  ) {
    const updateResultPerEntity = containerValues.mutationResultState.resultPerEntity

    try {
      const data = await client.mutate<SetFieldsForEntityTypeMutation, SetFieldsForEntityTypeMutationVariables>({
        mutation: setFieldsForEntityType,
        variables: {
          setFieldsForEntityType: fieldsPerEntityInput,
        },
      })
      if (data && data.data?.setFieldsForEntityType && data.data?.setFieldsForEntityType?.entityType) {
        const resultPerEntity: ResultPerEntity = {
          entityType: data.data?.setFieldsForEntityType.entityType,
          isSaved: true,
          saveError: false,
        }

        updateResultPerEntity.push(resultPerEntity)
        setContainerValues((state) => ({
          ...state,
          hasUpdates: false,
          mutationResultState: { ...state.mutationResultState, resultPerEntity: updateResultPerEntity },
        }))
      } else {
        const resultPerEntity: ResultPerEntity = {
          entityType: fieldsPerEntityInput.entityType,
          isSaved: false,
          saveError: false,
        }

        updateResultPerEntity.push(resultPerEntity)
        setContainerValues((state) => ({
          ...state,
          hasUpdates: false,
          mutationResultState: { ...state.mutationResultState, resultPerEntity: updateResultPerEntity },
        }))
      }
    } catch {
      const resultPerEntity: ResultPerEntity = {
        entityType: fieldsPerEntityInput.entityType,
        isSaved: false,
        saveError: true,
      }

      updateResultPerEntity.push(resultPerEntity)
      setContainerValues((state) => ({
        ...state,
        hasUpdates: false,
        mutationResultState: { ...state.mutationResultState, resultPerEntity: updateResultPerEntity },
      }))
    }
  }
  return { entity: fieldsPerEntityInput.entityType, isComplete: true }
}

export const saveReportMapping = async (
  containerValues: State,
  setContainerValues: Dispatch<SetStateAction<State>>,
  client: ApolloClient<any>
): Promise<{ isComplete: boolean }> => {
  try {
    const { saveReportMappingChanges, reportMappingsData } = containerValues
    if (saveReportMappingChanges) {
      const mappings: StandardFieldToCrmFieldMapping[] = []
      reportMappingsData.forEach((reportMapping) => {
        const mapping = reportMapping.mappingList
          .filter((filteredList) => !!filteredList?.selectedOption?.id)
          .map((list) => {
            return {
              standardField: { entityType: list.reportField.crmEntityType as CrmEntityType, fieldName: list.reportField.fieldName as string },
              mappedCrmField: { entityType: list.reportField.crmEntityType as CrmEntityType, fieldName: list?.selectedOption?.id as string },
            }
          })
        mappings.push(...mapping)
      })

      const data = await client.mutate<SaveCurrentFieldMappingsMutation, SaveCurrentFieldMappingsMutationVariables>({
        mutation: saveCurrentFieldMappings,
        variables: {
          mappings,
        },
      })

      if (data && data.data?.saveCurrentFieldMappings?.statusCode) {
        setContainerValues((state) => ({
          ...state,
          hasUpdates: false,
          mutationResultState: { ...state.mutationResultState, areReportMappingsSaved: true },
        }))
      } else {
        setContainerValues((state) => ({
          ...state,
          hasUpdates: false,
          mutationResultState: { ...state.mutationResultState, areReportMappingsSaved: false },
        }))
      }
    } else {
      setContainerValues((state) => ({
        ...state,
        hasUpdates: false,
        mutationResultState: { ...state.mutationResultState, areReportMappingsSaved: true },
      }))
    }
  } catch {
    setContainerValues((state) => ({
      ...state,
      hasUpdates: false,
      mutationResultState: { ...state.mutationResultState, areReportMappingsSaved: false },
    }))
  }
  return { isComplete: true }
}

export const buildLastSyncStatus = (lastSyncEndedEpoch: number, isSyncingNow: boolean, accountTimeZone: string): string => {
  if (!isSyncingNow) {
    const tz = accountTimeZone ?? ''
    if (lastSyncEndedEpoch !== undefined && lastSyncEndedEpoch !== null) {
      try {
        const time =
          tz !== ''
            ? new Date(lastSyncEndedEpoch).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timeZone: accountTimeZone })
            : new Date(lastSyncEndedEpoch).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

        const dt =
          tz !== ''
            ? new Date(lastSyncEndedEpoch).toLocaleDateString([], {
                weekday: 'short',
                month: 'short',
                day: '2-digit',
                year: 'numeric',
                timeZone: accountTimeZone,
              })
            : new Date(lastSyncEndedEpoch).toLocaleDateString([], {
                weekday: 'short',
                month: 'short',
                day: '2-digit',
                year: 'numeric',
              })

        const unformattedTz =
          tz !== '' ? new Date(lastSyncEndedEpoch).toLocaleTimeString([], { timeZoneName: 'short', timeZone: accountTimeZone }) : ''

        const formattedTz = getTzFormattedString(unformattedTz, tz)

        return isLongTimeZoneFormat(tz)
          ? `${dt} ${time} ${formattedTz}`.replace(new RegExp(',', 'g'), '')
          : `${dt} ${formattedTz}`.replace(new RegExp(',', 'g'), '')
      } catch (e) {
        const time = new Date(lastSyncEndedEpoch).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
        return new Date(lastSyncEndedEpoch).toLocaleDateString() + ' ' + time
      }
    } else {
      return 'Your {{connectedCrm}} data is not yet synchronized.'
    }
  } else {
    return ''
  }
}

const getTzFormattedString = (unformattedTz: string, tz: string) => {
  if (isLongTimeZoneFormat(tz)) {
    return tz
  } else {
    return unformattedTz
  }
}

const isLongTimeZoneFormat = (tz: string) => {
  const longTz: string[] = ['Etc/GMT+12', 'Israel', 'Iran', 'IST', 'PRC', 'Japan', 'Kwajalein']
  return longTz.includes(tz)
}

export const getLastSyncResultUtil = async (setContainerValues: Dispatch<SetStateAction<State>>, client: ApolloClient<any>) => {
  try {
    const data = await client.query<LastSyncQuery, LastSyncQueryVariables>({
      query: lastSyncResult,
      fetchPolicy: 'network-only',
    })

    if (data?.data?.lastSync) {
      const syncResult = data?.data?.lastSync?.syncResult
      const lastSyncResult = { syncResult, showWarning: syncResult === SyncResult.Failed }
      setContainerValues((state) => ({
        ...state,
        lastSyncResult: lastSyncResult as LastSyncResult,
      }))
    }
  } catch {
    setContainerValues((state) => ({ ...state, lastSyncResult: { syncResult: null, showWarning: false } }))
  }
}
