export const getImagePrimitiveTypeFormB64: (base64: string) => string | undefined = (base64) => {
  const regExMatch = /data:image\/(.*?);base64/.exec(base64)
  if (regExMatch) {
    if (['jpg', 'jpeg', 'gif', 'png'].includes(regExMatch[1])) {
      return regExMatch[1]
    }
    if (regExMatch[1] === 'svg+xml') {
      return 'svg'
    }
  }
}

export const requireHttps = new RegExp('^https://')

export const imageUrlIsValid = (url: string) => {
  return requireHttps.test(url)
}
