import React from 'react'

import { Subtract } from '@utils/types'

import { AssetPickerListingPageProps, AssetPickerSidebarProps } from './Context/AssetPicker.context'
import { ListingPageProps, SidebarProps, TableProps } from '../ListingPage/Context/ListingPageCommon.context'

export const unusedAssetPickerTableProps: TableProps = {
  hasAutoSelectedRows: false,
  hasExpander: false,
  columns: [],
  headerActionCustomProps: [],
  renderSearchColumns: () => [],
  listPage: '',
  rowActionCustomProps: [],
  onCustomTableAction: () => undefined,
}

export const unusedAssetPickerSidebarProps: Subtract<SidebarProps, AssetPickerSidebarProps> = { sidebarHeader: '' }

export const unusedAssetPickerListingPageProps: Subtract<ListingPageProps, AssetPickerListingPageProps> = {
  renderDataCards: () => <></>,
  pageHeaderProps: {
    pageTitle: '',
    renderPageHeaderContent: () => <></>,
  },
  tableProps: unusedAssetPickerTableProps,
  getCustomEmptyListingProps: () => ({}),
}
