import { gql } from '@apollo/client'

export default gql`
  query getCountForRecentFormSubmissions($folderSubTypes: [String], $days: Int, $subTypes: [String]) {
    getCountForRecentFormSubmissions(folderSubTypes: $folderSubTypes, days: $days, subTypes: $subTypes) {
      createdByMeCount
      favoritesCount
      folderSubType
      recentCount
      subType
    }
  }
`
