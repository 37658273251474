import { RibbonColor, RibbonMedals, Ribbons } from '@components/CarouselDataCard/CarouselDataCardUtils'
import { SvgNames } from '@components/Svg'

export const WEBINAR_EMPTY_STATE_LINK =
  'https://success.act-on.com/acton/attachment/9883/u-b3fb8db1-17f5-41c6-b977-8b9ef0b94844/0/-/-/-/-/In%20App%3A%20Webinars%20empty%20state%20Getting%20Started%20with%20Act%20On%20Webinars'
export const FORMS_EMPTY_STATE_LINK =
  'https://success.act-on.com/acton/attachment/9883/u-0f9560ae-7b19-4b60-8235-97ff9bb54f41/0/-/-/-/-/In%20App%3A%20%20Act-On%20Contacts%20Form%20Submissions'

export const contactRequestedFields = [
  'E-mail Address',
  'Act-On Primary Score',
  'Job Title',
  'Company',
  'Cell Phone',
  'Business Street',
  'First Name',
  'Last Name',
]

export interface SubmissionsOverViewsCard {
  submissions: number
  views: number
  loading: boolean
}

export interface ActiveFormsCard {
  totalSubmissions: number
  active: number
  loading: boolean
}

export interface TopPerformingFormsCard {
  loading: boolean
  cards: RibbonMedals[]
}

export const defaultSubmissionsOverViewsCardValues: SubmissionsOverViewsCard = {
  submissions: 0,
  views: 0,
  loading: true,
}

export const defaultActiveFormsCardValues: ActiveFormsCard = {
  totalSubmissions: 0,
  active: 0,
  loading: true,
}

export enum DATA_PERIOD {
  Week = 7,
  Quarter = 90,
  Half_Year = 180,
  Year = 365,
}

export const dateRangeDropdownText: { [index in DATA_PERIOD]: string } = {
  [DATA_PERIOD.Week]: 'Past 7 days',
  [DATA_PERIOD.Quarter]: 'Past 90 days',
  [DATA_PERIOD.Half_Year]: 'Past 6 months',
  [DATA_PERIOD.Year]: 'Past year (12 months)',
}

export const ribbonsData: {
  svg: SvgNames
  background: string
}[] = [
  { svg: SvgNames[Ribbons.First], background: RibbonColor.First },
  { svg: SvgNames[Ribbons.Second], background: RibbonColor.Second },
  { svg: SvgNames[Ribbons.Third], background: RibbonColor.Third },
  { svg: SvgNames[Ribbons.Fourth], background: RibbonColor.Fourth },
  { svg: SvgNames[Ribbons.Fifth], background: RibbonColor.Fifth },
]
