import { TFunction } from 'i18next'

import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { SegmentCopyLog, SegmentCopyResponseDto, SegmentDefinitionResponse } from '@graphql/types/microservice/segment-types'
import {
  CheckForUnmappedSegmentFieldsRequestParams,
  CheckForUnmappedSegmentFieldsRequestType,
  CopyClassicSegmentsRequestParams,
  CopyClassicSegmentsRequestType,
  DeleteClassicSegmentsRequestParams,
  DeleteClassicSegmentsRequestType,
  DeleteSegmentByCopyIdRequestParams,
  DeleteSegmentByCopyIdRequestType,
  GetCopySegmentCountRequestType,
  GetCopySegmentLogRequestParams,
  GetCopySegmentLogRequestType,
  GetSegmentDefinitionFieldsRequestParams,
  GetSegmentDefinitionFieldsRequestType,
  GetUnifiedListFieldsFromSourceRequestParams,
  GetUnifiedListFieldsFromSourceRequestType,
  MoveToTrashRequestParams,
  MoveToTrashRequestType,
} from '@src/pages/ContactSegments/components/CopySegments/GraphQL/CopySegmentsRequests.segment.graphQL'
import {
  buildSegmentIdFieldNamesMap,
  filterAndSortContactsFields,
  getDeletedContactsFields,
  showStatusToast,
} from '@src/pages/ContactSegments/components/CopySegments/GraphQL/utils/CopySegmentsRequests.helper'
import { getStandardFormattedDate } from '@utils/date'
import { logNewRelicError } from '@utils/new-relic.utils'

import { LogStatus, LogStatusValues, SegmentsCopiedHistoryLog, Update } from '../../context/CopySegments.context'

interface BaseParams {
  t: TFunction
  update: Update
}

interface CheckForUnmappedSegmentFieldsUtilsParams extends CheckForUnmappedSegmentFieldsRequestParams, BaseParams {
  checkForUnmappedSegmentFieldsRequest: CheckForUnmappedSegmentFieldsRequestType
}

export const checkForUnmappedSegmentFieldsUtils = async ({
  checkForUnmappedSegmentFieldsRequest,
  segmentIds,
  t,
  update,
}: CheckForUnmappedSegmentFieldsUtilsParams) => {
  try {
    const { data, errors } = await checkForUnmappedSegmentFieldsRequest({ segmentIds })
    if (!!data?.checkForUnmappedSegmentFields) {
      update({
        segmentsWithFilterExpressions: Object.entries(data.checkForUnmappedSegmentFields).map(([key, value]) => ({ segmentId: key, value: !!value })),
      })
    }
    if (errors) {
      logNewRelicError(errors)
      showStatusToast(update, t('Something went wrong on our end.'))
    }
  } catch (e) {
    logNewRelicError(e)
    showStatusToast(update, t('Something went wrong on our end.'))
  }
}

interface GetSegmentDefinitionFieldsUtilsParams extends GetSegmentDefinitionFieldsRequestParams, BaseParams {
  getSegmentDefinitionFieldsRequest: GetSegmentDefinitionFieldsRequestType
}

export const getSegmentDefinitionFieldsUtils = async ({
  getSegmentDefinitionFieldsRequest,
  segmentIds,
  t,
  update,
}: GetSegmentDefinitionFieldsUtilsParams) => {
  update({ loadingFields: true })
  try {
    const { data, errors } = await getSegmentDefinitionFieldsRequest({ segmentIds })
    if (!!data?.getSegmentDefinitionFields) {
      const marketingListFields: string[] = [
        ...new Set(Object.values(data.getSegmentDefinitionFields as SegmentDefinitionResponse[]).map(({ fieldName }) => fieldName as string)),
      ]
      const segmentNamesByField = data?.getSegmentDefinitionFields as SegmentDefinitionResponse[]
      const mappedFields = marketingListFields.map((marketingListField) => ({ marketingListField, isNew: false }))
      update({ segmentNamesByField, mappedFields, fieldNamesBySegment: buildSegmentIdFieldNamesMap(segmentNamesByField) })
    } else if (errors) {
      showStatusToast(update, t('Copy.Segments.Page.Request.Error.GetSegmentDefinitionFields'))
      logNewRelicError(errors)
    }
  } catch (e) {
    showStatusToast(update, t('Copy.Segments.Page.Request.Error.GetSegmentDefinitionFields'))
    logNewRelicError(e)
  }
  update({ loadingFields: false })
}

interface CopyClassicSegmentsUtilsParams extends CopyClassicSegmentsRequestParams, BaseParams {
  migrateClassicSegmentsRequest: CopyClassicSegmentsRequestType
}

export const migrateClassicSegmentsUtils = async ({
  migrateClassicSegmentsRequest,
  segmentMapping,
  type,
  username,
  update,
  t,
}: CopyClassicSegmentsUtilsParams): Promise<SegmentCopyResponseDto | undefined> => {
  try {
    const { data, errors } = await migrateClassicSegmentsRequest({ segmentMapping, type, username })
    if (data?.copyClassicSegment) {
      update({ segmentsCopied: data?.copyClassicSegment.newExternalIds })
      return data?.copyClassicSegment
    }
    if (errors) {
      showStatusToast(update, t('Copy.Segments.Page.Request.Error.MigrateClassicSegments'))
      logNewRelicError(errors)
    }
  } catch (e) {
    showStatusToast(update, t('Copy.Segments.Page.Request.Error.MigrateClassicSegments'))
    logNewRelicError(e)
  }
}

interface MoveToTrashUtilsParams extends MoveToTrashRequestParams, BaseParams {
  moveToTrashRequest: MoveToTrashRequestType
}

export const moveToTrashUtils = async ({ moveToTrashRequest, listIds, t, update }: MoveToTrashUtilsParams) => {
  try {
    const { errors } = await moveToTrashRequest({ listIds })
    if (errors) {
      logNewRelicError(errors)
      showStatusToast(update, t('Copy.Segments.Page.Request.Error.MoveToTrash'))
    }
  } catch (e) {
    logNewRelicError(e)
    showStatusToast(update, t('Copy.Segments.Page.Request.Error.MoveToTrash'))
  }
}

interface DeleteClassicSegmentsUtilsParams extends DeleteClassicSegmentsRequestParams, BaseParams {
  deleteClassicSegmentsRequest: DeleteClassicSegmentsRequestType
}

export const deleteClassicSegmentsUtils = async ({ deleteClassicSegmentsRequest, segmentIds, t, update }: DeleteClassicSegmentsUtilsParams) => {
  try {
    const { errors } = await deleteClassicSegmentsRequest({ segmentIds })
    if (errors) {
      logNewRelicError(errors)
      showStatusToast(update, t('Copy.Segments.Page.Request.Error.DeleteClassicSegments'))
    }
  } catch (e) {
    logNewRelicError(e)
    showStatusToast(update, t('Copy.Segments.Page.Request.Error.DeleteClassicSegments'))
  }
}

interface GetCopySegmentCountUtilsParams extends BaseParams {
  getCopySegmentCountRequest: GetCopySegmentCountRequestType
}

export const getCopySegmentCountUtils = async ({ getCopySegmentCountRequest, t, update }: GetCopySegmentCountUtilsParams) => {
  try {
    const { data, errors } = await getCopySegmentCountRequest()
    if (!!data?.getCopySegmentCount) {
      update({ copyCount: data.getCopySegmentCount })
    }
    if (errors) {
      logNewRelicError(errors)
      showStatusToast(update, t('ListPage.ContactSegments.CopySegments.Requests.Segment.GetCopySegmentsCount.Error'))
    }
  } catch (e) {
    logNewRelicError(e)
    showStatusToast(update, t('ListPage.ContactSegments.CopySegments.Requests.Segment.GetCopySegmentsCount.Error'))
  }
}

interface GetCopySegmentLogUtilsParams extends BaseParams, GetCopySegmentLogRequestParams {
  getCopySegmentLogRequest: GetCopySegmentLogRequestType
  timeZone: string
}

export const getCopySegmentLogUtils = async ({
  getCopySegmentLogRequest,
  t,
  timeZone,
  update,
  ...paginationParams
}: GetCopySegmentLogUtilsParams) => {
  update({ loadingHistoryLog: true })
  try {
    const { data, errors } = await getCopySegmentLogRequest({ ...paginationParams })
    if (!!data?.getCopySegmentLog) {
      const historyLog = data.getCopySegmentLog.map((log) => {
        const { copyId, dateTime, names, fails, success, inProgress, parent, status, user } = log as SegmentCopyLog
        return {
          copyId,
          date: getStandardFormattedDate(dateTime, false, timeZone),
          parent,
          segmentsInfo: `${names?.length}: ${names?.join(', ')}`,
          fails,
          success,
          inProgress,
          status: LogStatusValues[status as LogStatus],
          userName: user,
        }
      }) as SegmentsCopiedHistoryLog[]
      update({ historyLog })
    }
    if (errors) {
      logNewRelicError(errors)
      showStatusToast(update, t('Copy.Segments.Page.Request.Error.GetCopySegmentLog'))
    }
  } catch (e) {
    logNewRelicError(e)
    showStatusToast(update, t('Copy.Segments.Page.Request.Error.GetCopySegmentLog'))
  } finally {
    update({ loadingHistoryLog: false })
  }
}

interface DeleteSegmentByCopyIdUtilsParams extends DeleteSegmentByCopyIdRequestParams, BaseParams {
  deleteSegmentByCopyIdRequest: DeleteSegmentByCopyIdRequestType
}

export const deleteSegmentByCopyIdUtils = async ({ deleteSegmentByCopyIdRequest, copyId, t, update }: DeleteSegmentByCopyIdUtilsParams) => {
  try {
    const { data, errors } = await deleteSegmentByCopyIdRequest({ copyId })
    if (!!data?.deleteSegmentByCopyId) {
      return true
    }
    if (errors) {
      logNewRelicError(errors)
    }
    showStatusToast(update, t('Copy.Segments.Page.Request.Error.DeleteSegmentByCopyId'))
    return false
  } catch (e) {
    logNewRelicError(e)
    showStatusToast(update, t('Copy.Segments.Page.Request.Error.DeleteSegmentByCopyId'))
    return false
  }
}

interface GetUnifiedListFieldsFromSourceUtilsParams extends GetUnifiedListFieldsFromSourceRequestParams, BaseParams {
  getUnifiedListFieldsFromSourceRequest: GetUnifiedListFieldsFromSourceRequestType
}

export const getUnifiedListFieldsFromSourceUtils = async ({
  getUnifiedListFieldsFromSourceRequest,
  sourceId,
  t,
  update,
}: GetUnifiedListFieldsFromSourceUtilsParams) => {
  try {
    const { data, errors } = await getUnifiedListFieldsFromSourceRequest({ sourceId })
    if (!!data?.getUnifiedListFieldsFromSource) {
      const allContactsFields: UnifiedListFieldMapping[] = filterAndSortContactsFields(
        data.getUnifiedListFieldsFromSource as UnifiedListFieldMapping[]
      )
      const deletedContactsFields: UnifiedListFieldMapping[] = getDeletedContactsFields(
        data.getUnifiedListFieldsFromSource as UnifiedListFieldMapping[]
      )
      const allContactsFieldsOptions: SelectV2SingleOption[] = allContactsFields.map(({ displayName = '' }: UnifiedListFieldMapping) => ({
        label: displayName,
        value: displayName,
      }))
      update({ allContactsFields, allContactsFieldsOptions, deletedAllContactsFields: deletedContactsFields })
    } else if (errors) {
      showStatusToast(update, t('Copy.Segments.Page.Request.Error.GetUnifiedListFieldsFromSource'))
      logNewRelicError(errors)
    }
  } catch (e) {
    showStatusToast(update, t('Copy.Segments.Page.Request.Error.GetUnifiedListFieldsFromSource'))
    logNewRelicError(e)
  }
}
