import React, { FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Row } from 'react-table'

import classNames from 'classnames'

import { getSearchTableProps, getUCLSearchTableProps } from '@complex/ListPickerModalV2/components/SearchResults/utils/SearchResultsUtils'
import { columns } from '@complex/ListPickerModalV2/components/SegmentTreePage/utils/SegmentTreePage.utils'
import { PreProcessedList } from '@complex/ListPickerModalV2/utils/interfaces/ListPickerModalInterfaces'
import { ListPickerModalContext, unifiedListTypes } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import ActionableNestedTable from '@components/ActionableNestedTable/ActionableNestedTable'
import BackButton from '@components/BackButton/BackButton'
import Breadcrumbs, { BreadcrumbsItemType } from '@components/Breadcrumbs/Breadcrumbs'
import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import ScrollArea from '@components/ScrollArea/ScrollArea'
import { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ListCategory } from '@utils/lists'

import './SegmentTreePage.css'

interface SegmentTreePageProps {
  checkboxCellOverride?: (row: Row) => ReactNode
  onRowSelectionChanged: (selectedRows: Row[], rows?: Row[]) => void
}

const rootClass = 'segment-tree-page'

const SegmentTreePage: FC<SegmentTreePageProps> = ({ checkboxCellOverride, onRowSelectionChanged }: SegmentTreePageProps) => {
  const {
    values: { disableLegacyLists, disableUnifiedLists, disabledListTooltipText, multiSelect, segmentTreePage, selectedLists },
    values,
    setClassicSegmentChildrenTree,
    setClassicSegmentAncestorsTree,
    setSegmentAncestorsTree,
    setSegmentChildrenTree,
    setState,
  } = useContext(ListPickerModalContext)
  const { listType, segment, segmentAncestors, segmentChildren, segmentTreeLoading = true } = segmentTreePage || {}
  const isUCL = unifiedListTypes.includes(listType as ListCategory)
  const { categorizationId, id: segmentId } = segment || {}
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbsItemType[]>([])
  const { t } = useTranslation()

  useEffect(() => {
    if (!!categorizationId && !!listType && isUCL) {
      setSegmentAncestorsTree(categorizationId, listType)
      setSegmentChildrenTree(categorizationId, listType)
    }
  }, [categorizationId])

  useEffect(() => {
    if (!!segmentId && !!listType && !isUCL) {
      setClassicSegmentAncestorsTree(segmentId, listType)
      setClassicSegmentChildrenTree(segmentId, listType)
    }
  }, [segmentId])

  const setSegmentTreePage = (segment: PreProcessedList) =>
    setState((state) => ({
      ...state,
      segmentTreePage: { ...state.segmentTreePage, segment, segmentAncestors: undefined, segmentChildren: undefined },
    }))

  const buildBreadcrumbs: BreadcrumbsItemType[] = useMemo(
    () => [
      ...(segmentAncestors ?? []).map((ancestor, index) => {
        const isLast = !!segmentAncestors && segmentAncestors?.length - 1 === index
        return {
          className: 'ellip',
          customTypography: { type: isLast ? TextType.BODY_TEXT : TextType.BODY_TEXT_LIGHT, weight: isLast ? TextWeight.MEDIUM : TextWeight.REGULAR },
          hasTooltip: true,
          text: ancestor.name,
          inDropDown: (segmentAncestors ?? []).length > 4 && index > 0 && !isLast,
          onClick: () => (isLast ? undefined : setSegmentTreePage(ancestor)),
        }
      }),
    ],
    [segmentAncestors]
  )

  useEffect(() => {
    if (!!segmentAncestors?.length) {
      setBreadcrumbs(() => buildBreadcrumbs)
    }
  }, [segmentAncestors])

  const clearSegmentTreePage = useCallback(() => setState((state) => ({ ...state, segmentTreePage: undefined })), [])

  const mainTableProps = useCallback(() => {
    const params = {
      disableCheckboxHeader: disableLegacyLists || disableUnifiedLists,
      disabledListTooltipText: disableLegacyLists || disableUnifiedLists ? disabledListTooltipText : '',
      lists: segmentChildren ?? [],
      listType: listType as ListCategory,
      multiSelect,
      onRowSelectionChanged,
      values,
    }
    return {
      ...(unifiedListTypes.includes(listType as ListCategory) ? getUCLSearchTableProps(params) : getSearchTableProps(params)),
    }
  }, [segmentTreeLoading, selectedLists])

  const breadcrumbsOutsideDropDown = breadcrumbs.filter(({ inDropDown }) => !inDropDown).length
  const breadcrumbsInDropDown = breadcrumbs.filter(({ inDropDown }) => inDropDown).length

  return (
    <div className={rootClass}>
      <div className={`${rootClass}__ancestors-container`}>
        <Tooltip align={'center'} trigger={<BackButton className={`${rootClass}__back-button`} noXMargin onClick={clearSegmentTreePage} />}>
          {t('Back to search results')}
        </Tooltip>
        {segmentTreeLoading ? (
          <Loader className={`${rootClass}__loader-row`} loaderType={LoaderTypes.row} />
        ) : (
          <Breadcrumbs
            className={classNames(`${rootClass}__breadcrumbs`, {
              [`${rootClass}__breadcrumbs-one`]: breadcrumbsOutsideDropDown === 1,
              [`${rootClass}__breadcrumbs-two`]: breadcrumbsOutsideDropDown === 2 && breadcrumbsInDropDown === 0,
              [`${rootClass}__breadcrumbs-two-has-drop-down`]: breadcrumbsOutsideDropDown === 2 && breadcrumbsInDropDown > 0,
              [`${rootClass}__breadcrumbs-three`]: breadcrumbsOutsideDropDown === 3,
              [`${rootClass}__breadcrumbs-four`]: breadcrumbsOutsideDropDown === 4,
            })}
            breadcrumbs={breadcrumbs}
            caretSvgName={SvgNames.caretRight}
            caretSvgType={SvgType.ICON}
            dropDownPositionIndex={!!segmentAncestors && segmentAncestors?.length > 4 ? 1 : undefined}
          />
        )}
      </div>
      <ScrollArea className={`${rootClass}__scroll-area`}>
        {segmentTreeLoading ? (
          <div className={`${rootClass}__loader-container`}>
            <Loader className={`${rootClass}__loader`} center />
          </div>
        ) : (
          <ActionableNestedTable {...mainTableProps()} columns={columns} checkboxCellOverride={checkboxCellOverride} initiallyExpandAll fixedHeader />
        )}
      </ScrollArea>
    </div>
  )
}

export default SegmentTreePage
