import React, { FC } from 'react'

import { useTranslation } from '@const/globals'

import ConfirmationModal, { YesNo } from '../ConfirmationModal'

interface Props {
  isOpen: boolean
  openModal: (open: boolean) => void
  discard: (arr: string[][]) => void
  initialData: string[][]
  setDiscardAction: (action: string) => void
}

export const DiscardModalActions = {
  DISCARD: 'discard',
  CONTINUE: 'continue',
}

const DiscardModal: FC<Props> = ({ isOpen, openModal, discard, initialData, setDiscardAction }: Props) => {
  const { t } = useTranslation()

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title={t('Discard unsaved changes?')}
      body={t("If you discard now, you'll lose any changes you've made.")}
      isDelete
      cancelButtonText={t('Continue Editing')}
      deleteButtonText={t('Discard Changes')}
      onAnswer={(answer) => {
        if (answer === YesNo.YES) {
          discard(initialData)
          setDiscardAction(DiscardModalActions.DISCARD)
          openModal(false)
        } else {
          setDiscardAction(DiscardModalActions.CONTINUE)
          openModal(false)
        }
      }}
    />
  )
}

export default DiscardModal
