import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Caution from '@components/Caution/Caution'
import ContentWarning, { ItemAsset } from '@components/DependenciesContentModal/components/ContentWarning/ContentWarning'
import { useDependenciesContentRequests } from '@components/DependenciesContentModal/GraphQL/DependenciesContent.graphQL'
import {
  getListAssetsRequestUtils,
  getSegmentAssetsRequestUtils,
} from '@components/DependenciesContentModal/GraphQL/utils/DependenciesContent.graphQL.utils'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import { useTranslation } from '@const/globals'

import './DependenciesContentModal.css'

export interface DependenciesContentModalItem {
  id: string
  name: string
}

export enum DependenciesItemType {
  SEGMENT = 'SEGMENT',
  LIST = 'LIST',
}

interface DependenciesContentModalProps {
  customCautionMessage?: string
  customCautionInfoMessage?: ReactNode
  selectedItems: DependenciesContentModalItem[]
  itemType: DependenciesItemType
  onDelete: (items: DependenciesContentModalItem[]) => void
  onCancel: VoidFunction
  title?: string
  checkboxLabel?: string
  postDeleteAction?: (items?: DependenciesContentModalItem[]) => void
  className?: string
  dataTest?: string
}

export interface DependenciesContentModalState {
  itemsAssetToDelete: ItemAsset[]
  itemsToDelete: string[]
  loaded: boolean
}

const rootClass = 'dependencies-content-modal'

const DependenciesContentModal: FC<DependenciesContentModalProps> = (props: DependenciesContentModalProps) => {
  const {
    dataTest = rootClass,
    className = '',
    title = 'Dependent Content Warning',
    customCautionMessage,
    customCautionInfoMessage,
    checkboxLabel,
    selectedItems,
    itemType,
    onCancel,
    onDelete,
    postDeleteAction,
  } = props
  const { t } = useTranslation()

  const [state, setState] = useState<DependenciesContentModalState>({
    itemsAssetToDelete: [],
    itemsToDelete: [],
    loaded: false,
  })

  const { itemsToDelete, itemsAssetToDelete, loaded } = state

  const { getSegmentAssetsRequest, getListAssetsRequest } = useDependenciesContentRequests()

  useEffect(() => {
    const requests = {
      [DependenciesItemType.SEGMENT]: () => getSegmentAssetsRequestUtils({ getSegmentAssetsRequest, selectedItems, onDelete, setState }),
      [DependenciesItemType.LIST]: () => getListAssetsRequestUtils({ getListAssetsRequest, selectedItems, onDelete, setState }),
    }
    requests[itemType]()
  }, [])

  const renderDeleteModal = () => {
    if (itemsToDelete.length > 0) {
      const items = selectedItems.filter(({ id }) => itemsToDelete.includes(id))
      onDelete(items)
      postDeleteAction?.(items)
    } else {
      onCancel()
      postDeleteAction?.()
    }
  }

  const onDeleteChange = useCallback(
    (itemId: string, hasToDelete: boolean) => {
      if (hasToDelete) {
        setState({
          ...state,
          itemsToDelete: [...itemsToDelete, itemId],
        })
      } else {
        const index = itemsToDelete.findIndex((id) => id === itemId)
        setState({
          ...state,
          itemsToDelete: [...itemsToDelete.slice(0, index), ...itemsToDelete.slice(index + 1)],
        })
      }
    },
    [itemsToDelete]
  )

  const header = <ModalHeader headerType={ModalHeaderType.Form}>{t(title)}</ModalHeader>

  const renderContentWarnings = () =>
    itemsAssetToDelete.map((itemAsset) => {
      const item = selectedItems.find(({ id }) => id === itemAsset.objectId && itemAsset.body.length > 0)
      return (
        item && (
          <ContentWarning
            onDeleteChange={onDeleteChange}
            key={itemAsset.objectId}
            asset={itemAsset}
            title={item.name}
            customCautionInfoMessage={customCautionInfoMessage}
            checkboxLabel={checkboxLabel}
          />
        )
      )
    })

  if (!loaded) {
    return null
  }

  return (
    <Modal className={classNames(rootClass, className)} dataTest={dataTest} isOpen header={header}>
      <ModalBody>
        <div className={`${rootClass}__body-content`}>
          <Caution className={`${rootClass}__body-caution`} message={customCautionMessage ?? t('DEPENDENT_CONTENT_WARNING_MODAL.CAUTION')} />
          {renderContentWarnings()}
        </div>
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form}>
        <Button buttonType={ButtonType.TERTIARY} onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} onClick={() => renderDeleteModal()}>
          {t('Continue')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default DependenciesContentModal
