import React, { ReactNode } from 'react'

import { ListPageAPI, ListPageCommonState } from '@complex/ListingPage/Context/ListingPageCommon.context'
import CreateFromDraftModal from '@src/pages/listingPages/Drafts/components/CreateFromDraftModal/CreateFromDraftModal'
import { DraftsCustomTableActions } from '@src/pages/listingPages/Drafts/utils/DraftsListingPage.tables'
import { ItemType } from '@utils/categorization'

type ActionsWithModals = Exclude<DraftsCustomTableActions, DraftsCustomTableActions.EDIT>

export const renderCustomModal = (customTableAction: ActionsWithModals, listPageValues: ListPageCommonState, listPageAPI: ListPageAPI) => {
  const customModal: { [key in ActionsWithModals]: () => ReactNode } = {
    [DraftsCustomTableActions.CREATE_LANDING_PAGE]: () => (
      <CreateFromDraftModal listPageAPI={listPageAPI} listPageValues={listPageValues} itemType={ItemType.LANDING_PAGE} />
    ),
    [DraftsCustomTableActions.CREATE_TEMPLATE]: () => (
      <CreateFromDraftModal listPageAPI={listPageAPI} listPageValues={listPageValues} itemType={ItemType.EMAIL_TEMPLATE} />
    ),
  }

  if (!(customTableAction in customModal)) {
    return
  }

  return customModal[customTableAction]()
}
