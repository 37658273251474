import classNames from 'classnames'
import emojiRegex from 'emoji-regex'
import _ from 'lodash'

import { PersonalizationItem } from '@complex/Personalization/utils/Personalization.context'
import { PersonalizationFieldsErrorsForSources } from '@graphql/types/query-types'
import { getUUID } from '@utils/const/globals'
import {
  contactFieldsFilter,
  contentPersonalizationFieldsFilter,
  FilterTypes,
  listOrSegmentFieldsFilter,
  salesforceFieldsFilter,
  senderFieldsFilter,
  sourceListFieldsFilter,
} from '@utils/filter'
import { getEmojiCount } from '@utils/formUtils'
import {
  PersonalizationValidationType,
  getPersonalizationCommon,
  getPersonalizationValidationType,
  detectPersonalizationSyntaxes,
  isPersonalizationSyntax,
  getMappingNameAndFallback,
  PersonalizationSyntax,
  PersonalizationCommon,
} from '@utils/personalization'
import { BaseTagData, TagifyRuntimeSettings } from '@yaireo/tagify'

export const TAG_START_INTERPOLATION = '[['
export const TAG_END_INTERPOLATION = ']]'

export const PERSONALIZATION_TAG = /(\{\{=\{\{(.*?)\}\}\|(.*?)\}\})|(\{\{(.*?)\}\})/g
const TAGIFY_TAG = /\[\[([^\]]+?)\]\]/g
const NEWLINE = /\r|\n/g
const ZERO_WIDTH_SPACE = /\u200B/g
const SPACE = /\s/g

export interface PersonalizationTag extends PersonalizationCommon, BaseTagData {
  valid: boolean
}

export const isPersonalizationTag = (tagData: unknown): tagData is PersonalizationTag => {
  const fields: (keyof PersonalizationTag)[] = ['fieldId', 'displayText', 'syntax', 'valid', 'mappingName']
  return (
    tagData instanceof Object &&
    fields.every((key) => {
      if (key in tagData) {
        return tagData[key as keyof typeof tagData] !== undefined
      }
      return false
    })
  )
}

export const getPersonalizationCount = (text: string) => {
  return detectPersonalizationSyntaxes(text).length
}

export const getSanitizedInputLength = (inputValue: string, isEmoji?: boolean) => {
  const emojiCount = getEmojiCount(inputValue)
  const patterns = [...detectPersonalizationSyntaxes(inputValue), TAGIFY_TAG, ZERO_WIDTH_SPACE, NEWLINE, emojiRegex(), /\s/g]
  isEmoji && patterns.push(SPACE)
  let newValue = inputValue
  patterns.forEach((pattern) => {
    newValue = newValue.replace(pattern, '')
  })
  return emojiCount + newValue.length
}

export const getUniquePersonalizationValue = () => `personalization-${getUUID(true)}`

export const compareTagSyntaxToErrorField = (tagData: PersonalizationTag, error: PersonalizationFieldsErrorsForSources) => {
  const fullSyntax = isPersonalizationSyntax(tagData.syntax) ? tagData.syntax.slice(2, -2) : tagData.syntax
  const [syntax] = fullSyntax.trim().toLocaleLowerCase().split('|')
  const [errorField] = error.field.trim().toLocaleLowerCase().split('|')
  return syntax === errorField
}

export const tagifyPersonalizations = (text?: string, allPersonalizations?: PersonalizationItem[]) => {
  if (!text) {
    return
  }
  const getTagifiedReplacement = (personalizationText: string) => {
    const { mappingName, fallback } = getMappingNameAndFallback(personalizationText, allPersonalizations)

    const personalization: PersonalizationItem = allPersonalizations?.find((item) => {
      return item.mapping?.trim() === mappingName || (!item.mapping && item.title.trim() === mappingName)
    }) ?? {
      id: getUniquePersonalizationValue(),
      title: mappingName,
      mapping: mappingName,
      group: FilterTypes.CONTACT_FIELDS,
    }

    const data = getPersonalizationCommon({ ...personalization, fallbackText: fallback })

    const tagValue = getUniquePersonalizationValue()
    const tagData: PersonalizationTag = {
      ...data,
      value: tagValue,
      valid: true,
    }
    return `${TAG_START_INTERPOLATION}${JSON.stringify(tagData)}${TAG_END_INTERPOLATION}`
  }
  const syntaxes = detectPersonalizationSyntaxes(text)
  if (syntaxes.length) {
    const syntaxesPattern = syntaxes.map((text) => _.escapeRegExp(text)).join('|')
    return text.replace(new RegExp(syntaxesPattern, 'g'), (syntax) => getTagifiedReplacement(syntax as PersonalizationSyntax))
  }
  return text
}

export const untagifyPersonalizations = (tags: PersonalizationTag[], mixedTags: string) => {
  return tags
    .reduce(
      (text, tagData) => {
        const escapedSyntax = _.escapeRegExp(tagData.syntax)
        const tagifySyntax = new RegExp(`\\[\\[${escapedSyntax}\\]\\]`, 'g')
        return { value: text.value.replace(tagifySyntax, tagData.syntax ?? '') }
      },
      { value: mixedTags }
    )
    .value.trim()
    .replace(NEWLINE, '')
    .replace(ZERO_WIDTH_SPACE, '')
}

export const getPersonalizationSvg = <T extends Pick<PersonalizationCommon, 'group'>>(activeTag: T | undefined) => {
  switch (activeTag?.group) {
    case FilterTypes.SOURCE_LIST_FIELDS: {
      return sourceListFieldsFilter.svgUnselected
    }
    case FilterTypes.RECIPIENT_LIST_FIELDS: {
      return listOrSegmentFieldsFilter.svgUnselected
    }
    case FilterTypes.SENDER_FIELDS: {
      return senderFieldsFilter.svgUnselected
    }
    case FilterTypes.CUSTOM_ACCOUNT_FIELDS: {
      return contentPersonalizationFieldsFilter.svgUnselected
    }
    case FilterTypes.SALESFORCE_FIELDS: {
      return salesforceFieldsFilter.svgUnselected
    }
    case FilterTypes.CONTACT_FIELDS:
    default: {
      return contactFieldsFilter.svgUnselected
    }
  }
}

const errorIcon = `
<svg role="img" aria-label="error-solid" class="svg svg--icon" data-test="svg">
  <use xlink:href="#error-solid" />
</svg>`

const warningIcon = `
<svg role="img" aria-label="warning" class="svg svg--fill-white svg--icon" data-test="svg">
  <use xlink:href="#warning" />
</svg>`

export const tagValidationMessages: Record<PersonalizationValidationType, string> = {
  MALFORMED: 'This personalization is malformed',
  CRM: 'This CRM personalization is invalid or missing from currently synced CRM data',
  MISSING: 'This personalization does not exist',
  RECIPIENTS: 'This personalization is not available for all recipients',
}

export const getTagValidationMessage = (tagData: PersonalizationTag, personalizationErrors: PersonalizationFieldsErrorsForSources[] | undefined) => {
  const personalizationError = personalizationErrors?.find((e) => compareTagSyntaxToErrorField(tagData, e))
  if (personalizationError) {
    const type = getPersonalizationValidationType(personalizationError)
    if (type) {
      return tagValidationMessages[type]
    }
  }
  return ''
}

export const getTooltipText = (invalidReason: string, tagData: PersonalizationTag) => {
  if (invalidReason) {
    return invalidReason
  } else if (tagData.fallbackText) {
    return `<b>Fallback:</b> ${_.escape(tagData.fallbackText)}`
  } else if (!tagData.fallbackText) {
    return `<div class='tagify__tag-tooltip__text'>${warningIcon}No fallback set</div>`
  }
  return ''
}

export const getTagWrapper = (input: HTMLElement, settings: TagifyRuntimeSettings<PersonalizationTag>) => {
  const modeClass = !!settings.mode ? settings.classNames[`${settings.mode}Mode` as const] : undefined
  return `<tags id="tagify-input" class="${classNames(settings.classNames.namespace, modeClass, input.className)}"
              ${settings.readonly ? 'readonly' : ''}
              ${settings.disabled ? 'disabled' : ''}
              ${settings.required ? 'required' : ''}
              ${settings.mode === 'select' ? "spellcheck='false'" : ''}
              tabIndex="-1">
      <span contenteditable="true" tabIndex="0" aria-placeholder="${settings.placeholder || ''}"
          class="${settings.classNames.input}"
          role="textbox"
          aria-autocomplete="both"
          aria-multiline="${settings.mode == 'mix'}"></span>
  </tags>`
}

export const getAccountDateFieldText = (displayText: string) => {
  const rawText = displayText.replace(/\^/g, '').split('(')[0]
  return rawText.includes('WITHOFFSET')
    ? rawText.replace('.DATEWITHOFFSET', ' Date with Offset')
    : rawText.includes('.DATE')
    ? rawText.replace('.DATE', ' Date')
    : rawText
}

export const getTagTemplate = (
  tagify: Tagify<PersonalizationTag>,
  tagData: PersonalizationTag,
  personalizationErrors: PersonalizationFieldsErrorsForSources[] | undefined
) => {
  const invalidReason = getTagValidationMessage(tagData, personalizationErrors)
  const invalidClassName = invalidReason ? `tagify__tag-invalid` : undefined
  const containerClassName = classNames(tagify.settings.classNames.tag, invalidClassName)
  const iconClassNames = classNames('tagify__tag-icon', { ['tagify__tag-icon--visible']: invalidReason })

  const isAccountDateField = tagData.syntax.replace(/ /, '').replace(/\./, '').toLowerCase().includes('accountdate')
  const rawTagDisplayText = tagData[tagify.settings.tagTextProp] || tagData.value
  const tagDisplayText = isAccountDateField ? getAccountDateFieldText(`${rawTagDisplayText}`) : rawTagDisplayText

  const tagSpan = `<span contenteditable="false" class="${tagify.settings.classNames.tagText}">${_.escape(String(tagDisplayText))}</span>`
  const showTooltip =
    !isAccountDateField && (tagData.group ? ![FilterTypes.CUSTOM_ACCOUNT_FIELDS, FilterTypes.SENDER_FIELDS].includes(tagData.group) : true)
  const tooltip = showTooltip
    ? `<div class="${iconClassNames}">${errorIcon}</div>
       ${tagSpan}
       <div class="tagify__tag-tooltip">${getTooltipText(invalidReason, tagData)}</div>`
    : tagSpan

  return `<tag 
            tabIndex="0"
            id="${tagData.value}"
            contenteditable="false"
            class="${containerClassName}"
            ${tagify.getAttributes(tagData)}
          >
            <x class="${tagify.settings.classNames.tagX}" id="${tagData.value}" role='button' aria-label='remove tag' tabindex="0">
              <div class="tagify__tag-tooltip">Remove</div>
            </x>
            <div>${tooltip}</div>
          </tag>`
}

export const updateTagsStyle = (tagify: Tagify<PersonalizationTag>, personalizationErrors: PersonalizationFieldsErrorsForSources[] | undefined) => {
  // Manipulating the DOM directly will avoid the need to remount and interrupt the user's typing
  const tags = tagify.value
  const elements = tagify.getTagElms()

  const wasTagRemoved = tags.length !== elements.length
  if (tagify && !wasTagRemoved) {
    elements.forEach((element, index) => {
      const validationMessage = getTagValidationMessage(tags[index], personalizationErrors)
      const tooltip = element.querySelector('div > .tagify__tag-tooltip')
      const icon = element.querySelector('.tagify__tag-icon')
      if (!icon || !tooltip) {
        return
      }
      if (validationMessage) {
        element.classList.add('tagify__tag-invalid')
        icon.classList.add('tagify__tag-icon--visible')
      } else {
        element.classList.remove('tagify__tag-invalid')
        icon.classList.remove('tagify__tag-icon--visible')
      }
      tooltip.innerHTML = getTooltipText(validationMessage, tags[index])
      tagify.settings.transformTag(tags[index])
    })
  }
}
