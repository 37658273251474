import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import './sectionHeadline.css'

const rootClass = 'section-headline'

export enum SectionHeadlineType {
  REGULAR = 'regular',
  SMALL = 'small',
}

interface Props {
  children: ReactNode
  title?: string
  type?: SectionHeadlineType
  dataTest?: string
  className?: string
}

const SectionHeadline: FC<Props> = (props: Props) => {
  const { dataTest = 'section-headline', title, type, className } = props
  return (
    <h3
      data-test={dataTest}
      className={classNames(rootClass, className, {
        [`${rootClass}__small`]: type === SectionHeadlineType.SMALL,
      })}
      title={title}
    >
      {props.children}
    </h3>
  )
}

export default SectionHeadline
