import { ApolloClient } from '@apollo/client'
import getClickReportsByLaunchId from '@graphql/microservices/sms-reporting/getClickReportsByLaunchId'
import getClickReportsByMessageId from '@graphql/microservices/sms-reporting/getClickReportsByMessageId'
import getFailedReportsByLaunchId from '@graphql/microservices/sms-reporting/getFailedReportsByLaunchId'
import getFailedReportsByMessageId from '@graphql/microservices/sms-reporting/getFailedReportsByMessageId'
import getOptedOutReportsByLaunchId from '@graphql/microservices/sms-reporting/getOptedOutReportsByLaunchId'
import getOptedOutReportsByMessageId from '@graphql/microservices/sms-reporting/getOptedOutReportsByMessageId'
import getRepliesReportsByLaunchId from '@graphql/microservices/sms-reporting/getRepliesReportsByLaunchId'
import getRepliesReportsByMessageId from '@graphql/microservices/sms-reporting/getRepliesReportsByMessageId'
import getSentReportsByLaunchId from '@graphql/microservices/sms-reporting/getSentReportsByLaunchId'
import getSentReportsByMessageId from '@graphql/microservices/sms-reporting/getSentReportsByMessageId'
import {
  GetClickReportsByLaunchIdQuery,
  GetClickReportsByLaunchIdQueryVariables,
  GetClickReportsByMessageIdQuery,
  GetClickReportsByMessageIdQueryVariables,
  GetFailedReportsByLaunchIdQuery,
  GetFailedReportsByLaunchIdQueryVariables,
  GetFailedReportsByMessageIdQuery,
  GetFailedReportsByMessageIdQueryVariables,
  GetOptedOutReportsByLaunchIdQuery,
  GetOptedOutReportsByLaunchIdQueryVariables,
  GetOptedOutReportsByMessageIdQuery,
  GetOptedOutReportsByMessageIdQueryVariables,
  GetRepliesReportsByLaunchIdQuery,
  GetRepliesReportsByLaunchIdQueryVariables,
  GetRepliesReportsByMessageIdQuery,
  GetRepliesReportsByMessageIdQueryVariables,
  GetSentReportsByLaunchIdQuery,
  GetSentReportsByLaunchIdQueryVariables,
  GetSentReportsByMessageIdQuery,
  GetSentReportsByMessageIdQueryVariables,
} from '@graphql/types/microservice/sms-reporting-types'

export const getDeliveredData = (client: ApolloClient<any>, variables: any, isLaunch: boolean) => {
  if (isLaunch) {
    return client
      .query<GetSentReportsByLaunchIdQuery, GetSentReportsByLaunchIdQueryVariables>({
        query: getSentReportsByLaunchId,
        fetchPolicy: 'network-only',
        variables,
      })
      .then((data) => data.data.getSentReportsByLaunchId)
  } else {
    return client
      .query<GetSentReportsByMessageIdQuery, GetSentReportsByMessageIdQueryVariables>({
        query: getSentReportsByMessageId,
        fetchPolicy: 'network-only',
        variables,
      })
      .then((data) => data.data.getSentReportsByMessageId)
  }
}

export const getRepliedData = (client: ApolloClient<any>, variables: any, isLaunch: boolean) => {
  if (isLaunch) {
    return client
      .query<GetRepliesReportsByLaunchIdQuery, GetRepliesReportsByLaunchIdQueryVariables>({
        query: getRepliesReportsByLaunchId,
        fetchPolicy: 'network-only',
        variables,
      })
      .then((data) => data.data.getRepliesReportsByLaunchId)
  } else {
    return client
      .query<GetRepliesReportsByMessageIdQuery, GetRepliesReportsByMessageIdQueryVariables>({
        query: getRepliesReportsByMessageId,
        fetchPolicy: 'network-only',
        variables,
      })
      .then((data) => data.data.getRepliesReportsByMessageId)
  }
}

export const getClickedData = (client: ApolloClient<any>, variables: any, isLaunch: boolean) => {
  if (isLaunch) {
    return client
      .query<GetClickReportsByLaunchIdQuery, GetClickReportsByLaunchIdQueryVariables>({
        query: getClickReportsByLaunchId,
        fetchPolicy: 'network-only',
        variables,
      })
      .then((data) => data.data.getClickReportsByLaunchId)
  } else {
    return client
      .query<GetClickReportsByMessageIdQuery, GetClickReportsByMessageIdQueryVariables>({
        query: getClickReportsByMessageId,
        fetchPolicy: 'network-only',
        variables,
      })
      .then((data) => data.data.getClickReportsByMessageId)
  }
}

export const getOptedOutData = (client: ApolloClient<any>, variables: any, isLaunch: boolean) => {
  if (isLaunch) {
    return client
      .query<GetOptedOutReportsByLaunchIdQuery, GetOptedOutReportsByLaunchIdQueryVariables>({
        query: getOptedOutReportsByLaunchId,
        fetchPolicy: 'network-only',
        variables,
      })
      .then((data) => data.data.getOptedOutReportsByLaunchId)
  } else {
    return client
      .query<GetOptedOutReportsByMessageIdQuery, GetOptedOutReportsByMessageIdQueryVariables>({
        query: getOptedOutReportsByMessageId,
        fetchPolicy: 'network-only',
        variables,
      })
      .then((data) => data.data.getOptedOutReportsByMessageId)
  }
}

export const getFailedData = (client: ApolloClient<any>, variables: any, isLaunch: boolean) => {
  if (isLaunch) {
    return client
      .query<GetFailedReportsByLaunchIdQuery, GetFailedReportsByLaunchIdQueryVariables>({
        query: getFailedReportsByLaunchId,
        fetchPolicy: 'network-only',
        variables,
      })
      .then((data) => data.data.getFailedReportsByLaunchId)
  } else {
    return client
      .query<GetFailedReportsByMessageIdQuery, GetFailedReportsByMessageIdQueryVariables>({
        query: getFailedReportsByMessageId,
        fetchPolicy: 'network-only',
        variables,
      })
      .then((data) => data.data.getFailedReportsByMessageId)
  }
}
