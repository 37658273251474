import { Update } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { EmptyListingProps } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { FilterDefinition, favoriteFilter, allCustomDataFilter } from '@utils/filter'

export const getCustomEmptyListingProps = (
  setFilter: (filterDefinition: FilterDefinition, customFilterSelected: boolean) => void,
  _update: Update,
  filter?: FilterDefinition
): EmptyListingProps => {
  if (filter === favoriteFilter) {
    return {
      imgSrc: StaticImageNames.emptyFavorites,
      headline: "You don't have any favorites yet",
      text: 'Add hearts to custom data that you want to easily find later.',
      buttonText: 'View all custom data',
      buttonOnClick: () => setFilter(allCustomDataFilter, false),
    }
  } else {
    return {
      imgSrc: StaticImageNames.emptyChart,
      headline: 'You haven’t created any custom data yet',
      text: 'You’ll find custom data here once you’ve saved them.',
      buttonText: 'Something here',
      // eslint-disable-next-line no-console
      buttonOnClick: () => console.log('allItemFilter onClick function'),
    }
  }
}
