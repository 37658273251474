import React, { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'

import classNames from 'classnames'

import './button.css'

export const rootClass = 'button'

export enum ButtonType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERTIARY = 'TERTIARY',
  NAVIGATION_BUTTON = 'NAVIGATION_BUTTON',
  DELETE = 'DELETE',
  DELETE_OUTLINE = 'DELETE_OUTLINE',
  CANCEL_SEND = 'CANCEL_SEND',
  ERASE = 'ERASE',
  FLOAT = 'FLOAT',
  FLOAT_TEAL = 'FLOAT_TEAL',
  REMOVE = 'REMOVE',
  WHITE = 'WHITE',
  TABLE_ACTION = 'TABLE_ACTION',
  ACTIVE_FILTER = 'ACTIVE_FILTER',
  TEXT = 'TEXT',
  TEXT_TEAL = 'TEXT_TEAL',
  INFO = 'INFO', // Green on green
  HEADER = 'HEADER',
  TRANSPARENT = 'TRANSPARENT',
  ICON = 'ICON',
  DISMISS = 'DISMISS',
  DEFAULT = 'DEFAULT',
  EDIT = 'EDIT',
  PILL = 'PILL',
  DASHED_PILL = 'DASHED_PILL',
  OUTLINE = 'OUTLINE',
  TEXT_INSERT = 'TEXT_INSERT',
  FLOAT_GRAY = 'FLOAT_GRAY',
  FLOAT_YELLOW = 'FLOAT_YELLOW',
  FLOAT_DARK_BG = 'FLOAT_DARK_BG',
  PRIMARY_DARK_TEAL = 'PRIMARY_DARK_TEAL',
  PRIMARY_DARK_BLUE = 'PRIMARY_DARK_BLUE',
}

type TypeButton = 'button' | 'submit' | 'reset'

export enum ButtonIconPosition {
  LEFT,
  RIGHT,
  FLOAT,
}

export enum ButtonSize {
  REGULAR = 'REGULAR',
  LARGE = 'LARGE',
  MEDIUM = ' MEDIUM',
  SMALL = 'SMALL',
}

export enum ButtonWeight {
  REGULAR = 'REGULAR',
  MEDIUM = 'MEDIUM',
}

export enum ButtonTextAlign {
  LEFT = 'LEFT',
  CENTER = 'CENTER',
  RIGHT = 'RIGHT',
}

export interface ButtonProps {
  /** Which type of button to return */
  buttonType: ButtonType
  buttonSize?: ButtonSize
  weight?: ButtonWeight
  /** what type of button **/
  type?: TypeButton
  /** Is disabled? */
  disabled?: boolean
  iconPosition?: ButtonIconPosition
  title?: string
  buttonTextAlign?: ButtonTextAlign
  isLink?: boolean
  to?: string
  linkState?: Object
  target?: string
  noPadding?: boolean
  noTopBottomPadding?: boolean
  minimalPadding?: boolean
  clickStyled?: boolean
  render?: boolean
  cursorIsNotAllowed?: boolean
  register?: any
  fullWidth?: boolean
  autoFocus?: boolean
  children: ReactNode
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  onMouseDown?: (event: React.MouseEvent<HTMLButtonElement>) => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void
  inline?: boolean
  ariaLabel?: string
  dataTest?: string
  className?: string
  tabIndex?: number
  forceHover?: boolean
  dataProperties?: { [key: string]: string }
}

export const Button: FC<ButtonProps> = (props: ButtonProps) => {
  const {
    buttonType,
    buttonSize = ButtonSize.REGULAR,
    weight = ButtonWeight.REGULAR,
    disabled,
    onClick,
    onMouseDown,
    onKeyDown,
    dataTest = 'button',
    type = 'button',
    register,
    className,
    iconPosition,
    title,
    cursorIsNotAllowed,
    isLink = false,
    to,
    linkState,
    noPadding = false,
    noTopBottomPadding = false,
    minimalPadding = false,
    clickStyled,
    render = true,
    fullWidth,
    target,
    autoFocus,
    inline,
    // ariaLabel,
    buttonTextAlign,
    tabIndex,
    forceHover,
    dataProperties,
  } = props

  if (!render) return null

  const buttonClasses = classNames(rootClass, className, {
    [`${rootClass}--primary`]: buttonType === ButtonType.PRIMARY,
    [`${rootClass}--secondary`]: buttonType === ButtonType.SECONDARY,
    [`${rootClass}--navigation-button`]: buttonType === ButtonType.NAVIGATION_BUTTON,
    [`${rootClass}--tertiary`]: buttonType === ButtonType.TERTIARY,
    [`${rootClass}--delete`]: buttonType === ButtonType.DELETE,
    [`${rootClass}--cancel-send`]: buttonType === ButtonType.CANCEL_SEND,
    [`${rootClass}--erase`]: buttonType === ButtonType.ERASE,
    [`${rootClass}--primary ${rootClass}--primary-teal-medium`]: buttonType === ButtonType.PRIMARY_DARK_TEAL,
    [`${rootClass}--dark-blue ${rootClass}--dark-blue`]: buttonType === ButtonType.PRIMARY_DARK_BLUE,
    [`${rootClass}--float`]:
      buttonType === ButtonType.FLOAT ||
      buttonType === ButtonType.FLOAT_TEAL ||
      buttonType === ButtonType.FLOAT_YELLOW ||
      buttonType === ButtonType.FLOAT_DARK_BG,
    [`${rootClass}--float-teal`]: buttonType === ButtonType.FLOAT_TEAL,
    [`${rootClass}--float-yellow`]: buttonType === ButtonType.FLOAT_YELLOW,
    [`${rootClass}--float-dark-bg`]: buttonType === ButtonType.FLOAT_DARK_BG,
    [`${rootClass}--float-gray`]: buttonType === ButtonType.FLOAT_GRAY,
    [`${rootClass}--float-teal-clicked`]: buttonType === ButtonType.FLOAT_TEAL && clickStyled,
    [`${rootClass}--remove`]: buttonType === ButtonType.REMOVE,
    [`${rootClass}--white`]: buttonType === ButtonType.WHITE,
    [`${rootClass}--table-action`]: buttonType === ButtonType.TABLE_ACTION,
    [`${rootClass}--active-filter`]: buttonType === ButtonType.ACTIVE_FILTER,
    [`${rootClass}--text`]: buttonType === ButtonType.TEXT || buttonType === ButtonType.TEXT_TEAL,
    [`${rootClass}--text-teal`]: buttonType === ButtonType.TEXT_TEAL,
    [`${rootClass}--info`]: buttonType === ButtonType.INFO,
    [`${rootClass}--header`]: buttonType === ButtonType.HEADER,
    [`${rootClass}--transparent`]: buttonType === ButtonType.TRANSPARENT,
    [`${rootClass}--icon`]: buttonType === ButtonType.ICON,
    [`${rootClass}--dismiss`]: buttonType === ButtonType.DISMISS,
    [`${rootClass}--pill`]: buttonType === ButtonType.PILL,
    [`${rootClass}--dashed-pill`]: buttonType === ButtonType.DASHED_PILL,
    [`${rootClass}--outline`]: buttonType === ButtonType.OUTLINE,
    [`${rootClass}--delete-outline`]: buttonType === ButtonType.DELETE_OUTLINE,
    [`${rootClass}--icon-left`]: iconPosition === ButtonIconPosition.LEFT,
    [`${rootClass}--icon-right`]: iconPosition === ButtonIconPosition.RIGHT,
    [`${rootClass}--icon-float`]: iconPosition === ButtonIconPosition.FLOAT,
    [`${rootClass}--text-align-left`]: buttonTextAlign === ButtonTextAlign.LEFT,
    [`${rootClass}--text-align-center`]: buttonTextAlign === ButtonTextAlign.CENTER,
    [`${rootClass}--text-align-right`]: buttonTextAlign === ButtonTextAlign.RIGHT,
    [`${rootClass}--size-large`]: buttonSize === ButtonSize.LARGE,
    [`${rootClass}--size-medium`]: buttonSize === ButtonSize.MEDIUM,
    [`${rootClass}--size-small`]: buttonSize === ButtonSize.SMALL,
    [`${rootClass}--text-insert`]: buttonType === ButtonType.TEXT_INSERT,
    [`${rootClass}--weight-medium`]: weight === ButtonWeight.MEDIUM,
    [`${rootClass}--edit`]: buttonType === ButtonType.EDIT,
    [`${rootClass}--no-padding`]: noPadding,
    [`${rootClass}--no-top-bottom-padding`]: noTopBottomPadding,
    [`${rootClass}--minimal-padding`]: minimalPadding,
    [`${rootClass}--full-width`]: fullWidth,
    [`${rootClass}--inline`]: inline,
    [`${rootClass}--hover`]: forceHover,
    [`${rootClass}--cursor-not-allowed`]: cursorIsNotAllowed,
  })

  const linkTo = { pathname: to, state: linkState }

  if (isLink && to) {
    return (
      <Link ref={register} className={buttonClasses} to={linkTo} target={target} title={title} data-test={dataTest} tabIndex={0} role={'link'}>
        {props.children}
      </Link>
    )
  } else {
    return (
      <button
        type={type}
        /* eslint-disable-next-line jsx-a11y/no-autofocus */
        autoFocus={autoFocus}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onKeyDown={onKeyDown}
        data-test={dataTest}
        title={title}
        className={buttonClasses}
        disabled={disabled}
        ref={register}
        tabIndex={tabIndex ?? 0}
        // aria-label={ariaLabel ?? 'button'}
        {...dataProperties}
      >
        {props.children}
      </button>
    )
  }
}

export default Button
