import { ApolloClient } from '@apollo/client'
import { SelectAssetV2Props } from '@components/SelectAssetV2/SelectAssetV2'
import { OptInOutVisibility } from '@graphql/types/microservice/crm-types'
import { IMPORT_SOURCE } from '@src/pages/ContactSegments/utils/ContactSegments.constants'
import { RecordModalState, State, SyncSchedule } from '@src/pages/datamanagement/context/DataManagementContext'

export const NON_INITIALIZED_UCL_TOOLTIP = 'Please click the “Get Started” button to set up your fields'

export const getSelectAssetV2Props = (t: Function, update: Function): SelectAssetV2Props => {
  return {
    isOpen: true,
    headerText: t('SelectImportContactsSourceModal.Header'),
    subHeaderText: t('SelectImportContactsSourceModal.SubHeader'),
    rowItems: [
      {
        title: t('SelectImportContactsSourceModal.Local.Title'),
        description: t('SelectImportContactsSourceModal.Local.Description'),
        name: IMPORT_SOURCE.LOCAL,
      },
      {
        name: IMPORT_SOURCE.FTP,
        title: t('SelectImportContactsSourceModal.Ftp.Title'),
        description: t('SelectImportContactsSourceModal.Ftp.Description'),
      },
    ],
    onAction: (selectedItem) => update('importContactsSource', selectedItem),
    onCancel: () => update('selectingImportContactsSource', false),
    initialSelectedAsset: IMPORT_SOURCE.LOCAL,
  }
}

export enum RowHistoryState {
  NEW,
  PREEXISTING,
}

export enum MappingStatus {
  Valid = 'VALID',
  MissingCrmField = 'MISSING_CRM_FIELD',
}

export enum ColumnDataType {
  Primarykey = 'PRIMARYKEY',
  Reference = 'REFERENCE',
  String = 'STRING',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Boolean = 'BOOLEAN',
  Integer = 'INTEGER',
  Numeric = 'NUMERIC',
  Currency = 'CURRENCY',
  Percent = 'PERCENT',
  Phone = 'PHONE',
  Email = 'EMAIL',
  Url = 'URL',
  Picklist = 'PICKLIST',
  Muiltipicklist = 'MUILTIPICKLIST',
  Image = 'IMAGE',
  MultiString = 'MULTI_STRING',
  ReferenceType = 'REFERENCE_TYPE',
}

export enum CrmEntityType {
  Account = 'ACCOUNT',
  Campaign = 'CAMPAIGN',
  Contact = 'CONTACT',
  Deal = 'DEAL',
  Dealcontact = 'DEALCONTACT',
  Deallead = 'DEALLEAD',
  Group = 'GROUP',
  Lead = 'LEAD',
  Opportunity = 'OPPORTUNITY',
  Owner = 'OWNER',
}

export enum DataManagementTabs {
  CONTACT_MAPPING = 'contact-mapping',
  SYNC_SCHEDULE = 'sync-schedule',
  REPORT_MAPPING = 'report-mapping',
  CRM_ENTITIES = 'crm-entity',
}

export const FrequencyType = {
  HOURLY: 'Hourly',
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
}
export const syncRunningStatuses: string[] = ['Canceling', 'FetchingData', 'MergingData', 'OptOutPull', 'OptOutPush', 'Scheduled']

export const ScheduleType = {
  OFF: 'Off',
  AUTOMATIC: 'Automatic',
  SCHEDULE: 'Schedule',
}
export const ScheduleAMPM = {
  AM: 'AM',
  PM: 'PM',
}

export const SyncFilterType = {
  NO_FILTERS: 'No filters',
  CUSTOM_SEGMENT: 'Records from a custom segment',
}

export enum SyncResult {
  Cancelled = 'Cancelled',
  Failed = 'Failed',
  NotStarted = 'NotStarted',
  Started = 'Started',
  Succeeded = 'Succeeded',
  Unavailable = 'Unavailable',
}

export const dataManagementDefaultState: State = {
  currentTab: DataManagementTabs.CONTACT_MAPPING,
  loading: false,
  loadingULFMappings: true,
  errors: {},
  pageError: false,
  saved: false,
  syncSchedule: {
    frequency: FrequencyType.DAILY,
    period: '',
    scheduledTime: {
      hour: 0,
      minute: 0,
      ampm: ScheduleAMPM.AM,
    },
    schedule: ScheduleType.OFF,
    dayOfTheWeek: '',
    dayOfTheMonth: '',
    pullOptOut: false,
    pushOptOut: false,
    isOptInOutSupported: true,
    optInOutVisibility: {} as OptInOutVisibility,
  },
  originalSyncSchedule: {} as SyncSchedule,
  syncInfo: [],
  hasUpdates: false,
  entityTable: [],
  recordModal: {} as RecordModalState,
  openDiscardModal: false,
  token: '',
  accountContext: '',
  accountTimeZone: '',
  lastSync: 0,
  lastSyncResult: {
    syncResult: SyncResult.Unavailable,
    showWarning: false,
  },
  mutationResultState: {
    resultPerEntity: [],
    isOptInOutSaved: false,
    isSyncScheduleSaved: false,
    areFieldMappingsSaved: false,
    areReportMappingsSaved: false,
    attemptToSaveEntityChanges: [],
    attemptToSaveOptInOutChanges: false,
    attemptToSaveScheduleChanges: false,
    attemptToSaveFieldMappingChanges: false,
    attemptToSaveReportMappingsChanges: false,
  },
  runningSyncStatus: {
    isSyncingNow: false,
    syncState: '',
    isCanceling: false,
  },
  connectorType: '',
  scoreSheetsList: [],
  fieldMappings: [],
  fieldMappingStatus: new Map(),
  standardFieldsMap: new Map(),
  rowHistoryStatus: new Map(),
  crmContactData: {
    done: false,
    standardRows: [],
    customRows: [],
    stdFields: [],
    dataAvailable: false,
  },
  nonCrmContactsData: {
    standardRows: [],
    customRows: [],
    index: 0,
  },
  showCrmContactValidationToast: { open: false, text: '' },
  clients: {
    listSrvClient: {} as ApolloClient<any>,
    crmSyncSrvClient: {} as ApolloClient<any>,
    hotProspectsClient: {} as ApolloClient<any>,
    crmEntityMappingClient: {} as ApolloClient<any>,
  },
  saveUCLChanges: false,
  reportMappingsData: [],
  saveReportMappingChanges: false,
  fetchNonCRMData: false,
  unifiedListFieldMappings: [],
  selectingImportContactsSource: false,
  importContactsSource: '',
}
