import { gql } from '@apollo/client'

export default gql`
  query getAllAvailableFields {
    getAllAvailableFields {
      crmEntityType
      crmField {
        crmEntityType
        displayName
        fieldName
        dataType
      }
    }
  }
`
