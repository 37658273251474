import { gql } from '@apollo/client'

export default gql`
  mutation manageAddress($address: AddressRequest!, $addressType: String!, $optionType: String!, $UUID: String) {
    manageAddress(address: $address, addressType: $addressType, optionType: $optionType, UUID: $UUID) {
      statusCode
      reasonPhrase
    }
  }
`
