import { IEntityContentJsonExtended } from '@utils/composer/beeEditor/beeEditorTypes'

export const mockLandingPageBeeTemplate: IEntityContentJsonExtended = {
  page: {
    body: {
      container: { style: { 'background-color': '#ffffff' } },
      content: {
        // @ts-expect-error
        computedStyle: { linkColor: '#0068a5', messageBackgroundColor: 'transparent', messageWidth: '600px' },
        style: { color: '#000000', 'font-family': 'Arial, Helvetica Neue, Helvetica, sans-serif' },
      },
      type: 'mailup-bee-page-properties',
      webFonts: [],
    },
    description: '',
    rows: [
      {
        container: {
          style: {
            'background-color': 'transparent',
            'background-image': 'none',
            'background-repeat': 'no-repeat',
            'background-position': 'top left',
          },
        },
        content: {
          style: {
            'background-color': 'transparent',
            color: '#000000',
            width: '500px',
            'background-image': 'none',
            'background-repeat': 'no-repeat',
            'background-position': 'top left',
            'border-top': '0px solid transparent',
            'border-right': '0px solid transparent',
            'border-bottom': '0px solid transparent',
            'border-left': '0px solid transparent',
            'border-radius': '0px',
          },
          computedStyle: {
            rowColStackOnMobile: true,
            rowReverseColStackOnMobile: false,
            // @ts-expect-error
            verticalAlign: 'top',
            hideContentOnMobile: false,
            hideContentOnDesktop: false,
          },
        },
        columns: [
          {
            style: {
              'background-color': 'transparent',
              'border-bottom': '0px solid transparent',
              'border-left': '0px solid transparent',
              'border-right': '0px solid transparent',
              'border-top': '0px solid transparent',
              'padding-bottom': '5px',
              'padding-left': '0px',
              'padding-right': '0px',
              'padding-top': '5px',
            },
            modules: [],
            'grid-columns': 12,
            uuid: 'af91a689-8833-471c-926b-b6dacb9cb711',
          },
        ],
        type: 'one-column-empty',
        uuid: '8e4f4f87-d906-4a47-b6fb-696249d80497',
      },
    ],
    template: { name: 'template-base', type: 'basic', version: '2.0.0' },
    title: '',
  },
  comments: {},
}

export const mockLandingPageBeeTemplateStory = {
  page: {
    body: {
      container: { style: { 'background-color': '#ffffff' } },
      content: {
        computedStyle: { linkColor: '#0068a5', messageBackgroundColor: 'transparent', messageWidth: '600px' },
        style: { color: '#000000', 'font-family': 'Arial, Helvetica Neue, Helvetica, sans-serif' },
      },
      type: 'mailup-bee-page-properties',
      webFonts: [],
    },
    description: '',
    rows: [
      {
        container: {
          style: {
            'background-color': 'transparent',
            'background-image': 'none',
            'background-repeat': 'no-repeat',
            'background-position': 'top left',
          },
        },
        content: {
          style: {
            'background-color': 'transparent',
            color: '#000000',
            width: '800px',
            'background-image': 'none',
            'background-repeat': 'no-repeat',
            'background-position': 'top left',
            'border-top': '0px solid transparent',
            'border-right': '0px solid transparent',
            'border-bottom': '0px solid transparent',
            'border-left': '0px solid transparent',
            'border-radius': '0px',
            'padding-top': '0px',
            'padding-right': '0px',
            'padding-bottom': '0px',
            'padding-left': '0px',
          },
          mobileStyle: {},
          computedStyle: {
            rowColStackOnMobile: true,
            rowReverseColStackOnMobile: false,
            verticalAlign: 'top',
            hideContentOnMobile: false,
            hideContentOnDesktop: false,
          },
        },
        columns: [
          {
            'grid-columns': 12,
            modules: [
              {
                type: 'mailup-bee-newsletter-modules-heading',
                descriptor: {
                  heading: {
                    title: 'h1',
                    text: '<span class="tinyMce-placeholder">Add title text</span>',
                    style: {
                      color: '#222222',
                      'font-size': '24px',
                      'font-family': 'inherit',
                      'link-color': '#0068a5',
                      'line-height': '120%',
                      'text-align': 'left',
                      direction: 'ltr',
                      'font-weight': '700',
                      'letter-spacing': '0px',
                    },
                  },
                  style: {
                    width: '100%',
                    'text-align': 'center',
                    'padding-top': '10px',
                    'padding-right': '10px',
                    'padding-bottom': '10px',
                    'padding-left': '10px',
                  },
                  mobileStyle: {},
                },
                uuid: '96b132a7-3e54-4f37-b80b-6f4ad5185fac',
              },
            ],
            style: {
              'background-color': 'transparent',
              'padding-top': '5px',
              'padding-right': '0px',
              'padding-bottom': '5px',
              'padding-left': '0px',
              'border-top': '0px solid transparent',
              'border-right': '0px solid transparent',
              'border-bottom': '0px solid transparent',
              'border-left': '0px solid transparent',
            },
            uuid: '94caac99-1b75-46a8-9c28-d17a68b8e174',
          },
        ],
        uuid: '74ba1af1-a74d-403f-82c1-38700eb673c2',
      },
    ],
    template: { name: 'template-base', type: 'basic', version: '2.0.0' },
    title: '',
    deletedRows: [],
  },
  comments: {},
}
