import { gql } from '@apollo/client'

export default gql`
  mutation refreshBounceRecordCount($verbs: [String]) {
    refreshBounceRecordCount(verbs: $verbs) {
      body
      message
      objectId
      statusCode
    }
  }
`
