export enum ExportFirstLineOption {
  NAMES = 'NAMES',
  VALUES = 'VALUES',
}

export enum Delimiter {
  Comma = 'COMMA',
  SemiColon = 'SEMI_COLON',
  Tab = 'TAB',
}

export enum Quote {
  Quote = 'QUOTE',
  SimpleQuote = 'SIMPLE_QUOTE',
}

export const delimiterSymbols: { [key: string]: string } = {
  [Delimiter.Tab]: 'Tab',
  [Delimiter.Comma]: ',',
  [Delimiter.SemiColon]: ';',
}

export const exportOptionsText: { [key: string]: string } = {
  [ExportFirstLineOption.NAMES]: 'ExportContacts.Sections.ExportOptions.Fields.Names',
  [ExportFirstLineOption.VALUES]: 'ExportContacts.Sections.ExportOptions.Fields.Values',
  [Delimiter.Comma]: 'Commas',
  [Delimiter.Tab]: 'Tabs',
  [Delimiter.SemiColon]: 'Semi-colons',
  [Quote.SimpleQuote]: 'Single-quoted',
  [Quote.Quote]: 'Double-quoted',
}

export const exportOptionsDefaultValues = {
  schedule: {
    ampm: 1,
    hour: 10,
    interval: 6,
    minute: 0,
    type: 'WEEKLY',
    weekdaysOnly: false,
    hasEndTime: false,
    isScheduled: true,
    isUnscheduled: false,
  },
}
