import React, { FC, useCallback, useState, useMemo, useEffect, Dispatch, SetStateAction } from 'react'

import classNames from 'classnames'
import _ from 'lodash'

import BackButton from '@components/BackButton/BackButton'
import Button, { ButtonType } from '@components/Button/index'
import { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import LazyLoadOnScrollContainer from '@components/LazyLoadOnScrollContainer/LazyLoadOnScrollContainer'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal/index'
import Search, { SearchType } from '@components/Search/Search'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { TableV2 } from '@components/TableV2/TableV2'
import Typography, { ModalBodyStyle, ModalHeaderStyle, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import {
  getDefaultSelectedRows,
  sendDetailsSelectContactsModalColumns,
} from '@src/pages/EmailComposer/components/SendDetails/components/SendDetailsSelectContactsModal/SendDetailsSelectContactsModal.utils'
import { MessageRecipient } from '@src/pages/EmailComposer/utils/EmailComposer.types'
import { Row, RowSelectionState } from '@tanstack/react-table'

import './SendDetailsSelectContactsModal.css'

interface SendDetailsSelectContactsModalProps {
  className?: string
  dataTest?: string
  onClose: () => void
  onAction: (selectedRecipients: MessageRecipient[]) => void
  handleBack: () => void
  isOpen: boolean
  listName: string
  listContacts: MessageRecipient[]
  selectedRecipients: MessageRecipient[]
  updateChoosedContacts?: (updatedContacts: MessageRecipient[]) => void
  chosenContacts?: MessageRecipient[]
  handleLoadNext: () => Promise<any>
  allLoaded?: boolean
  loadingNextData?: boolean
  saveScrollSessionKey?: string
  setSearchTerm?: Dispatch<SetStateAction<string>>
  searchTerm?: string
}

interface SendDetailsSelectContactsModalState {
  selectedContacts: MessageRecipient[]
  visibleContacts: MessageRecipient[]
  canSubmit: boolean
  rowSelections: RowSelectionState
}

const rootClass = 'send-details-select-contacts-modal'

const SendDetailsSelectContactsModal: FC<SendDetailsSelectContactsModalProps> = (props: SendDetailsSelectContactsModalProps) => {
  const {
    dataTest = rootClass,
    className = '',
    onAction,
    onClose,
    handleBack,
    listName,
    listContacts,
    selectedRecipients,
    isOpen,
    updateChoosedContacts,
    chosenContacts = [],
    handleLoadNext,
    allLoaded,
    loadingNextData,
    saveScrollSessionKey,
    setSearchTerm,
    searchTerm,
  } = props

  const getDefaultSelectedContacts = useCallback(() => {
    const currentChoosedContacts = chosenContacts || []
    const mergedSelectedContacts = [...currentChoosedContacts]
    selectedRecipients.forEach((selectedRecipient) => {
      const isDuplicate = mergedSelectedContacts.some((contact) => contact.id === selectedRecipient.id)

      if (!isDuplicate) {
        mergedSelectedContacts.push(selectedRecipient)
      }
    })

    return mergedSelectedContacts
  }, [chosenContacts, selectedRecipients])

  const [state, setState] = useState<SendDetailsSelectContactsModalState>({
    selectedContacts: getDefaultSelectedContacts(),
    visibleContacts: listContacts.sort((a, b) => a.name.localeCompare(b.name)),
    canSubmit: !!chosenContacts?.length,
    rowSelections: getDefaultSelectedRows(getDefaultSelectedContacts(), listContacts),
  })
  const { visibleContacts, rowSelections, selectedContacts, canSubmit } = state
  useEffect(() => {
    setState((state) => ({
      ...state,
      rowSelections: getDefaultSelectedRows(selectedContacts, listContacts),
    }))
  }, [listContacts])

  const { t } = useTranslation()
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      visibleContacts: listContacts.sort((a, b) => a.name.localeCompare(b.name)),
    }))
  }, [listContacts])
  const handleSearchChange = useCallback(
    (value: string) => {
      setSearchTerm?.(value)
    },
    [setSearchTerm]
  )
  const handleAction = useCallback(() => {
    onAction(selectedContacts.map(({ id, name, email = '', srcId }) => ({ id, name, email, isList: false, size: 1, srcId })))
  }, [onAction, selectedContacts])

  useEffect(() => {
    const canSubmit =
      !!selectedContacts?.length &&
      (selectedContacts.some((recip) => selectedRecipients.every((contact) => recip.id !== contact.id)) ||
        selectedRecipients.some((recip) => selectedContacts.every((contact) => recip.id !== contact.id)))

    setState((state) => ({
      ...state,
      canSubmit,
    }))
  }, [selectedContacts, selectedRecipients])

  useEffect(() => {
    updateChoosedContacts?.(selectedContacts)
  }, [selectedContacts])

  const onRowSelectionChanged = useCallback(
    (rows: Row<MessageRecipient>[]) => {
      const updatedSelections: MessageRecipient[] = rows.map((row) => row.original).filter((row) => row.email)

      const filteredContacts = _.differenceBy(chosenContacts, updatedSelections, 'id')

      const visibleOtherListSelected = filteredContacts.filter((item) => visibleContacts.some((contact) => contact.id === item.id))

      const combinedContacts = [
        ...chosenContacts
          .filter((choosed) => !visibleContacts.some((contact) => contact.id === choosed.id))
          .filter((choosed) => {
            if (!!visibleOtherListSelected.length) {
              return visibleOtherListSelected.some((item) => item.id !== choosed.id)
            }
            return true
          }),
        ...updatedSelections,
      ]

      setState((state) => ({
        ...state,
        selectedContacts: _.uniqBy(combinedContacts, 'id'),
      }))
    },

    [chosenContacts, visibleContacts]
  )

  const handleRowDisable = useCallback((row: Row<MessageRecipient>) => !row.original.email, [])

  const emptyStateProps = useMemo(
    () => ({
      headline: t('SendDetails.Recipients.ListPicker.EmptySearch.Headline', { searchTerm }),
      imgSrc: StaticImageNames.emptySearch,
      size: EmptyListingSize.MEDIUM,
      hideIcon: false,
      withoutBorder: true,
    }),
    [t, searchTerm]
  )

  return (
    <Modal
      className={classNames(rootClass, className)}
      data-test={dataTest}
      isOpen={isOpen}
      header={
        <ModalHeader headerType={ModalHeaderType.List} className={`${rootClass}__header`}>
          <BackButton onClick={handleBack} className={`${rootClass}__header__back-button`} dataTest={`${dataTest}-button-back`} />
          <Typography text={t(`SendDetails.Recipients.SelectContacts.Title`, { listName })} {...ModalHeaderStyle} />
          <div className={`${rootClass}__header__extra`}>
            <Search
              incomingValue={searchTerm ?? ''}
              placeholder="Search"
              canClear
              searchType={SearchType.LARGE}
              onChangeHandler={handleSearchChange}
              dataTest={`${dataTest}-search`}
              className={`${rootClass}__search-input`}
            />
          </div>
        </ModalHeader>
      }
    >
      <ModalBody className={`${rootClass}__body`}>
        <LazyLoadOnScrollContainer
          allLoaded={allLoaded}
          onLoadMoreData={handleLoadNext}
          loadingNextData={loadingNextData}
          saveScrollSessionKey={saveScrollSessionKey}
          className={`${rootClass}__lazy-loader`}
        >
          <TableV2
            enableInsideLoader={true}
            enableLazyLoading={true}
            data={visibleContacts}
            columns={sendDetailsSelectContactsModalColumns}
            withoutBorders
            enableCheckbox
            onRowSectionSelectionChanged={onRowSelectionChanged}
            defaultSelectedRows={rowSelections}
            emptyState={emptyStateProps}
            rowDisabled={handleRowDisable}
          />
        </LazyLoadOnScrollContainer>
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.List} className={`${rootClass}__footer`}>
        <div className={`${rootClass}__footer__extra`}>
          <Typography
            text="SendDetails.Recipients.SelectContacts.SelectedText"
            {...ModalBodyStyle}
            tagProps={{ medium: { weight: TextWeight.MEDIUM, inline: true } }}
            values={{ count: selectedContacts.length }}
          />
        </div>
        <Button buttonType={ButtonType.TERTIARY} onClick={onClose} dataTest={`${dataTest}-button-tertiary`}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} disabled={!canSubmit} onClick={handleAction} dataTest={`${dataTest}-button-primary`}>
          {t('SendDetails.Recipients.ListPicker.Submit')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default SendDetailsSelectContactsModal
