import { KeyValue, KeyValuePickerProps } from '@components/KeyValuePicker/KeyValuePicker'
import { MAX_EMAIL_INPUT_LENGTH, rootContext } from '@utils/const/globals'

import { AnalyticsVariables } from './components/AnalyticsVariablesModal/AnalyticsVariables.constants'

export const ANALYTICS_SETTINGS_LINK = `${rootContext}/classic/if/_account/connectors.jsp?type=googleanalytics`

export const UTM_PARAMS: KeyValue[] = [
  { key: 'utm_id', value: '' },
  { key: 'utm_source', value: 'act_on_software' },
  { key: 'utm_medium', value: 'email' },
  { key: 'utm_campaign', value: AnalyticsVariables.SUBJECT_LINE },
  { key: 'utm_term', value: AnalyticsVariables.ENV_CLICKTHROUGH_LINK_NAME },
  { key: 'utm_content', value: 'email' },
  { key: 'utm_source_platform', value: 'act_on_software' },
]

export const keyValueProps: Partial<KeyValuePickerProps> = {
  keyHeader: 'Parameter',
  valueHeader: 'Value',
  keyPlaceholder: 'e.g. Source',
  valuePlaceholder: 'e.g. Paid_Ad',
  addButtonText: 'Add parameter',
  groupedInputs: { name: 'analyticsFields' },
  minRowCount: 1,
  maxInputLength: MAX_EMAIL_INPUT_LENGTH,
}

export const formatDomain = (domain: string) => domain.replace(/https:/, '').replace(/\//g, '')

export const validateAnalyticsFields: (typeof keyValueProps)['validityFunctions'] = {
  key: (keyValue) => (!keyValue.key && !!keyValue.value ? 'Parameter is missing' : ''),
  value: (keyValue) => (!keyValue.value && !!keyValue.key ? 'Value is missing' : ''),
}
