import { gql } from '@apollo/client'

export default gql`
  query getSalesPermissions {
    getSalesPermissions {
      hideMyActonTab
      hidePasswordAndOrganizationTab
      hideMySignatureTab
    }
  }
`
