import XLSX from 'xlsx-js-style'

import { CampaignTableRowData } from '@src/pages/reports/revenueImpactNew/components/campaignTable/CampaignTable.types'
import { addAvgRevenueAndWonPercentage } from '@src/pages/reports/revenueImpactNew/components/campaignTable/utils/campaignTable.formattedData'

const addTotal: (data: CampaignTableRowData[]) => CampaignTableRowData[] = (data) => {
  if (!data.length) return []
  const totalRow = data.reduce(
    (row, acc) => {
      const keys: ('opportunities' | 'won' | 'revenue' | 'pipeline')[] = ['opportunities', 'won', 'revenue', 'pipeline']
      const result: CampaignTableRowData = { id: 'total', opportunities: 0, won: 0, revenue: 0, pipeline: 0 }
      keys.forEach((key) => {
        result[key] = Number(row[key] || 0) + Number(acc[key] || 0)
      })
      return result
    },
    { id: 'total', opportunities: 0, won: 0, revenue: 0, pipeline: 0 }
  )
  totalRow.name = 'Total'
  return [...data, addAvgRevenueAndWonPercentage(totalRow)]
}

const downloadCSV: (data: CampaignTableRowData[], withTotal: boolean) => void = (data, withTotal) => {
  let csvContent = 'data:text/csv;charset=utf-8,'
  const dataToDownload = withTotal ? addTotal(data) : data
  const addRowToCSV = (row: CampaignTableRowData, depth: number) => {
    const { name, status, subRows, won, wonPercentage, revenue, pipeline, opportunities, avgRevenue, parentPath } = row
    let campaign = name || status
    campaign = (parentPath ? parentPath.join(' -> ') + ' -> ' : '-> '.repeat(depth)) + campaign
    csvContent += [campaign, opportunities, won, `${wonPercentage?.toFixed(1)}%`, pipeline, revenue, avgRevenue?.toFixed()].join(',') + '\n'
    withTotal && subRows?.forEach((row) => addRowToCSV(row, depth + 1))
  }
  //Setting Titles
  csvContent += ['Campaign Details', 'Opportunities', 'Won', 'Won %', 'Pipeline', 'Revenue', 'Avg Revenue'].join(',') + '\n'

  dataToDownload.forEach((row) => addRowToCSV(row, 0))

  const encodedUri = encodeURI(csvContent)
  const link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', 'campaign_performance.csv')
  document.body.appendChild(link)

  link.click()
  document.body.removeChild(link)
}

const prepareRowsForExcel: (data: CampaignTableRowData[], withTotal: boolean) => { v: string | number }[][] = (data, withTotal) => {
  const excelRows: { v: string | number }[][] = [
    ['Campaign details', 'Opportunities', 'Won', 'Won %', 'Pipeline', 'Revenue', ' AVG Revenue'].map((key) => ({
      v: key,
      s: { font: { bold: true } },
    })),
  ]
  const addRow = (row: CampaignTableRowData, depth: number) => {
    const { name, status, subRows, parentPath, won, wonPercentage, revenue, pipeline, opportunities, avgRevenue } = row
    let campaign = name || status
    campaign = (parentPath ? parentPath.join(' -> ') + ' -> ' : '-> '.repeat(depth)) + campaign
    excelRows.push([campaign, opportunities, won, `${wonPercentage?.toFixed(1)}%`, pipeline, revenue, avgRevenue?.toFixed()].map((v = '') => ({ v })))
    withTotal && subRows?.forEach((row) => addRow(row, depth + 1))
  }

  const dataToDownload = withTotal ? addTotal(data) : data

  dataToDownload.forEach((row) => addRow(row, 0))
  return excelRows
}

const downloadExcel: (data: CampaignTableRowData[], withTotal: boolean) => void = (data, withTotal) => {
  const wb = XLSX.utils.book_new()

  // Create worksheet with rows; Add worksheet to workbook
  const ws = XLSX.utils.aoa_to_sheet(prepareRowsForExcel(data, withTotal))
  XLSX.utils.book_append_sheet(wb, ws)

  // Write Excel file to browser
  XLSX.writeFile(wb, 'campaign_performance.xlsx')
}

export { downloadCSV, downloadExcel }
