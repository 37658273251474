import React, { FC, useContext, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import Container from '@components/Container/Container'
import InfoTooltip from '@components/InfoTooltip/InfoTooltip'
import { Status } from '@components/StatusToast/StatusToast'
import TextArea from '@components/TextArea/TextArea'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import PlainTextComposerFooter from '@src/pages/EmailComposer/components/PlainTextComposer/components/PlainTextComposerFooter/PlainTextComposerFooter'
import { filterNotEmptyArray } from '@utils/array'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { useEmailComposerRequests } from '@utils/composer/emailComposer/GraphQL/EmailComposerRequests.graphQL'
import { useTextCommands } from '@utils/hooks/useTextCommands'
import { logNewRelicError } from '@utils/new-relic.utils'

import { PlainTextBlock, plainTextJsonToValue, plainTextValueToJson } from './PlainTextComposer.utils'
import { isParagraphModule } from '../../utils/BeeEditor.types'
import { getRowDisplayConditions } from '../../utils/DisplayConditions.utils'
import { T_BEE_BLANK } from '../../utils/EmailComposer.constants'
import { detectEmailType } from '../../utils/EmailComposerDetector.utils'

import './PlainTextComposer.css'

interface PlainTextComposerProps {
  className?: string
  dataTest?: string
}

const rootClass = 'plain-text-composer'

const PlainTextComposer: FC<PlainTextComposerProps> = (props: PlainTextComposerProps) => {
  const { dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const rssBlocks = useRef(new Map<Number, PlainTextBlock>())

  const containerValues = useContext(EmailComposerContext)
  const {
    values: {
      isCreatedNewMessage,
      message,
      loading,
      message: { templateJson, plainTextOnlyDynamicContent, messageType },
      detectedURLChanges: { isBlankMessage },
    },
    api: { update, onSave, updateModal },
  } = containerValues

  const inputRef = useRef<HTMLTextAreaElement>(null)
  const [inputValue, setInputValue] = useState(() => plainTextJsonToValue(templateJson, rssBlocks))

  const { isEmailWebinar } = detectEmailType(messageType)
  const { getMessagePlainTextRequest } = useEmailComposerRequests()
  const { undo, redo, isUndoEnabled, isRedoEnabled, insert, onDropDownToggle, onButtonMouseDown, onTextChange } = useTextCommands(inputRef)

  const updateJson = (value = inputRef.current?.value ?? '') => {
    update({
      message: {
        templateJson: plainTextValueToJson(value, message, rssBlocks),
        templateHtml: value,
      },
      preview: {
        plainText: value,
      },
    })
  }

  const handleTextChange = () => {
    onTextChange()
    updateJson()
    setInputValue(inputRef.current?.value ?? '')
  }

  useEffect(() => {
    if (!loading) {
      // When message is marked as plain text only but was created as HTML outside of the plain text composer
      const convertToPlainText = async () => {
        const showError = () => updateModal('statusToast', { message: t('EmailComposer.PlainTextComposer.Conversion.Error'), status: Status.FAIL })

        setInputValue('')
        update({ isPlainTextComposerLoading: true })

        const success = await onSave(true, true)
        if (success) {
          try {
            const response = await getMessagePlainTextRequest({ messageId: isBlankMessage ? T_BEE_BLANK : message.id })
            // Workaround: Uncertain where these P tags are coming from
            const text = (response.data?.getMessagePlainText?.plainText ?? '').trim().replace(/<p>|<\/p>/g, '')
            updateJson(text)
            setInputValue(text)
            update({ isPlainTextComposerLoading: false })
          } catch (e) {
            showError()
            logNewRelicError(e, 'Error getting flattened plain text after plain text only conversion')
          }
        } else {
          showError()
          logNewRelicError('Error saving message before plain text only conversion')
        }
      }

      const requiresTextConversion =
        isBlankMessage ||
        (isEmailWebinar &&
          templateJson.page.rows.some((row) => row.columns.some((column) => column.modules.some((module) => !isParagraphModule(module)))))

      if (requiresTextConversion) {
        convertToPlainText()
      } else {
        const conditions = getRowDisplayConditions(message, true).filter(filterNotEmptyArray)
        update({ message: { plainTextOnlyDynamicContent: conditions } })
      }
      // Makes sure that the plain text value is in the context for the preview features
      handleTextChange()
    }
  }, [loading, isBlankMessage])

  useEffect(() => {
    if (plainTextOnlyDynamicContent.length > 0) {
      updateJson()
    }
  }, [plainTextOnlyDynamicContent])

  useEffect(() => {
    if (isCreatedNewMessage) {
      // The plain text only flag may be lost if no initial save happens
      // A significant backend change would need to happen to avoid this otherwise
      onSave(true, true)
    }
  }, [isCreatedNewMessage, onSave])

  return (
    <div className={`${rootClass}__container`}>
      <div className={classNames(rootClass, className)} data-test={dataTest}>
        <div className={`${rootClass}__header`}>
          <Typography text={t('Plain text email')} type={TextType.BANNER_HEADER} />
          <InfoTooltip hasDarkBackground>
            <Typography text={t('EmailComposer.PlainTextComposer.Title.Tooltip')} type={TextType.BODY_TEXT_WHITE} tagComponents={{ br: <br /> }} />
          </InfoTooltip>
        </div>
        <Container noPadding className={`${rootClass}__content`}>
          <TextArea
            name={'plainText'}
            inputRef={inputRef}
            value={inputValue}
            resize={false}
            onChange={handleTextChange}
            dataTest={`${dataTest}-textarea`}
            className={`${rootClass}__textarea`}
          />
        </Container>
        <PlainTextComposerFooter
          inputRef={inputRef}
          showLoading={!inputRef.current}
          undo={undo}
          redo={redo}
          insert={insert}
          isRedoEnabled={isRedoEnabled}
          isUndoEnabled={isUndoEnabled}
          onButtonMouseDown={onButtonMouseDown}
          onDropDownToggle={onDropDownToggle}
          rssBlocks={rssBlocks}
        />
      </div>
    </div>
  )
}

export default PlainTextComposer
