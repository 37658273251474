import React, { FC } from 'react'

import classNames from 'classnames'

import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2Props } from '@components/SelectV2/SelectV2.props'
import SelectV2Popover from '@components/SelectV2Popover/SelectV2Popover'
import Svg, { SvgNames } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography from '@components/Typography/Typography'

import './TimeZoneSelectV2.css'

interface TimeZoneSelectV2Props {
  className?: string
  dataTest?: string
  align?: 'start' | 'center' | 'end' | undefined
  selectProps: Partial<SelectV2Props>
  label: string
  isDisabled?: boolean
  type?: TimeZoneSelectV2Type
}

export enum TimeZoneSelectV2Type {
  DFAULT = 'DEFAULT',
  MINIMAL = 'MINIMAL',
}

const rootClass = 'time-zone-select-v2'

const TimeZoneSelectV2: FC<TimeZoneSelectV2Props> = (props: TimeZoneSelectV2Props) => {
  const { dataTest = rootClass, className = '', align = 'start', selectProps, label, isDisabled, type = TimeZoneSelectV2Type.DFAULT } = props
  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {type === TimeZoneSelectV2Type.DFAULT ? (
        <SelectV2 className={`${rootClass}__selectV2`} isDisabled={isDisabled} isClearable={false} inputIcon={SvgNames.world} {...selectProps} />
      ) : (
        <SelectV2Popover
          isDisabled={isDisabled}
          align={align}
          renderTrigger={() => (
            <div className={classNames(`${rootClass}__trigger`, { [`${rootClass}__trigger-disabled`]: isDisabled })} tabIndex={0} role={'button'}>
              <div className={classNames(`${rootClass}__svg-container`, `${rootClass}__svg-container-margin`)}>
                <Svg fill={isDisabled ? SvgColor.BUTTON_GRAY : undefined} name={SvgNames.world} className={`${rootClass}__trigger-svg`} />
              </div>
              <Typography text={label} className={classNames({ [`${rootClass}__trigger-disabled__text`]: isDisabled })} />
              <div className={`${rootClass}__svg-container`}>
                <Svg
                  fill={isDisabled ? SvgColor.BUTTON_GRAY : undefined}
                  name={SvgNames.caretDownSmall}
                  className={`${rootClass}__trigger-svg-small`}
                />
              </div>
            </div>
          )}
          selectProps={selectProps}
          className={`${rootClass}__selectV2-popover`}
        />
      )}
    </div>
  )
}

export default TimeZoneSelectV2
