import React, { FC } from 'react'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './IncomingWebhookAuthenticationDisabledSection.css'

const rootClass = 'incoming-webhook-authentication-disabled-section'
const rootTranslation = 'Incoming.Webhooks.CreateModal.Authentication.DisabledSection'

const IncomingWebhookAuthenticationDisabledSection: FC = () => {
  const { t } = useTranslation()
  return (
    <div className={rootClass} data-test={rootClass}>
      <div className={`${rootClass}__shield-container`}>
        <Svg name={SvgNames.warningShield} type={SvgType.SUPER_LARGE_ICON} />
      </div>
      <div className={`${rootClass}__info-container`}>
        <Typography
          className={`${rootClass}__info-title`}
          text={t(`${rootTranslation}.Info.Title`)}
          type={TextType.BANNER_HEADER}
          weight={TextWeight.MEDIUM}
        />
        <div className={`${rootClass}__info-text-container`}>
          <Typography className={`${rootClass}__info-text`} text={t(`${rootTranslation}.Info.Text.1`)} type={TextType.BODY_TEXT_LIGHT} />
          <Typography className={`${rootClass}__info-text`} text={t(`${rootTranslation}.Info.Text.2`)} type={TextType.BODY_TEXT_LIGHT} />
        </div>
        <TextLink className={`${rootClass}__info-link`} text={t(`${rootTranslation}.Info.Link`)} size={TextLinkSize.LARGE} />
      </div>
    </div>
  )
}

export default IncomingWebhookAuthenticationDisabledSection
