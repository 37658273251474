import React from 'react'

import { TableActions } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.constants'
import { ListPageCommonState, TableProps, Update } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { renderRelativeDate } from '@components/Table/components/tableColumns'
import { HeaderAction, TableColumn } from '@components/Table/Table'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { ColWithTooltip } from '@components/TableV2/utils/tableV2ColumnUtils'
import Tooltip from '@components/Tooltip/Tooltip'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { getCustomRowActionsUtils } from '@src/pages/listingPages/AutomatedProgramTemplates/utils/Helpers'
import {
  AutomatedProgramTemplateCustomModals,
  AutomatedProgramTemplatesCustomTableActions,
  ProgramTemplateListing,
  headerActionCustomProps,
  rowActionCustomProps,
} from '@src/pages/listingPages/AutomatedProgramTemplates/utils/ListingPage.constants'
import { ItemType } from '@utils/categorization'
import { getStandardFormattedDate, relativeDate } from '@utils/date'
import { renderPathToFolder } from '@utils/searchUtils'

const onCustomTableAction = (
  customTableAction: AutomatedProgramTemplatesCustomTableActions,
  update: Update,
  _listPageValues: ListPageCommonState
) => {
  const customAction: { [key in AutomatedProgramTemplatesCustomTableActions]: () => void } = {
    [AutomatedProgramTemplatesCustomTableActions.CREATE_PROGRAM]: () =>
      update &&
      update({
        showCustomModal: true,
        customTableAction: AutomatedProgramTemplateCustomModals.CREATE_PROGRAM,
      }),
  }
  customAction[customTableAction]()
}

const getCustomRowActions = (tableActions: TableActions, t: Function, canCreate: boolean) => getCustomRowActionsUtils(tableActions, t, canCreate)

const getCustomHeaderActions = (_tableActions: TableActions): HeaderAction[] => []

const renderItemNameVal = (original: ItemDto, search: string, rootClass: string) => {
  if (!original) {
    return ''
  }

  const asset = original as ProgramTemplateListing

  const text = () => (
    <div className={`${rootClass}__program-name-typography`}>
      <div className={'ellip'}>{search ? renderBoldTextOnMatch(asset.m_title, search) : asset.m_title}</div>
    </div>
  )

  return (
    <Tooltip trigger={text()} ellipOnTrigger>
      {asset.m_title}
    </Tooltip>
  )
}

const renderCreatedTime = (original: ItemDto) => {
  const { m_creationTime } = original as ProgramTemplateListing
  return renderRelativeDate(relativeDate(m_creationTime, true, false), getStandardFormattedDate(m_creationTime))
}

const renderSearchColumnsUtil = (
  searchInAllItems: boolean | undefined,
  currentFolder: FolderData,
  search: string,
  folders: FolderData[],
  t: Function
): TableColumn[] => [
  {
    Header: 'Template Name',
    accessor: 'name',
    align: 'left',
    minWidth: 150,
    Cell: ({
      cell: {
        row: { original },
      },
    }) => renderItemNameVal(original, search, ''),
    flexColumn: true,
  },
  {
    Header: 'Tags',
    accessor: 'tags',
    align: 'left',
    maxWidth: 100,
    disableSorting: true,
    enableCustomCellValue: true,
  },
  {
    Header: 'Location',
    accessor: 'folderId',
    align: 'left',
    maxWidth: 120,
    Cell: (row: any) =>
      renderPathToFolder(ItemType.PROGRAM_TEMPLATE, row.cell.value, folders, searchInAllItems, 'list-page-table-container', t, currentFolder),
  },
  {
    Header: 'Last Updated',
    accessor: 'm_creationTime',
    align: 'left',
    maxWidth: 120,
    Cell: ({
      cell: {
        row: { original },
      },
    }) => renderCreatedTime(original),
  },
]

const renderSearchColumnsUtilV2 = (
  searchInAllItems: boolean | undefined,
  currentFolder: FolderData,
  search: string,
  folders: FolderData[],
  t: Function
): ColumnDefWithAdditionalProps<ProgramTemplateListing>[] => {
  return [
    {
      header: 'Template Name',
      accessorKey: 'name',
      textAlign: 'left',
      enableCustomCellValue: true,
      cell: (cell) => {
        const cellText = renderBoldTextOnMatch(cell.row.original.m_title, search, true)
        return <ColWithTooltip cell={cell} typographySpecifiedTitle={cellText} tooltipTitle={cell.getValue<string>()} />
      },
    },
    {
      header: 'Location',
      accessorKey: 'folderId',
      textAlign: 'left',
      maxSize: 150,
      cell: (row) =>
        renderPathToFolder(
          ItemType.PROGRAM_TEMPLATE,
          row.cell.getValue<number>(),
          folders,
          searchInAllItems,
          'list-page-table-container',
          t,
          currentFolder
        ),
    },
    {
      header: 'Last Updated',
      accessorKey: 'm_creationTime',
      textAlign: 'right',
      maxSize: 120,
      cell: ({
        cell: {
          row: { original },
        },
      }) => renderCreatedTime(original),
    },
  ]
}

export const tableProps = (t: Function, canCreate: boolean): TableProps => {
  return {
    columns: [
      {
        Header: 'Template Name',
        accessor: 'm_title',
        align: 'left',
        minWidth: 250,
        flexColumn: true,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => renderItemNameVal(original, '', ''),
      },
      {
        Header: 'Tags',
        accessor: 'tags',
        align: 'left',
        minWidth: 120,
        maxWidth: 140,
        disableSortBy: true,
      },
      {
        Header: 'Created',
        accessor: 'm_creationTime',
        align: 'left',
        maxWidth: 125,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => renderCreatedTime(original),
      },
    ] as TableColumn[],
    hasAutoSelectedRows: false,
    hasExpander: false,
    rowActionCustomProps,
    renderSearchColumns: (searchInAllItems: boolean | undefined, currentFolder: FolderData, search: string, folders: FolderData[]) =>
      renderSearchColumnsUtil(searchInAllItems, currentFolder, search, folders, t),
    headerActionCustomProps,
    listPage: 'AutomatedProgramTemplates',
    getCustomHeaderActions,
    getCustomRowActions: (tableActions) => getCustomRowActions(tableActions, t, canCreate),
    onCustomTableAction,
    actonAssetType: ItemType.PROGRAM_TEMPLATE,
    shareModalText: '',
  }
}

export const tablePropsV2 = (t: Function, canCreate: boolean): TableProps => {
  return {
    columns: [
      {
        header: 'Template Name',
        accessorKey: 'templateName',
        textAlign: 'left',
        enableCustomCellValue: true,
        cell: (cell) => {
          const title = cell.row.original.m_title
          return <ColWithTooltip cell={cell} typographySpecifiedTitle={title} tooltipTitle={title} />
        },
      },
      {
        header: 'Tags',
        accessorKey: 'tags',
        textAlign: 'left',
        maxSize: 180,
        enableCustomCellValue: true,
        disableSorting: true,
      },
      {
        header: 'Created',
        accessorKey: 'm_creationTime',
        textAlign: 'left',
        maxSize: 180,
        cell: ({
          cell: {
            row: { original },
          },
        }) => renderCreatedTime(original),
      },
    ] as ColumnDefWithAdditionalProps<ProgramTemplateListing, unknown>[],
    hasAutoSelectedRows: false,
    hasExpander: false,
    rowActionCustomProps,
    renderSearchColumns: (searchInAllItems: boolean | undefined, currentFolder: FolderData, search: string, folders: FolderData[]) =>
      renderSearchColumnsUtilV2(searchInAllItems, currentFolder, search, folders, t) as any,
    headerActionCustomProps,
    listPage: 'AutomatedProgramTemplates',
    getCustomHeaderActions,
    getCustomRowActions: (tableActions) => getCustomRowActions(tableActions, t, canCreate),
    onCustomTableAction,
    actonAssetType: ItemType.PROGRAM_TEMPLATE,
    shareModalText: '',
  }
}
