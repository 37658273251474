import React, { FC, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import Loader, { LoaderTypes } from '@components/Loader/Loader'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Search from '@components/Search/Search'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { TableV2 } from '@components/TableV2/TableV2'
import { TableV2RowData } from '@components/TableV2/tableV2TS/interfaces'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { ModalBodyStyle, ModalHeaderStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { Row } from '@tanstack/react-table'

import './AssignManagersModal.css'

export interface AssignedManager extends TableV2RowData<AssignedManager> {
  id: string
  name: string
  isAssigned: boolean
}

export interface AssignManagersModalProps {
  isManagers?: boolean
  isOpen: boolean
  managers: AssignedManager[]
  loading: boolean
  className?: string
  dataTest?: string
  onClose: () => void
  onAction: (assignments: AssignedManager[]) => void
}

interface AssignManagersModalState {
  assignedManagers: AssignedManager[]
  visibleManagers: AssignedManager[]
  selectedManagers: Record<string, boolean>
  searchText: string
  disableSubmit: boolean
}

const defaultState: AssignManagersModalState = {
  searchText: '',
  assignedManagers: [],
  visibleManagers: [],
  selectedManagers: {},
  disableSubmit: true,
}

const rootClass = 'assign-managers-modal'

const AssignManagersModal: FC<AssignManagersModalProps> = (props: AssignManagersModalProps) => {
  const { dataTest = rootClass, className = '', onAction, onClose, isOpen, managers, isManagers, loading } = props
  const [state, setState] = useState<AssignManagersModalState>({
    ...defaultState,
  })
  const { searchText, assignedManagers, disableSubmit } = state
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    const notSubmittable =
      assignedManagers.length === 0
        ? true
        : managers.every((mgr) => {
            const aMgr = assignedManagers.find((aMgr) => aMgr.id === mgr.id)
            return aMgr?.isAssigned === mgr.isAssigned
          })
    setState({ ...state, disableSubmit: notSubmittable })
  }, [assignedManagers])

  const getUpdatedSelections = (list: AssignedManager[]) => {
    const selected = list.filter((manager) => manager.isAssigned)
    return Object.fromEntries(
      selected.map((account) => {
        const rowId = list.indexOf(account)
        return [rowId, true]
      })
    )
  }

  useEffect(() => {
    const selections = getUpdatedSelections(managers)
    setState({ ...state, assignedManagers: managers, visibleManagers: managers, selectedManagers: selections })

    return () => setState(defaultState)
  }, [managers])

  const selectedManagersCount = useMemo(() => state.assignedManagers.filter((mgr) => mgr.isAssigned).length, [state.assignedManagers])

  const handleAction = () => {
    setIsSubmitting(true)
    onAction(assignedManagers)
  }

  const handleClose = () => {
    onClose()
  }

  const handleSearchChange = (value: string) => {
    const visibleManagers = assignedManagers.filter((mgr) => mgr.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()))
    const selectedManagers = getUpdatedSelections(visibleManagers)
    setState({ ...state, searchText: value, visibleManagers, selectedManagers })
  }

  const updateSelectedRows = (selectedRows: AssignedManager[]) => {
    const updatedManagers = assignedManagers.map((manager) => ({
      ...manager,
      isAssigned: selectedRows.filter((row) => row.id === manager.id).length > 0,
    }))
    setState({ ...state, assignedManagers: updatedManagers })
  }

  const onRowSelectionChanged = (ids: string[], managerRows: Row<AssignedManager>[]) => {
    const selectedRows: AssignedManager[] = []
    if (ids.length === 0 && !searchText) {
      updateSelectedRows(selectedRows)
    } else {
      const updatedManagers: AssignedManager[] = []
      const nowSelected = ids.map((id) => managerRows[parseInt(id)].original)
      assignedManagers.forEach((aMgr) => {
        const visRow = !!managerRows.find((mRow) => mRow.original.id === aMgr.id)
        if (visRow) {
          const isAssigned = !!nowSelected.find((nsMgr) => nsMgr.id === aMgr.id)
          updatedManagers.push({ ...aMgr, isAssigned })
        } else {
          updatedManagers.push({ ...aMgr })
        }
      })
      setState({ ...state, assignedManagers: updatedManagers })
    }
  }

  const columns: ColumnDefWithAdditionalProps<AssignedManager>[] = [
    {
      header: 'Name',
      accessorKey: 'name',
      textAlign: 'left',
      cell: (row: { getValue: () => any }) => <TextWithTooltipOnEllip typographyProps={{ text: row.getValue() }} />,
    },
  ]

  const header = (
    <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__header`}>
      <Typography text={t(isManagers ? 'Assign Managers' : 'Assign sales users')} {...ModalHeaderStyle} />
      <div className={`${rootClass}__header__extra`}>
        <Search incomingValue={searchText} placeholder={'Search'} canClear onChangeHandler={handleSearchChange} dataTest={`${dataTest}-search`} />
      </div>
    </ModalHeader>
  )

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
      <ModalBody className={`${rootClass}__body`}>
        <Typography
          text={t(isManagers ? 'AccountManagement.AssignManagers.Subheader' : 'AccountManagement.AssignSalesUsers.Subheader')}
          {...ModalBodyStyle}
        />
        <TableV2
          data={state.visibleManagers}
          columns={columns}
          enableCheckbox
          defaultSelectedRows={state.selectedManagers}
          onRowSelectionChanged={onRowSelectionChanged}
          loading={loading}
          enableInsideLoader
          withoutBorders
          styles={{ minWidth: 'auto' }}
          emptyState={{
            headline: t(`Inbox.EmptyListing.Search.Headline`, { search: searchText }),
            imgSrc: StaticImageNames.emptySearch,
            size: EmptyListingSize.MEDIUM,
            hideIcon: false,
            withoutBorder: true,
          }}
        />
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form} className={`${rootClass}__footer`}>
        <div className={`${rootClass}__footer-extra`}>
          <Typography
            text={t('AccountManagement.AssignManagers.Footer.Selected', { count: selectedManagersCount })}
            type={TextType.BODY_TEXT_SMALL}
            tagProps={{ medium: { weight: TextWeight.MEDIUM, inline: true } }}
          />
        </div>
        <div className={`${rootClass}__footer-buttons`}>
          <Button buttonType={ButtonType.TERTIARY} disabled={isSubmitting} onClick={handleClose} dataTest={`${dataTest}-button-tertiary`}>
            {t('Close')}
          </Button>
          <Button
            buttonType={ButtonType.PRIMARY}
            disabled={disableSubmit}
            onClick={handleAction}
            dataTest={`${dataTest}-button-primary`}
            className={classNames(`${rootClass}__submit-button`, {
              [`${rootClass}__submit-button-submitting`]: isSubmitting,
            })}
          >
            {!isSubmitting && t('Submit')}
            {isSubmitting && <Loader loaderType={LoaderTypes.row} className={`${rootClass}__submit-loader`} />}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default AssignManagersModal
