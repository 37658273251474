import { rootContext } from '@const/globals'

export const PAGE_SIZE_OPTIONS = [10, 20, 50, 100]

export enum MAX_WIDTHS {
  small = 180,
  medium = 228,
  large = 276,
}

export const SEGMENTS_LIST_URL = `${rootContext}/segments`
export const CONTACTS_DETAILS_URL = `${SEGMENTS_LIST_URL}/contacts`

export enum ContactsTableActions {
  SEND_EMAIL = 'sendEmail',
  SEND_SMS = 'sendSms',
  EXPORT = 'export',
  DELETE = 'delete',
  REMOVE_FROM_SEGMENT = 'removeFromSegment',
  REMOVE_FROM_BOUNCE = 'removeFromBounce',
}
