import React, { FC } from 'react'

import FilterableList, { FilterableListData } from '@components/FilterableList/FilterableList'

import './FilterableListPair.css'

type Props = {
  accessor: string
  data: FilterableListData[]
  label: string
  onItemSelectionChanged: (updatedData: FilterableListData[]) => void
  placeholder?: string
  emptyText?: string
  className?: string
  dataTest?: string
  loading?: boolean
}

const rootClass = 'filterable-list-pair'

const FilterableListPair: FC<Props> = (props: Props) => {
  const {
    accessor,
    data,
    label,
    placeholder,
    dataTest = rootClass,
    className = rootClass,
    onItemSelectionChanged,
    emptyText,
    loading = false,
  } = props
  const selectedItems = data.filter((item) => item.selected)

  const onItemSelection = (selectedItem: FilterableListData) => {
    const updatedData = data.map((item) => {
      return item.id === selectedItem.id
        ? {
            ...item,
            selected: selectedItem.selected,
          }
        : {
            ...item,
          }
    })
    onItemSelectionChanged(updatedData)
  }

  return (
    <div className={className} data-test={dataTest}>
      <FilterableList
        accessor={accessor}
        data={data}
        placeholder={placeholder}
        onItemSelection={onItemSelection}
        emptyText={emptyText}
        loading={loading}
        dataTest={`${rootClass}-left`}
        className={`${rootClass}__left`}
      />
      <FilterableList
        accessor={accessor}
        data={selectedItems}
        label={label}
        onItemSelection={onItemSelection}
        selectedList
        loading={loading}
        dataTest={`${rootClass}-right`}
        className={`${rootClass}__right`}
      />
    </div>
  )
}

export default FilterableListPair
