import { ListingPageSubType } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { FormType } from '@complex/ListingPage/Utils/ListingPage.constants'
import { legacyActonContext, rootContext } from '@const/globals'
import { ItemDto } from '@graphql/types/microservice/categorization-types'

export interface Form extends ItemDto {
  name: string
  description: string
  submits: number
  formType: FormType
  list: string
  listId: string
  segment: string
  segmentId: string
  createdAt: number
  createdBy: string
  lastUpdated: number
  lastUpdatedBy: string
}

export const getFormsSubTypes = (connectedCrm: string): ListingPageSubType[] => [
  { name: 'new', label: 'New Forms', hasAndCondition: true },
  { name: 'classic', label: 'Classic Forms', hasAndCondition: true },
  ...getMoreFiltersSubTypes(connectedCrm),
]

export const getMoreFiltersSubTypes = (connectedCrm: string): ListingPageSubType[] => [
  { name: 'webex', label: 'Webex' },
  { name: 'citrix', label: 'Citrix' },
  { name: 'zoom', label: 'Zoom' },
  { name: 'unaffiliated', label: 'Unaffiliated' },
  {
    name: 'crmpushdata',
    label: connectedCrm ? connectedCrm.replace('SalesForce', 'Salesforce') : 'Disconnected CRM',
    tooltip: connectedCrm ? undefined : 'Form is associated with a previously connected CRM',
  },
]

export const FORM_BUILDER_LINK = `${rootContext}/classic/if/legacy/forms`

export const FORM_BUILDER_LINK_NO_REACT = `${legacyActonContext}//ng-ui/editor/#!/form-builder`

export const FORM_BUILDER_CLASSIC_LINK = `${legacyActonContext}/forms2/build.jsp`

export const FORM_TEMPLATE_LISTING_LINK = `${rootContext}/content/formTemplates`

export const FORM_TEMPLATE_SUCCESS_FLAG = 'listingPage:formTemplates:newTemplateSuccess'

export const FORM_INFO_LINK = `https://connect.act-on.com/hc/en-us/articles/6281878545687-Form-Settings-Guide`

export const getFormsPreviewUrl = (formId: string, isNew = true) =>
  isNew ? `${legacyActonContext}/internalapi/FormBuilder/read/snippet/form/${formId}` : `/acton/form/1/${formId}:/0/index.htm?preview=1`

export const openFormBuilderWindow = (windowName: string, formId = '', params = '', hasReactWrapper = true, isClassic = false) => {
  if (isClassic) {
    const classicParams = `id=${formId}&norefresh=0&tab=0&doneURL=&ts=${new Date().valueOf()}${params ? `&${params}` : ''}`
    const width = 800
    const height = screen.height - 100
    const left = (screen.width - width) / 2
    const top = (screen.height - height) / 2
    const attributes = `left=${left},top=${top},width=${width},height=${height},modal=yes,dependent=yes,resizable=yes,scrollbars=yes,toobar=no,location=no,status=no`

    const newWindow = window.open(`${FORM_BUILDER_CLASSIC_LINK}?${classicParams}`, windowName, attributes)
    newWindow?.focus()
    return newWindow
  }
  const baseUrl = hasReactWrapper ? FORM_BUILDER_LINK : FORM_BUILDER_LINK_NO_REACT
  return window.open(`${baseUrl}/${formId}${params ? `?${params}` : ''}`, windowName)
}

export interface FormBuilderMessageData {
  formId: string
  formTitle: string
}

export type FormBuilderMessage = FormBuilderMessageData & { windowName: string }

export const isFormBuilderMessage = (data: MessageEvent<any>['data']) => data instanceof Object && 'formId' in data && 'formTitle' in data
