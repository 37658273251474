import { ListingPageItem } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { PagePickerUi } from '@graphql/types/query-types'

export const convertWebPagesToItemDto = (pages: PagePickerUi[]) => {
  return pages.map((page) => {
    const dto = page as ListingPageItem
    dto.name = page.name
    dto.externalId = page.url
    dto.id = page.url
    dto.item = JSON.stringify({ ...dto, __typename: 'WebPage' })
    return dto
  })
}

export const convertDomainsToItemDto = (domains: string[]) => {
  return domains.map((domain) => {
    const dto: ListingPageItem = { name: domain, id: domain, externalId: domain }
    dto.item = JSON.stringify({ ...dto, url: domain, __typename: 'Domain' })
    return dto
  })
}
