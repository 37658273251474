import React, { FC, useCallback, useEffect, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import InsertURLModal from '@components/UploadImage/components/InsertURLModal'
import { useTranslation } from '@const/globals'

import './FavIconExpanded.css'

interface Props {
  onImageChange: (image: string) => void
  onRemoveImage: () => void
  imageURL: string
  isDefaultURL: boolean
  isValidURL: boolean
  className?: string
  dataTest?: string
}

const rootClass = 'fav-icon__expanded'

const FavIconExpanded: FC<Props> = (props: Props) => {
  const { onImageChange, onRemoveImage, imageURL, isDefaultURL, isValidURL, className, dataTest = rootClass } = props
  const { t } = useTranslation()
  const [img, setImg] = useState<string>('')
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false)

  useEffect(() => {
    setImg(isValidURL && !isDefaultURL ? imageURL : '')
  }, [isValidURL, isDefaultURL, imageURL])

  const handleChangeImage = useCallback(() => setOpenModal(true), [])
  const handleCloseModal = useCallback(() => setOpenModal(false), [])
  const handleSaveFromURL = useCallback(
    (url: string) => {
      setOpenModal(false)
      setImg(url)
      onImageChange(url)
    },
    [imageURL, onImageChange]
  )

  const handleRemoveURL = useCallback(() => {
    setOpenModal(false)
    onRemoveImage()
  }, [])

  const handleConfirmationAnswer = useCallback((answer: YesNo) => {
    setOpenConfirmationModal(false)
    if (answer === YesNo.YES) {
      onRemoveImage()
    }
  }, [])

  const confirmationModalBody = <Typography text={t('Remove.Favicon')} type={TextType.BODY_TEXT_LIGHT} />

  const isDefault = !img && isDefaultURL
  const isInvalid = !img && !isValidURL
  //translation context
  const context = isDefault ? 'default' : 'invalid'

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div
        className={classNames(`${rootClass}-holder`, {
          [`${rootClass}-holder-default`]: isDefault,
          [`${rootClass}-holder-invalid`]: isInvalid,
        })}
        data-test={`${dataTest}-holder`}
      >
        {isDefault || isInvalid ? (
          <>
            <Svg
              name={isDefault ? SvgNames.image : SvgNames.noImage}
              type={SvgType.EXTRA_LARGE_ICON}
              className={`${rootClass}-holder-${context}-svg`}
              dataTest={`${dataTest}-${context}-svg`}
            />
            <Typography
              text={t('FavIcon.Not.Set.Title', { context })}
              type={TextType.SECTION_HEADER}
              weight={TextWeight.MEDIUM}
              className={`${rootClass}-holder-title`}
              dataTest={`${dataTest}-holder-title`}
            />
            <Typography
              text={t('FavIcon.Not.Set.Description', { context, imageURL })}
              type={isInvalid ? TextType.BODY_TEXT_SMALL : undefined}
              lineHeight={isInvalid ? LineHeight.MEDIUM_SMALL : undefined}
              className={`${rootClass}-holder-description`}
              dataTest={`${dataTest}-holder-description`}
            />
            <Button
              buttonType={ButtonType.WHITE}
              onClick={handleChangeImage}
              className={`${rootClass}-holder-${context}-set-button`}
              dataTest={`${dataTest}-set-button`}
            >
              {t('FavIcon.Not.Set.Button.Label', { context })}
            </Button>
          </>
        ) : (
          <img alt="favicon" className={`${rootClass}-image`} data-test={`${dataTest}-body-image`} src={img} />
        )}
      </div>
      {!isDefault && !isInvalid && (
        <div className={`${rootClass}-hover`} data-test={`${dataTest}-body-hover`}>
          <div className={`${rootClass}-hover-content`}>
            <Button
              buttonType={ButtonType.TRANSPARENT}
              onClick={handleChangeImage}
              className={`${rootClass}-hover-change-button`}
              dataTest={`${dataTest}-body-hover-change-button`}
            >
              {t('Change image')}
            </Button>
            <Button
              buttonType={ButtonType.ERASE}
              onClick={() => {
                setOpenConfirmationModal(true)
              }}
              className={`${rootClass}-hover-remove-button`}
              dataTest={`${dataTest}-body-hover-remove-button`}
            >
              {t('Remove')}
            </Button>
          </div>
        </div>
      )}
      <InsertURLModal
        isOpen={openModal}
        imgUrl={img}
        onRemove={handleRemoveURL}
        onClose={handleCloseModal}
        onSave={handleSaveFromURL}
        dataTest={`${dataTest}-modal`}
      />
      <ConfirmationModal
        isDelete
        deleteButtonText={'Remove'}
        isOpen={openConfirmationModal}
        title={t('Are you sure?')}
        body={confirmationModalBody}
        onAnswer={handleConfirmationAnswer}
        className={`${rootClass}-confirmation-modal`}
        dataTest={`${dataTest}-confirmation-modal`}
      />
    </div>
  )
}

export default FavIconExpanded
