import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Row } from 'react-table'

import { PageHeaderProps, SidebarProps } from '@complex/ListingPage/Context/ListingPageCommon.context'
import ListingPageContainer from '@complex/ListingPage/ListingPageContainer'
import { parseItemDto } from '@complex/ListingPage/Utils/ListingPage.utils'
import { SectionType } from '@components/ItemActivity/ItemActivity'
import PageHeaderOption from '@components/PageHeaderOption/PageHeaderOption'
import SegmentDetailsContainer from '@components/SegmentDetails/SegmentDetailsContainer'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import { TextWeight } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import {
  CustomDataJoinViewListingPageContext,
  customDataJoinViewListingPageInitialState,
  CustomDataJoinViewListingPageState,
} from '@src/pages/listingPages/CustomDataJoinView/utils/CustomDataJoinViewListingPage.context'
import { getCustomEmptyListingProps } from '@src/pages/listingPages/CustomDataJoinView/utils/CustomDataJoinViewListingPage.filters'
import { CustomObject } from '@src/pages/listingPages/CustomDataJoinView/utils/CustomDataJoinViewListingPage.interfaces'
import { tableProps } from '@src/pages/listingPages/CustomDataJoinView/utils/CustomDataJoinViewListingPage.tables'
import {
  CUSTOM_DATA_URL,
  iconByItemType,
  listingPageHeaderByItemType,
} from '@src/pages/listingPages/FormsJoinView/FormsJoinViewListingPageContainer.constants'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import { allCustomDataFilter } from '@utils/filter'
import { useCategorizationService } from '@utils/hooks/microservices/useCategorizationService'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'

import './CustomDataJoinViewListingPage.css'

interface CustomDataJoinViewListingPageContainerProps {
  className?: string
  dataTest?: string
}

const rootClass = 'custom-data-join-view-listing-page'

export const CustomDataJoinViewListingPageContainer: FC<CustomDataJoinViewListingPageContainerProps> = (
  props: CustomDataJoinViewListingPageContainerProps
) => {
  const { dataTest = rootClass } = props

  const [state, setState] = useState<CustomDataJoinViewListingPageState>(customDataJoinViewListingPageInitialState)
  const { selectedCustomObject } = state

  const { hasAOCWebinars, userAllowedToCreate } = useAccountSettings()

  const history = useHistory()

  const { t } = useTranslation()

  const update = useDeepUpdate(setState)

  const { getItemRequest } = useCategorizationService()

  const sidebarProps: SidebarProps = {
    sidebarHeader: 'Custom Data',
    hasRecent: false,
    hasCreatedByMe: false,
    allItemFilter: allCustomDataFilter,
    customFilterSelected: false,
  }

  const onRowClicked = (selectedItem: Row<CustomObject>) => {
    const customObject = selectedItem.original
    update({ selectedCustomObject: customObject })
    new URLSearchParams(history.location.search).set('externalId', customObject.externalId ?? '')
    history.push(
      `${rootContext}/${CUSTOM_DATA_URL}/details?externalId=${customObject.externalId}&type=${ItemType.CUSTOM_OBJECT}&backTo=${CUSTOM_DATA_URL}`
    )
  }

  useEffect(() => {
    const showDetails = history.location.pathname.includes('/details')
    if (showDetails) {
      if (showDetails && !selectedCustomObject) {
        const search = new URLSearchParams(window.location.search)
        const externalId = search.get('externalId')
        const type = search.get('type')
        if (externalId && type) {
          getItemRequest({ type: ItemType.CUSTOM_OBJECT, externalId }).then((customObject) => {
            if (customObject) {
              update({ selectedCustomObject: parseItemDto<CustomObject>(customObject) })
            }
          })
        }
      }
    } else {
      update({ selectedCustomObject: undefined })
    }
  }, [history.location])

  const renderPageHeaderOption = useCallback((type: string) => {
    const icon = iconByItemType[type]
    return {
      value: type,
      labelNode: (
        <PageHeaderOption
          key={`${type}-option`}
          text={listingPageHeaderByItemType[type]}
          isSelected={type === ItemType.CUSTOM_OBJECT}
          selectedIcon={icon.selected}
          unselectedIcon={icon.unselected}
        />
      ),
    }
  }, [])

  const pageHeaderProps: PageHeaderProps = useMemo(
    () => ({
      pageTitle: t('Audience Center'),
    }),
    [hasAOCWebinars, history, renderPageHeaderOption, t]
  )

  const getCustomObjectDetailsSections = (): SectionType[] => {
    if (selectedCustomObject) {
      const { schema_name, schema_id, dataset_name, dataset_id } = selectedCustomObject

      const renderDetailsSection = (text: string) => (
        <TextWithTooltipOnEllip
          typographyProps={{
            text,
            values: selectedCustomObject,
            tagProps: { medium: { weight: TextWeight.MEDIUM } },
            inline: true,
          }}
        />
      )

      return [
        {
          children: (
            <div className={`${rootClass}__custom-object-details`}>
              {schema_name && renderDetailsSection(`CustomDataJoinView.Details.TechnicalDetails.SchemaName`)}
              {schema_id && renderDetailsSection(`CustomDataJoinView.Details.TechnicalDetails.SchemaID`)}
              {dataset_name && renderDetailsSection(`CustomDataJoinView.Details.TechnicalDetails.DatasetName`)}
              {dataset_id && renderDetailsSection(`CustomDataJoinView.Details.TechnicalDetails.DatasetID`)}
            </div>
          ),
          title: t(`CustomDataJoinView.Details.TechnicalDetails`, selectedCustomObject),
        },
      ]
    } else {
      return []
    }
  }

  return (
    <CustomDataJoinViewListingPageContext.Provider value={{ update, values: state }}>
      {selectedCustomObject ? (
        <SegmentDetailsContainer
          customTabsTitles={{ details: t(`CustomDataJoinView.Details.Title`, selectedCustomObject) }}
          pageHeader={selectedCustomObject.dataset_name}
          detailsActivityCustomSections={getCustomObjectDetailsSections()}
          canExport={false}
          canSendMessages={false}
        />
      ) : (
        <ListingPageContainer
          listingPageProps={{
            hasTabs: true,
            canDeleteItems: false,
            canEdit: userAllowedToCreate,
            canCreate: userAllowedToCreate,
            hideDeleteItems: true,
            getCustomEmptyListingProps,
            searchFields: ['schema_name', 'dataset_name'],
            sidebarProps,
            pageHeaderProps,
            tableProps: tableProps(t, onRowClicked),
          }}
          dataTest={dataTest}
          itemType={ItemType.CUSTOM_OBJECT}
        />
      )}
    </CustomDataJoinViewListingPageContext.Provider>
  )
}
