import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { ButtonProps } from '@components/Button/Button'
import ButtonWithLoader from '@components/ButtonWithLoader/ButtonWithLoader'
import Caution from '@components/Caution/Caution'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import Container from '@components/Container'
import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography, { TextAlign, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { TestResponseEntity } from '@graphql/types/microservice/webhooks-incoming-management-types'
import StatusMessage from '@src/pages/Webhooks/IncomingWebhooks/components/StatusMessage/StatusMessage'
import TestWebhookResponse from '@src/pages/Webhooks/IncomingWebhooks/components/TestWebhookModal/components/TestWebhookResponse/TestWebhookResponse'
import TestWebhookURL from '@src/pages/Webhooks/IncomingWebhooks/components/TestWebhookModal/components/TestWebhookURL/TestWebhookURL'
import { useIncomingWebhooksRequests } from '@src/pages/Webhooks/IncomingWebhooks/GraphQL/IncomingWebhooks.graphQL'
import { IncomingWebhook } from '@src/pages/Webhooks/IncomingWebhooks/IncomingWebhooksContext'

import './TestWebhookModal.css'

interface TestWebhookModalProps {
  className?: string
  dataTest?: string
  onCancel: (inTest: boolean) => void
  onDone: (success: boolean) => void
  isOpen: boolean
  webhook: IncomingWebhook
  isNew?: boolean
  onEdit: (webhook: IncomingWebhook) => void
}

enum TestStatus {
  INIT,
  WAITING,
  SUCCESS,
  FAILURE,
}

interface TestWebhookModalState {
  status: TestStatus
  testResponse?: TestResponseEntity
  confirmModalOpen: boolean
  goEdit: boolean
}

const defaultState = {
  waiting: false,
  status: TestStatus.INIT,
  confirmModalOpen: false,
  goEdit: false,
}

const rootClass = 'test-webhook-modal'
const CHECK_TEST_INTERVAL = 5000

const TestWebhookModal: FC<TestWebhookModalProps> = (props: TestWebhookModalProps) => {
  const { dataTest = rootClass, className = '', isOpen, onCancel, onDone, onEdit, webhook, isNew = false } = props

  const [state, setState] = useState<TestWebhookModalState>(defaultState)
  const { status, testResponse, confirmModalOpen, goEdit } = state

  const showNewWebhookMessage = useRef(isNew)

  const { getTestData, removeTestData, updateWebhookTestedStatus } = useIncomingWebhooksRequests()

  const { t } = useTranslation()

  const checkTestData = (interval: NodeJS.Timeout) => {
    getTestData(webhook.id).then(({ data }) => {
      if (data?.hasTestData?.hasResults) {
        clearInterval(interval)
        setState({
          ...state,
          status: data.hasTestData.statusCode === '200' ? TestStatus.SUCCESS : TestStatus.FAILURE,
          testResponse: data.hasTestData,
        })
      }
    })
  }

  const onDoneClick = () => {
    if (status === TestStatus.SUCCESS) {
      removeTestData(webhook.id)
      updateWebhookTestedStatus(webhook.id, true)
      onDone(true)
    } else {
      onDone(false)
    }
  }

  const onTestCancel = () => {
    setState({ ...state, confirmModalOpen: true })
  }

  const onEditClick = () => {
    setState({ ...state, confirmModalOpen: true, goEdit: true })
  }

  const onConfirmAnswer = (answer: YesNo) => {
    if (answer === YesNo.YES) {
      if (goEdit) {
        onEdit(webhook)
      } else {
        onCancel(status === TestStatus.WAITING)
      }
    }
    setState({ ...state, confirmModalOpen: false })
  }

  const onRunTestClick = () => {
    updateWebhookTestedStatus(webhook.id, false)
    setState({ ...state, status: TestStatus.WAITING })
    showNewWebhookMessage.current = false
  }

  useEffect(() => {
    if (status === TestStatus.WAITING) {
      const interval = setInterval(() => checkTestData(interval), CHECK_TEST_INTERVAL) as NodeJS.Timeout
      return () => clearInterval(interval)
    }
  }, [status])

  useEffect(() => {
    if (!isOpen) {
      setState(defaultState)
    }
  }, [isOpen])

  const renderLoader = () => {
    return (
      <div className={`${rootClass}__waiting_test`}>
        <Loader loaderType={LoaderTypes.page} />
        <Typography text={t('Incoming.Webhooks.Test.Waiting')} />
      </div>
    )
  }

  const renderInit = () => {
    return (
      <div>
        {showNewWebhookMessage.current && (
          <StatusMessage
            className={`${rootClass}__webhook-created-message`}
            header={t('Incoming.Webhooks.Test.CreatedWebhook.Header')}
            subheader={t('Incoming.Webhooks.Test.CreatedWebhook.Subheader')}
          />
        )}
        <Container className={`${rootClass}__conf-container`}>
          <Typography
            text={t('Incoming.Webhooks.Test.Step.One.Title')}
            type={TextType.BODY_TEXT_LARGE}
            weight={TextWeight.MEDIUM}
            textAlign={TextAlign.CENTER}
          />
          <Typography
            className={`${rootClass}__description`}
            text={t(isNew ? 'Incoming.Webhooks.Test.Step.One.Description.New' : 'Incoming.Webhooks.Test.Step.One.Description')}
            type={TextType.BODY_TEXT_LIGHT}
            weight={TextWeight.REGULAR}
            textAlign={TextAlign.CENTER}
          />
          <TestWebhookURL url={webhook.url} />
          {isNew && (
            <Caution className={`${rootClass}__caution`}>
              <Typography text={`Incoming.Webhooks.Test.Caution`} tagProps={{ bold: { weight: TextWeight.BOLD } }} inline />
            </Caution>
          )}
        </Container>
        <div className={`${rootClass}__container`}>
          <Typography
            text={t('Incoming.Webhooks.Test.Step.Two.Title')}
            type={TextType.BODY_TEXT_LARGE}
            weight={TextWeight.MEDIUM}
            textAlign={TextAlign.CENTER}
          />
          <Typography
            className={`${rootClass}__description`}
            text={t('Incoming.Webhooks.Test.Step.Two.Description')}
            type={TextType.BODY_TEXT_LIGHT}
            weight={TextWeight.REGULAR}
            textAlign={TextAlign.CENTER}
          />
          <div className={`${rootClass}__icons`}>
            <Svg type={SvgType.SUPER_LARGE_ICON} name={SvgNames.webhook} />
            <Svg className={`${rootClass}__arrow-icon`} type={SvgType.LARGE_ICON} name={SvgNames.arrowRight} />
            <Svg type={SvgType.SUPER_LARGE_ICON} name={SvgNames.application} />
          </div>
        </div>
      </div>
    )
  }

  const renderTestResponse = (success: boolean) => {
    return <TestWebhookResponse url={webhook.url} success={success} testResponse={testResponse} onEditClick={onEditClick} />
  }

  const renderWaitingButtons = (loading: boolean) => {
    return (
      <>
        <ButtonWithLoader dataTest={`${dataTest}-test-button`} onClick={onRunTestClick} loading={loading}>
          {t('Run test')}
        </ButtonWithLoader>
        <Button buttonType={ButtonType.TERTIARY} onClick={onTestCancel} dataTest={`${dataTest}-cancel-button`}>
          {t(loading ? 'Cancel' : 'Skip')}
        </Button>
      </>
    )
  }

  const renderResponseButtons = () => {
    return (
      <>
        <Button className={`${rootClass}__test-button`} buttonType={ButtonType.PRIMARY} onClick={onDoneClick} dataTest={`${dataTest}-test-button`}>
          {t('Done')}
        </Button>
        <Button
          className={`${rootClass}__retry-button`}
          buttonType={ButtonType.FLOAT_TEAL}
          onClick={onRunTestClick}
          dataTest={`${dataTest}-retry-button`}
        >
          <Svg name={SvgNames.refresh} fill={SvgColor.TEXT_TEAL} type={SvgType.ICON} />
          {t('Retry')}
        </Button>
      </>
    )
  }

  const renderContent: { [key in TestStatus]: () => ReactNode } = {
    [TestStatus.INIT]: () => renderInit(),
    [TestStatus.WAITING]: () => renderLoader(),
    [TestStatus.SUCCESS]: () => renderTestResponse(true),
    [TestStatus.FAILURE]: () => renderTestResponse(false),
  }

  const renderFooterButtons: { [key in TestStatus]: () => React.ReactElement<ButtonProps> } = {
    [TestStatus.INIT]: () => renderWaitingButtons(false),
    [TestStatus.WAITING]: () => renderWaitingButtons(true),
    [TestStatus.SUCCESS]: () => renderResponseButtons(),
    [TestStatus.FAILURE]: () => renderResponseButtons(),
  }

  const header = (
    <ModalHeader
      headerType={ModalHeaderType.Basic}
      closeButton={onTestCancel}
      className={classNames(`${rootClass}__header`, { [`${rootClass}__header-hidden`]: isNew })}
    >
      <Typography text={t('Incoming.Webhooks.Test.Title')} type={TextType.PAGE_HEADER} weight={TextWeight.BOLD} />
    </ModalHeader>
  )

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
      <ModalBody>{renderContent[status]()}</ModalBody>
      <ModalFooter footerType={ModalFooterType.Basic} className={`${rootClass}__footer`}>
        {renderFooterButtons[status]()}
      </ModalFooter>
      <ConfirmationModal
        isYesNo
        isOpen={confirmModalOpen}
        title={t('Are you sure?')}
        noButtonText={"Don't skip"}
        yesButtonText={'Yes, skip it'}
        body={
          <>
            <Typography text={t('Incoming.Webhooks.Test.Skip.1stSentence')} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.BOLD} />
            <Typography text={t('Incoming.Webhooks.Test.Skip.2stSentence')} type={TextType.BODY_TEXT_LIGHT} />
          </>
        }
        onAnswer={onConfirmAnswer}
      />
    </Modal>
  )
}

export default TestWebhookModal
