import React, { FC } from 'react'

import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { TextType } from '@components/Typography/Typography'
import { useAllUsers } from '@src/pages/Settings/Users/context/UsersContext'
import { SALES_USERS_CAN_DO_LINK } from '@src/pages/Settings/Users/tabs/SalesUsers/constants/salesUsers.constants'

const rootClass = 'sales-users__sub-header'
const dataTest = rootClass

const SalesUsersSubHeader: FC = () => {
  const { isAdministrator } = useAllUsers()
  return (
    <div className={`${rootClass}-container`}>
      <Typography
        text="Sales.Users.Sub.Header.Description"
        inline
        type={TextType.BODY_TEXT_LIGHT}
        dataTest={`${dataTest}-description`}
        values={{
          context: isAdministrator ? 'admin' : 'nonAdmin',
        }}
        tagComponents={{
          TextLink: <TextLink link={SALES_USERS_CAN_DO_LINK} size={TextLinkSize.LARGE} dataTest={`${dataTest}-text-link`} />,
        }}
      />
    </div>
  )
}

export default SalesUsersSubHeader
