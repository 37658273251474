import React, { FC } from 'react'

import classNames from 'classnames'

import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'

import './DataWithArrow.css'

interface Props {
  text: string
  changePercent?: string
  vsTitle?: string
}

const rootClass = 'data-with-arrow'

const DataWithArrow: FC<Props> = ({ text, changePercent, vsTitle }: Props) => {
  const getArrow = (pctString: string) => {
    if (parseInt(pctString) === 0) {
      return (
        <div className={`${rootClass}__change-no-arrow`} aria-label="change-no-arrow">
          0
        </div>
      )
    }

    const isDecrease = pctString[0] === '-'
    const directionClass = `${rootClass}__change-arrow-${isDecrease ? 'down' : 'up'}`
    return (
      <Svg
        name={isDecrease ? SvgNames.arrowDown : SvgNames.arrowUp}
        type={SvgType.ICON}
        className={classNames(`${rootClass}__change-arrow`, directionClass)}
      />
    )
  }

  return (
    <div key={text}>
      <Typography text={text} weight={TextWeight.BOLD} inline />
      {changePercent && (
        <>
          {getArrow(changePercent)}
          <Typography text={changePercent} type={TextType.BODY_TEXT_SMALL} inline />
          <Typography text={` VS `} type={TextType.BODY_TEXT_LIGHT_TINY} inline />
          <Typography text={vsTitle} type={TextType.BODY_TEXT_SMALL_LIGHT} inline />
        </>
      )}
    </div>
  )
}

export default DataWithArrow
