import React, { useMemo } from 'react'

import classNames from 'classnames'

import { ActionType } from '@components/TableV2/tableV2TS/enums'
import { Row } from '@tanstack/react-table'

import { TableV2RowDropDownActions } from './components/TableV2RowDropDownActions/TableV2RowDropDownActions'
import { TableV2RowPrimaryAction } from './components/TableV2RowPrimaryAction/TableV2RowPrimaryAction'
import { RowAction } from '../../tableV2TS/interfaces'
import { splitActions } from '../../utils/tableV2Utils'

import './TableV2RowAction.css'

interface Props<T> {
  row: Row<T>
  className?: string
  dataTest?: string
  isVisible: boolean
  rowActions: RowAction<T>[]
  onToggleDropDown?: (open: boolean) => void
}

const rootClass = 'tableV2-row-action'

export const TableV2RowAction = <T extends {}>({ row, isVisible, rowActions, className = '', dataTest = rootClass, onToggleDropDown }: Props<T>) => {
  const [primaryRowActions, secondaryRowActions] = useMemo(() => splitActions(rowActions, row, ActionType.Row), [rowActions, row])

  const renderPrimaryActions = useMemo(
    () =>
      primaryRowActions.map((action, index) => (
        <TableV2RowPrimaryAction key={`primary-${index}`} dataTest={dataTest} rowAction={action as RowAction<T>} row={row} />
      )),
    [primaryRowActions, row]
  )

  const renderSecondaryActions = useMemo(() => {
    if (secondaryRowActions.length > 0) {
      return (
        <TableV2RowDropDownActions
          dataTest={dataTest}
          rowActions={secondaryRowActions as RowAction<T>[]}
          row={row}
          onToggleDropDown={onToggleDropDown}
        />
      )
    }
  }, [secondaryRowActions, row])

  if (!primaryRowActions.length && !secondaryRowActions.length) {
    return null
  }

  return (
    <div className={classNames(rootClass, className, { [`${rootClass}__visible`]: isVisible })}>
      {renderPrimaryActions}
      {renderSecondaryActions}
    </div>
  )
}
