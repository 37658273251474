import React, { FC, useCallback, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import { SvgNames } from '@components/Svg'
import Typography from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { prevPageLinks } from '@src/pages/EmailComposer/utils/EmailComposer.constants'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { useEmailComposerRequests } from '@utils/composer/emailComposer/GraphQL/EmailComposerRequests.graphQL'

interface ABTestBlockedModalProps {
  isSent?: boolean
  dataTest?: string
}

const rootClass = 'abTest-blocked-modal'

const ABTestBlockeModal: FC<ABTestBlockedModalProps> = (props: ABTestBlockedModalProps) => {
  const { dataTest = rootClass, isSent } = props

  const {
    values: {
      message: { publishId },
    },
    api: { update },
  } = useContext(EmailComposerContext)

  const { t } = useTranslation()
  const history = useHistory()

  const { stopAbTestMessageRequest } = useEmailComposerRequests()

  const handleClose = useCallback(() => {
    if (window.opener) {
      window.close()
    } else {
      history.push(prevPageLinks.abTest)
    }
  }, [history])

  const handleStopAbTest = useCallback(() => {
    if (publishId) {
      stopAbTestMessageRequest(publishId).then((data) => {
        if (data?.stopAbTestMessage?.status === 'ok') {
          update({ message: { isAbTestRunning: false } })
        }
      })
    }
  }, [publishId, stopAbTestMessageRequest])

  return (
    <ConfirmationModal
      isOpen
      dataTest={dataTest}
      className={rootClass}
      title={t(isSent ? 'A/B.Test.BlockedModal.Running.Title' : 'A/B.Test.BlockedModal.Scheduled.Title')}
      isYesNo={!isSent}
      okButtonText={t('Got it')}
      titleIcon={SvgNames.warning}
      yesButtonText={t('A/B.Test.BlockedModal.Scheduled.YES')}
      body={
        <Typography
          className="push-down-x2 push-up-x2"
          text={t(isSent ? 'A/B.Test.BlockedModal.Running.Body' : 'A/B.Test.BlockedModal.Scheduled.Body')}
        />
      }
      onAnswer={(answer) => {
        if (answer === YesNo.YES) {
          handleStopAbTest()
        } else {
          handleClose()
        }
      }}
      closeModal={handleClose}
    />
  )
}

export default ABTestBlockeModal
