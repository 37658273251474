import React, { ChangeEvent, FC, useCallback, useRef, useState } from 'react'

import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import Caution from '@components/Caution/Caution'
import { Checkbox } from '@components/Checkbox/Checkbox'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import InputWithStatus from '@components/InputWithStatus/InputWithStatus'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { MARKETING_DOMAIN_NEED_HELP_LINK, MARKETING_DOMAIN_SHOW_HOW_LINK } from '@src/pages/Content/Branding/tabs/WebIdentity/webIdentity.constants'

import './MarketingDomainForm.css'

interface Props {
  domain: string
  isInvalid: boolean
  onSave: (url: string) => void
  onReset: () => void
  onCancel: () => void
  dataTest?: string
}

const rootClass = 'marketing-domain__form'

const MarketingDomainForm: FC<Props> = (props) => {
  const { domain, isInvalid, onSave, onReset, onCancel, dataTest = rootClass } = props
  const { t } = useTranslation()
  const stateRef = useRef<{ domain: string; unSelectCheckbox: boolean }>({ domain, unSelectCheckbox: !isInvalid && !!domain })
  const [disableSave, setDisableSave] = useState<boolean>(true)
  const [checked, setChecked] = useState<boolean>(isInvalid || !!domain)
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false)

  const handleReset = useCallback(() => setOpenConfirmation(true), [])
  const handleSave = useCallback(() => {
    !stateRef.current.domain ? handleReset() : onSave(stateRef.current.domain)
  }, [onSave, handleReset])

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (stateRef.current.unSelectCheckbox) {
        setChecked(false)
        stateRef.current.unSelectCheckbox = false
      }
      const newDomain = e.target.value
      stateRef.current.domain = newDomain
      setDisableSave(domain === newDomain)
    },
    [domain]
  )

  const onCheckboxChange = useCallback((check: boolean) => {
    setChecked(check)
  }, [])

  const handleAnswer = useCallback(
    (answer: YesNo) => {
      if (answer === YesNo.YES) {
        onReset()
      }
      setOpenConfirmation(false)
    },
    [onReset]
  )

  const hasError = isInvalid && disableSave

  return (
    <div className={rootClass} data-test={dataTest}>
      <Caution
        message={
          <Typography
            text={'Marketing.Domain.Form.Caution'}
            tagProps={{ b: { weight: TextWeight.MEDIUM, inline: true } }}
            tagComponents={{
              TextLink: <TextLink link={MARKETING_DOMAIN_SHOW_HOW_LINK} size={TextLinkSize.LARGE} dataTest={`${dataTest}-show-me-link`} />,
            }}
            dataTest={`${dataTest}-description`}
          />
        }
        className={`${rootClass}__caution`}
        dataTest={dataTest}
      />
      <div className={`${rootClass}__content`}>
        <div className={`${rootClass}-domain-label`}>
          <Typography text={t('Custom marketing domain')} weight={TextWeight.MEDIUM} />
          {!!domain && (
            //If user do not have set URL the “Reset” button will be hidden because there is nothing to reset.
            <Tooltip
              trigger={
                <Button
                  onClick={handleReset}
                  buttonType={ButtonType.TEXT_TEAL}
                  iconPosition={ButtonIconPosition.LEFT}
                  dataTest={`${rootClass}-reset-button`}
                >
                  <Svg name={SvgNames.reset} type={SvgType.LARGER_ICON} />
                  {t('Reset')}
                </Button>
              }
              align="end"
              className={`${rootClass}__reset-tooltip`}
            >
              {t('Marketing.Domain.Reset.Tooltip')}
            </Tooltip>
          )}
        </div>
        <InputWithStatus
          /* eslint-disable-next-line jsx-a11y/no-autofocus */
          autoFocus
          value={domain}
          onChange={onInputChange}
          tooltipProps={{ isPopover: true }}
          hasCustomError={hasError}
          showStatus={hasError}
          customTooltipErrorMessages={
            <Typography
              text="Marketing.Domain.Error.Tooltip"
              type={TextType.BODY_TEXT_WHITE_SMALL}
              lineHeight={LineHeight.MEDIUM_SMALL}
              tagProps={{ b: { weight: TextWeight.BOLD, inline: true } }}
              tagComponents={{
                TextLink: (
                  <TextLink
                    link={MARKETING_DOMAIN_NEED_HELP_LINK}
                    weight={TextWeight.REGULAR}
                    isWhite
                    hideIcon
                    underline
                    className={`${rootClass}__tooltip-link`}
                    data-test={`${dataTest}-show-me-link`}
                  />
                ),
              }}
            />
          }
          className={`${rootClass}__input`}
          dataTest={`${rootClass}-input`}
        />
        <div className={`${rootClass}__checkbox-wrapper`}>
          <Checkbox dataTest={`${dataTest}-checkbox`} onChange={onCheckboxChange} checked={checked} />
          <Typography
            text={'Marketing.Domain.Form.Checkbox.Msg'}
            tagComponents={{
              TextLink: <TextLink link={MARKETING_DOMAIN_SHOW_HOW_LINK} size={TextLinkSize.LARGE} hideIcon dataTest={`${dataTest}-steps-link`} />,
            }}
          />
        </div>
        <div className={`${rootClass}__actions`}>
          <Button buttonType={ButtonType.PRIMARY} onClick={handleSave} disabled={disableSave || !checked} dataTest={`${dataTest}-save-button`}>
            {t('Save')}
          </Button>
          <Button buttonType={ButtonType.TEXT} onClick={onCancel} dataTest={`${dataTest}-cancel-button`}>
            {t('Cancel')}
          </Button>
        </div>
      </div>
      <ConfirmationModal
        isYesNo
        yesButtonText={'Reset domain'}
        isOpen={openConfirmation}
        title={t('Are you sure?')}
        body={t('Reset.Marketing.Domain.Modal.Description')}
        onAnswer={handleAnswer}
        dataTest={`${dataTest}-confirmation-modal`}
      />
    </div>
  )
}

export default MarketingDomainForm
