import React, { FC, useContext } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/Button'
import SectionHeadline from '@components/SectionHeadline'
import { SvgNames } from '@components/Svg'
import Svg, { SvgType } from '@components/Svg/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { FormManagerContext } from '@src/pages/Content/Forms/FormManager/context/FormManager.context'
import { useAccountSettings } from '@utils/account/account.utils'
import { getStandardFormattedDate } from '@utils/date'
import { openFormBuilderWindow } from '@utils/forms'

import './FormManagerFormDetails.css'

interface FormManagerFormDetailsProps {
  className?: string
  dataTest?: string
}

const rootClass = 'form-manager-form-details'

const FormManagerFormDetails: FC<FormManagerFormDetailsProps> = (props: FormManagerFormDetailsProps) => {
  const { dataTest = rootClass, className = '' } = props
  const {
    onOpenBuilderWindow,
    values: {
      formId,
      title,
      description,
      classicFormsEnabled,
      isClassic,
      expirationDate,
      connectedCrm,
      connectedWebinar,
      createdBy,
      createdAt,
      lastUpdatedBy,
      updatedAt,
      canEdit,
    },
  } = useContext(FormManagerContext)
  const { t } = useTranslation()
  const { hasReactWrapper } = useAccountSettings()

  const openEditor = () => {
    const builderWindow = openFormBuilderWindow(isClassic ? 'editClassicForm' : 'editNewForm', formId, '', hasReactWrapper, isClassic)
    builderWindow && onOpenBuilderWindow(builderWindow)
  }

  const renderBlock = (label: string, text: string | undefined) => (
    <div className={`${rootClass}__block`}>
      <Typography text={t(label)} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.MEDIUM} className={`${rootClass}__block-label`} />
      <Typography
        text={text}
        type={TextType.BODY_TEXT_LIGHT}
        className={`${rootClass}__block-text`}
        dataTest={`${dataTest}-block-${label.replace(' ', '-')}`}
      />
    </div>
  )

  const createdByText = createdBy
    ? t('UserTimestamp', { date: getStandardFormattedDate(createdAt), user: createdBy })
    : getStandardFormattedDate(createdAt)
  const lastUpdatedByText = lastUpdatedBy
    ? t('UserTimestamp', { date: getStandardFormattedDate(updatedAt), user: lastUpdatedBy })
    : getStandardFormattedDate(updatedAt)

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <SectionHeadline dataTest={`${dataTest}-section-headline`}>{t('Form Properties')}</SectionHeadline>
      {renderBlock('Name', title)}
      {description && renderBlock('Description', description)}
      {classicFormsEnabled && renderBlock('Form type', t(`${isClassic ? 'Classic' : 'New'} form`))}
      {!!expirationDate && renderBlock('Expiration date', getStandardFormattedDate(expirationDate, true))}
      {connectedCrm && renderBlock('CRM connected', connectedCrm)}
      {connectedWebinar && renderBlock('Webinar connected', connectedWebinar)}
      {renderBlock('Created', createdByText)}
      {renderBlock('Last updated', lastUpdatedByText)}
      <Button
        buttonType={ButtonType.INFO}
        onClick={openEditor}
        disabled={!canEdit}
        title={canEdit ? '' : 'Ask your administrator for permission to do this'}
      >
        <Svg name={SvgNames.pencil} type={SvgType.MEDIUM_LARGE_ICON} className={`${rootClass}__button-svg`} />
        <Typography text={t('Edit form details')} type={TextType.NORMAL_TEXT_TEAL_LARGE} weight={TextWeight.MEDIUM} />
      </Button>
    </div>
  )
}

export default FormManagerFormDetails
