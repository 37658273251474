import React from 'react'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { TextWeight } from '@components/Typography/Typography'
import { OptInForm } from '@graphql/types/microservice/sms-management-types'

import { rootClassFormOptIns } from './FormOptInsLists'
import { renderDateCellV2, renderWithIcon } from '../../MarketingListOptIns.utils'

export const renderFormIcon = () => <Svg name={SvgNames.form} type={SvgType.LARGER_ICON} />

export const formOptInsColumns: ColumnDefWithAdditionalProps<OptInForm>[] = [
  {
    header: 'Form',
    accessorKey: 'formTitle',
    textAlign: 'left',
    cell: (cell) => renderWithIcon(`${rootClassFormOptIns}__segment-with-icon`, cell, renderFormIcon(), TextWeight.MEDIUM),
  },
  {
    header: 'Appended To',
    accessorKey: 'listDisplayName',
    textAlign: 'left',
    maxSize: 230,
  },
  {
    header: 'Opt-Ins',
    accessorKey: 'optInCount',
    textAlign: 'right',
    maxSize: 120,
  },
  {
    header: 'Latest Opt-In Added',
    accessorKey: 'latestOptInDate',
    textAlign: 'right',
    maxSize: 200,
    cell: (cell) => renderDateCellV2(cell),
  },
]
