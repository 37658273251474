import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Input from '@components/Input/Input'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import TextArea from '@components/TextArea/TextArea'
import Typography, { ModalHeaderListStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import AlertFilters from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertFilters/AlertFilters'
import AlertRecipients from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertRecipients/AlertRecipients'
import AlertSchedule from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertSchedule/AlertSchedule'

import './CreateAlertModal.css'

interface CreateAlertModalProps {
  className?: string
  dataTest?: string
  onCancel: () => void
}

const rootClass = 'create-alert-modal'

const CreateAlertModal: FC<CreateAlertModalProps> = (props: CreateAlertModalProps) => {
  const { dataTest = rootClass, className = '', onCancel } = props

  const { t } = useTranslation()

  const header = (
    <ModalHeader headerType={ModalHeaderType.List} className={`${rootClass}__header`}>
      {t('Create an alert')}
    </ModalHeader>
  )

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen header={header} noPadding allowFullScreen>
      <ModalBody className={`${rootClass}__body`}>
        <div className={`${rootClass}__left-section`}>
          <div className={`${rootClass}__left-section-details`}>
            <div className={`${rootClass}__name`}>
              <Typography
                text={t('Name (required)')}
                tagProps={{ light: { weight: TextWeight.MEDIUM_LIGHT, type: TextType.BODY_TEXT_SMALL_LIGHT } }}
                weight={TextWeight.MEDIUM}
                type={TextType.BODY_TEXT_SMALL}
                inlineBlock
              />
              <Input dataTest={`${dataTest}-input-alert-name`} placeholder={t('Add alert name')} />
            </div>
            <div className={`${rootClass}__description`}>
              <Typography text={t('Description')} weight={TextWeight.MEDIUM} type={TextType.BODY_TEXT_SMALL} />
              <TextArea dataTest={`${dataTest}-input-alert-description`} name={'description'} placeholder={t('Add alert description')} resize />
            </div>
          </div>
          <div className={`${rootClass}__left-section-schedule`}>
            <Typography text={t('Schedule')} {...ModalHeaderListStyle} />
            <Typography className={`${rootClass}__left-section-subtitle`} text={t('Choose when you’d like to be alerted.')} />
            <AlertSchedule dataTest={`${dataTest}-schedule`} />
          </div>
          <div className={`${rootClass}__left-section-filters`}>
            <Typography text={t('Filters')} {...ModalHeaderListStyle} />
            <Typography className={`${rootClass}__left-section-subtitle`} text={t('Add filters to receive alerts that match specific criteria.')} />
            <AlertFilters dataTest={`${dataTest}-filters`} />
          </div>
        </div>
        <div className={`${rootClass}__divider`} />
        <div className={`${rootClass}__recipients`}>
          <Typography className={`${rootClass}__recipients-header`} text={t('Recipients')} {...ModalHeaderListStyle} />
          <AlertRecipients dataTest={`${dataTest}-recipients`} />
        </div>
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.List} className={`${rootClass}__footer`}>
        <Button buttonType={ButtonType.TERTIARY} onClick={onCancel} dataTest={`${dataTest}-button-cancel`}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} onClick={() => undefined} dataTest={`${dataTest}-button-create`}>
          {t('Create')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CreateAlertModal
