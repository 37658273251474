import React, { FC, useCallback, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import classNames from 'classnames'

import MediaCard, { MediaCardProps } from '@components/MediaCard/MediaCard'
import StaticImage from '@components/StaticImage/StaticImage'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { SvgNames } from '@components/Svg'
import { useTranslation } from '@const/globals'

import './ImageMediaCard.css'

export interface ImageMediaCardProps extends Omit<MediaCardProps, 'onMainAction' | 'bodyContent'> {
  imageSrc: string
  imageFill?: boolean
  dataTest?: string
  onSelect: () => void
  onPreview?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const rootClass = 'image-media-card'

const ImageMediaCard: FC<ImageMediaCardProps> = (props) => {
  const { imageSrc, imageFill, mainActionLabel, onSelect, onPreview, dataTest = rootClass } = props
  const { t } = useTranslation()
  const { ref: imgRef, inView } = useInView({ threshold: 0, triggerOnce: true })
  const [loadError, setLoadError] = useState<boolean>(false)

  const handleLoadError = useCallback(() => {
    setLoadError(true)
  }, [])

  const bodyContent = loadError ? (
    <StaticImage name={StaticImageNames.imageLight} dataTest={`${dataTest}-load-error-image`} />
  ) : (
    <img
      ref={imgRef}
      src={inView ? imageSrc : undefined}
      className={classNames(`${rootClass}__image`, {
        [`${rootClass}__image--fill`]: imageFill,
      })}
      alt={''}
      onError={handleLoadError}
      data-test={`${dataTest}-image`}
      loading="lazy"
    />
  )

  const mediaCardProps: MediaCardProps = {
    onMainAction: onSelect,
    bodyContent,
    actions: onPreview ? [{ key: 'preview', icon: SvgNames.zoom, onClick: onPreview, tooltip: t('Preview') }] : undefined,
    ...props,
    mainActionLabel: mainActionLabel ?? t('Select image'),
    className: classNames(rootClass, props.className, { [`${rootClass}--fill`]: imageFill, [`${rootClass}--error`]: loadError }),
  }
  return <MediaCard {...mediaCardProps} />
}

export default ImageMediaCard
