import React, { FC, useEffect, useState } from 'react'

import Button, { ButtonType } from '@components/Button'
import Input from '@components/Input/Input'
import Loader from '@components/Loader'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './CreateSegmentModal.css'

interface CreateSegmentModalProps {
  isOpen: boolean
  errorMessage?: string
  loading?: boolean
  closeModal: () => void
  onSubmit: (name: string) => void
  className?: string
  dataTest?: string
}

const rootClass = 'create-segment-modal'

const CreateSegmentModal: FC<CreateSegmentModalProps> = (props: CreateSegmentModalProps) => {
  const { isOpen, errorMessage, loading, closeModal, onSubmit, dataTest = rootClass, className = '' } = props

  const { t } = useTranslation()
  const [segmentName, setSegmentName] = useState<string>('')
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [error, setError] = useState<string | undefined>()

  useEffect(() => setError(errorMessage), [errorMessage])

  const renderModalHeader = () => {
    return (
      <ModalHeader headerType={ModalHeaderType.Form}>
        <Typography text={t('New segment')} type={TextType.DATA_CARD_MODAL_HEADER} weight={TextWeight.BOLD} dataTest={`${dataTest}-header-text`} />
      </ModalHeader>
    )
  }

  const renderInnerModalBody = () => (
    <>
      <Typography text={t('Segment name')} weight={TextWeight.MEDIUM} type={TextType.BODY_TEXT_SMALL} className={`${rootClass}__text`} />
      <Input
        placeholder={t('New segment 1')}
        value={segmentName}
        dataTest={`${dataTest}-input`}
        error={!!error}
        onChange={(e) => {
          setSegmentName(e.target.value)
          setSubmitDisabled(e.target.value === '')
          setError('')
        }}
      />
      {error && <Typography text={error} type={TextType.ERROR} />}
    </>
  )

  const renderModalBody = () => {
    return (
      <ModalBody className={`${rootClass}__body`} dataTest={`${dataTest}-modal-body`}>
        <>
          {!loading && renderInnerModalBody()}
          {loading && <Loader center />}
        </>
      </ModalBody>
    )
  }

  const renderModalFooter = () => {
    return (
      <ModalFooter>
        <Button buttonType={ButtonType.TERTIARY} onClick={closeModal} dataTest={`${dataTest}-cancel`}>
          {'Cancel'}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} disabled={submitDisabled} onClick={() => onSubmit(segmentName)} dataTest={`${dataTest}-create`}>
          {'Create'}
        </Button>
      </ModalFooter>
    )
  }

  return (
    <div data-test={dataTest} className={className}>
      <Modal isOpen={isOpen} className={rootClass} header={renderModalHeader()}>
        {renderModalBody()}
        {renderModalFooter()}
      </Modal>
    </div>
  )
}

export default CreateSegmentModal
