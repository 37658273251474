import { Dispatch, SetStateAction } from 'react'

import { ItemAsset } from '@components/DependenciesContentModal/components/ContentWarning/ContentWarning'
import { DependenciesContentModalState, DependenciesContentModalItem } from '@components/DependenciesContentModal/DependenciesContentModal'
import { GetAssetsQuery, GetProgramsUsingListsQuery } from '@graphql/types/microservice/segment-types'
import { logNewRelicError } from '@utils/new-relic.utils'
import { FetchPromise } from '@utils/types'

interface BaseParams {
  selectedItems: DependenciesContentModalItem[]
  onDelete: (selectedItems: DependenciesContentModalItem[]) => void
  setState: Dispatch<SetStateAction<DependenciesContentModalState>>
}

interface GetSegmentAssetsRequestUtilsParams extends BaseParams {
  getSegmentAssetsRequest: (itemIds: string[]) => FetchPromise<GetAssetsQuery>
}

interface GetListAssetsRequestUtilsParams extends BaseParams {
  getListAssetsRequest: (itemIds: string[]) => FetchPromise<GetProgramsUsingListsQuery>
}

export const getSegmentAssetsRequestUtils = ({ getSegmentAssetsRequest, selectedItems, onDelete, setState }: GetSegmentAssetsRequestUtilsParams) =>
  getSegmentAssetsRequest(selectedItems.map(({ id }) => id))
    .then(({ data }) => {
      if (data?.getAssets) {
        const assets = data.getAssets as ItemAsset[]

        if (assets.every((item) => item.body.length === 0)) {
          onDelete(selectedItems)
        } else {
          const itemIds = assets.filter((asset) => asset.body.length === 0).map((asset) => asset.objectId)
          setState((state) => ({
            ...state,
            itemsAssetToDelete: assets,
            itemsToDelete: itemIds,
            loaded: true,
          }))
        }
      }
    })
    .catch((error) => {
      setState((state) => ({
        ...state,
        loaded: true,
      }))
      logNewRelicError(error)
    })

export const getListAssetsRequestUtils = ({ getListAssetsRequest, selectedItems, onDelete, setState }: GetListAssetsRequestUtilsParams) =>
  getListAssetsRequest(selectedItems.map(({ id }) => id))
    .then(({ data }) => {
      if (data?.getProgramsUsingLists) {
        const assets = data.getProgramsUsingLists as ItemAsset[]

        if (assets.every((item) => item.body.length === 0)) {
          onDelete(selectedItems)
        } else {
          const itemIds = assets.filter((asset) => asset.body.length === 0).map((asset) => asset.objectId)
          setState((state) => ({
            ...state,
            itemsAssetToDelete: assets,
            itemsToDelete: itemIds,
            loaded: true,
          }))
        }
      }
    })
    .catch((error) => {
      setState((state) => ({
        ...state,
        loaded: true,
      }))
      logNewRelicError(error)
    })
