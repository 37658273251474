import React, { FC } from 'react'

import classNames from 'classnames'

import ButtonIcon, { ButtonIconSize, ButtonIconType } from '@components/ButtonIcon/ButtonIcon'
import { SvgNames } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'

import './PopoverTriggerButton.css'

interface PopoverTriggerButtonProps {
  className?: string
  dataTest?: string
  isOpen?: boolean
  isSelected?: boolean
  onClick?: () => void
  icon: SvgNames
  tooltip?: string
}

const rootClass = 'popover-trigger-button'

const PopoverTriggerButton: FC<PopoverTriggerButtonProps> = (props: PopoverTriggerButtonProps) => {
  const { dataTest = rootClass, className = '', isOpen, isSelected, onClick, icon, tooltip } = props

  return (
    <div>
      <Tooltip
        className={`${rootClass}__tooltip`}
        trigger={
          <ButtonIcon
            onClick={onClick}
            dataTest={dataTest}
            icon={icon}
            size={ButtonIconSize.LARGE}
            type={isOpen ? ButtonIconType.PRIMARY : ButtonIconType.LIGHTER_TEAL_ON_HOVER}
            className={classNames(rootClass, className, {
              [`${rootClass}__open`]: isOpen,
              [`${rootClass}__selected`]: isSelected && !isOpen,
            })}
          />
        }
        position={'top'}
        hide={!tooltip}
      >
        {tooltip}
      </Tooltip>
    </div>
  )
}

export default PopoverTriggerButton
