import React from 'react'

export function useEvent<T>(eventType: string, object: EventTarget = window) {
  const [event, setEvent] = React.useState<Event | null>(null)
  const prevEvent = React.useRef<Event>()

  React.useEffect(() => {
    function onEvent(event: Event) {
      if (eventType === 'message' && typeof (event as any).data === 'string') return
      if (prevEvent?.current && event?.timeStamp - prevEvent.current?.timeStamp < 100) {
        setTimeout(() => setEvent(event), 100)
        return
      }

      prevEvent.current = event
      setEvent(event)
    }

    object.addEventListener(eventType, onEvent)
    return () => object.removeEventListener(eventType, onEvent)
  }, [])

  return event as T | Event
}

export default {
  useEvent,
}
