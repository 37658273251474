import React from 'react'

import classNames from 'classnames'

import {
  ListPageTableActions,
  MAX_STANDARD_ACTIONS_COUNT,
  TableActions,
  TagManagerParams,
} from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.constants'
import { ItemDtoWithBeeComposer, ListPageCommonState, ListPageTableActionCustomProps } from '@complex/ListingPage/Context/ListingPageCommon.context'
import Button, { ButtonType } from '@components/Button'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { HeaderAction } from '@components/TableV2/tableV2TS/interfaces'
import TagManager from '@components/TagManager/TagManager'
import Tooltip from '@components/Tooltip/Tooltip'
import { ItemDto, LabelDto } from '@graphql/types/microservice/categorization-types'
import { Row } from '@tanstack/react-table'
import { ItemType } from '@utils/categorization'

export const renderTagManager = ({ onApplyAndRemoveTags, onCreateTag, t, selectedIds, tags }: TagManagerParams) => {
  const title = 'Add Tags'
  return (
    <TagManager
      appliedTags={[]}
      buttonText={'Add'}
      tags={tags}
      trigger={
        <Tooltip
          trigger={
            <Button buttonType={ButtonType.TABLE_ACTION}>
              <Svg name={SvgNames.tagOutline} />
            </Button>
          }
        >
          {t(title)}
        </Tooltip>
      }
      title={title}
      onApplyAndRemove={(tagsToApply: LabelDto[], tagsToRemove: number[]) => onApplyAndRemoveTags(selectedIds, tagsToApply, tagsToRemove)}
      onCreate={(color, tagName) => onCreateTag({ color, name: tagName })}
    />
  )
}

export const getFavoriteProps = (selectedItems: ItemDto[], rootClass: string) => {
  const isFavorite = !!selectedItems.length && !selectedItems.some(({ isFavorite }) => !isFavorite)
  return {
    label: isFavorite ? 'Remove from Favorites' : 'Add to Favorites',
    icon: () => getFavoriteSvg(isFavorite, rootClass),
  }
}

export const getFavoriteSvg = (isFavorite: boolean, rootClass: string) => (
  <Svg
    dataTest={isFavorite ? 'favorites-selected' : 'favorites-unselected'}
    className={classNames({ [`${rootClass}__favorite-svg-icon`]: isFavorite })}
    name={isFavorite ? SvgNames.favoritesSelected : SvgNames.favoritesUnselected}
    type={SvgType.LARGER_ICON}
  />
)

export const getActionProps = (
  actionName: string | ListPageTableActions,
  actionCustomOrder: ListPageTableActionCustomProps[],
  standardActionsCount?: number
) => {
  const action = actionCustomOrder.filter((action) => action.name === actionName)

  return {
    position: action[0]?.position ?? 0,
    topSection: action[0]?.hasTopSection ?? false,
    inDropdown: standardActionsCount && standardActionsCount >= MAX_STANDARD_ACTIONS_COUNT ? true : action[0]?.isInDropdown ?? false,
  }
}

const sortActions = (actions: HeaderAction & Pick<ListPageTableActionCustomProps, 'position'>[]) => {
  return actions.sort((a, b) => (a.position ?? -1) - (b.position ?? -1))
}

export const getAllHeaderActions = (tableActions: TableActions, tagManagerParams: TagManagerParams, values: ListPageCommonState) => {
  const {
    listingPageProps: {
      tableProps: { getCustomHeaderActions, listPage, headerActionCustomProps },
    },
  } = values
  const labels = ['Remove from Favorites', 'Add to Favorites', 'Move to folder', 'Add to folder', 'Add tags', 'Remove from folder']

  const defaultHeaderActions = getDefaultHeaderActions(tableActions, tagManagerParams, values, headerActionCustomProps)
  const standardIcons = defaultHeaderActions.filter((el) => labels.includes(el.label))
  const customHeaderActions = getCustomHeaderActions?.(tableActions, values.selectedRows, standardIcons.length) ?? []

  const allActions = [...defaultHeaderActions, ...customHeaderActions] as HeaderAction & Pick<ListPageTableActionCustomProps, 'position'>[]

  if (listPage == 'Forms' && !values.selectedRows.length) {
    return sortActions(defaultHeaderActions)
  }

  return sortActions(allActions)
}

export const getDefaultHeaderActions = (
  tableActions: TableActions,
  tagManagerParams: TagManagerParams,
  values: ListPageCommonState,
  headerActionCustomProps: ListPageTableActionCustomProps[]
) => {
  const {
    items,
    selectedRows,
    canShareToCatalog,
    canDeleteItems,
    hideDeleteItems,
    hideMoveItems,
    readOnlyFolders,
    activeFolderId,
    listingPageProps: {
      sidebarProps: { hasFavorites = true, hideTags },
    },
  } = values
  const standardActions = ['MANAGE_TAGS', 'ADD_TO_FAVORITES', !hideMoveItems, activeFolderId].filter((el) => el)

  return [
    {
      label: 'Add tags',
      embeddedComponent: renderTagManager(tagManagerParams),
      ...getActionProps('MANAGE_TAGS', headerActionCustomProps),
      hidden: hideTags,
    },
    ...(hideMoveItems
      ? []
      : [
          {
            label: items?.some(({ folderId }) => folderId) ? 'Move to folder' : 'Add to folder',
            hasTooltip: true,
            icon: SvgNames.moveFolder,
            hidden: readOnlyFolders,
            onClick: tableActions.moveToFolder,
            ...getActionProps('MOVE_TO_FOLDER', headerActionCustomProps),
          },
        ]),
    {
      ...getFavoriteProps(selectedRows, 'list-page-table-container'),
      onClick: tableActions.setFavoriteItems,
      hasTooltip: true,
      hidden: !hasFavorites,
      ...getActionProps('ADD_TO_FAVORITES', headerActionCustomProps),
    },

    ...(activeFolderId
      ? [
          {
            label: 'Remove from folder',
            hasTooltip: true,
            icon: SvgNames.removeFromFolder,
            iconSize: SvgType.LARGER_ICON,
            hidden: readOnlyFolders,
            onClick: tableActions.deleteFromFolder,
            ...getActionProps('REMOVE_FROM_FOLDER', headerActionCustomProps),
          },
        ]
      : []),

    ...(canShareToCatalog
      ? [
          {
            label: `Share to catalog`,
            hasTooltip: true,
            icon: SvgNames.shareToCatalog,
            iconSize: SvgType.LARGER_ICON,
            hidden: (row: Row<ItemDtoWithBeeComposer>) => row?.original?.beeComposer,
            onClick: tableActions.copyToCatalog,
            ...getActionProps('SHARE_TO_CATALOG', headerActionCustomProps, standardActions.length),
          },
        ]
      : []),

    ...(hideDeleteItems
      ? []
      : [
          {
            disabled: !canDeleteItems,
            label: 'Delete items',
            hasTooltip: true,
            tooltipMessage: 'Ask your administrator for permission to do this',
            icon: SvgNames.delete,
            iconSize: SvgType.LARGER_ICON,
            onClick: tableActions.deleteItems,
            ...getActionProps('DELETE', headerActionCustomProps),
          },
        ]),
  ]
}

export const getAllRowActions = (rowActionItems: TableActions, actionCustomOrder: ListPageTableActionCustomProps[], values: ListPageCommonState) => {
  const {
    listingPageProps: {
      tableProps: { getCustomRowActions },
    },
  } = values

  const defaultRowActions = getDefaultRowActions(rowActionItems, actionCustomOrder, values)
  const customRowActions = getCustomRowActions?.(rowActionItems) ?? []
  const allRowActions = [...defaultRowActions, ...customRowActions]

  return allRowActions.sort((a, b) => (a.position ?? -1) - (b.position ?? -1))
}

export const getDefaultRowActions = (
  rowActionItems: TableActions,
  actionCustomOrder: ListPageTableActionCustomProps[],
  values: ListPageCommonState
) => {
  const {
    canShareToChildAccounts,
    disableShareToChildAccountsTooltip,
    canShareToCatalog,
    canDeleteItems,
    hideMoveItems,
    hideDeleteItems,
    canEdit,
    canCreate,
    activeFolderId,
    search,
    searchAll,
    readOnlyFolders,
    listingPageProps: {
      canPreview,
      canDuplicate,
      disableShareToAccounts,
      disableDuplicate,
      tableProps: { listPage },
      sidebarProps: { hasFavorites = true },
    },
  } = values

  return [
    {
      label: ({ original: { isFavorite } }: Row<ItemDto>) => (isFavorite ? 'Remove favorite' : 'Favorite'),
      icon: ({ original: { isFavorite } }: Row<ItemDto>) => getFavoriteSvg(isFavorite ?? false, 'list-page-table-container'),
      hidden: !hasFavorites,
      onClick: (row: Row<ItemDto>) => rowActionItems.clickRowAction(row, 'SET_FAVORITE'),
      ...getActionProps('ADD_TO_FAVORITES', actionCustomOrder),
    },
    {
      hidden: hideMoveItems,
      label: ({ original: { folderId } }: { original: ItemDto }) => (folderId ? 'Move to folder' : 'Add to folder'),
      icon: SvgNames.moveFolder,
      onClick: (row: Row<ItemDto>) => rowActionItems.clickRowAction(row, 'ADD_TO_FOLDER'),
      ...getActionProps('MOVE_TO_FOLDER', actionCustomOrder),
    },
    {
      disabled: !canDeleteItems,
      hidden: hideDeleteItems,
      hasTooltip: true,
      tooltipMessage: 'Ask your administrator for permission to do this',
      label: `Delete`,
      icon: SvgNames.delete,
      onClick: (row: Row<ItemDto>) => rowActionItems.clickRowAction(row, 'DELETE'),
      ...getActionProps('DELETE', actionCustomOrder),
    },
    ...(canPreview
      ? [
          {
            label: `ListPage.${listPage}.Table.Preview`,
            icon: SvgNames.zoom,
            iconSize: SvgType.ICON,
            onClick: (row: Row<ItemDto>) => rowActionItems.clickRowAction(row, 'PREVIEW'),
            ...getActionProps('PREVIEW', actionCustomOrder),
          },
        ]
      : []),
    ...(canDuplicate
      ? [
          {
            disabled: (row: Row<ItemDtoWithBeeComposer>) => {
              return (disableDuplicate && row.original.beeComposer && values.itemType === ItemType.LANDING_PAGE) || !canEdit || !canCreate
            },
            label: 'Duplicate',
            icon: SvgNames.cloneSegment,
            hasTooltip: !!((row: Row<ItemDtoWithBeeComposer>) => {
              return (row.original.beeComposer && values.itemType === ItemType.LANDING_PAGE) || !canEdit || !canCreate
            }),
            tooltipMessage: (row: Row<ItemDtoWithBeeComposer>) =>
              row?.original?.beeComposer ? 'Beta.Emails.Tooltip.Message.Duplicate' : 'Ask your administrator for permission to do this',
            onClick: (row: Row<ItemDto>) => rowActionItems.clickRowAction(row, 'DUPLICATE'),
            ...getActionProps('DUPLICATE', actionCustomOrder),
          },
        ]
      : []),
    ...(canShareToChildAccounts
      ? [
          {
            disabled: (row: Row<ItemDtoWithBeeComposer>) =>
              (row.original.beeComposer && disableShareToAccounts) || !canShareToChildAccounts || !!disableShareToChildAccountsTooltip,
            label: `Share to accounts`,
            icon: SvgNames.share,
            iconSize: SvgType.ICON,
            hasTooltip: true,
            tooltipMessage: (row: Row<ItemDtoWithBeeComposer>) =>
              row?.original?.beeComposer ? 'Beta.Emails.Tooltip.Message' : disableShareToChildAccountsTooltip,
            onClick: (row: Row<ItemDto>) => rowActionItems.clickRowAction(row, 'SHARE_TO_ACCOUNTS'),
            ...getActionProps('SHARE_TO_ACCOUNTS', actionCustomOrder),
          },
        ]
      : []),
    ...(canShareToCatalog
      ? [
          {
            disabled: !canShareToCatalog,
            label: `Share to catalog`,
            icon: SvgNames.shareToCatalog,
            iconSize: SvgType.ICON,
            hidden: (row: Row<ItemDtoWithBeeComposer>) => row?.original?.beeComposer,
            onClick: (row: Row<ItemDto>) => rowActionItems.clickRowAction(row, 'SHARE_TO_CATALOG'),
            ...getActionProps('SHARE_TO_CATALOG', actionCustomOrder),
          },
        ]
      : []),
    ...(activeFolderId || (!!search && searchAll)
      ? [
          {
            label: 'Remove from folder',
            icon: SvgNames.removeFromFolder,
            hidden: ({ original: { folderId } }: { original: ItemDto }) => !folderId || readOnlyFolders,
            onClick: (row: any) => rowActionItems.clickRowAction(row, 'REMOVE_FROM_FOLDER'),
            ...getActionProps('REMOVE_FROM_FOLDER', actionCustomOrder),
          },
        ]
      : []),
  ]
}

export const isInvalidTagName = (tags: LabelDto[], tag: LabelDto) =>
  tags.some(({ id, name }) => tag.id !== id && tag.name?.toLowerCase() === name?.toLowerCase())
