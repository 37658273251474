import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'

export enum PersonalizationModals {
  FALLBACK = 'fallback',
  DEFAULT = 'default',
  ADD_RECIPIENTS = 'addRecipients',
}
export const LP_PERSONALIZATION_LEARN_MORE = 'https://connect.act-on.com/hc/en-us/articles/28614264731031-Personalization-new-Landing-Page-Composer'
export const PERSONALIZATION_LEARN_MORE = 'https://connect.act-on.com/hc/en-us/sections/20975988754327-Email-Personalization'
export const ACCOUNT_DATE_LEARN_MORE = 'https://connect.act-on.com/hc/en-us/articles/4411670090263-Using-Sender-Personalization-Fields'

export const senderFieldCategories = [
  'Message Sender Fields',
  'Account User Fields',
  'Account (Company) Fields',
  'Other Fields',
  'Date Fields',
  'Custom Account Fields',
]

export const STICKY_TOP_POSITION_WITH_BLOCK = -24
export const STICKY_TOP_POSITION = 0

export const AccountDateFormats: SelectV2SingleOption[] = [
  {
    value: 'SHORT',
    label: 'Short format (MM/DD/YY)',
  },
  {
    value: 'MEDIUM',
    label: 'Medium format (Mon DD, YYYY)',
  },
  {
    value: 'LONG',
    label: 'Long format (Month DD, YYYY)',
  },
  {
    value: 'FULL',
    label: 'Full format (Day of week, Month DD, YYYY)',
  },
]
