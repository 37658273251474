import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import Svg, { SvgNames } from '@components/Svg'
import { SvgColor, SvgType } from '@components/Svg/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { DynamicContentListItem, EmailComposerContext } from '@utils/composer/context/EmailComposer.context'

import './EmailComposerPreviewDynamicContentBanner.css'

const rootClass = 'email-composer-preview-dynamic-content-banner'

export const EmailComposerPreviewDynamicContentBanner = () => {
  const {
    values: {
      preview: { dynamicContentList, isPreviewDynamicContentOn },
    },
  } = useContext(EmailComposerContext)

  const { t } = useTranslation()

  const [visibleList, setVisibleList] = useState<DynamicContentListItem[]>([])
  const [showMore, setShowMore] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const bannerRef = useRef<HTMLDivElement>(null)
  const contentSizeRef = useRef<HTMLDivElement>(null)

  const enabledContents = useMemo(() => {
    return dynamicContentList.filter((content) => content.isActive)
  }, [dynamicContentList, isPreviewDynamicContentOn])

  useEffect(() => {
    const handleResize = () => {
      const containerWidth = bannerRef.current?.offsetWidth || 0
      let totalItemWidth = 0
      let visibleItemCount = 0

      for (let i = 0; i < enabledContents.length; i++) {
        if (contentSizeRef.current) {
          const itemWidth = (contentSizeRef.current.childNodes[i] as HTMLDivElement)?.offsetWidth
          totalItemWidth += itemWidth
        }
        if (totalItemWidth + 250 <= containerWidth) {
          visibleItemCount++
        } else {
          break
        }
      }

      setVisibleList(enabledContents.slice(0, visibleItemCount))
      setShowMore(enabledContents.length > visibleItemCount)
    }

    handleResize()

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [enabledContents, isPreviewDynamicContentOn])

  return isPreviewDynamicContentOn ? (
    <>
      <div ref={bannerRef} className={rootClass}>
        <div className={`${rootClass}__previewing-as-label`}>
          <Svg fill={SvgColor.TEXT_TEAL} type={SvgType.LARGE_ICON} name={SvgNames.arrowDiverge} />
          <Typography
            text={t('EmailComposer.Preview.Dynamic_Content.Banner.Preview')}
            lineHeight={LineHeight.MEDIUM_LARGE}
            type={TextType.NORMAL_TEXT_TEAL_LARGE}
            weight={TextWeight.BOLD}
          ></Typography>
        </div>
        {!enabledContents.length ? (
          <div className={`${rootClass}__content-wrapper__item`}>
            <Svg
              name={SvgNames.hideAlt}
              fill={SvgColor.TEXT_TEAL}
              type={SvgType.LARGE_ICON}
              className={`${rootClass}__content-wrapper__icon`}
              dataTest={`${rootClass}__content-wrapper-svg`}
            />
            <Typography
              text={`All display conditions hidden`}
              lineHeight={LineHeight.MEDIUM_LARGE}
              type={TextType.NORMAL_TEXT_TEAL_LARGE}
              weight={TextWeight.MEDIUM}
            ></Typography>
          </div>
        ) : (
          <>
            <div ref={contentSizeRef} className={`${rootClass}__content-size`}>
              {dynamicContentList.map((dynamicContent) => {
                if (!dynamicContent.isActive) {
                  return
                }
                return (
                  <div className={`${rootClass}__content-size__item`} key={dynamicContent.rowIndex}>
                    <Svg
                      name={SvgNames.show}
                      fill={SvgColor.TEXT_TEAL}
                      type={SvgType.LARGE_ICON}
                      className={`${rootClass}__content-wrapper__icon`}
                      dataTest={`${rootClass}__content-wrapper-svg`}
                    />
                    <Typography
                      text={`${dynamicContent.label}`}
                      lineHeight={LineHeight.MEDIUM_LARGE}
                      type={TextType.NORMAL_TEXT_TEAL_LARGE}
                      weight={TextWeight.MEDIUM}
                    ></Typography>
                  </div>
                )
              })}
            </div>
            <div ref={wrapperRef} className={`${rootClass}__content-wrapper`}>
              {visibleList.map((dynamicContent, index) => {
                const isLastItem = index === visibleList.length - 1
                if (!dynamicContent.isActive) {
                  return
                }
                return (
                  <div className={`${rootClass}__content-wrapper__item`} key={dynamicContent.rowIndex}>
                    <Svg
                      name={SvgNames.show}
                      fill={SvgColor.TEXT_TEAL}
                      type={SvgType.LARGE_ICON}
                      className={`${rootClass}__content-wrapper__icon`}
                      dataTest={`${rootClass}__content-wrapper-svg`}
                    />
                    <Typography
                      text={`${dynamicContent.label}${isLastItem ? '' : ','}`}
                      lineHeight={LineHeight.MEDIUM_LARGE}
                      type={TextType.NORMAL_TEXT_TEAL_LARGE}
                      weight={TextWeight.MEDIUM}
                    ></Typography>
                  </div>
                )
              })}
            </div>
            {showMore && (
              <Typography
                text={`+${enabledContents.length - visibleList.length} ${t('More').toLowerCase()}`}
                lineHeight={LineHeight.MEDIUM_LARGE}
                type={TextType.NORMAL_TEXT_TEAL_LARGE}
                weight={TextWeight.MEDIUM}
              />
            )}
          </>
        )}
      </div>
    </>
  ) : null
}

export default EmailComposerPreviewDynamicContentBanner
