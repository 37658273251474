import { ApolloClient } from '@apollo/client'
import { AccountSettings, StringKeyValue } from '@graphql/types/query-types'
import { Action } from '@interface/Action'
import { UserSettings } from '@utils/hooks/useUserSettings'
import { NavigationInterface } from '@utils/navigation/navigation.utils'
import { makeFetchActionCreators } from '@utils/store/fetch'

import { LOG_OUT, NAMESPACE } from './account.utils'
import actionTypes from './actionTypes'
import { THEME } from '../theme'

export interface LoadAccountRequest {
  client: ApolloClient<object>
  loading?: boolean
}

export interface LoadAccountActionType {
  accountSettings?: AccountSettings
  error?: Error | undefined | unknown
  dynamicNav?: NavigationInterface[]
  loggedOut?: boolean
  isAuthorized?: boolean
  loading?: boolean
  token?: string
  accountContext?: string
  microserviceUrls?: StringKeyValue
  userSettings?: UserSettings
}

export const logOutActions = makeFetchActionCreators<undefined, LoadAccountActionType>(`${NAMESPACE}/${LOG_OUT}`)

export type SetLanguageAction = Action<string>
export type SetUserSettingsAction = Action<UserSettings>
export type SetThemeAction = Action<THEME>

export function loadAccount(payload: LoadAccountRequest) {
  return {
    type: actionTypes.loadAccountSettings,
    payload,
  }
}

export function loadAccountReceived(payload: LoadAccountActionType) {
  return {
    type: actionTypes.loadAccountSettingsReceived,
    payload,
  }
}

export function loadAccountFailed(payload: LoadAccountActionType) {
  return {
    type: actionTypes.loadAccountSettingsFailed,
    payload,
  }
}

export function updateUserAuthentication(payload: LoadAccountActionType) {
  return {
    type: actionTypes.updateUserAuthentication,
    payload,
  }
}

export function setLanguage(language: string): SetLanguageAction {
  return {
    type: actionTypes.setLanguage,
    payload: language ? language : 'en',
  }
}

export function setUserSettings(userSettings: UserSettings): SetUserSettingsAction {
  return {
    type: actionTypes.setUserSettings,
    payload: userSettings,
  }
}

export function setTheme(theme: THEME): SetThemeAction {
  return {
    type: actionTypes.setTheme,
    payload: theme,
  }
}

export function setPendingIFrameRequest(iframeMessage?: any): Action {
  return {
    type: actionTypes.setPendingIFrameRequest,
    payload: iframeMessage,
  }
}

export interface CheckUserAuthenticationPayload {
  client: ApolloClient<object>
}

export function checkUserAuthentication(payload: CheckUserAuthenticationPayload): Action<CheckUserAuthenticationPayload> {
  return {
    type: actionTypes.checkUserAuthentication,
    payload,
  }
}

export interface AccountActions {
  loadAccount(payload?: LoadAccountRequest): Action<LoadAccountRequest>
  logOut(): Action
  setPendingIFrameRequest(iframeMessage?: any): Action
  setLanguage(language: string): Action<string>
  setTheme(theme: THEME): Action<THEME>
  checkUserAuthentication(payload: CheckUserAuthenticationPayload): Action<CheckUserAuthenticationPayload>
  setUserSettings(userSettings: UserSettings): Action<UserSettings>
}

const actions: AccountActions = {
  loadAccount: loadAccount,
  logOut: logOutActions.request,
  setPendingIFrameRequest: setPendingIFrameRequest,
  setLanguage,
  setTheme,
  setUserSettings,
  checkUserAuthentication,
}

export default actions
