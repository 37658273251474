import { call, put, takeLatest, takeEvery, all } from 'redux-saga/effects'

import { ApolloQueryResult, ApolloClient, FetchResult } from '@apollo/client'
import programMutation from '@graphql/mutations/automatedPrograms'
import createProgramMessages from '@graphql/mutations/createProgramMessages'
import saveProgramFinalQuery from '@graphql/mutations/saveProgramFinal'
import programQuery from '@graphql/queries/automatedPrograms'
import {
  CreateProgramMessagesMutation,
  CreateProgramMessagesMutationVariables,
  ProgramCreateMessageInput,
  SaveProgramFinalMutation,
  SaveProgramFinalMutationVariables,
  SaveProgramMutation,
  SaveProgramMutationVariables,
} from '@graphql/types/mutation-types'
import { ProgramInput, ProgramQuery, ProgramQueryVariables } from '@graphql/types/query-types'
import { PayloadAction } from '@interface/Action'
import api from '@utils/api'
import { logError } from '@utils/env'
import { ProgramStepType } from '@utils/program/program.constants'
import {
  loadProgramComplete,
  loadProgramError,
  saveProgramComplete,
  LoadProgramPayload,
  SaveProgramPayload,
  saveProgramError,
  SaveProgramFinalPayload,
  saveProgramFinalComplete,
  saveProgramFinalError,
  CreateProgramMessagePayload,
} from '@utils/program/programActions'
import actionTypes from '@utils/program/programActionTypes'
import runSagas from '@utils/store/sagas'

import { cleanProgram } from './sagasUtils'

export function loadProgramQuery(action: PayloadAction<LoadProgramPayload>): Promise<ApolloQueryResult<ProgramQuery>> {
  return action.payload.client.query<ProgramQuery, ProgramQueryVariables>({
    query: programQuery,
    fetchPolicy: 'network-only',
    variables: {
      id: action.payload?.id === 'new' ? '' : action.payload?.id,
    },
  })
}

export function saveProgramMutation(client: ApolloClient<object>, program: ProgramInput): Promise<FetchResult<SaveProgramMutation>> {
  return client.mutate<SaveProgramMutation, SaveProgramMutationVariables>({
    mutation: programMutation,
    variables: {
      program: program,
    },
  })
}

export function saveProgramFinalMutation(client: ApolloClient<object>, programId: string): Promise<FetchResult<SaveProgramFinalMutation>> {
  return client.mutate<SaveProgramFinalMutation, SaveProgramFinalMutationVariables>({
    mutation: saveProgramFinalQuery,
    variables: {
      programId,
    },
  })
}

export function* loadProgram(action: PayloadAction<LoadProgramPayload>) {
  try {
    const { data } = yield call(loadProgramQuery, action)
    yield put(loadProgramComplete(data))
  } catch (err) {
    logError(err)
    yield put(loadProgramError({ error: err }))
  }
}

export function* saveProgram(action: PayloadAction<SaveProgramPayload>) {
  try {
    const cleanedProgram = cleanProgram(action.payload.program)
    // remove start step
    const firstTrack = cleanedProgram.tracks?.find((track) => (track.id = 't0'))
    if (firstTrack != undefined && firstTrack.steps[0] !== undefined && firstTrack.steps[0].stepType === ProgramStepType.START) {
      firstTrack?.steps.splice(0, 1)
    }

    const {
      data: { saveProgram },
    } = yield call(saveProgramMutation, action.payload.client, cleanedProgram)
    if (saveProgram.status === 'saved') {
      if (saveProgram.valid) {
        try {
          const { data } = yield call(saveProgramFinalMutation, action.payload.client, action.payload.program.id)
          yield put(saveProgramFinalComplete(data.saveProgramFinal))
        } catch (err) {
          logError(err)
          yield put(saveProgramFinalError(err))
        }
      } else {
        yield put(saveProgramComplete(saveProgram))
      }
    } else {
      yield put(saveProgramError({ error: 'Failed to save' }))
    }
  } catch (err) {
    logError(err)
    yield put(saveProgramError({ error: err }))
  }
}

export function* saveProgramFinal(action: PayloadAction<SaveProgramFinalPayload>) {
  try {
    const { data } = yield call(saveProgramFinalMutation, action.payload.client, action.payload.programId)
    yield put(saveProgramFinalComplete(data.saveProgramFinal))
  } catch (err) {
    logError(err)
    yield put(saveProgramFinalError(err))
  }
}

interface CreateProgramMessageCallResult {
  status?: string
  msgId?: string
  title: string
  message?: string
}

function createProgramMessageCall(messageId: string, programId: string) {
  return call(api.doFetch, 'programCreateMessage', {
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
    body: `progid=${programId}&msgid=${messageId}&ts=${new Date().getTime()}`,
  })
}

function buildErrorMessage(results: CreateProgramMessageCallResult[]) {
  const successes: string[] = []
  const errors: CreateProgramMessageCallResult[] = []
  results.forEach((res) => {
    if (res.status === 'ok') {
      successes.push(res.title)
    } else if (res.status === 'error') {
      errors.push({ title: res.title, message: res.message })
    }
  })
  const successMsg = successes.length ? `Messages added to the Program: ${successes.join(', ')}.` : ''
  const errorMsg = errors.length ? `These messages could not be added: ${errors.map((err) => `${err.title}: ${err.message}`).join(', ')}` : ''
  return `Not all your selected messages could be added to the Program. ${successMsg} ${errorMsg}`
}

const createProgramMessagesMutation = (
  client: ApolloClient<object>,
  programId: string,
  msgIds: string[]
): Promise<FetchResult<CreateProgramMessagesMutation>> => {
  const createMessageInput: ProgramCreateMessageInput = {
    programId,
    msgIds,
  }
  return client.mutate<CreateProgramMessagesMutation, CreateProgramMessagesMutationVariables>({
    mutation: createProgramMessages,
    errorPolicy: 'all',
    variables: { createMessageInput },
  })
}

export function* createProgramMessageRequest(action: PayloadAction<CreateProgramMessagePayload>) {
  const {
    data: { createProgramMessages },
  } = yield createProgramMessagesMutation(action.payload.client, action.payload.programId, action.payload.messageIds)

  if (createProgramMessages.errorMsgIds.length === 0) {
    yield put({
      type: actionTypes.createProgramMessageComplete,
      payload: {
        results: createProgramMessages.succeedMsgIds,
      },
    })
  } else {
    const successMsg = createProgramMessages.succeedMsgIds.length
      ? `Messages added to the Program: ${createProgramMessages.succeedMsgIds.join(', ')}.`
      : ''
    const errorMsg = `These messages could not be added: ${createProgramMessages.errorMsgIds.join(', ')}.`
    const payload = `Not all your selected messages could be added to the Program. ${successMsg} ${errorMsg}`

    yield put({
      type: actionTypes.createProgramMessageError,
      payload,
    })
  }
}

export function* createProgramMessage(action: PayloadAction<CreateProgramMessagePayload>) {
  try {
    const results: Array<any> = yield all(
      action?.payload.messageIds.map((messageId) => createProgramMessageCall(messageId, action.payload.programId))
    )
    const allOk = results.reduce((acc: boolean, res: CreateProgramMessageCallResult) => acc || res.status === 'ok', false)
    if (allOk) {
      yield put({
        type: actionTypes.createProgramMessageComplete,
        payload: {
          results,
        },
      })
    } else {
      yield put({
        type: actionTypes.createProgramMessageError,
        payload: buildErrorMessage(results),
      })
    }
  } catch (e) {
    logError(e)
    yield put({
      type: actionTypes.createProgramMessageError,
      payload: (e as Error).message,
    })
  }
}

export function* addWatchers() {
  yield takeLatest(actionTypes.loadProgram, loadProgram)
  yield takeLatest(actionTypes.saveProgram, saveProgram)
  yield takeLatest(actionTypes.saveProgramFinal, saveProgramFinal)
  yield takeEvery(actionTypes.createProgramMessage, createProgramMessage)
  yield takeLatest(actionTypes.createProgramMessageRequest, createProgramMessageRequest)
}

runSagas(addWatchers)
