import { useApolloClient } from '@apollo/client'
import getRssFeed from '@graphql/queries/getRssFeed'
import getRssFeedPlainText from '@graphql/queries/getRssFeedPlainText'
import { GetRssFeedPlainTextQuery, GetRssFeedPlainTextQueryVariables, GetRssFeedQuery, GetRssFeedQueryVariables } from '@graphql/types/query-types'
import { FetchPromise } from '@utils/types'

export interface RssModalRequests {
  getRssFeedQuery: (url: string) => FetchPromise<GetRssFeedQuery>
  getRssFeedPlainTextQuery: (params: GetRssFeedPlainTextQueryVariables) => FetchPromise<GetRssFeedPlainTextQuery>
}

export const useRssModalRequests = (): RssModalRequests => {
  const client = useApolloClient()

  const getRssFeedQuery = async (url: string) =>
    await client.query<GetRssFeedQuery, GetRssFeedQueryVariables>({
      query: getRssFeed,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
      variables: { url },
    })

  const getRssFeedPlainTextQuery = async (params: GetRssFeedPlainTextQueryVariables) =>
    await client.query<GetRssFeedPlainTextQuery, GetRssFeedPlainTextQueryVariables>({
      query: getRssFeedPlainText,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
      variables: { ...params },
    })

  return {
    getRssFeedQuery,
    getRssFeedPlainTextQuery,
  }
}
