import React, { FC, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Container from '@components/Container'
import PageHeadline from '@components/PageHeadline'
import { PageHeaderType } from '@components/PageHeadline/PageHeadline'
import StaticImage from '@components/StaticImage/StaticImage'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import MapContactsContainer from '@src/pages/sms/marketingListOptIns/components/MapContacts/MapContactsContainer'
import { AddListStatus } from '@src/pages/sms/marketingListOptIns/components/OptInsSourceLists/OptInsSourceListsContainer'

import './StartPage.css'

type Props = {
  hasPurchasedSMS: boolean
  className?: string
  dataTest?: string
}

const rootClass = 'start-page'
const KB_LINK = 'https://success.act-on.com/acton/attachment/9883/u-f1ac31f4-0198-4407-b026-1326b7797e4d/0/-/-/-/-/SMS%20Opt-In%20Setup'

const StartPage: FC<Props> = (props: Props) => {
  const { hasPurchasedSMS, dataTest = rootClass, className = '' } = props
  const [isAddOpen, setIsAddOpen] = useState(false)
  const { t } = useTranslation()

  const closeModal = (statusVal?: AddListStatus) => {
    setIsAddOpen(false)
    if (statusVal === AddListStatus.success) {
      window.location.reload()
    }
  }

  return (
    <>
      {isAddOpen && <MapContactsContainer closeModal={closeModal} />}
      <Container className={classNames(rootClass, className)} data-test={dataTest}>
        <div className={`${rootClass}__image`}>
          <StaticImage name={StaticImageNames.errorNothingFound} altText={t('No contacts have been added yet')} />
        </div>
        <PageHeadline type={PageHeaderType.LARGE_HEADER}>{t('No contacts have been added yet')}</PageHeadline>
        <div className={`${rootClass}__box-row`}>
          {hasPurchasedSMS && (
            <div className={`${rootClass}__box-outlined`}>
              <div className={`${rootClass}__box-title`}>
                <Typography text={t('Have SMS Opt-Ins?')} weight={TextWeight.MEDIUM} />
              </div>
              <div className={`${rootClass}__box-action`}>
                <Button buttonType={ButtonType.PRIMARY} onClick={() => setIsAddOpen(true)}>
                  {t(`Add opt-in list`)}
                </Button>
              </div>
            </div>
          )}
          <div className={`${rootClass}__box-default`}>
            <div className={`${rootClass}__box-title`}>
              <Typography text={t('No SMS Opt-Ins Yet?')} type={TextType.BODY_TEXT_LIGHT} />
            </div>
            <div className={`${rootClass}__box-action`}>
              <TextLink text={t('Learn how to grow your audience')} link={KB_LINK} size={TextLinkSize.LARGE} weight={TextWeight.REGULAR} />
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default StartPage
