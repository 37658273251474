import { Status } from '@components/StatusToast/StatusToast'

export enum VersionState {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  ADDED = 'added',
}

export type SubscriptionManagementDTO = {
  name: string
  description: string
  version: string
  created: number
  status: VersionState
  default: boolean
  isEnabled: boolean | undefined
}

export const defaultSortBy = [
  {
    id: 'name',
    desc: false,
  },
]

export const errorStatusToast = {
  statusMessage: 'Something went wrong on our end.',
  status: Status.FAIL,
  showStatusToast: true,
}

export const successStatusToast = {
  statusMessage: 'Version successfully deleted',
  status: Status.SUCCESS,
  showStatusToast: true,
}
