import React, { FC, ReactNode, useCallback, useContext } from 'react'
import { Row } from 'react-table'

import classNames from 'classnames'

import SearchResults from '@complex/ListPickerModalV2/components/SearchResults/SearchResults'
import SegmentTreePage from '@complex/ListPickerModalV2/components/SegmentTreePage/SegmentTreePage'
import SelectedLists from '@complex/ListPickerModalV2/components/SelectedLists/SelectedLists'
import {
  getEmptyListingObject,
  ListPickerEmptyListingOptions,
  ListPickerModalContext,
} from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import {
  getOnRowSelectionChangedFunction,
  onRowSelectionChangeInAllListsUtils,
  onRowSelectionChangeInSelectViewUtils,
} from '@complex/ListPickerModalV2/utils/ListPickerModalUtils'
import ActionableNestedTableWithEmptyListing from '@components/ActionableNestedTableWithEmptyListing/ActionableNestedTableWithEmptyListing'
import { ActionableNestedTableWithLazyLoadingProps } from '@components/ActionableNestedTableWithLazyLoading/ActionableNestedTableWithLazyLoading'
import Caution from '@components/Caution/Caution'
import Checkbox from '@components/Checkbox'
import Loader from '@components/Loader'
import { MenuItemData } from '@components/Menu/Menu'
import { ModalBody } from '@components/Modal'
import Radio from '@components/Radio'
import ResizableMenu from '@components/ResizableMenu/ResizableMenu'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'
import { ListCategory } from '@utils/lists'

import './ListPickerModalBody.css'

export interface ListPickerBodyBaseProps {
  hideLegacyLists?: boolean
  restrictMixingLegacyListsAndUCL: boolean
}

interface ListPickerBodyProps extends ListPickerBodyBaseProps {
  menuItems: MenuItemData[]
  showCaution?: boolean
  tableProps: ActionableNestedTableWithLazyLoadingProps
  customBanner?: ReactNode
}

const rootClass = 'list-picker-modal-body'
export { rootClass as listPickerModalBodyRootClass }

const ListPickerModalBody: FC<ListPickerBodyProps> = (props: ListPickerBodyProps) => {
  const { hideLegacyLists = false, menuItems, restrictMixingLegacyListsAndUCL, showCaution = false, tableProps, customBanner } = props
  const {
    values: {
      currentFolder,
      currentTag,
      disabledListTooltipText,
      emptyListingOption,
      headerShadow,
      hideUCL,
      loading,
      multiSelect,
      search,
      viewingSelectedLists,
      segmentTreePage,
      selectAllSegmentsOfSelectedList,
      hideAllUCLListTypes,
    },
    values,
    setState,
    showTooltipByCriteria,
  } = useContext(ListPickerModalContext)
  const { loading: searchLoading } = search || {}
  const { t } = useTranslation()

  const onRowSelectionChangeInSelectView = (selectedRows: Row[], type: ListCategory) =>
    onRowSelectionChangeInSelectViewUtils(values, setState, selectedRows, type, multiSelect)

  const onRowSelectionChangeInAllLists = (selectedRows: Row[], rows: Row[], type: ListCategory) =>
    onRowSelectionChangeInAllListsUtils(values, setState, selectedRows, rows, type, multiSelect)

  const renderRadioButton = useCallback(
    ({ isSelected, toggleRowSelected, original }: Row) => (
      <Radio checked={isSelected} disabled={(original as any).disabled} onChange={({ target }) => toggleRowSelected(target.checked)} />
    ),
    []
  )

  const renderListSelectedCheckbox = useCallback((row: Row) => {
    const checkbox = (
      <Checkbox
        className={classNames({ [`${rootClass}__list-checkbox`]: (row.original as any).id.startsWith('l-') })}
        checked={row.isSelected}
        onChange={(e) => row.toggleRowSelected(e)}
        disabled={(row.original as any).disabled}
        title={(row.original as any).disabled ? disabledListTooltipText : undefined}
      />
    )
    const tooltip = showTooltipByCriteria?.(row)
    if (tooltip) {
      return (
        <Tooltip position={'left'} trigger={checkbox} ellipOnTrigger inline={false}>
          {tooltip}
        </Tooltip>
      )
    }
    return checkbox
  }, [])

  return (
    <ModalBody className={rootClass}>
      {loading ? (
        <Loader center />
      ) : (
        !viewingSelectedLists && (
          <div className={classNames(`${rootClass}__main-content`, { [`${rootClass}__with-caution`]: showCaution })}>
            <ResizableMenu className={`${rootClass}__menu`} menuItems={menuItems} />
            <div className={classNames(`${rootClass}__info-content`, { [`${rootClass}__info-content-warning`]: showCaution })}>
              {customBanner && <div className={`${rootClass}__custom-banner`}>{customBanner}</div>}
              {segmentTreePage ? (
                <SegmentTreePage
                  checkboxCellOverride={multiSelect ? (selectAllSegmentsOfSelectedList ? renderListSelectedCheckbox : undefined) : renderRadioButton}
                  onRowSelectionChanged={getOnRowSelectionChangedFunction(setState, values, multiSelect)}
                />
              ) : search ? (
                searchLoading ? (
                  <div className={`${rootClass}__search-loader`}>
                    <Loader center />
                  </div>
                ) : (
                  <SearchResults
                    checkboxCellOverride={
                      multiSelect ? (selectAllSegmentsOfSelectedList ? renderListSelectedCheckbox : undefined) : renderRadioButton
                    }
                    restrictMixingLegacyListsAndUCL={restrictMixingLegacyListsAndUCL}
                    onRowSelectionChanged={getOnRowSelectionChangedFunction(setState, values, multiSelect)}
                    onRowSelectionChangedInAllLists={onRowSelectionChangeInAllLists}
                    hideAllListsOption={!hideUCL && !hideAllUCLListTypes && hideLegacyLists}
                  />
                )
              ) : (
                <div className={`${rootClass}__table-wrapper`}>
                  {showCaution && <Caution message={t('Act-On Contacts cannot be combined with legacy lists or segments.')} />}
                  <ActionableNestedTableWithEmptyListing
                    className={classNames(`${rootClass}__table`, { [`${rootClass}__table-header-shadow`]: headerShadow })}
                    {...tableProps}
                    {...getEmptyListingObject(
                      emptyListingOption || ListPickerEmptyListingOptions.EMPTY_OTHERS,
                      rootClass,
                      t,
                      (currentFolder && currentFolder.name) || (currentTag && currentTag.name)
                    )}
                    checkboxCellOverride={
                      multiSelect
                        ? selectAllSegmentsOfSelectedList || showTooltipByCriteria
                          ? renderListSelectedCheckbox
                          : undefined
                        : renderRadioButton
                    }
                    expandHeaderActions
                  />
                </div>
              )}
            </div>
          </div>
        )
      )}
      {viewingSelectedLists && <SelectedLists multiSelect={multiSelect} onRowSelectionChanged={onRowSelectionChangeInSelectView} />}
    </ModalBody>
  )
}

export default ListPickerModalBody
