import { FC, useEffect } from 'react'

import { legacyActonContext } from '@const/globals'
import { AccountState } from '@utils/account/reducer'
import documentUtils from '@utils/document'

interface CustomCSSProps {
  account: AccountState
}

const CustomCSS: FC<CustomCSSProps> = (props: CustomCSSProps) => {
  const { accountSettings } = props.account

  useEffect(() => {
    if (accountSettings?.customCssInjection && accountSettings?.customCssVersion) {
      const stylesheet = documentUtils.createElement('link') as HTMLLinkElement
      stylesheet.rel = 'stylesheet'
      stylesheet.type = 'text/css'
      stylesheet.href = `${legacyActonContext}/internalapi/branding/customcss/file?v=${accountSettings.customCssVersion}`
      documentUtils.appendChildToBody(stylesheet)
    }
  }, [])

  return null
}

export default CustomCSS
