export enum MethodOptions {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum FormatOptions {
  FORM = 'FORM',
  JSON = 'JSON',
}

export const FORMAT_OPTIONS = [
  [FormatOptions.FORM, 'application/x-www-form-urlencoded'],
  [FormatOptions.JSON, 'application/json'],
]
