import React, { FC, useContext } from 'react'

import Modal, { ModalHeader, ModalBody } from '@components/Modal'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import { useTranslation } from '@const/globals'
import { SMSContext, SMSContextAPI } from '@src/pages/sms/context/SMSContext'

import MessagePreviewVisual from '../MessagePreview/MessagePreviewVisual'

import './previewModal.css'

interface Props {
  isOpen: boolean
  closeModal: () => void
  dataTest?: string
}

const rootClass = 'preview-modal'

export const PreviewModal: FC<Props> = (props: Props) => {
  const { isOpen, dataTest = 'sms-preview-modal', closeModal } = props
  const context = useContext<SMSContextAPI>(SMSContext)

  const { t } = useTranslation()

  return (
    <Modal
      isOpen={isOpen}
      dataTest={dataTest}
      className={rootClass}
      header={
        <ModalHeader headerType={ModalHeaderType.Form} closeButton={closeModal}>
          {t('Message Preview')}
        </ModalHeader>
      }
    >
      <ModalBody>
        <MessagePreviewVisual context={context} />
      </ModalBody>
    </Modal>
  )
}

export default PreviewModal
