import { useEffect } from 'react'

import { useAccountSettings } from '@utils/account/account.utils'
import { EmailComposerAPI, EmailMessage, SendTimeType } from '@utils/composer/context/EmailComposer.context'

export const useUserTimezone = (update: EmailComposerAPI['update'], message: EmailMessage) => {
  const { timeZoneId } = useAccountSettings()
  const {
    id,
    sendMethod: { sendtimezone, staggertimezone, sendtype },
  } = message

  useEffect(() => {
    if (id) {
      if (sendtype === SendTimeType.LATER && !sendtimezone) {
        update({
          message: {
            sendMethod: {
              sendtimezone: timeZoneId,
              defaultsendtimezone: timeZoneId,
            },
          },
        })
      }
    } else if (sendtype === SendTimeType.STAGGERED || !staggertimezone) {
      update({
        message: {
          sendMethod: {
            staggertimezone: timeZoneId,
            defaultsendtimezone: timeZoneId,
          },
        },
      })
    }
  }, [id, sendtimezone, staggertimezone, timeZoneId, update, sendtype])
}
