import { TFunction } from 'i18next'

import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { Update } from '@src/pages/ContactSegments/components/CopySegments/context/CopySegments.context'
import { GetUnifiedListFieldMappingsRequestType } from '@src/pages/ContactSegments/components/CopySegments/GraphQL/CopySegmentsRequests.list.graphQL'
import {
  filterAndSortContactsFields,
  getDeletedContactsFields,
  showStatusToast,
} from '@src/pages/ContactSegments/components/CopySegments/GraphQL/utils/CopySegmentsRequests.helper'
import { logNewRelicError } from '@utils/new-relic.utils'

interface GetSegmentDefinitionFieldsUtilsParams {
  getUnifiedListFieldMappingsRequest: GetUnifiedListFieldMappingsRequestType
  t: TFunction
  update: Update
}

export const getUnifiedListFieldMappingsUtils = async ({ getUnifiedListFieldMappingsRequest, t, update }: GetSegmentDefinitionFieldsUtilsParams) => {
  try {
    const { data, errors } = await getUnifiedListFieldMappingsRequest()
    if (!!data?.unifiedListFieldMappings) {
      const allContactsFields: UnifiedListFieldMapping[] = filterAndSortContactsFields(data.unifiedListFieldMappings as UnifiedListFieldMapping[])
      const deletedContactsFields: UnifiedListFieldMapping[] = getDeletedContactsFields(data.unifiedListFieldMappings as UnifiedListFieldMapping[])
      const allContactsFieldsOptions: SelectV2SingleOption[] = allContactsFields.map(({ displayName = '' }: UnifiedListFieldMapping) => ({
        label: displayName,
        value: displayName,
      }))
      update({ allContactsFields, allContactsFieldsOptions, deletedAllContactsFields: deletedContactsFields })
    } else if (errors) {
      showStatusToast(update, t('Copy.Segments.Page.Request.Error.UnifiedListFieldMappings'))
      logNewRelicError(errors)
    }
  } catch (e) {
    showStatusToast(update, t('Copy.Segments.Page.Request.Error.UnifiedListFieldMappings'))
    logNewRelicError(e)
  }
}

interface GetSegmentDefinitionFieldsUtilsParams {
  getUnifiedListFieldMappingsRequest: GetUnifiedListFieldMappingsRequestType
  t: TFunction
  update: Update
}

export const loadUnifiedListFieldMappingsUtils = async ({ getUnifiedListFieldMappingsRequest, t, update }: GetSegmentDefinitionFieldsUtilsParams) => {
  try {
    const { data, errors } = await getUnifiedListFieldMappingsRequest()
    if (!!data?.unifiedListFieldMappings) {
      const unifiedListFieldMappings: UnifiedListFieldMapping[] = filterAndSortContactsFields(
        data.unifiedListFieldMappings as UnifiedListFieldMapping[]
      )
      update({ unifiedListFieldMappings })
    } else if (errors) {
      showStatusToast(update, t('Copy.Segments.Page.Request.Error.UnifiedListFieldMappings'))
      logNewRelicError(errors)
    }
  } catch (e) {
    showStatusToast(update, t('Copy.Segments.Page.Request.Error.UnifiedListFieldMappings'))
    logNewRelicError(e)
  }
}
