import React, { FC, useCallback, useContext, useRef } from 'react'

import Accordion from '@components/Accordion/Accordion'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import UploadFile, { UploadArgs } from '@src/pages/EmailComposer/EmailModals/components/MediaFileModal/UploadFile/UploadFile'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { FilterTypes } from '@utils/filter'

import { useUploadEmailRequest } from '../../graphQL/UploadHtmlComposerRequsets.graphql'

import './UploadHtmlModal.css'

export interface PersonalizationField {
  name: string
  value?: string
  type: FilterTypes
}

interface UploadHtmlModalProps {
  isOpen: boolean
  onClose: (goBack?: boolean) => void
  className?: string
  dataTest?: string
  uploadMediaFile?: (args: UploadArgs | undefined) => Promise<void>
  hasHtmlContent?: boolean
  msgId?: string
}

const rootClass = 'upload-html-modal'

const UploadHtmlModal: FC<UploadHtmlModalProps> = (props: UploadHtmlModalProps) => {
  const { onClose, hasHtmlContent, msgId = '' } = props
  const { t } = useTranslation()
  const {
    api: { update },
  } = useContext(EmailComposerContext)
  const { uploadEmailCodeRequest } = useUploadEmailRequest()
  const handleClose = (goBack?: boolean) => {
    onClose(goBack)
    if (controllerRef.current) {
      controllerRef.current.abort()
    }
  }

  const controllerRef = useRef<AbortController | null>(null)

  const createNewAbortController = () => {
    if (controllerRef.current) {
      controllerRef.current.abort()
    }
    controllerRef.current = new AbortController()
  }

  const uploadMediaFile = useCallback(
    async (args?: UploadArgs): Promise<void> => {
      const base64 = args?.base64File || ''
      const fileName = `${args?.fileName}.${args?.extension}` || ''
      createNewAbortController()
      const signal = controllerRef.current?.signal
      const response = await uploadEmailCodeRequest(base64, msgId, fileName, signal)
      const uploadEmailCode = response?.data?.uploadEmailCode

      if (uploadEmailCode) {
        update({ message: { templateHtml: uploadEmailCode.html } })
        onClose()
      }
    },
    [uploadEmailCodeRequest, msgId, update, onClose]
  )
  const MAX_SIZE_250KB = 250 * 1024 // 250KB in bytes

  return (
    <UploadFile
      hideDescriptionText={true}
      isOpen={true}
      onFileUpload={uploadMediaFile}
      handleCancel={() => handleClose(false)}
      uploadText={t(`EmailComposer.UploadHtml.Uploader.Title`)}
      maxSizeText={t(`250KB max for HTML files and 10MB max for ZIP files`)}
      hideBackButton={true}
      hasWarningMessage={hasHtmlContent && t('EmailComposer.UploadHtml.Modal.Caution')}
      hideFolders={true}
      className={rootClass}
      acceptCustomFormats=".zip, .html"
      uploadButtonText={t('Upload Code')}
      secondaryFile="html"
      secondaryFileMaxSize={MAX_SIZE_250KB}
    >
      <Typography className={`${rootClass}__description ${rootClass}__with-margin`} text={t(`EmailComposer.UploadHtml.Modal.Description`)} />
      <Accordion
        className={`${rootClass}__options__accordion`}
        dataTest={`${rootClass}-options`}
        childData={[
          {
            index: '0',
            header: (
              <Typography
                className={`${rootClass}__accordion-title`}
                text={t('File modifications')}
                type={TextType.BODY_TEXT}
                weight={TextWeight.MEDIUM}
                lineHeight={LineHeight.MEDIUM_LARGE}
              />
            ),
            content: <Typography className={`${rootClass}__accordion-description`} text={t(`EmailComposer.UploadHtml.Modal.File.Text`)} />,
          },
          {
            index: '1',
            header: (
              <Typography
                className={`${rootClass}__accordion-title`}
                text={t('ZIP files')}
                type={TextType.BODY_TEXT}
                weight={TextWeight.MEDIUM}
                lineHeight={LineHeight.MEDIUM_LARGE}
              />
            ),
            content: <Typography className={`${rootClass}__accordion-description`} text={t(`EmailComposer.UploadHtml.Modal.Zip.Text`)} />,
          },
        ]}
        chevronLeft
      />
    </UploadFile>
  )
}

export default UploadHtmlModal
