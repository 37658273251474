import React, { forwardRef, useCallback, useEffect, useMemo, useReducer, useRef } from 'react'

import classNames from 'classnames'
import { useDebouncedCallback } from 'use-debounce'

import ButtonShowHide from '@components/ButtonShowHide/ButtonShowHide'
import { LabelV2 } from '@components/LabelV2/LabelV2'
import { SvgNames, SvgType } from '@components/Svg'
import { getUUID } from '@const/globals'

import {
  cancelDelayedValidation,
  handleOnChange,
  inputReducer,
  onClearHandler,
  onInputFocus,
  onKeyDownHandlerSVG,
  refreshStatus,
  setIcon,
  updateInputReducer,
} from './input.utils'
import { InputInfo } from './InputInfo/InputInfo'
import { InputProps } from './InputTS/interface'
import { PasswordInfoBlock } from './PasswordInfoBlock/PasswordInfoBlock'
import { InputItemsWrapper } from './Wrappers/InputItemsWrapper'

import './inputV2.css'

export const DEFAULT_MESSAGE_KEY = 'defaultMessage'

const rootClass = 'inputV2'
const rootClassWithClear = `${rootClass}-with-clear`
const rootClassWithMaxCharacter = `${rootClass}-with-max-character`

const Input = forwardRef((props: InputProps, ref) => {
  const {
    id,
    min,
    max,
    name,
    trim,
    value,
    error,
    tabIndex,
    disabled,
    register,
    readOnly,
    children,
    required,
    fullWidth,
    className,
    inputInfo,
    clearProps,
    labelProps,
    statusProps,
    leadingIcon,
    trailingIcon,
    disableFocus,
    autoComplete,
    inputAriaLabel,
    clipBoardProps,
    onChangeDebounce,
    autoFocus = false,
    maxCharacterProps,
    passwordInfoProps,
    parentWrapperClassName,
    dataTest = rootClass,
    appearance = 'standard',
    handleValueChangeFromOuter,
    iconType = SvgType.LARGER_ICON,
    placeholder = 'Enter your details here',
    onCopy,
    onBlur,
    onClear,
    onFocus,
    onChange,
    onKeyDown,
    onBlurCapture,
    onFocusCapture,
    ...rest
  } = props

  const {
    reload,
    showErrorOnChange,
    showStatus = true,
    validityFunctions,
    showIconOnValid = true,
    successDebounceTime = 500,
    validationDebounceTime = 1200,
    onlyValidateOnBlur = false,
    onReload,
  } = statusProps ?? {}
  const { enablePasswordInfoBlock } = passwordInfoProps ?? {}

  const inputId = id ?? getUUID()
  const inputRef = useRef<HTMLInputElement>()

  const [state, dispatch] = useReducer(inputReducer, {
    loading: false,
    isValid: undefined,
    isTouched: undefined,
    isTyping: false,
    showPassword: passwordInfoProps?.showPassword,
    passwordValidationError: false,
    showClearBtn: false,
    errorKey: 'required',
    activeIcon: undefined,
    currentValue: value ?? '',
    statusVisible: showStatus,
    copyToClipBoardStatus: undefined,
  })

  const {
    isValid,
    isTyping,
    activeIcon,
    currentValue,
    errorKey,
    loading,
    passwordValidationError,
    showClearBtn,
    statusVisible,
    copyToClipBoardStatus,
    isTouched,
    showPassword,
  } = state

  const hasClear = !!clearProps
  const hasLabel = labelProps?.label ?? labelProps?.children
  const hasValidationStatus = !!statusProps
  const { maxLength, hideLengthIfEmpty } = maxCharacterProps ?? {}
  const isReadOnly = readOnly && !maxCharacterProps && !clearProps && !statusProps
  const showCounter = (hideLengthIfEmpty && currentValue && currentValue?.length > 0) || !hideLengthIfEmpty
  const computedClipboardError = !!clipBoardProps?.tooltipErrorOnCopy?.(currentValue ?? '')
  const hasError = !disabled && (error || passwordValidationError || copyToClipBoardStatus?.error || computedClipboardError)
  const showInputInfo = (inputInfo?.enabled || ((hasError || isValid === false) && !enablePasswordInfoBlock)) && !isReadOnly && !clipBoardProps

  const validationDelayed = useDebouncedCallback((isLoading, isValid) => {
    dispatch(updateInputReducer('WITH_STATUS', { loading: isLoading, isValid, isTouched: false }))
    setIcon(dispatch, isValid, showIconOnValid, currentValue, hasError)
  }, successDebounceTime)

  const errorValidationDelayed = useDebouncedCallback((errorKey: string) => {
    dispatch(updateInputReducer('WITH_STATUS', { loading: false, isValid: false, activeIcon: SvgNames.inputStatusInvalid, errorKey }))
  }, validationDebounceTime)

  const commonProps = useMemo(
    () => ({
      isTyping,
      required,
      error: hasError,
      showErrorOnChange,
      validationDelayed,
      validityFunctions,
      value: currentValue,
      errorValidationDelayed,
      dispatch,
    }),
    [currentValue, errorValidationDelayed, hasError, isTyping, required, showErrorOnChange, validationDelayed, validityFunctions]
  )

  useEffect(() => {
    if (showStatus && hasValidationStatus) {
      if (hasError) {
        cancelDelayedValidation(showErrorOnChange, errorValidationDelayed, validationDelayed)
        dispatch(updateInputReducer('WITH_STATUS', { loading: false, isValid: false, activeIcon: SvgNames.inputStatusInvalid }))
      } else if (isTouched && !onlyValidateOnBlur) {
        refreshStatus(commonProps)
      }
    }
  }, [
    commonProps,
    errorValidationDelayed,
    hasError,
    isTouched,
    showErrorOnChange,
    showStatus,
    hasValidationStatus,
    validationDelayed,
    onlyValidateOnBlur,
  ])

  useEffect(() => {
    if (showStatus && hasValidationStatus) {
      cancelDelayedValidation(showErrorOnChange, errorValidationDelayed, validationDelayed)

      if (showStatus !== statusVisible && !onlyValidateOnBlur) {
        refreshStatus(commonProps)
      }

      dispatch(updateInputReducer('WITH_STATUS', { statusVisible: showStatus }))
    }
  }, [showStatus, hasValidationStatus, showErrorOnChange, errorValidationDelayed, validationDelayed, statusVisible, onlyValidateOnBlur])

  useEffect(() => {
    if (autoFocus && !!inputRef.current) {
      inputRef.current?.focus()
    }
  }, [autoFocus])

  useEffect(() => {
    if (reload) {
      cancelDelayedValidation(showErrorOnChange, errorValidationDelayed, validationDelayed)

      if (!onlyValidateOnBlur) {
        refreshStatus(commonProps)
      }
    }
    onReload?.()
  }, [commonProps, errorValidationDelayed, onReload, reload, showErrorOnChange, validationDelayed, onlyValidateOnBlur])

  useEffect(() => {
    if (hasClear || (hasValidationStatus && !onlyValidateOnBlur)) {
      let typingTimeout: NodeJS.Timeout | undefined

      if (isTyping && typingTimeout) {
        clearTimeout(typingTimeout)
        typingTimeout = setTimeout(() => {
          dispatch(updateInputReducer('IS_TYPING', false))
        }, 300)
      }

      return () => {
        clearTimeout(typingTimeout)
      }
    }
  }, [hasClear, hasValidationStatus, isTyping, onlyValidateOnBlur])

  useEffect(() => {
    handleValueChangeFromOuter && dispatch(updateInputReducer('CURRENT_VALUE', value))
  }, [value, handleValueChangeFromOuter])

  const debouncedOnChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event)
  }, onChangeDebounce)

  const onCopyToClipboard = useCallback(async () => {
    if (clipBoardProps) {
      try {
        if (computedClipboardError) {
          dispatch(updateInputReducer('COPY_TO_CLIPBOARD_STATUS', { error: true }))
        } else {
          await navigator.clipboard.writeText(currentValue ?? '')
          dispatch(updateInputReducer('COPY_TO_CLIPBOARD_STATUS', { success: true }))
        }
      } catch (error) {
        dispatch(updateInputReducer('COPY_TO_CLIPBOARD_STATUS', { error: true }))
      } finally {
        setTimeout(() => {
          dispatch(updateInputReducer('COPY_TO_CLIPBOARD_STATUS', undefined))
        }, 3000)
      }

      onCopy?.(currentValue)
    }
  }, [clipBoardProps, computedClipboardError, currentValue, onCopy])

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      handleOnChange({
        trim,
        event,
        hasClear,
        showStatus,
        onChangeDebounce,
        debouncedOnChange,
        hasValidationStatus,
        onlyValidateOnBlur,
        onChange,
        ...commonProps,
      })
    },
    [trim, hasClear, showStatus, onChangeDebounce, debouncedOnChange, hasValidationStatus, onChange, commonProps, onlyValidateOnBlur]
  )

  const handleOnFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement, Element>): void => {
      if (hasValidationStatus) {
        if (!isValid && !onlyValidateOnBlur) {
          refreshStatus({
            event: event,
            ...commonProps,
          })
        }
        dispatch(updateInputReducer('WITH_STATUS', { isTouched: true }))
      }

      onInputFocus({
        event,
        showStatus,
        statusVisible,
        hasValidationStatus,
        onFocus,
        ...commonProps,
      })
    },
    [hasValidationStatus, showStatus, statusVisible, onFocus, commonProps, isValid, onlyValidateOnBlur]
  )

  const onInputBlur = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onBlur?.(e)
      if (hasValidationStatus) {
        dispatch(updateInputReducer('WITH_STATUS', { isTouched: true, loading: true }))
        if (!statusVisible) {
          return
        }

        if (!isValid && onlyValidateOnBlur) {
          refreshStatus({
            event: e,
            ...commonProps,
          })
        }
      }
    },
    [commonProps, hasValidationStatus, isValid, onBlur, onlyValidateOnBlur, statusVisible]
  )

  const inputComponent = (
    <div
      className={classNames(`${rootClass}__container`, {
        [`${className}__container`]: className,
        [`${rootClass}__container-input-info`]: showInputInfo,
        [`${rootClass}__container-icon`]: leadingIcon,
      })}
    >
      <input
        min={min}
        max={max}
        ref={ref}
        name={name}
        id={inputId}
        tabIndex={tabIndex}
        value={currentValue}
        data-test={dataTest}
        type={!showPassword && enablePasswordInfoBlock ? 'password' : undefined}
        /* eslint-disable-next-line jsx-a11y/no-autofocus */
        autoFocus={autoFocus}
        aria-label={inputAriaLabel}
        placeholder={placeholder}
        autoComplete={autoComplete}
        className={classNames(rootClass, className, {
          [`${rootClass}__read-only`]: isReadOnly,
          [`${rootClass}__without-focus`]: disableFocus,
          [`${rootClass}__has-leading-icon`]: leadingIcon,
          [`${rootClass}__has-max-character`]: maxCharacterProps,
          [`${rootClass}__has-copy`]: clipBoardProps && isReadOnly,
          [`${rootClass}__has-trailing-icon`]: trailingIcon ?? statusProps,
          [`${rootClass}__read-only-appearance-${appearance}${hasError ? '-error' : ''}`]: isReadOnly,
          [`${rootClass}__error`]: hasError || (isValid === false && !passwordInfoProps?.enablePasswordInfoBlock),
          [`${rootClass}__read-only-appearance-none-with-copy`]: isReadOnly && clipBoardProps && appearance == 'none',
          [`${rootClass}__read-only-appearance-has-focus`]: isReadOnly && !disableFocus && !clipBoardProps && !hasError,
          [`${rootClass}__has-combination`]: maxCharacterProps && ((hasClear && showClearBtn) || statusProps),
          [`${rootClassWithMaxCharacter}__counter-hidden`]: hideLengthIfEmpty,
        })}
        disabled={disabled}
        required={required}
        readOnly={isReadOnly}
        maxLength={maxLength}
        aria-describedby={inputInfo?.helperText}
        onBlur={onInputBlur}
        onKeyDown={onKeyDown}
        onFocus={handleOnFocus}
        onChange={handleChange}
        onFocusCapture={onFocusCapture}
        onBlurCapture={onBlurCapture}
        {...rest}
        {...register}
      />

      {(maxCharacterProps || statusProps || clipBoardProps || clearProps || trailingIcon || leadingIcon || children) && (
        <InputItemsWrapper
          loading={loading}
          isValid={isValid}
          errorKey={errorKey}
          disabled={disabled}
          iconType={iconType}
          dataTest={dataTest}
          isTyping={isTyping}
          hasError={hasError}
          readOnly={isReadOnly}
          rootClass={rootClass}
          activeIcon={activeIcon}
          clearProps={clearProps}
          appearance={appearance}
          leadingIcon={leadingIcon}
          showCounter={showCounter}
          statusProps={statusProps}
          currentValue={currentValue}
          trailingIcon={trailingIcon}
          showClearBtn={showClearBtn}
          statusVisible={statusVisible}
          clipBoardProps={clipBoardProps}
          maxCharacterProps={maxCharacterProps}
          rootClassWithClear={rootClassWithClear}
          copyToClipBoardStatus={copyToClipBoardStatus}
          rootClassWithMaxCharacter={rootClassWithMaxCharacter}
          onClear={onClear}
          dispatch={dispatch}
          onClearHandler={onClearHandler}
          onCopyToClipboard={onCopyToClipboard}
          onKeyDownHandlerSVG={onKeyDownHandlerSVG}
        >
          {children}
        </InputItemsWrapper>
      )}
    </div>
  )

  return (
    <div
      className={classNames(`${rootClass}__wrapper`, parentWrapperClassName, {
        [`${rootClass}__wrapper-full-width`]: fullWidth,
      })}
    >
      {enablePasswordInfoBlock && hasLabel ? (
        <div className={`${rootClass}__with-password`}>
          <LabelV2 htmlFor={inputId} {...labelProps} />
          <ButtonShowHide
            hasIcon
            showText="Show"
            hideText="Hide"
            isShowing={!!showPassword}
            onClick={() => dispatch(updateInputReducer('SHOW_PASSWORD', !showPassword))}
          />
        </div>
      ) : (
        hasLabel && <LabelV2 htmlFor={inputId} {...labelProps} />
      )}

      {inputComponent}

      {showInputInfo && (
        <InputInfo rootClass={rootClass} error={!loading && (hasError || isValid === false)} inputInfo={inputInfo} errorKey={errorKey} />
      )}
      {enablePasswordInfoBlock && <PasswordInfoBlock inputValue={currentValue} dispatch={dispatch} />}
    </div>
  )
})

export default React.memo(Input)
