import React, { ReactNode } from 'react'
import { Cell } from 'react-table'

import { ApolloClient } from '@apollo/client'
import { Column, ColumnStatus } from '@components/ColumnsOrderModal/components/DraggableColumn/DraggableColumn'
import { renderFieldCell, uuidColumns } from '@components/ContactsDetails/utils/ContactsDetails.utils'
import { EmptyListingProps, EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { TableColumn, TableColumnAlign } from '@components/Table/Table'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { ColWithTitle as ColWithTitleV2 } from '@components/TableV2/utils/tableV2ColumnUtils'
import { TextAlign, TextType } from '@components/Typography/Typography'
import { rootContext } from '@const/globals'
import columnIndexOrder from '@graphql/microservices/segment/columnIndexOrder'
import columnIndexOrderToSubSegments from '@graphql/microservices/segment/columnIndexOrderToSubSegments'
import getColumnIndexOrder from '@graphql/microservices/segment/getColumnIndexOrder'
import { GetColumnIndexOrderQuery, GetColumnIndexOrderQueryVariables, PageInput } from '@graphql/types/microservice/segment-types'
import { CONTACTS_DETAILS_URL } from '@src/pages/ContactSegments/utils/ContactSegmentsSession.utils'
import { CellContext } from '@tanstack/react-table'
import { BouncesSegments, smsBouncesVerbs } from '@utils/categorization'
import { Contact } from '@utils/contact/contact.constants'
import { sendEmailToContact } from '@utils/contact/contact.utils'
import { Segment, goEditSegment } from '@utils/contactSegments/contactSegments.utils'
import { getFormattedNumber } from '@utils/numbers'

const HEADER_CHAR_WIDTH = 11
const COLUMN_VALUE_CHAR_WIDTH = 12
const PADDING = 24
const TABLE_ACTION_WIDTH = 60

export const getTableLabel = (search: string, totalCount: number, loading: boolean, t: Function) => {
  return search !== '' && !loading
    ? `${t(`Result${totalCount !== 1 ? 's' : ''}`)} ${t('for')} "${search}"`
    : `${getFormattedNumber(totalCount)} ${t('Contacts')}`
}

const getColumnWidth = (columnData: any, accessor: string, headerText: string, maxCellWidth: number) => {
  const maxWidth = maxCellWidth
  const columnWidth = Math.max(
    ...columnData.map((cellData: any) => (`${cellData[accessor]}` || '').length * COLUMN_VALUE_CHAR_WIDTH),
    headerText.length * HEADER_CHAR_WIDTH
  )
  return Math.min(maxWidth, columnWidth + PADDING)
}

export const renderFieldCellV2 = (cell: CellContext<Contact, unknown>, search: string): ReactNode => {
  return search ? renderBoldTextOnMatch(cell.getValue<string>(), search) : <ColWithTitleV2 cell={cell} />
}

export const getColumnsHeadersUtils = (columns: Column[], maxCellWidth: number, contacts: any[], search: string) => {
  return columns
    .filter(({ status, name }) => status !== ColumnStatus.HIDDEN && !uuidColumns.includes(name))
    .map(({ name: header, status }, index) => {
      const width = getColumnWidth(contacts, index.toString(), header || '', maxCellWidth)
      const lastIndex = columns.filter(({ status }) => status !== ColumnStatus.HIDDEN).length - 1
      const maxWidthAndFlex =
        index === lastIndex ? { flexColumn: true, minWidth: width + TABLE_ACTION_WIDTH } : { maxWidth: maxCellWidth, minWidth: width }
      return {
        ...maxWidthAndFlex,
        Header: header || '',
        accessor: `fields[${index.toString()}]`,
        align: 'left' as TableColumnAlign,
        disableSortBy: true,
        sticky: status === ColumnStatus.LOCKED ? 'left' : '',
        Cell: (cell: Cell<Contact>) => renderFieldCell(cell, search),
        className: `contacts-details__cell-search ${index === lastIndex ? 'contacts-details__last-column' : ''}`,
      } as TableColumn
    })
}
export const getColumnsHeadersUtilsV2 = (columns: Column[], search: string): ColumnDefWithAdditionalProps<Contact>[] => {
  return columns
    .filter(({ status, name }) => status !== ColumnStatus.HIDDEN && !uuidColumns.includes(name))
    .map(({ name: header }, index) => ({
      header,
      accessorKey: `field[${index}]`,
      textAlign: 'left',
      padding: { left: 24 },
      maxSize: 200,
      cell: (cell) => renderFieldCellV2(cell, search),
    }))
}

export const formatContactsUtils = (contacts: PageInput, columns: Column[]): Contact[] => {
  if (contacts?.contacts && columns.length > 0) {
    return Object.keys(contacts.contacts).map((recId: string, index) => {
      const contact: string[] = contacts.contacts[recId]
      const values = columns.reduce((columns: string[], { status, id, name }: Column) => {
        return [...columns, ...(status !== ColumnStatus.HIDDEN && !uuidColumns.includes(name) ? [contact[id]] : [])]
      }, [])

      //TODO after using tableV2 remove fields: values prop
      const modifiedData = { id: index.toString(), recId, fields: values }
      for (let i = 0; i < values.length; i++) {
        Object.assign(modifiedData, { [`field[${i}]`]: values[i] })
      }
      return modifiedData
    })
  }
  return []
}

const buildColumns = (order: number[], hidden: number[], locked: number[], defaultHeaders: string[]) => {
  return defaultHeaders
    .map((header: string, index) => {
      const status = locked.includes(index) ? ColumnStatus.LOCKED : hidden.includes(index) ? ColumnStatus.HIDDEN : ColumnStatus.DEFAULT
      return { name: header, id: index, status, index } as Column
    })
    .sort((firstColumn, secondColumn) => {
      const firstColumnPosition = order.indexOf(firstColumn.id)
      return firstColumnPosition === -1 ? 1 : firstColumnPosition - order.indexOf(secondColumn.id)
    })
}

const getColumnsSeparatedByStatus = (columns: Column[]) => {
  return columns.reduce(
    ([order, hidden, locked]: any, { status, id }: Column) => [
      [...order, id],
      [...hidden, ...(status === ColumnStatus.HIDDEN ? [id] : [])],
      [...locked, ...(status === ColumnStatus.LOCKED ? [id] : [])],
    ],
    [[], [], []]
  )
}

export const getColumnsUtils = (segment: Segment, headers: string[], client: ApolloClient<any>): Promise<Column[]> => {
  return client
    .query<GetColumnIndexOrderQuery, GetColumnIndexOrderQueryVariables>({
      query: getColumnIndexOrder,
      fetchPolicy: 'network-only',
      variables: {
        segmentId: segment.externalId,
      },
    })
    .then(({ data }) => {
      const { order = [], fixed = [], hidden = [] } = { ...data.getColumnIndexOrder }
      return buildColumns(order as number[], hidden as number[], fixed as number[], headers)
    })
    .catch(() => buildColumns([], [], [], headers))
}

export const saveColumnsUtils = (columns: Column[], segment: Segment, client: ApolloClient<any>, allSubsegments = false): Promise<any> => {
  const [order, hidden, fixed] = getColumnsSeparatedByStatus(columns)
  return client.mutate({
    mutation: allSubsegments ? columnIndexOrderToSubSegments : columnIndexOrder,
    variables: {
      order,
      fixed,
      hidden,
      segmentId: segment.externalId,
      ...(allSubsegments ? {} : { applyUCL: false }),
    },
  })
}

export const onSendEmailClick = (selectedContacts: Contact[]) => {
  if (selectedContacts.length > 0) {
    const contactsIds = selectedContacts.map(({ recId }) => recId)
    sendEmailToContact(contactsIds.toString())
  }
}

export const getContactDetailsEmptyState = ({ externalId, isEditable }: Segment, isBounceSegment = false, search: string) => {
  if (search !== '') {
    return ContactDetailsEmptyStates.SEARCH
  }
  if (externalId === smsBouncesVerbs[BouncesSegments.SMS_OPT_IN]) {
    return ContactDetailsEmptyStates.SMS_OPT_IN
  }
  if (isBounceSegment) {
    return ContactDetailsEmptyStates.BOUNCE
  }
  if (isEditable) {
    return ContactDetailsEmptyStates.EDITABLE_SEGMENT
  }
  return ContactDetailsEmptyStates.NON_EDITABLE_SEGMENT
}

const commonEmptyStateProps = (isDirectSelect = false): EmptyListingProps => ({
  imgSrc: StaticImageNames.errorNothingFound,
  size: EmptyListingSize.LARGE,
  textAlign: TextAlign.CENTER,
  textType: TextType.BODY_TEXT_LIGHT,
  headline: isDirectSelect ? 'This segment has no contacts!' : 'No contacts match your segment conditions',
  className: `contacts-details__empty-listing`,
})

enum ContactDetailsEmptyStates {
  EDITABLE_SEGMENT,
  NON_EDITABLE_SEGMENT,
  BOUNCE,
  SMS_OPT_IN,
  SEARCH,
}

export const contactDetailsEmptyStateData: { [key in ContactDetailsEmptyStates]: (options: any) => EmptyListingProps } = {
  [ContactDetailsEmptyStates.EDITABLE_SEGMENT]: ({ isDirectSelect, segment, segmentSearch, searchAllItems }) => ({
    ...commonEmptyStateProps(isDirectSelect),
    buttonOnClick: () => goEditSegment(segment, CONTACTS_DETAILS_URL, { search: segmentSearch, searchAll: searchAllItems }),
    text: `Try editing this segment ${
      isDirectSelect ? 'and selecting some contacts to begin targeting' : 'to begin targeting contacts'
    } by location, behavior, activity, engagement, and more.`,
    buttonText: 'Edit segment',
  }),
  [ContactDetailsEmptyStates.NON_EDITABLE_SEGMENT]: ({ isDirectSelect }) => ({
    ...commonEmptyStateProps(isDirectSelect),
    buttonURL: `${rootContext}/datamanagement`,
    text: 'Visit Data Management to import contacts or sync your CRM.',
    buttonText: 'Data Management',
  }),
  [ContactDetailsEmptyStates.BOUNCE]: () => ({
    ...commonEmptyStateProps(),
    text: 'Contacts will automatically be added to this segment when they qualify.',
  }),
  [ContactDetailsEmptyStates.SMS_OPT_IN]: () => ({
    ...commonEmptyStateProps(),
    text: 'Visit SMS Opt Ins to select contacts.',
    buttonText: 'Select SMS Opt-Ins',
    buttonURL: `${rootContext}/sms/optins`,
  }),
  [ContactDetailsEmptyStates.SEARCH]: () => ({
    imgSrc: StaticImageNames.emptySearch,
    size: EmptyListingSize.MEDIUM,
    text: 'There were no contacts matching your search.',
    headline: 'No results found',
  }),
}
