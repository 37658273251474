import React, { FC, useEffect, useState } from 'react'

import { useDebouncedCallback } from 'use-debounce'

import { DiscardModalActions } from '@components/DiscardModal/DiscardModal'
import Input from '@components/Input/Input'
import Typography from '@components/Typography/Typography'
import { validateRangedIp } from '@src/pages/Settings/OtherSettings/IpAddresses/utils/Ipaddresses.utils'
import { IPRegExp } from '@utils/formUtils'

import './singleInput.css'

interface IPS_Validity {
  [key: number]: boolean
}

interface Props {
  value: string
  columnIndex: number
  columnLength: number
  index: number
  discardModal: boolean
  state: string[][]
  setState: (arr: string[][]) => void
  ipsValidity: object
  updateValidity: (ipsValidityParam: IPS_Validity) => void
  initialData: string[][]
  discardAction: string
  setDiscardAction: (action: string) => void
  setShowSaveBanner: (action: boolean) => void
}

const rootClass = 'single-input'
const DEBOUNCE_TIME = 300

const SingleInput: FC<Props> = ({
  index,
  columnIndex,
  columnLength,
  setState,
  state,
  ipsValidity,
  updateValidity,
  discardModal,
  setDiscardAction,
  discardAction,
  setShowSaveBanner,
}: Props) => {
  const [isValid, setIsValid] = useState<boolean>(true)

  useEffect(() => {
    discardAction === DiscardModalActions.DISCARD && setIsValid(true)
    discardAction === DiscardModalActions.CONTINUE && setShowSaveBanner(true)
    setDiscardAction('')
  }, [discardModal])

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowSaveBanner(true)
    const data = [
      ...state.slice(0, columnIndex),
      [...state[columnIndex].slice(0, index), event.target.value, ...state[columnIndex].slice(index + 1)],
      ...state.slice(columnIndex + 1),
    ]
    setState(data)
    validateInput(event.target.value)
  }

  const validateInput = useDebouncedCallback((value) => {
    checkValidity(value)
  }, DEBOUNCE_TIME)

  const checkValidity = (item: string) => {
    const validity = !item || (IPRegExp.test(item) && validateRangedIp(item))
    setIsValid(validity)
    updateValidity({ ...ipsValidity, [inputIndex]: validity })
  }

  const inputIndex = columnIndex * columnLength + index + 1

  return (
    <>
      <Typography className={`${rootClass}__index`} text={inputIndex} />
      <Input
        data-testid={inputIndex}
        className={`${rootClass}__input`}
        value={state[columnIndex][index]}
        error={!isValid}
        onChange={(event) => onInputChange(event)}
      />
    </>
  )
}

export default React.memo(SingleInput)
