import { gql } from '@apollo/client'

export default gql`
  query getAllScheduledMessages {
    getAllScheduledMessages {
      id
      msgTitle
      scheduledBy
      sentTime
      previewUrl
    }
  }
`
