import React, { FC, ReactNode, useCallback } from 'react'

import classNames from 'classnames'

import InfoTooltip, { InfoTooltipIconSize } from '@components/InfoTooltip/InfoTooltip'
import Spinner, { LoaderSize } from '@components/Spinner/Spinner'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Toggletip, { ToggletipProps, ToggletipTriggerSize } from '@components/Toggletip/Toggletip'
import Typography, { TextWeight, TypographyProps } from '@components/Typography/Typography'

import OptionsBlock from './components/OptionsBlock/OptionsBlock'

import './ValidationsList.css'

export enum ValidationSectionStatus {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  REST = 'rest',
}

export type TextLinkProps = React.ComponentProps<typeof TextLink>

export type ValidationOption =
  | string
  | (TypographyProps & {
      link?: string
      list?: string[]
      onClick?: () => void
    })

export interface Validation {
  key: string
  title: string | ReactNode
  status: ValidationSectionStatus
  options?: ValidationOption[]
  loading?: boolean
  titleProps?: TypographyProps
  allMedium?: boolean
  link?: TextLinkProps['link']
  onClick?: TextLinkProps['onClick']
  hideLinkIcon?: TextLinkProps['hideIcon']
  infoTooltipContent?: ReactNode | ReactNode[]
  tooltipContent?: ReactNode | ReactNode[]
  infoToggletipProps?: ToggletipProps
  hasTooltip?: boolean
  alignContent?: boolean
}

export interface ValidationSectionCardProps {
  validations?: Validation[]
  className?: string
  dataTest?: string
}

const rootClass = 'validations-list'

const ValidationsList: FC<ValidationSectionCardProps> = ({ validations, className, dataTest = rootClass }) => {
  const getSvgName = useCallback(
    (status: ValidationSectionStatus) =>
      status === ValidationSectionStatus.ERROR
        ? SvgNames.spamCheckFailNoFill
        : status === ValidationSectionStatus.SUCCESS
        ? SvgNames.spamCheckSuccessNoFill
        : SvgNames.spamCheckWarningNoFill,
    []
  )

  if (!validations) {
    return null
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {validations.map((validation: Validation) => {
        const {
          key,
          status,
          title,
          options,
          loading,
          allMedium,
          link,
          onClick,
          hideLinkIcon,
          titleProps = {},
          infoTooltipContent,
          infoToggletipProps,
          alignContent,
        } = validation

        if (!title) {
          return null
        }

        const isRest = status === ValidationSectionStatus.REST && !loading
        const redirectLinkContextDisabled = titleProps?.values?.context === 'disabled'

        return (
          <div key={key} className="push-up" data-test={`${dataTest}-item`}>
            <div
              className={classNames(`${rootClass}__item-header`, {
                [`${rootClass}__item-header-rest`]: isRest,
                [`${rootClass}__item-header-align`]: alignContent,
                [`${rootClass}__item-header-disabledLinks`]: redirectLinkContextDisabled,
              })}
            >
              {loading ? (
                <Spinner size={LoaderSize.CUSTOM} unsizeWrapper customSize={{ width: '16px', height: '16px' }} />
              ) : (
                !isRest && <Svg name={getSvgName(status)} type={SvgType.LARGER_ICON} />
              )}
              {typeof title === 'string' ? (
                <Typography
                  text={title}
                  weight={allMedium ? TextWeight.MEDIUM : undefined}
                  // This tapProps and Components are widely used, so put here by default
                  tagProps={{ medium: { weight: TextWeight.MEDIUM } }}
                  tagComponents={{
                    TextLink: (
                      <TextLink
                        link={link}
                        size={TextLinkSize.LARGE}
                        onClick={onClick}
                        dataTest={`${dataTest}-${key}-link`}
                        hideIcon={hideLinkIcon}
                      />
                    ),
                  }}
                  inline
                  {...titleProps}
                  infoTooltipProps={
                    infoTooltipContent
                      ? {
                          minimalPadding: false,
                          iconSize: InfoTooltipIconSize.SMALL,
                          className: `${rootClass}__item-tooltip`,
                          children: infoTooltipContent,
                        }
                      : undefined
                  }
                  infoToggletipProps={
                    infoToggletipProps
                      ? { ...infoToggletipProps, triggerSize: ToggletipTriggerSize.SMALL, isDismissable: true, inline: true }
                      : undefined
                  }
                />
              ) : (
                <>
                  {title}
                  {infoTooltipContent && (
                    <InfoTooltip minimalPadding={false} iconSize={InfoTooltipIconSize.SMALL} className={`${rootClass}__item-tooltip`}>
                      {infoTooltipContent}
                    </InfoTooltip>
                  )}
                  {infoToggletipProps && <Toggletip {...infoToggletipProps} triggerSize={ToggletipTriggerSize.SMALL} isDismissable inline />}
                </>
              )}
            </div>
            {options ? <OptionsBlock options={options} /> : null}
          </div>
        )
      })}
    </div>
  )
}

export default ValidationsList
