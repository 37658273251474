import { Dispatch, ReactNode, SetStateAction } from 'react'
import { UseTranslationResponse } from 'react-i18next'

import { ApolloClient } from '@apollo/client'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { Status } from '@components/StatusToast/StatusToast'
import { FolderDto, GetItemTypesByLabelQuery, ItemDto, LabelDto } from '@graphql/types/microservice/categorization-types'
import { Errors } from '@interface/common'
import { Folder } from '@interface/Folder'
import { ItemType } from '@utils/categorization'
import { FilterDefinition } from '@utils/filter'
import { DeleteConfirmationModals } from '@utils/listPageDeleteModals'
import { FetchPromise } from '@utils/types'

export type DeleteFolder = (folder: FolderData) => void
export type DeleteTag = (tag: LabelDto) => void
export type SetFolder = (folderId: number) => void
export type SetTag = (tagId: number) => void
export type SetFilter = (filter: FilterDefinition) => void
export type ApplyAndRemoveTags = (segments: number[], tagsToApply: LabelDto[], tagsToRemove: number[]) => Promise<boolean>
export type CreateFolder = (folder: FolderDto) => void
export type GetFolders = () => void
export type RenameFolder = (folder: FolderDto) => void
export type CreateTag = (tag: LabelDto) => void
export type GetItemTypesUsedInTags = (tagId: number) => FetchPromise<GetItemTypesByLabelQuery>

export type Update<T> = (FILL_IN: keyof T, value: any) => void
export type SetContainerValues<T> = Dispatch<SetStateAction<T>>
export type InitItemsLoading<T> = (setContainerValues: SetContainerValues<ListPageState<T>>, pageNumber?: number, needsLoading?: boolean) => void
export type SetItems<T> = (
  setContainerValues: SetContainerValues<ListPageState<T>>,
  newItems: ItemDto[],
  pageNumber: number,
  pageSize: number,
  refetchAllPages?: boolean
) => void

export type ListPageStatusToast = { title?: string; statusMessage: string | ReactNode; status: Status; showStatusToast: boolean; folderName?: string }

export interface ListPageUtilsConstants<T> {
  client: ApolloClient<any>
  update: Update<ListPageState<T>>
  setContainerValues: SetContainerValues<ListPageState<T>>
  initItemsLoading: InitItemsLoading<T>
  setItems: SetItems<T>
  t: UseTranslationResponse<'translation', undefined>
  itemType: ItemType
  className?: string
}

export interface ListPageState<K> {
  items: K[]
  activeFolderId?: number
  activeTagId?: number
  creatingFolder: boolean
  creatingTag: boolean
  filterActive: FilterDefinition | undefined
  folders: Folder[]
  tags: LabelDto[]
  itemTypesUsed: Record<string, number>
  deleteConfirmationData: any
  confirmationModal: DeleteConfirmationModals | undefined
  loading: boolean
  errors?: Errors
  pageError: boolean
  folderPath: FolderData[]
  saved: boolean
  search: string
  searchResults: number
  searchItemsResults: K[]
  searchItemsLoading: boolean
  searchAllItems?: boolean
  isProcessingAction: boolean
  foldersLoading: boolean
  tagsLoading: boolean
  movingItem: boolean
  showManageTag: boolean
  showShare?: boolean
  showMoveToFolder?: boolean
  statusToast: ListPageStatusToast
  currentPage: number
  allItemsLoaded: boolean
  infoHoverLoading: boolean
  selectingImportContactsSource: boolean
  importContactsSource: string
}

export const listPageContextValues: ListPageState<any> = {
  creatingFolder: false,
  creatingTag: false,
  filterActive: undefined,
  activeFolderId: undefined,
  folders: [],
  tags: [],
  errors: {},
  folderPath: [],
  deleteConfirmationData: [],
  confirmationModal: undefined,
  loading: false,
  pageError: false,
  saved: false,
  search: '',
  searchResults: 0,
  searchItemsResults: [],
  searchItemsLoading: false,
  items: [],
  itemTypesUsed: {},
  isProcessingAction: false,
  foldersLoading: true,
  tagsLoading: true,
  movingItem: false,
  showManageTag: false,
  statusToast: { statusMessage: '', status: Status.FAIL, showStatusToast: false },
  currentPage: 0,
  allItemsLoaded: false,
  infoHoverLoading: false,
  selectingImportContactsSource: false,
  importContactsSource: '',
}
