import { Row } from 'react-table'

import { Column } from '@components/ColumnsOrderModal/components/DraggableColumn/DraggableColumn'
import { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { HeaderAction, RowAction } from '@components/Table/Table'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { Contact } from '@utils/contact/contact.constants'
import { sendEmailToContact } from '@utils/contact/contact.utils'
import { ContactViewPanel, openContactView } from '@utils/contacts'
import { saveSelectedContactsToLocalStorage } from '@utils/contactSegments/contactSegments.utils'

export enum ContactsTableActions {
  ADD_TO_SEGMENT = 'addToSegment',
  DELETE = 'delete',
  EXPORT = 'export',
  REMOVE_FROM_BOUNCE = 'removeFromBounce',
  REMOVE_FROM_SEGMENT = 'removeFromSegment',
  SEND_EMAIL = 'sendEmail',
  SEND_SMS = 'sendSms',
}

export const getContactsHeaderActions = (
  isDirectSelect: boolean,
  doTableAction: Function,
  allowToDelete: boolean,
  hasNewSegmentComposerUI: boolean,
  disableSegmentModifications?: boolean
): HeaderAction[] => [
  {
    label: 'Add to direct select segment',
    icon: SvgNames.groupAdd,
    hasTooltip: true,
    hidden: !hasNewSegmentComposerUI,
    onClick: () => doTableAction(ContactsTableActions.ADD_TO_SEGMENT),
  },
  {
    label: 'Send Email',
    icon: SvgNames.letter,
    hasTooltip: true,
    onClick: () => doTableAction(ContactsTableActions.SEND_EMAIL),
  },
  {
    disabled: disableSegmentModifications,
    tooltipMessage: disableSegmentModifications ? 'Segment.Tooltip.Disabled.Migration' : undefined,
    hidden: !isDirectSelect,
    label: 'Remove from segment',
    icon: SvgNames.removeContact,
    hasTooltip: true,
    inDropdown: false,
    onClick: () => doTableAction(ContactsTableActions.REMOVE_FROM_SEGMENT),
  },
  {
    disabled: disableSegmentModifications || !allowToDelete,
    hidden: !allowToDelete,
    tooltipMessage: disableSegmentModifications ? 'Segment.Tooltip.Disabled.Migration' : 'Ask your administrator for permission to do this',
    label: 'Delete',
    icon: SvgNames.delete,
    iconSize: SvgType.ICON,
    hasTooltip: true,
    inDropdown: false,
    onClick: () => doTableAction(ContactsTableActions.DELETE),
  },
]

export const getContactsRowActions = (
  isDirectSelect: boolean,
  onDeleteContactClick: (row: Row<Contact>) => void,
  onRemoveContactFromSegment: (recIds: string[]) => void,
  onAddToSegmentClick: (contacts: Contact[]) => void,
  allowToDelete: boolean,
  contactsHeaders: Column[],
  unifiedListFieldMappings: UnifiedListFieldMapping[],
  hasNewSegmentComposerUI: boolean,
  disableSegmentModifications?: boolean
): RowAction[] => [
  {
    label: 'Send Email',
    icon: SvgNames.letter,
    onClick: (row: Row<Contact>) => {
      saveSelectedContactsToLocalStorage([row.original], contactsHeaders, unifiedListFieldMappings)
      sendEmailToContact(row?.original.recId)
    },
  },
  {
    label: 'Contact Report',
    icon: SvgNames.contactReport,
    onClick: (row: Row<Contact>) => openContactView(row?.original.recId, ContactViewPanel.SUMMARY),
  },
  {
    label: 'Edit contact',
    icon: SvgNames.pencil,
    inDropdown: true,
    disabled: disableSegmentModifications,
    tooltipMessage: disableSegmentModifications ? 'Segment.Tooltip.Disabled.Migration' : undefined,
    hasTooltip: true,
    onClick: (row: Row<Contact>) => openContactView(row?.original.recId, ContactViewPanel.INFO),
  },
  {
    label: 'Add to direct select segment',
    icon: SvgNames.groupAdd,
    hidden: !hasNewSegmentComposerUI,
    inDropdown: true,
    onClick: (row: Row<Contact>) => onAddToSegmentClick([row?.original]),
  },
  {
    disabled: !allowToDelete || disableSegmentModifications,
    hasTooltip: true,
    tooltipMessage: disableSegmentModifications ? 'Segment.Tooltip.Disabled.Migration' : 'Ask your administrator for permission to do this',
    label: 'Delete contact',
    icon: SvgNames.delete,
    inDropdown: true,
    onClick: onDeleteContactClick,
  },
  {
    disabled: disableSegmentModifications,
    tooltipMessage: disableSegmentModifications ? 'Segment.Tooltip.Disabled.Migration' : undefined,
    hasTooltip: true,
    hidden: !isDirectSelect,
    label: 'Remove from Segment',
    icon: SvgNames.removeContact,
    inDropdown: true,
    onClick: (row: Row<Contact>) => onRemoveContactFromSegment([row?.original.recId]),
  },
]
