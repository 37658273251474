import React, { FC, useState, ChangeEvent, useEffect } from 'react'
import { FieldValues, useForm } from 'react-hook-form'

import classNames from 'classnames'
import * as yup from 'yup'

import CreateSegmentModal from '@components/CreateSegmentModal/CreateSegmentModal'
import FormRow from '@components/FormRow'
import Input from '@components/Input/Input'
import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import { ModalBody } from '@components/Modal'
import SelectOrCreateItem from '@components/SelectOrCreateItem/SelectOrCreateItem'
import { SelectV2Option, SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { yupResolver } from '@hookform/resolvers/yup'
import CopyToListStep from '@src/pages/programs/dashboard/components/ProgramSteps/components/CopyToListStep/CopyToListStep'
import { SubmitSelectOption } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditAppendToSegmentStep/EditAppendToSegmentStepContainer'
import { ProgramAppendToSegmentStepExt } from '@utils/program/program.constants'

import './EditAppendToSegmentStep.css'

interface EditAppendToSegmentStepProps {
  step: ProgramAppendToSegmentStepExt
  closeModal(): void
  submitId: string
  defaultOption: SelectV2SingleOption | undefined
  handleFormSubmit: (data: SubmitSelectOption) => void
  listOptions: SelectV2SingleOption[]
  getSegmentOptions: (currentPage: number) => Promise<SelectV2Option[]>
  searchSegment: (search: string) => Promise<SelectV2Option[]>
  createNewSegment: (name: string) => void
  stepTitle: string
  loading: boolean
  isRunning: boolean
  className?: string
  dataTest?: string
}

const rootClass = 'edit-append-to-segment-step'

const EditAppendToSegmentStep: FC<EditAppendToSegmentStepProps> = (props: EditAppendToSegmentStepProps) => {
  const {
    step,
    stepTitle,
    loading,
    defaultOption,
    listOptions,
    getSegmentOptions,
    searchSegment,
    isRunning,
    handleFormSubmit,
    submitId,
    createNewSegment,
    dataTest = rootClass,
    className = '',
  } = props
  const [inputValue, setInputValue] = useState<string>(stepTitle)

  const [showCreateSegmentModal, setShowCreateSegmentModal] = useState<boolean>(false)
  const [selectedOption, setSelectedOption] = useState<SelectV2SingleOption | undefined>(defaultOption)
  const { t } = useTranslation()

  useEffect(() => {
    setSelectedOption(defaultOption)
  }, [defaultOption])

  const schema = yup.object().shape({
    displayName: yup.string().required('Step Name is required.'),
    value: yup.lazy(() => (selectedOption ? yup.string().notRequired() : yup.string().required('Segment is required.'))),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })

  const onSubmit = ({ displayName }: FieldValues) => {
    const { label, value } = { ...defaultOption, ...selectedOption }
    if (value && label) {
      handleFormSubmit({
        displayName,
        label,
        value,
      })
    }
  }

  const onSubmitCreateSegmentModal = (name: string) => {
    setShowCreateSegmentModal(false)
    createNewSegment(name)
  }

  const renderDropdownAndButton = () => {
    return (
      <SelectOrCreateItem
        className={`${rootClass}__select-or-create-item`}
        options={listOptions}
        loadNewOptions={(nextPage) => getSegmentOptions(nextPage)}
        searchOptions={searchSegment}
        placeholder={t('Select')}
        showCreateItemModal={showCreateSegmentModal}
        setSelectedOption={setSelectedOption}
        defaultValue={defaultOption}
        setShowCreateItemModal={setShowCreateSegmentModal}
        buttonText={t('New segment')}
        insideModal
        createItemModal={
          <CreateSegmentModal
            isOpen={showCreateSegmentModal}
            closeModal={() => setShowCreateSegmentModal(false)}
            onSubmit={onSubmitCreateSegmentModal}
          />
        }
      />
    )
  }

  const renderForm = () => (
    <form data-test={dataTest} onSubmit={handleSubmit(onSubmit)}>
      <Typography className={`${rootClass}__text`} text={t('Add contacts to an All Contacts segment')} type={TextType.BODY_TEXT_LIGHT} />
      <FormRow>
        <Input
          className={`${rootClass}__step-name`}
          label={t('Step Name')}
          defaultValue={inputValue}
          trim={false}
          register={register('displayName', { onBlur: (e: ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value) })}
        />
        {errors.displayName && <span className="error">{t(errors.displayName.message)}</span>}
      </FormRow>
      <FormRow>
        <div className={classNames(`${rootClass}__flex`, `${rootClass}__flex-align-items`, `${rootClass}__select-label`)}>
          <Typography text={t('Select a segment')} weight={TextWeight.MEDIUM} type={TextType.BODY_TEXT_SMALL} />
          <Tooltip triggerClassName={`${rootClass}__select-label-tooltip`} trigger={<Svg name={SvgNames.info} type={SvgType.ICON} />}>
            {t('You can only select top level direct select segments')}
          </Tooltip>
        </div>
        {renderDropdownAndButton()}
        {errors.value && <span className="error">{t(errors.value.message)}</span>}
      </FormRow>
      <button type="submit" id={submitId} hidden />
    </form>
  )

  const renderView = () => {
    const listName = selectedOption?.label ?? ''
    return (
      <>
        <Typography text={stepTitle} weight={TextWeight.MEDIUM} type={TextType.SECTION_HEADER} />
        <CopyToListStep listName={listName} listId={step?.listId ?? ''} update={false} isCopy={false} />
      </>
    )
  }
  const renderModalBody = () => {
    return <ModalBody className={classNames(rootClass, className)}>{isRunning ? renderView() : renderForm()}</ModalBody>
  }

  return (
    <div className={classNames(rootClass, { [`${rootClass}__loading`]: loading })} data-test={dataTest}>
      {loading ? <Loader loaderType={LoaderTypes.page} /> : <>{renderModalBody()}</>}
    </div>
  )
}

export default EditAppendToSegmentStep
