import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import TooltipButton from '@components/TooltipButton/TooltipButton'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './AccordionStep.css'

interface AccordionStepProps {
  className?: string
  dataTest?: string
  stepIcon: SvgNames
  label: string
  onRemove?: () => void
  onClick?: () => void
  onContinue?: () => void
  children: ReactNode
  completed: boolean
  error?: boolean
  isOpen?: boolean
}

const rootClass = 'accordion-step'

const AccordionStep: FC<AccordionStepProps> = (props: AccordionStepProps) => {
  const {
    dataTest = rootClass,
    className = '',
    children,
    stepIcon,
    label,
    completed,
    error = false,
    isOpen = false,
    onRemove,
    onClick,
    onContinue,
  } = props
  const { t } = useTranslation()

  const disableContinue = !completed || error

  return (
    <div className={classNames(rootClass, className, { [`${rootClass}__selected`]: isOpen })} data-test={dataTest}>
      <div
        className={classNames(`${rootClass}__header`, 'flex-align-center')}
        onClick={onClick}
        role={'button'}
        tabIndex={0}
        onKeyDown={(keyDownEvent) => (keyDownEvent.key === '' || keyDownEvent.key === 'Enter' ? onClick?.() : undefined)}
        data-test={`${rootClass}-header`}
      >
        <div className={classNames(`${rootClass}__header-label`, 'flex-align-center')}>
          <Svg name={stepIcon} type={SvgType.LARGE_ICON} />
          <Typography text={label} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.MEDIUM} />
        </div>
        <div className={classNames(`${rootClass}__header-right`, 'flex-align-center')}>
          {onRemove && (
            <Button
              className={`${rootClass}__header-remove`}
              buttonType={ButtonType.DELETE_OUTLINE}
              onClick={(e) => {
                e.stopPropagation()
                onRemove()
              }}
            >
              {t('Remove')}
            </Button>
          )}
          <Tooltip
            disableTooltip={!error}
            trigger={
              <Svg
                name={error ? SvgNames.errorSolid : completed ? SvgNames.checkboxTeal : SvgNames.checkCircleFull}
                type={SvgType.LARGE_ICON}
                className={`${rootClass}__header-icon`}
              />
            }
          >
            {t('AccordionStep.Error.Tooltip')}
          </Tooltip>
        </div>
      </div>
      {isOpen && (
        <div className={`${rootClass}__content`} data-test={`${dataTest}-content`}>
          {children}
          {onContinue && (
            <TooltipButton
              className={rootClass}
              fullWidth
              buttonType={ButtonType.PRIMARY}
              disabled={disableContinue}
              onClick={onContinue}
              hideTooltip={!disableContinue}
              title={t('AccordionStep.Continue.Disabled')}
            >
              {t('Continue')}
            </TooltipButton>
          )}
        </div>
      )}
    </div>
  )
}

export default AccordionStep
