import { legacyActonContext } from '@const/globals'

export interface ContactInformationFields {
  'Act-On Primary Score': string | null
  'Business Street': string | null
  'Cell Phone': string | null
  Company: string | null
  'E-mail Address': string | null
  'First Name': string | null
  'Job Title': string | null
  'Last Name': string | null
}

export const sendEmailToContact = (recId: string) => {
  window.open(`${legacyActonContext}/_compose/start.jsp?cid=${recId}&allContacts=1&selectedContacts=1`, `_blank`)
}
