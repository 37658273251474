import React, { FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import Breadcrumbs, { BreadcrumbsItemType } from '@components/Breadcrumbs/Breadcrumbs'
import InfoCards, { CardsOptions } from '@components/InfoCard/InfoCard'
import ItemActivityDetailsSection from '@components/ItemActivity/components/ItemActivityDetailsSection/ItemActivityDetailsSection'
import { useGetItemActivityQueries } from '@components/ItemActivity/ItemActivity.graphQL'
import { createTagUtils, getAllActivityUtils, moveItemsIntoFolder } from '@components/ItemActivity/ItemActivityUtils'
import MoveToFolderModal from '@components/MoveToFolderModal/MoveToFolderModal'
import { SegmentDetailsContext } from '@components/SegmentDetails/context/SegmentDetails.context'
import { Status } from '@components/StatusToast/StatusToast'
import { SvgType } from '@components/Svg'
import { renderColWithTitle } from '@components/Table/components/tableColumns'
import { TableColumn, TableColumnAlign } from '@components/Table/Table'
import TableWithLoader from '@components/TableWithLoader/TableWithLoader'
import TagManager from '@components/TagManager/TagManager'
import TagManagerTriggerWithText from '@components/TagManagerTriggerWithText/TagManagerTriggerWithText'
import TagWithName from '@components/TagWithName/TagWithName'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import { SegmentActivityDto } from '@graphql/types/microservice/segment-types'
import { Folder } from '@interface/Folder'
import { ItemType } from '@utils/categorization'
import { Segment } from '@utils/contactSegments/contactSegments.utils'
import { formatSendTSDate } from '@utils/date'
import { buildHeader } from '@utils/folderUtils'
import { NO_COLOR, sortTagsByName } from '@utils/tags'

import './ItemActivity.css'

export type SectionType = {
  action?: Function
  actionText?: string
  children: ReactNode
  title: string
}

export enum ActivityTypes {
  AUTOMATED_PROGRAMS = 'AUTOMATED_PROGRAMS',
  CREATED = 'CREATED',
  EDITED = 'EDITED',
  EXPORT = 'EXPORT',
  FORM = 'FORM',
  FTP_EXPORT = 'FTP_EXPORT',
  FTP_IMPORT = 'FTP_IMPORT',
  IMPORT = 'IMPORT',
  LIST_MAINTENANCE_PROGRAMS = 'LIST_MAINTENANCE_PROGRAM',
  MESSAGE = 'MESSAGE',
  SMS = 'SMS',
  SMS_SCHEDULED = 'SMS_SCHEDULED',
  WEBINAR = 'WEBINAR',
  CUSTOM = 'CUSTOM',
}
export enum CustomActivityTypes {
  AB_SPLIT = 'A/B split',
  RECORD_CLEARED = 'Records Cleared',
  SEGMENT_CREATED = 'Segment Created',
}

export interface ItemActivityState {
  allActivity: SegmentActivityDto[]
  loadingAllActivity: boolean
  changeFolder: boolean
  moveToFolderId?: number
  tags: LabelDto[]
}

const initialState = {
  changeFolder: false,
  allActivity: [],
  loadingAllActivity: false,
  tags: [],
}

interface Props {
  className?: string
  dataTest?: string
  onApplyAndRemoveTagsSuccess: (updatedLabels: LabelDto[]) => void
  segment: Segment
  itemType: ItemType
  onItemFolderChange: (moveToFolderId: number) => void
  customSections?: SectionType[]
  renderActivityCol: (row: any) => ReactNode
  performanceCards: CardsOptions
}

const rootClass = 'item-activity'

const ItemActivity: FC<Props> = (props: Props) => {
  const {
    dataTest = rootClass,
    className = '',
    segment,
    itemType,
    customSections,
    performanceCards,
    onItemFolderChange,
    onApplyAndRemoveTagsSuccess,
    renderActivityCol,
  } = props

  const [state, setState] = useState<ItemActivityState>(initialState)
  const { allActivity, loadingAllActivity, changeFolder, moveToFolderId, tags } = state
  const {
    values: { folders },
    update,
  } = useContext(SegmentDetailsContext)
  const { id, folderId, externalId, tags: segmentTags } = segment
  const { t } = useTranslation()
  const {
    getActivitiesRequest,
    getTagsRequest,
    moveItemsIntoFolderRequest,
    createTagsRequest,
    applyTagsRequest,
    removeTagsRequest,
    getFoldersRequest,
  } = useGetItemActivityQueries()

  useEffect(() => {
    getAllActivityUtils(setState, getActivitiesRequest, externalId as string, itemType)
  }, [])

  useEffect(() => {
    getTagsRequest(itemType).then(({ data }) => {
      setState((state) => ({ ...state, tags: data?.getLabels as LabelDto[] }))
    })
  }, [segment])

  useEffect(() => {
    getFoldersRequest(itemType).then(({ data }) => {
      update({ folders: data?.getFolders as Folder[] })
    })
  }, [])

  useEffect(() => {
    if (moveToFolderId) {
      onItemFolderChange(moveToFolderId)
    }
  }, [moveToFolderId])

  const onApplyAndRemoveTags = async (itemId: number, tagsToApply: LabelDto[], tagsToRemove: number[]) => {
    applyTagsRequest(itemId, tagsToApply, itemType)
      .then(({ data }) => {
        if (data?.applyLabels) {
          removeTagsRequest(itemId, tagsToRemove, itemType).catch(() =>
            update({
              statusToast: { statusMessage: t("Tags couldn't be removed."), showStatusToast: true, status: Status.FAIL },
            })
          )
        }
        const updatedTags = sortTagsByName([...segmentTags.filter(({ id }) => !tagsToRemove.includes(id)), ...tagsToApply])
        onApplyAndRemoveTagsSuccess(updatedTags)
      })
      .catch(() => {
        update({ statusToast: { statusMessage: t("Tags couldn't be applied"), showStatusToast: true, status: Status.FAIL } })
      })
  }

  const onChangeFolder = (folderId: number) => {
    moveItemsIntoFolder([segment], folderId, !folderId, folders, rootClass, t, update, setState, moveItemsIntoFolderRequest, itemType)
    setState({ ...state, moveToFolderId: folderId, changeFolder: false })
  }

  const columns: TableColumn[] = useMemo(() => {
    return [
      {
        Header: 'Activity',
        accessor: 'activityName',
        align: 'left' as TableColumnAlign,
        Cell: ({ cell: { row } }) => renderActivityCol(row),
      },
      {
        Header: 'By',
        accessor: 'author',
        align: 'left' as TableColumnAlign,
        minWidth: 150,
        maxWidth: 150,
        Cell: renderColWithTitle,
      },
      {
        Header: 'Time',
        accessor: 'date',
        align: 'right' as TableColumnAlign,
        minWidth: 220,
        maxWidth: 220,
        Cell: (row: any) => formatSendTSDate(row.cell.value, { dateFormat: 'MMM d, yyyy', includeTime: true }),
        sortType: 'statusDateTime',
      },
    ]
  }, [])

  const createTag = (tag: LabelDto) => {
    tags.some(({ id, name }) => tag.id !== id && tag.name === name)
      ? update({
          statusToast: {
            statusMessage: t('A tag with the same name already exists.'),
            showStatusToast: true,
            status: Status.FAIL,
          },
        })
      : createTagUtils(update, tag, createTagsRequest, itemType)
  }
  const renderTags = () => {
    return (
      <div className={`${rootClass}__details-tags`}>
        {segmentTags.map(({ color, name }: LabelDto) => (
          <TagWithName className={`${rootClass}__details-tag`} key={name} color={color ?? NO_COLOR} name={name ?? ''} />
        ))}
        <TagManager
          trigger={<TagManagerTriggerWithText className={`${rootClass}__details-tag-manager-trigger`} text={'manage tags'} svgType={SvgType.ICON} />}
          appliedTags={segmentTags}
          onApplyAndRemove={(tagsToApply: LabelDto[], tagsToRemove: number[]) => onApplyAndRemoveTags(id, tagsToApply, tagsToRemove)}
          onCreate={(color, name) => createTag({ color, name } as LabelDto)}
          tags={tags}
          title={'Manage Tags'}
        />
      </div>
    )
  }

  const folderTreeBreadcrumbs: BreadcrumbsItemType[] = useMemo(
    () => buildHeader(segment.folderId as number, folders).map((value) => ({ text: value, hasTooltip: true })),
    [segment, folders]
  )

  const detailsSections: SectionType[] = [
    {
      children: <Breadcrumbs breadcrumbs={folderTreeBreadcrumbs} wrap />,
      title: t('Folder'),
      action: () => setState({ ...state, changeFolder: true }),
      actionText: t('Change folder'),
    },
    { children: renderTags(), title: t('Tags') },
    ...(customSections ?? []),
  ]

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {changeFolder && (
        <MoveToFolderModal
          movableItems={[segment]}
          addToFolder={!folderId}
          onMoveClick={onChangeFolder}
          folders={folders}
          onClose={() => setState({ ...state, changeFolder: false })}
          isOpen
        />
      )}
      <InfoCards data-test={dataTest} cardsOptions={performanceCards} />
      <div className={`${rootClass}__details`}>
        <div className={`${rootClass}__details-content`}>
          <Typography className={`${rootClass}__details-header`} text={t('Details')} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
          <div className={`${rootClass}__sections-wrapper`}>
            {detailsSections.map(({ children, ...rest }) => (
              <ItemActivityDetailsSection key={rest.title} {...rest}>
                {children}
              </ItemActivityDetailsSection>
            ))}
          </div>
        </div>
      </div>
      <div className={`${rootClass}__activity`}>
        <div className={`${rootClass}__activity-content`}>
          <Typography
            className={`${rootClass}__activity-content-title`}
            text={t('Activity')}
            type={TextType.SECTION_HEADER}
            weight={TextWeight.MEDIUM}
          />
          <TableWithLoader
            initialState={{ sortBy: [{ id: 'date', desc: true }] }}
            className={`${rootClass}__table`}
            data={allActivity}
            columns={columns}
            loading={loadingAllActivity}
          />
        </div>
      </div>
    </div>
  )
}

export default ItemActivity
