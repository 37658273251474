import { createContext } from 'react'

import { FilterFunctionMap, FrontendFilteringAPI, getDefaultFilteringAPI } from '@complex/ListingPage/Hooks/useFrontendFiltering'
import { PersonalizationModals } from '@complex/Personalization/Personalization.constants'
import { PersonalizationContainerState } from '@complex/Personalization/PersonalizationContainer'
import { SvgNames } from '@components/Svg'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { TypographyProps } from '@components/Typography/Typography'
import {
  FilterDefinition,
  FilterTypes,
  allPersonalizationFieldsFilter,
  contactFieldsFilter,
  contentPersonalizationFieldsFilter,
  crmFieldsFilter,
  listOrSegmentFieldsFilter,
  msdynamicsFieldsFilter,
  netsuiteFieldsFilter,
  salesforceFieldsFilter,
  senderFieldsFilter,
  sugarCRMFieldsFilter,
  zendeskFieldsFilter,
  sourceListFieldsFilter,
} from '@utils/filter'
import { ReactSetStateAction } from '@utils/interface/common'
import { StatusToastType } from '@utils/interface/StatusToast'
import { UpdateState } from '@utils/types'

export interface SetupToaster {
  showStatus: boolean
  successStatus: boolean
  typographyProps: TypographyProps
  setToastStatus: ReactSetStateAction<StatusToastType | undefined>
}

export type PersonalizationTableColumnsType<T = PersonalizationItem, D = string> = {
  [k: string]: ColumnDefWithAdditionalProps<T, D>
}

export interface PersonalizationTableProps {
  searchText: string
  rootClass: string
  activeFilter?: string
  allData: PersonalizationItem[]
  isChoosingMultipleContacts?: boolean
  isRecipientListFieldsSelected: boolean
}

export type PersonalizationItem = {
  id: string
  title: string
  valid?: boolean
  category?: string
  listSegmentName?: string
  hasFieldWarningIcon?: boolean
  group: FilterTypes
  email?: string
  fallbackText?: string
  mapping?: string
  fullNameMapping?: string
  excludeGroups?: string
}

export interface PersonalizationSelection {
  selectedField: string
  maxLength: string
  fallbackText: string
  group?: FilterTypes
}

export const defaultState: PersonalizationContainerState = {
  selection: {
    selectedField: '',
    maxLength: '',
    fallbackText: '',
  },
  isOpen: false,
  loading: true,
  canSubmit: false,
  activeModal: PersonalizationModals.DEFAULT,
  activeFilter: FilterTypes.CONTACT_FIELDS,
  activeFilterSvg: SvgNames.usersUnselectedFull,
  searchText: '',
  allData: [],
  searchAllData: true,
  sortingBy: [{ id: 'title', desc: false }],
}

export type PersonalizationModalContextValues = PersonalizationContainerState &
  FrontendFilteringAPI<PersonalizationItem> & {
    update: UpdateState<PersonalizationContainerState>
  }

export const personalizationFilters: FilterDefinition[] = [
  allPersonalizationFieldsFilter,
  contactFieldsFilter,
  listOrSegmentFieldsFilter,
  sourceListFieldsFilter,
  contentPersonalizationFieldsFilter,
  senderFieldsFilter,
  crmFieldsFilter,
  salesforceFieldsFilter,
  { ...netsuiteFieldsFilter, name: 'NetSuite CRM fields' },
  msdynamicsFieldsFilter,
  { ...zendeskFieldsFilter, name: 'Zendesk Sell fields' },
  sugarCRMFieldsFilter,
]

export const personalizationFilterFunctions: FilterFunctionMap<PersonalizationItem> = {
  [FilterTypes.ALL_PERSONALIZATION_FIELDS]: () => true,
  [FilterTypes.CONTACT_FIELDS]: (item) => item.group === FilterTypes.CONTACT_FIELDS,
  [FilterTypes.RECIPIENT_LIST_FIELDS]: (item) => item.group === FilterTypes.RECIPIENT_LIST_FIELDS,
  [FilterTypes.SOURCE_LIST_FIELDS]: (item) => item.group === FilterTypes.SOURCE_LIST_FIELDS,
  [FilterTypes.CUSTOM_ACCOUNT_FIELDS]: (item) => item.group === FilterTypes.CUSTOM_ACCOUNT_FIELDS,
  [FilterTypes.SENDER_FIELDS]: (item) => item.group === FilterTypes.SENDER_FIELDS,
  [FilterTypes.SALESFORCE_FIELDS]: (item) => item.group === FilterTypes.CRM_FIELDS,
  [FilterTypes.NETSUITE_FIELDS]: (item) => item.group === FilterTypes.CRM_FIELDS,
  [FilterTypes.MSDYNAMICS_FIELDS]: (item) => item.group === FilterTypes.CRM_FIELDS,
  [FilterTypes.ZENDESK_FIELDS]: (item) => item.group === FilterTypes.CRM_FIELDS,
  [FilterTypes.SUGAR_CRM_FIELDS]: (item) => item.group === FilterTypes.CRM_FIELDS,
  search: (item, search) => !search || item.title.toLowerCase().includes(`${search}`.toLowerCase()),
  folder: (_item, folder) => folder === undefined, // no folders here so this will never be called, but is required field
}

export const PersonalizationModalContext = createContext<PersonalizationModalContextValues>({
  ...getDefaultFilteringAPI<PersonalizationItem>(),
  ...defaultState,
  update: () => null,
})
