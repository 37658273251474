import React, { FC, useMemo } from 'react'

import classNames from 'classnames'

import ColumnChart, { ColumnChartProps } from '@components/ColumnChart/ColumnChart'
import Container from '@components/Container'
import Switch, { SwitchOption, SwitchOptions } from '@components/Switch/Switch'
import { TableProps } from '@components/Table/Table'
import { MultipleTables } from '@components/TableV2/components/MultipleTables/MultipleTables'
import { TableV2Props } from '@components/TableV2/tableV2TS/interfaces'
import TableWithLoader from '@components/TableWithLoader/TableWithLoader'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import ChartExtraElement, {
  ChartExtraElementProps,
} from '@src/pages/programs/dashboard/components/ProgramPerformance/components/ChartExtraElement/ChartExtraElement'
import { ColumnDef } from '@tanstack/react-table'
import { SortByState } from '@utils/sms.utils'

import { EmailSMSPerformanceData } from '../../EmailSMSPerformanceContainer'
import { SMSPerformanceData } from '../../SMSPerformanceContainer'

import './EmailSMSPerformanceDetail.css'

export type EmailSMSPerformanceDetailProps = ColumnChartProps &
  TableProps &
  ChartExtraElementProps & {
    pageSize: number
    pageIndex: number
    sortBy: SortByState[]
    className?: string
    dataTest?: string
    tableTitle?: string
    tableSubTitle?: string
    loading: boolean
    enableTableV2?: boolean
    setEnableTableV2?: React.Dispatch<React.SetStateAction<boolean>>
  }

const rootClass = 'email-sms-performance-detail'

const findSubrows = (tableData: any[]) => {
  let expand = {}
  tableData.forEach((data, index) => {
    if (data.subRows) {
      const expanded = { [index]: true }
      expand = { ...expand, ...expanded }
    }
  })
  return expand
}
const EmailSMSPerformanceDetail: FC<EmailSMSPerformanceDetailProps> = (props: EmailSMSPerformanceDetailProps) => {
  const {
    dataTest = rootClass,
    className = '',
    tableSubTitle,
    tableTitle,
    chartType,
    onSwitchChartType,
    pageSize,
    pageIndex,
    sortBy,
    loading,
    enableTableV2,
    setEnableTableV2,
  } = props
  const chartExtraElementProps: ChartExtraElementProps = { ...props }
  const chartProps: ColumnChartProps = { ...props, hideLegend: true }
  const tableProps: TableProps = { ...props, className, disableMultiSort: true, manualSortBy: true }
  const expand = findSubrows(tableProps.data)
  const { t } = useTranslation()

  const expanded = {
    ...expand,
  }

  const switchOptions: SwitchOption[] = [
    {
      label: SwitchOptions.PERCENT,
      tooltip: t('View % of unique messages'),
    },
    {
      label: SwitchOptions.NUMBER,
      tooltip: t('View unique counts'),
    },
  ]

  const extraElement = <ChartExtraElement {...chartExtraElementProps} className={`${rootClass}__extra-element`} />

  const tableV2Props = useMemo(
    (): TableV2Props<SMSPerformanceData | EmailSMSPerformanceData> => ({
      loading,
      sortingBy: sortBy,
      withoutBorders: true,
      manualSorting: true,
      enableInsideLoader: true,
      data: tableProps.data,
      styles: { margin: '0 -2rem -2rem' },
      //@ts-ignore should be removed after switching to tableV2
      columns: tableProps.columns as ColumnDef<SMSPerformanceData | EmailSMSPerformanceData, unknown>[],
      enablePaginate: tableProps.controlledPageCount ? tableProps.controlledPageCount > 1 : false,
      resetPageIndex: tableProps.resetPageIndex,

      pageSizeOptions: tableProps.pageSizeOptions,
      paginationState: {
        pageSize: tableProps.initialState?.pageSize ?? 10,
        pageIndex: tableProps.initialState?.pageIndex ?? 0,
        controlledPageCount: tableProps.controlledPageCount ?? 0,
      },
      onSort: tableProps.onSort,
      fetchData: tableProps.fetchData,
      onRowClicked: tableProps.onRowClicked,
    }),
    [
      loading,
      sortBy,
      tableProps.data,
      tableProps.columns,
      tableProps.resetPageIndex,
      tableProps.controlledPageCount,
      tableProps.pageSizeOptions,
      tableProps.initialState?.pageSize,
      tableProps.initialState?.pageIndex,
      tableProps.onSort,
      tableProps.fetchData,
      tableProps.onRowClicked,
    ]
  )

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <ColumnChart {...chartProps} ExtraElement={extraElement} disablePrint />
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={() => setEnableTableV2?.((prev) => !prev)}> </div>
      <Container className={`${rootClass}__container`}>
        <div className={`${rootClass}__title`}>
          <div>
            <Typography type={TextType.SECTION_HEADER} text={tableTitle} weight={TextWeight.MEDIUM} />
            <Typography type={TextType.BODY_TEXT_SMALL_LIGHT} text={t(tableSubTitle)} lineHeight={LineHeight.MEDIUM_LARGE} />
          </div>
          <Switch options={switchOptions} selected={chartType} onClick={(option) => onSwitchChartType(option as SwitchOptions)} />
        </div>
        <MultipleTables
          enableTableV2={enableTableV2}
          tableV2Props={tableV2Props}
          oldTable={
            <TableWithLoader
              loading={loading}
              {...tableProps}
              className={`${rootClass}__table`}
              initialState={{ expanded, pageSize, pageIndex, sortBy }}
              disableMultiSort
            />
          }
        />
      </Container>
    </div>
  )
}

export default EmailSMSPerformanceDetail
