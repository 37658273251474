import { gql } from '@apollo/client'

export default gql`
  query getCustomAccountCompanyInfo {
    getCustomAccountCompanyInfo {
      address {
        name
        street
        street2
        city
        state
        zip
        country
        UUID
      }
      defaultAccountAddress {
        name
        street
        street2
        city
        state
        zip
        country
        UUID
      }
      companyName
      creatorTimezone
    }
  }
`
