import React, { FC, useContext } from 'react'

import ReviewAndSendHeader from '@src/pages/EmailComposer/components/ReviewAndSend/components/ReviewAndSendHeader'
import ReviewAndSendMain from '@src/pages/EmailComposer/components/ReviewAndSend/components/ReviewAndSendMain/ReviewAndSendMain'
import ReviewAndSendSidebar from '@src/pages/EmailComposer/components/ReviewAndSend/components/ReviewAndSendSidebar'
import EmailSizeWarningModal from '@src/pages/EmailComposer/EmailModals/components/EmailSizeWarningModal/EmailSizeWarningModal'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { isCustomerCareLogin } from '@utils/cookie'

import './ReviewAndSend.css'

interface ReviewAndSendProps {
  dataTest?: string
}

const rootClass = 'review-and-send'

const ReviewAndSend: FC<ReviewAndSendProps> = ({ dataTest = rootClass }) => {
  const {
    values: {
      showEmailTooLargeWarning,
      validations: {
        contentValidations: { htmlSize },
      },
      messageConfiguration: {
        reviewAndSend: { keepTabs },
      },
    },
  } = useContext(EmailComposerContext)

  return (
    <div className={rootClass} data-test={dataTest}>
      {showEmailTooLargeWarning && <EmailSizeWarningModal htmlSize={htmlSize} />}
      {!keepTabs && <ReviewAndSendHeader rootClass={isCustomerCareLogin() ? `${rootClass}__header__customer-care` : `${rootClass}__header`} />}
      <div className={`${rootClass}__container`}>
        <ReviewAndSendMain className={`${rootClass}__main`} />
        <ReviewAndSendSidebar rootClass={`${rootClass}__sidebar`} />
      </div>
    </div>
  )
}

export default ReviewAndSend
