import React, { FC, useContext, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { Checkbox } from '@components/Checkbox/Checkbox'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Typography, { ModalBodyStyle } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import {
  FeedbackOption,
  getFeedbackOptionText,
} from '@src/pages/RecommendedSegments/components/DiscardedSegmentFeedbackModal/utils/DiscardedSegmentFeedbackModal.constants'
import { RecommendedSegmentsContext } from '@src/pages/RecommendedSegments/utils/RecommendedSegments.context'

import './DiscardedSegmentFeedbackModal.css'

interface DiscardedSegmentFeedbackModalProps {
  className?: string
  dataTest?: string
  onFeedbackSubmit: (feedback?: Map<FeedbackOption, boolean>) => void
}

interface DiscardedSegmentFeedbackModalState {
  preventFromShowingAgain: boolean
  selectedOptions: Map<FeedbackOption, boolean>
}

const rootClass = 'discarded-segment-feedback-modal'

const DiscardedSegmentFeedbackModal: FC<DiscardedSegmentFeedbackModalProps> = (props: DiscardedSegmentFeedbackModalProps) => {
  const { dataTest = rootClass, className = '', onFeedbackSubmit } = props

  const { update } = useContext(RecommendedSegmentsContext)

  const [state, setState] = useState<DiscardedSegmentFeedbackModalState>({
    preventFromShowingAgain: false,
    selectedOptions: new Map(Object.values(FeedbackOption).map((option) => [option, false])),
  })
  const { preventFromShowingAgain, selectedOptions } = state

  const { t } = useTranslation()

  const handleFooterCheckboxChange = (checked: boolean) => {
    setState({ ...state, preventFromShowingAgain: checked })
  }

  const preventModalFromShowingAgain = () => {
    //TODO hit an endpoint to prevent this modal from showing up if the "Don't show again" option is enabled
    update({ feedbackModalEnabled: false })
  }

  const handleSubmit = (feedbackOptions?: Map<FeedbackOption, boolean>) => {
    onFeedbackSubmit(feedbackOptions)
    if (preventFromShowingAgain) {
      preventModalFromShowingAgain()
    }
  }

  const renderOptions = () => {
    return Object.entries(FeedbackOption).map(([key, value]) => (
      <Checkbox
        dataTest={`${dataTest}-option-${key}`}
        key={`option-${key}`}
        checked={selectedOptions.get(value)}
        label={t(getFeedbackOptionText[value])}
        onChange={(checked) => {
          setState((state) => ({
            ...state,
            selectedOptions: selectedOptions.set(value, checked),
          }))
        }}
      />
    ))
  }

  const header = (
    <ModalHeader headerType={ModalHeaderType.List} className={`${rootClass}__header`}>
      {t('Feedback')}
    </ModalHeader>
  )

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen header={header}>
      <ModalBody className={`${rootClass}__body`}>
        <Typography text={t('To help us improve our AI model, please share why you chose to discard this recommendation?')} {...ModalBodyStyle} />
        {renderOptions()}
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.List} className={`${rootClass}__footer`}>
        <div className={`${rootClass}__footer__extra`}>
          <Checkbox
            label={t("Don't show again")}
            defaultChecked={preventFromShowingAgain}
            onChange={handleFooterCheckboxChange}
            dataTest={`${dataTest}-footer-checkbox`}
          />
        </div>
        <Button buttonType={ButtonType.TERTIARY} onClick={() => handleSubmit()} dataTest={`${dataTest}-skip-button`}>
          {t('Skip')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} onClick={() => handleSubmit(selectedOptions)} dataTest={`${dataTest}-submit-button`}>
          {t('Submit feedback')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default DiscardedSegmentFeedbackModal
