import { TFunction } from 'i18next'

import { RelativeType } from '@src/pages/SegmentComposer/SegmentComposer.constants'

export const getMoreThanOptions = (t: TFunction) => [
  {
    value: RelativeType.MINUTES,
    label: t('Minutes ago'),
  },
  {
    value: RelativeType.HOURS,
    label: t('Hours ago'),
  },
  {
    value: RelativeType.DAYS,
    label: t('Days ago'),
  },
  {
    value: RelativeType.WEEKS,
    label: t('Weeks ago'),
  },
  {
    value: RelativeType.MONTHS,
    label: t('Months ago'),
  },
  {
    value: RelativeType.QUARTERS,
    label: t('Quarters ago'),
  },
]

export const getNextLastOptions = (t: TFunction) => [
  {
    value: RelativeType.MINUTES,
    label: t('Minutes'),
  },
  {
    value: RelativeType.HOURS,
    label: t('Hours'),
  },
  {
    value: RelativeType.DAYS,
    label: t('Days'),
  },
  {
    value: RelativeType.WEEKS,
    label: t('Weeks'),
  },
  {
    value: RelativeType.MONTHS,
    label: t('Months'),
  },
  {
    value: RelativeType.QUARTERS,
    label: t('Quarters'),
  },
]

export const getRelativeRangeBehaviorOptions = (t: TFunction) => [
  {
    value: 'DAYS_AGO',
    label: t('Days ago'),
  },
  {
    value: 'WEEKS_AGO',
    label: t('Weeks ago'),
  },
  {
    value: 'MONTHS_AGO',
    label: t('Months ago'),
  },
  {
    value: 'QUARTERS_AGO',
    label: t('Quarters ago'),
  },
]

export const getRelativeRangeProfileOptions = (t: TFunction) => [
  {
    label: '1',
    options: [
      {
        value: 'DAYS_AGO',
        label: t('Days ago'),
      },
      {
        value: 'WEEKS_AGO',
        label: t('Weeks ago'),
      },
      {
        value: 'MONTHS_AGO',
        label: t('Months ago'),
      },
      {
        value: 'QUARTERS_AGO',
        label: t('Quarters ago'),
      },
    ],
  },
  {
    label: '2',
    options: [
      {
        value: 'DAYS_FROM_NOW',
        label: t('Days from now'),
      },
      {
        value: 'WEEKS_FROM_NOW',
        label: t('Weeks from now'),
      },
      {
        value: 'MONTHS_FROM_NOW',
        label: t('Months from now'),
      },
      {
        value: 'QUARTERS_FROM_NOW',
        label: t('Quarters from now'),
      },
    ],
  },
]

export const getBehaviorTimeframeConditionOptions = (t: TFunction) => {
  return [
    {
      label: '1',
      options: [
        {
          value: 'ALL_DATA',
          label: t('Across all data'),
        },
        {
          value: 'THE_LAST',
          label: t('In the last...'),
        },
        {
          value: 'MORE_THAN',
          label: t('More than...'),
        },
        {
          value: 'BEFORE_SPECIFIC_DATE',
          label: t('Before this date...'),
        },
        {
          value: 'AFTER_SPECIFIC_DATE',
          label: t('After this date...'),
        },
      ],
    },
    {
      label: '2',
      options: [
        {
          value: 'THIS_WEEK',
          label: t('This week'),
        },
        {
          value: 'THIS_MONTH',
          label: t('This month'),
        },
        {
          value: 'THIS_QUARTER',
          label: t('This quarter'),
        },
        {
          value: 'THIS_YEAR',
          label: t('This year'),
        },
      ],
    },
    {
      label: '3',
      options: [
        {
          value: 'LAST_WEEK',
          label: t('Last week'),
        },
        {
          value: 'LAST_MONTH',
          label: t('Last month'),
        },
        {
          value: 'LAST_QUARTER',
          label: t('Last quarter'),
        },
        {
          value: 'LAST_YEAR',
          label: t('Last year'),
        },
      ],
    },
    {
      label: '4',
      options: [
        {
          value: 'SPECIFIC_DATE',
          label: t('A specific date'),
        },
        {
          value: 'SPECIFIC_MONTH',
          label: t('A specific month'),
        },
        {
          value: 'SPECIFIC_QUARTER',
          label: t('A specific quarter'),
        },
      ],
    },
    {
      label: '5',
      options: [
        {
          value: 'CUSTOM_DATE_RANGE',
          label: t('Custom date range'),
        },
        {
          value: 'CUSTOM_RELATIVE_RANGE',
          label: t('Custom relative range'),
        },
      ],
    },
  ]
}

export const getDateTimeframeConditionOptions = (t: TFunction) => {
  return [
    {
      label: '1',
      options: [
        {
          value: 'TODAY',
          label: t('Today'),
        },
        {
          value: 'YESTERDAY',
          label: t('Yesterday'),
        },
        {
          value: 'THE_NEXT',
          label: t('The next...'),
        },
        {
          value: 'THE_LAST',
          label: t('The last...'),
        },
      ],
    },
    {
      label: '2',
      options: [
        {
          value: 'THIS_WEEK',
          label: t('This week'),
        },
        {
          value: 'THIS_MONTH',
          label: t('This month'),
        },
        {
          value: 'THIS_QUARTER',
          label: t('This quarter'),
        },
        {
          value: 'THIS_YEAR',
          label: t('This year'),
        },
      ],
    },
    {
      label: '3',
      options: [
        {
          value: 'NEXT_WEEK',
          label: t('Next week'),
        },
        {
          value: 'NEXT_MONTH',
          label: t('Next month'),
        },
        {
          value: 'NEXT_QUARTER',
          label: t('Next quarter'),
        },
        {
          value: 'NEXT_YEAR',
          label: t('Next year'),
        },
      ],
    },
    {
      label: '4',
      options: [
        {
          value: 'LAST_WEEK',
          label: t('Last week'),
        },
        {
          value: 'LAST_MONTH',
          label: t('Last month'),
        },
        {
          value: 'LAST_QUARTER',
          label: t('Last quarter'),
        },
        {
          value: 'LAST_YEAR',
          label: t('Last year'),
        },
      ],
    },
    {
      label: '5',
      options: [
        {
          value: 'SPECIFIC_DATE',
          label: t('A specific date'),
        },
        {
          value: 'SPECIFIC_MONTH',
          label: t('A specific month'),
        },
        {
          value: 'SPECIFIC_QUARTER',
          label: t('A specific quarter'),
        },
      ],
    },
    {
      label: '6',
      options: [
        {
          value: 'CUSTOM_DATE_RANGE',
          label: t('Custom date range'),
        },
        {
          value: 'CUSTOM_RELATIVE_RANGE',
          label: t('Custom relative range'),
        },
      ],
    },
  ]
}
