import React, { useCallback, useEffect, useRef } from 'react'

import classNames from 'classnames'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography from '@components/Typography/Typography'
import { SelectOption } from '@interface/Select'

interface SingleSelectDropdownOptionProps<T> {
  option: T
  selected: boolean
  onClick: (option: T, isDeselect: boolean) => void
  dataTest: string
}

const rootClass = 'single-select-dropdown__option'

const SingleSelectDropdownOption = <T extends SelectOption>({ option, selected, onClick, dataTest }: SingleSelectDropdownOptionProps<T>) => {
  const ref = useRef<HTMLDivElement>(null)
  const { value, label, labelNode, disabled, disabledTooltip } = option
  const displayText = labelNode || label || value

  const handleClick = useCallback(() => {
    if (!disabled) {
      onClick(option, selected)
    }
  }, [option, selected, onClick, disabled])

  useEffect(() => {
    const handleOptionSelectEvent = (e: Event) => {
      handleClick()
      e.stopPropagation()
    }
    ref.current?.addEventListener('optionSelect', handleOptionSelectEvent)
    return () => {
      ref.current?.removeEventListener('optionSelect', handleOptionSelectEvent)
    }
  }, [handleClick])

  const typographyText = <Typography text={displayText} className={classNames({ [`${rootClass}-disabled`]: disabled })} />
  return (
    <div
      onClick={handleClick}
      onKeyDown={(keyDownEvent) => (keyDownEvent.key === ' ' ? handleClick() : undefined)}
      key={value}
      role={'option'}
      aria-selected={selected}
      tabIndex={0}
      className={classNames(rootClass, {
        [`${rootClass}-selected`]: selected,
        [`${rootClass}-div-disabled`]: disabled,
      })}
      data-test={`${dataTest}-option-${value.replace(/\s+/g, '-')}`}
      ref={ref}
    >
      {disabled && disabledTooltip ? <Tooltip trigger={typographyText}>{disabledTooltip}</Tooltip> : typographyText}
      {selected && <Svg name={SvgNames.check} type={SvgType.MEDIUM} />}
    </div>
  )
}

export default SingleSelectDropdownOption
