import React, { CSSProperties, FC } from 'react'

import classNames from 'classnames'

import Modal, { ModalBody } from '@components/Modal'

import './loader.css'

const rootClass = 'loader'

export const renderLoader = (className?: string, center = true, blackout?: boolean, loaderType?: LoaderTypes) => (
  <Modal isOpen clear>
    <ModalBody className={`${rootClass}-body`}>
      <Loader center={center} className={className} blackout={blackout} loaderType={loaderType} />
    </ModalBody>
  </Modal>
)

export enum LoaderTypes {
  page = 'page',
  row = 'row',
}

interface LoaderProps {
  /** Center the loader both vertically and horizontally within container */
  center?: boolean
  /** prevent screen from being clicked */
  blackout?: boolean
  loaderType?: LoaderTypes
  className?: string
  styles?: CSSProperties
  dataTest?: string | undefined
}

const Loader: FC<LoaderProps> = (props: LoaderProps) => {
  const { className, center = false, blackout = false, loaderType = LoaderTypes.page, styles, dataTest } = props

  const getPageLoader = () => {
    return (
      <svg id="load" x="0px" y="0px" viewBox="0 0 150 150">
        <circle id="loading-inner" cx="75" cy="75" r="60" />
      </svg>
    )
  }

  const getRowLoader = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 12" aria-live={'polite'} aria-busy={'true'} aria-label="rowLoader">
        <circle fill="currentColor" stroke="none" cx="2" cy="6" r="2">
          <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1" />
        </circle>
        <circle fill="currentColor" stroke="none" cx="9" cy="6" r="2">
          <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2" />
        </circle>
        <circle fill="currentColor" stroke="none" cx="16" cy="6" r="2">
          <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3" />
        </circle>
      </svg>
    )
  }

  return (
    <div
      data-test={dataTest || rootClass}
      className={classNames(rootClass, className, {
        [`${rootClass}--page`]: loaderType === LoaderTypes.page,
        [`${rootClass}--row`]: loaderType === LoaderTypes.row,
        [`${rootClass}--center`]: center,
        [`${rootClass}--blackout`]: blackout,
      })}
      style={styles}
    >
      {loaderType === LoaderTypes.page && getPageLoader()}
      {loaderType === LoaderTypes.row && getRowLoader()}
    </div>
  )
}

export default Loader
