import { useCallback, useContext, useEffect, useRef } from 'react'
import { useHistory } from 'react-router'

import useCheckMessageContentPersonalizationRequest from '@src/pages/EmailComposer/hooks/useCheckMessageContentPersonalizationRequest'
import { useClickthroughLinksValidations } from '@src/pages/EmailComposer/hooks/useClickthroughLinksValidations'
import { useEmailTemplateUIValidations } from '@src/pages/EmailComposer/hooks/useEmailTemplateUIValidations'
import { detectEmailType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { ComposerHistoryState, EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { useEmailComposerRequests } from '@utils/composer/emailComposer/GraphQL/EmailComposerRequests.graphQL'
import { logNewRelicError } from '@utils/new-relic.utils'

export const useGetEmailValidations = () => {
  const {
    values: {
      haveUnsavedChanges,
      message: { id, templateHtml, customTextPart, messageType },
      validations: { isContentMissing, isEmailValidated },
      autoSaveSuccess,
      savedWithTempId,
      isSaving,
      retrieveMessageSuccess,
      createMessageSuccess,
    },
    api: { updateValidations, update },
  } = useContext(EmailComposerContext)

  useEmailTemplateUIValidations()

  const { validateEmailMessageRequest, deliverabilityConcernRequest } = useEmailComposerRequests()

  const history = useHistory<ComposerHistoryState>()

  const validateEmailMessage = useCallback(async () => {
    const { data, errors } = await validateEmailMessageRequest({ messageId: id })
    if (errors) {
      logNewRelicError(errors, 'Validating email message')
    } else if (data?.validateEmailMessage?.status === 'ok') {
      updateValidations({
        contentValidations: {
          ...data?.validateEmailMessage,
        },
      })
    }
  }, [id, updateValidations, validateEmailMessageRequest])

  const getDeliverabilityValidations = useCallback(async () => {
    const { data, errors } = await deliverabilityConcernRequest({ messageId: id })
    if (errors) {
      logNewRelicError(errors, 'Fetching email deliverability validations')
    } else if (data) {
      updateValidations({
        deliverabilityValidations: data.deliverabilityConcern || {},
      })
    }
  }, [deliverabilityConcernRequest, id, updateValidations])

  const checkPersonalizationFields = useCheckMessageContentPersonalizationRequest()

  const getClickthroughLinks = useClickthroughLinksValidations()

  const needToCheckValidation = useRef(!!templateHtml || !!customTextPart)

  useEffect(() => {
    needToCheckValidation.current = !!templateHtml || !!customTextPart
    update({ validations: { needToCheckValidation: needToCheckValidation.current } })
  }, [templateHtml, customTextPart, update])

  const { isEmailWebinar } = detectEmailType(messageType)

  useEffect(() => {
    const isFirstEdit = new URLSearchParams(history.location.search).get('firstEdit')

    if (
      !isSaving &&
      ((isEmailWebinar && isFirstEdit && autoSaveSuccess) ||
        (!isContentMissing &&
          !haveUnsavedChanges &&
          needToCheckValidation.current &&
          (autoSaveSuccess || (retrieveMessageSuccess && !createMessageSuccess))))
    ) {
      updateValidations({ isValidationsLoading: true })
      needToCheckValidation.current = false
      update({ validations: { needToCheckValidation: needToCheckValidation.current } })
      if (!Promise.allSettled) {
        Promise.allSettled = function (promises: any) {
          return Promise.all(
            promises.map((p: any) =>
              Promise.resolve(p).then(
                (value) => ({
                  status: 'fulfilled',
                  value,
                }),
                (reason) => ({
                  status: 'rejected',
                  reason,
                })
              )
            )
          )
        }
      }
      Promise.allSettled([validateEmailMessage(), checkPersonalizationFields(), getDeliverabilityValidations()]).finally(() => {
        updateValidations({ isValidationsLoading: false, isEmailValidated: true })
        if (isEmailWebinar && !savedWithTempId && isFirstEdit) {
          update({ sendErrorCount: true })
        }
        update({ autoSaveSuccess: undefined, loading: false })
      })
    }
  }, [
    checkPersonalizationFields,
    getClickthroughLinks,
    validateEmailMessage,
    getDeliverabilityValidations,
    updateValidations,
    isContentMissing,
    haveUnsavedChanges,
    autoSaveSuccess,
    update,
    isEmailValidated,
    isEmailWebinar,
    savedWithTempId,
    isSaving,
    retrieveMessageSuccess,
    createMessageSuccess,
  ])
}
