import React, { useMemo } from 'react'

import { TFunction } from 'i18next'

import Button, { ButtonType } from '@components/Button'
import InfoHoverCard from '@components/InfoHoverCard/InfoHoverCard'
import InfoHoverHeader from '@components/InfoHoverHeader/InfoHoverHeader'
import PillLabel, { PillLabelType } from '@components/PillLabel/PillLabel'
import Svg, { SvgNames } from '@components/Svg'
import { renderColWithTooltip } from '@components/Table/components/tableColumns'
import { TableColumn } from '@components/Table/Table'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { ColWithTooltip } from '@components/TableV2/utils/tableV2ColumnUtils'
import { checkUrlForTableV2 } from '@components/TableV2/utils/tableV2Utils'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { getUUID, useTranslation } from '@const/globals'
import { UserResponse } from '@graphql/types/query-types'
import { PERMISSION_TITLES, PERMISSION_TITLES_MAP } from '@src/pages/Settings/Users/tabs/MarketingUsers/constants/marketingUsers.constants'

interface PermissionsType {
  title: string
  values: string
}

export const useTableColumns: (rootClass: string, onEditPermissionAction: (user?: UserResponse) => void, isAccountAdmin: boolean) => TableColumn[] = (
  rootClass,
  onEditPermissionAction,
  isAccountAdmin
) => {
  const { t } = useTranslation()

  return useMemo<TableColumn[]>(
    () => [
      {
        Header: t('USER DETAILS'),
        accessor: 'fullName',
        align: 'left',
        sortType: 'caseInsensitive',
        maxWidth: 240,
        minWidth: 240,
        flexColumn: true,
        className: 'user-details',
        dataTest: (row: any) => `${rootClass}-${row.dataTest}`,
        Cell: (row: any) =>
          getFullNameColumn(
            t,
            row,
            rootClass,
            isAccountAdmin,
            onEditPermissionAction,
            row.row.original.isAdmin,
            row.row.original.permissions,
            row.row.original.launchApproval
          ),
      },
      {
        Header: t('TITLE'),
        accessor: 'title',
        align: 'left',
        maxWidth: 150,
        minWidth: 150,
        flexColumn: true,
        sortType: 'caseInsensitive',
        Cell: (row: any) => (row.row.original.title ? renderColWithTooltip(row) : <div className={'no-value-td'}>--</div>),
      },
      {
        Header: t('LOGIN'),
        accessor: 'login',
        align: 'left',
        maxWidth: 200,
        minWidth: 200,
        flexColumn: true,
        sortType: 'caseInsensitive',
        Cell: (row) => renderColWithTooltip(row),
      },
      {
        Header: t('STATUS'),
        accessor: 'isVerified',
        align: 'left',
        sortType: (row: any) => {
          return row.original.isVerified ? -1 : 1
        },
        className: 'status',
        Cell: (row) => {
          const isVerified = !!row.row.original.isVerified
          return (
            <PillLabel text={t(isVerified ? 'Verified' : 'Unverified')} type={isVerified ? PillLabelType.SUCCESS : PillLabelType.ERROR} noMargin />
          )
        },
      },
    ],
    [isAccountAdmin, onEditPermissionAction, rootClass, t]
  )
}

export const tableColumnsV2: (
  rootClass: string,
  isAccountAdmin: boolean,
  t: TFunction,
  onEditPermissionAction: (user?: UserResponse) => void
) => ColumnDefWithAdditionalProps<UserResponse>[] = (rootClass, isAccountAdmin, t, onEditPermissionAction) => {
  return [
    {
      header: 'USER DETAILS',
      accessorKey: 'fullName',
      textAlign: 'left',
      sortingFn: 'caseInsensitive',
      enableFlexCell: true,
      cell: (cell) =>
        getFullNameColumn(
          t,
          cell,
          rootClass,
          isAccountAdmin,
          onEditPermissionAction,
          cell.row.original.isAdmin,
          cell.row.original.permissions,
          cell.row.original.launchApproval
        ),
    },
    {
      header: 'TITLE',
      accessorKey: 'title',
      textAlign: 'left',
      maxSize: 150,
      sortingFn: 'caseInsensitive',
      cell: (cell) => (cell.row.original.title ? ColWithTooltip({ cell }) : <div className={'no-value-td'}>--</div>),
    },
    {
      header: 'LOGIN',
      accessorKey: 'login',
      textAlign: 'left',
      sortingFn: 'caseInsensitive',
      cell: (cell) => ColWithTooltip({ cell }),
    },
    {
      header: 'STATUS',
      accessorKey: 'isVerified',
      textAlign: 'left',
      maxSize: 130,
      className: 'status',
      sortingFn: (cell) => (cell.original.isVerified ? -1 : 1),
      cell: (cell) => {
        const isVerified = !!cell.row.original.isVerified
        return <PillLabel text={t(isVerified ? 'Verified' : 'Unverified')} type={isVerified ? PillLabelType.SUCCESS : PillLabelType.ERROR} noMargin />
      },
    },
  ]
}

const getFullNameColumn = (
  t: TFunction,
  row: any,
  rootClass: string,
  isAccountAdmin: boolean,
  onEditPermissionAction: (user?: UserResponse) => void,
  isAdmin?: boolean,
  permissions?: {},
  launchApproval?: string
) => {
  return (
    <>
      {checkUrlForTableV2() ? <ColWithTooltip cell={row} /> : renderColWithTooltip(row)}
      {isAdmin ? (
        <Tooltip trigger={<PillLabel text={t('Admin')} dataTest={'pill-label-admin'} />}>
          {t('Administrators have all available account permissions.')}
        </Tooltip>
      ) : (
        <InfoHoverCard
          loading={false}
          header={<InfoHoverHeader title={getInfoHoverHeader(t, rootClass, onEditPermissionAction, row.row.original, isAccountAdmin)} />}
          openDelay={350}
          closeDelay={150}
          sideOffset={2}
          dataTest={'non-admin-info-card'}
        >
          {getInfoHoverCardBody(rootClass, t, permissions, launchApproval)}
        </InfoHoverCard>
      )}
    </>
  )
}

const getInfoHoverHeader = (
  t: Function,
  rootClass: string,
  onEditPermissionAction: (user?: UserResponse) => void,
  row: UserResponse,
  isAccountAdmin: boolean
) => {
  return (
    <div className={`${rootClass}__info-hover-header`}>
      <Typography type={TextType.BODY_TEXT_WHITE} text={t('User Permissions')} weight={TextWeight.MEDIUM} />
      {isAccountAdmin && (
        <Button buttonType={ButtonType.EDIT} onClick={() => onEditPermissionAction(row)} className={`${rootClass}__info-hover-header-icon`}>
          <Svg name={SvgNames.keyWhite} />
          {t('Edit')}
        </Button>
      )}
    </div>
  )
}

export const reformatPermissions = (permissions: { [key: string]: any } | undefined) => {
  const formattedPermissions: PermissionsType[] = []
  PERMISSION_TITLES.forEach((title) => {
    const values: string[] = []
    for (const key in permissions) {
      const currentPermissions = [...(permissions[key] ?? [])]
      const index = currentPermissions.indexOf('MANAGE')
      if (index !== -1) {
        currentPermissions[index] = 'EDIT'
      }
      if (Object.values(currentPermissions).includes(title)) {
        values.push(PERMISSION_TITLES_MAP.get(key) as string)
      }
    }

    const joinedValues = values.join(', ')
    if (values.length) {
      formattedPermissions.push({
        title,
        values: joinedValues,
      })
    }
  })

  return formattedPermissions
}

const getInfoHoverCardBody = (rootClass: string, t: Function, permissions?: object, launchApproval?: string) => {
  return (
    <div className={`${rootClass}__info`}>
      {launchApproval && (
        <div className={`${rootClass}__info-list`}>
          <Typography
            type={TextType.BODY_TEXT_LIGHT_TINY}
            text={t('LAUNCH')}
            weight={TextWeight.BOLD}
            lineHeight={LineHeight.TINY}
            className={`${rootClass}__info-list__title`}
          />
          <Typography
            type={TextType.BODY_TEXT_SMALL}
            text={launchApproval}
            lineHeight={LineHeight.SMALL}
            className={`${rootClass}__info-list__value`}
          />
        </div>
      )}
      {permissions &&
        reformatPermissions(permissions).map((permission) => {
          return (
            <div key={getUUID()} className={`${rootClass}__info-list`}>
              <Typography
                type={TextType.BODY_TEXT_LIGHT_TINY}
                text={permission.title}
                weight={TextWeight.BOLD}
                lineHeight={LineHeight.TINY}
                className={`${rootClass}__info-list__title`}
              />
              <Typography
                key={getUUID()}
                type={TextType.BODY_TEXT_SMALL}
                text={permission.values}
                lineHeight={LineHeight.SMALL}
                className={`${rootClass}__info-list__value`}
              />
            </div>
          )
        })}
    </div>
  )
}
