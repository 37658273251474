import React, { FC } from 'react'

import classNames from 'classnames'

import Container from '@components/Container/Container'
import EmptyListing from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { useTranslation } from '@const/globals'

interface NoReportAvailableProps {
  canClearHistory: boolean
  className?: string
  dataTest?: string
}

const rootClass = 'no-report-available'
const SUPPORT = 'https://connect.act-on.com/hc/en-us'

const NoReportAvailable: FC<NoReportAvailableProps> = (props: NoReportAvailableProps) => {
  const { canClearHistory, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()

  const text = canClearHistory
    ? `We can't display this report due to the large amount of historical data it contains. You can clear the program history from the Actions menu or contact Customer Support to learn more.`
    : 'Please contact Customer Support for assistance'

  return (
    <Container>
      <EmptyListing
        headline={t('Report viewing has been disabled for this program')}
        imgSrc={StaticImageNames.errorStateAllContacts}
        text={t(text)}
        buttonText={t('Contact Customer Support')}
        buttonOnClick={() => false}
        link={SUPPORT}
        className={classNames(className)}
        data-test={dataTest}
      />
    </Container>
  )
}

export default NoReportAvailable
