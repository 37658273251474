import React, { FC } from 'react'

import classNames from 'classnames'

import Svg, { SvgNames } from '@components/Svg'

import './RecommendedSegmentIcon.css'

interface RecommendedSegmentIconProps {
  className?: string
  dataTest?: string
}

const rootClass = 'recommended-segment-icon'

const RecommendedSegmentIcon: FC<RecommendedSegmentIconProps> = (props: RecommendedSegmentIconProps) => {
  const { dataTest = rootClass, className = '' } = props
  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Svg dataTest={`${dataTest}-svg`} name={SvgNames.userUnselected} />
    </div>
  )
}

export default RecommendedSegmentIcon
