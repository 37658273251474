import { ReactNode } from 'react'

import { Status } from '@components/StatusToast/StatusToast'
import { SvgNames } from '@components/Svg'
import { rootContext } from '@const/globals'
import { IMPORT_CONTACTS_URL } from '@src/pages/importcontacts/utils/ImportContactsContainerUtils'
import { ItemType } from '@utils/categorization'

export const COPY_SEGMENTS_URL = `${rootContext}/segments/copy`
export const IMPORT_CONTACTS_FULL_URL = `${rootContext}${IMPORT_CONTACTS_URL}`
export const UPGRADE_PROGRAMS_URL = `${rootContext}/upgrade/programs`

export const BANNER_TYPE = 'upgradeToAllContactsBanner'
export enum ItemState {
  NOT_STARTED = 'notStarted',
  IN_PROGRESS = 'inProgress',
  MARKED_AS_COMPLETED = 'markedAsCompleted',
}

export enum UpgradeItemType {
  FORMS = 'forms',
  CONTACTS = 'contacts',
  PROGRAMS = 'programs',
  SEGMENTS = 'segments',
}

export const sectionValues: { [key in ItemState]: { label: string; icon: SvgNames } } = {
  [ItemState.NOT_STARTED]: { label: 'Not started', icon: SvgNames.minusCircle },
  [ItemState.IN_PROGRESS]: { label: 'In progress', icon: SvgNames.time },
  [ItemState.MARKED_AS_COMPLETED]: { label: 'Marked as complete', icon: SvgNames.checkTeal },
}

export const sections = [ItemState.NOT_STARTED, ItemState.IN_PROGRESS, ItemState.MARKED_AS_COMPLETED]

export interface UpgradeItem {
  type: UpgradeItemType
  label: string
  title: string
  subtitle: string
  svgName: SvgNames
  buttonText: string
  buttonLink?: string
  state: ItemState
  viewLogDisabled?: boolean
  position: number
  hasViewLog: boolean
}

export interface UpgradeAssistantState {
  allContactsFormCount: number
  allContactsSegmentCount: number
  allContactsImportCount: number
  allContactsProgramCount: number
  statusToast: { statusMessage: string | ReactNode; status: Status; showStatusToast: boolean }
  items: UpgradeItem[]
  showFormsUpgradeModal: boolean
  externalIdToUpgrade: string
  externalIdToUpgradeType?: ItemType
  completedItems?: string[]
  showConfirmationModal: boolean
  loadingStatus: boolean
  showUpgradeLogModal: boolean
  upgradeLogType?: UpgradeItemType
}

export const defaultState: UpgradeAssistantState = {
  allContactsFormCount: 0,
  allContactsSegmentCount: 0,
  allContactsImportCount: 0,
  allContactsProgramCount: 0,
  statusToast: { statusMessage: '', status: Status.SUCCESS, showStatusToast: false },
  items: [],
  showFormsUpgradeModal: false,
  externalIdToUpgrade: '',
  showConfirmationModal: false,
  loadingStatus: true,
  showUpgradeLogModal: false,
  upgradeLogType: undefined,
}

export const errorStatusToast = {
  statusMessage: 'Something went wrong on our end.',
  status: Status.FAIL,
  showStatusToast: true,
}

export const FORM_RESTORE_FAILED = 37
