import React, { FC, useContext, useMemo, useState } from 'react'
import { Row } from 'react-table'

import classNames from 'classnames'

import ActionableNestedTable from '@components/ActionableNestedTable/ActionableNestedTable'
import Button, { ButtonType } from '@components/Button'
import { Checkbox } from '@components/Checkbox/Checkbox'
import Container from '@components/Container'
import InputWithStatus from '@components/InputWithStatus/InputWithStatus'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Switch, { SwitchOption, SwitchOptions } from '@components/Switch/Switch'
import { RowAction, TableColumn } from '@components/Table/Table'
import { MultipleTables } from '@components/TableV2/components/MultipleTables/MultipleTables'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { List } from '@interface/foldersLists/List'
import { CUTOFF_SPLITS, MAX_SPLITS } from '@src/pages/ContactSegments/components/ABSplit/ABSplit.constants'
import { columnsV2, getSelectABSplitSourcesColumns, getSplitInputError } from '@src/pages/ContactSegments/components/ABSplit/ABSplit.utils'
import { ABSplitContext } from '@src/pages/ContactSegments/components/ABSplit/ABSplitContext'

import './ABSplitSecondSection.css'

interface ABSplitSecondSectionProps {
  className?: string
  dataTest?: string
}

const rootClass = 'ab-split-second-section'

const ABSplitSecondSection: FC<ABSplitSecondSectionProps> = (props: ABSplitSecondSectionProps) => {
  const { dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const [showAll, setShowAll] = useState<boolean>(false)
  const {
    values: { selectedDistribution, numberOfSplits, sources, splits, availableContacts, inputError },
    update,
    recalculateSplits,
  } = useContext(ABSplitContext)

  const onInputChange = (index: number, newValue: string) => {
    update(
      'splits',
      recalculateSplits([
        ...splits.slice(0, index),
        {
          ...splits[index],
          value: newValue,
          autoUpdate: false,
          isValid: !getSplitInputError(newValue, selectedDistribution, availableContacts, splits, index, t),
        },
        ...splits.slice(index + 1),
      ])
    )
  }

  const renderSplits = () =>
    splits.map((split, index) => (
      <div className={`${rootClass}__split`} key={index} id={index.toString()}>
        <div className={`${rootClass}__split-label`}>
          <Typography text={`Split ${split.name}`} weight={TextWeight.MEDIUM} />
          {!split.autoUpdate && (
            <Tooltip
              trigger={
                <Svg
                  className={`${rootClass}__split-locked`}
                  name={SvgNames.lock}
                  type={SvgType.LARGER_ICON}
                  dataTest={`${rootClass}-${SvgNames.lock}`}
                />
              }
              position={'top'}
            >
              {t('This split has been manually edited. You can only change it by reentering the number of contacts.')}
            </Tooltip>
          )}
        </div>
        <InputWithStatus
          value={
            Number(split.value)
              ? selectedDistribution === SwitchOptions.PERCENT
                ? Number(split.value).toFixed(2).toString()
                : Number(split.value).toString()
              : split.value.toString()
          }
          onChange={(event) => {
            onInputChange(index, event.currentTarget.value)
          }}
          showIconOnValid={false}
          icon={selectedDistribution === SwitchOptions.PERCENT ? SvgNames.percentage : undefined}
          onChangeDebounce={500}
          hasCustomError={!split.isValid}
          customTooltipErrorMessages={getSplitInputError(split.value, selectedDistribution, availableContacts, splits, index, t)}
          tooltipProps={{ position: 'top', align: 'start', alignOffset: 89 }}
          disabled={inputError && split.isValid}
        />
      </div>
    ))

  const columns: TableColumn[] = useMemo(() => getSelectABSplitSourcesColumns(), [])
  const rowActions: RowAction[] = [
    {
      label: t('Remove source'),
      icon: SvgNames.delete,
      onClick: ({ original }: Row<List>) => update('sourceToRemove', original),
    },
  ]

  const switchOptions: SwitchOption[] = [
    { label: SwitchOptions.PERCENT },
    {
      label: SwitchOptions.NUMBER,
      tooltip: availableContacts <= 1 ? t('This segment doesn’t have enough contacts to split by #') : undefined,
    },
  ]
  return (
    <Container dataTest={dataTest} className={classNames(rootClass, className)}>
      <div className={`${rootClass}__header`}>
        <Typography text={t("2. Distribute your segment's contacts across splits")} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.MEDIUM} />
        <Tooltip trigger={<Svg name={SvgNames.info} type={SvgType.LARGER_ICON} />} position={'right'} className={rootClass}>
          {t('A/B splits are divided evenly by default. Enter a number or percentage to customize how contacts are split.')}
        </Tooltip>
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={(event) => event.preventDefault()} className={`${rootClass}__switch-container`}>
        <Switch
          className={`${rootClass}__switch`}
          options={switchOptions}
          selected={selectedDistribution}
          onClick={(option) => {
            if (option !== selectedDistribution) {
              if (splits.every((split) => split.autoUpdate)) {
                update('selectedDistribution', selectedDistribution === SwitchOptions.NUMBER ? SwitchOptions.PERCENT : SwitchOptions.NUMBER)
              } else {
                update('changeDistribution', true)
              }
            }
          }}
          disabled={availableContacts <= 1}
        />
        <Button
          buttonType={ButtonType.FLOAT}
          onClick={() => {
            update(
              'splits',
              recalculateSplits(
                splits.map((split) => {
                  return { ...split, autoUpdate: true, isValid: true }
                })
              )
            )
          }}
        >
          <Svg name={SvgNames.resetNoFill} type={SvgType.ICON} />
          <Typography text={t('Restore default splits')} type={TextType.BODY_TEXT_SMALL} />
        </Button>
      </div>
      <div className={`${rootClass}__splits-wrapper`}>
        <div className={classNames(`${rootClass}__splits`, { [`${rootClass}__splits-hidden`]: !showAll })}>{renderSplits()}</div>
        {numberOfSplits > CUTOFF_SPLITS && numberOfSplits <= MAX_SPLITS && (
          <Button buttonType={ButtonType.TEXT} onClick={() => setShowAll(!showAll)} className={`${rootClass}__button-expand`}>
            <Typography
              className={`${rootClass}__button-expand-text`}
              text={t(showAll ? 'Show less' : 'Show all')}
              weight={TextWeight.MEDIUM}
              type={TextType.LINK_LARGE}
            />
          </Button>
        )}
      </div>
      <div className={`${rootClass}__suppress`}>
        <Typography text={t('Suppress contacts')} weight={TextWeight.MEDIUM} />
        <Checkbox label={t('Suppress all non-deliverable contacts')} onChange={(checked) => update('suppressNonDeliverable', checked)} />
        <Button buttonType={ButtonType.SECONDARY} className={`${rootClass}__button-select`} onClick={() => update('showListPicker', true)}>
          <Svg name={SvgNames.plus} type={SvgType.ICON} />
          {t('Select segments to suppress')}
        </Button>
        <MultipleTables
          tableV2Props={{
            data: sources,
            columns: columnsV2,
            rowActions,
            withoutBorders: true,
            styles: { margin: '0 -2rem -2rem -2rem', minWidth: 'auto' },
            rowEmptyText: t('Nothing selected yet'),
          }}
          oldTable={
            <ActionableNestedTable
              className={`${rootClass}__table`}
              emptyText={t('Nothing selected yet')}
              data={sources}
              columns={columns}
              rowActions={rowActions}
            />
          }
        />
      </div>
    </Container>
  )
}

export default ABSplitSecondSection
