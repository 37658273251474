import React, { FC, useCallback, useContext, useMemo } from 'react'

import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { TimePickerV2Format } from '@components/TimePickerV2/TimePickerV2'
import ValidationSection from '@src/pages/EmailComposer/components/common/ValidationSection/ValidationSection'
import { Validation, ValidationSectionStatus } from '@src/pages/EmailComposer/components/common/ValidationsList/ValidationsList'
import { CommonComposerTab } from '@utils/composer/commonComposer/CommonComposer.context'
import { EmailComposerContext, SendTimeType, ValidationSectionType } from '@utils/composer/context/EmailComposer.context'
import { formatTime, isTimePassedInAllTimezones } from '@utils/date'

dayjs.extend(utc)
dayjs.extend(timezone)

interface SendMethodSectionProps {
  dataTest?: string
}

const rootClass = 'send-method-section'

export const SendMethodSection: FC<SendMethodSectionProps> = ({ dataTest = rootClass }) => {
  const {
    values: {
      message: { sendMethod },
      messageConfiguration: {
        reviewAndSend: { hideSendMethodValidation },
      },
    },
    api: { onTabChange, onStatusChange },
  } = useContext(EmailComposerContext)

  const validations = useMemo<Validation[]>(() => {
    const { sendtype, senddate, sendtime, sendtimezone, defaultsendtimezone } = sendMethod
    const transKeySuffix = !sendtype ? 'NoMethod' : sendtype
    let senderStatus = ValidationSectionStatus.SUCCESS
    let isRecipientsTimeZonePassed = false
    let sendOn = undefined
    let from = undefined
    let to = undefined
    let timeZone = undefined
    let interval = undefined

    const currentDate = dayjs().add(5, 'minute')
    // Current date and time in selected timezone
    const currentTimeInTargetTimezone = dayjs().utc().tz(sendtimezone)

    if (!sendtype) {
      senderStatus = ValidationSectionStatus.ERROR
    } else if (sendtype === SendTimeType.LATER) {
      if (!senddate || !sendtime) {
        senderStatus = ValidationSectionStatus.ERROR
      } else {
        const parsedTime = dayjs(sendtime, TimePickerV2Format.HH_MM)

        sendOn = dayjs(senddate).hour(parsedTime.hour()).minute(parsedTime.minute()).tz(sendtimezone, true)
        timeZone = sendtimezone

        if (!sendOn.isValid() || !parsedTime.isValid() || sendOn.valueOf() < currentTimeInTargetTimezone.valueOf()) {
          senderStatus = ValidationSectionStatus.ERROR
        }
        sendOn = formatTime(sendOn)
      }
    } else if (sendtype === SendTimeType.TIME_ZONE) {
      if (!senddate || !sendtime) {
        senderStatus = ValidationSectionStatus.ERROR
      } else {
        const parsedTime = dayjs(sendtime, TimePickerV2Format.HH_MM)
        sendOn = dayjs(senddate).hour(parsedTime.hour()).minute(parsedTime.minute())

        if (
          !sendOn.isValid() ||
          !parsedTime.isValid() ||
          isTimePassedInAllTimezones(senddate, sendtime) ||
          // Check if time is past for User fallback timezone
          sendOn.tz(defaultsendtimezone, true).valueOf() < currentTimeInTargetTimezone.valueOf()
        ) {
          senderStatus = ValidationSectionStatus.ERROR
        } else {
          // Current date and time in earliest time zone in the world (GMT+14:00 Pacific/Kiritimati)
          const sendOnEarliestTimeZone = dayjs(senddate).hour(parsedTime.hour()).minute(parsedTime.minute()).tz('Pacific/Kiritimati', true)

          if (currentDate.valueOf() > sendOnEarliestTimeZone.valueOf()) {
            senderStatus = ValidationSectionStatus.SUCCESS
            isRecipientsTimeZonePassed = true
          }
          sendOn = formatTime(sendOn)
        }
      }
    } else if (sendtype === SendTimeType.STAGGERED) {
      const { staggertimezone, staggerdatestart, staggerdateend, staggertimestart, staggertimeend } = sendMethod
      if (!staggerdatestart || !staggerdateend || !staggertimestart || !staggertimeend) {
        senderStatus = ValidationSectionStatus.ERROR
      } else {
        timeZone = staggertimezone
        from = staggerdatestart.tz(timeZone, true)
        to = staggerdateend.tz(timeZone, true)
        interval = `${dayjs(staggertimestart, TimePickerV2Format.HH_MM).format('h:mm A')} - ${dayjs(staggertimeend, TimePickerV2Format.HH_MM).format(
          'h:mm A'
        )}`

        const currentTimeInTargetTimezone = dayjs().utc().tz(staggertimezone)

        const parsedTimeEnd = dayjs(staggertimeend, TimePickerV2Format.HH_MM)
        const parsedTimeStart = dayjs(staggertimestart, TimePickerV2Format.HH_MM)

        const timeDifferenceInHours = parsedTimeEnd.diff(parsedTimeStart, 'hour', true)
        const isTimeDiffLessThanHour = timeDifferenceInHours < 1

        let sendOnStart = staggerdatestart.hour(parsedTimeStart.hour()).minute(parsedTimeStart.minute())
        let sendOnEnd = staggerdateend.hour(parsedTimeEnd.hour()).minute(parsedTimeEnd.minute())

        if (staggertimezone) {
          sendOnStart = sendOnStart.tz(staggertimezone, true)
          sendOnEnd = sendOnEnd.tz(staggertimezone, true)
        }

        const startTimeInPast = sendOnStart.valueOf() < dayjs().valueOf()
        const endTimeInPast = sendOnEnd.valueOf() < dayjs().valueOf()

        if (
          !from.isValid() ||
          !to.isValid() ||
          currentTimeInTargetTimezone.valueOf() > sendOnEnd.valueOf() ||
          sendOnStart.valueOf() < currentTimeInTargetTimezone.valueOf() ||
          isTimeDiffLessThanHour ||
          startTimeInPast ||
          endTimeInPast
        ) {
          senderStatus = ValidationSectionStatus.ERROR
        }
      }
    } else if (sendtype === SendTimeType.ADAPTIVE) {
      const { adaptivedatestart, adaptivedateend } = sendMethod
      from = adaptivedatestart
      to = adaptivedateend

      if (!from?.isValid() || !to?.isValid()) {
        senderStatus = ValidationSectionStatus.ERROR
      }
    }

    return [
      {
        key: 'method',
        status: senderStatus,
        title: `EmailComposer.Send.Method.Validation.${transKeySuffix}`,
        titleProps: {
          values: {
            context: senderStatus,
            from,
            to,
            sendOn,
            timeZone,
            interval,
            formatParams: {
              from: { weekday: 'long', month: 'short', day: 'numeric' },
              to: { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' },
              sendOn: {
                weekday: 'long',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
                timeZone,
              },
            },
          },
        },
      },
      ...(isRecipientsTimeZonePassed
        ? [
            {
              key: 'recipeints_time_zone_passed',
              status: ValidationSectionStatus.WARNING,
              title: `EmailComposer.Send.Method.Validation.${transKeySuffix}`,
              titleProps: {
                values: {
                  context: 'warning',
                },
              },
            },
          ]
        : []),
    ]
  }, [sendMethod])

  const handleClick = useCallback<VoidFunction>(
    () => onTabChange(CommonComposerTab.SEND, { fromValidationSection: ValidationSectionType.SEND_METHOD }),
    [onTabChange]
  )

  return (
    <>
      {!hideSendMethodValidation && (
        <ValidationSection
          type={ValidationSectionType.SEND_METHOD}
          dataTest={dataTest}
          title="Send method"
          buttonLabel="Edit send method"
          validations={validations}
          onClick={handleClick}
          onStatusChange={onStatusChange}
        />
      )}
    </>
  )
}
