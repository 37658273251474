import React, { FC, useContext, useMemo } from 'react'

import classNames from 'classnames'

import Tab from '@components/TabsAO/TabAO'
import Tabs, { TabStyle } from '@components/TabsAO/TabsAO'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import LastUpdated from '@src/pages/programs/dashboard/components/LastUpdated/LastUpdated'
import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'

import ProgramPerformanceDetail from './components/ProgramPerformanceDetail/ProgramPerformanceDetail'
import {
  allContactsDetail,
  earlyExitsDetail,
  inProgramDetail,
  standardExitsDetail,
} from './components/ProgramPerformanceDetail/ProgramPerformanceDetail.constants'
import { ProgramPerformanceTab, ProgramPerformanceTabs, ProgramScheduleFrequency, ProgramScheduleType } from './ProgramPerformance.constants'
import {
  getAllContactsLabelContent,
  getEarlyExitLabelContent,
  getEarlyExitSegmentInfo,
  getInProgramLabelContent,
  getStandardExitLabelContent,
} from './ProgramPerformance.utils'
import { getProgramStepsWithLetters } from '../../ProgramSteps.utils'

import './ProgramPerformance.css'

export interface ProgramPerformanceProps {
  currentTab: ProgramPerformanceTab
  onTabChange: (newTab: string) => void
  className?: string
  dataTest?: string
}

const rootClass = 'program-performance'

const ProgramPerformance: FC<ProgramPerformanceProps> = (props: ProgramPerformanceProps) => {
  const {
    values: { program, programCounts: allCounts, programUrlId },
    refreshProgram,
  } = useContext(ProgramManagerContext)
  const { currentTab, onTabChange, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const programCounts = allCounts.programCounts

  const scheduleType =
    program.schedule && program.schedule.type !== ProgramScheduleType.TRIGGERED && program.schedule.type !== ProgramScheduleType.NEVER
      ? ProgramScheduleType.SCHEDULED
      : (program.schedule?.type as ProgramScheduleType) ?? ProgramScheduleType.NEVER
  const scheduleFrequency = (program.schedule?.type as ProgramScheduleFrequency) ?? ProgramScheduleFrequency.WEEKLY
  const scheduleInterval = program.schedule?.interval ?? -1
  const scheduleWeekdaysOnly = !!program.schedule?.weekdaysOnly && program.schedule.type === ProgramScheduleFrequency.DAILY
  const nextScheduledAddDate = program.schedule?.nextTime ?? ''
  const lastUpdated = program.lastUpdated

  const dailyFrequencyText = `${t('Contacts enter the program')} ${
    scheduleInterval === 0 ? scheduleFrequency?.toLowerCase() : `${t('every')} ${scheduleInterval} ${t('hours')}`
  }${scheduleWeekdaysOnly ? ` ${t('only on weekdays')}` : ''}`

  const nonDailyFrequncyText = `${t('Contacts enter the program')} ${scheduleFrequency?.toLowerCase()}`

  const scheduleTooltip = (
    <div>
      <Typography text={t('There are new contacts waiting to enter the program.')} type={TextType.BODY_TEXT_WHITE} weight={TextWeight.BOLD} inline />{' '}
      <Typography
        text={`${scheduleFrequency === ProgramScheduleFrequency.DAILY ? dailyFrequencyText : nonDailyFrequncyText}. ${t(
          'New contacts will enter the program on'
        )} ${nextScheduledAddDate}.`}
        type={TextType.BODY_TEXT_WHITE}
        inline
      />
    </div>
  )

  const totalContacts = programCounts.enteredContacts
  const currentlyInProgram =
    programCounts.enteredContacts - programCounts.doneCount - programCounts.deletedCount > 0
      ? programCounts.enteredContacts - programCounts.doneCount - programCounts.deletedCount
      : 0
  const earlyExitCount = programCounts.earlyExitCount + programCounts.deletedCount
  const standardExitCount = programCounts.doneCount - programCounts.earlyExitCount

  const tabsWithLabelContent = useMemo(() => {
    return [...ProgramPerformanceTabs].map((tab) => {
      const labelContent =
        tab.index === ProgramPerformanceTab.All_CONTACTS
          ? getAllContactsLabelContent(totalContacts)
          : tab.index === ProgramPerformanceTab.IN_PROGRAM
          ? getInProgramLabelContent(currentlyInProgram, totalContacts)
          : tab.index === ProgramPerformanceTab.EARLY_EXITS
          ? getEarlyExitLabelContent(earlyExitCount, totalContacts)
          : tab.index === ProgramPerformanceTab.STANDARD_EXITS
          ? getStandardExitLabelContent(programCounts, totalContacts)
          : undefined
      return { ...tab, labelContent }
    })
  }, [programCounts])

  const earlyExitSegmentInfos = useMemo(
    () => (currentTab === ProgramPerformanceTab.EARLY_EXITS ? getEarlyExitSegmentInfo(program, allCounts) : []),
    [program, allCounts, currentTab]
  )

  const programSteps = useMemo(() => getProgramStepsWithLetters(program), [program])

  const commonTabProps = {
    programId: programUrlId,
    allowReentrantAddresses: program.allowReentrantAddresses,
    earlyExitSegmentInfos: earlyExitSegmentInfos,
    programSteps,
    noUpdate: false,
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__refresh-ctr`}>
        <LastUpdated lastUpdated={lastUpdated} onRefresh={refreshProgram} />
        <div className={`${rootClass}__schedule-ctr`}>
          {scheduleType === ProgramScheduleType.SCHEDULED && (
            <Tooltip
              trigger={
                <Typography
                  text={t('Contacts added on a schedule')}
                  type={TextType.BODY_TEXT_LIGHT}
                  inline
                  dataTest={`${dataTest}-schedule-text`}
                  className={`${rootClass}__schedule`}
                />
              }
            >
              {scheduleTooltip}
            </Tooltip>
          )}
          {scheduleType !== ProgramScheduleType.SCHEDULED && (
            <Typography
              text={t(`Pending contacts added ${scheduleType === ProgramScheduleType.TRIGGERED ? 'immediately' : 'manually'}`)}
              inline
              dataTest={`${dataTest}-schedule-text`}
            />
          )}
        </div>
      </div>
      <Tabs childData={tabsWithLabelContent} defaultValue={currentTab as string} tabStyle={TabStyle.SPLIT} onChange={onTabChange}>
        <Tab index={ProgramPerformanceTab.All_CONTACTS}>
          <ProgramPerformanceDetail
            {...allContactsDetail}
            {...commonTabProps}
            programPerformanceTab={ProgramPerformanceTab.All_CONTACTS}
            totalContacts={totalContacts}
          />
        </Tab>
        <Tab index={ProgramPerformanceTab.IN_PROGRAM}>
          <ProgramPerformanceDetail
            {...inProgramDetail}
            {...commonTabProps}
            programPerformanceTab={ProgramPerformanceTab.IN_PROGRAM}
            totalContacts={currentlyInProgram}
          />
        </Tab>
        <Tab index={ProgramPerformanceTab.EARLY_EXITS}>
          <ProgramPerformanceDetail
            {...earlyExitsDetail}
            {...commonTabProps}
            programPerformanceTab={ProgramPerformanceTab.EARLY_EXITS}
            totalContacts={earlyExitCount}
          />
        </Tab>
        <Tab index={ProgramPerformanceTab.STANDARD_EXITS}>
          <ProgramPerformanceDetail
            {...standardExitsDetail}
            {...commonTabProps}
            programPerformanceTab={ProgramPerformanceTab.STANDARD_EXITS}
            totalContacts={standardExitCount}
          />
        </Tab>
      </Tabs>
    </div>
  )
}

export default ProgramPerformance
