import { ComponentClass, ComponentType, FC, lazy } from 'react'

import { rootContext } from '@const/globals'
import BrandingContainer from '@src/pages/Content/Branding/BrandingContainer'
import DraftsListingPageContainer from '@src/pages/listingPages/Drafts/DraftsListingPageContainer'
const SubscriptionManagement = lazy(() => import('@src/pages/SubscriptionManagement/SubscriptionManagement'))
const ProgramsAndTemplatesTabbedContainer = lazy(
  () => import('@src/pages/listingPages/TabbedContainers/ProgramsAndTemplatesTabbedContainer/ProgramsAndTemplatesTabbedContainer')
)
const LandingPagesAndTemplatesTabbedContainer = lazy(
  () => import('@src/pages/listingPages/TabbedContainers/LandingPagesAndTemplates/LandingPagesAndTemplatesTabbedContainer')
)
const FormAndTemplatesTabbedListingPageContainer = lazy(
  () => import('@src/pages/listingPages/TabbedContainers/FormAndTemplates/FormAndTemplatesTabbedContainer')
)
const AudienceCenterTabbedContainer = lazy(() => import('@src/pages/listingPages/TabbedContainers/AudienceCenter/AudienceCenterTabbedContainer'))
const AddContactsFromCRM = lazy(() => import('@src/pages/ContactSegments/components/AddContactsFromCRM/AddContactsFromCRMContainer'))
const ContentFragments = lazy(() => import('@src/pages/listingPages/ContentFragment/ContentFragmentListingPageContainer'))
const CopySegments = lazy(() => import('@src/pages/ContactSegments/components/CopySegments/CopySegmentsContainer'))
const DataManagement = lazy(() => import('@src/pages/datamanagement/DataManagementContainer'))
const IncomingWebhooks = lazy(() => import('@src/pages/Webhooks/IncomingWebhooks/IncomingWebhooksContainer'))
const ProgramManager = lazy(() => import('@src/pages/programs/manager/ProgramManagerContainer'))
const EditSMS = lazy(() => import('@src/pages/sms/edit'))
const PreviewSMS = lazy(() => import('@src/pages/sms/preview/SMSPreviewContainer'))
const RecommendedSegments = lazy(() => import('@src/pages/RecommendedSegments/RecommendedSegmentsContainer'))
const SMSListing = lazy(() => import('@src/pages/sms/listing'))
const SMSReport = lazy(() => import('@src/pages/sms/report'))
const SMSOptinViewAll = lazy(() => import('@src/pages/sms/marketingListOptIns/components/SMSOptinViewAll/SMSOptinViewAllContainer'))
const SMSOptIns = lazy(() => import('@src/pages/sms/marketingListOptIns'))
const SMSOptout = lazy(() => import('@src/pages/sms/optouts'))
const SMSInitialMessage = lazy(() => import('@src/pages/sms/initialMessage'))
const SMSSettings = lazy(() => import('@src/pages/sms/settings'))
const Features = lazy(() => import('@utils/const/features'))
const ImportContacts = lazy(() => import('@src/pages/importcontacts/ImportContactsContainer'))
const ImportContactsV2 = lazy(() => import('@src/pages/ImportContactsV2/ImportContactsV2Container'))
const ExportContacts = lazy(() => import('@src/pages/ExportContacts/ExportContactsContainer'))
const FileDownload = lazy(() => import('@src/pages/FileDownload/FileDownload'))
const IPAddressesToIgnore = lazy(() => import('@src/pages/Settings/OtherSettings/IpAddresses/IpAddressesToIgnore'))
const EmailMessageReport = lazy(() => import('@src/pages/reports/emailMessages/EmailMessagesContainer'))
const RevenueImpactReport = lazy(() => import('@src/pages/reports/revenueImpact/RevenueImpactContainer'))
const RevenueImpactReportNew = lazy(() => import('@src/pages/reports/revenueImpactNew/RevenueImpactNewContainer'))
const EmailPerformance = lazy(() => import('@src/pages/reports/EmailPerformance/EmailPerformanceContainer'))
const FormsLPsMP = lazy(() => import('@src/pages/reports/FormsLPsMP/FormsLPsMP'))
const Analytics = lazy(() => import('@src/pages/reports/Analytics/AnalyticsContainer'))
const SageBoard = lazy(() => import('@src/pages/reports/SageBoard/SageBoardContainer'))
const WebsiteVisitorsContainer = lazy(() => import('@src/pages/reports/WebsiteVisitors/WebsiteVisitorsContainer'))
const ProgramContactsContainer = lazy(() => import('@src/pages/programs/dashboard/components/ProgramContacts/ProgramContactsContainer'))
const ProgramSuppressionsContainer = lazy(() => import('@src/pages/programs/dashboard/components/ProgramSuppressions/ProgramSuppressionsContainer'))
const BeaconSettings = lazy(() => import('@src/pages/Settings/OtherSettings/BeaconSettings/BeaconSettings'))
const ContactSettings = lazy(() => import('@src/pages/Settings/OtherSettings/ContactSettings/ContactSettings'))
const LinkPerformance = lazy(() => import('@src/pages/reports/linkPerformance/LinkPerformanceContainer'))
const ListMaintenancePrograms = lazy(() => import('@src/pages/listingPages/ListMaintenancePrograms/ListMaintenanceProgramsListingPageContainer'))
const EraseContact = lazy(() => import('@src/pages/Settings/OtherSettings/EraseContact/EraseContact'))
const CustomAccountSettings = lazy(() => import('@src/pages/Settings/OtherSettings/CustomAccountSettings/CustomAccountSettingsContainer'))
const TrueOpenSettings = lazy(() => import('@src/pages/Settings/OtherSettings/TrueOpenSettings/TrueOpenSettings'))
const Users = lazy(() => import('@src/pages/Settings/Users/UsersContainer'))
const ProfileContainer = lazy(() => import('@src/pages/Profile/ProfileContainer'))
const StandardFieldNames = lazy(() => import('@src/pages/Contacts/StandardFieldNames/StandardFieldNames'))
const SubscriptionCategoryMappingsContainer = lazy(() => import('@src/pages/SubscriptionCategoryMappings/SubscriptionCategoryMappingsContainer'))
const FromAddressesContainer = lazy(() => import('@src/pages/Settings/OtherSettings/FromAddresses/FromAddressesContainer'))
const ABSplit = lazy(() => import('@src/pages/ContactSegments/components/ABSplit/ABSplitContainer'))
const WebsiteProspectorAlerts = lazy(() => import('@src/pages/WebsiteProspectorAlerts/WebsiteProspectorAlertsContainer'))
const InboxContainer = lazy(() => import('@src/pages/MarketingNetwork/Inbox/InboxContainer'))
const CustomizeContainer = lazy(() => import('@src/pages/MarketingNetwork/Customize/CustomizeContainer'))
const FormManagerContainer = lazy(() => import('@src/pages/Content/Forms/FormManager/FormManagerContainer'))
const LandingPageManagerContainer = lazy(() => import('@src/pages/Content/LandingPages/LandingPagesManager/LandingPageManagerContainer'))
const AccountManagementTabbedListingPageContainer = lazy(
  () => import('@src/pages/listingPages/TabbedContainers/AccountManagement/AccountManagementTabbedContainer')
)
const MessageUsage = lazy(() => import('@src/pages/MarketingNetwork/IframePages/MessageUsage/MessageUsage'))
const ContactsUsage = lazy(() => import('@src/pages/MarketingNetwork/IframePages/ContactsUsage/ContactsUsage'))
const BackToParent = lazy(() => import('@src/pages/MarketingNetwork/Helpers/BackToParent'))
const EmailTemplatesListingPageContainer = lazy(() => import('@src/pages/listingPages/EmailTemplates/EmailTemplatesListingPageContainer'))
const EmailComposerContainer = lazy(() => import('@src/pages/EmailComposer/EmailComposerContainer'))
const LandingPageComposerContainer = lazy(() => import('@src/pages/LandingPageComposer/LandingPageComposerContainer'))
const EmailComposerEmpty = lazy(() => import('@src/pages/EmailComposer/components/EmailComposerEmpty/EmailComposerEmpty'))
const UpgradeAssistant = lazy(() => import('@src/pages/UpgradeAssistant/UpgradeAssistant'))
const SegmentComposerContainer = lazy(() => import('@src/pages/SegmentComposer/SegmentComposerContainer'))
const SavedRowsCopy = lazy(() => import('@src/pages/SavedRowsCopy/SavedRowsCopy'))

export interface RouteConfig {
  path: string
  component: FC<any> | ComponentClass<any> | ComponentType<any> | null
  noProd?: boolean
  noNav?: boolean
  title?: string
  exact?: boolean
}

const allRoutes: RouteConfig[] = [
  {
    path: `${rootContext}/home/inbox`,
    component: InboxContainer,
    title: 'Inbox',
  },
  {
    path: `${rootContext}/content/emails/:id/:tab?`,
    component: EmailComposerContainer,
    title: 'Email Composer',
    noNav: true,
  },
  {
    path: `${rootContext}/content/landingPage/:id/:tab?`,
    component: LandingPageComposerContainer,
    title: 'Landing Page Composer',
    noNav: true,
  },
  {
    path: `${rootContext}/content/emailTemplates/invalid-email/:id`,
    component: EmailComposerEmpty,
    title: 'Email Composer',
  },
  {
    path: `${rootContext}/content/emailDrafts/invalid-email/:id`,
    component: EmailComposerEmpty,
    title: 'Drafts',
  },
  {
    // This route will go to a new email listing page
    path: `${rootContext}/content/emails`,
    component: EmailComposerContainer,
    title: 'Email Composer',
    noNav: true,
  },
  {
    // This route will go to a new email listing page
    path: `${rootContext}/content/landingPage`,
    component: LandingPageComposerContainer,
    title: 'Landing Page Composer',
    noNav: true,
  },
  {
    path: `${rootContext}/marketingNetwork/messageUsage`,
    component: MessageUsage,
    title: 'Message Usage',
  },
  {
    path: `${rootContext}/backToParent`,
    component: BackToParent,
    title: 'Marketing Network',
  },
  {
    path: `${rootContext}/marketingNetwork/contactsUsage`,
    component: ContactsUsage,
    title: 'Contacts Usage',
  },
  {
    path: `${rootContext}/segments/splits/:segmentId/:type?`,
    component: ABSplit,
    title: 'A/B Split',
  },
  {
    path: `${rootContext}/contacts/crm/:id?`,
    component: AddContactsFromCRM,
    title: 'CRM Segments',
  },
  {
    path: `${rootContext}/segments/copy`,
    component: CopySegments,
    title: 'Copy Segments',
  },
  {
    path: `${rootContext}/upgrade/programs/:id?`,
    component: ProgramManager,
    title: 'Upgrade Programs',
  },
  {
    path: `${rootContext}/segments/composer/edit/:id`,
    component: SegmentComposerContainer,
    title: 'Segment Composer',
    noNav: true,
  },
  {
    path: `${rootContext}/segments/composer/new/:type(direct|query)/:id?`,
    component: SegmentComposerContainer,
    title: 'Segment Composer',
    noNav: true,
  },
  {
    path: `${rootContext}/segments`,
    component: AudienceCenterTabbedContainer,
    title: 'All Contacts',
  },
  {
    path: `${rootContext}/content/forms/:id/:tab?/:subTab?`,
    component: FormManagerContainer,
    title: 'Form Manager',
  },
  {
    path: `${rootContext}/content/landingPages/:id/:tab?/:subTab?`,
    component: LandingPageManagerContainer,
    title: 'Landing Page Manager',
  },
  {
    path: `${rootContext}/content/fragments`,
    component: ContentFragments,
    title: 'Content Fragments',
  },
  {
    path: `${rootContext}/contactSettings`,
    component: ContactSettings,
    title: 'Contact Settings',
  },
  {
    path: `${rootContext}/customData`,
    component: AudienceCenterTabbedContainer,
    title: 'Custom Data',
  },
  /* Classic Redirects  */
  {
    path: `${rootContext}/report/linkPerformance/:id/:emailName?`,
    component: LinkPerformance,
    title: 'Link Performance Report',
  },
  {
    path: `${rootContext}/automation/listMaintenance`,
    component: ListMaintenancePrograms,
    title: 'List Maintenance Programs',
  },
  {
    path: `${rootContext}/classic/if/reports/messages.jsp`,
    component: EmailMessageReport,
    title: 'Email Messages Report',
    exact: true,
  },
  /* End Classic Redirects */
  {
    path: `${rootContext}/classic`,
    component: null,
  },
  {
    path: `${rootContext}/features`,
    component: Features,
    noProd: true,
  },
  {
    path: `${rootContext}/programs/:id/suppressed`,
    component: ProgramSuppressionsContainer,
    title: 'Program Suppressions',
  },
  {
    path: `${rootContext}/automation/programs/:id/suppressed`,
    component: ProgramSuppressionsContainer,
    title: 'Program Suppressions',
  },
  {
    path: `${rootContext}/automation/programs/:id/:tab?/:subTab?`,
    component: ProgramManager,
    title: 'Automated Journey Builder',
  },
  {
    path: `${rootContext}/automation/programs/:id`,
    component: ProgramManager,
    title: 'Automated Journey Builder',
  },
  {
    path: `${rootContext}/programs/:id/contacts/:state/:step?`,
    component: ProgramContactsContainer,
    title: 'Program Contacts',
  },
  {
    path: `${rootContext}/automation/programs`,
    component: ProgramsAndTemplatesTabbedContainer,
    title: 'Automated Programs',
  },
  {
    path: `${rootContext}/automation/programs`,
    component: ProgramsAndTemplatesTabbedContainer,
    title: 'Automated Programs',
  },
  {
    path: `${rootContext}/automation/programTemplates`,
    component: ProgramsAndTemplatesTabbedContainer,
    title: 'Automated Programs Templates',
  },
  {
    path: `${rootContext}/automation/programTemplates`,
    component: ProgramsAndTemplatesTabbedContainer,
    title: 'Automated Programs Templates',
  },
  {
    path: `${rootContext}/content/forms`,
    component: FormAndTemplatesTabbedListingPageContainer,
    title: 'Forms',
  },
  {
    path: `${rootContext}/content/forms`,
    component: FormAndTemplatesTabbedListingPageContainer,
    title: 'Forms',
  },
  {
    path: `${rootContext}/content/formTemplates`,
    component: FormAndTemplatesTabbedListingPageContainer,
    title: 'Form Templates',
  },
  {
    path: `${rootContext}/content/formTemplates`,
    component: FormAndTemplatesTabbedListingPageContainer,
    title: 'Form Templates',
  },
  {
    path: `${rootContext}/content/landingPages`,
    component: LandingPagesAndTemplatesTabbedContainer,
    title: 'Landing Pages',
  },
  {
    path: `${rootContext}/content/landingPageTemplates`,
    component: LandingPagesAndTemplatesTabbedContainer,
    title: 'Landing Page Templates',
  },
  {
    path: `${rootContext}/report/emailMessages`,
    component: EmailMessageReport,
    title: 'Email Messages Report',
  },
  {
    path: `${rootContext}/report/revenueImpact`,
    component: RevenueImpactReport,
    title: 'Revenue Impact Report',
  },
  {
    path: `${rootContext}/report/impact/revenue`,
    component: RevenueImpactReportNew,
    title: 'Revenue Impact Report',
  },
  {
    path: `${rootContext}/report/analytics/:tab?`,
    component: Analytics,
    title: 'Analytics',
  },
  {
    path: `${rootContext}/report/sageboard/:tab?`,
    component: SageBoard,
    title: 'SageBoard',
  },
  {
    path: `${rootContext}/report/emailPerformance`,
    component: EmailPerformance,
    title: 'Email Performance',
  },
  {
    path: `${rootContext}/report/forms-lps-media`,
    component: FormsLPsMP,
    title: 'Forms, LPs, and Media',
  },
  {
    path: `${rootContext}/report/websiteVisitors`,
    component: WebsiteVisitorsContainer,
    title: 'Website Visitors Report',
  },
  {
    path: `${rootContext}/contacts/recommendations`,
    component: RecommendedSegments,
    title: 'Recommended Segments',
    exact: true,
  },
  {
    path: `${rootContext}/sms/messages`,
    component: SMSListing,
    title: 'SMS',
  },
  {
    path: `${rootContext}/sms/messages`,
    component: SMSListing,
    title: 'SMS',
  },
  {
    path: `${rootContext}/sms/optins/all`,
    component: SMSOptinViewAll,
    title: 'SMS Opt-ins View All',
    exact: true,
  },
  {
    path: `${rootContext}/sms/optins`,
    component: SMSOptIns,
    title: 'SMS Opt-ins',
    exact: true,
  },
  {
    path: `${rootContext}/sms/optouts`,
    component: SMSOptout,
    title: 'SMS Opt-outs',
  },
  {
    path: `${rootContext}/sms/initialMessage`,
    component: SMSInitialMessage,
    title: 'SMS Initial Message',
  },
  {
    path: `${rootContext}/sms/:id/edit`,
    component: EditSMS,
    title: 'Edit SMS',
  },
  {
    path: `${rootContext}/sms/:id/:recid/preview`,
    component: PreviewSMS,
    title: 'Preview SMS',
    noNav: true,
  },
  {
    path: `${rootContext}/sms/:id/report`,
    component: SMSReport,
    title: 'SMS',
  },
  {
    path: `${rootContext}/sms/settings`,
    component: SMSSettings,
    title: 'SMS Settings',
  },
  {
    path: `${rootContext}/datamanagement`,
    component: DataManagement,
    title: 'Data Management',
  },
  {
    path: `${rootContext}/importContacts/download`,
    component: FileDownload,
    title: 'File Download',
    exact: true,
  },
  {
    path: `${rootContext}/importContacts`,
    component: ImportContacts,
    title: 'Import Contacts',
  },
  {
    path: `${rootContext}/importContactsV2/:type/:job?/:section?/download`,
    component: FileDownload,
    title: 'File Download',
    exact: true,
  },
  {
    path: `${rootContext}/importContactsV2/:type/:job?/:section?`,
    component: ImportContactsV2,
    title: 'Import Contacts',
  },
  {
    path: `${rootContext}/exportContacts/:job?/:section?`,
    component: ExportContacts,
    title: 'Export Contacts',
  },
  {
    path: `${rootContext}/ipAddressesToIgnore`,
    component: IPAddressesToIgnore,
    title: 'Ip Addresses to Ignore',
  },
  {
    path: `${rootContext}/beaconSettings`,
    component: BeaconSettings,
    title: 'Beacon Settings',
  },
  {
    path: `${rootContext}/settings/customAccountSettings`,
    component: CustomAccountSettings,
    title: 'Custom Account Settings',
  },
  {
    path: `${rootContext}/settings/trueOpenSettings`,
    component: TrueOpenSettings,
    title: 'True Open Settings',
  },
  {
    path: `${rootContext}/settings/users`,
    component: Users,
    title: 'Users',
  },
  {
    path: `${rootContext}/marketingNetwork/accountManagement`,
    component: AccountManagementTabbedListingPageContainer,
    title: 'Account Management',
  },
  {
    path: `${rootContext}/marketingNetwork/managers`,
    component: AccountManagementTabbedListingPageContainer,
    title: 'Managers',
  },
  {
    path: `${rootContext}/eraseContact`,
    component: EraseContact,
    title: 'Erase Contact',
  },
  {
    path: `${rootContext}/contacts/standardFieldNames`,
    component: StandardFieldNames,
    title: 'Standard Field Names',
  },
  {
    path: `${rootContext}/subscriptionManagement/mapping`,
    component: SubscriptionCategoryMappingsContainer,
    title: 'subManagement',
  },
  {
    path: `${rootContext}/subscriptionManagement`,
    component: SubscriptionManagement,
    title: 'Subscription Management',
  },
  {
    path: `${rootContext}/settings/fromAddresses`,
    component: FromAddressesContainer,
    title: 'From Addresses',
  },
  {
    path: `${rootContext}/profile/:tab?`,
    component: ProfileContainer,
    title: 'Profile',
  },
  {
    path: `${rootContext}/content/branding`,
    component: BrandingContainer,
    title: 'Branding',
  },
  {
    path: `${rootContext}/webhooks/incoming`,
    component: IncomingWebhooks,
    title: 'Incoming Webhooks',
  },
  {
    path: `${rootContext}/wspAlerts`,
    component: WebsiteProspectorAlerts,
    title: 'Website Prospector Alerts',
  },
  {
    path: `${rootContext}/marketingNetwork/customize`,
    component: CustomizeContainer,
    title: 'Customize',
  },
  {
    path: `${rootContext}/content/formSubmissions`,
    component: AudienceCenterTabbedContainer,
    title: 'Form Submissions',
  },
  {
    path: `${rootContext}/content/webinarSubmissions`,
    component: AudienceCenterTabbedContainer,
    title: 'Webinar Submissions',
  },
  {
    path: `${rootContext}/content/emailTemplates`,
    component: EmailTemplatesListingPageContainer,
    title: 'Email Templates',
  },
  {
    path: `${rootContext}/outbound/drafts/invalid-email/:id`,
    component: EmailComposerEmpty,
    title: 'Drafts',
  },
  {
    path: `${rootContext}/outbound/drafts`,
    component: DraftsListingPageContainer,
    title: 'Drafts',
  },
  {
    path: `${rootContext}/upgradeAssistant`,
    component: UpgradeAssistant,
    title: 'Upgrade Assistant',
  },
  {
    path: `${rootContext}/savedRowsCopy`,
    component: SavedRowsCopy,
    title: 'Saved Rows Copy',
  },
]

export default allRoutes
