import React, { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import DropDown from '@components/DropDown'
import { DropDownType } from '@components/DropDown/DropDown'
import DropDownActions from '@components/DropDownActions/DropDownActions'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { getDropDownActions } from '@src/pages/listingPages/FormsJoinView/FormsJoinViewListingPageContainer.constants'
import { goCreateForm, goCreateWebinar } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'

import './FormsJoinViewPageHeader.css'

interface FormsJoinViewPageHeaderProps {
  className?: string
  dataTest?: string
  currentItemType: ItemType
}

const rootClass = 'forms-join-view-page-header'

const FormsJoinViewPageHeader: FC<FormsJoinViewPageHeaderProps> = (props: FormsJoinViewPageHeaderProps) => {
  const { dataTest = rootClass, className = '', currentItemType } = props

  const [dropDownOpen, setDropdownOpen] = useState(false)

  const { t } = useTranslation()

  const history = useHistory()

  const { hasEmbeddedAnalytics } = useAccountSettings()

  const isWebinarSubmissions = currentItemType === ItemType.WEBINAR_SUBMISSION

  const toggleDropDown = () => setDropdownOpen(!dropDownOpen)

  const renderButtons = () => (
    <div className={`${rootClass}__header-button`}>
      <Button
        className={`${rootClass}__create-form-button`}
        buttonType={ButtonType.PRIMARY}
        onClick={() => (isWebinarSubmissions ? goCreateWebinar() : goCreateForm())}
      >
        {!isWebinarSubmissions && <Svg type={SvgType.ICON} name={SvgNames.plus} className={`${rootClass}__create-form-icon`} fill={SvgColor.WHITE} />}
        <Typography text={t(isWebinarSubmissions ? 'View webinars' : 'Create form')} type={TextType.BODY_TEXT_WHITE} weight={TextWeight.MEDIUM} />
        {isWebinarSubmissions && (
          <Svg type={SvgType.ICON} name={SvgNames.customLink} className={`${rootClass}__view-webinars-icon`} fill={SvgColor.WHITE} />
        )}
      </Button>
      {hasEmbeddedAnalytics && !isWebinarSubmissions && (
        <DropDown
          type={DropDownType.STYLED}
          hasOverflowIcon
          isOpen={dropDownOpen}
          toggleOpen={toggleDropDown}
          className={classNames({ [`${rootClass}__drop-down-open`]: dropDownOpen })}
        >
          <DropDownActions menuItems={getDropDownActions(t, history.push)} closeDropDown={toggleDropDown} />
        </DropDown>
      )}
    </div>
  )

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {renderButtons()}
    </div>
  )
}

export default FormsJoinViewPageHeader
