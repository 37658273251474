import pretty from 'pretty'

import { LandingPageContentResponse, LandingPageCssFile, LandingPageJsFile } from '@graphql/types/query-types'
import { prepareJsonForBeeEditor } from '@src/pages/EmailComposer/utils/retrieveMessageHelper'
import {
  DEFAULT_LANDING_PAGE_TITLE,
  LandingPageComposerState,
  LandingPageRedirectUrl,
  LandingPageVisibilityStatus,
} from '@utils/composer/context/LandingPageComposer.context'
import { DeepPartial } from '@utils/types'

export const retrieveLandingPageHelper = ({
  landingPage,
  containerValuesRef,
  newLPComposerCreateBlank,
  accountName,
}: {
  landingPage: LandingPageContentResponse
  containerValuesRef: React.MutableRefObject<LandingPageComposerState>
  newLPComposerCreateBlank?: boolean
  accountName?: string
}): DeepPartial<LandingPageComposerState> => {
  const {
    landingPage: { isLandingPage },
  } = containerValuesRef.current

  const { beeEditorPage, emailContentHtml, settings, isValidJson, messageType } = landingPage
  const { enabled, name, lastUpdated, seoInfo, redirectConfig, createdAt, customCode, publishInfo } = settings ?? {}
  const { hasUnpublishedChanges, isLandingPagePublished, lastPublishedAt } = publishInfo ?? {}
  const { allowIndexing, image, pageDescription, title } = seoInfo ?? {}

  const { redirect, redirectType } = redirectConfig ?? {}

  const landingPageTitle = !name?.length ? DEFAULT_LANDING_PAGE_TITLE : name

  const autoSave = containerValuesRef.current.disabledFeatures.autoSave

  const parsedJson = prepareJsonForBeeEditor(beeEditorPage, isValidJson, isLandingPage)

  return {
    loading: false,
    lastSavedTitle: landingPageTitle,
    disabledFeatures: { autoSave },
    message: {
      messageType,
      title: landingPageTitle,
      templateJson: parsedJson,
      templateHtml: emailContentHtml ? pretty(emailContentHtml) : undefined,
    },
    lastEdited: lastUpdated,
    createdAt,
    landingPage: {
      ...(!newLPComposerCreateBlank && { lastStatusEdited: lastUpdated, isLandingPageEnabled: enabled }),
      ...(newLPComposerCreateBlank && { isLandingPagePublished, lastPublishedTime: lastPublishedAt, hasUnpublishedChanges }),
      landingPageMetaTitle: title || accountName,
      landingPageMetaDescription: pageDescription,
      landingPageMetaImage: image,
      landingPageId: redirectType === LandingPageRedirectUrl.LANDING_PAGE ? redirect : '',
      landingPageIndividualUrl: redirectType === LandingPageRedirectUrl.INDIVIDUAL ? redirect : '',
      landingPageVisibility: allowIndexing ? LandingPageVisibilityStatus.SHOW : LandingPageVisibilityStatus.HIDE,
      landingPageUrlType: redirectType ?? LandingPageRedirectUrl.GLOBAL,
      customJavaScript: customCode?.customJavascript,
      customCss: customCode?.customCss,
      selectedCss: (customCode?.cssFiles ?? []).filter((cssFile): cssFile is LandingPageCssFile => cssFile !== undefined),
      selectedScripts: (customCode?.jsFiles ?? []).filter((jsFile): jsFile is LandingPageJsFile => jsFile !== undefined),
    },
  }
}
