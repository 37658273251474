import React, { FC, useEffect, useState } from 'react'

import { ApolloClient } from '@apollo/client'
import EmptyListing from '@components/EmptyListing/EmptyListing'
import SearchResultSummary from '@components/SearchResultSummary/SearchResultSummary'
import { MIN_TABLE_ROWS_FOR_PAGINATION, useTranslation } from '@const/globals'
import { OptInStatus } from '@graphql/types/microservice/sms-management-types'
import OptInsTable from '@src/pages/sms/marketingListOptIns/components/SMSOptinViewAll/OptInsTable'
import { useOptInsResults, processOptInResults } from '@src/pages/sms/marketingListOptIns/components/SMSOptinViewAll/OptInView.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { logNewRelicError } from '@utils/new-relic.utils'
import { SortByState } from '@utils/sms.utils'

import './OptInSearchResults.css'

interface Props {
  search: string
  onClear: () => void
}

interface State {
  pageIndex: number
  pageSize: number
  sortBy: SortByState[]
  client?: ApolloClient<any>
}

const rootClass = 'opt-in-search-results'
const MIN_SEARCH_LENGTH = 3

const defaultSortBy: SortByState = {
  id: 'optInDate',
  desc: true,
}

const defaultState: State = {
  pageSize: 10,
  pageIndex: 0,
  sortBy: [defaultSortBy],
}

const convertAccessorToSort = (id: string) => {
  switch (id) {
    case 'contactName':
      return 'CONTACTNAME'
    case 'listName':
      return 'LISTNAME'
    case 'phoneNumber':
      return 'PHONENUMBER'
    case 'optInSource':
      return 'OPTINSOURCE'
    case 'optInDate':
      return 'OPTINDATE'
    default:
      throw new Error(`Unknown accessor: ${id}`)
  }
}

const OptInSearchResults: FC<Props> = ({ search, onClear }: Props) => {
  const { t } = useTranslation()
  const [count, setCount] = useState<number>(0)
  const { accountId } = useAccountSettings()
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.SMS_MANAGEMENT })
  const [pageState, setPageState] = useState<State>({ ...defaultState, client })
  const [entries, setEntries] = useState<OptInStatus[]>([])

  const { data, error } = useOptInsResults({
    client: pageState.client,
    accountId,
    page: pageState.pageIndex,
    size: pageState.pageSize,
    searchTerm: search,
    sortColumn: convertAccessorToSort(pageState.sortBy[0].id),
    sortDirection: pageState.sortBy[0].desc ? 'DESC' : 'ASC',
  })

  useEffect(() => {
    const entries = processOptInResults(data)
    setEntries(entries)
    setCount(data?.getOptInStatusByAccountId.totalContacts ?? 0)
  }, [data])

  useEffect(() => {
    if (error) {
      logNewRelicError(error)
    }
  }, [error])
  const fetchData = (pageIndex: number, pageSize: number) => {
    setPageState({ ...pageState, pageIndex, pageSize })
  }

  const onChangeSort = (sortBy: Array<any>) => {
    const validSortBy = sortBy.length > 0 ? sortBy : [defaultSortBy]
    setPageState({ ...pageState, sortBy: validSortBy })
  }

  const hidePagination = count < MIN_TABLE_ROWS_FOR_PAGINATION
  const passOnClassName = count === 0 ? `${rootClass}__empty-listing` : hidePagination ? `${rootClass}__no-pagination` : `${rootClass}__pagination`

  if (search.length < MIN_SEARCH_LENGTH) {
    return <EmptyListing text={t('Minimum of three characters required')} headline={'Search too short'} />
  }

  return (
    <div className={rootClass}>
      <SearchResultSummary term={search} count={count} name={t(count === 1 ? 'contact' : 'contacts')} onClear={onClear} />
      <OptInsTable
        totalCount={count}
        pageSize={pageState.pageSize}
        pageIndex={pageState.pageIndex}
        hidePagination={hidePagination}
        entries={entries}
        loading={false}
        emptyText={t('No contacts match your search')}
        className={passOnClassName}
        fetchData={fetchData}
        sortBy={pageState.sortBy}
        onChangeSort={onChangeSort}
      />
    </div>
  )
}

export default OptInSearchResults
