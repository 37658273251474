import React, { FC, useContext, MouseEvent } from 'react'

import classNames from 'classnames'

import Pill, { PillType } from '@components/Pill/Pill'
import { TableV2 } from '@components/TableV2/TableV2'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { legacyActonContext, useTranslation, rootContext } from '@const/globals'
import { FormManagerContext, FormManagerLandingPage } from '@src/pages/Content/Forms/FormManager/context/FormManager.context'
import { CellContext, SortingState } from '@tanstack/react-table'

import './LandingPages.css'

interface LandingPagesProps {
  className?: string
  dataTest?: string
}

export const defaultSortingBy: SortingState = [
  {
    id: 'title',
    desc: false,
  },
]

const rootClass = 'landing-pages'

const LandingPages: FC<LandingPagesProps> = (props: LandingPagesProps) => {
  const { dataTest = rootClass, className = '' } = props
  const {
    values: { landingPages },
  } = useContext(FormManagerContext)
  const { t } = useTranslation()

  const LANDING_PAGE_LINK = `${legacyActonContext}//ng-ui/editor/#!/lp-editor`

  const handleClick = (event: MouseEvent<HTMLAnchorElement>, id: string, isBee: boolean) => {
    if (event.button === 0) {
      event.preventDefault()
      window.open(!isBee ? `${LANDING_PAGE_LINK}/${id}` : `${rootContext}/content/landingPage/${id}/settings`, `editLandingPage-${id}`)
    }
  }

  const columns: ColumnDefWithAdditionalProps<FormManagerLandingPage, any>[] = [
    {
      header: 'Page Name',
      textAlign: 'left',
      accessorKey: 'title',
      enableClickableCell: true,
      hideTooltip: true,
      cell: (cell: CellContext<FormManagerLandingPage, any>) => (
        <>
          <TextLink
            text={cell.getValue()}
            link={
              !cell.row.original.isBee ? `${LANDING_PAGE_LINK}/${cell.row.original.id}` : `${rootContext}/content/landingPage/${cell.row.original.id}`
            }
            size={TextLinkSize.LARGE}
            onClick={(event) => handleClick(event, cell.row.original.id, cell.row.original.isBee)}
            className="push-left-half"
          />
          {cell.row.original.isBee && <Pill noMargin text="Beta" type={PillType.SECONDARY} />}
        </>
      ),
    },
  ]

  const renderEmpty = (
    <div className={`${rootClass}__empty`}>
      <Typography text={t("This form hasn't been added to any Act-On Landing Pages")} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.MEDIUM} />
    </div>
  )
  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {landingPages.length > 0 && <TableV2 data={landingPages} columns={columns} withoutBorders sortingBy={defaultSortingBy} />}
      {landingPages.length === 0 && renderEmpty}
    </div>
  )
}

export default LandingPages
