import React, { FC, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { SmsMessageContent } from '@graphql/types/microservice/sms-management-types'
import { useSmsMessageChatResults } from '@src/pages/sms/preview/SMSPreview.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { logNewRelicError } from '@utils/new-relic.utils'
import { MatchParams } from '@utils/types'

import SMSPreview from './SMSPreview'

interface Props {
  dataTest?: string
}

interface State {
  smsMessage?: SmsMessageContent
  smsReplies?: any[]
}

type AllProps = RouteComponentProps<MatchParams> & Props

const SMSPreviewContainer: FC<AllProps> = (props: AllProps) => {
  const { match } = props
  const { accountId } = useAccountSettings()
  const launchId = match.params.id
  const recId = match.params.recid

  const { client: smsManagementClient } = useMicroserviceClient({ serviceName: MicroserviceClients.SMS_MANAGEMENT })

  const [state, setState] = useState<State>({})

  const { loading, data, error } = useSmsMessageChatResults(smsManagementClient, {
    launchId,
    accountId,
    recId,
    includeReplies: true,
  })

  useEffect(() => {
    const { smsMessage, smsReplies } = data?.getSmsMessageChat || {}

    setState({ ...state, smsMessage, smsReplies })
  }, [data])

  useEffect(() => {
    if (error) {
      logNewRelicError(error)
    }
  }, [error])

  return <SMSPreview loading={loading} error={error} smsMessage={state.smsMessage} smsReplies={state.smsReplies} />
}

export default SMSPreviewContainer
