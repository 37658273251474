import getItemHierarchyByExternalId from '@graphql/microservices/categorization/getItemHierarchyByExternalId'
import { GetItemHierarchyByExternalIdQuery, GetItemHierarchyByExternalIdQueryVariables } from '@graphql/types/microservice/categorization-types'
import { ItemType } from '@utils/categorization'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export interface SegmentComposerCategorizationRequests {
  getSegmentHierarchyRequest: (externalId: string) => FetchPromise<GetItemHierarchyByExternalIdQuery>
}

export const useSegmentComposerCategorizationRequests = (): SegmentComposerCategorizationRequests => {
  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })

  const getSegmentHierarchyRequest = (externalId: string) =>
    categorizationClient.query<GetItemHierarchyByExternalIdQuery, GetItemHierarchyByExternalIdQueryVariables>({
      query: getItemHierarchyByExternalId,
      fetchPolicy: 'network-only',
      variables: {
        externalId,
        type: ItemType.SEGMENT,
      },
    })

  return {
    getSegmentHierarchyRequest,
  }
}
