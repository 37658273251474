import { LabelDto } from '@graphql/types/microservice/categorization-types'

export const NO_COLOR = '#949494'

export const TagColors: { [key: string]: TagColor } = {
  [NO_COLOR]: { name: 'No Color', className: 'no-color' },
  '#FF9F00': { name: 'Orange', className: 'orange-peel' },
  '#FFDF00': { name: 'Gold', className: 'golden-yellow' },
  '#32CD32': { name: 'Lime', className: 'lime-green' },
  '#0AAA5D': { name: 'Leaf', className: 'leaf' },
  '#53BFFC': { name: 'Sky', className: 'sky' },
  '#484EE7': { name: 'Cobalt', className: 'cobalt' },
  '#9F00FF': { name: 'Violet', className: 'vivid-violet' },
  '#FF1DCE': { name: 'Magenta', className: 'hot-magenta' },
  '#9B111E': { name: 'Ruby', className: 'ruby-red' },
}

export const TagColorsObjects = Object.entries(TagColors).map(([key, { name, className }]) => ({ color: key, name, className }))

export interface TagColor {
  name: string
  className: string
}

export enum TagRowActions {
  DELETE = 'delete',
  EDIT = 'edit',
}

export const sortTagsByName = (tags: LabelDto[]) =>
  [...tags].sort((a, b) => a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase() as string) as number)
