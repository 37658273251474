import React, { FC, Key, useContext, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import Container from '@components/Container'
import EditableContainer from '@components/EditableContainer/EditableContainer'
import Loader from '@components/Loader'
import RadioCard, { STYLE } from '@components/RadioCard/RadioCard'
import RadioCardGroup from '@components/RadioCardGroup/RadioCardGroup'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import {
  ColumnType,
  DynamicListItems,
  OptionType,
  RowType,
} from '@src/pages/datamanagement/components/CrmContacts/components/DynamicList/utils/DynamicList.interfaces'
import { rowHasMappedCRM } from '@src/pages/datamanagement/components/CrmContacts/components/DynamicList/utils/DynamicList.utils'
import ReviewPushModal from '@src/pages/datamanagement/components/ReviewPushModal/ReviewPushModal'
import { DataManagementContext } from '@src/pages/datamanagement/context/DataManagementContext'
import useCRM, { CRMConnectorType } from '@utils/hooks/useCRM'

import './PushSetting.css'

interface PushSettingProps {
  className?: string
  dataTest?: string
}

const rootClass = 'push-setting'

export enum PushMethod {
  ALLOW = 'allow',
  NOT_ALLOW = 'not allow',
}

type CRMObject = {
  name: string
  image?: SvgNames
}

const getCRM = (connector: CRMConnectorType): CRMObject => {
  const connectors: { [key in CRMConnectorType]: CRMObject } = {
    [CRMConnectorType.MS_DYNAMICS]: { name: 'MS Dynamics', image: SvgNames.dynamicsPush },
    [CRMConnectorType.SALESLOGIC]: { name: 'Saleslogix' },
    [CRMConnectorType.SALESFORCE]: { name: 'Salesforce', image: SvgNames.salesforcePush },
    [CRMConnectorType.ZENDESK]: { name: 'Zendesk', image: SvgNames.zendeskPush },
    [CRMConnectorType.NETSUITE]: { name: 'NetSuite', image: SvgNames.netsuitePush },
    [CRMConnectorType.SUGAR]: { name: 'SugarCRM', image: SvgNames.sugarPush },
    [CRMConnectorType.NOT_CONNECTED]: { name: '' },
  }
  return connectors[connector]
}

const PushSetting: FC<PushSettingProps> = (props: PushSettingProps) => {
  const { dataTest = rootClass, className = '' } = props

  const {
    refreshCRMData,
    savePushPolicy,
    values: { crmContactData, pushPolicy, pushPolicyLoading },
  } = useContext(DataManagementContext)

  const [pushMethod, setPushMethod] = useState<Key>(PushMethod.ALLOW)
  const [showReviewPushModal, setShowReviewPushModal] = useState<boolean>(false)

  const { connectorType } = useCRM()

  const { t } = useTranslation()

  const { name: crmName, image: crmImage } = getCRM(connectorType)

  const getDisplayNameFromOptionColumn = (selected?: string, values?: OptionType[]) => {
    return selected !== '-1' ? values?.find(({ key }) => key === selected)?.display : undefined
  }

  const getRow = (rowColumns: DynamicListItems[]) => {
    return rowColumns
      .filter(({ type, column }) => type !== ColumnType.BLANK && type !== ColumnType.ICON && column !== t('DATA TYPE'))
      .map(({ type, selected, values, staticValue, column = '' }) => ({
        column,
        value: type === ColumnType.OPTION ? getDisplayNameFromOptionColumn(selected, values) : staticValue,
      }))
  }

  const rows = useMemo(() => {
    return [...crmContactData.standardRows, ...crmContactData.customRows]
      .filter(({ columns, type, id }) => {
        if ((type === RowType.REQUIRED || type === RowType.STANDARD || type === RowType.CUSTOM) && id !== -1) {
          const actonContactsColumn = columns.find(({ column }) => column === t('ACT-ON CONTACTS'))
          const displayName =
            actonContactsColumn?.type === ColumnType.OPTION
              ? getDisplayNameFromOptionColumn(actonContactsColumn?.selected, actonContactsColumn?.values)
              : actonContactsColumn?.staticValue
          const isAllowed = pushPolicy?.enabledFields?.includes(displayName)
          return ((isAllowed && pushMethod === PushMethod.NOT_ALLOW) || (!isAllowed && pushMethod === PushMethod.ALLOW)) && rowHasMappedCRM(columns)
        }
      })
      .map(({ columns }) => getRow(columns))
  }, [crmContactData.customRows, crmContactData.standardRows, crmContactData.dataAvailable, pushMethod, pushPolicy?.enabledFields])

  useEffect(() => {
    setPushMethod(pushPolicy?.allowPush ? PushMethod.ALLOW : PushMethod.NOT_ALLOW)
  }, [pushPolicy?.allowPush])

  const onContainerCancel = () => {
    setPushMethod(pushPolicy?.allowPush ? PushMethod.ALLOW : PushMethod.NOT_ALLOW)
    if (showReviewPushModal) {
      setShowReviewPushModal(false)
    }
  }

  const onContainerSave = () => {
    setShowReviewPushModal(true)
  }

  const onSaveConfirm = () => {
    setShowReviewPushModal(false)
    const allowPush = pushMethod === PushMethod.ALLOW
    savePushPolicy(allowPush).then(() => {
      refreshCRMData()
    })
  }

  return (
    <Container className={classNames(rootClass, className)} dataTest={dataTest}>
      {showReviewPushModal && <ReviewPushModal onCancel={onContainerCancel} onSave={onSaveConfirm} pushMethod={pushMethod} rows={rows} />}
      {pushPolicyLoading ? (
        <Loader className={`${rootClass}__loader`} />
      ) : (
        <EditableContainer className={`${rootClass}__editable-container`} onSave={onContainerSave} onCancel={onContainerCancel} fullWidth>
          {(isEditing) => (
            <>
              <Typography
                text={t('CRM Data Sync Setting')}
                type={TextType.BODY_TEXT_LARGE}
                weight={TextWeight.MEDIUM}
                lineHeight={LineHeight.MEDIUM_LARGE}
              />
              <Typography
                className={`${rootClass}__subheader`}
                text={t(pushPolicy?.allowPush ? 'CRM.PushSetting.Subheader.Push' : 'CRM.PushSetting.Subheader.Pull', { crmName })}
                type={TextType.BODY_TEXT_LIGHT}
              />
              <div className={`${rootClass}__push-images`}>
                <div className={`${rootClass}__push-image-container`}>
                  {crmImage && <Svg name={crmImage} type={SvgType.EXTRA_LARGE_ICON} />}
                  <Typography className={`${rootClass}__push-image-container-text`} text={crmName} />
                </div>
                <Svg
                  name={pushMethod === PushMethod.ALLOW ? SvgNames.twoWayArrow : SvgNames.arrowRight}
                  type={SvgType.LARGE_ICON}
                  fill={SvgColor.LIGHT_GRAY}
                />
                <div className={`${rootClass}__push-image-container`}>
                  <Svg name={SvgNames.actOnPush} type={SvgType.EXTRA_LARGE_ICON} />
                  <Typography className={`${rootClass}__push-image-container-text`} text={t('Act-On fields')} />
                </div>
              </div>
              {isEditing && (
                <div className={`${rootClass}__push-selector`}>
                  <Typography type={TextType.BODY_TEXT} weight={TextWeight.MEDIUM} text={t('Default sync setting')} />
                  <Typography
                    className={`${rootClass}__push-selector-subheader`}
                    type={TextType.BODY_TEXT_LIGHT}
                    text={t('CRM.PushSetting.Selector.Subheader', { crmName })}
                  />
                  <RadioCardGroup selectedOption={pushMethod} onSelect={(key: Key) => setPushMethod(key)}>
                    <RadioCard
                      title={t('CRM.PushSetting.Selector.Allow.Title', { crmName })}
                      description={t('CRM.PushSetting.Selector.Allow.Description', { crmName })}
                      key={PushMethod.ALLOW}
                      style={STYLE.RADIO_OUT}
                    />
                    <RadioCard
                      title={t('CRM.PushSetting.Selector.NotAllow.Title', { crmName })}
                      description={t('CRM.PushSetting.Selector.NotAllow.Description', { crmName })}
                      key={PushMethod.NOT_ALLOW}
                      style={STYLE.RADIO_OUT}
                    />
                  </RadioCardGroup>
                </div>
              )}
            </>
          )}
        </EditableContainer>
      )}
    </Container>
  )
}

export default PushSetting
