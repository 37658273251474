import React, { FC } from 'react'

import { SelectOption } from '@components/MultiSelect/MultiSelect'
import PageContainer from '@components/PageContainer'
import PageHeader from '@components/PageHeader'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import Tabs from '@components/TabsAO/TabsAO'
import { useTranslation } from '@const/globals'
import withLoadingAndError from '@hoc/withLoadingAndError/withLoadingAndError'

import FragmentsTabContainer from './components/FragmentsTab/FragmentsTabContainer'
import TagsTabContainer from './components/TagsTab/TagsTabContainer'

import './LinkPerformance.css'

export interface LinkPerformanceProps {
  emailName: string
  messageId: string
  currentTab: string
  tagOptions: SelectOption[]
  fragOptions: SelectOption[]
  onTabChange: (newTab: string) => void
  dataTest?: string
  className?: string
}

const rootClass = 'link-performance'

const LinkPerformance: FC<LinkPerformanceProps> = (props: LinkPerformanceProps) => {
  const { dataTest = rootClass, className = rootClass, emailName, messageId, currentTab, onTabChange, fragOptions, tagOptions } = props
  const { t } = useTranslation()

  const tabs = [
    {
      index: 'fragments',
      label: t('Fragments'),
      content: <FragmentsTabContainer messageId={messageId} allTags={tagOptions} allFragments={fragOptions}></FragmentsTabContainer>,
    },
    {
      index: 'tags',
      label: t('Tags'),
      content: <TagsTabContainer messageId={messageId} allTags={tagOptions}></TagsTabContainer>,
    },
  ]

  return (
    <PageContainer className={rootClass} dataTest={dataTest}>
      <PositionContainer>
        <PageHeader primaryText={`${t('Link Performance for')} ${emailName}`} leftContent dataTest={rootClass}></PageHeader>
        <Tabs childData={tabs} onChange={onTabChange} defaultValue={currentTab} className={`${className}__tabs`} />
      </PositionContainer>
    </PageContainer>
  )
}

export default withLoadingAndError(LinkPerformance)
