import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { renderToString } from 'react-dom/server'

import { SelectV2GroupedOption } from '@components/SelectV2/SelectV2.props'
import { SvgColor, SvgContainerType, SvgType } from '@components/Svg/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { getUUID, useTranslation } from '@const/globals'
import { getCalendarLink } from '@src/pages/EmailComposer/utils/Addons/customAddons.helpers'
import { CustomAddonModalForm } from '@src/pages/EmailComposer/utils/Addons/customAddons.types'
import { IAddOnResponseHTMLExtended } from '@src/pages/EmailComposer/utils/BeeEditor.types'
import { useAccountSettings } from '@utils/account/account.utils'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { logNewRelicError } from '@utils/new-relic.utils'
import { getDynamicTimezones } from '@utils/timezones'

import { EventBlock } from './components/EventBlock/EventBlock'
import { CalendarOption } from './components/EventBlock/EventBlock.utils'
import EventModal from './EventModal'
import { useEventModalRequests } from './graphQL/eventModal.graphQL'
import {
  getDefaultValues,
  getEventBlockMetaData,
  parseAddress,
  PLACEHOLDER_VIDEO_CONFERENCE_OPTION,
  renderSelectOptionIcon,
  WebinarType,
} from './utils/EventModal.utils'

interface EventModalContainerProps {
  onClose: () => void
  onAction: (data: IAddOnResponseHTMLExtended) => void
  defaultValues?: Record<string, any>
  className?: string
  dataTest?: string
}

const rootClass = 'event-modal-container'

const EventModalContainer: FC<EventModalContainerProps> = (props: EventModalContainerProps) => {
  const { onClose, onAction, defaultValues } = props
  const {
    values: { message },
  } = useContext(EmailComposerContext)

  const { accountTimeZoneId } = useAccountSettings()
  const { getCustomAccountCompanyInfo, getUserAddressInfo } = useEventModalRequests()
  const { t } = useTranslation()
  const { accountId } = useAccountSettings()

  const [companyName, setCompanyName] = useState<string>()
  const [addressOptions, setAddressOptions] = useState<SelectV2GroupedOption[]>()
  const [fetchAddressOptions, setFetchAddressOptions] = useState<boolean>(true)
  const [webinarOptions, setWebinarOptions] = useState<SelectV2GroupedOption[]>()
  const [loading, setLoading] = useState<boolean>(true)

  const timeZoneOptions = getDynamicTimezones().map((timezone) => ({ label: timezone.label.replace('_', ' '), value: timezone.value }))

  const form = useForm<CustomAddonModalForm>({
    mode: 'onChange',
    defaultValues: getDefaultValues(defaultValues, accountTimeZoneId),
  })

  const getAddressOptions = useCallback(async () => {
    const { data: accountData, errors: accountError } = await getCustomAccountCompanyInfo()
    const { data: userData, errors: userError } = await getUserAddressInfo()

    if (accountData?.getCustomAccountCompanyInfo && userData?.loadOverviewPage) {
      const { defaultAccountAddress, companyName, address: otherAddresses } = accountData.getCustomAccountCompanyInfo
      const userAddressProps = userData.loadOverviewPage
      const defaultAddress =
        defaultValues?.address && defaultValues?.addressName
          ? {
              value: defaultValues?.addressName,
              label: defaultValues?.addressName,
              subText: defaultValues?.address,
              extraOptions: {
                street: defaultValues?.street,
                street2: defaultValues?.street2,
                city: defaultValues?.city,
                state: defaultValues?.state,
                zip: defaultValues?.zip,
              },
            }
          : undefined

      const companyAddress = parseAddress(
        { name: SvgNames.buildings, type: SvgType.SELECTV2_OPTION_ICON, containerType: SvgContainerType.LARGE },
        defaultAccountAddress
      )
      const userAddress = parseAddress(
        { name: SvgNames.userCircle, type: SvgType.SELECTV2_OPTION_ICON, containerType: SvgContainerType.LARGE },
        { name: t('EmailComposer.EventModal.UserAddress'), ...userAddressProps }
      )

      const otherAddressData =
        otherAddresses?.map((address) =>
          parseAddress(
            { name: SvgNames.location, fill: SvgColor.BUTTON_GRAY, type: SvgType.SELECTV2_OPTION_ICON, containerType: SvgContainerType.LARGE },
            address
          )
        ) ?? []

      const addressOptions: SelectV2GroupedOption[] = [
        {
          options: [{ ...companyAddress }, { ...userAddress }],
          label: t('EmailComposer.EventModal.AccountAddresses'),
        },
        {
          options: otherAddressData,
          label: t('EmailComposer.EventModal.OtherAddresses'),
        },
      ]

      form.setValue('locationDetails.selectedAddress', defaultAddress ?? addressOptions[0].options[0])
      setCompanyName(companyName)
      setAddressOptions(addressOptions)
      setFetchAddressOptions(false)
    } else if (accountError || userError) {
      logNewRelicError(accountError ?? userError)
    }
  }, [getCustomAccountCompanyInfo, getUserAddressInfo])

  useEffect(() => {
    if (fetchAddressOptions) {
      setFetchAddressOptions(false)
      getAddressOptions()
    }
  }, [fetchAddressOptions, getAddressOptions])

  useEffect(() => {
    const getWebinarOptions = () => {
      const defaultWebinarType = defaultValues?.videoConferenceName
        ? { label: defaultValues?.videoConferenceName, value: defaultValues?.videoConferenceName, subText: defaultValues?.videoConferenceDescription }
        : PLACEHOLDER_VIDEO_CONFERENCE_OPTION

      form.setValue('webinarDetails.selectedWebinarType', defaultWebinarType)
      form.setValue('webinarDetails.selectedWebinar', defaultValues?.selectedWebinar)
      setWebinarOptions(webinarOptions)
    }

    getWebinarOptions()
  }, [defaultValues?.selectedWebinar, defaultValues?.videoConferenceDescription, defaultValues?.videoConferenceName, form, webinarOptions])

  useEffect(() => {
    if (addressOptions) {
      setLoading(false)
    }
  }, [addressOptions])

  const webinarTypeOptions = [
    {
      label: WebinarType.ZOOM_MEETING,
      value: WebinarType.ZOOM_MEETING,
      renderItemIcon: () =>
        renderSelectOptionIcon(undefined, { name: SvgNames.zoomLogo, type: SvgType.VERY_LARGE_ICON, containerType: SvgContainerType.LARGE }),
      subText: t('EmailComposer.EventModal.WebinarType.Zoom'),
    },
    {
      label: WebinarType.GOOGLE_MEET,
      value: WebinarType.GOOGLE_MEET,
      renderItemIcon: () =>
        renderSelectOptionIcon(undefined, { name: SvgNames.googleMeetLogo, type: SvgType.VERY_LARGE_ICON, containerType: SvgContainerType.LARGE }),
      subText: t('EmailComposer.EventModal.WebinarType.Google'),
    },
    {
      label: WebinarType.WEBEX_MEETING,
      value: WebinarType.WEBEX_MEETING,
      renderItemIcon: () =>
        renderSelectOptionIcon(undefined, { name: SvgNames.webexLogo, type: SvgType.VERY_LARGE_ICON, containerType: SvgContainerType.LARGE }),
      subText: t('EmailComposer.EventModal.WebinarType.Webex'),
    },
    {
      label: WebinarType.EXTERNAL_EVENT_LINK,
      value: WebinarType.EXTERNAL_EVENT_LINK,
      renderItemIcon: () =>
        renderSelectOptionIcon(undefined, {
          name: SvgNames.linked,
          fill: SvgColor.TEXT_TEAL,
          type: SvgType.VERY_LARGE_ICON,
          containerType: SvgContainerType.LARGE,
        }),
      subText: t('Add an external link for recipients to join your event.'),
    },
  ]

  const handleInsert = (data: CustomAddonModalForm) => {
    const eventStrings = {
      addToCalendar: t('EmailComposer.EventBlock.AddToCalendar'),
      addEvent: t('EmailComposer.EventBlock.AddEventToCalendar'),
      getDirections: t('EmailComposer.EventBlock.GetDirections'),
    }

    const blockId = defaultValues?.blockId ? defaultValues?.blockId : getUUID(true)
    const calendarDownloadLink = defaultValues?.calendarDownloadLink
      ? defaultValues?.calendarDownloadLink
      : getCalendarLink(data, CalendarOption.APPLE, accountId, blockId, true)

    onAction({
      type: 'html',
      value: {
        html: renderToString(
          <EventBlock
            data={data}
            eventStrings={eventStrings}
            companyName={companyName ?? ''}
            messageId={message.id}
            accountId={accountId}
            blockId={blockId}
            calendarDownloadLink={calendarDownloadLink}
          />
        ),
        customFields: { ...getEventBlockMetaData(data, blockId, calendarDownloadLink) },
      },
    })
  }

  return (
    <div className={rootClass}>
      <EventModal
        form={form}
        onClose={onClose}
        loading={loading}
        timeZoneOptions={timeZoneOptions}
        addressOptions={addressOptions ?? []}
        webinarOptions={webinarOptions ?? []}
        webinarTypeOptions={webinarTypeOptions ?? []}
        refetchAddressOptions={(fetch: boolean) => setFetchAddressOptions(fetch)}
        handleInsert={handleInsert}
        submitButtonText={defaultValues?.blockId ? 'Save event' : 'Create event'}
      />
    </div>
  )
}

export default EventModalContainer
