import { MouseEvent, RefObject, useRef, useState } from 'react'

import { DropDownProps } from '@components/DropDown/DropDown'

/**
 * Enables programmatic control of text input fields via command buttons,
 * allowing arbitrary text insertion to behave like a paste command,
 * and preservation of the browser's native undo/redo states.
 *
 * This implementation will only work with an uncontrolled input field,
 * so `defaultValue` prop should be used instead of `value`.
 * If the value needs to be completely replaced, use `reset()`
 *
 * `onTextChange` and `onButtonMouseDown` should be applied to the text input and command buttons
 *
 * For `<DropDown />`, the `toggleOpen` prop should use `onDropDownToggle`
 * since it does not trigger the necessary mouse events
 */
export const useTextCommands = <T extends HTMLTextAreaElement | HTMLInputElement>(inputRef: RefObject<T>) => {
  const [isUndoEnabled, setUndoEnabled] = useState(false)
  const [isRedoEnabled, setRedoEnabled] = useState(false)
  const wasFocused = useRef(false)

  const checkUndoRedoEnabled = () => {
    if (inputRef.current) {
      inputRef.current.focus()
      setUndoEnabled(document.queryCommandEnabled('undo'))
      setRedoEnabled(document.queryCommandEnabled('redo'))
    }
    return false
  }

  const checkInputFocused = () => {
    // When a toolbar element is invoked we check if the text input was focused
    // to determine where to insert the text (in place or at the bottom)
    wasFocused.current = document.activeElement === inputRef.current
  }

  const onTextChange = () => checkUndoRedoEnabled()

  const onDropDownToggle: DropDownProps['toggleOpen'] = (isOpen) => isOpen && checkInputFocused()

  const onButtonMouseDown = (e: MouseEvent) => {
    checkInputFocused()
    e.preventDefault()
  }

  const setCursorToEnd = () => {
    if (inputRef.current) {
      inputRef.current.focus()
      const value = inputRef.current.value
      inputRef.current.setSelectionRange(value.length, value.length)
      wasFocused.current = false
    }
  }

  const setCursorToStart = () => {
    if (inputRef.current) {
      inputRef.current.focus()
      inputRef.current.setSelectionRange(0, 0)
      wasFocused.current = false
    }
  }

  const insert = (text: string, insertPosition?: number) => {
    if (inputRef.current) {
      inputRef.current.focus()
      const notFocusedDefaultBehavior = !wasFocused.current && insertPosition === undefined
      const insertAtBottom = notFocusedDefaultBehavior || (!!insertPosition && insertPosition >= inputRef.current.value.length)
      const insertAtTop = insertPosition === 0

      insertAtBottom && setCursorToEnd()
      insertAtTop && setCursorToStart()

      if (insertPosition !== undefined) {
        inputRef.current.setSelectionRange(insertPosition, insertPosition)
      }
      document.execCommand('insertText', false, text)

      insertAtBottom && inputRef.current.scrollTo({ top: inputRef.current.scrollHeight, behavior: 'smooth' })
      insertAtTop && inputRef.current.scrollTo({ top: 0, behavior: 'smooth' })

      checkUndoRedoEnabled()
    }
  }

  const clear = () => reset('')

  const reset = (text: string) => {
    if (inputRef.current) {
      inputRef.current.focus()
      document.execCommand('selectAll')
      document.execCommand('insertText', false, text)
      checkUndoRedoEnabled()
    }
  }

  const undo = () => {
    if (inputRef.current) {
      inputRef.current.focus()
      document.execCommand('undo')
      checkUndoRedoEnabled()
    }
  }

  const redo = () => {
    if (inputRef.current) {
      inputRef.current.focus()
      document.execCommand('redo')
      checkUndoRedoEnabled()
    }
  }

  return {
    insert,
    clear,
    undo,
    redo,
    reset,
    onButtonMouseDown,
    onTextChange,
    onDropDownToggle,
    isUndoEnabled,
    isRedoEnabled,
  }
}
