import React, { FC, useCallback, useEffect, useRef } from 'react'
import { useFieldArray, UseFieldArrayReturn, useFormContext, useWatch } from 'react-hook-form'

import classNames from 'classnames'

import { PersonalizationContainerTagInputProps } from '@complex/Personalization/PersonalizationContainer'
import AddButton from '@components/AddButton/AddButton'
import ButtonIcon, { ButtonIconType } from '@components/ButtonIcon/ButtonIcon'
import Container from '@components/Container'
import { getPersonalizationSvg, PersonalizationTag } from '@components/PersonalizationTagInput/PersonalizationTagInput.utils'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SvgNames } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@utils/const/globals'
import { FilterTypes } from '@utils/filter'

import DisplayConditionsField from './components/DisplayConditionsField/DisplayConditionsField'
import { emailGroupingOperators, emailGroupingOptions } from '../../DisplayConditions.operators'
import { renderValidation, buttonProps, typographyProps } from '../../DisplayConditions.render'
import { DisplayConditionsForm, MultiFieldError, TogglePersonalizationsModal } from '../../DisplayConditions.types'

import './DisplayConditionsGroup.css'

export interface DisplayConditionsGroupProps {
  groupId: string
  groupController: UseFieldArrayReturn<DisplayConditionsForm, 'groups', 'id'>
  className?: string
  dataTest?: string
  togglePersonalizations: TogglePersonalizationsModal
  hasError?: boolean
}

const rootClass = 'display-conditions-group'

const DisplayConditionsGroup: FC<DisplayConditionsGroupProps> = (props: DisplayConditionsGroupProps) => {
  const { togglePersonalizations, groupController, groupId, dataTest = rootClass, className = '', hasError = false } = props
  const selectMenuRef = useRef<HTMLElement>(null)

  const { t } = useTranslation()
  const isInitialized = useRef(false)
  const { control, formState, trigger } = useFormContext<DisplayConditionsForm>()

  const { fields, insert, remove, move, update } = groupController
  const groupIndex = fields.findIndex((group) => group.id === groupId)
  const groups = useWatch({ control, name: `groups` })
  const group = useWatch({ control, name: `groups.${groupIndex}` })

  const fieldController = useFieldArray({ control, name: `groups.${groupIndex}.fields` })
  useWatch({ control, name: `groups.${groupIndex}.fields` })

  const groupingOptions = emailGroupingOptions.map((option) => ({ ...option, label: t(option.label) }))

  const renderGroupingOperator = () => {
    return (
      <div className={`${rootClass}__content__grouping-operator`}>
        <Typography text={t('EmailComposer.DynamicContent.Grouping.Prefix')} {...typographyProps} />
        <SelectV2
          options={groupingOptions}
          defaultValue={group.groupingOperator}
          isClearable={false}
          menuPortalTarget={selectMenuRef?.current}
          className={`${rootClass}__content__grouping-operator-select`}
          onChange={(option) => {
            update(groupIndex, {
              ...group,
              fields: fieldController.fields,
              groupingOperator: (option?.value as keyof typeof emailGroupingOperators) ?? emailGroupingOperators.all,
            })
          }}
          insideModal
        />
        <Typography text={t('EmailComposer.DynamicContent.Grouping.Suffix')} {...typographyProps} />
      </div>
    )
  }

  const renderDivider = () => {
    return (
      <div className={`${rootClass}__divider`}>
        <div className={`${rootClass}__divider__line`} />
        <Typography text={t('OR')} type={TextType.SECTION_HEADER_LIGHT} weight={TextWeight.MEDIUM} />
        <div className={`${rootClass}__divider__line`} />
      </div>
    )
  }

  const getPersonalizationProps = useCallback(
    (fieldIndex: number) => {
      const personalizationTagInput: PersonalizationContainerTagInputProps = {
        onInsertTag: (data: Omit<PersonalizationTag, 'value'>) => {
          fieldController.update(fieldIndex, {
            ...fieldController.fields[fieldIndex],
            personalization: {
              id: data.fieldId,
              title: data.displayText,
              group: data.group ?? FilterTypes.CUSTOM_ACCOUNT_FIELDS,
              mapping: data.mappingName,
            },
          })
          togglePersonalizations(undefined)
          trigger(`groups.${groupIndex}.fields.${fieldIndex}.personalization`)
          trigger(`groups.${groupIndex}.groupError`)
        },
        activeSvg: getPersonalizationSvg(fieldController.fields[fieldIndex]?.personalization),
        preSelectedItem: fieldController.fields[fieldIndex]?.personalization,
        isFallbackModalActive: false,
      }
      return personalizationTagInput
    },
    [fieldController]
  )

  const handleNewField = () => {
    togglePersonalizations(getPersonalizationProps(fieldController.fields.length))
  }

  useEffect(() => {
    if (!isInitialized.current && fieldController.fields.length === 0) {
      fieldController.append({ values: [], operator: 'EQUALS.TEXT', dateFormat: '' })
      handleNewField()
    }
    isInitialized.current = true
  }, [])

  if (!group) {
    return null
  }

  const allGroupErrors = formState.errors.groups?.[groupIndex]

  const hasErrors = !!allGroupErrors?.fields || !!allGroupErrors?.groupError || hasError

  return (
    <div className={classNames(rootClass, className, { [`${rootClass}--has-errors`]: hasErrors })}>
      {groupIndex !== 0 && renderDivider()}
      <Container dataTest={dataTest} noPadding>
        <div className={`${rootClass}__header`}>
          <Typography
            type={TextType.BANNER_HEADER_DARK}
            text={groups.length === 1 ? 'Display conditions' : `${groupIndex + 1}. Display conditions`}
          />
          <div className={`${rootClass}__header__actions`}>
            <Tooltip
              trigger={
                <ButtonIcon
                  icon={SvgNames.arrowUpPlain}
                  disabled={groupIndex === 0}
                  onClick={() => move(groupIndex, groupIndex - 1)}
                  dataTest={`${dataTest}-move-up`}
                  {...buttonProps}
                />
              }
            >
              {groupIndex === 0 ? t(`EmailComposer.DynamicContent.Group.MoveUp.Disabled`) : t('EmailComposer.DynamicContent.Group.MoveUp')}
            </Tooltip>
            <Tooltip
              trigger={
                <ButtonIcon
                  icon={SvgNames.arrowDownPlain}
                  disabled={groupIndex === fields.length - 1}
                  onClick={() => move(groupIndex, groupIndex + 1)}
                  dataTest={`${dataTest}-move-down`}
                  {...buttonProps}
                />
              }
            >
              {groupIndex === fields.length - 1
                ? t(`EmailComposer.DynamicContent.Group.MoveDown.Disabled`)
                : t('EmailComposer.DynamicContent.Group.MoveDown')}
            </Tooltip>
            <span className={`${rootClass}__header__divider`} />
            <Tooltip
              trigger={
                <ButtonIcon
                  icon={SvgNames.cloneSegment}
                  onClick={() => {
                    trigger('duplicateGroups')

                    return insert(groupIndex, { ...group })
                  }}
                  dataTest={`${dataTest}-duplicate`}
                  {...buttonProps}
                />
              }
            >
              {t('EmailComposer.DynamicContent.Group.Duplicate')}
            </Tooltip>
            <Tooltip
              trigger={
                <ButtonIcon
                  {...buttonProps}
                  icon={SvgNames.delete}
                  onClick={() => {
                    // Must remove fields first or group will stay in form state
                    fieldController.remove()
                    trigger('duplicateGroups')
                    remove(groupIndex)
                  }}
                  dataTest={`${dataTest}-delete`}
                  type={ButtonIconType.DELETE}
                />
              }
            >
              {t('EmailComposer.DynamicContent.Group.Delete')}
            </Tooltip>
          </div>
        </div>
        <div className={`${rootClass}__content`}>
          {renderGroupingOperator()}
          {fieldController.fields.map((field, index) => (
            <DisplayConditionsField
              groupId={groupId}
              fieldId={field.id}
              key={field.id}
              groupController={groupController}
              fieldController={fieldController}
              getPersonalizationProps={getPersonalizationProps}
              togglePersonalizations={togglePersonalizations}
              dataTest={`${dataTest}-condition-${index}`}
              hasGroupError={hasError}
            />
          ))}
          {renderValidation(t((allGroupErrors?.groupError as MultiFieldError)?.message?.messageText))}
          <div className={classNames(`${rootClass}__divider__line`, `${rootClass}__divider__line__conditions`)} />
          <AddButton
            label={t('EmailComposer.DynamicContent.AddCondition')}
            onClick={() => {
              handleNewField()
            }}
            dataTest={`${dataTest}-add`}
          />
        </div>
      </Container>
    </div>
  )
}

export default DisplayConditionsGroup
