import React, { MouseEvent } from 'react'
import { Row } from 'react-table'

import { TableActions } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.constants'
import { getActionProps } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.utils'
import {
  ListingPageItem,
  ListPageCommonState,
  ListPageTableActionCustomProps,
  TableProps,
  Update,
} from '@complex/ListingPage/Context/ListingPageCommon.context'
import { FormType } from '@complex/ListingPage/Utils/ListingPage.constants'
import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { renderRelativeDate } from '@components/Table/components/tableColumns'
import { HeaderAction, RowAction } from '@components/Table/Table'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType } from '@components/Typography/Typography'
import { rootContext } from '@const/globals'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import FormInfoHover from '@src/pages/listingPages/Forms/components/FormInfoHover/FormInfoHover'
import { CellContext } from '@tanstack/react-table'
import { ItemType } from '@utils/categorization'
import { getStandardFormattedDate, relativeDate } from '@utils/date'
import { Form, openFormBuilderWindow } from '@utils/forms'
import { getFormattedNumber } from '@utils/numbers'
import { renderPathToFolder } from '@utils/searchUtils'
import { titleCase } from '@utils/strings'

export enum FormsCustomTableActions {
  SHOW_DASHBOARD = 'SHOW_DASHBOARD',
  SHOW_DETAILS = 'SHOW_DETAILS',
  EDIT = 'EDIT',
  CREATE_TEMPLATE = 'CREATE_TEMPLATE',
  MIGRATE_FORM = 'MIGRATE_FORM',
  MIGRATE_FORMS = 'MIGRATE_FORMS',
  CREATE_FORM = 'CREATE_FORM',
  CREATE_FROM_TEMPLATE = 'CREATE_FROM_TEMPLATE',
  CREATE_FROM_MIGRATED = 'CREATE_FROM_MIGRATED',
}

const rowActionCustomProps: ListPageTableActionCustomProps[] = [
  { name: 'SHOW_DASHBOARD', position: 0, hasTopSection: false, isInDropdown: false },
  { name: 'EDIT', position: 1, hasTopSection: false, isInDropdown: false },
  { name: 'PREVIEW', position: 2, hasTopSection: false, isInDropdown: false },
  { name: 'ADD_TO_FAVORITES', position: 3, hasTopSection: false, isInDropdown: false },
  { name: 'MIGRATE_FORM', position: 4, hasTopSection: false, isInDropdown: true },
  { name: 'CREATE_TEMPLATE', position: 5, hasTopSection: false, isInDropdown: true },
  { name: 'DUPLICATE', position: 6, hasTopSection: true, isInDropdown: true },
  { name: 'SHARE_TO_CATALOG', position: 7, hasTopSection: false, isInDropdown: true },
  { name: 'SHARE_TO_ACCOUNTS', position: 8, hasTopSection: false, isInDropdown: true },
  { name: 'MOVE_TO_FOLDER', position: 9, hasTopSection: true, isInDropdown: true },
  { name: 'REMOVE_FROM_FOLDER', position: 10, hasTopSection: false, isInDropdown: true },
  { name: 'DELETE', position: 11, hasTopSection: true, isInDropdown: true },
]

const headerActionCustomProps: ListPageTableActionCustomProps[] = [
  { name: 'ADD_TO_FAVORITES', position: 0, hasTopSection: false, isInDropdown: false },
  { name: 'MANAGE_TAGS', position: 1, hasTopSection: false, isInDropdown: false },
  { name: 'MOVE_TO_FOLDER', position: 2, hasTopSection: false, isInDropdown: false },
  { name: 'REMOVE_FROM_FOLDER', position: 3, hasTopSection: false, isInDropdown: false },
  { name: 'MIGRATE_FORMS', position: 4, hasTopSection: false, isInDropdown: false },
  { name: 'DELETE', position: 5, hasTopSection: false, isInDropdown: true },
]

export const goToFormReport = ({ formId, backURL, target }: { formId: string; backURL?: string; target?: string }) => {
  const backTo = backURL ? `?backTo=${backURL}` : ''
  window.open(`${rootContext}/content/forms/${formId}/performance${backTo}`, target ?? '_top')
}

const onCustomTableAction = (
  customTableAction: FormsCustomTableActions,
  update: Update,
  listPageValues: ListPageCommonState,
  selectedItem?: ItemDto,
  hasReactWrapper?: boolean
) => {
  const showCustomModal = (action: FormsCustomTableActions, refetchCountsOnAction = true) =>
    update({
      customTableAction: action,
      showCustomModal: true,
      refetchCountsOnAction,
    })

  const { selectedRows } = listPageValues
  const selectedRow = selectedItem ?? selectedRows[0]
  const row = selectedRow as Form
  const selectedRowId = row?.externalId ?? '-1'
  const isClassic = row.formType === FormType.CLASSIC

  const customAction: { [key in FormsCustomTableActions]: () => void } = {
    [FormsCustomTableActions.EDIT]: () => {
      openFormBuilderWindow(
        isClassic ? `editClassicForm-${selectedRowId}` : `editNewForm-${selectedRowId}`,
        `${selectedRowId}`,
        '',
        hasReactWrapper,
        row.formType === FormType.CLASSIC
      )
    },
    [FormsCustomTableActions.CREATE_TEMPLATE]: () => showCustomModal(FormsCustomTableActions.CREATE_TEMPLATE, false),
    [FormsCustomTableActions.MIGRATE_FORMS]: () => showCustomModal(FormsCustomTableActions.MIGRATE_FORMS),
    [FormsCustomTableActions.MIGRATE_FORM]: () => showCustomModal(FormsCustomTableActions.MIGRATE_FORMS),
    [FormsCustomTableActions.CREATE_FORM]: () => showCustomModal(FormsCustomTableActions.CREATE_FORM, false),
    [FormsCustomTableActions.CREATE_FROM_MIGRATED]: () => showCustomModal(FormsCustomTableActions.CREATE_FROM_MIGRATED, false),
    [FormsCustomTableActions.CREATE_FROM_TEMPLATE]: () => showCustomModal(FormsCustomTableActions.CREATE_FROM_TEMPLATE, false),
    [FormsCustomTableActions.SHOW_DETAILS]: () => {
      window.open(`${rootContext}/content/forms/${selectedRowId}/details`, '_top')
    },
    [FormsCustomTableActions.SHOW_DASHBOARD]: () => goToFormReport({ formId: selectedRowId }),
  }
  customAction[customTableAction]()
}

const getCustomRowActions = (tableActions: TableActions, t: Function, canCreate: boolean): RowAction[] => [
  {
    label: t('View report'),
    icon: SvgNames.graphBar,
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(FormsCustomTableActions.SHOW_DASHBOARD, row),
    ...getActionProps(FormsCustomTableActions.SHOW_DASHBOARD, rowActionCustomProps),
  },
  {
    label: t('Edit'),
    icon: SvgNames.pencil,
    hidden: !canCreate,
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(FormsCustomTableActions.EDIT, row),
    ...getActionProps(FormsCustomTableActions.EDIT, rowActionCustomProps),
  },
  {
    label: t('Migrate form'),
    icon: SvgNames.migrate,
    disabled: !canCreate,
    hasTooltip: !canCreate,
    tooltipMessage: 'Ask your administrator for permission to do this',
    hidden: (row: Row<ItemDto>) => {
      const form = row.original as Form
      return form.formType === FormType.NEW
    },
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(FormsCustomTableActions.MIGRATE_FORM, row),
    ...getActionProps(FormsCustomTableActions.MIGRATE_FORM, rowActionCustomProps),
  },
  {
    label: t('Create template'),
    icon: SvgNames.appendedList,
    disabled: !canCreate,
    hasTooltip: !canCreate,
    tooltipMessage: 'Ask your administrator for permission to do this',
    hidden: (row: Row<ItemDto>) => {
      const form = row.original as Form
      return form.formType === FormType.CLASSIC
    },
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(FormsCustomTableActions.CREATE_TEMPLATE, row),
    ...getActionProps(FormsCustomTableActions.CREATE_TEMPLATE, rowActionCustomProps),
  },
]

const getCustomHeaderActions = (
  tableActions: TableActions,
  selectedRows: ListingPageItem[],
  t: Function,
  canCreate: boolean,
  standardActionsCount: number
): HeaderAction[] => {
  return [
    {
      label: t('Migrate forms'),
      icon: SvgNames.migrate,
      disabled: !canCreate,
      hasTooltip: true,
      tooltipMessage: 'Ask your administrator for permission to do this',
      hidden: () => {
        const rows = selectedRows as Form[]
        return rows.some((form) => form.formType === FormType.NEW)
      },
      onClick: (_event: MouseEvent<HTMLButtonElement> | Event) => tableActions.customTableHeaderAction(FormsCustomTableActions.MIGRATE_FORMS),
      ...getActionProps(FormsCustomTableActions.MIGRATE_FORMS, headerActionCustomProps, standardActionsCount),
    },
  ]
}

const clickableColumnOptions = {
  colIndex: 0,
  action: { customTableAction: FormsCustomTableActions.SHOW_DETAILS },
}

const onRowClicked = (selectedItem: Row<Form>) => {
  window.location.href = `${rootContext}/content/forms/${selectedItem.original.externalId}/details`
}

export const tableProps = (t: Function, useOnlyNewForms: boolean, canCreate: boolean, hasReactWrapper: boolean): TableProps => ({
  columns: tableV2Columns(useOnlyNewForms, t),
  hasAutoSelectedRows: false,
  hasExpander: false,
  rowActionCustomProps,
  renderSearchColumns: (searchInAllItems: boolean | undefined, currentFolder: FolderData, search: string, folders: FolderData[]) =>
    renderSearchColumnsUtilV2(searchInAllItems, currentFolder, search, folders, t, useOnlyNewForms) as any,
  headerActionCustomProps,
  listPage: 'Forms',
  getCustomHeaderActions: (tableActions, selectedRows, standardActionsCount) =>
    getCustomHeaderActions(tableActions, selectedRows, t, canCreate, standardActionsCount),
  getCustomRowActions: (tableActions) => getCustomRowActions(tableActions, t, canCreate),
  onCustomTableAction: (customAction, update, listPageValues, selectedItem) =>
    onCustomTableAction(customAction, update, listPageValues, selectedItem, hasReactWrapper),
  clickableColumnOptions,
  actonAssetType: 'FORM',
  shareModalText: '',
  onRowClicked,
})

const renderItemNameVal = (original: Form, search: string, rootClass: string, useOnlyNewForms: boolean, t: Function) => {
  const item = original
  const submitsTo = item.list ? item.list : item.segment

  return (
    <>
      <div className={`${rootClass}__form-name`}>
        <div className={`${rootClass}__form-name-wrapper`}>
          <Tooltip
            ellipOnTrigger
            trigger={
              <div className={`${rootClass}__form-name-typography`}>
                <div className={'ellip'}>{search ? renderBoldTextOnMatch(item.name, search) : item.name}</div>
                {!useOnlyNewForms && (
                  <Typography
                    text={`${titleCase(item.formType)} ${t('form')}`}
                    type={TextType.BODY_TEXT_SMALL_LIGHT}
                    className={`list-page-table-container__secondary`}
                  />
                )}
              </div>
            }
          >
            {item.name}
          </Tooltip>
          <FormInfoHover
            name={item.name}
            description={item.description}
            formType={useOnlyNewForms ? undefined : item.formType}
            submitsTo={submitsTo}
            createdOn={item.createdAt}
            createdBy={item.createdBy}
            lastUpdated={item.lastUpdated}
            updatedBy={item.lastUpdatedBy}
          />
        </div>
      </div>
    </>
  )
}

const renderSearchColumnsUtilV2 = (
  searchInAllItems: boolean | undefined,
  currentFolder: FolderData,
  search: string,
  folders: FolderData[],
  t: Function,
  useOnlyNewForms: boolean
): ColumnDefWithAdditionalProps<Form>[] => [
  {
    header: 'Form Title',
    accessorKey: 'name',
    textAlign: 'left',
    cell: ({ row: { original } }) => renderItemNameVal(original, search, 'forms-listing-page-container', useOnlyNewForms, t),
  },
  {
    header: 'Tags',
    accessorKey: 'tags',
    textAlign: 'left',
    maxSize: 120,
    disableSorting: true,
    enableCustomCellValue: true,
  },
  {
    header: 'Location',
    accessorKey: 'folderId',
    textAlign: 'left',
    maxSize: 120,
    cell: (row) => renderPathToFolder(ItemType.FORM, row.getValue<number>(), folders, searchInAllItems, 'listing-page-container', t, currentFolder),
  },
  {
    header: 'Last Updated',
    accessorKey: 'lastUpdated',
    textAlign: 'left',
    maxSize: 150,
    cell: (cell) => renderRelativeDate(relativeDate(cell.getValue<number>(), true, false), getStandardFormattedDate(cell.getValue<number>())),
  },
]

const tableV2Columns = (useOnlyNewForms: boolean, t: Function): ColumnDefWithAdditionalProps<Form, any>[] => {
  return [
    {
      header: 'Form Title',
      accessorKey: 'name',
      textAlign: 'left',
      cell: ({ row: { original } }) => renderItemNameVal(original, '', 'forms-listing-page-container', useOnlyNewForms, t),
    },
    {
      header: 'Tags',
      accessorKey: 'tags',
      textAlign: 'left',
      maxSize: 180,
      disableSorting: true,
      enableCustomCellValue: true,
    },
    {
      header: () => (
        <div className={'forms-listing-page-container__submits'}>
          <Typography type={TextType.TABLE_HEADER} text={t('Submits')} />
          <Tooltip triggerClassName={`forms-listing-page-container__submits__info`} trigger={<Svg name={SvgNames.info} type={SvgType.ICON} />}>
            {t('Submission data from the last 27 months')}
          </Tooltip>
        </div>
      ),
      accessorKey: 'submits',
      textAlign: 'right',
      fieldType: 'integer',
      maxSize: 150,
      cell: (cell: CellContext<Form, number | undefined>) => {
        const submits = cell.getValue()
        return submits !== undefined ? getFormattedNumber(submits) : 0
      },
      enableCustomCellValue: true,
    },
    {
      header: 'Last Updated',
      accessorKey: 'lastUpdated',
      textAlign: 'left',
      fieldType: 'TIMESTAMP',
      maxSize: 150,
      cell: (cell: CellContext<Form, number>) =>
        renderRelativeDate(relativeDate(cell.getValue(), true, false), getStandardFormattedDate(cell.getValue())),
    },
  ]
}
