import { useContext } from 'react'

import { ProgramSegmentDetails, ProgramSource } from '@graphql/types/query-types'
import { List } from '@interface/foldersLists/List'
import { SEGMENT_OPTIONS, SEGMENT_TYPES } from '@src/pages/programs/edit/components/ProgramFlow/components/ConditionalSelect/segmentOptions'
import {
  BaseList,
  UpgradedProgramStep,
  UpgradedStepSources,
  UpgradeManagerContext,
} from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'
import { titleCase } from '@utils/strings'

export enum ConditionType {
  IF = 'IF',
  ELSE_IF = 'ELSE_IF',
  OTHERWISE = 'OTHERWISE',
}

export const getConditionText = (messageRelated: ProgramSegmentDetails | undefined, not?: boolean) => {
  if (messageRelated) {
    return titleCase(SEGMENT_OPTIONS.find(({ type }) => type === messageRelated.name)?.text ?? '')
  }
  return titleCase(SEGMENT_OPTIONS.find(({ type }) => type === (not ? SEGMENT_TYPES.NOT_IN : SEGMENT_TYPES.IN))?.text ?? '')
}

export const getUpgradedStepSources = (
  upgradedStepSources: UpgradedStepSources,
  upgradedCurrentStep: UpgradedProgramStep,
  upgradeId: string,
  newSource: List
) => ({
  ...upgradedStepSources,
  [upgradedCurrentStep?.stepId ?? '']: {
    ...upgradedStepSources[upgradedCurrentStep.stepId ?? ''],
    [upgradeId]: { id: newSource.id ?? '', name: newSource.name ?? '', size: newSource.size ?? 0 },
  },
})

export const getUpgradedSource = (
  source: ProgramSource | undefined,
  recommended: BaseList | undefined,
  upgradedCurrentStep: UpgradedProgramStep | undefined,
  upgradedStepSources: UpgradedStepSources,
  upgradeId?: string
) => {
  return {
    source: {
      id: source?.id ?? '',
      sourceName: source?.name ?? '',
      count: source?.count ?? 0,
    },
    recommended,
    removed: false,
    newSource:
      !!upgradedCurrentStep && upgradedCurrentStep.upgraded && Object.keys(upgradedStepSources).length !== 0
        ? upgradedStepSources[upgradedCurrentStep?.stepId ?? '']?.[upgradeId || upgradedCurrentStep.upgradeId]
        : undefined,
    upgradeId: (upgradeId || upgradedCurrentStep?.upgradeId) ?? '',
  }
}

export const useUpgradedSource = (source: ProgramSource | undefined, recommended: BaseList | undefined, upgradeId?: string) => {
  const {
    values: { upgradedCurrentStep, upgradedStepSources },
  } = useContext(UpgradeManagerContext)

  return getUpgradedSource(source, recommended, upgradedCurrentStep, upgradedStepSources, upgradeId)
}
