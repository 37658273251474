import React, { FC, ReactNode, useContext, useEffect, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { TableActions } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.constants'
import {
  DuplicateListingPageItem,
  ListPageAPI,
  ListPageCommonState,
  PageHeaderProps,
  SidebarProps,
  TableProps,
  Update,
} from '@complex/ListingPage/Context/ListingPageCommon.context'
import ListingPageContainer from '@complex/ListingPage/ListingPageContainer'
import { MIRRORED_LISTS_ID, UNIFIED_CONTACTS_ID } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import Button, { ButtonType } from '@components/Button/Button'
import CollapsibleBannerCollapsedContent from '@components/CollapsibleBanner/components/CollapsibleBannerCollapsedContent/CollapsibleBannerCollapsedContent'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { Status } from '@components/StatusToast/StatusToast'
import { SvgNames } from '@components/Svg'
import Svg, { SvgType } from '@components/Svg/Svg'
import Typography, { TextType } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { TemplateIdDescription } from '@graphql/types/mutation-types'
import { Program, WebhookSubscription } from '@graphql/types/query-types'
import {
  automatedProgramsListingPageTableV2Columns,
  renderSearchColumnsUtilV2,
} from '@src/pages/listingPages/AutomatedPrograms/AutomatedProgramListingPageContainer.tables'
import {
  AutomatedProgramsCustomModals,
  AutomatedProgramsCustomTableActions,
  AutomatedProgramsListingPageContainerProps,
  AutomatedProgramsSession,
  headerActionCustomProps,
  rowActionCustomProps,
} from '@src/pages/listingPages/AutomatedPrograms/AutomatedProgramsListingPage.constants'
import {
  getCustomEmptyListingProps,
  getCustomFilterParams,
  getCustomRowActionsUtils,
  onClearProgramHistory,
  onCreateTemplate,
  pauseProgram,
  renderCustomFilters,
} from '@src/pages/listingPages/AutomatedPrograms/AutomatedProgramsListingPage.helpers'
import CreateProgramTemplateModal from '@src/pages/listingPages/AutomatedPrograms/components/CreateProgramTemplateModal/CreateProgramTemplateModal'
import ManageContactsOrStartModalContainer from '@src/pages/listingPages/AutomatedPrograms/components/ManageContactsOrStartModalContainer/ManageContactsOrStartModalContainer'
import { getAutomatedProgramsListingPageRequests } from '@src/pages/listingPages/AutomatedPrograms/GraphQL/AutomatedProgramsListingPageRequests.graphQL'
import { UpgradeProgramsBannerContext } from '@src/pages/listingPages/TabbedContainers/ProgramsAndTemplatesTabbedContainer/context/UpgradeProgramsBanner.context'
import ClearHistoryModal from '@src/pages/programs/dashboard/components/ProgramPerformance/components/ClearHistoryModal/ClearHistoryModal'
import { removeProgramWebhooksChannels } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditOutgoingWebhookStep/utils/EditOutgoingWebhookStep.utils'
import { useOutgoingWebhookStep } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditOutgoingWebhookStep/utils/useOutgoingWebhookStep'
import { PROGRAMS_URL } from '@src/pages/programs/manager/ProgramManager.constants'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import { allProgramsFilter, draftProgramsFilter, FilterTypes, pausedProgramsFilter, runningProgramsFilter } from '@utils/filter'
import { logNewRelicError } from '@utils/new-relic.utils'
import { ProgramAssetType } from '@utils/program/program.constants'
import { parseErrorSetToRunMessage } from '@utils/program/program.utils'
import { setItem } from '@utils/sessionStorage'

const rootClass = 'automated-programs-listing-page-container'

const AutomatedProgramsListingPageContainer: FC<AutomatedProgramsListingPageContainerProps> = (props: AutomatedProgramsListingPageContainerProps) => {
  const { dataTest = rootClass, hasUpgradablePrograms = false } = props
  const {
    exclusivelyActOnContacts,
    hasAutomatedProgramsUpgrade,
    hasShowActOnContactsTab,
    hasOutgoingWebhookStep,
    reenterProgramContacts,
    userAllowedToCreatePrograms,
    userAllowedToDeletePrograms,
  } = useAccountSettings()
  const apolloClient = useApolloClient()
  const { deleteItemsRequest, duplicateProgramRequest } = getAutomatedProgramsListingPageRequests(apolloClient)
  const {
    values: { showAutomatedProgramsUpgradeBanner, upgradeCompleted },
    update,
  } = useContext(UpgradeProgramsBannerContext)

  const [webhooksEndpoints, setWebhooksEndpoints] = useState<WebhookSubscription[]>()

  const showUpgradeHelper = hasAutomatedProgramsUpgrade && !upgradeCompleted && hasUpgradablePrograms && !exclusivelyActOnContacts

  const { loadEndpoints, saveEndpoints } = useOutgoingWebhookStep()

  const { t } = useTranslation()

  const getActiveFilter = (filter: string) => {
    switch (filter) {
      case FilterTypes.RUNNING:
        return runningProgramsFilter
      case FilterTypes.PAUSED:
        return pausedProgramsFilter
      default:
        return draftProgramsFilter
    }
  }

  const getWebhooksEndpoints = async () => {
    const endpoints = await loadEndpoints()
    setWebhooksEndpoints(endpoints)
  }

  const onDeleteProgram = async (items: ItemDto[]) => {
    const deletePromise = await deleteItemsRequest(items)
    if (webhooksEndpoints) {
      const updatedWebhooksEndpoints = items.reduce((updatedWebhooksEndpoints: WebhookSubscription[], program) => {
        return program.externalId ? removeProgramWebhooksChannels(program.externalId, updatedWebhooksEndpoints) : updatedWebhooksEndpoints
      }, webhooksEndpoints)
      await saveEndpoints(updatedWebhooksEndpoints)
      getWebhooksEndpoints()
    }
    return deletePromise
  }

  const renderCustomModal = (
    customTableAction: AutomatedProgramsCustomModals,
    listPageValues: ListPageCommonState,
    listPageAPI: ListPageAPI,
    errorMessage?: string
  ): ReactNode => {
    const { update, setStatusToast, setError } = listPageAPI

    const closeModal = (fetchItems?: boolean) => {
      update({ showCustomModal: false, fetchItems, fetchFilterCounts: true, errorMessage: undefined, loading: true, items: [] })
    }

    const { selectedRows } = listPageValues
    if (selectedRows.length === 0) {
      return
    }

    const selectedRow = selectedRows[0]
    if (!selectedRow || !update) {
      return undefined
    }
    const selectedRowId = selectedRow.externalId ?? '-1'
    const row = selectedRow as Program

    const customModal: { [key in AutomatedProgramsCustomModals]: () => ReactNode } = {
      [AutomatedProgramsCustomModals.CREATE_TEMPLATE]: () => {
        const lists = row.sources.map((source) => ({
          id: source.id,
          title: source.name,
          type:
            source.baseId === UNIFIED_CONTACTS_ID || source.baseId.startsWith(MIRRORED_LISTS_ID) || source.id.startsWith(MIRRORED_LISTS_ID)
              ? ProgramAssetType.SEGMENT
              : ProgramAssetType.LIST,
        }))
        const messages = row.messages.map((source) => ({
          id: source.id,
          title: source.name,
          type: ProgramAssetType.EMAIL,
        }))
        const assets = [...lists, ...messages]
        return (
          <CreateProgramTemplateModal
            isOpen
            title={row.name ?? ''}
            assets={assets}
            onClose={() => update({ showCustomModal: false })}
            onAction={async (title: string, descriptionMapping: TemplateIdDescription[]) => {
              const { data, errors } = await onCreateTemplate(selectedRowId, title, descriptionMapping, apolloClient)
              if (data?.createProgramTemplate) {
                setStatusToast('Your template was created', Status.SUCCESS)
              } else {
                setError('Your template could not be created', errors)
              }
              closeModal()
            }}
          />
        )
      },
      [AutomatedProgramsCustomModals.START]: () => (
        <ManageContactsOrStartModalContainer programId={selectedRowId} isFirstRun={!!row.startedOn} isOpen title={'Start Program'} update={update} />
      ),
      [AutomatedProgramsCustomModals.START_ERROR]: () => {
        setError(parseErrorSetToRunMessage(t, errorMessage, rootClass), errorMessage)
        closeModal()
        return false
      },
      [AutomatedProgramsCustomModals.START_ADD]: () => {
        setStatusToast('Pending contacts are being added to the program. This may take a few minutes.', Status.SUCCESS)
        closeModal(true)
        return false
      },
      [AutomatedProgramsCustomModals.START_EXIT]: () => {
        setStatusToast('Pending contacts are being exited from the program. This may take a few minutes.', Status.SUCCESS)
        closeModal(true)
        return false
      },
      [AutomatedProgramsCustomModals.PAUSE]: () => (
        <ConfirmationModal
          isOpen
          className={`${rootClass}__confirmation`}
          title={t('Pause your program?')}
          body={t('Your program must be paused before you can make any changes to it.')}
          isYesNo
          yesButtonText={t('Pause program')}
          onAnswer={async (answer: YesNo) => {
            if (answer === YesNo.YES) {
              const { data, errors } = await pauseProgram(selectedRowId, apolloClient)
              if (data) {
                setStatusToast('Your program was paused', Status.SUCCESS)
                closeModal(true)
              } else if (errors) {
                setError('Your program could not be paused', errors)
                closeModal()
              }
            } else {
              update({ showCustomModal: false })
            }
          }}
        />
      ),
      [AutomatedProgramsCustomModals.CLEAR_PROGRAM_HISTORY]: () => (
        <ClearHistoryModal
          allowReentrantAddresses={row.allowReentrantAddresses}
          isOpen
          showReentrantCheckbox={reenterProgramContacts}
          onClearHistory={async () => {
            const { data, errors } = await onClearProgramHistory(selectedRowId, apolloClient, row.allowReentrantAddresses)
            if (data && data.deleteProgramHistory) {
              setStatusToast(`Your program's history was cleared`, Status.SUCCESS)
            } else if (errors || (data && !data.deleteProgramHistory)) {
              setError(`Your program's history could not be cleared`, errors)
            }
          }}
          onClose={() => update({ showCustomModal: false })}
        />
      ),
    }

    return customModal[customTableAction]()
  }

  const onCustomTableAction = (
    customTableAction: AutomatedProgramsCustomTableActions,
    update: Update,
    listPageValues: ListPageCommonState,
    selectedItem?: ItemDto
  ) => {
    const { selectedRows } = listPageValues
    const selectedRow = selectedItem ?? selectedRows[0]
    if (!selectedRow) {
      alert('no selected row')
      return
    }
    const selectedRowId = selectedRow.externalId ?? '-1'

    const customAction: { [key in AutomatedProgramsCustomTableActions]: () => void } = {
      [AutomatedProgramsCustomTableActions.START]: () => update({ showCustomModal: true, customTableAction: AutomatedProgramsCustomModals.START }),
      [AutomatedProgramsCustomTableActions.PAUSE]: () => update({ showCustomModal: true, customTableAction: AutomatedProgramsCustomModals.PAUSE }),
      [AutomatedProgramsCustomTableActions.CREATE_TEMPLATE]: () =>
        update({ showCustomModal: true, customTableAction: AutomatedProgramsCustomModals.CREATE_TEMPLATE }),
      [AutomatedProgramsCustomTableActions.EDIT]: () => {
        window.location.assign(`${PROGRAMS_URL}/${selectedRowId}`)
      },
      [AutomatedProgramsCustomTableActions.SHOW_DASHBOARD]: () => {
        window.location.assign(`${PROGRAMS_URL}/${selectedRowId}/program-performance`)
      },
      [AutomatedProgramsCustomTableActions.CLASSIC_EDITOR]: () => {
        window.location.assign(`${rootContext}/classic/if/programs4/edit.jsp?id=${selectedRowId}`)
      },
    }
    customAction[customTableAction]()
  }

  const renderSearchColumnsV2 = (searchInAllItems: boolean | undefined, currentFolder: FolderData, search: string, folders: FolderData[]) =>
    renderSearchColumnsUtilV2(searchInAllItems, currentFolder, search, folders, t)

  const getCustomRowActions = (tableActions: TableActions) =>
    getCustomRowActionsUtils(tableActions, t, exclusivelyActOnContacts, hasShowActOnContactsTab, userAllowedToCreatePrograms)

  const sidebarProps: SidebarProps = {
    sidebarHeader: 'Program Manager',
    hasRecent: false,
    hasCreatedByMe: true,
    allItemFilter: allProgramsFilter,
    renderCustomFilters,
    customFilterSelected: false,
  }

  const toggleCollapseAutomatedProgramsUpgradeBanner = () => {
    setItem(AutomatedProgramsSession.UPGRADE_PROGRAMS_BANNER, (!showAutomatedProgramsUpgradeBanner).toString())
    update({ showAutomatedProgramsUpgradeBanner: !showAutomatedProgramsUpgradeBanner })
  }

  const pageHeaderProps: PageHeaderProps = {
    pageTitle: 'Automated Programs',
    renderPageHeaderContent: () => (
      <>
        {showUpgradeHelper && (
          <CollapsibleBannerCollapsedContent
            onClick={toggleCollapseAutomatedProgramsUpgradeBanner}
            customTooltipText={t('ListPage.AutomatedPrograms.UpgradeBanner.Helper.Tooltip')}
            collapsed={false}
            icon={SvgNames.lightBulbNoBackground}
          />
        )}
        {userAllowedToCreatePrograms ? (
          <Button className={`${rootClass}__new-program`} buttonType={ButtonType.PRIMARY} isLink to={`${PROGRAMS_URL}/new`}>
            <Svg type={SvgType.ICON} name={SvgNames.plus} />
            <Typography text={t('New program')} type={TextType.BODY_TEXT_WHITE} />
          </Button>
        ) : (
          <Button
            className={`${rootClass}__new-program`}
            buttonType={ButtonType.PRIMARY}
            disabled
            title={t('Ask your administrator for permission to do this')}
          >
            <Svg type={SvgType.ICON} name={SvgNames.plus} />
            <Typography text={t('New program')} type={TextType.BODY_TEXT_WHITE} />
          </Button>
        )}
      </>
    ),
  }

  const clickableColumnOptions = {
    colIndex: 0,
    action: {
      customTableAction: AutomatedProgramsCustomTableActions.EDIT,
    },
  }

  const tableProps: TableProps = {
    columns: automatedProgramsListingPageTableV2Columns,
    hasAutoSelectedRows: false,
    hasExpander: false,
    rowActionCustomProps,
    renderSearchColumns: renderSearchColumnsV2,
    headerActionCustomProps,
    listPage: 'AutomatedPrograms',
    getCustomRowActions,
    clickableColumnOptions,
    onCustomTableAction,
    actonAssetType: ItemType.PROGRAM,
    shareModalText: '',
  }

  const duplicateProgram = async (params: DuplicateListingPageItem, listPageAPI: ListPageAPI) => {
    const { listingPageItem, tags, newName, folderId } = params
    const { update, setStatusToast } = listPageAPI

    const { data, errors } = await duplicateProgramRequest(listingPageItem.externalId ?? '', newName, tags, folderId)

    if (data) {
      update({ fetchItems: true })
      window.open(`${PROGRAMS_URL}/${data.duplicateProgram?.id}`)
    } else if (errors) {
      logNewRelicError(errors)
      setStatusToast('Your program could not be duplicated', Status.FAIL)
      update({ fetchFilterCounts: true, errorMessage: undefined })
    }
  }

  useEffect(() => {
    if (hasOutgoingWebhookStep) {
      getWebhooksEndpoints()
    }
  }, [])

  return (
    <ListingPageContainer
      className={rootClass}
      listingPageProps={{
        customDeleteItemsCall: onDeleteProgram,
        customDuplicateItem: duplicateProgram,
        getCustomActiveFilter: getActiveFilter,
        getCustomEmptyListingProps,
        getCustomFilterParams,
        renderCustomModal,
        sidebarProps,
        pageHeaderProps,
        tableProps,
        canEdit: userAllowedToCreatePrograms,
        canCreate: userAllowedToCreatePrograms,
        canDeleteItems: userAllowedToDeletePrograms,
        canDuplicate: true,
        canPreview: false,
        canShareToCatalog: false,
        canShareToChildAccounts: false,
        sortBy: [{ id: 'lastUpdated', desc: true }],
        hasTabs: true,
        customFilters: [
          {
            field: 'status',
            values: [
              { id: FilterTypes.DRAFT, value: 'draft' },
              { id: FilterTypes.RUNNING, value: 'running' },
              { id: FilterTypes.PAUSED, value: 'stopped' },
            ],
          },
        ],
      }}
      dataTest={dataTest}
      itemType={ItemType.PROGRAM}
    />
  )
}

export default AutomatedProgramsListingPageContainer
