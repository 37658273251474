export const MAX_SPLITS = 30
export const MIN_SPLITS = 2
export const CUTOFF_SPLITS = 8
export const MAX_SCROLL_SPLITS = 10

export const USED_SPLIT_NAME_ERROR = 'This name is in use. Please enter a unique segment name'

export const GENERIC_SPLIT_ERROR = 'Something went wrong when creating the splits'
export enum ABSplitSession {
  TOAST = 'absplit:toast',
}

export const DistributionNames: { [key: string]: string } = {
  '%': 'percentage',
  '#': 'number',
}

export const ABSplitsChartPlotOptions = {
  series: {
    states: {
      inactive: {
        enabled: false,
      },
      hover: {
        enabled: false,
      },
    },
  },
}
