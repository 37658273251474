import { gql } from '@apollo/client'

export default gql`
  mutation CreateShortUrl($assetId: String!, $assetType: String!, $longUrl: String!) {
    createShortUrlDefaultDomain(assetId: $assetId, assetType: $assetType, longUrl: $longUrl) {
      id
      shortUrl
    }
  }
`
