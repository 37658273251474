import { UNIFIED_CONTACTS_ID } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import pushPolicy from '@graphql/microservices/crm-sync/pushPolicy'
import savePushPolicy from '@graphql/microservices/crm-sync/savePushPolicy'
import unifiedListFieldMappings from '@graphql/microservices/list/unifiedListFieldMappings'
import {
  PushPolicyInput,
  PushPolicyQuery,
  PushPolicyQueryVariables,
  SavePushPolicyMutation,
  SavePushPolicyMutationVariables,
} from '@graphql/types/microservice/crm-sync-types'
import {
  GetUnifiedListFieldMappingsQuery,
  GetUnifiedListFieldMappingsQueryVariables,
  UnifiedListFieldMapping,
} from '@graphql/types/microservice/list-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

interface CrmContactsRequests {
  getUnifiedListFieldMappings: () => Promise<UnifiedListFieldMapping[]>
  getPushPolicy: () => FetchPromise<PushPolicyQuery>
  setPushPolicy: (pushPolicy: Omit<PushPolicyInput, 'listId'>) => FetchPromise<SavePushPolicyMutation>
}

export const useCrmContactsRequests = (): CrmContactsRequests => {
  const { client: crmSyncClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CRM_SYNC })
  const { client: listServiceClient } = useMicroserviceClient({ serviceName: MicroserviceClients.LIST })

  const getUnifiedListFieldMappings = () => {
    return listServiceClient
      .query<GetUnifiedListFieldMappingsQuery, GetUnifiedListFieldMappingsQueryVariables>({
        query: unifiedListFieldMappings,
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        return data.unifiedListFieldMappings as UnifiedListFieldMapping[]
      })
  }

  const getPushPolicy = () => {
    return crmSyncClient.query<PushPolicyQuery, PushPolicyQueryVariables>({
      fetchPolicy: 'network-only',
      query: pushPolicy,
      variables: {
        listId: UNIFIED_CONTACTS_ID,
      },
    })
  }

  const setPushPolicy = (pushPolicy: Omit<PushPolicyInput, 'listId'>) => {
    return crmSyncClient.mutate<SavePushPolicyMutation, SavePushPolicyMutationVariables>({
      mutation: savePushPolicy,
      variables: {
        pushPolicy: { ...pushPolicy, listId: UNIFIED_CONTACTS_ID },
      },
    })
  }

  return {
    getUnifiedListFieldMappings,
    getPushPolicy,
    setPushPolicy,
  }
}
