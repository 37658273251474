import React from 'react'

// eslint-disable-next-line no-restricted-imports
import { format, set } from 'date-fns'
// eslint-disable-next-line no-restricted-imports
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { TFunction } from 'i18next'

import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import Svg, { SvgNames } from '@components/Svg'
import { SvgColor, SvgProps } from '@components/Svg/Svg'
import { CustomAddonModalForm } from '@src/pages/EmailComposer/utils/Addons/customAddons.types'
import { checkURLValidity } from '@utils/formUtils'
import { timeZoneData } from '@utils/timezones'

interface Address {
  name?: string
  street?: string
  street2?: string
  state?: string
  city?: string
  zip?: string
}

export enum WebinarType {
  ZOOM_MEETING = 'Zoom Meeting',
  GOOGLE_MEET = 'Google Meet',
  WEBEX_MEETING = 'Webex Meeting',
  EXTERNAL_EVENT_LINK = 'External event link',
  EVENT_FORMAT = 'Event format',
}

export const webinarIcons: { [key: string]: SvgNames } = {
  [WebinarType.ZOOM_MEETING]: SvgNames.zoomLogo,
  [WebinarType.GOOGLE_MEET]: SvgNames.googleMeetLogo,
  [WebinarType.WEBEX_MEETING]: SvgNames.webexLogo,
  [WebinarType.EXTERNAL_EVENT_LINK]: SvgNames.url,
  [WebinarType.EVENT_FORMAT]: SvgNames.calendarAdd,
}

export enum EventModalTabs {
  IN_PERSON = 'IN_PERSON',
  ONLINE = 'ONLINE',
}

export const DEFAULT_START_DATE = set(new Date(), { minutes: 0, hours: 9 })

export const PLACEHOLDER_VIDEO_CONFERENCE_OPTION = {
  label: 'Event format',
  value: 'Event format',
  subText: 'Choose the format for your online event',
}

export const renderSelectOptionIcon = (isSelected?: boolean, svgProps?: Partial<SvgProps>) => {
  const { name: svgName = SvgNames.buildings, fill = SvgColor.BUTTON_GRAY, type, containerType } = svgProps ?? {}

  return (
    <div>
      <Svg name={svgName} fill={isSelected ? SvgColor.TEXT_TEAL : fill} type={type} containerType={containerType} />
    </div>
  )
}

export const parseAddress = (svgProps?: Partial<SvgProps>, address?: Address) => {
  const { name = '', street, street2, city, state, zip } = address ?? {}

  const subText =
    street || city || state ? `${street}${street2 ? ` ${street2}` : ''}${street ? ', ' : ''}${city}${state ? ', ' : ''}${state} ${zip}` : undefined
  return {
    label: name,
    value: name,
    renderItemIcon: (isSelected?: boolean) => renderSelectOptionIcon(isSelected, svgProps),
    subText,
    extraOptions: {
      street: street,
      street2: street2,
      city: city,
      state: state,
      zip: zip,
    },
  }
}

export const getEventBlockMetaData = (data: CustomAddonModalForm, blockId: string, calendarDownloadLink: string) => {
  const {
    details: { eventName: name, startDate, endDate, endTime, allDay, timeZone, description, addVideoConferencing: addVideoConferenceButton },
    locationDetails: { selectedAddress, roomDetails, registrationLink, registrationLabel, includeMapToAddress, includeRegistration },
    webinarDetails: {
      selectedWebinarType: selectedVideoConference,
      webinarPasscode: videoConferencePasscode,
      webinarLink: videoConferenceLink,
      selectedWebinar,
    },
    activeTab,
  } = data
  const addressName = selectedAddress?.value
  const formattedStartTime = startDate ? format(startDate, 'h:mm a') : ''
  let formattedEndTime = ''

  if (allDay && endDate) {
    formattedEndTime = format(endDate, 'h:mm a')
  } else if (!allDay && startDate) {
    const [hours, minutes] = endTime.split(':')
    const newHours = minutes.includes('PM') ? parseInt(hours) + 12 : parseInt(hours)

    const date = set(startDate.getTime(), { hours: newHours, minutes: parseInt(minutes) })
    formattedEndTime = format(date, 'h:mm a')
  }

  let tempEndDate

  if (allDay && endDate) {
    tempEndDate = endDate
  } else if (startDate && endTime) {
    const [hours, minutes] = endTime.split(':')
    const newHours = endTime.includes('PM') ? parseInt(hours) + 12 : parseInt(hours)

    tempEndDate = set(startDate.getTime(), { hours: newHours, minutes: parseInt(minutes.split(' ')[0]) })
  }

  let addressDetails

  if (activeTab === EventModalTabs.IN_PERSON) {
    addressDetails = {
      street: selectedAddress?.extraOptions?.street,
      street2: selectedAddress?.extraOptions?.street2,
      city: selectedAddress?.extraOptions?.city,
      state: selectedAddress?.extraOptions?.state,
      zip: selectedAddress?.extraOptions?.zip,
      address: selectedAddress?.subText ?? '',
    }
  }

  return {
    ...addressDetails,
    blockId,
    name,
    activeTab,
    calendarDownloadLink,
    timeZone,
    allDay,
    addressName,
    videoConferencePasscode,
    addVideoConferenceButton,
    description,
    includeRegistration,
    includeMapToAddress,
    registrationLabel,
    registrationLink,
    roomDetails,
    selectedWebinar,
    startDate: startDate ? zonedTimeToUtc(startDate, timeZone?.value) : undefined,
    endDate: tempEndDate && zonedTimeToUtc(tempEndDate, timeZone?.value),
    videoConferenceName: selectedVideoConference ? selectedVideoConference.value : undefined,
    videoConferenceDescription: selectedVideoConference ? selectedVideoConference.subText : undefined,
    videoConferenceLink: videoConferenceLink !== '' ? videoConferenceLink : undefined,
    webinarName: selectedWebinar ? selectedWebinar.label : undefined,
    webinarId: selectedWebinar ? selectedWebinar.extraOptions?.id : undefined,
    startTime: allDay ? undefined : formattedStartTime,
    endTime: allDay ? undefined : formattedEndTime,
  }
}

export const getDefaultValues = (defaultValues?: Record<string, any>, accountTimeZoneId?: string): CustomAddonModalForm => {
  const timeZoneOptions = timeZoneData.map((option) => ({ label: option.name, value: option.id }))
  const defaultTimeZoneOption = timeZoneOptions.find((timeZone) => timeZone.value === accountTimeZoneId)

  const {
    name = '',
    description = '',
    startDate,
    endDate,
    startTime,
    endTime,
    allDay = false,
    timeZone = defaultTimeZoneOption,
    addVideoConferenceButton = false,
    roomDetails = '',
    includeMapToAddress = false,
    includeRegistration = false,
    registrationLink = '',
    registrationLabel = '',
    videoConferenceLink = '',
    videoConferencePasscode = '',
    activeTab = EventModalTabs.IN_PERSON,
  } = defaultValues ?? {}

  let tempEndTime = ''

  if (endTime?.includes('PM')) {
    const [hours, minutes] = endTime.split(':')
    const newHours = parseInt(hours) + 12

    tempEndTime = `${newHours}:${minutes}`
  }

  return {
    details: {
      eventName: name,
      description,
      startTime,
      endTime: endTime ?? tempEndTime,
      timeZone,
      startDate: startDate ? utcToZonedTime(startDate ?? '', (timeZone as unknown as SelectV2SingleOption)?.value ?? '') : undefined,
      endDate: endDate ? utcToZonedTime(endDate ?? '', (timeZone as unknown as SelectV2SingleOption)?.value ?? '') : undefined,
      allDay: Boolean(allDay),
      addVideoConferencing: Boolean(addVideoConferenceButton),
    },
    locationDetails: {
      roomDetails,
      registrationLink,
      registrationLabel,
      includeMapToAddress: Boolean(includeMapToAddress),
      includeRegistration: Boolean(includeRegistration),
    },
    webinarDetails: {
      webinarLink: videoConferenceLink,
      webinarPasscode: videoConferencePasscode,
    },
    activeTab,
  }
}

export const URL_VALIDATION_MESSAGES = {
  invalidUrl: 'Please enter a valid URL',
}

export const validateEventUrl = (url: string, t: TFunction, setError: Function) => {
  const isValid = checkURLValidity(url) === undefined
  setError(isValid ? undefined : t('Please enter a valid URL'))
  return isValid
}
