import { gql } from '@apollo/client'

export default gql`
  query getPersonalizationsMissingFallback($msgId: String!, $htmlOrText: String!, $subject: String!, $preview: String!) {
    getPersonalizationsMissingFallback(msgId: $msgId, htmlOrText: $htmlOrText, subject: $subject, preview: $preview) {
      personalization
      groupId
    }
  }
`
