import { gql } from '@apollo/client'

export default gql`
  query getSendTestEmailInfo {
    getSendTestEmailInfo {
      id
      baseId
      name
      count
      lastCounted
      lastModified
      testContacts {
        id
        name
        email
      }
      segments {
        id
        baseId
        name
        count
        lastCounted
        lastModified
        testContacts {
          id
          name
          email
        }
      }
      subjectPrefix
    }
  }
`
