import React, { useState } from 'react'
import { FieldError } from 'react-hook-form'

import classNames from 'classnames'
import { FieldPath, FieldValues } from 'react-hook-form/dist/types'
import { UseFormRegister } from 'react-hook-form/dist/types/form'

import Input from '@components/Input/Input'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { HMACAuthenticationField } from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/components/IncomingWebhookAuthentication/components/IncomingWebhookHMACAuthentication/IncomingWebhookHMACAuthentication.constants'
import {
  renderInfoTooltip,
  renderShowButton,
} from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/utils/CreateIncomingWebhookModal.utils'

import './IncomingWebhookInput.css'

export type InputField = Omit<HMACAuthenticationField, HMACAuthenticationField.SIGNATURE_HEADER | HMACAuthenticationField.SIGNING_SECRET>

interface IncomingWebhookInputProps<T extends FieldValues> {
  className?: string
  dataTest?: string
  error?: FieldError
  field: InputField
  isPassword?: boolean
  name: FieldPath<T>
  placeholder: string
  register: UseFormRegister<T>
  rootTranslation: string
}

const rootClass = 'incoming-webhook-input'
const IncomingWebhookInput = <T extends FieldValues>({
  className = '',
  dataTest = rootClass,
  error,
  field,
  isPassword = false,
  name,
  placeholder,
  register,
  rootTranslation,
}: IncomingWebhookInputProps<T>) => {
  const [showPassword, setShowPassword] = useState(false)
  const { t } = useTranslation()

  return (
    <div className={classNames(rootClass, className)}>
      <Input
        dataTest={dataTest}
        register={register(name)}
        placeholder={t(placeholder)}
        type={!showPassword && isPassword ? 'password' : 'text'}
        error={!!error}
        label={
          <div className={`${rootClass}__label`}>
            <span>
              <Typography
                text={t(`${rootTranslation}.${field}.Label`)}
                tagProps={{ bold: { weight: TextWeight.MEDIUM, type: TextType.BODY_TEXT } }}
                type={TextType.BODY_TEXT_LIGHT}
                lineHeight={LineHeight.MEDIUM}
                inline
              />
              {renderInfoTooltip(t(`${rootTranslation}.${field}.Tooltip`))}
            </span>
            {isPassword && renderShowButton(showPassword, () => setShowPassword(!showPassword), t)}
          </div>
        }
      />
      {error && (
        <div className={`${rootClass}__error`}>
          <Svg name={SvgNames.inputStatusInvalidNoFill} type={SvgType.ICON} fill={SvgColor.ERROR_TEXT} />
          <Typography type={TextType.ERROR} text={t(error.message)} lineHeight={LineHeight.MEDIUM_SMALL} />
        </div>
      )}
    </div>
  )
}

export default IncomingWebhookInput
