import React, { ComponentProps, FC, ReactNode } from 'react'

import classNames from 'classnames'

import ImportLog, { ImportLogProps } from '@complex/ImportLog/ImportLog'
import DeleteConfirmation, { DeleteConfirmationProps } from '@components/DeleteConfirmation/DeleteConfirmation'
import DuplicateFolderModal, { DuplicateFolderModalProps } from '@components/DuplicateModal/DuplicateFolderModal'
import { ExportToFtpFileModal, ExportToFtpFileModalProps } from '@components/ExportToFtpFileModal/ExportToFtpFileModal'
import ManageTagModal from '@components/ManageTagModal/ManageTagModal'
import MoveToFolderModal, { MoveToFolderModalProps } from '@components/MoveToFolderModal/MoveToFolderModal'
import NewAddFolderModal from '@components/NewAddFolder/NewAddFolderModal'
import SelectAssetV2, { SelectAssetV2Props } from '@components/SelectAssetV2/SelectAssetV2'
import SelectFtpFileWithSearchModal, { FileSelectWithSearchModalProps } from '@components/SelectFtpFileWithSearchModal/SelectFtpFileWithSearchModal'
import StatusToast, { Status } from '@components/StatusToast/StatusToast'
import { LabelDto } from '@graphql/types/microservice/categorization-types'

export interface StatusToastProps {
  title?: string
  status: Status
  message: string | ReactNode
  closeStatus: () => void
}

export interface ListPageModalsState {
  showDeleteConfirmation: boolean
  showStatusToast: boolean
  showManageTag: boolean
  showMoveToFolder: boolean
  showAddFolder: boolean
  showDuplicate: boolean
  showSelectAsset: boolean
  showSelectFtpSyncJob: boolean
  showFtpFileImportLog: boolean
  showExportActionModal: boolean
  showSelectFtpFile: boolean
  showSelectFtpExportManage: boolean
  showCreateNewFtpFile: boolean
}

export interface ListPageModalsProps {
  addFolderProps: ComponentProps<typeof NewAddFolderModal>
  moveToFolderProps: MoveToFolderModalProps<any>
  deleteConfirmationProps: DeleteConfirmationProps
  duplicateProps: DuplicateFolderModalProps
  selectAssetV2Props: SelectAssetV2Props
  selectFtpSyncJobProps: SelectAssetV2Props
  selectExportActionProps: SelectAssetV2Props
  selectFtpExportFileProps: FileSelectWithSearchModalProps
  selectFtpExportSyncProps: SelectAssetV2Props
  exportToFtpFileProps: ExportToFtpFileModalProps
  importLogModalProps: ImportLogProps
  statusToastProps: StatusToastProps
  modalsState: ListPageModalsState
  closeModal: (modalName: ListPageModal) => void
  tagToEdit?: LabelDto
  createTag: (tag: LabelDto) => void
  className?: string
  dataTest?: string
}

export enum ListPageModal {
  DELETE_CONFIRMATION = 'deleteConfirmation',
  STATUS_TOAST = 'statusToast',
  SHOW_MANAGE_TAG = 'showManageTag',
  MOVE_TO_FOLDER = 'moveToFolder',
  ADD_FOLDER = 'addFolder',
  DUPLICATE = 'duplicate',
  SELECT_ASSET = 'selectAsset',
  SELECT_FTP_SYNC = 'selectFtpSync',
  FTP_IMPORT_LOG = 'ftpImportLog',
  EXPORT_ACTION_MODAL = 'exportActionModal',
  SELECT_EXPORT_FILE = 'selectExportFile',
  MANAGE_FTP_EXPORT = 'manageFtpExport',
  CREATE_FTP_FILE = 'createFtpFile',
}

const rootClass = 'list-page-modals'

const ListPageModals: FC<ListPageModalsProps> = (props: ListPageModalsProps) => {
  const {
    modalsState,
    closeModal,
    tagToEdit,
    createTag,
    addFolderProps,
    moveToFolderProps,
    deleteConfirmationProps,
    duplicateProps,
    selectAssetV2Props,
    selectFtpSyncJobProps,
    selectExportActionProps,
    selectFtpExportFileProps,
    selectFtpExportSyncProps,
    exportToFtpFileProps,
    importLogModalProps,
    statusToastProps,
    dataTest = rootClass,
    className = '',
  } = props

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {modalsState.showDeleteConfirmation && (
        <DeleteConfirmation {...deleteConfirmationProps} onClose={() => closeModal(ListPageModal.DELETE_CONFIRMATION)} />
      )}
      {modalsState.showStatusToast && <StatusToast {...statusToastProps} closeStatus={() => closeModal(ListPageModal.STATUS_TOAST)} />}
      {modalsState.showManageTag && (
        <ManageTagModal tag={tagToEdit} onClose={() => closeModal(ListPageModal.SHOW_MANAGE_TAG)} onCreate={createTag} isOpen />
      )}
      {modalsState.showMoveToFolder && <MoveToFolderModal {...moveToFolderProps} onClose={() => closeModal(ListPageModal.MOVE_TO_FOLDER)} />}
      {modalsState.showAddFolder && <NewAddFolderModal {...addFolderProps} isOpen onClose={() => closeModal(ListPageModal.ADD_FOLDER)} />}
      {modalsState.showDuplicate && <DuplicateFolderModal {...duplicateProps} onCancel={() => closeModal(ListPageModal.DUPLICATE)} />}
      {modalsState.showSelectAsset && <SelectAssetV2 {...selectAssetV2Props} onCancel={() => closeModal(ListPageModal.SELECT_ASSET)} />}
      {modalsState.showSelectFtpSyncJob && <SelectAssetV2 {...selectFtpSyncJobProps} onCancel={() => closeModal(ListPageModal.SELECT_FTP_SYNC)} />}
      {modalsState.showFtpFileImportLog && <ImportLog {...importLogModalProps} onClose={() => closeModal(ListPageModal.FTP_IMPORT_LOG)} />}
      {modalsState.showExportActionModal && (
        <SelectAssetV2 {...selectExportActionProps} onCancel={() => closeModal(ListPageModal.EXPORT_ACTION_MODAL)} />
      )}
      {modalsState.showSelectFtpFile && (
        <SelectFtpFileWithSearchModal {...selectFtpExportFileProps} onClose={() => closeModal(ListPageModal.SELECT_EXPORT_FILE)} />
      )}
      {modalsState.showCreateNewFtpFile && (
        <ExportToFtpFileModal {...exportToFtpFileProps} onClose={() => closeModal(ListPageModal.CREATE_FTP_FILE)} />
      )}
      {modalsState.showSelectFtpExportManage && (
        <SelectAssetV2 {...selectFtpExportSyncProps} onCancel={() => closeModal(ListPageModal.MANAGE_FTP_EXPORT)} />
      )}
    </div>
  )
}

export default ListPageModals
