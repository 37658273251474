import Cookies from 'js-cookie'

import globals, { legacyActonContext, rootContext } from '@const/globals'
import documentUtils from '@utils/document'
import windowUtils, { getLocalStorageItem, setLocalStorageItem } from '@utils/window'

import { LoginCookie } from './cookie'

export const PROD_LOGIN_URL = 'https://login.actonsoftware.com/acton/account/login.jsp'
export const DEV_LOGIN_URL = `${window.location.origin}/acton/account/login.jsp`

export const PROD_LOGIN_URL_MFA = 'https://login.actonsoftware.com/app'
export const DEV_LOGIN_URL_MFA = `${window.location.origin}/app`

export const getApplicationRoot = () => documentUtils.getElementById('root') as HTMLDivElement

export const getModalRoot = (id?: string): HTMLDivElement => documentUtils.getElementById(id ?? 'modal-root') as HTMLDivElement

export function sendLoginScreen(saveOriginURL?: boolean) {
  let redirect = ''
  const { search, pathname, hash } = location
  const params = new URLSearchParams(search)

  const backToParent = getLocalStorageItem('backToParent')

  if (backToParent) {
    return
  } else {
    if (params.get('redirect') || saveOriginURL) {
      params.delete('redirect')
      const legacyPath = pathname.indexOf(rootContext) === 0 ? `${legacyActonContext}/..` : ''
      const search = params.toString()
      const backUrl = encodeURIComponent(`${legacyPath}${pathname}${!!hash.length ? '' : search}${hash}`)
      redirect = `?u=${backUrl}`

      !!hash.length && setLocalStorageItem('URL_WITH_HASH', pathname + hash)
    }

    if (window.mfaLogoutRedirect && Cookies.get(LoginCookie.MFA) === 'true') {
      const loginUrl = globals.isProd() ? PROD_LOGIN_URL_MFA : DEV_LOGIN_URL_MFA
      windowUtils.setLocationHref(`${window.mfaLogoutRedirect}/logout?returnToUrl=${encodeURIComponent(loginUrl)}`)
    } else {
      const loginUrl = (globals.isProd() ? PROD_LOGIN_URL : DEV_LOGIN_URL) + redirect
      windowUtils.setLocationHref(loginUrl)
    }
  }
}

export function onlyUnique(value: string, index: number, self: string[]) {
  return self.indexOf(value) === index
}

export const download = (url: string, fileName: string) => {
  const link = document.createElement('a')
  link.href = url
  link.download = fileName
  // Appending to DOM hides the link behind modals. This is more direct
  link.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }))
  link.remove()
}

export const downloadImage = (imageSrc: string, imageName = 'image.png') => {
  fetch(imageSrc, { mode: 'cors' })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      return response.blob()
    })
    .then((blob) => {
      const href = URL.createObjectURL(blob)
      download(href, imageName)
      window.URL.revokeObjectURL(href)
    })
}

export const isFunction = (value: any): value is Function => value instanceof Function

export const isValidJson = (str: string): boolean => {
  try {
    JSON.parse(str)
    return true
  } catch {
    return false
  }
}

export const parseJsonSafely = (str: string): any => {
  try {
    let parsed = JSON.parse(str)

    while (typeof parsed === 'string' && isValidJson(parsed)) {
      parsed = JSON.parse(parsed)
    }
    return parsed
  } catch {
    return null
  }
}

/**
 * Removes the element at the specified index in the given array
 * @param {number} index - The index at which the element should be removed.
 * @param {T[]} array - The original array.
 * @returns {T[]} - A new array with the element removed at the specified index.
 */
export const removeElement = <T>(index: number, array: T[]): T[] => [...array.slice(0, index), ...array.slice(index + 1)]

/**
 * Replaces the element at the specified index in the given array with a new value
 * @param {number} index - The index at which the element should be replaced.
 * @param {T} value - The new value to be placed at the specified index.
 * @param {T[]} array - The original array.
 * @returns {T[]} - A new array with the element replaced at the specified index.
 */
export const replaceElement = <T>(index: number, value: T, array: T[]): T[] => [...array.slice(0, index), value, ...array.slice(index + 1)]

/**
 * Edits the element at the specified index in the given array by applying partial updates. The original element is merged with the new values provided, creating a new array with the updated element.
 * @param {number} index - The index at which the element should be edited.
 * @param {Partial<T>} newValues - Partial updates to be applied to the existing element.
 * @param {T[]} array - The original array.
 * @returns {T[]} - A new array with the element at the specified index edited with the provided partial updates.
 *
 * @example
 * const originalArray = [{ id: 1, name: 'John' }, { id: 2, name: 'Jane' }];
 * const editedArray = editElement(1, { name: 'Janet' }, originalArray);
 * // editedArray is now [{ id: 1, name: 'John' }, { id: 2, name: 'Janet' }];
 */
export const editElement = <T>(index: number, newValues: Partial<T>, array: T[]): T[] =>
  replaceElement(index, { ...array[index], ...newValues }, array)

export default {
  getApplicationRoot,
  getModalRoot,
  sendLoginScreen,
  isFunction,
  removeElement,
  replaceElement,
}

export const isValidUrl = (url: string | undefined) => {
  try {
    return !!url && new URL(url)
  } catch {
    return false
  }
}

// Helper function to remove HTML tags from string
export const stripHtml = (text: string) => text.replace(/<\/?[^>]+(>|$)/g, '')
