import React, { FC } from 'react'

import Chart from '@components/Chart/Chart'
import { ChartProps, mapLegend, mapSeries, MapTypes, usJoinBy, worldJoinBy } from '@const/Chart.constants'

interface MapChartProps extends ChartProps {
  className?: string
  dataTest?: string
  mapType: MapTypes
  chartHeight?: number
}

const rootClass = 'map-chart'

const MapChart: FC<MapChartProps> = (props: MapChartProps) => {
  const { ExtraElement, dataTest = rootClass, className = '', title = '', mapType, chartHeight = 555, fields } = props

  const series = [
    {
      ...mapSeries,
      ...{
        name: fields[0]?.name ?? '',
        joinBy: mapType === MapTypes.US ? usJoinBy : worldJoinBy,
        data: fields[0]?.data ?? [],
      },
    },
  ]

  return (
    <Chart
      dataTest={dataTest}
      className={className}
      chartType={'map'}
      series={series}
      title={title}
      ExtraElement={ExtraElement}
      legend={mapLegend}
      chartHeight={chartHeight}
      mapType={mapType}
    />
  )
}

export default MapChart
