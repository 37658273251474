import React, { FC } from 'react'

import equal from 'fast-deep-equal/es6/react'

import Svg from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'

import { NavigationState, rootClass } from '../Navigation'
import navigationUtils from '../navigationUtils'

export const getFinalState = (expanded: boolean, state: NavigationState) => {
  let lastSelectedMenu = state.lastSelectedMenu
  let currentlySelectedMenu = state.currentlySelectedMenu
  if (expanded) {
    lastSelectedMenu = state.currentlySelectedMenu
    currentlySelectedMenu = ''
  } else if (state.lastSelectedMenu) {
    currentlySelectedMenu = state.lastSelectedMenu
  }
  return {
    ...state,
    userInitiatedNavigationCollapse: true,
    currentlySelectedMenu,
    lastSelectedMenu,
    menuItemSelectedByUserHover: false,
    menuItemSelectedByUserClick: false,
  }
}

interface NavigationCollapserProps {
  expanded: boolean
  state: NavigationState
  t: (val: string) => string
  setState: (val: NavigationState) => void
}
const propsAreEqual = (prevProps: NavigationCollapserProps, nextProps: NavigationCollapserProps) => equal(prevProps, nextProps)

const NavigationCollapser: FC<NavigationCollapserProps> = React.memo((props: NavigationCollapserProps) => {
  const { expanded, state, setState, t } = props

  return (
    <button
      data-test="navigation-collapser"
      onClick={() => {
        navigationUtils.toggleNavigation(expanded, state, setState, getFinalState(expanded, state))
      }}
      className={`${rootClass}__expander`}
      title={t(expanded ? 'Collapse menu' : 'Expand menu')}
    >
      <label tabIndex={-1}>
        <Svg name={SvgNames.collapseMenu} />
        <span>{t('Collapse menu')}</span>
      </label>
    </button>
  )
}, propsAreEqual)

NavigationCollapser.displayName = 'NavigationCollapser'
export default NavigationCollapser
