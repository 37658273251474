import { createContext, ReactNode } from 'react'

import { ContactStatus, Delimiter, Quote } from '@complex/ImportContacts/ImportContacts.constants'
import { ProgressBarStep } from '@components/ProgressBar/ProgressBar'
import { SelectV2Option, SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { ListItem } from '@components/SimpleList/SimpleList'
import { Status } from '@components/StatusToast/StatusToast'
import { StandardField, UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { List } from '@interface/foldersLists/List'
import { ColumnMergeType } from '@src/pages/ImportContactsV2/utils/ImportContactsV2.constants'

export type Update = (fields: Partial<ImportContactsContainerValues>) => void

export interface ImportContactsContextAPI {
  values: ImportContactsContainerValues
  startUpload: () => void
  handleOnSectionChange: (newStep: string, currentStepCompleted?: boolean) => void
  removeFormData: () => void
  onFileSelected: (files: File[]) => void
  validateColumn: (index: number) => void
  updatePreviewData: (file: any) => void
  doImport: () => void
  importContacts: () => void
  closeModal: () => void
  updateHasHeaders: (hasHeaders: boolean) => void
  createSegment: (segmentName: string) => void
  reviewSummary: () => void
  closeSummary: () => void
  onSubmitLists: (lists: List[]) => void
  closeStatusToast: () => void
  resetState: () => void
  matchFieldsWithHeaders: () => void
  setContactPreference: (preference: ContactStatus) => void
  enableContactPreference: (show: boolean) => void
  getTopDirectSelectSegments: (currentPage?: number) => Promise<SelectV2Option[]>
  searchTopDirectSelectSegments: (search: string) => Promise<SelectV2Option[]>
  isAValidSegmentName: (segmentName: string) => Promise<boolean>
  update: Update
}

export interface ImportContactsContainerValues {
  pageError: boolean
  loading: boolean
  saved: boolean
  fileSelected?: File | null
  fileUploading: boolean
  uploadingProgress: number
  uploadCompleted: boolean
  doImportList: boolean
  importingList: boolean
  mappingId: string
  listName: string
  previewHeader: string[]
  previewRecords: ListItem[][]
  mappingPreview: MappingPreview[]
  standardFields: StandardField[]
  nonDeletedFields: UnifiedListFieldMapping[]
  deletedFields: UnifiedListFieldMapping[]
  visibleFields: UnifiedListFieldMapping[]
  statusToast: { statusMessage: string | ReactNode | undefined; status: Status; showStatusToast: boolean }
  delimiter: Delimiter
  hasHeaders: boolean
  quoteChar: Quote
  columnCount: number
  validationTrigger: boolean
  validateAllTrigger: boolean
  inputOnBlurTriggered: boolean
  previewIndex: number | undefined
  error: {
    displayError: boolean
    errorTitle?: string
    errorMessage?: string | ReactNode
  }
  indexRef: number
  summary: {
    mapped: number
    unmapped: number
    newFields: number
    warnings: number
  }
  selectSegmentChecked: boolean
  showProgress: boolean
  importStarted: boolean
  contactPreference: ContactStatus | undefined
  contactPreferenceEnabled: boolean
  creatingSegmentLoading: boolean
  selectOptions: SelectV2SingleOption[]
  segmentSelectedOption?: SelectV2SingleOption
  validSegmentName: boolean
  steps: ProgressBarStep[]
  showAdvancedOptions: boolean
  mergeRule: ColumnMergeType
}

export const defaultState: ImportContactsContainerValues = {
  pageError: false,
  loading: false,
  saved: false,
  fileSelected: null,
  fileUploading: false,
  uploadingProgress: 0,
  uploadCompleted: false,
  doImportList: false,
  importingList: false,
  mappingId: '',
  listName: '',
  previewHeader: [],
  previewRecords: [],
  standardFields: [],
  mappingPreview: [],
  statusToast: { statusMessage: '', status: Status.FAIL, showStatusToast: false },
  nonDeletedFields: [],
  deletedFields: [],
  visibleFields: [],
  delimiter: Delimiter.Comma,
  hasHeaders: true,
  quoteChar: Quote.Quote,
  columnCount: 0,
  validationTrigger: false,
  validateAllTrigger: false,
  inputOnBlurTriggered: false,
  previewIndex: undefined,
  error: {
    displayError: false,
    errorTitle: '',
    errorMessage: '',
  },
  indexRef: -1,
  summary: {
    mapped: 0,
    unmapped: 0,
    newFields: 0,
    warnings: 0,
  },
  selectSegmentChecked: false,
  showProgress: false,
  importStarted: false,
  contactPreference: undefined,
  contactPreferenceEnabled: false,
  creatingSegmentLoading: false,
  selectOptions: [],
  validSegmentName: true,
  steps: [],
  showAdvancedOptions: false,
  mergeRule: ColumnMergeType.IfEmpty,
}

export const DateFormatDisplay: { [key: string]: string } = {
  'MMM dd yyyy': 'Month dd YYYY',
  'dd MMM yyyy': 'dd Month YYYY',
  'EEE, dd MMM yyyy': 'Weekday, dd Month YYYY',
  'MM.dd.yyyy': 'MM.dd.YYYY',
  'dd.MM.yyyy': 'dd.MM.YYYY',
  'yyyy/MM/dd': 'YYYY/MM/dd',
  'yyyy-MM-dd': 'YYYY-MM-dd',
  'yyyy.MM.dd': 'YYYY.MM.dd',
  'MM/dd/yyyy': 'MM/dd/yyyy',
  'MM/dd/yy': 'MM/dd/yy',
  'MM-dd-yyyy': 'MM-dd-yyyy',
  'MM-dd-yy': 'MM-dd-yy',
  'dd/MM/yyyy': 'dd/MM/yyyy',
  'dd/MM/yy': 'dd/MM/yy',
  'dd-MM-yyyy': 'dd-MM-yyyy',
  'dd-MM-yy': 'dd-MM-yy',
  'yyyy-dd-MM': 'yyyy-dd-MM',
}

export const DateTimeFormatDisplay: { [key: string]: string } = {
  'MM/dd/yyyy HH:mm': 'MM/dd/yyyy HH:mm',
  'MM/dd/yy HH:mm': 'MM/dd/yy HH:mm',
  'MM-dd-yyyy HH:mm': 'MM-dd-yyyy HH:mm',
  'MM-dd-yy HH:mm': 'MM-dd-yy HH:mm',
  'MMM dd yyyy HH:mm': 'Month dd yyyy HH:mm',
  'dd/MM/yyyy HH:mm': 'dd/MM/yyyy HH:mm',
  'dd/MM/yy HH:mm': 'dd/MM/yy HH:mm',
  'dd-MM-yyyy HH:mm': 'dd-MM-yyyy HH:mm',
  'dd-MM-yy HH:mm': 'dd-MM-yy HH:mm',
  'dd MMM yyyy HH:mm': 'dd MMM yyyy HH:mm',
  'MM/dd/yyyy HH:mm:ss': 'MM/dd/yyyy HH:mm:ss',
  'MM/dd/yy HH:mm:ss': 'MM/dd/yy HH:mm:ss',
  'MM-dd-yyyy HH:mm:ss': 'MM-dd-yyyy HH:mm:ss',
  'MM-dd-yy HH:mm:ss': 'MM-dd-yy HH:mm:ss',
  'MMM dd yyyy HH:mm:ss': 'Month dd yyyy HH:mm:ss',
  'dd/MM/yyyy HH:mm:ss': 'dd/MM/yyyy HH:mm:ss',
  'dd/MM/yy HH:mm:ss': 'dd/MM/yy HH:mm:ss',
  'dd-MM-yyyy HH:mm:ss': 'dd-MM-yyyy HH:mm:ss',
  'dd-MM-yy HH:mm:ss': 'dd-MM-yy HH:mm:ss',
  'dd MMM yyyy HH:mm:ss': 'dd MMM yyyy HH:mm:ss',
  'yyyy-dd-MM hh:mm:ss': 'yyyy-dd-MM hh:mm:ss',
  'EEE, dd MMM yyyy HH:mm': 'Weekday, dd Month YYYY HH:mm',
  'EEE, dd MMM yyyy HH:mm:ss': 'Weekday, dd Month YYYY HH:mm:ss',
  'EEE, dd MMM yyyy hh:mm:ss': 'Weekday, dd Month YYYY hh:mm:ss',
}
export const DataTypeList = [
  { id: 'TEXT', label: 'Text' },
  {
    id: 'DATE',
    label: 'Date',
    formatOptions: [
      'dd MMM yyyy',
      'dd/MM/yyyy',
      'dd-MM-yyyy',
      'dd.MM.yyyy',
      'dd/MM/yy',
      'dd-MM-yy',
      'EEE, dd MMM yyyy',
      'MMM dd yyyy',
      'MM/dd/yyyy',
      'MM-dd-yyyy',
      'MM.dd.yyyy',
      'MM/dd/yy',
      'MM-dd-yy',
      'yyyy/MM/dd',
      'yyyy-MM-dd',
      'yyyy.MM.dd',
      'yyyy-dd-MM',
    ],
  },
  { id: 'EMAIL', label: 'Email' },
  { id: 'SCORE', label: 'Score' },
  { id: 'NUMBER', label: 'Number' },
  { id: 'BOOLEAN', label: 'Boolean' },
  {
    id: 'DATETIME',
    label: 'Date time',
    formatOptions: [
      'MM/dd/yyyy HH:mm',
      'MM/dd/yy HH:mm',
      'MM-dd-yyyy HH:mm',
      'MM-dd-yy HH:mm',
      'MMM dd yyyy HH:mm',
      'dd/MM/yyyy HH:mm',
      'dd/MM/yy HH:mm',
      'dd-MM-yyyy HH:mm',
      'dd-MM-yy HH:mm',
      'dd MMM yyyy HH:mm',
      'MM/dd/yyyy HH:mm:ss',
      'MM/dd/yy HH:mm:ss',
      'MM-dd-yyyy HH:mm:ss',
      'MM-dd-yy HH:mm:ss',
      'MMM dd yyyy HH:mm:ss',
      'dd/MM/yyyy HH:mm:ss',
      'dd/MM/yy HH:mm:ss',
      'dd-MM-yyyy HH:mm:ss',
      'dd-MM-yy HH:mm:ss',
      'dd MMM yyyy HH:mm:ss',
      'yyyy-dd-MM hh:mm:ss',
      'EEE, dd MMM yyyy HH:mm',
      'EEE, dd MMM yyyy HH:mm:ss',
      'EEE, dd MMM yyyy hh:mm:ss',
    ],
  },
]

export enum ColumnStateType {
  EXCEPTION = 'EXCEPTION',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
  DUPLICATE = 'DUPLICATE',
  CUSTOM_FIELD_DELETED = 'CUSTOM_FIELD_DELETED',
  CUSTOM_FIELD_IN_USE = 'CUSTOM_FIELD_IN_USE',
}

export type MappingPreview = {
  index: number
  friendlyName: string
  mapping: string | undefined
  mappingIndex: string
  dataType: string | undefined
  dataFormat: string | undefined
  merge: string
  isCustom: boolean
  state?: ColumnStateType | null
  isDuplicate?: boolean
  isNewCustomFieldDeleted?: boolean
  isNewCustomFieldInUse?: boolean
  displayName?: string
  csvColumn?: string
  marketingColumnName?: string
}

export const defaultMappingPreviewField: Omit<MappingPreview, 'index'> = {
  friendlyName: '',
  mapping: '',
  mappingIndex: '',
  dataType: '',
  dataFormat: '',
  merge: 'IF_EMPTY',
  isCustom: false,
  state: null,
  isDuplicate: false,
  isNewCustomFieldDeleted: false,
  isNewCustomFieldInUse: false,
  displayName: '',
}

export enum VALIDATION_MESSAGE {
  EMAIL_MISSING = 'Map E-mail Address before importing.',
  DUPLICATED_FIELDS = 'Existing fields can only be mapped one time.',
  CUSTOM_FIELD_IN_USE = 'Field Name is in use. Enter a unique field name.',
  EMPTY_CUSTOM_FIELD = 'Name your Custom Field before importing.',
  EMPTY_DATA_FORMAT = 'For one or more fields, data format must be selected in order to import.',
  CUSTOM_FIELD_DATA_TYPE = 'Custom Fields data types must be selected in order to import.',
  VALIDATION_IN_PROGRESS = "Hang on, we're still validating your data.",
  VALIDATION_SUCCESFUL = 'Validation success! Click Review to Continue.',
}

export const ImportContactsContext = createContext<ImportContactsContextAPI>({ values: defaultState } as any)
