import { createContext } from 'react'

import { WebhookSubscription } from '@graphql/types/query-types'
import { DeepUpdateState } from '@utils/types'

export interface ProgramOutgoingWebhooksState {
  isSavingEndpoints: boolean
  defaultEndpoints: WebhookSubscription[]
  loadingEndpoints: boolean
  endpoints: WebhookSubscription[]
}

export interface ProgramOutgoingWebhooksAPI {
  values: ProgramOutgoingWebhooksState
  update: DeepUpdateState<ProgramOutgoingWebhooksState>
}

export const programOutgoingWebhooksInitialState: ProgramOutgoingWebhooksState = {
  isSavingEndpoints: false,
  defaultEndpoints: [],
  loadingEndpoints: false,
  endpoints: [],
}

export const ProgramOutgoingWebhooksContext = createContext<ProgramOutgoingWebhooksAPI>({ values: programOutgoingWebhooksInitialState } as any)
