import { gql } from '@apollo/client'

export default gql`
  query getLabs {
    getLabs {
      title
      description
      caution
      link
      enabled
      settingsId
    }
  }
`
