import { Dispatch, SetStateAction } from 'react'

import { ListPickerModalState } from '@complex/ListPickerModalV2/utils/interfaces/ListPickerModalInterfaces'
import { ListPickerEmptyListingOptions } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import { FilterDefinition } from '@utils/filter'
export const setInitialStateUtils = (setState: Dispatch<SetStateAction<ListPickerModalState>>, isUnifiedList: boolean, filter?: FilterDefinition) =>
  setState((state: ListPickerModalState) => ({
    ...state,
    currentFilter: filter ?? undefined,
    currentFolder: undefined,
    currentTag: undefined,
    currentPage: 0,
    emptyListingOption: ListPickerEmptyListingOptions.EMPTY_OTHERS,
    foldersState: { folders: [], foldersLoading: true },
    headerShadow: false,
    listsState: { ...state.listsState, listsLoading: true },
    loading: false,
    tagsState: { ...state.tagsState, tagsLoading: isUnifiedList },
  }))
