import React, { ChangeEvent, FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import Container from '@components/Container'
import Input from '@components/Input/Input'
import Select from '@components/Select/Select'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './FatigueRuleSetting.css'

type Props = {
  fatigueFrequency: number
  fatigueInterval: number
  setFrequency: (frequency: number) => void
  setInterval: (interval: number) => void
  className?: string
  dataTest?: string
}

const rootClass = 'fatigue-rule-setting'

const FatigueRuleSetting: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '', fatigueFrequency, fatigueInterval, setFrequency, setInterval } = props
  const [error, setError] = useState<boolean>(false)
  const [frequency, setFreq] = useState<number | string>(fatigueFrequency)
  const { t } = useTranslation()

  useEffect(() => {
    setFreq(fatigueFrequency)
  }, [fatigueFrequency])

  const handleFrequencyInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setFreq(value)
    if (isNaN(Number(value)) || parseInt(value) > 9 || parseInt(value) < 1) {
      setError(true)
    } else {
      setFrequency(parseInt(value))
    }
  }

  const containerDescription = (
    <>
      <Typography
        text={t('The maximum number of messages that will be sent during the defined period.')}
        type={TextType.BODY_TEXT_LIGHT}
        lineHeight={LineHeight.MEDIUM}
      />
      <Typography
        text={t('This limit should match the limits given to customers during their Opt-In to prevent over-sending.')}
        type={TextType.BODY_TEXT_LIGHT}
        lineHeight={LineHeight.MEDIUM}
      />
    </>
  )

  return (
    <Container className={classNames(rootClass, className)} data-test={dataTest} noBottom title={'Fatigue Rules'} description={containerDescription}>
      <div className={`${rootClass}__inputs`}>
        <span className={`${rootClass}__select-span`}>
          <Select
            className={`${rootClass}__select`}
            label={t('Time Period')}
            onChange={(e) => setInterval(parseInt(e.target.value))}
            value={fatigueInterval?.toString()}
          >
            <option value={7}>{t('Weekly (7 days)')}</option>
            <option value={30}>{t('Monthly (30 days)')}</option>
          </Select>
        </span>
        <span>
          <Input
            className={`${rootClass}__input`}
            value={frequency?.toString()}
            label={t('Maximum number of messages than can be sent')}
            onChange={(e) => handleFrequencyInput(e)}
            type={'number'}
          />
          {error && <Typography text={t('Number must be between 1-9')} type={TextType.ERROR} className={`${rootClass}__error`} />}
        </span>
      </div>
    </Container>
  )
}

export default FatigueRuleSetting
