import React, { FC } from 'react'

import Button, { ButtonType } from '@components/Button'
import { ModalFooter } from '@components/Modal'
import Svg, { SvgNames } from '@components/Svg'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { EmailTemplateLayoutType } from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/utils/EmailLayoutsModal.utils'

interface EmailLayoutsModalFooterProps {
  onInsert: () => void
  onCancel: () => void
  onPreview: () => void
  layoutsType: EmailTemplateLayoutType
  isPreview: boolean
  isReplaceFlow: boolean
  disableReplace: boolean
  hasSelected: boolean
  className: string
  selectedTitle?: string
  dataTest?: string
}

const EmailLayoutsModalFooter: FC<EmailLayoutsModalFooterProps> = ({
  onInsert,
  onCancel,
  onPreview,
  layoutsType,
  isPreview,
  selectedTitle,
  isReplaceFlow,
  disableReplace,
  hasSelected,
  className,
  dataTest = className,
}) => {
  const { t } = useTranslation()

  return (
    <ModalFooter className={className} dataTest={dataTest}>
      <div className={`${className}__left`} data-test={`${dataTest}-left`}>
        {!isPreview ? (
          hasSelected ? (
            <>
              <Button
                buttonType={ButtonType.INFO}
                onClick={onPreview}
                className={`${className}__preview-button`}
                dataTest={`${dataTest}-button-preview`}
              >
                <Svg name={SvgNames.zoom} />
                {t('Preview selected')}
              </Button>
              <TextWithTooltipOnEllip
                typographyProps={{
                  text: selectedTitle ? 'Bold.Name.Selected' : '',
                  type: TextType.BODY_TEXT_SMALL_LIGHT,
                  tagProps: { bold: { weight: TextWeight.BOLD, inline: true, lineHeight: LineHeight.MEDIUM_SMALL } },
                  values: { name: selectedTitle, layoutsType },
                }}
                tooltipProps={{ children: selectedTitle }}
                className={`${className}__preview-selected-text`}
              />
            </>
          ) : (
            <Typography
              text={t('AssetPicker.Common.Footer.SelectedRowCount_single', { count: 0, itemType: layoutsType })}
              type={TextType.BODY_TEXT_SMALL_LIGHT}
            />
          )
        ) : null}
      </div>
      <Button buttonType={ButtonType.TERTIARY} onClick={onCancel} dataTest={`${dataTest}-button-cancel`}>
        {t(isPreview ? 'Back' : 'Cancel')}
      </Button>
      <Button
        buttonType={ButtonType.PRIMARY}
        onClick={onInsert}
        disabled={(!hasSelected && !isPreview) || disableReplace}
        dataTest={`${dataTest}-button-insert`}
      >
        {isReplaceFlow ? t('Replace') : t('Email.Layout.Modal.Insert.Label', { layoutsType })}
      </Button>
    </ModalFooter>
  )
}

export default EmailLayoutsModalFooter
