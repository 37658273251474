import React, { ReactNode } from 'react'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { formSubmissionInfoHoverCardRootClass } from '@src/pages/listingPages/FormsJoinView/components/FormSubmissionInfoHoverCard/FormSubmissionInfoHoverCard'
import { FormActivities } from '@src/pages/listingPages/FormsJoinView/components/FormSubmissionInfoHoverCard/FormSubmissionInfoHoverCard.constants'
import { formatDateWithAbbreviations } from '@utils/date'

export const renderField = (label: ReactNode, date: number, index: number) => {
  return (
    <div key={index} className={`${formSubmissionInfoHoverCardRootClass}__activities-element`}>
      <div className={`${formSubmissionInfoHoverCardRootClass}__activities-name`}>
        <Svg name={SvgNames.clock} type={SvgType.ICON} />
        {label}
      </div>
      <Typography text={formatDateWithAbbreviations(date)} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.MEDIUM_LIGHT} />
    </div>
  )
}

const renderUserActivity = (activityDescription: string, userName: string, t: Function) => (
  <Typography
    text={t('FormSubmissions.InfoHover.RecentActivities.UserActivity')}
    values={{ activityDescription, userName }}
    tagProps={{ bold: { weight: TextWeight.BOLD } }}
    type={TextType.BODY_TEXT_SMALL}
    className={`${formSubmissionInfoHoverCardRootClass}__activities-name-compound`}
  />
)

const renderFirstAndLastSubmissions = (t: Function, isFirst = false) => (
  <Typography
    text={t(`FormSubmissions.InfoHover.RecentActivities.${isFirst ? 'First' : 'Last'}Submission`)}
    tagProps={{ bold: { weight: TextWeight.BOLD } }}
    type={TextType.BODY_TEXT_SMALL}
    className={`${formSubmissionInfoHoverCardRootClass}__activities-name-compound`}
  />
)

export const activityByDescription: { [key in FormActivities]: (userName: string, t: Function) => ReactNode } = {
  [FormActivities.SPLIT]: (userName: string, t: Function) => renderUserActivity('A/B split', userName, t),
  [FormActivities.CREATED]: (userName: string, t: Function) => renderUserActivity('Form created', userName, t),
  [FormActivities.RECORDS_CLEARED]: (userName: string, t: Function) => renderUserActivity('Records cleared', userName, t),
  [FormActivities.SEGMENT_CREATED]: (userName: string, t: Function) => renderUserActivity('Segment created', userName, t),
  [FormActivities.FIRST_SUBMISSION]: (_userName: string, t: Function) => renderFirstAndLastSubmissions(t, true),
  [FormActivities.LAST_SUBMISSION]: (_userName: string, t: Function) => renderFirstAndLastSubmissions(t),
}
