import { gql } from '@apollo/client'

export default gql`
  query scheduledMessages {
    scheduledMessages {
      id
      isCustomMessage
      isNewComposer
      isTemplateAvailableInEBE
      beeComposer
      modified
      created
      scheduled
      previewUrl
      publicUrl
      title
      subject
      to
    }
  }
`
