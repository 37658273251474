import { gql } from '@apollo/client'

export default gql`
  mutation createNewMessage(
    $messageType: MessageType!
    $fromDefault: Boolean
    $isPlainTextOnly: Boolean
    $startId: String
    $beeBlankSetup: Boolean
    $isCustomHtml: Boolean
    $assetReportRecipientDetails: AssetReportRecipientDetails
  ) {
    createNewMessage(
      messageType: $messageType
      fromDefault: $fromDefault
      isPlainTextOnly: $isPlainTextOnly
      startId: $startId
      beeBlankSetup: $beeBlankSetup
      isCustomHtml: $isCustomHtml
      assetReportRecipientDetails: $assetReportRecipientDetails
    ) {
      id
      publishId
      messageContentResponse {
        messageType
        beeEditorPage
        emailContentHtml
        settings {
          title
          subject
          preview
          senderName
          senderEmail
          replyToEmail
          replyToName
          replyToUUID
          senderUUID
          subscriptionCategoryId
          actOnCampaignId
          hasPrePopulatedUTMParams
          analyticsTrackingList {
            parameter
            value
          }
          isTransactionalMessage
          isTemplateAvailableInEBE
          fallbackSenderName
          fallbackSenderEmail
          fallbackSenderUUID
          customTextPart
          abTestIsRunning
          abTestsSent
          isPlainTextOnly
          webinarConfig {
            name
            type
            messageCategory
          }
          isCustomHtml
        }
        sendDetails {
          sendToLists {
            srcId
            srcName
            isList
            baseId
            size
            parentId
            children {
              srcId
              srcName
              isList
              baseId
              size
              parentId
            }
          }
          suppressLists {
            srcId
            srcName
            isList
            baseId
            size
            parentId
            children {
              srcId
              srcName
              isList
              baseId
              size
              parentId
            }
          }
          restricteLists {
            srcId
            srcName
            isList
            baseId
            size
            parentId
            children {
              srcId
              srcName
              isList
              baseId
              size
              parentId
            }
          }
          sendToContacts {
            id
            name
            contacts {
              id
              name
              email
            }
          }
          individualRecipientsSourceIds
          suppressDuplicates
          overrideFatigueRules
          sendMethod {
            launchType
            scheduleTimestamp
            scheduleTimeZone
            staggeredLaunchStart
            staggeredLaunchEnd
            adaptiveLaunchEnd
            adaptiveLaunchStart
            sendingPeriodStartHour
            sendingPeriodEndHour
            sendingPeriodStartMinute
            sendingPeriodEndMinute
            isCreateCRMMsgSentNotes
          }
          assetReportRecipientDetails {
            unique
            title
            type
            column
            reportName
            reportType
            ignoreIPs
            showAllOpens
            key
            count
            showAll
            formTitle
            msgId
            contextPath
            fromEmail
            formViewFilter
            fileName
            formId
            deployType
            deployId
            formListId
            anonymous
          }
        }
        lastEdited
        isValidJson
        id
        errorsCount
        status
      }
      status
    }
  }
`
