import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'

import Container from '@components/Container'
import Input from '@components/Input'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { detectEmailType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { DEFAULT_EMAIL_TITLE, EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { useTranslation } from '@utils/const/globals'
import { replaceSpaces } from '@utils/strings'

import { emailSettingsTypographyProps, renderValidation } from '../../EmailComposerSettings.utils'

interface EmailComposerSettingsTitleProps {
  className?: string
  dataTest?: string
}

const EmailComposerSettingsTitle: FC<EmailComposerSettingsTitleProps> = (props: EmailComposerSettingsTitleProps) => {
  const { className = '', dataTest = className } = props
  const {
    values: {
      message: { title, messageType },
      validations: { settingsValidations },
      messageConfiguration: {
        hideInternalEmailTitle,
        settings: { disableInternalTitle },
      },
    },
    api: { update },
  } = useContext(EmailComposerContext)
  const { t } = useTranslation()
  const [currentValue, setCurrentValue] = useState('')

  const [isDirty, setDirty] = useState<boolean>(false)
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const cleanUpRef = useRef<Function>()
  const inputRef = useRef<HTMLInputElement>()

  const { titleMissing } = settingsValidations
  const { isEmailTemplate, isEmailCRM } = detectEmailType(messageType)

  const handleSaveChanges = useCallback(
    (value?: string) => {
      if (!value || !value.trim()) {
        update({ lastSavedTitle: DEFAULT_EMAIL_TITLE, message: { title: DEFAULT_EMAIL_TITLE } })
      } else if (!!value.trim()) {
        update({ lastSavedTitle: replaceSpaces(title), message: { title: replaceSpaces(title) } })
      } else {
        setDirty(true)
      }
      setCurrentValue('')
    },
    [title, update]
  )

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.value || !!e.target.value.trim()) {
        update({ message: { title: e.target.value } })
      }
      setCurrentValue(e.target.value)
    },
    [update]
  )

  const handleFocus = useCallback(() => {
    setDirty(false)
    setIsFocused(true)
  }, [])

  const handleBlur = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      handleSaveChanges(e.target.value)
      setIsFocused(false)
    },
    [handleSaveChanges]
  )

  useEffect(() => {
    cleanUpRef.current = () => handleSaveChanges(inputRef.current?.value)
  }, [handleBlur])

  useLayoutEffect(() => {
    return () => {
      const cleanUp = cleanUpRef.current

      if (cleanUp && isFocused) {
        cleanUp()
      }
    }
  }, [])

  return (
    <Container dataTest={dataTest}>
      <div className={`${className}__subsection`}>
        {hideInternalEmailTitle ? (
          <>
            <Typography
              dataTest={`${dataTest}-title`}
              text={t('EmailComposer.Settings.BlankMessage.Title')}
              weight={TextWeight.BOLD}
              lineHeight={LineHeight.LARGE}
              type={TextType.PAGE_HEADER_SEMI_BOLD}
            />
            <Typography
              dataTest={`${dataTest}-description`}
              text={t('EmailComposer.Settings.BlankMessage.Description')}
              weight={TextWeight.REGULAR}
              lineHeight={LineHeight.MEDIUM}
              type={TextType.NORMAL_TEXT_GRAY_LARGE}
            />
          </>
        ) : (
          <>
            <Tooltip
              hide={!disableInternalTitle}
              position={'top'}
              sideOffset={-22}
              trigger={
                <Input
                  ref={inputRef}
                  label={
                    isEmailTemplate
                      ? t('EmailComposer.Settings.Title.Template')
                      : isEmailCRM
                      ? t('EmailComposer.Settings.Title.CRM')
                      : t('EmailComposer.Settings.Internal.Title')
                  }
                  labelDisplay="inline"
                  required
                  disabled={disableInternalTitle}
                  maxlength={400}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  dataTest={`${dataTest}-title-input`}
                  value={currentValue || title}
                  trim={false}
                  error={isDirty && titleMissing}
                />
              }
            >
              {t('Disabled when resending emails')}
            </Tooltip>
            {isDirty && titleMissing ? (
              renderValidation(
                isEmailCRM
                  ? t('EmailComposer.Settings.Title.CRM.Missing')
                  : t('EmailComposer.Settings.Title.Missing', { context: isEmailTemplate ? 'template' : '' }),
                'ERROR'
              )
            ) : (
              <Typography text={t('EmailComposer.Settings.Internal.Title.Description')} {...emailSettingsTypographyProps} />
            )}
          </>
        )}
      </div>
    </Container>
  )
}

export default EmailComposerSettingsTitle
