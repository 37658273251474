const i18nLanguages = [
  {
    language: 'English',
    translation: 'English',
    langCode: 'en',
  },
  {
    language: 'Espanol',
    translation: 'Español',
    langCode: 'es',
  },
  {
    language: 'Arabic',
    translation: 'العربية',
    langCode: 'ar',
  },
  {
    language: 'Danish',
    translation: 'Dansk',
    langCode: 'da',
  },
  {
    language: 'French',
    translation: 'Français',
    langCode: 'fr',
  },
  {
    language: 'Hebrew',
    translation: 'עברית',
    langCode: 'he',
  },
  {
    language: 'Japanese',
    translation: '日本語',
    langCode: 'ja',
  },
  {
    language: 'Russian',
    translation: 'Pусский',
    langCode: 'ru',
  },
  {
    language: 'Portuguese',
    translation: 'Português',
    langCode: 'pt',
  },
  {
    language: 'Nederlands',
    translation: 'Nederlands',
    langCode: 'nl',
  },
  {
    language: 'Swedish',
    translation: 'Svenska',
    langCode: 'sv',
  },
  {
    language: 'Italian',
    translation: 'Italiano',
    langCode: 'it',
  },
  {
    language: 'Chinese',
    translation: '中国',
    langCode: 'zh',
  },
  {
    language: 'Dutch',
    translation: 'Deutsch',
    langCode: 'de',
  },
  {
    language: 'Polish',
    translation: 'Polski',
    langCode: 'pl',
  },
  {
    language: 'Turkish',
    translation: 'Türk',
    langCode: 'tr',
  },
]

const languageCodes = i18nLanguages.map((language) => language.langCode).sort()

module.exports = {
  i18nLanguages,
  languageCodes,
}
