import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

import Container from '@components/Container'
import { LinkTextButton, LinkTextButtonSize } from '@components/LinkTextButton/LinkTextButton'
import MoreOptions from '@components/MoreOptions/MoreOptions'
import SelectV2 from '@components/SelectV2/SelectV2'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { detectEmailType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import { CommonComposerTab } from '@utils/composer/commonComposer/CommonComposer.context'
import { ComposerHistoryState, EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import {
  NOT_PERSONALIZED_FROM_ADDRESS_FALLBACK_SENDER_VALUE,
  PERSONALIZED_FROM_ADDRESS_OPTION_VALUE,
  SALESFORCE_OWNER_OPTION_VALUE,
  SAME_AS_SENDER_OPTION_VALUE,
} from '@utils/composer/settings.constants'
import { useTranslation } from '@utils/const/globals'
import useCRM, { CRMConnectorType } from '@utils/hooks/useCRM'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'

import { findSenderOption, getSenderOptions } from './EmailComposerSettingsSender.utils'
import {
  DYNAMIC_SENDERS_URL,
  emailSettingsSelectProps,
  emailSettingsTypographyProps,
  renderValidation,
  SettingsFormChangeHandler,
} from '../../EmailComposerSettings.utils'

interface EmailComposerSettingsSenderProps {
  className?: string
  dataTest?: string
  onFieldChange: SettingsFormChangeHandler
  senderReadOnly?: boolean
}

const rootClass = 'email-composer-settings-sender'

const EmailComposerSettingsSender: FC<EmailComposerSettingsSenderProps> = (props: EmailComposerSettingsSenderProps) => {
  const {
    values: {
      emailSenders,
      message: { sender, senderUUID, replyToSender, fallbackSender },
      validations: {
        settingsValidations: { alwaysShowInlineValidations, listsIncompatibleWithPersonalizedFrom, listsIncompatibleWithSalesforceOwner },
      },
      messageConfiguration: {
        messageType,
        settings: { disableReplyTo, disableSender },
      },
    },
    api: { updateValidations },
  } = useContext(EmailComposerContext)
  const { onFieldChange, className = '', dataTest = className, senderReadOnly } = props
  const { t } = useTranslation()
  const { connectorType } = useCRM()
  const { personalizedFrom, userId, userName, userEmail } = useAccountSettings()

  const history = useHistory<ComposerHistoryState>()
  const userVisitedReview = history.location.state?.visitedTabs?.[CommonComposerTab.REVIEW]

  const { isEmailBlankMessage } = detectEmailType(messageType)

  const [isDirty, setDirtyState] = useState({
    sender: userVisitedReview || alwaysShowInlineValidations.sender,
    fallbackSender: false,
    replyToSender: false,
  })
  const updateDirty = useDeepUpdate(setDirtyState)
  const setDirty: typeof updateDirty = (values) => {
    if (values.sender) {
      updateValidations({ settingsValidations: { alwaysShowInlineValidations: { sender: true } } })
    }
    updateDirty({ ...values })
  }

  const senderOptions = useMemo(
    () =>
      getSenderOptions(
        emailSenders,
        { value: userId, label: userName || userEmail, subText: userName ? userEmail : undefined },
        connectorType === CRMConnectorType.SALESFORCE,
        personalizedFrom,
        t
      ),
    [emailSenders, userId, userEmail, userName, connectorType, personalizedFrom, t]
  )
  const sameAsSender = useMemo(
    () => senderOptions.ReplyTo[0]?.options.find((option) => option.value === SAME_AS_SENDER_OPTION_VALUE),
    [senderOptions]
  )
  const isPersonalizedFrom = senderUUID === PERSONALIZED_FROM_ADDRESS_OPTION_VALUE
  const isSalesforceOwner = senderUUID === SALESFORCE_OWNER_OPTION_VALUE

  const incompatibleSenderErrorText =
    isSalesforceOwner && !!listsIncompatibleWithSalesforceOwner.length
      ? 'EmailComposer.Settings.Sender.Salesforce.Incompatible'
      : isPersonalizedFrom && !!listsIncompatibleWithPersonalizedFrom.length
      ? 'EmailComposer.Settings.Sender.Personalized.Incompatible'
      : ''

  const otherSenderErrorText = !sender ? 'EmailComposer.Settings.Sender.Missing' : ''

  const showIncompatibleSenderError = !isEmailBlankMessage && !!incompatibleSenderErrorText
  const showOtherSenderError =
    !isEmailBlankMessage && isDirty.sender && (userVisitedReview || alwaysShowInlineValidations.sender) && !!otherSenderErrorText

  useEffect(() => {
    if (senderReadOnly) {
      onFieldChange('sender', userEmail)
      onFieldChange('senderUUID', userId)
    }
  }, [onFieldChange, senderReadOnly, userEmail, userId])

  return (
    <Container data-test={dataTest}>
      {!senderReadOnly ? (
        <>
          <div className={`${className}__subsection`}>
            <Tooltip
              hide={!disableSender}
              position={'top'}
              sideOffset={-30}
              trigger={
                <SelectV2
                  hideContainerTooltip={!!disableSender}
                  label={t('EmailComposer.Settings.Sender.Label')}
                  placeholder={t('EmailComposer.Settings.Sender.NoSelection')}
                  onChange={(option) => {
                    onFieldChange('sender', option?.value)
                    onFieldChange('senderUUID', option?.value)
                    setDirty({ sender: true })
                    if (option?.value === PERSONALIZED_FROM_ADDRESS_OPTION_VALUE) {
                      if (fallbackSender === NOT_PERSONALIZED_FROM_ADDRESS_FALLBACK_SENDER_VALUE) {
                        onFieldChange('fallbackSender', userId)
                      }
                    }
                  }}
                  isDisabled={disableSender}
                  onFocus={() => setDirty({ sender: false })}
                  onBlur={() => setDirty({ sender: true })}
                  onMenuClose={() => setDirty({ sender: true })}
                  groupedOptions={senderOptions.Sender}
                  {...emailSettingsSelectProps}
                  isRequired={!isEmailBlankMessage}
                  value={findSenderOption(senderOptions.Sender, senderUUID || sender)}
                  error={showIncompatibleSenderError || showOtherSenderError}
                  dataTest={`${dataTest}-sender-select`}
                />
              }
            >
              <Typography text={t('EmailComposer.Resend.Disabled')} type={TextType.BODY_TEXT_WHITE} />
            </Tooltip>

            {showIncompatibleSenderError ? (
              renderValidation(incompatibleSenderErrorText, 'ERROR', `${rootClass}-error`)
            ) : showOtherSenderError ? (
              renderValidation(otherSenderErrorText, 'ERROR', `${rootClass}-error`)
            ) : personalizedFrom || connectorType === CRMConnectorType.SALESFORCE ? (
              <Typography
                className="flex-align-center"
                text={t('EmailComposer.Settings.Sender.Description')}
                tagComponents={{
                  TextLink: <LinkTextButton link={DYNAMIC_SENDERS_URL} size={LinkTextButtonSize.SMALL} hideIconLeft className="push-right-half" />,
                }}
                {...emailSettingsTypographyProps}
              />
            ) : null}
            {isPersonalizedFrom && (
              <MoreOptions className={`${className}__fallback-sender`}>
                <SelectV2
                  label={t('EmailComposer.Settings.Sender.Label.Fallback')}
                  placeholder={t('EmailComposer.Settings.Sender.NoSelection')}
                  onChange={(option) => onFieldChange('fallbackSender', option?.value)}
                  groupedOptions={senderOptions.Fallback}
                  {...emailSettingsSelectProps}
                  value={findSenderOption(senderOptions.Fallback, fallbackSender)}
                  dataTest={`${dataTest}-fallback-select`}
                />
                <Typography text={t('EmailComposer.Settings.Sender.Fallback.Description')} {...emailSettingsTypographyProps} />
              </MoreOptions>
            )}
          </div>
          <div className={`${className}__subsection`}>
            <Tooltip
              hide={!disableReplyTo}
              position={'top'}
              sideOffset={-30}
              trigger={
                <SelectV2
                  isDisabled={disableReplyTo}
                  label={t('EmailComposer.Settings.Sender.Label.ReplyTo')}
                  placeholder={t('EmailComposer.Settings.Sender.NoSelection')}
                  onChange={(option) => onFieldChange('replyToSender', option?.value)}
                  groupedOptions={senderOptions.ReplyTo}
                  {...emailSettingsSelectProps}
                  isRequired={!isEmailBlankMessage}
                  value={findSenderOption(senderOptions.ReplyTo, replyToSender) ?? sameAsSender}
                  isClearable={false}
                  dataTest={`${dataTest}-replyto-select`}
                  hideContainerTooltip={!!disableReplyTo}
                />
              }
            >
              <Typography text={t('EmailComposer.Resend.Disabled')} type={TextType.BODY_TEXT_WHITE} />
            </Tooltip>

            <Typography text={t('EmailComposer.Settings.Sender.ReplyTo.Description')} {...emailSettingsTypographyProps} />
          </div>
        </>
      ) : (
        <>
          <div className={`${className}__subsection-sender`}>
            <Typography
              className="push-up"
              lineHeight={LineHeight.MEDIUM}
              weight={TextWeight.MEDIUM}
              text={t('EmailComposer.Settings.Sender.Label')}
            />
            <div data-test={`${rootClass}-subsection-sender`}>
              <Typography inline={true} text={userName} />
              <Typography type={TextType.BODY_TEXT_LIGHT} inline={true} weight={TextWeight.ITALIC} text={userEmail} className="push-right" />
            </div>
          </div>
          <div className={`${className}__subsection-replyto`} data-test={`${rootClass}-subsection-reply`}>
            <Typography
              className="push-up"
              lineHeight={LineHeight.MEDIUM}
              weight={TextWeight.MEDIUM}
              text={t('EmailComposer.Settings.Sender.Label.ReplyTo')}
            />
            <Typography text={sameAsSender?.label} />
          </div>
        </>
      )}
    </Container>
  )
}

export default EmailComposerSettingsSender
