import React, { FC } from 'react'

import Button, { ButtonType } from '@components/Button'
import Caution from '@components/Caution/Caution'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { CustomLinkItemInput } from '@graphql/types/query-types'
import { SortedCustomLinkInfo } from '@src/pages/Content/Branding/tabs/CustomLink/CustomLinkContainer'

import './CustomerSupportInfo.css'

interface Props {
  info: SortedCustomLinkInfo
  showInfo: boolean
  onSetup: () => void
  dataTest?: string
}
const labels = new Map<keyof CustomLinkItemInput, string>([
  ['url', 'URL'],
  ['text', 'Text'],
  ['hint', 'Hint'],
])

const rootClass = 'custom-link__customer-support'

const CustomerSupportInfo: FC<Props> = (props) => {
  const { info, showInfo, onSetup, dataTest = rootClass } = props
  const { t } = useTranslation()
  const cautionTextLines = [{ span: t('Custom.Link.Caution.Title'), weight: TextWeight.BOLD }, { span: t('Custom.Link.Caution.Description') }]

  return (
    <div className={rootClass} data-test={dataTest}>
      {showInfo ? (
        <>
          <div className={`${rootClass}-title`}>
            <Svg name={SvgNames.customLink} className={`${rootClass}__custom-link-svg`} dataTest={`${dataTest}-custom-link-svg`} />
            <Typography text={info.find(({ key }) => key === 'text')?.value ?? ''} type={TextType.SECTION_HEADER} inline />
            <Tooltip
              align="start"
              trigger={<Svg name={SvgNames.info} type={SvgType.LARGER_ICON} className={`${rootClass}__info-svg`} dataTest={`${dataTest}-info-svg`} />}
              dataTest={`${dataTest}-tooltip`}
            >
              {t('Custom.Link.Customer.Support.Tooltip')}
            </Tooltip>
          </div>
          <div className={`${rootClass}__info`}>
            {info.map(({ key, value }) => (
              <div key={key} className={`${rootClass}__info-section`}>
                <Typography text={`${t(labels.get(key))}:`} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.MEDIUM} inline />
                <Typography text={value || `(${t('Not set')})`} type={!value ? TextType.BODY_TEXT_LIGHT : undefined} />
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <Caution textLines={cautionTextLines} className={`${rootClass}__caution`} dataTest={dataTest} />
          <Button buttonType={ButtonType.SECONDARY} className={`${rootClass}__setup-button`} dataTest={`${dataTest}-setup-button`} onClick={onSetup}>
            {t('Set up custom link')}
          </Button>
        </>
      )}
    </div>
  )
}

export default CustomerSupportInfo
