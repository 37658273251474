import React, { FC, useContext, useEffect, useState } from 'react'

import classNames from 'classnames'

import FormsAndTemplatesPickerModal, {
  FormsAndTemplatesPickerModalProps,
} from '@components/AssetPickers/FormsAndTemplatesPickerModal/FormsAndTemplatesPickerModal'
import Button, { ButtonType } from '@components/Button'
import Container from '@components/Container'
import PreviewAssetModal, { PreviewAssetModalProps, PreviewModalSize } from '@components/PreviewAssetModal/PreviewAssetModal'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { TableV2 } from '@components/TableV2/TableV2'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { FormsUpgradeModalContext } from '@src/pages/UpgradeAssistant/components/FormsUpgradeModal/context/FormsUpgradeModal.context'
import { getColumns, rootText, useRowActions } from '@src/pages/UpgradeAssistant/components/FormsUpgradeModal/FormsUpgradeModal.utils'
import { useFormsUpgradeModalRequests } from '@src/pages/UpgradeAssistant/components/FormsUpgradeModal/GraphQL/FormsUpgradeModalRequests.graphQL'
import { Form } from '@utils/forms'

import './SelectFormsStep.css'

interface SelectFormsStepProps {
  className?: string
  dataTest?: string
}

export interface SelectFormsStepState {
  showFormPicker: boolean
  formToRemove?: Form
  formToPreview?: Form
  previewHtml?: string
}
export const rootClass = 'select-forms-step'

export const SelectFormsStep: FC<SelectFormsStepProps> = (props: SelectFormsStepProps) => {
  const { dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const [state, setState] = useState<SelectFormsStepState>({ showFormPicker: false })
  const { showFormPicker, formToPreview, formToRemove, previewHtml } = state
  const {
    values: { forms, selectedSources },
    update,
  } = useContext(FormsUpgradeModalContext)

  const { getFormPreviewRequest } = useFormsUpgradeModalRequests()

  useEffect(() => {
    if (formToPreview) {
      getFormPreviewRequest({ formId: formToPreview.externalId ?? '' }).then((response) => {
        setState((prevState) => ({ ...prevState, previewHtml: response.data?.getFormPreview }))
      })
    }
  }, [formToPreview])

  useEffect(() => {
    if (formToRemove && selectedSources) {
      const selectedSourcesKey = Object.keys(selectedSources).find((source) => selectedSources[source].some(({ id }) => id === formToRemove.id))

      update({
        selectedSources: !!selectedSourcesKey
          ? { ...selectedSources, [selectedSourcesKey]: selectedSources[selectedSourcesKey].filter(({ id }) => id != formToRemove.id) }
          : selectedSources,
      })
      update({ forms: forms.filter((form) => form.id !== formToRemove.id), formFields: [] })
    }
  }, [formToRemove])

  useEffect(() => {
    if (selectedSources) {
      const selectedItems: Form[] = []
      Object.keys(selectedSources).forEach((source) => {
        selectedItems.push(...(selectedSources[source] as Form[]))
      })
      update({ forms: selectedItems, formFields: [] })
    }
  }, [selectedSources])

  const columns = getColumns()

  const rowActions = useRowActions({ setState })

  const onPickerClose = () => setState((state) => ({ ...state, showFormPicker: false }))

  const formsPickerProps: FormsAndTemplatesPickerModalProps = {
    isOpen: true,
    onSubmitCustomSources: (selectedSources) => {
      update({ selectedSources })
      onPickerClose()
    },
    onSubmit: (rows) => {
      update({ forms: rows as Form[], formFields: [] })
      onPickerClose()
    },
    preSelectedCustomSourceItems: selectedSources,
    onBack: () => onPickerClose(),
    onClose: () => onPickerClose(),
  }

  const previewAssetProps: PreviewAssetModalProps = {
    isOpen: true,
    onClose: () => setState((state) => ({ ...state, previewHtml: undefined })),
    size: PreviewModalSize.MEDIUM,
    title: formToPreview?.name ?? '',
    useTitlePrefix: false,
    previewHtml: previewHtml,
  }

  return (
    <Container className={classNames(rootClass, className)} data-test={dataTest}>
      {showFormPicker && <FormsAndTemplatesPickerModal {...formsPickerProps} />}
      {previewHtml && <PreviewAssetModal {...previewAssetProps} />}
      <Typography className={`${rootClass}__title`} text={t(`${rootText}Step.One.Title`)} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
      <Typography className={`${rootClass}__subtitle`} text={t(`${rootText}Step.One.Subtitle`)} type={TextType.BODY_TEXT_LIGHT} />
      {forms.length > 0 && (
        <Typography
          className={`${rootClass}__content-title`}
          text={t(`${rootText}Step.One.Content.Title`, { count: forms.length })}
          weight={TextWeight.MEDIUM}
        />
      )}
      <Container className={`${rootClass}__content`}>
        {forms.length > 0 ? (
          <TableV2 data={forms} enableSorting columns={columns} rowActions={rowActions} withoutBorders />
        ) : (
          <Typography
            className={`${rootClass}__content-none`}
            text={t(`${rootText}Step.One.None`)}
            type={TextType.BODY_TEXT_SMALL_LIGHT}
            weight={TextWeight.ITALIC}
          />
        )}
      </Container>
      <Button className={`${rootClass}__button`} buttonType={ButtonType.INFO} onClick={() => setState({ ...state, showFormPicker: true })}>
        <Svg className={`${rootClass}__button-svg`} name={SvgNames.createSubsegment} type={SvgType.LARGER_ICON} />
        {t(`${rootText}Step.One.Button`)}
      </Button>
    </Container>
  )
}
