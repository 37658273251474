import { SvgNames } from '@components/Svg'
import { TabItemData } from '@components/TabsAO/TabsAO'

export enum FormManagerTabs {
  DETAILS = 'details',
  REPORT = 'report',
  PERFORMANCE = 'performance',
  RESPONSES = 'responses',
}

export const getFormManagerTabStructure = (t: Function): TabItemData[] => [
  {
    index: FormManagerTabs.DETAILS,
    label: t('Details'),
  },
  {
    index: FormManagerTabs.REPORT,
    label: t('Report'),
  },
  {
    index: FormManagerTabs.PERFORMANCE,
    parent: FormManagerTabs.REPORT,
    triggerButtonOption: {
      text: t('Form Performance'),
      icon: SvgNames.graphBar,
      onClick: () => null,
    },
    label: '',
  },
  {
    index: FormManagerTabs.RESPONSES,
    parent: FormManagerTabs.REPORT,
    triggerButtonOption: {
      text: t('Form Responses'),
      icon: SvgNames.clipboardContent,
      onClick: () => null,
    },
    label: '',
  },
]
