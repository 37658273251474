import { createContext, Dispatch, ReactNode, SetStateAction } from 'react'
import { Row } from 'react-table'

import { TFunction } from 'i18next'

import { AssetPickerPreviewMetadataProps } from '@complex/AssetPickerModal/Components/Preview/AssetPickerPreviewMetadata/AssetPickerPreviewMetadata'
import { CustomRequestType, InfoBannerType } from '@complex/AssetPickerModal/Context/AssetPicker.context'
import { DeleteConfirmationModals } from '@complex/ListingPage/Components/ListingPageModals/ListingPageModals'
import { ListPageTableActions, TableActions } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.constants'
import { FilterQueryParams, MenuActions } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { ListingPageStatusToast, listPageContextCommonValues } from '@complex/ListingPage/Utils/ListingPage.constants'
import { BulkModalProps } from '@components/BulkActionsModal/BulkActionsModal'
import { CollapsibleMenuItemWithHeaderData } from '@components/CollapsibleMenu/CollapsibleMenu'
import { MenuItem } from '@components/DropDownActions/DropDownActions'
import { EmptyListingProps } from '@components/EmptyListing/EmptyListing'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { Status } from '@components/StatusToast/StatusToast'
import { SvgNames } from '@components/Svg'
import { HeaderAction, RowAction, TableColumn } from '@components/Table/Table'
import { HeaderAction as HeaderActionV2, RowAction as RowActionV2, TableV2Props, TableV2RowData } from '@components/TableV2/tableV2TS/interfaces'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { FolderDto, ItemDto, LabelDto } from '@graphql/types/microservice/categorization-types'
import { LabelDtoInput } from '@graphql/types/microservice/segment-types'
import { BulkAssetMutationResponse } from '@graphql/types/query-types'
import { Errors } from '@interface/common'
import { Folder } from '@interface/Folder'
import { Row as RowV2, SortingState } from '@tanstack/react-table'
import { ItemType } from '@utils/categorization'
import { CustomFilterDefinition, FilterDefinition, FilterTypes } from '@utils/filter'
import { FetchPromise, SortingFieldType } from '@utils/types'

import { GetFilterRequests } from '../GraphQL/Filters.graphQL'
import { GetFolderRequests } from '../GraphQL/Folders.graphQL'
import { GetSearchRequests } from '../GraphQL/Search.graphQL'

export const TABLEV2_ENABLED_LISTINGS = [
  ItemType.CUSTOM_OBJECT,
  ItemType.FORM,
  ItemType.FORM_TEMPLATE,
  ItemType.ACCOUNT,
  ItemType.LANDING_PAGE,
  ItemType.LANDING_PAGE_TEMPLATE,
  ItemType.FORM_SUBMISSION,
  ItemType.FRAGMENT,
  ItemType.SEGMENT,
  ItemType.UNSORTABLE_SEGMENT,
  ItemType.EMAIL_TEMPLATE,
  ItemType.EMAIL_DRAFT,
  ItemType.PROGRAM,
  ItemType.LIST_MAINTENANCE_PROGRAM,
  ItemType.WEBINAR_SUBMISSION,
]

export type ItemDtoWithBeeComposer = ItemDto & {
  beeComposer?: boolean
  isCustomMessage?: boolean
  isPlainTextOnly?: boolean
}

export type Update = (fields: Partial<ListPageCommonState>) => void
export type SetItems = (items: ItemDto[]) => void
export type FetchItems = VoidFunction
export type OnSetFavoriteItems = (selectedItems?: ItemDto[]) => void
export type SearchItems = (searchAll: boolean) => void
export type ApplyAndRemoveTags = (selectedIds: number[], tagsToApply: LabelDto[], tagsToRemove: number[]) => void
export type SearchItemsByCustomFilter = (field: string, query: string) => void
export type RenderSearchColumnsV2 = (
  searchInAllItems: boolean | undefined,
  currentFolder: FolderData,
  search: string,
  folders: FolderData[],
  selectedCustomSource?: CustomSource
) => ColumnDefWithAdditionalProps<ItemDtoRow, any>[]
export type SetFilter = (filter: FilterDefinition, customFilterSelected: boolean, clearSubTypes?: boolean) => void
export type SetError = (message: string | ReactNode, error: any) => void

export interface DuplicateListingPageItem {
  listingPageItem: ListingPageItem
  newName: string
  tags: LabelDto[]
  folderId?: number
  includeRecipients?: boolean
  includeSuppressionRules?: boolean
  includeUnPublishedChanges?: boolean
}

export interface GetSidebarMenuItemsParams {
  values: ListPageCommonState
  t: TFunction
  menuActions: MenuActions
  defaultOpenedFolders: number[]
  clearFoldersToExpand?: VoidFunction
}

export type GetSidebarMenuItems = (params: GetSidebarMenuItemsParams) => Partial<CollapsibleMenuItemWithHeaderData>[]
export type ListPageCommonContext = ListPageCommonContextAPI<ListPageCommonState>
export type ListPageAPI = Omit<ListPageCommonContextAPI<ListPageCommonState>, 'values'>

export type FilterCounts = {
  [id: string]: number | undefined
}

export interface RenderCustomFiltersParams {
  activeFilter: FilterDefinition
  activeSubTypes: string[]
  defaultSubTypes: string[]
  filterCounts: FilterCounts
  menuActions: MenuActions
  renderCustomFilterWithCount: (children: JSX.Element, count?: number, key?: string | number) => JSX.Element
  t: TFunction
}

export interface SidebarProps {
  sidebarHeader: string
  hideAllItem?: FilterTypes
  hasRecent: boolean
  hasRecommended?: boolean
  hasCreatedByMe: boolean
  hasFavorites?: boolean
  hideFilterCount?: boolean
  hideFolders?: boolean
  hideTags?: boolean
  hasSalesUsersAction?: boolean
  allItemFilter: FilterDefinition
  /** Allows adding extra filters in the top section of the sidebar, and adjusting the positions of default filters */
  customDefaultFilters?: CustomFilterDefinition[]
  /** Allows adding extra filters in the top section of the sidebar, and adjusting the positions of default filters. Provides an extra filtering function for items */
  getCustomDefaultFilters?: (menuActions: MenuActions) => CustomFilterDefinition[]
  customFilterSelected?: boolean
  renderCustomFilters?: (params: RenderCustomFiltersParams) => CollapsibleMenuItemWithHeaderData[]
  customSources?: CustomSource[]
  getCustomFilterCounts?: () => Promise<FilterCounts>
}

export interface ListingPageItem extends ItemDto {
  name?: string
  tags?: LabelDto[]
  externalId?: string
  beeComposer?: boolean
  recordsCount?: number
}

export interface SortBy {
  id: string
  desc: boolean
  fieldType?: SortingFieldType
}

export interface TableProps {
  hasAutoSelectedRows: boolean
  hasExpander: boolean
  buildSubRows?: (items: any[]) => any[]
  columns: TableColumn[] | ColumnDefWithAdditionalProps<any>[]
  renderSearchColumns: (
    searchInAllItems: boolean | undefined,
    currentFolder: FolderData,
    search: string,
    folders: FolderData[],
    selectedSource?: CustomSource
  ) => any[] // change type once all tables get converted to V2
  listPage: string
  headerActionCustomProps: ListPageTableActionCustomProps[]
  rowActionCustomProps: ListPageTableActionCustomProps[]
  onCustomTableAction?: (customAction: any, update: Update, listPageValues: ListPageCommonState, selectedItem?: ItemDto) => void
  getCustomHeaderActions?: (tableActions: TableActions, selectedRows: ListingPageItem[], standardActionsCount: number) => HeaderAction[]
  getCustomRowActions?: (tableActions: TableActions) => RowAction[]
  clickableColumnOptions?: { colIndex: number; action: Partial<ListPageCommonState> }
  actonAssetType?: string
  shareModalText?: string
  onRowClicked?: (row: Row<any>) => void
  headerCheckboxDisabled?: boolean
  rowDisabled?: (row: RowV2<any>) => boolean
  rowTooltip?: (row: RowV2<any>) => string | ReactNode
  isRowSelectionDisabled?: (row: RowV2<any>) => boolean
  rowDisabledTitle?: string
  hasDisabledRowStyles?: boolean
  enableLazyLoading?: boolean
  onLoading?: VoidFunction
}

export type ItemDtoRow = ItemDto & TableV2RowData<ItemDto> & { disabled?: boolean; beeComposer?: boolean }

export type TablePropsV2 = Omit<TableProps, 'columns' | 'renderSearchColumns' | 'getCustomHeaderActions' | 'getCustomRowActions'> & {
  columns: ColumnDefWithAdditionalProps<ItemDtoRow, any>[]
  renderSearchColumns: (
    searchInAllItems: boolean | undefined,
    currentFolder: FolderData,
    search: string,
    folders: FolderData[],
    selectedCustomSource?: CustomSource
  ) => ColumnDefWithAdditionalProps<ItemDtoRow, any>[]
  onCustomTableAction?: (customAction: any, update: Update) => void
  getCustomHeaderActions?: (tableActions: TableActions, selectedRows: ListingPageItem[], standardActionsCount: number) => HeaderActionV2[]
  getCustomRowActions?: (tableActions: TableActions, canCreate: boolean) => RowActionV2[]
  sortingBy?: SortingState
}

export interface ListPageTableActionCustomProps {
  name: ListPageTableActions | string
  position: number
  hasTopSection?: boolean
  isInDropdown?: boolean
}

export interface PageHeaderProps {
  pageTitle: ReactNode
  renderPageHeaderContent?: (update: Update) => ReactNode
  pageHeaderClassName?: string
}

export interface TabProps {
  text: string
  url: string
  isSelected: boolean
}

export interface GetFilterItemsWithCustomRequests {
  customRequestFilters: CustomRequestFilter[]
  sessionFilter?: FilterDefinition
}

export type FilterInfoHoverText = Partial<{ [key in FilterTypes]: string }>
export type CustomFilter = {
  field: string
  values: {
    id: string
    value: string
  }[]
}

export type OnItemsFetched = (items: ItemDto[], setError: SetError) => void

export type SetStatusToast = (message: string | ReactNode, status: Status, folderName?: string) => void

export type ItemTypesUsedCount = Partial<Record<ItemType, number>>

export interface ListingPageSubType {
  /** Name in the categorization service */
  name: string
  /** Display name */
  label: string
  /** Requires items to have both this subType AND other active subTypes  */
  hasAndCondition?: boolean
  /** Toggling this subType should behave like a top-level filter
   * e.g. clears other subTypes when selected, clears itself when folder/tag selected,
   * shows as a filter in search results, toggles search all items off, etc.
   */
  behaveAsFilter?: FilterDefinition
  tooltip?: string
}

export interface ListingPageExternalApi {
  updateFilterCounts: (counts: FilterCounts) => void
}

export interface ListingPageProps {
  /*
    RENDERING
  */
  /** Callback for a custom empty listing based on which filter is selected */
  getCustomEmptyListingProps: (
    setFilter: (filterDefinition: FilterDefinition, customFilterSelected: boolean) => void,
    update: Update,
    filter?: FilterDefinition,
    openTemplateModal?: Dispatch<SetStateAction<boolean>>
  ) => EmptyListingProps | undefined
  /** Overrides the empty listing for the 'default' filters (All Items, Created By Me, etc.) */
  getCustomDefaultEmptyListingProps?: (filter: ListPageCommonState, setFilter: SetFilter, t: Function) => EmptyListingProps | undefined
  /** Used to translate a clicked custom filter ID into a filter definition */
  getCustomActiveFilter?: (filter: string) => FilterDefinition | undefined
  renderCustomModal?: (customTableAction: any, listPageValues: ListPageCommonState, listPageAPI: ListPageAPI, errorMessage?: string) => ReactNode
  renderDataCards?: () => ReactNode

  /*
    GRAPHQL ENDPOINT HELPERS
  */
  getPreviewMenuActions?: (item: ListingPageItem) => MenuItem[]
  /** Endpoint to call when deleting an item. */
  customDeleteItemsCall?: (items: ItemDto[]) => FetchPromise<any>
  /** Displays a `BulkActionsModal` after items have been deleted indicating itemized successes and failures. For this to work correctly the endpoint must return a `BulkAssetMutationResponse` */
  isDeleteCallWithBulkResponse?: boolean
  customDuplicateItem?: (params: DuplicateListingPageItem, listPageAPI: ListPageAPI) => void
  customPreviewItemCall?: (listPageValues: ListPageCommonState, update: Update, setError: SetError) => void
  /** Specifies which fields to filter by for a custom filter */
  getCustomFilterParams?: (activeFilter?: FilterDefinition) => { field: string; query: string }
  /** Filters that use non-categorization service endpoints */
  customRequestFilters?: CustomRequestFilter[]
  hasCustomRequests?: boolean
  searchFields?: string[]
  customFilters?: CustomFilter[]
  /** List of names/labels for the subTypes so that they can show up in some common interfaces like the search header */
  subTypes?: ListingPageSubType[]
  /** Default sort for the table */
  sortBy?: SortBy[]
  /** For scenarios when we have an asset/asset template combo where we need to be able to convert one to the other */
  hasSecondaryFolders?: boolean
  /** For scenarios when we have an asset/asset template combo where we need to be able to convert one to the other */
  hasSecondaryTags?: boolean
  /** For scenarios when we have an asset/asset template combo where we need to be able to convert one to the other */
  secondaryItemType?: ItemType

  /*
    BEHAVIOR OVERRIDES
  */
  /** Overrides the allItemsFilter as the default filter that gets selected */
  initialFilter?: FilterDefinition
  /** Do not automatically clear the search term when switching between different filters */
  alwaysPreserveSearchTerm?: boolean
  /** These subTypes IDs are always applied and do not show up in the sidebar */
  defaultSubTypes?: string[]
  /** The listing page will not restore the state that it was in last time the page was loaded, including selected filter and search term */
  disableSessionData?: boolean
  /** These subType ids will be toggled on when the listing page mounts but can be toggled off */
  defaultActiveSubTypes?: string[]
  /** Folders in the sidebar do not show any options to add, delete, rename, or move items into folders */
  readOnlyFolders?: boolean

  /*
    MAJOR ELEMENT PROPS
  */
  sidebarProps: SidebarProps
  pageHeaderProps: PageHeaderProps
  tableProps: TableProps
  tabs?: TabProps[]
  hasTabs?: boolean

  /*
    PERMISSIONS
  */
  canDeleteItems?: boolean
  hideDeleteItems?: boolean
  hideMoveItems?: boolean
  canCreate?: boolean
  canEdit?: boolean
  canShareToCatalog?: boolean
  canShareToChildAccounts?: boolean
  shareToChildAccountsIcon?: SvgNames
  disableShareToChildAccountsTooltip?: string
  canPreview?: boolean
  canDuplicate?: boolean
  disableShareToAccounts?: boolean
  disableDuplicate?: boolean

  /*
    EVENT TRIGGERS
  */
  /** Triggers current items to re-fetch */
  doFetchItems?: boolean
  /** Forces a loading state while external data is loaded by the top-level Listing Page */
  externalDataLoading?: boolean

  /*
    EVENT HOOKS
  */
  /** Callback that informs the top-level listing page which filter has been selected */
  onFilterChanged?: (filter?: FilterDefinition) => void
  /** Triggers every time new items are done fetching */
  onItemsFetched?: OnItemsFetched
  /**
   * Async Method that is called on every page load (items fetch), and receives the fetched items as a parameter.
   * It is intended to add extra data to items, data that is not fetched from the categorization service
   * e.g. adding CRM push errors to Forms submissions on Form submissions listing page.
   */
  addCustomData?: (items: ItemDto[]) => Promise<ItemDto[]>
  /** Triggers when the window receives a message event */
  onWindowMessage?: (event: MessageEvent<any>) => void
  /** Triggers when the window receives focus or the browser tab activates */
  onWindowActive?: (event: Event, update: Update, setStatusToast: SetStatusToast, items: ItemDto[]) => void
  /** Triggers when the listing page first renders */
  onPageLoad?: (setStatusToast: SetStatusToast, update?: Update) => void
  /** Returns some methods that can be used to update a targeted part of the listing page state */
  onApiAvailable?: (api: ListingPageExternalApi) => void

  /*
    INFORMATIVE ELEMENTS
  */
  helpSectionLink?: string
  showHelpSection?: boolean
  filterInfoHoverText?: FilterInfoHoverText
}

export interface ListPageCommonContextAPI<T> {
  setItems: SetItems
  update: (fields: Partial<ListPageCommonState>) => void
  getFolderItems: FetchItems
  getItemTypesUsedInTags: (tagId: number) => Promise<ItemTypesUsedCount>
  getTagItems: FetchItems
  getFilterItems: FetchItems
  getFilterItemsWithCustomRequests: (requests: GetFilterItemsWithCustomRequests) => void
  createFolder: (folder: FolderDto) => void
  renameFolder: (folder: FolderDto) => void
  deleteFolder: (folder: FolderData) => void
  createTag: (tag: LabelDtoInput) => void
  deleteTag: (tag: LabelDto) => void
  onClickTagInRow: (tag: string) => void
  currentTag?: LabelDto
  values: T
  setFilter: SetFilter
  toggleSubType: (subType: string, toggleOffSubTypes?: string[], filterActive?: FilterDefinition) => void
  setFolder: (folderId: number) => void
  setTag: (tag: LabelDto, clicked?: boolean) => void
  setSelectedCustomSource?: (customSource: CustomSource) => void
  setStatusToast: (message: string | ReactNode, status: Status, folderName?: string, hasTimeout?: boolean) => void
  setBulkResponseModal: (bulkResponse: BulkAssetMutationResponse, bulkActionKey?: string, customProps?: Partial<BulkModalProps>) => void
  setError: SetError
  onSearch: (isInAllFolders: boolean) => void
  moveItemsToFolder: (folderId: number) => void
  removeItemsFromFolder: () => void
  shareToCatalog: () => void
  deleteItems: (items: ItemDto[]) => void
  duplicateItem: (params: DuplicateListingPageItem, listPageAPI: ListPageAPI) => void
  onColumnSort: (sortBy: SortBy[]) => void
  rowDisabled?: TableV2Props<T>['rowDisabled']
  isRowSelectionDisabled?: TableV2Props<T>['isRowSelectionDisabled']
  onShouldFetch: () => void
}

export interface CustomRequestFilter {
  filter: FilterDefinition
  request: (currentPage?: number, params?: FilterQueryParams) => CustomRequestType
  searchRequest: (query: string, currentPage?: number, params?: FilterQueryParams) => CustomRequestType
}

/** Enables a mix of multiple categorization and non-categorization data endpoints that the user can choose from */
export type CustomSource = {
  /** The default filter item to be selected when switching to this source */
  allItemFilter?: FilterDefinition
  /** Non-categorization service endpoints to use for fetching filtered asset data */
  customRequestFilters?: CustomRequestFilter[]
  /**
   * Non-categorization service endpoints to use for fetching data not related to fetching assets in filters (folders, counts, etc.).
   * Note that the request must take the same parameters and return the same type as the categorization requests so that they can seamlessly be substituted
   */
  customRequestGeneral?: {
    getCountQueryRequest?: GetFilterRequests['getCountQueryRequest']
    getSubTypesByTypesRequest?: GetFilterRequests['getSubTypesByTypesRequest']
    getCountForFavoritesAndCreatedByMeAndRecentRequest?: GetFilterRequests['getCountForFavoritesAndCreatedByMeAndRecentRequest']
    getAllFoldersRequest?: GetFolderRequests['getAllFoldersRequest']
    getItemsInFolderRequest?: GetFilterRequests['getItemsInFolderRequest']
    searchItemsRequest?: GetSearchRequests['searchItemsRequest']
    getCustomFilterItemsRequest?: GetFilterRequests['getCustomFilterItemsRequest']
  }
  /** Info banner to display for the source above the table */
  filterInfoBanner?: (source: CustomSourceItems, filterActive: FilterDefinition, t: TFunction) => { [key: string]: InfoBannerType }
  /** Info block to display for the source at the bottom of the sidebar */
  filterInfoSidebarBlock?: () => ReactNode
  /** Used for some item-specific text that appears in the picker (e.g. 'Search templates') */
  itemName?: string
  /** Placeholder for the search input. By default the itemName is transformed to lowercase for this but we may need an override for proper nouns */
  searchPlaceholder?: string
  /** Text for how the source displays in the source dropdown */
  label: string
  /** Overrides the name of the first column in the table */
  mainColumnName: string
  /** Unique identifier for the source */
  value: string
  /** Does not display in dropdown, source is triggered by a filter instead */
  filterTrigger?: FilterDefinition
  /** Listing props that needs to be applied immediately when this source is selected when in a combined picker before fetching items */
  listingPageProps?: Partial<Pick<ListingPageProps, 'defaultActiveSubTypes' | 'defaultSubTypes'>>
  /** Sidebar props that needs to be applied immediately when this source is selected when in a combined picker before fetching items */
  sidebarProps?: Partial<Pick<SidebarProps, 'hideTags' | 'hideFolders'>>
  /** Overrides the item type which controls categorization endpoint parameters */
  itemType?: string
}

export type ExtendedItemDto = ItemDto & { name?: string; count?: number; disabled?: boolean }
export type CustomSourceItems = { [key: string]: ExtendedItemDto[] }

export type ListPageCommonState = {
  items: ItemDto[]
  customSourceItems?: CustomSourceItems
  customSources?: CustomSource[]
  selectedCustomSource?: CustomSource
  onSelectChange?: (item: CustomSource) => void
  itemTypesUsed: ItemTypesUsedCount
  actionSelectedRow: any[]
  itemsId: number[]
  creatingTag: boolean
  showShareToCatalog: boolean
  creatingFolder: boolean
  isEditingFolder: boolean
  addToFolder: boolean
  isEditingTag: boolean
  initialPageLoading: boolean
  showDeleteConfirmationModal: boolean
  fetchTags: boolean
  fetchItems: boolean
  fetchNextPageItems: boolean
  fetchFolders: boolean
  fetchFilterCounts: boolean
  itemsToMove: ItemDto[]
  selectedRows: ListingPageItem[]
  selectedRowsRestore: ListingPageItem[]
  restoreRowsOnModalClose?: boolean
  selectedIds: number[]
  canShareToCatalog: boolean
  canDeleteItems: boolean
  hideDeleteItems?: boolean
  hideMoveItems?: boolean
  canCreate: boolean
  canEdit: boolean
  showCustomModal: boolean
  refetchCountsOnAction: boolean
  customTableAction: string
  filterActive: FilterDefinition | undefined
  activeSubTypes: string[]
  defaultSubTypes: string[]
  filterCounts: FilterCounts
  folders: Folder[]
  tags: LabelDto[]
  deleteConfirmationData: any
  loading: boolean
  pageError: boolean
  folderPath: FolderData[]
  canShareToChildAccounts: boolean
  disableShareToChildAccountsTooltip: string
  saved: boolean
  search: string
  searchFields: string[]
  searchItemsResults: ExtendedItemDto[]
  searchItemsLoading: boolean
  isProcessingAction: boolean
  movingItem: boolean
  showManageTag: boolean
  statusToast: ListingPageStatusToast
  currentPage: number
  allItemsLoaded: boolean
  infoHoverLoading: boolean
  listingPageProps: ListingPageProps
  dataTest: string
  emptyListingProps: EmptyListingProps
  hasToExpandFolders: number[]
  sortBy: SortBy[]
  previewHtml?: string
  previewUrl?: string
  renderPreview?: () => ReactNode
  previewMetadata?: AssetPickerPreviewMetadataProps
  confirmationModal?: DeleteConfirmationModals
  showRemoveFromFolder?: boolean
  forceResetSelectedRows?: boolean
  showPreview?: boolean
  showMoveToFolder?: boolean
  showShare?: boolean
  showDuplicate?: boolean
  hoveredFolder?: FolderData
  tagToEdit?: LabelDto
  itemAssetToDelete?: ItemDto[]
  itemToClone?: ItemDto
  itemsToDelete?: ItemDto[]
  itemType?: ItemType
  activeFolderId: number | undefined
  secondaryFolders?: Folder[]
  secondaryTags?: LabelDto[]
  activeTagId?: number
  searchAll?: boolean
  errors?: Errors
  readOnlyFolders: boolean
  showSalesUsersControls?: boolean
  showSalesUsersEnabled?: boolean
  showBackButtonOnFormsPreviewModal?: boolean
  isSalesUser: boolean
  hasCustomRequests?: boolean
  /** When the table mounts it triggers an onSort() callback, this prevents the initial onSort() from causing another fetch */
  isTableSortInitialized: boolean
  errorMessage?: string
  showBulkResponse?: boolean
  bulkResponseProps?: BulkModalProps
}

const values: ListPageCommonState = {
  ...listPageContextCommonValues,
}

export const ListingPageCommonContext = createContext<ListPageCommonContext>({ values } as ListPageCommonContext)
