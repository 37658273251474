import React, { FC } from 'react'

import classNames from 'classnames'

import PageContainer from '@components/PageContainer'
import PageHeader from '@components/PageHeader'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import SingleSelectDropdown from '@components/SingleSelectDropdown/SingleSelectDropdown'
import StatusToast, { Status } from '@components/StatusToast/StatusToast'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgNamesStrings } from '@components/Svg/SvgNames'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './SubscriptionCategoryMappings.css'

interface Props {
  className?: string
  dataTest?: string
  mappings?: Mapping[] | undefined
  childCategories?: Subscription[] | undefined
  parentCategories: Subscription[] | undefined
  onSubmit: (childCategory: any, parentId: number) => void
  clearToast: () => void
  toastMessage: string
  toastSuccess: boolean
  status: Status
}

export interface Mapping {
  childCategoryId: number
  parentCategoryId: number
}

export interface Subscription {
  accountId: number
  classicId: number
  deleted: boolean | undefined
  description?: string | null
  id: number
  subscriptionCategory: any
  name?: string
  isDisabled?: boolean
  title?: string
}

const rootClass = 'subscription-category-management'

const SubscriptionCategoryMappings: FC<Props> = (props: Props) => {
  const {
    dataTest = rootClass,
    className = '',
    parentCategories,
    childCategories,
    mappings,
    onSubmit,
    clearToast,
    toastSuccess,
    toastMessage,
    status,
  } = props
  const { t } = useTranslation()

  const transformedChildCategories = childCategories?.map((child) => {
    const isLinked = mappings?.some((map) => map.childCategoryId === child.id)
    return {
      ...child,
      value: child.id.toString(),
      disabled: isLinked,
      disabledTooltip: t('This field has already been mapped'),
      label: child.subscriptionCategory,
    }
  })

  const parentCategoriesTransformed = parentCategories?.map((parent) => {
    const isLinked = mappings?.some((map) => map.parentCategoryId === parent.id)
    const title = isLinked ? parent.subscriptionCategory : 'Select Category'
    const svgType = isLinked ? 'linked' : 'unlinked'

    return { ...parent, isDisabled: isLinked, svgType, title, name: parent.subscriptionCategory }
  })

  const matchingObject = (newObj: any) => {
    return parentCategoriesTransformed?.find((item) => {
      return item.id === newObj.id
    })
  }
  const renderParentCats = () => {
    const parentCats = parentCategoriesTransformed?.map((parent) => {
      return (
        <div className={`${rootClass}__data-container`} key={`parent-${parent.id}`}>
          <div key={`parents-${parent.id}`} className={`${rootClass}__parents`}>
            <Typography text={`${parent.subscriptionCategory}`} />
            <Typography text={`${parent.description ?? ''}`} type={TextType.BODY_TEXT_SMALL} className={`${rootClass}__category-desc`} />
          </div>

          <div className={`${rootClass}__children`} key={`children-${parent.id}`}>
            <Tooltip
              className={`${rootClass}__tooltip`}
              trigger={
                <Svg
                  name={SvgNames[matchingObject(parent)?.svgType as SvgNamesStrings]}
                  type={SvgType.LARGER_ICON}
                  dataTest={`${matchingObject(parent)?.svgType}-icon`}
                />
              }
            >
              {parent.isDisabled ? 'Linked' : 'Unlinked'}
            </Tooltip>
            <SingleSelectDropdown
              options={transformedChildCategories ?? []}
              onSubmit={(_, selectedChild) => onSubmit(selectedChild?.id, parent.id)}
              placeholder="Select Category"
            />
          </div>
        </div>
      )
    })
    return (
      <>
        <div className={`${rootClass}__category-headers`}>
          <Typography text={t('Parent Categories')} className={`${rootClass}__parent-categories-header`} weight={TextWeight.BOLD} />
          <Typography text={t('Child Categories')} className={`${rootClass}__child-category-header`} weight={TextWeight.BOLD} />
        </div>
        {parentCats}
      </>
    )
  }

  return (
    <PageContainer className={classNames(rootClass, className)} data-test={dataTest}>
      {toastMessage && <StatusToast message={toastMessage} closeStatus={clearToast} isSuccess={toastSuccess} status={status} />}
      <PositionContainer>
        <div>
          <PageHeader linkBack leftContent primaryText={t('Link Subscription Management Categories')} dataTest={rootClass} />
          <Typography
            text={t(
              'Link your subscription categories to your parent accounts categories. This will help you maintain accurate contact preferences data when syncing with your parent accounts Subscription Management List.'
            )}
            className={`${rootClass}__page-description`}
          />
        </div>
        <div className={`${rootClass}__categories-container`}>
          <Typography text={t('Subscription Management')} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.MEDIUM} />
          <div>{renderParentCats()}</div>
        </div>
      </PositionContainer>
    </PageContainer>
  )
}

export default SubscriptionCategoryMappings
