import React, { FC, useEffect, useMemo } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import HoverSVG from '@components/HoverSVG/HoverSVG'
import Input from '@components/Input/Input'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { InputDefinition } from '@graphql/types/microservice/webhooks-incoming-management-types'

import './CustomTouchPointBehaviorsMappingField.css'

export interface BehaviorField {
  label: string
  placeholder: string
  value: string
}

interface CustomTouchPointBehaviorsMappingFieldProps {
  field: BehaviorField
  index: number
}

const rootClass = 'custom-touch-point-behaviors-mapping-field'
const CustomTouchPointBehaviorsMappingField: FC<CustomTouchPointBehaviorsMappingFieldProps> = ({
  field: { label, placeholder, value },
  index,
}: CustomTouchPointBehaviorsMappingFieldProps) => {
  const {
    control,
    formState: { errors },
    register,
    setValue,
  } = useFormContext<InputDefinition>()
  const { fields: webhookMappings } = useFieldArray({
    control,
    name: 'contentMappings',
  })
  const { t } = useTranslation()

  const defaultValue = useMemo(() => webhookMappings.find(({ sourceField }) => value === sourceField)?.destinationField, [webhookMappings])

  useEffect(() => {
    setValue(`contentMappings.${index}.destinationField`, value)
  }, [])

  return (
    <div className={rootClass} data-test={rootClass}>
      <div className={`${rootClass}__field`}>
        <Typography className={`${rootClass}__field-name`} text={label} weight={TextWeight.MEDIUM} />
        <div className={`${rootClass}__field-arrow-container`}>
          <Svg name={SvgNames.arrowRight} type={SvgType.LARGER_ICON} />
        </div>
      </div>
      <Input
        className={`${rootClass}__input`}
        defaultValue={defaultValue}
        error={!!(errors?.contentMappings ?? [])[index]}
        placeholder={placeholder}
        register={register(`contentMappings.${index}.sourceField`)}
      />
      <Tooltip
        triggerClassName={`${rootClass}__info-tooltip`}
        trigger={
          <HoverSVG
            svg={SvgNames.info}
            hoverSvg={SvgNames.infoHover}
            fill={SvgColor.LIGHT_GRAY}
            hoverFill={SvgColor.TEXT_TEAL}
            type={SvgType.LARGER_ICON}
          />
        }
      >
        {t(label)}
      </Tooltip>
    </div>
  )
}

export default CustomTouchPointBehaviorsMappingField
