import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import Container from '@components/Container'
import Loader from '@components/Loader'
import PageContainer from '@components/PageContainer'
import PageHeader from '@components/PageHeader'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import getPurgatoryCSVURL from '@graphql/microservices/entity-upload/getPurgatoryCSVURL'
import { GetPurgatoryCsvurlQuery, GetPurgatoryCsvurlQueryVariables } from '@graphql/types/microservice/entity-upload-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'

import './FileDownload.css'

type Props = {
  className?: string
  dataTest?: string
}

const rootClass = 'file-download'

const FileDownload: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '' } = props
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.ENTITY_UPLOAD })

  const [showError, setShowError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [downloadUrl, setDownloadUrl] = useState<string>()

  const { t } = useTranslation()

  const resolveUrl = (fileId: string, isDecoded: boolean) => {
    client
      .query<GetPurgatoryCsvurlQuery, GetPurgatoryCsvurlQueryVariables>({
        query: getPurgatoryCSVURL,
        fetchPolicy: 'network-only',
        variables: {
          fileId,
          isDecoded,
        },
      })
      .then((response) => {
        setDownloadUrl(response.data.getPurgatoryCSVURL ?? '')
        setLoading(false)
      })
      .catch(() => {
        setShowError(true)
        setLoading(false)
      })
  }

  useEffect(() => {
    const search = new URLSearchParams(window.location.search)
    const fileId = search.get('fileId')
    const isDecoded = search.get('isDecoded')

    if (fileId) {
      resolveUrl(fileId, !!isDecoded)
    } else {
      setShowError(true)
      setLoading(false)
    }
  }, [])

  if (loading) {
    return <Loader center />
  }

  return (
    <PageContainer className={classNames(rootClass, className)} dataTest={dataTest}>
      <PositionContainer noOverflow>
        <PageHeader primaryText={t('File Download')} />
        <Container className={`${rootClass}__container`}>
          {showError && <Typography text={t('The fileId was not specified or the service could not be accessed.')} />}
          {!showError &&
            (!downloadUrl ? (
              <div className={`${rootClass}__text`}>
                <Typography text={t('The file that you requested cannot be downloaded. Possible reasons:')} />
                <Typography text={`\u2022 ${t("It's not longer available")}`} className={`${rootClass}__text-bullet`} />
                <Typography text={`\u2022 ${t("It doesn't belong to the current account")}`} className={`${rootClass}__text-bullet`} />
              </div>
            ) : (
              <>
                <div className={`${rootClass}__text`}>
                  <Typography text={t('You have been redirected here to download a .csv file.')} type={TextType.BODY_TEXT_LIGHT} />
                </div>
                <div className={`${rootClass}__text`}>
                  <Typography
                    inline
                    text={t('File.Download.DownloadText')}
                    tagComponents={{
                      TextLink: (
                        <TextLink
                          link={downloadUrl}
                          size={TextLinkSize.LARGE}
                          weight={TextWeight.REGULAR}
                          dataTest={`${dataTest}-text-link`}
                          hideIcon
                        />
                      ),
                    }}
                  />
                </div>
              </>
            ))}
        </Container>
      </PositionContainer>
    </PageContainer>
  )
}

export default FileDownload
