import React, { FC } from 'react'

import { BehaviorResponse } from '@graphql/types/query-types'
import CustomTouchPointBehaviorsBody from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/components/IncomingWebhookContentMapping/components/CustomTouchPointBehaviorsContentMapping/components/CustomTouchPointBehaviorsBody/CustomTouchPointBehaviorsBody'
import CustomTouchPointBehaviorsHeader from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/components/IncomingWebhookContentMapping/components/CustomTouchPointBehaviorsContentMapping/components/CustomTouchPointBehaviorsHeader/CustomTouchPointBehaviorsHeader'

import './CustomTouchPointBehaviorsContentMapping.css'

interface CustomTouchPointBehaviorsContentMappingProps {
  className?: string
  dataTest?: string
  behaviors: BehaviorResponse[]
}

const rootClass = 'custom-touch-point-behaviors-content-mapping'

const CustomTouchPointBehaviorsContentMapping: FC<CustomTouchPointBehaviorsContentMappingProps> = ({
  dataTest,
  behaviors,
}: CustomTouchPointBehaviorsContentMappingProps) => (
  <div className={rootClass} data-test={dataTest}>
    <CustomTouchPointBehaviorsHeader behaviors={behaviors} />
    <CustomTouchPointBehaviorsBody />
  </div>
)

export default CustomTouchPointBehaviorsContentMapping
