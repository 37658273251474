import { gql } from '@apollo/client'

export default gql`
  query getWebsiteVisitorsReport($startTime: String!, $endTime: String!, $onRefresh: Boolean!) {
    getWebsiteVisitorsReport(startTime: $startTime, endTime: $endTime, onRefresh: $onRefresh) {
      knownTotal
      anonymousTotal
      companyTotal
      bouncesTotal
      conversions
      monthName
      asof {
        asofDate
        asofTime
      }
      counts {
        companies
        known
        anonymous
        bounces
        dayOfMonth
        dayOfWeek
      }
      companies {
        name
        count
      }
      searches {
        key
        count
      }
      referrers {
        site
        count
      }
      socialMedia {
        site
        count
      }
      states {
        code
        count
      }
      countries {
        code
        count
      }
      isTruncated
      truncatedTimestamp
    }
  }
`
