import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { Checkbox } from '@components/Checkbox/Checkbox'
import { ModalFooter } from '@components/Modal'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'

interface SendTestEmailModalFooterProps {
  handleCheckboxChange: (check: boolean) => void
  onClose: VoidFunction
  onSend: VoidFunction
  disableSend: boolean
  zeroRecipients: boolean
  hideCheckbox: boolean
  className: string
  plainTextMode?: boolean
  defaultChecked?: boolean
  showNext?: boolean
  dataTest?: string
}

const SendTestEmailModalFooter: FC<SendTestEmailModalFooterProps> = ({
  handleCheckboxChange,
  onClose,
  disableSend,
  zeroRecipients,
  onSend,
  hideCheckbox,
  className,
  defaultChecked,
  plainTextMode = false,
  showNext,
  dataTest = className,
}) => {
  const { t } = useTranslation()

  const actionButton = (
    <Button
      buttonType={ButtonType.PRIMARY}
      disabled={disableSend}
      onClick={onSend}
      dataTest={`${dataTest}-button-${zeroRecipients ? 'disabled' : 'send'}`}
    >
      {t(showNext ? 'Next' : 'Send')}
    </Button>
  )

  return (
    <ModalFooter className={className} dataTest={dataTest}>
      {plainTextMode ? (
        <></>
      ) : (
        <Checkbox
          label={t('Send plain text version only')}
          onChange={handleCheckboxChange}
          defaultChecked={defaultChecked}
          className={classNames(`${className}__checkbox`, {
            [`${className}__checkbox-hide`]: hideCheckbox,
          })}
          dataTest={`${dataTest}-checkbox`}
        />
      )}
      <Button buttonType={ButtonType.TERTIARY} onClick={onClose} dataTest={`${dataTest}-button-cancel`}>
        {t('Cancel')}
      </Button>
      {zeroRecipients ? (
        <Tooltip trigger={actionButton}>{t('EmailComposer.SendTestEmailPersonalizationModal.Button.Tooltip')}</Tooltip>
      ) : (
        actionButton
      )}
    </ModalFooter>
  )
}

export default SendTestEmailModalFooter
