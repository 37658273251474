import React, { FC, useCallback, useState } from 'react'

import classNames from 'classnames'

import { ButtonType } from '@components/Button'
import { Button, ButtonSize } from '@components/Button/Button'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import PageHeader from '@components/PageHeader/PageHeader'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import TriggerButton from '@components/TriggerButton/TriggerButton'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { useLandingPageContext } from '@src/pages/Content/LandingPages/LandingPagesManager/context/LandingPagesManager.context'
import { useAccountSettings } from '@utils/account/account.utils'
import { openLandingPageSocialPublishWindow } from '@utils/landingPages'

import './LandingPageManagerHeader.css'

interface LandingPageManagerHeaderProps {
  className?: string
  dataTest?: string
}

const rootClass = 'landing-page-manager-header'

const LandingPageManagerHeader: FC<LandingPageManagerHeaderProps> = ({ dataTest = rootClass, className }) => {
  const { t } = useTranslation()
  const {
    landingPage: { name, canEdit, enabled, defaultUrlGroup, id, publishInfo: { hasUnpublishedChanges, isLandingPagePublished } = {} },
    onEdit,
    dirtyClickthroughLinks,
    onSaveLinks,
    onCancelLinkChanges,
  } = useLandingPageContext()
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const defaultURL = defaultUrlGroup.publicUrl

  const { newLPComposerCreateBlank } = useAccountSettings()

  const status = newLPComposerCreateBlank ? isLandingPagePublished : enabled

  const handleVisit = useCallback(() => window.open(defaultURL), [defaultURL])
  const handlePublish = useCallback(() => openLandingPageSocialPublishWindow(id, defaultURL), [id, defaultURL])
  const handleCancel = useCallback(() => setOpenConfirmation(true), [])
  const handleModalAnswer = useCallback(
    (answer: YesNo) => {
      if (answer === YesNo.YES) {
        onCancelLinkChanges()
      }
      setOpenConfirmation(false)
    },
    [onCancelLinkChanges]
  )

  const headerStatusText = newLPComposerCreateBlank ? (isLandingPagePublished ? 'LandingPage.Live' : 'Draft') : enabled ? 'Enabled' : 'Disabled'

  return (
    <PageHeader primaryText={name} linkUrl="content/landingPages" background className={classNames(rootClass, className)} dataTest={dataTest}>
      {dirtyClickthroughLinks ? (
        <>
          <div className={classNames('flex-align-center', `${rootClass}__status`, `${rootClass}__dirty`)}>
            <Svg name={SvgNames.pencil} type={SvgType.ICON} />
            <Typography text={t('Unsaved changes')} weight={TextWeight.MEDIUM} />
          </div>
          <Button buttonType={ButtonType.TERTIARY} onClick={handleCancel} className="push-left-x2">
            {t('Cancel')}
          </Button>
          <Button buttonType={ButtonType.PRIMARY} onClick={onSaveLinks}>
            {t('Save')}
          </Button>
        </>
      ) : (
        <>
          <div className={classNames('flex-align-center', `${rootClass}__status`)}>
            <span
              className={classNames(`${rootClass}__status-dot`, {
                [`${rootClass}__status-dot-enabled`]: status,
                [`${rootClass}__status-dot-disabled`]: !status,
              })}
              data-test={`${dataTest}-dot`}
            ></span>
            <Typography
              dataTest={`${rootClass}-publish-status`}
              text={t(headerStatusText, {
                name: t('LandingPage.Live'),
                context: newLPComposerCreateBlank && hasUnpublishedChanges ? 'unpublishedChanges' : '',
              })}
              weight={isLandingPagePublished ? undefined : TextWeight.MEDIUM}
              tagProps={{ b: { weight: TextWeight.BOLD, inline: true } }}
            />
          </div>
          <TriggerButton
            label={t('Actions')}
            options={[
              {
                text: t('Edit landing page'),
                icon: SvgNames.pencil,
                disabled: !canEdit,
                disabledTooltip: t('Ask your administrator for permission to do this'),
                onClick: onEdit,
              },
              {
                text: t('Visit landing page'),
                icon: SvgNames.customLink,
                onClick: handleVisit,
              },
              {
                text: t('Publish to social media'),
                icon: SvgNames.share,
                onClick: handlePublish,
              },
            ]}
            buttonType={ButtonType.PRIMARY}
            buttonSize={ButtonSize.LARGE}
            alignRight
            dataTest={`${dataTest}-actions`}
          />
        </>
      )}
      <ConfirmationModal
        body={t('Leave.And.Lose.Changes.Message')}
        cancelButtonText={t('Keep editing')}
        deleteButtonText={t('Discard changes')}
        title={t('You have unsaved changes')}
        onAnswer={handleModalAnswer}
        isDelete
        isOpen={openConfirmation}
      />
    </PageHeader>
  )
}

export default LandingPageManagerHeader
