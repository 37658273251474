import React, { FC, MutableRefObject, RefObject, useCallback, useContext, useEffect, useRef, useState } from 'react'

import { IPluginDisplayCondition, IPluginFilePicker, ISpecialLink } from '@beefree.io/sdk/dist/types/bee'
import { PersonalizationContainer } from '@complex/Personalization/PersonalizationContainer'
import { getRecipientSrcIds } from '@complex/Personalization/utils/Personalization.utils'
import Button, { ButtonType } from '@components/Button/index'
import ButtonIcon, { ButtonIconSize, ButtonIconType } from '@components/ButtonIcon/ButtonIcon'
import ButtonMenu, { ButtonMenuOption } from '@components/ButtonMenu/ButtonMenu'
import Container from '@components/Container/index'
import Svg, { SvgNames } from '@components/Svg/index'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'
import { RowArgs } from '@src/pages/EmailComposer/BeeEditor/BeeEditorContainer'
import OptOutLinkSelector from '@src/pages/EmailComposer/components/common/OptOutLinkSelector/OptOutLinkSelector'
import {
  getNewlinesAroundInsertedText,
  createRSSPlainTextBlock,
  PlainTextBlock,
  RSS_PATTERN,
} from '@src/pages/EmailComposer/components/PlainTextComposer/PlainTextComposer.utils'
import { EmailTemplateLayoutType } from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/utils/EmailLayoutsModal.utils'
import { EmailEditModalState, EmailModals, EmailModalsState } from '@src/pages/EmailComposer/EmailModals/EmailModals'
import { modalsInitialState } from '@src/pages/EmailComposer/utils/BeeEditor.constants'
import {
  BeeEditorActionDoneCallbacks,
  IAddOnResponse,
  IAddOnResponseTypes,
  PlainTextRSSResponse,
} from '@src/pages/EmailComposer/utils/BeeEditor.types'
import { getButtonMenuProps } from '@src/pages/EmailComposer/utils/EmailComposer.constants'
import { detectEmailType, detectWebinarType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'

import './PlainTextComposerFooter.css'

interface PlainTextComposerFooterProps {
  inputRef: RefObject<HTMLTextAreaElement>
  showLoading: boolean
  isUndoEnabled: boolean
  isRedoEnabled: boolean
  undo: () => void
  redo: () => void
  insert: (text: string, position?: number) => void
  onDropDownToggle: (isOpen: boolean) => void
  onButtonMouseDown: (e: React.MouseEvent<HTMLButtonElement>) => void
  rssBlocks: MutableRefObject<Map<Number, PlainTextBlock>>
  className?: string
  dataTest?: string
}

const rootClass = 'plain-text-composer-footer'

const PlainTextComposerFooter: FC<PlainTextComposerFooterProps> = (props: PlainTextComposerFooterProps) => {
  const {
    showLoading,
    inputRef,
    undo,
    redo,
    insert,
    onDropDownToggle,
    rssBlocks,
    isUndoEnabled,
    isRedoEnabled,
    onButtonMouseDown,
    dataTest = rootClass,
  } = props
  const { t } = useTranslation()

  const {
    api: { update },
    values: {
      isStory,
      message: { plainTextOnlyDynamicContent, sendto_contacts, sendto_lists, individualRecipientsSourceIds, messageType, webinarConfig },
      messageConfiguration: { messageType: messageConfigurationType },
    },
  } = useContext(EmailComposerContext)

  const [editModalState] = useState<EmailEditModalState>({})
  const [modalState, setModalState] = useState<EmailModalsState>(modalsInitialState)
  const [customRowMetaData] = useState<Record<string, string>>()
  const [showPersonalizations, setShowPersonalizations] = useState(false)

  const closeModals = useCallback(() => setModalState(modalsInitialState), [])

  const buttonMenuProps = getButtonMenuProps(messageConfigurationType === 'RSS_MESSAGE', true)

  const dynamicContentRef = useRef(plainTextOnlyDynamicContent)
  useEffect(() => {
    dynamicContentRef.current = plainTextOnlyDynamicContent
  }, [plainTextOnlyDynamicContent])

  const getInsertText = (insertType: string, insertText: string) => {
    const { before, after } = getNewlinesAroundInsertedText(inputRef.current)
    switch (insertType) {
      case 'LANDING_PAGES':
        return `${before}Landing page URL:\n${insertText}${after}`
      case 'FORM':
        return `${before}Form URL:\n${insertText}${after}`
      case 'FILE':
        return `${before}Media file URL:\n${insertText}${after}`
      case 'VIEW_IN_BROWSER':
        return `${before}View in browser:\n${insertText}${after}`
      case 'IMAGE':
        return `${before}Image:\nTo see the original image, click here${insertText}${after}`
    }
  }

  const { isEmailBlankMessage, isEmailTemplate, isEmailABTest, isEmailForm, isEmailProgram } = detectEmailType(messageType)
  const { isAccepted, isPending, isRejected, isDeclined } = detectWebinarType(webinarConfig)

  const actionDoneCallbacks = useRef<BeeEditorActionDoneCallbacks>({
    onSpecialLinkDone: (data: ISpecialLink | undefined) => {
      if (data) {
        insert(`${getInsertText(data.type, data.link)}`)
      }
      closeModals()
    },
    onSaveRowDone: () => null,
    onMergeTagDone: () => null,
    onAddOnDone: (data: IAddOnResponse | undefined) => {
      if (data && inputRef.current) {
        const { before, after } = getNewlinesAroundInsertedText(inputRef.current)
        let textToInsert = ''

        if (data.type === IAddOnResponseTypes.ROW_ADD_ON) {
          const isHeader = data.value.name.includes(EmailTemplateLayoutType.HEADER)
          const prefix = isHeader ? '' : '\n\n'
          const suffix = isHeader ? '\n\n' : ''

          textToInsert = `${prefix}${(data.value as any).columns[0].modules[0].text}${suffix}`

          insert(`${before}${textToInsert}${after}`, isHeader ? 0 : inputRef.current.value.length)
        } else if (data.type === IAddOnResponseTypes.HTML) {
          const { current: map } = rssBlocks
          const id = Array.from(`${inputRef.current.value + data.value.html}`.matchAll(RSS_PATTERN)).length + 1
          textToInsert = `\n--- RSS Block ${id} ---\n${data.value.html}--- RSS Block ${id} ---\n`

          const rssBlock = createRSSPlainTextBlock(data as PlainTextRSSResponse, id, textToInsert)
          const plainTextId = rssBlock.rss.plainTextId

          if (map && plainTextId) {
            if (map.has(plainTextId)) {
              map.delete(plainTextId)
              map.set(plainTextId, rssBlock)
            } else {
              map.set(plainTextId, rssBlock)
            }
          }

          insert(`${textToInsert}`)
        }
      }
      closeModals()
    },
    onFilePickerDone: (data?: IPluginFilePicker) => {
      if (data) {
        insert(`${getInsertText('IMAGE', `: ${data.url}`)}`)
      }
      closeModals()
    },
    onPreventMultipleLayoutsConfirm: () => null,
    onEditRowDone: () => null,
    onDisplayConditionDone: (data: IPluginDisplayCondition | undefined) => {
      if (data) {
        const { before, after } = getNewlinesAroundInsertedText(inputRef.current)
        update({ message: { plainTextOnlyDynamicContent: [...dynamicContentRef.current, data] } })
        insert(`${before}${data.before}\n${t('EmailComposer.PlainTextComposer.DynamicContent.Placeholder')}\n${data.after}${after}`)
      }
      closeModals()
    },
  })

  const handleInsert = (selectedOption: ButtonMenuOption) => {
    switch (selectedOption.id) {
      case 'assetLink':
        setModalState({ ...modalState, selectAsset: true })
        break
      case 'imageBlock':
        setModalState({ ...modalState, imageModal: true })
        break
      case 'dynamicContentBlock':
        setModalState({ ...modalState, dynamicContent: { isActive: true } })
        break
      case 'rss':
        setModalState({ ...modalState, rssModal: { isActive: true, isPlainText: true } })
        break
      case 'footerBlock':
        setModalState({ ...modalState, footersModal: true })
        break
      case 'headerBlock':
        setModalState({ ...modalState, headersModal: true })
        break
    }
  }

  const disableSegmentFields = isEmailABTest || isEmailForm || isEmailProgram || isAccepted || isPending || isRejected || isDeclined

  const renderPersonalizationModal = () => {
    return (
      <PersonalizationContainer
        isOpen
        disableAddRecipientsButton={!!sendto_contacts?.length}
        specificIds={getRecipientSrcIds(individualRecipientsSourceIds, sendto_lists)}
        closePersonalization={() => setShowPersonalizations(false)}
        doneCallback={(data) => {
          setShowPersonalizations(false)
          data?.value && insert(data.value)
        }}
        isStory={isStory}
        disableListOrSegmentFields={disableSegmentFields}
        disableListOrSegmentFieldsForTemplate={isEmailBlankMessage || isEmailTemplate}
      />
    )
  }

  return (
    <>
      {showPersonalizations && renderPersonalizationModal()}
      <EmailModals
        {...actionDoneCallbacks.current}
        modalsState={modalState}
        editModalState={editModalState}
        metaData={customRowMetaData}
        rowArgs={{} as RowArgs}
        excludeOptOutLink
        sendPlainText
      />
      <Container noPadding className={rootClass} dataTest={dataTest}>
        <div className={`${rootClass}__left`}>
          <ButtonMenu {...buttonMenuProps} onSelect={handleInsert} onMouseDown={onButtonMouseDown} />
          <Button
            buttonType={ButtonType.FLOAT_TEAL}
            onClick={() => setShowPersonalizations(true)}
            onMouseDown={onButtonMouseDown}
            disabled={showLoading}
            minimalPadding
            className={`${rootClass}__personalize-button`}
            dataTest={`${dataTest}-personalize-button`}
          >
            <Svg name={SvgNames.userUnselected} />
            {t('Personalize')}
          </Button>
          <OptOutLinkSelector showLoading={showLoading} includeLabel insert={insert} onDropDownToggle={onDropDownToggle} />
        </div>
        <div className={`${rootClass}__right`}>
          <Tooltip
            trigger={
              <ButtonIcon
                type={ButtonIconType.TERTIARY}
                icon={isUndoEnabled ? SvgNames.actionUndoEnabled : SvgNames.actionUndoDisabled}
                size={ButtonIconSize.LARGE}
                onClick={undo}
                onMouseDown={onButtonMouseDown}
                disabled={!isUndoEnabled || showLoading}
                dataTest={`${dataTest}-undo-button`}
              />
            }
            position={'top'}
          >
            {isUndoEnabled ? t('Undo') : t('EmailComposer.Preview.Plain.Text.Edit.NoUndo')}
          </Tooltip>
          <Tooltip
            trigger={
              <ButtonIcon
                type={ButtonIconType.TERTIARY}
                icon={isRedoEnabled ? SvgNames.actionRedoEnabled : SvgNames.actionRedoDisabled}
                size={ButtonIconSize.LARGE}
                onClick={redo}
                onMouseDown={onButtonMouseDown}
                disabled={!isRedoEnabled || showLoading}
                dataTest={`${dataTest}-redo-button`}
              />
            }
            position={'top'}
          >
            {isRedoEnabled ? t('Redo') : t('EmailComposer.Preview.Plain.Text.Edit.NoRedo')}
          </Tooltip>
        </div>
      </Container>
    </>
  )
}

export default PlainTextComposerFooter
