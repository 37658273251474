import { createContext, ReactNode } from 'react'

import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { Status } from '@components/StatusToast/StatusToast'
import { StandardField } from '@graphql/types/microservice/list-types'
import { ProgramSendChoiceInput } from '@graphql/types/mutation-types'
import {
  ExitCondition,
  Program,
  ProgramAppendToSegmentStep,
  ProgramBranchStep,
  ProgramCopyStep,
  ProgramRecordSetStep,
  ProgramSendStep,
  ProgramSetFieldChoice,
  ProgramSetFieldOperation,
  ProgramSource,
  ProgramWaitUntilInSegmentStep,
} from '@graphql/types/query-types'
import { List } from '@interface/foldersLists/List'
import { DataType } from '@src/pages/programs/upgradeManager/components/steps/ChangeFieldValueUpgradeStep/components/FieldReplacement/FieldReplacement.utils'

export enum UpgradeStep {
  SELECT_PROGRAM = 'SELECT_PROGRAM',
  CONTACT_SOURCES = 'CONTACT_SOURCES',
  EARLY_EXITS = 'EARLY_EXITS',
  FIX_STEP_ISSUES = 'FIX_STEP_ISSUES',
  REVIEW_UPGRADE = 'REVIEW_UPGRADE',
  SUCCESSFULLY_UPGRADED = 'SUCCESSFULLY_UPGRADED',
}

export type UpgradeSourcesStep = UpgradeStep.CONTACT_SOURCES | UpgradeStep.EARLY_EXITS | UpgradeStep.FIX_STEP_ISSUES

export type EarlyExitTempSource = ProgramSource & { upgradeId: string }

export type UpgradedEarlyExitCondition = ExitCondition & { upgradeId: string }

export type UpgradedStepOperation = ProgramSetFieldOperation & {
  operationId?: string
  newField?: { name: string; isNew: boolean; dataType?: DataType }
  upgraded?: boolean
}

export type UpgradedStepCondition = Omit<ProgramSetFieldChoice, 'operations'> & {
  conditionId?: string
  operations: UpgradedStepOperation[]
  upgraded?: boolean
}

type UpgradedStep = {
  upgradeId: string
  upgraded?: boolean
  removed: boolean
}

export type UpgradedProgramRecordSetStep = Omit<ProgramRecordSetStep, 'conditions' | 'operations'> &
  UpgradedStep & {
    conditions: UpgradedStepCondition[]
    operations: UpgradedStepOperation[]
  }

export type UpgradedProgramCopyStep = ProgramCopyStep & UpgradedStep

export type UpgradedProgramAppendToSegmentStep = ProgramAppendToSegmentStep &
  UpgradedStep & {
    newListId?: string
  }

export type UpgradedStepSendChoices = ProgramSendChoiceInput & { choiceId?: string; newSrcId?: string }

export type UpgradedProgramSendStep = Omit<ProgramSendStep, 'sendChoices'> &
  UpgradedStep & {
    sendChoices: UpgradedStepSendChoices[]
  }

export type SourceUpgradeStepType = ProgramBranchStep | ProgramWaitUntilInSegmentStep

export type UpgradedBranchStep = ProgramBranchStep & UpgradedStep

export type UpgradedWaitUntilInSegmentStep = ProgramWaitUntilInSegmentStep & UpgradedStep

export type UpgradedSourceUpgradeStepType = UpgradedBranchStep | UpgradedWaitUntilInSegmentStep

export interface BaseList {
  id: string
  name: string
  size: number
}

export interface RecommendedSegments {
  [key: string]: BaseList
}

export interface UpgradedSource {
  source: { id: string; sourceName?: string; count?: number }
  recommended?: BaseList
  newSource?: List | BaseList
  removed: boolean
  upgradeId: string
}

export interface UpgradedStepSources {
  [stepKey: string]: { [sourceKey: string]: BaseList }
}

export type UpgradedProgramStep =
  | UpgradedProgramCopyStep
  | UpgradedProgramRecordSetStep
  | UpgradedProgramAppendToSegmentStep
  | UpgradedProgramSendStep
  | UpgradedSourceUpgradeStepType

export interface UpgradeManagerContainerState {
  activeUpgradeStep: UpgradeStep
  allContactsFieldsOptions?: SelectV2SingleOption[]
  contactsCount?: number
  currentStep?: UpgradedProgramStep
  currentStepPosition: number
  isSuccessfullyUpgraded: boolean
  isUpgrading: boolean
  recommendedSegments: RecommendedSegments
  showUpgradeSidePanel: boolean
  standardFields: StandardField[]
  statusToast?: {
    message: string | ReactNode
    status: Status
    showStatusToast: boolean
  }
  stepsToUpgrade: UpgradedProgramStep[]
  submitButtonDisabled: boolean
  switchToManualStart: boolean
  upgradedCurrentStep?: UpgradedProgramStep
  upgradedSteps: UpgradedProgramStep[]
  upgradedProgram?: Program
  upgradedSources?: UpgradedSource[]
  upgradedEarlyExitSources?: UpgradedSource[]
  upgradedEarlyExitConditions?: UpgradedEarlyExitCondition[]
  upgradedStepSources: UpgradedStepSources
  leavingPage: boolean
}

export const UpgradeManagerContainerInitialState: UpgradeManagerContainerState = {
  activeUpgradeStep: UpgradeStep.SELECT_PROGRAM,
  currentStepPosition: 1,
  isSuccessfullyUpgraded: false,
  isUpgrading: false,
  recommendedSegments: {},
  showUpgradeSidePanel: false,
  standardFields: [],
  stepsToUpgrade: [],
  submitButtonDisabled: true,
  switchToManualStart: false,
  upgradedSteps: [],
  upgradedStepSources: {},
  leavingPage: false,
  currentStep: undefined,
  upgradedSources: undefined,
  upgradedEarlyExitSources: undefined,
  upgradedEarlyExitConditions: undefined,
}

type UpgradeSources = (step: UpgradeSourcesStep) => void
export type Update = (fieldsToUpdate: Partial<UpgradeManagerContainerState>) => void

export interface UpgradeManagerAPI {
  values: UpgradeManagerContainerState
  goToNextOrPreviousStep: (current: number) => void
  saveStep: VoidFunction
  upgradeSources: UpgradeSources
  upgradeProgram: VoidFunction
  update: Update
}

export const UpgradeManagerContext = createContext<UpgradeManagerAPI>({ values: UpgradeManagerContainerInitialState } as any)
