import React, { FC } from 'react'

import { TFunction } from 'i18next'

import AssetPickerModal, { AssetPickerModalBaseProps, AssetPickerModalProps } from '@complex/AssetPickerModal/AssetPickerModal'
import { AssetPickerListingPageProps, AssetPickerSidebarProps, AssetPickerTableProps } from '@complex/AssetPickerModal/Context/AssetPicker.context'
import { RenderSearchColumnsV2, ListPageCommonState, Update } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { useTranslation } from '@const/globals'
import { ItemType } from '@utils/categorization'
import { allFormTemplatesFilter } from '@utils/filter'
import { getFormsPreviewUrl } from '@utils/forms'

import { renderSearchTableColumns, renderTableColumns } from './FormTemplatesPickerModal.tables'

const rootClass = 'form-templates-picker-modal'

export const getCreateFormFromTemplateModalProps = (t: TFunction) => ({
  isOpen: true,
  titleText: t('ListPage.Forms.TemplatePicker.Title', { count: 1 }),
  primaryButtonText: t('ListPage.Forms.TemplatePicker.PrimaryButton', { count: 1 }),
  secondaryButtonText: 'ListPage.Forms.TemplatePicker.SecondaryButton',
  isSingleSelect: true,
})

const FormTemplatesPickerModal: FC<AssetPickerModalBaseProps> = (props) => {
  const { t } = useTranslation()

  const customPreviewItemCall = (listPageValues: ListPageCommonState, update: Update) => {
    const { selectedRows } = listPageValues
    update({ previewUrl: getFormsPreviewUrl(selectedRows[0].externalId ?? '') })
  }

  const renderSearchColumns: RenderSearchColumnsV2 = (
    _searchInAllItems: boolean | undefined,
    _currentFolder: FolderData,
    search: string,
    folders: FolderData[]
  ) => renderSearchTableColumns(search, folders, t)

  const tableProps: AssetPickerTableProps = {
    columns: renderTableColumns(t),
    renderSearchColumns,
    listPage: 'FormTemplates',
    sortingBy: [{ id: 'updatedAt', desc: true }],
  }

  const sidebarProps: AssetPickerSidebarProps = {
    hasRecent: false,
    hasCreatedByMe: true,
    allItemFilter: allFormTemplatesFilter,
  }

  const listingPageProps: AssetPickerListingPageProps = {
    customPreviewItemCall,
    sidebarProps,
  }

  const assetPickerProps: AssetPickerModalProps = {
    className: rootClass,
    listingPageProps,
    tableProps,
    hasTags: true,
    hasCustomFilters: false,
    hasSidebar: true,
    hasPreview: true,
    itemType: ItemType.FORM_TEMPLATE,
    ...props,
  }

  return <AssetPickerModal {...assetPickerProps} />
}

export default FormTemplatesPickerModal
