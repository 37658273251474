import React, { FC, useEffect, useState } from 'react'

import Button, { ButtonType } from '@components/Button'
import FilterableList, { FilterableListData } from '@components/FilterableList/FilterableList'
import Modal from '@components/Modal'
import { ModalBody } from '@components/Modal/components/ModalBody'
import { ModalFooter, ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeader, ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Typography, { ModalBodyStyle, ModalHeaderFormStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { FieldNamesDataType } from '@utils/standardFieldNames.utils'

import './optimizeCrmModal.css'

const rootClass = 'optimize-crm-modal'

interface Props {
  isOpen: boolean
  crmType: string
  optimizeCRM: () => void
  closeModal: (tableLoading: boolean) => void
  data: FieldNamesDataType[]
  onRowSelectionChanged: (rowIds: string[]) => void
}

const OptimizeCRMModal: FC<Props> = (props: Props) => {
  const { isOpen = false, crmType, optimizeCRM, closeModal, data, onRowSelectionChanged } = props
  const { t } = useTranslation()
  const [filteredList, setFilteredList] = useState<FilterableListData[]>()

  useEffect(() => {
    const updatedData = data.map((row) => ({
      ...row,
      id: parseInt(row.id),
      selected: row.selected ?? false,
      disabled: row.isValid,
    }))
    setFilteredList(updatedData)
  }, [data])

  const onItemSelection = (selectedItem: FilterableListData) => {
    const updatedData = data.map((item) => {
      return item.id === selectedItem.id.toString()
        ? {
            ...item,
            id: parseInt(item.id),
            selected: selectedItem.selected,
          }
        : {
            ...item,
            id: parseInt(item.id),
            selected: item.selected ?? false,
          }
    })
    setFilteredList(updatedData)
    onRowSelectionChanged(updatedData.filter((item) => item.selected).map((item) => item.id.toString()))
  }

  const modalHeader = (
    <ModalHeader headerType={ModalHeaderType.List}>
      <Typography text={t(`Optimize for {{connectorType}}`, { connectorType: crmType })} {...ModalHeaderFormStyle} />
    </ModalHeader>
  )

  return (
    <Modal header={modalHeader} isOpen={isOpen} className={rootClass}>
      <div className={`${rootClass}__body-description`}>
        <Typography
          inline
          text={t(
            'Select the field names from your CRM to map them with Act-On standard fields. This will reset your current field mappings. Click '
          )}
          {...ModalBodyStyle}
        />
        <Typography text={t('Preview changes ')} {...ModalBodyStyle} weight={TextWeight.BOLD} inline />
        <Typography text={t('to review, then save or discard changes.')} {...ModalBodyStyle} inline />
      </div>
      <ModalBody>
        <Typography text={t('Fields')} type={TextType.TABLE_HEADER} />
        <FilterableList
          accessor={'name'}
          data={filteredList ?? []}
          onItemSelection={onItemSelection}
          emptyText={t('No results found')}
          className={`${rootClass}__list`}
        />
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form}>
        <Button buttonType={ButtonType.TERTIARY} onClick={() => closeModal(false)}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} onClick={optimizeCRM}>
          {t('Preview changes')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default React.memo(OptimizeCRMModal)
