import { useEffect, useState } from 'react'

import { useApolloClient, useQuery } from '@apollo/client'
import getLandingPageSlugsForAccount from '@graphql/queries/getLandingPageSlugsForAccount'
import { GetLandingPageSlugsForAccountQuery, GetLandingPageSlugsForAccountQueryVariables } from '@graphql/types/query-types'
import { logNewRelicError } from '@utils/new-relic.utils'

const useAccountSlugs = (): {
  accountSlugs: Array<string | undefined>
  refetchAccountSlugs: () => void
} => {
  const [accountSlugs, setAccountSlugs] = useState<Array<string | undefined>>([])
  const client = useApolloClient()

  const { loading, error, data, refetch } = useQuery<GetLandingPageSlugsForAccountQuery, GetLandingPageSlugsForAccountQueryVariables>(
    getLandingPageSlugsForAccount,
    {
      client,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  )

  useEffect(() => {
    if (!loading && data?.getLandingPageSlugsForAccount) {
      setAccountSlugs(data?.getLandingPageSlugsForAccount.sort())
    }
    if (error) {
      logNewRelicError(error)
    }
  }, [loading, error, data])

  return {
    accountSlugs,
    refetchAccountSlugs: refetch,
  }
}

export default useAccountSlugs
