import { gql } from '@apollo/client'

export default gql`
  query getContactsFromCrmFilter($listId: String!, $crmNamespace: String!, $crmFilter: String!) {
    getContactsFromCrmFilter(listId: $listId, crmNamespace: $crmNamespace, crmFilter: $crmFilter) {
      id
      name
      email
    }
  }
`
