import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'

import { TFunction } from 'i18next'

import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import PageContainer from '@components/PageContainer'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { prevPageLinks } from '@src/pages/EmailComposer/utils/EmailComposer.constants'
import { PrevPageParamsEnum } from '@src/pages/EmailComposer/utils/EmailComposer.types'
import { useAccountSettings } from '@utils/account/account.utils'
import { MatchParams } from '@utils/types'

type EmailComposerEmptyProps = RouteComponentProps<MatchParams> & {
  dataTest?: string
}
enum EmptyComposerLayout {
  DRAFT = 'draft',
  TEMPLATE = 'template',
}

const rootClass = 'empty-email-composer'

const getEmptyListingDetails = (t: TFunction, messageId: string | null, enableEmailDraftsReact: boolean) => {
  const prevPageQueryParam = new URLSearchParams(window.location.search).get('prevPage') as PrevPageParamsEnum
  const isDraft = prevPageQueryParam === PrevPageParamsEnum.drafts || messageId?.charAt(0) === 'd'
  const context = isDraft ? EmptyComposerLayout.DRAFT : EmptyComposerLayout.TEMPLATE
  if (prevPageQueryParam && Object.values(PrevPageParamsEnum).includes(prevPageQueryParam)) {
    return {
      headline: t('EmailComposerEmpty.headline', { context }),
      buttonText: t('EmailComposerEmpty.buttonText', { context }),
      buttonURL: prevPageLinks[prevPageQueryParam],
    }
  } else {
    const buttonURL = isDraft
      ? enableEmailDraftsReact
        ? prevPageLinks[PrevPageParamsEnum.drafts]
        : prevPageLinks[PrevPageParamsEnum.classicDrafts]
      : prevPageLinks[PrevPageParamsEnum.emailTemplate]
    return {
      headline: t(`EmailComposerEmpty.headline`, { context }),
      buttonText: t('EmailComposerEmpty.buttonText', { context }),
      buttonURL,
    }
  }
}

const EmailComposerEmpty: FC<EmailComposerEmptyProps> = (props) => {
  const { t } = useTranslation()
  const { enableEmailDraftsReact } = useAccountSettings()
  const { match, dataTest = rootClass } = props

  const { headline, buttonText, buttonURL } = getEmptyListingDetails(t, match.params.id, enableEmailDraftsReact)

  return (
    <PageContainer noPadding>
      <PositionContainer innerDivClassname={rootClass} dataTest={dataTest}>
        <EmptyListing
          headline={headline}
          text={t('EmailComposerEmpty.text')}
          imgSrc={StaticImageNames.errorNothingFound}
          buttonText={buttonText}
          buttonURL={buttonURL}
          size={EmptyListingSize.LARGE}
          fullHeight
        />
      </PositionContainer>{' '}
    </PageContainer>
  )
}

export default EmailComposerEmpty
