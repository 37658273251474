import React, { FC, useCallback } from 'react'

import { useImagePickerModalContentContext } from '@components/AssetPickers/ImagePickerModal/context/ImagePickerModalContent.context'
import FolderSearchFilterButtons, {
  FolderSearchOption,
} from '@components/FolderSearch/components/FolderSearchFilterButtons/FolderSearchFilterButtons'
import { SvgNames } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { allImagesFilter, favoriteFilter } from '@utils/filter'
import { DEFAULT_FOLDER_ID, DEFAULT_FOLDER_NAME } from '@utils/folders'

const rootClass = 'image-picker-modal-content__search-results'

interface ImagePickerModalSearchResultsProps {
  dataTest?: string
}

const ImagePickerModalSearchResults: FC<ImagePickerModalSearchResultsProps> = ({ dataTest = rootClass }) => {
  const { t } = useTranslation()
  const { searchTerm, imageItems, loading, isLogos, filterState, updateFilterState, activeFolderName } = useImagePickerModalContentContext()
  const { activeFilter } = filterState
  const searchAllActive = activeFilter?.name === allImagesFilter.name
  const favoritesActive = activeFilter?.name === favoriteFilter.name

  const handleChange = useCallback(
    (all: boolean) => {
      const state = all ? { activeFilter: allImagesFilter, activeFolder: undefined } : { activeFilter: undefined, activeFolder: DEFAULT_FOLDER_ID }
      updateFilterState(state)
    },
    [updateFilterState]
  )

  if (!searchTerm || loading) {
    return null
  }

  const options: FolderSearchOption[] = [
    {
      icon: favoritesActive ? favoriteFilter.svgUnselected : SvgNames.folder,
      name: favoritesActive ? favoriteFilter.name : activeFolderName ?? DEFAULT_FOLDER_NAME,
      active: !searchAllActive,
      searchAll: false,
    },
    {
      icon: allImagesFilter.svgUnselected,
      name: allImagesFilter.name,
      active: searchAllActive,
      searchAll: true,
    },
  ]

  return (
    <div className={rootClass} data-test={dataTest}>
      <Typography
        className="inset-down-x4 inset-up-x4"
        text="AssetPicker.Search.Results"
        values={{ count: imageItems.length, searchTerm }}
        type={TextType.SECTION_HEADER}
        weight={TextWeight.BOLD}
      />
      {!isLogos && (
        <div className="flex-align-center push-up-x2">
          <Typography text={t('Search in:')} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.MEDIUM} className="inset-left-x2" />
          <FolderSearchFilterButtons onChange={handleChange} options={options} dataTest={`${dataTest}-buttons`} />
        </div>
      )}
    </div>
  )
}
export default ImagePickerModalSearchResults
