import React, { ComponentProps, FC, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { ImportLogProps } from '@complex/ImportLog/ImportLog'
import { ListingPageStatusToast } from '@complex/ListingPage/Utils/ListingPage.constants'
import ListPageModals, { ListPageModal, ListPageModalsProps, ListPageModalsState, StatusToastProps } from '@complex/ListPageModals/ListPageModals'
import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import { DeleteConfirmationProps } from '@components/DeleteConfirmation/DeleteConfirmation'
import { DuplicateFolderModalProps } from '@components/DuplicateModal/DuplicateFolderModal'
import { ExportToFtpFileModalProps } from '@components/ExportToFtpFileModal/ExportToFtpFileModal'
import { MoveToFolderModalProps } from '@components/MoveToFolderModal/MoveToFolderModal'
import NewAddFolderModal from '@components/NewAddFolder/NewAddFolderModal'
import { SelectAssetV2Props, SelectAssetV2RowItem } from '@components/SelectAssetV2/SelectAssetV2'
import { FileSelectWithSearchModalProps } from '@components/SelectFtpFileWithSearchModal/SelectFtpFileWithSearchModal'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { rootContext, useTranslation } from '@const/globals'
import { FolderDto, LabelDto } from '@graphql/types/microservice/categorization-types'
import { FtpFileDto } from '@graphql/types/microservice/entity-upload-types'
import { doExport } from '@src/pages/ContactSegments/components/ContactSegmentsActions/ContactSegmentsActions.utils'
import { EXPORT_ACTION, IMPORT_SOURCE } from '@src/pages/ContactSegments/utils/ContactSegments.constants'
import { filterNotEmptyArray } from '@utils/array'
import { ItemType } from '@utils/categorization'
import { Segment } from '@utils/contactSegments/contactSegments.utils'
import { ContactSegmentsContext } from '@utils/contactSegments/context/ContactSegmentsContext'
import { formatDateWithAbbreviations } from '@utils/date'
import { getDeleteConfirmationModalUtils } from '@utils/listPageDeleteModals'

import './ContactSegmentsModals.css'

interface Props {
  addToFolder: boolean
  clear: () => void
  hoveredFolder?: FolderData
  isEditingFolder: boolean
  onMoveClick: (folderId: number, segments?: Segment[]) => void
  segmentToClone?: Segment
  segmentsToMove: Segment[]
  tagToEdit?: LabelDto
  segmentToExport?: Segment
  onSegmentExport: () => void
}

const rootClass = 'contact-segments-modals'

const ContactSegmentsModals: FC<Props> = (props: Props) => {
  const {
    addToFolder,
    tagToEdit,
    clear,
    hoveredFolder,
    isEditingFolder,
    onMoveClick,
    segmentToClone,
    segmentsToMove,
    segmentToExport,
    onSegmentExport,
  } = props
  const {
    values: {
      activeFolderId,
      confirmationModal,
      creatingFolder,
      deleteConfirmationData,
      folders,
      isFtpConnectorActive,
      itemTypesUsed,
      showManageTag,
      statusToast: { statusMessage, status, showStatusToast, title },
      tags,
      selectingImportContactsSource,
      manageFtpSyncSegmentId,
      ftpSyncJobs,
      ftpFileImportLogTarget,
      loadingFtpFiles,
      ftpFiles,
      selectingFtpFile,
      selectingExportAction,
      ftpExportSyncJobs,
      selectingFtpExports,
      creatingFtpFile,
    },
    cloneSegment,
    closeStatusToast,
    createFolder,
    createTag,
    deleteAllContacts,
    deleteFolder,
    deleteFromFolder,
    deleteSegments,
    deleteTag,
    renameFolder,
    update,
    getFtpExportSyncJobsList,
  } = useContext(ContactSegmentsContext)

  const { t } = useTranslation()
  const history = useHistory<{ selectedFtpFile: FtpFileDto; exportSegment: Segment }>()

  const closeAddFolderModal = () => {
    update({ creatingFolder: false })
    clear()
  }

  const onFolderCreate = (folder: FolderDto) => {
    !isEditingFolder ? createFolder(folder) : renameFolder(folder)
    clear()
  }

  const addFolderProps: ComponentProps<typeof NewAddFolderModal> = {
    folders,
    hoveredFolder,
    isEditing: isEditingFolder,
    isOpen: creatingFolder,
    nestedWithin: hoveredFolder ? (isEditingFolder ? hoveredFolder.parentId : hoveredFolder.id) : activeFolderId,
    onClose: closeAddFolderModal,
    onCreate: onFolderCreate,
    onModalSave: (statusToast) => update({ statusToast: { ...(statusToast as ListingPageStatusToast) } }),
  }

  const moveToFolderProps: MoveToFolderModalProps<Segment> = {
    addToFolder,
    folders,
    isOpen: segmentsToMove.length > 0,
    movableItems: segmentsToMove,
    onClose: clear,
    onMoveClick,
  }

  const deleteConfirmationProps: DeleteConfirmationProps = {
    buttonText: 'Delete',
    className: `${rootClass}__delete-confirmation`,
    ...getDeleteConfirmationModalUtils({
      confirmationModal,
      deleteAllContacts,
      deleteConfirmationData,
      deleteFolder,
      deleteFromFolder,
      deleteSegments,
      deleteTag,
      folders,
      itemType: ItemType.SEGMENT,
      itemTypesUsed,
      t,
      update,
    }),
  }

  const duplicateProps: DuplicateFolderModalProps = {
    activeFolderId: segmentToClone?.folderId ?? activeFolderId,
    folderAllOptionLabel: t('All segments'),
    descriptionKey: 'ListPage.ContactSegments.Duplicate.Description',
    folders,
    headerTitleKey: 'Duplicate Segment',
    itemName: segmentToClone?.name ?? '',
    itemToCloneTags: segmentToClone?.tags,
    namePlaceholder: 'Segment name',
    onCancel: clear,
    onClone: (newName, tags, folder) => cloneSegment(segmentToClone, newName, tags, folder),
    onTagCreate: createTag,
    tags,
  }

  const statusToastProps: StatusToastProps = {
    closeStatus: closeStatusToast,
    message: statusMessage,
    status,
    title,
  }

  const selectAssetV2Props: SelectAssetV2Props = {
    isOpen: true,
    headerText: t('SelectImportContactsSourceModal.Header'),
    subHeaderText: t('SelectImportContactsSourceModal.SubHeader'),
    rowItems: [
      {
        title: t('SelectImportContactsSourceModal.Local.Title'),
        description: t('SelectImportContactsSourceModal.Local.Description'),
        name: IMPORT_SOURCE.LOCAL,
      },
      {
        name: IMPORT_SOURCE.FTP,
        title: t('SelectImportContactsSourceModal.Ftp.Title'),
        description: t('SelectImportContactsSourceModal.Ftp.Description'),
        hidden: !isFtpConnectorActive,
      },
    ],
    onAction: (selectedItem) => update({ importContactsSource: selectedItem }),
    onCancel: clear,
    initialSelectedAsset: IMPORT_SOURCE.LOCAL,
  }

  const selectFtpSyncJobProps: SelectAssetV2Props = {
    isOpen: true,
    headerText: t('ListPage.ContactSegments.FtpSyncModal.Header'),
    subHeaderText: t('ListPage.ContactSegments.FtpSyncModal.SubHeader'),
    onCancel: clear,
    onAction: (selectedItem) => window.open(`${rootContext}/importContactsV2/${IMPORT_SOURCE.FTP}/${selectedItem}/options`, '_self'),
    rowItems: ftpSyncJobs.map(({ importSyncJobId, filePath = '', lastExecution }): SelectAssetV2RowItem => {
      return {
        name: importSyncJobId.toString(),
        title: filePath ? filePath.substring(1) : `(${t('no file selected')})`,
        description: `Last sync: ${lastExecution ? formatDateWithAbbreviations(lastExecution, true) : '-'}`,
      }
    }),
    initialSelectedAsset: ftpSyncJobs[0]?.importSyncJobId.toString() ?? '',
  }

  const exportActions: { [key in EXPORT_ACTION]: (segmentToExport: Segment) => void } = {
    [EXPORT_ACTION.CSV]: () => {
      if (segmentToExport) {
        doExport(segmentToExport, update, t)
        onSegmentExport()
      }
    },
    [EXPORT_ACTION.CSV_WITH_UPLOADED_FILES]: () => {
      if (segmentToExport) {
        doExport(segmentToExport, update, t)
        onSegmentExport()
      }
    },
    [EXPORT_ACTION.MANAGE_FTP]: () => segmentToExport && getFtpExportSyncJobsList(segmentToExport),
    [EXPORT_ACTION.NEW_FTP]: () =>
      update({
        loadingFtpFiles: true,
        selectingFtpFile: true,
        selectingExportAction: false,
      }),
  }

  const selectExportActionProps: SelectAssetV2Props = {
    isOpen: true,
    headerText: 'SelectExportContactsActionModal.Header',
    subHeaderText: 'SelectExportContactsActionModal.SubHeader',
    onCancel: clear,
    onAction: (selectedItem) => segmentToExport && exportActions[selectedItem as EXPORT_ACTION](segmentToExport),
    rowItems: [
      {
        title: t('SelectExportContactsActionModal.Csv.Title'),
        description: t('SelectExportContactsActionModal.Csv.Description'),
        name: EXPORT_ACTION.CSV,
      },
      {
        title: t('SelectExportContactsActionModal.ManageFtp.Title'),
        description: t('SelectExportContactsActionModal.ManageFtp.Description'),
        name: EXPORT_ACTION.MANAGE_FTP,
        hidden: !segmentToExport?.ftpData?.export.syncJobs.length,
      },
      {
        title: t('SelectExportContactsActionModal.NewFtp.Title'),
        description: t('SelectExportContactsActionModal.NewFtp.Description'),
        name: EXPORT_ACTION.NEW_FTP,
      },
    ],
    initialSelectedAsset: EXPORT_ACTION.CSV,
  }

  const selectFtpExportFileProps: FileSelectWithSearchModalProps = {
    isOpen: true,
    onClose: clear,
    onAction: (file) => segmentToExport && history.push(`${rootContext}/exportContacts`, { selectedFtpFile: file, exportSegment: segmentToExport }),
    files: ftpFiles,
    loading: loadingFtpFiles,
    footerActionElement: (
      <Button
        className={`${rootClass}__action-button`}
        buttonType={ButtonType.INFO}
        iconPosition={ButtonIconPosition.LEFT}
        onClick={() => update({ creatingFtpFile: true, selectingFtpFile: false })}
      >
        <Svg name={SvgNames.downloadCloud} type={SvgType.ICON} />
        {t('Export to a new file')}
      </Button>
    ),
  }

  const selectFtpExportSyncProps: SelectAssetV2Props = {
    isOpen: true,
    headerText: 'SelectFtpExportSyncJobModal.Header',
    subHeaderText: 'SelectFtpExportSyncJobModal.SubHeader',
    onAction: (job) => segmentToExport && history.push(`${rootContext}/exportContacts/${job}/options`),
    onCancel: clear,
    rowItems: ftpExportSyncJobs.map((job): SelectAssetV2RowItem => {
      const executionDto = job.exportSyncJobExecutionDTOS?.filter(filterNotEmptyArray) ?? []
      return {
        name: job.exportSyncJobId.toString(),
        title: (job.filePath ?? '').substring(1),
        description: `Last export date: ${executionDto[0]?.executionEnd ? formatDateWithAbbreviations(executionDto[0].executionEnd, true) : '-'}`,
      }
    }),
    initialSelectedAsset: ftpSyncJobs[0]?.importSyncJobId.toString() ?? '',
  }

  const exportToFtpFileProps: ExportToFtpFileModalProps = {
    isOpen: true,
    defaultFileName: segmentToExport?.name,
    onClose: clear,
    onAction: (fileName) =>
      segmentToExport &&
      history.push(`${rootContext}/exportContacts`, {
        selectedFtpFile: { name: `${fileName}.csv`, urlPath: `/${fileName}.csv`, size: 0 },
        exportSegment: segmentToExport,
      }),
    handleBack: () => update({ loadingFtpFiles: true, creatingFtpFile: false, selectingFtpFile: true }),
  }

  const modalsState: ListPageModalsState = {
    showAddFolder: creatingFolder,
    showDeleteConfirmation: !!confirmationModal,
    showDuplicate: !!segmentToClone,
    showManageTag: showManageTag,
    showMoveToFolder: segmentsToMove.length > 0,
    showStatusToast: showStatusToast,
    showSelectAsset: selectingImportContactsSource,
    showSelectFtpSyncJob: !!manageFtpSyncSegmentId && ftpSyncJobs.length > 1,
    showFtpFileImportLog: !!ftpFileImportLogTarget,
    showExportActionModal: selectingExportAction,
    showSelectFtpFile: selectingFtpFile,
    showSelectFtpExportManage: ftpExportSyncJobs.length > 1 && selectingFtpExports,
    showCreateNewFtpFile: creatingFtpFile,
  }

  const closeModal: { [key in ListPageModal]: () => void } = {
    [ListPageModal.ADD_FOLDER]: addFolderProps.onClose,
    [ListPageModal.DELETE_CONFIRMATION]: deleteConfirmationProps.onClose,
    [ListPageModal.DUPLICATE]: duplicateProps.onCancel,
    [ListPageModal.MOVE_TO_FOLDER]: moveToFolderProps.onClose,
    [ListPageModal.SHOW_MANAGE_TAG]: () => update({ showManageTag: false }),
    [ListPageModal.STATUS_TOAST]: statusToastProps.closeStatus,
    [ListPageModal.SELECT_ASSET]: () => update({ selectingImportContactsSource: false }),
    [ListPageModal.SELECT_FTP_SYNC]: () => update({ manageFtpSyncSegmentId: undefined }),
    [ListPageModal.FTP_IMPORT_LOG]: () => update({ ftpFileImportLogTarget: undefined }),
    [ListPageModal.EXPORT_ACTION_MODAL]: selectExportActionProps.onCancel,
    [ListPageModal.SELECT_EXPORT_FILE]: selectFtpExportFileProps.onClose,
    [ListPageModal.MANAGE_FTP_EXPORT]: selectFtpExportSyncProps.onCancel,
    [ListPageModal.CREATE_FTP_FILE]: exportToFtpFileProps.onClose,
  }

  const importLogModalProps: ImportLogProps = {
    onClose: clear,
    importFileId: ftpFileImportLogTarget,
    isOpen: true,
  }

  const listPageModalsProps: ListPageModalsProps = {
    addFolderProps,
    closeModal: (modal) => closeModal[modal](),
    createTag,
    deleteConfirmationProps,
    duplicateProps,
    modalsState,
    moveToFolderProps,
    statusToastProps,
    tagToEdit,
    selectAssetV2Props,
    selectFtpSyncJobProps,
    importLogModalProps,
    selectExportActionProps,
    selectFtpExportFileProps,
    selectFtpExportSyncProps,
    exportToFtpFileProps,
  }

  return <ListPageModals {...listPageModalsProps} />
}

export default ContactSegmentsModals
