import React, { FC, useCallback } from 'react'

import Caution from '@components/Caution/Caution'
import Input from '@components/Input/Input'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { SvgNames } from '@components/Svg'
import Typography, { TextWeight } from '@components/Typography/Typography'
import UploadImage from '@components/UploadImage/UploadImage'
import { useTranslation } from '@const/globals'

import { ImageToInsertParams } from '../../ImagePickerWithActionsModal'
import { ValidFormatsSubheader } from '../ImageFileModal.utils'

import './UploadImageModalBody.css'

interface UploadImageModalBodyProps {
  setImageParams: React.Dispatch<React.SetStateAction<ImageToInsertParams>>
  imageParams: ImageToInsertParams
  folders: SelectV2SingleOption[]
  dataTest?: string
  accept?: string
  maxSize?: number
}

const rootClass = 'upload-image-modal-body'

const _1MB = Math.pow(2, 20)
const _10MB = 10 * _1MB

const UploadImageModalBody: FC<UploadImageModalBodyProps> = ({
  setImageParams,
  imageParams,
  folders,
  dataTest = rootClass,
  accept = '.jpg, .jpeg, .gif, .png',
  maxSize = _10MB,
}) => {
  const { t } = useTranslation()

  const handleTitleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setImageParams((cur) => ({ ...cur, title: e.target.value })),
    [setImageParams]
  )

  const handleImageChange = useCallback<React.ComponentProps<typeof UploadImage>['onImageChange']>(
    (image) => {
      const { imageBase64 = '', imageType = '', size = 0, title = '' } = { ...image }
      setImageParams((cur) => ({ ...cur, imageBase64, size, imageType, title }))
    },
    [setImageParams]
  )

  const handleFolderChange = useCallback<(option?: SelectV2SingleOption) => void>(
    (folder) => setImageParams((cur) => ({ ...cur, folder })),
    [setImageParams]
  )

  const { size, title, imageBase64, folder, imageType } = imageParams

  const isGif = imageType === 'gif'
  const showCaution = !!size && (isGif ? size > 5 * _1MB : size > _1MB)

  return (
    <div className={rootClass} data-test={dataTest}>
      <ValidFormatsSubheader accept={accept} />
      <UploadImage
        onImageChange={handleImageChange}
        withoutInsertFromURL
        maxSize={maxSize}
        image={imageBase64}
        accept={accept}
        className={`${rootClass}__uploader`}
        dataTest={`${dataTest}-uploader`}
      />
      {showCaution && (
        <Caution dataTest={`${dataTest}-caution`}>
          <Typography
            text={t('Upload.Image.Modal.Size.Caution', { context: isGif ? 'gif' : 'other' })}
            tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
          />
        </Caution>
      )}
      <Input
        label={t('Image title')}
        placeholder={t('Optional name for the image')}
        onChange={handleTitleChange}
        value={title}
        dataTest={`${dataTest}-input-title`}
      />
      <SelectV2
        label={t('Add to folder')}
        inputIcon={SvgNames.folder}
        options={folders}
        defaultValue={folder || folders[0]}
        onChange={handleFolderChange}
        insideModal
        isClearable={false}
        dataTest={`${dataTest}-select-folder`}
      />
    </div>
  )
}

export default UploadImageModalBody
