import React, { FC, Key, useContext, useState } from 'react'

import Accordion from '@components/Accordion/Accordion'
import Button, { ButtonType } from '@components/Button'
import ButtonCopy from '@components/ButtonCopy/ButtonCopy'
import RadioCard from '@components/RadioCard/RadioCard'
import RadioCardGroup from '@components/RadioCardGroup/RadioCardGroup'
import { SvgNames } from '@components/Svg'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Toggle from '@components/Toggle'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import SendApiDetailsModal from '@src/components/common/SendApiDetailsModal/SendApiDetailsModal'
import { detectEmailType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { useTranslation } from '@utils/const/globals'

import { SettingsFormChangeHandler } from '../../../../EmailComposerSettings.utils'

import './EventTriggeredEmailPane.css'

const EVENT_TRIGGERED_LINK = 'https://connect.act-on.com/hc/en-us/articles/6175279939351-Using-Act-On-s-Event-Triggered-Email-API'

export interface EventTriggeredEmailPaneProps {
  className?: string
  dataTest?: string
  onFieldChange: SettingsFormChangeHandler
  onConvertToTemplate: (discardDraft: boolean) => void
  onSendAPIDetails: (email: string, details: string, note: string) => Promise<void>
}

const rootClass = 'event-triggered-email-pane'

const EventTriggeredEmailPane: FC<EventTriggeredEmailPaneProps> = (props) => {
  const { dataTest = rootClass, onFieldChange, onConvertToTemplate, onSendAPIDetails } = props
  const {
    values: {
      message: { id, isTriggered, messageType, publishId },
      validations: { isContentMissing },
    },
    api: { updateModal },
  } = useContext(EmailComposerContext)

  const { isEmailABTest } = detectEmailType(messageType)
  const isPublishedAbTest = publishId?.startsWith('s')
  const [option, setOption] = useState<Key>('keep')
  const [showApiDetails, setShowApiDetails] = useState(false)
  const { t } = useTranslation()
  const { isEmailDraft } = detectEmailType(messageType)

  const handleSend = async (email: string, details: string, note: string) => {
    await onSendAPIDetails(email, details, note)
    setShowApiDetails(false)
  }

  const renderConvertOptions = (
    <div className={`${rootClass}__options__content`}>
      <RadioCardGroup selectedOption={option} onSelect={(value) => setOption(value)}>
        <RadioCard
          key={'keep'}
          title={t(`EmailComposer.Settings.EventTriggered.Options.Keep.Title`)}
          description={t(`EmailComposer.Settings.EventTriggered.Options.Keep.Description`)}
          dataTest={`${rootClass}-option-keep`}
        />

        <RadioCard
          key={'discard'}
          title={t(`EmailComposer.Settings.EventTriggered.Options.Discard.Title`)}
          description={t(`EmailComposer.Settings.EventTriggered.Options.Discard.Description`)}
          dataTest={`${rootClass}-option-discard`}
        />
      </RadioCardGroup>
      <div className={`${rootClass}__options__action`}>
        <Tooltip
          hide={!isContentMissing}
          sideOffset={0}
          trigger={
            <Button
              disabled={isContentMissing}
              onClick={() => onConvertToTemplate(option === 'discard')}
              buttonType={ButtonType.PRIMARY}
              dataTest={`${rootClass}-option-action`}
            >
              {t(`EmailComposer.Settings.EventTriggered.Options.ButtonText`)}
            </Button>
          }
        >
          {t(`EmailComposer.Design.TextEmail.Tooltip.BodyText.MissingContent`)}
        </Tooltip>
      </div>
    </div>
  )

  return (
    <div className={`${rootClass}`}>
      {showApiDetails && (
        <SendApiDetailsModal
          onClose={() => setShowApiDetails(false)}
          onSend={(email: string, details: string, note: string) => handleSend(email, details, note)}
          id={publishId ?? id}
        />
      )}
      <Typography
        type={TextType.BODY_TEXT_LIGHT}
        text={'EmailComposer.Settings.EventTriggered.Description'}
        tagProps={{ bold: { weight: TextWeight.BOLD } }}
        tagComponents={{
          TextLink: <TextLink link={EVENT_TRIGGERED_LINK} size={TextLinkSize.LARGE} />,
        }}
        dataTest={`${dataTest}-description`}
        inline
      />
      <hr className={`${rootClass}__divider`} />
      {isEmailDraft ? (
        <>
          <Typography text={'EmailComposer.Settings.EventTriggered.Warning'} tagProps={{ bold: { weight: TextWeight.BOLD } }} inline />
          <Accordion
            className={`${rootClass}__options__accordion`}
            dataTest={`${rootClass}-options`}
            childData={[
              {
                index: '0',
                header: (
                  <Typography
                    text={t('EmailComposer.Settings.EventTriggered.Options.Header')}
                    type={TextType.BODY_TEXT}
                    weight={TextWeight.MEDIUM}
                    lineHeight={LineHeight.MEDIUM_LARGE}
                  />
                ),
                content: renderConvertOptions,
              },
            ]}
            chevronLeft
          />
        </>
      ) : (
        <div className={`${rootClass}__details-container`}>
          <div className={`${rootClass}__toggle-container`}>
            <Typography weight={TextWeight.BOLD} text={t(`EmailComposer.Settings.EventTriggered.Toggle`)} />
            <Toggle isOn={isTriggered} onToggle={(value) => onFieldChange('isTriggered', value)} large dataTest={`${dataTest}-ete-toggle`} />
          </div>
          {isTriggered && (
            <>
              {!isEmailABTest || (isEmailABTest && isPublishedAbTest) ? (
                <>
                  <div className={`${rootClass}__id-container`}>
                    <Typography
                      text={`<bold>templateId:</bold> ${isEmailABTest ? publishId : id}`}
                      tagProps={{ bold: { weight: TextWeight.BOLD } }}
                      inline
                    />
                    <ButtonCopy
                      buttonText={t('EmailComposer.Settings.EventTriggered.Copy')}
                      onClick={() => navigator.clipboard.writeText(publishId || id)}
                    />
                  </div>
                  <TextLink
                    dataTest={`${dataTest}-api-modal-link`}
                    text={t('EmailComposer.Settings.EventTriggered.API')}
                    iconName={SvgNames.airplaneOutline}
                    iconLeft
                    size={TextLinkSize.LARGE}
                    onClick={() => {
                      updateModal('statusToast', undefined)
                      setShowApiDetails(true)
                    }}
                  />
                </>
              ) : (
                <div className={`${rootClass}__id-container`}>
                  <Typography text={`Publish A/B test email to get ID for API calls`} tagProps={{ bold: { weight: TextWeight.BOLD } }} inline />
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default EventTriggeredEmailPane
