import React, { FC, ReactNode } from 'react'

import CollapsibleMenuItemWithHeader, {
  CollapsibleMenuItemWithHeaderProps,
} from '@components/CollapsibleMenu/components/CollapsibleMenuItemWithHeader/CollapsibleMenuItemWithHeader'

import './CollapsibleMenuItemWithHeaderAndAction.css'

interface CollapsibleMenuItemWithHeaderAndActionProps extends CollapsibleMenuItemWithHeaderProps {
  action: ReactNode
}

const CollapsibleMenuItemWithHeaderAndAction: FC<CollapsibleMenuItemWithHeaderAndActionProps> = (
  props: CollapsibleMenuItemWithHeaderAndActionProps
) => {
  const { action } = props

  return <CollapsibleMenuItemWithHeader {...props}>{action}</CollapsibleMenuItemWithHeader>
}

export default CollapsibleMenuItemWithHeaderAndAction
