import React, { FC, useContext, useMemo } from 'react'
import { useHistory } from 'react-router'

import { YesNo } from '@components/ConfirmationModal'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import ProgressBar from '@components/ProgressBar/ProgressBar'
import SidebarMenu from '@components/SidebarMenu/SidebarMenu'
import { SvgNames } from '@components/Svg'
import { rootContext, useTranslation } from '@const/globals'
import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'
import { ProgramManagerTabs } from '@src/pages/programs/manager/ProgramManager.constants'
import { EarlyExitsUpgrade } from '@src/pages/programs/upgradeManager/components/EarlyExitsUpgrade/EarlyExitsUpgrade'
import { SourcesUpgrade } from '@src/pages/programs/upgradeManager/components/SourcesUpgrade/SourcesUpgrade'
import { StepIssues } from '@src/pages/programs/upgradeManager/components/StepIssues/StepIssues'
import {
  UpgradeManagerContainerInitialState,
  UpgradeManagerContext,
  UpgradeSourcesStep,
  UpgradeStep,
} from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'
import { hasEarlyExitConditionsToUpdate, useUpgradeProgramSteps } from '@src/pages/programs/upgradeManager/helper/UpgradeManager.helper'

const rootText = 'AutomatedPrograms.UpgradeManager'

interface UpgradeManagerProps {
  className: string
  clearSession: VoidFunction
  updateSession: VoidFunction
}

export const UpgradeManager: FC<UpgradeManagerProps> = (props: UpgradeManagerProps) => {
  const { className, clearSession, updateSession } = props

  const {
    values: { activeUpgradeStep, showUpgradeSidePanel, submitButtonDisabled, stepsToUpgrade, upgradedSteps, leavingPage },
    saveStep,
    update,
    upgradeSources,
  } = useContext(UpgradeManagerContext)
  const {
    values: { program, programUrlId },
    setContainerValues,
  } = useContext(ProgramManagerContext)
  const { t } = useTranslation()
  const history = useHistory()
  const { steps } = useUpgradeProgramSteps()

  const onSidebarClose = () => {
    clearSession()
    update({
      ...UpgradeManagerContainerInitialState,
      stepsToUpgrade: stepsToUpgrade,
      activeUpgradeStep: UpgradeStep.CONTACT_SOURCES,
    })
  }

  const hasEarlyExitConditions = useMemo(() => {
    return hasEarlyExitConditionsToUpdate(program)
  }, [program?.exit?.exitChoices, program?.sources])

  const hasStepsToUpgrade = stepsToUpgrade.length > 0

  const onSubmitSources: { [key in UpgradeSourcesStep]: () => void } = {
    [UpgradeStep.CONTACT_SOURCES]: () => {
      upgradeSources(UpgradeStep.CONTACT_SOURCES)
      setContainerValues({ tab: ProgramManagerTabs.EARLY_EXITS })
      update({ activeUpgradeStep: UpgradeStep.EARLY_EXITS })

      if (!hasEarlyExitConditions) {
        onSubmitSources[UpgradeStep.EARLY_EXITS]()
      }
    },
    [UpgradeStep.EARLY_EXITS]: () => {
      upgradeSources(UpgradeStep.EARLY_EXITS)
      setContainerValues({ tab: ProgramManagerTabs.JOURNEY_BUILDER })
      update({ activeUpgradeStep: UpgradeStep.FIX_STEP_ISSUES, currentStep: stepsToUpgrade[0], upgradedCurrentStep: upgradedSteps[0] })

      if (!hasStepsToUpgrade) {
        onSubmitSources[UpgradeStep.FIX_STEP_ISSUES]()
      }
    },
    [UpgradeStep.FIX_STEP_ISSUES]: () => {
      upgradeSources(UpgradeStep.FIX_STEP_ISSUES)
      setContainerValues({ showUpgrade: true })
      update({ showUpgradeSidePanel: false, activeUpgradeStep: UpgradeStep.REVIEW_UPGRADE })
    },
  }

  const onBackButtonClick: { [key in UpgradeSourcesStep]: () => void } = {
    [UpgradeStep.CONTACT_SOURCES]: () => {
      updateSession()
      history.push(`${rootContext}/upgrade/programs`, { programToUpgrade: { ...program, externalId: programUrlId } })
    },
    [UpgradeStep.EARLY_EXITS]: () => {
      setContainerValues({ tab: ProgramManagerTabs.SETTINGS })
      update({ activeUpgradeStep: UpgradeStep.CONTACT_SOURCES })
    },
    [UpgradeStep.FIX_STEP_ISSUES]: () => {
      setContainerValues({ tab: ProgramManagerTabs.EARLY_EXITS })
      saveStep()
      update({ activeUpgradeStep: UpgradeStep.EARLY_EXITS })

      if (!hasEarlyExitConditions) {
        onBackButtonClick[UpgradeStep.EARLY_EXITS]()
      }
    },
  }

  return (
    <>
      {leavingPage && (
        <DeleteConfirmationModal
          isOpen
          title={t(`${rootText}.ExitConfirmationModal.Title`)}
          body={t(`${rootText}.ExitConfirmationModal.Body`)}
          deleteButtonText={t(`${rootText}.ExitConfirmationModal.Exit`)}
          onAnswer={(answer) => {
            if (answer === YesNo.YES) {
              onSidebarClose()
            }
            update({ leavingPage: false })
          }}
        />
      )}
      {showUpgradeSidePanel && (
        <SidebarMenu
          className={className}
          header={t(`${rootText}.SidebarMenu.Header`)}
          icon={SvgNames.automation}
          onClose={() => update({ leavingPage: true })}
          onCancel={() =>
            [UpgradeStep.CONTACT_SOURCES, UpgradeStep.EARLY_EXITS, UpgradeStep.FIX_STEP_ISSUES].includes(activeUpgradeStep as UpgradeSourcesStep)
              ? onBackButtonClick[activeUpgradeStep as UpgradeSourcesStep]()
              : undefined
          }
          onSubmit={() =>
            [UpgradeStep.CONTACT_SOURCES, UpgradeStep.EARLY_EXITS, UpgradeStep.FIX_STEP_ISSUES].includes(activeUpgradeStep as UpgradeSourcesStep)
              ? onSubmitSources[activeUpgradeStep as UpgradeSourcesStep]()
              : undefined
          }
          submitButtonText={t('Next')}
          cancelButtonText={activeUpgradeStep === UpgradeStep.CONTACT_SOURCES ? t(`${rootText}.SidebarMenu.BackToSelectProgram`) : t('Back')}
          submitButtonDisabled={submitButtonDisabled}
        >
          <ProgressBar className={`${className}__progress-bar`} steps={steps} />
          {activeUpgradeStep === UpgradeStep.CONTACT_SOURCES ? (
            <SourcesUpgrade />
          ) : activeUpgradeStep === UpgradeStep.EARLY_EXITS ? (
            <EarlyExitsUpgrade />
          ) : (
            <StepIssues />
          )}
        </SidebarMenu>
      )}
    </>
  )
}
