import React, { FC, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import Modal, { ModalBody, ModalHeader } from '@components/Modal'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import StatusToast from '@components/StatusToast/StatusToast'
import { TableV2 } from '@components/TableV2/TableV2'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { useUpgradeLogModalRequests } from '@src/pages/UpgradeAssistant/components/UpgradeLogModal/GraphQL/UpgradeLogModalRequests.graphQL'
import {
  defaultState,
  LogItem,
  rootClass,
  UpgradeLogModalState,
} from '@src/pages/UpgradeAssistant/components/UpgradeLogModal/UpgradeLogModal.constants'
import { getTableColumns, getUpgradeLog } from '@src/pages/UpgradeAssistant/components/UpgradeLogModal/UpgradeLogModal.utils'
import { UpgradeItemType } from '@src/pages/UpgradeAssistant/UpgradeAssistant.contants'

import './UpgradeLogModal.css'

export interface UpgradeLogModalProps {
  className?: string
  dataTest?: string
  type: UpgradeItemType
  onClose: () => void
  onUpgrade: (logItem: LogItem) => void
  onRevert: (logItem: LogItem) => void
}

const UpgradeLogModal: FC<UpgradeLogModalProps> = (props: UpgradeLogModalProps) => {
  const { dataTest = rootClass, className = '', type, onClose, onUpgrade, onRevert } = props
  const [state, setState] = useState<UpgradeLogModalState>(defaultState)
  const { getUpgradeLogRequest, getItemsByExternalIdsResquest } = useUpgradeLogModalRequests()
  const { pageIndex, pageSize, items, totalItems, statusToast, loading, showConfirmationModal, itemToRevert } = state
  const { t } = useTranslation()

  useEffect(() => {
    getUpgradeLog(getUpgradeLogRequest, getItemsByExternalIdsResquest, pageIndex, pageSize, type, setState)
  }, [pageSize, pageIndex, type])

  const fetchData = (pageIndex: number, pageSize: number) => setState((prevState) => ({ ...prevState, pageSize, pageIndex }))

  const onActionClick = (logItem: LogItem, action: string) => {
    if (action === 'revert') {
      setState((prevState) => ({ ...prevState, showConfirmationModal: true, itemToRevert: logItem }))
    } else if (action === 'upgrade') {
      onUpgrade(logItem)
    }
  }
  const onConfirmationModalAnswer = (answer: YesNo) => {
    if (answer === YesNo.YES && itemToRevert) {
      onRevert(itemToRevert)
    }
    setState((prevState) => ({ ...prevState, showConfirmationModal: false, itemToRevert: undefined }))
  }
  const onStatusToastClose = () => setState((prevState) => ({ ...prevState, statusToast: { ...prevState.statusToast, showStatusToast: false } }))
  const tableColumns: ColumnDefWithAdditionalProps<LogItem>[] = useMemo(() => getTableColumns(type, onActionClick, t), [type])

  const header = (
    <ModalHeader headerType={ModalHeaderType.Form} closeButton={onClose} className={`${rootClass}__header`}>
      {t(`UpgradeLogModal.${type}.Title`)}
    </ModalHeader>
  )

  const renderUpgradeConfirmationModal = () => (
    <ConfirmationModal
      isYesNo
      isOpen
      okButtonText={t('Revert')}
      dataTest={rootClass}
      className={classNames(rootClass)}
      title={t('Are you sure?')}
      body={
        <Typography
          text={t('UpgradeLogModal.RevertConfirmationModal.Text', { type: type === UpgradeItemType.FORMS ? 'form' : 'program' })}
          type={TextType.BODY_TEXT_LIGHT}
          tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
        />
      }
      onAnswer={onConfirmationModalAnswer}
    />
  )

  return (
    <>
      {statusToast.showStatusToast && (
        <StatusToast status={statusToast.status} message={statusToast.statusMessage} closeStatus={onStatusToastClose} />
      )}
      {showConfirmationModal && renderUpgradeConfirmationModal()}
      <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen header={header} noPadding>
        <ModalBody className={`${rootClass}__body`}>
          <div className={`${rootClass}__body-content`}>
            <Typography
              className={`${rootClass}__body-content-title`}
              text={t(`UpgradeLogModal.${type}.Table.Title`, { count: totalItems })}
              type={TextType.BODY_TEXT_LARGE}
              weight={TextWeight.MEDIUM}
            />
            <TableV2
              loading={loading}
              data={items}
              columns={tableColumns}
              paginationState={{ pageIndex, pageSize, controlledPageCount: Math.ceil(totalItems / pageSize), dropdownInsideModal: true }}
              fetchData={fetchData}
              enableInsideLoader
              enablePaginate
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default UpgradeLogModal
