import React from 'react'

import Pill, { PillSize, PillType } from '@components/Pill/Pill'
import { SvgNames } from '@components/Svg'
import Typography from '@components/Typography/Typography'
import { rootContext } from '@utils/const/globals'

type MessageType = 'blank' | 'plain' | 'upload'

export const navigateToLegacyComposer = (start: MessageType) => {
  const types: { [key in MessageType]: string } = {
    blank: 'blank',
    plain: 'plain',
    upload: 'upload',
  }

  window.open(`${rootContext}/classic/if/legacy/emailMessage?start=${types[start]}`)
}

const createOption = (key: MessageType, title: string | JSX.Element, icon: SvgNames) => ({
  key,
  title,
  icon,
  onClick: () => navigateToLegacyComposer(key),
})

export const getSplitButtonOptions = () => [
  createOption('blank', 'Blank message', SvgNames.plus),
  createOption(
    'plain',
    <div className="split-button-title">
      <Typography text="Plain text email" />
      <Pill type={PillType.GRAY} text="Legacy" size={PillSize.SMALL} noMargin />
    </div>,
    SvgNames.appendedList
  ),
  createOption(
    'upload',
    <div className="split-button-title">
      <Typography text="Upload HTML code" />
      <Pill type={PillType.GRAY} text="Legacy" size={PillSize.SMALL} noMargin />
    </div>,
    SvgNames.upload
  ),
]
