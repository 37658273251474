import React, { Dispatch, FC, SetStateAction, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { disableClicks } from '@components/PreviewAssetModal/PreviewAssetModal.utils'
import { SvgType } from '@components/Svg'
import Switch, { SwitchOption, SwitchOptions } from '@components/Switch/Switch'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import { ensureFirstLetterIsCapitalized } from '@utils/strings'

import { useTemplateCatalogModalContentContext } from '../../TemplateCatalogModal.context'
import { buttonProps, labelProps } from '../../utils/TemplateCatalogModal.utils'

import './TemplateCatalogModalPreview.css'

const rootClass = 'template-catalog-modal-preview'

interface TemplateCatalogModalPreviewProps {
  setIsPreview: Dispatch<SetStateAction<boolean>>
  onSearchTermSelect: (term: string) => void
  dataTest?: string
}

export const TemplateCatalogModalPreview: FC<TemplateCatalogModalPreviewProps> = ({ setIsPreview, onSearchTermSelect, dataTest = rootClass }) => {
  const { previewItem, onSubTypeChange } = useTemplateCatalogModalContentContext()
  const [view, setView] = useState<SwitchOptions>(SwitchOptions.DESKTOP)

  const { t } = useTranslation()

  const toggleOptions: SwitchOption[] = [{ label: SwitchOptions.DESKTOP }, { label: SwitchOptions.MOBILE }]

  const handleToggle = useCallback((label: string) => {
    setView(label as SwitchOptions)
  }, [])

  const isMobile = view === SwitchOptions.MOBILE

  const handleSubTypeChange = (id: string) => {
    onSubTypeChange({ id, name: ensureFirstLetterIsCapitalized(id) ?? '' })
    setIsPreview(false)
  }

  const handleSearchTermSelect = (term: string) => {
    onSearchTermSelect(term)
    setIsPreview(false)
  }

  return (
    <div className={rootClass} data-test={dataTest}>
      <div className={classNames(`${rootClass}__template-container`, { [`${rootClass}__template-container-mobile`]: isMobile })}>
        <iframe
          srcDoc={disableClicks(previewItem?.htmlData ?? '')}
          title={previewItem?.title}
          className={classNames(`${rootClass}__template`, { [`${rootClass}__template-mobile`]: isMobile })}
        />
        <Switch options={toggleOptions} selected={view} onClick={handleToggle} className={`${rootClass}__switch`} iconSize={SvgType.LARGER_ICON} />
      </div>
      <div className={`${rootClass}__sidebar`}>
        <div className={`${rootClass}__header`}>
          <Typography
            type={TextType.SUB_HEADER}
            lineHeight={LineHeight.MEDIUM_SMALL}
            text={t('Email Templates').toUpperCase()}
            className={`${rootClass}__sub-header`}
          />
          <Typography text={previewItem?.title} type={TextType.PAGE_HEADER_SEMI_BOLD_LARGE} lineHeight={LineHeight.LARGER} />
        </div>
        {previewItem?.description && (
          <div className={`${rootClass}__description`}>
            <Typography text={t('Description')} {...labelProps} />
            <Typography text={previewItem?.description} />
          </div>
        )}
        {!!previewItem?.categories?.length && (
          <div className={`${rootClass}__categories-wrapper`}>
            <Typography text={t('Categories')} {...labelProps} />
            <div className={`${rootClass}__categories`}>
              {previewItem?.categories?.map((category) => {
                if (category) {
                  return (
                    <Button
                      key={category}
                      onClick={() => handleSubTypeChange(category)}
                      buttonType={ButtonType.PILL}
                      className={`${rootClass}__pill-button`}
                    >
                      <Typography text={ensureFirstLetterIsCapitalized(category)} {...buttonProps} />
                    </Button>
                  )
                }
              })}
            </div>
          </div>
        )}
        {!!previewItem?.tags?.length && (
          <div className={`${rootClass}__terms-wrapper`}>
            <Typography text={t('Related.Search.Terms')} {...labelProps} />
            <div className={`${rootClass}__terms`}>
              {previewItem?.tags?.map((tag) => {
                return (
                  <Button
                    key={tag}
                    onClick={() => handleSearchTermSelect(tag ?? '')}
                    buttonType={ButtonType.PILL}
                    className={`${rootClass}__pill-button`}
                  >
                    <Typography text={tag} {...buttonProps} />
                  </Button>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
