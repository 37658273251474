import { useState, useEffect } from 'react'

import { FolderData } from '@components/SortableFolders/components/Folder/Folder'

type useFolderNameExistErrorType = (folders: FolderData[], newFolderName: string, parentId?: number) => boolean

const searchFolderById = (folders: FolderData[], folderId: number): FolderData | undefined => {
  for (const folder of folders) {
    if (folder.id === folderId) {
      return folder
    }
    if (folder.subFolders) {
      const _folder = searchFolderById(folder.subFolders, folderId)
      if (_folder) {
        return _folder
      }
    }
  }
  return
}

const containFolderWithName = (folders: FolderData[], newName: string): boolean => {
  return folders.some((folder) => folder.name === newName)
}

const useFolderNameExistError: useFolderNameExistErrorType = (folders, newFolderName, parentId) => {
  const [hasError, setHasError] = useState<boolean>(false)

  useEffect(() => {
    if (!parentId) {
      setHasError(containFolderWithName(folders, newFolderName))
      return
    }
    const parentFolder = searchFolderById(folders, parentId)
    if (parentFolder && parentFolder.subFolders) {
      setHasError(containFolderWithName(parentFolder.subFolders, newFolderName))
    } else {
      setHasError(false)
    }
  }, [folders, newFolderName, parentId])
  return hasError
}

export default useFolderNameExistError
