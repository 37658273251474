import React, { useCallback } from 'react'
import { ConnectDragSource } from 'react-dnd'

import classNames from 'classnames'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextType } from '@components/Typography/Typography'
import { ReactSetStateAction } from '@interface/common'
import { flexRender } from '@tanstack/react-table'

import { CommonV2CellProps, TableTagState, TableV2TagsProps } from '../../tableV2TS/interfaces'
import { ColumnDefWithAdditionalProps } from '../../tableV2TS/types'
import { Expander } from '../Expander/Expander'
import { TableV2Tags } from '../TableV2Tags/TableV2Tags'

import './CellValue.css'

interface CellValueProps<T> extends CommonV2CellProps<T> {
  dataTest?: string
  tagState: TableTagState
  cellDisabled?: boolean
  isDraggable?: boolean
  enableSubRow?: boolean
  firstColumnsID?: string
  draggableRootClassName?: string
  tagProps?: Omit<TableV2TagsProps<T>, 'cell' | 'tagState' | 'setTagState'>
  dragRef?: ConnectDragSource
  setTagState: ReactSetStateAction<TableTagState>
}

const rootClass = 'cell-value'

export const CellValue = <T extends {}>({
  row,
  cell,
  cellDisabled = false,
  column,
  tagState,
  tagProps,
  parentRows,
  isDraggable,
  caretCellID,
  hoveredRowID,
  enableSubRow,
  firstColumnsID,
  dataTest = rootClass,
  draggableRootClassName,
  dragRef,
  hasToExpand,
  setTagState,
  onRowExpanded,
}: CellValueProps<T>) => {
  const { tags, selectedTagId, defaultTagsNumber, readOnlyTags, tagAction, onCreateTag, onApplyAndRemoveTags } = tagProps || {}
  const { columnDef }: { columnDef: ColumnDefWithAdditionalProps<T> } = column
  const cellWithCaretID = column.id === caretCellID
  const cellValue = flexRender(columnDef.cell, cell.getContext())

  const textType = useCallback(() => {
    let textType
    if (cellDisabled) {
      textType = TextType.BODY_TEXT_LIGHTER
    } else {
      textType = TextType.BODY_TEXT
    }
    return textType
  }, [row, cellDisabled])

  const typography = (
    <Typography
      key={row.id}
      text={cellValue}
      weight={columnDef.textWeight}
      className={classNames({ ['ellip']: !columnDef.enableLongContent }, { [`${rootClass}__flexCell`]: columnDef.enableFlexCell })}
      type={textType()}
    />
  )

  const cellValueOrTypography = columnDef.enableCustomCellValue ? cellValue : typography

  return (
    <>
      {isDraggable && column.id === firstColumnsID && (
        <span ref={dragRef} data-test={`${dataTest}-drag-source-${row.id}`} className={`${draggableRootClassName}-drag-source`}>
          <Svg name={SvgNames.drag} type={SvgType.ICON} />
        </span>
      )}

      {cellWithCaretID ? (
        <>
          {enableSubRow ? (
            <div className={classNames(columnDef.className, { [`${rootClass}__flexCell`]: columnDef.enableFlexCell })}>
              {!row.depth && !row.subRows.length ? null : (
                <Expander
                  row={row}
                  cell={cell}
                  column={column}
                  parentRows={parentRows}
                  caretCellID={caretCellID}
                  hoveredRowID={hoveredRowID}
                  hasToExpand={hasToExpand}
                  onRowExpanded={onRowExpanded}
                />
              )}
              {cellValueOrTypography}
            </div>
          ) : (
            cellValueOrTypography
          )}
        </>
      ) : columnDef.enableCustomCellValue && column.id === 'tags' ? (
        <TableV2Tags
          cell={cell}
          tags={tags}
          tagState={tagState}
          disabled={cellDisabled}
          readOnlyTags={readOnlyTags}
          selectedTagId={selectedTagId}
          defaultTagsNumber={defaultTagsNumber}
          tagAction={tagAction}
          onCreateTag={onCreateTag}
          setTagState={setTagState}
          onApplyAndRemoveTags={onApplyAndRemoveTags}
        />
      ) : (
        cellValueOrTypography
      )}
    </>
  )
}
