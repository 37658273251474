import React, { FC, useEffect, useMemo, useRef } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'

import { GraphQLError } from 'graphql/index'

import {
  contactPreferencesOptions,
  ContactStatus,
  FirstLineOption,
  MergeStrategy,
  UpsertContactSetting,
} from '@complex/ImportContacts/ImportContacts.constants'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { Status } from '@components/StatusToast/StatusToast'
import { rootContext, useTranslation } from '@const/globals'
import {
  FtpFileDto,
  ImportDefinitionDto,
  ImportDefinitionDtoInput,
  ImportSyncJobDto,
  ImportSyncJobDtoInput,
} from '@graphql/types/microservice/entity-upload-types'
import { useFieldMappingValidator } from '@src/pages/importcontacts/components/MappingScreen/utils/useFieldMappingValidator'
import { useAddContactsFromCRMClassicRequests } from '@src/pages/importcontacts/GraphQL/ImportContactsRequests.classic.graphQL'
import { useImportContactsV2Requests } from '@src/pages/ImportContactsV2/GraphQL/ImportContactsV2Request.graphQL'
import ImportContactsV2 from '@src/pages/ImportContactsV2/ImportContactsV2'
import { ImportContactsSources, ImportContactsSteps } from '@src/pages/ImportContactsV2/utils/ImportContactsV2.constants'
import {
  importContactsV2ContainerInitialState,
  ImportContactsV2ContainerState,
  ImportContactsV2Context,
} from '@src/pages/ImportContactsV2/utils/ImportContactsV2.context'
import {
  changeUrlPathOnTabChange,
  createSegmentUtils,
  getExternalIdMappingOption,
  getImportContactsV2ListingTabs,
  getMappingPreviews,
  getProgramScheduleFromCron,
  isAValidSegmentNameUtils,
  loadCsvMappingData,
} from '@src/pages/ImportContactsV2/utils/ImportContactsV2.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import { filterNotEmptyArray } from '@utils/array'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'
import { usePersistentState } from '@utils/hooks/usePersistentState'
import { useUnifiedContactList } from '@utils/hooks/useUnifiedContactList'
import { logNewRelicError } from '@utils/new-relic.utils'

type ImportContactsV2ContainerProps = RouteComponentProps<{ type: string; job?: string; section?: string }>

const IMPORT_CONTACTS_URL = `${rootContext}/importContactsV2`

const ImportContactsV2Container: FC<ImportContactsV2ContainerProps> = ({ match }: ImportContactsV2ContainerProps) => {
  const { t } = useTranslation()

  const [state, setState, { updateSessionData, clearSessionData, getDataFromSession }] = usePersistentState<ImportContactsV2ContainerState>({
    key: `importContacts${match.params.type}`,
    autoLoad: false,
    initialValue: {
      ...importContactsV2ContainerInitialState,
      steps: getImportContactsV2ListingTabs(t, !!match.params.job, match.params.section),
    },
  })
  const {
    addToSegment,
    contactPreference,
    externalIdMatch,
    fieldQuotes,
    fieldsSeparator,
    firstLineContains,
    ftpFiles,
    hasToUpdateFirstRows,
    importDefinitionId,
    importSyncJob,
    isUpdatingFromSession,
    loading,
    loadingSegments,
    mappingPreview,
    mergeRule,
    mergeStrategy,
    objectType = '',
    rejectRules,
    removeEntriesFromList,
    schedule,
    segmentOptions,
    segmentSelectedOption,
    selectedFile,
    statusToast,
    steps,
    updateAction,
    useCustomPrefix,
    unifiedListFieldMappings,
    validateAllTrigger,
    visibleFields,
  } = state

  const validationProcessRef = useRef({ inProgress: false, reviewClicked: false })
  const hasToMatchFieldsWithHeaders = useRef(true)

  const update = useDeepUpdate(setState)

  const history = useHistory()

  const { canHideObjectTypeCustomPrefix, hasFTPMirrorImport, userId, userName, accountId } = useAccountSettings()

  const { getUnifiedListFieldMappingsRequest } = useUnifiedContactList({ fetchOnMount: false, getDeletedMappings: true })

  const {
    createImportDefinitionRequest,
    getFtpFilesRequest,
    getFtpFirstRowsRequest,
    getImportDefinitionRequest,
    getImportSyncJobRequest,
    getTopDirectSelectSegmentsRequest,
    getSegmentById,
    updateImportDefinitionRequest,
    updateImportSyncJobRequest,
  } = useImportContactsV2Requests({ selectedFile, importDefinitionId, schedule, importSyncJob })

  const { createSegmentRequest, isAValidSegmentNameRequest } = useAddContactsFromCRMClassicRequests()
  const { validateAllFields, validateColumn } = useFieldMappingValidator<ImportContactsV2ContainerState>({
    containerValues: state,
    validationProcessRef,
    setStatusToast: (statusToast) => update({ statusToast }),
    setContainerValues: setState,
  })

  const currentStep = useMemo(() => steps.find(({ isActive }) => isActive), [steps])

  const closeStatusToast = () => {
    update({ statusToast: { ...statusToast, showStatusToast: false } })
  }

  const isAValidSegmentName = async (segmentName: string) => await isAValidSegmentNameUtils(isAValidSegmentNameRequest, segmentName, update)

  const createSegment = (segmentName: string) => {
    if (segmentOptions) {
      createSegmentUtils(createSegmentRequest, segmentName, update, segmentOptions)
    }
  }

  const getSelectedSegment = async (segmentId?: string) => {
    if (segmentId) {
      const selectedSegmentFromCurrentOptions = segmentOptions?.find((option) => option.value === segmentId)
      if (selectedSegmentFromCurrentOptions) {
        return selectedSegmentFromCurrentOptions
      } else {
        const selectedSegment = await getSegmentById(segmentId)
        if (selectedSegment) {
          const { name = '' } = JSON.parse(selectedSegment?.item ?? '{}')
          return { label: name, value: segmentId }
        }
      }
    }
  }

  const getSegmentOptions = async (page = 0) => {
    const { data } = await getTopDirectSelectSegmentsRequest(page)
    let newItems: SelectV2SingleOption[] = []
    if (data?.getTopDirectSelectSegments) {
      newItems = data.getTopDirectSelectSegments.map((item) => {
        const { name = '', id = '' } = JSON.parse(item?.item ?? '{}')
        return { label: name, value: id }
      })
    }
    return newItems
  }

  const updateImportSyncJob = async (values?: Partial<ImportSyncJobDtoInput>): Promise<ImportSyncJobDto | undefined> => {
    const { data, errors } = await updateImportSyncJobRequest(values)
    const importSyncJob = data?.updateImportSyncJob
    if (importSyncJob && !errors) {
      update({
        statusToast: {
          statusMessage: t('Success! Your changes have been saved.'),
          showStatusToast: true,
          status: Status.SUCCESS,
        },
      })
      return importSyncJob
    } else {
      onError(errors)
    }
  }

  const getImportDefinitionInput = (): ImportDefinitionDtoInput => {
    const mappings = getMappingPreviews(mappingPreview, mergeStrategy, externalIdMatch, unifiedListFieldMappings)
    return {
      ...rejectRules,
      accountId,
      bounceStatus: contactPreference.value !== '' ? (contactPreference.value as ContactStatus) : undefined,
      defaultMergeRule: mergeRule,
      delimiter: fieldsSeparator,
      hasHeader: firstLineContains === FirstLineOption.NAMES,
      importDefinitionId,
      importDefinitionName: selectedFile?.name || '',
      importSegmentIds: segmentSelectedOption?.value,
      mappings,
      mergeStrategy,
      mirrorImport: (!segmentSelectedOption && mergeStrategy === MergeStrategy.Email) || !hasFTPMirrorImport ? false : removeEntriesFromList,
      objectType:
        mergeStrategy === MergeStrategy.ExternalId
          ? useCustomPrefix || !canHideObjectTypeCustomPrefix
            ? `custom.${objectType}`
            : objectType
          : undefined,
      quote: fieldQuotes,
      upsertContactSetting: updateAction,
      userId,
      userName,
    }
  }

  const onError = (errors?: readonly GraphQLError[]) => {
    logNewRelicError(errors)
    update({
      statusToast: {
        statusMessage: t('Something went wrong on our end. Please try again.'),
        status: Status.FAIL,
        showStatusToast: true,
      },
    })
  }

  const createImportDefinition = async (): Promise<ImportDefinitionDto | undefined> => {
    const importDefinitionInput = getImportDefinitionInput()
    const { data, errors } = await createImportDefinitionRequest(importDefinitionInput)
    const importDefinition = data?.createImportDefinition
    if (importDefinition && !errors) {
      update({ importDefinitionId: data.createImportDefinition?.importDefinitionId })
      return importDefinition
    } else {
      onError(errors)
    }
  }

  const updateImportDefinition = async (): Promise<ImportDefinitionDto | undefined> => {
    const importDefinitionInput = getImportDefinitionInput()
    const { data, errors } = await updateImportDefinitionRequest(importDefinitionInput)
    const importDefinition = data?.updateImportDefinition
    if (importDefinition && !errors) {
      return importDefinition
    } else {
      onError(errors)
    }
  }

  const loadImportDefinition = async (importDefinitionId: number, selectedFile?: FtpFileDto) => {
    const importDefinition = await getImportDefinitionRequest(importDefinitionId)
    if (importDefinition) {
      const {
        bounceStatus,
        defaultMergeRule,
        delimiter,
        hasHeader,
        importDefinitionId,
        importSegmentIds,
        mappings,
        mergeStrategy,
        mirrorImport,
        objectType,
        quote,
        rejectHardBounces,
        rejectOptOut,
        rejectSuppressedDomains,
        upsertContactSetting,
      } = importDefinition
      const mappingsPreview = mappings?.filter(filterNotEmptyArray)
      const firstLineContains = hasHeader ? FirstLineOption.NAMES : FirstLineOption.VALUES
      const contactPreference = contactPreferencesOptions?.find((option) => option.value === bounceStatus)
      const segmentSelectedOption = await getSelectedSegment(importSegmentIds)
      update({
        addToSegment: !!importSegmentIds,
        contactPreference: !bounceStatus ? contactPreferencesOptions[0] : contactPreference,
        externalIdMatch: mergeStrategy === MergeStrategy.ExternalId ? getExternalIdMappingOption(mappingsPreview) : undefined,
        fieldQuotes: quote,
        fieldsSeparator: delimiter,
        firstLineContains,
        importDefinitionId,
        loading: false,
        mergeRule: defaultMergeRule,
        mergeStrategy,
        objectType: objectType?.startsWith('custom.') ? objectType.replace('custom.', '') : objectType,
        rejectRules: { rejectOptOut, rejectHardBounces, rejectSuppressedDomains },
        removeEntriesFromList: mirrorImport,
        segmentSelectedOption,
        updateAction: upsertContactSetting ?? UpsertContactSetting.OldestContact,
        useCustomPrefix: !objectType || objectType.startsWith('custom.') || !canHideObjectTypeCustomPrefix,
      })
      if (selectedFile) {
        loadCsvMappingData(update, selectedFile, getFtpFirstRowsRequest, firstLineContains, defaultMergeRule, delimiter, quote, mappingsPreview)
        if (mappingsPreview?.length) {
          hasToMatchFieldsWithHeaders.current = false
        }
      }
    }
    return importDefinition
  }

  const loadImportSyncJob = async (
    importSyncJobId: number
  ): Promise<{ importDefinitionId: number | undefined; selectedFile: FtpFileDto | undefined } | undefined> => {
    const importSyncJob = await getImportSyncJobRequest(importSyncJobId)
    if (importSyncJob) {
      const { filePath, importDefinitionId, cron } = importSyncJob
      const ftpFiles = await getFtpFilesRequest()
      const selectedFile = ftpFiles.find(({ urlPath }) => urlPath === filePath)
      update({
        selectedFile,
        importSyncJob,
        importDefinitionId,
        schedule: cron ? getProgramScheduleFromCron(cron) : { ...schedule, isScheduled: false, isUnscheduled: true, type: 'NEVER' },
      })
      return { importDefinitionId, selectedFile }
    }
    return
  }

  const handleOnSectionChange = async (newTab: string, currentStepCompleted = false) => {
    if (match.params.job) {
      changeUrlPathOnTabChange(newTab as ImportContactsSteps)
    }
    if (currentStep?.key === ImportContactsSteps.SELECT_FILE && selectedFile && mappingPreview.length === 0) {
      loadCsvMappingData(update, selectedFile, getFtpFirstRowsRequest, firstLineContains, mergeRule, fieldsSeparator, fieldQuotes)
    }
    if (currentStep?.key === ImportContactsSteps.IMPORT_OPTIONS) {
      if (!importDefinitionId) {
        const importDefinition = await createImportDefinition()
        if (!importDefinition) {
          return
        }
      }
      const hasToUncheckSegmentCheckbox = addToSegment && !segmentSelectedOption
      if (hasToUncheckSegmentCheckbox) {
        update({ addToSegment: false })
      }
    }
    update({
      steps: steps.map((step) => {
        if (step.key === currentStep?.key) {
          return { ...step, isActive: false, isCompleted: currentStepCompleted || step.isCompleted }
        } else if (step.key === newTab) {
          return { ...step, isActive: true }
        } else {
          return step
        }
      }),
    })
  }

  useEffect(() => {
    if (selectedFile && hasToUpdateFirstRows) {
      hasToMatchFieldsWithHeaders.current = true
      loadCsvMappingData(update, selectedFile, getFtpFirstRowsRequest, firstLineContains, mergeRule, fieldsSeparator, fieldQuotes)
      update({ hasToUpdateFirstRows: false })
    }
  }, [hasToUpdateFirstRows])

  useEffect(() => {
    const loadInitialValues = async () => {
      const [segmentOptions, unifiedListFieldMappings] = await Promise.all([getSegmentOptions(), getUnifiedListFieldMappingsRequest()])
      update({ segmentOptions, unifiedListFieldMappings, loadingSegments: false })
    }
    loadInitialValues()
  }, [])

  useEffect(() => {
    const clearListener = history.listen(({ pathname }) => {
      if (pathname !== `${IMPORT_CONTACTS_URL}/${match.params.type}` && !!selectedFile && !importSyncJob) {
        updateSessionData()
      }
    })
    return clearListener
  }, [state])

  useEffect(() => {
    if (validateAllTrigger) {
      validateAllFields()
      if (hasToMatchFieldsWithHeaders.current) {
        hasToMatchFieldsWithHeaders.current = false
      }
    }
  }, [validateAllTrigger])

  useEffect(() => {
    if (!loadingSegments || !match.params.job) {
      if (match.params.job && importDefinitionId === undefined && importSyncJob === undefined) {
        loadImportSyncJob(parseInt(match.params.job)).then((data) => {
          if (data?.importDefinitionId) {
            const { importDefinitionId, selectedFile } = data
            loadImportDefinition(importDefinitionId, selectedFile)
          }
        })
      } else {
        update({ loading: false })
      }
    }
  }, [match.params.job, segmentOptions])

  useEffect(() => {
    if (!loading && !isUpdatingFromSession) {
      hasToMatchFieldsWithHeaders.current = true
      update({
        ...importContactsV2ContainerInitialState,
        ftpFiles,
        importDefinitionId,
        importSyncJob,
        loading: false,
        segmentOptions,
        steps: getImportContactsV2ListingTabs(t, false),
        visibleFields,
      })
    }
  }, [selectedFile])

  useEffect(() => {
    if (isUpdatingFromSession && selectedFile) {
      update({ isUpdatingFromSession: false })
    }
  }, [isUpdatingFromSession])

  return (
    <ImportContactsV2Context.Provider
      value={{
        values: state,
        handleOnSectionChange,
        closeStatusToast,
        onError,
        updateImportDefinition,
        updateImportSyncJob,
        update,
        validateColumn,
        isAValidSegmentName,
        createSegment,
        getSegmentOptions,
      }}
    >
      <ImportContactsV2
        clearSession={clearSessionData}
        getDataFromSession={getDataFromSession}
        currentStep={currentStep ?? steps[0]}
        hasToMatchFieldsWithHeaders={hasToMatchFieldsWithHeaders}
        type={match.params.type as ImportContactsSources}
        jobUrlId={match.params.job}
      />
    </ImportContactsV2Context.Provider>
  )
}

export default ImportContactsV2Container
