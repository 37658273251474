import React, { ChangeEvent, FC, useCallback, useState } from 'react'

import classNames from 'classnames'

import ScheduleSelector from '@complex/ScheduleSelector/ScheduleSelector'
import { DEFAULT_SCHEDULE } from '@complex/ScheduleSelector/utils/ScheduleSelector.utils'
import Button, { ButtonType } from '@components/Button'
import Input from '@components/Input/Input'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import { useTranslation } from '@const/globals'
import { ListMaintenanceProgramInput } from '@graphql/types/mutation-types'
import { handleScheduleChange } from '@src/pages/listingPages/ListMaintenancePrograms/utils/Helpers'

import './CreateProgramModal.css'

interface CreateProgramModalProps {
  className?: string
  dataTest?: string
  onClose: () => void
  onContinue: (program: ListMaintenanceProgramInput) => void
  defaultProgram?: ListMaintenanceProgramInput
}

const defaultProgramInput: ListMaintenanceProgramInput = {
  schedule: DEFAULT_SCHEDULE,
  name: '',
  description: '',
}

const rootClass = 'create-program-modal'

const CreateProgramModal: FC<CreateProgramModalProps> = (props: CreateProgramModalProps) => {
  const { dataTest = rootClass, className = '', defaultProgram, onClose, onContinue } = props

  const [hasName, setHasName] = useState<boolean>(false)
  const [state, setState] = useState<ListMaintenanceProgramInput>({ ...defaultProgramInput, ...defaultProgram })
  const { description, name, schedule } = state

  const { t } = useTranslation()

  const onNameChange = useCallback(({ target: { value: name } }: ChangeEvent<HTMLInputElement>) => {
    setState((state) => ({ ...state, name }))
    setHasName(!name)
  }, [])

  const onDescriptionChange = useCallback(
    ({ target: { value: description } }: ChangeEvent<HTMLInputElement>) => setState((state) => ({ ...state, description })),
    []
  )

  const onContinueClick = () => {
    if (name) {
      onContinue({ name, description, schedule })
    }
  }

  const header = (
    <ModalHeader dataTest={`${dataTest}-header`} headerType={ModalHeaderType.List} className={`${rootClass}__header`}>
      {t('New Program')}
    </ModalHeader>
  )

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen header={header}>
      <ModalBody dataTest={`${dataTest}-body`} className={`${rootClass}__body`}>
        <Input
          dataTest={`${dataTest}-input-name`}
          className={`${rootClass}__input-name`}
          label={t('Program name')}
          value={name}
          onChange={onNameChange}
          error={hasName}
          /* eslint-disable-next-line jsx-a11y/no-autofocus */
          autoFocus
        />
        <Input
          dataTest={`${dataTest}-input-description`}
          className={`${rootClass}__input-description`}
          label={t('Program description')}
          value={description}
          onChange={onDescriptionChange}
        />
        <ScheduleSelector
          dataTest={`${dataTest}-schedule-selector`}
          className={`${rootClass}__schedule-selector`}
          defaultValue={schedule}
          onChange={(schedule) => {
            setState((state) => ({ ...state, schedule: handleScheduleChange(schedule) }))
          }}
          showOptionsOnManual
        />
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.List} className={`${rootClass}__footer`}>
        <Button buttonType={ButtonType.TERTIARY} onClick={onClose} dataTest={`${dataTest}-button-tertiary`}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} onClick={onContinueClick} dataTest={`${dataTest}-button-primary`} disabled={hasName}>
          {t('Continue')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CreateProgramModal
