import React, { FC, useEffect, useState } from 'react'

import ListingPage from '@complex/ListingPage/Components/ListPage/ListingPage'
import { deleteFolderById, getFolderById, setActiveFolder } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import {
  DuplicateListingPageItem,
  ItemTypesUsedCount,
  ListingPageCommonContext,
  ListingPageProps,
  ListPageAPI,
  ListPageCommonState,
} from '@complex/ListingPage/Context/ListingPageCommon.context'
import { useFolderRequests } from '@complex/ListingPage/GraphQL/Folders.graphQL'
import { useHeaderActionRequests } from '@complex/ListingPage/GraphQL/HeaderActions.graphQL'
import { useTagRequests } from '@complex/ListingPage/GraphQL/Tags.graphQL'
import { removeItemFromFolderMessage } from '@complex/ListingPage/Utils/ListingPage.constants'
import { filterOutSelectedItems, useGetDefaultValues, isSalesUserAccount } from '@complex/ListingPage/Utils/ListingPage.utils'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { Status } from '@components/StatusToast/StatusToast'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { FolderDto, ItemDto, LabelDto, LabelDtoInput } from '@graphql/types/microservice/categorization-types'
import { BulkAssetMutationResponse } from '@graphql/types/mutation-types'
import { Folder } from '@interface/Folder'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import { logNewRelicError } from '@utils/new-relic.utils'

import { useFolderSubTypes } from './Hooks/useFolderSubTypes'
import { useListingPageBase } from './Hooks/useListingPageBase'

export interface ListPageContainerProps {
  listingPageProps: ListingPageProps
  dataTest: string
  itemType?: ItemType
  className?: string
}

const rootClass = 'list-page-container'

const ListingPageContainer: FC<ListPageContainerProps> = (props: ListPageContainerProps) => {
  const { listingPageProps, itemType, dataTest = rootClass, className = '' } = props

  const folderSubTypes = useFolderSubTypes(listingPageProps)
  const { saveFolderRequest, renameFolderRequest, deleteFolderRequest } = useFolderRequests(folderSubTypes)
  const { createTagRequest, deleteTagRequest, getItemTypesUsedRequest } = useTagRequests()
  const { moveItemsToFoldersMutationRequest, deleteFromFolderMutationRequest, shareToCatalogMutationRequest } = useHeaderActionRequests()
  const {
    customDeleteItemsCall,
    customDuplicateItem,
    readOnlyFolders = false,
    sidebarProps: { customSources: customSourcesProps },
  } = listingPageProps

  const { t } = useTranslation()

  const update = (fieldsToUpdate: Partial<ListPageCommonState>) => {
    setContainerValues((containerValues) => ({
      ...containerValues,
      ...fieldsToUpdate,
    }))
  }

  useEffect(() => {
    if (listingPageProps.doFetchItems) {
      update({ fetchItems: true })
    }
  }, [listingPageProps.doFetchItems])

  const accountSettings = useAccountSettings()
  const isSalesUser = isSalesUserAccount(accountSettings)

  const [containerValues, setContainerValues] = useState<ListPageCommonState>({
    ...useGetDefaultValues(listingPageProps, dataTest, itemType),
    selectedCustomSource: customSourcesProps && customSourcesProps[0],
    isSalesUser,
    readOnlyFolders,
  })

  const {
    activeFolderId,
    activeTagId,
    addToFolder,
    selectedIds,
    initialPageLoading,
    selectedRows,
    folders,
    items,
    currentPage,
    listingPageProps: {
      isDeleteCallWithBulkResponse,
      sidebarProps: { allItemFilter },
      tableProps: { listPage, rowDisabled, isRowSelectionDisabled },
    },
  } = containerValues

  const {
    currentTag,
    onSearch,
    setStatusToast,
    getFilterItems,
    getFilterItemsWithCustomRequests,
    getTagItems,
    getFolderItems,
    setItems,
    setError,
    setTag,
    setFilter,
    setFolder,
    toggleSubType,
    onColumnSort,
    setBulkResponseModal,
    onClickTagInRow,
    onShouldFetch,
  } = useListingPageBase(containerValues, update, setContainerValues)

  useEffect(() => {
    const {
      customDeleteItemsCall,
      customPreviewItemCall,
      getCustomEmptyListingProps,
      renderCustomModal,
      getCustomDefaultEmptyListingProps,
      getCustomActiveFilter,
      getCustomFilterParams,
      renderDataCards,
      onItemsFetched,
      onWindowMessage,
      onWindowActive,
      onPageLoad,
    } = listingPageProps
    update({
      listingPageProps: {
        ...listingPageProps,
        customPreviewItemCall,
        customDeleteItemsCall,
        getCustomEmptyListingProps,
        renderCustomModal,
        getCustomDefaultEmptyListingProps,
        getCustomActiveFilter,
        getCustomFilterParams,
        renderDataCards,
        onItemsFetched,
        onWindowMessage,
        onWindowActive,
        onPageLoad,
      },
    })
  }, [listingPageProps])

  useEffect(() => {
    const onWindowMessage = listingPageProps.onWindowMessage
    if (onWindowMessage) {
      const handler = (event: MessageEvent<any>) => onWindowMessage(event)
      window.addEventListener('message', handler)
      return () => {
        window.removeEventListener('message', handler)
      }
    }
  }, [listingPageProps.onWindowMessage])

  useEffect(() => {
    const onWindowActive = listingPageProps.onWindowActive
    if (onWindowActive) {
      const handler = (event: Event) => document.visibilityState === 'visible' && onWindowActive(event, update, setStatusToast, items)
      document.addEventListener('visibilitychange', handler)
      return () => {
        document.removeEventListener('visibilitychange', handler)
      }
    }
  }, [listingPageProps.onWindowActive, setStatusToast])

  useEffect(() => {
    const onPageLoad = listingPageProps.onPageLoad

    if (onPageLoad) {
      onPageLoad(setStatusToast, update)
    }
  }, [listingPageProps.onPageLoad, setStatusToast])

  const createFolder = async (folder: FolderDto) => {
    const { data, errors } = await saveFolderRequest(`${itemType}`, folder)

    if (errors) {
      setError(`ListPage.Common.Folders.${folder.id ? 'Updated' : 'Added'}.Error`, Status.FAIL)
    } else {
      update({ hasToExpandFolders: [data?.setFolder?.parentId] })
      setStatusToast(`ListPage.Common.Folders.${folder.id ? 'Updated' : 'Added'}`, Status.SUCCESS)
    }
  }

  const renameFolder = async (folder: FolderDto) => {
    const { errors } = await renameFolderRequest(`${itemType}`, folder)

    const statusMessage = (
      <Typography
        text={t(`ListPage.Common.Folders.Rename.${errors ? 'Fail' : 'Success'}Toast`)}
        values={{ folder: folder }}
        tagProps={{ bold: { weight: TextWeight.BOLD } }}
        inline
      />
    )

    if (errors) {
      setError(statusMessage, Status.FAIL)
    } else {
      setStatusToast(statusMessage, Status.SUCCESS)
    }
  }

  const deleteFolder = async (folder: FolderData) => {
    const { id, parentId } = folder
    const { data, errors } = await deleteFolderRequest(`${itemType}`, folder)

    if (data && data?.deleteFolder) {
      const { id: deletedFolderId, name = '' } = data?.deleteFolder
      const editedFolders = deleteFolderById(deletedFolderId, folders)

      update({
        fetchFolders: true,
        statusToast: {
          statusMessage: t('ListPage.Common.Folders.Delete.SuccessToast', { name }),
          showStatusToast: true,
          status: Status.SUCCESS,
        },
        folders: editedFolders,
      })

      if (activeFolderId) {
        const refreshFragments =
          activeFolderId === id ||
          activeFolderId === parentId ||
          (folder?.subFolders && !!getFolderById(activeFolderId, folder.subFolders as Folder[]))

        if (refreshFragments) {
          parentId ? setActiveFolder(update, parentId, folders) : setFilter(allItemFilter, false)
        }
      }
    } else if (errors) {
      setError(t('ListPage.Common.Folders.Delete.FailToast'), Status.FAIL)
    }
  }

  const createTag = async (tag: LabelDtoInput) => {
    const { errors } = await createTagRequest(`${itemType}`, tag)

    if (errors) {
      setError(`ListPage.Common.Tags.${tag.id ? 'Updated' : 'Added'}.Error`, Status.FAIL)
    } else {
      setTag(tag)
      setStatusToast(`ListPage.Common.Tags.${tag.id ? 'Updated' : 'Added'}`, Status.SUCCESS)

      update({ fetchItems: true })
    }
  }

  const deleteTag = async (tag: LabelDto) => {
    const { errors } = await deleteTagRequest(`${itemType}`, tag)

    if (errors) {
      setError('ListPage.Common.Tags.Delete.FailToast', Status.FAIL)
    } else {
      setTag(tag)
      setStatusToast('ListPage.Common.Tags.Delete.SuccessToast', Status.SUCCESS)
      if (activeTagId === tag.id) {
        update({ filterActive: allItemFilter })
      }
      setFilter(allItemFilter, false)
    }
  }

  const getItemTypesUsedInTags = async (tagId: number) => {
    try {
      const { data } = await getItemTypesUsedRequest(tagId)
      const labelData = data?.getItemTypesByLabel
      // getItemTypesByLabel return type is 'any'
      const result = typeof labelData !== 'object' ? {} : labelData
      Object.keys(result).forEach((key) => {
        if (!(key in ItemType)) {
          delete result[key]
        }
      })
      return result as ItemTypesUsedCount
    } catch (error) {
      logNewRelicError(error)
      return {}
    }
  }

  const deleteItems = async (itemsToDelete: ItemDto[]) => {
    if (customDeleteItemsCall) {
      const response = await customDeleteItemsCall(itemsToDelete)
      const tProps = { count: itemsToDelete.length }
      const filteredItems = filterOutSelectedItems(items, selectedIds)
      const showSuccessToast = () => setStatusToast(t(`ListPage.${listPage}.DeleteItem.SuccessToast`, tProps), Status.SUCCESS)
      const refreshItems = (addtionalFields?: Partial<ListPageCommonState>) =>
        update({
          movingItem: false,
          fetchItems: true,
          fetchTags: true,
          fetchFolders: true,
          fetchFilterCounts: true,
          isProcessingAction: true,
          forceResetSelectedRows: true,
          ...addtionalFields,
        })

      if (!response.errors) {
        if (isDeleteCallWithBulkResponse) {
          const key = Object.keys(response.data)[0]
          const bulkResponse = response.data[key] as BulkAssetMutationResponse

          if (bulkResponse.failedIds.length) {
            setBulkResponseModal(bulkResponse, `ListPage.${listPage}.DeleteItem.Bulk`, { className: `${rootClass}__delete-response` })
          } else {
            showSuccessToast()
            refreshItems({
              items: filteredItems,
              currentPage: filteredItems.length < 50 ? 0 : currentPage,
            })
          }
        } else {
          showSuccessToast()
          refreshItems({
            items: filteredItems,
            currentPage: filteredItems.length < 50 ? 0 : currentPage,
          })
        }
      } else {
        setError(t(`ListPage.${listPage}.DeleteItem.FailToast`, tProps), response.errors)
        refreshItems()
      }
    }
  }

  const duplicateItem = async (params: DuplicateListingPageItem, listPageAPI: ListPageAPI) => {
    if (customDuplicateItem) {
      await customDuplicateItem(params, listPageAPI)
    }
  }

  const moveItemsToFolder = async (folderId: number) => {
    const cannotMove = selectedRows.every((item) => item.folderId === folderId)
    if (cannotMove) {
      const folder = getFolderById(folderId, folders)
      const statusMessage = (
        <>
          <Typography
            text={t(`ListPage.${listPage}.MoveToFolder.Warning`, { count: selectedRows.length, folder })}
            tagProps={{ bold: { weight: TextWeight.MEDIUM } }}
            inline
          />
        </>
      )
      setStatusToast(statusMessage, Status.WARNING)
    } else {
      update({ isProcessingAction: true })
      const { errors } = await moveItemsToFoldersMutationRequest(`${itemType}`, selectedIds, folderId)

      const folder = getFolderById(folderId, folders)
      const key = addToFolder ? `ListPage.${listPage}.MoveToFolder.Added` : `ListPage.${listPage}.MoveToFolder.Moved`
      const statusMessage = (
        <>
          <Typography
            text={t(key, {
              count: selectedIds.length,
              folder,
            })}
            tagProps={{ bold: { weight: TextWeight.BOLD } }}
            inline
          />
        </>
      )

      const filteredItems = filterOutSelectedItems(items, selectedIds)

      if (!errors) {
        setStatusToast(statusMessage, Status.SUCCESS)
        update({
          movingItem: false,
          fetchItems: true,
          items: activeFolderId && filteredItems.length !== 0 ? filteredItems : items,
          currentPage: filteredItems.length < 50 ? 0 : currentPage,
          fetchFolders: true,
        })
      } else {
        setError(
          <Typography
            text={t(`ListPage.${listPage}.MoveToFolder.FailToast`, { count: selectedRows.length, folder })}
            tagProps={{ bold: { weight: TextWeight.BOLD } }}
            inline
          />,
          errors
        )
      }
    }
  }

  const removeItemsFromFolder = async () => {
    update({ isProcessingAction: true, showRemoveFromFolder: false })

    const { data, errors } = await deleteFromFolderMutationRequest(`${itemType}`, selectedIds)
    const haveDifferentFolders = [...new Set(selectedRows.map(({ folderId }) => folderId))].length > 1 ? '.DifferentFolders' : ''

    if (data) {
      const filteredItems = filterOutSelectedItems(items, selectedIds)

      setStatusToast(
        removeItemFromFolderMessage(
          selectedRows,
          folders,
          `ListPage.${listPage}.DeleteFromFolder.SuccessToast${haveDifferentFolders}`,
          haveDifferentFolders,
          activeFolderId
        ),
        Status.SUCCESS
      )
      update({
        fetchItems: true,
        fetchFolders: true,
        items: activeFolderId && filteredItems.length !== 0 ? filteredItems : items,
        currentPage: filteredItems.length < 50 ? 0 : currentPage,
      })
    } else {
      setError(removeItemFromFolderMessage(selectedRows, folders, `ListPage.${listPage}.DeleteFromFolder.FailToast`, '', activeFolderId), errors)
    }
  }

  const shareToCatalog = async () => {
    update({ isProcessingAction: true })
    const externalIds = selectedRows.map((item) => item.externalId)

    const { data, errors } = await shareToCatalogMutationRequest(`${itemType}`, externalIds as string[])

    if (data) {
      if (data?.shareAssetToCatalog.failedIds && data.shareAssetToCatalog.failedIds.length > 0) {
        const failedAssets = data.shareAssetToCatalog.failedIds.map((failed) => failed?.name ?? '').join()
        const movedAssets = data.shareAssetToCatalog.successIds

        setStatusToast(
          t(`ListPage.${listPage}.ShareToCatalog.FailToast.Secondary`, {
            count: selectedRows.length,
            movedAssets: movedAssets?.length,
            failedAssets,
          }),
          Status.FAIL
        )
      } else {
        setStatusToast(t(`ListPage.${listPage}.ShareToCatalog.SuccessToast`, { count: selectedRows.length }), Status.SUCCESS)
      }
    } else {
      setError(t(`ListPage.${listPage}.ShareToCatalog.FailToast`), errors)
    }

    update({ isProcessingAction: false, showShareToCatalog: false })
  }

  return (
    <ListingPageCommonContext.Provider
      value={{
        update,
        setFolder,
        setItems,
        setFilter,
        toggleSubType,
        setTag,
        setStatusToast,
        setBulkResponseModal,
        setError,
        getFilterItems,
        getFilterItemsWithCustomRequests,
        getFolderItems,
        getItemTypesUsedInTags,
        renameFolder,
        getTagItems,
        createFolder,
        createTag,
        deleteFolder,
        deleteTag,
        deleteItems,
        onSearch,
        moveItemsToFolder,
        removeItemsFromFolder,
        shareToCatalog,
        onColumnSort,
        duplicateItem,
        onClickTagInRow,
        rowDisabled,
        isRowSelectionDisabled,
        onShouldFetch,
        currentTag,
        values: { ...containerValues },
      }}
    >
      <ListingPage loading={initialPageLoading} className={className} />
    </ListingPageCommonContext.Provider>
  )
}

export default ListingPageContainer
