import { gql } from '@apollo/client'

export default gql`
  query accountRegistration($accountId: ID) {
    accountRegistration(accountId: $accountId) {
      accountId
      maximumRunTime
      memoryOverrideGB
      enabled
      selectedParentSegment
    }
  }
`
