import { gql } from 'apollo-boost'

export default gql`
  query getAvailableFields($entityTypes: [String!]) {
    availableFields(entityTypes: $entityTypes) {
      entityType
      fieldDefinitions {
        fieldName
        displayName
        dataType
        referenceEntity
        pickListValues
        labelLookups
        lengthLimit
        defaultValue
        defaultedOnCreate
        required
        creatable
        updateable
      }
    }
  }
`
