import React, { FC, useContext } from 'react'

import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import { ModalHeader } from '@components/Modal'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { rootText } from '@src/pages/ContactSegments/components/CopySegments/constants/CopySegments.constants'
import { CopySegmentsContext, SegmentActions } from '@src/pages/ContactSegments/components/CopySegments/context/CopySegments.context'
import { useUnmappedFieldSegments } from '@src/pages/ContactSegments/components/CopySegments/utils/UseUnmappedFieldSegments'

import './FieldMatchingWarningModal.css'

const rootClass = 'field-matching-warning-modal'
const modalText = `${rootText}Modal.FieldMatchingWarningModal.`

const FieldMatchingWarningModal: FC = () => {
  const {
    values: { segmentAction },
    update,
  } = useContext(CopySegmentsContext)
  const { t } = useTranslation()

  const unmappedFieldsSegments = useUnmappedFieldSegments()

  const onAnswer = (answer: YesNo) => {
    if (answer === YesNo.YES) {
      if (segmentAction !== SegmentActions.NOTHING) {
        update({ showDependenciesModal: true })
      } else {
        update({ showReviewSegmentsModal: true })
      }
    }
    update({ showFieldMatchingWarningModal: false })
  }

  return (
    <ConfirmationModal
      isOpen
      className={rootClass}
      body={
        <>
          <div className={`${rootClass}__info-container`}>
            <Typography text={t(`${modalText}Info`)} type={TextType.BODY_TEXT_LIGHT} lineHeight={LineHeight.MEDIUM} />
          </div>
          <Typography
            text={t(`${modalText}SegmentWarning.Title`, { count: unmappedFieldsSegments.length })}
            type={TextType.BODY_TEXT}
            weight={TextWeight.MEDIUM}
            lineHeight={LineHeight.MEDIUM}
          />
          <div className={`${rootClass}__segment-container`}>
            {unmappedFieldsSegments.map((segment) => (
              <div key={segment.id} className={`${rootClass}__segment`}>
                <Svg name={SvgNames.warningSolidNoFill} type={SvgType.LARGER_ICON} fill={SvgColor.YELLOW_CAUTION} />
                <TextWithTooltipOnEllip typographyProps={{ text: t(`${modalText}SegmentWarning.Warning`, { segment: segment.name }) }} />
              </div>
            ))}
          </div>
        </>
      }
      title={
        <ModalHeader className={`${rootClass}__header`} headerType={ModalHeaderType.Form}>
          {t(`${modalText}Title`)}
        </ModalHeader>
      }
      isYesNo
      noButtonText={t('Cancel')}
      yesButtonText={t('Submit')}
      onAnswer={onAnswer}
      showDivider
    />
  )
}

export default FieldMatchingWarningModal
