import React, { ChangeEvent, FC, useMemo, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { Checkbox } from '@components/Checkbox/Checkbox'
import Input, { InputProps } from '@components/Input/Input'
import Label from '@components/Label'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import NestedDropDown from '@components/NestedDropDown/NestedDropDown'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { Status } from '@components/StatusToast/StatusToast'
import { SvgNames } from '@components/Svg'
import { TOAST_TEXT_CLASSNAME } from '@components/Toast/Toast'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { FolderDto } from '@graphql/types/microservice/categorization-types'
import { flattenFolders, getFolderDropDownOptions } from '@utils/folderUtils'

import useFolderNameExistError from './utils/folderNameExistError'

import './NewAddFolderModal.css'

export interface AddFolderModalProps {
  className?: string
  dataTest?: string
  folders: FolderData[]
  nestedWithin?: number
  hoveredFolder?: FolderData
  isOpen: boolean
  isEditing?: boolean
  onClose: () => void
  onCreate: (folder: FolderDto) => void
  onModalSave: (obj: object) => void
}

interface State {
  folderName: string
  isNested: boolean
  parentFolderId?: number
}

const rootClass = 'new-add-folder-modal'

const NewAddFolderModal: FC<AddFolderModalProps> = (props: AddFolderModalProps) => {
  const { dataTest = rootClass, className = '', folders, hoveredFolder, nestedWithin, isOpen, isEditing, onClose, onCreate, onModalSave } = props
  const [state, setState] = useState<State>({
    folderName: '',
    isNested: !!nestedWithin,
    parentFolderId: nestedWithin,
  })
  const { folderName, isNested, parentFolderId } = state

  const { t } = useTranslation()
  const folderNameExist = useFolderNameExistError(folders, folderName, parentFolderId)

  const handleClose = () => {
    setState({ ...state, isNested: false, parentFolderId: undefined })
    onClose()
  }

  const getFolderNameExistsFailMessage = (folderName: string) => (
    <Typography
      className={TOAST_TEXT_CLASSNAME}
      text={'{{folderName}} already exists at this folder level'}
      values={{ folderName }}
      tagProps={{ bold: { weight: TextWeight.BOLD } }}
      inline
    />
  )

  const onSave = () => {
    if (folderNameExist) {
      onModalSave({
        statusMessage: getFolderNameExistsFailMessage(folderName),
        showStatusToast: true,
        status: Status.FAIL,
      })
    } else {
      const folder: FolderDto = {
        name: folderName,
        parentId: isNested ? parentFolderId : undefined,
        id: isEditing && !!hoveredFolder ? hoveredFolder.id : undefined,
      }
      onCreate(folder)
    }
  }

  const onFolderSelect = (parentFolderId: number) => {
    setState({ ...state, parentFolderId })
  }

  const setFolderName = (event: ChangeEvent<HTMLInputElement>) => {
    const folderName = event.target.value.trim()
    setState({ ...state, folderName })
  }

  const header = (
    <ModalHeader>
      <Typography
        text={isEditing ? t('Rename Folder') : t('Create a new folder')}
        type={TextType.SECTION_HEADER}
        lineHeight={LineHeight.MEDIUM_LARGE}
        weight={TextWeight.MEDIUM}
      />
    </ModalHeader>
  )

  const inputProps: Partial<InputProps> = useMemo(
    () => ({
      ...(hoveredFolder && isEditing ? { placeholder: hoveredFolder.name, value: hoveredFolder.name } : {}),
    }),
    [hoveredFolder, isEditing]
  )

  const canCreateFolder = () => folderName && (!isNested || parentFolderId)

  const renderModalBody = () => {
    return (
      <ModalBody>
        <Label className={`${rootClass}__input-label`}>{t('Folder name')}</Label>
        <Input
          className={classNames({
            [`${rootClass}__input`]: !folderName,
            [`${rootClass}__input-valid`]: !!folderName,
          })}
          placeholder={t('Folder name')}
          value={folderName}
          onChange={setFolderName}
          dataTest={`${rootClass}-input`}
          error={folderNameExist}
          {...inputProps}
        />
        {folderNameExist && <Typography text={t('That folder name already exists. Please choose a different name.')} type={TextType.ERROR} />}
        {!isEditing && (
          <div className={`${rootClass}__nested`}>
            <Checkbox
              className={`${rootClass}__nested-checkbox`}
              checked={isNested}
              label={t('Nest within a folder:')}
              onChange={(checked) => setState({ ...state, isNested: checked })}
            />
            {isNested && (
              <NestedDropDown
                withFooter
                defaultSelected={nestedWithin ? nestedWithin.toString() : undefined}
                className={`${rootClass}__select`}
                allOptions={getFolderDropDownOptions(flattenFolders(folders))}
                options={getFolderDropDownOptions(folders)}
                placeholderIcon={SvgNames.folderNested}
                title={t('Select a folder')}
                footerPrimaryButtonText={t('Nest here')}
                withTitle
                onSubmit={(selected) => {
                  if (selected) {
                    onFolderSelect(+selected)
                  }
                }}
              />
            )}
          </div>
        )}
      </ModalBody>
    )
  }

  const renderModalFooter = () => {
    return (
      <ModalFooter>
        <Button buttonType={ButtonType.TERTIARY} onClick={handleClose}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} disabled={!canCreateFolder()} onClick={onSave} dataTest={`${dataTest}-primary-button`}>
          {isEditing ? t('Rename') : t('Create')}
        </Button>
      </ModalFooter>
    )
  }

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header} showOverflow>
      {renderModalBody()}
      {renderModalFooter()}
    </Modal>
  )
}

export default NewAddFolderModal
