import React, { useCallback, useEffect, useMemo } from 'react'

import LabelWithSvgTooltip from '@components/LabelWithTooltipIcon/LabelWithSvgTooltip'
import { useTranslation } from '@const/globals'
import { PwdPolicyResponse } from '@graphql/types/query-types'
import { SecurityOptionEditContentRefType } from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Security/components/SecuritySection/SecuritySection'
import SecuritySettingDropdown from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Security/components/SecuritySettingDropdown/SecuritySettingDropdown'
import {
  historyPasswordsCount,
  passwordComplexityOptions,
  passwordExpirationDays,
  passwordLengthOptions,
  SecurityOptionType,
} from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Security/constants/security.options'

import './PasswordPoliciesForm.css'

interface Props {
  contentRef: SecurityOptionEditContentRefType
  setIsEnabled: (enabled: boolean) => void
  data?: PwdPolicyResponse
  dataTest?: string
}

const rootClass = 'password-policies-form'

const PasswordPoliciesForm = (props: Props) => {
  const { contentRef, setIsEnabled, data, dataTest = rootClass } = props
  const { t } = useTranslation()

  useEffect(() => {
    setIsEnabled(false)
    contentRef.data = data
  }, [data])

  const onDropdownSelect = useCallback(
    (keyValue: { [key: string]: string }) => {
      setIsEnabled(true)
      contentRef.data = { ...contentRef.data, ...keyValue }
    },
    [setIsEnabled]
  )

  const lengthOptions = useMemo<SecurityOptionType[]>(
    () =>
      passwordLengthOptions.map((option) => ({
        label: t('Password.Length.Option.Label', { length: option }),
        id: option.toString(),
      })),
    [t]
  )

  const complexityOptions = useMemo<SecurityOptionType[]>(
    () =>
      passwordComplexityOptions.map((option) => ({
        label: t(`Password.Complexity.Option.Label.${option}`),
        id: option.toString(),
      })),
    [t]
  )

  const expirationOptions = useMemo<SecurityOptionType[]>(
    () =>
      passwordExpirationDays.map((option) => ({
        label: typeof option === 'string' ? t(option) : t('Password.Expiration.Option.Label', { count: option }),
        id: option.toString(),
      })),
    [t]
  )

  const historyCountOptions = useMemo<SecurityOptionType[]>(
    () =>
      historyPasswordsCount.map((option) => ({
        label: t('Password.History.Option.Label', { count: option }),
        id: option.toString(),
      })),
    [t]
  )

  return (
    <div data-test={dataTest} className={rootClass}>
      <SecuritySettingDropdown options={lengthOptions} onSelect={onDropdownSelect} label={t('Length')} value={data?.length} name="length" />
      <SecuritySettingDropdown
        options={complexityOptions}
        onSelect={onDropdownSelect}
        label={<LabelWithSvgTooltip label={t('Complexity')} tooltipContent={t('Password.Complexity.Tooltip.Message')} />}
        value={data?.complexity}
        name="complexity"
      />
      <SecuritySettingDropdown
        options={expirationOptions}
        onSelect={onDropdownSelect}
        label={t('Expiration')}
        value={data?.expiration}
        name="expiration"
      />
      <SecuritySettingDropdown options={historyCountOptions} onSelect={onDropdownSelect} label={t('History')} value={data?.history} name="history" />
    </div>
  )
}

export default PasswordPoliciesForm
