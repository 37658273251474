import React, { FC, useEffect, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import ListPickerModal from '@complex/ListPickerModal/ListPickerModal'
import { ListPickerModal as ListPickerModalV2 } from '@complex/ListPickerModalV2/ListPickerModal'
import { MIRRORED_LISTS_ID, UNIFIED_CONTACTS_ID } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import { getListSchemaUtils } from '@complex/ListPickerModalV2/utils/ListPickerModalWrapperUtils'
import Loader from '@components/Loader/Loader'
import { ProgramSource } from '@graphql/types/query-types'
import { List } from '@interface/foldersLists/List'
import { useAccountSettings } from '@utils/account/account.utils'

export interface Props {
  programSources: ProgramSource[]
  isOpen: boolean
  defaultSelectedLists?: string[]
  multiSelect?: boolean
  disableLegacyLists?: boolean
  disableUnifiedLists?: boolean
  restrictMixingLegacyListsAndUCL?: boolean
  showLoader?: boolean
  submitDisabledOnSelect?: boolean
  onProgramSourcesUpdate: (programSources: ProgramSource[]) => void
  onSubmit?: (lists: List[]) => void
  onClose: () => void
  dataTest?: string
}

export interface State {
  loadingLists?: List[]
}

const rootClass = 'list-picker-wrapper'

export const ListPickerWrapper: FC<Props> = (props: Props) => {
  const {
    isOpen,
    programSources,
    defaultSelectedLists,
    multiSelect,
    onClose,
    onProgramSourcesUpdate,
    onSubmit,
    disableUnifiedLists = false,
    disableLegacyLists = false,
    restrictMixingLegacyListsAndUCL = false,
    showLoader = true,
    submitDisabledOnSelect = false,
    dataTest = rootClass,
  } = props
  const { hasShowActOnContactsTab, phase3FormSubmission } = useAccountSettings()
  const hasAOContactsSources =
    hasShowActOnContactsTab &&
    (programSources.length === 0 ||
      programSources.some(
        (sourceList) =>
          sourceList.baseId === UNIFIED_CONTACTS_ID || sourceList.id.startsWith(MIRRORED_LISTS_ID) || sourceList.baseId.startsWith(MIRRORED_LISTS_ID)
      ))

  const client = useApolloClient()
  const [state, setState] = useState<State>({})
  const { loadingLists } = state

  useEffect(() => {
    if (loadingLists) {
      const listIds = loadingLists.map((list) => list.id)
      getListSchemaUtils(state, setState, client, listIds, onProgramSourcesUpdate, onClose)
    }
  }, [loadingLists])

  if (loadingLists) {
    return showLoader ? <Loader center /> : null
  }

  return hasShowActOnContactsTab ? (
    <ListPickerModalV2
      dataTest={`${dataTest}-list-picker-modal`}
      isOpen={isOpen}
      closeModal={onClose}
      defaultSelectedLists={defaultSelectedLists}
      multiSelect={multiSelect}
      hideUCL={!hasAOContactsSources}
      restrictMixingLegacyListsAndUCL={restrictMixingLegacyListsAndUCL}
      disableLegacyLists={disableLegacyLists}
      disableUnifiedLists={disableUnifiedLists}
      submitDisabledOnSelect={submitDisabledOnSelect}
      hasUnifiedFormSubmissions={phase3FormSubmission}
      hideAllUCLListTypes={!hasAOContactsSources}
      hideLegacyListToggle={!hasAOContactsSources}
      submitLists={(lists: List[]) => {
        onSubmit?.(lists)
        setState({
          ...state,
          loadingLists: lists,
        })
      }}
    />
  ) : (
    <ListPickerModal
      dataTest={`${dataTest}-list-picker-modal`}
      isOpen={isOpen}
      closeModal={onClose}
      selectedLists={defaultSelectedLists}
      submitLists={(lists: List[]) => {
        onSubmit?.(lists)
        setState({
          ...state,
          loadingLists: lists,
        })
      }}
    />
  )
}

export default ListPickerWrapper
