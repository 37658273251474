import React, { FC } from 'react'

import Select from '@components/Select/Select'
import { useTranslation } from '@const/globals'
import { ProgramGoToSetStep } from '@graphql/types/query-types'
import { Step, Track } from '@utils/program/program.constants'

interface StepSelectorProps {
  name: string
  step: ProgramGoToSetStep
  tracks: Track[]
  register?: any
}

export interface State {
  options?: (JSX.Element | null)[]
}

export function buildOptions(tracks: Track[], t: Function) {
  const options: JSX.Element[] = []
  tracks.forEach((track) => {
    const optGroup: JSX.Element[] = []
    track.steps.forEach((step: Step, i: number) => {
      if (!step || !step.stepId || track.steps.length - 1 === i) return null
      let stepIndex = i + 1
      if (track.id === 't0') {
        stepIndex--
        if (i === 0) {
          return
        }
      }
      optGroup.push(
        <option key={step.stepId} value={step.stepId}>
          {`${track.letter}-${stepIndex} ${step.displayName}`}
        </option>
      )
    })
    if (optGroup.length > 0) {
      options.push(
        <optgroup key={track.letter} label={`${t('Track')} ${track.letter}`}>
          {optGroup}
        </optgroup>
      )
    }
  })
  return options
}

export const StepSelector: FC<StepSelectorProps> = (props: StepSelectorProps) => {
  const { t } = useTranslation()
  const [state, setState] = React.useState<State>({})

  React.useEffect(() => {
    setState({
      ...state,
      options: buildOptions(props.tracks, t),
    })
  }, [])

  if (!state.options) return null

  return (
    <Select label={t('Go To')} name={props.name} register={props.register} defaultValue={props.step.goToStepId ?? ''}>
      {state.options}
    </Select>
  )
}

export default StepSelector
