import React, { FC } from 'react'

import classNames from 'classnames'

import AdvertisingBanner from '@components/AdvertisingBanner/AdvertisingBanner'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { LEARN_MORE_URL, UPSELL_LINK } from '@src/pages/reports/components/reports.constants'

import './AnalyticsUpsell.css'

interface AnalyticsUpsellProps {
  onDismiss: () => void
  className?: string
  dataTest?: string
}

const rootClass = 'analytics-upsell'

const AnalyticsUpsell: FC<AnalyticsUpsellProps> = (props: AnalyticsUpsellProps) => {
  const { onDismiss, dataTest = rootClass, className = '' } = props

  return (
    <AdvertisingBanner
      headline={'Want to make this liveboard truly yours?'}
      info={'With Advanced Analytics, you can customize & create your own data analysis.'}
      learnMore={{
        text: 'Learn more about Analytics',
        url: LEARN_MORE_URL,
      }}
      actionButton={{
        text: 'Upgrade to Analytics',
        onClick: () => window.open(UPSELL_LINK, `_blank`),
      }}
      bannerImage={StaticImageNames.analytics}
      onDismiss={onDismiss}
      className={classNames(rootClass, className)}
      dataTest={dataTest}
    />
  )
}

export default AnalyticsUpsell
