import React, { FC, useContext } from 'react'

import classNames from 'classnames'

import Container from '@components/Container'
import EmptyListing from '@components/EmptyListing/EmptyListing'
import { renderLoader } from '@components/Loader/Loader'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { TextAlign, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { StandardField, UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import FieldMappingUsageModal from '@src/pages/datamanagement/components/CrmContacts/components/FieldMappingUsage/FieldMappingUsageModal'
import { checkFieldUsage } from '@src/pages/datamanagement/components/CrmContacts/CrmContactUtil'
import SchemaEditor, { SchemaType } from '@src/pages/datamanagement/components/NonCrmContacts/SchemaEditor/SchemaEditor'
import { DataManagementContext, Row } from '@src/pages/datamanagement/context/DataManagementContext'
import { DataTypeList } from '@src/pages/importcontacts/context/ImportContactsContext'
import { FieldValidationState } from '@utils/crm.utils'
import { useFormService } from '@utils/hooks/microservices/useFormService'

import './NonCrmContacts.css'

export enum SchemaUpdateAction {
  STANDARD,
  CUSTOM,
  CUSTOM_NAME,
  CUSTOM_DATATYPE,
  CUSTOM_SCORESHEET,
}

type Props = {
  className?: string
  dataTest?: string
  loading: boolean
  showFirstTime: boolean
  setShowFirstTime: (show: boolean) => void
}

const rootClass = 'non-crm-contacts'

const NonCrmContacts: FC<Props> = (props: Props) => {
  const { className = '', dataTest = rootClass, loading, showFirstTime, setShowFirstTime } = props
  const {
    update,
    values: { fieldMappings, fieldMappingUsageWarning, standardFieldsMap, nonCrmContactsData },
  } = useContext(DataManagementContext)

  const { t } = useTranslation()

  const { getFormsByColumnNameRequest } = useFormService()

  const findDeletedField = (displayName?: string): number => {
    if (displayName !== undefined && displayName !== '') {
      return fieldMappings.findIndex((field) => field.deleted && field.displayName === displayName)
    }

    return -1
  }

  const checkDuplicateNames = (mapping: UnifiedListFieldMapping, rowId: number) => {
    const mappings = nonCrmContactsData.standardRows.concat(nonCrmContactsData.customRows)
    const duplicates = mappings.reduce((acc: any[], row) => {
      return row.id !== rowId && row.mapping.displayName === mapping.displayName && !row.mapping.deleted ? [...acc, row] : acc
    }, [])

    if (duplicates.length > 0) {
      if (mapping.standardFieldKey) {
        return { status: FieldValidationState.DUP_STD, duplicates: duplicates }
      } else {
        return { status: FieldValidationState.DUP_CUST, duplicates: duplicates }
      }
    } else {
      if (!mapping.standardFieldKey) {
        const standardFieldsList = [...standardFieldsMap.values()]
        const matchesStdField = standardFieldsList.some((stdField) => stdField.label === mapping.displayName)
        if (matchesStdField) {
          return { status: FieldValidationState.CUST_MATCH_STD, duplicates: [] }
        }
      }
      return { status: FieldValidationState.OK, duplicates: [] }
    }
  }

  const addNewField = (row: Row, action: SchemaUpdateAction, value: string) => {
    let newField: UnifiedListFieldMapping

    if (action === SchemaUpdateAction.STANDARD) {
      const stdField = standardFieldsMap?.get(value)

      newField = {
        ...row.mapping,
        displayName: stdField?.label,
        standardFieldKey: stdField?.key,
        dataType: stdField?.dataType,
        hidden: stdField?.hidden,
        readOnly: stdField?.readOnly,
      }
    } else {
      newField = {
        ...row.mapping,
        displayName: value,
        dataType: row.mapping.dataType === undefined ? DataTypeList[0].id : row.mapping.dataType,
      }
    }

    const existingFieldIndex = findDeletedField(newField?.displayName)
    if (existingFieldIndex !== -1) {
      newField.columnIndex = fieldMappings[existingFieldIndex].columnIndex
      fieldMappings.splice(existingFieldIndex, 1)
    }

    const data = { ...nonCrmContactsData, index: nonCrmContactsData.index + 1 }
    const { status: rowStatus } = checkDuplicateNames(newField, data.index)

    if (action === SchemaUpdateAction.STANDARD) {
      data.standardRows = [...data.standardRows, { id: data.index, mapping: newField, status: rowStatus }]
    } else {
      data.customRows = [...data.customRows, { id: data.index, mapping: newField, status: rowStatus }]
    }

    update('nonCrmContactsData', data)
    update('fieldMappings', [...fieldMappings, newField])
  }

  const updateField = (row: Row, action: SchemaUpdateAction, value: string) => {
    const fieldsCopy = [...fieldMappings]
    let updatedField: UnifiedListFieldMapping = {}
    const index = fieldMappings.indexOf(row.mapping)

    switch (action) {
      case SchemaUpdateAction.STANDARD:
        const stdField = standardFieldsMap.get(value) as StandardField
        updatedField = {
          ...row.mapping,
          standardFieldKey: stdField.key,
          dataType: stdField.dataType,
          displayName: stdField.label,
          hidden: stdField.hidden,
          readOnly: stdField.readOnly,
        }
        break
      case SchemaUpdateAction.CUSTOM_NAME:
        updatedField = { ...row.mapping, displayName: value }
        break
      case SchemaUpdateAction.CUSTOM_DATATYPE:
        updatedField = { ...row.mapping, dataType: value }
        break
      case SchemaUpdateAction.CUSTOM_SCORESHEET:
        updatedField = { ...row.mapping, typeMetaData: JSON.stringify(value) }
        break
      default:
        return
    }

    if (
      (action !== SchemaUpdateAction.CUSTOM_NAME && action !== SchemaUpdateAction.STANDARD) ||
      row.mapping.displayName !== updatedField.displayName
    ) {
      const existingFieldIndex = findDeletedField(updatedField?.displayName)
      if (existingFieldIndex !== -1) {
        replaceField(fieldsCopy, index, existingFieldIndex)
      } else {
        // Check if new name is duplicated
        const { status: rowStatus } = checkDuplicateNames(updatedField, row.id)
        const data = { ...nonCrmContactsData }
        const updatedRow = {
          id: row.id,
          mapping: updatedField,
          status: rowStatus,
        }

        // Check if the old name was duplicated
        const { duplicates: prevDuplicates } = checkDuplicateNames(row.mapping, row.id)

        // If there was only one duplicated, set it to OK
        if (prevDuplicates.length === 1) {
          prevDuplicates[0].status = FieldValidationState.OK
        }

        const rows = action === SchemaUpdateAction.STANDARD ? data.standardRows : data.customRows
        const dataIndex = rows.findIndex((actualRow) => actualRow.id === row.id)
        rows[dataIndex] = updatedRow

        update('nonCrmContactsData', data)

        fieldsCopy[index] = updatedField
        update('fieldMappings', fieldsCopy)
      }
    }
  }

  const replaceField = (fieldsCopy: UnifiedListFieldMapping[], actualIndex: number, deletedIndex: number) => {
    const updatedField: UnifiedListFieldMapping = {
      ...fieldsCopy[deletedIndex],
      dataType: fieldsCopy[actualIndex].dataType,
      deleted: false,
    }

    const deletedField = fieldsCopy.splice(deletedIndex, 1)
    actualIndex = deletedIndex < actualIndex ? actualIndex - 1 : actualIndex

    let data = { ...nonCrmContactsData }
    let rows = fieldsCopy[actualIndex].standardFieldKey ? data.standardRows : data.customRows

    const deletedRowIndex = rows.findIndex((row) => row.mapping.displayName === deletedField[0].displayName)
    rows.splice(deletedRowIndex, 1)
    const fieldRowIndex = rows.findIndex((row) => row.mapping === fieldsCopy[actualIndex])

    if (fieldRowIndex !== -1) {
      rows[fieldRowIndex].mapping = updatedField
      rows[fieldRowIndex].status = FieldValidationState.OK
    } else {
      data = { ...data, index: data.index + 1 }
      const row = { id: data.index, mapping: updatedField, status: FieldValidationState.OK }
      rows = [...rows, row]
    }

    if (fieldsCopy[actualIndex].standardFieldKey) {
      data.standardRows = rows
    } else {
      data.customRows = rows
    }

    update('nonCrmContactsData', data)

    fieldsCopy[actualIndex] = updatedField
    update('fieldMappings', fieldsCopy)
  }

  const handleDelete = async (row: Row) => {
    const fieldsCopy = [...fieldMappings]
    const fieldIndex = fieldMappings.indexOf(row.mapping)

    let isRequired = false
    if (row.mapping.standardFieldKey) {
      isRequired = standardFieldsMap.get(row.mapping.standardFieldKey)?.required || false
    }

    if (!isRequired) {
      const isBeingUsed = await checkFieldUsage(row.mapping, update, getFormsByColumnNameRequest, fieldMappingUsageWarning)
      if (!isBeingUsed) {
        const { duplicates: duplicatedRows } = checkDuplicateNames(row.mapping, row.id)
        const data = { ...nonCrmContactsData }

        if (duplicatedRows.length === 1 && duplicatedRows[0].status !== FieldValidationState.CUST_MATCH_STD) {
          const duplicatedRow = duplicatedRows[0]
          duplicatedRow.status = FieldValidationState.OK

          if (!duplicatedRow.mapping.columnIndex && row.mapping.columnIndex) {
            duplicatedRow.mapping.columnIndex = row.mapping.columnIndex
          }
        }

        if (!fieldsCopy[fieldIndex].columnIndex || duplicatedRows.length > 0) {
          fieldsCopy.splice(fieldIndex, 1)
        } else {
          fieldsCopy[fieldIndex] = { ...fieldsCopy[fieldIndex], deleted: true }
        }

        if (row.mapping.standardFieldKey) {
          const rowIndex = nonCrmContactsData.standardRows.indexOf(row)
          data.standardRows.splice(rowIndex, 1)
        } else {
          const rowIndex = nonCrmContactsData.customRows.indexOf(row)
          data.customRows.splice(rowIndex, 1)
        }

        update('nonCrmContactsData', data)
        update('fieldMappings', fieldsCopy)
      }
    }
  }

  return (
    <div className={classNames(rootClass, className)}>
      <FieldMappingUsageModal dataTest={`${dataTest}-field-mapping-usage-modal`} />
      {loading && renderLoader()}
      {!loading && showFirstTime && (
        <Container>
          <EmptyListing
            imgSrc={StaticImageNames.emptyLost}
            buttonOnClick={() => setShowFirstTime(false)}
            text={t(
              "You're moments away from using Act-On Contacts. Here, you can import data from many sources while keeping field names consistent and your data well-managed. Begin by setting up your fields. Then, import and merge your data."
            )}
            textAlign={TextAlign.CENTER}
            textType={TextType.BODY_TEXT_LIGHT}
            buttonText={'Get Started'}
            headline={'Looks like your first time here'}
          />
        </Container>
      )}
      {!loading && !showFirstTime && Array.isArray(fieldMappings) && (
        <div className={`${rootClass}__schema_editor_containers`}>
          <Container>
            <SchemaEditor schemaType={SchemaType.STANDARD} handleInsert={addNewField} handleUpdate={updateField} handleDelete={handleDelete} />
          </Container>
          <Container>
            <SchemaEditor schemaType={SchemaType.CUSTOM} handleInsert={addNewField} handleUpdate={updateField} handleDelete={handleDelete} />
          </Container>
        </div>
      )}
    </div>
  )
}

export default NonCrmContacts
