import React, { ReactNode } from 'react'

import { TFunction } from 'i18next'

import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import {
  BehaviorEmailOption,
  BehaviorEngagementOption,
  BehaviorExpressionConditionType,
  BehaviorProgramOption,
  BehaviorWebsiteOption,
} from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/BehaviorExpression/BehaviorExpression.constants'
import { renderSingleValue } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/utils/SegmentComposerBuild.utils'
import { RATE_SYMBOL } from '@src/pages/SegmentComposer/SegmentComposer.constants'
import { AssetTypes } from '@utils/actonAssetTypes'

export enum BehaviorCategory {
  EMAIL = 'email',
  FORMS = 'forms',
  PROGRAMS = 'programs',
  WEBSITE = 'website',
  WEBINAR = 'webinar',
  DELIVERABILITY = 'deliverability',
  ENGAGEMENT = 'engagement',
  SMS = 'sms',
}
export const getBehaviorCategory = (t: TFunction) => {
  return [
    {
      label: t('Email'),
      value: BehaviorCategory.EMAIL,
    },
    {
      label: t('Forms'),
      value: BehaviorCategory.FORMS,
    },
    {
      label: t('Programs'),
      value: BehaviorCategory.PROGRAMS,
    },
    {
      label: t('Website'),
      value: BehaviorCategory.WEBSITE,
    },
    {
      label: t('Webinar'),
      value: BehaviorCategory.WEBINAR,
    },
    {
      label: t('Deliverability'),
      value: BehaviorCategory.DELIVERABILITY,
    },
    {
      label: t('Engagement'),
      value: BehaviorCategory.ENGAGEMENT,
    },
    {
      label: t('SMS'),
      value: BehaviorCategory.SMS,
    },
  ]
}

export const getBehaviorEmailOptions = (t: TFunction) => {
  return [
    {
      label: '1',
      options: [
        {
          label: t('Was sent emails'),
          value: BehaviorEmailOption.WAS_SENT_EMAILS,
          extraOptions: { nextConditionType: BehaviorExpressionConditionType.QUANTITY_ASSETS, assetType: AssetTypes.EMAIL, isRequired: 'false' },
        },
        {
          label: t('Was sent email from'),
          value: BehaviorEmailOption.WAS_SENT_EMAIL_FROM,
          extraOptions: { nextConditionType: BehaviorExpressionConditionType.MULTISELECT },
        },
        {
          label: t('Last email send date'),
          value: BehaviorEmailOption.LAST_EMAIL_SENT_DATE,
          extraOptions: { nextConditionType: 'TIMEFRAMES' },
        },
        {
          label: t('First email send date'),
          value: BehaviorEmailOption.FIRST_EMAIL_SEND_DATE,
          extraOptions: { nextConditionType: 'TIMEFRAMES' },
        },
        {
          label: t('Last email sent name'),
          value: BehaviorEmailOption.LAST_EMAIL_SENT_NAME,
          extraOptions: { nextConditionType: BehaviorExpressionConditionType.QUANTITY_ASSETS, assetType: AssetTypes.EMAIL, isRequired: 'true' },
        },
        {
          label: t('First email sent name'),
          value: BehaviorEmailOption.FIRST_EMAIL_SENT_NAME,
          extraOptions: { nextConditionType: BehaviorExpressionConditionType.QUANTITY_ASSETS, assetType: AssetTypes.EMAIL, isRequired: 'true' },
        },
      ],
    },
    {
      label: '2',
      options: [
        {
          label: t('Opened emails'),
          value: BehaviorEmailOption.OPENED_EMAILS,
          extraOptions: { nextConditionType: BehaviorExpressionConditionType.QUANTITY_ASSETS, assetType: AssetTypes.EMAIL, isRequired: 'false' },
        },
        {
          label: t('Open rate'),
          value: BehaviorEmailOption.OPEN_RATE,
          extraOptions: { nextConditionType: BehaviorExpressionConditionType.NUMERIC, symbol: RATE_SYMBOL },
        },
        {
          label: t('Change in open rate'),
          value: BehaviorEmailOption.CHANGE_IN_OPEN_RATE,
          extraOptions: { nextConditionType: BehaviorExpressionConditionType.NUMERIC, symbol: RATE_SYMBOL },
        },
        {
          label: t('Last email open date'),
          value: BehaviorEmailOption.LAST_EMAIL_OPEN_DATE,
          extraOptions: { nextConditionType: 'TIMEFRAMES' },
        },
        {
          label: t('First email open date'),
          value: BehaviorEmailOption.FIRST_EMAIL_OPEN_DATE,
          extraOptions: { nextConditionType: 'TIMEFRAMES' },
        },
      ],
    },
    {
      label: '3',
      options: [
        {
          label: t('Clicked on emails'),
          value: BehaviorEmailOption.CLICKED_ON_EMAILS,
          extraOptions: { nextConditionType: BehaviorExpressionConditionType.QUANTITY_ASSETS, assetType: AssetTypes.EMAIL, isRequired: 'false' },
        },
        {
          label: t('Clicked on a specific link'),
          value: BehaviorEmailOption.CLICKED_ON_A_SPECIFIC_LINK,
          extraOptions: { nextConditionType: BehaviorExpressionConditionType.QUANTITY_ASSETS, assetType: AssetTypes.LINK, isRequired: 'true' },
        },
        {
          label: t('Click rate'),
          value: BehaviorEmailOption.CLICK_RATE,
          extraOptions: { nextConditionType: BehaviorExpressionConditionType.NUMERIC, symbol: RATE_SYMBOL },
        },
        {
          label: t('Change in click rate'),
          value: BehaviorEmailOption.CHANGE_IN_CLICK_RATE,
          extraOptions: { nextConditionType: BehaviorExpressionConditionType.NUMERIC, symbol: RATE_SYMBOL },
        },
        {
          label: t('Last email click date'),
          value: BehaviorEmailOption.LAST_EMAIL_CLICK_DATE,
          extraOptions: { nextConditionType: 'TIMEFRAMES' },
        },
        {
          label: t('First email click date'),
          value: BehaviorEmailOption.FIRST_EMAIL_CLICK_DATE,
          extraOptions: { nextConditionType: 'TIMEFRAMES' },
        },
      ],
    },
  ]
}

export const getBehaviorFormOptions = (t: TFunction) => {
  return [
    {
      label: t('Submitted forms'),
      value: 'submitted_forms',
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.QUANTITY_ASSETS, assetType: AssetTypes.FORM, isRequired: 'false' },
    },
    {
      label: t('Unique forms submitted'),
      value: 'unique_forms_submitted',
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.NUMERIC },
    },
  ]
}

export const getBehaviorProgramOptions = (t: TFunction) => {
  return [
    {
      label: t('Entered programs'),
      value: BehaviorProgramOption.ENTERED_PROGRAMS,
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.QUANTITY_ASSETS, assetType: AssetTypes.PROGRAM, isRequired: 'false' },
    },
    {
      label: t('Exited programs'),
      value: BehaviorProgramOption.EXITED_PROGRAMS,
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.QUANTITY_ASSETS, assetType: AssetTypes.PROGRAM, isRequired: 'false' },
    },
    {
      label: t('Early exited programs'),
      value: BehaviorProgramOption.EARLY_EXITED_PROGRAMS,
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.QUANTITY_ASSETS, assetType: AssetTypes.PROGRAM, isRequired: 'false' },
    },
    {
      label: t('Currently in a program'),
      value: BehaviorProgramOption.CURRENTLY_IN_PROGRAM,
      extraOptions: {
        nextConditionType: BehaviorExpressionConditionType.QUANTITY_ASSETS,
        assetType: AssetTypes.PROGRAM,
        isRequired: 'false',
        noTimeframes: 'true',
      },
    },
    {
      label: t('Number of programs enrolled in'),
      value: BehaviorProgramOption.NUMBER_OF_PROGRAMS_ENROLLED_IN,
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.NUMERIC },
    },
    {
      label: t('Last program enrolled in'),
      value: BehaviorProgramOption.LAST_PROGRAM_ENROLLED_IN,
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.QUANTITY_ASSETS, assetType: AssetTypes.PROGRAM, isRequired: 'true' },
    },
    {
      label: t('Last program enrolled date'),
      value: BehaviorProgramOption.LAST_PROGRAM_ENROLLED_DATE,
      extraOptions: { nextConditionType: 'TIMEFRAMES' },
    },
    {
      label: t('Last program end date'),
      value: BehaviorProgramOption.LAST_PROGRAM_END_DATE,
      extraOptions: { nextConditionType: 'TIMEFRAMES' },
    },
  ]
}

export const getBehaviorWebsiteOptions = (t: TFunction) => {
  return [
    {
      label: t('Visited website'),
      value: BehaviorWebsiteOption.VISITED_WEBSITE,
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.NUMERIC },
    },
    {
      label: t('Visited web pages'),
      value: BehaviorWebsiteOption.VISITED_WEB_PAGES,
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.QUANTITY_ASSETS, assetType: AssetTypes.WEB_PAGE, isRequired: 'false' },
    },
    {
      label: t('Average page views per session'),
      value: BehaviorWebsiteOption.AVERAGE_PAGE_VIEWS_PER_SESSION,
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.NUMERIC },
    },
    {
      label: t('First web page visited'),
      value: BehaviorWebsiteOption.FIRST_WEB_PAGE_VISITED,
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.QUANTITY_ASSETS, assetType: AssetTypes.WEB_PAGE, isRequired: 'false' },
    },
    {
      label: t('Last web page visited'),
      value: BehaviorWebsiteOption.LAST_WEB_PAGE_VISITED,
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.QUANTITY_ASSETS, assetType: AssetTypes.WEB_PAGE, isRequired: 'false' },
    },
    {
      label: t('Searched'),
      value: BehaviorWebsiteOption.SEARCHED,
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.QUANTITY_TEXT, isRequired: 'false' },
    },
    {
      label: t('Downloaded media'),
      value: BehaviorWebsiteOption.DOWNLOADED_MEDIA,
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.QUANTITY_ASSETS, assetType: AssetTypes.MEDIA, isRequired: 'false' },
    },
    {
      label: t('Visited landing pages'),
      value: BehaviorWebsiteOption.VISITED_LANDING_PAGES,
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.QUANTITY_ASSETS, assetType: AssetTypes.LANDING_PAGE, isRequired: 'false' },
    },
    {
      label: t('Clicked on a landing page link'),
      value: BehaviorWebsiteOption.CLICKED_ON_A_LANDING_PAGE_LINK,
      extraOptions: {
        nextConditionType: BehaviorExpressionConditionType.QUANTITY_ASSETS,
        assetType: AssetTypes.LANDING_PAGE_LINK,
        isRequired: 'false',
      },
    },
  ]
}

export const getBehaviorWebinarOptions = (t: TFunction) => {
  return [
    {
      label: t('Registered for webinars'),
      value: 'registered_for_webinars',
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.QUANTITY_ASSETS, assetType: AssetTypes.WEBINAR, isRequired: 'false' },
    },
    {
      label: t('Attended webinars'),
      value: 'attended_webinars',
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.QUANTITY_ASSETS, assetType: AssetTypes.WEBINAR, isRequired: 'false' },
    },
  ]
}

export const getBehaviorDeliverabilityOptions = (t: TFunction) => {
  return [
    {
      label: t('Email opted in'),
      value: 'email_opted_in',
      extraOptions: { nextConditionType: 'STATUS' },
    },
    {
      label: t('Email opted out'),
      value: 'email_opted_out',
      extraOptions: { nextConditionType: 'STATUS' },
    },
    {
      label: t('Hard bounced'),
      value: 'hard_bounced',
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.NUMERIC },
    },
    {
      label: t('Soft bounced'),
      value: 'soft_bounced',
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.NUMERIC },
    },
    {
      label: t('Inactive soft bounced'),
      value: 'inactive_soft_bounced',
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.NUMERIC },
    },
    {
      label: t('Spam complaint'),
      value: 'spam_complaint',
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.NUMERIC },
    },
  ]
}

export const getBehaviorEngagementOptions = (t: TFunction) => {
  return [
    {
      label: t('First engagement date'),
      value: BehaviorEngagementOption.FIRST_ENGAGEMENT_DATE,
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.ENGAGEMENT_MULTISELECT, isRequired: 'false' },
    },
    {
      label: t('Type of first engagement'),
      value: BehaviorEngagementOption.TYPE_OF_FIRST_ENGAGEMENT,
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.ENGAGEMENT_MULTISELECT, isRequired: 'false' },
    },
    {
      label: t('Last engagement date'),
      value: BehaviorEngagementOption.LAST_ENGAGEMENT_DATE,
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.ENGAGEMENT_MULTISELECT, isRequired: 'false' },
    },
    {
      label: t('Type of last engagement'),
      value: BehaviorEngagementOption.TYPE_OF_LAST_ENGAGEMENT,
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.ENGAGEMENT_MULTISELECT, isRequired: 'false' },
    },
    {
      label: t('Number of engagements'),
      value: BehaviorEngagementOption.NUMBER_OF_ENGAGEMENTS,
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.ENGAGEMENT_MULTISELECT_WITH_NUMERIC, isRequired: 'true' },
    },
    {
      label: t('Number of sends since last engagement'),
      value: BehaviorEngagementOption.NUMBER_OF_SENDS_SINCE_LAST_ENGAGEMENT,
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.ENGAGEMENT_MULTISELECT_WITH_NUMERIC, isRequired: 'true' },
    },
  ]
}

export const getBehaviorSMSOptions = (t: TFunction) => {
  return [
    {
      label: t('Was sent an SMS'),
      value: 'was_sent_an_sms',
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.QUANTITY_ASSETS, assetType: AssetTypes.SMS, isRequired: 'false' },
    },
    {
      label: t('Clicked on an SMS'),
      value: 'clicked_on_an_sms',
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.QUANTITY_ASSETS, assetType: AssetTypes.SMS, isRequired: 'false' },
    },
    {
      label: t('Replied to an SMS'),
      value: 'replied_to_an_sms',
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.QUANTITY_ASSETS, assetType: AssetTypes.SMS, isRequired: 'false' },
    },
    {
      label: t('SMS send failed'),
      value: 'sms_send_failed',
      extraOptions: { nextConditionType: BehaviorExpressionConditionType.QUANTITY_ASSETS, assetType: AssetTypes.SMS, isRequired: 'false' },
    },
  ]
}

export const getDeliverabilityStatusOptions = (t: TFunction) => [
  {
    value: 'IS_CURRENTLY',
    label: t('Is currently'),
  },
  {
    value: 'IS_NOT_CURRENTLY',
    label: t('Is not currently'),
  },
  {
    value: 'AT_LEAST_ONCE',
    label: t('At least once'),
    extraOptions: { nextConditionType: 'TIMEFRAMES' },
  },
  {
    value: 'NEVER',
    label: t('Never'),
  },
]

export const renderCategoryOptions: {
  [key in BehaviorCategory]: (
    t: TFunction,
    onChange: (category: BehaviorCategory, value: SelectV2SingleOption) => void,
    value?: SelectV2SingleOption
  ) => ReactNode
} = {
  [BehaviorCategory.EMAIL]: (t, onChange, value) => {
    const options = getBehaviorEmailOptions(t)
    return (
      <SelectV2
        dataTest={'behavior-email-select'}
        renderSingleValue={renderSingleValue}
        groupedOptions={options}
        value={value || options[0].options[0]}
        onChange={(value) => onChange(BehaviorCategory.EMAIL, value as SelectV2SingleOption)}
        isClearable={false}
        isSearchable={false}
        showGroupsWithoutLabel
      />
    )
  },
  [BehaviorCategory.FORMS]: (t, onChange, value) => {
    const options = getBehaviorFormOptions(t)
    return (
      <SelectV2
        dataTest={'behavior-form-select'}
        options={options}
        value={value || options[0]}
        onChange={(value) => onChange(BehaviorCategory.FORMS, value as SelectV2SingleOption)}
        isClearable={false}
        isSearchable={false}
      />
    )
  },
  [BehaviorCategory.PROGRAMS]: (t, onChange, value) => {
    const options = getBehaviorProgramOptions(t)
    return (
      <SelectV2
        dataTest={'behavior-program-select'}
        options={options}
        value={value || options[0]}
        onChange={(value) => onChange(BehaviorCategory.PROGRAMS, value as SelectV2SingleOption)}
        isClearable={false}
        isSearchable={false}
      />
    )
  },
  [BehaviorCategory.WEBSITE]: (t, onChange, value) => {
    const options = getBehaviorWebsiteOptions(t)
    return (
      <SelectV2
        dataTest={'behavior-website-select'}
        options={options}
        value={value || options[0]}
        onChange={(value) => onChange(BehaviorCategory.WEBSITE, value as SelectV2SingleOption)}
        isClearable={false}
        isSearchable={false}
      />
    )
  },
  [BehaviorCategory.WEBINAR]: (t, onChange, value) => {
    const options = getBehaviorWebinarOptions(t)
    return (
      <SelectV2
        dataTest={'behavior-webinar-select'}
        options={options}
        value={value || options[0]}
        onChange={(value) => onChange(BehaviorCategory.WEBINAR, value as SelectV2SingleOption)}
        isClearable={false}
        isSearchable={false}
      />
    )
  },
  [BehaviorCategory.DELIVERABILITY]: (t, onChange, value) => {
    const options = getBehaviorDeliverabilityOptions(t)
    return (
      <SelectV2
        dataTest={'behavior-deliverability-select'}
        options={options}
        value={value || options[0]}
        onChange={(value) => onChange(BehaviorCategory.DELIVERABILITY, value as SelectV2SingleOption)}
        isClearable={false}
        isSearchable={false}
      />
    )
  },
  [BehaviorCategory.ENGAGEMENT]: (t, onChange, value) => {
    const options = getBehaviorEngagementOptions(t)
    return (
      <SelectV2
        dataTest={'behavior-engagement-select'}
        options={options}
        value={value || options[0]}
        onChange={(value) => onChange(BehaviorCategory.ENGAGEMENT, value as SelectV2SingleOption)}
        isClearable={false}
        isSearchable={false}
      />
    )
  },
  [BehaviorCategory.SMS]: (t, onChange, value) => {
    const options = getBehaviorSMSOptions(t)
    return (
      <SelectV2
        dataTest={'behavior-sms-select'}
        options={options}
        value={value || options[0]}
        onChange={(value) => onChange(BehaviorCategory.SMS, value as SelectV2SingleOption)}
        isClearable={false}
        isSearchable={false}
      />
    )
  },
}

export const getDefaultCategoryByBehavior: {
  [key in BehaviorCategory]: (t: TFunction) => SelectV2SingleOption
} = {
  [BehaviorCategory.EMAIL]: (t) => getBehaviorEmailOptions(t)[0].options[0],
  [BehaviorCategory.FORMS]: (t) => getBehaviorFormOptions(t)[0],
  [BehaviorCategory.PROGRAMS]: (t) => getBehaviorProgramOptions(t)[0],
  [BehaviorCategory.WEBSITE]: (t) => getBehaviorWebsiteOptions(t)[0],
  [BehaviorCategory.WEBINAR]: (t) => getBehaviorWebinarOptions(t)[0],
  [BehaviorCategory.DELIVERABILITY]: (t) => getBehaviorDeliverabilityOptions(t)[0],
  [BehaviorCategory.ENGAGEMENT]: (t) => getBehaviorEngagementOptions(t)[0],
  [BehaviorCategory.SMS]: (t) => getBehaviorSMSOptions(t)[0],
}
