import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

import { isOverUpperHalf } from '@components/ColumnsOrderModal/utils/ColumnsOrderModal.utils'
import { Row } from '@tanstack/react-table'
import { ItemType } from '@utils/categorization'

import { SelectionType } from '../../tableV2TS/enums'
import { CommonV2RowProps } from '../../tableV2TS/interfaces'
import { CanDropCallback, Item } from '../../tableV2TS/types'
import { getCaretCellID, isCellClickable, selectionType } from '../../utils/tableV2Utils'
import { TableV2Row } from '../TableV2Row/TableV2Row'

import './DraggableRow.css'

interface Props<T> extends CommonV2RowProps<T> {
  row: Row<T>
  dataTest?: string
  className: string
  itemType?: ItemType
  selectedRows: Row<T>[]
  isDraggable?: boolean
  canDropCallback: CanDropCallback<T>
  toggleAllRowsSelected: (allSelected: boolean) => void
  onRowsSort?: (selectedRows: Row<T>[], droppedOnRow: Row<T>, above: boolean, parentRowId?: string) => void
}

const rootClass = 'tableV2-draggable-row'

export const DraggableRow = <T extends {}>({
  row,
  rows,
  rowRef,
  tagProps,
  className,
  rowActions,
  isExpanded,
  isDraggable,
  selectedRows,
  hoveredRowID,
  enableSubRow,
  stickyColumns,
  stickyColsWidths,
  enableSpacerBlock,
  dataTest = rootClass,
  itemType = ItemType.SEGMENT,
  onRowsSort,
  rowDisabled,
  customClass,
  hasToExpand,
  onRowClicked,
  onRowExpanded,
  setHoveredRowID,
  canDropCallback,
  toggleAllRowsSelected,
}: Props<T>) => {
  const [isOverTop, setIsOverTop] = useState<boolean>(false)
  const ref = useRef(null)

  const firstColumnsID = row.getVisibleCells()[0].column.id

  const [{ isDragging }, dragRef, preview] = useDrag(
    () => ({
      type: itemType,
      item: () => {
        const rows = [...selectedRows]
        if (!selectedRows.includes(row)) {
          row.toggleSelected(true)
          rows.push(row)
        }
        return { rows, selectionType: selectionType(rows), toggleAllRowsSelected } as Item<T>
      },
      isDragging: () => selectedRows.includes(row),
      collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    }),
    [selectedRows]
  )

  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: itemType,
    drop: (item: Item<T>) => {
      toggleAllRowsSelected(false)
      if (onRowsSort && !item.rows.includes(row)) {
        onRowsSort(item.rows, row, isOverTop, item.selectionType === SelectionType.SUBROWS ? row.id : undefined)
      }
      return
    },
    hover: (_item, monitor) => setIsOverTop(isOverUpperHalf(ref, monitor)),
    canDrop: (item: Item<T>, monitor) =>
      monitor.isOver() && canDropCallback(row, item, monitor.getItemType()?.toString() || '', isOverUpperHalf(ref, monitor)),
    collect: (monitor) => ({ isOver: monitor.isOver(), canDrop: monitor.canDrop() }),
  })

  dropRef(ref)

  useEffect(() => {
    // Disable browser DnD preview
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [preview])

  useEffect(() => {
    rowRef.current = ref.current
  }, [rowRef])

  const draggableRowProps = useMemo(
    () => ({ isOver, canDrop, isOverTop, isDragging, draggableRootClassName: rootClass, dragRef }),
    [isOver, canDrop, isOverTop, isDragging, dragRef]
  )

  return (
    <TableV2Row
      row={row}
      rows={rows}
      rowRef={ref}
      tagProps={tagProps}
      dataTest={dataTest}
      rootClass={className}
      isExpanded={isExpanded}
      rowActions={rowActions}
      isDraggable={isDraggable}
      enableSubRow={enableSubRow}
      hoveredRowID={hoveredRowID}
      stickyColumns={stickyColumns}
      firstColumnsID={firstColumnsID}
      stickyColsWidths={stickyColsWidths}
      enableSpacerBlock={enableSpacerBlock}
      draggableRowProps={draggableRowProps}
      rowDisabled={rowDisabled}
      hasToExpand={hasToExpand}
      onRowClicked={onRowClicked}
      onRowExpanded={onRowExpanded}
      caretCellID={getCaretCellID(row)}
      setHoveredRowID={setHoveredRowID}
      isCellClickable={isCellClickable(row)}
      customClass={customClass}
    />
  )
}
