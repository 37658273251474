import { Status } from '@components/StatusToast/StatusToast'
import { ListPageState } from '@interface/ListPage.context'

export enum EMPTY_LISTING_OPTIONS {
  EMPTY_CONTACT_PREFERENCES = 'EMPTY_CONTACT_PREFERENCES',
  EMPTY_CRM_ALL_SEGMENTS = 'EMPTY_CRM_ALL_SEGMENTS',
  EMPTY_CRM_SOURCED_SEGMENTS = 'EMPTY_CRM_SOURCED_SEGMENTS',
  EMPTY_NON_CRM_ALL_SEGMENTS = 'EMPTY_NON_CRM_ALL_SEGMENTS',
  EMPTY_FOLDER = 'EMPTY_FOLDER',
  EMPTY_TAG = 'EMPTY_TAG',
  EMPTY_FAVORITES = 'EMPTY_FAVORITES',
  EMPTY_CREATED_BY_ME = 'EMPTY_CREATED_BY_ME',
  EMPTY_RECENT = 'EMPTY_RECENT',
  NOT_SET_CRM_DATA_MANAGEMENT = 'NOT_SET_CRM_DATA_MANAGEMENT',
  NOT_SET_NON_CRM_DATA_MANAGEMENT = 'NOT_SET_NON_CRM_DATA_MANAGEMENT',
}

export const listPageContextValues: ListPageState<any> = {
  creatingFolder: false,
  creatingTag: false,
  filterActive: undefined,
  folders: [],
  tags: [],
  errors: {},
  folderPath: [],
  deleteConfirmationData: [],
  confirmationModal: undefined,
  loading: true,
  pageError: false,
  saved: false,
  search: '',
  searchResults: 0,
  searchItemsResults: [],
  searchItemsLoading: false,
  items: [],
  itemTypesUsed: {},
  isProcessingAction: false,
  foldersLoading: true,
  tagsLoading: true,
  movingItem: false,
  showManageTag: false,
  statusToast: { statusMessage: '', status: Status.FAIL, showStatusToast: false },
  currentPage: 0,
  allItemsLoaded: false,
  infoHoverLoading: false,
  selectingImportContactsSource: false,
  importContactsSource: '',
}
