import React, { RefObject } from 'react'
import { Cell, Row } from 'react-table'

import { SvgNames } from '@components/Svg'
import { RowAction, TableColumn } from '@components/Table/Table'
import { ProgramStep } from '@graphql/types/query-types'
import ListProgramStep from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/components/ListProgramStep/ListProgramStep'
import { ItemType } from '@utils/categorization'

export const EDIT_STEP_EVENT = 'onStepEdit'
export const EXPANDED_STEPS_SESSION = `${ItemType.LIST_MAINTENANCE_PROGRAM}:expandedSteps`
export const EXPAND_ALL_STEPS_EVENT = 'onExpandAllSteps'
export const EXPAND_STEP_EVENT = 'onStepExpand'
export const SAVE_PROGRAM_EVENT = 'onSaveProgram'

export const getProgramStepsListingColumns = (stepsRefs: RefObject<Map<number, HTMLDivElement | null>>): TableColumn[] => [
  {
    Header: 'Step description',
    accessor: 'name',
    align: 'left',
    flexColumn: true,
    Cell: ({ row: { original: step, id, index } }: Cell<ProgramStep>) => (
      <ListProgramStep
        key={`program-step-${id}`}
        step={step}
        position={index}
        register={(ref) => {
          if (stepsRefs.current) {
            stepsRefs.current.set(index, ref)
          }
        }}
      />
    ),
  },
]

export const getProgramStepsListingRowActions = (
  onDeleteStep: (step: Row<ProgramStep>) => void,
  onDuplicateStep: (step: Row<ProgramStep>) => void,
  onEditStep: (step: Row<ProgramStep>) => void,
  userAllowedToCreatePrograms: boolean,
  stepBeingEditedIndex?: number
): RowAction[] =>
  userAllowedToCreatePrograms
    ? [
        {
          label: 'Edit',
          icon: SvgNames.pencil,
          hasTooltip: true,
          hidden: ({ index }: Row<ProgramStep>) => index === stepBeingEditedIndex,
          onClick: onEditStep,
        },
        {
          label: 'Duplicate',
          icon: SvgNames.cloneSegment,
          hasTooltip: true,
          onClick: onDuplicateStep,
        },
        {
          label: 'Delete',
          icon: SvgNames.delete,
          hasTooltip: true,
          onClick: onDeleteStep,
        },
      ]
    : []
