import React, { FC } from 'react'
import { useForm } from 'react-hook-form'

import * as yup from 'yup'

import { useApolloClient, useQuery } from '@apollo/client'
import FormRow from '@components/FormRow/FormRow'
import Input from '@components/Input/Input'
import Loader from '@components/Loader/Loader'
import { ModalBody } from '@components/Modal'
import PageError from '@components/PageError/PageError'
import Radio from '@components/Radio/Radio'
import RadioGroup from '@components/RadioGroup/RadioGroup'
import Select from '@components/Select/Select'
import TextArea from '@components/TextArea/TextArea'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import crmCampaignStatusQuery from '@graphql/queries/crmCampaignStatus'
import crmUsersQuery from '@graphql/queries/crmUsers'
import {
  CrmCampaignStatusQuery,
  CrmCampaignStatusQueryVariables,
  CrmType,
  CrmUsersQuery,
  CrmUsersQueryVariables,
  Program,
} from '@graphql/types/query-types'
import { yupResolver } from '@hookform/resolvers/yup'
import AddCRMTaskDetail from '@src/pages/programs/dashboard/components/ProgramSteps/components/AddCRMTaskDetail/AddCRMTaskDetail'
import { CRM_CONTACT_OWNER_ID } from '@utils/crm.utils'
import { logNewRelicError } from '@utils/new-relic.utils'
import { ProgramTaskStepExt, Step } from '@utils/program/program.constants'

import './editTaskStep.css'

const rootClass = 'edit-task-step'

export interface State {
  step: ProgramTaskStepExt
}

const PRIORITIES = ['High', 'Normal', 'Low']

enum DueDate {
  NONE = 'none',
  IMMEDIATELY = 'immediately',
  DUE_AFTER = 'day',
  DUE_AFTER_WEEK = 'week',
}

interface CrmUser {
  extId?: string
  firstName?: string
  lastName?: string
}

interface Props {
  step: Step
  program: Program
  isRunning: boolean
  saveStepAndProgram(step: Step | null, program?: Program): void
  submitId: string
  dataTest?: string
}

export const handleFormSubmit = (data: any, step: ProgramTaskStepExt, props: Props) => {
  const { saveStepAndProgram } = props

  saveStepAndProgram({
    ...step,
    ...data,
    dateUnit: data.dateUnit ?? step.dateUnit,
    dateAmount: data.dateAmount ?? step.dateAmount,
  })
}

const schema = yup.object().shape({
  displayName: yup.string().required('Step Name is required.'),
  update: yup.boolean(),
})

export const EditTaskStep: FC<Props> = (props: Props) => {
  const { step: baseStep, program, isRunning, submitId, dataTest = 'edit-task-step' } = props
  const { t } = useTranslation()

  const client = useApolloClient()

  const [state, setState] = React.useState<State>({
    step: baseStep as ProgramTaskStepExt,
  })
  const { step } = state

  const {
    loading: loadingUsers,
    error: usersError,
    data: userData,
  } = useQuery<CrmUsersQuery, CrmUsersQueryVariables>(crmUsersQuery, {
    client: client as any,
    variables: {
      type: program.crm?.crmType as CrmType,
    },
  })
  const {
    loading: loadingStatuses,
    error: statusesError,
    data: statusData,
  } = useQuery<CrmCampaignStatusQuery, CrmCampaignStatusQueryVariables>(crmCampaignStatusQuery, {
    client: client as any,
    variables: {
      type: program.crm?.crmType as CrmType,
    },
  })

  const crmUsers: CrmUser[] | undefined = userData?.crmUsers?.users
  const crmStatuses: string[] | undefined = statusData?.crmCampaignStatus

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })

  if (!program.crm?.crmType) return null

  const onSubmit = (data: any) => {
    handleFormSubmit(data, step, props)
  }

  const onDateUnitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      step: {
        ...step,
        dateUnit: event.target.value,
      },
    })
  }

  const registerDueDate = () =>
    register('dueDate', {
      onChange: onDateUnitChange,
    })

  const dueAfterRowDisabled = step.dateUnit !== DueDate.DUE_AFTER && step.dateUnit !== DueDate.DUE_AFTER_WEEK
  const loading = loadingUsers || loadingStatuses
  const error = usersError || statusesError

  if (error) {
    logNewRelicError(error)
    if (statusesError) {
      return (
        <>
          <ModalBody className={rootClass}>
            <Typography
              text={t(`Your crm data is not available`, { crmName: program.crm?.crmName })}
              className={`${rootClass}__not-current`}
              data-test={`${dataTest}__sync-warning`}
            />
          </ModalBody>
        </>
      )
    }
  }

  const renderForm = () => (
    <form data-test={dataTest} onSubmit={handleSubmit(onSubmit)}>
      <FormRow>
        <Input
          className={`${rootClass}__step-name`}
          label={t('Step Name')}
          defaultValue={step.displayName}
          name="displayName"
          register={register('displayName')}
        />
        {errors?.displayName && <span className="error">{t(errors.displayName.message)}</span>}
      </FormRow>
      <FormRow>
        <Select
          label={t('Assigned To')}
          defaultValue={step.ownerId}
          onChange={(event) => {
            const value = event.target.value
            const contactOwner: CrmUser = { extId: CRM_CONTACT_OWNER_ID, firstName: 'Contact Owner' }
            const owner = value === CRM_CONTACT_OWNER_ID ? contactOwner : crmUsers?.find((crmUser) => crmUser.extId === value)
            if (owner) {
              setState({
                ...state,
                step: {
                  ...state.step,
                  ownerId: owner.extId,
                  ownerName: owner.firstName,
                },
              })
            }
          }}
        >
          <option value={CRM_CONTACT_OWNER_ID}>{t('Contact Owner')}</option>
          {crmUsers?.map((crmUser) => (
            <option key={crmUser.extId} value={crmUser.extId}>
              {crmUser.firstName} {crmUser.lastName}
            </option>
          ))}
        </Select>
      </FormRow>
      <FormRow>
        <Input
          label={t('Subject')}
          defaultValue={step.subject}
          name="subject"
          register={register('subject')}
          placeholder={t("Share a note, we'll pass along all the details...")}
        />
      </FormRow>
      <FormRow>
        <TextArea
          className={`${rootClass}__text-area`}
          label={t('Comments')}
          defaultValue={step.comment}
          name="comment"
          register={register('comment')}
          placeholder={t("Share a note, we'll pass along all the details...")}
        />
      </FormRow>
      <FormRow className={`${rootClass}__row`}>
        <div className={`${rootClass}__col`}>
          <Select register={register('status')} name="status" defaultValue={step.status} label={t('Status')}>
            <option value="">{t('-- Select a Status --')}</option>
            {crmStatuses?.map((status) => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </Select>
        </div>
        <div className={`${rootClass}__col`}>
          <Select register={register('priority')} name="priority" defaultValue={step.priority} label={t('Priority')}>
            {PRIORITIES?.map((priority) => (
              <option key={priority} value={priority}>
                {priority}
              </option>
            ))}
          </Select>
        </div>
      </FormRow>
      <FormRow>
        <RadioGroup label={t('Due Date')} verticalLayout>
          <Radio
            dataTest={`${dataTest}-radio-none`}
            label={t('None')}
            name="dueDate"
            value={DueDate.NONE}
            checked={step.dateUnit === DueDate.NONE}
            register={registerDueDate()}
          />
          <Radio
            label={t('Immediately')}
            name="dueDate"
            value={DueDate.IMMEDIATELY}
            checked={step.dateUnit === DueDate.IMMEDIATELY}
            register={registerDueDate()}
          />
          <Radio
            label={t('Due After')}
            name="dueDate"
            value={DueDate.DUE_AFTER}
            checked={step.dateUnit === DueDate.DUE_AFTER || step.dateUnit === DueDate.DUE_AFTER_WEEK}
            register={registerDueDate()}
          />
        </RadioGroup>
        <div className={`${rootClass}__due-row`}>
          <Input
            name="dateAmount"
            register={register('dateAmount', { disabled: dueAfterRowDisabled })}
            defaultValue={step.dateAmount}
            disabled={dueAfterRowDisabled}
          />
          <Select
            name="dateUnit"
            register={register('dateUnit', { disabled: dueAfterRowDisabled })}
            defaultValue={step.dateUnit}
            disabled={dueAfterRowDisabled}
          >
            <option value="day">{t('Days')}</option>
            <option value="week">{t('Week')}</option>
          </Select>
        </div>
      </FormRow>
      <button type="submit" id={submitId} hidden />
    </form>
  )

  const renderView = () => (
    <>
      <Typography text={step.displayName} weight={TextWeight.MEDIUM} type={TextType.SECTION_HEADER} />
      <AddCRMTaskDetail
        status={step.status ?? ''}
        subject={step.subject ?? ''}
        priority={step.priority ?? ''}
        comment={step.comment}
        ownerName={step.ownerName}
        dateAmount={step.dateAmount}
        dateUnit={step.dateUnit}
      />
    </>
  )

  const renderBody = () => (isRunning ? renderView() : renderForm())

  return (
    <ModalBody className={rootClass}>
      {loading && <Loader center />}
      {!loading && error && <PageError />}
      {!loading && !error && renderBody()}
    </ModalBody>
  )
}

export default EditTaskStep
