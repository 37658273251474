import React, { Dispatch, FC, SetStateAction, useContext } from 'react'

import ListPickerModal from '@complex/ListPickerModalV2/ListPickerModal'
import { UNIFIED_CONTACTS_ID } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import { List } from '@interface/foldersLists/List'
import { CopySegmentsContext } from '@src/pages/ContactSegments/components/CopySegments/context/CopySegments.context'
import { useAccountSettings } from '@utils/account/account.utils'
import { SegmentType } from '@utils/segments'

interface ModalHandlerProps {
  showListPicker: boolean
  setShowListPicker: Dispatch<SetStateAction<boolean>>
}

const ModalHandler: FC<ModalHandlerProps> = ({ showListPicker, setShowListPicker }: ModalHandlerProps) => {
  const {
    values: { parentLocation },
    update,
  } = useContext(CopySegmentsContext)
  const { phase3FormSubmission } = useAccountSettings()

  const onSubmit = (list: List[]) => {
    update({ parentLocation: list[0], parentType: list[0].baseId === UNIFIED_CONTACTS_ID ? SegmentType.SEGMENT : SegmentType.SUBMISSION })
    closeModal()
  }

  const closeModal = () => setShowListPicker(() => false)

  return (
    <>
      {showListPicker && (
        <ListPickerModal
          isOpen
          defaultSelectedLists={parentLocation ? [parentLocation?.id] : undefined}
          submitLists={onSubmit}
          hasUnifiedFormSubmissions={phase3FormSubmission}
          hideContactPreferences
          closeModal={closeModal}
          hideLegacyLists
          hideLegacyListToggle
          multiSelect={false}
        />
      )}
    </>
  )
}

export default ModalHandler
