import React, { ComponentProps } from 'react'

import Typography, { TextWeight } from '@components/Typography/Typography'
import { escapeRegExp } from '@utils/strings'

interface RenderBoldTextOnFirstMatchParams {
  value: string
  disabled?: boolean
  search: string
  key?: string | number
  hideTooltip?: boolean
  overridingProps?: Partial<ComponentProps<typeof Typography>>
}

export const renderBoldTextOnFirstMatch = ({ hideTooltip, key, overridingProps, search, value }: RenderBoldTextOnFirstMatchParams) => {
  const matcher = new RegExp(escapeRegExp(search.trim()), 'i')
  const match = value.match(matcher)
  let text
  if (match) {
    const index = match?.index ?? 0
    const lastIndex = index + search.length
    text = value.slice(0, index) + `<bold>${value.slice(index, lastIndex)}</bold>` + value.slice(lastIndex)
  } else {
    text = value
  }

  return (
    <Typography
      className="ellip"
      key={`typography-${key}`}
      title={hideTooltip ? '' : value}
      text={text}
      tagProps={{ bold: { weight: TextWeight.BOLD } }}
      inline
      {...overridingProps}
    />
  )
}
