import React, { FC, useEffect, useRef, useState } from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import classNames from 'classnames'
// eslint-disable-next-line no-restricted-imports
import { format, isToday } from 'date-fns'
import _ from 'lodash'
import 'snapsvg-cjs'

import ButtonWithLoader from '@components/ButtonWithLoader/ButtonWithLoader'
import Popover from '@components/Popover/Popover'
import Svg, { SvgNames } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useAccountSettings } from '@utils/account/account.utils'
import { CommonComposerAPI } from '@utils/composer/commonComposer/CommonComposer.context'
import { useTranslation } from '@utils/const/globals'
import { getFormattedTime } from '@utils/date'
import { useTimeout } from '@utils/hooks/useTimeout'
import { useWindowBlur } from '@utils/hooks/useWindowBlur'

import './AutoSaveIndicator.css'

export enum AutoSaveStatus {
  SUCCESS = 'success',
  FAIL = 'fail',
}

declare const Snap: any

export type AutoSaveIndicatorProps = {
  lastEdited?: number
  isIndicatingAutoSave?: boolean
  autoSaveFailure?: boolean
  hasPopoverCard?: boolean
  onPopoverCardButtonClick?: () => void
  onIndicatingAutoSaveEnds?: () => void
  hasPublishStatus?: boolean
  isAutoSaveDisabled?: boolean
  isCustomerCareLogin?: boolean
  isSaving?: boolean
  hasUnsavedChanges?: boolean
  update?: CommonComposerAPI['update']
}
const rootClass = 'global-composer-header-save-indicator'
const AUTOSAVE_DONE_DELAY = 2500
export const AUTOSAVE_SUCCESS_DELAY = 4000
export const AUTOSAVE_ALL_CHANGES_SAVED_DELAY = 1000

export const AutoSaveIndicator: FC<AutoSaveIndicatorProps> = (props: AutoSaveIndicatorProps) => {
  const {
    isAutoSaveDisabled,
    hasPopoverCard,
    hasPublishStatus,
    autoSaveFailure,
    isIndicatingAutoSave,
    lastEdited,
    update,
    onPopoverCardButtonClick,
    onIndicatingAutoSaveEnds,
    isCustomerCareLogin,
    isSaving,
    hasUnsavedChanges,
  } = props

  const progressBarRef = useRef<HTMLDivElement>(null)
  const { accountTimeZoneId, newLPComposerCreateBlank } = useAccountSettings()

  const [autoSaveLoading, setAutoSaveLoading] = useState<boolean>(false)
  const [autoSaveStatus, setAutoSaveStatus] = useState<AutoSaveStatus>(AutoSaveStatus.SUCCESS)
  const [allChangesSaved, setAllChangesSaved] = useState<boolean>(true)
  const [lastSavedTime, setLastSavedTime] = useState<string>('')
  const [text, setText] = useState<string>('')
  const [showCloudCheckSvg, setShowCloudCheckSvg] = useState<boolean>(false)

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)

  const lastPublishedRef = useRef<number | undefined>(lastEdited)

  const { t } = useTranslation()

  const successTimer = useTimeout(AUTOSAVE_SUCCESS_DELAY)
  const changesSavedTimer = useTimeout(AUTOSAVE_ALL_CHANGES_SAVED_DELAY)
  const doneTimer = useTimeout(AUTOSAVE_DONE_DELAY)

  const isAnimating = successTimer.isRunning || changesSavedTimer.isRunning || doneTimer.isRunning

  useWindowBlur(isPopoverOpen, setIsPopoverOpen)

  useEffect(() => {
    if (autoSaveLoading) {
      const svg = Snap('.save-indicator-progress-bar')
      setShowCloudCheckSvg(false)

      if (svg) {
        const progress = svg.select('#progress')

        progress.attr({ r: 15, fill: 'none', stroke: '#00babe', strokeWidth: 3, strokeDasharray: '0, 100' })
        Snap.animate(
          0,
          100,
          (value: number) => {
            progress.attr({ 'stroke-dasharray': `${value}, 100` })

            if (value === 100) {
              setShowCloudCheckSvg(true)
            }
          },
          1200
        )
      }
    }
  }, [autoSaveLoading])

  const getLastEditedFormmatedTime = (lastEdited: number) => {
    const today = new Date()
    const lastEditedDate = new Date(lastEdited)
    let lastEditedFormat

    if (today.getUTCFullYear() !== lastEditedDate.getUTCFullYear()) {
      lastEditedFormat = 'MMM d yyyy'
    } else if (today.getUTCMonth() !== lastEditedDate.getUTCMonth() || today.getUTCDay() !== lastEditedDate.getUTCDay()) {
      lastEditedFormat = 'MMM d'
    }

    return lastEditedFormat ? `${format(lastEditedDate, lastEditedFormat)}, ${getFormattedTime(lastEdited)}` : `${getFormattedTime(lastEdited)}`
  }

  useEffect(() => {
    const setOnline = () => setAutoSaveStatus(AutoSaveStatus.SUCCESS)
    const setOffline = () => setAutoSaveStatus(AutoSaveStatus.FAIL)

    window.addEventListener('online', setOnline)
    window.addEventListener('offline', setOffline)

    return () => {
      window.removeEventListener('online', setOnline)
      window.removeEventListener('offline', setOffline)
    }
  }, [])

  useEffect(() => {
    if (isIndicatingAutoSave || isSaving) {
      animateIndicator().then(() => {
        onIndicatingAutoSaveEnds?.()
      })
    }
  }, [isIndicatingAutoSave, isSaving, onIndicatingAutoSaveEnds])

  useEffect(() => {
    if (autoSaveFailure) {
      successTimer.clear()
      changesSavedTimer.clear()
      doneTimer.clear()

      setAutoSaveLoading(false)
      setAutoSaveStatus(AutoSaveStatus.FAIL)
      update?.({ isIndicatingAutoSave: false, autoSaveFailure: false })
    }
  }, [autoSaveFailure])

  useEffect(() => {
    if (!lastPublishedRef.current) {
      lastPublishedRef.current = lastEdited
    }
    if (lastEdited && !isAnimating) {
      const lastEditedFormattedTime = getLastEditedFormmatedTime(lastEdited)

      setLastSavedTime(lastEditedFormattedTime)
      setText(
        hasPublishStatus
          ? text === ''
            ? `Last published ${lastEditedFormattedTime}`
            : 'Unpublished changes'
          : t('ComposerHeader.AutoSave.Status.Success.Done', { lastSavedTime: lastEditedFormattedTime })
      )
    }
  }, [lastEdited, hasPublishStatus])

  const updateTextWithLastEditedTime = (lastEdited: number, hasPublishStatus?: boolean) => {
    const lastEditedFormattedTime = getLastEditedFormmatedTime(lastEdited)
    setText(
      t(hasPublishStatus && !newLPComposerCreateBlank ? 'Unpublished changes' : 'ComposerHeader.AutoSave.Status.Success.Done', {
        lastSavedTime: lastEditedFormattedTime,
      })
    )
  }

  const saveAllChanges = () => {
    changesSavedTimer.set(() => {
      setAllChangesSaved(true)
      const lastEditedFormattedTime = getLastEditedFormmatedTime(Date.now())
      setLastSavedTime(lastEditedFormattedTime)

      doneTimer.set(() => {
        setText(
          t(hasPublishStatus && !newLPComposerCreateBlank ? 'Unpublished changes' : 'ComposerHeader.AutoSave.Status.Success.Done', {
            lastSavedTime: lastEditedFormattedTime,
          })
        )
      })
    })
  }

  useEffect(() => {
    if (!lastEdited) return

    if (isSaving) {
      setText(t('ComposerHeader.AutoSave.Status.Loading'))
      return
    }

    if (_.isUndefined(hasUnsavedChanges)) {
      updateTextWithLastEditedTime(lastEdited, hasPublishStatus)
    } else if (!hasUnsavedChanges) {
      saveAllChanges()
    }
  }, [hasUnsavedChanges, isSaving, lastEdited])

  const animateIndicator = async () => {
    return new Promise<void>((resolve) => {
      setAutoSaveLoading(true)
      setText(t(hasPublishStatus && !newLPComposerCreateBlank ? 'Unpublished changes' : 'ComposerHeader.AutoSave.Status.Loading'))
      setAllChangesSaved(false)

      successTimer.set(() => {
        setAutoSaveLoading(false)
        setText(t(hasPublishStatus && !newLPComposerCreateBlank ? 'Unpublished changes' : 'ComposerHeader.AutoSave.Status.Success'))
        setAutoSaveStatus(AutoSaveStatus.SUCCESS)

        changesSavedTimer.set(() => {
          setAllChangesSaved(true)
          setLastSavedTime(getFormattedTime(Date.now()))

          doneTimer.set(() => {
            setText(
              t(hasPublishStatus && !newLPComposerCreateBlank ? 'Unpublished changes' : 'ComposerHeader.AutoSave.Status.Success.Done', {
                lastSavedTime,
              })
            )
            resolve()
          })
        })
      })
    })
  }

  const renderPopoverTrigger = () => {
    const fill = SvgColor.LIGHT_GRAY
    const commonClassNames = [`${rootClass}__icon`, `${rootClass}__icon-trigger`]

    return (
      <Tooltip
        position={'bottom'}
        hide={isPopoverOpen}
        disableTooltip={isPopoverOpen}
        trigger={
          <>
            <div className={classNames(`${rootClass}__popover-trigger`, { [`${rootClass}__popover-trigger-active`]: isPopoverOpen })}>
              <svg className={classNames(`${rootClass}__svg`, 'save-indicator-progress-bar')} width={36} height={36}>
                <circle
                  className={classNames(`${rootClass}__progress-bar`, { [`${rootClass}__progress-bar-inactive`]: !autoSaveLoading })}
                  id={'progress'}
                  cx="18"
                  cy="18"
                  r="15"
                  fill="none"
                />
              </svg>
              {(!autoSaveLoading && autoSaveStatus === AutoSaveStatus.FAIL) || isAutoSaveDisabled ? (
                <Svg name={SvgNames.cloudOff} fill={fill} className={classNames(...commonClassNames)} />
              ) : (
                <>
                  <Svg
                    name={SvgNames.cloud}
                    fill={fill}
                    className={classNames(...commonClassNames, {
                      [`${rootClass}__icon-hide`]: !autoSaveLoading,
                      [`${rootClass}__icon-show`]: autoSaveLoading,
                    })}
                    dataTest={`${rootClass}-cloud-svg`}
                  />
                  <Svg
                    name={SvgNames.cloudCheck}
                    fill={fill}
                    className={classNames(...commonClassNames, {
                      [`${rootClass}__icon-hide`]: autoSaveLoading || autoSaveStatus === AutoSaveStatus.FAIL,
                      [`${rootClass}__icon-show`]: showCloudCheckSvg && autoSaveStatus !== AutoSaveStatus.FAIL,
                    })}
                    dataTest={`${rootClass}-cloud-check-svg`}
                  />
                </>
              )}
            </div>
          </>
        }
      >
        {t('ComposerHeader.AutoSave.Tooltip')}
      </Tooltip>
    )
  }

  const renderPopoverCard = () => {
    let svgName = isAutoSaveDisabled ? SvgNames.cloudOff : SvgNames.cloudCheck
    let headerText = t('ComposerHeader.AutoSave.Status.Success')
    let bodyText = isCustomerCareLogin
      ? t('ComposerHeader.AutoSave.Disabled.CustomerCare')
      : isAutoSaveDisabled
      ? t('ComposerHeader.AutoSave.Disabled')
      : t(hasPublishStatus ? 'ComposerHeader.AutoSave.Popover.BodyText.Publish' : 'ComposerHeader.AutoSave.Popover.BodyText.Success')
    let buttonText = t('ComposerHeader.AutoSave.Popover.ButtonText.Success')

    const lastEditedDate = hasPublishStatus ? lastPublishedRef.current : lastEdited

    const timeZoneAbbr = new Intl.DateTimeFormat(undefined, {
      timeZone: accountTimeZoneId,
      timeZoneName: 'short',
    })
      .format(lastEditedDate)
      .split(' ')[1]

    const lastSavedToday = isToday((lastEditedDate ? new Date(lastEditedDate) : new Date()).getTime())

    if (autoSaveLoading) {
      svgName = SvgNames.cloud
      headerText = t('ComposerHeader.AutoSave.Popover.BodyText.Loading')
    } else if (isAutoSaveDisabled) {
      headerText = t('ComposerHeader.AutoSave.Status.Disabled')
    } else if (autoSaveStatus === AutoSaveStatus.FAIL) {
      svgName = SvgNames.cloudOff
      headerText = t('ComposerHeader.AutoSave.Status.Error')
      bodyText = t('ComposerHeader.AutoSave.Popover.BodyText.Error')
      buttonText = t('ComposerHeader.AutoSave.Popover.ButtonText.Error')
    }

    return (
      <>
        <div className={`${rootClass}__popover-header`}>
          <div className={`${rootClass}__popover-header__icon`}>
            <Svg name={svgName} fill={SvgColor.TEXT_GRAY} className={classNames(`${rootClass}__icon`)} />
          </div>
          <Typography text={headerText} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.MEDIUM} lineHeight={LineHeight.LARGER} />
        </div>
        <div className={`${rootClass}__popover-content`}>
          <Typography text={bodyText} type={TextType.BODY_TEXT} />
          {(hasPublishStatus || (!autoSaveLoading && allChangesSaved)) && lastEditedDate && (
            <Typography
              text={t('ComposerHeader.AutoSave.Popover.BodyText.Success.Done.Expanded', {
                text: `${hasPublishStatus ? 'Last published' : 'Last saved'} ${lastSavedToday ? 'today' : 'on'}`,
                date: lastSavedToday ? undefined : format(new Date(lastEditedDate ?? ''), 'LLLL d, yyyy'),
                time: getFormattedTime(new Date(lastEditedDate ?? '').getTime()),
                timeZone: timeZoneAbbr,
              })}
              type={TextType.BODY_TEXT}
              className={hasPublishStatus ? 'push-up-x3' : ''}
            />
          )}
        </div>
        {!hasPublishStatus && (
          <div className={`${rootClass}__popover-footer`}>
            <ButtonWithLoader
              loading={!allChangesSaved}
              onClick={() => {
                setIsPopoverOpen(false)
                onPopoverCardButtonClick?.()
              }}
              className={classNames(`${rootClass}__button-with-loader`, { [`${rootClass}__button-with-loader-loading`]: !allChangesSaved })}
            >
              {buttonText}
            </ButtonWithLoader>
          </div>
        )}
      </>
    )
  }

  const saveMessage =
    !autoSaveLoading && autoSaveStatus === AutoSaveStatus.FAIL
      ? 'ComposerHeader.AutoSave.Status.Error'
      : !newLPComposerCreateBlank && hasUnsavedChanges && !isSaving
      ? t('ComposerHeader.AutoSave.Status.UnsavedChanges')
      : text

  return (
    <div className={rootClass} data-test={rootClass}>
      <SwitchTransition mode={'out-in'}>
        <CSSTransition
          key={saveMessage}
          in={hasUnsavedChanges || isSaving || autoSaveLoading || allChangesSaved}
          nodeRef={progressBarRef}
          addEndListener={(done) => progressBarRef.current?.addEventListener('transitionend', done, false)}
          classNames={`${rootClass}__fade-text`}
        >
          <div ref={progressBarRef}>
            <Typography
              text={t(saveMessage)}
              dataTest={`${rootClass}-status`}
              type={TextType.BODY_TEXT_SMALL_LIGHT}
              weight={TextWeight.MEDIUM}
              className={classNames(`${rootClass}__status`, { [`${rootClass}__status-active`]: isPopoverOpen })}
            />
          </div>
        </CSSTransition>
      </SwitchTransition>
      {(saveMessage !== '' || !!lastEdited) && hasPopoverCard && (
        <Popover align={'end'} trigger={renderPopoverTrigger()} isOpen={isPopoverOpen} onOpenChange={(open) => setIsPopoverOpen(open)}>
          <div className={`${rootClass}__popover`}>{renderPopoverCard()}</div>
        </Popover>
      )}
    </div>
  )
}
