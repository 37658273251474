import React, { FC } from 'react'

import EmptyListing from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { TextType } from '@components/Typography/Typography'
import { MANAGE_TEST_LIST_CONTACTS_URL } from '@src/pages/EmailComposer/EmailModals/components/SendTestEmailModal/constants/SendTestEmailModal.constants'
import { useTranslation } from '@utils/const/globals'

interface TestListRecipientsEmptyListingProps {
  noContacts: boolean
  dataTest: string
}

const TestListRecipientsEmptyListing: FC<TestListRecipientsEmptyListingProps> = ({ noContacts, dataTest }) => {
  const { t } = useTranslation()
  return (
    <EmptyListing
      imgSrc={noContacts ? StaticImageNames.errorNothingFound : StaticImageNames.emptySearch}
      headline={noContacts ? t('Test.List.Contacts.Empty.Listing.Headline') : t('No results found')}
      text={
        noContacts ? (
          <Typography
            text={t('Test.List.Contacts.Empty.Listing.Text')}
            type={TextType.BODY_TEXT_LIGHT}
            tagComponents={{
              TextLink: <TextLink link={MANAGE_TEST_LIST_CONTACTS_URL} size={TextLinkSize.LARGE} hideIcon />,
            }}
            className="push-down-x2"
          />
        ) : (
          'There were no results matching your search.'
        )
      }
      dataTest={dataTest}
      fullHeight
      withoutBorder
    />
  )
}

export default TestListRecipientsEmptyListing
