import { useState, useCallback } from 'react'

import equal from 'fast-deep-equal/es6'

function useDeepEqualState<T>(defState: T): [T, (state: T) => void] {
  const [state, setState] = useState<T>(defState)

  function setNewState(newState: T) {
    if (!equal(state, newState)) {
      setState(newState)
    }
  }

  const setDeepEqualState = useCallback(setNewState, [])
  return [state, setDeepEqualState]
}

export default useDeepEqualState
