import React, { FC, useCallback, useMemo } from 'react'

import { TableV2 } from '@components/TableV2/TableV2'
import { TableV2Props } from '@components/TableV2/tableV2TS/interfaces'
import { useTranslation } from '@const/globals'
import {
  getEmailLayoutsRowActions,
  getEmailLayoutsTableColumns,
} from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/components/EmailLayoutsModalContent/EmailLayoutsModalTable.utils'
import { useEmailLayoutsModalContentContext } from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/context/EmailLayoutsModalContent.context'
import { EmailTemplateLayout } from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/graphQL/EmailLayoutsModal.graphQL'
import { SortingState } from '@tanstack/react-table'
import { Row as RowV2 } from '@tanstack/table-core/build/lib/types'

const EmailLayoutsModalTable: FC = () => {
  const { t } = useTranslation()
  const { layoutsType, loading, loadingNextData, layouts, searchTerm, onSelect, selectedId, onPreview, sortBy, onSortColumnChange } =
    useEmailLayoutsModalContentContext()

  const handleImageSelect = useCallback(
    (rowIds: string[], rows: RowV2<EmailTemplateLayout>[]) => {
      const layoutId = rowIds[0]
      if (layoutId) {
        const selectedRow = rows.find(({ id }) => id === layoutId)?.original
        if (selectedRow) {
          onSelect(selectedRow)
        }
      }
    },
    [onSelect]
  )
  const handleSortColumnsChange = useCallback((sortingState: SortingState) => onSortColumnChange(sortingState[0]), [onSortColumnChange])
  const defaultSelectedRows = useMemo(() => (selectedId ? { [selectedId]: true } : undefined), [selectedId])

  const tableV2Props: TableV2Props<EmailTemplateLayout> = {
    columns: getEmailLayoutsTableColumns(t, layoutsType, searchTerm),
    data: loading ? [] : layouts,
    loading: loading || loadingNextData,
    enableRadio: true,
    enableStickyHeader: true,
    withoutBorders: true,
    enableLazyLoading: true,
    manualSorting: true,
    resetSorting: true,
    enableInsideLoader: true,
    rowUniqueIdKey: 'stringId',
    onRowSelectionChanged: handleImageSelect,
    defaultSelectedRows,
    rowActions: getEmailLayoutsRowActions(t, onPreview),
    sortingBy: [sortBy],
    onSort: handleSortColumnsChange,
  }

  return <TableV2 {...tableV2Props} />
}

export default EmailLayoutsModalTable
