import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Container from '@components/Container'
import Tabs, { TabItemData, TabOrientation, TabStyle } from '@components/TabsAO/TabsAO'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import DataAnalytics, { RevenueImpactDataAnalytics } from '@src/pages/reports/revenueImpactNew/components/DataAnalytics/DataAnalytics'

import './ChartSection.css'

export interface ChartSectionProps {
  children: ReactNode
  className?: string
  dataTest?: string
  title: string
  analyticsData: RevenueImpactDataAnalytics[]
  loading: boolean
  tabOptions?: TabItemData[]
  defaultTabValue?: string
  onTabChange?: (tab: string) => void
}

const rootClass = 'chart-section'

const ChartSection: FC<ChartSectionProps> = (props) => {
  const { children, className, dataTest = rootClass, title, analyticsData, loading, tabOptions, defaultTabValue, onTabChange } = props

  return (
    <Container className={classNames(rootClass, className)} noSidePadding>
      <div className={`${rootClass}__header`}>
        <Typography
          text={title}
          type={TextType.SECTION_HEADER}
          className={`${rootClass}__title`}
          dataTest={`${dataTest}__title`}
          weight={TextWeight.MEDIUM}
        />
        {tabOptions && (
          <Tabs
            className={`${rootClass}__tabs`}
            dataTest={`${dataTest}__tabs`}
            childData={tabOptions}
            defaultValue={defaultTabValue}
            onChange={onTabChange}
            orientation={TabOrientation.horizontal}
            tabStyle={TabStyle.CARD}
          />
        )}
        <DataAnalytics data={analyticsData} loading={loading} />
      </div>
      <div className={`${rootClass}__content`}>{children}</div>
    </Container>
  )
}

export default ChartSection
