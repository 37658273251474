import { allEmailTriggeredFilter } from '@utils/filter'

import { EmailPickerProps, EmailType } from '../useEmailPickerProps'

export const useEmailTriggeredPickerProps = () => {
  const config: EmailPickerProps = {
    customSources: {
      allItemFilter: { ...allEmailTriggeredFilter, name: 'All emails' },
      itemName: 'Template',
      label: 'Triggered messages',
      mainColumnName: 'Email Title',
      value: EmailType.TRIGGERED,
      itemType: EmailType.TRIGGERED,
    },
    renderSearchColumns: () => [],
    renderTableColumns: [],
    renderCustomFilters: () => [],
    hasCustomFilters: true,
    hasTags: false,
    hasCreatedByMe: false,
    hasFavorites: false,
    subTypes: [],
    i18nListPageKey: 'Emails.Triggered',
  }
  return config
}
