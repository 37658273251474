import { EXPANDED_STEPS_SESSION } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/utils/ProgramStepsListing.constants'
import { ListMaintenanceProgramDto } from '@utils/listingPage/listMaintenancePrograms.utils'

export const getInitialExpandedSteps = (program?: ListMaintenanceProgramDto) =>
  Object.entries(sessionStorage)
    .filter(([key]) => key.includes(`${EXPANDED_STEPS_SESSION}:${program?.id}`))
    .reduce((expandedSteps, [key, isExpanded]) => {
      const stepId = key.split(':')[3]
      return expandedSteps.set(stepId, isExpanded === 'true')
    }, new Map())
