import { gql } from '@apollo/client'

export default gql`
  mutation setConfig($accountId: ID, $config: ConfigInput) {
    setConfig(accountId: $accountId, config: $config) {
      id
      data {
        dataSource
        featureInclude
        featuresCat
        invalidFeaturesCat
        features
        featuresNum
        featureValuesInclude
        featureValueList {
          key
          value
        }
        label
      }
      model {
        algorithm
        hyperparameterList {
          key
          value
        }
        numberCluster
      }
      visualization {
        visualizationRequired
        topK
        outputPath
      }
      filter
    }
  }
`
