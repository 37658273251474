import { GetHasToCopySegmentRequestType, SetHasToCopySegmentRequestType } from '@src/pages/ContactSegments/GraphQL/ContactSegments.segment.graphQL'
import { showStatusToast } from '@src/pages/ContactSegments/GraphQL/utils/ContactSegmentsRequests.helper'
import { Update } from '@utils/contactSegments/context/ContactSegmentsContext'
import { logNewRelicError } from '@utils/new-relic.utils'

const rootText = 'ListPage.ContactSegments.CopySegments.Requests.Segment'

interface BaseUpdate {
  update: Update
}

interface GetHasToCopySegmentUtilsParams extends BaseUpdate {
  getHasToCopySegmentRequest: GetHasToCopySegmentRequestType
}

export const getHasToCopySegmentUtils = async ({ getHasToCopySegmentRequest, update }: GetHasToCopySegmentUtilsParams) => {
  try {
    const { data, errors } = await getHasToCopySegmentRequest()
    if (data?.hasToCopySegment !== undefined) {
      update({ copySegmentsDone: data?.hasToCopySegment })
    } else if (errors) {
      showStatusToast(update, `${rootText}.HasCopySegments`)
      logNewRelicError(errors)
    }
  } catch (e) {
    showStatusToast(update, `${rootText}.HasCopySegments`)
    logNewRelicError(e)
  }
}

interface SetHasToCopySegmentUtilsParams extends BaseUpdate {
  setHasToCopySegmentRequest: SetHasToCopySegmentRequestType
}

export const setHasToCopySegmentUtils = async ({ setHasToCopySegmentRequest, update }: SetHasToCopySegmentUtilsParams) => {
  try {
    const { data, errors } = await setHasToCopySegmentRequest()
    if (data?.setHasToCopySegment !== undefined) {
      update({ copySegmentsDone: data?.setHasToCopySegment })
    } else if (errors) {
      showStatusToast(update, `${rootText}.SetHasCopySegments`)
      logNewRelicError(errors)
    }
  } catch (e) {
    showStatusToast(update, `${rootText}.SetHasCopySegments`)
    logNewRelicError(e)
  }
}
