import { gql } from '@apollo/client'

export default gql`
  mutation cloneSegment($newName: String, $srcId: String, $subSegments: Boolean, $type: String, $folderId: Long, $labels: [LabelDTOInput]) {
    cloneSegment(newName: $newName, srcId: $srcId, subSegments: $subSegments, type: $type, folderId: $folderId, labels: $labels) {
      body
      message
      objectId
      statusCode
    }
  }
`
