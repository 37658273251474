import React, { useContext, useState } from 'react'

import classNames from 'classnames'

import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import StepContent from '@src/pages/ContactSegments/components/CopySegments/components/StepContent/StepContent'
import ModalHandler from '@src/pages/ContactSegments/components/CopySegments/components/StepOne/components/ModalHandler/ModalHandler'
import { stepBaseText } from '@src/pages/ContactSegments/components/CopySegments/constants/CopySegments.constants'
import { CopySegmentsContext, Steps } from '@src/pages/ContactSegments/components/CopySegments/context/CopySegments.context'
import { stepProps } from '@src/pages/ContactSegments/components/CopySegments/utils/CopySegments.utils'
import { ALL_ACTON_CONTACTS_SEGMENT_NAME } from '@src/pages/ContactSegments/utils/ContactSegments.constants'
import { getFormattedNumber } from '@utils/numbers'

import './StepOne.css'

const rootClass = 'step-one'
const stepOneText = `${stepBaseText}One.`

const StepOne = () => {
  const {
    values: { parentLocation },
  } = useContext(CopySegmentsContext)
  const [showListPicker, setShowListPicker] = useState<boolean>(false)
  const { t } = useTranslation()

  const onClick = () => setShowListPicker(() => true)

  return (
    <>
      <ModalHandler showListPicker={showListPicker} setShowListPicker={setShowListPicker} />
      <StepContent className={rootClass} data-test={rootClass} {...stepProps[Steps.ONE]} onClick={onClick}>
        <div
          className={classNames(`${rootClass}__selected-parent-container`, {
            [`${rootClass}__selected-parent-centered`]: !parentLocation || parentLocation.name === ALL_ACTON_CONTACTS_SEGMENT_NAME,
          })}
        >
          <Typography
            text={!!parentLocation ? parentLocation?.name : ALL_ACTON_CONTACTS_SEGMENT_NAME}
            type={TextType.BODY_TEXT_SMALL}
            weight={TextWeight.MEDIUM}
          />
          {!!parentLocation && parentLocation.name !== ALL_ACTON_CONTACTS_SEGMENT_NAME && (
            <Typography
              text={t(`${stepOneText}Contacts`, { count: getFormattedNumber(parentLocation?.size ?? 0) })}
              type={TextType.BODY_TEXT_SMALL_LIGHT}
              weight={TextWeight.REGULAR}
            />
          )}
        </div>
      </StepContent>
    </>
  )
}

export default StepOne
