export interface TotalRegistrationsCard {
  registrations: number
  webinars: number
}

export interface AttendanceCard {
  registrations: number
  attended: number
}

export const WEBINARS_DATE_SELECTED_OPTION = 'formJoinView:WebinarsSelectedOption'

export const defaultTotalRegistrationsCardValues: TotalRegistrationsCard = {
  registrations: 0,
  webinars: 0,
}

export const defaultAttendanceCardValues: AttendanceCard = {
  registrations: 0,
  attended: 0,
}
