import { TFunction } from 'i18next'

import { ProgressBarStep } from '@components/ProgressBar/ProgressBar'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { ExportContactsSteps } from '@src/pages/ExportContacts/utils/ExportContacts.constants'

export interface ExportField {
  field: UnifiedListFieldMapping
  selected: boolean
}

export const getExportContactsListingTabs = (t: TFunction, allCompleted: boolean, sectionParam = 'file'): ProgressBarStep[] => {
  const activeSectionByParam: { [key: string]: ExportContactsSteps } = {
    file: ExportContactsSteps.SELECT_FILE,
    options: ExportContactsSteps.EXPORT_OPTIONS,
    fields: ExportContactsSteps.CHOOSE_FIELDS,
    review: ExportContactsSteps.REVIEW_EXPORT,
  }
  return [
    {
      key: ExportContactsSteps.SELECT_FILE,
      header: t('ExportContacts.Sections.ExportFile'),
      isCompleted: allCompleted,
    },
    {
      key: ExportContactsSteps.EXPORT_OPTIONS,
      header: t('ExportContacts.Sections.ExportOptions'),
      isCompleted: allCompleted,
    },
    {
      key: ExportContactsSteps.CHOOSE_FIELDS,
      header: t('ExportContacts.Sections.ChooseFields'),
      isCompleted: allCompleted,
    },
    {
      key: ExportContactsSteps.REVIEW_EXPORT,
      header: t('ExportContacts.Sections.ReviewExport'),
      isCompleted: allCompleted,
    },
  ].map((step) => ({ ...step, isActive: step.key === activeSectionByParam[sectionParam] }))
}

export const changeUrlPathOnStepChange = (newTab: ExportContactsSteps) => {
  const optionsPath = '/options'
  const fieldsPath = '/fields'
  const urlHasOptionsPath = location.pathname.includes(optionsPath)
  const urlHasFieldsPath = location.pathname.includes(fieldsPath)
  let newPath = location.pathname

  switch (newTab) {
    case ExportContactsSteps.EXPORT_OPTIONS:
      if (!urlHasOptionsPath) {
        newPath = urlHasFieldsPath ? newPath.replace(fieldsPath, optionsPath) : `${newPath}${optionsPath}`
      }
      break
    case ExportContactsSteps.CHOOSE_FIELDS:
      if (!urlHasFieldsPath) {
        newPath = urlHasOptionsPath ? newPath.replace(optionsPath, fieldsPath) : `${newPath}${fieldsPath}`
      }
      break
    default:
      if (urlHasOptionsPath || urlHasFieldsPath) {
        newPath = newPath.replace(urlHasOptionsPath ? optionsPath : fieldsPath, '')
      }
  }

  history.replaceState({}, '', newPath)
}
