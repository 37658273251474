import { CRMSyncDisplay } from '@complex/ContactSegments/SegmentNameWithCrmSyncAndInfoHover/SegmentNameWithCrmSyncAndInfoHover'
import { SvgNames } from '@components/Svg'
import { CRM_LIMIT_REACHED_WARNING, CRM_MISSING_ID_WARNING, CRM_ON_GOING_SYNC } from '@utils/contactSegments/contactSegments.utils'
import { CRM_STATUS } from '@utils/crm.utils'

export const crmSyncOptions: { [key: string]: CRMSyncDisplay } = {
  [CRM_STATUS.SF_REPORT_ID_FIELD_MISSING]: {
    tooltipText: CRM_MISSING_ID_WARNING,
    icon: SvgNames.fail,
  },
  [CRM_STATUS.SF_REPORT_NOT_FOUND]: {
    tooltipText: 'AddContactsFromCRM.SelectCRMSources.NotFound.Header',
    icon: SvgNames.fail,
  },
  [CRM_STATUS.SF_REPORT_UNSUPPORTED_FORMAT]: {
    tooltipText: 'AddContactsFromCRM.SelectCRMSources.Unsupported.Content',
    icon: SvgNames.fail,
  },
  [CRM_STATUS.SF_REPORT_UNSUPPORTED_TYPE]: {
    tooltipText: 'AddContactsFromCRM.SelectCRMSources.Unsupported.Content',
    icon: SvgNames.fail,
  },
  [CRM_STATUS.SF_REPORT_MAX_RECORDS_REACHED]: {
    tooltipText: CRM_LIMIT_REACHED_WARNING,
    icon: SvgNames.warningSolidNoFill,
    tooltipData: {
      link: 'https://act-on.com',
      linkText: 'More info',
    },
  },
  [CRM_STATUS.CRM_SYNC_FAILED]: {
    tooltipText: 'AddContactsFromCRM.SelectCRMSources.Sync.Failed.Header',
    icon: SvgNames.fail,
  },
  [CRM_STATUS.ON_GOING_SYNC]: {
    tooltipText: CRM_ON_GOING_SYNC,
    icon: SvgNames.reloadNoFill,
  },
}
