import { useApolloClient } from '@apollo/client'
import getCountQuery from '@graphql/microservices/categorization/getCountQuery'
import getCountQueryBySubTypes from '@graphql/microservices/categorization/getCountQueryBySubTypes'
import getImportCount from '@graphql/microservices/list/getImportCount'
import getCopySegmentCount from '@graphql/microservices/segment/getCopySegmentCount'
import restoreForm from '@graphql/mutations/restoreForm'
import restoreProgramFromBackup from '@graphql/mutations/restoreProgramFromBackup'
import setAllContactsUpgradeCompleted from '@graphql/mutations/setAllContactsUpgradeCompleted'
import stopProgram from '@graphql/mutations/stopProgram'
import getAllContactsUpgradeCompleted from '@graphql/queries/getAllContactsUpgradeCompleted'
import {
  GetCountQueryBySubTypesQuery,
  GetCountQueryBySubTypesQueryVariables,
  GetCountQueryQuery,
  GetCountQueryQueryVariables,
} from '@graphql/types/microservice/categorization-types'
import { GetImportDetailsStatusQuery, GetImportDetailsStatusQueryVariables } from '@graphql/types/microservice/list-types'
import { GetCopySegmentCountQuery, GetCopySegmentCountQueryVariables } from '@graphql/types/microservice/segment-types'
import {
  RestoreFormMutation,
  RestoreFormMutationVariables,
  RestoreProgramFromBackupMutation,
  RestoreProgramFromBackupMutationVariables,
  SetAllContactsUpgradeCompletedMutation,
  SetAllContactsUpgradeCompletedMutationVariables,
  StopProgramMutation,
  StopProgramMutationVariables,
} from '@graphql/types/mutation-types'
import { GetAllContactsUpgradeCompletedQuery, GetAllContactsUpgradeCompletedQueryVariables } from '@graphql/types/query-types'
import { ItemSubTypeDtoName, ItemType } from '@utils/categorization'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export interface UpgradeAssistantRequests {
  getFormToUpgradeCount: (days?: number) => Promise<number>
  getFormTemplatesCount: () => Promise<number>
  getAllContactsFormCount: () => FetchPromise<GetCountQueryQuery>
  getAllContactsUpgrade: () => FetchPromise<GetAllContactsUpgradeCompletedQuery>
  setAllContactsUpgrade: (itemsCompleted: string[]) => FetchPromise<SetAllContactsUpgradeCompletedMutation>
  getUpgradedSegmentCount: () => FetchPromise<GetCopySegmentCountQuery>
  getUpgradedListsCount: () => FetchPromise<GetImportDetailsStatusQuery>
  getAllContactsProgramCount: () => FetchPromise<GetCountQueryQuery>
  getProgramsToUpgradeCount: () => Promise<number>
  restoreFormRequest: (srcFormId: string, backupId: string) => FetchPromise<RestoreFormMutation>
  restoreProgramRequest: (srcProgramId: string, bckProgramId: string) => FetchPromise<RestoreProgramFromBackupMutation>
  pauseProgramRequest: (srcProgramId: string) => FetchPromise<StopProgramMutation>
}
export const useUpgradeAssistantRequests = (): UpgradeAssistantRequests => {
  const actonClient = useApolloClient()
  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })
  const { client: segmentClient } = useMicroserviceClient({ serviceName: MicroserviceClients.SEGMENT })
  const { client: listClient } = useMicroserviceClient({ serviceName: MicroserviceClients.LIST })

  const getAllContactsFormCount = () => {
    return categorizationClient.query<GetCountQueryQuery, GetCountQueryQueryVariables>({
      query: getCountQuery,
      fetchPolicy: 'network-only',
      variables: {
        field: 'listId',
        values: ['l-mirrored-list'],
        subTypes: [],
        type: 'Form',
      },
    })
  }

  const getFormToUpgradeCount = (days = 180) => {
    return categorizationClient
      .query<GetCountQueryBySubTypesQuery, GetCountQueryBySubTypesQueryVariables>({
        query: getCountQueryBySubTypes,
        fetchPolicy: 'network-only',
        variables: {
          subTypes: ['activeForm', ItemSubTypeDtoName.NO_PHASE_3, 'new'],
          type: ItemType.FORM,
          days,
        },
      })
      .then(({ data }) => {
        return data.getCountQueryBySubTypes?.count ?? 0
      })
  }

  const getFormTemplatesCount = () => {
    return categorizationClient
      .query<GetCountQueryQuery, GetCountQueryQueryVariables>({
        query: getCountQuery,
        fetchPolicy: 'network-only',
        variables: {
          field: 'name',
          values: [''],
          subTypes: [ItemSubTypeDtoName.NO_PHASE_3],
          type: ItemType.FORM_TEMPLATE,
        },
      })
      .then(({ data }) => {
        if (data.getCountQuery && data.getCountQuery.length > 0) {
          return data.getCountQuery[0]?.count ?? 0
        } else {
          return 0
        }
      })
  }

  const setAllContactsUpgrade = (itemsCompleted: string[]) => {
    return actonClient.mutate<SetAllContactsUpgradeCompletedMutation, SetAllContactsUpgradeCompletedMutationVariables>({
      mutation: setAllContactsUpgradeCompleted,
      fetchPolicy: 'network-only',
      variables: {
        itemsCompleted,
      },
    })
  }

  const getAllContactsUpgrade = () => {
    return actonClient.query<GetAllContactsUpgradeCompletedQuery, GetAllContactsUpgradeCompletedQueryVariables>({
      query: getAllContactsUpgradeCompleted,
      fetchPolicy: 'no-cache',
    })
  }

  const getUpgradedSegmentCount = () => {
    return segmentClient.query<GetCopySegmentCountQuery, GetCopySegmentCountQueryVariables>({
      query: getCopySegmentCount,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    })
  }

  const getUpgradedListsCount = () => {
    return listClient.query<GetImportDetailsStatusQuery, GetImportDetailsStatusQueryVariables>({
      query: getImportCount,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        importDetailsStatus: {
          count: 0,
          pageNumber: 1,
          pageSize: 99999,
          filterBy: {
            source: ['ACTON_LIST'],
            status: ['COMPLETED'],
            total_unsubmitted: ['0'],
          },
        },
      },
    })
  }

  const getAllContactsProgramCount = () => {
    return categorizationClient.query<GetCountQueryQuery, GetCountQueryQueryVariables>({
      query: getCountQuery,
      fetchPolicy: 'network-only',
      variables: {
        field: 'hasActOnContactList',
        values: ['true'],
        subTypes: [],
        type: ItemType.PROGRAM,
      },
    })
  }

  const getProgramsToUpgradeCount = () => {
    return categorizationClient
      .query<GetCountQueryQuery, GetCountQueryQueryVariables>({
        query: getCountQuery,
        fetchPolicy: 'network-only',
        variables: {
          field: 'hasActOnContactList',
          values: ['false'],
          subTypes: [],
          type: ItemType.PROGRAM,
        },
      })
      .then(({ data }) => {
        if (data.getCountQuery && data.getCountQuery.length > 0) {
          return data.getCountQuery[0]?.count ?? 0
        } else {
          return 0
        }
      })
  }

  const restoreFormRequest = (srcFormId: string, backupId: string) => {
    return actonClient.mutate<RestoreFormMutation, RestoreFormMutationVariables>({
      mutation: restoreForm,
      fetchPolicy: 'network-only',
      variables: {
        srcFormId,
        backupId,
      },
    })
  }

  const restoreProgramRequest = (srcProgramId: string, bckProgramId: string) => {
    return actonClient.mutate<RestoreProgramFromBackupMutation, RestoreProgramFromBackupMutationVariables>({
      mutation: restoreProgramFromBackup,
      fetchPolicy: 'network-only',
      variables: {
        srcProgramId,
        bckProgramId,
      },
    })
  }

  const pauseProgramRequest = (programUrlId: string) => {
    return actonClient.mutate<StopProgramMutation, StopProgramMutationVariables>({
      mutation: stopProgram,
      errorPolicy: 'all',
      variables: {
        programId: programUrlId,
      },
    })
  }

  return {
    getFormToUpgradeCount,
    getFormTemplatesCount,
    getAllContactsFormCount,
    getAllContactsUpgrade,
    setAllContactsUpgrade,
    getUpgradedSegmentCount,
    getUpgradedListsCount,
    getAllContactsProgramCount,
    getProgramsToUpgradeCount,
    restoreFormRequest,
    restoreProgramRequest,
    pauseProgramRequest,
  }
}
