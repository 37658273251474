import { gql } from '@apollo/client'

export default gql`
  mutation startJob($accountId: ID, $configId: Int, $targetSegment: String, $processorConfigs: [ProcessorConfigPairInput]) {
    startJob(accountId: $accountId, configId: $configId, targetSegment: $targetSegment, processorConfigs: $processorConfigs) {
      id
      executionId
      accountId
      status
      config {
        id
        data {
          dataSource
          featureInclude
          featuresCat
          invalidFeaturesCat
          features
          featuresNum
          featureValuesInclude
          featureValueList {
            key
            value
          }
          label
        }
        model {
          algorithm
          hyperparameterList {
            key
            value
          }
          numberCluster
        }
        visualization {
          visualizationRequired
          topK
          outputPath
        }
        filter
      }
      startTime
      completionTime
      insertTime
      maximumRunTime
      memoryOverride
      targetSegment
    }
  }
`
