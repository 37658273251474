import { gql } from '@apollo/client'

export default gql`
  query getFormSummaryData($formId: String!) {
    getFormSummaryData(formId: $formId) {
      accountId
      formId
      formName
      formDescription
      listName
      listId
      blocks
      authorId
    }
  }
`
