import React, { FC, useContext } from 'react'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { NewSource, NewSourceProps } from '@src/pages/programs/upgradeManager/components/NewSource/NewSource'
import { UpgradedSource, UpgradeManagerContext, UpgradeStep } from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'

import './SourceReplacement.css'

interface SourceReplacementProps extends NewSourceProps {
  sourceToUpgrade: UpgradedSource
}

const rootClass = 'source-replacement'
const rootText = 'AutomatedPrograms.UpgradeManager.SourceReplacement.'

export const SourceReplacement: FC<SourceReplacementProps> = (props: SourceReplacementProps) => {
  const { sourceToUpgrade, ...rest } = props

  const {
    source: { sourceName, count },
  } = sourceToUpgrade

  const {
    values: { activeUpgradeStep },
  } = useContext(UpgradeManagerContext)

  const { t } = useTranslation()

  const sourceText =
    activeUpgradeStep === UpgradeStep.CONTACT_SOURCES
      ? 'ContactSource.'
      : activeUpgradeStep === UpgradeStep.EARLY_EXITS
      ? 'EarlyExitSource.'
      : 'FixStepIssues.'

  return (
    <div className={rootClass} data-test={rootClass}>
      <div className={`${rootClass}__container`}>
        <Typography text={t(`${rootText}${sourceText}Current`)} type={TextType.BODY_TEXT} weight={TextWeight.MEDIUM} />
        <div className={`${rootClass}__source-box ${rootClass}__source-box-current`}>
          <TextWithTooltipOnEllip typographyProps={{ text: sourceName, type: TextType.BODY_TEXT }} />
          <Typography text={t(`${rootText}Contacts`, { count })} type={TextType.BODY_TEXT_LIGHT} />
        </div>
      </div>
      <Svg className={`${rootClass}__arrow`} name={SvgNames.arrowRight} type={SvgType.LARGE_ICON} />
      <NewSource sourceToUpgrade={sourceToUpgrade} {...rest} />
    </div>
  )
}
