import React, { FC } from 'react'

import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

interface CustomizeModal {
  isOpen: boolean
  onAnswer: (answer: YesNo) => void
}

export interface CustomizeModalsProps {
  showResetProps: CustomizeModal
  showPublishProps: CustomizeModal
  showDiscardProps: CustomizeModal
}

const rootClass = 'customize-modals'

const CustomizeModals: FC<CustomizeModalsProps> = (props: CustomizeModalsProps) => {
  const {
    showResetProps: { isOpen: showReset, onAnswer: onResetAnswer },
    showPublishProps: { isOpen: showPublish, onAnswer: onPublishAnswer },
    showDiscardProps: { isOpen: showDiscard, onAnswer: onDiscardAnswer },
  } = props
  const { t } = useTranslation()

  return (
    <div className={rootClass}>
      {showReset && (
        <ConfirmationModal
          isOpen={showReset}
          isYesNo
          dataTest={`${rootClass}-reset`}
          onAnswer={onResetAnswer}
          okButtonText={'Marketing.Customize.Reset.Button.Text'}
          body={
            <Typography
              text={t('Marketing.Customize.Reset.Modal.Body')}
              type={TextType.BODY_TEXT_LIGHT}
              tagProps={{ b: { weight: TextWeight.BOLD, inline: true } }}
            />
          }
          title={t('Marketing.Customize.Reset.Modal.Title')}
        />
      )}
      {showPublish && (
        <ConfirmationModal
          isOpen={showPublish}
          isYesNo
          dataTest={`${rootClass}-publish`}
          onAnswer={onPublishAnswer}
          okButtonText={'Yes, publish'}
          noButtonText={'Continue editing'}
          body={<Typography text={t('Marketing.Customize.Publish.Modal.Body')} type={TextType.BODY_TEXT_LIGHT} />}
          title={t('Publish changes')}
        />
      )}
      {showDiscard && (
        <ConfirmationModal
          isOpen={showDiscard}
          isDelete
          dataTest={`${rootClass}-discard`}
          onAnswer={onDiscardAnswer}
          deleteButtonText={'Discard changes'}
          cancelButtonText={'Continue editing'}
          body={<Typography text={t('Marketing.Customize.Discard.Modal.Body')} type={TextType.BODY_TEXT_LIGHT} />}
          title={t('Discard all unpublished changes')}
        />
      )}
    </div>
  )
}

export default CustomizeModals
