import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import {
  deletedListError,
  missingListError,
} from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/components/ListProgramStep/utils/ListProgramStep.utils'
import { useTopDirectSegments } from '@src/pages/programs/edit/state/getTopDirectSelectSegments.util'

import './CopyToListStep.css'

export interface CopyToListStepProps {
  listName: string
  listId: string
  update: boolean
  isCopy: boolean
  className?: string
  dataTest?: string
}

const rootClass = 'copy-to-list-step'

const CopyToListStep: FC<CopyToListStepProps> = (props: CopyToListStepProps) => {
  const { listName, listId, update, isCopy, dataTest = rootClass, className = '' } = props
  const [segmentName, setSegmentName] = useState<string>('')
  const name = segmentName || listName
  const { t } = useTranslation()
  const listOptions = useTopDirectSegments(listId)

  useEffect(() => {
    if (listOptions.length > 0) {
      const selectedSegment = listOptions.find((option) => option.value === listId)
      setSegmentName(selectedSegment?.label ?? '')
    }
  }, [listOptions])

  const renderListName = () => {
    return name ? (
      <Typography text={name || t('undefined')} weight={name ? TextWeight.MEDIUM : TextWeight.ITALIC} inline />
    ) : (
      <Tooltip trigger={<Svg name={SvgNames.warningSolid} type={SvgType.ICON} />} textBold={listId ? deletedListError : missingListError} />
    )
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__target-list-container`}>
        <Typography text={`${t(isCopy ? 'Target List' : 'Target Segment')}: `} inline />{' '}
        {name && <Svg name={SvgNames.contacts} type={SvgType.ICON} />}
        {renderListName()}
      </div>
      {update && <Typography text={t('Existing contacts will be updated')} type={TextType.BODY_TEXT_LIGHT} className={`${rootClass}__update`} />}
    </div>
  )
}

export default CopyToListStep
