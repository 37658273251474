import React, { FC, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import DirtyBanner from '@components/DirtyBanner/DirtyBanner'
import DiscardModal from '@components/DiscardModal/DiscardModal'
import PageContainer from '@components/PageContainer'
import PageHeader from '@components/PageHeader'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import StatusToast, { Status } from '@components/StatusToast/StatusToast'
import { useTranslation } from '@const/globals'
import { Rule } from '@graphql/types/microservice/segment-types'
import { yupResolver } from '@hookform/resolvers/yup'
import ContactOwnerSetting from '@src/pages/Settings/OtherSettings/ContactSettings/components/ContactOwnerSetting/ContactOwnerSetting'
import EmailSuppressionDomains from '@src/pages/Settings/OtherSettings/ContactSettings/components/EmailSuppressionDomains/EmailSuppressionDomains'
import FatigueSuppressionRules from '@src/pages/Settings/OtherSettings/ContactSettings/components/FatigueSuppressionRules/FatigueSuppressionRules'
import InactiveSoftBounces from '@src/pages/Settings/OtherSettings/ContactSettings/components/InactiveSoftBounces/InactiveSoftBounces'
import {
  ContactSettingsFields,
  contactSettingsValidators,
  getContactSettingsUtils,
  saveContactSettings,
} from '@src/pages/Settings/OtherSettings/ContactSettings/ContactSettings.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import useCRM from '@utils/hooks/useCRM'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'

import './ContactSettings.css'

interface Props {
  dataTest?: string
}

export interface ContactSettings {
  [ContactSettingsFields.FATIGUE_RULES]: Rule[]
  [ContactSettingsFields.SOFT_BOUNCES_ALLOWED]: number
  [ContactSettingsFields.EMAIL_SUPPRESSION_DOMAIN]: string
  [ContactSettingsFields.CONTACT_OWNER_NAME]?: number
  [ContactSettingsFields.CONTACT_OWNER_EMAIL]?: number
  [ContactSettingsFields.OPPORTUNITIES_SEGMENT_ID]?: string
  [ContactSettingsFields.PROSPECTS_SEGMENT_ID]?: string
  [ContactSettingsFields.CONTACTS_SEGMENT_ID]?: string
}

interface ContactSettingsState {
  isDiscarding: boolean
  statusToast?: { message: string; status: Status }
  loading: boolean
}

const rootClass = 'contact-settings'

const defaultSettings: ContactSettings = {
  [ContactSettingsFields.FATIGUE_RULES]: [],
  [ContactSettingsFields.SOFT_BOUNCES_ALLOWED]: 0,
  [ContactSettingsFields.EMAIL_SUPPRESSION_DOMAIN]: '',
  [ContactSettingsFields.CONTACT_OWNER_NAME]: -1,
  [ContactSettingsFields.CONTACT_OWNER_EMAIL]: -1,
  [ContactSettingsFields.OPPORTUNITIES_SEGMENT_ID]: '',
  [ContactSettingsFields.PROSPECTS_SEGMENT_ID]: '',
  [ContactSettingsFields.CONTACTS_SEGMENT_ID]: '',
}

const initialState: ContactSettingsState = {
  isDiscarding: false,
  loading: true,
}

const ContactSettings: FC<Props> = (props: Props) => {
  const { dataTest = rootClass } = props
  const [state, setState] = useState<ContactSettingsState>(initialState)
  const { isDiscarding, statusToast, loading } = state
  const { t } = useTranslation()
  const { hasCRMConnected } = useCRM()
  const { personalizedFrom, websiteProspectorOwnerUCL } = useAccountSettings()
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.SEGMENT })

  const linkBack = useMemo(() => new URLSearchParams(window.location.search).has('redirect'), [])

  const formMethods = useForm<ContactSettings>({
    defaultValues: defaultSettings,
    resolver: yupResolver(contactSettingsValidators),
    reValidateMode: 'onChange',
    mode: 'onBlur',
  })

  const {
    trigger,
    control,
    getValues,
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = formMethods

  const onSave = async (): Promise<void> => {
    const settings = getValues()
    return saveContactSettings(settings, client).then((isSaved) => {
      if (isSaved) {
        setState((state) => ({
          ...state,
          statusToast: { message: t('Your Contact Settings changes have been saved'), status: Status.SUCCESS },
        }))
        reset(settings)
      }
    })
  }

  const closeStatusToast = () => setState((state) => ({ ...state, statusToast: undefined }))
  const isSaveDisabled = !isValid && (Object.keys(errors).length > 1 || errors.emailSuppressionDomain === undefined)

  useEffect(() => {
    trigger()
  }, [isDirty])

  useEffect(() => {
    getContactSettingsUtils(client).then((settings) => {
      setState({ ...state, loading: false })
      reset(settings)
    })
    register(ContactSettingsFields.OPPORTUNITIES_SEGMENT_ID)
    register(ContactSettingsFields.CONTACTS_SEGMENT_ID)
    register(ContactSettingsFields.PROSPECTS_SEGMENT_ID)
  }, [])

  const renderDiscardModal = () => (
    <DiscardModal
      isOpen={isDiscarding}
      openModal={(isDiscarding) => setState((state) => ({ ...state, isDiscarding }))}
      discard={() => reset()}
      initialData={[]}
      setDiscardAction={() => undefined}
    />
  )

  return (
    <FormProvider {...formMethods}>
      <PageContainer className={rootClass} dataTest={dataTest}>
        {isDiscarding && renderDiscardModal()}
        {statusToast && <StatusToast message={statusToast.message} closeStatus={closeStatusToast} status={statusToast.status} />}
        {isDirty && (
          <DirtyBanner
            expanded
            onSave={handleSubmit(onSave, (errors) => Object.keys(errors).length === 1 && errors.emailSuppressionDomain !== undefined && onSave())}
            onDiscard={() => setState((state) => ({ ...state, isDiscarding: true }))}
            saveDisabled={isSaveDisabled}
          />
        )}
        <PositionContainer noOverflow>
          <PageHeader primaryText={t('Contact Settings')} leftContent linkBack={linkBack} />
          <InactiveSoftBounces
            className={rootClass}
            dataTest={`${dataTest}__inactive-soft-bounces`}
            error={errors?.softBouncesAllowed}
            value={watch('softBouncesAllowed')}
            disabled={isSubmitting}
            register={register}
            loading={loading}
          />
          <EmailSuppressionDomains
            className={rootClass}
            dataTest={`${dataTest}__email-suppression-domains`}
            register={register}
            disabled={isSubmitting}
            error={errors?.emailSuppressionDomain}
          />
          <FatigueSuppressionRules
            className={rootClass}
            dataTest={`${dataTest}__fatigue-suppression-rules`}
            register={register}
            disabled={isSubmitting}
            errors={errors}
            control={control}
          />
          {((!hasCRMConnected && personalizedFrom) || websiteProspectorOwnerUCL) && (
            <ContactOwnerSetting className={rootClass} dataTest={`${dataTest}__contact-owner-setting`} register={register} />
          )}
        </PositionContainer>
      </PageContainer>
    </FormProvider>
  )
}

export default ContactSettings
