import React, { FC, useContext } from 'react'

import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'
import {
  SourceUpgradeStepType,
  UpgradedSource,
  UpgradedSourceUpgradeStepType,
  UpgradeManagerContext,
} from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'
import { getSource } from '@src/pages/programs/UpgradePrograms/components/ReviewUpgrade/sections/FixStepIssuesSection/components/ReviewCurrentStep/ReviewCurrentStep.utils'
import { ReviewSourceReplacement } from '@src/pages/programs/UpgradePrograms/components/ReviewUpgrade/sections/FixStepIssuesSection/components/ReviewCurrentStep/steps/components/ReviewSourceReplacement/ReviewSourceReplacement'

interface ReviewSourceStepProps {
  step: UpgradedSourceUpgradeStepType
}

export const ReviewSourceStep: FC<ReviewSourceStepProps> = ({ step }: ReviewSourceStepProps) => {
  const {
    values: { upgradedStepSources, stepsToUpgrade },
  } = useContext(UpgradeManagerContext)
  const {
    values: {
      program: { sources },
    },
  } = useContext(ProgramManagerContext)
  const upgradedSource = upgradedStepSources[step.stepId ?? ''][step.upgradeId ?? '']
  const originalStep = stepsToUpgrade?.find((originalStep) => originalStep.stepId === step.stepId) as SourceUpgradeStepType
  const programOriginalSource = sources.find((source) => source.id === originalStep?.srcId ?? '')

  const source: UpgradedSource = getSource(programOriginalSource, upgradedSource)

  return <ReviewSourceReplacement source={source} />
}
