import React, { CSSProperties, FC, ReactNode } from 'react'

import classNames from 'classnames'

import Loader from '@components/Loader'
import Svg from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Toast from '@components/Toast'
import Typography, { TextWeight } from '@components/Typography/Typography'

import './StatusToast.css'

export enum Status {
  SUCCESS = 'success',
  WARNING = 'warning',
  PAUSE = 'pause',
  FAIL = 'fail',
  LOADING = 'loading',
}

export interface StatusToastProps {
  message: string | ReactNode
  closeStatus: () => void
  title?: string
  isSuccess?: boolean
  status?: Status
  hasTimeout?: boolean
  className?: string
  style?: CSSProperties
  customTimeoutTime?: number
}

const rootClass = 'status-toast'

const getStatusIcon = (status: Status) => {
  const statusIcon = {
    success: SvgNames.checkCircle,
    warning: SvgNames.warning,
    pause: SvgNames.pauseCircle,
    fail: SvgNames.errorCircle,
    loading: SvgNames.reload,
  }
  return statusIcon[status]
}

const StatusToast: FC<StatusToastProps> = ({
  isSuccess,
  message,
  title,
  closeStatus,
  status,
  className,
  hasTimeout = true,
  style,
  customTimeoutTime,
}: StatusToastProps) => {
  const icon = status ? getStatusIcon(status) : isSuccess ? SvgNames.checkCircle : SvgNames.errorCircle
  const svg = (
    <div
      className={classNames({
        [`${rootClass}__success`]: status === Status.SUCCESS || isSuccess === true,
        [`${rootClass}__warning`]: status === Status.WARNING || status === Status.PAUSE,
        [`${rootClass}__fail`]: status === Status.FAIL || isSuccess === false,
        [`${rootClass}__loading`]: status === Status.LOADING,
      })}
      data-test={'status-svg-element'}
    >
      {status === Status.LOADING ? <Loader /> : <Svg name={icon} />}
    </div>
  )
  const text = title ? (
    <div className={`${rootClass}__text-with-title`}>
      <Typography text={title} weight={TextWeight.BOLD} />
      <Typography className={`${rootClass}__text-with-title__text`} text={message} tagProps={{ bold: { weight: TextWeight.BOLD } }} />
    </div>
  ) : (
    message
  )

  return (
    <Toast
      text={text}
      onClose={closeStatus}
      icon={svg}
      noPadding
      timeout={hasTimeout}
      className={className}
      style={style}
      customTimeoutTime={customTimeoutTime}
    />
  )
}

export default StatusToast
