import React, { FC } from 'react'

import classNames from 'classnames'

import Modal, { ModalBody } from '@components/Modal'
import Spinner, { SpinnerProps } from '@components/Spinner/Spinner'

import './SpinnerModal.css'

interface SpinnerModalProps extends SpinnerProps {
  className?: string
  dataTest?: string
}

const rootClass = 'spinner-modal'

const SpinnerModal: FC<SpinnerModalProps> = (props: SpinnerModalProps) => {
  const { dataTest = rootClass, className = '', ...spinnerProps } = props

  return (
    <Modal className={classNames(rootClass, className)} dataTest={dataTest} isOpen>
      <ModalBody className={`${rootClass}__body`}>
        <Spinner {...spinnerProps} />
      </ModalBody>
    </Modal>
  )
}

export default SpinnerModal
