import { gql } from '@apollo/client'

export default gql`
  mutation verifyFromAddress($uuid: String!) {
    verifyFromAddress(uuid: $uuid) {
      status
      message
      uuid
      verified
    }
  }
`
