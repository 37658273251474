import { gql } from '@apollo/client'

export default gql`
  query retrieveMediaFilesByFolder($mediaType: String!, $contentType: String, $favoriteOnly: Boolean!) {
    retrieveMediaFilesByFolder(mediaType: $mediaType, contentType: $contentType, favoriteOnly: $favoriteOnly) {
      accountId
      folders {
        id
        name
        isDefault
        entries {
          id
          title
          previewUrl
          linkUrl
          docType
          modified
          created
          isFavorite
          size
          downloadLink
          expirationDate
        }
      }
    }
  }
`
