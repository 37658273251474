import React, { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { CustomSourceItems, ExtendedItemDto } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { MIRRORED_LISTS_ID } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import AutomatedProgramPickerModal from '@components/AssetPickers/AutomatedProgramPickerModal/AutomatedProgramPickerModal'
import Button, { ButtonType } from '@components/Button'
import Container from '@components/Container'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import { Program } from '@graphql/types/query-types'
import { ProgramSelected } from '@src/pages/programs/UpgradePrograms/components/ProgramSelected/ProgramSelected'
import { ProgramFromItemDto } from '@src/pages/programs/UpgradePrograms/UpgradePrograms'
import { getDisabledRowTooltip } from '@src/pages/programs/UpgradePrograms/utils/UpgradePrograms.utils'
import { Row } from '@tanstack/react-table'

import './SelectProgram.css'

interface State {
  showProgramPicker: boolean
  program?: ProgramFromItemDto
}

const rootClass = 'select-program'
const rootText = 'UpgradePrograms.Page.SelectProgram.'

export const SelectProgram: FC = () => {
  const history = useHistory<{ openUpgradeSidePanel?: boolean; programToUpgrade?: ProgramFromItemDto }>()
  const programToUpgrade = history.location.state?.programToUpgrade

  const [state, setState] = useState<State>({
    ...(programToUpgrade ? { program: programToUpgrade as ProgramFromItemDto } : {}),
    showProgramPicker: false,
  })
  const { showProgramPicker, program } = state

  const { t } = useTranslation()

  useEffect(() => {
    if (programToUpgrade) {
      setState((state) => ({ ...state, program: programToUpgrade as ProgramFromItemDto }))
    }
  }, [history.location.state])

  const onClick = () => setState((state) => ({ ...state, showProgramPicker: true }))

  const onProgramsPickerClose = () => setState((state) => ({ ...state, showProgramPicker: false }))

  const onProgramPickerSubmit = (items: CustomSourceItems) =>
    setState((state) => ({ ...state, program: Object.values(items).flatMap((item) => item)[0] as ProgramFromItemDto, showProgramPicker: false }))

  const disableAOCBasedPrograms = (program: Program) =>
    !!program.hasActOnContactList || !!program.sources.find((source) => source.baseId.startsWith(MIRRORED_LISTS_ID))

  const disabledRowTooltip = (program: Program) => {
    return disableAOCBasedPrograms(program) ? getDisabledRowTooltip(t) : ''
  }

  const onNext = () => history.push(`${rootContext}/automation/programs/${program?.externalId}/settings`, { openUpgradeSidePanel: true })

  return (
    <>
      {showProgramPicker && (
        <AutomatedProgramPickerModal
          isOpen
          isSingleSelect
          onClose={onProgramsPickerClose}
          onSubmitCustomSources={onProgramPickerSubmit}
          disableRowByCriteria={({ original }: Row<Program>) => disableAOCBasedPrograms(original)}
          disabledRowTooltip={disabledRowTooltip}
          preSelectedCustomSourceItems={{ ['Automated Programs']: program ? [program as ExtendedItemDto] : [] }}
          hasSuggestedPrograms
        />
      )}
      <Container className={`${rootClass}__container`}>
        <div className={`${rootClass}__title-container`}>
          <Typography text={t(`${rootText}Title`)} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} inline />
          <Typography text={t(`${rootText}SubTitle`)} type={TextType.BODY_TEXT_LIGHT} />
        </div>
        <ProgramSelected program={program} />
        <Button className={`${rootClass}__button-programs`} buttonType={ButtonType.SECONDARY} onClick={onClick}>
          <Svg name={SvgNames.plus} type={SvgType.ICON} />
          {!!program ? t(`${rootText}Button.Change`) : t(`${rootText}Button.Select`)}
        </Button>
      </Container>
      <div className={`${rootClass}__button-next-container`}>
        <Button buttonType={ButtonType.PRIMARY} onClick={onNext} disabled={!program}>
          {t('Next')}
        </Button>
      </div>
    </>
  )
}
