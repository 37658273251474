import React, { FC, useEffect, useState } from 'react'

import { useApolloClient, useQuery } from '@apollo/client'
import { Status } from '@components/StatusToast/StatusToast'
import updateCustomizeManagedAccountsData from '@graphql/mutations/updateCustomizeManagedAccountsData'
import getCustomizeManagedAccountsData from '@graphql/queries/getCustomizeManagedAccountsData'
import { UpdateCustomizeManagedAccountsDataMutation, UpdateCustomizeManagedAccountsDataMutationVariables } from '@graphql/types/mutation-types'
import { GetCustomizeManagedAccountsDataQuery, GetCustomizeManagedAccountsDataQueryVariables } from '@graphql/types/query-types'
import Customize from '@src/pages/MarketingNetwork/Customize/Customize'
import { DEFAULT_LOGO } from '@src/pages/MarketingNetwork/Customize/utils/Customize.utils'
import { logNewRelicError } from '@utils/new-relic.utils'

export interface CustomizeState {
  title: string
  logoPath: string
  alertsFromAddress: string
  isDefault: boolean
  customLogo?: string
}

export interface CustomizeToastState {
  message: string
  status?: Status
}

const defaultState: CustomizeState = {
  title: '',
  alertsFromAddress: '',
  logoPath: '',
  isDefault: true,
}

const defaultToastState: CustomizeToastState = {
  message: '',
}

const CustomizeContainer: FC = () => {
  const [state, setState] = useState<CustomizeState>(defaultState)
  const [toastState, setToastState] = useState<CustomizeToastState>(defaultToastState)
  const client = useApolloClient()

  const {
    data,
    loading: accountsDataLoading,
    error,
  } = useQuery<GetCustomizeManagedAccountsDataQuery, GetCustomizeManagedAccountsDataQueryVariables>(getCustomizeManagedAccountsData, {
    client,
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  })

  useEffect(() => {
    if (!accountsDataLoading && data) {
      setState({ ...data.getCustomizeManagedAccountsData })
    } else if (error) {
      logNewRelicError(error)
    }
  }, [data])

  const onSave = async (customTitle?: string, customLogo?: string, customAlertAddress?: string) => {
    const restoreDefaultLogo = customLogo !== undefined && customLogo === DEFAULT_LOGO

    const { data, errors } = await client.mutate<UpdateCustomizeManagedAccountsDataMutation, UpdateCustomizeManagedAccountsDataMutationVariables>({
      mutation: updateCustomizeManagedAccountsData,
      errorPolicy: 'all',
      variables: {
        data: {
          title: customTitle,
          imageBase64: customLogo?.split(',')[1],
          imageType: 'png',
          alertsFromAddress: customAlertAddress,
          restoreDefaultLogo,
        },
      },
      refetchQueries: ['getCustomizeManagedAccountsData'],
    })

    if (data?.updateCustomizeManagedAccountsData) {
      const { status, errorMsg } = data.updateCustomizeManagedAccountsData

      if (status === 'ok') {
        setStatusToast('Changes published successfully', Status.SUCCESS)
      } else if (errorMsg) {
        setStatusToast('An error occurred while publishing your changes, please try again', Status.FAIL)
      }
    } else if (errors) {
      logNewRelicError(errors)
    }
  }

  const setStatusToast = (message: string, status?: Status) => setToastState({ message, status })

  return (
    <Customize
      applicationName={state.title}
      logo={state.logoPath}
      loading={accountsDataLoading}
      isDefault={state.isDefault}
      pageError={error}
      alertAddress={state.alertsFromAddress}
      toastState={toastState}
      setStatusToast={setStatusToast}
      onSave={onSave}
    />
  )
}

export default CustomizeContainer
