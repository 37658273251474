import React, { FC, useEffect } from 'react'
import { FieldValues, UseFormRegister } from 'react-hook-form'

import { useApolloClient, useQuery } from '@apollo/client'
import Loader from '@components/Loader/Loader'
import PageError from '@components/PageError/PageError'
import Select from '@components/Select/Select'
import { useTranslation } from '@const/globals'
import crmReferenceEntity from '@graphql/queries/crmReferenceEntity'
import { CrmReferenceEntityQuery, CrmReferenceEntityQueryVariables, FieldDefinition, StringKeyValue } from '@graphql/types/query-types'
import { logNewRelicError } from '@utils/new-relic.utils'

interface Props {
  fieldDefinition: FieldDefinition
  register: UseFormRegister<FieldValues>
  handleUpdate(e: any): void
  defaultValue: string
  dataTest?: string
}

export const ReferenceSelect: FC<Props> = (props: Props) => {
  const { fieldDefinition, defaultValue, dataTest = 'reference-step', register, handleUpdate } = props
  const client = useApolloClient()

  const { t } = useTranslation()

  const {
    loading,
    error,
    data: referenceEntityData,
  } = useQuery<CrmReferenceEntityQuery, CrmReferenceEntityQueryVariables>(crmReferenceEntity, {
    client: client as any,
    variables: {
      type: fieldDefinition.referenceEntity || '',
    },
  })

  useEffect(() => {
    if (error) {
      logNewRelicError(error)
    }
  }, [error])

  const referenceEntities: StringKeyValue[] | undefined = referenceEntityData?.crmReferenceEntities

  const getPickListOptions = () =>
    referenceEntities?.reduce(
      (acc, cur) => {
        return [
          ...acc,
          <option key={cur.key} value={cur.key} selected={cur.key === defaultValue}>
            {`${cur.value}`}
          </option>,
        ]
      },
      [
        <option key="empty" value="">
          --- {t('Select Reference')} ---
        </option>,
      ]
    )

  return (
    <>
      {loading && <Loader center />}
      {error && <PageError />}
      <Select
        label={fieldDefinition.displayName}
        name={fieldDefinition.fieldName}
        dataTest={dataTest}
        register={register(fieldDefinition.fieldName, { onChange: handleUpdate })}
      >
        {referenceEntities && getPickListOptions()}
      </Select>
    </>
  )
}

export default ReferenceSelect
