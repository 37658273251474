import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Modal, { ModalBody, ModalHeader, ModalFooter } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import { TableV2 } from '@components/TableV2/TableV2'
import Typography, { ModalBodyStyle } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import { getAnalyticsVariableColumns, getAnalyticsVariableData, getAnalyticsVariableRowActions } from './AnalyticsVariables.constants'

import './AnalyticsVariablesModal.css'

interface AnalyticsVariablesModalProps {
  className?: string
  dataTest?: string
  onClose: () => void
  isOpen: boolean
}

const rootClass = 'analytics-variables-modal'

const AnalyticsVariablesModal: FC<AnalyticsVariablesModalProps> = (props: AnalyticsVariablesModalProps) => {
  const { dataTest = rootClass, className = '', onClose, isOpen } = props

  const { t } = useTranslation()

  const header = (
    <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__header`}>
      {t('EmailComposer.Settings.AnalyticsTracking.Variable.Title')}
    </ModalHeader>
  )

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
      <ModalBody className={`${rootClass}__body`}>
        <Typography text={t('EmailComposer.Settings.AnalyticsTracking.Variable.Description')} {...ModalBodyStyle} />
        <TableV2 columns={getAnalyticsVariableColumns(t)} data={getAnalyticsVariableData(t)} rowActions={getAnalyticsVariableRowActions(t)} />
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form} className={`${rootClass}__footer`}>
        <Button buttonType={ButtonType.TRANSPARENT} onClick={onClose} dataTest={`${dataTest}-button-tertiary`}>
          {t('Close')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default AnalyticsVariablesModal
