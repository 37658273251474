import { FC } from 'react'

import { TFunction } from 'i18next'

import { renderModalUtils } from '@src/components/ClassicModalHandler/ClassicModalHandlerUtils'

export enum ClassicModalType {
  AI_GENERATED_EMAIL = 'AI_GENERATED_EMAIL',
  LIST_PICKER = 'LIST_PICKER',
  MANAGE_CONTACTS_OR_START_MODAL = 'MANAGE_CONTACTS_OR_START_MODAL',
  OPT_IN_EMAIL = 'OPT_IN_EMAIL',
}

export interface ClassicModalHandlerProps {
  showModal: boolean
  message: any
  onClose: VoidFunction
  type: ClassicModalType
  t: TFunction
}

const ClassicModalHandler: FC<ClassicModalHandlerProps> = (props: ClassicModalHandlerProps) => renderModalUtils(props)

export default ClassicModalHandler
