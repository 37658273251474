import { gql } from 'apollo-boost'

export default gql`
  query getImportDetailsStatus($importDetailsStatus: Page_ImportDetailsStatusInput) {
    importDetailsStatus(importDetailsStatusPage: $importDetailsStatus) {
      pageNumber
      pageSize
      offSet
      count
      totalPages
      list {
        userId
        source
        sourceId
        typeMetaData
        status
        totalSubmitted
        totalAdded
        totalUpdated
        totalRejected
        totalUnSubmitted
        createdTime
        submittedTime
        closedTime
      }
    }
  }
`
