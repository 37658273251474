import { ListPageCommonState, SetFilter, Update } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { EmptyListingProps } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { FORMS_EMPTY_STATE_LINK, WEBINAR_EMPTY_STATE_LINK } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.constants'
import { goCreateForm, goCreateWebinar } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.utils'
import { ItemType } from '@utils/categorization'
import { allFormSubmissionsFilter, allWebinarSubmissionsFilter, createdByMeFilter, favoriteFilter, FilterDefinition } from '@utils/filter'
import { ellip } from '@utils/strings'

const getCommonProps = (filter: string, itemType: ItemType): Partial<EmptyListingProps> => {
  return {
    buttonText: `ListPage.FormsJoinView.${itemType}.Table.${filter}.EmptyListing.ButtonText`,
    buttonPlusIcon: itemType === ItemType.FORM_SUBMISSION,
    buttonOnClick: () => (itemType === ItemType.WEBINAR_SUBMISSION ? goCreateWebinar() : goCreateForm()),
    buttonLinkIcon: itemType === ItemType.WEBINAR_SUBMISSION,
  }
}
export const getCustomDefaultEmptyListingProps = (
  values: ListPageCommonState,
  setFilter: SetFilter,
  t: Function,
  itemType: ItemType
): EmptyListingProps | undefined => {
  const { activeTagId, activeFolderId, search, searchItemsResults } = values
  const allSubmissionsFilter = itemType === ItemType.WEBINAR_SUBMISSION ? allWebinarSubmissionsFilter : allFormSubmissionsFilter
  if (activeFolderId) {
    return {
      imgSrc: StaticImageNames.emptyFolder,
      headline: 'This folder is empty!',
      text: `ListPage.FormsJoinView.${itemType}.Table.Folder.EmptyListing.Text`,
      linkText: `ListPage.FormsJoinView.${itemType}.Table.Folder.EmptyListing.LinkText`,
      onLinkClick: () => setFilter(allSubmissionsFilter, false),
      ...getCommonProps('Folder', itemType),
    }
  } else if (search && searchItemsResults && searchItemsResults.length === 0) {
    return {
      imgSrc: StaticImageNames.emptySearch,
      headline: `${t(`ListPage.FormsJoinView.${itemType}.Table.Search.EmptyListing.Headline`)} “${ellip(search ?? '', 25)}”`,
      text: `ListPage.FormsJoinView.${itemType}.Table.Search.EmptyListing.Text`,
      linkText: `ListPage.FormsJoinView.${itemType}.Table.Search.EmptyListing.LinkText`,
      ...getCommonProps('Search', itemType),
      onLinkClick: () => setFilter(allSubmissionsFilter, false),
    }
  } else if (activeTagId) {
    return {
      imgSrc: StaticImageNames.emptyTag,
      headline: `ListPage.FormsJoinView.${itemType}.Table.Tag.EmptyListing.Headline`,
      text: `ListPage.FormsJoinView.${itemType}.Table.Tag.EmptyListing.Text`,
      buttonText: `ListPage.FormsJoinView.${itemType}.Table.Tag.EmptyListing.ButtonText`,
      buttonOnClick: () => setFilter(allSubmissionsFilter, false),
      linkText: `ListPage.FormsJoinView.${itemType}.Table.Tag.EmptyListing.LinkText`,
      link: 'https://connect.act-on.com/hc/en-us/articles/4411645246871-Getting-Started-with-Tags',
      hideIcon: false,
    }
  }
}
export const getCustomEmptyListingProps = (
  setFilter: (filterDefinition: FilterDefinition, customFilterSelected: boolean) => void,
  _update: Update,
  itemType: ItemType,
  filter?: FilterDefinition
): EmptyListingProps => {
  const isWebinarSubmissions = itemType === ItemType.WEBINAR_SUBMISSION
  if (filter === favoriteFilter) {
    return {
      imgSrc: StaticImageNames.emptyFavorites,
      headline: `ListPage.FormsJoinView.${itemType}.Table.Favorites.EmptyListing.Headline`,
      text: `ListPage.FormsJoinView.${itemType}.Table.Favorites.EmptyListing.Text`,
      linkText: `ListPage.FormsJoinView.${itemType}.Table.Favorites.EmptyListing.LinkText`,
      onLinkClick: () => setFilter(isWebinarSubmissions ? allWebinarSubmissionsFilter : allFormSubmissionsFilter, false),
      ...getCommonProps('Favorites', itemType),
    }
  } else if (filter === createdByMeFilter) {
    return {
      ...getCommonProps('CreatedByMe', itemType),
      imgSrc: StaticImageNames.emptyCreatedByMe,
      headline: `ListPage.FormsJoinView.${itemType}.Table.CreatedByMe.EmptyListing.Headline`,
      text: `ListPage.FormsJoinView.${itemType}.Table.CreatedByMe.EmptyListing.Text`,
      linkText: `ListPage.FormsJoinView.${itemType}.Table.CreatedByMe.EmptyListing.LinkText`,
      link: isWebinarSubmissions ? WEBINAR_EMPTY_STATE_LINK : FORMS_EMPTY_STATE_LINK,
    }
  } else {
    return {
      ...getCommonProps('AllSubmissions', itemType),
      imgSrc: isWebinarSubmissions ? StaticImageNames.emptyWebinarSubmissions : StaticImageNames.emptyFormSubmissions,
      headline: `ListPage.FormsJoinView.${itemType}.Table.AllSubmissions.EmptyListing.Headline`,
      text: `ListPage.FormsJoinView.${itemType}.Table.AllSubmissions.EmptyListing.Text`,
      linkText: `ListPage.FormsJoinView.${itemType}.Table.AllSubmissions.EmptyListing.LinkText`,
      link: isWebinarSubmissions ? WEBINAR_EMPTY_STATE_LINK : FORMS_EMPTY_STATE_LINK,
    }
  }
}
