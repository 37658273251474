import importStatus, { Status as ImportStatus } from '@graphql/microservices/list/importStatus'
import standardFields from '@graphql/microservices/list/standardFields'
import {
  GetImportStatusQuery,
  GetImportStatusQueryVariables,
  StandardFieldsQuery,
  StandardFieldsQueryVariables,
} from '@graphql/types/microservice/list-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export type GetInProgressImportsRequestType = () => FetchPromise<GetImportStatusQuery>
export type GetStandardFieldsRequestType = () => FetchPromise<StandardFieldsQuery>

export interface ImportContactsListRequests {
  getInProgressImportsRequest: GetInProgressImportsRequestType
  getStandardFieldsRequest: GetStandardFieldsRequestType
}

export const useImportContactsListRequests = (): ImportContactsListRequests => {
  const { client: listClient } = useMicroserviceClient({ serviceName: MicroserviceClients.LIST })

  const getInProgressImportsRequest = async () =>
    await listClient.query<GetImportStatusQuery, GetImportStatusQueryVariables>({
      query: importStatus,
      variables: {
        status: {
          pageNumber: 1,
          pageSize: -1,
          count: 0,
          filterBy: {
            source: ['ACTON_LIST', 'CSV', 'FTP'],
            status: [ImportStatus.CREATED],
          },
        },
      },
    })

  const getStandardFieldsRequest = async () =>
    await listClient.query<StandardFieldsQuery, StandardFieldsQueryVariables>({
      query: standardFields,
      fetchPolicy: 'network-only',
    })

  return {
    getInProgressImportsRequest,
    getStandardFieldsRequest,
  }
}
