import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { ButtonWeight } from '@components/Button/Button'
import ClickToCopy from '@components/ClickToCopy/ClickToCopy'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { useLandingPageContext } from '@src/pages/Content/LandingPages/LandingPagesManager/context/LandingPagesManager.context'
import useSeoUrl from '@src/pages/Content/LandingPages/LandingPagesManager/hooks/useSeoUrl'
import { toTrainCase } from '@utils/strings'

import './LandingPageURLGroup.css'

interface LandingPageURlGroupProps {
  name: string
  publicUrl: string
  onSeoURLClick: () => void
  onShortURLClick: () => void
  onEdit?: () => void
  onDelete?: () => void
  seoFriendlyUrl?: string
  shortUrl?: string
  isDefault?: boolean
}

const rootClass = 'landing-page-url-group'

const LandingPageURLGroup: FC<LandingPageURlGroupProps> = ({
  name,
  publicUrl,
  onSeoURLClick,
  onShortURLClick,
  seoFriendlyUrl,
  shortUrl,
}: LandingPageURlGroupProps) => {
  const { t } = useTranslation()
  const {
    landingPage: { hasUrlShortener },
  } = useLandingPageContext()
  const { prettyURLPrefix, seoUrl } = useSeoUrl(seoFriendlyUrl || '')
  const dataTest = `${rootClass}-${toTrainCase(name)}`

  return (
    <div className={rootClass} data-test={dataTest}>
      <div className={`${rootClass}__block`}>
        <Typography text={t('Public URL')} type={TextType.BODY_TEXT_LIGHT} />
        <ClickToCopy
          value={publicUrl}
          copyText="Copy URL"
          className={`${rootClass}__block-public-url`}
          text={
            <a className="ellip" href={publicUrl} target="_blank" rel="noreferrer">
              <Typography className="ellip" text={publicUrl} type={TextType.LINK} />
            </a>
          }
        />
      </div>
      <div className={`${rootClass}__block`}>
        <div
          className={classNames(`${rootClass}__block-title`, {
            [`${rootClass}__block-title-center`]: !seoFriendlyUrl,
          })}
        >
          <Typography text={t('SEO-Friendly URL')} type={TextType.BODY_TEXT_LIGHT} />
          <Button
            buttonType={ButtonType.TEXT_TEAL}
            weight={ButtonWeight.MEDIUM}
            onClick={onSeoURLClick}
            className={`${rootClass}__block-button`}
            dataTest={`${dataTest}-button-seo`}
          >
            {t(seoFriendlyUrl ? 'Edit' : 'Setup')}
          </Button>
        </div>
        {seoFriendlyUrl && (
          <ClickToCopy
            value={seoFriendlyUrl}
            copyText="Copy URL"
            className={`${rootClass}__block-seo-friendly-url`}
            text={
              <a className="ellip" href={seoFriendlyUrl} target="_blank" rel="noreferrer">
                <Typography text={prettyURLPrefix} inline type={TextType.LINK} />
                <Typography text={seoUrl} inline weight={TextWeight.BOLD} type={TextType.LINK} />
              </a>
            }
            dataTest={`${dataTest}-copy-seo`}
          />
        )}
      </div>
      {(hasUrlShortener || shortUrl) && (
        <div className={`${rootClass}__block`}>
          <div
            className={classNames(`${rootClass}__block-title`, {
              [`${rootClass}__block-title-center`]: !shortUrl,
            })}
          >
            <Typography text={t('Short URL')} type={TextType.BODY_TEXT_LIGHT} />
            <Button
              buttonType={ButtonType.TEXT_TEAL}
              onClick={onShortURLClick}
              weight={ButtonWeight.MEDIUM}
              className={`${rootClass}__block-button`}
              dataTest={`${dataTest}-button-short`}
            >
              {t(shortUrl ? 'Remove' : 'Setup')}
            </Button>
          </div>
          {shortUrl && (
            <ClickToCopy
              value={shortUrl}
              copyText="Copy URL"
              className={`${rootClass}__block-short-url`}
              text={
                <a className="ellip" href={shortUrl} target="_blank" rel="noreferrer">
                  <Typography text={shortUrl} type={TextType.LINK} />
                </a>
              }
              dataTest={`${dataTest}-copy-short`}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default LandingPageURLGroup
