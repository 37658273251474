import Cookies from 'js-cookie'
import { combineReducers } from 'redux'

import { AccountSettings } from '@graphql/types/query-types'
import { Action } from '@interface/Action'
import { Results } from '@interface/Results'
import { UserSettings } from '@utils/hooks/useUserSettings'
import { NavigationInterface } from '@utils/navigation/navigation.utils'
import { makeFetchActionTypes } from '@utils/store/fetch'

import { LOG_OUT, NAMESPACE } from './account.utils'
import { LoadAccountActionType, SetThemeAction } from './actions'
import actionTypes from './actionTypes'
import './sagas'
import { getTheme, THEME } from '../theme'

export const logOutActionTypes = makeFetchActionTypes(`${NAMESPACE}/${LOG_OUT}`)

export interface AccountState {
  accountSettings?: AccountSettings
  isAuthorized?: boolean
  loading?: boolean
  results?: Results
  language?: string
  dynamicNav?: NavigationInterface[]
  theme: THEME
  loggedOut?: boolean
  pendingIframeMessage?: any
  token?: string
  accountContext?: string
  microserviceUrls?: { key: string }
  userSettings?: UserSettings
}

export const updateNewRelicLoggingCookies = (action: Action<LoadAccountActionType>) => {
  const accountId = action.payload?.accountSettings?.accountId
  const userId = action.payload?.accountSettings?.userId
  accountId && Cookies.set('lastAccountId', accountId)
  userId && Cookies.set('lastUserId', userId)
}

export function account(
  state = {
    accountSettings: undefined,
    isAuthorized: undefined,
    loading: false,
    results: undefined,
    dynamicNav: null,
    loggedOut: undefined,
    language: undefined,
    userSettings: undefined,
  },
  action: Action<LoadAccountActionType>
) {
  switch (action.type) {
    case actionTypes.loadAccountSettings:
      return {
        ...state,
        loading: action.payload?.loading ?? true,
        results: null,
        loggedOut: undefined,
        isAuthorized: undefined,
      }
    case actionTypes.loadAccountSettingsReceived:
      updateNewRelicLoggingCookies(action)
      const jslang = Cookies.getJSON('jslang')
      const language = jslang ? jslang : 'en'
      return {
        ...state,
        accountSettings: action.payload?.accountSettings,
        isAuthorized: action.payload?.isAuthorized ?? false,
        loading: false,
        dynamicNav: action.payload?.dynamicNav,
        token: action.payload?.token,
        aoAccountContext: action.payload?.accountContext,
        microserviceUrls: action.payload?.microserviceUrls,
        userSettings: action.payload?.userSettings,
        language,
      }
    case actionTypes.loadAccountSettingsFailed:
      return {
        ...state,
        loading: false,
        isAuthorized: false,
        results: { error: action.payload?.error },
      }
    case actionTypes.updateUserAuthentication:
      return {
        ...state,
        isAuthorized: action.payload?.isAuthorized ?? false,
        loading: false,
        token: action.payload?.token,
        aoAccountContext: action.payload?.accountContext,
      }
    case actionTypes.setLanguage: {
      if (action.payload) {
        // Set in case user settings service fails
        // Also makes classic pages immediately up-to-date on page load
        Cookies.set('l6e', String(action.payload))
        Cookies.set('jslang', String(action.payload))
        localStorage.setItem('i18nextLng', String(action.payload))
      }
      return {
        ...state,
        language: action.payload,
      }
    }
    case actionTypes.setUserSettings: {
      return {
        ...state,
        userSettings: action.payload,
      }
    }
    case logOutActionTypes.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case logOutActionTypes.RECEIVE:
      return {
        ...state,
        isAuthorized: false,
        accountSettings: undefined,
        results: undefined,
        loading: false,
        loggedOut: action.payload?.loggedOut,
      }
    case logOutActionTypes.FAIL:
      return {
        ...state,
        loading: false,
        loggedOut: false,
      }
    case actionTypes.setPendingIFrameRequest:
      return {
        ...state,
        pendingIframeMessage: action.payload,
      }
    default:
      return state
  }
}

export function theme(
  state = {
    theme: getTheme(),
  },
  action: SetThemeAction
) {
  switch (action.type) {
    case actionTypes.setTheme: {
      return {
        ...state,
        theme: action.payload,
      }
    }
    default:
      return state
  }
}

export default combineReducers({
  account,
  theme,
})
