import { gql } from '@apollo/client'

export default gql`
  query getManagersAndChildAccounts {
    getManagersAndChildAccounts {
      managerList {
        id
        firstName
        lastName
        login
        email
        remoteDomain
        isAdministrator
        assignedAccounts
        assignmentCount
      }
      childAccountList {
        accountId
        name
      }
    }
  }
`
