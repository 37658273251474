import React, { FC, useCallback } from 'react'

interface Props {
  url: string
  onStatusChange: (isValid: boolean) => void
  dataTest?: string
}

const ImageURLChecker: FC<Props> = ({ url, onStatusChange, dataTest }) => {
  const handleStatusChange = useCallback((isValid: boolean) => () => onStatusChange(isValid), [onStatusChange])

  return (
    <img
      data-test={dataTest || 'image-url-checker'}
      src={url}
      onError={handleStatusChange(false)}
      onLoad={handleStatusChange(true)}
      alt={url}
      style={{ display: 'none' }}
    />
  )
}

export default ImageURLChecker
