import { gql } from '@apollo/client'

export default gql`
  query getLastEmailContentEditSession($emailContentId: String) {
    getLastEmailContentEditSession(emailContentId: $emailContentId) {
      accountId
      createdAt
      emailContentId
      id
      sessionId
      sessionType
      updatedAt
    }
  }
`
