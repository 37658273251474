import React, { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Input from '@components/Input/Input'
import SingleSelectDropdown from '@components/SingleSelectDropdown/SingleSelectDropdown'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { SelectOption } from '@interface/Select'

import './InputToSelectMappingRow.css'

import { StandardFieldNameSelectOption } from '@utils/standardFieldNames.utils'

export interface InputToSelectMappingRowProps {
  rowId: string
  inputValue: string
  inputError?: string
  inputFocus?: boolean
  selectValue: string
  selectOptions: StandardFieldNameSelectOption[]
  onChangeInput: (rowId: string, value: string) => void
  onChangeSelect: (rowId: string, value: string) => void
  deleteRow: (rowId: string) => void
  moveUp: (rowId: string) => void
  moveDown: (rowId: string) => void
  canMoveUp: boolean
  canMoveDown: boolean
  className?: string
  dataTest?: string
}

const rootClass = 'input-to-select-mapping-row'

const InputToSelectMappingRow: FC<InputToSelectMappingRowProps> = (props: InputToSelectMappingRowProps) => {
  const {
    rowId,
    inputValue,
    inputError,
    inputFocus,
    selectValue,
    selectOptions,
    onChangeInput,
    onChangeSelect,
    deleteRow,
    moveUp,
    moveDown,
    canMoveDown,
    canMoveUp,
    dataTest = rootClass,
    className = '',
  } = props
  const { t } = useTranslation()
  const [options, setOptions] = useState<SelectOption[]>([])

  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    setOptions(selectOptions.filter((option) => !option.selected).map(({ name, mapping }) => ({ value: mapping, label: name })))
  }, [selectOptions])

  useEffect(() => {
    if (inputFocus && inputRef.current) {
      inputRef.current?.focus()
    }
  }, [inputRef.current, inputFocus])

  const selectValueChange = useCallback((value?: string) => onChangeSelect(rowId, value ?? ''), [rowId, onChangeSelect])

  return (
    <div key={rowId} className={classNames(rootClass, className)} data-test={`${dataTest}-${rowId}`}>
      <div className={`${rootClass}__inputs`}>
        <div>
          <Input
            value={inputValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeInput(rowId, e.target.value)}
            ref={inputRef}
            index={0}
            error={!!inputError}
            className={`${rootClass}__input`}
            dataTest={`${dataTest}-input-value`}
          />
          {inputError && <Typography text={inputError} type={TextType.ERROR} dataTest={`${dataTest}-input-error`} />}
        </div>

        <Svg name={SvgNames.arrowRight} type={SvgType.LARGER_ICON} className={`${rootClass}__seperator`} />
        <SingleSelectDropdown options={options} onSubmit={selectValueChange} value={selectValue} index={0} className={`${rootClass}__select`} />
      </div>
      <div className={`${rootClass}__actions`}>
        <Tooltip
          trigger={
            <Button
              buttonType={ButtonType.TABLE_ACTION}
              disabled={!canMoveUp}
              onClick={() => moveUp(rowId)}
              className={classNames(`${rootClass}__button`, { [`${rootClass}__button-disabled`]: !canMoveUp })}
              dataTest={`${dataTest}-move-up`}
            >
              <Svg
                name={SvgNames.arrowUpPlain}
                type={SvgType.MEDIUM}
                className={classNames({
                  [`${rootClass}__svg-disabled`]: !canMoveUp,
                })}
              />
            </Button>
          }
        >
          {t(canMoveUp ? 'Move Up' : `Can't Move Up`)}
        </Tooltip>
        <Tooltip
          trigger={
            <Button
              buttonType={ButtonType.TABLE_ACTION}
              disabled={!canMoveDown}
              onClick={() => moveDown(rowId)}
              className={classNames(`${rootClass}__button`, { [`${rootClass}__button-disabled`]: !canMoveDown })}
              dataTest={`${dataTest}-move-down`}
            >
              <Svg
                name={SvgNames.arrowDownPlain}
                type={SvgType.MEDIUM}
                className={classNames({
                  [`${rootClass}__svg-disabled`]: !canMoveDown,
                })}
              />
            </Button>
          }
        >
          {t(canMoveDown ? 'Move Down' : `Can't Move Down`)}
        </Tooltip>
        <Tooltip
          trigger={
            <Button
              buttonType={ButtonType.TABLE_ACTION}
              onClick={() => deleteRow(rowId)}
              className={classNames(`${rootClass}__button`, `${rootClass}__delete`)}
              dataTest={`${dataTest}-delete`}
            >
              <Svg name={SvgNames.delete} type={SvgType.MEDIUM} />
            </Button>
          }
        >
          {t('Delete')}
        </Tooltip>
      </div>
    </div>
  )
}

export default InputToSelectMappingRow
