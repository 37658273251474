import React, { FC, MouseEvent, useRef } from 'react'

import classNames from 'classnames'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import TagWithName, { MARGIN, TagWithNameProps } from '@components/TagWithName/TagWithName'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'

import './RemovableTag.css'

import Button, { ButtonType } from '@components/Button/Button'

interface Props extends TagWithNameProps {
  onRemove: (tag: string) => void
}

const rootClass = 'removable-tag'

const RemovableTag: FC<Props> = (props: Props) => {
  const { onRemove, dataTest = rootClass, className = '', name } = props
  const { t } = useTranslation()
  const tagNameRef = useRef<HTMLDivElement>(null)

  const handleMouseEnter = () => {
    if (tagNameRef.current && tagNameRef.current.style.marginLeft != `${MARGIN}px`) {
      tagNameRef.current.style.marginLeft = `${parseInt(tagNameRef.current.style.marginLeft) - 11}px`
      tagNameRef.current.style.paddingRight = `3px`
    }
  }

  const handleRemoveTag = (e: MouseEvent<HTMLButtonElement>, name: string) => {
    onRemove(name)
    e.stopPropagation()
  }

  return (
    <TagWithName {...props} className={classNames(rootClass, className)} dataTest={dataTest} tagNameRef={tagNameRef} onMouseEnter={handleMouseEnter}>
      <Tooltip
        position={'top'}
        trigger={
          <Button className={`${rootClass}__remove-button`} buttonType={ButtonType.ICON} onClick={(e) => handleRemoveTag(e, name)}>
            <Svg className={`${rootClass}__remove-icon`} name={SvgNames.close} type={SvgType.TINY_ICON} />
          </Button>
        }
        triggerClassName={`${rootClass}__remove`}
      >
        {t('Remove tag')}
      </Tooltip>
    </TagWithName>
  )
}

export default RemovableTag
