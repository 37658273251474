import React, { ComponentProps } from 'react'

import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { buildHeader } from '@utils/folderUtils'
import { escapeRegExp, replaceAll } from '@utils/strings'

import Breadcrumbs, { BreadcrumbsItemType } from '../Breadcrumbs/Breadcrumbs'
import { FolderData } from '../SortableFolders/components/Folder/Folder'
import Tooltip from '../Tooltip/Tooltip'

export const renderBoldTextOnMatch = (
  textValue: string,
  search: string,
  hideTooltip?: boolean,
  overridingProps?: Partial<ComponentProps<typeof Typography>>
) => {
  if (!search) {
    // Not returning in this case can cause the browser to freeze on the regex
    return textValue
  }
  const value = textValue ?? ''
  const matcher = new RegExp(replaceAll(escapeRegExp(search.trim()), ' +', '|'), 'ig')

  let text = ''
  let currentIndex = 0
  let match
  while ((match = matcher.exec(value))) {
    const boldText = match[0]
    text = text.concat(value.slice(currentIndex, match.index), `<bold>${boldText}</bold>`)
    currentIndex = match.index + boldText.length
  }

  text = text.concat(value.slice(currentIndex))

  return (
    <Typography
      className="ellip"
      title={hideTooltip ? '' : value}
      text={text}
      tagProps={{ bold: { weight: TextWeight.BOLD } }}
      inline
      {...overridingProps}
    />
  )
}

export const renderLocationBreadcrumbs = (currentFolderId: number, folders: FolderData[], defaultText: string) => {
  const folderPath = buildHeader(currentFolderId, folders)
  const tooltipBreadcrumbs: BreadcrumbsItemType[] = (folderPath.length ? folderPath : [defaultText]).map((value, idx, items) => ({
    text: value,
    hasTooltip: false,
    customTypography: { type: TextType.BODY_TEXT_WHITE, weight: items.length === idx + 1 ? TextWeight.MEDIUM : undefined },
  }))

  let folderBreadcrumbs: BreadcrumbsItemType[] = [...tooltipBreadcrumbs].map((item) => ({
    ...item,
    customTypography: { ...item.customTypography, type: undefined },
  }))

  if (!folderBreadcrumbs.length) {
    folderBreadcrumbs.push({ text: defaultText, hasTooltip: false, customTypography: { weight: TextWeight.MEDIUM } })
  }

  const MAX_PATH_DEPTH = 3
  const MAX_CHAR_LENGTH = 45

  if (folderBreadcrumbs.length >= MAX_PATH_DEPTH) {
    folderBreadcrumbs = folderBreadcrumbs.splice(folderBreadcrumbs.length - MAX_PATH_DEPTH)
    folderBreadcrumbs[1] = { text: '...', hasTooltip: false }
  }

  const lastIndex = folderBreadcrumbs.length - 1
  if (folderBreadcrumbs.length >= 2 && folderBreadcrumbs[lastIndex].text.length + folderBreadcrumbs[lastIndex - 1].text.length > MAX_CHAR_LENGTH) {
    folderBreadcrumbs[lastIndex - 1] = { text: '...', hasTooltip: false }
  }

  return (
    <Tooltip trigger={<Breadcrumbs breadcrumbs={folderBreadcrumbs} />} triggerClassName={'location-breadcrumbs'}>
      <Breadcrumbs breadcrumbs={tooltipBreadcrumbs} />
    </Tooltip>
  )
}
