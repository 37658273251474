import React, { FC, useContext, useRef } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import classNames from 'classnames'

import ListingPageHeader from '@complex/ListingPage/Components/ListingPageHeader/ListingPageHeader'
import ListingPageModals from '@complex/ListingPage/Components/ListingPageModals/ListingPageModals'
import ListPageTableContainer from '@complex/ListingPage/Components/ListingPageTable/ListPageTableContainer'
import SidebarContainer from '@complex/ListingPage/Components/Sidebar/SidebarContainer'
import { ListingPageCommonContext, TABLEV2_ENABLED_LISTINGS } from '@complex/ListingPage/Context/ListingPageCommon.context'
import DragLayer from '@components/DragLayer/DragLayer'
import ListPageScrollArea from '@components/ListPageScrollArea/ListPageScrollArea'
import PageContainer from '@components/PageContainer/PageContainer'
import { checkUrlForTableV2 } from '@components/TableV2/utils/tableV2Utils'
import withLoadingAndError from '@hoc/withLoadingAndError/withLoadingAndError'
import { ItemType } from '@utils/categorization'

import './ListingPage.css'

interface ListingPageProps {
  className?: string
}

const rootClass = 'listing-page'

const ListingPage: FC<ListingPageProps> = (props: ListingPageProps) => {
  const { className = '' } = props
  const {
    values: {
      itemType,
      dataTest,
      listingPageProps: { renderDataCards, hasTabs },
    },
  } = useContext(ListingPageCommonContext)

  const menuRefV2 = useRef<HTMLDivElement>(null)

  const pageHeader = () => (
    <>
      <ListingPageModals />
      <ListingPageHeader />
      {renderDataCards?.()}
    </>
  )

  const pageContent = () => (
    <>
      {hasTabs && pageHeader()}
      {TABLEV2_ENABLED_LISTINGS.includes(itemType as ItemType) || checkUrlForTableV2() ? (
        <div className={classNames(`${rootClass}__content`, { [`${rootClass}__content-tableV2`]: hasTabs })}>
          {!hasTabs && pageHeader()}
          <div className={`${rootClass}__main`} data-test={dataTest}>
            <SidebarContainer menuReference={menuRefV2} />
            <ListPageTableContainer className={`${rootClass}__table`} />
          </div>
        </div>
      ) : (
        <ListPageScrollArea>
          {(tableRef, menuRef, scrollAreaRef) => (
            <div className={`${rootClass}__content`}>
              {!hasTabs && pageHeader()}
              <div className={`${rootClass}__main`} data-test={dataTest}>
                <SidebarContainer menuReference={menuRef} outerScrollAreaRef={scrollAreaRef} />
                <ListPageTableContainer reference={tableRef} scrollableElement={scrollAreaRef} className={`${rootClass}__table`} />
              </div>
            </div>
          )}
        </ListPageScrollArea>
      )}
    </>
  )

  return (
    <DndProvider backend={HTML5Backend}>
      <DragLayer itemType={`${itemType}` as ItemType} />
      <div className={classNames(rootClass, className, { [`${rootClass}__has-tabs`]: hasTabs })}>
        {hasTabs ? (
          pageContent()
        ) : (
          <PageContainer className={classNames(rootClass, className)} dataTest={`${dataTest}-container`} noPadding>
            {pageContent()}
          </PageContainer>
        )}
      </div>
    </DndProvider>
  )
}

export default withLoadingAndError(ListingPage)
