import { gql } from '@apollo/client'

export default gql`
  query getCommentsPollPage(
    $accountId: String!
    $messageId: String!
    $blockName: String!
    $recId: String
    $srcId: String
    $timezone: String!
    $pageNumber: Int
    $pageSize: Int
  ) {
    getCommentsPollPage(
      accountId: $accountId
      messageId: $messageId
      blockName: $blockName
      recId: $recId
      srcId: $srcId
      timezone: $timezone
      pageNumber: $pageNumber
      pageSize: $pageSize
    ) {
      totalResponse
      pollUserChoiceResponses {
        indexOfChoices
        nameOfChoices
        comment
        time
        contactName
      }
    }
  }
`
