import { gql } from '@apollo/client'

export default gql`
  mutation addListContacts($srcId: String!, $addContactsInput: [AddContactsInput!]!) {
    addListContacts(srcId: $srcId, addContactsInput: $addContactsInput) {
      count
      contacts {
        id
        name
        email
      }
    }
  }
`
