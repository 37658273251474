import React, { FC } from 'react'
import { useDragDropManager, useDragLayer } from 'react-dnd'

import classNames from 'classnames'

import { Item } from '@components/ActionableNestedTable/components/NestedTableRowWithDnD/NestedTableRowWithDnD'
import { Column } from '@components/ColumnsOrderModal/components/DraggableColumn/DraggableColumn'
import DragPreview from '@components/DragLayer/components/DragPreview/DragPreview'
import { ItemType } from '@utils/categorization'

import './DragLayer.css'

interface Props {
  className?: string
  dataTest?: string
  disabled?: (type: ItemType, targets: ItemType[]) => boolean
  itemType?: ItemType
}

const rootClass = 'drag-layer'

const getItemStyles = (currentOffset: any) => {
  if (!currentOffset) {
    return {
      display: 'none',
    }
  }
  const { x, y } = currentOffset
  return {
    transform: `translate(${x}px, ${y}px)`,
  }
}

const toTitleCase = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

const itemsPreview = (type: string, data: Column | Item): { text: string; values?: any } => {
  const titleCasedType = toTitleCase(type)
  if (type === ItemType.TABLE_COLUMN || type === ItemType.POLL_CHOICE) {
    const { name } = data as Column
    return { text: name }
  } else {
    const { rows } = data as Item
    // When dragging on already selected row useDragLayer duplicates that row in 'item'.
    // So counting unique rows to have right value
    const uniqueRowIds = new Set<string>()
    rows?.forEach(({ id }) => uniqueRowIds.add(id))
    return {
      text: `DragLayer.Preview.${titleCasedType}s`,
      values: Array.isArray(rows) ? { ...(rows.length > 0 ? rows[0] : {}), count: uniqueRowIds.size } : {},
    }
  }
}

const DragLayer: FC<Props> = (props: Props) => {
  const { itemType, dataTest = rootClass, className = '', disabled = false } = props

  const {
    itemType: dragLayerItemType,
    isDragging,
    item,
    currentOffset,
  } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: itemType ?? monitor.getItemType(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  const manager = useDragDropManager()

  const getTargetTypes = (targets: (string | symbol)[]): ItemType[] =>
    targets.reduce((targetTypes: ItemType[], currentTarget) => {
      const currentTargetType = manager.getRegistry().getTargetType(currentTarget) as ItemType[]
      return [...targetTypes, ...currentTargetType]
    }, [])

  const isDisabled = () => {
    const monitor = manager.getMonitor()
    const targets = monitor.getTargetIds()
    if (targets.length > 0) {
      const targetTypes = getTargetTypes(targets)
      const canDrop = targets.every((target) => monitor.canDropOnTarget(target))
      const disabledPreview = disabled && disabled(itemType ?? (dragLayerItemType as ItemType), targetTypes)
      return disabledPreview || !canDrop
    }
    return true
  }

  const renderItem = (type: ItemType, item: Column | Item) => {
    const preview = itemsPreview(type, item)
    if (preview) {
      const { text, values } = preview
      return <DragPreview disabled={isDisabled()} text={text} values={values} />
    }
  }

  if (!isDragging) {
    return null
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div style={getItemStyles(currentOffset)}>{renderItem(itemType ?? (dragLayerItemType as ItemType), item)}</div>
    </div>
  )
}

export default DragLayer
