import { gql } from '@apollo/client'

export default gql`
  mutation regenerateSplits($srcId: String, $sendToCategorizationService: Boolean) {
    regenerateSplits(srcId: $srcId, sendToCategorizationService: $sendToCategorizationService) {
      body
      message
      objectId
      statusCode
    }
  }
`
