// eslint-disable-next-line no-restricted-imports
import { format, formatDistanceStrict, formatDistanceToNow, hoursToMilliseconds } from 'date-fns'
import isToday from 'date-fns/isToday'
import { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'

import { TimePickerV2Format } from '@components/TimePickerV2/TimePickerV2'

/**
 * @deprecated The methods in this file are deprecated and should no longer be used.
 * Please use the methods in dateUtils.ts instead
 */

dayjs.extend(timezone)

/** @deprecated */
export function formatDateWithAbbreviations(time: number, includeTime = false, timePrefix?: string): string {
  const date = new Date(time)
  if (isToday(date)) {
    return format(date, 'h:mm aaa')
  } else if (includeTime) {
    return format(date, timePrefix ? `MMM d, yyyy '${timePrefix}' h:mm aaa` : `MMM d, yyyy h:mm aaa`)
  } else {
    return format(date, 'MMM d, yyyy')
  }
}

/** @deprecated */
export function formatSendTSDate(time: number, options?: { dateFormat?: string; hourFormat?: string; includeTime?: boolean }) {
  const { dateFormat = 'MMMM d, yyyy', hourFormat = 'h:mm aaa', includeTime = false } = { ...options }
  const date = new Date(time)
  if (includeTime) {
    return `${format(date, dateFormat)} • ${format(date, hourFormat)}`
  } else {
    return format(date, dateFormat)
  }
}

/** @deprecated */
export function convertHoursToMilliseconds(hours: number) {
  return hoursToMilliseconds(hours)
}

/** @deprecated */
export const getStandardFormattedDate = (epochTime: number | undefined, withTime?: boolean, timeZone?: string) => {
  const d = new Date(epochTime ?? 0)
  if (!epochTime || d === undefined || isNaN(Number(d))) {
    return 'undefined'
  }
  const date = new Intl.DateTimeFormat('en', { day: 'numeric', month: 'numeric', year: 'numeric' }).format(d)
  const timeZoneOpts: any = timeZone ? { timeZone, timeZoneName: 'short' } : {}
  const time = withTime ? ` ${new Intl.DateTimeFormat('en', { hour: 'numeric', minute: 'numeric', hour12: true, ...timeZoneOpts }).format(d)}` : ''

  return epochTime ? `${date}${time}` : 'undefined'
}

/** @deprecated */
export const getDayOfWeekDate = (epochTime: number, withTime?: boolean) => {
  const d = new Date(epochTime)
  const dayOfWeek = new Intl.DateTimeFormat('en', { weekday: 'short' }).format(d)
  const date = new Intl.DateTimeFormat('en', { day: 'numeric', month: 'short' }).format(d)
  const time = withTime ? new Intl.DateTimeFormat('en', { hour: 'numeric', minute: 'numeric', hour12: true }).format(d) : ''
  return `${dayOfWeek} • ${date}${withTime ? ' • ' : ''}${time.toLowerCase()}`
}

/** @deprecated */
export const isWeekend = (checkDate: Date) => {
  const dayOfWeek = checkDate.getDay()
  return dayOfWeek === 0 || dayOfWeek === 6
}

/** @deprecated */
export const getTimeParts = (hourStr: string, minuteStr: string) => {
  const hourNum = parseInt(hourStr)
  const ampm = hourNum < 12 ? 0 : 1
  const hour = hourNum > 12 ? hourNum - 12 : hourNum === 0 ? 12 : hourNum
  const minute = parseInt(minuteStr)
  return {
    ampm,
    hour,
    minute,
  }
}

/** @deprecated */
export interface DateFormats {
  format: string
  name: string
}

/** @deprecated */
export const dateYesterdayMidnight = new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0, 0, 0, 0))

/** @deprecated */
export const dateTodayMidnight = new Date(new Date(new Date().setDate(new Date().getDate())).setHours(0, 0, 0, 0))

/** @deprecated */
export const startTime = () => {
  const yesterdaysDate = new Date(dateYesterdayMidnight.getFullYear(), dateYesterdayMidnight.getMonth() - 1, 1)
  const month = String(yesterdaysDate.getMonth()).padStart(2, '0')
  const year = String(yesterdaysDate.getFullYear())
  const day = String(yesterdaysDate.getDate()).padStart(2, '0')
  // This logic accounts for the first month of the year
  const updatedYear = month === '00' ? String(Number(year) - 1) : year
  const updatedMonth = month === '00' ? '12' : month

  return `${updatedYear}-${updatedMonth}-${day}`
}

/** @deprecated */
export const getDate = (date: string, monthNum?: number, dayNum?: number) => {
  return new Date(new Date(date).getUTCFullYear(), new Date(date).getUTCMonth() + (monthNum ?? 0), dayNum ?? 1).toISOString().substring(0, 10)
}

/** @deprecated */
export const datePickerFormats = (t: Function) => {
  return [
    {
      title: 'MONTH',
      options: [
        {
          format: 'M',
          name: t('Numeric (04)'),
        },
        {
          format: 'MMMM',
          name: t('Text (April)'),
        },
        {
          format: 'MMM',
          name: t('Abbreviated Text (Apr)'),
        },
      ],
    },
    {
      title: 'DAY',
      options: [
        {
          format: 'd',
          name: t('Numeric (14)'),
        },
      ],
    },
    {
      title: 'YEAR',
      options: [
        {
          format: 'yyyy',
          name: t('4 digit (2021)'),
        },
        {
          format: 'yy',
          name: t('2 digit (21)'),
        },
      ],
    },
  ]
}

/** @deprecated */
export const monthsForLocale = (localeName = 'en-US', monthFormat: 'short' | 'long' = 'short') => {
  return Array.from({ length: 12 }, (_item, i) => {
    return new Date(0, i).toLocaleString(localeName, { month: monthFormat }).toUpperCase()
  })
}

/** @deprecated */
export const daysOfWeek = (t: Function, plural = false) => {
  if (plural) {
    return [t('Sundays'), t('Mondays'), t('Tuesdays'), t('Wednesdays'), t('Thursdays'), t('Fridays'), t('Saturdays')]
  }
  return [t('Sunday'), t('Monday'), t('Tuesday'), t('Wednesday'), t('Thursday'), t('Friday'), t('Saturday')]
}

/** @deprecated */
export const dayOfWeek = (t: Function, day = 0) => {
  return daysOfWeek(t)[day]
}

/** @deprecated */
export const dayWithOrdinal = (day?: number) => {
  if (day) {
    const digits = [day % 10, day % 100]
    const ordinals = ['st', 'nd', 'rd', 'th']
    const oPattern = [1, 2, 3, 4]
    const tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19]
    return oPattern.includes(digits[0]) && !tPattern.includes(digits[1]) ? day + ordinals[digits[0] - 1] : day + ordinals[3]
  }
}

/** @deprecated */
export const minutePrependZero = (minute = 0) => {
  return minute <= 9 ? '0' + minute : minute.toString()
}

/** @deprecated */
export const relativeDate = (
  date: Date | string | number,
  addSuffix?: boolean,
  addPrefix?: boolean,
  unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year',
  roundingMethod?: 'floor' | 'ceil' | 'round' | undefined
) => {
  const formattedDate = new Date(date)
  const undefinedDate = date === undefined || isNaN(Number(formattedDate))

  return undefinedDate
    ? 'undefined'
    : addPrefix
    ? formatDistanceToNow(formattedDate, { addSuffix })
    : formatDistanceStrict(formattedDate, new Date(), { addSuffix, unit, roundingMethod })
}

/** @deprecated */
export interface DateTypeData {
  date: string | Date
  count: string
}

/** @deprecated */
export enum DateType {
  YEAR = 'year',
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day',
}

/** @deprecated */
export const DEFAULT_DATE_QUANTITIES = {
  [DateType.YEAR]: 5,
  [DateType.MONTH]: 12,
  [DateType.WEEK]: 12,
  [DateType.DAY]: 7,
}

/** @deprecated */
export const convertStringToDate = (date: string, dateType: DateType) => {
  switch (dateType) {
    case DateType.MONTH:
      return new Date(new Date(date).getUTCFullYear(), new Date(date).getUTCMonth(), 1)
    case DateType.WEEK:
      const yearWeek = date.split('-')
      const year = parseInt(yearWeek[0])
      const week = parseInt(yearWeek[1]) - 1
      const weekNum = week === 0 ? 1 : week

      return new Date(year, 0, 1 + (weekNum - 1) * 7)
    default:
      return new Date(new Date(date).getUTCFullYear(), new Date(date).getUTCMonth(), new Date(date).getUTCDate())
  }
}

/** @deprecated */
export const getDateString = (date: Date, dateType: DateType) => {
  switch (dateType) {
    case DateType.MONTH:
      return date.toString().substring(4, 7)
    case DateType.WEEK:
      if (date.getDay() <= 4) {
        date.setDate(date.getDate() - date.getDay() + 1)
      } else {
        date.setDate(date.getDate() + 8 - date.getDay())
      }

      return date.toString().substring(4, 10)
    case DateType.DAY:
      return date.toString().substring(4, 10)
    case DateType.YEAR:
      return date.toString().substring(11, 15)
  }
}

/** @deprecated */
export const getTimeStampPastDate = (daysBefore: number) => {
  const resultDate = new Date()
  resultDate.setDate(resultDate.getDate() - daysBefore)
  return Math.floor(resultDate.getTime() / 1000).toString()
}

/** @deprecated */
export const getAbbreviatedTimeZone = (date: number) => new Date(date).toLocaleTimeString(undefined, { timeZoneName: 'short' }).split(' ')[2]

/** @deprecated */
export const formatDate = (timestamp: string) => (timestamp ? new Date(parseInt(timestamp)).toLocaleString('en-US') : '')

// Output ex: Apr 7, 2023
/** @deprecated */
export const getFormattedDate = (timestamp: number) => {
  const date = new Date(timestamp)
  const options = { month: 'short', day: 'numeric', year: 'numeric' }
  return date.toLocaleDateString('en-US', options as any)
}

// Output ex: 12:30 PM
/** @deprecated */
export const getFormattedTime = (timestamp: number) => {
  const date = new Date(timestamp)
  let hours = date.getHours()
  let minutes: string | number = date.getMinutes()
  const meridiem = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours ? hours : 12 // convert 0 to 12
  minutes = minutes < 10 ? '0' + minutes : minutes
  return `${hours}:${minutes} ${meridiem}`
}

/** @deprecated */
export const WEEK_DAYS = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']

/** @deprecated */
export const formatTime = (date: Dayjs) => {
  return date.format('dddd, MMM D, YYYY [at] h:mm A')
}

/** @deprecated */
export const isTimePassedInAllTimezones = (senddate: Date, sendtime: string) => {
  const currentDate = dayjs().add(5, 'minute')
  const parsedTime = dayjs(sendtime, TimePickerV2Format.HH_MM)
  return currentDate.valueOf() > dayjs(senddate).hour(parsedTime.hour()).minute(parsedTime.minute()).tz('US/Samoa', true).valueOf()
}
