import React from 'react'

import { TFunction } from 'i18next'

import { EmptyListingProps } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import TextLink from '@components/TextLink/TextLink'
import Typography from '@components/Typography/Typography'
import { EMPTY_LISTING_OPTIONS } from '@utils/ListPage.context.default'

const LEARN_MORE_LINK = 'https://connect.act-on.com/hc/en-us/articles/13439125593367-Upgrade-to-Act-On-Contacts'

const getEmptyPageText = (rootClass: string, t: TFunction) => {
  return (
    <div className={`${rootClass}__empty-state`}>
      <Typography text={t('Set up your new contacts database to start taking advantage of all the benefits of Audience Center!')} />
      <div className={`${rootClass}__empty-state-bullets`}>
        <Typography text={`\u2022 ${t('Access to powerful AI capabilities across our platform')}`} />
        <Typography text={`\u2022 ${t('Best-in-class segmentation')}`} />
        <Typography text={`\u2022 ${t('Better rollup reporting across all contacts')}`} />
        <Typography text={`\u2022 ${t('Drag-and-drop organization')}`} />
        <Typography text={`\u2022 ${t('Cleaner data')}`} />
      </div>
      <TextLink link={LEARN_MORE_LINK} text={t('Learn More')} underline />
    </div>
  )
}

export const getEmptyListingOptions = (
  enableCRMSourcedSegmentsAction = false,
  hasUCLInitialized: boolean,
  t: TFunction,
  rootClass: string
): { [key in EMPTY_LISTING_OPTIONS]: EmptyListingProps } => ({
  EMPTY_CONTACT_PREFERENCES: {
    imgSrc: StaticImageNames.errorNothingFound,
    text: 'When contacts opt out or bounce, you’ll find them here.',
    headline: 'Nothing to see here!',
  },
  EMPTY_CRM_ALL_SEGMENTS: {
    imgSrc: StaticImageNames.emptyAllSegmentIllustration,
    text: "To add contacts here, sync your CRM data. Don't forget to set up a sync schedule!",
    buttonText: 'View sync options',
    headline: 'No Contacts Yet!',
  },
  EMPTY_CRM_SOURCED_SEGMENTS: enableCRMSourcedSegmentsAction
    ? {
        imgSrc: StaticImageNames.emptyContactsMedium,
        text: 'It looks like you don’t have any CRM sourced segments just yet. To get started, you can add segments from CRM sources or create a new one.',
        buttonText: 'Add segment from CRM',
        buttonPlusIcon: true,
        headline: 'No CRM Sourced Segments',
      }
    : {
        imgSrc: StaticImageNames.emptyContactsMedium,
        text: 'It looks like you don’t have any CRM sourced segments just yet.',
        headline: 'No CRM Sourced Segments',
      },
  EMPTY_NON_CRM_ALL_SEGMENTS: {
    imgSrc: StaticImageNames.emptyAllSegmentIllustration,
    text: 'To get started, you can import contacts from a CSV file or an existing marketing list.',
    buttonText: 'Import contacts now',
    headline: 'No Contacts Yet!',
  },
  EMPTY_FOLDER: {
    imgSrc: StaticImageNames.emptyFolder,
    text: 'You can drag a segment into this folder or create a new segment.',
    buttonText: 'Create segment',
    linkText: 'View all segments',
    headline: 'This folder is empty!',
  },
  EMPTY_TAG: {
    imgSrc: StaticImageNames.emptyTag,
    text: 'Add tags to easily keep track of your segments, assign leads, and more.',
    linkText: 'More info',
    buttonText: 'View all segments',
    link: 'https://connect.act-on.com/hc/en-us/articles/4411645246871',
    headline: 'No segments found with this tag...',
  },
  EMPTY_FAVORITES: {
    imgSrc: StaticImageNames.emptyFavorites,
    text: 'Add hearts to segments that you want to easily find later.',
    buttonText: 'View all segments',
    headline: 'You don’t have any favorites yet',
  },
  EMPTY_CREATED_BY_ME: {
    imgSrc: StaticImageNames.emptyCreatedByMe,
    text: 'Create segments to begin targeting contacts by location, behavior, activities, engagements, and more.',
    buttonText: 'Create a segment',
    headline: 'You haven’t created any segments yet',
    buttonDisabled: !hasUCLInitialized,
  },
  EMPTY_RECENT: {
    imgSrc: StaticImageNames.emptyRecent,
    text: 'Once you’ve created or modified some segments, come back to Recents to find ‘em waiting here for you.',
    buttonText: 'View all segments',
    headline: 'You don’t have any recent segments',
  },
  NOT_SET_CRM_DATA_MANAGEMENT: {
    imgSrc: StaticImageNames.emptyAllSegmentIllustration,
    text: getEmptyPageText(rootClass, t),
    buttonText: 'Set up now',
    headline: 'Get Started with Audience Center!',
  },
  NOT_SET_NON_CRM_DATA_MANAGEMENT: {
    imgSrc: StaticImageNames.emptyAllSegmentIllustration,
    text: getEmptyPageText(rootClass, t),
    buttonText: 'Set up now',
    headline: 'Get Started with Audience Center!',
  },
})

export enum MENU_ITEM_INDEX {
  FILTERS,
  MORE_FILTERS,
  FOLDERS,
  TAGS,
}

export const ALL_ACTON_CONTACTS_SEGMENT_NAME = 'All Act-On Contacts'

export const REFRESH_TIMER = 120000

export const NON_INITIALIZED_UCL_TOOLTIP = 'Please click the “Set up now” button to get your contact list set up'

export enum IMPORT_SOURCE {
  LOCAL = 'LOCAL',
  FTP = 'FTP',
}

export enum EXPORT_ACTION {
  CSV = 'CSV',
  CSV_WITH_UPLOADED_FILES = 'CSV_WITH_UPLOADED_FILES',
  MANAGE_FTP = 'MANAGE_FTP',
  NEW_FTP = 'NEW_FTP',
}
