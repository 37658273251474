import React, { FC, Fragment } from 'react'

import classNames from 'classnames'

import { ButtonIconPosition, ButtonType } from '@components/Button'
import { Button, ButtonSize, ButtonWeight } from '@components/Button/Button'
import Pill from '@components/Pill/Pill'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Table, { TableColumn } from '@components/Table/Table'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'

import './SendEmailStepDetail.css'

export interface SendChoice {
  msgId: string
  name: string
  operator?: string
  condition?: string
  isDefault: boolean
  sent?: number
  clicked?: number
}

export type SendEmailStepMode = 'Conditional' | 'ABTest' | 'NoMode'

export interface SendEmailStepDetailProps {
  programId: string
  sendMode: SendEmailStepMode
  sendChoices: SendChoice[]
  abThreshold: number
  className?: string
  dataTest?: string
  abWinner: string
  stepId: string
  handleSetAbWinner?: (programId: string, stepId: string, msgId: string, name: string) => void
  handleResetAbTest?: (programId: string, stepId: string) => void
}

const rootClass = 'send-email-step-detail'

const SendEmailStepDetail: FC<SendEmailStepDetailProps> = (props: SendEmailStepDetailProps) => {
  const {
    programId,
    sendMode,
    sendChoices,
    abThreshold,
    dataTest = rootClass,
    className = '',
    stepId,
    handleSetAbWinner,
    abWinner,
    handleResetAbTest,
  } = props
  const { t } = useTranslation()
  const baseUrl = `${rootContext}/classic/if/messages/messageSentFrame.jsp?id=${programId}&msgid=`

  const renderSend = (sendChoice: SendChoice, isThen = true, className?: string) => (
    <div className={className}>
      <div className={`${rootClass}__message-container`}>
        {isThen && <Typography text={t('THEN ')} inline className={`${rootClass}__then`} />}
        <Typography text={t(isThen ? 'send ' : 'Send')} inline />
        <Svg name={SvgNames.letter} type={SvgType.ICON} className={`${rootClass}__svg`} />
        <TextLink text={sendChoice.name} link={`${baseUrl}${sendChoice.msgId}`} size={TextLinkSize.LARGE} hideIcon />
      </div>
    </div>
  )

  const renderDoNothing = (className = `${rootClass}__then`) => <Typography text={t('Send no email')} className={className} />

  const renderNoEmailSelected = () => <Typography text={t('No email selected')} weight={TextWeight.ITALIC} />

  // Early exit if no condition/AB test
  if (sendMode === 'NoMode') {
    return sendChoices.length === 0
      ? renderNoEmailSelected()
      : sendChoices[0].msgId === ''
      ? renderDoNothing(`${rootClass}__singleton`)
      : renderSend(sendChoices[0], false, classNames(rootClass, `${rootClass}__singleton`))
  }

  const renderIf = () => {
    const elseChoice = sendChoices.find((choice) => choice.isDefault)
    const elseLine = elseChoice ? (
      <>
        <Typography text={t('ELSE')} /> {elseChoice.msgId === '' ? renderDoNothing() : renderSend(elseChoice)}
      </>
    ) : undefined

    const ifs = sendChoices
      .filter((choice) => !choice.isDefault)
      .map((sendChoice, idx) => (
        <Fragment key={`${sendChoice.msgId}-ifs-${idx}`}>
          <div className={`${rootClass}__test-message-container`}>
            <Typography text={sendChoice.operator} inline /> <Svg name={SvgNames.contacts} type={SvgType.ICON} />{' '}
            <Typography text={sendChoice.condition} weight={TextWeight.MEDIUM} inline />
          </div>
          {sendChoice.msgId === '' ? renderDoNothing() : renderSend(sendChoice)}
        </Fragment>
      ))

    return (
      <>
        {ifs}
        {elseLine}
      </>
    )
  }

  const renderAB = () => {
    const cols: TableColumn[] = [
      {
        Header: 'Subject',
        accessor: 'subject',
        align: 'left',
      },
      {
        Header: 'Sent',
        accessor: 'sent',
        align: 'center',
        width: 75,
        minWidth: 75,
        maxWidth: 75,
      },
      {
        Header: 'Clicked',
        accessor: 'clicked',
        align: 'center',
        width: 75,
        minWidth: 75,
        maxWidth: 75,
      },
      {
        Header: '',
        accessor: 'button',
        align: 'right',
        width: 160,
        maxWidth: 160,
        minWidth: 160,
      },
    ]

    const markWinner = (msgId: string, name: string) => (
      <Button
        onClick={() => (handleSetAbWinner ? handleSetAbWinner(programId, stepId, msgId, name) : undefined)}
        className={`${rootClass}__mark-winner`}
        buttonType={ButtonType.INFO}
        buttonSize={ButtonSize.SMALL}
        weight={ButtonWeight.MEDIUM}
        dataTest={`${rootClass}-mark-winner`}
      >
        <Typography text={t('A/B Mark Winner')} type={TextType.NORMAL_TEXT_TEAL} />
      </Button>
    )

    const winnerPill = () => <Tooltip trigger={<Pill text={t('Winner')} />}>{t('A/B Winner Pill Tooltip', { abThreshold: abThreshold })}</Tooltip>
    sendChoices.forEach((choice, index) => (choice.name = `(${String.fromCharCode(index + 65)}) ${choice.name}`))
    const tableData: any[] = sendChoices.map((choice) => ({
      subject: choice.name,
      sent: choice.sent,
      clicked: choice.clicked,
      button: abWinner === '' && handleSetAbWinner ? markWinner(choice.msgId, choice.name) : abWinner === choice.msgId ? winnerPill() : '',
    }))

    const description = () => {
      if (abWinner === '') {
        return (
          <div className={`${rootClass}__ab-description`}>
            <Typography text={`${t('A/B Description One')}`} inline />
            <Typography text={abThreshold} inline weight={TextWeight.BOLD} /> <Typography text={t('A/B Description Two')} inline />
            <Tooltip trigger={<Svg name={SvgNames.info} type={SvgType.LARGER_ICON} className="tabs-ao__split-container-tooltip" />}>
              {t('A/B Description Info Tooltip')}
            </Tooltip>
          </div>
        )
      } else {
        const abWinnerName = sendChoices.find((x) => x.msgId === abWinner)
        return (
          <>
            <div className={`${rootClass}__ab-description`}>
              <Typography text={t('A/B Description Three', { name: abWinnerName ? abWinnerName.name : '' })} inline />
            </div>
            {handleResetAbTest && (
              <div className={`${rootClass}__ab-test`}>
                <Button
                  onClick={() => (handleResetAbTest ? handleResetAbTest(programId, stepId) : undefined)}
                  className={`${rootClass}__reset-winner`}
                  buttonType={ButtonType.INFO}
                  buttonSize={ButtonSize.LARGE}
                  weight={ButtonWeight.MEDIUM}
                  iconPosition={ButtonIconPosition.LEFT}
                  dataTest={`${rootClass}-reset-winner`}
                >
                  <Svg name={SvgNames.reload} type={SvgType.LARGER_ICON} className={`${rootClass}__ab-restart`} />
                  <Typography text={t('A/B Restart Test Button One')} type={TextType.NORMAL_TEXT_TEAL} />
                </Button>
              </div>
            )}
          </>
        )
      }
    }

    return (
      <>
        {description()}
        <div className={`${rootClass}__ab-expanded`}>
          <Table columns={cols} data={tableData} />
        </div>
      </>
    )
  }

  return (
    <div
      className={classNames(rootClass, className, {
        [`${rootClass}__ab-test`]: sendMode === 'ABTest',
        [`${rootClass}__conditional`]: sendMode === 'Conditional',
      })}
      data-test={dataTest}
    >
      {sendMode === 'Conditional' ? renderIf() : renderAB()}
    </div>
  )
}

export default SendEmailStepDetail
