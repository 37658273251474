import React, { FC, useEffect, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/index'
import { isOverUpperHalf } from '@components/ColumnsOrderModal/utils/ColumnsOrderModal.utils'
import Svg, { SvgNames, SvgType } from '@components/Svg/index'
import Typography, { TextType } from '@components/Typography/Typography'
import { ItemType } from '@utils/categorization'

import './DraggableItem.css'

export interface DraggableElement {
  name: string
  index: number
  id?: string
  content?: string
}

interface DraggableItemProps {
  item: DraggableElement
  placeholder?: string
  listingPosition?: number
  canBeRemoved?: boolean
  error?: string
  onDrop: (droppedItem: DraggableElement, droppedAt: number) => void
  onRemove: (item: DraggableElement) => void
  onChange?: (item: DraggableElement) => void
  onBlur?: (item: DraggableElement) => void
  className?: string
  dataTest?: string
  showListingPosition?: boolean
}

const rootClass = 'draggable-item'

const DraggableItem: FC<DraggableItemProps> = (props: DraggableItemProps) => {
  const {
    item,
    placeholder,
    listingPosition,
    error,
    canBeRemoved,
    onRemove,
    onDrop,
    onChange,
    onBlur,
    dataTest = rootClass,
    showListingPosition = true,
    className = '',
  } = props
  const { name, index } = item

  const [isOverTop, setIsOverTop] = useState(false)
  const dropRef = useRef<HTMLDivElement>(null)

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: ItemType.FORM,
      item,
      collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    }),
    [item]
  )

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemType.FORM,
    drop: (item: DraggableElement) => onDrop(item, isOverTop ? index : index + 1),
    hover: (_, monitor) => setIsOverTop(isOverUpperHalf(dropRef, monitor)),
    canDrop: (_item) => true,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [preview])

  drop(dropRef)

  return (
    <>
      <div
        className={classNames(rootClass, className, {
          [`${rootClass}__over`]: canDrop && isOver && !isOverTop,
          [`${rootClass}__over-top`]: canDrop && isOver && isOverTop,
        })}
        data-test={`${dataTest}-${index}__drop`}
        ref={dropRef}
      >
        {showListingPosition && <Typography className={classNames(`${rootClass}__index`, 'ellip')} text={`${listingPosition}.`} />}
        <div
          data-test={`${dataTest}-${index}__drag`}
          className={classNames(`${rootClass}__element`, {
            [`${rootClass}__element-dragged`]: isDragging,
            [`${rootClass}__element-error`]: !!error,
          })}
          ref={drag}
          role={'button'}
          tabIndex={0}
        >
          <div className={`${rootClass}__element-drag`}>
            <Svg className={`${rootClass}__element-drag-icon`} name={SvgNames.drag} type={SvgType.SMALLER_ICON} />
          </div>
          <input
            readOnly={!onChange}
            type="text"
            value={name}
            placeholder={placeholder}
            /* eslint-disable-next-line jsx-a11y/no-autofocus */
            autoFocus
            className={classNames(`${rootClass}__element-name`, {
              [`${rootClass}__element-name-readonly`]: !onChange,
            })}
            onChange={(event) => onChange?.({ ...item, name: event.target.value })}
            onBlur={(event) => {
              if (onBlur) {
                onBlur({ ...item, name: event.target.value })
              }
            }}
            data-test={`${dataTest}-${index}__input`}
          />
          {canBeRemoved && (
            <div className={`${rootClass}__element-remove`}>
              <Button buttonType={ButtonType.ICON} onClick={() => onRemove(item)} dataTest={`${dataTest}-remove`}>
                <Svg name={SvgNames.clearIndicator} />
              </Button>
            </div>
          )}
        </div>
      </div>
      {error && <Typography text={error} type={TextType.ERROR} className={`${rootClass}__error`} />}
    </>
  )
}

export default DraggableItem
