import { SvgNames } from '@components/Svg'
import { ProgramState } from '@utils/program/program.constants'

export interface ProgramStatusOption {
  [key: string]: {
    main: string
    mainClass: string
    icon: SvgNames
    primary: string
    secondary: string
    isError?: boolean
  }
}

export const ProgramStatusOptions: ProgramStatusOption = {
  [ProgramState.RUNNING]: {
    main: 'Program is running',
    mainClass: 'state-running',
    icon: SvgNames.pause,
    primary: 'Pause Program',
    secondary: 'You cannot edit a running program. Please pause the program to edit it.',
  },
  [ProgramState.PAUSED]: {
    main: 'Program is paused',
    mainClass: 'state-paused',
    icon: SvgNames.play,
    primary: 'Start Program',
    secondary: 'You will not be able to edit the program while it is running.',
  },
  [ProgramState.DRAFT]: {
    main: 'Program has errors',
    mainClass: 'state-draft',
    icon: SvgNames.play,
    primary: 'Start Program',
    secondary: 'You will not be able to edit the program while it is running.',
  },
  [ProgramState.ERROR]: {
    main: 'Program has errors',
    mainClass: 'state-error',
    icon: SvgNames.warning,
    primary: 'View Error summary',
    secondary: 'Click here to view and fix program errors.',
    isError: true,
  },
}
