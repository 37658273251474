import { ApolloClient } from '@apollo/client'
import crmDefinition from '@graphql/microservices/crm/crmDefinition'
import crmSegmentsInfo from '@graphql/microservices/crm/crmSegmentsInfo'
import deleteSyncedSegments from '@graphql/microservices/crm/deleteSyncedSegments'
import {
  CrmDefinitionQuery,
  CrmDefinitionQueryVariables,
  CrmSegmentsInfoQuery,
  CrmSegmentsInfoQueryVariables,
  DeleteSyncedSegmentsMutation,
  DeleteSyncedSegmentsMutationVariables,
} from '@graphql/types/microservice/crm-types'
import { FetchPromise } from '@utils/types'

type GetCRMNotSupportedEntitiesRequestParams = { connectedCRM: string }
type DeleteSyncedSegmentsRequestParams = { segmentIdentifiers: string[] }
type GetCRMSegmentsInfoRequestParams = DeleteSyncedSegmentsRequestParams

export type GetCRMNotSupportedEntitiesRequestType = (params: GetCRMNotSupportedEntitiesRequestParams) => FetchPromise<CrmDefinitionQuery>
export type DeleteSyncedSegmentsRequestType = (params: DeleteSyncedSegmentsRequestParams) => FetchPromise<DeleteSyncedSegmentsMutation>
export type GetCRMSegmentsInfoRequestType = (params: GetCRMSegmentsInfoRequestParams) => FetchPromise<CrmSegmentsInfoQuery>

export interface GetCRMServiceRequest {
  getCRMNotSupportedEntitiesRequest: GetCRMNotSupportedEntitiesRequestType
  deleteSyncedSegmentsRequest: DeleteSyncedSegmentsRequestType
  getCRMSegmentsInfoRequest: GetCRMSegmentsInfoRequestType
}

export const useContactSegmentsCRMServiceRequests = (crmClient: ApolloClient<any>): GetCRMServiceRequest => {
  const deleteSyncedSegmentsRequest = async ({ segmentIdentifiers }: DeleteSyncedSegmentsRequestParams) =>
    await crmClient.mutate<DeleteSyncedSegmentsMutation, DeleteSyncedSegmentsMutationVariables>({
      mutation: deleteSyncedSegments,
      variables: { segmentIdentifiers },
    })

  const getCRMSegmentsInfoRequest = async ({ segmentIdentifiers }: GetCRMSegmentsInfoRequestParams) =>
    await crmClient.query<CrmSegmentsInfoQuery, CrmSegmentsInfoQueryVariables>({
      query: crmSegmentsInfo,
      fetchPolicy: 'network-only',
      variables: { segmentIdentifiers },
    })

  const getCRMNotSupportedEntitiesRequest = async ({ connectedCRM }: GetCRMNotSupportedEntitiesRequestParams) =>
    await crmClient.query<CrmDefinitionQuery, CrmDefinitionQueryVariables>({
      query: crmDefinition,
      fetchPolicy: 'network-only',
      variables: {
        connectedCrmName: connectedCRM,
      },
    })

  return {
    deleteSyncedSegmentsRequest,
    getCRMSegmentsInfoRequest,
    getCRMNotSupportedEntitiesRequest,
  }
}
