import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { renderColWithTitleV2 } from '@components/TableV2/utils/tableV2ColumnUtils'
import { OptStatus } from '@graphql/types/microservice/sms-management-types'

export const smsOptOutColumns: ColumnDefWithAdditionalProps<OptStatus, unknown>[] = [
  {
    header: 'Mobile Phone Number',
    accessorKey: 'phoneNumber',
    textAlign: 'left',
    cell: (cell) => renderColWithTitleV2(cell),
  },
  {
    header: 'Opt-Out Source',
    accessorKey: 'source',
    textAlign: 'left',
    maxSize: 168,
  },
  {
    header: 'Opt-Out Date',
    accessorKey: 'statusDate',
    textAlign: 'right',
    maxSize: 200,
  },
]
