import React, { FC, useCallback } from 'react'

import classNames from 'classnames'

import { ANALYTICS_INVISIBLE_ACTIONS } from '@src/pages/reports/Analytics/Analytics.constants'
import { Action } from '@thoughtspot/visual-embed-sdk'
import { AppEmbed, Page, useEmbedRef } from '@thoughtspot/visual-embed-sdk/react'

import './SpotIQ.css'

interface SpotIQProps {
  hasAIAnalytics: boolean
  detailPath: string
  setRef: (ref: any) => void
  onDetailRender: () => void
  className?: string
  dataTest?: string
}

const rootClass = 'spot-iq'

const SpotIQ: FC<SpotIQProps> = (props: SpotIQProps) => {
  const { hasAIAnalytics, detailPath, setRef, onDetailRender, dataTest = rootClass, className = '' } = props
  const embedRef = useEmbedRef<typeof AppEmbed>()

  const aiAction = !hasAIAnalytics ? [Action.SpotIQAnalyze] : []
  const allActions = [...ANALYTICS_INVISIBLE_ACTIONS, ...aiAction]

  const onRouteChange = useCallback(
    (data: any) => {
      if (data.data.currentPath !== '/insights/results') {
        setRef(embedRef.current)
        onDetailRender()
      }
    },
    [embedRef, setRef]
  )

  const path = detailPath.includes('insight') ? { path: detailPath } : {}

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {hasAIAnalytics && (
        <AppEmbed
          {...path}
          pageId={Page.SpotIQ}
          ref={embedRef}
          dataPanelV2
          hiddenActions={allActions}
          additionalFlags={{ showOnboarding: false }}
          onRouteChange={onRouteChange}
          className={`${rootClass}__embed`}
          fullHeight
        />
      )}
      {!hasAIAnalytics && (
        <iframe
          className={`${rootClass}__iframe`}
          title={
            'Leverage Act-On’s Digital Marketing Analytics, Campaign Reports, and Dashboards to gain valuable insights for enhancing your campaigns, content, and messaging throughout the customer lifecycle.'
          }
          src={
            'https://success.act-on.com/acton/attachment/9883/u-bc549b7c-56c7-4958-9164-842103f44156/0/-/-/-/-/In%20App%3A%20%20Spot%20IQ%20Upsell%20page'
          }
        />
      )}
    </div>
  )
}

export default SpotIQ
