import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import Loader from '@components/Loader'
import { useTranslation } from '@const/globals'
import saveGlobalBcc from '@graphql/mutations/saveGlobalBcc'
import setAutoLogoutTimeout from '@graphql/mutations/setAutoLogoutTimeout'
import setPasswordPolicies from '@graphql/mutations/setPasswordPolicies'
import getAutoLogout from '@graphql/queries/getCustomAccountAutoLogout'
import getPwdPolicies from '@graphql/queries/getCustomAccountPwdPolicies'
import getGlobalBcc from '@graphql/queries/getGlobalBcc'
import {
  MutationSaveGlobalBccArgs,
  MutationUpdateCustomAccountPwdPoliciesArgs,
  PwdPolicyInput,
  UpdateCustomAccountAutoLogoutMutation,
  UpdateCustomAccountAutoLogoutMutationVariables,
} from '@graphql/types/mutation-types'
import {
  GetCustomAccountAutoLogoutQuery,
  GetCustomAccountAutoLogoutQueryVariables,
  GetCustomAccountPwdPoliciesQuery,
  GetCustomAccountPwdPoliciesQueryVariables,
  GetGlobalBccQuery,
  PwdPolicyResponse,
} from '@graphql/types/query-types'
import { StatusToastType } from '@interface/StatusToast'
import Security from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Security/Security'
import { useAccountSettings } from '@utils/account/account.utils'
import { logNewRelicError } from '@utils/new-relic.utils'

interface Props {
  setToastStatus: (value: StatusToastType) => void
  dataTest?: string
}

const SecurityContainer: FC<Props> = (props: Props) => {
  const { setToastStatus, dataTest } = props
  const [pwdPolicies, setPwdPolicies] = useState<PwdPolicyResponse | undefined>(undefined)
  const [globalBcc, setGlobalBcc] = useState<string | undefined>(undefined)
  const [autoLogout, setAutoLogout] = useState<string | undefined>(undefined)
  const { hasGlobalBCC, isAccountAdmin } = useAccountSettings()
  const client = useApolloClient()
  const { t } = useTranslation()

  const {
    data: dataPwd,
    loading: loadingPwd,
    error: errorPwd,
    refetch: refetchPwd,
  } = useQuery<GetCustomAccountPwdPoliciesQuery, GetCustomAccountPwdPoliciesQueryVariables>(getPwdPolicies, {
    client,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const {
    data: dataLogout,
    loading: loadingLogout,
    error: errorLogout,
    refetch: refetchLogout,
  } = useQuery<GetCustomAccountAutoLogoutQuery, GetCustomAccountAutoLogoutQueryVariables>(getAutoLogout, {
    client,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const {
    data: dataGlobalBcc,
    loading: loadingGlobalBcc,
    error: errorGlobalBcc,
    refetch: refetchGlobalBcc,
  } = useQuery<GetGlobalBccQuery, GetGlobalBccQuery>(getGlobalBcc, {
    client,
    fetchPolicy: 'no-cache',
  })

  const [changeAutoLogoutTimeout, { loading: loadingLogoutChange }] = useMutation<
    UpdateCustomAccountAutoLogoutMutation,
    UpdateCustomAccountAutoLogoutMutationVariables
  >(setAutoLogoutTimeout, {
    client,
    fetchPolicy: 'no-cache',
  })

  const [changePwdPolicies, { loading: loadingPwdPoliciesChange }] = useMutation<any, MutationUpdateCustomAccountPwdPoliciesArgs>(
    setPasswordPolicies,
    {
      client,
      fetchPolicy: 'no-cache',
    }
  )

  const [changeGlobalBccPolicies, { loading: loadingBccPoliciesChange }] = useMutation<any, MutationSaveGlobalBccArgs>(saveGlobalBcc, {
    client,
    fetchPolicy: 'no-cache',
  })

  const onChangeAutoLogoutTimeout = useCallback((timeoutBySec: string) => {
    changeAutoLogoutTimeout({
      variables: {
        timeoutBySec,
      },
    })
      .then(() => {
        setToastStatus({
          showStatus: true,
          title: t('Success!'),
          statusMessage: t('AutoLogout.Settings.Change.Success.Message'),
          successStatus: true,
        })
        refetchLogout()
      })
      .catch((error) => {
        setToastStatus({
          showStatus: true,
          statusMessage: t('Something went wrong on our end. Please try again.'),
          successStatus: false,
        })
        logNewRelicError(error)
      })
  }, [])

  const onChangePwdPolicies = useCallback((policies: PwdPolicyInput) => {
    changePwdPolicies({
      variables: {
        policies,
      },
    })
      .then(() => {
        setToastStatus({
          showStatus: true,
          title: t('Success!'),
          statusMessage: t('Password.Policies.Change.Success.Message'),
          successStatus: true,
        })
        refetchPwd()
      })
      .catch((error) => {
        setToastStatus({
          showStatus: true,
          statusMessage: t('Something went wrong on our end. Please try again.'),
          successStatus: false,
        })
        logNewRelicError(error)
      })
  }, [])

  const onChangeGlobalBcc = useCallback((email: string) => {
    changeGlobalBccPolicies({
      variables: {
        email,
      },
    })
      .then(() => {
        setToastStatus({
          showStatus: true,
          statusMessage: email.length > 0 ? t('Global.BCC.Save.Success') : t('Global.BCC.Reset.Success'),
          successStatus: true,
        })
        refetchGlobalBcc()
      })
      .catch((error) => {
        setToastStatus({
          showStatus: true,
          statusMessage: t('Something went wrong on our end. Please try again.'),
          successStatus: false,
        })
        logNewRelicError(error)
      })
  }, [])

  useEffect(() => {
    if (!loadingPwd && dataPwd?.getCustomAccountPwdPolicies) {
      setPwdPolicies(dataPwd.getCustomAccountPwdPolicies)
    }
    if (errorPwd) {
      logNewRelicError(errorPwd)
    }
  }, [loadingPwd, errorPwd, dataPwd])

  useEffect(() => {
    if (!loadingLogout && dataLogout?.getCustomAccountAutoLogout) {
      setAutoLogout(dataLogout.getCustomAccountAutoLogout)
    }
    if (errorLogout) {
      logNewRelicError(errorLogout)
    }
  }, [loadingLogout, errorLogout, dataLogout])

  useEffect(() => {
    if (!loadingGlobalBcc && dataGlobalBcc?.getGlobalBcc !== undefined) {
      setGlobalBcc(dataGlobalBcc?.getGlobalBcc)
    }
    if (errorPwd) {
      logNewRelicError(errorPwd)
    }
  }, [loadingGlobalBcc, errorGlobalBcc, dataGlobalBcc])

  const loadingPwdPolicies = useMemo<boolean>(() => loadingPwd || loadingPwdPoliciesChange, [loadingPwd, loadingPwdPoliciesChange])
  const loadingAutoLogout = useMemo<boolean>(() => loadingLogoutChange || loadingLogout, [loadingLogout, loadingLogoutChange])
  const loadingGlobalBccPolicy = useMemo<boolean>(() => loadingBccPoliciesChange || loadingGlobalBcc, [loadingBccPoliciesChange, loadingGlobalBcc])

  if (loadingPwdPolicies && loadingAutoLogout) return <Loader center />

  if ((!pwdPolicies || !autoLogout) && !hasGlobalBCC) return null

  return (
    <Security
      dataTest={dataTest}
      pwdPolicies={pwdPolicies}
      autoLogout={autoLogout}
      globalBcc={globalBcc ?? ''}
      hasGlobalBCC={hasGlobalBCC}
      isAdmin={isAccountAdmin}
      onChangePwdPolicies={onChangePwdPolicies}
      onChangeAutoLogoutTimeout={onChangeAutoLogoutTimeout}
      onChangeBCCPolicy={onChangeGlobalBcc}
      loadingPwdPolicies={loadingPwdPolicies}
      loadingAutoLogout={loadingAutoLogout}
      loadingGlobalBcc={loadingGlobalBccPolicy}
    />
  )
}

export default SecurityContainer
