import SvgNames from '@components/Svg/SvgNames'
import { FieldValidationState, TableType } from '@utils/crm.utils'

export enum IconType {
  ERROR,
  OK,
  WARNING,
}

export enum ColumnType {
  OPTION,
  ICON,
  STATIC,
  EDIT,
  BLANK,
  ALLOW_PUSH,
}

export type ExtraInfo = {
  rowType?: RowType
  tableType?: TableType
}

export enum RowType {
  STANDARD,
  CUSTOM,
  REQUIRED,
  TEMPORARY,
}

export interface DynamicListItems {
  type: ColumnType
  column?: string
  values?: OptionType[]
  icon?: IconType
  selected?: string
  staticValue?: string
  id?: string
  dataType?: string
  dupStatus?: FieldValidationState
}

export interface OptionType {
  display: string
  value?: string
  type?: string
  disabled?: boolean
  dataType?: string
  key?: string
  id?: number
  unmappable?: boolean
}

export interface Metadata {
  scoreSheetId?: string
}

export interface DynamicListRowData {
  id: number
  columns: DynamicListItems[]
  key: string
  type?: RowType
  metadata?: Metadata
}

export interface Icon {
  image: SvgNames
  class: string
  tooltip: string
}

export enum CommonMappingColumn {
  ACTON_CONTACTS = 'ACT-ON CONTACTS',
  DATA_TYPE = 'DATA TYPE',
  ALLOW_PUSH = 'ALLOW PUSH',
}
