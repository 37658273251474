import dayjs, { Dayjs } from 'dayjs'

import { GetRevenueImpactLeadsQueryVariables, GetRevenueImpactOpportunitiesQueryVariables } from '@graphql/types/microservice/data-lake-query-types'

const generateDataByDate = (
  props: {
    startDate?: string
    endDate?: string
    withRevenue?: boolean
  } = {}
): {
  count: number
  date: string
  revenue?: number
}[] => {
  const { startDate, endDate, withRevenue } = props
  const [startDay, endDay] = dayjs(startDate).isValid() && dayjs(endDate).isValid() ? [dayjs(startDate), dayjs(endDate)] : defaultDateRange
  const daysInRange = endDay.diff(startDate, 'day')
  const result = []
  for (let i = 0; i < daysInRange; i++) {
    // Add 15% '0's into data
    const randCount = Math.random() - 0.15
    const count = randCount > 0 ? Math.floor(randCount * 100) : 0
    const date = startDay.add(i, 'day').format('YYYY-MM-DD')
    if (!withRevenue) {
      result.push({ count, date })
    } else {
      const randRevenue = Math.random() - 0.15
      const revenue = randRevenue > 0 ? Math.floor(randRevenue * 500000) : 0
      result.push({ count, date, revenue })
    }
  }
  return result
}

export const revenueImpactHeaderMock = {
  isSyncing: false,
  isSyncingError: false,
  lastSyncedDate: '12:00 AM 11/23/22',
  syncConfiguration: 'every Tuesday at 12:00 AM PST',
}

export const CRMSyncMock = {
  lastSync: {
    ended: Date.now(),
  },
  runningSyncStatus: { syncStatus: 'Idle' },
}

export const revenueImpactNewMock = {
  getRevenueImpactWonOpportunities: {
    total: {
      amount: 65300000,
      count: 803,
      increase: 16,
    },
    topIndustry: {
      amount: 22600000,
      count: 380,
      industry: 'Finance',
    },
    topSegment: {
      amount: 14500000,
      count: 86,
      segment: 'Enterprise',
    },
  },
  getRevenueImpactCampaigns: {
    campaigns: [
      {
        name: 'Website',
        nameRows: [
          {
            name: 'Organic Chat Drift',
            statusRowLost: {
              stageRows: [
                {
                  name: 'Sample Lost',
                  opportunities: 4,
                  pipeline: 210000,
                  revenue: 210000,
                  won: 0,
                },
              ],
            },
            statusRowOpen: {
              stageRows: [
                {
                  name: 'SQL - No Opportunity',
                  opportunities: 1,
                  pipeline: 84000,
                  revenue: 12814,
                  won: 0,
                },
                {
                  name: 'Closed - Lost No Resources / Budget',
                  opportunities: 6,
                  pipeline: 1814,
                  revenue: 1814,
                  won: 0,
                },
              ],
            },
            statusRowWon: {
              stageRows: [
                {
                  name: 'Sample Won',
                  opportunities: 2,
                  pipeline: 210000,
                  revenue: 210000,
                  won: 2,
                },
              ],
            },
          },
        ],
      },
      {
        name: 'Event',
        nameRows: [
          {
            name: 'Name - 2',
            statusRowLost: undefined,
            statusRowOpen: undefined,
            statusRowWon: {
              stageRows: [
                {
                  name: 'Sample - 2',
                  opportunities: 123,
                  pipeline: 94000,
                  revenue: 13014,
                  won: 112,
                },
              ],
            },
          },
        ],
      },
      {
        name: 'Email',
        nameRows: [
          {
            name: 'Name - 1',
            statusRowLost: undefined,
            statusRowOpen: undefined,
            statusRowWon: {
              stageRows: [
                {
                  name: 'Sample - 1',
                  opportunities: 44,
                  pipeline: 84000,
                  revenue: 12814,
                  won: 112,
                },
              ],
            },
          },
        ],
      },
      {
        name: '',
        nameRows: [
          {
            name: 'First row',
            statusRowLost: undefined,
            statusRowOpen: {
              stageRows: [
                {
                  name: '',
                  opportunities: 18,
                  pipeline: 33400,
                  revenue: 64760,
                  won: 15,
                },
              ],
            },
            statusRowWon: {
              stageRows: [
                {
                  name: 'Stage Sample ',
                  opportunities: 27,
                  pipeline: 134000,
                  revenue: 504760,
                  won: 19,
                },
              ],
            },
          },
        ],
      },
    ],
  },
  getRevenueImpactLeads: {
    analytics: {
      count: 978,
      trend: 16,
      topSource: 'Sales',
      topMonth: 'October',
    },
    bySource: [
      {
        name: 'Sales',
        count: 93,
      },
      {
        name: 'Marketing',
        count: 88,
      },
      {
        name: 'SDR',
        count: 75,
      },
      {
        name: 'Email',
        count: 66,
      },
      {
        name: 'Unknown',
        count: 58,
      },
      {
        name: 'Webinar',
        count: 44,
      },
      {
        name: 'Website',
        count: 38,
      },
      {
        name: 'Development',
        count: 20,
      },
      {
        name: 'Mobile App',
        count: 15,
      },
    ],
    byDate: generateDataByDate(),
  },
  getRevenueImpactOpportunities: {
    allOpportunities: {
      count: 617,
      revenue: 16905404,
      topCampaign: 'Prospecting',
      topMonth: 'November',
      topSource: 'Web',
      trend: 4,
    },
    newOpportunities: {
      analytics: {
        count: 423,
        revenue: 7690544,
        topCampaign: 'Email',
        topMonth: 'March',
        topSource: 'Channel',
        trend: 10,
      },
      byCampaign: [
        {
          count: 78,
          name: 'Channel',
          revenue: 1085700,
        },
        {
          count: 62,
          name: 'Sales',
          revenue: 985700,
        },
        {
          count: 54,
          name: 'SDR',
          revenue: 885700,
        },
        {
          count: 32,
          name: 'Marketing',
          revenue: 815700,
        },
        {
          count: 22,
          name: 'Webinar',
          revenue: 665700,
        },
        {
          count: 19,
          name: 'Unknown',
          revenue: 455700,
        },
        {
          count: 18,
          name: 'Website',
          revenue: 375700,
        },
        {
          count: 15,
          name: 'Development',
          revenue: 285700,
        },
        {
          count: 13,
          name: 'Mobile App',
          revenue: 107809,
        },
      ],
      byDate: generateDataByDate({ withRevenue: true }),
      bySource: [
        {
          count: 93,
          name: 'Prospecting',
          revenue: 4300000,
        },
        {
          count: 88,
          name: 'Event',
          revenue: 3460780,
        },
        {
          count: 76,
          name: 'Webinar',
          revenue: 3060780,
        },
        {
          count: 70,
          name: 'Customer',
          revenue: 2460780,
        },
        {
          count: 57,
          name: 'Email',
          revenue: 2060780,
        },
        {
          count: 34,
          name: 'Partner',
          revenue: 1660780,
        },
        {
          count: 32,
          name: 'Website',
          revenue: 1320780,
        },
        {
          count: 23,
          name: 'Development',
          revenue: 1060780,
        },
        {
          count: 19,
          name: 'Mobile App',
          revenue: 660780,
        },
      ],
    },
    wonOpportunities: {
      analytics: {
        count: 187,
        revenue: 1690544,
        topCampaign: 'Channel',
        topMonth: 'January',
        topSource: 'Prospecting',
        trend: 8,
      },
      byCampaign: [
        {
          count: 88,
          name: 'Channel',
          revenue: 1185700,
        },
        {
          count: 72,
          name: 'Sales',
          revenue: 1085700,
        },
        {
          count: 64,
          name: 'SDR',
          revenue: 985700,
        },
        {
          count: 42,
          name: 'Marketing',
          revenue: 715700,
        },
        {
          count: 32,
          name: 'Webinar',
          revenue: 705700,
        },
        {
          count: 29,
          name: 'Unknown',
          revenue: 555700,
        },
        {
          count: 28,
          name: 'Website',
          revenue: 475700,
        },
        {
          count: 25,
          name: 'Development',
          revenue: 385700,
        },
        {
          count: 23,
          name: 'Mobile App',
          revenue: 207809,
        },
      ],
      byDate: generateDataByDate({ withRevenue: true }),
      bySource: [
        {
          count: 76,
          name: 'Prospecting',
          revenue: 3900000,
        },
        {
          count: 69,
          name: 'Event',
          revenue: 3660780,
        },
        {
          count: 65,
          name: 'Webinar',
          revenue: 2960780,
        },
        {
          count: 48,
          name: 'Customer',
          revenue: 2760780,
        },
        {
          count: 34,
          name: 'Email',
          revenue: 1860780,
        },
        {
          count: 30,
          name: 'Partner',
          revenue: 1660780,
        },
        {
          count: 28,
          name: 'Website',
          revenue: 1320780,
        },
        {
          count: 17,
          name: 'Development',
          revenue: 1160780,
        },
        {
          count: 15,
          name: 'Mobile App',
          revenue: 860780,
        },
      ],
    },
  },
  getRevenuePropertiesFromUserSetting: {
    newLeadShowOther: false,
    opportunityShowOther: false,
  },
}

export const generateNewLeadsMockData = ({ startDate, endDate }: GetRevenueImpactLeadsQueryVariables) => ({
  ...revenueImpactNewMock.getRevenueImpactLeads,
  byDate: generateDataByDate({ startDate, endDate }),
})

export const generateOpportunitiesMockData = ({ startDate, endDate }: GetRevenueImpactOpportunitiesQueryVariables) => ({
  allOpportunities: revenueImpactNewMock.getRevenueImpactOpportunities.allOpportunities,
  newOpportunities: {
    ...revenueImpactNewMock.getRevenueImpactOpportunities.newOpportunities,
    byDate: generateDataByDate({ startDate, endDate, withRevenue: true }),
  },
  wonOpportunities: {
    ...revenueImpactNewMock.getRevenueImpactOpportunities.wonOpportunities,
    byDate: generateDataByDate({ startDate, endDate, withRevenue: true }),
  },
})

const yesterday = dayjs().subtract(1, 'day').endOf('day')
// last 90 days
export const defaultDateRange: [Dayjs, Dayjs] = [yesterday.subtract(90, 'day').startOf('day'), yesterday]
