import React, { FC, useMemo } from 'react'

import classNames from 'classnames'

import Button from '@components/Button'
import { ButtonSize, ButtonType, ButtonWeight } from '@components/Button/Button'
import Checkbox from '@components/Checkbox'
import Container from '@components/Container'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import TooltipButton from '@components/TooltipButton/TooltipButton'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ItemState } from '@src/pages/UpgradeAssistant/UpgradeAssistant.contants'

import './UpgradeItem.css'

export interface UpgradeItemProps {
  className?: string
  dataTest?: string
  state: ItemState
  title: string
  subtitle: string
  svgName: SvgNames
  buttonText: string
  label: string
  buttonLink?: string
  onMarkAsComplete: (checked: boolean) => void
  onButtonClick?: () => void
  onViewLogClick?: () => void
  viewLogText?: string
  viewLogDisabled?: boolean
  viewLogDisabledTooltip?: string
}

const rootClass = 'upgrade-item'

const UpgradeItem: FC<UpgradeItemProps> = (props: UpgradeItemProps) => {
  const {
    dataTest = rootClass,
    className = '',
    state,
    title,
    subtitle,
    svgName,
    label,
    buttonText,
    onMarkAsComplete,
    onButtonClick,
    buttonLink,
    viewLogText,
    onViewLogClick,
    viewLogDisabled = false,
    viewLogDisabledTooltip,
  } = props

  const { t } = useTranslation()
  const markedAsComplete = state === ItemState.MARKED_AS_COMPLETED

  const renderLogButtonContent = useMemo(
    () => (
      <>
        <Svg name={SvgNames.resources} type={SvgType.LARGER_ICON} />
        {t(viewLogText || 'UpgradeAssistant.ViewLog')}
      </>
    ),
    [viewLogText]
  )

  const renderLogButton = () =>
    viewLogDisabled ? (
      <TooltipButton
        className={`${rootClass}__log-button`}
        buttonSize={ButtonSize.SMALL}
        buttonType={ButtonType.TEXT}
        weight={ButtonWeight.MEDIUM}
        onClick={() => undefined}
        title={t(viewLogDisabledTooltip)}
        disabled
      >
        {renderLogButtonContent}
      </TooltipButton>
    ) : (
      <Button
        className={`${rootClass}__log-button`}
        buttonSize={ButtonSize.SMALL}
        buttonType={ButtonType.TEXT_TEAL}
        weight={ButtonWeight.MEDIUM}
        onClick={onViewLogClick}
      >
        {renderLogButtonContent}
      </Button>
    )

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__header`}>
        <Typography text={t(label)} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
        <Checkbox dataTest={`${dataTest}-checkbox-${label}`} label={t('Mark as complete')} checked={markedAsComplete} onChange={onMarkAsComplete} />
      </div>
      <Container className={`${rootClass}__content`}>
        <div>
          <Svg name={svgName} type={SvgType.LARGE_ICON} fill={SvgColor.TEXT_TEAL} />
          <div className={`${rootClass}__content-text`}>
            <Typography text={t(title)} type={TextType.BODY_TEXT} tagProps={{ bold: { weight: TextWeight.MEDIUM } }} inline />
            <Typography text={t(subtitle)} type={TextType.BODY_TEXT_SMALL} />
          </div>
        </div>
        <div className={`${rootClass}__content-buttons`}>
          {onViewLogClick && state !== ItemState.NOT_STARTED && renderLogButton()}
          <Button
            className={`${rootClass}__upgrade-button`}
            minimalPadding
            buttonType={ButtonType.OUTLINE}
            disabled={markedAsComplete}
            isLink={!!buttonLink && !markedAsComplete}
            to={buttonLink}
            onClick={onButtonClick}
          >
            {t(buttonText)}
          </Button>
        </div>
      </Container>
    </div>
  )
}

export default UpgradeItem
