import { useCallback, useState } from 'react'

const useInviteUsers = () => {
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false)

  const onCloseInviteModal = useCallback(() => {
    setShowInviteModal(false)
  }, [])

  const onAddUsers = useCallback(() => {
    setShowInviteModal(true)
  }, [])

  return { onCloseInviteModal, showInviteModal, onAddUsers }
}

export default useInviteUsers
