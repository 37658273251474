import {
  AlertFilterCondition,
  Condition,
  ConditionField,
} from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertFilters/utils/AlertFilters.utils'

export const defaultVisitorsCondition: AlertFilterCondition = {
  field: '' as ConditionField,
  condition: Condition.IS,
  value: '',
}

export const CHECK_CONDITIONS_STATUS_EVENT = 'onCheckErrors'
