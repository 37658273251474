import { legacyActonContext } from '@const/globals'
import { CRMConnectorType } from '@utils/hooks/useCRM'

export const goToReconnect = (connectorType: string) => {
  window.open(`${legacyActonContext}/ng-ui/if/_account/connectors.jsp?type=${connectorType}`)
}

export const getConnectorType = (connector: CRMConnectorType): string => {
  const connectors: { [key in CRMConnectorType]: string } = {
    [CRMConnectorType.MS_DYNAMICS]: 'msdynamics',
    [CRMConnectorType.SALESLOGIC]: 'saleslogix',
    [CRMConnectorType.SALESFORCE]: 'salesforce',
    [CRMConnectorType.ZENDESK]: 'zendesk',
    [CRMConnectorType.NETSUITE]: 'netsuite',
    [CRMConnectorType.SUGAR]: 'sugarcrm',
    [CRMConnectorType.NOT_CONNECTED]: '',
  }
  return connectors[connector]
}
