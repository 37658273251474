import React, { ReactNode } from 'react'

import classNames from 'classnames'

import { DEFAULT_FILTERS, MenuActions } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { GetSidebarMenuItemsParams, ListPageCommonState, CustomSource } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { ListingPageSession } from '@complex/ListingPage/Utils/ListingPage.utils'
import { CollapsibleMenuItemWithHeaderData } from '@components/CollapsibleMenu/CollapsibleMenu'
import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import FilterContainer from '@components/FilterContainer/FilterContainer'
import { MenuItemProps } from '@components/Menu/components/MenuItem/MenuItem'
import SelectV2 from '@components/SelectV2/SelectV2'
import SortableFolders from '@components/SortableFolders/SortableFolders'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { SvgNames } from '@components/Svg'
import TagsList from '@components/TagsList/TagsList'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import { getItem } from '@utils/sessionStorage'

const itemRowClass = 'menu-item'

const getSelect = ({ selectCustomSource }: MenuActions, customSources: CustomSource[], selectedCustomSource?: CustomSource): MenuItemProps => {
  const onChange = (value: string) => {
    if (customSources) {
      const item = customSources.find(({ value: selectItemValue }) => value === selectItemValue) as CustomSource
      selectCustomSource?.(item)
    }
  }
  const options = customSources?.map(({ label, value }) => ({ label, value }))
  const selectedOption = options.find(({ value }) => selectedCustomSource?.value === value)

  return {
    content: (
      <SelectV2
        key={`${options?.length}-${selectedCustomSource?.value}`}
        value={selectedOption}
        defaultValue={selectedOption}
        className={`${itemRowClass}__select`}
        insideModal
        isClearable={false}
        onChange={(option) => {
          if (!!option) {
            onChange(option.value as string)
          }
        }}
        options={options}
      />
    ),
    dataTest: 'menu-items-select',
  }
}

const getInfoBlock = (infoBlock: ReactNode): MenuItemProps => {
  return {
    content: <div className={`asset-picker-sidebar__info-block`}>{infoBlock}</div>,
    dataTest: 'menu-items-info-block',
  }
}

const getDefaultFilters = (values: ListPageCommonState, menuActions: MenuActions): CollapsibleMenuItemWithHeaderData => {
  const {
    filterActive,
    selectedCustomSource,
    filterCounts,
    listingPageProps: {
      sidebarProps: { allItemFilter, customDefaultFilters, hasFavorites, hasRecent, hasRecommended, hasCreatedByMe, hideFilterCount },
    },
  } = values

  const filters = [...DEFAULT_FILTERS]
  customDefaultFilters?.forEach((filter) => (filter.position ? filters.splice(filter.position - 1, 0, filter) : filters.push(filter)))

  return {
    header: '',
    content: (
      <FilterContainer
        filters={[selectedCustomSource?.allItemFilter ?? allItemFilter, ...filters]}
        filterCounts={filterCounts}
        activeFilter={filterActive}
        filterAction={menuActions.clickFilter}
        hasRecent={hasRecent}
        hasRecommended={hasRecommended}
        hasCreatedByMe={hasCreatedByMe}
        hasFavorites={hasFavorites}
        hideCount={hideFilterCount}
      />
    ),
    icon: SvgNames.segmentFilters,
    dataTest: 'menu-items-filters',
  }
}

const getFolders = (
  values: ListPageCommonState,
  t: Function,
  menuActions: MenuActions,
  defaultOpenedFolders: number[]
): CollapsibleMenuItemWithHeaderData => {
  const { folders, activeFolderId, fetchFolders } = values

  return {
    content:
      folders.length > 0 || fetchFolders ? (
        <SortableFolders
          className={classNames(`${itemRowClass}__folders`)}
          activeFolder={activeFolderId}
          folders={folders}
          defaultOpenedFolders={defaultOpenedFolders}
          onClick={menuActions.clickFolder}
        />
      ) : (
        <EmptyListing
          imgSrc={StaticImageNames.aocNoFolders}
          text={t(`AssetPicker.Sidebar.EmptyListing.Folders`)}
          size={EmptyListingSize.SMALL}
          textType={TextType.BODY_TEXT_SMALL_LIGHT}
          withoutBorder
        />
      ),
    header: folders.length > 0 ? `${t('Folders')} (${folders.length})` : t('Folders'),
    icon: SvgNames.folderClosed,
    isOpen: getItem(ListingPageSession.FOLDER_MENU_SECTION_CLOSED) !== 'true',
    dataTest: 'menu-items-folders',
  }
}

const getTags = (values: ListPageCommonState, t: Function, menuActions: MenuActions): CollapsibleMenuItemWithHeaderData => {
  const { tags, activeTagId, fetchTags } = values

  return {
    content:
      tags.length > 0 || fetchTags ? (
        <TagsList
          onClick={menuActions.clickTag}
          selectedId={activeTagId}
          tags={tags}
          className={classNames(`${itemRowClass}__tags`, `${itemRowClass}__tags`)}
        />
      ) : (
        <EmptyListing
          imgSrc={StaticImageNames.aocNoTags}
          text={t(`AssetPicker.Sidebar.EmptyListing.Tags`)}
          size={EmptyListingSize.SMALL}
          textType={TextType.BODY_TEXT_SMALL_LIGHT}
          withoutBorder
        />
      ),
    header: tags.length > 0 ? `${t('Tags')} (${tags.length})` : t('Tags'),
    icon: SvgNames.tag,
    isOpen: getItem(ListingPageSession.TAG_MENU_SECTION_CLOSED) !== 'true',
    dataTest: 'menu-items-tags',
  }
}

const rootClass = 'asset-picker-sidebar-container'

export const renderCustomFilterWithCount = (
  children: JSX.Element,
  count?: number,
  key?: string | number,
  hideFilterCount?: boolean,
  className = rootClass
) => (
  <div className={classNames(`${className}__custom-filter`, `asset-picker-sidebar__custom-filter`, `ellip`)} key={key}>
    <div className={classNames(`${className}__custom-filter__name`, `asset-picker-sidebar__custom-filter__name`, `ellip`)}>{children}</div>
    {!hideFilterCount && (
      <Typography
        type={TextType.BODY_TEXT_LIGHT}
        className={classNames(`${className}__custom-filter__count`, `asset-picker-sidebar__custom-filter__count`)}
        text={count !== undefined ? count : ''}
        lineHeight={LineHeight.SMALL}
      />
    )}
  </div>
)

export const getSidebarMenuItems = ({ values, t, menuActions, defaultOpenedFolders }: GetSidebarMenuItemsParams) => {
  const {
    activeSubTypes,
    filterActive,
    filterCounts,
    selectedCustomSource,
    defaultSubTypes,
    listingPageProps: {
      sidebarProps: { renderCustomFilters, allItemFilter, hideFilterCount, hideFolders, hideTags, customSources },
    },
  } = values

  const hasCustomSourceDropdown = customSources && customSources.filter((source) => !source.filterTrigger).length > 1
  const infoBlock = selectedCustomSource?.filterInfoSidebarBlock

  const baseFilters: Partial<CollapsibleMenuItemWithHeaderData>[] = [
    ...(hasCustomSourceDropdown ? [getSelect(menuActions, customSources, selectedCustomSource)] : []),
    ...[getDefaultFilters(values, menuActions)],
    ...(renderCustomFilters
      ? renderCustomFilters({
          activeFilter: filterActive ?? selectedCustomSource?.allItemFilter ?? allItemFilter,
          activeSubTypes,
          defaultSubTypes,
          filterCounts,
          menuActions,
          renderCustomFilterWithCount: (...args) => renderCustomFilterWithCount(...args, hideFilterCount, rootClass),
          t,
        })
      : []),
    ...(!hideFolders ? [getFolders(values, t, menuActions, defaultOpenedFolders)] : []),
    ...(!hideTags ? [getTags(values, t, menuActions)] : []),
    ...(infoBlock ? [getInfoBlock(infoBlock())] : []),
  ]
  return baseFilters
}
