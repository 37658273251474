import React, { FC } from 'react'

import classNames from 'classnames'

import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { dayOfWeek, dayWithOrdinal, minutePrependZero } from '@utils/date'

import { ProgramScheduleType, Schedule } from '../ProgramSettings/ProgramSettings'

import './ProgramSchedule.css'

interface Props {
  schedule: Schedule
  timeZone?: string
  className?: string
  dataTest?: string
}

const rootClass = 'program-schedule'

const ProgramSchedule: FC<Props> = (props: Props) => {
  const { schedule, dataTest = rootClass, className = '', timeZone = '' } = props
  const { scheduleType, weekdaysOnly, interval, hour, minute, ampm, hasEndTime, endHour, endMinute, endAmpm } = schedule

  const { t } = useTranslation()

  const getMeridian = (ampm = 0) => {
    return ampm === 0 ? 'AM' : 'PM'
  }

  const startTimestamp = `${hour}:${minutePrependZero(minute)} ${getMeridian(ampm)} ${timeZone}`
  const endTimestamp = `${endHour}:${minutePrependZero(endMinute)} ${getMeridian(endAmpm)} ${timeZone}`

  const renderDailyText = () => {
    const ROOT_KEY = `Program.Schedule.Daily`
    const subKeys: string[] = []

    subKeys.push(!interval ? `Once` : `Interval`)
    interval && hasEndTime && subKeys.push(`NotAfter`)
    weekdaysOnly && subKeys.push(`WeekdaysOnly`)

    const dailySentence = t(`${ROOT_KEY}.${subKeys.join('.')}`, { startTimestamp, endTimestamp, count: interval })

    return <Typography text={dailySentence} type={TextType.BODY_TEXT_SMALL_LIGHT} />
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Typography
        text={scheduleType == ProgramScheduleType.NEVER ? t('Manually') : t('On a Schedule')}
        type={TextType.BODY_TEXT_LARGE}
        className={`${rootClass}__subheader`}
      />
      {scheduleType == ProgramScheduleType.NEVER && <Typography text={t('Pending contacts added manually')} type={TextType.BODY_TEXT_SMALL_LIGHT} />}
      {scheduleType == ProgramScheduleType.TRIGGERED && (
        <Typography text={'Immediately after the contact enters one of the program source lists'} type={TextType.BODY_TEXT_SMALL_LIGHT} />
      )}
      {scheduleType == ProgramScheduleType.DAILY && renderDailyText()}
      {scheduleType == ProgramScheduleType.WEEKLY && (
        <Typography text={`Check weekly every ${dayOfWeek(t, (interval ?? 0) - 1)} at ${startTimestamp}`} type={TextType.BODY_TEXT_SMALL_LIGHT} />
      )}
      {scheduleType == ProgramScheduleType.MONTHLY && (
        <Typography
          text={`Check monthly at ${startTimestamp} on the ${dayWithOrdinal(interval)} of the month`}
          type={TextType.BODY_TEXT_SMALL_LIGHT}
        />
      )}
    </div>
  )
}

export default ProgramSchedule
