import { MenuActions } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { ListingPageSession } from '@complex/ListingPage/Utils/ListingPage.utils'
import { EmptyListingProps, EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { ItemType } from '@utils/categorization'
import { CustomFilterDefinition, FilterDefinition, FilterTypes, allAccountsFilter, myAccountsFilter, unassignedAccountsFilter } from '@utils/filter'
import { setItem } from '@utils/sessionStorage'

const customFilters: { [key: string]: FilterDefinition } = {
  [FilterTypes.MY_ACCOUNTS]: myAccountsFilter,
  [FilterTypes.UNASSIGNED_ACCOUNTS]: unassignedAccountsFilter,
  [FilterTypes.ALL_ACCOUNTS]: allAccountsFilter,
}

interface GetCustomEmptyListingProps {
  setFilter: (filterDefinition: FilterDefinition, customFilterSelected: boolean, clearSubTypes: boolean) => void
  isAdmin: boolean
  filter?: FilterDefinition
  isStory?: boolean
  search?: string
}

export const getCustomEmptyListingProps = ({
  setFilter,
  filter,
  isStory,
  isAdmin,
  search,
}: GetCustomEmptyListingProps): EmptyListingProps | undefined => {
  if (filter === myAccountsFilter) {
    const buttonProps = isAdmin ? { buttonText: 'View all accounts', buttonOnClick: () => setFilter(allAccountsFilter, false, true) } : {}
    return {
      ...buttonProps,
      imgSrc: StaticImageNames.noProgramHistory,
      headline: search ? `No accounts match your search term ${search}` : `You aren’t managing any accounts`,
      size: EmptyListingSize.MEDIUM,
      text: isAdmin
        ? `Add yourself as a manager on an account to see them here`
        : 'Ask your account administrator to add you as a manager on an account',

      isStory,
    }
  } else if (filter === unassignedAccountsFilter) {
    return {
      imgSrc: StaticImageNames.noProgramHistory,
      headline: search ? `No accounts match your search term ${search}` : 'Great work! All accounts have managers assigned.',
      size: EmptyListingSize.MEDIUM,
      text: 'Only accounts with no assigned managers will show up in this list',
      buttonText: 'View all accounts',
      buttonOnClick: () => setFilter(allAccountsFilter, false, true),
      isStory,
    }
  } else if (filter) {
    return {
      imgSrc: StaticImageNames.emptyChart,
      headline: "There's an issue with your Marketing Network",
      text: 'It looks like your Marketing Network account setup is unfinished. Reach out to your account manager to complete the process',
      isStory,
    }
  }
}

export const getCustomFilterParams = (_activeFilter?: FilterDefinition) => ({ field: '', query: '' })

export const getActiveFilter = (filter: string) => customFilters[filter]

export const getCustomDefaultFilters = (isAccountAdmin: boolean, userId: string, menuActions: MenuActions): CustomFilterDefinition[] => {
  return isAccountAdmin
    ? [
        {
          ...customFilters['My accounts'],
          filterAction: () => {
            setItem(`${ItemType.ACCOUNT}:${ListingPageSession.FILTER}`, JSON.stringify(myAccountsFilter.name))
            setItem(`${ItemType.ACCOUNT}:${ListingPageSession.CUSTOM_FILTER_SELECTED}`, JSON.stringify('true'))
            return menuActions.clickSubType(`accounts-${userId}`)
          },
          position: 2,
          subTypeName: `accounts-${userId}`,
        },
        {
          ...customFilters['No manager assigned'],
          filterAction: () => {
            setItem(`${ItemType.ACCOUNT}:${ListingPageSession.FILTER}`, JSON.stringify(unassignedAccountsFilter.name))
            setItem(`${ItemType.ACCOUNT}:${ListingPageSession.CUSTOM_FILTER_SELECTED}`, JSON.stringify('true'))
            return menuActions.clickSubType(FilterTypes.UNASSIGNED_ACCOUNTS)
          },
          position: 3,
          extraFilter: (newItems: ItemDto[]) => newItems.filter((item) => JSON.parse(item?.item ?? '').parent !== 'true'),
          subTypeName: 'no manager assigned',
        },
      ]
    : []
}
