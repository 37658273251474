import React, { useCallback, useMemo, useState } from 'react'

import classNames from 'classnames'

import Checkbox from '@components/Checkbox'
import { EditModal, EditModalProps } from '@components/EditModal/EditModal'
import { renderLoader } from '@components/Loader/Loader'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { getUUID, useTranslation } from '@utils/const/globals'
import { FileUplaodModalType } from '@utils/formUtils'

import './FormUploadFileModal.css'

const rootClass = 'form-upload-file'

export interface FilesData {
  id: number
  name: string
  date: string
  url?: string
  fullFileName?: string
}

export interface UploadFileProps extends Pick<EditModalProps, 'handleCancel'> {
  handlePrimaryButton: (items: number[], type?: FileUplaodModalType) => void
  type: FileUplaodModalType
  filesData: FilesData[]
  loading?: boolean
  headerText?: string
}

export const FormUploadFileModal = ({ type, filesData, handleCancel, handlePrimaryButton, loading, headerText }: UploadFileProps) => {
  const { t } = useTranslation()
  const [selectedFiles, setSelectedFiles] = useState<number[]>([])

  const isPreviewMode = type === FileUplaodModalType.PREVIEW
  const isDownloadMode = type === FileUplaodModalType.DOWNLOAD
  const title = isPreviewMode ? t('Preview') : t('Download')
  const checkboxCheckState = selectedFiles.length == filesData.length

  const handleFileSelection = useCallback(
    (fileId: number) => {
      if (isPreviewMode) {
        setSelectedFiles([fileId])
      } else {
        setSelectedFiles((prevSelectedFiles) =>
          prevSelectedFiles.includes(fileId) ? prevSelectedFiles.filter((id) => id !== fileId) : [...prevSelectedFiles, fileId]
        )
      }
    },
    [isPreviewMode]
  )

  const handleSelectAll = useCallback(
    (isChecked: boolean) => {
      if (isChecked) {
        setSelectedFiles([])
      } else {
        setSelectedFiles(filesData.map((file) => file.id))
      }
    },
    [filesData]
  )

  const modalProps: EditModalProps['modalProps'] = useMemo(
    () => ({
      header: {
        content: (
          <Typography
            text={headerText || t('UploadFile.title', { name: title, context: isDownloadMode ? 'multiple' : '' })}
            type={TextType.DATA_CARD_MODAL_HEADER}
          />
        ),
      },
      body: {
        content: (
          <>
            <Typography text={t(`UploadFile.${isDownloadMode ? 'Download' : 'Preview'}`)} type={TextType.BODY_TEXT_LIGHT} className="push-up-x4" />
            {filesData.map((file) => {
              const isSelected = selectedFiles.includes(file.id)

              return (
                <button className={classNames(`${rootClass}__files`)} key={getUUID()} onClick={() => handleFileSelection(file.id)}>
                  <div className={classNames(`${rootClass}__files__wrapper`, { [`${rootClass}__files--selected`]: isSelected })}>
                    <Svg className={classNames(`${rootClass}__file-svg`)} name={SvgNames.blankMessage} type={SvgType.ICON} />
                    <div className={classNames(`${rootClass}__file`)}>
                      <TextWithTooltipOnEllip
                        typographyProps={{
                          text: file.name,
                          lineHeight: LineHeight.MEDIUM,
                          weight: TextWeight.MEDIUM,
                          className: classNames(`${rootClass}__file-title`),
                        }}
                      />
                      <Typography
                        className={classNames(`${rootClass}__file-date`)}
                        text={file.date}
                        type={TextType.BODY_TEXT_LIGHT}
                        lineHeight={LineHeight.MEDIUM_SMALL}
                      />
                    </div>
                  </div>
                </button>
              )
            })}
          </>
        ),
      },
      footer: {
        props: {
          customContent: isDownloadMode && (
            <div className={`${rootClass}__selection-button`}>
              <Checkbox
                indeterminate={!checkboxCheckState && !!selectedFiles.length}
                checked={checkboxCheckState}
                onChange={() => handleSelectAll(checkboxCheckState || !!selectedFiles.length)}
                dataTest="select-all"
              />
              <Typography text={t('Select all')} type={TextType.BODY_TEXT} lineHeight={LineHeight.MEDIUM_LARGE} />
            </div>
          ),
        },
      },
    }),
    [checkboxCheckState, filesData, handleFileSelection, handleSelectAll, isDownloadMode, selectedFiles, t, title]
  )

  return loading ? (
    renderLoader('loader--white-background')
  ) : (
    <EditModal
      useScroll
      modalProps={modalProps}
      canSubmit={!!selectedFiles.length}
      dataTest={rootClass}
      handleCancel={handleCancel}
      handlePrimaryButton={() => handlePrimaryButton(selectedFiles, type)}
      footerActionButtonLabel={title}
    />
  )
}
