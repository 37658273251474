import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import axios from 'axios'

import { useApolloClient, useQuery } from '@apollo/client'
import { SwitchOptions } from '@components/Switch/Switch'
import { renderTooltipHeader } from '@components/Table/components/tableColumns'
import { TableColumn } from '@components/Table/Table'
import { ChartSettingState, defaultChartSettingState } from '@const/Chart.constants'
import { legacyActonContext, rootContext } from '@const/globals'
import getEmailPerformanceResponse from '@graphql/queries/getEmailPerformanceResponse'
import getMessageRollupReadyResponse from '@graphql/queries/getMessageRollupReadyResponse'
import {
  EmailPerformanceResponse,
  GetMessageRollupReadyResponseQuery,
  QueryGetEmailPerformanceResponseArgs,
  QueryGetMessageRollupReadyResponseArgs,
} from '@graphql/types/query-types'
import EmailSMSPerformance from '@src/pages/programs/dashboard/components/EmailSMSPerformance/EmailSMSPerformance'
import {
  emailColumns,
  emailColumnsV2,
  getEmailHeaderStrings,
} from '@src/pages/programs/dashboard/components/EmailSMSPerformance/EmailSMSPerformance.constants'
import {
  EmailSMSPerformanceFunctions,
  getDefaultSort,
  getSortColumn,
  helpMessage,
  processProgramMessageCountsWithTotals,
} from '@src/pages/programs/dashboard/components/EmailSMSPerformance/EmailSMSPerformance.utils'
import { sortEnterExitData } from '@src/pages/programs/dashboard/components/ProgramPerformance/components/ProgramPerformanceDetail/components/ProgramPerformanceChartContainer/ProgramPerformanceChartContainer.utils'
import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'
import { Row } from '@tanstack/react-table'
import { DateType, DEFAULT_DATE_QUANTITIES, getDateString } from '@utils/date'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'
import useUserSettings from '@utils/hooks/useUserSettings'
import { logNewRelicError } from '@utils/new-relic.utils'
import { SortByState } from '@utils/sms.utils'
import { download } from '@utils/utils'

export interface EmailSMSPerformanceData {
  messageId: string
  STEP_ID: (string | undefined)[]
  SUBJECT: string
  SENT: number
  delivered: string[]
  opened: string[]
  clicked: string[]
  clickToOpen: string
  optOut: string[]
  isWinner: boolean
  abThreshold: number
}

interface EmailSMSPerformanceContainerProps {
  emailPerformance: boolean
}

interface EmailSMSPerformanceContainerState {
  table: {
    data: any[]
    tableType: SwitchOptions
    canPaginate: boolean
    totalRecords: number
    pageIndex: number
    pageSize: number
    sortBy: SortByState[]
  }
  chart: {
    dateType: DateType
    dateTypeQuantity: number
    categories: any[]
    fields: any[]
  }
  showEmptyMessage: boolean
  firstLoad: boolean
  chartLoading: boolean
  chartSettings: ChartSettingState
}

const defaultSortBy: SortByState = {
  id: 'STEP_ID',
  desc: false,
}

const EmailSMSPerformanceContainerState: EmailSMSPerformanceContainerState = {
  table: {
    data: [],
    tableType: SwitchOptions.PERCENT,
    canPaginate: true,
    totalRecords: 0,
    pageIndex: 0,
    pageSize: 10,
    sortBy: [defaultSortBy],
  },
  chart: {
    dateType: DateType.MONTH,
    dateTypeQuantity: DEFAULT_DATE_QUANTITIES[DateType.MONTH],
    categories: [],
    fields: [],
  },
  showEmptyMessage: false,
  firstLoad: true,
  chartLoading: true,
  chartSettings: defaultChartSettingState,
}

const baseUrl = `${legacyActonContext}/internalapi/program`

const EmailSMSPerformanceContainer: FC<EmailSMSPerformanceContainerProps> = (props: EmailSMSPerformanceContainerProps) => {
  const {
    values: { program, programUrlId },
    refreshProgram,
  } = useContext(ProgramManagerContext)
  const history = useHistory()
  const location = useLocation()
  const locationState = location?.state ?? {}
  const [state, setState] = useState<EmailSMSPerformanceContainerState>({
    ...EmailSMSPerformanceContainerState,
    table: { ...EmailSMSPerformanceContainerState.table, sortBy: getDefaultSort(locationState) },
  })

  const update = useDeepUpdate(setState)

  const [columns, setColumns] = useState<TableColumn[]>(emailColumns)
  const { userSettings, setUserSetting } = useUserSettings()
  const [enableTableV2, setEnableTableV2] = useState(true)

  useEffect(() => {
    if (state.chartLoading && userSettings !== undefined) {
      const getChart = userSettings['programManagerCharts']
      const { email } = getChart
      update({
        chart: {
          dateType: email.dateType,
          dateTypeQuantity: email.dateTypeQuantity,
        },
        chartSettings: getChart,
        chartLoading: false,
      })
    }
  }, [userSettings, update])

  const client = useApolloClient()
  const {
    data,
    error: pageError,
    loading,
    refetch,
  } = useQuery<EmailPerformanceResponse, QueryGetEmailPerformanceResponseArgs>(getEmailPerformanceResponse, {
    client: client as any,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      programId: programUrlId,
      unitsAgo: state.chart.dateTypeQuantity,
      groupByString: state.chart.dateType.toUpperCase(),
      forceRefresh: true,
      perPage: state.table.pageSize,
      currentPage: state.table.pageIndex,
      sortColumn: getSortColumn(state.table.sortBy[0].id),
      sortDirection: state.table.sortBy[0].desc ? 'DESC' : 'ASC',
      isNumericForSorting: true,
    },
  })

  const setColumnHeaders = () => {
    const updatedColumns = columns.map((column) => {
      const [title, tooltip] = getEmailHeaderStrings(column.accessor, state.table.tableType === SwitchOptions.PERCENT)
      const Header = tooltip ? { Header: renderTooltipHeader(title, tooltip) } : {}
      return { ...column, ...Header }
    })
    setColumns(updatedColumns)
  }

  useEffect(() => {
    if (!loading && !pageError && !state.chartLoading && data) {
      updateData(data)
    }
    if (pageError) {
      update({ showEmptyMessage: true })
    }
    setColumnHeaders()
  }, [loading, pageError, state.chartLoading, data, update])

  useEffect(() => {
    if (data) {
      setColumnHeaders()
      updateData(data)
    }
  }, [state.table.tableType])

  const updateData = (data: any) => {
    const {
      getEmailPerformanceResponse: { programMessageCountsWithTotals, programMessageSends },
    } = data
    const tableData = processProgramMessageCountsWithTotals(programMessageCountsWithTotals, state.table.tableType, program)
    const sortedProgramMessageSends = sortEnterExitData(programMessageSends.dateToCount, state.chart.dateType)
    const categories = sortedProgramMessageSends.map((entry: any) => getDateString(entry.date, state.chart.dateType))
    const fields = [
      {
        name: 'All Emails',
        color: '#A184DD',
        data: sortedProgramMessageSends.map((day: { count: string }) => parseInt(day.count)),
      },
    ]

    update({
      table: {
        data: tableData,
        totalRecords: programMessageCountsWithTotals.totalRecords,
      },
      chart: { categories, fields },
      showEmptyMessage: !tableData.length,
      firstLoad: false,
    })
  }

  const onRefresh = () => {
    refreshProgram()
    refetch()
  }

  const handleDateChange = useCallback(
    (dateType: DateType, dateTypeQuantity: number) =>
      update({
        chart: {
          dateType,
          dateTypeQuantity: isNaN(dateTypeQuantity) ? 0 : dateTypeQuantity,
        },
        chartSettings: { email: { dateType, dateTypeQuantity } },
      }),
    [update]
  )

  useEffect(() => {
    if (!state.chartLoading) {
      setUserSetting('programManagerCharts', state.chartSettings)
    }
  }, [state.chartSettings])

  const handleSwitchChange = useCallback((button: SwitchOptions) => update({ table: { tableType: button } }), [update])

  const onSort = (sortBy: SortByState[]) => {
    const validSortBy = sortBy.length > 0 ? sortBy : getDefaultSort(locationState)

    const updatedLocationState = {
      ...(history.location.state as Object),
      emailTabSortColumn: validSortBy[0].id,
      emailTabSortDesc: validSortBy[0].desc,
    }

    history.replace(location.pathname, updatedLocationState)

    update({ table: { sortBy: validSortBy } })
  }

  const fetchData = useCallback((pageIndex: number, pageSize: number) => update({ table: { pageIndex, pageSize } }), [update])

  const handleDownload = (url: string) => {
    try {
      download(url, program.name)
    } catch (e) {
      logNewRelicError(e)
    }
  }

  const messageReport = () => {
    const regexProgName = program.name.replace(/[^\w ]/g, '')
    const rollupUrl = '/message/rollup'
    axios
      .get(`${baseUrl}${rollupUrl}/generate`, {
        params: {
          programid: programUrlId,
          programname: regexProgName,
        },
      })
      .then((data) => handleDownload(`${baseUrl}${rollupUrl}?xml=${data.data}`))
      .catch((e) => logNewRelicError(e))
  }

  const recipientReport = () => {
    const messageIds = state.table.data.map((x) => x.messageId)

    client
      .query<GetMessageRollupReadyResponseQuery, QueryGetMessageRollupReadyResponseArgs>({
        query: getMessageRollupReadyResponse,
        fetchPolicy: 'network-only',
        variables: {
          programName: program.name,
          msgIds: messageIds,
        },
      })
      .then((resp) => handleDownload(`${baseUrl}/recipient/report/download?xml=${resp.data.getMessageRollupReadyResponse.xml}`))
  }

  const openReport = (row: any) => {
    if (row.values.sent !== '0') {
      const messageId = row.original.messageId
      window.location.href = `${rootContext}/classic/if/messages/messageSentFrame.jsp?id=${programUrlId}&msgid=${messageId}&reactBack=1&tab=2`
    }
  }

  const openReportV2 = (row: Row<EmailSMSPerformanceData>) => {
    if (row.original.SENT !== 0) {
      const messageId = row.original.messageId
      window.location.href = `${rootContext}/classic/if/messages/messageSentFrame.jsp?id=${programUrlId}&msgid=${messageId}&reactBack=1&tab=2`
    }
  }

  return (
    <EmailSMSPerformance
      helpMessage={helpMessage}
      onSwitchChartType={handleSwitchChange}
      onDateChange={handleDateChange}
      {...EmailSMSPerformanceFunctions}
      {...props}
      lastUpdated={program.lastUpdated}
      title={'All Emails'}
      dateType={state.chart.dateType}
      dateTypeQuantity={state.chart.dateTypeQuantity}
      fields={state.chart.fields}
      categories={state.chart.categories}
      tableTitle={'Individual Emails'}
      tableSubTitle={'Track the performance of each unique message. Contacts who receive a message multiple times will count once in this report.'}
      chartType={state.table.tableType}
      data={state.table.data}
      //@ts-ignore should be removed after switching to tableV2
      columns={enableTableV2 ? emailColumnsV2 : columns}
      canSwitchChartType={false}
      showEmptyMessage={state.showEmptyMessage}
      onRefresh={onRefresh}
      loading={(loading && state.firstLoad) || state.chartLoading}
      chartLoading={(loading && !state.firstLoad) || state.chartLoading}
      onSort={onSort}
      sortBy={state.table.sortBy}
      pageSize={state.table.pageSize}
      pageIndex={state.table.pageIndex}
      canPaginate={state.table.canPaginate}
      fetchData={fetchData}
      controlledPageCount={Math.ceil(state.table.totalRecords / state.table.pageSize)}
      messageReport={messageReport}
      recipientReport={recipientReport}
      openReport={enableTableV2 ? openReportV2 : openReport}
      enableTableV2={enableTableV2}
      setEnableTableV2={setEnableTableV2}
    />
  )
}

export default EmailSMSPerformanceContainer
