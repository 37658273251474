import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import { ListingPageItem } from '@complex/ListingPage/Context/ListingPageCommon.context'
import Button, { ButtonType } from '@components/Button'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Radio from '@components/Radio'
import { RadioType } from '@components/Radio/Radio'
import { Status } from '@components/StatusToast/StatusToast'
import Typography, { LineHeight, ModalBodyStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { FormSubmissionSummaryDto } from '@graphql/types/microservice/entity-join-types'
import { WebinarType } from '@src/pages/listingPages/FormsJoinView/components/DetailsCardWrapper/utils/DetailsCardWrapper.utils'
import { useFormsJoinViewListingPageEntityJoinRequests } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.entityJoin.graphQL'
import { ItemType } from '@utils/categorization'
import { relativeDate } from '@utils/date'

import './FormSelectorModal.css'

export enum FormSelectorType {
  EDIT = 'EDIT',
  PREVIEW = 'PREVIEW',
  VIEW_REPORT = 'VIEW_REPORT',
}

interface FormSelectorModalProps {
  className?: string
  dataTest?: string
  type: FormSelectorType
  list: ListingPageItem
  onClose: () => void
  onAction: (selectedForm: FormSubmissionSummaryDto, totalForms: FormSubmissionSummaryDto[]) => void
  setStatusToast: (message: string, status: Status) => void
  isOpen: boolean
  showPreview?: boolean
}

const rootClass = 'form-selector-modal'

const FormSelectorModal: FC<FormSelectorModalProps> = (props: FormSelectorModalProps) => {
  const { dataTest = rootClass, className = '', onAction, onClose, isOpen, type = FormSelectorType.EDIT, list, setStatusToast, showPreview } = props
  const [forms, setForms] = useState<FormSubmissionSummaryDto[]>([])
  const [selectedForm, setSelectedForm] = useState<FormSubmissionSummaryDto | undefined>()
  const [loading, setLoading] = useState(true)

  const { t } = useTranslation()

  const { getRelatedForms, getWebinarRelatedFormsRequest } = useFormsJoinViewListingPageEntityJoinRequests()

  const onSubmitClick = () => {
    if (selectedForm) {
      onAction(selectedForm, forms)
    }
  }

  const getForms = () => {
    if (list.subTypeDTO && list.subTypeDTO[0]) {
      const itemType = list.subTypeDTO[0].itemType
      if (itemType === ItemType.WEBINAR_SUBMISSION) {
        const webinarId = 'webinarId' in list ? (list.webinarId as string) : ''
        const webinarType = (list.subTypeDTO[0].name as string).toUpperCase() as WebinarType
        if (webinarType) {
          return getWebinarRelatedFormsRequest(webinarId, webinarType)
        }
      }
    }
    return getRelatedForms(list.externalId as string)
  }

  useEffect(() => {
    getForms()
      .then((forms) => {
        if (forms.length === 1) {
          onAction(forms[0], forms)
          onClose()
        } else {
          setForms(forms)
        }
      })
      .catch(() => {
        setStatusToast('Something went wrong on our end. Please try again.', Status.FAIL)
        onClose()
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const contentByType: { [key in FormSelectorType]: any } = {
    [FormSelectorType.EDIT]: { header: 'Edit Form', subheader: 'Select a form to edit.', buttonText: 'Edit' },
    [FormSelectorType.VIEW_REPORT]: { header: 'View Form Report', subheader: 'Select a form to view the report.', buttonText: 'View' },
    [FormSelectorType.PREVIEW]: { header: 'Preview Form', subheader: 'Select a form to preview.', buttonText: 'Preview' },
  }
  const { header, subheader, buttonText } = contentByType[type]

  const renderRow = (form: FormSubmissionSummaryDto) => {
    const isSelected = form.formId === selectedForm?.formId
    const onSelectForm = () => setSelectedForm(form)
    return (
      <div
        role={'radio'}
        aria-checked={isSelected}
        data-test={`${dataTest}-option`}
        tabIndex={0}
        onKeyDown={(keyDownEvent) => {
          if (keyDownEvent.key === ' ' || keyDownEvent.key === 'Enter') {
            onSelectForm()
          }
        }}
        key={`option-${form.formId}`}
        className={classNames(`${rootClass}__row`, { [`${rootClass}__row-selected`]: isSelected })}
        onClick={onSelectForm}
      >
        <Typography className={`${rootClass}__row-form-name`} text={form.formName} weight={TextWeight.MEDIUM} />
        <Typography
          text={`${t(`Last updated:`)} ${relativeDate(form.updatedTime ?? new Date(), true)}`}
          type={TextType.BODY_TEXT_SMALL_LIGHT}
          lineHeight={LineHeight.MEDIUM_SMALL}
        />
        <Radio checked={isSelected} className={`${rootClass}__row-radio-button`} onChange={onSelectForm} type={RadioType.CHECKMARK} />
      </div>
    )
  }

  const modalHeader = (
    <ModalHeader headerType={ModalHeaderType.List} className={`${rootClass}__header`}>
      {t(header)}
    </ModalHeader>
  )

  if (loading || forms.length === 1 || showPreview) {
    return null
  }

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={modalHeader}>
      <ModalBody className={`${rootClass}__body`}>
        {forms.length === 0 ? (
          <div className={`${rootClass}__empty-state`}>
            <Typography text={t(`All forms have been deleted.`)} type={TextType.BODY_TEXT_LIGHT} />
          </div>
        ) : (
          <>
            <Typography className={`${rootClass}__subheader`} text={t(subheader)} {...ModalBodyStyle} />
            {forms.map(renderRow)}
          </>
        )}
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.List} className={`${rootClass}__footer`}>
        <Button buttonType={ButtonType.TERTIARY} onClick={onClose} dataTest={`${dataTest}-button-tertiary`}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} onClick={onSubmitClick} dataTest={`${dataTest}-button-primary`} disabled={!selectedForm}>
          {t(buttonText)}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default FormSelectorModal
