import { gql } from '@apollo/client'

export default gql`
  query getBeeRecentlyTemplates($filter: [String], $search: String, $beeTemplateIds: [String]) {
    getBeeRecentlyTemplates(filter: $filter, search: $search, beeTemplateIds: $beeTemplateIds) {
      categories
      publishAt
      searchTerms
      templateId
      thumbnailLargeUrl
      thumbnailUrl
      title
    }
  }
`
