import { FetchResult, ApolloQueryResult } from '@apollo/client'
import { CustomColumn } from '@components/MessagePicker/MessagePicker'
import { CreateNewBlankMessageMutation } from '@graphql/types/mutation-types'
import {
  MessageFolders,
  MessageListingQuery,
  ProgramMessagesQuery,
  ScheduledMessagesQuery,
  SentMessageDatesQuery,
  SentMessagesQuery,
} from '@graphql/types/query-types'
import { Folder, MessageFolder } from '@interface/foldersLists/Folder'
import { Message } from '@interface/messages/Message'
import { logError } from '@utils/env'
import { getProgramMessageName } from '@utils/program/program'
import windowUtils from '@utils/window'

import { Props, State } from './MessagePickerModal'

export enum MessageType {
  BLANK = 'BLANK',
  BEE_BLANK = 'BEE_BLANK',
  TEMPLATE = 'TEMPLATE',
  SENT = 'SENT',
  SCHEDULED = 'SCHEDULED',
  DRAFT = 'DRAFT_MESSAGE',
  PROGRAM = 'AUTOMATED_PROGRAM',
}

export const MESSAGE_TYPES = [
  {
    type: MessageType.SCHEDULED,
    text: 'Scheduled Messages',
  },
  {
    type: MessageType.TEMPLATE,
    text: 'Message Templates',
  },
  {
    type: MessageType.DRAFT,
    text: 'Draft Messages',
  },
  {
    type: MessageType.SENT,
    text: 'Sent Messages',
  },
  {
    type: MessageType.PROGRAM,
    text: 'Program Messages',
  },
]

export enum BodySection {
  LOADING,
  PICKER,
  FOLDERS,
  PREVIEW,
}

export interface FoldersAndListings {
  folders: Folder[]
  listings: { [key: number]: Message[] }
}

export interface FoldersAndFolders {
  folders: Folder[]
  listings: { [key: number]: MessageFolder[] }
}

export interface MessagePickerModalUtils {
  loadProgramMessagesSuccess: (data: ApolloQueryResult<ProgramMessagesQuery>) => void
  getCustomColumn: () => CustomColumn | undefined
  mapMessageListingToFolder: (data: MessageListingQuery) => FoldersAndListings
  mapFolderMessageListingToFolder: (messageFolders: MessageFolders[]) => FoldersAndFolders
  loadScheduledMessagesSuccess: (data: ApolloQueryResult<ScheduledMessagesQuery>) => void
  loadSentMessagesSuccess: (data: ApolloQueryResult<SentMessagesQuery>) => void
  loadMessageListingsSuccess: (
    data: ApolloQueryResult<MessageListingQuery>,
    enableEmailDraftsReact?: boolean,
    hasEmailTemplatesReact?: boolean
  ) => void
  loadBlankMessageError: (error: Error) => void
  loadBlankMessageSuccess: (
    data: FetchResult<CreateNewBlankMessageMutation, Record<string, any>, Record<string, any>>,
    hasReactWrapper?: boolean,
    beeComposer?: boolean
  ) => void
  loadMessagesError: (error: Error) => void
  loadSentMessagesDatesSuccess: (data: ApolloQueryResult<SentMessageDatesQuery>) => void
  loadFoldersError(error: Error): void
  getMessageTypeOptions(): { type: MessageType; text: string }[]
  updateSelectedMessages(selectedMessages: Message[]): void
  isLoading(): boolean
  getBodySection(): BodySection
}

export const getMessagePickerModalUtil = (props: Props, state: State, setState: (state: State) => void, t: Function) => ({
  getCustomColumn: function () {
    switch (state.messageType) {
      case MessageType.DRAFT:
      case MessageType.TEMPLATE:
        return CustomColumn.LAST_EDITED
      case MessageType.SENT:
        return CustomColumn.SENT
      case MessageType.SCHEDULED:
        return CustomColumn.SCHEDULED
    }
  },
  mapFolderMessageListingToFolder: function (messageFolders: MessageFolders[]): FoldersAndFolders {
    const listings: { [key: string]: MessageFolder[] } = {}
    const folders = messageFolders.map((messageListing) => {
      if (messageListing.folders) {
        listings[messageListing.id] = messageListing.folders
      }
      return {
        id: messageListing.id,
        name: messageListing.name,
        count: messageListing.count,
        isDefault: messageListing.isDefault,
        isContacts: false,
      }
    })
    return {
      folders,
      listings,
    }
  },
  mapMessageListingToFolder: function (data: MessageListingQuery): FoldersAndListings {
    const listings: { [key: string]: Message[] } = {}
    const folders = data.messageListing.map((messageListing) => {
      if (messageListing.entries) {
        listings[messageListing.id] = messageListing.entries.map((message) => ({
          ...message,
        }))
      }
      return {
        id: messageListing.id,
        name: messageListing.name,
        count: messageListing.count,
        isDefault: messageListing.isDefault,
        isContacts: false,
      }
    })
    return {
      folders,
      listings,
    }
  },
  loadBlankMessageSuccess: function (
    data: FetchResult<CreateNewBlankMessageMutation, Record<string, any>, Record<string, any>>,
    hasReactWrapper?: boolean,
    beeComposer?: boolean
  ) {
    if (!data.data) {
      throw new Error('No data returned for create new message')
    }
    props.onSubmitMessage([
      {
        id: data.data.createNewBlankMessage.id ?? '',
        name: getProgramMessageName(data.data.createNewBlankMessage),
        beeComposer: data.data.createNewBlankMessage.isBeeComposer,
        isNewComposer: true,
        __typename: 'ProgramMessage',
      },
    ])
    beeComposer
      ? windowUtils.openBeeComposerWindow(data.data.createNewBlankMessage.id, 'program')
      : windowUtils.openComposerWindow(data.data.createNewBlankMessage.id, hasReactWrapper)
  },
  loadBlankMessageError: function (error: Error) {
    logError(error)
    setState({
      ...state,
      messageType: undefined,
    })
    alert(t('An error occurred while trying to create a blank message'))
  },
  loadSentMessagesDatesSuccess: function (data: ApolloQueryResult<SentMessageDatesQuery>) {
    const folders = data.data.sentMessageDates.map((date) => ({
      id: date.code,
      name: date.name,
      count: date.count,
      isDefault: false,
      isContacts: false,
    }))
    setState({
      ...state,
      folders: {
        folders,
      },
      currentFolder: folders.length > 0 ? folders[0] : undefined,
    })
  },
  loadFoldersError: function (error: Error) {
    logError(error)
    setState({
      ...state,
      folders: {
        error: true,
      },
    })
  },
  loadScheduledMessagesSuccess: function (data: ApolloQueryResult<ScheduledMessagesQuery>) {
    const scheduledMessages = data.data.scheduledMessages
    const folder = {
      id: '0',
      name: t('All Messages'),
      count: scheduledMessages.length,
      isDefault: true,
      isContacts: false,
    }
    setState({
      ...state,
      folders: {
        folders: [folder],
      },
      currentFolder: folder,
      messages: {
        messages: scheduledMessages,
      },
    })
  },
  loadProgramMessagesSuccess: function (data: ApolloQueryResult<ProgramMessagesQuery>) {
    const folderLists = this.mapFolderMessageListingToFolder(data.data.programMessages as MessageFolders[])
    const defaultFolder = folderLists.folders.find((folder) => folder.isDefault)
    setState({
      ...state,
      folders: {
        folders: folderLists.folders,
      },
      currentFolder: defaultFolder,
      messageFolderListings: folderLists.listings,
    })
  },
  loadMessageListingsSuccess: function (
    data: ApolloQueryResult<MessageListingQuery>,
    enableEmailDraftsReact?: boolean,
    hasEmailTemplatesReact?: boolean
  ) {
    const folderLists = this.mapMessageListingToFolder(data.data)

    const defaultFolder = folderLists.folders.find((folder) => {
      if ((hasEmailTemplatesReact || enableEmailDraftsReact) && folder.id === 'null') return folder
      return folder.isDefault
    })

    setState({
      ...state,
      folders: {
        folders: folderLists.folders,
      },
      currentFolder: defaultFolder,
      messageListings: folderLists.listings,
    })
  },
  loadSentMessagesSuccess: function (data: ApolloQueryResult<SentMessagesQuery>) {
    setState({
      ...state,
      messages: {
        messages: data.data.sentMessages.map((sentMessage) => ({
          ...sentMessage,
          id: sentMessage.msgId,
        })),
      },
    })
  },
  loadMessagesError: function (error: Error) {
    logError(error)
    setState({
      ...state,
      messages: {
        error: true,
      },
    })
  },
  getMessageTypeOptions: function () {
    if (props.allowedMessageCategories) {
      return MESSAGE_TYPES.filter((messageType) => props.allowedMessageCategories?.includes(messageType.type))
    }
    return MESSAGE_TYPES
  },
  updateSelectedMessages: function (selectedMessages: Message[]) {
    setState({
      ...state,
      selectedMessages,
    })
  },
  isLoading: function () {
    return !!state.messageType && [MessageType.BLANK, MessageType.BEE_BLANK].includes(state.messageType)
  },
  getBodySection: function () {
    if (this.isLoading()) {
      return BodySection.LOADING
    } else if (state.previewingMessage) {
      return BodySection.PREVIEW
    } else if (state.messageType) {
      return BodySection.FOLDERS
    }
    return BodySection.PICKER
  },
})
