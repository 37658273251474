import React, { FC } from 'react'

import Checkbox from '../../Checkbox/index'

type Props = {
  indeterminate?: any
  dataTest?: string
  [key: string]: any
}

const IndeterminateCheckbox: FC<Props> = ({ indeterminate = false, dataTest = '', ...rest }: Props) => {
  return <Checkbox indeterminate={indeterminate} dataTest={dataTest} {...rest} />
}

export default IndeterminateCheckbox
