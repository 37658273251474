import React from 'react'

import { TFunction } from 'i18next'

import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import Pill, { PillSize, PillType } from '@components/Pill/Pill'
import Tooltip from '@components/Tooltip/Tooltip'
import { ItemDto } from '@graphql/types/query-types'

export interface EmailDto extends ItemDto {
  name: string
  beeComposer: boolean
}

export const renderLegacyEmailPill = (beeComposer: boolean, t: TFunction, rootClass: string) => {
  if (!beeComposer) {
    return <Pill text={t('Legacy')} type={PillType.GRAY} size={PillSize.EXTRA_SMALL} noMargin className={`${rootClass}__message-row-pill`} />
  }
  return null
}

export const renderEmailNameCell = (original: ItemDto, search: string, rootClass: string, t: TFunction, showPill = true) => {
  const item = original as EmailDto
  const itemName = item?.name ?? ''
  const pill = showPill ? renderLegacyEmailPill(item.beeComposer, t, rootClass) : null

  return (
    <div className={`${rootClass}__item-name ellip`}>
      <Tooltip
        trigger={
          <div className={`${rootClass}__item-name-typography`}>
            <div className={'ellip'}>{search ? renderBoldTextOnMatch(itemName, search) : itemName}</div>
          </div>
        }
        minimalPadding
        ellipOnTrigger
      >
        {itemName}
      </Tooltip>
      {pill}
    </div>
  )
}
