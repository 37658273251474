import React from 'react'

import classNames from 'classnames'
import { TFunction } from 'i18next'

import { ApolloClient } from '@apollo/client'
import { getFolderById, deleteFolderById } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { Status } from '@components/StatusToast/StatusToast'
import { TOAST_TEXT_CLASSNAME } from '@components/Toast/Toast'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import deleteFolder from '@graphql/microservices/categorization/deleteFolder'
import getFolders from '@graphql/microservices/categorization/getFolders'
import getItemsInFolder from '@graphql/microservices/categorization/getItemsInFolder'
import moveItems from '@graphql/microservices/categorization/moveItems'
import renameFolder from '@graphql/microservices/categorization/renameFolder'
import setFolder from '@graphql/microservices/categorization/setFolder'
import {
  DeleteFolderMutation,
  DeleteFolderMutationVariables,
  FolderDto,
  FolderDtoInput,
  GetFoldersQuery,
  GetFoldersQueryVariables,
  GetItemsInFolderQuery,
  GetItemsInFolderQueryVariables,
  MoveItemsMutation,
  MoveItemsMutationVariables,
  RenameFolderMutation,
  RenameFolderMutationVariables,
  SetFolderMutation,
  SetFolderMutationVariables,
} from '@graphql/types/microservice/categorization-types'
import { Folder } from '@interface/Folder'
import { SetContainerValues } from '@interface/ListPage.context'
import { SyncedSegmentDetailsRequestType } from '@src/pages/ContactSegments/components/AddContactsFromCRM/GraphQL/AddContactsFromCRMRequests.crm.graphQL'
import { GetCRMSegmentsInfoRequestType } from '@src/pages/ContactSegments/GraphQL/ContactSegments.crm.graphQL'
import { initSegmentLoading, setSegments } from '@src/pages/ContactSegments/utils/ContactSegmentsContainerUtils'
import { CONTACT_SEGMENTS_CURRENT_SECTION } from '@src/pages/ContactSegments/utils/ContactSegmentsSession.utils'
import { filterNotEmptyArray } from '@utils/array'
import { ItemType } from '@utils/categorization'
import { ContactSegmentsSession, ContactSegmentsState, Segment } from '@utils/contactSegments/contactSegments.utils'
import { clearMenuSections } from '@utils/contactSegments/context/ContactSegmentsContext'
import { buildComplexFoldersTreeHeader, sortFoldersByName } from '@utils/folderUtils'
import { EMPTY_LISTING_OPTIONS } from '@utils/ListPage.context.default'
import { setItem } from '@utils/sessionStorage'

export const createFolderUtils = (
  setContainerValues: SetContainerValues<ContactSegmentsState>,
  containerValues: ContactSegmentsState,
  client: ApolloClient<any>,
  folder: FolderDto,
  t: Function
) => {
  client
    .mutate<SetFolderMutation, SetFolderMutationVariables>({
      mutation: setFolder,
      variables: {
        folder: folder as FolderDtoInput,
        type: ItemType.SEGMENT,
      },
    })
    .then(() => {
      setContainerValues({
        ...containerValues,
        statusToast: {
          statusMessage: (
            <div className={classNames(TOAST_TEXT_CLASSNAME, `contact-segments__toast`)}>
              <Typography className={'ellip'} text={folder?.name} weight={TextWeight.MEDIUM} />
              <Typography text={` ${t('has been added')}`} />
            </div>
          ),
          showStatusToast: true,
          status: Status.SUCCESS,
        },
        hasToExpandFolders: [folder.parentId],
        creatingFolder: false,
      })
      getFoldersUtils(setContainerValues, client)
    })
}

export const renameFolderUtils = (
  setContainerValues: SetContainerValues<ContactSegmentsState>,
  containerValues: ContactSegmentsState,
  client: ApolloClient<any>,
  folder: FolderDto,
  t: Function
) => {
  client
    .mutate<RenameFolderMutation, RenameFolderMutationVariables>({
      mutation: renameFolder,
      variables: {
        newName: folder.name,
        type: ItemType.SEGMENT,
        folderId: folder.id,
      },
    })
    .then(() => {
      setContainerValues({
        ...containerValues,
        statusToast: {
          statusMessage: (
            <div className={classNames(TOAST_TEXT_CLASSNAME, `contact-segments__toast`)}>
              <Typography className={'ellip'} text={folder?.name} weight={TextWeight.MEDIUM} />
              <Typography text={` ${t('has been renamed')}`} />
            </div>
          ),
          showStatusToast: true,
          status: Status.SUCCESS,
        },
        creatingFolder: false,
      })
      getFoldersUtils(setContainerValues, client)
    })
}

export const deleteFolderUtils = (
  folderId: number,
  setContainerValues: SetContainerValues<ContactSegmentsState>,
  client: ApolloClient<any>,
  t: Function
) => {
  return client
    .mutate<DeleteFolderMutation, DeleteFolderMutationVariables>({
      mutation: deleteFolder,
      variables: {
        folderId,
        type: ItemType.SEGMENT,
      },
    })
    .then((data) => {
      setContainerValues((containerValues) => {
        const folder = data.data?.deleteFolder
        const folders = deleteFolderById(folder?.id, containerValues.folders)
        return {
          ...containerValues,
          folders,
          statusToast: {
            statusMessage: (
              <div className={classNames(TOAST_TEXT_CLASSNAME, `contact-segments__toast`)}>
                <Typography className={'ellip'} text={folder?.name} weight={TextWeight.MEDIUM} />
                <Typography text={` ${t('has been deleted')}`} />
              </div>
            ),
            showStatusToast: true,
            status: Status.SUCCESS,
          },
        }
      })
      getFoldersUtils(setContainerValues, client)
    })
}

export const setFolderUtils = (folderId: number, folders: FolderData[], setContainerValues: SetContainerValues<ContactSegmentsState>) => {
  const folderPath = buildComplexFoldersTreeHeader(folderId, folders)
  setContainerValues((containerValues) => ({
    ...containerValues,
    ...clearMenuSections,
    folderPath,
    activeFolderId: folderId,
    emptyListingOption: EMPTY_LISTING_OPTIONS.EMPTY_FOLDER,
  }))
  setItem(ContactSegmentsSession.FOLDER, folderId.toString())
  setItem(CONTACT_SEGMENTS_CURRENT_SECTION, ContactSegmentsSession.FOLDER)
}

export const folderExists = (folderId: number, folders: Folder[]): boolean => {
  return folders.some((folder) => folder.id === folderId || (folder.subFolders && folderExists(folderId, folder.subFolders)))
}

export const getFoldersUtils = async (setContainerValues: SetContainerValues<ContactSegmentsState>, client: ApolloClient<any>) => {
  setContainerValues((containerValues: ContactSegmentsState) => ({ ...containerValues, foldersLoading: true }))
  await client
    .query<GetFoldersQuery, GetFoldersQueryVariables>({
      query: getFolders,
      fetchPolicy: 'network-only',
      variables: {
        type: ItemType.SEGMENT,
      },
    })
    .then(({ data }) => {
      const folders = data.getFolders
      if (folders) {
        setContainerValues((containerValues) => {
          const { activeFolderId, folderPath } = containerValues
          return {
            ...containerValues,
            folders: sortFoldersByName(folders, true),
            folderPath: activeFolderId ? buildComplexFoldersTreeHeader(activeFolderId, folders as FolderData[]) : folderPath,
            foldersLoading: false,
          }
        })
      }
    })
}

export const getFolderItemsUtils = (
  setFtpSegmentData: (newSegments: Segment[]) => Promise<Segment[]>,
  setContainerValues: SetContainerValues<ContactSegmentsState>,
  syncedSegmentDetailsRequest: SyncedSegmentDetailsRequestType,
  getCRMSegmentsInfoRequest: GetCRMSegmentsInfoRequestType,
  hasCRMConnected: boolean,
  client: ApolloClient<any>,
  pageNumber: number,
  pageSize: number,
  folderId: number | undefined,
  refreshRecordCount: Function,
  needsReRender?: boolean
) => {
  !needsReRender && initSegmentLoading(setContainerValues, pageNumber)
  client
    .query<GetItemsInFolderQuery, GetItemsInFolderQueryVariables>({
      query: getItemsInFolder,
      fetchPolicy: 'network-only',
      variables: {
        type: ItemType.SEGMENT,
        folder: folderId,
        pageNumber,
        pageSize,
      },
    })
    .then(({ data }) =>
      setSegments(
        setFtpSegmentData,
        setContainerValues,
        syncedSegmentDetailsRequest,
        getCRMSegmentsInfoRequest,
        hasCRMConnected,
        data.getItemsInFolder?.filter(filterNotEmptyArray) ?? [],
        pageNumber,
        pageSize,
        refreshRecordCount,
        !needsReRender
      )
    )
}

const renderSuccessMessage = (segments: Segment[], folders: Folder[]) => {
  const haveDifferentFolders = [...new Set(segments.map(({ folderId }) => folderId))].length > 1 ? '.DifferentFolders' : ''
  const { name = '', folderId } = segments.length ? segments[0] : ({} as Segment)
  const values = {
    count: segments.length,
    itemName: name,
    ...(!haveDifferentFolders ? { folderName: folderId ? getFolderById(folderId, folders)?.name : '' } : {}),
  }
  return (
    <div className={TOAST_TEXT_CLASSNAME}>
      <Typography
        type={TextType.BODY_TEXT_LIGHT}
        inline
        text={`ListPage.ContactSegments.DeleteFromFolder.SuccessToast${haveDifferentFolders}`}
        tagProps={{ bold: { weight: TextWeight.BOLD } }}
        values={values}
      />
    </div>
  )
}

export const deleteFromFolderUtils = (
  segmentsIds: number[],
  setContainerValues: SetContainerValues<ContactSegmentsState>,
  containerValues: ContactSegmentsState,
  client: ApolloClient<any>,
  setSectionBySessionData: (needsReRender?: boolean) => void
) => {
  setContainerValues({ ...containerValues, movingItem: true })
  client
    .mutate<MoveItemsMutation, MoveItemsMutationVariables>({
      mutation: moveItems,
      variables: {
        itemIds: segmentsIds,
        folderId: null,
        type: ItemType.SEGMENT,
      },
    })
    .then(() => {
      const { deleteConfirmationData, folders } = containerValues
      setContainerValues((containerValues) => ({
        ...containerValues,
        movingSegment: false,
        statusToast: {
          statusMessage: renderSuccessMessage(deleteConfirmationData, folders),
          showStatusToast: true,
          status: Status.SUCCESS,
        },
      }))
      getFoldersUtils(setContainerValues, client)
      setSectionBySessionData(true)
    })
    .catch(() => {
      setContainerValues({
        ...containerValues,
        movingItem: false,
      })
    })
}

export const moveSegmentsToFolderUtils = (
  segmentsIds: number[],
  folderId: number | null,
  addToFolder: boolean,
  setContainerValues: SetContainerValues<ContactSegmentsState>,
  containerValues: ContactSegmentsState,
  client: ApolloClient<any>,
  t: TFunction,
  setSectionBySessionData: (needsReRender?: boolean) => void
) => {
  setContainerValues({ ...containerValues, movingItem: true })
  client
    .mutate<MoveItemsMutation, MoveItemsMutationVariables>({
      mutation: moveItems,
      variables: {
        folderId,
        itemIds: segmentsIds,
        type: ItemType.SEGMENT,
      },
    })
    .then(() => {
      const { folders } = containerValues
      const folder = getFolderById(folderId, folders)
      setContainerValues({
        ...containerValues,
        movingItem: false,
        statusToast: {
          statusMessage: (
            <div className={classNames(TOAST_TEXT_CLASSNAME, `contact-segments__toast`)}>
              <Typography
                text={`${t(addToFolder ? `Added` : `Moved`)} ${segmentsIds.length} ${t(`segment${segmentsIds.length > 1 ? 's' : ''} to`)} `}
              />
              <Typography className={'ellip'} text={folder?.name} weight={TextWeight.MEDIUM} />
            </div>
          ),
          showStatusToast: true,
          status: Status.SUCCESS,
        },
      })
      getFoldersUtils(setContainerValues, client)
      setSectionBySessionData(true)
    })
    .catch(() => {
      setContainerValues({
        ...containerValues,
        movingItem: false,
      })
    })
}
