import React, { FC, useContext } from 'react'

import classNames from 'classnames'

import { ListingPageCommonContext } from '@complex/ListingPage/Context/ListingPageCommon.context'
import PageHeader from '@components/PageHeader'
import { PageHeaderType } from '@components/PageHeadline/PageHeadline'
import { useTranslation } from '@const/globals'

import './ListingPageHeader.css'

interface ListingPageHeaderProps {
  className?: string
}

export const listingPageHeaderRootClass = 'listing-page-header'

const ListingPageHeader: FC<ListingPageHeaderProps> = (props: ListingPageHeaderProps) => {
  const { className = '' } = props
  const {
    values: {
      dataTest,
      listingPageProps: {
        pageHeaderProps: { pageTitle, renderPageHeaderContent, pageHeaderClassName },
      },
    },
    update,
  } = useContext(ListingPageCommonContext)

  const { t } = useTranslation()

  return (
    <div className={classNames(className, pageHeaderClassName)} data-test={dataTest}>
      {typeof pageTitle === 'string' ? (
        <PageHeader
          headlineType={PageHeaderType.SUB_HEADER}
          leftContent
          primaryText={t(pageTitle)}
          dataTest={dataTest}
          className={listingPageHeaderRootClass}
        >
          {renderPageHeaderContent?.(update)}
        </PageHeader>
      ) : (
        pageTitle
      )}
    </div>
  )
}

export default ListingPageHeader
