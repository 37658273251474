import React, { FC, useMemo } from 'react'

import classNames from 'classnames'

import Filter from '@components/Filter/Filter'
import { FilterDefinition, FilterTypes } from '@utils/filter'
import { toTrainCase } from '@utils/strings'

interface Props {
  className?: string
  dataTest?: string
  filterAction: (filter: FilterDefinition) => void
  activeFilter?: FilterDefinition
  filters: FilterDefinition[]
  filterCounts?: { [key in string]: number | undefined }
  hideCount?: boolean
  showIcons?: boolean
  hideAllItem?: FilterTypes
  hasFavorites?: boolean
  hasRecent?: boolean
  hasRecommended?: boolean
  hasCreatedByMe?: boolean
}

const rootClass = 'filter-container'

const FilterContainer: FC<Props> = (props: Props) => {
  const {
    dataTest = rootClass,
    className = '',
    filterAction,
    activeFilter,
    showIcons = true,
    filters = [],
    hideAllItem,
    hasFavorites = true,
    hasRecommended = false,
    hasRecent = true,
    hasCreatedByMe = true,
    filterCounts = {},
    hideCount,
  } = props

  const filterValidations: { [key: string]: boolean } = {
    [FilterTypes.FAVORITES]: hasFavorites,
    [FilterTypes.RECENT]: hasRecent,
    [FilterTypes.RECOMMENDED]: hasRecommended,
    [FilterTypes.CREATED_BY_ME]: hasCreatedByMe,
  }

  const getFilters = (filters: FilterDefinition[]) => {
    const determineFilters = filters
      .filter((item) => filterValidations[item.name] ?? item)
      .filter((item) => (hideAllItem ? item.name !== hideAllItem : true))
    return determineFilters.map((filter: FilterDefinition, index) => {
      const { name, svgUnselected, svgSelected } = filter
      const label = toTrainCase(name)
      const isActive = activeFilter?.name === filter.name
      const icon = showIcons ? { icon: isActive ? svgSelected : svgUnselected } : {}
      const countKey = 'subTypeName' in filter ? `${filter.subTypeName}` : filter.name
      return (
        <Filter
          key={`filter-${name}-${index}`}
          filter={filter}
          action={filter.filterAction ? filter.filterAction : filterAction}
          {...icon}
          isActive={isActive}
          dataTest={`filter-${label}`}
          count={filterCounts[countKey]}
          hideCount={hideCount}
          className={`${className}__item`}
        />
      )
    })
  }

  const renderFilters: JSX.Element[] = useMemo(() => getFilters(filters), [activeFilter, filters, showIcons])

  return (
    <div className={classNames(className)} data-test={dataTest}>
      {renderFilters}
    </div>
  )
}

export default FilterContainer
