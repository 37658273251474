import { EmailComposerRowCategoryDto } from '@graphql/types/microservice/email-management-types'
import { EmailModalsState } from '@src/pages/EmailComposer/EmailModals/EmailModals'
import { detectEmailType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { ExtendedMessageType } from '@utils/composer/emailComposer/types'

import { AddOnModal } from './BeeEditor.types'

export const SIGNATURE_DATA_ATTRIBUTE = 'data-signatureId'
export const LAYOUT_DATA_ATTRIBUTE = 'dataLayoutId'
export const INVALID_SESSION_ERROR_CODE = 5150
export const CO_EDITING_USERS_LIMIT = 1
export const CHANGE_TYPE = {
  USER_JOINED: 'USER_JOINED',
  USER_LEFT: 'USER_LEFT',
}

export const IS_FIRST_MSG_OPENER = 'isFirstMsgOpener'

export const ALLOWED_TO_LAUNCH = 'allowed to launch'

export const DATE_FORMAT = 'MM/DD/YYYY'

//* for other codes https://docs.beefree.io/beefree-sdk/tracking-message-changes#complete-event-chart
export enum TrackingMessageChangesCodes {
  HistoryRestored = '1609',
}

export const modulesGroups = (isLandingPage?: boolean, isEmailRSS?: boolean) => [
  {
    label: 'Essentials',
    collapsable: true,
    collapsedOnLoad: false,
    modulesNames: ['Heading', 'Paragraph', 'Button', 'Image', 'Divider', 'List', 'Table', ...(isEmailRSS ? ['RSS'] : [])],
  },
  ...(isLandingPage
    ? []
    : [
        {
          label: 'Branding',
          collapsable: true,
          collapsedOnLoad: false,
          modulesNames: ['Email Header', 'Email Footer', 'Signatures'],
        },
      ]),
  {
    label: 'Additional content',
    collapsable: true,
    collapsedOnLoad: false,
    modulesNames: isLandingPage
      ? ['Html', 'Signatures', 'Menu', 'Icons', 'Social', 'Event', 'Video', 'RSS', 'Form', 'Map Block']
      : ['generativeEmail', 'Html', 'Menu', 'Icons', 'Social', 'Event', 'Video', 'Poll Block'],
  },
]

export const contentDefaults = {
  general: {
    color: '#222222',
    backgroundColor: '#ffffff',
    contentAreaWidth: '600px',
    defaultFont: 'Arial, Helvetica Neue, Helvetica, sans-serif',
    linkColor: '#0068a5',
  },
  title: {
    blockOptions: {
      paddingTop: '10px',
      paddingBottom: '10px',
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  },
  paragraph: {
    styles: {
      color: '#222222',
      lineHeight: '150%',
    },
  },
  list: {
    styles: {
      color: '#222222',
      lineHeight: '150%',
    },
  },
  divider: {
    blockOptions: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
  menu: {
    styles: {
      color: '#222222',
    },
    itemsSpacing: {
      paddingBottom: '10px',
      paddingLeft: '10px',
      paddingRight: '10px',
      paddingTop: '10px',
    },
  },
  button: {
    label: 'Button text',
    styles: {
      color: '#ffffff',
      backgroundColor: '#0068a5',
      borderRadius: '6px',
    },
  },
  icons: {
    iconSpacing: {
      paddingTop: '10px',
      paddingBottom: '10px',
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    styles: {
      color: '#222222',
    },
  },
}

export const titleDefaultStyles = {
  h1: {
    color: '#222222',
    'font-size': '24px',
    'text-align': 'left',
  },
  h2: {
    color: '#222222',
  },
  h3: {
    color: '#222222',
  },
}

export const getRowsConfiguration = (hasExternalContentURLs: boolean = true, savedRowCategories?: EmailComposerRowCategoryDto[]) => {
  return {
    emptyRows: true,
    defaultRows: false,
    externalContentURLs: hasExternalContentURLs
      ? Array.isArray(savedRowCategories)
        ? savedRowCategories?.map((category) => ({
            name: category.name,
            value: category.name,
            handle: category.name,
            isLocal: true,
            behaviors: { canDelete: true, canEdit: true },
          }))
        : undefined
      : undefined,
  }
}

export const getAdvancedPermissions = (
  messageType: ExtendedMessageType,
  isLandingPage?: boolean,
  newLPComposerFormBlock?: boolean,
  newLPComposerMapBlock?: boolean
) => {
  const { isEmailRSS } = detectEmailType(messageType)

  const showRSSBlock = !!isLandingPage || isEmailRSS
  const showFormBlock = !!isLandingPage && newLPComposerFormBlock
  const showMapBlock = !!isLandingPage && newLPComposerMapBlock

  return {
    content: {
      menu: {
        properties: {
          hamburger: {
            show: !!isLandingPage,
          },
        },
      },
      addon: {
        [AddOnModal.HEADER_BLOCK]: {
          behaviors: {
            canViewSidebar: !isLandingPage,
          },
        },
        [AddOnModal.FOOTER_BLOCK]: {
          behaviors: {
            canViewSidebar: !isLandingPage,
          },
        },
        [AddOnModal.RSS_BLOCK]: {
          behaviors: {
            canViewSidebar: showRSSBlock,
          },
        },
        [AddOnModal.WEBINAR_BLOCK]: {
          behaviors: {
            canViewSidebar: false,
          },
        },
        [AddOnModal.EVENT_BLOCK]: {
          behaviors: {
            canViewSidebar: true,
          },
        },
        [AddOnModal.POLL_BLOCK]: {
          behaviors: {
            canViewSidebar: !isLandingPage,
          },
        },
        [AddOnModal.FORM_BLOCK]: {
          behaviors: {
            canViewSidebar: showFormBlock,
          },
        },
        [AddOnModal.MAP_BLOCK]: {
          behaviors: {
            canViewSidebar: showMapBlock,
          },
          properties: {
            dynamicImage: {
              show: false,
            },
            imageSelector: {
              show: false,
            },
            link: {
              show: false,
            },
            hideOnMobile: {
              show: false,
            },
            hideOnAmp: {
              show: false,
            },
          },
        },
        [AddOnModal.GENERATIVE_EMAIL]: {
          behaviors: {
            canViewSidebar: !isLandingPage,
          },
        },
        [AddOnModal.STICKER_BLOCK]: {
          behaviors: {
            canViewSidebar: false,
          },
        },
      },
    },
    rows: {
      displayConditions: {
        show: !isLandingPage,
      },
    },
  }
}

export const editorFonts = {
  showDefaultFonts: true,
  customFonts: [
    {
      name: 'Andale Mono',
      fontFamily: "'Andale Mono', monospace",
    },
    {
      name: 'Arial Black',
      fontFamily: "'Arial Black', 'Helvetica Neue', Helvetica, sans-serif",
    },
    {
      name: 'Arial Narrow',
      fontFamily: "'Arial Narrow', 'Helvetica Neue', Helvetica, sans-serif",
    },
    {
      name: 'Book Antiqua',
      fontFamily: "'Book Antique', Georgia, serif",
    },
    {
      name: 'Calibri',
      fontFamily: 'Calibri, Helvetica, Arial, sans-serif',
    },
    {
      name: 'Cambria',
      fontFamily: 'Cambria, Times, "Times New Roman", serif',
    },
    {
      name: 'Century Gothic',
      fontFamily: "'Century Gothic', CenturyGothic, AppleGothic, sans-serif",
    },
    {
      name: 'Comic Sans MS',
      fontFamily: "'Comic Sans MS', cursive, sans-serif",
    },
    {
      name: 'Copperplate',
      fontFamily: 'Copperplate, cursive, serif',
    },
    {
      name: 'Impact',
      fontFamily: 'Impact, "Arial Black", cursive, sans-serif',
    },
    {
      name: 'Lucida Console',
      fontFamily: "'Lucida Console', monospace",
    },
    {
      name: 'Palatino Linotype',
      fontFamily: "'Palatino Linotype', Palatino, 'Book Antiqua', Baskerville, Georgia, serif",
    },
    {
      name: 'Symbol',
      fontFamily: 'Symbol, cursive, sans-serif',
    },
    {
      name: 'Terminal',
      fontFamily: 'Terminal, monospace',
    },
    {
      name: 'Webdings',
      fontFamily: 'Webdings, sans-serif',
    },
    {
      name: 'Windings',
      fontFamily: 'Wingdings, sans-serif',
    },
  ],
}

export const actionDoneCallbacksDefault = {
  onSpecialLinkDone: () => null,
  onSaveRowDone: () => null,
  onMergeTagDone: () => null,
  onAddOnDone: () => null,
  onFilePickerDone: () => null,
  onPreventMultipleLayoutsConfirm: () => null,
  onEditRowDone: () => null,
  onDisplayConditionDone: () => null,
}

export const modalsInitialState: EmailModalsState = {
  selectAsset: false,
  formsPicker: false,
  generativeEmail: false,
  personalization: false,
  saveRowModal: false,
  optOutModal: false,
  imageModal: false,
  statusToast: false,
  signaturesModal: false,
  eventModal: false,
  rssModal: { isActive: false, isPlainText: false },
  headersModal: false,
  footersModal: false,
  dynamicContent: undefined,
  savedRowsEdit: false,
  webinarModal: false,
  pollModal: false,
  formBlockModal: false,
  mapBlockModal: false,
  pollBlockRemovedModal: false,
  webinarBlockRemovedModal: false,
}
