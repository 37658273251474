import React, { FC, ReactNode } from 'react'

import { StatusToastType } from '@interface/StatusToast'
import ProfileOverviewModal from '@src/pages/Profile/components/Overview/component/ProfileOverviewModal/ProfileOverviewModal'
import ConfirmUsersDeleteModal from '@src/pages/Settings/Users/components/ConfirmUsersDeleteModal/ConfirmUsersDeleteModal'
import InviteUsersModalContainer from '@src/pages/Settings/Users/components/InviteUsersModal/InviteUsersModalContainer'
import UsersBulkDeleteErrorModal from '@src/pages/Settings/Users/components/UsersBulkDeleteErrorModal/UsersBulkDeleteErrorModal'
import UsersContext from '@src/pages/Settings/Users/context/UsersContext'
import useEditProfile from '@src/pages/Settings/Users/hooks/useEditProfile'
import useInviteUsers from '@src/pages/Settings/Users/hooks/useInviteUsers'
import useLoadingMutation from '@src/pages/Settings/Users/hooks/useLoadingMutation'
import useResendVerification from '@src/pages/Settings/Users/hooks/useResendVerification'
import useUserDelete from '@src/pages/Settings/Users/hooks/useUsersDelete'
import MarketingUsersContextProvider from '@src/pages/Settings/Users/tabs/MarketingUsers/MarketingUsersContextProvider'
import SalesUsersContextProvider from '@src/pages/Settings/Users/tabs/SalesUsers/SalesUsersContextProvider'

import './UsersContainer.css'

interface Props {
  children: ReactNode
  isAdministrator: boolean
  hasExternalUserAuth: boolean
  setToastStatus: (value: StatusToastType) => void
}

const UsersContextProvider: FC<Props> = (props) => {
  const { children, setToastStatus, hasExternalUserAuth, ...rest } = props
  const { handleResendVerification } = useResendVerification(setToastStatus)
  const { setLoadingMutation, loadingSales, loadingMarketing } = useLoadingMutation()
  const { onDeleteUsers, handleDeleteUsers, usersToDelete, bulkDeleteErrorInfo, onCloseBulkModal } = useUserDelete(setToastStatus, setLoadingMutation)
  const { userData, onClose, showModal, handleEditProfile, onEditProfile } = useEditProfile(setToastStatus)
  const { onCloseInviteModal, showInviteModal, onAddUsers } = useInviteUsers()
  return (
    <UsersContext.Provider
      value={{
        onDeleteUsers,
        handleResendVerification,
        onEditProfile,
        onAddUsers,
        setToastStatus,
        setLoadingMutation,
        ...rest,
      }}
    >
      <MarketingUsersContextProvider loadingMutation={loadingMarketing}>
        <SalesUsersContextProvider loadingMutation={loadingSales}>
          {children}
          {props.isAdministrator && <InviteUsersModalContainer onClose={onCloseInviteModal} isOpen={showInviteModal} />}
        </SalesUsersContextProvider>
      </MarketingUsersContextProvider>
      {props.isAdministrator && <ConfirmUsersDeleteModal onAnswer={handleDeleteUsers} usersToDelete={usersToDelete} />}
      {props.isAdministrator && <UsersBulkDeleteErrorModal onClose={onCloseBulkModal} usersInfo={bulkDeleteErrorInfo} />}
      {showModal && (
        <ProfileOverviewModal
          data={userData}
          onClose={onClose}
          hasExternalUserAuth={hasExternalUserAuth}
          onAction={handleEditProfile}
          isOpen={showModal}
          headerTitleKey={'Edit User Profile'}
        />
      )}
    </UsersContext.Provider>
  )
}

export default UsersContextProvider
