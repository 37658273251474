import React, { ComponentProps, FC, Fragment, ReactElement, useContext, useState } from 'react'
import { ConnectDragSource } from 'react-dnd'

import Row from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/Row/Row'
import { RowGroup } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/RowGroup/RowGroup'
import SegmentDefinitionElementWithDnD, {
  DroppableSegmentDefinitionItem,
} from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/SegmentDefinition/components/SegmentDefinitionElementWithDnD/SegmentDefinitionElementWithDnD'
import SegmentDefinitionElementWithOperator from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/SegmentDefinition/components/SegmentDefinitionElementWithOperator/SegmentDefinitionElementWithOperator'
import {
  getCoordinates,
  moveRow,
  RENDERER_DROPPED_STATE_TIMEOUT,
  updateGroup,
} from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/SegmentDefinition/SegmentDefinition.utils'
import { isSegmentComposerRow } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/utils/SegmentComposerBuild.utils'
import { CombineRowsType } from '@src/pages/SegmentComposer/SegmentComposer.constants'
import { SegmentComposerContext } from '@src/pages/SegmentComposer/SegmentComposer.context'
import { ExpressionGroup, ExpressionRow } from '@src/pages/SegmentComposer/SegmentComposer.interfaces'

interface SegmentDefinitionProps {
  builderElement: ExpressionGroup | ExpressionRow | (ExpressionGroup | ExpressionRow)[]
  className?: string
  dataTest?: string
  hasDragAndDrop?: boolean
  isPreview?: boolean
  operator?: CombineRowsType
}

const rootClass = 'segment-definition'

const SegmentDefinition: FC<SegmentDefinitionProps> = (props: SegmentDefinitionProps) => {
  const { builderElement, dataTest = rootClass, className = '', hasDragAndDrop = false, isPreview = false, operator } = props

  const {
    update,
    values: { selectedRows, segmentDefinition },
  } = useContext(SegmentComposerContext)

  const { groups } = segmentDefinition

  const [droppedItemId, setDroppedItemId] = useState<string>()

  const onSelectRow = (selected: boolean, rowId: string) => {
    if (selected) {
      update({ selectedRows: [...selectedRows, rowId] })
    } else {
      update({ selectedRows: selectedRows.filter((id) => rowId != id) })
    }
  }

  const onExpressionGroupChange = (group: ExpressionGroup, newValues: Partial<ExpressionGroup>) => {
    const rootGroup = groups[0]
    if (rootGroup) {
      const updatedRootGroup = updateGroup(groups[0], group, newValues)
      update({
        segmentDefinition: {
          ...segmentDefinition,
          groups: [updatedRootGroup],
        },
      })
    }
  }

  const dropElement = (droppedItemId: string, to: number[], isOverTop: boolean) => {
    const from = getCoordinates(droppedItemId, segmentDefinition.groups[0])
    if (from && to) {
      // Check if both rows are from the same group and make corrections to index if necessary
      if (from.length === to.length && from.slice(0, -1).every((value, index) => value === to[index])) {
        to[to.length - 1] += from[from.length - 1] > to[to.length - 1] ? 0 : -1
      }
      const updatedRootGroup = moveRow(from, to, groups[0], isOverTop)
      update({
        segmentDefinition: {
          ...segmentDefinition,
          groups: [updatedRootGroup],
        },
      })

      setDroppedItemId(droppedItemId)
      setTimeout(() => {
        setDroppedItemId(undefined)
      }, RENDERER_DROPPED_STATE_TIMEOUT)
    }
  }

  const onDrop = (droppedItem: DroppableSegmentDefinitionItem, droppedAt: string, isOverTop: boolean) => {
    if (droppedItem.id === droppedAt || droppedItem.id === droppedAt) {
      return
    }
    const targetCoordinates = getCoordinates(droppedAt, segmentDefinition.groups[0])
    if (targetCoordinates) {
      dropElement(droppedItem.id, targetCoordinates, isOverTop)
    }
  }

  const renderComponent = (
    component: (dragSource?: ConnectDragSource, isRecentlyDropped?: boolean) => ReactElement,
    id: string,
    operator?: CombineRowsType
  ) => {
    const key = isPreview ? `row-preview-${id}` : `row-${id}`
    if (hasDragAndDrop) {
      return (
        <SegmentDefinitionElementWithDnD
          dataTest={dataTest}
          key={key}
          itemId={id}
          onDrop={onDrop}
          operator={operator}
          recentlyDroppedItemId={droppedItemId}
        >
          {component}
        </SegmentDefinitionElementWithDnD>
      )
    } else {
      return (
        <SegmentDefinitionElementWithOperator dataTest={dataTest} key={key} operator={operator} isPreview={isPreview}>
          {component()}
        </SegmentDefinitionElementWithOperator>
      )
    }
  }

  const renderSingleElement = (element: ExpressionGroup | ExpressionRow, operator?: CombineRowsType): ReactElement => {
    if (isSegmentComposerRow(element)) {
      const id = element.factor.id || ''
      const commonRowProps: ComponentProps<typeof Row> = {
        dataTest: `${dataTest}-row`,
        isDragged: isPreview,
        row: element,
        onSelect: (selected) => onSelectRow(selected, id ?? ''),
      }
      return renderComponent(
        (dragSource, isRecentlyDropped) => (
          <Row className={`${className}-row`} {...commonRowProps} dragSource={dragSource} isRecentlyDropped={isRecentlyDropped} />
        ),
        id,
        operator
      )
    } else {
      const id = element.id
      const commonGroupProps: ComponentProps<typeof RowGroup> = {
        dataTest: `${dataTest}-row-group`,
        expressionGroup: element,
        isDragged: isPreview,
        onExpressionGroupChange,
      }
      return renderComponent(
        (dragSource, isRecentlyDropped) => (
          <RowGroup className={`${className}-row-group`} {...commonGroupProps} dragSource={dragSource} isRecentlyDropped={isRecentlyDropped}>
            {renderGroupRows(element.rows, element.operator)}
          </RowGroup>
        ),
        id,
        operator
      )
    }
  }

  const renderGroupRows = (items: (ExpressionRow | ExpressionGroup)[], operator?: CombineRowsType): ReactElement[] => {
    return items.map((element, index) => {
      const currentElementOperator = index > 0 ? operator : undefined
      return renderSingleElement(element, currentElementOperator)
    })
  }

  return (
    <Fragment>{Array.isArray(builderElement) ? renderGroupRows(builderElement, operator) : renderSingleElement(builderElement, operator)}</Fragment>
  )
}

export default SegmentDefinition
