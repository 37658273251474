import React, { FC, useMemo } from 'react'

import classNames from 'classnames'

import CarouselDataCard from '@components/CarouselDataCard/CarouselDataCard'
import { RibbonColor, RibbonMedals } from '@components/CarouselDataCard/CarouselDataCardUtils'
import { SvgNames } from '@components/Svg'
import { useTranslation } from '@const/globals'
import { RegistrationDataCardInfo } from '@graphql/types/microservice/entity-join-types'
import { ribbonsData } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.constants'
import { ItemType } from '@utils/categorization'
import { getFormattedNumber } from '@utils/numbers'

import './WebinarCarouselDataCard.css'

interface WebinarCarouselDataCardProps {
  className?: string
  dataTest?: string
  topPerformingWebinars: RegistrationDataCardInfo[]
  loading: boolean
}

const rootClass = 'webinar-carousel-data-card'
const TOP_WEBINARS_AMOUNT = 5

const WebinarCarouselDataCard: FC<WebinarCarouselDataCardProps> = (props: WebinarCarouselDataCardProps) => {
  const { dataTest = rootClass, className = '', loading, topPerformingWebinars } = props

  const { t } = useTranslation()

  const emptyWebinarsPerformanceCard: RibbonMedals = {
    title: t(`ListPage.FormsJoinView.${ItemType.WEBINAR_SUBMISSION}.DataCards.TopPerformers.EmptyState.Title`),
    subTitle: t(`ListPage.FormsJoinView.${ItemType.WEBINAR_SUBMISSION}.DataCards.TopPerformers.EmptyState.SubTitle`),
    svg: SvgNames.warning,
    background: RibbonColor.Empty,
  }

  const getTopPerformingWebinars = (webinars: RegistrationDataCardInfo[]): RibbonMedals[] => {
    return webinars.map((webinar, index) => {
      const { webinarName = '', totalRegistrations = 0 } = webinar
      return {
        title: webinarName,
        subTitle: t(`{{formattedCount}} registrations`, { count: totalRegistrations, formattedCount: getFormattedNumber(totalRegistrations) }),
        ...ribbonsData[index],
      }
    })
  }

  const cards = useMemo(() => {
    const sortedTopPerformingWebinars = topPerformingWebinars
      .sort((a, b) => b.totalRegistrations - a.totalRegistrations)
      .slice(0, TOP_WEBINARS_AMOUNT)
    return getTopPerformingWebinars(sortedTopPerformingWebinars)
  }, [topPerformingWebinars])

  return (
    <CarouselDataCard
      className={classNames(rootClass, className)}
      dataTest={dataTest}
      ribbonMedalsData={cards}
      loading={loading}
      emptyCard={cards.length === 0 ? emptyWebinarsPerformanceCard : undefined}
    />
  )
}

export default WebinarCarouselDataCard
