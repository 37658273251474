import React, { FC, useMemo, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Loader from '@components/Loader/Loader'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Radio from '@components/Radio'
import RadioGroup from '@components/RadioGroup'
import Typography, { ModalBodyStyle, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './ManageContactsOrStartModal.css'

export interface ManageContactsOrStartModalProps {
  isOpen: boolean
  isStart?: boolean
  isFirstRun?: boolean
  pendingContacts: number
  title?: string
  loading?: boolean
  submitting?: boolean
  onAddContactsToProgram: () => void
  onExitContactsFromProgram: () => void
  onClose: () => void
  className?: string
  dataTest?: string
}

const rootClass = 'manage-contacts-or-start-modal'

const ManageContactsOrStartModal: FC<ManageContactsOrStartModalProps> = (props: ManageContactsOrStartModalProps) => {
  const {
    isOpen,
    isFirstRun = false,
    isStart,
    pendingContacts,
    title = 'Manage Pending Contacts',
    loading,
    submitting,
    onAddContactsToProgram,
    onExitContactsFromProgram,
    onClose,
    dataTest = rootClass,
    className = '',
  } = props
  const [isAdd, setIsAdd] = useState(true)
  const { t } = useTranslation()

  const showSimple = useMemo(() => isStart && pendingContacts === 0, [pendingContacts])

  const onConfirmClicked = () => (isAdd ? onAddContactsToProgram() : onExitContactsFromProgram())

  const getBody = () =>
    loading ? (
      <Loader />
    ) : showSimple ? (
      <>
        {isStart && <Typography text={t('Program.Dashboard.Start.Common.One')} {...ModalBodyStyle} className={`${rootClass}__text-one`} />}
        <Typography text={t('Program.Dashboard.Start.NonFirstFlow.Two')} {...ModalBodyStyle} />
        <Typography text={`${t('Program.Dashboard.Start.NonFirstFlow.One')}?`} {...ModalBodyStyle} />
      </>
    ) : isFirstRun ? (
      <>
        {isStart && <Typography text={t('Program.Dashboard.Start.Common.One')} {...ModalBodyStyle} className={`${rootClass}__text-one`} />}
        <Typography text={t('Program.Dashboard.Start.FirstFlow.One')} {...ModalBodyStyle} inline />
        <Typography
          text={t('Program.Dashboard.Start.FirstFlow.Two', { pendingSize: pendingContacts })}
          {...ModalBodyStyle}
          weight={TextWeight.BOLD}
          inline
        />
        <Typography text={t('Program.Dashboard.Start.FirstFlow.Three')} {...ModalBodyStyle} inline />
      </>
    ) : (
      <>
        {isStart && <Typography text={t('Program.Dashboard.Start.Common.One')} {...ModalBodyStyle} className={`${rootClass}__text-one`} />}{' '}
        <Typography text={`${t('There')} ${t(pendingContacts === 1 ? 'is' : 'are')} `} {...ModalBodyStyle} inline />
        <Typography
          text={`${pendingContacts} ${t(pendingContacts === 1 ? 'contact' : 'contacts')}`}
          {...ModalBodyStyle}
          weight={TextWeight.BOLD}
          inline
        />
        <Typography text={` ${t('waiting to enter the program. You can add them now, or exit them from the program')}.`} {...ModalBodyStyle} inline />
      </>
    )

  const getRadios = () => (
    <div className={`${rootClass}__radios`}>
      <RadioGroup verticalLayout>
        <Radio
          label={t('Program.Dashboard.Start.FirstFlow.RadioOne')}
          checked={isAdd}
          onChange={() => setIsAdd(!isAdd)}
          dataTest={`${rootClass}-add-pending`}
        />
        <Radio
          label={t('Program.Dashboard.Start.FirstFlow.RadioTwo')}
          checked={!isAdd}
          onChange={() => setIsAdd(!isAdd)}
          dataTest={`${rootClass}-exit-pending`}
        />
      </RadioGroup>
    </div>
  )

  return (
    <Modal
      isOpen={isOpen}
      header={<ModalHeader headerType={ModalHeaderType.List}>{t(title)}</ModalHeader>}
      className={classNames(rootClass, className, {
        [`${rootClass}__non-first-start`]: isStart && !isFirstRun,
      })}
      dataTest={dataTest}
    >
      <ModalBody className={`${rootClass}__modal-body`}>
        {getBody()}
        {!showSimple && !loading && getRadios()}
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.List}>
        <Button buttonType={ButtonType.TERTIARY} onClick={onClose} dataTest={`${dataTest}--cancel`}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} disabled={submitting || loading} onClick={onConfirmClicked} dataTest={`${dataTest}--confirm`}>
          {t('Confirm')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ManageContactsOrStartModal
