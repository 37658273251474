import { gql } from '@apollo/client'

export default gql`
  query getSmsPerformanceResponse(
    $programId: String!
    $unitsAgo: Int!
    $groupByString: String!
    $size: Int!
    $page: Int!
    $sortDirection: Direction!
    $sortColumn: String!
  ) {
    getSmsPerformanceResponse(
      programId: $programId
      unitsAgo: $unitsAgo
      groupByString: $groupByString
      size: $size
      page: $page
      sortDirection: $sortDirection
      sortColumn: $sortColumn
    ) {
      programSmsMessageSendsResponse {
        smsDateToCountResponse {
          date
          count
        }
      }
      programSmsMessagesCountsWithTotalsResponse {
        programSmsMessageCountsResponse {
          stepId
          stepName
          messageId
          sentCount
          deliveredCount
          failedCount
          clickedCount
          repliedCount
          optOutsCount
          uniqueSentCount
          uniqueDeliveredCount
          uniqueFailedCount
          uniqueClickedCount
          uniqueRepliedCount
          uniqueOptOutsCount
        }
        totalRecords
      }
    }
  }
`
