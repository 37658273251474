import React, { FC, useCallback, useEffect, useState } from 'react'

import classNames from 'classnames'

import Caution from '@components/Caution/Caution'
import Container from '@components/Container'
import Loader from '@components/Loader'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import BrandingSectionHeader from '@src/pages/Content/Branding/components/BrandingSectionHeader/BrandingSectionHeader'
import MarketingDomainForm from '@src/pages/Content/Branding/tabs/WebIdentity/MarketingDomain/components/marketingDomainForm/MarketingDomainForm'
import { MARKETING_DOMAIN_MORE_INFO_LINK } from '@src/pages/Content/Branding/tabs/WebIdentity/webIdentity.constants'

import './MarketingDomain.css'

interface MarketingDomainProps {
  loading: boolean
  url: string
  domain: string
  onSaveUrl: (url: string) => void
  onReset: () => void
  isDefaultURL: boolean
  invalidURL: string
  onCancel: () => void
  className?: string
  dataTest?: string
}

const rootClass = 'marketing-domain'

const MarketingDomain: FC<MarketingDomainProps> = (props) => {
  const { loading, url, domain, onSaveUrl, onReset, isDefaultURL, invalidURL, onCancel, className, dataTest = rootClass } = props
  const { t } = useTranslation()
  const [isEditing, setEditing] = useState<boolean>(false)

  const handleSave = useCallback(
    (domain: string) => {
      setEditing(false)
      onSaveUrl(domain)
    },
    [onSaveUrl]
  )

  useEffect(() => {
    !!invalidURL && setEditing(true)
  }, [invalidURL])

  const handleReset = useCallback(() => {
    setEditing(false)
    onReset()
  }, [onReset])

  const handleCancel = useCallback(() => {
    setEditing(false)
    onCancel()
  }, [onCancel])

  return (
    <Container className={classNames(rootClass, className)} dataTest={dataTest}>
      <BrandingSectionHeader
        titleKey="Marketing domain"
        descriptionKey="Marketing.Domain.Description"
        onEdit={setEditing}
        isEditing={isEditing}
        moreInfoLink={MARKETING_DOMAIN_MORE_INFO_LINK}
        className={`${rootClass}__header`}
        dataTest={`${dataTest}-header`}
      />
      {loading ? (
        <Loader />
      ) : isEditing ? (
        <MarketingDomainForm
          domain={invalidURL || (isDefaultURL ? '' : domain)}
          isInvalid={!!invalidURL}
          onSave={handleSave}
          onReset={handleReset}
          onCancel={handleCancel}
        />
      ) : (
        <>
          {isDefaultURL && (
            <Caution
              textLines={[
                { span: t('Marketing.Domain.No.Custom.Title'), weight: TextWeight.BOLD },
                { span: t('Marketing.Domain.No.Custom.Description') },
              ]}
              className={`${rootClass}__caution`}
              dataTest={dataTest}
            />
          )}
          <div className={`${rootClass}__url-wrapper`} data-test={`${dataTest}-url-wrapper`}>
            <Typography text={domain} type={TextType.SECTION_HEADER} dataTest={`${dataTest}-description`} />
            <a href={url} rel="noreferrer" target="_blank" data-test={`${dataTest}-url-link`}>
              <Svg name={SvgNames.externalLink} type={SvgType.ICON} fill={SvgColor.TEXT_TEAL} />
            </a>
          </div>
        </>
      )}
    </Container>
  )
}

export default MarketingDomain
