import React, { FC, useRef, useState } from 'react'
import { Cell, Row } from 'react-table'

import classNames from 'classnames'

import { getColumnProps } from '@components/ActionableNestedTable/ActionableNestedTable.utils'
import Button, { ButtonTextAlign, ButtonType } from '@components/Button/Button'
import CaretIcon, { CaretIconDirection } from '@components/CaretIcon'
import { SvgType } from '@components/Svg'
import { getColumnAlignClass, renderTags } from '@components/Table/components/tableColumns'
import TableRowAction from '@components/Table/components/TableRowAction/TableRowAction'
import { RowAction, TableColumn } from '@components/Table/Table'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { LabelDto } from '@graphql/types/microservice/categorization-types'

import './NestedTableRow.css'

export interface NestedTableRowProps {
  className?: string
  dataTest?: string
  row: Row
  selectedTagId?: number
  columns: TableColumn[]
  onRowClicked?: (row: Row<any>) => void
  readOnlyTags?: boolean
  tags?: LabelDto[]
  tagAction?: (name: string) => void
  defaultTagsNumber?: number
  onApplyAndRemoveTags?: (rows: any[], tagsToApply: LabelDto[], tagsToRemove: number[]) => void
  onCreateTag?: (tag: LabelDto) => void
  rowActions?: RowAction[]
  nextRowDepthDifference: number
  previousRowDepthDifference: number
  onRowExpanded?: (row: Row) => void
  isHover?: boolean
  hasExpander?: boolean
}

export interface NestedTableRowState {
  hover: boolean
  numberOfTagsDisplayed: number
}

const rootClass = 'nested-table-row'

const renderExpanderText = (row: Row<any>, columns: TableColumn[], content: Cell, onRowClicked?: Function) => {
  const renderContent = content.render('Cell')

  return onRowClicked ? (
    <Button
      buttonType={ButtonType.TEXT}
      buttonTextAlign={ButtonTextAlign.LEFT}
      className={classNames(`${rootClass}__text`, `${rootClass}__text-action`, getColumnAlignClass(columns, content.column.id))}
      onClick={() => onRowClicked(row)}
    >
      <Typography text={renderContent} inline lineHeight={LineHeight.TINY} />
    </Button>
  ) : (
    <Typography
      className={classNames(`${rootClass}__text`, getColumnAlignClass(columns, content.column.id))}
      text={renderContent}
      inline
      lineHeight={LineHeight.MEDIUM}
    />
  )
}

const expandRow = (row: Row, onRowExpanded?: Function) => {
  if (row.canExpand) {
    row.toggleRowExpanded()
    onRowExpanded && onRowExpanded(row)
  }
}

export const renderExpanderCell = (row: Row, columns: TableColumn[], onRowClicked?: Function, onRowExpanded?: Function) => {
  const cell = row.cells.find((cell) => cell.column.id === columns[0].accessor) as Cell

  return row.canExpand ? (
    <>
      <Button
        buttonType={ButtonType.TEXT}
        className={`${rootClass}__caret`}
        dataTest={`${rootClass}__caret_${row.id}`}
        {...row.getToggleRowExpandedProps}
        onClick={() => expandRow(row, onRowExpanded)}
      >
        <CaretIcon direction={CaretIconDirection.RIGHT} toggle={row.isExpanded} type={SvgType.VERY_SMALL_ICON} />
      </Button>
      {renderExpanderText(row, columns, cell, onRowClicked)}
    </>
  ) : (
    renderExpanderText(row, columns, cell, onRowClicked)
  )
}

/**
 * @deprecated use <TableV2 instead
 */
const NestedTableRow: FC<NestedTableRowProps> = (props: NestedTableRowProps) => {
  const {
    dataTest = rootClass,
    row,
    columns,
    nextRowDepthDifference,
    previousRowDepthDifference,
    onRowClicked,
    onApplyAndRemoveTags,
    onCreateTag,
    rowActions = [],
    readOnlyTags = false,
    tags,
    tagAction,
    defaultTagsNumber = 3,
    selectedTagId,
    className = '',
    onRowExpanded,
    isHover = false,
    hasExpander = true,
  } = props

  const tagsRef = useRef<HTMLDivElement>(null)
  const waitingTagRef = useRef<HTMLDivElement>(null)

  const [state, setState] = useState<NestedTableRowState>({
    hover: false,
    numberOfTagsDisplayed: defaultTagsNumber,
  })
  const { t } = useTranslation()
  const rawRowProps = row.getRowProps()

  const renderCell = (cell: Cell) => {
    return cell.column.id === 'expander' ? (
      cell.render('Cell')
    ) : (
      <div className={classNames(`td`, `${rootClass}-vertical-alignment`)}>
        {cell.column.id === 'tags' ? (
          readOnlyTags && cell.value?.length === 0 ? (
            <Typography className={`${rootClass}__cell-text`} text={cell.render('Cell')} />
          ) : (
            renderTags({
              appliedTags: cell.value || [],
              numberOfTagsDisplayed: state?.numberOfTagsDisplayed,
              onApplyAndRemoveTags: onApplyAndRemoveTags ? onApplyAndRemoveTags : () => false,
              onCreateTag: onCreateTag ? onCreateTag : () => false,
              rootClass,
              row: cell.row,
              readOnlyTags,
              selectedTagId: selectedTagId as number,
              setState,
              t,
              tags: tags ?? [],
              tagAction,
              tagsRef,
              waitingTagRef,
            })
          )
        ) : (
          <Typography
            text={cell.render('Cell')}
            className={classNames(`${rootClass}__cell-text`, getColumnAlignClass(columns, cell.column.id), {
              [`${rootClass}__cell-flexed`]: !hasExpander,
            })}
            type={(cell.row as Row<any>)?.original.disabled ? TextType.BODY_TEXT_LIGHT : TextType.BODY_TEXT}
          />
        )}
      </div>
    )
  }

  return (
    <div
      {...rawRowProps}
      data-test={dataTest}
      className={classNames(rootClass, className, {
        [`${rootClass}-expanded`]: row.depth === 0,
        [`${rootClass}-hover`]: state?.hover || isHover,
      })}
      id={`${rootClass}-${row.id}`}
    >
      {row.cells
        .filter((cell) => (hasExpander ? columns.length !== 0 && cell.column.id !== columns[0].accessor : true))
        .map((cell, idx) => {
          const borders = [...Array(row.depth)]
          return (
            <div className={`${rootClass}__cell`} {...getColumnProps(cell.column, cell.getCellProps())} key={`td-${idx}`}>
              {cell.column.id === 'expander' ? (
                <div
                  className={classNames(`${rootClass}__expander`, {
                    [`${rootClass}__expander-parent`]: row.depth === 0,
                  })}
                  data-test={`${dataTest}__expander-ctr-${row.canExpand ? 'parent' : 'child'}`}
                  aria-label={`${dataTest}__expander`}
                  title={undefined}
                >
                  {borders.map((_: undefined, index) => (
                    <div
                      key={`border-${index}`}
                      className={`${rootClass}__expander-border`}
                      style={{
                        height: index >= borders.length - nextRowDepthDifference ? `calc(100% - ${previousRowDepthDifference ? 2 : 1}rem)` : '',
                        marginTop: index >= borders.length - previousRowDepthDifference ? '1rem' : '',
                      }}
                    />
                  ))}
                  {renderExpanderCell(row, columns, onRowClicked, onRowExpanded)}
                </div>
              ) : (
                renderCell(cell)
              )}
            </div>
          )
        })}
      {rowActions?.length > 0 && (
        <TableRowAction
          className={`${rootClass}-actions`}
          onToggleDropDown={(hover) => setState({ ...state, hover })}
          rowActions={rowActions}
          row={row}
        />
      )}
    </div>
  )
}

export default NestedTableRow
