import { gql } from '@apollo/client'

export default gql`
  mutation createTemplateFromLandingPage($landingPageId: String) {
    createTemplateFromLandingPage(landingPageId: $landingPageId) {
      id
      status
      msg
    }
  }
`
