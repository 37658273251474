import React, { FC, useMemo } from 'react'

import classNames from 'classnames'

import { ButtonType } from '@components/Button'
import PageHeader from '@components/PageHeader'
import { PageHeaderType } from '@components/PageHeadline/PageHeadline'
import { SvgNames } from '@components/Svg'
import TriggerButton, { TriggerButtonOption } from '@components/TriggerButton/TriggerButton'
import { useTranslation } from '@const/globals'
import { useSalesUsers } from '@src/pages/Settings/Users/context/SalesUsersContext'
import { useAllUsers } from '@src/pages/Settings/Users/context/UsersContext'

import './UsersPageHeader.css'

interface UsersPageHeaderProps {
  className?: string
  dataTest?: string
}

const rootClass = 'users-page-header'

const UsersPageHeader: FC<UsersPageHeaderProps> = (props: UsersPageHeaderProps) => {
  const { dataTest = rootClass, className = '' } = props
  const { crmUsersCount, maxAllowedCountForCrm, allowEditSalesPermission, onEditSalesPermissions, onImportSalesUsers, enableImportCRM } =
    useSalesUsers()
  const { onAddUsers, isAdministrator: isAdmin } = useAllUsers()
  const { t } = useTranslation()
  const options = useMemo<TriggerButtonOption[]>(() => {
    const options: TriggerButtonOption[] = [{ text: t('Manage.Users.Trigger.Add'), icon: SvgNames.addContacts, onClick: onAddUsers }]
    //Shown only if account has more than 0 CRM users available
    if (enableImportCRM && crmUsersCount < maxAllowedCountForCrm) {
      options.push({ text: t('Manage.Users.Trigger.Import'), icon: SvgNames.uploadCloud2, onClick: onImportSalesUsers })
    }
    //Shown only if there are more than 0 sales users (any type)
    if (allowEditSalesPermission) {
      options.push({ text: t('Manage.Users.Trigger.Edit'), icon: SvgNames.key, onClick: onEditSalesPermissions })
    }
    return options
  }, [t, crmUsersCount, maxAllowedCountForCrm, allowEditSalesPermission, onEditSalesPermissions, onImportSalesUsers, onAddUsers, enableImportCRM])

  return (
    <PageHeader
      primaryText={t('Users')}
      headlineType={PageHeaderType.SUB_HEADER}
      leftContent
      className={classNames(rootClass, className)}
      dataTest={dataTest}
    >
      {isAdmin && (
        <TriggerButton label={t('Manage users')} options={options} buttonType={ButtonType.PRIMARY} dataTest={`${dataTest}-trigger-button`} />
      )}
    </PageHeader>
  )
}

export default UsersPageHeader
