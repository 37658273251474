import React, { ChangeEvent, FC, useContext, useEffect, useMemo, useState } from 'react'

import Checkbox from '@src/../../../libs/components/common/Checkbox'

import InputV2 from '@components/InputV2/InputV2'
import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextAlign, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { DataType } from '@graphql/types/microservice/segment-types'
import {
  FieldStatusValues,
  getDataTypeFormatOptionsUtils,
  getUnmappedFieldsWarning,
  validateField,
} from '@src/pages/ContactSegments/components/CopySegments/components/StepThree/components/MappingField/MappingField.utils'
import { CopySegmentsContext, FieldStatusType, MappedField } from '@src/pages/ContactSegments/components/CopySegments/context/CopySegments.context'
import { DataTypeList } from '@src/pages/importcontacts/context/ImportContactsContext'
import { SegmentType } from '@utils/segments'

import './MappingField.css'

export interface MappingFieldProps {
  field: MappedField
}

export interface MappingFieldState {
  automaticallyMatched: boolean
  isNew: boolean
  fieldStatus?: FieldStatusValues
  selectedAllContactsFieldOption?: SelectV2SingleOption
  inputValue: string
  selectedDataTypeOption?: SelectV2SingleOption
  selectedDataFormatOption?: SelectV2SingleOption
  updateDisabled: boolean
  isHovered: boolean
}

const rootClass = 'step-three-mapping-field'
const mappingFieldText = 'Copy.Segments.Page.Step.Three.Field.'

const MappingField: FC<MappingFieldProps> = ({
  field: { allContactsField, marketingListField: marketingListFieldProp, dataFormat, isNew: isNewProp, dataType },
}: MappingFieldProps) => {
  const {
    values: {
      allContactsFields,
      allContactsFieldsOptions,
      deletedAllContactsFields,
      fieldNamesBySegment,
      mappedFields,
      segmentNamesByField,
      segmentsWithFilterExpressions,
      parentType,
      unifiedListFieldMappings,
    },
    updateMappedField,
  } = useContext(CopySegmentsContext)
  const { t } = useTranslation()

  const dataTypeOptions: SelectV2SingleOption[] = useMemo(
    () =>
      DataTypeList.map(({ label, id }) => ({
        label,
        value: id,
      })),
    []
  )

  const getDataTypeFormatOptions = (dataType: string) => getDataTypeFormatOptionsUtils(dataType)

  const [state, setState] = useState<MappingFieldState>({
    selectedAllContactsFieldOption: allContactsFieldsOptions.find(({ value }) => value === allContactsField),
    automaticallyMatched: false,
    isNew: isNewProp,
    inputValue: isNewProp ? allContactsField ?? marketingListFieldProp : marketingListFieldProp,
    updateDisabled: false,
    selectedDataTypeOption: dataTypeOptions.find(({ value }) => value === dataType),
    selectedDataFormatOption: getDataTypeFormatOptions(dataType ?? '').find(({ value }) => value === dataFormat),
    isHovered: false,
  })

  const {
    automaticallyMatched,
    isNew,
    fieldStatus,
    selectedDataTypeOption,
    selectedDataFormatOption,
    selectedAllContactsFieldOption,
    inputValue,
    updateDisabled,
    isHovered,
  } = state

  const formatOptions = useMemo(() => getDataTypeFormatOptions(selectedDataTypeOption?.value ?? ''), [selectedDataTypeOption])
  const automaticallyMappedFields = mappedFields
    .filter(({ automaticallyMapped }) => automaticallyMapped)
    .map(({ allContactsField }) => allContactsField)
  const allContactsFieldsFilteredOptions = allContactsFieldsOptions.filter(({ value }) => !automaticallyMappedFields.includes(value))

  useEffect(() => {
    const fieldStatus: FieldStatusValues | undefined = getUnmappedFieldsWarning({
      segmentsWithFilterExpressions,
      segmentNamesByField,
      fieldNamesBySegment,
      mappedFields,
      marketingListFieldProp,
      t,
    })
    setState((state) => ({ ...state, fieldStatus }))
  }, [])

  useEffect(() => {
    if (allContactsFieldsOptions.length && !selectedAllContactsFieldOption) {
      matchFieldWithOption()
    }
  }, [allContactsFieldsOptions, fieldNamesBySegment])

  useEffect(() => {
    const filteredMappedFields = mappedFields.filter(({ marketingListField }) => marketingListField !== marketingListFieldProp)
    const unmappedFieldsWarning = getUnmappedFieldsWarning({
      segmentsWithFilterExpressions,
      segmentNamesByField,
      fieldNamesBySegment,
      mappedFields,
      marketingListFieldProp,
      t,
    })
    const fieldStatus: FieldStatusValues | undefined =
      unmappedFieldsWarning ||
      validateField({
        state,
        mappedFields: filteredMappedFields,
        allContactsField: isNew ? inputValue : selectedAllContactsFieldOption?.value,
        deletedAllContactsFields,
        allContactsFieldsOptions,
      })
    setState((state) => ({ ...state, fieldStatus }))
  }, [mappedFields])

  useEffect(() => {
    if (!updateDisabled) {
      let updatedColumnId
      if (isNew) {
        const unifiedListField = unifiedListFieldMappings?.find((mapping) => mapping.displayName === inputValue)
        if (parentType === SegmentType.SUBMISSION && !!unifiedListField) {
          updatedColumnId = unifiedListField.columnIndex
        }
      }
      updateMappedField({
        columnId: updatedColumnId,
        automaticallyMapped: automaticallyMatched,
        marketingListField: marketingListFieldProp,
        allContactsField: isNew ? inputValue : selectedAllContactsFieldOption?.value,
        isNew,
        dataType: selectedDataTypeOption?.value as DataType,
        dataFormat: selectedDataFormatOption?.value,
        fieldStatus: fieldStatus?.type,
      })
    }
  }, [isNew, fieldStatus, inputValue, selectedDataTypeOption, selectedDataFormatOption, selectedAllContactsFieldOption, updateDisabled])

  const onSelectChange = (option?: SelectV2SingleOption) => {
    const defaultFieldValues = allContactsFields.find(({ displayName }) => option?.label === displayName)
    const selectedDataTypeOption = dataTypeOptions.find(({ value }) => value === defaultFieldValues?.dataType)
    setState((state) => ({ ...state, selectedAllContactsFieldOption: option, selectedDataTypeOption, selectedDataFormatOption: undefined }))
  }

  const onInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => setState((state) => ({ ...state, inputValue: target.value.trim() }))

  const onInputFocus = () => setState((state) => ({ ...state, updateDisabled: true }))

  const onInputBlur = () => setState((state) => ({ ...state, updateDisabled: false }))

  const onCheckboxChange = (value: boolean) => setState((state) => ({ ...state, isNew: value }))

  const onDataTypeChanged = (option?: SelectV2SingleOption) =>
    setState((state) => ({ ...state, selectedDataTypeOption: option, selectedDataFormatOption: undefined }))

  const onDataFormatChanged = (option?: SelectV2SingleOption) => setState((state) => ({ ...state, selectedDataFormatOption: option }))

  const matchFieldWithOption = () => {
    const match = allContactsFieldsOptions.find(({ label }) => {
      const normalizedLabel = label.toLowerCase().trim()
      const normalizedField = marketingListFieldProp.toLowerCase().trim()
      return normalizedLabel === normalizedField || (normalizedField.includes('e-mail') && normalizedLabel === 'e-mail')
    })
    setState((state) => ({ ...state, selectedAllContactsFieldOption: match, automaticallyMatched: !!match }))
  }
  const matchFieldToShowTooltip = () => {
    const matchingFields = segmentNamesByField.filter((item) => item.fieldName === marketingListFieldProp)
    return matchingFields[0]?.segmentInfo?.map((segment) => (
      <Typography key={segment?.segmentId} textAlign={TextAlign.LEFT} type={TextType.BODY_TEXT_WHITE_REGULAR} text={`${segment?.segmentName}`} />
    ))
  }

  const handleMouseEnter = () => setState((state) => ({ ...state, isHovered: true }))
  const handleMouseLeave = () => setState((state) => ({ ...state, isHovered: false }))
  const iconInfo = isHovered ? SvgNames.infoHover : SvgNames.info

  return (
    <div className={rootClass}>
      <div className={`${rootClass}__tooltip-wrapper`}>
        <Typography inline className={`${rootClass}__column-centered`} text={marketingListFieldProp} />
        <div className={`${rootClass}__tooltip-container`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Tooltip className={`${rootClass}__tooltip`} trigger={<Svg name={iconInfo} type={SvgType.ICON} />}>
            <Typography
              className={`${rootClass}__tooltip-header`}
              type={TextType.SECTION_HEADER}
              weight={TextWeight.BOLD}
              text={`${marketingListFieldProp} field used in`}
            />
            {matchFieldToShowTooltip()}
          </Tooltip>
        </div>
      </div>
      <div className={`${rootClass}__field-status-column`}>
        {!!fieldStatus && (
          <Tooltip
            className={`${rootClass}__tooltip`}
            trigger={<Svg name={fieldStatus.name} type={SvgType.LARGER_ICON} fill={fieldStatus.color} />}
            triggerClassName={`${rootClass}__column-centered`}
          >
            {fieldStatus.type === FieldStatusType.SEGMENT_WITH_UNMAPPED_FIELDS ? fieldStatus.message : t(fieldStatus.message)}
          </Tooltip>
        )}
      </div>
      <div className={`${rootClass}__act-on-contacts-field`}>
        {isNew ? (
          <InputV2 className={`${rootClass}__input`} value={inputValue} onChange={onInputChange} onBlur={onInputBlur} onFocus={onInputFocus} />
        ) : allContactsFieldsOptions.length === 0 ? (
          <Loader className={`${rootClass}__loader`} loaderType={LoaderTypes.row} />
        ) : (
          <SelectV2
            key={selectedAllContactsFieldOption?.value}
            className={`${rootClass}__select`}
            truncateOptions
            hideCheckMark
            defaultValue={selectedAllContactsFieldOption}
            options={allContactsFieldsFilteredOptions}
            maxMenuHeight={300}
            onChange={onSelectChange}
          />
        )}
        <Checkbox checked={isNew} onChange={onCheckboxChange} label={t('Create a new field')} />
      </div>
      <SelectV2
        key={selectedDataTypeOption?.value}
        className={`${rootClass}__select`}
        truncateOptions
        hideCheckMark
        defaultValue={selectedDataTypeOption}
        options={dataTypeOptions}
        maxMenuHeight={300}
        onChange={onDataTypeChanged}
        isDisabled={!isNew}
      />
      {formatOptions.length > 0 && isNew && (
        <div className={`${rootClass}__format-container`}>
          <div className={`${rootClass}__format-box`}>
            <Typography text={t(`${mappingFieldText}DataFormat.Text`)} />
            <SelectV2
              key={selectedDataTypeOption?.value}
              className={`${rootClass}__select`}
              truncateOptions
              hideCheckMark
              defaultValue={selectedDataFormatOption}
              options={formatOptions}
              maxMenuHeight={300}
              onChange={onDataFormatChanged}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default MappingField
