import React, { FC, useCallback, useEffect, useState } from 'react'

import { DropdownWithTogglesProps } from '@components/DropdownWithToggles/DropdownWithToggles.mocks'
import { rootContext } from '@const/globals'
import getDashboardId from '@graphql/microservices/thoughtspot/getDashboardId'
import { useEmailTemplatesListingPageRequests } from '@src/pages/listingPages/EmailTemplates/GraphQL/useEmailTemplatesListingPageRequests.graphQL'
import EmailPerformance from '@src/pages/reports/EmailPerformance/EmailPerformance'
import { useAccountSettings } from '@utils/account/account.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { logNewRelicError } from '@utils/new-relic.utils'

export const enum TrueOpenFilter {
  ALL = 'all',
  NONE = 'none',
  PRIVACY = 'privacy',
  SECURITY = 'security',
}

interface TrueOpenState {
  filter: TrueOpenFilter
  currentValue: string
  toggles: DropdownWithTogglesProps['toggles']
}

interface EmailPerformanceState {
  liveboardId?: string
  dashboardToken?: string
  loading: boolean
  queryError?: string
  previewHtml?: string
  previewTitle: string
  trueOpen: TrueOpenState
  showPreviewRequestError: boolean
  hideAnalyticsUpsell: boolean
}

const toggles: DropdownWithTogglesProps['toggles'] = [
  {
    id: TrueOpenFilter.SECURITY,
    labelEnabled: 'Excluding security systems',
    labelDisabled: 'Exclude security systems',
    description: 'Opens & clicks by bots and spam appliances',
    value: false,
  },
  {
    id: TrueOpenFilter.PRIVACY,
    labelEnabled: 'Excluding privacy systems',
    labelDisabled: 'Exclude privacy systems',
    description: 'Apple MPP opens',
    value: false,
  },
]

const defaultState: EmailPerformanceState = {
  liveboardId: undefined,
  dashboardToken: undefined,
  loading: true,
  previewHtml: undefined,
  previewTitle: '',
  trueOpen: { filter: TrueOpenFilter.NONE, currentValue: 'Filtering disabled', toggles },
  showPreviewRequestError: false,
  hideAnalyticsUpsell: false,
}

const EmailPerformanceContainer: FC = () => {
  const { hasEmbeddedAnalytics } = useAccountSettings()
  if (!hasEmbeddedAnalytics) {
    window.location.assign(`${rootContext}/classic/dashboard`)
  }

  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.THOUGHTSPOT })
  const [state, setState] = useState<EmailPerformanceState>(defaultState)

  const { getItemPreviewRequest } = useEmailTemplatesListingPageRequests()

  const getDashboardIdRequest = useCallback(() => {
    client
      .query({ query: getDashboardId, fetchPolicy: 'network-only', errorPolicy: 'all', variables: { dashboardName: 'emailPerformance' } })
      .then((response) => {
        const liveboardId = response.data.getDashboardId
        if (liveboardId) {
          setState((prevState) => ({ ...prevState, liveboardId, loading: false }))
        }
        if (response.error || response.errors) {
          logNewRelicError(response.error ?? response.errors)
          setState((prevState) => ({ ...prevState, queryError: 'Unable to retrieve the dashboard', loading: false }))
        }
      })
  }, [client])

  useEffect(() => {
    getDashboardIdRequest()
  }, [getDashboardIdRequest])

  const previewAction = useCallback(
    async (msgId: string, previewTitle: string) => {
      const { data, errors } = await getItemPreviewRequest(msgId)

      if (data) {
        const { html, status } = data.getEmailTemplatePreview

        if (status === 'success') {
          setState((prevState) => ({ ...prevState, previewHtml: html, previewTitle }))
        }
      } else if (errors) {
        setState((prevState) => ({ ...prevState, showPreviewRequestError: true }))
        logNewRelicError(errors)
      }
    },
    [getItemPreviewRequest]
  )

  const toggleTrueOpen = (toggleId: TrueOpenFilter, value: boolean) => {
    const newToggles = state.trueOpen.toggles.map((toggle) => (toggle.id === toggleId ? { ...toggle, value } : toggle))
    const enabledToggleCount = newToggles.reduce((acc, toggle) => (toggle.value ? acc + 1 : acc), 0)
    if (enabledToggleCount === newToggles.length) {
      const newFilter = TrueOpenFilter.ALL
      const newCurrentValue = 'Filtering enabled'
      setState((prevState) => ({
        ...prevState,
        trueOpen: { ...prevState.trueOpen, filter: newFilter, currentValue: newCurrentValue, toggles: newToggles },
      }))
    } else if (enabledToggleCount === 0) {
      setState((prevState) => ({
        ...prevState,
        trueOpen: { ...prevState.trueOpen, filter: TrueOpenFilter.NONE, currentValue: 'Filtering disabled', toggles: newToggles },
      }))
    } else {
      // in this case only one toggle is previously active
      const newCurrentValue = `Filtering ${toggleId === TrueOpenFilter.SECURITY ? 'privacy' : 'security'} systems`
      setState((prevState) => ({
        ...prevState,
        trueOpen: { ...prevState.trueOpen, filter: toggleId, currentValue: newCurrentValue, toggles: newToggles },
      }))
    }
  }

  const closeError = useCallback(() => setState((prevState) => ({ ...prevState, showPreviewRequestError: false })), [])

  const closePreview = useCallback(() => setState((prevState) => ({ ...prevState, previewHtml: undefined, loading: false })), [])

  return (
    <EmailPerformance
      liveboardId={state.liveboardId}
      loading={state.loading}
      previewTitle={state.previewTitle}
      previewHtml={state.previewHtml}
      previewAction={previewAction}
      closePreview={closePreview}
      toggleTrueOpen={toggleTrueOpen}
      trueOpenToggles={state.trueOpen.toggles}
      trueOpenFilter={state.trueOpen.filter}
      trueOpenCurrentValue={state.trueOpen.currentValue}
      showPreviewRequestError={state.showPreviewRequestError}
      closeError={closeError}
      queryError={state.queryError}
    />
  )
}

export default EmailPerformanceContainer
