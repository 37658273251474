import React, { FC, useState } from 'react'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'

import './CollapsibleBannerCollapseButton.css'

export interface CollapsibleBannerCollapseButtonProps {
  onClick: (collapse: boolean) => void
}

const rootClass = 'collapsible-banner-collapse-button'

const CollapsibleBannerCollapseButton: FC<CollapsibleBannerCollapseButtonProps> = ({ onClick }: CollapsibleBannerCollapseButtonProps) => {
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const { t } = useTranslation()

  const collapseBanner = () => {
    setCollapsed(() => !collapsed)
    onClick(!collapsed)
  }

  return (
    <Tooltip
      className={`${rootClass}__tooltip`}
      trigger={
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
        <div data-test={rootClass} className={`${rootClass}__icon-container`} role={'button'} onClick={collapseBanner}>
          <Svg name={SvgNames.collapse} type={SvgType.LARGER_ICON} />
        </div>
      }
      position={'top'}
      align={'center'}
    >
      <div>{t('Collapse')} </div>
    </Tooltip>
  )
}

export default CollapsibleBannerCollapseButton
