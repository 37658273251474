import { gql } from '@apollo/client'

export default gql`
  query getCountForRecentWebinarRegistrations($folderSubTypes: [String], $offset: Int, $subTypes: [String]) {
    getCountForRecentWebinarRegistrations(folderSubTypes: $folderSubTypes, offset: $offset, subTypes: $subTypes) {
      createdByMeCount
      favoritesCount
      folderSubType
      recentCount
      subType
    }
  }
`
