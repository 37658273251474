import { createContext, ReactNode } from 'react'

import { CustomSourceItems } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { ProgressBarStep } from '@components/ProgressBar/ProgressBar'
import { Status } from '@components/StatusToast/StatusToast'
import { FormFieldUsageResponse } from '@graphql/types/query-types'
import { Form } from '@utils/forms'

export enum FormFieldDataType {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Email = 'EMAIL',
  MultiText = 'MULTI_TEXT',
  Number = 'NUMBER',
  Score = 'SCORE',
  Text = 'TEXT',
  FileField = 'FileField',
}

export interface FormFieldUsage extends Pick<FormFieldUsageResponse, 'fieldName' | 'formsInfo' | 'dataType'> {
  fieldMapping?: string
  isMapped: boolean
  isValid: boolean
  uclColumnIndex?: number
}

export enum ArchiveSettings {
  ARCHIVE = 'ARCHIVE',
  DELETE = 'DELETE',
  NOTHING = 'NOTHING',
}

export interface FormsUpgradeModalState {
  archiveSetting: ArchiveSettings
  createSegment: boolean
  formFields: FormFieldUsage[]
  forms: Form[]
  selectedSources?: CustomSourceItems
  showDeleteConfirmationModal: boolean
  showExitConfirmationModal: boolean
  statusToast?: {
    message: string | ReactNode
    status: Status
    showStatusToast: boolean
  }
  steps: ProgressBarStep[]
  isWarningModalOpen: boolean
  isWarningConfirmed: boolean
}

export const FormsUpgradeModalInitialState: Omit<FormsUpgradeModalState, 'steps'> = {
  archiveSetting: ArchiveSettings.NOTHING,
  createSegment: true,
  formFields: [],
  forms: [],
  showDeleteConfirmationModal: false,
  showExitConfirmationModal: false,
  isWarningModalOpen: false,
  isWarningConfirmed: false,
}

export type Update = (fieldsToUpdate: Partial<FormsUpgradeModalState>) => void

export interface FormsUpgradeAPI {
  values: FormsUpgradeModalState
  update: Update
}

export const FormsUpgradeModalContext = createContext<FormsUpgradeAPI>({ values: FormsUpgradeModalInitialState } as any)
