import { gql } from '@apollo/client'

export default gql`
  mutation deleteSeoFriendlyUrl($pageId: String!, $urlId: String!) {
    deleteSeoFriendlyUrl(pageId: $pageId, urlId: $urlId) {
      id
      name
      publicUrl
      seoFriendlyUrl
      shortUrl
    }
  }
`
