/* istanbul ignore file */
import {
  ApolloClient,
  ApolloLink,
  DocumentNode,
  HttpLink,
  InMemoryCache,
  InMemoryCacheConfig,
  MutationOptions,
  OperationVariables,
  QueryOptions,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { isProd, isStaging, USER_NOT_VALID_ERROR } from '@const/globals'
import utils from '@utils/utils'

//Remove __typename
export const cleanTypename = () => {
  return new ApolloLink((operation, forward) => {
    if (operation.variables) {
      const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value)
      operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename)
    }
    return forward(operation).map((data) => {
      return data
    })
  })
}

export const logoutOnAuthError = () => {
  return onError(({ graphQLErrors }) => {
    if (graphQLErrors?.length && graphQLErrors.some((err) => err.extensions?.errorCode === USER_NOT_VALID_ERROR)) {
      utils.sendLoginScreen()
    }
  })
}

export const createClient = (
  url: string,
  token: string | undefined,
  aoAccountContext?: string,
  transactionId?: string,
  cacheConfig?: InMemoryCacheConfig
) => {
  const cleanTypeName = cleanTypename()

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        transactionId,
        authorization: token,
        AOAccountContext: aoAccountContext,
      },
    }
  })

  // Setup Apollo with GraphQL
  const httpLink = new HttpLink({ uri: url, fetchOptions: {} })
  return new ApolloClient({
    link: ApolloLink.from([cleanTypeName, authLink, httpLink]),
    cache: new InMemoryCache(cacheConfig),
  })
}

export const createGraphQLQuery = <Response, Variables extends OperationVariables>(
  client: ApolloClient<object>,
  query: DocumentNode,
  options?: Partial<QueryOptions<Variables, Response>>
) => {
  return async (variables: Variables) => {
    return await client.query<Response, Variables>({
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      ...options,
      query,
      variables,
    })
  }
}

export const createGraphQLMutation = <Response, Variables extends OperationVariables>(
  client: ApolloClient<object>,
  mutation: DocumentNode,
  options?: Partial<MutationOptions<Response, Variables>>
) => {
  return async (variables: Variables) => {
    return await client.mutate<Response, Variables>({
      errorPolicy: 'all',
      ...options,
      mutation,
      variables,
    })
  }
}

const AOR_DEV = 'aordeks'
const AOR_STAGING = 'aorseks'
const AOR_PROD = 'aorpeks'
const DEV = 'dev-'
const STG = 'staging-'
const PROD = ''
export const getEnvironmentLocation = () => {
  // TODO: Update for localhost to localhost
  if (isProd()) {
    return { prefix: PROD, aorLocation: AOR_PROD }
  } else if (isStaging()) {
    return { prefix: STG, aorLocation: AOR_STAGING }
  } else {
    return { prefix: DEV, aorLocation: AOR_DEV }
  }
}
