import React, { ReactNode, useCallback, useMemo, useState, FC } from 'react'

import classNames from 'classnames'

import CloseButton from '@components/CloseButton/CloseButton'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import { ToggletipTriggerSize, ToggletipAlign, ToggletipSide } from '@components/Toggletip/Toggletip'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import * as Popover from '@radix-ui/react-popover'
import { useTranslation } from '@utils/const/globals'
import { useWindowBlur } from '@utils/hooks/useWindowBlur'

import './DisplayToggleCardToggletip.css'

export interface DisplayToggleCardToggletipProps {
  title: string
  description: string
  content?: ReactNode
  side?: ToggletipSide
  sideOffset?: number
  align?: ToggletipAlign
  alignOffset?: number
  triggerSize?: ToggletipTriggerSize
  className?: string
  dataTest?: string
}

const rootClass = 'display-toggle-card-toggletip'

const DisplayToggleCardToggletip: FC<DisplayToggleCardToggletipProps> = ({
  title,
  description,
  content = null,
  side,
  sideOffset,
  align,
  alignOffset,
  triggerSize = ToggletipTriggerSize.MEDIUM,
  className,
  dataTest = rootClass,
}) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [triggerHover, setTriggerHover] = useState<boolean>(false)
  useWindowBlur(isOpen, setIsOpen)

  const handleStopProp = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation(),
    []
  )
  const handleMouseEnter = useCallback(() => setTriggerHover(true), [])
  const handleMouseLeave = useCallback(() => setTriggerHover(false), [])
  const handleClose = useCallback(() => setIsOpen(false), [])

  const defaultIconType = useMemo<SvgType>(() => {
    switch (triggerSize) {
      case ToggletipTriggerSize.LARGE:
        return SvgType.LARGE_ICON
      case ToggletipTriggerSize.MEDIUM:
        return SvgType.LARGER_ICON
      case ToggletipTriggerSize.SMALL:
        return SvgType.ICON
    }
  }, [triggerSize])

  const isActive = isOpen || triggerHover

  return (
    <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
      <Popover.Trigger
        onClick={handleStopProp}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={classNames(`${rootClass}__trigger`, `${rootClass}__trigger-${triggerSize}`, {
          [`${className}__trigger`]: !!className,
        })}
        data-test={`${dataTest}-trigger`}
      >
        <Svg name={isActive ? SvgNames.infoHover : SvgNames.info} type={defaultIconType} fill={isActive ? SvgColor.TEXT_TEAL : SvgColor.LIGHT_GRAY} />
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          side={side}
          sideOffset={sideOffset}
          align={align}
          alignOffset={alignOffset}
          onClick={handleStopProp}
          className={classNames(`${rootClass}__content`, {
            [`${className}__content`]: className,
          })}
          data-test={`${dataTest}-content`}
        >
          <div className={`${rootClass}__header`}>
            <div>
              <Typography text={title} type={TextType.PAGE_HEADER_SEMI_BOLD} className="push-up" lineHeight={LineHeight.LARGE} />
              <Typography text={description} type={TextType.BODY_TEXT_LIGHT} />
            </div>
            <Tooltip trigger={<CloseButton onClick={handleClose} dataTest={`${dataTest}-close`} />} isPopover>
              {t('Close')}
            </Tooltip>
          </div>
          {content}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

export default DisplayToggleCardToggletip
