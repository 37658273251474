import React, { FC } from 'react'
import { FieldValues, UseFormRegister } from 'react-hook-form'

import Button, { ButtonType } from '@components/Button/Button'
import Select from '@components/Select/Select'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import globals from '@const/globals'
import { CrmIntersectionSchema, FieldDefinition } from '@graphql/types/query-types'

import CRMStepComponent from './CRMStepComponent'
import { KeyValueWithId } from '../../EditCreateInCRMStep'

export interface Props {
  fieldName: string
  fields: KeyValueWithId[]
  showAdd: boolean
  addText: string
  updateStep(key: string, value: any): void
  dataTest?: string
  register: UseFormRegister<FieldValues>
  rootClass: string
  crmIntersections: CrmIntersectionSchema[]
}

interface State {
  selectValue: string
  addingValue: boolean
  consumedFields: { [key: string]: string }
}

const StepComponentMapper: FC<Props> = (props: Props) => {
  const { fieldName, fields, register, crmIntersections, showAdd, addText, updateStep, rootClass = 'crm-field', dataTest = 'crm-field' } = props

  const { t } = globals.useTranslation()

  const crmIntersectionObject: { [name: string]: FieldDefinition } = crmIntersections
    ? crmIntersections.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.key]: cur.fieldDefinition,
        }),
        {}
      )
    : {}

  const [state, setState] = React.useState<State>({
    selectValue: '',
    addingValue: false,
    consumedFields: fields
      ? (fields
          .filter((crm) => crm.key)
          .reduce(
            (acc, cur) => ({
              ...acc,
              [`${cur.key}`]: cur.key,
            }),
            {}
          ) as { [key: string]: string })
      : {},
  })

  const { selectValue, consumedFields } = state

  const add = (i: number, e: React.MouseEvent) => {
    e.preventDefault()
    const selectedValue = selectValue
      ? selectValue
      : crmIntersections.filter((intersection) => !consumedFields.hasOwnProperty(intersection.key))[0].key
    setState({
      ...state,
      selectValue: '',
      addingValue: false,
      consumedFields: { ...consumedFields, [selectedValue]: selectedValue },
    })
    updateStep(fieldName, [...fields.slice(0, i), { ...fields[i], key: selectedValue }])
  }

  const remove = (i: number) => {
    const valueToRemove = fields[i].key
    const updatedConsumedFields = Object.keys(consumedFields).reduce((acc, cur) => {
      if (cur === valueToRemove) {
        return acc
      }
      return {
        ...acc,
        [cur]: cur,
      }
    }, {})
    updateStep(fieldName, [...fields.slice(0, i), ...fields.slice(i + 1)])
    setState({
      ...state,
      consumedFields: updatedConsumedFields,
    })
  }

  const getIntersectionOptions = () =>
    crmIntersections
      .filter((field) => !Object.keys(consumedFields).includes(field.key))
      .reduce((acc: any, cur) => {
        return [
          ...acc,
          <option key={cur.key} value={cur.fieldDefinition?.fieldName}>
            {`${cur.fieldDefinition?.displayName}`}
          </option>,
        ]
      }, [])

  const updateField = (i: number) => (value: any) => {
    const updatedField = { ...fields[i], value }
    const updatedFields = [...fields.slice(0, i), updatedField, ...fields.slice(i + 1)]

    updateStep(fieldName, updatedFields)
  }

  return (
    <>
      {fields.map((field, i: number) =>
        field.key ? (
          <div key={field.id} className={`${rootClass}__row`} data-test={`${dataTest}-${i}`}>
            <CRMStepComponent
              fieldDefinition={crmIntersectionObject[field.key]}
              value={field.value}
              register={register}
              updateField={updateField(i)}
              dataTest={`${dataTest}-item-${i}`}
            />
            {crmIntersectionObject[field.key] && !crmIntersectionObject[field.key].required && (
              <Button buttonType={ButtonType.REMOVE} dataTest={`${dataTest}__remove-${i}`} onClick={() => remove(i)}>
                <Svg name={SvgNames.delete} type={SvgType.ICON} />
              </Button>
            )}
          </div>
        ) : (
          crmIntersections && (
            <div key={field.id} className={`${rootClass}__add-row`}>
              <Select
                className={`${rootClass}__intersection-schema`}
                dataTest={`${dataTest}-schema-select-${i}`}
                register={register('selectValue', {
                  onChange: (event) => {
                    setState({
                      ...state,
                      selectValue: event.target.value,
                    })
                  },
                })}
                name="selectValue"
                value={selectValue}
              >
                {getIntersectionOptions()}
              </Select>
              <Button dataTest={`${dataTest}__add_${i}`} buttonType={ButtonType.FLOAT} onClick={(e) => add(i, e)}>
                <Svg name={SvgNames.check} type={SvgType.ICON} />
                {t('Add')}
              </Button>
              <Button
                buttonType={ButtonType.REMOVE}
                dataTest={`${dataTest}__delete_${i}`}
                onClick={() => {
                  updateStep(fieldName, [...fields.slice(0, i)])
                  setState({
                    ...state,
                    selectValue: '',
                    addingValue: false,
                  })
                }}
              >
                {t('Cancel')}
              </Button>
            </div>
          )
        )
      )}
      {showAdd && !state.addingValue && Object.keys(consumedFields).length !== crmIntersections.length && (
        <Button
          buttonType={ButtonType.FLOAT}
          dataTest={`${dataTest}__add-field-button`}
          type="button"
          title={t(addText)}
          onClick={() => {
            updateStep(fieldName, [...fields, { key: '', value: '', id: globals.getUUID() }])
            setState({
              ...state,
              addingValue: true,
              selectValue: '',
            })
          }}
        >
          <Svg name={SvgNames.plus} type={SvgType.ICON} />
          {t(addText)}
        </Button>
      )}
    </>
  )
}

export default StepComponentMapper
