import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import Svg, { SvgNames } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { CommonComposerTab } from '@utils/composer/commonComposer/CommonComposer.context'
import { ComposerHistoryState, EmailComposerContext } from '@utils/composer/context/EmailComposer.context'

interface ReviewAndSendHeaderProps {
  rootClass: string
  dataTest?: string
}

const ReviewAndSendHeader: FC<ReviewAndSendHeaderProps> = ({ rootClass, dataTest = rootClass }) => {
  const { t } = useTranslation()
  const history = useHistory<ComposerHistoryState>()

  const {
    values: {
      isSaving,
      haveUnsavedChanges,
      message: { sendMethod },
      messageConfiguration: {
        reviewAndSend: { hideSendNow },
        preview: { disableTestEmail },
      },
      disabledFeatures: { autoSave: disabledAutoSave },
      validations: { isContentMissing, validationSectionsStatus },
      launchApproval,
      messageConfiguration,
    },
    api: { onPreview, onTest, onTabChange, update },
  } = useContext(EmailComposerContext)

  const [waitSaving, setWaitSaving] = useState(isSaving)
  const separateSaveAndClose = messageConfiguration?.saveButton?.separateSaveAndClose

  useEffect(() => {
    if (isSaving) {
      setWaitSaving(true)
    } else {
      // add some delay to avoid blinking while waiting for loadingValidation
      setTimeout(() => setWaitSaving(false), 300)
    }
  }, [isSaving])

  const canLaunch = useMemo(() => launchApproval !== 'Not allowed to launch', [launchApproval])

  const isSchedule = useMemo(() => sendMethod.sendtype && sendMethod.sendtype !== 'STANDARD', [sendMethod.sendtype])
  const hasCriticalErrors = useMemo(() => Object.values(validationSectionsStatus).some(({ errors }) => !!errors), [validationSectionsStatus])

  const loadingValidation = useMemo(() => Object.values(validationSectionsStatus).some(({ loading }) => !!loading), [validationSectionsStatus])

  const sendTooltipContext = useMemo(() => {
    switch (true) {
      case isContentMissing:
        return 'noContent'
      case hasCriticalErrors:
        return 'error'
      case !canLaunch:
        return 'requiresApproval'
      case !sendMethod.sendtype:
        return 'sendMethod'
      case loadingValidation:
        return 'loading'
      case waitSaving:
        return 'saving'
      default:
        return null
    }
  }, [canLaunch, hasCriticalErrors, isContentMissing, sendMethod.sendtype, loadingValidation, waitSaving])

  const handleBack = useCallback(() => onTabChange(history.location.state?.previousTab ?? CommonComposerTab.DESIGN), [onTabChange, history])

  const handlePreview = useCallback(() => {
    update({ isPreview: true })
    onPreview()
  }, [onPreview, update])

  const handleSend = useCallback(() => {
    update({ isSend: true })
  }, [update])

  const sendTestTooltipHeader = isContentMissing ? 'MissingContent' : haveUnsavedChanges ? 'UnsavedChanges' : ''
  const sendTestTooltipBody = disableTestEmail ? 'ResendDisabled' : isContentMissing ? 'MissingContent' : haveUnsavedChanges ? 'UnsavedChanges' : ''

  return (
    <div className={rootClass} data-test={dataTest}>
      <div className={`${rootClass}__left`}>
        <Button buttonType={ButtonType.FLOAT_DARK_BG} onClick={handleBack} dataTest={`${dataTest}-back-button`}>
          <Svg name={SvgNames.arrowLeft} />
          {t('Back to editing')}
        </Button>
      </div>
      <Tooltip
        hide={!isContentMissing}
        position={'bottom'}
        trigger={
          <Button buttonType={ButtonType.FLOAT_DARK_BG} onClick={handlePreview} disabled={isContentMissing}>
            <Svg name={SvgNames.zoom} />
            {t('Preview')}
          </Button>
        }
      >
        <Typography text={t('EmailComposer.Design.PreviewDisabled.Tooltip.HeaderText')} weight={TextWeight.BOLD} type={TextType.BODY_TEXT_WHITE} />
        <Typography text={t('EmailComposer.Design.PreviewDisabled.Tooltip.BodyText')} type={TextType.BODY_TEXT_WHITE} />
      </Tooltip>
      <Tooltip
        hide={!disableTestEmail || (!isContentMissing && !haveUnsavedChanges)}
        position={'bottom'}
        trigger={
          <Button
            buttonType={ButtonType.FLOAT_DARK_BG}
            onClick={onTest}
            disabled={disableTestEmail || isContentMissing || (!separateSaveAndClose && disabledAutoSave && haveUnsavedChanges)}
          >
            <Svg name={SvgNames.test} />
            {t('Test email')}
          </Button>
        }
      >
        {!disableTestEmail && sendTestTooltipHeader && (
          <Typography
            text={t(`EmailComposer.Design.TextEmail.Tooltip.HeaderText.${sendTestTooltipHeader}`)}
            weight={TextWeight.BOLD}
            type={TextType.BODY_TEXT_WHITE}
          />
        )}
        {sendTestTooltipBody && (
          <Typography text={t(`EmailComposer.Design.TextEmail.Tooltip.BodyText.${sendTestTooltipBody}`)} type={TextType.BODY_TEXT_WHITE} />
        )}
      </Tooltip>
      {!hideSendNow && (
        <Tooltip
          className={`${rootClass}-tooltip`}
          hide={!sendTooltipContext}
          position="left"
          arrowOffset={0}
          minimalPadding={false}
          trigger={
            <Button
              buttonType={ButtonType.PRIMARY}
              iconPosition={ButtonIconPosition.LEFT}
              disabled={waitSaving || loadingValidation || hasCriticalErrors || !sendMethod.sendtype || isContentMissing || !canLaunch}
              onClick={handleSend}
            >
              <Svg name={SvgNames.airplaneOutline} />
              {t(isSchedule ? 'Schedule email' : 'Send email')}
            </Button>
          }
        >
          {t('EmailComposer.Send.Tooltip', {
            context: sendTooltipContext,
          })}
        </Tooltip>
      )}
    </div>
  )
}

export default ReviewAndSendHeader
