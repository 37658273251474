import React from 'react'

import Loader from '@components/Loader'
import { SvgNames } from '@components/Svg'
import { TabItemData } from '@components/TabsAO/TabsAO'

export const tabs: TabItemData[] = [
  {
    index: 'programPerformance',
    label: 'Program Performance',
    content: <Loader />,
  },
  {
    index: 'steps',
    label: 'Steps',
    content: 'Steps content',
  },
  {
    index: 'emailPerformance',
    label: 'Email Performance',
    content: 'Email Performance content',
  },
  {
    index: 'smsPerformance',
    label: 'SMS Performance',
    content: 'SMS Performance content',
  },
  {
    index: 'programSettings',
    label: 'Program Settings',
    content: 'Program Settings content',
  },
]

export type ProgramExitType = 'segment' | 'email'

export interface EarlyExitSegmentInfo {
  id: string
  name: string
  condition: string
  exitType: ProgramExitType
  secondAction?: string
  secondExitType?: ProgramExitType
  secondList?: string
}

export const DEFAULT_SCHEDULE = {
  nextTS: 0,
  nextTime: null,
  type: 'NEVER',
  hour: 1,
  minute: 0,
  ampm: 0,
  interval: 0,
  timestamp: null,
  waitUntilDate: '1969-12-31',
  waitUntilDateFormatted: '',
  triggerAllowed: false,
  isScheduled: false,
  isUnscheduled: true,
  isTriggered: false,
  weekdaysOnly: false,
  hasEndTime: false,
  endHour: null,
  endMinute: null,
  endAmpm: null,
}

export const ProgramActionsOptions = {
  edit: {
    text: 'Edit program',
    icon: SvgNames.pencil,
    onClick: () => alert('Edit Program clicked'),
  },
  start: {
    text: 'Start program',
    icon: SvgNames.play,
    onClick: () => alert('Start Program clicked'),
  },
  pause: {
    text: 'Pause program',
    icon: SvgNames.pause,
    onClick: () => alert('Pause Program clicked'),
  },
  view: {
    text: 'View program',
    icon: SvgNames.viewProgram,
    onClick: () => alert('View Program clicked'),
  },
  errors: {
    text: 'View error summary',
    icon: SvgNames.warning,
    onClick: () => alert('View Errors clicked'),
  },
  add: {
    text: 'Add new contacts',
    icon: SvgNames.addContactsNoFill,
    onClick: () => alert('Add Contacts clicked'),
  },
  clear: {
    text: 'Clear program history',
    icon: SvgNames.cancelSend,
    onClick: () => alert('Clear Program History clicked'),
  },
}
