import React, { FC, useContext } from 'react'

import classNames from 'classnames'

import Container from '@components/Container'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ExportContactsContext } from '@src/pages/ExportContacts/utils/ExportContacts.context'

import { ExportSummaryStatus } from './utils/ReviewExportFields.constants'

import './ReviewExportFields.css'

interface ReviewExportFieldsProps {
  className?: string
  dataTest?: string
}

const rootClass = 'review-export-fields'

export const ReviewExportFields: FC<ReviewExportFieldsProps> = (props: ReviewExportFieldsProps) => {
  const { dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const {
    values: { selectedFtpFile, exportFields },
  } = useContext(ExportContactsContext)

  const selectedFields = exportFields.filter((field) => field.selected).length
  const notSelectedFields = exportFields.length - selectedFields

  const renderStatus = (status: ExportSummaryStatus, value: string | number) => {
    const contentIconByStatus: { [key in ExportSummaryStatus]: { icon: SvgNames; className?: string } } = {
      [ExportSummaryStatus.FIELDS_SELECTED]: { icon: SvgNames.checkSuccess },
      [ExportSummaryStatus.FIELDS_NOT_SELECTED]: { icon: SvgNames.cancelCircle, className: `${rootClass}__status-icon-unmapped` },
    }
    const { icon, className } = contentIconByStatus[status]
    return (
      <div className={`${rootClass}__status`} data-test={`${dataTest}-status-${status}`}>
        <div className={`${rootClass}__status-header`}>
          <Svg className={className} name={icon} type={SvgType.LARGE_ICON} />
          <Typography text={t(status)} type={TextType.BODY_TEXT_LARGE_LIGHT} lineHeight={LineHeight.MEDIUM_LARGE} />
        </div>
        <Typography text={value} type={TextType.PAGE_HEADLINE} weight={TextWeight.MEDIUM} lineHeight={LineHeight.VERY_LARGE} />
      </div>
    )
  }

  return (
    <Container className={classNames(rootClass, className)} dataTest={dataTest}>
      <div className={`${rootClass}__header`}>
        <Typography
          text={t('ExportContacts.Sections.ReviewExport.Summary.Title')}
          type={TextType.BODY_TEXT_LARGE}
          weight={TextWeight.MEDIUM}
          lineHeight={LineHeight.MEDIUM_LARGE}
        />
        <Typography text={t('ExportContacts.Sections.ReviewExport.Summary.Description')} type={TextType.BODY_TEXT_LIGHT} />
      </div>
      <div className={`${rootClass}__file-wrapper`}>
        <Typography text={t('ExportContacts.Sections.ReviewExport.Summary.File.Title')} weight={TextWeight.MEDIUM} />
        <div className={`${rootClass}__file`} data-test={`${dataTest}-file`}>
          <Svg name={SvgNames.csvFile} fill={SvgColor.LIGHT_GRAY} type={SvgType.LARGE_ICON} />
          <Typography
            className={`${rootClass}__file-name`}
            dataTest={`${dataTest}-filename`}
            text={selectedFtpFile?.name ?? t('ExportContacts.Sections.ReviewExport.Summary.File.NoFile')}
            weight={TextWeight.MEDIUM}
          />
        </div>
      </div>
      <div className={`${rootClass}__status-container`}>
        {renderStatus(ExportSummaryStatus.FIELDS_SELECTED, selectedFields)}
        {renderStatus(ExportSummaryStatus.FIELDS_NOT_SELECTED, notSelectedFields)}
      </div>
    </Container>
  )
}
