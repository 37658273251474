import React, { FC, useContext, useEffect, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import Container from '@components/Container'
import { ExportToFtpFileModal } from '@components/ExportToFtpFileModal/ExportToFtpFileModal'
import SelectFtpFileWithSearchModal from '@components/SelectFtpFileWithSearchModal/SelectFtpFileWithSearchModal'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { FtpFileDto } from '@graphql/types/microservice/entity-upload-types'
import { useExportContactsRequest } from '@src/pages/ExportContacts/GraphQL/ExportContacts.graphql'
import { ExportContactsSteps } from '@src/pages/ExportContacts/utils/ExportContacts.constants'
import { ExportContactsContext } from '@src/pages/ExportContacts/utils/ExportContacts.context'
import SelectedFile from '@src/pages/ImportContactsV2/components/SelectedFile/SelectedFile'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'

import './ExportFileSelectionScreen.css'

interface ExportFileSelectionScreenProps {
  className?: string
  dataTest?: string
}

interface State {
  selectingFile: boolean
  ftpFiles: FtpFileDto[]
  loadingFtpFiles: boolean
  creatingFtpFile: boolean
}

const rootClass = 'export-file-selection-screen'

const ExportFileSelectionScreen: FC<ExportFileSelectionScreenProps> = (props: ExportFileSelectionScreenProps) => {
  const { dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const {
    values: { selectedFtpFile, exportDefinitionId, exportSegment },
    handleOnSectionChange,
    update,
  } = useContext(ExportContactsContext)

  const [state, setState] = useState<State>({
    selectingFile: false,
    ftpFiles: [],
    loadingFtpFiles: false,
    creatingFtpFile: false,
  })

  const { selectingFile, ftpFiles, creatingFtpFile, loadingFtpFiles } = state

  const localUpdate = useDeepUpdate(setState)

  const { getFtpFilesRequest } = useExportContactsRequest()

  useEffect(() => {
    if (selectingFile) {
      localUpdate({ loadingFtpFiles: true })
      getFtpFilesRequest().then((files) => {
        localUpdate({ ftpFiles: files, loadingFtpFiles: false })
      })
    }
  }, [selectingFile])

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {selectingFile && (
        <SelectFtpFileWithSearchModal
          isOpen
          onClose={() => localUpdate({ selectingFile: false })}
          onAction={(newFile) => {
            localUpdate({ selectingFile: false })
            update({ selectedFtpFile: newFile })
            if (exportDefinitionId) {
              update({ updateExportDefinition: true })
            }
          }}
          files={ftpFiles}
          loading={loadingFtpFiles}
          footerActionElement={
            <Button
              className={`${rootClass}__button-new-file`}
              buttonType={ButtonType.INFO}
              iconPosition={ButtonIconPosition.LEFT}
              onClick={() => localUpdate({ selectingFile: false, creatingFtpFile: true })}
            >
              <Svg name={SvgNames.downloadCloud} type={SvgType.ICON} />
              {t('Export to a new file')}
            </Button>
          }
        />
      )}
      {creatingFtpFile && (
        <ExportToFtpFileModal
          isOpen
          onClose={() => localUpdate({ selectingFile: true, creatingFtpFile: false })}
          onAction={(fileName) => {
            localUpdate({ creatingFtpFile: false })
            update({ selectedFtpFile: { name: `${fileName}.csv`, urlPath: `/${fileName}.csv`, size: 0 } })
          }}
          handleBack={() => localUpdate({ selectingFile: true, creatingFtpFile: false })}
          defaultFileName={exportSegment?.name}
        />
      )}
      {selectedFtpFile && (
        <Container>
          <div className={`${rootClass}__selected-file-wrapper`}>
            <Typography text={t('ExportContacts.Sections.ReviewExport.Summary.File.Title')} weight={TextWeight.MEDIUM} />
            <SelectedFile
              className={`${rootClass}__selected-file`}
              selectedFile={selectedFtpFile}
              onDelete={() => localUpdate({ selectingFile: true })}
            />
          </div>
        </Container>
      )}
      <div className={`${rootClass}__button-next`}>
        <Button buttonType={ButtonType.PRIMARY} onClick={() => handleOnSectionChange(ExportContactsSteps.EXPORT_OPTIONS, true)}>
          {t('Next')}
        </Button>
      </div>
    </div>
  )
}

export default ExportFileSelectionScreen
