import React, { useCallback } from 'react'

import { useApolloClient } from '@apollo/client'
import testClickthroughLink from '@graphql/queries/testClickthroughLink'
import { ClickthroughLinkInput, TestClickthroughLinkQuery, TestClickthroughLinkQueryVariables } from '@graphql/types/query-types'
import ClickthroughLinks from '@src/pages/Content/LandingPages/LandingPagesManager/components/LandingPageManagerClickthroughLinks/ClickthroughLinks'
import { useLandingPageContext } from '@src/pages/Content/LandingPages/LandingPagesManager/context/LandingPagesManager.context'

const ClickthroughLinksContainer = () => {
  const client = useApolloClient()
  const {
    landingPage: { id: pageId },
  } = useLandingPageContext()

  const testLink = useCallback(
    (link: ClickthroughLinkInput) =>
      client
        .query<TestClickthroughLinkQuery, TestClickthroughLinkQueryVariables>({
          query: testClickthroughLink,
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
          variables: {
            link,
            pageId,
          },
        })
        .then(({ data }) => data?.testClickthroughLink ?? { code: link.code, completed: false, hasWarning: false }),
    [client, pageId]
  )

  return <ClickthroughLinks testLink={testLink} />
}

export default ClickthroughLinksContainer
