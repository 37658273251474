import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import ConfirmationModal from '@components/ConfirmationModal'
import Container from '@components/Container'
import PageContainer from '@components/PageContainer'
import PageHeader from '@components/PageHeader'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import ProgressLine from '@components/ProgressLine/ProgressLine'
import StatusToast from '@components/StatusToast/StatusToast'
import Svg, { SvgType } from '@components/Svg'
import Toggletip from '@components/Toggletip/Toggletip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import FormsUpgradeModal from '@src/pages/UpgradeAssistant/components/FormsUpgradeModal/FormsUpgradeModal'
import UpgradeItem from '@src/pages/UpgradeAssistant/components/UpgradeItem/UpgradeItem'
import { useUpgradeLogModalRequests } from '@src/pages/UpgradeAssistant/components/UpgradeLogModal/GraphQL/UpgradeLogModalRequests.graphQL'
import UpgradeLogModal from '@src/pages/UpgradeAssistant/components/UpgradeLogModal/UpgradeLogModal'
import { AssetType, LogItem } from '@src/pages/UpgradeAssistant/components/UpgradeLogModal/UpgradeLogModal.constants'
import { useUpgradeAssistantRequests } from '@src/pages/UpgradeAssistant/GraphQL/UpgradeAssistantRequests.graphQL'
import {
  BANNER_TYPE,
  defaultState,
  ItemState,
  sections,
  sectionValues,
  UpgradeAssistantState,
} from '@src/pages/UpgradeAssistant/UpgradeAssistant.contants'
import {
  getCopySegmentsValues,
  getFormsValues,
  getImportValues,
  getProgramsValues,
  getUpgradeCompletedStatus,
  markAllAsComplete,
  onItemButtonClick,
  onItemViewLogClick,
  onLogUpgradeClick,
  onMarkAsComplete,
  onRemoveBannerAnswer,
  revertForm,
  revertProgram,
} from '@src/pages/UpgradeAssistant/UpgradeAssistant.utils'
import { useAccountSettings } from '@utils/account/account.utils'

import './UpgradeAssistant.css'

interface UpgradeAssistantProps {
  dataTest?: string
}

const rootClass = 'upgrade-assistant'

const UpgradeAssistant: FC<UpgradeAssistantProps> = (props: UpgradeAssistantProps) => {
  const { dataTest = rootClass } = props
  const [state, setState] = useState<UpgradeAssistantState>(defaultState)
  const { hasFormsUpgrade, hasCopySegments, hasAutomatedProgramsUpgrade } = useAccountSettings()
  const {
    getFormToUpgradeCount,
    getFormTemplatesCount,
    getAllContactsFormCount,
    getAllContactsUpgrade,
    setAllContactsUpgrade,
    getUpgradedSegmentCount,
    getUpgradedListsCount,
    getProgramsToUpgradeCount,
    getAllContactsProgramCount,
    restoreFormRequest,
    restoreProgramRequest,
    pauseProgramRequest,
  } = useUpgradeAssistantRequests()
  const { getUpgradeLogRequest } = useUpgradeLogModalRequests()
  const { t } = useTranslation()
  const history = useHistory()
  const {
    statusToast,
    items,
    completedItems,
    showConfirmationModal,
    loadingStatus,
    showFormsUpgradeModal,
    upgradeLogType,
    showUpgradeLogModal,
    externalIdToUpgrade,
    externalIdToUpgradeType,
  } = state
  const formsRequestedRef = useRef(false)
  const segmentsRequestedRef = useRef(false)
  const contactsRequestedRef = useRef(false)
  const programsRequestedRef = useRef(false)

  const total = items.length
  const actualValue = useMemo(() => items.filter((item) => item.state === ItemState.MARKED_AS_COMPLETED).length, [items])

  const percentage = useMemo(() => (total === 0 ? 0 : Math.round((actualValue / total) * 100)), [actualValue, total])
  const isComplete = percentage === 100
  const isNotStarted = useMemo(() => items.filter((item) => item.state === ItemState.NOT_STARTED).length === total, [items, total])
  const showFooter = useMemo(() => !completedItems?.includes(BANNER_TYPE), [completedItems])

  const loadFormsValues = () =>
    getFormsValues(getFormToUpgradeCount, getFormTemplatesCount, getAllContactsFormCount, getUpgradeLogRequest, t, setState)

  useEffect(() => {
    let widestButtonWidth = 0
    const buttons = document.querySelectorAll<HTMLButtonElement>(`.upgrade-item__upgrade-button`)
    buttons.forEach((button) => {
      const { width } = button.getBoundingClientRect()
      widestButtonWidth = Math.max(widestButtonWidth, width)
    })
    buttons.forEach((button) => {
      button.style.width = `${widestButtonWidth}px`
    })
  }, [items])

  useEffect(() => {
    if (completedItems !== undefined) {
      setAllContactsUpgrade(completedItems)
    }
  }, [completedItems])

  useEffect(() => {
    getUpgradeCompletedStatus(getAllContactsUpgrade, setState)

    if (hasFormsUpgrade && !formsRequestedRef.current) {
      loadFormsValues()
      formsRequestedRef.current = true
    }
    if (hasCopySegments && !segmentsRequestedRef.current) {
      getCopySegmentsValues(getUpgradedSegmentCount, t, setState)
      segmentsRequestedRef.current = true
    }
    if (!contactsRequestedRef.current) {
      getImportValues(getUpgradedListsCount, t, setState)
      contactsRequestedRef.current = true
    }
    if (hasAutomatedProgramsUpgrade && !programsRequestedRef.current) {
      getProgramsValues(getProgramsToUpgradeCount, getAllContactsProgramCount, getUpgradeLogRequest, t, setState)
      programsRequestedRef.current = true
    }
  }, [])

  const onRemoveBanner = () => setState((state) => ({ ...state, showConfirmationModal: true }))

  const onUpgradeLogModalClose = () => setState((state) => ({ ...state, showUpgradeLogModal: false, upgradeLogType: undefined }))

  const renderConfirmationModal = (
    <ConfirmationModal
      isYesNo
      isOpen
      dataTest={rootClass}
      className={classNames(rootClass)}
      title={t('Are you sure?')}
      body={t('UpgradeAssistant.RemoveBannerModal.Text')}
      onAnswer={(answer) => onRemoveBannerAnswer(answer, setState)}
    />
  )

  const onFormsUpgradeClose = () => {
    setState((state) => ({ ...state, showFormsUpgradeModal: false }))
    loadFormsValues()
  }

  const onUpgrade = (logItem: LogItem) => {
    setState((state) => ({ ...state, showUpgradeLogModal: false, upgradeLogType: undefined }))
    onLogUpgradeClick(logItem, setState, history)
  }

  const onRevert = (logItem: LogItem) => {
    setState((state) => ({ ...state, showUpgradeLogModal: false, upgradeLogType: undefined }))
    const itemAction: { [key in AssetType]: Function } = {
      [AssetType.FORM]: () => revertForm(restoreFormRequest, logItem, setState, t),
      [AssetType.FORM_TEMPLATE]: () => revertForm(restoreFormRequest, logItem, setState, t),
      [AssetType.PROGRAM]: () => revertProgram(restoreProgramRequest, pauseProgramRequest, logItem, setState, t),
    }
    itemAction[logItem.assetType]()
  }

  return (
    <PageContainer className={rootClass} dataTest={dataTest}>
      {showConfirmationModal && renderConfirmationModal}
      {showFormsUpgradeModal && (
        <FormsUpgradeModal externalId={externalIdToUpgrade} externalIdType={externalIdToUpgradeType} onClose={onFormsUpgradeClose} isOpen />
      )}
      {showUpgradeLogModal && upgradeLogType && (
        <UpgradeLogModal type={upgradeLogType} onClose={onUpgradeLogModalClose} onUpgrade={onUpgrade} onRevert={onRevert} />
      )}
      {statusToast?.showStatusToast && (
        <StatusToast
          message={statusToast?.statusMessage}
          status={statusToast.status}
          closeStatus={() => setState((state) => ({ ...state, statusToast: { ...statusToast, showStatusToast: false } }))}
        />
      )}
      <PositionContainer>
        <PageHeader className={`${rootClass}__header`} primaryText={t('UpgradeAssistant.Title')} linkBack leftContent></PageHeader>
        <div className={`${rootClass}__progress`}>
          <div className={`${rootClass}__progress-text`}>
            <Typography
              text={
                isNotStarted
                  ? t('UpgradeAssistant.Progress.Title.Not.Started')
                  : isComplete
                  ? `${t('UpgradeAssistant.Progress.Title.Complete')} \uD83C\uDF89`
                  : t('UpgradeAssistant.Progress.Title')
              }
              weight={TextWeight.MEDIUM}
            />
            <Typography text={t('UpgradeAssistant.Progress.Label', { percentage })} />
          </div>
          <ProgressLine total={total} actualValue={actualValue} />
        </div>
        {sections.map((section) => {
          const { label, icon } = sectionValues[section]
          const sectionItems = items.filter((item) => item.state === section)
          if (sectionItems.length !== 0) {
            return (
              <div key={label} className={`${rootClass}__section`}>
                <div className={`${rootClass}__state`}>
                  <Svg name={icon} type={SvgType.LARGE_ICON} />
                  <Typography text={t(label)} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
                </div>
                <Container
                  className={classNames(`${rootClass}__container`, {
                    [`${rootClass}__container-teal-border`]: sectionItems.length === items.length,
                  })}
                >
                  {sectionItems
                    .sort((a, b) => (a.position > b.position ? 1 : -1))
                    .map((item) => (
                      <UpgradeItem
                        key={item.label}
                        {...item}
                        state={section}
                        onButtonClick={() => onItemButtonClick(item.type, setState)}
                        onViewLogClick={item.hasViewLog ? () => onItemViewLogClick(item.type, setState) : undefined}
                        onMarkAsComplete={(checked) => onMarkAsComplete(checked, item.type, state, setState)}
                      />
                    ))}
                </Container>
              </div>
            )
          } else {
            return undefined
          }
        })}
        {!loadingStatus && showFooter && (
          <Container className={`${rootClass}__container-footer`}>
            <div className={`${rootClass}__container-footer-text`}>
              <div className={`${rootClass}__container-footer-header`}>
                <Typography text={t('UpgradeAssistant.Footer.Title')} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
                <Toggletip description={t('UpgradeAssistant.Footer.InfoTooltip')} />
              </div>
              <Typography
                text={t(isComplete ? 'UpgradeAssistant.Footer.Text.Complete' : 'UpgradeAssistant.Footer.Text')}
                type={TextType.BODY_TEXT_LIGHT}
              />
            </div>
            <Button buttonType={ButtonType.PRIMARY} onClick={() => (!isComplete ? markAllAsComplete(setState) : onRemoveBanner())}>
              {t(isComplete ? 'UpgradeAssistant.Footer.Button.Complete' : 'UpgradeAssistant.Footer.Button')}
            </Button>
          </Container>
        )}
      </PositionContainer>
    </PageContainer>
  )
}

export default UpgradeAssistant
