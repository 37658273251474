import { PersonalizationItem } from '@complex/Personalization/utils/Personalization.context'
import { SelectV2GroupedOption, SelectV2Option, SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { isGroupedOptionArrayType, isSingleValueArrayType } from '@components/SelectV2/SelectV2.utils'
import { useTranslation } from '@const/globals'
import { getPersonalizationCommon } from '@utils/personalization'

import { DisplayConditionsFieldItem, FieldOperator } from './DisplayConditions.types'

export const useFieldOperators = (field: DisplayConditionsFieldItem) => {
  const { t } = useTranslation()
  const { personalization } = field

  const { operators, options } = getOperatorOptions(personalization)

  const translatedOptions = isSingleValueArrayType(options)
    ? options.map((op) => ({ value: op.value, label: t(op.label) }))
    : isGroupedOptionArrayType(options)
    ? options.map((opGroup) => ({ ...opGroup, options: opGroup.options.map((op) => ({ value: op.value, label: t(op.label) })) }))
    : []

  return {
    options: translatedOptions,
    operator: operators.find((op) => op.key === field.operator) ?? operators[0],
  }
}

const baseKey = 'EmailComposer.DynamicContent.Operator'

const emailTextOperators: FieldOperator[] = [
  { key: 'EQUALS.TEXT', displayText: `${baseKey}.Is`, valueCount: 1, valueType: 'string' },
  { key: 'NOT_EQUALS.TEXT', displayText: `${baseKey}.IsNot`, valueCount: 1, valueType: 'string' },
  { key: 'CONTAINS.TEXT', displayText: `${baseKey}.Contains`, valueCount: 1, valueType: 'string' },
  { key: 'NOT_CONTAINS.TEXT', displayText: `${baseKey}.DoesNotContain`, valueCount: 1, valueType: 'string' },
  { key: 'STARTS_WITH.TEXT', displayText: `${baseKey}.StartsWith`, valueCount: 1, valueType: 'string' },
  { key: 'ENDS_WITH.TEXT', displayText: `${baseKey}.EndsWith`, valueCount: 1, valueType: 'string' },
  { key: 'IS_EMPTY.TEXT', displayText: `${baseKey}.IsBlank`, valueCount: 0, valueType: 'string' },
  { key: 'IS_NOT_EMPTY.TEXT', displayText: `${baseKey}.IsNotBlank`, valueCount: 0, valueType: 'string' },
]

const emailNumericOperators: FieldOperator[] = [
  { key: 'EQUALS.NUMBER', displayText: `${baseKey}.IsEqualTo`, valueCount: 1, valueType: 'number' },
  { key: 'NOT_EQUALS.NUMBER', displayText: `${baseKey}.IsNotEqualTo`, valueCount: 1, valueType: 'number' },
  { key: 'IS_GREATER_THAN.NUMBER', displayText: `${baseKey}.IsGreaterThan`, valueCount: 1, valueType: 'number' },
  { key: 'IS_LESS_THAN.NUMBER', displayText: `${baseKey}.IsLessThan`, valueCount: 1, valueType: 'number' },
  { key: 'IS_BETWEEN.NUMBER', displayText: `${baseKey}.IsBetween`, valueCount: 2, valueType: 'number' },
]

const emailDateOperators: FieldOperator[] = [
  // Commented out operators are not yet implemented on the backend
  { key: 'EQUALS_DATE.DATE', displayText: `${baseKey}.DateIs`, valueCount: 1, valueType: 'date' },
  // 'date is not': '?',
  { key: 'IS_BEFORE_DATE.DATE', displayText: `${baseKey}.DateIsBefore`, valueCount: 1, valueType: 'date' },
  { key: 'IS_AFTER_DATE.DATE', displayText: `${baseKey}.DateIsAfter`, valueCount: 1, valueType: 'date' },
  { key: 'IS_BETWEEN_DATES.DATE', displayText: `${baseKey}.DateIsBetween`, valueCount: 2, valueType: 'date' },
  // 'date is within': '?',
  // 'date is not within': '?',
]

const emailBirthdateOperators: FieldOperator[] = [
  ...emailDateOperators,
  { key: 'IS_NOT_EMPTY.DATE', displayText: `${baseKey}.IsKnown`, valueCount: 0, valueType: 'date' },
  { key: 'IS_EMPTY.DATE', displayText: `${baseKey}.IsUnknown`, valueCount: 0, valueType: 'date' },
]

const emailScoreOperators: FieldOperator[] = [
  ...emailNumericOperators,
  { key: 'IS_NOT_EMPTY.SCORE', displayText: `${baseKey}.IsKnown`, valueCount: 0, valueType: 'number' },
  { key: 'IS_EMPTY.SCORE', displayText: `${baseKey}.IsUnknown`, valueCount: 0, valueType: 'number' },
]

const emailPictureOperators: FieldOperator[] = [
  { key: 'IS_EMPTY.PICTURE', displayText: `${baseKey}.IsBlank`, valueCount: 0, valueType: 'string' },
  { key: 'IS_NOT_EMPTY.PICTURE', displayText: `${baseKey}.IsNotBlank`, valueCount: 0, valueType: 'string' },
]

const emailTimezoneOperators: FieldOperator[] = [
  { key: 'EQUALS.TIMEZONE', displayText: `${baseKey}.Is`, valueCount: 1, valueType: 'string' },
  { key: 'NOT_EQUALS.TIMEZONE', displayText: `${baseKey}.IsNot`, valueCount: 1, valueType: 'string' },
  { key: 'IS_NOT_EMPTY.TIMEZONE', displayText: `${baseKey}.IsKnown`, valueCount: 0, valueType: 'string' },
  { key: 'IS_EMPTY.TIMEZONE', displayText: `${baseKey}.IsUnknown`, valueCount: 0, valueType: 'string' },
]

const emailZipcodeOperators: FieldOperator[] = [
  { key: 'EQUALS.ZIPCODE', displayText: `${baseKey}.Is`, valueCount: 1, valueType: 'number' },
  { key: 'NOT_EQUALS.ZIPCODE', displayText: `${baseKey}.IsNot`, valueCount: 1, valueType: 'number' },
  { key: 'IS_EMPTY.ZIPCODE', displayText: `${baseKey}.IsBlank`, valueCount: 0, valueType: 'number' },
  { key: 'IS_NOT_EMPTY.ZIPCODE', displayText: `${baseKey}.IsNotBlank`, valueCount: 0, valueType: 'number' },
]

export const allFieldOperators = [
  ...emailTextOperators,
  ...emailNumericOperators,
  ...emailDateOperators,
  ...emailPictureOperators,
  ...emailTimezoneOperators,
  ...emailZipcodeOperators,
]

export const findFieldOperator = (key: string) => allFieldOperators.find((op) => op.key === key || op.key.split('.')[0] === key)

export const emailGroupingOperators = { any: `${baseKey}.Any`, all: `${baseKey}.All` } as const

const getOperatorSelectOptions = (operators: FieldOperator[]): SelectV2SingleOption[] =>
  operators.map(({ key, displayText }) => ({ value: key, label: displayText }))

export const emailCustomOptions: SelectV2GroupedOption[] = [
  {
    label: 'Text Fields',
    options: getOperatorSelectOptions(emailTextOperators),
  },
  {
    label: 'Numeric Fields',
    options: getOperatorSelectOptions(emailNumericOperators),
  },
  {
    label: 'Date Fields',
    options: getOperatorSelectOptions(emailDateOperators),
  },
]

export const emailTextOptions = getOperatorSelectOptions(emailTextOperators)
export const emailNumericOptions = getOperatorSelectOptions(emailNumericOperators)
export const emailScoreOptions = getOperatorSelectOptions(emailScoreOperators)
export const emailDateOptions = getOperatorSelectOptions(emailDateOperators)
export const emailPictureOptions = getOperatorSelectOptions(emailPictureOperators)
export const emailBirthdateOptions = getOperatorSelectOptions(emailBirthdateOperators)
export const emailTimezoneOptions = getOperatorSelectOptions(emailTimezoneOperators)
export const emailZipcodeOptions = getOperatorSelectOptions(emailZipcodeOperators)
export const emailGroupingOptions: SelectV2SingleOption[] = Object.entries(emailGroupingOperators).map(([value, label]) => ({ value, label }))

type OperatorOptions = {
  operators: FieldOperator[]
  options: SelectV2Option[]
}

const getOperatorOptions = (personalization: PersonalizationItem | undefined): OperatorOptions => {
  const mapping = personalization ? getPersonalizationCommon(personalization)?.mappingName.toLocaleLowerCase() : ''
  if (mapping.includes('timezone') || mapping.includes('time zone')) {
    return {
      operators: emailTimezoneOperators,
      options: emailTimezoneOptions,
    }
  } else if (mapping.includes('act-on') && mapping.includes('score')) {
    return {
      operators: emailNumericOperators,
      options: emailNumericOptions,
    }
  } else if (mapping.includes('postal')) {
    return {
      operators: emailZipcodeOperators,
      options: emailZipcodeOptions,
    }
  } else if (mapping.includes('picture')) {
    return {
      operators: emailPictureOperators,
      options: emailPictureOptions,
    }
  } else if (mapping.includes('date of birth')) {
    return {
      operators: emailBirthdateOperators,
      options: emailBirthdateOptions,
    }
  } else if (mapping.includes('phone')) {
    return {
      operators: emailTextOperators.map((op) => ({ ...op, valueType: 'number' })),
      options: emailTextOptions,
    }
  } else {
    return {
      operators: [...emailTextOperators, ...emailNumericOperators, ...emailDateOperators],
      options: emailCustomOptions,
    }
  }
}
