import React, { FC } from 'react'

import classNames from 'classnames'

import Svg, { SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import SvgNames from '../Svg/SvgNames'

import './NumberedList.css'

interface NumberedListItem {
  name: string
  notInTop20?: boolean
  visits?: string
  svgName?: SvgNames
}

interface Props {
  title: string
  items: NumberedListItem[]
  className?: string
  dataTest?: string
  mainToolTip?: string
}

const rootClass = 'numbered-list'

const NumberedList: FC<Props> = (props: Props) => {
  const { title, items, dataTest = rootClass, className = '', mainToolTip } = props
  const { t } = useTranslation()
  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Typography text={title} type={TextType.TABLE_HEADER} className={`${rootClass}__title`} />
      {items.map((item, idx) => (
        <div key={item.name} className={`${rootClass}__items`}>
          <Typography text={`${idx + 1}. `} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.BOLD} inline />
          {mainToolTip ? (
            <Tooltip
              trigger={
                <Typography text={item.name} type={TextType.BODY_TEXT_SMALL} lineHeight={LineHeight.MEDIUM} dataTest={`${dataTest}-text`} inline />
              }
            >
              {t(`${item.name} - ${item.visits} ${mainToolTip}`)}
            </Tooltip>
          ) : (
            <Typography text={item.name} type={TextType.BODY_TEXT_SMALL} lineHeight={LineHeight.MEDIUM} dataTest={`${dataTest}-text`} inline />
          )}

          {item.notInTop20 && (
            <Tooltip
              trigger={
                <mark className={`${rootClass}__mark`} data-test={`${rootClass}__mark`}>
                  <Typography text="*" type={TextType.ERROR} className={`${rootClass}__not-top-20`} inline />
                </mark>
              }
            >
              {t('Not in Top 20 Last Month')}
            </Tooltip>
          )}
          {item.svgName && <Svg name={item.svgName} type={SvgType.ICON} />}
        </div>
      ))}
    </div>
  )
}

export default NumberedList
