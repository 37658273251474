import { gql } from '@apollo/client'

export default gql`
  query testClickthroughLink($pageId: String!, $link: ClickthroughLinkInput) {
    testClickthroughLink(pageId: $pageId, link: $link) {
      code
      link
      completed
      reason
      hasWarning
    }
  }
`
