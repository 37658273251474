import { ProgramOption } from '../ProgramSettings/ProgramSettings'

interface ProgramOptionDetail {
  optionKey: keyof ProgramOption
  title: string
  description: string
}

export const ProgramOptionDetails: ProgramOptionDetail[] = [
  {
    optionKey: 'allowLooping',
    title: 'Allow contacts to repeat steps',
    description:
      'Contacts will be allowed to repeat previously executed steps as long as there is a Wait or Wait Until step before the repeated step is run.',
  },
  {
    optionKey: 'suppressBadEmails',
    title: 'Suppress entrants on suppression lists',
    description:
      'Contact email addresses on the Opt-Out, Hard-Bounce, Spam Complaint or Email Suppression Domains List will not be entered into the program.',
  },
  {
    optionKey: 'historyDisabled',
    title: 'Disable program history',
    description:
      'Contact progression through each step will not be recorded and the program report and dashboards will be disabled for this program.',
  },
  {
    optionKey: 'allowReentrantAddresses',
    title: 'Allow contacts to re-enter',
    description:
      'Contacts may re-enter the program once they have completed or exited. (Warning: Enabling this function will cause the program report to become inaccurate.Entrance and exit data will not be available for segmentation or on the Contact Report.)',
  },
  {
    optionKey: 'useAlternateUniqueField',
    title: 'Use alternate unique identifier',
    description: `Select a field name to use to identify unique contacts. (Default is 'Contact's email address').`,
  },
  {
    optionKey: 'allowBypassFatigueRules',
    title: 'Ignore email fatigue rules',
    description: 'Allow contacts to receive messages in excess of Email Fatigue rules.',
  },
  {
    optionKey: 'sforceNote',
    title: 'Attach sent notes to contacts or leads from my CRM lists.',
    description: `Leave a note or task in your CRM indicating a message was sent to the contact. Applies to all messages in the program regardless of the message setting.`,
  },
]
