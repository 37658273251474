import React, { FC, useState } from 'react'

import classNames from 'classnames'

import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { useTranslation } from '@const/globals'
import DateCriteria, { DateCriteriaType } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/DateCriteria/DateCriteria'
import { getSystemOptions } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/SystemExpression/SystemExpression.utils'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'

import './SystemExpression.css'

interface SystemExpressionProps {
  className?: string
  dataTest?: string
}

interface SystemExpressionState {
  systemRecord: SelectV2SingleOption
  dateCriteria: DateCriteriaType
}

const rootClass = 'system-expression'
const defaultSystemIndex = 0

const SystemExpression: FC<SystemExpressionProps> = (props: SystemExpressionProps) => {
  const { dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const systemOptions = getSystemOptions(t)
  const [state, setState] = useState<SystemExpressionState>({
    systemRecord: systemOptions[defaultSystemIndex],
    dateCriteria: {},
  })
  const { systemRecord, dateCriteria } = state
  const update = useDeepUpdate(setState)

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <SelectV2
        dataTest={`${dataTest}-record-select`}
        className={`${rootClass}__record-select`}
        options={systemOptions}
        defaultValue={systemRecord}
        onChange={(systemRecord?: SelectV2SingleOption) => update({ systemRecord })}
        isClearable={false}
        isSearchable={false}
        truncateOptions
      />
      <DateCriteria dateCriteria={dateCriteria} onChange={(dateCriteria) => update({ dateCriteria })} />
    </div>
  )
}

export default SystemExpression
