import { gql } from '@apollo/client'

export default gql`
  query listsValidation($messageId: String!) {
    listsValidation(messageId: $messageId) {
      listsWithMissingEmailAddressColumn
      deletedListNames
      trashListNames
      deletedSuppressionListNames
      trashSuppressionListNames
      suppressionListsWithMissingEmailAddressColumn
    }
  }
`
