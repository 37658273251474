import getImportCount from '@graphql/microservices/list/getImportCount'
import { GetImportDetailsStatusQuery, GetImportDetailsStatusQueryVariables } from '@graphql/types/microservice/list-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export type GetImportCountRequestType = () => FetchPromise<GetImportDetailsStatusQuery>

export interface ContactSegmentsListServiceRequests {
  getImportCountRequest: GetImportCountRequestType
}

export const useContactSegmentsListServiceRequest = (): ContactSegmentsListServiceRequests => {
  const {
    client: { query },
  } = useMicroserviceClient({ serviceName: MicroserviceClients.LIST })

  const getImportCountRequest = () =>
    query<GetImportDetailsStatusQuery, GetImportDetailsStatusQueryVariables>({
      query: getImportCount,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        importDetailsStatus: {
          count: 0,
          pageNumber: 1,
          pageSize: 99999,
          filterBy: {
            source: ['ACTON_LIST'],
            status: ['COMPLETED'],
            total_unsubmitted: ['0'],
          },
        },
      },
    })

  return {
    getImportCountRequest,
  }
}
