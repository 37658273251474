import { t } from 'i18next'

import { ImagePickerSource } from '@components/AssetPickers/ImagePickerModal/utils/ImagePickerModal.utils'
import { SplitButtonOptionsProps } from '@components/SplitButton/SplitButton'
import { SvgNames } from '@components/Svg'

export enum InsertImageFlow {
  BROWSE = 'browse',
  URL = 'url',
  UPLOAD = 'upload',
  DYNAMIC_IMAGE = 'DYNAMIC_IMAGE',
  DYNAMIC_LOGO = 'DYNAMIC_LOGO',
}

interface AddImageFlowActions extends SplitButtonOptionsProps {
  flow: InsertImageFlow
}

export const getAddImageFlowActions = (isDynamicImageEnabled?: boolean): AddImageFlowActions[] => {
  const actions = [
    {
      key: 'upload',
      icon: SvgNames.upload,
      title: t('Upload image'),
      flow: InsertImageFlow.UPLOAD,
    },
    {
      key: 'link',
      icon: SvgNames.url,
      title: t('Add image from URL'),
      flow: InsertImageFlow.URL,
    },
  ]

  if (isDynamicImageEnabled) {
    actions.push({
      key: 'dynamic-image',
      icon: SvgNames.imageLeftCurve,
      title: t('DynamicImage.pickerOption'),
      flow: InsertImageFlow.DYNAMIC_IMAGE,
    })
  }

  return actions
}

enum MetadataFields {
  SOURCE_METADATA = 'actOnSrc',
  ID_METADATA = 'id',
  TS_METADATA = 'ts',
  FIELD_METADATA = 'dynamicField',
  LIST_ID = 'listId',
  FALLBACK_TITLE = 'fbTitle',
}

interface addSourceMetadataToImageURlProps {
  url: string
  source: ImagePickerSource
  id?: string
  modified?: number
  selectedField?: string
  listId?: string
  fallbackTitle?: string
}

export const addSourceMetadataToImageURl = ({
  url,
  source,
  id,
  modified,
  selectedField,
  listId,
  fallbackTitle,
}: addSourceMetadataToImageURlProps): string => {
  const urlObj = new URL(url)
  urlObj.searchParams.set(MetadataFields.SOURCE_METADATA, source)
  id && urlObj.searchParams.set(MetadataFields.ID_METADATA, id)
  modified && urlObj.searchParams.set(MetadataFields.TS_METADATA, `${modified}`)
  selectedField && urlObj.searchParams.set(MetadataFields.FIELD_METADATA, `${selectedField}`)
  listId && urlObj.searchParams.set(MetadataFields.LIST_ID, `${listId}`)
  fallbackTitle && urlObj.searchParams.set(MetadataFields.FALLBACK_TITLE, `${fallbackTitle}`)
  return urlObj.toString()
}
export const getSourceMetadataFromImageURl = (
  url?: string
): {
  url?: string
  source?: ImagePickerSource
  id?: string
  dynamicField?: string | null
  listId?: string | null
  fallbackTitle?: string | null
} => {
  if (!url) {
    return {}
  }
  try {
    const urlObj = new URL(url)
    const { searchParams, pathname, origin } = urlObj
    const _source = searchParams.get(MetadataFields.SOURCE_METADATA)
    const dynamicField = searchParams.get(MetadataFields.FIELD_METADATA)
    const listId = searchParams.get(MetadataFields.LIST_ID)
    const fallbackTitle = searchParams.get(MetadataFields.FALLBACK_TITLE)
    let source = undefined

    if (Object.values(ImagePickerSource).includes(_source as ImagePickerSource)) {
      source = _source as ImagePickerSource
    }

    return {
      url: `${origin}${pathname}`,
      source,
      id: searchParams.get(MetadataFields.ID_METADATA) ?? undefined,
      dynamicField,
      listId,
      fallbackTitle,
    }
  } catch (e) {
    return {}
  }
}
