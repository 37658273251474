import React, { FC, useContext, useEffect, useState } from 'react'

import { ListingPageCommonContext } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { EmptyListingProps } from '@components/EmptyListing/EmptyListing'
import PreviewAssetModal, { PreviewModalBackground, PreviewModalSize } from '@components/PreviewAssetModal/PreviewAssetModal'
import { Status } from '@components/StatusToast/StatusToast'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import { useTranslation } from '@utils/const/globals'

export interface ListingPagePreviewProps {
  itemName: string
  itemId: string
  isOpen: boolean
  onClose: () => void
  onBack?: () => void
  className?: string
  dataTest?: string
}

const rootClass = 'listing-page-preview'

const ListingPagePreview: FC<ListingPagePreviewProps> = (props: ListingPagePreviewProps) => {
  const { itemName, isOpen, onClose, onBack, dataTest = rootClass, className = rootClass } = props

  const {
    values,
    update,
    setError,
    values: {
      itemType,
      previewHtml,
      previewUrl,
      selectedRows,
      statusToast,
      listingPageProps: { customPreviewItemCall, getPreviewMenuActions },
    },
  } = useContext(ListingPageCommonContext)

  const { t } = useTranslation()
  const { newLPComposerCreateBlank } = useAccountSettings()

  const [loading, setLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    if (customPreviewItemCall) {
      customPreviewItemCall(values, update, setError)
    } else {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (previewHtml) {
      setLoading(false)
    } else if (statusToast.showStatusToast && statusToast.status === Status.FAIL) {
      // Detecting if customPreviewItemCall() failed
      setLoading(false)
      setIsError(true)
    }
  }, [previewHtml, statusToast])

  const emptyListingPropsForLandingPage: Partial<EmptyListingProps> | undefined =
    newLPComposerCreateBlank && itemType === ItemType.LANDING_PAGE
      ? { headline: t('No preview available'), text: t('ListPage.LandingPages.EmptyState.Preview.Text') }
      : undefined

  return (
    <PreviewAssetModal
      isOpen={isOpen}
      title={itemName}
      previewHtml={previewHtml}
      assetSrc={previewUrl}
      loading={previewUrl ? false : loading}
      size={PreviewModalSize.MEDIUM}
      error={isError}
      onClose={onClose}
      checkIsPreviewEmpty={
        itemType === ItemType.LANDING_PAGE ||
        itemType === ItemType.EMAIL_TEMPLATE ||
        itemType === ItemType.FORM ||
        itemType === ItemType.FORM_TEMPLATE
      }
      checkIsPreviewEmptyTimeout={itemType === ItemType.EMAIL_TEMPLATE ? 1000 : undefined}
      className={className}
      dataTest={dataTest}
      actions={getPreviewMenuActions?.(selectedRows[0])}
      onBackButtonClick={onBack}
      assetType={itemType?.replace('_', ' ').toLowerCase()}
      background={PreviewModalBackground.GREY}
      emptyListingProps={emptyListingPropsForLandingPage}
    />
  )
}

export default ListingPagePreview
