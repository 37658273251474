import React, { FC, useCallback, useEffect, useRef, useState } from 'react'

import { default as BeePlugin } from '@beefree.io/sdk'
import {
  BeePluginErrorCodes,
  IBeeConfig,
  ILoadConfig,
  IMergeTag,
  IPluginDisplayCondition,
  IPluginFilePicker,
  IPluginRow,
  ISpecialLink,
  RowDisplayConditionsHandler,
} from '@beefree.io/sdk/dist/types/bee'
import { Status } from '@components/StatusToast/StatusToast'
import { CACHE_BUSTER, getUUID, isProd, useTranslation } from '@const/globals'
import BeeEditor from '@src/pages/EmailComposer/BeeEditor/BeeEditor'
import {
  CHANGE_TYPE,
  CO_EDITING_USERS_LIMIT,
  INVALID_SESSION_ERROR_CODE,
  IS_FIRST_MSG_OPENER,
  TrackingMessageChangesCodes,
  actionDoneCallbacksDefault,
  contentDefaults,
  editorFonts,
  getAdvancedPermissions,
  getRowsConfiguration,
  modalsInitialState,
  modulesGroups,
  titleDefaultStyles,
} from '@src/pages/EmailComposer/utils/BeeEditor.constants'
import {
  beeActionHelper,
  extractSessionUserFields,
  getSelectedImageUrl,
  handleEditRow,
  handleSaveRow,
  removeOneElFromList,
  renderAddOnModal,
  startBeeSession,
} from '@src/pages/EmailComposer/utils/BeeEditor.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import { filterNotEmptyArray } from '@utils/array'
import { IEntityContentJsonExtended, IPluginDisplayConditionExtended } from '@utils/composer/beeEditor/beeEditorTypes'
import { CommonComposerTab } from '@utils/composer/commonComposer/CommonComposer.context'
import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'
import { useEmailComposerRequests } from '@utils/composer/emailComposer/GraphQL/EmailComposerRequests.graphQL'
import { isCustomerCareLogin } from '@utils/cookie'
import { logError } from '@utils/env'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { logNewRelicError } from '@utils/new-relic.utils'
import { getSessionStorageItem, removeSessionStorageItem, setSessionStorageItem } from '@utils/window'

import { replaceUserSelectNone } from '../EmailModals/components/FormBlockModal/FormBlock.utils'
import {
  handleWebinarRestore,
  synchDeletedRowsForWebinar,
} from '../EmailModals/components/ManageWebinarDetailsModal/components/WebinarBlock/utils/webinarBlock.utils'
import {
  webinarRestoreCodes,
  webinarRestoreDescriptions,
} from '../EmailModals/components/ManageWebinarDetailsModal/components/WebinarBlock/WebinarBlock'
import { EmailEditModalState, EmailModals, EmailModalsState } from '../EmailModals/EmailModals'
import { getBeeTranslations } from '../utils/BeeEditor.translations'
import {
  BeeEditorActionDoneCallbacks,
  BeeSessionTypes,
  CoEditingInfoRef,
  EmailModalArgs,
  IAddOnResponse,
  ISaveRow,
  OnSessionChange,
} from '../utils/BeeEditor.types'
import { detectEmailType } from '../utils/EmailComposerDetector.utils'

import './BeeEditor.css'

interface BeeEditorContainerProps {
  className?: string
  dataTest?: string
}

interface AccessTokenResponse {
  access_token: string
  token_type: string
  expires_in: number
  refresh_token: string
  as_client_id: string
  userName: string
  as_region: string
  '.issued': string
  '.expires': string
}

export interface NewRow extends IPluginRow {
  name: string
}

export interface RowArgs {
  handle: string
  label: string
  row?: NewRow
}

const BeeEditorContainer: FC<BeeEditorContainerProps> = (props: BeeEditorContainerProps) => {
  const { className } = props
  const {
    values: {
      startSession,
      isCoEditing,
      shouldStartNewSession,
      beeEditorRef,
      message: { templateJson },
      lastEmailContentEditSession,
      savedRowCategories,
      isStory,
      sessionId,
      messageConfiguration: { messageType },
      landingPage: {
        isLandingPage,
        landingPageMetaImage,
        landingPageMetaTitle,
        landingPageMetaDescription,
        landingPageVisibility,
        customJavaScript,
        customCss,
        selectedCss,
        selectedScripts,
      },
      beeClientId,
      beeClientSecret,
      tab,
      brandingFavicon,
    },
    api: { eventHooks, update, updateModal },
  } = useComposerContext()

  const { client: emailManagementClient } = useMicroserviceClient({ serviceName: MicroserviceClients.EMAIL_MANAGEMENT_PROXY })

  const emailManagementClientRef = useRef(emailManagementClient)

  const { deleteSavedRowRequest, updateSavedRowRequest, saveSavedRowRequest, getSavedRowsRequest } = useEmailComposerRequests()

  const [modalState, setModalState] = useState<EmailModalsState>(modalsInitialState)
  const updateModalState = useDeepUpdate(setModalState)

  const [editModalState, setEditModalState] = useState<EmailEditModalState>({})
  const updateEditModalState = useDeepUpdate(setEditModalState)

  const [rowData, setRowData] = useState<IPluginRow[]>([])
  const [customRowMetaData, setCustomRowMetaData] = useState<Record<string, string>>()

  const [rowArgs, setRowArgs] = useState({ handle: '', label: '' })

  const anyModalOpen = Object.values(modalState).some((modal) => modal === true)
  const { accountId, userId, userName, userEmail, newLPComposerCreateBlank, newLPComposerFormBlock, newLPComposerMapBlock } = useAccountSettings()
  const { t, i18n } = useTranslation()
  const rowsRef = useRef<IPluginRow[]>()
  const shouldForceStartOnceRef = useRef(true)
  const coEditingInfoRef = useRef<CoEditingInfoRef>({
    userIds: [],
    userNames: [],
  })

  const startedOnceRef = useRef(false)
  const isJoinedToSessionRef = useRef(false)
  const templateJsonRef = useRef<IEntityContentJsonExtended>()
  const actionDoneCallbacks = useRef<BeeEditorActionDoneCallbacks>(actionDoneCallbacksDefault)
  const coEditingUsername = isCustomerCareLogin() ? 'Act-On Customer Care' : userName === '' ? userEmail : userName
  const triggerBeeSessionOnce = useRef(true)

  const startBeeSessionFn = () => startBeeSession({ beeEditorRef, beeFinalConfig, templateJsonRef }).then(() => update({ isBeeLoading: true }))

  const setRow = (row: string) => setRowData((prevRows: IPluginRow[]) => [...prevRows, JSON.parse(row)])

  const handleDeleteRow = async (arg: IPluginRow) => {
    setRowData((prevRows) => prevRows.filter((row) => row?.metadata?.name !== arg?.metadata?.name))
    await deleteSavedRowRequest((arg.metadata?.id as number) ?? -1)
  }

  const closeModals = useCallback(() => setModalState(modalsInitialState), [])

  const handleGetSavedRows = useCallback(
    async (resolve: any, reject: any, args: any) => {
      const matchingCategory = savedRowCategories.find((category) => category.name === args.handle)
      const categoryId = matchingCategory?.id ?? -1
      const isDefaultCategory = matchingCategory?.isDefault ?? true

      const { data, errors } = await getSavedRowsRequest(categoryId, isDefaultCategory)
      if (errors) {
        reject()
      }
      if (data?.getEmailComposerRows) {
        const rows = data.getEmailComposerRows.filter(filterNotEmptyArray).map((row) => JSON.parse(row))
        setRowData(rows)
        update({ savedRows: rows })
        resolve(rows)
      }
    },
    [getSavedRowsRequest, emailManagementClientRef.current]
  )
  if (templateJson) {
    contentDefaults.general.linkColor = templateJson.page.body.content.computedStyle.linkColor
  }

  const intl = new Intl.Locale(i18n.language).maximize()
  const locale = `${intl.language}-${intl.region}`

  const { isEmailRSS } = detectEmailType(messageType)

  const beeFinalConfig: IBeeConfig = {
    container: 'bee-plugin-container',
    uid: `DEV-COMPOSER`,
    username: coEditingUsername,
    titleDefaultStyles: titleDefaultStyles,
    contentDefaults: contentDefaults,
    userColor: 'black',
    userHandle: `${accountId}-${userId}-${getUUID()}`,
    language: locale,
    // @ts-ignore -- not present in typedefs
    translations: getBeeTranslations(t, isLandingPage),
    customCss: `${window.location.origin}${!isStory ? `/app/static` : ''}/bee/bee-ao-styles.css?v=${!isProd() ? new Date().valueOf() : CACHE_BUSTER}`,
    modulesGroups: modulesGroups(isLandingPage, isEmailRSS),
    saveRows: isLandingPage ? newLPComposerCreateBlank : true,
    enable_display_conditions: true,
    loadingSpinnerDisableOnSave: isLandingPage,
    hooks: {
      getRows: {
        handler: handleGetSavedRows,
      },
    },
    advancedPermissions: getAdvancedPermissions(messageType, isLandingPage, newLPComposerFormBlock, newLPComposerMapBlock),
    rowsConfiguration: getRowsConfiguration?.(isLandingPage ? newLPComposerCreateBlank : true, savedRowCategories),
    editorFonts: editorFonts,
    // This will only function for LP composer
    onSave: (_, pageHtml) => {
      eventHooks.onPreviewSend?.(
        replaceUserSelectNone(pageHtml ?? ''),
        landingPageMetaImage,
        landingPageMetaTitle,
        landingPageMetaDescription,
        landingPageVisibility,
        customJavaScript,
        customCss,
        selectedCss,
        selectedScripts,
        brandingFavicon
      )
    },
    onSend: (html) => {
      eventHooks.onPreviewSend?.(
        html,
        landingPageMetaImage,
        landingPageMetaTitle,
        landingPageMetaDescription,
        landingPageVisibility,
        customJavaScript
      )
    },
    onSaveRow: async (jsonFile) => {
      await setRow(jsonFile)
    },
    onError: (error) => {
      if (error.code === (INVALID_SESSION_ERROR_CODE as BeePluginErrorCodes)) {
        startBeeSessionFn()
        startedOnceRef.current = true
      } else {
        updateModalState({ statusToast: { status: Status.FAIL, message: error.message, closeStatus: closeModals } })
      }
    },
    trackChanges: true,
    onChange: (pageJson, detail, version) => {
      let computedJson = pageJson

      if (isLandingPage) {
        beeEditorRef.current?.save()
      } else {
        beeEditorRef.current?.send()
      }

      const isMessageRestored = detail.code == TrackingMessageChangesCodes.HistoryRestored && detail.description == 'Message restored'
      /* 
       Handling when users may trigger BEE API history or our restore functionality.
       For that case we need to handle and synch the deleted rows
      */
      if (isMessageRestored) {
        computedJson = handleWebinarRestore(pageJson, templateJsonRef, true)
      }

      /*
        This code is must have to keep track of current deleted WebinarBlocks 
        because pageJson and templateJsonRef is not synched after deleting some other blocks.
      */
      if (pageJson.includes('deletedRows') && !isMessageRestored) {
        computedJson = synchDeletedRowsForWebinar(pageJson, templateJsonRef)
      }

      if (webinarRestoreCodes.includes(detail.code as string) && webinarRestoreDescriptions.includes(detail.description)) {
        computedJson = handleWebinarRestore(pageJson, templateJsonRef) ?? pageJson
      }

      eventHooks.onChange?.(computedJson, detail, version)
    },
    // @ts-ignore -- typedef for this handler is incorrect
    onSessionChange: ({ change, sessionData }: OnSessionChange) => {
      const userNames: string[] = extractSessionUserFields(change.value, 'username')
      const userIds: string[] = extractSessionUserFields(change.value, 'userId')

      coEditingInfoRef.current.userIds = [...(coEditingInfoRef.current.userIds ?? []), ...(change.type === CHANGE_TYPE.USER_LEFT ? [] : userIds)]
      coEditingInfoRef.current.userNames = [
        ...(coEditingInfoRef.current.userNames ?? []),
        ...(change.type === CHANGE_TYPE.USER_LEFT ? [] : userNames),
      ]

      const onlyOneUserInSession = Object.keys(sessionData.users).length === CO_EDITING_USERS_LIMIT

      if (change.type === CHANGE_TYPE.USER_JOINED && userNames.length > CO_EDITING_USERS_LIMIT) {
        userNames.splice(userNames.indexOf(coEditingUsername), 1)
        eventHooks.onSessionFull?.(userNames.splice(-1, CO_EDITING_USERS_LIMIT))
        update({ isBeeLoading: false })
      }

      if (change.type === 'USER_LEFT') {
        shouldForceStartOnceRef.current = false
        coEditingInfoRef.current.userNames = removeOneElFromList(coEditingInfoRef.current.userNames, change.value.username)
        coEditingInfoRef.current.userIds = removeOneElFromList(coEditingInfoRef.current.userIds, change.value.userId)
      }

      if (!isCoEditing && onlyOneUserInSession && shouldForceStartOnceRef.current && !startedOnceRef.current) {
        shouldForceStartOnceRef.current = false
        coEditingInfoRef.current.userNames.pop()
        coEditingInfoRef.current.userIds.pop()
        startBeeSessionFn()
        startedOnceRef.current = true
      }

      if (isLandingPage) {
        beeEditorRef.current?.save()
      } else {
        beeEditorRef.current?.send()
      }

      // Not needed in R1 because co-editing is disabled, will be used in the feature
      // if (change.type === 'USER_JOINED') {
      //   setModalState((state) => ({
      //     ...state,
      //     statusToast: { status: Status.SUCCESS, message: `${allUserNames.join(', ')} is in the session`, closeStatus: closeModals },
      //   }))
      // } else if (change.type === 'USER_LEFT') {
      //   setModalState((state) => ({
      //     ...state,
      //     statusToast: { status: Status.WARNING, message: `${allUserNames.join(', ')} left the session`, closeStatus: closeModals },
      //   }))
      // }
    },
    onSessionStarted: (sessionInfo) => {
      eventHooks.onSessionStarted?.(sessionInfo)
      if (isLandingPage) {
        beeEditorRef.current?.save()
      } else {
        beeEditorRef.current?.send()
      }
    },
    onLoad: () => eventHooks.onLoadWithSessionStatus?.(templateJsonRef.current!, isJoinedToSessionRef.current),
    onWarning: (_error) => undefined,
    onInfo: (_info) => undefined,
    onReady: (_args) => undefined,
    onLoadWorkspace: (_workspace) => undefined,
    contentDialog: {
      rowDisplayConditions: {
        label: 'Edit conditions',
        handler: async (resolve: (data: RowDisplayConditionsHandler) => void, reject: () => void, args: RowDisplayConditionsHandler) => {
          // `RowDisplayConditionsHandler` doesn't include the `conditions: DisplayConditionSet` that defines what the conditions actually are
          const displayCondition: IPluginDisplayCondition = args as IPluginDisplayConditionExtended
          updateModalState({ dynamicContent: displayCondition })
          actionDoneCallbacks.current.onDisplayConditionDone = (data) => {
            const resolver = resolve as (data: IPluginDisplayConditionExtended) => void
            if (data) {
              resolver(data)
            } else {
              reject()
            }
            closeModals()
          }
        },
      },
      onEditRow: {
        handler: async (resolve, reject, args: any) => {
          updateModalState({ statusToast: undefined })
          updateModalState({ savedRowsEdit: true })
          setRowArgs(args)
          try {
            actionDoneCallbacks.current.onEditRowDone = async (data) => {
              if (data) {
                handleEditRow({
                  newValue: data.name,
                  args: args.row,
                  update,
                  closeModals,
                  setRowData,
                  updateModalState,
                  updateSavedRowRequest,
                })
                resolve(true)
              } else {
                reject()
                closeModals()
              }
            }
          } catch (error) {
            reject()
          }
        },
      },
      saveRow: {
        handler: async (resolve, reject, args: any) => {
          updateModalState({ statusToast: undefined })
          updateModalState({ saveRowModal: true })
          setRowArgs(args)

          try {
            actionDoneCallbacks.current.onSaveRowDone = (data?: ISaveRow) => {
              if (data) {
                const columnData = JSON.stringify({
                  columns: args.columns,
                  container: args.container,
                  content: args.content,
                  empty: false,
                  locked: false,
                  synced: false,
                  type: args.type,
                  uuid: args.uuid,
                  webfonts: [],
                })
                const category = savedRowCategories[0]
                if (category) {
                  handleSaveRow({
                    category,
                    args: args.row,
                    name: data.name,
                    newRowData: columnData,
                    update,
                    setRowData,
                    closeModals,
                    logNewRelicError,
                    saveSavedRowRequest,
                    updateModalState,
                  })
                }
                const metaData = {
                  ...data,
                  isSaveRow: true,
                }
                resolve(metaData)
              } else {
                reject()
                closeModals()
              }
            }
          } catch (error) {
            reject()
          }
        },
      },
      onDeleteRow: {
        handler: async (resolve, _reject, args: any) => {
          await handleDeleteRow(args.row)
          resolve(true)
        },
      },
      specialLinks: {
        label: 'Asset Links',
        handler: async (resolve, reject) => {
          try {
            updateModalState({ selectAsset: true })
            actionDoneCallbacks.current.onSpecialLinkDone = (data?: ISpecialLink) => {
              if (data) {
                resolve(data)
                closeModals()
              } else {
                reject()
              }
              closeModals()
            }
          } catch (error) {
            reject()
          }
        },
      },
      mergeTags:
        !isLandingPage || (isLandingPage && newLPComposerCreateBlank)
          ? {
              label: 'Personalization',
              handler: async (resolve, reject) => {
                try {
                  updateModalState({ personalization: true })
                  actionDoneCallbacks.current.onMergeTagDone = (data?: IMergeTag) => beeActionHelper(resolve, reject, closeModals, data)
                } catch (error) {
                  closeModals()
                  reject()
                }
              },
            }
          : undefined,
      addOn: {
        label: 'customAddOn',
        handler: async (resolve, reject, args) => {
          try {
            actionDoneCallbacks.current.onPreventMultipleLayoutsConfirm = () => {
              reject()
              updateModal('confirmation', undefined)
            }

            actionDoneCallbacks.current.onAddOnDone = (data?: IAddOnResponse) => beeActionHelper(resolve, reject, closeModals, data)
            renderAddOnModal({
              messageType,
              args: args as EmailModalArgs,
              templateJsonRef,
              actionDoneCallbacks,
              updateModal,
              updateModalState,
              setCustomRowMetaData,
              updateEditModalState,
            })
          } catch (error) {
            closeModals()
            reject()
          }
        },
      },
      filePicker: {
        handler: async (resolve, reject, data) => {
          try {
            const selectedImageUrl = getSelectedImageUrl(data.url)
            updateModalState({ imageModal: true })
            updateEditModalState({ selectedImageUrl })
            actionDoneCallbacks.current.onFilePickerDone = (data?: IPluginFilePicker) => beeActionHelper(resolve, reject, closeModals, data)
          } catch (error) {
            closeModals()
            reject()
          }
        },
      },
    },
  }

  useEffect(() => {
    if (isCoEditing) {
      sessionStorage.removeItem(IS_FIRST_MSG_OPENER)
    }
    const handleBeforeUnload = () => {
      if (!isCoEditing) {
        setSessionStorageItem(IS_FIRST_MSG_OPENER, 'true')
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isCoEditing])

  useEffect(() => {
    rowsRef.current = rowData
  }, [rowData])

  useEffect(() => {
    templateJsonRef.current = templateJson
  }, [templateJson])

  useEffect(() => {
    emailManagementClientRef.current = emailManagementClient
  }, [emailManagementClient])

  useEffect(() => {
    if (shouldStartNewSession) {
      startBeeSessionFn()
      update({ shouldStartNewSession: false, landingPage: { lastPublishedMessageData: undefined } })
    }
  }, [shouldStartNewSession, update, startBeeSessionFn])

  useEffect(() => {
    if (triggerBeeSessionOnce.current) {
      if (startSession || lastEmailContentEditSession?.sessionId) {
        const isFirstMsgOpener = getSessionStorageItem(IS_FIRST_MSG_OPENER)
        const hasSessionId = startSession || isFirstMsgOpener ? undefined : lastEmailContentEditSession?.sessionId

        beeEditorRef.current = new BeePlugin()
        beeEditorRef.current
          .getToken(beeClientId ?? '', beeClientSecret ?? '')
          .then(({ access_token }: AccessTokenResponse) => {
            if (beeEditorRef.current) {
              beeEditorRef.current.token = { ...beeEditorRef.current.token, access_token }
            }

            if (startSession || isFirstMsgOpener) {
              startedOnceRef.current = true
            }

            removeSessionStorageItem(IS_FIRST_MSG_OPENER)
            return startBeeSession({
              sessionId: hasSessionId,
              beeEditorRef,
              beeFinalConfig,
              templateJsonRef,
            })
          })
          .then((status) => {
            if (status === BeeSessionTypes.JOIN) {
              isJoinedToSessionRef.current = true
            }
          })
          .catch(logError)
        eventHooks.onConfigLoaded?.(beeFinalConfig)
      }
      triggerBeeSessionOnce.current = false
    }
  }, [startSession, lastEmailContentEditSession?.sessionId])

  useEffect(() => {
    if (beeEditorRef.current) {
      const updatedConfig: ILoadConfig = {
        ...beeFinalConfig,
        hooks: {
          getRows: { handler: handleGetSavedRows },
        },
      }
      if (sessionId) {
        beeEditorRef.current?.loadConfig?.(updatedConfig)
      }
    }
  }, [
    emailManagementClientRef.current,
    handleGetSavedRows,
    beeEditorRef,
    landingPageMetaImage,
    landingPageMetaTitle,
    landingPageMetaDescription,
    landingPageVisibility,
    customJavaScript,
    customCss,
    selectedCss,
    selectedScripts,
  ])

  useEffect(() => {
    const hasPollBlock = templateJson.hasPollBlock
    const hasWebinarBlock = templateJson.hasWebinarBlock
    if (hasPollBlock && tab === CommonComposerTab.DESIGN) {
      updateModalState({ pollBlockRemovedModal: true })
    }
    if (hasWebinarBlock && tab === CommonComposerTab.DESIGN) {
      updateModalState({ webinarBlockRemovedModal: true })
    }
  }, [templateJson, tab, updateModalState])

  return (
    <>
      <EmailModals
        modalsState={modalState}
        editModalState={editModalState}
        metaData={customRowMetaData}
        {...actionDoneCallbacks.current}
        rowArgs={rowArgs}
        updateModalState={updateModalState}
      />
      <BeeEditor anyModalOpen={anyModalOpen} className={className} />
    </>
  )
}

export default BeeEditorContainer
