import React, { ComponentProps, FC, useContext } from 'react'

import classNames from 'classnames'

import { LabelType, LabelV2 } from '@components/LabelV2/LabelV2'
import PersonalizationTagInput from '@components/PersonalizationTagInput/PersonalizationTagInput'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { FieldDefinition } from '@graphql/types/query-types'
import { EditCRMStepV2Context } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.context'
import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'
import { FilterTypes } from '@utils/filter'
import useCRM from '@utils/hooks/useCRM'

import './CRMStringField.css'

interface CRMStringFieldProps {
  register?: (instance: HTMLElement) => void
  className?: string
  dataTest?: string
  field: FieldDefinition
  hasHelper?: boolean
  onChange: (value: string) => void
  value?: string
}

const rootClass = 'crm-string-field'

const CRMStringField: FC<CRMStringFieldProps> = (props: CRMStringFieldProps) => {
  const { dataTest = rootClass, className = '', field, hasHelper = false, onChange, value, register } = props

  const { program } = useContext(EditCRMStepV2Context)

  const {
    values: { programUrlId },
  } = useContext(ProgramManagerContext)

  const { connectorType } = useCRM()

  const { t } = useTranslation()

  const personalizationProps: ComponentProps<typeof PersonalizationTagInput>['personalizationModalProps'] = {
    specificIds: program.sourceList.map(({ id }) => id),
    isAutomatedPrograms: true,
    programId: programUrlId,
    disableAddRecipientsButton: true,
    hideSenderFieldsFilter: true,
    hideAccountPersonalizations: true,
    hideContactsFieldsFilter: true,
    defaultActiveFilter: FilterTypes.SOURCE_LIST_FIELDS,
  }

  const { displayName, fieldName, required, lengthLimit = -1 } = field

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <LabelV2 label={displayName} required={required} labelType={LabelType.input} />
      <PersonalizationTagInput
        register={register}
        className={classNames(`${rootClass}__input`, { [`${rootClass}__input-with-helper`]: hasHelper })}
        dataTest={`${dataTest}-field-${fieldName}`}
        onChange={onChange}
        defaultValue={value}
        personalizationModalProps={personalizationProps}
        recommendedLength={lengthLimit >= 0 ? lengthLimit : undefined}
      />
      {hasHelper && <Typography text={t('EditCrmStepV2.FieldsSelector.Input.Helper', { crmName: connectorType })} type={TextType.NORMAL_TEXT_GRAY} />}
    </div>
  )
}

export default CRMStringField
