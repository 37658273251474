import React, { FC } from 'react'

import classNames from 'classnames'

import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import Svg, { SvgType } from '@components/Svg'
import svgNames from '@components/Svg/SvgNames'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './UsersCountInfo.css'

interface Props {
  labelKey: string
  tooltipKey: string
  currentCount: number
  maxCount: number | undefined
  needMoreLink: string
  loading?: boolean
  isError?: boolean
  className?: string
  dataTest?: string
}

const typographyCommonProps = { inline: true, lineHeight: LineHeight.TINY }
const rootClass = 'users__count-info'

const UsersCountInfo: FC<Props> = (props) => {
  const { labelKey, tooltipKey, currentCount, maxCount, needMoreLink, loading = false, isError = false, dataTest = rootClass, className } = props
  const { t } = useTranslation()

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Typography text={`${t(labelKey)}: `} {...typographyCommonProps} />
      {loading ? (
        <Loader loaderType={LoaderTypes.row} />
      ) : (
        <>
          <Typography text={`${currentCount} `} {...typographyCommonProps} weight={TextWeight.BOLD} />
          <Typography text={`/${maxCount}`} {...typographyCommonProps} />
          <Tooltip
            position={'right'}
            trigger={
              <Svg
                name={isError ? svgNames.infoHover : svgNames.info}
                type={SvgType.MEDIUM}
                className={classNames(`${rootClass}__svg`, {
                  [`${rootClass}__svg-error`]: isError,
                })}
                dataTest={`${dataTest}__svg`}
              />
            }
            linkText={t('Need more?')}
            link={needMoreLink}
            className={`${rootClass}__tooltip`}
            dataTest={`${dataTest}-tooltip`}
          >
            {`${t(tooltipKey, { maxCount })} `}
          </Tooltip>
        </>
      )}
    </div>
  )
}

export default UsersCountInfo
