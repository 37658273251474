import { SaveBeeLandingPageMutationVariables } from '@graphql/types/mutation-types'
import { MessageType } from '@graphql/types/query-types'
import { removePointerEventsNone } from '@src/pages/EmailComposer/EmailModals/components/FormBlockModal/FormBlock.utils'
import { addBlockIdsIntoJson } from '@src/pages/EmailComposer/utils/EmailComposer.utils'
import { LandingPageComposerState, LandingPageRedirectUrl, LandingPageVisibilityStatus } from '@utils/composer/context/LandingPageComposer.context'

interface SaveLandingPageHelper {
  containerValues: LandingPageComposerState
  autoSave: boolean
  saveContent: boolean
  published?: boolean
  newLPComposerCreateBlank?: boolean
}

export const saveLandingPageHelper = ({
  containerValues,
  autoSave,
  saveContent,
  published,
  newLPComposerCreateBlank,
}: SaveLandingPageHelper): SaveBeeLandingPageMutationVariables => {
  const {
    landingPage: {
      landingPageMetaTitle,
      landingPageMetaDescription,
      landingPageMetaImage,
      landingPageId,
      landingPageIndividualUrl,
      landingPageVisibility,
      landingPageUrlType,
      lastStatusEdited,
      isLandingPageEnabled,
      customJavaScript,
      customCss,
      selectedScripts,
      selectedCss,
      hasUnpublishedChanges,
      lastPublishedTime,
    },
    message: { id, title, messageType, templateHtml, templateJson },
  } = containerValues

  return {
    ...(newLPComposerCreateBlank && published !== undefined && { published: autoSave ? undefined : published }),
    autoSave,
    pageContent: {
      messageType: messageType as MessageType,
      messageId: id,
      beeEditorPage: saveContent ? removePointerEventsNone(JSON.stringify(addBlockIdsIntoJson(templateJson))) : undefined,
      emailContentHtml: saveContent ? removePointerEventsNone(templateHtml) : undefined,
      settings: {
        name: title,
        ...(newLPComposerCreateBlank && !!lastPublishedTime && { publishedAt: lastPublishedTime, unPublishedChanges: hasUnpublishedChanges }),
        ...(!newLPComposerCreateBlank && { enabled: isLandingPageEnabled, lastUpdated: lastStatusEdited }),
        redirectConfig: {
          redirectType: landingPageUrlType,
          redirect:
            landingPageUrlType === LandingPageRedirectUrl.INDIVIDUAL
              ? landingPageIndividualUrl
              : landingPageUrlType === LandingPageRedirectUrl.LANDING_PAGE
              ? landingPageId
              : '',
        },
        seoInfo: {
          allowIndexing: landingPageVisibility === LandingPageVisibilityStatus.SHOW,
          title: landingPageMetaTitle,
          pageDescription: landingPageMetaDescription,
          image: {
            accountId: landingPageMetaImage?.accountId,
            id: landingPageMetaImage?.id,
            name: landingPageMetaImage?.name,
          },
        },
        customCode: {
          customJavascript: customJavaScript,
          customCss,
          jsFiles: selectedScripts?.map((item) => ({
            ...item,
            index: undefined,
          })),
          cssFiles: selectedCss?.map((item) => ({
            ...item,
            index: undefined,
          })),
        },
      },
    },
  }
}
