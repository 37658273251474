import React, { FC, useContext } from 'react'
import { useFormContext } from 'react-hook-form'

import { InputDefinition } from '@graphql/types/microservice/webhooks-incoming-management-types'
import IncomingWebhookToggleSection from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/components/IncomingWebhookToggleSection/IncomingWebhookToggleSection'
import { AuthenticationType, IncomingWebhooksContext } from '@src/pages/Webhooks/IncomingWebhooks/IncomingWebhooksContext'

const rootTranslation = 'Incoming.Webhooks.CreateModal.Authentication.ToggleSection'

const IncomingWebhookAuthenticationToggleSection: FC = () => {
  const {
    values: { enableAuthentication = false },
    update,
  } = useContext(IncomingWebhooksContext)
  const { setValue } = useFormContext<InputDefinition>()

  const onToggle = (toggle: boolean) => {
    update({ enableAuthentication: toggle })
    !toggle && setValue('authentication.type', AuthenticationType.None, { shouldDirty: true, shouldValidate: true })
  }

  return <IncomingWebhookToggleSection isToggleOn={enableAuthentication} onToggle={onToggle} rootTranslation={rootTranslation} />
}

export default IncomingWebhookAuthenticationToggleSection
