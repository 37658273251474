import React, { FC, useEffect, useMemo, useRef, useState } from 'react'

import classNames from 'classnames'

import {
  PersonalizationContainer,
  PersonalizationContainerProps,
  PersonalizationContainerTagInputProps,
} from '@complex/Personalization/PersonalizationContainer'
import { PersonalizationItem } from '@complex/Personalization/utils/Personalization.context'
import Button, { ButtonType } from '@components/Button'
import EmojiButton from '@components/EmojiButton/EmojiButton'
import Svg, { SvgNames } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import { isJest, useTranslation } from '@const/globals'
import { PersonalizationFieldsErrorsForSources } from '@graphql/types/query-types'
import { FilterTypes } from '@utils/filter'
import { collapseSelectionRange, placeCaretAfterNode } from '@utils/formUtils'
import { useDetectInteraction } from '@utils/hooks/useDetectInteraction'
import { formatNumber } from '@utils/numbers'
import Tagify, { TagifySettings } from '@yaireo/tagify'
import { MixedTags } from '@yaireo/tagify/dist/react.tagify'
import '@yaireo/tagify/dist/tagify.css'

import {
  getPersonalizationCount,
  getSanitizedInputLength,
  getTagTemplate,
  getTagValidationMessage,
  getUniquePersonalizationValue,
  isPersonalizationTag,
  PersonalizationTag,
  TAG_END_INTERPOLATION,
  TAG_START_INTERPOLATION,
  tagifyPersonalizations,
  untagifyPersonalizations,
  updateTagsStyle,
  getTagWrapper,
  getPersonalizationSvg,
} from './PersonalizationTagInput.utils'

import './PersonalizationTagInput.css'

interface PersonalizationTagInputProps {
  register?: (instance: HTMLElement) => void
  className?: string
  dataTest?: string
  loading?: boolean
  disabled?: boolean
  onChange: (text: string) => void
  onFocus?: () => void
  onBlur?: () => void
  onTagsChange?: (tags?: PersonalizationTag[]) => void
  defaultValue?: string
  allPersonalizations?: PersonalizationItem[]
  personalizationErrors?: PersonalizationFieldsErrorsForSources[]
  recommendedLength?: number
  includeEmoji?: boolean
  error?: boolean
  personalizationModalProps?: Omit<PersonalizationContainerProps, 'isOpen' | 'closePersonalization'>
}

enum TAGIFY_NODE {
  TAG = 'TAG',
  REMOVE_BUTTON = 'X',
}

type TagifyEventTarget = EventTarget &
  Node & {
    id: string
    __tagifyTagData: PersonalizationTag
  }

const rootClass = 'personalization-tag-input'

const PersonalizationTagInput: FC<PersonalizationTagInputProps> = (props: PersonalizationTagInputProps) => {
  const {
    register,
    recommendedLength,
    includeEmoji = false,
    loading,
    onChange,
    onFocus,
    onBlur,
    onTagsChange,
    className,
    dataTest = rootClass,
    defaultValue,
    allPersonalizations,
    personalizationErrors,
    error,
    disabled,
    personalizationModalProps,
  } = props
  const tagifiedDefaultValue = useMemo(() => tagifyPersonalizations(defaultValue, allPersonalizations), [defaultValue, allPersonalizations])
  const { t } = useTranslation()

  const [showPersonalizationModal, setShowPersonalizationModal] = useState<boolean>(false)
  const [characterCount, setCharacterCountState] = useState<number>(getSanitizedInputLength(tagifiedDefaultValue ?? ''))
  const [activeTag, setActiveTag] = useState<PersonalizationTag>()
  const [hasTags, setHasTags] = useState(!!getPersonalizationCount(defaultValue ?? ''))

  const tagifyRef = useRef<Tagify<PersonalizationTag>>()
  const [mountKey, setMountKey] = useState('initial-mount')
  const wrapperRef = useRef<HTMLDivElement>(null)
  const charCountRef = useRef<HTMLDivElement>(null)
  const errorsRef = useRef(personalizationErrors)

  if (register && tagifyRef.current?.DOM.input) {
    register(tagifyRef.current.DOM.input)
  }

  const remount = () => setMountKey(`remount-${new Date().valueOf()}`)

  useEffect(() => {
    handleMount()
  }, [mountKey])

  useEffect(() => {
    if (!loading && mountKey === 'initial-mount') {
      remount()
    }
  }, [loading, mountKey])

  useEffect(() => {
    allPersonalizations && remount()
  }, [allPersonalizations])

  useEffect(() => {
    errorsRef.current = personalizationErrors
    if (tagifyRef.current) {
      updateTagsStyle(tagifyRef.current, personalizationErrors)
      onTagsChange?.(tagifyRef.current.value)
    }
  }, [personalizationErrors])

  const handleMount = () => {
    const attachEventListeners = () => {
      // Tagify's e.detail.event.preventDefault() results in an illegal invocation error
      // Adding an actual keydown event listener circumvents the issue
      const input = tagifyRef.current?.DOM.input as HTMLSpanElement
      input.onkeydown = (event) => {
        const { nodeName } = event.target as Node
        if (event.key === 'Enter' && ![`${TAGIFY_NODE.REMOVE_BUTTON}`, `${TAGIFY_NODE.TAG}`].includes(nodeName)) {
          // User should not be able to make their own line breaks
          event.preventDefault()
        }
      }

      input.onpaste = () => {
        // When the user pastes the text they inserted is selected,
        // which is not normal pasting behavior
        collapseSelectionRange()
      }
      updateCharacterCount()

      input.contentEditable = disabled ? 'false' : 'true'
    }

    if (tagifyRef.current?.DOM.input) {
      // Small delay to ensure component has remounted
      setTimeout(() => attachEventListeners(), 250)
    } else {
      setTimeout(handleMount, 250)
    }
  }

  const handleBlur = () => {
    const tagify = tagifyRef.current
    if (!tagify) {
      return
    }
    const manualTagAdded = tagify.getCleanValue().length < getPersonalizationCount(tagify.getMixedTagsAsString())
    if (manualTagAdded) {
      remount()
      handleChange()
    }
    onBlur?.()
  }

  const { setInsideInteraction } = useDetectInteraction({
    containerRef: wrapperRef,
    onOutside: handleBlur,
  })

  const setCharacterCount: typeof setCharacterCountState = (value) => {
    const input = wrapperRef.current?.querySelector(`.tagify`) as HTMLInputElement
    const charCount = charCountRef.current
    if (input && charCount) {
      input.style.paddingRight = `${charCount.clientWidth + 18}px`
    }
    setCharacterCountState(value)
  }

  const settings: TagifySettings<PersonalizationTag> = {
    pattern: /{{/,
    trim: false,
    editTags: false,
    dropdown: {
      enabled: false,
    },
    onChangeAfterBlur: false,
    keepInvalidTags: true,
    mixTagsInterpolator: [TAG_START_INTERPOLATION, TAG_END_INTERPOLATION],
    transformTag: (tagData) => {
      // Possible tagify bug; should ignore any tag that does contain expected fields
      if (isPersonalizationTag(tagData)) {
        tagData.valid = !(tagData && getTagValidationMessage(tagData, errorsRef.current))
      }
    },
    originalInputValueFormat: (tagData) => {
      // This converts the tagify tag syntax into a custom syntax on change
      // Type note: tagData is not an array
      const tag = tagData as unknown as PersonalizationTag
      return `${tag.syntax}`
    },
    tagTextProp: 'displayText',
    templates: {
      tag: function (tagData: PersonalizationTag) {
        return getTagTemplate(this, tagData, personalizationErrors)
      },
      wrapper: getTagWrapper,
    },
  }

  const isFallbackModalActive = useMemo(() => {
    return !!activeTag ? (activeTag.group ? ![FilterTypes.CUSTOM_ACCOUNT_FIELDS].includes(activeTag.group) : true) : false
  }, [activeTag])

  const personalizationContainerTagProps: PersonalizationContainerTagInputProps = {
    onInsertTag: async (tagData) => {
      if (!activeTag) {
        const prevTagElements = tagifyRef.current?.getTagElms()
        tagifyRef.current?.addTags([{ ...tagData, value: getUniquePersonalizationValue() }])
        const addedTagElements = tagifyRef.current?.getTagElms().filter((element) => !prevTagElements?.includes(element))
        tagifyRef.current?.DOM.input.focus()
        addedTagElements?.[0] && placeCaretAfterNode(addedTagElements[0])
      } else {
        const tagElement = document.getElementById(activeTag.value)

        if (tagElement) {
          tagifyRef.current?.replaceTag(tagElement, { ...tagData, value: activeTag.value })
        }
      }
      setActiveTag(undefined)
      setShowPersonalizationModal(false)
      setInsideInteraction(true)
    },
    preSelectedItem: activeTag
      ? {
          id: activeTag.fieldId,
          title: activeTag.displayText,
          mapping: activeTag.mappingName,
          group: activeTag.group ?? FilterTypes.CONTACT_FIELDS,
          fallbackText: activeTag.fallbackText,
        }
      : undefined,
    isFallbackModalActive,
    activeSvg: activeTag ? getPersonalizationSvg(activeTag) : undefined,
  }

  const onKeyDown = (e: CustomEvent<Tagify.KeydownEventData<PersonalizationTag>>) => {
    const { id, nodeName, __tagifyTagData: tagData } = e.detail.event.target as TagifyEventTarget
    const {
      tagify,
      event: { key, target },
    } = e.detail

    if (key === 'Enter') {
      if (nodeName === TAGIFY_NODE.REMOVE_BUTTON) {
        tagify.removeTags(id)
      } else if (nodeName === TAGIFY_NODE.TAG) {
        const tagElement = target as HTMLElement
        tagElement.blur()

        setActiveTag(tagData)
        setShowPersonalizationModal(true)
      }
    }
  }

  const getCleanText = () => {
    const tagify = tagifyRef.current
    if (!tagify) {
      return defaultValue ?? ''
    }
    const mixedTags = tagify.getMixedTagsAsString()
    const tags = tagify.getCleanValue()
    return untagifyPersonalizations(tags, mixedTags)
  }

  const injectTextAtCaret = (text: string) => {
    // Typedefs are incomplete, these functions will work with a text node
    const textNode = document.createTextNode(text) as unknown as HTMLElement
    tagifyRef.current?.injectAtCaret(textNode)
    placeCaretAfterNode(textNode)
    tagifyRef.current?.DOM.input.focus()
    updateCharacterCount()
    handleChange()
  }

  const updateCharacterCount = () => {
    setCharacterCount(getSanitizedInputLength(getCleanText()))
  }

  const clearLeadingSpaces = () => {
    const tagifyInput = tagifyRef.current?.DOM.input
    const inputValue = tagifyInput?.textContent || ''

    if (/^\s+$/.test(inputValue)) {
      tagifyRef.current?.removeAllTags()
      onChange('')
    }
    const trimmedValue = inputValue.replace(/^\s+/, '')
    if (inputValue !== trimmedValue) {
      tagifyRef.current?.removeAllTags()
      tagifyRef.current?.DOM.input.append(trimmedValue)
      onChange(trimmedValue)
    }

    updateCharacterCount()
  }

  const handleChange = () => {
    const tagify = tagifyRef.current
    if (!tagify) {
      return
    }
    const cleanText = getCleanText()
    onChange(cleanText)
    updateCharacterCount()

    const tags = tagify.getCleanValue()
    setHasTags(!!tags.length)
  }

  return (
    <>
      {showPersonalizationModal && (
        <PersonalizationContainer
          isOpen={showPersonalizationModal}
          closePersonalization={() => {
            setShowPersonalizationModal(false)
            setActiveTag(undefined)
          }}
          tagInputProps={personalizationContainerTagProps}
          {...personalizationModalProps}
        />
      )}
      <div className={`${rootClass}__wrapper`} data-test={dataTest} ref={wrapperRef}>
        <div
          className={classNames(`${rootClass}__input-wrapper`, {
            [`${rootClass}__input-wrapper--has-tags`]: hasTags,
            [`${rootClass}__input-wrapper--error`]: error,
            [`${rootClass}__input-wrapper--disabled`]: disabled,
            [`${className}`]: className,
          })}
        >
          <MixedTags
            key={mountKey}
            settings={settings}
            tagifyRef={tagifyRef}
            className={`${rootClass}__tags-input`}
            onClick={(e) => {
              setActiveTag(e.detail.data)
              setShowPersonalizationModal(true)
            }}
            // onChange is difficult to trigger in Jest, but onInput works consistently
            onInput={isJest() ? handleChange : updateCharacterCount}
            onChange={() => handleChange()}
            onRemove={() => onTagsChange?.(tagifyRef.current?.value)}
            onAdd={() => onTagsChange?.(tagifyRef.current?.value)}
            onFocus={onFocus}
            onKeydown={onKeyDown}
            defaultValue={tagifiedDefaultValue}
            onBlur={clearLeadingSpaces}
          />
          {recommendedLength !== undefined && (
            <Typography
              type={TextType.BODY_TEXT_LIGHT}
              text={`${formatNumber(characterCount)}/${formatNumber(recommendedLength)}`}
              lineHeight={LineHeight.MEDIUM}
              className={classNames(`${rootClass}__character-count`, { [`${rootClass}__character-count--disabled`]: disabled })}
              reference={charCountRef}
              dataTest={`${dataTest}-character-count`}
            />
          )}
        </div>
        <div className={`${rootClass}__tag-buttons`}>
          {includeEmoji && (
            <EmojiButton disabled={disabled} onSelect={(emoji) => injectTextAtCaret(emoji)} dataTest={`${dataTest}-emoji-button`} align={'end'} />
          )}
          <Button
            buttonType={ButtonType.TEXT_INSERT}
            onClick={() => setShowPersonalizationModal(true)}
            dataTest={`${dataTest}-personalize-button`}
            disabled={disabled}
          >
            <Svg name={SvgNames.userUnselected} fill={!disabled ? SvgColor.TEXT_GRAY : SvgColor.TAB_GRAY} />
            {t('Personalize')}
          </Button>
        </div>
      </div>
    </>
  )
}

export default PersonalizationTagInput
