import { SwitchOptions } from '@components/Switch/Switch'
import { Program, ProgramMessageCountsWithTotals, ProgramSendStep } from '@graphql/types/query-types'
import { getStep } from '@src/pages/programs/dashboard/ProgramSteps.utils'
import { ProgramManagerLocationStateProps } from '@src/pages/programs/manager/ProgramManager.constants'
import { getFormattedNumber } from '@utils/numbers'
import { SortByState } from '@utils/sms.utils'

export const calculatePercent = (uniqueAccessor: number, baseAccessor: number, fixed: number) => {
  const rawPercent = baseAccessor === 0 ? 0 : uniqueAccessor / baseAccessor
  return `${rawPercent === 0 && uniqueAccessor > 0 ? 0 : (rawPercent * 100).toFixed(fixed)}%`
}

export const processProgramMessageCountsWithTotals = (
  programMessageCountsWithTotals: ProgramMessageCountsWithTotals,
  tableType: SwitchOptions,
  program: Program
) => {
  const formatData = (percent: string, numeric: number) => {
    const formattedNumeric = getFormattedNumber(numeric)
    if (tableType === SwitchOptions.PERCENT) {
      return [percent, formattedNumeric]
    } else {
      return [formattedNumeric, percent]
    }
  }

  return programMessageCountsWithTotals.programMessageCountsList.map((data) => {
    if (data) {
      const step = data.stepId ? getStep(program, data.stepId) : { displayName: '' }
      return {
        messageId: data.messageId,
        STEP_ID: [data.uiStepCode, step?.displayName],
        SUBJECT: data.messageTitle,
        SENT: data.uniqueSent,
        delivered: formatData(`${data.percentageDelivered}%`, data.uniqueDelivered),
        opened: formatData(`${data.percentageOpened}%`, data.uniqueOpen),
        clicked: formatData(`${data.percentageClicked}%`, data.uniqueClick),
        clickToOpen: formatData(`${data.percentageClickToOpen}%`, data.uniqueClickToOpen),
        optOut: formatData(`${data.percentageOptOut}%`, data.uniqueOptOut),
        isWinner: ((step as ProgramSendStep)?.abWinner ?? '-1') === data.messageId,
        abThreshold: (step as ProgramSendStep)?.abThreshold ?? 0,
      }
    }
    return []
  })
}

export const getDefaultSort = (locationState: ProgramManagerLocationStateProps): SortByState[] => {
  const column = locationState && locationState['emailTabSortColumn'] ? locationState['emailTabSortColumn'] : undefined
  const lsSortDesc = (locationState && locationState['emailTabSortDesc']) ?? (column && column !== 'STEP_ID')

  return [{ id: column ?? 'STEP_ID', desc: !!lsSortDesc }]
}

export const getSortColumn = (sortId: string) => {
  return sortId === 'clicked'
    ? 'UNIQUE_CLICK'
    : sortId === 'opened'
    ? 'UNIQUE_OPEN'
    : sortId === 'delivered'
    ? 'UNIQUE_DELIVERED'
    : sortId === 'clickToOpen'
    ? 'UNIQUE_CLICK_TO_OPEN'
    : sortId === 'optOut'
    ? 'UNIQUE_OPT_OUT'
    : sortId.toUpperCase()
}

const addMessage = () => alert('Add Message')

export const EmailSMSPerformanceFunctions = {
  addMessage,
}

const LEARN_MORE_LINK = 'https://connect.act-on.com/hc/en-us/articles/4408888964119-How-to-Manage-Automated-Program-Messages'

export const helpMessage = {
  text: 'Learn more about Automated Program messages',
  url: LEARN_MORE_LINK,
}
