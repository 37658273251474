import { useApolloClient } from '@apollo/client'
import scoreSheets from '@graphql/microservices/list/scoreSheets'
import Campaigns from '@graphql/queries/campaigns'
import { ScoreSheetsQuery, ScoreSheetsQueryVariables } from '@graphql/types/microservice/list-types'
import { CampaignsQuery, CampaignsQueryVariables } from '@graphql/types/query-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

interface getEditAlertStepResponse {
  getScoreSheetsRequest: () => FetchPromise<ScoreSheetsQuery>
  getCampaigns: () => FetchPromise<CampaignsQuery>
}

export const useGetEditAlertStepQueries = (): getEditAlertStepResponse => {
  const client = useApolloClient()
  const { client: listService } = useMicroserviceClient({ serviceName: MicroserviceClients.LIST })

  const getScoreSheetsRequest = () => {
    return listService.query<ScoreSheetsQuery, ScoreSheetsQueryVariables>({
      fetchPolicy: 'network-only',
      query: scoreSheets,
    })
  }

  const getCampaigns = () => {
    return client.query<CampaignsQuery, CampaignsQueryVariables>({
      fetchPolicy: 'network-only',
      query: Campaigns,
    })
  }

  return {
    getScoreSheetsRequest,
    getCampaigns,
  }
}
