import { gql } from '@apollo/client'

export default gql`
  query getAllowedSendWindow($accountId: Long!) {
    getAllowedSendWindow(accountId: $accountId) {
      allowOverride
      sendTimeEnd
      sendTimeStart
    }
  }
`
