import React from 'react'

import { AccordionDetailsModalHeaderActions } from '@components/AccordionDetailsModal/AccordionDetailsModal'
import Button, { ButtonType } from '@components/Button'
import Svg, { SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'

const rootClass = 'accordion-details-modal'

export const renderActionButtons = (actions: AccordionDetailsModalHeaderActions[], t: Function) =>
  actions.map(({ icon, tooltip, onClick }) => (
    <Tooltip
      key={`action-${icon}`}
      position={'top'}
      trigger={
        <Button buttonType={ButtonType.ICON} onClick={onClick} className={`${rootClass}__action`}>
          <Svg name={icon} type={SvgType.LARGER_ICON} dataTest={`${rootClass}-top-action`} />
        </Button>
      }
    >
      {t(tooltip)}
    </Tooltip>
  ))
