import getAllItems from '@graphql/microservices/categorization/getAllItems'
import { GetAllItemsQuery, GetAllItemsQueryVariables } from '@graphql/types/microservice/categorization-types'
import { ItemType } from '@utils/categorization'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export interface GetAllItemsRequestTypeParams {
  pageNumber: number
  pageSize: number
  type: ItemType
}

export type GetAllItemsRequestType = (params: GetAllItemsRequestTypeParams) => FetchPromise<GetAllItemsQuery>

export interface ContactSegmentsCategorizationServiceRequests {
  getAllItemsRequest: GetAllItemsRequestType
}

export const useContactSegmentsCategorizationServiceRequest = (): ContactSegmentsCategorizationServiceRequests => {
  const {
    client: { query },
  } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })

  const getAllItemsRequest = (params: GetAllItemsRequestTypeParams) =>
    query<GetAllItemsQuery, GetAllItemsQueryVariables>({
      query: getAllItems,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        ...params,
      },
    })

  return {
    getAllItemsRequest,
  }
}
