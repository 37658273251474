import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Container from '@components/Container'
import Typography, { TextType } from '@components/Typography/Typography'

import './TextBubble.css'

interface Props {
  className?: string
  dataTest?: string
  text: string | ReactNode
  smallerMargin?: boolean
  noMargin?: boolean
  radiusRight?: boolean
  backgroundColor?: string
  roundedRadius?: boolean
}

export enum BackgroundColor {
  TEAL = 'TEAL',
}

const rootClass = 'text-bubble'

const TextBubble: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '', text, smallerMargin, noMargin, radiusRight, roundedRadius, backgroundColor } = props
  return (
    <Container
      className={classNames(rootClass, className, {
        [`${rootClass}__smaller-margin`]: smallerMargin,
        [`${rootClass}__no-margin`]: noMargin,
        [`${rootClass}__radius-right`]: radiusRight,
        [`${rootClass}__radius-left`]: !radiusRight,
        [`${rootClass}__rounded-radius`]: roundedRadius,
        [`${rootClass}__background-teal`]: backgroundColor === BackgroundColor.TEAL,
      })}
      dataTest={dataTest}
    >
      <Typography text={text} type={TextType.BODY_TEXT} />
    </Container>
  )
}

export default TextBubble
