import React from 'react'

import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { UserResponse } from '@graphql/types/query-types'
import { CopyFromUser } from '@src/pages/Settings/Users/components/CopyPermissionsModal/CopyPermissionsModal'
import { UserWithId } from '@src/pages/Settings/Users/hooks/useResendVerification'

export const getSelectedUsersIds = (selectedUsers: UserResponse[]) => {
  return selectedUsers.map((user) => user.id || '')
}

const getDisplayValue = (user: UserResponse) => {
  if (!user.fullName) {
    return user.isAdmin ? `${user.email} (Admin)` : user.email
  }

  return user.isAdmin ? `${user.fullName} (Admin)` : user.fullName
}

export const getCopyFromUsers: (users: UserWithId[]) => CopyFromUser[] = (users) =>
  users.map((user) => ({
    id: user.id,
    value: user.id,
    label: getDisplayValue(user),
    permissions: user.permissions,
    launchApproval: user.launchApproval,
    launchApprovalId: user.launchApprovalId,
    isAdmin: user.isAdmin,
  }))

export const getSelectedUsers = (selectedUsers: UserResponse[], user?: UserResponse) => {
  if (user) {
    return [user]
  }

  return selectedUsers.filter((user) => {
    return !user.isMe
  })
}

export const getConfirmationBody = (t: Function, selectedUsers: UserResponse[], data: UserResponse[], copyFromUser?: CopyFromUser) => (
  <>
    <Typography
      text={t('Confirm.Copy.Permission.Modal', {
        count: selectedUsers.length,
        userName: selectedUsers.length === 1 && selectedUsers[0].fullName,
        copyFromUserName: data.find((user) => user.id === copyFromUser?.id)?.fullName,
      })}
      type={TextType.BODY_TEXT_LIGHT}
    />
    <Typography text={t('Confirm.Copy.Permission.Modal.Bold')} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.BOLD} />
  </>
)
