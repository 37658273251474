import React, { FC, ReactNode, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { useTranslation } from '@const/globals'

import './SmallCollapsibleBanner.css'

interface SmallCollapsibleBannerProps {
  className?: string
  dataTest?: string
  body: ReactNode
  onCollapse: VoidFunction
  onPrimaryButtonClick: VoidFunction
  icon: SvgNames
  isDefaultExpanded?: boolean
  collapseButtonText?: string
  primaryButtonText?: string
}

const rootClass = 'small-collapsible-banner'

export const SmallCollapsibleBanner: FC<SmallCollapsibleBannerProps> = (props: SmallCollapsibleBannerProps) => {
  const {
    dataTest = rootClass,
    className = '',
    body,
    icon,
    onPrimaryButtonClick,
    onCollapse,
    isDefaultExpanded = true,
    collapseButtonText,
    primaryButtonText,
  } = props
  const [collapsed, setCollapsed] = useState<boolean>(!isDefaultExpanded)
  const { t } = useTranslation()

  const collapseBanner = () => {
    onCollapse()
    setCollapsed(() => false)
  }

  return (
    <>
      {!collapsed ? (
        <div className={classNames(rootClass, className)} data-test={dataTest}>
          <div className={`${rootClass}__container`}>
            <Svg className={`${rootClass}__icon`} name={icon} type={SvgType.LARGE_ICON} />
            {body}
          </div>
          <div className={`${rootClass}__actions`}>
            <Button className={`${rootClass}__collapse-button`} buttonType={ButtonType.FLOAT_TEAL} onClick={collapseBanner}>
              {collapseButtonText || t('Dismiss')}
            </Button>
            <Button buttonType={ButtonType.PRIMARY} onClick={onPrimaryButtonClick}>
              {primaryButtonText || t('Upgrade')}
            </Button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
