import React, { FC } from 'react'

import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { Box } from '@src/pages/programs/UpgradeCommonComponents/Box/Box'
import {
  FieldSetOperation,
  FieldSetValues,
} from '@src/pages/programs/upgradeManager/components/steps/ChangeFieldValueUpgradeStep/components/FieldReplacement/FieldReplacement.utils'
import { UpgradedStepOperation } from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'

import './ReviewRemovedStepField.css'

interface ReviewRemovedStepFieldProps {
  operation: UpgradedStepOperation
}

const rootClass = 'review-removed-step-field'

export const ReviewRemovedStepField: FC<ReviewRemovedStepFieldProps> = ({ operation: { fieldName, value, rule } }: ReviewRemovedStepFieldProps) => {
  const { t } = useTranslation()

  return (
    <div className={rootClass}>
      <div className={`${rootClass}__value-container`}>
        <Typography text={t('Field name')} weight={TextWeight.MEDIUM} lineHeight={LineHeight.MEDIUM} />
        <Box isCurrent isSourceValue>
          <Typography text={fieldName} />
        </Box>
      </div>
      {!!rule && (
        <div className={`${rootClass}__condition-container`}>
          <Typography className={`${rootClass}__condition`} text={t(FieldSetValues[rule as FieldSetOperation])} lineHeight={LineHeight.MEDIUM} />
          <Typography
            className={`${rootClass}__condition-source`}
            text={value}
            type={TextType.BODY_TEXT_SMALL}
            lineHeight={LineHeight.MEDIUM_SMALL}
          />
        </div>
      )}
    </div>
  )
}
