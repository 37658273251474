import { gql } from '@apollo/client'
import { allListsChildren, folderLists } from '@graphql/queries/fragments/lists'

export default gql`
  ${allListsChildren}
  ${folderLists}

  query SearchListsByIds($ids: String!) {
    searchListsByIds(ids: $ids) {
      count
      contacts {
        ...AllListsChildren
      }
      formSubmissions {
        ...FolderListsFragment
      }
      marketing {
        ...FolderListsFragment
      }
      extension {
        ...FolderListsFragment
      }
      webinar {
        ...FolderListsFragment
      }
      unifiedLists {
        ...FolderListsFragment
      }
    }
  }
`
