import React, { FC, useCallback, useState } from 'react'

import BackButton from '@components/BackButton/BackButton'
import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import DropDown from '@components/DropDown'
import { DropDownType } from '@components/DropDown/DropDown'
import DropDownActions, { MenuItem } from '@components/DropDownActions/DropDownActions'
import { ModalHeader } from '@components/Modal'
import Svg, { SvgNames } from '@components/Svg'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import { ModalHeaderFormStyle } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

interface Props {
  title: string
  actions?: MenuItem[]
  dataTest?: string
  onBackButtonClick?: VoidFunction
  useTitlePrefix?: boolean
  titlePrefix?: string
}

const rootClass = 'preview-asset-modal__header'

const PreviewAssetModalHeader: FC<Props> = (props: Props) => {
  const { title, actions, dataTest = rootClass, useTitlePrefix = true, titlePrefix, onBackButtonClick } = props
  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(false)
  const handleCloseDropdown = useCallback(() => setShow(false), [])

  return (
    <ModalHeader className={rootClass} dataTest={dataTest}>
      {onBackButtonClick && <BackButton onClick={onBackButtonClick} />}
      <TextWithTooltipOnEllip
        className={`${rootClass}__asset-name`}
        typographyProps={{
          text: useTitlePrefix ? (titlePrefix ? `${titlePrefix} ${title}` : t('Preview.Asset.Modal.Title', { title })) : title,
          ...ModalHeaderFormStyle,
        }}
      />
      {!!actions?.length && (
        <DropDown
          className={`${rootClass}__actions-dropdown`}
          isOpen={show}
          toggleOpen={setShow}
          type={DropDownType.STYLED}
          align="end"
          trigger={
            <Button
              iconPosition={ButtonIconPosition.RIGHT}
              buttonType={ButtonType.FLOAT}
              dataTest={`${dataTest}-actions-button`}
              className={`${rootClass}__actions-button`}
            >
              {t('Actions')}
              <Svg name={SvgNames.caretFillDown} />
            </Button>
          }
        >
          <DropDownActions menuItems={actions} closeDropDown={handleCloseDropdown} />
        </DropDown>
      )}
    </ModalHeader>
  )
}

export default PreviewAssetModalHeader
