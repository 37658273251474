import React, { ChangeEvent, FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonIconPosition, ButtonType } from '@components/Button/Button'
import PrintButton from '@components/PrintButton'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import TimespanPicker, { DirectionOptions } from '@components/TimespanPicker/TimespanPicker'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './ReportHeader.css'

export type AltViewOption = {
  label: string
  value: string
  selected: boolean
}

type Props = {
  className?: string
  dataTest?: string
  reportType?: string
  startTime: string
  endTime?: string
  updatedDate?: string
  nextDisabled?: boolean
  prevDisabled?: boolean
  refreshOnClick?: () => void
  handleChange: (direction: DirectionOptions) => void
  altView?: boolean
  altViewOptions?: AltViewOption[]
  altViewDropdownChange?: (event: ChangeEvent<HTMLSelectElement>) => void
  reportButtonText?: string
}

const rootClass = 'report-header'

const ReportHeader: FC<Props> = (props: Props) => {
  const {
    dataTest = rootClass,
    className = '',
    reportType,
    startTime,
    endTime,
    updatedDate = 'Tue Sep 14 2021 12:00 AM PDT',
    nextDisabled = false,
    prevDisabled = false,
    altView = false,
    altViewOptions,
    altViewDropdownChange,
    handleChange,
    refreshOnClick,
    reportButtonText = 'Print',
  } = props

  const { t } = useTranslation()

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={classNames(`${rootClass}__row`)}>
        <Typography type={TextType.PAGE_HEADER} text={reportType} />
        <div className={classNames(`${rootClass}__buttons__row`)}>
          <PrintButton rootClass={rootClass} text={reportButtonText} />
          {refreshOnClick && (
            <Button noTopBottomPadding={true} buttonType={ButtonType.TERTIARY} iconPosition={ButtonIconPosition.LEFT} onClick={refreshOnClick}>
              <Svg name={SvgNames.refresh} type={SvgType.LARGER_ICON} />
              <Typography type={TextType.BODY_TEXT_SMALL} text={t('Refresh')} />
            </Button>
          )}
        </div>
      </div>
      <div className={classNames(`${rootClass}__row`)}>
        <TimespanPicker
          startTime={startTime}
          endTime={endTime}
          nextDisabled={nextDisabled}
          prevDisabled={prevDisabled}
          handleChange={handleChange}
          altView={altView}
          altViewOptions={altViewOptions}
          altViewDropdownChange={altViewDropdownChange}
        />
        <Typography
          className={classNames({
            [`${rootClass}__align`]: altView,
          })}
          type={TextType.BODY_TEXT_SMALL}
          text={`${t('Updated')} ${updatedDate}`}
        />
      </div>
    </div>
  )
}

export default ReportHeader
