import React, { FC, useMemo, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Checkbox from '@components/Checkbox'
import ConfirmationModal from '@components/ConfirmationModal'
import { YesNo } from '@components/ConfirmationModal/ConfirmationModal'
import Container from '@components/Container'
import Svg, { SvgColor, SvgType } from '@components/Svg/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { MIN_PAGINATION_SIZE } from '@components/TableV2/components/Pagination/TableV2Pagination'
import { TableV2 } from '@components/TableV2/TableV2'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { OptInsSourceList } from '@src/pages/sms/marketingListOptIns/components/OptInsSourceLists/OptInsSourceLists.constants'
import { SortByState } from '@utils/sms.utils'

import { renderListType } from './OptInsSourceLists.utils'
import { renderDate, renderWithIcon } from '../../MarketingListOptIns.utils'

import './OptInsSourceLists.css'

interface OptInsSourceLists {
  lists: OptInsSourceList[]
  pageSize: number
  pageIndex: number
  totalCount: number
  sortBy: SortByState[]
  loading: boolean
  fetchData: (pageIndex: number, pageSize: number) => void
  onOpenAddList: () => void
  onChangeSetSendWelcomeMessage: (sentWelcomeMessage: boolean, listIds: [string]) => void
  onChangeSort: (sortBy: SortByState[]) => void
  dataTest?: string
}

interface State {
  showConfirmation: boolean
  enableWelcomeMessage: boolean
  listId: string
}

const defaultState: State = {
  showConfirmation: false,
  enableWelcomeMessage: false,
  listId: '',
}

const rootClass = 'optIns-sourceLists'
const MIN_ROWS_FOR_OVERFLOW = 5

const OptInsSourceLists: FC<OptInsSourceLists> = (props: OptInsSourceLists) => {
  const {
    lists,
    loading,
    pageSize,
    pageIndex,
    totalCount,
    sortBy,
    fetchData,
    onOpenAddList,
    onChangeSetSendWelcomeMessage,
    onChangeSort,
    dataTest = rootClass,
  } = props
  const [state, setState] = useState<State>(defaultState)
  const { t } = useTranslation()

  const renderCheckbox = (row: any) => (
    <Checkbox
      checked={row.cell.row.original.sendWelcomeMessage}
      label={t('Send')}
      className={`${rootClass}__welcome-checkbox`}
      onChange={() => {
        setState({
          showConfirmation: true,
          enableWelcomeMessage: !row.cell.row.original.sendWelcomeMessage,
          listId: row.cell.row.original.listId,
        })
      }}
    />
  )

  const tableV2columns = useMemo(
    (): ColumnDefWithAdditionalProps<OptInsSourceList, unknown>[] => [
      {
        header: 'List/Segment',
        accessorKey: 'listDisplayName',
        textAlign: 'left',
        sortingFn: 'alphanumeric',
        enableCustomCellValue: true,
        cell: (row) => renderWithIcon(`${rootClass}__list-segment-name`, row, renderListType(row, lists), TextWeight.MEDIUM),
      },
      {
        header: 'Opt-Ins',
        accessorKey: 'optInCount',
        textAlign: 'right',
        sortingFn: 'alphanumeric',
        maxSize: 110,
      },
      {
        header: 'Latest Opt-In Added',
        accessorKey: 'latestOptInDate',
        textAlign: 'right',
        sortingFn: 'alphanumeric',
        maxSize: 220,
        cell: (row) => renderDate(row),
      },
      {
        header: 'Welcome Message',
        accessorKey: 'welcome',
        textAlign: 'right',
        disableSorting: true,
        maxSize: 144,
        cell: (row) => renderCheckbox(row),
      },
    ],
    [lists]
  )

  const hasOverflow = lists.length > MIN_ROWS_FOR_OVERFLOW

  const updateSendStatus = () => {
    onChangeSetSendWelcomeMessage(state.enableWelcomeMessage, [state.listId])
  }

  const closeConfirmation = () => setState(defaultState)

  const renderConfirmation = () => {
    const titleWord = state.enableWelcomeMessage ? 'on' : 'off'
    const title = t(`Turn ${titleWord} Welcome Message?`)
    const messageWord = state.enableWelcomeMessage ? '' : 'not '
    const body = t(`Contacts who are added to the list in the future will ${messageWord} receive a welcome message.`)

    return (
      <ConfirmationModal
        isOpen={true}
        title={title}
        className={`${rootClass}__confirmation`}
        body={body}
        isYesNo
        yesButtonText={`Turn ${titleWord}`}
        noButtonText={t('Cancel')}
        onAnswer={(answer) => {
          if (answer === YesNo.YES) {
            updateSendStatus()
          }
          closeConfirmation()
        }}
      />
    )
  }

  return (
    <Container
      className={classNames(rootClass, {
        [`${rootClass}__has-overflow`]: hasOverflow,
      })}
      noSidePadding
      noBottomPadding
      dataTest={dataTest}
    >
      <div className={`${rootClass}__headline`}>
        <Typography text={'Source Lists'} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
        {lists.length > 0 && (
          <Button data-test={`${dataTest}-add-source`} buttonType={ButtonType.SECONDARY} title={t('Add List')} onClick={onOpenAddList}>
            <Svg name={SvgNames.plus} type={SvgType.ICON} fill={SvgColor.TEXT_GRAY} />
            {t('Add List')}
          </Button>
        )}
      </div>
      {state.showConfirmation && renderConfirmation()}
      <TableV2
        data={lists}
        loading={loading}
        sortingBy={sortBy}
        columns={tableV2columns}
        enablePaginate={totalCount > MIN_PAGINATION_SIZE}
        manualSorting={true}
        enableInsideLoader={true}
        withoutBorders={true}
        enableBodyHeightComputation={true}
        fetchData={fetchData}
        onSort={onChangeSort}
        emptyState={{
          text: t('No Source Lists have been added yet.'),
          buttonText: t('Add list'),
          buttonOnClick: onOpenAddList,
          maxHeight: pageSize > MIN_PAGINATION_SIZE ? '22rem' : '',
          withoutBorder: true,
        }}
        paginationState={{
          pageSize,
          pageIndex,
          controlledPageCount: Math.ceil(totalCount / pageSize),
        }}
      />
    </Container>
  )
}

export default OptInsSourceLists
