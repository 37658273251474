import React, { FC, useEffect, useRef } from 'react'

import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'
import { DynamicContentListItem } from '@utils/composer/context/EmailComposer.context'
import { useEmailComposerRequests } from '@utils/composer/emailComposer/GraphQL/EmailComposerRequests.graphQL'
import { logNewRelicError } from '@utils/new-relic.utils'

import EmailComposerPreview from './EmailComposerPreview'
import { processIframePreviewHtml } from './EmailComposerPreview.utils'
import { useLoadPlainText } from './hooks/useLoadPlainText'

const EmailComposerPreviewContainer: FC = () => {
  const {
    values: {
      isSaving,
      message: { id: messageId, templateHtml, templateJson, subject, previewText, customTextPart, isPlainTextOnly },
      preview: { plainText, isDarkMode, isPlainText, selectedContact, isPreviewDynamicContentOn },
    },
    api: { updatePreview },
  } = useComposerContext()

  const previewFrameRef = useRef<HTMLIFrameElement>(null)

  const { getPersonalizeEmailMessageRequest } = useEmailComposerRequests()

  const personalizeEmailMessage = async () => {
    updatePreview({ loadingPersonalization: true })
    const { data, errors } = await getPersonalizeEmailMessageRequest({
      recId: selectedContact?.id || '',
      msgId: messageId,
      subject: subject,
      previewText: previewText,
      htmlOrPlainText: isPlainText ? (customTextPart ? customTextPart : plainText) : templateHtml,
    })
    if (errors) {
      logNewRelicError(errors, 'Fetching personalize email message for composer preview')
      updatePreview({ loadingPersonalization: false })
    } else if (data?.personalizeEmailMessage) {
      const { htmlOrPlainText, subject, previewText, senderEmail, senderName } = data?.personalizeEmailMessage
      updatePreview({
        ...(isPlainText ? { formattedPlainText: htmlOrPlainText } : { html: processIframePreviewHtml(htmlOrPlainText || templateHtml) }),
        formattedSubject: subject,
        formattedPreviewText: previewText,
        loadingPersonalization: false,
        personalizedSender: { senderEmail, senderName },
      })
    }
  }

  const loadPlainText = useLoadPlainText()

  useEffect(() => {
    if (selectedContact) {
      personalizeEmailMessage()
    } else {
      updatePreview({ html: processIframePreviewHtml(templateHtml), formattedPlainText: '' })
    }
  }, [selectedContact, templateHtml, isPlainText])

  useEffect(() => {
    if (templateJson && !isPreviewDynamicContentOn) {
      updatePreview({
        dynamicContentList: templateJson.page.rows
          .map((row, index) =>
            row.container.displayCondition ? { ...row.container.displayCondition, isActive: true, uuid: row.uuid, rowIndex: index + 1 } : undefined
          )
          .filter((item) => !!item) as DynamicContentListItem[],
      })
    }
  }, [templateJson, isPreviewDynamicContentOn])

  useEffect(() => {
    if (isDarkMode) {
      previewFrameRef.current?.contentWindow?.postMessage('setDarkMode')
    } else {
      previewFrameRef.current?.contentWindow?.postMessage('revertDarkMode')
    }
  }, [isDarkMode])

  useEffect(() => {
    if (!isSaving) {
      loadPlainText()
    }
  }, [isSaving, loadPlainText])

  useEffect(() => {
    const defaultPreviewState = {
      contactsList: [],
      selectedContact: null,
      isPreviewPersonalizationOn: false,
      isPreviewDynamicContentOn: false,
      isDarkMode: false,
      isPlainText: isPlainTextOnly ?? false,
    }
    updatePreview(defaultPreviewState)
    return () => {
      updatePreview(defaultPreviewState)
    }
  }, [])

  return <EmailComposerPreview previewFrameRef={previewFrameRef} />
}

export default EmailComposerPreviewContainer
