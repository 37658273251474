import { createContext } from 'react'

import { CustomSourceItems, ExtendedItemDto } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { SelectV2Option, SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { Status } from '@components/StatusToast/StatusToast'
import { undefinedReturnedFunction } from '@const/globals'
import { SyncedSegment } from '@graphql/types/microservice/crm-types'
import { crmSegmentsMock } from '@src/pages/ContactSegments/components/AddContactsFromCRM/AddContactsFromCRM.mock'
import { Segment } from '@utils/contactSegments/contactSegments.utils'
import { CRM_STATUS, DEFAULT_SYNCS_REMAINING, DEFAULT_TOTAL_SYNCS } from '@utils/crm.utils'
import { CRMSourceOption } from '@utils/crm.utils'

export interface CRMSegmentSyncs {
  total: number
  remaining: number
}

export interface CRMSegmentSource {
  identifier: string
  label: string
}

export type SourceIdentifiersType = {
  [key: string]: string[]
}[]

export interface SourcedSegmentDefaultValues extends SyncedSegment {
  originalSources: CustomSourceItems
}

export enum RADIO_OPTION {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export type InvalidCRMStatus = Exclude<CRM_STATUS, CRM_STATUS.ON_GOING_SYNC | CRM_STATUS.CRM_SYNC_FAILED>

export interface InvalidSource {
  sourceIdentifier: string
  status: InvalidCRMStatus
}

export interface InvalidSources {
  [key: string]: InvalidSource[]
}

export interface AddContactsFromCRMContainerState {
  crmSegmentSources: CRMSegmentSource[]
  crmSourceOptions: CRMSourceOption[]
  invalidSources: InvalidSources
  hasUCLInitialized?: boolean
  isDirty: boolean
  isOngoingSync: boolean
  options: SelectV2SingleOption[]
  optionsDetails: SyncedSegment[]
  pickedSources: CustomSourceItems
  radioOption: RADIO_OPTION
  segmentName?: string
  selectedOption?: SelectV2SingleOption
  selectedSourcedSegment?: SourcedSegmentDefaultValues
  segments: Segment[]
  showDifferentTypeError: boolean
  showSyncNowModal: boolean
  sources: CustomSourceItems
  statusToast?: { statusMessage: string; status: Status }
  syncs: CRMSegmentSyncs
  validatingSources: boolean
  validSegmentName: boolean
}

export const AddContactsFromCRMContainerInitialState: AddContactsFromCRMContainerState = {
  crmSegmentSources: [],
  crmSourceOptions: [],
  invalidSources: {},
  isDirty: false,
  isOngoingSync: false,
  options: [],
  optionsDetails: [],
  pickedSources: {},
  radioOption: RADIO_OPTION.CREATE,
  showDifferentTypeError: false,
  showSyncNowModal: false,
  segments: crmSegmentsMock,
  sources: {},
  syncs: { total: DEFAULT_TOTAL_SYNCS, remaining: DEFAULT_SYNCS_REMAINING },
  validatingSources: false,
  validSegmentName: true,
}

export type Update = (fieldsToUpdate: Partial<AddContactsFromCRMContainerState>) => void

type RemoveSource = (source: ExtendedItemDto) => void

export interface AddContactsFromCRMAPI {
  values: AddContactsFromCRMContainerState
  update: Update
  getCRMSourcedSegmentOptions: (page?: number) => Promise<SelectV2Option[]>
  getSegmentSources: VoidFunction
  searchCRMSourcedSegmentOptions: (search: string) => Promise<SelectV2Option[]>
  isAValidSegmentName: (segmentName: string) => Promise<boolean>
  removeSource: RemoveSource
}

export const AddContactsFromCRMContextMock: AddContactsFromCRMAPI = {
  values: AddContactsFromCRMContainerInitialState,
  update: undefinedReturnedFunction,
  getCRMSourcedSegmentOptions: () => Promise.resolve([]),
  getSegmentSources: undefinedReturnedFunction,
  searchCRMSourcedSegmentOptions: () => Promise.resolve([]),
  isAValidSegmentName: () => Promise.resolve(true),
  removeSource: undefinedReturnedFunction,
}

export const AddContactsFromCRMContext = createContext<AddContactsFromCRMAPI>({ values: AddContactsFromCRMContainerInitialState } as any)
