import { gql } from '@apollo/client'

export default gql`
  mutation unifiedListFieldMapping($unifiedListFieldMapping: [UnifiedListFieldMappingInput]) {
    unifiedListFieldMapping(unifiedListFieldMapping: $unifiedListFieldMapping) {
      columnIndex
      dataType
      deleted
      displayName
      hidden
      ingestFieldMappings {
        format
        mapping
        objectType
        source
        sourceId
      }
      readOnly
      standardFieldKey
      typeMetaData
    }
  }
`
