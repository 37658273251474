import axios from 'axios'

const uploadPath = '/entity/uploads'

class Entity {
  static uploadCSV = (
    microserviceUrl: string,
    token: string,
    AOAccountContext: string,
    attributes: any,
    progress: any,
    success: Function,
    error: Function
  ) => {
    const url = `${microserviceUrl}${uploadPath}`
    const config = {
      headers: { authorization: token, AOAccountContext: AOAccountContext, 'Content-Type': 'multipart/form-data' },
      onUploadProgress: progress,
    }

    axios
      .post(url, attributes, config)
      .then((data) => success(data))
      .catch((data) => error(data))
  }
}

export default Entity
