import React, { FC, useContext, useEffect, useState } from 'react'

import StatusToast from '@components/StatusToast/StatusToast'
import { useTranslation } from '@const/globals'
import cancelSync from '@graphql/microservices/crm/cancelSync'
import { CancelSyncQuery, CancelSyncQueryVariables } from '@graphql/types/microservice/crm-types'
import LastSyncEndTime from '@src/pages/datamanagement/components/LastSyncEndTime/LastSyncEndTime'
import { DataManagementContext, RunningSyncStatus } from '@src/pages/datamanagement/context/DataManagementContext'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'

type Props = {
  className?: string
  dataTest?: string
}

const rootClass = 'cancel-sync'

const CancelSync: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const { update } = useContext(DataManagementContext)
  const [callLastSync, setCallLastSync] = useState(false)
  const [showError, setShowError] = useState(false)
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.CRM })

  const { loading, error, data } = useQueryOnMount<CancelSyncQuery, CancelSyncQueryVariables>(cancelSync, {
    client,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!loading && data && data.cancelSync) {
      setCallLastSync(false)
      if (data.cancelSync === 'Canceling') {
        const runningSyncStatus: RunningSyncStatus = {
          isSyncingNow: false,
          syncState: 'Canceling sync...',
          isCanceling: true,
        }
        update('runningSyncStatus', runningSyncStatus)
      }
      setCallLastSync(true)
    }
    if (error) {
      setShowError(true)
      setCallLastSync(true)
    }
  }, [data, error])

  return (
    <>
      {showError && <StatusToast isSuccess={false} message={t('Error Cancelling Sync')} closeStatus={() => setShowError(false)} />}
      <div className={`${className}`} data-test={`${dataTest}`}>
        {callLastSync && <LastSyncEndTime />}
      </div>
    </>
  )
}

export default CancelSync
