import React from 'react'

import { TFunction } from 'i18next'

import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { ProgramSource } from '@graphql/types/query-types'
import { Box } from '@src/pages/programs/UpgradeCommonComponents/Box/Box'
import { ConditionType } from '@src/pages/programs/upgradeManager/components/steps/Steps.utils'
import {
  UpgradedProgramCopyStep,
  UpgradedProgramRecordSetStep,
  UpgradedProgramSendStep,
  UpgradedSourceUpgradeStepType,
} from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'
import { ReviewRemovedStepCondition } from '@src/pages/programs/UpgradePrograms/components/ReviewUpgrade/sections/RemovedStepsSection/components/ReviewRemovedStepCondition/ReviewRemovedStepCondition'
import { ReviewRemovedStepEmailMessageAction } from '@src/pages/programs/UpgradePrograms/components/ReviewUpgrade/sections/RemovedStepsSection/components/ReviewRemovedStepEmailMessageAction/ReviewRemovedStepEmailMessageAction'
import { ReviewRemovedStepField } from '@src/pages/programs/UpgradePrograms/components/ReviewUpgrade/sections/RemovedStepsSection/components/ReviewRemovedStepField/ReviewRemovedStepField'

export const getSourceUpgradeStepSource = (step: UpgradedSourceUpgradeStepType, sources: ProgramSource[], t: TFunction) => {
  const source = sources.find((source) => source.id === step.srcId)
  return (
    <div className={`removed-steps-section__source-list-container`}>
      <Typography text={t('Source list')} weight={TextWeight.MEDIUM} />
      <Box className={`removed-steps-section__box`} isCurrent isSourceValue>
        <TextWithTooltipOnEllip typographyProps={{ text: source?.name ?? '', type: TextType.BODY_TEXT }} />
        <Typography
          text={t(`AutomatedPrograms.UpgradeManager.SourceReplacement.Contacts`, { count: source?.count })}
          type={TextType.BODY_TEXT_LIGHT}
        />
      </Box>
    </div>
  )
}

export const getCopyToListStepSource = (step: UpgradedProgramCopyStep, sources: ProgramSource[], t: TFunction) => {
  const source = sources.find((source) => source.id === step.listId)
  return (
    <div className={`removed-steps-section__source-list-container`}>
      <Typography text={t('Source list')} weight={TextWeight.MEDIUM} />
      <Box className={`removed-steps-section__box`} isCurrent isSourceValue>
        <TextWithTooltipOnEllip typographyProps={{ text: source?.name ?? '', type: TextType.BODY_TEXT }} />
        <Typography
          text={t(`AutomatedPrograms.UpgradeManager.SourceReplacement.Contacts`, { count: source?.count })}
          type={TextType.BODY_TEXT_LIGHT}
        />
      </Box>
    </div>
  )
}

export const getSendEmailMessageStepSource = (step: UpgradedProgramSendStep) => <ReviewRemovedStepEmailMessageAction step={step} />

export const getChangeFieldValueStepValues = (step: UpgradedProgramRecordSetStep, _sources: ProgramSource[], t: TFunction) => {
  return (
    <>
      {step?.conditions?.map((condition, index) => (
        <ReviewRemovedStepCondition key={condition.conditionId} condition={condition} type={index === 0 ? ConditionType.IF : ConditionType.ELSE_IF} />
      ))}
      {step?.operations?.length > 0 && (
        <Box className={`removed-steps-section__box`}>
          {step?.conditions?.length !== 0 && <Typography text={t('Otherwise')} lineHeight={LineHeight.MEDIUM} />}
          <div className={`removed-steps-section__operations`}>
            {step?.operations?.map((operation) => (
              <ReviewRemovedStepField key={operation.operationId} operation={operation} />
            ))}
          </div>
        </Box>
      )}
    </>
  )
}
