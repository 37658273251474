import {
  GetRecommendedSourcesRequestType,
  GetSearchSegmentSourcesRequestType,
  GetSegmentSourcesRequestType,
} from '@components/AssetPickers/CRMSourcePickerModal/GraphQL/CRMSourcePickerModal.graphQL'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { SegmentSourceQueryResponse } from '@graphql/types/microservice/crm-types'
import { CRM_CONNECTOR_DEFINITIONS } from '@utils/crm.utils'
import { CRMSourceOption } from '@utils/crm.utils'
import { CRMConnectorType } from '@utils/hooks/useCRM'
import { logNewRelicError } from '@utils/new-relic.utils'

const convertToSegmentSourceQueryResponse = (sources: any) =>
  Object.entries(sources).reduce(
    (total: SegmentSourceQueryResponse[], current) => [...total, { identifier: current[0], label: current[1] } as SegmentSourceQueryResponse],
    []
  )

export const convertToItemDto = (segmentSources: SegmentSourceQueryResponse[]) =>
  segmentSources.map(
    ({ identifier, label }) => ({ id: identifier, labels: [], position: 0, isFavorite: false, item: JSON.stringify({ name: label }) } as ItemDto)
  )

export const getRecommendedSourcesRequestUtils = async (
  getRecommendedSourcesRequest: GetRecommendedSourcesRequestType,
  selectedSourceOption: CRMSourceOption,
  t: Function,
  search?: string
) => {
  try {
    const { data } = await getRecommendedSourcesRequest({
      crmSourceOption: selectedSourceOption,
      searchTerm: search,
    })
    const sources = convertToItemDto(convertToSegmentSourceQueryResponse(data?.recommendedSources).slice(0, 1000))
    return { data: sources }
  } catch (error) {
    logNewRelicError(error)
    return { data: [], error: t('AddContactsFromCRM.StatusToast.Failed.RecommendedSources') }
  }
}

export const getMainFilterRequestResultsUtils = async (
  getSegmentSourcesRequest: GetSegmentSourcesRequestType,
  connectorType: CRMConnectorType,
  selectedSourceOption: CRMSourceOption,
  t: Function
) => {
  try {
    const { data } = await getSegmentSourcesRequest({
      connectedCrmName: CRM_CONNECTOR_DEFINITIONS[connectorType],
      crmSourceOption: selectedSourceOption,
    })

    const restrictedSources = data?.segmentSources?.slice(0, 1000) as SegmentSourceQueryResponse[]
    const sources = convertToItemDto(restrictedSources)
    return { data: sources }
  } catch (error) {
    logNewRelicError(error)
    return { data: [], error: t('AddContactsFromCRM.StatusToast.Failed.SourceOptions') }
  }
}

export const getSearchRequestResultsUtils = async (
  getSearchSegmentSourcesRequest: GetSearchSegmentSourcesRequestType,
  connectorType: CRMConnectorType,
  selectedSourceOption: CRMSourceOption,
  query: string,
  t: Function
) => {
  try {
    const { data } = await getSearchSegmentSourcesRequest({
      connectedCrmName: CRM_CONNECTOR_DEFINITIONS[connectorType],
      crmSourceOption: selectedSourceOption,
      searchTerm: query,
    })
    const restrictedSources = data?.segmentSources?.slice(0, 1000) as SegmentSourceQueryResponse[]
    const sources = convertToItemDto(restrictedSources)
    return { data: sources }
  } catch (error) {
    logNewRelicError(error)
    return { data: [], error: t('AddContactsFromCRM.StatusToast.Failed.SegmentSources') }
  }
}
