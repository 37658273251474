import { gql } from '@apollo/client'

export default gql`
  query getContactFormActivities($contactId: String, $activityType: String) {
    getContactFormActivities(contactId: $contactId, activityType: $activityType) {
      activity
      activityTime
      email
      formId
      formName
      type
    }
  }
`
