import { gql } from '@apollo/client'

export default gql`
  query getImageLibraryFolders($includeSvg: Boolean) {
    getImageLibraryFolders(includeSvg: $includeSvg) {
      folderId
      name
      isDefault
      entryCount
    }
  }
`
