import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import StaticImage from '@components/StaticImage/StaticImage'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { LineHeight, TextAlign, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './EmptyListing.css'

export interface EmptyListingProps {
  text?: string | ReactNode
  textAlign?: TextAlign
  textType?: TextType
  headline?: string
  buttonDisabled?: boolean
  buttonText?: string
  buttonURL?: string
  buttonType?: ButtonType
  buttonPlusIcon?: boolean
  buttonLinkIcon?: boolean
  buttonOnClick?: () => void
  imgSrc?: StaticImageNames
  imageWithTitle?: boolean
  maxHeight?: string
  linkText?: string
  link?: string
  className?: string
  dataTest?: string
  hideIcon?: boolean
  headlineType?: TextType
  headlineLineHeight?: LineHeight
  onLinkClick?: () => void
  textLinkSize?: TextLinkSize
  isStory?: boolean
  size?: EmptyListingSize
  withoutBorder?: boolean
  fullHeight?: boolean
  tagProps?: {
    weight?: TextWeight
    inline?: boolean
  }
}

const rootClass = 'empty-listing'

export enum EmptyListingSize {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
  CARD = 'card',
}

const EmptyListing: FC<EmptyListingProps> = (props: EmptyListingProps) => {
  const {
    text,
    textAlign = TextAlign.LEFT,
    textType = TextType.BODY_TEXT_LIGHT,
    headline,
    buttonDisabled = false,
    buttonURL,
    buttonOnClick,
    buttonText,
    buttonType = ButtonType.PRIMARY,
    buttonPlusIcon = false,
    buttonLinkIcon = false,
    imgSrc,
    imageWithTitle = true,
    maxHeight,
    linkText,
    link = '',
    dataTest = rootClass,
    hideIcon = true,
    headlineType = TextType.PAGE_HEADLINE,
    headlineLineHeight = LineHeight.VERY_LARGE,
    onLinkClick,
    textLinkSize = TextLinkSize.LARGE,
    size = EmptyListingSize.LARGE,
    isStory,
    className,
    withoutBorder,
    fullHeight,
    tagProps,
  } = props

  const buttonIconProps = buttonPlusIcon ? { hasIcon: ButtonIconPosition.LEFT } : {}
  const buttonProps = buttonURL
    ? { ...buttonIconProps, isLink: true, to: buttonURL }
    : {
        ...buttonIconProps,
        onClick: buttonOnClick,
        buttonPlusIcon,
      }
  const style = maxHeight ? { maxHeight } : {}
  const { t } = useTranslation()

  const textIsString = () => {
    return typeof text === 'string'
  }

  return (
    <div
      className={classNames(rootClass, className, {
        [`${rootClass}__no-border`]: withoutBorder,
        [`${rootClass}__full-height`]: fullHeight,
      })}
      style={style}
      data-test={dataTest}
    >
      {imgSrc && (
        <div
          className={classNames({
            [`${rootClass}__small`]: size === EmptyListingSize.SMALL,
            [`${rootClass}__medium`]: size === EmptyListingSize.MEDIUM,
            [`${rootClass}__large`]: size === EmptyListingSize.LARGE,
            [`${rootClass}__card`]: size === EmptyListingSize.CARD,
          })}
        >
          <StaticImage
            className={`${rootClass}__image`}
            dataTest={`${dataTest}__static__image`}
            name={imgSrc}
            altText={rootClass}
            isStory={isStory}
            withTitle={imageWithTitle}
          />
        </div>
      )}
      {headline && <Typography text={t(headline)} className={`${rootClass}__headline`} type={headlineType} lineHeight={headlineLineHeight} />}
      {textIsString() ? (
        <Typography
          text={t(text)}
          className={classNames(`${rootClass}__text`, {
            [`${rootClass}__text-small`]: size === EmptyListingSize.SMALL,
          })}
          textAlign={textAlign}
          type={textType}
          tagProps={{ bold: { weight: tagProps?.weight ?? TextWeight.BOLD, inline: tagProps?.inline } }}
        />
      ) : (
        text
      )}
      {(buttonURL || buttonOnClick) && buttonText && (
        <Button
          className={classNames(`${rootClass}__button`, {
            [`${rootClass}__button-small`]: size === EmptyListingSize.SMALL,
            [`${rootClass}__button-icon`]: buttonPlusIcon,
          })}
          buttonType={buttonType}
          {...buttonProps}
          disabled={buttonDisabled}
        >
          {buttonPlusIcon && <Svg name={SvgNames.plus} type={SvgType.ICON} fill={SvgColor.WHITE} />}
          {t(buttonText)}
          {buttonLinkIcon && <Svg className={`${rootClass}__button-link`} name={SvgNames.customLink} type={SvgType.ICON} fill={SvgColor.WHITE} />}
        </Button>
      )}
      {linkText && (
        <TextLink
          text={t(linkText)}
          link={link}
          className={classNames(`${rootClass}__link`, {
            [`${rootClass}__link-small`]: size === EmptyListingSize.SMALL,
          })}
          size={textLinkSize}
          hideIcon={hideIcon}
          onClick={onLinkClick}
        />
      )}
    </div>
  )
}

export default EmptyListing
