import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/Button'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './RecipientInfo.css'

interface RecipientInfoProps {
  className?: string
  dataTest?: string
  name: string
  description: string
  onReload?: () => void
  isCurrentUser?: boolean
  isGroup?: boolean
  isMuted?: boolean
}

const rootClass = 'recipient-info'

const RecipientInfo: FC<RecipientInfoProps> = (props: RecipientInfoProps) => {
  const { dataTest = rootClass, className = '', name, description, isGroup, isCurrentUser, isMuted, onReload } = props

  const { t } = useTranslation()

  const groupIcon = isMuted ? SvgNames.groupSubtractedRounded : SvgNames.groupRounded
  const userIcon = isMuted ? SvgNames.userSubtractedRounded : SvgNames.userRounded

  const getName = () => {
    const formattedName = isMuted ? name : `<darker>${name}</darker>`
    const currentUserLabel = isCurrentUser ? ` <regular>(${t('You')})</regular>` : ''
    return `${formattedName}${currentUserLabel}`
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__icon`}>
        <Svg name={isGroup ? groupIcon : userIcon} type={SvgType.VERY_LARGE_ICON} />
        {isMuted && (
          <div data-test={`${dataTest}-muted-label`} className={`${rootClass}__icon-muted`}>
            <Svg name={SvgNames.bellOffRoundedRed} type={SvgType.LARGER_ICON} />
            <Typography text={t('Muted')} type={TextType.BODY_TEXT_WHITE_TINY} weight={TextWeight.MEDIUM} />
          </div>
        )}
      </div>
      <div className={classNames({ [`${className}-details`]: className })}>
        <div className={`${rootClass}__name`}>
          <Typography
            className={'ellip'}
            text={getName()}
            tagProps={{
              regular: { weight: TextWeight.REGULAR },
              darker: { type: TextType.BODY_TEXT },
            }}
            weight={TextWeight.MEDIUM}
            type={TextType.BODY_TEXT_LIGHT}
            inline
          />
          {isGroup && (
            <Button
              buttonType={ButtonType.ICON}
              dataTest={`${dataTest}-reload-button`}
              className={`${rootClass}__name-group-button`}
              onClick={onReload}
            >
              <Svg name={SvgNames.reloadNoFill} className={`${rootClass}__name-group-button-icon`} type={SvgType.ICON} />
            </Button>
          )}
        </div>
        <Typography className={'ellip'} text={description} type={TextType.BODY_TEXT_SMALL_LIGHT} lineHeight={LineHeight.SMALL} />
      </div>
    </div>
  )
}

export default RecipientInfo
