import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import { FtpTooltipStatusIcons } from '@complex/ContactSegments/SegmentNameWithFtpSyncAndInfoHover/utils/SegmentNameWithFtpSyncAndInfoHover.constants'
import SegmentNameWithInfoHover from '@complex/ContactSegments/SegmentNameWithInfoHover/SegmentNameWithInfoHover'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ExecutionStatus, FtpExecutionStatus, FtpExportExecutionStatus, Segment } from '@utils/contactSegments/contactSegments.utils'
import { formatDateWithAbbreviations } from '@utils/date'

import './SegmentNameWithFtpSyncAndInfoHover.css'

interface SegmentNameWithFtpSyncAndInfoHoverProps {
  className?: string
  dataTest?: string
  segmentData: Segment
  cardBody: ReactNode
  ftpImportSyncData?: FtpExecutionStatus
  ftpExportSyncData?: FtpExportExecutionStatus
}

const rootClass = 'segment-name-with-ftp-sync-and-info-hover'

const SegmentNameWithFtpSyncAndInfoHover: FC<SegmentNameWithFtpSyncAndInfoHoverProps> = (props: SegmentNameWithFtpSyncAndInfoHoverProps) => {
  const { dataTest = rootClass, className = '', segmentData, cardBody, ftpImportSyncData, ftpExportSyncData } = props

  const { t } = useTranslation()

  const importSyncJobs = segmentData.ftpData?.import.syncJobs ?? []
  const exportSyncJobs = segmentData.ftpData?.export.syncJobs ?? []

  const importExecutions = segmentData.ftpData?.import.executionStatus ?? ftpImportSyncData
  const exportExecutions = segmentData.ftpData?.export.executionStatus ?? ftpExportSyncData

  const hasImportSyncJobs = importSyncJobs.length > 0 || !!importExecutions
  const hasExportSyncJobs = exportSyncJobs.length > 0 || !!exportExecutions

  const getImportTooltipText = (ftpSyncData: FtpExecutionStatus) => {
    return ftpSyncData.status === ExecutionStatus.Failed
      ? `Last FTP sync failed`
      : hasImportSyncJobs && hasExportSyncJobs
      ? 'Last FTP import completed'
      : 'Syncs via FTP'
  }

  const getExportTooltipText = (ftpSyncData: FtpExportExecutionStatus) => {
    return ftpSyncData.status === ExecutionStatus.Failed
      ? `Last FTP export failed`
      : hasImportSyncJobs && hasExportSyncJobs
      ? 'Last FTP export completed'
      : 'Export via FTP'
  }

  const listingIcon =
    importExecutions?.status === ExecutionStatus.Failed || exportExecutions?.status === ExecutionStatus.Failed
      ? SvgNames.inputStatusInvalidNoFill
      : SvgNames.reloadNoFill

  const renderStatus = (content: { tooltip: string; lastExecutionText: number; icon: SvgNames }) => {
    const { tooltip, lastExecutionText, icon } = content
    return (
      <div className={`${rootClass}__tooltip-data`}>
        <Svg
          name={icon}
          type={SvgType.ICON}
          className={classNames(`${rootClass}__tooltip-icon`, `${rootClass}__tooltip-icon-${icon}`)}
          dataTest={`${rootClass}__icon-${icon}`}
        />
        <div className={`${rootClass}__tooltip-content-details`}>
          <Typography text={t(tooltip)} type={TextType.BODY_TEXT_WHITE} weight={TextWeight.MEDIUM} />
          {lastExecutionText && <Typography text={lastExecutionText} type={TextType.BODY_TEXT_WHITE} />}
        </div>
      </div>
    )
  }

  const renderImportStatus = (data: FtpExecutionStatus) => {
    return renderStatus({
      tooltip: getImportTooltipText(data),
      lastExecutionText: data.lastExecution ? t(`Last sync: {{date}}`, { date: formatDateWithAbbreviations(data.lastExecution, true) }) : null,
      icon: FtpTooltipStatusIcons[data.status],
    })
  }

  const renderExportStatus = (data: FtpExportExecutionStatus) => {
    return renderStatus({
      tooltip: getExportTooltipText(data),
      lastExecutionText: data.lastExecution ? t(`Last export: {{date}}`, { date: formatDateWithAbbreviations(data.lastExecution, true) }) : null,
      icon: FtpTooltipStatusIcons[data.status],
    })
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__content`}>
        <Tooltip
          trigger={
            <Svg
              name={listingIcon}
              type={SvgType.LARGER_ICON}
              className={classNames(`${rootClass}__icon`, `${rootClass}__icon-${listingIcon}`)}
              dataTest={`${dataTest}-tooltip-icon`}
            />
          }
          align={'center'}
          className={`${rootClass}__tooltip`}
        >
          <div className={`${rootClass}__tooltip-content`} data-test={`${dataTest}-tooltip-content`}>
            <div className={`${rootClass}__tooltip-header`}>
              <Typography
                className={classNames(`${rootClass}__tooltip-title`, 'ellip')}
                text={t(hasImportSyncJobs && hasExportSyncJobs ? 'FTP import & export' : hasExportSyncJobs ? 'FTP export' : 'FTP import')}
                type={TextType.SECTION_HEADER}
                weight={TextWeight.BOLD}
              />
            </div>
            {!!importExecutions && renderImportStatus(importExecutions)}
            {!!exportExecutions && renderExportStatus(exportExecutions)}
          </div>
        </Tooltip>
        <Tooltip position={'top'} trigger={segmentData.name} ellipOnTrigger>
          {segmentData.name}
        </Tooltip>
        <SegmentNameWithInfoHover segmentData={segmentData} cardBody={cardBody} />
      </div>
    </div>
  )
}

export default SegmentNameWithFtpSyncAndInfoHover
