import React, { FC, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import InfoTooltip from '@components/InfoTooltip/InfoTooltip'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography from '@components/Typography/Typography'
import { EmailComposerPreviewProps } from '@src/pages/EmailComposer/components/EmailComposerPreview/EmailComposerPreview'
import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'
import { useTranslation } from '@utils/const/globals'

import { handleDynamicContent } from '../../../../EmailComposerPreview.utils'
import EmailComposerPreviewIframe from '../EmailComposerPreviewIframe/EmailComposerPreviewIframe'
import EmailComposerPreviewPlainText from '../EmailComposerPreviewPlainText/EmailComposerPreviewPlainText'

import './EmailComposerPreviewMobile.css'

type EmailComposerPreviewContentType = Pick<EmailComposerPreviewProps, 'previewFrameRef'> & {
  srcValue?: string
  hideInfo?: boolean
  hideRotate?: boolean
}

const rootClass = 'email-composer-preview-mobile'

const EmailComposerPreviewMobile: FC<EmailComposerPreviewContentType> = ({ previewFrameRef, hideRotate, srcValue, hideInfo }) => {
  const {
    values: {
      message: { isPlainTextOnly },
      preview: { html, isPlainText, isDarkMode, dynamicContentList },
      landingPage: { isLandingPage },
    },
  } = useComposerContext()

  const [isRotated, setIsRotated] = useState<boolean>(false)

  const { t } = useTranslation()

  return (
    <>
      <div className={rootClass}>
        <div
          className={classNames(`${rootClass}__container`, {
            [`${rootClass}__container--rotated`]: isRotated,
            [`${rootClass}__container--with-plain-text`]: isPlainText,
          })}
          data-test={`${rootClass}-container`}
        >
          {isPlainText || isPlainTextOnly ? (
            <EmailComposerPreviewPlainText isRotated={isRotated} />
          ) : (
            <EmailComposerPreviewIframe
              isLandingPage={isLandingPage}
              previewFrameRef={previewFrameRef}
              html={handleDynamicContent(dynamicContentList, srcValue ?? html)}
              isMobile
              isRotated={isRotated}
              isDarkMode={isDarkMode}
            />
          )}
        </div>
        {!hideRotate && (
          <Button
            className={`${rootClass}__container-rotate-button`}
            buttonType={ButtonType.OUTLINE}
            onClick={() => setIsRotated(!isRotated)}
            dataTest={`${rootClass}-rotate-button`}
          >
            <Svg name={SvgNames.refresh} type={SvgType.LARGER_ICON} />
            {t('Rotate screen')}
          </Button>
        )}
      </div>
      {!hideInfo && (
        <div className={`${rootClass}__info flex-align-center`}>
          <Typography text={t('EmailComposer.Preview.Mobile.Info', { context: isLandingPage ? 'landingPage' : 'default' })} />
          {!isLandingPage && <InfoTooltip minimalPadding={false}>{t('EmailComposer.Preview.Mobile.Info.Tooltip')}</InfoTooltip>}
        </div>
      )}
    </>
  )
}

export default EmailComposerPreviewMobile
