import { useApolloClient } from '@apollo/client'
import moveItems from '@graphql/microservices/categorization/moveItems'
import setFavoriteItems from '@graphql/microservices/categorization/setFavoriteItem'
import shareAssetToCatalog from '@graphql/mutations/shareAssetToCatalog'
import {
  MoveItemsMutation,
  MoveItemsMutationVariables,
  SetFavoriteItemMutation,
  SetFavoriteItemMutationVariables,
} from '@graphql/types/microservice/categorization-types'
import { ShareAssetToCatalogMutation, ShareAssetToCatalogMutationVariables } from '@graphql/types/mutation-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export interface HeaderActionRequests {
  moveItemsToFoldersMutationRequest: (type: string, itemsIds: number[], folderId?: number) => FetchPromise<MoveItemsMutation>
  setFavoriteItemsMutationRequest: (type: string, itemsIds: number[], isFavorite: boolean) => FetchPromise<SetFavoriteItemMutation>
  shareToCatalogMutationRequest: (type: string, ids?: string[]) => FetchPromise<ShareAssetToCatalogMutation>
  deleteFromFolderMutationRequest: (type: string, itemsIds: number[]) => FetchPromise<MoveItemsMutation>
}

export const useHeaderActionRequests = (): HeaderActionRequests => {
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })
  const actonClient = useApolloClient()

  const setFavoriteItemsMutationRequest = async (type: string, itemsIds: number[], isFavorite: boolean) => {
    return await client.mutate<SetFavoriteItemMutation, SetFavoriteItemMutationVariables>({
      mutation: setFavoriteItems,
      errorPolicy: 'all',
      variables: {
        type,
        itemIds: itemsIds,
        isFavorite,
      },
    })
  }
  const moveItemsToFoldersMutationRequest = async (type: string, itemsIds: number[], folderId?: number) =>
    await client.mutate<MoveItemsMutation, MoveItemsMutationVariables>({
      mutation: moveItems,
      errorPolicy: 'all',
      variables: {
        type,
        itemIds: itemsIds,
        folderId,
      },
    })

  const shareToCatalogMutationRequest = async (type: string, ids?: string[]) =>
    await actonClient.mutate<ShareAssetToCatalogMutation, ShareAssetToCatalogMutationVariables>({
      mutation: shareAssetToCatalog,
      errorPolicy: 'all',
      variables: {
        type,
        ids,
      },
    })

  const deleteFromFolderMutationRequest = async (type: string, itemsIds: number[]) =>
    await client.mutate<MoveItemsMutation, MoveItemsMutationVariables>({
      mutation: moveItems,
      errorPolicy: 'all',
      variables: {
        type,
        itemIds: itemsIds,
        folderId: null,
      },
    })

  return {
    moveItemsToFoldersMutationRequest,
    setFavoriteItemsMutationRequest,
    shareToCatalogMutationRequest,
    deleteFromFolderMutationRequest,
  }
}
