import { useEffect, useState } from 'react'

/**
 * Custom React hook that monitors and returns the visibility state of the document.
 * It provides a boolean state indicating whether the document is currently visible to the user.
 * This can be useful for optimizing interactions and performance when the document is not in view.
 * This is helpful when we want to detect browser tab visibility changes.
 *
 * @returns {boolean} isVisible - The current visibility state of the document.
 */
export const useDocumentVisibility = () => {
  const [isVisible, setIsVisible] = useState(!document.hidden)

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden)
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  return isVisible
}
