import { legacyActonContext } from '@const/globals'

export enum ContactViewPanel {
  SUMMARY = 'Summary',
  TIMELINE = 'Timeline',
  SCORING = 'Scoring',
  MEMBERSHIP = 'Membership',
  INFO = 'Info',
}

export const getContactViewURL = (contactId: string, defaultTab?: string) =>
  `${legacyActonContext}/ng-ui/contact?recid=${contactId}&defaultTab=${defaultTab ?? ContactViewPanel.INFO}&frame=1&ts=${new Date().getTime()}`

export const openContactView = (contactId: string, defaultTab: string) => {
  const url = getContactViewURL(contactId, defaultTab)
  const width = 980
  const height = 880
  const left = (screen.width - width) / 2
  const top = (screen.height - height) / 2
  const features = `left=${left},top=${top},width=${width},height=${height},modal=yes,dependent=yes,resizable=yes,scrollbars=yes,toobar=no,location=no,status=no`

  return window.open(url, '_blank', features)
}
