import React, { FC, MutableRefObject, ReactNode, useCallback, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import Typography, { TextType, TextWeight, TypographyProps } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import * as PopoverPrimitive from '@radix-ui/react-popover'
import { Align, Side } from '@radix-ui/react-popper'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'

import './Tooltip.css'

export type TooltipProps = {
  text?: ReactNode
  trigger: ReactNode
  activeTrigger?: ReactNode
  children?: ReactNode | ReactNode[]
  openOnClick?: boolean
  closeOnClick?: boolean
  autoCloseDuration?: number
  link?: string
  disabledTrigger?: boolean
  disableTooltip?: boolean
  linkText?: string
  position?: Side
  align?: Align
  sideOffset?: number
  alignArrowToStart?: boolean
  alignOffset?: number
  arrowOffset?: number
  triggerClassName?: string
  minimalPadding?: boolean
  fullWidth?: boolean
  alignTextCenter?: boolean
  className?: string
  dataTest?: string
  hide?: boolean
  ellipOnTrigger?: boolean
  isPopover?: boolean
  withoutTail?: boolean
  triggerRef?: MutableRefObject<HTMLElement | undefined>
  textBold?: string
  inline?: boolean
  hideWhenDetached?: boolean
  neverEllip?: boolean
  childrenProps?: TypographyProps
  // Temporary solution to show tooltip content
  alwaysShowOnHover?: boolean
  arrowClassName?: string
  borderColor?: string
}

const rootClass = 'tooltip'

const Close = PopoverPrimitive.Close

const Tooltip: FC<TooltipProps> = (props) => {
  const {
    trigger,
    children: originalChildren,
    text,
    activeTrigger,
    openOnClick,
    closeOnClick = false,
    autoCloseDuration,
    link,
    linkText,
    position = 'top',
    align = 'center',
    sideOffset = 3,
    alignArrowToStart = false,
    alignOffset = 0,
    arrowOffset = 3,
    dataTest = rootClass,
    triggerClassName,
    minimalPadding = true,
    disabledTrigger = false,
    disableTooltip,
    fullWidth = false,
    alignTextCenter = false,
    className = '',
    childrenProps = {},
    hide,
    ellipOnTrigger = false,
    neverEllip = false,
    isPopover = false,
    withoutTail = false,
    triggerRef,
    textBold = '',
    inline = true,
    hideWhenDetached = true,
    alwaysShowOnHover = false,
    arrowClassName,
    borderColor,
  } = props
  const _isPopover = isPopover || !!link
  const isPopoverEnabled = _isPopover || openOnClick
  // This will make this compatible with Typography's tagComponents prop
  const children = text === undefined ? originalChildren : text

  const Component = isPopoverEnabled ? PopoverPrimitive : TooltipPrimitive
  const [isEllip, setIsEllip] = useState<boolean>(false)
  const [open, setOpen] = useState(false)
  const [timeOutId, setTimeOutId] = useState<NodeJS.Timeout>()
  const { t } = useTranslation()
  const triggerContainerRef = useRef<any>(null)

  const detectIsEllip = useCallback(() => {
    const textContainer = triggerContainerRef.current
    setIsEllip(!neverEllip && !!textContainer && textContainer.scrollWidth > textContainer.clientWidth)
  }, [])

  useEffect(() => {
    detectIsEllip()
  }, [])

  const getToggleMethod = () => {
    if (autoCloseDuration) {
      if (open) {
        return
      }

      return () => {
        setOpen(true)

        setTimeout(() => {
          setOpen(false)
        }, autoCloseDuration)
      }
    }

    return () => setOpen(!open)
  }

  useEffect(() => {
    window.addEventListener('resize', detectIsEllip)

    return () => {
      window.removeEventListener('resize', detectIsEllip)
    }
  }, [detectIsEllip])

  const getTriggerEvent = () => {
    if (openOnClick) {
      return { onClick: getToggleMethod() }
    }

    return _isPopover ? { onMouseEnter: getToggleMethod() } : {}
  }

  const focusOutside = _isPopover && !autoCloseDuration && !closeOnClick ? { onFocusOutside: getToggleMethod() } : {}
  const pointerDownOutside = closeOnClick
    ? {
        onPointerDownOutside: (e: Event) => {
          if (!triggerContainerRef.current.contains(e.target)) {
            setOpen(() => false)
          }
        },
      }
    : {}
  const openProp = hide ? { open: false } : isPopoverEnabled ? { open } : {}

  const preventDefault = useCallback((e: Event) => e.preventDefault(), [])
  const handleInteractOutside = useCallback((e: Event) => {
    if (e.target === triggerRef?.current) {
      triggerRef.current.focus()
    }
  }, [])

  const popoverProps = _isPopover
    ? {
        // This fixes issue with focusing input, when it is trigger for popover
        trapFocus: false,
        onOpenAutoFocus: preventDefault,
        onCloseAutoFocus: preventDefault,
        onInteractOutside: handleInteractOutside,
      }
    : {}

  const renderLink = (children: JSX.Element) => {
    const modalElement = document.getElementById('modal-root')
    const triggerElement = triggerContainerRef.current
    const isWithinModal = modalElement && triggerElement && modalElement.contains(triggerElement)

    if (!isWithinModal) {
      return (
        <a href={link} target="_blank" rel="noreferrer">
          {children}
        </a>
      )
    } else {
      // Workaround: within a modal the link is not receiving the click event
      const onClick = () => window.open(link, '_blank')
      return (
        <a href={link} onMouseUp={onClick} onKeyDown={(keyDownEvent) => (keyDownEvent.key === ' ' ? onClick() : undefined)}>
          {children}
        </a>
      )
    }
  }

  const triggerhasProps = !!trigger && typeof trigger === 'object' && 'props' in trigger
  const ellipOnText = isEllip && triggerhasProps && trigger?.props.text !== undefined
  const renderOnIcon = !isEllip && triggerhasProps && trigger?.props?.text === undefined

  const Primitive = (
    <Component.Root delayDuration={350} data-test={dataTest} {...openProp} disableHoverableContent>
      <Component.Trigger
        asChild
        data-test="tooltip-trigger"
        ref={triggerContainerRef}
        className={classNames(`${rootClass}__trigger`, triggerClassName, {
          [`${rootClass}__trigger--full-width`]: fullWidth,
          [`${rootClass}__trigger--disabled`]: disabledTrigger,
          [`${rootClass}__trigger--disable-tooltip`]: disableTooltip,
          [`${rootClass}__trigger--inline`]: inline,
          [`${className}__trigger-wrapper`]: className,
          ['ellip']: ellipOnTrigger,
        })}
        {...getTriggerEvent()}
      >
        <div
          onMouseLeave={() => {
            if (!closeOnClick) {
              setTimeOutId(
                setTimeout(() => {
                  setOpen(false)
                }, 250)
              )
            }
          }}
          className={classNames({ ['ellip']: ellipOnTrigger }, { [`${rootClass}__ellip-on-text`]: ellipOnText })}
        >
          {open ? activeTrigger ?? trigger : trigger}
        </div>
      </Component.Trigger>
      <Component.Portal>
        {(alwaysShowOnHover || ellipOnText || renderOnIcon || openOnClick) && (
          <Component.Content
            side={position}
            align={align}
            alignOffset={alignOffset}
            sideOffset={sideOffset}
            {...pointerDownOutside}
            {...focusOutside}
            {...popoverProps}
            className={`${rootClass}__content`}
            hideWhenDetached={hideWhenDetached}
          >
            <div
              className={classNames(rootClass, className, {
                [`${rootClass}__minimal-padding`]: minimalPadding,
                [`${rootClass}__align-text-center`]: alignTextCenter,
              })}
              onMouseEnter={() => (timeOutId ? clearTimeout(timeOutId) : undefined)}
              onFocus={() => (timeOutId ? clearTimeout(timeOutId) : undefined)}
              onMouseLeave={() => !closeOnClick && setOpen(false)}
              onBlur={() => !closeOnClick && setOpen(false)}
              style={{ borderColor }}
            >
              {!!textBold && (
                <Typography className={`${rootClass}__text-bold`} text={t(textBold)} type={TextType.BODY_TEXT_WHITE} weight={TextWeight.MEDIUM} />
              )}
              <Typography className={`${rootClass}__children`} text={children} type={TextType.BODY_TEXT_WHITE} inline {...childrenProps} />
              {link && (
                <Close className={`${rootClass}__actions`}>{renderLink(<Typography text={linkText} type={TextType.BODY_TEXT_WHITE} inline />)}</Close>
              )}
            </div>
            {!withoutTail && (
              <Component.Arrow
                offset={arrowOffset}
                className={classNames(`${rootClass}__arrow`, arrowClassName, {
                  [`${rootClass}__arrow-left-aligned`]: alignArrowToStart && (position === 'top' || position === 'bottom'),
                })}
              />
            )}
          </Component.Content>
        )}
      </Component.Portal>
    </Component.Root>
  )

  return isPopoverEnabled ? Primitive : <TooltipPrimitive.Provider>{Primitive}</TooltipPrimitive.Provider>
}

export default Tooltip
