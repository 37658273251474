import React, { FC, useCallback, useState } from 'react'

import classNames from 'classnames'

import Container from '@components/Container/Container'
import Loader from '@components/Loader/index'
import PageContainer from '@components/PageContainer/PageContainer'
import PageHeader from '@components/PageHeader/index'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ANALYTICS_INVISIBLE_ACTIONS } from '@src/pages/reports/Analytics/Analytics.constants'
import { Action, SageEmbed, useEmbedRef } from '@thoughtspot/visual-embed-sdk/react'

import './SageBoard.css'

interface SageBoardProps {
  loading: boolean
  className?: string
  dataTest?: string
}

const rootClass = 'sage-board'

const SageBoard: FC<SageBoardProps> = (props: SageBoardProps) => {
  const { loading, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const [sheetLoaded, setSheetLoaded] = useState(true)
  const embedRef = useEmbedRef<typeof SageEmbed>()

  const hiddenActions = [...ANALYTICS_INVISIBLE_ACTIONS, Action.EditSageAnswer]

  const onLoaded = useCallback(() => {
    setSheetLoaded(true)
  }, [])

  return (
    <PageContainer className={classNames(rootClass, className)} alwaysPadding dataTest={dataTest}>
      <PageHeader primaryText={t('Home')} leftContent />
      {loading && <Loader />}
      {!loading && (
        <div
          className={classNames(`${rootClass}__embed-container`, {
            [`${rootClass}__embed-container-loaded`]: sheetLoaded,
          })}
        >
          <SageEmbed
            ref={embedRef}
            hiddenActions={hiddenActions}
            onSageEmbedQuery={() => onLoaded()}
            className={classNames(`${rootClass}__embed`, {
              [`${rootClass}__embed-loaded`]: sheetLoaded,
            })}
          />
        </div>
      )}
      <Container>
        <Typography type={TextType.SECTION_HEADER}>This is below the embedded component</Typography>
      </Container>
    </PageContainer>
  )
}

export default SageBoard
