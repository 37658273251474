import React from 'react'

import { TFunction } from 'i18next'

import { SelectAssetV2RowItem } from '@components/SelectAssetV2/SelectAssetV2'
import { SvgNames, SvgType } from '@components/Svg'
import Svg, { SvgColor, SvgContainerType } from '@components/Svg/Svg'
import { RowAction } from '@components/TableV2/tableV2TS/interfaces'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { AssetReportRecipientDetails, AssetReportType, ListsValidation, SendToListResponse } from '@graphql/types/query-types'
import { MessageRecipient } from '@src/pages/EmailComposer/utils/EmailComposer.types'
import { CellContext } from '@tanstack/react-table'
import { EmailValidations, SendToContact, SendtoList, SettingsValidations } from '@utils/composer/context/EmailComposer.context'
import { PERSONALIZED_FROM_ADDRESS_OPTION_VALUE, SALESFORCE_OWNER_OPTION_VALUE } from '@utils/composer/settings.constants'
import { formatNumber } from '@utils/numbers'
import { ensureFirstLetterIsCapitalized } from '@utils/strings'

export enum RecipientModals {
  NONE = 'none',
  CHOOSE_TYPE = 'choose_type',
  LIST_PICKER = 'list_picker',
  ADD_LIST_CONTACTS = 'add_list_contacts',
  ADD_CONTACTS_FROM_LIST = 'add_contacts_from_list',
  ADD_CONTACTS = 'add_contacts',
}

export interface RecipientsState {
  currentPage: number
  pageSize: number
  resetPageIndex: boolean
  recipients: MessageRecipient[]
  currentData: MessageRecipient[]
  activeModal: RecipientModals
  selectListForContact?: MessageRecipient
  selectListContacts?: MessageRecipient[]
  chosenContacts?: MessageRecipient[]
}

type AssetReportRecipientTypesWithSvg = Extract<AssetReportType, 'WEB' | 'LANDING_PAGE' | 'MSG' | 'PARTNER' | 'DOWNLOAD'>

const urlParams = new URLSearchParams(window.location.search)
const messageType = urlParams.get('messageConfigurationType')
const isCrmMessage = messageType === 'crm'

export const getRecipientsDefaultState = (sendto_lists?: SendtoList[], sendto_contacts?: SendToContact[]): RecipientsState => {
  const recipientLists: MessageRecipient[] = sendto_lists
    ? sendto_lists.map((list) => ({
        id: list.srcId,
        isList: true,
        name: list.srcName,
        size: list.size,
      }))
    : []

  const recipientContacts: MessageRecipient[] = sendto_contacts
    ? sendto_contacts.map((contact) => ({
        id: contact.id,
        isList: false,
        name: contact.name,
        size: 1,
        email: contact.email,
      }))
    : []

  const recipients = [...recipientLists, ...recipientContacts].sort((a, b) => (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1))

  const currentPage = 0
  const pageSize = 10

  return {
    currentPage,
    pageSize,
    resetPageIndex: !(recipients.length > 10),
    activeModal: RecipientModals.NONE,
    recipients,
    currentData: recipients.length > 10 ? recipients.slice(currentPage, pageSize) : recipients,
  }
}

export const getCurrentRecipientsData = (pageIndex: number, pageSize: number, recipients: MessageRecipient[]) => {
  const start = pageIndex * pageSize
  return recipients.slice(start, start + pageSize) ?? []
}

const CRITICAL_VALIDATIONS = ['zeroRecipients', 'listsIncompatibleWithSalesforceOwner', 'listsIncompatibleWithPersonalizedFrom']

export const isValidationCritical = (validation: keyof EmailValidations) => {
  return CRITICAL_VALIDATIONS.includes(validation)
}

const renderSingleContact = (recipient: MessageRecipient, rootClass: string) => (
  <div className={`${rootClass}__recipients`}>
    <TextWithTooltipOnEllip
      typographyProps={{
        text: recipient.name,
      }}
      tooltipProps={{ alignTextCenter: true, children: recipient.name }}
    />
    {!isCrmMessage && (
      <Typography text={recipient.email} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.ITALIC} className={`${rootClass}__recipient-email`} />
    )}
  </div>
)

export const renderContactsAsList = (rootClass: string, recipientDetails: AssetReportRecipientDetails, t: TFunction) => {
  const { title, type, column, reportName: name } = recipientDetails
  const shouldShowTypeSvg = type === 'WEB' || type === 'LANDING_PAGE' || type === 'MSG' || type === 'PARTNER' || type === 'DOWNLOAD'

  const typeSvgProps: { [key in AssetReportRecipientTypesWithSvg]: { label: string; icon: SvgNames } } = {
    ['WEB']: { label: t('Web'), icon: SvgNames.world },
    ['LANDING_PAGE']: {
      label: t('Landing Page'),
      icon: SvgNames.airplaneLandingV2,
    },
    ['MSG']: {
      label: t('Email'),
      icon: SvgNames.email,
    },
    ['PARTNER']: {
      label: t('Partner'),
      icon: SvgNames.threeUsersSelected,
    },
    ['DOWNLOAD']: {
      label: t('Download'),
      icon: SvgNames.download,
    },
  }

  const tagComponents = {
    name,
    action: t(`SendDetails.AssetReportRecipients.${column === 'unique' || column === 'submit' ? 'Submit' : ensureFirstLetterIsCapitalized(column)}`),
    title,
    TypeSvg: shouldShowTypeSvg && (
      <Tooltip
        text={typeSvgProps[type].label}
        trigger={
          <Svg name={typeSvgProps[type].icon} type={SvgType.LARGER_ICON} containerType={SvgContainerType.STANDARD} fill={SvgColor.DARK_BLUE} />
        }
        triggerClassName={`${rootClass}__svg-tooltip`}
      />
    ),
    FormSvg: (
      <Tooltip
        text={'Form'}
        trigger={<Svg name={SvgNames.formV2} type={SvgType.LARGER_ICON} containerType={SvgContainerType.STANDARD} fill={SvgColor.DARK_BLUE} />}
        triggerClassName={`${rootClass}__svg-tooltip`}
      />
    ),
  }

  return (
    <div className={`${rootClass}__recipients`}>
      <Typography
        text={t(`SendDetails.AssetReportRecipients.${shouldShowTypeSvg ? 'Common' : type}`)}
        tagComponents={tagComponents}
        className={`${rootClass}__recipients-no-ellip`}
      />
    </div>
  )
}

const renderListWithValidations = (name: string, trContext: string, isError: boolean) => (
  <div className="flex-align-center">
    <Tooltip
      triggerClassName="flex-align-center push-left"
      inline={false}
      minimalPadding={false}
      trigger={
        <Svg
          name={isError ? SvgNames.inputStatusInvalidNoFill : SvgNames.warningSolidNoFill}
          fill={isError ? SvgColor.ERROR_TEXT : SvgColor.YELLOW_CAUTION}
          type={SvgType.ICON}
          dataTest={`send-details-recipients-validation`}
        />
      }
      childrenProps={{
        text: `SendDetails.Recipients.Validation.Tooltip.${trContext}`,
        tagProps: { bold: { weight: TextWeight.BOLD } },
        inline: true,
      }}
    />
    {name}
  </div>
)

export const getAssetReportRecipientColumns = (
  recipientCount: number,
  rootClass: string,
  recipientDetails: AssetReportRecipientDetails,
  t: TFunction
): ColumnDefWithAdditionalProps<AssetReportRecipientDetails>[] => [
  {
    header: 'Recipients',
    accessorKey: 'recipient',
    textAlign: 'left',
    cell: () => renderContactsAsList(rootClass, recipientDetails, t),
  },
  {
    header: 'Records',
    accessorKey: 'records',
    textAlign: 'right',
    maxSize: 150,
    cell: () => formatNumber(recipientCount),
  },
]

export const getRecipientsColumns = (
  rootClass: string,
  senderUUID?: string,
  listsValidation?: ListsValidation,
  settingsValidations?: SettingsValidations,
  isOptInEmail?: boolean
): ColumnDefWithAdditionalProps<MessageRecipient>[] => {
  const crmColumn: ColumnDefWithAdditionalProps<MessageRecipient>[] = isCrmMessage
    ? [
        {
          header: '',
          accessorKey: 'email',
          textAlign: 'left',
          disableSorting: true,
          maxSize: 300,
          cell: (cell) => (
            <Typography
              text={cell.row.original.email}
              type={TextType.BODY_TEXT_LIGHT}
              weight={TextWeight.ITALIC}
              className={`${rootClass}__recipient-email`}
            />
          ),
        },
      ]
    : []

  return [
    {
      header: 'Name',
      accessorKey: 'name',
      textAlign: 'left',
      cell: (cell) => {
        if (!cell.row.original.isList) {
          return renderSingleContact(cell.row.original, rootClass)
        }
        const nameToRender = cell.getValue<string>()
        const name = cell.row.original.name
        const id = cell.row.original.id

        if (listsValidation) {
          const { deletedListNames, listsWithMissingEmailAddressColumn, trashListNames } = listsValidation

          if (!!listsWithMissingEmailAddressColumn?.includes(name)) {
            return renderListWithValidations(nameToRender, 'EmailColumn', true)
          }

          if (!!deletedListNames?.includes(name) || !!trashListNames?.includes(name)) {
            return renderListWithValidations(nameToRender, 'Deleted', false)
          }
        }
        if (settingsValidations) {
          const { listsIncompatibleWithPersonalizedFrom, listsIncompatibleWithSalesforceOwner } = settingsValidations
          if (senderUUID === PERSONALIZED_FROM_ADDRESS_OPTION_VALUE && listsIncompatibleWithPersonalizedFrom.includes(id)) {
            return renderListWithValidations(nameToRender, 'Personalized', true)
          }
          if (senderUUID === SALESFORCE_OWNER_OPTION_VALUE && listsIncompatibleWithSalesforceOwner.includes(id)) {
            return renderListWithValidations(nameToRender, 'Salesforce', true)
          }
        }
        return nameToRender
      },
    },
    ...crmColumn,
    ...(!isOptInEmail
      ? [
          {
            header: 'Records',
            accessorKey: 'size',
            textAlign: 'right',
            maxSize: 150,
            cell: (cell: CellContext<MessageRecipient, unknown>) => (cell.getValue<number>() === -1 ? '--' : formatNumber(cell.getValue<number>())),
            enableHiding: isOptInEmail,
          } as ColumnDefWithAdditionalProps<MessageRecipient>,
        ]
      : []),
  ]
}

export const getNoRecipientsColumns = () => {
  return [
    {
      header: 'Recipient',
      accessorKey: 'recipient',
      textAlign: 'left',
      cell: () => (
        <div className="flex-align-center">
          <Svg name={SvgNames.inputStatusInvalidNoFill} fill={SvgColor.ERROR_TEXT} type={SvgType.ICON} className="push-left" />
          <Typography
            text="EmailComposer.Recipients.Validation.NoList"
            type={TextType.ERROR_NEW}
            tagProps={{ medium: { weight: TextWeight.MEDIUM } }}
            inline
          />
        </div>
      ),
    },
    {
      header: 'Records',
      accessorKey: 'record',
      textAlign: 'right',
      maxSize: 150,
    },
  ]
}

export const getRowActions = (
  getConnectedCrm: string,
  t: TFunction,
  removeRow: (id: string, isList: boolean) => void
): RowAction<MessageRecipient>[] => {
  const urlParams = new URLSearchParams(window.location.search)
  const messageType = urlParams.get('messageConfigurationType')
  const isCrmMessage = messageType === 'crm'

  return [
    {
      tooltipMessage: t('You can’t remove {{getConnectedCrm}} recipients from here.', { getConnectedCrm }),
      hasTooltip: isCrmMessage,
      label: t('Remove'),
      icon: SvgNames.deleteRounded,
      disabled: isCrmMessage,
      tooltipAlign: 'start',
      tooltipAlignOffset: 10,
      onClick: (row) => removeRow(row.original.id, row.original.isList),
    },
  ]
}

export const getUpdatedRecipientsCount = (recipients: MessageRecipient[]) => {
  const recipientsCount = recipients.reduce((acc, curr) => acc + curr.size, 0)
  return recipientsCount > 0 ? recipientsCount : 0
}

export const getModalRowItems = (t: TFunction, disableLists: boolean, disableIndividuals: boolean): SelectAssetV2RowItem[] => {
  return [
    {
      iconUnselected: SvgNames.contactBook,
      iconSelected: SvgNames.contactBook,
      title: t('Personalization.addRecipientModal.listOrSegment'),
      description: t('Personalization.addRecipientModal.listOrSegment.description'),
      name: RecipientModals.LIST_PICKER,
      disabled: disableLists,
      disabledSvgName: disableLists ? SvgNames.contactBook : undefined,
      disabledTooltip: disableLists ? t('Personalization.addRecipientModal.listOrSegment.disabledTooltip') : undefined,
      baseSvgFill: SvgColor.BACKGROUND_GRAY,
    },
    {
      iconUnselected: SvgNames.userChecked,
      iconSelected: SvgNames.userChecked,
      name: RecipientModals.ADD_LIST_CONTACTS,
      title: t('Personalization.addRecipientModal.specificContact'),
      description: t('Personalization.addRecipientModal.specificContact.description'),
      disabled: disableIndividuals,
      disabledSvgName: disableIndividuals ? SvgNames.userChecked : undefined,
      disabledTooltip: disableIndividuals ? t('Personalization.addRecipientModal.listOrSegment.disabledTooltip') : undefined,
      baseSvgFill: SvgColor.BACKGROUND_GRAY,
    },
  ]
}

export const keyMappingToSrcId = <T,>(propName: 'id' | 'cid', data?: T & { srcId?: string; id?: any; cid?: any }[]) => {
  return data?.reduce<Record<string, string | undefined>>((prev, curr) => {
    if (!prev[curr[propName]]) {
      prev[curr[propName]] = curr.srcId
    }
    return prev
  }, {})
}

export const getHierarchy = (lists?: (SendToListResponse | undefined)[]) => {
  let total: (SendToListResponse | undefined)[] = []
  lists?.forEach((list) => {
    if (list) {
      total.push(list)
      if (list?.children && list.children.length > 0) {
        const children = getHierarchy(list.children)
        total = total.concat(children)
      }
    }
  })
  return total
}
