import React, { FC, useCallback, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'

import { renderToString } from 'react-dom/server'

import { IAddOnResponseHTMLExtended } from '@src/pages/EmailComposer/utils/BeeEditor.types'
import { useAccountSettings } from '@utils/account/account.utils'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'

import { getWebinarHTMLFields } from './components/WebinarBlock/utils/webinarBlock.utils'
import { WebinarBlock, WebinarCustomFields } from './components/WebinarBlock/WebinarBlock'
import { ManageWebinarDetailsModal } from './ManageWebinarDetailsModal'
import {
  ExcludeTypes,
  ManageWebinarDetailsModalForm,
  ManageWebinarDetailsModalTabs,
  getWebinarDefaultValues,
} from './utils/ManageWebinarDetailsModal.utils'

interface ManageWebinarDetailsModalContainerProps {
  onClose: () => void
  onAction: (data: IAddOnResponseHTMLExtended) => void
  customFieldValues: WebinarCustomFields
}

const rootClass = 'manage-webinar-details-modal-container'

export const ManageWebinarDetailsModalContainer: FC<ManageWebinarDetailsModalContainerProps> = ({ customFieldValues, onClose, onAction }) => {
  const {
    values: {
      message: { id, templateJson, webinarConfig },
    },
  } = useContext(EmailComposerContext)
  const hasRegistration = customFieldValues?.hasRegistration
  const hasForm = customFieldValues?.hasForm

  const { accountId, accountTimeZoneId } = useAccountSettings()
  const [activeTab, setActiveTab] = useState(
    customFieldValues.activeTab || (hasRegistration ? ManageWebinarDetailsModalTabs.REGISTRATION : undefined)
  )

  const [fieldShouldBeeHidden, setFieldShouldBeeHidden] = useState(customFieldValues.hasRegistration)

  const form = useForm<ManageWebinarDetailsModalForm>({
    mode: 'onChange',
    defaultValues: getWebinarDefaultValues({ ...customFieldValues, activeTab }, fieldShouldBeeHidden, accountTimeZoneId, webinarConfig),
  })

  const { customFields: jsonCustomFields } = getWebinarHTMLFields(templateJson, accountId, id, true, webinarConfig)
  const handleInsert = useCallback(
    (customFieldValues: Omit<WebinarCustomFields, ExcludeTypes>, webinarId: string) => {
      // for those cases when template doesn't have multiple webinarBlocks
      const jsonFieldsToSpread = jsonCustomFields.length == 1 ? jsonCustomFields[0] : jsonCustomFields.find((field) => field.webinarId == webinarId)
      const fields = {
        ...jsonFieldsToSpread,
        ...customFieldValues,
        activeTab,
      }

      const webinarHtml = renderToString(
        <WebinarBlock messageId={id} accountId={accountId} customFields={fields as WebinarCustomFields} webinarConfig={webinarConfig} />
      )

      onAction({
        type: 'html',
        value: {
          html: webinarHtml,
          customFields: fields,
        },
      })

      setFieldShouldBeeHidden(false)
    },
    [accountId, activeTab, id, jsonCustomFields, onAction, webinarConfig]
  )

  return (
    <div className={rootClass}>
      <ManageWebinarDetailsModal
        isOpen
        form={form}
        hasForm={hasForm}
        activeTab={activeTab}
        webinarConfig={webinarConfig}
        hasRegistration={hasRegistration}
        onCancel={onClose}
        onSave={handleInsert}
        setActiveTab={setActiveTab}
        defaultTimeZoneValue={customFieldValues.defaultWebinarInfo.timeZone.value}
      />
    </div>
  )
}
