import { useContext, useMemo } from 'react'

import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'

export const useValidationSelectionsCount = () => {
  const {
    values: {
      validations: { validationSectionsStatus },
    },
  } = useContext(EmailComposerContext)

  return useMemo(
    () =>
      Object.values(validationSectionsStatus).reduce(
        (acc, { errors = 0, warnings = 0 }) => {
          acc.errors = (acc.errors ?? 0) + errors
          acc.warnings = (acc.warnings ?? 0) + warnings
          return acc
        },
        { errors: 0, warnings: 0 }
      ),
    [validationSectionsStatus]
  )
}
