import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/Button'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import windowUtils from '@utils/window'

import './pageError.css'

const rootClass = 'page-error'

interface PageErrorProps {
  center?: boolean
  vertical?: boolean
  message?: string
  canCancel?: boolean
  onCancel?(): void
  dataTest?: string
}

export const PageError: FC<PageErrorProps> = (props: PageErrorProps) => {
  const { dataTest = 'page-error', center, vertical, canCancel, onCancel, message = 'an unexpected error has occurred.' } = props
  const { t } = useTranslation()

  return (
    <div
      data-test={dataTest}
      className={classNames(rootClass, {
        [`${rootClass}--center`]: center,
        [`${rootClass}--vertical`]: vertical,
      })}
    >
      <div className={`${rootClass}__wrapper`}>
        <div className={`${rootClass}__icon-wrapper`}>
          <Svg name={SvgNames.error} type={SvgType.VERY_LARGE_ICON} />
        </div>
        <div className={`${rootClass}__text`}>
          <Typography text={t('Oops')} type={TextType.PAGE_HEADER} />
          <Typography text={t(message)} type={TextType.BODY_TEXT_LIGHT} />
        </div>
      </div>
      {canCancel && (
        <Button
          dataTest={`${dataTest}-go-back-button`}
          buttonType={ButtonType.PRIMARY}
          onClick={() => {
            if (onCancel) {
              onCancel()
            } else {
              windowUtils.location().reload()
            }
          }}
        >
          {t('Go Back')}
        </Button>
      )}
    </div>
  )
}

export default PageError
