import React, { FC, ReactNode, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Svg, { SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { Side } from '@radix-ui/react-popper'

import SvgNames from '../Svg/SvgNames'

import './ClipboardCopy.css'

export enum CopyTextSize {
  REGULAR = 'REGULAR',
  SMALL = 'SMALL',
  LARGE = 'LARGE',
}

type Props = {
  value: string
  tooltipText?: string
  tooltipDuration?: number
  tooltipPosition?: Side
  hideTooltip?: boolean
  className?: string
  text?: string | ReactNode
  textType?: TextType
  textWeight?: TextWeight
  link?: string
  size?: CopyTextSize
  iconName?: SvgNames
  hideIcon?: boolean
  lineHeight?: LineHeight
  copyClicked?: (event: React.MouseEvent<HTMLButtonElement>) => any
  dataTest?: string
}

const rootClass = 'clipboard-copy'

export const ClipboardCopy: FC<Props> = (props: Props) => {
  const {
    tooltipText = 'Copied',
    tooltipDuration = 1500,
    tooltipPosition = 'top',
    text = 'Copy to clipboard',
    textType,
    textWeight = TextWeight.MEDIUM,
    hideTooltip = false,
    className = '',
    value,
    size = CopyTextSize.LARGE,
    iconName = SvgNames.clipboardCheck,
    hideIcon,
    lineHeight,
    copyClicked,
    dataTest = rootClass,
  } = props

  const [clicked, setClicked] = useState(false)

  const copyCode = () => {
    navigator.clipboard.writeText(value)
  }

  const clipboardCopyTrigger = (
    <div className={classNames(rootClass, className)}>
      <Button
        buttonType={ButtonType.TEXT}
        data-test={dataTest}
        className={`${rootClass}__button`}
        onClick={(event) => {
          event.preventDefault()
          setClicked(true)
          copyClicked && copyClicked(event)
          copyCode()
          setTimeout(() => {
            setClicked(false)
          }, tooltipDuration)
        }}
      >
        {
          <Typography
            text={text}
            type={
              textType || (size === CopyTextSize.REGULAR ? TextType.LINK_SMALL : size === CopyTextSize.LARGE ? TextType.LINK : TextType.LINK_TINY)
            }
            weight={textWeight}
            lineHeight={lineHeight}
            inline
            className={`${rootClass}__trigger-text`}
          />
        }
      </Button>
      {!hideIcon && clicked && (
        <span className={`${rootClass}__svg`}>
          <Svg name={iconName} type={size === CopyTextSize.LARGE ? SvgType.MEDIUM : SvgType.SMALLER_ICON} />
        </span>
      )}
    </div>
  )

  return (
    <Tooltip
      trigger={clipboardCopyTrigger}
      triggerClassName={`${className}__trigger`}
      openOnClick
      autoCloseDuration={tooltipDuration}
      position={tooltipPosition}
      hide={hideTooltip}
      align="center"
    >
      {tooltipText}
    </Tooltip>
  )
}

export default ClipboardCopy
