import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import classNames from 'classnames'

import BackButton from '@components/BackButton/BackButton'
import Button, { ButtonType } from '@components/Button'
import { InputProps } from '@components/Input/Input'
import InputWithClear from '@components/InputWithClear/InputWithClear'
import NestedDropdownEmptyListing from '@components/NestedDropDown/components/NestedDropdownEmptyListing/NestedDropdownEmptyListing'
import { findSelectedOption, findSelectedOptionPath, searchNestedOptions } from '@components/NestedDropDown/utils/nestedDropDown'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import { NestedDropDownPortal } from './NestedDropDownPortal/NestedDropDownPortal'

import './nestedDropDown.css'

interface Props {
  title?: string
  placeholder?: string
  placeholderIcon?: SvgNames
  placeholderIconSelected?: SvgNames
  hideIcon?: boolean
  defaultSelected?: string
  options: SelectOption[]
  onItemSelected?: (option: SelectOption) => any
  allOptions?: SelectOption[]
  className?: string
  dataTest?: string
  withFooter?: boolean
  withTitle?: boolean
  asChild?: boolean
  footerPrimaryButtonText?: string
  footerSecondaryButtonText?: string
  onSubmit: (selected: string | undefined) => void
  isRequired?: boolean
}

interface State {
  searchQuery: string
  selectedOption: SelectOption | undefined
  value: SelectOption | undefined
  isActive: boolean
  nested: SelectOption[]
  tempOptions: SelectOption[]
  previousOptions: SelectOption[][]
  hideSearch: boolean
  rootSearchOn: boolean
  optionScrollY: number
  pageHeight: number
  isCompactView: boolean
}

export interface SelectOption {
  name: string
  mapping: string
  filtered: boolean
  icon?: SvgNames
  selectedIcon?: SvgNames
  nestedOptions?: SelectOption[]
  disabled?: boolean
}

const rootClass = 'nested-dropdown'
const MAX_SCREEN_SIZE = 705

const NestedDropDown: FC<Props> = (props: Props) => {
  const {
    onSubmit,
    title = 'Select',
    options = [],
    onItemSelected,
    placeholder = 'Select a folder',
    placeholderIcon = SvgNames.folder,
    placeholderIconSelected = SvgNames.folderNestedGray,
    hideIcon = false,
    defaultSelected = '',
    withFooter,
    withTitle,
    footerPrimaryButtonText = 'Save',
    footerSecondaryButtonText = 'Cancel',
    allOptions,
    asChild,
    dataTest = rootClass,
    className = '',
    isRequired = true,
  } = props

  const [state, setState] = useState<State>({
    searchQuery: '',
    selectedOption: findSelectedOption(allOptions ?? options, defaultSelected),
    value: findSelectedOption(allOptions ?? options, defaultSelected),
    isActive: false,
    nested: [],
    tempOptions: options.map((option) => ({ ...option, filtered: false })),
    previousOptions: [],
    hideSearch: false,
    rootSearchOn: false,
    optionScrollY: 0,
    pageHeight: 0,
    isCompactView: false,
  })
  const inputRef = useRef<HTMLInputElement>(null)
  const {
    searchQuery,
    selectedOption,
    value,
    isActive,
    nested,
    tempOptions,
    previousOptions,
    hideSearch,
    rootSearchOn,
    optionScrollY,
    pageHeight,
    isCompactView,
  } = state

  const isRootSearch = useMemo<boolean>(() => rootSearchOn || !nested.length, [nested, rootSearchOn])

  const rootSearchOptions = useMemo<SelectOption[]>(
    () => (isRootSearch && searchQuery ? searchNestedOptions(options, searchQuery) : []),
    [searchQuery, isRootSearch, options]
  )

  const noOptionAvailable = useMemo<boolean>(
    () => !rootSearchOptions.length && !tempOptions.some((option) => !option.filtered),
    [tempOptions, rootSearchOptions]
  )

  const optionsToRender = useMemo<SelectOption[]>(
    () => (rootSearchOptions.length ? rootSearchOptions : tempOptions.filter((option) => !option.filtered)),
    [rootSearchOptions, tempOptions]
  )

  const disablePrimaryButton = useMemo<boolean>(
    () => isRequired && (!selectedOption || (!!searchQuery && optionsToRender.every(({ mapping }) => mapping !== selectedOption.mapping))),
    [isRequired, selectedOption, searchQuery, optionsToRender]
  )

  const ref = useRef<HTMLDivElement>(null)
  const optionsRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()

  const escapeListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeDropDown()
        updateState({ selectedOption: value })
      }
    },
    [value]
  )

  useEffect(() => {
    isActive && inputRef.current?.focus()
  }, [isActive])

  const clickListener = useCallback(
    (e: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(e.target as Node) &&
        (e.target as Node).nodeName !== 'svg' &&
        (e.target as Node).nodeName !== 'use' &&
        !(e.target as Element)?.classList?.contains('empty-listing__button')
      ) {
        closeDropDown()
        updateState({ selectedOption: value })
      }
    },
    [ref.current, value]
  )

  useEffect(() => {
    window.addEventListener('click', clickListener)
    window.addEventListener('keyup', escapeListener)
    return () => {
      window.removeEventListener('keyup', escapeListener)
      window.removeEventListener('click', clickListener)
    }
  }, [options])

  const onEmptyListingButtonClick = useCallback(() => {
    if (isRootSearch) {
      // Just Clear search
      clearSearch()
    } else {
      // Search all folders
      updateState({ rootSearchOn: true })
    }
  }, [isRootSearch, options])

  useEffect(() => {
    if (isActive && value) {
      const pathToValue = findSelectedOptionPath(options, value.mapping)
      if (pathToValue?.length) {
        const nested = [...pathToValue]
        const currentValue = pathToValue.pop()
        const tempOptions = currentValue?.nestedOptions ?? (pathToValue.length ? pathToValue[pathToValue.length - 1]?.nestedOptions ?? [] : options)
        const previousOptions = [options, ...pathToValue.map((option) => option.nestedOptions ?? [])]
        if (!currentValue?.nestedOptions) {
          previousOptions.pop()
          nested.pop()
        }
        updateState({ nested, tempOptions, previousOptions })
      }
    }
  }, [isActive, value, options])

  const updateState = (updatedState: Partial<State>) => {
    setState((state) => ({ ...state, ...updatedState }))
  }

  useEffect(() => {
    const updatePageHeight = () => updateState({ pageHeight: window.innerHeight })
    window.addEventListener('resize', updatePageHeight)
    updatePageHeight()
    updateState({ isCompactView: window.innerHeight < MAX_SCREEN_SIZE })
    return () => {
      window.removeEventListener('resize', updatePageHeight)
    }
  }, [isActive, pageHeight])

  const searchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = e.target.value

    const filteredOptions = tempOptions.map((option) => ({
      ...option,
      filtered: !option.name.toLowerCase().includes(searchQuery.toLowerCase()),
    }))

    updateState({
      searchQuery,
      tempOptions: filteredOptions,
    })
  }

  const activateSelect = () => {
    updateState({ isActive: true })
  }

  const clearSearch = () => {
    updateState({
      searchQuery: '',
      tempOptions: tempOptions.map((option) => ({ ...option, filtered: false })),
    })
  }

  const closeDropDown = () => {
    const initialOptions = options.map((option) => ({ ...option, filtered: false }))
    updateState({
      isActive: false,
      tempOptions: initialOptions,
      previousOptions: [initialOptions],
      nested: [],
      optionScrollY: 0,
      searchQuery: '',
      rootSearchOn: false,
      pageHeight: 0,
    })
  }

  const submitSelected = (option: SelectOption | undefined) => {
    updateState({
      selectedOption: option,
      value: option,
    })
    onSubmit(option?.mapping)
    closeDropDown()
  }

  const onSelect = (option: SelectOption) => {
    if (option.nestedOptions?.length) {
      // Navigate to option
      updateState({
        searchQuery: '',
        tempOptions: option.nestedOptions.map((option) => ({ ...option, filtered: false })) ?? [],
        previousOptions: [...previousOptions, tempOptions],
        nested: [...nested, option],
        selectedOption: option,
        rootSearchOn: false,
      })
      return
    }

    if (selectedOption?.mapping === option.mapping) {
      // UnSelect option
      updateState({ selectedOption: nested[nested.length - 1] })
      return
    }

    updateState({ selectedOption: option })

    if (onItemSelected) {
      onItemSelected(option)
      submitSelected(option)
    }
  }

  const onScroll = () => {
    if (optionsRef.current) {
      const { scrollTop } = optionsRef.current

      if (optionScrollY < scrollTop && !hideSearch) {
        updateState({ hideSearch: true })
      }

      if (optionScrollY > scrollTop && hideSearch) {
        updateState({ hideSearch: false })
      }

      updateState({ optionScrollY: scrollTop })
    }
  }

  const returnPreviousOptions = () => {
    nested.pop()
    updateState({
      searchQuery: '',
      tempOptions: previousOptions[previousOptions.length - 1].map((option) => ({ ...option, filtered: false })),
      previousOptions: previousOptions.slice(0, -1),
      nested: nested,
      selectedOption: nested[nested.length - 1],
    })
  }

  const searchPlaceholder = t('Search') + ' ' + (nested?.length ? nested[nested.length - 1].name : t('folders'))
  const inputProps: InputProps = {
    value: searchQuery,
    className: classNames(`${rootClass}__search`),
    placeholder: searchPlaceholder,
    icon: SvgNames.search,
    dataTest: `${dataTest}-search`,
    inputAriaLabel: 'searchInput',
    register: inputRef,
    onChange: searchChange,
  }

  const renderWithTitle = () => {
    const text = selectedOption?.name ?? title
    const textTypography = <Typography text={text} type={TextType.SECTION_HEADER} />
    return (
      <div className={`${rootClass}__dropdown-content-title`}>
        {nested?.length > 0 && (
          <BackButton
            className={`${rootClass}__dropdown-content-title-back-button`}
            dataTest={`${dataTest}-chevron-back`}
            onClick={(e) => {
              e.preventDefault()
              returnPreviousOptions()
            }}
          />
        )}
        {!selectedOption?.name ? (
          textTypography
        ) : (
          <Tooltip triggerClassName={`${rootClass}__tooltip__trigger-ellipsis`} trigger={textTypography} position={'bottom'}>
            {text}
          </Tooltip>
        )}
      </div>
    )
  }

  const renderOptions = () => {
    return (
      <div
        className={classNames(`${rootClass}__options`, {
          [`${rootClass}__options--scrolled`]: hideSearch,
        })}
        ref={optionsRef}
        onScroll={onScroll}
      >
        {optionsToRender.map((option) => {
          const disabled = !!option.disabled
          const isSelected = option.mapping === selectedOption?.mapping
          return (
            <div
              role={'option'}
              aria-selected={isSelected}
              tabIndex={0}
              key={option.mapping}
              data-test={`${dataTest}-${option.mapping}`}
              className={classNames(`${rootClass}__options-item`, {
                [`${rootClass}__options-item--disabled`]: disabled,
                [`${rootClass}__options-item--selected`]: isSelected,
              })}
              onKeyDown={(keyDownEvent) => {
                if (disabled) {
                  return undefined
                } else if (keyDownEvent.key === ' ') {
                  keyDownEvent.stopPropagation()
                  onSelect(option)
                }
              }}
              onClick={
                disabled
                  ? undefined
                  : (e) => {
                      e.stopPropagation()
                      onSelect(option)
                    }
              }
            >
              <div className={`${rootClass}__options-item-value`}>
                {option?.icon && option?.selectedIcon && (
                  <Svg
                    name={isSelected ? option.selectedIcon : option.icon}
                    type={SvgType.LARGER_ICON}
                    className={`${rootClass}__options-item-value-icon`}
                  />
                )}
                <Tooltip
                  triggerClassName={`${rootClass}__tooltip__trigger-ellipsis`}
                  trigger={<Typography text={option.name} lineHeight={LineHeight.MEDIUM_LARGE} />}
                  position={'bottom'}
                >
                  {disabled ? t('NESTED_DROPDOWN_OPTION_DISABLED_TOOLTIP_MSG', { option: option.name }) : option.name}
                </Tooltip>
              </div>
              {option.nestedOptions?.length && (
                <Svg name={SvgNames.chevronRight} type={SvgType.MEDIUM} className={`${rootClass}__options-item-nested-icon`} />
              )}
            </div>
          )
        })}
      </div>
    )
  }

  const renderFooter = () => {
    return (
      <div className={classNames(`${rootClass}__footer-wrapper`)}>
        <Button
          buttonType={ButtonType.TERTIARY}
          dataTest={`${rootClass}__close`}
          onClick={() => {
            closeDropDown()
            updateState({ selectedOption: value })
          }}
        >
          {footerSecondaryButtonText}
        </Button>
        <Button
          buttonType={ButtonType.PRIMARY}
          dataTest={`${rootClass}__submit`}
          disabled={disablePrimaryButton}
          onClick={() => {
            if ((isRequired && selectedOption) || !isRequired) {
              submitSelected(selectedOption)
            }
          }}
        >
          {footerPrimaryButtonText}
        </Button>
      </div>
    )
  }

  const elementRef = useRef<HTMLDivElement>(null)

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest} ref={ref}>
      <div className={`${rootClass}__wrapper`} ref={elementRef}>
        <div
          role={'listbox'}
          tabIndex={0}
          className={classNames(`${rootClass}__faux-select`, {
            [`${rootClass}__faux-select--active`]: isActive,
          })}
          data-test={`${dataTest}-select`}
          onKeyDown={(keyDownEvent) => {
            if (keyDownEvent.key === ' ' || keyDownEvent.key === 'Enter' || keyDownEvent.key === 'ArrowDown') {
              activateSelect()
            }
          }}
          onClick={activateSelect}
        >
          <div className={classNames(`${rootClass}__faux-select-placeholder`, 'ellip')} data-test={`${dataTest}-faux-select-placeholder`}>
            <div className={`${rootClass}__faux-select-flex`}>
              {placeholderIcon && !hideIcon && <Svg name={!!value?.name ? placeholderIconSelected : placeholderIcon} type={SvgType.LARGER_ICON} />}
              <Typography text={value?.name || t(placeholder)} inline className={'ellip'} />
            </div>
          </div>
        </div>
        <NestedDropDownPortal
          parentRef={asChild ? ref : undefined}
          elementRef={elementRef}
          isActive={isActive}
          close={closeDropDown}
          rootClass={rootClass}
        >
          <div
            className={classNames(`${rootClass}__dropdown-wrapper`, {
              [`${rootClass}__dropdown-wrapper--active`]: isActive,
              [`${rootClass}__dropdown-wrapper--is-compact`]: isCompactView,
            })}
          >
            <div
              className={classNames(`${rootClass}__search-wrapper`, {
                [`${rootClass}__search-wrapper--hidden-search`]: hideSearch,
              })}
            >
              {withTitle && renderWithTitle()}
              {!hideSearch && <InputWithClear inputProps={inputProps} onClear={clearSearch} />}
            </div>
            <div className={`${rootClass}__dropdown-container`}>
              <div className={`${rootClass}__options-wrapper`}>
                {searchQuery && noOptionAvailable && (
                  <NestedDropdownEmptyListing
                    isRootSearch={isRootSearch}
                    onClick={onEmptyListingButtonClick}
                    dataTest={`${dataTest}-empty-listing`}
                  />
                )}
                {!noOptionAvailable && renderOptions()}
              </div>
            </div>
            {withFooter && renderFooter()}
          </div>
        </NestedDropDownPortal>
      </div>
    </div>
  )
}

export default NestedDropDown
