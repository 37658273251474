import { gql } from '@apollo/client'

export default gql`
  query validateEmailMessage($messageId: String) {
    validateEmailMessage(messageId: $messageId) {
      contentErrors
      hasPhysicalAddressInHtml
      hasPhysicalAddressInText
      hasOptOutLinkInHtml
      hasOptOutLinkInText
      hasCompanyInformation
      hasAccountCompany
      hasBizAddress
      hasAccountCompanyInText
      hasAccountCompanyInHtml
      mobileAndDesktopValidation {
        hasDesktopOptOutLink
        hasDesktopCompanyName
        hasDesktopPhysicalAddressInHtml
        hasMobileOptOutLink
        hasMobileCompanyName
        hasMobilePhysicalAddressInHtml
      }
      status
      signatureWarning
      linkDomainsBlockListedBySpamhaus
      dynamicContentValidationResponse {
        hasAddress
        hasOptOut
        hasCompanyName
        dynamicPersonalizationFieldErrors {
          field
          isMissingForAll
          isCrmField
          isInvalidFormat
          lists
          hasFallbackText
        }
      }
      dynamicPlainTextValidationResponse {
        hasAddress
        hasOptOut
        hasCompanyName
        dynamicPersonalizationFieldErrors {
          field
          isMissingForAll
          isCrmField
          isInvalidFormat
          lists
          hasFallbackText
        }
      }
    }
  }
`
