import React, { FC } from 'react'
import { Row } from 'react-table'

import classNames from 'classnames'

import './SpacerBlock.css'

interface Props {
  rows: Row[]
  depth: number
  parentIndex: number
  spacerIndex: number
  className?: string
  dataTest?: string
}

const FIRST_LEVEL = 1
export const MAX_NESTING = 6

const rootClass = 'spacer-block'

const SpacerBlock: FC<Props> = (props: Props) => {
  const { spacerIndex, parentIndex, depth, rows } = props

  const nextIndex = parentIndex + 1
  const prevIndex = parentIndex - 1
  // @ts-ignore
  const nextRowDifference = nextIndex < rows.length ? depth - rows[nextIndex].original.depth : -1
  const nextRowLimit = depth - nextRowDifference
  // @ts-ignore
  const previousRowDifference = prevIndex > -1 ? depth - rows[prevIndex].original.depth : -1
  const prevRowLimit = depth - previousRowDifference

  const renderHalfBottomBorder = nextRowDifference > 0 && nextRowLimit < spacerIndex
  const renderHalfTopBorder = previousRowDifference > 0 && prevRowLimit < spacerIndex
  const className =
    spacerIndex === 1 ? `${rootClass}-small` : depth === spacerIndex || spacerIndex === MAX_NESTING ? `${rootClass}` : `${rootClass}-large`
  return (
    <div
      className={classNames(className, {
        [`${rootClass}-border`]: !renderHalfBottomBorder && !renderHalfTopBorder && spacerIndex !== FIRST_LEVEL,
      })}
      data-test="spacer-block"
    >
      {renderHalfBottomBorder && <div className={`${rootClass}-half-bottom`} />}
      {renderHalfTopBorder && <div className={`${rootClass}-half-top`} />}
    </div>
  )
}

export default SpacerBlock
