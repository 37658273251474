import { SvgNames } from '@components/Svg'
import { RowAction } from '@components/Table/Table'
import { UserResponse } from '@graphql/types/query-types'

type Props = {
  t: (s: any) => any
  onEdit: (user: UserResponse) => void
  onResend: (user: UserResponse) => void
  onDelete: (user: UserResponse) => void
  onEditLaunch: (user: UserResponse) => void
  handleUpdateCRM: (id: string) => void
  enableLaunch: boolean
}

const getRowActions: (props: Props) => RowAction[] = ({ t, onEdit, onResend, onDelete, onEditLaunch, handleUpdateCRM, enableLaunch }) => [
  {
    label: t('Edit user profile'),
    icon: SvgNames.pencil,
    onClick: (row) => onEdit(row.original),
  },
  {
    label: t('Edit launch approval'),
    icon: SvgNames.rocket,
    onClick: (row) => onEditLaunch(row.original),
    hidden: () => !enableLaunch,
  },
  {
    label: t('Update from CRM'),
    icon: SvgNames.optimize,
    onClick: (row) => handleUpdateCRM(row.original.id),
    hidden: (row) => !row.original.isCRM,
    // If Account is enable To Launch, then 'Edit Launch' icon will show, and Update will go to Dropdown
    inDropdown: enableLaunch,
  },
  {
    label: t('Resend verification email'),
    icon: SvgNames.letter,
    onClick: (row) => onResend(row.original),
    hidden: (row) => row.original.isCRM || row.original.isVerified,
    // If Account is enable To Launch, then 'Edit Launch' icon will show, and Resend will go to Dropdown
    inDropdown: enableLaunch,
  },
  {
    label: t('Delete user'),
    icon: SvgNames.delete,
    onClick: (row) => onDelete(row.original),
    inDropdown: true,
  },
]

export default getRowActions
