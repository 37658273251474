import { gql } from '@apollo/client'

export default gql`
  query auditList($pageNumber: Int, $pageSize: Int, $externalId: String, $type: String) {
    auditList(pageNumber: $pageNumber, pageSize: $pageSize, externalId: $externalId, type: $type) {
      accountId
      auditType
      createdTime
      description
      enabled
      externalId
      id
      updatedTime
      userExternalId
      userId
      username
    }
  }
`
