import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'

export const Locales: SelectV2SingleOption[] = [
  { label: 'English (United States): en-US', value: 'en-US' },
  { label: 'Afrikaans (South Africa): af-ZA', value: 'af-ZA' },
  { label: 'Afrikaans (Namibia): af-NA', value: 'af-NA' },
  { label: 'Afrikaans: af', value: 'af' },
  { label: 'Aghem (Cameroon): agq-CM', value: 'agq-CM' },
  { label: 'Aghem: agq', value: 'agq' },
  { label: 'Akan (Ghana): ak-GH', value: 'ak-GH' },
  { label: 'Akan: ak', value: 'ak' },
  { label: 'Albanian (Albania): sq-AL', value: 'sq-AL' },
  { label: 'Albanian (Kosovo): sq-XK', value: 'sq-XK' },
  { label: 'Albanian (North Macedonia): sq-MK', value: 'sq-MK' },
  { label: 'Albanian: sq', value: 'sq' },
  { label: 'Amharic (Ethiopia): am-ET', value: 'am-ET' },
  { label: 'Amharic: am', value: 'am' },
  { label: 'Anii (Benin): blo-BJ', value: 'blo-BJ' },
  { label: 'Anii: blo', value: 'blo' },
  { label: 'Arabic (Algeria): ar-DZ', value: 'ar-DZ' },
  { label: 'Arabic (Arabic, Egypt): ar-EG', value: 'ar-EG' },
  { label: 'Arabic (Bahrain): ar-BH', value: 'ar-BH' },
  { label: 'Arabic (Chad): ar-TD', value: 'ar-TD' },
  { label: 'Arabic (Comoros): ar-KM', value: 'ar-KM' },
  { label: 'Arabic (Djibouti): ar-DJ', value: 'ar-DJ' },
  { label: 'Arabic (Eritrea): ar-ER', value: 'ar-ER' },
  { label: 'Arabic (Iraq): ar-IQ', value: 'ar-IQ' },
  { label: 'Arabic (Israel): ar-IL', value: 'ar-IL' },
  { label: 'Arabic (Jordan): ar-JO', value: 'ar-JO' },
  { label: 'Arabic (Kuwait): ar-KW', value: 'ar-KW' },
  { label: 'Arabic (Lebanon): ar-LB', value: 'ar-LB' },
  { label: 'Arabic (Libya): ar-LY', value: 'ar-LY' },
  { label: 'Arabic (Mauritania): ar-MR', value: 'ar-MR' },
  { label: 'Arabic (Morocco): ar-MA', value: 'ar-MA' },
  { label: 'Arabic (Oman): ar-OM', value: 'ar-OM' },
  { label: 'Arabic (Palestinian Territories): ar-PS', value: 'ar-PS' },
  { label: 'Arabic (Qatar): ar-QA', value: 'ar-QA' },
  { label: 'Arabic (Saudi Arabia): ar-SA', value: 'ar-SA' },
  { label: 'Arabic (Somalia): ar-SO', value: 'ar-SO' },
  { label: 'Arabic (South Sudan): ar-SS', value: 'ar-SS' },
  { label: 'Arabic (Sudan): ar-SD', value: 'ar-SD' },
  { label: 'Arabic (Syria): ar-SY', value: 'ar-SY' },
  { label: 'Arabic (Tunisia): ar-TN', value: 'ar-TN' },
  { label: 'Arabic (United Arab Emirates): ar-AE', value: 'ar-AE' },
  { label: 'Arabic (Western Sahara): ar-EH', value: 'ar-EH' },
  { label: 'Arabic (world): ar-001', value: 'ar-001' },
  { label: 'Arabic (Yemen): ar-YE', value: 'ar-YE' },
  { label: 'Arabic: ar', value: 'ar' },
  { label: 'Armenian (Armenia): hy-AM', value: 'hy-AM' },
  { label: 'Armenian: hy', value: 'hy' },
  { label: 'Assamese (Bangla, India): as-IN', value: 'as-IN' },
  { label: 'Assamese: as', value: 'as' },
  { label: 'Asturian (Spain): ast-ES', value: 'ast-ES' },
  { label: 'Asturian: ast', value: 'ast' },
  { label: 'Asu (Tanzania): asa-TZ', value: 'asa-TZ' },
  { label: 'Asu: asa', value: 'asa' },
  { label: 'Azerbaijani (Azerbaijan): az-AZ', value: 'az-AZ' },
  { label: 'Azerbaijani (Cyrillic): az', value: 'az' },
  { label: 'Bafia (Cameroon): ksf-CM', value: 'ksf-CM' },
  { label: 'Bafia: ksf', value: 'ksf' },
  { label: 'Bambara (Mali): bm-ML', value: 'bm-ML' },
  { label: 'Bambara: bm', value: 'bm' },
  { label: 'Bangla (Bangla, Bangladesh): bn-BD', value: 'bn-BD' },
  { label: 'Bangla (India): bn-IN', value: 'bn-IN' },
  { label: 'Bangla: bn', value: 'bn' },
  { label: 'Basaa (Cameroon): bas-CM', value: 'bas-CM' },
  { label: 'Basaa: bas', value: 'bas' },
  { label: 'Basque (Spain): eu-ES', value: 'eu-ES' },
  { label: 'Basque: eu', value: 'eu' },
  { label: 'Belarusian (Belarus): be-BY', value: 'be-BY' },
  { label: 'Belarusian (Taraskievica orthography): be', value: 'be' },
  { label: 'Bemba (Zambia): bem-ZM', value: 'bem-ZM' },
  { label: 'Bemba: bem', value: 'bem' },
  { label: 'Bena (Tanzania): bez-TZ', value: 'bez-TZ' },
  { label: 'Bena: bez', value: 'bez' },
  { label: 'Bhojpuri (Devanagari, India): bho-IN', value: 'bho-IN' },
  { label: 'Bhojpuri: bho', value: 'bho' },
  { label: 'Bodo (Devanagari, India): brx-IN', value: 'brx-IN' },
  { label: 'Bodo: brx', value: 'brx' },
  { label: 'Bosnian (Bosnia & Herzegovina): bs-BA', value: 'bs-BA' },
  { label: 'Bosnian (Cyrillic): bs', value: 'bs' },
  { label: 'Breton (France): br-FR', value: 'br-FR' },
  { label: 'Breton: br', value: 'br' },
  { label: 'Bulgarian (Bulgaria): bg-BG', value: 'bg-BG' },
  { label: 'Bulgarian: bg', value: 'bg' },
  { label: 'Burmese (Myanmar (Burma)): my-MM', value: 'my-MM' },
  { label: 'Burmese: my', value: 'my' },
  { label: 'Cantonese (China): yue-CN', value: 'yue-CN' },
  { label: 'Cantonese (Hong Kong SAR China): yue-HK', value: 'yue-HK' },
  { label: 'Cantonese (Simplified): yue', value: 'yue' },
  { label: 'Catalan (Andorra): ca-AD', value: 'ca-AD' },
  { label: 'Catalan (France): ca-FR', value: 'ca-FR' },
  { label: 'Catalan (Italy): ca-IT', value: 'ca-IT' },
  { label: 'Catalan (Spain): ca-ES', value: 'ca-ES' },
  { label: 'Catalan: ca', value: 'ca' },
  { label: 'Cebuano (Philippines): ceb-PH', value: 'ceb-PH' },
  { label: 'Cebuano: ceb', value: 'ceb' },
  {
    label: 'Central Atlas Tamazight (Morocco): tzm-MA',
    value: 'tzm-MA',
  },
  { label: 'Central Atlas Tamazight: tzm', value: 'tzm' },
  { label: 'Central Kurdish (Arabic, Iraq): ckb-IQ', value: 'ckb-IQ' },
  { label: 'Central Kurdish (Iran): ckb-IR', value: 'ckb-IR' },
  { label: 'Central Kurdish: ckb', value: 'ckb' },
  { label: 'Chakma (Bangladesh): ccp-BD', value: 'ccp-BD' },
  { label: 'Chakma (India): ccp-IN', value: 'ccp-IN' },
  { label: 'Chakma: ccp', value: 'ccp' },
  { label: 'Chechen (Cyrillic, Russia): ce-RU', value: 'ce-RU' },
  { label: 'Chechen: ce', value: 'ce' },
  {
    label: 'Cherokee (Cherokee, United States): chr-US',
    value: 'chr-US',
  },
  { label: 'Cherokee: chr', value: 'chr' },
  { label: 'Chiga (Uganda): cgg-UG', value: 'cgg-UG' },
  { label: 'Chiga: cgg', value: 'cgg' },
  { label: 'Chinese (China): zh-CN', value: 'zh-CN' },
  { label: 'Chinese (Hong Kong SAR China): zh-HK', value: 'zh-HK' },
  { label: 'Chinese (Macao SAR China): zh-MO', value: 'zh-MO' },
  { label: 'Chinese (Simplified, Singapore): zh-SG', value: 'zh-SG' },
  { label: 'Chinese (Simplified): zh', value: 'zh' },
  { label: 'Chinese (Taiwan): zh-TW', value: 'zh-TW' },
  { label: 'Chuvash (Cyrillic, Russia): cv-RU', value: 'cv-RU' },
  { label: 'Chuvash: cv', value: 'cv' },
  { label: 'Colognian (Germany): ksh-DE', value: 'ksh-DE' },
  { label: 'Colognian: ksh', value: 'ksh' },
  { label: 'Cornish (United Kingdom): kw-GB', value: 'kw-GB' },
  { label: 'Cornish: kw', value: 'kw' },
  { label: 'Croatian (Bosnia & Herzegovina): hr-BA', value: 'hr-BA' },
  { label: 'Croatian (Croatia): hr-HR', value: 'hr-HR' },
  { label: 'Croatian: hr', value: 'hr' },
  { label: 'Czech (Czechia): cs-CZ', value: 'cs-CZ' },
  { label: 'Czech: cs', value: 'cs' },
  { label: 'Danish (Denmark): da-DK', value: 'da-DK' },
  { label: 'Danish (Greenland): da-GL', value: 'da-GL' },
  { label: 'Danish: da', value: 'da' },
  { label: 'Dogri (Devanagari, India): doi-IN', value: 'doi-IN' },
  { label: 'Dogri: doi', value: 'doi' },
  { label: 'Duala (Cameroon): dua-CM', value: 'dua-CM' },
  { label: 'Duala: dua', value: 'dua' },
  { label: 'Dutch (Aruba): nl-AW', value: 'nl-AW' },
  { label: 'Dutch (Belgium): nl-BE', value: 'nl-BE' },
  { label: 'Dutch (Caribbean Netherlands): nl-BQ', value: 'nl-BQ' },
  { label: 'Dutch (Curaçao): nl-CW', value: 'nl-CW' },
  { label: 'Dutch (Netherlands): nl-NL', value: 'nl-NL' },
  { label: 'Dutch (Sint Maarten): nl-SX', value: 'nl-SX' },
  { label: 'Dutch (Suriname): nl-SR', value: 'nl-SR' },
  { label: 'Dutch: nl', value: 'nl' },
  { label: 'Dzongkha (Bhutan): dz-BT', value: 'dz-BT' },
  { label: 'Dzongkha: dz', value: 'dz' },
  { label: 'Embu (Kenya): ebu-KE', value: 'ebu-KE' },
  { label: 'Embu: ebu', value: 'ebu' },
  { label: 'English (American Samoa): en-AS', value: 'en-AS' },
  { label: 'English (Anguilla): en-AI', value: 'en-AI' },
  { label: 'English (Antigua & Barbuda): en-AG', value: 'en-AG' },
  { label: 'English (Australia): en-AU', value: 'en-AU' },
  { label: 'English (Austria): en-AT', value: 'en-AT' },
  { label: 'English (Bahamas): en-BS', value: 'en-BS' },
  { label: 'English (Barbados): en-BB', value: 'en-BB' },
  { label: 'English (Belgium): en-BE', value: 'en-BE' },
  { label: 'English (Belize): en-BZ', value: 'en-BZ' },
  { label: 'English (Bermuda): en-BM', value: 'en-BM' },
  { label: 'English (Botswana): en-BW', value: 'en-BW' },
  {
    label: 'English (British Indian Ocean Territory): en-IO',
    value: 'en-IO',
  },
  { label: 'English (British Virgin Islands): en-VG', value: 'en-VG' },
  { label: 'English (Burundi): en-BI', value: 'en-BI' },
  { label: 'English (Cameroon): en-CM', value: 'en-CM' },
  { label: 'English (Canada): en-CA', value: 'en-CA' },
  { label: 'English (Cayman Islands): en-KY', value: 'en-KY' },
  { label: 'English (Christmas Island): en-CX', value: 'en-CX' },
  { label: 'English (Cocos (Keeling) Islands): en-CC', value: 'en-CC' },
  { label: 'English (Cook Islands): en-CK', value: 'en-CK' },
  { label: 'English (Cyprus): en-CY', value: 'en-CY' },
  { label: 'English (Denmark): en-DK', value: 'en-DK' },
  { label: 'English (Diego Garcia): en-DG', value: 'en-DG' },
  { label: 'English (Dominica): en-DM', value: 'en-DM' },
  { label: 'English (Eritrea): en-ER', value: 'en-ER' },
  { label: 'English (Eswatini): en-SZ', value: 'en-SZ' },
  { label: 'English (Europe): en-150', value: 'en-150' },
  { label: 'English (Falkland Islands): en-FK', value: 'en-FK' },
  { label: 'English (Fiji): en-FJ', value: 'en-FJ' },
  { label: 'English (Finland): en-FI', value: 'en-FI' },
  { label: 'English (Gambia): en-GM', value: 'en-GM' },
  { label: 'English (Germany): en-DE', value: 'en-DE' },
  { label: 'English (Ghana): en-GH', value: 'en-GH' },
  { label: 'English (Gibraltar): en-GI', value: 'en-GI' },
  { label: 'English (Grenada): en-GD', value: 'en-GD' },
  { label: 'English (Guam): en-GU', value: 'en-GU' },
  { label: 'English (Guernsey): en-GG', value: 'en-GG' },
  { label: 'English (Guyana): en-GY', value: 'en-GY' },
  { label: 'English (Hong Kong SAR China): en-HK', value: 'en-HK' },
  { label: 'English (India): en-IN', value: 'en-IN' },
  { label: 'English (Indonesia): en-ID', value: 'en-ID' },
  { label: 'English (Ireland): en-IE', value: 'en-IE' },
  { label: 'English (Isle of Man): en-IM', value: 'en-IM' },
  { label: 'English (Israel): en-IL', value: 'en-IL' },
  { label: 'English (Jamaica): en-JM', value: 'en-JM' },
  { label: 'English (Jersey): en-JE', value: 'en-JE' },
  { label: 'English (Kenya): en-KE', value: 'en-KE' },
  { label: 'English (Kiribati): en-KI', value: 'en-KI' },
  { label: 'English (Lesotho): en-LS', value: 'en-LS' },
  { label: 'English (Liberia): en-LR', value: 'en-LR' },
  { label: 'English (Macao SAR China): en-MO', value: 'en-MO' },
  { label: 'English (Madagascar): en-MG', value: 'en-MG' },
  { label: 'English (Malawi): en-MW', value: 'en-MW' },
  { label: 'English (Malaysia): en-MY', value: 'en-MY' },
  { label: 'English (Maldives): en-MV', value: 'en-MV' },
  { label: 'English (Malta): en-MT', value: 'en-MT' },
  { label: 'English (Marshall Islands): en-MH', value: 'en-MH' },
  { label: 'English (Mauritius): en-MU', value: 'en-MU' },
  { label: 'English (Micronesia): en-FM', value: 'en-FM' },
  { label: 'English (Montserrat): en-MS', value: 'en-MS' },
  { label: 'English (Namibia): en-NA', value: 'en-NA' },
  { label: 'English (Nauru): en-NR', value: 'en-NR' },
  { label: 'English (Netherlands): en-NL', value: 'en-NL' },
  { label: 'English (New Zealand): en-NZ', value: 'en-NZ' },
  { label: 'English (Nigeria): en-NG', value: 'en-NG' },
  { label: 'English (Niue): en-NU', value: 'en-NU' },
  { label: 'English (Norfolk Island): en-NF', value: 'en-NF' },
  { label: 'English (Northern Mariana Islands): en-MP', value: 'en-MP' },
  { label: 'English (Pakistan): en-PK', value: 'en-PK' },
  { label: 'English (Palau): en-PW', value: 'en-PW' },
  { label: 'English (Papua New Guinea): en-PG', value: 'en-PG' },
  { label: 'English (Philippines): en-PH', value: 'en-PH' },
  { label: 'English (Pitcairn Islands): en-PN', value: 'en-PN' },
  { label: 'English (Puerto Rico): en-PR', value: 'en-PR' },
  { label: 'English (Rwanda): en-RW', value: 'en-RW' },
  { label: 'English (Samoa): en-WS', value: 'en-WS' },
  { label: 'English (Seychelles): en-SC', value: 'en-SC' },
  { label: 'English (Sierra Leone): en-SL', value: 'en-SL' },
  { label: 'English (Singapore): en-SG', value: 'en-SG' },
  { label: 'English (Sint Maarten): en-SX', value: 'en-SX' },
  { label: 'English (Slovenia): en-SI', value: 'en-SI' },
  { label: 'English (Solomon Islands): en-SB', value: 'en-SB' },
  { label: 'English (South Africa): en-ZA', value: 'en-ZA' },
  { label: 'English (South Sudan): en-SS', value: 'en-SS' },
  { label: 'English (St. Helena): en-SH', value: 'en-SH' },
  { label: 'English (St. Kitts & Nevis): en-KN', value: 'en-KN' },
  { label: 'English (St. Lucia): en-LC', value: 'en-LC' },
  { label: 'English (St. Vincent & Grenadines): en-VC', value: 'en-VC' },
  { label: 'English (Sudan): en-SD', value: 'en-SD' },
  { label: 'English (Sweden): en-SE', value: 'en-SE' },
  { label: 'English (Switzerland): en-CH', value: 'en-CH' },
  { label: 'English (Tanzania): en-TZ', value: 'en-TZ' },
  { label: 'English (Tokelau): en-TK', value: 'en-TK' },
  { label: 'English (Tonga): en-TO', value: 'en-TO' },
  { label: 'English (Trinidad & Tobago): en-TT', value: 'en-TT' },
  { label: 'English (Turks & Caicos Islands): en-TC', value: 'en-TC' },
  { label: 'English (Tuvalu): en-TV', value: 'en-TV' },
  { label: 'English (U.S. Outlying Islands): en-UM', value: 'en-UM' },
  { label: 'English (U.S. Virgin Islands): en-VI', value: 'en-VI' },
  { label: 'English (Uganda): en-UG', value: 'en-UG' },
  { label: 'English (United Arab Emirates): en-AE', value: 'en-AE' },
  { label: 'English (United Kingdom): en-GB', value: 'en-GB' },
  { label: 'English (Vanuatu): en-VU', value: 'en-VU' },
  { label: 'English (world): en-001', value: 'en-001' },
  { label: 'English (Zambia): en-ZM', value: 'en-ZM' },
  { label: 'English (Zimbabwe): en-ZW', value: 'en-ZW' },
  { label: 'English: en', value: 'en' },
  { label: 'Esperanto (world): eo-001', value: 'eo-001' },
  { label: 'Esperanto: eo', value: 'eo' },
  { label: 'Estonian (Estonia): et-EE', value: 'et-EE' },
  { label: 'Estonian: et', value: 'et' },
  { label: 'Ewe (Ghana): ee-GH', value: 'ee-GH' },
  { label: 'Ewe (Togo): ee-TG', value: 'ee-TG' },
  { label: 'Ewe: ee', value: 'ee' },
  { label: 'Ewondo (Cameroon): ewo-CM', value: 'ewo-CM' },
  { label: 'Ewondo: ewo', value: 'ewo' },
  { label: 'Faroese (Denmark): fo-DK', value: 'fo-DK' },
  { label: 'Faroese (Faroe Islands): fo-FO', value: 'fo-FO' },
  { label: 'Faroese: fo', value: 'fo' },
  { label: 'Filipino (Philippines): fil-PH', value: 'fil-PH' },
  { label: 'Filipino: fil', value: 'fil' },
  { label: 'Finnish (Finland): fi-FI', value: 'fi-FI' },
  { label: 'Finnish: fi', value: 'fi' },
  { label: 'French (Algeria): fr-DZ', value: 'fr-DZ' },
  { label: 'French (Belgium): fr-BE', value: 'fr-BE' },
  { label: 'French (Benin): fr-BJ', value: 'fr-BJ' },
  { label: 'French (Burkina Faso): fr-BF', value: 'fr-BF' },
  { label: 'French (Burundi): fr-BI', value: 'fr-BI' },
  { label: 'French (Cameroon): fr-CM', value: 'fr-CM' },
  { label: 'French (Canada): fr-CA', value: 'fr-CA' },
  { label: 'French (Central African Republic): fr-CF', value: 'fr-CF' },
  { label: 'French (Chad): fr-TD', value: 'fr-TD' },
  { label: 'French (Comoros): fr-KM', value: 'fr-KM' },
  { label: 'French (Congo - Brazzaville): fr-CG', value: 'fr-CG' },
  { label: 'French (Congo - Kinshasa): fr-CD', value: 'fr-CD' },
  { label: 'French (Côte d’Ivoire): fr-CI', value: 'fr-CI' },
  { label: 'French (Djibouti): fr-DJ', value: 'fr-DJ' },
  { label: 'French (Equatorial Guinea): fr-GQ', value: 'fr-GQ' },
  { label: 'French (France): fr-FR', value: 'fr-FR' },
  { label: 'French (French Guiana): fr-GF', value: 'fr-GF' },
  { label: 'French (French Polynesia): fr-PF', value: 'fr-PF' },
  { label: 'French (Gabon): fr-GA', value: 'fr-GA' },
  { label: 'French (Guadeloupe): fr-GP', value: 'fr-GP' },
  { label: 'French (Guinea): fr-GN', value: 'fr-GN' },
  { label: 'French (Haiti): fr-HT', value: 'fr-HT' },
  { label: 'French (Luxembourg): fr-LU', value: 'fr-LU' },
  { label: 'French (Madagascar): fr-MG', value: 'fr-MG' },
  { label: 'French (Mali): fr-ML', value: 'fr-ML' },
  { label: 'French (Martinique): fr-MQ', value: 'fr-MQ' },
  { label: 'French (Mauritania): fr-MR', value: 'fr-MR' },
  { label: 'French (Mauritius): fr-MU', value: 'fr-MU' },
  { label: 'French (Mayotte): fr-YT', value: 'fr-YT' },
  { label: 'French (Monaco): fr-MC', value: 'fr-MC' },
  { label: 'French (Morocco): fr-MA', value: 'fr-MA' },
  { label: 'French (New Caledonia): fr-NC', value: 'fr-NC' },
  { label: 'French (Niger): fr-NE', value: 'fr-NE' },
  { label: 'French (Réunion): fr-RE', value: 'fr-RE' },
  { label: 'French (Rwanda): fr-RW', value: 'fr-RW' },
  { label: 'French (Senegal): fr-SN', value: 'fr-SN' },
  { label: 'French (Seychelles): fr-SC', value: 'fr-SC' },
  { label: 'French (St. Barthélemy): fr-BL', value: 'fr-BL' },
  { label: 'French (St. Martin): fr-MF', value: 'fr-MF' },
  { label: 'French (St. Pierre & Miquelon): fr-PM', value: 'fr-PM' },
  { label: 'French (Switzerland): fr-CH', value: 'fr-CH' },
  { label: 'French (Syria): fr-SY', value: 'fr-SY' },
  { label: 'French (Togo): fr-TG', value: 'fr-TG' },
  { label: 'French (Tunisia): fr-TN', value: 'fr-TN' },
  { label: 'French (Vanuatu): fr-VU', value: 'fr-VU' },
  { label: 'French (Wallis & Futuna): fr-WF', value: 'fr-WF' },
  { label: 'French: fr', value: 'fr' },
  { label: 'Friulian (Italy): fur-IT', value: 'fur-IT' },
  { label: 'Friulian: fur', value: 'fur' },
  { label: 'Fula (Adlam, Burkina Faso): ff-BF', value: 'ff-BF' },
  { label: 'Fula (Adlam, Cameroon): ff-CM', value: 'ff-CM' },
  { label: 'Fula (Adlam, Gambia): ff-GM', value: 'ff-GM' },
  { label: 'Fula (Adlam, Ghana): ff-GH', value: 'ff-GH' },
  { label: 'Fula (Adlam, Guinea-Bissau): ff-GW', value: 'ff-GW' },
  { label: 'Fula (Adlam, Guinea): ff-GN', value: 'ff-GN' },
  { label: 'Fula (Adlam, Liberia): ff-LR', value: 'ff-LR' },
  { label: 'Fula (Adlam, Mauritania): ff-MR', value: 'ff-MR' },
  { label: 'Fula (Adlam, Niger): ff-NE', value: 'ff-NE' },
  { label: 'Fula (Adlam, Nigeria): ff-NG', value: 'ff-NG' },
  { label: 'Fula (Adlam, Senegal): ff-SN', value: 'ff-SN' },
  { label: 'Fula (Adlam, Sierra Leone): ff-SL', value: 'ff-SL' },
  { label: 'Fula (Adlam): ff', value: 'ff' },
  { label: 'Galician (Spain): gl-ES', value: 'gl-ES' },
  { label: 'Galician: gl', value: 'gl' },
  { label: 'Ganda (Uganda): lg-UG', value: 'lg-UG' },
  { label: 'Ganda: lg', value: 'lg' },
  { label: 'Georgian (Georgia): ka-GE', value: 'ka-GE' },
  { label: 'Georgian: ka', value: 'ka' },
  { label: 'German (Austria): de-AT', value: 'de-AT' },
  { label: 'German (Belgium): de-BE', value: 'de-BE' },
  { label: 'German (Germany): de-DE', value: 'de-DE' },
  { label: 'German (Italy): de-IT', value: 'de-IT' },
  { label: 'German (Liechtenstein): de-LI', value: 'de-LI' },
  { label: 'German (Luxembourg): de-LU', value: 'de-LU' },
  { label: 'German (Switzerland): de-CH', value: 'de-CH' },
  { label: 'German: de', value: 'de' },
  { label: 'Greek (Cyprus): el-CY', value: 'el-CY' },
  { label: 'Greek (Greece): el-GR', value: 'el-GR' },
  { label: 'Greek (Polytonic): el', value: 'el' },
  { label: 'Gujarati (Gujarati, India): gu-IN', value: 'gu-IN' },
  { label: 'Gujarati: gu', value: 'gu' },
  { label: 'Gusii (Kenya): guz-KE', value: 'guz-KE' },
  { label: 'Gusii: guz', value: 'guz' },
  { label: 'Haryanvi (Devanagari, India): bgc-IN', value: 'bgc-IN' },
  { label: 'Haryanvi: bgc', value: 'bgc' },
  { label: 'Hausa (Ghana): ha-GH', value: 'ha-GH' },
  { label: 'Hausa (Nigeria): ha-NG', value: 'ha-NG' },
  { label: 'Hausa (Niger): ha-NE', value: 'ha-NE' },
  { label: 'Hausa: ha', value: 'ha' },
  { label: 'Hawaiian (United States): haw-US', value: 'haw-US' },
  { label: 'Hawaiian: haw', value: 'haw' },
  { label: 'Hebrew (Hebrew, Israel): he-IL', value: 'he-IL' },
  { label: 'Hebrew: he', value: 'he' },
  { label: 'Hindi (Devanagari, India): hi-IN', value: 'hi-IN' },
  { label: 'Hindi (Latin): hi', value: 'hi' },
  { label: 'Hungarian (Hungary): hu-HU', value: 'hu-HU' },
  { label: 'Hungarian: hu', value: 'hu' },
  { label: 'Icelandic (Iceland): is-IS', value: 'is-IS' },
  { label: 'Icelandic: is', value: 'is' },
  { label: 'Igbo (Nigeria): ig-NG', value: 'ig-NG' },
  { label: 'Igbo: ig', value: 'ig' },
  { label: 'Inari Sami (Finland): smn-FI', value: 'smn-FI' },
  { label: 'Inari Sami: smn', value: 'smn' },
  { label: 'Indonesian (Indonesia): id-ID', value: 'id-ID' },
  { label: 'Indonesian: id', value: 'id' },
  { label: 'Interlingua (world): ia-001', value: 'ia-001' },
  { label: 'Interlingua: ia', value: 'ia' },
  { label: 'Interlingue (Estonia): ie-EE', value: 'ie-EE' },
  { label: 'Interlingue: ie', value: 'ie' },
  { label: 'Irish (Ireland): ga-IE', value: 'ga-IE' },
  { label: 'Irish (United Kingdom): ga-GB', value: 'ga-GB' },
  { label: 'Irish: ga', value: 'ga' },
  { label: 'Italian (Italy): it-IT', value: 'it-IT' },
  { label: 'Italian (San Marino): it-SM', value: 'it-SM' },
  { label: 'Italian (Switzerland): it-CH', value: 'it-CH' },
  { label: 'Italian (Vatican City): it-VA', value: 'it-VA' },
  { label: 'Italian: it', value: 'it' },
  {
    label: 'Japanese (Japan, JP, Japanese Calendar): ja-JP',
    value: 'ja-JP',
  },
  { label: 'Japanese: ja', value: 'ja' },
  { label: 'Javanese (Indonesia): jv-ID', value: 'jv-ID' },
  { label: 'Javanese: jv', value: 'jv' },
  { label: 'Jola-Fonyi (Senegal): dyo-SN', value: 'dyo-SN' },
  { label: 'Jola-Fonyi: dyo', value: 'dyo' },
  { label: 'Kabuverdianu (Cape Verde): kea-CV', value: 'kea-CV' },
  { label: 'Kabuverdianu: kea', value: 'kea' },
  { label: 'Kabyle (Algeria): kab-DZ', value: 'kab-DZ' },
  { label: 'Kabyle: kab', value: 'kab' },
  { label: 'Kaingang (Brazil): kgp-BR', value: 'kgp-BR' },
  { label: 'Kaingang: kgp', value: 'kgp' },
  { label: 'Kako (Cameroon): kkj-CM', value: 'kkj-CM' },
  { label: 'Kako: kkj', value: 'kkj' },
  { label: 'Kalaallisut (Greenland): kl-GL', value: 'kl-GL' },
  { label: 'Kalaallisut: kl', value: 'kl' },
  { label: 'Kalenjin (Kenya): kln-KE', value: 'kln-KE' },
  { label: 'Kalenjin: kln', value: 'kln' },
  { label: 'Kamba (Kenya): kam-KE', value: 'kam-KE' },
  { label: 'Kamba: kam', value: 'kam' },
  { label: 'Kangri (Devanagari, India): xnr-IN', value: 'xnr-IN' },
  { label: 'Kangri: xnr', value: 'xnr' },
  { label: 'Kannada (India): kn-IN', value: 'kn-IN' },
  { label: 'Kannada: kn', value: 'kn' },
  { label: 'Kashmiri (Arabic, India): ks-IN', value: 'ks-IN' },
  { label: 'Kashmiri (Arabic): ks', value: 'ks' },
  { label: 'Kazakh (Cyrillic, Kazakhstan): kk-KZ', value: 'kk-KZ' },
  { label: 'Kazakh: kk', value: 'kk' },
  { label: 'Khmer (Cambodia): km-KH', value: 'km-KH' },
  { label: 'Khmer: km', value: 'km' },
  { label: 'Kikuyu (Kenya): ki-KE', value: 'ki-KE' },
  { label: 'Kikuyu: ki', value: 'ki' },
  { label: 'Kinyarwanda (Rwanda): rw-RW', value: 'rw-RW' },
  { label: 'Kinyarwanda: rw', value: 'rw' },
  { label: 'Konkani (Devanagari, India): kok-IN', value: 'kok-IN' },
  { label: 'Konkani: kok', value: 'kok' },
  { label: 'Korean (China): ko-CN', value: 'ko-CN' },
  { label: 'Korean (Korean, South Korea): ko-KR', value: 'ko-KR' },
  { label: 'Korean (North Korea): ko-KP', value: 'ko-KP' },
  { label: 'Korean: ko', value: 'ko' },
  { label: 'Koyra Chiini (Mali): khq-ML', value: 'khq-ML' },
  { label: 'Koyra Chiini: khq', value: 'khq' },
  { label: 'Koyraboro Senni (Mali): ses-ML', value: 'ses-ML' },
  { label: 'Koyraboro Senni: ses', value: 'ses' },
  { label: 'Kurdish (Türkiye): ku-TR', value: 'ku-TR' },
  { label: 'Kurdish: ku', value: 'ku' },
  { label: 'Kuvi (Devanagari, India): kxv-IN', value: 'kxv-IN' },
  { label: 'Kuvi (Devanagari): kxv', value: 'kxv' },
  { label: 'Kwasio (Cameroon): nmg-CM', value: 'nmg-CM' },
  { label: 'Kwasio: nmg', value: 'nmg' },
  { label: 'Kyrgyz (Cyrillic, Kyrgyzstan): ky-KG', value: 'ky-KG' },
  { label: 'Kyrgyz: ky', value: 'ky' },
  { label: 'Lakota (United States): lkt-US', value: 'lkt-US' },
  { label: 'Lakota: lkt', value: 'lkt' },
  { label: 'Langi (Tanzania): lag-TZ', value: 'lag-TZ' },
  { label: 'Langi: lag', value: 'lag' },
  { label: 'Lao (Lao, Laos): lo-LA', value: 'lo-LA' },
  { label: 'Lao: lo', value: 'lo' },
  { label: 'Latvian (Latvia): lv-LV', value: 'lv-LV' },
  { label: 'Latvian: lv', value: 'lv' },
  { label: 'Ligurian (Italy): lij-IT', value: 'lij-IT' },
  { label: 'Ligurian: lij', value: 'lij' },
  { label: 'Lingala (Angola): ln-AO', value: 'ln-AO' },
  { label: 'Lingala (Central African Republic): ln-CF', value: 'ln-CF' },
  { label: 'Lingala (Congo - Brazzaville): ln-CG', value: 'ln-CG' },
  { label: 'Lingala (Congo - Kinshasa): ln-CD', value: 'ln-CD' },
  { label: 'Lingala: ln', value: 'ln' },
  { label: 'Lithuanian (Lithuania): lt-LT', value: 'lt-LT' },
  { label: 'Lithuanian: lt', value: 'lt' },
  { label: 'Lombard (Italy): lmo-IT', value: 'lmo-IT' },
  { label: 'Lombard: lmo', value: 'lmo' },
  { label: 'Low German (Germany): nds-DE', value: 'nds-DE' },
  { label: 'Low German (Netherlands): nds-NL', value: 'nds-NL' },
  { label: 'Low German: nds', value: 'nds' },
  { label: 'Lower Sorbian (Germany): dsb-DE', value: 'dsb-DE' },
  { label: 'Lower Sorbian: dsb', value: 'dsb' },
  { label: 'Luba-Katanga (Congo - Kinshasa): lu-CD', value: 'lu-CD' },
  { label: 'Luba-Katanga: lu', value: 'lu' },
  { label: 'Luo (Kenya): luo-KE', value: 'luo-KE' },
  { label: 'Luo: luo', value: 'luo' },
  { label: 'Luxembourgish (Luxembourg): lb-LU', value: 'lb-LU' },
  { label: 'Luxembourgish: lb', value: 'lb' },
  { label: 'Luyia (Kenya): luy-KE', value: 'luy-KE' },
  { label: 'Luyia: luy', value: 'luy' },
  {
    label: 'Macedonian (Cyrillic, North Macedonia): mk-MK',
    value: 'mk-MK',
  },
  { label: 'Macedonian: mk', value: 'mk' },
  { label: 'Machame (Tanzania): jmc-TZ', value: 'jmc-TZ' },
  { label: 'Machame: jmc', value: 'jmc' },
  { label: 'Maithili (Devanagari, India): mai-IN', value: 'mai-IN' },
  { label: 'Maithili: mai', value: 'mai' },
  { label: 'Makhuwa (Mozambique): vmw-MZ', value: 'vmw-MZ' },
  {
    label: 'Makhuwa-Meetto (Mozambique): mgh-MZ',
    value: 'mgh-MZ',
  },
  { label: 'Makhuwa-Meetto: mgh', value: 'mgh' },
  { label: 'Makhuwa: vmw', value: 'vmw' },
  { label: 'Makonde (Tanzania): kde-TZ', value: 'kde-TZ' },
  { label: 'Makonde: kde', value: 'kde' },
  { label: 'Malagasy (Madagascar): mg-MG', value: 'mg-MG' },
  { label: 'Malagasy: mg', value: 'mg' },
  { label: 'Malay (Brunei): ms-BN', value: 'ms-BN' },
  { label: 'Malay (Indonesia): ms-ID', value: 'ms-ID' },
  { label: 'Malay (Malaysia): ms-MY', value: 'ms-MY' },
  { label: 'Malay (Singapore): ms-SG', value: 'ms-SG' },
  { label: 'Malay: ms', value: 'ms' },
  { label: 'Malayalam (India): ml-IN', value: 'ml-IN' },
  { label: 'Malayalam: ml', value: 'ml' },
  { label: 'Maltese (Malta): mt-MT', value: 'mt-MT' },
  { label: 'Maltese: mt', value: 'mt' },
  { label: 'Manipuri (Bangla, India): mni-IN', value: 'mni-IN' },
  { label: 'Manipuri (Bangla): mni', value: 'mni' },
  { label: 'Manx (Isle of Man): gv-IM', value: 'gv-IM' },
  { label: 'Manx: gv', value: 'gv' },
  { label: 'Māori (New Zealand): mi-NZ', value: 'mi-NZ' },
  { label: 'Māori: mi', value: 'mi' },
  { label: 'Marathi (Devanagari, India): mr-IN', value: 'mr-IN' },
  { label: 'Marathi: mr', value: 'mr' },
  { label: 'Masai (Kenya): mas-KE', value: 'mas-KE' },
  { label: 'Masai (Tanzania): mas-TZ', value: 'mas-TZ' },
  { label: 'Masai: mas', value: 'mas' },
  { label: 'Mazanderani (Arabic, Iran): mzn-IR', value: 'mzn-IR' },
  { label: 'Mazanderani: mzn', value: 'mzn' },
  { label: 'Meru (Kenya): mer-KE', value: 'mer-KE' },
  { label: 'Meru: mer', value: 'mer' },
  { label: 'Metaʼ (Cameroon): mgo-CM', value: 'mgo-CM' },
  { label: 'Metaʼ: mgo', value: 'mgo' },
  { label: 'Moksha (Cyrillic, Russia): mdf-RU', value: 'mdf-RU' },
  { label: 'Moksha: mdf', value: 'mdf' },
  { label: 'Mongolian (Cyrillic, Mongolia): mn-MN', value: 'mn-MN' },
  { label: 'Mongolian: mn', value: 'mn' },
  { label: 'Morisyen (Mauritius): mfe-MU', value: 'mfe-MU' },
  { label: 'Morisyen: mfe', value: 'mfe' },
  { label: 'Mundang (Cameroon): mua-CM', value: 'mua-CM' },
  { label: 'Mundang: mua', value: 'mua' },
  { label: 'N’Ko (Guinea): nqo-GN', value: 'nqo-GN' },
  { label: 'N’Ko: nqo', value: 'nqo' },
  { label: 'Nama (Namibia): naq-NA', value: 'naq-NA' },
  { label: 'Nama: naq', value: 'naq' },
  { label: 'Nepali (Devanagari, Nepal): ne-NP', value: 'ne-NP' },
  { label: 'Nepali (India): ne-IN', value: 'ne-IN' },
  { label: 'Nepali: ne', value: 'ne' },
  { label: 'Ngiemboon (Cameroon): nnh-CM', value: 'nnh-CM' },
  { label: 'Ngiemboon: nnh', value: 'nnh' },
  { label: 'Ngomba (Cameroon): jgo-CM', value: 'jgo-CM' },
  { label: 'Ngomba: jgo', value: 'jgo' },
  { label: 'Nheengatu (Brazil): yrl-BR', value: 'yrl-BR' },
  { label: 'Nheengatu (Colombia): yrl-CO', value: 'yrl-CO' },
  { label: 'Nheengatu (Venezuela): yrl-VE', value: 'yrl-VE' },
  { label: 'Nheengatu: yrl', value: 'yrl' },
  { label: 'Nigerian Pidgin (Nigeria): pcm-NG', value: 'pcm-NG' },
  { label: 'Nigerian Pidgin: pcm', value: 'pcm' },
  { label: 'North Ndebele (Zimbabwe): nd-ZW', value: 'nd-ZW' },
  { label: 'North Ndebele: nd', value: 'nd' },
  { label: 'Northern Frisian (Germany): frr-DE', value: 'frr-DE' },
  { label: 'Northern Frisian: frr', value: 'frr' },
  { label: 'Northern Luri (Arabic, Iran): lrc-IR', value: 'lrc-IR' },
  { label: 'Northern Luri (Iraq): lrc-IQ', value: 'lrc-IQ' },
  { label: 'Northern Luri: lrc', value: 'lrc' },
  { label: 'Northern Sami (Finland): se-FI', value: 'se-FI' },
  { label: 'Northern Sami (Norway): se-NO', value: 'se-NO' },
  { label: 'Northern Sami (Sweden): se-SE', value: 'se-SE' },
  { label: 'Northern Sami: se', value: 'se' },
  { label: 'Norwegian (Norway): no-NO', value: 'no-NO' },
  { label: 'Norwegian Bokmål (Norway): nb-NO', value: 'nb-NO' },
  {
    label: 'Norwegian Bokmål (Svalbard & Jan Mayen): nb-SJ',
    value: 'nb-SJ',
  },
  { label: 'Norwegian Bokmål: nb', value: 'nb' },
  { label: 'Norwegian Nynorsk (Norway): nn-NO', value: 'nn-NO' },
  { label: 'Norwegian Nynorsk: nn', value: 'nn' },
  { label: 'Norwegian: no', value: 'no' },
  { label: 'Nuer (South Sudan): nus-SS', value: 'nus-SS' },
  { label: 'Nuer: nus', value: 'nus' },
  { label: 'Nyankole (Uganda): nyn-UG', value: 'nyn-UG' },
  { label: 'Nyankole: nyn', value: 'nyn' },
  { label: 'Obolo (Nigeria): ann-NG', value: 'ann-NG' },
  { label: 'Obolo: ann', value: 'ann' },
  { label: 'Occitan (France): oc-FR', value: 'oc-FR' },
  { label: 'Occitan (Spain): oc-ES', value: 'oc-ES' },
  { label: 'Occitan: oc', value: 'oc' },
  { label: 'Odia (India): or-IN', value: 'or-IN' },
  { label: 'Odia: or', value: 'or' },
  { label: 'Oromo (Ethiopia): om-ET', value: 'om-ET' },
  { label: 'Oromo (Kenya): om-KE', value: 'om-KE' },
  { label: 'Oromo: om', value: 'om' },
  { label: 'Ossetic (Cyrillic, Georgia): os-GE', value: 'os-GE' },
  { label: 'Ossetic (Russia): os-RU', value: 'os-RU' },
  { label: 'Ossetic: os', value: 'os' },
  { label: 'Pashto (Afghanistan): ps-AF', value: 'ps-AF' },
  { label: 'Pashto (Pakistan): ps-PK', value: 'ps-PK' },
  { label: 'Pashto: ps', value: 'ps' },
  { label: 'Persian (Afghanistan): fa-AF', value: 'fa-AF' },
  { label: 'Persian (Arabic, Iran): fa-IR', value: 'fa-IR' },
  { label: 'Persian: fa', value: 'fa' },
  { label: 'Pijin (Solomon Islands): pis-SB', value: 'pis-SB' },
  { label: 'Pijin: pis', value: 'pis' },
  { label: 'Polish (Poland): pl-PL', value: 'pl-PL' },
  { label: 'Polish: pl', value: 'pl' },
  { label: 'Portuguese (Angola): pt-AO', value: 'pt-AO' },
  { label: 'Portuguese (Brazil): pt-BR', value: 'pt-BR' },
  { label: 'Portuguese (Cape Verde): pt-CV', value: 'pt-CV' },
  { label: 'Portuguese (Equatorial Guinea): pt-GQ', value: 'pt-GQ' },
  { label: 'Portuguese (Guinea-Bissau): pt-GW', value: 'pt-GW' },
  { label: 'Portuguese (Luxembourg): pt-LU', value: 'pt-LU' },
  { label: 'Portuguese (Macao SAR China): pt-MO', value: 'pt-MO' },
  { label: 'Portuguese (Mozambique): pt-MZ', value: 'pt-MZ' },
  { label: 'Portuguese (Portugal): pt-PT', value: 'pt-PT' },
  { label: 'Portuguese (São Tomé & Príncipe): pt-ST', value: 'pt-ST' },
  { label: 'Portuguese (Switzerland): pt-CH', value: 'pt-CH' },
  { label: 'Portuguese (Timor-Leste): pt-TL', value: 'pt-TL' },
  { label: 'Portuguese: pt', value: 'pt' },
  { label: 'Prussian (Poland): prg-PL', value: 'prg-PL' },
  { label: 'Prussian: prg', value: 'prg' },
  { label: 'Punjabi (Arabic, Pakistan): pa-PK', value: 'pa-PK' },
  { label: 'Punjabi (Arabic): pa', value: 'pa' },
  { label: 'Punjabi (Gurmukhi, India): pa-IN', value: 'pa-IN' },
  { label: 'Quechua (Bolivia): qu-BO', value: 'qu-BO' },
  { label: 'Quechua (Ecuador): qu-EC', value: 'qu-EC' },
  { label: 'Quechua (Peru): qu-PE', value: 'qu-PE' },
  { label: 'Quechua: qu', value: 'qu' },
  { label: 'Rajasthani (Devanagari, India): raj-IN', value: 'raj-IN' },
  { label: 'Rajasthani: raj', value: 'raj' },
  { label: 'Romanian (Romania): ro-RO', value: 'ro-RO' },
  { label: 'Romanian (Moldova): ro-MD', value: 'ro-MD' },
  { label: 'Romanian: ro', value: 'ro' },
  { label: 'Romansh (Switzerland): rm-CH', value: 'rm-CH' },
  { label: 'Romansh: rm', value: 'rm' },
  { label: 'Rombo (Tanzania): rof-TZ', value: 'rof-TZ' },
  { label: 'Rombo: rof', value: 'rof' },
  { label: 'Rundi (Burundi): rn-BI', value: 'rn-BI' },
  { label: 'Rundi: rn', value: 'rn' },
  { label: 'Russian (Belarus): ru-BY', value: 'ru-BY' },
  { label: 'Russian (Cyrillic, Russia): ru-RU', value: 'ru-RU' },
  { label: 'Russian (Kazakhstan): ru-KZ', value: 'ru-KZ' },
  { label: 'Russian (Kyrgyzstan): ru-KG', value: 'ru-KG' },
  { label: 'Russian (Moldova): ru-MD', value: 'ru-MD' },
  { label: 'Russian (Ukraine): ru-UA', value: 'ru-UA' },
  { label: 'Russian: ru', value: 'ru' },
  { label: 'Rwa (Tanzania): rwk-TZ', value: 'rwk-TZ' },
  { label: 'Rwa: rwk', value: 'rwk' },
  { label: 'Samburu (Kenya): saq-KE', value: 'saq-KE' },
  { label: 'Samburu: saq', value: 'saq' },
  { label: 'Sango (Central African Republic): sg-CF', value: 'sg-CF' },
  { label: 'Sango: sg', value: 'sg' },
  { label: 'Sangu (Tanzania): sbp-TZ', value: 'sbp-TZ' },
  { label: 'Sangu: sbp', value: 'sbp' },
  { label: 'Sanskrit (Devanagari, India): sa-IN', value: 'sa-IN' },
  { label: 'Sanskrit: sa', value: 'sa' },
  { label: 'Santali (India): sat-IN', value: 'sat-IN' },
  { label: 'Santali (Ol Chiki): sat', value: 'sat' },
  { label: 'Sardinian (Italy): sc-IT', value: 'sc-IT' },
  { label: 'Sardinian: sc', value: 'sc' },
  {
    label: 'Scottish Gaelic (United Kingdom): gd-GB',
    value: 'gd-GB',
  },
  { label: 'Scottish Gaelic: gd', value: 'gd' },
  { label: 'Sena (Mozambique): seh-MZ', value: 'seh-MZ' },
  { label: 'Sena: seh', value: 'seh' },
  { label: 'Serbian (Bosnia & Herzegovina): sr-BA', value: 'sr-BA' },
  { label: 'Serbian (Cyrillic, Kosovo): sr-XK', value: 'sr-XK' },
  { label: 'Serbian (Cyrillic, Montenegro): sr-ME', value: 'sr-ME' },
  { label: 'Serbian (Cyrillic, Serbia): sr-RS', value: 'sr-RS' },
  { label: 'Serbian (Cyrillic): sr', value: 'sr' },
  { label: 'Serbian (Serbia and Montenegro): sr-CS', value: 'sr-CS' },
  { label: 'Shambala (Tanzania): ksb-TZ', value: 'ksb-TZ' },
  { label: 'Shambala: ksb', value: 'ksb' },
  { label: 'Shona (Zimbabwe): sn-ZW', value: 'sn-ZW' },
  { label: 'Shona: sn', value: 'sn' },
  { label: 'Sichuan Yi (China): ii-CN', value: 'ii-CN' },
  { label: 'Sichuan Yi: ii', value: 'ii' },
  { label: 'Silesian (Poland): szl-PL', value: 'szl-PL' },
  { label: 'Silesian: szl', value: 'szl' },
  { label: 'Sindhi (Arabic, Pakistan): sd-PK', value: 'sd-PK' },
  { label: 'Sindhi (Arabic): sd', value: 'sd' },
  { label: 'Sindhi (Devanagari, India): sd-IN', value: 'sd-IN' },
  { label: 'Sinhala (Sinhala, Sri Lanka): si-LK', value: 'si-LK' },
  { label: 'Sinhala: si', value: 'si' },
  { label: 'Skolt Sami (Finland): sms-FI', value: 'sms-FI' },
  { label: 'Skolt Sami: sms', value: 'sms' },
  { label: 'Slovak (Slovakia): sk-SK', value: 'sk-SK' },
  { label: 'Slovak: sk', value: 'sk' },
  { label: 'Slovenian (Slovenia): sl-SI', value: 'sl-SI' },
  { label: 'Slovenian: sl', value: 'sl' },
  { label: 'Soga (Uganda): xog-UG', value: 'xog-UG' },
  { label: 'Soga: xog', value: 'xog' },
  { label: 'Somali (Djibouti): so-DJ', value: 'so-DJ' },
  { label: 'Somali (Ethiopia): so-ET', value: 'so-ET' },
  { label: 'Somali (Kenya): so-KE', value: 'so-KE' },
  { label: 'Somali (Somalia): so-SO', value: 'so-SO' },
  { label: 'Somali: so', value: 'so' },
  { label: 'Spanish (Argentina): es-AR', value: 'es-AR' },
  { label: 'Spanish (Belize): es-BZ', value: 'es-BZ' },
  { label: 'Spanish (Bolivia): es-BO', value: 'es-BO' },
  { label: 'Spanish (Brazil): es-BR', value: 'es-BR' },
  { label: 'Spanish (Canary Islands): es-IC', value: 'es-IC' },
  { label: 'Spanish (Ceuta & Melilla): es-EA', value: 'es-EA' },
  { label: 'Spanish (Chile): es-CL', value: 'es-CL' },
  { label: 'Spanish (Colombia): es-CO', value: 'es-CO' },
  { label: 'Spanish (Costa Rica): es-CR', value: 'es-CR' },
  { label: 'Spanish (Cuba): es-CU', value: 'es-CU' },
  { label: 'Spanish (Dominican Republic): es-DO', value: 'es-DO' },
  { label: 'Spanish (Ecuador): es-EC', value: 'es-EC' },
  { label: 'Spanish (El Salvador): es-SV', value: 'es-SV' },
  { label: 'Spanish (Equatorial Guinea): es-GQ', value: 'es-GQ' },
  { label: 'Spanish (Guatemala): es-GT', value: 'es-GT' },
  { label: 'Spanish (Honduras): es-HN', value: 'es-HN' },
  { label: 'Spanish (Latin America): es-419', value: 'es-419' },
  { label: 'Spanish (Spain): es-ES', value: 'es-ES' },
  { label: 'Spanish (Mexico): es-MX', value: 'es-MX' },
  { label: 'Spanish (Nicaragua): es-NI', value: 'es-NI' },
  { label: 'Spanish (Panama): es-PA', value: 'es-PA' },
  { label: 'Spanish (Paraguay): es-PY', value: 'es-PY' },
  { label: 'Spanish (Peru): es-PE', value: 'es-PE' },
  { label: 'Spanish (Philippines): es-PH', value: 'es-PH' },
  { label: 'Spanish (Puerto Rico): es-PR', value: 'es-PR' },
  { label: 'Spanish (United States): es-US', value: 'es-US' },
  { label: 'Spanish (Uruguay): es-UY', value: 'es-UY' },
  { label: 'Spanish (Venezuela): es-VE', value: 'es-VE' },
  { label: 'Spanish: es', value: 'es' },
  {
    label: 'Standard Moroccan Tamazight (Morocco): zgh-MA',
    value: 'zgh-MA',
  },
  { label: 'Standard Moroccan Tamazight: zgh', value: 'zgh' },
  { label: 'Sundanese (Indonesia): su-ID', value: 'su-ID' },
  { label: 'Sundanese (Latin): su', value: 'su' },
  { label: 'Swahili (Congo - Kinshasa): sw-CD', value: 'sw-CD' },
  { label: 'Swahili (Kenya): sw-KE', value: 'sw-KE' },
  { label: 'Swahili (Tanzania): sw-TZ', value: 'sw-TZ' },
  { label: 'Swahili (Uganda): sw-UG', value: 'sw-UG' },
  { label: 'Swahili: sw', value: 'sw' },
  { label: 'Swampy Cree (Canada): csw-CA', value: 'csw-CA' },
  { label: 'Swampy Cree: csw', value: 'csw' },
  { label: 'Swedish (Åland Islands): sv-AX', value: 'sv-AX' },
  { label: 'Swedish (Finland): sv-FI', value: 'sv-FI' },
  { label: 'Swedish (Sweden): sv-SE', value: 'sv-SE' },
  { label: 'Swedish: sv', value: 'sv' },
  { label: 'Swiss German (France): gsw-FR', value: 'gsw-FR' },
  { label: 'Swiss German (Switzerland): gsw-CH', value: 'gsw-CH' },
  { label: 'Swiss German (Liechtenstein): gsw-LI', value: 'gsw-LI' },
  { label: 'Swiss German: gsw', value: 'gsw' },
  { label: 'Syriac (Iraq): syr-IQ', value: 'syr-IQ' },
  { label: 'Syriac (Syria): syr-SY', value: 'syr-SY' },
  { label: 'Syriac: syr', value: 'syr' },
  { label: 'Tachelhit (Morocco): shi-MA', value: 'shi-MA' },
  { label: 'Tachelhit (Latin): shi', value: 'shi' },
  { label: 'Taita (Kenya): dav-KE', value: 'dav-KE' },
  { label: 'Taita: dav', value: 'dav' },
  { label: 'Tajik (Cyrillic, Tajikistan): tg-TJ', value: 'tg-TJ' },
  { label: 'Tajik: tg', value: 'tg' },
  { label: 'Tamil (India): ta-IN', value: 'ta-IN' },
  { label: 'Tamil (Malaysia): ta-MY', value: 'ta-MY' },
  { label: 'Tamil (Singapore): ta-SG', value: 'ta-SG' },
  { label: 'Tamil (Sri Lanka): ta-LK', value: 'ta-LK' },
  { label: 'Tamil: ta', value: 'ta' },
  { label: 'Tasawaq (Niger): twq-NE', value: 'twq-NE' },
  { label: 'Tasawaq: twq', value: 'twq' },
  { label: 'Tatar (Cyrillic, Russia): tt-RU', value: 'tt-RU' },
  { label: 'Tatar: tt', value: 'tt' },
  { label: 'Telugu (India): te-IN', value: 'te-IN' },
  { label: 'Telugu: te', value: 'te' },
  { label: 'Teso (Kenya): teo-KE', value: 'teo-KE' },
  { label: 'Teso (Uganda): teo-UG', value: 'teo-UG' },
  { label: 'Teso: teo', value: 'teo' },
  { label: 'Thai (Thai, Thailand): th-TH', value: 'th-TH' },
  { label: 'Thai: th', value: 'th' },
  { label: 'Tibetan (China): bo-CN', value: 'bo-CN' },
  { label: 'Tibetan (India): bo-IN', value: 'bo-IN' },
  { label: 'Tibetan: bo', value: 'bo' },
  { label: 'Tigrinya (Eritrea): ti-ER', value: 'ti-ER' },
  { label: 'Tigrinya (Ethiopia): ti-ET', value: 'ti-ET' },
  { label: 'Tigrinya: ti', value: 'ti' },
  { label: 'Toki Pona (world): tok-001', value: 'tok-001' },
  { label: 'Toki Pona: tok', value: 'tok' },
  { label: 'Tongan (Tonga): to-TO', value: 'to-TO' },
  { label: 'Tongan: to', value: 'to' },
  { label: 'Turkish (Cyprus): tr-CY', value: 'tr-CY' },
  { label: 'Turkish (Türkiye): tr-TR', value: 'tr-TR' },
  { label: 'Turkish: tr', value: 'tr' },
  { label: 'Turkmen (Turkmenistan): tk-TM', value: 'tk-TM' },
  { label: 'Turkmen: tk', value: 'tk' },
  { label: 'Ukrainian (Cyrillic, Ukraine): uk-UA', value: 'uk-UA' },
  { label: 'Ukrainian: uk', value: 'uk' },
  { label: 'Upper Sorbian (Germany): hsb-DE', value: 'hsb-DE' },
  { label: 'Upper Sorbian: hsb', value: 'hsb' },
  { label: 'Urdu (Arabic, Pakistan): ur-PK', value: 'ur-PK' },
  { label: 'Urdu (India): ur-IN', value: 'ur-IN' },
  { label: 'Urdu: ur', value: 'ur' },
  { label: 'Uyghur (Arabic, China): ug-CN', value: 'ug-CN' },
  { label: 'Uyghur: ug', value: 'ug' },
  { label: 'Uzbek (Afghanistan): uz-AF', value: 'uz-AF' },
  { label: 'Uzbek (Arabic): uz', value: 'uz' },
  { label: 'Uzbek (Cyrillic, Uzbekistan): uz-UZ', value: 'uz-UZ' },
  { label: 'Vai (Liberia): vai-LR', value: 'vai-LR' },
  { label: 'Vai (Latin): vai', value: 'vai' },
  { label: 'Venetian (Italy): vec-IT', value: 'vec-IT' },
  { label: 'Venetian: vec', value: 'vec' },
  { label: 'Vietnamese (Vietnam): vi-VN', value: 'vi-VN' },
  { label: 'Vietnamese: vi', value: 'vi' },
  { label: 'Vunjo (Tanzania): vun-TZ', value: 'vun-TZ' },
  { label: 'Vunjo: vun', value: 'vun' },
  { label: 'Walser (Switzerland): wae-CH', value: 'wae-CH' },
  { label: 'Walser: wae', value: 'wae' },
  { label: 'Welsh (United Kingdom): cy-GB', value: 'cy-GB' },
  { label: 'Welsh: cy', value: 'cy' },
  {
    label: 'Western Frisian (Netherlands): fy-NL',
    value: 'fy-NL',
  },
  { label: 'Western Frisian: fy', value: 'fy' },
  { label: 'Wolof (Senegal): wo-SN', value: 'wo-SN' },
  { label: 'Wolof: wo', value: 'wo' },
  { label: 'Xhosa (South Africa): xh-ZA', value: 'xh-ZA' },
  { label: 'Xhosa: xh', value: 'xh' },
  { label: 'Yakut (Cyrillic, Russia): sah-RU', value: 'sah-RU' },
  { label: 'Yakut: sah', value: 'sah' },
  { label: 'Yangben (Cameroon): yav-CM', value: 'yav-CM' },
  { label: 'Yangben: yav', value: 'yav' },
  { label: 'Yiddish (Hebrew, Ukraine): yi-UA', value: 'yi-UA' },
  { label: 'Yiddish: yi', value: 'yi' },
  { label: 'Yoruba (Benin): yo-BJ', value: 'yo-BJ' },
  { label: 'Yoruba (Nigeria): yo-NG', value: 'yo-NG' },
  { label: 'Yoruba: yo', value: 'yo' },
  { label: 'Zarma (Niger): dje-NE', value: 'dje-NE' },
  { label: 'Zarma: dje', value: 'dje' },
  { label: 'Zhuang (China): za-CN', value: 'za-CN' },
  { label: 'Zhuang: za', value: 'za' },
  { label: 'Zulu (South Africa): zu-ZA', value: 'zu-ZA' },
  { label: 'Zulu: zu', value: 'zu' },
]
