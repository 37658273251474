import { TFunction } from 'i18next'

import { SelectAssetV2RowItem } from '@components/SelectAssetV2/SelectAssetV2'
import { RecordErrorsByOriginQuery } from '@graphql/types/microservice/crm-sync-types'
import { Query } from '@graphql/types/query-types'

import { FormManagerValues } from '../context/FormManager.context'

export const getPushErrorsCountFromResponse = (response?: RecordErrorsByOriginQuery): number => {
  return response?.recordErrorsByOrigin?.length ?? 0
}

export const getFormManagerValuesFromResponse = (response: Query['getForm']): Partial<FormManagerValues> => {
  if (!response) {
    return { formNotFound: true }
  }

  const {
    id: formId,
    isPaymentsEnabled: payments,
    list: { listId, listName, segmentId, segmentName, sendToActOnContacts, isActOnContacts, contactsCount },
    counts: { totalSubmits },
    embedCodes: embeds,
    expirationDate,
    capSignupsEnabled,
    capSignupsLimit,
    title,
    description,
    isClassic,
    landingPages,
    createdAt,
    updatedAt,
    connectedCrm,
    connectedWebinar,
    createdBy,
    lastUpdatedBy,
  } = response

  return {
    formId,
    payments,
    embeds: embeds.map((code) => ({
      ...code,
      id: code.embedCodeId,
      sourceHTML: code.sourceHtml ?? '',
      noCssHtml: code.noCssHtml ?? '',
      prefilledHtml: code.prefilledHtml ?? '',
      noCssAndPrefilledHtml: code.noCssAndPrefilledHtml ?? '',
    })),
    submissionDetails: {
      id: listId,
      name: listName,
      segmentId,
      segmentName,
      sentToAOC: sendToActOnContacts,
      contactCount: contactsCount,
      type: isActOnContacts ? 'segment' : 'list',
    },
    expirationDate,
    cappedSignups: {
      enabled: capSignupsEnabled ?? false,
      current: totalSubmits,
      total: capSignupsLimit ?? 0,
    },
    createdBy,
    lastUpdatedBy,
    title,
    description: description ?? '',
    isClassic,
    landingPages,
    createdAt,
    updatedAt: updatedAt ?? createdAt,
    connectedCrm: connectedCrm ?? '',
    connectedWebinar: connectedWebinar ?? '',
  }
}

export enum DownloadXLSOptions {
  DOWNLOAD_AS_XLS = 'download_as_csv',
  DOWNLOAD_AS_XLS_WITH_UPLOADED = 'download_as_csv_with_uploaded',
}

export const getModalRowItems = (t: TFunction): SelectAssetV2RowItem[] => {
  return [
    {
      title: t('FormManager.ExportModal.Download.XLS.Title'),
      description: t('FormManager.ExportModal.Download.XLS.Description'),
      name: DownloadXLSOptions.DOWNLOAD_AS_XLS,
    },
    {
      title: t('FormManager.ExportModal.Download.XLS.With.Uploaded.Title'),
      description: t('FormManager.ExportModal.Download.XLS.With.Uploaded.Description'),
      name: DownloadXLSOptions.DOWNLOAD_AS_XLS_WITH_UPLOADED,
    },
  ]
}
