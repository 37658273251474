import React, { FC, useState } from 'react'

import classNames from 'classnames'

import { LandingPageStatus } from '@complex/ListingPage/Utils/ListingPage.constants'
import Button, { ButtonType } from '@components/Button/Button'
import InfoHoverCard from '@components/InfoHoverCard/InfoHoverCard'
import InfoHoverHeader from '@components/InfoHoverHeader/InfoHoverHeader'
import { SvgNames } from '@components/Svg'
import Svg, { SvgType } from '@components/Svg/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { getStandardFormattedDate } from '@utils/date'
import { titleCase } from '@utils/strings'

import './LandingPagesInfoHover.css'

interface LandingPagesInfoHoverProps {
  name: string
  enabled: boolean
  description: string
  connectedForms: string[]
  createdOn: number
  createdBy: string
  lastUpdated: number
  updatedBy: string
  className?: string
  dataTest?: string
}

const CONNECTED_FORMS_EXPANDER_MIN_LENGTH = 2 // per discussion with design

const rootClass = 'landing-pages-info-hover'

const LandingPagesInfoHover: FC<LandingPagesInfoHoverProps> = (props: LandingPagesInfoHoverProps) => {
  const { name, description, enabled, connectedForms, createdOn, createdBy, lastUpdated, updatedBy, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()

  const [expandedConnectedForms, setExpandedConnectedForms] = useState(false)
  const longFormsList = connectedForms?.length > CONNECTED_FORMS_EXPANDER_MIN_LENGTH
  const isSingleConnectedForm = connectedForms?.length === 1

  const toggleExpandedConnectedForm = () => setExpandedConnectedForms(!expandedConnectedForms)

  const header = (
    <InfoHoverHeader
      title={<Typography text={name} weight={TextWeight.MEDIUM} type={TextType.BODY_TEXT_WHITE} className={classNames({ ['ellip-clamp']: true })} />}
      subtitle={
        <div className="flex-align-center">
          <Svg className="push-left" name={enabled ? SvgNames.circleGreen : SvgNames.circle} type={SvgType.SMALL_ICON} />
          <Typography
            text={t(titleCase(enabled ? LandingPageStatus.ENABLED : LandingPageStatus.DISABLED))}
            type={TextType.BODY_TEXT_WHITE_SMALL}
            tagProps={{ bold: { weight: TextWeight.MEDIUM, inline: true } }}
          />
        </div>
      }
    />
  )

  const renderList = (
    <div className={`${rootClass}__body-section`}>
      <Typography
        text={t(isSingleConnectedForm ? 'LandingPages.InfoHover.ConnectedForm' : 'LandingPages.InfoHover.ConnectedForms')}
        type={TextType.TABLE_HEADER}
        className={`${rootClass}__body-section-title`}
      />
      {!expandedConnectedForms
        ? connectedForms
            ?.filter((_, index) => index < CONNECTED_FORMS_EXPANDER_MIN_LENGTH)
            ?.map((form) => {
              return (
                <div className={`${rootClass}__body-section-line`} key={form}>
                  <Svg name={SvgNames.contacts} type={SvgType.ICON} />
                  <Typography text={form} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.BOLD} inline />
                </div>
              )
            })
        : connectedForms?.map((form) => {
            return (
              <div className={`${rootClass}__body-section-line`} key={form}>
                <Svg name={SvgNames.contacts} type={SvgType.ICON} />
                <Typography text={form} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.BOLD} inline />
              </div>
            )
          })}

      {longFormsList && (
        <Button buttonType={ButtonType.TEXT_TEAL} className={`${rootClass}__show-link`} onClick={toggleExpandedConnectedForm}>
          <Typography
            text={`${t(expandedConnectedForms ? 'Show less' : 'Show more')}`}
            type={TextType.NORMAL_TEXT_TEAL}
            weight={TextWeight.MEDIUM}
            inline
          />
        </Button>
      )}
    </div>
  )

  const footer = (
    <div className={`${rootClass}__body-section`}>
      <Typography text={t('History')} type={TextType.TABLE_HEADER} className={`${rootClass}__body-section-title`} />
      <div className={classNames(`${rootClass}__footer-section-line`, `${rootClass}__body-section-line-first`, 'ellip')}>
        <Typography
          text={`<medium>Created:</medium> {{date}} by {{createdBy}}`}
          values={{ date: getStandardFormattedDate(createdOn, true), createdBy }}
          tagProps={{ medium: { weight: TextWeight.MEDIUM } }}
          type={TextType.BODY_TEXT_SMALL}
          className={'ellip'}
          inline
        />
      </div>
      <div className={classNames(`${rootClass}__footer-section-line`, 'ellip')}>
        <Typography
          text={`<medium>Last updated:</medium> {{date}} by {{updatedBy}}`}
          values={{ date: getStandardFormattedDate(lastUpdated, true), updatedBy }}
          tagProps={{ medium: { weight: TextWeight.MEDIUM } }}
          type={TextType.BODY_TEXT_SMALL}
          className={'ellip'}
          inline
        />
      </div>
    </div>
  )

  const body = (
    <div>
      {!!connectedForms?.length && renderList}
      {footer}
    </div>
  )

  return (
    <InfoHoverCard
      header={header}
      description={description}
      sideOffset={2}
      openDelay={350}
      closeDelay={150}
      className={classNames(rootClass, className)}
      data-test={dataTest}
    >
      {body}
    </InfoHoverCard>
  )
}

export default LandingPagesInfoHover
