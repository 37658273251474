import React, { FC } from 'react'

import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import { useTranslation } from '@const/globals'

interface ProgramCurrentlyRunningModalProps {
  onCancel: VoidFunction
  onSubmit: VoidFunction
}

const rootClass = 'program-currently-running-modal'
const rootText = 'UpgradePrograms.Page.ReviewUpgrade.ProgramCurrentlyRunningModal.'

export const ProgramCurrentlyRunningModal: FC<ProgramCurrentlyRunningModalProps> = ({ onCancel, onSubmit }: ProgramCurrentlyRunningModalProps) => {
  const { t } = useTranslation()

  const onAnswer = (answer: YesNo) => {
    if (answer === YesNo.YES) {
      onSubmit()
    } else {
      onCancel()
    }
  }

  return (
    <ConfirmationModal
      isYesNo
      isOpen
      title={t(`${rootText}Title`)}
      body={t(`${rootText}Body`)}
      yesButtonText={t('OK')}
      onAnswer={onAnswer}
      className={rootClass}
    />
  )
}
