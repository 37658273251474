import { CRMStatusDisplayData } from '@complex/ContactSegments/SegmentInfoHoverCard/SegmentInfoHoverCard'
import { SvgNames } from '@components/Svg'
import { ItemType } from '@utils/categorization'
import {
  CRM_LIMIT_REACHED_WARNING,
  CRM_MISSING_ID_WARNING_INFO,
  CRM_MISSING_ID_WARNING_TITLE,
  CRM_ON_GOING_SYNC,
} from '@utils/contactSegments/contactSegments.utils'
import { Segment } from '@utils/contactSegments/contactSegments.utils'
import { CRM_STATUS } from '@utils/crm.utils'
import { NO_COLOR } from '@utils/tags'

export const segments = [
  {
    externalId: 'q-0001',
    name: 'Parent Segment',
    parent: undefined,
    subRows: [
      {
        externalId: 'q-0002',
        name: 'Sub-Segment 1',
        parent: 'q-0001',
        subRows: [
          {
            externalId: 'q-0003',
            name: 'Sub-Segment 2',
            parent: 'q-0002',
            subRows: [
              {
                externalId: 'q-0004',
                name: 'Sub-Segment 3',
                parent: 'q-0003',
              },
            ],
          },
        ],
      },
    ],
  },
] as Segment[]

export const segment: Segment = {
  id: 1,
  isEditable: true,
  isFavorite: false,
  externalId: 'q-0001',
  lastCountDate: '9/21/2021, 1:44:21 PM',
  name: 'Test Segment',
  parent: undefined,
  recordsCount: 10,
  tags: [{ color: NO_COLOR, name: 'Tag 1' }],
  type: 'Segment',
  updatedTime: '9/21/2021, 1:44:21 PM',
  position: 1,
  itemType: ItemType.SEGMENT,
}

export const crmSegment: Segment = {
  id: 1,
  isEditable: true,
  isFavorite: false,
  externalId: 'q-0001',
  lastCountDate: '9/21/2021, 1:44:21 PM',
  name: 'Test Segment',
  parent: undefined,
  recordsCount: 10,
  tags: [{ color: NO_COLOR, name: 'Tag 1' }],
  type: 'Segment',
  updatedTime: '9/21/2021, 1:44:21 PM',
  position: 1,
  itemType: ItemType.SEGMENT,
}

export const crmStatusObjects: { [key in CRM_STATUS]: CRMStatusDisplayData } = {
  [CRM_STATUS.SF_REPORT_ID_FIELD_MISSING]: {
    title: CRM_MISSING_ID_WARNING_TITLE,
    subtitle: CRM_MISSING_ID_WARNING_INFO,
    icon: SvgNames.fail,
  },
  [CRM_STATUS.SF_REPORT_MAX_RECORDS_REACHED]: {
    title: 'Salesforce contact limit reached',
    subtitle: CRM_LIMIT_REACHED_WARNING,
    helpLink: { text: 'More info', link: 'https://act-on.com' },
    icon: SvgNames.warningSolidNoFill,
  },
  [CRM_STATUS.SF_REPORT_NOT_FOUND]: {
    title: 'AddContactsFromCRM.SelectCRMSources.NotFound.Header',
    subtitle: 'AddContactsFromCRM.SelectCRMSources.NotFound.Content',
    icon: SvgNames.fail,
  },
  [CRM_STATUS.SF_REPORT_UNSUPPORTED_FORMAT]: {
    title: 'AddContactsFromCRM.SelectCRMSources.Unsupported.Header',
    subtitle: 'AddContactsFromCRM.SelectCRMSources.Unsupported.Content',
    icon: SvgNames.fail,
  },
  [CRM_STATUS.SF_REPORT_UNSUPPORTED_TYPE]: {
    title: 'AddContactsFromCRM.SelectCRMSources.Unsupported.Header',
    subtitle: 'AddContactsFromCRM.SelectCRMSources.Unsupported.Content',
    icon: SvgNames.fail,
  },
  [CRM_STATUS.CRM_SYNC_FAILED]: {
    title: 'AddContactsFromCRM.SelectCRMSources.Sync.Failed.Header',
    icon: SvgNames.fail,
  },
  [CRM_STATUS.ON_GOING_SYNC]: {
    title: CRM_ON_GOING_SYNC,
    icon: SvgNames.reloadNoFill,
  },
}
