import React from 'react'

import { TableColumn } from '@components/Table/Table'
import Typography, { TextType } from '@components/Typography/Typography'
import { getFormattedNumber } from '@utils/numbers'

const renderText = (value: string, disabled: boolean) => (
  <Typography text={value} type={disabled ? TextType.BODY_TEXT_LIGHT : TextType.BODY_TEXT} inline />
)

export const columns: TableColumn[] = [
  {
    Header: 'List/Segment',
    accessor: 'name',
    align: 'left',
    Cell: ({ cell: { value, row } }) => renderText(value, row.original?.disabled),
    className: 'list-picker-modal__info-hover',
    flexColumn: true,
  },
  {
    Header: 'Records',
    accessor: 'size',
    align: 'right',
    Cell: ({ cell: { value, row } }) => renderText(value !== undefined ? getFormattedNumber(value) : '', row.original?.disabled),
    className: 'list-picker-modal__records',
    maxWidth: 54,
  },
]
