import { IEntityContentJsonExtended } from '../../beeEditor/beeEditorTypes'

export const mockEmailBeeTemplate: IEntityContentJsonExtended = {
  page: {
    body: {
      container: { style: { 'background-color': '#ffffff' } },
      content: {
        // @ts-expect-error
        computedStyle: { linkColor: '#0068a5', messageBackgroundColor: 'transparent', messageWidth: '600px' },
        style: { color: '#000000', 'font-family': 'Arial, Helvetica Neue, Helvetica, sans-serif' },
      },
      type: 'mailup-bee-page-properties',
      webFonts: [],
    },
    description: '',
    rows: [
      {
        columns: [
          {
            'grid-columns': 12,
            modules: [],
            style: {
              'background-color': 'transparent',
              'border-bottom': '0px solid transparent',
              'border-left': '0px solid transparent',
              'border-right': '0px solid transparent',
              'border-top': '0px solid transparent',
              'padding-bottom': '5px',
              'padding-left': '0px',
              'padding-right': '0px',
              'padding-top': '5px',
            },
            uuid: 'de95d499-ebff-41f5-b2a3-b06b586939ca',
          },
        ],
        container: {
          style: {
            'background-color': 'transparent',
            'background-image': 'none',
            'background-position': 'top left',
            'background-repeat': 'no-repeat',
          },
        },
        content: {
          computedStyle: {
            hideContentOnDesktop: false,
            hideContentOnMobile: false,
            rowColStackOnMobile: true,
            rowReverseColStackOnMobile: false,
            // @ts-expect-error
            verticalAlign: 'top',
          },
          style: {
            'background-color': 'transparent',
            'background-image': 'none',
            'background-position': 'top left',
            'background-repeat': 'no-repeat',
            'border-bottom': '0px solid transparent',
            'border-left': '0px solid transparent',
            'border-radius': '0px',
            'border-right': '0px solid transparent',
            'border-top': '0px solid transparent',
            color: '#000000',
            width: '600px',
          },
        },
        empty: true,
        locked: false,
        rowInternal: {
          uid: 'emailHeaderBlockHandler',
          entity: 'Email Header',
          icon: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMSIgeT0iMSIgd2lkdGg9IjIyIiBoZWlnaHQ9IjIyIiByeD0iMyIgc3Ryb2tlPSIjNDQ0NDQ0IiBzdHJva2Utd2lkdGg9IjIiLz4KPHBhdGggZD0iTTQgOUM0IDkuNTUyMjggNC40NDc3MiAxMCA1IDEwSDE5QzE5LjU1MjMgMTAgMjAgOS41NTIyOCAyMCA5VjVDMjAgNC40NDc3MiAxOS41NTIzIDQgMTkgNEg1QzQuNDQ3NzIgNCA0IDQuNDQ3NzIgNCA1VjlaIiBmaWxsPSIjQzlDOUM5Ii8+Cjwvc3ZnPgo=',
          placeholder: 'Select an email header',
          ctaLabel: 'Browse headers',
          configurationUi: { contentDialogId: 'emailHeaderBlockHandler', external: null },
        },
        synced: false,
        // @ts-expect-error
        type: 'rowAddon',
        uuid: '4dcaa410-b46b-4fd7-bfe6-0ac1a186bfc0',
      },
      {
        container: {
          style: {
            'background-color': 'transparent',
            'background-image': 'none',
            'background-repeat': 'no-repeat',
            'background-position': 'top left',
          },
        },
        content: {
          style: {
            'background-color': 'transparent',
            color: '#000000',
            width: '500px',
            'background-image': 'none',
            'background-repeat': 'no-repeat',
            'background-position': 'top left',
            'border-top': '0px solid transparent',
            'border-right': '0px solid transparent',
            'border-bottom': '0px solid transparent',
            'border-left': '0px solid transparent',
            'border-radius': '0px',
          },
          computedStyle: {
            rowColStackOnMobile: true,
            rowReverseColStackOnMobile: false,
            // @ts-expect-error
            verticalAlign: 'top',
            hideContentOnMobile: false,
            hideContentOnDesktop: false,
          },
        },
        columns: [
          {
            style: {
              'background-color': 'transparent',
              'border-bottom': '0px solid transparent',
              'border-left': '0px solid transparent',
              'border-right': '0px solid transparent',
              'border-top': '0px solid transparent',
              'padding-bottom': '5px',
              'padding-left': '0px',
              'padding-right': '0px',
              'padding-top': '5px',
            },
            modules: [],
            'grid-columns': 12,
            uuid: 'af91a689-8833-471c-926b-b6dacb9cb711',
          },
        ],
        type: 'one-column-empty',
        uuid: '8e4f4f87-d906-4a47-b6fb-696249d80497',
      },
      {
        columns: [
          {
            'grid-columns': 12,
            modules: [],
            style: {
              'background-color': 'transparent',
              'border-bottom': '0px solid transparent',
              'border-left': '0px solid transparent',
              'border-right': '0px solid transparent',
              'border-top': '0px solid transparent',
              'padding-bottom': '5px',
              'padding-left': '0px',
              'padding-right': '0px',
              'padding-top': '5px',
            },
            uuid: '3943c014-1c02-429f-8b67-18ce451dcc77',
          },
        ],
        container: {
          style: {
            'background-color': 'transparent',
            'background-image': 'none',
            'background-position': 'top left',
            'background-repeat': 'no-repeat',
          },
        },
        content: {
          computedStyle: {
            hideContentOnDesktop: false,
            hideContentOnMobile: false,
            rowColStackOnMobile: true,
            rowReverseColStackOnMobile: false,
            // @ts-expect-error
            verticalAlign: 'top',
          },
          style: {
            'background-color': 'transparent',
            'background-image': 'none',
            'background-position': 'top left',
            'background-repeat': 'no-repeat',
            'border-bottom': '0px solid transparent',
            'border-left': '0px solid transparent',
            'border-radius': '0px',
            'border-right': '0px solid transparent',
            'border-top': '0px solid transparent',
            color: '#000000',
            width: '600px',
          },
        },
        empty: true,
        locked: false,
        rowInternal: {
          uid: 'emailFooterBlockHandler',
          entity: 'Email Footer',
          icon: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMSIgeT0iMSIgd2lkdGg9IjIyIiBoZWlnaHQ9IjIyIiByeD0iMyIgc3Ryb2tlPSIjNDQ0NDQ0IiBzdHJva2Utd2lkdGg9IjIiLz4KPHBhdGggZD0iTTQgMTlDNCAxOS41NTIzIDQuNDQ3NzIgMjAgNSAyMEgxOUMxOS41NTIzIDIwIDIwIDE5LjU1MjMgMjAgMTlWMTVDMjAgMTQuNDQ3NyAxOS41NTIzIDE0IDE5IDE0SDVDNC40NDc3MiAxNCA0IDE0LjQ0NzcgNCAxNVYxOVoiIGZpbGw9IiNDOUM5QzkiLz4KPC9zdmc+Cg==',
          placeholder: 'Select an email footer',
          ctaLabel: 'Browse footers',
          configurationUi: { contentDialogId: 'emailFooterBlockHandler', external: null },
        },
        synced: false,
        // @ts-expect-error
        type: 'rowAddon',
        uuid: '22b4ce41-12ea-4f9a-aa21-96f4dc978fa7',
      },
    ],
    template: { name: 'template-base', type: 'basic', version: '2.0.0' },
    title: '',
  },
  comments: {},
}
