import React, { FC, useContext, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Checkbox from '@components/Checkbox'
import Container from '@components/Container'
import { LinkTextButton } from '@components/LinkTextButton/LinkTextButton'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ExportContactsSteps } from '@src/pages/ExportContacts/utils/ExportContacts.constants'
import { ExportContactsContext } from '@src/pages/ExportContacts/utils/ExportContacts.context'
import { replaceElement } from '@utils/utils'

import './SelectExportFieldsScreen.css'

interface SelectExportFieldsScreenProps {
  className?: string
  dataTest?: string
}

const rootClass = 'select-export-fields-screen'

export const SelectExportFieldsScreen: FC<SelectExportFieldsScreenProps> = (props: SelectExportFieldsScreenProps) => {
  const { dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()

  const {
    values: { exportFields },
    update,
    handleOnSectionChange,
  } = useContext(ExportContactsContext)

  const [allSelected, setAllSelected] = useState<boolean>(false)

  useEffect(() => {
    setAllSelected(exportFields.every((field) => field.selected))
  }, [exportFields])

  const canMoveForward = useMemo(() => exportFields.some((field) => field.selected), [exportFields])

  const handleSelectAll = () => {
    update({
      exportFields: exportFields.map((field) => {
        return {
          ...field,
          selected: !allSelected,
        }
      }),
    })
    setAllSelected(!allSelected)
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Container>
        <div className={`${rootClass}__header`}>
          <Typography
            className={`${rootClass}__header-text`}
            text={t('ExportContacts.Sections.ChooseFields.Header', {
              selected: exportFields.filter((field) => field.selected).length,
              total: exportFields.length,
            })}
            tagProps={{ light: { type: TextType.BODY_TEXT_LIGHTEST_GRAY } }}
            weight={TextWeight.MEDIUM}
            lineHeight={LineHeight.TINY}
          />
          <LinkTextButton hideIcons text={t(allSelected ? 'Unselect all' : 'Select all')} onClick={handleSelectAll} />
        </div>
        <div className={`${rootClass}__content`}>
          {exportFields.map(({ field, selected }, index) => (
            <Checkbox
              key={field.columnIndex}
              label={field.displayName}
              checked={selected}
              onChange={(checked) => update({ exportFields: replaceElement(index, { ...exportFields[index], selected: checked }, exportFields) })}
            />
          ))}
        </div>
      </Container>
      <div className={`${rootClass}__button-next`}>
        <Button
          disabled={!canMoveForward}
          buttonType={ButtonType.PRIMARY}
          onClick={() => handleOnSectionChange(ExportContactsSteps.REVIEW_EXPORT, true)}
        >
          {t('Next')}
        </Button>
      </div>
    </div>
  )
}
