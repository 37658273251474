import React, { FC } from 'react'

import classNames from 'classnames'

import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './spinner.css'

export enum LoaderSize {
  XSMALL = 'XSMALL',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  XLARGE = 'XLARGE',
  CUSTOM = 'CUSTOM',
}

export interface SpinnerProps {
  text?: string
  headline?: string
  className?: string
  dataTest?: string
  size?: LoaderSize
  hideAdditionalText?: boolean
  unsizeWrapper?: boolean
  customSize?: React.CSSProperties
}

const rootClass = 'spinner'

const Spinner: FC<SpinnerProps> = (props: SpinnerProps) => {
  const { customSize, hideAdditionalText, text, headline, className, dataTest = rootClass, unsizeWrapper, size = LoaderSize.LARGE } = props
  const { t } = useTranslation()
  return (
    <div className={classNames(`${rootClass}__wrapper`, className, { [`${rootClass}__wrapper-unsize`]: unsizeWrapper })} data-test={dataTest}>
      <div className={`${rootClass}__svg--container`}>
        <svg
          className={classNames(`${rootClass}__svg`, {
            [`${rootClass}__svg--xsmall`]: size === LoaderSize.XSMALL,
            [`${rootClass}__svg--small`]: size === LoaderSize.SMALL,
            [`${rootClass}__svg--medium`]: size === LoaderSize.MEDIUM,
            [`${rootClass}__svg--large`]: size === LoaderSize.LARGE,
            [`${rootClass}__svg--xlarge`]: size === LoaderSize.XLARGE,
          })}
          style={customSize}
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8" cy="8" r="7"></circle>
        </svg>
      </div>
      {!hideAdditionalText && (
        <>
          {headline && (
            <Typography
              className={`${rootClass}__headline`}
              text={t(headline)}
              type={TextType.PAGE_HEADER}
              weight={TextWeight.BOLD}
              dataTest={`${dataTest}-headline`}
            />
          )}
          {text && <Typography className={`${rootClass}__text`} text={t(text)} dataTest={`${dataTest}-text`} />}
        </>
      )}
    </div>
  )
}

export default Spinner
