import React from 'react'

import { GraphQLError } from 'graphql'
import { TFunction } from 'i18next'

import { getFolderById } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { ListingPageItem, ListPageAPI, ListPageCommonState } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { FORM_COMPOSER_LINK } from '@complex/ListingPage/Utils/ListingPage.constants'
import { MenuItem } from '@components/DropDownActions/DropDownActions'
import { SegmentDetailsContainerState } from '@components/SegmentDetails/context/SegmentDetails.context'
import { Status } from '@components/StatusToast/StatusToast'
import { SvgNames } from '@components/Svg'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { rootContext } from '@const/globals'
import { OriginsWithErrorsQuery } from '@graphql/types/microservice/crm-sync-types'
import {
  FormSubmissionSummaryDto,
  GetListsRelatedFormsQuery,
  GetRecentFormSubmissionsQuery,
  GetRecentWebinarRegistrationsQuery,
  GetWebinarListsRelatedFormsQuery,
} from '@graphql/types/microservice/entity-join-types'
import { CloneSegmentMutation } from '@graphql/types/microservice/segment-types'
import { AccountSettings, ItemDto, LabelDto } from '@graphql/types/query-types'
import { goCreateSegment } from '@src/pages/ContactSegments/utils/ContactSegmentsContainerUtils'
import { goToFormReport } from '@src/pages/listingPages/Forms/utils/FormsListingPage.tables'
import {
  FORM_SUBMISSION_LIST_URL,
  FORMS_SUBMISSIONS_URL,
  FormsJoinViewListingPageContainerState,
  WEBINAR_SUBMISSION_LIST_URL,
} from '@src/pages/listingPages/FormsJoinView/FormsJoinViewListingPageContainer.constants'
import { DATA_PERIOD, dateRangeDropdownText } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.constants'
import { FormsJoinViewCustomModals } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.tables'
import { filterNotEmptyArray } from '@utils/array'
import { ItemType } from '@utils/categorization'
import { Contact } from '@utils/contact/contact.constants'
import { sendEmailToContact } from '@utils/contact/contact.utils'
import { getListDownloadLink, Segment } from '@utils/contactSegments/contactSegments.utils'
import { openFormBuilderWindow } from '@utils/forms'
import { logNewRelicError } from '@utils/new-relic.utils'
import { setItem } from '@utils/sessionStorage'
import { FetchPromise } from '@utils/types'
import { download } from '@utils/utils'

export type FormSubmissionList = ListingPageItem & {
  recordsCount: number
}

export type FormsJoinViewUpdate = (values: Partial<FormsJoinViewListingPageContainerState>) => void

export const onSendEmailClick = (selectedContacts: Contact[]) => {
  if (selectedContacts.length > 0) {
    const contactsIds = selectedContacts.map(({ recId }) => recId)
    sendEmailToContact(contactsIds.toString())
  }
}

export const onViewFormReport = ({ formId = '' }: FormSubmissionSummaryDto) => {
  goToFormReport({ formId, backURL: FORMS_SUBMISSIONS_URL })
}

export const onEditSegment = (externalId: string, currentUrl: string, options?: { search?: string; searchAll?: boolean }) => {
  const { search, searchAll } = { ...options }
  const searchTerm = search ? `?search=${search}${searchAll ? '&searchAll' : ''}` : ''
  const redirectUrl = encodeURIComponent(`${currentUrl}${searchTerm}`)
  window.open(`${rootContext}/classic/segment/${externalId}/${externalId}?back=${redirectUrl}`, '_self')
}

export const onEditForm = ({ formId }: FormSubmissionSummaryDto, hasReactWrapper?: boolean) => {
  openFormBuilderWindow(`editClassicForm-${formId}`, formId, '', hasReactWrapper, false)
}

export const goCreateForm = () => window.open(FORM_COMPOSER_LINK)

export const goCreateWebinar = () => window.location.assign(`${rootContext}/classic/if/webinar/listing.jsp`)

export const getPreviewMenuActions = ({ externalId }: ListingPageItem, hasReactWrapper?: boolean) => [
  { text: 'Edit form', icon: SvgNames.pencil, onClick: () => onEditForm({ formId: externalId }, hasReactWrapper) },
  { text: 'View form report', icon: SvgNames.zoom, onClick: () => onViewFormReport({ formId: externalId }) },
]

export const getDetailsPageMenuActions = (
  { externalId, parent, isEditable }: Segment,
  update: (values: Partial<SegmentDetailsContainerState>) => void,
  itemType: ItemType,
  { disableSegmentModifications, isUsingExternalSegmentationService }: AccountSettings
) => [
  {
    icon: SvgNames.pencil,
    text: 'Edit form',
    hidden: !!parent,
    onClick: () => update({ showCustomModal: true, customAction: FormsJoinViewCustomModals.EDIT_FORM }),
  },
  {
    disabled: disableSegmentModifications || !isEditable,
    hasTooltip: disableSegmentModifications || !isEditable,
    hidden: !parent,
    tooltipMessage: disableSegmentModifications ? 'Segment.Tooltip.Disabled.Migration' : 'System generated segments can not be edited',
    icon: SvgNames.pencil,
    text: 'Edit segment definition',
    onClick: () => {
      onEditSegment(externalId, window.location.pathname + window.location.search)
    },
  },
  {
    icon: SvgNames.graphBar,
    text: 'View form report',
    hidden: !!parent,
    onClick: () => update({ showCustomModal: true, customAction: FormsJoinViewCustomModals.VIEW_FORM_REPORT }),
  },
  {
    icon: SvgNames.createSubsegment,
    text: 'Create segment',
    hidden: isUsingExternalSegmentationService,
    disabled: disableSegmentModifications,
    hasTooltip: disableSegmentModifications,
    tooltipMessage: disableSegmentModifications ? 'Segment.Tooltip.Disabled.Migration' : undefined,
    onClick: () =>
      goCreateSegment(itemType === ItemType.WEBINAR_SUBMISSION ? WEBINAR_SUBMISSION_LIST_URL : FORM_SUBMISSION_LIST_URL, {
        parentId: externalId,
        ...(itemType === ItemType.WEBINAR_SUBMISSION ? { isWebinarRegistration: true } : { isFormSubmission: true }),
      }),
  },
  {
    icon: SvgNames.cancelSend,
    text: 'Clear records',
    hidden: !!parent || itemType === ItemType.WEBINAR_SUBMISSION,
    disabled: disableSegmentModifications,
    hasTooltip: disableSegmentModifications,
    tooltipMessage: disableSegmentModifications ? 'Segment.Tooltip.Disabled.Migration' : undefined,
    onClick: () => update({ showCustomModal: true, customAction: FormsJoinViewCustomModals.CLEAR_RECORDS }),
  },
]

export const getRecentRequestResultsUtils = async <T extends GetRecentFormSubmissionsQuery | GetRecentWebinarRegistrationsQuery>(
  getRecentRequest: (search: string, currentPage?: number) => FetchPromise<T>,
  search: string,
  currentPage?: number
) => {
  try {
    const { data } = await getRecentRequest(search, currentPage)
    let items: ItemDto[] = []
    if (data) {
      if ('getRecentWebinarRegistrations' in data && data.getRecentWebinarRegistrations) {
        items = data.getRecentWebinarRegistrations as ItemDto[]
      } else if ('getRecentFormSubmissions' in data && data.getRecentFormSubmissions) {
        items = data.getRecentFormSubmissions as ItemDto[]
      }
    }
    return { data: items }
  } catch (error) {
    logNewRelicError(error)
    return { data: [], error: 'Something went wrong on our end. Please try again.' }
  }
}

export const onDuplicateSegmentResponse = (
  data: CloneSegmentMutation | null | undefined,
  errors: readonly GraphQLError[] | undefined,
  newName: string,
  folderId: number | undefined,
  tags: LabelDto[],
  listingPageValues: ListPageCommonState,
  listingPageAPI: ListPageAPI,
  t: TFunction
) => {
  if (data) {
    const folderName = folderId ? getFolderById(folderId, listingPageValues.folders)?.name : undefined
    listingPageAPI.update({
      fetchFilterCounts: true,
      fetchFolders: !!folderId,
      fetchItems: true,
      fetchTags: tags.length > 0,
      statusToast: {
        statusMessage: (
          <Typography
            text={`ListPage.FormsJoinView.Duplicate.Success${folderId ? '.WithinFolder' : ''}`}
            values={{
              itemName: newName,
              folderName,
            }}
            tagProps={{ bold: { weight: TextWeight.BOLD } }}
            inline
          />
        ),
        status: Status.SUCCESS,
        showStatusToast: true,
      },
    })
  }
  if (errors) {
    listingPageAPI.update({
      statusToast: {
        statusMessage: t('ListPage.FormsJoinView.Duplicate.Fail'),
        status: Status.FAIL,
        showStatusToast: true,
      },
    })
  }
}

export const getDateRangeDropDownActions = (key: string, update: Function): MenuItem[] => [
  {
    text: dateRangeDropdownText[DATA_PERIOD.Week],
    onClick: () => {
      update({ days: DATA_PERIOD.Week })
      setItem(key, DATA_PERIOD.Week.toString())
    },
  },
  {
    text: dateRangeDropdownText[DATA_PERIOD.Quarter],
    onClick: () => {
      update({ days: DATA_PERIOD.Quarter })
      setItem(key, DATA_PERIOD.Quarter.toString())
    },
  },
  {
    text: dateRangeDropdownText[DATA_PERIOD.Half_Year],
    onClick: () => {
      update({ days: DATA_PERIOD.Half_Year })
      setItem(key, DATA_PERIOD.Half_Year.toString())
    },
  },
  {
    text: dateRangeDropdownText[DATA_PERIOD.Year],
    onClick: () => {
      update({ days: DATA_PERIOD.Year })
      setItem(key, DATA_PERIOD.Year.toString())
    },
  },
]

export const parseDataPeriod = (value: any) => {
  if (Object.values(DATA_PERIOD).includes(value)) {
    return value
  }
  return null
}

export const doExport = (selectedList: Segment, update: FormsJoinViewUpdate, t: Function, isZip?: boolean) => {
  if (selectedList.name && selectedList.externalId) {
    const downloadLink = getListDownloadLink(selectedList, isZip)
    download(downloadLink, selectedList.name)
    update({
      statusToast: {
        statusMessage: t(`ListPage.Common.Export`, { count: selectedList.recordsCount }),
        status: Status.SUCCESS,
        showStatusToast: true,
      },
    })
  }
}

export enum FormJoinViewSession {
  INCOMING_STATUS_TOAST = 'formJoinView:incomingStatusToast',
}

export const getCrmPushErrors = async (
  getCrmPushErrorsRequest: () => FetchPromise<OriginsWithErrorsQuery>,
  update: FormsJoinViewUpdate,
  t: Function
) => {
  const { data, errors } = await getCrmPushErrorsRequest()

  if (errors) {
    logNewRelicError(errors)
    update({
      statusToast: {
        statusMessage: t('Something went wrong on our end. Please try again.'),
        status: Status.FAIL,
        showStatusToast: true,
      },
    })
  }
  return data?.originsWithErrors ? data.originsWithErrors.filter(filterNotEmptyArray) : []
}

export const getListsRelatedForms = async (
  getListsRelatedFormsRequest: (listIds: string[]) => FetchPromise<GetListsRelatedFormsQuery>,
  listIds: string[],
  update: FormsJoinViewUpdate,
  t: Function
) => {
  const { data, errors } = await getListsRelatedFormsRequest(listIds)
  if (errors) {
    logNewRelicError(errors)
    update({
      statusToast: {
        statusMessage: t('Something went wrong on our end. Please try again.'),
        status: Status.FAIL,
        showStatusToast: true,
      },
    })
    return []
  }
  return data?.getListsRelatedForms || []
}

export const getWebinarListsRelatedForms = async (
  getListsRelatedFormsRequest: (webinarListIds: string[]) => FetchPromise<GetWebinarListsRelatedFormsQuery>,
  webinarListIds: string[],
  update: FormsJoinViewUpdate,
  t: Function
) => {
  const { data, errors } = await getListsRelatedFormsRequest(webinarListIds)
  if (errors) {
    logNewRelicError(errors)
    update({
      statusToast: {
        statusMessage: t('Something went wrong on our end. Please try again.'),
        status: Status.FAIL,
        showStatusToast: true,
      },
    })
    return []
  }
  return data?.getWebinarListsRelatedForms || []
}
