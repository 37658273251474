import { dateFormat, TooltipOptions } from 'highcharts'

import { ChartField } from '@const/Chart.constants'
import { getCountDisplay } from '@utils/numbers'

import { getTooltipDateRange, xAxisOtherUIGroupingKey } from '../../ChartSection/ChartSection.utils'

export const tooltip = (
  startTime: number,
  endTime: number,
  isColumnChart: boolean,
  isRevenue: boolean,
  isLeadSource: boolean,
  additionalData: ChartField[],
  otherData?: number[][]
): TooltipOptions => ({
  followPointer: true,
  useHTML: true,
  outside: true,
  shadow: false,
  distance: 0,
  hideDelay: 0.1,
  borderWidth: 0,
  borderRadius: 0,
  backgroundColor: 'none',
  padding: 12,
  headerFormat: '',
  pointFormatter: function (this): string {
    const isOther = this.category === xAxisOtherUIGroupingKey
    const noCategoryName = this.category === ''

    const headerTitle = isColumnChart ? (isOther ? 'Other' : this.category) : getTooltipDateRange(Number(this.category), startTime, endTime)

    const header = noCategoryName
      ? `<i style={{ lineHeight: 1.5, fontSize: 'small' }}>${isLeadSource ? 'No lead source' : 'No campaign type'}</i>`
      : ` <b style={{ lineHeight: 1.5, fontSize: 'small' }}>
        ${headerTitle} ${isOther && otherData ? `(${otherData[this.series.index].length})` : ''}
      </b>`

    return `
        <div 
            style="
                position: absolute;
                top: 40px;
                padding: 6px 12px;
                border-radius: 5px;
                border: 1px solid ${this.color};
                background-color: rgba(247, 247, 247, 0.85);
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
            "
        >
            <tspan style="color:${this.color}; font-size: larger;">&#x25CF;</tspan>
            ${header}<br>
            <b style="line-height: 1.5">${isRevenue ? '$' : ''}${getCountDisplay(Number(this.y))} ${this.series.name}</b></br>
            ${
              isColumnChart
                ? `<span style="line-height: 1.5">${dateFormat('%b %e, %Y', startTime)} - ${dateFormat('%b %e, %Y', endTime)}</span>`
                : `<span style="line-height: 1.5">
                    ${!isRevenue ? '$' : ''}${getCountDisplay(Number((additionalData[this.series.index].data as number[][])[this.index][1]))} 
                    ${additionalData[this.series.index].name}
                </span>`
            }
        </div>
      `
  },
})

export const yAxisLabels = (isRevenue: boolean) => ({
  formatter: function (this: any): string {
    return isRevenue ? `$${getCountDisplay(this.value)}` : getCountDisplay(this.value)
  },
})
