import { ActivityTypes } from '@components/ItemActivity/ItemActivity'
import { ItemType } from '@utils/categorization'

export const itemActivityMockSegment = {
  externalId: 'q-0001',
  id: 1234,
  isFavorite: false,
  position: 0,
  itemType: ItemType.SEGMENT,
  lastCountDate: '9/21/2021, 1:44:21 PM',
  name: 'Segment - 1',
  parent: undefined,
  recordsCount: 10,
  tags: [{ color: '#FF9F00', name: 'Tag 1' }],
  type: 'Segment',
  updatedTime: '9/21/2021, 1:44:21 PM',
  isEditable: true,
}

export const getLabelsResponse = [
  {
    color: '#949494',
    enabled: true,
    id: 474165,
    name: 'red_tag',
    itemCount: 1,
    __typename: 'LabelDTO',
  },
  {
    color: '#484EE7',
    enabled: true,
    id: 474136,
    name: 'blue_tag',
    itemCount: 1,
    __typename: 'LabelDTO',
  },
]

export const getFoldersResponse = [
  {
    createdTime: null,
    depth: 0,
    id: 1,
    itemCount: 0,
    name: 'folder 1',
    parentId: null,
    position: null,
    __typename: 'FolderDTO',
    subFolders: null,
  },
  {
    createdTime: null,
    depth: 0,
    id: 2,
    itemCount: 2,
    name: 'folder 2',
    parentId: null,
    position: null,
    __typename: 'FolderDTO',
    subFolders: null,
  },
]

export const getAllActivityResponse = {
  getActivityAudit: [
    {
      activityIdentifier: '108219',
      activityName: 'Segment Created',
      author: 'Storybook',
      authorId: 827,
      date: 1656942852459,
      type: 'CREATED',
      __typename: 'SegmentActivityDTO',
    },
  ],
  getActivityAutomatedPrograms: [
    {
      activityIdentifier: 'tr-020a',
      activityName: 'Testing wrong data entry',
      author: 'Zoom Test Integration',
      authorId: 827,
      date: 1674505515717,
      type: 'AUTOMATED_PROGRAMS',
      __typename: 'SegmentActivityDTO',
    },
    {
      activityIdentifier: 'tr-005f',
      activityName: 'Fake activity',
      author: 'Storybook',
      authorId: 827,
      date: 1659378538353,
      type: 'AUTOMATED_PROGRAMS',
      __typename: 'SegmentActivityDTO',
    },
  ],
  getActivityMessages: [
    {
      date: 1611524223000,
      activityName: 'Conference X Follow-Up_Thanks For Attending',
      activityIdentifier: 's-0102-2202',
      author: 'Jeremy Fox',
      authorId: 1,
      type: 'MESSAGE' as ActivityTypes,
    },
  ],
  getActivitySMS: [
    {
      date: 1611724043000,
      activityName: 'Conference X SMS-reminder',
      activityIdentifier: '528cb4a9-522a-461b-b8b1-186747479dc6',
      author: 'Jeremy Fox',
      authorId: 1,
      type: 'SMS' as ActivityTypes,
    },
  ],
  getActivityImport: [
    {
      date: 1611524143000,
      activityName: 'the-list.csv',
      activityIdentifier: '545',
      author: 'Jeremy Fox',
      authorId: 1,
      type: 'IMPORT' as ActivityTypes,
    },
  ],
}

export const getSendableContactsResponse = {
  date: 1680619198036,
  description: null,
  externalId: null,
  percentageValue: 100,
  value: 3,
  values: null,
  __typename: 'StatValueDTO',
}

export const cardsOptionsMock = {
  cardsTitle: 'Performance',
  cardsSubTitle: '',
  cards: [
    {
      label: 'Total Contacts',
      labelContent: {
        primary: '164',
      },
    },
    {
      label: 'Sendable Contacts',
      labelContent: {
        primary: '84%',
        secondary: '96',
      },
    },
  ],
}

export const getAllItemsStatsMock = {
  data: {
    getStatSendableContacts: {
      value: 96,
      percentageValue: 84,
    },
    getStatEngagedContacts: {
      value: 96,
      percentageValue: 64,
    },
    getStatAverageLeadScore: {
      value: 15,
    },
  },
}
