import { gql } from '@apollo/client'

export default gql`
  query hasTestData($webhookId: String!) {
    hasTestData(webhookId: $webhookId) {
      hasResults
      statusCode
      message
      request {
        headers
        body
      }
      response {
        headers
        body
      }
    }
  }
`
