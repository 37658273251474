import React, { FC } from 'react'

import Typography, { TextType, TextWeight } from '@components/Typography/Typography'

import { useTemplateCatalogModalContentContext } from '../../TemplateCatalogModal.context'

const rootClass = 'template-catalog-modal-content__search-results'

interface TemplateCatalogModalSearchResultsProps {
  dataTest?: string
}

const TemplateCatalogModalSearchResults: FC<TemplateCatalogModalSearchResultsProps> = ({ dataTest = rootClass }) => {
  const { searchText, catalogItems, templatesLoading } = useTemplateCatalogModalContentContext()

  if (!searchText || templatesLoading) {
    return null
  }

  return (
    <div className={rootClass} data-test={dataTest}>
      <Typography
        text="AssetPicker.Search.Results"
        values={{ count: catalogItems.length, searchTerm: searchText }}
        type={TextType.SECTION_HEADER}
        weight={TextWeight.BOLD}
      />
    </div>
  )
}
export default TemplateCatalogModalSearchResults
