import React, { FC, ReactNode, RefObject, UIEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  ActionType,
  Column,
  Row,
  TableInstance,
  TableState,
  useExpanded,
  useFlexLayout,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'

import classNames from 'classnames'
import equal from 'fast-deep-equal/es6/react'

import {
  getColumnProps,
  getRowSelectedReducerActionState,
  selectOrDeselectSubRows,
} from '@components/ActionableNestedTable/ActionableNestedTable.utils'
import NestedTableRow, { renderExpanderCell } from '@components/ActionableNestedTable/components/NestedTableRow/NestedTableRow'
import NestedTableRowWithDnD, { Item } from '@components/ActionableNestedTable/components/NestedTableRowWithDnD/NestedTableRowWithDnD'
import CaretIcon, { CaretIconDirection } from '@components/CaretIcon'
import ContentExpander from '@components/ContentExpander/ContentExpander'
import Loader from '@components/Loader'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import TableActionHeader from '@components/Table/components/TableActionHeader'
import { getColumnAlignClass } from '@components/Table/components/tableColumns'
import { HeaderAction, RowAction, TableColumn } from '@components/Table/Table'
import scrollbarWidth from '@components/Table/utils/scrollbarWidth'
import { getCheckboxColumnUtils, renderCheckboxUtils, showHeaderActionsUtils, toggleSelectedRowsUtils } from '@components/Table/utils/TableUtils'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import { ItemType } from '@utils/categorization'
import { usePrevious } from '@utils/hooks/usePrevious'
import { isFunction } from '@utils/utils'

import './ActionableNestedTable.css'

interface HeaderProps {
  disableCheckboxHeader?: boolean
  fixedHeader?: boolean
  headerActions?: HeaderAction[]
  useHeaderCheckbox?: boolean
}

interface RowsProps {
  customRowFinder?: (rows: Row[], row: Row) => Row
  defaultSelectedRows?: any[]
  initiallyExpandAll?: boolean
  hasToExpand?: (row: Row) => boolean
  onRowClicked?: (row: Row<any>) => void
  onRowExpanded?: (row: Row) => void
  onRowSelectionChanged?: (selectedRows: Row[], rows?: Row[], selectedRowsIds?: any) => void
  onRowsSort?: (selectedRows: Row[], droppedOnRow: Row, above: boolean, parentRowId?: string) => void
  rowActions?: RowAction[]
  selectSubRows?: boolean
}

interface TagsProps {
  defaultTagsNumber?: number
  onApplyAndRemoveTags?: (rows: number[], tagsToApply: LabelDto[], tagsToRemove: number[]) => void
  onCreateTag?: (tag: LabelDto) => void
  readOnlyTags?: boolean
  selectedTagId?: number
  tagAction?: (name: string) => void
  tags?: LabelDto[]
}

interface DragAndDropProps {
  canDrop?: boolean | ((row: Row, item: Item, itemType: string, isOverTop: boolean) => boolean)
  hasDragDrop?: boolean
  itemsType?: ItemType
}

interface CheckboxProps {
  checkboxCellOverride?: (row: Row) => ReactNode
  checkboxLabel?: string
  checkboxWidth?: number
  disabledCheckboxTooltipText?: string
  isSingleSelect?: boolean
  /** When true, the selectedRowIds state will automatically reset if the data changes */
  autoResetSelectedRows?: boolean
  /** Array of dependencies that, if any of them changes, the selectedRowIds state will reset. */
  resetSelectedRowsOnChange?: any[]
  forceResetSelectedRows?: boolean
  useCheckboxes?: boolean
}

export type CustomSubRowSelectionType = (row: Row) => boolean

export interface ActionableNestedTableProps extends HeaderProps, RowsProps, TagsProps, DragAndDropProps, CheckboxProps {
  data: any[]
  columns: TableColumn[]
  onColumnSort?: (sortBy: Array<any>) => void
  initialState?: any
  className?: string
  dataTest?: string
  hasOverflow?: boolean
  hasExpander?: boolean
  loading?: boolean
  reference?: RefObject<HTMLDivElement>
  onBodyScroll?: UIEventHandler<HTMLDivElement> | undefined
  emptyText?: string
  emptyTextIcon?: SvgNames
  emptyTextIconTooltipText?: string
  manualSortBy?: boolean
  disableMultiSort?: boolean
  disableSortRemove?: boolean
  enableSort?: boolean
  scrollFromPageContainer?: boolean
  expandHeaderActions?: boolean
  customSubRowSelection?: CustomSubRowSelectionType
}

enum TableReducerAction {
  DEFAULT = 'default',
  DESELECT_ALL = 'deselectAllRows',
  TOGGLE_ROW_SELECTED = 'toggleRowSelected',
}

const rootClass = 'actionable-nested-table'

/**
 * @deprecated use <TableV2 instead
 */
const ActionableNestedTable: FC<ActionableNestedTableProps> = (props: ActionableNestedTableProps) => {
  const {
    data,
    columns,
    onColumnSort,
    checkboxCellOverride,
    checkboxLabel,
    checkboxWidth = 54,
    customRowFinder,
    defaultSelectedRows,
    disabledCheckboxTooltipText,
    disableCheckboxHeader,
    emptyText,
    emptyTextIcon,
    emptyTextIconTooltipText,
    initiallyExpandAll = false,
    headerActions = [],
    initialState,
    isSingleSelect = false,
    forceResetSelectedRows = false,
    onApplyAndRemoveTags,
    onBodyScroll,
    onCreateTag,
    onRowClicked,
    onRowSelectionChanged,
    onRowsSort,
    canDrop,
    rowActions = [],
    readOnlyTags,
    selectedTagId,
    tags,
    tagAction,
    defaultTagsNumber,
    useCheckboxes = false,
    useHeaderCheckbox = false,
    dataTest = rootClass,
    className = '',
    fixedHeader = false,
    hasOverflow = false,
    hasExpander = true,
    loading = false,
    reference,
    hasDragDrop = false,
    onRowExpanded,
    hasToExpand,
    autoResetSelectedRows = true,
    resetSelectedRowsOnChange = [],
    itemsType,
    manualSortBy = true,
    disableMultiSort = true,
    disableSortRemove = true,
    enableSort = false,
    expandHeaderActions = false,
    customSubRowSelection,
    selectSubRows = false,
  } = props

  const { t } = useTranslation()

  const initiallyExpandAllRef = useRef(initiallyExpandAll)
  const toggleSelectedRows = () => {
    toggleSelectedRowsUtils(selectedRowIds, toggleAllRowsSelected)
  }

  const renderCheckboxHeader = ({ getToggleAllRowsSelectedProps }: any) =>
    renderCheckboxUtils(
      useHeaderCheckbox,
      getToggleAllRowsSelectedProps,
      forceDeselectAll() ? () => dispatch({ type: TableReducerAction.DESELECT_ALL }) : toggleAllRowsSelected,
      checkboxLabel,
      disableCheckboxHeader
    )

  const CheckboxColumn = getCheckboxColumnUtils(
    renderCheckboxHeader,
    useHeaderCheckbox,
    false,
    rootClass,
    checkboxCellOverride,
    checkboxWidth,
    disabledCheckboxTooltipText
  )

  let checkboxColumn: TableColumn[] = []
  if (useCheckboxes && !columns.find((col) => col.accessor === 'selection')) {
    checkboxColumn = [CheckboxColumn]
  }

  const expanderCol = {
    accessor: 'expander',
    Header: columns.length !== 0 ? columns[0].Header : '',
    align: 'left',
    flexColumn: true,
    Cell: (row: any) => renderExpanderCell(row.row, columns, onRowClicked, onRowExpanded),
  }

  const stateReducer = (state: TableState, action: ActionType, previousState: TableState, instance?: TableInstance) => {
    const actions: { [key in TableReducerAction]: TableState } = {
      [TableReducerAction.DESELECT_ALL]: { ...state, selectedRowIds: {} },
      [TableReducerAction.DEFAULT]: state,
      [TableReducerAction.TOGGLE_ROW_SELECTED]: getRowSelectedReducerActionState(state, action, isSingleSelect),
    }
    if (customSubRowSelection && action.type === TableReducerAction.TOGGLE_ROW_SELECTED) {
      selectOrDeselectSubRows(state, previousState, instance as TableInstance)
    }
    return actions[(action.type as TableReducerAction) ?? TableReducerAction.DEFAULT] ?? state
  }

  const enhancedColumns = useMemo(() => (hasExpander ? [...checkboxColumn, expanderCol, ...columns] : [...checkboxColumn, ...columns]), [columns])
  const tableInstance = useTable(
    {
      columns: enhancedColumns as Column<any>[],
      data,
      initialState,
      selectSubRows,
      autoResetExpanded: true,
      autoResetSelectedRows,
      stateReducer,
      manualSortBy,
      disableMultiSort,
      disableSortRemove,
    },
    useSortBy,
    useFlexLayout,
    useExpanded,
    usePagination,
    useRowSelect
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state: { sortBy, selectedRowIds },
    toggleAllRowsSelected,
    toggleRowSelected,
    selectedFlatRows,
    dispatch,
    toggleAllRowsExpanded,
  } = tableInstance
  const rawTableProps = getTableProps()

  const prevSelectedRowIds = usePrevious(selectedRowIds)

  useEffect(() => {
    if (sortBy && onColumnSort) {
      const sortColumn = columns.find((col) => col.accessor === sortBy[0].id)
      const fieldType = sortColumn?.fieldType ?? 'string'
      onColumnSort([{ ...sortBy[0], fieldType }])
    }
  }, [sortBy])

  useEffect(() => {
    if (initiallyExpandAll) {
      toggleAllRowsExpanded(true)
    }
  }, [initiallyExpandAll])

  const forceDeselectAll = useCallback(() => {
    const getDisabledRowsAmount = (rows: Row[]): number => {
      const disabledAmount = rows.reduce((curr, row) => curr + ((row as Row<any>).original.disabled ? 1 : 0), 0)
      const subRows = rows.flatMap(({ subRows }) => subRows)
      return disabledAmount + (!!subRows.length ? getDisabledRowsAmount(subRows) : 0)
    }
    const disabledAmount = getDisabledRowsAmount(rows.filter(({ depth }) => depth === 0))
    const selectedAmount = rows.reduce((curr, row) => curr + (row.isSelected ? 1 : 0), 0)
    return !!disabledAmount && !!selectedAmount && rows.length <= disabledAmount + selectedAmount
  }, [rows, selectedRowIds])

  useEffect(() => {
    if (onRowSelectionChanged && ((isSingleSelect && !equal(prevSelectedRowIds, selectedRowIds)) || !isSingleSelect)) {
      onRowSelectionChanged(selectedFlatRows, rows, selectedRowIds)
    }
  }, [selectedRowIds])

  useEffect(() => {
    if (customRowFinder && defaultSelectedRows && defaultSelectedRows?.length > 0) {
      defaultSelectedRows.forEach((row) => {
        if (customSubRowSelection && customSubRowSelection(row)) {
          return
        }
        const rowFound = customRowFinder(rows, row)
        rowFound && toggleRowSelected(rowFound.id, true)
      })
    }
  }, [toggleRowSelected, defaultSelectedRows])

  useEffect(() => {
    if (resetSelectedRowsOnChange.length > 0 || forceResetSelectedRows) {
      dispatch({ type: TableReducerAction.DESELECT_ALL })
    }
  }, [...resetSelectedRowsOnChange, forceResetSelectedRows])

  const [headOverflow, setHeadOverflow] = useState<number>(0)
  const [showButtons, setShowButtons] = useState(expandHeaderActions)

  useEffect(() => {
    if (expandHeaderActions) {
      const filteredRows = rows.filter((row) => row.canExpand)
      setShowButtons(expandHeaderActions && filteredRows.length > 0)
    }
  }, [rows])

  const bodyRef = useCallback(
    (body: HTMLDivElement) => {
      if (body) {
        const { clientHeight, scrollHeight } = body
        setHeadOverflow(scrollHeight > clientHeight ? scrollbarWidth() : 0)
      }
    },
    [data]
  )

  const showHeaderActions = showHeaderActionsUtils(selectedRowIds, headerActions)

  const renderRows = () => {
    const initiallyExpandAll = initiallyExpandAllRef.current
    initiallyExpandAllRef.current = false
    return rows.map((row, i) => {
      prepareRow(row)
      if ((row as Row<any>)?.original.disabled && row.isSelected) {
        row.toggleRowSelected(false)
      }
      const nextRowDepthDifference = rows[i].depth - (rows[i + 1]?.depth ?? 0)
      const previousRowDepthDifference = rows[i].depth - (rows[i - 1]?.depth ?? 0)
      if (row.canExpand && !row.isExpanded && (hasToExpand?.(row) || initiallyExpandAll)) {
        row.toggleRowExpanded()
      }

      const nestedTableRowProps = {
        row,
        selectedTagId,
        columns,
        disabledCheckboxTooltipText,
        onRowClicked,
        onApplyAndRemoveTags,
        onCreateTag,
        rowActions,
        readOnlyTags,
        tags,
        tagAction,
        defaultTagsNumber,
        nextRowDepthDifference,
        previousRowDepthDifference,
        onRowExpanded,
        hasExpander,
      }

      return hasDragDrop && onRowsSort && canDrop ? (
        <NestedTableRowWithDnD
          dataTest={`${dataTest}__row-with-dnd`}
          key={`tr-${i}`}
          nestedTableRowProps={nestedTableRowProps}
          selectedRows={selectedFlatRows}
          onRowsSort={onRowsSort}
          canDropCallback={isFunction(canDrop) ? canDrop : () => canDrop}
          toggleAllRowsSelected={toggleAllRowsSelected}
          itemType={itemsType}
        />
      ) : (
        <NestedTableRow dataTest={`${dataTest}__row`} key={`tr-${i}`} {...nestedTableRowProps} />
      )
    })
  }
  return (
    <div
      className={classNames(rootClass, className, { [`${rootClass}__has-overflow`]: hasOverflow })}
      {...rawTableProps}
      data-test={dataTest}
      ref={reference}
    >
      {showButtons && <ContentExpander className={`${rootClass}__extra-options`} onChange={toggleAllRowsExpanded} />}
      <div
        style={{ paddingRight: `${headOverflow}px` }}
        className={classNames(`${rootClass}__thead`, {
          [`${rootClass}__thead-fixed`]: fixedHeader,
        })}
        data-test={`${dataTest}__thead`}
      >
        {headerGroups.map((headerGroup, i) => {
          if (showHeaderActions) {
            return (
              <div key={i} className={'table'}>
                <TableActionHeader
                  actions={headerActions}
                  count={Object.keys(selectedRowIds).length}
                  key="TableActionHeader"
                  onChange={toggleSelectedRows}
                  total={rows.length}
                  {...headerGroup}
                />
              </div>
            )
          }
          return (
            <div className={`${rootClass}__trh`} {...headerGroup.getHeaderGroupProps()} key={`trh-${i}`}>
              {headerGroup.headers
                .filter((header) => (hasExpander ? columns.length !== 0 && header.id !== columns[0].accessor : true))
                .map((header, idx) => {
                  const Header = typeof header.Header === 'string' ? t(header.Header) : header.render('Header')
                  return (
                    <div
                      {...getColumnProps(header, header.getHeaderProps(header.getSortByToggleProps()))}
                      tabIndex={i}
                      key={`trh-${i}-${idx}`}
                      className={classNames(`th`, getColumnAlignClass(columns, header.id, false))}
                      title={undefined}
                    >
                      <Typography text={Header} type={TextType.TABLE_HEADER} />
                      {header.isSorted && enableSort && (
                        <div
                          aria-live="polite"
                          aria-label={`Sorted by header ${header.Header} in ${header.isSortedDesc ? 'descending' : 'ascending'} order`}
                          className={classNames(`${rootClass}__sort-div`)}
                        >
                          {header.isSortedDesc ? (
                            <span className={classNames(`${rootClass}__sort-span`)}>
                              <CaretIcon direction={CaretIconDirection.DOWN} />
                            </span>
                          ) : (
                            <span className={classNames(`${rootClass}__sort-span`)}>
                              <CaretIcon direction={CaretIconDirection.UP} />
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  )
                })}
            </div>
          )
        })}
      </div>
      <div
        ref={bodyRef}
        onScroll={onBodyScroll}
        className={classNames(`${rootClass}__body`, { [`${rootClass}__body-loading`]: loading })}
        {...getTableBodyProps()}
      >
        {loading ? (
          <Loader />
        ) : emptyText && !rows.length ? (
          <div className={`${rootClass}__empty-text-container`}>
            <Typography
              className={`${rootClass}__empty-text`}
              dataTest={`${dataTest}__empty-text`}
              text={emptyText}
              type={TextType.BODY_TEXT_LIGHT}
              weight={TextWeight.ITALIC}
            />
            {!!emptyTextIcon && !!emptyTextIconTooltipText && (
              <Tooltip
                trigger={<Svg name={emptyTextIcon} type={SvgType.LARGER_ICON} />}
                triggerClassName={`${dataTest}__empty-text-icon-tooltip-trigger`}
              >
                {t(emptyTextIconTooltipText)}
              </Tooltip>
            )}
          </div>
        ) : (
          renderRows()
        )}
      </div>
    </div>
  )
}

export default ActionableNestedTable
