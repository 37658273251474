import React, { FC, Fragment, useMemo } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Checkbox from '@components/Checkbox'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import { HeaderAction } from '@components/TableV2/tableV2TS/interfaces'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { toTrainCase } from '@utils/strings'
import { isFunction } from '@utils/utils'

import './FloatingActionHeader.css'

interface FloatingActionHeaderProps {
  className?: string
  dataTest?: string
  headerActionCountText: string
  actions: HeaderAction[]
}

const rootClass = 'floating-action-header'

export const FloatingActionHeader: FC<FloatingActionHeaderProps> = (props: FloatingActionHeaderProps) => {
  const { dataTest = rootClass, className = '', headerActionCountText, actions } = props
  const { t } = useTranslation()

  const renderAction = ({ disabled = false, label: labelAction, icon: iconAction, hasTooltip, onClick: onClickItem }: HeaderAction) => {
    const label = isFunction(labelAction) ? labelAction() : labelAction
    const isDisabled = isFunction(disabled) ? disabled() : disabled
    const icon = (
      <Svg
        className={classNames(`${rootClass}__actions-icon`, { [`${rootClass}__actions-icon-disabled`]: isDisabled })}
        name={iconAction as SvgNames}
        type={SvgType.LARGER_ICON}
        fill={SvgColor.WHITE}
      />
    )
    const onClick = !isDisabled ? onClickItem : undefined
    const button = (
      <Button
        className={classNames(`${rootClass}__actions-button`, { [`${rootClass}__actions-button-disabled`]: isDisabled })}
        disabled={isDisabled}
        buttonType={ButtonType.TABLE_ACTION}
        onClick={onClick}
        dataTest={`${dataTest}-${toTrainCase(label)}-button`}
      >
        {icon}
      </Button>
    )
    return <Fragment key={label}>{hasTooltip ? <Tooltip trigger={button}>{t(label)}</Tooltip> : button}</Fragment>
  }

  const renderActions = useMemo(
    () =>
      actions.map((action) => {
        return renderAction(action)
      }),
    [actions]
  )

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__total-count`}>
        <div className={`${rootClass}__checkbox`}>
          <Checkbox withoutLabel checked />
        </div>
        <Typography text={headerActionCountText} type={TextType.BODY_TEXT_WHITE} weight={TextWeight.MEDIUM} dataTest="header-action-count-text" />
      </div>
      <div className={`${rootClass}__actions`}>{renderActions}</div>
    </div>
  )
}

export default FloatingActionHeader
