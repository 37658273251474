import React, { ComponentProps, FC, useContext, useMemo } from 'react'

import { ProgramRecordSetStep } from '@graphql/types/query-types'
import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'
import {
  BaseList,
  UpgradedProgramRecordSetStep,
  UpgradedSource,
  UpgradedStepCondition,
  UpgradeManagerContext,
} from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'
import { getSource } from '@src/pages/programs/UpgradePrograms/components/ReviewUpgrade/sections/FixStepIssuesSection/components/ReviewCurrentStep/ReviewCurrentStep.utils'
import { ReviewSourceReplacement } from '@src/pages/programs/UpgradePrograms/components/ReviewUpgrade/sections/FixStepIssuesSection/components/ReviewCurrentStep/steps/components/ReviewSourceReplacement/ReviewSourceReplacement'

type ReviewSourceReplacementProps = Omit<ComponentProps<typeof ReviewSourceReplacement>, 'source'>

interface ReviewChangeFieldValueSourceUpgradeProps extends ReviewSourceReplacementProps {
  step: UpgradedProgramRecordSetStep
  condition: UpgradedStepCondition
  conditionIndex: number
}

const ReviewChangeFieldValueSourceUpgrade: FC<ReviewChangeFieldValueSourceUpgradeProps> = (props: ReviewChangeFieldValueSourceUpgradeProps) => {
  const { step, condition, conditionIndex, ...rest } = props

  const {
    values: { upgradedStepSources, stepsToUpgrade },
  } = useContext(UpgradeManagerContext)

  const {
    values: {
      program: { sources },
    },
  } = useContext(ProgramManagerContext)

  const source: UpgradedSource = useMemo(() => {
    const upgradedSource: BaseList | undefined = upgradedStepSources[step.stepId ?? '']?.[`source-${condition.conditionId}`]
    const originalStep = stepsToUpgrade.find(({ stepId }) => stepId === step.stepId) as ProgramRecordSetStep | undefined
    const originalCondition = originalStep?.conditions?.[conditionIndex]
    const originalConditionSource = sources.find(({ id }) => id === originalCondition?.srcId)
    return getSource(originalConditionSource, upgradedSource)
  }, [condition.conditionId, conditionIndex, sources, step.stepId, stepsToUpgrade, upgradedStepSources])

  return <ReviewSourceReplacement source={source} {...rest} />
}

export default ReviewChangeFieldValueSourceUpgrade
