import { SvgNames } from '@components/Svg'
import { ExecutionStatus } from '@utils/contactSegments/contactSegments.utils'

export const FtpTooltipStatusIcons: { [key: string]: SvgNames } = {
  [ExecutionStatus.Failed]: SvgNames.inputStatusInvalidNoFill,
  [ExecutionStatus.Processed]: SvgNames.reloadNoFill,
  [ExecutionStatus.Processing]: SvgNames.reloadNoFill,
  [ExecutionStatus.Pending]: SvgNames.reloadNoFill,
  [ExecutionStatus.Cancelled]: SvgNames.reloadNoFill,
}
