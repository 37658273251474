import React, { FC, useMemo } from 'react'

import classNames from 'classnames'

import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import ModalFooterV2, { ModalFooterV2ViewSelectedProps } from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import { SvgNames } from '@components/Svg'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

interface ImagePickerModalFooterProps {
  onInsert: () => void
  onCancel: () => void
  onPreview: () => void
  isPreview: boolean
  isReplaceFlow: boolean
  disableReplace: boolean
  selectedTitle?: string
  dataTest?: string
}

const MIN_ELIDED_LENGTH = 110
const rootClass = 'image-picker-modal__footer'

const ImagePickerModalFooter: FC<ImagePickerModalFooterProps> = ({
  onInsert,
  onCancel,
  onPreview,
  isPreview,
  isReplaceFlow,
  disableReplace,
  selectedTitle,
  dataTest = rootClass,
}) => {
  const { t } = useTranslation()

  const forceTooltip = useMemo(() => (selectedTitle?.length ?? '') > MIN_ELIDED_LENGTH, [selectedTitle])
  const viewSelected: ModalFooterV2ViewSelectedProps | undefined = !isPreview
    ? {
        noSelection: !selectedTitle,
        noSelectionText: t('No image selected'),
        icon: SvgNames.zoom,
        label: t('Preview selection'),
        currentText: (
          <TextWithTooltipOnEllip
            typographyProps={{
              text: 'Bold.Name.Selected',
              type: TextType.BODY_TEXT_SMALL_LIGHT,
              tagProps: { bold: { weight: TextWeight.BOLD, inline: true, lineHeight: LineHeight.MEDIUM_SMALL } },
              values: { name: selectedTitle },
              dataTest: `${dataTest}-selected-title`,
            }}
            tooltipProps={{ children: selectedTitle }}
            forceTooltip={forceTooltip}
            className={`${rootClass}__preview-selected-text`}
          />
        ),
        onClick: onPreview,
        isViewSelected: !isPreview,
      }
    : undefined

  return (
    <>
      <ModalFooterV2
        className={classNames(rootClass, {
          [`${rootClass}-forceTooltip`]: forceTooltip,
        })}
        footerType={ModalFooterType.Form}
        viewSelected={viewSelected}
        buttons={{
          cancelButtonLabel: t(isPreview ? 'Back' : 'Cancel'),
          actionButtonLabel: t(isReplaceFlow ? 'Replace' : 'Insert'),
          actionButtonOnClick: onInsert,
          actionButtonDisabled: (!selectedTitle && !isPreview) || disableReplace,
        }}
        onClose={onCancel}
      />
    </>
  )
}

export default ImagePickerModalFooter
