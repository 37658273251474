import React from 'react'

import { t } from 'i18next'

import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import { EnhancedColWithTitle, MutedCol } from '@components/Table/components/tableColumns'
import { TableColumn } from '@components/Table/Table'
import { TableListRow } from '@components/TableList/TableList'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { ColWithTooltip, EnhancedColWithTitle as EnhancedColWithTitleV2, renderMutedColV2 } from '@components/TableV2/utils/tableV2ColumnUtils'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { RecipientsPerCreditMultiplierResponse } from '@graphql/types/query-types'
import SegmentedMessage from '@src/pages/sms/edit/utils/segmentationCalculator'
import { CellContext } from '@tanstack/react-table'
import { Message } from '@utils/sms.utils'

import { rootClass } from '../listing/components/ScheduledMessages/ScheduledMessages'

export const OPT_IN_PERSONALIZATION = '{{={{_TIME(20)}}}}'

export const MIN_HOUR_DIFFERENCE = 60 * 60 * 1000

const MAX_COUNTRY_CHARS_TO_SHOW = 36

interface GetCreditsByCreditMultiplierCellsParams {
  recipientsPerCreditMultiplier: RecipientsPerCreditMultiplierResponse[]
  segmentedMessage: SegmentedMessage
  t: (text: string) => string
  useMinus?: boolean
  availableCredits?: string
  needsEmphasis?: boolean
  rootClass?: string
}

export const getCreditsByCreditMultiplierCells = ({
  recipientsPerCreditMultiplier,
  t,
  availableCredits,
  needsEmphasis,
  useMinus,
  segmentedMessage,
  rootClass,
}: GetCreditsByCreditMultiplierCellsParams): TableListRow[] => {
  const getCountriesToShow = (countries: string[]) => {
    const countriesToShow = {
      countries: [] as string[],
      otherCountries: [] as string[],
      others: 0,
    }

    return countries.reduce((acc: typeof countriesToShow, curr) => {
      const withCurrCountryLength = [...acc.countries, curr].join().length
      if (withCurrCountryLength > MAX_COUNTRY_CHARS_TO_SHOW) {
        return {
          ...acc,
          otherCountries: acc.otherCountries.concat([curr]),
          others: acc.others + 1,
        }
      } else {
        return {
          ...acc,
          countries: [...acc.countries, curr],
        }
      }
    }, countriesToShow)
  }

  const availableCreditsRow =
    availableCredits === undefined
      ? []
      : [
          {
            left: <Typography text={t('Available Credits')} type={TextType.BODY_TEXT_SMALL} />,
            right: <Typography text={availableCredits} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.MEDIUM} />,
          },
        ]

  const recipientRows = recipientsPerCreditMultiplier.map((multiplier) => {
    const countriesRaw = [...multiplier.contactsPerCountry]
      .sort((countryA, countryB) => (countryA.contacts > countryB.contacts ? -1 : 1))
      .map((country) => {
        const isUnknown = country.country === 'unknown'
        return isUnknown ? 'Unknown' : country.country
      })

    const countriesToShow = getCountriesToShow(countriesRaw)

    const otherText = `${countriesToShow.others} ${t(countriesToShow.others === 1 ? 'other' : 'others')}`
    const countries = (countriesToShow.others > 0 ? [...countriesToShow.countries, otherText] : countriesToShow.countries).join(', ')
    const countriesTitle = countriesToShow.others > 0 ? countriesToShow.otherCountries.join(', ') : undefined

    const creditsType = needsEmphasis
      ? {
          type: TextType.EMPHASIZED_TEXT_TEAL,
        }
      : { type: TextType.BODY_TEXT_SMALL, weight: TextWeight.MEDIUM }

    const creditsRow = multiplier.contacts * segmentedMessage.segments.length * multiplier.creditMultiplier

    const renderCountriesLine = () => {
      return countriesToShow.others > 0 ? (
        <Tooltip trigger={<Typography text={countries} type={TextType.BODY_TEXT_TINY} />}>{countriesTitle}</Tooltip>
      ) : (
        <Typography text={countries} type={TextType.BODY_TEXT_TINY} />
      )
    }

    const countriesLine = countries.length > 0 && creditsRow > 0 ? renderCountriesLine() : null

    return {
      left: (
        <div>
          <Typography
            className={rootClass ? `${rootClass}__section-subtitle` : ''}
            text={`${multiplier.contacts * segmentedMessage.segments.length} ${t('messages')} x ${multiplier.creditMultiplier} ${t(
              multiplier.creditMultiplier === 1 ? 'credit' : 'credits'
            )}`}
            type={TextType.BODY_TEXT_SMALL}
            weight={TextWeight.MEDIUM}
          />
          {countriesLine}
        </div>
      ),
      right: <Typography text={`${useMinus && creditsRow > 0 ? '-' : ''}${creditsRow}`} {...creditsType} />,
      isTeal: true,
    }
  })

  return [...availableCreditsRow, ...recipientRows]
}

interface GetCreditsWhenInternationalDisabledParams {
  availableCredits?: string
  breakdownText: string
  requiredCredits?: string
  useMinus?: boolean
  needsEmphasis?: boolean
  t: (text: string) => string
}

export const getCreditsWhenInternationalDisabled = ({
  availableCredits,
  breakdownText,
  requiredCredits,
  useMinus,
  needsEmphasis,
  t,
}: GetCreditsWhenInternationalDisabledParams): TableListRow[] => {
  const availableCreditsRow: any =
    availableCredits === undefined
      ? []
      : [
          {
            left: <Typography text={t('Available Credits')} type={TextType.BODY_TEXT_SMALL} />,
            right: <Typography text={availableCredits} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.MEDIUM} />,
          },
        ]

  const requiredType = needsEmphasis
    ? {
        type: TextType.EMPHASIZED_TEXT_TEAL,
      }
    : { type: TextType.BODY_TEXT_SMALL, weight: TextWeight.MEDIUM }

  const requiredCreditsRow = {
    left: (
      <div>
        <Typography text={t('Total Credits required for this Send')} type={TextType.BODY_TEXT_SMALL} />
        <Typography text={breakdownText} type={TextType.BODY_TEXT_TINY} />
      </div>
    ),
    right: <Typography text={`${useMinus && parseInt(requiredCredits ?? '0') > 0 ? '-' : ''}${requiredCredits}`} {...requiredType} />,
    isTeal: true,
  }

  return [...availableCreditsRow, requiredCreditsRow]
}

const extraTitleContent = (row: any) => {
  const extraContent = row.row.original.status === 'SENDING' ? <Loader loaderType={LoaderTypes.row} /> : <></>
  return <EnhancedColWithTitle row={row} extraContent={extraContent} />
}

const extraTitleContentV2 = (cell: CellContext<Message, unknown>) => {
  const extraContent = cell.row.original.status === 'SENDING' ? <Loader loaderType={LoaderTypes.row} /> : <></>
  return <EnhancedColWithTitleV2 cell={cell} extraContent={extraContent} />
}

const renderMutedCol = (row: any) => <MutedCol row={row} checkCol={'status'} checkVal={'SENDING'} />

const paddingLeftRight = { left: 0, right: 6 }

const scheduleHeaderWithTooltip = (
  <div className={`${rootClass}__header-flex-cell`}>
    <Typography text={t('Scheduled For')} type={TextType.TABLE_HEADER} inline />
    <Tooltip align="start" trigger={<Svg name={SvgNames.info} type={SvgType.ICON} fill={SvgColor.LIGHT_GRAY} />}>
      {t('Scheduled.For.Tooltip')}
    </Tooltip>
  </div>
)

export const columns: TableColumn[] = [
  {
    Header: 'Title',
    accessor: 'title',
    align: 'left',
    Cell: (row: any) => extraTitleContent(row),
    flexColumn: true,
    sortType: 'caseInsensitive',
  },
  {
    Header: 'Send Date',
    accessor: 'sentAt',
    Cell: (row: any) => renderMutedCol(row),
    align: 'left',
    minWidth: 88,
    maxWidth: 88,
    sortType: 'statusDateTime',
  },
  {
    Header: 'Sent By',
    accessor: 'userFriendlyName',
    Cell: (row: any) => renderMutedCol(row),
    align: 'left',
    minWidth: 111,
    maxWidth: 111,
    sortType: 'caseInsensitive',
  },
  {
    Header: 'Sent',
    accessor: 'recipientCount',
    Cell: (row: any) => renderMutedCol(row),
    align: 'right',
    minWidth: 53,
    maxWidth: 53,
    sortType: 'alphanumeric',
  },
  {
    Header: 'Clicked',
    accessor: 'clickedCount',
    Cell: (row: any) => renderMutedCol(row),
    align: 'right',
    minWidth: 64,
    maxWidth: 64,
    sortType: 'alphanumeric',
  },
  {
    Header: 'Replied',
    accessor: 'respondedCount',
    Cell: (row: any) => renderMutedCol(row),
    align: 'right',
    minWidth: 69,
    maxWidth: 69,
    sortType: 'alphanumeric',
  },
  {
    Header: 'Failed',
    accessor: 'bouncedCount',
    Cell: (row: any) => renderMutedCol(row),
    align: 'right',
    minWidth: 62,
    maxWidth: 62,
    sortType: 'alphanumeric',
  },
  {
    Header: 'Opted Out',
    accessor: 'optedOutCount',
    Cell: (row: any) => renderMutedCol(row),
    align: 'right',
    minWidth: 84,
    maxWidth: 84,
    sortType: 'alphanumeric',
  },
]

export const sentMessageColumn: ColumnDefWithAdditionalProps<Message, unknown>[] = [
  {
    header: 'Title',
    accessorKey: 'title',
    textAlign: 'left',
    sortingFn: 'caseInsensitive',
    cell: (cell) => extraTitleContentV2(cell),
  },
  {
    header: 'Send Date',
    accessorKey: 'sentAt',
    textAlign: 'left',
    sortingFn: 'statusDateTime',
    minSize: 88,
    padding: paddingLeftRight,
    cell: (cell) => renderMutedColV2(cell),
  },
  {
    header: 'Sent By',
    accessorKey: 'userFriendlyName',
    textAlign: 'left',
    sortingFn: 'caseInsensitive',
    maxSize: 111,
    padding: paddingLeftRight,
    cell: (cell) => renderMutedColV2(cell),
  },
  {
    header: 'Sent',
    accessorKey: 'recipientCount',
    textAlign: 'right',
    sortingFn: 'alphanumeric',
    maxSize: 53,
    padding: paddingLeftRight,
    cell: (cell) => renderMutedColV2(cell),
  },
  {
    header: 'Clicked',
    accessorKey: 'clickedCount',
    textAlign: 'right',
    sortingFn: 'alphanumeric',
    maxSize: 64,
    padding: paddingLeftRight,
    cell: (cell) => renderMutedColV2(cell),
  },
  {
    header: 'Replied',
    accessorKey: 'respondedCount',
    textAlign: 'right',
    sortingFn: 'alphanumeric',
    maxSize: 69,
    padding: paddingLeftRight,
    cell: (cell) => renderMutedColV2(cell),
  },
  {
    header: 'Failed',
    accessorKey: 'bouncedCount',
    textAlign: 'right',
    sortingFn: 'alphanumeric',
    maxSize: 62,
    padding: paddingLeftRight,
    cell: (cell) => renderMutedColV2(cell),
  },
  {
    header: 'Opted Out',
    accessorKey: 'optedOutCount',
    textAlign: 'right',
    sortingFn: 'alphanumeric',
    maxSize: 108,
    padding: { left: 0 },
    cell: (cell) => renderMutedColV2(cell),
  },
]

export const scheduledMessagesColumns: ColumnDefWithAdditionalProps<Message>[] = [
  {
    header: 'Title',
    accessorKey: 'title',
    textAlign: 'left',
    className: `scheduled-messages__title-col`,
    sortingFn: 'caseInsensitive',
    cell: (cell) => ColWithTooltip({ cell }),
  },
  {
    header: 'Scheduled By',
    accessorKey: 'userFriendlyName',
    textAlign: 'left',
    maxSize: 170,
    sortingFn: 'caseInsensitive',
    padding: paddingLeftRight,
  },
  {
    header: () => scheduleHeaderWithTooltip,
    enableFlexCell: true,
    accessorKey: 'scheduledSendTime',
    textAlign: 'left',
    maxSize: 190,
    sortingFn: 'datetime',
    padding: paddingLeftRight,
  },
  {
    header: 'Timezone',
    accessorKey: 'scheduleTimeZone',
    textAlign: 'left',
    maxSize: 116,
    sortingFn: 'caseInsensitive',
    padding: paddingLeftRight,
  },
  {
    header: 'Recipients',
    accessorKey: 'recipientCount',
    textAlign: 'right',
    maxSize: 105,
    sortingFn: 'alphanumeric',
  },
]

export const draftMessagesTableColumns: ColumnDefWithAdditionalProps<Message>[] = [
  {
    header: 'Title',
    accessorKey: 'title',
    textAlign: 'left',
    sortingFn: 'caseInsensitive',
    enableCustomCellValue: true,
    cell: (cell) => {
      const value = cell.getValue<string>()
      const title = value === 'Untitled' ? <em>{value}</em> : value
      return (
        <ColWithTooltip
          cell={cell}
          typographySpecifiedTitle={title}
          weight={TextWeight.MEDIUM}
          type={TextType.BODY_TEXT_GRAY}
          tooltipProps={{ inline: false }}
        />
      )
    },
  },
  {
    header: 'LAST EDIT',
    accessorKey: 'lastModifiedTime',
    textAlign: 'left',
    maxSize: 170,
    sortingFn: 'statusDateTime',
    padding: paddingLeftRight,
  },
  {
    header: 'LAST EDITED BY',
    accessorKey: 'userFriendlyName',
    textAlign: 'left',
    maxSize: 140,
    sortingFn: 'caseInsensitive',
  },
]
