import React, { FC, LegacyRef } from 'react'
import AceEditor, { IAceOptions, IEditorProps } from 'react-ace'
import ReactAce from 'react-ace'

import 'ace-builds/src-noconflict/ace'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/mode-html'
import 'ace-builds/src-noconflict/mode-javascript'
import 'ace-builds/src-noconflict/theme-github'
import 'ace-builds/src-noconflict/theme-xcode'
import 'ace-builds/webpack-resolver'
import classNames from 'classnames'

import { configureAce } from '@utils/aceConfigHelper'

import './Ace.css'

configureAce()

export interface AceProps {
  content: string
  mode: string
  theme: string
  onCodeChange: (code: string) => void
  height?: string
  width?: string
  fontSize?: number
  options?: IAceOptions
  editorProps?: IEditorProps
  aceRef?: LegacyRef<ReactAce>
  className?: string
  scrollMargin?: number[]
  name?: string
}

const rootClass = 'ace'

export const Ace: FC<AceProps> = (props: AceProps) => {
  const {
    content,
    mode,
    theme,
    onCodeChange,
    options,
    editorProps,
    aceRef,
    className = rootClass,
    scrollMargin,
    width,
    height,
    fontSize,
    name = 'ACE_EDITOR',
  } = props

  return (
    <AceEditor
      ref={aceRef}
      className={classNames(className, rootClass)}
      mode={mode}
      theme={theme}
      onChange={onCodeChange}
      value={content}
      name={name}
      editorProps={{ ...editorProps }}
      setOptions={{
        ...options,
      }}
      fontSize={fontSize}
      scrollMargin={scrollMargin}
      width={width}
      height={height}
    />
  )
}
