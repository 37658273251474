import { ActivityTypes, CustomActivityTypes } from '@components/ItemActivity/ItemActivity'
import addTag from '@graphql/microservices/categorization/addTag'
import applyTags from '@graphql/microservices/categorization/applyTags'
import getFolders from '@graphql/microservices/categorization/getFolders'
import getTags from '@graphql/microservices/categorization/getTags'
import moveItems from '@graphql/microservices/categorization/moveItems'
import removeTags from '@graphql/microservices/categorization/removeTags'
import getActivityListMaintenancePrograms from '@graphql/microservices/segment/getActivityListMaintenancePrograms'
import getAllActivity from '@graphql/microservices/segment/getAllActivity'
import {
  AddTagMutation,
  AddTagMutationVariables,
  ApplyTagsMutation,
  ApplyTagsMutationVariables,
  GetFoldersQuery,
  GetFoldersQueryVariables,
  GetTagsQuery,
  GetTagsQueryVariables,
  LabelDtoInput,
  MoveItemsMutation,
  MoveItemsMutationVariables,
  RemoveTagsMutation,
  RemoveTagsMutationVariables,
} from '@graphql/types/microservice/categorization-types'
import {
  GetActivityListMaintenanceProgramsQuery,
  GetActivityListMaintenanceProgramsQueryVariables,
  SegmentActivityDto,
} from '@graphql/types/microservice/segment-types'
import { ItemType } from '@utils/categorization'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

interface ItemActivityQueriesResponse {
  getAllActivitiesRequest: (segmentId: string) => Promise<SegmentActivityDto[]>
  getTagsRequest: (itemType: ItemType) => FetchPromise<GetTagsQuery>
  moveItemsIntoFolderRequest: (itemsIds: number[], folderId: number | null, itemType: ItemType) => FetchPromise<MoveItemsMutation>
  createTagsRequest: (tag: LabelDtoInput, itemType: ItemType) => FetchPromise<AddTagMutation>
  applyTagsRequest: (itemId: number, tags: LabelDtoInput[], itemType: ItemType) => FetchPromise<ApplyTagsMutation>
  removeTagsRequest: (itemId: number, tagsToRemove: number[], itemType: ItemType) => FetchPromise<RemoveTagsMutation>
  getFoldersRequest: (itemType: ItemType) => FetchPromise<GetFoldersQuery>
  getActivitiesRequest: (id: string) => Promise<SegmentActivityDto[]>
}

const DEFAULT_PAGE_SIZE = 50
const DEFAULT_PAGE = 0
export const useGetItemActivityQueries = (): ItemActivityQueriesResponse => {
  const { client: segmentClient } = useMicroserviceClient({ serviceName: MicroserviceClients.SEGMENT })
  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })
  const getAllActivitiesRequest = (segmentId: string, type: ItemType = ItemType.SEGMENT) =>
    segmentClient
      .query({
        query: getAllActivity,
        fetchPolicy: 'network-only',
        variables: {
          segmentId,
          type,
          pageSize: DEFAULT_PAGE_SIZE,
          pageNumber: DEFAULT_PAGE,
        },
      })
      .then(({ data }) => [
        ...(data.getActivityAudit as SegmentActivityDto[]),
        ...(data.getActivityAutomatedPrograms as SegmentActivityDto[]),
        ...(data.getActivityMessages as SegmentActivityDto[]),
        ...(data.getActivitySMS as SegmentActivityDto[]),
        ...(data.getActivityImport as SegmentActivityDto[]),
      ])

  const getTagsRequest = (itemType: ItemType) =>
    categorizationClient.query<GetTagsQuery, GetTagsQueryVariables>({
      query: getTags,
      variables: {
        types: [itemType],
      },
    })

  const moveItemsIntoFolderRequest = (itemsIds: number[], folderId: number | null, itemType: ItemType) =>
    categorizationClient.mutate<MoveItemsMutation, MoveItemsMutationVariables>({
      mutation: moveItems,
      variables: {
        folderId,
        itemIds: itemsIds,
        type: itemType,
      },
    })

  const createTagsRequest = (tag: LabelDtoInput, itemType: ItemType) =>
    categorizationClient.mutate<AddTagMutation, AddTagMutationVariables>({
      mutation: addTag,
      variables: {
        tag,
        type: itemType,
      },
    })

  const applyTagsRequest = (item: number, tags: LabelDtoInput[], itemType: ItemType) =>
    categorizationClient.mutate<ApplyTagsMutation, ApplyTagsMutationVariables>({
      mutation: applyTags,
      variables: {
        items: [item],
        labels: tags,
        type: itemType,
      },
    })

  const removeTagsRequest = (item: number, tagsToRemove: number[], itemType: ItemType) =>
    categorizationClient.mutate<RemoveTagsMutation, RemoveTagsMutationVariables>({
      mutation: removeTags,
      variables: {
        items: [item],
        labels: tagsToRemove,
        type: itemType,
      },
    })

  const getFoldersRequest = (itemType: ItemType) =>
    categorizationClient.query<GetFoldersQuery, GetFoldersQueryVariables>({
      query: getFolders,
      fetchPolicy: 'network-only',
      variables: {
        type: itemType,
      },
    })

  const getListMaintenanceActivities = (segmentId: string): Promise<SegmentActivityDto[]> => {
    return segmentClient
      .query<GetActivityListMaintenanceProgramsQuery, GetActivityListMaintenanceProgramsQueryVariables>({
        query: getActivityListMaintenancePrograms,
        fetchPolicy: 'network-only',
        variables: {
          segmentId,
          pageSize: DEFAULT_PAGE_SIZE,
          pageNumber: DEFAULT_PAGE,
        },
      })
      .then(({ data }) => data.getActivityListMaintenancePrograms as SegmentActivityDto[])
  }

  const getActivitiesRequest = (segmentId: string, type: ItemType = ItemType.SEGMENT) =>
    Promise.all([getAllActivitiesRequest(segmentId, type), getListMaintenanceActivities(segmentId)]).then(
      ([allActivitiesResponse, listMaintenanceActivitiesResponse]) => [
        ...allActivitiesResponse.filter((object) => {
          return !(object.type && object.type.toUpperCase() === ActivityTypes.CUSTOM && object.activityName === CustomActivityTypes.SEGMENT_CREATED)
        }),
        ...listMaintenanceActivitiesResponse,
      ]
    )

  return {
    getAllActivitiesRequest,
    getTagsRequest,
    moveItemsIntoFolderRequest,
    createTagsRequest,
    applyTagsRequest,
    removeTagsRequest,
    getFoldersRequest,
    getActivitiesRequest,
  }
}
