import { Message } from '@interface/messages/Message'
import listsUtils from '@utils/lists'

import { Props, State } from './MessagePicker'

export interface MessagePickerUtil {
  handleListChanged(message: Message, checked: boolean): void
  handleCollapseFolder(folderId: string, collapse: boolean): void
  isLoading(isProgramMessagePicker: boolean): boolean
  isError(): boolean
  state(): State
  props(): Props
}

export const getMessagePickerUtil = (props: Props, state: State, setState: (state: State) => void): MessagePickerUtil => ({
  handleListChanged: function (message: Message, checked: boolean) {
    if (checked) {
      if (props.multiSelect) {
        const { selectedMessages } = props
        selectedMessages.push(message)
        props.updateSelectedMessages([...new Set(selectedMessages)])
      } else {
        props.updateSelectedMessages([message])
      }
    } else {
      if (props.multiSelect) {
        props.updateSelectedMessages(props.selectedMessages.filter((selectedMessage) => selectedMessage.id !== message.id))
      } else {
        props.updateSelectedMessages([])
      }
    }
  },
  handleCollapseFolder: function (folderId: string, collapse: boolean) {
    setState({
      ...state,
      collapsedFolders: listsUtils.handleCollapse(folderId, collapse, state.collapsedFolders),
    })
  },
  isLoading: function (isProgramMessagePicker: boolean) {
    if (props.folders && props.folders.loading) {
      return true
    }
    if (props.messages) {
      if (isProgramMessagePicker || Object.keys(props.messages).includes('loading')) {
        return props.messages.loading || false
      }
      return props.messages.messages === undefined
    }
    return false
  },
  isError: function () {
    return props.messages?.error ?? props.folders?.error ?? false
  },
  state: function () {
    return state
  },
  props: function () {
    return props
  },
})
