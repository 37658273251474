import { gql } from '@apollo/client'

export default gql`
  query getContacts($isSubmissionList: Boolean, $page: PageInput) {
    getContacts(isSubmissionList: $isSubmissionList, page: $page) {
      contacts
      hasNextPage
      headers
      id
      pageNumber
      pageSize
      rowIds
      search
      totalCount
    }
  }
`
