import { Dispatch, SetStateAction } from 'react'

import { Status } from '@components/StatusToast/StatusToast'
import { rootContext } from '@const/globals'
import {
  DeleteSubscriptionManagementVersionMutation,
  SubscriptionManagementDtoInput,
  UpsertSubscriptionManagementVersionMutation,
} from '@graphql/types/mutation-types'
import { GetAvailableLanguagesQuery, GetSubscriptionManagementVersionsQuery } from '@graphql/types/query-types'
import { AddEditVersionModalState } from '@src/pages/SubscriptionManagement/components/AddEditVersionModal/AddEditVersionModal'
import {
  errorStatusToast,
  SubscriptionManagementDTO,
  successStatusToast,
  VersionState,
} from '@src/pages/SubscriptionManagement/SubcriptionManagement.constants'
import { SubscriptionManagementState } from '@src/pages/SubscriptionManagement/SubscriptionManagement'
import { filterNotEmptyArray } from '@utils/array'
import { logNewRelicError } from '@utils/new-relic.utils'
import { FetchPromise } from '@utils/types'

export const initSubscriptionManagementVersions = async (
  getSubscriptionManagement: () => FetchPromise<GetSubscriptionManagementVersionsQuery>,
  setState: Dispatch<SetStateAction<SubscriptionManagementState>>
) => {
  const { data, errors } = await getSubscriptionManagement()
  if (data) {
    const availableLanguages = data?.getSubscriptionManagementVersions || []
    const filteredAndMappedVersions: SubscriptionManagementDTO[] = availableLanguages.filter(filterNotEmptyArray).map((item) => {
      let status: VersionState
      if (!item.isEnabled) {
        status = VersionState.DISABLED
      } else if (item.needsChanges) {
        status = VersionState.ADDED
      } else {
        status = VersionState.ENABLED
      }
      return {
        name: item.name ?? '',
        description: item.description ?? '',
        version: item.language ?? '',
        created: item.createdTime ?? 0,
        status,
        default: !!item.isDefault,
        isEnabled: item.isEnabled,
      }
    })
    setState((state) => ({ ...state, versions: filteredAndMappedVersions, loading: false }))
  } else if (errors) {
    logNewRelicError(errors)
    setState((state) => ({
      ...state,
      statusToast: errorStatusToast,
      loading: false,
    }))
  }
}

export const initSubscriptionManagementLanguages = async (
  getSMAvailableLanguages: () => FetchPromise<GetAvailableLanguagesQuery>,
  setState: Dispatch<SetStateAction<SubscriptionManagementState>>
) => {
  const { data, errors } = await getSMAvailableLanguages()
  if (data) {
    const availableLanguages = data?.getAvailableLanguages || []
    const filteredLanguages = availableLanguages
      .filter((language) => language !== undefined && language.value !== undefined && language.label !== undefined)
      .map((language) => ({ value: language!.value!, label: language!.label! }))
    setState((state) => ({ ...state, languages: filteredLanguages }))
  } else if (errors) {
    logNewRelicError(errors)
    setState((state) => ({
      ...state,
      statusToast: errorStatusToast,
      loadingStatus: false,
    }))
  }
}

export const upsertSM = (
  upsertSMVersion: (subscriptionManagementVersion: SubscriptionManagementDtoInput) => FetchPromise<UpsertSubscriptionManagementVersionMutation>,
  setState: Function,
  version: SubscriptionManagementDtoInput
) => {
  upsertSMVersion(version)
    .then((response) => {
      const data = response.data?.upsertSubscriptionManagementVersion
      if (data?.errorMessage) {
        setState((state: AddEditVersionModalState) => ({
          ...state,
          statusToast: { statusMessage: data?.errorMessage, status: Status.FAIL, showStatusToast: true },
          loadingStatus: false,
        }))
      } else {
        setState((state: SubscriptionManagementState) => ({ ...state }))
        let param
        if (data?.language) {
          param = version ? '?languageVersion=' + data?.language : ''
        } else {
          param = version ? '?languageVersion=' + data?.name : ''
        }
        window.open(`${rootContext}/classic/if/optinout/settings.jsp` + param, '_self')
      }
    })
    .catch((e) => {
      logNewRelicError(e)
      setState((state: SubscriptionManagementState) => ({
        ...state,
        statusToast: errorStatusToast,
        loadingStatus: false,
      }))
    })
}

export const deleteSM = async (
  deleteSMVersion: (subscriptionManagementVersion: SubscriptionManagementDtoInput) => FetchPromise<DeleteSubscriptionManagementVersionMutation>,
  setState: Dispatch<SetStateAction<SubscriptionManagementState>>,
  version: SubscriptionManagementDtoInput
) => {
  const { data, errors } = await deleteSMVersion(version)
  if (data) {
    setState((state) => ({
      ...state,
      loading: true,
      statusToast: successStatusToast,
      loadingStatus: false,
    }))
  } else if (errors) {
    logNewRelicError(errors)
    setState((state) => ({
      ...state,
      statusToast: errorStatusToast,
      loadingStatus: false,
    }))
  }
}
