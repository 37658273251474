import { useApolloClient } from '@apollo/client'
import deleteSubscriptionManagementVersion from '@graphql/mutations/deleteSubscriptionManagementVersion'
import upsertSubscriptionManagementVersion from '@graphql/mutations/upsertSubscriptionManagementVersion'
import getAvailableLanguages from '@graphql/queries/getAvailableLanguages'
import getSubscriptionManagementVersions from '@graphql/queries/getSubscriptionManagementVersions'
import {
  DeleteSubscriptionManagementVersionMutation,
  DeleteSubscriptionManagementVersionMutationVariables,
  SubscriptionManagementDtoInput,
  UpsertSubscriptionManagementVersionMutation,
  UpsertSubscriptionManagementVersionMutationVariables,
} from '@graphql/types/mutation-types'
import {
  GetAvailableLanguagesQuery,
  GetAvailableLanguagesQueryVariables,
  GetSubscriptionManagementVersionsQuery,
  GetSubscriptionManagementVersionsQueryVariables,
} from '@graphql/types/query-types'
import { FetchPromise } from '@utils/types'

export interface SubscriptionManagementRequests {
  getSubscriptionManagement: () => FetchPromise<GetSubscriptionManagementVersionsQuery>
  getSMAvailableLanguages: () => FetchPromise<GetAvailableLanguagesQuery>
  upsertSMVersion: (dto: SubscriptionManagementDtoInput) => FetchPromise<UpsertSubscriptionManagementVersionMutation>
  deleteSMVersion: (dto: SubscriptionManagementDtoInput) => FetchPromise<DeleteSubscriptionManagementVersionMutation>
}

export const useSubscriptionManagementRequests = (): SubscriptionManagementRequests => {
  const actonClient = useApolloClient()

  const getSubscriptionManagement = () => {
    return actonClient.query<GetSubscriptionManagementVersionsQuery, GetSubscriptionManagementVersionsQueryVariables>({
      query: getSubscriptionManagementVersions,
      fetchPolicy: 'network-only',
      variables: {},
    })
  }

  const getSMAvailableLanguages = () => {
    return actonClient.query<GetAvailableLanguagesQuery, GetAvailableLanguagesQueryVariables>({
      query: getAvailableLanguages,
      fetchPolicy: 'network-only',
      variables: {},
    })
  }

  const upsertSMVersion = (subscriptionManagementVersion: SubscriptionManagementDtoInput) => {
    return actonClient.mutate<UpsertSubscriptionManagementVersionMutation, UpsertSubscriptionManagementVersionMutationVariables>({
      mutation: upsertSubscriptionManagementVersion,
      fetchPolicy: 'network-only',
      variables: {
        dto: subscriptionManagementVersion,
      },
    })
  }

  const deleteSMVersion = (subscriptionManagementVersion: SubscriptionManagementDtoInput) => {
    return actonClient.mutate<DeleteSubscriptionManagementVersionMutation, DeleteSubscriptionManagementVersionMutationVariables>({
      mutation: deleteSubscriptionManagementVersion,
      fetchPolicy: 'network-only',
      variables: {
        dto: subscriptionManagementVersion,
      },
    })
  }

  return {
    getSubscriptionManagement,
    getSMAvailableLanguages,
    upsertSMVersion,
    deleteSMVersion,
  }
}
