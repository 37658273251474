import React, { FC, ReactNode, useMemo, useRef, useState } from 'react'

import classNames from 'classnames'

import DropDown from '@components/DropDown'
import { DropDownType } from '@components/DropDown/DropDown'
import DropDownActions, { MenuItem } from '@components/DropDownActions/DropDownActions'
import TableWithLoader, { TableWithLoaderProps } from '@components/TableWithLoader/TableWithLoader'

import './TableWithMenu.css'

type Props = TableWithLoaderProps & {
  menuItems: MenuItem[] | ReactNode
}

const rootClass = 'table-with-menu'

/**
 * @deprecated use <TableV2 instead
 */
const TableWithMenu: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '', menuItems = [] } = props
  const { loading, ...tableProps }: TableWithLoaderProps = { ...props }

  const [openMenu, setOpenMenu] = useState(false)

  const headerRef = useRef<HTMLDivElement>(null)

  const menuHeight = useMemo(() => headerRef.current?.getBoundingClientRect().height ?? 0, [headerRef.current])

  const renderMenu = () => {
    if (!loading) {
      return Array.isArray(menuItems) ? (
        menuItems?.length > 0 && (
          <div className={`${rootClass}__menu`} data-test={`${dataTest}__menu`} style={{ height: `${menuHeight}px` }}>
            <DropDown isOpen={openMenu} toggleOpen={setOpenMenu} type={DropDownType.STYLED} hasOverflowIcon>
              <DropDownActions closeDropDown={() => setOpenMenu(false)} menuItems={menuItems} />
            </DropDown>
          </div>
        )
      ) : (
        <div className={`${rootClass}__menu`} data-test={`${dataTest}__menu`} style={{ height: `${menuHeight}px` }}>
          {menuItems}
        </div>
      )
    }
  }

  return (
    <div className={classNames(rootClass, className)}>
      {renderMenu()}
      <TableWithLoader className={className} dataTest={`${dataTest}__table`} headerRef={headerRef} loading={loading} {...tableProps} />
    </div>
  )
}

export default TableWithMenu
