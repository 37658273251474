import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './AddAlertFilterButton.css'

interface AddAlertFilterButtonProps {
  className?: string
  dataTest?: string
  onClick?: () => void
}

const rootClass = 'add-alert-filter-button'

const AddAlertFilterButton: FC<AddAlertFilterButtonProps> = (props: AddAlertFilterButtonProps) => {
  const { dataTest = rootClass, className = '', onClick } = props

  const { t } = useTranslation()

  return (
    <Button className={classNames(rootClass, className)} dataTest={dataTest} onClick={onClick} buttonType={ButtonType.TRANSPARENT}>
      <div className={`${rootClass}__add-icon`}>
        <Svg name={SvgNames.plusLight} type={SvgType.LARGER_ICON} />
      </div>
      <Typography text={t('Add an alert filter')} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
    </Button>
  )
}

export default AddAlertFilterButton
