import React, { FC, useContext, useMemo } from 'react'
import { Row } from 'react-table'

import { ListPickerModalState } from '@complex/ListPickerModalV2/utils/interfaces/ListPickerModalInterfaces'
import { ListPickerModalContext } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import Button, { ButtonType } from '@components/Button'
import { ModalFooter, ModalFooterAction } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Toggle from '@components/Toggle'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { List } from '@interface/foldersLists/List'
import { useAccountSettings } from '@utils/account/account.utils'
import { ListCategory } from '@utils/lists'
import { getFormattedNumber } from '@utils/numbers'
import { setItem } from '@utils/sessionStorage'

import './ListPickerModalFooter.css'

interface ListPickerModalFooterProps {
  className?: string
  dataTest?: string
  cancelButtonText: string
  closeModal: VoidFunction
  isRestrictedToLegacyLists: boolean
  selectedLists: Row<List>[][]
  submitButtonText: string
  submitDisabledWithoutSelection: boolean
  submitDisabledOnSelect?: boolean
  submitLists: VoidFunction
  toggleSelectedLists: VoidFunction
  isMultiSelect?: boolean
  disableSubmit?: boolean
  disableSubmitByCriteria?: boolean
  hideLegacyListToggle: boolean
  submitTooltipText?: string
}
export enum ListPickerFooterToggle {
  STATUS = 'ListPickerFooterToggle:Status',
}

const rootClass = 'list-picker-modal-footer'

const ListPickerModalFooter: FC<ListPickerModalFooterProps> = (props: ListPickerModalFooterProps) => {
  const {
    cancelButtonText,
    closeModal,
    selectedLists,
    submitButtonText,
    submitDisabledWithoutSelection,
    submitDisabledOnSelect,
    submitLists,
    toggleSelectedLists,
    dataTest = rootClass,
    isMultiSelect = false,
    disableSubmit = false,
    hideLegacyListToggle,
    submitTooltipText,
    disableSubmitByCriteria,
  } = props
  const {
    values: { viewingSelectedLists, isLegacyForms, listType, selectAllSegmentsOfSelectedList, disableTopListCount, isLegacyWebinars },
    setState,
  } = useContext(ListPickerModalContext)

  const { t } = useTranslation()

  const { exclusivelyActOnContacts, hasShowActOnContactsTab, phase3FormSubmission, hasAOCWebinars } = useAccountSettings()

  const totalLists = useMemo(
    () =>
      selectedLists.reduce((curr, list) => {
        if (selectAllSegmentsOfSelectedList || disableTopListCount) {
          return curr + list.filter(({ original }) => !original.id.startsWith('l-')).length
        }
        return curr + list.length
      }, 0),
    [selectedLists]
  )

  const isWebinarSubmission = (listType === ListCategory.WEBINAR || listType === ListCategory.WEBINAR_UCL) && hasAOCWebinars

  const hasToggle =
    !exclusivelyActOnContacts &&
    hasShowActOnContactsTab &&
    phase3FormSubmission &&
    !hideLegacyListToggle &&
    (listType === ListCategory.FORM_SUBMISSIONS_UCL || listType === ListCategory.FORM_SUBMISSIONS || isWebinarSubmission)

  const totalRecords = useMemo(
    () =>
      selectedLists
        .flatMap((list) => Object.values(list))
        .filter(({ original }, index, arr) => arr.findIndex(({ original: originalDuplicated }) => originalDuplicated.id === original.id) === index)
        .reduce((curr, { original: { size = 0, id } }) => {
          if (selectAllSegmentsOfSelectedList || disableTopListCount) {
            return curr + (id.startsWith('l-') ? 0 : size)
          }
          return curr + size
        }, 0),
    [selectedLists]
  )

  const selectedListName = useMemo(() => {
    if (totalLists === 1) {
      return selectedLists.flatMap((list) => Object.values(list))[0].original.name
    }
  }, [selectedLists])

  const recordsInfoText =
    !!totalRecords || !!totalLists
      ? `${getFormattedNumber(totalLists)} ${t(`List${totalLists > 1 ? 's' : ''}`)}, ${getFormattedNumber(totalRecords)} ${t(
          `Record${totalRecords !== 1 ? 's' : ''} Selected`
        )}`
      : t('No records selected')

  const submitButton = (
    <Button
      buttonType={ButtonType.PRIMARY}
      dataTest={`${dataTest}-save-button`}
      onClick={() => submitLists()}
      cursorIsNotAllowed={disableSubmitByCriteria}
      disabled={
        disableSubmitByCriteria
          ? disableSubmitByCriteria
          : submitDisabledWithoutSelection
          ? !totalLists
          : submitDisabledOnSelect
          ? disableSubmit
          : false
      }
    >
      {t(submitButtonText)}
    </Button>
  )

  return (
    <ModalFooter className={rootClass} footerType={ModalFooterType.List}>
      <ModalFooterAction className={`${rootClass}__actions`}>
        {isMultiSelect ? (
          <>
            <Button
              className={`${rootClass}__view-selected-button`}
              buttonType={ButtonType.INFO}
              onClick={() => toggleSelectedLists()}
              disabled={!totalLists && !viewingSelectedLists}
            >
              <Svg type={SvgType.LARGER_ICON} name={viewingSelectedLists ? SvgNames.arrowLeft : SvgNames.listCheck} />
              <Typography
                text={t(viewingSelectedLists ? 'View All Lists' : 'View Selected')}
                type={TextType.NORMAL_TEXT_TEAL_LARGE}
                weight={TextWeight.MEDIUM}
              />
            </Button>
            <Typography lineHeight={LineHeight.MEDIUM_LARGE} text={recordsInfoText} type={TextType.BODY_TEXT_SMALL_LIGHT} inline />
          </>
        ) : (
          <Typography
            type={TextType.BODY_TEXT_SMALL_LIGHT}
            text={t(totalLists ? '{{name}} selected' : 'No list selected')}
            values={{ name: selectedListName }}
            tagProps={{ bold: { weight: TextWeight.BOLD } }}
            inlineBlock
          />
        )}
        {hasToggle && (
          <div className={`${rootClass}__toggle-button`}>
            <Toggle
              isOn={isWebinarSubmission ? isLegacyWebinars : isLegacyForms}
              onToggle={(value) => {
                setState((state: ListPickerModalState) =>
                  isWebinarSubmission
                    ? {
                        ...state,
                        isLegacyWebinars: value,
                        listType: value ? ListCategory.WEBINAR : ListCategory.WEBINAR_UCL,
                        foldersState: { folders: [], foldersLoading: false },
                        search: undefined,
                      }
                    : {
                        ...state,
                        isLegacyForms: value,
                        listType: value ? ListCategory.FORM_SUBMISSIONS : ListCategory.FORM_SUBMISSIONS_UCL,
                        foldersState: { folders: [], foldersLoading: false },
                        search: undefined,
                      }
                )
                setItem(ListPickerFooterToggle.STATUS, value ? 'true' : 'false')
              }}
            />
            <Typography
              type={TextType.BODY_TEXT}
              text={t(isWebinarSubmission ? 'Show legacy webinar registrations' : 'Show legacy form submissions')}
              inlineBlock
            />
          </div>
        )}
      </ModalFooterAction>
      <Button className={`${rootClass}__cancel-button`} buttonType={ButtonType.TERTIARY} onClick={closeModal} dataTest={`${dataTest}-close-button`}>
        {t(cancelButtonText)}
      </Button>
      {submitTooltipText && disableSubmitByCriteria && totalLists ? (
        <Tooltip isPopover triggerClassName={`${rootClass}__tooltip-button`} trigger={submitButton} position="left" minimalPadding={false}>
          {t(submitTooltipText)}
        </Tooltip>
      ) : (
        submitButton
      )}
    </ModalFooter>
  )
}

export default ListPickerModalFooter
