import { gql } from '@apollo/client'

export default gql`
  query getBrandingDomainDisplayedUrls {
    getBrandingDomainDisplayedUrls {
      defaultUrl {
        url
        path
        domain
        protocol
        isDefault
      }
      currentUrl {
        url
        path
        domain
        protocol
        isDefault
      }
    }
  }
`
