import React, { useContext } from 'react'

import { getUUID } from '@const/globals'
import {
  ColumnType,
  DynamicListItems,
  DynamicListRowData,
  Metadata,
  RowType,
} from '@src/pages/datamanagement/components/CrmContacts/components/DynamicList/utils/DynamicList.interfaces'
import ScoreSheetRow from '@src/pages/datamanagement/components/CrmContacts/ScoreSheetRow/ScoreSheetRow'
import { UseCRMContactsRowHandlerProps } from '@src/pages/datamanagement/components/CrmContacts/utils/useCRMContactsRowHandler/useCRMContactsRowHandler'
import { DataManagementContext } from '@src/pages/datamanagement/context/DataManagementContext'

export type CRMContactsAddRowFunction = (
  type?: RowType,
  defaultId?: number,
  selected?: { [key: string]: string },
  dataType?: string,
  relatedId?: number,
  metadata?: object
) => DynamicListRowData

type UseCRMContactsAddRowHandlerFunction = {
  addRow: CRMContactsAddRowFunction
}

type UseCRMContactsAddRowHandlerProps = Pick<
  UseCRMContactsRowHandlerProps,
  'baseRow' | 'standardFieldsList' | 'dataLoaded' | 'scoresheetsList' | 'scoresheetsLoading' | 'setDataLoaded'
>

export const useCRMContactsAddRowHandler = (props: UseCRMContactsAddRowHandlerProps): UseCRMContactsAddRowHandlerFunction => {
  const { baseRow, standardFieldsList, dataLoaded, scoresheetsList, scoresheetsLoading, setDataLoaded } = props

  const {
    userUpdates,
    values: { fieldMappings, unifiedListFieldMappings },
  } = useContext(DataManagementContext)

  const getLabelToKeyMapping = (staticValue: string | undefined) => {
    return standardFieldsList?.standardFields
      ?.filter((field) => field?.label === staticValue)
      .map((req) => req?.key)
      .pop()
  }

  const updateMetaData = (list: DynamicListRowData[], rowId: number, metadata: Metadata) => {
    const foundRow = list.filter((row) => row.id === rowId)
    if (foundRow.length > 0) {
      foundRow[0] = {
        ...foundRow[0],
        metadata,
      }

      const arrayId = list.findIndex((row) => row.id === rowId)
      list.splice(arrayId, 1, foundRow[0])
      setDataLoaded({
        ...dataLoaded,
        done: true,
      })
    }

    return foundRow.length
  }

  const updateRowMetadata = (metadata: Metadata, rowId?: number) => {
    if (rowId) {
      // find the row - std or custom?
      const stdRowUpdate = updateMetaData(dataLoaded.standardRows, rowId, metadata)
      if (stdRowUpdate <= 0) {
        updateMetaData(dataLoaded.customRows, rowId, metadata)
      }

      const localUnifiedMappingRow = unifiedListFieldMappings?.filter((row) => row?.columnIndex === rowId)
      if (localUnifiedMappingRow && localUnifiedMappingRow.length > 0) {
        localUnifiedMappingRow[0] = {
          ...localUnifiedMappingRow[0],
          typeMetaData: JSON.stringify(metadata),
        }

        fieldMappings.push(localUnifiedMappingRow[0])
      } else {
        // previously changed row with (apparently) nothing currently in the db so we have to update the pre existing change request
        const fieldMappingRow = fieldMappings.filter((row) => row.columnIndex === rowId)
        if (fieldMappingRow && fieldMappingRow.length > 0) {
          fieldMappingRow[0] = {
            ...fieldMappingRow[0],
            typeMetaData: JSON.stringify(metadata),
          }
          const arrayId = fieldMappings.findIndex((row) => row.columnIndex === rowId)
          fieldMappings.splice(arrayId, 1, fieldMappingRow[0])
        }
      }

      // make the 'save' drop down happen
      userUpdates('updateRow', true)
    }
  }

  const addRow = (
    type: RowType = RowType.STANDARD,
    defaultId = -1,
    selected?: { [key: string]: string },
    dataType?: string,
    relatedId?: number,
    metadata?: object
  ) => {
    let columnsCopy: DynamicListItems[] = []
    if (type === RowType.STANDARD) {
      if (baseRow.standard.columns.length !== 0) {
        columnsCopy = baseRow.standard.columns.reduce<DynamicListItems[]>((acc, col) => {
          const localCopy = { ...col }
          if (selected && col.column && selected.hasOwnProperty(col.column)) {
            localCopy.selected = selected[col.column]
          }
          return [...acc, localCopy]
        }, [])
      }
    } else if (type === RowType.CUSTOM) {
      if (baseRow.custom.columns.length !== 0) {
        columnsCopy = baseRow.custom.columns.map((col) => {
          const localCopy = { ...col }
          if (selected && col.column && selected.hasOwnProperty(col.column)) {
            localCopy.selected = selected[col.column]
          } else if (localCopy.type === ColumnType.EDIT && selected && selected['edit']) {
            localCopy.staticValue = selected['edit']
          }
          return localCopy
        })
      }
    } else if (type === RowType.TEMPORARY) {
      columnsCopy.push(
        <ScoreSheetRow
          relatedRowId={relatedId}
          updateRowMetadata={updateRowMetadata}
          existingMetadata={metadata}
          listsrvLoading={scoresheetsLoading}
          scoresheetsList={scoresheetsList}
        />
      )
    } /* if (type === RowType.REQUIRED) */ else {
      if (baseRow.required.columns.length !== 0) {
        columnsCopy = baseRow.required.columns.map((col) => {
          const localCopy = { ...col }
          if (selected && col.column && selected.hasOwnProperty(col.column)) {
            localCopy.selected = selected[col.column]
          } else if (localCopy.type === ColumnType.STATIC && selected && selected['static']) {
            const staticValue = selected['static']
            const labelToKeyMapping = getLabelToKeyMapping(staticValue)
            localCopy.staticValue = staticValue
            localCopy.dataType = dataType
            localCopy.selected = labelToKeyMapping
          }
          return localCopy
        })
      }
    }

    const newRow: DynamicListRowData = {
      id: defaultId,
      columns: columnsCopy,
      key: getUUID(),
      type: type,
      metadata: metadata,
    }
    return newRow
  }

  return { addRow }
}
