import React from 'react'

import { TFunction } from 'i18next'

import {
  ContactStatus,
  Delimiter,
  FirstLineOption,
  MergeStrategy,
  Quote,
  UpsertContactSetting,
} from '@complex/ImportContacts/ImportContacts.constants'
import DropZone from '@components/DropZone/DropZone'
import { DEFAULT_MAX_FILE_SIZE } from '@components/DropZone/DropZone.constants'
import { ProgressBarStep } from '@components/ProgressBar/ProgressBar'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { LineHeight, TextAlign, TextType, TextWeight } from '@components/Typography/Typography'
import {
  CronDto,
  CronDtoInput,
  DayOfWeek,
  FtpFileDto,
  ImportMappingEntity,
  ImportMappingEntityInput,
  PreviewContactsDto,
} from '@graphql/types/microservice/entity-upload-types'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { ProgramSchedule } from '@graphql/types/query-types'
import { defaultMappingPreviewField, MappingPreview } from '@src/pages/importcontacts/context/ImportContactsContext'
import { CreateSegmentRequestType, IsAValidSegmentNameRequestType } from '@src/pages/importcontacts/GraphQL/ImportContactsRequests.classic.graphQL'
import { ImportContactsV2Requests } from '@src/pages/ImportContactsV2/GraphQL/ImportContactsV2Request.graphQL'
import {
  ColumnMergeType,
  DataType,
  EXTERNAL_ID_FIELD_KEY,
  ImportContactsSteps,
  importOptionsDefaultValues,
} from '@src/pages/ImportContactsV2/utils/ImportContactsV2.constants'
import { Update } from '@src/pages/ImportContactsV2/utils/ImportContactsV2.context'
import { WEEK_DAYS } from '@utils/date'

const rootClass = 'import-contacts-v2'

export const getImportContactsV2ListingTabs = (t: TFunction, allCompleted: boolean, sectionParam = 'file'): ProgressBarStep[] => {
  const activeSectionByParam: { [key: string]: ImportContactsSteps } = {
    file: ImportContactsSteps.SELECT_FILE,
    options: ImportContactsSteps.IMPORT_OPTIONS,
    mapping: ImportContactsSteps.MAP_FIELDS,
    review: ImportContactsSteps.REVIEW,
  }
  return [
    {
      key: ImportContactsSteps.SELECT_FILE,
      header: t('ImportContactsV2.Sections.ImportFile'),
      isCompleted: allCompleted,
    },
    {
      key: ImportContactsSteps.IMPORT_OPTIONS,
      header: t('ImportContactsV2.Sections.ImportOptions'),
      isCompleted: allCompleted,
    },
    {
      key: ImportContactsSteps.MAP_FIELDS,
      header: t('ImportContactsV2.Sections.MapFields'),
      isCompleted: allCompleted,
    },
    {
      key: ImportContactsSteps.REVIEW,
      header: t('ImportContactsV2.Sections.ReviewImport'),
      isCompleted: allCompleted,
    },
    {
      key: ImportContactsSteps.IMPORT_IN_PROGRESS,
      isCompleted: allCompleted,
      hidden: true,
    },
  ].map((step) => ({ ...step, isActive: step.key === activeSectionByParam[sectionParam] }))
}

export const renderImportFileInfoCards = (t: Function) => (
  <div className={`${rootClass}__upload-bullet-wrapper`}>
    <div>
      <Typography text={t('ImportContactsV2.Sections.ImportFile.FirstSubCard.Title')} weight={TextWeight.BOLD} textAlign={TextAlign.LEFT} />
      <Typography
        text={t('ImportContactsV2.Sections.ImportFile.FirstSubCard.Text')}
        type={TextType.BODY_TEXT_SMALL}
        textAlign={TextAlign.LEFT}
        lineHeight={LineHeight.MEDIUM}
        inline
      />
    </div>
    <div>
      <Typography text={t('ImportContactsV2.Sections.ImportFile.SecondSubCard.Title')} weight={TextWeight.BOLD} textAlign={TextAlign.LEFT} />
      <Typography
        text={t('ImportContactsV2.Sections.ImportFile.SecondSubCard.Text')}
        type={TextType.BODY_TEXT_SMALL}
        textAlign={TextAlign.LEFT}
        lineHeight={LineHeight.MEDIUM}
        inline
      />
    </div>
    <div>
      <Typography text={t('ImportContactsV2.Sections.ImportFile.ThirdSubCard.Title')} weight={TextWeight.BOLD} textAlign={TextAlign.LEFT} />
      <Typography
        text={t('ImportContactsV2.Sections.ImportFile.ThirdSubCard.Text')}
        type={TextType.BODY_TEXT_SMALL}
        textAlign={TextAlign.LEFT}
        lineHeight={LineHeight.MEDIUM}
        inline
      />
    </div>
  </div>
)

export const renderFileSelection = (t: Function, update: Update, userAllowedToCreate: boolean) => (
  <div className={`${rootClass}__drop-zone-wrapper`}>
    <DropZone
      className={rootClass}
      customImportTrigger={
        <Typography
          inline
          text={t('ImportContactsV2.Sections.ImportFile.ImportText')}
          type={TextType.BODY_TEXT_LIGHT}
          values={{ importSize: DEFAULT_MAX_FILE_SIZE }}
          tagComponents={{
            TextLink: (
              <TextLink
                onClick={() => update({ isSelectingFile: true, loadingFtpFiles: true })}
                weight={TextWeight.MEDIUM}
                size={TextLinkSize.LARGE}
                hideIcon
              />
            ),
          }}
        />
      }
      disabled={!userAllowedToCreate}
      disabledTooltip={t('Ask your administrator for permission to do this')}
      disableDragAndDrop
    />
  </div>
)

export const isAValidSegmentNameUtils = async (isAValidSegmentNameRequest: IsAValidSegmentNameRequestType, segmentName: string, update: Function) => {
  const { data } = await isAValidSegmentNameRequest({ segmentName })
  update({ validSegmentName: data?.isAValidSegmentName ?? true })
  return data?.isAValidSegmentName ?? true
}

export const createSegmentUtils = async (
  createSegmentRequest: CreateSegmentRequestType,
  segmentName: string,
  update: Update,
  segmentOptions: SelectV2SingleOption[]
) => {
  const { data } = await createSegmentRequest({ segmentName })
  if (data?.createSegment) {
    const segmentCreatedOption: SelectV2SingleOption = { label: segmentName, value: data.createSegment.id }
    update({
      segmentOptions: [segmentCreatedOption, ...segmentOptions],
      segmentSelectedOption: segmentCreatedOption,
    })
  }
}

export const getMappingPreviews = (
  mappingPreview: MappingPreview[],
  mergeStrategy: MergeStrategy,
  externalIdMatch: SelectV2SingleOption | undefined | null,
  unifiedListFieldMappings?: UnifiedListFieldMapping[]
) => {
  const tempMappingPreview = mappingPreview
    .map<ImportMappingEntityInput>((preview) => {
      const actonColumnId = parseInt(preview.mappingIndex) || undefined
      return {
        actonColumn: actonColumnId !== undefined ? preview.displayName : preview.friendlyName,
        actonColumnId,
        actonStandardKey: preview.mapping !== '' ? preview.mapping : undefined,
        csvColumn: preview.csvColumn,
        csvColumnPosition: preview.index,
        dataFormat: preview.dataFormat,
        dataType: preview.dataType === '' ? DataType.Text : (preview.dataType as DataType),
        mergeType: preview.merge as ColumnMergeType,
      }
    })
    .filter((preview) => preview.actonColumn !== '')

  if (mergeStrategy === MergeStrategy.ExternalId) {
    const externalIdAlreadyMappedField = tempMappingPreview.find(({ actonStandardKey }) => actonStandardKey === EXTERNAL_ID_FIELD_KEY)
    if (externalIdAlreadyMappedField) {
      tempMappingPreview.splice(tempMappingPreview.indexOf(externalIdAlreadyMappedField), 1)
    }
    if (externalIdMatch && unifiedListFieldMappings) {
      const externalIdField = unifiedListFieldMappings.find(({ standardFieldKey }) => standardFieldKey === EXTERNAL_ID_FIELD_KEY)
      if (externalIdField) {
        const externalIdMapping: ImportMappingEntityInput = {
          actonColumn: externalIdField.displayName,
          actonColumnId: externalIdField?.columnIndex,
          actonStandardKey: EXTERNAL_ID_FIELD_KEY,
          csvColumn: externalIdMatch.value,
          csvColumnPosition: parseInt(externalIdMatch?.extraOptions?.csvPosition || '0'),
          dataFormat: '',
          dataType: DataType.Text,
          mergeType: ColumnMergeType.IfEmpty,
        }
        tempMappingPreview.push(externalIdMapping)
      }
    }
  }

  return tempMappingPreview
}

export const changeUrlPathOnTabChange = (newTab: ImportContactsSteps) => {
  const optionsPath = '/options'
  const mappingPath = '/mapping'
  const urlHasOptionsPath = location.pathname.includes(optionsPath)
  const urlHasMappingPath = location.pathname.includes(mappingPath)
  let newPath = location.pathname

  switch (newTab) {
    case ImportContactsSteps.IMPORT_OPTIONS:
      if (!urlHasOptionsPath) {
        newPath = urlHasMappingPath ? newPath.replace(mappingPath, optionsPath) : `${newPath}${optionsPath}`
      }
      break
    case ImportContactsSteps.MAP_FIELDS:
      if (!urlHasMappingPath) {
        newPath = urlHasOptionsPath ? newPath.replace(optionsPath, mappingPath) : `${newPath}${mappingPath}`
      }
      break
    default:
      if (urlHasOptionsPath || urlHasMappingPath) {
        newPath = newPath.replace(urlHasOptionsPath ? optionsPath : mappingPath, '')
      }
  }

  history.replaceState({}, '', newPath)
}

export const getFilteredFTPFiles = (files: FtpFileDto[], acceptedFormats = ['.csv']) => {
  const isValidFormat = (file: string) => acceptedFormats.some((format) => file.endsWith(format))
  return files.filter(({ name = '' }) => isValidFormat(name))
}

export const getExternalIdMappingOption = (mappingsPreview: ImportMappingEntity[] | undefined): SelectV2SingleOption | undefined => {
  if (mappingsPreview) {
    const externalIdMappedField = mappingsPreview.find(({ actonStandardKey }) => actonStandardKey === EXTERNAL_ID_FIELD_KEY)
    if (externalIdMappedField) {
      return {
        label: externalIdMappedField.csvColumn || '',
        value: externalIdMappedField.csvColumn || '',
        extraOptions: { csvPosition: externalIdMappedField.csvColumnPosition?.toString() },
      }
    }
  }
}

export const loadCsvMappingData = (
  update: Update,
  selectedFile: FtpFileDto,
  getFtpFirstRowsRequest: ImportContactsV2Requests['getFtpFirstRowsRequest'],
  firstLineContains: FirstLineOption,
  defaultMergeType = ColumnMergeType.IfEmpty,
  delimiter?: Delimiter,
  quote?: Quote,
  defaultMappings?: ImportMappingEntity[]
) => {
  if (selectedFile?.name) {
    update({ loadingMappingScreen: true })
    getFtpFirstRowsRequest(selectedFile.name, firstLineContains === FirstLineOption.NAMES, delimiter, quote).then((data: PreviewContactsDto[]) => {
      const previewHeader: string[] = []
      const previewRecords: { value: string; hasError: boolean }[][] = []
      const mappingPreview: MappingPreview[] = []
      data.forEach((preview, index) => {
        const defaultMappingPreview = defaultMappings?.find(({ csvColumnPosition }) => csvColumnPosition === index)
        previewHeader.push(preview.columnName || '')
        mappingPreview.push({
          ...defaultMappingPreviewField,
          index,
          csvColumn: preview.columnName,
          merge: defaultMergeType,
          ...(defaultMappingPreview
            ? {
                dataFormat: defaultMappingPreview.dataFormat,
                dataType: defaultMappingPreview.dataType,
                displayName: defaultMappingPreview.actonColumn,
                friendlyName: defaultMappingPreview.actonColumn,
                isCustom: defaultMappingPreview.actonColumnId === null,
                mapping: defaultMappingPreview.actonStandardKey,
                mappingIndex: defaultMappingPreview.actonColumnId,
                merge: defaultMappingPreview.mergeType || defaultMappingPreviewField.merge,
              }
            : {}),
        })
        if (preview.previewRows) {
          preview.previewRows.forEach((value = '', index) => {
            if (!previewRecords[index]) {
              previewRecords[index] = []
            }
            previewRecords[index].push({ value, hasError: false })
          })
        }
      })
      update({
        previewHeader,
        mappingPreview,
        previewRecords,
        loadingMappingScreen: false,
        ...(defaultMappings?.length ? { validateAllTrigger: true } : {}),
      })
    })
  }
}

export const getCronFromProgramSchedule = (schedule: ProgramSchedule): CronDtoInput | undefined => {
  const { isScheduled, hour, minute, interval, ampm, type, weekdaysOnly } = schedule
  if (isScheduled) {
    let cron: CronDtoInput = {
      seconds: '0',
      minutes: minute?.toString(),
      hours: ((hour ?? 0) + (ampm ?? 0) * 12).toString(),
      dayOfMonth: '*',
      month: '*',
    }
    if (type === 'DAILY') {
      cron = {
        ...cron,
        hours: typeof interval === 'number' && interval !== 0 && !isNaN(interval) ? `${cron.hours}/${interval}` : cron.hours,
        dayOfMonth: '?',
        dayOfWeek: (weekdaysOnly ? 'WEEKDAYS' : 'ALL') as DayOfWeek,
      }
    }
    if (type === 'WEEKLY') {
      const weekDays: DayOfWeek[] = WEEK_DAYS as DayOfWeek[]
      cron = {
        ...cron,
        dayOfMonth: '?',
        dayOfWeek: weekDays[(interval ?? importOptionsDefaultValues.schedule.interval ?? 1) - 1],
      }
    }
    if (type === 'MONTHLY') {
      cron = {
        ...cron,
        dayOfMonth: interval?.toString(),
        dayOfWeek: 'NONE' as DayOfWeek,
      }
    }
    return cron
  }
}

export const getProgramScheduleFromCron = (cron: CronDto): ProgramSchedule => {
  const { hours = '0', minutes = '0', everyHour, dayOfMonth, dayOfWeek } = cron
  let interval = 0
  let type = 'DAILY'
  let weekdaysOnly: boolean | undefined
  if (dayOfWeek === 'ALL' || dayOfWeek === 'WEEKDAYS') {
    interval = parseInt(everyHour ?? '0')
    weekdaysOnly = dayOfWeek === 'WEEKDAYS'
  } else if (dayOfWeek === 'NONE') {
    type = 'MONTHLY'
    interval = parseInt(dayOfMonth ?? '0')
  } else if (dayOfWeek) {
    type = 'WEEKLY'
    interval = WEEK_DAYS.indexOf(dayOfWeek) + 1
  }

  return {
    isScheduled: true,
    hour: parseInt(hours) % 12,
    minute: parseInt(minutes),
    interval,
    ampm: parseInt(hours) > 11 ? 1 : 0,
    type,
    weekdaysOnly,
  }
}

export const advancedOptionsText: { [key: string]: string } = {
  [FirstLineOption.NAMES]: 'ImportContactsV2.Sections.ImportOptions.Fields.Names',
  [FirstLineOption.VALUES]: 'ImportContactsV2.Sections.ImportOptions.Fields.Values',
  [Delimiter.Comma]: 'Commas',
  [Delimiter.Tab]: 'Tabs',
  [Delimiter.SemiColon]: 'Semi-colons',
  [Quote.SimpleQuote]: 'Single-quoted',
  [Quote.Quote]: 'Double-quoted',
  [UpsertContactSetting.OldestContact]: 'ImportContactsV2.Sections.ImportOptions.ContactsUpdate.Oldest',
  [UpsertContactSetting.SkipExistingContact]: 'ImportContactsV2.Sections.ImportOptions.ContactsUpdate.SkipExisting',
  [UpsertContactSetting.SkipNewContact]: 'ImportContactsV2.Sections.ImportOptions.ContactsUpdate.SkipNew',
  [ColumnMergeType.IfEmpty]: 'ImportContactsV2.Sections.ImportOptions.Merge.Blank',
  [ColumnMergeType.Overwrite]: 'ImportContactsV2.Sections.ImportOptions.Update.Overwrite',
  [ContactStatus.OptOut]: 'ImportContactsV2.Sections.ImportOptions.RejectRules.OptOut',
  [ContactStatus.HardBounce]: 'ImportContactsV2.Sections.ImportOptions.RejectRules.HardBounce',
  [ContactStatus.SoftBounce]: 'ImportContactsV2.Sections.ImportOptions.RejectRules.EmailSuppression',
  [MergeStrategy.Email]: 'Email',
  [MergeStrategy.ExternalId]: 'External id',
}
