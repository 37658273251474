import React, { FC, useEffect, useState } from 'react'

import { useTranslation } from '@const/globals'
import getAllowedSendWindow from '@graphql/microservices/sms-management/getAllowedSendWindow'
import getFatigueRule from '@graphql/microservices/sms-management/getFatigueRule'
import getInternationalSending from '@graphql/microservices/sms-management/getInternationalSending'
import setAllowedSendWindow from '@graphql/microservices/sms-management/setAllowedSendWindow'
import setFatigueRule from '@graphql/microservices/sms-management/setFatigueRule'
import setInternationalSending from '@graphql/microservices/sms-management/setInternationalSending'
import {
  GetFatigueRuleQueryVariables,
  GetInternationalSendingQueryVariables,
  GetAllowedSendWindowQueryVariables,
  Query,
  SetInternationalSendingMutation,
  SetInternationalSendingMutationVariables,
  SetAllowedSendWindowMutation,
  SetAllowedSendWindowMutationVariables,
  SetFatigueRuleMutationVariables,
  SetFatigueRuleMutation,
} from '@graphql/types/microservice/sms-management-types'
import Settings from '@src/pages/sms/settings/Settings'
import { MIN_HOUR_DIFFERENCE } from '@src/pages/sms/utils/sms.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'
import { logNewRelicError } from '@utils/new-relic.utils'

interface Props {
  dataTest?: string
}

interface State {
  hasInternationalSending: boolean
  toastMessage: string
  toastSuccess: boolean
}

interface FatigueRules {
  fatigueFrequency: number
  fatigueInterval: number
}

const defaultFatigueRules: FatigueRules = {
  fatigueInterval: 30,
  fatigueFrequency: 4,
}

const defaultState: State = {
  hasInternationalSending: false,
  toastMessage: '',
  toastSuccess: false,
}

interface AllowedSendState {
  allowOverride: boolean
  startTime: string
  startTimeError: boolean
  endTime: string
  endTimeError: boolean
}

const defaultAllowedSendState: AllowedSendState = {
  allowOverride: false,
  startTime: '09:00',
  startTimeError: false,
  endTime: '17:00',
  endTimeError: false,
}

const SettingsContainer: FC<Props> = ({ dataTest }: Props) => {
  const [fatigueRules, setFatigueRules] = useState<FatigueRules>(defaultFatigueRules)
  const [state, setState] = useState<State>(defaultState)
  const [allowedSendState, setAllowedSendState] = useState<AllowedSendState>(defaultAllowedSendState)
  const { accountId } = useAccountSettings()
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.SMS_MANAGEMENT })

  const { t } = useTranslation()

  const {
    data: internationalSendingData,
    loading: internationalSendingLoading,
    error: internationalSendingError,
  } = useQueryOnMount<Query, GetInternationalSendingQueryVariables>(getInternationalSending, {
    client,
    fetchPolicy: 'network-only',
    variables: {
      accountId,
    },
  })

  const {
    data: allowedSendData,
    loading: allowedSendLoading,
    error: allowedSendError,
  } = useQueryOnMount<Query, GetAllowedSendWindowQueryVariables>(getAllowedSendWindow, {
    client,
    fetchPolicy: 'network-only',
    variables: {
      accountId,
    },
  })
  const {
    data: fatigueData,
    loading: fatigueLoading,
    error: fatigueError,
  } = useQueryOnMount<Query, GetFatigueRuleQueryVariables>(getFatigueRule, {
    client,
    fetchPolicy: 'network-only',
    variables: {
      accountId,
    },
  })

  useEffect(() => {
    if (internationalSendingError) {
      logNewRelicError(internationalSendingError)
    }
    if (allowedSendError) {
      logNewRelicError(allowedSendError)
    }
    if (fatigueError) {
      logNewRelicError(fatigueError)
    }
  }, [internationalSendingError, allowedSendError, fatigueError])

  useEffect(() => {
    if (fatigueData) {
      setFatigueRules({
        ...fatigueRules,
        fatigueFrequency: fatigueData.getFatigueRule.fatigueFrequency ?? defaultFatigueRules.fatigueFrequency,
        fatigueInterval: fatigueData.getFatigueRule.fatigueInterval ?? defaultFatigueRules.fatigueInterval,
      })
    }
    if (internationalSendingData) {
      setState({ ...state, hasInternationalSending: internationalSendingData.getInternationalSending })
    }
    if (allowedSendData) {
      setAllowedSendState({
        ...allowedSendState,
        allowOverride: allowedSendData.getAllowedSendWindow.allowOverride,
        startTime: allowedSendData.getAllowedSendWindow.sendTimeStart ?? defaultAllowedSendState.startTime,
        endTime: allowedSendData.getAllowedSendWindow.sendTimeEnd ?? defaultAllowedSendState.endTime,
      })
    }
  }, [internationalSendingData, allowedSendData, fatigueData])

  const onChangeInternationalSending = async (hasInternationalSending: boolean) => {
    if (hasInternationalSending === state.hasInternationalSending) return

    const { data } = await client.mutate<SetInternationalSendingMutation, SetInternationalSendingMutationVariables>({
      mutation: setInternationalSending,
      variables: {
        accountId,
        isOn: hasInternationalSending,
      },
    })
    if (data && data.setInternationalSending) {
      const toastMessage = t(`International Sending is now ${hasInternationalSending ? 'enabled' : 'disabled'}`)
      setState({ ...state, toastMessage, hasInternationalSending, toastSuccess: true })
    } else {
      setState({ ...state, toastMessage: t('Unable to update the International Sending state.'), toastSuccess: false })
    }
  }

  const onChangeAllowOverride = async (allowOverride: boolean) => {
    if (allowOverride === allowedSendState.allowOverride) return

    const { data } = await client.mutate<SetAllowedSendWindowMutation, SetAllowedSendWindowMutationVariables>({
      mutation: setAllowedSendWindow,
      variables: {
        accountId,
        sendTimeStart: allowedSendState.startTime ?? defaultAllowedSendState.startTime,
        sendTimeEnd: allowedSendState.endTime ?? defaultAllowedSendState.endTime,
        allowOverride: allowOverride,
      },
    })

    if (data && data.setAllowedSendWindow) {
      const toastMessage = t(`Allow Override is now ${allowOverride ? 'enabled' : 'disabled'}`)
      setAllowedSendState({ ...allowedSendState, allowOverride })
      setState({ ...state, toastMessage, toastSuccess: true })
    } else {
      setState({ ...state, toastMessage: t('Unable to update Allow Override'), toastSuccess: false })
    }
  }

  const splitTime = (time: string) => {
    return time.split(':')
  }

  const validateTimerange = (startTime: string, endTime: string) => {
    const [hoursStart, minutesStart] = splitTime(startTime as string)
    const dateStart = new Date()
    dateStart.setHours(parseInt(hoursStart), parseInt(minutesStart), 0, 0)

    const [hoursEnd, minutesEnd] = splitTime(endTime as string)
    const dateEnd = new Date()
    dateEnd.setHours(parseInt(hoursEnd), parseInt(minutesEnd), 0, 0)

    return dateEnd.valueOf() - dateStart.valueOf()
  }

  const onChangeStartTime = async (newStartTime: string | Date) => {
    if (newStartTime === allowedSendState.startTime) {
      setAllowedSendState({ ...allowedSendState, startTimeError: false })
      return
    }

    if (newStartTime >= allowedSendState.endTime) {
      setAllowedSendState({ ...allowedSendState, startTimeError: true })
      return
    }

    const range = validateTimerange(newStartTime as string, allowedSendState.endTime)

    if (range < MIN_HOUR_DIFFERENCE) {
      setAllowedSendState({ ...allowedSendState, startTimeError: true })
      return
    }

    const { data } = await client.mutate<SetAllowedSendWindowMutation, SetAllowedSendWindowMutationVariables>({
      mutation: setAllowedSendWindow,
      variables: {
        accountId,
        sendTimeStart: (newStartTime ?? defaultAllowedSendState.startTime).toString(),
        sendTimeEnd: allowedSendState.endTime ?? defaultAllowedSendState.endTime,
        allowOverride: allowedSendState.allowOverride,
      },
    })

    if (data && data.setAllowedSendWindow) {
      const toastMessage = newStartTime ? t('Updated the Start Time') : ''
      setAllowedSendState({ ...allowedSendState, startTime: newStartTime?.toString(), startTimeError: false })
      setState({ ...state, toastMessage, toastSuccess: true })
    } else {
      setState({ ...state, toastMessage: t('Unable to update the Start Time'), toastSuccess: false })
    }
  }

  const onChangeEndTime = async (newEndTime: string | Date) => {
    if (newEndTime === allowedSendState.endTime) {
      setAllowedSendState({ ...allowedSendState, endTimeError: false })
      return
    }

    if (newEndTime <= allowedSendState.startTime) {
      setAllowedSendState({ ...allowedSendState, endTimeError: true })
      return
    }

    const range = validateTimerange(allowedSendState.startTime, newEndTime as string)

    if (range < MIN_HOUR_DIFFERENCE) {
      setAllowedSendState({ ...allowedSendState, endTimeError: true })
      return
    }

    const { data } = await client.mutate<SetAllowedSendWindowMutation, SetAllowedSendWindowMutationVariables>({
      mutation: setAllowedSendWindow,
      variables: {
        accountId,
        sendTimeStart: allowedSendState.startTime ?? defaultAllowedSendState.startTime,
        sendTimeEnd: (newEndTime ?? defaultAllowedSendState.endTime).toString(),
        allowOverride: allowedSendState.allowOverride,
      },
    })

    if (data && data.setAllowedSendWindow) {
      const toastMessage = newEndTime ? t('Updated the End Time') : ''
      setAllowedSendState({ ...allowedSendState, endTime: newEndTime?.toString(), endTimeError: false })
      setState({ ...state, toastMessage, toastSuccess: true })
    } else {
      setState({ ...state, toastMessage: t('Unable to update the End Time'), toastSuccess: false })
    }
  }
  //MINES
  const setFrequency = async (newFrequency: number) => {
    if (newFrequency === fatigueRules.fatigueFrequency) return
    const { data } = await client.mutate<SetFatigueRuleMutation, SetFatigueRuleMutationVariables>({
      mutation: setFatigueRule,
      variables: {
        accountId,
        fatigueFrequency: newFrequency,
        fatigueInterval: fatigueRules.fatigueInterval,
      },
    })

    if (data && data.setFatigueRule) {
      const toastMessage = newFrequency ? t('Updated fatigue rules') : ''
      setFatigueRules({ ...fatigueRules, fatigueFrequency: newFrequency })
      setState({ ...state, toastMessage, toastSuccess: true })
    } else {
      setState({ ...state, toastMessage: t('Number must be between 1-9'), toastSuccess: false })
    }
  }

  const setInterval = async (newInterval: number) => {
    if (newInterval === fatigueRules.fatigueInterval) return
    const { data } = await client.mutate<SetFatigueRuleMutation, SetFatigueRuleMutationVariables>({
      mutation: setFatigueRule,
      variables: {
        accountId,
        fatigueInterval: newInterval ?? defaultFatigueRules.fatigueInterval,
        fatigueFrequency: fatigueRules.fatigueFrequency ?? defaultFatigueRules.fatigueFrequency,
      },
    })

    if (data && data.setFatigueRule) {
      const toastMessage = newInterval ? t('Updated fatigue rules') : ''
      setFatigueRules({ ...fatigueRules, fatigueInterval: newInterval })
      setState({ ...state, toastMessage, toastSuccess: true })
    } else {
      setState({ ...state, toastMessage: t('Unable to update'), toastSuccess: false })
    }
  }

  const clearToast = () => setState({ ...state, toastMessage: '' })

  return (
    <Settings
      data={{
        hasInternationalSending: state.hasInternationalSending,
        allowOverride: allowedSendState.allowOverride,
        startTime: allowedSendState.startTime,
        startTimeError: allowedSendState.startTimeError,
        endTime: allowedSendState.endTime,
        endTimeError: allowedSendState.endTimeError,
        fatigueFrequency: fatigueRules.fatigueFrequency,
        fatigueInterval: fatigueRules.fatigueInterval,
      }}
      setFrequency={setFrequency}
      setInterval={setInterval}
      onChangeInternationalSending={onChangeInternationalSending}
      onChangeAllowOverride={onChangeAllowOverride}
      onChangeStartTime={onChangeStartTime}
      onChangeEndTime={onChangeEndTime}
      dataTest={dataTest}
      loading={internationalSendingLoading || allowedSendLoading || fatigueLoading}
      error={internationalSendingError || allowedSendError || fatigueError}
      toastMessage={state.toastMessage}
      toastSuccess={state.toastSuccess}
      clearToast={clearToast}
    />
  )
}

export default SettingsContainer
