import React, { FC } from 'react'

import Modal, { ModalHeader, ModalBody } from '@components/Modal'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import { useTranslation } from '@const/globals'
import SMSOptinViewAllContainer from '@src/pages/sms/marketingListOptIns/components/SMSOptinViewAll/SMSOptinViewAllContainer'

interface Props {
  isOpen: boolean
  onOpenViewAll: () => void
}

const OptInViewAllModal: FC<Props> = ({ isOpen, onOpenViewAll }: Props) => {
  const { t } = useTranslation()
  return (
    <Modal
      isOpen={isOpen}
      header={
        <ModalHeader headerType={ModalHeaderType.List} closeButton={onOpenViewAll}>
          {t('All SMS Opt-Ins')}
        </ModalHeader>
      }
    >
      <ModalBody>
        <SMSOptinViewAllContainer />
      </ModalBody>
    </Modal>
  )
}

export default OptInViewAllModal
