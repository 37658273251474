import { ApolloClient } from '@apollo/client'
import { Status } from '@components/StatusToast/StatusToast'
import { legacyActonContext, rootContext } from '@const/globals'
import { default as getSegmentData } from '@graphql/microservices/categorization/getItem'
import getItemsInFolderAndLabels from '@graphql/microservices/categorization/getItemsInFolderAndLabels'
import setFavoriteItems from '@graphql/microservices/categorization/setFavoriteItem'
import setItemPosition from '@graphql/microservices/categorization/setItemPosition'
import unifiedListFieldMappings from '@graphql/microservices/list/getUnifiedListFieldMappings'
import cloneSegment from '@graphql/microservices/segment/cloneSegment'
import deleteSegments from '@graphql/microservices/segment/deleteSegments'
import getAssets from '@graphql/microservices/segment/getAssets'
import refreshBounceRecordCount from '@graphql/microservices/segment/refreshBounceRecordCount'
import refreshRecordCount from '@graphql/microservices/segment/refreshRecordCount'
import regenerateSplits from '@graphql/microservices/segment/regenerateSplits'
import {
  GetItemQuery,
  GetItemQueryVariables,
  GetItemsInFolderAndLabelsQuery,
  GetItemsInFolderAndLabelsQueryVariables,
  SetFavoriteItemMutation,
  SetFavoriteItemMutationVariables,
  SetItemPositionMutation,
  SetItemPositionMutationVariables,
  SubTypeDto,
} from '@graphql/types/microservice/categorization-types'
import { SyncedSegment } from '@graphql/types/microservice/crm-types'
import { GetImportSyncJobBySegmentIdsQueryVariables } from '@graphql/types/microservice/entity-upload-types'
import { GetUnifiedListFieldMappingsQuery, GetUnifiedListFieldMappingsQueryVariables } from '@graphql/types/microservice/list-types'
import {
  CloneSegmentMutation,
  CloneSegmentMutationVariables,
  DeleteSegmentsMutation,
  DeleteSegmentsMutationVariables,
  LabelDtoInput,
  RefreshBounceRecordCountMutation,
  RefreshBounceRecordCountMutationVariables,
  RefreshRecordCountMutation,
  RefreshRecordCountMutationVariables,
  RegenerateSplitsMutation,
  RegenerateSplitsMutationVariables,
  SegmentDtoInput,
} from '@graphql/types/microservice/segment-types'
import { AccountSettings, ItemDto } from '@graphql/types/query-types'
import { SetContainerValues } from '@interface/ListPage.context'
import { SyncedSegmentDetailsRequestType } from '@src/pages/ContactSegments/components/AddContactsFromCRM/GraphQL/AddContactsFromCRMRequests.crm.graphQL'
import { SegmentAsset } from '@src/pages/ContactSegments/components/ContentWarning/ContentWarning'
import { DeleteSyncedSegmentsRequestType, GetCRMSegmentsInfoRequestType } from '@src/pages/ContactSegments/GraphQL/ContactSegments.crm.graphQL'
import { ContactSegmentsEntityUploadServiceRequest } from '@src/pages/ContactSegments/GraphQL/ContactSegments.entity-upload.graphQL'
import { ALL_ACTON_CONTACTS_SEGMENT_NAME } from '@src/pages/ContactSegments/utils/ContactSegments.constants'
import { getFavoriteSegmentsUtils } from '@src/pages/ContactSegments/utils/FiltersUtils'
import { IMPORT_CONTACTS_URL } from '@src/pages/importcontacts/utils/ImportContactsContainerUtils'
import { filterNotEmptyArray } from '@utils/array'
import {
  doFilterBounce,
  doFilterSms,
  isBounceSegment,
  ITEM_SUB_TYPE_FROM_DTO,
  ItemSubType,
  ItemSubTypeDtoName,
  ItemType,
} from '@utils/categorization'
import { buildSegment, buildSegmentHierarchy, buildSegments, ContactSegmentsState, Segment } from '@utils/contactSegments/contactSegments.utils'
import { ActionResult, Update } from '@utils/contactSegments/context/ContactSegmentsContext'
import { CRM_STATUS } from '@utils/crm.utils'
import { FilterTypes } from '@utils/filter'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { logNewRelicError } from '@utils/new-relic.utils'

type SegmentWithRawAttributes = Segment & { subTypeDTO: SubTypeDto[]; parentId: string }

export const useMicroserviceClients = () => {
  const { client: listClient } = useMicroserviceClient({ serviceName: MicroserviceClients.LIST })
  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })
  const { client: segmentClient } = useMicroserviceClient({ serviceName: MicroserviceClients.SEGMENT })
  const { client: crmClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CRM })
  return { listClient, categorizationClient, segmentClient, crmClient }
}

export const cloneSegmentUtils = async (
  segment: any,
  newSegmentName: any,
  setContainerValues: SetContainerValues<ContactSegmentsState>,
  containerValues: ContactSegmentsState,
  client: ApolloClient<any>,
  labels: LabelDtoInput[],
  folderId?: number
): Promise<ActionResult | void> => {
  setContainerValues({ ...containerValues, loading: true })
  return client
    .mutate<CloneSegmentMutation, CloneSegmentMutationVariables>({
      mutation: cloneSegment,
      variables: {
        type: ItemType.SEGMENT,
        srcId: segment.externalId,
        newName: newSegmentName,
        subSegments: false,
        labels: labels,
        folderId: folderId,
      },
    })
    .then(({ data }) => {
      setContainerValues((containerValues: ContactSegmentsState) => ({ ...containerValues, loading: false }))
      return data?.cloneSegment as ActionResult
    })
    .catch(() => setContainerValues((containerValues: ContactSegmentsState) => ({ ...containerValues, loading: false })))
}

export const deleteSegmentsUtils = (
  segments: SegmentDtoInput[],
  setContainerValues: SetContainerValues<ContactSegmentsState>,
  client: ApolloClient<any>
) => {
  setContainerValues((containerValues) => ({ ...containerValues, isProcessingAction: true }))
  return client
    .mutate<DeleteSegmentsMutation, DeleteSegmentsMutationVariables>({
      mutation: deleteSegments,
      variables: {
        type: ItemType.SEGMENT,
        segments,
      },
    })
    .then(() => {
      setContainerValues((containerValues) => ({
        ...containerValues,
        isProcessingAction: false,
      }))
    })
}

export const getTagItemsUtils = (
  setFtpSegmentData: (newSegments: Segment[]) => Promise<Segment[]>,
  setContainerValues: SetContainerValues<ContactSegmentsState>,
  syncedSegmentDetailsRequest: SyncedSegmentDetailsRequestType,
  getCRMSegmentsInfoRequest: GetCRMSegmentsInfoRequestType,
  hasCRMConnected: boolean,
  client: ApolloClient<any>,
  pageNumber: number,
  pageSize: number,
  tagId: number,
  refreshRecordCount: Function,
  { hasPurchasedSMS, isUsingExternalSegmentationService }: AccountSettings,
  needsReRender?: boolean
) => {
  !needsReRender && initSegmentLoading(setContainerValues, pageNumber)
  client
    .query<GetItemsInFolderAndLabelsQuery, GetItemsInFolderAndLabelsQueryVariables>({
      query: getItemsInFolderAndLabels,
      fetchPolicy: 'network-only',
      variables: {
        types: [ItemType.SEGMENT, ItemType.BOUNCE],
        labelIds: [tagId],
        pageNumber,
        pageSize,
      },
    })
    .then(({ data }) => {
      let items = (data.getItemsInFolderAndLabels?.filter(filterNotEmptyArray) ?? []) as ItemDto[]
      if (!hasPurchasedSMS) {
        items = doFilterSms(items)
      }
      if (isUsingExternalSegmentationService) {
        items = doFilterBounce(items)
      }
      setSegments(
        setFtpSegmentData,
        setContainerValues,
        syncedSegmentDetailsRequest,
        getCRMSegmentsInfoRequest,
        hasCRMConnected,
        items,
        pageNumber,
        pageSize,
        refreshRecordCount,
        !needsReRender
      )
    })
    .catch(() => {
      setContainerValues((containerValues) => ({
        ...containerValues,
        loading: false,
        statusToast: { statusMessage: 'An unexpected error occurred while fetching the segments', status: Status.FAIL, showStatusToast: true },
      }))
    })
}

export const getSegmentUtils = (
  segmentId: number,
  type: ItemType,
  setContainerValues: SetContainerValues<ContactSegmentsState>,
  client: ApolloClient<any>
) => {
  const abortController = new AbortController()
  const { signal } = abortController
  client
    .query<GetItemQuery, GetItemQueryVariables>({
      query: getSegmentData,
      fetchPolicy: 'network-only',
      variables: {
        itemId: segmentId,
        type,
      },
      context: {
        fetchOptions: {
          signal,
        },
      },
    })
    .then(({ data }) => {
      setContainerValues((containerValues) => ({
        ...containerValues,
        showDetails: buildSegment(data.getItem),
      }))
    })

  return abortController
}

const doRefreshRecordCount = (segments: string[], client: ApolloClient<any>, hasBounces = false) => {
  if (segments.length > 0) {
    return client.mutate<
      RefreshRecordCountMutation | RefreshBounceRecordCountMutation,
      RefreshRecordCountMutationVariables | RefreshBounceRecordCountMutationVariables
    >({
      mutation: hasBounces ? refreshBounceRecordCount : refreshRecordCount,
      variables: hasBounces ? { verbs: segments } : { segmentIds: segments },
    })
  }
}

export const refreshRecordCountUtils = (
  selectedSegments: string[],
  setContainerValues: SetContainerValues<ContactSegmentsState>,
  client: ApolloClient<any>,
  segmentWasEdited?: boolean
) => {
  const { regularSegments, bounces } = selectedSegments.reduce(
    (segments: { regularSegments: string[]; bounces: string[] }, segment) => ({
      ...segments,
      ...(isBounceSegment(segment) ? { bounces: [...segments.bounces, segment] } : { regularSegments: [...segments.regularSegments, segment] }),
    }),
    { regularSegments: [], bounces: [] }
  )

  Promise.all([doRefreshRecordCount(bounces, client, true), doRefreshRecordCount(regularSegments, client)])
    .then(() => {
      !segmentWasEdited &&
        setContainerValues((containerValues) => ({
          ...containerValues,
          statusToast: {
            statusMessage: `We're counting your contacts now. This may take some time. Check back later for an updated contact count.`,
            status: Status.SUCCESS,
            showStatusToast: true,
          },
        }))
    })
    .catch((error) => {
      setContainerValues((containerValues) => ({
        ...containerValues,
        statusToast: {
          statusMessage: 'Something went wrong on our end. Please try again.',
          status: Status.FAIL,
          showStatusToast: true,
        },
      }))
      logNewRelicError(error)
    })
}

export const regenerateSplitsUtils = (id: string, client: ApolloClient<any>) => {
  return client.mutate<RegenerateSplitsMutation, RegenerateSplitsMutationVariables>({
    mutation: regenerateSplits,
    errorPolicy: 'all',
    variables: {
      srcId: id,
    },
  })
}

export const setFavoriteItemsUtils = (
  itemsIds: number[],
  isFavorite: boolean,
  setContainerValues: SetContainerValues<ContactSegmentsState>,
  containerValues: ContactSegmentsState,
  client: ApolloClient<any>,
  type = ItemType.SEGMENT
) => {
  client
    .mutate<SetFavoriteItemMutation, SetFavoriteItemMutationVariables>({
      mutation: setFavoriteItems,
      variables: {
        type,
        isFavorite,
        itemIds: itemsIds,
      },
    })
    .then(({ data }) => {
      const favoriteSegmentList = data?.setFavoriteItem as Segment[]
      if (favoriteSegmentList) {
        const { filterActive, searchItemsResults, items, search } = containerValues
        if (filterActive?.name === FilterTypes.FAVORITES && !search) {
          setContainerValues((state) => ({ ...state, isProcessingAction: true }))
          getFavoriteSegmentsUtils(setContainerValues, client, 0, 200, true, [ItemType.BOUNCE, ItemType.SEGMENT]).then(() => {
            setContainerValues((state) => ({ ...state, isProcessingAction: false }))
          })
        } else {
          const actualSegments = setFavorite(favoriteSegmentList, search ? searchItemsResults : items)
          setContainerValues((containerValues) => ({
            ...containerValues,
            ...(search ? { searchItemsResults: actualSegments } : { items: actualSegments }),
            loading: false,
          }))
        }
      }
    })
}

export const saveSortedSegments = (
  itemIds: number[],
  position: number,
  setContainerValues: SetContainerValues<ContactSegmentsState>,
  client: ApolloClient<any>
) => {
  return client
    .mutate<SetItemPositionMutation, SetItemPositionMutationVariables>({
      mutation: setItemPosition,
      variables: {
        itemIds,
        position,
        type: ItemType.SEGMENT,
      },
    })
    .catch(() => {
      setContainerValues((containerValues) => ({
        ...containerValues,
        statusToast: { statusMessage: 'An unexpected error occurred while sorting the segments', status: Status.FAIL, showStatusToast: true },
      }))
    })
}

const setFavorite = (modifiedSegments: Segment[], segments: Segment[]): Segment[] => {
  return segments.reduce((segments: Segment[], segment: Segment) => {
    const modifiedSegment = modifiedSegments.find(({ id }) => id === segment.id)
    return [
      ...segments,
      {
        ...segment,
        ...(modifiedSegment ? { isFavorite: modifiedSegment.isFavorite } : {}),
        ...(segment.subRows ? { subRows: setFavorite(modifiedSegments, segment.subRows) } : {}),
      },
    ]
  }, [])
}

export const sendEmailToSegment = (segmentId: string) => {
  window.open(`${legacyActonContext}/_compose/start.jsp?srcId=${segmentId}&allContacts=1&selectedLists=1`, `_blank`)
}

export const goCreateSegment = (
  currentUrl: string,
  options?: { activeFolderId?: number; parentId?: string; isFormSubmission?: boolean; isWebinarRegistration?: boolean }
) => {
  const { activeFolderId, parentId = 'l-unified-contacts', isFormSubmission = false, isWebinarRegistration = false } = { ...options }
  const params = new URLSearchParams()
  if (activeFolderId) {
    params.set('folderId', activeFolderId.toString())
  }
  if (isFormSubmission) {
    params.set('isFormSubmission', 'true')
  }
  if (isWebinarRegistration) {
    params.set('isWebinarRegistration', 'true')
  }
  params.set('back', currentUrl)
  window.open(`${rootContext}/classic/segment/_new_/${parentId}?${params.toString()}`, '_self')
}

export const goToImportContacts = (isFTPConnectorActive: boolean, update: Update) => {
  if (isFTPConnectorActive) {
    update({ selectingImportContactsSource: true })
  } else {
    window.open(`${rootContext}${IMPORT_CONTACTS_URL}`, '_self')
  }
}

export const goToDataManagement = (currentTab = '') => {
  const tabPath = currentTab ? `?currentTab=${currentTab}` : ''
  window.open(`${rootContext}/datamanagement${tabPath}`, '_self')
}

export const initSegmentLoading = (setContainerValues: SetContainerValues<ContactSegmentsState>, pageNumber?: number, needsLoading?: boolean) => {
  setContainerValues((containerValues) => {
    if (pageNumber === 0) {
      return { ...containerValues, items: [], allItemsLoaded: false, loading: needsLoading ?? true }
    } else {
      return { ...containerValues, loading: needsLoading ?? true }
    }
  })
}

const refreshCreatedSegments = (segments: Segment[], refreshRecordCount: Function) => {
  const createdSegmentIds = segments.filter(({ createdTime, updatedTime }) => createdTime === updatedTime).map(({ externalId }) => externalId)
  createdSegmentIds.length > 0 && refreshRecordCount(createdSegmentIds)
}

const setUpdatedSegments = async (
  setFtpSegmentData: (newSegments: Segment[]) => Promise<Segment[]>,
  setContainerValues: SetContainerValues<ContactSegmentsState>,
  updatedSegments: any = [],
  pageNumber: number,
  pageSize: number,
  refreshRecordCount?: Function,
  updatePage = true
) => {
  const segments = buildSegments(updatedSegments)
  const builtSegments = buildSegmentHierarchy(segments)
  const segmentsWithFtpData = await setFtpSegmentData(builtSegments)
  setContainerValues(({ currentPage, ...containerValues }) => ({
    ...containerValues,
    items: pageNumber === 0 ? segmentsWithFtpData : [...containerValues.items, ...segmentsWithFtpData],
    currentPage: segments.length > 0 && updatePage ? pageNumber : currentPage,
    allItemsLoaded: segments.length < pageSize,
    loading: false,
  }))
  refreshRecordCount && refreshCreatedSegments(segments, refreshRecordCount)
}

export const setFtpSegmentsLastExecutions = async (
  segments: Segment[],
  getFtpSyncsBySegmentRequest: ContactSegmentsEntityUploadServiceRequest['getFtpSyncsBySegmentRequest'],
  getFtpExportSyncsBySegmentRequest: ContactSegmentsEntityUploadServiceRequest['getFtpExportSyncsBySegmentRequest'],
  getLastSegmentExecutionRequest: ContactSegmentsEntityUploadServiceRequest['getLastSegmentExecutionRequest'],
  getLastSegmentExportExecutionRequest: ContactSegmentsEntityUploadServiceRequest['getLastSegmentExportExecutionRequest']
) => {
  const segmentsWithoutFtpData = segments.filter(({ ftpData }) => !ftpData)
  if (segmentsWithoutFtpData.length > 0) {
    const allContactsId = segments.find((segment) => segment.name === ALL_ACTON_CONTACTS_SEGMENT_NAME)?.externalId
    const requestParams: GetImportSyncJobBySegmentIdsQueryVariables = {
      segmentIds: segmentsWithoutFtpData.map(({ externalId }) => externalId),
      allContactsId,
      page: 0,
      pageSize: 1000,
    }
    const [importFtpSyncsBySegmentId, exportFtpSyncsBySegmentId, ftpSegmentsExecutionDetails, ftpExportSegmentsExecutionDetails] = await Promise.all([
      getFtpSyncsBySegmentRequest(requestParams),
      getFtpExportSyncsBySegmentRequest(requestParams),
      getLastSegmentExecutionRequest(allContactsId),
      getLastSegmentExportExecutionRequest(allContactsId),
    ])

    return segments.map((segment) => {
      const importFtpSync = importFtpSyncsBySegmentId[segment.externalId] ?? []
      const importExecutionStatus = ftpSegmentsExecutionDetails[segment.externalId]
      const exportFtpSync = exportFtpSyncsBySegmentId[segment.externalId] ?? []
      const exportExecutionStatus = ftpExportSegmentsExecutionDetails[segment.externalId]
      if (importFtpSync.length > 0 || exportFtpSync.length > 0) {
        return {
          ...segment,
          ftpData: {
            import: {
              syncJobs: importFtpSync,
              executionStatus: importExecutionStatus,
            },
            export: {
              syncJobs: exportFtpSync,
              executionStatus: exportExecutionStatus,
            },
          },
        }
      }
      return segment
    })
  }
  return segments
}

const setCRMStatus = async (
  newSegments: any = [],
  syncedSegmentDetailsRequest: SyncedSegmentDetailsRequestType,
  getCRMSegmentsInfoRequest: GetCRMSegmentsInfoRequestType
) => {
  const updatedSegments = await setOngoingSyncStatusUtils(newSegments, syncedSegmentDetailsRequest)
  return getCRMSegmentsInfoUtils(updatedSegments, getCRMSegmentsInfoRequest)
}

export const setSegments = async (
  setFtpSegmentData: (newSegments: Segment[]) => Promise<Segment[]>,
  setContainerValues: SetContainerValues<ContactSegmentsState>,
  syncedSegmentDetailsRequest: SyncedSegmentDetailsRequestType,
  getCRMSegmentsInfoRequest: GetCRMSegmentsInfoRequestType,
  hasCRMConnected: boolean,
  newSegments: ItemDto[] = [],
  pageNumber: number,
  pageSize: number,
  refreshRecordCount?: Function,
  updatePage = true
) => {
  if (hasCRMConnected) {
    setCRMStatus(newSegments, syncedSegmentDetailsRequest, getCRMSegmentsInfoRequest).then((updatedSegments) => {
      setUpdatedSegments(setFtpSegmentData, setContainerValues, updatedSegments, pageNumber, pageSize, refreshRecordCount, updatePage)
    })
  } else {
    setUpdatedSegments(setFtpSegmentData, setContainerValues, newSegments, pageNumber, pageSize, refreshRecordCount, updatePage)
  }
}

export enum CloneSegmentServiceMessages {
  DIRECT_SEGMENT_NOT_ALLOWED = 'Direct Select segments can not be duplicated.',
  DUPLICATE_SEGMENT_NAME_NOT_ALLOWED = 'A segment with the same name already exists.',
  DEFAULT = 'Error while trying to duplicate segment.',
}

export const getAssetsUtils = (segments: Segment[], setState: Function, client: ApolloClient<any>) => {
  const segmentIds = segments.map((segment) => segment.externalId)
  client
    .query({
      query: getAssets,
      fetchPolicy: 'network-only',
      variables: {
        segmentIds,
      },
    })
    .then(({ data }) => {
      const { getAssets } = data
      setState((state: any) => ({ ...state, segmentAssetToDelete: getAssets as SegmentAsset[], segmentsToDelete: segments }))
    })
}

export const getUnifiedListFieldMappingsUtils = async (client: ApolloClient<any>) => {
  return await client.query<GetUnifiedListFieldMappingsQuery, GetUnifiedListFieldMappingsQueryVariables>({
    query: unifiedListFieldMappings,
    fetchPolicy: 'network-only',
  })
}

const setCRMErrorDetails = (errorMessage: string, segments: SegmentWithRawAttributes[]): SegmentWithRawAttributes[] =>
  segments.map((segment) => {
    const { crmStatus, parentId } = segment
    const subTypes = segment.subTypeDTO?.map(({ name }: SubTypeDto) => ITEM_SUB_TYPE_FROM_DTO[name as ItemSubTypeDtoName]) ?? []
    const isCRMSourced = subTypes.includes(ItemSubType.CRM_SOURCED) && !parentId
    return {
      ...segment,
      crmStatus: isCRMSourced ? CRM_STATUS.CRM_SYNC_FAILED : crmStatus,
      crmErrorDetails: isCRMSourced ? errorMessage : undefined,
    }
  })

const setCRMErrorStatus = (crmSegmentsInfo: any, segments: Segment[]): Segment[] =>
  segments.map((segment) => {
    const { crmStatus, externalId } = segment
    if (!!crmSegmentsInfo[externalId]) {
      const { syncedSegment, syncWarning } = crmSegmentsInfo[externalId]
      return {
        ...segment,
        crmStatus: syncedSegment && !!syncWarning?.length ? syncWarning[0] : crmStatus || null,
        crmErrorDetails: undefined,
      }
    }
    return {
      ...segment,
      crmStatus: crmStatus || null,
      crmErrorDetails: undefined,
    }
  })

export const getCRMSegmentsInfoUtils = async (segments: SegmentWithRawAttributes[], getCRMSegmentsInfoRequest: GetCRMSegmentsInfoRequestType) => {
  try {
    const segmentIdentifiers = segments.filter(({ parentId }) => parentId === null || parentId === undefined).map(({ externalId }) => externalId)
    if (!!segmentIdentifiers.length) {
      const { data, errors } = await getCRMSegmentsInfoRequest({ segmentIdentifiers })
      if (data?.crmSegmentsInfo) {
        return setCRMErrorStatus(data?.crmSegmentsInfo, segments)
      } else if (errors) {
        logNewRelicError(errors[0].message)
        return setCRMErrorDetails(errors[0].message, segments as SegmentWithRawAttributes[])
      }
    }
    return segments
  } catch (e) {
    logNewRelicError(e)
    return setCRMErrorDetails(typeof e === 'string' ? e : e instanceof Error ? e.message : '', segments as SegmentWithRawAttributes[])
  }
}

const setOngoingSyncStatus = (syncedSegmentDetails: SyncedSegment[], segments: SegmentWithRawAttributes[]): SegmentWithRawAttributes[] =>
  segments.map((segment) => {
    const { externalId, isEditable, subTypes } = segment
    const { ongoingSync, syncedSegmentId } = syncedSegmentDetails.find(({ segmentIdentifier }) => segmentIdentifier === externalId) ?? {}
    return {
      ...segment,
      crmStatus: ongoingSync || (!isEditable && subTypes?.includes(ItemSubType.CRM_SOURCED)) ? CRM_STATUS.ON_GOING_SYNC : undefined,
      syncedSegmentId,
    }
  })

export const setOngoingSyncStatusUtils = async (
  segments: SegmentWithRawAttributes[],
  syncedSegmentDetailsRequest: SyncedSegmentDetailsRequestType
): Promise<SegmentWithRawAttributes[]> => {
  try {
    const segmentIdentifiers = segments
      .filter(({ parentId }) => parentId === null || parentId === undefined)
      .map(({ externalId }) => externalId ?? '')
    if (!!segmentIdentifiers.length) {
      const { data, errors } = await syncedSegmentDetailsRequest({ segmentIdentifiers })
      if (!!data?.syncedSegmentDetails) {
        return setOngoingSyncStatus(data?.syncedSegmentDetails as SyncedSegment[], segments)
      } else if (errors) {
        logNewRelicError(errors[0].message)
        return setCRMErrorDetails(errors[0].message, segments as SegmentWithRawAttributes[])
      }
    }
    return segments
  } catch (e) {
    logNewRelicError(e)
    return setCRMErrorDetails(typeof e === 'string' ? e : e instanceof Error ? e.message : '', segments as SegmentWithRawAttributes[])
  }
}

export const getCRMSourcedSegmentDetailsUtils = async (
  segmentIdentifiers: string[],
  syncedSegmentDetailsRequest: SyncedSegmentDetailsRequestType
): Promise<SyncedSegment[] | undefined> => {
  try {
    if (segmentIdentifiers.length > 0) {
      const { data } = await syncedSegmentDetailsRequest({ segmentIdentifiers })
      if (data?.syncedSegmentDetails) {
        return data.syncedSegmentDetails as SyncedSegment[]
      }
    } else {
      return []
    }
  } catch (e) {
    logNewRelicError(e)
  }
}

export const deleteSyncedSegmentsUtils = async (deleteSyncedSegmentsRequest: DeleteSyncedSegmentsRequestType, segmentIdentifiers: string[]) => {
  try {
    await deleteSyncedSegmentsRequest({ segmentIdentifiers })
  } catch (e) {
    logNewRelicError(e)
  }
}
