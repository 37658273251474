import { Dispatch, SetStateAction } from 'react'

import { Update } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { EmptyListingProps } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { legacyActonContext } from '@const/globals'
import { FilterDefinition, favoriteFilter, allEmailTemplatesFilter, createdByMeFilter, FilterTypes, eventTriggeredEmailsFilter } from '@utils/filter'

export const getCustomEmptyListingProps = (
  setFilter: (filterDefinition: FilterDefinition, customFilterSelected: boolean) => void,
  _update: Update,
  filter?: FilterDefinition,
  openTemplateModal?: Dispatch<SetStateAction<boolean>>
): EmptyListingProps => {
  if (filter === favoriteFilter) {
    return {
      imgSrc: StaticImageNames.emptyFavorites,
      headline: "You don't have any favorites yet",
      text: 'Add hearts to templates that you want to easily find later',
      buttonText: 'View all templates',
      buttonOnClick: () => setFilter(allEmailTemplatesFilter, false),
    }
  } else if (filter === createdByMeFilter) {
    return {
      imgSrc: StaticImageNames.emptyCreatedByMe,
      headline: 'You haven’t created any templates yet',
      text: 'You’ll find email templates here once you’ve created them',
      buttonText: 'Create template',
      buttonOnClick: () => window.open(`${legacyActonContext}/_compose/start.jsp?template=1`),
    }
  } else if (filter === eventTriggeredEmailsFilter) {
    return {
      imgSrc: StaticImageNames.emptyChart,
      headline: "You haven't created any event triggered templates yet",
      text: 'You’ll find event triggered email templates here once you’ve created them',
      buttonText: 'Check template',
      buttonOnClick: () => setFilter(allEmailTemplatesFilter, false),
    }
  } else {
    return {
      imgSrc: StaticImageNames.emptyChart,
      headline: "Let's start building",
      text: 'ListPage.Common.Templates.EmptyState.AllFilter.Text',
      buttonText: 'Check out our template catalog',
      buttonOnClick: () => openTemplateModal?.(true),
      linkText: 'ListPage.EmailTemplates.EmptyState.AllFilter.LinkText',
      link: 'https://connect.act-on.com/hc/en-us/articles/360023756174-Create-an-Email-Template',
      hideIcon: false,
    }
  }
}

export const getActiveFilter = (filter: string) => {
  switch (filter) {
    case FilterTypes.ALL_EMAIL_TEMPLATES:
      return allEmailTemplatesFilter
    case FilterTypes.FAVORITES:
      return favoriteFilter
    case FilterTypes.CREATED_BY_ME:
      return createdByMeFilter
  }
}
