import React, { FC, ReactNode, useContext } from 'react'

import { AssetPickerContext, InfoBannerType } from '@complex/AssetPickerModal/Context/AssetPicker.context'
import Caution from '@components/Caution/Caution'
import InfoAction from '@components/InfoAction/InfoAction'
import { SvgNames } from '@components/Svg'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import AssetPickerInfo from './AssetPickerInfo'

interface AssetPickerInfoContainerProps {
  infoBanner: InfoBannerType
}

export enum AssetPickerInfoType {
  DEFAULT = 'default',
  WARNING = 'warning',
  INFO = 'info',
}

const rootClass = 'asset-picker-info'

const AssetPickerInfoContainer: FC<AssetPickerInfoContainerProps> = (props: AssetPickerInfoContainerProps) => {
  const {
    infoBanner: { customInfo, values },
  } = props
  const { text, title, type, showBorderBottom, hideOnSearch } = values || {}
  const {
    values: {
      listingPageState: { search },
    },
  } = useContext(AssetPickerContext)
  const { t } = useTranslation()

  const defaultInfoText: { [key: string]: ReactNode } = {
    [AssetPickerInfoType.DEFAULT]: <Typography className={`${rootClass}__default`} text={t(text)} type={TextType.BODY_TEXT_LIGHT} />,
    [AssetPickerInfoType.WARNING]: <Caution className={`${rootClass}__warning`} message={text} />,
    [AssetPickerInfoType.INFO]: <InfoAction className={`${rootClass}__info`} message={text} svgName={SvgNames.lightBulb} />,
  }

  const content = customInfo ?? (text ? defaultInfoText[type as string] : <></>)

  return hideOnSearch && !!search ? (
    <></>
  ) : (
    <AssetPickerInfo className={showBorderBottom ? `${rootClass}__show-border-bottom` : undefined} title={t(title)}>
      {content}
    </AssetPickerInfo>
  )
}

export default AssetPickerInfoContainer
