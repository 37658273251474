import { AccountState } from '@utils/account/reducer'
import { getAccount } from '@utils/account/selectors'
import { NavigationState, getNavigation } from '@utils/navigation/navigation.utils'
import { getEditProgram } from '@utils/program/program'
import { EditProgramState } from '@utils/program/program.constants'

export interface EditProgramStateProps {
  navigation: NavigationState
  editProgram: EditProgramState
  account: AccountState
}

function mapStateToProps(state: any): EditProgramStateProps {
  return {
    navigation: getNavigation(state),
    editProgram: getEditProgram(state),
    account: getAccount(state),
  }
}

export default mapStateToProps
