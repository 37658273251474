import { gql } from '@apollo/client'

export default gql`
  query getBehaviors {
    getBehaviors {
      verb
      behavior
      description
    }
  }
`
