import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'
import { ConnectDragSource, useDrag, useDrop } from 'react-dnd'

import classNames from 'classnames'

import { isOverUpperHalf } from '@components/ColumnsOrderModal/utils/ColumnsOrderModal.utils'
import SegmentDefinitionElementWithOperator from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/SegmentDefinition/components/SegmentDefinitionElementWithOperator/SegmentDefinitionElementWithOperator'
import { ITEM_DROPPED_STATE_TIMEOUT } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/SegmentDefinition/SegmentDefinition.utils'
import { CombineRowsType } from '@src/pages/SegmentComposer/SegmentComposer.constants'

import './SegmentDefinitionElementWithDnD.css'

export type DroppableSegmentDefinitionItem = {
  id: string
  operator?: CombineRowsType
}

interface SegmentDefinitionElementWithDnDProps {
  children: (dragSource: ConnectDragSource, isRecentlyDropped?: boolean) => ReactNode
  className?: string
  dataTest?: string
  itemId: string
  operator?: CombineRowsType
  onDrop: (droppedItem: DroppableSegmentDefinitionItem, currentGroupId: string, isOverTop: boolean) => void
  recentlyDroppedItemId?: string
}

const rootClass = 'segment-definition-element-with-dn-d'
const ITEM_TYPE = 'segment-definition-element'

const SegmentDefinitionElementWithDnD: FC<SegmentDefinitionElementWithDnDProps> = (props: SegmentDefinitionElementWithDnDProps) => {
  const { dataTest = rootClass, children, className = '', itemId, operator, onDrop, recentlyDroppedItemId } = props

  const [isOverTop, setIsOverTop] = useState(false)
  const [isRecentlyDropped, setIsRecentlyDropped] = useState(false)

  const rowRef = useRef<HTMLDivElement | null>(null)

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ITEM_TYPE,
      item: { id: itemId, operator },
      collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    }),
    [itemId, operator]
  )

  const [{ isOver }, drop] = useDrop({
    accept: ITEM_TYPE,
    drop: (item: DroppableSegmentDefinitionItem, monitor) => {
      if (onDrop && !monitor.didDrop()) {
        onDrop(item, itemId, isOverUpperHalf(rowRef, monitor))
      }
    },
    hover: (_, monitor) => {
      setIsOverTop(isOverUpperHalf(rowRef, monitor))
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
    }),
  })

  useEffect(() => {
    if (itemId === recentlyDroppedItemId) {
      setIsRecentlyDropped(true)
      setTimeout(() => {
        setIsRecentlyDropped(false)
      }, ITEM_DROPPED_STATE_TIMEOUT)
    }
  }, [recentlyDroppedItemId, itemId])

  drop(rowRef)

  return (
    <div
      className={classNames(rootClass, className, {
        [`${rootClass}__is-over`]: isOver && !isOverTop,
        [`${rootClass}__is-over-top`]: isOver && isOverTop,
      })}
      data-test={dataTest}
      ref={rowRef}
    >
      <SegmentDefinitionElementWithOperator
        className={classNames({ [`${rootClass}__dragging`]: isDragging })}
        dataTest={`${dataTest}-element-with-operator`}
        operator={operator}
      >
        {children(drag, isRecentlyDropped)}
      </SegmentDefinitionElementWithOperator>
    </div>
  )
}

export default SegmentDefinitionElementWithDnD
