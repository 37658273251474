import { gql } from '@apollo/client'

export default gql`
  query checkListMaintenanceProgramErrorsBeforeSave($program: ListMaintenanceProgramInput!) {
    checkListMaintenanceProgramErrorsBeforeSave(program: $program) {
      status
      valid
      stepErrors {
        errors
        id
        name
        type
      }
      mainErrors
      exitErrors
      isRunning
    }
  }
`
