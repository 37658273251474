import React from 'react'

import { TFunction } from 'i18next'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import { RowAction } from '@components/Table/Table'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { SubscriptionManagementDTO, VersionState } from '@src/pages/SubscriptionManagement/SubcriptionManagement.constants'
import { Row } from '@tanstack/react-table'
import { relativeDate } from '@utils/date'

const rootClass = 'subscription-management'

const renderVersionState = (versionState: SubscriptionManagementDTO, t: TFunction) => {
  const versionStates = versionState.isEnabled ? VersionState.ENABLED : VersionState.DISABLED
  const { label, icon } = VersionStateValues[versionStates]
  const trigger = <Svg name={icon} type={SvgType.ICON} />
  return (
    <Tooltip trigger={trigger} className={`${rootClass}__version-col-icon`}>
      {t(label)}
    </Tooltip>
  )
}

const renderNeedChanges = (versionState: VersionState, t: TFunction) => {
  const { label, icon } = VersionStateValues[versionState]
  const trigger = <Svg name={icon} type={SvgType.ICON} />
  return (
    <Tooltip trigger={trigger} className={`${rootClass}__version-col-icon-warning`}>
      {t(label)}
    </Tooltip>
  )
}
const renderVersionName = (subscriptionManagement: SubscriptionManagementDTO, t: TFunction) => {
  return (
    <div className={`${rootClass}__version-col`}>
      {renderVersionState(subscriptionManagement, t)}
      <div className={`${rootClass}__version-name`}>
        <TextWithTooltipOnEllip typographyProps={{ text: subscriptionManagement.name, type: TextType.BODY_TEXT, weight: TextWeight.REGULAR }} />
        {subscriptionManagement.status === VersionState.ADDED && renderNeedChanges(subscriptionManagement.status, t)}
      </div>
      {subscriptionManagement.default && <Typography text={'(system default)'} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.REGULAR} inline />}
    </div>
  )
}

const renderVersionDescription = (subscriptionManagement: SubscriptionManagementDTO) => {
  return (
    <div>
      <TextWithTooltipOnEllip typographyProps={{ text: subscriptionManagement.description, type: TextType.BODY_TEXT, weight: TextWeight.REGULAR }} />
    </div>
  )
}

export const columnsSubscriptionManagement = (t: TFunction): ColumnDefWithAdditionalProps<SubscriptionManagementDTO>[] => [
  {
    header: 'version name',
    accessorKey: 'name',
    textAlign: 'left',
    maxSize: 310,
    minSize: 150,
    cell: ({ row: { original } }) => renderVersionName(original, t),
  },
  {
    header: 'description',
    accessorKey: 'description',
    textAlign: 'left',
    maxSize: 240,
    minSize: 150,
    cell: ({ row: { original } }) => renderVersionDescription(original),
  },
  {
    header: 'version language',
    accessorKey: 'version',
    maxSize: 150,
    textAlign: 'left',
    minSize: 100,
  },
  {
    header: 'created',
    accessorKey: 'created',
    textAlign: 'left',
    maxSize: 120,
    minSize: 100,
    cell: ({ row: { original } }) => (original.created ? relativeDate(original.created, true, false) : ''),
  },
]

export const VersionStateValues: { [key in VersionState]: { label: string; icon: SvgNames } } = {
  [VersionState.ENABLED]: { label: 'Subscription Management enabled', icon: SvgNames.circleGreen },
  [VersionState.ADDED]: { label: 'New categories have been added', icon: SvgNames.spamCheckWarning },
  [VersionState.DISABLED]: { label: 'Subscription Management disabled', icon: SvgNames.circle },
}

type RowActionClick = (row: SubscriptionManagementDTO) => void

export const getRowActions = (handleEdit: RowActionClick, handlePreview: RowActionClick, handleUrl: RowActionClick, handleDelete: RowActionClick) => {
  return [
    {
      label: 'Edit',
      icon: SvgNames.pencil,
      hasTooltip: true,
      onClick: (row) => handleEdit(row.original),
    },
    {
      label: 'Preview',
      icon: SvgNames.zoom,
      hasTooltip: true,
      onClick: (row) => handlePreview(row.original),
    },
    {
      label: 'URL',
      icon: SvgNames.url,
      hasTooltip: true,
      onClick: (row) => handleUrl(row.original),
    },
    {
      label: 'Delete',
      icon: SvgNames.delete,
      hidden: (row: Row<SubscriptionManagementDTO>) => row.original.default,
      onClick: (row) => handleDelete(row.original),
    },
  ] as RowAction[]
}
