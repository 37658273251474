import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { Program } from '@graphql/types/query-types'
import { useStatusValues } from '@src/pages/programs/UpgradePrograms/utils/UpgradePrograms.utils'
import { ProgramState } from '@utils/program/program.constants'

import './ProgramSelected.css'

interface ProgramSelectedProps {
  program?: Program
}

const rootClass = 'program-selected'

export const ProgramSelected: FC<ProgramSelectedProps> = ({ program }: ProgramSelectedProps) => {
  const statusValues: { [key in ProgramState]: { icon: ReactNode; text: string } } = useStatusValues()
  const { t } = useTranslation()

  return (
    <div
      className={classNames(rootClass, {
        [`${rootClass}__centered`]: !program,
      })}
    >
      {!!program ? (
        <>
          <Typography text={program?.name} type={TextType.BODY_TEXT} weight={TextWeight.MEDIUM} lineHeight={LineHeight.MEDIUM} />
          <div className={`${rootClass}__status`}>
            {program?.status ? statusValues[program?.status as ProgramState].icon : undefined}
            <Typography
              text={program?.status ? statusValues[(program?.status as ProgramState) ?? ProgramState.ERROR].text : undefined}
              type={TextType.BODY_TEXT_SMALL_LIGHT}
              weight={TextWeight.REGULAR}
              lineHeight={LineHeight.MEDIUM_SMALL}
            />
          </div>
        </>
      ) : (
        <Typography text={t('None selected')} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.ITALIC} lineHeight={LineHeight.MEDIUM_SMALL} />
      )}
    </div>
  )
}
