import { Status } from '@components/StatusToast/StatusToast'
import { Update } from '@utils/contactSegments/context/ContactSegmentsContext'

export const showStatusToast = (update: Update, rootText: string) =>
  update({
    statusToast: {
      statusMessage: `${rootText}.Error`,
      status: Status.FAIL,
      showStatusToast: true,
    },
  })
