import React, { MouseEvent, useCallback, useEffect } from 'react'

import classNames from 'classnames'

import CaretIcon, { CaretIconDirection } from '@components/CaretIcon'
import { SvgType } from '@components/Svg'
import { maxVisibleDepth } from '@components/TableV2/utils/subRowUtils'

import { CommonV2CellProps } from '../../tableV2TS/interfaces'
import { expanderThreadState } from '../../utils/tableV2Utils'

import './Expander.css'

const rootClass = 'expander'

export const Expander = <T extends {}>({ row, cell, parentRows, hoveredRowID, hasToExpand, onRowExpanded }: CommonV2CellProps<T>) => {
  const expandHandler = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      e.preventDefault()
      if (row.getCanExpand()) {
        onRowExpanded?.(row)
      }
      return row.getToggleExpandedHandler()()
    },
    [row]
  )

  useEffect(() => {
    if (row.getCanExpand() && !row.getIsExpanded() && hasToExpand?.(row)) {
      row.getToggleExpandedHandler()()
    }
  }, [hasToExpand, row])

  return (
    <div className={`${rootClass}__border-wrapper`}>
      <>
        {[...Array(row.depth > maxVisibleDepth ? maxVisibleDepth : row.depth)].map((_, i) => {
          const threadState = i < 3 ? expanderThreadState(parentRows, row, i) : true
          return (
            <div
              key={i}
              className={classNames({
                [`${rootClass}__thread`]: threadState,
                [`${rootClass}__thread-hovered`]: threadState && !!hoveredRowID,
                [`line-${row.id}-${i}`]: true,
              })}
            />
          )
        })}

        {row.getCanExpand() && (
          <button className={`${rootClass}__button`} onClick={expandHandler}>
            <CaretIcon direction={CaretIconDirection.RIGHT} toggle={row.getIsExpanded()} type={SvgType.VERY_SMALL_ICON} />
          </button>
        )}
        {!cell.getValue() && cell.getValue()}
      </>
    </div>
  )
}
