import React, { FC, useState, useEffect } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'

import { SelectOption } from '@components/MultiSelect/MultiSelect'
import getAllFragmentsAndAllTags from '@graphql/microservices/email-event/getAllFragmentsAndAllTags'
import { GetAllFragmentsAndAllTagsQuery, GetAllFragmentsAndAllTagsQueryVariables } from '@graphql/types/microservice/email-event-types'
import { useAccountSettings } from '@utils/account/account.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'

import LinkPerformance from './LinkPerformance'
import { LinkPerformanceLinkLocationState } from './utils/LinkPerformance.utils'
export interface LinkPerformanceMatchParams {
  id: string
  emailName: string
}

export type LinkPerformanceRouteProps = {
  location: { state?: LinkPerformanceLinkLocationState }
}

export type LinkPerformanceContainerProps = RouteComponentProps<LinkPerformanceMatchParams> & LinkPerformanceRouteProps

const LinkPerformanceContainer: FC<LinkPerformanceContainerProps> = ({ match, location }: LinkPerformanceContainerProps) => {
  const history = useHistory()
  const { accountId } = useAccountSettings()
  //TODO: get email name from message id with api
  const { id: messageId, emailName } = match.params

  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.EMAIL_EVENT })

  const [currentTab, setCurrentTab] = useState(location?.state?.tab || 'fragments')

  const [allFragments, setAllFragments] = useState<SelectOption[]>([])
  const [allTags, setAllTags] = useState<SelectOption[]>([])

  const onTabChange = (newTab: string) => {
    const state = { ...(history.location.state as Object), tab: newTab }
    history.replace(location.pathname, state)
    setCurrentTab(newTab)
  }

  const {
    loading,
    error: pageError,
    data,
  } = useQueryOnMount<GetAllFragmentsAndAllTagsQuery, GetAllFragmentsAndAllTagsQueryVariables>(getAllFragmentsAndAllTags, {
    client,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      accountId,
      messageId,
    },
  })

  const updateData = (data: GetAllFragmentsAndAllTagsQuery) => {
    const { getAllFragmentsAndAllTags } = data

    if (getAllFragmentsAndAllTags) {
      const { fragmentNames, tagNames } = getAllFragmentsAndAllTags ?? { fragmentNames: [], tagNames: [] }
      const tagNamesOptions = tagNames
        ? tagNames.map((tag) => {
            return {
              label: tag || '',
              value: tag || '',
            }
          })
        : []

      const fragmentNamesOptions = fragmentNames
        ? fragmentNames.map((fragment) => {
            return {
              label: fragment || '',
              value: fragment || '',
            }
          })
        : []

      setAllTags(tagNamesOptions)
      setAllFragments(fragmentNamesOptions)
    }
  }

  useEffect(() => {
    if (!loading && !pageError && data) {
      updateData(data)
    }
  }, [loading, pageError])

  return (
    <LinkPerformance
      emailName={emailName || ''}
      messageId={messageId}
      currentTab={currentTab}
      onTabChange={onTabChange}
      loading={loading}
      pageError={pageError}
      fragOptions={allFragments}
      tagOptions={allTags}
    />
  )
}

export default LinkPerformanceContainer
