import { gql } from '@apollo/client'

export default gql`
  query getAllAccountProvisioningData($amount: Int, $groupBy: GroupBy) {
    getAllAccountProvisioningData(amount: $amount, groupBy: $groupBy) {
      available
      date
      isCPMAccount
      used
      values {
        available
        date
        isCPMAccount
        used
        values {
          available
          date
          isCPMAccount
          used
        }
      }
    }
  }
`
