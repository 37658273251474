import { useApolloClient } from '@apollo/client'
import auditList from '@graphql/microservices/categorization/auditList'
import unifiedListFieldMappings from '@graphql/microservices/list/getUnifiedListFieldMappings'
import scoreSheets from '@graphql/microservices/list/scoreSheets'
import validateDataTypes from '@graphql/microservices/list/validateDataTypes'
import saveListMaintenanceProgram from '@graphql/mutations/saveListMaintenanceProgram'
import Campaigns from '@graphql/queries/campaigns'
import checkListMaintenanceProgramErrorsBeforeSave from '@graphql/queries/checkListMaintenanceProgramErrorsBeforeSave'
import getListMaintenanceProgram from '@graphql/queries/getListMaintenanceProgram'
import getListMaintenanceProgramErrors from '@graphql/queries/getListMaintenanceProgramErrors'
import listSchemaQuery from '@graphql/queries/listSchema'
import { AuditListQuery, AuditListQueryVariables } from '@graphql/types/microservice/categorization-types'
import {
  DataType,
  ScoreSheetsQuery,
  ScoreSheetsQueryVariables,
  GetUnifiedListFieldMappingsQuery,
  GetUnifiedListFieldMappingsQueryVariables,
  ValidateDataTypesQuery,
  ValidateDataTypesQueryVariables,
} from '@graphql/types/microservice/list-types'
import {
  ListMaintenanceProgramInput,
  SaveListMaintenanceProgramMutation,
  SaveListMaintenanceProgramMutationVariables,
} from '@graphql/types/mutation-types'
import {
  Campaign,
  CampaignsQuery,
  CampaignsQueryVariables,
  CheckListMaintenanceProgramErrorsBeforeSaveQuery,
  CheckListMaintenanceProgramErrorsBeforeSaveQueryVariables,
  GetListMaintenanceProgramErrorsQuery,
  GetListMaintenanceProgramErrorsQueryVariables,
  GetListMaintenanceProgramQuery,
  GetListMaintenanceProgramQueryVariables,
  ListSchemaQuery,
  ListSchemaQueryVariables,
  Program,
  ProgramStatus,
} from '@graphql/types/query-types'
import { ItemType } from '@utils/categorization'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

interface ProgramStepsAndDetailsQueriesResponse {
  getActivities: (programExternalId: string, page?: number, pageSize?: number) => FetchPromise<AuditListQuery>
  getCampaigns: () => Promise<Campaign[]>
  getListMaintenanceProgramRequest: (programId: string, useCache?: boolean) => Promise<Program>
  getListSchemaRequest: (listId: string) => FetchPromise<ListSchemaQuery>
  getProgramErrorsRequest: (program: string | ListMaintenanceProgramInput) => Promise<ProgramStatus | undefined>
  getScoreSheetsRequest: () => FetchPromise<ScoreSheetsQuery>
  getUnifiedFieldsRequest: () => FetchPromise<GetUnifiedListFieldMappingsQuery>
  saveProgramRequest: (program: ListMaintenanceProgramInput) => Promise<string>
  validateFieldsTypesRequest: (value: string, type: string, dateFormat?: string) => FetchPromise<ValidateDataTypesQuery>
}

const LIST_MAINTENANCE_PROGRAMS_TYPE = 'LIST_MAINT'

export const useProgramStepsAndDetailsQueries = (): ProgramStepsAndDetailsQueriesResponse => {
  const client = useApolloClient()

  const { client: listService } = useMicroserviceClient({ serviceName: MicroserviceClients.LIST })
  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })

  const getActivities = (externalId: string, page = 0, pageSize = 50) => {
    return categorizationClient.query<AuditListQuery, AuditListQueryVariables>({
      query: auditList,
      variables: {
        externalId,
        pageSize,
        pageNumber: page,
        type: ItemType.LIST_MAINTENANCE_PROGRAM,
      },
    })
  }

  const getListMaintenanceProgramRequest = (programId: string, useCache = true) => {
    return client
      .query<GetListMaintenanceProgramQuery, GetListMaintenanceProgramQueryVariables>({
        fetchPolicy: useCache ? 'cache-first' : 'network-only',
        query: getListMaintenanceProgram,
        variables: {
          programId,
        },
      })
      .then(({ data }) => {
        const {
          lastRun,
          runStatus: { isRunning },
        } = data.getListMaintenanceProgram
        return { ...data.getListMaintenanceProgram, lastRun: isRunning && lastRun === 0 ? Date.now() : lastRun } as Program
      })
  }

  const saveProgramRequest = (program: ListMaintenanceProgramInput) => {
    return client
      .mutate<SaveListMaintenanceProgramMutation, SaveListMaintenanceProgramMutationVariables>({
        mutation: saveListMaintenanceProgram,
        variables: {
          program: { ...program, type: LIST_MAINTENANCE_PROGRAMS_TYPE },
        },
      })
      .then(({ data }) => {
        const { status = '', message = '' } = { ...data?.saveListMaintenanceProgram }
        return status === 'success' && message.includes(':') ? message.split(':')[1].trim() : ''
      })
  }

  const getListSchemaRequest = (listId: string) => {
    return client.query<ListSchemaQuery, ListSchemaQueryVariables>({
      query: listSchemaQuery,
      variables: {
        listIds: [listId],
      },
    })
  }

  const getUnifiedFieldsRequest = () => {
    return listService.query<GetUnifiedListFieldMappingsQuery, GetUnifiedListFieldMappingsQueryVariables>({
      fetchPolicy: 'network-only',
      query: unifiedListFieldMappings,
    })
  }

  const getScoreSheetsRequest = () => {
    return listService.query<ScoreSheetsQuery, ScoreSheetsQueryVariables>({
      fetchPolicy: 'network-only',
      query: scoreSheets,
    })
  }

  const getCampaigns = () => {
    return client
      .query<CampaignsQuery, CampaignsQueryVariables>({
        fetchPolicy: 'network-only',
        query: Campaigns,
      })
      .then(({ data }) => data.campaigns.filter(({ id }) => id))
  }

  const getProgramErrorsRequest = (program: string | ListMaintenanceProgramInput) => {
    if (typeof program === 'string') {
      return client
        .query<GetListMaintenanceProgramErrorsQuery, GetListMaintenanceProgramErrorsQueryVariables>({
          fetchPolicy: 'network-only',
          query: getListMaintenanceProgramErrors,
          variables: {
            programId: program,
          },
        })
        .then(({ data }) => data.getListMaintenanceProgramErrors)
    }
    return client
      .query<CheckListMaintenanceProgramErrorsBeforeSaveQuery, CheckListMaintenanceProgramErrorsBeforeSaveQueryVariables>({
        fetchPolicy: 'network-only',
        query: checkListMaintenanceProgramErrorsBeforeSave,
        variables: {
          program: { ...program, type: LIST_MAINTENANCE_PROGRAMS_TYPE },
        },
      })
      .then(({ data }) => data.checkListMaintenanceProgramErrorsBeforeSave)
  }

  const validateFieldsTypesRequest = (value: string, type: string, dateFormat?: string) => {
    return listService.query<ValidateDataTypesQuery, ValidateDataTypesQueryVariables>({
      fetchPolicy: 'network-only',
      query: validateDataTypes,
      variables: {
        dataTypeValues: {
          dataType: type as DataType,
          dataValues: [value],
          format: dateFormat,
        },
      },
    })
  }

  return {
    getActivities,
    getCampaigns,
    getListMaintenanceProgramRequest,
    getListSchemaRequest,
    getProgramErrorsRequest,
    getScoreSheetsRequest,
    getUnifiedFieldsRequest,
    saveProgramRequest,
    validateFieldsTypesRequest,
  }
}
