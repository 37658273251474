import unifiedListFieldMappings from '@graphql/microservices/list/getUnifiedListFieldMappings'
import { GetUnifiedListFieldMappingsQuery, GetUnifiedListFieldMappingsQueryVariables } from '@graphql/types/microservice/list-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export type GetUnifiedListFieldMappingsRequestType = () => FetchPromise<GetUnifiedListFieldMappingsQuery>

export interface AddContactsFromCRMListRequests {
  getUnifiedListFieldMappingsRequest: GetUnifiedListFieldMappingsRequestType
}

export const useAddContactsFromCRMListRequests = (): AddContactsFromCRMListRequests => {
  const { client: listClient } = useMicroserviceClient({ serviceName: MicroserviceClients.LIST })

  const getUnifiedListFieldMappingsRequest = async () =>
    await listClient.query<GetUnifiedListFieldMappingsQuery, GetUnifiedListFieldMappingsQueryVariables>({
      query: unifiedListFieldMappings,
      errorPolicy: 'all',
    })

  return {
    getUnifiedListFieldMappingsRequest,
  }
}
