import { gql } from '@apollo/client'

export default gql`
  mutation updateCustomizeManagedAccountsData($data: CustomizeManagedAccountsLogoAttributesInput!) {
    updateCustomizeManagedAccountsData(data: $data) {
      status
      errorMsg
    }
  }
`
