import { gql } from '@apollo/client'

export default gql`
  mutation createEmailContentEditSession($sessionType: EmailContentSessionType, $emailContentId: String, $sessionId: String) {
    createEmailContentEditSession(sessionType: $sessionType, emailContentId: $emailContentId, sessionId: $sessionId) {
      accountId
      createdAt
      emailContentId
      id
      sessionId
      sessionType
      updatedAt
    }
  }
`
