import React, { FC, useEffect, useState } from 'react'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import setAlertSubscribe from '@graphql/mutations/setAlertSubscribe'
import setLaunchNotification from '@graphql/mutations/setLaunchNotification'
import loadProfileNotificationPage from '@graphql/queries/loadProfileNotificationPage'
import {
  SetAlertSubscribeMutation,
  SetAlertSubscribeMutationVariables,
  SetLaunchNotificationMutation,
  SetLaunchNotificationMutationVariables,
} from '@graphql/types/mutation-types'
import { LoadProfileNotificationPageQuery, LoadProfileNotificationPageQueryVariables, NotificationPageResponse } from '@graphql/types/query-types'
import { StatusToastType } from '@interface/StatusToast'
import { useAnalyticsGraphQl } from '@src/pages/Profile/components/Notifications/hooks/useAnalyticsGraphQl'
import Notifications from '@src/pages/Profile/components/Notifications/Notifications'
import { logNewRelicError } from '@utils/new-relic.utils'

interface SecurityContainerProps {
  setToastStatus: (value: StatusToastType) => void
}

const NotificationsContainer: FC<SecurityContainerProps> = (props: SecurityContainerProps) => {
  const { setToastStatus } = props
  const [launchNotificationsData, setNotificationsData] = useState<NotificationPageResponse>()

  const client = useApolloClient()
  const { data, loading, error, refetch } = useQuery<LoadProfileNotificationPageQuery, LoadProfileNotificationPageQueryVariables>(
    loadProfileNotificationPage,
    {
      client,
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    if (!loading && data?.loadProfileNotificationPage) {
      setNotificationsData(data?.loadProfileNotificationPage)
    }
    if (error) {
      logNewRelicError(error)
      setToastStatus({
        showStatus: true,
        statusMessage: 'Something went wrong on our end.',
        successStatus: false,
      })
    }
  }, [data, loading, error])

  const [saveLaunchNotification] = useMutation<SetLaunchNotificationMutation, SetLaunchNotificationMutationVariables>(setLaunchNotification, {
    client,
    fetchPolicy: 'no-cache',
  })

  const [saveAlertsSubscribe] = useMutation<SetAlertSubscribeMutation, SetAlertSubscribeMutationVariables>(setAlertSubscribe, {
    client,
    fetchPolicy: 'no-cache',
    variables: {
      alertsEmail: '',
      dailyLimit: -1,
    },
  })

  const { getUserNotification, saveUserNotification } = useAnalyticsGraphQl()

  return (
    <Notifications
      setToastStatus={setToastStatus}
      saveLaunchNotification={(isLaunchNotificationSet) => saveLaunchNotification({ variables: { isLaunchNotificationSet } })}
      launchNotificationsData={launchNotificationsData}
      saveAlertsSubscribe={saveAlertsSubscribe}
      loading={loading}
      onRefresh={refetch}
      getUserNotification={getUserNotification}
      saveUserNotification={saveUserNotification}
    />
  )
}

export default NotificationsContainer
