import React, { useRef } from 'react'

import classNames from 'classnames'
import { t } from 'i18next'

import { LabelDto } from '@graphql/types/microservice/categorization-types'

import { RenderTags } from './RenderTags/RenderTags'
import { TableV2TagsProps } from '../../tableV2TS/interfaces'

import './TableV2Tags.css'

const rootClass = 'tableV2-tags'

export const TableV2Tags = <T extends {}>({
  cell,
  disabled = false,
  tags,
  tagState,
  selectedTagId,
  readOnlyTags = false,
  tagAction,
  setTagState,
  onCreateTag,
  onApplyAndRemoveTags,
}: TableV2TagsProps<T>) => {
  const tagsRef = useRef<HTMLDivElement>(null)
  const waitingTagRef = useRef<HTMLDivElement>(null)

  return (
    <div
      className={classNames(`${rootClass}__focus`, {
        [`${rootClass}-hover`]: tagState?.hover,
      })}
      data-test={`${rootClass}-tag-test`}
    >
      <RenderTags
        t={t}
        row={cell.row}
        tags={tags ?? []}
        tagsRef={tagsRef}
        disabled={disabled}
        rootClass={rootClass}
        readOnlyTags={readOnlyTags}
        selectedTagId={selectedTagId}
        waitingTagRef={waitingTagRef}
        appliedTags={cell.getValue<LabelDto[]>() || []}
        numberOfTagsDisplayed={tagState?.numberOfTagsDisplayed}
        tagAction={tagAction}
        setTagState={setTagState}
        onCreateTag={onCreateTag ? onCreateTag : () => false}
        onApplyAndRemoveTags={onApplyAndRemoveTags ? onApplyAndRemoveTags : () => false}
      />
    </div>
  )
}
