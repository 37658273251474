import React, { FC } from 'react'

import classNames from 'classnames'

import Svg, { SvgType } from '@components/Svg'
import Typography from '@components/Typography/Typography'

import SvgNames from '../Svg/SvgNames'

import './SpinnerStatus.css'

interface Props {
  text: string
  className?: string
  dataTest?: string
}

const rootClass = 'spinner-status'

const SpinnerStatus: FC<Props> = (props: Props) => {
  const { text, dataTest = rootClass, className = '' } = props

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Svg name={SvgNames.reload} type={SvgType.ICON} className={classNames(`${rootClass}__icon`)} />
      <Typography text={text} inline />
    </div>
  )
}

export default SpinnerStatus
