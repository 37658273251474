import React, { useCallback, useState } from 'react'

import classNames from 'classnames'
import { TFunction } from 'i18next'

import { AssetPickerTableType, DEFAULT_PICKER_COLUMN_SIZES } from '@complex/AssetPickerModal/Components/Table/AssetPickerTable.utils'
import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import Svg, { SvgNames } from '@components/Svg'
import { SvgColor, SvgType } from '@components/Svg/Svg'
import { renderRelativeDate } from '@components/Table/components/tableColumns'
import { RowAction } from '@components/TableV2/tableV2TS/interfaces'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography from '@components/Typography/Typography'
import { BeeTemplate } from '@graphql/types/microservice/email-management-types'
import { getStandardFormattedDate, relativeDate } from '@utils/date'
import { ensureFirstLetterIsCapitalized } from '@utils/strings'

export const TitleWithImage = ({
  title,
  thumbnail_large,
  searchTerm,
}: {
  title?: string
  url?: string
  thumbnail_large?: string
  isDefault?: boolean
  t: TFunction
  searchTerm?: string
}) => {
  const imgClass = 'template-catalog-modal-content__cell-image'
  const [loadError, setLoadError] = useState<boolean>(false)

  const handleLoadError = useCallback(() => setLoadError(true), [])

  const text = title && searchTerm ? renderBoldTextOnMatch(title, searchTerm) : title

  return (
    <div className="flex-align-center">
      <div className={classNames(`${imgClass}-wrapper`, { [`${imgClass}-wrapper-error`]: loadError })}>
        {loadError ? (
          <Svg name={SvgNames.labelIcon} type={SvgType.LARGE_ICON} fill={SvgColor.BACKGROUND_GRAY} />
        ) : (
          <img src={thumbnail_large} alt={title} className={imgClass} onError={handleLoadError} loading="lazy" />
        )}
      </div>
      <TextWithTooltipOnEllip
        typographyProps={{ text, children: text, className: 'push-right-x2' }}
        tooltipProps={{
          children: text,
          childrenProps: { children: text },
        }}
      />
    </div>
  )
}

export const getTemplateCatalogTableColumns = (t: TFunction, searchTerm: string): ColumnDefWithAdditionalProps<BeeTemplate>[] => [
  {
    header: t('Template title'),
    accessorKey: 'title',
    textAlign: 'left',
    disableSorting: true,
    enableCustomCellValue: true,
    enableLongContent: true,
    fieldType: 'string',
    cell: (cell) => <TitleWithImage {...cell.row.original} t={t} searchTerm={searchTerm} />,
  },
  {
    header: t('Related.Search.Terms'),
    accessorKey: 'searchTerms',
    textAlign: 'left',
    disableSorting: true,
    maxSize: 276,
    enableLongContent: true,
    enableCustomCellValue: true,
    fieldType: 'string',
    cell: (cell) => {
      const categoryNames = cell.row.original.categories?.map((category) => `${ensureFirstLetterIsCapitalized(category)}`).join(', ')
      const text = <Typography text={categoryNames} inline />

      return (
        <TextWithTooltipOnEllip
          typographyProps={{ children: text }}
          tooltipProps={{
            children: text,
            childrenProps: { children: categoryNames },
          }}
        />
      )
    },
  },

  {
    header: t('Published'),
    textAlign: 'left',
    accessorKey: 'published',
    fieldType: 'TIMESTAMP',
    ...DEFAULT_PICKER_COLUMN_SIZES[AssetPickerTableType.SEARCH].date,
    minSize: 180,
    maxSize: 180,
    cell: (cell) =>
      renderRelativeDate(
        relativeDate(new Date(cell.row.original.published_at ?? '').getTime(), true, false),
        getStandardFormattedDate(new Date(cell.row.original.published_at ?? '').getTime())
      ),
  },
]

export const getImagePickerRowActions = (t: TFunction, onPreview: (item: BeeTemplate) => void): RowAction<BeeTemplate>[] => [
  {
    label: t('Preview'),
    icon: SvgNames.zoom,
    iconSize: SvgType.ICON,
    tooltipMessage: t('Preview'),
    onClick: (row) => onPreview(row.original),
  },
]
