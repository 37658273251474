import { gql } from '@apollo/client'

export default gql`
  mutation setWebhookTestedStatus($webhookId: String!, $tested: Boolean!) {
    setWebhookTestedStatus(webhookId: $webhookId, tested: $tested) {
      status
      body
    }
  }
`
