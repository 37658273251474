import { gql } from '@apollo/client'

export default gql`
  query getItemsByExternalIds($returnDeletedItems: Boolean, $externalIds: [String], $subTypes: [String], $type: String) {
    getItemsByExternalIds(returnDeletedItems: $returnDeletedItems, externalIds: $externalIds, subTypes: $subTypes, type: $type) {
      accountId
      authorId
      createdTime
      enabled
      externalId
      favoriteUsers
      folderId
      hasToAudit
      id
      isFavorite
      item
      itemLevel
      labels {
        accountId
        color
        createdTime
        enabled
        id
        itemCount
        name
        updatedTime
        views
      }
      parentId
      position
      rootParent
      subTypeDTO {
        count
        itemType
        name
      }
      updatedTime
      username
      views
    }
  }
`
