import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import Popover from '@components/Popover/Popover'
import PopoverCard from '@components/PopoverCard/PopoverCard'
import PopoverTriggerButton from '@components/PopoverTriggerButton/PopoverTriggerButton'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2Option } from '@components/SelectV2/SelectV2.props'
import { SvgNames } from '@components/Svg'
import TextArea from '@components/TextArea/TextArea'
import Typography from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './MultiTextInput.css'

interface MultiTextInputProps {
  className?: string
  dataTest?: string
  selectedOptions: SelectV2Option[]
  onChange: (selectedOptions: SelectV2Option[]) => void
  onCreate: (inputValue: string) => void
}

const rootClass = 'multi-text-input'
const SPLIT_PATTERN = /\r\n|\r|\n/

const MultiTextInput: FC<MultiTextInputProps> = (props: MultiTextInputProps) => {
  const { dataTest = rootClass, className = '', onCreate, selectedOptions, onChange } = props
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [multipleValues, setMultipleValues] = useState<string>('')

  const { t } = useTranslation()

  const onPopoverAction = () => {
    setIsOpen(false)
    const newValues = multipleValues.split(SPLIT_PATTERN).filter((value) => value.trim()) || []
    onChange(
      [...new Set(newValues)].map((value) => ({
        label: value,
        value,
      }))
    )
  }

  const onOpenChange = (open: boolean) => {
    setIsOpen(open)
    if (open) {
      setMultipleValues(selectedOptions.map((option) => option.label).join('\n'))
    }
  }

  const onTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMultipleValues(event.target.value)
  }

  useEffect(() => {
    setMultipleValues(selectedOptions.map((option) => option.label).join('\n'))
  }, [selectedOptions])
  const renderPopoverTrigger = () => <PopoverTriggerButton isOpen={isOpen} icon={SvgNames.clipboardContent} />

  const renderPopoverContent = () => (
    <div className={`${rootClass}__popover-content`}>
      <Typography text={t('SegmentComposer.Build.MultiTextInput.PopoverContent.Text')} />
      <TextArea
        value={multipleValues}
        name={'multiValues'}
        placeholder={t('SegmentComposer.Build.MultiTextInput.PopoverContent.Placeholder')}
        rows={6}
        resize={false}
        onChange={onTextAreaChange}
      />
    </div>
  )

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <SelectV2
        className={`${rootClass}__select`}
        value={selectedOptions}
        onChangeMultiple={onChange}
        onCreate={onCreate}
        placeholder={t('SegmentComposer.Build.MultiTextInput.Select.Placeholder')}
        truncateMultiValues
        minSearchOptions={1}
        hasSearchOnClick
        createOnClickOutside
      />
      <Popover align={'end'} position={'bottom'} trigger={renderPopoverTrigger()} isOpen={isOpen} onOpenChange={onOpenChange} className={rootClass}>
        <PopoverCard
          headerIcon={SvgNames.clipboardContent}
          headerText={t('SegmentComposer.Build.MultiTextInput.PopoverHeader')}
          content={renderPopoverContent()}
          onCancel={() => onOpenChange(false)}
          primaryButton={{ text: t('Apply'), onClick: onPopoverAction }}
        />
      </Popover>
    </div>
  )
}

export default MultiTextInput
