import { Program, WebhookSubscription } from '@graphql/types/query-types'
import { getStepId } from '@src/pages/programs/dashboard/ProgramSteps.utils'
import { ProgramOutgoingWebhookStepExt } from '@utils/program/program.constants'

export const getEndpointsByStep = (step: ProgramOutgoingWebhookStepExt, programId: string, endpoints: WebhookSubscription[]) => {
  return endpoints.filter(({ channels = [] }) => channels.includes(`${programId}_${step.stepId}`)).map(({ endpointId = '' }) => endpointId)
}

export const getStepsIdsByEndpoints = (
  outgoingWebhooksSteps: ProgramOutgoingWebhookStepExt[],
  program: Program,
  programUrlId: string,
  outgoingWebhooksEndpoints: WebhookSubscription[]
) => {
  const updatedOutgoingWebhooksSteps = outgoingWebhooksSteps.map((step) => {
    if (step.endpoints) {
      return step
    } else {
      return {
        ...step,
        endpoints: getEndpointsByStep(step, programUrlId, outgoingWebhooksEndpoints),
      }
    }
  })
  const allWebhookEndpointsMap = new Map<string, string[]>()
  outgoingWebhooksEndpoints.forEach(({ endpointId = '' }) => {
    allWebhookEndpointsMap.set(endpointId, [])
  })
  updatedOutgoingWebhooksSteps.forEach(({ endpoints: stepEndpoints = [], letter = '' }) => {
    stepEndpoints.forEach((endpoint = '') => {
      const stepId = getStepId(program, letter)
      const stepsListByEndpoint = allWebhookEndpointsMap.get(endpoint) || []
      allWebhookEndpointsMap.set(endpoint, [...stepsListByEndpoint, stepId])
    })
  })

  return allWebhookEndpointsMap
}

export const removeProgramWebhooksChannels = (programId: string, endpoints: WebhookSubscription[]): WebhookSubscription[] => {
  return endpoints.map(({ channels = [], ...endpoint }) => ({
    ...endpoint,
    channels: channels.filter((channel = '') => !channel.startsWith(`${programId}_s`) && !channel.startsWith(`${programId}_newstep`)),
  }))
}
