import { FC } from 'react'
import React from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Tooltip from '@components/Tooltip/Tooltip'
import { TextType, LineHeight, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@utils/const/globals'

import './chip.css'

const rootClass = 'chip'

export interface Option {
  id: string
  name: string
}

interface Props {
  id: string
  name: string
  svgName?: SvgNames
  showIcon?: boolean
  simple?: boolean
  fixed?: boolean
  selected?: boolean
  disabled?: boolean
  onRemove: (option: Option, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  className?: string
  dataTest?: string
  tinyText?: boolean
}
const Chip: FC<Props> = (props) => {
  const { id, name, svgName, showIcon, simple, fixed, selected, disabled, onRemove, className, dataTest = rootClass, tinyText } = props

  const { t } = useTranslation()

  const showClose = !fixed && !disabled && !simple

  return (
    <div
      className={classNames(rootClass, className, {
        [`${rootClass}--fixed`]: fixed && !disabled,
        [`${rootClass}--selected`]: selected && !fixed && !disabled,
        [`${rootClass}--disabled`]: disabled,
        [`${rootClass}--simple`]: simple,
      })}
      data-test={dataTest}
      role="presentation"
      onMouseDown={(e) => e.stopPropagation()}
    >
      {svgName && showIcon && <Svg name={svgName} type={SvgType.ICON} fill={fixed ? SvgColor.WHITE : SvgColor.TEXT_GRAY} />}
      <TextWithTooltipOnEllip
        typographyProps={{
          text: name,
          type: tinyText ? TextType.BODY_TEXT_TINY : TextType.BODY_TEXT_SMALL,
          lineHeight: LineHeight.MEDIUM_SMALL,
          weight: fixed ? TextWeight.MEDIUM : TextWeight.REGULAR,
        }}
      />
      {showClose && (
        <Tooltip
          align={'start'}
          alignOffset={-12}
          trigger={
            <Button buttonType={ButtonType.ICON} onClick={(event) => onRemove({ id, name }, event)} className={`${rootClass}__remove-button`}>
              <Svg name={SvgNames.close} />
            </Button>
          }
        >
          {t('Remove')}
        </Tooltip>
      )}
    </div>
  )
}

export default Chip
