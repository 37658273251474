import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import './MoreOptions.css'

interface MoreOptionsProps {
  className?: string
  dataTest?: string
  children: ReactNode
}

const rootClass = 'more-options'

const MoreOptions: FC<MoreOptionsProps> = (props: MoreOptionsProps) => {
  const { dataTest = rootClass, className = '', children } = props
  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={classNames({ [`${className}__content`]: className })}>{children}</div>
    </div>
  )
}

export default MoreOptions
