import React, { useContext } from 'react'
import { ClearIndicatorProps } from 'react-select'

import classNames from 'classnames'

import { SelectV2Context } from '@components/SelectV2/SelectV2.context'
import { selectRootClass } from '@components/SelectV2/SelectV2.utils'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'
import { css } from '@emotion/css'

const CustomClearIndicator = (props: ClearIndicatorProps<any>) => {
  const { getStyles, innerProps } = props
  const {
    values: { hideDropdownIndicator, tabIndex },
  } = useContext(SelectV2Context)
  const { t } = useTranslation()

  return (
    <Tooltip
      align={'start'}
      alignOffset={9}
      triggerClassName={`${selectRootClass}__clear-indicator-tooltip`}
      trigger={
        <div
          tabIndex={tabIndex}
          className={classNames(css(getStyles('clearIndicator', props)), `${selectRootClass}__clear-indicator-container`, {
            [`${selectRootClass}__clear-indicator-with-dropdown-indicator-hidden`]: hideDropdownIndicator,
          })}
          {...innerProps}
        >
          <div className={`${selectRootClass}__clear-indicator`}>
            <Svg name={SvgNames.clearIndicator} type={SvgType.SMALLER_ICON} />
          </div>
        </div>
      }
    >
      {t('Clear selection')}
    </Tooltip>
  )
}

export default CustomClearIndicator
