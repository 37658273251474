import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import Label from '@components/Label/Label'

import './autoResizeTextArea.css'

const rootClass = 'auto-resize-textarea'
export interface TextAreaProps {
  onChangeHandler?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  onBlur?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  hasError: boolean
  children?: ReactNode
  name: string
  role?: string
  label?: string | ReactNode
  maxLength?: number
  rows?: number
  value?: string
  register?: any
  className?: string
  placeholder?: string
  dataTest?: string
  labelDisplay?: string
  setParentContainerHeight?: (parentHeight: number) => void
  required?: boolean
}

/**
 * @deprecated use autoResize prop on TextArea component instead
 */
const AutoResizeTextArea: FC<TextAreaProps> = (props: TextAreaProps) => {
  const {
    name,
    label,
    role,
    onChangeHandler,
    // register,
    value,
    className,
    placeholder,
    dataTest = 'auto-resize',
    labelDisplay,
    onBlur,
    maxLength,
    rows,
    hasError,
    setParentContainerHeight,
    required,
  } = props

  // Credit:https://medium.com/@lucasalgus/creating-a-custom-auto-resize-textarea-component-for-your-react-web-application-6959c0ad68bc
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const [textAreaHeight, setTextAreaHeight] = useState('auto')

  const setHeight = () => {
    if (textAreaRef && textAreaRef.current) {
      const parentHeight = textAreaRef.current.scrollHeight
      const textAreaHeight = `${textAreaRef.current.scrollHeight}px`
      setTextAreaHeight(textAreaHeight)
      if (setParentContainerHeight) {
        setParentContainerHeight(parentHeight ?? 0)
      }
    }
  }

  useEffect(() => {
    setHeight()
  }, [setHeight])

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChangeHandler) {
      onChangeHandler(event)
    }
    setHeight()
  }

  return (
    <>
      {label && (
        <Label htmlFor={name} display={labelDisplay}>
          {label}
        </Label>
      )}
      <textarea
        data-test={`${dataTest}__text-area`}
        style={{
          height: textAreaHeight,
        }}
        className={classNames(rootClass, `${className}__text-area`, { [`${rootClass}__error`]: hasError })}
        ref={textAreaRef}
        required={required}
        rows={rows}
        name={name}
        role={role}
        aria-label={name ?? 'textarea'}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        maxLength={maxLength}
        tabIndex={0}
        placeholder={placeholder}
      >
        {props.children}
      </textarea>
    </>
  )
}

export default AutoResizeTextArea
