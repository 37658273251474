import React, { FC } from 'react'

import { rootContext } from '@utils/const/globals'

interface PollBlockProps {
  image?: string
  showImage?: boolean
  prompt: string
  choices: string[]
  canSelectMultiple?: boolean
  blockId: string
}

const rootClass = 'poll-block'

export const PollBlock: FC<PollBlockProps> = (props) => {
  const { image, showImage, prompt, choices, canSelectMultiple, blockId } = props

  const renderChoice = (name: string, index: number) => {
    return (
      <tr>
        <td style={{ padding: '3px 0' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <tr>
              <td>
                <a
                  href={`${window.location.protocol}//${window.location.host}${rootContext}/pollBlockResponse?accountId={{Env.AccountId}}&messageId={{Env.MsgId}}&blockId=${blockId}&recId={{Env.RecId}}&sourceId={{Env.SrcId}}&selectedChoice=${index}`}
                  target="_blank"
                  style={{
                    textDecoration: 'none',
                    color: '#000000',
                    fontSize: '16px',
                  }}
                  rel="noreferrer"
                >
                  <div style={{ padding: ' 10px 12px', border: '1px solid #dddddd', borderRadius: '4px' }}>
                    <div
                      style={{
                        display: 'inline-block',
                        position: 'relative',
                        top: '2px',
                        width: '16px',
                        height: '16px',
                        marginRight: '6px',
                        border: '1px solid #000000',
                        borderRadius: canSelectMultiple ? '2px' : '50%',
                      }}
                    ></div>
                    <label>{name}</label>
                  </div>
                </a>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    )
  }

  const renderButton = () => (
    <a
      href={`${window.location.protocol}//${window.location.host}${rootContext}/pollBlockResponse?accountId={{Env.AccountId}}&messageId={{Env.MsgId}}&blockId=${blockId}&recId={{Env.RecId}}&sourceId={{Env.SrcId}}`}
      target="_blank"
      style={{
        textDecoration: 'none',
      }}
      rel="noreferrer"
    >
      <div
        style={{
          width: 'max-content',
          margin: '18px 0px 24px 0px',
          padding: '12px 24px 9px',
          borderRadius: '4px',
          background: '#304A71',
          fontWeight: '600',
          color: '#ffffff',
          cursor: 'pointer',
        }}
      >
        Take survey
      </div>
    </a>
  )

  const renderInfoText = () => (
    <p style={{ fontSize: '12px', fontStyle: 'italic' }}>
      To change your response,{' '}
      <a
        href={`${window.location.protocol}//${window.location.host}${rootContext}/pollBlockResponse?accountId={{Env.AccountId}}&messageId={{Env.MsgId}}&blockId=${blockId}&recId={{Env.RecId}}&sourceId={{Env.SrcId}}`}
        target="_blank"
        style={{ fontWeight: '600', textDecoration: 'none', color: '#0068A5' }}
        rel="noreferrer"
      >
        click here.
      </a>
    </p>
  )

  return (
    <div id={rootClass} style={{ padding: '24px', border: '1px solid #dddddd', borderRadius: '10px', textAlign: 'left' }}>
      {showImage && image && (
        <img
          src={image}
          alt="poll"
          style={{ marginBottom: '24px', width: '100%', height: '144px', borderRadius: '10px', objectFit: 'cover', aspectRatio: '3.88 / 1' }}
        />
      )}
      <h1 style={{ marginBottom: '0px 15px 0px 0px', fontSize: '18px', fontWeight: '700' }}>{prompt}</h1>
      <table style={{ width: '100%', padding: '0', border: 'none', borderCollapse: 'collapse' }}>
        {choices.map((choice, index) => renderChoice(choice, index))}
      </table>
      {renderButton()}
      {renderInfoText()}
    </div>
  )
}
