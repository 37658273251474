import { useApolloClient } from '@apollo/client'
import createEmailTemplateFromDraft from '@graphql/mutations/createEmailTemplateFromDraft'
import createLandingPageFromEmailDraft from '@graphql/mutations/createLandingPageFromEmailDraft'
import {
  CreateLandingPageFromEmailDraftMutation,
  CreateEmailTemplateFromDraftMutation,
  CreateEmailTemplateFromDraftMutationVariables,
  CreateLandingPageFromEmailDraftMutationVariables,
} from '@graphql/types/mutation-types'
import { FetchPromise } from '@utils/types'

interface CreateFromDraftRequests {
  createLandingPageFromDraftRequest: (
    variables: CreateLandingPageFromEmailDraftMutationVariables
  ) => FetchPromise<CreateLandingPageFromEmailDraftMutation>
  createEmailTemplateFromDraftRequest: (
    variables: CreateEmailTemplateFromDraftMutationVariables
  ) => FetchPromise<CreateEmailTemplateFromDraftMutation>
}

export const useCreateFromDraftRequests = (): CreateFromDraftRequests => {
  const actonClient = useApolloClient()

  const createLandingPageFromDraftRequest = (variables: CreateLandingPageFromEmailDraftMutationVariables) =>
    actonClient.mutate<CreateLandingPageFromEmailDraftMutation, CreateLandingPageFromEmailDraftMutationVariables>({
      mutation: createLandingPageFromEmailDraft,
      errorPolicy: 'all',
      variables,
    })

  const createEmailTemplateFromDraftRequest = async (variables: CreateEmailTemplateFromDraftMutationVariables) => {
    return await actonClient.mutate<CreateEmailTemplateFromDraftMutation, CreateEmailTemplateFromDraftMutationVariables>({
      mutation: createEmailTemplateFromDraft,
      errorPolicy: 'all',
      variables,
    })
  }
  return { createLandingPageFromDraftRequest, createEmailTemplateFromDraftRequest }
}
