import React, { FC } from 'react'

import classNames from 'classnames'
import equal from 'fast-deep-equal/es6'

import DataCardHeader from '@components/DataCard/components/DataCardHeader/DataCardHeader'
import DataCard from '@components/DataCard/DataCard'
import DividedContentDataCard from '@components/DividedContentDataCard/DividedContentDataCard'
import Loader, { LoaderTypes } from '@components/Loader/Loader'
import PieChart from '@components/PieChart/PieChart'
import SingleLineChart, { SingleLineChartColor } from '@components/SingleLineChart/SingleLineChart'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { AccountDataTotals, defaultAccountData } from '@src/pages/listingPages/AutomatedPrograms/AutomatedProgramsListingPage.constants'
import { formatNumber } from '@utils/numbers'

import './AccountManagementDataCards.css'

interface AccountManagementDataCardsProps {
  showData: boolean
  accountData: AccountDataTotals
  loading: boolean
  className?: string
  dataTest?: string
}

const LEARN_MORE = 'https://connect.act-on.com/hc/en-us/articles/360023758434-Managing-Child-Accounts-in-the-Marketing-Network'

const rootClass = 'account-management-data-cards'

const AccountManagementDataCards: FC<AccountManagementDataCardsProps> = (props: AccountManagementDataCardsProps) => {
  const { showData, accountData, loading, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()

  const { activeContacts, usedContacts, childAccounts } = accountData

  const today = new Date()
  const currentRange = `${today.toLocaleString('default', { month: 'short' })} 1, ${today.getFullYear()} - Now`
  // const activeContactsChildren =
  //   activeContacts.children > 0 && activeContacts.children / activeContacts.total < 0.03
  //     ? Math.ceil(activeContacts.total * 0.03)
  //     : activeContacts.children

  const activeContactsFields = [
    {
      name: currentRange,
      colorByPoint: true,
      data: [
        {
          name: 'Children',
          y: activeContacts.children,
          color: '#00BABE',
        },
        {
          name: 'Parent',
          y: activeContacts.total - activeContacts.children,
          color: '#193661',
        },
      ],
    },
  ]

  return (
    <div
      className={classNames(rootClass, className, {
        [`${rootClass}__manager`]: !showData,
      })}
      data-test={dataTest}
    >
      <Typography
        text={t('AccountManagement.DataCards.SubHeader')}
        type={TextType.BODY_TEXT_LIGHT}
        tagComponents={{
          TextLink: <TextLink link={LEARN_MORE} size={TextLinkSize.LARGE} />,
        }}
        className={`${rootClass}__text`}
        dataTest={`${dataTest}-text`}
      />
      {showData && loading && <Loader loaderType={LoaderTypes.row} className={`${rootClass}__loader`} />}
      {showData && !loading && !equal(accountData, defaultAccountData) && (
        <div className={`${rootClass}__cards`}>
          <DividedContentDataCard
            headerText={t('AccountManagement.DataCards.ActiveContacts.Header', { total: formatNumber(activeContacts.total) })}
            leftContent={
              <Typography
                text={t('AccountManagement.DataCards.ActiveContacts.Left', {
                  children: formatNumber(activeContacts.children),
                  parent: formatNumber(activeContacts.total - activeContacts.children),
                })}
                type={TextType.BODY_TEXT_SMALL_LIGHT}
                tagProps={{
                  teal: { type: TextType.EMPHASIZED_TEXT_TEAL, inline: true },
                  darkBlue: {
                    type: TextType.NORMAL_TEXT_DARKER_BLUE,
                    weight: TextWeight.BOLD,
                    inline: true,
                    className: `${rootClass}__card-child-count`,
                  },
                }}
              />
            }
            rightContent={
              <PieChart
                disableMenu
                disableLegend
                isTinyPieChart
                noContainer
                chartHeight={75}
                chartWidth={75}
                className={`${rootClass}__pie-chart`}
                fields={activeContactsFields}
                showTooltip
              />
            }
            className={`${rootClass}__card`}
            dataTest={`${dataTest}-active-contacts-card`}
          />
          <DataCard className={`${rootClass}__card`} dataTest={`${dataTest}-used-contacts-card`}>
            <DataCardHeader headerText={'Used Contacts'} />
            <SingleLineChart actualValue={usedContacts.current} total={usedContacts.total} actualColor={SingleLineChartColor.ORANGE} />
          </DataCard>
          <DataCard className={`${rootClass}__card`} dataTest={`${dataTest}-child-accounts-card`}>
            <DataCardHeader headerText={'Used Child Accounts'} />
            <SingleLineChart actualValue={childAccounts.current} total={childAccounts.total} actualColor={SingleLineChartColor.PURPLE} />
          </DataCard>
        </div>
      )}
    </div>
  )
}

export default AccountManagementDataCards
