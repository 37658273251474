import { useCallback, useEffect, useState, useRef } from 'react'

import { DocumentNode } from 'graphql'
import _ from 'lodash'

import { useQuery, OperationVariables, ApolloQueryResult } from '@apollo/client'
import { QueryHookOptions, QueryResult } from '@apollo/client/react/types/types'
import { TypedDocumentNode } from '@graphql-typed-document-node/core'

// This custom useQueryOnMount hook will execute only once when the component is mounted,
// and will not be triggered again on any token or client updates

// useQueryOnMount has same type as useQuery
function useQueryOnMount<TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
  const [skip, setSkip] = useState(false)
  const prevOptions = useRef(options)
  const skipRef = useRef(false)

  const mountedQuery = useQuery<TData, TVariables>(query, {
    ...options,
    skip, // Skip query execution
  })
  const [queryResult, setQueryResult] = useState<QueryResult<TData, TVariables>>(mountedQuery)

  const changeSkip = useCallback((skip: boolean) => {
    skipRef.current = skip
    setSkip(skip)
  }, [])

  useEffect(() => {
    if (_.isEqual(options, prevOptions.current)) {
      return
    }
    const { client: prevClient, ...prevRest } = prevOptions.current ?? {}
    const { client, ...rest } = options ?? {}
    const onlyClientChanged = !_.isEqual(prevClient, client) && _.isEqual(rest, prevRest)
    prevOptions.current = options
    changeSkip(onlyClientChanged)
  }, [options, changeSkip])

  // Set the skip flag to true after the query is executed successfully once
  useEffect(() => {
    if (!skipRef.current) {
      setQueryResult(mountedQuery)
      const { loading, data, error } = mountedQuery
      if (!loading && (data || error)) {
        changeSkip(true)
      }
    }
  }, [mountedQuery, changeSkip])

  const refetch = useCallback<(variables?: Partial<TVariables> | undefined) => Promise<ApolloQueryResult<TData>>>(
    (variables) => {
      // override refetch to reset skip
      changeSkip(false)
      return queryResult.refetch(variables)
    },
    [changeSkip]
  )

  return { ...queryResult, refetch }
}

export default useQueryOnMount
