import React, { FC } from 'react'

import classNames from 'classnames'
import { AlignValue, LegendOptions, OptionsLayoutValue, TooltipOptions, VerticalAlignValue } from 'highcharts'

import Chart from '@components/Chart/Chart'
import { ChartFieldData, ChartProps, LegendLocation } from '@const/Chart.constants'
import { formatNumber } from '@utils/numbers'
import { wordwrap } from '@utils/strings'

import './PieChart.css'

export interface PieChartProps extends ChartProps {
  isDonut?: boolean
  chartHeight?: number
  isTinyPieChart?: boolean
  showTooltip?: boolean
  showValuePercentage?: boolean
  disableLegend?: boolean
  wideLegend?: boolean
}

const rootClass = 'pie-chart'

const PieChart: FC<PieChartProps> = (props: PieChartProps) => {
  const {
    fields,
    title = '',
    subtitle = '',
    isDonut = false,
    loading,
    error,
    ExtraElement,
    chartHeight = 500,
    chartWidth,
    legendLocation,
    disablePrint,
    disableMenu,
    disableLegend,
    wideLegend,
    dataTest = rootClass,
    className = '',
    isTinyPieChart,
    showTooltip,
    showValuePercentage,
    noContainer,
    tooltip,
    plotOptions,
    dataLabels = {},
    events = {},
  } = props
  const isLegendRight = legendLocation === LegendLocation.RIGHT

  const fieldName = (fields[0].name ?? 'Values').toUpperCase()
  const total = (fields[0].data as ChartFieldData[]).reduce((acc, curr) => acc + (curr.y ?? 0), 0)

  const getCombinedTooltip = (label: string, boldText: string, action?: string, actionList?: string) => {
    const parts = [label, boldText, action, actionList].filter((val) => !!val)
    const actionText = action ? ` ${action} <strong>${actionList}</strong>` : ''
    return parts.length === 1 ? parts[0] : `${label} <strong>${boldText}</strong>${actionText}`
  }

  const renderTooltipElement = (rowClass: string, tip: string) => `
      <div class="${rowClass}-label__tooltip tooltip tooltip__minimal-padding">
        <div class="typography typography__type-body-text-white">
          <div class="${rowClass}-label__tooltip__text">${tip}</div>
        </div>
      </div>`

  const renderValueWithTooltip = (rowClass: string, value: number) => {
    const tipValue = ((value / total) * 100).toFixed(0)
    return `<div class="${rowClass}-value__tooltip tooltip tooltip__minimal-padding">
        <div class="typography typography__type-body-text-white">
          <div class="${rowClass}-value__tooltip__text">${tipValue}%</div>
        </div>
      </div>`
  }

  const formatLegendLabel = (field: ChartFieldData) => {
    const isTitleRow = field.name === fieldName
    const rowClass = isTitleRow ? `${rootClass}__legend-title-row` : `${rootClass}__legend-row`
    const label = isTitleRow ? field.name : field.custom ? field.custom.initialText : field.name
    const tooltipText = field.custom?.boldText
      ? getCombinedTooltip(label, field.custom.boldText, field.custom?.secondText, field.custom?.secondBoldText)
      : label
    const tooltip = wordwrap(tooltipText ?? '', 45)

    const tooltipElement = isTitleRow ? '' : renderTooltipElement(rowClass, tooltip)

    const boldTextElement = !isTitleRow && field.custom?.boldText ? `<span class="${rowClass}-value">${field.custom.boldText}</span>` : ''
    const valueTooltip = isTitleRow || !field.y || !showValuePercentage ? '' : renderValueWithTooltip(rowClass, field.y)
    const valueElement = formatNumber(isTitleRow ? total : field.y ?? 0)

    const secondAction = !isTitleRow && field.custom?.secondText ? field.custom.secondText : ''
    const secondBoldTextElement =
      !isTitleRow && field.custom?.secondBoldText ? `<span class="${rowClass}-value">${field.custom.secondBoldText}</span>` : ''

    return `
    <div class="${rowClass} ${wideLegend ? `${rootClass}__legend-row-wide` : ''}">
      <div class="${rowClass}-label">
        <span>${label} </span>
        ${boldTextElement}
        ${tooltipElement}
        ${secondAction}
        ${secondBoldTextElement}
      </div>
      <div class="${rowClass}-value">
        ${valueElement}
        ${valueTooltip}
      </div>
    </div>`
  }
  const tooltipTinyPieChart: TooltipOptions = {
    backgroundColor: 'rgba(247, 247, 247, 0.85)',
    borderRadius: 12,
    borderWidth: 1.2,
    enabled: true,
    headerFormat:
      '<b> <tspan style="color:{point.color}; font-size: larger">&#x25cf;</tspan> </b> <b style="font-size:small; font-weight:bold; line-height:1.2"> {point.key}</b> <br>',
    hideDelay: 0.1,
    outside: true,
    padding: 6,
    pointFormatter: function (this): string {
      return `<b> ${(this.percentage ?? 0).toFixed(2)}% (${formatNumber(this.y ?? 0)} contacts)</b><br> ${this.series.name}`
    },
    snap: 10,
    style: {
      fontSize: '12',
      fontWeight: '400',
      height: 56,
      whiteSpace: 'pre',
      width: 150,
    },
  }

  const align: AlignValue = isLegendRight ? 'right' : 'center'
  const y = isLegendRight ? 30 : 230
  const size = isLegendRight ? '220px' : '136px'
  const center: [string | number | null, string | number | null] = isLegendRight ? ['12%', 98] : ['50%', 50]
  const legend: LegendOptions = {
    symbolRadius: 2,
    layout: 'vertical' as OptionsLayoutValue,
    verticalAlign: 'top' as VerticalAlignValue,
    align,
    floating: true,
    y,
    itemMarginTop: 0,
    itemMarginBottom: 0,
    navigation: {
      enabled: false,
    },
    useHTML: true,
    padding: 10,
    itemStyle: {
      color: 'var(--text-gray)',
      lineHeight: '36px',
    },
    labelFormatter: function (): string {
      return formatLegendLabel(this as ChartFieldData)
    },
  }

  if (isDonut) {
    fields[0].innerSize = '85%'
  }

  const labelSeries = {
    type: 'pie',
    data: [{ name: fieldName, y: null }],
  }

  const series = [labelSeries, ...fields.map((field) => ({ ...field, type: 'pie', animation: false }))]

  const updatedPlotOptions = {
    pie: {
      dataLabels: {
        enabled: false,
        ...dataLabels,
      },
      cursor: 'pointer',
      showInLegend: true,
      size,
      center,
    },
    ...plotOptions,
  }

  const centerTinyPieChart: [string | number | null, string | number | null] = ['50%', '50%']
  const plotOptionsTinyPieChart = {
    pie: {
      borderWidth: 1.2,
      center: centerTinyPieChart,
      dataLabels: {
        enabled: false,
      },
      cursor: 'pointer',
      minSize: 55,
      showInLegend: true,
      tooltip: {
        distance: 0,
      },
      size: 55,
    },
  }

  return (
    <Chart
      disableMenu={disableMenu}
      noContainer={noContainer}
      series={series}
      chartType="pie"
      chartHeight={chartHeight}
      chartWidth={chartWidth}
      title={title}
      subtitle={subtitle}
      legend={disableLegend ? { enabled: false } : legend}
      loading={loading}
      error={error}
      ExtraElement={ExtraElement}
      events={events}
      plotOptions={isTinyPieChart ? plotOptionsTinyPieChart : updatedPlotOptions}
      tooltip={showTooltip ? tooltip ?? (isTinyPieChart ? tooltipTinyPieChart : undefined) : { enabled: false }}
      disablePrint={disablePrint}
      dataTest={dataTest}
      className={classNames(rootClass, className, {
        [`${rootClass}__legend-right`]: isLegendRight,
      })}
    />
  )
}

export default PieChart
