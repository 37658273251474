import React, { FC, ReactNode, useState } from 'react'

import classNames from 'classnames'

import * as PopoverPrimitive from '@radix-ui/react-popover'
import { Side } from '@radix-ui/react-popper'

import './Popover.css'

interface PopoverProps {
  trigger: ReactNode
  children: ReactNode
  isOpen?: boolean
  onOpenChange?: (isOpen: boolean) => void
  align?: 'center' | 'end' | 'start'
  position?: Side
  sideOffset?: number
  className?: string
  dataTest?: string
  isHover?: boolean
}

const rootClass = 'popover'

const Popover: FC<PopoverProps> = (props: PopoverProps) => {
  const { className = '', children, trigger, align, position = 'top', sideOffset = 6, isOpen = false, onOpenChange, isHover } = props
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(isOpen)
  const [timeOutId, setTimeOutId] = useState<NodeJS.Timeout>()

  return (
    <div className={classNames(rootClass, className)} data-test={rootClass}>
      <PopoverPrimitive.Root
        onOpenChange={(open) => (onOpenChange ? onOpenChange(open) : setIsPopoverOpen(open))}
        open={onOpenChange ? isOpen : isPopoverOpen}
      >
        <PopoverPrimitive.Trigger
          className={`${rootClass}__trigger`}
          data-test={`${rootClass}-trigger`}
          onPointerEnter={() => isHover && setIsPopoverOpen(true)}
          onMouseLeave={() => {
            if (isHover) {
              setTimeOutId(
                setTimeout(() => {
                  setIsPopoverOpen(false)
                }, 250)
              )
            }
          }}
        >
          {trigger}
        </PopoverPrimitive.Trigger>
        <PopoverPrimitive.Content
          style={{ zIndex: 99999 }}
          align={align}
          side={position}
          sideOffset={sideOffset}
          onEscapeKeyDown={() => setIsPopoverOpen(false)}
          onMouseEnter={() => (timeOutId ? clearTimeout(timeOutId) : undefined)}
          onPointerLeave={() => isHover && setIsPopoverOpen(false)}
        >
          {children}
        </PopoverPrimitive.Content>
      </PopoverPrimitive.Root>
    </div>
  )
}

export default Popover
