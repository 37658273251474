import { Update } from '@src/pages/ContactSegments/components/AddContactsFromCRM/AddContactsFromCRMContext'
import { IsAValidSegmentNameRequestType } from '@src/pages/ContactSegments/components/AddContactsFromCRM/GraphQL/AddContactsFromCRMRequests.classic.graphQL'
import { logNewRelicError } from '@utils/new-relic.utils'

interface IsAValidSegmentNameUtilsParams {
  isAValidSegmentNameRequest: IsAValidSegmentNameRequestType
  segmentName: string
  update: Update
}

export const isAValidSegmentNameUtils = async ({ isAValidSegmentNameRequest, segmentName, update }: IsAValidSegmentNameUtilsParams) => {
  try {
    const { data } = await isAValidSegmentNameRequest({ segmentName: segmentName.trim() })
    update({ validSegmentName: data?.isAValidSegmentName ?? true })
    return data?.isAValidSegmentName ?? true
  } catch (error) {
    logNewRelicError(error)
    update({ validSegmentName: true })
    return true
  }
}
