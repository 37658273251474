import { useCallback, useRef, useState } from 'react'

export const useTimeout = (delay: number) => {
  const timeout = useRef<NodeJS.Timeout | null>(null)

  const isRunningRef = useRef(false)
  const [isRunning, setRunningState] = useState(false)
  const setRunning = (value: boolean) => {
    isRunningRef.current = value
    setRunningState(value)
  }

  const set = useCallback(
    (callback: Function) => {
      if (!isRunningRef.current) {
        setRunning(true)
        timeout.current = setTimeout(() => {
          setRunning(false)
          typeof callback === 'function' && callback()
        }, delay)
      }
    },
    [delay]
  )

  const clear = useCallback(() => {
    if (timeout.current && isRunningRef.current) {
      setRunning(false)
      clearTimeout(timeout.current)
    }
  }, [])

  return {
    set,
    clear,
    isRunning,
  }
}
