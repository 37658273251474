import { TFunction } from 'i18next'

export const getSystemOptions = (t: TFunction) => [
  {
    label: t('Record create date'),
    value: 'recordCreateDate',
  },
  {
    label: t('Record last updated date'),
    value: 'recordLastUpdatedDate',
  },
]
