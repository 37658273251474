import React, { FC, Key, useContext, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import CheckboxCard from '@components/CheckboxCard/CheckboxCard'
import DropDown from '@components/DropDown'
import { DropDownType } from '@components/DropDown/DropDown'
import Svg, { SvgNames } from '@components/Svg'
import { useTranslation } from '@const/globals'
import { opportunitiesEntities } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.constants'
import { EditCRMStepV2Context } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.context'
import {
  getEntityTitle,
  getIconByEntity,
} from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.utils'
import useCRM from '@utils/hooks/useCRM'
import { ProgramCreateInCRMStepExt } from '@utils/program/program.constants'

import './CRMEntitySelector.css'

interface CRMEntitySelectorProps {
  className?: string
  dataTest?: string
}

const rootClass = 'crm-entity-selector'

const CRMEntitySelector: FC<CRMEntitySelectorProps> = (props: CRMEntitySelectorProps) => {
  const { dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const { connectorType } = useCRM()
  const {
    update,
    values: { entities, steps },
  } = useContext(EditCRMStepV2Context)
  const [createEntityDropdownOpen, setCreateEntityDropdownOpen] = useState<boolean>(false)

  const { control, watch } = useFormContext<ProgramCreateInCRMStepExt>()
  const { append } = useFieldArray<ProgramCreateInCRMStepExt>({ control, name: 'additionalEntities' })
  const additionalEntities = watch('additionalEntities')
  const pushType = watch('pushType')
  const pushNewRecords = watch('pushNewRecords')

  const onEntitySelected = (key: Key, value: string) => {
    const matchingKeys = opportunitiesEntities.includes(value)
      ? entities.reduce<{ key: string; value: string }[]>((keys, { value: entityValue, key: entityKey }) => {
          if (entityKey && entityValue && opportunitiesEntities.includes(entityValue)) {
            keys.push({ key: entityKey, value: entityValue })
          }
          return keys
        }, [])
      : [{ key, value }]

    const allMatchingAreSelected = matchingKeys.every(({ value }) => additionalEntities?.some(({ entityType }) => entityType === value))

    if (allMatchingAreSelected) {
      update({
        stepToRemove: {
          title: key.toString(),
          key: value,
          open: false,
          completed: false,
        },
      })
    } else {
      const newSteps = matchingKeys.reduce<typeof steps>((keys, { key, value: entityValue }) => {
        if (!steps.some((step) => step.key === entityValue)) {
          keys.push({
            title: key as string,
            key: entityValue,
            open: false,
            completed: false,
            newStep: true,
          })
        }
        return keys
      }, [])

      update({ steps: [...steps, ...newSteps] })
      append(newSteps.map(({ key }) => ({ entityType: key as string, fields: [] })))
    }

    setCreateEntityDropdownOpen(false)
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <DropDown
        dataTest={`${dataTest}-dropdown`}
        isOpen={createEntityDropdownOpen}
        toggleOpen={setCreateEntityDropdownOpen}
        type={DropDownType.STYLED}
        trigger={
          <Button
            className={classNames({ [`${rootClass}__open`]: createEntityDropdownOpen })}
            buttonType={ButtonType.OUTLINE}
            dataTest={`${rootClass}-trigger`}
          >
            <Svg name={SvgNames.plus} />
            {t('Add an entity')}
          </Button>
        }
      >
        <div className={`${rootClass}__dropdown`}>
          {entities.map(({ key = '', value = '' }) => (
            <CheckboxCard
              disabled={pushNewRecords && pushType === 'Lead' && opportunitiesEntities.includes(value)}
              key={key}
              title={getEntityTitle(key)}
              iconLeft={getIconByEntity(value, connectorType) ?? SvgNames.crmCloudLineNoFill}
              inDropdown
              isSelected={steps.some((step) => step.key === value)}
              onSelect={() => onEntitySelected(key, value)}
              tooltip={t('EditCrmStepV2.EntitySelector.Tooltip')}
            />
          ))}
        </div>
      </DropDown>
    </div>
  )
}

export default CRMEntitySelector
