import React, { ComponentType, FC, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, useHistory } from 'react-router-dom'

import { compose } from 'redux'

import { ApolloQueryResult, useApolloClient } from '@apollo/client'
import { DATA_FETCHING_ERROR, FAILED_TO_START_ERROR, isProd, PROGRAM_NOT_FOUND_ERROR, rootContext } from '@const/globals'
import { AutomatedProgramCountsResponse, Program, ProgramQuery, ProgramStatus } from '@graphql/types/query-types'
import ProgramOutgoingWebhooks from '@src/pages/programs/edit/components/ProgramOutgoingWebhooks/ProgramOutgoingWebhooks'
import { DEFAULT_POPUP_STATE, ProgramManagerContextValues } from '@src/pages/programs/manager/context/ProgramManagerContextValues'
import { useProgramManagerCategorizationServiceRequests } from '@src/pages/programs/manager/graphQL/ProgramManager.categorization.graphQL'
import { getProgramManagerQueries } from '@src/pages/programs/manager/graphQL/ProgramManager.graphQL'
import ProgramManager from '@src/pages/programs/manager/ProgramManager'
import {
  MOVE_NEXT_RETRIES,
  PROGRAM_LIST_URL,
  ProgramManagerRouteProps,
  ProgramManagerTabs,
} from '@src/pages/programs/manager/ProgramManager.constants'
import { getContactsWaitingInStep, processProgram } from '@src/pages/programs/manager/ProgramManager.helpers'
import { ProgramFromItemDto } from '@src/pages/programs/UpgradePrograms/UpgradePrograms'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import navigationActions from '@utils/navigation/state/actions'
import { logNewRelicError } from '@utils/new-relic.utils'
import mapStateToProps, { EditProgramStateProps } from '@utils/program/mapStateToProps'
import { getCurrentProgramStatus } from '@utils/program/program'
import { ProgramErrors } from '@utils/program/program.constants'
import { MatchParams } from '@utils/types'

import {
  ProgramManagerAPI,
  ProgramManagerContext,
  ProgramManagerErrors,
  ProgramManagerModalsState,
  ProgramManagerPopupsState,
  ProgramManagerValuesAPI,
} from './context/ProgramManager.context'
import { getProgramManagerTabFromLocation, getProgramManagerTabPath } from './ProgramManager.utils'

const baseUrl = `${rootContext}/automation/programs`

const REPORT_TABS = [
  ProgramManagerTabs.PROGRAM_PERFORMANCE,
  ProgramManagerTabs.STEPS,
  ProgramManagerTabs.EMAIL_PERFORMANCE,
  ProgramManagerTabs.SMS_PERFORMANCE,
]

interface OriginalProgramState {
  program?: Program
  programCounts?: AutomatedProgramCountsResponse
  programErrors?: ProgramStatus
}

type ProgramManagerContainerAllProps = RouteComponentProps<MatchParams> & EditProgramStateProps & ProgramManagerRouteProps

const ProgramManagerContainer: FC<ProgramManagerContainerAllProps> = (props: ProgramManagerContainerAllProps) => {
  const { match, location, navigation } = props
  const history = useHistory<{ tab: string; subTab?: string; programToUpgrade?: ProgramFromItemDto }>()
  const { hasPurchasedSMS, userAllowedToCreatePrograms, hasOutgoingWebhookStep } = useAccountSettings()
  const client = useApolloClient()
  const { getItemByIdRequest } = useProgramManagerCategorizationServiceRequests()

  const isNewProgram = match.params.id === 'new' || match.params.id === 'debug'

  const [originalProgram, setOriginalProgram] = useState<OriginalProgramState>()
  const [containerValues, setActualContainerValues] = useState<ProgramManagerValuesAPI>(() => ({
    ...ProgramManagerContextValues.values,
    isNew: isNewProgram,
    isEditing: isNewProgram && userAllowedToCreatePrograms,
    canEdit: userAllowedToCreatePrograms,
    programUrlId: isNewProgram ? '' : match.params.id,
    tab: getProgramManagerTabFromLocation(match.params, location.state, hasPurchasedSMS),
    subTab: match.params.subTab ?? '',
    showUpgrade: window.location.pathname.includes('/upgrade/programs'),
  }))

  const setContainerValues = (newContainerValues: Partial<ProgramManagerValuesAPI>) => {
    setActualContainerValues((containerValues) => ({ ...containerValues, ...newContainerValues }))
  }

  const {
    loadProgramRequest,
    getStatusRequest,
    getCountsRequest,
    saveProgramRequest,
    saveProgramFinalRequest,
    clearProgramHistoryRequest,
    startProgramRequest,
    pauseProgramRequest,
    addOrExitPendingContactsRequest,
    moveNextRequest,
  } = getProgramManagerQueries(client)

  const getProgramCounts = async () => {
    const { data, errors } = await getCountsRequest(containerValues.programUrlId)

    if (data) {
      setProgramCounts(data.getAutomatedProgramCounts)
      setOriginalProgram({ ...originalProgram, programCounts: data.getAutomatedProgramCounts })
    }

    if (errors) {
      if (errors[0].extensions?.errorCode === DATA_FETCHING_ERROR) {
        openPopup('loadError')
        setProgramManagerErrors('countsNotFound', true)
      }
      logNewRelicError(errors)
    }
  }

  const getProgramStatus = async () => {
    const { data, errors } = await getStatusRequest(containerValues.programUrlId)

    if (data) {
      setProgramErrors(data.programStatus)
      setOriginalProgram({ ...originalProgram, programErrors: data.programStatus })
    }

    if (errors) {
      openPopup('loadError')
      logNewRelicError(errors)
    }
  }

  const getDebugProgram = (idReplacement: string) => {
    if (match.params.id === 'debug' && !isProd()) {
      const response = JSON.parse(sessionStorage.getItem('ProgramManager:debugProgramJson') ?? '{}') as ApolloQueryResult<ProgramQuery>
      if ('data' in response && 'program' in response.data) {
        response.data.program.id = idReplacement
        response.data.program.users = []
        return response
      }
    }
  }

  const getProgram = async (withLoading = true) => {
    withLoading && setLoading(true)
    const { data, errors } = await loadProgramRequest(containerValues.programUrlId)

    if (data) {
      const hasUnifiedLists = data.program.hasActOnContactList
      const programData = getDebugProgram(data.program.id)?.data.program ?? data.program
      const program = processProgram(programData, hasUnifiedLists)

      if (!isNewProgram) {
        if (REPORT_TABS.indexOf(containerValues.tab as ProgramManagerTabs) > -1) {
          await getProgramCounts()
        }
        await getProgramStatus()
      }
      const isEditing = userAllowedToCreatePrograms && (containerValues.isEditing || !program.runStatus.isRunning)
      setContainerValues({
        program,
        isEditing,
        loading: false,
        programState: getCurrentProgramStatus(program),
      })
      setOriginalProgram({ ...originalProgram, program })
    }

    if (errors) {
      setLoading(false)
      if (errors[0].extensions?.errorCode === PROGRAM_NOT_FOUND_ERROR) {
        setProgramManagerErrors('programNotFound', true)
      } else {
        openPopup('loadError')
      }
      logNewRelicError(errors)
    }
  }

  const loadPreselectedProgramToUpgrade = () => {
    getItemByIdRequest(match.params.id, ItemType.PROGRAM)
      .then(({ data }) => {
        if (data) {
          const programToUpgrade = { ...data.getItem, ...JSON.parse(data.getItem?.item ?? '{}'), id: data.getItem?.id } as ProgramFromItemDto
          history.replace({
            ...history.location,
            state: { ...history.location.state, programToUpgrade },
          })
        }
      })
      .catch((error) => {
        logNewRelicError(error)
      })
  }

  useEffect(() => {
    if (!containerValues.loading && !containerValues.showUpgrade) {
      getProgram()
      if (isNewProgram) {
        setContainerValues({ unsavedChanges: true })
      } else {
        setContainerValues({ isNew: false })
      }
    }
    if (containerValues.showUpgrade && match.params.id) {
      loadPreselectedProgramToUpgrade()
    }
  }, [match.params.id])

  useEffect(() => {
    if (containerValues.program) {
      document.title = `${document.title}::${containerValues.program.name}`
    }
  }, [containerValues.program])

  useEffect(() => {
    if (containerValues.reloadProgramId !== undefined && !containerValues.showUpgrade) {
      if (isNewProgram) {
        history.replace({ pathname: `${rootContext}/automation/programs/${containerValues.reloadProgramId}` })
      } else {
        getProgram()
      }
      setContainerValues({
        reloadProgramId: undefined,
      })
    }
  }, [containerValues.reloadProgramId])

  useEffect(() => {
    if (!isNewProgram && !containerValues.showUpgrade) {
      const tabPath = getProgramManagerTabPath(containerValues.tab, containerValues.subTab, hasPurchasedSMS)
      history.replace(`${baseUrl}/${containerValues.programUrlId}${tabPath}`, {
        ...location.state,
        tab: containerValues.tab,
        subTab: containerValues.subTab,
      })
    }
  }, [containerValues.tab, containerValues.subTab])

  const tabChange = async (tab: string, subtab?: string) => {
    setLoading(true)
    if (REPORT_TABS.indexOf(tab as ProgramManagerTabs) > -1) {
      await getProgramCounts()
    }
    setContainerValues({ tab, subTab: subtab ?? location.state?.subTab, loading: false })
  }

  const subTabChange = (subTab: string) => {
    setContainerValues({ subTab })
  }

  const editProgram = () => {
    if (!containerValues.program?.runStatus.isRunning && userAllowedToCreatePrograms) {
      setContainerValues({ isEditing: !containerValues.isEditing })
    }
  }

  useEffect(() => {
    if (containerValues.navigateFromCancelModal) {
      history.push(PROGRAM_LIST_URL)
    }
  }, [containerValues.navigateFromCancelModal])

  const cancelEdit = () => {
    if (isNewProgram) {
      setContainerValues({ navigateFromCancelModal: true })
    } else {
      const prevProgram = originalProgram?.program ? { program: originalProgram.program } : {}
      const prevProgramCounts = originalProgram?.programCounts ? { programCounts: originalProgram.programCounts } : {}
      const prevProgramErrors = originalProgram?.programErrors ? { programErrors: originalProgram.programErrors } : {}
      setContainerValues({
        ...prevProgram,
        ...prevProgramCounts,
        ...prevProgramErrors,
        unsavedChanges: false,
        programManagerModalsState: { ...containerValues.programManagerModalsState, showCancelEdit: false },
      })
    }
  }

  const setLoading = (loading: boolean) => setContainerValues({ loading })

  const updateProgramAndSetUnsaved = (program: Program, hasAOContactsSource = false, unsavedChanges = true) => {
    setContainerValues({ program, unsavedChanges, hasAOContactsSource })
  }

  const startProgram = async (skipPending: boolean) => {
    setContainerValues({ loading: false, programStarting: true })
    const hasPending = containerValues.programCounts?.pendingSize
    const { data, errors } = await startProgramRequest(skipPending, containerValues.program?.lastRowId ?? 0, containerValues.programUrlId)

    if (data?.startProgram) {
      if (data.startProgram.errorCode === FAILED_TO_START_ERROR) {
        setContainerValues({ errorSetToRunMessage: data.startProgram.message })
        openPopup('errorSetToRun')
      } else {
        if (!skipPending && hasPending) {
          openPopup('addSuccess')
        } else if (skipPending) {
          openPopup('exitSuccess')
        }
        await getProgram()
      }
      setContainerValues({ isEditing: false, unsavedChanges: false })
    }
    setContainerValues({ loading: false, programStarting: false })

    if (errors) {
      openPopup('errorSetToRun')
      logNewRelicError(errors)
    }
  }

  const pauseProgram = async () => {
    setLoading(true)
    const { data, errors } = await pauseProgramRequest(containerValues.programUrlId)

    if (data?.stopProgram) {
      await getProgram()
    }
    if (errors) {
      openPopup('errorSetToPause')
    }

    setLoading(false)
  }

  const refreshProgram = () => (containerValues.tab === ProgramManagerTabs.STEPS ? getProgram(false) : getProgramCounts())

  const clearProgramHistory = async (allowReentrantAddresses: boolean) => {
    const { errors } = await clearProgramHistoryRequest(containerValues.programUrlId, allowReentrantAddresses)

    if (errors) {
      openPopup('clearHistoryFail')
    } else {
      openPopup('clearHistorySuccess')
      getProgram()
    }
  }

  const addContactsToProgram = () => {
    addOrExitPendingContactsRequest(false, containerValues.programUrlId).then((data) => {
      if (data.errors || data.data?.listingRunScheduledDripProgramNow.status === 'error') {
        openPopup('addFail')
      } else {
        openPopup('addSuccess')
        getProgram()
      }
    })
  }

  const exitContactsFromProgram = () => {
    addOrExitPendingContactsRequest(true, containerValues.programUrlId).then((data) => {
      if (data.errors || data.data?.listingRunScheduledDripProgramNow.status === 'error') {
        openPopup('exitFail')
      } else {
        openPopup('exitSuccess')
        getProgram()
        setContainerValues({
          contactSize: containerValues.programCounts?.pendingSize ?? 0,
        })
      }
    })
  }

  const fixErrors = () => {
    setContainerValues({ tab: ProgramManagerTabs.JOURNEY_BUILDER })
  }

  const saveProgram = async () => {
    if (containerValues.program && !containerValues.isSaving) {
      setContainerValues({ isSaving: true })
      // Note: isNew is used to log usage of AJB vs. Legacy AP builder
      const { data, errors } = await saveProgramRequest({ ...containerValues.program, isNew: isNewProgram })
      setContainerValues({ saving: false })

      if (errors) {
        openPopup('saveError')
        logNewRelicError(errors)
      }

      if (data) {
        const { saveProgram: saveProgramResult } = data

        if (saveProgramResult.status === 'saved') {
          if (saveProgramResult.valid) {
            const { data: spfData, errors: spfErrors } = await saveProgramFinalRequest(containerValues.program.id)
            if (spfData) {
              if (spfData.saveProgramFinal && spfData.saveProgramFinal !== containerValues.programUrlId) {
                setContainerValues({
                  reloadProgramId: spfData.saveProgramFinal,
                  programUrlId: spfData.saveProgramFinal,
                  unsavedChanges: false,
                })
              } else {
                setContainerValues({ unsavedChanges: false })
                await getProgram()
                openPopup('saveSuccess')
              }
            }

            if (spfErrors) {
              logNewRelicError(errors)
              openPopup('saveError')
            }
          } else {
            setProgramErrors({
              mainErrors: saveProgramResult.mainErrors,
              exitErrors: saveProgramResult.exitErrors,
              stepErrors: saveProgramResult.stepErrors,
            })
            toggleProgramManagerModalsState('showConfirmSave')
          }
        } else {
          openPopup('saveError')
        }
      }
      setContainerValues({ isSaving: false })
    }
  }

  const saveProgramFinal = async () => {
    if (containerValues.program) {
      setContainerValues({
        ...containerValues,
        saving: true,
        programManagerModalsState: {
          ...containerValues.programManagerModalsState,
          showConfirmSave: false,
        },
      })
      const { data, errors } = await saveProgramFinalRequest(containerValues.program.id)
      setContainerValues({
        ...containerValues,
        saving: false,
        unsavedChanges: false,
        programManagerModalsState: {
          ...containerValues.programManagerModalsState,
          showConfirmSave: false,
        },
      })

      if (errors) {
        logNewRelicError(errors)
        openPopup('saveError')
      }

      if (data?.saveProgramFinal) {
        if (data.saveProgramFinal && data.saveProgramFinal !== containerValues.programUrlId) {
          setContainerValues({ reloadProgramId: data.saveProgramFinal, programUrlId: data.saveProgramFinal, unsavedChanges: false })
        } else {
          await getProgram()
          openPopup('saveSuccess')
        }
      }
    }
  }

  const endLoopAndReload = (interval: any, moveToNextSuccess: boolean) => {
    if (containerValues.program) {
      clearInterval(interval)
      getProgram()
      openPopup(moveToNextSuccess ? 'moveToNextSuccess' : 'moveToNextFail')
    }
  }

  const moveNext = async (stepId: string) => {
    let moveNextRetries = MOVE_NEXT_RETRIES
    const waitingContacts = getContactsWaitingInStep(stepId, containerValues.programCounts.programStepCounts)

    const { data, errors } = await moveNextRequest(stepId, containerValues.programUrlId)

    if (errors || !data?.moveContactsToNextStep) {
      openPopup('moveToNextFail')
      return
    }

    const interval = setInterval(async () => {
      moveNextRetries -= 1
      const { data } = await getCountsRequest(containerValues.programUrlId)
      const updatedWaitingContacts = data ? getContactsWaitingInStep(stepId, data.getAutomatedProgramCounts.programStepCounts) : 0

      if (waitingContacts !== updatedWaitingContacts) {
        endLoopAndReload(interval, true)
      } else if (moveNextRetries === 0) {
        endLoopAndReload(interval, false)
      }
    }, 10 * 1000)
  }

  const setProgramErrors = (programErrors: ProgramErrors) => setContainerValues({ programErrors })

  const setProgramManagerErrors = (error: keyof ProgramManagerErrors, val: boolean) =>
    setContainerValues({ programManagerErrors: { ...containerValues.programManagerErrors, [error]: val } })

  const setProgramCounts = (programCounts: AutomatedProgramCountsResponse) => setContainerValues({ programCounts })

  const onStartProgramModal = async (modal: keyof ProgramManagerModalsState) => {
    await getProgramCounts()

    setContainerValues({
      programManagerModalsState: {
        ...containerValues.programManagerModalsState,
        [modal]: !containerValues.programManagerModalsState[modal],
      },
    })
  }

  const toggleProgramManagerModalsState = async (modal: keyof ProgramManagerModalsState) => {
    if (modal === 'showStartProgram' && !containerValues.programManagerModalsState.showStartProgram) {
      await onStartProgramModal(modal)
    } else {
      setContainerValues({
        programManagerModalsState: {
          ...containerValues.programManagerModalsState,
          [modal]: !containerValues.programManagerModalsState[modal],
        },
      })
    }
  }

  const toggleNavigationDisabled = (disabled: boolean) => setContainerValues({ navigationDisabled: disabled })

  const togglePopup = (fieldToToggle: keyof ProgramManagerPopupsState, val: boolean) => {
    setContainerValues({
      programManagerPopupsState: { ...DEFAULT_POPUP_STATE, [fieldToToggle]: val },
    })
  }

  const closePopup = (fieldToClose: keyof ProgramManagerPopupsState) => {
    setContainerValues({ errorSetToRunMessage: undefined })
    togglePopup(fieldToClose, false)
  }

  const openPopup = (fieldToClose: keyof ProgramManagerPopupsState) => {
    togglePopup(fieldToClose, true)
  }

  const programManagerContextValue: ProgramManagerAPI = {
    values: containerValues,
    updateProgramAndSetUnsaved,
    setLoading,
    tabChange,
    subTabChange,
    editProgram,
    cancelEdit,
    saveProgram,
    saveProgramFinal,
    startProgram,
    pauseProgram,
    clearProgramHistory,
    addContactsToProgram,
    exitContactsFromProgram,
    moveNext,
    setProgramManagerErrors,
    toggleProgramManagerModalsState,
    toggleNavigationDisabled,
    closePopup,
    openPopup,
    fixErrors,
    refreshProgram,
    navigation,
    navigationActions,
    setContainerValues,
  }

  return (
    <ProgramManagerContext.Provider value={programManagerContextValue}>
      {hasOutgoingWebhookStep ? (
        <ProgramOutgoingWebhooks>
          <ProgramManager />
        </ProgramOutgoingWebhooks>
      ) : (
        <ProgramManager />
      )}
    </ProgramManagerContext.Provider>
  )
}

export default compose<ComponentType<ProgramManagerContainerAllProps>>(connect(mapStateToProps))(ProgramManagerContainer)
