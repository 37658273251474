import React, { FC } from 'react'

import classNames from 'classnames'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'

import './ActonContactsFieldIcon.css'

interface ActonContactsFieldIconProps {
  className?: string
  dataTest?: string
  disabled?: boolean
}

const rootClass = 'acton-contacts-field-icon'

const ActonContactsFieldIcon: FC<ActonContactsFieldIconProps> = (props: ActonContactsFieldIconProps) => {
  const { dataTest = rootClass, className = '', disabled } = props

  const { t } = useTranslation()

  return (
    <Tooltip
      triggerClassName={classNames(rootClass, className, { [`${rootClass}__disabled`]: disabled })}
      dataTest={dataTest}
      textBold={t('Learn more about Act-On Contacts fields')}
      trigger={
        <Svg
          dataTest={`${dataTest}-icon`}
          className={`${rootClass}__icon`}
          name={SvgNames.userRoundedNoFill}
          fill={SvgColor.NAVIGATION_BUTTON_HOVER}
          hoverFill={SvgColor.HOVER_INDICATOR_BACKGROUND}
          type={SvgType.LARGE_ICON}
        />
      }
    >
      {t(
        'This is an Act-On Contacts field. All changes made to these contact properties will automatically update across Act-On and sync between other systems.'
      )}
    </Tooltip>
  )
}

export default ActonContactsFieldIcon
