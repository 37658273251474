import { gql } from '@apollo/client'

export default gql`
  query todaySegments($accountId: ID, $segmentStatus: SegmentStatus, $additionalDaysBack: Int) {
    todaySegments(accountId: $accountId, segmentStatus: $segmentStatus, additionalDaysBack: $additionalDaysBack) {
      id
      accountId
      configId
      jobId
      segmentId
      status
      name
      factors
      memberEstimate
      insertTime
      updateTime
      parentSegmentId
      type
      feedback
      selectionReason
    }
  }
`
