import React, { ReactNode, useContext } from 'react'
import { DropdownIndicatorProps } from 'react-select'

import classNames from 'classnames'

import { SelectV2Context } from '@components/SelectV2/SelectV2.context'
import { selectRootClass } from '@components/SelectV2/SelectV2.utils'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { css } from '@emotion/css'

interface CustomDropdownIndicatorProps extends DropdownIndicatorProps<any> {
  renderCustomDropdownIndicator?: ReactNode
}

const CustomDropdownIndicator = (props: CustomDropdownIndicatorProps) => {
  const {
    getStyles,
    innerProps,
    selectProps: { dropdownIndicatorRef },
  } = props
  const {
    values: { hasSearchOnClick, tabIndex, hasSearchOpen },
    update,
  } = useContext(SelectV2Context)

  const onSearchClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    update({ hasSearchOpen: !hasSearchOpen, inputValue: '', inputChanged: false, currentSearchOptions: [] })
  }

  const updatedInnerProps = {
    ...innerProps,
    onClick: hasSearchOnClick ? onSearchClick : innerProps.onClick,
  }

  return (
    <div
      data-test={`select-v2-dropdown-indicator`}
      ref={dropdownIndicatorRef}
      tabIndex={tabIndex}
      className={classNames(css(getStyles('dropdownIndicator', props)), `${selectRootClass}__indicator-container`)}
      {...updatedInnerProps}
    >
      <div className={classNames(`${selectRootClass}__indicator`)}>
        <Svg name={hasSearchOnClick ? SvgNames.search : SvgNames.dropdownIndicator} type={SvgType.ICON} />
      </div>
    </div>
  )
}

export default CustomDropdownIndicator
