import React, { FC } from 'react'

import { ApolloError } from '@apollo/client'
import { renderLoader } from '@components/Loader/Loader'
import PageContainer from '@components/PageContainer/PageContainer'
import PageHeader from '@components/PageHeader'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import StatusToast from '@components/StatusToast/StatusToast'
import { useTranslation } from '@const/globals'
import AllowedSendTime from '@src/pages/sms/settings/components/AllowedSendTime/AllowedSendTime'
import FatigueRuleSetting from '@src/pages/sms/settings/components/FatigueRuleSetting/FatigueRuleSetting'
import InternationalSending from '@src/pages/sms/settings/components/InternationalSending/InternationalSending'

interface SettingsData {
  hasInternationalSending: boolean
  allowOverride: boolean
  startTime: string | Date
  startTimeError: boolean
  endTime: string | Date
  endTimeError: boolean
  fatigueFrequency: number
  fatigueInterval: number
}

interface Props {
  data: SettingsData
  toastMessage?: string
  toastSuccess?: boolean
  loading?: boolean
  error?: ApolloError
  onChangeInternationalSending: (isEnabled: boolean) => void
  onChangeAllowOverride: (isEnabled: boolean) => void
  onChangeStartTime: (startTime: string | Date) => void
  onChangeEndTime: (endTime: string | Date) => void
  clearToast: () => void
  setFrequency: (frequency: number) => void
  setInterval: (interval: number) => void
  className?: string
  dataTest?: string
}

const rootClass = 'settings'

const Settings: FC<Props> = (props: Props) => {
  const {
    data: { hasInternationalSending, allowOverride, startTime, startTimeError, endTime, endTimeError, fatigueInterval, fatigueFrequency },
    toastMessage,
    toastSuccess,
    loading,
    onChangeInternationalSending,
    onChangeAllowOverride,
    onChangeStartTime,
    onChangeEndTime,
    setFrequency,
    setInterval,
    clearToast,
  } = props
  const { t } = useTranslation()

  return (
    <PageContainer>
      {loading && renderLoader('loader--white-background')}
      {toastMessage && <StatusToast isSuccess={toastSuccess ?? false} message={toastMessage} closeStatus={clearToast} />}
      {!loading && (
        <PositionContainer noOverflow>
          <PageHeader primaryText={t('SMS Settings')} leftContent dataTest={rootClass} />
          <InternationalSending hasInternationalSending={hasInternationalSending} onChangeInternationalSending={onChangeInternationalSending} />
          <AllowedSendTime
            onChangeAllowOverride={onChangeAllowOverride}
            allowOverride={allowOverride}
            startTime={startTime}
            startTimeError={startTimeError}
            onChangeStartTime={onChangeStartTime}
            endTime={endTime}
            endTimeError={endTimeError}
            onChangeEndTime={onChangeEndTime}
          />
          <FatigueRuleSetting
            fatigueFrequency={fatigueFrequency}
            fatigueInterval={fatigueInterval}
            setFrequency={setFrequency}
            setInterval={setInterval}
          />
        </PositionContainer>
      )}
    </PageContainer>
  )
}

export default Settings
