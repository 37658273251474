import React, { FC, useCallback, useState } from 'react'

import classNames from 'classnames'

import AddButton from '@components/AddButton/AddButton'
import { useTranslation } from '@const/globals'
import AlertFiltersList from '@src/pages/WebsiteProspectorAlerts/components/AlertFiltersList/AlertFiltersList'
import AddAlertFilterModal from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AddAlertFilterModal/AddAlertFilterModal'
import AddAlertFilterButton from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertFilters/components/AddAlertFilterButton/AddAlertFilterButton'
import { AlertFilter } from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertFilters/utils/AlertFilters.utils'

import './AlertFilters.css'

interface AlertFiltersProps {
  className?: string
  dataTest?: string
}

const rootClass = 'alert-filters'

const AlertFilters: FC<AlertFiltersProps> = (props: AlertFiltersProps) => {
  const { dataTest = rootClass, className = '' } = props

  const [filters, setFilters] = useState<AlertFilter[]>([])
  const [isAddingFilter, setIsAddingFilter] = useState(false)
  const [editingFilter, setEditingFilter] = useState<AlertFilter>()

  const { t } = useTranslation()

  const onCloseAddFilterModal = useCallback(() => {
    setIsAddingFilter(false)
    setEditingFilter(undefined)
  }, [])

  const editFilter = (editedFilter: AlertFilter) => {
    const filterIndex = filters.findIndex((currentFilter) => currentFilter === editingFilter)
    onChangeFilter(filterIndex, editedFilter)
    onCloseAddFilterModal()
  }

  const addFilter = (newFilter: AlertFilter) => {
    setFilters([...filters, newFilter])
    onCloseAddFilterModal()
  }

  const onSaveFilterHandler = (filter: AlertFilter) => {
    if (editingFilter) {
      editFilter(filter)
    } else {
      addFilter(filter)
    }
  }

  const onDeleteFilter = (filter: AlertFilter) => {
    const filterIndex = filters.findIndex((currentFilter) => currentFilter === filter)
    const modifiedFilters = [...filters.slice(0, filterIndex), ...filters.slice(filterIndex + 1)]
    setFilters(modifiedFilters)
  }

  const onChangeFilter = (index: number, data: Partial<AlertFilter>) => {
    const replacedFilters = [...filters.slice(0, index), { ...filters[index], ...data }, ...filters.slice(index + 1)]
    setFilters(replacedFilters)
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {(isAddingFilter || !!editingFilter) && (
        <AddAlertFilterModal onClose={onCloseAddFilterModal} onSaveFilter={onSaveFilterHandler} selectedFilter={editingFilter} isOpen />
      )}
      <div className={`${rootClass}__filter-container`}>
        {filters.length > 0 ? (
          <AlertFiltersList filters={filters} onDelete={onDeleteFilter} onChange={onChangeFilter} onEdit={(filter) => setEditingFilter(filter)} />
        ) : (
          <AddAlertFilterButton onClick={() => setIsAddingFilter(true)} />
        )}
      </div>
      {filters.length > 0 && (
        <div className={`${rootClass}__footer`}>
          <AddButton label={t('Add filter')} onClick={() => setIsAddingFilter(true)} />
        </div>
      )}
    </div>
  )
}

export default AlertFilters
