import React, { FC, useRef } from 'react'

import classNames from 'classnames'

import Button, { ButtonIconPosition, ButtonSize, ButtonType, ButtonWeight } from '@components/Button/Button'
import HoverSVG from '@components/HoverSVG/HoverSVG'
import { SvgNames } from '@components/Svg/index'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './ButtonShowHide.css'

export enum ButtonShowHideSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

interface ButtonShowHideProps {
  size?: ButtonShowHideSize
  showText?: string
  hideText?: string
  isShowing: boolean
  hasIcon?: boolean
  onClick: () => void
  className?: string
  dataTest?: string
  disabled?: boolean
}

const rootClass = 'button-show-hide'

const getTextProps = (size: ButtonShowHideSize) => {
  if (size === ButtonShowHideSize.SMALL) {
    return {
      type: TextType.BODY_TEXT_SMALL,
    }
  } else if (size === ButtonShowHideSize.MEDIUM) {
    return {
      type: TextType.BODY_TEXT,
    }
  } else if (size === ButtonShowHideSize.LARGE) {
    return {
      type: TextType.BODY_TEXT_LARGE,
    }
  }
}

const ButtonShowHide: FC<ButtonShowHideProps> = (props: ButtonShowHideProps) => {
  const {
    isShowing,
    disabled,
    showText = 'Show',
    hideText = 'Hide',
    size = ButtonShowHideSize.MEDIUM,
    hasIcon = true,
    onClick,
    dataTest = rootClass,
    className = '',
  } = props
  const { t } = useTranslation()
  const buttonRef = useRef<HTMLButtonElement>()

  const textProps = getTextProps(size)

  return (
    <Button
      register={buttonRef}
      buttonType={ButtonType.TEXT_TEAL}
      iconPosition={ButtonIconPosition.LEFT}
      buttonSize={ButtonSize.SMALL}
      weight={ButtonWeight.MEDIUM}
      onClick={onClick}
      className={classNames(rootClass, className)}
      dataTest={dataTest}
      disabled={disabled}
    >
      {hasIcon && (
        <HoverSVG
          svg={isShowing ? SvgNames.hideGreen : SvgNames.showGreen}
          hoverSvg={isShowing ? SvgNames.hideDark : SvgNames.showDark}
          parentRef={buttonRef}
        />
      )}
      <Typography
        text={t(isShowing ? hideText : showText)}
        weight={TextWeight.MEDIUM}
        lineHeight={LineHeight.MEDIUM_SMALL}
        {...textProps}
        className={`${rootClass}__text`}
      />
    </Button>
  )
}

export default ButtonShowHide
