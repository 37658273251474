import { legacyActonContext } from '@const/globals'
import documentUtils from '@utils/document'
import { SetIFrameVisibilityAction } from '@utils/iframe/state/actions'
import actionTypes from '@utils/iframe/state/actionTypes'
import windowUtils from '@utils/window'

export function getIFrame() {
  return documentUtils.getElementById('root-iframe') as HTMLIFrameElement
}

const getJSPIFrame = () => getIFrame().contentDocument?.querySelector('iframe')

export const postClassicMessage = (message: any) => {
  const iframe = message.getRootIFrameOnly ? getIFrame() : getJSPIFrame()
  if (iframe) {
    if (iframe.src?.includes(legacyActonContext)) {
      iframe.contentWindow?.postMessage(message, '*')
    }
  }
}

export function postMessage(message: any, setPendingIFrameRequest?: (iframeMessage?: any) => void) {
  const iframe = getIFrame()
  if (iframe) {
    if (iframe.src?.includes(`${legacyActonContext}/ng-ui`)) {
      iframe.contentWindow?.postMessage(message, '*')
    } else if (setPendingIFrameRequest) {
      setPendingIFrameRequest(message)
    }
  }
}

export function validateOrigin(origin?: string) {
  if (!origin) return false
  const parts = origin.split(':')
  if (parts.length >= 2) {
    return parts[1].replace(/\//g, '') === windowUtils.getHostName().split(':')[0]
  }
  return false
}

export interface FrameViewState {
  visible: boolean
}

export function iframeView(
  state: FrameViewState = {
    visible: true,
  },
  action: SetIFrameVisibilityAction
) {
  switch (action.type) {
    case actionTypes.setIframeVisibility: {
      return {
        ...state,
        visible: action.payload,
      }
    }
    default:
      return state
  }
}

export default {
  getIFrame,
  postMessage,
  postClassicMessage,
  validateOrigin,
  iframeView,
}
