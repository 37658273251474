export enum LandingPageManagerTab {
  DETAILS = 'details',
  REPORT = 'report',
  PERFORMANCE = 'performance',
  CLICK_MAP = 'click-map',
  LINKS = 'links',
}

export enum LandingPageManagerSubTab {
  MANAGE_URLS = 'manageUrls',
}

export const getLandingPageDefaultTab = (showLinksAndMap: boolean, matchTab?: string): LandingPageManagerTab => {
  if (matchTab && Object.values(LandingPageManagerTab).includes(matchTab as LandingPageManagerTab)) {
    if (showLinksAndMap && matchTab === LandingPageManagerTab.REPORT) {
      return LandingPageManagerTab.PERFORMANCE
    } else if (!showLinksAndMap && matchTab !== LandingPageManagerTab.DETAILS && matchTab !== LandingPageManagerTab.REPORT) {
      return LandingPageManagerTab.REPORT
    }
    return matchTab as LandingPageManagerTab
  }
  return LandingPageManagerTab.DETAILS
}
