import React, { FC, useContext } from 'react'

import classNames from 'classnames'

import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import { useTranslation } from '@const/globals'
import { ContactSegmentsContext } from '@utils/contactSegments/context/ContactSegmentsContext'

const rootClass = 'done-copy-segments-modal'
const rootText = 'ListPage.ContactSegments.DoneCopySegmentsModal'

const DoneCopySegmentsModal: FC = () => {
  const { doFinishCopySegments, update } = useContext(ContactSegmentsContext)
  const { t } = useTranslation()

  const onAnswer = (answer: YesNo) => {
    if (answer === YesNo.YES) {
      doFinishCopySegments()
    }
    update({ showDoneCopySegmentsModal: false })
  }

  return (
    <ConfirmationModal
      isYesNo
      isOpen
      dataTest={rootClass}
      className={classNames(rootClass)}
      title={t('Are you sure?')}
      body={t(`${rootText}.Body`)}
      onAnswer={onAnswer}
    />
  )
}

export default DoneCopySegmentsModal
