import React, { FC, useEffect, useState } from 'react'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import createSenderDomainConfig from '@graphql/microservices/email-management/createSenderDomainConfig'
import getAllSenderDomainConfig from '@graphql/microservices/email-management/getAllSenderDomainConfig'
import changeDefaultFromAddress from '@graphql/mutations/changeDefaultFromAddress'
import deleteFromAddress from '@graphql/mutations/deleteFromAddress'
import saveFromAddress from '@graphql/mutations/saveFromAddress'
import verifyFromAddress from '@graphql/mutations/verifyFromAddress'
import loadFromAddressesPage from '@graphql/queries/loadFromAddressesPage'
import {
  CreateSenderDomainConfigMutation,
  CreateSenderDomainConfigMutationVariables,
  GetAllSenderDomainConfigQuery,
  GetAllSenderDomainConfigQueryVariables,
} from '@graphql/types/microservice/email-management-proxy-service-types'
import {
  ChangeDefaultFromAddressMutation,
  ChangeDefaultFromAddressMutationVariables,
  DeleteFromAddressMutation,
  DeleteFromAddressMutationVariables,
  SaveFromAddressMutation,
  SaveFromAddressMutationVariables,
  VerifyFromAddressMutation,
  VerifyFromAddressMutationVariables,
} from '@graphql/types/mutation-types'
import { FromAddresses, LoadFromAddressesPageQuery, QueryLoadFromAddressesPageArgs } from '@graphql/types/query-types'
import FromAddress from '@src/pages/Settings/OtherSettings/FromAddresses/FromAddress'
import { SenderDomainInfo } from '@src/pages/Settings/OtherSettings/FromAddresses/utils/FromAddresses.constants'
import { useAccountSettings } from '@utils/account/account.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'
import { logNewRelicError } from '@utils/new-relic.utils'

interface State {
  fromAddresses: FromAddresses[]
  domainConfigInfo: SenderDomainInfo | undefined
}
const FromAddressesContainer: FC = () => {
  const [state, setState] = useState<State>({
    fromAddresses: [],
    domainConfigInfo: undefined,
  })
  const { fromAddresses, domainConfigInfo } = state
  const {
    userSettings: { isAdministrator },
    hasCheckDomainConfig,
    parentId,
  } = useAccountSettings()

  const client = useApolloClient()
  const { client: emailManagementClient } = useMicroserviceClient({ serviceName: MicroserviceClients.EMAIL_MANAGEMENT_PROXY })
  const { data, loading, error, refetch } = useQuery<LoadFromAddressesPageQuery, QueryLoadFromAddressesPageArgs>(loadFromAddressesPage, {
    client,
    fetchPolicy: 'network-only',
    variables: { sortColumn: 'NAME', sortDirection: 'ASC' },
  })

  //TODO:: this will change
  const { data: allSenderDomainConfigData, loading: senderDomainLoading } = hasCheckDomainConfig
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useQueryOnMount<GetAllSenderDomainConfigQuery, GetAllSenderDomainConfigQueryVariables>(getAllSenderDomainConfig, {
        client: emailManagementClient,
        fetchPolicy: 'network-only',
      })
    : { data: undefined, loading: false }

  const [saveAddress] = useMutation<SaveFromAddressMutation, SaveFromAddressMutationVariables>(saveFromAddress, {
    client,
    fetchPolicy: 'no-cache',
  })

  const [sendEmailVerification] = useMutation<VerifyFromAddressMutation, VerifyFromAddressMutationVariables>(verifyFromAddress, {
    client,
    fetchPolicy: 'no-cache',
  })

  const [deleteAddress] = useMutation<DeleteFromAddressMutation, DeleteFromAddressMutationVariables>(deleteFromAddress, {
    client,
    fetchPolicy: 'no-cache',
  })

  const [changeDefault] = useMutation<ChangeDefaultFromAddressMutation, ChangeDefaultFromAddressMutationVariables>(changeDefaultFromAddress, {
    client,
    fetchPolicy: 'no-cache',
  })

  const [saveAddressToService] = useMutation<CreateSenderDomainConfigMutation, CreateSenderDomainConfigMutationVariables>(createSenderDomainConfig, {
    client: emailManagementClient,
    fetchPolicy: 'no-cache',
    variables: { parentId: parseInt(parentId as string) || null },
  })

  useEffect(() => {
    const fromAddresses = data?.loadFromAddressesPage
    if (!loading && fromAddresses) {
      setState((state) => ({ ...state, fromAddresses: fromAddresses }))
    }
    if (error) {
      logNewRelicError(error)
    }
  }, [loading, data, error])

  useEffect(() => {
    const domainConfig = allSenderDomainConfigData?.getAllSenderDomainConfig
    if (!senderDomainLoading && domainConfig) {
      setState((state) => ({
        ...state,
        domainConfigInfo: domainConfig.reduce((acc: SenderDomainInfo, domain) => {
          return domain
            ? {
                ...acc,
                [domain.senderDomain as string]: {
                  hasDKIM: domain.hasDkimCnameRecord || domain.hasDkimTxtRecord || domain.dkimIsSubdomain,
                  hasSPF: domain.hasSpfTxtRecord,
                },
              }
            : { ...acc }
        }, {}),
      }))
    }
  }, [allSenderDomainConfigData, senderDomainLoading])

  return (
    <FromAddress
      state={fromAddresses}
      loading={loading}
      isAdmin={isAdministrator}
      saveAddress={saveAddress}
      saveAddressToService={saveAddressToService}
      deleteAddress={deleteAddress}
      changeDefault={changeDefault}
      sendEmailVerification={sendEmailVerification}
      refetch={refetch}
      domainConfigInfo={domainConfigInfo}
    />
  )
}

export default FromAddressesContainer
