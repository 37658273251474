import { gql } from '@apollo/client'

export default gql`
  query getAnalyticsSettings {
    getAnalyticsSettings {
      domains
      useGoogle
      useCoreMetrics
    }
  }
`
