import { useEffect, useRef } from 'react'

import { CACHE_BUSTER } from '@const/globals'
import getThoughtSpotLoginToken from '@graphql/microservices/thoughtspot/getThoughtSpotLoginToken'
import { AuthType, init } from '@thoughtspot/visual-embed-sdk'
import useMicroserviceClient, { MicroserviceClients, useMicroserviceUrl } from '@utils/hooks/useMicroserviceClient'

const THOUGHTSPOT_CLIENT_URL = `https://act-on.thoughtspot.cloud`
const THOUGHTSPOT_SANDBOX_CLIENT_URL = 'https://act-on-dev.thoughtspot.cloud'

export const useThoughtSpotService = (userEmail: string) => {
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.THOUGHTSPOT })
  const serviceUrl = useMicroserviceUrl({ serviceName: MicroserviceClients.THOUGHTSPOT })
  const clientRef = useRef(client)

  useEffect(() => {
    clientRef.current = client
  }, [client])

  const tsAuth = () => {
    return clientRef.current
      .query<string>({
        query: getThoughtSpotLoginToken,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        variables: {
          username: userEmail,
        },
      })
      .then((response: any) => {
        return response.data.getThoughtSpotLoginToken
      })
  }

  const isSandbox = serviceUrl?.includes('thoughtspot-service-sandbox')
  const thoughtSpotHost = isSandbox ? THOUGHTSPOT_SANDBOX_CLIENT_URL : THOUGHTSPOT_CLIENT_URL

  return {
    tsAuth,
    thoughtSpotHost: `${thoughtSpotHost}?v=${CACHE_BUSTER}`,
  }
}

export const tsInitializer = (thoughtSpotHost: string, userEmail: string, tsAuth: () => Promise<string>) => {
  // This is temporary, while the S3 buckets are worked out Prod works and the others don't
  const css = `${window.location.origin}/app/static/thoughtspot/thoughtspot-ao-styles.css`

  return init({
    thoughtSpotHost,
    authType: AuthType.TrustedAuthTokenCookieless,
    username: userEmail,
    autoLogin: true,
    getAuthToken: () => tsAuth(),
    customizations: {
      style: {
        customCSSUrl: css,
      },
    },
  })
}
