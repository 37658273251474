import React, { FC } from 'react'

import classNames from 'classnames'

import chooserUtils from '@components/ChooserScreen/chooserUtils'

import { ChooserButtonProps } from './ChooserButton'

export interface ChooserGroupProps {
  dataTest?: string
  className?: string
  children: React.ReactElement<ChooserButtonProps> | React.ReactElement<ChooserButtonProps>[]
}

const rootClass = 'chooser-group'

export const ChooserGroup: FC<ChooserGroupProps> = (props: ChooserGroupProps) => {
  const { dataTest = 'chooser-group', className, children } = props

  const numberOfChildren = chooserUtils.getNumberOfRenderedChildren(children)
  if (numberOfChildren === 0) {
    return null
  }

  return (
    <div data-test={dataTest} className={classNames(rootClass, className)}>
      {children}
    </div>
  )
}

export default ChooserGroup
