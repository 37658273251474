enum StaticImageNames {
  aiError = 'ai-error',
  aiIntegration = 'ai-integration',
  analytics = 'analytics',
  aocNoFolders = 'aoc-no-folders',
  aocNoTags = 'aoc-no-tags',
  betaBannerCard = 'beta-banner-card',
  blocked = 'blocked',
  cactus = 'cactus',
  cardBrandingFeedback = 'card-branding-feedback',
  cardBrandingGuide = 'card-branding-guide',
  cardBrandingZoomConnection = 'card-branding-zoom-connection',
  checkmark = 'checkmark',
  clock = 'clock',
  clouds = 'clouds',
  csvFile = 'csv-file',
  download = 'download',
  download2 = 'download2',
  emptyAlerts = 'empty-alerts',
  emptyAllSegmentIllustration = 'empty-all-segment-illustration',
  emptyBox = 'empty-box',
  emptyCelebrate = 'empty-celebrate',
  emptyCelebrateSmall = 'empty-celebrate-small',
  emptyChart = 'empty-chart',
  emptyChartMedium = 'empty-chart-medium',
  emptyContacts = 'empty-contacts',
  emptyContactsMedium = 'empty-contacts-medium',
  emptyCreatedByMe = 'empty-created-by-me',
  emptyFavorites = 'empty-favorites',
  emptyFilter = 'empty-filter',
  emptyFolder = 'empty-folder',
  emptyFormSubmissions = 'empty-form-submissions',
  emptyHiding = 'empty-hiding',
  emptyLost = 'empty-lost',
  emptyMappedFields = 'empty-mapped-fields',
  emptyMessages = 'empty-messages',
  emptyRecent = 'empty-recent',
  emptyRestrictedContent = 'empty-restricted-content',
  emptySearch = 'empty-search',
  emptySegmentIllustration = 'empty-segment-illustration',
  emptySegmentIllustrationV2 = 'empty-segment-illustration-v2',
  emptyShareAsset = 'empty-share-asset',
  emptySmsOptInConfirmation = 'empty-sms-opt-in-confirmation',
  emptyStateIllust = 'empty-state-illust',
  emptyTag = 'empty-tag',
  emptyWebhooks = 'empty-webhooks',
  emptyWebinarSubmissions = 'empty-webinar-submissions',
  envelope = 'envelope',
  envelopePurple = 'envelope-purple',
  envelopeTeal = 'envelope-teal',
  errorCircle = 'error-circle',
  errorCircleMedium = 'error-circle-medium',
  errorNothingFound = 'error-nothing-found',
  errorStateAllContacts = 'error-state-all-contacts',
  fieldMatching = 'field-matching',
  image = 'image',
  imageLight = 'image-light',
  inbox = 'inbox',
  inboxPreviewEmpty = 'inbox-preview-empty',
  lifeSaver = 'life-saver',
  lineChartEmpty = 'line-chart-empty',
  logo = 'logo',
  logosEmpty = 'logos-empty',
  messagesTeal = 'messages-teal',
  mlSegmentation = 'ml-segmentation',
  musubi = 'musubi',
  noProgramHistory = 'no-program-history',
  pausedProgram = 'paused-program',
  peopleChart = 'people-chart',
  personalizationCheckDefault = 'personalization-check-default',
  personalizationCheckError = 'personalization-check-error',
  personalizationCheckFailure = 'personalization-check-failure',
  personalizationCheckSuccess = 'personalization-check-success',
  print = 'print',
  scheduledEmpty = 'scheduled-empty',
  smsClicksEmpty = 'sms-clicks-empty',
  smsDraftsEmpty = 'sms-drafts-empty',
  smsOptInEmpty = 'sms-opt-in-empty',
  smsOptOutEmpty = 'sms-opt-out-empty',
  smsRepliesEmpty = 'sms-replies-empty',
  smsSentEmpty = 'sms-sent-empty',
  spamCheckDefault = 'spam-check-default',
  spamCheckError = 'spam-check-error',
  spamCheckFailure = 'spam-check-failure',
  spamCheckSuccess = 'spam-check-success',
  successCircle = 'success-circle',
  upgradingAsset = 'upgrading-asset',
  uploadFormActive = 'upload-form-active',
  warning = 'warning',
  warningSmall = 'warning-small',
  welcome = 'welcome',
}

export const StaticImageSvgs = [
  StaticImageNames.emptySegmentIllustration,
  StaticImageNames.emptyBox,
  StaticImageNames.emptyContactsMedium,
  StaticImageNames.fieldMatching,
  StaticImageNames.blocked,
  StaticImageNames.envelopePurple,
  StaticImageNames.envelopeTeal,
]

export default StaticImageNames
