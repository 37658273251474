import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'
import { t } from 'i18next'

import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'

import './container.css'

const rootClass = 'container'

export interface Props {
  children: ReactNode
  className?: string
  dataTest?: string
  noBottom?: boolean
  noPadding?: boolean
  noSidePadding?: boolean
  noBottomPadding?: boolean
  header?: string | ReactNode
  title?: string
  withoutDescription?: boolean
  description?: string | ReactNode
  onClick?: VoidFunction
}

const Container: FC<Props> = (props: Props) => {
  const {
    className,
    children,
    noBottom,
    noPadding,
    noBottomPadding,
    noSidePadding,
    dataTest = 'container',
    header,
    title,
    description,
    withoutDescription,
    onClick,
  } = props
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={classNames(`${rootClass}__wrapper`, { [`${className}__wrapper`]: className })}
      onClick={onClick}
      role={onClick ? 'button' : undefined}
    >
      {header && (
        <div className={`${rootClass}__header`}>
          {typeof header === 'string' ? <Typography text={header} weight={TextWeight.MEDIUM} lineHeight={LineHeight.TINY} /> : header}
        </div>
      )}
      <div
        className={classNames(rootClass, className, {
          [`${rootClass}__no-bottom`]: noBottom,
          [`${rootClass}__no-padding`]: noPadding,
          [`${rootClass}__no-side-padding`]: noSidePadding,
          [`${rootClass}__no-bottom-padding`]: noBottomPadding,
          [`${rootClass}__has-header`]: header,
        })}
        data-test={dataTest}
      >
        {title && (
          <Typography
            text={t(title)}
            type={TextType.SECTION_HEADER}
            className={classNames(`${rootClass}__title`, {
              [`${rootClass}__title-no-description`]: withoutDescription,
            })}
            weight={TextWeight.MEDIUM}
          />
        )}
        {description && (
          <div className={`${rootClass}__description`}>
            {typeof description === 'string' ? <Typography text={t(description)} type={TextType.BODY_TEXT_LIGHT} /> : description}
          </div>
        )}
        {children}
      </div>
    </div>
  )
}

export default Container
