import React, { FC, useContext, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import EditableContainer from '@components/EditableContainer/EditableContainer'
import { TableV2 } from '@components/TableV2/TableV2'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { rootTranslation } from '@src/pages/RecommendedSegments/components/SegmentsInsightsSettingsContainer/SegmentsInsightsSettingsContainer'
import {
  getCriteriaSettingsColumns,
  getSelectedCriteriaRowsIds,
  SegmentationCriteriaRow,
} from '@src/pages/RecommendedSegments/components/SegmentsInsightsSettingsModal/components/SegmentsInsightsCriteriaSettings/utils/SegmentsInsightsCriteriaSettings.utils'
import { FeaturesCategories } from '@src/pages/RecommendedSegments/utils/RecommendedSegments.constants'
import { RecommendedSegmentsContext } from '@src/pages/RecommendedSegments/utils/RecommendedSegments.context'

import './SegmentsInsightsCriteriaSettings.css'

interface SegmentsInsightsCriteriaSettingsProps {
  className?: string
  dataTest?: string
}

const rootClass = 'segments-insights-criteria-settings'

const SegmentsInsightsCriteriaSettings: FC<SegmentsInsightsCriteriaSettingsProps> = (props: SegmentsInsightsCriteriaSettingsProps) => {
  const { dataTest = rootClass, className = '' } = props

  const {
    saveSettings,
    values: { settings },
  } = useContext(RecommendedSegmentsContext)

  const { categorizedFeatures = [], availableFeatures = [], invalidFeatures = [] } = { ...settings }

  const [selectedCriteriaRowsIds, setSelectedCriteriaRowsIds] = useState<Record<string, boolean>>({})

  const { t } = useTranslation()

  useEffect(() => {
    if (availableFeatures.length && categorizedFeatures.length) {
      const selectedCriteriaRowsIds = getSelectedCriteriaRowsIds(categorizedFeatures, availableFeatures)
      setSelectedCriteriaRowsIds(selectedCriteriaRowsIds)
    }
  }, [availableFeatures, categorizedFeatures])

  const attributesRowData: SegmentationCriteriaRow[] = useMemo(
    () =>
      availableFeatures.map((criteria) => ({
        ...criteria,
        subRows: [...Array(criteria.features.length).keys()].map((index) => {
          const feature = criteria.features[index]
          return { ...criteria, disabled: invalidFeatures.includes(feature) }
        }),
      })),
    [availableFeatures, invalidFeatures]
  )

  const columns: ColumnDefWithAdditionalProps<SegmentationCriteriaRow>[] = useMemo(() => {
    const selectedAttributesAmountById = availableFeatures.reduce((selectedByRowId: Map<number, number>, _, index) => {
      const amount = Object.keys(selectedCriteriaRowsIds).filter((rowId) => rowId.includes(`${index}.`)).length
      selectedByRowId.set(index, amount)
      return selectedByRowId
    }, new Map())
    return getCriteriaSettingsColumns(selectedAttributesAmountById, t)
  }, [availableFeatures, selectedCriteriaRowsIds])

  const availableAttributesByCategory = useMemo(
    () =>
      availableFeatures.reduce((amountByCategory: Map<string, number>, { category, features }) => {
        amountByCategory.set(category, features.length)
        return amountByCategory
      }, new Map()),
    [availableFeatures]
  )

  const onRowSelectionChange = (rowIds: string[]) => {
    const newSelectedRowsIds = rowIds.reduce((rowIdsMap: Record<string, boolean>, rowId) => ({ ...rowIdsMap, [rowId]: true }), {})
    setSelectedCriteriaRowsIds(newSelectedRowsIds)
  }

  const onSave = () => {
    const tempNewCriteria: Map<string, string[]> = new Map()
    Object.keys(selectedCriteriaRowsIds).forEach((rowId) => {
      const [categoryId, attributeId] = rowId.split('.')
      if (attributeId) {
        const criteria = availableFeatures[parseInt(categoryId)]
        const categorySelectedAttributes = tempNewCriteria.get(criteria.category) ?? []
        tempNewCriteria.set(criteria.category, [...categorySelectedAttributes, criteria.features[parseInt(attributeId)]])
      }
    })
    saveSettings({
      data: {
        featuresCat: tempNewCriteria.get(FeaturesCategories.CATEGORICAL) ?? [],
        featuresNum: tempNewCriteria.get(FeaturesCategories.NUMERICAL) ?? [],
      },
    })
  }

  const onReset = () => {
    const filterInvalidFeatures = (features: string[]) => features.filter((feature) => !invalidFeatures.includes(feature))
    saveSettings({
      data: {
        featuresCat: filterInvalidFeatures(availableFeatures[0].features),
        featuresNum: filterInvalidFeatures(availableFeatures[1].features),
      },
    })
  }

  const onCancel = () => {
    const selectedCriteriaRowsIds = getSelectedCriteriaRowsIds(categorizedFeatures, availableFeatures)
    setSelectedCriteriaRowsIds(selectedCriteriaRowsIds)
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <EditableContainer onSave={onSave} onCancel={onCancel} onReset={onReset}>
        {(isEditable) => (
          <>
            <Typography
              text={t(`${rootTranslation}.Criteria.Header`)}
              type={TextType.BODY_TEXT_LARGE}
              weight={TextWeight.MEDIUM}
              lineHeight={LineHeight.MEDIUM_LARGE}
            />
            <Typography className={`${rootClass}__subheader`} text={t(`${rootTranslation}.Criteria.Subheader`)} />
            {isEditable ? (
              <TableV2
                data={attributesRowData}
                columns={columns}
                withoutBorders
                enableSubRow
                enableCheckbox
                defaultSelectedRows={selectedCriteriaRowsIds}
                onRowSelectionChanged={onRowSelectionChange}
                enableSubRowSelection
                rowDisabledTitle={t('Some fields are not mapped to your CRM and can not be added to the AI Insights model')}
              />
            ) : (
              categorizedFeatures.map(({ category, features }) => (
                <Typography
                  key={category}
                  dataTest={`${dataTest}-${category}`}
                  text={`${t(category)} (${features.length}/${availableAttributesByCategory.get(category)})`}
                  type={TextType.BODY_TEXT_LIGHT}
                />
              ))
            )}
          </>
        )}
      </EditableContainer>
    </div>
  )
}

export default SegmentsInsightsCriteriaSettings
