import { gql } from '@apollo/client'

export default gql`
  mutation updateSegment($accountId: ID, $segmentDbId: ID, $status: SegmentStatus, $segmentFeedback: [SegmentFeedback]) {
    updateSegment(accountId: $accountId, segmentDbId: $segmentDbId, status: $status, segmentFeedback: $segmentFeedback) {
      id
      accountId
      configId
      jobId
      segmentId
      status
      name
      factors
      memberEstimate
      insertTime
      updateTime
      parentSegmentId
      type
      feedback
      selectionReason
    }
  }
`
