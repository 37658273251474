import React, { FC, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/Button'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './AddUserButton.css'

interface AddUserButtonProps {
  className?: string
  dataTest?: string
  isAdded?: boolean
  onClick?: () => void
}

const rootClass = 'add-user-button'
const JUST_ADDED_TIME = 1000

const AddUserButton: FC<AddUserButtonProps> = (props: AddUserButtonProps) => {
  const { dataTest = rootClass, className = '', isAdded = false, onClick } = props

  const [justAdded, setJustAdded] = useState(false)
  const isAddedPreviousRenderValue = useRef(isAdded)
  const hasToRemove = isAdded && !justAdded && isAddedPreviousRenderValue.current === isAdded

  const { t } = useTranslation()

  useEffect(() => {
    if (isAdded && isAddedPreviousRenderValue.current !== isAdded) {
      setJustAdded(true)
      setTimeout(() => setJustAdded(false), JUST_ADDED_TIME)
    }
    isAddedPreviousRenderValue.current = isAdded
  }, [isAdded])

  return (
    <Button
      buttonType={ButtonType.TEXT}
      dataTest={dataTest}
      className={classNames(rootClass, className, {
        [`${rootClass}__add`]: !isAdded,
        [`${rootClass}__just-added`]: justAdded,
        [`${rootClass}__remove`]: hasToRemove,
      })}
      onClick={onClick}
    >
      <Typography
        text={t(isAdded ? (hasToRemove ? 'Remove' : 'Added!') : 'Add')}
        type={isAdded ? TextType.BODY_TEXT_WHITE_SMALL : TextType.NORMAL_TEXT_TEAL}
        lineHeight={LineHeight.MEDIUM_SMALL}
        weight={TextWeight.MEDIUM}
      />
    </Button>
  )
}

export default AddUserButton
