import getAllItems from '@graphql/microservices/categorization/getAllItems'
import getTopDirectSelectSegments from '@graphql/microservices/categorization/getTopDirectSelectSegments'
import {
  ConditionOperator,
  GetAllItemsQuery,
  GetAllItemsQueryVariables,
  GetTopDirectSelectSegmentsQuery,
  GetTopDirectSelectSegmentsQueryVariables,
} from '@graphql/types/microservice/categorization-types'
import { ItemType } from '@utils/categorization'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export type GetTopDirectSelectSegmentsRequestType = (currentPage: number) => FetchPromise<GetTopDirectSelectSegmentsQuery>
export type SearchDirectSelectSegmentsRequestType = (search: string) => FetchPromise<GetAllItemsQuery>

export interface ImportContactsCategorizationRequests {
  getTopDirectSelectSegmentsRequest: GetTopDirectSelectSegmentsRequestType
  searchDirectSelectSegmentsRequest: SearchDirectSelectSegmentsRequestType
}

export const useImportContactsCategorizationRequests = (): ImportContactsCategorizationRequests => {
  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })
  const getTopDirectSelectSegmentsRequest = async (currentPage: number) =>
    await categorizationClient.query<GetTopDirectSelectSegmentsQuery, GetTopDirectSelectSegmentsQueryVariables>({
      query: getTopDirectSelectSegments,
      errorPolicy: 'all',
      variables: {
        fieldType: 'type',
        pageNumber: currentPage,
        pageSize: 50,
        type: ItemType.SEGMENT,
      },
    })

  const searchDirectSelectSegmentsRequest = async (search: string) =>
    await categorizationClient.query<GetAllItemsQuery, GetAllItemsQueryVariables>({
      query: getAllItems,
      fetchPolicy: 'network-only',
      variables: {
        type: ItemType.SEGMENT,
        pageNumber: 0,
        pageSize: 1000,
        fieldCondition: {
          itemField: {
            field: 'name',
            value: search,
          },
          conditionOperator: 'AND' as ConditionOperator,
          fieldCondition: {
            itemField: {
              field: 'type',
              value: 'direct',
            },
          },
        },
      },
    })

  return {
    getTopDirectSelectSegmentsRequest,
    searchDirectSelectSegmentsRequest,
  }
}
