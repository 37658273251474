import { gql } from '@apollo/client'

export default gql`
  query getSegmentMetadata($segmentId: String) {
    getSegmentMetadata(segmentId: $segmentId) {
      body
      message
      objectId
      statusCode
    }
  }
`
