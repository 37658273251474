import { gql } from '@apollo/client'

export default gql`
  query validateEmailSender($msgId: String!) {
    validateEmailSender(msgId: $msgId) {
      personalizeFromErrorSourceIds
      salesforceOwnerErrorSourceIds
    }
  }
`
