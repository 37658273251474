import {
  GetSegmentFilterRequestType,
  GetSegmentMetadataRequestType,
} from '@complex/ListPickerModalV2/utils/graphql/ListPickerModalSegmentRequests.graphQL'
import { ListPickerModalState } from '@complex/ListPickerModalV2/utils/interfaces/ListPickerModalInterfaces'
import { showFailStatusToast } from '@complex/ListPickerModalV2/utils/ListPickerModalUtils'
import { Campaign, CrmEntity, CrmEntityField, ScoreSheet, SubscriptionField, SupportedBehavior } from '@utils/contactSegments/contactSegments.utils'
import { SegmentDefinition } from '@utils/contactSegments/contactSegments.utils'
import { logNewRelicError } from '@utils/new-relic.utils'

export const getSegmentDefinitionUtils = async (segmentId: string, setState: Function, getSegmentFilterRequest: GetSegmentFilterRequestType) => {
  try {
    const { data } = await getSegmentFilterRequest({ segmentId })
    if (data) {
      return JSON.parse(data.getSegmentFilter?.body) as SegmentDefinition
    } else {
      showFailStatusToast(setState, "An unexpected error occurred while fetching segment's definition.")
    }
  } catch {
    showFailStatusToast(setState, "An unexpected error occurred while fetching segment's definition.")
  }
}

const mapEntityFields = (fields: CrmEntityField[]) =>
  fields.reduce((mappedFields: Record<string, string>, element: CrmEntityField) => {
    mappedFields[element.fieldName] = element.displayName
    return mappedFields
  }, {})

const getCRMMapping = ({ supportedCrmEntities }: any) =>
  supportedCrmEntities?.reduce(
    (mappedEntities: Record<string, { displayName: string; entityFields: Record<string, string> }>, element: CrmEntity) => {
      mappedEntities[element.entityType] = {
        displayName: element.displayName,
        entityFields: mapEntityFields(element.entityFields),
      }
      return mappedEntities
    },
    {}
  )

const getScoreSheets = ({ scoreSheets }: any) =>
  scoreSheets.reduce((scoreSheets: Record<string, string>, element: ScoreSheet) => {
    scoreSheets[element.id] = element.name
    return scoreSheets
  }, {})

const getSubscriptionMapping = ({ subscriptionFields }: any) =>
  subscriptionFields?.reduce((mappedFields: Record<string, string>, element: SubscriptionField) => {
    mappedFields[element.fieldName] = element.displayName
    return mappedFields
  }, {})

const getCampaigns = ({ campaigns }: any) =>
  campaigns.reduce((campaigns: Record<string, string>, element: Campaign) => {
    campaigns[element.id] = element.name
    return campaigns
  }, {})

const getSupportedBehaviors = ({ supportedBehaviors }: any) =>
  supportedBehaviors.reduce((behaviors: Record<string, string>, element: SupportedBehavior) => {
    behaviors[element.behaviorType] = element.displayName
    return behaviors
  }, {})

export const setSegmentsMetadataUtils = (setState: Function, getSegmentMetadataRequest: GetSegmentMetadataRequestType) => {
  setState((state: ListPickerModalState) => ({
    ...state,
    metadataLoading: true,
  }))
  getSegmentMetadataRequest()
    .then(({ data }) => {
      if (data) {
        const response = JSON.parse(data?.getSegmentMetadata?.body)
        if (response) {
          setState((state: ListPickerModalState) => ({
            ...state,
            crmMapping: getCRMMapping(response),
            subscriptionMapping: getSubscriptionMapping(response),
            campaigns: {
              ...state.campaigns,
              ...getCampaigns(response),
            },
            scoreSheets: getScoreSheets(response),
            supportedBehaviors: getSupportedBehaviors(response),
          }))
        }
      }
    })
    .catch((e) => {
      logNewRelicError(e)
      showFailStatusToast(setState, "An unexpected error occurred while fetching segment's metadata.")
    })
    .finally(() =>
      setState((state: ListPickerModalState) => ({
        ...state,
        metadataLoading: false,
      }))
    )
}
