import React, { FC, ReactNode } from 'react'

import Button, { ButtonType } from '@components/Button'
import SectionHeadline from '@components/SectionHeadline'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import CompanyInfoSectionMeta from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Company/components/CompanyInfoSectionMeta/CompanyInfoSectionMeta'

import './CompanyInfoSection.css'

interface Props {
  value: string
  onEdit: Function
  metaTitle?: string
  title?: string
  required?: boolean
  description?: string | ReactNode
  dataTest?: string
}
const rootClass = 'company__info-section'

const CompanyInfoSection: FC<Props> = (props: Props) => {
  const { title, description, onEdit, dataTest = 'section', metaTitle, required, ...metaProps } = props
  const { t } = useTranslation()
  return (
    <div className={rootClass} data-test={dataTest}>
      <div className={`${rootClass}-content`}>
        {title && (
          <SectionHeadline className={`${rootClass}-title`}>
            {title}
            {required && <Typography text={`(${t('required')})`} inline type={TextType.BODY_TEXT_LARGE_LIGHT} className={`${rootClass}-required`} />}
          </SectionHeadline>
        )}
        {description && (
          <div className={`${rootClass}-description`}>
            <Typography text={description} lineHeight={LineHeight.MEDIUM} />
          </div>
        )}
        <CompanyInfoSectionMeta title={metaTitle} {...metaProps} dataTest={`${dataTest}-meta`} />
      </div>
      <Button
        buttonType={ButtonType.FLOAT_TEAL}
        onClick={() => onEdit()}
        className={`${rootClass}__edit-button`}
        dataTest={`${dataTest}__edit-button`}
      >
        {t('Edit')}
      </Button>
    </div>
  )
}

export default CompanyInfoSection
