import { ApolloClient } from '@apollo/client'
import { Status } from '@components/StatusToast/StatusToast'
import addTag from '@graphql/microservices/categorization/addTag'
import applyTags from '@graphql/microservices/categorization/applyTags'
import deleteTags from '@graphql/microservices/categorization/deleteTags'
import getItemTypesByLabel from '@graphql/microservices/categorization/getItemTypesByLabel'
import getTags from '@graphql/microservices/categorization/getTags'
import removeTags from '@graphql/microservices/categorization/removeTags'
import {
  AddTagMutation,
  AddTagMutationVariables,
  ApplyTagsMutation,
  ApplyTagsMutationVariables,
  DeleteTagsMutation,
  DeleteTagsMutationVariables,
  GetItemTypesByLabelQuery,
  GetItemTypesByLabelQueryVariables,
  GetTagsQuery,
  GetTagsQueryVariables,
  LabelDto,
  LabelDtoInput,
  RemoveTagsMutation,
  RemoveTagsMutationVariables,
} from '@graphql/types/microservice/categorization-types'
import { SetContainerValues } from '@interface/ListPage.context'
import { CONTACT_SEGMENTS_CURRENT_SECTION } from '@src/pages/ContactSegments/utils/ContactSegmentsSession.utils'
import { ItemType } from '@utils/categorization'
import { ContactSegmentsSession, ContactSegmentsState } from '@utils/contactSegments/contactSegments.utils'
import { clearMenuSections } from '@utils/contactSegments/context/ContactSegmentsContext'
import { allSegmentsFilter, FilterDefinition } from '@utils/filter'
import { EMPTY_LISTING_OPTIONS } from '@utils/ListPage.context.default'
import { logNewRelicError } from '@utils/new-relic.utils'
import { setItem } from '@utils/sessionStorage'

export const applyTagsUtils = async (
  setContainerValues: SetContainerValues<ContactSegmentsState>,
  containerValues: ContactSegmentsState,
  client: ApolloClient<any>,
  segments: number[],
  tags: LabelDtoInput[],
  t: Function
) => {
  try {
    const { data } = await client.mutate<ApplyTagsMutation, ApplyTagsMutationVariables>({
      mutation: applyTags,
      variables: {
        items: segments,
        labels: tags,
        type: ItemType.SEGMENT,
      },
    })
    return data?.applyLabels
  } catch {
    setContainerValues({
      ...containerValues,
      statusToast: { statusMessage: t("Tags couldn't be applied"), showStatusToast: true, status: Status.FAIL },
      loading: false,
    })
  }
}

export const getTagsUtils = async (setContainerValues: SetContainerValues<ContactSegmentsState>, client: ApolloClient<any>) => {
  await client
    .query<GetTagsQuery, GetTagsQueryVariables>({
      query: getTags,
      fetchPolicy: 'network-only',
      variables: {
        types: [ItemType.SEGMENT, ItemType.BOUNCE],
      },
    })
    .then(({ data }) => {
      const tags = data.getLabels as LabelDto[]
      if (tags) {
        setContainerValues((containerValues) => ({ ...containerValues, tags }))
      }
    })
}

export const createTagUtils = (
  setContainerValues: SetContainerValues<ContactSegmentsState>,
  containerValues: ContactSegmentsState,
  client: ApolloClient<any>,
  tag: LabelDtoInput,
  refreshSegments: Function
) => {
  client
    .mutate<AddTagMutation, AddTagMutationVariables>({
      mutation: addTag,
      variables: {
        tag,
        type: ItemType.SEGMENT,
      },
    })
    .then(() => {
      setContainerValues({
        ...containerValues,
        showManageTag: false,
        statusToast: {
          statusMessage: `Tag has been ${tag.id ? 'updated' : 'added'}`,
          showStatusToast: true,
          status: Status.SUCCESS,
        },
      })
      getTagsUtils(setContainerValues, client)
      tag.id && refreshSegments(0)
    })
}

export const deleteTagUtils = (
  tagId: number,
  setContainerValues: SetContainerValues<ContactSegmentsState>,
  client: ApolloClient<any>,
  t: Function,
  setFilter: (filter: FilterDefinition) => void,
  setFolder: Function
) => {
  client
    .mutate<DeleteTagsMutation, DeleteTagsMutationVariables>({
      mutation: deleteTags,
      variables: {
        tagsId: [tagId],
        type: ItemType.SEGMENT,
      },
    })
    .then(() => {
      setContainerValues((containerValues) => {
        const { activeFolderId, activeTagId, filterActive } = containerValues
        if (activeTagId === tagId) {
          setFilter(allSegmentsFilter)
        } else {
          filterActive && setFilter(filterActive)
          activeFolderId && setFolder(activeFolderId)
        }
        return {
          ...containerValues,
          statusToast: { statusMessage: t('Tag has been deleted'), showStatusToast: true, status: Status.SUCCESS },
        }
      })
      getTagsUtils(setContainerValues, client)
    })
}

export const getItemTypesByTagIdUtils = async (tagId: number, client: ApolloClient<any>) => {
  try {
    const { data } = await client.query<GetItemTypesByLabelQuery, GetItemTypesByLabelQueryVariables>({
      query: getItemTypesByLabel,
      variables: {
        labelId: tagId,
      },
    })
    return data.getItemTypesByLabel ?? {}
  } catch (error) {
    logNewRelicError(error)
    return {}
  }
}

export const removeTagsUtils = async (
  setContainerValues: SetContainerValues<ContactSegmentsState>,
  containerValues: ContactSegmentsState,
  client: ApolloClient<any>,
  segments: number[],
  tags: number[],
  t: Function
) => {
  try {
    const { data } = await client.mutate<RemoveTagsMutation, RemoveTagsMutationVariables>({
      mutation: removeTags,
      variables: {
        items: segments,
        labels: tags,
        type: ItemType.SEGMENT,
      },
    })
    return data?.removeLabels
  } catch {
    setContainerValues({
      ...containerValues,
      statusToast: { statusMessage: t("Tags couldn't be removed."), showStatusToast: true, status: Status.FAIL },
    })
  }
}

export const setTagUtils = (tagId: number, setContainerValues: SetContainerValues<ContactSegmentsState>) => {
  setContainerValues((containerValues) => ({
    ...containerValues,
    ...clearMenuSections,
    activeTagId: tagId,
    emptyListingOption: EMPTY_LISTING_OPTIONS.EMPTY_TAG,
  }))
  setItem(ContactSegmentsSession.TAG, tagId?.toString())
  setItem(CONTACT_SEGMENTS_CURRENT_SECTION, ContactSegmentsSession.TAG)
}
