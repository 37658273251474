import React, { ChangeEvent, FC, useCallback, useContext } from 'react'

import { Checkbox } from '@components/Checkbox/Checkbox'
import Container from '@components/Container'
import Input from '@components/Input'
import RadioWithOptions from '@components/RadioWithOptions/RadioWithOptions'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2Props, SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { AddContactsFromCRMContext, RADIO_OPTION } from '@src/pages/ContactSegments/components/AddContactsFromCRM/AddContactsFromCRMContext'
import { ItemType } from '@utils/categorization'

import './CRMSegmentDetails.css'

const rootClass = 'crm-segment-details'
const dataTest = rootClass
const SALESFORCE_CAMPAIGNS = 'Salesforce Campaigns'

const CRMSegmentDetails: FC = () => {
  const {
    values: {
      isOngoingSync,
      options,
      optionsDetails,
      radioOption,
      segmentName,
      selectedOption,
      showDifferentTypeError,
      sources,
      syncs: { remaining: syncsRemaining, total: totalSyncs },
      validSegmentName,
    },
    getCRMSourcedSegmentOptions,
    isAValidSegmentName,
    searchCRMSourcedSegmentOptions,
    update,
  } = useContext(AddContactsFromCRMContext)

  const { t } = useTranslation()

  const showRemainingSyncs = !!Object.values(sources).reduce((total, curr) => total + curr.length, 0) && !sources[SALESFORCE_CAMPAIGNS]?.length

  const onSyncChange = useCallback((isOngoingSync: boolean) => update({ isOngoingSync }), [])

  const onChange = useCallback(
    (newSelectedOption?: SelectV2SingleOption) => {
      const currentSourceType = optionsDetails.find(({ segmentIdentifier }) => selectedOption?.value === segmentIdentifier)?.sourceType
      const newSelectedOptionSourceType = optionsDetails.find(({ segmentIdentifier }) => newSelectedOption?.value === segmentIdentifier)?.sourceType
      const hasSourcesSelected = !!Object.values(sources).flatMap((items) => items).length

      update({
        selectedOption: newSelectedOption,
        showDifferentTypeError: currentSourceType !== newSelectedOptionSourceType && hasSourcesSelected,
      })
    },
    [selectedOption, sources, optionsDetails]
  )

  const onInputBlur = useCallback((inputValue: string) => isAValidSegmentName(inputValue), [])

  const onInputChange = useCallback((inputValue: string) => update({ segmentName: inputValue }), [])

  const selectProps: SelectV2Props = {
    className: `${rootClass}__select`,
    defaultValue: selectedOption,
    isClearable: true,
    isSearchable: true,
    isAsync: true,
    itemType: ItemType.SEGMENT,
    loadNewOptions: (nextPage) => getCRMSourcedSegmentOptions(nextPage),
    maxMenuHeight: 200,
    onChange,
    options,
    searchOptions: searchCRMSourcedSegmentOptions,
    truncateOptions: true,
  }

  return (
    <Container className={rootClass} data-test={dataTest}>
      <Typography className={`${rootClass}__header`} text={t('CRM Segment details')} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
      <Typography
        className={`${rootClass}__description`}
        text={t('Select an existing CRM sourced segment or create a new one. Then choose whether it should stay in sync with your CRM.')}
        type={TextType.BODY_TEXT_LIGHT}
      />
      <div className={`${rootClass}__options-container`}>
        <div className={`${rootClass}__radio-option-create-container`}>
          <RadioWithOptions
            label={t('Create a new segment')}
            onChange={() => update({ radioOption: RADIO_OPTION.CREATE })}
            checked={radioOption === RADIO_OPTION.CREATE}
          >
            <Input
              className={`${rootClass}__input`}
              value={segmentName}
              placeholder={t('Segment name')}
              onChange={({ target }: ChangeEvent<HTMLInputElement>) => onInputChange(target?.value)}
              onBlur={({ target }: ChangeEvent<HTMLInputElement>) => onInputBlur(target?.value)}
              error={!validSegmentName}
            />
          </RadioWithOptions>
          {!validSegmentName && radioOption === RADIO_OPTION.CREATE && (
            <Typography
              className={`${rootClass}__radio-option-create-error`}
              text={t('This segment name is already in use')}
              type={TextType.ERROR}
              inline
            />
          )}
        </div>
        <RadioWithOptions
          label={t('Select an existing segment')}
          onChange={() => update({ radioOption: RADIO_OPTION.EDIT })}
          checked={radioOption === RADIO_OPTION.EDIT}
        >
          <div className={`${rootClass}__radio-option-edit`}>
            <SelectV2 key={`${selectedOption?.value}`} {...selectProps} />
            {(!selectedOption || showDifferentTypeError) && (
              <Tooltip trigger={<Svg name={SvgNames.errorSolid} type={SvgType.LARGER_ICON} />}>
                {showDifferentTypeError
                  ? t('AddContactsFromCRM.CRMSegmentDetails.Select.DifferentType.Tooltip')
                  : t('AddContactsFromCRM.CRMSegmentDetails.Select.Tooltip')}
              </Tooltip>
            )}
          </div>
        </RadioWithOptions>
        <div className={`${rootClass}__keep-sync`}>
          <Checkbox
            checked={isOngoingSync}
            onChange={onSyncChange}
            label={
              <div data-test={`${dataTest}__sync-remaining`}>
                <Typography text={t('Keep this segment in sync with CRM')} inline />
                {showRemainingSyncs && (
                  <Typography
                    text={t('AddContactsFromCRM.Syncs.Remaining')}
                    values={{ syncsRemaining, totalSyncs }}
                    tagProps={{ bold: { weight: TextWeight.BOLD } }}
                    inline
                  />
                )}
              </div>
            }
          />
          {showRemainingSyncs && (
            <Tooltip trigger={<Svg name={SvgNames.info} type={SvgType.ICON} />}>
              {t(
                'Your account allows for a total of {{totalSyncs}} synced segments. You can create {{syncsRemaining}} more synced segments before reaching the limit.',
                { syncsRemaining, totalSyncs }
              )}
            </Tooltip>
          )}
        </div>
      </div>
    </Container>
  )
}

export default CRMSegmentDetails
