import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'

import { v4 as scrollAreaKey } from 'uuid'

import Loader from '@components/Loader'
import ScrollArea from '@components/ScrollArea/ScrollArea'
import { TableV2VirtualScroller } from '@components/TableV2/components/TableV2VirtualScroller/TableV2VirtualScroller'
import { useStickyColWidths } from '@components/TableV2/hook/tableV2.hook'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { Row } from '@tanstack/react-table'
import { isFunction } from '@utils/utils'

import { CommonV2RowProps, TableV2Props } from '../../tableV2TS/interfaces'
import { CanDropCallback } from '../../tableV2TS/types'
import { hoverStateHandler } from '../../utils/subRowUtils'
import { BODY_MIN_HEIGHT, getCaretCellID } from '../../utils/tableV2Utils'
import { DraggableRow } from '../DraggableRow/DraggableRow'
import { SingleRow } from '../SingleRow/SingleRow'
import { TableV2Row } from '../TableV2Row/TableV2Row'

import './TBody.css'

type ExcludedTBodyProps = 'setHoveredRowID' | 'hoveredRowID' | 'isExpanded' | 'stickyColsWidths' | 'rowRef'

interface TBodyProps<T = {}> extends Omit<CommonV2RowProps<T>, ExcludedTBodyProps> {
  isLoading?: boolean
  isExpanded: boolean
  rowEmptyText?: string
  isDraggable?: boolean
  columnsLength: number
  selectedRows: Row<T>[]
  enablePaginate?: boolean
  showInsideLoader?: boolean
  hasRowDisabledTitle?: boolean
  data: TableV2Props<T>['data']
  hasDisabledRowStyles?: boolean
  canDrop?: boolean | CanDropCallback<T>
  bodyMaxHeight?: TableV2Props<T>['bodyMaxHeight']
  bodyMinHeight?: TableV2Props<T>['bodyMinHeight']
  enableBodyHeightComputation?: TableV2Props<T>['enableBodyHeightComputation']
  showScrollArea: boolean
  toggleAllRowsSelected: (value?: boolean | undefined) => void
  onRowsSort?: (selectedRows: Row<T>[], droppedOnRow: Row<T>, above: boolean, parentRowId?: string) => void
  virtualScroller?: TableV2Props<T>['virtualScroller']
}

const rootClass = 'tableV2-tbody'

export const TBody = <T extends {}>({
  data,
  rows,
  canDrop,
  tagProps,
  isLoading,
  isExpanded,
  rowActions,
  isDraggable,
  selectedRows,
  enableSubRow,
  rowEmptyText,
  columnsLength,
  stickyColumns,
  bodyMaxHeight,
  enablePaginate,
  showInsideLoader,
  enableSpacerBlock,
  hasRowDisabledTitle,
  hasDisabledRowStyles,
  enableBodyHeightComputation,
  onRowsSort,
  customClass,
  rowDisabled,
  hasToExpand,
  onRowClicked,
  onRowExpanded,
  showScrollArea,
  toggleAllRowsSelected,
  virtualScroller,
}: TBodyProps<T>) => {
  const stickyColsWidths = useStickyColWidths(stickyColumns)
  const rowRef = useRef<HTMLTableRowElement | null>(null)
  const tbodyRef = useRef<HTMLTableSectionElement>(null)

  const [hoveredRowID, setHoveredRowID] = useState<string | undefined>()
  const [rowHeight, setRowHeight] = useState(BODY_MIN_HEIGHT)
  const scrollAreaUniqueKey = scrollAreaKey()

  const checkHoverState = useCallback(() => hoverStateHandler(rootClass, hoveredRowID), [hoveredRowID])

  useEffect(() => {
    if (enableSubRow && isExpanded) {
      checkHoverState()
    }
  }, [enableSubRow, hoveredRowID])

  useEffect(() => {
    if (tbodyRef.current) {
      tbodyRef.current.scrollTop = 0
    }
  }, [isLoading])

  useEffect(() => {
    if (rowRef.current) {
      if (bodyMaxHeight) {
        setRowHeight(bodyMaxHeight)
      } else setRowHeight(rowRef.current?.clientHeight * 10)
    }
  }, [bodyMaxHeight, rowRef, rows.length])

  if (showInsideLoader) {
    return (
      <tbody className={rootClass}>
        <SingleRow
          trClassName={`${rootClass}__loader-row`}
          tdClassName={`${rootClass}__loader`}
          columnsLength={columnsLength}
          trStyles={{
            ...(enablePaginate && {
              minHeight: rowHeight,
            }),
          }}
        >
          <Loader />
        </SingleRow>
      </tbody>
    )
  }

  const tbody = (
    <tbody
      className={rootClass}
      ref={tbodyRef}
      style={{
        ...(enablePaginate &&
          enableBodyHeightComputation && {
            maxHeight: rowHeight,
          }),
        ...(virtualScroller?.enabled ? { height: `${virtualScroller.containerHeight}px` } : {}),
      }}
    >
      {!data.length && !isLoading && (
        <SingleRow rootClass={rootClass} columnsLength={columnsLength}>
          <Typography text={rowEmptyText} type={TextType.BODY_TEXT_GRAY} weight={TextWeight.ITALIC} inline />
        </SingleRow>
      )}

      {isDraggable && !!canDrop && !!onRowsSort ? (
        <>
          {rows.map((row) => (
            <Fragment key={row.id}>
              <DraggableRow
                row={row}
                rows={rows}
                rowRef={rowRef}
                tagProps={tagProps}
                className={rootClass}
                rowActions={rowActions}
                isExpanded={isExpanded}
                isDraggable={isDraggable}
                enableSubRow={enableSubRow}
                hoveredRowID={hoveredRowID}
                selectedRows={selectedRows}
                stickyColumns={stickyColumns}
                stickyColsWidths={stickyColsWidths}
                enableSpacerBlock={enableSpacerBlock}
                hasRowDisabledTitle={hasRowDisabledTitle}
                hasDisabledRowStyles={hasDisabledRowStyles}
                onRowsSort={onRowsSort}
                hasToExpand={hasToExpand}
                customClass={customClass}
                rowDisabled={rowDisabled}
                onRowClicked={onRowClicked}
                onRowExpanded={onRowExpanded}
                setHoveredRowID={setHoveredRowID}
                toggleAllRowsSelected={toggleAllRowsSelected}
                canDropCallback={isFunction(canDrop) ? canDrop : () => canDrop}
              />
            </Fragment>
          ))}
        </>
      ) : virtualScroller?.enabled ? (
        <TableV2VirtualScroller
          rows={rows}
          virtualScroller={virtualScroller}
          rowProps={{
            rowRef,
            tagProps,
            rootClass,
            isExpanded,
            rowActions,
            enableSubRow,
            hoveredRowID,
            stickyColumns,
            stickyColsWidths,
            enableSpacerBlock,
            hasRowDisabledTitle,
            hasDisabledRowStyles,
            rowDisabled,
            hasToExpand,
            customClass,
            onRowClicked,
            onRowExpanded,
            setHoveredRowID,
          }}
        />
      ) : (
        rows.map((row) => (
          <Fragment key={row.id}>
            <TableV2Row
              row={row}
              rows={rows}
              rowRef={rowRef}
              tagProps={tagProps}
              rootClass={rootClass}
              isExpanded={isExpanded}
              rowActions={rowActions}
              enableSubRow={enableSubRow}
              hoveredRowID={hoveredRowID}
              stickyColumns={stickyColumns}
              stickyColsWidths={stickyColsWidths}
              enableSpacerBlock={enableSpacerBlock}
              hasRowDisabledTitle={hasRowDisabledTitle}
              hasDisabledRowStyles={hasDisabledRowStyles}
              rowDisabled={rowDisabled}
              hasToExpand={hasToExpand}
              customClass={customClass}
              onRowClicked={onRowClicked}
              onRowExpanded={onRowExpanded}
              caretCellID={getCaretCellID(row)}
              setHoveredRowID={setHoveredRowID}
            />
          </Fragment>
        ))
      )}
    </tbody>
  )

  return (enablePaginate && enableBodyHeightComputation) || showScrollArea ? (
    <ScrollArea key={scrollAreaUniqueKey} showOnEvent="always" hasHorizontalScroll className={`${rootClass}__scroll`}>
      {tbody}
    </ScrollArea>
  ) : (
    tbody
  )
}
