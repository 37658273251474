import React, { ChangeEvent, useCallback, useState } from 'react'
import { Control, Controller, FieldError } from 'react-hook-form'

import classNames from 'classnames'
import { FieldPath, FieldPathValue, FieldValues } from 'react-hook-form/dist/types'

import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2Props, SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { HMACAuthenticationField } from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/components/IncomingWebhookAuthentication/components/IncomingWebhookHMACAuthentication/IncomingWebhookHMACAuthentication.constants'
import { renderInfoTooltip } from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/utils/CreateIncomingWebhookModal.utils'

import './IncomingWebhookSelect.css'

export type SelectField = Omit<HMACAuthenticationField, HMACAuthenticationField.ALGORITHM | HMACAuthenticationField.ENCODING>

interface IncomingWebhookSelectProps<T extends FieldValues> {
  className?: string
  dataTest?: string
  control: Control<T>
  currentOption?: SelectV2SingleOption
  error?: FieldError
  field: SelectField
  name: FieldPath<T>
  options?: SelectV2SingleOption[]
  placeholder: string
  rootTranslation: string
}

const rootClass = 'incoming-webhook-select'
const IncomingWebhookSelect = <T extends FieldValues>({
  className = '',
  dataTest = rootClass,
  control,
  currentOption,
  error,
  field,
  name,
  options,
  placeholder,
  rootTranslation,
}: IncomingWebhookSelectProps<T>) => {
  const { t } = useTranslation()
  const [selectedOption, setSelectedOption] = useState<SelectV2SingleOption | undefined>(currentOption)
  const onChange = useCallback((selectedOption?: SelectV2SingleOption) => setSelectedOption(() => selectedOption), [])

  const selectProps: SelectV2Props = {
    className: `${rootClass}__select`,
    defaultValue: selectedOption,
    error: !!error,
    errorMessage: error?.message,
    insideModal: true,
    isClearable: false,
    onChange,
    options,
    placeholder,
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__label`}>
        <span>
          <Typography
            text={t(`${rootTranslation}.${field}.Label`)}
            tagProps={{ bold: { weight: TextWeight.MEDIUM, type: TextType.BODY_TEXT } }}
            type={TextType.BODY_TEXT_LIGHT}
            inline
          />
          {renderInfoTooltip(t(`${rootTranslation}.${field}.Tooltip`))}
        </span>
      </div>
      <Controller
        control={control}
        name={name}
        render={({ field: { onBlur, onChange } }) => (
          <SelectV2
            {...selectProps}
            onBlur={onBlur}
            onChange={(option) => {
              onChange({ target: { value: (option as SelectV2SingleOption).value } } as ChangeEvent | FieldPathValue<T, FieldPath<T>>)
              onBlur()
            }}
          />
        )}
      />
    </div>
  )
}

export default IncomingWebhookSelect
