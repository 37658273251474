import { Folder } from '@interface/foldersLists/Folder'
import { List } from '@interface/foldersLists/List'
import { FilterTypes } from '@utils/filter'

export enum ListCategory {
  ACCOUNTS = 'ACCOUNTS',
  MARKETING = 'MARKETING',
  FORM_SUBMISSIONS = 'SIGNUP',
  FORM_SUBMISSIONS_UCL = 'FORM_SUBMISSIONS',
  WEBINAR = 'WEBINAR',
  WEBINAR_UCL = 'WEBINAR_REGISTRATION',
  UNIFIED_LIST = 'UNIFIED_LIST',
  EXTENSION = 'EXTENSION',
}

export const LIST_CATEGORY_FILTERS: { [key in ListCategory]: string } = {
  [ListCategory.ACCOUNTS]: FilterTypes.ALL_ACCOUNT_LISTS,
  [ListCategory.MARKETING]: FilterTypes.ALL_MARKETING_LISTS,
  [ListCategory.FORM_SUBMISSIONS]: FilterTypes.ALL_FORM_SUBMISSIONS,
  [ListCategory.FORM_SUBMISSIONS_UCL]: FilterTypes.ALL_FORM_SUBMISSIONS,
  [ListCategory.WEBINAR]: FilterTypes.ALL_WEBINAR_LISTS,
  [ListCategory.WEBINAR_UCL]: FilterTypes.ALL_WEBINAR_LISTS,
  [ListCategory.UNIFIED_LIST]: FilterTypes.ALL_SEGMENTS,
  [ListCategory.EXTENSION]: FilterTypes.EXTENSION,
}

export const GET_LIST_CATEGORIES = () => [
  {
    type: ListCategory.ACCOUNTS,
    text: 'Account Lists',
  },
  {
    type: ListCategory.UNIFIED_LIST,
    text: 'Act-On Contacts',
  },
  {
    type: ListCategory.EXTENSION,
    text: 'Extension Lists',
  },

  {
    type: ListCategory.FORM_SUBMISSIONS,
    text: 'Form Submissions',
  },
  {
    type: ListCategory.FORM_SUBMISSIONS_UCL,
    text: 'Form Submissions',
  },
  {
    type: ListCategory.MARKETING,
    text: 'Marketing Lists',
  },
  {
    type: ListCategory.WEBINAR,
    text: 'Webinar Lists',
  },
  {
    type: ListCategory.WEBINAR_UCL,
    text: 'Webinar Lists',
  },
]

export function getListCategoryName(category: string, t: Function) {
  switch (category) {
    case ListCategory.ACCOUNTS:
      return t('ACCOUNT LISTS')
    case ListCategory.MARKETING:
      return t('MARKETING LISTS')
    case ListCategory.FORM_SUBMISSIONS:
    case ListCategory.FORM_SUBMISSIONS_UCL:
      return t('FORM SUBMISSIONS')
    case ListCategory.WEBINAR:
      return t('WEBINAR LISTS')
    case ListCategory.UNIFIED_LIST:
      return t('ACT-ON CONTACTS')
  }
  return ''
}

export function checkIfAllListsAreSelected(lists: List[], selectedLists: string[]): boolean {
  return lists.reduce((acc: boolean, cur: List) => {
    if (!acc) {
      return false
    }
    if (!selectedLists.includes(cur.id)) {
      return false
    }
    return cur.children.length > 0 ? checkIfAllListsAreSelected(cur.children, selectedLists) : true
  }, true)
}

export function checkIfAllListsInFoldersAreSelected(folders: Folder[], selectedLists: string[]): boolean {
  return folders.reduce((acc: boolean, cur: Folder) => {
    if (!acc) {
      return false
    }
    return cur.entries && cur.entries.length > 0 ? checkIfAllListsAreSelected(cur.entries, selectedLists) : true
  }, true)
}

export function getListIds(lists: List[]): string[] {
  return lists.reduce((acc: string[], cur: List) => {
    const childrenLists = getListIds(cur.children)
    return [...acc, cur.id, ...childrenLists]
  }, [])
}

export function getFolderListIds(folders: Folder[]): string[] {
  return folders.reduce((acc: string[], cur: Folder) => {
    const childrenLists = getListIds(cur.entries ?? [])
    return [...acc, ...childrenLists]
  }, [])
}

export function handleCollapse(id: string, collapse: boolean, items: string[]) {
  if (collapse) {
    return [...items, id]
  } else {
    return items.filter((item) => item !== id)
  }
}

export default {
  getListCategoryName,
  checkIfAllListsAreSelected,
  checkIfAllListsInFoldersAreSelected,
  getListIds,
  getFolderListIds,
  handleCollapse,
}
