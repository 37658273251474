import React, { FC, ReactNode, RefObject, useCallback, useEffect, useReducer, useRef, useState } from 'react'
import { useDrop } from 'react-dnd'

import classNames from 'classnames'

import { COMMON_DROPPABLE_TYPES } from '@complex/ListingPage/Utils/ListingPage.constants'
import Button, { ButtonType } from '@components/Button/Button'
import CollapsibleMenuItemHoverMenu from '@components/CollapsibleMenu/components/CollapsibleMenuItemHoverMenu/CollapsibleMenuItemHoverMenu'
import CollapsibleMenuItemWithHeader from '@components/CollapsibleMenu/components/CollapsibleMenuItemWithHeader/CollapsibleMenuItemWithHeader'
import CollapsibleMenuItemWithHeaderAndAction from '@components/CollapsibleMenu/components/CollapsibleMenuItemWithHeaderAndAction/CollapsibleMenuItemWithHeaderAndAction'
import MenuItem, { MenuItemProps } from '@components/Menu/components/MenuItem/MenuItem'
import ScrollArea from '@components/ScrollArea/ScrollArea'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import useWindowSize from '@utils/hooks/useWindowSize'
import { isFunction } from '@utils/utils'

import './CollapsibleMenu.css'

interface Props {
  className?: string
  dataTest?: string
  header: string
  indexActive: number
  items: Partial<CollapsibleMenuItemData>[] | ((isCollapsed: boolean) => Partial<CollapsibleMenuItemData>[])
  onCollapse?: (collapsed: boolean) => void
  isCollapsed?: boolean
  reference?: RefObject<HTMLDivElement>
  outerScrollAreaRef?: RefObject<HTMLDivElement>
  topMarginMin?: number
}

export interface CollapsibleMenuItemData extends MenuItemProps {
  icon: SvgNames
}

export interface CollapsibleMenuItemWithHeaderData extends CollapsibleMenuItemData {
  header: string
  showHeaderWhenCollapsed?: boolean
  isOpen?: boolean
}

export interface CollapsibleMenuItemWithHeaderAndActionsData extends CollapsibleMenuItemWithHeaderData {
  action: ReactNode
}

interface CollapsedState {
  collapsed: boolean
  byUser: boolean
}

interface CollapsingAction {
  type: CollapsingActionType
  collapse: boolean
}

enum CollapsingActionType {
  AUTO,
  BY_USER,
}

const setCollapsedReducer = ({ collapsed, byUser }: CollapsedState, { type, collapse }: CollapsingAction) => {
  const actions: { [key in CollapsingActionType]: (collapse: boolean) => CollapsedState } = {
    // If the user has manually collapsed the menu, then the menu won't automatically expand again.
    [CollapsingActionType.AUTO]: (collapse) =>
      collapsed && byUser
        ? { collapsed, byUser }
        : {
            collapsed: collapse,
            byUser: false,
          },
    [CollapsingActionType.BY_USER]: (collapse) => ({ collapsed: collapse, byUser: true }),
  }
  return actions[type](collapse)
}

const rootClass = 'collapsible-menu'

const DEFAULT_COLLAPSING_BREAKPOINT = 1200
const BOTTOM_MARGIN_MAX = 24
const TOP_MARGIN_MIN = 72
const SCROLL_BUFFER = 2

const CollapsibleMenu: FC<Props> = (props: Props) => {
  const {
    header,
    indexActive,
    items,
    onCollapse,
    isCollapsed: collapsedProp = false,
    dataTest = rootClass,
    className = '',
    reference,
    outerScrollAreaRef,
    topMarginMin = TOP_MARGIN_MIN,
  } = props
  const [{ collapsed }, setCollapsed] = useReducer(setCollapsedReducer, { collapsed: collapsedProp, byUser: true })
  const [hasOverflow, setHasOverflow] = useState(false)
  const { t } = useTranslation()

  const scrollAreaRef = useRef<HTMLDivElement>(null)
  const collapserRef = useRef<HTMLDivElement>(null)

  const getOuterScrollArea = useCallback(() => outerScrollAreaRef?.current ?? document.getElementById('page-container'), [outerScrollAreaRef])

  const resizeMenu = useCallback(() => {
    const menu = reference?.current
    const scrollArea = scrollAreaRef?.current
    const outerScrollArea = getOuterScrollArea()
    const collapser = collapserRef?.current

    if (menu && scrollArea && outerScrollArea && collapser) {
      const { top } = menu.getBoundingClientRect()
      const topMargin = Math.max(top, topMarginMin)
      const fullHeight = window.innerHeight - topMargin
      const contentHeight = scrollArea.children[0].getBoundingClientRect().height
      const menuHeight = contentHeight + collapser.offsetHeight + SCROLL_BUFFER

      const exceedsWindowHeight = fullHeight < menuHeight + BOTTOM_MARGIN_MAX
      const distanceFromBottom = outerScrollArea.scrollHeight - outerScrollArea.scrollTop - outerScrollArea.offsetHeight
      const bottomMargin = exceedsWindowHeight && distanceFromBottom ? Math.max(BOTTOM_MARGIN_MAX - distanceFromBottom, 0) : 0

      if (!exceedsWindowHeight) {
        menu.style.maxHeight = `${menuHeight - bottomMargin}px`
      } else {
        menu.style.maxHeight = `${fullHeight - BOTTOM_MARGIN_MAX}px`
      }
      setHasOverflow(exceedsWindowHeight && bottomMargin === 0)
    }
  }, [reference, getOuterScrollArea, topMarginMin])

  const menuObserver = useRef(
    new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => Array.isArray(entries) && entries.length && resizeMenu())
    })
  )
  const { width, height } = useWindowSize()

  const [{ isOver }, drop] = useDrop({
    accept: collapsed ? COMMON_DROPPABLE_TYPES : [],
    collect: (monitor) => ({ isOver: monitor.isOver() }),
  })

  if (reference) {
    drop(reference)
  }

  useEffect(() => {
    const outerScrollArea = getOuterScrollArea()
    scrollAreaRef.current?.addEventListener('scroll', resizeMenu)
    outerScrollArea?.addEventListener('scroll', resizeMenu)

    return () => {
      scrollAreaRef.current?.removeEventListener('scroll', resizeMenu)
      outerScrollArea?.removeEventListener('scroll', resizeMenu)
    }
  }, [getOuterScrollArea, resizeMenu])

  useEffect(() => {
    const scrollArea = scrollAreaRef?.current
    if (scrollArea) {
      menuObserver.current.observe(scrollArea)
      menuObserver.current.observe(scrollArea.children[0])
    }

    return () => {
      if (scrollArea) {
        menuObserver.current.unobserve(scrollArea)
        menuObserver.current.unobserve(scrollArea.children[0])
      }
    }
  }, [menuObserver, collapsed])

  useEffect(
    () =>
      setCollapsed({
        type: CollapsingActionType.AUTO,
        collapse: width !== undefined && width < DEFAULT_COLLAPSING_BREAKPOINT,
      }),
    [width]
  )

  useEffect(() => {
    resizeMenu()
  }, [height, resizeMenu])

  useEffect(() => {
    if (collapsed && isOver) {
      setTimeout(() => setCollapsed({ type: CollapsingActionType.BY_USER, collapse: false }), 500)
    }
  }, [isOver])

  useEffect(() => setCollapsed({ type: CollapsingActionType.BY_USER, collapse: collapsedProp }), [collapsedProp])

  const getItems = () => (isFunction(items) ? items(collapsed) : items)

  const getCollapsedItem = (props: any) => {
    if (props.hasOwnProperty('header')) {
      if (props.hasOwnProperty('action')) {
        return <CollapsibleMenuItemWithHeaderAndAction {...props} collapsedMenu />
      }
      return <CollapsibleMenuItemWithHeader {...props} collapsedMenu />
    }
    return <MenuItem {...props} />
  }

  const renderItem = (props: any) => {
    if (props.hasOwnProperty('header') && !props.hasOwnProperty('showHeaderWhenCollapsed')) {
      if (props.hasOwnProperty('action')) {
        return <CollapsibleMenuItemWithHeaderAndAction {...props} />
      }
      return <CollapsibleMenuItemWithHeader {...props} />
    }
    return <MenuItem {...props} />
  }

  const renderTrigger = (icon: SvgNames, index: number) => (
    <div key={index} className={`${rootClass}__division`}>
      <div className={`${rootClass}__item-icon`}>
        <Svg name={icon} type={SvgType.ICON} />
      </div>
    </div>
  )

  const renderAllItems = () => {
    return getItems().map(({ icon, dataTest, ...rest }, index) =>
      collapsed && icon ? (
        <CollapsibleMenuItemHoverMenu
          itemActive={index === indexActive}
          key={index}
          content={getCollapsedItem({ ...rest, hasOverflow: true })}
          trigger={renderTrigger(icon, index)}
          dataTest={dataTest}
        />
      ) : (
        <div key={index} className={`${rootClass}__division`}>
          {renderItem({ ...rest, scrollAreaRef })}
        </div>
      )
    )
  }

  return (
    <div
      className={classNames(rootClass, className, {
        [`${rootClass}__collapsed`]: collapsed,
        [`${rootClass}--has-overflow`]: hasOverflow,
      })}
      data-test={dataTest}
      ref={reference}
    >
      <div className={classNames(`${rootClass}__collapser`, { [`${rootClass}__collapser-collapsed`]: collapsed })} ref={collapserRef}>
        {!collapsed && (
          <Typography
            dataTest={`${dataTest}__collapser-text`}
            text={t(header)}
            lineHeight={LineHeight.MEDIUM_LARGE}
            type={TextType.BODY_TEXT_LARGE}
            className={`${rootClass}__title ellip`}
            weight={TextWeight.MEDIUM}
          />
        )}
        <Tooltip
          position={'top'}
          trigger={
            <Button
              buttonType={ButtonType.TEXT}
              onClick={() => {
                setCollapsed({ type: CollapsingActionType.BY_USER, collapse: !collapsed })
                onCollapse && onCollapse(!collapsed)
              }}
            >
              <Svg className={classNames({ [`${rootClass}__collapsed-icon`]: collapsed })} name={SvgNames.collapseMenu} type={SvgType.MEDIUM} />
            </Button>
          }
          triggerClassName={classNames(`${rootClass}__collapser-icon`, { [`${rootClass}__collapser-icon-collapsed`]: collapsed })}
        >
          {t(collapsed ? 'Expand' : 'Collapse')}
        </Tooltip>
      </div>
      {collapsed ? (
        renderAllItems()
      ) : (
        <ScrollArea className={`${rootClass}__items`} register={scrollAreaRef}>
          {renderAllItems()}
        </ScrollArea>
      )}
    </div>
  )
}

export default CollapsibleMenu
