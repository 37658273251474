import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Container from '@components/Container/Container'
import Loader, { LoaderTypes } from '@components/Loader/Loader'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Toggle from '@components/Toggle/Toggle'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import {
  AccountSharePermissions,
  AccountShareUpdateProps,
} from '@src/pages/listingPages/AccountManagement/AccountManagementListingPageContainer.types'

import './AccountManagementPermissionsModal.css'

interface AccountManagementPermissionsModalProps {
  isOpen: boolean
  accounts: AccountSharePermissions[]
  className?: string
  dataTest?: string
  onClose: () => void
  onAction: (permissions: AccountShareUpdateProps) => void
}

interface AccountManagementPermissionsModalState {
  canShare: boolean
  canSubscribe: boolean
}

const defaultState = {
  canShare: false,
  canSubscribe: false,
}

const rootClass = 'account-management-permissions-modal'

const AccountManagementPermissionsModal: FC<AccountManagementPermissionsModalProps> = (props: AccountManagementPermissionsModalProps) => {
  const { dataTest = rootClass, className = '', onAction, onClose, isOpen, accounts } = props
  const [permissions, setPermissions] = useState<AccountManagementPermissionsModalState>(defaultState)
  const [saving, setSaving] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    if (accounts.length === 1) {
      setPermissions({ canShare: accounts[0].canShare, canSubscribe: accounts[0].canSubscribe })
    } else {
      // Per design, when any accounts have a permission enable, show the toggle as enabled
      const canShare = accounts.some((account) => account.canShare)
      const canSubscribe = accounts.some((account) => account.canSubscribe)
      setPermissions({ canShare, canSubscribe })
    }

    return () => setPermissions(defaultState)
  }, [])

  const handleAction = () => {
    setSaving(true)
    onAction(permissions)
  }

  const handleClose = () => {
    onClose()
  }

  const onTogglePermission = (permission: 'share' | 'subscribe') => {
    const prevPermissions = permissions
    if (permission === 'share') {
      setPermissions({ canShare: !prevPermissions.canShare, canSubscribe: prevPermissions.canSubscribe })
    } else {
      setPermissions({ canShare: prevPermissions.canShare, canSubscribe: !prevPermissions.canSubscribe })
    }
  }

  const header = (
    <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__header`}>
      {t(accounts.length === 1 ? 'Share permissions' : 'Bulk update share permissions')}
    </ModalHeader>
  )

  const subHeader = accounts.length === 1 ? accounts[0].name : t('AccountManagement.SharePermissions.SubHeader', { count: accounts.length })

  const renderPermissions = () => (
    <>
      <Container className={`${rootClass}__permission`}>
        <Typography text={t('Can Share')} weight={TextWeight.BOLD} />
        <div className={`${rootClass}__permission-description`}>
          <Typography
            text={t('AccountManagement.SharePermissions.CanShare')}
            type={TextType.BODY_TEXT_LIGHT}
            className={`${rootClass}__permission-text`}
          />
          <Toggle isOn={permissions.canShare} large onToggle={() => onTogglePermission('share')} dataTest={`${dataTest}-can-share`} />
        </div>
      </Container>
      <Container className={`${rootClass}__permission`}>
        <Typography text={t('Can Subscribe')} weight={TextWeight.BOLD} />
        <div className={`${rootClass}__permission-description`}>
          <Typography
            text={t('AccountManagement.SharePermissions.CanSubscribe')}
            type={TextType.BODY_TEXT_LIGHT}
            className={`${rootClass}__permission-text`}
          />
          <Toggle isOn={permissions.canSubscribe} large onToggle={() => onTogglePermission('subscribe')} dataTest={`${dataTest}-can-subscribe`} />
        </div>
      </Container>
    </>
  )

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
      <ModalBody className={`${rootClass}__body`}>
        <Typography text={subHeader} type={TextType.SUB_HEADER} className={`${rootClass}__subheader`} />
        {renderPermissions()}
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form} className={`${rootClass}__footer`}>
        <Button buttonType={ButtonType.TERTIARY} onClick={handleClose} dataTest={`${dataTest}-button-tertiary`}>
          {t('Cancel')}
        </Button>
        <Button
          buttonType={ButtonType.PRIMARY}
          onClick={handleAction}
          className={classNames(`${rootClass}__submit-button`, {
            [`${rootClass}__submit-button-submitting`]: saving,
          })}
          dataTest={`${dataTest}-button-primary`}
        >
          {!saving && t('Save')}
          {saving && <Loader loaderType={LoaderTypes.row} className={`${rootClass}__submit-loader`} />}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default AccountManagementPermissionsModal
