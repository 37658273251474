import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { Row } from 'react-table'

import { useApolloClient, useQuery } from '@apollo/client'
import { AltViewOption } from '@components/ReportHeader/ReportHeader'
import { TableColumn } from '@components/Table/Table'
import { DirectionOptions } from '@components/TimespanPicker/TimespanPicker'
import { ChartField, ChartProps, highChartColors } from '@const/Chart.constants'
import { useTranslation } from '@const/globals'
import crmDefinition from '@graphql/microservices/crm/crmDefinition'
import getFiscalCalendar from '@graphql/queries/getFiscalCalendar'
import getRoiReport from '@graphql/queries/getRoiReport'
import { CrmDefinitionQuery, CrmDefinitionQueryVariables } from '@graphql/types/microservice/crm-types'
import { FiscalCalendarResponse, QueryGetFiscalCalendarArgs, QueryGetRoiReportArgs, RoiReportResponse } from '@graphql/types/query-types'
import RevenueImpact from '@src/pages/reports/revenueImpact/RevenueImpact'
import {
  getChartHeight,
  getDateRange,
  getTableData,
  handleAltDateChange,
  handleIncrementChange,
} from '@src/pages/reports/revenueImpact/RevenueImpact.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import { dateYesterdayMidnight, getDate, getStandardFormattedDate } from '@utils/date'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'

import {
  AccumulationFields,
  CampaignGroups,
  LeadFields,
  defaultState,
  opportunitiesNetChangeFields,
  opportunityTrendsFields,
  outcomeFields,
  revenueBookedFields,
  defaultReplacementText,
} from './RevenueImpactContainer.constants'

interface Props {
  dataTest?: string
}

export interface RevenueImpactContainerState {
  opportunityTrends: ChartProps
  opportunitiesNetChange: ChartProps
  revenueBooked: ChartProps
  outcomes: ChartProps
  tableData: Row[]
  leadsConverted: { fields: ChartField[] }
  leadsCreated: { fields: ChartField[] }
  campaignGroups: any
  updatedDate: string
  startTime: string
  startTimePresentation: string
  endTime: string
  curQuarterStart: string
  curQuarterEnd: string
  nextDisabled: boolean
  prevDisabled: boolean
  dateRange: string[]
  altViewOptions: AltViewOption[]
  quarterSelected: boolean
  fiscalCalendarIsUpdated: boolean
  columns: TableColumn[]
  className?: string
}

export interface ReplacementTextState {
  leadsConvertedText: string
  trendChartTitle: string
  netChangeColChartTitle: string
}

const RevenueImpactContainer: FC<Props> = ({ dataTest }: Props) => {
  const [state, setState] = useState<RevenueImpactContainerState>(defaultState)
  const [replacementTextState, setReplacementTextState] = useState<ReplacementTextState>(defaultReplacementText)
  const { accountTimeZoneId, getConnectedCrm } = useAccountSettings()
  const { t } = useTranslation()
  const apolloClient = useApolloClient()

  const {
    loading: fiscalCalendarLoading,
    error: fiscalCalendarError,
    data: fiscalCalendarData,
  } = useQuery<FiscalCalendarResponse, QueryGetFiscalCalendarArgs>(getFiscalCalendar, {
    client: apolloClient as any,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      date: new Date().toISOString().substring(0, 10),
    },
  })

  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.CRM })

  const { loading: crmLoading, data: crmDefinitions } = useQueryOnMount<CrmDefinitionQuery, CrmDefinitionQueryVariables>(crmDefinition, {
    client,
    fetchPolicy: 'network-only',
    variables: {
      connectedCrmName: getConnectedCrm,
    },
  })

  useEffect(() => {
    if (crmDefinitions && !crmLoading) {
      const opportunityTextMap = { ...crmDefinitions?.crmDefinition?.entityTypeDisplayInfoMap }
      setReplacementTextState({
        ...replacementTextState,
        leadsConvertedText: 'New ' + (opportunityTextMap?.Deal.displayName ?? 'Opportunities') + ' Created',
        trendChartTitle: (opportunityTextMap.Deal.singularDisplayName ?? 'Opportunity') + ' Trends',
        netChangeColChartTitle: 'Net Change in # of ' + (opportunityTextMap.Deal.displayName ?? 'Opportunities'),
      })

      if (!opportunityTextMap.Campaign) {
        const replacementColumns: TableColumn[] = state.columns.map((entry) => {
          if (entry.Header === t('Campaigns')) {
            entry.Header = ''
          } else if (entry.Header === t('Opportunities')) {
            entry.Header = opportunityTextMap?.Deal.displayName ?? 'Opportunities'
          }
          return entry
        })

        setState({ ...state, columns: replacementColumns })
      }
    }
  }, [crmLoading, crmDefinitions])

  useEffect(() => {
    if (crmDefinitions && !crmLoading && state.outcomes && state.outcomes.fields.length > 0) {
      const opportunityTextMap = { ...crmDefinitions?.crmDefinition?.entityTypeDisplayInfoMap }
      state.outcomes.fields[0].name = opportunityTextMap.Deal.displayName

      setState({
        ...state,
        outcomes: state.outcomes,
      })
    }
  }, [state.outcomes, crmLoading, crmDefinition])

  useEffect(() => {
    if (!state.fiscalCalendarIsUpdated) {
      if (!fiscalCalendarLoading && !fiscalCalendarError) {
        const data: any = fiscalCalendarData

        setState({
          ...state,
          startTime: data.getFiscalCalendar.fiscalQuarter.startTime,
          endTime: data.getFiscalCalendar.fiscalQuarter.endTime,
          curQuarterStart: data.getFiscalCalendar.fiscalQuarter.startTime,
          curQuarterEnd: data.getFiscalCalendar.fiscalQuarter.endTime,
          dateRange: getDateRange(getDate(data.getFiscalCalendar.fiscalQuarter.startTime), true),
          fiscalCalendarIsUpdated: true,
        })
      }
    }
  }, [fiscalCalendarData, fiscalCalendarLoading, fiscalCalendarError])

  const {
    loading,
    error: pageError,
    data,
  } = useQuery<RoiReportResponse, QueryGetRoiReportArgs>(getRoiReport, {
    client: apolloClient as any,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      startTime: state.startTime,
      endTime: state.endTime,
    },
    skip: !state.startTime && !state.endTime,
  })

  useEffect(() => {
    if (!loading && !pageError && data) {
      updateData(data)
    }
  }, [data, loading, pageError])

  const getLeadsData = (leads: LeadFields[]) => {
    const sortedLeads = [...leads].sort((a: LeadFields, b: LeadFields) => b.count - a.count)
    return {
      fields: [
        {
          name: t('Total'),
          data: sortedLeads.map((entry: LeadFields, index: number) => ({
            name: entry.leadSource,
            y: entry.count,
            color: highChartColors[index],
          })),
        },
      ],
    }
  }

  const getOpportunityTrends = (accumulation: AccumulationFields, fieldInfo: ChartField[]) => {
    return {
      categories: state.dateRange,
      fields: fieldInfo.map((field: ChartField, index: number) => ({
        ...field,
        name: t(field.name),
        data: index === 0 ? [...accumulation.created] : [...accumulation.won],
      })),
    }
  }

  const getOpportunitiesNetChange = (accumulation: AccumulationFields, fieldInfo: ChartField[]) => {
    return {
      categories: state.dateRange,
      fields: fieldInfo.map((field: ChartField) => {
        const createdOpportunities = accumulation.created.map((num: number) => num).reverse()

        return {
          ...field,
          name: t(field.name),
          data: createdOpportunities.map((entry: number, index: number) => entry - accumulation.closed[index]),
        }
      }),
    }
  }

  const getRevenueBooked = (accumulation: AccumulationFields, fieldInfo: ChartField[]) => {
    return {
      categories: state.dateRange,
      fields: fieldInfo.map((field: ChartField) => ({
        ...field,
        name: t(field.name),
        data: [...accumulation.wonAmt],
      })),
    }
  }

  const getOutcomeData = (index: number, sortedCampaignGroups: CampaignGroups[], opportunitiesAccessor: string, wonAccessor: string) => {
    return index === 0
      ? sortedCampaignGroups.map((campaignGroup: CampaignGroups) => campaignGroup[opportunitiesAccessor])
      : sortedCampaignGroups.map((campaignGroup: CampaignGroups) => campaignGroup[wonAccessor])
  }

  const getOutcomes = (countSelected: boolean, campaignGroups: CampaignGroups[], outcomesFields: any) => {
    const sortedCampaignGroups = countSelected
      ? [...campaignGroups].sort((a: CampaignGroups, b: CampaignGroups) => b.opportunities - a.opportunities)
      : [...campaignGroups].sort((a: CampaignGroups, b: CampaignGroups) => b.opportunitiesAmount - a.opportunitiesAmount)

    return {
      categories: sortedCampaignGroups.map((campaign: CampaignGroups) => (campaign.type ? campaign.type : '')),
      fields: countSelected
        ? outcomesFields.map((field: ChartField, index: number) => ({
            ...field,
            name: t(field.name),
            data: getOutcomeData(index, sortedCampaignGroups, 'opportunities', 'wonOpportunities'),
          }))
        : outcomesFields.map((field: ChartField, index: number) => ({
            ...field,
            name: t(field.name),
            data: getOutcomeData(index, sortedCampaignGroups, 'opportunitiesAmount', 'wonOpportunitiesAmount'),
          })),
    }
  }

  const onDateChange = (direction: DirectionOptions) => {
    handleAltDateChange(state, setState, direction)
  }

  const onAltDateDropDownChange = (event: ChangeEvent<HTMLSelectElement>) => {
    handleIncrementChange(state, setState, event.target.value)
  }

  const handleRadioChange = (countSelected: boolean) => {
    const outcomes = getOutcomes(countSelected, state.campaignGroups, state.outcomes.fields)
    setState({ ...state, outcomes })
  }

  const updateData = (data: any) => {
    const { leadsCreatedByLeadSource, leadsConvertedByLeadSource, campaignGroups, accumulation, fiscalInfo } = data.getRoiReport

    const opportunityTextMap = { ...crmDefinitions?.crmDefinition?.entityTypeDisplayInfoMap }
    const leadsCreated = getLeadsData(leadsCreatedByLeadSource)
    const leadsConverted = getLeadsData(leadsConvertedByLeadSource)
    const tableData = getTableData(campaignGroups, opportunityTextMap?.Campaign ? null : t('All Deals'))
    const outcomes = getOutcomes(true, campaignGroups, outcomeFields)
    const opportunityTrends = getOpportunityTrends(accumulation, opportunityTrendsFields)
    const opportunitiesNetChange = getOpportunitiesNetChange(accumulation, opportunitiesNetChangeFields)
    const revenueBooked = getRevenueBooked(accumulation, revenueBookedFields)

    const startTimePresentation = state.quarterSelected
      ? `${fiscalInfo.fiscalQuarter} '${fiscalInfo.fiscalYear.substring(2, 4)}`
      : `${new Date(new Date(fiscalInfo.periodStartDate).getUTCFullYear(), new Date(fiscalInfo.periodStartDate).getUTCMonth(), 1)
          .toString()
          .substring(4, 7)} '${fiscalInfo.fiscalYear.substring(2, 4)}`

    setState({
      ...state,
      leadsCreated,
      leadsConverted,
      startTimePresentation,
      outcomes,
      campaignGroups,
      tableData,
      opportunityTrends,
      opportunitiesNetChange,
      revenueBooked,
    })
  }

  return (
    <RevenueImpact
      chartHeight={getChartHeight(state.leadsConverted.fields, state.leadsCreated.fields)}
      startTime={state.startTimePresentation}
      prevDisabled={state.prevDisabled}
      nextDisabled={state.nextDisabled}
      updatedDate={
        state.updatedDate && state.updatedDate !== '0'
          ? getStandardFormattedDate(new Date(parseInt(state.updatedDate)).valueOf(), true, accountTimeZoneId)
          : getStandardFormattedDate(dateYesterdayMidnight.valueOf(), true, accountTimeZoneId)
      }
      onDateChange={onDateChange}
      altView
      altViewOptions={state.altViewOptions}
      altViewDropDownChange={onAltDateDropDownChange}
      loading={loading || fiscalCalendarLoading}
      pageError={pageError || fiscalCalendarError}
      leadsCreated={state.leadsCreated}
      leadsConverted={state.leadsConverted}
      outcomes={state.outcomes}
      tableData={state.tableData}
      opportunityTrends={state.opportunityTrends}
      opportunitiesNetChange={state.opportunitiesNetChange}
      revenueBooked={state.revenueBooked}
      handleRadioChange={handleRadioChange}
      dataTest={dataTest}
      leadsConvertedText={t(replacementTextState.leadsConvertedText)}
      trendChartTitle={t(replacementTextState.trendChartTitle)}
      netChangeColChartTitle={t(replacementTextState.netChangeColChartTitle)}
      columns={state.columns}
    />
  )
}

export default RevenueImpactContainer
