import React, { FC } from 'react'

import classNames from 'classnames'

import { Checkbox } from '@components/Checkbox/Checkbox'
import DownloadButton from '@components/DownloadButton/DownloadButton'
import MultiSelect, { SelectOption } from '@components/MultiSelect/MultiSelect'
import TableWithLoaderAndEmptyListing from '@components/TableWithLoaderAndEmptyListing/TableWithLoaderAndEmptyListing'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import { useTranslation } from '@const/globals'
import withError from '@hoc/withError/withError'

import { fragColumns, SharedTabProps } from '../../utils/LinkPerformance.utils'

import './FragmentsTab.css'

type Props = SharedTabProps & fragmentTabProps
export interface fragmentTabProps {
  fragOptions: SelectOption[]
  fragOnsubmit(selected: string[]): void
  groupBy: boolean
  onCheck: (checked: boolean) => void
}

const rootClass = 'fragments-tab'

const FragmentsTab: FC<Props> = (props: Props) => {
  const {
    dataTest = rootClass,
    className = '',
    loading,
    fragOptions,
    tagOptions,
    tagOnsubmit,
    fragOnsubmit,
    onCheck,
    onSort,
    onDownload,
    fetchData,
    groupBy,
    totalCount,
    totalClicks,
    uniqueClicks,
    pageSize,
    pageIndex,
    sortBy,
    data,
    resetPageIndex,
  } = props

  const { t } = useTranslation()

  const pageCount = Math.ceil(totalCount / pageSize)
  const hasTagsSelected = tagOptions.find((option) => option.selected)
  const hasFragsSelected = fragOptions.find((option) => option.selected)

  const clearFilters = () => {
    if (hasTagsSelected) {
      tagOnsubmit([])
    }
    if (hasFragsSelected) {
      fragOnsubmit([])
    }
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__inputs`}>
        <div className={`${rootClass}__left-options`}>
          <MultiSelect title={'Fragments'} options={fragOptions} onSubmit={fragOnsubmit} className={`${rootClass}__multi-select`} />
          <MultiSelect title={'Tags'} options={tagOptions} onSubmit={tagOnsubmit} className={`${rootClass}__multi-select`} />
          {(hasTagsSelected || hasFragsSelected) && (
            <TextLink
              text={t('Clear filters')}
              onClick={clearFilters}
              className={`${rootClass}__clear-text`}
              dataTest={`${dataTest}__clear`}
              size={TextLinkSize.LARGE}
              hideIcon
            />
          )}
        </div>
        <div className={`${rootClass}__right-options`}>
          <Checkbox label={'Group by Fragment'} onChange={onCheck} checked={groupBy} />
          <DownloadButton downloadFn={onDownload} label={'Export Results'} hasToolTip={false} />
        </div>
      </div>

      <TableWithLoaderAndEmptyListing
        className={`${rootClass}__table-wrapper`}
        data={data}
        columns={fragColumns(totalClicks, uniqueClicks)}
        hasFooter
        loading={loading}
        text={'No Fragments'}
        canPaginate
        onSort={onSort}
        initialState={{ pageSize, pageIndex: pageIndex, sortBy: sortBy }}
        fetchData={fetchData}
        controlledPageCount={pageCount}
        manualSortBy
        disableSortRemove
        disableMultiSort
        resetPageIndex={resetPageIndex}
        autoHeight
      />
    </div>
  )
}

export default withError(FragmentsTab)
