import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import './Box.css'

interface BoxProps {
  children: ReactNode
  className?: string
  isCurrent?: boolean
  isSourceValue?: boolean
}

const rootClass = 'box'

export const Box: FC<BoxProps> = ({ isCurrent = false, isSourceValue = false, children, className = rootClass }: BoxProps) => {
  return (
    <div
      className={classNames(rootClass, className, { [`${rootClass}__current`]: isSourceValue && isCurrent, [`${rootClass}__source`]: isSourceValue })}
    >
      {children}
    </div>
  )
}
