import React from 'react'

import { t } from 'i18next'

import { IPluginColumn, IPluginModule, IPluginRow } from '@beefree.io/sdk/dist/types/bee'
import { ValidityReturnType } from '@components/InputWithStatus/InputWithStatus'
import { Status } from '@components/StatusToast/StatusToast'
import { SvgNames } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { getUUID, legacyActonContext } from '@const/globals'
import { Folder } from '@interface/Folder'
import { detectEmailType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { IEntityContentJsonExtended, IPluginRowExtended, IPluginModuleWithInternalModule } from '@utils/composer/beeEditor/beeEditorTypes'
import { EmailComposerAPI } from '@utils/composer/context/EmailComposer.context'
import { logNewRelicError } from '@utils/new-relic.utils'

import { LAYOUT_DATA_ATTRIBUTE, SIGNATURE_DATA_ATTRIBUTE } from './BeeEditor.constants'
import {
  AddOnModal,
  BeeEditorActionDoneCallbacks,
  BeeSessionTypes,
  ClassicMessageJson,
  EmailModalArgs,
  ExtractSessionUserFields,
  HandleGetSavedRows,
  HandleSaveRow,
  RenderAddOnModal,
  StartBeeSession,
} from './BeeEditor.types'
import { EmailTemplateLayoutType } from '../EmailModals/components/EmailLayoutsModal/utils/EmailLayoutsModal.utils'

const getClassicMessageJsonUrl = (id: string) =>
  `${legacyActonContext}/_compose/messageGetJSON.jsp?id=${id}&isM=0&isResend=false&isZoomWebinar=false&isWebexWebinar=false&_=${new Date().valueOf()}`

const getClassicMessagePreviewUrl = (id: string) => `${legacyActonContext}/messages/previewPane.jsp?msgid=${id}&format=html`

const parseClassicBlocks = (html: string, json: ClassicMessageJson): IEntityContentJsonExtended | undefined => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const original = doc.getElementById('htmlversion')
  if (!original) return

  const blocks = original.querySelectorAll('[class^=ao_]')
  const sectionCount = json.content.columns.reduce((prev, cur) => (prev < cur.section ? cur.section : prev), 0) + 1
  const rowContents: IPluginRow[] = Array.from({ length: sectionCount }, () => getNewRow())

  const columnDefs: { [key: string]: { column: IPluginColumn; section: number } } = {}
  blocks.forEach((block) => {
    const blockId = block.getAttribute('id') ?? ''
    const column = json.content.columns.find((col) => col.blocks.find((colBlock) => colBlock.id === blockId))
    if (column) {
      if (!(column.id in columnDefs)) {
        columnDefs[column.id] = { column: getNewColumn(column.id), section: column.section }
      }
      columnDefs[column.id].column.modules.push(getParagraphModule(block))
    }
  })

  Object.keys(columnDefs).forEach((columnId) => {
    rowContents[columnDefs[columnId].section].columns.push(columnDefs[columnId].column)
  })
  return getNewTemplate(rowContents)
}

const getNewRow = (uuid = getUUID()): IPluginRow => ({
  columns: [],
  container: {
    style: {
      'background-color': '#EDEDED',
      'background-image': 'none',
      'background-position': 'top left',
      'background-repeat': 'no-repeat',
    },
  },
  content: {
    computedStyle: {
      hideContentOnDesktop: false,
      hideContentOnMobile: false,
      rowColStackOnMobile: true,
      rowReverseColStackOnMobile: false,
      // @ts-ignore -- Present in Bee save data but not in typedefs
      verticalAlign: 'top',
    },
    style: {
      'background-color': 'transparent',
      'background-image': 'none',
      'background-position': 'top left',
      'background-repeat': 'no-repeat',
      color: '#000000',
      width: '605px',
    },
  },
  empty: false,
  locked: false,
  synced: false,
  type: 'one-column-empty',
  uuid,
})

const getNewTemplate = (rows: IPluginRow[]): IEntityContentJsonExtended => ({
  page: {
    body: {
      container: { style: { 'background-color': '#FFFFFF' } },
      content: {
        // @ts-ignore -- Present in Bee save data but not in typedefs
        computedStyle: {
          linkColor: '#0000FF',
          messageBackgroundColor: 'transparent',
          messageWidth: '605px',
        },
        style: {
          color: '#000000',
          'font-family': 'Arial, Helvetica Neue, Helvetica, sans-serif',
        },
      },
      type: 'mailup-bee-page-properties',
      webFonts: [],
    },
    description: '',
    rows,
    template: {
      name: 'template-base',
      type: 'basic',
      version: '2.0.0',
    },
    title: '',
  },
  comments: {},
})

const getNewColumn = (colId = getUUID()): IPluginColumn => ({
  'grid-columns': 12,
  modules: [],
  style: {
    'background-color': 'transparent',
    'border-bottom': '0px solid transparent',
    'border-left': '0px solid transparent',
    'border-right': '0px solid transparent',
    'border-top': '0px solid transparent',
    'padding-bottom': '10px',
    'padding-left': '10px',
    'padding-right': '10px',
    'padding-top': '10px',
  },
  uuid: colId,
})

const getParagraphModule = (block: Element): IPluginModule => ({
  type: 'mailup-bee-newsletter-modules-paragraph',
  descriptor: {
    paragraph: {
      html: block.innerHTML,
      style: {
        color: '#000000',
        'font-size': '14px',
        'font-family': 'inherit',
        'font-weight': '400',
        'line-height': '120%',
        'text-align': 'left',
        direction: 'ltr',
        'letter-spacing': '0px',
      },
      computedStyle: {
        linkColor: '#0068a5',
        paragraphSpacing: '16px',
      },
    },
    style: {
      'padding-top': '10px',
      'padding-right': '10px',
      'padding-bottom': '10px',
      'padding-left': '10px',
    },
    // @ts-ignore -- Present in Bee save data but not in typedefs
    mobileStyle: {},
    computedStyle: {
      hideContentOnAmp: false,
      hideContentOnHtml: false,
      hideContentOnDesktop: false,
      hideContentOnMobile: false,
    },
  },
  uuid: block.id,
})

export const transformFolders = (folders: Folder[]) => {
  return folders.map((folder) => {
    return { label: folder.name, value: `${folder.id}` }
  })
}

export const fetchClassicMessageTemplate = (messageId: string) => {
  // Proof-of-concept code to be replaced
  Promise.all([fetch(getClassicMessagePreviewUrl(messageId)), fetch(getClassicMessageJsonUrl(messageId))]).then(([html, json]) => {
    Promise.all([html.text(), json.json()]).then(([html, json]) => {
      return parseClassicBlocks(html, json)
    })
  })
}

const URLRegExpWithParamsAndImageTypes = new RegExp(/^(https?:\/\/)?([a-zA-Z0-9-_~:/?#\[\]@!$&'()*+,;=%]+\/?)+\.(jpg|jpeg|png|gif|webp)(\?.*)?$/i)
const checkURLValidity = (url: string): undefined | ValidityReturnType => {
  if (!URLRegExpWithParamsAndImageTypes.test(url)) {
    return { errorMessageKey: 'invalidUrl' }
  }
}

export const getSelectedImageUrl = (url: string | object): string | undefined => {
  if (typeof url === 'string') {
    const pattern = /url\('([^']+)'\)/

    const match = url.match(pattern)
    if (match) {
      const selectedUrl = match[1]
      return checkURLValidity(selectedUrl) ? selectedUrl : undefined
    } else {
      return checkURLValidity(url) ? url : undefined
    }
  }
  return undefined
}

const getCustomAddOnDataIdAttribute = (args: EmailModalArgs, dataAttribute: string): string | undefined => {
  if (args.value?.type === 'html') {
    return getDataIdAttributeFromHTML(args.value.html, dataAttribute)
  }

  return undefined
}

export const getDataIdAttributeFromHTML = (html: string, dataAttribute: string): string | undefined => {
  let dataId: string | undefined
  if (html) {
    try {
      const parser = new DOMParser()
      const doc = parser.parseFromString(html, 'text/html')
      const element = doc.querySelector(`[${dataAttribute}]`)
      dataId = element?.getAttribute(`${dataAttribute}`) || undefined
    } catch (e) {
      dataId = undefined
    }
  }

  return dataId
}

export const prepareCustomAddOnHTML = (html?: string, dataAttribute?: string, dataValue?: string | number) => {
  const htmlNode = document.createElement('div')
  const data = dataAttribute ? `${dataAttribute}="${dataValue ?? ''}"` : ''
  htmlNode.innerHTML = `<div ${data} style="text-align: initial; word-break: break-word; font-family: 'Open Sans', Helvetica, Arial, sans-serif; font-size: 9px;">${
    html ?? ''
  }</div>`
  htmlNode.querySelectorAll('img').forEach((node) => {
    node.style.maxWidth = '100%'
    node.style.height = 'auto'
  })
  htmlNode.querySelectorAll('p').forEach((node) => {
    node.style.margin = 'revert'
  })

  return htmlNode.innerHTML
}

const checkEmailLayoutAlreadyExist = (templateJson: any, contentDialogId: AddOnModal): boolean => {
  if (!Array.isArray(templateJson?.page?.rows)) {
    return false
  }

  return templateJson?.page?.rows.some((row: any) => {
    const { rowInternal, columns } = row

    return (
      rowInternal?.uid === contentDialogId &&
      Array.isArray(columns) &&
      columns.some((column: any) => Array.isArray(column?.modules) && !!column.modules.length)
    )
  })
}

const checkAddOnIDHaveContent = (templateJson: IEntityContentJsonExtended, contentDialogId: string, key: string, id?: string): string | undefined => {
  if (!id) {
    return undefined
  }

  const row = templateJson?.page?.rows?.find((row: IPluginRowExtended) => {
    const { metadata, rowInternal } = row
    return rowInternal?.uid === contentDialogId && metadata?.[key] === id
  })

  return checkRowContentIsEmpty(row) ? undefined : id
}

const checkIsThereWebinarBLockInHeaderOrFooter = (templateJson: IEntityContentJsonExtended) => {
  const hasWebinarBLock = templateJson?.page?.rows?.some((row: IPluginRowExtended) => {
    if ([AddOnModal.HEADER_BLOCK, AddOnModal.FOOTER_BLOCK].includes(row.rowInternal?.uid as AddOnModal)) {
      return row.columns.some(
        (col) =>
          col.modules.length == 1 &&
          col.modules.some((module: IPluginModuleWithInternalModule) => {
            return module.descriptor?.html?.html?.includes('webinar-block')
          })
      )
    }
  })

  return hasWebinarBLock
}

export const checkRowContentIsEmpty = (row?: IPluginRowExtended): boolean => {
  if (!row) {
    return true
  }

  const columns = Array.isArray(row.columns) ? row.columns : undefined
  if (columns) {
    return columns.some((column) => Array.isArray(column?.modules) && !column.modules.length)
  }

  return true
}

export const beeActionHelper = <T,>(resolve: (data: T, options?: Record<string, unknown>) => void, reject: () => void, cb: () => void, data?: T) => {
  if (data) {
    resolve(data)
  } else {
    reject()
  }
  cb()
}

export const startBeeSession = ({ sessionId, beeEditorRef, beeFinalConfig, templateJsonRef }: StartBeeSession) => {
  if (sessionId) {
    beeEditorRef?.current?.join(beeFinalConfig, sessionId)
    return Promise.resolve(BeeSessionTypes.JOIN)
  } else {
    beeEditorRef?.current?.start(beeFinalConfig, templateJsonRef.current!, undefined, { shared: true })
    return Promise.resolve(BeeSessionTypes.START)
  }
}

export const extractSessionUserFields = (
  value: ExtractSessionUserFields | ExtractSessionUserFields[],
  field: keyof ExtractSessionUserFields
): string[] => {
  return Array.isArray(value) ? value.map((v) => v[field]) : [value[field]]
}

export const removeOneElFromList = (array: string[], value: string) => {
  const index = array.indexOf(value)
  if (index !== -1) {
    array.splice(index, 1)
  }
  return array
}

export const handleEditRow = async ({
  args,
  newValue,
  update,
  closeModals,
  setRowData,
  updateSavedRowRequest,
  updateModalState,
}: HandleGetSavedRows) => {
  let prevName = ''

  setRowData((prevRows) => {
    prevName = (prevRows.find((row) => row?.metadata?.name === args?.metadata?.name)?.metadata?.name as string) ?? ''
    return prevRows.map((row) => (row?.metadata?.name === args?.metadata?.name ? { ...row, metadata: { ...row.metadata, name: newValue } } : row))
  })
  const { data, errors } = await updateSavedRowRequest(args.metadata?.id as number, newValue)

  if (data?.updateEmailComposerRow) {
    const msg = (
      <Typography
        text={t('EmailComposer.SaveRow.Update.Status.Toast', { prevName, name: newValue })}
        tagProps={{ medium: { weight: TextWeight.MEDIUM, inline: true } }}
      />
    )
    updateModalState({
      statusToast: {
        status: Status.SUCCESS,
        message: msg,
        closeStatus: closeModals,
      },
      savedRowsEdit: false,
    })
  }

  if (errors) {
    logNewRelicError(errors)
    if (errors[0].message.includes('duplicate name')) {
      update({ savedRowDuplicateError: true })
    } else {
      updateModalState({
        statusToast: {
          status: Status.FAIL,
          message: errors[0].message,
          closeStatus: closeModals,
        },
        savedRowsEdit: false,
      })
    }
  }
}

export const handleSaveRow = async ({
  args,
  category,
  name,
  newRowData,
  update,
  setRowData,
  closeModals,
  updateModalState,
  logNewRelicError,
  saveSavedRowRequest,
}: HandleSaveRow) => {
  setRowData((prevRows) => [...prevRows, args])

  const { data, errors } = await saveSavedRowRequest({
    categoryId: category.id,
    data: newRowData,
    name,
    isDefaultCategory: category.isDefault,
  })

  if (data?.saveEmailComposerRow) {
    const message = (
      <Typography text={t('EmailComposer.SaveRow.Save.Status.Toast', { name })} tagProps={{ medium: { weight: TextWeight.MEDIUM, inline: true } }} />
    )
    updateModalState({
      statusToast: {
        status: Status.SUCCESS,
        message,
        closeStatus: closeModals,
      },
      saveRowModal: false,
    })
  }

  if (errors) {
    logNewRelicError(errors)
    if (errors[0].message.includes('duplicate name')) {
      update({ savedRowDuplicateError: true })
    } else {
      updateModalState({
        statusToast: {
          status: Status.FAIL,
          message: errors[0].message,
          closeStatus: closeModals,
        },
        saveRowModal: false,
      })
    }
  }
}

const renderPreventMultipleEmailLayoutsModal = (
  layoutsType: EmailTemplateLayoutType,
  actionDoneCallbacks: React.MutableRefObject<BeeEditorActionDoneCallbacks>,
  updateModal: EmailComposerAPI['updateModal']
) =>
  updateModal('confirmation', {
    title: t('Prevent.Multiple.Layouts.Modal.Title', { layoutsType }),
    body: (
      <Typography
        text="Prevent.Multiple.Layouts.Modal.Body"
        type={TextType.BODY_TEXT_LIGHT}
        tagProps={{ medium: { weight: TextWeight.MEDIUM, inline: true } }}
        values={{ layoutsType }}
        className="push-down-x2"
      />
    ),
    okButtonText: 'Got it',
    titleIcon: SvgNames.warning,
    titleIconFillColor: SvgColor.TEXT_GRAY,
    closeModal: () => {
      actionDoneCallbacks.current.onPreventMultipleLayoutsConfirm()
    },
  })

export const renderAddOnModal = ({
  args,
  messageType,
  templateJsonRef,
  actionDoneCallbacks,
  updateModal,
  updateEditModalState,
  setCustomRowMetaData,
  updateModalState,
}: RenderAddOnModal) => {
  const { isEmailWebinar } = detectEmailType(messageType)
  const hasWebinarInHeaderOrFooter = isEmailWebinar ? checkIsThereWebinarBLockInHeaderOrFooter(templateJsonRef.current!) : false

  switch (args.contentDialogId) {
    case AddOnModal.EVENT_BLOCK:
      updateModalState({ eventModal: true })
      setCustomRowMetaData(() => args?.value.customFields)
      return
    case AddOnModal.GENERATIVE_EMAIL:
      updateModalState({ generativeEmail: true })
      return
    case AddOnModal.HEADER_BLOCK: {
      const headerId = args.value?.[LAYOUT_DATA_ATTRIBUTE]
      if (!headerId && checkEmailLayoutAlreadyExist(templateJsonRef.current, AddOnModal.HEADER_BLOCK)) {
        renderPreventMultipleEmailLayoutsModal(EmailTemplateLayoutType.HEADER, actionDoneCallbacks, updateModal)
      } else {
        const selectedHeaderId =
          !hasWebinarInHeaderOrFooter && checkAddOnIDHaveContent(templateJsonRef.current!, AddOnModal.HEADER_BLOCK, LAYOUT_DATA_ATTRIBUTE, headerId)
            ? headerId
            : undefined
        updateEditModalState({ selectedLayoutId: selectedHeaderId })
        updateModalState({ headersModal: true })
      }
      return
    }
    case AddOnModal.FOOTER_BLOCK: {
      const footerId = args.value?.[LAYOUT_DATA_ATTRIBUTE]
      if (!footerId && checkEmailLayoutAlreadyExist(templateJsonRef.current, AddOnModal.FOOTER_BLOCK)) {
        renderPreventMultipleEmailLayoutsModal(EmailTemplateLayoutType.FOOTER, actionDoneCallbacks, updateModal)
      } else {
        const selectedFooterId =
          !hasWebinarInHeaderOrFooter && checkAddOnIDHaveContent(templateJsonRef.current!, AddOnModal.FOOTER_BLOCK, LAYOUT_DATA_ATTRIBUTE, footerId)
            ? footerId
            : undefined
        updateEditModalState({ selectedLayoutId: selectedFooterId })
        updateModalState({ footersModal: true })
      }
      return
    }
    case AddOnModal.SIGNATURE_BLOCK:
      updateEditModalState({ selectedSignatureId: getCustomAddOnDataIdAttribute(args, SIGNATURE_DATA_ATTRIBUTE) })
      updateModalState({ signaturesModal: true })
      return
    case AddOnModal.RSS_BLOCK:
      updateModalState({ rssModal: { isActive: true, isPlainText: false } })
      setCustomRowMetaData(() => args?.value.customFields)
      return
    case AddOnModal.WEBINAR_BLOCK:
      updateModalState({ webinarModal: true })
      setCustomRowMetaData(() => args?.value.customFields)
      return
    case AddOnModal.FORM_BLOCK:
      updateModalState({ formBlockModal: true })
      setCustomRowMetaData(() => args?.value.customFields)
      return
    case AddOnModal.MAP_BLOCK:
      updateModalState({ mapBlockModal: true })
      setCustomRowMetaData(() => args?.value.customFields)
      return
    case AddOnModal.POLL_BLOCK:
      const blockId =
        templateJsonRef.current?.page.rows
          .flatMap((row) => row.columns)
          .flatMap((column) => column.modules)
          .find((module) => (module.descriptor as unknown as { html: { html: string } })?.html?.html === args.value.html)?.uuid || ''
      updateModalState({ pollModal: true })
      setCustomRowMetaData(() => ({ ...args?.value.customFields, blockId: blockId }))
  }
}
