import React, { FC, forwardRef, MutableRefObject, useEffect, useRef, useState } from 'react'
import FlipMove from 'react-flip-move'
import { Prompt } from 'react-router-dom'

import classNames from 'classnames'

import { useApolloClient, useQuery } from '@apollo/client'
import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import Container from '@components/Container'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import DirtyBanner from '@components/DirtyBanner/DirtyBanner'
import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import InfoAction, { InfoActionType } from '@components/InfoAction/InfoAction'
import InputToSelectMappingRow from '@components/InputToSelectMappingRow/InputToSelectMappingRow'
import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import PageContainer from '@components/PageContainer'
import PageError from '@components/PageError'
import PageHeader from '@components/PageHeader'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import StatusToast from '@components/StatusToast/StatusToast'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation, getUUID } from '@const/globals'
import saveAccountSchema from '@graphql/mutations/saveAccountSchema'
import getCrmSchema from '@graphql/queries/getCrmSchema'
import loadAccountSchema from '@graphql/queries/loadAccountSchema'
import { FieldNameResponse } from '@graphql/types/query-types'
import OptimizeCRMModal from '@src/pages/Contacts/StandardFieldNames/components/OptimizeCrmModal/OptimizeCRMModal'
import { getBannerInfo, MORE_INFO } from '@src/pages/Contacts/StandardFieldNames/utils/StandardFieldNames.constants'
import { getRowSelectOptions } from '@src/pages/Contacts/StandardFieldNames/utils/StandardFieldNames.helpers'
import { useAccountSettings } from '@utils/account/account.utils'
import useCRM from '@utils/hooks/useCRM'
import { StatusToastType } from '@utils/interface/StatusToast'
import { logNewRelicError } from '@utils/new-relic.utils'
import { FieldNamesDataType, StandardFieldNamesState } from '@utils/standardFieldNames.utils'

import './standardFieldNames.css'

const DUPLICATE_ERROR_MESSAGE = 'This field name is already in use'
const REQUIRED_ERROR_MESSAGE = 'Field name is required'

const defaultState: StandardFieldNamesState = {
  tableLoading: true,
  showDirtyBanner: false,
  showOptimizeCRMModal: false,
  showConfirmation: false,
  showDiscardConfirmation: false,
  newRowIndex: undefined,
  dataColumns: [],
  selectedDataColumns: [],
  tableData: [],
  hasError: false,
}
const rootClass = 'standard-field-names'

type FieldNamesDataTypeRow = FieldNamesDataType & { idx: number; renderer: (field: FieldNamesDataType, idx: number) => JSX.Element }
const InputToSelectMappingRowWithRef = forwardRef((props: FieldNamesDataTypeRow, ref) => (
  <div ref={ref as MutableRefObject<HTMLDivElement>}>{props.renderer(props, props.idx)}</div>
))

const StandardFieldNames: FC = () => {
  const [state, setState] = useState<StandardFieldNamesState>(defaultState)
  const [toast, setToast] = useState<StatusToastType | undefined>(undefined)

  const { t } = useTranslation()
  const { isSF } = useAccountSettings()
  const { hasCRMConnected, connectorType } = useCRM()
  const client = useApolloClient()

  const { loading, data, error, refetch } = useQuery(loadAccountSchema, {
    client,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const getSaveableSchema = () => {
    return state.tableData.map((tData) => ({
      name: tData.name,
      mapping: tData.mapping,
    }))
  }

  const saveFieldNames = () => {
    return client.mutate({
      mutation: saveAccountSchema,
      fetchPolicy: 'no-cache',
      variables: {
        standardFieldNames: {
          columns: getSaveableSchema(),
        },
      },
    })
  }

  const {
    loading: modalLoading,
    data: modalData,
    error: modalError,
  } = useQuery(getCrmSchema, {
    client,
    fetchPolicy: 'network-only',
    variables: { crmType: connectorType },
  })

  useEffect(() => {
    if (error) {
      logNewRelicError(error)
      setState((prevState) => ({ ...prevState, tableLoading: false, hasError: true }))
    } else {
      if (!loading && data) {
        const tableLoading = !modalLoading ? { tableLoading: false } : {}
        const tableDataRaw = data.loadAccountSchema.columns.map((column: FieldNameResponse) => {
          const id = getUUID()
          return {
            ...column,
            id,
            isValid: true,
            selected: column.mapping != '',
          }
        })
        const tableData = tableDataRaw.map((row: FieldNamesDataType) => ({
          ...row,
          selectOptions: getRowSelectOptions(row, tableDataRaw),
        }))
        setState((prevState) => ({ ...prevState, ...tableLoading, tableData }))
      }
    }
  }, [data, loading])

  useEffect(() => {
    if (modalError) {
      logNewRelicError(modalError)
      setState((prevState) => ({ ...prevState, tableLoading: false, hasError: true }))
    } else {
      if (!modalLoading && modalData) {
        const tableLoading = !loading ? { tableLoading: false } : {}
        const columns = modalData.getCrmSchema.columns.map((c: FieldNameResponse, id: number) => ({ ...c, id: id.toString(), selected: false }))
        setState((prevState) => ({ ...prevState, ...tableLoading, dataColumns: columns }))
      }
    }
  }, [modalData, modalLoading, modalError])

  const updateDataColumns = () => {
    const dataColumns = state.dataColumns.map((column) => {
      const tableRow = state.tableData.find((row) => row.mapping === column.mapping)
      return tableRow ? { ...column, selected: true } : column
    })
    const selectedDataColumns = [...dataColumns].filter((column) => column.selected)
    setState(() => ({ ...state, dataColumns, selectedDataColumns }))
  }

  useEffect(() => {
    if (!state.tableLoading) {
      updateDataColumns()
    }
  }, [state.tableLoading])

  const inputIsValid = (value: string, rowId: string) => {
    return !state.tableData.find((row) => row.id !== rowId && row.name === value) && value.length > 0
  }

  const onInputChange = (rowId: string, value: string) => {
    const tableData = [...state.tableData]
    const currentRow = tableData.find((row) => row.id === rowId)
    if (currentRow) {
      const currentRowIdx = tableData.indexOf(currentRow)
      const isValid = inputIsValid(value, rowId)
      if (currentRowIdx > -1) {
        tableData.splice(currentRowIdx, 1, { ...currentRow, name: value ?? '', isValid })
        setState((prevState) => ({ ...prevState, tableData, showDirtyBanner: true }))
      }
    }
  }

  const onSelectChange = (rowId: string, newValue: string) => {
    const tableData = [...state.tableData]
    const currentRow = tableData.find((row) => row.id === rowId)
    if (currentRow) {
      if (currentRow.mapping === '' && newValue === '') {
        return
      }
      const currentRowIdx = tableData.indexOf(currentRow)
      if (currentRowIdx > -1) {
        const selectOptions = getRowSelectOptions(currentRow, state.tableData)
        tableData.splice(currentRowIdx, 1, { ...currentRow, mapping: newValue, selected: !currentRow.selected, selectOptions })
        const tableDataWithUpdatedOptions = tableData.map((row: FieldNamesDataType) => ({
          ...row,
          selectOptions: getRowSelectOptions(row, tableData),
        }))
        setState((prevState) => ({ ...prevState, tableData: tableDataWithUpdatedOptions, showDirtyBanner: true }))
      }
    }
  }

  const moveRowUp = (rowId: string) => {
    const tableData = [...state.tableData]
    const currentRow = tableData.find((row) => row.id === rowId)
    if (currentRow) {
      const currentRowIdx = tableData.indexOf(currentRow)
      if (currentRowIdx > -1) {
        tableData[currentRowIdx] = tableData[currentRowIdx - 1]
        tableData[currentRowIdx - 1] = currentRow
        setState((prevState) => ({ ...prevState, tableData, showDirtyBanner: true }))
      }
    }
  }

  const moveRowDown = (rowId: string) => {
    const tableData = [...state.tableData]
    const currentRow = tableData.find((row) => row.id === rowId)
    if (currentRow) {
      const currentRowIdx = tableData.indexOf(currentRow)
      if (currentRowIdx > -1) {
        tableData[currentRowIdx] = tableData[currentRowIdx + 1]
        tableData[currentRowIdx + 1] = currentRow
        setState((prevState) => ({ ...prevState, tableData, showDirtyBanner: true }))
      }
    }
  }

  const deleteRow = (rowId: string) => {
    if (flipContainer.current) {
      flipContainer.current.style.height = `${flipContainer.current.clientHeight}px`
    }
    const tableDataRaw = state.tableData.filter((row) => row.id !== rowId)
    const tableData = tableDataRaw.map((row) => ({ ...row, selectOptions: getRowSelectOptions(row, tableDataRaw) }))
    setState((prevState) => ({ ...prevState, tableData, showDirtyBanner: true }))
  }

  const onAddRow = (isTop: boolean) => {
    const tableData = [...state.tableData]
    const newRowIndex = getUUID()
    const newRowRaw = {
      id: newRowIndex,
      name: '',
      mapping: '',
      isValid: true,
    } as FieldNamesDataType
    const selectOptions = getRowSelectOptions(newRowRaw, tableData)
    const newRow = { ...newRowRaw, selectOptions }

    if (isTop) {
      tableData.unshift(newRow)
    } else {
      tableData.push(newRow)
    }
    setState((prevState) => ({ ...prevState, tableData, newRowIndex }))
  }

  const renderDiscardConfirmation = () => (
    <DeleteConfirmationModal
      isOpen
      title={t('Discard unsaved changes?')}
      deleteButtonText={t('Discard changes')}
      body={t(`If you discard now, you'll lose any changes you've made.`)}
      onAnswer={(answer: YesNo) => {
        if (answer === YesNo.YES) {
          setState((prevState) => ({ ...prevState, tableLoading: true, showDirtyBanner: false, showDiscardConfirmation: false }))
          refetch()
        } else {
          setState((prevState) => ({ ...prevState, showDiscardConfirmation: false }))
        }
      }}
    />
  )

  const isPageSaveDisabled = () => {
    const tableData = state.tableData
    const hasSomeInvalidRows = tableData.some((row) => !row.isValid)
    const hasSomeBlankNames = tableData.some((row) => row.name === '')
    return hasSomeInvalidRows || hasSomeBlankNames
  }

  const renderDirtyBanner = () => (
    <>
      {state.showDiscardConfirmation && renderDiscardConfirmation()}
      <Prompt when={state.showDirtyBanner} message="You have unsaved changes, are you sure you want to leave?" />
      <DirtyBanner
        expanded
        saveDisabled={isPageSaveDisabled()}
        onSave={async () => {
          const { errors } = await saveFieldNames()
          const toast = {
            showStatus: true,
            statusMessage: t(`Success! We've saved your changes.`),
            successStatus: true,
          }
          if (errors) {
            logNewRelicError(error)
          }
          setState((prevState) => ({ ...prevState, showDirtyBanner: false }))
          setToast(toast)
        }}
        onDiscard={() => {
          setState((prevState) => ({ ...prevState, showDiscardConfirmation: true }))
        }}
      />
    </>
  )

  const renderSubheader = () => (
    <div className={`${rootClass}__sub-header-container`}>
      <Typography
        className={`${rootClass}__sub-header`}
        text={t(
          `Use standard field names to map contact fields from your marketing lists and forms to Act-On's system usage. Standard fields are used across Act-On to keep your contact data consistent and identify important fields such as name and email.`
        )}
        type={TextType.BODY_TEXT_LIGHT}
        dataTest={`${rootClass}-description`}
      />
      <TextLink
        text={t('More info')}
        link={MORE_INFO}
        dataTest={`${rootClass}-more-info`}
        className={`${rootClass}__more-info`}
        size={TextLinkSize.LARGE}
      />
    </div>
  )

  const renderInfoAction = () => (
    <InfoAction
      svgName={SvgNames.cogRefresh}
      message={t(getBannerInfo(connectorType).text, { connectorType })}
      secondaryButton={{
        label: t(getBannerInfo(connectorType).linkText, { connectorType }),
        onClick: () =>
          isSF
            ? setState((prevState) => ({ ...prevState, showConfirmation: true }))
            : setState({
                ...state,
                showOptimizeCRMModal: true,
              }),
      }}
      className={`${rootClass}__status-section`}
      type={InfoActionType.NORMAL}
    />
  )

  const renderEmptyListing = () => (
    <EmptyListing
      headline={t(`You’ve not added any fields to map yet`)}
      text={t('Add standard fields here and map them to commonly used fields in your list.')}
      imgSrc={StaticImageNames.emptyMappedFields}
      size={EmptyListingSize.MEDIUM}
      buttonText={t('Add Field')}
      buttonOnClick={() => onAddRow(false)}
      buttonPlusIcon
      withoutBorder
    />
  )

  const renderAddButton = (isTop: boolean) => (
    <Button
      iconPosition={ButtonIconPosition.LEFT}
      buttonType={isTop ? ButtonType.PRIMARY : ButtonType.SECONDARY}
      onClick={() => onAddRow(isTop)}
      dataTest={`${rootClass}-add-button`}
      className={classNames({
        [`${rootClass}__add-bottom`]: !isTop,
      })}
    >
      <Svg name={SvgNames.plus} type={SvgType.LARGER_ICON} />
      {t('Add field')}
    </Button>
  )

  const renderPageHeader = () => (
    <PageHeader leftContent primaryText={t('Standard Field Names')} dataTest={rootClass} className={`${rootClass}__page-header`}>
      {renderAddButton(true)}
    </PageHeader>
  )

  const renderListHeader = () => (
    <div className={`${rootClass}__list-header`}>
      <Typography text={t('Field Name')} type={TextType.TABLE_HEADER} className={`${rootClass}__list-header-left`} />
      <Typography text={t('System Usage')} type={TextType.TABLE_HEADER} />
    </div>
  )

  const closeOptimizeCRMModal = (tableLoading = false) => {
    const showDirtyBanner = state.showDirtyBanner ? !tableLoading : false
    setState(() => ({
      ...state,
      showOptimizeCRMModal: !state.showOptimizeCRMModal,
      tableLoading,
      showDirtyBanner,
    }))
  }

  const onRowSelectionChanged = (rowIds: string[]) => {
    const selectedDataColumns = rowIds.length === 0 ? [] : state.dataColumns.filter((column) => rowIds.find((rowId) => rowId === column.id))
    const dataColumns =
      rowIds.length === 0
        ? state.dataColumns
        : state.dataColumns.map((column) => ({ ...column, selected: !!rowIds.find((rowId) => rowId === column.id) }))
    setState((prevState) => ({ ...prevState, selectedDataColumns, dataColumns }))
  }

  const onOptimize = () => {
    const tableDataRaw = state.selectedDataColumns
    const tableData = tableDataRaw.map((row) => ({
      ...row,
      isValid: true,
      selectOptions: getRowSelectOptions(row, tableDataRaw),
    }))
    setState(() => ({ ...state, tableData, showDirtyBanner: true, showOptimizeCRMModal: false }))
  }

  const renderOptimizeModal = () => (
    <OptimizeCRMModal
      isOpen={state.showOptimizeCRMModal}
      optimizeCRM={onOptimize}
      closeModal={closeOptimizeCRMModal}
      crmType={connectorType}
      data={state.dataColumns}
      onRowSelectionChanged={onRowSelectionChanged}
    />
  )

  const renderOptimizeForSalesforceModal = () => (
    <ConfirmationModal
      isOpen={state.showConfirmation && isSF}
      title={t('Are you sure you want to optimize standard fields for Salesforce?')}
      isYesNo
      yesButtonText={t('Preview Changes')}
      body={t(
        'Optimizing for Salesforce will reset your current field mappings. Click Preview changes to review, then save or discard these changes.'
      )}
      onAnswer={(answer) => {
        if (answer === YesNo.YES) {
          const tableData = state.dataColumns.map((row) => ({
            ...row,
            isValid: true,
            selectOptions: getRowSelectOptions(row, state.dataColumns),
          }))
          setState((prevState) => ({ ...prevState, showConfirmation: false, tableData, showDirtyBanner: true }))
        } else {
          setState((prevState) => ({ ...prevState, showConfirmation: false }))
        }
      }}
      className={`${rootClass}__confirmation`}
    />
  )

  const renderRow = (field: FieldNamesDataType, idx: number) => {
    const inputError = field.name === '' ? REQUIRED_ERROR_MESSAGE : !field.isValid ? DUPLICATE_ERROR_MESSAGE : undefined
    return (
      <InputToSelectMappingRow
        className={`${rootClass}__input`}
        rowId={field.id}
        key={field.id}
        inputValue={field.name}
        inputFocus={field.id === state.newRowIndex}
        inputError={inputError}
        selectValue={field.mapping}
        selectOptions={field.selectOptions}
        onChangeInput={onInputChange}
        onChangeSelect={onSelectChange}
        deleteRow={deleteRow}
        moveUp={moveRowUp}
        moveDown={moveRowDown}
        canMoveUp={idx > 0}
        canMoveDown={idx < state.tableData.length - 1}
      />
    )
  }
  const flipContainer = useRef<HTMLDivElement>(null)
  const renderLoader = () => (
    <div className={`${rootClass}__loader-wrapper`}>
      <Loader blackout={false} loaderType={LoaderTypes.page} />
    </div>
  )

  if (state.hasError) {
    return <PageError center />
  }

  return (
    <>
      {renderOptimizeForSalesforceModal()}
      {renderOptimizeModal()}
      {state.showDirtyBanner && renderDirtyBanner()}
      {toast?.showStatus && (
        <StatusToast
          isSuccess={toast.successStatus}
          message={toast.statusMessage}
          closeStatus={() => {
            setToast({ ...toast, showStatus: false })
          }}
        />
      )}
      <PageContainer className={rootClass}>
        <PositionContainer>
          <div className={`${rootClass}__container`}>
            {renderPageHeader()}
            {renderSubheader()}
            {hasCRMConnected && renderInfoAction()}
            <Container noSidePadding className={`${rootClass}__content-container`}>
              {state.tableLoading && renderLoader()}
              {state.tableData?.length === 0 && !state.tableLoading && renderEmptyListing()}
              {state.tableData?.length > 0 && !state.tableLoading && (
                <>
                  {renderListHeader()}
                  <div className={'flipContainer'} ref={flipContainer}>
                    <FlipMove
                      enterAnimation={'fade'}
                      leaveAnimation={'fade'}
                      onFinishAll={() => {
                        if (flipContainer.current) {
                          flipContainer.current.style.height = `auto`
                        }
                      }}
                    >
                      {state.tableData.map((field, idx) => (
                        <InputToSelectMappingRowWithRef key={field.id} {...field} idx={idx} renderer={renderRow} />
                      ))}
                    </FlipMove>
                  </div>
                  {renderAddButton(false)}
                </>
              )}
            </Container>
          </div>
        </PositionContainer>
      </PageContainer>
    </>
  )
}

export default StandardFieldNames
