import React, { FC, useEffect, useState } from 'react'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import updateUserPassword from '@graphql/mutations/updateUserPassword'
import getCustomAccountPwdPolicies from '@graphql/queries/getCustomAccountPwdPolicies'
import loadSecurityPage from '@graphql/queries/loadSecurityPage'
import { MutationUpdateUserPasswordArgs, UpdateUserPassword } from '@graphql/types/mutation-types'
import { PwdPolicyResponse, SecurityPageResponse } from '@graphql/types/query-types'
import { StatusToastType } from '@interface/StatusToast'
import Security from '@src/pages/Profile/components/Security/Security'
import { logNewRelicError } from '@utils/new-relic.utils'

interface SecurityContainerProps {
  setToastStatus: (value: StatusToastType) => void
}

const SecurityContainer: FC<SecurityContainerProps> = (props: SecurityContainerProps) => {
  const { setToastStatus } = props
  const [passwordData, setPasswordData] = useState<SecurityPageResponse>()
  const [policy, setPolicy] = useState<PwdPolicyResponse>()

  const client = useApolloClient()
  const {
    data: securityData,
    loading: securityDataLoading,
    error: securityDataError,
    refetch: refetchSecurityData,
  } = useQuery<{ loadSecurityPage: SecurityPageResponse }>(loadSecurityPage, {
    client,
    fetchPolicy: 'network-only',
  })

  const { data, loading, error, refetch } = useQuery<{ getCustomAccountPwdPolicies: PwdPolicyResponse }>(getCustomAccountPwdPolicies, {
    client,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!securityDataLoading && securityData?.loadSecurityPage) {
      setPasswordData(securityData?.loadSecurityPage)
    }
    if (error) {
      logNewRelicError(error)
      showError()
    }
  }, [securityData, securityDataLoading, securityDataError])

  useEffect(() => {
    if (!loading && data?.getCustomAccountPwdPolicies) {
      setPolicy(data?.getCustomAccountPwdPolicies)
    }
    if (error) {
      logNewRelicError(error)
      showError()
    }
  }, [data, loading, error])

  const showError = () => {
    setToastStatus({
      showStatus: true,
      statusMessage: 'Something went wrong on our end.',
      successStatus: false,
    })
  }

  const refresh = () => {
    return Promise.all([refetchSecurityData(), refetch()])
  }

  const [saveNewPassword] = useMutation<UpdateUserPassword, MutationUpdateUserPasswordArgs>(updateUserPassword, {
    client,
    fetchPolicy: 'no-cache',
  })

  return (
    <Security
      policy={policy}
      setToastStatus={setToastStatus}
      passwordData={passwordData}
      onSave={(data) => saveNewPassword({ variables: data })}
      loading={securityDataLoading || loading}
      onRefresh={refresh}
    />
  )
}

export default SecurityContainer
