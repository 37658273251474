import { useEffect, useState } from 'react'

import { FetchPolicy } from '@apollo/client'
import { DateRangeValueType } from '@components/DateRangePicker/DateRangePicker'
import getRevenueImpactCampaigns from '@graphql/microservices/data-lake-query/getRevenueImpactCampaigns'
import getRevenueImpactLeads from '@graphql/microservices/data-lake-query/getRevenueImpactLeads'
import getRevenueImpactOpportunities from '@graphql/microservices/data-lake-query/getRevenueImpactOpportunities'
import getRevenueImpactWonOpportunities from '@graphql/microservices/data-lake-query/getRevenueImpactWonOpportunities'
import {
  CampaignSectionData,
  GetRevenueImpactCampaignsQuery,
  GetRevenueImpactCampaignsQueryVariables,
  GetRevenueImpactLeadsQuery,
  GetRevenueImpactLeadsQueryVariables,
  GetRevenueImpactOpportunitiesQuery,
  GetRevenueImpactOpportunitiesQueryVariables,
  GetRevenueImpactWonOpportunitiesQuery,
  GetRevenueImpactWonOpportunitiesQueryVariables,
  LeadsSectionData,
  OpportunitiesSectionData,
  WonOpportunities,
} from '@graphql/types/microservice/data-lake-query-types'
import { getDataLakeRequestQueryDates } from '@src/pages/reports/revenueImpactNew/RevenueImpactNew.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { logNewRelicError } from '@utils/new-relic.utils'

interface RevenueImpactContainerState {
  tableData: CampaignSectionData
  headerCardsData: WonOpportunities
  newLeadsData: LeadsSectionData
  opportunitiesData: OpportunitiesSectionData
}

const defaultState: RevenueImpactContainerState = {
  headerCardsData: {},
  newLeadsData: {},
  opportunitiesData: {},
  tableData: {},
}

const useGetRevenueImpactData: (range: DateRangeValueType) => { loading: boolean; state: RevenueImpactContainerState } = (range) => {
  const { accountTimeZoneId: timeZoneId } = useAccountSettings()
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.DATA_LAKE_QUERY }, { resultCaching: true })
  const [state, setState] = useState<RevenueImpactContainerState>(defaultState)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const dates = getDataLakeRequestQueryDates(range)
    if (!dates) {
      return
    }

    setLoading(true)

    const variables = { ...dates, timeZoneId }
    const options = {
      fetchPolicy: 'network-only' as FetchPolicy,
      notifyOnNetworkStatusChange: true,
      variables,
    }
    Promise.all(
      [
        client.query<GetRevenueImpactWonOpportunitiesQuery, GetRevenueImpactWonOpportunitiesQueryVariables>({
          query: getRevenueImpactWonOpportunities,
          ...options,
        }),
        client.query<GetRevenueImpactLeadsQuery, GetRevenueImpactLeadsQueryVariables>({
          query: getRevenueImpactLeads,
          ...options,
        }),
        client.query<GetRevenueImpactOpportunitiesQuery, GetRevenueImpactOpportunitiesQueryVariables>({
          query: getRevenueImpactOpportunities,
          ...options,
        }),
        client.query<GetRevenueImpactCampaignsQuery, GetRevenueImpactCampaignsQueryVariables>({
          query: getRevenueImpactCampaigns,
          ...options,
        }),
      ].map((p) =>
        p.catch((error) => {
          logNewRelicError(error)
          return error
        })
      )
    )
      .then(([wonOppData, leadsData, opportunitiesData, campaignsData]) => {
        setState((cur) => ({
          ...cur,
          opportunitiesData: opportunitiesData.data?.getRevenueImpactOpportunities ?? {},
          headerCardsData: wonOppData.data?.getRevenueImpactWonOpportunities ?? {},
          newLeadsData: leadsData.data?.getRevenueImpactLeads ?? {},
          tableData: campaignsData.data?.getRevenueImpactCampaigns ?? {},
        }))
      })
      .finally(() => {
        setLoading(false)
      })
  }, [range, timeZoneId])

  return { loading, state }
}

export default useGetRevenueImpactData
