import { gql } from '@apollo/client'

export default gql`
  query getFormRecentActivities($listId: String, $days: Int) {
    getFormRecentActivities(listId: $listId, days: $days) {
      amountOfSubmissions
      amountOfSubmissionsByDate
      author
      authorId
      firstSubmission
      listId
      mostRecentSubmission
    }
  }
`
