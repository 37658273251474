import { ipToInt } from '@utils/strings'

export const validateRangedIp = (ip: string) => {
  const parts = ip.split('-')
  if (!isRangedIp(ip)) {
    return true
  }
  if (parts[1].includes('.')) {
    return ipToInt(parts[0]) < ipToInt(parts[1])
  } else {
    return parts[0].split('.')[3] < parts[1]
  }
}

export const isRangedIp = (ip: string) => {
  const parts = ip.split('-')
  return parts.length > 1
}
