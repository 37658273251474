import React, { FC } from 'react'
import { FieldError, UseFormRegister } from 'react-hook-form'

import Caution from '@components/Caution/Caution'
import Container from '@components/Container'
import Select from '@components/Select/Select'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ContactSettingsFields, SOFT_BOUNCES_ALLOWED_OPTIONS } from '@src/pages/Settings/OtherSettings/ContactSettings/ContactSettings.utils'

import { ContactSettings } from '../../ContactSettings'

import './InactiveSoftBounces.css'

interface Props {
  className?: string
  dataTest?: string
  error?: FieldError
  loading?: boolean
  value: number | string | undefined
  disabled: boolean
  register: UseFormRegister<ContactSettings>
}

const rootClass = 'inactive-soft-bounces'

const InactiveSoftBounces: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '', error, value, disabled, register, loading = false } = props

  const { t } = useTranslation()

  return (
    <Container className={`${className}__container`} dataTest={dataTest}>
      <Typography
        dataTest={`${dataTest}__header`}
        className={`${className}__header`}
        text={t('Inactive Soft Bounces')}
        type={TextType.SECTION_HEADER}
        weight={TextWeight.MEDIUM}
      />
      <Typography text={t('This setting allows you to choose how many ')} type={TextType.BODY_TEXT_LIGHT} inline />
      <Typography text={t('Soft Bounces ')} weight={TextWeight.BOLD} type={TextType.BODY_TEXT_LIGHT} inline />
      <Typography text={t('will be tolerated before the contact is moved from the ')} type={TextType.BODY_TEXT_LIGHT} inline />
      <Typography text={t('Soft Bounces List ')} weight={TextWeight.BOLD} type={TextType.BODY_TEXT_LIGHT} inline />
      <Typography className={`${className}__section-description`} text={t('to the ')} type={TextType.BODY_TEXT_LIGHT} inline />
      <Typography text={t('Inactive Soft Bounces List.')} weight={TextWeight.BOLD} type={TextType.BODY_TEXT_LIGHT} inline />
      <div className={`${className}__input-section`}>
        <Select
          disabled={disabled}
          dataTest={`${dataTest}__select`}
          className={`${className}__input`}
          label={t('Number of Soft Bounces Allowed')}
          register={register(ContactSettingsFields.SOFT_BOUNCES_ALLOWED)}
        >
          {SOFT_BOUNCES_ALLOWED_OPTIONS.map((option) => (
            <option key={`option-${option}`} value={option}>
              {option === 0 ? '' : option}
            </option>
          ))}
        </Select>
        <div className={`${rootClass}__tooltip-container`}>
          <Tooltip triggerClassName={`${rootClass}__tooltip`} trigger={<Svg name={SvgNames.info} type={SvgType.LARGER_ICON} />} inline={false}>
            {t(
              'Set this to a higher number if you mail to the same contacts multiple times per week. If blank, soft bounces will never trigger a status change to inactive soft bounce.'
            )}
          </Tooltip>
        </div>
        {error && <Typography dataTest={`${dataTest}__error`} text={t('Number must be between 1-20')} type={TextType.ERROR} />}
        {value == 0 && !loading && <Caution message={t('Leaving this value blank will prevent repeat soft bounces from being marked inactive.')} />}
      </div>
    </Container>
  )
}

export default InactiveSoftBounces
