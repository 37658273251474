import { gql } from '@apollo/client'

export default gql`
  mutation createSmsComposerState {
    createSmsComposerState {
      launchId
      senderCode
      creditBalance
      smsNextMonthCredits
      vcFields {
        label
        value
        groupId
      }
      accountVCMaps {
        name
        mapping
      }
      crmFields {
        label
        value
        groupId
      }
      internationalSendingEnabled
    }
  }
`
