export const LEARN_MORE_LINK =
  'https://success.act-on.com/acton/attachment/9883/u-1f7176e9-6b2f-4b43-ade3-a9476c63853a/0/-/-/-/-/In-App%3A%20Sender%20Personalization%20%28Settings%20%3E%20Other%20Settings%20%3E%20From%20Addresses%29'

export const MORE_INFO =
  'https://success.act-on.com/acton/attachment/9883/u-1f7176e9-6b2f-4b43-ade3-a9476c63853a/0/-/-/-/-/In-App%3A%20Sender%20Personalization%20%28Settings%20%3E%20Other%20Settings%20%3E%20From%20Addresses%29'

export enum DOMAIN_WARNING_TOOLTIP_TEXT {
  SPF_UNSET = 'SPF is not set up for this domain',
  DKIM_UNSET = 'DKIM is not set up for this domain',
  ALL_UNSET = 'Neither DKIM nor SPF is set up for this domain',
}

export type SenderDomainInfo = Record<string, { hasDKIM: boolean; hasSPF: boolean }>
