import { createContext } from 'react'

import { AutomatedProgramCountsResponse, Program } from '@graphql/types/query-types'
import { ProgramManagerContextValues } from '@src/pages/programs/manager/context/ProgramManagerContextValues'
import { NavigationState } from '@utils/navigation/navigation.utils'
import { NavigationActions } from '@utils/navigation/state/actions'
import { ProgramErrors } from '@utils/program/program.constants'
import { ProgramState } from '@utils/program/program.constants'

export interface ProgramManagerPopupsState {
  exitSuccess: boolean
  exitFail: boolean
  addSuccess: boolean
  addFail: boolean
  errorSetToRun: boolean
  errorSetToPause: boolean
  moveToNextSuccess: boolean
  moveToNextFail: boolean
  clearHistorySuccess: boolean
  clearHistoryFail: boolean
  saveSuccess: boolean
  saveError: boolean
  loadError: boolean
  copyProgramMessageSuccess: boolean
  copyProgramMessageFail: boolean
}

export interface ProgramManagerModalsState {
  showStartProgram: boolean
  showPauseProgram: boolean
  showErrors: boolean
  showFeedbackBanner: boolean
  showAddContactsModal: boolean
  showExitContactsModal: boolean
  showClearHistory: boolean
  showConfirmSave: boolean
  showCancelEdit: boolean
}

export interface ProgramManagerErrors {
  programNotFound: boolean
  countsNotFound: boolean
}

export interface ProgramManagerValuesAPI {
  programState: ProgramState
  program: Program
  programUrlId: string
  programErrors: ProgramErrors
  programCounts: AutomatedProgramCountsResponse
  programManagerErrors: ProgramManagerErrors
  programManagerModalsState: ProgramManagerModalsState
  programManagerPopupsState: ProgramManagerPopupsState
  programStarting: boolean
  reenterProgramContacts: boolean
  hasAOContactsSource: boolean
  pendingSize: number
  contactSize: number
  canEdit: boolean
  isEditing: boolean
  isNew: boolean
  isPausing: boolean
  isStarting: boolean
  isSaving: boolean
  reloadProgramId?: string
  tab: string
  subTab: string
  navigationDisabled: boolean
  unsavedChanges: boolean
  saveFinal: boolean | undefined
  loading: boolean
  saving: boolean
  navigateFromCancelModal: boolean
  errorSetToRunMessage?: string
  showUpgrade: boolean
}

export interface ProgramManagerAPI {
  values: ProgramManagerValuesAPI
  updateProgramAndSetUnsaved: (program: Program, hasAOContactsSource?: boolean) => void
  setLoading: (loading: boolean) => void
  tabChange: (tab: string, subtab?: string) => void
  subTabChange: (subTab: string) => void
  editProgram: () => void
  cancelEdit: () => void
  saveProgram: () => void
  saveProgramFinal: () => void
  startProgram: (skipPending: boolean) => void
  pauseProgram: () => void
  clearProgramHistory: (allowReentrantAddresses: boolean) => void
  addContactsToProgram: () => void
  fixErrors: () => void
  refreshProgram: () => void
  exitContactsFromProgram: () => void
  moveNext: (stepId: string) => void
  setProgramManagerErrors: (error: keyof ProgramManagerErrors, val: boolean) => void
  toggleProgramManagerModalsState: (modal: keyof ProgramManagerModalsState) => void
  toggleNavigationDisabled(disabled: boolean): void
  closePopup: (fieldToClose: keyof ProgramManagerPopupsState) => void
  openPopup: (fieldToClose: keyof ProgramManagerPopupsState) => void
  navigationActions: NavigationActions
  navigation: NavigationState
  setContainerValues: (values: Partial<ProgramManagerValuesAPI>) => void
}

export const ProgramManagerContext = createContext<ProgramManagerAPI>({ values: ProgramManagerContextValues.values } as any)
