import React, { FC, useMemo } from 'react'

import classNames from 'classnames'

import SingleSelectDropdown from '@components/SingleSelectDropdown/SingleSelectDropdown'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { SelectOption } from '@interface/Select'
import { AlertScheduleState } from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertSchedule/AlertSchedule'
import {
  AlertFrequency,
  getHoursOptions,
  getSendAlertOnOptions,
  SendAlertOn,
} from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertSchedule/utils/AlertSchedule.utils'
import { daysOfWeek } from '@utils/date'

import './ScheduleOptions.css'

interface ScheduleOptionsProps {
  className?: string
  dataTest?: string
  onChange: (values: Partial<AlertScheduleState>) => void
  frequency?: AlertFrequency
  sendOn?: SendAlertOn
}

const rootClass = 'schedule-options'

const ScheduleOptions: FC<ScheduleOptionsProps> = (props: ScheduleOptionsProps) => {
  const { dataTest = rootClass, className = '', onChange, frequency = AlertFrequency.DAILY, sendOn = SendAlertOn.EVERY_DAY } = props

  const { t } = useTranslation()

  const frequencySelectOptions: SelectOption[] = [
    {
      label: t('Daily'),
      value: AlertFrequency.DAILY,
    },
    {
      label: t('Weekly'),
      value: AlertFrequency.WEEKLY,
    },
  ]

  const daysOptions: SelectOption[] = useMemo(() => daysOfWeek(t).map((day, index) => ({ label: day, value: index.toString() })), [])

  const hoursOptions = useMemo(getHoursOptions, [])

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__options-container`}>
        <div className={`${rootClass}__options`}>
          <Typography text={t('Frequency')} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.MEDIUM} />
          <SingleSelectDropdown
            defaultValue={AlertFrequency.DAILY}
            value={frequency}
            options={frequencySelectOptions}
            onSubmit={(value) =>
              value &&
              onChange({
                frequency: value as AlertFrequency,
                sendOn: value === AlertFrequency.DAILY ? SendAlertOn.EVERY_DAY : (daysOptions[0].value as SendAlertOn),
              })
            }
          />
        </div>
        <div className={`${rootClass}__options`}>
          <Typography text={t('Send on')} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.MEDIUM} />
          <SingleSelectDropdown
            defaultValue={SendAlertOn.EVERY_DAY}
            value={sendOn}
            options={frequency === AlertFrequency.DAILY ? getSendAlertOnOptions(t) : daysOptions}
            onSubmit={(value) => value && onChange({ sendOn: value as SendAlertOn })}
          />
        </div>
        <div className={`${rootClass}__options`}>
          <Typography text={t('Send at')} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.MEDIUM} />
          <SingleSelectDropdown placeholder={t('Select a time')} options={hoursOptions} onSubmit={() => undefined} />
        </div>
      </div>
      {sendOn === SendAlertOn.WEEKDAYS && (
        <Typography
          className={`${rootClass}__note`}
          text={t('Recipients won’t receive this alert on Saturday or Sunday.')}
          type={TextType.BODY_TEXT_SMALL_LIGHT}
          lineHeight={LineHeight.MEDIUM_SMALL}
        />
      )}
    </div>
  )
}

export default ScheduleOptions
