import { useApolloClient } from '@apollo/client'
import getUnifiedListFieldMappings from '@graphql/microservices/list/getUnifiedListFieldMappings'
import generateAuthenticationToken from '@graphql/microservices/webhooks-incoming-management/generateAuthenticationToken'
import getBehaviors from '@graphql/queries/getBehaviors'
import {
  GetUnifiedListFieldMappingsQuery,
  GetUnifiedListFieldMappingsQueryVariables,
  UnifiedListFieldMapping,
} from '@graphql/types/microservice/list-types'
import {
  GenerateAuthenticationTokenQuery,
  GenerateAuthenticationTokenQueryVariables,
} from '@graphql/types/microservice/webhooks-incoming-management-types'
import { BehaviorResponse, GetBehaviorsQuery, GetBehaviorsQueryVariables } from '@graphql/types/query-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'

interface CreateIncomingWebhookModalRequests {
  getCustomTouchPointBehaviors: () => Promise<BehaviorResponse[]>
  getUCLFieldMappings: () => Promise<UnifiedListFieldMapping[]>
  generateToken: () => Promise<string>
}

export const useCreateIncomingWebhookModalRequests = (): CreateIncomingWebhookModalRequests => {
  const { client: listServiceClient } = useMicroserviceClient({ serviceName: MicroserviceClients.LIST })
  const { client: incomingWebhooksClient } = useMicroserviceClient({ serviceName: MicroserviceClients.WEBHOOKS_INCOMING_MANAGEMENT })
  const actOnClassicClient = useApolloClient()

  const getCustomTouchPointBehaviors = () =>
    actOnClassicClient
      .query<GetBehaviorsQuery, GetBehaviorsQueryVariables>({
        query: getBehaviors,
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => data.getBehaviors as BehaviorResponse[])

  const getUCLFieldMappings = () =>
    listServiceClient
      .query<GetUnifiedListFieldMappingsQuery, GetUnifiedListFieldMappingsQueryVariables>({
        query: getUnifiedListFieldMappings,
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => data.unifiedListFieldMappings?.filter((mapping) => !mapping?.hidden && !mapping?.deleted) as UnifiedListFieldMapping[])

  const generateToken = () =>
    incomingWebhooksClient
      .query<GenerateAuthenticationTokenQuery, GenerateAuthenticationTokenQueryVariables>({
        query: generateAuthenticationToken,
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => data.generateAuthenticationToken ?? '')

  return {
    getCustomTouchPointBehaviors,
    getUCLFieldMappings,
    generateToken,
  }
}
