import { Dispatch, SetStateAction } from 'react'

import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { GetAccountCategoriesQuery } from '@graphql/types/query-types'
import {
  CategoryOption,
  EditUpdateSubscriptionStepState,
} from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditUpdateSubscriptionStep/EditUpdateSubscriptionStep'
import { errorStatusToast } from '@src/pages/SubscriptionManagement/SubcriptionManagement.constants'
import { filterNotEmptyArray } from '@utils/array'
import { logNewRelicError } from '@utils/new-relic.utils'
import { ProgramUpdateSubscriptionStepExt } from '@utils/program/program.constants'
import { FetchPromise } from '@utils/types'

export const getSubscriptionManagementCategories = async (
  getSMAccountCategories: () => FetchPromise<GetAccountCategoriesQuery>,
  setState: Dispatch<SetStateAction<EditUpdateSubscriptionStepState>>
) => {
  const { data, errors } = await getSMAccountCategories()
  if (data) {
    const categories = data?.getAccountCategories || []
    setState((state) => ({ ...state, accountCategories: categories.filter(filterNotEmptyArray), loading: false }))
  } else if (errors) {
    logNewRelicError(errors)
    setState((state) => ({
      ...state,
      statusToast: errorStatusToast,
    }))
  }
}

export const filterSelectedCategories = (step: ProgramUpdateSubscriptionStepExt): CategoryOption[] => {
  const selectedCategories = step.subscribeCategories
    .map((categoryName) => ({ categoryName, option: true }))
    .concat(step.unsubscribeCategories.map((categoryName) => ({ categoryName, option: false })))

  if (selectedCategories.length === 0) {
    return [{ categoryName: '', option: undefined }]
  }

  return selectedCategories
}

export const hasDuplicates = (categoryOptions: (CategoryOption | undefined)[]): boolean => {
  const categoryNames = categoryOptions.filter((item): item is CategoryOption => !!item?.categoryName).map((item) => item.categoryName)
  const duplicates = categoryNames.filter((name, index) => categoryNames.indexOf(name) !== index && name)

  return duplicates.length > 0
}

export const markDuplicates = (categoryOptions: CategoryOption[]): CategoryOption[] => {
  const nameCounts = new Map<string, number>()
  categoryOptions.forEach((option) => {
    if (option.categoryName) {
      const count = nameCounts.get(option.categoryName) ?? 0
      nameCounts.set(option.categoryName, count + 1)
    }
  })
  return categoryOptions.map((item) => ({
    ...item,
    duplicated: item.categoryName ? (nameCounts.get(item.categoryName) ?? 0) > 1 : false,
  }))
}

export const toSelectOption = (category: string | undefined): SelectV2SingleOption | undefined => {
  if (!category || category.trim() === '') {
    return undefined
  }

  return {
    label: category,
    value: category,
  }
}
