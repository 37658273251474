import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import Label from '@components/Label'
import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import SelectWithSubContent from '@components/SelectDropdownWithStatus/SelectWithSubContent'
import SingleSelectDropdown from '@components/SingleSelectDropdown/SingleSelectDropdown'
import { useTranslation } from '@const/globals'
import { SelectOption } from '@interface/Select'
import { approvalsMap, LaunchApprovalValue, options } from '@src/pages/Settings/Users/components/UserLaunchApprovalSelect/launchApproval.const'
import { useMarketingUsers } from '@src/pages/Settings/Users/context/MarketingUsersContext'

type ApprovalType = LaunchApprovalValue.ALLOWED | LaunchApprovalValue.NOT_ALLOWED | string

export const getInitialApproval = (launchApproval?: string, launchApprovalId?: string): LaunchApprovalValue | undefined =>
  approvalsMap.get(launchApproval ?? '') || (launchApprovalId ? LaunchApprovalValue.REQUIRES_ADMIN : undefined)

interface Props {
  onSubmit: (optionOrUserId?: ApprovalType) => void
  //this props exist in UserResponse
  launchApproval?: string
  launchApprovalId?: string
  excludeUserIds?: string[]
  className?: string
  dataTest?: string
}

const rootClass = 'user-launch-approval-select'

const UserLaunchApprovalSelect: FC<Props> = (props: Props) => {
  const { onSubmit, launchApproval, launchApprovalId, excludeUserIds, className = rootClass, dataTest = rootClass } = props
  const { t } = useTranslation()
  const [approval, setApproval] = useState<LaunchApprovalValue | undefined>(getInitialApproval(launchApproval, launchApprovalId))
  const requiresAdmin = useMemo<boolean>(() => approval === LaunchApprovalValue.REQUIRES_ADMIN, [approval])
  const { users, loading } = useMarketingUsers()
  const usersOptions = useMemo<SelectOption[]>(
    () =>
      users
        .filter((user) => user?.id && user?.fullName && (excludeUserIds ? !excludeUserIds.includes(user.id) : true))
        .map(({ fullName, id }) => ({ value: id!, label: fullName! })),
    [excludeUserIds]
  )

  useEffect(() => {
    setApproval(getInitialApproval(launchApproval, launchApprovalId))
  }, [launchApproval, launchApprovalId])

  const handleSelectApproval = useCallback(
    (approval: string) => {
      setApproval(approval as LaunchApprovalValue)
      onSubmit(approval === LaunchApprovalValue.REQUIRES_ADMIN ? launchApprovalId : approval)
    },
    [onSubmit]
  )

  return (
    <div className={classNames(rootClass, className)}>
      <SelectWithSubContent
        defaultValue={approval}
        options={options}
        onSubmit={handleSelectApproval}
        label={t('Launch.Approval')}
        subLabel={<Label required>{t('Requires.Approval.From')}</Label>}
        showSubContent={requiresAdmin}
        dataTest={`${dataTest}__select-approval`}
      >
        {loading ? (
          <Loader loaderType={LoaderTypes.row} />
        ) : (
          <SingleSelectDropdown
            options={usersOptions}
            defaultValue={launchApprovalId}
            onSubmit={onSubmit}
            className={`${rootClass}__select-admin`}
            dataTest={`${dataTest}__select-admin`}
          />
        )}
      </SelectWithSubContent>
    </div>
  )
}

export default UserLaunchApprovalSelect
