import { gql } from '@apollo/client'

export default gql`
  query getAvailableLanguages {
    getAvailableLanguages {
      value
      label
    }
  }
`
