import { gql } from '@apollo/client'

export default gql`
  query getOptInMarketingList($accountId: Long!, $sortDirection: Direction, $sortColumn: OptInMarketingListSortColumns, $size: Int!, $page: Int!) {
    getOptInMarketingList(accountId: $accountId, sortDirection: $sortDirection, sortColumn: $sortColumn, size: $size, page: $page) {
      optInMarketingLists {
        latestOptInDate
        listDisplayName
        listId
        listType
        optInCount
        sendWelcomeMessage
      }
      totalCount
    }
  }
`
