import { gql } from '@apollo/client'

export default gql`
  query getActivityListMaintenancePrograms($pageNumber: Long, $segmentId: String, $pageSize: Long) {
    getActivityListMaintenancePrograms(pageNumber: $pageNumber, segmentId: $segmentId, pageSize: $pageSize) {
      accountId
      activityIdentifier
      activityName
      author
      authorId
      createdTime
      date
      enabled
      id
      path
      segmentId
      type
      updatedTime
    }
  }
`
