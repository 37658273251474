import React, { FC, useEffect, useRef, useState } from 'react'

import SageBoard from '@src/pages/reports/SageBoard/SageBoard'
import { useAccountSettings } from '@utils/account/account.utils'
import { tsInitializer, useThoughtSpotService } from '@utils/hooks/microservices/useThoughtspotService'

const SageBoardContainer: FC = () => {
  const { userEmail } = useAccountSettings()
  const [loading, setLoading] = useState(true)
  const initialized = useRef<boolean>(false)

  const { tsAuth, thoughtSpotHost } = useThoughtSpotService(userEmail)

  useEffect(() => {
    if (loading) {
      if (!initialized.current) {
        tsInitializer(thoughtSpotHost, userEmail, tsAuth)
        initialized.current = true
      }
      setLoading(false)
    }
  }, [])

  return <SageBoard loading={loading} />
}

export default SageBoardContainer
