import React, { FC, useCallback, useContext, useEffect, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonSize, ButtonType } from '@components/Button/Button'
import { Checkbox } from '@components/Checkbox/Checkbox'
import ConditionCard from '@components/ConditionCard/ConditionCard'
import ConfirmationModal from '@components/ConfirmationModal'
import { YesNo } from '@components/ConfirmationModal/ConfirmationModal'
import Container from '@components/Container'
import Label from '@components/Label'
import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import Radio from '@components/Radio'
import RadioGroup from '@components/RadioGroup'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import Svg, { SvgColor, SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { TextLinkSize } from '@components/TextLink/TextLink'
import TextLink from '@components/TextLink/TextLink'
import TimeInput from '@components/TimeInput/TimeInput'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import CancelSync from '@src/pages/datamanagement/components/CancelSync/CancelSync'
import ReloadSyncStatus from '@src/pages/datamanagement/components/ReloadSyncStatus/ReloadSyncStatus'
import RunSync from '@src/pages/datamanagement/components/RunSync/RunSync'
import SyncResultModal from '@src/pages/datamanagement/components/SyncResultModal/SyncResultModal'
import SyncResults from '@src/pages/datamanagement/components/SyncResults/SyncResults'
import { DataManagementContext, ScheduledTime } from '@src/pages/datamanagement/context/DataManagementContext'
import { buildLastSyncStatus } from '@src/pages/datamanagement/context/DataManagementUtil'
import { FrequencyType, ScheduleAMPM, ScheduleType, SyncFilterType } from '@src/pages/datamanagement/utils/DataManagement.constants'
import { useAccountSettings, useGetFeatureFlag } from '@utils/account/account.utils'
import { getTimeParts } from '@utils/date'

import './SyncSchedule.css'

type Props = {
  className?: string
  dataTest?: string
}

const rootClass = 'sync-schedule'
const CRM_HOW_TO_ADD_LINK = 'https://connect.act-on.com/hc/en-us/articles/1500003419622-Set-Up-Data-Management-for-your-CRM-Integration'
//TODO: Refactor - Delete (Related logic will be in TimeInput.tsx)
export const ConvertStartTime = (ampm?: string, hour?: number, minute?: number) => {
  if (
    hour === undefined ||
    hour === 0 ||
    minute === undefined ||
    (hour === 12 && minute === 0 && (ampm === '0' || ampm?.toUpperCase() === ScheduleAMPM.AM))
  ) {
    return '00:00'
  }

  const hourStr = (ampm === '1' || ampm?.toUpperCase() === ScheduleAMPM.PM ? hour + 12 : hour % 12).toString()
  const minuteStr = minute !== undefined && minute < 10 ? `0${minute}` : minute.toString()
  return `${hourStr}:${minuteStr}`
}

const SyncSchedule: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '' } = props
  const syncNowButtonTitle = 'Sync Now'
  const cancelButtonTitle = 'Cancel'

  const {
    values: { accountTimeZone, connectorType, lastSync, lastSyncResult, runningSyncStatus, syncSchedule },
    nestedUpdate,
    getLastSyncResult,
  } = useContext(DataManagementContext)

  const { crmSelectEntitiestoSync, crmSyncFilters } = useAccountSettings()
  const { t } = useTranslation()

  const [showFrequency, setShowFrequency] = useState(false)
  const [displayState, setDisplayState] = useState<string>(syncSchedule.frequency ? syncSchedule.frequency : '')
  const [showResults, setShowResults] = useState(false)
  const [showViewResult, setShowViewResult] = useState(true)
  const [syncButtonTitle, setSyncButtonTitle] = useState(syncNowButtonTitle)
  const [lastSyncStatus, setLastSyncStatus] = useState<string>(t('Last sync completed on ') + lastSync)
  const [startSync, setStartSync] = useState<boolean>(false)
  const [cancelSync, setCancelSync] = useState<boolean>(false)
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false)

  const hasShowActOnContactsTab = useGetFeatureFlag('hasShowActOnContactsTab')

  const [formattedScheduleTime, setFormattedScheduleTime] = useState<string>(
    ConvertStartTime(syncSchedule.scheduledTime.ampm, syncSchedule.scheduledTime.hour, syncSchedule.scheduledTime.minute)
  )

  const frequencyOptions: SelectV2SingleOption[] = [
    { value: FrequencyType.HOURLY, label: FrequencyType.HOURLY },
    { value: FrequencyType.DAILY, label: FrequencyType.DAILY },
    { value: FrequencyType.WEEKLY, label: FrequencyType.WEEKLY },
    { value: FrequencyType.MONTHLY, label: FrequencyType.MONTHLY },
  ]

  const hourOptions: SelectV2SingleOption[] = [
    { value: '4', label: `4 ${t('Hours')}` },
    { value: '6', label: `6 ${t('Hours')}` },
    { value: '8', label: `8 ${t('Hours')}` },
    { value: '12', label: `12 ${t('Hours')}` },
  ]

  const daysOfTheWeek: SelectV2SingleOption[] = [
    { value: '1', label: t('Mon') },
    { value: '2', label: t('Tue') },
    { value: '3', label: t('Wed') },
    { value: '4', label: t('Thu') },
    { value: '5', label: t('Fri') },
    { value: '6', label: t('Sat') },
    { value: '7', label: t('Sun') },
  ]

  const getDayofTheMonth = useCallback((t: Function): SelectV2SingleOption[] => {
    const dates = []
    for (let i = 1; i <= 31; i++) {
      dates.push({
        value: `${i}`,
        label: t(i),
      })
    }

    return dates
  }, [])

  const dayOfTheMonth = getDayofTheMonth(t)

  const frequencySelectedOption = frequencyOptions.find(({ value }) => value === syncSchedule.frequency)
  const hourSelectedOption = hourOptions.find(({ value }) => value === syncSchedule.period)
  const daysOfTheWeekSelectedOption = daysOfTheWeek.find(({ value }) => value === syncSchedule.dayOfTheWeek)
  const dayofTheMonthSelectedOption = dayOfTheMonth.find(({ value }) => value === syncSchedule.dayOfTheMonth)

  const onScheduleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    nestedUpdate('syncSchedule', 'schedule', event.target.value, false)
  }

  const getRadioButtonLabels = (mainLabel: string, subText: string) => (
    <div className={`${rootClass}__schedule-option`}>
      <p className={`${rootClass}__maintext`}>{mainLabel}</p>
      <p className={`${rootClass}__subtext`}>{subText}</p>
    </div>
  )

  const getLabelSpan = (labelText: string) => (
    <span className={showFrequency ? `${rootClass}__label-enable` : `${rootClass}__label-disable`}>{labelText}</span>
  )

  const timeChange = (value: string | Date) => {
    const [hourStr, minuteStr] = value === '' || value === null ? ['0', '0'] : (value as string).split(':')

    const { ampm, hour, minute } = getTimeParts(hourStr, minuteStr)

    const scheduledTime: ScheduledTime = {
      hour,
      minute,
      ampm: ampm.toString(),
    }

    nestedUpdate('syncSchedule', 'scheduledTime', scheduledTime, false)
  }

  useEffect(() => {
    setFormattedScheduleTime(ConvertStartTime(syncSchedule.scheduledTime.ampm, syncSchedule.scheduledTime.hour, syncSchedule.scheduledTime.minute))
  }, [syncSchedule.scheduledTime])

  const syncButtonAction = () => {
    if (syncButtonTitle === cancelButtonTitle) {
      setShowCancelConfirmation(true)
    } else {
      setStartSync(true)
      setShowViewResult(false)
    }
  }

  useEffect(() => {
    if (runningSyncStatus.isSyncingNow) {
      setShowViewResult(false)
      setSyncButtonTitle(cancelButtonTitle)
    } else {
      getLastSyncResult()
      setSyncButtonTitle(syncNowButtonTitle)
    }
    setLastSyncStatus(runningSyncStatus.syncState)
    if (cancelSync) {
      setCancelSync(false)
    }

    if (startSync) {
      setStartSync(false)
    }
  }, [runningSyncStatus.syncState, runningSyncStatus.isSyncingNow])

  useEffect(() => {
    const isLastSyncNull = lastSync === undefined || lastSync === null || lastSync === 0
    setShowViewResult(!isLastSyncNull)
    const formattedTime = buildLastSyncStatus(lastSync, runningSyncStatus.isSyncingNow, accountTimeZone)
    const statusText = crmSyncFilters
      ? t('Last sync:', { time: formattedTime })
      : isLastSyncNull
      ? formattedTime
      : t('Last sync completed on ') + formattedTime
    const intlStatus = t(statusText, {
      connectedCrm: connectorType,
      time: formattedTime,
    })
    setLastSyncStatus(intlStatus)
  }, [lastSync])

  useEffect(() => {
    if (!syncSchedule.frequency || syncSchedule.frequency === 'None') {
      setDisplayState(FrequencyType.HOURLY)
    } else {
      setDisplayState(syncSchedule.frequency)
      if (
        syncSchedule.frequency === FrequencyType.HOURLY &&
        (syncSchedule.period === undefined || syncSchedule.period === null || parseInt(syncSchedule.period) < 4)
      ) {
        nestedUpdate('syncSchedule', 'period', '4', true)
      } else if (syncSchedule.frequency === FrequencyType.WEEKLY && (syncSchedule.dayOfTheWeek === undefined || syncSchedule.dayOfTheWeek === null)) {
        nestedUpdate('syncSchedule', 'dayOfTheWeek', '1', true)
      } else if (
        syncSchedule.frequency === FrequencyType.MONTHLY &&
        (syncSchedule.dayOfTheMonth === undefined || syncSchedule.dayOfTheWeek === null)
      ) {
        nestedUpdate('syncSchedule', 'dayOfTheMonth', '1', true)
      }
    }
  }, [syncSchedule.frequency])

  useEffect(() => {
    setShowFrequency(syncSchedule.schedule === ScheduleType.SCHEDULE)
    if (syncSchedule.schedule === ScheduleType.SCHEDULE && (syncSchedule.frequency === 'None' || syncSchedule.frequency === undefined)) {
      nestedUpdate('syncSchedule', 'frequency', FrequencyType.HOURLY, true)
    }
  }, [syncSchedule.schedule])

  const confirmationBody = t('Any progress will be lost. Are you sure you want to cancel this sync?')

  return (
    <>
      {showCancelConfirmation && (
        <ConfirmationModal
          centerTitle
          isYesNo
          yesButtonText={t('Cancel Sync')}
          noButtonText={t('Continue Sync')}
          isOpen={showCancelConfirmation}
          body={confirmationBody}
          title={t('Cancel sync in progress?')}
          onAnswer={(answer) => {
            if (answer === YesNo.YES) {
              setCancelSync(true)
              setShowViewResult(false)
            }
            setShowCancelConfirmation(false)
          }}
        />
      )}
      <Container dataTest={dataTest} className={classNames(rootClass, className)}>
        {lastSyncResult.showWarning && (
          <div className={`${rootClass}__panel-warning`}>
            <div className={`${rootClass}__svg-warning`}>
              <Svg name={SvgNames.cautionYellow} type={SvgType.LARGER_ICON} />
            </div>
            {t('For some reason, the last sync was not successful. Please contact Support.')}
          </div>
        )}
        {!hasShowActOnContactsTab && showResults && <SyncResults setShowResults={setShowResults} />}
        {hasShowActOnContactsTab && showResults && <SyncResultModal setShowResults={setShowResults} />}
        <div
          className={classNames(`${rootClass}__status`, {
            [`${rootClass}__status-redesigned`]: crmSyncFilters,
          })}
        >
          {startSync && <RunSync />}
          {cancelSync && <CancelSync />}
          <ReloadSyncStatus />
          <div className={`${rootClass}__title-panel`}>
            {crmSyncFilters && <Svg name={SvgNames.reloadNoFill} type={SvgType.LARGE_ICON} fill={SvgColor.TEAL} />}
            {runningSyncStatus.isSyncingNow && (
              <span className={`${rootClass}__panel-title`}>
                <Loader className={`${rootClass}__status-loader`} loaderType={LoaderTypes.page} />
                <div className={`${rootClass}__status-format`}> {lastSyncStatus}</div>
              </span>
            )}
            {!crmSyncFilters && !runningSyncStatus.isSyncingNow && <span className={`${rootClass}__panel-title`}> {lastSyncStatus}</span>}
            {crmSyncFilters && !runningSyncStatus.isSyncingNow && (
              <Typography text={t(lastSyncStatus)} tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }} type={TextType.BODY_TEXT_LARGE} />
            )}
            {showViewResult && (
              <Button
                buttonType={ButtonType.TEXT}
                className={`${rootClass}__results-link`}
                onClick={() => {
                  setShowResults(true)
                }}
              >
                {t('View results')}
              </Button>
            )}
          </div>
          <div
            className={classNames(`${rootClass}__sync-button-container`, {
              [`${rootClass}__sync-button-container--redesigned`]: crmSyncFilters,
            })}
          >
            <Button buttonType={ButtonType.PRIMARY} buttonSize={ButtonSize.REGULAR} onClick={() => syncButtonAction()}>
              {t(syncButtonTitle)}
            </Button>
          </div>
        </div>
        <div className={`${rootClass}__schedule-panel`}>
          <span className={`${rootClass}__panel-title`}>{t('Synchronization Schedule')}</span>
          <Typography
            className={`${rootClass}__subtext`}
            text={t('DataManagement.SyncSchedule.SynchronizationSchedule.Subheader')}
            type={TextType.BODY_TEXT_SMALL_LIGHT}
          />
          <div className={`${rootClass}__primary-radiogroup`}>
            <RadioGroup verticalLayout>
              <div>
                <Radio
                  className={'radio-top'}
                  labelChildren={getRadioButtonLabels(t('Off'), t('To sync data, click Sync Now'))}
                  dataTest={`${dataTest}-mode-off`}
                  name="scheduleMode"
                  value={t(ScheduleType.OFF)}
                  checked={syncSchedule.schedule === ScheduleType.OFF}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setShowFrequency(false)
                      onScheduleChange(event)
                    }
                  }}
                />
              </div>
              <div>
                <Radio
                  labelChildren={getRadioButtonLabels(t('Automatic'), t('Sync about once an hour'))}
                  dataTest={`${dataTest}-mode-automatic`}
                  name="scheduleMode"
                  value={t(ScheduleType.AUTOMATIC)}
                  checked={syncSchedule.schedule === ScheduleType.AUTOMATIC}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setShowFrequency(false)
                      onScheduleChange(event)
                    }
                  }}
                />
              </div>
              <div>
                <Radio
                  className={'radio-bottom'}
                  labelChildren={getRadioButtonLabels(t('Schedule'), t('Set up a custom sync schedule'))}
                  dataTest={`${dataTest}-mode-schedule`}
                  name="scheduleMode"
                  value={t(ScheduleType.SCHEDULE)}
                  checked={syncSchedule.schedule === ScheduleType.SCHEDULE}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setShowFrequency(true)
                      onScheduleChange(event)
                    }
                  }}
                />
              </div>
            </RadioGroup>
            <div className={`${rootClass}__schedule-row`}>
              <div className={`${rootClass}__schedule-col ${rootClass}__schedule-col--1`}>
                <Label>{getLabelSpan(t('Frequency'))}</Label>
                <SelectV2
                  key={`${syncSchedule.frequency}-${frequencyOptions?.length}`}
                  dataTest={`${dataTest}-frequency-select`}
                  options={frequencyOptions}
                  defaultValue={frequencySelectedOption}
                  isDisabled={!showFrequency}
                  isClearable={false}
                  truncateOptions
                  hideCheckMark
                  onChange={(option) => {
                    setDisplayState(`${(option as SelectV2SingleOption).value}`)
                    nestedUpdate('syncSchedule', 'frequency', (option as SelectV2SingleOption).value, false)
                  }}
                />
              </div>
              {displayState === FrequencyType.HOURLY && (
                <div className={`${rootClass}__schedule-col ${rootClass}__schedule-col--2 run-interval`}>
                  <Label>{getLabelSpan(t('Run every'))}</Label>
                  <SelectV2
                    key={`${syncSchedule.period}-${hourOptions?.length}`}
                    dataTest={`${dataTest}-interval-select`}
                    options={hourOptions}
                    defaultValue={hourSelectedOption || hourOptions[0]}
                    isDisabled={!showFrequency}
                    isClearable={false}
                    truncateOptions
                    hideCheckMark
                    onChange={(option) => {
                      nestedUpdate('syncSchedule', 'period', (option as SelectV2SingleOption).value, false)
                    }}
                  />
                </div>
              )}
              {displayState === FrequencyType.WEEKLY && (
                <div className={`${rootClass}__schedule-col ${rootClass}__schedule-col--3 run-dotw`}>
                  <Label>{getLabelSpan(t('Run every'))}</Label>
                  <SelectV2
                    key={`${syncSchedule.dayOfTheWeek}-${daysOfTheWeek?.length}`}
                    dataTest={`${dataTest}-dotw-select`}
                    defaultValue={daysOfTheWeekSelectedOption}
                    options={daysOfTheWeek}
                    isDisabled={!showFrequency}
                    isClearable={false}
                    truncateOptions
                    hideCheckMark
                    onChange={(option) => {
                      nestedUpdate('syncSchedule', 'dayOfTheWeek', (option as SelectV2SingleOption).value, false)
                    }}
                  />
                </div>
              )}
              {displayState === FrequencyType.MONTHLY && (
                <div className={`${rootClass}__schedule-col ${rootClass}__schedule-col--3 run-dotm`}>
                  <Label>{getLabelSpan(t('Day'))}</Label>
                  <SelectV2
                    key={`${syncSchedule.dayOfTheMonth}-${dayOfTheMonth?.length}`}
                    dataTest={`${dataTest}-dotm-select`}
                    options={dayOfTheMonth}
                    defaultValue={dayofTheMonthSelectedOption}
                    isDisabled={!showFrequency}
                    isClearable={false}
                    truncateOptions
                    hideCheckMark
                    onChange={(option) => {
                      nestedUpdate('syncSchedule', 'dayOfTheMonth', (option as SelectV2SingleOption).value, false)
                    }}
                  />
                </div>
              )}

              <div className={`${rootClass}__schedule-col ${rootClass}__schedule-col--3 run-starttime`}>
                <TimeInput
                  value={formattedScheduleTime}
                  onChange={(newValue) => timeChange(newValue)}
                  required
                  label={getLabelSpan(t('Start time'))}
                  name="scheduledTime"
                  disabled={!showFrequency}
                  dataTest={`${dataTest}-starttime-select`}
                />
              </div>
            </div>
          </div>
        </div>
        {crmSelectEntitiestoSync && (
          <div className={`${rootClass}__schedule-panel`}>
            <div className={`${rootClass}__title-container`}>
              <div className={`${rootClass}__title-section`}>
                <span className={`${rootClass}__panel-title`}>{t('CRM entities to sync')}</span>
                <div className={`${rootClass}__title-container__subtext-container`}>
                  <Typography
                    className={`${rootClass}__title-container__subtext`}
                    text={t('DataManagement.SyncSchedule.CRMEntitiesSync.Subheader', {
                      CRM: connectorType,
                    })}
                    type={TextType.BODY_TEXT_SMALL_LIGHT}
                  />
                  <TextLink text={t('CRM.How.To.Add')} link={CRM_HOW_TO_ADD_LINK} size={TextLinkSize.LARGE} className="push-left-half" />
                </div>
              </div>
              <Button
                buttonType={ButtonType.TEXT}
                className={`${rootClass}__results-link`}
                onClick={() => {
                  // Add functionality to select all entities
                }}
              >
                {t('Select all')}
              </Button>
            </div>
            <div className={`${rootClass}__entities-content`}>
              <div className={`${rootClass}__entities-row`}>
                <div className={`${rootClass}__entity-option`}>
                  <Checkbox
                    dataTest={`${dataTest}-entity-contacts`}
                    checked={true}
                    label={t('Contacts')}
                    onChange={() => {
                      // Handle entity selection
                    }}
                  />
                </div>
                <div className={`${rootClass}__entity-option`}>
                  <Checkbox
                    dataTest={`${dataTest}-entity-leads`}
                    checked={true}
                    label={t('Leads')}
                    onChange={() => {
                      // Handle entity selection
                    }}
                  />
                </div>
                <div className={`${rootClass}__entity-option`}>
                  <Checkbox
                    dataTest={`${dataTest}-entity-opportunities`}
                    checked={true}
                    label={t('Opportunities')}
                    onChange={() => {
                      // Handle entity selection
                    }}
                  />
                </div>
                <div className={`${rootClass}__entity-option`}>
                  <Checkbox
                    dataTest={`${dataTest}-entity-accounts`}
                    checked={true}
                    label={t('Accounts')}
                    onChange={() => {
                      // Handle entity selection
                    }}
                  />
                </div>
                <div className={`${rootClass}__entity-option`}>
                  <Checkbox
                    dataTest={`${dataTest}-entity-campaigns`}
                    checked={true}
                    label={t('Campaigns')}
                    onChange={() => {
                      // Handle entity selection
                    }}
                  />
                </div>
                <div className={`${rootClass}__entity-option`}>
                  <Checkbox
                    dataTest={`${dataTest}-entity-owners`}
                    checked={true}
                    label={t('Owners')}
                    onChange={() => {
                      // Handle entity selection
                    }}
                  />
                </div>
                <div className={`${rootClass}__entity-option`}>
                  <Checkbox
                    dataTest={`${dataTest}-entity-groups`}
                    checked={true}
                    label={t('Groups')}
                    onChange={() => {
                      // Handle entity selection
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {syncSchedule.isOptInOutSupported && (
          <div
            className={`${rootClass}__option-panel ${rootClass}${
              !syncSchedule.optInOutVisibility.visibility ? '__option-panel-unset-height' : '__option-panel-with-height'
            }`}
          >
            <span className={`${rootClass}__panel-title`}>{t('Options')}</span>
            {!syncSchedule.optInOutVisibility.visibility && (
              <div className={`${rootClass}__panel-warning`}>
                <div className={`${rootClass}__svg-warning`}>
                  <Svg name={SvgNames.cautionYellow} type={SvgType.LARGER_ICON} />
                </div>
                {t('Synchronizing Opt In/Out data requires setting ')}&nbsp; <b>Email Opt Out</b>&nbsp; {t('to')} &nbsp;
                <b>Visible</b>&nbsp;
                {t(' by an administrator within Salesforce.')}&nbsp;
                <a className={`${rootClass}__more-info`} href={syncSchedule.optInOutVisibility.moreInfoUrl} target="_blank" rel="noreferrer">
                  {t('More Info')}
                  &nbsp;
                  <Svg name={SvgNames.customLink} type={SvgType.ICON} />
                </a>
              </div>
            )}
            <div className={`${rootClass}__option-row`}>
              <div className={`${rootClass}__option`}>
                <Checkbox
                  dataTest={`${dataTest}-option-pulloptout`}
                  disabled={!syncSchedule.optInOutVisibility.visibility}
                  checked={syncSchedule.pullOptOut}
                  label={t('Pull Opt-Out from CRM')}
                  onChange={(checked) => {
                    nestedUpdate('syncSchedule', 'pullOptOut', checked, false)
                  }}
                />
                <p className={`${rootClass}__subtext`}>
                  {t(
                    'Add records to the Act-On Opt-Out list when the Email Opt-Out field is selected in your CRM. Note: Clearing the field in the CRM will not remove the record from the Act-On Opt-Out list.'
                  )}
                </p>
              </div>
              <div className={`${rootClass}__option`}>
                <Checkbox
                  dataTest={`${dataTest}-option-pushoptout`}
                  disabled={!syncSchedule.optInOutVisibility.visibility}
                  checked={syncSchedule.pushOptOut}
                  label={t('Push Opt-Out to CRM')}
                  onChange={(checked) => {
                    nestedUpdate('syncSchedule', 'pushOptOut', checked, false)
                  }}
                />
                <p className={`${rootClass}__subtext`}>
                  {t('Update the Email Opt-Out field in your CRM when contacts opt-in or opt-out via Act-On.')}
                </p>
              </div>
            </div>
          </div>
        )}
        {crmSyncFilters && (
          <div className={`${rootClass}__schedule-panel`}>
            <span className={`${rootClass}__panel-title`}>{t('Synchronization Filters')}</span>
            <Typography
              className={`${rootClass}__subtext ${rootClass}__subtext-filters`}
              text={t('DataManagement.SyncSchedule.SyncFilters.Subheader', { CRM: connectorType })}
              type={TextType.BODY_TEXT_SMALL_LIGHT}
            />
            <div className={`${rootClass}__primary-radiogroup`}>
              <RadioGroup verticalLayout>
                <div>
                  <Radio
                    className={'radio-top'}
                    label={t('No filters')}
                    dataTest={`${dataTest}-mode-off`}
                    name="scheduleMode"
                    value={SyncFilterType.NO_FILTERS}
                    checked={syncSchedule.schedule === ScheduleType.OFF}
                    onChange={(event) => {
                      if (event.target.checked) {
                      }
                    }}
                  />
                  <Typography
                    className={`${rootClass}__filter-subtext`}
                    text={t('DataManagement.SyncSchedule.SyncFilters.NoFiltersSubtext', { CRM: connectorType })}
                    type={TextType.BODY_TEXT_LIGHT}
                  />
                </div>
                <div>
                  <Radio
                    className={'radio-top'}
                    label={t('Records from a custom segment')}
                    dataTest={`${dataTest}-mode-off`}
                    name="scheduleMode"
                    value={SyncFilterType.CUSTOM_SEGMENT}
                    checked={syncSchedule.schedule === ScheduleType.OFF}
                    onChange={(event) => {
                      if (event.target.checked) {
                      }
                    }}
                  />
                  <Typography
                    className={`${rootClass}__filter-subtext`}
                    text={t('DataManagement.SyncSchedule.SyncFilters.CustomSegment', { CRM: connectorType })}
                    type={TextType.BODY_TEXT_LIGHT}
                  />
                </div>
              </RadioGroup>
            </div>
            <div className={`${rootClass}__panel-warning ${rootClass}__panel-warning-filters`}>
              <div className={`${rootClass}__svg-warning`}>
                <Svg name={SvgNames.cautionYellow} type={SvgType.LARGER_ICON} />
              </div>
              {t('DataManagement.SyncSchedule.SyncFilters.Warning')}
            </div>
            <div>
              <ConditionCard className={`${rootClass}__condition-card`} filterType={'Lead match'} conditions={[]} onChange={() => {}} />
              <ConditionCard className={`${rootClass}__condition-card`} filterType={'Contacts match'} conditions={[]} onChange={() => {}} />
              <ConditionCard className={`${rootClass}__condition-card`} filterType={'Target match'} conditions={[]} onChange={() => {}} />
            </div>
          </div>
        )}
      </Container>
    </>
  )
}

export default SyncSchedule
