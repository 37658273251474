import { gql } from '@apollo/client'

export default gql`
  query beaconSettings {
    beaconSettings {
      administrator
      cookieMode
      copypasta
      copypastaDict {
        STANDARD
        OPTIN
        OPTOUT
        NOCOOKIES
      }
      enabled
      beaconData {
        isBeaconActive
      }
    }
  }
`
