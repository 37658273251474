import { gql } from '@apollo/client'

export default gql`
  mutation deleteFormTemplates($templateIds: [String!]!) {
    deleteFormTemplates(templateIds: $templateIds) {
      status
      successIds {
        id
        name
      }
      failedIds {
        id
        name
        reason
      }
    }
  }
`
