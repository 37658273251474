import React, { FC, Fragment, ReactNode, useMemo, useState } from 'react'

import classNames from 'classnames'

import { renderBreadcrumbsItem } from '@components/Breadcrumbs/BreadcrumbsUtils'
import BreadcrumbsDropDown from '@components/Breadcrumbs/components/BreadcrumbsDropDown/BreadcrumbsDropDown'
import { BreadcrumbInfoHoverContent } from '@components/Breadcrumbs/components/BreadcrumbsItemWithInfoHover/BreadcrumbsItemWithInfoHover'
import { MenuItem } from '@components/DropDownActions/DropDownActions'
import Svg, { SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { undefinedReturnedFunction } from '@const/globals'

import { TypographyProps } from '../Typography/Typography'

import './Breadcrumbs.css'

interface BreadcrumbsProps {
  className?: string
  dataTest?: string
  breadcrumbs: BreadcrumbsItemType[]
  defaultActiveIndex?: number
  wrap?: boolean
  svgName?: SvgNames
  caretSvgName?: SvgNames
  caretSvgType?: SvgType
  charsLimit?: number
  prefix?: BreadcrumbsItemType
  hasAllInactiveElements?: boolean
  dropDownPositionIndex?: number
  dropDownClassName?: string
  onDropDownOpen?: (open: boolean) => void
}

export interface BreadcrumbsItemType {
  text: string
  id?: number | string
  onClick?: () => void
  hasTooltip?: boolean
  customTooltip?: ReactNode
  customTypography?: Partial<TypographyProps>
  isActive?: boolean
  inDropDown?: boolean
  className?: string
  infoHoverContent?: BreadcrumbInfoHoverContent
  svgName?: SvgNames
}

const rootClass = 'breadcrumbs'

const Breadcrumbs: FC<BreadcrumbsProps> = (props: BreadcrumbsProps) => {
  const {
    breadcrumbs,
    defaultActiveIndex = breadcrumbs.length - 1,
    wrap = false,
    dataTest = rootClass,
    className = '',
    caretSvgName = SvgNames.paginationControlsNext,
    caretSvgType = SvgType.SMALL_ICON,
    svgName,
    charsLimit,
    prefix,
    dropDownPositionIndex,
    hasAllInactiveElements = false,
    onDropDownOpen,
  } = props
  const [dropDownOpen, setDropDownOpen] = useState<boolean>(false)

  const updatedBreadcrumbs: BreadcrumbsItemType[] = useMemo(() => breadcrumbs.filter(({ inDropDown }) => !inDropDown), [breadcrumbs])

  const dropDownMenuItems: MenuItem[] = useMemo(
    () =>
      breadcrumbs
        .filter(({ inDropDown }) => !!inDropDown)
        .map(({ text, onClick }) => ({
          text,
          onClick: onClick ?? undefinedReturnedFunction,
        })),
    [breadcrumbs]
  )

  const renderDropDown = (index: number) => (
    <div className={classNames(`${rootClass}__crumb`, { [`${rootClass}__drop-down-open`]: dropDownOpen })}>
      <BreadcrumbsDropDown
        menuItems={dropDownMenuItems}
        onDropDownOpen={(open) => {
          onDropDownOpen?.(open)
          setDropDownOpen(() => open)
        }}
      />
      {index < updatedBreadcrumbs.length && <Svg name={caretSvgName} type={caretSvgType} fill={SvgColor.BUTTON_GRAY} />}
    </div>
  )

  return (
    <div className={classNames(rootClass, className, { [`${rootClass}__wrap`]: wrap })} data-test={dataTest}>
      {prefix && renderBreadcrumbsItem({ ...prefix, isActive: false })}
      {updatedBreadcrumbs.map((item, index) => (
        <Fragment key={index}>
          {!!dropDownPositionIndex && dropDownPositionIndex === index && renderDropDown(dropDownPositionIndex)}
          <div className={`${rootClass}__crumb`}>
            {renderBreadcrumbsItem({ ...item, isActive: hasAllInactiveElements ? false : index === defaultActiveIndex }, charsLimit, svgName)}
            {index !== updatedBreadcrumbs.length - 1 && <Svg name={caretSvgName} type={caretSvgType} fill={SvgColor.BUTTON_GRAY} />}
          </div>
        </Fragment>
      ))}
      {!!dropDownPositionIndex && dropDownPositionIndex > updatedBreadcrumbs.length - 1 && renderDropDown(dropDownPositionIndex)}
    </div>
  )
}

export default Breadcrumbs
