import { gql } from '@apollo/client'

export default gql`
  mutation runProgramNow($programId: String) {
    runProgramNow(programId: $programId) {
      status
      message
    }
  }
`
