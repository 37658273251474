import React, { useEffect, ChangeEvent, FC, useState } from 'react'

import classNames from 'classnames'

import { InputProps, InputSize, InputType } from '@components/Input/Input'
import InputWithClear from '@components/InputWithClear/InputWithClear'
import Select from '@components/Select/Select'
import { SelectSize } from '@components/Select/Select'
import Switch, { SwitchOption, SwitchOptions } from '@components/Switch/Switch'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { DateType, DEFAULT_DATE_QUANTITIES } from '@utils/date'
import { titleCase } from '@utils/strings'

import './ChartExtraElement.css'

export interface ChartExtraElementProps {
  canSwitchChartType: boolean
  chartType: SwitchOptions
  onSwitchChartType: (button: SwitchOptions) => void
  dateType: DateType
  dateTypeQuantity: number
  onDateChange: (dateType: DateType, dateTypeQuantity: number) => void
  className?: string
  dataTest?: string
}
export interface ChartExtraElementState {
  dateType: DateType
  dateTypeQuantity: number
  visibleQuantity: string
  dateTypeQuantities: typeof DEFAULT_DATE_QUANTITIES
  showQuantityClear: boolean
}

export const ChartExtraElementDefaultState: ChartExtraElementState = {
  dateType: DateType.MONTH,
  dateTypeQuantity: DEFAULT_DATE_QUANTITIES[DateType.MONTH],
  visibleQuantity: String(DEFAULT_DATE_QUANTITIES[DateType.MONTH]),
  dateTypeQuantities: { ...DEFAULT_DATE_QUANTITIES },
  showQuantityClear: false,
}

const MIN_DATE_TYPE_QUANTITY = 1
const MAX_DATE_TYPE_QUANTITY = 99

export const getDateTypeQuantity = (currentQuantity: number, state: ChartExtraElementState): number => {
  const defaultQuantity = state.dateTypeQuantities[state.dateType as DateType]
  return isNaN(currentQuantity)
    ? defaultQuantity
    : currentQuantity < MIN_DATE_TYPE_QUANTITY
    ? MIN_DATE_TYPE_QUANTITY
    : currentQuantity > MAX_DATE_TYPE_QUANTITY
    ? MAX_DATE_TYPE_QUANTITY
    : currentQuantity
}

const DateTypes = ['year', 'month', 'week', 'day']

const rootClass = 'chart-extra-element'

const ChartExtraElement: FC<ChartExtraElementProps> = (props: ChartExtraElementProps) => {
  const { canSwitchChartType, chartType, onSwitchChartType, dateType, dateTypeQuantity, onDateChange, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const [state, setState] = useState<ChartExtraElementState>({ ...ChartExtraElementDefaultState, dateType, dateTypeQuantity })

  useEffect(() => {
    const dateTypeQuantity = getDateTypeQuantity(state.dateTypeQuantity, state)
    state.dateTypeQuantities[state.dateType] = dateTypeQuantity
    setState({
      ...state,
      dateTypeQuantity: dateTypeQuantity,
      visibleQuantity: String(dateTypeQuantity),
      showQuantityClear: dateTypeQuantity != DEFAULT_DATE_QUANTITIES[state.dateType],
    })

    onDateChange(state.dateType, state.dateTypeQuantity)
  }, [state.dateType, state.dateTypeQuantity])

  const updateQuantity = (e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
    if (state.visibleQuantity === '') {
      handleQuantityClear()
    } else {
      const dateTypeQuantity = getDateTypeQuantity(parseInt(e.currentTarget.value), state)
      const visibleQuantity = String(dateTypeQuantity)
      setState({ ...state, dateTypeQuantity, visibleQuantity })
    }
  }

  const inputProps: InputProps = {
    value: state.visibleQuantity,
    size: InputSize.SMALL,
    inputType: InputType.QUANTITY,
    type: 'number',
    min: MIN_DATE_TYPE_QUANTITY,
    max: MAX_DATE_TYPE_QUANTITY,
    onChange: (e) => setState({ ...state, visibleQuantity: e.target.value }),
    onKeyPress: (e) => e.key == 'Enter' && updateQuantity(e),
    onBlur: (e) => updateQuantity(e),
  }

  const switchOptions: SwitchOption[] = [{ label: SwitchOptions.BAR }, { label: SwitchOptions.GRAPH }]

  const handleQuantityClear = () => {
    const defaultValue = DEFAULT_DATE_QUANTITIES[state.dateType]
    state.dateTypeQuantities[state.dateType] = defaultValue
    setState({ ...state, dateTypeQuantity: defaultValue, visibleQuantity: String(defaultValue) })
  }

  const handleDateChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setState({
      ...state,
      dateType: e.target.value as DateType,
      dateTypeQuantity: state.dateTypeQuantities[e.target.value as DateType],
    })
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div>
        {canSwitchChartType && (
          <Switch options={switchOptions} selected={chartType} onClick={(option) => onSwitchChartType(option as SwitchOptions)} />
        )}
      </div>
      <div className={`${rootClass}__date-selector-ctr`}>
        <Select value={state.dateType} onChange={handleDateChange} size={SelectSize.SMALL}>
          {DateTypes.map((dType: string) => (
            <option key={dType} value={dType}>
              {`By ${titleCase(dType)}`}
            </option>
          ))}
        </Select>
        <Typography text={t('for the last')} type={TextType.BODY_TEXT_SMALL_LIGHT} inline />
        <InputWithClear
          dataTest={`${dataTest}__input`}
          inputProps={{ ...inputProps }}
          onClear={handleQuantityClear}
          showClearOverride={state.showQuantityClear}
        />
        <Typography text={`${state.dateType}s`} type={TextType.BODY_TEXT_SMALL_LIGHT} inline />
      </div>
    </div>
  )
}

export default ChartExtraElement
