import { gql } from '@apollo/client'

export default gql`
  mutation deletePersonalization($key: String) {
    deletePersonalization(key: $key) {
      statusCode
      reasonPhrase
    }
  }
`
