import { ListPageCommonState, CustomSource } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import { Folder } from '@interface/Folder'
import { SortDirection } from '@utils/common'
import { createdByMeFilter, favoriteFilter, FilterDefinition, recentFilter, recommendedFilter } from '@utils/filter'
import { buildComplexFoldersTreeHeader } from '@utils/folderUtils'
import { SortingFieldType } from '@utils/types'

export enum FolderActions {
  RENAME = 'rename',
  DELETE = 'delete',
  ADD_SUBFOLDER = 'add subfolder',
}

export enum FOLDER_EMPTY_LISTING_OPTIONS {
  EMPTY_FOLDER = 'EMPTY_FOLDER',
}

export enum TagRowActions {
  DELETE = 'delete',
  EDIT = 'edit',
}

export enum MENU_ITEM_INDEX {
  FILTERS,
  CUSTOM_FILTERS,
  FOLDERS,
  TAGS,
}

export interface MenuActions {
  addFolder: () => void
  toggleSalesUsersControls: () => void
  clickFolder: (folderId: number) => void
  toggleSalesUsersEnabled: (folderId: number, isEnabled: boolean) => void
  clickFolderAction: (folder: FolderData, folderAction: FolderActions) => void
  clickFilter: (filter: FilterDefinition) => void
  clickSubType: (subType: string, toggleOffSubTypes?: string[]) => void
  clickCustomFilter: (filter: FilterDefinition) => void
  addTag: () => void
  clickTag: (tag: LabelDto) => void
  clickTagAction: (tag: LabelDto, tagAction: TagRowActions) => void
  dropItem: (folder: FolderData) => void
  selectCustomSource?: (selectedCustomSource: CustomSource) => void
}

export const defaultMenuActions: MenuActions = {
  addFolder: () => null,
  toggleSalesUsersControls: () => null,
  clickFolder: () => null,
  toggleSalesUsersEnabled: () => null,
  clickFolderAction: () => null,
  clickFilter: () => null,
  clickSubType: () => null,
  clickCustomFilter: () => null,
  addTag: () => null,
  clickTag: () => null,
  clickTagAction: () => null,
  dropItem: () => null,
}

export interface FilterQueryParams {
  type: string
  pageNumber: number
  pageSize: number
  direction: SortDirection
  orderBy: string
  value: string
  field: string
  fieldType: SortingFieldType
  subTypes: string[]
  [custom: string]: number | string | string[] | undefined
}

export const MAX_FOLDER_DEPTH = 3

export const DEFAULT_FILTERS = [favoriteFilter, createdByMeFilter, recommendedFilter, recentFilter]

export const isCustomFilterSelected = (allItemFilter: FilterDefinition, activeFilter?: FilterDefinition) => {
  if (!activeFilter) {
    return false
  }
  return ![allItemFilter, ...DEFAULT_FILTERS].some((defaultFilter) => defaultFilter.name === activeFilter.name)
}

export const setActiveFolder = (update: (fields: Partial<ListPageCommonState>) => void, folderId: number, folders: FolderData[]) => {
  const folderPath = buildComplexFoldersTreeHeader(folderId, folders)

  update({
    folderPath,
    activeFolderId: folderId,
    activeTagId: undefined,
    filterActive: undefined,
  })
}

export const deleteFolderById = (id: number, folders: Folder[]): Folder[] => {
  return folders.reduce((folders: Folder[], actualFolder) => {
    if (actualFolder.id !== id) {
      return actualFolder.subFolders?.length > 0
        ? [...folders, { ...actualFolder, subFolders: deleteFolderById(id, actualFolder.subFolders) }]
        : [...folders, actualFolder]
    }
    return folders
  }, [])
}

export const getFolderById = (folderId: number | null | undefined, folders: Folder[]): Folder | undefined => {
  if (typeof folderId !== 'number') {
    return undefined
  }

  for (const curFolder of folders) {
    const { id, subFolders } = curFolder
    if (id === folderId) {
      return curFolder
    }
    if (subFolders) {
      const folderInNested = getFolderById(folderId, subFolders)
      if (folderInNested) {
        return folderInNested
      }
    }
  }
  return undefined
}

export const getFlattenedSubFolderIds = (id: number, folders: Folder[] | null): number[] => {
  const parentFolder = getFolderById(id, folders ?? [])
  if (!parentFolder) {
    return []
  }

  if (!parentFolder.subFolders || !parentFolder.subFolders.length) {
    return [parentFolder.id]
  }

  const ids = parentFolder.subFolders.reduce(
    (prev: number[], folder) => {
      return [...prev, folder.id, ...getFlattenedSubFolderIds(folder.id, parentFolder.subFolders ?? [])]
    },
    [parentFolder.id]
  )

  return [...new Set(ids)]
}
