import React, { useContext } from 'react'
import { components, ContainerProps } from 'react-select'

import { SelectV2Context } from '@components/SelectV2/SelectV2.context'
import { SelectV2Option } from '@components/SelectV2/SelectV2.props'
import Tooltip from '@components/Tooltip/Tooltip'

const rootClass = 'custom-select-container'

const MAX_CHARS_BEFORE_TOOLTIP = 20

const CustomSelectContainer = (props: ContainerProps<SelectV2Option>) => {
  const { children } = props
  const {
    values: { containerTooltipMessage, selectedOption, renderSelectContainerWrapper, hideContainerTooltip },
  } = useContext(SelectV2Context)

  const renderSelectContainer = () => <components.SelectContainer {...props}>{children}</components.SelectContainer>

  const getLabel = () => {
    return selectedOption instanceof Array ? selectedOption.map((option) => option.label).join(', ') : selectedOption?.label
  }
  const isHidden = () => {
    const label = getLabel()
    if (hideContainerTooltip) {
      return true
    } else if (!label) {
      return true
    } else if (label.length < MAX_CHARS_BEFORE_TOOLTIP) {
      return true
    }

    return false
  }

  return (
    <Tooltip
      trigger={renderSelectContainerWrapper ? renderSelectContainerWrapper(renderSelectContainer()) : renderSelectContainer()}
      fullWidth
      ellipOnTrigger
      hide={isHidden()}
      className={rootClass}
    >
      {containerTooltipMessage ?? getLabel()}
    </Tooltip>
  )
}

export default CustomSelectContainer
