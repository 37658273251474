import React, { FC, useCallback, useContext, useMemo } from 'react'

import { TextType } from '@components/Typography/Typography'
import { EmailComposerContext, ValidationSectionType } from '@utils/composer/context/EmailComposer.context'
import { useTranslation } from '@utils/const/globals'

import ValidationSection from '../ValidationSection/ValidationSection'
import ValidationsList, { Validation } from '../ValidationsList/ValidationsList'

import './AntiSpamValidations.css'

import { CommonComposerTab } from '@utils/composer/commonComposer/CommonComposer.context'

interface AntiSpamValidationsProps {
  validations: Validation[]
  isInReview?: boolean
}

const rootClass = 'anti-spam-validations'

const AntiSpamValidations: FC<AntiSpamValidationsProps> = ({ validations, isInReview }) => {
  const {
    values: {
      validations: { isContentMissing, isValidationsLoading },
      messageConfiguration: {
        reviewAndSend: { disableEditings },
      },
    },
    api: { onTabChange, onStatusChange },
  } = useContext(EmailComposerContext)

  const { t } = useTranslation()

  const handleBack = useCallback(() => onTabChange(CommonComposerTab.DESIGN), [onTabChange])

  const renderValidations = useMemo(() => !isContentMissing, [isContentMissing])

  return renderValidations ? (
    isInReview ? (
      <ValidationSection
        type={ValidationSectionType.ANTI_SPAM}
        title="EmailComposer.Preview.Validations.Anti.Spam.Title"
        subTitle={
          validations.length === 0 && !isValidationsLoading
            ? { text: t('EmailComposer.Preview.Validations.Anti.Spam.Desc'), type: TextType.BODY_TEXT_LIGHT }
            : undefined
        }
        buttonLabel="EmailComposer.Preview.Validations.Edit.Button"
        validations={validations}
        tooltipContentKey={disableEditings ? t('EmailComposer.Resend.Disabled') : ''}
        isLoading={isValidationsLoading}
        onClick={handleBack}
        disableEditBtn={disableEditings}
        onStatusChange={onStatusChange}
      />
    ) : (
      <div className={rootClass}>{!isContentMissing && !isValidationsLoading && <ValidationsList validations={validations} />}</div>
    )
  ) : null
}

export default AntiSpamValidations
