import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import ButtonWithLoader from '@components/ButtonWithLoader/ButtonWithLoader'
import { YesNo } from '@components/ConfirmationModal'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import Input from '@components/Input/Input'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import TextArea from '@components/TextArea/TextArea'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { InputContentMapping, InputDefinition } from '@graphql/types/microservice/webhooks-incoming-management-types'
import { yupResolver } from '@hookform/resolvers/yup'
import IncomingWebhookAuthentication from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/components/IncomingWebhookAuthentication/IncomingWebhookAuthentication'
import IncomingWebhookContentMapping from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/components/IncomingWebhookContentMapping/IncomingWebhookContentMapping'
import WebhookSourceSelector from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/components/WebhookSourceSelector/WebhookSourceSelector'
import {
  buildSignature,
  buildWebhook,
  createIncomingWebhookValidators,
  parseIncomingWebhookToInputDefinition,
} from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/utils/CreateIncomingWebhookModal.utils'
import { useIncomingWebhooksRequests } from '@src/pages/Webhooks/IncomingWebhooks/GraphQL/IncomingWebhooks.graphQL'
import {
  AuthenticationType,
  IncomingWebhook,
  IncomingWebhooksContext,
  InputSignature,
  SignatureTemplateList,
  Source,
} from '@src/pages/Webhooks/IncomingWebhooks/IncomingWebhooksContext'

import './CreateIncomingWebhookModal.css'

export interface OnWebhookCreatedParams {
  triggerTest: boolean
  toastMessage?: string
  webhookId?: string
}

interface CreateIncomingWebhookModalProps {
  className?: string
  dataTest?: string
  onCancel: () => void
  onWebhookCreated: (params: OnWebhookCreatedParams) => void
  webhook?: IncomingWebhook
  isOpen: boolean
}

interface CreateIncomingWebhookModalState {
  loading: boolean
  showCancelConfirmation: boolean
}

const defaultIncomingWebhook: InputDefinition = {
  enabled: false,
  tested: false,
  name: '',
  description: '',
  channel: undefined,
  contentMappings: [{ destinationField: '', sourceField: '', mappingFunction: '', mappingFunctionParams: '', mappingMetadata: '' }],
  source: Source.Custom,
  authentication: {
    type: AuthenticationType.None,
  },
}

const defaultCreateIncomingWebhookModalState: CreateIncomingWebhookModalState = {
  loading: false,
  showCancelConfirmation: false,
}

const rootClass = 'create-incoming-webhook-modal'

const CreateIncomingWebhookModal: FC<CreateIncomingWebhookModalProps> = (props: CreateIncomingWebhookModalProps) => {
  const { dataTest = rootClass, className = '', onWebhookCreated, onCancel, isOpen, webhook: webhookToEdit } = props

  const {
    values: { enableContentMapping, signatureTemplateList },
    update,
  } = useContext(IncomingWebhooksContext)

  const [state, setState] = useState<CreateIncomingWebhookModalState>(defaultCreateIncomingWebhookModalState)
  const { loading, showCancelConfirmation } = state

  const { createWebhook, updateWebhookTestedStatus } = useIncomingWebhooksRequests()

  const { t } = useTranslation()

  const formMethods = useForm<InputDefinition>({
    defaultValues: defaultIncomingWebhook,
    resolver: yupResolver(createIncomingWebhookValidators),
    mode: 'onBlur',
  })

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors, isValid, isDirty, dirtyFields },
  } = formMethods

  const webhookToEditInputDefinition = useMemo<InputDefinition | undefined>(() => {
    if (webhookToEdit) {
      return parseIncomingWebhookToInputDefinition(webhookToEdit)
    }
  }, [webhookToEdit])

  const webhook = { ...(webhookToEditInputDefinition ?? defaultIncomingWebhook), ...watch() }
  const { source, authentication, channel, contentMappings, tested, webhookId } = webhook

  const handleClose = () => {
    if (isDirty) {
      setState((state) => ({ ...state, showCancelConfirmation: true }))
    } else {
      onCancel()
    }
  }

  const onConfirmationModalAnswer = (answer: YesNo) => {
    if (answer === YesNo.YES) {
      onCancel()
    }
    setState((state) => ({ ...state, showCancelConfirmation: false }))
  }

  const onCreateWebhook = () => {
    if (!loading) {
      setState((state) => ({ ...state, loading: true }))
      let signature = undefined
      if (authentication?.signature) {
        signature = buildSignature(source, signatureTemplateList as SignatureTemplateList, authentication?.signature as InputSignature)
      }
      const webhookInputDefinition = buildWebhook(
        webhook,
        (contentMappings as InputContentMapping[]) ?? [],
        signature,
        !!enableContentMapping,
        dirtyFields
      )
      createWebhook(webhookInputDefinition)
        .then(async ({ data }) => {
          const shouldTestAgain = Object.entries(dirtyFields).some(([field, isDirty]) => isDirty && field !== 'name' && field !== 'description')
          if (webhookId && tested && shouldTestAgain) {
            await updateWebhookTestedStatus(webhookId, false)
          }
          onWebhookCreated({ triggerTest: !webhookToEdit || shouldTestAgain, webhookId: data?.saveWebhookDefinition?.body })
        })
        .catch(({ message }) => {
          onWebhookCreated({ triggerTest: false, toastMessage: message })
        })
        .finally(() => {
          setState((state) => ({ ...state, loading: false }))
          onCancel()
        })
    }
  }

  useEffect(() => {
    if (!isOpen) {
      setState(defaultCreateIncomingWebhookModalState)
      reset(defaultIncomingWebhook)
    }
  }, [isOpen])

  useEffect(() => {
    if (enableContentMapping && channel === undefined) {
      setValue('contentMappings', [{ destinationField: '', sourceField: '', mappingFunction: '', mappingFunctionParams: '', mappingMetadata: '' }])
    }
  }, [enableContentMapping])

  useEffect(() => {
    if (webhookToEditInputDefinition) {
      if (!!webhookToEditInputDefinition.authentication?.type && webhookToEditInputDefinition.authentication?.type !== AuthenticationType.None) {
        update({ enableAuthentication: true })
      }
      if (webhookToEditInputDefinition.contentMappings?.some((mapping) => !!mapping?.sourceField)) {
        update({ enableContentMapping: true })
      }
      reset(webhookToEditInputDefinition)
    }
  }, [webhookToEditInputDefinition, isOpen])

  const onSourceChange = (source: Source) => {
    setValue('source', source, { shouldValidate: true, shouldDirty: true })
  }

  const header = (
    <ModalHeader headerType={ModalHeaderType.List} className={`${rootClass}__header`}>
      {t(`Incoming.Webhooks.CreateModal.${webhookToEdit ? 'EditHeader' : 'CreateHeader'}`)}
    </ModalHeader>
  )

  return (
    <FormProvider {...formMethods}>
      <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
        {showCancelConfirmation && isDirty && (
          <DeleteConfirmationModal
            isOpen
            title={t('Are you sure?')}
            cancelButtonText={t('Continue editing')}
            deleteButtonText={t('Yes, cancel')}
            body={
              <Typography
                text={'If you cancel now, you’ll lose all unsaved changes. You cannot undo this action.'}
                type={TextType.BODY_TEXT_LIGHT}
                tagProps={{ bold: { weight: TextWeight.BOLD } }}
                inline
              />
            }
            onAnswer={onConfirmationModalAnswer}
          />
        )}
        <ModalBody className={classNames(`${rootClass}__body`, { [`${rootClass}__body-loading`]: loading })}>
          <div className={`${rootClass}__details`}>
            <div className={`${rootClass}__details-name`}>
              <Input
                dataTest={`${dataTest}-name-input`}
                register={register('name')}
                placeholder={t('Incoming.Webhooks.CreateModal.Input.Placeholder')}
                label={
                  <Typography
                    text={t('Incoming.Webhooks.CreateModal.Input.Label')}
                    tagProps={{ bold: { weight: TextWeight.MEDIUM, type: TextType.BODY_TEXT } }}
                    type={TextType.BODY_TEXT_LIGHT}
                    inline
                  />
                }
                error={!!errors?.name}
              />
              {errors?.name && (
                <div className={`${rootClass}__details-name-error`}>
                  <Svg name={SvgNames.inputStatusInvalidNoFill} type={SvgType.ICON} />
                  <Typography type={TextType.ERROR} text={t(errors?.name.message)} lineHeight={LineHeight.MEDIUM_SMALL} />
                </div>
              )}
            </div>
            <div className={`${rootClass}__details-description`}>
              <TextArea
                dataTest={`${dataTest}-description-input`}
                name={'description'}
                register={register('description')}
                placeholder={t('Incoming.Webhooks.CreateModal.TextArea.Placeholder')}
                label={<Typography text={t('Description')} weight={TextWeight.MEDIUM} inline />}
              />
            </div>
            <WebhookSourceSelector
              dataTest={`${dataTest}-source-selector`}
              className={`${rootClass}__details-source`}
              source={source}
              onChange={onSourceChange}
              disabled={!!webhookToEdit}
            />
          </div>
          <IncomingWebhookAuthentication
            dataTest={`${dataTest}-authentication`}
            className={`${rootClass}__authentication`}
            authentication={authentication}
          />
          {source === Source.Custom && (
            <IncomingWebhookContentMapping dataTest={`${dataTest}-content-mapping`} className={`${rootClass}__content-mapping`} channel={channel} />
          )}
        </ModalBody>
        <ModalFooter footerType={ModalFooterType.List} className={`${rootClass}__footer`}>
          <Button buttonType={ButtonType.TERTIARY} onClick={handleClose} dataTest={`${dataTest}-cancel-button`} disabled={loading}>
            {t('Cancel')}
          </Button>
          <ButtonWithLoader
            className={webhookToEdit ? `${rootClass}__edit-button` : `${rootClass}__create-button`}
            dataTest={`${dataTest}-create-button`}
            onClick={handleSubmit(onCreateWebhook)}
            disabled={!isValid || !isDirty || (enableContentMapping && !channel)}
            loading={loading}
          >
            {t(webhookToEdit ? 'Save changes' : 'Create')}
          </ButtonWithLoader>
        </ModalFooter>
      </Modal>
    </FormProvider>
  )
}

export default CreateIncomingWebhookModal
