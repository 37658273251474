import { gql } from '@apollo/client'

export default gql`
  query getCustomAccountSettingsPage {
    getCustomAccountSettingsPage {
      supportedEncodings
      clickthroughPersonalizerEncoding
      cacheBuster
      staticDomain
      isAdministrator
      clientID
      clientSecret
      customPersonalizations {
        key
        value
        accessor
      }
      brokenClickthroughPersonalization {
        url
        display
        urlLinkDisplay
      }
      unpublishedLandingPage {
        url
        display
        urlLinkDisplay
      }
    }
  }
`
