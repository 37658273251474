import { isTableV2RowData } from '@components/TableV2/tableV2TS/interfaces'
import { RowSelectionState } from '@tanstack/react-table'

export const TABLEV2_CELL_PADDING = 24

export const getRowSelectionState = <T extends { id?: string }>(selectedItems: T[]): RowSelectionState => {
  return selectedItems.reduce((selectionState: RowSelectionState, { id = '' }) => ({ ...selectionState, [id]: true }), {})
}

export const mapToTableKeysObject = (arr?: string | string[]) => {
  const keys = Array.isArray(arr) ? arr : arr?.split(',')
  return keys?.reduce<Record<string, boolean>>((prev, curr) => {
    prev[curr] = true
    return prev
  }, {})
}

export const flattenSubRows = <T extends {}>(allData: T[], depth = 0, maxSubRowDepth = 10) => {
  if (depth > maxSubRowDepth) {
    return []
  }
  const result = allData.flatMap((item) => {
    if (isTableV2RowData<T>(item) && item.subRows !== undefined) {
      return [item, ...flattenSubRows(item.subRows, depth + 1, maxSubRowDepth)]
    }
    return [item]
  }) as T[]
  return result
}
