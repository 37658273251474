import React, { FC, useContext, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import classNames from 'classnames'

import { MergeStrategy } from '@complex/ImportContacts/ImportContacts.constants'
import Button, { ButtonType } from '@components/Button'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import { renderLoader } from '@components/Loader/Loader'
import { rootContext, useTranslation } from '@const/globals'
import ReviewImportAdvancedOptions from '@src/pages/ImportContactsV2/components/ReviewImport/components/ReviewImportAdvancedOptions/ReviewImportAdvancedOptions'
import ReviewImportOptions from '@src/pages/ImportContactsV2/components/ReviewImport/components/ReviewImportOptions/ReviewImportOptions'
import ReviewImportSummary from '@src/pages/ImportContactsV2/components/ReviewImport/components/ReviewImportSummary/ReviewImportSummary'
import { useImportContactsV2Requests } from '@src/pages/ImportContactsV2/GraphQL/ImportContactsV2Request.graphQL'
import { ImportContactsSteps } from '@src/pages/ImportContactsV2/utils/ImportContactsV2.constants'
import { ImportContactsV2Context } from '@src/pages/ImportContactsV2/utils/ImportContactsV2.context'

import './ReviewImport.css'

interface ReviewImportProps {
  className?: string
  dataTest?: string
}

enum ImportConfirmationAction {
  IMPORT_NOW = 'IMPORT_NOW',
  IMPORT_LATER = 'IMPORT_LATER',
  DO_NOTHING = 'DO_NOTHING',
}

const rootClass = 'review-import'

const ReviewImport: FC<ReviewImportProps> = (props: ReviewImportProps) => {
  const { dataTest = rootClass, className = '' } = props

  const {
    handleOnSectionChange,
    onError,
    updateImportDefinition,
    updateImportSyncJob,
    update,
    values: { schedule, selectedFile, importDefinitionId, importSyncJob, mergeStrategy, mappingPreview },
  } = useContext(ImportContactsV2Context)

  const [isSaving, setIsSaving] = useState(false)
  const [isShowingConfirmationModal, setIsShowingConfirmationModal] = useState(false)

  const isNewSyncRef = useRef(false)

  const canSaveChanges = useMemo(() => {
    return (
      (mergeStrategy === MergeStrategy.Email && mappingPreview.some((mapping) => mapping.dataType === 'EMAIL')) ||
      mergeStrategy === MergeStrategy.ExternalId
    )
  }, [mappingPreview, mergeStrategy])

  const history = useHistory()

  const { createImportSyncJobRequest } = useImportContactsV2Requests({ selectedFile, importDefinitionId, schedule, importSyncJob })

  const { t } = useTranslation()

  const doUpdateImportDefinition = async (): Promise<boolean> => {
    const importDefinition = await updateImportDefinition()
    return !!importDefinition
  }

  const doUpdateImportSyncJob = async (hasToRunNow = true): Promise<boolean> => {
    if (importSyncJob !== undefined) {
      const importSyncJob = await updateImportSyncJob({ hasToRunNow })
      update({ importedNow: hasToRunNow })
      return !!importSyncJob
    } else {
      isNewSyncRef.current = true
      const { data, errors } = await createImportSyncJobRequest({ hasToRunNow })
      if (data?.createImportSyncJob && !errors) {
        update({
          importedNow: hasToRunNow,
          importSyncJob: data.createImportSyncJob,
        })
        return true
      } else {
        onError(errors)
        return false
      }
    }
  }

  const doImport = async (hasToRunNow = true): Promise<boolean> => {
    setIsSaving(true)
    let isSaveSuccessful = false
    const isImportDefinitionUpdateSuccessful = await doUpdateImportDefinition()
    if (isImportDefinitionUpdateSuccessful) {
      isSaveSuccessful = await doUpdateImportSyncJob(hasToRunNow)
    }
    setIsSaving(false)
    return isSaveSuccessful
  }

  const onConfirmationModalAnswer = async (action: ImportConfirmationAction) => {
    setIsShowingConfirmationModal(false)
    if (action === ImportConfirmationAction.IMPORT_NOW) {
      await doImport(true)
    }
    if (action !== ImportConfirmationAction.DO_NOTHING && isNewSyncRef.current) {
      handleOnSectionChange(ImportContactsSteps.IMPORT_IN_PROGRESS, true)
    } else {
      history.push(`${rootContext}/segments`)
    }
  }

  const onSaveClick = async () => {
    const hasBeenImported = await doImport(false)
    setIsShowingConfirmationModal(hasBeenImported)
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {isSaving && renderLoader('loader--white-background')}
      <ConfirmationModal
        isOpen={isShowingConfirmationModal}
        title={t('Do you want to import now?')}
        body={t(
          schedule.isScheduled
            ? 'Your import settings have been saved. Do you want to import your contacts now or wait until the next scheduled sync?'
            : 'Your import settings have been saved, but no sync is scheduled. Would you like to import contacts now?'
        )}
        isYesNo
        yesButtonText={t('Import now')}
        onAnswer={(answer) =>
          onConfirmationModalAnswer(answer === YesNo.YES ? ImportConfirmationAction.IMPORT_NOW : ImportConfirmationAction.DO_NOTHING)
        }
        {...(schedule.isScheduled
          ? {
              footerActionElement: (
                <Button buttonType={ButtonType.INFO} onClick={() => onConfirmationModalAnswer(ImportConfirmationAction.IMPORT_LATER)}>
                  {t('Import later')}
                </Button>
              ),
            }
          : {})}
      />
      <ReviewImportSummary dataTest={`${dataTest}-summary`} />
      <ReviewImportOptions dataTest={`${dataTest}-options`} />
      <ReviewImportAdvancedOptions dataTest={`${dataTest}-advanced-options`} />
      <Button buttonType={ButtonType.PRIMARY} className={`${rootClass}__save-button`} onClick={onSaveClick} disabled={!canSaveChanges}>
        {t('Save')}
      </Button>
    </div>
  )
}

export default ReviewImport
