import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { useImagePickerModalContentContext } from '@components/AssetPickers/ImagePickerModal/context/ImagePickerModalContent.context'
import ImageMediaCard from '@components/ImageMediaCard/ImageMediaCard'
import Spinner, { LoaderSize } from '@components/Spinner/Spinner'

import { convertToBytes, getIsUnsupportedFileType, getTooltipText } from '../../utils/ImagePickerModal.utils'

const rootClass = 'image-picker-modal-content__grid'

const ImagePickerModalGridView: FC<{ dataTest?: string }> = ({ dataTest = rootClass }) => {
  const { imageItems, loading, onSelect, onPreview, selectedId, isLogos, imageMaxSize, accept } = useImagePickerModalContentContext()
  const { t } = useTranslation()

  if (loading) {
    return <Spinner size={LoaderSize.MEDIUM} className={`${rootClass}__spinner`} dataTest={`${dataTest}-spinner`} />
  }

  return (
    <div
      className={classNames(rootClass, {
        [`${rootClass}-less-3-items`]: imageItems.length < 3,
      })}
      data-test={dataTest}
    >
      {imageItems.map((item) => {
        const { title, url, dimensions, id, thumbNailUrl, size } = item

        const maxSizeExedeed = imageMaxSize ? imageMaxSize <= convertToBytes(size ?? '') : false

        const isUnsupportedFileType = accept ? getIsUnsupportedFileType(accept, url) : false

        const isDisabled = maxSizeExedeed || isUnsupportedFileType

        const isSelected = selectedId === id
        return (
          <ImageMediaCard
            key={id}
            imageSrc={thumbNailUrl ?? url}
            title={title}
            onPreview={() => onPreview(item)}
            onSelect={() => onSelect(item)}
            description={dimensions}
            isSelected={isSelected}
            imageFill={!isLogos}
            dataTest={`${dataTest}-card-${id}`}
            isDisabled={isDisabled}
            disabledTooltip={isDisabled ? getTooltipText(maxSizeExedeed, isUnsupportedFileType, accept, imageMaxSize, t) : undefined}
          />
        )
      })}
    </div>
  )
}

export default ImagePickerModalGridView
