import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Container from '@components/Container'
import { LinkTextButton } from '@components/LinkTextButton/LinkTextButton'
import StaticImage from '@components/StaticImage/StaticImage'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { LineHeight, TextAlign, TextType, TextWeight } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import UpgradedFormsSummaryModal from '@src/pages/UpgradeAssistant/components/FormsUpgradeModal/components/FormsUpgradeConfirmation/components/UpgradedFormsSummaryModal/UpgradedFormsSummaryModal'
import { ArchiveSettings, FormsUpgradeModalContext } from '@src/pages/UpgradeAssistant/components/FormsUpgradeModal/context/FormsUpgradeModal.context'
import { useFormsUpgradeModalRequests } from '@src/pages/UpgradeAssistant/components/FormsUpgradeModal/GraphQL/FormsUpgradeModalRequests.graphQL'

import './FormsUpgradeConfirmation.css'

interface FormsUpgradeConfirmationProps {
  className?: string
  dataTest?: string
  onClose: VoidFunction
  onReset: VoidFunction
}

const rootClass = 'forms-upgrade-confirmation'
const FORMS_UPGRADE_CONFIRMATION_TEXT = 'Forms.Upgrade.Confirmation'
const LEARN_MORE_LINK =
  'https://success.act-on.com/acton/attachment/9883/u-feec7d98-37ea-460d-9146-2b174c8db71b/0/-/-/-/-/in%20App%3A%20%20Testing%20forms'
const UPGRADE_ASSISTANT_URL = `${rootContext}/upgradeAssistant`

const FormsUpgradeConfirmation: FC<FormsUpgradeConfirmationProps> = (props: FormsUpgradeConfirmationProps) => {
  const { dataTest = rootClass, className = '', onClose, onReset } = props

  const {
    values: { archiveSetting, createSegment, formFields, forms },
  } = useContext(FormsUpgradeModalContext)

  const [isUpgrading, setIsUpgrading] = useState(true)
  const [showSummary, setShowSummary] = useState(false)

  const { t } = useTranslation()

  const history = useHistory()

  const { archiveLegacyListsRequest, deleteLegacyListsRequest, upgradeFormsRequest } = useFormsUpgradeModalRequests()

  const testSteps = useMemo(() => Array.from({ length: 5 }).map((_, index) => t(`${FORMS_UPGRADE_CONFIRMATION_TEXT}.TestSteps.${index + 1}`)), [t])
  const upgradeRequestedRef = useRef(false)

  const onShowSummaryChange = useCallback(() => setShowSummary(!showSummary), [showSummary])

  const onBackToUpgradeAssistant = useCallback(() => {
    if (history.location.pathname === UPGRADE_ASSISTANT_URL) {
      onClose()
    } else {
      history.push(UPGRADE_ASSISTANT_URL)
    }
  }, [history])

  const handleLegacyListsOnSave = (listIds: string[]) => {
    switch (archiveSetting) {
      case ArchiveSettings.ARCHIVE:
        return archiveLegacyListsRequest(listIds)
      case ArchiveSettings.DELETE:
        return deleteLegacyListsRequest(listIds)
      default:
        return Promise.resolve()
    }
  }

  const upgradeForms = async () => {
    const formIds = forms.map(({ externalId = '' }) => externalId)
    const { data } = await upgradeFormsRequest({ formFields, formIds, createSegment })
    if (data) {
      const listIdsWithoutDuplicates = [...new Set(forms.map(({ listId }) => listId))]
      await handleLegacyListsOnSave(listIdsWithoutDuplicates)
    }
    setIsUpgrading(false)
  }

  useEffect(() => {
    if (isUpgrading && !upgradeRequestedRef.current) {
      upgradeForms()
      upgradeRequestedRef.current = true
    }
  }, [])

  const renderStatus = ({ image, headline, text, onClick }: { image: StaticImageNames; headline: string; text: string; onClick?: VoidFunction }) => (
    <div className={`${rootClass}__status`}>
      <StaticImage name={image} />
      <Typography type={TextType.PAGE_HEADLINE} text={t(headline, { count: forms.length })} />
      <Typography
        textAlign={TextAlign.CENTER}
        text={text}
        type={TextType.BODY_TEXT_LIGHT}
        tagProps={{ bold: { weight: TextWeight.BOLD } }}
        tagComponents={{ Link: <LinkTextButton onClick={onShowSummaryChange} hideIconLeft hideIconRight /> }}
        inline
      />
      {onClick && (
        <Button buttonType={ButtonType.PRIMARY} onClick={onClick}>
          {t(`${FORMS_UPGRADE_CONFIRMATION_TEXT}.Button.Primary`)}
        </Button>
      )}
      <Button buttonType={ButtonType.SECONDARY} onClick={onBackToUpgradeAssistant}>
        <Svg name={SvgNames.arrowLeft} type={SvgType.ICON} />
        {t(`${FORMS_UPGRADE_CONFIRMATION_TEXT}.Button.Secondary`)}
      </Button>
    </div>
  )

  const renderDoneStatus = () =>
    renderStatus({
      headline: `${FORMS_UPGRADE_CONFIRMATION_TEXT}.Done.Headline`,
      image: StaticImageNames.emptyCelebrateSmall,
      text: t(`${FORMS_UPGRADE_CONFIRMATION_TEXT}.Done.Text`, { count: forms.length }),
      onClick: onReset,
    })

  const renderUpgradingStatus = () =>
    renderStatus({
      headline: `${FORMS_UPGRADE_CONFIRMATION_TEXT}.Upgrading.Headline`,
      image: StaticImageNames.upgradingAsset,
      text: t(`${FORMS_UPGRADE_CONFIRMATION_TEXT}.Upgrading.Text`),
    })

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <UpgradedFormsSummaryModal forms={forms} onClose={onShowSummaryChange} isOpen={showSummary} />
      <Container className={`${rootClass}__status-container`}>{isUpgrading ? renderUpgradingStatus() : renderDoneStatus()}</Container>
      {!isUpgrading && (
        <Container>
          <div className={`${rootClass}__steps-top-section`}>
            <Typography text={t(`${FORMS_UPGRADE_CONFIRMATION_TEXT}.TestSteps.Header`)} type={TextType.NORMAL_TEXT_TEAL} weight={TextWeight.BOLD} />
            <Typography
              text={t(`${FORMS_UPGRADE_CONFIRMATION_TEXT}.TestSteps.Title`)}
              type={TextType.MODAL_HEADLINE_LARGE}
              weight={TextWeight.MEDIUM}
              lineHeight={LineHeight.LARGER}
            />
            <Typography text={t(`${FORMS_UPGRADE_CONFIRMATION_TEXT}.TestSteps.Body`)} type={TextType.BODY_TEXT_LIGHT} />
          </div>
          <div className={`${rootClass}__steps-bottom-section`}>
            <div className={`${rootClass}__steps-list`}>
              {testSteps.map((step, index) => (
                <Typography
                  key={index}
                  text={`${index + 1}. ${step}`}
                  tagProps={{ bold: { weight: TextWeight.BOLD } }}
                  type={TextType.BODY_TEXT_LARGE}
                  weight={TextWeight.MEDIUM}
                  lineHeight={LineHeight.MEDIUM_LARGE}
                  inline
                />
              ))}
            </div>
            <LinkTextButton
              className={`${rootClass}__steps-link`}
              text={t(`${FORMS_UPGRADE_CONFIRMATION_TEXT}.LearnMoreAbout`)}
              link={LEARN_MORE_LINK}
              hideIconLeft
            />
          </div>
        </Container>
      )}
    </div>
  )
}

export default FormsUpgradeConfirmation
