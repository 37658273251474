import { SubscriptionManagementDTO, VersionState } from '@src/pages/SubscriptionManagement/SubcriptionManagement.constants'

export const dataMock: SubscriptionManagementDTO[] = [
  {
    name: 'Danish',
    description: 'Default Subscription Management page',
    version: 'da',
    created: 1716315273000,
    status: VersionState.ADDED,
    default: true,
    isEnabled: false,
  },
  {
    name: 'Bulgarian',
    description: 'Act-On’s subscription management in Bulgarian',
    version: 'bg',
    created: 1708539273000,
    status: VersionState.DISABLED,
    default: false,
    isEnabled: true,
  },
  {
    name: 'Afrikaans',
    description: 'Act-On’s subscription management in Afrikaans',
    version: 'af',
    created: 1645800820979,
    status: VersionState.ENABLED,
    default: false,
    isEnabled: false,
  },
  {
    name: 'Chinese (Simplified)',
    description: 'Act-On’s subscription management in Chinese (Simplified)',
    version: 'zh-CN',
    created: 1645800820979,
    status: VersionState.ENABLED,
    default: false,
    isEnabled: true,
  },
]

export const languagesVersionsOptions: { value: string; label: string }[] = [
  { value: 'af', label: 'Afrikaans' },
  { value: 'ar', label: 'Arabic' },
  { value: 'bg', label: 'Bulgarian' },
  { value: 'ca', label: 'Catalan' },
  { value: 'zh-CN', label: 'Chinese (Simplified)' },
  { value: 'zh-TW', label: 'Chinese (Traditional)' },
  { value: 'hr', label: 'Croatian' },
  { value: 'cs', label: 'Czech' },
  { value: 'da', label: 'Danish' },
]
