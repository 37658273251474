import React, { FC, useContext, useEffect, useState } from 'react'

import classNames from 'classnames'

import { PreProcessedList } from '@complex/ListPickerModalV2/utils/interfaces/ListPickerModalInterfaces'
import { ListPickerModalContext } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import { BreadcrumbsItemType } from '@components/Breadcrumbs/Breadcrumbs'
import BreadcrumbsHoverCard from '@components/BreadcrumbsHoverCard/BreadcrumbsHoverCard'
import { ListCategory } from '@utils/lists'

import './SegmentTree.css'

interface SegmentTreeProps {
  segmentId: string
  listType?: ListCategory
}

const rootClass = 'segment-tree'

const SegmentTree: FC<SegmentTreeProps> = ({ segmentId, listType }: SegmentTreeProps) => {
  const {
    values: { search },
    setState,
  } = useContext(ListPickerModalContext)
  const { parentsHierarchy } = search ?? {}
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbsItemType[]>([])

  const setSegmentTreePage = (segment: PreProcessedList) => setState((state) => ({ ...state, segmentTreePage: { segment, listType } }))

  useEffect(() => {
    if (parentsHierarchy && parentsHierarchy[segmentId]) {
      const hierarchy = parentsHierarchy[segmentId]
      setBreadcrumbs(() =>
        hierarchy
          .filter((node) => !!node)
          .map((node, index) => ({
            text: node.name,
            onClick: () => {
              setSegmentTreePage(node)
            },
            inDropDown: hierarchy?.length > 4 && index > 1 && index < hierarchy?.length - 2,
            hasTooltip: true,
          }))
      )
    }
  }, [segmentId])

  const breadcrumbsOutsideDropDown = breadcrumbs.filter(({ inDropDown }) => !inDropDown).length
  const breadcrumbsInDropDown = breadcrumbs.filter(({ inDropDown }) => inDropDown).length

  return (
    <BreadcrumbsHoverCard
      className={classNames(rootClass, {
        [`${rootClass}-one`]: breadcrumbsOutsideDropDown === 1,
        [`${rootClass}-two`]: breadcrumbsOutsideDropDown === 2,
        [`${rootClass}-three`]: breadcrumbsOutsideDropDown === 3,
        [`${rootClass}-four`]: breadcrumbsOutsideDropDown === 4 && breadcrumbsInDropDown === 0,
        [`${rootClass}-five`]: breadcrumbsOutsideDropDown === 4 && breadcrumbsInDropDown > 0,
      })}
      breadcrumbs={breadcrumbs}
      dropDownPositionIndex={breadcrumbs.length > 4 ? 2 : undefined}
    />
  )
}

export default SegmentTree
