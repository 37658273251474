import React, { FC, useContext } from 'react'
import { useFormContext } from 'react-hook-form'

import { InputDefinition } from '@graphql/types/microservice/webhooks-incoming-management-types'
import IncomingWebhookToggleSection from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/components/IncomingWebhookToggleSection/IncomingWebhookToggleSection'
import { IncomingWebhooksContext } from '@src/pages/Webhooks/IncomingWebhooks/IncomingWebhooksContext'

const rootTranslation = 'Incoming.Webhooks.CreateModal.ContentMapping.ToggleSection'

const IncomingWebhookContentMappingToggleSection: FC = () => {
  const {
    values: { enableContentMapping = false, webhookToEdit },
    update,
  } = useContext(IncomingWebhooksContext)
  const { setValue } = useFormContext<InputDefinition>()

  const onToggle = (toggle: boolean) => {
    update({ enableContentMapping: toggle })
    !toggle && setValue('channel', undefined, { shouldDirty: true, shouldValidate: true, shouldTouch: true })
  }

  return (
    <IncomingWebhookToggleSection
      isToggleOn={enableContentMapping}
      disabled={webhookToEdit?.contentMappings.some((mapping) => !!mapping.sourceField)}
      onToggle={onToggle}
      rootTranslation={rootTranslation}
    />
  )
}

export default IncomingWebhookContentMappingToggleSection
