import React, { FC, useMemo } from 'react'

import classNames from 'classnames'

import SelectV2 from '@components/SelectV2/SelectV2'
import { useTranslation } from '@const/globals'
import DateTimeframes from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/DateTimeframes/DateTimeframes'
import { IgnoreYearPopoverProps } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/IgnoreYearPopover/IgnoreYearPopover'
import { getDateConditionOptions, TextEmptyComparison, TextNotEmptyComparison } from '@src/pages/SegmentComposer/SegmentComposer.constants'

export interface DateCriteriaType {
  criteria?: string
  // TODO: it will have more properties in the future
}
interface DateCriteriaProps {
  className?: string
  dataTest?: string
  dateCriteria: DateCriteriaType
  onChange: (dateCriteria: DateCriteriaType) => void
  ignoreYearProps?: IgnoreYearPopoverProps
}

const rootClass = 'date-criteria'
const defaultDateConditionIndex = 0

const DateCriteria: FC<DateCriteriaProps> = (props: DateCriteriaProps) => {
  const { dataTest = rootClass, className = '', dateCriteria, onChange, ignoreYearProps } = props
  const { t } = useTranslation()
  const dateConditionOptions = getDateConditionOptions(t)
  const { criteria = dateConditionOptions[defaultDateConditionIndex] } = dateCriteria

  const criteriaOption = useMemo(
    () => dateConditionOptions.find((option) => option.value === criteria) || dateConditionOptions[defaultDateConditionIndex],
    [criteria, dateConditionOptions]
  )

  const renderNextCondition = () => {
    const { value } = criteriaOption
    if (value in TextEmptyComparison || value in TextNotEmptyComparison) {
      return null
    }
    return <DateTimeframes comparison={criteriaOption} ignoreYearProps={ignoreYearProps} />
  }

  return (
    <>
      <SelectV2
        className={classNames(`${rootClass}__date-criteria-select`, className)}
        dataTest={`${dataTest}-criteria-select`}
        options={dateConditionOptions}
        value={criteriaOption}
        onChange={(criteria) => onChange({ ...dateCriteria, criteria: criteria?.value })}
        isClearable={false}
        isSearchable={false}
      />
      {renderNextCondition()}
    </>
  )
}

export default DateCriteria
