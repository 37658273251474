import { gql } from '@apollo/client'

export default gql`
  mutation setCreatorTimezone($timezone: String) {
    setCreatorTimezone(timezone: $timezone) {
      statusCode
      reasonPhrase
    }
  }
`
