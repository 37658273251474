import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Loader from '@components/Loader/Loader'
import PageError from '@components/PageError'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { useTranslation } from '@const/globals'
import { Folder } from '@interface/foldersLists/Folder'
import { getFolderName } from '@utils/folders'
import { ListCategory } from '@utils/lists'
import { getCountDisplay } from '@utils/numbers'

import './folderListPicker.css'
const rootClass = 'folder-list-picker'

export interface FoldersState {
  folders?: Folder[]
  loading?: boolean
  error?: boolean
}

interface Props {
  folders?: FoldersState
  currentFolderId?: string | number
  dataTest?: string
  listType?: string
  folderHeader?: ReactNode
  changeFolder(folder: Folder): void
  customScrollClass?: string
  children?: ReactNode
}

export const FolderListPicker: FC<Props> = (props: Props) => {
  const { changeFolder, dataTest = 'folder-list-picker', customScrollClass, listType } = props
  const { t } = useTranslation()

  const getFolders = () => {
    if (listType === ListCategory.UNIFIED_LIST) {
      return null
    }

    if (props.folders?.loading) {
      return <Loader center />
    }

    if (props.folders?.error) {
      return <PageError vertical />
    }

    return props.folders?.folders?.map((folder) => {
      //Hide Needs Attention Folder
      if (listType != ListCategory.ACCOUNTS && folder.id === -3) {
        return
      }

      const current = folder.id === props.currentFolderId

      return (
        <button
          data-test={`${dataTest}-folder-${folder.id}`}
          key={folder.id}
          className={classNames(`${rootClass}__folders-row`, {
            [`${rootClass}__folders-row--current`]: current,
          })}
          onClick={() => {
            if (folder.isContacts) return
            if (props.currentFolderId !== folder.id) {
              changeFolder(folder)
            }
          }}
        >
          <label tabIndex={-1} className={`${rootClass}__folders-row-name`} title={getFolderName(folder, t)}>
            {current && (
              <Svg className={`${rootClass}__folder-icon ${rootClass}__folder-icon--current`} type={SvgType.ICON} name={SvgNames.folderOpened} />
            )}
            {!current && <Svg className={`${rootClass}__folder-icon`} type={SvgType.ICON} name={SvgNames.folder} />}
            {getFolderName(folder, t)}
          </label>
          <label tabIndex={-1} className={`${rootClass}__folders-row-count`}>
            {getCountDisplay(folder.count)}
          </label>
        </button>
      )
    })
  }

  return (
    <div data-test={dataTest} className={rootClass}>
      <div className={classNames(`${rootClass}__folders`, customScrollClass)}>
        <div className={`${rootClass}__folders-header`}>{props.folderHeader}</div>
        <div className={`${rootClass}__folders-list`}>{getFolders()}</div>
      </div>
      {props.children}
    </div>
  )
}

export default FolderListPicker
