import React from 'react'

import { ProgramSetFieldOperation } from '@graphql/types/query-types'

import { FieldSetOptions, numberDataType, State } from './EditFieldSetStep'

export const getEditFieldSetStepUtils = (setState: React.Dispatch<React.SetStateAction<State>>) => ({
  updateOperationValue: function <T>(conditionId: string, operationId: string, key: keyof ProgramSetFieldOperation, value: T) {
    setState((state) => ({
      ...state,
      operations: {
        ...state.operations,
        [conditionId]: state.operations[conditionId].map((curOperation) => {
          if (curOperation.id === operationId) {
            let resetRule = {}
            if (key === 'fieldName') {
              const selectedMapping = state.uclMappings.find((mapping) => value === mapping.displayName)
              const dataType = selectedMapping ? selectedMapping.dataType : ''
              resetRule = { rule: dataType === numberDataType ? FieldSetOptions.INCR : FieldSetOptions.CONST }
            }
            if (typeof value === 'string' && value === FieldSetOptions.NOOP) {
              return {
                ...curOperation,
                fieldName: FieldSetOptions.NOOP,
              }
            }
            return {
              ...curOperation,
              [key]: value,
              ...resetRule,
            }
          }
          return curOperation
        }),
      },
    }))
  },
})
