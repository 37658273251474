import { useHistory } from 'react-router-dom'

import { ListPageCommonState, Update } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { ListingPageStatusToast } from '@complex/ListingPage/Utils/ListingPage.constants'
import { parseItemDtoResult } from '@complex/ListingPage/Utils/ListingPage.utils'
import { useTranslation } from '@const/globals'
import { useListMaintenanceProgramsListingPageRequests } from '@src/pages/listingPages/ListMaintenancePrograms/GraphQL/ListMaintenanceProgramsListingPageRequests.graphQL'
import { LIST_MAINTENANCE_PROGRAMS_URL, runProgramUtils } from '@src/pages/listingPages/ListMaintenancePrograms/utils/Helpers'
import { ListMaintenanceProgramDto, ListMaintenanceProgramsContextState } from '@utils/listingPage/listMaintenancePrograms.utils'

import { ProgramStepsAndDetailsTabs } from '../components/ProgramStepsAndDetails/utils/ProgramStepsAndDetails.constants'

interface ListMaintenanceProgramsHandlerProps {
  update: (values: Partial<ListMaintenanceProgramsContextState>) => void
}

export const useListMaintenanceProgramsHandler = (props: ListMaintenanceProgramsHandlerProps) => {
  const { update } = props

  const { deleteProgramsRequest, runProgramNowRequest, getProgramRequest } = useListMaintenanceProgramsListingPageRequests()

  const { push } = useHistory()

  const { t } = useTranslation()

  const getProgram = (programId: number | string) => {
    return getProgramRequest(programId).then(({ data }) => {
      if (data?.getItem) {
        return parseItemDtoResult([data.getItem])[0] as unknown as ListMaintenanceProgramDto
      }
    })
  }

  const setProgram = (
    program: string | undefined | ListMaintenanceProgramDto,
    options?: { fixErrors?: boolean; detailsPage?: boolean; backTo?: string }
  ) => {
    const { fixErrors, detailsPage = false, backTo } = { ...options }

    const updateProgram = (program: ListMaintenanceProgramDto | undefined) => {
      update({ program, loadingProgram: false })
      if (program) {
        const searchParams = new URLSearchParams({
          programId: program.externalId,
          tab: detailsPage ? ProgramStepsAndDetailsTabs.PROGRAM_DETAILS : ProgramStepsAndDetailsTabs.PROGRAM_STEPS,
          ...(fixErrors !== undefined ? { fixErrors: `${fixErrors}` } : {}),
          ...(backTo ? { backTo } : {}),
        })
        push({
          pathname: LIST_MAINTENANCE_PROGRAMS_URL,
          search: searchParams.toString(),
        })
      }
    }

    if (typeof program === 'string') {
      getProgram(program).then((programData) => updateProgram(programData))
    } else {
      updateProgram(program)
    }
  }

  const runProgram = (program: ListMaintenanceProgramDto, listingPageUpdate?: Update, listingPageState?: ListPageCommonState) => {
    const onSuccess = (statusToast: ListingPageStatusToast) => (listingPageUpdate ? listingPageUpdate({ statusToast }) : update({ statusToast }))
    const isListingPage = !!listingPageUpdate && !!listingPageState
    return runProgramUtils(runProgramNowRequest, program, onSuccess, t, isListingPage).then((hasRun) => {
      if (isListingPage && hasRun) {
        const items = listingPageState.items.map((item) => (program.externalId === item.externalId ? { ...item, lastDoneTS: Date.now() } : item))
        listingPageUpdate({ items })
      }
      return hasRun
    })
  }

  const deletePrograms = (programs: ListMaintenanceProgramDto[]) => {
    return deleteProgramsRequest(programs)
  }

  return {
    deletePrograms,
    getProgram,
    setProgram,
    runProgram,
  }
}
