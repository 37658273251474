import { EmailTemplateLayout } from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/graphQL/EmailLayoutsModal.graphQL'
import { EmailTemplateLayoutType } from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/utils/EmailLayoutsModal.utils'
import { ColumnSort } from '@tanstack/react-table'
import { createSafeContext, useSafeContext } from '@utils/account/account.utils'

export interface EmailLayoutsModalContentContextAPI {
  layoutsType: EmailTemplateLayoutType
  layouts: EmailTemplateLayout[]
  loading: boolean
  loadingNextData: boolean
  onSelect: (layout: EmailTemplateLayout) => void
  onPreview: (layout: EmailTemplateLayout) => void
  sortBy: ColumnSort
  onSortColumnChange: (sortBy: ColumnSort) => void
  selectedId?: string
  searchTerm?: string
  sendPlainText?: boolean
}

const EmailLayoutsModalContentContext = createSafeContext<EmailLayoutsModalContentContextAPI>()
export const useEmailLayoutsModalContentContext = () => useSafeContext(EmailLayoutsModalContentContext)

export default EmailLayoutsModalContentContext
