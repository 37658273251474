import { gql } from '@apollo/client'

export default gql`
  mutation deleteClassicSegments($segmentIds: [String]) {
    deleteClassicSegments(segmentIds: $segmentIds) {
      body
      message
      objectId
      statusCode
    }
  }
`
