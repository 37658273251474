import React, { FC, RefObject, useCallback, useEffect, useState } from 'react'
import { Row } from 'react-table'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import ActionableNestedTableWithEmptyListing from '@components/ActionableNestedTableWithEmptyListing/ActionableNestedTableWithEmptyListing'
import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import { YesNo } from '@components/ConfirmationModal'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import InfoAction from '@components/InfoAction/InfoAction'
import InputWithStatus from '@components/InputWithStatus/InputWithStatus'
import Modal, { ModalHeader } from '@components/Modal'
import { ModalBody } from '@components/Modal/components/ModalBody'
import { ModalFooter, ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Search, { SearchType } from '@components/Search/Search'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { ColWithTooltip } from '@components/Table/components/tableColumns'
import { HeaderAction, RowAction, TableColumn } from '@components/Table/Table'
import { MultipleTables } from '@components/TableV2/components/MultipleTables/MultipleTables'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import setGlobalDomains from '@graphql/mutations/setGlobalDomains'
import getFormDomains from '@graphql/queries/getFormDomains'
import { StatusToastType } from '@interface/StatusToast'
import getAddInputInfoText, {
  STICKY_HEADER_POSITION,
} from '@src/pages/Settings/OtherSettings/CustomAccountSettings/utils/CustomAccountSettings.utils'
import { domainRegExp, hyperlinkPrefixRegExp } from '@utils/formUtils'
import { logNewRelicError } from '@utils/new-relic.utils'

import { FORM_DOMAINS_MORE_INFO_LINK, FORM_DOMAINS_VALIDATION_MESSAGES } from './constants/formDomains.constants'
import { columnsV2 } from './FormDomails.utils'

import './FormDomains.css'

interface Props {
  setToastStatus: (value: StatusToastType) => void
  scrollableElement: RefObject<HTMLDivElement>
  dataTest?: string
}

export interface FormDomainItem {
  id: number
  domain: string
}

const rootClass = 'form-domains'

const FormDomains: FC<Props> = (props: Props) => {
  const { dataTest, setToastStatus, scrollableElement } = props

  const [domainToEdit, setDomainToEdit] = useState<string>('')
  const [domainToDelete, setDomainToDelete] = useState<string>('')
  const [formDomains, setFormDomains] = useState<FormDomainItem[]>([])
  const [formDomainsFiltered, setFormDomainsFiltered] = useState<FormDomainItem[]>([])
  const [multipleDeleteKeys, setMultipleDeleteKeys] = useState<string[]>([])
  const [editIndex, setEditIndex] = useState<number>(-1)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [isMultipleDelete, setIsMultipleDelete] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const client = useApolloClient()
  const { data, loading, error, refetch } = useQuery(getFormDomains, {
    client,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const [setDomains, { loading: loadingMutation }] = useMutation<string[]>(setGlobalDomains, {
    client,
    fetchPolicy: 'no-cache',
  })
  const tableLoading = loading || loadingMutation

  useEffect(() => {
    if (!loading && data?.getDomains) {
      const formDomains = data?.getDomains.map((value: string, id: number) => {
        return { domain: value, id }
      })
      setFormDomains(formDomains)
    }
    if (error) {
      logNewRelicError(error)
    }
  }, [loading, error, data])

  useEffect(() => {
    const loweredSearchTerm = searchTerm.toLowerCase()
    searchTerm === ''
      ? setFormDomainsFiltered(formDomains)
      : setFormDomainsFiltered(formDomains.filter(({ domain }) => domain.toLowerCase().includes(loweredSearchTerm)))
  }, [formDomains, searchTerm])

  const { t } = useTranslation()

  const headerActions: HeaderAction[] = [
    {
      label: t('Delete'),
      icon: SvgNames.pencil,
      onClick: () => {
        setIsDeleteModalOpen(true)
        setIsMultipleDelete(true)
      },
    },
  ]

  const columns: TableColumn[] = [
    {
      Header: 'FORM DOMAIN',
      accessor: 'domain',
      isSorted: true,
      isSortedDesc: true,
      sortType: 'caseInsensitive',
      align: 'left',
      flexColumn: true,
      Cell: (row: any) => <ColWithTooltip row={row} />,
    },
  ]

  const rowActions: RowAction[] = [
    {
      label: t('Edit'),
      icon: SvgNames.pencil,
      onClick: (e) => {
        setEditIndex(e.original.id)
        setDomainToEdit(e.original.domain)
        setIsModalOpen(true)
        setIsEditing(true)
      },
    },
    {
      label: t('Delete'),
      icon: SvgNames.delete,
      onClick: (e) => {
        setDomainToDelete(e.original.domain)
        setIsDeleteModalOpen(true)
        setIsMultipleDelete(false)
      },
    },
  ]

  const deleteDomains = useCallback(
    (selectedDomains: string[]) => {
      const domainsToDelete: string[] = []
      formDomains.forEach(({ domain }) => {
        if (!selectedDomains.includes(domain)) {
          domainsToDelete.push(domain)
        }
      })
      return domainsToDelete
    },
    [formDomains]
  )

  const closeModal = () => {
    setIsModalOpen(false)
    setDomainToEdit('')
    setDomainToDelete('')
    setEditIndex(-1)
    setIsEditing(false)
  }

  const onInputChange = useCallback<(event: React.ChangeEvent<HTMLInputElement>) => void>((e) => setDomainToEdit(e.target.value), [])

  const checkUrlValidity = (value: string) => {
    if (!domainRegExp.test(value) || !hyperlinkPrefixRegExp.test(value)) {
      return { errorMessageKey: 'invalidUrl' }
    }
  }

  const checkUniqueValidity = (value: string) => {
    const data = formDomains.map((v) => v.domain)
    const editValue = data[editIndex]

    if (data.filter((v) => !isEditing || v !== editValue).findIndex((url) => url === value) > -1) {
      return { errorMessageKey: 'isUnique' }
    }
  }

  const isButtonEnabled = (value: string) => {
    return !!value && !checkUrlValidity(value) && !checkUniqueValidity(value)
  }

  const addFormDomains = () => {
    const updated = formDomains.map((v) => v.domain)
    updated.push(domainToEdit)
    setDomains({ variables: { url: updated } })
      .then(() => {
        setToastStatus({
          showStatus: true,
          statusMessage: t('Success! We’ve added this domain.'),
          successStatus: true,
        })
        refetch()
      })
      .catch((error) => {
        logNewRelicError(error)
      })
  }

  const onSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm)
  }

  const editFormDomain = () => {
    const updated = formDomains.map((v) => v.domain)
    updated[editIndex] = domainToEdit
    setDomains({ variables: { url: updated } })
      .then(() => {
        setToastStatus({
          showStatus: true,
          statusMessage: t('Success! We’ve edited this domain.'),
          successStatus: true,
        })
        refetch()
      })
      .catch((error) => {
        logNewRelicError(error)
      })
  }

  const onDelete = (data: string[]) => {
    setDomains({ variables: { url: data } })
      .then(() => {
        setToastStatus({
          showStatus: true,
          statusMessage: t('Success! We’ve removed the domain(s).'),
          successStatus: true,
        })
        refetch()
      })
      .catch((error) => {
        logNewRelicError(error)
      })
  }

  return (
    <div data-test={dataTest} className={rootClass}>
      {isDeleteModalOpen && (
        <DeleteConfirmationModal
          title={t('Are you sure?')}
          body={t(
            `Act-On forms embedded on your selected domains will fail to load in the browser. Please remove any forms from these sites before deleting.`
          )}
          deleteButtonText={t('Yes, delete')}
          onAnswer={(answer) => {
            if (answer === YesNo.YES) {
              if (isMultipleDelete) {
                onDelete(deleteDomains(multipleDeleteKeys))
              } else {
                onDelete(deleteDomains([domainToDelete]))
              }
            }
            setIsDeleteModalOpen(false)
            setDomainToDelete('')
          }}
          isOpen={isDeleteModalOpen}
        />
      )}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          className={`${rootClass}__modal`}
          header={<ModalHeader headerType={ModalHeaderType.Form}>{t(isEditing ? t('Edit Form Domain') : t('Form Domain'))}</ModalHeader>}
        >
          <ModalBody className={`${rootClass}__modal-body`}>
            <InputWithStatus
              placeholder={t('Example: https://www.act-on.com/')}
              /* eslint-disable-next-line jsx-a11y/no-autofocus */
              autoFocus
              required
              dataTest={`${dataTest}-modal-input`}
              value={domainToEdit}
              validityFunctions={[checkUrlValidity, checkUniqueValidity]}
              tooltipErrorMessages={FORM_DOMAINS_VALIDATION_MESSAGES}
              onChange={onInputChange}
              name="formDomain"
              tooltipProps={{ triggerClassName: `${rootClass}__modal-input` }}
            />
            {getAddInputInfoText(t, rootClass, true)}
          </ModalBody>
          <ModalFooter footerType={ModalFooterType.Form}>
            <Button buttonType={ButtonType.TERTIARY} onClick={closeModal} dataTest={`${dataTest}-close-button`}>
              {t('Cancel')}
            </Button>
            <Button
              buttonType={ButtonType.PRIMARY}
              dataTest={`${dataTest}-save-button`}
              disabled={!isButtonEnabled(domainToEdit)}
              onClick={() => {
                isEditing ? editFormDomain() : addFormDomains()
                setIsModalOpen(false)
                closeModal()
              }}
            >
              {t(isEditing ? 'Save' : 'Add')}
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <InfoAction
        svgName={SvgNames.lightBulb}
        message={
          <div>
            <Typography
              text={t(
                'Add each external web domain where you’ll embed Act-On forms. This satisfies browser policies so that your forms load correctly.'
              )}
            />
            <TextLink text={t('More info')} link={FORM_DOMAINS_MORE_INFO_LINK} size={TextLinkSize.LARGE} />
          </div>
        }
        className={`${rootClass}__info-action`}
      />
      <div className={`${rootClass}__actions`}>
        <Button
          buttonType={ButtonType.PRIMARY}
          dataTest={`${dataTest}-button__add`}
          iconPosition={ButtonIconPosition.LEFT}
          onClick={() => {
            setIsModalOpen(true)
          }}
        >
          <Svg name={SvgNames.plus} type={SvgType.LARGER_ICON} />
          {t('Add form domain')}
        </Button>
        <Search
          className={`${rootClass}__actions-search`}
          dataTest={`${dataTest}-search-input`}
          incomingValue={searchTerm}
          onChangeHandler={onSearch}
          placeholder={t('Search form domains')}
          searchType={SearchType.LARGE}
          canClear
          clearOnChange={[searchTerm]}
        />
      </div>
      <MultipleTables
        tableV2Props={{
          rowActions,
          headerActions,
          loading: tableLoading,
          columns: columnsV2,
          allLoaded: !tableLoading,
          data: formDomainsFiltered,
          enableInsideLoader: true,
          enableStickyHeader: true,
          enableCheckbox: true,
          stickyHeaderTopPosition: STICKY_HEADER_POSITION,
          emptyState: {
            headline: !formDomains.length ? t('No form domains set') : t('No results found'),
            text: !formDomains.length ? t('Add external web domains to host your Act-On forms.') : t('There were no results matching your search.'),
            buttonText: !formDomains.length ? t('Add a form domain') : undefined,
            imageWithTitle: false,
            imgSrc: StaticImageNames.emptySearch,
            size: EmptyListingSize.MEDIUM,
            buttonOnClick: () => {
              setIsModalOpen(true)
            },
          },
          onRowSelectionChanged: setMultipleDeleteKeys,
          rowUniqueIdKey: 'domain',
        }}
        oldTable={
          <ActionableNestedTableWithEmptyListing
            data={formDomainsFiltered}
            columns={columns}
            className={`${rootClass}__table`}
            useCheckboxes
            useHeaderCheckbox
            headerActions={headerActions}
            rowActions={rowActions}
            onRowSelectionChanged={(selectedRows: Row<{}>[], rows?: Row<{}>[]) => {
              const rowIds = selectedRows.map((a: Row) => a.id)
              const filtered = rows && rows.filter((row: any) => rowIds.includes(row.id))
              if (rowIds.length > 0) {
                setMultipleDeleteKeys(filtered ? filtered.map((row: any) => row.original.domain) : [])
              } else if (multipleDeleteKeys.length > 0) {
                setMultipleDeleteKeys([])
              }
            }}
            headline={formDomains.length === 0 ? t('No form domains set') : t('No results found')}
            text={
              formDomains.length === 0 ? t('Add external web domains to host your Act-On forms.') : t('There were no results matching your search.')
            }
            buttonText={formDomains.length === 0 ? t('Add a form domain') : undefined}
            buttonOnClick={() => {
              setIsModalOpen(true)
            }}
            imageWithTitle={false}
            fixedHeader
            imgSrc={StaticImageNames.emptySearch}
            size={EmptyListingSize.MEDIUM}
            loading={tableLoading}
            allLoaded={!tableLoading}
            scrollableElement={scrollableElement}
            onLoading={() => {
              // empty required method
            }}
            dataTest={`${dataTest}__table`}
          />
        }
      />
    </div>
  )
}

export default FormDomains
