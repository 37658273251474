import clearAllRecordsFromList from '@graphql/microservices/entity-join/clearAllRecordsFromList'
import deleteMirroredJoinLists from '@graphql/microservices/entity-join/deleteMirroredJoinLists'
import getConversionRate from '@graphql/microservices/entity-join/getConversionRate'
import getCountForRecentFormSubmissions from '@graphql/microservices/entity-join/getCountForRecentFormSubmissions'
import getCountForRecentWebinarRegistrations from '@graphql/microservices/entity-join/getCountForRecentWebinarRegistrations'
import getFormRecentActivities from '@graphql/microservices/entity-join/getFormRecentActivities'
import getListsRelatedForms from '@graphql/microservices/entity-join/getListsRelatedForms'
import getRecentFormSubmissions from '@graphql/microservices/entity-join/getRecentFormSubmissions'
import getRecentWebinarRegistrations from '@graphql/microservices/entity-join/getRecentWebinarRegistrations'
import { default as getRelatedFormsQuery } from '@graphql/microservices/entity-join/getRelatedForms'
import getSubmitsAndViews from '@graphql/microservices/entity-join/getSubmitsAndViews'
import getWebinarAttendanceData from '@graphql/microservices/entity-join/getWebinarAttendanceData'
import getWebinarListsRelatedForms from '@graphql/microservices/entity-join/getWebinarListsRelatedForms'
import getWebinarRecentActivities from '@graphql/microservices/entity-join/getWebinarRecentActivities'
import getWebinarRegistrationDataForCards from '@graphql/microservices/entity-join/getWebinarRegistrationDataForCards'
import getWebinarRelatedForms from '@graphql/microservices/entity-join/getWebinarRelatedForms'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import {
  ClearAllRecordsFromListMutation,
  ClearAllRecordsFromListMutationVariables,
  DeleteListsMutation,
  FormSubmissionActivityDto,
  FormSubmissionDataCardDto,
  GetCountForRecentFormSubmissionsQuery,
  GetCountForRecentFormSubmissionsQueryVariables,
  GetConversionRateQuery,
  GetConversionRateQueryVariables,
  GetFormRecentActivitiesQuery,
  GetFormRecentActivitiesQueryVariables,
  GetRecentFormSubmissionsQuery,
  GetRecentFormSubmissionsQueryVariables,
  GetSubmitsAndViewsQuery,
  GetSubmitsAndViewsQueryVariables,
  FormSubmissionSummaryDto,
  GetRelatedFormsQueryVariables,
  GetRelatedFormsQuery,
  GetRecentWebinarRegistrationsQuery,
  GetRecentWebinarRegistrationsQueryVariables,
  GetWebinarRecentActivitiesQuery,
  GetWebinarRecentActivitiesQueryVariables,
  WebinarType,
  WebinarRegistrationActivityDto,
  GetCountForRecentWebinarRegistrationsQuery,
  GetCountForRecentWebinarRegistrationsQueryVariables,
  GetWebinarRelatedFormsQuery,
  GetWebinarRelatedFormsQueryVariables,
  GetWebinarRegistrationDataForCardsQuery,
  GetWebinarRegistrationDataForCardsQueryVariables,
  RegistrationDataCardInfoResponse,
  DeleteMirroredJoinListsMutationVariables,
  DeleteMirroredJoinListsMutation,
  GetWebinarAttendanceDataQuery,
  GetWebinarAttendanceDataQueryVariables,
  WebinarAttendanceData,
  GetListsRelatedFormsQuery,
  GetListsRelatedFormsQueryVariables,
  GetWebinarListsRelatedFormsQueryVariables,
  GetWebinarListsRelatedFormsQuery,
} from '@graphql/types/microservice/entity-join-types'
import { DATA_PERIOD } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.constants'
import { ItemType } from '@utils/categorization'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { logNewRelicError } from '@utils/new-relic.utils'
import { FetchPromise } from '@utils/types'

export type WebinarRelatedFormsRequest = (webinarId: string, webinarType: WebinarType) => Promise<FormSubmissionSummaryDto[]>

export type WebinarAttendanceDataRequest = (webinarId: string, webinarType: WebinarType) => Promise<WebinarAttendanceData>

export interface FormsJoinViewListingPageEntityJoinRequests {
  getRelatedForms: (id: string) => Promise<FormSubmissionSummaryDto[]>
  clearAllRecords: (ids: string[]) => FetchPromise<ClearAllRecordsFromListMutation>
  getRecentActivities: (id: string) => Promise<FormSubmissionActivityDto>
  deleteLists: (items: ItemDto[], type: ItemType) => FetchPromise<DeleteListsMutation>
  getRecentFormSubmissionsRequest: (search: string, currentPage?: number) => FetchPromise<GetRecentFormSubmissionsQuery>
  getRecentWebinarRegistrationsRequest: (search: string, currentPage?: number) => FetchPromise<GetRecentWebinarRegistrationsQuery>
  getCountForRecentFormSubmissionsRequest: () => Promise<number | void>
  getCountForRecentWebinarRegistrationsRequest: () => Promise<number | void>
  getConversionRateRequest: (id: string) => Promise<number>
  getSubmitsAndViewsRequest: (listId: string) => Promise<FormSubmissionDataCardDto>
  getWebinarDataCardsDataRequest: (days: DATA_PERIOD) => Promise<RegistrationDataCardInfoResponse>
  getWebinarRecentActivitiesRequest: (webinarId: string, webinarType: WebinarType) => Promise<WebinarRegistrationActivityDto>
  getWebinarRelatedFormsRequest: WebinarRelatedFormsRequest
  getWebinarAttendanceDataRequest: WebinarAttendanceDataRequest
  getListsRelatedFormsRequest: (listIds: string[]) => FetchPromise<GetListsRelatedFormsQuery>
  getWebinarListsRelatedFormsRequest: (webinarListIds: string[]) => FetchPromise<GetWebinarListsRelatedFormsQuery>
}

const DAYS = 30
const FORM_SUBMISSION_SUB_TYPE = 'form submission'

export const useFormsJoinViewListingPageEntityJoinRequests = (): FormsJoinViewListingPageEntityJoinRequests => {
  const { client: entityJoinClient } = useMicroserviceClient({ serviceName: MicroserviceClients.ENTITY_JOIN })

  const deleteLists = async (items: ItemDto[], type: ItemType) =>
    await entityJoinClient.mutate<DeleteMirroredJoinListsMutation, DeleteMirroredJoinListsMutationVariables>({
      mutation: deleteMirroredJoinLists,
      variables: {
        listIds: items.map(({ externalId }) => externalId),
        type,
      },
    })

  const getRecentFormSubmissionsRequest = async (search: string, currentPage?: number) => {
    return await entityJoinClient.query<GetRecentFormSubmissionsQuery, GetRecentFormSubmissionsQueryVariables>({
      query: getRecentFormSubmissions,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        pageNumber: currentPage,
        pageSize: 50,
        field: 'name',
        value: search,
        subTypes: [FORM_SUBMISSION_SUB_TYPE],
        folderSubTypes: [],
        days: DAYS,
      },
    })
  }

  const getRecentWebinarRegistrationsRequest = async (search: string, currentPage?: number) => {
    return await entityJoinClient.query<GetRecentWebinarRegistrationsQuery, GetRecentWebinarRegistrationsQueryVariables>({
      query: getRecentWebinarRegistrations,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        pageNumber: currentPage,
        pageSize: 50,
        field: 'name',
        value: search,
        subTypes: [],
        folderSubTypes: [],
        days: DAYS,
      },
    })
  }

  const getCountForRecentFormSubmissionsRequest = async () => {
    return entityJoinClient
      .query<GetCountForRecentFormSubmissionsQuery, GetCountForRecentFormSubmissionsQueryVariables>({
        query: getCountForRecentFormSubmissions,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        variables: {
          days: DAYS,
          subTypes: [FORM_SUBMISSION_SUB_TYPE],
          folderSubTypes: [],
        },
      })
      .then(({ data }) => {
        return data.getCountForRecentFormSubmissions?.recentCount ?? 0
      })
      .catch((error) => {
        logNewRelicError(error)
      })
  }

  const getCountForRecentWebinarRegistrationsRequest = async () => {
    return entityJoinClient
      .query<GetCountForRecentWebinarRegistrationsQuery, GetCountForRecentWebinarRegistrationsQueryVariables>({
        query: getCountForRecentWebinarRegistrations,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        variables: {
          offset: DAYS,
          subTypes: [],
          folderSubTypes: [],
        },
      })
      .then(({ data }) => {
        return data.getCountForRecentWebinarRegistrations?.recentCount ?? 0
      })
      .catch((error) => {
        logNewRelicError(error)
      })
  }

  const clearAllRecords = (listIds: string[]) =>
    entityJoinClient.mutate<ClearAllRecordsFromListMutation, ClearAllRecordsFromListMutationVariables>({
      mutation: clearAllRecordsFromList,
      variables: {
        listIds,
      },
    })

  const getRelatedForms = (listId: string) =>
    entityJoinClient
      .query<GetRelatedFormsQuery, GetRelatedFormsQueryVariables>({
        query: getRelatedFormsQuery,
        variables: {
          listId,
        },
      })
      .then(({ data }) => data.getRelatedForms as FormSubmissionSummaryDto[])

  const getRecentActivities = (listId: string, days = 30) =>
    entityJoinClient
      .query<GetFormRecentActivitiesQuery, GetFormRecentActivitiesQueryVariables>({
        query: getFormRecentActivities,
        variables: {
          listId,
          days,
        },
      })
      .then(({ data }) => data.getFormRecentActivities as FormSubmissionActivityDto)

  const getConversionRateRequest = (id: string) =>
    entityJoinClient
      .query<GetConversionRateQuery, GetConversionRateQueryVariables>({
        query: getConversionRate,
        variables: {
          listId: id,
        },
      })
      .then(({ data }) => data.getConversionRate as number)

  const getSubmitsAndViewsRequest = (listId: string) =>
    entityJoinClient
      .query<GetSubmitsAndViewsQuery, GetSubmitsAndViewsQueryVariables>({
        query: getSubmitsAndViews,
        variables: {
          listId,
        },
      })
      .then(({ data }) => data.getSubmitsAndViews as FormSubmissionDataCardDto)

  const getWebinarDataCardsDataRequest = async (days: DATA_PERIOD) => {
    return entityJoinClient
      .query<GetWebinarRegistrationDataForCardsQuery, GetWebinarRegistrationDataForCardsQueryVariables>({
        query: getWebinarRegistrationDataForCards,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        variables: {
          days,
        },
      })
      .then(({ data }) => data.getWebinarRegistrationDataForCards as RegistrationDataCardInfoResponse)
  }

  const getWebinarRecentActivitiesRequest = (webinarId: string, webinarType: WebinarType, days = 30) =>
    entityJoinClient
      .query<GetWebinarRecentActivitiesQuery, GetWebinarRecentActivitiesQueryVariables>({
        query: getWebinarRecentActivities,
        variables: {
          webinarId,
          webinarType,
          days,
        },
      })
      .then(({ data }) => data.getWebinarRecentActivities as WebinarRegistrationActivityDto)

  const getWebinarRelatedFormsRequest = (webinarId: string, webinarType: WebinarType) =>
    entityJoinClient
      .query<GetWebinarRelatedFormsQuery, GetWebinarRelatedFormsQueryVariables>({
        query: getWebinarRelatedForms,
        variables: {
          webinarId,
          webinarType,
        },
      })
      .then(({ data }) => [data.getWebinarRelatedForms] as FormSubmissionSummaryDto[])

  const getWebinarAttendanceDataRequest = (webinarId: string, webinarType: WebinarType) =>
    entityJoinClient
      .query<GetWebinarAttendanceDataQuery, GetWebinarAttendanceDataQueryVariables>({
        fetchPolicy: 'network-only',
        query: getWebinarAttendanceData,
        variables: {
          webinarId,
          webinarType,
        },
      })
      .then(({ data }) => data.getWebinarAttendanceData as WebinarAttendanceData)

  const getListsRelatedFormsRequest = (listIds: string[]) =>
    entityJoinClient.query<GetListsRelatedFormsQuery, GetListsRelatedFormsQueryVariables>({
      query: getListsRelatedForms,
      variables: {
        listIds,
      },
    })

  const getWebinarListsRelatedFormsRequest = (webinarListIds: string[]) =>
    entityJoinClient.query<GetWebinarListsRelatedFormsQuery, GetWebinarListsRelatedFormsQueryVariables>({
      query: getWebinarListsRelatedForms,
      variables: {
        webinarListIds,
      },
    })

  return {
    getRelatedForms,
    clearAllRecords,
    getRecentActivities,
    deleteLists,
    getConversionRateRequest,
    getRecentFormSubmissionsRequest,
    getRecentWebinarRegistrationsRequest,
    getCountForRecentFormSubmissionsRequest,
    getCountForRecentWebinarRegistrationsRequest,
    getSubmitsAndViewsRequest,
    getWebinarDataCardsDataRequest,
    getWebinarRecentActivitiesRequest,
    getWebinarRelatedFormsRequest,
    getWebinarAttendanceDataRequest,
    getListsRelatedFormsRequest,
    getWebinarListsRelatedFormsRequest,
  }
}
