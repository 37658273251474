import { gql } from '@apollo/client'

export default gql`
  query getListsFields($listIdsSeparatedByComma: String!) {
    getListsFields(listIdsSeparatedByComma: $listIdsSeparatedByComma) {
      listId
      listName
      fields
    }
  }
`
