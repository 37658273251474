import React, { FC, ReactNode, useEffect, useState, useMemo } from 'react'

import classNames from 'classnames'
import JSZip from 'jszip'

import BackButton from '@components/BackButton/BackButton'
import Button, { ButtonType } from '@components/Button'
import Caution from '@components/Caution/Caution'
import DropZone from '@components/DropZone/DropZone'
import Input from '@components/Input/Input'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import StatusToast, { Status } from '@components/StatusToast/StatusToast'
import Svg, { SvgNames } from '@components/Svg'
import Typography, { ModalHeaderStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import ExpirationDate from '../ExpirationDate/ExpirationDate'

import './UploadFile.css'

export interface UploadArgs {
  base64File?: string
  fileName?: string
  extension?: string
  folderName?: string
}

interface UploadFileProps {
  className?: string
  dataTest?: string
  isOpen: boolean
  handleBack?: () => void
  handleCancel: () => void
  folders?: SelectV2SingleOption[]
  onFileUpload: (args?: UploadArgs) => Promise<void>
  hideDescriptionText?: boolean
  uploadText?: string
  maxSizeText?: string
  children?: ReactNode
  hideBackButton?: boolean
  hasWarningMessage?: string | boolean
  hideFolders?: boolean
  acceptCustomFormats?: string
  uploadButtonText?: string
  secondaryFile?: string
  secondaryFileMaxSize?: number
}

interface UploadFileState {
  extension: string
  maxFileSize: boolean
  uploadArgs: UploadArgs | undefined
  isUploadButton: boolean
  fileSize?: number
  emptyFile?: boolean
  date?: Date
  isSvgHovered: boolean
  isUploading: boolean
  fileTypeNotAllowed?: boolean
  isSecondaryFile?: boolean
}

const initialState: UploadFileState = {
  extension: '',
  maxFileSize: false,
  emptyFile: false,
  isUploadButton: true,
  uploadArgs: undefined,
  isSvgHovered: false,
  isUploading: false,
  fileTypeNotAllowed: false,
  isSecondaryFile: false,
}

const rootClass = 'upload-file'

const SUPPORTED_FILE_TYPES =
  'https://connect.act-on.com/hc/en-us/articles/360023938773-Upload-Images-Files-and-Create-Tracking-URLs#01H840FPBBG033SR9B6ZT9DWEY'

const ACCEPT = '.pps, .txt, .pptx, .vcs, .ics, .ppsx, .xls, .doc, .xlsx, .docx, .pdf, .zip, .ppt'

const MAX_FILE_SIZE = 10 * 1024 * 1024 //10MB

const UploadFile: FC<UploadFileProps> = (props: UploadFileProps) => {
  const {
    dataTest = rootClass,
    className = '',
    isOpen,
    handleBack,
    handleCancel,
    folders,
    onFileUpload,
    hideDescriptionText = false,
    uploadText,
    maxSizeText,
    children,
    hideBackButton = false,
    hasWarningMessage = false,
    hideFolders = false,
    acceptCustomFormats,
    uploadButtonText,
    secondaryFileMaxSize,
    secondaryFile,
  } = props
  const acceptFormats = acceptCustomFormats || ACCEPT
  const [state, setState] = useState(initialState)
  const [showStatusToast, setShowStatusToast] = useState(false)
  const { t } = useTranslation()

  const header = (
    <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__header`}>
      {!hideBackButton && (
        <BackButton
          onClick={() => {
            setState({ ...initialState, uploadArgs: { ...initialState.uploadArgs, folderName: folders?.[0]?.label } })
            handleBack && handleBack()
          }}
        />
      )}
      <Typography text={t('Upload media file')} {...ModalHeaderStyle} />
    </ModalHeader>
  )

  useEffect(() => {
    if (folders && folders.length > 0 && !hideFolders) {
      setState((prevState) => ({
        ...prevState,
        uploadArgs: { ...prevState.uploadArgs, folderName: folders[0].label },
      }))
    }
  }, [folders, hideFolders])

  const getFileType = (fileName: string) => {
    const parts = fileName.split('.')
    if (parts.length > 1) {
      return parts[parts.length - 1]
    }
    return ''
  }

  const removeTypeFromName = (fileName: string) => {
    const lastDotIndex = fileName.lastIndexOf('.')
    if (lastDotIndex !== -1) {
      return fileName.substring(0, lastDotIndex)
    }
    return fileName
  }

  const getBase64String = (inputString: string) => {
    const parts = inputString.split('base64,')
    return parts.length > 1 ? parts[1] : ''
  }

  const handleFileUpload = async (files: File[]) => {
    if (files[0]) {
      const file = files[0]
      const reader = new FileReader()

      reader.onloadend = async () => {
        const { name, size } = file
        const extension = getFileType(name)

        const isSecondaryFile = extension === secondaryFile
        const maxFileSize = isSecondaryFile ? Number(secondaryFileMaxSize) : MAX_FILE_SIZE
        if (size > maxFileSize) {
          setState({
            ...state,
            maxFileSize: true,
            isUploading: false,
            fileTypeNotAllowed: false,
            emptyFile: false,
            isSecondaryFile,
          })
          return
        } else if (size === 0) {
          setState({
            ...state,
            emptyFile: true,
            isUploading: false,
            fileTypeNotAllowed: false,
            maxFileSize: false,
          })
          return
        } else if (!acceptFormats.includes(`.${extension}`)) {
          setState({
            ...state,
            fileTypeNotAllowed: true,
            isUploading: false,
            emptyFile: false,
            maxFileSize: false,
          })
          return
        }

        if (extension === 'zip') {
          const zip = await JSZip.loadAsync(file)
          let secondaryFileFound = false

          for (const fileName in zip.files) {
            const zipFile = zip.files[fileName]
            if (zipFile.name.endsWith(`.${secondaryFile}`)) {
              const fileSize = (await zipFile.async('blob')).size
              if (fileSize > Number(secondaryFileMaxSize)) {
                setState({
                  ...state,
                  maxFileSize: true,
                  isUploading: false,
                  fileTypeNotAllowed: false,
                  emptyFile: false,
                })
                return
              }
              secondaryFileFound = true
              break
            }
          }

          if (!secondaryFileFound) {
            setState({
              ...state,
              fileTypeNotAllowed: true,
              isUploading: false,
              emptyFile: false,
              maxFileSize: false,
            })
            return
          }
        }

        const base64File = getBase64String(`${reader.result}`)
        const fileName = removeTypeFromName(name)

        setState({
          ...state,
          fileSize: size,
          maxFileSize: false,
          fileTypeNotAllowed: false,
          emptyFile: false,
          uploadArgs: { ...state.uploadArgs, fileName, base64File, extension },
        })
      }

      reader.readAsDataURL(file)
    }
  }

  const fileLoaded = state.uploadArgs?.fileName !== undefined && state.uploadArgs?.extension !== undefined

  const errorMsg = useMemo(() => {
    switch (true) {
      case state.maxFileSize:
        return `File size exceeds maximum limit ${state.isSecondaryFile ? `${secondaryFileMaxSize && secondaryFileMaxSize / 1024} KB` : '10 MB'}`

      case state.fileTypeNotAllowed:
        return 'File type is not allowed'

      case state.emptyFile:
        return 'File is empty'

      default:
        return ''
    }
  }, [state.maxFileSize, state.isSecondaryFile, state.fileTypeNotAllowed, state.emptyFile, secondaryFileMaxSize])

  const renderOptions = () => (
    <>
      {!hideDescriptionText && (
        <>
          <Typography text={'Upload a file from your device. '} type={TextType.BODY_TEXT_LIGHT} inline={true} />
          <Button
            buttonType={ButtonType.TEXT}
            inline={true}
            isLink={true}
            className={`${rootClass}__link`}
            to={SUPPORTED_FILE_TYPES}
            target={'_blank'}
          >
            <Typography text={' Supported file types '} inline={true} weight={TextWeight.MEDIUM} />
            <Svg name={SvgNames.externalLinkSelected} />
          </Button>
        </>
      )}
      {hasWarningMessage && (
        <Caution
          message={<Typography text={hasWarningMessage} dataTest={`${dataTest}-description`} />}
          className={`${className}__caution`}
          dataTest={dataTest}
        />
      )}
      <DropZone
        showFileLoaded={fileLoaded}
        handleRemoveFile={() =>
          setState((prevState) => ({
            ...initialState,
            uploadArgs: {
              ...initialState.uploadArgs,
              folderName: prevState?.uploadArgs?.folderName,
            },
          }))
        }
        fileName={state.uploadArgs?.fileName}
        fileSize={state.fileSize}
        accept={acceptFormats}
        onFileSelected={handleFileUpload}
        selectFileText={state.maxFileSize ? 'Upload a different file' : uploadText || 'Upload a file'}
        maxSize={maxSizeText ?? '10MB'}
        isError={state.maxFileSize || state.fileTypeNotAllowed || state.emptyFile}
        errorMsg={errorMsg}
        isUploading={state.isUploading}
      />
      {children}
      {fileLoaded && !hideFolders && folders && folders.length > 0 && (
        <div className={`${rootClass}__initial-inputs`}>
          <Input
            defaultValue={state.uploadArgs?.fileName}
            disabled={state.uploadArgs?.fileName === undefined || state.uploadArgs?.extension === undefined}
            label={'File name'}
            onChange={(e) => setState({ ...state, uploadArgs: { ...state.uploadArgs, fileName: e.target.value } })}
            className={`${rootClass}__initial-inputs--file-name-input`}
          />
          <SelectV2
            defaultValue={{
              label: state.uploadArgs?.folderName || folders[0].label,
              value: state.uploadArgs?.folderName || folders[0].value,
            }}
            label={'Add to folder'}
            inputIcon={SvgNames.folder}
            options={folders}
            isClearable
            isSearchable
            insideModal
            onChange={(e) => setState({ ...state, uploadArgs: { ...state.uploadArgs, folderName: e?.label } })}
          />
          <ExpirationDate />
        </div>
      )}
    </>
  )

  const handleUploadButton = async () => {
    setState({ ...state, isUploading: true })
    await onFileUpload(state.uploadArgs).catch(() => setShowStatusToast(true))
    setState({ ...state, isUploadButton: false, isUploading: false })
  }

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
      {showStatusToast && (
        <StatusToast
          message={t('We encountered an error uploading your file. Please try again.')}
          status={Status.FAIL}
          hasTimeout={true}
          closeStatus={() => setShowStatusToast(false)}
        />
      )}
      <ModalBody>{renderOptions()}</ModalBody>
      <ModalFooter footerType={ModalFooterType.Form} className={`${rootClass}__footer`}>
        <Button
          onClick={handleCancel}
          buttonType={ButtonType.TERTIARY}
          dataTest={`${dataTest}-button-tertiary`}
          className={`${rootClass}__button-cancel`}
        >
          {t('Cancel')}
        </Button>
        <Button
          buttonType={ButtonType.PRIMARY}
          onClick={handleUploadButton}
          disabled={state.isUploading || state.uploadArgs?.fileName === undefined || state.uploadArgs?.extension === undefined}
          dataTest={`${dataTest}-button-primary`}
          className={`${rootClass}__button-upload`}
        >
          {uploadButtonText || t('Insert')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default UploadFile
