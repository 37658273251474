import React from 'react'

import classNames from 'classnames'

import { getFolderById } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextType } from '@components/Typography/Typography'
import { Folder } from '@interface/Folder'
import { ITEM_TYPES, ItemType } from '@utils/categorization'
import { buildHeader } from '@utils/folderUtils'

export const renderPathToFolder = (
  location: ItemType,
  folderId: number,
  folders: FolderData[],
  inAllItems: boolean | undefined,
  rootClass: string,
  t: Function,
  activeFolder?: FolderData,
  allItemsLocationOverride?: string
) => {
  const allItemsLocation = t(allItemsLocationOverride || `ListPage${ITEM_TYPES[location].listPage}.AllItemsFilter`)
  const pathArray = buildHeader(folderId, folders)
  const path = inAllItems
    ? pathArray[pathArray.length - 1] || allItemsLocation
    : activeFolder?.name ?? (getFolderById(folderId, folders as Folder[])?.name || allItemsLocation)
  return <Typography title={path} className={classNames(`${rootClass}__search-path`, `ellip`)} text={path} type={TextType.BODY_TEXT_SMALL_LIGHT} />
}

export const renderItemNameVal = (svgNames: SvgNames | null, name: string, search: string, rootClass: string) => (
  <div className={`${rootClass}__search-name`}>
    {svgNames && <Svg name={svgNames} type={SvgType.MEDIUM} />}
    <div className={`${rootClass}__search-name-typography`}>
      <div className={'ellip'}>{search ? renderBoldTextOnMatch(name, search) : name}</div>
    </div>
  </div>
)

export const partialMatch = <T extends {}>(item: T, key: (keyof T)[] | keyof T, search: string | number | undefined) => {
  if (search === undefined || search === '') {
    return true
  }
  const keys = Array.isArray(key) ? key : [key]
  return keys.some((k) => {
    const value = item[k as keyof T]
    if (value === undefined || value === '') {
      return false
    }
    return String(value).toLowerCase().includes(String(search).toLowerCase())
  })
}
