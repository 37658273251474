import { gql } from '@apollo/client'

export default gql`
  query getFtpFiles($path: String) {
    getFtpFiles(path: $path) {
      name
      size
      timestamp
      urlPath
    }
  }
`
