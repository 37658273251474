enum ElementHoverState {
  unset,
  currentHover,
  chain,
}

const setElementState = (element: Element, state: ElementHoverState) => {
  element?.setAttribute('threadLine', state ? (state === ElementHoverState.currentHover ? 'current-hover' : 'hovered') : '')
}

const setNestedElementsState = (className: string, currentDepth: number) => {
  let j = 0
  let threadElement: Element

  do {
    const currentClass = `${className}.${j++}`
    const finalName = `${currentClass}-${currentDepth}`
    threadElement = document.getElementsByClassName(finalName)[0]
    setElementState(threadElement, ElementHoverState.chain)
    if (threadElement) {
      setNestedElementsState(currentClass, currentDepth)
    }
  } while (threadElement)
}

export const maxVisibleDepth = 4

export const hoverStateHandler = (_rootClass: string, hoveredRowID?: string) => {
  if (hoveredRowID && hoveredRowID.length === 1) {
    const threadElements = document.querySelectorAll('[class*=hovered]')
    threadElements.forEach((threadElement) => {
      setElementState(threadElement, ElementHoverState.unset)
    })
    return
  }

  if (hoveredRowID && hoveredRowID.length > 1) {
    const threadLineDepth = hoveredRowID.split('.').length
    const currentDepth = threadLineDepth > maxVisibleDepth + 1 ? maxVisibleDepth - 1 : threadLineDepth - 2
    const lastCharNumber = +hoveredRowID.split('.').pop()!
    const threadElements = document.querySelectorAll('[class*=hovered]')
    threadElements.forEach((threadElement) => {
      setElementState(threadElement, ElementHoverState.unset)
    })

    for (let i = 0; i <= lastCharNumber; i++) {
      const className = `line-${hoveredRowID.slice(0, hoveredRowID.lastIndexOf('.'))}.${i}`
      const finalName = `${className}-${currentDepth}`
      const threadElement = document.getElementsByClassName(finalName)[0]

      setElementState(threadElement, ElementHoverState.chain)

      if (i === lastCharNumber) continue

      setNestedElementsState(className, currentDepth)
    }
  }
}
