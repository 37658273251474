import React, { FC, useCallback, useContext, useEffect, useMemo, useRef } from 'react'

import classNames from 'classnames'

import { StrictSortingState } from '@complex/ListingPage/Hooks/useFrontendFiltering'
import { personalizationFilters, PersonalizationItem, PersonalizationModalContext } from '@complex/Personalization/utils/Personalization.context'
import { getPersonalizationEmptyListingProps, personalizationTableColumns } from '@complex/Personalization/utils/Personalization.utils'
import FolderSearch, { FolderSearchProps } from '@components/FolderSearch/FolderSearch'
import { TableV2 } from '@components/TableV2/TableV2'
import { TableV2Props } from '@components/TableV2/tableV2TS/interfaces'
import { useTranslation } from '@const/globals'
import { RowSelectionState, SortingState } from '@tanstack/react-table'
import { allPersonalizationFieldsFilter, FilterTypes } from '@utils/filter'

import { AddRecipientsBlock } from './AddRecipientsBlock/AddRecipientsBlock'
import { STICKY_TOP_POSITION, STICKY_TOP_POSITION_WITH_BLOCK } from './Personalization.constants'

const rootClass = 'personalization'

const defaultSort: StrictSortingState<PersonalizationItem> = [{ id: 'title', desc: false }]
const recipientsDefaultSort: StrictSortingState<PersonalizationItem> = [{ id: 'listSegmentName', desc: false }]
const senderDefaultSort: StrictSortingState<PersonalizationItem> = [{ id: 'category', desc: false }]

const PersonalizationTableContainer: FC = () => {
  const {
    update,
    onLoadNextPage,
    crm,
    data,
    allData,
    loading,
    programId,
    searchText,
    activeFilter,
    sortingBy,
    selectedItem,
    allItemsLoaded,
    filterLoading,
    selectedListIds,
  } = useContext(PersonalizationModalContext)
  const contentRef = useRef<HTMLDivElement>(null)
  const recipientFieldLoading = selectedListIds && !data && !searchText && activeFilter == FilterTypes.RECIPIENT_LIST_FIELDS
  const tableLoading = recipientFieldLoading || loading || filterLoading

  const { t } = useTranslation()

  const isRecipientListFieldsSelected = activeFilter === FilterTypes.RECIPIENT_LIST_FIELDS
  const isSourceListFieldsSelected = activeFilter === FilterTypes.SOURCE_LIST_FIELDS
  const hasAddRecipientsBlock =
    !searchText &&
    ((isRecipientListFieldsSelected && data?.[0]?.group == FilterTypes.RECIPIENT_LIST_FIELDS) ||
      (isSourceListFieldsSelected && data?.[0]?.group == FilterTypes.SOURCE_LIST_FIELDS))

  useEffect(() => {
    const sort =
      activeFilter === FilterTypes.RECIPIENT_LIST_FIELDS
        ? recipientsDefaultSort
        : activeFilter === FilterTypes.SENDER_FIELDS
        ? senderDefaultSort
        : defaultSort
    update({ sortingBy: sort })
  }, [activeFilter, update])

  const handleSearchTable = useCallback((inAllFolders: boolean) => update({ searchAllData: inAllFolders }), [update])

  const columnsProps = useMemo(() => {
    return {
      activeFilter,
      allData,
      isRecipientListFieldsSelected,
      rootClass,
      searchText,
    }
  }, [activeFilter, allData, isRecipientListFieldsSelected, searchText])

  const emptyListingProps = useMemo(
    () => getPersonalizationEmptyListingProps(searchText, activeFilter, t, update, programId, crm),
    [searchText, activeFilter, t, update, crm]
  )

  const defaultSelectedRows = useMemo<RowSelectionState | undefined>(() => {
    if (selectedItem?.id) {
      return { [selectedItem.id]: true }
    }
  }, [selectedItem?.id])

  const onRowSelectionChanged = useCallback(
    (keys: string[]) => {
      update({
        selectedItem: {
          ...data.find(({ id }) => id === keys[0]),
          fallbackText: selectedItem?.fallbackText,
        } as PersonalizationItem,
      })
    },
    [update, data, selectedItem?.fallbackText]
  )

  const onSort = useCallback((sortingState: SortingState) => update({ sortingBy: sortingState as StrictSortingState<PersonalizationItem> }), [update])

  const tableV2Props: TableV2Props<PersonalizationItem> = useMemo(
    () => ({
      columns: personalizationTableColumns(columnsProps),
      data,
      defaultSelectedRows,
      loading: tableLoading,
      allLoaded: allItemsLoaded,
      enableRadio: true,
      sortingBy,
      enableSorting: true,
      enableStickyHeader: true,
      withoutBorders: true,
      resetSorting: tableLoading,
      enableLazyLoading: true,
      manualSorting: true,
      onSort,
      emptyState: emptyListingProps,
      stickyHeaderTopPosition: searchText || !hasAddRecipientsBlock ? STICKY_TOP_POSITION : STICKY_TOP_POSITION_WITH_BLOCK,
      onLoading: onLoadNextPage,
      onRowSelectionChanged,
      scrollableElementRef: contentRef,
      enableInsideLoader: true,
      rowUniqueIdKey: 'id',
    }),
    [
      columnsProps,
      data,
      defaultSelectedRows,
      tableLoading,
      allItemsLoaded,
      activeFilter,
      onSort,
      emptyListingProps,
      searchText,
      hasAddRecipientsBlock,
      onLoadNextPage,
      onRowSelectionChanged,
    ]
  )

  const folderSearchProps: FolderSearchProps<PersonalizationItem> = {
    tableV2Props,
    data: tableV2Props.data,
    columns: [],
    search: searchText,
    searchAllFilter: allPersonalizationFieldsFilter,
    hideSearchResultsLabel: !tableV2Props.data.length,
    onSearch: handleSearchTable,
    currentFilter: personalizationFilters.find((filter) => filter.name === activeFilter),
    resultsLabel: t('AssetPicker.Search.Results', { count: tableV2Props.data.length, searchTerm: searchText }),
    resultsLabelAboveFilters: true,
    largeResultsLabel: true,
    alwaysShowResultsLabel: true,
    enableTableV2: true,
  }

  useEffect(() => {
    if (!searchText) {
      update({ searchAllData: false })
    }
  }, [defaultSelectedRows, searchText, update])

  return (
    <div
      className={classNames(`${rootClass}__table`, {
        [`${rootClass}__table-with-padding`]: hasAddRecipientsBlock,
      })}
      ref={contentRef}
    >
      {hasAddRecipientsBlock && <AddRecipientsBlock />}
      {searchText ? <FolderSearch {...folderSearchProps} /> : <TableV2 {...tableV2Props} />}
    </div>
  )
}

export default PersonalizationTableContainer
