import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { UseFormReturn, useController } from 'react-hook-form'

import classNames from 'classnames'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import Button, { ButtonType } from '@components/Button'
import EmojiButton from '@components/EmojiButton/EmojiButton'
import InputV2 from '@components/InputV2/InputV2'
import Label from '@components/Label'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import Svg, { SvgNames } from '@components/Svg'
import { SvgColor, SvgType } from '@components/Svg/Svg'
import Tab from '@components/TabsAO/TabAO'
import Tabs, { TabItemData, TabStyle } from '@components/TabsAO/TabsAO'
import TextArea, { TextAreaResizeDirection } from '@components/TextArea/TextArea'
import TimeZoneSelectV2 from '@components/TimeZoneSelectV2/TimeZoneSelectV2'
import Toggle from '@components/Toggle'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { WebinarConfig } from '@graphql/types/query-types'
import { detectWebinarType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { useTranslation } from '@utils/const/globals'
import { ReactSetStateAction } from '@utils/interface/common'
import { getDynamicTimezones, getGmtOffset, sortTimezones } from '@utils/timezones'

import { getTimezoneAbbreviation } from './components/WebinarBlock/utils/webinarBlock.utils'
import { WebinarCustomFields } from './components/WebinarBlock/WebinarBlock'
import {
  ExcludeTypes,
  ManageWebinarDetailsModalForm,
  ManageWebinarDetailsModalTabs,
  convertToTimezone,
} from './utils/ManageWebinarDetailsModal.utils'

import './ManageWebinarDetailsModal.css'

dayjs.extend(utc)
dayjs.extend(timezone)

interface ManageWebinarDetailsModalProps {
  isOpen: boolean
  hasForm?: boolean
  hasRegistration?: boolean
  dataTest?: string
  form: UseFormReturn<ManageWebinarDetailsModalForm>
  activeTab?: ManageWebinarDetailsModalTabs
  webinarConfig?: WebinarConfig
  onCancel: () => void
  onSave: (customFieldValues: Omit<WebinarCustomFields, ExcludeTypes>, webinarId: string) => void
  setActiveTab: ReactSetStateAction<ManageWebinarDetailsModalTabs | undefined>
  defaultTimeZoneValue: string
}

const rootClass = 'manage-webinar-details-modal'

const textAreaStyles = { minHeight: '96px', maxHeight: '174px' }

export const ManageWebinarDetailsModal: FC<ManageWebinarDetailsModalProps> = ({
  form,
  isOpen,
  hasForm,
  activeTab,
  hasRegistration,
  dataTest = rootClass,
  webinarConfig,
  onSave,
  onCancel,
  setActiveTab,
  defaultTimeZoneValue,
}) => {
  const { t } = useTranslation()

  const { isWebex, isGoTo, isAccepted, isReminder, isMultiSession } = detectWebinarType(webinarConfig)

  const hasToggle = !isGoTo
  const noPhoneNumbersToggle = isGoTo || isWebex

  const webinarName = useController({ control: form.control, name: 'webinarName' })
  const webinarDescription = useController({ control: form.control, name: 'webinarDescription' })
  const webinarUrl = useController({ control: form.control, name: 'joinUrl' })
  const webinarPasscode = useController({ control: form.control, name: 'passcode' })
  const webinarId = useController({ control: form.control, name: 'webinarId' })
  const timezone = useController({ control: form.control, name: 'timeZone' })
  const webinarJoinButton = useController({ control: form.control, name: 'joinButtonText' })
  const registrationDescription = useController({ control: form.control, name: 'registrationDescription' })
  const registrationLink = useController({ control: form.control, name: 'registrationLink' })
  const registrationButton = useController({ control: form.control, name: 'registrationButtonText' })
  const startTime = useController({ control: form.control, name: 'startTime' })
  const endTime = useController({ control: form.control, name: 'endTime' })
  const startDate = useController({ control: form.control, name: 'startDate' })
  const webinarDate = useController({ control: form.control, name: 'date' })
  const webinarTime = useController({ control: form.control, name: 'time' })
  const formValues = form.getValues()
  const formToggleValues = formValues.toggleState

  const [toggleState, setToggleState] = useState(formToggleValues)

  const [inputKey, setInputKey] = useState<string>(webinarName.field.value)

  const [date, setDate] = useState<string>(startDate.field.value)

  const defaultDateTimeOptions = useRef({
    defaultStartDate: formValues.startDate,
    defaultStartTime: formValues.startTime,
    defaultEndTime: formValues.endTime,
    defaultTimezone: timezone.field.value.value,
  })

  useEffect(() => {
    if (!noPhoneNumbersToggle) {
      setToggleState((state) => ({ ...state, showPhoneNumbers: form.getValues().toggleState.showPhoneNumbers }))
    }

    if (hasRegistration) {
      setToggleState((state) => {
        const { registrationButtonText, registrationLink } = form.getValues()
        const disableToggle = registrationButtonText === '' && registrationLink === ''
        return { ...state, showRegistrationButton: disableToggle ? false : form.getValues().toggleState.showRegistrationButton }
      })
    }
  }, [form, hasRegistration, noPhoneNumbersToggle])

  const time = `${startTime.field.value} - ${endTime.field.value}`

  const handleTimezoneChange = useCallback((selectedTimezone: string) => {
    const { defaultStartDate, defaultStartTime, defaultEndTime, defaultTimezone } = defaultDateTimeOptions.current
    const result = convertToTimezone(defaultStartDate, defaultStartTime, defaultEndTime, defaultTimezone, selectedTimezone)

    startTime.field.onChange(result.startTime)
    endTime.field.onChange(result.endTime)
    startDate.field.onChange(result.date)

    webinarDate.field.onChange(dayjs.utc(result.date).format('dddd, MMMM DD, YYYY'))
    webinarTime.field.onChange(`${result.startTime} - ${result.endTime} (${getTimezoneAbbreviation(result.date, selectedTimezone)})`)

    setDate(result.date)
  }, [])

  useEffect(() => {
    handleTimezoneChange(timezone.field.value.value)
  }, [timezone])

  const tabData: TabItemData[] = [
    {
      index: ManageWebinarDetailsModalTabs.REGISTRATION,
      label: t('Registration'),
      icon: activeTab === ManageWebinarDetailsModalTabs.REGISTRATION ? SvgNames.formSimpleSelectedWhite : SvgNames.formSimpleOutline,
    },
    {
      index: ManageWebinarDetailsModalTabs.WEBINAR,
      label: t('Webinar'),
      icon: activeTab === ManageWebinarDetailsModalTabs.REGISTRATION ? SvgNames.webinarOutline : SvgNames.webinar,
    },
  ]

  const getWebinarDetailsFields = useCallback(
    (name?: string) => {
      const allowCopy = name !== 'goto'

      const fields = [
        isGoTo &&
          (isAccepted || isReminder) && {
            label: t('Manage.Webinar.Details.Modal.Webinar.Join.Link'),
            field: webinarUrl,
            hasToggle,
            icon: SvgNames.url,
            toggleState: toggleState.showJoinLink,
            placeholder: t('Manage.Webinar.Details.Modal.Webinar.Join.Link.Placeholder'),
            canEdit: false,
            allowCopy,
            toggleName: 'showJoinLink',
          },
        {
          label: t('Manage.Webinar.Details.Modal.Webinar.ID'),
          field: webinarId,
          hasToggle,
          icon: SvgNames.hashtag,
          toggleState: toggleState.showWebinarId,
          placeholder: t('Manage.Webinar.Details.Modal.Webinar.ID.Placeholder'),
          canEdit: !hasToggle,
          allowCopy,
          toggleName: 'showWebinarId',
        },
        (webinarPasscode.field.value || isGoTo) && {
          label: t('Manage.Webinar.Details.Modal.Webinar.Passcode'),
          field: webinarPasscode,
          hasToggle,
          icon: SvgNames.lockLight,
          toggleState: toggleState.showPasscode,
          placeholder: t('Manage.Webinar.Details.Modal.Webinar.Passcode.Placeholder'),
          canEdit: !hasToggle,
          allowCopy,
          toggleName: 'showPasscode',
        },
        (!hasRegistration || (isMultiSession && isWebex && !hasForm)) && {
          label: t('Manage.Webinar.Details.Modal.Webinar.Join.Button'),
          field: webinarJoinButton,
          hasToggle: true,
          toggleState: toggleState.showJoinButton,
          placeholder: t('Manage.Webinar.Details.Modal.Webinar.Join.Button.Placeholder'),
          canEdit: toggleState.showJoinButton,
          allowCopy: false,
          toggleName: 'showJoinButton',
        },
      ]

      return fields
    },
    [
      isGoTo,
      isAccepted,
      isReminder,
      t,
      webinarUrl,
      hasToggle,
      toggleState.showJoinLink,
      toggleState.showWebinarId,
      toggleState.showPasscode,
      toggleState.showJoinButton,
      webinarId,
      webinarPasscode,
      hasRegistration,
      isMultiSession,
      isWebex,
      hasForm,
      webinarJoinButton,
    ]
  )

  const handleToggle = useCallback((field: string, value: boolean) => {
    setToggleState((prevState) => ({
      ...prevState,
      [field]: value,
    }))
  }, [])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value

    webinarName.field.onChange(newValue)
  }

  const timezoneOptions = getDynamicTimezones()
  const initialTimeZoneValue = defaultTimeZoneValue ?? ''

  if (initialTimeZoneValue && !timezoneOptions.find((timeZone) => timeZone.value === initialTimeZoneValue)) {
    timezoneOptions.push({
      label: `${getGmtOffset(initialTimeZoneValue)} ${initialTimeZoneValue}`,
      value: initialTimeZoneValue,
    })
  }

  const renderWebinarDetailsForm = () => {
    return (
      <div className={`${rootClass}__body`}>
        <div className={`${rootClass}__name-wrapper`} data-test={`${dataTest}-name-wrapper`}>
          <InputV2
            labelProps={{ label: <Typography text={t('Manage.Webinar.Details.Modal.Webinar.Name')} weight={TextWeight.MEDIUM} /> }}
            value={webinarName.field.value}
            onChange={handleInputChange}
            key={inputKey}
            dataTest={`${dataTest}-name`}
            onBlurCapture={(event) => setInputKey(event.target.value)}
          />
          <EmojiButton
            onSelect={(emoji) => {
              const newValue = webinarName.field.value.concat(emoji)

              webinarName.field.onChange(newValue)
              setInputKey(newValue)
            }}
            align={'end'}
          />
        </div>
        <div className={`${rootClass}__description-wrapper`} data-test={`${dataTest}-description-wrapper`}>
          <TextArea
            textAreaStyles={textAreaStyles}
            label={
              <Typography text={t('Manage.Webinar.Details.Modal.Webinar.Description')} weight={TextWeight.MEDIUM} className={`${rootClass}__label`} />
            }
            labelWithoutMargin
            name="webinarInformation"
            placeholder={t('Manage.Webinar.Details.Modal.Webinar.Description.Placeholder')}
            maxCharacterProps={{ maxLength: 2000 }}
            resizeDirection={TextAreaResizeDirection.VERTICAL}
            value={webinarDescription.field.value}
            onChange={(e) => webinarDescription.field.onChange(e)}
            dataTest={`${dataTest}-description`}
          />
        </div>
        <div className={`${rootClass}__date-container`}>
          <div className={`${rootClass}__date-wrapper`}>
            <Typography text={t('Start date')} weight={TextWeight.MEDIUM} className={`${rootClass}__label`} />
            <div className={`${rootClass}__date`}>
              <Svg name={SvgNames.calendar} fill={SvgColor.BUTTON_GRAY} type={SvgType.LARGER_ICON} dataTest={`${dataTest}-calendar-svg`} />
              <Typography text={dayjs.utc(date).format('ddd, MMMM DD, YYYY')} dataTest={`${dataTest}-date`} />
            </div>
          </div>
          <div className={`${rootClass}__divider`} />
          <div className={`${rootClass}__time-wrapper`}>
            <Typography text={t('Time')} weight={TextWeight.MEDIUM} className={`${rootClass}__label`} />
            <div className={`${rootClass}__time`}>
              <Svg name={SvgNames.clock} fill={SvgColor.BUTTON_GRAY} type={SvgType.LARGER_ICON} dataTest={`${dataTest}-clock-svg`} />
              <Typography text={time} dataTest={`${dataTest}-time`} />
            </div>
          </div>
          <div className={`${rootClass}__divider`} />
          <div className={`${rootClass}__timezone`}>
            <Typography text={t('Time zone')} weight={TextWeight.MEDIUM} className={`${rootClass}__label`} />

            <TimeZoneSelectV2
              className={`${rootClass}__timezone-select`}
              selectProps={{
                insideModal: true,
                maxMenuHeight: 300,
                options: sortTimezones(timezoneOptions),
                value: timezone.field.value,
                onChange: (e) => {
                  e && timezone.field.onChange(e)
                },
              }}
              label={''}
              dataTest={`${dataTest}-timezone`}
            />
          </div>
        </div>
        <div className={`${rootClass}__webinar-detail-container`} data-test={`${dataTest}-details`}>
          <Typography text={t('Manage.Webinar.Details.Modal.Form.Header')} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
          <div className={`${rootClass}__webinar-detail-field-container`}>
            {getWebinarDetailsFields(webinarConfig?.name).map((details) => {
              if (!details) return
              const { allowCopy, canEdit, field, hasToggle, label, placeholder, toggleName, toggleState, icon } = details

              const clipBoardProps = allowCopy
                ? {
                    copyTooltip: t('Copy'),
                    copySuccessTooltip: t('Copied'),
                  }
                : undefined

              return (
                <div className={`${rootClass}__webinar-detail`} key={label}>
                  <Label>
                    <Typography text={label} weight={TextWeight.MEDIUM} />
                  </Label>
                  <InputV2
                    className={classNames(`${rootClass}__webinar-detail-field`, {
                      [`${rootClass}__webinar-detail-field-disabled`]: hasToggle && !toggleState,
                      [`${rootClass}__webinar-detail-field-gray`]: !canEdit && allowCopy,
                    })}
                    value={field.field.value}
                    onChange={(value) => field.field.onChange(value)}
                    leadingIcon={icon}
                    readOnly={!canEdit}
                    clipBoardProps={clipBoardProps}
                    placeholder={t(placeholder)}
                  />
                  {hasToggle && <Toggle isOn={!!toggleState} onToggle={(e) => handleToggle(toggleName, e)} noRightMargin />}
                </div>
              )
            })}
          </div>
          <div className={`${rootClass}__toggle-wrapper`}>
            {!noPhoneNumbersToggle && (
              <Toggle
                isOn={!!toggleState.showPhoneNumbers}
                label={t('Manage.Webinar.Details.Modal.Phonenumber')}
                onToggle={(value) => handleToggle('showPhoneNumbers', value)}
                noLeftMargin
                dataTest={`${dataTest}-show-phone-numbers`}
              />
            )}
            <Toggle
              isOn={!!toggleState?.showCalendarBlock}
              label={t('Manage.Webinar.Details.Modal.AddToCalendar')}
              onToggle={(value) => handleToggle('showCalendarBlock', value)}
              noLeftMargin
              dataTest={`${dataTest}-add-to-calendar`}
            />
          </div>
        </div>
      </div>
    )
  }

  const renderRegistrationDetailsForm = () => {
    return (
      <div className={`${rootClass}__body`}>
        <div className={`${rootClass}__description-wrapper`}>
          <TextArea
            textAreaStyles={textAreaStyles}
            label={
              <Typography
                text={t('Manage.Webinar.Details.Modal.Registration.Information.Label')}
                weight={TextWeight.MEDIUM}
                className={`${rootClass}__label`}
              />
            }
            labelWithoutMargin
            name="registrationDetails"
            maxCharacterProps={{ maxLength: 2000 }}
            resizeDirection={TextAreaResizeDirection.VERTICAL}
            placeholder={t('Manage.Webinar.Details.Modal.Registration.Information.Placeholder')}
            value={registrationDescription.field.value}
            onChange={(e) => registrationDescription.field.onChange(e)}
            dataTest={`${dataTest}-registration-information`}
          />
        </div>
        <div className={`${rootClass}__registration`}>
          <div className={`${rootClass}__registration-header`} data-test={`${dataTest}-registration-header`}>
            <Typography text={t('Manage.Webinar.Details.Modal.Registration.Button')} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
            <Toggle isOn={!!toggleState.showRegistrationButton} onToggle={(value) => handleToggle('showRegistrationButton', value)} />
          </div>
          {toggleState.showRegistrationButton && (
            <div className={`${rootClass}__registration-body`}>
              <InputV2
                labelProps={{
                  label: <Typography text={t('Manage.Webinar.Details.Modal.Registration.Link')} weight={TextWeight.MEDIUM} />,
                }}
                leadingIcon={SvgNames.url}
                value={registrationLink.field.value}
                onChange={(e) => registrationLink.field.onChange(e)}
                dataTest={`${dataTest}-registration-link`}
                placeholder={t('Manage.Webinar.Details.Modal.Registration.Link.Placeholder')}
              />
              <InputV2
                labelProps={{ label: <Typography text={t('Manage.Webinar.Details.Modal.Registration.Button')} weight={TextWeight.MEDIUM} /> }}
                value={registrationButton.field.value}
                onChange={(e) => registrationButton.field.onChange(e)}
                dataTest={`${dataTest}-registration-button`}
                placeholder={t('Manage.Webinar.Details.Modal.Registration.Button.Placeholder')}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  const modalBody = () => {
    switch (true) {
      case isWebex && isMultiSession && hasRegistration && !hasForm:
        return renderWebinarDetailsForm()
      case hasRegistration:
        return (
          <Tabs
            tabStyle={TabStyle.PILL}
            defaultValue={activeTab}
            onChange={(tab) => setActiveTab(tab as ManageWebinarDetailsModalTabs)}
            childData={tabData}
            className={hasRegistration ? `${rootClass}__tabs` : ''}
            dataTest={`${dataTest}-tabs`}
          >
            <Tab index={ManageWebinarDetailsModalTabs.REGISTRATION} className={`${rootClass}__content`}>
              {renderRegistrationDetailsForm()}
            </Tab>
            <Tab index={ManageWebinarDetailsModalTabs.WEBINAR} className={`${rootClass}__content`}>
              {renderWebinarDetailsForm()}
            </Tab>
          </Tabs>
        )

      default:
        return renderWebinarDetailsForm()
    }
  }

  return (
    <Modal isOpen={isOpen} className={rootClass}>
      <ModalHeader className={`${rootClass}__header`}>
        <Typography
          text={t('Manage.Webinar.Details.Modal.Header')}
          type={TextType.MODAL_HEADLINE}
          lineHeight={LineHeight.LARGE}
          dataTest={`${dataTest}-header`}
        />
      </ModalHeader>
      <ModalBody>{modalBody()}</ModalBody>
      <ModalFooter className={`${rootClass}__footer`}>
        <Button
          buttonType={ButtonType.TERTIARY}
          onClick={onCancel}
          disabled={false}
          className={`${rootClass}__close-button`}
          dataTest="cancel-button"
        >
          {t('Cancel')}
        </Button>
        <Button
          buttonType={ButtonType.PRIMARY}
          className={`${rootClass}__save-button`}
          dataTest="save-button"
          onClick={() =>
            onSave(
              {
                ...formValues,
                toggleState: { ...toggleState },
              },
              webinarId.field.value
            )
          }
        >
          {t('Save webinar')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
