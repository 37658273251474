import React, { FC, useMemo } from 'react'

import classNames from 'classnames'

import { FilterInfoHoverText, ListingPageSubType } from '@complex/ListingPage/Context/ListingPageCommon.context'
import Breadcrumbs from '@components/Breadcrumbs/Breadcrumbs'
import Button, { ButtonType } from '@components/Button/Button'
import Chip from '@components/Chip/Chip'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import Svg, { SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight, TypographyProps } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { FolderDto, LabelDto } from '@graphql/types/microservice/categorization-types'
import { FilterDefinition, commonFilters } from '@utils/filter'
import { svgNamesToCamelCase } from '@utils/folderUtils'

import './FolderTableLabel.css'

export interface FolderTableLabelProps {
  className?: string
  dataTest?: string
  folderPath: FolderData[]
  onFolderClick?: (folderId: number) => void
  search: string
  searchResults: number
  currentTag?: LabelDto
  currentFilter?: FilterDefinition
  currentFolder?: FolderDto
  subTypes?: ListingPageSubType[]
  filterInfoHoverText?: FilterInfoHoverText
  filterCounts?: { [key in string]: number | undefined }
  allItemsFilter: FilterDefinition
  onAllItemsFilterClick: () => void
  onRemoveSubType?: (name: string) => void
}

const rootClass = 'folder-table-label'

const DEFAULT_PARENT_FOLDERS_LENGTH = 30

const FolderTableLabel: FC<FolderTableLabelProps> = (props: FolderTableLabelProps) => {
  const {
    dataTest = rootClass,
    className = '',
    folderPath,
    search,
    searchResults,
    filterInfoHoverText,
    currentTag: tag,
    currentFolder,
    currentFilter,
    allItemsFilter,
    onFolderClick,
    onAllItemsFilterClick,
    filterCounts,
    onRemoveSubType,
    subTypes,
  } = props

  const { t } = useTranslation()

  const hasCustomIcon =
    currentFilter &&
    !commonFilters.includes(currentFilter) &&
    !currentFilter?.name.startsWith('All') &&
    !currentFilter.svgSelected?.startsWith('toggle')

  const getTableLabel = () => t('FolderTableLabel.ResultCount', { count: searchResults, search })

  const isCommonFilter = currentFilter && commonFilters.includes(currentFilter)

  const renderSearchResults = useMemo(
    () => <Typography className={'ellip'} type={TextType.BODY_TEXT} weight={TextWeight.BOLD} text={getTableLabel()} />,
    [searchResults, search]
  )

  const prefix = useMemo(() => ({ text: allItemsFilter.name, onClick: onAllItemsFilterClick }), [])

  const renderCount = (_count?: number, _props: Partial<TypographyProps> = {}) => null
  /*
    TODO: Re-enable this when filter counts take custom filters into account
    count !== undefined ? (
      <Typography type={TextType.BODY_TEXT_LIGHT} text={`(${count})`} className={`${rootClass}__count`} inline {...props} />
    ) : null
  */

  const renderFolderPath = useMemo(() => {
    const folderTree = folderPath.map(({ name, id }) => ({
      text: name,
      onClick: () => id && onFolderClick && onFolderClick(id),
      hasTooltip: true,
    }))
    const count = folderPath.length ? folderPath[folderPath.length - 1].itemCount : undefined

    return (
      <>
        <Breadcrumbs
          className={`${rootClass}__folder-tree__${folderTree.length}`}
          breadcrumbs={folderTree}
          svgName={SvgNames.folderOpened}
          charsLimit={DEFAULT_PARENT_FOLDERS_LENGTH}
        />
        {renderCount(count)}
      </>
    )
  }, [folderPath])

  const getFilterIconProps = () => {
    const commonIconProps = {
      type: SvgType.MEDIUM,
      fill: SvgColor.TEAL,
    }

    const iconSvgName = svgNamesToCamelCase(currentFilter)

    const name: SvgNames = (SvgNames as Record<string, string>)[iconSvgName.svgSelected] as SvgNames

    return { ...commonIconProps, name }
  }

  const renderIcon = () => {
    if (currentFilter?.name === allItemsFilter.name) return
    if (!isCommonFilter && !tag && !hasCustomIcon) return

    return <Svg {...(currentFilter ? getFilterIconProps() : { type: SvgType.MEDIUM, name: SvgNames.tag, style: { fill: `${tag?.color}` } })} />
  }

  const renderText = useMemo(() => {
    const hasIcon = isCommonFilter || tag
    const isAllItems = currentFilter?.name === allItemsFilter.name
    let textNode

    if (tag) {
      textNode = <Typography text={tag.name} inline weight={TextWeight.BOLD} />
    } else if (!isAllItems) {
      textNode = (
        <Tooltip alignTextCenter trigger={<Typography text={t(currentFilter?.name)} inline weight={TextWeight.BOLD} />}>
          <Typography text={t(currentFilter?.name)} type={TextType.BODY_TEXT_WHITE} />
        </Tooltip>
      )
    }

    const filterCountExists = currentFilter && filterCounts && currentFilter.name in filterCounts
    const count = tag ? tag.itemCount : filterCountExists ? filterCounts[currentFilter?.name] : undefined

    return (
      <div
        className={classNames({ [`${rootClass}__text-with-no-icon`]: !hasIcon && !hasCustomIcon, [`${rootClass}__text`]: hasIcon || hasCustomIcon })}
      >
        {textNode}
        {renderCount(count, isAllItems ? { inline: false } : {})}
      </div>
    )
  }, [currentFilter, tag, hasCustomIcon])

  const renderSubTypes = () => {
    if (!subTypes?.length) {
      return null
    }
    return (
      <div className={classNames('flex-align-center', 'ellip', `${rootClass}__sub-types`)}>
        <div className={classNames(`${rootClass}__sub-types__divider`)}></div>
        <Typography text={'Filters:'} type={TextType.TABLE_HEADER} weight={TextWeight.BOLD} />
        {subTypes.map((subType) => (
          <Chip key={subType.name} id={subType.name} name={t(subType.label)} onRemove={() => onRemoveSubType?.(subType.name)} tinyText />
        ))}
      </div>
    )
  }

  const showFilterSeparator = currentFolder || currentFilter || tag

  const renderSuffix = () => {
    return (
      <div className={classNames('flex-align-center', 'ellip')}>
        {currentFolder ? (
          renderFolderPath
        ) : (
          <>
            {renderIcon()}
            {renderText}
          </>
        )}
        {filterInfoHoverText && currentFilter && currentFilter.name in filterInfoHoverText ? (
          <Tooltip
            alignTextCenter
            trigger={<Svg name={SvgNames.info} type={SvgType.ICON} fill={SvgColor.TEXT_GRAY} />}
            className={`${rootClass}__filter-tooltip`}
          >
            <Typography text={t(filterInfoHoverText[currentFilter.name as keyof FilterInfoHoverText])} inline type={TextType.BODY_TEXT_WHITE} />
          </Tooltip>
        ) : null}
      </div>
    )
  }

  const renderPrefix = () =>
    currentFilter?.name === allItemsFilter.name ? (
      <Typography text={t(allItemsFilter.name)} type={TextType.BODY_TEXT} weight={TextWeight.MEDIUM} inline />
    ) : (
      <>
        <Button buttonType={ButtonType.TEXT} onClick={prefix.onClick} className={`${rootClass}__prefix`}>
          <Typography text={t(allItemsFilter.name)} type={TextType.BODY_TEXT} weight={TextWeight.REGULAR} inline />
          {showFilterSeparator && (
            <Svg
              className={`${rootClass}__prefix-separator`}
              name={SvgNames.paginationControlsNext}
              type={SvgType.SMALL_ICON}
              fill={SvgColor.BUTTON_GRAY}
            />
          )}
        </Button>
      </>
    )

  const renderContent = () => {
    return (
      <div className={classNames(`${rootClass}__content`, 'flex-align-center', 'ellip')}>
        {search ? (
          renderSearchResults
        ) : (
          <>
            {renderPrefix()}
            {renderSuffix()}
            {renderSubTypes()}
          </>
        )}
      </div>
    )
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {renderContent()}
    </div>
  )
}

export default FolderTableLabel
