import React, { FC, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import ScrollArea from '@components/ScrollArea/ScrollArea'
import SectionWithHeader from '@components/SectionWithHeader/SectionWithHeader'
import SelectableRow from '@components/SelectableRow/SelectableRow'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import {
  activitiesFilters,
  AlertFilterType,
  filtersIcons,
} from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertFilters/utils/AlertFilters.utils'

import './FilterTypeSelect.css'

interface FilterSelectProps {
  className?: string
  dataTest?: string
  hasSalesOwner?: boolean
  selectedFilter?: AlertFilterType
  onChange?: (filter: AlertFilterType) => void
}

const rootClass = 'filter-type-select'

const FilterTypeSelect: FC<FilterSelectProps> = (props: FilterSelectProps) => {
  const { dataTest = rootClass, className = '', hasSalesOwner, onChange, selectedFilter = AlertFilterType.ALL_VISITORS } = props

  const [showMenu, setShowMenu] = useState(false)
  const [containerWidth, setContainerWidth] = useState(0)

  const containerRef = useRef<HTMLDivElement>(null)
  const menuObserver = useRef(
    new ResizeObserver((entries) => window.requestAnimationFrame(() => Array.isArray(entries) && entries.length && resizeMenu()))
  )

  const { t } = useTranslation()

  const resizeMenu = () => {
    setContainerWidth(containerRef.current?.getBoundingClientRect().width ?? 0)
  }

  const renderSection = (header: string, options: AlertFilterType[]) => {
    return (
      <SectionWithHeader header={t(header)}>
        {options.map((filter) => {
          const isSelected = selectedFilter === filter
          return (
            <SelectableRow
              isSelected={isSelected}
              key={filter}
              onClick={() => {
                onChange && onChange(filter)
                setShowMenu(false)
              }}
            >
              <div className={classNames(`${rootClass}__menu-option`, `${rootClass}__row`, { [`${rootClass}__menu-option-selected`]: isSelected })}>
                <Svg name={filtersIcons[filter]} type={SvgType.LARGE_ICON} className={`${rootClass}__menu-option-icon`} />
                <Typography text={t(filter)} type={TextType.BODY_TEXT} lineHeight={LineHeight.MEDIUM_LARGE} weight={TextWeight.MEDIUM} />
              </div>
            </SelectableRow>
          )
        })}
      </SectionWithHeader>
    )
  }

  useEffect(() => {
    const container = containerRef?.current
    container && menuObserver.current.observe(container.children[0])
  }, [menuObserver, containerRef])

  useEffect(() => {
    resizeMenu()
  }, [containerRef.current])

  const visitorsOptions = [
    AlertFilterType.ALL_VISITORS,
    AlertFilterType.ANONYMOUS_VISITORS,
    ...(hasSalesOwner ? [AlertFilterType.MY_LEADS_AND_CONTACTS, AlertFilterType.MARKETING_PROSPECTS] : [AlertFilterType.KNOWN_VISITORS]),
  ]

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest} ref={containerRef}>
      <DropdownMenu.Root open={showMenu} onOpenChange={(open) => setShowMenu(open)}>
        <DropdownMenu.Trigger
          data-test={`${dataTest}-trigger`}
          className={classNames(`${rootClass}__row`, `${rootClass}__select`, { [`${rootClass}__select-expanded`]: showMenu })}
        >
          <div>
            <Svg name={filtersIcons[selectedFilter]} type={SvgType.LARGE_ICON} />
            <Typography text={t(selectedFilter)} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.MEDIUM} lineHeight={LineHeight.MEDIUM_LARGE} />
          </div>
          <Svg className={`${rootClass}__select-caret`} name={SvgNames.chevronDown} type={SvgType.ICON} />
        </DropdownMenu.Trigger>
        <DropdownMenu.Content data-test={`${dataTest}-content`} style={{ width: `${containerWidth}px` }}>
          <ScrollArea className={`${rootClass}__menu`}>
            {renderSection('Visitors', visitorsOptions)}
            {renderSection('Activities', activitiesFilters)}
          </ScrollArea>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </div>
  )
}

export default FilterTypeSelect
