import { gql } from '@apollo/client'

export default gql`
  mutation duplicateLandingPage($landingPageId: String!, $newName: String, $tags: [LabelDto], $folderId: Int, $includeUnPublishedChanges: Boolean) {
    duplicateLandingPage(
      landingPageId: $landingPageId
      newName: $newName
      tags: $tags
      folderId: $folderId
      includeUnPublishedChanges: $includeUnPublishedChanges
    ) {
      id
      name
    }
  }
`
