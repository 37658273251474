import React, { FC, useContext } from 'react'

import classNames from 'classnames'

import { ModalBody } from '@components/Modal'
import ModalFooterV2 from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import Spinner, { LoaderSize } from '@components/Spinner/Spinner'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { FieldDefinition, StringKeyValue } from '@graphql/types/query-types'
import {
  CRMStepSpecificDateOptions,
  DetailsDataType,
} from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.constants'
import { EditCRMStepV2Context } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.context'
import { getEntityTitle } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.utils'
import useCRM from '@utils/hooks/useCRM'
import { ProgramCreateInCRMStepExt } from '@utils/program/program.constants'

import './CreateInCRMDetailsV2.css'

interface CreateInCRMDetailsV2Props {
  className?: string
  dataTest?: string
  step: ProgramCreateInCRMStepExt
  closeModal?: VoidFunction
  isModal?: boolean
}

const rootClass = 'create-in-crm-details-v2'

const CreateInCRMDetailsV2: FC<CreateInCRMDetailsV2Props> = (props: CreateInCRMDetailsV2Props) => {
  const { dataTest = rootClass, className = '', step, closeModal, isModal = false } = props
  const { additionalEntities = [], insertAlways, pushNewRecords, pushType, staticFields = [], updateExisting, useListPushPolicy } = step

  const {
    values: { fieldDefinitionsByEntity, entitiesReferences, loadingInitialValues, loadingReferences },
  } = useContext(EditCRMStepV2Context)

  const { t } = useTranslation()

  const { connectorType } = useCRM()

  const getFormattedText = (text: string | undefined) => (text ? `<medium>${text}</medium>` : `<italic>${t('undefined')}</italic>`)

  const getIndefiniteArticle = (word: string) => {
    const startsWithVowelRegex = new RegExp(/\b[aeiou]\w*/, 'gi')
    return word.match(startsWithVowelRegex) ? 'an' : 'a'
  }

  const renderEntitiesDescription = () => {
    return additionalEntities.map(({ entityType = '' }) => {
      const entity = getEntityTitle(entityType, true)
      return t(`${getIndefiniteArticle(entity)} {{entity}}`, { entity })
    })
  }

  const renderDescription = () => {
    let text = 'EditCrmStepV2.Details'
    if (pushNewRecords) {
      text = `${text}.AddContacts`
    }
    if (updateExisting) {
      text = `${text}.UpdateContacts`
    }
    if (additionalEntities.length > 0) {
      text = `${text}.AddEntities`
    }
    if (!pushNewRecords && !updateExisting && additionalEntities.length === 0) {
      text = `${text}.NoFieldsSet`
    }

    return <Typography text={text} values={{ crmName: connectorType, entities: renderEntitiesDescription() }} type={TextType.BODY_TEXT_LIGHT} />
  }

  const renderFields = (fields: StringKeyValue[], fieldDefinitions: FieldDefinition[]) => {
    const getFriendlyValue = (value = '', fieldDefinition?: FieldDefinition) => {
      if (fieldDefinition) {
        const { referenceEntity = '' } = fieldDefinition
        switch (fieldDefinition.dataType) {
          case DetailsDataType.DATETIME:
          case DetailsDataType.DATE:
            return value === 'day_0' ? t('Immediately') : t(CRMStepSpecificDateOptions[value]) || t('None')
          case DetailsDataType.REFERENCE:
            return entitiesReferences.get(referenceEntity)?.find(({ key }) => key === value)?.value ?? value
        }
      }
      return value
    }

    return (
      <ul className={`${rootClass}__fields`}>
        {fields.map(({ key, value }, idx) => {
          const fieldDefinition = fieldDefinitions.find(({ fieldName }) => fieldName === key)
          const friendlyValue = getFriendlyValue(value, fieldDefinition)
          return (
            <li key={`${value}-${idx}`}>
              <Typography
                text={`${fieldDefinition?.displayName ?? key}: ${getFormattedText(friendlyValue)}`}
                tagProps={{ medium: { weight: TextWeight.MEDIUM }, italic: { weight: TextWeight.ITALIC } }}
                inline
              />
            </li>
          )
        })}
      </ul>
    )
  }

  const renderCreateNewRecord = () => {
    if (pushNewRecords) {
      const fieldDefinitions = pushType ? fieldDefinitionsByEntity.get(pushType) ?? [] : []
      return (
        <>
          {insertAlways && (
            <Typography text={t('A new record will be created everytime this step runs (allowing duplicates)')} type={TextType.BODY_TEXT_LIGHT} />
          )}
          <div>
            <Typography
              text={`${t('Create new records as')}: ${getFormattedText(pushType)}`}
              tagProps={{ medium: { weight: TextWeight.MEDIUM }, italic: { weight: TextWeight.ITALIC } }}
              inline
            />
          </div>
          {staticFields && (
            <div>
              <Typography text={`${t('Fields to set on new records')}: `} />
              {renderFields(staticFields, fieldDefinitions)}
            </div>
          )}
        </>
      )
    }
  }

  const renderUpdateExistingRecord = () => {
    if (updateExisting) {
      const baseText = 'EditCrmStepV2.StepDetails.RadioOption.ExistingRecords'
      const policy = useListPushPolicy ? `${baseText}.UseListPushPolicy` : insertAlways ? `${baseText}.UpdateAll` : `${baseText}.UpdateBlank`
      return (
        <div>
          <Typography text={`${t('When updating existing records')}: `} />
          <ul>
            <li>
              <Typography text={t(policy)} weight={TextWeight.MEDIUM} />
            </li>
          </ul>
        </div>
      )
    }
  }

  const renderEntities = () => {
    if (additionalEntities.length > 0) {
      return (
        <div>
          <Typography text={`${t('Entities added')}: `} />
          <div>
            {additionalEntities?.map(({ entityType = '', fields = [], entitySingular }, index) => {
              const fieldDefinitions = fieldDefinitionsByEntity.get(entityType) ?? []
              return (
                <div
                  className={classNames({ [`${rootClass}__entity-container-spacing`]: index < additionalEntities.length - 1 })}
                  key={`entity-${entityType}`}
                >
                  <Typography text={entitySingular ?? entityType} weight={TextWeight.MEDIUM} />
                  {fields.length > 0 && renderFields(fields, fieldDefinitions)}
                </div>
              )
            })}
          </div>
        </div>
      )
    }
  }

  const renderContent = () =>
    loadingInitialValues || loadingReferences ? (
      <Spinner size={LoaderSize.MEDIUM} />
    ) : (
      <div className={classNames(rootClass, className)} data-test={dataTest}>
        {renderDescription()}
        {renderCreateNewRecord()}
        {renderUpdateExistingRecord()}
        {renderEntities()}
      </div>
    )

  return isModal ? (
    <>
      <ModalBody>{renderContent()}</ModalBody>
      <ModalFooterV2 onClose={closeModal} />
    </>
  ) : (
    renderContent()
  )
}

export default CreateInCRMDetailsV2
