import React, { FC } from 'react'

import classNames from 'classnames'

import BreadcrumbsItem, { BreadcrumbsItemProps } from '@components/Breadcrumbs/components/BreadcrumbsItem/BreadcrumbsItem'

import './ActionableBreadcrumbsItem.css'

import Button, { ButtonType } from '@components/Button/Button'

type Props = BreadcrumbsItemProps & {
  dataTest?: string
  onClick: () => void
}

const rootClass = 'actionable-breadcrumbs-item'

const ActionableBreadcrumbsItem: FC<Props> = (props: Props) => {
  const { onClick, dataTest = rootClass, className = '', ...breadcrumbsProps } = props
  return (
    <Button buttonType={ButtonType.TEXT} className={classNames(rootClass, className)} data-test={dataTest} onClick={onClick}>
      <BreadcrumbsItem {...breadcrumbsProps} />
    </Button>
  )
}

export default ActionableBreadcrumbsItem
