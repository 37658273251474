import { gql } from '@apollo/client'

export default gql`
  mutation startAutomatedProgram($programId: String!) {
    startAutomatedProgram(programId: $programId) {
      id
      status
      message
      errorCode
      lastRowId
      scheduled
      pending
      needsConfirmation
      reenterContacts
    }
  }
`
