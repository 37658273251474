import React, { FC, useContext } from 'react'

import classNames from 'classnames'

import { defaultMenuActions, MenuActions, isCustomFilterSelected } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { ListingPageCommonContext } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { useSidebarBase } from '@complex/ListingPage/Hooks/useSidebarBase'
import Menu, { MenuItemData } from '@components/Menu/Menu'
import { FilterDefinition } from '@utils/filter'

import AssetPickerSidebar from './AssetPickerSidebar'
import { getSidebarMenuItems } from './MenuItems/AssetPickerSidebarMenuItems'

interface AssetPickerSidebarContainerProps {
  className?: string
}

const rootClass = 'asset-picker-sidebar-container'

const AssetPickerSidebarContainer: FC<AssetPickerSidebarContainerProps> = (props) => {
  const { className } = props
  const {
    setFolder,
    setFilter,
    setTag,
    toggleSubType,
    setSelectedCustomSource,
    onShouldFetch,
    values: {
      filterActive,
      listingPageProps: {
        onFilterChanged,
        sidebarProps: { allItemFilter, customSources },
      },
    },
  } = useContext(ListingPageCommonContext)

  const menuActions: MenuActions = {
    ...defaultMenuActions,
    clickFolder: (folderId: number) => {
      setFolder(folderId)
      onFilterChanged?.()
    },
    clickFilter: (filter: FilterDefinition) => {
      const customSource = customSources?.find((source) => source.filterTrigger?.name === filter.name)
      if (customSource && setSelectedCustomSource) {
        setSelectedCustomSource(customSource)
      } else {
        setFilter(filter, isCustomFilterSelected(allItemFilter, filter))
        onFilterChanged?.(filter)
      }
    },
    clickCustomFilter: (filter: FilterDefinition) => {
      onShouldFetch()
      filter === filterActive ? setFilter(allItemFilter, false) : setFilter(filter, true)
      onFilterChanged?.(filter)
    },
    clickTag: (tag) => {
      setTag(tag, true)
      onFilterChanged?.()
    },
    clickSubType: (subType: string, toggleOffSubTypes?: string[]) => {
      onShouldFetch()
      toggleSubType?.(subType, toggleOffSubTypes)
    },
    selectCustomSource: (customSource) => setSelectedCustomSource?.(customSource),
  }

  const { menuItems } = useSidebarBase(menuActions, getSidebarMenuItems)

  return (
    <AssetPickerSidebar>
      <Menu className={classNames(`${rootClass}__menu`, className)} items={menuItems() as MenuItemData[]} />
    </AssetPickerSidebar>
  )
}

export default AssetPickerSidebarContainer
