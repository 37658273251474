import React, { FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import FTPSyncSegmentDefinition from '@complex/ContactSegments/SegmentInfoHoverCard/components/FTPSyncSegmentDefinition/FTPSyncSegmentDefinition'
import { ListingPageCommonContext } from '@complex/ListingPage/Context/ListingPageCommon.context'
import ExpandableBreadCrumbs from '@components/ExpandableBreadCrumbs/ExpandableBreadCrumbs'
import Loader from '@components/Loader'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { AuditDto, AuditListQuery } from '@graphql/types/microservice/categorization-types'
import { FormSubmissionSummaryDto, WebinarRegistrationActivityDto } from '@graphql/types/microservice/entity-join-types'
import { WebinarType } from '@src/pages/listingPages/FormsJoinView/components/DetailsCardWrapper/utils/DetailsCardWrapper.utils'
import RelatedFormsList from '@src/pages/listingPages/FormsJoinView/components/RelatedFormsList/RelatedFormsList'
import {
  registrationActivityByDescription,
  renderField,
} from '@src/pages/listingPages/FormsJoinView/components/WebinarRegistrationInfoHoverCard/utils/WebinarRegistrationInfoHoverCard.utils'
import { RegistrationsActivities } from '@src/pages/listingPages/FormsJoinView/components/WebinarRegistrationInfoHoverCard/utils/WebinarRegistrationsInfoHoverCard.constants'
import { useFormsJoinViewListingPageEntityJoinRequests } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.entityJoin.graphQL'
import { useFormsJoinViewListingPageRequests } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.graphQL'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import { Segment } from '@utils/contactSegments/contactSegments.utils'
import { buildHeader } from '@utils/folderUtils'
import { logNewRelicError } from '@utils/new-relic.utils'

import './WebinarRegistrationInfoHoverCard.css'

interface WebinarRegistrationInfoHoverCardProps {
  className?: string
  dataTest?: string
  segment: Segment
  syncsWithFtp?: boolean
  hasExport?: boolean
  onFtpViewLogAction?: (target: string | undefined) => void
}

interface WebinarRegistrationInfoHoverCardState {
  recentBaseActivities?: WebinarRegistrationActivityDto
  recentActivitiesAudits?: AuditListQuery
  recentActivities?: WebinarActivity[]
  relatedForms?: FormSubmissionSummaryDto[]
  loadingActivities: boolean
}

interface WebinarActivity {
  activity: ReactNode
  timeStamp: number
}

export const webinarRegistrationInfoHoverCardRootClass = 'webinar-registration-info-hover-card'

const WebinarRegistrationInfoHoverCard: FC<WebinarRegistrationInfoHoverCardProps> = (props: WebinarRegistrationInfoHoverCardProps) => {
  const { dataTest = webinarRegistrationInfoHoverCardRootClass, className = '', segment, syncsWithFtp, hasExport = false, onFtpViewLogAction } = props

  const { t } = useTranslation()

  const { userName: loggedUser } = useAccountSettings()

  const [state, setState] = useState<WebinarRegistrationInfoHoverCardState>({
    loadingActivities: true,
  })

  const { recentBaseActivities, recentActivitiesAudits, recentActivities, loadingActivities, relatedForms } = state

  const {
    values: { folders },
  } = useContext(ListingPageCommonContext)

  const folderTreeBreadcrumbs: string[] = useMemo(() => buildHeader(segment.folderId as number, folders).reverse(), [segment, folders])

  const { getActivitiesAudits } = useFormsJoinViewListingPageRequests()
  const { getWebinarRecentActivitiesRequest, getWebinarRelatedFormsRequest } = useFormsJoinViewListingPageEntityJoinRequests()

  useEffect(() => {
    const webinarId = 'webinarId' in segment ? (segment.webinarId as string) : ''
    const webinarType = segment.subTypeDTO && segment.subTypeDTO[0] && ((segment.subTypeDTO[0].name as string).toUpperCase() as WebinarType)
    if (webinarType) {
      getWebinarRecentActivitiesRequest(webinarId, webinarType).then((recentActivities) =>
        setState((state) => ({ ...state, recentBaseActivities: recentActivities }))
      )
      getWebinarRelatedFormsRequest(webinarId, webinarType)
        .then((relatedForms) => setState((state) => ({ ...state, relatedForms: relatedForms })))
        .catch((error) => {
          logNewRelicError(error)
        })
    }
    getActivitiesAudits(segment.externalId, ItemType.WEBINAR_SUBMISSION).then((activitiesAudits) =>
      setState((state) => ({ ...state, recentActivitiesAudits: activitiesAudits as AuditListQuery }))
    )
  }, [])

  useEffect(() => {
    if (recentBaseActivities && recentActivitiesAudits) {
      const webinarId = 'webinarId' in segment ? (segment.webinarId as string) : ''
      const activitiesArray: WebinarActivity[] = [
        ...(webinarId
          ? [
              ...(recentBaseActivities.mostRecentRegistration
                ? [
                    {
                      activity: registrationActivityByDescription[RegistrationsActivities.LAST_REGISTRATION]('', t),
                      timeStamp: recentBaseActivities.mostRecentRegistration,
                    },
                  ]
                : []),
              ...(recentBaseActivities.firstRegistration
                ? [
                    {
                      activity: registrationActivityByDescription[RegistrationsActivities.FIRST_REGISTRATION]('', t),
                      timeStamp: recentBaseActivities.firstRegistration,
                    },
                  ]
                : []),
              ...recentBaseActivities.amountOfRegistrationsByDate?.reduce((result: WebinarActivity[], registration) => {
                return [
                  ...result,
                  {
                    activity: (
                      <Typography
                        text={t(`ListPage.FormsJoinView.WEBINAR_REGISTRATION.InfoHover.RecentActivities.NewRegistrations`)}
                        values={{ amountOfRegistrations: registration.amount }}
                        weight={TextWeight.BOLD}
                        type={TextType.BODY_TEXT_SMALL}
                      />
                    ),
                    timeStamp: registration.date,
                  },
                ]
              }, []),
            ]
          : []),
        ...(recentActivitiesAudits as AuditDto[]).reduce((result: WebinarActivity[], audit) => {
          const userName = audit.username === loggedUser ? 'you' : audit.username?.substring(0, audit.username?.indexOf(' ') + 2)
          return ['CUSTOM', 'CREATED'].includes(audit.auditType || '')
            ? [
                ...result,
                {
                  activity: registrationActivityByDescription[
                    audit.auditType === 'CUSTOM' ? (audit?.description as RegistrationsActivities) : RegistrationsActivities.CREATED
                  ](userName ?? '', t),
                  timeStamp: audit?.createdTime,
                },
              ]
            : result
        }, []),
      ]
      setState((state) => ({
        ...state,
        recentActivities: activitiesArray.sort((a, b) => (a.timeStamp < b.timeStamp ? 1 : -1)).slice(0, 5),
        loadingActivities: false,
      }))
    }
  }, [recentBaseActivities, recentActivitiesAudits])

  return (
    <div className={classNames(webinarRegistrationInfoHoverCardRootClass, className)} data-test={dataTest}>
      <div className={`${webinarRegistrationInfoHoverCardRootClass}__section-tree`}>
        {syncsWithFtp && <FTPSyncSegmentDefinition onFtpViewLogAction={onFtpViewLogAction} hasExport={hasExport} segment={segment} />}
        {folderTreeBreadcrumbs.length !== 0 && (
          <>
            <Typography
              text={t('ListPage.FormsJoinView.WEBINAR_REGISTRATION.InfoHover.Location')}
              type={TextType.BODY_TEXT_LIGHT_TINY}
              weight={TextWeight.MEDIUM}
              inline
              className={`${webinarRegistrationInfoHoverCardRootClass}__section-title`}
            />
            <ExpandableBreadCrumbs
              crumbs={folderTreeBreadcrumbs}
              baseCrumb={t('ListPage.FormsJoinView.WEBINAR_REGISTRATION.InfoHover.BaseFolderCrumb')}
            />
            <div className={`${webinarRegistrationInfoHoverCardRootClass}__divider`} />
          </>
        )}
        <div className={`${webinarRegistrationInfoHoverCardRootClass}__section-related`}>
          <Typography
            text={t('ListPage.FormsJoinView.WEBINAR_REGISTRATION.InfoHover.RelatedForms')}
            type={TextType.BODY_TEXT_LIGHT_TINY}
            weight={TextWeight.MEDIUM}
            className={`${webinarRegistrationInfoHoverCardRootClass}__section-title`}
          />
        </div>
        {!relatedForms ? (
          <Loader className={`${webinarRegistrationInfoHoverCardRootClass}__loader`} />
        ) : (
          <RelatedFormsList relatedForms={relatedForms} nameMaxWidth={24} isWebinarRegistration />
        )}
        <div className={`${webinarRegistrationInfoHoverCardRootClass}__divider`} />
        <div className={`${webinarRegistrationInfoHoverCardRootClass}__section-recent`}>
          <Typography
            text={t('ListPage.FormsJoinView.WEBINAR_REGISTRATION.InfoHover.RecentActivities')}
            type={TextType.BODY_TEXT_LIGHT_TINY}
            weight={TextWeight.MEDIUM}
            inline
            className={`${webinarRegistrationInfoHoverCardRootClass}__section-title`}
          />
        </div>
        {loadingActivities && <Loader className={`${webinarRegistrationInfoHoverCardRootClass}__loader`} />}
        {recentActivities && (
          <div className={`${webinarRegistrationInfoHoverCardRootClass}__activities`}>
            {recentActivities.map(({ activity, timeStamp }, i) => renderField(activity, timeStamp, i))}
          </div>
        )}
      </div>
    </div>
  )
}

export default WebinarRegistrationInfoHoverCard
