import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Row } from 'react-table'

import { FolderActions, getFolderById, MAX_FOLDER_DEPTH } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { Item } from '@components/ActionableNestedTable/components/NestedTableRowWithDnD/NestedTableRowWithDnD'
import { MenuItem } from '@components/DropDownActions/DropDownActions'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { Status } from '@components/StatusToast/StatusToast'
import SvgNames from '@components/Svg/SvgNames'
import { HeaderAction, RowAction } from '@components/Table/Table'
import { rootContext, useTranslation } from '@const/globals'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import { ListPageStatusToast } from '@interface/ListPage.context'
import { useAddContactsFromCRMRequests } from '@src/pages/ContactSegments/components/AddContactsFromCRM/GraphQL/AddContactsFromCRMRequests.crm.graphQL'
import {
  getBouncesSegmentsHeaderActions,
  getBouncesSegmentsRowActions,
} from '@src/pages/ContactSegments/components/ContactSegmentsActions/ContactPreferencesActions/BouncesSegments.actions'
import {
  doExport,
  hasSourceOptionsUtils,
  onHeaderSendEmail,
  onHeaderSetFavorite,
  removeSegmentsFromFolder,
} from '@src/pages/ContactSegments/components/ContactSegmentsActions/ContactSegmentsActions.utils'
import {
  getSegmentsHeaderActions,
  getSegmentsRowActions,
  SegmentRowActions,
  SegmentTableActions,
  TagManagerParams,
} from '@src/pages/ContactSegments/components/ContactSegmentsActions/Segments.actions'
import { SegmentAsset } from '@src/pages/ContactSegments/components/ContentWarning/ContentWarning'
import { NON_INITIALIZED_UCL_TOOLTIP } from '@src/pages/ContactSegments/utils/ContactSegments.constants'
import { CloneSegmentServiceMessages, getAssetsUtils } from '@src/pages/ContactSegments/utils/ContactSegmentsContainerUtils'
import { SEGMENTS_LIST_URL } from '@src/pages/ContactSegments/utils/ContactSegmentsSession.utils'
import { IMPORT_CONTACTS_URL } from '@src/pages/importcontacts/utils/ImportContactsContainerUtils'
import { useAIAudienceInsights } from '@src/pages/RecommendedSegments/utils/useAIAudienceInsights'
import { openSegmentComposerToCreate } from '@src/pages/SegmentComposer/SegmentComposer.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import { getSegmentByIndex, goEditCRMSourcedSegment, Segment, syncCrmSegment } from '@utils/contactSegments/contactSegments.utils'
import { ContactSegmentsContext } from '@utils/contactSegments/context/ContactSegmentsContext'
import useCRM from '@utils/hooks/useCRM'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { DeleteConfirmationModals } from '@utils/listPageDeleteModals'
import { MenuActions } from '@utils/menuItems'
import { TagRowActions } from '@utils/tags'

interface Props {
  children: (props: ActionsData) => JSX.Element
  className?: string
  dataTest?: string
  isContactPreferences?: boolean
  openDropdown: (open: boolean) => void
  hasUCLInitialized?: boolean
  hasUpgradeHelperAction: boolean
}

export interface ContactSegmentsActionsState {
  addToFolder: boolean
  hoveredFolder?: FolderData
  isEditingFolder: boolean
  segmentAssetToDelete?: SegmentAsset[]
  segmentToClone?: Segment
  segmentsToDelete?: Segment[]
  segmentsToMove: Segment[]
  selectedRows: Row[]
  selectedSegments: Segment[]
  showAddContactsFromCRMLink: boolean
  tagToEdit?: LabelDto
  segmentToExport?: Segment
}

interface ActionsData extends ContactSegmentsActionsState {
  callMenuAction: (action: MenuActions, data?: any) => void
  clearModals: () => void
  clearSegmentAssets: () => void
  clearSegmentsToDelete: () => void
  dropDownActions: MenuItem[]
  headerActions: HeaderAction[]
  onMoveToFolder: (folderId: number, segments?: Segment[] | undefined) => void
  onRowSelect: (rows: Row[]) => void
  onRowSelectionChanged: (rows: Row[]) => void
  onSegmentExport: () => void
  rowActions: RowAction[]
}

const ContactSegmentsActions: FC<Props> = (props: Props) => {
  const { className = '', children, isContactPreferences, openDropdown, hasUCLInitialized, hasUpgradeHelperAction } = props

  const {
    values: {
      activeFolderId,
      folders,
      filterActive,
      items: segments,
      search,
      searchAllItems: searchAllSegments,
      searchItemsResults: searchSegmentsResults,
      tags,
      loading,
      isProcessingAction,
      isFtpConnectorActive,
    },
    applyAndRemoveTags,
    createTag,
    getItemTypesUsedInTags,
    moveItemsIntoFolder: moveSegmentsIntoFolder,
    refreshRecordCount,
    regenerateSplits,
    setFavoriteItems: setFavoriteSegments,
    setFolder,
    setTag,
    update,
    getFtpSyncJobsList,
  } = useContext(ContactSegmentsContext)

  const [state, setState] = useState<ContactSegmentsActionsState>({
    addToFolder: false,
    isEditingFolder: false,
    segmentAssetToDelete: undefined,
    segmentToClone: undefined,
    segmentsToDelete: undefined,
    segmentsToMove: [],
    selectedRows: [],
    selectedSegments: [],
    showAddContactsFromCRMLink: false,
  })

  const {
    addToFolder,
    hoveredFolder,
    isEditingFolder,
    segmentAssetToDelete,
    segmentToClone,
    segmentsToDelete,
    segmentsToMove,
    selectedRows,
    selectedSegments,
    showAddContactsFromCRMLink,
    tagToEdit,
    segmentToExport,
  } = state

  const accountSettings = useAccountSettings()
  const { userAllowedToDelete, userAllowedToDownload, userAllowedToCreate, disableSegmentModifications, hasNewSegmentComposerUI } = accountSettings
  const { connectorType, hasCRMConnected } = useCRM()
  const { isRegistered } = useAIAudienceInsights()

  const { t } = useTranslation()
  const history = useHistory()

  const { client: segmentClient } = useMicroserviceClient({ serviceName: MicroserviceClients.SEGMENT })
  const { addFromCRMEnabledRequest, setSuccessfulSyncFlagRequest, syncNowRequest } = useAddContactsFromCRMRequests()

  const { hasBouncesSegmentsSelected, hasRegularSegmentsSelected } = useMemo(() => {
    const itemTypes = [...new Set(selectedSegments.map(({ itemType }) => itemType))]
    return { hasBouncesSegmentsSelected: itemTypes.includes(ItemType.BOUNCE), hasRegularSegmentsSelected: itemTypes.includes(ItemType.SEGMENT) }
  }, [selectedSegments])

  useEffect(() => {
    if (hasCRMConnected && hasUCLInitialized) {
      hasSourceOptions()
    }
  }, [])

  useEffect(() => {
    if (selectedRows.length > 0 && !loading && !isProcessingAction) {
      const selectedSegments = selectedRows.reduce((selectedSegments: Segment[], row: Row) => {
        const segment = getSegmentByIndex(row.id, segments)
        return segment ? [...selectedSegments, segment] : selectedSegments
      }, [])
      setState((state) => ({ ...state, selectedSegments }))
    }
  }, [segments])

  const hasSourceOptions = useCallback(() => hasSourceOptionsUtils(connectorType, addFromCRMEnabledRequest, setState, update), [])

  const tagManagerParams: TagManagerParams = {
    onApplyAndRemoveTags: applyAndRemoveTags,
    onCreateTag: createTag,
    selectedSegmentsIds: selectedSegments.map(({ id }) => id),
    t,
    tags,
  }

  const doSyncCrmSegment = useCallback(
    async (syncedSegmentId: number | undefined) => {
      const showStatusToast = (statusToast: ListPageStatusToast) => {
        update({ statusToast })
      }
      return syncCrmSegment(setSuccessfulSyncFlagRequest, syncNowRequest, t, syncedSegmentId, showStatusToast)
    },
    [setSuccessfulSyncFlagRequest, syncNowRequest, t, update]
  )

  const dropDownActions: MenuItem[] = [
    {
      text: t('Add contact'),
      icon: SvgNames.addContactsNoFill,
      disabled: disableSegmentModifications,
      hasTooltip: disableSegmentModifications ? true : undefined,
      tooltipMessage: disableSegmentModifications ? t('Segment.Tooltip.Disabled.Migration') : undefined,
      onClick: () => history.push(`${rootContext}/classic/if/_lists/marketingListing.jsp?addContact&back=${SEGMENTS_LIST_URL}`),
    },
    {
      text: t('Import contacts'),
      icon: SvgNames.uploadCloud2,
      disabled: !userAllowedToCreate || disableSegmentModifications,
      tooltipMessage: disableSegmentModifications ? t('Segment.Tooltip.Disabled.Migration') : t('Ask your administrator for permission to do this'),
      hasTooltip: true,
      onClick: () => (isFtpConnectorActive ? update({ selectingImportContactsSource: true }) : history.push(`${rootContext}${IMPORT_CONTACTS_URL}`)),
    },
    ...(hasNewSegmentComposerUI
      ? [
          {
            text: t('Add direct select segment'),
            icon: SvgNames.directSelect,
            onClick: () => openSegmentComposerToCreate('direct'),
          },
        ]
      : []),
    ...(showAddContactsFromCRMLink
      ? [
          {
            text: t('Add segment from CRM'),
            icon: SvgNames.optimize,
            disabled: disableSegmentModifications,
            hasTooltip: true,
            tooltipMessage: disableSegmentModifications ? t('Segment.Tooltip.Disabled.Migration') : undefined,
            onClick: () => goEditCRMSourcedSegment(),
          },
        ]
      : []),
    ...(isRegistered
      ? [
          {
            text: t('AI Audience Insights'),
            icon: SvgNames.usersUnselectedFull,
            onClick: () => history.push(`${rootContext}/contacts/recommendations?redirect`),
          },
        ]
      : []),
    ...(hasUpgradeHelperAction
      ? [
          {
            text: t('Show upgrade helper'),
            disabled: disableSegmentModifications,
            hasTooltip: true,
            topSection: true,
            tooltipMessage: disableSegmentModifications ? t('Segment.Tooltip.Disabled.Migration') : undefined,
            icon: SvgNames.lightBulbUnselected,
            onClick: () => update({ copySegmentsDone: false, showUpgradeBanner: true, allContactsUpgradeDone: false }),
          },
        ]
      : []),
    {
      text: t('Contact settings'),
      topSection: !hasUpgradeHelperAction,
      icon: SvgNames.settingsThreeLines,
      onClick: () => history.push(`${rootContext}/contactSettings?redirect`),
    },
  ].map((action) => ({
    ...action,
    disabled: !hasUCLInitialized || action.disabled,
    hasTooltip: !hasUCLInitialized || action.hasTooltip,
    tooltipMessage: !hasUCLInitialized ? NON_INITIALIZED_UCL_TOOLTIP : action.tooltipMessage,
  }))

  const tableRowActions: { [key in SegmentRowActions]: (segment: Segment) => void } = {
    [SegmentRowActions.CLONE]: (segment) => {
      if (segment.type === 'Direct Select') {
        update({
          statusToast: {
            statusMessage: CloneSegmentServiceMessages.DIRECT_SEGMENT_NOT_ALLOWED,
            status: Status.FAIL,
            showStatusToast: true,
          },
        })
      } else {
        setState((state) => ({ ...state, segmentToClone: segment }))
      }
    },
    [SegmentRowActions.DELETE]: (segment) => getAssetsUtils([segment], setState, segmentClient),
    [SegmentRowActions.DELETE_ALL_CONTACTS]: (segment) => {
      update({
        deleteConfirmationData: [segment],
        confirmationModal: DeleteConfirmationModals.DELETE_ALL_CONTACTS,
      })
    },
    [SegmentRowActions.DELETE_FROM_FOLDER]: (segment) => {
      removeSegmentsFromFolder(update, [segment])
      openDropdown(false)
    },
    [SegmentRowActions.EXPORT]: (segment) => {
      if (isFtpConnectorActive) {
        setState((state) => ({ ...state, segmentToExport: segment }))
        update({ selectingExportAction: true })
      } else {
        doExport(segment, update, t)
      }
    },
    [SegmentRowActions.MOVE_TO_FOLDER]: (segment) => {
      setState({ ...state, addToFolder: !segment.folderId, segmentsToMove: [segment] })
      openDropdown(false)
    },
    [SegmentRowActions.REFRESH_COUNT]: ({ externalId }) => refreshRecordCount([externalId]),
    [SegmentRowActions.SET_FAVORITE]: ({ id, isFavorite, itemType }) => setFavoriteSegments([id], !isFavorite, itemType),
    [SegmentRowActions.SYNC_CRM_SEGMENT]: ({ syncedSegmentId }) => doSyncCrmSegment(syncedSegmentId),
    [SegmentRowActions.EDIT_CRM]: (segment) => goEditCRMSourcedSegment(segment),
    [SegmentRowActions.SPLIT]: ({ externalId }) => history.push(`${rootContext}/segments/splits/${externalId}`),
    [SegmentRowActions.REGENERATE_SPLIT]: ({ externalId }) => regenerateSplits(externalId),
    [SegmentRowActions.MANAGE_FTP_SYNC]: (segment) => getFtpSyncJobsList(segment),
  }

  const tableActions: { [key in SegmentTableActions]: () => void } = {
    [SegmentTableActions.DELETE]: () => getAssetsUtils(selectedSegments, setState, segmentClient),
    [SegmentTableActions.MANAGE_TAGS]: () => undefined,
    [SegmentTableActions.MOVE_TO_FOLDER]: () => {
      const addToFolder = !selectedSegments.some(({ folderId }) => folderId)
      setState({ ...state, addToFolder, segmentsToMove: selectedSegments })
    },
    [SegmentTableActions.REFRESH_COUNT]: () => refreshRecordCount(selectedSegments.map(({ externalId }) => externalId)),
    [SegmentTableActions.SEND_EMAIL]: () => onHeaderSendEmail(selectedSegments),
    [SegmentTableActions.SEND_SMS]: () => undefined,
    [SegmentTableActions.SET_FAVORITE]: () =>
      onHeaderSetFavorite(selectedSegments, setFavoriteSegments, isContactPreferences ? ItemType.BOUNCE : ItemType.SEGMENT),
    [SegmentTableActions.DELETE_FROM_FOLDER]: () => removeSegmentsFromFolder(update, selectedSegments),
  }

  const menuActions = {
    [MenuActions.DROP_ITEM]: ({ item: { rows }, folder }: { item: Item; folder: FolderData }) => onDropSegment(rows, folder),
    [MenuActions.CLICK_FOLDER]: (folderId: number) => setFolder(folderId),
    [MenuActions.CLICK_FOLDER_ACTION]: ({ folder, action }: { folder: FolderData; action: FolderActions }) => callFolderAction(action, folder),
    [MenuActions.ADD_FOLDER]: () => onAddFolder(),
    [MenuActions.ADD_TAG]: () => onAddTag(),
    [MenuActions.CLICK_TAG_ACTION]: ({ tag, action }: { tag: LabelDto; action: TagRowActions }) => callTagAction(action, tag),
    [MenuActions.CLICK_TAG]: ({ id }: LabelDto) => setTag(id),
    [MenuActions.CLICK_CUSTOM_FILTER]: () => null,
  }

  const folderRowActions = {
    [FolderActions.RENAME]: (folder: FolderData) => {
      setState({ ...state, hoveredFolder: folder, isEditingFolder: true })
      update({ creatingFolder: true })
    },
    [FolderActions.DELETE]: (folder: FolderData) => {
      update({
        deleteConfirmationData: folder,
        confirmationModal: folder.parentId ? DeleteConfirmationModals.DELETE_FOLDER_WITH_PARENT : DeleteConfirmationModals.DELETE_FOLDER,
      })
    },
    [FolderActions.ADD_SUBFOLDER]: (folder: FolderData) => {
      setState({ ...state, hoveredFolder: folder })
      update({ creatingFolder: true })
    },
  }

  const tagRowActions = {
    [TagRowActions.EDIT]: (tag: LabelDto) => {
      setState({ ...state, tagToEdit: tag })
      update({ showManageTag: true })
    },
    [TagRowActions.DELETE]: async (tag: LabelDto) => {
      const itemTypesUsedInTags = await getItemTypesUsedInTags(tag.id)
      update({
        itemTypesUsed: itemTypesUsedInTags ?? undefined,
        deleteConfirmationData: tag,
        confirmationModal: DeleteConfirmationModals.DELETE_TAG,
      })
    },
  }

  const callFolderAction = (action: FolderActions, folder: FolderData) => {
    if (folderRowActions.hasOwnProperty(action)) {
      folderRowActions[action](folder)
    }
  }

  const callTagAction = (action: TagRowActions, tag: LabelDto) => {
    if (tagRowActions.hasOwnProperty(action)) {
      tagRowActions[action](tag)
    }
  }

  const callMenuAction = (action: MenuActions, data: any) => {
    if (menuActions.hasOwnProperty(action)) {
      menuActions[action](data)
    }
  }

  const callRowAction = (action: SegmentRowActions, { original }: Row<Segment>) => {
    if (tableRowActions.hasOwnProperty(action)) {
      tableRowActions[action](original)
    }
  }

  const callTableAction = (action: SegmentTableActions) => {
    if (tableActions.hasOwnProperty(action)) {
      tableActions[action]()
    }
  }

  const rowActions = useMemo(
    () =>
      isContactPreferences
        ? getBouncesSegmentsRowActions(className, callRowAction, userAllowedToDelete, userAllowedToDownload)
        : getSegmentsRowActions(
            className,
            SEGMENTS_LIST_URL,
            activeFolderId,
            setState,
            callRowAction,
            search,
            searchAllSegments,
            isFtpConnectorActive,
            t,
            accountSettings
          ),
    [activeFolderId, filterActive, isContactPreferences, search, searchSegmentsResults, segments, isFtpConnectorActive, accountSettings]
  )

  const headerActions: HeaderAction[] = useMemo(
    () =>
      hasBouncesSegmentsSelected
        ? getBouncesSegmentsHeaderActions(className, selectedSegments, callTableAction, hasRegularSegmentsSelected)
        : getSegmentsHeaderActions(
            className,
            selectedSegments,
            callTableAction,
            tagManagerParams,
            userAllowedToDelete,
            disableSegmentModifications,
            t
          ),
    [selectedSegments, filterActive, search, searchSegmentsResults, segments, isContactPreferences, disableSegmentModifications]
  )

  const onMoveToFolder = (folderId: number, segments?: Segment[]) => {
    if (segments?.length || segmentsToMove.length) {
      moveSegmentsIntoFolder(segments ?? segmentsToMove, folderId, addToFolder)
      setState({ ...state, segmentsToMove: [] })
    }
  }

  const onDropSegment = (rows: Row[], folder: FolderData) => {
    const selectedSegments = rows.map(({ original }) => original as Segment)
    onMoveToFolder(folder.id, selectedSegments)
  }

  const onAddTag = () => {
    setState({ ...state, tagToEdit: undefined })
    update({ showManageTag: true })
  }

  const onAddFolder = () => {
    const folder = activeFolderId ? getFolderById(activeFolderId, folders) : undefined
    if (folder?.depth === MAX_FOLDER_DEPTH) {
      update({
        statusToast: {
          statusMessage: `${folder.name} ${t('has reached the max subfolder limit')}`,
          showStatusToast: true,
          status: Status.FAIL,
        },
      })
    } else {
      update({ creatingFolder: true })
    }
  }

  const onRowSelect = (rows: Row[]) =>
    setState((state) => ({ ...state, selectedRows: rows, selectedSegments: rows.map(({ original }) => original as Segment) }))

  const clearModals = () => {
    update({
      creatingFolder: false,
      selectingImportContactsSource: false,
      selectingFtpFile: false,
      selectingFtpExports: false,
      selectingExportAction: false,
      creatingFtpFile: false,
    })
    setState((state) => ({
      ...state,
      hoveredFolder: undefined,
      isEditingFolder: false,
      segmentToClone: undefined,
      segmentsToMove: [],
    }))
  }

  const clearSegmentAssets = () => setState((state) => ({ ...state, segmentAssetToDelete: undefined }))

  const clearSegmentsToDelete = () => setState((state) => ({ ...state, segmentsToDelete: undefined }))

  const onSegmentExport = () => {
    setState((state) => ({ ...state, segmentToExport: undefined }))
    update({ selectingExportAction: false })
  }

  const renderProps: ActionsData = {
    addToFolder,
    dropDownActions,
    headerActions,
    hoveredFolder,
    isEditingFolder,
    rowActions,
    segmentAssetToDelete,
    segmentToClone,
    segmentsToDelete,
    segmentsToMove,
    selectedRows,
    selectedSegments,
    showAddContactsFromCRMLink,
    tagToEdit,
    segmentToExport,
    clearModals,
    clearSegmentAssets,
    clearSegmentsToDelete,
    callMenuAction,
    onMoveToFolder,
    onRowSelect,
    onRowSelectionChanged: onRowSelect,
    onSegmentExport,
  }

  return children(renderProps)
}

export default ContactSegmentsActions
