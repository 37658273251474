import React, { useCallback, useMemo } from 'react'

import classNames from 'classnames'

import { LinkTextButton } from '@components/LinkTextButton/LinkTextButton'
import Modal, { ModalBody } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import ModalFooterV2, { ModalFooterV2ButtonProps } from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import ModalHeaderV2 from '@components/Modal/components/ModalHeaderV2/ModalHeaderV2'
import SplitButton, { SplitButtonType } from '@components/SplitButton/SplitButton'
import Typography, { LineHeight, ModalHeaderStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useAccountSettings } from '@utils/account/account.utils'
import { legacyActonContext, rootContext, useTranslation } from '@utils/const/globals'

import './BouncesAndOptOutsModal.css'

const rootClass = 'bouncesAndOptOuts'

const link = 'https://connect.act-on.com/hc/en-us/articles/360023757134-Handling-Generic-and-Role-based-Email-Addresses'

interface BouncesAndOptOutsModalProp {
  onCloseModal: VoidFunction
  isOpen: boolean
}

export const BouncesAndOptOutsModal = ({ onCloseModal, isOpen }: BouncesAndOptOutsModalProp) => {
  const { t } = useTranslation()

  const { gaLegacyEmail } = useAccountSettings()

  const header = useMemo(
    () => (
      <ModalHeaderV2
        headerType="list"
        className={`${rootClass}__header`}
        headerText={<Typography text={t('bouncesAndOptOuts.header.title')} {...ModalHeaderStyle} />}
      />
    ),
    [t]
  )

  const handleOptInCampaign = useCallback(() => {
    window.open(`${rootContext}/content/emails/new?messageConfigurationType=opt_in&quickStart=true`)
    onCloseModal()
  }, [onCloseModal])

  const handleLegacyCreate = useCallback(() => {
    window.open(`${legacyActonContext}/_compose/w_StartOptInWizard.jsp?ts=${new Date().getTime()}`)
    onCloseModal()
  }, [onCloseModal])

  const closeModal = useCallback(() => {
    onCloseModal()
  }, [onCloseModal])

  const footerButtons: ModalFooterV2ButtonProps = useMemo(() => {
    const computedFooterButtonProps = gaLegacyEmail
      ? {
          renderCustomActionButton: () => (
            <SplitButton
              buttonType={SplitButtonType.PRIMARY}
              options={[
                {
                  key: 'createOptIn',
                  title: t('bouncesAndOptOuts.splitButton.optIn'),
                  onClick: handleOptInCampaign,
                },
                {
                  key: 'legacy',
                  title: t('bouncesAndOptOuts.splitButton.legacyComposer'),
                  onClick: handleLegacyCreate,
                },
              ]}
            />
          ),
        }
      : { actionButtonOnClick: handleOptInCampaign, actionButtonLabel: t('bouncesAndOptOuts.splitButton.optIn') }

    return {
      cancelButtonLabel: t('Cancel'),
      ...computedFooterButtonProps,
    }
  }, [gaLegacyEmail, handleLegacyCreate, handleOptInCampaign, t])

  return (
    <Modal className={classNames(`${rootClass}__modal`)} data-test={`${rootClass}-modal`} isOpen={isOpen} header={header}>
      <ModalBody className={`${rootClass}__body`}>
        <Typography
          className={`${rootClass}__body-text`}
          text={t('bouncesAndOptOuts.body.text')}
          type={TextType.BODY_TEXT_LIGHT}
          weight={TextWeight.REGULAR}
          lineHeight={LineHeight.MEDIUM}
          tagComponents={{ LinkTextButton: <LinkTextButton link={link} text="Learn more" inline hideIconLeft /> }}
        />
        <div className={`${rootClass}__option-wrapper`}>
          <div className={`${rootClass}__option-intro`}>
            <Typography
              className={`${rootClass}__option-intro-title`}
              text={t('bouncesAndOptOuts.RoleBasedEmailAddresses.title')}
              weight={TextWeight.MEDIUM}
              lineHeight={LineHeight.MEDIUM}
            />
            <Typography
              text={t('bouncesAndOptOuts.RoleBasedEmailAddresses.text')}
              type={TextType.BODY_TEXT_LIGHT}
              weight={TextWeight.REGULAR}
              lineHeight={LineHeight.MEDIUM}
              tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooterV2
        showTopBorder
        dataTest={`${rootClass}-footer`}
        className={`${rootClass}__footer`}
        footerType={ModalFooterType.Form}
        buttons={footerButtons}
        onClose={closeModal}
      />
    </Modal>
  )
}
