import React, { FC, useContext } from 'react'

import classNames from 'classnames'

import Caution from '@components/Caution/Caution'
import Container from '@components/Container'
import Modal, { ModalBody } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import ModalFooterV2 from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import ModalHeaderV2 from '@components/Modal/components/ModalHeaderV2/ModalHeaderV2'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { SegmentComposerContext } from '@src/pages/SegmentComposer/SegmentComposer.context'

import './SegmentErrorsModal.css'

interface SegmentErrorsModalProps {
  className?: string
  dataTest?: string
  onClose: () => void
  onFixErrors: () => void
  isOpen: boolean
}

const rootClass = 'segment-errors-modal'

const SegmentErrorsModal: FC<SegmentErrorsModalProps> = (props: SegmentErrorsModalProps) => {
  const { dataTest = rootClass, className = '', onFixErrors, onClose, isOpen } = props

  const {
    values: { errors },
  } = useContext(SegmentComposerContext)

  const { t } = useTranslation()

  return (
    <Modal
      className={classNames(rootClass, className)}
      data-test={dataTest}
      isOpen={isOpen}
      paddingV2
      header={
        <ModalHeaderV2 className={`${rootClass}__header`} headerType={ModalHeaderType.Form} headerText={t('SegmentComposer.ErrorsModal.Header')} />
      }
    >
      <ModalBody className={`${rootClass}__body`}>
        <Caution className={`${rootClass}__caution-message`} message={t('SegmentComposer.ErrorsModal.CautionMessage')} />
        <Container
          header={
            <div className={`${rootClass}__error-header`}>
              <Svg name={SvgNames.errorSolid} type={SvgType.LARGER_ICON} />
              <Typography text={t('Main errors')} weight={TextWeight.MEDIUM} />
            </div>
          }
        >
          {Array.from(errors).map((error) => (
            <Typography key={error} className={`${rootClass}__error-message`} text={t(error)} />
          ))}
        </Container>
      </ModalBody>
      <ModalFooterV2
        className={`${rootClass}__footer`}
        dataTest={`${dataTest}-footer`}
        footerType={ModalFooterType.Form}
        onClose={onClose}
        buttons={{
          actionButtonLabel: t('Fix errors'),
          actionButtonOnClick: onFixErrors,
          cancelButtonLabel: t('Cancel'),
        }}
      />
    </Modal>
  )
}

export default SegmentErrorsModal
