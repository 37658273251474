import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import { MultiStyledStrings, renderTextLines } from '@components/Caution/Caution'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './InfoStatus.css'

export enum InfoStatusTypes {
  Active = 'ACTIVE',
  Caution = 'CAUTION',
  Warning = 'WARNING',
  Informative = 'INFORMATIVE',
}

type Props = {
  status: InfoStatusTypes
  message?: string | ReactNode
  children?: ReactNode
  className?: string
  dataTest?: string
  multiline?: boolean
  textLines?: MultiStyledStrings[]
  statusText?: string | ReactNode
  svgName?: SvgNames
  noWrapperMargin?: boolean
}

const rootClass = 'info-status'

const InfoStatus: FC<Props> = (props: Props) => {
  const { status, message, children, multiline, dataTest = rootClass, className = '', textLines, statusText, svgName, noWrapperMargin } = props

  const { t } = useTranslation()

  const renderWithT = message && typeof message === 'string' && !textLines

  return (
    <div
      data-test={`${dataTest}__info-status`}
      className={classNames(rootClass, className, {
        [`${rootClass}--multiline`]: multiline,
        [`${rootClass}--active`]: status === InfoStatusTypes.Active,
        [`${rootClass}--caution`]: status === InfoStatusTypes.Caution,
        [`${rootClass}--warning`]: status === InfoStatusTypes.Warning,
        [`${rootClass}--informative`]: status === InfoStatusTypes.Informative,
        [`${rootClass}--no-margin`]: noWrapperMargin,
      })}
    >
      <div className={`${rootClass}--wrapper`}>
        {svgName && (
          <span className={`${rootClass}__svg`}>
            <Svg name={svgName} type={SvgType.MEDIUM_LARGE_ICON} />
          </span>
        )}
        {textLines && renderTextLines(textLines)}
        {renderWithT && <Typography text={t(message)} type={TextType.BODY_TEXT_SMALL} />}
        {!renderWithT && message}
        {children ?? null}
      </div>
      <div className={classNames(`${rootClass}--wrapper`, `${rootClass}--wrapper-status-text`)}>
        <Typography text={typeof statusText === 'string' ? t(statusText) : statusText} type={TextType.BODY_TEXT_GRAY} />
      </div>
    </div>
  )
}

export default InfoStatus
