import { useMemo } from 'react'

import { FromAddresses } from '@graphql/types/query-types'

const useFilteredState = (term: string, data: FromAddresses[]): FromAddresses[] => {
  return useMemo(() => (!term ? data : data.filter((row) => filterRow(row, term.toLowerCase()))), [term, data])
}

const filterRow = (row: FromAddresses, term: string) => {
  const { name, email, title, cell, phone, fax } = row
  return Object.values({ name, email, title, cell, phone, fax }).some((value) => value?.toLowerCase().includes(term))
}

export default useFilteredState
