import equal from 'fast-deep-equal'

import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { TextWeight } from '@components/Typography/Typography'
import { MessageType } from '@graphql/types/mutation-types'
import { ValidationResponse, WebinarConfig } from '@graphql/types/query-types'
import { isEmailComposerTab, ValidationOptions } from '@src/pages/EmailComposer/utils/EmailComposer.types'
import { CommonComposerTab } from '@utils/composer/commonComposer/CommonComposer.context'
import {
  ContentValidations,
  DEFAULT_EMAIL_COMPOSER_TAB,
  defaultEmailComposerValidations,
  EmailSender,
  EmailValidations,
} from '@utils/composer/context/EmailComposer.context'
import { ExtendedMessageType } from '@utils/composer/emailComposer/types'
import { PERSONALIZED_FROM_ADDRESS_OPTION_VALUE, SALESFORCE_OWNER_OPTION_VALUE } from '@utils/composer/settings.constants'

export const areValidationsDirty = (validations: EmailValidations): boolean => {
  return !equal(validations, defaultEmailComposerValidations)
}

export const getComputedMessageType = (messageType: ExtendedMessageType, condition: boolean) => {
  return condition ? 'TEMPLATE' : messageType
}

export const detectEmailType = (messageType?: ExtendedMessageType) => ({
  isEmailABTest: messageType == 'AB_TEST_MESSAGE',
  isEmailDraft: messageType == 'DRAFT',
  isEmailWebinar: messageType == 'WEBINAR_MESSAGE',
  isEmailRSS: messageType == 'RSS_MESSAGE',
  isEmailTemplate: messageType == 'TEMPLATE' || messageType?.startsWith('t-'),
  isEmailCRM: messageType == 'CRM_MESSAGE',
  isEmailProgram: messageType == 'PROGRAM_MESSAGE',
  isEmailForm: messageType == 'FORM_AUTO_RESPONSE',
  isEmailResend: messageType == 'RESEND_MESSAGE',
  isEmailBlankMessage: messageType == 'BLANK_MESSAGE',
  isOptInEmail: messageType == 'OPT_IN_MESSAGE',
  isLandingPage: messageType == 'LANDING_PAGE',
})

export const detectWebinarType = (config?: WebinarConfig) => {
  const name = config?.name
  return {
    isGoTo: name === 'goto',
    isWebex: name === 'webex',
    isZoom: name === 'zoom',
    isReminder: config?.messageCategory === 'reminder',
    isAccepted: config?.messageCategory === 'accepted',
    isPending: config?.messageCategory === 'pending',
    isRejected: config?.messageCategory === 'rejected',
    isDeclined: config?.messageCategory === 'declined',
    isInvitation: config?.messageCategory === 'invitation',
    isMultiSession: config?.type === 'multi',
    isSingleSession: config?.type === 'single',
  }
}

export const getValidTab = (newTab: CommonComposerTab | undefined, isTemplate = false) => {
  const tabExists = isEmailComposerTab(newTab)
  const isInvalidTab = !tabExists || (isTemplate && [CommonComposerTab.SEND, CommonComposerTab.REVIEW].includes(newTab))
  return isInvalidTab ? DEFAULT_EMAIL_COMPOSER_TAB : newTab
}

export const findSender = (id: string, emailSenders: EmailSender[]) =>
  id === SALESFORCE_OWNER_OPTION_VALUE
    ? { email: SALESFORCE_OWNER_OPTION_VALUE, name: SALESFORCE_OWNER_OPTION_VALUE, uuid: '' }
    : id === PERSONALIZED_FROM_ADDRESS_OPTION_VALUE
    ? { email: PERSONALIZED_FROM_ADDRESS_OPTION_VALUE, name: PERSONALIZED_FROM_ADDRESS_OPTION_VALUE, uuid: '' }
    : emailSenders.find(({ uuid }) => uuid === id)

export const detectMessageTypeByLetter = (startIdParam: string | null, matchParamsId: string) => {
  const letter = startIdParam?.[0] || matchParamsId[0]
  const typesByLetter: Record<string, Extract<MessageType, 'DRAFT' | 'TEMPLATE'> | ''> = {
    d: 'DRAFT',
    t: 'TEMPLATE',
    //* we have multiple cases when id starts with s- id.
    s: '',
  }
  return typesByLetter[letter] || 'DRAFT' //* set to default type
}

export const getValidationOptions = (
  optionType: 'OPT_OUT_LINK' | 'COMPANY_NAME' | 'PHYSICAL_ADDRESS',
  contentValidations: ContentValidations & ValidationResponse,
  isPlainTextOnly: boolean | undefined,
  handlePlainTextEdit: () => void
) => {
  const {
    hasOptOutLinkInHtml,
    hasOptOutLinkInText,
    hasPhysicalAddressInHtml,
    hasPhysicalAddressInText,
    hasAccountCompanyInText,
    hasAccountCompanyInHtml,
    mobileAndDesktopValidation,
    dynamicContentValidationResponse,
    dynamicPlainTextValidationResponse,
  } = contentValidations ?? {}
  const dynamicContentValidationResponseData = isPlainTextOnly ? dynamicPlainTextValidationResponse : dynamicContentValidationResponse

  const {
    hasDesktopCompanyName,
    hasDesktopOptOutLink,
    hasDesktopPhysicalAddressInHtml,
    hasMobileCompanyName,
    hasMobileOptOutLink,
    hasMobilePhysicalAddressInHtml,
  } = mobileAndDesktopValidation || {}

  const { hasOptOut, hasCompanyName, hasAddress } = dynamicContentValidationResponseData ?? {}

  const options: ValidationOptions[] = []

  const addOption = (optionText: string, condition?: boolean, onClick?: () => void) => {
    if (condition) {
      options.push({
        text: optionText,
        tagProps: { medium: { weight: TextWeight.MEDIUM } },
        inline: true,
        ...(onClick && { onClick }),
      })
    }
  }

  switch (optionType) {
    case 'OPT_OUT_LINK': {
      if (isPlainTextOnly) {
        addOption('EmailComposer.Preview.Validations.Anti.Spam.OptOutLink.Item.Dynamic.PlainText', hasOptOut)
      } else {
        addOption(
          'EmailComposer.Preview.Validations.Anti.Spam.OptOutLink.Item.HTML',
          hasOptOutLinkInText && !hasOptOutLinkInHtml && !hasDesktopOptOutLink && !hasMobileOptOutLink
        )
        addOption(
          'EmailComposer.Preview.Validations.Anti.Spam.OptOutLink.Item.PlainText',
          !hasOptOutLinkInText && (hasOptOutLinkInHtml || hasDesktopOptOutLink || hasMobileOptOutLink),
          handlePlainTextEdit
        )
        addOption('EmailComposer.Preview.Validations.Anti.Spam.OptOutLink.Item.Desktop', !hasDesktopOptOutLink && hasMobileOptOutLink)
        addOption('EmailComposer.Preview.Validations.Anti.Spam.OptOutLink.Item.Mobile', hasDesktopOptOutLink && !hasMobileOptOutLink)
        addOption('EmailComposer.Preview.Validations.Anti.Spam.OptOutLink.Item.Dynamic', hasOptOut && !hasOptOutLinkInHtml && !hasOptOutLinkInText)
        addOption(
          'EmailComposer.Preview.Validations.Anti.Spam.OptOutLink.Item.Dynamic.PlainText',
          hasOptOut && hasOptOutLinkInHtml && !hasOptOutLinkInText
        )
      }

      break
    }

    case 'COMPANY_NAME': {
      if (isPlainTextOnly) {
        addOption('EmailComposer.Preview.Validations.Anti.Spam.CompanyName.Item.Dynamic.PlainText', hasCompanyName)
      } else {
        addOption(
          'EmailComposer.Preview.Validations.Anti.Spam.CompanyName.Item.HTML',
          hasAccountCompanyInText && !hasAccountCompanyInHtml && !hasDesktopCompanyName && !hasMobileCompanyName
        )
        addOption(
          'EmailComposer.Preview.Validations.Anti.Spam.CompanyName.Item.PlainText',
          !hasAccountCompanyInText && (hasAccountCompanyInHtml || hasDesktopCompanyName || hasMobileCompanyName),
          handlePlainTextEdit
        )
        addOption('EmailComposer.Preview.Validations.Anti.Spam.CompanyName.Item.Desktop', !hasDesktopCompanyName && hasMobileCompanyName)
        addOption('EmailComposer.Preview.Validations.Anti.Spam.CompanyName.Item.Mobile', hasDesktopCompanyName && !hasMobileCompanyName)
        addOption(
          'EmailComposer.Preview.Validations.Anti.Spam.CompanyName.Item.Dynamic',
          hasCompanyName && !hasAccountCompanyInHtml && !hasAccountCompanyInText
        )
        addOption(
          'EmailComposer.Preview.Validations.Anti.Spam.CompanyName.Item.Dynamic.PlainText',
          hasCompanyName && hasAccountCompanyInHtml && !hasAccountCompanyInText
        )
      }

      break
    }

    case 'PHYSICAL_ADDRESS': {
      if (isPlainTextOnly) {
        addOption('EmailComposer.Preview.Validations.Anti.Spam.PhysicalAddress.Item.Dynamic.PlainText', hasAddress)
      } else {
        addOption(
          'EmailComposer.Preview.Validations.Anti.Spam.PhysicalAddress.Item.HTML',
          hasPhysicalAddressInText && !hasPhysicalAddressInHtml && !hasDesktopPhysicalAddressInHtml && !hasMobilePhysicalAddressInHtml
        )
        addOption(
          'EmailComposer.Preview.Validations.Anti.Spam.PhysicalAddress.Item.PlainText',
          !hasPhysicalAddressInText && (hasPhysicalAddressInHtml || hasDesktopPhysicalAddressInHtml || hasMobilePhysicalAddressInHtml),
          handlePlainTextEdit
        )
        addOption(
          'EmailComposer.Preview.Validations.Anti.Spam.PhysicalAddress.Item.Desktop',
          !hasDesktopPhysicalAddressInHtml && hasMobilePhysicalAddressInHtml
        )
        addOption(
          'EmailComposer.Preview.Validations.Anti.Spam.PhysicalAddress.Item.Mobile',
          hasDesktopPhysicalAddressInHtml && !hasMobilePhysicalAddressInHtml
        )
        addOption(
          'EmailComposer.Preview.Validations.Anti.Spam.PhysicalAddress.Item.Dynamic',
          hasAddress && !hasPhysicalAddressInHtml && !hasPhysicalAddressInText
        )
        addOption(
          'EmailComposer.Preview.Validations.Anti.Spam.PhysicalAddress.Item.Dynamic.PlainText',
          hasAddress && hasPhysicalAddressInHtml && !hasPhysicalAddressInText
        )
      }

      break
    }

    default:
      break
  }

  return options
}

export const getIdFromUrl = (url: string) => {
  const pathSegments = url.split('/').filter((segment) => segment.length > 0)
  const idIndex = pathSegments.indexOf('emails') + 1

  return {
    id: pathSegments[idIndex] || '',
  }
}

export const findSenderOption = (senders: EmailSender[], senderId?: string): SelectV2SingleOption | undefined => {
  const sender = senders.find((sender) => sender.uuid === senderId)
  if (sender) {
    return {
      label: sender.name,
      value: sender.uuid,
      subText: sender.email,
    }
  }
}
