import { useContext, useEffect, useRef } from 'react'

import { ModuleTypes } from '@beefree.io/sdk/dist/types/bee'
import { IPluginModuleWithInternalModule } from '@utils/composer/beeEditor/beeEditorTypes'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'

export const useAddDynamicImagePersonalizationToHtml = () => {
  const {
    values: {
      message: { templateHtml, templateJson },
    },
    api: { update },
  } = useContext(EmailComposerContext)

  const updatedDynamicHtmlOnce = useRef(false)
  const changedHtml = useRef(templateHtml)

  useEffect(() => {
    if (!templateHtml) {
      // If templateHtml is empty or the update has already occurred, exit early
      return
    }

    const parser = new DOMParser()
    const doc = parser.parseFromString(templateHtml, 'text/html')
    const imgTags = doc.querySelectorAll('img')

    if (imgTags.length) {
      templateJson.page.rows.forEach((row) => {
        return row.columns.filter((column) => {
          return column.modules.find((module: IPluginModuleWithInternalModule) => {
            if ((module.type as string) === ModuleTypes.IMAGE) {
              const dynamicImageSrc = module.descriptor.image?.dynamicImageFields?.dynamicSrc

              if (dynamicImageSrc) {
                Array.from(imgTags).forEach((img) => {
                  if (img.src === module.descriptor.image?.src) {
                    img.setAttribute('data-personalization', dynamicImageSrc)
                  }
                })
              }
            }
          })
        })
      })

      updatedDynamicHtmlOnce.current = true

      changedHtml.current = doc.documentElement.outerHTML
    }
  }, [templateHtml, templateJson.page.rows, update])

  return changedHtml.current
}
