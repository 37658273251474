import React, { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { useApolloClient, useMutation } from '@apollo/client'
import ImagePickerWithActionsModalContainer from '@components/AssetPickers/ImagePickerWithActionsModal/ImagePickerWithActionsModalContainer'
import Container from '@components/Container'
import DropDown from '@components/DropDown'
import { DropDownType } from '@components/DropDown/DropDown'
import DropDownActions, { MenuItem } from '@components/DropDownActions/DropDownActions'
import InputV2 from '@components/InputV2/InputV2'
import LabelWithSvgTooltip from '@components/LabelWithTooltipIcon/LabelWithSvgTooltip'
import Modal, { ModalBody } from '@components/Modal'
import ModalFooterV2 from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import ModalHeaderV2 from '@components/Modal/components/ModalHeaderV2/ModalHeaderV2'
import Spinner from '@components/Spinner/Spinner'
import { SvgNames, SvgType } from '@components/Svg'
import TextArea from '@components/TextArea/TextArea'
import Toggle from '@components/Toggle'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import UploadImage, { ImageURL, ImageWithType } from '@components/UploadImage/UploadImage'
import uploadImage from '@graphql/mutations/uploadImage'
import { UploadImageMutation, UploadImageMutationVariables } from '@graphql/types/mutation-types'
import { useAccountSettings } from '@utils/account/account.utils'
import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'
import { LandingPageVisibilityStatus } from '@utils/composer/context/LandingPageComposer.context'

import { SettingsFormChangeHandler } from '../../utils/LandingPageComposerSettings.utils'

import './MetaDataPane.css'

export interface MetaDataPaneProps {
  className?: string
  dataTest?: string
  onFieldChange: SettingsFormChangeHandler
}

const imageMaxSize = 5 * Math.pow(2, 20)

const rootClass = 'meta-data-pane'

const MetaDataPane: FC<MetaDataPaneProps> = (props) => {
  const { dataTest = rootClass, className, onFieldChange } = props

  const { t } = useTranslation()
  const client = useApolloClient()
  const { accountId } = useAccountSettings()
  const {
    values: {
      message: { id },
      landingPage,
      publicUrlManager,
    },
    api: { update },
  } = useComposerContext()

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<boolean>(false)
  const [isImagePickerOpen, setIsImagePickerOpen] = useState<boolean>(false)

  const { landingPageMetaImage, landingPageVisibility, landingPageMetaTitle, landingPageMetaDescription, showErrors } = landingPage
  const { landingPagePublicUrl, landingPageSeoFriendlyUrl } = publicUrlManager

  const SEOReportURL = `/acton/_seo/seoReport.jsp?id=page:${id}`

  const dropDownActions: MenuItem[] = [
    {
      text: t('Preview listing'),
      onClick: () => setIsPreviewModalOpen(true),
      icon: SvgNames.show,
    },
    {
      text: t('View SEO report'),
      onClick: () => window.open(SEOReportURL, '_blank'),
      icon: SvgNames.externalLink,
      inFooter: true,
    },
  ]

  const [uploadImageMutation, { loading }] = useMutation<UploadImageMutation, UploadImageMutationVariables>(uploadImage, {
    client,
    fetchPolicy: 'no-cache',
  })

  const onUploadImage = useCallback(
    (variables: UploadImageMutationVariables) =>
      uploadImageMutation({ variables }).then(({ data }) => {
        if (!data) {
          return Promise.reject()
        }
        return data
      }),
    [uploadImageMutation]
  )

  const onImageChange = (image?: ImageWithType | ImageURL) => {
    const { id, imageBase64, imageType, title, url } = image
      ? (image as ImageWithType & ImageURL)
      : { id: undefined, imageBase64: undefined, imageType: undefined, title: undefined, url: undefined }
    if (url) {
      onFieldChange('landingPageMetaImage', {
        accountId: Number(accountId),
        id: id,
        name: title,
        url: url,
      })
    } else if (imageBase64 && imageType && title) {
      onUploadImage({ base64File: imageBase64, extension: imageType, folderName: 'Default Folder', fileName: title }).then((data) => {
        onFieldChange('landingPageMetaImage', {
          accountId: Number(accountId),
          id: data.uploadImage.id,
          name: data.uploadImage.title,
          url: data.uploadImage.previewUrl,
        })
      })
    } else {
      onFieldChange('landingPageMetaImage', undefined)
    }

    setIsImagePickerOpen(false)
  }

  const toggleOpen = (dropdownOpen: boolean) => setDropdownOpen(dropdownOpen)

  const renderPreviewModal = () => {
    return (
      <Modal paddingV2 className={`${rootClass}__preview`} isOpen={isPreviewModalOpen}>
        <ModalHeaderV2 headerType={'form'} headerText={t('LandingPageComposer.Settings.MetaData.Preview.Title')} />
        <ModalBody>
          <Typography className="push-up-x4" text={t('LandingPageComposer.Settings.MetaData.Preview.Description')} type={TextType.BODY_TEXT_LIGHT} />
          {landingPageMetaImage?.url && <img className={`${rootClass}__preview-image`} src={landingPageMetaImage?.url} alt={'SEO meta data'} />}
          <Typography className={`${rootClass}__preview-meta-name`} text={landingPageMetaTitle} type={TextType.PAGE_HEADLINE} />
          <Typography className={`${rootClass}__preview-meta-url ellip`} text={landingPageSeoFriendlyUrl || landingPagePublicUrl} />
          {landingPageMetaDescription && <Typography text={landingPageMetaDescription} />}
        </ModalBody>
        <ModalFooterV2 showTopBorder onClose={() => setIsPreviewModalOpen(false)} />
      </Modal>
    )
  }

  return (
    <Container>
      <div className={classNames(rootClass, className)} data-test={dataTest}>
        {renderPreviewModal()}
        <div className={`${rootClass}__title`}>
          <Typography text={t('LandingPageComposer.Settings.MetaData.Header')} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.MEDIUM} />
          <DropDown type={DropDownType.STYLED} hasOverflowIcon isOpen={dropdownOpen} toggleOpen={toggleOpen}>
            <DropDownActions menuItems={dropDownActions} closeDropDown={() => toggleOpen(false)} />
          </DropDown>
        </div>
        <Typography text={t('LandingPageComposer.Settings.MetaData.Header.Description')} type={TextType.BODY_TEXT_LIGHT} />
        <div className={`${rootClass}__content push-down-x4`}>
          <div className={`${rootClass}__fields`}>
            <InputV2
              dataTest={`${dataTest}-page-title`}
              labelProps={{
                label: t('LandingPageComposer.Settings.MetaData.Title'),
                required: true,
              }}
              value={landingPage['landingPageMetaTitle'] ?? ''}
              handleValueChangeFromOuter
              maxCharacterProps={{ maxLength: 55 }}
              placeholder={t('LandingPageComposer.Settings.MetaData.Title.Placeholder')}
              onChange={(e) => {
                onFieldChange('landingPageMetaTitle', e.target.value)
              }}
              error={!!showErrors && !landingPage['landingPageMetaTitle']}
              onBlur={() => update({ landingPage: { showErrors: true } })}
              inputInfo={{
                enabled: !!showErrors && !landingPage['landingPageMetaTitle'],
                errorText: 'A page title is required',
              }}
            />
            <div>
              <TextArea
                dataTest={`${dataTest}-page-description`}
                className={`${rootClass}__fields-textarea`}
                name="landingPageMetaDescription"
                label={t('LandingPageComposer.Settings.MetaData.Description')}
                placeholder={t('LandingPageComposer.Settings.MetaData.Description.Placeholder')}
                value={landingPage['landingPageMetaDescription']}
                maxCharacterProps={{ maxLength: 150 }}
                onChange={(e) => onFieldChange('landingPageMetaDescription', e.target.value)}
                resize={false}
              />
            </div>
            <div>
              <LabelWithSvgTooltip
                label={t('LandingPageComposer.Settings.MetaData.Visibility')}
                tooltipContent={t('LandingPageComposer.Settings.MetaData.Visibility.Tooltip')}
                align="center"
                svgType={SvgType.LARGER_ICON}
              />
              <Toggle
                dataTest={`${dataTest}-page-visibility-toggle`}
                label={t('LandingPageComposer.Settings.MetaData.Visibility.Label')}
                isOn={landingPageVisibility === LandingPageVisibilityStatus.SHOW}
                onToggle={(value) =>
                  onFieldChange('landingPageVisibility', value ? LandingPageVisibilityStatus.SHOW : LandingPageVisibilityStatus.HIDE)
                }
                noLeftMargin
              />
            </div>
          </div>
          <div className={`${rootClass}__upload-image-container`}>
            <div className={`${rootClass}__upload-image-container-label`}>
              <LabelWithSvgTooltip
                label={t('LandingPageComposer.Settings.MetaData.Image')}
                tooltipContent={t('LandingPageComposer.Settings.MetaData.Image.Tooltip')}
                align="center"
                svgType={SvgType.LARGER_ICON}
              />
              <Typography
                text={t('LandingPageComposer.Settings.MetaData.Image.Info')}
                type={TextType.BODY_TEXT_SMALL_LIGHT}
                lineHeight={LineHeight.MEDIUM_SMALL}
              />
            </div>
            {loading ? (
              <Spinner />
            ) : isImagePickerOpen ? (
              <ImagePickerWithActionsModalContainer
                handleImagePickerDone={onImageChange}
                hideUploadFromURL
                accept=".png, .jpg, .jpeg"
                imageMaxSize={imageMaxSize}
                allowSvg
              />
            ) : (
              <UploadImage
                image={landingPageMetaImage?.url}
                onImagePickerOpenChange={(open) => setIsImagePickerOpen(open)}
                onImageChange={onImageChange}
                canSelectExistingImage
                maxSize={imageMaxSize}
                withHeaderActions
                hideUploadFromURL
                accept=".png, .jpg, .jpeg"
                useRequirementsInImagePicker
                className={`${rootClass}__upload-image`}
              />
            )}
          </div>
        </div>
      </div>
    </Container>
  )
}

export default MetaDataPane
