import { gql } from '@apollo/client'

export default gql`
  query getSignatureTemplateList {
    getSignatureTemplateList {
      signatureTemplateId
      name
      description
      source
      algorithms
      hashAlgorithms
      keyEncodings
      allAlgorithms
      allHashAlgorithms
      allKeyEncodings
      allTimestampFormats
      headerName
      headerFormat
      payloadFormat
      signatureEncoding
      timestampCheck
      timestampHeaderName
      timestampHeaderFormat
      timestampLeeway
      timestampFormat
    }
  }
`
