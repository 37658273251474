import { gql } from '@apollo/client'

export default gql`
  mutation setCompanyName($name: String) {
    setCompanyName(name: $name) {
      statusCode
      reasonPhrase
    }
  }
`
