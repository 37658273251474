import { gql } from '@apollo/client'

export default gql`
  mutation removeFaviconUrl {
    removeFaviconUrl {
      status
      errorMsg
      item {
        url
        isDefault
      }
    }
  }
`
