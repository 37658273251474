import { gql } from '@apollo/client'

export default gql`
  query GetTags($types: [String], $subTypes: [String], $type: String) {
    getLabels(types: $types, subTypes: $subTypes, type: $type) {
      color
      enabled
      id
      itemCount
      name
    }
  }
`
