import React, { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router'

import { ApolloError, useApolloClient } from '@apollo/client'
import { DirectionOptions } from '@components/TimespanPicker/TimespanPicker'
import { useTranslation } from '@const/globals'
import getEmailMessagesReportDataLake from '@graphql/microservices/data-lake-query/getEmailMessagesReportDataLake'
import getEmailMessagesReportSummary from '@graphql/queries/getEmailMessagesReportSummary'
import { GetEmailMessagesReportDataLakeQuery, GetEmailMessagesReportDataLakeQueryVariables } from '@graphql/types/microservice/data-lake-query-types'
import { GetEmailMessagesReportSummaryQuery, GetEmailMessagesReportSummaryQueryVariables } from '@graphql/types/query-types'
import EmailMessages from '@src/pages/reports/emailMessages/EmailMessages'
import { useAccountSettings } from '@utils/account/account.utils'
import { dateTodayMidnight, dateYesterdayMidnight, getStandardFormattedDate, monthsForLocale, startTime } from '@utils/date'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import {
  DailyReportFieldTypes,
  EmailMessageContainerState,
  EmailMessageReportsDataFormat,
  MonthlyReportFieldTypes,
  getDailyVisitors,
  getMonthlyComparison,
  getMonthlyPageDetails,
  getReportActivityByDay,
  getReportDataByDay,
  handleDateChange,
  setStartMonth,
} from '@utils/reports/reports.utils'

const months = monthsForLocale('en-US', 'short')

const defaultEmailMessageReports: EmailMessageReportsDataFormat = {
  monthlyComparison: {
    rows: [],
    columns: [],
  },
  dailyActivity: {
    fields: [],
  },
  monthlyLaunchDetails: {
    categories: [],
    fields: [],
  },
  opensByDay: {
    categories: [],
    fields: [],
  },
  clicksByDay: {
    categories: [],
    fields: [],
  },
  activityByDay: {
    fields: [],
  },
  updatedDate: '',
}

const defaultState: EmailMessageContainerState = {
  startTime: startTime(),
  startTimePresentation: months[setStartMonth(dateYesterdayMidnight)],
  endTime: new Date(dateYesterdayMidnight).toISOString().substring(0, 10),
  endTimePresentation: months[dateYesterdayMidnight.getMonth()],
  nextDisabled: true,
  prevDisabled: false,
}

interface DataState {
  loading: boolean
  pageError?: ApolloError
  data: any
}

const EmailMessagesContainer: FC = () => {
  const { accountTimeZoneId, dataLakeEmailReport, autoRefreshSummaryReports } = useAccountSettings()

  const { search } = useLocation()
  const hasTwoParams = search.includes('&')
  const search1 = hasTwoParams ? search.split('&')[0] : search
  const urlSource = search1.includes('source') ? search.split('=')[1] : ''
  const useClassic = urlSource === 'classic'
  const useDataLake = !useClassic && (urlSource === 'dataLake' || dataLakeEmailReport)
  const search2 = hasTwoParams ? search.split('&')[1] : search
  const todayAsEndDate = search2.includes('includeCurrentDay')

  const endTime = (todayAsEndDate ? new Date(dateTodayMidnight) : new Date(dateYesterdayMidnight)).toISOString().substring(0, 10)
  const endTimePresentation = todayAsEndDate ? months[dateTodayMidnight.getMonth()] : months[dateYesterdayMidnight.getMonth()]
  const [state, setState] = useState<EmailMessageContainerState>({ ...defaultState, endTime, endTimePresentation })
  const [dataState, setDataState] = useState<DataState>({
    loading: true,
    data: [],
  })
  const { loading, pageError, data } = dataState

  const { t } = useTranslation()
  const { client: clientMS } = useMicroserviceClient({ serviceName: MicroserviceClients.DATA_LAKE_QUERY })
  const client = useApolloClient()

  const getClassicData = async (onRefresh: boolean) => {
    const result = await client.query<GetEmailMessagesReportSummaryQuery, GetEmailMessagesReportSummaryQueryVariables>({
      query: getEmailMessagesReportSummary,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        startTime: state.startTime,
        endTime: state.endTime,
        onRefresh,
      },
    })
    setDataState({ ...result, pageError: result.error })
  }

  const getMSData = async (onRefresh: boolean) => {
    const result = await clientMS.query<GetEmailMessagesReportDataLakeQuery, GetEmailMessagesReportDataLakeQueryVariables>({
      query: getEmailMessagesReportDataLake,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        startTime: state.startTime,
        endTime: state.endTime,
        onRefresh,
        timeZoneId: accountTimeZoneId,
      },
    })
    setDataState({ ...result, pageError: result.error })
  }

  const getData = async (onRefresh: boolean) => (useDataLake ? getMSData(onRefresh) : getClassicData(onRefresh))

  useEffect(() => {
    getData(false)
  }, [])

  useEffect(() => {
    getData(true)
  }, [state.nextDisabled, state.startTime, state.startTimePresentation, state.endTime, state.endTimePresentation])

  const onDateChange = (direction: DirectionOptions) => {
    handleDateChange(state, setState, direction)
  }

  const updateData = (data: any): EmailMessageReportsDataFormat => {
    if (!data) {
      return defaultEmailMessageReports
    }

    const reportData = useDataLake ? data.getEmailMessagesReportDataLake : data.getEmailMessagesReportSummary
    if (!reportData) {
      return defaultEmailMessageReports
    }

    const { weeklySummary, monthlySummary, currentTime } = reportData

    const dailyFieldInfo = [
      {
        name: t('Sent'),
        fieldName: DailyReportFieldTypes.SENDS,
        color: '#A084DD',
      },
      {
        name: t('Opens'),
        fieldName: DailyReportFieldTypes.OPENS,
        color: '#50BDFF',
      },
      {
        name: t('Clicks'),
        fieldName: DailyReportFieldTypes.CLICKS,
        color: '#7DE7C1',
      },
      {
        name: t('Bounces'),
        fieldName: DailyReportFieldTypes.BOUNCES,
        color: '#FFCB3E',
      },
      {
        name: t('Opt Outs'),
        fieldName: DailyReportFieldTypes.OPT_OUTS,
        color: '#F27575',
      },
    ]

    const dailyActivity = getDailyVisitors(monthlySummary, dailyFieldInfo)

    const monthlyFieldInfo = [
      {
        name: t('Sent'),
        fieldName: MonthlyReportFieldTypes.TOTALSENDS,
        color: '#A084DD',
        excludeDailyActivity: true,
      },
      {
        name: t('Opens'),
        fieldName: MonthlyReportFieldTypes.TOTALOPENS,
        color: '#50BDFF',
      },
      {
        name: t('Clicks'),
        fieldName: MonthlyReportFieldTypes.TOTALCLICKS,
        color: '#7DE7C1',
      },
      {
        name: t('Bounces'),
        fieldName: MonthlyReportFieldTypes.TOTALBOUNCES,
        color: '#FFCB3E',
        excludeMonthlySummary: true,
        excludeDailyActivity: true,
      },
      {
        name: t('Opt Outs'),
        fieldName: MonthlyReportFieldTypes.TOTALOPTOUTS,
        color: '#F27575',
        excludeMonthlySummary: true,
        excludeDailyActivity: true,
      },
    ]

    const monthlyComparison = getMonthlyComparison(monthlySummary, monthlyFieldInfo)
    const monthlyLaunchDetails = getMonthlyPageDetails(monthlySummary, monthlyFieldInfo)

    const openDayColors = ['#C1EAFF', '#98DCFF', '#35BFFF']
    const clickDayColors = ['#CAF8E7', '#AAF8DB', '#71E7BD']

    const opensByDay = getReportDataByDay(monthlySummary, openDayColors, DailyReportFieldTypes.OPENS)
    const clicksByDay = getReportDataByDay(monthlySummary, clickDayColors, DailyReportFieldTypes.CLICKS)
    const activityByDay = getReportActivityByDay(weeklySummary, monthlyFieldInfo)

    return {
      dailyActivity,
      monthlyComparison,
      monthlyLaunchDetails,
      opensByDay,
      clicksByDay,
      activityByDay,
      updatedDate: currentTime,
    }
  }

  const refreshOnClick = !autoRefreshSummaryReports
    ? () => {
        getData(true)
      }
    : undefined

  const formattedData = !loading && !pageError && data ? updateData(data) : defaultEmailMessageReports

  return (
    <EmailMessages
      dataLakeUseError={useDataLake && !dataLakeEmailReport}
      loading={loading}
      monthlyLaunchDetails={formattedData.monthlyLaunchDetails}
      onDateChange={onDateChange}
      dailyActivity={formattedData.dailyActivity}
      monthlyComparison={formattedData.monthlyComparison}
      opensByDay={formattedData.opensByDay}
      clicksByDay={formattedData.clicksByDay}
      activityByDay={formattedData.activityByDay}
      updatedDate={
        formattedData.updatedDate && formattedData.updatedDate !== '0'
          ? getStandardFormattedDate(new Date(parseInt(formattedData.updatedDate)).valueOf(), true, accountTimeZoneId)
          : getStandardFormattedDate(dateYesterdayMidnight.valueOf(), true, accountTimeZoneId)
      }
      startTime={state.startTimePresentation}
      endTime={state.endTimePresentation}
      pageError={pageError}
      nextDisabled={state.nextDisabled}
      prevDisabled={state.prevDisabled}
      refreshOnClick={refreshOnClick}
    />
  )
}

export default EmailMessagesContainer
