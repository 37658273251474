import React, { FC } from 'react'

import PageContainer from '@components/PageContainer'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import { legacyActonContext } from '@const/globals'
import { useAccountSettings } from '@utils/account/account.utils'

import './ContactsUsage.css'

interface Props {
  className?: string
  dataTest?: string
  isContact: boolean
}

const rootClass = 'contacts-usage'

const ContactsUsage: FC<Props> = (props: Props) => {
  const { dataTest } = props

  const accountSettings = useAccountSettings()

  return (
    <PageContainer>
      <PositionContainer>
        <iframe
          title={'Contacts usage'}
          src={`${legacyActonContext}/agency2/marketingNetwork.jsp?tid=${accountSettings.accountId}`}
          className={`${rootClass}__iframe`}
          data-test={`${dataTest}-iframe`}
        />
      </PositionContainer>
    </PageContainer>
  )
}

export default ContactsUsage
