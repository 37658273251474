import React, { FC, useContext } from 'react'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'
import { Box } from '@src/pages/programs/UpgradeCommonComponents/Box/Box'
import { getConditionText } from '@src/pages/programs/upgradeManager/components/steps/Steps.utils'
import { UpgradedProgramSendStep, UpgradeManagerContext } from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'

import './ReviewSendEmailMessageStep.css'

interface ReviewSendEmailMessageStepProps {
  step: UpgradedProgramSendStep
}

const rootClass = 'review-send-email-message-step'

export const ReviewSendEmailMessageStep: FC<ReviewSendEmailMessageStepProps> = ({ step }: ReviewSendEmailMessageStepProps) => {
  const {
    values: { upgradedStepSources },
  } = useContext(UpgradeManagerContext)
  const {
    values: {
      program: { sources, messages },
    },
  } = useContext(ProgramManagerContext)
  const { t } = useTranslation()

  return (
    <div className={rootClass}>
      {step?.sendChoices?.map(({ not, msgId, srcId, choiceId }, index) => {
        const upgradedSource = upgradedStepSources[step.stepId ?? ''][choiceId ?? '']
        const programOriginalSource = sources.find((source) => source.id === srcId ?? '')
        const message = messages.find((message) => message.id === msgId)
        return (
          <Box key={index}>
            <Typography
              text={t(index === 0 ? getConditionText(undefined, not) : getConditionText(undefined, not).replace('If', 'Else if'))}
              lineHeight={LineHeight.MEDIUM}
            />
            <div className={`${rootClass}__container`}>
              <div className={`${rootClass}__current-field-container`}>
                <div className={`${rootClass}__value-container`}>
                  <Typography text={t('Current field')} weight={TextWeight.MEDIUM} lineHeight={LineHeight.MEDIUM} />
                  <Box isCurrent isSourceValue>
                    <TextWithTooltipOnEllip
                      typographyProps={{
                        text: programOriginalSource?.name,
                      }}
                    />
                    <Typography
                      text={t(`AutomatedPrograms.UpgradeManager.SourceReplacement.Contacts`, { count: programOriginalSource?.count })}
                      type={TextType.BODY_TEXT_LIGHT}
                    />
                  </Box>
                </div>
                <div className={`${rootClass}__message-container`}>
                  <Typography text={t('Send')} lineHeight={LineHeight.MEDIUM} />
                  <Typography
                    className={`${rootClass}__message`}
                    text={message?.name}
                    type={TextType.BODY_TEXT_SMALL}
                    lineHeight={LineHeight.MEDIUM_SMALL}
                  />
                </div>
              </div>
              <Svg className={`${rootClass}__arrow`} name={SvgNames.arrowRight} type={SvgType.LARGE_ICON} />
              <div className={`${rootClass}__change-to-container`}>
                <Typography text={t('Change to')} type={TextType.BODY_TEXT} lineHeight={LineHeight.MEDIUM} weight={TextWeight.MEDIUM} />
                <Box isSourceValue>
                  <TextWithTooltipOnEllip typographyProps={{ text: upgradedSource?.name, type: TextType.BODY_TEXT }} />
                  <Typography
                    text={t(`AutomatedPrograms.UpgradeManager.SourceReplacement.Contacts`, { count: upgradedSource?.size })}
                    type={TextType.BODY_TEXT_LIGHT}
                  />
                </Box>
              </div>
            </div>
          </Box>
        )
      })}
    </div>
  )
}
