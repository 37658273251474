import React, { FC } from 'react'

import { DashStyleValue, OptionsTickmarkPlacementValue, XAxisOptions } from 'highcharts'

import Chart from '@components/Chart/Chart'
import {
  ChartProps,
  LegendLocation,
  tooltipNoHeaderFormatter,
  tooltipPointsFormatter,
  topLegendProps,
  yAxisLabelsOptions,
} from '@const/Chart.constants'

import './ColumnChart.css'

const rootClass = 'column-chart'

export interface ColumnChartProps extends ChartProps {
  isBar?: boolean
  showTicks?: boolean
  outcomesByIncome?: boolean
  useToolTipNoHeader?: boolean
  chartInModal?: boolean
}

const modalZIndex = 99999
const ColumnChart: FC<ColumnChartProps> = (props: ColumnChartProps) => {
  const {
    isBar,
    ExtraElement,
    showTicks,
    dataTest = rootClass,
    className = '',
    title = '',
    subtitle = '',
    categories,
    chartInModal = false,
    outcomesByIncome,
    legendLocation,
    fields,
    sharedTooltip,
    hideLegend,
    useToolTipNoHeader = false,
    tooltip: tooltipOptions,
    plotOptions,
    disablePrint,
    loading,
    error,
    noContainer,
    axisLabelsStyle,
    xAxisOptions,
    yAxisLabels,
    legendOptions,
    exportingOptions,
    chartMarginTop,
    chartHeight,
    events,
    showTicksOnEmptyData,
    hideXAxisLine,
  } = props

  const chartColumnData: Array<any> = categories
    ? fields
    : fields.map((field) => ({
        name: field.name,
        data: [field.data],
      }))

  const tooltip = {
    ...tooltipPointsFormatter,
    outside: true,
    style: {
      zIndex: chartInModal ? modalZIndex : 100,
    },
    headerFormat: '<b>{point.key}</b><br>',
    shared: sharedTooltip,
  }

  const tooltipNoHeader = {
    ...tooltipPointsFormatter,
    outside: true,
    style: {
      zIndex: chartInModal ? modalZIndex : 100,
    },
    useHTML: true,
    shared: sharedTooltip,
    formatter: function () {
      return tooltipNoHeaderFormatter(this)
    },
  }

  const legend = (legendLocation && legendLocation === LegendLocation.TOP) || isBar ? { ...topLegendProps, ...legendOptions } : undefined

  const xAxisBaseProps: XAxisOptions = {
    categories,
    ...xAxisOptions,
  }

  if (axisLabelsStyle) {
    const style = xAxisBaseProps.labels?.style ? { ...xAxisBaseProps.labels.style, ...axisLabelsStyle } : axisLabelsStyle
    xAxisBaseProps.labels = { ...xAxisBaseProps.labels, style }
  }

  const xAxis = showTicks
    ? {
        ...xAxisBaseProps,
        crosshair: { width: 2, color: '#E3E3E3' },
        tickmarkPlacement: 'on' as OptionsTickmarkPlacementValue,
        tickWidth: 1,
      }
    : xAxisBaseProps

  const yAxis = {
    alignTicks: true,
    endOnTick: true,
    gridLineDashStyle: 'LongDash' as DashStyleValue,
    labels: outcomesByIncome
      ? {
          ...yAxisLabelsOptions,
          ...yAxisLabels,
          style: {
            ...axisLabelsStyle,
          },
        }
      : {
          ...yAxisLabels,
          style: {
            ...axisLabelsStyle,
          },
        },
    startOfTick: true,
    tickAmount: 5,
    title: {
      text: null,
    },
  }

  const defaultPlotOptions = {
    column: {
      borderRadius: 4,
      borderWidth: 2.5,
      maxPointWidth: 36,
    },
    ...plotOptions,
  }

  return (
    <Chart
      series={chartColumnData}
      chartType={isBar ? 'bar' : 'column'}
      title={title}
      subtitle={subtitle}
      xAxis={categories ? xAxis : undefined}
      yAxis={yAxis}
      legend={hideLegend ? { enabled: false } : legend}
      legendLocation={legendLocation}
      tooltip={tooltipOptions || (useToolTipNoHeader ? tooltipNoHeader : tooltip)}
      ExtraElement={ExtraElement}
      disablePrint={disablePrint}
      dataTest={dataTest}
      plotOptions={defaultPlotOptions}
      className={className}
      loading={loading}
      error={error}
      noContainer={noContainer}
      exportingOptions={exportingOptions}
      chartMarginTop={chartMarginTop}
      chartHeight={chartHeight}
      events={events}
      showTicksOnEmptyData={showTicksOnEmptyData}
      hideXAxisLine={hideXAxisLine}
    />
  )
}

export default ColumnChart
