import { useApolloClient } from '@apollo/client'
import isAValidSegmentName from '@graphql/queries/isAValidSegmentName'
import { IsAValidSegmentNameQuery, IsAValidSegmentNameQueryVariables } from '@graphql/types/query-types'
import { FetchPromise } from '@utils/types'

interface IsAValidSegmentNameRequestParams {
  segmentName: string
}

export type IsAValidSegmentNameRequestType = (params: IsAValidSegmentNameRequestParams) => FetchPromise<IsAValidSegmentNameQuery>

export interface AddContactsFromCRMClassicRequests {
  isAValidSegmentNameRequest: IsAValidSegmentNameRequestType
}

export const useAddContactsFromCRMClassicRequests = (): AddContactsFromCRMClassicRequests => {
  const actOnClassicClient = useApolloClient()

  const isAValidSegmentNameRequest = async ({ segmentName }: IsAValidSegmentNameRequestParams) =>
    await actOnClassicClient.query<IsAValidSegmentNameQuery, IsAValidSegmentNameQueryVariables>({
      query: isAValidSegmentName,
      errorPolicy: 'all',
      variables: {
        segmentName,
      },
    })

  return {
    isAValidSegmentNameRequest,
  }
}
