import { ApolloClient } from '@apollo/client'
import getSmsMessageChat from '@graphql/microservices/sms-management/getSmsMessageChat'
import { GetSmsMessageChatQuery, GetSmsMessageChatQueryVariables, QueryGetSmsMessageChatArgs } from '@graphql/types/microservice/sms-management-types'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'

export const useSmsMessageChatResults = (client: ApolloClient<any>, variables: GetSmsMessageChatQueryVariables) => {
  return useQueryOnMount<GetSmsMessageChatQuery, QueryGetSmsMessageChatArgs>(getSmsMessageChat, {
    client,
    fetchPolicy: 'network-only',
    variables: variables,
  })
}
