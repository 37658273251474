import React, { MutableRefObject, FC, useCallback, useState } from 'react'

import ImagePickerModalEmptyListing from '@components/AssetPickers/ImagePickerModal/components/ImagePickerModalContent/ImagePickerModalEmptyListing'
import ImagePickerModalGridView from '@components/AssetPickers/ImagePickerModal/components/ImagePickerModalContent/ImagePickerModalGridView'
import ImagePickerModalSearchResults from '@components/AssetPickers/ImagePickerModal/components/ImagePickerModalContent/ImagePickerModalSearchResults'
import ImagePickerModalTable from '@components/AssetPickers/ImagePickerModal/components/ImagePickerModalContent/ImagePickerModalTable'
import { useImagePickerModalContentContext } from '@components/AssetPickers/ImagePickerModal/context/ImagePickerModalContent.context'
import {
  imagePickerSortOptionsLibrary,
  imagePickerSortOptionsLogos,
  ImagePickerView,
} from '@components/AssetPickers/ImagePickerModal/utils/ImagePickerModal.utils'
import LazyLoadOnScrollContainer from '@components/LazyLoadOnScrollContainer/LazyLoadOnScrollContainer'
import SortDropDown from '@components/SortDropDown/SortDropDown'
import { SvgNames } from '@components/Svg'
import SwitchButtons from '@components/SwitchButtons/SwitchButtons'

import './ImagePickerModalContent.css'

interface ImagePickerModalContentProps {
  viewTypeRef: MutableRefObject<ImagePickerView>
  onLoadNext: () => Promise<void>
  allLoaded: boolean
  saveScrollSessionKey?: string
  dataTest?: string
}

const rootClass = 'image-picker-modal-content'

const ImagePickerModalContent: FC<ImagePickerModalContentProps> = ({
  viewTypeRef,
  onLoadNext,
  allLoaded,
  saveScrollSessionKey,
  dataTest = rootClass,
}) => {
  const { imageItems, loading, onSortColumnChange, sortBy, isLogos } = useImagePickerModalContentContext()
  const [view, setView] = useState<ImagePickerView>(viewTypeRef.current)
  const isListView = view === ImagePickerView.LIST

  const handleViewChange = useCallback(
    (view: ImagePickerView) => {
      viewTypeRef.current = view
      setView(view)
    },
    [viewTypeRef]
  )

  if (!loading && !imageItems.length) {
    return <ImagePickerModalEmptyListing dataTest={`${dataTest}-empty-listing`} />
  }

  return (
    <>
      <div className={`${rootClass}__filters`} data-test={`${dataTest}-filters`}>
        <SortDropDown
          options={isLogos ? imagePickerSortOptionsLogos : imagePickerSortOptionsLibrary}
          onSelect={onSortColumnChange}
          selectedOption={sortBy}
          dataTest={`${dataTest}-sort`}
        />
        <SwitchButtons
          onChange={handleViewChange}
          icons={[
            { icon: SvgNames.gridView, key: ImagePickerView.GRID },
            { icon: SvgNames.listView, key: ImagePickerView.LIST },
          ]}
          defaultActive={view}
          dataTest={`${dataTest}-switch`}
        />
      </div>
      <LazyLoadOnScrollContainer
        onLoadMoreData={onLoadNext}
        hideSpinner={isListView}
        className={`${rootClass}__container`}
        allLoaded={allLoaded}
        saveScrollSessionKey={saveScrollSessionKey}
      >
        <ImagePickerModalSearchResults dataTest={`${dataTest}-search-results`} />
        {isListView ? <ImagePickerModalTable /> : <ImagePickerModalGridView dataTest={`${dataTest}-grid`} />}
      </LazyLoadOnScrollContainer>
    </>
  )
}

export default ImagePickerModalContent
