import React from 'react'

import { TFunction } from 'i18next'

import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { SortDropDownOption } from '@components/SortDropDown/sortDropDown.types'
import Typography, { TextType } from '@components/Typography/Typography'
import { ColumnSort } from '@tanstack/react-table'
import { allImagesFilter, allLogosFilter, FilterDefinition } from '@utils/filter'

export enum ImagePickerSource {
  LIBRARY = 'LIBRARY',
  LOGOS = 'LOGOS',
  EXTERNAL = 'EXTERNAL',
  DYNAMIC_IMAGE = 'DYNAMIC_IMAGE',
  DYNAMIC_LOGO = 'DYNAMIC_LOGO',
}

export interface DynamicImageModalStates {
  showPickerModal: boolean
  showListFieldModal: boolean
  showDynamicImageModal: boolean
}

export type ImagePickerModalInsertCallback = (data: IImagePickerItem | undefined, isLogos?: boolean) => void

export enum ImagePickerView {
  LIST = 'list',
  GRID = 'grid',
}

export interface IImagePickerItem {
  id: string
  url: string
  title: string
  dimensions?: string
  size?: string
  created?: number
  modified?: number
  isFavorite?: boolean
  isDefault?: boolean
  thumbNailUrl?: string
  isLocal?: boolean
  linkUrl?: string
}

export interface IImagePickerFilterState {
  activeSource: ImagePickerSource
  activeFilter?: FilterDefinition
  activeFolder?: number
}

export const getInitialImagePickerFilterState = (fromLogos?: boolean): IImagePickerFilterState => ({
  activeSource: fromLogos ? ImagePickerSource.LOGOS : ImagePickerSource.LIBRARY,
  activeFilter: fromLogos ? allLogosFilter : allImagesFilter,
})

export const initialImagePickerSortStateLibrary: ColumnSort = {
  id: 'modified',
  desc: true,
}

export const initialImagePickerSortStateLogos: ColumnSort = {
  id: 'title',
  desc: false,
}

export const imagePickerSortOptionsLogos: SortDropDownOption[] = [
  {
    label: 'Title',
    id: 'title',
    ascLabel: 'A-Z',
    descLabel: 'Z-A',
  },
  {
    label: 'Dimensions',
    id: 'dimensions',
    ascLabel: 'Smallest first',
    descLabel: 'Largest first',
  },
  {
    label: 'File size',
    id: 'size',
    ascLabel: 'Smallest first',
    descLabel: 'Largest first',
  },
]

export const imagePickerSortOptionsLibrary: SortDropDownOption[] = [
  ...imagePickerSortOptionsLogos,
  {
    label: 'Date created',
    id: 'created',
    descLabel: 'Newest first',
    ascLabel: 'Oldest first',
    descFirst: true,
  },
  {
    label: 'Last Updated',
    id: 'modified',
    descLabel: 'Newest first',
    ascLabel: 'Oldest first',
    descFirst: true,
  },
]

export const getImageSourceOptions: (t: Function) => SelectV2SingleOption[] = (t) => [
  {
    label: t('Image library'),
    value: ImagePickerSource.LIBRARY,
  },
  {
    label: t('Account logos'),
    value: ImagePickerSource.LOGOS,
  },
]

export const getFileType = (url: string): string | undefined => {
  try {
    const pathname = new URL(url).pathname
    const type = pathname.split('.').pop()
    return type && type.length > 5 ? undefined : type
  } catch (e) {
    return undefined
  }
}

export const convertToBytes = (sizeString: string): number => {
  const value = parseFloat(sizeString)
  const unit = sizeString.match(/[a-zA-Z]+/)?.[0].toUpperCase() || 'B'
  const units = ['B', 'KB', 'MB', 'GB', 'TB']

  let multiplier = 1
  for (let i = 1; i < units.length; i++) {
    if (units[i] === unit) {
      multiplier = 1024 ** i
      break
    }
  }

  return value * multiplier
}

export const getIsUnsupportedFileType = (accept: string, url: string) => {
  const fileType = url.split('.').slice(-1)[0]
  const formatsToCheck = 'jpg, jpeg, gif, png, svg'

  const fileTypeToCompare = fileType.includes('?') ? fileType.split('?')[0] : fileType.toLocaleLowerCase()

  return formatsToCheck.includes(fileTypeToCompare) ? !accept.split(', ').includes(`.${fileTypeToCompare}`) : false
}

export const getSizeInMB = (size: number) => {
  return size / Math.pow(1024, 2)
}

export const getUnsupportedFileTypeTooltipText = (fileTypes: string, t: Function) => {
  const arrayFileTypes = fileTypes.split(', ')

  const getFormattedType = (type: string) => {
    return type.split('.')[1]
  }

  if (arrayFileTypes.length === 1) {
    return t('Image.Picker.Modal.Suppoted.FileType', { type: getFormattedType(arrayFileTypes[0]), context: 'single' })
  } else if (arrayFileTypes.length === 2) {
    return t('Image.Picker.Modal.Suppoted.FileType', {
      type1: getFormattedType(arrayFileTypes[0]),
      type2: getFormattedType(arrayFileTypes[1]),
      context: 'two',
    })
  } else {
    const types = arrayFileTypes
      .slice(0, -1)
      .map((type) => getFormattedType(type))
      .join(', ')

    const lastType = getFormattedType(arrayFileTypes[arrayFileTypes.length - 1])
    return t('Image.Picker.Modal.Suppoted.FileType', { types, lastType, context: 'multiple' })
  }
}

export const getTooltipText = (
  maxSizeExedeed: boolean,
  isUnsupportedFileType: boolean,
  accept: string | undefined,
  imageMaxSize: number | undefined,
  t: TFunction
) => {
  return maxSizeExedeed && isUnsupportedFileType ? (
    <div>
      <Typography
        text={t('Image.Picker.Modal.Max.Size.Tooltip', { size: imageMaxSize && getSizeInMB(imageMaxSize) })}
        type={TextType.BODY_TEXT_WHITE}
        inline
      />
      {'. '}
      <Typography text={getUnsupportedFileTypeTooltipText(accept ?? '', t)} type={TextType.BODY_TEXT_WHITE} inline />
    </div>
  ) : isUnsupportedFileType ? (
    <Typography text={getUnsupportedFileTypeTooltipText(accept ?? '', t)} type={TextType.BODY_TEXT_WHITE} />
  ) : maxSizeExedeed ? (
    <Typography
      text={t('Image.Picker.Modal.Max.Size.Tooltip', { size: imageMaxSize && getSizeInMB(imageMaxSize) })}
      type={TextType.BODY_TEXT_WHITE}
    />
  ) : null
}
