import Highcharts from 'highcharts'

export const initHighcharts = () => {
  // Add any other Highcharts initialization here
  // For example:
  // Highcharts.setOptions({ lang: { thousandsSep: ',' } });
  // Highcharts.setOptions({ colors: ['#ff0000', '#00ff00', '#0000ff'] });

  Highcharts.setOptions({
    time: { useUTC: false },
  })
}

export const getHighchartsDateFormat = (format: string, timestamp: number, capitalize?: boolean) => {
  initHighcharts()
  return Highcharts.dateFormat(format, timestamp, capitalize)
}

export default { initHighcharts, getHighchartsDateFormat }
