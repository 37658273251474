import React, { FC, useCallback, useEffect, useState } from 'react'

import classNames from 'classnames'

import { rootContext, useTranslation } from '@const/globals'
import getDashboardId from '@graphql/microservices/thoughtspot/getDashboardId'
import LiveboardContainer from '@src/pages/reports/Liveboard/LiveboardContainer'
import { useAccountSettings } from '@utils/account/account.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { logNewRelicError } from '@utils/new-relic.utils'

import './FormsLPsMP.css'

interface FormsLPsMPProps {
  className?: string
  dataTest?: string
}

interface FormsLPsMPState {
  liveboardId?: string
  dashboardToken?: string
  loading: boolean
  queryError?: string
}

const defaultState: FormsLPsMPState = {
  liveboardId: undefined,
  dashboardToken: undefined,
  loading: true,
}

const rootClass = 'forms-l-ps-mp'

const FormsLPsMP: FC<FormsLPsMPProps> = (props: FormsLPsMPProps) => {
  const { hasEmbeddedAnalytics } = useAccountSettings()
  if (!hasEmbeddedAnalytics) {
    window.location.assign(`${rootContext}/classic/dashboard`)
  }

  const { dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const [state, setState] = useState<FormsLPsMPState>(defaultState)
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.THOUGHTSPOT })

  const getDashboardIdRequest = useCallback(() => {
    client
      .query({ query: getDashboardId, fetchPolicy: 'network-only', errorPolicy: 'all', variables: { dashboardName: 'Forms, Landing Pages, Media' } })
      .then((response) => {
        const liveboardId = response.data.getDashboardId
        if (liveboardId) {
          setState((prevState) => ({ ...prevState, liveboardId, loading: false }))
        }
        if (response.error || response.errors) {
          logNewRelicError(response.error ?? response.errors)
          setState((prevState) => ({ ...prevState, queryError: 'Unable to retrieve the dashboard', loading: false }))
        }
      })
  }, [client])

  useEffect(() => {
    getDashboardIdRequest()
  }, [getDashboardIdRequest])

  return (
    <LiveboardContainer
      liveboardId={state.liveboardId}
      title={t('Forms, Landing Pages, Media Performance')}
      loading={state.loading}
      queryError={state.queryError}
      className={classNames(rootClass, className)}
      dataTest={dataTest}
    />
  )
}

export default FormsLPsMP
