import { gql } from '@apollo/client'

export default gql`
  query getMarketingUser {
    getMarketingUser {
      alertsAddress
      isVerified
      created
      timezone
      extUserId
      isAdmin
      isCRM
      login
      title
      cell
      firstName
      isSingleSignOn
      isMe
      phone
      id
      fax
      email
      lastName
      launchApproval
      launchApprovalId
      fullName
      permissions {
        contactLists
        allContent
        accountWideEmails
        automatedPrograms
        launchApproval
      }
      disabled
      jobTitle
      streetAddress
      streetAddress2
      city
      state
      zip
      country
    }
  }
`
