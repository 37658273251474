import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import RecipientsList, {
  Recipient,
} from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertRecipients/components/RecipientsList/RecipientsList'
import { recipientsListMock } from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertRecipients/components/RecipientsList/utils/RecipientsList.mock'
import RecipientSelect from '@src/pages/WebsiteProspectorAlerts/components/RecipientSelect/RecipientSelect'
import { useAccountSettings } from '@utils/account/account.utils'

import './AlertRecipients.css'

interface AlertRecipientsProps {
  className?: string
  dataTest?: string
}

const rootClass = 'alert-recipients'

const AlertRecipients: FC<AlertRecipientsProps> = (props: AlertRecipientsProps) => {
  const { dataTest = rootClass, className = '' } = props

  const { userEmail, userName, userId } = useAccountSettings()

  const [recipients, setRecipients] = useState(recipientsListMock)

  const onMute = (recipient: Recipient, mute: boolean) => {
    const recipientIndex = recipients.findIndex(({ id }) => id === recipient.id)
    const newRecipientsList = [
      ...recipients.slice(0, recipientIndex),
      { ...recipients[recipientIndex], isMuted: mute },
      ...recipients.slice(recipientIndex + 1),
    ]
    setRecipients(newRecipientsList)
  }

  const onRemove = (deletedRecipients: Recipient[]) => {
    const newRecipientsList = recipients.filter((recipient) => !deletedRecipients.includes(recipient))
    setRecipients(newRecipientsList)
  }

  useEffect(() => {
    if (userId) {
      const currentUser: Recipient = {
        name: userName,
        email: userEmail,
        id: parseInt(userId),
        userType: 'Owner',
      }
      setRecipients([currentUser, ...recipients])
    }
  }, [userId])

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <RecipientSelect dataTest={`${dataTest}-search`} recipients={recipients} className={`${rootClass}__search`} />
      <RecipientsList recipients={recipients} onMute={onMute} onRemove={onRemove} />
    </div>
  )
}

export default AlertRecipients
