import { ApolloClient } from '@apollo/client'
import { renderSegmentNameWithInfoHover } from '@complex/ContactSegments/segmentInfoHover.utils'
import { Column } from '@components/ColumnsOrderModal/components/DraggableColumn/DraggableColumn'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import changeBounceStatus from '@graphql/microservices/segment/changeBounceStatus'
import { ChangeBounceStatusMutation, ChangeBounceStatusMutationVariables } from '@graphql/types/microservice/segment-types'
import { BouncesSegments, bouncesVerbs } from '@utils/categorization'
import { Contact } from '@utils/contact/contact.constants'
import { Segment } from '@utils/contactSegments/contactSegments.utils'
import { relativeDate } from '@utils/date'
import { getFormattedNumber } from '@utils/numbers'

export const contactPreferencesColumnsV2: ColumnDefWithAdditionalProps<Segment>[] = [
  {
    header: 'Segments',
    accessorKey: 'name',
    textAlign: 'left',
    enableFlexCell: true,
    cell: ({ row: { original } }) => renderSegmentNameWithInfoHover(original),
  },
  {
    header: 'Tags',
    accessorKey: 'tags',
    textAlign: 'left',
    maxSize: 180,
    enableCustomCellValue: true,
  },
  {
    header: 'Records',
    accessorKey: 'recordsCount',
    textAlign: 'left',
    maxSize: 100,
    cell: ({ cell }) => getFormattedNumber(cell.getValue<number>()),
  },
  {
    header: 'Last Count',
    accessorKey: 'lastCountDate',
    textAlign: 'left',
    maxSize: 120,
    cell: ({ cell }) => relativeDate(cell.getValue<string>()),
  },
]

const getEmailFieldIndex = (headers: Column[]) =>
  headers.findIndex(({ name }) => name === ('E-mail Address' || 'Email Address' || 'E-mail' || 'Email'))

export const getChangeBounceStatusConfirmationModal = (contacts: Contact[], headers: Column[], segment: Segment, t: Function) => {
  const titles: { [key: string]: (email: string) => { title: string; body: string; button: string } } = {
    [BouncesSegments.OPT_OUT]: (email: string) => ({
      title: 'Remove contact from opt-out list?',
      body: t('CHANGE_BOUNCE_STATUS.OPT_OUT', { email }),
      button: 'Remove contact',
    }),
    [BouncesSegments.SOFT_BOUNCES]: (email: string) => ({
      title: 'Change contact status to hard bounce?',
      body: t('CHANGE_BOUNCE_STATUS.SOFT_BOUNCES', { email }),
      button: 'Change status',
    }),
    [BouncesSegments.HARD_BOUNCES]: (email: string) => ({
      title: 'Change contact status to soft bounce?',
      body: t('CHANGE_BOUNCE_STATUS.HARD_BOUNCES', { email }),
      button: 'Change status',
    }),
  }

  const emailFieldIndex = getEmailFieldIndex(headers)
  const email = contacts[0].fields[emailFieldIndex]
  return titles[segment.name](contacts.length > 1 ? t('the selected contacts') : email ?? t('the selected contact'))
}

export const onRemoveFromBounce = (contacts: Contact[], segment: Segment, client: ApolloClient<any>) => {
  const contactRecIds = contacts.map(({ recId }) => recId)
  const verb = Object.values(bouncesVerbs).find((id) => id === segment.externalId) ?? ''
  return changeContactsBounceStatus(contactRecIds, verb, client)
}

export const changeContactsBounceStatus = (recIds: string[], verb: string, client: ApolloClient<any>) =>
  client.mutate<ChangeBounceStatusMutation, ChangeBounceStatusMutationVariables>({
    mutation: changeBounceStatus,
    variables: { recIds, verb },
  })
