import React from 'react'
import { Row } from 'react-table'

import { TableActions } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.constants'
import { getActionProps } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.utils'
import {
  ItemDtoWithBeeComposer,
  ListPageCommonState,
  ListPageTableActionCustomProps,
  TableProps,
} from '@complex/ListingPage/Context/ListingPageCommon.context'
import { Update } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import Pill, { PillSize, PillType } from '@components/Pill/Pill'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { renderRelativeDate } from '@components/Table/components/tableColumns'
import { RowAction } from '@components/Table/Table'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType } from '@components/Typography/Typography'
import { rootContext } from '@const/globals'
import { ItemDto } from '@graphql/types/query-types'
import { LandingPageManagerSubTab, LandingPageManagerTab } from '@src/pages/Content/LandingPages/LandingPagesManager/LandingPageManager.utils'
import { AssetTypes } from '@utils/actonAssetTypes'
import { ItemType } from '@utils/categorization'
import { CommonComposerTab } from '@utils/composer/commonComposer/CommonComposer.context'
import { getStandardFormattedDate, relativeDate } from '@utils/date'
import { FilterDefinition, allLandingPages, createdByMeFilter, favoriteFilter, recentFilter } from '@utils/filter'
import { openLandingPageEditorWindow } from '@utils/landingPages'
import { getFormattedCount } from '@utils/numbers'
import { renderPathToFolder } from '@utils/searchUtils'

import LandingPagesInfoHover from '../components/LandingPagesInfoHover/LandingPagesInfoHover'
import { LandingPages } from '../LandingPagesListingPageContainer.contants'

export enum LandingPagesCustomTableActions {
  SHOW_DASHBOARD = 'SHOW_DASHBOARD',
  SHOW_DETAILS = 'SHOW_DETAILS',
  EDIT = 'EDIT',
  CREATE_TEMPLATE = 'CREATE_TEMPLATE',
  CREATE_LANDING_PAGE = 'CREATE_LANDING_PAGE',
  CREATE_LANDING_PAGE_FROM_TEMPLATE = 'CREATE_LANDING_PAGE_FROM_TEMPLATE',
  CREATE_LANDING_PAGE_FROM_CODE = 'CREATE_LANDING_PAGE_FROM_CODE',
  CREATE_LANDING_PAGE_FROM_URL = 'CREATE_LANDING_PAGE_FROM_URL',
  PUBLIC_URLS = 'PUBLIC_URLS',
  CREATE_LANDING_PAGE_DUPLICATE = 'CREATE_LANDING_PAGE_DUPLICATE',
  CREATE_TEMPLATE_FROM_LANDING_PAGE_DUPLICATE = 'CREATE_TEMPLATE_FROM_LANDING_PAGE_DUPLICATE',
}

const rowActionCustomProps: ListPageTableActionCustomProps[] = [
  { name: 'SHOW_DASHBOARD', position: 0, hasTopSection: false, isInDropdown: false },
  { name: 'EDIT', position: 1, hasTopSection: false, isInDropdown: false },
  { name: 'PREVIEW', position: 2, hasTopSection: false, isInDropdown: false },
  { name: 'ADD_TO_FAVORITES', position: 3, hasTopSection: false, isInDropdown: false },
  { name: 'CREATE_TEMPLATE', position: 4, hasTopSection: false, isInDropdown: true },
  { name: 'PUBLIC_URLS', position: 5, hasTopSection: false, isInDropdown: true },
  { name: 'DUPLICATE', position: 6, hasTopSection: true, isInDropdown: true },
  { name: 'SHARE_TO_CATALOG', position: 7, hasTopSection: false, isInDropdown: true },
  { name: 'SHARE_TO_ACCOUNTS', position: 8, hasTopSection: false, isInDropdown: true },
  { name: 'MOVE_TO_FOLDER', position: 9, hasTopSection: true, isInDropdown: true },
  { name: 'REMOVE_FROM_FOLDER', position: 10, hasTopSection: false, isInDropdown: true },
  { name: 'DELETE', position: 11, hasTopSection: true, isInDropdown: true },
]

const headerActionCustomProps: ListPageTableActionCustomProps[] = [
  { name: 'ADD_TO_FAVORITES', position: 0 },
  { name: 'MANAGE_TAGS', position: 1 },
  { name: 'MOVE_TO_FOLDER', position: 2 },
  { name: 'REMOVE_FROM_FOLDER', position: 3 },
  { name: 'PUBLIC_URLS', position: 4, isInDropdown: true },
  { name: 'SHARE_TO_CATALOG', position: 5, isInDropdown: true },
  { name: 'DELETE', position: 6, isInDropdown: true },
]

const onCustomTableAction = (
  customTableAction: LandingPagesCustomTableActions,
  update: Update,
  listPageValues: ListPageCommonState,
  selectedItem?: ItemDto,
  newLPComposerCreateBlank?: boolean
) => {
  const showCustomModal = (action: LandingPagesCustomTableActions, refetchCountsOnAction = true) =>
    update({
      customTableAction: action,
      showCustomModal: true,
      refetchCountsOnAction,
    })

  const { selectedRows } = listPageValues
  const selectedRow = selectedItem ?? selectedRows[0]
  const row = selectedRow as LandingPages
  const selectedRowId = row?.externalId ?? '-1'
  const isBeeComposer = row.beeComposer

  const customAction: { [key in LandingPagesCustomTableActions]: () => void } = {
    [LandingPagesCustomTableActions.CREATE_TEMPLATE]: () => showCustomModal(LandingPagesCustomTableActions.CREATE_TEMPLATE, false),
    [LandingPagesCustomTableActions.CREATE_LANDING_PAGE]: () => showCustomModal(LandingPagesCustomTableActions.CREATE_LANDING_PAGE, false),
    [LandingPagesCustomTableActions.CREATE_LANDING_PAGE_FROM_TEMPLATE]: () =>
      showCustomModal(LandingPagesCustomTableActions.CREATE_LANDING_PAGE_FROM_TEMPLATE, false),
    [LandingPagesCustomTableActions.CREATE_LANDING_PAGE_FROM_CODE]: () =>
      showCustomModal(LandingPagesCustomTableActions.CREATE_LANDING_PAGE_FROM_CODE, false),
    [LandingPagesCustomTableActions.CREATE_LANDING_PAGE_FROM_URL]: () =>
      showCustomModal(LandingPagesCustomTableActions.CREATE_LANDING_PAGE_FROM_URL, false),
    [LandingPagesCustomTableActions.EDIT]: () => openLandingPageEditorWindow(selectedRowId, isBeeComposer),
    [LandingPagesCustomTableActions.SHOW_DETAILS]: () => {
      window.location.href = `${rootContext}/content/landingPages/${selectedRowId}/${LandingPageManagerTab.DETAILS}`
    },
    [LandingPagesCustomTableActions.SHOW_DASHBOARD]: () => {
      if (isBeeComposer && !newLPComposerCreateBlank) {
        window.open(`${rootContext}/content/landingPage/${selectedRowId}/${CommonComposerTab.REPORT}`, '_blank')
      } else {
        window.location.href = `${rootContext}/content/landingPages/${selectedRowId}/${LandingPageManagerTab.REPORT}`
      }
    },
    [LandingPagesCustomTableActions.PUBLIC_URLS]: () => {
      if (isBeeComposer && !newLPComposerCreateBlank) {
        window.open(`${rootContext}/content/landingPage/${selectedRowId}/${CommonComposerTab.SETTINGS}`, '_blank')
      } else {
        window.location.href = `${rootContext}/content/landingPages/${selectedRowId}/${LandingPageManagerTab.DETAILS}/${LandingPageManagerSubTab.MANAGE_URLS}`
      }
    },
    [LandingPagesCustomTableActions.CREATE_LANDING_PAGE_DUPLICATE]: () => {
      showCustomModal(LandingPagesCustomTableActions.CREATE_LANDING_PAGE_DUPLICATE, false)
    },
    [LandingPagesCustomTableActions.CREATE_TEMPLATE_FROM_LANDING_PAGE_DUPLICATE]: () => {
      showCustomModal(LandingPagesCustomTableActions.CREATE_TEMPLATE_FROM_LANDING_PAGE_DUPLICATE, false)
    },
  }
  customAction[customTableAction]()
}

export const getCustomEmptyListingProps = (
  setFilter: (filterDefinition: FilterDefinition, customFilterSelected: boolean) => void,
  update: Update,
  goToCatalog: () => void,
  filter?: FilterDefinition
) => {
  if (filter === favoriteFilter) {
    return {
      imgSrc: StaticImageNames.emptyFavorites,
      size: EmptyListingSize.LARGE,
      headline: 'ListPage.LandingPages.EmptyState.Favorites.Headline',
      text: 'ListPage.LandingPages.EmptyState.Favorites.Text',
      buttonText: 'ListPage.LandingPages.EmptyState.Favorites.ButtonText',
      buttonOnClick: () => setFilter(allLandingPages, false),
    }
  } else if (filter === createdByMeFilter) {
    return {
      imgSrc: StaticImageNames.emptyCreatedByMe,
      size: EmptyListingSize.LARGE,
      headline: 'ListPage.LandingPages.EmptyState.CreatedByMe.Headline',
      text: 'ListPage.LandingPages.EmptyState.CreatedByMe.Text',
      buttonText: 'ListPage.LandingPages.EmptyState.CreatedByMe.ButtonText',
      buttonOnClick: () => update({ showCustomModal: true, customTableAction: LandingPagesCustomTableActions.CREATE_LANDING_PAGE }),
    }
  } else if (filter === recentFilter) {
    return {
      imgSrc: StaticImageNames.emptyRecent,
      size: EmptyListingSize.LARGE,
      headline: 'You haven’t used these recently',
      text: 'You’ll find items that you used recently here.',
    }
  } else {
    return {
      imgSrc: StaticImageNames.emptyChart,
      size: EmptyListingSize.LARGE,
      headline: 'ListPage.LandingPages.EmptyState.AllLandingPages.Headline',
      text: 'ListPage.LandingPages.EmptyState.AllLandingPages.Text',
      linkText: 'ListPage.LandingPages.EmptyState.AllLandingPages.LinkText',
      link: 'https://connect.act-on.com/hc/en-us/articles/360023758214-Landing-Page-Composer-Overview',
      hideIcon: false,
      buttonText: 'ListPage.LandingPages.EmptyState.AllLandingPages.ButtonText',
      buttonOnClick: () => goToCatalog(),
    }
  }
}

const renderItemNameVal = (original: LandingPages, search: string, rootClass: string, t: Function) => {
  const item = original
  const isEnabled = item.enabled

  return (
    <>
      <div className={`${rootClass}__landing-page-name`}>
        <div className={`${rootClass}__landing-page-name-wrapper`}>
          <Tooltip
            ellipOnTrigger
            trigger={
              <div className={`${rootClass}__landing-page-name-typography`}>
                <Svg className="push-left" name={isEnabled ? SvgNames.circleGreen : SvgNames.circle} type={SvgType.SMALL_ICON} />
                <div className={'ellip'}>{search ? renderBoldTextOnMatch(item.name, search) : item.name}</div>
              </div>
            }
          >
            {item.name}
          </Tooltip>
          {item.beeComposer && <Pill text={t('BETA')} type={PillType.SECONDARY} noMargin size={PillSize.SMALL} />}
          <LandingPagesInfoHover
            name={item.name}
            description={item.description}
            enabled={item.enabled}
            connectedForms={item.connectedForms}
            createdOn={item.createdAt}
            createdBy={item.createdBy}
            lastUpdated={item.lastUpdated}
            updatedBy={item.lastUpdatedBy}
          />
        </div>
      </div>
    </>
  )
}

const getCustomRowActions = (
  tableActions: TableActions,
  t: Function,
  canCreate: boolean,
  _?: boolean,
  newLPComposerTemplates?: boolean
): RowAction[] => [
  {
    label: t('View report'),
    icon: SvgNames.graphBar,
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(LandingPagesCustomTableActions.SHOW_DASHBOARD, row),
    ...getActionProps(LandingPagesCustomTableActions.SHOW_DASHBOARD, rowActionCustomProps),
  },
  {
    label: t('Edit'),
    icon: SvgNames.pencil,
    hidden: !canCreate,
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(LandingPagesCustomTableActions.EDIT, row),
    ...getActionProps(LandingPagesCustomTableActions.EDIT, rowActionCustomProps),
  },
  {
    label: t('Create template'),
    icon: SvgNames.appendedList,
    disabled: (row: Row<ItemDtoWithBeeComposer>) => (!newLPComposerTemplates && row?.original?.beeComposer) || !canCreate,
    hasTooltip: !!((row: Row<ItemDtoWithBeeComposer>) => row?.original?.beeComposer) || !canCreate,
    tooltipMessage: (row?: Row<ItemDtoWithBeeComposer>) =>
      !newLPComposerTemplates && row?.original?.beeComposer
        ? 'Beta.Emails.Tooltip.Message.Duplicate'
        : !canCreate
        ? 'Ask your administrator for permission to do this'
        : '',
    onClick: (row: Row<ItemDtoWithBeeComposer>) =>
      tableActions.customTableRowAction(
        newLPComposerTemplates && row.original.beeComposer
          ? LandingPagesCustomTableActions.CREATE_TEMPLATE_FROM_LANDING_PAGE_DUPLICATE
          : LandingPagesCustomTableActions.CREATE_TEMPLATE,
        row
      ),
    ...getActionProps(LandingPagesCustomTableActions.CREATE_TEMPLATE, rowActionCustomProps),
  },
  {
    label: t('Public URLs'),
    icon: SvgNames.linked,
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(LandingPagesCustomTableActions.PUBLIC_URLS, row),
    ...getActionProps(LandingPagesCustomTableActions.PUBLIC_URLS, rowActionCustomProps),
  },
]

export const tableV2Columns = (t: Function): ColumnDefWithAdditionalProps<LandingPages, any>[] => [
  {
    header: t('Landing Page Title'),
    accessorKey: 'name',
    textAlign: 'left',
    cell: ({ row: { original } }) => renderItemNameVal(original, '', 'landing-pages-listing-page-container', t),
    padding: {
      right: 0,
    },
  },
  {
    header: t('Tags'),
    accessorKey: 'tags',
    textAlign: 'left',
    maxSize: 126,
    disableSorting: true,
    enableCustomCellValue: true,
  },
  {
    header: () => (
      <div className={'landing-pages-listing-page-container__views'}>
        <Typography type={TextType.TABLE_HEADER} text={t('Views')} />
        <Tooltip triggerClassName={`landing-pages-listing-page-container__views__info`} trigger={<Svg name={SvgNames.info} type={SvgType.ICON} />}>
          {t('ListPage.LandingPages.Table.ViewsColumn.InfoText')}
        </Tooltip>
      </div>
    ),
    accessorKey: 'views',
    textAlign: 'right',
    fieldType: 'integer',
    cell: (row) => getFormattedCount(row.getValue()) || 0,
    maxSize: 100,
  },
  {
    header: () => (
      <div className={'landing-pages-listing-page-container__clicks'}>
        <Typography type={TextType.TABLE_HEADER} text={t('Clicks')} />
        <Tooltip triggerClassName={`landing-pages-listing-page-container__clicks__info`} trigger={<Svg name={SvgNames.info} type={SvgType.ICON} />}>
          {t('ListPage.LandingPages.Table.ClicksColumn.InfoText')}
        </Tooltip>
      </div>
    ),
    accessorKey: 'clicks',
    textAlign: 'right',
    fieldType: 'integer',
    cell: (row) => getFormattedCount(row.getValue()) || 0,
    maxSize: 100,
  },
  {
    header: t('Last Updated'),
    accessorKey: 'lastUpdated',
    textAlign: 'left',
    fieldType: 'TIMESTAMP',
    cell: (cell) => renderRelativeDate(relativeDate(cell.getValue(), true, false), getStandardFormattedDate(cell.getValue())),
    maxSize: 150,
  },
]

const renderSearchColumnsV2 = (
  searchInAllItems: boolean | undefined,
  currentFolder: FolderData,
  search: string,
  folders: FolderData[],
  t: Function
): ColumnDefWithAdditionalProps<LandingPages>[] => [
  {
    header: 'Landing Page Title',
    accessorKey: 'name',
    textAlign: 'left',
    cell: ({ row: { original } }) => renderItemNameVal(original, search, 'landing-pages-listing-page-container', t),
  },
  {
    header: 'Tags',
    accessorKey: 'tags',
    textAlign: 'left',
    maxSize: 150,
    disableSorting: true,
    enableCustomCellValue: true,
  },
  {
    header: 'Location',
    accessorKey: 'folderId',
    textAlign: 'left',
    maxSize: 150,
    cell: (row) =>
      renderPathToFolder(ItemType.LANDING_PAGE, row.getValue<number>(), folders, searchInAllItems, 'listing-page-container', t, currentFolder),
  },
  {
    header: 'Last Updated',
    accessorKey: 'lastUpdated',
    textAlign: 'left',
    maxSize: 150,
    cell: (cell) => renderRelativeDate(relativeDate(cell.getValue<number>(), true, false), getStandardFormattedDate(cell.getValue<number>())),
  },
]

const clickableColumnOptions = {
  colIndex: 0,
  action: { customTableAction: LandingPagesCustomTableActions.SHOW_DETAILS },
}

export const tableProps = (t: Function, canCreate: boolean, newLPComposerCreateBlank?: boolean, newLPComposerTemplates?: boolean): TableProps => ({
  listPage: 'LandingPages',
  headerActionCustomProps,
  rowActionCustomProps,
  getCustomRowActions: (tableActions) => getCustomRowActions(tableActions, t, canCreate, newLPComposerCreateBlank ?? false, newLPComposerTemplates),
  onCustomTableAction: (customAction, update, listPageValues, selectedItem) =>
    onCustomTableAction(customAction, update, listPageValues, selectedItem, newLPComposerCreateBlank),
  clickableColumnOptions,
  hasAutoSelectedRows: false,
  hasExpander: false,
  columns: tableV2Columns(t),
  renderSearchColumns: (searchInAllItems: boolean | undefined, currentFolder: FolderData, search: string, folders: FolderData[]) =>
    renderSearchColumnsV2(searchInAllItems, currentFolder, search, folders, t),
  onRowClicked: (selectedItem: Row<LandingPages>) => {
    if (selectedItem?.original?.beeComposer && !newLPComposerCreateBlank) {
      window.open(`${rootContext}/content/landingPage/${selectedItem.original.externalId}/${CommonComposerTab.SETTINGS}`, '_blank')
    } else {
      window.location.href = `${rootContext}/content/landingPages/${selectedItem.original.externalId}/${LandingPageManagerTab.DETAILS}`
    }
  },
  actonAssetType: AssetTypes.LANDING_PAGE,
  shareModalText: '',
})
