import React, { FC, useState } from 'react'

import classNames from 'classnames'

import { ExtendedItemDto } from '@complex/ListingPage/Context/ListingPageCommon.context'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { useTranslation } from '@const/globals'
import AssetPickerSelector from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/AssetPickerSelector/AssetPickerSelector'
import {
  AssetsCriteria,
  QuantityCriteria,
} from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/BehaviorExpression/BehaviorExpression.interfaces'
import Quantity from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/Quantity/Quantity'
import {
  SegmentMembership,
  segmentMembershipOptionLabels,
} from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/SegmentMembershipExpression/SegmentMembershipExpression.constants'
import { AssetTypes } from '@utils/actonAssetTypes'

import './SegmentMembershipExpression.css'

interface SegmentMembershipExpressionProps {
  className?: string
  dataTest?: string
}

const rootClass = 'segment-membership-expression'

const SegmentMembershipExpression: FC<SegmentMembershipExpressionProps> = (props: SegmentMembershipExpressionProps) => {
  const { dataTest = rootClass, className = '' } = props

  const { t } = useTranslation()

  const membershipOptions = Object.values(SegmentMembership).map((membership) => ({
    value: membership,
    label: t(segmentMembershipOptionLabels[membership]),
  }))

  const [membership, setMembership] = useState<SelectV2SingleOption>(membershipOptions[0])
  const [segmentCriteria, setSegmentCriteria] = useState<AssetsCriteria>()

  const onSelect = (membership: SelectV2SingleOption | undefined) => {
    if (membership) {
      setMembership(membership)
    }
  }

  const onSelectSegment = (segments: ExtendedItemDto[]) => {
    setSegmentCriteria({ ...segmentCriteria, selectedAssets: segments } as AssetsCriteria)
  }

  const onQuantityChange = (quantityCriteria: QuantityCriteria) => {
    setSegmentCriteria({ ...segmentCriteria, quantityCriteria })
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <SelectV2
        dataTest={`${dataTest}-membership-select`}
        className={`${rootClass}__membership-select`}
        options={membershipOptions}
        defaultValue={membership}
        onChange={onSelect}
        isClearable={false}
        isSearchable={false}
      />
      <Quantity dataTest={`${dataTest}-quantity`} criteria={segmentCriteria?.quantityCriteria} onChange={onQuantityChange} isRequired>
        <AssetPickerSelector
          dataTest={`${dataTest}-segment-picker`}
          selectedAssets={segmentCriteria?.selectedAssets}
          onSelect={onSelectSegment}
          assetType={AssetTypes.SEGMENT}
        />
      </Quantity>
    </div>
  )
}

export default SegmentMembershipExpression
