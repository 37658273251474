import React, { FC, useContext } from 'react'

import classNames from 'classnames'

import CappedSignups from '@src/pages/Content/Forms/FormManager/components/CappedSignups/CappedSignups'
import FormManagerFormDetails from '@src/pages/Content/Forms/FormManager/components/FormManagerFormDetails/FormManagerFormDetails'
import LandingPageEmbedCodesContainer from '@src/pages/Content/Forms/FormManager/components/LandingPageEmbedCodesContainer/LandingPageEmbedCodesContainer'
import SettingsAndReports from '@src/pages/Content/Forms/FormManager/components/SettingsAndReports/SettingsAndReports'
import SubmissionDetails from '@src/pages/Content/Forms/FormManager/components/SubmissionDetails/SubmissionDetails'
import { FormManagerContext } from '@src/pages/Content/Forms/FormManager/context/FormManager.context'

import './FormManagerDetails.css'

interface FormManagerDetailsProps {
  className?: string
  dataTest?: string
}

const rootClass = 'form-manager-details'

const FormManagerDetails: FC<FormManagerDetailsProps> = (props: FormManagerDetailsProps) => {
  const { dataTest = rootClass, className = '' } = props
  const {
    values: {
      isClassic,
      cappedSignups: { enabled },
      crmPushErrorsCount,
    },
  } = useContext(FormManagerContext)

  const showSettings = isClassic || crmPushErrorsCount > 0

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__container-left`}>
        {!isClassic && <LandingPageEmbedCodesContainer />}
        {<SubmissionDetails />}
        {!isClassic && enabled && <CappedSignups />}
        {showSettings && <SettingsAndReports />}
      </div>
      <div className={`${rootClass}__container-right`}>
        <FormManagerFormDetails />
      </div>
    </div>
  )
}

export default FormManagerDetails
