import React, { FC, useContext, useMemo } from 'react'

import classNames from 'classnames'

import Input from '@components/Input/Input'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import TextArea from '@components/TextArea/TextArea'
import TimeZoneSelectV2 from '@components/TimeZoneSelectV2/TimeZoneSelectV2'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { INPUT_DEBOUNCE_TIME, useTranslation } from '@const/globals'
import { Program } from '@graphql/types/query-types'
import { ListMaintenanceProgramsContext } from '@src/pages/listingPages/ListMaintenancePrograms/context/ListMaintenancePrograms.context'
import { useAccountSettings } from '@utils/account/account.utils'
import { formatSendTSDate, relativeDate } from '@utils/date'
import { getDynamicTimezones, getTimezoneLabel, timeZoneData } from '@utils/timezones'

import './ListProgramBasicDetailsEditor.css'

interface ListProgramBasicDetailsEditorProps {
  className?: string
  dataTest?: string
  onChange: <T extends keyof Program>(field: T, value: Program[T]) => void
}

const rootClass = 'list-program-basic-details-editor'
const timeZoneTooltip = 'This time zone is used to interpret all schedule option times and all scheduled delay times in this program.'

const ListProgramBasicDetailsEditor: FC<ListProgramBasicDetailsEditorProps> = (props: ListProgramBasicDetailsEditorProps) => {
  const { dataTest = rootClass, className = '', onChange } = props

  const {
    values: { programDetails, program },
  } = useContext(ListMaintenanceProgramsContext)

  const { t } = useTranslation()

  const { userAllowedToCreatePrograms } = useAccountSettings()

  const { name: defaultName = '', description: defaultDescription = '' } = { ...program }
  const { createdOn, creator, lastRun, name = '', description = '', timeZoneId } = { ...programDetails }

  const createdOnValue = createdOn && creator ? `${formatSendTSDate(createdOn, { dateFormat: 'MMM d, yyyy' })} ${t('by')} ${creator}` : ''
  const createdOnTooltip = createdOn ? formatSendTSDate(createdOn, { includeTime: true }) : ''

  const lastRanValue = lastRun ? relativeDate(lastRun, true) : ''
  const lastRanTooltip = lastRun ? formatSendTSDate(lastRun, { includeTime: true }) : ''

  const timeZoneName = useMemo(() => timeZoneData.find(({ id }) => id === timeZoneId)?.name || '', [timeZoneId])

  const getTimezoneOptions = useMemo(() => {
    return getDynamicTimezones()
  }, [])

  const renderEditableFields = () => (
    <>
      <Input
        dataTest={`${dataTest}-input-name`}
        label={t('Program name')}
        placeholder={defaultName}
        value={name}
        onChange={({ target: { value } }) => onChange('name', value)}
        onChangeDebounce={INPUT_DEBOUNCE_TIME}
      />
      <div>
        <TextArea
          dataTest={`${dataTest}-input-description`}
          name={'program-description'}
          className={`${rootClass}__text-area`}
          label={t('Program description')}
          placeholder={defaultDescription}
          value={description}
          onChange={({ target: { value } }) => onChange('description', value)}
        />
      </div>
      <div className={`${rootClass}__details-timezone`} data-test={`${dataTest}-timezone`}>
        <div className={`${rootClass}__details-header-with-tooltip`}>
          <Typography text={t('Time zone')} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.MEDIUM} />
          <Tooltip trigger={<Svg name={SvgNames.info} type={SvgType.ICON} />}>{t(timeZoneTooltip)}</Tooltip>
        </div>
        <TimeZoneSelectV2
          isDisabled={!userAllowedToCreatePrograms}
          label={getTimezoneLabel(timeZoneId)}
          selectProps={{
            maxMenuHeight: 300,
            options: getTimezoneOptions,
            value: {
              label: getTimezoneLabel(timeZoneId),
              value: timeZoneId || '',
            },
            onChange: (e) => {
              e && onChange('timeZoneId', e.value)
            },
          }}
        />
      </div>
    </>
  )

  const renderReadOnlyField = (label: string, value: string, options?: { title?: string; headerTooltip?: string }) => {
    const { title, headerTooltip } = { ...options }
    return value ? (
      <div className={`${rootClass}__details-field`} data-test={`${dataTest}-${label.toLowerCase().replace(' ', '-')}`}>
        <div className={`${rootClass}__details-header-with-tooltip`}>
          <Typography text={t(label)} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.MEDIUM} />
          {headerTooltip && <Tooltip trigger={<Svg name={SvgNames.info} type={SvgType.ICON} />}>{t(headerTooltip)}</Tooltip>}
        </div>
        <Typography title={title ?? value} className={'ellip'} text={value} type={TextType.BODY_TEXT_LIGHT} />
      </div>
    ) : null
  }

  return (
    <div className={classNames(className, rootClass)} data-test={dataTest}>
      <Typography text={t('Program Details')} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
      <div className={`${rootClass}__details`}>
        {userAllowedToCreatePrograms ? (
          renderEditableFields()
        ) : (
          <>
            {renderReadOnlyField('Program name', name)}
            {renderReadOnlyField('Program description', description)}
            {renderReadOnlyField('Time zone', timeZoneName, { headerTooltip: timeZoneTooltip })}
          </>
        )}
        {renderReadOnlyField('Created', createdOnValue, { title: createdOnTooltip })}
        {renderReadOnlyField('Last ran', lastRanValue, { title: lastRanTooltip })}
      </div>
    </div>
  )
}

export default ListProgramBasicDetailsEditor
