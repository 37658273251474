import React, { FC, useContext } from 'react'

import classNames from 'classnames'

import { List } from '@interface/foldersLists/List'
import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'
import { SourceReplacement } from '@src/pages/programs/upgradeManager/components/SourceReplacement/SourceReplacement'
import { getUpgradedStepSources, useUpgradedSource } from '@src/pages/programs/upgradeManager/components/steps/Steps.utils'
import {
  UpgradedSource,
  UpgradedSourceUpgradeStepType,
  UpgradeManagerContext,
} from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'

const rootClass = 'source-upgrade-step'
const upgradeManagerClass = 'upgrade-manager'

export const SourceUpgradeStep: FC = () => {
  const {
    values: { currentStep, recommendedSegments, upgradedCurrentStep, upgradedStepSources },
    update,
  } = useContext(UpgradeManagerContext)
  const {
    values: {
      program: { sources },
    },
  } = useContext(ProgramManagerContext)
  const sourceUpgradeStep = currentStep as UpgradedSourceUpgradeStepType
  const source = sources.find((source) => source.id === sourceUpgradeStep?.srcId)
  const recommended = Object.entries(recommendedSegments).find(([key, _]) => key === sourceUpgradeStep?.srcId)
  const upgradedSource: UpgradedSource = useUpgradedSource(source, !!recommended ? recommended[1] : undefined)

  const onSubmitSource = (_: string, newSource: List, upgradeId: string) => {
    if (!!upgradedCurrentStep) {
      update({
        upgradedStepSources: getUpgradedStepSources(upgradedStepSources, upgradedCurrentStep, upgradeId, newSource),
        upgradedCurrentStep: { ...upgradedCurrentStep, srcId: newSource.id ?? '', upgraded: true } as UpgradedSourceUpgradeStepType,
      })
    }
  }

  return (
    <div className={classNames(rootClass, `${upgradeManagerClass}__step`)}>
      <SourceReplacement
        key={`${upgradedSource.upgradeId}-${upgradedSource.source.id}`}
        sourceToUpgrade={upgradedSource}
        onSubmitSource={onSubmitSource}
      />
    </div>
  )
}
