import React, { FC } from 'react'

import classNames from 'classnames'

import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import { LineHeight, TextType, TextWeight, TypographyProps } from '@components/Typography/Typography'
import withError from '@hoc/withError/withError'

import { renderArrow, renderInfo } from './DataAnalytics.utils'

import './DataAnalytics.css'

export interface RevenueImpactDataAnalytics {
  title: string
  value?: string | number
  emptyValueText?: string
  trend?: { isPositive: boolean }
  tooltipTypographyProps?: TypographyProps
}

export interface DataAnalyticsProps {
  data: RevenueImpactDataAnalytics[]
  loading: boolean
  dataTest?: string
  className?: string
}

const rootClass = 'data-analytics'

const DataAnalytics: FC<DataAnalyticsProps> = (props) => {
  const { data, loading, dataTest = rootClass, className } = props

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {data.map((item, index) => {
        const { title, value, emptyValueText, trend, tooltipTypographyProps } = item
        const text = value || emptyValueText || 'No data'
        const hideTooltip = !(value || emptyValueText)
        return (
          <div className={`${rootClass}__card`} data-test={`${dataTest}__card`} key={index}>
            <div className={`${rootClass}__card-value`}>
              {loading ? null : (
                <>
                  {trend && renderArrow(trend.isPositive)}
                  <TextWithTooltipOnEllip
                    typographyProps={{
                      text,
                      weight: value ? TextWeight.MEDIUM : TextWeight.ITALIC,
                      lineHeight: LineHeight.LARGER,
                    }}
                    tooltipProps={{
                      hide: hideTooltip,
                    }}
                  />
                </>
              )}
            </div>
            <div className={`${rootClass}__card-title`}>
              <TextWithTooltipOnEllip
                typographyProps={{
                  text: title,
                  type: TextType.BODY_TEXT_SMALL,
                  lineHeight: LineHeight.MEDIUM_SMALL,
                }}
              />
              {tooltipTypographyProps &&
                renderInfo(value ? tooltipTypographyProps : { text: `Not enough historical data to accurately display a ${title.toLowerCase()}` })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default withError(DataAnalytics)
