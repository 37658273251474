import { UNIFIED_CONTACTS_ID } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import { List } from '@interface/foldersLists/List'
import { ALL_ACTON_CONTACTS_SEGMENT_NAME } from '@src/pages/ContactSegments/utils/ContactSegments.constants'

export const RECOMMENDED_SEGMENTS_RESULTS_DATE_FORMAT = 'MM-dd-yyyy'
export const DEFAULT_AI_AUDIENCE_LIST = { id: UNIFIED_CONTACTS_ID, name: ALL_ACTON_CONTACTS_SEGMENT_NAME, size: 0 } as List

export enum FeaturesCategories {
  CATEGORICAL = 'Contact Profile Data',
  NUMERICAL = 'Contact Behavior Data',
}

export enum SegmentationInsightsTimeRange {
  ALL_TIME = 'All time',
  LAST_MONTH = 'Last 30 days',
  LAST_QUARTER = 'Last 90 days',
  LAST_SEMESTER = 'Last 180 days',
}

export const textByFeatureId: { [key: string]: string } = {
  count_beacon_hits: 'Contacts Total website page hits',
  count_closed_lost_opportunities: 'Count of closed-lost opportunities for a contact i.e., pursue many times and fail many times',
  count_closed_won_opportunities: 'Count of closed-won opportunities for a contact i.e., repurchase many times if more than 1',
  count_download: 'Total content downloads from the website by this content',
  count_form_submissions: 'Total form submits for the contact',
  count_meeting_attended: 'Total webinars contact attended',
  count_meeting_registered_not_attended: 'Total webinars contact registered for but did not attend',
  count_open_opportunities: 'Count of open opportunities for a contact i.e., current active sale',
  days_since_last_form_submission: "# of days since contact's last form submittal",
  median_idle: 'How often contacts interact with us, on average (median time between interaction)',
  percent_click_to_sent: 'What % of emails sent does the contact click on',
  percent_click_to_sent_prior: "How has the contact's click rate changed (compared to prior period)",
  percent_open_to_sent: 'What % of emails sent does the contact open',
  percent_open_to_sent_prior: "How has the contact's open rate changed (compared to prior period)",
}

export const AI_AUDIENCE_HEADER_LINK = `https://success.act-on.com/acton/attachment/9883/u-dc00da9c-d4d4-450d-8b25-e649cadcd728/0/-/-/-/-/In%20App%3A%20%20Audience%20Insights%20How%20does%20it%20work`
