import React from 'react'

import { TFunction } from 'i18next'

import ButtonIcon, { ButtonIconSize, ButtonIconType } from '@components/ButtonIcon/ButtonIcon'
import PillLabel, { PillLabelType } from '@components/PillLabel/PillLabel'
import { SvgNames } from '@components/Svg'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { envVarRegex } from '@src/pages/EmailComposer/EmailModals/components/ClickthroughLinksModal/constants/ClickthroughLinksModal.constants'
import { ClickthroughLinks } from '@utils/composer/context/EmailComposer.context'

export const getClickthrougLinksTableColumns = (t: TFunction, isLandingPage?: boolean): ColumnDefWithAdditionalProps<ClickthroughLinks>[] => [
  {
    header: 'Link text',
    accessorKey: 'label',
    textAlign: 'left',
    maxSize: 300,
    enableCustomCellValue: true,
    cell: ({ row }) => {
      const label = row.original.label
      if (!label) {
        return <Typography text={t('No display text')} weight={TextWeight.ITALIC} />
      }
      if (typeof label === 'string') {
        const isValidHTML = (str: string) => {
          const parser = new DOMParser()
          try {
            const doc = parser.parseFromString(str, 'text/html')
            return Array.from(doc.body.childNodes).some((node) => node.nodeType === Node.ELEMENT_NODE)
          } catch (e) {
            return false
          }
        }
        return isValidHTML(label) ? (
          <span dangerouslySetInnerHTML={{ __html: label }} />
        ) : (
          <Tooltip fullWidth={true} triggerClassName="ellip" trigger={<Typography text={label} />}>
            {label}
          </Tooltip>
        )
      }

      return <span dangerouslySetInnerHTML={{ __html: label.outerHTML }} style={{ pointerEvents: 'none' }} />
    },
  },
  {
    header: 'Type',
    accessorKey: 'type',
    textAlign: 'left',
    maxSize: 120,
    cell: ({ row }) => (row.original.type === 'Html' ? row.original.type.toUpperCase() : row.original.type),
  },
  {
    header: 'Destination URL',
    accessorKey: 'url',
    textAlign: 'left',
    cell: ({ row }) => {
      return (
        <Tooltip fullWidth={true} triggerClassName="ellip" trigger={<Typography text={row.original.url} />}>
          {row.original.url}
        </Tooltip>
      )
    },
  },
  {
    header: 'Result',
    accessorKey: 'valid',
    textAlign: 'left',
    cell: ({ row }) => {
      const isValid = row.original.valid
      return <PillLabel text={t(isValid ? 'Passed!' : 'Unvalidated')} type={isValid ? PillLabelType.SUCCESS : PillLabelType.ERROR} noMargin />
    },
    maxSize: 135,
  },
  {
    header: '',
    accessorKey: 'link',
    textAlign: 'left',
    disableSorting: true,
    maxSize: 60,
    padding: '0',
    cell: ({ row }) => {
      const hideButton =
        row.original.url?.match(envVarRegex) ||
        ((row.original.url?.indexOf('/acton/fs/optinout') !== -1 ||
          row.original.url?.indexOf('/acton/rif/') !== -1 ||
          row.original.url?.indexOf('/acton/vcal/') !== -1) &&
          isLandingPage)

      return (
        !hideButton && (
          <Tooltip
            trigger={
              <ButtonIcon
                type={ButtonIconType.TERTIARY}
                icon={SvgNames.externalLink}
                size={ButtonIconSize.LARGE}
                onClick={() => {
                  if (row.original.url) {
                    const url = row.original.url?.match(/^https?:\/\//i) ? row.original.url : `http://${row.original.url}`
                    window.open(url, '_blank')
                  }
                }}
              />
            }
          >
            {row.original.url}
          </Tooltip>
        )
      )
    },
  },
]

export const clickthrougLinksTableDefaultSortBy = [
  {
    id: 'label',
    desc: false,
  },
]
