import { gql } from '@apollo/client'

export default gql`
  query getProgramsUsingLists($listIds: [String]) {
    getProgramsUsingLists(listIds: $listIds) {
      body
      message
      objectId
      statusCode
    }
  }
`
