import React, { FC, ReactNode, useCallback, useState } from 'react'
import { useHistory } from 'react-router'

import classNames from 'classnames'
import { t } from 'i18next'

import {
  DuplicateListingPageItem,
  ListPageAPI,
  ListPageCommonState,
  PageHeaderProps,
  SetError,
  SetStatusToast,
  SidebarProps,
  Update,
} from '@complex/ListingPage/Context/ListingPageCommon.context'
import { useTagRequests } from '@complex/ListingPage/GraphQL/Tags.graphQL'
import ListingPageContainer from '@complex/ListingPage/ListingPageContainer'
import { getPreviewIdFromUrl, removeParamFromUrl } from '@complex/ListingPage/Utils/ListingPage.utils'
import TemplateCatalogModalContainer from '@components/AssetPickers/TemplateCatalogModal/TemplateCatalogModalContainer'
import Button, { ButtonIconPosition, ButtonType } from '@components/Button/index'
import Pill, { PillSize, PillType } from '@components/Pill/Pill'
import { Status } from '@components/StatusToast/StatusToast'
import Svg, { SvgNames, SvgType } from '@components/Svg/index'
import Tooltip from '@components/Tooltip/Tooltip'
import TriggerButton from '@components/TriggerButton/TriggerButton'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { legacyActonContext, rootContext, useTranslation } from '@const/globals'
import { useEmailTemplatesListingPageRequests } from '@src/pages/listingPages/EmailTemplates/GraphQL/useEmailTemplatesListingPageRequests.graphQL'
import {
  BLANK_MESSAGE_SETUP_ID,
  BLANK_MESSAGE_URL_BEE,
  EmailTemplateMessage,
  EmailTemplateMessageData,
  isEmailTemplateMessage,
} from '@src/pages/listingPages/EmailTemplates/utils/EmailTemplates.constants'
import { getActiveFilter, getCustomEmptyListingProps } from '@src/pages/listingPages/EmailTemplates/utils/EmailTemplatesListingPage.filters'
import {
  EmailTemplatesCustomTableActionsWithModals,
  renderCreateAsLandingPageModal,
} from '@src/pages/listingPages/EmailTemplates/utils/EmailTemplatesListingPage.modals'
import { EmailTemplatesCustomTableActions, tableProps } from '@src/pages/listingPages/EmailTemplates/utils/EmailTemplatesListingPage.tables'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import { DUPLICATED_AS_TEMPLATE_ID, SAVED_AS_TEMPLATE_ID } from '@utils/composer/EmailModal.constants'
import { allEmailTemplatesFilter } from '@utils/filter'
import { emailTemplatesSubTypes } from '@utils/listingPage/emailTemplates'
import { renderCustomFilters } from '@utils/listingPage/emailTemplates'
import { logNewRelicError } from '@utils/new-relic.utils'

import './EmailTemplatesListingPageContainer.css'

interface EmailTemplatesListingPageContainerProps {
  className?: string
  dataTest?: string
}

const rootClass = 'email-templates-listing-page-container'

const getTemplateButtonOptions = (showLabel: boolean) => [
  {
    text: 'Email composer',
    onClick: () => window.open(BLANK_MESSAGE_URL_BEE),
    ...(showLabel && {
      label: <Pill className={`${rootClass}__beta-pill`} type={PillType.SECONDARY} text={t('BETA')} size={PillSize.SMALL} noMargin />,
    }),
  },
  {
    text: 'Legacy email composer',
    onClick: () => window.open(`${rootContext}/classic/if/_compose/emailMessage.jsp?send=0&template=1&id=t-blank`),
  },
]

const EmailTemplatesListingPageContainer: FC<EmailTemplatesListingPageContainerProps> = (props: EmailTemplatesListingPageContainerProps) => {
  const { dataTest = rootClass } = props
  const { createTagRequest } = useTagRequests()

  const {
    userAllowedToDeleteContent,
    userAllowedToCreateContent,
    enableLandingPageSectionReact,
    gaLegacyEmail,
    isAgencyAccount,
    isClientAccount,
    newLPComposerCreateBlank,
  } = useAccountSettings()
  const { t } = useTranslation()
  const [windowMessages, setWindowMessages] = useState<EmailTemplateMessage[]>([])
  const [showTemplateCatalog, setShowTemplateCatalog] = useState(false)

  const history = useHistory<{ [SAVED_AS_TEMPLATE_ID]?: string; [DUPLICATED_AS_TEMPLATE_ID]?: string }>()

  const { deleteItemsRequest, getItemPreviewRequest, createLandingPageFromTemplateRequest, duplicateEmailTemplateRequest } =
    useEmailTemplatesListingPageRequests()

  const getItemPreview = async (listPageValues: ListPageCommonState, update: Update, setError: SetError) => {
    const { selectedRows } = listPageValues

    const previewIdFromUrl = getPreviewIdFromUrl(history)

    const { data, errors } = await getItemPreviewRequest(selectedRows[0]?.externalId ?? previewIdFromUrl)

    if (data) {
      const { html, status } = data.getEmailTemplatePreview

      if (status === 'success') {
        update({ loading: false, previewHtml: html })

        removeParamFromUrl(history, ['preview'])
      }
    } else if (errors) {
      setError('ListPage.EmailTemplates.Preview', errors)
      logNewRelicError(errors)
    }
  }

  const onPageLoad = useCallback(
    async (setStatusToast: SetStatusToast, update?: Update) => {
      const previewId = getPreviewIdFromUrl(history)
      if (previewId) {
        update?.({ showPreview: true })
      }

      if (history.location.state) {
        const savedTemplateId = history.location.state[SAVED_AS_TEMPLATE_ID]
        const duplicatedTemplateId = history.location.state[DUPLICATED_AS_TEMPLATE_ID]
        const state = savedTemplateId ? SAVED_AS_TEMPLATE_ID : DUPLICATED_AS_TEMPLATE_ID
        const message = savedTemplateId ? 'Template saved' : 'Success! Your email has been duplicated'

        if (savedTemplateId || duplicatedTemplateId) {
          setStatusToast?.(<Typography text={t(message)} />, Status.SUCCESS)

          const currentState = { ...history.location.state }

          delete currentState[state]

          history.replace({
            ...history.location,
            state: currentState,
          })
        }
      }
    },
    [history, t]
  )

  const onWindowMessage = useCallback(async (event: MessageEvent<EmailTemplateMessageData>) => {
    if (isEmailTemplateMessage(event.data)) {
      setWindowMessages((windowMessages) => {
        const eventExists = windowMessages.some((message) => message.emailId === event.data.emailId)
        if (eventExists) {
          return windowMessages
        }
        const source = event.source ? (event.source as Window) : null
        return [...windowMessages, { ...event.data, windowName: source?.name ?? '' }]
      })
    }
  }, [])

  const onWindowActive = useCallback(
    async (_event: Event, update: Update, setStatusToast: SetStatusToast) => {
      if (!windowMessages.length) {
        return
      }

      if (windowMessages[0].emailId === BLANK_MESSAGE_SETUP_ID) {
        setStatusToast(
          <Typography text={t('BlankMessage.Setup.Success')} tagProps={{ medium: { weight: TextWeight.MEDIUM, inline: true } }} />,
          Status.SUCCESS
        )
      } else {
        setStatusToast(
          <Typography
            text={windowMessages[0].isEditing ? 'ListPage.EmailTemplates.Updated' : 'ListPage.EmailTemplates.Created'}
            tagProps={{ bold: { weight: TextWeight.MEDIUM } }}
            values={{ emailTitle: windowMessages[0].emailTitle }}
            inline
          />,
          Status.SUCCESS
        )
        update({ fetchItems: true, fetchFilterCounts: true })
      }

      setWindowMessages([])
    },
    [windowMessages]
  )

  const renderCustomModal = (
    customTableAction: EmailTemplatesCustomTableActionsWithModals,
    listPageValues: ListPageCommonState,
    listPageAPI: ListPageAPI
  ) => {
    const { selectedRows } = listPageValues
    const selectedRow = selectedRows[0]

    const customModal: { [key in EmailTemplatesCustomTableActionsWithModals]: () => ReactNode } = {
      [EmailTemplatesCustomTableActions.CREATE_LANDING_PAGE_FROM_TEMPLATE]: () =>
        renderCreateAsLandingPageModal(
          { selectedRow, listPageValues, listPageAPI, createLandingPageFromTemplateRequest, createTagRequest },
          enableLandingPageSectionReact
        ),
      [EmailTemplatesCustomTableActions.CREATE_MESSAGE]: () => <div>foo</div>,
    }

    if (!(customTableAction in customModal)) {
      return
    }

    return customModal[customTableAction]()
  }

  const sidebarProps: SidebarProps = {
    sidebarHeader: 'Email Templates Manager',
    hasSalesUsersAction: true,
    hasRecent: false,
    hasCreatedByMe: true,
    allItemFilter: allEmailTemplatesFilter,
    customFilterSelected: false,
    renderCustomFilters: (params) => renderCustomFilters(params, rootClass),
  }

  const duplicateTemplate = async (params: DuplicateListingPageItem, listPageAPI: ListPageAPI) => {
    const { listingPageItem, newName, tags, folderId } = params
    const { update, setStatusToast, setError } = listPageAPI

    const { data, errors } = await duplicateEmailTemplateRequest(listingPageItem.externalId ?? '-1', newName, tags, folderId ?? -1)

    if (data?.duplicateEmailTemplate.newId) {
      setStatusToast(t('ListPage.EmailTemplates.Duplicate.SuccessToast'), Status.SUCCESS)
      update({ loading: true, fetchItems: true })
    } else {
      setError(t('ListPage.EmailTemplates.Duplicate.FailToast'), errors)
    }
  }

  const renderCreateTemplateButton = () => {
    const createTemplateBtn = (
      <Button
        buttonType={ButtonType.PRIMARY}
        iconPosition={ButtonIconPosition.LEFT}
        dataTest={`${rootClass}-create`}
        disabled={!userAllowedToCreateContent}
        onClick={() => window.open(`${legacyActonContext}/_compose/start.jsp?template=1`)}
        className={classNames({ [`${rootClass}__create-template-disabled`]: !userAllowedToCreateContent })}
      >
        <Svg name={SvgNames.plus} type={SvgType.ICON} />
        {t('Create template')}
      </Button>
    )

    const blankSetupButton = (
      <Button
        buttonType={ButtonType.FLOAT}
        iconPosition={ButtonIconPosition.LEFT}
        dataTest={`${rootClass}-blank-setup`}
        onClick={() => window.open(BLANK_MESSAGE_URL_BEE)}
        className={`${rootClass}__blank-setup-button`}
      >
        <Svg name={SvgNames.gear} type={SvgType.ICON} />
        {t('Blank.Email.Setup.Title')}
      </Button>
    )

    const templateCatalogButton = (
      <Button
        buttonType={ButtonType.OUTLINE}
        iconPosition={ButtonIconPosition.LEFT}
        dataTest={`${rootClass}-template-catalog`}
        onClick={() => setShowTemplateCatalog(true)}
        className={`${rootClass}__template-catalog-button`}
      >
        <Svg name={SvgNames.shareToCatalog} type={SvgType.ICON} />
        {t('Template.Catalog')}
      </Button>
    )

    return (
      <div className={`${rootClass}__template-buttons-wrapper`}>
        {!gaLegacyEmail ? (
          blankSetupButton
        ) : (
          <TriggerButton
            useLabel
            dataTest={`${rootClass}-blank-setup`}
            className={`${rootClass}__blank-setup-button`}
            buttonType={ButtonType.FLOAT}
            label={t('Blank.Email.Setup.Title')}
            options={getTemplateButtonOptions(!gaLegacyEmail)}
            showCaretIcon={false}
            svgIcon={SvgType.ICON}
            svgName={SvgNames.gear}
            iconPosition={ButtonIconPosition.LEFT}
          />
        )}
        {templateCatalogButton}
        {createTemplateBtn}
      </div>
    )
  }

  const pageHeaderProps: PageHeaderProps = {
    pageTitle: 'Email Templates',
    renderPageHeaderContent: () => (
      <>
        {userAllowedToCreateContent ? (
          renderCreateTemplateButton()
        ) : (
          <Tooltip trigger={renderCreateTemplateButton()}>
            {userAllowedToCreateContent ? '' : 'Ask your administrator for permission to do this'}
          </Tooltip>
        )}
      </>
    ),
  }

  return (
    <>
      {showTemplateCatalog && <TemplateCatalogModalContainer setShowTemplateCatalog={setShowTemplateCatalog} />}
      <ListingPageContainer
        listingPageProps={{
          canPreview: true,
          customPreviewItemCall: getItemPreview,
          canDeleteItems: userAllowedToDeleteContent,
          canDuplicate: true,
          customDuplicateItem: duplicateTemplate,
          canEdit: userAllowedToCreateContent,
          canCreate: userAllowedToCreateContent,
          customDeleteItemsCall: deleteItemsRequest,
          isDeleteCallWithBulkResponse: true,
          getCustomActiveFilter: getActiveFilter,
          getCustomEmptyListingProps: (filter, setFilter, t) => getCustomEmptyListingProps(filter, setFilter, t, setShowTemplateCatalog),
          renderCustomModal,
          sidebarProps,
          sortBy: [{ id: 'lastUpdated', desc: true }],
          pageHeaderProps,
          tableProps: tableProps(t, userAllowedToCreateContent, true, isAgencyAccount, isClientAccount, newLPComposerCreateBlank),
          subTypes: emailTemplatesSubTypes,
          hasSecondaryFolders: true,
          hasSecondaryTags: true,
          secondaryItemType: ItemType.LANDING_PAGE,
          onWindowMessage,
          onWindowActive,
          onPageLoad,
        }}
        dataTest={dataTest}
        itemType={ItemType.EMAIL_TEMPLATE}
      />
    </>
  )
}

export default EmailTemplatesListingPageContainer
