import React, { FC, useContext, useMemo } from 'react'

import AssetPickerSidebar from '@complex/AssetPickerModal/Components/Sidebar/AssetPickerSidebar'
import { CollapsibleMenuItemWithHeaderData } from '@components/CollapsibleMenu/CollapsibleMenu'
import FilterContainer from '@components/FilterContainer/FilterContainer'
import Menu from '@components/Menu/Menu'
import { SvgNames } from '@components/Svg'
import { allSignaturesFilter, mySignaturesFilter, accountSignaturesFilter } from '@utils/filter'

import { SignaturesModalContext } from './SignaturesModal.context'

const rootClass = 'signatures-modal'

const SignaturesModalSidebarContainer: FC = () => {
  const {
    update,
    values: { activeFilter, filterCounts },
  } = useContext(SignaturesModalContext)

  const mediaFilters = useMemo(() => [allSignaturesFilter, mySignaturesFilter, accountSignaturesFilter], [filterCounts])

  const onFilterClick = (filterName: string) => {
    update({ activeFilter: filterName, currentPage: 0 })
  }

  const getDefaultFilters = (): CollapsibleMenuItemWithHeaderData => {
    return {
      header: '',
      content: (
        <FilterContainer
          filters={mediaFilters}
          filterCounts={filterCounts}
          activeFilter={mediaFilters.find((filter) => filter.name === activeFilter)}
          filterAction={(filter) => onFilterClick(filter.name)}
          hasRecent={false}
          hasRecommended={false}
          hasCreatedByMe={false}
          hasFavorites={true}
          hideCount={false}
        />
      ),
      icon: SvgNames.segmentFilters,
      dataTest: 'menu-items-filters',
    }
  }

  const items = [getDefaultFilters()]

  return (
    <AssetPickerSidebar>
      <Menu items={items} className={`${rootClass}__sidebar__menu`} />
    </AssetPickerSidebar>
  )
}

export default SignaturesModalSidebarContainer
