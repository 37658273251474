import React, { FC, useCallback, useRef, useEffect } from 'react'

import Button, { ButtonType } from '@components/Button'
import InfoTooltip from '@components/InfoTooltip/InfoTooltip'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Toggle from '@components/Toggle'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { EmailComposerPreviewProps } from '@src/pages/EmailComposer/components/EmailComposerPreview/EmailComposerPreview'
import { confirmPlainTextEdit } from '@src/pages/EmailComposer/components/EmailComposerPreview/EmailComposerPreview.render.utils'
import { hasDisplayConditions } from '@src/pages/EmailComposer/utils/DisplayConditions.utils'
import { detectEmailType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { RefreshPreviewModal } from '@src/pages/LandingPageComposer/components/LandingPageComposerCustomCode/components/RefreshPreviewModal'
import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'
import { EmailPreviewPlatform } from '@utils/composer/context/EmailComposer.context'
import { useTranslation } from '@utils/const/globals'

import EmailComposerPreviewDynamicContentBanner from './components/EmailComposerPreviewDynamicContentBanner/EmailComposerPreviewDynamicContentBanner'
import EmailComposerPreviewIframe from './components/EmailComposerPreviewIframe/EmailComposerPreviewIframe'
import EmailComposerPreviewMobile from './components/EmailComposerPreviewMobile/EmailComposerPreviewMobile'
import EmailComposerPreviewPlainText from './components/EmailComposerPreviewPlainText/EmailComposerPreviewPlainText'
import EmailComposerPreviewSidebar from './components/EmailComposerPreviewSidebar/EmailComposerPreviewSidebar'
import { handleDynamicContent } from '../../EmailComposerPreview.utils'
import EmailComposerPreviewInbox from '../EmailComposerPreviewInbox/EmailComposerPreviewInbox'

import './EmailComposerPreviewContent.css'

type EmailComposerPreviewContentType = Pick<EmailComposerPreviewProps, 'previewFrameRef' | 'isCustomCodePreview'>

const rootClass = 'email-composer-preview-content'

const EmailComposerPreviewContent: FC<EmailComposerPreviewContentType> = ({ previewFrameRef, isCustomCodePreview }) => {
  const {
    values: {
      preview: { html, isDarkMode, isPlainText, platform, dynamicContentList },
      disabledFeatures,
      isCoeditingPrimaryUser,
      haveUnsavedChanges,
      message,
      message: { templateHtml, templateJson, isPlainTextOnly },
      landingPage: { isLandingPage },
      messageConfiguration: {
        preview: { disableEditPlainText },
        messageType: messageConfigType,
      },
      detectedURLChanges: { plainTextMode, uploadHtmlMode },
      showRefreshPreviewModal,
    },
    api: { updatePreview, updateModal, onEditPlainText, update },
  } = useComposerContext()

  const containerRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()

  const { isEmailBlankMessage, isOptInEmail } = detectEmailType(messageConfigType)

  const isMobile = platform === EmailPreviewPlatform.MOBILE

  useEffect(() => {
    const changeBackgroundColor = () => {
      if (containerRef.current === null) {
        return
      }
      const iframe = previewFrameRef?.current?.contentDocument?.querySelector<HTMLTableElement>('.nl-container')
      if (isPlainText || iframe !== null) {
        containerRef.current.style.backgroundColor = ''
      } else {
        containerRef.current.style.backgroundColor = templateJson.page.body.container.style['background-color']
      }
    }
    // Can change immediately when iframe initializes
    changeBackgroundColor()
    // Small delay for when dark mode changes
    setTimeout(changeBackgroundColor, 1)
    // Longer delay for when plain text is toggled off
    setTimeout(changeBackgroundColor, 500)
  }, [templateHtml, isDarkMode, isPlainText, previewFrameRef, templateJson.page.body.container.style])

  const renderContent = () => {
    if ((isPlainText || plainTextMode) && !isMobile) {
      return <EmailComposerPreviewPlainText />
    } else {
      switch (platform) {
        case EmailPreviewPlatform.DESKTOP:
          return (
            <EmailComposerPreviewIframe
              isLandingPage={isLandingPage}
              previewFrameRef={previewFrameRef}
              html={handleDynamicContent(dynamicContentList, html)}
              isDarkMode={isDarkMode}
            />
          )
        case EmailPreviewPlatform.MOBILE:
          return <EmailComposerPreviewMobile previewFrameRef={previewFrameRef} />
        case EmailPreviewPlatform.INBOX:
          return <EmailComposerPreviewInbox />
      }
    }
  }

  const renderEditPlainTextButton = () => {
    const tooltipText = t('EmailComposer.Preview.Plain.Text.Edit.Disabled.Tooltip')
    const text = t('EmailComposer.Preview.Plain.Text.Edit.Button')
    const onConfirm = () => updateModal('editPlainText', true)
    const hasDynamicContent = hasDisplayConditions(message)
    const showWarning = !disabledFeatures.editPlainText && !!message.customTextPart
    const button = (
      <Tooltip
        hide={!disableEditPlainText}
        position={'top'}
        trigger={
          <Button
            onClick={hasDynamicContent && !showWarning ? () => confirmPlainTextEdit(updateModal, t, onConfirm) : onConfirm}
            dataTest={`${rootClass}-edit-plain-text-button`}
            buttonType={ButtonType.OUTLINE}
            disabled={!isCoeditingPrimaryUser || disableEditPlainText}
          >
            {text}
          </Button>
        }
      >
        <Typography text={t('EmailComposer.Resend.Disabled')} type={TextType.BODY_TEXT_WHITE} />
      </Tooltip>
    )
    return (
      !disabledFeatures.editPlainText &&
      !isPlainTextOnly && (
        <div className={`${rootClass}__header-action flex-align-center`}>
          {isCoeditingPrimaryUser ? (
            button
          ) : (
            <Tooltip trigger={button} minimalPadding={false} position={'top'}>
              {tooltipText}
            </Tooltip>
          )}
        </div>
      )
    )
  }

  const handlePlainTextToggle = useCallback(
    (isOn: boolean) => {
      const onConfirm = () => updatePreview({ isPlainText: isOn })
      if (isOn && haveUnsavedChanges) {
        onEditPlainText(onConfirm)
      } else {
        onConfirm()
      }
    },
    [updatePreview, onEditPlainText, haveUnsavedChanges]
  )

  const handleRefresh = () => {
    update({ showRefreshPreviewModal: false, preview: { html: templateHtml } })
  }

  return (
    <div className={rootClass}>
      <div className={`${rootClass}__wrapper`}>
        {isPlainText && (
          <div className={`${rootClass}__header`} data-test={`${rootClass}-header`}>
            <div className={`${rootClass}__header__title-row`}>
              <div className={`${rootClass}__header-title flex-align-center`}>
                <Typography text={t(`EmailComposer.Preview.Plain.Text.Title`)} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
                <InfoTooltip align="start" alignArrowToStart>
                  {t(`EmailComposer.Preview.Plain.Text.Title.Tooltip`)}
                </InfoTooltip>
              </div>
              {!(isEmailBlankMessage || isOptInEmail) && renderEditPlainTextButton()}
            </div>
          </div>
        )}
        <EmailComposerPreviewDynamicContentBanner />
        <div
          className={`${rootClass}__container`}
          ref={containerRef}
          {...(isCustomCodePreview ? { style: { position: 'relative', overflow: 'hidden' } } : {})}
        >
          {isCustomCodePreview && showRefreshPreviewModal && <RefreshPreviewModal onRefresh={handleRefresh} />}
          {renderContent()}
        </div>
        {!isLandingPage && (
          <div className={`${rootClass}__footer`}>
            <div className={`${rootClass}__footer-toggles`}>
              <div className={`${rootClass}__footer-toggles-wrapper`}>
                <Typography
                  type={isDarkMode ? undefined : TextType.BODY_TEXT_GRAY}
                  text={t('Dark mode')}
                  weight={TextWeight.MEDIUM}
                  lineHeight={LineHeight.MEDIUM}
                />
                <Toggle large isOn={isDarkMode} onToggle={(isOn) => updatePreview({ isDarkMode: isOn })} dataTest={`${rootClass}-dark-mode-toggle`} />
              </div>
              {!isPlainTextOnly && <div className={`${rootClass}__footer-toggles-divider`} />}
              {!plainTextMode && (
                <div className={`${rootClass}__footer-toggles-wrapper`}>
                  <Typography
                    type={isPlainText ? undefined : TextType.BODY_TEXT_GRAY}
                    text={t('Plain text')}
                    weight={TextWeight.MEDIUM}
                    lineHeight={LineHeight.MEDIUM}
                  />
                  <Toggle large isOn={isPlainText} onToggle={handlePlainTextToggle} dataTest={`${rootClass}-plain-text-toggle`} />
                </div>
              )}
            </div>
            {!plainTextMode && !uploadHtmlMode && (
              <Button
                noPadding
                className={`${rootClass}__footer-code-button`}
                buttonType={ButtonType.TERTIARY}
                onClick={() => updatePreview({ isCodeModalOpen: true })}
                dataTest={`${rootClass}-getHtmlCode-button`}
              >
                <Svg name={SvgNames.codeIcon} type={SvgType.LARGER_ICON} />
                <Typography
                  text={t('View HTML code')}
                  type={TextType.LINK}
                  weight={TextWeight.MEDIUM}
                  dataTest={`${rootClass}-getHtmlCode-typography`}
                />
              </Button>
            )}
          </div>
        )}
      </div>
      {!isLandingPage && <EmailComposerPreviewSidebar />}
    </div>
  )
}

export default EmailComposerPreviewContent
