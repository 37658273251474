import React, { useCallback, useEffect, useRef, useState } from 'react'

import Button, { ButtonType } from '@components/Button'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@utils/const/globals'

import './ForethoughtWidget.css'

const rootClass = 'forethought-widget'

const ForethoughtWidget = () => {
  const [show, setShow] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const clicked = useRef<boolean>(false)

  const { t } = useTranslation()

  const handleOpenClose = useCallback(() => {
    if (window.Forethought) {
      if (open) {
        window.Forethought('widget', 'hide')
        clicked.current = true
        setOpen(false)
      } else {
        window.Forethought('widget', 'open')
        setOpen(true)
        window.Forethought('widget', 'show')
      }
    }
  }, [open])

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      const targetElement = document.getElementById('forethought-chat')
      const isForethought = mutations.some((mutation) => mutation.target === targetElement)

      if (!targetElement || !isForethought) return

      if (!show) {
        if (window.Forethought) {
          window.Forethought('widget', 'hide')
          window.Forethought('widget', 'close')
          setShow(true)
        }
      } else {
        if (open && clicked.current) {
          window.Forethought('widget', 'hide')
          window.Forethought('widget', 'close')
          clicked.current = false
          setOpen(false)
        } else if (open) {
          clicked.current = true
        }
      }
    })

    const config = { childList: true, subtree: true, attributes: true, attributeFilter: ['style'] }
    observer.observe(document, config)

    return () => {
      observer.disconnect()
    }
  }, [show, open])

  return show ? (
    <Tooltip
      className={`${rootClass}__tooltip`}
      trigger={
        <Button className={rootClass} buttonType={ButtonType.ICON} onClick={handleOpenClose}>
          <Svg name={open ? SvgNames.questionActive : SvgNames.question} type={SvgType.LARGE_ICON} />
        </Button>
      }
      position="left"
      minimalPadding={false}
      hide={open}
    >
      {t('Help and support')}
    </Tooltip>
  ) : null
}
export default ForethoughtWidget
