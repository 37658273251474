import React, { FC, useCallback, useContext, useMemo } from 'react'

import Button, { ButtonType } from '@components/Button'
import { TextType } from '@components/Typography/Typography'
import { CommonComposerTab } from '@utils/composer/commonComposer/CommonComposer.context'
import { EmailComposerContext, ValidationSectionType } from '@utils/composer/context/EmailComposer.context'
import { useTranslation } from '@utils/const/globals'

import ValidationSection from '../ValidationSection/ValidationSection'
import ValidationsList, { Validation } from '../ValidationsList/ValidationsList'

import './ContentValidations.css'

interface ContentValidationsProps {
  getValidations: (handleViewResults: () => void, isInReview?: boolean) => Validation[]
  isInReview?: boolean
}

const rootClass = 'content-validations'

const ContentValidations: FC<ContentValidationsProps> = ({ getValidations, isInReview }) => {
  const { t } = useTranslation()

  const {
    values: {
      validations: { isValidationsLoading, isContentMissing },
      messageConfiguration: {
        reviewAndSend: { disableEditings },
      },
    },
    api: { onTabChange, update, onStatusChange },
  } = useContext(EmailComposerContext)

  const handleViewResults = useCallback(() => update({ isClickthroughLinks: true }), [])

  const handleBack = useCallback(() => onTabChange(CommonComposerTab.DESIGN), [onTabChange])
  const validations = useMemo(
    () => (!isContentMissing ? getValidations(handleViewResults) : undefined),
    [getValidations, isContentMissing, isInReview, handleViewResults]
  )

  return (
    <>
      {isInReview ? (
        <ValidationSection
          type={ValidationSectionType.CONTENT}
          title="EmailComposer.Preview.Validations.Content.Title"
          subTitle={
            isContentMissing ? { text: t('EmailComposer.Preview.Validations.Content.Review.Desc_rest'), type: TextType.BODY_TEXT_LIGHT } : undefined
          }
          buttonLabel="EmailComposer.Preview.Validations.Edit.Button"
          validations={validations}
          disableEditBtn={disableEditings}
          tooltipContentKey={disableEditings ? t('EmailComposer.Resend.Disabled') : ''}
          isLoading={isValidationsLoading}
          onClick={handleBack}
          onStatusChange={onStatusChange}
        />
      ) : (
        <div className={rootClass}>
          {validations ? (
            <ValidationsList validations={validations} />
          ) : (
            <Button fullWidth buttonType={ButtonType.OUTLINE} onClick={() => update({ isPreview: false })}>
              {t(`Design email`)}
            </Button>
          )}
        </div>
      )}
    </>
  )
}

export default ContentValidations
