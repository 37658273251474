import React, { FC, useEffect, useState } from 'react'

import Caution from '@components/Caution/Caution'
import Container from '@components/Container'
import TableList, { TableListRow } from '@components/TableList/TableList'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { SMSContextAPI } from '@src/pages/sms/context/SMSContext'
import { getCreditsByCreditMultiplierCells, getCreditsWhenInternationalDisabled } from '@src/pages/sms/utils/sms.utils'

import './CreditsSummary.css'

const rootClass = 'credits-summary'

interface State {
  availableCredits: number
  requiredCredits: number
  remainingBalance: number
  parts: number
  recipients: number
  totalRow?: TableListRow
  breakdownText: string
}

const defaultState: State = {
  availableCredits: 0,
  requiredCredits: 0,
  remainingBalance: 0,
  parts: 0,
  recipients: 0,
  breakdownText: '',
}

interface Props {
  context: SMSContextAPI
}

const CreditsSummary: FC<Props> = ({ context }: Props) => {
  const {
    values: {
      smsMessage: { recipientsPerCreditMultiplier },
      segmentedMessage,
      costToSend,
      totalRecipients,
      availableCredits,
      internationalSendingEnabled,
    },
  } = context
  const [state, setState] = useState<State>(defaultState)
  const { t } = useTranslation()

  useEffect(() => {
    const parts = segmentedMessage.segments.length
    const requiredCredits = costToSend
    const remainingBalance = availableCredits - requiredCredits
    const breakdownText = `${parts} ${t(parts === 1 ? 'Message' : 'Messages')} x ${totalRecipients.toLocaleString()} ${t('Recipients')}`

    const totalTypography =
      remainingBalance < 0
        ? { type: TextType.ERROR_LARGE }
        : {
            type: TextType.BODY_TEXT_LARGE,
            weight: TextWeight.BOLD,
          }
    const totalRow = {
      left: <Typography text={t('Remaining Balance')} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.MEDIUM} />,
      right: <Typography text={remainingBalance.toLocaleString()} {...totalTypography} />,
    }

    setState({
      ...state,
      availableCredits,
      requiredCredits,
      remainingBalance,
      breakdownText,
      parts,
      recipients: totalRecipients,
      totalRow,
    })
  }, [segmentedMessage, costToSend, totalRecipients, availableCredits])

  let rows: TableListRow[] = []
  if (internationalSendingEnabled) {
    if (recipientsPerCreditMultiplier) {
      rows = getCreditsByCreditMultiplierCells({
        recipientsPerCreditMultiplier,
        segmentedMessage,
        t,
        availableCredits: state.availableCredits.toLocaleString(),
        needsEmphasis: true,
        useMinus: true,
      })
    }
  } else {
    rows = getCreditsWhenInternationalDisabled({
      availableCredits: state.availableCredits.toLocaleString(),
      breakdownText: state.breakdownText,
      requiredCredits: state.requiredCredits.toLocaleString(),
      useMinus: true,
      needsEmphasis: true,
      t,
    })
  }

  return (
    <div className={`${rootClass}`} data-text={rootClass}>
      <Container title={'Credits Summary'} description={'A breakdown of credits required to send a message to the selected recipients'}>
        <div className={`${rootClass}__table-credits-breakdown`}>
          {recipientsPerCreditMultiplier && <TableList rows={rows} totalRow={state.totalRow} hasBorder />}
        </div>
        {state.remainingBalance < 0 && state.requiredCredits > 0 && <Caution message={t('You do not have enough credits to send this message.')} />}
      </Container>
    </div>
  )
}

export default CreditsSummary
