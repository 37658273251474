import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import ActivityGaugeChart from '@components/ActivityGaugeChart/ActivityGaugeChart'
import CarouselDataCard from '@components/CarouselDataCard/CarouselDataCard'
import DividedContentDataCard from '@components/DividedContentDataCard/DividedContentDataCard'
import SingleContentDataCard from '@components/SingleContentDataCard/SingleContentDataCard'
import SingleLineChart, { SingleLineChartColor } from '@components/SingleLineChart/SingleLineChart'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { ChartFieldData } from '@const/Chart.constants'
import { useTranslation } from '@const/globals'
import DataCardsHeaderWithDateSelect from '@src/pages/listingPages/FormsJoinView/components/DataCardsHeaderWithDateSelect/DataCardsHeaderWithDateSelect'
import { getEmptyPerformanceCard } from '@src/pages/listingPages/FormsJoinView/components/FormJoinViewDataCards/utils/FormJoinViewDataCards.constants'
import {
  FormJoinViewDataCardsOption,
  loadDataCards,
} from '@src/pages/listingPages/FormsJoinView/components/FormJoinViewDataCards/utils/FormJoinViewDataCards.utils'
import {
  ActiveFormsCard,
  DATA_PERIOD,
  defaultActiveFormsCardValues,
  defaultSubmissionsOverViewsCardValues,
  SubmissionsOverViewsCard,
  TopPerformingFormsCard,
} from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.constants'
import { useFormsJoinViewListingPageRequests } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.graphQL'
import { getDateRangeDropDownActions, parseDataPeriod } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.utils'
import { ItemType } from '@utils/categorization'
import { formatNumber } from '@utils/numbers'
import { getItem } from '@utils/sessionStorage'

import './FormJoinViewDataCards.css'

interface FormJoinViewDataCardsProps {
  className?: string
  dataTest?: string
}

interface FormJoinViewDataCardsState {
  days: DATA_PERIOD
  submissionsOverViewsCard: SubmissionsOverViewsCard
  activeFormsCard: ActiveFormsCard
  topPerformingFormsCards: TopPerformingFormsCard
  dateRangeDropDownOpen: boolean
}

const rootClass = 'form-join-view-data-cards'

const FormJoinViewDataCards: FC<FormJoinViewDataCardsProps> = (props: FormJoinViewDataCardsProps) => {
  const { dataTest = rootClass, className = '' } = props

  const { t } = useTranslation()
  const checkIfHasSavedOption = () => {
    const savedOption = getItem(FormJoinViewDataCardsOption.SELECTED_OPTION)
    if (savedOption !== null) {
      return parseDataPeriod(parseInt(savedOption))
    } else {
      return DATA_PERIOD.Week
    }
  }

  const [state, setState] = useState<FormJoinViewDataCardsState>({
    submissionsOverViewsCard: defaultSubmissionsOverViewsCardValues,
    activeFormsCard: defaultActiveFormsCardValues,
    days: checkIfHasSavedOption(),
    dateRangeDropDownOpen: false,
    topPerformingFormsCards: { cards: [], loading: true },
  })

  const { submissionsOverViewsCard, activeFormsCard, days, dateRangeDropDownOpen, topPerformingFormsCards } = state

  const { getAllFormsData } = useFormsJoinViewListingPageRequests()

  const update = (values: Partial<FormJoinViewDataCardsState>) => {
    setState((state) => ({ ...state, ...values }))
  }

  useEffect(() => {
    update({
      submissionsOverViewsCard: { ...submissionsOverViewsCard, loading: true },
      activeFormsCard: { ...activeFormsCard, loading: true },
      topPerformingFormsCards: { cards: [], loading: true },
    })
  }, [days])

  useEffect(() => {
    if (topPerformingFormsCards.loading || activeFormsCard.loading || submissionsOverViewsCard.loading) {
      loadDataCards(update, days, getAllFormsData, t)
    }
  }, [topPerformingFormsCards.loading, activeFormsCard.loading, submissionsOverViewsCard.loading])

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <DataCardsHeaderWithDateSelect
        headerText={t(`ListPage.FormsJoinView.${ItemType.FORM_SUBMISSION}.DataCards.Header`)}
        dateRangeDropDownOpen={dateRangeDropDownOpen}
        days={days}
        onDropdownOpen={(open) => update({ dateRangeDropDownOpen: open })}
        onDropdownClose={() => update({ dateRangeDropDownOpen: false })}
        dropdownItems={getDateRangeDropDownActions(FormJoinViewDataCardsOption.SELECTED_OPTION, update)}
      />
      <div className={`${rootClass}__cards`}>
        <DividedContentDataCard
          className={`${rootClass}__total-submissions`}
          headerText={t(`ListPage.FormsJoinView.${ItemType.FORM_SUBMISSION}.DataCards.TotalSubmissions.Header`)}
          leftContent={
            submissionsOverViewsCard.loading ? (
              <Typography
                text={t(`ListPage.FormsJoinView.${ItemType.FORM_SUBMISSION}.DataCards.TotalSubmissions.LoadingMessage`)}
                type={TextType.BODY_TEXT_LARGE}
                weight={TextWeight.BOLD}
                lineHeight={LineHeight.MEDIUM_LARGE}
              />
            ) : (
              <Typography
                text={t(`ListPage.FormsJoinView.${ItemType.FORM_SUBMISSION}.DataCards.TotalSubmissions.Left`, {
                  submissions: formatNumber(submissionsOverViewsCard.submissions),
                  views: formatNumber(submissionsOverViewsCard.views),
                })}
                type={TextType.BODY_TEXT_SMALL_LIGHT}
                tagProps={{
                  text: { type: TextType.DATA_CARD_TEXT, weight: TextWeight.BOLD, inline: true },
                  textLight: { type: TextType.DATA_CARD_TEXT_LIGHT, inline: true },
                }}
              />
            )
          }
          rightContent={
            <ActivityGaugeChart
              actualValue={submissionsOverViewsCard.submissions}
              total={submissionsOverViewsCard.views}
              backgroundColor={'#69B46620'}
              showCenterPercentage={false}
              fields={[
                {
                  name: 'Total submissions over views',
                  data: [
                    {
                      color: '#69B466',
                      radius: '140%',
                      innerRadius: '90%',
                      y: submissionsOverViewsCard.loading ? 0 : (submissionsOverViewsCard.submissions * 100) / submissionsOverViewsCard.views,
                      dataLabels: { shadow: true },
                    },
                  ] as ChartFieldData[],
                },
              ]}
            />
          }
        />
        <SingleContentDataCard headerText={'Active Forms'} className={`${rootClass}__active-forms`}>
          {activeFormsCard.loading ? (
            <Typography
              text={t(`ListPage.FormsJoinView.${ItemType.FORM_SUBMISSION}.DataCards.ActiveForms.LoadingMessage`)}
              type={TextType.BODY_TEXT_LARGE}
              weight={TextWeight.BOLD}
              lineHeight={LineHeight.MEDIUM_LARGE}
            />
          ) : (
            <SingleLineChart
              actualValue={activeFormsCard.active}
              total={activeFormsCard.totalSubmissions}
              actualColor={SingleLineChartColor.TEAL}
              smallerTotal
              percentageFixedColor={SingleLineChartColor.TEXT_TEAL}
            />
          )}
        </SingleContentDataCard>
        <CarouselDataCard
          ribbonMedalsData={topPerformingFormsCards.cards}
          loading={topPerformingFormsCards.loading}
          emptyCard={topPerformingFormsCards.cards.length === 0 ? getEmptyPerformanceCard(t) : undefined}
        />
      </div>
    </div>
  )
}

export default FormJoinViewDataCards
