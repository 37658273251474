import { useSelector, useDispatch } from 'react-redux'

import { ChartSettingState, defaultChartSettingState } from '@const/Chart.constants'
import getUserSettings from '@graphql/microservices/user-settings/getUserSettings'
import upsertUserSettings from '@graphql/microservices/user-settings/upsertUserSettings'
import {
  GetUserSettingsQuery,
  GetUserSettingsQueryVariables,
  UpsertUserSettingsMutation,
  UpsertUserSettingsMutationVariables,
  UserSetting as UserSettingEntry,
} from '@graphql/types/microservice/user-settings-types'
import { setUserSettings as dispatchUserSettings } from '@utils/account/actions'
import { AccountState } from '@utils/account/reducer'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { safeParsedJsonObject } from '@utils/json'
import { logNewRelicError } from '@utils/new-relic.utils'
import { getThemeFromValue } from '@utils/theme'

// Note: To add settings a schema update must be done in user-settings-service
export type UserSetting = {
  lastConfirm: string
  programManagerCharts: ChartSettingState
  i18nextLng: string
  'ui-theme': string
  showFeedbackBanner: string
  'datamanagement/showSaveBanner': boolean
  'datamanagement/showFirstTimePage': boolean
  currentPage: string
  hideAnalyticsUpsell: boolean
}

export const defaultUserSettings: UserSetting = {
  lastConfirm: '',
  programManagerCharts: defaultChartSettingState,
  i18nextLng: 'en',
  'ui-theme': getThemeFromValue(''),
  showFeedbackBanner: '',
  'datamanagement/showSaveBanner': false,
  'datamanagement/showFirstTimePage': true,
  currentPage: 'classic/dashboard',
  hideAnalyticsUpsell: false,
}

export type UserSettings = { [key in keyof UserSetting]: UserSetting[key] }

export const parseUserSettings = (fetchedSettings: UserSettingEntry[]) => {
  const settingsData: UserSettings = { ...defaultUserSettings }
  fetchedSettings.forEach((setting) => {
    // @ts-ignore
    settingsData[setting.settingName] = parseUserSettingValue(setting)
  })
  return settingsData
}

const parseUserSettingValue = (setting: UserSettingEntry) => {
  const settingName = (setting.settingName ?? '') as keyof UserSetting
  if (!(settingName in defaultUserSettings)) {
    return setting.settingValue
  }
  const settingType = typeof defaultUserSettings[settingName]

  if (settingType === 'object') {
    return safeParsedJsonObject(setting.settingValue ?? '', defaultUserSettings[settingName])
  } else if (settingType === 'boolean') {
    return setting.settingValue === 'true'
  }
  return setting.settingValue
}

export const useUserSettings = () => {
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.USER_SETTINGS })
  const userSettings = useSelector((state: any) => (state.account.account as AccountState).userSettings ?? defaultUserSettings)

  const dispatch = useDispatch()
  const setUserSettings = (settings: UserSettings) => dispatch(dispatchUserSettings(settings))

  const refetchUserSettings = async () => {
    const { data, errors } = await client.query<GetUserSettingsQuery, GetUserSettingsQueryVariables>({
      query: getUserSettings,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    })
    if (errors) {
      logNewRelicError(errors)
    }

    if (!data.getUserSettings.userSettings) {
      setUserSettings({ ...defaultUserSettings })
      return
    }

    const fetchedSettings = data.getUserSettings.userSettings as UserSettingEntry[]
    const settingsData = parseUserSettings(fetchedSettings)
    setUserSettings({ ...defaultUserSettings, ...userSettings, ...settingsData })
  }

  const setUserSetting = async <T extends keyof UserSettings>(settingName: T, settingValue: UserSettings[T]) => {
    if (userSettings && userSettings[settingName] === settingValue) {
      return
    }

    const value = typeof defaultUserSettings[settingName] === 'object' ? JSON.stringify(settingValue) : String(settingValue)

    const { errors } = await client.mutate<UpsertUserSettingsMutation, UpsertUserSettingsMutationVariables>({
      mutation: upsertUserSettings,
      variables: {
        settings: [
          {
            settingName: settingName,
            settingValue: value,
          },
        ],
      },
      errorPolicy: 'all',
    })

    if (errors) {
      logNewRelicError(errors)
    } else {
      setUserSettings({ ...defaultUserSettings, ...userSettings, [settingName]: settingValue })
    }
  }

  return { userSettings, setUserSetting, refetchUserSettings }
}

export default useUserSettings
