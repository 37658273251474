import { ItemDto } from '@graphql/types/query-types'
import { SetContainerValues } from '@interface/ListPage.context'
import { useAddContactsFromCRMRequests } from '@src/pages/ContactSegments/components/AddContactsFromCRM/GraphQL/AddContactsFromCRMRequests.crm.graphQL'
import { useContactSegmentsCRMServiceRequests } from '@src/pages/ContactSegments/GraphQL/ContactSegments.crm.graphQL'
import { useContactSegmentsEntityUploadServiceRequest } from '@src/pages/ContactSegments/GraphQL/ContactSegments.entity-upload.graphQL'
import { setFtpSegmentsLastExecutions, setSegments } from '@src/pages/ContactSegments/utils/ContactSegmentsContainerUtils'
import {
  getAllSegmentsUtils,
  getFavoriteSegmentsUtils,
  getRecentSegmentsUtils,
  getSegmentsCreatedByMeUtils,
} from '@src/pages/ContactSegments/utils/FiltersUtils'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemSubType, ItemType } from '@utils/categorization'
import { ContactSegmentsState, Segment } from '@utils/contactSegments/contactSegments.utils'
import { FilterTypes } from '@utils/filter'
import useCRM from '@utils/hooks/useCRM'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { EMPTY_LISTING_OPTIONS } from '@utils/ListPage.context.default'

interface UseSegmentFiltersProps {
  setContainerValues: SetContainerValues<ContactSegmentsState>
  allSegmentsEmptyListing: EMPTY_LISTING_OPTIONS
  update: (values: Partial<ContactSegmentsState>) => void
  refreshRecordCount: (selectedSegments: string[], segmentWasEdited?: boolean) => void
}

export const useSegmentFilters = (props: UseSegmentFiltersProps) => {
  const { setContainerValues, allSegmentsEmptyListing, update, refreshRecordCount } = props

  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })
  const { client: crmClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CRM })

  const accountSettings = useAccountSettings()

  const { hasCRMConnected } = useCRM()

  const { getCRMSegmentsInfoRequest } = useContactSegmentsCRMServiceRequests(crmClient)
  const { syncedSegmentDetailsRequest } = useAddContactsFromCRMRequests()
  const { getLastSegmentExecutionRequest, getFtpExportSyncsBySegmentRequest, getFtpSyncsBySegmentRequest, getLastSegmentExportExecutionRequest } =
    useContactSegmentsEntityUploadServiceRequest()

  const updateSegmentsWithFtpData = (segments: Segment[]) =>
    setFtpSegmentsLastExecutions(
      segments,
      getFtpSyncsBySegmentRequest,
      getFtpExportSyncsBySegmentRequest,
      getLastSegmentExecutionRequest,
      getLastSegmentExportExecutionRequest
    )

  const setItems = (items: ItemDto[], page = 0, pageSize: number, needsReRender?: boolean) => {
    return setSegments(
      updateSegmentsWithFtpData,
      setContainerValues,
      syncedSegmentDetailsRequest,
      getCRMSegmentsInfoRequest,
      hasCRMConnected,
      items,
      page,
      pageSize,
      refreshRecordCount,
      !needsReRender
    )
  }

  const filterActions = {
    [FilterTypes.ALL_SEGMENTS]: async (page = 0, pageSize: number, needsReRender?: boolean) => {
      update({ emptyListingOption: allSegmentsEmptyListing })
      const items = await getAllSegmentsUtils(setContainerValues, categorizationClient, page, pageSize, { needsReRender, accountSettings })
      await setItems(items, page, pageSize, needsReRender)
    },
    [FilterTypes.RECENT]: async (page = 0, pageSize: number, needsReRender?: boolean) => {
      update({ emptyListingOption: EMPTY_LISTING_OPTIONS.EMPTY_RECENT })
      const items = await getRecentSegmentsUtils(setContainerValues, categorizationClient, page, pageSize, needsReRender)
      await setItems(items, page, pageSize, needsReRender)
    },
    [FilterTypes.CREATED_BY_ME]: async (page = 0, pageSize: number, needsReRender?: boolean) => {
      update({ emptyListingOption: EMPTY_LISTING_OPTIONS.EMPTY_CREATED_BY_ME })
      const items = await getSegmentsCreatedByMeUtils(setContainerValues, categorizationClient, page, pageSize, needsReRender)
      await setItems(items, page, pageSize, needsReRender)
    },
    [FilterTypes.FAVORITES]: async (page = 0, pageSize: number, needsReRender?: boolean) => {
      update({ emptyListingOption: EMPTY_LISTING_OPTIONS.EMPTY_FAVORITES })
      const items = await getFavoriteSegmentsUtils(
        setContainerValues,
        categorizationClient,
        page,
        pageSize,
        true,
        [ItemType.SEGMENT, ItemType.BOUNCE],
        needsReRender
      )
      await setItems(items, page, pageSize, needsReRender)
    },
    [FilterTypes.CONTACT_PREFERENCES]: async (page = 0, pageSize: number, needsReRender?: boolean) => {
      update({ emptyListingOption: EMPTY_LISTING_OPTIONS.EMPTY_CONTACT_PREFERENCES })
      const items = await getAllSegmentsUtils(setContainerValues, categorizationClient, page, pageSize, {
        type: ItemType.BOUNCE,
        needsReRender,
        accountSettings,
      })
      await setItems(items, page, pageSize, needsReRender)
    },
    [FilterTypes.CRM_SOURCED]: async (page = 0, pageSize: number, needsReRender?: boolean) => {
      update({ emptyListingOption: EMPTY_LISTING_OPTIONS.EMPTY_CRM_SOURCED_SEGMENTS })
      const items = await getAllSegmentsUtils(setContainerValues, categorizationClient, page, pageSize, {
        needsReRender,
        subTypes: [ItemSubType.CRM_SOURCED],
        accountSettings,
      })
      await setItems(items, page, pageSize, needsReRender)
    },
  }

  return {
    filterActions,
    updateSegmentsWithFtpData,
  }
}
