import equal from 'fast-deep-equal/es6/react'
import { TFunction } from 'i18next'
import * as yup from 'yup-latest'

import { PersonalizationContainerProps } from '@complex/Personalization/PersonalizationContainer'
import { PersonalizationItem } from '@complex/Personalization/utils/Personalization.context'
import { MAX_SUMMARY_CHARACTERS } from '@src/pages/Content/Email/Editor/components/GenerativeEmailModal/GenerativeEmailModal.constants'
import {
  emailGroupingOperators,
  findFieldOperator,
} from '@src/pages/EmailComposer/EmailModals/components/DynamicContent/components/DisplayConditions/DisplayConditions.operators'
import { TranslationKey } from '@utils/const/globals'
import { FilterTypes } from '@utils/filter'
import { formatNumber } from '@utils/numbers'

import { dateFormats } from './DisplayConditions.dates'

export interface DisplayConditionsFieldItem {
  personalization?: PersonalizationItem
  values: string[]
  operator: string
  dateFormat: string
  groupIdx?: number
}

export interface DisplayConditionsGroupItem {
  header: string
  groupingOperator: keyof typeof emailGroupingOperators
  fields: DisplayConditionsFieldItem[]
  groupError?: string
}

export interface DisplayConditionsForm {
  header: string
  description: string
  groups: DisplayConditionsGroupItem[]
  duplicateGroups?: string
}

export interface FieldOperator {
  key: string
  displayText: TranslationKey
  valueCount: 0 | 1 | 2
  valueType: 'string' | 'number' | 'date'
}

export interface MultiFieldError {
  message: undefined | { messageText: string; fields: DisplayConditionsFieldItem[] }
}

export type TogglePersonalizationsModal = (props: PersonalizationContainerProps['tagInputProps'] | undefined) => void

export const displayConditionsFormSchema = (t: TFunction): yup.ObjectSchema<DisplayConditionsForm> => {
  return yup.object().shape({
    header: yup.string().required('Name is required').defined(),
    description: yup
      .string()
      .default('')
      .test('description', '', function (value) {
        return value.length <= MAX_SUMMARY_CHARACTERS
          ? true
          : this.createError({
              message: t('', {
                maxChars: MAX_SUMMARY_CHARACTERS,
                overage: formatNumber(value.length - MAX_SUMMARY_CHARACTERS),
              }),
            })
      })
      .defined(),
    groups: yup
      .array()
      .of(
        yup.object().shape({
          header: yup.string().default('Display Conditions'),
          groupingOperator: yup.string<'any' | 'all'>().default('all'),
          fields: yup
            .array()
            .of(
              yup.object().shape({
                values: yup
                  .array()
                  .of(yup.string().defined())
                  .defined()
                  .default([])
                  .test('invalidValue[0]', '', function (values) {
                    return testInvalidValue(this, values, 0)
                  })
                  .test('invalidValue[1]', '', function (values) {
                    return testInvalidValue(this, values, 1)
                  })
                  .test('invalidRange', 'EmailComposer.DynamicContent.Error.InvalidRange', function (values) {
                    const field = this.parent as DisplayConditionsFieldItem
                    const operator = findFieldOperator(field.operator)
                    if (operator?.valueCount === 2 && !!values[0] && !!values[1]) {
                      const isInvalidDate = operator?.valueType === 'date' && Date.parse(values[0]) > Date.parse(values[1])
                      const isInvalidNumber = operator?.valueType === 'number' && Number(values[0]) > Number(values[1])
                      if (isInvalidDate || isInvalidNumber) {
                        return this.createError({ message: 'EmailComposer.DynamicContent.Error.InvalidRange' })
                      }
                    }
                    return true
                  }),
                dateFormat: yup.string().default(dateFormats[0][0].value).defined(),
                operator: yup.string().default('EQUALS.TEXT'),
                personalization: yup
                  .object()
                  .shape({
                    id: yup.string().defined(),
                    title: yup.string().defined(),
                    group: yup.string<FilterTypes>().defined(),
                  })
                  .test('blankField', 'EmailComposer.DynamicContent.Error.MissingField', (personalization) => {
                    if (equal(personalization, {})) {
                      return false
                    }
                    return true
                  }),
              })
            )
            .default([]),
          // Need separate dummy field to allow errors for entire group without overwriting field errors
          groupError: yup
            .string()
            .default('')
            .defined()
            .test('duplicateField', 'EmailComposer.DynamicContent.Error.Duplicate', function () {
              let allDupFields: DisplayConditionsFieldItem[] = []
              const group = this.parent as DisplayConditionsGroupItem
              for (let i = 0; i < group.fields.length; ++i) {
                const field = group.fields[i]
                if (equal(field.personalization, {}) || field.values.every((value) => value === '')) {
                  // Field is incomplete, don't count as duplicate
                  return true
                }
                const dupFields = group.fields.filter(
                  (otherField) =>
                    otherField.operator === field.operator &&
                    equal(otherField.values, field.values) &&
                    otherField.personalization?.id === field.personalization?.id
                )

                if (dupFields.length > 1) {
                  allDupFields = [...allDupFields, ...dupFields]
                }
              }
              if (allDupFields.length) {
                return this.createError({ message: { messageText: 'EmailComposer.DynamicContent.Error.Duplicate', fields: [...allDupFields] } })
              }
              return true
            }),
        })
      )
      .default([]),
    duplicateGroups: yup
      .string()
      .default('')
      .defined()
      .test('duplicateGroups', '', function () {
        const groups = this.parent.groups
        let duplicates: DisplayConditionsFieldItem[] = []
        const allFields = groups.flatMap((group: DisplayConditionsGroupItem, idx: number) =>
          group.fields.map((field: DisplayConditionsFieldItem) => ({ ...field, groupIdx: idx }))
        )

        allFields.forEach((field: DisplayConditionsFieldItem) => {
          if (equal(field.personalization, {}) || field.values.every((value) => value === '')) {
            // Field is incomplete, don't count as duplicate
            return true
          }

          const dupFields = allFields.filter(
            (otherField: DisplayConditionsFieldItem) =>
              otherField.operator === field.operator &&
              equal(otherField.values, field.values) &&
              otherField.personalization?.id === field.personalization?.id
          )

          if (dupFields.length > 1 && !equal(dupFields, duplicates)) {
            duplicates = [...duplicates, ...dupFields]
          }
        })

        if (duplicates.length > 0) {
          return this.createError({
            path: 'duplicateGroups',
            message: { messageText: 'EmailComposer.DynamicContent.Error.Duplicate.Group', fields: [...duplicates] },
          })
        }

        return true
      }),
  })
}

function testInvalidValue(test: yup.TestContext, values: string[], index: number) {
  const field = test.parent as DisplayConditionsFieldItem
  const fieldPath = test.path

  const operator = findFieldOperator(field.operator)
  const value = values[index]
  const path = { path: `${fieldPath}[${index}]` }

  // Don't check values that aren't applicable based on selected personalization/operator
  if (equal(field.personalization, {}) || (operator?.valueCount ?? 0) < index + 1) {
    return true
  }

  const isDateRange = operator?.valueType === 'date' && operator?.valueCount === 2
  if (!value && !isDateRange) {
    return test.createError({ message: 'EmailComposer.DynamicContent.Error.MissingValue', ...path })
  }

  if (operator?.valueType === 'number') {
    if (isNaN(Number(value))) {
      return test.createError({ type: 'invalidValue', message: 'EmailComposer.DynamicContent.Error.InvalidNumber', ...path })
    } else if (operator.key === 'IS_YEAR.DATE' && (Number(value) < 1000 || Number(value) > 9999)) {
      return test.createError({ message: 'EmailComposer.DynamicContent.Error.InvalidYear', ...path })
    } else if (operator.key === 'IS_DAY.DATE' && (Number(value) < 1 || Number(value) > 31)) {
      return test.createError({ message: 'EmailComposer.DynamicContent.Error.InvalidDay', ...path })
    }
  } else if (operator?.valueType === 'date') {
    if (operator.valueCount === 1 && isNaN(Date.parse(value))) {
      return test.createError({ message: 'EmailComposer.DynamicContent.Error.InvalidDate', ...path })
    } else if (operator.valueCount === 2) {
      const [startDate, endDate] = values
      const invalidDates = isNaN(Date.parse(startDate)) || isNaN(Date.parse(endDate))

      return invalidDates ? test.createError({ message: 'EmailComposer.DynamicContent.Error.InvalidDate', path: fieldPath }) : true
    }
  }
  return true
}
