import { gql } from '@apollo/client'

export default gql`
  mutation uploadImage($base64File: String, $fileName: String, $extension: String, $folderName: String, $url: String) {
    uploadImage(base64File: $base64File, fileName: $fileName, extension: $extension, folderName: $folderName, url: $url) {
      id
      title
      created
      modified
      linkUrl
      previewUrl
      size
      originalLocation
    }
  }
`
