import { ApolloClient } from '@apollo/client'
import { ListInfo, ListSchemaQuery, Program, ProgramSource } from '@graphql/types/query-types'
import { getUpdatedProgramSources } from '@utils/program/program'
import { toTrainCase } from '@utils/strings'
import { MatchParams } from '@utils/types'

import { getProgramManagerQueries } from './graphQL/ProgramManager.graphQL'
import {
  DEFAULT_PROGRAM_MANAGER_TAB,
  ProgramManagerLocationStateProps,
  ProgramManagerTabs,
  getProgramManagerTabsStructure,
} from './ProgramManager.constants'

export const getProgramManagerTabFromLocation = (params: MatchParams, locationState?: ProgramManagerLocationStateProps, hasSms = true) => {
  const { id, tab } = params
  const currentTab = locationState?.tab ?? tab
  const isTabParent = currentTab && getProgramManagerTabsStructure({ hasSms }).find((item) => item.parent === currentTab)
  const isValidTab = currentTab && !isTabParent && Object.values(ProgramManagerTabs).includes(currentTab as ProgramManagerTabs)

  return isValidTab ? currentTab : id === 'new' ? ProgramManagerTabs.SETTINGS : DEFAULT_PROGRAM_MANAGER_TAB
}

export const getProgramManagerTabPath = (tab: string, subTab?: string, hasSms = true) => {
  const tabItem = getProgramManagerTabsStructure({ hasSms }).find((item) => item.index === tab)
  if (tab === DEFAULT_PROGRAM_MANAGER_TAB || !tabItem) {
    return ''
  }

  const subTabPath = subTab && tab === ProgramManagerTabs.PROGRAM_PERFORMANCE ? `/${toTrainCase(subTab)}` : ''
  return `/${tabItem.index}${subTabPath}`
}

export const getProgramSourceFromListSchema = (lists: ListInfo[], listSchemas: ListSchemaQuery['listSchemas']) => {
  return listSchemas.map((listSchema) => {
    const baseId = lists.find((list) => list.id == listSchema.listId)?.baseId
    return {
      id: listSchema.listId,
      baseId: baseId ?? '',
      name: listSchema.displayName,
      headers: listSchema.schema?.headers ?? [],
      __typename: 'ProgramSource',
    }
  })
}

export const getUpdatedProgramWithAOContactsSource = async (client: ApolloClient<any>, program: Program, listId: string) => {
  const { getListSchemaRequest, searchListByIdsRequest } = getProgramManagerQueries(client)
  const { data: schemaData } = await getListSchemaRequest([listId])
  const { data: segmentData } = await searchListByIdsRequest([listId])

  const listSchemas = schemaData?.listSchemas
  const unifiedLists = segmentData?.searchListsByIds?.unifiedLists

  if (listSchemas && listSchemas.length && unifiedLists && unifiedLists.length) {
    const lists = unifiedLists[0].entries ?? []
    const newSource = getProgramSourceFromListSchema(lists as ListInfo[], listSchemas)
    return getUpdatedProgramSources(program, newSource as ProgramSource[])
  }
  return program
}
