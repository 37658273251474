import React, { Key, useContext, useMemo } from 'react'

import RadioCard, { RadioCardProps, STYLE } from '@components/RadioCard/RadioCard'
import RadioCardGroup from '@components/RadioCardGroup/RadioCardGroup'
import { useTranslation } from '@const/globals'
import StepContent from '@src/pages/ContactSegments/components/CopySegments/components/StepContent/StepContent'
import { stepBaseText } from '@src/pages/ContactSegments/components/CopySegments/constants/CopySegments.constants'
import { CopySegmentsContext, SegmentActions, Steps } from '@src/pages/ContactSegments/components/CopySegments/context/CopySegments.context'
import { stepProps } from '@src/pages/ContactSegments/components/CopySegments/utils/CopySegments.utils'

import './StepFour.css'

const rootClass = 'step-four'
const stepFourText = `${stepBaseText}Four.`

const StepFour = () => {
  const {
    values: { segmentAction },
    update,
  } = useContext(CopySegmentsContext)
  const { t } = useTranslation()

  const onSelect = (key: Key) => update({ segmentAction: key as SegmentActions })

  const segmentOptions: (RadioCardProps & { key: SegmentActions })[] = useMemo(
    () => [
      {
        key: SegmentActions.ARCHIVE,
        title: t(`${stepFourText}Archive.Title`),
        description: t(`${stepFourText}Archive.Description`),
      },
      { key: SegmentActions.DELETE, title: t(`${stepFourText}Delete.Title`), description: t(`${stepFourText}Delete.Description`) },
      { key: SegmentActions.NOTHING, title: t(`${stepFourText}Nothing.Title`), description: t(`${stepFourText}Nothing.Description`) },
    ],
    []
  )

  return (
    <StepContent className={rootClass} data-test={rootClass} {...stepProps[Steps.FOUR]}>
      <RadioCardGroup onSelect={onSelect} selectedOption={segmentAction}>
        {segmentOptions.map(({ key, ...option }) => (
          <RadioCard key={key} {...option} style={STYLE.RADIO_OUT} />
        ))}
      </RadioCardGroup>
    </StepContent>
  )
}

export default StepFour
