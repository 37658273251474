import { gql } from '@apollo/client'
import { allListsChildren } from '@graphql/queries/fragments/lists'

export default gql`
  ${allListsChildren}

  query listPoll($id: String!) {
    listPoll(id: $id) {
      ...AllListsChildren
    }
  }
`
