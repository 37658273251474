import React, { FC, Fragment } from 'react'

import classNames from 'classnames'

import Switch from '@components/Switch/Switch'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import AlertFilterCard from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertFilters/components/AlertFilterCard/AlertFilterCard'
import {
  AlertFilter,
  LogicalCondition,
} from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertFilters/utils/AlertFilters.utils'

import './AlertFiltersList.css'

interface AlertFiltersListProps {
  className?: string
  dataTest?: string
  filters: AlertFilter[]
  onDelete?: (filter: AlertFilter) => void
  onChange?: (index: number, data: Partial<AlertFilter>) => void
  onEdit?: (filter: AlertFilter) => void
  readOnly?: boolean
}

const rootClass = 'alert-filters-list'

const AlertFiltersList: FC<AlertFiltersListProps> = (props: AlertFiltersListProps) => {
  const { dataTest = rootClass, className = '', filters, readOnly = false, onDelete, onChange, onEdit } = props
  const toggleOptions = [{ label: LogicalCondition.AND }, { label: LogicalCondition.OR }, { label: LogicalCondition.NOT }]
  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {filters.map((filter, index) => (
        <Fragment key={`filter-${filter.id}`}>
          {index > 0 &&
            filter.logicalCondition &&
            (readOnly ? (
              <div className={`${rootClass}__filter-fixed`}>
                <Typography text={filter.logicalCondition.toUpperCase()} weight={TextWeight.MEDIUM} type={TextType.BODY_TEXT_SMALL} />
              </div>
            ) : (
              <Switch
                className={`${rootClass}__filter-toggle`}
                options={toggleOptions}
                selected={filter.logicalCondition}
                onClick={(option) => onChange && onChange(index, { logicalCondition: option as LogicalCondition })}
                isToggle
              />
            ))}
          <AlertFilterCard
            className={`${rootClass}__filter`}
            filter={filter}
            key={`filter-card-${filter.id}`}
            onEdit={() => onEdit && onEdit(filter)}
            onDelete={() => onDelete && onDelete(filter)}
            readOnly={readOnly}
          />
        </Fragment>
      ))}
    </div>
  )
}

export default AlertFiltersList
