import { Encoding, SignatureHashAlgorithm, Source } from './IncomingWebhooksContext'

export const hashAlgorithmLabels = {
  [SignatureHashAlgorithm.Md5]: 'MD5',
  [SignatureHashAlgorithm.Sha1]: 'SHA-1',
  [SignatureHashAlgorithm.Sha256]: 'SHA-256',
  [SignatureHashAlgorithm.Sha384]: 'SHA-384',
  [SignatureHashAlgorithm.Sha512]: 'SHA-512',
}

export const encodingLabels = {
  [Encoding.Base64]: 'Base64',
  [Encoding.Hex]: 'Hex',
  [Encoding.Plain]: 'Plain text',
}

export const webhookSourcesValues: { [key: string]: Source } = {
  default: Source.Custom,
  Calendly: Source.Calendly,
}
