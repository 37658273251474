import { gql } from '@apollo/client'

export default gql`
  query getWebinarRegistrationDataForCards($days: Int) {
    getWebinarRegistrationDataForCards(days: $days) {
      topWebinars {
        totalRegistrations
        webinarId
        webinarName
      }
      totalAttendees
      totalRegistrations
      totalWebinars
    }
  }
`
