import { useEffect } from 'react'

const useFixModalPointerEvents = (rootClass: string) => {
  useEffect(() => {
    // Since EmailComposer is implemented as a Modal, some events
    // are not triggering properly inside newly opened Modals.
    // For example dragging file into Dropzone.
    // To fix that issue adding 'pointer-events':none on all the other Modals

    const modalsExceptThis = document.querySelectorAll(`div.modal:not(.${rootClass})`)

    // Add 'no-pointer-events' class from other modals when the Insert Image Modal mounts
    modalsExceptThis.forEach((element) => {
      element.classList.add('no-pointer-events')
    })

    // Remove 'no-pointer-events' class from other modals when the Insert Image Modal unmounts
    return () => {
      modalsExceptThis.forEach((element) => {
        element.classList.remove('no-pointer-events')
      })
    }
  }, [rootClass])
}

export default useFixModalPointerEvents
