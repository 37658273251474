import { gql } from '@apollo/client'

export default gql`
  query countSuppressed($srcId: String, $excId: String) {
    countSuppressed(srcId: $srcId, excId: $excId) {
      body
      message
      objectId
      statusCode
    }
  }
`
