import React, { FC, useEffect, useState } from 'react'

import { ApolloClient } from '@apollo/client'
import Button, { ButtonType } from '@components/Button'
import { ModalBody, ModalFooter } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import getSmsMessage from '@graphql/microservices/sms-management/getSmsMessage'
import upsertMessage from '@graphql/microservices/sms-management/upsertMessage'
import {
  GetSmsMessageQuery,
  QueryGetSmsMessageArgs,
  SmsMessage,
  SmsMessageInput,
  UpsertMessageMutation,
  UpsertMessageMutationVariables,
} from '@graphql/types/microservice/sms-management-types'
import { Program } from '@graphql/types/query-types'
import SendSMSStepDetail from '@src/pages/programs/dashboard/components/ProgramSteps/components/SendSMSStepDetail/SendSMSStepDetail'
import { SMSContext } from '@src/pages/sms/context/SMSContext'
import { smsStateDefaultValues } from '@src/pages/sms/context/smsStateDefaultValues'
import { SMSMessage, State } from '@src/pages/sms/edit/EditSMSContainer'
import { canScheduleUtil, canSendUtil, updateMessageUtil } from '@src/pages/sms/edit/utils/EditSMSContainerUtils'
import { replacePersonalizationWithX } from '@src/pages/sms/edit/utils/personalizationReplacement'
import SegmentedMessage from '@src/pages/sms/edit/utils/segmentationCalculator'
import { MessageStatus } from '@src/pages/sms/marketingListOptIns/MarketingListOptIns.constants'
import { useAccountSettings } from '@utils/account/account.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { ProgramSmsSendStepExt, ProgramStepType, Step, Track } from '@utils/program/program.constants'

import SmsModal from './SmsModal'

import './SmsModal.css'

interface Props {
  step: Step
  program?: Program
  tracks?: Track[]
  isRunning: boolean
  closeModal: () => void
  saveStepAndProgram(step: Step | null, program?: Program): void
  dataTest?: string
}

const enum StepMode {
  add,
  edit,
}

interface ProgramState {
  step: ProgramSmsSendStepExt
  mode: StepMode
  client: ApolloClient<any>
}

const defaultState: State = smsStateDefaultValues

const rootClass = 'edit-sms-step-container'

const EditSmsStep: FC<Props> = (props: Props) => {
  const { step: baseStep, isRunning, closeModal, saveStepAndProgram, dataTest = rootClass } = props
  const { t } = useTranslation()

  const [containerValues, setContainerValues] = useState<State>({
    ...defaultState,
    smsMessage: { ...defaultState.smsMessage, title: baseStep.displayName },
  })

  const accountSettings = useAccountSettings()
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.SMS_MANAGEMENT })

  const [programState] = useState<ProgramState>(() => {
    const step = baseStep as ProgramSmsSendStepExt
    return {
      step,
      mode: step.smsMessageId ? StepMode.edit : StepMode.add,
      client,
    }
  })

  const getDefaultReceivedData = () => {
    return {
      title: programState.step.displayName,
      messageText: '',
      status: MessageStatus.PROGRAM_SAVED,
      smsMessageId: '',
      accountId: accountSettings.accountId,
    }
  }

  useEffect(() => {
    const receivedData: SmsMessage = getDefaultReceivedData()

    const getExisting = async () => {
      return await programState.client.query<GetSmsMessageQuery, QueryGetSmsMessageArgs>({
        query: getSmsMessage,
        fetchPolicy: 'network-only',
        variables: {
          smsMessageId: programState.step.smsMessageId,
          accountId: accountSettings.accountId,
        },
      })
    }
    const getNew = async () => {
      return await programState.client.mutate<UpsertMessageMutation, UpsertMessageMutationVariables>({
        mutation: upsertMessage,
        variables: {
          smsMessage: receivedData,
          accountId: receivedData.accountId,
          isWelcomeMessage: false,
          userId: accountSettings.userId,
        },
      })
    }
    const processData = (data: SmsMessage) => {
      if (data.smsMessageId) {
        const messageText = data.messageText ?? ''
        const smsMessage: SMSMessage = {
          title: data.title ?? programState.step.displayName,
          messageText,
          status: data.status,
          smsMessageId: data.smsMessageId,
          description: '',
          launchId: '',
          recipients: [],
          recipientsPerCreditMultiplier: [
            {
              contacts: 0,
              contactsPerCountry: [
                {
                  country: 'United States',
                  contacts: 0,
                },
              ],
              creditMultiplier: 0,
            },
          ],
          recipientTimezones: [],
        }
        setContainerValues({
          ...containerValues,
          smsMessage,
          segmentedMessage: new SegmentedMessage(replacePersonalizationWithX(messageText)),
        })
      }
    }

    // if existing step, get the related message
    if (programState.step.smsMessageId) {
      getExisting().then((data) => {
        const msgData = data.data.getSmsMessage
        processData({ ...msgData, accountId: accountSettings.accountId })
      })
    } else {
      // otherwise create a new message
      getNew()
        .then((data) => {
          receivedData.smsMessageId = data.data?.upsertMessage?.smsMessageId
          processData(receivedData)
        })
        .catch(() => setContainerValues({ ...containerValues, errors: { fetchMessage: true } }))
    }
  }, [programState.step])

  const sendSMSMessage = () => {
    // First update the message via graphQL
    const { smsMessage: oldMessage } = containerValues
    const smsMessage: SmsMessageInput = {
      title: oldMessage.title,
      messageText: oldMessage.messageText,
      smsMessageId: oldMessage.smsMessageId,
      accountId: accountSettings.accountId,
      status: MessageStatus.PROGRAM_SAVED,
    }
    programState.client
      .mutate<UpsertMessageMutation, UpsertMessageMutationVariables>({
        mutation: upsertMessage,
        variables: {
          smsMessage,
          accountId: accountSettings.accountId,
          isWelcomeMessage: false,
          userId: accountSettings.userId,
        },
      })
      .then(() => {
        // Then update the parent program
        saveStepAndProgram({
          ...programState.step,
          stepId: baseStep.stepId,
          stepType: ProgramStepType.SMS,
          displayName: containerValues.smsMessage.title,
          smsMessageId: containerValues.smsMessage.smsMessageId,
        })
      })
  }
  const scheduleMessageLaunch = () => false

  const saveMessage = (smsMessage: SMSMessage, messageCaretPosition?: number) => {
    const updatedCaretPosition = messageCaretPosition ? { messageCaretPosition } : {}
    setContainerValues({
      ...containerValues,
      ...updatedCaretPosition,
      smsMessage,
      segmentedMessage: new SegmentedMessage(replacePersonalizationWithX(smsMessage.messageText)),
    })
  }

  const updateMessage = (message: string) => updateMessageUtil(message, containerValues, setContainerValues)

  const updater = (field: string, value: any) => {
    setContainerValues({ ...containerValues, [field]: value })
  }

  const canSend = () => canSendUtil(containerValues)
  const canSchedule = () => canScheduleUtil(containerValues)

  const renderView = () => (
    <>
      <ModalBody className={rootClass}>
        <Typography text={baseStep.displayName} weight={TextWeight.MEDIUM} type={TextType.SECTION_HEADER} />
        <SendSMSStepDetail message={containerValues.smsMessage.messageText} />
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form} flexEnd>
        <Button buttonType={ButtonType.TERTIARY} onClick={closeModal} dataTest={`${dataTest}-close-button`}>
          {t('Close')}
        </Button>
      </ModalFooter>
    </>
  )

  return isRunning ? (
    renderView()
  ) : (
    <SMSContext.Provider
      value={{
        values: containerValues,
        updater,
        saveMessage,
        sendSMSMessage,
        canSend,
        canSchedule,
        updateMessage,
        scheduleMessageLaunch,
      }}
      data-test={dataTest}
    >
      <SmsModal closeModal={closeModal} />
    </SMSContext.Provider>
  )
}

export default EditSmsStep
