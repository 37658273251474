import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import ListingPagePreview from '@complex/ListingPage/Components/ListingPageModals/LisitingPagePreview/ListingPagePreview'
import { ListingPageCommonContext } from '@complex/ListingPage/Context/ListingPageCommon.context'
import Button, { ButtonType } from '@components/Button'
import { FilterableListData } from '@components/FilterableList/FilterableList'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Search, { SearchType } from '@components/Search/Search'
import { TableV2 } from '@components/TableV2/TableV2'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import withLoadingAndError from '@hoc/withLoadingAndError/withLoadingAndError'

import './ListingPageShareModal.css'

export interface ListingPageShareModalProps {
  isOpen: boolean
  shareAccounts: FilterableListData[]
  loading: boolean
  canPreview: boolean
  updateSharedAccounts: (shareAccountsIds: number[]) => void
  onClose: () => void
  onItemSelection?: (selectedItem: FilterableListData) => void
  className?: string
  dataTest?: string
}

const rootClass = 'listing-page-share-modal'

const ListingPageShareModal: FC<ListingPageShareModalProps> = (props: ListingPageShareModalProps) => {
  const { isOpen, shareAccounts, canPreview, updateSharedAccounts, onClose, dataTest = rootClass, className = '' } = props

  const {
    values: {
      selectedRows,
      listingPageProps: {
        tableProps: { shareModalText },
      },
    },
  } = useContext(ListingPageCommonContext)

  const { t } = useTranslation()
  const [items, setItems] = useState<FilterableListData[]>(shareAccounts.sort((a, b) => a.name.localeCompare(b.name)))
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [search, setSearch] = useState<string>('')

  useEffect(() => {
    if (!!shareAccounts) {
      setItems(shareAccounts)
    }
  }, [shareAccounts])

  useEffect(() => {
    if (search) {
      const filteredItems = shareAccounts.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
      setItems(filteredItems)
    } else {
      setItems(shareAccounts)
    }
  }, [search, shareAccounts])

  const onShare = () => {
    updateSharedAccounts(selectedIds.map((id) => parseInt(id)))
    onClose()
  }

  const onRowSelectionChanged = useCallback(
    (ids: string[]) => {
      const newIds = ids.map((id) => items[parseInt(id)].id.toString())
      setSelectedIds(newIds)
    },
    [items]
  )

  const columns = useMemo(() => {
    return [
      {
        header: 'Account',
        accessorKey: 'name',
        textAlign: 'left',
      },
      {
        header: 'Account #',
        accessorKey: 'id',
        textAlign: 'right',
        maxSize: 180,
      },
    ]
  }, [])

  const userSelectedRows = useMemo(
    () =>
      Object.fromEntries(
        selectedIds.map((id) => {
          const rowId = items.findIndex((item) => item.id.toString() === id)
          return [rowId, true]
        })
      ),
    [selectedIds, items]
  )

  const renderModalBody = () => {
    return (
      <ModalBody>
        <Typography text={t(`Share ${shareModalText?.toLocaleLowerCase()}`)} type={TextType.BODY_TEXT_LIGHT} className={`${rootClass}__title`} />
        <div className={`${rootClass}__type-name`}>
          <Typography text={selectedRows[0]?.name} weight={TextWeight.BOLD} inline />
          {canPreview && (
            <Button buttonType={ButtonType.TEXT_TEAL} onClick={() => setShowPreview(true)} className={`${rootClass}__preview-text`}>
              <Typography text={t('Preview')} type={TextType.NORMAL_TEXT_TEAL_LARGE} weight={TextWeight.MEDIUM} />
            </Button>
          )}
        </div>
        <div className={`${rootClass}__accounts-list`}>
          <Typography text={`${t('With the following accounts')}:`} type={TextType.BODY_TEXT_LIGHT} className={`${rootClass}__title`} />
          <div className={`${rootClass}__child-accounts`}>
            {items.length !== 0 ? (
              <>
                <div className={`${rootClass}__search`}>
                  <Search
                    incomingValue={search}
                    onChangeHandler={setSearch}
                    placeholder={'Search Accounts'}
                    searchType={SearchType.LARGE}
                    clearOnChange={[search]}
                    canClear
                  />
                </div>
                <TableV2
                  data={items}
                  columns={columns}
                  enableCheckbox
                  styles={{ minWidth: 'auto' }}
                  withoutBorders
                  defaultSelectedRows={userSelectedRows}
                  onRowSelectionChanged={onRowSelectionChanged}
                />
              </>
            ) : (
              <>
                <Typography text={t('There are no accounts available to share with. ')} />
                <Typography text={t('Add accounts to your Marketing Network.')} />
              </>
            )}
          </div>
        </div>
      </ModalBody>
    )
  }

  const renderModalFooter = () => {
    return (
      <ModalFooter footerType={ModalFooterType.Form}>
        <div className={`${rootClass}__footer__extra`}>
          <Typography
            text={t('ListPage.ShareModal.Footer', { current: selectedIds.length, count: shareAccounts.length })}
            type={TextType.BODY_TEXT_SMALL_LIGHT}
            tagProps={{ medium: { weight: TextWeight.MEDIUM, inline: true } }}
          />
        </div>
        <Button buttonType={ButtonType.TERTIARY} onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} onClick={onShare} disabled={selectedIds.length === 0} dataTest={`${rootClass}-share-button`}>
          {t('Share asset')}
        </Button>
      </ModalFooter>
    )
  }

  return showPreview ? (
    <ListingPagePreview
      itemName={selectedRows[0].name ?? ''}
      itemId={selectedRows[0].externalId ?? ''}
      isOpen={showPreview}
      onClose={() => setShowPreview(false)}
    />
  ) : (
    <Modal
      isOpen={isOpen}
      header={<ModalHeader headerType={ModalHeaderType.Form}>{t('Share asset')}</ModalHeader>}
      className={classNames(rootClass, className)}
      data-test={dataTest}
    >
      {renderModalBody()}
      {renderModalFooter()}
    </Modal>
  )
}

export default withLoadingAndError(ListingPageShareModal)
