import React, { createRef, FC, KeyboardEvent, MouseEvent, ReactNode, RefObject, useState } from 'react'

import classNames from 'classnames'

import Tooltip from '@components/Tooltip/Tooltip'

import './BaseTag.css'

export interface BaseTagProps {
  className?: string
  dataTest?: string
  children?: ReactNode
  color: string
  isSelected?: boolean
  name: string
  onClick?: (name: string) => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  reference?: RefObject<HTMLDivElement>
}

const rootClass = 'base-tag'

const BaseTag: FC<BaseTagProps> = (props: BaseTagProps) => {
  const {
    name,
    color,
    children,
    className = '',
    isSelected = false,
    dataTest = rootClass,
    onClick,
    onMouseEnter,
    onMouseLeave,
    reference = createRef<HTMLDivElement>(),
  } = props
  const [hover, setHover] = useState<boolean>(false)
  const showCursor = !!(onClick || onMouseEnter || onMouseLeave)

  const handleOnMouseEnter = () => {
    if (onMouseEnter) {
      onMouseEnter()
    }
    setHover(true)
  }

  const handleOnMouseLeave = () => {
    if (onMouseLeave) {
      onMouseLeave()
    }
    setHover(false)
  }

  const renderColor = () => (
    <div
      className={classNames(`${rootClass}__color`, {
        [`${rootClass}__color-no-border`]: hover || isSelected,
        [`${rootClass}__color-collapsed`]: !children,
      })}
      style={{ backgroundColor: color }}
    />
  )

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    onClick?.(name)
  }

  const handleKeydown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === ' ') {
      event.stopPropagation()
      onClick?.(name)
    }
  }

  return (
    <div
      role={'button'}
      tabIndex={0}
      className={classNames(rootClass, className, { [`${rootClass}__background`]: !!children, [`${rootClass}__no-cursor`]: !showCursor })}
      data-test={dataTest}
      onKeyDown={handleKeydown}
      onClick={handleClick}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      ref={reference}
    >
      {children ? (
        <>
          {renderColor()}
          <div
            className={classNames(`${rootClass}__color-filling`, { [`${rootClass}__color-filling-full-width`]: hover || isSelected })}
            style={{ backgroundColor: color }}
          />
          {children}
        </>
      ) : (
        <Tooltip position={'top'} trigger={renderColor()} triggerClassName={`${rootClass}__tooltip`}>
          {name}
        </Tooltip>
      )}
    </div>
  )
}

export default BaseTag
