// eslint-disable-next-line no-restricted-imports
import { format } from 'date-fns'

import { SvgNames } from '@components/Svg'
import { OverviewManageProfile } from '@graphql/types/mutation-types'
import { OverviewResponse } from '@graphql/types/query-types'
import { OverviewVM } from '@src/pages/Profile/components/Overview/models/overview.view-model'

export interface ManageProfileUIValues {
  headerTitle: string
  divider: boolean
  items: ManageProfileUIItem[]
}

export interface ManageProfileUIItem {
  icon: SvgNames
  value: string
  tooltip: string
}

export const getOverviewManageProfileUIValues = (overviewVM: OverviewVM): ManageProfileUIValues[] => {
  const { jobTitle, companyName, login, fax, callPhone, bizPhone } = overviewVM.getData()
  return [
    {
      headerTitle: 'About',
      divider: true,
      items: [
        {
          icon: SvgNames.name,
          value: overviewVM.getFullName(),
          tooltip: 'Full name',
        },
        {
          icon: SvgNames.jobTitleLight,
          value: jobTitle,
          tooltip: 'Job title',
        },
        {
          icon: SvgNames.organization,
          value: companyName,
          tooltip: 'Company name',
        },
      ],
    },
    {
      headerTitle: 'Contact',
      divider: true,
      items: [
        {
          icon: SvgNames.letterLight,
          value: login,
          tooltip: 'Email address',
        },
        {
          icon: SvgNames.phoneNumber,
          value: bizPhone,
          tooltip: 'Phone number',
        },
        {
          icon: SvgNames.phoneLight,
          value: callPhone,
          tooltip: 'Mobile number',
        },
        {
          icon: SvgNames.fax,
          value: fax,
          tooltip: 'Fax number',
        },
        {
          icon: SvgNames.address,
          value: overviewVM.getProfileAddress(),
          tooltip: 'User address',
        },
      ],
    },
    {
      headerTitle: 'Local time',
      divider: false,
      items: [
        {
          icon: SvgNames.timezone,
          value: `${format(Date.now(), 'h:mm a (OOOO)').replace('GMT', 'UTC')}`,
          tooltip: 'Time zone',
        },
      ],
    },
  ]
}

export const getProfileFormData = (data: OverviewResponse): OverviewManageProfile => {
  const { firstName, lastName, jobTitle, street, street2, city, state, zip, country, bizPhone, callPhone, fax, timeZone } = data

  return {
    firstName,
    lastName,
    jobTitle,
    phoneNumber: bizPhone,
    mobileNumber: callPhone,
    faxNumber: fax,
    street,
    streetLine2: street2,
    city,
    state,
    zipCode: zip,
    country,
    timeZone,
  }
}
