import React from 'react'

import { SvgNames, SvgType } from '@components/Svg'
import Svg, { SvgColor } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import { TextWeight } from '@components/Typography/Typography'
import { ListsValidation } from '@graphql/types/query-types'
import { CellContext } from '@tanstack/react-table'
import { SendtoList } from '@utils/composer/context/EmailComposer.context'
import { formatNumber } from '@utils/numbers'

const renderListWithValidations = (name: string, trContext: string, isError: boolean) => (
  <div className="flex-align-center">
    <Tooltip
      triggerClassName="flex-align-center push-left"
      inline={false}
      minimalPadding={false}
      trigger={
        <Svg
          name={isError ? SvgNames.inputStatusInvalidNoFill : SvgNames.warningSolidNoFill}
          fill={isError ? SvgColor.ERROR_TEXT : SvgColor.YELLOW_CAUTION}
          type={SvgType.ICON}
        />
      }
      childrenProps={{
        text: `SendDetails.Suppression.Validation.Tooltip.${trContext}`,
        tagProps: { bold: { weight: TextWeight.BOLD } },
        inline: true,
      }}
    />
    {name}
  </div>
)

export const getSuppressionsColumns = (listsValidation?: ListsValidation) => {
  return [
    {
      header: 'Name',
      accessorKey: 'srcName',
      textAlign: 'left',
      cell: (cell: CellContext<SendtoList, any>) => {
        const name = cell.row.original.srcName

        if (listsValidation) {
          const { deletedSuppressionListNames, suppressionListsWithMissingEmailAddressColumn } = listsValidation

          if (!!suppressionListsWithMissingEmailAddressColumn?.includes(name)) {
            return renderListWithValidations(name, 'EmailColumn', true)
          }

          if (!!deletedSuppressionListNames?.includes(name)) {
            return renderListWithValidations(name, 'Deleted', false)
          }
        }

        return name
      },
    },
    {
      header: 'Records',
      accessorKey: 'size',
      textAlign: 'right',
      maxSize: 150,
      cell: (cell: CellContext<SendtoList, any>) => formatNumber(cell.getValue()),
    },
  ]
}
