import React from 'react'

import { renderColWithTooltip } from '@components/Table/components/tableColumns'
import { TableColumn } from '@components/Table/Table'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { ColWithTooltip } from '@components/TableV2/utils/tableV2ColumnUtils'
import { SforceUserResponse } from '@graphql/types/query-types'

export const tableColumns: (t: (s: string) => string) => TableColumn[] = () => [
  {
    Header: 'FIRST',
    accessor: 'firstName',
    align: 'left',
    minWidth: 130,
    maxWidth: 200,
    sortType: 'caseInsensitive',
    Cell: (row: any) => renderColWithTooltip(row),
  },
  {
    Header: 'LAST',
    accessor: 'lastName',
    align: 'left',
    minWidth: 130,
    maxWidth: 200,
    sortType: 'caseInsensitive',
    Cell: (row: any) => renderColWithTooltip(row),
  },
  {
    Header: 'EMAIL',
    accessor: 'email',
    align: 'left',
    minWidth: 170,
    sortType: 'caseInsensitive',
    Cell: (row: any) => renderColWithTooltip(row),
  },
  {
    Header: 'STATUS',
    accessor: 'role',
    align: 'left',
    minWidth: 130,
    maxWidth: 170,
    sortType: (row: any) => {
      return row.original.role ? -1 : 1
    },
    Cell: (row: any) => row.row.original.role ?? <div className={'no-value-td'}>--</div>,
  },
]

export const columns: ColumnDefWithAdditionalProps<SforceUserResponse>[] = [
  {
    header: 'FIRST',
    accessorKey: 'firstName',
    textAlign: 'left',
    maxSize: 200,
    sortingFn: 'caseInsensitive',
    cell: (cell) => ColWithTooltip({ cell }),
  },
  {
    header: 'LAST',
    accessorKey: 'lastName',
    textAlign: 'left',
    maxSize: 200,
    sortingFn: 'caseInsensitive',
    cell: (cell) => ColWithTooltip({ cell }),
  },
  {
    header: 'EMAIL',
    accessorKey: 'email',
    textAlign: 'left',
    minSize: 170,
    sortingFn: 'caseInsensitive',
    cell: (cell) => ColWithTooltip({ cell }),
  },
  {
    header: 'STATUS',
    accessorKey: 'role',
    textAlign: 'left',
    maxSize: 170,
    sortingFn: (cell) => (cell.original.role ? -1 : 1),
    cell: (cell) => cell.row.original.role ?? <div className={'no-value-td'}>--</div>,
  },
]
