import React, { FC } from 'react'

import classNames from 'classnames'

import Container from '@components/Container'
import TimeInput from '@components/TimeInput/TimeInput'
import Toggle from '@components/Toggle'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './AllowedSendTime.css'

type Props = {
  startTime: string | Date
  startTimeError: boolean
  onChangeStartTime: (startTime: string | Date) => void
  endTime: string | Date
  endTimeError: boolean
  onChangeEndTime: (endTime: string | Date) => void
  allowOverride: boolean
  onChangeAllowOverride: (isEnabled: boolean) => void
  className?: string
  dataTest?: string
}

const rootClass = 'allowed-send-time'

const AllowedSendTime: FC<Props> = (props: Props) => {
  const {
    dataTest = rootClass,
    className = '',
    startTime,
    startTimeError,
    onChangeStartTime,
    onChangeEndTime,
    endTime,
    endTimeError,
    allowOverride,
    onChangeAllowOverride,
  } = props
  const { t } = useTranslation()

  return (
    <>
      <Container
        className={classNames(rootClass, className)}
        data-test={dataTest}
        title={'Allowed SMS Hours'}
        description={"Choose which hours are acceptable for your account to send SMS. Check local guidelines for what's recommended"}
      >
        <div className={`${rootClass}__inputs`}>
          <TimeInput
            label={t('Start Time')}
            value={startTime ? startTime.toString() : ''}
            onChange={onChangeStartTime}
            className={'start-time'}
            error={startTimeError}
            hideClear
          />
          <TimeInput
            label={t('End Time')}
            value={endTime ? endTime.toString() : ''}
            onChange={onChangeEndTime}
            className={'end-time'}
            error={endTimeError}
            hideClear
          />
          <div className={`${rootClass}__toggle`}>
            <Toggle isOn={allowOverride} label={t('Allow override sends')} onToggle={onChangeAllowOverride} />
          </div>
        </div>
        {(startTimeError || endTimeError) && (
          <Typography text={'Start Time must be at least one hour before End Time'} type={TextType.ERROR} className={`${rootClass}__time-error`} />
        )}
      </Container>
    </>
  )
}

export default AllowedSendTime
