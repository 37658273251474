import { UNIFIED_CONTACTS_ID } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import { Program, ProgramSource, ProgramStepCounts } from '@graphql/types/query-types'

const applyUnifiedBaseId = (sources: ProgramSource[]): ProgramSource[] => sources.map((source) => ({ ...source, baseId: UNIFIED_CONTACTS_ID }))

const correctSourceBaseIds = (program: Program, hasUnifiedLists: boolean): Program => {
  if (hasUnifiedLists) {
    const sourceList = program.sourceList
    const sources = program.sources
    return { ...program, sources: applyUnifiedBaseId(sources), sourceList: applyUnifiedBaseId(sourceList) }
  }
  return program
}

export const processProgram = (rawProgram: Program, hasUnifiedLists = false): Program => {
  const program = correctSourceBaseIds(rawProgram, hasUnifiedLists)

  const tracks = program.tracks?.map((track) => ({
    ...track,
    steps: track.steps.filter((step, pos, self) => {
      return (
        self.findIndex((t) => {
          return t.stepId === step.stepId
        }) === pos
      )
    }),
  }))
  return { ...program, tracks }
}

export const getContactsWaitingInStep = (stepId: string, programStepCounts: ProgramStepCounts[]) => {
  const step = programStepCounts.find((programStepCount) => programStepCount.stepId === stepId)
  return step ? step.inStepCount : 0
}
