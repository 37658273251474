import React, { FC, ReactNode, useContext, useMemo } from 'react'

import classNames from 'classnames'

import Input from '@components/Input/Input'
import Select from '@components/Select/Select'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Toggle from '@components/Toggle'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { getUUID } from '@const/globals'
import {
  ColumnType,
  DynamicListRowData,
  RowType,
  ExtraInfo,
  DynamicListItems,
  CommonMappingColumn,
} from '@src/pages/datamanagement/components/CrmContacts/components/DynamicList/utils/DynamicList.interfaces'
import { getIcon, rowHasMappedCRM } from '@src/pages/datamanagement/components/CrmContacts/components/DynamicList/utils/DynamicList.utils'
import { DataManagementContext } from '@src/pages/datamanagement/context/DataManagementContext'
import useCRM from '@utils/hooks/useCRM'

import './DynamicListRow.css'

interface DynamicListRowProps {
  dataTest?: string
  updateRow: (rowId: string, columnName: string, value: string, extraInfo?: {}) => void
  deleteRow: (rowId: number, extraInfo?: {}) => void
  extraInfo?: ExtraInfo
  row: DynamicListRowData
}

const rootClass = 'dynamic-list-row'
const NEW_EMPTY_ROW = -1
const SCORE_DATA_TYPE = 'SCORE'

const DynamicListRow: FC<DynamicListRowProps> = (props: DynamicListRowProps) => {
  const { dataTest = rootClass, row, updateRow, deleteRow, extraInfo } = props

  const { id, columns, type } = row

  const {
    userUpdates,
    values: { pushPolicy },
  } = useContext(DataManagementContext)

  const { connectorType } = useCRM()

  const { t } = useTranslation()

  const { fieldName, fieldType } = useMemo(() => {
    const actonContactsColumn = row.columns.find(({ column }) => column === t(CommonMappingColumn.ACTON_CONTACTS))
    const dataTypeColumn = row.columns.find(({ column }) => column === t(CommonMappingColumn.DATA_TYPE))
    if (actonContactsColumn?.staticValue) {
      return {
        fieldName: actonContactsColumn?.staticValue,
        fieldKey: actonContactsColumn.selected,
        fieldType: actonContactsColumn.dataType ?? dataTypeColumn?.selected,
      }
    } else {
      const value = actonContactsColumn?.values?.find(({ key }) => key === actonContactsColumn.selected)
      return { fieldName: value?.display, fieldKey: value?.value, fieldType: value?.dataType }
    }
  }, [row])

  const hasMappedCRM = useMemo(() => rowHasMappedCRM(row.columns), [row])

  const isScoreType = fieldType === SCORE_DATA_TYPE

  const isAllowedToPush = useMemo(() => {
    return !!pushPolicy?.enabledFields?.find((field) => field === fieldName) || isScoreType
  }, [pushPolicy, row])

  const onPushPolicyChange = (allow: boolean) => {
    if (fieldName && !isScoreType) {
      const currentEnabledFields = pushPolicy?.enabledFields ?? []
      const enabledFields = allow ? currentEnabledFields.concat(fieldName) : currentEnabledFields.filter((field) => field !== fieldName)
      userUpdates('pushPolicy', { ...pushPolicy, enabledFields })
    }
  }

  const renderSelect = ({ column = '', values = [], selected = '' }: DynamicListItems) => {
    const actualSelectValue = values.filter((listItem) => listItem.display === selected)
    return (
      <Select
        id={id.toString()}
        className={classNames({ [`${rootClass}__unmapped`]: selected === '-1' })}
        name={column}
        onChange={(event) => updateRow(id.toString(), event.target.name, event.target.value, extraInfo)}
        defaultValue={
          actualSelectValue.length > 0 ? (actualSelectValue[0].value ? actualSelectValue[0].value : actualSelectValue[0].display) : selected
        }
      >
        {values.map((listItem, index) => (
          <option key={`${listItem.display}-${row.id}-${index}`} disabled={listItem.disabled} value={listItem.value ?? listItem.key}>
            {listItem.display}
          </option>
        ))}
      </Select>
    )
  }

  const renderOptionColumn = (item: DynamicListItems) => {
    if (item.selected !== '-1') {
      return (
        <Tooltip
          key={`${item.column}-${row.id}`}
          triggerClassName={`${rootClass}__list-item-tooltip`}
          trigger={<div className={`${rootClass}__list-item`}>{item.values && renderSelect(item)}</div>}
        >
          {item.selected}
        </Tooltip>
      )
    } else {
      return (
        <div className={`${rootClass}__list-item`} key={`${item.column}-${row.id}`}>
          {item.values && renderSelect(item)}
        </div>
      )
    }
  }

  const renderIconColumn = (item: DynamicListItems) => {
    const icon = getIcon(item.icon, item.dupStatus, rootClass)
    return (
      <div data-test={`${dataTest}-status`} className={`${rootClass}__list-icon`} key={`${item.column}-${row.id}`}>
        <Tooltip trigger={<Svg name={icon.image} type={SvgType.SMALLER_ICON} className={icon.class} />}>{t(icon.tooltip)}</Tooltip>
      </div>
    )
  }

  const renderStaticColumn = (item: DynamicListItems) => (
    <div className={`${rootClass}__list-static`} key={`${item.column}-${row.id}`}>
      <Typography text={item.staticValue} />
    </div>
  )

  const renderToggleColumn = (item: DynamicListItems) => {
    const getTooltipText = () => {
      if (!hasMappedCRM) {
        return `Select a CRM field to enable the push.`
      } else if (isScoreType) {
        return `Score updates will always push to CRM if mapped to a CRM field.`
      } else if (isAllowedToPush) {
        return `Act-On can push changes to the CRM.`
      } else {
        return `{{crm}} will push to Act-On Contacts. Act-On will NOT push changes to the CRM.`
      }
    }

    return (
      <Tooltip
        key={`${item.column}-${row.id}`}
        dataTest={`${dataTest}-toggle`}
        triggerClassName={`${rootClass}__list-toggle`}
        trigger={<Toggle isOn={isAllowedToPush && hasMappedCRM} onToggle={onPushPolicyChange} large disabled={isScoreType || !hasMappedCRM} />}
      >
        {t(getTooltipText(), { crm: connectorType })}
      </Tooltip>
    )
  }

  const renderEditColumn = (item: DynamicListItems) => {
    return (
      <div className={`${rootClass}__list-edit`} key={`${item.column}-${row.id}`}>
        <Input
          placeholder={t('Destination Field')}
          defaultValue={item.staticValue}
          type={'edit'}
          name={item.column}
          onBlur={(event) => {
            if (event.currentTarget.value !== '') {
              updateRow(id.toString(), event.target.name, event.target.value, extraInfo)
            }
          }}
        />
      </div>
    )
  }

  const rowColumns = columns.map((item) => {
    const componentByColumnType: { [key in ColumnType]: (item: DynamicListItems) => ReactNode } = {
      [ColumnType.OPTION]: renderOptionColumn,
      [ColumnType.ICON]: renderIconColumn,
      [ColumnType.STATIC]: renderStaticColumn,
      [ColumnType.BLANK]: () => <div key={`${item.column}-${row.id}`} />,
      [ColumnType.ALLOW_PUSH]: renderToggleColumn,
      [ColumnType.EDIT]: renderEditColumn,
    }
    return componentByColumnType[item.type](item)
  })

  return (
    <div className={rootClass} data-test={dataTest}>
      {rowColumns}
      {id === NEW_EMPTY_ROW || type === RowType.REQUIRED ? (
        <div className={`${rootClass}__trash`} key={getUUID()} />
      ) : (
        <div className={`${rootClass}__trash`} key={`${id}-trash}`}>
          <button className={`${rootClass}__trash-button`} onClick={() => deleteRow(id, extraInfo)}>
            <Svg name={SvgNames.delete} type={SvgType.LARGER_ICON} className={`${rootClass}__trash-icon`} />
          </button>
        </div>
      )}
    </div>
  )
}

export default DynamicListRow
