import React, { useContext, useState } from 'react'

import classNames from 'classnames'

import { TableV2 } from '@components/TableV2/TableV2'
import { useTranslation } from '@const/globals'
import { List } from '@interface/foldersLists/List'
import StepContent from '@src/pages/ContactSegments/components/CopySegments/components/StepContent/StepContent'
import ModalHandler from '@src/pages/ContactSegments/components/CopySegments/components/StepTwo/components/ModalHandler/ModalHandler'
import { useColumns, useRowActions } from '@src/pages/ContactSegments/components/CopySegments/components/StepTwo/StepTwo.table.utils'
import { stepBaseText } from '@src/pages/ContactSegments/components/CopySegments/constants/CopySegments.constants'
import { CopySegmentsContext, Steps } from '@src/pages/ContactSegments/components/CopySegments/context/CopySegments.context'
import { stepProps } from '@src/pages/ContactSegments/components/CopySegments/utils/CopySegments.utils'

import './StepTwo.css'

export interface StepTwoState {
  segmentToRemove?: List
  showListPicker: boolean
}

const rootClass = 'step-two'
const stepTwoText = `${stepBaseText}Two.`

const StepTwo = () => {
  const {
    values: { segments },
  } = useContext(CopySegmentsContext)
  const [state, setState] = useState<StepTwoState>({ showListPicker: false })
  const { showListPicker, segmentToRemove } = state
  const { t } = useTranslation()
  const segmentsCount = segments.length
  const isTableEmpty = segmentsCount === 0
  const emptyRowData: List[] = [{ name: t(`${stepTwoText}EmptyRowData`) } as List]

  const onSelectSegmentsClick = () => setState(() => ({ ...state, showListPicker: !showListPicker }))

  const rowActions = useRowActions({ setState })

  const columns = useColumns()

  return (
    <>
      <ModalHandler setStepTwoState={setState} showListPicker={showListPicker} segmentToRemove={segmentToRemove} />
      <StepContent
        className={classNames(rootClass, { [`${rootClass}__table-empty`]: isTableEmpty })}
        data-test={rootClass}
        {...stepProps[Steps.TWO]}
        onClick={onSelectSegmentsClick}
      >
        <TableV2
          data={isTableEmpty ? emptyRowData : segments}
          enableSorting
          columns={columns}
          rowActions={isTableEmpty ? undefined : rowActions}
          withoutBorders
          showBottomBorder
        />
      </StepContent>
    </>
  )
}

export default StepTwo
