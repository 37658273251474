import { ApolloQueryResult } from '@apollo/client'
import { ListsState } from '@components/ListPicker/ListPicker'
import { FavoritesListsQuery, FolderListsQuery, FoldersWithCountQuery, SearchListsInfo, SearchListsQuery } from '@graphql/types/query-types'
import { Folder } from '@interface/foldersLists/Folder'
import { List } from '@interface/foldersLists/List'
import { SearchResults } from '@interface/foldersLists/Search'
import { logError } from '@utils/env'
import { CONTACTS_FOLDER_ID } from '@utils/folders'
import { ListCategory } from '@utils/lists'
import { getCountDisplay } from '@utils/numbers'

import { Props, State } from './ListPickerModal'

export interface LoadedLists {
  category: string
  folder: Folder
  list: List
  count: number
}

export type LoadedListsCollection = { [key: string]: LoadedLists }

export interface ListPickerModalUtil {
  addLoadedLists(lists: List[], loadedLists: LoadedListsCollection, category: string, folder: Folder): LoadedListsCollection
  addLoadedFoldersLists(folders: Folder[], loadedLists: LoadedListsCollection, category: string): LoadedListsCollection
  addSearchLoadedLists(search: SearchListsInfo, loadedLists: LoadedListsCollection, t: Function): LoadedListsCollection
  getTotalRecords(): string
  updateSelectedLists(selectedLists: string[]): void
  submitList(): void
  listChanged(listType: string): void
  doSearch(query?: string): void
  toggleSelectedLists(): void
  loadFoldersSuccess(data: ApolloQueryResult<FoldersWithCountQuery>, isUnifiedList: boolean): void
  loadFolderError(error: Error): void
  loadListFoldersSuccess(data: ApolloQueryResult<FavoritesListsQuery>): void
  loadListFoldersError(error: Error): void
  loadListsSuccess(data: ApolloQueryResult<FolderListsQuery>): void
  loadListsError(error: Error): void
  loadSearchSuccess(data: ApolloQueryResult<SearchListsQuery>): void
  loadSearchError(error: Error): void
  state(): State
}

export const getListPickerModalUtil = (props: Props, state: State, setState: (state: any) => void, t: Function): ListPickerModalUtil => {
  return {
    addLoadedLists: function (lists: List[], loadedLists: LoadedListsCollection, category: string, folder: Folder) {
      for (const list of lists) {
        loadedLists[list.id] = {
          category,
          folder: {
            ...folder,
            entries: [],
          },
          list: {
            ...list,
            children: [],
          },
          count: list.size ?? 0,
        }
        if (list.children) {
          loadedLists = this.addLoadedLists(list.children, loadedLists, category, folder)
        }
      }
      return loadedLists
    },
    addLoadedFoldersLists: function (folders: Folder[], loadedLists: LoadedListsCollection, category: string) {
      for (const folder of folders) {
        if (folder.entries) {
          loadedLists = this.addLoadedLists(folder.entries, loadedLists, category, folder)
        }
      }
      return loadedLists
    },
    addSearchLoadedLists: function (search: SearchListsInfo, loadedLists: LoadedListsCollection, t: Function) {
      if (search.contacts) {
        loadedLists = this.addLoadedLists(search.contacts, loadedLists, ListCategory.ACCOUNTS, { id: CONTACTS_FOLDER_ID, name: t('Contacts') } as any)
      }
      if (search.marketing) {
        loadedLists = this.addLoadedFoldersLists(search.marketing, loadedLists, ListCategory.MARKETING)
      }
      if (search.formSubmissions) {
        loadedLists = this.addLoadedFoldersLists(search.formSubmissions, loadedLists, ListCategory.FORM_SUBMISSIONS)
      }
      if (search.webinar) {
        loadedLists = this.addLoadedFoldersLists(search.webinar, loadedLists, ListCategory.WEBINAR)
      }
      if (search.unifiedLists) {
        loadedLists = this.addLoadedFoldersLists(search.unifiedLists, loadedLists, ListCategory.UNIFIED_LIST)
      }
      return loadedLists
    },
    getTotalRecords: function (): string {
      return getCountDisplay(
        state.selectedLists.reduce((acc, cur) => {
          return (state.loadedLists[cur].count ?? 0) + acc
        }, 0)
      )
    },
    updateSelectedLists: function (selectedLists: string[]) {
      if (selectedLists.length === 0) {
        setState({ ...state, selectedLists, actonClassicLists: [], unifiedLists: [] })
      } else if (state.loadedLists[selectedLists[0]] && state.loadedLists[selectedLists[0]].category === ListCategory.UNIFIED_LIST) {
        setState({
          ...state,
          selectedLists,
          unifiedLists: selectedLists,
        })
      } else {
        setState({
          ...state,
          selectedLists,
          actonClassicLists: selectedLists,
        })
      }
    },
    submitList: function () {
      const raw = state.selectedLists.map((listId) => state.loadedLists[listId].list)
      const uniq = [...new Set(raw)]
      props.submitLists(uniq)
    },
    listChanged: function (listType: string) {
      setState({
        ...state,
        listType: listType,
        folders: undefined,
      })
    },
    doSearch: function (query?: string) {
      if (!query || query.trim().length === 0) {
        return
      }
      const lowercaseQuery = query.toLocaleLowerCase()
      setState({
        ...state,
        search: {
          query: lowercaseQuery,
          loading: true,
        },
        viewingSelectedLists: false,
      })
    },
    toggleSelectedLists: function () {
      if (state.selectedLists.length === 0) {
        return
      }
      const searchResults: SearchResults = {
        count: state.selectedLists.length ?? 0,
      }
      if (!state.viewingSelectedLists) {
        const categories: { [key: string]: { [key: string]: { folder: Folder; lists: List[] } } } = {}

        for (const list of state.selectedLists) {
          const loadedList = state.loadedLists[list]
          if (loadedList.category === ListCategory.ACCOUNTS) {
            if (!searchResults.contacts) {
              searchResults.contacts = []
            }
            searchResults.contacts?.push(loadedList.list)
          } else {
            if (!categories[loadedList.category]) {
              categories[loadedList.category] = {}
            }
            if (!categories[loadedList.category][loadedList.folder.id]) {
              categories[loadedList.category][loadedList.folder.id] = {
                folder: loadedList.folder,
                lists: [],
              }
            }
            categories[loadedList.category][loadedList.folder.id].lists.push(loadedList.list)
          }
        }

        if (categories[ListCategory.MARKETING]) {
          if (!searchResults.marketing) {
            searchResults.marketing = []
          }
          for (const key of Object.keys(categories[ListCategory.MARKETING])) {
            const folderLists = categories[ListCategory.MARKETING][key]
            searchResults.marketing.push({
              ...folderLists.folder,
              entries: folderLists.lists,
            })
          }
        }
        if (categories[ListCategory.FORM_SUBMISSIONS]) {
          if (!searchResults.formSubmissions) {
            searchResults.formSubmissions = []
          }
          for (const key of Object.keys(categories[ListCategory.FORM_SUBMISSIONS])) {
            const folderLists = categories[ListCategory.FORM_SUBMISSIONS][key]
            searchResults.formSubmissions.push({
              ...folderLists.folder,
              entries: folderLists.lists,
            })
          }
        }
        if (categories[ListCategory.WEBINAR]) {
          if (!searchResults.webinar) {
            searchResults.webinar = []
          }
          for (const key of Object.keys(categories[ListCategory.WEBINAR])) {
            const folderLists = categories[ListCategory.WEBINAR][key]
            searchResults.webinar.push({
              ...folderLists.folder,
              entries: folderLists.lists,
            })
          }
        }
        if (categories[ListCategory.UNIFIED_LIST]) {
          if (!searchResults.unifiedLists) {
            searchResults.unifiedLists = []
          }
          for (const key of Object.keys(categories[ListCategory.UNIFIED_LIST])) {
            const folderLists = categories[ListCategory.UNIFIED_LIST][key]
            searchResults.unifiedLists.push({
              ...folderLists.folder,
              entries: folderLists.lists,
            })
          }
        }
      }

      setState({
        ...state,
        viewingSelectedLists: !state.viewingSelectedLists,
        selectedListsFolders: {
          search: searchResults,
        },
      })
    },
    loadFoldersSuccess: function (data: ApolloQueryResult<FoldersWithCountQuery>, isUnifiedList: boolean) {
      const unifiedListId = -4
      const currentFolder = isUnifiedList
        ? data.data.foldersWithCount.find((folder) => folder.id === unifiedListId)
        : data.data.foldersWithCount.find((folder) => folder.isDefault)
      let lists: ListsState | undefined = undefined
      let loadedLists = state.loadedLists
      if (currentFolder?.entries) {
        lists = {
          lists: currentFolder.entries,
        }
        loadedLists = this.addLoadedLists(currentFolder.entries, state.loadedLists, state.listType, t('Contacts'))
      }
      setState((state: State) => {
        return {
          ...state,
          folders: {
            folders: data.data.foldersWithCount,
          },
          lists,
          currentFolder: currentFolder,
          loadedLists,
        }
      })
    },
    loadFolderError: function (error: Error) {
      logError(error)
      setState((state: State) => {
        return {
          ...state,
          folders: {
            error: true,
          },
        }
      })
    },
    loadListFoldersSuccess: function (data: ApolloQueryResult<FavoritesListsQuery>) {
      setState((state: { loadedLists: LoadedListsCollection; listType: string }) => {
        return {
          ...state,
          listFolders: {
            folders: data.data.getFavoritesLists,
          },
          loadedLists: this.addLoadedFoldersLists(data.data.getFavoritesLists, state.loadedLists, state.listType),
        }
      })
    },
    loadListFoldersError: function (error: Error) {
      logError(error)
      setState((state: State) => {
        return {
          ...state,
          listFolders: {
            error: true,
          },
        }
      })
    },
    loadListsSuccess: function (data: ApolloQueryResult<FolderListsQuery>) {
      const lists = data.data.folderLists.filter((list) => !list.isExtension)
      setState((state: { currentFolder: Folder; loadedLists: LoadedListsCollection; listType: string }) => {
        return {
          ...state,
          lists: {
            lists,
          },
          loadedLists: state.currentFolder ? this.addLoadedLists(lists, state.loadedLists, state.listType, state.currentFolder) : state.loadedLists,
        }
      })
    },
    loadListsError: function (error: Error) {
      logError(error)
      setState((state: State) => {
        return {
          ...state,
          lists: {
            error: true,
          },
        }
      })
    },
    loadSearchSuccess: function (data: ApolloQueryResult<SearchListsQuery>) {
      const searchLists = data.data.searchLists as SearchListsInfo
      setState((state: { search: { query: any }; loadedLists: LoadedListsCollection }) => {
        return {
          ...state,
          search: {
            search: searchLists,
            query: state.search?.query ?? '',
          },
          loadedLists: this.addSearchLoadedLists(searchLists, state.loadedLists, t),
        }
      })
    },
    loadSearchError: function (error: Error) {
      logError(error)
      setState((state: { search: { query: any } }) => {
        return {
          ...state,
          search: {
            error: true,
            query: state.search?.query ?? '',
          },
        }
      })
    },
    state: function () {
      return state
    },
  }
}
