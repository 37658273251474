import React, { FC, useCallback, useEffect, useState } from 'react'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { useTranslation } from '@const/globals'
import removeFaviconUrl from '@graphql/mutations/removeFaviconUrl'
import setBrandingFavicon from '@graphql/mutations/setBrandingFavicon'
import getBrandingFavicon from '@graphql/queries/getBrandingFavicon'
import {
  MutationSetBrandingFaviconArgs,
  RemoveFaviconUrlMutation,
  RemoveFaviconUrlMutationVariables,
  SetBrandingFaviconMutation,
} from '@graphql/types/mutation-types'
import { GetBrandingFaviconQuery, GetBrandingFaviconQueryVariables } from '@graphql/types/query-types'
import { StatusToastType } from '@interface/StatusToast'
import FavIcon from '@src/pages/Content/Branding/tabs/WebIdentity/FavIcon/FavIcon'
import { logNewRelicError } from '@utils/new-relic.utils'

interface Props {
  setToastStatus: (value: StatusToastType) => void
}

const FavIconContainer: FC<Props> = ({ setToastStatus }) => {
  const { t } = useTranslation()
  const client = useApolloClient()
  const [url, setUrl] = useState<string>('')
  const [isDefault, setIsDefault] = useState<boolean>(false)

  const { data, loading, error, refetch } = useQuery<GetBrandingFaviconQuery, GetBrandingFaviconQueryVariables>(getBrandingFavicon, {
    client,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const [setMutation, { loading: loadingSet }] = useMutation<SetBrandingFaviconMutation, MutationSetBrandingFaviconArgs>(setBrandingFavicon, {
    client,
    fetchPolicy: 'no-cache',
  })

  const [removeFavIcon] = useMutation<RemoveFaviconUrlMutation, RemoveFaviconUrlMutationVariables>(removeFaviconUrl, {
    client,
    fetchPolicy: 'no-cache',
  })

  const handleRemoveFavIcon = useCallback(() => {
    removeFavIcon()
      .then(({ data }) => {
        if (data?.removeFaviconUrl?.status === 'ok') {
          setToastStatus({
            showStatus: true,
            title: t('Success!'),
            statusMessage: t('Set.FavIcon.Success.Message'),
            successStatus: true,
          })
          setIsDefault(true)
          setUrl(data?.removeFaviconUrl?.item?.url ?? '')
          refetch()
        } else {
          setToastStatus({
            showStatus: true,
            statusMessage: t('Something went wrong on our end. Please try again.'),
            successStatus: false,
          })
        }
      })
      .catch((error) => {
        logNewRelicError(error)
      })
  }, [t, removeFavIcon])

  const handleSetURL = useCallback<(url: string) => void>(
    (url) => {
      setMutation({
        variables: {
          url,
        },
      })
        .then(({ data }) => {
          if (data?.setBrandingFavicon?.status === 'ok') {
            setToastStatus({
              showStatus: true,
              title: t('Success!'),
              statusMessage: t('Set.FavIcon.Success.Message'),
              successStatus: true,
            })
            setUrl(data.setBrandingFavicon.item?.url ?? url)
            refetch()
          } else {
            setToastStatus({
              showStatus: true,
              statusMessage: t('Something went wrong on our end. Please try again.'),
              successStatus: false,
            })
          }
        })
        .catch((error) => {
          logNewRelicError(error)
        })
    },
    [t, setMutation]
  )
  if (error) {
    logNewRelicError(error)
  }

  useEffect(() => {
    const { item } = { ...data?.getBrandingFavicon }
    item?.url && setUrl(item.url)
    setIsDefault(!!item?.isDefault)
  }, [data])

  return <FavIcon loading={loading || loadingSet} url={url} isDefaultURL={isDefault} onSaveUrl={handleSetURL} onRemoveImage={handleRemoveFavIcon} />
}

export default FavIconContainer
