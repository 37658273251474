import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import { ButtonSize } from '@components/Button/Button'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './ContentExpander.css'

interface ContentExpanderProps {
  className?: string
  dataTest?: string
  onChange: (expand: boolean) => void
  expandButtonText?: string
  collapseButtonText?: string
}

const rootClass = 'content-expander'

const ContentExpander: FC<ContentExpanderProps> = (props: ContentExpanderProps) => {
  const { dataTest = rootClass, className = '', onChange, expandButtonText, collapseButtonText } = props

  const { t } = useTranslation()

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Button
        onClick={() => onChange(true)}
        className={`${rootClass}__nesting-controls`}
        buttonType={ButtonType.TERTIARY}
        buttonSize={ButtonSize.SMALL}
        iconPosition={ButtonIconPosition.LEFT}
        dataTest={`${dataTest}-expand-all`}
      >
        <Svg name={SvgNames.arrowExpand} type={SvgType.ICON} />
        <Typography text={expandButtonText ?? t('Expand all')} type={TextType.LINK} weight={TextWeight.MEDIUM} />
      </Button>
      <Button
        onClick={() => onChange(false)}
        className={`${rootClass}__nesting-controls`}
        buttonType={ButtonType.TERTIARY}
        buttonSize={ButtonSize.SMALL}
        iconPosition={ButtonIconPosition.LEFT}
        dataTest={`${dataTest}-collapse-all`}
      >
        <Svg name={SvgNames.arrowShrink} type={SvgType.ICON} />
        <Typography text={collapseButtonText ?? t('Collapse all')} type={TextType.LINK} weight={TextWeight.MEDIUM} />
      </Button>
    </div>
  )
}

export default ContentExpander
