import { ApolloClient } from '@apollo/client'
import getAccountDetails from '@graphql/microservices/chatgpt-email/getAccountDetails'
import getChatgptResponse from '@graphql/microservices/chatgpt-email/getChatgptResponse'
import {
  GetAccountDetailsQuery,
  GetAccountDetailsQueryVariables,
  GetChatgptResponseQuery,
  GetChatgptResponseQueryVariables,
  MaxTokens,
} from '@graphql/types/microservice/chatgpt-email-types'
import { LengthTypes } from '@src/pages/Content/Email/Editor/components/GenerativeEmailModal/GenerativeEmailModal.constants'

export const getChatgptResponseRequest = async (
  outputLength: LengthTypes,
  contentGenerated: boolean,
  trackedParameters: { key: string; value: string }[],
  client: ApolloClient<any>
) => {
  return await client.query<GetChatgptResponseQuery, GetChatgptResponseQueryVariables>({
    query: getChatgptResponse,
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    variables: {
      promptDefinition: {
        maxTokens: convertLengthToText(outputLength).toUpperCase() as MaxTokens,
        isRetry: contentGenerated,
        trackedParameters,
      },
    },
  })
}

export const getCompanyInfoRequest = async (client: ApolloClient<any>) => {
  return await client.query<GetAccountDetailsQuery, GetAccountDetailsQueryVariables>({
    query: getAccountDetails,
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  })
}

export const convertLengthToText = (raw: LengthTypes) => {
  switch (raw) {
    case LengthTypes.SHORT:
      return 'short'
    case LengthTypes.MEDIUM:
      return 'medium'
    case LengthTypes.LONG:
      return 'long'
    case LengthTypes.NONE:
      return 'none'
  }
}
