import React, { FC, useCallback, useMemo, useState } from 'react'

import Button, { ButtonType } from '@components/Button'
import { ModalBody, ModalFooter } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { UserResponse } from '@graphql/types/query-types'
import UserLaunchApprovalSelect from '@src/pages/Settings/Users/components/UserLaunchApprovalSelect/UserLaunchApprovalSelect'

interface Props {
  user?: UserResponse
  onSave: (optionOrUserId: string) => void
  onCancel: () => void
  dataTest?: string
}

const rootClass = 'launch-permission-modal'

const EditLaunchPermissionsModalContent: FC<Props> = (props: Props) => {
  const { user, onSave, onCancel, dataTest = rootClass } = props
  const { t } = useTranslation()
  const [isTouched, setIsTouched] = useState<boolean>(false)
  const [approvalOrAdminId, setApprovalOrAdminId] = useState<string | undefined>(undefined)
  const disableSave = useMemo<boolean>(() => !isTouched || !approvalOrAdminId, [isTouched, approvalOrAdminId])

  const handleSave = useCallback(() => onSave(approvalOrAdminId ?? ''), [onSave, approvalOrAdminId])

  const handleSelectApproval = useCallback((approvalOrAdminId?: string) => {
    setIsTouched(true)
    setApprovalOrAdminId(approvalOrAdminId)
  }, [])

  return (
    <>
      <ModalBody>
        <Typography
          text={t('Launch.Permissions.Modal.Description')}
          type={TextType.BODY_TEXT_LIGHT}
          dataTest={`${dataTest}-description`}
          className={`${rootClass}-description`}
        />
        <UserLaunchApprovalSelect
          onSubmit={handleSelectApproval}
          launchApproval={user?.launchApproval}
          launchApprovalId={user?.launchApprovalId}
          dataTest={`${dataTest}-launch`}
        />
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form}>
        <Button buttonType={ButtonType.TERTIARY} onClick={() => onCancel()} dataTest={`${dataTest}-cancel-button`}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} onClick={handleSave} disabled={disableSave} dataTest={`${dataTest}-save-button`}>
          {t('Save')}
        </Button>
      </ModalFooter>
    </>
  )
}

export default EditLaunchPermissionsModalContent
