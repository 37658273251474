import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { BooleanCriteriaType } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/BooleanCriteria/BooleanCriteria'
import { DateCriteriaType } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/DateCriteria/DateCriteria'
import { NumericCriteriaType } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/NumericCriteria/NumericCriteria'
import { TextCriteriaType } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/TextCriteria/TextCriteria'
import { ConditionType, TextComparison } from '@src/pages/SegmentComposer/SegmentComposer.constants'
import {
  DateRangeCondition,
  DateValueCondition,
  NumericValueCondition,
  TextListCondition,
  TextEmptyCondition,
  TextNotEmptyCondition,
  NumericRangeCondition,
} from '@src/pages/SegmentComposer/SegmentComposer.interfaces'

export interface ProfileExpression {
  condition:
    | TextListCondition
    | TextEmptyCondition
    | TextNotEmptyCondition
    | DateValueCondition
    | DateRangeCondition
    | NumericValueCondition
    | NumericRangeCondition
  field: string
  type: string
  id?: string
  parentId?: string
  subRows?: ProfileExpression[]
}

export interface NumberRange {
  rangeStart?: number
  rangeEnd?: number
}

export interface ProfileExpressionState {
  comparison?: SelectV2SingleOption
  field: SelectV2SingleOption
  expression: ProfileExpression
  numericCriteria: NumericCriteriaType
  textCriteria: TextCriteriaType
  dateCriteria?: DateCriteriaType
  booleanCriteria: BooleanCriteriaType
  numberValue?: number
  ignoreYear: boolean
}

export const defaultExpression: ProfileExpression = {
  condition: {
    type: ConditionType.TEXT_LIST,
    comparison: TextComparison.EQUALS,
    values: [],
  },
  field: '',
  type: 'profile',
}
