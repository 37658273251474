import { gql } from '@apollo/client'

export default gql`
  mutation deleteFromAddress($uuid: String!) {
    fromAddressDelete(uuid: $uuid) {
      status
      message
    }
  }
`
