import React, { useMemo, useRef } from 'react'

import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { t } from 'i18next'

import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { WebinarConfig } from '@graphql/types/query-types'
import {
  fontStyles,
  getPhoneInfo,
  renderAddToCalendar,
  renderAddToCalendarIcons,
  renderDetails,
} from '@src/pages/EmailComposer/utils/Addons/customAddons.helpers'
import { detectWebinarType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'

import { convertTo12HourFormat, getTimezoneAbbreviation, showWebinarButtonBasedOnType, useWebinarBlockConfig } from './utils/webinarBlock.utils'
import { ManageWebinarDetailsModalTabs, ManageWebinarDetailsModalToggleState } from '../../utils/ManageWebinarDetailsModal.utils'

dayjs.extend(utc)
dayjs.extend(timezone)

export interface WebinarDetailsFields {
  iconName: string
  mainText: string
  subText?: string
  isLink?: boolean
  largeIcon?: boolean
}

export type WebinarCustomFields = {
  webinarId: string
  passcode: string
  date: string
  time: string
  joinUrl: string
  calendarDownloadLink: string
  phoneDetails: { [k in string]: string }
  blockId: string
  meetingUrl: string
  webinarName: string
  joinButtonText: string
  registrationButtonText: string
  webinarDescription: string
  registrationDescription?: string
  registrationLink?: string
  startTime: string
  endTime: string
  timeZone: SelectV2SingleOption
  startDate: string
  endDate: string
  webinarStartTime: string
  webinarDurationMinutes: number
  hasRegistration: boolean
  toggleState?: ManageWebinarDetailsModalToggleState
  activeTab?: ManageWebinarDetailsModalTabs.REGISTRATION | ManageWebinarDetailsModalTabs.WEBINAR
  hasForm: boolean
  usingZoom: boolean
  usingWebex: boolean
  usingCitrix: boolean
  msgType: string

  defaultWebinarInfo: WebinarCustomFields
}

interface WebinarBlockProps {
  messageId: string
  accountId: string
  customFields: WebinarCustomFields
  webinarConfig?: WebinarConfig
}

export interface WebinarHtmlFields {
  type: string
  html: {
    html: string
  }
  customFields?: WebinarCustomFields
}

const rootClass = 'webinar-block'

export const webinarRestoreCodes = ['1402', '1404', '0702', '14120']
export const webinarRestoreDescriptions = [
  'Webinar deleted',
  'Header deleted',
  'Email Header deleted',
  'Email Header changed',
  'Email Footer deleted',
  'Email Footer changed',
  'Footer deleted',
  'Row deleted',
  'HTML deleted',
  'Column Deleted',
]

export const WebinarBlock = ({ customFields, accountId, webinarConfig }: WebinarBlockProps) => {
  const {
    webinarId,
    calendarDownloadLink,
    joinUrl,
    meetingUrl,
    passcode,
    phoneDetails,
    joinButtonText,
    registrationButtonText,
    webinarName = '',
    registrationDescription,
    webinarDescription,
    blockId = '',
    endTime = '',
    startDate,
    startTime = '',
    timeZone = { label: '', value: '' },
    toggleState,
    hasRegistration,
    registrationLink,
    hasForm,
  } = customFields

  const { isGoTo, isZoom, isMultiSession, isWebex } = detectWebinarType(webinarConfig)

  const defaultEnable = isWebex ? isMultiSession && !hasForm : undefined

  const fieldShouldBeeHidden = useRef(!defaultEnable ?? (isGoTo ? true : hasRegistration && !toggleState))
  const { showRegistrationButton, showJoinButton, showCalendarBlock, showPasscode, showWebinarId, showPhoneNumbers } =
    useWebinarBlockConfig(customFields, webinarConfig) ?? {}

  const webinarJoinUrl = joinUrl || meetingUrl
  const idAttribute = !hasRegistration ? 'no-registration' : 'registration'
  const checkForJoinButton = !!(showJoinButton && joinButtonText && webinarJoinUrl)
  const checkForRegistrationButton = !!(showRegistrationButton && registrationButtonText && registrationLink)
  const formattedDate = dayjs.utc(startDate).format('dddd, MMMM DD, YYYY')

  const { backgroundColor, border, buttonCheck, color } = showWebinarButtonBasedOnType({
    checkForRegistrationButton,
    checkForJoinButton,
    hasRegistration,
    hasForm,
    webinarConfig,
  })

  if (toggleState) {
    fieldShouldBeeHidden.current = false
  }

  const webinarDetails: WebinarDetailsFields[] = useMemo(() => {
    const detailsFields = {
      date: [
        {
          iconName: 'calendar',
          mainText: formattedDate,
          subText: `${convertTo12HourFormat(startTime)} - ${convertTo12HourFormat(endTime)} (${getTimezoneAbbreviation(startDate, timeZone.value)})`,
        },
      ],
      join: [{ iconName: 'webinar-outline', mainText: 'Join Online', subText: webinarJoinUrl, isLink: true }],
      hashtag: [{ iconName: 'hashtag', mainText: 'Webinar ID', subText: webinarId?.replace(/(\d{3})(\d{4})(\d{4})/, '$1 $2 $3') ?? '' }],
      passcode: [{ iconName: 'lock', mainText: 'Passcode', subText: passcode }],
    }

    return [
      ...(startDate && startTime && endTime ? detailsFields['date'] : []),
      ...(isGoTo ? (webinarId ? detailsFields['hashtag'] : []) : !fieldShouldBeeHidden.current && showWebinarId ? detailsFields['hashtag'] : []),
      ...(isGoTo ? (passcode ? detailsFields['passcode'] : []) : !fieldShouldBeeHidden.current && showPasscode ? detailsFields['passcode'] : []),
    ]
  }, [formattedDate, startTime, endTime, startDate, timeZone, webinarJoinUrl, webinarId, passcode, isGoTo, showWebinarId, showPasscode])

  const addToCalendarData = useMemo(
    () => ({
      details: {
        isWebinar: true,
        description: registrationDescription || webinarDescription,
        addVideoConferencing: false,
        startDate: new Date(`${formattedDate} ${convertTo12HourFormat(startTime)}`),
        endDate: new Date(`${formattedDate} ${convertTo12HourFormat(endTime)}`),
        endTime,
        eventName: webinarName,
        startTime,
        timeZone,
        joinUrl,
        webinarId,
        phoneDetails,
      },
    }),
    [registrationDescription, webinarDescription, formattedDate, startTime, endTime, webinarName, timeZone, joinUrl, webinarId, phoneDetails]
  )

  return (
    <div id={rootClass} style={{ textAlign: 'left', padding: '10px' }}>
      <h1 style={{ margin: 0, textAlign: 'left', marginBottom: '10px', fontSize: '24px', wordWrap: 'break-word' }}>{webinarName}</h1>
      {renderDetails(webinarDetails, idAttribute)}
      {webinarDescription && (
        <div id={`${idAttribute}-description`} style={{ padding: '16px 0px 20px 3px' }}>
          <p style={{ ...fontStyles, display: 'inline-block', wordWrap: 'break-word', fontWeight: '400', textAlign: 'left' }}>{webinarDescription}</p>
        </div>
      )}

      {hasRegistration && showCalendarBlock && (
        <>
          {renderAddToCalendar(calendarDownloadLink)}
          <table id={`${idAttribute}-add-to-calendar-icons`} style={{ borderCollapse: 'separate', borderSpacing: '10px 10px', margin: '0 auto' }}>
            <tbody>
              <tr>{renderAddToCalendarIcons(addToCalendarData, accountId, blockId, calendarDownloadLink)}</tr>
            </tbody>
          </table>
        </>
      )}

      {hasRegistration && registrationDescription && (
        <div id={`${idAttribute}-description`} style={{ padding: '16px 0px 20px 3px' }}>
          <p style={{ ...fontStyles, display: 'inline-block', wordWrap: 'break-word', fontWeight: '400', textAlign: 'left' }}>
            {registrationDescription}
          </p>
        </div>
      )}

      {buttonCheck && (
        <div id={`${idAttribute}-interact-button`} style={{ marginTop: '10px', marginBottom: '12px' }}>
          <a
            style={{
              display: 'block',
              textAlign: 'center',
              textDecoration: 'none',
              padding: '10px 24px',
              borderRadius: '6px',
              fontSize: '16px',
              fontWeight: '600',
              color,
              border,
              backgroundColor,
            }}
            href={showRegistrationButton ? registrationLink : webinarJoinUrl}
            target={'_blank'}
            rel="noreferrer"
          >
            {t(showRegistrationButton ? registrationButtonText : joinButtonText)}
          </a>
        </div>
      )}

      {isZoom && showPhoneNumbers && getPhoneInfo(phoneDetails, showCalendarBlock && !hasRegistration)}

      {!hasRegistration && showCalendarBlock && (
        <>
          {renderAddToCalendar(calendarDownloadLink)}
          <table id={`${idAttribute}-add-to-calendar-icons`} align="center" style={{ borderCollapse: 'separate', borderSpacing: '10px 10px' }}>
            <tbody>
              <tr>{renderAddToCalendarIcons(addToCalendarData, accountId, blockId, calendarDownloadLink)}</tr>
            </tbody>
          </table>
        </>
      )}
    </div>
  )
}
