import { TFunction } from 'i18next'

import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'

export const getSMSStatusOptions = (t: TFunction): SelectV2SingleOption[] => [
  {
    label: t('Has a valid phone number'),
    value: 'HAS_VALID_PHONE_NUMBER',
  },
  {
    label: t('Does not have a valid phone number'),
    value: 'DOES_NOT_HAVE_VALID_PHONE_NUMBER',
  },
  {
    label: t('Is opted in to SMS'),
    value: 'IS_OPTED_IN_TO_SMS',
  },
  {
    label: t('Is opted out of SMS'),
    value: 'IS_OPTED_OUT_OF_SMS',
  },
  {
    label: t('Is not opted in to SMS'),
    value: 'IS_NOT_OPTED_IN_TO_SMS',
  },
  {
    label: t('Is not opted out of SMS'),
    value: 'IS_NOT_OPTED_OUT_OF_SMS',
  },
  {
    label: t('Phone number is in'),
    value: 'PHONE_NUMBER_IS_IN',
    extraOptions: { nextConditionType: 'COUNTRY' },
  },
  {
    label: t('Phone number is not in'),
    value: 'PHONE_NUMBER_IS_NOT_IN',
    extraOptions: { nextConditionType: 'COUNTRY' },
  },
]
