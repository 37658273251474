import { useApolloClient } from '@apollo/client'
import getItem from '@graphql/microservices/categorization/getItem'
import deletePrograms from '@graphql/mutations/deletePrograms'
import runProgramNow from '@graphql/mutations/runProgramNow'
import saveListMaintenanceProgram from '@graphql/mutations/saveListMaintenanceProgram'
import { GetItemQuery, GetItemQueryVariables } from '@graphql/types/microservice/categorization-types'
import {
  DeleteProgramsMutation,
  DeleteProgramsMutationVariables,
  ItemDto,
  ListMaintenanceProgramInput,
  RunProgramNowMutation,
  RunProgramNowMutationVariables,
  SaveListMaintenanceProgramMutation,
  SaveListMaintenanceProgramMutationVariables,
} from '@graphql/types/mutation-types'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export interface ListMaintenanceProgramsListingPageRequests {
  createProgramRequest: (program: ListMaintenanceProgramInput) => Promise<string>
  deleteProgramsRequest: (programs: ItemDto[]) => FetchPromise<DeleteProgramsMutation>
  getProgramRequest: (programId: string | number) => FetchPromise<GetItemQuery>
  runProgramNowRequest: (externalId: string) => FetchPromise<RunProgramNowMutation>
}

export const useListMaintenanceProgramsListingPageRequests = (): ListMaintenanceProgramsListingPageRequests => {
  const actonClient = useApolloClient()

  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })

  const { timeZoneId } = useAccountSettings()

  const createProgramRequest = (program: ListMaintenanceProgramInput) => {
    return actonClient
      .mutate<SaveListMaintenanceProgramMutation, SaveListMaintenanceProgramMutationVariables>({
        mutation: saveListMaintenanceProgram,
        variables: {
          program: { ...program, timeZoneId },
        },
      })
      .then(({ data }) => {
        const { status = '', message = '' } = { ...data?.saveListMaintenanceProgram }
        return status === 'success' && message.includes(':') ? message.split(':')[1].trim() : ''
      })
  }

  const deleteProgramsRequest = (programs: ItemDto[]) => {
    const items: ItemDto[] = programs.map(({ id, externalId }) => ({ id, externalId }))
    return actonClient.mutate<DeleteProgramsMutation, DeleteProgramsMutationVariables>({
      mutation: deletePrograms,
      variables: {
        items,
      },
    })
  }

  const getProgramRequest = (programId: string | number) => {
    const variables = typeof programId === 'string' ? { externalId: programId } : { itemId: programId }
    return categorizationClient.query<GetItemQuery, GetItemQueryVariables>({
      query: getItem,
      fetchPolicy: 'network-only',
      variables: {
        ...variables,
        type: ItemType.LIST_MAINTENANCE_PROGRAM,
      },
    })
  }

  const runProgramNowRequest = (externalId: string) => {
    return actonClient.mutate<RunProgramNowMutation, RunProgramNowMutationVariables>({
      mutation: runProgramNow,
      variables: {
        programId: externalId,
      },
    })
  }

  return {
    createProgramRequest,
    deleteProgramsRequest,
    getProgramRequest,
    runProgramNowRequest,
  }
}
