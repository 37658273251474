import React, { FC, useState } from 'react'

import Button, { ButtonType } from '@components/Button'
import ClipboardCopy from '@components/ClipboardCopy/ClipboardCopy'
import InfoAction from '@components/InfoAction/InfoAction'
import Spinner, { LoaderSize } from '@components/Spinner/Spinner'
import Svg, { SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import SvgNames from '@components/Svg/SvgNames'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { API_ACCESS_KEYS_MORE_INFO_LINK } from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/APIAccessKeys/constants/apiAccessKeys.constants'

import './APIAccessKeys.css'

interface Props {
  clientId: string
  clientSecret: string
  createApiCredentials: () => void
  loading?: boolean
  dataTest?: string
}

const rootClass = 'api-access-keys'

const APIAccessKeys: FC<Props> = (props: Props) => {
  const { dataTest, clientId, clientSecret, loading = false, createApiCredentials } = props

  const [hideTooltip, setHideTooltip] = useState<boolean>(false)
  const { t } = useTranslation()

  const copyTooltipText = t('Copy to clipboard')

  const onCopy = () => {
    setHideTooltip(true)
    setTimeout(() => {
      setHideTooltip(false)
    }, 3500)
  }

  const getInfoActionMessage = () => {
    return (
      <div>
        <Typography className={`${rootClass}__info-message`} text={t('API.Access.Keys.Tab.Info.Message')} inline />
        <TextLink text={t('More info')} link={API_ACCESS_KEYS_MORE_INFO_LINK} size={TextLinkSize.LARGE} />
      </div>
    )
  }

  return (
    <div data-test={dataTest} className={rootClass}>
      {loading ? (
        <Spinner size={LoaderSize.MEDIUM} />
      ) : clientId && clientSecret ? (
        <>
          <InfoAction svgName={SvgNames.lightBulb} message={getInfoActionMessage()} className={`${rootClass}__info-action`} />
          <div className={`${rootClass}__content`}>
            <Typography text={t('Act-On Keys')} type={TextType.SECTION_HEADER} />
            <Typography text={t('Client ID')} className={`${rootClass}__label`} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.MEDIUM} />
            <Tooltip
              trigger={
                <ClipboardCopy
                  value={clientId}
                  text={clientId}
                  dataTest={`${rootClass}__client-id`}
                  hideIcon
                  textWeight={TextWeight.REGULAR}
                  textType={TextType.BODY_TEXT_LIGHT}
                  lineHeight={LineHeight.MEDIUM_LARGE}
                  tooltipPosition="right"
                  copyClicked={() => {
                    onCopy()
                  }}
                  className={`${rootClass}__copy`}
                />
              }
              hide={hideTooltip}
              position="right"
              align="center"
            >
              {copyTooltipText}
            </Tooltip>
            <Typography text={t('Client Secret')} className={`${rootClass}__label`} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.MEDIUM} />
            <Tooltip
              trigger={
                <ClipboardCopy
                  value={clientSecret}
                  text={clientSecret}
                  dataTest={`${rootClass}__client-secret`}
                  hideIcon
                  textWeight={TextWeight.REGULAR}
                  textType={TextType.BODY_TEXT_LIGHT}
                  lineHeight={LineHeight.MEDIUM_LARGE}
                  tooltipPosition="right"
                  copyClicked={() => {
                    onCopy()
                  }}
                  className={`${rootClass}__copy`}
                />
              }
              hide={hideTooltip}
              position="right"
              align="center"
            >
              {copyTooltipText}
            </Tooltip>
          </div>
        </>
      ) : (
        <div className={`${rootClass}__generate-key-content`}>
          <Svg className={`${rootClass}__shield-icon`} name={SvgNames.shieldKeyLock} type={SvgType.EXTRA_LARGE_ICON} />
          <Typography
            text={t('API.Access.Keys.Tab.Generate.Credentials.Message')}
            weight={TextWeight.MEDIUM}
            lineHeight={LineHeight.LARGE}
            type={TextType.SECTION_HEADER}
          />
          <div className={`${rootClass}__key-info`}>
            <Typography text={t('API.Access.Keys.Tab.Generate.Credentials.Info.Message')} type={TextType.BODY_TEXT_GRAY} inline />
            <a href={API_ACCESS_KEYS_MORE_INFO_LINK} target="_blank" rel="noreferrer">
              <Svg name={SvgNames.externalLinkSelected} type={SvgType.ICON} />
            </a>
          </div>
          <Button buttonType={ButtonType.DEFAULT} className={`${rootClass}__generate-key-button`} onClick={createApiCredentials} fullWidth>
            <Svg name={SvgNames.generate} type={SvgType.ICON} fill={SvgColor.TEXT_TEAL} />
            <Typography text={t('API.Access.Keys.Tab.Generate')} type={TextType.NORMAL_TEXT_TEAL_LARGE} weight={TextWeight.MEDIUM} inline />
          </Button>
          <Typography text={t('API.Access.Keys.Tab.Warning')} type={TextType.NORMAL_TEXT_TEAL_LARGE} />
        </div>
      )}
    </div>
  )
}

export default APIAccessKeys
