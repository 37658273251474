import React, { FC } from 'react'

import classNames from 'classnames'

import Container from '@components/Container'
import { MIN_PAGINATION_SIZE } from '@components/TableV2/components/Pagination/TableV2Pagination'
import { TableV2 } from '@components/TableV2/TableV2'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { OptInForm } from '@graphql/types/microservice/sms-management-types'
import { SortByState } from '@utils/sms.utils'

import { formOptInsColumns } from './FormOptInsLists.utils'

import './FormOptInsLists.css'

interface Props {
  lists: OptInForm[]
  pageSize: number
  pageIndex: number
  totalCount: number
  sortBy: SortByState[]
  loading: boolean
  fetchData: (pageIndex: number, pageSize: number) => void
  onChangeSort: (sortBy: SortByState[]) => void
  dataTest?: string
}

export const rootClassFormOptIns = 'form-optIns-lists'
const MIN_ROWS_FOR_OVERFLOW = 5

export const FormOptInsLists: FC<Props> = ({
  lists,
  pageIndex,
  pageSize,
  totalCount,
  sortBy,
  fetchData,
  onChangeSort,
  loading,
  dataTest = rootClassFormOptIns,
}: Props) => {
  const { t } = useTranslation()

  const hasOverflow = lists.length > MIN_ROWS_FOR_OVERFLOW

  return (
    <Container
      className={classNames(rootClassFormOptIns, {
        [`${rootClassFormOptIns}__has-overflow`]: hasOverflow,
      })}
      noSidePadding
      noBottomPadding
      data-test={dataTest}
    >
      <Typography text={'Form Opt-Ins'} type={TextType.SECTION_HEADER} className={`${rootClassFormOptIns}__headline`} weight={TextWeight.MEDIUM} />

      <TableV2
        loading={loading}
        data={lists}
        columns={formOptInsColumns}
        sortingBy={sortBy}
        manualSorting={true}
        withoutBorders={true}
        enableInsideLoader={true}
        enableBodyHeightComputation={true}
        enablePaginate={totalCount > MIN_PAGINATION_SIZE}
        fetchData={fetchData}
        onSort={onChangeSort}
        paginationState={{
          pageSize: pageSize,
          pageIndex: pageIndex,
          controlledPageCount: Math.ceil(totalCount / pageSize),
        }}
        emptyState={{
          text: t('No Form Opt-Ins have been added yet.'),
          withoutBorder: true,
        }}
      />
    </Container>
  )
}
