import { gql } from '@apollo/client'

export default gql`
  query getClickthroughLinks($pageId: String!) {
    getClickthroughLinks(pageId: $pageId) {
      code
      name
      link
    }
  }
`
