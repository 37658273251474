import { getIsListSegment } from '@utils/program/program'

import { State } from './ConditionalSelect'

export interface ConditionalSelectUtils {
  messageChange(index: number, event: React.ChangeEvent<HTMLSelectElement>): void
  segmentChange(segment: string): void
  addMessage(): void
  deleteMessage(index: number): void
}

export const getConditionalSelectUtils = (state: State, setState: (state: State) => void) => ({
  messageChange: function (index: number, event: React.ChangeEvent<HTMLSelectElement>) {
    setState({
      ...state,
      newSegment: true,
      messageIds: [...state.messageIds.slice(0, index), event.target.value, ...state.messageIds.slice(index + 1, state.messageIds.length)],
    })
  },
  segmentChange: function (segment: string) {
    if (getIsListSegment(segment)) {
      setState({
        ...state,
        segment,
      })
    } else {
      setState({
        ...state,
        segment,
        messageIds: state.messageIds ?? [''],
        newSegment: true,
      })
    }
  },
  addMessage: function () {
    const messageIds = Array.isArray(state.messageIds) ? [...state.messageIds] : []
    messageIds.push('')
    setState({
      ...state,
      messageIds: messageIds,
      newSegment: true,
    })
  },
  deleteMessage: function (index: number) {
    const messageIds = state.messageIds
    setState({
      ...state,
      messageIds: [...messageIds.slice(0, index), ...messageIds.slice(index + 1, messageIds.length)],
      newSegment: true,
    })
  },
})
