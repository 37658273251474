import React, { FC } from 'react'

import classNames from 'classnames'

import { ApolloError } from '@apollo/client'
import { ColumnChartProps } from '@components/ColumnChart/ColumnChart'
import InfoCard, { CardsOptions } from '@components/InfoCard/InfoCard'
import { TableProps } from '@components/Table/Table'
import withLoadingAndError from '@hoc/withLoadingAndError/withLoadingAndError'
import EmailSMSEmpty from '@src/pages/programs/dashboard/components/EmailSMSPerformance/components/EmailSMSEmpty/EmailSMSEmpty'
import EmailSMSHeader from '@src/pages/programs/dashboard/components/EmailSMSPerformance/components/EmailSMSHeader/EmailSMSHeader'
import EmailSMSPerformanceDetail from '@src/pages/programs/dashboard/components/EmailSMSPerformance/components/EmailSMSPerformanceDetail/EmailSMSPerformanceDetail'
import { ChartExtraElementProps } from '@src/pages/programs/dashboard/components/ProgramPerformance/components/ChartExtraElement/ChartExtraElement'
import { SortByState } from '@utils/sms.utils'

import './EmailSMSPerformance.css'

export type EmailSMSPerformanceProps = ColumnChartProps &
  TableProps &
  ChartExtraElementProps & {
    enableTableV2?: boolean
    setEnableTableV2?: React.Dispatch<React.SetStateAction<boolean>>
    lastUpdated: number
    onRefresh: () => void
    messageReport?: () => void
    recipientReport?: () => void
    emailPerformance: boolean
    cardsOptions?: CardsOptions
    pageSize: number | 10
    pageIndex: number | 0
    sortBy: SortByState[]
    title: string
    tableTitle: string
    tableSubTitle?: string
    showEmptyMessage: boolean
    addMessage: () => void
    openReport?: (row: any) => void
    loading: boolean
    pageError?: ApolloError
    chartLoading?: boolean
    helpMessage?: {
      text: string
      url: string
    }
  }

const rootClass = 'email-sms-performance'

const EmailSMSPerformance: FC<EmailSMSPerformanceProps> = (props: EmailSMSPerformanceProps) => {
  const {
    dataTest = rootClass,
    className = '',
    lastUpdated,
    onRefresh,
    messageReport,
    recipientReport,
    emailPerformance = false,
    cardsOptions,
    showEmptyMessage,
    openReport,
    pageSize,
    pageIndex,
    sortBy,
    chartLoading = false,
    helpMessage,
    enableTableV2,
    setEnableTableV2,
  } = props

  const chartExtraElementProps: ChartExtraElementProps = { ...props }
  const chartProps: ColumnChartProps = { ...props }
  const tableProps: TableProps = { ...props, onRowClicked: openReport, className }

  if (showEmptyMessage) {
    return <EmailSMSEmpty helpMessage={helpMessage} />
  }

  const handleMessageReport = () => {
    if (messageReport) {
      messageReport()
    }
  }

  const handleRecipientReport = () => {
    if (recipientReport) {
      recipientReport()
    }
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <EmailSMSHeader
        lastUpdated={lastUpdated}
        onRefresh={onRefresh}
        messageReport={handleMessageReport}
        recipientReport={handleRecipientReport}
        emailPerformance={emailPerformance}
      />
      <div className={`${rootClass}__tabs-container`}>{cardsOptions && <InfoCard cardsOptions={cardsOptions} />}</div>
      <EmailSMSPerformanceDetail
        loading={chartLoading}
        {...chartProps}
        {...tableProps}
        {...chartExtraElementProps}
        pageSize={pageSize}
        pageIndex={pageIndex}
        sortBy={sortBy}
        enableTableV2={enableTableV2}
        setEnableTableV2={setEnableTableV2}
      />
    </div>
  )
}

export default withLoadingAndError(EmailSMSPerformance)
