import windowUtils from '@utils/window'

export const UI_THEME = 'ui-theme'

export enum THEME {
  LIGHT = 'smart-style-1',
  DARK = 'ao-dark-theme',
  CUSTOM = 'smart-style-c',
}

export function getThemeFromValue(value: string) {
  if (value === THEME.DARK) {
    return THEME.DARK
  } else if (value === THEME.CUSTOM) {
    return THEME.CUSTOM
  }
  return THEME.LIGHT
}

export function getTheme(): THEME {
  const theme = windowUtils.getLocalStorageItem(UI_THEME)
  if (theme) {
    return getThemeFromValue(theme)
  }

  return THEME.LIGHT
}

export function setTheme(theme: THEME) {
  windowUtils.setLocalStorageItem(UI_THEME, theme)
}
