import React, { FC, useState } from 'react'

import classNames from 'classnames'

import InfoAction from '@components/InfoAction/InfoAction'
import InfoStatus, { InfoStatusTypes } from '@components/InfoStatus/InfoStatus'
import { LabelType, LabelV2 } from '@components/LabelV2/LabelV2'
import { LinkTextButton, LinkTextButtonSize } from '@components/LinkTextButton/LinkTextButton'
import NumberBadge, { NumberBadgeType } from '@components/NumberBadge/NumberBadge'
import StaticImage from '@components/StaticImage/StaticImage'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { TableV2 } from '@components/TableV2/TableV2'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import Toggletip, { ToggletipTriggerSize } from '@components/Toggletip/Toggletip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { SpamTestResult, SpamTestResultReason } from '@graphql/types/query-types'
import { CheckCardStatus } from '@src/pages/EmailComposer/components/common/CheckStatusCard/CheckStatusCard.utils'
import { spamFiltersLink } from '@src/pages/EmailComposer/components/common/CheckStatusCard/CheckStatusModal/components/SpamCheckResults/constants/SpamCheckResultsConstants'
import { filterNotEmptyArray } from '@utils/array'

import './SpamCheckResults.css'

interface SpamCheckResultProps {
  status: CheckCardStatus
  issuesCount: number
  results?: SpamTestResult[]
  dataTest?: string
}

const rootClass = 'spam-check-results'

export const SpamCheckResults: FC<SpamCheckResultProps> = ({ issuesCount, status, results, dataTest = rootClass }) => {
  const { t } = useTranslation()
  const [selectedResult, setSelectedResult] = useState<SpamTestResult | undefined>(results ? results[0] : undefined)
  const isError = status === CheckCardStatus.ERROR || !results

  return (
    <div className={rootClass} data-test={dataTest}>
      <div className="push-up-x4">
        <div className="flex-align-center">
          {isError && <Svg name={SvgNames.spamCheckWarning} type={SvgType.LARGE_ICON} className="push-left-x2" />}
          <LabelV2
            labelType={LabelType.large}
            label={t('EmailComposer.Check.Status.Spam.Modal.Header', { context: status, count: issuesCount })}
            dataTest={`${dataTest}-title`}
          />
        </div>
        <Typography
          text={t('EmailComposer.Check.Status.Spam.Modal.Description', { context: status, count: issuesCount })}
          type={TextType.BODY_TEXT_LIGHT}
          tagComponents={{
            TextLink: <LinkTextButton hideIcons size={LinkTextButtonSize.MEDIUM} />,
          }}
          className="push-down-x2"
        />
      </div>
      <div className={`${rootClass}__content`} data-test={`${dataTest}-content`}>
        {isError ? (
          <div className={`${rootClass}__content__error`}>
            <StaticImage name={StaticImageNames.musubi} className="push-up-x4" />
            <Typography
              text={t('EmailComposer.Check.Status.Spam.Modal.Message.Header', { context: CheckCardStatus.ERROR })}
              type={TextType.MODAL_HEADER_SMALL}
              lineHeight={LineHeight.LARGE}
            />
            <Typography
              text={t('EmailComposer.Check.Status.Spam.Modal.Message.Text', { context: CheckCardStatus.ERROR })}
              type={TextType.BODY_TEXT_LIGHT}
              className="push-down-x2"
            />
          </div>
        ) : (
          <>
            <div className={`${rootClass}__sidebar`}>
              <LabelV2 labelType={LabelType.section} className={`${rootClass}__sidebar-title`} dataTest={`${dataTest}-sidebar-label`} withoutMargin>
                {t('Spam filters')}
              </LabelV2>
              <div className={`${rootClass}__sidebar-filters`}>
                {results.map((result) => (
                  <SpamFilter
                    key={result.appNameLong}
                    isSelected={selectedResult?.appNameLong === result.appNameLong}
                    onClick={() => setSelectedResult(result)}
                    dataTest={`${dataTest}-filter`}
                    {...result}
                  />
                ))}
              </div>
              <div className={classNames(`${rootClass}__sidebar-bottom`, 'flex-align-center')}>
                <StaticImage name={StaticImageNames.spamCheckDefault} dataTest={`${dataTest}-about-text-img`} />
                <LinkTextButton
                  hideIconLeft
                  text={t('EmailComposer.Check.Status.Spam.Modal.Sidebar.Link')}
                  size={LinkTextButtonSize.MEDIUM}
                  link={spamFiltersLink}
                  dataTest={`${dataTest}-about-text`}
                />
              </div>
            </div>
            {selectedResult && <SpamResultDetails {...selectedResult} dataTest={`${dataTest}-details`} />}
          </>
        )}
      </div>
    </div>
  )
}

interface SpamFilterProps extends SpamTestResult {
  onClick: () => void
  isSelected: boolean
  dataTest: string
}

const SpamFilter: FC<SpamFilterProps> = ({ onClick, isSelected, appNameLong, score, pass, dataTest }) => {
  const { t } = useTranslation()
  return (
    <button
      className={classNames(`${rootClass}__sidebar__filter`, { [`${rootClass}__sidebar__filter-selected`]: isSelected })}
      onClick={onClick}
      data-test={`${dataTest}-${appNameLong}`}
    >
      <Typography text={appNameLong} weight={TextWeight.MEDIUM} className={`${rootClass}__sidebar__filter-title`} />
      <Typography
        text={t('EmailComposer.Check.Status.Spam.Modal.Filter.Score', { score, context: pass ? 'pass' : 'fail' })}
        type={TextType.NORMAL_TEXT_GRAY}
        className={`${rootClass}__sidebar__filter-score`}
      />
      <Svg
        name={pass ? SvgNames.spamCheckSuccess : SvgNames.spamCheckFail}
        type={SvgType.LARGE_ICON}
        className={`${rootClass}__sidebar__filter-svg`}
      />
    </button>
  )
}

const columns: ColumnDefWithAdditionalProps<SpamTestResultReason>[] = [
  {
    header: 'Issue description',
    accessorKey: 'reason',
    textAlign: 'left',
    enableLongContent: true,
  },
  {
    header: 'Rating',
    accessorKey: 'rating',
    textAlign: 'right',
    maxSize: 100,
  },
]

const SpamResultDetails: FC<SpamTestResult & { dataTest: string }> = ({ appNameLong, pass, score, reasons, dataTest }) => {
  const { t } = useTranslation()
  const _reasons = reasons?.filter(filterNotEmptyArray)
  const reasonsLength = _reasons?.length ?? 0
  const showInfoAction = !pass || !!reasonsLength
  return (
    <div className={`${rootClass}__details`}>
      <div className="flex-align-center">
        <LabelV2 labelType={LabelType.large} label={appNameLong} withoutMargin dataTest={`${dataTest}-label`} className="push-left" />
        <Toggletip
          title={t('Overview')}
          description={
            <Typography
              text={t('EmailComposer.Check.Status.Spam.Modal.Result.Toogletip', { context: appNameLong })}
              tagProps={{ bold: { weight: TextWeight.BOLD } }}
              type={TextType.BODY_TEXT_WHITE}
              inline
            />
          }
          sideOffset={9}
          link={spamFiltersLink}
          linkText={t('EmailComposer.Check.Status.Spam.Modal.Result.Toogletip.Link', { context: appNameLong })}
          triggerSize={ToggletipTriggerSize.LARGE}
          isDismissable
        />
      </div>
      <div className="push-down-x2 push-up-x4">
        <Typography
          text={t(`EmailComposer.Check.Status.Spam.Modal.Result.Description.${pass ? 'Success' : 'Fail'}`, { score, context: appNameLong })}
          tagProps={{ bold: { weight: TextWeight.BOLD } }}
          type={TextType.BODY_TEXT_LIGHT}
          inline
        />
      </div>
      {showInfoAction &&
        (pass ? (
          <InfoAction
            svgName={SvgNames.lightBulb}
            message={<Typography text={t('EmailComposer.Check.Status.Spam.Modal.Result.Info.Pass')} />}
            dataTest={dataTest}
          />
        ) : (
          <InfoStatus
            status={InfoStatusTypes.Warning}
            svgName={SvgNames.cautionError}
            message={
              <Typography
                text={t('EmailComposer.Check.Status.Spam.Modal.Result.Info.Error')}
                tagProps={{ bold: { weight: TextWeight.BOLD } }}
                inline
              />
            }
            dataTest={dataTest}
          />
        ))}
      {!!reasonsLength && _reasons ? (
        <>
          <div className={classNames(`${rootClass}__details__table`, 'push-down-x4')} data-test={`${dataTest}-reasons`}>
            <div className={`${rootClass}__details__table-text`}>
              <div className={'flex-align-center flex-gap'}>
                <LabelV2 withoutMargin label={t('Issues found')} labelType={LabelType.standard} />
                {reasonsLength && (
                  <NumberBadge value={reasonsLength} type={NumberBadgeType.IMPORTANT} dataTest={`${dataTest}-reasons-count`} withoutXMargin />
                )}
              </div>
              <Typography
                text={t('EmailComposer.Check.Status.Spam.Modal.Result.Issues.Description')}
                type={TextType.BODY_TEXT_LIGHT}
                className="push-down-x2"
              />
            </div>
            <TableV2 data={_reasons} columns={columns} withoutBorders styles={{ minWidth: 'auto' }} />
          </div>
          <LinkTextButton
            text={t('EmailComposer.Check.Status.Spam.Modal.Result.Success.Link', { name: appNameLong })}
            hideIconLeft
            size={LinkTextButtonSize.MEDIUM}
            link={spamFiltersLink}
            className="push-down-x4"
          />
        </>
      ) : (
        <div className={`${rootClass}__details__success`} data-test={`${dataTest}-success`}>
          <StaticImage name={StaticImageNames.spamCheckSuccess} className="push-up-x4" />
          <Typography
            text={t('EmailComposer.Check.Status.Spam.Modal.Result.Success.Title', { name: appNameLong })}
            type={TextType.MODAL_HEADER_SMALL}
            lineHeight={LineHeight.LARGE}
          />
          <Typography
            text={t('EmailComposer.Check.Status.Spam.Modal.Result.Success.Text', { name: appNameLong })}
            type={TextType.BODY_TEXT_LIGHT}
            className="push-down-x2"
          />
          <LinkTextButton
            text={t('EmailComposer.Check.Status.Spam.Modal.Result.Success.Link', { name: appNameLong })}
            hideIconLeft
            size={LinkTextButtonSize.MEDIUM}
            link={spamFiltersLink}
            className="push-down-x2"
          />
        </div>
      )}
    </div>
  )
}
