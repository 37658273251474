import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import { FolderActions } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { Item } from '@components/ActionableNestedTable/components/NestedTableRowWithDnD/NestedTableRowWithDnD'
import Folder, { FolderData, FolderProps, FolderToggleProps } from '@components/SortableFolders/components/Folder/Folder'
import FolderWithDnD from '@components/SortableFolders/components/FolderWithDnD/FolderWithDnD'

import './SortableFolders.css'

interface Props {
  className?: string
  dataTest?: string
  activeFolder?: number
  folders: FolderData[]
  hideMoveItems?: boolean
  defaultOpenedFolders?: number[]
  /** Array of folders ids to be opened */
  hasToExpandFolders?: number[]
  /** Function to clear array of already opened folders */
  clearFoldersToExpand?: () => void
  onClick?: (folderId: number) => void
  toggleProps?: FolderToggleProps
  onActionClick?: (folder: FolderData, action: FolderActions) => void
  onDrop?: (item: Item, folder: FolderData) => void
}

const rootClass = 'sortable-folders'

const SortableFolders: FC<Props> = (props: Props) => {
  const {
    activeFolder,
    defaultOpenedFolders,
    folders,
    hideMoveItems = false,
    onClick,
    onActionClick,
    dataTest = rootClass,
    onDrop,
    hasToExpandFolders = [],
    clearFoldersToExpand,
    toggleProps,
    className = '',
  } = props
  const [openedFolders, setOpenedFolders] = useState<number[]>(defaultOpenedFolders || [])

  useEffect(() => {
    if (hasToExpandFolders.length > 0 && clearFoldersToExpand) {
      setOpenedFolders([...new Set([...openedFolders, ...hasToExpandFolders])])
      clearFoldersToExpand()
    }
  }, [hasToExpandFolders])

  const handleFolderClick = (activeFolderId: number) => onClick && onClick(activeFolderId)

  const handleCaretClick = ({ id }: FolderData) => {
    if (openedFolders.includes(id)) {
      setOpenedFolders(openedFolders.filter((folderId) => folderId !== id))
    } else {
      setOpenedFolders([...openedFolders, id])
    }
  }

  const renderFolders = (folders: FolderData[]) => {
    return folders.map(({ id, subFolders, ...folder }) => {
      const isOpen = openedFolders.some((folderId) => folderId === id)
      const renderSubFolders = subFolders && isOpen ? renderFolders(subFolders) : undefined

      const folderProps: FolderProps & { key: any } = {
        key: id,
        folder: { id, subFolders, ...folder },
        activeFolderId: activeFolder,
        handleFolderClick,
        onActionClick,
        isOpen,
        onCaretClick: handleCaretClick,
        toggleProps,
        hideMoveItems,
      }

      return onDrop && !hideMoveItems ? (
        <FolderWithDnD onDrop={onDrop} {...folderProps}>
          {renderSubFolders}
        </FolderWithDnD>
      ) : (
        <Folder {...folderProps}>{renderSubFolders}</Folder>
      )
    })
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {renderFolders(folders)}
    </div>
  )
}

export default SortableFolders
