import React, { FC } from 'react'

import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import ModalHeaderV2 from '@components/Modal/components/ModalHeaderV2/ModalHeaderV2'
import { SplitButtonOptionsProps } from '@components/SplitButton/SplitButton'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import { ModalHeaderFormStyle } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

interface ImagePickerModalHeader {
  onBack: () => void
  onSearch: (term: string) => void
  searchTerm: string
  isReplaceFlow: boolean
  isLogos: boolean
  actions?: SplitButtonOptionsProps[]
  previewImageTitle?: string
  dataTest?: string
  showImagePickerBackButton?: boolean
}

const rootClass = 'image-picker-modal__header'

const ImagePickerModalHeader: FC<ImagePickerModalHeader> = ({
  onBack,
  onSearch,
  searchTerm,
  isReplaceFlow,
  isLogos,
  actions,
  previewImageTitle,
  dataTest = rootClass,
  showImagePickerBackButton,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <ModalHeaderV2
        headerType={ModalHeaderType.Form}
        headerText={
          <TextWithTooltipOnEllip
            typographyProps={{
              text: t(previewImageTitle ? 'Preview.Asset.Modal.Title' : isReplaceFlow ? 'Replace image' : 'Select image', {
                title: previewImageTitle,
              }),
              ...ModalHeaderFormStyle,
              className: `${rootClass}__title`,
              dataTest: `${dataTest}-title`,
            }}
          />
        }
        iconProps={{ hasBackButton: !!previewImageTitle || showImagePickerBackButton, onClick: onBack }}
        searchProps={
          !previewImageTitle
            ? { placeholder: t(isLogos ? 'Search logos' : 'Search images'), onChangeHandler: onSearch, incomingValue: searchTerm }
            : undefined
        }
        actionButtonProps={actions && !previewImageTitle ? { splitButton: actions } : undefined}
        className={rootClass}
      />
    </>
  )
}

export default ImagePickerModalHeader
