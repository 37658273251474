import React, { FC, useContext } from 'react'

import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import { SvgNames } from '@components/Svg'
import { useTranslation } from '@const/globals'
import { bytesToSize } from '@src/pages/EmailComposer/components/EmailComposerPreview/EmailComposerPreview.utils'
import { CommonComposerTab } from '@utils/composer/commonComposer/CommonComposer.context'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'

interface EmailSizeWarningModalProps {
  htmlSize: number
}

const EmailSizeWarningModal: FC<EmailSizeWarningModalProps> = (props: EmailSizeWarningModalProps) => {
  const {
    api: { update },
  } = useContext(EmailComposerContext)
  const { htmlSize = 0 } = props

  const { t } = useTranslation()

  return (
    <ConfirmationModal
      isYesNo
      isOpen
      titleIcon={SvgNames.warning}
      title={t('EmailComposer.EmailSizeWarningModal.Title')}
      body={t('EmailComposer.EmailSizeWarningModal.Body', { emailSize: bytesToSize(htmlSize) })}
      onAnswer={(answer) =>
        answer === YesNo.NO
          ? update({ emailTooLargeWarningDismissed: true, showEmailTooLargeWarning: false })
          : update({ isPreview: false, tab: CommonComposerTab.DESIGN })
      }
      noButtonText={t('EmailComposer.EmailSizeWarningModal.NoButtonText')}
      yesButtonText={t('EmailComposer.EmailSizeWarningModal.YesButtonText')}
    />
  )
}

export default EmailSizeWarningModal
