import { SortDropDownOption } from '@components/SortDropDown/sortDropDown.types'
import { ColumnSort } from '@tanstack/react-table'

export const sortOptions: SortDropDownOption[] = [
  {
    label: 'Last Updated',
    id: 'updated',
    descLabel: 'Newest first',
    ascLabel: 'Oldest first',
    descFirst: true,
  },
]

export const initialSelectedSort: ColumnSort = { id: 'updated', desc: true }
