import { gql } from '@apollo/client'

export default gql`
  mutation saveGlobalBcc($email: String) {
    saveGlobalBcc(email: $email) {
      statusCode
      reasonPhrase
    }
  }
`
