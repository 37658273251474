import Highcharts, {
  AlignValue,
  ChartEventsOptions,
  ChartOptions,
  ColorAxisOptions,
  CSSObject,
  ExportingOptions,
  LegendOptions,
  MapNavigationOptions,
  NavigationOptions,
  OptionsStackingValue,
  PaneOptions,
  PlotOptions,
  SeriesPieDataLabelsOptionsObject,
  SubtitleOptions,
  TitleOptions,
  TooltipOptions,
  VerticalAlignValue,
  XAxisOptions,
  YAxisLabelsOptions,
  YAxisOptions,
} from 'highcharts'

import { ApolloError } from '@apollo/client'
import { SwitchOptions } from '@components/Switch/Switch'
import { DateType, DEFAULT_DATE_QUANTITIES } from '@utils/date'
import { formatNumber } from '@utils/numbers'

export interface ChartFieldData {
  name: string
  y: number | null
  sliced?: boolean
  selected?: boolean
  color?: string
  radius?: string
  innerRadius?: string
  dataLabels?: { shadow?: boolean }
  custom?: {
    initialText: string
    imgSrc: string
    boldText: string
    secondText?: string
    secondImgSrc?: string
    secondBoldText?: string
  }
}

export interface ChartField {
  data: string | string[] | number | number[] | number[][] | ChartFieldData[] | MapSeriesData[]
  name?: string
  label?: string
  color?: string
  colorByPoint?: boolean
  size?: string
  showInLegend?: boolean
  innerSize?: string
}

export enum LegendLocation {
  TOP = 'top',
  BOTTOM = 'bottom',
  RIGHT = 'right',
}

export interface ChartProps {
  fields: ChartField[]
  title?: string
  chartType?: SwitchOptions
  subtitle?: string
  hideLegend?: boolean
  legendLocation?: LegendLocation
  tickInterval?: number
  tooltipValueSuffix?: string
  sharedTooltip?: boolean
  categories?: string[]
  ExtraElement?: React.ReactNode
  disablePrint?: boolean
  disableMenu?: boolean
  className?: string
  dataTest?: string
  loading?: boolean
  error?: ApolloError
  plotOptions?: PlotOptions
  pane?: PaneOptions
  noContainer?: boolean
  chartHeight?: number
  chartWidth?: number
  alignValue?: number
  horizontalAlign?: number
  tooltip?: TooltipOptions
  events?: ChartEventsOptions
  dataLabels?: SeriesPieDataLabelsOptionsObject
  axisLabelsStyle?: CSSObject
  xAxisOptions?: XAxisOptions
  yAxisLabels?: YAxisLabelsOptions
  legendOptions?: LegendOptions
  exportingOptions?: ExportingOptions
  chartMarginTop?: number
  showTicksOnEmptyData?: boolean
  hideXAxisLine?: boolean
}

export const chartStyleOptions = {
  fontFamily: 'Open Sans, Helvetica, Arial, sans-serif',
}

export const chartOptions: ChartOptions = {
  styledMode: false,
  style: chartStyleOptions,
}

export const mapChartOptions: ChartOptions = {
  marginLeft: 12,
  spacingTop: 10,
  spacingRight: 0,
  spacingLeft: 1,
  spacingBottom: 0,
  styledMode: false,
  style: chartStyleOptions,
}

export const stackedOptions = {
  stacking: 'normal' as OptionsStackingValue,
}

export const titleOptions: TitleOptions = {
  align: 'left' as AlignValue,
  margin: 30,
  style: {
    fontSize: '16px',
    color: '#444444',
    lineHeight: '24px',
  },
}

export const subTitleOptions: SubtitleOptions = {
  align: 'left' as AlignValue,
  style: {
    fontSize: '12px',
    color: '#444444',
    lineHeight: '20px',
    fontWeight: 'normal',
  },
}

const renderMenuItem = (icon: string, text: string) => {
  return `
    <div style="display:flex;align-items:center;padding:0 6px;">
        <img width="16" height="16" src='/app/static/image/${icon}.png' />
        <span style="margin-left:12px;font-size: 14px;color:#444;">${text}</span>
    </div>
  `
}

export const exportingOptionsWithIcons = (t: Function) => ({
  menuItemDefinitions: {
    printChart: {
      text: renderMenuItem('print', t('Print chart')),
      onclick: function () {
        // @ts-ignore: Unreachable code error
        this.print()
      },
    },
    downloadJPEG: {
      text: renderMenuItem('image', t('Download JPEG image')),
      onclick: function () {
        // @ts-ignore: Unreachable code error
        this.exportChart({
          type: 'image/jpeg',
        })
      },
    },
    downloadCSV: {
      text: renderMenuItem('csv-file', t('Download CSV')),
      onclick: function () {
        // @ts-ignore: Unreachable code error
        this.downloadCSV()
      },
    },
    downloadPDF: {
      text: renderMenuItem('download', t('Download PDF')),
      onclick: function () {
        // @ts-ignore: Unreachable code error
        this.exportChart({
          type: 'application/pdf',
        })
      },
    },
  },
  buttons: {
    contextButton: {
      useHTML: true,
      symbol:
        'url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSI0cHgiIHZpZXdCb3g9IjAgMCAxNiA0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IHNrZXRjaHRvb2wgNTYuMyAoMTAxMDEwKSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT4zRTI4RUM2My1FQUQ5LTRGOTAtOTk2QS04QjA5MjIwODg4RkM8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIHNrZXRjaHRvb2wuPC9kZXNjPgogICAgPGcgaWQ9IkNvbnRhY3QtUmVwb3J0LShUby1FbmdpbmVlcmluZykiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJDb250YWN0LVJlcG9ydC0vLVN1bW1hcnkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yNTAuMDAwMDAwLCAtOTEuMDAwMDAwKSI+CiAgICAgICAgICAgIDxnIGlkPSJPdmVyZmxvdy1NZW51X3N2ZyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjUwLjAwMDAwMCwgODUuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiB4PSIwIiB5PSIwIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiPjwvcmVjdD4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0yLDEwIEMwLjg5NTQzMDUsMTAgMCw5LjEwNDU2OTUgMCw4IEMwLDYuODk1NDMwNSAwLjg5NTQzMDUsNiAyLDYgQzMuMTA0NTY5NSw2IDQsNi44OTU0MzA1IDQsOCBDNCw5LjEwNDU2OTUgMy4xMDQ1Njk1LDEwIDIsMTAgWiBNOCwxMCBDNi44OTU0MzA1LDEwIDYsOS4xMDQ1Njk1IDYsOCBDNiw2Ljg5NTQzMDUgNi44OTU0MzA1LDYgOCw2IEM5LjEwNDU2OTUsNiAxMCw2Ljg5NTQzMDUgMTAsOCBDMTAsOS4xMDQ1Njk1IDkuMTA0NTY5NSwxMCA4LDEwIFogTTE0LDEwIEMxMi44OTU0MzA1LDEwIDEyLDkuMTA0NTY5NSAxMiw4IEMxMiw2Ljg5NTQzMDUgMTIuODk1NDMwNSw2IDE0LDYgQzE1LjEwNDU2OTUsNiAxNiw2Ljg5NTQzMDUgMTYsOCBDMTYsOS4xMDQ1Njk1IDE1LjEwNDU2OTUsMTAgMTQsMTAgWiIgaWQ9IkZpbGwiIGZpbGw9IiM0NDQ0NDQiPjwvcGF0aD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+)',
      y: 8,
      menuItems: ['printChart', 'downloadJPEG', 'downloadCSV', 'downloadPDF'],
    },
  },
})

export const defaultExportingOptions: ExportingOptions = {
  buttons: {
    contextButton: {
      symbol:
        'url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSI0cHgiIHZpZXdCb3g9IjAgMCAxNiA0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IHNrZXRjaHRvb2wgNTYuMyAoMTAxMDEwKSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT4zRTI4RUM2My1FQUQ5LTRGOTAtOTk2QS04QjA5MjIwODg4RkM8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIHNrZXRjaHRvb2wuPC9kZXNjPgogICAgPGcgaWQ9IkNvbnRhY3QtUmVwb3J0LShUby1FbmdpbmVlcmluZykiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJDb250YWN0LVJlcG9ydC0vLVN1bW1hcnkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yNTAuMDAwMDAwLCAtOTEuMDAwMDAwKSI+CiAgICAgICAgICAgIDxnIGlkPSJPdmVyZmxvdy1NZW51X3N2ZyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjUwLjAwMDAwMCwgODUuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiB4PSIwIiB5PSIwIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiPjwvcmVjdD4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0yLDEwIEMwLjg5NTQzMDUsMTAgMCw5LjEwNDU2OTUgMCw4IEMwLDYuODk1NDMwNSAwLjg5NTQzMDUsNiAyLDYgQzMuMTA0NTY5NSw2IDQsNi44OTU0MzA1IDQsOCBDNCw5LjEwNDU2OTUgMy4xMDQ1Njk1LDEwIDIsMTAgWiBNOCwxMCBDNi44OTU0MzA1LDEwIDYsOS4xMDQ1Njk1IDYsOCBDNiw2Ljg5NTQzMDUgNi44OTU0MzA1LDYgOCw2IEM5LjEwNDU2OTUsNiAxMCw2Ljg5NTQzMDUgMTAsOCBDMTAsOS4xMDQ1Njk1IDkuMTA0NTY5NSwxMCA4LDEwIFogTTE0LDEwIEMxMi44OTU0MzA1LDEwIDEyLDkuMTA0NTY5NSAxMiw4IEMxMiw2Ljg5NTQzMDUgMTIuODk1NDMwNSw2IDE0LDYgQzE1LjEwNDU2OTUsNiAxNiw2Ljg5NTQzMDUgMTYsOCBDMTYsOS4xMDQ1Njk1IDE1LjEwNDU2OTUsMTAgMTQsMTAgWiIgaWQ9IkZpbGwiIGZpbGw9IiM0NDQ0NDQiPjwvcGF0aD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+)',
      y: 8,
      menuItems: ['downloadJPEG', 'downloadPDF', 'downloadCSV'],
    },
  },
  chartOptions: {
    chart: {
      style: {
        fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
        color: '#767676',
        fontSize: '12px',
        fontWeight: 'normal',
      },
    },
  },
  fallbackToExportServer: false,
}

export const navigationOptions: NavigationOptions = {
  menuItemHoverStyle: {
    background: 'rgba(229, 249, 249, 1)',
    color: '#767676',
  },
  menuItemStyle: {
    fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
    color: '#767676',
    fontSize: '12px',
    fontWeight: 'normal',
  },
}

export const allLegendProps: LegendOptions = { symbolRadius: 2, itemStyle: { color: '#444444', fontSize: '12px', fontWeight: '400' } }

export const topLegendProps: LegendOptions = {
  ...allLegendProps,
  verticalAlign: 'top' as VerticalAlignValue,
  align: 'left' as AlignValue,
  floating: false,
  padding: 0,
  itemMarginBottom: 24,
  x: 38,
}

export const defaultXAxisOptions: XAxisOptions = {
  labels: {
    enabled: false,
  },
}

export const defaultYAxisOptions: YAxisOptions = {
  title: {
    text: null,
  },
}

export const yAxisLabelsOptions: YAxisLabelsOptions = {
  formatter: function (this: any): string {
    return this.value > 1000 ? getLabelValue(this.value) : this.value
  },
}

const getLabelValue = (num: number) => {
  const numericSymbols = ['k', 'M']
  let axisLabel

  numericSymbols.forEach((_s, index: number) => {
    const axisValue = num * 10
    const multiplier = Math.pow(1000, index + 1)

    if (axisValue % multiplier !== axisValue) {
      axisLabel = Highcharts.numberFormat(num / multiplier, -1) + numericSymbols[index]
    }
  }, num)

  return `$${axisLabel}`
}

export const tooltipOptions: TooltipOptions = {
  formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
    return `${this.series.name}: ${this.y}`
  },
}

export const tooltipPointsFormatter: TooltipOptions = {
  pointFormatter: function (this): string {
    return `<tspan style="color:${this.color}" x="8" dy="15">&#x25cf;</tspan> ${this.series.name}: <b>${formatNumber(this.y ?? 0)}</b>`
  },
}

export const highChartColors = [
  '#008FCA',
  '#41CAA2',
  '#FFB8D7',
  '#4D5D71',
  '#FFD892',
  '#7E6EFF',
  '#3FDCE4',
  '#FF378E',
  '#B3E0D5',
  '#FFA27F',
  '#1B9377',
  '#B2B9D7',
  '#B6F2FC',
  '#CD7CA5',
  '#D8D0C7',
  '#C7D411',
  '#3224CE',
  '#03B2B1',
  '#FA7FFF',
  '#E26C2C',
]

export const mapNavigationOptions: MapNavigationOptions = {
  enabled: true,
  buttonOptions: {
    verticalAlign: 'bottom',
    align: 'left',
    alignTo: 'spacingBox',
  },
}

export const mapColorAxisOptions: ColorAxisOptions = {
  min: 0,
  stops: [
    [0, '#E9F8FF'],
    [0.5, '#45BEFF'],
    [1, '#0076BD'],
  ],
}

export interface MapSeriesData {
  code: string
  value: number
}

export enum MapTypes {
  US = 'US',
  WORLD = 'WORLD',
}

export const mapLegend: LegendOptions = {
  title: {
    text: 'Visits',
  },
  align: 'right',
  verticalAlign: 'bottom',
  layout: 'horizontal',
  floating: false,
}

export const mapSeries = {
  type: 'map',
  animation: {
    duration: 1000,
  },
  dataLabels: {
    enabled: false,
    color: '#FFFFFF',
    format: '{point.code}',
  },
  states: {
    hover: {
      color: '#a4edba',
    },
  },
}

export const usJoinBy = ['postal-code', 'code']

export const worldJoinBy = ['iso-a2', 'code']

export const tooltipNoHeaderFormatter = (val: any) => {
  const points = val.points ?? [val.point]
  return points.reduce(function (
    s: string,
    point: {
      key: string
      series: {
        color: string
        name: string
      }
      y: string
    }
  ) {
    return (
      s +
      '<span style="height: 10px; width: 10px; background-color: ' +
      point.series.color +
      '; border-radius: 50%; display: inline-block; margin-right: 5px;"></span>' +
      point.series.name +
      ': ' +
      '<b>' +
      point.y +
      '</b><br>'
    )
  }, '')
}

export type ChartSettingState = {
  email: {
    dateType: DateType
    dateTypeQuantity: number
  }
  sms: {
    dateType: DateType
    dateTypeQuantity: number
  }
  programPerformance: {
    allContacts: {
      chartType: SwitchOptions
      dateType: DateType
      dateTypeQuantity: number
    }
    standardExits: {
      dateType: DateType
      dateTypeQuantity: number
    }
  }
}

export const defaultChartSettingState: ChartSettingState = {
  email: {
    dateType: DateType.MONTH,
    dateTypeQuantity: DEFAULT_DATE_QUANTITIES[DateType.MONTH],
  },
  sms: {
    dateType: DateType.MONTH,
    dateTypeQuantity: DEFAULT_DATE_QUANTITIES[DateType.MONTH],
  },
  programPerformance: {
    allContacts: {
      chartType: SwitchOptions.BAR,
      dateType: DateType.MONTH,
      dateTypeQuantity: DEFAULT_DATE_QUANTITIES[DateType.MONTH],
    },
    standardExits: {
      dateType: DateType.MONTH,
      dateTypeQuantity: DEFAULT_DATE_QUANTITIES[DateType.MONTH],
    },
  },
}
