import { useApolloClient } from '@apollo/client'
import createSegment from '@graphql/mutations/createSegment'
import isAValidSegmentName from '@graphql/queries/isAValidSegmentName'
import listSchemaQuery from '@graphql/queries/listSchema'
import { CreateSegmentMutation, CreateSegmentMutationVariables } from '@graphql/types/mutation-types'
import { IsAValidSegmentNameQuery, IsAValidSegmentNameQueryVariables, ListSchemaQuery, ListSchemaQueryVariables } from '@graphql/types/query-types'
import { FetchPromise } from '@utils/types'

interface CreateSegmentRequestParams {
  segmentName: string
}

type IsAValidSegmentNameRequestParams = CreateSegmentRequestParams

export type CreateSegmentRequestType = (params: CreateSegmentRequestParams) => FetchPromise<CreateSegmentMutation>
export type IsAValidSegmentNameRequestType = (params: IsAValidSegmentNameRequestParams) => FetchPromise<IsAValidSegmentNameQuery>
export type GetListSchemaRequestType = (listIds: string[], count?: number) => FetchPromise<ListSchemaQuery>

export interface ImportContactsClassicRequests {
  createSegmentRequest: CreateSegmentRequestType
  isAValidSegmentNameRequest: IsAValidSegmentNameRequestType
  getListSchemaRequest: GetListSchemaRequestType
}

export const useAddContactsFromCRMClassicRequests = (): ImportContactsClassicRequests => {
  const actOnClassicClient = useApolloClient()

  const createSegmentRequest = async ({ segmentName }: CreateSegmentRequestParams) =>
    await actOnClassicClient.mutate<CreateSegmentMutation, CreateSegmentMutationVariables>({
      mutation: createSegment,
      variables: {
        name: segmentName,
      },
    })

  const isAValidSegmentNameRequest = async ({ segmentName }: IsAValidSegmentNameRequestParams) =>
    await actOnClassicClient.query<IsAValidSegmentNameQuery, IsAValidSegmentNameQueryVariables>({
      query: isAValidSegmentName,
      errorPolicy: 'all',
      variables: {
        segmentName,
      },
    })

  const getListSchemaRequest = async (listIds: string[], count?: number) =>
    await actOnClassicClient.query<ListSchemaQuery, ListSchemaQueryVariables>({
      query: listSchemaQuery,
      fetchPolicy: 'network-only',
      variables: {
        listIds,
        count,
        readOnly: true,
      },
    })

  return {
    createSegmentRequest,
    isAValidSegmentNameRequest,
    getListSchemaRequest,
  }
}
