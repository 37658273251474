import React, { FC, useEffect, useMemo, useRef, useState } from 'react'

import classNames from 'classnames'

import Breadcrumbs, { BreadcrumbsItemType } from '@components/Breadcrumbs/Breadcrumbs'
import { SvgNames, SvgType } from '@components/Svg'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import { TextWeight } from '@components/Typography/Typography'
import { Content, Root, Trigger } from '@radix-ui/react-hover-card'

import './BreadcrumbsHoverCard.css'

interface BreadcrumbsHoverCardProps {
  className?: string
  dataTest?: string
  breadcrumbs: BreadcrumbsItemType[]
  controlledOpen?: boolean
  dropDownPositionIndex?: number
}

interface State {
  dropDownOpen: boolean
  hoverCardOpen: boolean
  lastCrumbWidth: number
}

const rootClass = 'breadcrumbs-hover-card'

const BreadcrumbsHoverCard: FC<BreadcrumbsHoverCardProps> = ({
  breadcrumbs,
  className,
  controlledOpen,
  dropDownPositionIndex,
}: BreadcrumbsHoverCardProps) => {
  const [state, setState] = useState<State>({ dropDownOpen: false, hoverCardOpen: false, lastCrumbWidth: 0 })
  const { dropDownOpen, hoverCardOpen, lastCrumbWidth } = state
  const contentRef = useRef<HTMLDivElement>(null)
  const updatedBreadcrumbs: BreadcrumbsItemType[] = useMemo(
    () =>
      breadcrumbs.map((item) => ({
        ...item,
        className: classNames(item.className ?? '', `${rootClass}__breadcrumb`),
        customTypography: { ...item.customTypography, weight: TextWeight.REGULAR },
      })),
    [breadcrumbs]
  )

  useEffect(() => {
    if (!!contentRef.current && !lastCrumbWidth) {
      const crumbs = contentRef.current.getElementsByClassName('breadcrumbs__crumb')
      if (!!crumbs.length) {
        setState((state) => ({
          ...state,
          lastCrumbWidth: crumbs[crumbs.length - 1].getElementsByClassName('typography')[0].getBoundingClientRect().width,
        }))
      }
    }
  }, [contentRef.current, lastCrumbWidth])

  const toggleHoverCard = (hoverCardOpen: boolean) => (dropDownOpen ? undefined : setState(() => ({ ...state, hoverCardOpen })))

  const onDropDownOpen = (dropDownOpen: boolean) => setState(() => ({ ...state, dropDownOpen }))

  return (
    <div className={classNames(rootClass, className)}>
      <Root openDelay={0} closeDelay={0} open={controlledOpen ?? (hoverCardOpen && !!breadcrumbs.length)} onOpenChange={toggleHoverCard}>
        <Trigger className={`${rootClass}__trigger`}>
          <TextWithTooltipOnEllip
            typographyProps={{ className: `${rootClass}__trigger-text`, text: updatedBreadcrumbs[updatedBreadcrumbs.length - 1]?.text }}
          />
        </Trigger>
        <Content
          ref={contentRef}
          forceMount={(!contentRef.current && !lastCrumbWidth) || undefined}
          className={classNames(`${rootClass}__content`, { [`${rootClass}__content-show`]: !!lastCrumbWidth })}
          sideOffset={0}
          avoidCollisions
          side={'right'}
          style={{ transform: `translate(${lastCrumbWidth + 24}px, -22px)` }}
          sticky={'always'}
        >
          <Breadcrumbs
            className={`${rootClass}__breadcrumbs`}
            breadcrumbs={updatedBreadcrumbs}
            dropDownPositionIndex={dropDownPositionIndex}
            onDropDownOpen={onDropDownOpen}
            caretSvgName={SvgNames.caretRight}
            caretSvgType={SvgType.ICON}
            hasAllInactiveElements
          />
        </Content>
      </Root>
    </div>
  )
}

export default BreadcrumbsHoverCard
