import React, { FC, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import InfoCards from '@components/InfoCard/InfoCard'
import { useTranslation } from '@const/globals'
import { StatValueDto } from '@graphql/types/microservice/segment-types'
import {
  getAllSegmentStats,
  segmentPerformanceCards,
} from '@src/pages/ContactSegments/components/SegmentActivity/components/SegmentPerformance/utils/SegmentPerformance.utils'
import { Segment } from '@utils/contactSegments/contactSegments.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'

import './SegmentPerformance.css'

interface State {
  sendableContacts: StatValueDto
}

interface Props {
  className?: string
  dataTest?: string
  segment: Segment
}

const initialState: State = {
  sendableContacts: { value: 0, percentageValue: 0 },
}

const rootClass = 'segment-performance'

const SegmentPerformance: FC<Props> = (props: Props) => {
  const {
    dataTest = rootClass,
    className = '',
    segment: { externalId, recordsCount },
  } = props

  const [state, setState] = useState<State>(initialState)
  const { sendableContacts } = state

  const { t } = useTranslation()

  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.SEGMENT })

  useEffect(() => getAllSegmentStats(externalId, client, setState), [externalId])

  const cards = useMemo(() => segmentPerformanceCards(recordsCount, sendableContacts, t), [externalId, state])

  return <InfoCards data-test={dataTest} className={classNames(rootClass, className)} cardsOptions={cards} />
}

export default SegmentPerformance
