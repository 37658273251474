import { gql } from '@apollo/client'

export default gql`
  query crmCreateTypes {
    crmCreateTypes {
      key
      value
    }
  }
`
