import React, { FC, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { ButtonProps } from '@components/Button/Button'
import Svg, { SvgNames } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'

import './SelectFilterButton.css'

interface SelectFilterButtonProps extends Omit<ButtonProps, 'children' | 'buttonType'> {
  className?: string
  dataTest?: string
  label: string
  triggerOnHover?: boolean
  count?: number
  isRounded?: boolean
  isSelected: boolean
  onDiscard: () => void
  isOpen: boolean
}

interface SelectFilterButtonState {
  isActive: boolean
  isHovering: boolean
}

const rootClass = 'select-filter-button'

const SelectFilterButton: FC<SelectFilterButtonProps> = (props: SelectFilterButtonProps) => {
  const { dataTest = rootClass, className = '', label, isRounded = false, count = 0, isOpen = false, isSelected = false, onDiscard } = props
  const [state, setState] = useState<SelectFilterButtonState>({ isActive: false, isHovering: false })
  const { isActive, isHovering } = state
  const onHoverChange = (hoverState: boolean) => setState((state) => ({ ...state, isHovering: hoverState }))
  const buttonClasses = classNames(rootClass, className, {
    [`${rootClass}__active`]: isActive,
    [`${rootClass}__hover`]: isHovering || isOpen,
  })

  return (
    <div onMouseEnter={() => onHoverChange(true)} onMouseLeave={() => onHoverChange(false)} className={`${rootClass}__wrapper`}>
      {!isSelected ? (
        <>
          <Button
            buttonType={isRounded ? ButtonType.PILL : ButtonType.TERTIARY}
            className={buttonClasses}
            dataTest={dataTest}
            onClick={() => setState((state) => ({ ...state, isActive: !isActive }))}
          >
            <Typography text={label} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.MEDIUM} inline />
            <Svg name={SvgNames.downArrow} className={`${rootClass}__chevron`} />
          </Button>
        </>
      ) : (
        <div
          className={classNames(`${rootClass}__selected-wrapper`, {
            [`${rootClass}__selected-wrapper-pill`]: isRounded,
          })}
        >
          <Button
            buttonType={ButtonType.TERTIARY}
            className={classNames(`${rootClass}__selected`, `${rootClass}__selected-label`, {
              [`${rootClass}__selected-label-pill`]: isRounded,
            })}
          >
            <Svg name={SvgNames.checkThin} />
            <Typography text={`${label} (${count})`} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.MEDIUM} inline />
          </Button>
          <div />
          <Button
            buttonType={ButtonType.TERTIARY}
            className={classNames(`${rootClass}__selected`, `${rootClass}__selected-close`, {
              [`${rootClass}__selected-close-pill`]: isRounded,
            })}
            onClick={() => onDiscard()}
          >
            <Svg name={SvgNames.close} dataTest={`${dataTest}-discard-button`} />
          </Button>
        </div>
      )}
    </div>
  )
}

export default SelectFilterButton
