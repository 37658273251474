import React, { FC } from 'react'

import classNames from 'classnames'

import ScheduleSelector from '@complex/ScheduleSelector/ScheduleSelector'
import Container from '@components/Container'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ProgramSchedule } from '@graphql/types/query-types'
import { useAccountSettings } from '@utils/account/account.utils'

import './ListProgramScheduleEditor.css'

interface ListProgramScheduleEditorProps {
  className?: string
  dataTest?: string
  schedule?: ProgramSchedule
  onChange: (values: ProgramSchedule) => void
}

const rootClass = 'list-program-schedule-editor'

const ListProgramScheduleEditor: FC<ListProgramScheduleEditorProps> = (props: ListProgramScheduleEditorProps) => {
  const { dataTest = rootClass, className = '', schedule, onChange } = props

  const { t } = useTranslation()

  const { userAllowedToCreatePrograms } = useAccountSettings()

  return (
    <Container className={classNames(rootClass, className)} dataTest={dataTest}>
      <Typography text={t('Program Schedule')} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
      <ScheduleSelector defaultValue={schedule} onChange={onChange} disabled={!userAllowedToCreatePrograms} />
    </Container>
  )
}

export default ListProgramScheduleEditor
