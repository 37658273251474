import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { LinkTextButton, LinkTextButtonProps } from '@components/LinkTextButton/LinkTextButton'
import Svg, { SvgNames } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './AssetPickerPreviewMetadata.css'

export interface AssetPickerPreviewMetadataProps {
  header: string
  subHeader?: string
  metadata: { label: string; value: ReactNode }[]
  linkProps?: LinkTextButtonProps
  buttonText?: string
  onButtonClick?: () => void
  className?: string
  dataTest?: string
}

const rootClass = 'asset-picker-preview-metadata'

const AssetPickerPreviewMetadata: FC<AssetPickerPreviewMetadataProps> = (props: AssetPickerPreviewMetadataProps) => {
  const { header, metadata, subHeader, linkProps, buttonText, onButtonClick, dataTest = rootClass, className = rootClass } = props
  const { t } = useTranslation()

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__header`} data-test={`${dataTest}-header`}>
        <Typography text={header} type={TextType.SECTION_HEADER} weight={TextWeight.BOLD} />
      </div>
      <div className={`${rootClass}__properties__container`}>
        <Typography text={subHeader ?? t('Properties')} weight={TextWeight.MEDIUM} dataTest={`${dataTest}-properties-header`} />
        <div className={`${rootClass}__properties`}>
          <div className={`${rootClass}__properties__col`}>
            {metadata.map(({ label }, index) => (
              <Typography key={`${label}-${index}`} inline text={label} type={TextType.BODY_TEXT_LIGHT} dataTest={`${dataTest}-property-${label}`} />
            ))}
          </div>
          <div className={`${rootClass}__properties__col`}>
            {metadata.map(({ label, value }, index) => (
              <Typography key={`${label}-${index}`} inline text={value} dataTest={`${dataTest}-property-${value}`} />
            ))}
          </div>
        </div>
        {linkProps && <LinkTextButton hideIconLeft {...linkProps} />}
        {onButtonClick && (
          <Button
            buttonType={ButtonType.INFO}
            onClick={onButtonClick}
            className={`${rootClass}__properties__edit-button`}
            dataTest={`${dataTest}-property-edit-button`}
          >
            <Svg name={SvgNames.pencil} />
            {buttonText ?? t('Edit')}
          </Button>
        )}
      </div>
    </div>
  )
}

export default AssetPickerPreviewMetadata
