import React from 'react'

import { ApolloError } from '@apollo/client'
import { renderLoader } from '@components/Loader/Loader'
import PageContainer from '@components/PageContainer/PageContainer'
import PageError from '@components/PageError'
import { logNewRelicError } from '@utils/new-relic.utils'

interface WithLoadingProps {
  loading: boolean
  pageError?: ApolloError
}

const withLoadingAndError =
  <P extends {}>(Component: React.ComponentType<P>): React.FC<P & WithLoadingProps> =>
  ({ loading, pageError = undefined, ...props }: WithLoadingProps) => {
    if (pageError) {
      logNewRelicError(pageError.message)
      return (
        <PageContainer>
          <PageError message={pageError.message} center />
        </PageContainer>
      )
    } else if (loading) {
      return renderLoader('loader--white-background')
    } else {
      return <Component {...(props as P)} />
    }
  }

export default withLoadingAndError
