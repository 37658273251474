import React, { FC, useCallback, useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'

import CustomPrompt from '@components/CustomPrompt/CustomPrompt'
import StatusToast from '@components/StatusToast/StatusToast'
import { rootContext, useTranslation } from '@const/globals'
import { StatusToastType } from '@interface/StatusToast'
import LandingPageManagerContext from '@src/pages/Content/LandingPages/LandingPagesManager/context/LandingPagesManager.context'
import useAccountSlugs from '@src/pages/Content/LandingPages/LandingPagesManager/hooks/useAccountSlugs'
import useClickthroughLinks from '@src/pages/Content/LandingPages/LandingPagesManager/hooks/useClickthroughLinks'
import useLandingPage from '@src/pages/Content/LandingPages/LandingPagesManager/hooks/useLandingPage'
import LandingPageManager from '@src/pages/Content/LandingPages/LandingPagesManager/LandingPageManager'
import { getLandingPageDefaultTab, LandingPageManagerTab } from '@src/pages/Content/LandingPages/LandingPagesManager/LandingPageManager.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import { useDocumentVisibility } from '@utils/hooks/useDocumentVisibility'
import { openLandingPageEditorWindow } from '@utils/landingPages'
import { MatchParams } from '@utils/types'

type LandingPageManagerProps = RouteComponentProps<MatchParams> & {
  isStory: boolean
}

const baseUrl = `${rootContext}/content/landingPages`

const LandingPageManagerContainer: FC<LandingPageManagerProps> = ({ match, isStory }) => {
  const pageId = match.params.id
  const { t } = useTranslation()
  const history = useHistory()
  const { hasLandingPageReportV2 } = useAccountSettings()
  const [toast, setToastStatus] = useState<StatusToastType>()
  const [currentTab, setCurrentTab] = useState<string>(match.params.tab ?? '')
  const { loadingLP, landingPage, updateLandingPage, refetchLP, updatingLP } = useLandingPage(pageId)
  const { accountSlugs, refetchAccountSlugs } = useAccountSlugs()
  const {
    loadingLinks,
    clickthroughLinks,
    refetchLinks,
    updatingLinks,
    dirtyClickthroughLinks,
    onSaveLinks,
    onClickthroughLinkChange,
    onCancelLinkChanges,
  } = useClickthroughLinks(pageId, currentTab, setToastStatus)

  const isVisible = useDocumentVisibility()
  const showLinksAndMap = !!clickthroughLinks.length && hasLandingPageReportV2

  const handleCloseToast = useCallback(() => setToastStatus({ showStatus: false }), [])
  const onEdit = useCallback(() => !isStory && openLandingPageEditorWindow(pageId, landingPage?.isBee), [pageId, isStory, landingPage])

  useEffect(() => {
    const subTab = currentTab === LandingPageManagerTab.DETAILS && match.params.subTab ? `/${match.params.subTab}` : ''

    history.replace(`${baseUrl}/${pageId}/${currentTab}${subTab}`)
  }, [currentTab, pageId, history, match.params.subTab])

  useEffect(() => {
    !loadingLinks && setCurrentTab((currentTab) => getLandingPageDefaultTab(showLinksAndMap, currentTab))
  }, [showLinksAndMap, loadingLinks])

  useEffect(() => {
    const url = new URL(window.location.href)
    const isLPManager = url.pathname.includes('landingPages')

    if (isVisible && isLPManager) {
      refetchLP()
      refetchLinks()
    }
  }, [refetchLP, refetchLinks, isVisible])

  return (
    <>
      {toast?.showStatus && (
        <StatusToast isSuccess={toast.successStatus} message={toast.statusMessage} title={toast.title} closeStatus={handleCloseToast} />
      )}
      <LandingPageManagerContext.Provider
        value={{
          landingPage,
          clickthroughLinks,
          setToastStatus,
          onEdit,
          updateLandingPage,
          dirtyClickthroughLinks,
          onSaveLinks,
          onCancelLinkChanges,
          onClickthroughLinkChange,
          accountSlugs,
          refetchAccountSlugs,
        }}
      >
        <CustomPrompt
          when={dirtyClickthroughLinks}
          title={t('You have unsaved changes')}
          body={t('Leave.And.Lose.Changes.Message')}
          cancelButtonText={t('Keep editing')}
          okButtonText={t('Discard changes')}
          showOnLocationChange={({ pathname }) => !pathname.includes(pageId)}
        />
        <LandingPageManager
          loading={loadingLP || loadingLinks}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          updatingData={updatingLP || updatingLinks}
          notFound={!landingPage}
          showLinksAndMap={showLinksAndMap}
          id={pageId}
        />
      </LandingPageManagerContext.Provider>
    </>
  )
}

export default LandingPageManagerContainer
