import dayjs from 'dayjs'
import { dateFormat, PlotOptions, XAxisLabelsOptions, XAxisOptions } from 'highcharts'

import { LegendLocation } from '@const/Chart.constants'
import { getUUID } from '@const/globals'
import { getCountDisplay } from '@utils/numbers'

export const xAxisOtherUIGroupingKey = getUUID()

export const getMonthDifference = (startTime: number, endTime: number): number => {
  return dayjs(endTime).diff(dayjs(startTime), 'month', true)
}

export const isOneDayRange = (startTime: number, endTime: number): boolean => {
  return dayjs(endTime).diff(dayjs(startTime), 'day', true) < 1
}

export const getTooltipDateRange = (dateValue: number, startTime: number, endTime: number): string => {
  const monthDifference = getMonthDifference(startTime, endTime)
  const isOneMonth = monthDifference <= 1
  const isMoreThreeMonths = monthDifference >= 3
  const date = dayjs(dateValue)
  const year = date.year()

  if (isOneMonth) {
    return `${date.format('MMM D')}, ${year}`
  }
  const startDate = dayjs(startTime)
  const endDate = dayjs(endTime)

  if (isMoreThreeMonths) {
    const realStartOfMonth = date.startOf('month')
    const startOfMonth = realStartOfMonth.valueOf() < startTime ? startDate : realStartOfMonth
    const endOfMonth = date.endOf('month')
    const firstDayOfMonth = startOfMonth.format('MMM D')
    const lastDayOfMonth = (endOfMonth.valueOf() > endTime ? endDate : endOfMonth).date()
    return startOfMonth.date() === lastDayOfMonth ? `${firstDayOfMonth}, ${year}` : `${firstDayOfMonth} - ${lastDayOfMonth}, ${year}`
  }

  const realStartOfWeek = date.startOf('week')
  const realEndOfWeek = date.endOf('week')
  const startOfWeek = realStartOfWeek.valueOf() < startTime ? startDate : realStartOfWeek
  const endOfWeek = realEndOfWeek.valueOf() > endTime ? endDate : realEndOfWeek
  const isWeekFullyInMonth = startOfWeek.month() === endOfWeek.month()
  const firstDayOfWeek = startOfWeek.format('MMM D')
  const lastDayOfWeek = isWeekFullyInMonth ? endOfWeek.date() : endOfWeek.format('MMM D')

  return startOfWeek.date() === endOfWeek.date() ? `${firstDayOfWeek}, ${year}` : `${firstDayOfWeek} - ${lastDayOfWeek}, ${year}`
}

const disableLegendEvent = {
  events: {
    legendItemClick: function () {
      return false
    },
  },
}

export const columnChartPlotOptions = (isRevenue?: boolean): PlotOptions => ({
  column: {
    borderRadius: 0,
    maxPointWidth: 21,
    minPointLength: 6,
    dataLabels: {
      enabled: !isRevenue,
      formatter: function (this: any) {
        return getCountDisplay(Number(this.y))
      },
      crop: false,
      overflow: 'allow',
      style: {
        color: '#444',
        fontSize: '12px',
        fontWeight: '400',
      },
    },
    ...disableLegendEvent,
  },
})

const getTickPositions = (isOneMonth: boolean, isMoreThreeMonths: boolean, startTime: number, endTime: number): Array<number> => {
  const unit = isMoreThreeMonths ? 'month' : isOneMonth ? 'day' : 'week'
  const start = dayjs(startTime).startOf(unit)
  const diff = dayjs(endTime).startOf(unit).diff(start, unit, true)
  const step = diff < 4 ? 1 : Math.floor(diff / 2)
  const ticksCount = Math.floor(diff / step)
  return Array.from({ length: ticksCount + 1 }).map((_, index) => start.add(index * step, unit).valueOf())
}

export const fillMissingData = (
  data: number[][],
  isOneMonth: boolean,
  isMoreThreeMonths: boolean,
  startTime: number,
  endTime: number
): number[][] => {
  const interval = isOneMonth ? 'day' : isMoreThreeMonths ? 'month' : 'week'
  const start = dayjs(startTime).startOf(interval)
  const end = dayjs(endTime).startOf(interval)
  const diff = end.diff(start, interval)
  const result: number[][] = []
  if (diff < 1 || !data.length) {
    return result
  }
  const sortedData = data.sort((a, b) => (a[0] > b[0] ? 1 : -1))
  let dataIndex = 0
  for (let i = 0; i <= diff; i++) {
    const dateValue = start.add(i, interval).valueOf()
    if (!sortedData[dataIndex]) {
      result.push([dateValue, 0])
    } else if (dataIndex === 0 && sortedData[dataIndex][0] < dateValue) {
      result.push(sortedData[dataIndex])
      dataIndex++
      i--
    } else if (sortedData[dataIndex][0] === dateValue) {
      result.push(sortedData[dataIndex])
      dataIndex++
    } else if (sortedData[dataIndex][0] > dateValue) {
      result.push([dateValue, 0])
    }
  }
  return result
}
interface XAxisOptionsProps {
  missingLabelReplacer: string
  isColumnChart: boolean
  startTime: number
  endTime: number
}

const xAxisLabels = (props: {
  missingLabelReplacer: string
  isColumnChart: boolean
  isMoreThreeMonths: boolean
  startTime: number
}): XAxisLabelsOptions => {
  const { missingLabelReplacer, isColumnChart, isMoreThreeMonths, startTime } = props
  const labelProps: XAxisLabelsOptions = {
    rotation: 0,
  }
  if (isColumnChart) {
    labelProps.formatter = function (this: any): string {
      const value = this.value
      if (value === '') {
        return `<i style="color: #767676">${missingLabelReplacer}</i>`
      }
      if (value === xAxisOtherUIGroupingKey) {
        return `<i style="color: #767676">Other</i>`
      }
      return value
    }
  } else {
    labelProps.formatter = function (this: any): string {
      return dateFormat(isMoreThreeMonths ? '%b %Y' : '%b %e, %Y', Math.max(this.value, startTime))
    }
  }
  return labelProps
}

export const getXAxisCommonOptions = (props: XAxisOptionsProps): XAxisOptions => {
  const { isColumnChart, missingLabelReplacer, startTime, endTime } = props
  const monthDifference = getMonthDifference(startTime, endTime)
  const isOneMonth = monthDifference <= 1
  const isMoreThreeMonths = monthDifference >= 3

  const labels = xAxisLabels({ missingLabelReplacer, isColumnChart, isMoreThreeMonths, startTime })
  if (isColumnChart) {
    return { labels }
  }
  return {
    labels,
    tickPositions: getTickPositions(isOneMonth, isMoreThreeMonths, startTime, endTime),
  }
}

export const lineChartPlotOptions = {
  series: {
    lineWidth: 4,
    marker: { enabled: false, symbol: 'circle', radius: 4 },
    ...disableLegendEvent,
  },
  spline: { pointPlacement: 'on' },
}

export const defaultLegendOptions = {
  y: 3,
  useHTML: true,
  itemHoverStyle: { color: '#444' },
  labelFormat: `
    <span style="position: relative;top: -2px;">
      <text y="14">{name}</text>
    </span>
  `,
}

export const defaultOptions = {
  isDashed: true,
  chartHeight: 370,
  chartMarginTop: 77,
  showTick: false,
  noContainer: true,
  legendLocation: LegendLocation.TOP,
  axisLabelsStyle: {
    color: '#444444',
    fontSize: '12px',
    lineHeight: '16px',
  },
}
