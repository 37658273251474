import React, { ReactNode } from 'react'

import classNames from 'classnames'

import './ScopedModal.css'

interface ScopedModalProps {
  isOpen: boolean
  children: ReactNode
  className?: string
  dataTest?: string
}

const rootClass = 'scoped-modal'

export const ScopedModal: React.FC<ScopedModalProps> = ({ isOpen, children, className = rootClass, dataTest = rootClass }) => {
  if (!isOpen) return null

  return (
    <div className={classNames(rootClass, className, { [`${rootClass}--visible`]: isOpen })} style={{ position: 'absolute' }} data-test={dataTest}>
      <div className={`${rootClass}__background`} />
      <div className={`${rootClass}__content`}>
        <div className={`${rootClass}__body`} data-test={`${dataTest}__body`}>
          {children}
        </div>
      </div>
    </div>
  )
}
