import { gql } from '@apollo/client'

export default gql`
  query getImage($id: String) {
    getImage(id: $id) {
      id
      title
      previewUrl
      isFavorite
      linkUrl
      modified
      created
      doctype
      size
      thumbNailUrl
      dimensions
    }
  }
`
