import React, { useContext } from 'react'
import { NoticeProps } from 'react-select'

import classNames from 'classnames'

import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import { SelectV2Context } from '@components/SelectV2/SelectV2.context'
import { selectRootClass } from '@components/SelectV2/SelectV2.utils'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { LineHeight, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

const CustomNoOptionsMessage = (props: NoticeProps<any>) => {
  const { innerProps, clearValue } = props
  const {
    values: { inputValue, nestedSearch },
    update,
  } = useContext(SelectV2Context)
  const { t } = useTranslation()

  const onLinkClick = () => {
    clearValue()
    update({ inputValue: '' })
  }

  return (
    <div {...innerProps}>
      <EmptyListing
        className={classNames(`${selectRootClass}__no-options-message`, { [`${selectRootClass}__no-options-message-empty-menu`]: !inputValue })}
        imgSrc={StaticImageNames.emptyBox}
        headlineLineHeight={LineHeight.LARGE}
        headlineType={TextType.BANNER_HEADER}
        headline={t(!!inputValue ? 'Nothing turned up' : 'Nothing to see here')}
        text={t(!!inputValue ? 'We can’t find what you’re looking for. Let’s try another search.' : 'You don’t have any options to select.')}
        linkText={!!inputValue ? t('Nested.DropDown.Empty.Listing.Btn.Text.Root') : ''}
        onLinkClick={!!inputValue ? onLinkClick : undefined}
        size={nestedSearch ? EmptyListingSize.SMALL : EmptyListingSize.MEDIUM}
        withoutBorder
      />
    </div>
  )
}

export default CustomNoOptionsMessage
