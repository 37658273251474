import { createContext } from 'react'

import { FilterFunctionMap, FrontendFilteringAPI, getDefaultFilteringAPI, StrictSortingState } from '@complex/ListingPage/Hooks/useFrontendFiltering'
import { Entry } from '@graphql/types/query-types'
import { FilterTypes, allMediaFilesFilter, allMediaFilter, favoriteFilter, mediaFileFilter, mediaLinkFilter } from '@utils/filter'
import { Folder } from '@utils/interface/Folder'
import { UpdateState } from '@utils/types'

export type MediaItemResponse = Entry

export type MediaItem = Omit<MediaItemResponse, 'size'> & {
  size: number
  folderId: number
}

export interface MediaPickerModalState {
  allData: MediaItem[]
  loading: boolean
  searchText: string
  searchAllData: boolean
  activeFilter: string
  activeFolder?: number
  activeSubTypes: string[]
  selectedItem?: MediaItem
  selectedItems?: MediaItem[]
  sortingBy: StrictSortingState<MediaItem>
  folders: Folder[]
  previewItem?: MediaItem
  isViewingSelected?: boolean
  visibleItems?: MediaItem[]
}

export const getDefaultMediaPickerModalState = (isCssJsMode?: boolean): MediaPickerModalState => ({
  allData: [],
  loading: true,
  activeFilter: isCssJsMode ? FilterTypes.ALL_MEDIA : FilterTypes.ALL_MEDIA_FILES,
  activeSubTypes: [],
  folders: [],
  activeFolder: undefined,
  searchText: '',
  searchAllData: false,
  sortingBy: [{ id: 'modified', desc: false }],
  selectedItems: [],
})

type MediaPickerModalContextValues = MediaPickerModalState &
  FrontendFilteringAPI<MediaItem> & {
    update: UpdateState<MediaPickerModalState>
  }

export const getMediaFilters = (isCssOrJs?: boolean) => {
  return [isCssOrJs ? allMediaFilter : allMediaFilesFilter, favoriteFilter, mediaFileFilter, mediaLinkFilter]
}

export const getMediaFilterFunctions = (isCssJsMode?: boolean): FilterFunctionMap<MediaItem> => ({
  ...(isCssJsMode ? { [FilterTypes.ALL_MEDIA]: () => true } : { [FilterTypes.ALL_MEDIA_FILES]: () => true }),
  [FilterTypes.FAVORITES]: (item) => item.isFavorite,
  [FilterTypes.MEDIA_FILE]: (item) => item.docType !== 'URL',
  [FilterTypes.MEDIA_LINK]: (item) => item.docType === 'URL',
  search: (item, search) => !search || item.title.toLowerCase().includes(search.toString().toLowerCase()),
  folder: (item, folder) => folder === undefined || item.folderId === folder,
})

export const MediaPickerModalContext = createContext<MediaPickerModalContextValues>({
  ...getDefaultFilteringAPI<MediaItem>(),
  ...getDefaultMediaPickerModalState(),
  update: () => null,
})
