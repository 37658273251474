import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import classNames from 'classnames'

import { FetchResult } from '@apollo/client'
import {
  DuplicateListingPageItem,
  ListPageAPI,
  ListPageCommonState,
  PageHeaderProps,
  SetError,
  SetStatusToast,
  SidebarProps,
  Update,
} from '@complex/ListingPage/Context/ListingPageCommon.context'
import ListingPageContainer from '@complex/ListingPage/ListingPageContainer'
import { CatalogLink, FormType } from '@complex/ListingPage/Utils/ListingPage.constants'
import { getPreviewIdFromUrl, removeParamFromUrl } from '@complex/ListingPage/Utils/ListingPage.utils'
import FormsPickerModal, { getCreateFormFromMigratedModalProps } from '@components/AssetPickers/FormsPickerModal/FormsPickerModal'
import FormTemplatesPickerModal, {
  getCreateFormFromTemplateModalProps,
} from '@components/AssetPickers/FormTemplatesPickerModal/FormTemplatesPickerModal'
import BulkActionsModal from '@components/BulkActionsModal/BulkActionsModal'
import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import { Checkbox } from '@components/Checkbox/Checkbox'
import MoveToFolderModal from '@components/MoveToFolderModal/MoveToFolderModal'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { Status } from '@components/StatusToast/StatusToast'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextWeight } from '@components/Typography/Typography'
import UpgradeHelper from '@components/UpgradeHelper/UpgradeHelper'
import { rootContext, useTranslation } from '@const/globals'
import { GetItemsQuery } from '@graphql/types/microservice/categorization-types'
import { ItemDto } from '@graphql/types/query-types'
import { useGetFormsListingPageRequests } from '@src/pages/listingPages/Forms/GraphQL/FormsListingPageRequests.graphQL'
import { getCustomEmptyListingProps, renderCustomFilters } from '@src/pages/listingPages/Forms/utils/FormsListingPage.Helpers'
import { FormsCustomTableActions, tableProps } from '@src/pages/listingPages/Forms/utils/FormsListingPage.tables'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import { allFormsFilter, FilterTypes } from '@utils/filter'
import {
  Form,
  FORM_TEMPLATE_SUCCESS_FLAG,
  FormBuilderMessage,
  FormBuilderMessageData,
  getFormsPreviewUrl,
  getFormsSubTypes,
  isFormBuilderMessage,
  openFormBuilderWindow,
} from '@utils/forms'
import useCRM, { CRMConnectorType } from '@utils/hooks/useCRM'
import { UpdateState } from '@utils/types'

import CreateFormModal, { CreateFormMethod } from './components/CreateFormModal/CreateFormModal'

import './FormsListingPageContainer.css'

interface FormsListingPageContainerProps {
  className?: string
  dataTest?: string
  showUpgradeHelper: boolean
  onUpgradeHelperClick: () => void
  doFetchItems?: boolean
}

type ActionsWithModals = Exclude<
  FormsCustomTableActions,
  FormsCustomTableActions.EDIT | FormsCustomTableActions.SHOW_DASHBOARD | FormsCustomTableActions.SHOW_DETAILS
>

export interface FormsListingPageContainerState {
  importFormSettings: boolean
  formTemplateExists: boolean
  windowMessages: FormBuilderMessage[]
}

const rootClass = 'forms-listing-page-container'

const FormsListingPageContainer: FC<FormsListingPageContainerProps> = (props: FormsListingPageContainerProps) => {
  const { dataTest = rootClass, showUpgradeHelper, onUpgradeHelperClick, doFetchItems } = props
  const [containerValues, setContainerValues] = useState<FormsListingPageContainerState>({
    importFormSettings: false,
    formTemplateExists: true,
    windowMessages: [],
  })
  const updateContainer: UpdateState<FormsListingPageContainerState> = (fields) => {
    setContainerValues((containerValues) => ({ ...containerValues, ...fields }))
  }
  const { importFormSettings, formTemplateExists, windowMessages } = containerValues
  const accountSettings = useAccountSettings()
  const { hasCRMConnected, connectorType } = useCRM()
  const { useOnlyNewForms, userAllowedToCreateContent, userAllowedToDeleteContent, hasReactWrapper } = accountSettings
  const {
    deleteFormsRequest,
    duplicateFormRequest,
    migrateClassicFormsRequest,
    createTemplateFromFormRequest,
    getFormTemplateExists,
    getFormPreviewRequest,
  } = useGetFormsListingPageRequests()

  const history = useHistory()

  useEffect(() => {
    getFormTemplateExists().then((data: FetchResult<GetItemsQuery>) => {
      setContainerValues((state) => ({ ...state, formTemplateExists: data?.data?.getItems?.length !== 0 }))
    })
  }, [])

  const getItemPreview = async (listPageValues: ListPageCommonState, update: Update, setError: SetError) => {
    const { selectedRows } = listPageValues

    const form = (selectedRows[0] as Form) ?? {}

    const searchParams = new URLSearchParams(history.location.search)

    const isNewForm = form.formType === FormType.NEW || searchParams.get('isNewForm') === '1'

    const previewIdFromUrl = getPreviewIdFromUrl(history)

    if (isNewForm) {
      update({ previewUrl: getFormsPreviewUrl(form.externalId ?? previewIdFromUrl) })
      removeParamFromUrl(history, ['preview', 'isNewForm'])
    } else {
      const { data, errors } = await getFormPreviewRequest({ formId: form.externalId ?? previewIdFromUrl })

      if (data) {
        update({ previewHtml: data.getFormPreview })
        removeParamFromUrl(history, ['preview', 'isNewForm'])
      } else if (errors) {
        setError(t('ListPage.Forms.Preview.Error'), errors)
      }
    }
  }

  const deleteForms = async (items: ItemDto[]) => deleteFormsRequest({ formIds: items.map((item) => item.externalId ?? '').filter((id) => !!id) })

  const onPageLoad = useCallback(
    async (_: SetStatusToast, update?: Update) => {
      const previewId = getPreviewIdFromUrl(history)
      if (previewId) {
        update?.({ showPreview: true })
      }
    },
    [history]
  )

  const onWindowMessage = useCallback(
    async (event: MessageEvent<FormBuilderMessageData>) => {
      if (isFormBuilderMessage(event.data)) {
        const eventExists = windowMessages.filter((message) => message.formId === event.data.formId).length
        const source = event.source ? (event.source as Window) : null
        !eventExists && updateContainer({ windowMessages: [...windowMessages, { ...event.data, windowName: source?.name ?? '' }] })
      }
    },
    [windowMessages]
  )

  const onWindowActive = useCallback(
    async (_event: Event, update: Update, setStatusToast: SetStatusToast, items: ItemDto[]) => {
      if (!windowMessages.length) {
        return
      }

      const message = windowMessages[0]
      const isCreated = !items.some((item) => message.formId === item.externalId)

      const formTitle = message.formTitle
      const status = (
        <Typography
          text={isCreated ? 'ListPage.Forms.FormCreated' : 'ListPage.Forms.FormUpdated'}
          tagProps={{ bold: { weight: TextWeight.MEDIUM } }}
          values={{ formTitle }}
          inline
        />
      )
      setStatusToast(status, Status.SUCCESS)
      update({ fetchItems: true, fetchFolders: true, fetchFilterCounts: true })

      updateContainer({ windowMessages: [] })
    },
    [windowMessages]
  )

  const { t } = useTranslation()

  const renderCustomModal = (customTableAction: ActionsWithModals, listPageValues: ListPageCommonState, listPageAPI: ListPageAPI) => {
    const { update, setStatusToast, setBulkResponseModal, setError } = listPageAPI
    const { selectedRows, secondaryFolders } = listPageValues

    const selectedRow = selectedRows[0]

    const nonTableModals = [
      FormsCustomTableActions.CREATE_FROM_TEMPLATE,
      FormsCustomTableActions.CREATE_FROM_MIGRATED,
      FormsCustomTableActions.CREATE_FORM,
    ]
    if (!nonTableModals.includes(customTableAction)) {
      if (selectedRows.length === 0 || !selectedRow) {
        return
      }
    }

    const row = selectedRow as Form
    const selectedRowId = row?.externalId ?? '-1'
    const closeModal = () => update({ customTableAction: undefined, showCustomModal: false, fetchItems: true, isProcessingAction: true })

    const renderMigrateFormModal = () => {
      const selectedRowNames = selectedRows.map((row) => row.name ?? 'name')
      const selectedRowIds = selectedRows.map((row) => row.externalId ?? '-1')
      const warnings = t(
        'Migrating a form does not override the original classic version, but creates a copy as a new form. Your classic form will continue to be available to you in its current state after migration.'
      )
      return (
        <BulkActionsModal
          className={`${rootClass}__migrate-confirmation`}
          isOpen
          errorMessages={[]}
          successMessages={selectedRowNames}
          successTitle={t('ListPage.Forms.Migrate.Confirm.SuccessTitle', tProps)}
          title={t('ListPage.Forms.Migrate.Confirm.Title', tProps)}
          warnings={warnings}
          warningAsInfo
          hideCardIcons
          primaryButtonText={t('ListPage.Forms.Migrate.Confirm.ButtonText', tProps)}
          showLoadingOnPrimary
          onAction={async () => {
            const { data, errors } = await migrateClassicFormsRequest({ formIds: selectedRowIds.map((id) => `f-${id}`) })

            update({ showCustomModal: false })
            if (!data?.migrateClassicForms.failedIds?.length) {
              update({ fetchItems: true, forceResetSelectedRows: true })
              setStatusToast(t('ListPage.Forms.Migrate.SuccessToast', tProps), Status.SUCCESS)
            } else {
              setBulkResponseModal(data.migrateClassicForms, 'ListPage.Forms.Migrate.Bulk', { className: `${rootClass}__migrate-response` })
            }
            update({ fetchFolders: true })

            if (errors) {
              setError(t('ListPage.Forms.Migrate.FailToast', tProps), errors)
            }
          }}
          onClose={() => {
            update({ showCustomModal: false })
          }}
        />
      )
    }

    const tProps = { count: selectedRows.length }

    const customModal: {
      [key in ActionsWithModals]: () => ReactNode
    } = {
      [FormsCustomTableActions.MIGRATE_FORMS]: () => renderMigrateFormModal(),
      [FormsCustomTableActions.MIGRATE_FORM]: () => renderMigrateFormModal(),
      [FormsCustomTableActions.CREATE_FORM]: () => {
        const onSelectFormCreateMethod = (method: CreateFormMethod) => {
          if (method === CreateFormMethod.NEW) {
            openFormBuilderWindow('createNewForm', 'blank', '', hasReactWrapper)
            closeModal()
          } else if (method === CreateFormMethod.TEMPLATE) {
            update({ customTableAction: FormsCustomTableActions.CREATE_FROM_TEMPLATE })
          } else if (method === CreateFormMethod.MIGRATE) {
            update({ customTableAction: FormsCustomTableActions.CREATE_FROM_MIGRATED })
          } else if (method === CreateFormMethod.CLASSIC) {
            openFormBuilderWindow('createClassicForm', '', '', hasReactWrapper, true)
            closeModal()
          } else {
            closeModal()
          }
        }
        return (
          <CreateFormModal
            onCreate={(method) => onSelectFormCreateMethod(method)}
            onCancel={closeModal}
            showClassicOptions={!useOnlyNewForms}
            disableTemplateOption={!formTemplateExists}
            isOpen
          />
        )
      },
      [FormsCustomTableActions.CREATE_FROM_TEMPLATE]: () => {
        const onSelectFormTemplate = async (items: ItemDto[]) => {
          openFormBuilderWindow('createFormFromTemplate', 'blank', `template=${items[0].externalId}`, hasReactWrapper)
          closeModal()
        }

        return (
          <FormTemplatesPickerModal
            {...getCreateFormFromTemplateModalProps(t)}
            onSubmit={(items) => onSelectFormTemplate(items as ItemDto[])}
            onClose={() => update({ customTableAction: FormsCustomTableActions.CREATE_FORM })}
          />
        )
      },
      [FormsCustomTableActions.CREATE_FROM_MIGRATED]: () => {
        const onSelectFormToMigrate = async (items: ItemDto[]) => {
          openFormBuilderWindow('createMigratedForm', `f-${items[0].externalId}`, 'converted=1', hasReactWrapper)
          closeModal()
        }
        return (
          <FormsPickerModal
            {...getCreateFormFromMigratedModalProps(t)}
            onSubmit={(items) => onSelectFormToMigrate(items as ItemDto[])}
            onClose={() => update({ customTableAction: FormsCustomTableActions.CREATE_FORM })}
          />
        )
      },
      [FormsCustomTableActions.CREATE_TEMPLATE]: () => {
        const onCreateTemplate = async (folderId: number) => {
          const { data, errors } = await createTemplateFromFormRequest({ formId: selectedRowId, folderId, importSettings: importFormSettings })

          if (data) {
            openFormBuilderWindow('createTemplate', `${selectedRowId}`, 'istemplate=true', hasReactWrapper)
            sessionStorage.setItem(FORM_TEMPLATE_SUCCESS_FLAG, '1')
            history.push(`${rootContext}/content/formTemplates`)
          }

          if (errors) {
            setError(t('There was a problem creating your template'), errors)
          }
          closeModal()
        }

        return (
          <MoveToFolderModal
            folders={secondaryFolders as FolderData[]}
            addToFolder={true}
            description={t('Save to folder')}
            primaryButtonText={t('Create')}
            headerText={t('Create template')}
            folderPrimaryButtonText={t('Select')}
            options={
              <Checkbox
                label={t('Include Response and Ad Blocker settings')}
                onChange={(value) => setContainerValues((state) => ({ ...state, importFormSettings: value }))}
              />
            }
            onMoveClick={onCreateTemplate}
            onClose={closeModal}
            isOpen
          />
        )
      },
    }

    if (!(customTableAction in customModal)) {
      return
    }
    return customModal[customTableAction]()
  }

  const getConnectorName = (type: CRMConnectorType): string => {
    if (type === CRMConnectorType.SALESFORCE) {
      return 'Salesforce'
    } else if (type === CRMConnectorType.NETSUITE) {
      return 'Netsuite'
    }
    return type
  }

  const sidebarProps: SidebarProps = {
    sidebarHeader: 'Form Manager',
    hasSalesUsersAction: true,
    hasRecent: false,
    hasCreatedByMe: true,
    allItemFilter: allFormsFilter,
    renderCustomFilters: (params) => renderCustomFilters(params, useOnlyNewForms, hasCRMConnected ? getConnectorName(connectorType) : '', t),
    customFilterSelected: false,
  }

  const renderCreateButton = (update: Update) => (
    <Button
      buttonType={ButtonType.PRIMARY}
      iconPosition={ButtonIconPosition.LEFT}
      disabled={!userAllowedToCreateContent}
      onClick={() => update({ showCustomModal: true, customTableAction: FormsCustomTableActions.CREATE_FORM })}
      className={classNames(`${rootClass}__new-form`, {
        [`${rootClass}__new-form-disabled`]: !userAllowedToCreateContent,
      })}
    >
      <Svg type={SvgType.ICON} name={SvgNames.plus} />
      {t('Create form')}
    </Button>
  )

  const pageHeaderProps: PageHeaderProps = {
    pageTitle: 'Forms',
    renderPageHeaderContent: (update) => {
      return (
        <div className={`${rootClass}__header-content`}>
          {showUpgradeHelper && <UpgradeHelper onClick={onUpgradeHelperClick} />}
          {userAllowedToCreateContent ? (
            renderCreateButton(update)
          ) : (
            <Tooltip trigger={renderCreateButton(update)}>{t('Ask your administrator for permission to do this')}</Tooltip>
          )}
        </div>
      )
    },
  }

  const goToCatalog = () => window.open(CatalogLink.FORM, '_blank')

  const duplicateForm = async (params: DuplicateListingPageItem, listPageAPI: ListPageAPI) => {
    const { listingPageItem, newName, tags, folderId } = params
    const { update, setStatusToast, setError } = listPageAPI
    const formType = (listingPageItem as Form).formType

    const { data, errors } = await duplicateFormRequest({ formId: listingPageItem.externalId ?? '-1', newName, tags, folderId })

    if (data) {
      openFormBuilderWindow(`duplicateForm-${data.duplicateForm.id}`, `${data.duplicateForm.id}`, '', hasReactWrapper, formType === FormType.CLASSIC)
      setStatusToast(t('ListPage.Forms.Duplicate.SuccessToast'), Status.SUCCESS)
      update({ loading: true, fetchItems: true })
    } else {
      setError(t('ListPage.Forms.Duplicate.FailToast'), errors)
    }
  }

  return (
    <ListingPageContainer
      listingPageProps={{
        canPreview: true,
        doFetchItems,
        canCreate: userAllowedToCreateContent,
        canEdit: userAllowedToCreateContent,
        canDeleteItems: userAllowedToDeleteContent,
        canShareToCatalog: false,
        customPreviewItemCall: getItemPreview,
        customDuplicateItem: duplicateForm,
        canDuplicate: true,
        hasTabs: true,
        customDeleteItemsCall: deleteForms,
        isDeleteCallWithBulkResponse: true,
        shareToChildAccountsIcon: SvgNames.formSimple,
        getCustomEmptyListingProps: (setFilter, update, filter) => getCustomEmptyListingProps(setFilter, update, goToCatalog, filter),
        renderCustomModal,
        subTypes: getFormsSubTypes(connectorType),
        hasSecondaryFolders: true,
        secondaryItemType: ItemType.FORM_TEMPLATE,
        sidebarProps,
        pageHeaderProps,
        tableProps: tableProps(t, useOnlyNewForms, userAllowedToCreateContent, hasReactWrapper),
        filterInfoHoverText: {
          [FilterTypes.CREATED_BY_ME]: t('ListPage.Forms.FilterInfoHover.CreatedByMe'),
        },
        sortBy: [{ id: 'lastUpdated', desc: true }],
        onWindowMessage,
        onWindowActive,
        onPageLoad,
      }}
      dataTest={dataTest}
      itemType={ItemType.FORM}
      className={classNames(rootClass)}
    />
  )
}

export default FormsListingPageContainer
