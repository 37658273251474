import { gql } from '@apollo/client'

export default gql`
  query getFiscalCalendar($date: String!) {
    getFiscalCalendar(date: $date) {
      fiscalMonth {
        periodNumber
        year
        startTime
        endTime
      }
      fiscalQuarter {
        periodNumber
        year
        startTime
        endTime
      }
    }
  }
`
