import { Action } from '@thoughtspot/visual-embed-sdk/react'

export const ANALYTICS_VISIBLE_ACTIONS = [
  Action.AddFilter,
  Action.AddParameter,
  Action.AddToFavorites,
  Action.AnswerChartSwitcher,
  Action.ConfigureFilter,
  // NOTE: Per AO-84377 the commented out actions are being temporarily removed
  Action.CopyLink,
  Action.CreateMonitor,
  Action.CrossFilter,
  Action.Download,
  Action.DownloadAsCsv,
  Action.DownloadAsPdf,
  Action.DownloadAsPng,
  Action.DownloadAsXlsx,
  Action.DrillDown,
  Action.DrillExclude,
  Action.DrillInclude,
  Action.Edit,
  Action.EditDetails,
  Action.EditTitle,
  Action.Explore,
  Action.LiveboardInfo,
  Action.MakeACopy,
  Action.Pin,
  Action.Present,
  Action.Remove,
  Action.RemoveCrossFilter,
  Action.Save,
  Action.Schedule,
  Action.SchedulesList,
  Action.Share,
  Action.ToggleSize,
]

export const ANALYTICS_INVISIBLE_ACTIONS = [
  Action.EditTML,
  Action.ExportTML,
  Action.ImportTML,
  Action.ManagePipelines,
  Action.RequestVerification,
  Action.ShowUnderlyingData,
  Action.SyncToOtherApps,
  Action.SyncToSheets,
  Action.UpdateTML,
]
