import React, { FC, useCallback, useMemo, useState } from 'react'

import { IImagePickerItem, getFileType } from '@components/AssetPickers/ImagePickerModal/utils/ImagePickerModal.utils'
import Button, { ButtonType } from '@components/Button'
import StaticImage from '@components/StaticImage/StaticImage'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Svg, { SvgNames } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { formatDateWithAbbreviations } from '@utils/date'

import './ImagePickerModalPreview.css'

interface ImagePickerModalPreviewProps {
  image: IImagePickerItem
  onEdit: (id: string) => void
  isLogo?: boolean
  className?: string
  dataTest?: string
}

const rootClass = 'image-picker-modal-preview'

const ImagePickerModalPreview: FC<ImagePickerModalPreviewProps> = ({ image, onEdit, isLogo, dataTest = rootClass }) => {
  const { id, title, url, modified } = image

  const { t } = useTranslation()
  const [loadError, setLoadError] = useState<boolean>(false)

  const handleLoadError = useCallback(() => setLoadError(true), [])

  const handleEdit = useCallback(() => onEdit(id), [id, onEdit])

  const properties = useMemo(() => {
    const { url, size, dimensions, created, modified } = image
    const props = [
      { label: t('Type'), value: `${(getFileType(url) ?? '').toUpperCase()} ${t('Image')}` },
      { label: t('Dimensions'), value: dimensions ? dimensions : '-' },
      { label: t('File size'), value: size ? size : '-' },
    ]

    if (!isLogo) {
      return [
        ...props,
        { label: t('Date created'), value: created ? formatDateWithAbbreviations(Number(created)) : '-' },
        { label: t('Last updated'), value: modified ? formatDateWithAbbreviations(Number(modified)) : '-' },
      ]
    }

    return props
  }, [isLogo, image, t])

  return (
    <div className={rootClass} data-test={dataTest}>
      <div className={`${rootClass}__image-holder`}>
        <div className={`${rootClass}__image-holder__inner`}>
          {loadError ? (
            <StaticImage name={StaticImageNames.imageLight} dataTest={`${dataTest}-load-error-image`} />
          ) : (
            // After image edit url will still same, but 'modified' will change
            // and will cause <img /> re-render. Logos url already contain ts
            <img src={isLogo ? url : `${url}?ts=${modified}`} alt={title} onError={handleLoadError} data-test={`${dataTest}-image`} />
          )}
        </div>
      </div>
      <div className={`${rootClass}__info`}>
        <div className={`${rootClass}__info-name`} data-test={`${dataTest}-info-name`}>
          <Typography text={title} type={TextType.SECTION_HEADER} weight={TextWeight.BOLD} />
        </div>
        <div className={`${rootClass}__info-properties-container`}>
          <Typography text={t('Properties')} weight={TextWeight.MEDIUM} dataTest={`${dataTest}-info-properties-header`} />
          <div className={`${rootClass}__info-properties`}>
            <div className={`${rootClass}__info-properties-col`}>
              {properties.map(({ label }) => (
                <Typography key={label} inline text={label} type={TextType.BODY_TEXT_LIGHT} dataTest={`${dataTest}-info-property-${label}`} />
              ))}
            </div>
            <div className={`${rootClass}__info-properties-col`}>
              {properties.map(({ label, value }) => (
                <Typography key={label} inline text={value} dataTest={`${dataTest}-info-property-${value}`} />
              ))}
            </div>
          </div>
          <Button
            buttonType={ButtonType.INFO}
            onClick={handleEdit}
            className={`${rootClass}__info-properties-edit-button`}
            dataTest={`${dataTest}-info-property-edit-button`}
          >
            <Svg name={SvgNames.pencil} />
            {t(isLogo ? 'Edit logo' : 'Edit image')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ImagePickerModalPreview
