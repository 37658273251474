import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'
import dayjs, { Dayjs } from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import DatePicker from '@components/DatePicker/DatePicker'
import InfoTooltip from '@components/InfoTooltip/InfoTooltip'
import Label from '@components/Label/Label'
import Radio from '@components/Radio'
import RadioGroup from '@components/RadioGroup'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import TimePickerV2, { TimePickerV2Format } from '@components/TimePickerV2/TimePickerV2'
import TimeZoneSelectV2 from '@components/TimeZoneSelectV2/TimeZoneSelectV2'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { DATE_FORMAT } from '@src/pages/EmailComposer/utils/BeeEditor.constants'
import { getHourAndMinuteFromTime } from '@src/pages/EmailComposer/utils/EmailComposer.utils'
import { EmailComposerContext, SendTimeType } from '@utils/composer/context/EmailComposer.context'
import { defaultTimezone, getDynamicTimezones, getTimezoneLabel } from '@utils/timezones'

dayjs.extend(utc)
dayjs.extend(timezone)

interface TimezoneSuffixProps {
  showValidationErrorsByDefault: boolean
  rootClass: string
  dataTest: string
}

const MIN_DATE = new Date().valueOf()

const TimezoneSuffix: FC<TimezoneSuffixProps> = ({ showValidationErrorsByDefault, rootClass, dataTest }) => {
  const {
    api: { update },
    values: {
      message: {
        sendMethod: { sendtype, senddate, sendtime, sendtimezone, defaultsendtimezone },
      },
      messageConfiguration: {
        sendDetails: { hideSendMethodTimezone, hideSendMethodManuallyTimezone },
      },
    },
  } = useContext(EmailComposerContext)
  const { t } = useTranslation()

  const isTimezone = sendtype === SendTimeType.TIME_ZONE
  const [showDateErrors, setShowDateErrors] = useState(showValidationErrorsByDefault)
  const [showTimeErrors, setShowTimeErrors] = useState(showValidationErrorsByDefault)

  const handleDateChange = useCallback((senddate?: Date) => update({ message: { sendMethod: { senddate } } }), [update])
  const handleDateBlur = useCallback(() => setShowDateErrors(true), [])
  const handleDateFocus = useCallback(() => setShowDateErrors(false), [])
  const handleTimeBlur = useCallback(() => setTimeout(() => setShowTimeErrors(true), 100), [])
  const handleTimeFocus = useCallback(() => setShowTimeErrors(false), [])

  const handleTimeChange = useCallback((_: Dayjs | null, sendtime: string) => update({ message: { sendMethod: { sendtime: sendtime } } }), [update])

  const handleTimeZoneChange = useCallback(
    (value: string) => {
      update({ message: { sendMethod: { sendtimezone: value } } })
    },
    [update]
  )

  const handleFallbackTimeZoneChange = useCallback(
    (value: string) => {
      update({ message: { sendMethod: { defaultsendtimezone: value } } })
    },
    [update]
  )

  const handleCheckboxChange = useCallback(() => {
    update({
      message: { sendMethod: { sendtype: isTimezone ? SendTimeType.LATER : SendTimeType.TIME_ZONE } },
      previousSendType: isTimezone ? SendTimeType.TIME_ZONE : SendTimeType.LATER,
    })
  }, [update, isTimezone])

  const { dateMissing, dateInPast, timeMissing, timeInPast, defaultTimeInPast } = useMemo(() => {
    const timeMissing = !sendtime
    if (!senddate) {
      return { dateMissing: true, timeMissing }
    }
    let sendOn = dayjs(senddate)
    if (!sendOn.isValid()) {
      return { dateMissing: true, timeMissing }
    }

    const dateInPast = sendOn.endOf('day').valueOf() < dayjs().startOf('day').valueOf()
    if (dateInPast) {
      return { dateInPast }
    }
    const { hour, minute } = getHourAndMinuteFromTime(sendtime)
    if (typeof hour !== 'number' || typeof minute !== 'number') {
      return { timeMissing: true }
    }
    const parsedTime = dayjs(sendtime, TimePickerV2Format.HH_MM)
    sendOn = sendOn.hour(parsedTime.hour()).minute(parsedTime.minute())
    if (!isTimezone && sendtimezone) {
      sendOn = sendOn.tz(sendtimezone, true)
    }
    if (!sendOn.isValid()) {
      return { timeMissing: true }
    }

    const timeInPast = sendOn.valueOf() < dayjs().valueOf()
    const defaultTimeInPast = sendOn.tz(defaultsendtimezone, true).valueOf() < dayjs().valueOf()

    if (timeInPast) {
      return { timeInPast, defaultTimeInPast }
    }

    return {}
  }, [sendtime, senddate, sendtimezone, isTimezone])

  useEffect(() => {
    if (sendtime) {
      setShowTimeErrors(true)
    }
    if (senddate) {
      setShowDateErrors(true)
    }
  }, [senddate, sendtime, showValidationErrorsByDefault])

  const getTimezoneOptions = useMemo(() => {
    return getDynamicTimezones()
  }, [])

  return (
    <div className={`${rootClass}__send-timezone-suffix`}>
      <div className={`${rootClass}__send-timezone-suffix-top`}>
        <DatePicker
          label="Date"
          value={senddate}
          format={DATE_FORMAT}
          minDate={MIN_DATE}
          required
          onChange={handleDateChange}
          className={`${rootClass}__send-timezone-suffix-top-date`}
          dataTest={`${dataTest}-date-picker-suffix`}
          errorMessage={
            showDateErrors ? (dateMissing ? 'Date is missing' : dateInPast && !isTimezone ? 'Date is in the past' : undefined) : undefined
          }
          status={showDateErrors && (dateMissing || (!isTimezone && dateInPast)) ? 'error' : undefined}
          hideErrorIcon
          onBlur={handleDateBlur}
          onFocus={handleDateFocus}
        />
        <TimePickerV2
          labelKey={t('Time')}
          required
          defaultValue={sendtime}
          format={TimePickerV2Format.HH_MM}
          isParentModal
          onSubmit={handleTimeChange}
          className={`${rootClass}__send-timezone-suffix-top-time`}
          status={showTimeErrors && (timeMissing || (!isTimezone && timeInPast)) ? 'error' : undefined}
          errorMessage={showTimeErrors ? (timeMissing ? 'Time is missing' : timeInPast && !isTimezone ? 'Time is in missing' : undefined) : undefined}
          onBlur={handleTimeBlur}
          onFocus={handleTimeFocus}
          hideErrorIcon
        />
      </div>
      <Label>
        <Typography text={t('Time zone')} weight={TextWeight.MEDIUM} inline />
      </Label>
      <RadioGroup verticalLayout>
        {!hideSendMethodManuallyTimezone ? (
          <Radio
            labelChildren={<Typography text={t(`SendDetails.SendMethod.Options.Schedule.Timezone.Manually.Radio`)} />}
            checked={!isTimezone}
            onChange={handleCheckboxChange}
          />
        ) : (
          <div />
        )}
        {!isTimezone ? (
          <div
            className={classNames(
              `${rootClass}__send-timezone-suffix-timezone-container`,
              !hideSendMethodManuallyTimezone && 'push-down-x2 push-up-x2'
            )}
          >
            {!hideSendMethodManuallyTimezone && <div className={`${rootClass}__send-timezone-suffix-timezone-divider`} />}
            <TimeZoneSelectV2
              dataTest={`${dataTest}-send-timezone-suffix-timezone-select`}
              className={`${rootClass}__send-timezone-suffix-timezone`}
              label={getTimezoneLabel(sendtimezone)}
              selectProps={{
                options: getTimezoneOptions,
                value: { label: getTimezoneLabel(sendtimezone), value: sendtimezone || defaultTimezone.name },
                onChange: (e) => {
                  handleTimeZoneChange(e?.value || defaultTimezone.name)
                },
              }}
            />
          </div>
        ) : (
          <div />
        )}
        {!hideSendMethodTimezone ? (
          <Radio
            dataTest={`${dataTest}-send-timezone-suffix-default-timezone-radio`}
            labelChildren={
              <div className={`${rootClass}__send-timezone-suffix-timezone-label`}>
                <Typography text={t('SendDetails.SendMethod.Options.Schedule.Timezone.Default.Radio')} />
                <InfoTooltip>{t('SendDetails.SendMethod.Options.Schedule.Timezone.Tooltip')}</InfoTooltip>
              </div>
            }
            checked={isTimezone}
            onChange={handleCheckboxChange}
          />
        ) : (
          <div />
        )}
        {isTimezone ? (
          <div
            className={classNames(
              `${rootClass}__send-timezone-suffix-timezone-container`,
              !hideSendMethodManuallyTimezone && 'push-down-x2 push-up-x2'
            )}
          >
            <div className={`${rootClass}__send-timezone-suffix-timezone-divider`} />
            <div className={`${rootClass}__send-timezone-suffix-full-width`}>
              <Typography
                className="push-up"
                text={t('SendDetails.SendMethod.Options.Schedule.Timezone.Default.Radio.Text')}
                weight={TextWeight.MEDIUM}
              />
              <TimeZoneSelectV2
                dataTest={`${dataTest}-send-timezone-suffix-default-timezone-select`}
                className={`${rootClass}__send-timezone-suffix-timezone`}
                label={getTimezoneLabel(defaultsendtimezone)}
                selectProps={{
                  options: getTimezoneOptions,
                  value: { label: getTimezoneLabel(defaultsendtimezone), value: defaultsendtimezone || defaultTimezone.name },
                  onChange: (e) => {
                    handleFallbackTimeZoneChange(e?.value || defaultTimezone.name)
                  },
                }}
              />
              {defaultTimeInPast && (
                <div className="flex-align-center push-down" data-test={dataTest}>
                  <Svg {...{ name: SvgNames.inputStatusInvalidNoFill, type: SvgType.ICON, fill: SvgColor.ERROR_TEXT }} className={'push-left'} />
                  <Typography
                    text={t('SendDetails.SendMethod.Options.Schedule.Timezone.Default.Radio.Error')}
                    type={TextType.ERROR_NEW}
                    lineHeight={LineHeight.MEDIUM_SMALL}
                    tagProps={{ bold: { weight: TextWeight.BOLD } }}
                    inline
                  />
                </div>
              )}
            </div>
          </div>
        ) : (
          <div />
        )}
      </RadioGroup>
    </div>
  )
}

export default TimezoneSuffix
