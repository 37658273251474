import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Typography, { TextType, TextWeight } from '@components/Typography/Typography'

export const ValidFormatsSubheader: FC<{ isLandingPage?: boolean; dataTest?: string }> = ({ isLandingPage, dataTest }) => {
  const accept = isLandingPage ? 'jpg, jpeg, gif, png, svg' : 'jpg, jpeg, gif, png'

  const { t } = useTranslation()

  return (
    <Typography
      text={t('Insert.Image.Modal.ValidFormats.SubHeader', { types: accept })}
      type={TextType.BODY_TEXT_LIGHT}
      tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
      dataTest={dataTest}
    />
  )
}
