import { gql } from '@apollo/client'

export default gql`
  query getColumnIndexOrder($segmentId: String) {
    getColumnIndexOrder(segmentId: $segmentId) {
      accountId
      createdTime
      enabled
      fixed
      hidden
      id
      order
      segmentId
      updatedTime
    }
  }
`
