import { gql } from '@apollo/client'

export default gql`
  query getWebPagesFiltered($search: String) {
    getWebPagesFiltered(search: $search) {
      name
      url
    }
  }
`
