import React, { FC, useContext, useEffect, useState } from 'react'

import Modal, { ModalHeader } from '@components/Modal'
import { TableColumn } from '@components/Table/Table'
import TableWithLoader from '@components/TableWithLoader/TableWithLoader'
import { useTranslation } from '@const/globals'
import EntityRecordTableResult from '@graphql/microservices/crm/EntityRecordTableResult'
import { GetEntityRecordTableQuery, GetEntityRecordTableQueryVariables } from '@graphql/types/microservice/crm-types'
import { DataManagementContext, RecordModalState } from '@src/pages/datamanagement/context/DataManagementContext'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'

import './EntityRecordTable.css'

type Props = {
  className?: string
  dataTest?: string
  entityDisplayName: string
  recordCounts: number
  showResultsModal: boolean
  entityIdentifier: string
}

const rootClass = 'entity-record-table'
const COL_WIDTH = 156

interface State {
  recordRows?: any[]
  pageIndex: number
  pageSize: number
  columns: TableColumn[]
}

const EntityRecordTable: FC<Props> = (props: Props) => {
  const { entityDisplayName, entityIdentifier, recordCounts, showResultsModal, dataTest = rootClass } = props
  const { update } = useContext(DataManagementContext)

  const { t } = useTranslation()

  const [pageCount, setPageCount] = useState(0)
  const [columns, setColumns] = useState<TableColumn[]>([])
  const [state, setState] = useState<State>({
    pageIndex: 0,
    pageSize: 50,
    columns: [],
  })

  useEffect(() => {
    setPageCount(recordCounts / state.pageSize !== 0 ? Math.trunc(recordCounts / state.pageSize) + 1 : recordCounts / state.pageSize)
  }, [state.pageSize])

  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.CRM })

  const { loading, data } = useQueryOnMount<GetEntityRecordTableQuery, GetEntityRecordTableQueryVariables>(EntityRecordTableResult, {
    client,
    fetchPolicy: 'network-only',
    variables: {
      type: entityIdentifier as string,
      count: state.pageSize,
      offset: state.pageIndex * state.pageSize,
      sort: false,
    },
  })

  useEffect(() => {
    if (data !== undefined && data.entityType !== undefined) {
      const syncDataResult = data.entityType.entityTypeDataMapping
      if (columns.length == 0) {
        const colNameLabelPair = data.entityType.entityTypeFields ?? []
        const columns = buildTableColumns(colNameLabelPair ?? [])
        setColumns(columns)
      }
      setState({ ...state, recordRows: buildTableRows(syncDataResult ?? []) })
    }
  }, [data, loading])

  const buildTableColumns = (keyLabelPair?: any[]): TableColumn[] => {
    if (keyLabelPair != undefined && keyLabelPair.length > 0) {
      const tableCol: TableColumn[] = keyLabelPair.map((column, idx) => ({
        Header: column.label,
        accessor: t(column.name),
        align: 'left',
        className: `${rootClass}__col_header`,
        minWidth: idx == 0 ? COL_WIDTH - 24 : COL_WIDTH,
        maxWidth: idx == 0 ? COL_WIDTH - 24 : COL_WIDTH,
        disableSortBy: true,
      }))
      return tableCol
    } else {
      return []
    }
  }

  const buildTableRows = (entityTypeDataMapping: any[]): any[] => {
    const tableArray: any[] = []
    entityTypeDataMapping.forEach((row) => {
      tableArray.push(row)
    })
    return tableArray
  }

  const fetchData = (pageIndex: number, pageSize: number) => {
    setState({ ...state, pageIndex, pageSize })
  }

  const closeModal = () => {
    update('recordModal', {
      openRecordModal: false,
      modalEntityName: '',
      modalRecordCount: 0,
    } as RecordModalState)
  }
  const closeButton = { closeButton: closeModal }

  const hasOverflow = (state?.recordRows ?? []).length > 10

  return (
    <div className={`${rootClass}`} data-test={`${dataTest}`}>
      <Modal
        header={
          <ModalHeader {...closeButton} className={`${rootClass}__title-text`}>
            {t(entityDisplayName)}
          </ModalHeader>
        }
        allowFullScreen={true}
        noPadding={true}
        isOpen={showResultsModal}
        className={`${rootClass}__modal_heading`}
      >
        <div className={`${rootClass}__rec_count`}>{t(recordCounts) + ' records'}</div>
        <div className={`${rootClass}__outer`}>
          <TableWithLoader
            data={state?.recordRows && !loading ? state?.recordRows : []}
            columns={columns}
            maxHeight={'54rem'}
            hasOverflow={hasOverflow}
            className={`${rootClass}__table`}
            canPaginate={true}
            controlledPageCount={pageCount}
            fetchData={fetchData}
            initialState={{ pageSize: state.pageSize, pageIndex: state.pageIndex }}
            pageSizeOptions={[50, 100, 150, 200]}
            loading={loading}
          />
        </div>
      </Modal>
    </div>
  )
}

export default EntityRecordTable
