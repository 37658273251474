import React, { FC, useMemo } from 'react'

import { ButtonType } from '@components/Button'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { UserToDelete } from '@src/pages/Settings/Users/hooks/useUsersDelete'

interface ConfirmUsersDeleteModalProps {
  onAnswer: (answer: YesNo) => void
  usersToDelete?: UserToDelete[]
  dataTest?: string
}

const rootClass = 'confirm-users-delete-modal'

const ConfirmUsersDeleteModal: FC<ConfirmUsersDeleteModalProps> = (props: ConfirmUsersDeleteModalProps) => {
  const { onAnswer, usersToDelete, dataTest = rootClass } = props
  const { t } = useTranslation()
  const count = useMemo<number>(() => usersToDelete?.length ?? 0, [usersToDelete])
  const userName = useMemo<string>(() => (usersToDelete?.length === 1 ? usersToDelete[0].name : ''), [usersToDelete])

  return (
    <ConfirmationModal
      isYesNo
      isDelete
      isOpen={!!count}
      deleteButtonText={count > 1 ? 'Delete users' : 'Delete user'}
      deleteButtonType={ButtonType.ERASE}
      title={t('Confirm.User.Delete.Modal.Title', { userName, count })}
      body={
        <>
          <Typography text={t('Confirm.User.Delete.Modal.1stMessage', { count })} type={TextType.BODY_TEXT_LIGHT} />
          <Typography text={t('Confirm.User.Delete.Modal.2ndMessage')} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.BOLD} />
        </>
      }
      onAnswer={onAnswer}
      className={rootClass}
      dataTest={dataTest}
    />
  )
}

export default ConfirmUsersDeleteModal
