import React, { FC, useMemo, CSSProperties } from 'react'

import classNames from 'classnames'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { getFormattedNumber } from '@utils/numbers'

import './SingleLineChart.css'

export enum SingleLineChartColor {
  TEAL = 'teal',
  TEXT_TEAL = 'text-teal',
  ORANGE = 'orange',
  PURPLE = 'purple',
}

interface Props {
  className?: string
  dataTest?: string
  actualValue: number
  total: number
  fractionDigits?: number
  actualColor?: SingleLineChartColor
  showWarning?: boolean
  tooltipBarInfo?: string
  text?: string | React.ReactNode
  smallerTotal?: boolean
  percentageFixedColor?: SingleLineChartColor
  title?: string | React.ReactNode
  helperText?: string
  showError?: boolean
  showHelperTextIcon?: boolean
}

const rootClass = 'single-line-chart'

const WARNING_PERCENTAGE = 90
const ERROR_PERCENTAGE = 100

const SingleLineChart: FC<Props> = (props: Props) => {
  const {
    dataTest = rootClass,
    className = '',
    actualValue = 0,
    total = 0,
    fractionDigits = 0,
    actualColor = SingleLineChartColor.TEAL,
    showWarning,
    tooltipBarInfo,
    text = '',
    smallerTotal,
    percentageFixedColor,
    helperText,
    title,
    showError,
    showHelperTextIcon,
  } = props
  const { t } = useTranslation()

  const isTitleString = typeof title === 'string'
  const isTextString = typeof text === 'string'
  const percentage = useMemo(() => (total === 0 ? 0 : (actualValue / total) * 100), [actualValue, total])
  const fixedPercentage = percentage.toFixed(fractionDigits)
  const lineLength: CSSProperties = { width: `${actualValue > total ? 100 : percentage}%` }

  const renderBar = () =>
    tooltipBarInfo ? (
      <Tooltip
        trigger={
          <div
            className={classNames(`${rootClass}__bar-actual`, `${rootClass}__bar-actual-${actualColor}`, {
              [`${rootClass}__bar-actual-hidden`]: actualValue === 0,
              [`${rootClass}__bar-actual-warning`]:
                showWarning && parseInt(fixedPercentage) >= WARNING_PERCENTAGE && parseInt(fixedPercentage) < ERROR_PERCENTAGE,
              [`${rootClass}__bar-actual-red`]:
                showError && (showWarning ? parseInt(fixedPercentage) >= ERROR_PERCENTAGE : parseInt(fixedPercentage) >= WARNING_PERCENTAGE),
            })}
          />
        }
        triggerClassName={`${rootClass}__tooltip`}
      >
        {t(tooltipBarInfo)}
      </Tooltip>
    ) : (
      <div
        className={classNames(`${rootClass}__bar-actual`, `${rootClass}__bar-actual-${actualColor}`, {
          [`${rootClass}__bar-actual-hidden`]: actualValue === 0,
          [`${rootClass}__bar-actual-warning`]:
            showWarning && parseInt(fixedPercentage) >= WARNING_PERCENTAGE && parseInt(fixedPercentage) < ERROR_PERCENTAGE,
          [`${rootClass}__bar-actual-red`]:
            showError && (showWarning ? parseInt(fixedPercentage) >= ERROR_PERCENTAGE : parseInt(fixedPercentage) >= WARNING_PERCENTAGE),
        })}
      />
    )

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={classNames(`${rootClass}__values`, { [`${rootClass}__values-small-total`]: smallerTotal })}>
        {showError && !showHelperTextIcon && percentage >= 100 && (
          <Svg className={`${rootClass}__warning-icon`} name={SvgNames.warningSolidNoFill} type={SvgType.MEDIUM} />
        )}
        {title ? (
          isTitleString ? (
            <Typography text={title} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
          ) : (
            title
          )
        ) : (
          <>
            <Typography text={getFormattedNumber(actualValue)} weight={TextWeight.BOLD} inline />
            <Typography
              text={`/${getFormattedNumber(total)}`}
              type={smallerTotal ? TextType.BODY_TEXT_SMALL_LIGHT : TextType.BODY_TEXT_LIGHT}
              weight={TextWeight.MEDIUM}
              inline
            />
          </>
        )}
        {isTextString ? (
          <Typography
            className={classNames({
              [`${rootClass}__percentage-warning`]: showError && percentage >= WARNING_PERCENTAGE,
              [`${rootClass}__percentage-fixed-${percentageFixedColor}`]: percentageFixedColor,
            })}
            text={text ? text : `${percentage !== 100 && fixedPercentage === '100' ? 99 : fixedPercentage}%`}
            type={actualValue === 0 || text ? TextType.BODY_TEXT_LIGHT : TextType.BODY_TEXT_SMALL}
            weight={TextWeight.MEDIUM}
            lineHeight={LineHeight.MEDIUM_LARGE}
            inline
          />
        ) : (
          text
        )}
      </div>
      <div className={`${rootClass}__bar`}>
        <div className={`${rootClass}__bar-total`} />
        <div style={lineLength}>{renderBar()}</div>
      </div>
      {helperText && (
        <div className={`${rootClass}__helper-text`}>
          {showHelperTextIcon && percentage >= WARNING_PERCENTAGE && (
            <Svg className={`${rootClass}__warning-icon`} name={SvgNames.warningSolidNoFill} type={SvgType.LARGER_ICON} />
          )}
          <Typography text={helperText} type={TextType.BODY_TEXT_SMALL_LIGHT} lineHeight={LineHeight.MEDIUM_SMALL} />
        </div>
      )}
    </div>
  )
}

export default SingleLineChart
