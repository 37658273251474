import dayjs from 'dayjs'

import { filterNotEmptyArray } from '@utils/array'
import { IPluginDisplayConditionExtended } from '@utils/composer/beeEditor/beeEditorTypes'
import { DisplayConditionSetPredicate } from '@utils/composer/emailComposer/types'
import { getPersonalizationCommon } from '@utils/personalization'

import { findDateFormat } from './components/DisplayConditions/DisplayConditions.dates'
import { findFieldOperator } from './components/DisplayConditions/DisplayConditions.operators'
import { DisplayConditionsForm } from './components/DisplayConditions/DisplayConditions.types'

export const getDynamicContentDelimiter = (groupLabel: string): string => `{% ${groupLabel} %}`

export const processDisplayConditionForm = (displayConditions: DisplayConditionsForm): IPluginDisplayConditionExtended => {
  return {
    before: getDynamicContentDelimiter(displayConditions.header),
    after: getDynamicContentDelimiter(displayConditions.header),
    className: '',
    description: displayConditions.description,
    isActive: true,
    label: displayConditions.header,
    type: '',
    name: '',
    condition: displayConditions.groups
      .map((group) => {
        if (!group.groupingOperator || !group.fields?.length) {
          return undefined
        }
        return {
          description: group.header,
          html: '',
          text: '',
          useHtmlText: false,
          matchType: group.groupingOperator,
          predicate:
            group.fields
              ?.map((field) => {
                if (!field.operator || !field.personalization) {
                  return undefined
                }
                const personalization = field.personalization ? getPersonalizationCommon(field.personalization) : undefined
                const operator = findFieldOperator(field.operator)
                const valueCount = operator?.valueCount ?? 1
                const jsDateFormat = findDateFormat(field.dateFormat).jsFormat
                const processedValues = field.values.map((value) => (operator?.valueType !== 'date' ? value : dayjs(value).format(jsDateFormat)))
                const condition: DisplayConditionSetPredicate = {
                  field: personalization?.mappingName ?? '',
                  uiField: field.personalization,
                  type: field.operator?.split('.')[0],
                  uiType: field.operator,
                  criteria: valueCount >= 1 ? processedValues[0] : '',
                  criteria_max: valueCount === 2 ? processedValues[1] : '',
                  dateFormat: operator?.valueType === 'date' ? field.dateFormat : '',
                }
                return condition
              })
              .filter(filterNotEmptyArray) ?? [],
        }
      })
      .filter(filterNotEmptyArray)
      .filter((group) => !!group.predicate.length),
  }
}

export const processDisplayConditionRow = (numberOfExistingConditions: number, row?: IPluginDisplayConditionExtended): DisplayConditionsForm => {
  const count = numberOfExistingConditions + 1
  const rowHeader = count === 1 ? `Untitled conditions` : `Untitled conditions (${numberOfExistingConditions + 1})`
  return {
    header: row?.label ?? rowHeader,
    description: row?.description ?? '',
    groups:
      row?.condition?.map((condition) => {
        return {
          header: condition.description,
          groupingOperator: condition.matchType,
          fields: condition.predicate.map((predicate) => {
            const op = findFieldOperator(predicate.uiType)
            const values = op?.valueCount === 0 ? [] : op?.valueCount === 1 ? [predicate.criteria] : [predicate.criteria, predicate.criteria_max]
            const jsDateFormat = findDateFormat(predicate.dateFormat).jsFormat
            const processedValues = op?.valueType !== 'date' ? values : values.map((value) => dayjs(value, jsDateFormat).toISOString())
            return {
              personalization: predicate.uiField,
              operator: `${predicate.uiType}`,
              values: processedValues.filter(filterNotEmptyArray),
              dateFormat: predicate.dateFormat,
            }
          }),
        }
      }) ?? [],
  }
}
