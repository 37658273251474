import React, { FC, useState } from 'react'

import classNames from 'classnames'

import BackButton from '@components/BackButton/BackButton'
import Button, { ButtonType } from '@components/Button'
import Modal, { ModalBody, ModalHeader, ModalFooter } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Radio from '@components/Radio'
import Typography, { ModalBodyStyle, ModalHeaderStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import { OptOutLinkType, useOptOutLinks } from './useOptOutLinks'

import './OptOutLinkModal.css'

export interface OptOutLinkModalProps {
  className?: string
  dataTest?: string
  onClose: (goBack: boolean) => void
  onAction: (selectedOption: OptOutLinkType) => void
  isOpen: boolean
}

interface OptOutLinkModalState {
  selectedOption?: OptOutLinkType
}

const rootClass = 'opt-out-link-modal'

const OptOutLinkModal: FC<OptOutLinkModalProps> = (props: OptOutLinkModalProps) => {
  const { dataTest = rootClass, className = '', onAction, onClose, isOpen } = props
  const [state, setState] = useState<OptOutLinkModalState>({ selectedOption: undefined })
  const { selectedOption } = state

  const { t } = useTranslation()

  const optOutLinks = useOptOutLinks()

  const handleAction = () => {
    selectedOption && onAction(selectedOption)
  }

  const handleClose = () => {
    onClose(false)
  }

  const handleBack = () => {
    onClose(true)
  }

  const header = (
    <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__header`}>
      <BackButton onClick={handleBack} />
      <Typography text={t('EmailComposer.AssetLinks.OptOut.Modal.Title')} {...ModalHeaderStyle} />
    </ModalHeader>
  )

  const renderRadios = () => {
    if (!optOutLinks) {
      return (
        <Typography
          text={t(`There was a problem retrieving your opt-out links.`)}
          className={`${rootClass}__radio-group__radio-description`}
          {...ModalBodyStyle}
          type={TextType.ERROR}
        />
      )
    }

    return Object.keys(optOutLinks).map((key) => (
      <div key={key}>
        <Radio
          label={t(`EmailComposer.AssetLinks.OptOut.Modal.${key}.Label`)}
          checked={selectedOption === key}
          onChange={() => setState({ selectedOption: key as OptOutLinkType })}
          labelTypography={{ weight: TextWeight.BOLD }}
          dataTest={`${dataTest}-radio-${key}`}
        />
        <Typography
          text={t(`EmailComposer.AssetLinks.OptOut.Modal.${key}.Description`)}
          className={`${rootClass}__radio-group__radio-description`}
          {...ModalBodyStyle}
        />
      </div>
    ))
  }

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
      <ModalBody className={`${rootClass}__body`}>
        <div className={`${rootClass}__radio-group`}>
          <>
            <Typography text={t('EmailComposer.AssetLinks.OptOut.Modal.Description')} {...ModalBodyStyle} />
            {renderRadios()}
          </>
        </div>
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form} className={`${rootClass}__footer`}>
        <Button buttonType={ButtonType.TERTIARY} onClick={handleClose} dataTest={`${dataTest}-button-tertiary`}>
          {t('EmailComposer.AssetLinks.OptOut.Modal.CancelButton')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} onClick={handleAction} disabled={!selectedOption} dataTest={`${dataTest}-button-primary`}>
          {t('EmailComposer.AssetLinks.OptOut.Modal.InsertButton')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default OptOutLinkModal
