import React, { FC, ReactNode, Ref, useCallback, useMemo, useState } from 'react'

import classNames from 'classnames'

import { FolderActions, MAX_FOLDER_DEPTH } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import Button, { ButtonType } from '@components/Button/Button'
import CaretIcon, { CaretIconDirection } from '@components/CaretIcon'
import RowActions from '@components/RowActions/RowActions'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { RowAction } from '@components/Table/Table'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Toggle from '@components/Toggle'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { SubTypeDto } from '@graphql/types/microservice/categorization-types'
import { formatNumber } from '@utils/numbers'

import './Folder.css'

export interface FolderToggleProps {
  showToggle: boolean
  isToggleOn: (folder: FolderData) => boolean
  onToggleClick: (id: number, isEnabled: boolean) => void
}

export interface FolderProps {
  className?: string
  dataTest?: string
  activeFolderId?: number
  handleFolderClick: (id: number, parentId?: number) => void
  isOpen?: boolean
  openedFolders?: number[]
  onActionClick?: (folder: FolderData, action: FolderActions) => void
  folder: FolderData
  reference?: Ref<HTMLDivElement>
  children?: ReactNode
  onCaretClick: (folder: FolderData) => void
  toggleProps?: FolderToggleProps
  hideMoveItems?: boolean
}

export type FolderData = {
  depth?: number
  id: number
  itemCount?: number
  name: string
  parentId?: number
  position: number
  subFolders?: FolderData[]
  subTypeDTOS?: SubTypeDto[]
}

const rootClass = 'folder'

const Folder: FC<FolderProps> = (props: FolderProps) => {
  const {
    handleFolderClick,
    isOpen = false,
    activeFolderId,
    dataTest = rootClass,
    className = '',
    onActionClick,
    reference,
    folder,
    onCaretClick,
    children,
    toggleProps,
    hideMoveItems,
  } = props
  const { depth = 0, itemCount, subFolders, id, name, parentId } = folder
  const isActive = id === activeFolderId
  const typographyStyle: React.CSSProperties = { maxWidth: `${14 - depth}rem` }
  const [isCursorHovering, setIsCursorHovering] = useState<boolean>(false)

  const handleClick = useCallback(() => handleFolderClick(id, parentId), [folder])

  const folderHoverActions: RowAction[] = useMemo(
    () =>
      onActionClick
        ? [
            ...(depth !== MAX_FOLDER_DEPTH
              ? [
                  {
                    label: 'Add Subfolder',
                    icon: SvgNames.addFolder,
                    hasTooltip: true,
                    onClick: () => onActionClick(folder, FolderActions.ADD_SUBFOLDER),
                  },
                ]
              : []),
            {
              label: 'Rename',
              icon: SvgNames.pencil,
              inDropdown: true,
              onClick: () => onActionClick(folder, FolderActions.RENAME),
            },
            {
              label: 'Delete',
              icon: SvgNames.delete,
              inDropdown: true,
              onClick: () => onActionClick(folder, FolderActions.DELETE),
            },
          ]
        : [],
    [folder]
  )

  return (
    <>
      <div
        className={classNames(rootClass, className, {
          [`${rootClass}__header-hovered`]: isCursorHovering,
          [`${rootClass}--active`]: isActive,
          [`${rootClass}__pointer-event`]: true,
          [`${rootClass}--with-caret`]: !!subFolders?.length,
        })}
        role={'button'}
        tabIndex={0}
        onMouseEnter={() => setIsCursorHovering(true)}
        onMouseLeave={() => setIsCursorHovering(false)}
        data-test={dataTest}
        ref={reference}
        onKeyDown={(keyDownEvent) => (keyDownEvent.key === 'Enter' ? handleClick() : undefined)}
        onClick={(e: React.MouseEvent) => {
          const DOUBLE_CLICK = 2
          if (e.detail === DOUBLE_CLICK) {
            onCaretClick(folder)
          } else {
            handleClick()
          }
        }}
      >
        <div style={{ paddingLeft: `${Math.max(0, depth) * 1.5}rem` }} className={`${rootClass}__header`}>
          <div className={`${rootClass}__header-title ellip`}>
            {!!subFolders?.length && (
              <Button
                buttonType={ButtonType.ICON}
                dataTest={`caret-folder-${id}`}
                className={`${rootClass}__caret`}
                onClick={(e: React.MouseEvent) => {
                  onCaretClick(folder)
                  e.stopPropagation()
                }}
              >
                <CaretIcon direction={CaretIconDirection.RIGHT} toggle={isOpen} type={SvgType.TINY_ICON} className={`${rootClass}__caret-icon`} />
              </Button>
            )}
            <div className={`${rootClass}__folder-name ellip`}>
              <Svg
                className={classNames(`${rootClass}__folder-icon`, {
                  [`${rootClass}__icon`]: !isActive,
                  [`${rootClass}__icon-active`]: isActive,
                  [`${rootClass}__no-sub-folders`]: !subFolders?.length,
                })}
                name={isActive ? SvgNames.folderOpened : SvgNames.folderClosed}
                type={SvgType.MEDIUM}
              />
              <TextWithTooltipOnEllip
                typographyProps={{
                  text: name,
                  style: typographyStyle,
                  className: 'ellip',
                  lineHeight: LineHeight.MEDIUM_LARGE,
                  weight: isActive ? TextWeight.BOLD : TextWeight.REGULAR,
                }}
                tooltipProps={{ children: name }}
              />
            </div>
          </div>
          {toggleProps?.showToggle && depth === 0 && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <div className={`${rootClass}__toggle-container`} onClick={(e) => e.stopPropagation()}>
              <Toggle isOn={toggleProps?.isToggleOn(folder)} onToggle={(isOn) => toggleProps?.onToggleClick(id, isOn)} />
            </div>
          )}
          {!toggleProps?.showToggle && (
            <Typography
              lineHeight={LineHeight.MEDIUM_LARGE}
              text={formatNumber(itemCount ?? 0)}
              type={isActive ? TextType.BODY_TEXT : TextType.BODY_TEXT_LIGHT}
              weight={isActive ? TextWeight.BOLD : TextWeight.REGULAR}
            />
          )}
          {!toggleProps?.showToggle && isCursorHovering && onActionClick && !hideMoveItems && (
            <div className={`${rootClass}__actions-container`}>
              <RowActions actions={folderHoverActions} />
            </div>
          )}
        </div>
      </div>
      {children}
    </>
  )
}

export default Folder
