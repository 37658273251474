import { gql } from '@apollo/client'

export default gql`
  mutation saveFromAddress($fromAddress: FromAddressesInput!, $action: String!, $uuid: String) {
    saveFromAddress(fromAddress: $fromAddress, action: $action, uuid: $uuid) {
      status
      message
      uuid
      verified
    }
  }
`
