import { useCallback } from 'react'

import { useApolloClient, useMutation } from '@apollo/client'
import { useTranslation } from '@const/globals'
import updateSforceUsers from '@graphql/mutations/updateSforceUsers'
import { MutationAddSforceUserArgs, UpdateSforceUsersMutation } from '@graphql/types/mutation-types'
import { UserResponse } from '@graphql/types/query-types'
import { useAllUsers } from '@src/pages/Settings/Users/context/UsersContext'
import { logNewRelicError } from '@utils/new-relic.utils'

interface Props {
  refetch: () => void
  salesUsers: UserResponse[]
}

interface ReturnProps {
  handleUpdateCRM: (id?: string) => void
  loading: boolean
}

const useUpdateFromCRM: (props: Props) => ReturnProps = ({ refetch, salesUsers }) => {
  const { t } = useTranslation()
  const { setToastStatus } = useAllUsers()

  const client = useApolloClient()

  const [updateFromCRM, { loading }] = useMutation<UpdateSforceUsersMutation, MutationAddSforceUserArgs>(updateSforceUsers, {
    client,
    fetchPolicy: 'no-cache',
  })

  const handleUpdateCRM = useCallback<(id?: string) => void>(
    (id) => {
      const ids: string[] = id ? [id] : (salesUsers.filter((user) => user?.isCRM && user?.id).map(({ id }) => id) as string[])
      return updateFromCRM({
        variables: {
          ids,
        },
      })
        .then(({ data }) => {
          if (data?.updateSforceUsers?.status === 'ok') {
            setToastStatus({
              showStatus: true,
              title: t('Success!'),
              statusMessage: t('Update.From.CRM.Success.Message', { count: ids.length }),
              successStatus: true,
            })
            refetch()
          } else {
            setToastStatus({
              showStatus: true,
              statusMessage: t('Update.From.CRM.Error.Message'),
              successStatus: false,
            })
          }
        })
        .catch((error) => {
          logNewRelicError(error)
        })
    },
    [t, updateFromCRM, refetch, salesUsers]
  )

  return { handleUpdateCRM, loading }
}

export default useUpdateFromCRM
