import { gql } from '@apollo/client'

export default gql`
  mutation updateSubTypeByFolderIds(
    $subTypesToBeRemoved: [SubTypeDTOInput]
    $subTypesToAdd: [SubTypeDTOInput]
    $folderIds: [Long]
    $type: String
    $subTypesToRemove: [Long]
  ) {
    updateSubTypeByFolderIds(
      subTypesToBeRemoved: $subTypesToBeRemoved
      subTypesToAdd: $subTypesToAdd
      folderIds: $folderIds
      type: $type
      subTypesToRemove: $subTypesToRemove
    )
  }
`
