import { createContext, ReactNode } from 'react'

import { GraphQLError } from 'graphql/index'

import { ProgressBarStep } from '@components/ProgressBar/ProgressBar'
import { SelectV2Option } from '@components/SelectV2/SelectV2.props'
import { Status } from '@components/StatusToast/StatusToast'
import { FtpFileDto, ImportDefinitionDto, ImportSyncJobDto, ImportSyncJobDtoInput } from '@graphql/types/microservice/entity-upload-types'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { MappingScreenValues } from '@src/pages/importcontacts/components/MappingScreen/MappingScreenContainer'
import { ImportOptions, importOptionsDefaultValues } from '@src/pages/ImportContactsV2/utils/ImportContactsV2.constants'

export type ImportContactsV2ContainerState = Omit<MappingScreenValues, 'hasHeaders'> &
  ImportOptions & {
    ftpFiles: FtpFileDto[]
    hasToUpdateFirstRows: boolean
    isDeletingFile: boolean
    isSelectingFile: boolean
    isUpdatingFromSession: boolean
    importDefinitionId?: number
    importedNow?: boolean
    importSyncJob?: ImportSyncJobDto
    loading: boolean
    loadingFtpFiles: boolean
    loadingSegments: boolean
    selectedFile?: FtpFileDto
    showAdvancedOptions: boolean
    showExternalIdMatchError: boolean
    showObjectTypeError: boolean
    statusToast: { statusMessage: string | ReactNode; status: Status; showStatusToast: boolean }
    steps: ProgressBarStep[]
    unifiedListFieldMappings?: UnifiedListFieldMapping[]
  }

export const importContactsV2ContainerInitialState: ImportContactsV2ContainerState = {
  nonDeletedFields: [],
  deletedFields: [],
  ftpFiles: [],
  hasToUpdateFirstRows: false,
  isDeletingFile: false,
  isSelectingFile: false,
  isUpdatingFromSession: false,
  indexRef: 0,
  inputOnBlurTriggered: false,
  listName: 'Mocked list',
  loading: true,
  loadingFtpFiles: true,
  loadingSegments: true,
  mappingPreview: [],
  previewHeader: [],
  previewIndex: undefined,
  previewRecords: [],
  segmentOptions: [],
  showAdvancedOptions: false,
  showExternalIdMatchError: false,
  showObjectTypeError: false,
  statusToast: { statusMessage: '', status: Status.FAIL, showStatusToast: false },
  steps: [],
  validateAllTrigger: false,
  validationTrigger: false,
  visibleFields: [],
  ...importOptionsDefaultValues,
}

export type Update = (values: Partial<ImportContactsV2ContainerState>) => void

export interface ImportContactsV2API {
  closeStatusToast: VoidFunction
  updateImportDefinition: () => Promise<ImportDefinitionDto | undefined>
  getSegmentOptions: (currentPage?: number) => Promise<SelectV2Option[]>
  handleOnSectionChange: (newStep: string, currentStepCompleted?: boolean) => void
  onError: (errors?: readonly GraphQLError[]) => void
  values: ImportContactsV2ContainerState
  updateImportSyncJob: (values?: Partial<ImportSyncJobDtoInput>) => Promise<ImportSyncJobDto | undefined>
  update: Update
  validateColumn: (index: number) => void
  createSegment: (segmentName: string) => void
  isAValidSegmentName: (segmentName: string) => Promise<boolean>
}

export const ImportContactsV2ContextMock: ImportContactsV2API = {
  values: {
    ...importContactsV2ContainerInitialState,
  },
  updateImportDefinition: () => Promise.resolve({}),
  getSegmentOptions: () => new Promise(() => []),
  handleOnSectionChange: () => undefined,
  onError: () => undefined,
  updateImportSyncJob: () => Promise.resolve({}),
  update: () => undefined,
  closeStatusToast: () => undefined,
  validateColumn: () => undefined,
  isAValidSegmentName: () => new Promise(() => true),
  createSegment: () => undefined,
}

export const ImportContactsV2Context = createContext<ImportContactsV2API>({ values: importContactsV2ContainerInitialState } as any)
