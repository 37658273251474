import React, { FC, useState } from 'react'

import ListPickerModal from '@complex/ListPickerModal/ListPickerModal'
import { ListPickerModal as ListPickerModalV2 } from '@complex/ListPickerModalV2/ListPickerModal'
import Button, { ButtonType } from '@components/Button/Button'
import Container from '@components/Container'
import EmptyList from '@components/EmptyList/EmptyList'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { useTranslation } from '@const/globals'
import { MarketingList, MarketingListResponse } from '@graphql/types/query-types'
import { List } from '@interface/foldersLists/List'
import { SMSContextAPI } from '@src/pages/sms/context/SMSContext'
import { useAccountSettings } from '@utils/account/account.utils'

import RecipientLists from './RecipientLists'

import './Recipients.css'

const rootClass = 'recipients'

interface State {
  addingLists: boolean
}

interface Props {
  context: SMSContextAPI
}

const Recipients: FC<Props> = ({ context }: Props) => {
  const {
    values: { smsMessage },
    saveMessage,
  } = context
  const { recipients } = smsMessage
  const { t } = useTranslation()
  const { hasShowActOnContactsTab } = useAccountSettings()
  const [state, setState] = useState<State>({ addingLists: false })

  const onOpenAddList = () => {
    setState({
      ...state,
      addingLists: true,
    })
  }

  const closeModal = () => {
    setState({
      ...state,
      addingLists: false,
    })
  }

  const onRemoveList = (list: MarketingList) => {
    const updatedLists = recipients.filter((recip) => recip.listId !== list.listId)
    saveMessage({ ...smsMessage, recipients: updatedLists })
  }

  const getListType = (list: List) => {
    if (list.isSegment) {
      return 'SEGMENT'
    } else if (list.isCrm) {
      return 'CRM'
    } else if (list.isExtension) {
      return 'EXTENSION'
    } else if (list.isWebinar) {
      return 'WEBINAR'
    } else if (list.isTest) {
      return 'TEST'
    } else if (list.isFormSubmission) {
      return 'FORM_SUBMISSION'
    }
    // Assuming this is default from List itself. May cause a bug we should be aware of
    return 'MARKETING'
  }

  const onSubmitLists = (newLists: List[]) => {
    const recipLists: MarketingListResponse[] = newLists.map((list) => {
      return {
        listId: list.id,
        listName: list.name,
        listType: getListType(list),
        totalContacts: 0,
        countNotOptedIn: 0,
        countMissingPhone: 0,
        countDuplicate: 0,
        suppressedInternationalContacts: 0,
        headers: [],
        sampleContacts: [{ firstName: '', lastName: '', emailAddress: '', recId: '' }],
      }
    })
    saveMessage({ ...smsMessage, recipients: recipLists })
    closeModal()
  }

  const getBody = () => {
    if (recipients.length) {
      return <RecipientLists lists={recipients} onRemoveList={onRemoveList} />
    } else {
      return <EmptyList required message={t('No recipients have been added yet')} />
    }
  }

  const renderListPicker = () =>
    hasShowActOnContactsTab ? (
      <ListPickerModalV2
        isOpen={state.addingLists}
        defaultSelectedLists={recipients.map((recipient) => recipient.listId)}
        closeModal={closeModal}
        submitLists={onSubmitLists}
        smsCompatibleLists
      />
    ) : (
      <ListPickerModal
        isOpen={state.addingLists}
        selectedLists={recipients.map((recipient) => recipient.listId)}
        closeModal={closeModal}
        submitLists={onSubmitLists}
        smsCompatibleLists
      />
    )

  return (
    <Container data-text={rootClass} title={'Recipients'}>
      {state.addingLists && renderListPicker()}
      {getBody()}
      <div className={`${rootClass}__list-button`}>
        <Button buttonType={ButtonType.FLOAT} title={t('Add Lists or Segments')} onClick={onOpenAddList} dataTest={`${rootClass}-button-add`}>
          <Svg name={SvgNames.plus} type={SvgType.ICON} />
          {t('Add Recipients')}
        </Button>
      </div>
    </Container>
  )
}

export default Recipients
