import React, { FC, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import classNames from 'classnames'

import { YesNo } from '@components/ConfirmationModal'
import CustomPrompt from '@components/CustomPrompt/CustomPrompt'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import DirtyBanner from '@components/DirtyBanner/DirtyBanner'
import DropDown from '@components/DropDown'
import { DropDownType } from '@components/DropDown/DropDown'
import DropDownActions, { MenuItem } from '@components/DropDownActions/DropDownActions'
import { renderLoader } from '@components/Loader/Loader'
import PageContainer from '@components/PageContainer'
import PageHeader from '@components/PageHeader'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import SelectAssetV2 from '@components/SelectAssetV2/SelectAssetV2'
import StatusToast from '@components/StatusToast/StatusToast'
import Tabs, { TabItemData } from '@components/TabsAO/TabsAO'
import { rootContext, useTranslation } from '@const/globals'
import getFtpConnectionStatus from '@graphql/microservices/entity-upload/getFtpConnectionStatus'
import { GetFtpConnectionStatusQuery, GetFtpConnectionStatusQueryVariables } from '@graphql/types/microservice/entity-upload-types'
import { IMPORT_SOURCE } from '@src/pages/ContactSegments/utils/ContactSegments.constants'
import { getSessionStorageOrDefault } from '@src/pages/datamanagement/components/CrmContacts/CrmContacts'
import CrmContactsContainer from '@src/pages/datamanagement/components/CrmContacts/CrmContactsContainer'
import CrmEntity from '@src/pages/datamanagement/components/CrmEntity/CrmEntity'
import EntityRecordTable from '@src/pages/datamanagement/components/EntityRecordTable/EntityRecordTable'
import NonCrmContactsContainer from '@src/pages/datamanagement/components/NonCrmContacts/NonCrmContactsContainer'
import ReportMappingContainer from '@src/pages/datamanagement/components/ReportMapping/ReportMappingContainer'
import SyncSchedule from '@src/pages/datamanagement/components/SyncSchedule/SyncSchedule'
import { DataManagementContext, Entity } from '@src/pages/datamanagement/context/DataManagementContext'
import { DataManagementTabs, getSelectAssetV2Props, NON_INITIALIZED_UCL_TOOLTIP } from '@src/pages/datamanagement/utils/DataManagement.constants'
import { IMPORT_CONTACTS_URL } from '@src/pages/importcontacts/utils/ImportContactsContainerUtils'
import { useAccountSettings } from '@utils/account/account.utils'
import useCRM from '@utils/hooks/useCRM'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'

import './DataManagement.css'

export interface SyncInfo {
  entities: Entity[]
}

type Props = {
  className?: string
  dataTest?: string
  client?: any
  loading?: boolean
  loadCRMData?: (isDiscard?: boolean) => void
  loadNonCRMData?: () => void
}

const ALL_CONTACTS_URL = `${rootContext}/segments`
const IMPORT_CONTACTS_FULL_URL = `${rootContext}${IMPORT_CONTACTS_URL}`
const rootClass = 'data-management'

const DataManagement: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, loading = true, loadCRMData, loadNonCRMData } = props
  const {
    values: {
      currentTab,
      syncInfo,
      recordModal,
      hasUpdates,
      openDiscardModal,
      mutationResultState,
      entityTable,
      fieldMappings,
      showCrmContactValidationToast,
      showFirstTimePage,
      unifiedListFieldMappings,
      selectingImportContactsSource,
      importContactsSource,
    },
    save,
    discard,
    update,
    updateEntityTypes,
  } = useContext(DataManagementContext)
  const { hasShowActOnContactsTab, enableRevenueReportMappingTab, userAllowedToCreate } = useAccountSettings()
  const { hasCRMConnected } = useCRM()
  const history = useHistory()

  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [showStatus, setShowStatus] = useState<boolean>(false)
  const [statusMessage, setStatusMessage] = useState<string>('')
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)
  const [nextTab, setNextTab] = useState<string>('')
  const [showSaveBanner, setShowSaveBanner] = useState(getSessionStorageOrDefault('showSaveBanner', false))

  const { client: entityUploadClient } = useMicroserviceClient({ serviceName: MicroserviceClients.ENTITY_UPLOAD })

  const { data: ftpConnectionStatusData } = useQueryOnMount<GetFtpConnectionStatusQuery, GetFtpConnectionStatusQueryVariables>(
    getFtpConnectionStatus,
    {
      client: entityUploadClient,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )

  const disableDropdownOptions = unifiedListFieldMappings.length === 0
  const params = new URLSearchParams(window.location.search)
  const isSyncTab = params.get('currentTab') === 'sync-schedule'

  const { language } = useSelector((state: any) => state.account.account)

  const { t } = useTranslation()

  useEffect(() => {
    const pathLastIndex = history.location.pathname.substring(history.location.pathname.lastIndexOf('/') + 1)
    let url = ''
    switch (pathLastIndex) {
      case 'datamanagement':
      case DataManagementTabs.CONTACT_MAPPING:
        url = hasShowActOnContactsTab ? DataManagementTabs.CONTACT_MAPPING : DataManagementTabs.CRM_ENTITIES
        break
      case DataManagementTabs.REPORT_MAPPING:
        url = enableRevenueReportMappingTab ? DataManagementTabs.REPORT_MAPPING : DataManagementTabs.CRM_ENTITIES
        break
      case DataManagementTabs.SYNC_SCHEDULE:
        url = DataManagementTabs.SYNC_SCHEDULE
        break
      case DataManagementTabs.CRM_ENTITIES:
        url = DataManagementTabs.CRM_ENTITIES
    }
    if (url) {
      update('currentTab', url)
      history.push(`${rootContext}/datamanagement/${url}`)
    } else {
      update('currentTab', DataManagementTabs.CRM_ENTITIES)
      history.push(`${rootContext}/datamanagement/${DataManagementTabs.CRM_ENTITIES}`)
    }
  }, [history.location?.pathname])

  useEffect(() => {
    const entitiesTobeSaved: string[] = entityTable.map((val) => val.entity)

    const {
      areFieldMappingsSaved,
      areReportMappingsSaved,
      attemptToSaveEntityChanges,
      attemptToSaveOptInOutChanges,
      attemptToSaveReportMappingsChanges,
      attemptToSaveScheduleChanges,
      isOptInOutSaved,
      isSyncScheduleSaved,
      resultPerEntity,
    } = mutationResultState

    const allEntitySaveAttemptComplete = attemptToSaveEntityChanges?.length === entitiesTobeSaved.length

    const allSaveAttemptComplete =
      (!hasCRMConnected || (attemptToSaveScheduleChanges && attemptToSaveOptInOutChanges)) &&
      attemptToSaveReportMappingsChanges &&
      areFieldMappingsSaved &&
      allEntitySaveAttemptComplete

    if (allSaveAttemptComplete) {
      const allEntitiesSaveSuccess = resultPerEntity.length > 0 ? resultPerEntity.filter((res) => !res.isSaved).length == 0 : true

      const isAllDataSaved = (!hasCRMConnected || (isSyncScheduleSaved && isOptInOutSaved)) && areReportMappingsSaved && allEntitiesSaveSuccess

      setIsSuccess(isAllDataSaved)
      if (isAllDataSaved) {
        setStatusMessage(t('Data Management settings saved. Changes will be reflected on the next sync.'))
      } else {
        setStatusMessage(t('Error while saving Data Management settings'))
      }

      setShowStatus(true)

      if (hasCRMConnected) {
        loadCRMData && loadCRMData(true)
      } else {
        loadNonCRMData && loadNonCRMData()
      }
    }
  }, [mutationResultState, hasCRMConnected])

  const displayStatusToast = (isSuccess: boolean, message: string) => {
    setIsSuccess(isSuccess)
    setStatusMessage(message)
    setShowStatus(true)
  }

  useEffect(() => {
    const { open, text } = showCrmContactValidationToast
    if (open) {
      displayStatusToast(false, text)
    }
  }, [showCrmContactValidationToast])

  useEffect(() => {
    if (isSyncTab) {
      update('currentTab', DataManagementTabs.SYNC_SCHEDULE)
    }
  }, [])

  const getTabs = () => {
    const tabs: TabItemData[] = []
    if (hasShowActOnContactsTab) {
      tabs.push({
        index: DataManagementTabs.CONTACT_MAPPING,
        label: t('Contact Mapping'),
        content: <CrmContactsContainer language={language} />,
      })
    }

    if (enableRevenueReportMappingTab) {
      tabs.push({
        index: DataManagementTabs.REPORT_MAPPING,
        label: t('Report Mapping'),
        content: <ReportMappingContainer />,
      })
    }

    tabs.push({
      index: DataManagementTabs.SYNC_SCHEDULE,
      label: t('Sync Schedule'),
      content: <SyncSchedule dataTest={dataTest} />,
    })

    tabs.push({
      index: DataManagementTabs.CRM_ENTITIES,
      label: t('CRM Entities'),
      content: <CrmEntity entities={syncInfo} updateEntities={updateEntityTypes} />,
    })

    return tabs
  }

  window.onunload = function () {
    sessionStorage.removeItem('datamanagement/showSaveBanner')
  }

  useEffect(() => {
    if (hasUpdates) {
      setShowSaveBanner(true)
    } else {
      if (nextTab) {
        update('currentTab', nextTab)
        history.push(`${rootContext}/datamanagement/${nextTab}`)
        setNextTab('')
      }
    }
  }, [hasUpdates])

  useEffect(() => {
    sessionStorage.setItem('datamanagement/showSaveBanner', JSON.stringify(showSaveBanner))
  }, [showSaveBanner])

  useEffect(() => {
    if (importContactsSource != '') {
      history.push(importContactsSource === IMPORT_SOURCE.FTP ? `${rootContext}/importContactsV2/${importContactsSource}` : IMPORT_CONTACTS_FULL_URL)
    }
  }, [importContactsSource])

  const toggleOpen = (dropdownOpen: boolean) => setDropdownOpen(dropdownOpen)

  const dropDownActions: MenuItem[] = [
    {
      text: t('View Act-On Contacts'),
      onClick: () => history.push(ALL_CONTACTS_URL),
      disabled: disableDropdownOptions,
      hasTooltip: disableDropdownOptions,
      tooltipMessage: NON_INITIALIZED_UCL_TOOLTIP,
    },
    {
      text: t('Import Contacts'),
      onClick: () =>
        ftpConnectionStatusData?.getFtpConnectionStatus ? update('selectingImportContactsSource', true) : history.push(IMPORT_CONTACTS_FULL_URL),
      disabled: disableDropdownOptions || !userAllowedToCreate,
      hasTooltip: disableDropdownOptions || !userAllowedToCreate,
      tooltipMessage: !userAllowedToCreate ? t('Ask your administrator for permission to do this') : NON_INITIALIZED_UCL_TOOLTIP,
    },
  ]

  return (
    <>
      <CustomPrompt
        when={hasUpdates}
        title={t('Discard all unsaved changes?')}
        body={t('If you discard changes, you’ll delete any edits you made since you last saved.')}
        okButtonText={t('Discard Changes')}
        cancelButtonText={t('Continue Editing')}
        onConfirm={() => {
          discard()
          setShowSaveBanner(false)
        }}
      />
      <PageContainer dataTest={`${dataTest}-container`}>
        {loading && renderLoader()}
        {selectingImportContactsSource && <SelectAssetV2 {...getSelectAssetV2Props(t, update)} />}
        {recordModal.openRecordModal && (
          <EntityRecordTable
            entityDisplayName={recordModal.modalEntityName}
            entityIdentifier={recordModal.modalEntityIdentifier}
            recordCounts={recordModal.modalRecordCount}
            showResultsModal={recordModal.openRecordModal}
          />
        )}
        {openDiscardModal && (
          <DeleteConfirmationModal
            className={`${rootClass}__confirmation-modal`}
            isOpen={openDiscardModal}
            title={t('Discard all unsaved changes?')}
            body={t('If you discard changes, you’ll delete any edits you made since you last saved.')}
            cancelButtonText={t('Continue Editing')}
            deleteButtonText={t('Discard Changes')}
            onAnswer={(answer) => {
              if (answer === YesNo.YES) {
                discard()
                setShowSaveBanner(false)
              } else {
                update('openDiscardModal', false)
                setNextTab('')
              }
            }}
          />
        )}
        {(hasUpdates || showSaveBanner) && (
          <DirtyBanner
            expanded
            onSave={() => {
              save()
              setShowSaveBanner(false)
            }}
            onDiscard={() => update('openDiscardModal', true)}
          />
        )}
        {showStatus && <StatusToast isSuccess={isSuccess} message={statusMessage} closeStatus={() => setShowStatus(false)} />}
        <PositionContainer>
          {!loading && (
            <PageHeader primaryText={t('Data Management')} headlineClassName={`${rootClass}__headline`} dataTest={`${dataTest}-header`} leftContent>
              {(fieldMappings.length !== 0 || !showFirstTimePage) && hasShowActOnContactsTab && (
                <DropDown
                  type={DropDownType.STYLED}
                  hasOverflowIcon
                  className={classNames({ [`${rootClass}__overflow`]: hasCRMConnected })}
                  isOpen={dropdownOpen}
                  toggleOpen={toggleOpen}
                >
                  <DropDownActions menuItems={dropDownActions} closeDropDown={() => toggleOpen(false)} />
                </DropDown>
              )}
            </PageHeader>
          )}
          {!loading &&
            (!hasCRMConnected ? (
              <NonCrmContactsContainer setShowSaveBanner={setShowSaveBanner} />
            ) : (
              <Tabs
                childData={getTabs()}
                defaultValue={currentTab}
                onChange={(newTab: string) => {
                  if (hasUpdates) {
                    setNextTab(newTab)
                    update('openDiscardModal', true)
                  } else {
                    update('currentTab', newTab)
                    history.push(`${rootContext}/datamanagement/${newTab}`)
                  }
                }}
              />
            ))}
        </PositionContainer>
      </PageContainer>
    </>
  )
}

export default DataManagement
