import { useCallback } from 'react'

import { useApolloClient, useMutation } from '@apollo/client'
import { useTranslation } from '@const/globals'
import resendUsersVerification from '@graphql/mutations/resendUsersVerification'
import { MutationResendUsersVerificationArgs, ResendUsersVerificationMutation } from '@graphql/types/mutation-types'
import { UserResponse } from '@graphql/types/query-types'
import { StatusToastType } from '@interface/StatusToast'
import { logNewRelicError } from '@utils/new-relic.utils'

export type UserWithId = UserResponse & { id: string }

const useResendVerification = (setToastStatus: (value: StatusToastType) => void) => {
  const { t } = useTranslation()

  const client = useApolloClient()
  const [resendVerification] = useMutation<ResendUsersVerificationMutation, MutationResendUsersVerificationArgs>(resendUsersVerification, {
    client,
    fetchPolicy: 'no-cache',
  })

  const handleResendVerification = useCallback<(usersToResend: UserResponse[]) => Promise<any>>(
    (usersToResend) => {
      if (!usersToResend) {
        return Promise.reject()
      }

      const usersWithId: UserWithId[] = usersToResend.filter((user) => !!user?.id) as UserWithId[]
      const { verifiedUsers, unverifiedUsers } = usersWithId.reduce(
        (acc: { verifiedUsers: UserWithId[]; unverifiedUsers: UserWithId[] }, user) => {
          user.isVerified ? acc.verifiedUsers.push(user) : acc.unverifiedUsers.push(user)
          return acc
        },
        { verifiedUsers: [], unverifiedUsers: [] }
      )
      const verifiedUsersCount: number = verifiedUsers.length
      const ids: string[] = unverifiedUsers.map(({ id }) => id)
      const transContext: string = verifiedUsersCount ? 'withVerified' : ''
      const onlyReSentEmail = unverifiedUsers.length === 1 ? unverifiedUsers[0].email : ''
      const onlyVerifiedEmail = verifiedUsers.length === 1 ? verifiedUsers[0].email : ''

      return resendVerification({
        variables: {
          ids,
        },
      })
        .then(({ data }) => {
          if (data?.resendUsersVerification?.status === 'ok') {
            setToastStatus({
              showStatus: true,
              title: t('Users.Resend.Verification.Success.Title', {
                count: transContext ? ids.length : 0,
                context: transContext,
                onlyEmail: onlyReSentEmail,
              }),
              statusMessage: t('Users.Resend.Verification.Success.Message', {
                count: verifiedUsersCount || ids.length,
                context: transContext,
                onlyEmail: onlyVerifiedEmail || onlyReSentEmail,
              }),
              successStatus: true,
            })
          } else {
            setToastStatus({
              showStatus: true,
              statusMessage: t('Something went wrong on our end. Please try again.'),
              successStatus: false,
            })
          }
        })
        .catch((error) => {
          logNewRelicError(error)
        })
    },
    [t, resendVerification]
  )

  return { handleResendVerification }
}

export default useResendVerification
