import React, { FC } from 'react'

import classNames from 'classnames'

import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import ProgramStepIcon from '@src/pages/programs/edit/components/ProgramFlow/components/ProgramStepIcon'
import { ProgramStepType } from '@utils/program/program.constants'

import './GoToStepDetail.css'

interface Props {
  stepName: string
  stepType: ProgramStepType
  className?: string
  dataTest?: string
}

const rootClass = 'go-to-step-detail'

const GoToStepDetail: FC<Props> = (props: Props) => {
  const { stepName, stepType, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Typography text={t('Go to')} inline /> <ProgramStepIcon stepType={stepType} overrideColorAndHeight iconOnly className={`${rootClass}__svg`} />{' '}
      {stepName !== '' && <Typography text={stepName} weight={TextWeight.MEDIUM} inline />}
      {stepName === '' && <Typography text={t('undefined')} weight={TextWeight.ITALIC} inline />}
    </div>
  )
}

export default GoToStepDetail
