import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'

import Container from '@components/Container'
import Input from '@components/Input'
import Typography from '@components/Typography/Typography'
import { emailSettingsTypographyProps, renderValidation } from '@src/pages/EmailComposer/components/EmailComposerSettings/EmailComposerSettings.utils'
import { DEFAULT_LANDING_PAGE_TITLE, LandingPageComposerContext } from '@utils/composer/context/LandingPageComposer.context'
import { useTranslation } from '@utils/const/globals'
import { replaceSpaces } from '@utils/strings'

interface EmailComposerSettingsTitleProps {
  className?: string
  dataTest?: string
}

const LandingPageComposerSettingsTitle: FC<EmailComposerSettingsTitleProps> = (props: EmailComposerSettingsTitleProps) => {
  const { className = '', dataTest = className } = props
  const {
    values: {
      message: { title },
      validations: { settingsValidations },
    },
    api: { update },
  } = useContext(LandingPageComposerContext)

  const { t } = useTranslation()
  const [currentValue, setCurrentValue] = useState('')

  const [isDirty, setDirty] = useState<boolean>(false)
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const cleanUpRef = useRef<Function>()
  const inputRef = useRef<HTMLInputElement>()

  const { titleMissing } = settingsValidations

  const handleSaveChanges = useCallback(
    (value?: string) => {
      if (!value || !value.trim()) {
        update({ lastSavedTitle: DEFAULT_LANDING_PAGE_TITLE, message: { title: DEFAULT_LANDING_PAGE_TITLE } })
      } else if (!!value.trim()) {
        update({ lastSavedTitle: replaceSpaces(title), message: { title: replaceSpaces(title) } })
      } else {
        setDirty(true)
      }
      setCurrentValue('')
    },
    [title, update]
  )

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.value || !!e.target.value.trim()) {
        update({ message: { title: e.target.value } })
      }
      setCurrentValue(e.target.value)
    },
    [update]
  )

  const handleFocus = useCallback(() => {
    setDirty(false)
    setIsFocused(true)
  }, [])

  const handleBlur = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      handleSaveChanges(e.target.value)
      setIsFocused(false)
    },
    [handleSaveChanges]
  )

  useEffect(() => {
    cleanUpRef.current = () => handleSaveChanges(inputRef.current?.value)
  }, [handleBlur])

  useLayoutEffect(() => {
    return () => {
      const cleanUp = cleanUpRef.current

      if (cleanUp && isFocused) {
        cleanUp()
      }
    }
  }, [])

  return (
    <Container dataTest={dataTest}>
      <div className={`${className}__subsection`}>
        <Input
          ref={inputRef}
          label={t('LandingPageComposer.Settings.Title')}
          labelDisplay="inline"
          required
          maxlength={400}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          dataTest={`${dataTest}-title-input`}
          value={currentValue || title}
          trim={false}
          error={isDirty && titleMissing}
        />
        {isDirty && titleMissing ? (
          renderValidation(t('LandingPageComposer.Settings.Title.Missing'), 'ERROR')
        ) : (
          <Typography text={t('LandingPageComposer.Settings.Title.Description')} {...emailSettingsTypographyProps} />
        )}
      </div>
    </Container>
  )
}

export default LandingPageComposerSettingsTitle
