import React, { FC, useContext } from 'react'

import Container from '@components/Container'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'
import { UpgradeManagerContext } from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'
import { ProgramSelected } from '@src/pages/programs/UpgradePrograms/components/ProgramSelected/ProgramSelected'
import { FixStepIssuesSection } from '@src/pages/programs/UpgradePrograms/components/ReviewUpgrade/sections/FixStepIssuesSection/FixStepIssuesSection'
import { RemovedEarlyExitSourcesSection } from '@src/pages/programs/UpgradePrograms/components/ReviewUpgrade/sections/RemovedEarlyExitSourcesSection/RemovedEarlyExitSourcesSection'
import { RemovedSourcesSection } from '@src/pages/programs/UpgradePrograms/components/ReviewUpgrade/sections/RemovedSourcesSection/RemovedSourcesSection'
import { RemovedStepsSection } from '@src/pages/programs/UpgradePrograms/components/ReviewUpgrade/sections/RemovedStepsSection/RemovedStepsSection'
import { SegmentsToReplaceSection } from '@src/pages/programs/UpgradePrograms/components/ReviewUpgrade/sections/SegmentsToReplaceSection/SegmentsToReplaceSection'

import './ReviewUpgrade.css'

const rootClass = 'review-upgrade'
const rootText = 'UpgradePrograms.Page.ReviewUpgrade.'

export const ReviewUpgrade: FC = () => {
  const {
    values: { upgradedSources, upgradedSteps, upgradedEarlyExitSources },
  } = useContext(UpgradeManagerContext)
  const {
    values: { program },
  } = useContext(ProgramManagerContext)
  const { t } = useTranslation()
  const removedSources = upgradedSources?.filter(({ removed }) => removed) ?? []
  const removedSteps = upgradedSteps?.filter(({ removed }) => removed) ?? []
  const filteredUpgradedSources = upgradedSources?.filter(({ removed }) => !removed)
  const showSources = filteredUpgradedSources?.length !== 0 || upgradedEarlyExitSources?.length !== 0

  const showFixStepIssuesSection = upgradedSteps.length !== 0 && upgradedSteps.length !== removedSteps.length
  const showRemovedStepsSection = removedSteps.length !== 0
  const showRemovedEarlyExitSourcesSection = upgradedEarlyExitSources && upgradedEarlyExitSources.filter(({ removed }) => removed).length !== 0

  return (
    <Container className={`${rootClass}__container`}>
      <div className={`${rootClass}__title-container`}>
        <Typography text={t(`${rootText}Title`)} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} inline />
        <Typography text={t(`${rootText}SubTitle`)} type={TextType.BODY_TEXT_LIGHT} />
      </div>
      <ProgramSelected program={program} />
      {showSources && (
        <>
          <div className={'divider'} />
          <SegmentsToReplaceSection />
        </>
      )}
      {removedSources?.length !== 0 && (
        <>
          {!showSources && <div className={'divider'} />}
          <RemovedSourcesSection sources={removedSources} />
        </>
      )}
      {showRemovedEarlyExitSourcesSection && (
        <>
          {!showSources && <div className={'divider'} />}
          <RemovedEarlyExitSourcesSection sources={upgradedEarlyExitSources} />
        </>
      )}
      {(showFixStepIssuesSection || showRemovedStepsSection) && <div className={'divider'} />}
      {showFixStepIssuesSection && <FixStepIssuesSection />}
      {showRemovedStepsSection && <RemovedStepsSection steps={removedSteps} />}
    </Container>
  )
}
