import { useCallback, useContext, useEffect, useRef, useState } from 'react'

interface WaitForEmailSaveEndType<T> {
  waitForSaveEnd: (promise: () => Promise<T>) => Promise<T>
  loading: boolean
}

import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'

export const useWaitForEmailSaveEnd = <T = undefined>(): WaitForEmailSaveEndType<T> => {
  const {
    values: { haveUnsavedChanges },
  } = useContext(EmailComposerContext)

  const isSavingEmailRef = useRef(haveUnsavedChanges)
  useEffect(() => {
    isSavingEmailRef.current = haveUnsavedChanges
  }, [haveUnsavedChanges])

  const [loading, setLoading] = useState(false)

  const waitForSaveEnd = useCallback(
    (promise: () => Promise<any>) =>
      new Promise((resolve) => {
        setLoading(true)
        const checkIsSaving = () => {
          if (!isSavingEmailRef.current) {
            resolve(true)
          } else {
            setTimeout(checkIsSaving, 100)
          }
        }
        checkIsSaving()
      })
        .then(promise)
        .finally(() => setLoading(false)),
    []
  )

  return { waitForSaveEnd, loading }
}
