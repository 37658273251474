import React, { FC, useContext } from 'react'

import classNames from 'classnames'

import Caution from '@components/Caution/Caution'
import { LinkTextButton } from '@components/LinkTextButton/LinkTextButton'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'

interface AccountLimitsWarningProps {
  className?: string
  dataTest?: string
}

const LEARN_MORE = 'https://connect.act-on.com/hc/en-us/articles/360023761194-Understanding-Active-Contacts'

const rootClass = 'account-limits-warning'

const AccountLimitsWarning: FC<AccountLimitsWarningProps> = (props: AccountLimitsWarningProps) => {
  const { dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const {
    values: {
      messageLimits: { monthlyLimit, currentUsage },
      recipientsCount,
    },
  } = useContext(EmailComposerContext)

  const recipientsToDate = currentUsage + recipientsCount

  if (recipientsToDate === 0 || recipientsToDate < monthlyLimit) {
    return null
  }

  const atLimit = currentUsage >= monthlyLimit

  const text = (
    <>
      <Typography
        text={`${t(atLimit ? 'AccountLimitsWarning.LimitReached' : 'AccountLimitsWarning.AtLimitRisk')} `}
        tagProps={{ medium: { weight: TextWeight.MEDIUM, inline: true } }}
        inline
      />
      <LinkTextButton text={t('Learn more')} link={LEARN_MORE} hideIconLeft />
    </>
  )

  return (
    <div className={`${rootClass}__left-section`}>
      <Caution className={classNames(rootClass, className)} dataTest={dataTest} message={text} />
    </div>
  )
}

export default AccountLimitsWarning
