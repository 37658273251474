import React, { FC, useState } from 'react'

import classNames from 'classnames'

import ButtonCopy from '@components/ButtonCopy/ButtonCopy'
import { Checkbox } from '@components/Checkbox/Checkbox'
import DatePicker from '@components/DatePicker/DatePicker'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './ExpirationDate.css'

interface ExpirationDateProps {
  className?: string
  dataTest?: string
}

const rootClass = 'expiration-date'

interface ExpirationDateState {
  isChecked: boolean
  isSvgHovered: boolean
  date?: Date
}

const initialState: ExpirationDateState = {
  isChecked: false,
  isSvgHovered: false,
}

const ExpirationDate: FC<ExpirationDateProps> = (props: ExpirationDateProps) => {
  const { dataTest = rootClass, className = '' } = props

  const { t } = useTranslation()
  const [state, setState] = useState(initialState)
  const handleMouseEnter = () => setState({ ...state, isSvgHovered: true })
  const handleMouseLeave = () => setState({ ...state, isSvgHovered: false })

  const copyCode = (code: string) => {
    navigator.clipboard.writeText(code)
  }

  const hideForNow = true

  return (
    <>
      {!hideForNow && (
        <div className={classNames(rootClass, className)} data-test={dataTest}>
          <Checkbox label={t(`Add an expiration date`)} value={'linkToLibary'} onChange={(checked) => setState({ ...state, isChecked: checked })} />
          <Tooltip
            trigger={
              <div className={`${rootClass}__bottom-options--svg`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <Svg name={state.isSvgHovered ? SvgNames.infoHover : SvgNames.infoRest} type={SvgType.LARGER_ICON} />
              </div>
            }
            position={'right'}
          >
            {t('The selected expiration date will apply to the link only within this email')}
          </Tooltip>
          {state.isChecked && (
            <div className={`${rootClass}__date-container`}>
              <div className={`${rootClass}__date-container--date-picker`}>
                <DatePicker
                  isParentModal={false}
                  onChange={(date) => setState({ ...state, date })}
                  hasApplyButton={true}
                  inputReadOnly={false}
                  hasCancelButton={false}
                  allowClear={false}
                  leadingIcon={false}
                />
                {state.date && <ButtonCopy onClick={() => copyCode(`${state.date}` ?? '')} />}
              </div>
              {state.date && (
                <Typography
                  text={t(`Consider including the link's expiration date in your email message`)}
                  type={TextType.BODY_TEXT_SMALL_LIGHT}
                  className={`${rootClass}__date-container--date-text`}
                />
              )}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default ExpirationDate
