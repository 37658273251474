import React, { FC, Fragment, ReactNode } from 'react'

import classNames from 'classnames'

import { YesNo } from '@components/ConfirmationModal'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import Typography, { ModalBodyStyle, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

export interface DeleteConfirmationProps {
  className?: string
  dataTest?: string
  title: string
  text?: string
  content?: ReactNode
  textBold?: string | string[]
  buttonText?: string
  deleteAction: () => void
  onClose: () => void
  isRemove?: boolean
}

const rootClass = 'delete-confirmation'

const DeleteConfirmation: FC<DeleteConfirmationProps> = (props: DeleteConfirmationProps) => {
  const { dataTest = rootClass, className = '', title, text, content, textBold, buttonText, deleteAction, onClose, isRemove } = props

  const { t } = useTranslation()

  const body = (
    <>
      {!!content ? (
        content
      ) : (
        <>
          <Typography text={t(text)} tagProps={{ bold: { weight: TextWeight.BOLD } }} {...ModalBodyStyle} inline />{' '}
          {textBold &&
            (Array.isArray(textBold) ? (
              <>
                <br />
                {textBold.map((text, index) => (
                  <Fragment key={`text-bold-${index}`}>
                    <Typography text={t(text)} {...ModalBodyStyle} weight={TextWeight.BOLD} inline />
                    {index !== textBold.length - 1 && <br />}
                  </Fragment>
                ))}
              </>
            ) : (
              <Typography text={t(textBold)} {...ModalBodyStyle} weight={TextWeight.BOLD} inline />
            ))}
        </>
      )}
    </>
  )

  return (
    <DeleteConfirmationModal
      dataTest={`${dataTest}--modal`}
      className={classNames(rootClass, className)}
      title={t(title)}
      body={body}
      deleteButtonText={t(buttonText)}
      onAnswer={(answer: YesNo) => {
        answer === YesNo.YES ? deleteAction() : onClose()
      }}
      isOpen
      isRemove={isRemove}
    />
  )
}

export default DeleteConfirmation
