import { gql } from '@apollo/client'

export default gql`
  query crmPeopleFields {
    crmPeopleFields {
      key
      value
    }
  }
`
