import React, { FC, useEffect, useRef, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'

import { useCrmPushErrorsModalRequests } from '@complex/CrmPushErrorsModal/GraphQL/CrmPushErrorsMOdalRequests.graphQL'
import { legacyActonContext, rootContext } from '@const/globals'
import {
  DefaultFormManagerModals,
  FormManagerAPI,
  FormManagerContext,
  FormManagerModals,
  FormManagerValues,
} from '@src/pages/Content/Forms/FormManager/context/FormManager.context'
import { FormManagerContextValues } from '@src/pages/Content/Forms/FormManager/context/FormManagerContext.values'
import FormManager from '@src/pages/Content/Forms/FormManager/FormManager'
import { FormManagerTabs } from '@src/pages/Content/Forms/FormManager/FormManager.constants'
import { useAccountSettings } from '@utils/account/account.utils'
import { getStandardFormattedDate } from '@utils/date'
import useCRM from '@utils/hooks/useCRM'
import { logNewRelicError } from '@utils/new-relic.utils'
import { MatchParams } from '@utils/types'
import { download } from '@utils/utils'

import { useFormManagerRequests } from './GraphQL/FormManagerRequests.graphQL'
import { getFormManagerValuesFromResponse, getPushErrorsCountFromResponse } from './Utils/FormManager.utils'

type FormManagerProps = RouteComponentProps<MatchParams> & {
  location: {
    state: {
      tab?: string
      subTab?: string
    }
  }
}

const baseUrl = `${rootContext}/content/forms`

const FormManagerContainer: FC<FormManagerProps> = (props) => {
  const { match, location } = props
  const history = useHistory()
  const { userAllowedToCreateContent, useOnlyNewForms } = useAccountSettings()
  const { hasCRMConnected } = useCRM()
  const reportIFrameRef = useRef<HTMLIFrameElement>(null)

  const { clearFormPerformanceReportRequest, getFormRequest, createFormEmbedCodeRequest, saveFormEmbedCodeRequest, deleteFormEmbedCodeRequest } =
    useFormManagerRequests()

  const { getFormCRMPushErrorsRequest } = useCrmPushErrorsModalRequests()

  const [containerValues, setActualContainerValues] = useState<FormManagerValues>(() => ({
    ...FormManagerContextValues.values,
    loading: true,
    tab: match.params.tab ?? FormManagerTabs.DETAILS,
    subTab: match.params.subTab ?? '',
    formId: match.params.id,
    canEdit: userAllowedToCreateContent,
    reportIFrameRef,
  }))

  const setContainerValues = (newContainerValues: Partial<FormManagerValues>) => {
    setActualContainerValues((containerValues) => ({ ...containerValues, ...newContainerValues }))
  }

  useEffect(() => {
    const subTab = containerValues.subTab ? `/${containerValues.subTab}` : ''
    history.replace(`${baseUrl}/${containerValues.formId}/${containerValues.tab}${subTab}${history.location.search}`, {
      ...location.state,
      tab: containerValues.tab,
      subTab: containerValues.subTab,
    })
  }, [containerValues.tab, containerValues.subTab])

  const getFormData = async () => {
    const { data, errors } = await getFormRequest({ id: containerValues.formId })
    if (errors || !data?.getForm?.id) {
      setContainerValues({ formNotFound: true })
    }

    if (data) {
      setContainerValues({ ...getFormManagerValuesFromResponse(data.getForm), classicFormsEnabled: !useOnlyNewForms })
    }
    setContainerValues({ loading: false })
  }

  useEffect(() => {
    getFormData()
    if (hasCRMConnected) {
      getFormCRMPushErrors()
    }
  }, [containerValues.formId])

  const tabChange = async (tab: string, subtab?: string) => {
    setContainerValues({ tab, subTab: subtab ?? location.state?.subTab ?? '' })
  }

  const subTabChange = (subTab: string) => {
    setContainerValues({ subTab })
  }

  const setLoading = (loading: boolean) => setContainerValues({ loading })

  const toggleModal = (modal: keyof FormManagerModals) => {
    setContainerValues({
      modals: {
        ...containerValues.modals,
        ...DefaultFormManagerModals,
        [modal]: !containerValues.modals[modal],
      },
    })
  }

  const downloadSubmittedData = (isZip?: boolean) => {
    const url = `${legacyActonContext}/forms/formSourceReportDetail2.jsp?formid=${
      containerValues.formId
    }&download=1&col=submit&ts=${Date.now().valueOf()}${isZip ? '&includeAttachments=1' : ''}`
    download(url, `${containerValues.title} submitted data (${getStandardFormattedDate(new Date().valueOf())}).xls`)
  }

  const clearPerformanceReport = async () => {
    const { data, errors } = await clearFormPerformanceReportRequest({ formId: containerValues.formId })

    if (data?.clearFormPerformanceReport) {
      toggleModal('clearPerformanceReportSuccess')
      reportIFrameRef.current?.contentWindow?.location.reload()
    }
    if (errors) {
      logNewRelicError(errors)
      toggleModal('clearPerformanceReportError')
    }
  }

  const getFormCRMPushErrors = async () => {
    const { data, errors } = await getFormCRMPushErrorsRequest({
      origin: containerValues.formId,
      listId: containerValues.submissionDetails.id,
    })
    if (errors) {
      toggleModal('pushErrorsErr')
      logNewRelicError(errors)
    }

    if (data) {
      setContainerValues({ crmPushErrorsCount: getPushErrorsCountFromResponse(data) })
    }
    setContainerValues({ loading: false })
  }

  // eslint-disable-next-line no-console
  const changeIPFiltering = () => console.log('change IP Filtering')

  const createEditEmbedCode = async (name: string, externalUrl: string, embedCodeId?: string) => {
    const { data, errors } = !embedCodeId
      ? await createFormEmbedCodeRequest({
          embedCode: { formId: containerValues.formId, name, deploymentId: '', externalURL: externalUrl },
        })
      : await saveFormEmbedCodeRequest({
          embedCode: {
            formId: containerValues.formId,
            name,
            deploymentId: containerValues.embeds.find((embed) => embed.id === embedCodeId)?.deploymentId ?? '',
            embedCodeId,
            externalURL: externalUrl,
          },
        })

    if (data) {
      await getFormData()
      toggleModal(embedCodeId ? 'editEmbedCodeSuccess' : 'createEmbedCodeSuccess')
    }

    if (errors) {
      logNewRelicError(errors)
      toggleModal(embedCodeId ? 'editEmbedCodeError' : 'createEmbedCodeError')
    }
  }

  const deleteEmbedCode = async (embedCodeId: string) => {
    const { data, errors } = await deleteFormEmbedCodeRequest({ formId: containerValues.formId, embedCodeId })

    if (data) {
      toggleModal('deleteEmbedCodeSuccess')
      getFormData()
    }

    if (errors) {
      toggleModal('deleteEmbedCodeError')
      logNewRelicError(errors)
    }
  }

  const onOpenBuilderWindow = (builderWindow: Window) => {
    setContainerValues({ builderWindow })
  }

  const onPageFocus = async () => {
    if (!containerValues.builderWindow) {
      return
    }

    if (containerValues.builderWindow.closed) {
      setContainerValues({ builderWindow: undefined })
    }
    await getFormData()
  }

  useEffect(() => {
    const handler = (event: MessageEvent<any>) => {
      if (event.origin !== window.location.origin) return

      const source = event.source ? (event.source as Window) : undefined
      if (source?.name === 'editNewForm' || source?.name.includes('editLandingPage')) {
        getFormData()
      }
    }

    window.addEventListener('message', handler)
    return () => {
      window.removeEventListener('message', handler)
    }
  }, [])

  useEffect(() => {
    if (containerValues.isClassic) {
      window.addEventListener('focus', onPageFocus)
      return () => window.removeEventListener('focus', onPageFocus)
    }
  }, [containerValues.builderWindow])

  const setCurrentRow = (id?: string, name?: string) =>
    id && name ? setContainerValues({ row: { id, name } }) : setContainerValues({ row: undefined })

  const formManagerContextValues: FormManagerAPI = {
    values: containerValues,
    tabChange,
    subTabChange,
    setLoading,
    toggleModal,
    setCurrentRow,
    downloadSubmittedData,
    clearPerformanceReport,
    getFormCRMPushErrors,
    changeIPFiltering,
    deleteEmbedCode,
    createEditEmbedCode,
    onOpenBuilderWindow,
  }

  return (
    <FormManagerContext.Provider value={formManagerContextValues}>
      <FormManager />
    </FormManagerContext.Provider>
  )
}

export default FormManagerContainer
