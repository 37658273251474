import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgProps } from '@components/Svg/Svg'

import './hoverSvg.css'

export interface HoverSvgProps extends Omit<SvgProps, 'name'> {
  className?: string
  dataTest?: string
  parentRef?: any
  svg: SvgNames
  hoverSvg: SvgNames
  hoverFillColor?: HoverFillColors
  allowPropagation?: boolean
  ignoreFillOnHover?: boolean
}

interface State {
  isHovering: boolean
}

export enum HoverFillColors {
  TEAL = 'teal',
  TEXT_TEAL = 'text-teal',
}

const rootClass = 'hover-svg'

const HoverSVG: FC<HoverSvgProps> = ({
  svg,
  fill,
  hoverSvg,
  hoverFillColor,
  type = SvgType.LARGER_ICON,
  parentRef,
  className,
  dataTest = rootClass,
  allowPropagation = false,
  ignoreFillOnHover = false,
  ...svgProps
}: HoverSvgProps) => {
  const [state, setState] = useState<State>({
    isHovering: false,
  })

  const onEnter = () => {
    setState({ ...state, isHovering: true })
  }

  const onLeave = () => {
    setState({ ...state, isHovering: false })
  }

  useEffect(() => {
    parentRef?.current?.addEventListener('mouseenter', onEnter)
    parentRef?.current?.addEventListener('mouseleave', onLeave)

    return () => {
      parentRef?.current?.removeEventListener('mouseenter', onEnter)
      parentRef?.current?.removeEventListener('mouseleave', onLeave)
    }
  }, [parentRef])

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={rootClass}
      onMouseEnter={() => !parentRef && onEnter()}
      onMouseLeave={() => !parentRef && onLeave()}
      onKeyDown={(keyDownEvent) => (keyDownEvent.key === ' ' ? keyDownEvent.stopPropagation() : undefined)}
      onClick={(e) => !allowPropagation && e.stopPropagation()}
    >
      <Svg
        {...svgProps}
        fill={ignoreFillOnHover && state.isHovering ? undefined : fill}
        className={classNames(rootClass, className, { [`${rootClass}__hover-${hoverFillColor}`]: !!hoverFillColor })}
        name={state.isHovering ? hoverSvg : svg}
        type={type}
        dataTest={`${dataTest}-icon`}
      />
    </div>
  )
}

export default HoverSVG
