import React, { FC, useEffect, useState } from 'react'

import { ApolloClient } from '@apollo/client'
import { renderLoader } from '@components/Loader/Loader'
import getOptInForms from '@graphql/microservices/sms-management/getOptInForms'
import getSentInitialMessage from '@graphql/microservices/sms-management/getSentInitialMessage'
import isInitialMessageInSendingStatus from '@graphql/microservices/sms-management/isInitialMessageInSendingStatus'
import {
  GetSentInitialMessageQuery,
  GetSentInitialMessageQueryVariables,
  IsInitialMessageInSendingStatusQuery,
  IsInitialMessageInSendingStatusQueryVariables,
  Query,
  QueryGetOptInFormsArgs,
} from '@graphql/types/microservice/sms-management-types'
import InitialMessageContainer from '@src/pages/sms/initialMessage'
import { useOptInsResults } from '@src/pages/sms/marketingListOptIns/components/SMSOptinViewAll/OptInView.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'
import { logNewRelicError } from '@utils/new-relic.utils'
import { SortByState } from '@utils/sms.utils'

import { MarketingListOptIns } from './MarketingListOptIns'

export interface State {
  pageIndex: number
  pageSize: number
  totalContacts: number
  formOptInsCount: number
  searchTerm: string
  isViewAllOpen: boolean
  sentInitialMessage: boolean
  client: ApolloClient<any>
}

interface Props {
  dataTest?: string
}

const defaultState = {
  pageIndex: 0,
  pageSize: 10,
  totalContacts: 0,
  formOptInsCount: 0,
  searchTerm: '',
  isViewAllOpen: false,
  sentInitialMessage: false,
}

export const formOptInsSortBy: SortByState = {
  id: 'LATEST_OPT_IN_DATE',
  desc: true,
}

export const MarketingListOptInsContainer: FC<Props> = () => {
  const { accountId, hasPurchasedSMS } = useAccountSettings()
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.SMS_MANAGEMENT })
  const [loading, setLoading] = useState(true)
  const [state, setState] = useState<State>({
    ...defaultState,
    client,
  })
  const [initialMessageInSendingStatus, setInitialMessageInSendingStatus] = useState<boolean>(false)

  const {
    loading: optInsResultsLoading,
    data,
    error,
  } = useOptInsResults({
    client: state.client,
    accountId,
    page: 0,
    size: 10,
    searchTerm: '',
    sortColumn: 'OPTINDATE',
    sortDirection: 'DESC',
  })

  const { data: formOptInsData, loading: formOptInsLoading } = useQueryOnMount<Query, QueryGetOptInFormsArgs>(getOptInForms, {
    client: state.client,
    fetchPolicy: 'network-only',
    variables: {
      accountId,
      size: state.pageSize,
      page: state.pageIndex,
      sortDirection: formOptInsSortBy.desc ? 'DESC' : ('ASC' as any),
      sortColumn: formOptInsSortBy.id as any,
    },
  })

  const { data: sentInitialMessage, loading: sentInitialMessageLoading } = useQueryOnMount<
    GetSentInitialMessageQuery,
    GetSentInitialMessageQueryVariables
  >(getSentInitialMessage, {
    client: state.client,
    fetchPolicy: 'network-only',
    variables: {
      accountId,
    },
  })

  const { data: isInitialMessageInSendingStatusData, loading: isInitialMessageInSendingStatusLoading } = useQueryOnMount<
    IsInitialMessageInSendingStatusQuery,
    IsInitialMessageInSendingStatusQueryVariables
  >(isInitialMessageInSendingStatus, {
    client: state.client,
    fetchPolicy: 'network-only',
    variables: {
      accountId,
    },
  })

  useEffect(() => {
    setState((state) => ({ ...state, totalContacts: data?.getOptInStatusByAccountId.totalContacts ?? 0 }))
  }, [data])

  useEffect(() => {
    setState((state) => ({ ...state, formOptInsCount: formOptInsData?.getOptInForms.totalCount ?? 0 }))
  }, [formOptInsData])

  useEffect(() => {
    const loading = optInsResultsLoading || sentInitialMessageLoading || formOptInsLoading || isInitialMessageInSendingStatusLoading

    setLoading(loading)
  }, [formOptInsLoading, isInitialMessageInSendingStatusLoading, optInsResultsLoading, sentInitialMessageLoading])

  useEffect(() => {
    if (sentInitialMessage?.getSentInitialMessage) {
      setState((state) => ({ ...state, sentInitialMessage: sentInitialMessage.getSentInitialMessage }))
    }
  }, [sentInitialMessage])

  useEffect(() => {
    if (isInitialMessageInSendingStatusData?.isInitialMessageInSendingStatus) {
      setInitialMessageInSendingStatus(isInitialMessageInSendingStatusData.isInitialMessageInSendingStatus)
    }
  }, [isInitialMessageInSendingStatusData])

  useEffect(() => {
    if (error) {
      logNewRelicError(error)
    }
  }, [error])

  const onOpenViewAll = () => setState((state) => ({ ...state, isViewAllOpen: !state.isViewAllOpen }))

  const onSearchChange = (searchTerm: string) => setState((state) => ({ ...state, searchTerm }))

  // wait until all queries return
  if (loading) {
    return renderLoader('loader--white-background')
  }

  const renderOptInPage = () => (
    <MarketingListOptIns
      totalContacts={state.totalContacts}
      searchTerm={state.searchTerm}
      onSearchChange={onSearchChange}
      loading={loading}
      error={error}
      isInitialMessageInSending={initialMessageInSendingStatus}
      isViewAllOpen={state.isViewAllOpen}
      onOpenViewAll={onOpenViewAll}
      hasPurchasedSMS={hasPurchasedSMS}
    />
  )

  return !hasPurchasedSMS || state.formOptInsCount === 0 || state.sentInitialMessage ? renderOptInPage() : <InitialMessageContainer />
}
