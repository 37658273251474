import React from 'react'

import classNames from 'classnames'

import { BreadcrumbsItemType } from '@components/Breadcrumbs/Breadcrumbs'
import ActionableBreadcrumbsItem from '@components/Breadcrumbs/components/ActionableBreadcrumbsItem/ActionableBreadcrumbsItem'
import BreadcrumbsItemWithInfoHover from '@components/Breadcrumbs/components/BreadcrumbsItemWithInfoHover/BreadcrumbsItemWithInfoHover'
import BreadcrumbsItemWithTooltip from '@components/Breadcrumbs/components/BreadcrumbsItemWithTooltip/BreadcrumbsItemWithTooltip'
import Svg, { SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import SvgNames from '@components/Svg/SvgNames'

import BreadcrumbsItem from './components/BreadcrumbsItem/BreadcrumbsItem'

const getBaseItem = (
  { onClick, text, isActive, customTypography, className, svgName }: BreadcrumbsItemType,
  charsLimit?: number,
  mainSvgName?: SvgNames
) => {
  const icon = svgName || mainSvgName
  return onClick && !isActive ? (
    <ActionableBreadcrumbsItem className={className} text={text} onClick={onClick} charsLimit={charsLimit} customTypography={customTypography} />
  ) : (
    <div className={classNames('flex-align-center', 'ellip')}>
      {icon && <Svg className={'breadcrumbs__icon'} name={icon} type={SvgType.MEDIUM} fill={SvgColor.TEAL} />}
      <div className={classNames('flex-align-center', 'ellip')}>
        <BreadcrumbsItem className={className} text={text} isActive={isActive} charsLimit={charsLimit} customTypography={customTypography} />
      </div>
    </div>
  )
}
export const renderBreadcrumbsItem = (breadcrumb: BreadcrumbsItemType, charsLimit?: number, svgName?: SvgNames) => {
  const { text, hasTooltip, customTooltip = text, infoHoverContent } = breadcrumb
  return hasTooltip ? (
    <BreadcrumbsItemWithTooltip trigger={getBaseItem(breadcrumb, charsLimit, svgName)} text={customTooltip} />
  ) : infoHoverContent ? (
    <BreadcrumbsItemWithInfoHover text={text} infoHoverContent={infoHoverContent} />
  ) : (
    getBaseItem(breadcrumb, charsLimit)
  )
}
