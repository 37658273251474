export const getCustomTableOptionsMock = () => [
  {
    label: '[Table Name] (profile type)',
    value: 'table_1',
    extraOptions: { type: 'PROFILE' },
  },
  {
    label: '[Table Name] (behavior type)',
    value: 'table_2',
    extraOptions: { type: 'BEHAVIOR' },
  },
]

export const getCustomTableDataOptionsMock = () => [
  {
    label: '[Text-based data]',
    value: 'data_1',
    extraOptions: { type: 'TEXT' },
  },
  {
    label: '[Numeric data]',
    value: 'data_2',
    extraOptions: { type: 'NUMERIC' },
  },
  {
    label: '[Date data]',
    value: 'data_3',
    extraOptions: { type: 'DATE' },
  },
]

export const getCustomTableDataBehaviorOptionsMock = () => [
  {
    label: '[Activity Date]',
    value: 'data_0',
    extraOptions: { type: 'ACTIVITY_DATE' },
  },
  {
    label: '[Text-based data]',
    value: 'data_1',
    extraOptions: { type: 'TEXT' },
  },
  {
    label: '[Numeric data]',
    value: 'data_2',
    extraOptions: { type: 'NUMERIC' },
  },
  {
    label: '[Date data]',
    value: 'data_3',
    extraOptions: { type: 'DATE' },
  },
]
