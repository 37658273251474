import { SvgNames } from '@components/Svg'
import { UserResponse } from '@graphql/types/query-types'

type RowActionClick = (row: UserResponse) => void

const getRowActions = (
  t: Function,
  onEditProfile: RowActionClick,
  onEditPermission: RowActionClick,
  onCopyPermission: RowActionClick,
  onDeleteUsersAction: RowActionClick,
  onResendVerificationAction: RowActionClick
) => {
  return [
    {
      label: t('Edit User Profile'),
      icon: SvgNames.pencil,
      onClick: (row: any) => onEditProfile(row.original),
    },
    {
      label: t('Edit permissions'),
      icon: SvgNames.key,
      onClick: (row: any) => onEditPermission(row.original),
      hidden: (row: any) => row.original.isMe,
    },
    {
      label: t('Copy permissions from another user'),
      icon: SvgNames.cloneSegment,
      onClick: (row: any) => onCopyPermission(row.original),
      hidden: (row: any) => row.original.isMe,
    },
    {
      label: t('Resend verification email'),
      icon: SvgNames.letter,
      onClick: (row: any) => onResendVerificationAction(row.original),
      hidden: (row: any) => row.original.isVerified,
      inDropdown: true,
    },
    {
      label: t('Delete user'),
      icon: SvgNames.delete,
      onClick: (row: any) => onDeleteUsersAction(row.original),
      hidden: (row: any) => row.original.isMe,
      inDropdown: true,
    },
  ]
}

export default getRowActions
