import { gql } from '@apollo/client'

export default gql`
  query getAllPermissions($id: String) {
    getAllPermissions(id: $id) {
      permissions {
        contactLists
        allContent
        accountWideEmails
        automatedPrograms
        launchApproval
      }
      status
      message
    }
  }
`
