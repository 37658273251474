import React, { ReactNode, useCallback, useMemo, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { LinkTextButton, LinkTextButtonColor, LinkTextButtonSize } from '@components/LinkTextButton/LinkTextButton'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import * as Popover from '@radix-ui/react-popover'

import './Toggletip.css'

export enum ToggletipSide {
  BOTTOM = 'bottom',
  TOP = 'top',
  RIGHT = 'right',
  LEFT = 'left',
}

export enum ToggletipAlign {
  CENTER = 'center',
  START = 'start',
  END = 'end',
}

export enum ToggletipTriggerSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export interface ToggletipProps {
  description: ReactNode | string
  title?: string
  trigger?: ReactNode
  activeTrigger?: ReactNode
  link?: string
  linkText?: string
  buttonText?: string
  side?: ToggletipSide
  sideOffset?: number
  align?: ToggletipAlign
  alignOffset?: number
  hasTail?: boolean
  isDismissable?: boolean
  ellipTitle?: boolean
  hide?: boolean
  onButtonClick?: () => void
  triggerSize?: ToggletipTriggerSize
  className?: string
  triggerOnHover?: boolean
  dataTest?: string
  tabIndex?: number
  inline?: boolean
}

const rootClass = 'toggletip'

const Toggletip = (props: ToggletipProps) => {
  const {
    trigger,
    activeTrigger,
    triggerOnHover = false,
    title,
    description,
    link,
    linkText,
    buttonText,
    side,
    sideOffset,
    align,
    alignOffset,
    hasTail = true,
    ellipTitle = true,
    isDismissable,
    hide,
    onButtonClick,
    triggerSize = ToggletipTriggerSize.MEDIUM,
    className,
    dataTest = rootClass,
    tabIndex,
    inline,
  } = props

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [triggerFocus, setTriggerFocus] = useState<boolean>(false)
  const [triggerHover, setTriggerHover] = useState<boolean>(false)

  const handleOpenChange = useCallback((open: boolean) => {
    setIsOpen(open)
    setTriggerFocus(open)
  }, [])

  const handleStopProp = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation(),
    []
  )
  const handleMouseEnter = useCallback(() => {
    setTriggerHover(true)
    if (triggerOnHover) {
      setIsOpen(true)
    }
  }, [])
  const handleMouseLeave = useCallback(() => {
    setTriggerHover(false)
    if (triggerOnHover) {
      setIsOpen(false)
    }
  }, [])

  const defaultIconType = useMemo<SvgType>(() => {
    switch (triggerSize) {
      case ToggletipTriggerSize.LARGE:
        return SvgType.LARGE_ICON
      case ToggletipTriggerSize.MEDIUM:
        return SvgType.LARGER_ICON
      case ToggletipTriggerSize.SMALL:
        return SvgType.ICON
    }
  }, [triggerSize])

  return (
    <Popover.Root open={hide ? false : isOpen} onOpenChange={handleOpenChange}>
      <Popover.Trigger
        onClick={handleStopProp}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={classNames(`${rootClass}__trigger`, `${rootClass}__trigger-default`, `${rootClass}__trigger-default-${triggerSize}`, {
          [`${rootClass}__trigger-inline`]: inline,
          [`${className}__trigger`]: !!className,
          [`${className}__trigger--focus`]: !!className && triggerFocus,
        })}
        data-test={`${dataTest}-trigger`}
        tabIndex={tabIndex}
      >
        {triggerFocus || triggerHover
          ? activeTrigger || trigger || <Svg name={SvgNames.infoHover} type={defaultIconType} fill={SvgColor.TEXT_TEAL} />
          : trigger || <Svg name={SvgNames.info} type={defaultIconType} fill={SvgColor.LIGHT_GRAY} />}
      </Popover.Trigger>
      <Popover.Content
        side={side}
        sideOffset={sideOffset}
        align={align}
        alignOffset={alignOffset}
        onClick={handleStopProp}
        className={classNames(`${rootClass}__content`, { [`${className}__content`]: className })}
        data-test={`${dataTest}-content`}
      >
        {(title || isDismissable) && (
          <div className={`${rootClass}__header`}>
            {title && (
              <Typography
                className={classNames(`${rootClass}__title`, {
                  ellip: ellipTitle,
                })}
                text={title}
                type={TextType.SECTION_HEADER}
                weight={TextWeight.BOLD}
              />
            )}
            {isDismissable && (
              <Popover.Close className={`${rootClass}__close`}>
                <Svg name={SvgNames.close} type={SvgType.LARGE_ICON} className={`${rootClass}__close--icon`} />
              </Popover.Close>
            )}
          </div>
        )}
        {typeof description === 'string' ? <Typography text={description} /> : description}
        {(buttonText || linkText) && (
          <div className={`${rootClass}__footer`}>
            {linkText ? (
              <LinkTextButton hideIconLeft text={linkText} link={link} size={LinkTextButtonSize.MEDIUM} color={LinkTextButtonColor.WHITE} />
            ) : (
              <div />
            )}
            {buttonText && (
              <Button buttonType={ButtonType.PRIMARY} onClick={onButtonClick}>
                {buttonText}
              </Button>
            )}
          </div>
        )}
        {hasTail && <Popover.Arrow className={`${rootClass}__arrow`} />}
      </Popover.Content>
    </Popover.Root>
  )
}

export default Toggletip
