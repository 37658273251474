import React, { FC } from 'react'

import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import InfoTooltip from '@components/InfoTooltip/InfoTooltip'
import Spinner from '@components/Spinner/Spinner'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { TextType } from '@components/Typography/Typography'
import { TextWeight } from '@components/Typography/Typography'
import { PreviewContact } from '@utils/composer/context/EmailComposer.context'
import { useTranslation } from '@utils/const/globals'
import { openContactView, ContactViewPanel } from '@utils/contacts'

import './RecipientsCard.css'

interface RecipientsCardProps {
  loading: boolean
  list: PreviewContact[]
  selectedContact: PreviewContact | null
  onChange: (index: number) => void
}

const rootClass = 'recipients-card'

const RecipientsCard: FC<RecipientsCardProps> = ({ loading, list, selectedContact, onChange }) => {
  const { t } = useTranslation()

  const currentContactIndex = selectedContact ? selectedContact.index + 1 : 0
  const isDisabledPrev = !selectedContact || selectedContact?.index === 0
  const isDisabledNext = !selectedContact || currentContactIndex === list.length

  return (
    <div className={rootClass} data-test={rootClass}>
      <div className={`${rootClass}__header`}>
        <div className={`${rootClass}__header-title`}>
          <Typography text={t('EmailComposer.Preview.Details.Recipients')} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} inline />
          <InfoTooltip minimalPadding={false}>
            <Typography
              className={`${rootClass}__header-title-tooltip`}
              text={t(`EmailComposer.Preview.Details.Recipients.Tooltip`)}
              type={TextType.BODY_TEXT_WHITE}
              tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
            />
          </InfoTooltip>
        </div>
        {!!list.length && (
          <div className={`${rootClass}__header-actions-container`}>
            <Typography text={`${currentContactIndex} of ${list.length}`} />
            <div className={`${rootClass}__header-actions`}>
              <Button
                className={`${rootClass}__header-actions-button`}
                dataTest={`${rootClass}-header-actions-prev`}
                buttonType={ButtonType.WHITE}
                disabled={isDisabledPrev}
                onClick={() => selectedContact && onChange(selectedContact.index - 1)}
              >
                <Svg name={SvgNames.caretLeft} fill={SvgColor.TEXT_GRAY} />
              </Button>
              <Button
                className={`${rootClass}__header-actions-button`}
                dataTest={`${rootClass}-header-actions-next`}
                buttonType={ButtonType.WHITE}
                disabled={isDisabledNext}
                onClick={() => selectedContact && onChange(selectedContact.index + 1)}
              >
                <Svg name={SvgNames.caretRight} fill={SvgColor.TEXT_GRAY} />
              </Button>
            </div>
          </div>
        )}
      </div>
      <div className={`${rootClass}__body`}>
        {loading ? (
          <Spinner className={`${rootClass}__loading`} />
        ) : !list.length ? (
          <Typography text={t(`EmailComposer.Preview.Details.Recipients.Empty`)} type={TextType.BODY_TEXT_LIGHT} />
        ) : (
          <>
            <Svg name={SvgNames.userCircle} type={SvgType.VERY_LARGE_ICON} fill={SvgColor.LIGHT_TEAL} />
            <div className={`${rootClass}__body-recipient`}>
              <Button
                buttonType={ButtonType.TEXT}
                iconPosition={ButtonIconPosition.RIGHT}
                onClick={() => selectedContact && openContactView(selectedContact?.id, ContactViewPanel.SUMMARY)}
              >
                <TextWithTooltipOnEllip
                  typographyProps={{
                    text: selectedContact?.name ?? selectedContact?.email,
                    type: TextType.SECTION_HEADER,
                    weight: TextWeight.MEDIUM,
                  }}
                />
                <Svg name={SvgNames.externalLink} type={SvgType.ICON} fill={SvgColor.TEXT_TEAL} />
              </Button>
              {selectedContact?.name && <TextWithTooltipOnEllip typographyProps={{ text: selectedContact?.email }} />}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default RecipientsCard
