import React, { FC, RefObject, useCallback, useContext, useRef, useState } from 'react'

import classNames from 'classnames'

import { IPluginFilePicker, ISpecialLink } from '@beefree.io/sdk/dist/types/bee'
import { PersonalizationContainer } from '@complex/Personalization/PersonalizationContainer'
import { getRecipientSrcIds } from '@complex/Personalization/utils/Personalization.utils'
import Button, { ButtonType } from '@components/Button/index'
import ButtonIcon, { ButtonIconSize, ButtonIconType } from '@components/ButtonIcon/ButtonIcon'
import ButtonMenu, { ButtonMenuOption } from '@components/ButtonMenu/ButtonMenu'
import Svg, { SvgNames } from '@components/Svg/index'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'
import { RowArgs } from '@src/pages/EmailComposer/BeeEditor/BeeEditorContainer'
import { EmailEditModalState, EmailModals, EmailModalsState } from '@src/pages/EmailComposer/EmailModals/EmailModals'
import { modalsInitialState } from '@src/pages/EmailComposer/utils/BeeEditor.constants'
import { BeeEditorActionDoneCallbacks } from '@src/pages/EmailComposer/utils/BeeEditor.types'
import { getButtonMenuProps } from '@src/pages/EmailComposer/utils/EmailComposer.constants'
import { detectEmailType, detectWebinarType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'

import UploadHtmlModal from '../UploadHtmlModal/UploadHtmlModal'

import './UploadHtmlComposerFooter.css'

interface UploadHtmlComposerFooterProps {
  inputRef: RefObject<HTMLTextAreaElement>
  isUndoEnabled: boolean
  isRedoEnabled: boolean
  undo: () => void
  redo: () => void
  insert: (text: string, position?: number) => void
  className?: string
  dataTest?: string
  isMinWidth?: boolean
}

const rootClass = 'upload-html-composer-footer'

const UploadHtmlComposerFooter: FC<UploadHtmlComposerFooterProps> = (props: UploadHtmlComposerFooterProps) => {
  const { undo, redo, insert, isUndoEnabled, isRedoEnabled, dataTest = rootClass, className = '', isMinWidth } = props
  const { t } = useTranslation()

  const {
    values: {
      isStory,
      message: { sendto_contacts, sendto_lists, individualRecipientsSourceIds, id, templateHtml, messageType, webinarConfig },
    },
  } = useContext(EmailComposerContext)

  const [editModalState] = useState<EmailEditModalState>({})
  const [modalState, setModalState] = useState<EmailModalsState>(modalsInitialState)
  const [customRowMetaData] = useState<Record<string, string>>()
  const [showPersonalizations, setShowPersonalizations] = useState(false)
  const [showUploadHtml, setShowUploadHtml] = useState(false)

  const footerRef = useRef<HTMLDivElement>(null)

  const closeModals = useCallback(() => setModalState(modalsInitialState), [])

  const buttonMenuProps = getButtonMenuProps(false, false)

  const getInsertText = (insertType: string, insertText: string, insertLabel?: string) => {
    if (insertType === 'IMAGE') {
      const formattedString = insertText.replace(/&/g, '&amp;')

      return `<img src='${formattedString}' />`
    } else {
      return `<a target='_blank' href='${insertText}'>${insertLabel}</a>`
    }
  }

  const { isEmailBlankMessage, isEmailTemplate, isEmailABTest, isEmailProgram, isEmailForm } = detectEmailType(messageType)
  const { isAccepted, isPending, isRejected, isDeclined } = detectWebinarType(webinarConfig)

  const actionDoneCallbacks = useRef<BeeEditorActionDoneCallbacks>({
    onSpecialLinkDone: (data: ISpecialLink | undefined) => {
      if (data) {
        insert(`${getInsertText(data.type, data.link, data.label)}`)
      }
      closeModals()
    },
    onSaveRowDone: () => null,
    onMergeTagDone: () => null,
    onAddOnDone: () => null,
    onFilePickerDone: (data?: IPluginFilePicker) => {
      if (data) {
        insert(`${getInsertText('IMAGE', data.url)}`)
      }
      closeModals()
    },
    onPreventMultipleLayoutsConfirm: () => null,
    onEditRowDone: () => null,
    onDisplayConditionDone: () => null,
  })

  const handleInsert = (selectedOption: ButtonMenuOption) => {
    switch (selectedOption.id) {
      case 'assetLink':
        setModalState({ ...modalState, selectAsset: true })
        break
      case 'imageBlock':
        setModalState({ ...modalState, imageModal: true })
        break
    }
  }

  const disableSegmentFields = isEmailABTest || isEmailForm || isEmailProgram || isAccepted || isPending || isRejected || isDeclined

  const renderPersonalizationModal = () => {
    return (
      <PersonalizationContainer
        isOpen
        disableAddRecipientsButton={!!sendto_contacts?.length}
        specificIds={getRecipientSrcIds(individualRecipientsSourceIds, sendto_lists)}
        closePersonalization={() => setShowPersonalizations(false)}
        doneCallback={(data) => {
          setShowPersonalizations(false)
          data?.value && insert(data.value)
        }}
        isStory={isStory}
        disableListOrSegmentFields={disableSegmentFields}
        disableListOrSegmentFieldsForTemplate={isEmailBlankMessage || isEmailTemplate}
      />
    )
  }

  const renderUploadHtmlModal = () => {
    return (
      <UploadHtmlModal
        isOpen
        msgId={id}
        hasHtmlContent={!!templateHtml}
        onClose={() => {
          setShowUploadHtml(false)
        }}
      />
    )
  }
  const onButtonMouseDown = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
  }

  return (
    <>
      {showPersonalizations && renderPersonalizationModal()}
      {showUploadHtml && renderUploadHtmlModal()}
      <EmailModals
        {...actionDoneCallbacks.current}
        modalsState={modalState}
        editModalState={editModalState}
        metaData={customRowMetaData}
        rowArgs={{} as RowArgs}
      />
      <div className={classNames(rootClass, className, { [`${rootClass}__wrapped`]: isMinWidth })} ref={footerRef}>
        <div className={classNames(`${rootClass}__left`, { [`${rootClass}__wrapped__left`]: isMinWidth })}>
          <ButtonMenu {...buttonMenuProps} onSelect={handleInsert} />
          <Button
            buttonType={ButtonType.FLOAT_TEAL}
            onClick={() => setShowUploadHtml(true)}
            minimalPadding
            className={`${rootClass}__personalize-button`}
            dataTest={`${dataTest}-personalize-button`}
          >
            <Svg name={SvgNames.upload} />
            {t('Upload')}
          </Button>
          <Button
            buttonType={ButtonType.FLOAT_TEAL}
            onClick={() => setShowPersonalizations(true)}
            minimalPadding
            className={`${rootClass}__personalize-button`}
            dataTest={`${dataTest}-personalize-button`}
          >
            <Svg name={SvgNames.userUnselected} />
            {t('Personalize')}
          </Button>
        </div>
        {isMinWidth && <div className={`${rootClass}__wrapped__divider`} />}
        <div className={`${rootClass}__right`}>
          <Tooltip
            trigger={
              <ButtonIcon
                type={ButtonIconType.TERTIARY}
                icon={isUndoEnabled ? SvgNames.actionUndoEnabled : SvgNames.actionUndoDisabled}
                size={ButtonIconSize.LARGE}
                onClick={undo}
                disabled={!isUndoEnabled}
                dataTest={`${dataTest}-undo-button`}
                onMouseDown={onButtonMouseDown}
              />
            }
            position={'top'}
          >
            {isUndoEnabled ? t('Undo') : t('EmailComposer.Preview.Plain.Text.Edit.NoUndo')}
          </Tooltip>
          <Tooltip
            trigger={
              <ButtonIcon
                type={ButtonIconType.TERTIARY}
                icon={isRedoEnabled ? SvgNames.actionRedoEnabled : SvgNames.actionRedoDisabled}
                size={ButtonIconSize.LARGE}
                onClick={redo}
                disabled={!isRedoEnabled}
                dataTest={`${dataTest}-redo-button`}
                onMouseDown={onButtonMouseDown}
              />
            }
            position={'top'}
          >
            {isRedoEnabled ? t('Redo') : t('EmailComposer.Preview.Plain.Text.Edit.NoRedo')}
          </Tooltip>
        </div>
      </div>
    </>
  )
}

export default UploadHtmlComposerFooter
