import { gql } from '@apollo/client'

export default gql`
  mutation editMessageDraft($draftId: String!) {
    editMessageDraft(draftId: $draftId) {
      draft {
        launchId
        title
        description
        marketingLists {
          listId
          listType
          listName
          totalContacts
          countMissingPhone
          countNotOptedIn
          countDuplicate
          suppressedInternationalContacts
          headers
          sampleContacts {
            recId
            emailAddress
            firstName
            lastName
          }
        }
        messageText
        recipientsPerCreditMultiplier {
          creditMultiplier
          contacts
          contactsPerCountry {
            country
            contacts
          }
        }
        recipientTimezones {
          tzOffset
          recipients
        }
      }
      config {
        launchId
        senderCode
        creditBalance
        smsNextMonthCredits
        vcFields {
          label
          value
          groupId
        }
        accountVCMaps {
          name
          mapping
        }
        crmFields {
          label
          value
          groupId
        }
        internationalSendingEnabled
      }
      locked
      lockedBy
    }
  }
`
