import React, { FC } from 'react'

import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { CombineRowsType } from '@src/pages/SegmentComposer/SegmentComposer.constants'

import './CombineLabel.css'

interface CombineLabelProps {
  className?: string
  dataTest?: string
  label: CombineRowsType
}

const rootClass = 'combine-label'

export const CombineLabel: FC<CombineLabelProps> = (props: CombineLabelProps) => {
  const { dataTest = rootClass, className = '', label } = props

  return (
    <div className={className} data-test={dataTest}>
      <div className={rootClass}>
        <Typography type={TextType.BODY_TEXT_SMALL} weight={TextWeight.BOLD} text={label.toUpperCase()} />
      </div>
    </div>
  )
}
