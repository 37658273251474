import React, { useCallback, useMemo } from 'react'

import classNames from 'classnames'
import { v4 as clipboardKey } from 'uuid'

import Button, { ButtonType } from '@components/Button'
import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import { getStatusTooltipText } from '../input.utils'
import { InputItemsWrapperProps } from '../InputTS/interface'

const clipboardErrMessage = 'Something went wrong...'

export const InputItemsWrapper = ({
  loading,
  isValid,
  hasError,
  errorKey,
  iconType,
  disabled,
  dataTest,
  readOnly,
  children,
  isTyping,
  rootClass,
  activeIcon,
  clearProps,
  showCounter,
  leadingIcon,
  statusProps,
  appearance,
  currentValue,
  trailingIcon,
  showClearBtn,
  statusVisible,
  clipBoardProps,
  maxCharacterProps,
  rootClassWithClear,
  copyToClipBoardStatus,
  rootClassWithMaxCharacter,
  onClear,
  onClearHandler,
  dispatch,
  onCopyToClipboard,
  onKeyDownHandlerSVG,
}: InputItemsWrapperProps) => {
  const { t } = useTranslation()
  const { maxLength, lengthTextType = TextType.BODY_TEXT_LIGHT, lengthTextLineHeight = LineHeight.MEDIUM_SMALL } = maxCharacterProps ?? {}
  const { tooltipErrorMessages, customTooltipErrorMessages } = statusProps ?? {}
  const { enableClear, alwaysShowClear } = clearProps ?? {}
  const { copyTooltip, tooltipErrorOnCopy, copySuccessTooltip } = clipBoardProps ?? {}
  const { success: clipBoardSuccess } = copyToClipBoardStatus ?? {}
  const endItemsCombination = [!!maxCharacterProps, !!clearProps, !!statusProps, !!clipBoardProps].filter(Boolean)
  const computedClipboardError = tooltipErrorOnCopy?.(currentValue ?? '')

  const isDividerVisible =
    appearance == 'standard' &&
    ((!statusProps && endItemsCombination.length == 2 && (currentValue || (isTyping && !currentValue))) ||
      ((enableClear || alwaysShowClear) && (isTyping || currentValue)) ||
      (readOnly && clipBoardProps) ||
      !!children)

  const statusTooltipText = useMemo(() => {
    return getStatusTooltipText({
      t,
      errorKey,
      customTooltipErrorMessages,
      error: hasError || isValid === false,
      tooltipErrorMessages,
    })
  }, [customTooltipErrorMessages, errorKey, hasError, isValid, t, tooltipErrorMessages])

  const getClipboardSvgName = useCallback(() => {
    if (copyToClipBoardStatus) {
      return clipBoardSuccess ? SvgNames.inputStatusSuccess : SvgNames.inputStatusInvalid
    } else if (hasError) {
      return SvgNames.inputStatusInvalid
    } else {
      return SvgNames.cloneSegment
    }
  }, [clipBoardSuccess, copyToClipBoardStatus, hasError])

  return (
    <>
      {leadingIcon && (
        <Svg
          className={classNames(`${rootClass}__leading-icon`, {
            [`${rootClass}__leading-icon-error`]: hasError,
            [`${rootClass}__leading-icon-disabled`]: disabled,
          })}
          name={leadingIcon}
          type={iconType}
          dataTest={`${dataTest}-svg-leading-icon`}
        />
      )}

      <div
        className={classNames(`${rootClass}__with-items`, {
          [`${rootClass}__with-items-has-copy`]: clipBoardProps,
          [`${rootClass}__with-items-right-position`]:
            !trailingIcon && !children && ((!statusProps && !currentValue?.length) || ((maxLength || clearProps) && (showClearBtn || statusProps))),
          [`${rootClass}__with-items-children`]: !!children,
        })}
        data-test={`${dataTest}__with-items`}
      >
        {!!maxLength && showCounter && (
          <div
            data-test={`${rootClassWithMaxCharacter}__counter`}
            className={classNames(`${rootClassWithMaxCharacter}__counter`, {
              [`${rootClassWithMaxCharacter}__counter-error`]: hasError || currentValue?.length === maxLength,
            })}
          >
            <Typography
              dataTest={`${rootClassWithMaxCharacter}__counter-value`}
              type={lengthTextType}
              text={
                maxLength >= 0
                  ? `${currentValue && maxLength && currentValue.length > maxLength ? maxLength : currentValue?.length}/${maxLength}`
                  : currentValue?.length
              }
              lineHeight={lengthTextLineHeight}
            />
          </div>
        )}

        {statusVisible && !!statusProps && loading ? (
          <Loader className={`${rootClass}-with-status__loader`} />
        ) : statusProps ? (
          <Tooltip
            className={`${rootClass}-with-status__tooltip`}
            trigger={
              <Svg
                className={classNames(`${rootClass}__trailing-icon`, {
                  [`${rootClass}__trailing-icon-error`]: !isValid,
                  [`${rootClass}__trailing-icon-success`]: isValid,
                })}
                name={(statusVisible ? activeIcon : undefined) as SvgNames}
                dataTest={`${dataTest}-status-tooltip-svg`}
                type={iconType}
              />
            }
            hide={(isValid || isValid === undefined || !errorKey) && !hasError}
            position="right"
          >
            {statusTooltipText}
          </Tooltip>
        ) : (
          trailingIcon &&
          !clipBoardProps && (
            <Svg
              className={classNames(`${rootClass}__trailing-icon`, {
                [`${rootClass}__trailing-icon-error`]: hasError,
              })}
              name={trailingIcon}
              type={iconType}
              dataTest={`${dataTest}-svg-${trailingIcon}-icon`}
            />
          )
        )}

        {isDividerVisible && <span className={classNames(`${rootClass}-item-divider`)} data-test={`${rootClass}-item-divider`} />}

        {isTyping && (enableClear || alwaysShowClear) ? (
          <Loader loaderType={LoaderTypes.row} className={`${rootClassWithClear}__typing-loader`} />
        ) : (
          (alwaysShowClear || (enableClear && showClearBtn)) && (
            <Tooltip
              align="start"
              trigger={
                <Button
                  buttonType={ButtonType.ICON}
                  className={classNames(`${rootClassWithClear}-button`)}
                  dataTest={`${dataTest}-clear-button`}
                  onClick={() => onClearHandler(dispatch, onClear)}
                  tabIndex={0}
                >
                  <Svg
                    className={classNames(`${rootClassWithClear}-button-svg`)}
                    name={SvgNames.clearIndicator}
                    type={SvgType.ICON}
                    tabIndex={0}
                    onKeyDown={onKeyDownHandlerSVG}
                  />
                </Button>
              }
            >
              Clear
            </Tooltip>
          )
        )}

        {children}

        {clipBoardProps && readOnly && (
          <Tooltip
            align="end"
            isPopover
            key={clipboardKey()}
            trigger={
              <Button
                className={classNames(`${rootClass}__copy-button`, {
                  [`${rootClass}__copy-button-read-only`]: readOnly && appearance == 'none',
                  [`${rootClass}__copy-button-success`]: clipBoardSuccess,
                  [`${rootClass}__copy-button-error`]: hasError,
                  [`${rootClass}__copy-button-disabled`]: disabled,
                })}
                dataTest={`${dataTest}-copy`}
                buttonType={ButtonType.ICON}
                onClick={hasError ? undefined : onCopyToClipboard}
              >
                <Svg name={getClipboardSvgName()} type={SvgType.LARGER_ICON} fill={SvgColor.TEXT_GRAY} />
              </Button>
            }
          >
            {clipBoardSuccess ? copySuccessTooltip : hasError ? computedClipboardError ?? clipboardErrMessage : copyTooltip}
          </Tooltip>
        )}
      </div>
    </>
  )
}
