import { gql } from '@apollo/client'

export default gql`
  query getRoiReport($startTime: String!, $endTime: String!) {
    getRoiReport(startTime: $startTime, endTime: $endTime) {
      message
      status
      fiscalInfo {
        increment
        fiscalQuarter
        currentPeriod
        fiscalMonth
        fiscalYear
        periodStartDate
        periodEndDate
      }
      leadsCreatedByLeadSource {
        count
        leadSource
      }
      leadsConvertedByLeadSource {
        count
        leadSource
      }
      asOf {
        asOfDate
      }
      campaignGroups {
        actualCost
        opportunitiesAmount
        closedOpportunitiesAmount
        wonOpportunitiesAmount
        openOpportunitiesAmount
        campaigns {
          actualCost
          opportunitiesAmount
          closedOpportunitiesAmount
          wonOpportunitiesAmount
          daysToClose {
            days
            count
          }
          name
          closedOpportunities
          createdOpportunities
          lostOpportunities
          openOpportunities
          opportunities
          wonOpportunities
          ownerStageCounts {
            ownerId
            stageCounts {
              stageName
              count
            }
          }
          stageTypes {
            type
            stages {
              opportunities
              stageName
              opportunitiesAmount
            }
          }
          type
        }
        closedOpportunities
        createdOpportunities
        lostOpportunities
        openOpportunities
        opportunities
        wonOpportunities
        type
      }
      accumulation {
        created
        wonAmt
        closed
        won
      }
    }
  }
`
