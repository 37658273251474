import React, { FC, useCallback, useEffect, useState } from 'react'

import classNames from 'classnames'

import InputWithStatus from '@components/InputWithStatus/InputWithStatus'
import Label from '@components/Label'
import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import { imageUrlIsValid } from '@components/UploadImage/components/utils'
import { useTranslation } from '@const/globals'
import { ValidFormatsSubheader } from '@src/pages/EmailComposer/EmailModals/components/ImageFileModal/components/ImageFileModal.utils'
import { ImageToInsertParams } from '@src/pages/EmailComposer/EmailModals/components/ImageFileModal/InsertImageModal'

import './InsertImageURLBody.css'

interface InsertImageURLBodyProps {
  setImageParams: React.Dispatch<React.SetStateAction<ImageToInsertParams>>
  url?: string
  isLandingPage?: boolean
  dataTest?: string
}

const _1MB = Math.pow(2, 20)
const _10MB = 10 * _1MB

const rootClass = 'insert-image-url-modal-body'

const InsertImageURLBody: FC<InsertImageURLBodyProps> = ({ setImageParams, url = '', isLandingPage, dataTest = rootClass }) => {
  const { t } = useTranslation()
  const [isValidURL, setIsValidURL] = useState<boolean>(imageUrlIsValid(url))
  const [isValidImage, setIsValidImage] = useState<boolean>(false)
  const [notSupportedType, setNotSupportedType] = useState<boolean>(false)
  const [loadingImg, setLoadingImg] = useState<boolean>(true)
  const [loadingSize, setLoadingSize] = useState<boolean>(false)
  const [size, setSize] = useState<number | null>(0)

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    const isValidUrl = imageUrlIsValid(url)
    if (!isValidUrl) {
      setIsValidURL(false)
      return
    }
    const type = new URL(url).pathname.split('.').pop()
    const isSVG = type === 'svg'
    setNotSupportedType(isSVG)
    if (isSVG && !isLandingPage) {
      setLoadingSize(false)
      return
    }

    setLoadingSize(true)
    fetch(url, { signal })
      .then((res) =>
        res.blob().then((file) => {
          setSize(file.size)
          setNotSupportedType(file.type === 'image/svg+xml')
        })
      )
      .catch(() => setSize(signal.aborted ? 0 : null))
      .finally(() => setLoadingSize(false))

    return () => {
      if (!isSVG) {
        controller.abort()
      }
    }
  }, [url])

  const handleURLChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const url = e.target.value
      const isValidUrl = imageUrlIsValid(url)
      setIsValidURL(isValidUrl)
      isValidUrl && setLoadingImg(true)
      setIsValidImage(false)
      setImageParams((cur) => ({ ...cur, url, validUrl: undefined }))
    },
    [setImageParams]
  )

  const handleImageError = useCallback(() => {
    setLoadingImg(false)
    setIsValidImage(false)
  }, [])

  const handleImageLoad = useCallback(() => {
    setLoadingImg(false)
    setIsValidImage(true)
  }, [])

  const loading = loadingImg || loadingSize
  const isValid = isValidURL && isValidImage && !notSupportedType
  const sizeDetected = size !== null
  const sizeError = sizeDetected && size > _10MB
  const showPreview = !loading && isValid && !sizeError
  const showError = !loadingImg && !!url && (!isValid || sizeError)

  useEffect(() => {
    showPreview && setImageParams((cur) => ({ ...cur, validUrl: url }))
  }, [showPreview, setImageParams, url])

  return (
    <div className={rootClass} data-test={dataTest}>
      <ValidFormatsSubheader />
      <Label required className={`${rootClass}__label`}>
        {t('Source URL')}
      </Label>
      <InputWithStatus
        value={url}
        onChange={handleURLChange}
        showStatus={!!url}
        hasCustomError={url ? showError : undefined}
        onChangeDebounce={500}
        tooltipProps={{ inline: false, hide: true }}
        dataTest={`${dataTest}-input-url`}
      />
      {showPreview && sizeDetected ? null : (
        <Typography
          type={showError ? TextType.ERROR_NEW : TextType.BODY_TEXT_SMALL_LIGHT}
          lineHeight={LineHeight.MEDIUM_SMALL}
          text={
            loading && url ? (
              <Loader loaderType={LoaderTypes.row} className={`${rootClass}__loader`} />
            ) : (
              t('Insert.Image.URL.Body.Helper', {
                context: showError
                  ? notSupportedType
                    ? 'noSupported'
                    : !isValid
                    ? 'invalid'
                    : 'sizeError'
                  : !sizeDetected && !loadingSize && url
                  ? 'sizeUndetected'
                  : 'text',
              })
            )
          }
          className="push-down"
          dataTest={`${dataTest}-helper-text`}
        />
      )}
      <div className={classNames({ [`${rootClass}__preview-hide`]: !showPreview })} data-test={`${dataTest}-preview`}>
        <Label className={`${rootClass}__preview__label`}>{t('Preview')}</Label>
        <div className={`${rootClass}__preview__img-wrapper`}>
          <img src={url} onError={handleImageError} onLoad={handleImageLoad} alt={url} className={`${rootClass}__preview__img`} />
        </div>
      </div>
    </div>
  )
}

export default InsertImageURLBody
