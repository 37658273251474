import React, { FC, useRef } from 'react'

import AssetPickerModal, { AssetPickerModalBaseProps, AssetPickerModalProps } from '@complex/AssetPickerModal/AssetPickerModal'
import { AssetPickerListingPageProps, AssetPickerSidebarProps, AssetPickerTableProps } from '@complex/AssetPickerModal/Context/AssetPicker.context'
import { CustomSource, ListingPageExternalApi, ListingPageItem, RenderSearchColumnsV2 } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { useTranslation } from '@const/globals'
import { WebinarPickerUi } from '@graphql/types/query-types'
import { filterNotEmptyArray } from '@utils/array'
import { ItemType } from '@utils/categorization'
import { allWebinarsFilter } from '@utils/filter'

import { useWebinarRequests } from './useWebinarRequests'
import { renderCustomFilters } from './WebinarsPickerModal.sidebar'
import { renderSearchTableColumns, renderTableColumns } from './WebinarsPickerModal.tables'

const WebinarsPickerModal: FC<AssetPickerModalBaseProps> = (props) => {
  const { t } = useTranslation()
  const listingApi = useRef<ListingPageExternalApi>()
  const isFilterCountsSet = useRef(false)

  const requests = useWebinarRequests()

  const convertWebinarsToItemDto = (webinars: (WebinarPickerUi | undefined)[]) => {
    return webinars.filter(filterNotEmptyArray).map((webinar) => {
      const dto = webinar as ListingPageItem
      dto.name = webinar.title
      dto.externalId = webinar.id
      dto.item = JSON.stringify(dto)
      return dto
    })
  }

  const isUpcoming = (item: WebinarPickerUi) => (item.date ?? 0) >= new Date().valueOf()
  const isPast = (item: WebinarPickerUi) => (item.date ?? 0) < new Date().valueOf()

  const customSource: CustomSource = {
    allItemFilter: allWebinarsFilter,
    itemName: 'Webinar',
    label: 'Webinars',
    mainColumnName: 'Webinar',
    value: ItemType.WEBINAR,
    itemType: ItemType.WEBINAR,
    customRequestGeneral: {
      getCountQueryRequest: async () => ({}),
      getSubTypesByTypesRequest: async () => ({}),
    },
    customRequestFilters: [
      {
        filter: allWebinarsFilter,
        request: async (_, params) => {
          let responseData: WebinarPickerUi[] = []
          if (params?.subTypes.length) {
            if (params.subTypes.includes('upcoming')) {
              const response = await requests.getWebinarsByFilterRequest({ scheduleFilter: 'UPCOMING' })
              responseData = response.data.getWebinarsByFilter?.filter(filterNotEmptyArray) ?? []
            }
            if (params.subTypes.includes('past')) {
              const response = await requests.getWebinarsByFilterRequest({ scheduleFilter: 'PAST' })
              responseData = [...responseData, ...(response.data.getWebinarsByFilter?.filter(filterNotEmptyArray) ?? [])]
            }
          } else {
            const response = await requests.getWebinarsRequest({})
            responseData = response.data.getWebinars?.filter(filterNotEmptyArray) ?? []
          }

          if (!isFilterCountsSet.current) {
            listingApi.current?.updateFilterCounts({
              [allWebinarsFilter.name]: responseData.length,
              upcoming: responseData.filter(isUpcoming).length,
              past: responseData.filter(isPast).length,
            })
            isFilterCountsSet.current = true
          }
          return { data: convertWebinarsToItemDto(responseData) }
        },
        searchRequest: async (query, _, params) => {
          const response = await requests.getWebinarsByTitle({ title: query })

          let items = response.data.getWebinarsByTitle?.filter(filterNotEmptyArray) ?? []
          if (params?.subTypes.length) {
            const criteria: ((item: WebinarPickerUi) => boolean)[] = []
            if (params.subTypes.includes('upcoming')) {
              criteria.push(isUpcoming)
            }
            if (params.subTypes.includes('past')) {
              criteria.push(isPast)
            }
            items = items.filter((item) => {
              return criteria.map((evaluate) => evaluate(item)).reduce((prev, cur) => prev || cur, false)
            })
          }
          return { data: convertWebinarsToItemDto(items) }
        },
      },
    ],
  }

  const renderSearchColumns: RenderSearchColumnsV2 = (
    _searchInAllItems: boolean | undefined,
    _currentFolder: FolderData,
    search: string,
    folders: FolderData[]
  ) => renderSearchTableColumns(search, folders, t)

  const tableProps: AssetPickerTableProps = {
    columns: renderTableColumns(t),
    renderSearchColumns,
    listPage: 'Webinars',
    enableLazyLoading: false,
    enableSorting: true,
    sortingBy: [{ id: 'date', desc: true }],
  }

  const sidebarProps: AssetPickerSidebarProps = {
    customSources: [customSource],
    hasRecent: false,
    hasCreatedByMe: false,
    hasFavorites: false,
    hideFolders: true,
    allItemFilter: allWebinarsFilter,
    renderCustomFilters,
  }

  const listingPageProps: AssetPickerListingPageProps = {
    onApiAvailable: (api) => {
      listingApi.current = api
    },
    hasCustomRequests: true,
    sidebarProps,
    subTypes: [
      {
        label: 'Upcoming',
        name: 'upcoming',
      },

      {
        label: 'Past',
        name: 'past',
      },
    ],
  }

  const assetPickerProps: AssetPickerModalProps = {
    listingPageProps,
    tableProps,
    hasTags: false,
    hasCustomFilters: true,
    hasSidebar: true,
    hasPreview: false,
    itemType: ItemType.WEBINAR,
    hideRecordsCount: true,
    ...props,
  }

  return <AssetPickerModal {...assetPickerProps} />
}

export default WebinarsPickerModal
