import React, { FC, useState } from 'react'

import classNames from 'classnames'

import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, LineHeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { getStandardFormattedDate } from '@utils/date'

import './lastUpdated.css'

interface Props {
  lastUpdated: number
  onRefresh?: () => void
  timeZone?: string
  className?: string
  dataTest?: string
}

const rootClass = 'last-updated'

const LastUpdated: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '', lastUpdated, timeZone, onRefresh } = props
  const [rotate, setRotate] = useState<boolean>(false)
  const { t } = useTranslation()

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Typography text={t('Program updated:')} className={`${rootClass}__label`} type={TextType.BODY_TEXT} inline />
      <Typography
        text={getStandardFormattedDate(lastUpdated, true, timeZone)}
        className={`${rootClass}__label`}
        type={TextType.BODY_TEXT_LIGHT}
        dataTest={`${rootClass}-date`}
        inline
      />
      {onRefresh && (
        <Tooltip
          trigger={
            <div
              role={'button'}
              tabIndex={0}
              onClick={() => {
                setRotate(true)
                onRefresh()
              }}
              onKeyDown={(keyDownEvent) => {
                if (keyDownEvent.key === ' ') {
                  setRotate(true)
                  onRefresh()
                }
              }}
              onAnimationEnd={() => setRotate(false)}
            >
              <Svg
                name={SvgNames.reload}
                type={SvgType.ICON}
                className={classNames(`${className}__icon`, { [`${rootClass}__icon--clicked`]: rotate })}
              />
            </div>
          }
          position={'right'}
        >
          <Typography text={t('Refresh')} type={TextType.BODY_TEXT_WHITE_SMALL} lineHeight={LineHeight.MEDIUM} />
        </Tooltip>
      )}
    </div>
  )
}

export default LastUpdated
