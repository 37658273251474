import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'

export const dateFormats = [
  'MM/dd/yyyy',
  'dd/MM/yyyy',
  'MM/dd/yy',
  'dd/MM/yy',
  'yyyy/MM/dd',
  'MM-dd-yyyy',
  'dd-MM-yyyy',
  'MM-dd-yy',
  'dd-MM-yy',
  'yyyy-MM-dd',
  'dd MMM yyyy',
  'MMM dd yyyy',
  'dd MMMM yyyy',
  'EEE, dd MMM yyyy',
  'EEEE, dd MMMM yyyy',
]

export const dateFormatsOptions: SelectV2SingleOption[] = dateFormats.map((format) => ({
  label: format,
  value: format,
}))
