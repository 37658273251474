import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import Accordion from '@components/Accordion/Accordion'
import Loader from '@components/Loader'
import { useTranslation } from '@const/globals'
import { UserPermissionsInput } from '@graphql/types/mutation-types'
import { PermissionsGroupName } from '@src/pages/Settings/Users/components/AllPermissionsForm/allPermissionsData'
import getPermissionsGroup from '@src/pages/Settings/Users/components/AllPermissionsForm/getPermissionsGroup'
import useAllPermissionsData from '@src/pages/Settings/Users/components/AllPermissionsForm/useAllPermissionsData'
import { getInitialApproval } from '@src/pages/Settings/Users/components/UserLaunchApprovalSelect/UserLaunchApprovalSelect'

import './AllPermissionsForm.css'

interface Props {
  onValueChange: ({ permissions, launchApproval }: { permissions: UserPermissionsInput; launchApproval?: string }) => void
  permissions?: UserPermissionsInput
  launchApproval?: string
  launchApprovalId?: string
  useGetDefaultValues?: boolean
  excludeUserIds?: string[]
  dataTest?: string
}

const rootClass = 'all-permissions-form'

const AllPermissionsForm: FC<Props> = (props: Props) => {
  const { onValueChange, permissions, launchApproval, launchApprovalId, useGetDefaultValues = false, excludeUserIds, dataTest = rootClass } = props
  const { t } = useTranslation()
  const [callOnChange, setCallOnChange] = useState<boolean>(useGetDefaultValues)
  const [_permission, setPermissions] = useState<UserPermissionsInput | undefined>(permissions)
  const [approvalOrAdminId, setApprovalOrAdminId] = useState<string | undefined>(getInitialApproval(launchApproval) || launchApprovalId)

  const launchChecked = useMemo<boolean>(() => approvalOrAdminId !== 'notallowed' && !!approvalOrAdminId, [approvalOrAdminId])
  const handleCheckboxClick = useCallback<(groupName: PermissionsGroupName, key: string, check: boolean) => void>((groupName, key, check) => {
    setCallOnChange(true)
    setPermissions(
      (
        cur = {
          contactLists: [],
          allContent: [],
          accountWideEmails: [],
          automatedPrograms: [],
        }
      ) => {
        const group = cur[groupName]
        if (!group) {
          return check ? { ...cur, [groupName]: [key] } : cur
        }
        const newGroupSet = new Set(group)
        check ? newGroupSet.add(key) : newGroupSet.delete(key)
        return { ...cur, [groupName]: Array.from(newGroupSet) }
      }
    )
  }, [])

  const handleSelectApproval = useCallback<(approvalOrAdminId?: string) => void>((approvalOrAdminId) => {
    setCallOnChange(true)
    setApprovalOrAdminId(approvalOrAdminId)
  }, [])

  useEffect(() => {
    if (callOnChange && _permission) {
      onValueChange({ permissions: _permission, launchApproval: approvalOrAdminId })
    }
  }, [callOnChange, _permission, approvalOrAdminId])

  useEffect(() => {
    setPermissions(permissions)
  }, [permissions])

  useEffect(() => {
    setApprovalOrAdminId(getInitialApproval(launchApproval) || launchApprovalId)
  }, [launchApproval, launchApprovalId])

  const { loading, allPermissionsData } = useAllPermissionsData({ setPermissions, currentPermissions: _permission })
  const childData = allPermissionsData.map((permissionGroup) =>
    getPermissionsGroup({
      permissionGroup,
      handleCheckboxClick,
      t,
      handleSelectApproval,
      launchApproval,
      launchApprovalId,
      launchChecked,
      excludeUserIds,
    })
  )

  return (
    <div className={rootClass} data-test={dataTest}>
      {loading ? (
        <Loader />
      ) : (
        <Accordion type="multiple" chevronLeft childData={childData} className={`${rootClass}__accordion`} triggerNoSidePadding />
      )}
    </div>
  )
}

export default AllPermissionsForm
