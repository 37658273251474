import React, { FC, Fragment, useRef } from 'react'

import classNames from 'classnames'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography, { LineHeight, TextAlign, TextType, TextWeight } from '@components/Typography/Typography'

import './ProgressBar.css'

export interface ProgressBarStep {
  isDisabled?: boolean
  header?: string
  hidden?: boolean
  isActive: boolean
  isCompleted: boolean
  canClick?: boolean // Used to indicate that the step can be clicked even if it is in a pending state
  key: string
}

interface ProgressBarProps {
  className?: string
  dataTest?: string
  steps: ProgressBarStep[]
  onClick?: (step: ProgressBarStep) => void
  isDisabled?: boolean
}

const rootClass = 'progress-bar'

const ProgressBar: FC<ProgressBarProps> = (props: ProgressBarProps) => {
  const { dataTest = rootClass, className = '', steps, onClick, isDisabled = false } = props

  const visibleSteps = steps.filter(({ hidden }) => !hidden)

  const stepContainerRef = useRef<(HTMLDivElement | null)[]>([])

  const renderButton = (step: ProgressBarStep) => (
    <div
      data-test={`${dataTest}-step-button`}
      className={classNames(`${rootClass}__button`, {
        [`${rootClass}__button-active`]: step.isActive,
        [`${rootClass}__button-pending`]: !step.isActive && !step.isCompleted,
        [`${rootClass}__button-pending-clickable`]: !step.isActive && !step.isCompleted && step.canClick,
        [`${rootClass}__button-done`]: !step.isActive && step.isCompleted,
        [`${rootClass}__button-not-clickable`]: !onClick,
        [`${rootClass}__button-disabled`]: step?.isDisabled,
      })}
      aria-disabled={step?.isDisabled}
      role={'button'}
      onClick={() => {
        if (onClick && ((step.isCompleted && !step.isActive) || step.canClick) && !step.isDisabled) {
          onClick(step)
        }
      }}
      onKeyDown={({ key }) => (key === 'Enter' ? step.isCompleted && onClick?.(step) : undefined)}
      tabIndex={0}
    >
      {!step.isActive && step.isCompleted && <Svg name={SvgNames.check} type={SvgType.ICON} fill={SvgColor.WHITE} />}
    </div>
  )

  const renderSeparator = (teal = false, hidden = false, className: string = '') => (
    <div
      className={classNames(`${rootClass}__separator`, className, {
        [`${rootClass}__separator-hidden`]: hidden,
        [`${rootClass}__separator-teal`]: teal,
      })}
    />
  )

  const renderStepHeader = (step: ProgressBarStep, index: number) => {
    const previousStep = index > 0 ? visibleSteps[index - 1] : undefined
    const nextStep = index < visibleSteps.length - 1 ? visibleSteps[index + 1] : undefined
    return (
      <div data-test={`${dataTest}-step`} className={`${rootClass}__step-header`} ref={(ref) => (stepContainerRef.current[index] = ref)}>
        <div className={`${rootClass}__step-header-button`}>
          {renderSeparator(previousStep?.isCompleted && (step.isCompleted || step.isActive), index === 0, `${rootClass}__separator-left`)}
          {renderButton(step)}
          {renderSeparator(
            step.isCompleted && (nextStep?.isCompleted || nextStep?.isActive),
            index === visibleSteps.length - 1,
            `${rootClass}__separator-right`
          )}
        </div>
        <Typography
          textAlign={TextAlign.CENTER}
          text={step.header ?? step.key}
          type={step?.isDisabled ? TextType.BODY_TEXT_SMALL_LIGHT : TextType.BODY_TEXT_SMALL}
          weight={TextWeight.MEDIUM}
          lineHeight={LineHeight.MEDIUM_SMALL}
        />
      </div>
    )
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {visibleSteps.map((step, index) => {
        const nextStep = index < visibleSteps.length - 1 ? visibleSteps[index + 1] : undefined
        return (
          <Fragment key={`${step.key}-step-container`}>
            {renderStepHeader(isDisabled ? { ...step, isDisabled: true } : step, index)}
            {index < visibleSteps.length - 1 ? renderSeparator(step.isCompleted && (nextStep?.isCompleted || nextStep?.isActive)) : null}
          </Fragment>
        )
      })}
    </div>
  )
}

export default ProgressBar
