import React, { FC, useEffect } from 'react'

import { MergeStrategy } from '@complex/ImportContacts/ImportContacts.constants'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import MappingScreen from '@src/pages/importcontacts/components/MappingScreen/MappingScreen'
import { defaultMappingPreviewField, ImportContactsContainerValues } from '@src/pages/importcontacts/context/ImportContactsContext'
import { getUpdatedMappingField, matchFieldsWithHeadersUtil } from '@src/pages/importcontacts/utils/ImportContactsContainerUtils'
import { replaceElement } from '@utils/utils'

export type MappingScreenValues = Pick<
  ImportContactsContainerValues,
  | 'nonDeletedFields'
  | 'deletedFields'
  | 'fileSelected'
  | 'hasHeaders'
  | 'indexRef'
  | 'inputOnBlurTriggered'
  | 'listName'
  | 'mappingPreview'
  | 'previewHeader'
  | 'previewIndex'
  | 'previewRecords'
  | 'validateAllTrigger'
  | 'validationTrigger'
  | 'visibleFields'
>

type Props = {
  className?: string
  dataTest?: string
  shouldChangeUrlPath?: boolean
  shouldMatchFieldsWithHeaders?: boolean
  update: (values: Partial<MappingScreenValues>) => void
  mergeStrategy?: MergeStrategy
} & Omit<MappingScreenValues, 'deletedFields' | 'nonDeletedFields'>

const MappingScreenContainer: FC<Props> = (props: Props) => {
  const {
    className,
    dataTest,
    fileSelected,
    hasHeaders,
    listName,
    mappingPreview,
    mergeStrategy,
    previewHeader,
    previewIndex,
    previewRecords,
    shouldChangeUrlPath = true,
    shouldMatchFieldsWithHeaders = true,
    update,
    validationTrigger,
    visibleFields,
  } = props

  const onChange = (index: number, values: Partial<MappingScreenValues>) => {
    update({
      validationTrigger: !validationTrigger,
      indexRef: index,
      ...values,
    })
  }

  const handleFieldChange = (index: number, fieldMapping: UnifiedListFieldMapping) => {
    onChange(index, {
      mappingPreview: replaceElement(
        index,
        { ...mappingPreview[index], ...getUpdatedMappingField(mappingPreview[index], fieldMapping) },
        mappingPreview
      ),
    })
  }

  const handleDataTypeChange = (index: number, dataTypeId: string) => {
    onChange(index, {
      mappingPreview: replaceElement(index, { ...mappingPreview[index], dataType: dataTypeId, dataFormat: '', state: null }, mappingPreview),
      previewRecords: previewRecords.map((previewRecord) => replaceElement(index, { ...previewRecord[index], hasError: false }, previewRecord)),
    })
  }

  const handleDataFormatChange = (index: number, value: string) => {
    onChange(index, {
      mappingPreview: replaceElement(index, { ...mappingPreview[index], dataFormat: value }, mappingPreview),
    })
  }

  const handleCustomField = (index: number, isCustom: boolean) => {
    onChange(index, {
      previewRecords: previewRecords.map((previewRecord) => replaceElement(index, { ...previewRecord[index], hasError: false }, previewRecord)),
      mappingPreview: replaceElement(
        index,
        {
          ...defaultMappingPreviewField,
          csvColumn: previewHeader[index],
          friendlyName: !isCustom && hasHeaders && previewHeader[index] ? previewHeader[index].trim() : '',
          index,
          isCustom: !isCustom,
          marketingColumnName: mappingPreview[index].marketingColumnName,
        },
        mappingPreview
      ),
    })
  }

  const handleFieldInput = (index: number, value: string) => {
    onChange(index, {
      inputOnBlurTriggered: true,
      mappingPreview: replaceElement(index, { ...mappingPreview[index], friendlyName: value.trim() }, mappingPreview),
    })
  }

  const handleMergeChange = (index: number, value: string) => {
    update({
      mappingPreview: replaceElement(index, { ...mappingPreview[index], merge: value }, mappingPreview),
    })
  }

  const setPreviewIndex = (index?: number) => {
    update({ previewIndex: index })
  }

  useEffect(() => {
    if (shouldMatchFieldsWithHeaders) {
      update({
        mappingPreview: matchFieldsWithHeadersUtil(mappingPreview, previewHeader, visibleFields, getUpdatedMappingField),
        validateAllTrigger: true,
      })
    }
  }, [])

  const mappingScreenFunctions = {
    handleCustomField,
    handleDataFormatChange,
    handleDataTypeChange,
    handleFieldChange,
    handleFieldInput,
    handleMergeChange,
    setPreviewIndex,
  }

  return (
    <MappingScreen
      {...mappingScreenFunctions}
      className={className}
      dataTest={dataTest}
      fileSelected={fileSelected}
      listName={listName}
      mappingPreview={mappingPreview}
      mergeStrategy={mergeStrategy}
      previewHeader={previewHeader}
      previewIndex={previewIndex}
      previewRecords={previewRecords}
      shouldChangeUrlPath={shouldChangeUrlPath}
      visibleFields={visibleFields}
    />
  )
}

export default MappingScreenContainer
