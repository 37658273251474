import { useCallback } from 'react'

import { useApolloClient, useMutation } from '@apollo/client'
import { useTranslation } from '@const/globals'
import addShortUrlMutation from '@graphql/mutations/addShortUrl'
import deleteShortUrlMutation from '@graphql/mutations/deleteShortUrl'
import {
  AddShortUrlMutation,
  AddShortUrlMutationVariables,
  DeleteShortUrlMutation,
  DeleteShortUrlMutationVariables,
} from '@graphql/types/mutation-types'
import { UrlGroup } from '@graphql/types/query-types'
import { useLandingPageContext } from '@src/pages/Content/LandingPages/LandingPagesManager/context/LandingPagesManager.context'
import { logNewRelicError } from '@utils/new-relic.utils'

const ACCOUNT_LIMIT_REACHED_ERROR_CODE = 22

const useShortUrlActions = (): {
  loading: boolean
  handleGenerateShortUrl: (groupId: string, onLimitError: () => void) => void
  handleDeleteShortUrl: (groupId: string) => void
} => {
  const { t } = useTranslation()
  const client = useApolloClient()
  const {
    landingPage: { id: pageId },
    updateLandingPage,
    setToastStatus,
  } = useLandingPageContext()

  const [addShortUrl, { loading: loadingAdd }] = useMutation<AddShortUrlMutation, AddShortUrlMutationVariables>(addShortUrlMutation, {
    client,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const [deleteShortUrl, { loading: loadingDelete }] = useMutation<DeleteShortUrlMutation, DeleteShortUrlMutationVariables>(deleteShortUrlMutation, {
    client,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const handleSuccess = useCallback(
    ({ action, urlGroups }: { action: 'Add' | 'Delete'; urlGroups: (UrlGroup | undefined)[] }) => {
      // First group always is default one
      const defaultUrlGroup = urlGroups.shift()
      updateLandingPage({ defaultUrlGroup, urlGroups })

      setToastStatus({
        showStatus: true,
        statusMessage: t(`${action}.Short.URL.Success.Message`),
        successStatus: true,
      })
    },
    [updateLandingPage]
  )

  const handleError = useCallback(({ action, errorMessage }: { action: 'Add' | 'Delete'; errorMessage?: string }) => {
    setToastStatus({
      showStatus: true,
      title: errorMessage ? t('Error!') : undefined,
      statusMessage: errorMessage ?? t(`${action}.Short.URL.Error.Message`),
      successStatus: false,
    })
  }, [])

  const handleGenerateShortUrl = useCallback<(groupId: string, onLimitError: () => void) => void>(
    (groupId, onLimitError) => {
      if (!groupId) {
        return
      }
      addShortUrl({
        variables: { pageId, urlId: groupId },
      })
        .then(({ data, errors }) => {
          if (data?.addShortUrl && Array.isArray(data.addShortUrl)) {
            handleSuccess({ action: 'Add', urlGroups: data.addShortUrl })
          } else {
            const error = Array.isArray(errors) ? errors[0] : undefined
            handleError({ action: 'Add', errorMessage: error?.message })
          }
        })
        .catch((error) => {
          logNewRelicError(error)
          const graphQLError = error?.graphQLErrors ? error.graphQLErrors[0] : null
          if (graphQLError?.extensions?.errorCode === ACCOUNT_LIMIT_REACHED_ERROR_CODE) {
            onLimitError()
          } else {
            handleError({ action: 'Add', errorMessage: error?.message })
          }
        })
    },
    [addShortUrl, pageId, handleSuccess, handleError]
  )

  const handleDeleteShortUrl = useCallback<(groupId: string) => void>(
    (groupId) => {
      if (!groupId) {
        return
      }
      deleteShortUrl({
        variables: { pageId, urlId: groupId },
      })
        .then(({ data }) => {
          if (data?.deleteShortUrl && Array.isArray(data.deleteShortUrl)) {
            handleSuccess({ action: 'Delete', urlGroups: data.deleteShortUrl })
          } else {
            handleError({ action: 'Delete' })
          }
        })
        .catch((error) => {
          handleError({ action: 'Delete' })
          logNewRelicError(error)
        })
    },
    [pageId, handleSuccess, handleError]
  )

  return { loading: loadingAdd || loadingDelete, handleGenerateShortUrl, handleDeleteShortUrl }
}

export default useShortUrlActions
