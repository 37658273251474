import React, { FC } from 'react'

import InfoHoverCard from '@components/InfoHoverCard/InfoHoverCard'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import TextLink from '@components/TextLink/TextLink'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { InvalidCRMStatus } from '@src/pages/ContactSegments/components/AddContactsFromCRM/AddContactsFromCRMContext'
import { InvalidSourceValues } from '@src/pages/ContactSegments/components/AddContactsFromCRM/components/SelectCRMSources/components/InvalidSourceWarning/InvalidSourceWarning.helper'
import { useAccountSettings } from '@utils/account/account.utils'

import './InvalidSourceWarning.css'

const rootClass = 'invalid-source-warning'

interface InvalidSourceWarningProps {
  crmStatus: InvalidCRMStatus
}
const InvalidSourceWarning: FC<InvalidSourceWarningProps> = (props: InvalidSourceWarningProps) => {
  const { crmStatus } = props
  const { isSF } = useAccountSettings()

  const { t } = useTranslation()

  if (!isSF) {
    return null
  }

  const { contentText, contentLink, headerText } = InvalidSourceValues[crmStatus]

  const renderHeader = () => (
    <div className={`${rootClass}__header`}>
      <Svg className={`${rootClass}__header-icon`} name={SvgNames.warning} type={SvgType.LARGER_ICON} />
      <Typography className={`${rootClass}__header-title`} text={t(headerText)} weight={TextWeight.BOLD} />
    </div>
  )

  const renderContent = () => (
    <div className={`${rootClass}__content`}>
      <Typography text={t(contentText)} type={TextType.BODY_TEXT_SMALL} inline />
      {!!contentLink && <TextLink className={`${rootClass}__content-link`} text={t('More info')} link={contentLink} />}
    </div>
  )

  return (
    <InfoHoverCard
      className={rootClass}
      dataTest={rootClass}
      icon={<Svg className={`${rootClass}__icon`} name={SvgNames.warningSolidNoFill} type={SvgType.ICON} />}
      header={renderHeader()}
    >
      {renderContent()}
    </InfoHoverCard>
  )
}

export default InvalidSourceWarning
