import React from 'react'

import classNames from 'classnames'

import { Item } from '@components/ActionableNestedTable/components/NestedTableRowWithDnD/NestedTableRowWithDnD'
import Button, { ButtonType } from '@components/Button/Button'
import { CollapsibleMenuItemWithHeaderAndActionsData, CollapsibleMenuItemWithHeaderData } from '@components/CollapsibleMenu/CollapsibleMenu'
import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import FilterContainer from '@components/FilterContainer/FilterContainer'
import Radio from '@components/Radio'
import RadioGroup from '@components/RadioGroup'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import SortableFolders from '@components/SortableFolders/SortableFolders'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Svg, { SvgType, SvgNames } from '@components/Svg'
import TagsList from '@components/TagsList/TagsList'
import Tooltip from '@components/Tooltip/Tooltip'
import { TextType } from '@components/Typography/Typography'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import { commonFilters, contactPreferencesFilter, crmSourcedFilter, FilterDefinition, FilterTypes } from '@utils/filter'

export enum MenuActions {
  DROP_ITEM = 'onItemDrop',
  CLICK_FOLDER = 'onFolderClick',
  CLICK_FOLDER_ACTION = 'onFolderActionClick',
  ADD_TAG = 'onAddTag',
  ADD_FOLDER = 'onAddFolder',
  CLICK_TAG = 'onTagClick',
  CLICK_TAG_ACTION = 'onTagActionClick',
  CLICK_CUSTOM_FILTER = 'CLICK_CUSTOM_FILTER',
}

interface BaseParams {
  dataTest: string
  callMenuAction: (action: MenuActions, data?: any) => void
  rootClass: string
  t: Function
  isCollapsed?: boolean
}

interface GetFiltersItemsParams {
  customFilters: FilterDefinition[]
  customFilterSelected?: boolean
  filterAction: (filter: FilterDefinition) => void
  filterActive: FilterDefinition | undefined
  hideFilterCount?: boolean
}

interface GetFoldersItemsParams extends BaseParams {
  activeFolderId: number | undefined
  defaultOpenedFolders: number[]
  hasToExpandFolders?: number[]
  clearFoldersToExpand?: VoidFunction
  folders: FolderData[]
  foldersEmptyListingText: string
  folderCount: number
}

interface GetTagsItemsParams extends BaseParams {
  tags: LabelDto[]
  activeTagId: number | undefined
  tagsEmptyListingText: string
}

export interface GetMenuItemsParams extends GetFiltersItemsParams, GetFoldersItemsParams, GetTagsItemsParams {}

const itemRowClass = 'menu-item'

export const renderMoreFiltersItems = (
  filterAction: (filter: FilterDefinition) => void,
  rootClass: string,
  t: Function,
  activeFilter?: FilterDefinition,
  hideCrmSynced = false,
  hideContactPreferences = false
): CollapsibleMenuItemWithHeaderData => ({
  content: (
    <RadioGroup className={`${rootClass}__more-filters`} verticalLayout>
      {!hideContactPreferences ? (
        <Radio
          label={t(FilterTypes.CONTACT_PREFERENCES)}
          checked={activeFilter?.name === FilterTypes.CONTACT_PREFERENCES}
          onChange={() => filterAction(contactPreferencesFilter)}
          boldOnChecked
        />
      ) : (
        <></>
      )}
      {!hideCrmSynced ? (
        <Radio
          label={t(FilterTypes.CRM_SOURCED)}
          checked={activeFilter?.name === FilterTypes.CRM_SOURCED}
          onChange={() => filterAction(crmSourcedFilter)}
          boldOnChecked
        />
      ) : (
        <></>
      )}
    </RadioGroup>
  ),
  icon: SvgNames.toggles,
  header: t('More Filters'),
  isOpen: true,
})

const getFiltersItems = (
  { customFilters, filterAction, filterActive, hideFilterCount }: GetFiltersItemsParams,
  hasRecent: boolean,
  hasCreatedByMe: boolean,
  hideAllItem?: FilterTypes
): CollapsibleMenuItemWithHeaderData => ({
  content: (
    <FilterContainer
      filters={[...customFilters, ...commonFilters]}
      activeFilter={filterActive}
      filterAction={filterAction}
      hasCreatedByMe={hasCreatedByMe}
      hasRecent={hasRecent}
      hideAllItem={hideAllItem}
      hideCount={hideFilterCount}
    />
  ),
  header: 'Filters',
  icon: SvgNames.segmentFilters,
  showHeaderWhenCollapsed: true,
  dataTest: 'menu-items-filters',
})

const getFoldersItems = ({
  rootClass,
  dataTest,
  folders,
  activeFolderId,
  defaultOpenedFolders,
  hasToExpandFolders,
  clearFoldersToExpand,
  foldersEmptyListingText,
  callMenuAction,
  t,
  folderCount,
}: GetFoldersItemsParams): CollapsibleMenuItemWithHeaderAndActionsData => ({
  action: (
    <Tooltip
      dataTest={`${dataTest}-add-folder`}
      position="top"
      trigger={
        <Button
          buttonType={ButtonType.ICON}
          className={classNames(`${itemRowClass}__add-button`)}
          onClick={() => callMenuAction(MenuActions.ADD_FOLDER)}
        >
          <Svg
            className={classNames(`${itemRowClass}__add-icon`, `${rootClass}__add-icon`)}
            name={SvgNames.plusLight}
            type={SvgType.ICON}
            dataTest={`${dataTest}-add-folder-icon`}
          />
        </Button>
      }
    >
      {t('Add Folder')}
    </Tooltip>
  ),
  content:
    folders.length > 0 ? (
      <SortableFolders
        className={classNames(`${rootClass}__folders`, `${itemRowClass}__folders`)}
        activeFolder={activeFolderId}
        folders={folders}
        defaultOpenedFolders={defaultOpenedFolders}
        hasToExpandFolders={hasToExpandFolders}
        clearFoldersToExpand={clearFoldersToExpand}
        onClick={(folderId) => callMenuAction(MenuActions.CLICK_FOLDER, folderId)}
        onActionClick={(folder: FolderData, action) => callMenuAction(MenuActions.CLICK_FOLDER_ACTION, { folder, action })}
        onDrop={(item: Item, folder: FolderData) => callMenuAction(MenuActions.DROP_ITEM, { item, folder })}
      />
    ) : (
      <EmptyListing
        imgSrc={StaticImageNames.aocNoFolders}
        linkText={'Add a folder'}
        text={foldersEmptyListingText}
        onLinkClick={() => callMenuAction(MenuActions.ADD_FOLDER)}
        size={EmptyListingSize.SMALL}
        textType={TextType.BODY_TEXT_SMALL_LIGHT}
        withoutBorder
      />
    ),
  header: folders.length > 0 ? t(`Folders ({{count}})`, { count: folderCount }) : t('Folders'),
  icon: SvgNames.folderClosed,
  isOpen: folders.length > 0,
  dataTest: 'menu-items-folders',
})

const getTagsItems = ({
  rootClass,
  dataTest,
  tags,
  activeTagId,
  tagsEmptyListingText,
  callMenuAction,
  t,
}: GetTagsItemsParams): CollapsibleMenuItemWithHeaderAndActionsData => ({
  action: (
    <Tooltip
      dataTest={`${dataTest}-add-tag`}
      position="top"
      trigger={
        <Button
          buttonType={ButtonType.ICON}
          className={classNames(`${itemRowClass}__add-button`)}
          onClick={() => callMenuAction(MenuActions.ADD_TAG)}
        >
          <Svg
            className={classNames(`${itemRowClass}__add-icon`, `${rootClass}__add-icon`)}
            name={SvgNames.plusLight}
            type={SvgType.ICON}
            dataTest={`${dataTest}-add-tag-icon`}
          />
        </Button>
      }
    >
      {t('Add Tag')}
    </Tooltip>
  ),
  content:
    tags.length > 0 ? (
      <TagsList
        onClick={(tag) => callMenuAction(MenuActions.CLICK_TAG, tag)}
        onActionClick={(tag, action) => callMenuAction(MenuActions.CLICK_TAG_ACTION, { tag, action })}
        selectedId={activeTagId}
        tags={tags}
        className={classNames(`${itemRowClass}__tags`, `${rootClass}__tags`)}
      />
    ) : (
      <EmptyListing
        imgSrc={StaticImageNames.aocNoTags}
        linkText={t('Add a tag')}
        text={tagsEmptyListingText}
        onLinkClick={() => callMenuAction(MenuActions.ADD_TAG)}
        size={EmptyListingSize.SMALL}
        textType={TextType.BODY_TEXT_SMALL_LIGHT}
        withoutBorder
      />
    ),
  header: tags.length > 0 ? t(`Tags ({{count}})`, { count: tags.length }) : t('Tags'),
  icon: SvgNames.tag,
  isOpen: tags.length > 0,
  dataTest: 'menu-items-tags',
})

export const getMenuItems = (menuItemsParams: GetMenuItemsParams, hasRecent: boolean, hasCreatedByMe: boolean, hideAllItem?: FilterTypes) => [
  getFiltersItems(menuItemsParams, hasRecent, hasCreatedByMe, hideAllItem),
  getFoldersItems(menuItemsParams),
  getTagsItems(menuItemsParams),
]
