import { useLandingPageContext } from '@src/pages/Content/LandingPages/LandingPagesManager/context/LandingPagesManager.context'

const useSeoUrl = (
  longUrl: string
): {
  prettyURLPrefix?: string
  seoUrl: string
} => {
  const {
    landingPage: { prettyURLPrefix },
  } = useLandingPageContext()

  return { prettyURLPrefix, seoUrl: longUrl.replace(new RegExp('^' + prettyURLPrefix), '') }
}

export default useSeoUrl
