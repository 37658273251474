import { useApolloClient } from '@apollo/client'
import getItem from '@graphql/microservices/categorization/getItem'
import deleteLists from '@graphql/microservices/entity-join/deleteLists'
import moveToTrash from '@graphql/microservices/segment/moveToTrash'
import convertToPhase3Form from '@graphql/mutations/convertToPhase3Form'
import getFormFieldsUsage from '@graphql/queries/getFormFieldsUsage'
import getFormPreview from '@graphql/queries/getFormPreview'
import { GetItemQuery, GetItemQueryVariables } from '@graphql/types/microservice/categorization-types'
import { DeleteListsMutation, DeleteListsMutationVariables } from '@graphql/types/microservice/entity-join-types'
import { MoveToTrashMutation, MoveToTrashMutationVariables } from '@graphql/types/microservice/segment-types'
import { ConvertToPhase3FormMutation, ConvertToPhase3FormMutationVariables, FormPhase3MigratorInput } from '@graphql/types/mutation-types'
import {
  GetFormFieldsUsageQuery,
  GetFormFieldsUsageQueryVariables,
  GetFormPreviewQuery,
  GetFormPreviewQueryVariables,
} from '@graphql/types/query-types'
import { FormFieldUsage } from '@src/pages/UpgradeAssistant/components/FormsUpgradeModal/context/FormsUpgradeModal.context'
import { ItemType } from '@utils/categorization'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export interface FormsUpgradeModalRequests {
  archiveLegacyListsRequest: (listIds: string[]) => FetchPromise<MoveToTrashMutation>
  deleteLegacyListsRequest: (listIds: string[]) => FetchPromise<DeleteListsMutation>
  getFormFieldsUsageRequest: (formIds: string[]) => FetchPromise<GetFormFieldsUsageQuery>
  getFormPreviewRequest: (variables: GetFormPreviewQueryVariables) => FetchPromise<GetFormPreviewQuery>
  upgradeFormsRequest: (data: {
    formIds: string[]
    formFields: FormFieldUsage[]
    createSegment: boolean
  }) => FetchPromise<ConvertToPhase3FormMutation>
  getItemByIdRequest: (externalId: string, type: ItemType) => FetchPromise<GetItemQuery>
}

export const useFormsUpgradeModalRequests = (): FormsUpgradeModalRequests => {
  const actonClient = useApolloClient()
  const { client: entityJoinServiceClient } = useMicroserviceClient({ serviceName: MicroserviceClients.ENTITY_JOIN })
  const { client: segmentServiceClient } = useMicroserviceClient({ serviceName: MicroserviceClients.SEGMENT })
  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })

  const archiveLegacyListsRequest = (listIds: string[]) => {
    return segmentServiceClient.mutate<MoveToTrashMutation, MoveToTrashMutationVariables>({
      mutation: moveToTrash,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables: {
        listIds,
      },
    })
  }

  const deleteLegacyListsRequest = (listIds: string[]) => {
    return entityJoinServiceClient.mutate<DeleteListsMutation, DeleteListsMutationVariables>({
      mutation: deleteLists,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables: {
        listIds,
      },
    })
  }

  const getFormFieldsUsageRequest = (formIds: string[]) =>
    actonClient.query<GetFormFieldsUsageQuery, GetFormFieldsUsageQueryVariables>({
      query: getFormFieldsUsage,
      fetchPolicy: 'network-only',
      variables: {
        formIds,
      },
    })

  const getFormPreviewRequest = async (variables: GetFormPreviewQueryVariables) =>
    actonClient.query<GetFormPreviewQuery, GetFormPreviewQueryVariables>({
      query: getFormPreview,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables,
    })

  const upgradeFormsRequest = (variables: { formIds: string[]; formFields: FormFieldUsage[]; createSegment: boolean }) => {
    const { formFields, formIds, createSegment } = variables
    const formPhase3Migrators = formFields.map<FormPhase3MigratorInput>(({ fieldMapping, fieldName, isMapped, uclColumnIndex }) => ({
      columnIndex: isMapped ? uclColumnIndex : undefined,
      fieldName: isMapped && fieldMapping ? fieldMapping : fieldName,
      formField: !isMapped,
      oldFieldName: fieldName,
    }))
    return actonClient.mutate<ConvertToPhase3FormMutation, ConvertToPhase3FormMutationVariables>({
      mutation: convertToPhase3Form,
      errorPolicy: 'all',
      variables: {
        formUpgradeData: {
          formIds,
          formPhase3Migrators,
          createSegment,
        },
      },
    })
  }

  const getItemByIdRequest = (externalId: string, type: ItemType) => {
    return categorizationClient.query<GetItemQuery, GetItemQueryVariables>({
      query: getItem,
      fetchPolicy: 'network-only',
      variables: {
        externalId,
        type,
      },
    })
  }

  return {
    archiveLegacyListsRequest,
    deleteLegacyListsRequest,
    getFormFieldsUsageRequest,
    getFormPreviewRequest,
    upgradeFormsRequest,
    getItemByIdRequest,
  }
}
