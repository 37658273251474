import React, { FC, useEffect } from 'react'

import { useApolloClient, useMutation } from '@apollo/client'
import copyPermissions from '@graphql/mutations/copyPermissions'
import editPermissions from '@graphql/mutations/editPermissions'
import setAdminPermission from '@graphql/mutations/setAdminPermission'
import {
  CopyPermissionsMutation,
  CopyPermissionsMutationVariables,
  EditPermissionsMutation,
  EditPermissionsMutationVariables,
  SetAdminPermissionMutation,
  SetAdminPermissionMutationVariables,
} from '@graphql/types/mutation-types'
import { useMarketingUsers } from '@src/pages/Settings/Users/context/MarketingUsersContext'
import { useAllUsers } from '@src/pages/Settings/Users/context/UsersContext'
import MarketingUsers from '@src/pages/Settings/Users/tabs/MarketingUsers/MarketingUsers'
import { logNewRelicError } from '@utils/new-relic.utils'

const MarketingUsersContainer: FC = () => {
  const client = useApolloClient()
  const { isAdministrator } = useAllUsers()
  const { users: marketingUsers, loading, maxCount, error, refetch } = useMarketingUsers()

  useEffect(() => {
    refetch()
  }, [])

  const [copyPermissionsData] = useMutation<CopyPermissionsMutation, CopyPermissionsMutationVariables>(copyPermissions, {
    client,
    fetchPolicy: 'no-cache',
  })

  const [editPermissionsData] = useMutation<EditPermissionsMutation, EditPermissionsMutationVariables>(editPermissions, {
    client,
    fetchPolicy: 'no-cache',
  })

  const [setAdmin] = useMutation<SetAdminPermissionMutation, SetAdminPermissionMutationVariables>(setAdminPermission, {
    client,
    fetchPolicy: 'no-cache',
  })

  if (error) {
    logNewRelicError(error)
  }

  return (
    <MarketingUsers
      data={marketingUsers}
      loading={loading}
      refetch={refetch}
      maxUsersCount={maxCount}
      isAdmin={isAdministrator}
      copyPermissions={copyPermissionsData}
      editPermissions={editPermissionsData}
      setAdmin={setAdmin}
    />
  )
}

export default MarketingUsersContainer
