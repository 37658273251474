import { gql } from '@apollo/client'

export default gql`
  query getRelatedForms($listId: String) {
    getRelatedForms(listId: $listId) {
      accountId
      createdTime
      enabled
      formId
      formName
      listId
      totalContacts
      totalSubmissions
      updatedTime
    }
  }
`
