import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import classNames from 'classnames'

import { getImportLogData, ImportContactsSources, importLogColumns, importLogRowActions } from '@complex/ImportLog/utils/ImportLog.utils'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import Modal, { ModalBody, ModalHeader, ModalSubHeader } from '@components/Modal'
import { TableV2 } from '@components/TableV2/TableV2'
import { TableV2RowData } from '@components/TableV2/tableV2TS/interfaces'
import Typography, { ModalHeaderStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import getImportDetailsStatus from '@graphql/microservices/list/getImportDetailsStatus'
import { ImportSyncJobDto } from '@graphql/types/microservice/entity-upload-types'
import { GetImportDetailsStatusQuery, GetImportDetailsStatusQueryVariables, ImportDetailsStatus } from '@graphql/types/microservice/list-types'
import { useEntityUploadService } from '@utils/hooks/microservices/useEntityUploadService'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'

import './ImportLog.css'

export type ImportLogProps = {
  className?: string
  dataTest?: string
  hasUnsavedImportChanges?: boolean
  isOpen: boolean
  onClose: VoidFunction
  importFileId?: string
}

export interface ImportLogData {
  recordsAdded: number
  date: string
  hasFailed: boolean
  importSyncJob?: ImportSyncJobDto
  importedBy: string
  recordsModified: number
  recordsFailed: number
  scheduleType: string
  name: string
  reportLink?: string
  reportLinkTooltip?: string
  result: string
  source: string
}

export type ImportLogDataRow = TableV2RowData<ImportLogData> & ImportLogData

interface State {
  importLogData: ImportLogDataRow[]
  importSyncJobs: ImportSyncJobDto[]
  loading: boolean
  loadingImportSyncJobs: boolean
  pageCount: number
  pageIndex: number
  pageSize: number
  showConfirmationModal: boolean
  totalImportsCount: number
}

export const importLogRootClass = 'import-log'
const DEFAULT_SORT_BY = [{ id: 'date', desc: true }]

const ImportLog: FC<ImportLogProps> = (props: ImportLogProps) => {
  const { dataTest = importLogRootClass, className = '', isOpen, onClose, importFileId, hasUnsavedImportChanges = false } = props

  const { t } = useTranslation()
  const pageSizeOptions = [10, 20, 50, 100]

  const [state, setState] = useState<State>({
    importLogData: [],
    importSyncJobs: [],
    loading: true,
    loadingImportSyncJobs: true,
    pageCount: 0,
    pageIndex: 0,
    pageSize: pageSizeOptions[0],
    showConfirmationModal: false,
    totalImportsCount: 0,
  })
  const { importLogData, totalImportsCount, pageCount, pageIndex, pageSize, showConfirmationModal, loading, loadingImportSyncJobs, importSyncJobs } =
    state

  const importSyncJobIdRef = useRef<number>()

  const { client: listService } = useMicroserviceClient({ serviceName: MicroserviceClients.LIST })

  const { getAllImportSyncJobs } = useEntityUploadService()

  const update = useDeepUpdate(setState)

  const editImportSyncJob = (importSyncJobId: number) => {
    window.open(`${rootContext}/importContactsV2/${ImportContactsSources.FTP}/${importSyncJobId}/options`, '_self')
  }

  const onImportSettingsClick = useCallback(
    ({ importSyncJob }: ImportLogDataRow) => {
      if (importSyncJob !== undefined) {
        if (hasUnsavedImportChanges) {
          importSyncJobIdRef.current = importSyncJob.importSyncJobId
          update({ showConfirmationModal: true })
        } else {
          editImportSyncJob(importSyncJob.importSyncJobId)
        }
      }
    },
    [hasUnsavedImportChanges, update]
  )

  const onConfirmationModalAnswer = useCallback(
    (answer: YesNo) => {
      if (answer === YesNo.YES && importSyncJobIdRef.current !== undefined) {
        editImportSyncJob(importSyncJobIdRef.current)
      }
      importSyncJobIdRef.current = undefined
      update({ showConfirmationModal: false })
    },
    [update]
  )

  const rowActions = useMemo(() => importLogRowActions(t, onImportSettingsClick), [onImportSettingsClick, t])

  const loadImportLog = (pageSize: number, pageNumber: number) => {
    listService
      .query<GetImportDetailsStatusQuery, GetImportDetailsStatusQueryVariables>({
        query: getImportDetailsStatus,
        fetchPolicy: 'network-only',
        variables: {
          importDetailsStatus: {
            sortBy: ['created_time'],
            pageSize,
            pageNumber,
            count: 0,
            filterBy: {
              source: ['ACTON_LIST', 'CSV', 'FTP'],
              source_id: importFileId ? [importFileId] : [],
            },
          },
        },
      })
      .then((response) => {
        const { importDetailsStatus } = response.data
        update({
          importLogData: getImportLogData((importDetailsStatus?.list as ImportDetailsStatus[]) ?? [], t, importSyncJobs),
          pageCount: importDetailsStatus?.totalPages ?? 0,
          totalImportsCount: importDetailsStatus?.count ?? 0,
          loading: false,
        })
      })
      .catch(() => {
        update({ loading: false })
      })
  }

  // Re-fetch the import status list when the user changes the page or the page size.
  useEffect(() => {
    if (!loadingImportSyncJobs) {
      update({ loading: true })
      loadImportLog(pageSize, pageIndex + 1)
    }
  }, [pageSize, pageIndex, loadingImportSyncJobs])

  useEffect(() => {
    getAllImportSyncJobs()
      .then((importSyncJobs) => {
        update({ importSyncJobs })
      })
      .finally(() => {
        update({ loadingImportSyncJobs: false })
      })
  }, [])

  // Method triggered by the paginator when the user changes the page options
  const changePageOptions = (pageIndex: number, pageSize: number) => {
    update({ pageSize, pageIndex })
  }

  const header = (
    <ModalHeader className={`${importLogRootClass}__modal-header`} closeButton={onClose} hideBorder>
      <Typography text={t('Import Log')} {...ModalHeaderStyle} />
    </ModalHeader>
  )

  const subHeader = (
    <ModalSubHeader className={`${importLogRootClass}__header`} hideBorder>
      <Typography text={`{{count}} imports`} values={{ count: totalImportsCount }} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.MEDIUM} />
    </ModalSubHeader>
  )

  return (
    <>
      <ConfirmationModal
        isOpen={showConfirmationModal}
        title={t('Are you sure?')}
        body={t('Changing the import setting will delete your current FTP import. Are you sure you want to proceed?')}
        isDelete
        onAnswer={onConfirmationModalAnswer}
        deleteButtonText={t('Yes')}
      />
      <Modal className={classNames(importLogRootClass, className)} data-test={dataTest} isOpen={isOpen} header={header} subHeader={subHeader}>
        <ModalBody className={`${importLogRootClass}__modal-body`}>
          <TableV2
            columns={importLogColumns}
            data={importLogData}
            enablePaginate={totalImportsCount > 1}
            enableSorting
            fetchData={changePageOptions}
            loading={loading || loadingImportSyncJobs}
            enableOuterLoader
            pageSizeOptions={pageSizeOptions}
            paginationState={{
              pageIndex,
              pageSize,
              controlledPageCount: pageCount,
              dropdownInsideModal: true,
              dropdownPosition: 'top',
            }}
            resetSorting
            sortingBy={DEFAULT_SORT_BY}
            rowActions={rowActions}
          />
        </ModalBody>
      </Modal>
    </>
  )
}

export default ImportLog
