import { gql } from '@apollo/client'

export default gql`
  mutation scheduleSmsLaunch(
    $billableCredits: Int!
    $composerState: SmsComposerState!
    $scheduledTime: Float!
    $isMarketerTimezone: Boolean
    $initialMessage: Boolean
  ) {
    scheduleSmsLaunch(
      billableCredits: $billableCredits
      composerState: $composerState
      scheduledTime: $scheduledTime
      isMarketerTimezone: $isMarketerTimezone
      initialMessage: $initialMessage
    )
  }
`
