import { gql } from '@apollo/client'

export default gql`
  query getRssFeed($url: String!) {
    getRssFeed(url: $url) {
      feedItems {
        url
        link
        author
        title
        description
        content
        date
      }
    }
  }
`
