import React, { FC, useEffect, useState } from 'react'

import DownloadButton from '@components/DownloadButton/DownloadButton'
import { MIN_PAGINATION_SIZE } from '@components/TableV2/components/Pagination/TableV2Pagination'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { OptInStatus } from '@graphql/types/microservice/sms-management-types'
import OptInsTable from '@src/pages/sms/marketingListOptIns/components/SMSOptinViewAll/OptInsTable'
import { getStandardFormattedDate } from '@utils/date'
import { SortByState } from '@utils/sms.utils'

import './smsOptins.css'

const rootClass = 'sms-opt-ins'

interface SMSOptinProps {
  entries: OptInStatus[] | []
  lastSyncDate: string
  accountTimeZoneId: string
  pageSize: number | 10
  pageIndex: number | 0
  totalCount: number | 0
  sortBy: SortByState[]
  fetchData: (pageIndex: number, pageSize: number) => void
  onChangeSort: (sortBy: Array<any>) => void
  downloadUrl: string
  loading?: boolean
  dataTest?: string
}

const SMSOptinViewAll: FC<SMSOptinProps> = (props: SMSOptinProps) => {
  const {
    entries,
    lastSyncDate,
    accountTimeZoneId,
    loading = false,
    downloadUrl,
    dataTest,
    totalCount,
    pageSize,
    pageIndex,
    fetchData,
    sortBy,
    onChangeSort,
  } = props
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(loading)

  const downloadDate = new Date()
  const downloadDateStr = downloadDate.toDateString().replace(/ /g, '-')

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  return (
    <>
      <div className={`${rootClass}__subhead`}>
        <div className={`${rootClass}__sync-date`}>
          <Typography
            type={TextType.BODY_TEXT_LIGHT}
            text={`${t('Last contact added')} ${getStandardFormattedDate(lastSyncDate as unknown as number, true, accountTimeZoneId)}`}
          />
        </div>
        {entries.length > 0 && (
          <DownloadButton url={downloadUrl} filename={downloadDateStr} label={'Download CSV'} dataTest={`${dataTest}__upload-button`} />
        )}
      </div>

      <OptInsTable
        entries={entries}
        totalCount={totalCount}
        emptyText={t('No contacts have been added yet.')}
        pageSize={pageSize}
        pageIndex={pageIndex}
        hidePagination={totalCount < MIN_PAGINATION_SIZE}
        fetchData={fetchData}
        sortBy={sortBy}
        onChangeSort={onChangeSort}
        loading={isLoading}
      />
    </>
  )
}

export default SMSOptinViewAll
