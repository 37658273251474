import React from 'react'

import { ListingPageItem } from '@complex/ListingPage/Context/ListingPageCommon.context'
import Button, { ButtonType } from '@components/Button'
import { ModalFooterV2Props } from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import { SvgNames } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { ItemDto } from '@graphql/types/query-types'
import { useTranslation } from '@utils/const/globals'
import { ellip } from '@utils/strings'
import { UpdateState } from '@utils/types'

import { getItemName, getRowSelectionState } from './AssetPickerModalUtil'
import { AssetPickerModalProps } from '../AssetPickerModal'
import { AssetPickerCommonState } from '../Context/AssetPicker.context'

export interface UseAssetPickerFooterProps {
  modalProps: AssetPickerModalProps
  modalState: AssetPickerCommonState
  update: UpdateState<AssetPickerCommonState>
}

export const useAssetPickerFooterProps = (props: UseAssetPickerFooterProps): ModalFooterV2Props => {
  const {
    modalProps: {
      hideRecordsCount = false,
      dataTest = 'asset-picker-footer',
      hasPreview,
      isSingleSelect,
      secondaryButtonText = 'Cancel',
      primaryButtonText = 'Submit',
      viewSelectedLabel,
      enableSubmitWithoutSelection = false,
      onSubmitCustomSources,
      onSubmit,
      onClose,
      tableProps: { listPage },
      listingPageProps: { hasCustomRequests },
    },
    modalState: {
      selectedViewItems,
      customSourceSelectedViewItems,
      isViewingSelected,
      listingPageState,
      listingPageState: { search, searchItemsResults, selectedCustomSource, selectedRows, items, showPreview, selectedRows: actionRows },
    },
    update,
  } = props

  const { t } = useTranslation()

  const customSourceSelectedViewItemsValues = Object.values(customSourceSelectedViewItems ?? {})
  const customSourcesSelectedItemsCount = Object.values(customSourceSelectedViewItemsValues ?? {}).reduce((curr, items) => curr + items.length, 0)
  const flatCustomSourceItems = customSourceSelectedViewItemsValues.flat()

  const handleSubmit = () => {
    if (hasCustomRequests && customSourceSelectedViewItems && onSubmitCustomSources) {
      onSubmitCustomSources?.(customSourceSelectedViewItems)
    } else if (onSubmit) {
      const selectedItem = showPreview ? actionRows : [...selectedViewItems]
      onSubmit?.(selectedItem)
    } else {
      onClose?.()
    }
  }

  const toggleViewSelected = () => {
    update({ isViewingSelected: !isViewingSelected })
  }

  const handleClose = () => {
    onClose?.()
  }

  const handlePreviewBack = () => {
    update({ listingPageState: { ...listingPageState, showPreview: false } })
  }

  let singleSelectedItemName = ''
  if (hasCustomRequests) {
    singleSelectedItemName = flatCustomSourceItems.length ? flatCustomSourceItems[0]?.name ?? '' : ''
  } else if (selectedViewItems.length) {
    singleSelectedItemName = getItemName(selectedViewItems[0]?.id, items, searchItemsResults, selectedViewItems)
  }

  const getSourcesSelectedRowsText = () => {
    const tProps = hideRecordsCount
      ? {
          count: customSourcesSelectedItemsCount,
          context: isSingleSelect ? 'single' : undefined,
          name: singleSelectedItemName,
        }
      : ({
          count: customSourcesSelectedItemsCount,
          recordsCount: customSourceSelectedViewItemsValues.reduce(
            (curr, items) => curr + items.reduce((curr, item) => curr + (item?.count ?? 0), 0),
            0
          ),
        } as {})
    return hideRecordsCount || !!customSourcesSelectedItemsCount
      ? t(`AssetPicker.${listPage}.Footer.SelectedRowCount`, tProps)
      : t(`AssetPicker.Sources.Footer.SelectedRowCount`, tProps)
  }

  const getDefaultSelectedRowsText = () => {
    const tProps = {
      count: selectedViewItems.length,
      name: ellip(singleSelectedItemName, 75),
      context: isSingleSelect ? 'single' : undefined,
    }
    return t(`AssetPicker.${listPage}.Footer.SelectedRowCount`, tProps)
  }

  const selectedRowsText = hasCustomRequests ? getSourcesSelectedRowsText() : getDefaultSelectedRowsText()

  let viewSelectedProps: ModalFooterV2Props['viewSelected'] | undefined
  let buttonProps: ModalFooterV2Props['buttons'] | undefined
  let onCloseProp: ModalFooterV2Props['onClose'] = handleClose

  if (!showPreview) {
    buttonProps = {
      actionButtonLabel: t(primaryButtonText),
      actionButtonOnClick: handleSubmit,
      actionButtonDisabled: selectedViewItems.length < 1 && !customSourcesSelectedItemsCount && !enableSubmitWithoutSelection,
      cancelButtonLabel: t(secondaryButtonText),
    }
    const noSelection = !isViewingSelected && selectedViewItems.length < 1 && !customSourcesSelectedItemsCount
    const selectedText = (
      <Typography
        text={selectedRowsText}
        type={TextType.BODY_TEXT_SMALL_LIGHT}
        tagProps={{ bold: { weight: TextWeight.MEDIUM, inline: true } }}
        lineHeight={LineHeight.MEDIUM_SMALL}
        inline
      />
    )
    const currentText =
      isSingleSelect && selectedViewItems.length ? (
        <Tooltip trigger={selectedText}>{<Typography text={singleSelectedItemName} type={TextType.BODY_TEXT_WHITE} />}</Tooltip>
      ) : (
        selectedText
      )

    if (!isSingleSelect) {
      // Show the View Selected button which lists the selected items
      viewSelectedProps = {
        noSelectionText: selectedRowsText,
        noSelection,
        label: isViewingSelected ? t('AssetPicker.Footer.ViewSelectedButtonInView') : t('AssetPicker.Footer.ViewSelectedButton'),
        icon: isViewingSelected ? SvgNames.arrowLeft : SvgNames.zoom,
        currentText,
        isViewSelected: isViewingSelected,
        onClick: toggleViewSelected,
      }
    } else if (isSingleSelect && hasPreview) {
      // Show the Preview selection button which previews the selected item
      viewSelectedProps = {
        noSelectionText: selectedRowsText,
        noSelection,
        label: t(viewSelectedLabel || 'Preview selection'),
        icon: SvgNames.zoom,
        currentText,
        isViewSelected: isViewingSelected,
        onClick: () => update({ listingPageState: { ...listingPageState, showPreview: true, selectedRows: selectedViewItems } }),
      }
    } else if (isSingleSelect && !hasPreview) {
      // Only show the selected item text
      viewSelectedProps = {
        hideButton: true,
        currentText,
        isViewSelected: false,
        onClick: () => null,
      }
    }
  } else if (showPreview && !isSingleSelect) {
    // Select / Unselect mode
    const selectedItems = hasCustomRequests ? (customSourceSelectedViewItems ?? {})[selectedCustomSource?.label ?? ''] ?? [] : selectedViewItems

    const isItemSelected = selectedViewItems.find((item) => item.id === actionRows[0].id)

    buttonProps = {
      renderCustomActionButton: () => (
        <Button
          buttonType={ButtonType.INFO}
          onClick={() => {
            let newItems: ItemDto[] = []
            let newRows: ListingPageItem[] = []
            if (isItemSelected) {
              // Unselect
              newItems = selectedItems.filter((item) => item.id !== actionRows[0]?.id)
              newRows = selectedRows.filter((row) => row.id !== actionRows[0]?.id)
            } else {
              // Select
              newItems = [...selectedItems, actionRows[0]]
              newRows = [...actionRows, actionRows[0]]
            }

            const customSourceSelectedViewItemsObj = hasCustomRequests
              ? { ...(customSourceSelectedViewItems ?? {}), [selectedCustomSource?.label ?? '']: newItems }
              : {}

            const selectedViewItemsList = hasCustomRequests ? Object.values(customSourceSelectedViewItemsObj).flat() : newItems
            update({
              selectedViewItems: selectedViewItemsList,
              customSourceSelectedViewItems: customSourceSelectedViewItemsObj,
              defaultSelectedRows: getRowSelectionState(search ? searchItemsResults : items, newItems, false),
              listingPageState: { ...listingPageState, selectedRows: newRows, showPreview: false },
            })
          }}
          dataTest={`${dataTest}-action-button`}
        >
          {isItemSelected ? t('Unselect') : t('Select')}
        </Button>
      ),
      cancelButtonLabel: t('Back'),
    }
    onCloseProp = handlePreviewBack
  } else if (showPreview && isSingleSelect) {
    // Choose asset
    buttonProps = {
      actionButtonLabel: t(primaryButtonText),
      actionButtonOnClick: handleSubmit,
      cancelButtonLabel: t('Back'),
    }
    onCloseProp = handlePreviewBack
  }

  return {
    buttons: buttonProps,
    viewSelected: viewSelectedProps,
    onClose: onCloseProp,
  }
}
