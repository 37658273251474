import React, { FC, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { TableV2 } from '@components/TableV2/TableV2'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { FormDto } from '@graphql/types/microservice/form-types'
import { CellContext } from '@tanstack/react-table'

import './UpdateFormsModal.css'

interface UpdateFormsModalProps {
  className?: string
  dataTest?: string
  forms: FormDto[]
  onUpdate: (formsIds: string[]) => void
  onCancel: () => void
}

interface UpdateFormsModalState {
  selectedFormsIds: string[]
}

const initialState: UpdateFormsModalState = {
  selectedFormsIds: [],
}

const rootClass = 'update-forms-modal'

const UpdateFormsModal: FC<UpdateFormsModalProps> = (props: UpdateFormsModalProps) => {
  const { dataTest = rootClass, className = '', forms, onCancel, onUpdate } = props
  const [state, setState] = useState<UpdateFormsModalState>(initialState)
  const { selectedFormsIds } = state
  const { t } = useTranslation()

  const renderFormCell = (row: CellContext<any, any>) => {
    return (
      <div className={`${rootClass}__name-with-icon`}>
        <Svg name={SvgNames.survey} type={SvgType.LARGER_ICON} />
        <TextWithTooltipOnEllip
          typographyProps={{
            type: TextType.BODY_TEXT,
            text: `${row.cell.getValue()}`,
          }}
        />
      </div>
    )
  }

  const columns: ColumnDefWithAdditionalProps<FormDto>[] = [
    {
      header: 'Forms',
      accessorKey: 'name',
      textAlign: 'left',
      enableCustomCellValue: true,
      cell: (row) => renderFormCell(row),
    },
  ]

  return (
    <Modal
      isOpen
      dataTest={dataTest}
      className={classNames(rootClass, className)}
      header={<ModalHeader headerType={ModalHeaderType.Form}>{t('Update.Form.Submissions.Modal.Title')}</ModalHeader>}
    >
      <ModalBody>
        <Typography type={TextType.BODY_TEXT_LIGHT} text={t('Update.Form.Submissions.Modal.Description', { count: forms.length })} />
        <TableV2
          styles={{ minWidth: 'auto' }}
          enableStickyHeader={false}
          withoutBorders
          data={forms}
          columns={columns}
          enableCheckbox
          onRowSelectionChanged={(ids, rows) => {
            setState({ ...state, selectedFormsIds: rows.filter(({ id }) => ids.includes(id)).map(({ original }) => original.id || '') })
          }}
        />
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form}>
        <Button buttonType={ButtonType.FLOAT} onClick={onCancel} dataTest={`${dataTest}-cancel-button`}>
          {t('Cancel')}
        </Button>
        <Button
          buttonType={ButtonType.PRIMARY}
          disabled={selectedFormsIds.length === 0}
          onClick={() => onUpdate(selectedFormsIds)}
          dataTest={`${dataTest}-update-button`}
        >
          {t('Update')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default UpdateFormsModal
