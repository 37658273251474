import React, { FC, useMemo } from 'react'

import Button, { ButtonType } from '@components/Button'
import { ModalBody, ModalFooter } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { MultipleTables } from '@components/TableV2/components/MultipleTables/MultipleTables'
import TableWithLoaderAndEmptyListing from '@components/TableWithLoaderAndEmptyListing/TableWithLoaderAndEmptyListing'
import { useTranslation } from '@const/globals'
import { SforceUserResponse } from '@graphql/types/query-types'
import UsersCountInfo from '@src/pages/Settings/Users/components/UsersCountInfo/UsersCountInfo'
import { NEED_MORE_LINK } from '@src/pages/Settings/Users/constants/users.constants'
import useSelectImportSalesUsers from '@src/pages/Settings/Users/tabs/SalesUsers/components/ImportSalesUsersModal/useSelectImportSalesUsers'
import { tableColumns } from '@src/pages/Settings/Users/tabs/SalesUsers/components/ImportSalesUsersModal/utils/importSalesUsers.tableColumns'
import getTableProps from '@src/pages/Settings/Users/tabs/SalesUsers/components/ImportSalesUsersModal/utils/importSalesUsers.tableProps'

import { columns } from './utils/importSalesUsers.tableColumns'
interface Props {
  isOpen: boolean
  loading: boolean
  users: SforceUserResponse[]
  numAvailable: number
  maxUsers: number
  onAdd: (ids: string[]) => void
  onCancel: () => void
  refreshCRM: () => void
  dataTest?: string
}

const rootClass = 'import-sales-users-modal'

const ImportSalesUsersModalContent: FC<Props> = (props: Props) => {
  const { isOpen, loading, users, numAvailable, maxUsers, onAdd, onCancel, refreshCRM, dataTest = rootClass } = props
  const { t } = useTranslation()
  const oldTableColumn = useMemo(() => tableColumns(t), [t])
  const tableProps = useMemo(() => getTableProps({ t, users, refreshCRM }), [t, users])

  const {
    onRowSelectionChanged,
    onSave,
    disableSave,
    availableRemaining,
    selectedCount,
    overrideCheckboxCell,
    checkboxCellOverride,
    invalidSelection,
  } = useSelectImportSalesUsers({
    onAdd,
    availableUsersNumber: numAvailable,
    isOpen,
  })

  return (
    <>
      <ModalBody>
        <MultipleTables
          tableV2Props={{
            data: users,
            columns,
            loading,
            enableSorting: true,
            withoutBorders: true,
            enableCheckbox: true,
            enableOuterLoader: true,
            defaultSelectedRows: tableProps.initialState?.selectedRowIds,
            onRowSelectionChanged,
            overrideCheckboxCell,
          }}
          oldTable={
            <TableWithLoaderAndEmptyListing
              dataTest={`${dataTest}-table`}
              className={`${rootClass}__table`}
              data={users}
              columns={oldTableColumn}
              loading={loading}
              onRowSelectionChanged={onRowSelectionChanged}
              checkboxCellOverride={checkboxCellOverride}
              {...tableProps}
            />
          }
        />
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form}>
        <UsersCountInfo
          labelKey="CRM.Users.Left.Label"
          tooltipKey="CRM.Users.Left.Tooltip"
          currentCount={availableRemaining}
          maxCount={maxUsers}
          needMoreLink={NEED_MORE_LINK}
          isError={invalidSelection}
          loading={loading}
          className={`${rootClass}__users-count`}
        />
        <Button buttonType={ButtonType.TERTIARY} onClick={() => onCancel()} dataTest={`${dataTest}-cancel-button`}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} onClick={onSave} disabled={disableSave} dataTest={`${dataTest}-save-button`}>
          {t(selectedCount < 2 ? 'Add User' : 'Add Users')}
        </Button>
      </ModalFooter>
    </>
  )
}

export default ImportSalesUsersModalContent
