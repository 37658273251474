import { gql } from '@apollo/client'

export default gql`
  query getContactSettings {
    getContactSettings {
      allowContacts
      contactOwner {
        contactOwnerType
        emailColumnIndex
        nameColumnIndex
      }
      inactiveCount
      rules {
        maxMessages
        timespan
      }
      salesSegment {
        contactsSegmentId
        opportunitySegmentId
        prospectsSegmentId
      }
      suppressedDomains
    }
  }
`
