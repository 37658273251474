import { CustomSourceItems } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { parseItemDtoResult } from '@complex/ListingPage/Utils/ListingPage.utils'
import { SyncedSegment, SyncedSegmentEntityFactor, SyncedSegmentQueryFactor } from '@graphql/types/microservice/crm-types'
import { CRMSegmentSource, InvalidCRMStatus } from '@src/pages/ContactSegments/components/AddContactsFromCRM/AddContactsFromCRMContext'
import {
  getSourcesFromEntityFactors,
  getSourcesFromQueryFactors,
  showFailedStatusToast,
} from '@src/pages/ContactSegments/components/AddContactsFromCRM/Helpers/AddContactsFromCRM.crm.helper'
import { GetSourceOptionsUtilsParams } from '@src/pages/ContactSegments/components/AddContactsFromCRM/Utils/AddContactsFromCRM.crm.utils.interfaces'
import { CRM_CONNECTOR_DEFINITIONS, getSourceTitle } from '@utils/crm.utils'
import { logNewRelicError } from '@utils/new-relic.utils'

import {
  GetSegmentSourcesUtilsParams,
  GetSyncedSegmentCountInfoUtilsParams,
  SaveSyncedSegmentUtilsParams,
  SetOptionsDetailsUtilsParams,
  SyncedSegmentDetailsUtilsParams,
  SyncNowUtilsParams,
  ValidateSourcesUtilsParams,
} from './AddContactsFromCRM.crm.utils.interfaces'

export const getSegmentSourcesUtils = async ({ connectorType, crmSourceOption, getSegmentSourcesRequest, update }: GetSegmentSourcesUtilsParams) => {
  try {
    const { data } = await getSegmentSourcesRequest({
      connectedCrmName: CRM_CONNECTOR_DEFINITIONS[connectorType],
      crmSourceOption,
    })

    if (data) {
      const crmSegmentSources = data?.segmentSources?.map(({ identifier, label }: any) => ({ identifier, label } as CRMSegmentSource))
      update({ crmSegmentSources })
    } else {
      showFailedStatusToast({ update, statusMessage: 'AddContactsFromCRM.StatusToast.Failed.SegmentSources' })
    }
  } catch (error) {
    logNewRelicError(error)
    showFailedStatusToast({ update, statusMessage: 'AddContactsFromCRM.StatusToast.Failed.SegmentSources' })
  }
}

export const getSourceOptionsUtils = async ({ getSourceOptionsRequest, update }: GetSourceOptionsUtilsParams) => {
  try {
    const crmSourceOptions = await getSourceOptionsRequest()
    if (crmSourceOptions) {
      update({ crmSourceOptions })
    } else {
      showFailedStatusToast({ update, statusMessage: 'AddContactsFromCRM.StatusToast.Failed.SourceOptions' })
    }
  } catch (error) {
    logNewRelicError(error)
    showFailedStatusToast({ update, statusMessage: 'AddContactsFromCRM.StatusToast.Failed.SourceOptions' })
  }
}

export const getSyncedSegmentCountInfoUtils = async ({ getSyncedSegmentCountInfoRequest, update }: GetSyncedSegmentCountInfoUtilsParams) => {
  try {
    const { data } = await getSyncedSegmentCountInfoRequest()
    if (data?.syncedSegmentCountInfo) {
      update({
        syncs: {
          total: data.syncedSegmentCountInfo.querySyncLimit,
          remaining: data.syncedSegmentCountInfo.remainingSyncs,
        },
      })
    } else {
      showFailedStatusToast({ update, statusMessage: 'AddContactsFromCRM.StatusToast.Failed.SyncedSegmentCountInfo' })
    }
  } catch (error) {
    logNewRelicError(error)
    showFailedStatusToast({ update, statusMessage: 'AddContactsFromCRM.StatusToast.Failed.SyncedSegmentCountInfo' })
  }
}

export const saveSyncedSegmentUtils = async ({ connectorType, saveSyncedSegmentRequest, syncedSegmentDto, update }: SaveSyncedSegmentUtilsParams) => {
  try {
    const { data } = await saveSyncedSegmentRequest({
      syncedSegmentDto: { ...syncedSegmentDto, connectedCrm: CRM_CONNECTOR_DEFINITIONS[connectorType] },
    })
    if (data?.saveSyncedSegment) {
      return true
    } else {
      showFailedStatusToast({ update, statusMessage: 'AddContactsFromCRM.StatusToast.Failed.SaveSyncedSegment' })
    }
  } catch (error) {
    logNewRelicError(error)
    showFailedStatusToast({ update, statusMessage: 'AddContactsFromCRM.StatusToast.Failed.SaveSyncedSegment' })
  }
}

export const getOptionsDetailsUtils = async ({ segmentIdentifiers, syncedSegmentDetailsRequest }: SetOptionsDetailsUtilsParams) => {
  try {
    if (segmentIdentifiers.length > 0) {
      const { data } = await syncedSegmentDetailsRequest({
        segmentIdentifiers,
      })
      return data?.syncedSegmentDetails as SyncedSegment[]
    } else {
      return []
    }
  } catch (error) {
    logNewRelicError(error)
  }
}

export const syncedSegmentDetailsUtils = async ({
  init,
  segmentIdentifiers,
  syncedSegmentDetailsRequest,
  update,
  crmSourceOptions,
}: SyncedSegmentDetailsUtilsParams) => {
  try {
    if (segmentIdentifiers.length > 0) {
      const { data } = await syncedSegmentDetailsRequest({
        segmentIdentifiers,
      })
      if (data?.syncedSegmentDetails) {
        const syncedSegmentDetails = (data?.syncedSegmentDetails as SyncedSegment[])[0]
        const { sourceType = '', syncedSegmentEntityFactors, syncedSegmentQueryFactors } = syncedSegmentDetails
        const crmSourceType = getSourceTitle(sourceType, crmSourceOptions)
        const sources: CustomSourceItems = {
          [crmSourceType]: parseItemDtoResult(
            syncedSegmentEntityFactors
              ? getSourcesFromEntityFactors(syncedSegmentEntityFactors as SyncedSegmentEntityFactor[])
              : getSourcesFromQueryFactors(syncedSegmentQueryFactors as SyncedSegmentQueryFactor[])
          ),
        }

        init
          ? update({
              isOngoingSync: syncedSegmentDetails.ongoingSync,
              pickedSources: {},
              selectedSourcedSegment: {
                ...syncedSegmentDetails,
                originalSources: sources,
              },
            })
          : update({
              selectedSourcedSegment: {
                ...syncedSegmentDetails,
                originalSources: sources,
              },
            })
      } else {
        showFailedStatusToast({ update, statusMessage: 'AddContactsFromCRM.StatusToast.Failed.SyncedSegmentDetails' })
      }
    } else {
      return []
    }
  } catch (error) {
    logNewRelicError(error)
    showFailedStatusToast({ update, statusMessage: 'AddContactsFromCRM.StatusToast.Failed.SyncedSegmentDetails' })
  }
}

export const syncNowUtils = async ({ syncNowRequest }: SyncNowUtilsParams) => {
  try {
    await syncNowRequest()
  } catch (error) {
    logNewRelicError(error)
  }
}

export const validateSourcesUtils = async ({
  connectorType,
  invalidSources,
  segmentSourceTypeName,
  sourceIdentifiers,
  update,
  validateSourcesRequest,
}: ValidateSourcesUtilsParams) => {
  try {
    update({ validatingSources: true })
    const { data } = await validateSourcesRequest({
      connectedCrmName: CRM_CONNECTOR_DEFINITIONS[connectorType],
      segmentSourceTypeName,
      sourceIdentifiers,
    })
    if (!!data?.validateSources) {
      const newInvalidSources = Object.entries(data.validateSources).map((source) => ({
        sourceIdentifier: source[0],
        status: source[1] as InvalidCRMStatus,
      }))
      update({ invalidSources: { ...invalidSources, [segmentSourceTypeName]: newInvalidSources } })
    }
  } catch (error) {
    logNewRelicError(error)
  }
}
