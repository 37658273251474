import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import classNames from 'classnames'

import Svg from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { rootContext } from '@const/globals'
import { CustomLogos } from '@graphql/types/query-types'
import { getInternalAddressFromCurrent } from '@src/pages/IFrameView/iframeViewUtils'
import { THEME } from '@utils/theme'

import { rootClass } from '../Navigation'

interface LogoProps {
  expanded: boolean
  theme: string
  isCustomLogo: boolean
  appLogo: string
  isMicrosoftStartPage: boolean
  userDesktop?: string
  customLogos?: CustomLogos
  pageSpecificLink?: string
  isNonRedirectingLogo?: boolean
}

const Logo: FC<LogoProps> = (props: LogoProps) => {
  const { expanded, theme, isCustomLogo, appLogo, isMicrosoftStartPage, userDesktop, customLogos, pageSpecificLink, isNonRedirectingLogo } = props

  const hasCustomLogo = isCustomLogo && expanded
  const renderLogo = () => {
    if (customLogos) {
      if (theme === THEME.CUSTOM) {
        if (expanded) {
          return (
            customLogos.customExpanded && (
              <img alt={'logo-custom-theme'} data-test="navigation-logo-custom-expanded" src={customLogos.customExpanded} />
            )
          )
        }
        return customLogos.customCollapsed && <img alt={'logo-custom-theme'} data-test="navigation-logo-custom" src={customLogos.customCollapsed} />
      } else if (theme === THEME.LIGHT) {
        if (expanded) {
          return (
            customLogos.lightExpanded && <img alt={'logo-light-theme'} data-test="navigation-logo-light-expanded" src={customLogos.lightExpanded} />
          )
        }
        return customLogos.lightCollapsed && <img alt={'logo-light-theme'} data-test="navigation-logo-light" src={customLogos.lightCollapsed} />
      } else if (theme === THEME.DARK) {
        if (expanded) {
          return customLogos.darkExpanded && <img alt={'logo-dark-theme'} data-test="navigation-logo-dark-expanded" src={customLogos.darkExpanded} />
        }
        return customLogos.darkCollapsed && <img alt={'logo-dark-theme'} data-test="navigation-logo-dark" src={customLogos.darkCollapsed} />
      }
      return null // should not return a logo if one is not specified
    }

    if (isCustomLogo) {
      return <img alt={'custom-logo-expanded'} data-test={expanded ? 'navigation-logo-custom-expanded' : ''} src={appLogo} />
    } else if (theme === THEME.LIGHT) {
      if (expanded) {
        return <Svg dataTest="navigation-logo-light-expanded" name={SvgNames.logo} />
      }
      return <Svg dataTest="navigation-logo-light" name={SvgNames.icon} />
    } else if (theme === THEME.DARK) {
      if (expanded) {
        return <Svg dataTest="navigation-logo-dark-expanded" name={SvgNames.logoWhite} />
      }
      return <Svg dataTest="navigation-logo-dark" name={SvgNames.iconWhite} />
    }
  }

  const link = pageSpecificLink ?? getInternalAddressFromCurrent(isMicrosoftStartPage, `${rootContext}/classic`, false, userDesktop)

  return (
    <>
      {isNonRedirectingLogo ? (
        <div
          className={classNames(`${rootClass}__logo`, {
            [`${rootClass}__logo--custom`]: hasCustomLogo || customLogos !== null,
          })}
        >
          {renderLogo()}
        </div>
      ) : (
        <>
          <Link
            to={link ? `${rootContext}/${link}` : ''}
            className={classNames(`${rootClass}__logo`, {
              [`${rootClass}__logo--custom`]: hasCustomLogo || customLogos !== null,
            })}
          >
            {renderLogo()}
          </Link>
        </>
      )}
    </>
  )
}

export default React.memo(Logo)
