import React, { FC } from 'react'

import {
  ListPageTableActionCustomProps,
  ListPageCommonState,
  PageHeaderProps,
  SetError,
  SidebarProps,
  Update,
  RenderCustomFiltersParams,
} from '@complex/ListingPage/Context/ListingPageCommon.context'
import { useContentFragmentListingPageRequests } from '@complex/ListingPage/GraphQL/ContentFragmentListingPageRequests.graphQL'
import ListingPageContainer from '@complex/ListingPage/ListingPageContainer'
import { CatalogLink, FORM_COMPOSER_LINK } from '@complex/ListingPage/Utils/ListingPage.constants'
import { ListingPageSession } from '@complex/ListingPage/Utils/ListingPage.utils'
import Button, { ButtonType } from '@components/Button'
import { CollapsibleMenuItemWithHeaderData } from '@components/CollapsibleMenu/CollapsibleMenu'
import { EmptyListingProps } from '@components/EmptyListing/EmptyListing'
import Radio from '@components/Radio'
import RadioGroup from '@components/RadioGroup'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { renderRelativeDate } from '@components/Table/components/tableColumns'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import Typography, { LineHeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { useAccountSettings, useGetFeatureFlag } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import { getStandardFormattedDate, relativeDate } from '@utils/date'
import {
  allFragmentsFilter,
  createdByMeFilter,
  favoriteFilter,
  FilterDefinition,
  FilterTypes,
  fragmentBlocksFilter,
  fragmentSectionsFilter,
} from '@utils/filter'
import { renderItemNameVal, renderPathToFolder } from '@utils/searchUtils'
import { getItem } from '@utils/sessionStorage'

import { getContentFragmentPreviewUrl } from './ContentFragmentListingPage.utils'

interface ContentFragmentListingPageContainerProps {
  className?: string
  dataTest?: string
}

const EMPTY_FRAGMENTS_LINK = `https://connect.act-on.com/hc/en-us/articles/360023938433-Create-Content-Fragments-and-Saved-Sections-in-Forms`

const rootClass = 'content-fragment-listing-page-container'

const ContentFragmentListingPageContainer: FC<ContentFragmentListingPageContainerProps> = (props: ContentFragmentListingPageContainerProps) => {
  const { dataTest = rootClass, className = '' } = props
  const { deleteItemsRequest } = useContentFragmentListingPageRequests()
  const canShareToCatalog = useGetFeatureFlag('canShareAsset')
  const { userAllowedToDeleteContent } = useAccountSettings()

  const { t } = useTranslation()

  const renderCustomFilters = ({
    activeFilter,
    menuActions,
    filterCounts,
    renderCustomFilterWithCount,
    t,
  }: RenderCustomFiltersParams): CollapsibleMenuItemWithHeaderData[] => [
    {
      header: t('Fragment Type'),
      isOpen: getItem(ListingPageSession.CUSTOM_MENU_SECTION_CLOSED) !== 'true',
      icon: SvgNames.toggles,
      dataTest: 'menu-items',
      content: (
        <RadioGroup className={`listing-page__custom-filters`} verticalLayout>
          {renderCustomFilterWithCount(
            <Radio
              label={t(FilterTypes.SECTIONS)}
              checked={activeFilter?.name === fragmentSectionsFilter.name}
              onChange={() => menuActions.clickCustomFilter(fragmentSectionsFilter)}
              onClick={() => menuActions.clickCustomFilter(fragmentSectionsFilter)}
              dataTest={`list-page-container-section-filter`}
              boldOnChecked
              className={'ellip'}
            />,
            filterCounts[FilterTypes.SECTIONS]
          )}
          {renderCustomFilterWithCount(
            <Radio
              label={t(FilterTypes.BLOCKS)}
              checked={activeFilter?.name === fragmentBlocksFilter.name}
              onChange={() => menuActions.clickCustomFilter(fragmentBlocksFilter)}
              onClick={() => menuActions.clickCustomFilter(fragmentBlocksFilter)}
              dataTest={`list-page-container-section-filter`}
              boldOnChecked
              className={'ellip'}
            />,
            filterCounts[FilterTypes.BLOCKS]
          )}
        </RadioGroup>
      ),
    },
  ]

  const getCustomEmptyListingProps = (
    setFilter: (filterDefinition: FilterDefinition, customFilterSelected: boolean) => void,
    _update: Update,
    filter?: FilterDefinition
  ): EmptyListingProps => {
    if (filter === favoriteFilter) {
      return {
        imgSrc: StaticImageNames.emptyFavorites,
        headline: "You don't have any favorites yet",
        text: 'Add hearts to fragments that you want to easily find later.',
        buttonText: 'View all fragments',
        buttonOnClick: () => setFilter(allFragmentsFilter, false),
      }
    } else if (filter === createdByMeFilter) {
      return {
        imgSrc: StaticImageNames.emptyCreatedByMe,
        headline: 'You haven’t created any fragments yet',
        text: 'You’ll find content fragments here once you’ve saved them in the form composer. This filtered view only includes fragments created September 2022 or after.',
        buttonText: 'Go to the form composer',
        buttonOnClick: () => window.open(FORM_COMPOSER_LINK),
      }
    } else if (filter === fragmentSectionsFilter) {
      return {
        imgSrc: StaticImageNames.noProgramHistory,
        headline: "You don't have any sections yet",
        text: "There's nothing to see here! You can save new fragments in the form composer.",
        buttonText: 'View all fragments',
        buttonOnClick: () => setFilter(allFragmentsFilter, false),
      }
    } else if (filter === fragmentBlocksFilter) {
      return {
        imgSrc: StaticImageNames.noProgramHistory,
        headline: "You don't have any blocks yet",
        text: "There's nothing to see here! You can save new fragments in the form composer.",
        buttonText: 'View all fragments',
        buttonOnClick: () => setFilter(allFragmentsFilter, false),
      }
    } else {
      return {
        imgSrc: StaticImageNames.emptyChart,
        headline: 'You haven’t created any fragments yet',
        text: `ListPage.ContentFragments.EmptyState.AllFilter.Text`,
        linkText: 'Learn more about content fragments',
        link: EMPTY_FRAGMENTS_LINK,
        buttonText: 'Check out our template catalog',
        hideIcon: false,
        buttonOnClick: () => window.open(CatalogLink.CONTENT_FRAGMENT, '_blank'),
      }
    }
  }

  const getActiveFilter = (filter: string) => (filter === FilterTypes.SECTIONS ? fragmentSectionsFilter : fragmentBlocksFilter)

  const getCustomFilterParams = (activeFilter?: FilterDefinition) => ({
    field: 'isSection',
    query: `${activeFilter?.name === FilterTypes.SECTIONS}`,
  })

  const getContentFragmentPreview = async (listPageValues: ListPageCommonState, update: Update, _setError: SetError) => {
    const { selectedRows } = listPageValues
    update({ previewUrl: getContentFragmentPreviewUrl(selectedRows[0].externalId ?? '') })
  }

  const renderSearchColumns = (
    searchInAllItems: boolean | undefined,
    currentFolder: FolderData,
    search: string,
    folders: FolderData[]
  ): ColumnDefWithAdditionalProps<ItemDto>[] => [
    {
      header: 'Name',
      accessorKey: 'name',
      textAlign: 'left',
      cell: ({ cell }) => (search ? renderItemNameVal(null, cell.getValue() as string, search, 'list-page-table-container') : null),
    },
    {
      header: 'Tags',
      accessorKey: 'tags',
      textAlign: 'left',
      maxSize: 130,
      disableSorting: true,
      enableCustomCellValue: true,
    },
    {
      header: 'Location',
      accessorKey: 'folderId',
      textAlign: 'left',
      maxSize: 130,
      disableSorting: true,
      cell: (row) =>
        renderPathToFolder(ItemType.FRAGMENT, row.getValue<number>(), folders, searchInAllItems, 'list-page-table-container', t, currentFolder),
    },
    {
      header: 'Created',
      accessorKey: 'createdAt',
      textAlign: 'left',
      maxSize: 150,
      cell: ({ cell }) => renderRelativeDate(relativeDate(cell.getValue<number>(), true, false), getStandardFormattedDate(cell.getValue() as number)),
    },
  ]

  const headerActionCustomProps: ListPageTableActionCustomProps[] = [
    { name: 'ADD_TO_FAVORITES', position: 0, isInDropdown: false },
    { name: 'MANAGE_TAGS', position: 1 },
    { name: 'MOVE_TO_FOLDER', position: 2 },
    { name: 'REMOVE_FROM_FOLDER', position: 3, isInDropdown: false },
    { name: 'SHARE_TO_CATALOG', position: 4 },
    { name: 'DELETE', position: 5, isInDropdown: true },
  ]

  const rowActionCustomProps: ListPageTableActionCustomProps[] = [
    { name: 'PREVIEW', position: 0 },
    { name: 'ADD_TO_FAVORITES', position: 1 },
    { name: 'SHARE_TO_ACCOUNTS', position: 2, isInDropdown: true },
    { name: 'SHARE_TO_CATALOG', position: 3, isInDropdown: true },
    { name: 'MOVE_TO_FOLDER', position: 4, hasTopSection: canShareToCatalog, isInDropdown: true },
    { name: 'REMOVE_FROM_FOLDER', position: 5, isInDropdown: true },
    { name: 'DELETE', position: 6, isInDropdown: true },
  ]

  const clickableColumnOptions = {
    colIndex: 0,
    action: {
      showPreview: true,
    },
  }

  const sidebarProps: SidebarProps = {
    sidebarHeader: 'Fragment Manager',
    hasRecent: false,
    hasCreatedByMe: true,
    allItemFilter: allFragmentsFilter,
    renderCustomFilters,
    customFilterSelected: false,
  }

  const pageHeaderProps: PageHeaderProps = {
    pageTitle: 'Content Fragments',
    renderPageHeaderContent: () => (
      <Button
        className={`listing-page-header__catalog-button`}
        buttonType={ButtonType.FLOAT}
        isLink
        to={CatalogLink.CONTENT_FRAGMENT}
        target={'_blank'}
      >
        {
          <>
            <Svg type={SvgType.ICON} name={SvgNames.shareToCatalog} />
            <Typography lineHeight={LineHeight.SMALL} text={t('Content catalog')} className={'listing-page-header__catalog-text'} />
          </>
        }
      </Button>
    ),
  }

  const tableProps = {
    columns: [
      {
        header: 'Name',
        accessorKey: 'name',
        textAlign: 'left',
      },
      {
        header: 'Tags',
        accessorKey: 'tags',
        textAlign: 'left',
        maxSize: 180,
        disableSorting: true,
        enableCustomCellValue: true,
      },
      {
        header: 'Created',
        accessorKey: 'createdAt',
        textAlign: 'left',
        maxSize: 170,
        cell: ({ cell }) =>
          renderRelativeDate(relativeDate(cell.getValue() as number, true, false), getStandardFormattedDate(cell.getValue() as number)),
      },
    ] as ColumnDefWithAdditionalProps<ItemDto>[],
    hasAutoSelectedRows: false,
    hasExpander: false,
    rowActionCustomProps,
    renderSearchColumns,
    headerActionCustomProps,
    listPage: 'ContentFragments',
    clickableColumnOptions,
    actonAssetType: 'CONTENT_FRAGMENT',
    shareModalText: 'Content Fragment',
  }

  return (
    <ListingPageContainer
      listingPageProps={{
        canPreview: true,
        canDeleteItems: userAllowedToDeleteContent,
        customPreviewItemCall: getContentFragmentPreview,
        customDeleteItemsCall: deleteItemsRequest,
        getCustomActiveFilter: getActiveFilter,
        getCustomEmptyListingProps,
        getCustomFilterParams,
        sidebarProps,
        pageHeaderProps,
        tableProps,
        sortBy: [{ id: 'createdAt', desc: true }],
        filterInfoHoverText: { [FilterTypes.CREATED_BY_ME]: 'Only includes Content Fragments created September 2022 or after' },
        customFilters: [
          {
            field: 'isSection',
            values: [
              { id: FilterTypes.SECTIONS, value: 'true' },
              { id: FilterTypes.BLOCKS, value: 'false' },
            ],
          },
        ],
      }}
      dataTest={dataTest}
      className={className}
      itemType={ItemType.FRAGMENT}
    />
  )
}

export default ContentFragmentListingPageContainer
