import React, { FC } from 'react'

import UsersCountInfo from '@src/pages/Settings/Users/components/UsersCountInfo/UsersCountInfo'
import { NEED_MORE_LINK } from '@src/pages/Settings/Users/constants/users.constants'
import { useSalesUsers } from '@src/pages/Settings/Users/context/SalesUsersContext'

const rootClass = 'sales-users__count-info'

const SalesUsersCountInfo: FC = () => {
  const { crmUsersCount, portalUsersCount, maxAllowedCountForPortal, maxAllowedCountForCrm } = useSalesUsers()
  return (
    <div className={rootClass}>
      <UsersCountInfo
        labelKey={'Portal.Users.Count.Label'}
        tooltipKey={'Portal.Users.Count.Tooltip'}
        needMoreLink={NEED_MORE_LINK}
        currentCount={maxAllowedCountForPortal - portalUsersCount}
        maxCount={maxAllowedCountForPortal}
        dataTest={`${rootClass}-portal`}
      />
      <UsersCountInfo
        labelKey={'CRM.Users.Count.Label'}
        tooltipKey={'CRM.Users.Count.Tooltip'}
        needMoreLink={NEED_MORE_LINK}
        currentCount={maxAllowedCountForCrm - crmUsersCount}
        maxCount={maxAllowedCountForCrm}
        dataTest={`${rootClass}-crm`}
      />
    </div>
  )
}

export default SalesUsersCountInfo
