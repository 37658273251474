import React, { FC, useEffect, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { Status } from '@components/StatusToast/StatusToast'
import assignAccounts from '@graphql/mutations/assignAccounts'
import getManagersAndChildAccounts from '@graphql/queries/getManagersAndChildAccounts'
import { AssignAccountsMutation, AssignAccountsMutationVariables } from '@graphql/types/mutation-types'
import { GetManagersAndChildAccountsQuery, GetManagersAndChildAccountsQueryVariables } from '@graphql/types/query-types'
import Managers, { MarketingNetworkAccount, MarketingNetworkManager, StatusResult } from '@src/pages/MarketingNetwork/Managers/Managers'
import { logNewRelicError } from '@utils/new-relic.utils'

interface ManagersContainerState {
  managers: MarketingNetworkManager[]
  visibleManagers: MarketingNetworkManager[]
  accounts: MarketingNetworkAccount[]
  search: string
  filter?: SelectV2SingleOption
  assignee?: MarketingNetworkManager
  statusResult?: StatusResult
  loading: boolean
}

const defaultState: ManagersContainerState = {
  managers: [],
  visibleManagers: [],
  accounts: [],
  search: '',
  loading: true,
}

const ManagersContainer: FC = () => {
  const [state, setState] = useState<ManagersContainerState>(defaultState)

  const client = useApolloClient()

  const getVisible = (managers: MarketingNetworkManager[], search?: string, filter?: string) => {
    let visible = managers

    if (filter && filter !== '-1') {
      visible = managers.filter((manager) => manager.accounts.includes(filter ?? '-1'))
    }

    if (search) {
      visible = visible.filter((manager) => manager.name.toLowerCase().includes(search.toLowerCase()))
    }

    return visible
  }

  const getManagers = async (statusResult?: StatusResult) => {
    const { data, errors } = await client.query<GetManagersAndChildAccountsQuery, GetManagersAndChildAccountsQueryVariables>({
      query: getManagersAndChildAccounts,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    })

    if (data.getManagersAndChildAccounts) {
      const accounts = data.getManagersAndChildAccounts.childAccountList.map((acc) => ({
        id: acc.accountId,
        name: acc.name ?? '',
      }))

      const managers = data.getManagersAndChildAccounts.managerList.map((mgr) => {
        const name = mgr.firstName || mgr.lastName ? `${mgr.firstName} ${mgr.lastName}`.trim() : mgr.email
        return {
          ...mgr,
          name,
          accounts: mgr.assignedAccounts.length === 0 ? [] : mgr.assignedAccounts.split(','),
        }
      })

      const visibleManagers = getVisible(managers, state.search, state.filter?.value)

      setState(() => ({
        ...state,
        statusResult,
        managers,
        visibleManagers,
        accounts,
        loading: false,
        assignee: undefined,
      }))
    }

    if (errors) {
      logNewRelicError(errors)
      setState(() => ({ ...state, loading: false }))
    }
  }

  useEffect(() => {
    getManagers()
  }, [])

  const onFilter = (filter?: SelectV2SingleOption) => {
    const visibleManagers = getVisible(state.managers, state.search, filter?.value)
    setState({ ...state, filter, visibleManagers })
  }

  const onSearch = (search: string) => {
    const visibleManagers = getVisible(state.managers, search, state.filter?.value)
    setState({ ...state, search, visibleManagers })
  }

  const onAssign = async (accounts: MarketingNetworkAccount[]) => {
    if (state.assignee) {
      const managerId = state.assignee?.id
      const { data, errors } = await client.mutate<AssignAccountsMutation, AssignAccountsMutationVariables>({
        mutation: assignAccounts,
        variables: {
          managerId,
          assignAccountIdList: accounts.length === 0 ? [] : accounts.map((account) => account.id),
        },
      })

      if (data) {
        const statusResult =
          data.assignAccounts?.errorAssigningIdList.length === 0
            ? {
                type: Status.SUCCESS,
                message: `Accounts assigned to ${state.assignee?.name ?? ''}`,
              }
            : {
                type: Status.FAIL,
                message: `The accounts could not be assigned to ${state.assignee?.name ?? ''}`,
              }
        await getManagers(statusResult)
      }

      if (errors) {
        logNewRelicError(errors)
        setState(() => ({
          ...state,
          assignee: undefined,
          statusResult: { message: 'There was an error saving the updated account assignments.', type: Status.FAIL },
        }))
      }
    }
  }

  const onToggleAssignModal = (assignee?: MarketingNetworkManager) => {
    setState({ ...state, assignee })
  }

  const onCloseStatus = () => setState({ ...state, statusResult: undefined })

  return (
    <Managers
      {...state}
      managers={state.visibleManagers}
      onFilter={onFilter}
      onSearch={onSearch}
      onAssign={onAssign}
      statusResult={state.statusResult}
      onCloseStatus={onCloseStatus}
      onToggleAssignModal={onToggleAssignModal}
    />
  )
}

export default ManagersContainer
