import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'

import classNames from 'classnames'
import { GraphQLError } from 'graphql'

import { ApolloQueryResult, FetchResult, MutationFunctionOptions } from '@apollo/client'
import Button, { ButtonType } from '@components/Button'
import FormRow from '@components/FormRow'
import Input from '@components/Input/Input'
import Label from '@components/Label'
import LabelWithSvgTooltip from '@components/LabelWithTooltipIcon/LabelWithSvgTooltip'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import UploadImage from '@components/UploadImage/UploadImage'
import { useTranslation } from '@const/globals'
import {
  AddLogoMutation,
  DuplicateLogoMutation,
  Exact,
  LogoAttributesInput,
  Maybe,
  UpdateLogoAttributesMutation,
} from '@graphql/types/mutation-types'
import { GetAllLogosQuery, LogoItem } from '@graphql/types/query-types'
import { StatusToastType } from '@interface/StatusToast'
import LogoModalsHeader from '@src/pages/Content/Branding/tabs/Logos/components/LogoModalsHeader'
import { logNewRelicError } from '@utils/new-relic.utils'

import './addEditLogoModal.css'

interface Props {
  logo?: LogoItem | undefined
  showModal: boolean
  onCancel: (isSuccess: boolean, touched: boolean) => void
  addLogo: (
    options?: MutationFunctionOptions<AddLogoMutation, Exact<{ data?: Maybe<LogoAttributesInput> }>> | undefined
  ) => Promise<FetchResult<AddLogoMutation>>
  duplicateLogo: (
    options?: MutationFunctionOptions<DuplicateLogoMutation, Exact<{ data?: Maybe<LogoAttributesInput> }>> | undefined
  ) => Promise<FetchResult<DuplicateLogoMutation>>
  editLogo: (
    options?: MutationFunctionOptions<UpdateLogoAttributesMutation, Exact<{ id?: Maybe<string>; data?: Maybe<LogoAttributesInput> }>> | undefined
  ) => Promise<FetchResult<AddLogoMutation>>
  refetch: () => Promise<ApolloQueryResult<GetAllLogosQuery>>
  setToastStatus: (value: StatusToastType) => void
  isDuplicate: boolean
  isPreview: boolean
  isLoading: boolean
  setLogoToPreview: (logo: LogoItem | undefined) => void
  setOpenAddEditLogoModal: (show: boolean) => void
  dataTest?: string
}

const initialState = {
  title: '',
  alt: '',
  target: '',
  tooltip: '',
  url: undefined,
  imageBase64: undefined,
  duplicated: false,
  imageType: '',
}

const rootClass = 'add-logo-modal'

const AddEditLogoModal: FC<Props> = (props: Props) => {
  const {
    logo,
    showModal = false,
    onCancel,
    addLogo,
    duplicateLogo,
    editLogo,
    refetch,
    setToastStatus,
    isDuplicate,
    isPreview,
    isLoading,
    setLogoToPreview,
    setOpenAddEditLogoModal,
    dataTest = rootClass,
  } = props
  const [touched, setTouched] = useState<boolean>(false)
  const [state, setState] = useState<LogoAttributesInput>(initialState)
  const { t } = useTranslation()

  useEffect(() => {
    if (logo) {
      setState({
        title: isDuplicate ? `${logo.title} (copy)` : logo.title ?? 'title',
        alt: logo.alt,
        target: logo.target,
        tooltip: logo.tooltip,
        url: logo.url,
        duplicated: isDuplicate || logo.duplicated,
        imageBase64: undefined,
      })
    } else {
      setState(initialState)
    }
  }, [logo, setState, isDuplicate, initialState])

  const handleCancel = useCallback(
    (isSuccess: boolean) => {
      onCancel(isSuccess, touched)
      !isDuplicate && setTouched(false)
      !isDuplicate && setState(initialState)
    },
    [onCancel, setTouched, setState, isDuplicate, initialState, touched]
  )

  const onInputChange = (e: ChangeEvent<HTMLInputElement>, input: keyof LogoAttributesInput) => {
    setTouched(true)
    setState({ ...state, [input]: e.target.value })
  }

  const handleImageChange = useCallback(
    ({ imageBase64 = '', imageType = '', url = '' } = {}) => {
      setTouched(true)
      setState((prevState) => ({ ...prevState, imageBase64, imageType, url }))
    },
    [setState, setTouched]
  )

  const handleError = (errors: readonly GraphQLError[] | undefined) => {
    setToastStatus({
      showStatus: true,
      title: t('An error occurred:'),
      statusMessage: t('We couldn’t save your changes. Please try again.'),
      successStatus: false,
    })
    errors?.map((error) => logNewRelicError(error.message))
  }

  const handleSuccess = (statusMessage: string) => {
    handleCancel(true)
    isPreview && setLogoToPreview(undefined)
    setOpenAddEditLogoModal(false)
    setToastStatus({
      showStatus: true,
      title: t('Success!'),
      statusMessage: t(statusMessage),
      successStatus: true,
    })
    refetch()
  }

  const handleEditLogo = (logo: LogoItem) => {
    editLogo({
      variables: {
        id: logo.id,
        data: state,
      },
    }).then((res) => {
      if (res.data) {
        handleSuccess('Your changes have been saved.')
      } else {
        handleError(res.errors)
      }
    })
  }

  const handleAddLogo = () => {
    const variables = { data: state }
    addLogo({ variables }).then((res) => {
      if (res.data?.addLogo?.status === 'ok') {
        handleSuccess('We’ve added your logo.')
      } else {
        handleError(res.errors)
      }
    })
  }

  const handleDuplicateLogo = (logo: LogoItem) => {
    let variables = { data: state }
    if (logo.isLocal && state.url === logo?.url) {
      variables = { data: { ...state, sourceId: logo?.id, url: undefined, imageBase64: undefined } }
    }

    duplicateLogo({ variables }).then((res) => {
      if (res.data?.duplicateLogo?.status === 'ok') {
        handleSuccess('We’ve added your logo.')
      } else {
        handleError(res.errors)
      }
    })
  }

  const handleSave = () => {
    if (logo) {
      isDuplicate ? handleDuplicateLogo(logo) : handleEditLogo(logo)
    } else {
      handleAddLogo()
    }
  }

  return (
    <Modal
      isOpen={showModal}
      className={classNames(rootClass)}
      dataTest={dataTest}
      header={
        <ModalHeader headerType={ModalHeaderType.Form}>
          <LogoModalsHeader logo={logo} isDuplicate={isDuplicate} />
        </ModalHeader>
      }
    >
      <ModalBody>
        <div className={`${rootClass}__body`}>
          <div className={`${rootClass}__body-inputs`}>
            <FormRow>
              <Label required>{t('Name')}</Label>
              <Input
                dataTest={`${dataTest}-input-name`}
                value={state.title}
                onChange={(e) => {
                  onInputChange(e, 'title')
                }}
              />
            </FormRow>
            <FormRow>
              <LabelWithSvgTooltip label={t('Alt text')} tooltipContent={t('This text will replace your logo if the image fails to load.')} />
              <Input dataTest={`${dataTest}-input-altText`} value={state.alt} onChange={(e) => onInputChange(e, 'alt')} />
            </FormRow>
            <FormRow>
              <LabelWithSvgTooltip
                label={t('Target URL')}
                tooltipContent={t('Act-On will redirect users to this URL when they click on your logo.')}
              />
              <Input dataTest={`${dataTest}-input-targetUrl`} value={state.target} onChange={(e) => onInputChange(e, 'target')} />
            </FormRow>
            <FormRow>
              <LabelWithSvgTooltip label={t('Hover text')} tooltipContent={t('This text appears when users hover over your logo.')} />
              <Input dataTest={`${dataTest}-input-hoverText`} value={state.tooltip} onChange={(e) => onInputChange(e, 'tooltip')} />
            </FormRow>
          </div>
          <div className={`${rootClass}__body-upload`}>
            <UploadImage onImageChange={handleImageChange} image={state.url} />
          </div>
        </div>
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form}>
        <Button buttonType={ButtonType.TERTIARY} onClick={() => handleCancel(false)} disabled={isLoading} dataTest={`${dataTest}-cancel-button`}>
          {t('Cancel')}
        </Button>
        <Button
          buttonType={ButtonType.PRIMARY}
          onClick={handleSave}
          disabled={(!isDuplicate && !touched) || !state.title || (!state.url && !state.imageBase64) || isLoading}
          dataTest={`${dataTest}-save-button`}
        >
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default AddEditLogoModal
