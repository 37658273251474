import React, { FC, useEffect } from 'react'

import Cookies from 'js-cookie'

import Toggle from '@components/Toggle'
import { isMocks, useTranslation } from '@const/globals'
import { useGetFeatureFlag } from '@utils/account/account.utils'
import windowUtils from '@utils/window'

import currentToggleConfig from './currentToggleConfig'
import newFeatureToggleUtils from './newFeatureToggleUtils'

import './newFeatureToggle.css'

const rootClass = 'new-feature-toggle'

export interface State {
  isOn: boolean
  loading: boolean
}

const NewFeatureToggle: FC = () => {
  const currentToggle = currentToggleConfig.getCurrentToggle()

  const { t } = useTranslation()
  const actonUI = useGetFeatureFlag('actonUI')

  const isOnInitial = (isMocks() || Cookies.get(currentToggle.feature) === '1') && actonUI
  const [state, setState] = React.useState<State>({
    isOn: isOnInitial,
    loading: false,
  })

  const { loading, isOn } = state

  useEffect(() => {
    newFeatureToggleUtils.toggleFeature(currentToggle, state, setState)
  }, [loading])

  if (currentToggle.disabled) return null

  if (!loading && !isOn) {
    windowUtils.redirectToClassic()
  }

  const onToggle = (isOn: boolean) => {
    setState({
      ...state,
      isOn,
      loading: true,
    })
  }

  return (
    <div className={rootClass}>
      <span className={`${rootClass}__title`}>{t(currentToggle.title)}</span>
      <Toggle
        dataTest="new-feature-toggle"
        isOn={isOn}
        onToggle={(isOnUpdate: boolean) => {
          onToggle(isOnUpdate)
        }}
        disabled={state.loading}
        title={`${t((isOn ? 'Disable ' : 'Enable ') + currentToggle.tooltip)}`}
      />
    </div>
  )
}

export default NewFeatureToggle
