import React, { FC, useContext, useMemo } from 'react'

import classNames from 'classnames'

import { ButtonType } from '@components/Button'
import { ButtonSize } from '@components/Button/Button'
import PageHeader from '@components/PageHeader/PageHeader'
import { SvgNames } from '@components/Svg'
import TriggerButton, { TriggerButtonOption } from '@components/TriggerButton/TriggerButton'
import { useTranslation } from '@const/globals'
import { FormManagerContext } from '@src/pages/Content/Forms/FormManager/context/FormManager.context'
import { useAccountSettings } from '@utils/account/account.utils'
import { openFormBuilderWindow } from '@utils/forms'

import './FormManagerHeader.css'

interface FormManagerHeaderProps {
  className?: string
  dataTest?: string
}

const rootClass = 'form-manager-header'
const DEFAULT_URL = 'content/forms'

const FormManagerHeader: FC<FormManagerHeaderProps> = (props: FormManagerHeaderProps) => {
  const { dataTest = rootClass, className = '' } = props
  const {
    toggleModal,
    downloadSubmittedData,
    onOpenBuilderWindow,
    values: { title, formId, canEdit, isClassic },
  } = useContext(FormManagerContext)
  const { t } = useTranslation()
  const { hasReactWrapper, newFormFileUploadBlock, userAllowedToDownload } = useAccountSettings()

  const backURL = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search)
    return searchParams.get('backTo') || DEFAULT_URL
  }, [])

  const getActionsButtonOptions = (): TriggerButtonOption[] => {
    return [
      {
        text: t('Edit form'),
        icon: SvgNames.pencil,
        disabled: !canEdit,
        disabledTooltip: 'Ask your administrator for permission to do this',
        onClick: () => {
          const builderWindow = openFormBuilderWindow(isClassic ? 'editClassicForm' : 'editNewForm', formId, '', hasReactWrapper, isClassic)
          builderWindow && onOpenBuilderWindow(builderWindow)
        },
      },
      {
        text: t(newFormFileUploadBlock ? 'Download' : 'Download submitted data'),
        icon: SvgNames.download,
        disabled: !userAllowedToDownload,
        disabledTooltip: 'Ask your administrator for permission to do this',
        onClick: () => (newFormFileUploadBlock ? toggleModal('downloadOptions') : downloadSubmittedData()),
      },
      {
        text: t('Clear performance report'),
        icon: SvgNames.optOut,
        onClick: () => toggleModal('clearPerformanceReport'),
      },
    ]
  }

  return (
    <PageHeader primaryText={title} linkUrl={backURL} leftContent background className={classNames(rootClass, className)} data-test={dataTest}>
      <TriggerButton
        label={t('Actions')}
        options={getActionsButtonOptions()}
        buttonType={ButtonType.PRIMARY}
        buttonSize={ButtonSize.LARGE}
        alignRight
        dataTest={`${dataTest}-actions`}
      />
    </PageHeader>
  )
}

export default FormManagerHeader
