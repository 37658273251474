import React, { FC, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import Select, { GroupBase, InputActionMeta, MultiValue, Props, SingleValue } from 'react-select'

import classNames from 'classnames'
import AsyncSelect, { AsyncProps } from 'react-select/async'
import AsyncCreatableSelect, { AsyncCreatableProps } from 'react-select/async-creatable'
import CreatableSelect, { CreatableProps } from 'react-select/creatable'
import { useDebouncedCallback } from 'use-debounce'

import Label from '@components/Label'
import SelectV2MenuFooter from '@components/SelectV2/components/SelectV2MenuFooter/SelectV2MenuFooter'
import { SelectV2Context, SelectV2Values, SelectV2ValuesAPI } from '@components/SelectV2/SelectV2.context'
import { SelectV2Option, SelectV2Props } from '@components/SelectV2/SelectV2.props'
import { getMenuListCustomStyle, inputCustomStyles, reactSelectTheme } from '@components/SelectV2/SelectV2.styles'
import {
  findGroupContainingOption,
  getCustomClassNames,
  getCustomComponents,
  isGroupedOptionArrayType,
  isMultiValueType,
  isSingleValueArrayType,
  isSingleValueType,
  selectRootClass,
  truncateValues,
  validateDefaultValue,
} from '@components/SelectV2/SelectV2.utils'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import TextLink from '@components/TextLink/TextLink'
import Toggletip, { ToggletipSide } from '@components/Toggletip/Toggletip'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { getUUID, useTranslation } from '@const/globals'

import './SelectV2.css'

const defaultMaxMenuHeight = 600
const DEFAULT_MIN_SEARCH_OPTIONS = 5
const BASE_INPUT_ID = 'select-v2-input'

const SelectV2: FC<SelectV2Props> = (props: SelectV2Props) => {
  const {
    dataTest = selectRootClass,
    className = '',
    createOnClickOutside = false,
    enableCreateOptionWithoutTyping = false,
    error = false,
    errorMessage,
    errorLink,
    footer,
    groupedOptions,
    hasSearchOnClick = false,
    hideCheckMark = false,
    hideDropdownIndicator = false,
    infoLabels,
    insideModal = false,
    isAsync,
    isClearable = true,
    isPaginator = false,
    isSearchable = true,
    maxSearchInputLength,
    itemType,
    maxMenuHeight = defaultMaxMenuHeight,
    minMenuHeight,
    menuPortalTarget = document.body,
    loadNewOptions,
    onChange,
    onChangeMultiple,
    onCreate: onCreateOption,
    onInputChange,
    containerTooltipMessage,
    options,
    placeholder = 'Select or search options',
    searchBoxPlaceholder = 'Search options',
    searchOptions,
    showIconOnSelect = false,
    truncateInputValueInNewOption = false,
    truncateOptions = false,
    label,
    isRequired = false,
    tooltipMessage,
    helperMessage,
    inputIcon,
    hideSelectedOptions = false,
    isDisabled,
    renderCustomIndicator,
    renderSelectContainerWrapper,
    renderSingleValue,
    renderCustomOption,
    renderMenuAction,
    nestedSearch = false,
    menuShouldScrollIntoView = false,
    menuIsOpen,
    minSearchOptions = DEFAULT_MIN_SEARCH_OPTIONS,
    closeMenuOnScroll,
    menuPlacement = 'auto',
    useToggleTip = false,
    showGroupsWithoutLabel = false,
    hideNumberBadge = false,
    hideContainerTooltip = false,
    truncateMultiValues = false,
    optionsWithLargePadding = false,
    menuListCustomStyle,
    ...rest
  } = props

  const ref = useRef<HTMLDivElement>(null)

  const isMulti = !!onChangeMultiple
  const truncateMulti = truncateMultiValues && isMulti && isSearchable

  const defaultValue = useMemo(() => validateDefaultValue(props), [props])

  const inputId = useMemo(() => `${BASE_INPUT_ID}-${getUUID()}`, [])

  const singleDefault = !!defaultValue && !isMulti && isSingleValueType(defaultValue)
  const multiDefault = !!defaultValue && isMulti && isMultiValueType(defaultValue) && isSingleValueArrayType(defaultValue)

  const [state, setState] = useState<SelectV2ValuesAPI>({
    ...SelectV2Values,
    currentOptions: groupedOptions ?? options ?? [],
    enableCreateOptionWithoutTyping,
    error,
    renderCustomIndicator,
    renderSelectContainerWrapper,
    renderSingleValue,
    hasSearchOnClick,
    searchBoxPlaceholder,
    hideCheckMark: hideCheckMark || isPaginator,
    hideDropdownIndicator,
    infoLabels,
    containerTooltipMessage,
    isPaginator,
    itemType,
    inputIcon,
    isMulti,
    optionsWithLargePadding,
    showIconOnSelect,
    selectedOption: singleDefault || multiDefault ? defaultValue : undefined,
    nestedSearch,
    renderCustomOption,
    isCreatable: !!onCreateOption,
    hideNumberBadge,
    showGroupsWithoutLabel,
    hideContainerTooltip,
  })

  const { currentOptions, currentSearchOptions, currentPage, inputChanged, inputValue, selectedOption, hasSearchOpen } = state
  const lastElement = useRef<Element>()
  const menuActionRef = useRef<HTMLDivElement>(null)
  const dropdownIndicatorRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()

  const closeSearchMenuList = useCallback(() => {
    update({
      inputValue: '',
      inputChanged: false,
      hasSearchOpen: false,
    })
  }, [])

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      // Check if the event target is outside the menu action and dropdown indicator elements
      if (event.target && !menuActionRef.current?.contains(event.target as Node) && !dropdownIndicatorRef.current?.contains(event.target as Node)) {
        // If the "createOnClickOutside" flag is true, the create option is enabled, the search menu is not open, and there is an input value, create the option
        if (createOnClickOutside && onCreateOption && !hasSearchOpen && !!inputValue) {
          onCreateOption(inputValue)
        }
        closeSearchMenuList()
      }
    },
    [closeSearchMenuList, createOnClickOutside, hasSearchOpen, inputValue, onCreateOption]
  )

  const handleEscape = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeSearchMenuList()
      }
    },
    [closeSearchMenuList]
  )

  useLayoutEffect(() => {
    if (truncateMulti) {
      truncateValues(ref, update)
    }
  }, [selectedOption, defaultValue, rest.value])

  useEffect(() => {
    if (!hasSearchOpen && hasSearchOnClick) {
      rest.onMenuClose?.()
      document.getElementById(inputId)?.focus()
    }
  }, [hasSearchOpen])

  useEffect(() => {
    if (hasSearchOnClick) {
      document.addEventListener('keyup', handleEscape)

      return () => {
        document.removeEventListener('keyup', handleEscape)
      }
    }
  }, [handleEscape, hasSearchOnClick])

  useEffect(() => {
    if (hasSearchOnClick && (hasSearchOpen || !!inputValue)) {
      document.addEventListener('pointerdown', handleClickOutside)

      return () => {
        document.removeEventListener('pointerdown', handleClickOutside)
      }
    }
  }, [handleClickOutside, hasSearchOnClick, inputValue, hasSearchOpen])

  useEffect(() => {
    if (!!lastElement.current) {
      lastElement.current.scrollIntoView(false)
    }
  }, [currentPage])

  useEffect(() => {
    setState((state) => ({ ...state, currentOptions: groupedOptions ?? options ?? [] }))
  }, [options, groupedOptions])

  useEffect(() => {
    setState((state) => ({ ...state, inputIcon }))
  }, [inputIcon])

  const update = (fieldsToUpdate: Partial<SelectV2ValuesAPI>) =>
    setState((state) => ({
      ...state,
      ...fieldsToUpdate,
    }))

  const filterOptions = (
    resolve: (value: SelectV2Option[] | PromiseLike<SelectV2Option[]>) => void,
    options: SelectV2Option[],
    inputValue: string
  ) => {
    const normalizedInputValue = inputValue?.trim().toLowerCase()
    if (!!groupedOptions) {
      const filteredOptions = groupedOptions
        .filter(({ options }) => !!options.filter(({ label }) => label?.trim().toLowerCase()?.includes(normalizedInputValue)).length)
        .map((groupedOption) => ({
          ...groupedOption,
          options: groupedOption.options.filter(({ label }) => label?.trim().toLowerCase()?.includes(normalizedInputValue)),
        }))
      setState(({ currentSearchOptions, ...rest }) => ({
        ...rest,
        currentSearchOptions: filteredOptions,
      }))
      resolve(filteredOptions)
    } else {
      const filteredOptions = options.filter(({ label }) => label?.trim().toLowerCase()?.includes(normalizedInputValue))
      setState(({ currentSearchOptions, ...rest }) => ({
        ...rest,
        currentSearchOptions: filteredOptions,
      }))
      resolve(filteredOptions)
    }
  }
  const searchFilteredOptions = (resolve: (value: SelectV2Option[] | PromiseLike<SelectV2Option[]>) => void, inputValue: string) => {
    if (searchOptions) {
      searchOptions(inputValue).then((searchOptions) => {
        filterOptions(resolve, searchOptions, inputValue)
      })
    } else {
      filterOptions(resolve, currentOptions, inputValue)
    }
  }

  const loadOptions = useDebouncedCallback((inputValue: string, resolve) => searchFilteredOptions(resolve, inputValue), 500)

  const getLastElement = () => {
    if (!currentOptions.length) {
      return
    }

    if (isGroupedOptionArrayType(currentOptions)) {
      const { options } = currentOptions[currentOptions.length - 1]
      const lastOption = options[options.length - 1]
      return document.getElementsByClassName(`option-${lastOption?.value}`)[0]
    } else if (isSingleValueArrayType(currentOptions)) {
      const lastOption = currentOptions[currentOptions.length - 1]
      return document.getElementsByClassName(`option-${lastOption?.value}`)[0]
    }
  }

  const onMenuScrollToBottom = () => {
    if (!!currentOptions.length) {
      lastElement.current = getLastElement()
      loadNewOptions?.(currentPage + 1).then((newOptions) => {
        if (!!newOptions.length) {
          setState(({ currentOptions, currentPage, ...rest }) => ({
            ...rest,
            currentOptions: [...currentOptions, ...newOptions],
            currentPage: currentPage + 1,
          }))
        }
      })
    }
  }

  const optionCount = useMemo(() => {
    if (isGroupedOptionArrayType(currentOptions)) {
      return currentOptions.flatMap((group) => group.options).length
    }
    return currentOptions.length
  }, [currentOptions])

  const baseProps: Partial<Props<SelectV2Option>> = {
    ...rest,
    inputId,
    className: classNames(selectRootClass, className),
    classNamePrefix: selectRootClass,
    classNames: getCustomClassNames({
      error,
      hasSearchOnClick,
      hideDropdownIndicator,
      insideModal,
      isClearable: isClearable && !isPaginator,
      isPaginator,
      isSearchable,
      truncateInputValueInNewOption,
      truncateOptions,
      showGroupsWithoutLabel,
      hasFooter: !!footer,
    }),
    menuIsOpen: hasSearchOnClick ? hasSearchOpen : menuIsOpen,
    closeMenuOnSelect: !isMulti,
    components: getCustomComponents({ hideDropdownIndicator, isPaginator, truncateMultiValues: truncateMulti }),
    isClearable: isClearable && !isPaginator && !inputChanged,
    inputValue,
    isDisabled,
    isMulti,
    isOptionDisabled: (option: SelectV2Option) => ('isDisabled' in option ? !!option.isDisabled : false),
    isSearchable: isSearchable && !isPaginator && (!!onCreateOption ? true : optionCount >= minSearchOptions) && !hasSearchOpen,
    backspaceRemovesValue: !hasSearchOpen,
    maxMenuHeight: !!options?.length || !!groupedOptions?.length ? maxMenuHeight : defaultMaxMenuHeight,
    minMenuHeight,
    menuActionRef,
    dropdownIndicatorRef,
    menuPlacement,
    menuShouldScrollIntoView,
    menuPortalTarget,
    hideSelectedOptions,
    defaultValue,
    onChange: (newValue: SingleValue<SelectV2Option> | MultiValue<SelectV2Option>) => {
      if (!isMulti && isSingleValueType(newValue)) {
        onChange?.(newValue ?? undefined)
        update({
          inputChanged: false,
          selectedOption: newValue ?? undefined,
          inputValue: undefined,
          inputGroup: findGroupContainingOption(currentOptions, newValue?.value),
        })
      } else if (isMulti && isMultiValueType(newValue) && isSingleValueArrayType(newValue)) {
        onChangeMultiple?.(newValue)
        update({
          inputChanged: false,
          selectedOption: newValue ?? undefined,
          inputValue: hasSearchOnClick ? inputValue : undefined,
          inputGroup: undefined,
        })
      }
    },
    onFocus: (e) => {
      rest.onFocus?.(e)
      update({ tabIndex: e.target.tabIndex })
    },
    onInputChange: (value: string, actionMeta: InputActionMeta) => {
      if (maxSearchInputLength && value.length > maxSearchInputLength) {
        return
      }
      actionMeta.action === 'input-change' && update({ inputChanged: !!value, inputValue: value, searchLoading: isAsync })
      actionMeta.action !== 'input-change' && update({ inputChanged: false, inputValue: undefined, searchLoading: false })
      onInputChange?.(value)
    },
    onMenuClose: () => {
      rest.onMenuClose?.()
      update({ inputChanged: false })
    },
    openMenuOnClick: !hasSearchOnClick,
    placeholder: isPaginator ? '' : t(placeholder),
    renderMenuAction: footer ? () => <SelectV2MenuFooter {...footer} /> : renderMenuAction,
    styles: { ...inputCustomStyles, ...(menuListCustomStyle ? getMenuListCustomStyle(menuListCustomStyle) : {}) },
    theme: reactSelectTheme,
    closeMenuOnScroll,
  }

  const asyncSelectProps: Partial<AsyncProps<SelectV2Option, boolean, GroupBase<SelectV2Option>>> = {
    ...baseProps,
    // Having cacheOptions when multi and searchOnClick causes issues when keeping the options open
    cacheOptions: isAsync && !isMulti && !hasSearchOnClick,
    defaultOptions: hasSearchOnClick && currentSearchOptions.length > 0 ? currentSearchOptions : currentOptions,
    loadOptions,
    onMenuScrollToBottom,
  }

  const baseCreatableSelectProps = {
    ...(enableCreateOptionWithoutTyping !== undefined
      ? { isValidNewOption: (inputValue: string) => enableCreateOptionWithoutTyping || !!inputValue.length }
      : {}),
    onCreateOption: (inputValue: string) => {
      if (!!inputValue || enableCreateOptionWithoutTyping) {
        update({ inputChanged: true, inputValue: isMulti ? '' : inputValue, hasSearchOpen: false })
        onCreateOption?.(inputValue)
      }
    },
    onKeyDown: (event: React.KeyboardEvent<HTMLElement>) => {
      if ((event.key === 'Enter' || event.key === 'Tab') && !!inputValue) {
        update({ inputChanged: true, inputValue: '', hasSearchOpen: false })
        onCreateOption?.(inputValue)
        event.preventDefault()
      }
    },
  }

  const asyncCreatableSelectProps: Partial<AsyncCreatableProps<SelectV2Option, boolean, GroupBase<SelectV2Option>>> = {
    ...asyncSelectProps,
    ...baseCreatableSelectProps,
  }

  const selectProps: Partial<Props<SelectV2Option>> = {
    ...baseProps,
    options: currentOptions,
  }

  const creatableSelectProps: Partial<CreatableProps<SelectV2Option, boolean, GroupBase<SelectV2Option>>> = {
    ...selectProps,
    ...baseCreatableSelectProps,
  }

  return (
    <SelectV2Context.Provider value={{ values: state, update }}>
      <div
        ref={ref}
        className={classNames(`${selectRootClass}__container`, {
          [`${selectRootClass}__container-disabled`]: isDisabled,
          [`${selectRootClass}__container-has-counter`]: truncateMulti,
        })}
        data-test={dataTest}
      >
        {label && (
          <div className={`${selectRootClass}__label`}>
            <Label>{isMulti ? <Typography text={label} weight={TextWeight.MEDIUM} /> : label}</Label>
            {isRequired && <Typography text={'(required)'} type={TextType.NORMAL_TEXT_GRAY_LARGE} lineHeight={LineHeight.LARGE} />}
            {tooltipMessage && (
              <>
                {useToggleTip ? (
                  <Toggletip
                    triggerOnHover
                    description={tooltipMessage}
                    className={`${selectRootClass}__label-tooltip`}
                    side={ToggletipSide.TOP}
                    sideOffset={6}
                  />
                ) : (
                  <Tooltip
                    className={`${selectRootClass}__label-tooltip`}
                    trigger={<Svg name={SvgNames.info} type={SvgType.LARGER_ICON} fill={SvgColor.LIGHT_GRAY} hoverFill={SvgColor.TEXT_TEAL} />}
                  >
                    {tooltipMessage}
                  </Tooltip>
                )}
              </>
            )}
          </div>
        )}
        {isAsync ? (
          !!onCreateOption ? (
            <AsyncCreatableSelect {...asyncCreatableSelectProps} />
          ) : (
            <AsyncSelect {...asyncSelectProps} />
          )
        ) : !!onCreateOption ? (
          <CreatableSelect {...creatableSelectProps} />
        ) : (
          <Select {...selectProps} />
        )}
        {error && !!errorMessage ? (
          <div className={`${selectRootClass}__error`}>
            <Svg name={SvgNames.inputStatusInvalidNoFill} type={SvgType.ICON} fill={SvgColor.ERROR_TEXT} />
            <Typography
              text={t(errorMessage)}
              type={TextType.ERROR_NEW}
              lineHeight={LineHeight.MEDIUM_SMALL}
              tagComponents={{ TextLink: <TextLink link={errorLink} /> }}
              inline
            />
          </div>
        ) : helperMessage ? (
          <Typography text={helperMessage} type={TextType.BODY_TEXT_SMALL_LIGHT} lineHeight={LineHeight.MEDIUM_SMALL} />
        ) : null}
      </div>
    </SelectV2Context.Provider>
  )
}

export default SelectV2
