import React, { FC, ReactNode, useContext } from 'react'

import classNames from 'classnames'

import Container from '@components/Container'
import Radio from '@components/Radio'
import RadioWithOptions from '@components/RadioWithOptions/RadioWithOptions'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import {
  delimiterSymbols,
  ExportFirstLineOption,
  exportOptionsText,
  Quote,
} from '@src/pages/ExportContacts/components/ExportOptionsScreen/utils/ExportOptionsScreen.constants'
import { getTypographyProps, renderExamplePill } from '@src/pages/ExportContacts/components/ExportOptionsScreen/utils/ExportOptionsScreen.utils'
import { ExportContactsContext } from '@src/pages/ExportContacts/utils/ExportContacts.context'
import { convertScheduleToTime } from '@src/pages/programs/dashboard/components/ProgramSteps/ProgramSteps.utils'

import './ReviewExportOptions.css'

interface ReviewExportOptionsProps {
  className?: string
  dataTest?: string
}

const rootClass = 'review-export-options'

const ReviewExportOptions: FC<ReviewExportOptionsProps> = (props: ReviewExportOptionsProps) => {
  const { dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const {
    values: { schedule, firstLineContains, fieldsSeparator, fieldQuotes },
  } = useContext(ExportContactsContext)
  const renderSection = (title: string, content: ReactNode) => (
    <div className={`${rootClass}__section`}>
      <Typography className={`${rootClass}__section-header`} text={t(title)} weight={TextWeight.MEDIUM} />
      <div className={`${rootClass}__section-content`}>{content}</div>
    </div>
  )
  return (
    <div>
      <Container className={classNames(rootClass, className)} dataTest={dataTest}>
        <Typography
          className={`${rootClass}__header`}
          text={t('Export options')}
          type={TextType.BODY_TEXT_LARGE}
          weight={TextWeight.MEDIUM}
          lineHeight={LineHeight.MEDIUM_LARGE}
        />
        <div>
          {renderSection(
            t('Sync frequency'),
            <RadioWithOptions label={t(schedule.isScheduled ? 'On a schedule' : 'Off')} checked={schedule.isScheduled} disabled>
              <Typography text={convertScheduleToTime(schedule, t)} />
            </RadioWithOptions>
          )}
          {renderSection(
            'ExportContacts.Sections.ExportOptions.Fields.Title',
            <Radio
              label={t('ExportContacts.Sections.ExportOptions.Fields')}
              className={rootClass}
              disabled
              checked
              labelTypography={{
                values: { option: t(exportOptionsText[firstLineContains]) },
                tagProps: {
                  light: { type: TextType.BODY_TEXT_LIGHT },
                },
                tagComponents: {
                  FirstName: renderExamplePill(firstLineContains === ExportFirstLineOption.NAMES ? 'FirstName' : 'John'),
                  LastName: renderExamplePill(firstLineContains === ExportFirstLineOption.NAMES ? 'LastName' : 'Doe'),
                  State: renderExamplePill(firstLineContains === ExportFirstLineOption.NAMES ? 'State' : 'CA'),
                },
              }}
            />
          )}
          {renderSection(
            'ExportContacts.Sections.ExportOptions.FieldsSeparator.Title',
            <Radio
              label={t('ExportContacts.Sections.ExportOptions.FieldsSeparator')}
              className={rootClass}
              disabled
              checked
              labelTypography={getTypographyProps(exportOptionsText[fieldsSeparator], delimiterSymbols[fieldsSeparator])}
            />
          )}
          {renderSection(
            'ExportContacts.Sections.ExportOptions.Quoted.Title',
            <Radio
              className={rootClass}
              label={t('ExportContacts.Sections.ExportOptions.Quoted')}
              disabled
              checked
              labelTypography={getTypographyProps(exportOptionsText[fieldQuotes], fieldQuotes === Quote.SimpleQuote ? "'" : '"')}
            />
          )}
        </div>
      </Container>
    </div>
  )
}

export default ReviewExportOptions
