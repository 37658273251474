import React, { FC, useEffect } from 'react'

import { useTranslation } from '@const/globals'
import data from '@emoji-mart/data/sets/14/apple.json'
import Picker from '@emoji-mart/react'

import {
  configs,
  customCategoryIcons,
  EmojiPickerIcons,
  EmojiPickerNavPosition,
  EmojiPickerPreviewPosition,
  EmojiPickerSearchPosition,
  EmojiPickerSkinTonePosition,
  EmojiPickerTheme,
} from './EmojiPicker.constants'
import injectedCss from './EmojiPicker.style'

import './EmojiPicker.css'

export interface EmojiPickerProps {
  theme?: EmojiPickerTheme
  icons?: EmojiPickerIcons
  autoFocus?: boolean
  navPosition?: EmojiPickerNavPosition
  previewPosition?: EmojiPickerPreviewPosition
  searchPosition?: EmojiPickerSearchPosition
  skinTonePosition?: EmojiPickerSkinTonePosition
  dataTest?: string
  onSelect: (item: EmojiPickerSelectedItem) => void
}

export interface EmojiPickerSelectedItem {
  id: string
  keywords: string[]
  name: string
  native: string
  shortcodes: string
  unified: string
}

const rootClass = 'emoji-picker'

const EmojiPicker: FC<EmojiPickerProps> = (props: EmojiPickerProps) => {
  const {
    theme = EmojiPickerTheme.LIGHT,
    icons = EmojiPickerIcons.OUTLINE,
    autoFocus = true,
    navPosition = EmojiPickerNavPosition.BOTTOM,
    previewPosition = EmojiPickerPreviewPosition.NONE,
    searchPosition = EmojiPickerSearchPosition.STICKY,
    skinTonePosition = EmojiPickerSkinTonePosition.SEARCH,
    dataTest = rootClass,
    onSelect,
  } = props

  const {
    i18n: { language },
  } = useTranslation()

  useEffect(() => {
    const element = document.querySelector('em-emoji-picker')
    if (element) {
      const styleNode = document.createElement('style')
      styleNode.innerHTML = injectedCss
      element.shadowRoot?.appendChild(styleNode)
    }
  }, [])

  return (
    <div className={rootClass} data-test={dataTest}>
      <Picker
        data={data}
        categoryIcons={customCategoryIcons}
        set={configs.SET}
        theme={theme}
        icons={icons}
        emojiVersion={configs.EMOJI_VERSION}
        locale={language}
        /* eslint-disable-next-line jsx-a11y/no-autofocus */
        autoFocus={autoFocus}
        navPosition={navPosition}
        previewPosition={previewPosition}
        searchPosition={searchPosition}
        skinTonePosition={skinTonePosition}
        maxFrequentRows={configs.MAX_FREQUENT_ROWS}
        onEmojiSelect={onSelect}
      />
    </div>
  )
}

export default EmojiPicker
