import { gql } from '@apollo/client'

export default gql`
  mutation createSubscriptionCategory($name: String!, $description: String, $checked: Boolean) {
    createSubscriptionCategory(name: $name, description: $description, checked: $checked) {
      id
      name
      description
      checked
    }
  }
`
