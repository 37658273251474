import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

/**
 * Predefined date format templates for consistent date representation.
 *
 * @enum {string}
 * @property {string} STANDARD - (e.g., 'Jan 15, 2024')
 * @property {string} SHORT - (e.g., 'Jan 15')
 * @property {string} LONG - (e.g., 'Jan 15, 2024, 3:45pm')
 * @property {string} FULL - (e.g., 'Mon, Jan 15, 2024, 3:45pm')
 * @property {string} DETAILED - (e.g., 'Jan 29, 2025 at 10:46 PM z')
 */
export enum DateFormats {
  STANDARD = 'MMM D, YYYY',
  SHORT = 'MMM D',
  LONG = 'MMM D, YYYY, h:mma',
  FULL = 'ddd, MMM D, YYYY, h:mma',
  DETAILED = 'MMM D, YYYY [at] h:mm A z',
}

/**
 * Represents dayjs time units
 *
 * @enum {string}
 */
export enum TimeUnits {
  MINUTE = 'm',
  HOUR = 'h',
  DAY = 'd',
  MONTH = 'M',
  YEAR = 'y',
}

/**
 * Defines the number of days within which a date is considered "recent"
 * for relative date formatting.
 */
const DAY_THRESHOLD = 7

/**
 * Pure utility method that provides a central place to format a timestamp using a specified date format
 *
 * @param {number} timestamp - Unix timestamp (milliseconds since epoch)
 * @param {DateFormats} format - The date format to apply
 * @returns {string} Formatted date string
 * @private
 */
function format(timestamp: number, format: DateFormats): string {
  return dayjs(timestamp).format(format)
}

/**
 * Formats a timestamp using the standard date format (MMM D, YYYY).
 *
 * @param {number} timestamp - Unix timestamp to format
 * @returns {string} Date in standard format
 * @example
 * standardDateFormat(Date.now()) // Returns: "Jan 15, 2024"
 */
export function standardDateFormat(timestamp: number): string {
  return format(timestamp, DateFormats.STANDARD)
}

/**
 * Formats a timestamp using the long date format (MMM D, YYYY, h:mma).
 *
 * @param {number} timestamp - Unix timestamp to format
 * @returns {string} Date in long format
 * @example
 * longDateFormat(Date.now()) // Returns: "Jan 15, 2024, 3:45pm"
 */
export function longDateFormat(timestamp: number): string {
  return format(timestamp, DateFormats.LONG)
}

/**
 * Formats a timestamp using the full date format (ddd, MMM D, YYYY, h:mma).
 *
 * @param {number} timestamp - Unix timestamp to format
 * @returns {string} Date in full format
 * @example
 * fullDateFormat(Date.now()) // Returns: "Mon, Jan 15, 2024, 3:45pm"
 */
export function fullDateFormat(timestamp: number): string {
  return format(timestamp, DateFormats.FULL)
}

/**
 * Formats a timestamp using the short date format (MMM D).
 *
 * @param {number} timestamp - Unix timestamp to format
 * @returns {string} Date in short format
 * @example
 * shortDateFormat(Date.now()) // Returns: "Jan 15"
 */
export function shortDateFormat(timestamp: number): string {
  return format(timestamp, DateFormats.SHORT)
}

/**
 * Provides a relative date format based on the timestamp's proximity to the current date.
 *
 * - If the date is from a different year, returns the standard date format
 * - If the date is within the last 7 days, returns a relative time (e.g., "2 days ago")
 * - Otherwise, returns the short date format
 *
 * @param {number} timestamp - Unix timestamp to format
 * @returns {string} Relative or formatted date string
 * @example
 * relativeDateFormat(Date.now() - 86400000) // Returns: "1 day ago"
 * relativeDateFormat(Date.now() - 604800000) // Returns: "Jan 15"
 */
export function relativeDateFormat(timestamp: number): string {
  const date = dayjs(timestamp)
  const now = dayjs()

  if (date.year() !== now.year()) {
    return standardDateFormat(timestamp)
  } else if (date.isAfter(now.subtract(DAY_THRESHOLD, 'day').startOf('day'))) {
    return date.fromNow()
  } else {
    return shortDateFormat(timestamp)
  }
}

/**
 * Formats a date string using a specified date format.
 *
 * @param {string} dateString - Date string to be parsed
 * @param {DateFormats} dateFormat - The date format to apply
 * @returns {string} Formatted date string
 * @example
 * dateFormatFromString('2024-01-15', DateFormats.STANDARD) // Returns: "Jan 15, 2024"
 */
export function dateFormatFromString(dateString: string, dateFormat: DateFormats): string {
  return format(dayjs(dateString).valueOf(), dateFormat)
}

/**
 * Formats a timestamp using the detailed date format (MMM D, YYYY at h:mm A z)
 * @param {number} [timestamp] - The timestamp to be formatted (in milliseconds since epoch).
 * @returns {string} The formatted date string in the format "MMM D, YYYY at h:mm A z",
 * @example
 * detailedDateFormat(1700000000000) // Returns: "Nov 14, 2023 at 1:26 PM UTC"
 */

export function detailedDateFormat(timestamp: number): string {
  return format(timestamp, DateFormats.DETAILED)
}
