import { gql } from '@apollo/client'

export default gql`
  query getCountQueryBySubTypes($days: Int, $subTypes: [String], $type: String) {
    getCountQueryBySubTypes(days: $days, subTypes: $subTypes, type: $type) {
      count
      field
      folderSubType
      itemType
      subType
      value
    }
  }
`
