import React, { FC, ReactNode, useCallback, useMemo, useRef, useState } from 'react'

import classNames from 'classnames'

import { ApolloQueryResult, MutationFunctionOptions, FetchResult } from '@apollo/client'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { HeaderAction, renderCheckboxCell } from '@components/Table/Table'
import { MultipleTables } from '@components/TableV2/components/MultipleTables/MultipleTables'
import { TableV2Props } from '@components/TableV2/tableV2TS/interfaces'
import TableWithLoaderAndEmptyListing from '@components/TableWithLoaderAndEmptyListing/TableWithLoaderAndEmptyListing'
import { useTranslation } from '@const/globals'
import {
  CopyPermissionsMutation,
  EditPermissionsMutation,
  SetAdminPermissionMutation,
  SetAdminPermissionMutationVariables,
  UserPermissionsInput,
} from '@graphql/types/mutation-types'
import { Exact, GetAllMarketingUsersQuery, Maybe, OverviewManageProfile, UserResponse } from '@graphql/types/query-types'
import CopyPermissionsModal, { CopyFromUser } from '@src/pages/Settings/Users/components/CopyPermissionsModal/CopyPermissionsModal'
import { approvalsMap } from '@src/pages/Settings/Users/components/UserLaunchApprovalSelect/launchApproval.const'
import { useAllUsers } from '@src/pages/Settings/Users/context/UsersContext'
import EditPermissionsModal from '@src/pages/Settings/Users/tabs/MarketingUsers/components/EditPermissionsModal/EditPermissionsModal'
import MarketingUsersSubHeader from '@src/pages/Settings/Users/tabs/MarketingUsers/components/MarketingUsersSubHeader'
import MarketingUsersTableInfoBar from '@src/pages/Settings/Users/tabs/MarketingUsers/components/MarketingUsersTableInfoBar'
import getHeaderActions from '@src/pages/Settings/Users/tabs/MarketingUsers/utils/MarketingUsers.headerActions'
import getRowActions from '@src/pages/Settings/Users/tabs/MarketingUsers/utils/MarketingUsers.rowActions'
import { useTableColumns, tableColumnsV2 } from '@src/pages/Settings/Users/tabs/MarketingUsers/utils/MarketingUsers.tableColumns'
import getTableProps from '@src/pages/Settings/Users/tabs/MarketingUsers/utils/MarketingUsers.tableProps'
import { getConfirmationBody, getSelectedUsers, getSelectedUsersIds } from '@src/pages/Settings/Users/tabs/MarketingUsers/utils/tableActions'
import { logNewRelicError } from '@utils/new-relic.utils'
import { FetchPromise } from '@utils/types'

import './MarketingUsers.css'

interface MarketingUsersProps {
  className?: string
  dataTest?: string
  data: UserResponse[]
  loading: boolean
  refetch: () => Promise<ApolloQueryResult<GetAllMarketingUsersQuery>>
  maxUsersCount: number
  isAdmin: boolean
  copyPermissions: (
    options?:
      | MutationFunctionOptions<CopyPermissionsMutation, Exact<{ usersIds?: Maybe<string[]> | Maybe<string>; copyFromUserId: string }>>
      | undefined
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>
  editPermissions: (
    options?:
      | MutationFunctionOptions<
          EditPermissionsMutation,
          Exact<{ id: string; permissions?: Maybe<UserPermissionsInput>; launchApproval?: Maybe<string> }>
        >
      | undefined
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>
  setAdmin: (
    options?: MutationFunctionOptions<SetAdminPermissionMutation, SetAdminPermissionMutationVariables> | undefined
  ) => FetchPromise<SetAdminPermissionMutation>
}

const defaultSortBy = { sortBy: [{ id: 'fullName', desc: false }] }

const rootClass = 'marketing-users'

const MarketingUsers: FC<MarketingUsersProps> = (props: MarketingUsersProps) => {
  const { dataTest = rootClass, className = '', data, loading, isAdmin, maxUsersCount, refetch, copyPermissions, editPermissions, setAdmin } = props
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [showCopyPermissionsModal, setShowCopyPermissionsModal] = useState<boolean>(false)
  const [copyFromUser, setCopyFromUser] = useState<CopyFromUser>()
  const [userToEditPermissions, setUserToEditPermissions] = useState<UserResponse>()
  const [usersToCopyPermissions, setUsersToCopyPermissions] = useState<UserResponse[]>([])
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)
  const [selectedUsersCount, setSelectedUsersCount] = useState<number>(0)
  const { onEditProfile, onDeleteUsers, handleResendVerification, setToastStatus } = useAllUsers()
  const { t } = useTranslation()
  const selectedUsers = useRef<UserResponse[]>([])
  const showEditPermissionsModal = !!userToEditPermissions

  const filteredUsers = useMemo<UserResponse[]>(() => {
    const term = searchTerm.toLowerCase()
    return term
      ? data.filter((user) => {
          const { fullName, email, title } = user
          return [fullName, email, title].some((attr) => attr && attr.toLowerCase().includes(term))
        })
      : data
  }, [data, searchTerm])

  const onEditProfileAction = useCallback((row: OverviewManageProfile) => {
    onEditProfile(row, refetch)
  }, [])

  const onEditPermissionAction = useCallback<(user?: UserResponse) => void>((user) => {
    setUserToEditPermissions(user)
  }, [])

  const onCopyPermissionAction = useCallback<(user?: UserResponse) => void>((user) => {
    setUsersToCopyPermissions(getSelectedUsers(selectedUsers.current, user))
    setShowCopyPermissionsModal(true)
  }, [])

  const onDeleteUsersAction = useCallback<(user?: UserResponse) => void>(
    (user) => {
      const users = getSelectedUsers(selectedUsers.current, user)
      if (users.length) {
        onDeleteUsers(users, refetch, false)
      }
    },
    [refetch, onDeleteUsers]
  )

  const onResendVerificationAction = useCallback<(user?: UserResponse) => void>(
    (user) => {
      const users = getSelectedUsers(selectedUsers.current, user)
      if (users.length) {
        handleResendVerification(users).then(() => refetch())
      }
    },
    [refetch]
  )

  const onRowSelectionChanged = useCallback<(rowIds: string[], rows: { original: UserResponse; id: string }[]) => void>((rowIds, users) => {
    selectedUsers.current = users.filter(({ id }) => rowIds.includes(id)).map(({ original }) => original)
    setSelectedUsersCount(selectedUsers.current.filter((user) => !user.isMe).length)
  }, [])

  const checkboxCellOverride = useCallback<(row: any) => ReactNode>(
    (row) => renderCheckboxCell(row, t('Marketing.Users.Checkbox.Cell.Override.Message')),
    []
  )

  const columns = useTableColumns(rootClass, onEditPermissionAction, isAdmin)
  const columnsV2 = useMemo(() => tableColumnsV2(rootClass, isAdmin, t, onEditPermissionAction), [rootClass, isAdmin, t, onEditPermissionAction])

  const tableProps = useMemo(() => getTableProps({ t, haveData: !!filteredUsers.length, searchTerm }), [filteredUsers.length, searchTerm])
  const rowActions = useMemo(
    () => getRowActions(t, onEditProfileAction, onEditPermissionAction, onCopyPermissionAction, onDeleteUsersAction, onResendVerificationAction),
    [onEditProfileAction, onEditPermissionAction, onCopyPermissionAction, onDeleteUsersAction, onResendVerificationAction]
  )
  const headerActions = useMemo<HeaderAction[]>(
    () => getHeaderActions(t, onDeleteUsersAction, onResendVerificationAction, onCopyPermissionAction, data.length),
    [t, onDeleteUsersAction, onResendVerificationAction, onCopyPermissionAction, data]
  )
  const handleConfirmationAnswer = useCallback(
    (answer: YesNo) => {
      setShowConfirmationModal(false)
      if (answer === YesNo.YES && copyFromUser?.id) {
        const setIsAdmin = copyFromUser.isAdmin
        const ids = getSelectedUsersIds(usersToCopyPermissions)
        Promise.all([
          usersToCopyPermissions.length &&
            setAdmin({
              variables: {
                ids,
                value: setIsAdmin,
                launchApproval: (approvalsMap.get(copyFromUser?.launchApproval || '') || copyFromUser.launchApprovalId) ?? '',
              },
            }),
          // No need to copy permissions, if user set as admin, cause admin have all permissions
          !setIsAdmin &&
            copyPermissions({
              variables: {
                usersIds: ids,
                copyFromUserId: copyFromUser.id.toString(),
              },
            }),
        ]).then((responses) => {
          const [resAdmin, respCopy] = responses
          const statuses: string[] = []
          if (resAdmin) {
            resAdmin.data?.setAdminPermission?.status && statuses.push(resAdmin.data.setAdminPermission.status)
            if (resAdmin?.data?.setAdminPermission?.status !== 'ok') {
              resAdmin.errors?.map((error) => logNewRelicError(error.message))
            }
          }
          if (respCopy) {
            statuses.push(respCopy.data.copyPermissions.status)
            if (respCopy.data.copyPermissions.status !== 'ok') {
              respCopy.errors?.map((error) => logNewRelicError(error.message))
            }
          }
          if (statuses.length) {
            if (statuses.every((st) => st === 'ok')) {
              const count = respCopy ? respCopy.data.copyPermissions.count : usersToCopyPermissions.length
              setToastStatus({
                showStatus: true,
                title: t('Success!'),
                statusMessage: t('Copy.Permissions.Users.Success', {
                  count,
                  userName: usersToCopyPermissions.length === 1 && usersToCopyPermissions[0].fullName,
                }),
                successStatus: true,
              })
              setCopyFromUser(undefined)
            } else {
              setToastStatus({
                showStatus: true,
                statusMessage: t('Copy.Permissions.Users.Error'),
                successStatus: false,
              })
            }
            refetch()
          }
        })
      }
    },
    [usersToCopyPermissions, copyFromUser, setAdmin, copyPermissions, refetch]
  )

  const handleCancelEditPermissions = useCallback(() => {
    setCopyFromUser(undefined)
    setUserToEditPermissions(undefined)
    selectedUsers.current = []
  }, [])

  const handleOpenCopyPermissions = useCallback(() => {
    if (userToEditPermissions) {
      setUsersToCopyPermissions([userToEditPermissions])
      setShowCopyPermissionsModal(true)
    }
  }, [userToEditPermissions])

  const tableV2Props: TableV2Props<UserResponse> = useMemo(
    () => ({
      loading,
      enableCheckbox: true,
      enableSorting: true,
      data: filteredUsers,
      columns: columnsV2,
      enableOuterLoader: true,
      rowActions: isAdmin ? rowActions : [],
      headerActions: isAdmin ? headerActions : [],
      selectedRowsCount: selectedUsersCount,
      sortingBy: defaultSortBy.sortBy,
      emptyState: {
        size: EmptyListingSize.MEDIUM,
        hideIcon: false,
        headline: !filteredUsers.length ? t('Marketing.Users.Table.Empty.Search.Headline', { searchTerm }) : undefined,
        text: !filteredUsers.length ? t('Marketing.Users.Table.Empty.Search.Text') : undefined,
        imgSrc: !filteredUsers.length ? StaticImageNames.emptySearch : undefined,
      },
      checkboxCellOverride,
      onRowSelectionChanged,
      rowUniqueIdKey: 'id',
    }),
    [checkboxCellOverride, columnsV2, filteredUsers, headerActions, isAdmin, loading, onRowSelectionChanged, rowActions, selectedUsersCount]
  )

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <MarketingUsersSubHeader />
      {!loading && (
        <MarketingUsersTableInfoBar
          onSearch={setSearchTerm}
          searchTerm={searchTerm}
          marketingUsersCount={data.filter((user) => !user.isSingleSignOn).length}
          maxAllowedMarketingUsersCount={maxUsersCount}
          loading={loading}
        />
      )}
      <MultipleTables
        tableV2Props={tableV2Props}
        oldTable={
          <TableWithLoaderAndEmptyListing
            className={`${rootClass}__table`}
            dataTest={`${rootClass}-table`}
            loading={loading}
            data={filteredUsers}
            columns={columns}
            headerAddMargin
            useCheckboxes={isAdmin}
            useHeaderCheckbox={isAdmin}
            rowActions={isAdmin ? rowActions : []}
            headerActions={isAdmin ? headerActions : []}
            checkboxCellOverride={checkboxCellOverride}
            onRowSelectionChanged={onRowSelectionChanged}
            selectedRowsCount={selectedUsersCount}
            initialState={defaultSortBy}
            {...tableProps}
          />
        }
      />
      <CopyPermissionsModal
        isOpen={showCopyPermissionsModal}
        onSave={(copyFromUser) => {
          setCopyFromUser(copyFromUser)
          setShowCopyPermissionsModal(false)
          !showEditPermissionsModal && setShowConfirmationModal(true)
        }}
        onCancel={() => {
          setShowCopyPermissionsModal(false)
          setCopyFromUser(undefined)
        }}
        selectedUsersIds={usersToCopyPermissions ? getSelectedUsersIds(usersToCopyPermissions) : []}
        userIdToEditPermissions={userToEditPermissions?.id}
      />
      <EditPermissionsModal
        onCancel={handleCancelEditPermissions}
        onCopyPermissions={handleOpenCopyPermissions}
        user={userToEditPermissions}
        copyFromUser={copyFromUser}
        editPermissions={editPermissions}
        setAdmin={setAdmin}
        refetch={refetch}
      />
      <ConfirmationModal
        isYesNo
        yesButtonText={'Save Changes'}
        isOpen={showConfirmationModal}
        title={t('Are you sure?')}
        body={getConfirmationBody(t, usersToCopyPermissions, data, copyFromUser)}
        onAnswer={handleConfirmationAnswer}
        className={rootClass}
        dataTest={`${dataTest}__confirm-copy-permissions`}
      />
    </div>
  )
}

export default MarketingUsers
