import React, { ChangeEvent, FC, useCallback } from 'react'

import FormRow from '@components/FormRow'
import Grid from '@components/Grid/Grid'
import Input from '@components/Input/Input'
import InputWithStatus, { ValidityReturnType } from '@components/InputWithStatus/InputWithStatus'
import Label from '@components/Label'
import LabelWithSvgTooltip from '@components/LabelWithTooltipIcon/LabelWithSvgTooltip'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import SingleSelectDropdown from '@components/SingleSelectDropdown/SingleSelectDropdown'
import TextArea from '@components/TextArea/TextArea'
import TimeZoneSelectV2 from '@components/TimeZoneSelectV2/TimeZoneSelectV2'
import { useTranslation } from '@const/globals'
import { userTypeOptions, UserTypeValue } from '@src/pages/Settings/Users/components/InviteUsersModal/const/userType.const'
import { InviteUsersForm, InviteUsersValueChangesFuncType } from '@src/pages/Settings/Users/components/InviteUsersModal/InviteUsersModalContent'
import { LEARN_MORE_LINK } from '@src/pages/Settings/Users/constants/users.constants'
import { useMarketingUsers } from '@src/pages/Settings/Users/context/MarketingUsersContext'
import { useSalesUsers } from '@src/pages/Settings/Users/context/SalesUsersContext'
import { checkEmailValidity } from '@utils/formUtils'
import { getDynamicTimezones } from '@utils/timezones'

import './InviteSingleUserForm.css'

interface Props {
  onValueChange: InviteUsersValueChangesFuncType
  values: InviteUsersForm
  dataTest?: string
}

const NUMBERS_MAX_LENGTH = 32

const rootClass = 'invite-single-user-form'

const InviteSingleUserForm: FC<Props> = (props: Props) => {
  const { onValueChange, values, dataTest = rootClass } = props
  const { t } = useTranslation()
  const { salesUsers } = useSalesUsers()
  const { users: marketingUsers } = useMarketingUsers()
  const onInputChange = useCallback(
    (name: keyof InviteUsersForm) => (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
      const value = name === 'email' ? [e.target.value] : e.target.value
      onValueChange({ [name]: value })
    },
    [onValueChange]
  )

  const onTimeZoneChange = useCallback(
    (option?: SelectV2SingleOption) => {
      if (option) {
        onValueChange({ timeZone: option.value })
      }
    },
    [onValueChange]
  )

  const handleUserTypeSelect = useCallback(
    (type?: string) => onValueChange({ userType: (type ?? UserTypeValue.MARKETING) as UserTypeValue }),
    [onValueChange]
  )

  const emailExistValidation = useCallback<(newEmail: string) => ValidityReturnType | undefined>(
    (newEmail) => {
      if ([...salesUsers, ...marketingUsers].some(({ email }) => email === newEmail)) {
        onValueChange({ hasEmailError: true })
        return { errorMessageKey: 'emailExist' }
      } else {
        onValueChange({ hasEmailError: false })
      }
    },
    [salesUsers, marketingUsers, onValueChange]
  )

  return (
    <div className={rootClass}>
      <Grid row>
        <Grid size={50}>
          <FormRow>
            <LabelWithSvgTooltip
              label={t('User Type')}
              tooltipContent={t('Invite.Users.Modal.Type.Tooltip.Message')}
              link={LEARN_MORE_LINK}
              linkText={t('Learn more')}
              align="center"
              alignTextCenter
            />
            <SingleSelectDropdown
              options={userTypeOptions}
              onSubmit={handleUserTypeSelect}
              defaultValue={values.userType}
              allowDeselect={false}
              dataTest={`${dataTest}-select-userType`}
            />
          </FormRow>
        </Grid>
        <Grid size={50}>
          <FormRow className={`${rootClass}__row-email`}>
            <Label required>{t('Email Address')}</Label>
            <InputWithStatus
              value={values.email && values.email[0]}
              validityFunctions={[checkEmailValidity, emailExistValidation]}
              tooltipErrorMessages={{ emailInvalid: t('Invalid email address'), emailExist: t('Email.Tag.Existence.Error.Msg') }}
              onChange={onInputChange('email')}
              dataTest={`${dataTest}-input-email`}
              className={`${rootClass}__input-email`}
            />
          </FormRow>
        </Grid>
      </Grid>
      <Grid row>
        <Grid size={50}>
          <FormRow>
            <Label>{t('First Name')}</Label>
            <Input dataTest={`${dataTest}-input-firstName`} onChange={onInputChange('firstName')} maxlength={NUMBERS_MAX_LENGTH} />
          </FormRow>
        </Grid>
        <Grid size={50}>
          <FormRow>
            <Label>{t('Last Name')}</Label>
            <Input dataTest={`${dataTest}-input-lastName`} onChange={onInputChange('lastName')} maxlength={NUMBERS_MAX_LENGTH} />
          </FormRow>
        </Grid>
      </Grid>
      <Grid row>
        <Grid size={50}>
          <FormRow>
            <Label>{t('Title')}</Label>
            <Input dataTest={`${dataTest}-input-title`} onChange={onInputChange('title')} maxlength={NUMBERS_MAX_LENGTH} />
          </FormRow>
        </Grid>
        <Grid size={50}>
          <FormRow>
            <Label>{t('Time Zone')}</Label>
            <TimeZoneSelectV2
              label={
                getDynamicTimezones().find((timezone) => {
                  return timezone.value === values.timeZone
                })?.label || ''
              }
              selectProps={{
                insideModal: true,
                maxMenuHeight: 300,
                options: getDynamicTimezones(),
                value: {
                  label:
                    getDynamicTimezones().find((timezone) => {
                      return timezone.value === values.timeZone
                    })?.label || '',
                  value: values.timeZone || '',
                },
                onChange: onTimeZoneChange,
              }}
              dataTest={`${dataTest}-input-timezone`}
            />
          </FormRow>
        </Grid>
      </Grid>
      <FormRow>
        <LabelWithSvgTooltip
          label={t('Custom Welcome Message')}
          tooltipContent={t('Custom.Welcome.Message.Tooltip')}
          align="center"
          alignTextCenter
        />
        <TextArea
          value={values.customWelcomeMessage}
          onChange={onInputChange('customWelcomeMessage')}
          name={`${dataTest}-textarea`}
          placeholder={t('Invite.Users.Modal.TextArea.Placeholder')}
          rows={7}
          resize={false}
          className={`${rootClass}__textarea`}
          dataTest={`${dataTest}-textarea`}
        />
      </FormRow>
    </div>
  )
}

export default InviteSingleUserForm
