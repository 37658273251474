import React, { FC, SyntheticEvent, useEffect, useState } from 'react'
import DatePicker from 'react-date-picker'

import classNames from 'classnames'

import { useQuery } from '@apollo/client'
import Button, { ButtonType } from '@components/Button'
import Caution from '@components/Caution/Caution'
import Label from '@components/Label'
import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Radio from '@components/Radio'
import RadioGroup from '@components/RadioGroup'
import StatusToast from '@components/StatusToast/StatusToast'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import TextLink from '@components/TextLink/TextLink'
import TimeInput from '@components/TimeInput/TimeInput'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import getAllowedSendWindow from '@graphql/microservices/sms-management/getAllowedSendWindow'
import { AllowedSendWindow, GetAllowedSendWindowQuery, GetAllowedSendWindowQueryVariables } from '@graphql/types/microservice/sms-management-types'
import { AllowedSendWindowProcessed, defaultAllowedSendWindowState, processAllowedWindowMessageData } from '@src/pages/sms/edit/utils/EditSMSUtils'
import { useAccountSettings } from '@utils/account/account.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { logNewRelicError } from '@utils/new-relic.utils'

import { SMSContextAPI } from '../../../context/SMSContext'

import './ScheduledSendModal.css'

interface Props {
  context: SMSContextAPI
  isOpen: boolean
  deliveryTime: number
  className?: string
  dataTest?: string
  close: () => void
}

interface State {
  date: Date | undefined
  time: string
  isMarketerTimezone: boolean
  canSubmit: boolean
  mustShowAfterHours: boolean
  maxDateError: boolean
  scheduledSendTimeError: boolean
  allowedSendWindow: AllowedSendWindowProcessed
  isScheduling: boolean
}

const rootClass = 'scheduled-send-modal'

const defaultState: State = {
  date: undefined,
  time: '',
  isMarketerTimezone: true,
  canSubmit: false,
  mustShowAfterHours: false,
  maxDateError: false,
  scheduledSendTimeError: false,
  allowedSendWindow: defaultAllowedSendWindowState,
  isScheduling: false,
}

const splitTime = (time: string) => {
  return time.split(':')
}

const ScheduledSendModal: FC<Props> = (props: Props) => {
  const { context, deliveryTime, isOpen, close, dataTest = rootClass, className = '' } = props
  const {
    values: {
      smsMessage: { recipientTimezones },
    },
    updater,
    scheduleMessageLaunch,
    canSend,
  } = context
  const { t } = useTranslation()
  const [state, setState] = useState<State>(defaultState)

  const { accountId, accountTimeZoneId } = useAccountSettings()
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.SMS_MANAGEMENT })
  const { data, loading, error } = useQuery<GetAllowedSendWindowQuery, GetAllowedSendWindowQueryVariables>(getAllowedSendWindow, {
    client,
    fetchPolicy: 'network-only',
    variables: {
      accountId,
    },
  })

  useEffect(() => {
    if (error) {
      logNewRelicError(error)
    }
  }, [error])

  const getUpdatedAllowedSendWindowValues = (getAllowedSendWindow: AllowedSendWindow) => {
    const timeParts = splitTime(state.time)
    const timezoneOffset = new Date().getHours() - new Date().getUTCHours()
    return processAllowedWindowMessageData(
      getAllowedSendWindow,
      defaultState.allowedSendWindow,
      recipientTimezones,
      timezoneOffset,
      parseInt(timeParts[0]),
      parseInt(timeParts[1]),
      !state.isMarketerTimezone
    )
  }

  useEffect(() => {
    if (data?.getAllowedSendWindow && state.time) {
      const updatedAllowedSendWindow = getUpdatedAllowedSendWindowValues(data.getAllowedSendWindow)
      const allowedSendWindow = {
        ...state.allowedSendWindow,
        ...updatedAllowedSendWindow,
      }

      setState({ ...state, allowedSendWindow })
    }
  }, [data])

  const today = new Date()
  const minDate = canSend() ? today : new Date(today.getFullYear(), today.getMonth() + 1, 1)
  const maxDate = new Date(today.getFullYear(), today.getMonth() + 2, 1)

  useEffect(() => {
    const checkAfterHours = () => {
      const openTime = new Date()
      openTime.setHours(state.allowedSendWindow.startHour, state.allowedSendWindow.startMinute, 0)
      const closeTime = new Date()
      closeTime.setHours(state.allowedSendWindow.endHour, state.allowedSendWindow.endMinute, 0)
      const [hours, minutes] = splitTime(state.time)
      const userTime = new Date()
      userTime.setHours(parseInt(hours), parseInt(minutes), 0)
      return userTime < openTime || userTime > closeTime
    }

    if (state.time === null || state.time === '') {
      return
    }

    const updatedAllowedSendWindow = getUpdatedAllowedSendWindowValues({
      ...state.allowedSendWindow,
      allowOverride: state.allowedSendWindow.overrideAllowed,
    })
    const allowedSendWindow = {
      ...state.allowedSendWindow,
      ...updatedAllowedSendWindow,
    }

    const mustShowAfterHours = state.isMarketerTimezone ? updatedAllowedSendWindow.recipientsOutsideAllowedWindow > 0 : checkAfterHours()
    const canSubmit = state.date !== undefined
    setState({ ...state, canSubmit, mustShowAfterHours, allowedSendWindow })
    if (canSubmit) {
      updateMessage()
    }
  }, [state.date, state.time, state.isMarketerTimezone])

  const isPastTime = (date: Date, time: string) => {
    const newTimestamp = date
    const timeParts = time.split(':').map((part) => parseInt(part))
    newTimestamp.setHours(timeParts[0])
    newTimestamp.setMinutes(timeParts[1])
    return newTimestamp < new Date()
  }

  const onChangeTime = (val: string) => {
    if (!state.date) {
      setState({ ...state, time: val })
      return
    }

    if (val) {
      const scheduledSendTimeError = isPastTime(state.date, val)
      setState({ ...state, time: val, scheduledSendTimeError })
    }
  }

  const onChangeDate = (date: Date | Date[]) => {
    if (date && !Array.isArray(date)) {
      if (date < maxDate) {
        if (state.time && isPastTime(date, state.time)) {
          setState({ ...state, scheduledSendTimeError: true, maxDateError: false })
        } else {
          setState({
            ...state,
            date,
            maxDateError: false,
            scheduledSendTimeError: false,
          })
        }
      } else {
        setState({ ...state, maxDateError: true })
      }
    }
  }

  const updateMessage = () => {
    const updatedDate = state.date ?? new Date()
    const [hours, minutes] = splitTime(state.time)
    updatedDate.setHours(parseInt(hours), parseInt(minutes), 0)
    updater('scheduledSendTime', updatedDate.valueOf())
  }

  const onTimezoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isMarketerTimezone = e.target.value === 'Mine'
    setState({ ...state, isMarketerTimezone })
    updater('isMarketerTimezone', isMarketerTimezone)
  }

  const submitSchedule = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.currentTarget.blur()
    setState({ ...state, isScheduling: true })
    scheduleMessageLaunch()
  }

  const onClose = () => {
    setState(defaultState)
    close()
  }

  const getTimeEstimate = () => {
    return deliveryTime < 2 ? `${t('1 minute')}` : `${deliveryTime} ${t('minutes')}`
  }

  const getCautionText = (isAfterHours?: boolean) => {
    const windowTime = `${state.allowedSendWindow.startText} - ${state.allowedSendWindow.endText}`
    if (isAfterHours) {
      return `${t(`You've selected `)} ${Intl.DateTimeFormat('en', { weekday: 'long' }).format(state.date)} ${t('at')} ${getFormattedTime(
        state.time
      )}. ${t('This time is outside of the advised send window (')}) ${windowTime}).`
    }
    if (state.allowedSendWindow.overrideAllowed) {
      if (state.isMarketerTimezone) {
        return `${state.allowedSendWindow.recipientsOutsideAllowedWindow} ${t(
          `recipient${
            state.allowedSendWindow.recipientsOutsideAllowedWindow > 1 ? 's' : ''
          } would receive this message outside of your allowed SMS hours of`
        )} ${windowTime}. ${t('Are you sure you want to schedule this time?')}`
      } else {
        return `${t('This send is outside of your standard SMS hours of')} ${windowTime}. ${t('Are you sure you want to schedule this time?')}`
      }
    } else {
      if (state.isMarketerTimezone) {
        return `${state.allowedSendWindow.recipientsOutsideAllowedWindow} ${t(
          `recipient${
            state.allowedSendWindow.recipientsOutsideAllowedWindow > 1 ? 's' : ''
          } would receive this message outside of your allowed SMS hours of`
        )} ${windowTime}. ${t('Message will be sent to them once send is inside their timezone.')}`
      } else {
        return `${t('This send is outside of your standard SMS hours of')} ${windowTime}.`
      }
    }
  }

  const potential = state.allowedSendWindow.recipientsOutsideAllowedWindow > 0 && state.time
  const shouldShowCaution = potential && state.mustShowAfterHours
  const scheduleButtonAnywayText = potential && state.mustShowAfterHours

  const renderForm = () => (
    <Modal
      isOpen={isOpen}
      className={classNames(rootClass, className)}
      data-test={`${dataTest}-form`}
      header={<ModalHeader headerType={ModalHeaderType.Form}>{t('Schedule send')}</ModalHeader>}
    >
      <ModalBody className={`${rootClass}__form-body`}>
        {loading && <Loader loaderType={LoaderTypes.row} />}
        {!loading && (
          <>
            <div>
              <div className={`${rootClass}__inputs`}>
                <div>
                  <Label>{t('Send On')}</Label>
                  <DatePicker
                    onChange={onChangeDate}
                    value={state.date}
                    className={`input`}
                    calendarClassName={`${rootClass}__calendar`}
                    calendarIcon={null}
                    clearIcon={null}
                    showLeadingZeros
                    minDate={minDate}
                  />
                </div>
                <TimeInput
                  value={state.time}
                  onChange={(newVal) => onChangeTime(newVal as string)}
                  label={t('Time')}
                  error={state.scheduledSendTimeError}
                  hideClear
                />
              </div>
              {state.maxDateError && (
                <Typography
                  text={t('Scheduled send date must be before the end of next month.')}
                  type={TextType.ERROR}
                  className={`${rootClass}__time-error`}
                />
              )}
              {state.scheduledSendTimeError && (
                <Typography text={t('Scheduled time cannot be in the past')} type={TextType.ERROR} className={`${rootClass}__time-error`} />
              )}
              <div className={`${rootClass}__inputs`}>
                <RadioGroup label={t('Timezone')}>
                  <Radio
                    className={'radio-input'}
                    dataTest={`${dataTest}-radio-marketer`}
                    label={`${t('My Timezone')} (${accountTimeZoneId})`}
                    name="dueDate"
                    value={'Mine'}
                    checked={state.isMarketerTimezone}
                    onChange={onTimezoneChange}
                  />
                  <Radio
                    className={'radio-input'}
                    dataTest={`${dataTest}-radio-recipient`}
                    label={t('Recipients Timezone')}
                    name="dueDate"
                    value={'Recipients'}
                    checked={!state.isMarketerTimezone}
                    onChange={onTimezoneChange}
                  />
                </RadioGroup>
              </div>
              <div className={`${rootClass}__time-estimate`}>
                <div className={`${rootClass}__time-estimate-icon`}>
                  <span>
                    <Svg name={SvgNames.timeEstimate} type={SvgType.ICON} />
                  </span>
                </div>
                <div className={`${rootClass}__time-estimate-message`}>
                  <Typography text={t('Estimated time to send this message is')} inline type={TextType.BODY_TEXT_SMALL} />{' '}
                  <Typography text={getTimeEstimate()} inline weight={TextWeight.MEDIUM} type={TextType.BODY_TEXT_SMALL} />
                  {'. '}
                  <Typography text={t('We will begin sending messages at the time selected above.')} inline type={TextType.BODY_TEXT_SMALL} />
                </div>
              </div>
              {shouldShowCaution && <Caution textLines={[{ span: getCautionText() }]} className={`${rootClass}__allowed-window-warning`} />}
            </div>
            <div className={`${rootClass}__info`}>
              <Typography text={t('You will be able to cancel this send beforehand.')} type={TextType.BODY_TEXT_SMALL_LIGHT} inline />
              <TextLink text={'Learn more about scheduling'} link={'https://connect.act-on.com/hc/en-us/articles/1500003063802'} />
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form}>
        <Button buttonType={ButtonType.TERTIARY} onClick={onClose} dataTest={`${rootClass}-close-button`}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} dataTest={`${rootClass}-save-button`} disabled={!state.canSubmit} onClick={submitSchedule}>
          {state.isScheduling && <Loader loaderType={LoaderTypes.row} className={`${rootClass}__button-scheduling`} />}
          {!state.isScheduling && t(scheduleButtonAnywayText ? 'Schedule Anyway' : 'Schedule')}
        </Button>
      </ModalFooter>
    </Modal>
  )

  const getFormattedTime = (time: string) => {
    const date = new Date()
    const [hours, minutes] = splitTime(time)
    date.setHours(parseInt(hours), parseInt(minutes), 0)
    return Intl.DateTimeFormat('en', { hour: 'numeric', minute: 'numeric', hour12: true }).format(date).toLowerCase()
  }

  if (error) {
    return <StatusToast isSuccess={false} message={t('Your allowed sending window information could not be retrieved.')} closeStatus={() => false} />
  }

  return renderForm()
}

export default ScheduledSendModal
