import { useApolloClient } from '@apollo/client'
import campaigns from '@graphql/queries/campaigns'
import getSubscriptionCategories from '@graphql/queries/getSubscriptionCategories'
import loadFromAddresses from '@graphql/queries/loadFromAddressesPage'
import {
  CampaignsQuery,
  CampaignsQueryVariables,
  GetSubscriptionCategoriesQuery,
  GetSubscriptionCategoriesQueryVariables,
  LoadFromAddressesPageQuery,
  LoadFromAddressesPageQueryVariables,
} from '@graphql/types/query-types'
import { FetchPromise } from '@utils/types'

export interface SegmentComposerClassicRequests {
  getCampaignsRequest: () => FetchPromise<CampaignsQuery>
  getVerifiedSendersRequest: () => FetchPromise<LoadFromAddressesPageQuery>
  getSubscriptionCategoriesRequest: () => FetchPromise<GetSubscriptionCategoriesQuery>
}

export const useSegmentComposerClassicRequests = (): SegmentComposerClassicRequests => {
  const actonClient = useApolloClient()

  const getCampaignsRequest = async () =>
    await actonClient.query<CampaignsQuery, CampaignsQueryVariables>({
      query: campaigns,
      fetchPolicy: 'network-only',
    })

  const getVerifiedSendersRequest = async () =>
    await actonClient.query<LoadFromAddressesPageQuery, LoadFromAddressesPageQueryVariables>({
      query: loadFromAddresses,
      fetchPolicy: 'network-only',
      variables: {
        sortColumn: 'name',
      },
    })

  const getSubscriptionCategoriesRequest = async () =>
    await actonClient.query<GetSubscriptionCategoriesQuery, GetSubscriptionCategoriesQueryVariables>({
      query: getSubscriptionCategories,
      fetchPolicy: 'network-only',
    })

  return {
    getCampaignsRequest,
    getVerifiedSendersRequest,
    getSubscriptionCategoriesRequest,
  }
}
