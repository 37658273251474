import React from 'react'

import { TFunction } from 'i18next'

import { MediaItem } from '@components/AssetPickers/MediaPickerModal/MediaPickerModal.context'
import { YesNo } from '@components/ConfirmationModal'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { LandingPageContentResponse, RetrieveBeeLandingPageQuery } from '@graphql/types/query-types'
import { EmailComposerAPI, SavedEmailMessageTemplate } from '@utils/composer/context/EmailComposer.context'
import { LandingPageComposerState } from '@utils/composer/context/LandingPageComposer.context'
import { logError } from '@utils/env'
import { DeepPartial, FetchPromise } from '@utils/types'

import { retrieveLandingPageHelper } from './retrieveLandingPageHelper'

export const handleGetLandingPage = ({
  messageId,
  containerValuesRef,
  update,
  changeLastSavedTemplate,
  retrieveBeeLandingPageRequest,
  newLPComposerCreateBlank,
  accountName,
}: {
  messageId: string
  containerValuesRef: React.MutableRefObject<LandingPageComposerState>
  update: (fields: DeepPartial<LandingPageComposerState>) => void
  changeLastSavedTemplate: (message: SavedEmailMessageTemplate) => void
  retrieveBeeLandingPageRequest: (messageId: string) => FetchPromise<RetrieveBeeLandingPageQuery>
  newLPComposerCreateBlank?: boolean
  accountName?: string
}) => {
  try {
    const commonGetMessageProps = {
      containerValuesRef,
      update,
      retrieveBeeLandingPageRequest,
      changeLastSavedTemplate,
      newLPComposerCreateBlank,
      accountName,
    }

    getLandingPageById({
      messageId,
      ...commonGetMessageProps,
    }).then(() => {
      update({ message: { id: messageId } })
    })
  } catch (error) {
    logError(error)
  }
}

const getLandingPageById = ({
  messageId,
  containerValuesRef,
  update,
  changeLastSavedTemplate,
  retrieveBeeLandingPageRequest,
  newLPComposerCreateBlank,
  accountName,
}: {
  messageId: string
  containerValuesRef: React.MutableRefObject<LandingPageComposerState>
  update: (fields: DeepPartial<LandingPageComposerState>) => void
  changeLastSavedTemplate: (message: SavedEmailMessageTemplate) => void
  retrieveBeeLandingPageRequest: (messageId: string) => FetchPromise<RetrieveBeeLandingPageQuery>
  newLPComposerCreateBlank?: boolean
  accountName?: string
}) => {
  if (messageId) {
    return retrieveBeeLandingPageRequest(messageId).then(({ data }) => {
      if (data?.retrieveBeeLandingPage) {
        const preparedData = retrieveLandingPageHelper({
          landingPage: data.retrieveBeeLandingPage as LandingPageContentResponse,
          containerValuesRef,
          newLPComposerCreateBlank,
          accountName,
        })

        update(preparedData)

        if (preparedData.message?.templateJson) {
          changeLastSavedTemplate(preparedData.message)
        }

        return Promise.resolve(preparedData)
      }
    })
  }
  return Promise.reject()
}

export const formatSelectedFiles = async (selectedItems?: MediaItem[]) => {
  if (!selectedItems) return []

  return Promise.all(
    selectedItems.map(async (item) => {
      const content = await fetch(item.linkUrl ?? '').then((response) => {
        return response.text()
      })

      return {
        name: item.title ?? '',
        id: item.id,
        content,
      }
    })
  )
}

export const landingPageConfirmationModal = ({
  t,
  title,
  bodyText,
  onConfirm,
  updateModal,
}: {
  t: TFunction
  title: string
  bodyText: string
  updateModal: EmailComposerAPI['updateModal']
  onConfirm: VoidFunction
}) => {
  updateModal('confirmation', {
    title: `${t(title)}?`,
    isYesNo: true,
    body: <Typography text={t(bodyText)} type={TextType.BODY_TEXT_LIGHT} tagProps={{ medium: { weight: TextWeight.MEDIUM, inline: true } }} />,
    yesButtonText: t('Confirm'),
    noButtonText: t('Cancel'),
    onAnswer: (answer) => {
      updateModal('confirmation', undefined)
      if (answer === YesNo.YES) {
        onConfirm()
      }
    },
  })
}

export const trimCode = (str: string) => str.replace(/\s+/g, ' ').trim()
