import React from 'react'

import * as HistoryInterface from 'history'
import { t } from 'i18next'

import { BulkModalProps } from '@components/BulkActionsModal/BulkActionsModal'
import Caution from '@components/Caution/Caution'
import { YesNo } from '@components/ConfirmationModal/index'
import { LoaderSize } from '@components/Spinner/Spinner'
import { Status } from '@components/StatusToast/StatusToast'
import { SvgNames } from '@components/Svg/index'
import { SvgColor } from '@components/Svg/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { EmailContentValidationsResponse, GetLastPublishedMessageContentQuery } from '@graphql/types/query-types'
import RequiredFieldsMissingModalBody from '@src/pages/EmailComposer/components/EmailComposerModalState/RequiredFieldsMissingModalBody/RequiredFieldsMissingModalBody'
import { prevPageLinks } from '@src/pages/EmailComposer/utils/EmailComposer.constants'
import { ContainerValuesRefType, PrevPageParamsEnum } from '@src/pages/EmailComposer/utils/EmailComposer.types'
import { separateSavePreCheckRequiredFields } from '@src/pages/EmailComposer/utils/EmailComposer.utils'
import { closeComposer } from '@src/pages/EmailComposer/utils/EmailComposerAPI.utils'
import { detectEmailType, getIdFromUrl } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { CommonComposerTab, SaveComposerCommonType } from '@utils/composer/commonComposer/CommonComposer.context'
import { ComposerHistoryState, EmailComposerAPI } from '@utils/composer/context/EmailComposer.context'
import { LandingPageRedirectUrl, LandingPageVisibilityStatus } from '@utils/composer/context/LandingPageComposer.context'
import { EmailComposerRequests } from '@utils/composer/emailComposer/GraphQL/EmailComposerRequests.graphQL'
import { ExtendedMessageType, messageConfigurationSettings, MessageConfigurationType } from '@utils/composer/emailComposer/types'
import { SAVED_AS_COPY_ID } from '@utils/composer/EmailModal.constants'
import { LandingPageComposerRequests } from '@utils/composer/landingPage/types'
import { isCustomerCareLogin } from '@utils/cookie'
import { logNewRelicError } from '@utils/new-relic.utils'
import { setLocalStorageItem } from '@utils/window'

const getPublishDescription = (messageType: ExtendedMessageType, isEmailRSS: boolean, isEmailABTest: boolean, isEmailBlankMessage: boolean) => {
  if (isEmailRSS || isEmailABTest || isEmailBlankMessage) {
    return t(`EmailComposer.ConfirmationModals.${messageType}.BodyText`)
  }
}

const onComposeAutoResponseDone = (id: string, subject: string, title: string) => {
  window.top?.opener?.onComposeAutoResponseDone({
    userSaved: true,
    msgid: id,
    subject,
    title,
  })
  window.top?.opener?.focus()
}

const onWebinarMessageSave = () => {
  setLocalStorageItem('messageSaved', `${Date.now()}`)
}

const { id: publishIdFromUrl } = getIdFromUrl(window.location.pathname)

const handleUnresolvedErrorsModal = (
  containerValuesRef: ContainerValuesRefType,
  updateModal: EmailComposerAPI['updateModal'],
  onTabChange: EmailComposerAPI['onTabChange'],
  saveComposerEmail: SaveComposerCommonType,
  update: EmailComposerAPI['update'],
  history: HistoryInterface.History<ComposerHistoryState>,
  enableEmailDraftsReact: boolean,
  shouldCloseComposer?: boolean
) => {
  const {
    validations: { errorsCount, warningsCount },
    message: { messageType, id, subject, title, publishId },
    messageConfiguration,
  } = containerValuesRef.current

  const errors = errorsCount ?? 0
  const warnings = warningsCount ?? 0

  const { isEmailWebinar, isEmailABTest, isEmailRSS, isEmailProgram, isEmailForm } = detectEmailType(messageType)

  const saveButton = messageConfiguration.saveButton

  const canPublish = messageConfiguration.canPublish

  if (errors + warnings > 0) {
    const errorText = t(`EmailComposer.UnresolvedErrorsModal.BodyText_Error`, { count: errors })
    const warningText = t(`EmailComposer.UnresolvedErrorsModal.BodyText_Warning`, { count: warnings })
    const andText = errors && warnings ? ` ${t('and')} ` : ''
    const context = errors ? 'error' : warnings ? 'warning' : ''

    updateModal('confirmation', {
      title: t(`EmailComposer.UnresolvedErrorsModal.title`, { context }),
      body: (
        <>
          <Typography
            text={t(`EmailComposer.UnresolvedErrorsModal${isEmailWebinar || isEmailABTest || isEmailRSS ? `.${messageType}` : ''}.BodyText`, {
              context,
              errorText: errors ? errorText : undefined,
              andText: andText,
              warningText: warnings ? warningText : undefined,
            })}
            type={TextType.BODY_TEXT_LIGHT}
            tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
            className="push-down-x2"
          />
          {!isEmailABTest && !isEmailRSS && !canPublish && (
            <Caution
              message={
                <Typography
                  text={t(`EmailComposer.ConfirmationModals.${messageType}.Caution`)}
                  type={TextType.BODY_TEXT_LIGHT}
                  tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
                />
              }
            />
          )}
        </>
      ),
      titleIcon: SvgNames.warning,
      titleIconFillColor: SvgColor.TEXT_GRAY,
      yesButtonText: t(saveButton?.separateDiscardAndPublish ? 'Publish anyway' : 'Save anyway'),
      noButtonText: t(
        saveButton?.separateDiscardAndPublish
          ? errors
            ? 'Review errors'
            : warnings
            ? 'Review warnings'
            : ''
          : errors
          ? 'Fix errors'
          : warnings
          ? 'Fix warnings'
          : ''
      ),
      isYesNo: true,
      onAnswer: (answer) => {
        if (answer === YesNo.YES) {
          const showAutoSaveIndicator =
            messageConfiguration.messageType === 'WEBINAR_MESSAGE' ||
            messageConfiguration.messageType === 'FORM_AUTO_RESPONSE' ||
            messageConfiguration.messageType === 'PROGRAM_MESSAGE'

          if (showAutoSaveIndicator && !canPublish) {
            update({ isIndicatingAutoSave: true })
          }
          const modifiedContainerValues = {
            ...containerValuesRef.current,
            message: {
              ...containerValuesRef.current.message,
              id: isEmailABTest || isEmailRSS ? publishId ?? id : canPublish ? publishIdFromUrl : id,
              templateJson: {
                ...containerValuesRef.current.message.templateJson,
                errorsCount,
              },
            },
          }

          const shouldSendErrorsCount = isEmailProgram || isEmailForm || isEmailABTest || isEmailRSS || isEmailWebinar || canPublish

          saveComposerEmail(shouldSendErrorsCount ? modifiedContainerValues : containerValuesRef.current).then(() => {
            if (isEmailProgram || isEmailABTest) {
              onComposeAutoResponseDone(isEmailABTest ? publishId ?? id : id, subject, title)
            }
            if (isEmailABTest || isEmailRSS) {
              if (window.opener) {
                if (isEmailRSS) {
                  window.opener.postMessage('Email Composer Saved', '*')
                }
                window.close()
              } else {
                history.push(prevPageLinks[isEmailABTest ? PrevPageParamsEnum.abTest : PrevPageParamsEnum.rss])
              }
            }
            if (isEmailWebinar) {
              if (window.opener) {
                onWebinarMessageSave()
                window.close()
              } else {
                history.push(prevPageLinks[PrevPageParamsEnum.webinar])
              }
            }
            if (shouldCloseComposer) {
              closeComposer(containerValuesRef, history, enableEmailDraftsReact)
            }
          })

          updateModal('confirmation', undefined)
          update({ haveUnsavedChanges: false })
        } else {
          updateModal('confirmation', undefined)
          containerValuesRef.current.tab !== 'review' && onTabChange(CommonComposerTab.REVIEW)
        }
      },
    })
  }
}

export const saveMessage = (
  checkOptOutValidPromise: (() => Promise<EmailContentValidationsResponse>) | undefined,
  containerValuesRef: ContainerValuesRefType,
  updateModal: EmailComposerAPI['updateModal'],
  onTabChange: EmailComposerAPI['onTabChange'],
  saveComposerEmail: SaveComposerCommonType,
  update: EmailComposerAPI['update'],
  history: HistoryInterface.History<ComposerHistoryState>,
  enableEmailDraftsReact: boolean,
  pureSave: boolean,
  /** To skip the visual indicators of saving */
  isSilentSave: boolean,
  newLPComposerCreateBlank?: boolean,
  bulkError?: BulkModalProps,
  LPpublished?: boolean,
  lastPublishedMessageData?: GetLastPublishedMessageContentQuery['getLastPublishedMessageContent']
): Promise<boolean | undefined> => {
  const {
    message: { id, subject, title, publishId, isPlainTextOnly },
    isSaving,
    haveUnsavedChanges,
    validations: { errorsCount, warningsCount },
    messageConfiguration: { messageType, canPublish },
    landingPage: { isLandingPage },
  } = containerValuesRef.current

  const paramsForSave = newLPComposerCreateBlank ? [undefined, undefined, LPpublished && newLPComposerCreateBlank] : []

  //For Landing Pages - if the LP is restored and there are unpublished changes, we need to use the last published message data
  const LPComputedContainerValues = {
    ...containerValuesRef.current,
    message: {
      ...containerValuesRef.current.message,
      templateHtml: lastPublishedMessageData?.html ?? '',
      templateJson: (lastPublishedMessageData?.json && JSON.parse(lastPublishedMessageData.json)) ?? '',
    },
  }

  const computedContainerValues =
    newLPComposerCreateBlank && isLandingPage && LPpublished && lastPublishedMessageData ? LPComputedContainerValues : containerValuesRef.current

  const handleSpinnerModal = (): Promise<boolean> => {
    return new Promise<boolean>((resolve) => {
      updateModal('spinner', { size: LoaderSize.LARGE })
      resolve(!isSaving)
    })
  }

  const errors = errorsCount ?? 0
  const warnings = warningsCount ?? 0

  const { isEmailProgram, isEmailABTest, isEmailRSS, isEmailWebinar, isEmailForm, isEmailBlankMessage, isEmailTemplate } =
    detectEmailType(messageType)

  // If it's a silent save, directly return the result of saveComposerEmail
  if (isSilentSave) {
    return saveComposerEmail(computedContainerValues, ...paramsForSave).then((status) => !!status)
  }

  // Handle spinner modal and proceed with the logic
  return handleSpinnerModal().then((spinnerStatus) => {
    if (bulkError?.errorMessages.length) {
      updateModal('bulkErrorModal', { ...bulkError })
      return undefined
    } else if (pureSave) {
      return saveComposerEmail(computedContainerValues, ...paramsForSave).then((saveStatus) => {
        if (saveStatus) {
          if (isEmailProgram) {
            onComposeAutoResponseDone(id, subject, title)
          }
          if (isEmailWebinar) {
            onWebinarMessageSave()
          }
        }
        setTimeout(() => {
          updateModal('spinner', undefined, !isLandingPage)
        }, 1500)

        return !!saveStatus
      })
    } else if (isEmailABTest || isEmailRSS || isEmailBlankMessage || canPublish) {
      if (errors + warnings > 0 && (!isSaving || haveUnsavedChanges)) {
        handleUnresolvedErrorsModal(containerValuesRef, updateModal, onTabChange, saveComposerEmail, update, history, enableEmailDraftsReact)
      } else {
        updateModal('confirmation', {
          title: `${t('Publish changes')}?`,
          body: (
            <Typography type={TextType.BODY_TEXT_LIGHT} text={getPublishDescription(messageType, isEmailRSS, isEmailABTest, isEmailBlankMessage)} />
          ),
          onAnswer: (answer: YesNo) => {
            if (answer === YesNo.YES) {
              const modifiedContainerValues = {
                ...containerValuesRef.current,
                message: {
                  ...containerValuesRef.current.message,
                  id: isEmailBlankMessage ? publishIdFromUrl : publishId ?? id,
                },
              }
              return saveComposerEmail(modifiedContainerValues).then((saveStatus) => {
                if (saveStatus) {
                  if (window.opener) {
                    if (isEmailRSS) {
                      window.opener.postMessage('Email Composer Saved', '*')
                    }
                    window.close()
                  } else {
                    history.push(prevPageLinks[isEmailABTest ? PrevPageParamsEnum.abTest : PrevPageParamsEnum.rss])
                  }
                }
                setTimeout(() => {
                  updateModal('spinner', undefined)
                }, 1500)
                return !!saveStatus
              })
            } else {
              updateModal('confirmation', undefined)
              return false
            }
          },
          isYesNo: true,
          yesButtonText: t('Publish changes'),
          noButtonText: t('Cancel'),
        })
      }
    } else {
      checkOptOutValidPromise &&
        checkOptOutValidPromise()
          .then(({ hasHtmlOptOut, hasTextOptOut }) => {
            const errors = separateSavePreCheckRequiredFields(containerValuesRef.current)

            if ((isPlainTextOnly && !hasTextOptOut) || (!isPlainTextOnly && !hasHtmlOptOut)) {
              errors.push('Opt-out link')
            }

            if (errors.length && !isCustomerCareLogin()) {
              updateModal('formTypeModal', {
                body: <RequiredFieldsMissingModalBody errors={errors} />,
                title: 'EmailComposer.Required.Fields.Missing.Modal.Title',
                confirmLabel: 'Fix Errors',
                onConfirm: () => {
                  updateModal('formTypeModal', undefined)
                  onTabChange(!hasHtmlOptOut ? CommonComposerTab.DESIGN : CommonComposerTab.SETTINGS)
                },
              })
            } else {
              setTimeout(() => {
                updateModal('spinner', undefined, false)
              }, 1500)

              const {
                validations: { errorsCount = 0, warningsCount = 0 },
                messageConfiguration: { saveButton: { separateSaveAndClose } = {} },
                haveUnsavedChanges,
              } = containerValuesRef.current

              const errorsTotal = errorsCount + warningsCount

              if (errorsTotal > 0 && !isEmailTemplate) {
                handleUnresolvedErrorsModal(containerValuesRef, updateModal, onTabChange, saveComposerEmail, update, history, enableEmailDraftsReact)
              } else if (haveUnsavedChanges && separateSaveAndClose) {
                updateModal('confirmation', {
                  title: t('Save your email changes?'),
                  body: (
                    <>
                      <Typography
                        type={TextType.BODY_TEXT_LIGHT}
                        text={t(`EmailComposer.Save.UnsavedChanges.Modal.Body.${isEmailForm || isEmailWebinar ? 'Form' : 'Program'}`)}
                        tagProps={{ medium: { weight: TextWeight.MEDIUM, inline: true } }}
                      />
                      <Caution
                        message={
                          <Typography
                            text={t(`EmailComposer.Save.UnsavedChanges.Modal.Caution.${isEmailForm || isEmailWebinar ? 'Form' : 'Program'}`)}
                            tagProps={{ medium: { weight: TextWeight.MEDIUM, inline: true } }}
                          />
                        }
                      />
                    </>
                  ),
                  onAnswer: (answer: YesNo) => {
                    if (answer === YesNo.YES) {
                      return saveComposerEmail(containerValuesRef.current).then((saveStatus) => {
                        if (isEmailProgram) {
                          onComposeAutoResponseDone(id, subject, title)
                        }
                        if (isEmailWebinar) {
                          onWebinarMessageSave()
                        }
                        return saveStatus
                      })
                    }
                    updateModal('confirmation', undefined)
                  },
                  isYesNo: true,
                  yesButtonText: t('Save changes'),
                  noButtonText: t('Cancel'),
                })
              } else {
                if (isEmailWebinar) {
                  update({ isIndicatingAutoSave: true })
                }
                return saveComposerEmail(containerValuesRef.current).then((saveStatus) => {
                  if (isEmailProgram) {
                    onComposeAutoResponseDone(id, subject, title)
                  }
                  if (isEmailWebinar) {
                    onWebinarMessageSave()
                  }
                  return saveStatus
                })
              }
            }
          })
          .catch(() => {
            updateModal('statusToast', {
              message: t('Failed to check Opt-out link'),
              status: Status.FAIL,
            })

            return false
          })
    }

    return spinnerStatus
  })
}

export const saveAsCopy =
  (
    containerValuesRef: ContainerValuesRefType,
    history: HistoryInterface.History<ComposerHistoryState>,
    createMessage: EmailComposerRequests['createMessage'],
    saveComposerEmail: SaveComposerCommonType,
    update: EmailComposerAPI['update'],
    createLandingPage?: LandingPageComposerRequests['createLandingPage'],
    updateModal?: EmailComposerAPI['updateModal'],
    updatedTitle?: string,
    newLPComposerCreateBlank?: boolean
  ) =>
  async () => {
    const title = containerValuesRef.current.message.title
    let saveId = ''

    if (title) {
      let messageType = containerValuesRef.current.message.messageType
      const { isEmailWebinar, isLandingPage } = detectEmailType(messageType)
      const isLandingPageSaveAsCopy = isLandingPage && newLPComposerCreateBlank

      if (isEmailWebinar) {
        messageType = 'DRAFT'
      }

      if (isLandingPageSaveAsCopy) {
        const { folderIdToClone, name = '', tags = [] } = containerValuesRef.current.landingPage.createLandingPageFields ?? {}
        if (createLandingPage) {
          const response = await createLandingPage?.(name, tags, folderIdToClone)
          const id = response?.id
          saveId = id
          // close immediately after creating LP
          if (id) {
            const lastPublishedMessageData = containerValuesRef.current.landingPage.lastPublishedMessageData
            if (lastPublishedMessageData) {
              const { html, json } = lastPublishedMessageData ?? {}
              json && update?.({ message: { templateHtml: html, templateJson: JSON.parse(json) } })
            }

            update({
              message: { id },
              landingPage: {
                landingPageMetaDescription: '',
                landingPageMetaImage: undefined,
                landingPageVisibility: LandingPageVisibilityStatus.SHOW,
                hasUnpublishedChanges: false,
                isLandingPagePublished: false,
                lastPublishedTime: undefined,
                landingPageUrlType: LandingPageRedirectUrl.GLOBAL,
              },
            })
            updateModal?.('duplicateModal', undefined)
          }
        }
      } else {
        const { id } =
          (await createMessage({
            messageType,
            fromDefault: false,
            isPlainTextOnly: containerValuesRef.current.message.isPlainTextOnly,
            isCustomHtml: containerValuesRef.current.detectedURLChanges.uploadHtmlMode,
          })) ?? ''

        saveId = id
      }

      const titleWithCopyText = `${title} (copy)`
      const newTitle = newLPComposerCreateBlank && isLandingPage ? updatedTitle || titleWithCopyText : titleWithCopyText
      const isCopySaved = await saveComposerEmail(
        {
          ...containerValuesRef.current,
          message: {
            ...containerValuesRef.current.message,
            messageType,
            id: saveId,
            title: newTitle,
          },
        },
        false,
        true
      )

      if (isCopySaved) {
        history.replace(containerValuesRef.current.tab, { [SAVED_AS_COPY_ID]: saveId })

        update({
          shouldStartNewSession: true,
          tab: CommonComposerTab.SETTINGS,
          message: { title: newTitle, id: saveId, messageType },
          modalState: {
            statusToast: {
              status: Status.SUCCESS,
              message: (
                <Typography
                  text={isLandingPageSaveAsCopy ? 'LandingPage.Save.AsCopy' : 'EmailComposer.Save.AsCopy'}
                  tagProps={{ bold: { weight: TextWeight.BOLD, className: undefined } }}
                  values={{ title: newTitle }}
                  className="inset-x2"
                  inline
                />
              ),
            },
          },
        })
      }
    }
  }

export const saveAsDraft =
  (
    containerValuesRef: ContainerValuesRefType,
    createMessage: EmailComposerRequests['createMessage'],
    saveComposerEmail: SaveComposerCommonType,
    update: EmailComposerAPI['update'],
    history?: HistoryInterface.History<ComposerHistoryState>
  ) =>
  async () => {
    const title = containerValuesRef.current.message.title
    if (title) {
      const { id } =
        (await createMessage({
          messageType: 'DRAFT',
          fromDefault: false,
          isPlainTextOnly: containerValuesRef.current.message.isPlainTextOnly,
          isCustomHtml: containerValuesRef.current.detectedURLChanges.uploadHtmlMode,
        })) ?? ''

      const isDraftSaved = await saveComposerEmail({
        ...containerValuesRef.current,
        message: {
          ...containerValuesRef.current.message,
          suppress_dups: true,
          id,
          messageType: 'DRAFT',
        },
      })

      if (isDraftSaved) {
        history?.replace({ pathname: location.pathname, search: `?messageConfigurationType=draft` })
        update({
          loading: true,
          tab: CommonComposerTab.SETTINGS,
          message: { messageType: 'DRAFT', id, suppress_dups: true },
          modalState: {
            statusToast: {
              status: Status.SUCCESS,
              message: (
                <Typography
                  text="Template.Saved.As.Draft"
                  tagProps={{ bold: { weight: TextWeight.BOLD, className: undefined } }}
                  values={{ title }}
                  className="inset-x2"
                  inline
                />
              ),
            },
          },
          messageConfiguration: messageConfigurationSettings[MessageConfigurationType.DRAFT],
        })
      }
    }
  }

export const saveAsTemplate =
  (
    containerValuesRef: ContainerValuesRefType,
    createMessage: EmailComposerRequests['createMessage'],
    saveComposerEmail: SaveComposerCommonType,
    update: EmailComposerAPI['update'],
    history?: HistoryInterface.History<ComposerHistoryState>
  ) =>
  async () => {
    const title = containerValuesRef.current.message.title
    if (title) {
      const { id } =
        (await createMessage({
          messageType: 'TEMPLATE',
          fromDefault: false,
          isPlainTextOnly: containerValuesRef.current.message.isPlainTextOnly,
          isCustomHtml: containerValuesRef.current.detectedURLChanges.uploadHtmlMode,
        })) ?? ''

      const isTemplateSaved = await saveComposerEmail({
        ...containerValuesRef.current,
        message: {
          ...containerValuesRef.current.message,
          overrideFatigueRules: false,
          id,
          messageType: 'TEMPLATE',
        },
      })

      if (isTemplateSaved) {
        history?.replace({ pathname: location.pathname, search: `?messageConfigurationType=template` })
        update({
          loading: true,
          tab: CommonComposerTab.SETTINGS,
          message: {
            messageType: 'TEMPLATE',
            id,
            // Addressing the situation when a message can be saved as a template first, then as a draft
            isCrmLaunch: undefined,
            isCrmSentNote: undefined,
            overrideFatigueRules: false,
            sendto_lists: undefined,
            sendto_contacts: undefined,
            suppress_dups: undefined,
            suppress_lists: undefined,
            sendMethod: { sendtype: undefined },
          },
          modalState: {
            statusToast: {
              status: Status.SUCCESS,
              message: (
                <Typography
                  text="Draft.Saved.As.Template"
                  tagProps={{ bold: { weight: TextWeight.BOLD, className: undefined } }}
                  values={{ title }}
                  className="inset-x2"
                  inline
                />
              ),
            },
          },
          messageConfiguration: messageConfigurationSettings[MessageConfigurationType.TEMPLATE],
        })
      }
    }
  }

export const convertToTemplate = (
  containerValuesRef: ContainerValuesRefType,
  createMessage: EmailComposerRequests['createMessage'],
  deleteEmailDrafts: EmailComposerRequests['deleteEmailDrafts'],
  saveComposerEmail: SaveComposerCommonType,
  update: EmailComposerAPI['update'],
  history?: HistoryInterface.History<ComposerHistoryState>
) => {
  const originalDraftId = containerValuesRef.current.message.id

  return async (discardDraft: boolean) => {
    let messageId = originalDraftId

    const { id } =
      (await createMessage({
        messageType: 'TEMPLATE',
        fromDefault: false,
        isPlainTextOnly: containerValuesRef.current.message.isPlainTextOnly,
        isCustomHtml: containerValuesRef.current.detectedURLChanges.uploadHtmlMode,
      })) ?? ''

    if (id) {
      messageId = id
    } else {
      logNewRelicError({ errors: 'Failed to create message' })
      return { errors: 'Failed to create message' }
    }

    if (discardDraft) {
      if (messageId) {
        const isDraftSaved = await saveComposerEmail({
          ...containerValuesRef.current,
          message: {
            ...containerValuesRef.current.message,
            id: messageId,
            messageType: 'TEMPLATE',
            isTriggered: true,
          },
        })

        if (!isDraftSaved) {
          logNewRelicError({ errors: 'Failed to save composer email' })
          return { errors: 'Failed to save composer email' }
        } else {
          history?.replace({ pathname: location.pathname, search: '?messageConfigurationType=template' })
        }
      }

      const isDeleted = await deleteEmailDrafts(containerValuesRef.current.message.id)

      if (isDeleted?.deleteEmailDrafts.failedIds.length) {
        logNewRelicError({ errors: 'Failed to delete email drafts' })
        return { errors: 'Failed to delete email drafts' }
      }

      update({
        message: { id: messageId, messageType: 'TEMPLATE', isTriggered: true },
        messageConfiguration: messageConfigurationSettings[MessageConfigurationType.TEMPLATE],
      })
    } else {
      const isDraftSaved = await saveComposerEmail(containerValuesRef.current)

      if (!isDraftSaved) {
        logNewRelicError({ errors: 'Failed to save draft' })
        return { errors: 'Failed to save draft' }
      } else {
        const isTemplateSaved = await saveComposerEmail({
          ...containerValuesRef.current,
          message: {
            ...containerValuesRef.current.message,
            id: messageId,
            messageType: 'TEMPLATE',
            isTriggered: true,
          },
        })

        if (isTemplateSaved) {
          history?.replace({ pathname: location.pathname, search: '?messageConfigurationType=template' })
          update({
            message: { id: messageId, messageType: 'TEMPLATE', isTriggered: true },
            messageConfiguration: messageConfigurationSettings[MessageConfigurationType.TEMPLATE],
          })
        } else {
          logNewRelicError({ errors: 'Failed to save template' })
          return { errors: 'Failed to save template' }
        }
      }
    }

    return { data: 'success' }
  }
}
