import React, { FC } from 'react'
import { FieldError, useFormContext } from 'react-hook-form'

import classNames from 'classnames'

import { InputDefinition } from '@graphql/types/microservice/webhooks-incoming-management-types'
import IncomingWebhookInput from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/components/IncomingWebhookAuthentication/components/IncomingWebhookInput/IncomingWebhookInput'

import './IncomingWebhookBasicAuthentication.css'

interface IncomingWebhookBasicAuthenticationProps {
  className?: string
  dataTest?: string
}

enum BasicAuthenticationField {
  USERNAME = 'Username',
  PASSWORD = 'Password',
}

const rootClass = 'incoming-webhook-basic-authentication'
const rootTranslation = 'Incoming.Webhooks.CreateModal.Authentication.Basic'

const IncomingWebhookBasicAuthentication: FC<IncomingWebhookBasicAuthenticationProps> = (props: IncomingWebhookBasicAuthenticationProps) => {
  const { dataTest = rootClass, className = '' } = props

  const {
    register,
    formState: { errors },
  } = useFormContext<InputDefinition>()

  const renderInputLabel = (field: BasicAuthenticationField) => {
    const fieldRelatedData: {
      [key in BasicAuthenticationField]: {
        error: FieldError | undefined
        name: 'authentication.password' | 'authentication.username'
        isPassword?: boolean
      }
    } = {
      [BasicAuthenticationField.PASSWORD]: { isPassword: true, error: errors?.authentication?.password, name: 'authentication.password' },
      [BasicAuthenticationField.USERNAME]: { error: errors?.authentication?.username, name: 'authentication.username' },
    }

    const lowerCaseField = field.toLowerCase()
    const inputProps = {
      ...fieldRelatedData[field],
      dataTest: `${dataTest}-input-${lowerCaseField}`,
      field,
      placeholder: `Enter a ${lowerCaseField}`,
      register,
      rootTranslation,
    }

    return <IncomingWebhookInput {...inputProps} />
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {renderInputLabel(BasicAuthenticationField.USERNAME)}
      {renderInputLabel(BasicAuthenticationField.PASSWORD)}
    </div>
  )
}

export default IncomingWebhookBasicAuthentication
