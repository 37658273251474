import { gql } from '@apollo/client'

export default gql`
  query standardFields {
    standardFields {
      dataType
      hidden
      id
      key
      label
      readOnly
      required
      unmappable
    }
  }
`
