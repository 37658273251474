import { SendTimeType } from '@utils/composer/context/EmailComposer.context'

export interface SendLaterOption {
  id: SendTimeType
  label: string
  text: string
  tooltip?: string
  tooltipLink?: string
}

export const CHOOSE_ARTICLE_LINK = 'https://connect.act-on.com/hc/en-us/articles/360023760114-Choosing-an-Email-Send-Method'

export const sendLaterOptions: SendLaterOption[] = [
  {
    id: SendTimeType.LATER,
    label: 'SendDetails.SendMethod.Options.Schedule.Label',
    text: 'SendDetails.SendMethod.Options.Schedule.Text',
  },
  {
    id: SendTimeType.STAGGERED,
    label: 'SendDetails.SendMethod.Options.OverTime.Label',
    text: 'SendDetails.SendMethod.Options.OverTime.Text',
    tooltip: 'SendDetails.SendMethod.Options.OverTime.Tooltip',
    tooltipLink: 'https://connect.act-on.com/hc/en-us/articles/360023760114-Choosing-an-Email-Send-Method#01H8KECHDF835KDGTJ9A51NZA4',
  },
  {
    id: SendTimeType.ADAPTIVE,
    label: 'SendDetails.SendMethod.Options.Adaptive.Label',
    text: 'SendDetails.SendMethod.Options.Adaptive.Text',
    tooltip: 'SendDetails.SendMethod.Options.Adaptive.Tooltip',
    tooltipLink: 'https://connect.act-on.com/hc/en-us/articles/360023760114-Choosing-an-Email-Send-Method#01H8KECHDFKGSYRSZ68VG2JX4S',
  },
]
