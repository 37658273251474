import React, { FC, useEffect, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import DraggableColumnActions from '@components/ColumnsOrderModal/components/DraggableColumnActions/DraggableColumnActions'
import { isOverUpperHalf } from '@components/ColumnsOrderModal/utils/ColumnsOrderModal.utils'
import ActonContactsFieldIcon from '@components/EditColumnsDropdown/components/ActonContactsFieldIcon/ActonContactsFieldIcon'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { LineHeight } from '@components/Typography/Typography'
import { UnifiedContactListColumn } from '@graphql/types/query-types'
import { ItemType } from '@utils/categorization'

import './DraggableColumn.css'

export enum ColumnType {
  ACTON_CONTACTS = 'Act-On Contacts Fields',
  FORM = 'Form fields',
  FORM_SUBMISSION = 'Form submission data',
  GEOIP = 'GeoIP data',
}

export interface Column {
  id: number
  index: number
  name: string
  status?: ColumnStatus
  newColumn?: UnifiedContactListColumn
  type: ColumnType
}

interface Props {
  className?: string
  dataTest?: string
  column: Column
  onDrop: (droppedColumn: Column, droppedAt: number) => void
  onLock: (column: Column) => void
  onHide?: (column: Column) => void
  onRemove?: (column: Column) => void
  disableLock?: boolean
  disableHide?: boolean
  listingPosition?: number
}

export enum ColumnStatus {
  DEFAULT = 'default',
  HIDDEN = 'hidden',
  LOCKED = 'locked',
}

const icons = {
  [ColumnStatus.DEFAULT]: undefined,
  [ColumnStatus.LOCKED]: SvgNames.lock,
  [ColumnStatus.HIDDEN]: SvgNames.hide,
}

const rootClass = 'draggable-column'

const DraggableColumn: FC<Props> = (props: Props) => {
  const {
    dataTest = rootClass,
    className = '',
    column,
    onDrop,
    onLock,
    onHide,
    onRemove,
    disableLock = false,
    disableHide = false,
    listingPosition = 0,
  } = props
  const { index, name, status = ColumnStatus.DEFAULT } = column
  const statusIcon = icons[status]

  const [isOverTop, setIsOverTop] = useState(false)
  const dropRef = useRef<HTMLDivElement>(null)

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: ItemType.TABLE_COLUMN,
      item: column,
      collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    }),
    [column]
  )

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemType.TABLE_COLUMN,
    drop: (item: Column) => onDrop(item, isOverTop ? index : index + 1),
    hover: (_, monitor) => setIsOverTop(isOverUpperHalf(dropRef, monitor)),
    canDrop: ({ status: itemStatus }) => itemStatus === status || (status !== ColumnStatus.LOCKED && itemStatus !== ColumnStatus.LOCKED),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  drop(dropRef)

  return (
    <div
      className={classNames(rootClass, className, {
        [`${rootClass}__over`]: canDrop && isOver && !isOverTop,
        [`${rootClass}__over-top`]: canDrop && isOver && isOverTop,
      })}
      data-test={`${dataTest}-${index}__drop`}
      ref={dropRef}
    >
      <Typography className={classNames(`${rootClass}__index`, 'ellip')} text={`${listingPosition}.`} />
      <div
        data-test={`${dataTest}-${index}__drag`}
        className={classNames(`${rootClass}__column`, {
          [`${rootClass}__column-${status}`]: status !== ColumnStatus.DEFAULT,
          [`${rootClass}__column-dragged`]: isDragging,
        })}
        ref={drag}
      >
        <div className={`${rootClass}__column-drag`}>
          <Svg className={`${rootClass}__column-drag-icon`} name={SvgNames.drag} type={SvgType.SMALLER_ICON} />
        </div>
        {column.type === ColumnType.ACTON_CONTACTS && <ActonContactsFieldIcon />}
        <Typography className={classNames(`${rootClass}__column-name`, 'ellip')} text={name} lineHeight={LineHeight.MEDIUM_LARGE} />
        {onRemove && (
          <div className={`${rootClass}__column-remove`}>
            <Button buttonType={ButtonType.ICON} onClick={() => onRemove(column)}>
              <Svg name={SvgNames.clearIndicator} />
            </Button>
          </div>
        )}
        {statusIcon && (
          <Svg
            className={`${rootClass}__column-status-${status}`}
            dataTest={`${dataTest}__status-${status}`}
            name={statusIcon}
            type={SvgType.MEDIUM}
          />
        )}
      </div>
      <DraggableColumnActions
        className={`${rootClass}__actions`}
        status={status}
        onHide={onHide ? () => onHide(column) : undefined}
        onLock={() => onLock(column)}
        disableLock={disableLock}
        disableHide={disableHide}
      />
    </div>
  )
}

export default DraggableColumn
