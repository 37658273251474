import { gql } from '@apollo/client'

export default gql`
  query getSmsMessageChat($launchId: String!, $accountId: Long!, $includeReplies: Boolean!, $recId: String!) {
    getSmsMessageChat(launchId: $launchId, accountId: $accountId, includeReplies: $includeReplies, recId: $recId) {
      smsMessage {
        smsMessageText
        timestamp
      }
      smsReplies {
        smsReplyText
        timestamp
      }
    }
  }
`
