import * as yup from 'yup'

import { ApolloClient } from '@apollo/client'
import getContactSettings from '@graphql/microservices/segment/getContactSettings'
import setContactSettings from '@graphql/microservices/segment/setContactSettings'
import searchListsByIdsQuery from '@graphql/queries/searchListsByIds'
import {
  ContactSettingsDto,
  ContactSettingsDtoInput,
  GetContactSettingsQuery,
  GetContactSettingsQueryVariables,
  Rule,
  SalesSegment,
  SetContactSettingsMutation,
  SetContactSettingsMutationVariables,
} from '@graphql/types/microservice/segment-types'
import { SearchListsByIdsQuery, SearchListsByIdsQueryVariables } from '@graphql/types/query-types'
import { ContactSettings } from '@src/pages/Settings/OtherSettings/ContactSettings/ContactSettings'

export const SOFT_BOUNCES_ALLOWED_OPTIONS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20]

const SPLIT_PATTERN = /\r\n|\r|\n| |,|;/
const DOMAIN_PATTERN = /^(?!www\.)([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})$/i
const EMAIL_PATTERN =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i

export enum ContactSettingsFields {
  SOFT_BOUNCES_ALLOWED = 'softBouncesAllowed',
  EMAIL_SUPPRESSION_DOMAIN = 'emailSuppressionDomain',
  FATIGUE_RULES = 'fatigueRules',
  ALLOWED_CONTACTS = 'allowedContacts',
  CONTACT_OWNER_EMAIL = 'contactOwnerEmail',
  CONTACT_OWNER_NAME = 'contactOwnerName',
  OPPORTUNITIES_SEGMENT_ID = 'opportunitiesSegmentId',
  PROSPECTS_SEGMENT_ID = 'prospectsSegmentId',
  CONTACTS_SEGMENT_ID = 'contactsSegmentId',
}

const fatigueRulesFieldsValidator = yup.number().typeError('Number is required').min(0, 'Must be positive').integer('Must be an integer').required()

export enum ContactOwnerType {
  CrmModel = 'CRM_MODEL',
  IdentifiedColumns = 'IDENTIFIED_COLUMNS',
  None = 'NONE',
}

export const getInvalidDomains = (value: string) => {
  return value.split(SPLIT_PATTERN).filter((domain) => domain !== '' && !domain.match(DOMAIN_PATTERN))
}
export const contactSettingsValidators = yup.object().shape({
  [ContactSettingsFields.SOFT_BOUNCES_ALLOWED]: yup.number().oneOf(SOFT_BOUNCES_ALLOWED_OPTIONS),
  [ContactSettingsFields.EMAIL_SUPPRESSION_DOMAIN]: yup.string().test('domain', 'You can only use valid domains, such as ', (value) => {
    const domains = value?.split(SPLIT_PATTERN).filter((domain) => domain !== '') || []
    const validDomains = domains.filter((domain) => domain.match(DOMAIN_PATTERN))
    return validDomains.length === domains.length
  }),
  [ContactSettingsFields.ALLOWED_CONTACTS]: yup.string().test('email', 'Must be a valid email', (value) => {
    const emails = value?.split(SPLIT_PATTERN).filter((email) => email !== '') || []
    const validEmails = emails.filter((email) => email.match(EMAIL_PATTERN))
    return validEmails.length === emails.length
  }),
  [ContactSettingsFields.FATIGUE_RULES]: yup.array().of(
    yup.object().shape({
      maxMessages: fatigueRulesFieldsValidator,
      timespan: fatigueRulesFieldsValidator,
    })
  ),
  [ContactSettingsFields.CONTACT_OWNER_EMAIL]: yup.number(),
  [ContactSettingsFields.CONTACT_OWNER_NAME]: yup.number(),
})

const parseSettings = ({ inactiveCount, suppressedDomains, rules, contactOwner, salesSegment }: ContactSettingsDto): ContactSettings => ({
  softBouncesAllowed: inactiveCount ?? 5,
  emailSuppressionDomain: suppressedDomains?.join('\n') ?? '',
  fatigueRules: (rules as Rule[]) ?? [],
  contactOwnerName: contactOwner?.nameColumnIndex,
  contactOwnerEmail: contactOwner?.emailColumnIndex,
  prospectsSegmentId: salesSegment?.prospectsSegmentId ?? '',
  contactsSegmentId: salesSegment?.contactsSegmentId ?? '',
  opportunitiesSegmentId: salesSegment?.opportunitySegmentId ?? '',
})

export const getContactSettingsUtils = (client: ApolloClient<any>): Promise<ContactSettings> => {
  return client
    .query<GetContactSettingsQuery, GetContactSettingsQueryVariables>({
      query: getContactSettings,
      fetchPolicy: 'network-only',
    })
    .then(({ data: { getContactSettings } }) => (getContactSettings ? parseSettings(getContactSettings) : ({} as ContactSettings)))
}

export const searchListByIds = (client: ApolloClient<any>, listId: string) => {
  return client.query<SearchListsByIdsQuery, SearchListsByIdsQueryVariables>({
    query: searchListsByIdsQuery,
    fetchPolicy: 'network-only',
    variables: {
      ids: listId,
    },
  })
}

export const saveContactSettings = (
  {
    emailSuppressionDomain,
    softBouncesAllowed,
    fatigueRules,
    contactOwnerName,
    contactOwnerEmail,
    prospectsSegmentId,
    contactsSegmentId,
    opportunitiesSegmentId,
  }: ContactSettings,
  client: ApolloClient<any>
) => {
  const contactOwner = {
    contactOwnerType:
      Number(contactOwnerEmail) === -1 && Number(contactOwnerName) === -1 ? ContactOwnerType.None : ContactOwnerType.IdentifiedColumns,
    emailColumnIndex: Number(contactOwnerEmail),
    nameColumnIndex: Number(contactOwnerName),
  }

  const salesSegment: SalesSegment = {
    prospectsSegmentId: prospectsSegmentId,
    contactsSegmentId: contactsSegmentId,
    opportunitySegmentId: opportunitiesSegmentId,
  }

  const contactSettings: ContactSettingsDtoInput = {
    suppressedDomains: emailSuppressionDomain.split(SPLIT_PATTERN),
    inactiveCount: Number.isNaN(softBouncesAllowed) ? 0 : softBouncesAllowed,
    rules: fatigueRules ?? [],
    contactOwner: contactOwner,
    salesSegment: salesSegment,
  }

  return setContactSettingsUtils(contactSettings, client)
}

const setContactSettingsUtils = (contactSettings: ContactSettingsDtoInput, client: ApolloClient<any>): Promise<boolean> => {
  return client
    .mutate<SetContactSettingsMutation, SetContactSettingsMutationVariables>({
      mutation: setContactSettings,
      variables: {
        contactSettings,
      },
    })
    .then(({ data }) => !!data?.setContactSettings)
}
