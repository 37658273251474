import React, { FC } from 'react'

import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import ModalFooterV2 from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import { SvgNames } from '@components/Svg'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

interface TemplateCatalogModalFooterProps {
  onSubmit: () => void
  onCancel: () => void
  onPreview: () => void
  isPreview: boolean
  selectedTitle?: string
  dataTest?: string
  previewLoading?: boolean
  createTemplateLoading?: boolean
}

const rootClass = 'template-catalog-modal__footer'

const TemplateCatalogModalFooter: FC<TemplateCatalogModalFooterProps> = ({
  onSubmit,
  onCancel,
  onPreview,
  selectedTitle,
  isPreview,
  previewLoading,
  createTemplateLoading,
  dataTest = rootClass,
}) => {
  const { t } = useTranslation()

  return (
    <ModalFooterV2
      dataTest={dataTest}
      className={rootClass}
      {...(!createTemplateLoading && {
        viewSelected: {
          noSelectionText: !isPreview && t('No template selected'),
          noSelection: !selectedTitle || isPreview,
          icon: SvgNames.zoom,
          label: t('Preview selection'),
          currentText: (
            <TextWithTooltipOnEllip
              typographyProps={{
                text: 'Bold.Name.Selected',
                type: TextType.BODY_TEXT_SMALL_LIGHT,
                tagProps: { bold: { weight: TextWeight.BOLD, inline: true, lineHeight: LineHeight.MEDIUM_SMALL } },
                values: { name: selectedTitle },
              }}
              tooltipProps={{ children: selectedTitle }}
              className={`${rootClass}__preview-selected-text`}
            />
          ),
          isViewSelected: false,
          onClick: onPreview,
        },
      })}
      buttons={{
        cancelButtonLabel: t('Cancel'),
        actionButtonOnClick: onSubmit,
        actionButtonLabel: t('Use template'),
        actionButtonLoading: createTemplateLoading || previewLoading,
        actionButtonDisabled: !selectedTitle || previewLoading,
      }}
      footerType={ModalFooterType.List}
      onClose={onCancel}
    />
  )
}

export default TemplateCatalogModalFooter
