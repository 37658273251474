import React, { ChangeEvent, FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import FormRow from '@components/FormRow/FormRow'
import { InputProps } from '@components/Input/Input'
import InputWithMaxLength from '@components/InputWithMaxLength/InputWithMaxLength'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { ModalBodyStyle } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import { NO_COLOR, TagColors } from '@utils/tags'

import { ModalHeaderType } from '../Modal/components/ModalHeader'

import './ManageTagModal.css'

interface Props {
  className?: string
  dataTest?: string
  isOpen: boolean
  onClose: () => void
  onCreate: (tag: LabelDto) => void
  tag?: LabelDto
}

interface State {
  name: string
  color: string
  id?: number
}

const rootClass = 'manage-tag-modal'

const INPUT_MAX_LENGTH = 35

const ManageTagModal: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, onClose, isOpen, onCreate, tag, className = '' } = props
  const [state, setState] = useState<State>({
    name: '',
    color: NO_COLOR,
  })
  const { name, color, id } = state
  const { t } = useTranslation()

  const onCreateClick = () => {
    const newTag: LabelDto = id ? { name, color, id } : { name, color }
    onCreate(newTag)
  }

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value.trim()
    setState({ ...state, name })
  }

  useEffect(() => {
    if (tag) {
      setState({ ...state, name: tag.name || '', color: tag.color || NO_COLOR, id: tag.id })
    }
  }, [tag])

  const renderColorPicker = () => {
    return Object.keys(TagColors).map((color) => {
      const name = TagColors[color].name
      const isNoColor = color === NO_COLOR
      const selected = color === state.color || (isNoColor && state.color === '')
      return (
        <div
          key={color}
          className={classNames(`${rootClass}__color-container`, {
            [`${rootClass}__color-container-selected`]: selected,
          })}
        >
          <Tooltip
            dataTest={`${dataTest}-color-${color}`}
            position="top"
            trigger={
              <div
                role={'button'}
                tabIndex={0}
                onKeyDown={(keyDownEvent) => (keyDownEvent.key === ' ' || keyDownEvent.key === 'Enter' ? setState({ ...state, color }) : undefined)}
                data-test={`${dataTest}-color-chooser-${color}`}
                className={classNames(`${rootClass}__color-chooser`, {
                  [`${rootClass}__color-chooser-no-color`]: isNoColor,
                })}
                style={{ backgroundColor: isNoColor ? '#FFFFFF' : color }}
                onClick={() => setState({ ...state, color })}
              >
                {selected && <Svg name={SvgNames.check} type={SvgType.LARGER_ICON} />}
              </div>
            }
          >
            {t(name)}
          </Tooltip>
        </div>
      )
    })
  }

  const header = <ModalHeader headerType={ModalHeaderType.Form}>{t(tag ? 'Edit Tag' : 'New Tag')}</ModalHeader>
  const inputProps: InputProps = {
    placeholder: t('Tag name'),
    iconType: SvgType.LARGER_ICON,
    icon: SvgNames.tag,
    defaultValue: name,
    onChange: onNameChange,
    dataTest: `${rootClass}-input`,
  }

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header} paddingV2>
      <ModalBody>
        <FormRow>
          <Typography className={`${rootClass}__label`} {...ModalBodyStyle} text={t('Enter a new tag name:')} />
          <InputWithMaxLength
            className={`${rootClass}__input-${TagColors[color]?.className ?? TagColors[NO_COLOR].className}`}
            inputProps={inputProps}
            maxLength={INPUT_MAX_LENGTH}
          />
        </FormRow>
        <FormRow>
          <Typography className={`${rootClass}__label`} {...ModalBodyStyle} text={t('Choose a tag color:')} />
          <div className={`${rootClass}__color-picker-container`}>{renderColorPicker()}</div>
        </FormRow>
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form}>
        <Button buttonType={ButtonType.TERTIARY} onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} disabled={!name} onClick={onCreateClick}>
          {t(tag ? 'Save' : 'Create')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ManageTagModal
