import React, { FC, useEffect, useRef, useState } from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import classNames from 'classnames'
// eslint-disable-next-line no-restricted-imports
import { format, isToday } from 'date-fns'
import 'snapsvg-cjs'

import ButtonWithLoader from '@components/ButtonWithLoader/ButtonWithLoader'
import Popover from '@components/Popover/Popover'
import Svg, { SvgNames } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useAccountSettings } from '@utils/account/account.utils'
import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'
import { AUTOSAVE_ALL_CHANGES_SAVED_DELAY, AUTOSAVE_DONE_DELAY, AUTOSAVE_SUCCESS_DELAY } from '@utils/composer/EmailModal.constants'
import { useTranslation } from '@utils/const/globals'
import { isCustomerCareLogin } from '@utils/cookie'
import { getFormattedTime } from '@utils/date'
import { useTimeout } from '@utils/hooks/useTimeout'
import { useWindowBlur } from '@utils/hooks/useWindowBlur'

import './AutoSaveIndicator.css'

export enum AutoSaveStatus {
  SUCCESS = 'success',
  FAIL = 'fail',
}

declare const Snap: any
const rootClass = 'auto-save-indicator'

const AutoSaveIndicator: FC = () => {
  const {
    values: {
      isIndicatingAutoSave,
      autoSaveFailure,
      lastEdited,
      disabledFeatures,
      messageConfiguration: { saveButton },
    },
    api: { onAutoSave, update, updateModal },
  } = useComposerContext()

  const { separateSaveAndClose, separateDiscardAndPublish } = saveButton ?? {}

  const progressBarRef = useRef<HTMLDivElement>(null)
  const { accountTimeZoneId } = useAccountSettings()

  const [autoSaveLoading, setAutoSaveLoading] = useState<boolean>(false)
  const [autoSaveStatus, setAutoSaveStatus] = useState<AutoSaveStatus>(AutoSaveStatus.SUCCESS)
  const [allChangesSaved, setAllChangesSaved] = useState<boolean>(true)
  const [lastSavedTime, setLastSavedTime] = useState<string>('')
  const [text, setText] = useState<string>('')
  const [showCloudCheckSvg, setShowCloudCheckSvg] = useState<boolean>(false)
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)

  const lastPublishedRef = useRef<number | undefined>(lastEdited)

  const { t } = useTranslation()

  const successTimer = useTimeout(AUTOSAVE_SUCCESS_DELAY)
  const changesSavedTimer = useTimeout(AUTOSAVE_ALL_CHANGES_SAVED_DELAY)
  const doneTimer = useTimeout(AUTOSAVE_DONE_DELAY)

  const isAnimating = successTimer.isRunning || changesSavedTimer.isRunning || doneTimer.isRunning

  useWindowBlur(isPopoverOpen, setIsPopoverOpen)

  useEffect(() => {
    if (autoSaveLoading) {
      const svg = Snap('.save-indicator-progress-bar')
      setShowCloudCheckSvg(false)

      if (svg) {
        const progress = svg.select('#progress')

        progress.attr({ r: 15, fill: 'none', stroke: '#00babe', strokeWidth: 3, strokeDasharray: '0, 100' })
        Snap.animate(
          0,
          100,
          (value: number) => {
            progress.attr({ 'stroke-dasharray': `${value}, 100` })

            if (value === 100) {
              setShowCloudCheckSvg(true)
            }
          },
          1200
        )
      }
    }
  }, [autoSaveLoading])

  useEffect(() => {
    const setOnline = () => setAutoSaveStatus(AutoSaveStatus.SUCCESS)
    const setOffline = () => setAutoSaveStatus(AutoSaveStatus.FAIL)

    window.addEventListener('online', setOnline)
    window.addEventListener('offline', setOffline)

    return () => {
      window.removeEventListener('online', setOnline)
      window.removeEventListener('offline', setOffline)
    }
  }, [])

  useEffect(() => {
    if (isIndicatingAutoSave) {
      animateIndicator().then(() => {
        update({ isIndicatingAutoSave: false, loading: false, lastEdited: Date.now() })
      })
    }
  }, [isIndicatingAutoSave])

  useEffect(() => {
    if (!lastPublishedRef.current) {
      lastPublishedRef.current = lastEdited
    }
  }, [lastEdited])

  useEffect(() => {
    if (autoSaveFailure) {
      successTimer.clear()
      changesSavedTimer.clear()
      doneTimer.clear()

      setAutoSaveLoading(false)
      setAutoSaveStatus(AutoSaveStatus.FAIL)
      update({ isIndicatingAutoSave: false, autoSaveFailure: false })
    }
  }, [autoSaveFailure])

  useEffect(() => {
    if (lastEdited && !isAnimating) {
      const today = new Date()
      const lastEditedDate = new Date(lastEdited)
      let lastEditedFormat

      if (today.getUTCFullYear() !== lastEditedDate.getUTCFullYear()) {
        lastEditedFormat = 'MMM d yyyy'
      } else if (today.getUTCMonth() !== lastEditedDate.getUTCMonth() || today.getUTCDay() !== lastEditedDate.getUTCDay()) {
        lastEditedFormat = 'MMM d'
      }

      const lastEditedFormattedTime = lastEditedFormat
        ? `${format(lastEditedDate, lastEditedFormat)}, ${getFormattedTime(lastEdited)}`
        : `${getFormattedTime(lastEdited)}`

      setLastSavedTime(lastEditedFormattedTime)
      setText(
        separateDiscardAndPublish
          ? text === ''
            ? t(`Last published {{timestamp}}`, { timestamp: lastEditedFormattedTime })
            : 'Unpublished changes'
          : t(`${text === '' ? 'Last edited' : 'Last saved'} {{timestamp}}`, { timestamp: lastEditedFormattedTime })
      )
    }
  }, [lastEdited])

  const animateIndicator = async () => {
    return new Promise<void>((resolve) => {
      setAutoSaveLoading(true)
      setText(t(separateDiscardAndPublish ? 'Unpublished changes' : 'EmailComposer.AutoSave.Status.Loading'))
      setAllChangesSaved(false)

      successTimer.set(() => {
        setAutoSaveLoading(false)
        setText(t(separateDiscardAndPublish ? 'Unpublished changes' : 'EmailComposer.AutoSave.Status.Success'))
        setAutoSaveStatus(AutoSaveStatus.SUCCESS)

        changesSavedTimer.set(() => {
          setAllChangesSaved(true)
          setLastSavedTime(getFormattedTime(Date.now()))

          doneTimer.set(() => {
            setText(t(separateDiscardAndPublish ? 'Unpublished changes' : 'EmailComposer.AutoSave.Status.Success.Done', { lastSavedTime }))
            resolve()
          })
        })
      })
    })
  }

  const renderPopoverTrigger = () => {
    const fill = SvgColor.LIGHT_GRAY
    const commonClassNames = [`${rootClass}__icon`, `${rootClass}__icon-trigger`]

    return (
      <Tooltip
        position={'bottom'}
        hide={isPopoverOpen}
        disableTooltip={isPopoverOpen}
        trigger={
          <>
            <div className={classNames(`${rootClass}__popover-trigger`, { [`${rootClass}__popover-trigger-active`]: isPopoverOpen })}>
              <svg className={classNames(`${rootClass}__svg`, 'save-indicator-progress-bar')} width={36} height={36}>
                <circle
                  className={classNames(`${rootClass}__progress-bar`, { [`${rootClass}__progress-bar-inactive`]: !autoSaveLoading })}
                  id={'progress'}
                  cx="18"
                  cy="18"
                  r="15"
                  fill="none"
                />
              </svg>
              {(!autoSaveLoading && autoSaveStatus === AutoSaveStatus.FAIL) || disabledFeatures.autoSave ? (
                <Svg name={SvgNames.cloudOff} fill={fill} className={classNames(...commonClassNames)} />
              ) : (
                <>
                  <Svg
                    name={SvgNames.cloud}
                    fill={fill}
                    className={classNames(...commonClassNames, {
                      [`${rootClass}__icon-hide`]: !autoSaveLoading,
                      [`${rootClass}__icon-show`]: autoSaveLoading,
                    })}
                    dataTest={`${rootClass}-cloud-svg`}
                  />
                  <Svg
                    name={SvgNames.cloudCheck}
                    fill={fill}
                    className={classNames(...commonClassNames, {
                      [`${rootClass}__icon-hide`]: autoSaveLoading || autoSaveStatus === AutoSaveStatus.FAIL,
                      [`${rootClass}__icon-show`]: showCloudCheckSvg && autoSaveStatus !== AutoSaveStatus.FAIL,
                    })}
                    dataTest={`${rootClass}-cloud-check-svg`}
                  />
                </>
              )}
            </div>
          </>
        }
      >
        {t('EmailComposer.AutoSave.Tooltip')}
      </Tooltip>
    )
  }

  const renderPopoverCard = () => {
    let svgName = disabledFeatures.autoSave ? SvgNames.cloudOff : SvgNames.cloudCheck
    let headerText = t('EmailComposer.AutoSave.Status.Success')
    let bodyText = isCustomerCareLogin()
      ? t('EmailComposer.AutoSave.Disabled.CustomerCare')
      : disabledFeatures.autoSave
      ? t('EmailComposer.AutoSave.Disabled')
      : t(separateDiscardAndPublish ? 'EmailComposer.AutoSave.Popover.BodyText.Publish' : 'EmailComposer.AutoSave.Popover.BodyText.Success')
    let buttonText = t('EmailComposer.AutoSave.Popover.ButtonText.Success')

    const lastEditedDate = separateDiscardAndPublish ? lastPublishedRef.current : lastEdited

    const timeZoneAbbr = new Intl.DateTimeFormat(undefined, {
      timeZone: accountTimeZoneId,
      timeZoneName: 'short',
    })
      .format(lastEditedDate)
      .split(' ')[1]

    const lastSavedToday = isToday((lastEditedDate ? new Date(lastEditedDate) : new Date()).getTime())

    if (autoSaveLoading) {
      svgName = SvgNames.cloud
      headerText = t('EmailComposer.AutoSave.Popover.BodyText.Loading')
    } else if (disabledFeatures.autoSave) {
      headerText = t('EmailComposer.AutoSave.Status.Disabled')
    } else if (autoSaveStatus === AutoSaveStatus.FAIL) {
      svgName = SvgNames.cloudOff
      headerText = t('EmailComposer.AutoSave.Status.Error')
      bodyText = t('EmailComposer.AutoSave.Popover.BodyText.Error')
      buttonText = t('EmailComposer.AutoSave.Popover.ButtonText.Error')
    }

    return (
      <>
        <div className={`${rootClass}__popover-header`}>
          <div className={`${rootClass}__popover-header__icon`}>
            <Svg name={svgName} fill={SvgColor.TEXT_GRAY} className={classNames(`${rootClass}__icon`)} />
          </div>
          <Typography text={headerText} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.MEDIUM} lineHeight={LineHeight.LARGER} />
        </div>
        <div className={`${rootClass}__popover-content`}>
          <Typography text={bodyText} type={TextType.BODY_TEXT} />
          {(separateDiscardAndPublish || (!autoSaveLoading && allChangesSaved)) && lastEditedDate && (
            <Typography
              text={t('EmailComposer.AutoSave.Popover.BodyText.Success.Done.Expanded', {
                text: `${separateDiscardAndPublish ? 'Last published' : text.includes('edited') ? 'Last edited' : 'Last saved'} ${
                  lastSavedToday ? 'today' : 'on'
                }`,
                date: lastSavedToday ? undefined : format(new Date(lastEditedDate ?? ''), 'LLLL d, yyyy'),
                time: getFormattedTime(new Date(lastEditedDate ?? '').getTime()),
                timeZone: timeZoneAbbr,
              })}
              type={TextType.BODY_TEXT}
              className={separateDiscardAndPublish ? 'push-up-x3' : ''}
            />
          )}
        </div>
        {!separateDiscardAndPublish && (
          <div className={`${rootClass}__popover-footer`}>
            <ButtonWithLoader
              loading={!allChangesSaved}
              onClick={() => {
                update({ isIndicatingAutoSave: true })
                onAutoSave(true)
                updateModal('statusToast', undefined)
                setIsPopoverOpen(false)
              }}
              className={classNames(`${rootClass}__button-with-loader`, { [`${rootClass}__button-with-loader-loading`]: !allChangesSaved })}
            >
              {buttonText}
            </ButtonWithLoader>
          </div>
        )}
      </>
    )
  }

  return (
    <div className={rootClass} data-test={rootClass}>
      <SwitchTransition mode={'out-in'}>
        <CSSTransition
          key={text}
          in={autoSaveLoading || allChangesSaved}
          nodeRef={progressBarRef}
          addEndListener={(done) => progressBarRef.current?.addEventListener('transitionend', done, false)}
          classNames={`${rootClass}__fade-text`}
        >
          <div ref={progressBarRef}>
            <Typography
              text={t(!autoSaveLoading && autoSaveStatus === AutoSaveStatus.FAIL ? 'EmailComposer.AutoSave.Status.Error' : text)}
              dataTest={`${rootClass}-status`}
              type={TextType.BODY_TEXT_SMALL_LIGHT}
              weight={TextWeight.MEDIUM}
              className={classNames(`${rootClass}__status`, { [`${rootClass}__status-active`]: isPopoverOpen })}
            />
          </div>
        </CSSTransition>
      </SwitchTransition>
      {(text !== '' || !!lastEdited) && !separateSaveAndClose && (
        <Popover
          align={'end'}
          trigger={renderPopoverTrigger()}
          isOpen={isPopoverOpen}
          onOpenChange={(open) => setIsPopoverOpen(open)}
          className={rootClass}
        >
          <div className={`${rootClass}__popover`}>{renderPopoverCard()}</div>
        </Popover>
      )}
    </div>
  )
}

export default AutoSaveIndicator
