import React, { useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Svg, { SvgColor, SvgNames } from '@components/Svg'
import Tab from '@components/TabsAO/TabAO'
import Tabs from '@components/TabsAO/TabsAO'
import { TabStyle } from '@components/TabsAO/TabsAO'

import LandingPageComposerCustomCodeCss from '../LandingPageComposerCustomCodeCSS/LandingPageComposerCustomCodeCSS'
import LandingPageComposerCustomCodeJavaScript from '../LandingPageComposerCustomCodeJavaScript/LandingPageComposerCustomCodeJavaScript'

import './LandingPageComposerCustomCodeSidebar.css'

const rootClass = 'landing-page-composer-custom-code-sidebar'

const LandingPageComposerCustomCodeSidebar = () => {
  const [collapsed, setCollapsed] = useState(false)
  const [activeTab, setActiveTab] = useState<string>('javascript')

  const tabs = [
    {
      index: 'javascript',
      label: 'JavaScript',
      component: <LandingPageComposerCustomCodeJavaScript />,
    },
    {
      index: 'css',
      label: 'CSS',
      component: <LandingPageComposerCustomCodeCss />,
    },
  ]

  const items = tabs.map((tab) => {
    return (
      <Tab index={tab.index} key={tab.index}>
        {tab.component}
      </Tab>
    )
  })

  const tabStructure = tabs.map((tab) => {
    return { ...tab, index: tab.index, build: '' }
  })

  return (
    <div
      tabIndex={0}
      role={'button'}
      className={classNames(rootClass, {
        [`${rootClass}--collapsed`]: collapsed,
      })}
      onClick={collapsed ? () => setCollapsed(false) : undefined}
      onKeyDown={(keyDownEvent) => (keyDownEvent.key === 'Enter' ? setCollapsed(false) : undefined)}
    >
      <Button
        className={classNames(`${rootClass}__toggle-button`, {
          [`${rootClass}__collapse-button`]: !collapsed,
        })}
        buttonType={ButtonType.PRIMARY}
        onClick={() => setCollapsed(!collapsed)}
      >
        <Svg name={collapsed ? SvgNames.caretLeft : SvgNames.caretRight} fill={SvgColor.WHITE} />
      </Button>
      <div className={`${rootClass}__container`}>
        <Tabs tabStyle={TabStyle.SOLID} className={`${rootClass}__tabs`} defaultValue={activeTab} onChange={setActiveTab} childData={tabStructure}>
          {items}
        </Tabs>
      </div>
    </div>
  )
}

export default LandingPageComposerCustomCodeSidebar
