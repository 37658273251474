import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Modal, { ModalBody, ModalHeader } from '@src/../../../libs/components/common/Modal'

import { YesNo } from '@components/ConfirmationModal'
import { PAGE_SIZE_OPTIONS } from '@components/ContactsDetails/ContactsDetails.constants'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import { TableV2 } from '@components/TableV2/TableV2'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { useColumns, useRowActions } from '@src/pages/ContactSegments/components/CopySegments/components/CopyLog/CopyLog.table.utils'
import { COPY_SEGMENT_LOG_PARAM, rootText } from '@src/pages/ContactSegments/components/CopySegments/constants/CopySegments.constants'
import {
  CopyLogField,
  CopyLogFieldValues,
  CopySegmentsContext,
} from '@src/pages/ContactSegments/components/CopySegments/context/CopySegments.context'
import { ColumnSort } from '@tanstack/react-table'
import { SortDirection } from '@utils/common'

import './CopyLog.css'

export interface CopyLogState {
  pageSize: number
  pageIndex: number
  sortBy: Array<any>
  undoActionId?: number
}

const defaultState: ColumnSort[] = [
  {
    id: CopyLogField.Date,
    desc: true,
  },
]

const rootClass = 'copy-log'
const copyLogText = `${rootText}CopyLog.`

const CopyLog = () => {
  const {
    values: { copyCount, historyLog, loadingHistoryLog },
    getCopySegmentCount,
    getCopySegmentLog,
    undoCopySegments,
    update,
  } = useContext(CopySegmentsContext)
  const [state, setState] = useState<CopyLogState>({ pageSize: PAGE_SIZE_OPTIONS[0], pageIndex: 0, sortBy: defaultState })
  const { pageSize, pageIndex, sortBy, undoActionId } = state
  const direction = sortBy[0].desc ? SortDirection.DESC : SortDirection.ASC

  const { t } = useTranslation()

  const history = useHistory()

  useEffect(() => {
    getCopySegmentCount()
  }, [])

  useEffect(() => {
    getCopySegmentLog({
      direction,
      pageNumber: pageIndex,
      pageSize,
      orderBy: CopyLogFieldValues[sortBy[0].id],
    })
  }, [sortBy, pageSize, pageIndex])

  const fetchData = (pageIndex: number, pageSize: number) => setState((state) => ({ ...state, pageSize, pageIndex }))

  const onAnswer = (answer: YesNo) => {
    if (answer === YesNo.YES && undoActionId !== undefined) {
      undoCopySegments(undoActionId, { direction, pageNumber: pageIndex, pageSize, orderBy: CopyLogFieldValues[sortBy[0].id] })
    }
    setState((state) => ({ ...state, undoActionId: undefined }))
  }

  const onChangeSort = (sortBy: Array<any>) => {
    const validSortBy = sortBy.length > 0 ? sortBy : defaultState
    setState((state) => ({ ...state, sortBy: validSortBy }))
  }

  const onClose = () => {
    const queryParams = new URLSearchParams(history.location.search)
    if (queryParams.has(COPY_SEGMENT_LOG_PARAM)) {
      queryParams.delete(COPY_SEGMENT_LOG_PARAM)
      history.replace({ search: queryParams.toString() })
    }
    update({ showSegmentCopyLog: false })
  }

  return (
    <>
      {undoActionId !== undefined && (
        <DeleteConfirmationModal
          body={t(`${copyLogText}DeleteConfirmationModal.Body`)}
          deleteButtonText={t('Undo')}
          isOpen
          onAnswer={onAnswer}
          title={t(`${copyLogText}DeleteConfirmationModal.Title`)}
        />
      )}
      <Modal
        isOpen
        className={rootClass}
        header={
          <ModalHeader headerType={ModalHeaderType.Form} closeButton={onClose}>
            <Typography text={t(`${rootText}Header.Link`)} type={TextType.PAGE_HEADER_SEMI_BOLD} />
          </ModalHeader>
        }
      >
        <ModalBody>
          <Typography
            text={copyCount !== undefined ? t(`${rootText}Copies.Number`, { count: copyCount }) : undefined}
            type={TextType.SECTION_HEADER}
            weight={TextWeight.MEDIUM}
          />
          <TableV2
            columns={useColumns()}
            data={historyLog}
            rowActions={useRowActions(setState)}
            loading={loadingHistoryLog}
            enableSorting
            manualSorting
            sortingBy={sortBy}
            onSort={onChangeSort}
            enablePaginate
            paginationState={{
              pageSize,
              pageIndex,
              controlledPageCount: Math.ceil((copyCount ?? 0) / pageSize),
              dropdownInsideModal: true,
            }}
            fetchData={fetchData}
            withoutBorders
            enableInsideLoader
            enableBodyHeightComputation
          />
        </ModalBody>
      </Modal>
    </>
  )
}

export default CopyLog
