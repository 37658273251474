import { gql } from '@apollo/client'

export default gql`
  query getPeerAccounts {
    getPeerAccounts {
      canPublish
      status
      peers {
        id
        name
      }
    }
  }
`
