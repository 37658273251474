import React, { FC } from 'react'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { Box } from '@src/pages/programs/UpgradeCommonComponents/Box/Box'
import {
  FieldSetOperation,
  FieldSetValues,
} from '@src/pages/programs/upgradeManager/components/steps/ChangeFieldValueUpgradeStep/components/FieldReplacement/FieldReplacement.utils'
import { UpgradedStepOperation } from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'

import './ReviewFieldReplacement.css'

interface ReviewFieldReplacementProps {
  operation: UpgradedStepOperation
  conditionId?: string
}

const rootClass = 'review-field-replacement'

export const ReviewFieldReplacement: FC<ReviewFieldReplacementProps> = ({
  operation: { fieldName, value, rule, newField },
}: ReviewFieldReplacementProps) => {
  const { t } = useTranslation()

  return (
    <div className={rootClass}>
      <div className={`${rootClass}__current-field-container`}>
        <div className={`${rootClass}__value-container`}>
          <Typography text={t('Current field')} weight={TextWeight.MEDIUM} lineHeight={LineHeight.MEDIUM} />
          <Box isCurrent isSourceValue>
            <Typography text={fieldName} />
          </Box>
        </div>
        {!!rule && (
          <div className={`${rootClass}__condition-container`}>
            <Typography className={`${rootClass}__condition`} text={t(FieldSetValues[rule as FieldSetOperation])} lineHeight={LineHeight.MEDIUM} />
            <Typography
              className={`${rootClass}__condition-source`}
              text={value}
              type={TextType.BODY_TEXT_SMALL}
              lineHeight={LineHeight.MEDIUM_SMALL}
            />
          </div>
        )}
      </div>
      <Svg className={`${rootClass}__arrow`} name={SvgNames.arrowRight} type={SvgType.LARGE_ICON} />
      <div className={`${rootClass}__change-to-container`}>
        <Typography text={t('Change to')} weight={TextWeight.MEDIUM} lineHeight={LineHeight.MEDIUM} />
        <Box isCurrent isSourceValue>
          <Typography text={newField?.name} />
        </Box>
      </div>
    </div>
  )
}
