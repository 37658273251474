import { gql } from '@apollo/client'

export default gql`
  query getAllSentMessagesByFilter($filter: FilterType!, $hidetoSpecificPeople: Boolean, $folder: String, $isFavorites: Boolean) {
    getAllSentMessagesByFilter(filter: $filter, hidetoSpecificPeople: $hidetoSpecificPeople, folder: $folder, isFavorites: $isFavorites) {
      id
      msgTitle
      sentCount
      senderName
      sentTime
      previewUrl
    }
  }
`
