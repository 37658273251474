import { gql } from '@apollo/client'

export default gql`
  query getFTPFirstRows($path: String, $hasHeader: Boolean!, $quote: Quote, $delimiter: Delimiter) {
    getFTPFirstRows(path: $path, hasHeader: $hasHeader, quote: $quote, delimiter: $delimiter) {
      columnName
      previewRows
    }
  }
`
