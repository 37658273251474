import { useCallback, useEffect, useState, Dispatch, SetStateAction, useRef } from 'react'

import equal from 'fast-deep-equal/es6'

import { useApolloClient, useMutation } from '@apollo/client'
import { useTranslation } from '@const/globals'
import updateClickthroughLinks from '@graphql/mutations/updateClickthroughLinks'
import getClickthroughLinks from '@graphql/queries/getClickthroughLinks'
import { ClickthroughLinkInput, UpdateClickthroughLinksMutation, UpdateClickthroughLinksMutationVariables } from '@graphql/types/mutation-types'
import { ClickthroughLink, GetClickthroughLinksQuery, GetClickthroughLinksQueryVariables } from '@graphql/types/query-types'
import { StatusToastType } from '@interface/StatusToast'
import { LandingPageManagerTab } from '@src/pages/Content/LandingPages/LandingPagesManager/LandingPageManager.utils'
import { filterNotEmptyArray } from '@utils/array'

export interface ClickthroughLinksAsObject {
  [key: string]: { link?: string; name?: string }
}

const objectToArray = (object: ClickthroughLinksAsObject): ClickthroughLinkInput[] =>
  Object.entries(object).map(([code, { link, name }]) => ({ code, link, name }))

const arrayToObject = (array: ClickthroughLinkInput[]): ClickthroughLinksAsObject =>
  array.reduce((acc: ClickthroughLinksAsObject, { code, link, name }) => {
    acc[code] = { link, name }
    return acc
  }, {})

const useClickthroughLinks = (
  pageId: string,
  currentTab: string,
  setToastStatus: Dispatch<SetStateAction<StatusToastType | undefined>>
): {
  loadingLinks: boolean
  updatingLinks: boolean
  dirtyClickthroughLinks: boolean
  clickthroughLinks: ClickthroughLink[]
  refetchLinks: () => Promise<any>
  onSaveLinks: () => void
  onCancelLinkChanges: () => void
  onClickthroughLinkChange: (link: ClickthroughLinkInput) => void
} => {
  const { t } = useTranslation()
  const client = useApolloClient()
  const [clickthroughLinks, setClickthroughLinks] = useState<ClickthroughLink[]>([])
  const [loadingLinks, setLoadingLinks] = useState<boolean>(true)
  const [updatingLinks, setUpdatingLinks] = useState<boolean>(false)
  const [dirtyClickthroughLinks, setDirtyClickthroughLinks] = useState<boolean>(false)
  const currentLinksRef = useRef<ClickthroughLinksAsObject>({})
  const initialLinksRef = useRef<ClickthroughLinksAsObject>({})

  useEffect(() => {
    currentLinksRef.current = arrayToObject(clickthroughLinks)
  }, [clickthroughLinks])

  const [saveLinks] = useMutation<UpdateClickthroughLinksMutation, UpdateClickthroughLinksMutationVariables>(updateClickthroughLinks, {
    client,
    fetchPolicy: 'no-cache',
  })

  const getLinks = useCallback(
    (afterSave?: boolean) => {
      return client
        .query<GetClickthroughLinksQuery, GetClickthroughLinksQueryVariables>({
          query: getClickthroughLinks,
          fetchPolicy: 'network-only',
          variables: { pageId },
        })
        .then(({ data }) => {
          if (data.getClickthroughLinks) {
            const links = data.getClickthroughLinks.filter(filterNotEmptyArray)
            initialLinksRef.current = arrayToObject(links)
            setClickthroughLinks((cur) => {
              if (!equal(cur, links)) {
                setUpdatingLinks(true)
                setTimeout(() => {
                  setDirtyClickthroughLinks(false)
                  setUpdatingLinks(false)
                }, 1000)
                return links
              }
              afterSave && setDirtyClickthroughLinks(false)
              return cur
            })
          }
        })
        .finally(() => setLoadingLinks(false))
    },
    [pageId, client]
  )

  useEffect(() => {
    getLinks()
  }, [getLinks])

  useEffect(() => {
    if (currentTab !== LandingPageManagerTab.LINKS) {
      const links = objectToArray(currentLinksRef.current)
      setClickthroughLinks((cur) => {
        if (!equal(cur, links)) {
          return links
        }
        return cur
      })
    }
  }, [currentTab])

  const onSaveLinks = useCallback(() => {
    setUpdatingLinks(true)
    const data = objectToArray(currentLinksRef.current)
    saveLinks({ variables: { pageId, data } }).then(({ data }) => {
      if (data?.updateClickthroughLinks) {
        getLinks(true)
      } else {
        setToastStatus({
          showStatus: true,
          title: t('Error!'),
          statusMessage: t('Failed to save clickthrough links.'),
          successStatus: false,
        })
        setUpdatingLinks(false)
      }
    })
  }, [saveLinks, pageId, getLinks, setToastStatus, t])

  const onClickthroughLinkChange = useCallback(({ code, ...restChanged }: ClickthroughLinkInput) => {
    setDirtyClickthroughLinks(true)
    const currentLink = currentLinksRef.current[code]
    currentLinksRef.current[code] = { ...currentLink, ...restChanged }
  }, [])

  const onCancelLinkChanges = useCallback(() => {
    setClickthroughLinks(objectToArray(initialLinksRef.current))
    setDirtyClickthroughLinks(false)
  }, [])

  return {
    loadingLinks,
    updatingLinks,
    dirtyClickthroughLinks,
    clickthroughLinks,
    refetchLinks: getLinks,
    onSaveLinks,
    onCancelLinkChanges,
    onClickthroughLinkChange,
  }
}

export default useClickthroughLinks
