import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import { Row } from 'react-table'

import { CustomSourceItems, ExtendedItemDto } from '@complex/ListingPage/Context/ListingPageCommon.context'
import ActionableNestedTable from '@components/ActionableNestedTable/ActionableNestedTable'
import CRMSourcePickerModal from '@components/AssetPickers/CRMSourcePickerModal/CRMSourcePickerModal'
import Button, { ButtonType } from '@components/Button'
import { YesNo } from '@components/ConfirmationModal'
import Container from '@components/Container'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { RowAction, TableColumn } from '@components/Table/Table'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { AddContactsFromCRMContext, RADIO_OPTION } from '@src/pages/ContactSegments/components/AddContactsFromCRM/AddContactsFromCRMContext'
import { getSelectCRMSourcesColumns } from '@src/pages/ContactSegments/components/AddContactsFromCRM/components/SelectCRMSources/SelectCRMSources.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import { getSourceTitle } from '@utils/crm.utils'
import useCRM from '@utils/hooks/useCRM'

import './SelectCRMSources.css'

interface SelectCRMSourcesState {
  sourceToRemove?: ExtendedItemDto
  showCRMSourcePicker: boolean
}

const initialState: SelectCRMSourcesState = {
  showCRMSourcePicker: false,
}

const rootClass = 'select-crm-sources'
const dataTest = rootClass

const SelectCRMSources: FC = () => {
  const {
    values: { crmSourceOptions, invalidSources, pickedSources, radioOption, selectedSourcedSegment, sources },
    removeSource,
    update,
  } = useContext(AddContactsFromCRMContext)

  const [state, setState] = useState<SelectCRMSourcesState>(initialState)
  const { sourceToRemove, showCRMSourcePicker } = state

  const { isSF } = useAccountSettings()
  const { connectorType } = useCRM()

  const { t } = useTranslation()

  const onSelectSourceClick = useCallback(() => {
    setState((state) => ({ ...state, showCRMSourcePicker: true }))
  }, [])

  const closeCRMSourcePicker = useCallback(() => setState((state) => ({ ...state, showCRMSourcePicker: false })), [])

  const onEdit = radioOption === RADIO_OPTION.EDIT

  const onSubmit = useCallback(
    async (items: CustomSourceItems) => {
      let updatedOriginalSources: CustomSourceItems = {}

      if (!!selectedSourcedSegment) {
        const { originalSources, sourceType = '' } = selectedSourcedSegment
        const sourceTypeName = getSourceTitle(sourceType, crmSourceOptions)
        const itemIds = items[sourceTypeName].map(({ id }) => id)
        updatedOriginalSources = {
          ...updatedOriginalSources,
          [sourceTypeName]: originalSources[sourceTypeName]?.filter(({ id }) => itemIds.includes(id)),
        }
      }

      update({
        pickedSources: items,
        selectedSourcedSegment: !!selectedSourcedSegment
          ? {
              ...selectedSourcedSegment,
              originalSources: updatedOriginalSources,
            }
          : undefined,
      })
      closeCRMSourcePicker()
    },
    [pickedSources, selectedSourcedSegment, sources]
  )

  const sourcesData = useMemo(() => Object.values(sources).reduce((curr, items) => [...curr, ...items], []), [sources])
  const totalSources = useMemo(() => Object.values(sources).reduce((total, items) => total + items.length, 0), [sources])

  const columns: TableColumn[] = useMemo(
    () => getSelectCRMSourcesColumns(!!invalidSources ? Object.entries(invalidSources).flatMap((source) => source[1]) : []),
    [invalidSources]
  )

  const rowActions: RowAction[] = [
    {
      label: t('Remove source'),
      icon: SvgNames.delete,
      onClick: ({ original }: Row<ExtendedItemDto>) => setState((state) => ({ ...state, sourceToRemove: original })),
    },
  ]

  const renderCRMSourcePicker = () => (
    <CRMSourcePickerModal
      dataTest={`${dataTest}__asset-picker`}
      isOpen={showCRMSourcePicker}
      connectorType={connectorType}
      crmSourceOptions={crmSourceOptions}
      defaultSourceType={onEdit ? selectedSourcedSegment?.sourceType : undefined}
      onSubmitCustomSources={(items) => onSubmit(items)}
      onClose={closeCRMSourcePicker}
      preSelectedCustomSourceItems={sources}
    />
  )

  return (
    <Container className={rootClass} dataTest={dataTest}>
      {showCRMSourcePicker && !!crmSourceOptions.length && renderCRMSourcePicker()}
      {sourceToRemove && (
        <DeleteConfirmationModal
          isOpen
          title={t('Are you sure you want to remove this source?')}
          deleteButtonText={t('Remove source')}
          body={t('This source will be removed from the list. You can always add it back by clicking on Select sources.')}
          onAnswer={(answer: YesNo) => {
            if (answer === YesNo.YES) {
              removeSource(sourceToRemove)
            }
            setState((state) => ({ ...state, sourceToRemove: undefined }))
          }}
        />
      )}
      <Typography className={`${rootClass}__header`} text={t('Select CRM sources')} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
      <div className={`${rootClass}__description`}>
        <Typography
          text={`${t(
            'Select the contacts that you want to convert into a new segment. We will only import contact-related data from the selected sources.'
          )} `}
          type={TextType.BODY_TEXT_LIGHT}
          inline
        />
        {isSF && (
          <Typography text={t('Salesforce reports are limited to 2,000 records.')} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.BOLD} inline />
        )}
      </div>
      <div className={`${rootClass}__select-sources`}>
        <Button dataTest={`${rootClass}__button`} buttonType={ButtonType.INFO} onClick={onSelectSourceClick}>
          <Svg type={SvgType.LARGER_ICON} name={SvgNames.listCheck} />
          <Typography text={t('Select sources')} type={TextType.NORMAL_TEXT_TEAL_LARGE} weight={TextWeight.MEDIUM} />
        </Button>
        {totalSources > 0 && (
          <Typography
            lineHeight={LineHeight.MEDIUM_LARGE}
            text={`${totalSources} ${t('Sources selected')}`}
            type={TextType.BODY_TEXT_SMALL_LIGHT}
            weight={TextWeight.MEDIUM}
            inline
          />
        )}
      </div>
      <ActionableNestedTable
        className={`${rootClass}__table`}
        emptyText={t('Source(s) not set')}
        data={sourcesData}
        columns={columns}
        rowActions={rowActions}
        emptyTextIcon={!sourcesData.length ? SvgNames.errorSolid : undefined}
        emptyTextIconTooltipText={!sourcesData.length ? 'AddContactsFromCRM.SelectCRMSources.Empty.Tooltip' : undefined}
      />
    </Container>
  )
}

export default SelectCRMSources
