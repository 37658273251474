import { DateRangeValueType } from '@components/DateRangePicker/DateRangePicker'
import { WonOpportunities } from '@graphql/types/microservice/data-lake-query-types'
import { RevenueHeaderDataCard } from '@src/pages/reports/revenueImpactNew/components/dataCards/DataCards'
import { getPreviousSameLengthFormattedRange, getTrendInPercentage } from '@src/pages/reports/revenueImpactNew/RevenueImpactNew.utils'
import { getCountDisplay } from '@utils/numbers'

const displayWith$: (str: string | number) => string = (str) => `$${str}`
const isNumber: (num: any) => boolean = (num) => typeof num === 'number'
type Props = {
  data: WonOpportunities
  range: DateRangeValueType
  t: (s: any) => any
}

const getFormattedData: (props: Props) => RevenueHeaderDataCard[] = ({ data, range, t }) => {
  const { total, topIndustry, topSegment, topRegion } = data ?? {}
  return [
    {
      header: t('Won Opportunities'),
      title: isNumber(total?.amount) ? displayWith$(getCountDisplay(total?.amount)) : undefined,
      subtitle: isNumber(total?.count) ? `${total?.count} ${t('Won opportunities')}` : undefined,
      value: getTrendInPercentage(total?.increase),
      trend: total?.increase ? { isPositive: total.increase > 0 } : undefined,
      tooltipTypographyProps: total?.increase
        ? {
            text: 'Revenue.Won.Opportunities.Card.Tooltip',
            values: { dateRange: getPreviousSameLengthFormattedRange(range) },
          }
        : undefined,
    },
    {
      header: t('Top Industry'),
      title: topIndustry?.industry ? topIndustry.industry : undefined,
      subtitle: isNumber(topIndustry?.count) ? `${topIndustry?.count} ${t('Won opportunities')}` : undefined,
      value: topIndustry?.amount ? displayWith$(getCountDisplay(topIndustry?.amount)) : undefined,
    },
    {
      header: t('Top Market Segment'),
      title: topSegment?.segment ? topSegment.segment : undefined,
      subtitle: isNumber(topSegment?.count) ? `${topSegment?.count} ${t('Won opportunities')}` : undefined,
      value: topSegment?.amount ? displayWith$(getCountDisplay(topSegment?.amount)) : undefined,
    },
    {
      header: t('Top Region'),
      title: topRegion?.region ? topRegion.region : undefined,
      subtitle: isNumber(topRegion?.count) ? `${topRegion?.count} ${t('Won opportunities')}` : undefined,
      value: topRegion?.amount ? displayWith$(getCountDisplay(topRegion?.amount)) : undefined,
    },
  ]
}

export default getFormattedData
