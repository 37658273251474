import { gql } from '@apollo/client'

export default gql`
  query salesUsers($parentAccountId: Long!, $accountId: Long!, $userId: Long!) {
    salesUsers(parentAccountId: $parentAccountId, accountId: $accountId, userId: $userId) {
      salesUsers {
        name
        salesUser
        userId
      }
    }
  }
`
