import React, { ReactNode, RefObject, UIEventHandler } from 'react'
import { Row } from 'react-table'

import SegmentNameWithCrmSyncAndInfoHover from '@complex/ContactSegments/SegmentNameWithCrmSyncAndInfoHover/SegmentNameWithCrmSyncAndInfoHover'
import SegmentNameWithInfoHover from '@complex/ContactSegments/SegmentNameWithInfoHover/SegmentNameWithInfoHover'
import ListInfoHoverCard from '@complex/ListPickerModalV2/components/ListInfoHoverCard/ListInfoHoverCard'
import SegmentTree from '@complex/ListPickerModalV2/components/SegmentTree/SegmentTree'
import {
  ListPickerModalState,
  ParentsHierarchy,
  PreProcessedList,
  ShowTooltipByCriteria,
} from '@complex/ListPickerModalV2/utils/interfaces/ListPickerModalInterfaces'
import { unifiedListTypes } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import { TableColumn } from '@components/Table/Table'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextAlign, TextType, TextWeight } from '@components/Typography/Typography'
import { CRM_STATUS } from '@utils/crm.utils'
import { ListCategory } from '@utils/lists'
import { getFormattedNumber } from '@utils/numbers'
import { escapeRegExp } from '@utils/strings'

export const renderTooltip = (value: string | ReactNode, disabled = false, text?: string, disableTooltip?: boolean) => (
  <Tooltip
    position={'top'}
    trigger={<Typography text={value} type={disabled ? TextType.BODY_TEXT_LIGHT : TextType.BODY_TEXT} inline />}
    ellipOnTrigger
    inline={false}
    disableTooltip={disableTooltip}
  >
    {typeof value === 'string' ? value : text}
  </Tooltip>
)

const renderListNameWithInfoHover = ({ row, value }: any, disableTooltip?: boolean) => (
  <div className={`list-picker-modal__tooltip`}>
    {renderTooltip(value, (row as Row<any>)?.original.disabled, undefined, disableTooltip)}
    <SegmentNameWithInfoHover segmentData={row.original} cardBody={<ListInfoHoverCard segmentData={row.original} />} />
  </div>
)

const renderListNameWithCrmSyncAndInfoHover = ({ row }: any) => (
  <SegmentNameWithCrmSyncAndInfoHover
    segmentData={row.original}
    cardBody={<ListInfoHoverCard className={`segment-name-with-crm-sync-and-info-hover__card-body`} segmentData={row.original} />}
  />
)
export const renderBoldTextOnMatch = (value: string, disabled = false, search: string) => {
  const matcher = new RegExp(escapeRegExp(search), 'i')
  const match = value.match(matcher)
  return match
    ? renderTooltip(
        <>
          <Typography text={value.slice(0, match.index)} type={disabled ? TextType.BODY_TEXT_LIGHT : TextType.BODY_TEXT} inline />
          <Typography weight={TextWeight.BOLD} text={match[0]} type={disabled ? TextType.BODY_TEXT_LIGHT : TextType.BODY_TEXT} inline />
          <Typography text={value.slice((match.index || 0) + search.length)} type={disabled ? TextType.BODY_TEXT_LIGHT : TextType.BODY_TEXT} inline />
        </>,
        false,
        value
      )
    : renderTooltip(value)
}

interface GetColumnsParams {
  searchValue?: string
  listType?: ListCategory
  searchHierarchy?: ParentsHierarchy
  showTooltipByCriteria?: ShowTooltipByCriteria
}

export const getColumns = ({ searchValue, listType, searchHierarchy, showTooltipByCriteria }: GetUCLColumnsParams): TableColumn[] => [
  {
    Header: 'List/Segment',
    accessor: 'name',
    align: 'left',
    Cell: (cell) => {
      const element = searchValue
        ? renderBoldTextOnMatch(cell.value, (cell.row as Row<any>)?.original.disabled, searchValue)
        : renderTooltip(cell.value, (cell.row as Row<any>)?.original.disabled)
      const tooltip = showTooltipByCriteria?.(cell.row)
      if (tooltip) {
        return (
          <Tooltip position={'top'} trigger={element} ellipOnTrigger inline={false}>
            {tooltip}
          </Tooltip>
        )
      }
      return element
    },
  },
  !!searchValue && !!searchHierarchy
    ? {
        Header: 'Segment Tree',
        accessor: 'tree',
        align: 'left',
        Cell: (cell) =>
          cell.row.depth === 0 &&
          !!searchHierarchy[(cell.row as Row<PreProcessedList>)?.original.id]?.length && (
            <SegmentTree segmentId={(cell.row as Row<PreProcessedList>)?.original.id} listType={listType} />
          ),
        className: 'list-picker-modal__segment-tree',
        maxWidth: 124,
      }
    : {
        Header: 'Source',
        accessor: 'type',
        align: 'left',
        Cell: ({ cell }) => renderTooltip(cell.value, (cell.row as Row<any>)?.original.disabled),
        className: 'list-picker-modal__source',
        maxWidth: 124,
      },
  {
    Header: 'Records',
    accessor: 'size',
    align: 'right',
    Cell: ({ cell: { value } }) => (value !== undefined ? getFormattedNumber(value) : ''),
    className: 'list-picker-modal__records',
    maxWidth: 54,
  },
]

const validateCrmStatus = ({ crmStatus }: PreProcessedList) => !!crmStatus && Object.keys(CRM_STATUS).includes(crmStatus)

type GetUCLColumnsParams = GetColumnsParams

export const getUCLColumns = ({ searchValue, listType, searchHierarchy, showTooltipByCriteria }: GetUCLColumnsParams): TableColumn[] => [
  {
    Header: 'List/Segment',
    accessor: 'name',
    align: 'left',
    Cell: (cell) => {
      const tooltip = showTooltipByCriteria?.(cell.row)
      const element = searchValue
        ? renderBoldTextOnMatch(cell.value, (cell.row as Row<any>)?.original.disabled, searchValue)
        : validateCrmStatus((cell.row as Row<any>)?.original as PreProcessedList)
        ? renderListNameWithCrmSyncAndInfoHover(cell)
        : renderListNameWithInfoHover(cell, tooltip != undefined)
      if (tooltip) {
        return (
          <Tooltip position={'top'} trigger={element} ellipOnTrigger inline={false}>
            {tooltip}
          </Tooltip>
        )
      }
      return element
    },
    className: 'list-picker-modal__info-hover',
    flexColumn: true,
  },
  {
    Header: 'Tags',
    accessor: 'tags',
    align: 'left',
    className: 'list-picker-modal__tags',
    maxWidth: 132,
  },
  !!searchValue && !!searchHierarchy
    ? {
        Header: 'Segment Tree',
        accessor: 'tree',
        align: 'left',
        Cell: (cell) =>
          cell.row.depth === 0 &&
          !!searchHierarchy[(cell.row as Row<PreProcessedList>)?.original.id]?.length && (
            <SegmentTree segmentId={(cell.row as Row<PreProcessedList>)?.original.id} listType={listType} />
          ),
        className: 'list-picker-modal__segment-tree',
        maxWidth: 124,
      }
    : {
        Header: 'Source',
        accessor: 'type',
        align: 'left',
        Cell: ({ cell }) => renderTooltip(cell.value, (cell.row as Row<PreProcessedList>)?.original.disabled),
        className: 'list-picker-modal__source',
        maxWidth: 124,
      },
  {
    Header: 'Records',
    accessor: 'size',
    align: 'right',
    Cell: ({ cell: { value } }) => (value !== undefined ? getFormattedNumber(value) : ''),
    className: 'list-picker-modal__records',
    maxWidth: 54,
  },
]

export const rowFinder = (rows: Row[], row: Row) => rows.find(({ original }) => (original as any).id === (row.original as any).id) as Row

export const customSubRowSelection = (row: Row) => (row?.original as any)?.id.startsWith('l-')

interface GetTablePropsParams {
  state: ListPickerModalState
  disabledListTooltipText?: string
  disableCheckboxHeader: boolean
  hasToExpand: (row: Row) => boolean
  expandAll: boolean
  multiSelect: boolean
  onScroll: UIEventHandler<HTMLDivElement>
  onRowSelectionChanged: (selectedRows: Row[], rows?: Row[]) => void
  refreshList: (currentPage: number) => void
  scrollableElement: RefObject<HTMLDivElement> | undefined
  showTooltipByCriteria?: ShowTooltipByCriteria
  selectSubRows?: boolean
}

interface GetUCLTablePropsParams extends GetTablePropsParams {
  onClickTagInRow: (name: string) => void
}

export const getTableProps = ({
  state: {
    allLoaded,
    currentPage,
    lazyLoading,
    listsState: { lists, listsLoading },
    listType,
    selectedLists,
    selectAllSegmentsOfSelectedList,
  },
  disabledListTooltipText,
  disableCheckboxHeader,
  expandAll,
  hasToExpand,
  multiSelect,
  refreshList,
  onRowSelectionChanged,
  onScroll,
  scrollableElement,
  selectSubRows,
  showTooltipByCriteria,
}: GetTablePropsParams) => ({
  allLoaded,
  checkboxWidth: 16,
  columns: unifiedListTypes.includes(listType) ? getUCLColumns({ showTooltipByCriteria }) : getColumns({ showTooltipByCriteria }),
  customRowFinder: rowFinder,
  customSubRowSelection: selectAllSegmentsOfSelectedList ? customSubRowSelection : undefined,
  data: lists as any[],
  disableCheckboxHeader: disableCheckboxHeader,
  defaultSelectedRows: Object.values(selectedLists[listType]),
  disabledCheckboxTooltipText: disabledListTooltipText,
  fixedHeader: true,
  hasToExpand,
  hideIcon: false,
  initiallyExpandAll: expandAll,
  isSingleSelect: !multiSelect,
  loading: lazyLoading || listsLoading,
  onBodyScroll: onScroll,
  onLoading: () => refreshList(currentPage + 1),
  onRowSelectionChanged,
  readOnlyTags: true,
  scrollableElement,
  textAlign: TextAlign.LEFT,
  textType: TextType.BODY_TEXT_LIGHT,
  selectSubRows,
  useHeaderCheckbox: multiSelect,
  useCheckboxes: true,
})

export const getUCLTableProps = ({ state, onClickTagInRow, ...rest }: GetUCLTablePropsParams) => {
  const { currentTag, lazyLoading, listsState, tagsState } = state
  const baseProps = getTableProps({
    state,
    ...rest,
  })
  return {
    ...baseProps,
    selectedTagId: currentTag?.id,
    tagAction: onClickTagInRow,
    tags: tagsState.tags,
    loading: lazyLoading || listsState.listsLoading,
  }
}
