import React, { FC, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'

import Loader from '@components/Loader/Loader'
import globals from '@utils/const/globals'

import allPublicRoutes, { RouteConfig } from './allPublicRoutes'
import ErrorBoundary from '../ErrorBoundary'

const getRoutes = (routes: RouteConfig[]) =>
  routes.map((route) => {
    if (route.noProd && globals.isProd()) return null

    if (route.component) {
      return (
        <ErrorBoundary key={route.path}>
          <Route path={route.path} component={route.component} exact={route.exact} />
        </ErrorBoundary>
      )
    }
    return <Route key={route.path} path={route.path} />
  })

const PublicRoutes: FC = () => {
  return (
    <Suspense fallback={<Loader center />}>
      <Switch>{...getRoutes(allPublicRoutes)}</Switch>
    </Suspense>
  )
}

export default PublicRoutes
