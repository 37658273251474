import React, { DragEvent, FC, ReactNode, useCallback, useRef, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { DEFAULT_MAX_FILE_SIZE } from '@components/DropZone/DropZone.constants'
import Label from '@components/Label/Label'
import SectionHeadline from '@components/SectionHeadline'
import Spinner from '@components/Spinner/Spinner'
import Svg, { SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { FormatFile } from '@const/globals'

import './DropZone.css'

export interface DropZoneProps {
  className?: string
  dataTest?: string
  onFileSelected?: (file: File[]) => void
  accept?: string
  selectFileText?: string
  errorTitle?: string
  errorStateSelectFileText?: string
  errorSvg?: SvgNames
  dragAndDropText?: string
  altUpload?: { text: string; onClick: () => void; asButton?: boolean; asFloatButton?: boolean }
  isError?: boolean
  errorMsg?: ReactNode
  maxSize?: string
  showFileLoaded?: boolean
  fileName?: string
  handleRemoveFile?: () => void
  fileSize?: number
  isUploading?: boolean
  customText?: ReactNode
  disabled?: boolean
  disabledTooltip?: string
  disableDragAndDrop?: boolean
  customImportTrigger?: ReactNode
}

const rootClass = 'drop-zone'

export interface State {
  dragState: string
}

enum dropZoneStates {
  BASE = 'base',
  DRAGOVER = 'dragOver',
  DRAGLEAVE = 'dragLeave',
  DROP = 'drop',
}

const DropZone: FC<DropZoneProps> = (props: DropZoneProps) => {
  const {
    accept,
    onFileSelected,
    dataTest = rootClass,
    className = '',
    selectFileText,
    errorTitle,
    errorStateSelectFileText,
    errorSvg,
    dragAndDropText,
    altUpload,
    isError = false,
    errorMsg,
    maxSize = DEFAULT_MAX_FILE_SIZE,
    showFileLoaded,
    fileName,
    handleRemoveFile,
    fileSize,
    isUploading = false,
    disabled = false,
    disabledTooltip,
    customText,
    disableDragAndDrop,
    customImportTrigger,
  } = props
  const { t } = useTranslation()
  const [state, setState] = useState<dropZoneStates>(dropZoneStates.BASE)
  const fileRef = useRef<HTMLInputElement | null>(null)

  const updateState = useCallback((e: any, eventState: dropZoneStates) => {
    e.preventDefault()
    e.stopPropagation()
    setState(eventState)
  }, [])

  const selectFile = useCallback(
    (e: any, eventState?: dropZoneStates) => {
      e.preventDefault()
      e.stopPropagation()
      if (!disabled) {
        const files = eventState ? e.dataTransfer.files : e.target.files
        onFileSelected && onFileSelected(files)
      }
      setState(dropZoneStates.BASE)
    },
    [onFileSelected]
  )

  const getOnChangeCallback = useCallback(
    (state: dropZoneStates) => (e: DragEvent<HTMLDivElement>) => {
      updateState(e, state)
    },
    [updateState]
  )

  const handleOnDrop = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      selectFile(e, dropZoneStates.DROP)
    },
    [selectFile]
  )

  const renderUploadSection = () => {
    return (
      <>
        <Svg name={SvgNames.uploadCloud2} className={`${rootClass}__upload-cloud-icon`} />
        <div className={classNames(`${rootClass}__text`, `${className}__dropzone-text`)}>
          {customImportTrigger ?? (
            <>
              <Label htmlFor="selectFile" display={'inline'}>
                <Typography inline text={t(selectFileText)} weight={TextWeight.MEDIUM} type={TextType.LINK} />
              </Label>
              <input id="selectFile" accept={accept} type="file" hidden onChange={selectFile} ref={fileRef} disabled={disabled} />
              {customText ?? (
                <Typography
                  text={` ${dragAndDropText ? t(dragAndDropText) : t('DragAndDropHere.Text', { maxSize })}`}
                  type={TextType.BODY_TEXT}
                  inline
                />
              )}
            </>
          )}
        </div>
        {altUpload && (
          <>
            <div className={`${rootClass}__division`}>
              <Typography text={t('or')} type={TextType.BODY_TEXT_LIGHT} />
            </div>
            {altUpload.asButton || altUpload.asFloatButton ? (
              <Button
                className={`${rootClass}__upload-button`}
                buttonType={altUpload.asFloatButton ? ButtonType.OUTLINE : ButtonType.WHITE}
                onClick={altUpload.onClick}
                disabled={disabled}
                dataTest={`${dataTest}-alt-upload-button`}
              >
                {altUpload.asFloatButton && (
                  <Svg name={SvgNames.images} type={SvgType.ICON} fill={SvgColor.TEXT_TEAL} className={`${rootClass}__browse-button-svg`} />
                )}
                {t(altUpload.text)}
              </Button>
            ) : (
              <Button
                fullWidth
                buttonType={ButtonType.TEXT}
                className={`${rootClass}__upload-link`}
                onClick={altUpload.onClick}
                disabled={disabled}
                dataTest={`${dataTest}-alt-upload-link`}
              >
                <Typography text={t(altUpload.text)} weight={TextWeight.MEDIUM} type={TextType.LINK} />
              </Button>
            )}
          </>
        )}
      </>
    )
  }

  return (
    <div
      className={classNames(rootClass, className, {
        [`${rootClass}__drag-over`]: state === dropZoneStates.DRAGOVER,
        [`${rootClass}__error`]: isError,
        [`${rootClass}__file-loaded`]: showFileLoaded,
      })}
      data-test={dataTest}
      onDragEnter={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      onDragOver={disableDragAndDrop ? undefined : getOnChangeCallback(dropZoneStates.DRAGOVER)}
      onDragLeave={disableDragAndDrop ? undefined : getOnChangeCallback(dropZoneStates.BASE)}
      onDrop={disableDragAndDrop ? undefined : handleOnDrop}
    >
      {isUploading ? (
        <Spinner headline={'Uploading now...'} text={'Just a moment while your file is being uploaded'} />
      ) : isError ? (
        <>
          <Svg
            name={errorSvg ?? SvgNames.inputStatusInvalid}
            type={SvgType.VERY_LARGE_ICON}
            fill={SvgColor.REMOVE_RED}
            dataTest={`${dataTest}-error-svg`}
          />
          <SectionHeadline>{t(errorTitle ?? 'Drop.Zone.Error.Title')}</SectionHeadline>
          {errorMsg && <Typography text={errorMsg} className={`${rootClass}__error-message`} />}
          <Button
            className={`${rootClass}__upload-button`}
            buttonType={ButtonType.WHITE}
            dataTest={`${dataTest}-error-select-button`}
            onClick={() => (altUpload?.asFloatButton ? altUpload.onClick() : fileRef.current?.click())}
          >
            {t(errorStateSelectFileText ?? selectFileText)}
            <input id="selectFile" accept={accept} type="file" hidden onChange={selectFile} ref={fileRef} />
          </Button>
          {altUpload?.asButton && (
            <Button
              buttonType={ButtonType.TEXT}
              className={`${rootClass}__upload-link`}
              onClick={altUpload.onClick}
              dataTest={`${dataTest}-error-alt-button`}
            >
              <Typography text={t(altUpload.text)} type={TextType.LINK} weight={TextWeight.MEDIUM} />
            </Button>
          )}
        </>
      ) : showFileLoaded ? (
        <div className={`${rootClass}__file`}>
          <Svg className={`${rootClass}__file-svg`} name={SvgNames.blankMessage} />
          <div className={`${rootClass}__file-info`}>
            <div className={`${rootClass}__file-name-wrapper`}>
              <Typography
                className={classNames(`${rootClass}__file-name`, 'ellip')}
                text={fileName}
                type={TextType.BODY_TEXT_SMALL}
                weight={TextWeight.BOLD}
                lineHeight={LineHeight.MEDIUM_SMALL}
              />
              <Button buttonType={ButtonType.ICON} className={`${rootClass}__file-remove-button`} onClick={handleRemoveFile}>
                <Svg className={`${rootClass}__file-remove-icon`} name={SvgNames.close} type={SvgType.SMALL_ICON} fill={SvgColor.TEXT_GRAY} />
              </Button>
            </div>
            <Typography text={FormatFile.readableBytes(fileSize ?? 0)} type={TextType.BODY_TEXT_LIGHT_TINY} lineHeight={LineHeight.SMALL} />
          </div>
        </div>
      ) : (
        <>
          {disabled ? (
            <Tooltip alwaysShowOnHover disabledTrigger={true} trigger={renderUploadSection()}>
              {disabledTooltip}
            </Tooltip>
          ) : (
            renderUploadSection()
          )}
        </>
      )}
    </div>
  )
}

export default DropZone
