import React, { FC } from 'react'

import Highcharts, { DashStyleValue, OptionsTickmarkPlacementValue, XAxisOptions } from 'highcharts'

import Chart from '@components/Chart/Chart'
import { ChartProps, LegendLocation, tooltipPointsFormatter, topLegendProps } from '@const/Chart.constants'

const rootClass = 'line-chart'

//Make legend on line charts square instead of a line
// @ts-ignore
Highcharts.seriesTypes.line.prototype.drawLegendSymbol = Highcharts.seriesTypes.bar.prototype.drawLegendSymbol

export interface LineChartProps extends ChartProps {
  isDateTime?: boolean
  dateFormat?: string
  showTick?: boolean
  isDashed?: boolean
}

const LineChart: FC<LineChartProps> = (props: LineChartProps) => {
  const {
    fields,
    title = '',
    subtitle = '',
    ExtraElement,
    categories,
    hideLegend,
    isDateTime,
    dateFormat,
    tickInterval,
    tooltipValueSuffix,
    tooltip: tooltipOptions,
    plotOptions,
    disablePrint,
    dataTest = rootClass,
    className = '',
    loading,
    error,
    noContainer,
    showTick = true,
    axisLabelsStyle,
    xAxisOptions,
    yAxisLabels,
    legendOptions,
    exportingOptions,
    chartMarginTop,
    chartHeight,
    isDashed,
    events,
    showTicksOnEmptyData,
    hideXAxisLine,
  } = props

  const chartColumnData: Array<any> = categories
    ? fields
    : fields.map((field) => ({
        name: field.name,
        data: isDateTime ? field.data : [field.data],
        color: field.color,
      }))

  const xAxisBaseProps: XAxisOptions = {
    categories,
    ...xAxisOptions,
  }

  if (axisLabelsStyle) {
    const style = xAxisBaseProps.labels?.style ? { ...xAxisBaseProps.labels.style, ...axisLabelsStyle } : axisLabelsStyle
    xAxisBaseProps.labels = { ...xAxisBaseProps.labels, style }
  }

  if (isDateTime) {
    xAxisBaseProps.type = 'datetime'
    xAxisBaseProps.labels = { ...xAxisBaseProps.labels, format: dateFormat ?? '{value:%b %e, %Y}' }
  }

  const xAxisTickInterval = tickInterval ? { tickInterval } : {}

  const xAxis = {
    ...xAxisBaseProps,
    ...xAxisTickInterval,
    categories,
    crosshair: { width: 2, color: '#E3E3E3' },
    tickmarkPlacement: 'on' as OptionsTickmarkPlacementValue,
    tickWidth: (categories || isDateTime) && showTick ? 1 : 0,
  }

  const yAxis = {
    gridLineDashStyle: isDashed ? ('LongDash' as DashStyleValue) : undefined,
    title: {
      text: null,
    },
    labels: {
      ...yAxisLabels,
      style: {
        ...axisLabelsStyle,
      },
    },
  }

  const tooltip = {
    ...tooltipPointsFormatter,
    headerFormat: '<b>{point.key}</b><br>',
    shared: true,
    valueSuffix: tooltipValueSuffix,
  }

  const pointStart = isDateTime ? {} : { pointStart: 1 }

  const defaultPlotOptions = {
    series: {
      ...pointStart,
      lineWidth: 2,
      marker: { enabled: false, symbol: 'circle', radius: 4 },
    },
    spline: { pointPlacement: 'on' },
    ...plotOptions,
  }

  const legend = {
    ...topLegendProps,
    ...legendOptions,
  }

  return (
    <Chart
      series={chartColumnData}
      chartType="spline"
      chartMarginTop={hideLegend ? 66 : chartMarginTop}
      title={title}
      subtitle={subtitle}
      xAxis={xAxis}
      yAxis={yAxis}
      plotOptions={defaultPlotOptions}
      legend={hideLegend ? { enabled: false } : legend}
      legendLocation={LegendLocation.TOP}
      tooltip={tooltipOptions || tooltip}
      ExtraElement={ExtraElement}
      disablePrint={disablePrint}
      dataTest={dataTest}
      className={className}
      loading={loading}
      error={error}
      noContainer={noContainer}
      exportingOptions={exportingOptions}
      chartHeight={chartHeight}
      events={events}
      showTicksOnEmptyData={showTicksOnEmptyData}
      hideXAxisLine={hideXAxisLine}
    />
  )
}

export default LineChart
