import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import DropDown from '@components/DropDown'
import { DropDownType } from '@components/DropDown/DropDown'
import DropDownActions from '@components/DropDownActions/DropDownActions'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import TooltipButton from '@components/TooltipButton/TooltipButton'
import { useTranslation } from '@const/globals'

import './MediaCardActions.css'

type ActionType = { key: string; icon?: SvgNames; onClick: (event: React.MouseEvent<HTMLButtonElement>) => void }
export type MediaCardActionType = ActionType & { icon: SvgNames; tooltip: string }
export type MediaCardDropdownActionType = ActionType & { text: string; topSection?: boolean; hidden?: boolean }

interface Props {
  actions?: MediaCardActionType[]
  dropdownActions?: MediaCardDropdownActionType[]
  onDropdownOpenChange?: (open: boolean) => void
  className?: string
  dataTest?: string
}

const rootClass = 'media-card-actions'

const MediaCardActions: FC<Props> = (props: Props) => {
  const { actions, dropdownActions, onDropdownOpenChange, className, dataTest = rootClass } = props
  const { t } = useTranslation()
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)

  useEffect(() => onDropdownOpenChange && onDropdownOpenChange(dropdownOpen), [dropdownOpen, onDropdownOpenChange])

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {actions?.map(({ icon, onClick, tooltip, key }) => (
        <TooltipButton key={key} title={tooltip} onClick={onClick} dataTest={`${dataTest}-${key}-button`} className={`${rootClass}__button`}>
          <Svg name={icon} type={SvgType.LARGE_ICON} />
        </TooltipButton>
      ))}
      {dropdownActions && (
        <DropDown
          isOpen={dropdownOpen}
          toggleOpen={setDropdownOpen}
          hasOverflowIcon
          overflowIconTooltipText={t('More actions')}
          align="start"
          type={DropDownType.STYLED}
          className={`${rootClass}__dropdown`}
          dataTest={`${dataTest}-dropdown`}
        >
          <DropDownActions menuItems={dropdownActions} closeDropDown={() => setDropdownOpen(false)} />
        </DropDown>
      )}
    </div>
  )
}

export default MediaCardActions
