import { ApolloClient } from '@apollo/client'
import { SavingMessage } from '@components/AutoSavingStatus/AutoSavingStatus'
import createSmsComposerState from '@graphql/mutations/createSmsComposerState'
import editMessageDraft from '@graphql/mutations/editMessageDraft'
import scheduleSmsLaunch from '@graphql/mutations/scheduleSmsLaunch'
import sendMessage from '@graphql/mutations/sendMessage'
import updateComposerState from '@graphql/mutations/updateComposerState'
import {
  CreateSmsComposerStateMutation,
  CreateSmsComposerStateMutationVariables,
  EditMessageDraftMutation,
  EditMessageDraftMutationVariables,
  ScheduleSmsLaunchMutation,
  ScheduleSmsLaunchMutationVariables,
  SendMessageMutation,
  SendMessageMutationVariables,
  UpdateComposerStateMutation,
  UpdateComposerStateMutationVariables,
} from '@graphql/types/mutation-types'
import { MarketingList, MarketingListResponse, RecipientsPerCreditMultiplierResponse, SmsComposerState } from '@graphql/types/query-types'
import { OPT_IN_PERSONALIZATION } from '@src/pages/sms/utils/sms.utils'
import { logNewRelicError } from '@utils/new-relic.utils'

import { replacePersonalizationWithX } from './personalizationReplacement'
import SegmentedMessage from './segmentationCalculator'
import { MAX_MESSAGE_LENGTH, SMSMessage, State } from '../EditSMSContainer'

export const createMessage = async (setContainerValues: Function, containerValues: State, client: ApolloClient<any>) => {
  try {
    const data = await client.mutate<CreateSmsComposerStateMutation, CreateSmsComposerStateMutationVariables>({
      mutation: createSmsComposerState,
    })

    if (data.data) {
      setContainerValues({
        ...containerValues,
        smsMessage: {
          ...containerValues.smsMessage,
          launchId: data.data.createSmsComposerState.launchId,
        },
        senderCode: data.data.createSmsComposerState.senderCode,
        availableCredits: data.data.createSmsComposerState.creditBalance,
        smsNextMonthCredits: data.data.createSmsComposerState.smsNextMonthCredits,
        internationalSendingEnabled: data.data.createSmsComposerState.internationalSendingEnabled,
      })
    } else {
      setContainerValues({
        ...containerValues,
        loading: false,
        pageError: true,
      })
      logNewRelicError(data.errors)
    }
  } catch {
    setContainerValues({ ...containerValues, loading: false, pageError: true })
  }
}

const getUSCanadaContacts = (recipientsPerCreditMultiplier: RecipientsPerCreditMultiplierResponse) => {
  return recipientsPerCreditMultiplier.contactsPerCountry.reduce((acc, currContactsPerCountry) => {
    return (
      acc + (currContactsPerCountry.country === 'United States' || currContactsPerCountry.country === 'Canada' ? currContactsPerCountry.contacts : 0)
    )
  }, 0)
}

export const calculateCreditsByRecipients = (
  recipientsPerCreditMultiplier: RecipientsPerCreditMultiplierResponse[],
  internationalSendingEnabled: boolean
) => {
  return (
    recipientsPerCreditMultiplier.reduce(
      (acc, curr) => acc + (internationalSendingEnabled ? curr.contacts : getUSCanadaContacts(curr)) * curr.creditMultiplier,
      0
    ) ?? 0
  )
}

export const getMessage = async (launchId: string, setContainerValues: Function, containerValues: State, client: ApolloClient<any>) => {
  try {
    const data = await client.mutate<EditMessageDraftMutation, EditMessageDraftMutationVariables>({
      mutation: editMessageDraft,
      variables: {
        draftId: launchId,
      },
    })

    if (data.data) {
      const editDraft = data.data.editMessageDraft
      const config = editDraft.config
      const draft = editDraft.draft

      const recipients = draft.marketingLists ? draft.marketingLists.sort((a, b) => (a.listName < b.listName ? -1 : 1)) : []
      const totalRecipients = getTotalRecipients(recipients, config.internationalSendingEnabled)
      const segmentedMessage = new SegmentedMessage(replacePersonalizationWithX(draft.messageText))
      const incompatibleCharacter = segmentedMessage.hasIncompatibleEncoding(draft.messageText)
      const recipientsWithMultiplierCredits = calculateCreditsByRecipients(draft.recipientsPerCreditMultiplier, config.internationalSendingEnabled)
      const costToSend = getUpdatedCost(containerValues, recipientsWithMultiplierCredits, segmentedMessage)
      setContainerValues({
        ...containerValues,
        smsMessage: {
          messageText: draft.messageText,
          description: draft.description,
          launchId: draft.launchId,
          title: draft.title,
          recipients: draft.marketingLists,
          recipientsPerCreditMultiplier: draft.recipientsPerCreditMultiplier,
          recipientTimezones: draft.recipientTimezones,
        },
        senderCode: config.senderCode,
        availableCredits: config.creditBalance,
        smsNextMonthCredits: config.smsNextMonthCredits,
        segmentedMessage,
        costToSend,
        totalRecipients,
        internationalSendingEnabled: config.internationalSendingEnabled,
        errors: { incompatibleCharacter },
      })
    } else {
      setContainerValues({
        ...containerValues,
        loading: false,
        pageError: true,
      })
      logNewRelicError(data.errors)
    }
  } catch {
    setContainerValues({ ...containerValues, loading: false, pageError: true })
  }
}

const cleanMessage = (smsMessage: SMSMessage): SmsComposerState => {
  return {
    launchId: smsMessage.launchId,
    title: smsMessage.title,
    messageText: smsMessage.messageText,
    description: smsMessage.description,
    marketingLists: smsMessage.recipients.map(
      (recipient: MarketingList) =>
        ({
          listId: recipient.listId,
          listType: recipient.listType,
          listName: recipient.listName,
          totalContacts: recipient.totalContacts,
          countMissingPhone: recipient.countMissingPhone,
          countDuplicate: recipient.countDuplicate,
          countNotOptedIn: recipient.countNotOptedIn,
          headers: recipient.headers,
          sampleContacts: recipient.sampleContacts,
        } as MarketingList)
    ),
    recipientsPerCreditMultiplier: smsMessage.recipientsPerCreditMultiplier,
  }
}

export const sendSMSMessageUtil = async (setContainerValues: Function, containerValues: State, client: ApolloClient<any>, initialMessage = false) => {
  const updatedMessage = cleanMessage(containerValues.smsMessage)
  try {
    const data = await client.mutate<SendMessageMutation, SendMessageMutationVariables>({
      mutation: sendMessage,
      variables: {
        billableCredits: containerValues.costToSend,
        composerState: updatedMessage,
        initialMessage,
      },
    })
    if (data.data && data.data.sendMessage) {
      setContainerValues({
        ...containerValues,
        loading: false,
        saved: true,
        sendMessageStatus: {
          showStatus: true,
          statusMessage: 'Messages are sending now.',
          successStatus: true,
        },
      })
    } else {
      setContainerValues({
        ...containerValues,
        sendMessageStatus: {
          showStatus: true,
          statusMessage: 'Something went wrong on our end. No messages have been sent.',
          successStatus: false,
        },
        errors: {
          ...containerValues.errors,
          sendFailure: true,
        },
      })
      logNewRelicError(data.errors)
    }
  } catch {
    setContainerValues({ ...containerValues, pageError: true })
  }
}

export const scheduleSMSLaunchUtil = async (
  setContainerValues: Function,
  containerValues: State,
  client: ApolloClient<any>,
  initialMessage = false
) => {
  const updatedMessage = cleanMessage(containerValues.smsMessage)
  try {
    const data = await client.mutate<ScheduleSmsLaunchMutation, ScheduleSmsLaunchMutationVariables>({
      mutation: scheduleSmsLaunch,
      variables: {
        billableCredits: containerValues.costToSend,
        composerState: updatedMessage,
        scheduledTime: containerValues.scheduledSendTime,
        isMarketerTimezone: containerValues.isMarketerTimezone,
        initialMessage,
      },
    })
    if (data && data.data?.scheduleSmsLaunch) {
      setContainerValues({
        ...containerValues,
        loading: false,
        saved: true,
        sendMessageStatus: {
          showStatus: true,
          statusMessage: 'Message has been scheduled.',
          successStatus: true,
        },
      })
    } else {
      setContainerValues({
        ...containerValues,
        sendMessageStatus: {
          showStatus: true,
          statusMessage: 'Something went wrong on our end. The message was not scheduled.',
          successStatus: false,
        },
        errors: {
          ...containerValues.errors,
          sendFailure: true,
        },
      })
      logNewRelicError(data.errors)
    }
  } catch {
    setContainerValues({ ...containerValues, pageError: true })
  }
}

export const saveMessageUtil = async (
  smsMessage: SMSMessage,
  messageCaretPosition: number,
  setContainerValues: Function,
  containerValues: State,
  client: ApolloClient<any>,
  initialMessage = false
) => {
  setContainerValues({ ...containerValues, savingMessage: SavingMessage.SAVING })
  const updatedMessage = cleanMessage(smsMessage)
  try {
    const data = await client.mutate<UpdateComposerStateMutation, UpdateComposerStateMutationVariables>({
      mutation: updateComposerState,
      variables: {
        smsMessage: updatedMessage,
        initialMessage,
      },
    })
    if (data.data) {
      const recipients = data.data.updateSmsComposerState.marketingLists
        ? data.data.updateSmsComposerState.marketingLists.sort((a, b) => (a.listName < b.listName ? -1 : 1))
        : []

      const totalRecipients = getTotalRecipients(recipients, containerValues.internationalSendingEnabled)
      const segmentedMessage = new SegmentedMessage(replacePersonalizationWithX(data.data.updateSmsComposerState.messageText))
      const recipientsWithMultiplierCredits = calculateCreditsByRecipients(
        data.data.updateSmsComposerState.recipientsPerCreditMultiplier,
        containerValues.internationalSendingEnabled
      )
      const costToSend = getUpdatedCost(containerValues, recipientsWithMultiplierCredits, segmentedMessage)
      setContainerValues({
        ...containerValues,
        smsMessage: {
          launchId: data.data.updateSmsComposerState.launchId,
          title: data.data.updateSmsComposerState.title,
          description: data.data.updateSmsComposerState.description,
          messageText: data.data.updateSmsComposerState.messageText,
          recipients,
          recipientsPerCreditMultiplier: data.data.updateSmsComposerState.recipientsPerCreditMultiplier,
          recipientTimezones: data.data.updateSmsComposerState.recipientTimezones,
        },
        segmentedMessage,
        costToSend,
        totalRecipients,
        messageCaretPosition,
        savingMessage: SavingMessage.SAVED,
      })
    } else {
      setContainerValues({
        ...containerValues,
        pageError: true,
        messageCaretPosition,
        savingMessage: SavingMessage.NONE,
      })
      logNewRelicError(data.errors)
    }
  } catch (ex) {
    const addRecipientsError = true
    setContainerValues({ ...containerValues, savingMessage: SavingMessage.NONE, errors: { addRecipientsError } })
  }
}

export const getTotalRecipients = (recipients: any, internationalSendingEnabled: boolean) => {
  const total = recipients.reduce((acc: number, recipient: MarketingListResponse) => {
    return (
      acc +
      recipient.totalContacts -
      recipient.countNotOptedIn -
      recipient.countMissingPhone -
      recipient.countDuplicate -
      (internationalSendingEnabled ? 0 : recipient.suppressedInternationalContacts)
    )
  }, 0)
  return total < 0 ? 0 : total
}

export const getUpdatedCost = (containerValues: State, recipientsWithMultiplierCredits: number, segmentedMessage?: SegmentedMessage) => {
  return (segmentedMessage ?? containerValues.segmentedMessage).segments.length * recipientsWithMultiplierCredits
}

export const updateMessageUtil = (message: string, containerValues: State, setContainerValues: Function) => {
  const segmentedMessage = new SegmentedMessage(replacePersonalizationWithX(message))
  const incompatibleCharacter = segmentedMessage.hasIncompatibleEncoding(message)
  const sizeError = segmentedMessage.size >= MAX_MESSAGE_LENGTH
  const recipientsWithMultiplierCredits = calculateCreditsByRecipients(
    containerValues.smsMessage.recipientsPerCreditMultiplier ?? [],
    containerValues.internationalSendingEnabled
  )
  const costToSend = getUpdatedCost(containerValues, recipientsWithMultiplierCredits, segmentedMessage)
  setContainerValues({
    ...containerValues,
    smsMessage: { ...containerValues.smsMessage, messageText: message },
    segmentedMessage,
    costToSend,
    errors: { sizeError, incompatibleCharacter },
  })
}

export const canSendUtil = (containerValues: State, isInitialMessage = false): boolean => {
  const sufficientBalance = containerValues.availableCredits - containerValues.costToSend >= 0
  const recipients = containerValues.totalRecipients
  const hasOptInDate = !isInitialMessage || containerValues.smsMessage.messageText.includes(OPT_IN_PERSONALIZATION)

  return (
    sufficientBalance &&
    (recipients > 0 || isInitialMessage) &&
    Object.keys(containerValues.errors).find((error) => containerValues.errors[error]) === undefined &&
    (containerValues.smsMessage.messageText?.length ?? 0) > 0 &&
    ((containerValues.smsMessage.title?.length ?? 0) > 0 || isInitialMessage) &&
    hasOptInDate
  )
}

export const canScheduleUtil = (containerValues: State, isInitialMessage = false): boolean => {
  const sufficientBalance = containerValues.smsNextMonthCredits - containerValues.costToSend >= 0
  const recipients = containerValues.totalRecipients
  const hasOptInDate = !isInitialMessage || containerValues.smsMessage.messageText.includes(OPT_IN_PERSONALIZATION)

  return (
    sufficientBalance &&
    (recipients > 0 || isInitialMessage) &&
    Object.keys(containerValues.errors).find((error) => containerValues.errors[error]) === undefined &&
    (containerValues.smsMessage.messageText?.length ?? 0) > 0 &&
    ((containerValues.smsMessage.title?.length ?? 0) > 0 || isInitialMessage) &&
    hasOptInDate
  )
}
