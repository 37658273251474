import {
  DefaultFormManagerModals,
  FormManagerAPI,
  FormManagerCappedSignUps,
  FormManagerModals,
  FormManagerPerformanceReport,
  FormManagerPerformanceReportActivity,
  FormManagerPerformanceReportSource,
  FormManagerResponsesReport,
  FormManagerSubmissionDetails,
  FormManagerValues,
} from '@src/pages/Content/Forms/FormManager/context/FormManager.context'
import { FormManagerTabs } from '@src/pages/Content/Forms/FormManager/FormManager.constants'

const modals: FormManagerModals = { ...DefaultFormManagerModals }

const submissionDetails: FormManagerSubmissionDetails = {
  id: '',
  name: '',
  type: 'list',
  contactCount: 0,
}

const cappedSignups: FormManagerCappedSignUps = {
  enabled: false,
  current: 0,
  total: 0,
}

const sources: FormManagerPerformanceReportSource = {
  name: '',
  uniqueSubmits: 0,
  submits: 0,
  views: 0,
}

const activity: FormManagerPerformanceReportActivity[] = []

const performanceReport: FormManagerPerformanceReport = {
  start: 0,
  end: 0,
  totalViews: 0,
  totalSubmits: 0,
  lastSubmit: 0,
  ipFiltering: false,
  sources,
  activity,
}

const responsesReport: FormManagerResponsesReport = {
  fields: [],
}

const values: FormManagerValues = {
  formId: '1',
  title: '',
  description: '',
  isClassic: false,
  classicFormsEnabled: false,
  submissionDetails,
  cappedSignups,
  landingPages: [],
  embeds: [],
  crmPushErrorsCount: 0,
  createdAt: new Date('3/02/2023 1:40 PM').valueOf(),
  createdBy: '',
  updatedAt: new Date('3/14/2023 1:40 PM').valueOf(),
  lastUpdatedBy: '',
  canEdit: true,
  expirationDate: new Date('3/14/2023 1:40 PM').valueOf(),
  connectedCrm: '',
  connectedWebinar: '',
  performanceReport,
  responsesReport,
  modals,
  loading: false,
  tab: FormManagerTabs.DETAILS,
  subTab: '',
  reportIFrameRef: null,
}

// eslint-disable-next-line no-console
const consoleLog = (message: string) => console.log(message)

const formManagerContextFunctions: Omit<FormManagerAPI, 'values'> = {
  setLoading: (loading: boolean) => consoleLog(`Loading ${loading}`),
  tabChange: (tab: string) => consoleLog(`New tab: ${tab}`),
  subTabChange: (subTab: string) => consoleLog(`New subTab: ${subTab}`),
  toggleModal: (modal) => consoleLog(`Toggle modal: ${modal}`),
  downloadSubmittedData: () => consoleLog('Download submitted data'),
  clearPerformanceReport: () => consoleLog('Clear performance report'),
  changeIPFiltering: () => consoleLog('Change IP Filtering'),
  deleteEmbedCode: () => consoleLog('Delete embed code'),
  getFormCRMPushErrors: () => consoleLog('Get push errors'),
  setCurrentRow: (id?: string, name?: string) => consoleLog(`set current row to {id: ${id}, name: ${name}`),
  createEditEmbedCode: (name: string, externalUrl: string, embedCodeId?: string) =>
    consoleLog(`${embedCodeId ? 'Edit' : 'Create'} form embed code. Name: ${name}, external URL: ${externalUrl}`),
  onOpenBuilderWindow: (builderWindow: Window) => consoleLog(`Opened builder window ${builderWindow.name}`),
}

export const FormManagerContextValues = {
  values,
  ...formManagerContextFunctions,
}
