import React, { FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react'

import equal from 'fast-deep-equal'

import { ProgramTrack, WebhookSubscription } from '@graphql/types/query-types'
import {
  getEndpointsByStep,
  getStepsIdsByEndpoints,
} from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditOutgoingWebhookStep/utils/EditOutgoingWebhookStep.utils'
import { useOutgoingWebhookStep } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditOutgoingWebhookStep/utils/useOutgoingWebhookStep'
import {
  ProgramOutgoingWebhooksContext,
  programOutgoingWebhooksInitialState,
  ProgramOutgoingWebhooksState,
} from '@src/pages/programs/edit/components/ProgramOutgoingWebhooks/ProgramOutgoingWebhooks.context'
import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'
import { useAccountSettings } from '@utils/account/account.utils'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'
import { ProgramOutgoingWebhookStepExt, ProgramStepType } from '@utils/program/program.constants'

interface ProgramOutgoingWebhooksProps {
  children?: ReactNode
}

const ProgramOutgoingWebhooks: FC<ProgramOutgoingWebhooksProps> = (props: ProgramOutgoingWebhooksProps) => {
  const { children } = props

  const {
    setContainerValues,
    values: { isSaving, loading, program, programUrlId, isNew },
  } = useContext(ProgramManagerContext)

  const [state, setState] = useState<ProgramOutgoingWebhooksState>(programOutgoingWebhooksInitialState)
  const { endpoints, defaultEndpoints, loadingEndpoints, isSavingEndpoints } = state

  const update = useDeepUpdate(setState)

  const { hasOutgoingWebhookStep } = useAccountSettings()

  const { loadEndpoints, saveEndpoints } = useOutgoingWebhookStep()

  const currentOutgoingWebhooksSteps = useMemo(() => {
    return program.tracks
      .map(({ steps }) => steps.filter(({ stepType }) => stepType === ProgramStepType.OUTGOING_WEBHOOK) as ProgramOutgoingWebhookStepExt[])
      .flat()
  }, [program.tracks])

  const loadWebhooksEndpoints = async () => {
    update({ loadingEndpoints: true })
    try {
      const endpoints = await loadEndpoints()
      update({ endpoints, defaultEndpoints: endpoints })
    } finally {
      update({ loadingEndpoints: false })
    }
  }

  const areEndpointsEqual = (a: WebhookSubscription[], b: WebhookSubscription[]): boolean =>
    a.every((endpoint, index) => equal([...(endpoint.channels ?? [])].sort(), [...(b[index].channels ?? [])]?.sort()))

  const getUpdatedEndpointsFromStep = (step: ProgramOutgoingWebhookStepExt, endpoints: WebhookSubscription[]): WebhookSubscription[] => {
    const { stepId, endpoints: stepEndpointsIds = [] } = step
    if (programUrlId && stepId) {
      const stepEndpointChannel = `${programUrlId}_${stepId}`
      return endpoints.map((endpoint) => {
        const { channels = [], endpointId = '' } = endpoint
        const stepIncludesEndpoint = stepEndpointsIds.includes(endpointId)
        const endpointIncludesStep = channels.includes(stepEndpointChannel)
        if (stepIncludesEndpoint !== endpointIncludesStep) {
          return {
            ...endpoint,
            channels: stepIncludesEndpoint ? [...channels, stepEndpointChannel] : channels.filter((channel) => channel !== stepEndpointChannel),
          }
        }
        return endpoint
      })
    } else {
      return endpoints
    }
  }

  const removeDeletedStepsFromEndpoints = (endpoints: WebhookSubscription[], steps: ProgramOutgoingWebhookStepExt[]): WebhookSubscription[] => {
    const stepIds = steps.map((step) => step.stepId)
    return endpoints.map((endpoint) => ({
      ...endpoint,
      channels: endpoint.channels?.filter(
        (channel = '') => !channel.startsWith(`${programUrlId}_`) || stepIds.some((stepId) => channel === `${programUrlId}_${stepId}`)
      ),
    }))
  }

  useEffect(() => {
    if (!isNew && !isSavingEndpoints && !loadingEndpoints && !loading) {
      let updatedEndpoints = currentOutgoingWebhooksSteps.reduce(
        (updatedEndpoints: WebhookSubscription[], step) => getUpdatedEndpointsFromStep(step, updatedEndpoints),
        endpoints
      )
      updatedEndpoints = removeDeletedStepsFromEndpoints(updatedEndpoints, currentOutgoingWebhooksSteps)
      if (!areEndpointsEqual(updatedEndpoints, endpoints)) {
        update({ endpoints: updatedEndpoints })
      }
    }
  }, [currentOutgoingWebhooksSteps])

  const setEndpointChannels = ([id, stepsIds]: [string, string[]]): WebhookSubscription => {
    const endpoint = endpoints?.find(({ endpointId }) => endpointId === id)
    const defaultChannels = (endpoint?.channels ?? []).filter(
      (channel = '') => !channel.startsWith(`${programUrlId}_s`) && !channel.startsWith(`${programUrlId}_newstep`)
    )
    const currentProgramChannels = stepsIds.map((stepId = '') => `${programUrlId}_${stepId}`)
    return { ...endpoint, channels: [...defaultChannels, ...currentProgramChannels] }
  }

  const getUpdatedEndpoints = (outgoingWebhooksSteps: ProgramOutgoingWebhookStepExt[]): WebhookSubscription[] => {
    const stepsByEndpointsMap = getStepsIdsByEndpoints(outgoingWebhooksSteps, program, programUrlId, endpoints)
    return Array.from(stepsByEndpointsMap.entries()).map(setEndpointChannels)
  }

  useEffect(() => {
    if (isSavingEndpoints && !isSaving && !isNew && !loading) {
      const updatedEndpoints = getUpdatedEndpoints(currentOutgoingWebhooksSteps)
      if (!areEndpointsEqual(updatedEndpoints, defaultEndpoints)) {
        const doSave = async () => {
          await saveEndpoints(updatedEndpoints)
          await loadWebhooksEndpoints()
          update({ isSavingEndpoints: false })
        }
        doSave()
      }
    }
  }, [isSavingEndpoints, isSaving, loading])

  useEffect(() => {
    if (isSaving) {
      update({ isSavingEndpoints: true })
    }
  }, [isSaving])

  useEffect(() => {
    if (hasOutgoingWebhookStep) {
      loadWebhooksEndpoints()
    }
  }, [])

  const addEndpointsToStepId = (endpointsByStepId: Record<string, string[]> | null, currentStep: ProgramOutgoingWebhookStepExt) => {
    if (currentStep.endpoints && currentStep.endpoints.length === 0) {
      const currentStepEndpoints = getEndpointsByStep(currentStep, programUrlId, endpoints)
      if (currentStepEndpoints.length) {
        return { ...endpointsByStepId, [currentStep.stepId]: currentStepEndpoints }
      }
    }
    return endpointsByStepId
  }

  const getUpdatedTracks = (endpointsIdByStepId: Record<string, string[]>): ProgramTrack[] => {
    return program.tracks.map((track) => ({
      ...track,
      steps: track.steps.map((step) => ({
        ...step,
        ...(step.stepType === ProgramStepType.OUTGOING_WEBHOOK &&
          step.stepId &&
          endpointsIdByStepId[step.stepId] && { endpoints: endpointsIdByStepId[step.stepId] }),
      })),
    }))
  }

  useEffect(() => {
    if (!isNew && programUrlId && endpoints.length > 0) {
      const endpointsIdByStepId = currentOutgoingWebhooksSteps.reduce(addEndpointsToStepId, null)
      if (endpointsIdByStepId) {
        const updatedTracks = getUpdatedTracks(endpointsIdByStepId)
        if (!equal(updatedTracks, program.tracks)) {
          setContainerValues({ program: { ...program, tracks: updatedTracks } })
        }
      }
    }
  }, [currentOutgoingWebhooksSteps, endpoints])

  return <ProgramOutgoingWebhooksContext.Provider value={{ values: state, update }}>{children}</ProgramOutgoingWebhooksContext.Provider>
}

export default ProgramOutgoingWebhooks
