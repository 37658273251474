import React from 'react'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'

const rootClass = 'data-cards'

export const renderArrow = (isPositive: boolean) => (
  <Svg
    name={isPositive ? SvgNames.arrowUpPlain : SvgNames.arrowDownPlain}
    fill={isPositive ? SvgColor.SUCCESS_GREEN : SvgColor.REMOVE_RED}
    type={SvgType.ICON_MEDIUM}
    className={`${rootClass}__card-arrow-icon`}
    dataTest={`${rootClass}__card-arrow-icon`}
  />
)
