import React, { FC } from 'react'

import { ApolloError } from '@apollo/client'
import ProgramHeader from '@complex/ProgramHeader/ProgramHeader'
import { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import PageContainer from '@components/PageContainer'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { ColWithTooltip } from '@components/Table/components/tableColumns'
import { TableColumn } from '@components/Table/Table'
import TableWithEmptyListing from '@components/TableWithEmptyListing/TableWithEmptyListing'
import { useTranslation } from '@const/globals'
import withLoadingAndError from '@hoc/withLoadingAndError/withLoadingAndError'
import { ProgramContactList } from '@src/pages/programs/dashboard/components/ProgramContacts/ProgramContactsContainer'
import { ContactViewPanel, openContactView } from '@utils/contacts'
import { SortByState } from '@utils/sms.utils'

import './ProgramSuppressions.css'

interface Props {
  title: string
  loading: boolean
  pageError?: ApolloError
  data: ProgramContactList[]
  programId: string
  totalCount: number
  pageSize: number | 50
  pageIndex: number | 0
  pageCount: number
  fetchData: (pageIndex: number, pageSize: number) => void
  onChangeSort: (sortBy: Array<any>) => void
  sortBy?: SortByState[]
  searchIncomingValue?: string
  searchPlaceholder?: string
  linkOptions?: {}
  onSearch: (query: string) => void
  resetPageIndex: boolean
  removeContact: (recId: string) => void
  dataTest?: string
  isEmptySearch: boolean
  downloadUrl: string
  tableTitle: string
}

export interface State {
  searchQuery?: string
}

const rootClass = 'program-suppressions'

const ProgramSuppressions: FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  const {
    dataTest = rootClass,
    title,
    loading,
    resetPageIndex,
    data,
    onSearch,
    fetchData,
    onChangeSort,
    isEmptySearch,
    totalCount,
    pageSize,
    pageIndex,
    pageCount,
    sortBy = [],
    searchIncomingValue = '',
    searchPlaceholder = t('Search list'),
    downloadUrl,
    tableTitle,
  } = props

  const renderCell = (row: any) => <ColWithTooltip row={row} />
  const className = isEmptySearch ? `${rootClass}__empty-table` : `${rootClass}__table`

  const columns: TableColumn[] = [
    {
      Header: 'Name',
      accessor: 'name',
      align: 'left',
      Cell: renderCell,
      minWidth: 100,
      maxWidth: 100,
      sortType: 'caseInsensitive',
    },
    {
      Header: 'Email',
      accessor: 'email',
      align: 'left',
      Cell: renderCell,
      minWidth: 220,
      flexColumn: true,
      sortType: 'statusDateTime',
    },
    {
      Header: 'Source List',
      accessor: 'listName',
      align: 'left',
      Cell: renderCell,
      minWidth: 100,
      maxWidth: 100,
      sortType: 'caseInsensitive',
    },
    {
      Header: 'Date Added',
      accessor: 'when',
      align: 'left',
      Cell: renderCell,
      minWidth: 200,
      maxWidth: 200,
      sortType: 'statusDateTime',
    },
    {
      Header: 'Reason',
      accessor: 'reason',
      align: 'left',
      Cell: renderCell,
      minWidth: 130,
      maxWidth: 130,
      sortType: 'caseInsensitive',
    },
    {
      Header: 'Details',
      accessor: 'reasonDetails',
      align: 'left',
      Cell: renderCell,
      minWidth: 130,
      maxWidth: 130,
      sortType: 'caseInsensitive',
    },
  ]

  const onRowClicked = (row: any) => {
    openContactView(row?.original.recId, ContactViewPanel.SUMMARY)
  }

  const emptyListingProps = {
    headline: t('No results found'),
    imgSrc: StaticImageNames.emptySearch,
    text: t(`There were no contacts matching your search.`),
    size: EmptyListingSize.MEDIUM,
    className: className,
  }

  const renderTable = () => {
    return (
      <TableWithEmptyListing
        {...emptyListingProps}
        className={className}
        data-test={`${dataTest}`}
        data={data}
        loading={loading}
        columns={columns}
        canPaginate
        onSort={onChangeSort}
        fetchData={fetchData}
        controlledPageCount={pageCount}
        disableSortRemove
        manualSortBy
        disableMultiSort
        initialState={{ pageSize, pageIndex, sortBy }}
        onRowClicked={onRowClicked}
        resetPageIndex={resetPageIndex}
      />
    )
  }

  return (
    <PageContainer className={rootClass} data-test={dataTest}>
      <PositionContainer>
        <ProgramHeader
          primaryText={t(title)}
          downloadUrl={downloadUrl}
          tableTitle={t(tableTitle)}
          showReentryWarning={false}
          searchIncomingValue={searchIncomingValue}
          searchPlaceholder={searchPlaceholder}
          totalCount={totalCount}
          onSearch={onSearch}
        />
        {renderTable()}
      </PositionContainer>
    </PageContainer>
  )
}

export default withLoadingAndError(ProgramSuppressions)
