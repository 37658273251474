import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'
// eslint-disable-next-line no-restricted-imports
import { isMatch } from 'date-fns'

import Button, { ButtonType } from '@components/Button'
import Container from '@components/Container'
import Input from '@components/Input/Input'
import InputWithStatus from '@components/InputWithStatus/InputWithStatus'
import Loader from '@components/Loader'
import MoreOptions from '@components/MoreOptions/MoreOptions'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType } from '@components/Typography/Typography'
import { INPUT_DEBOUNCE_TIME, useTranslation } from '@const/globals'
import { ProgramFieldSetStep } from '@graphql/types/query-types'
import { DataTypeList } from '@src/pages/importcontacts/context/ImportContactsContext'
import {
  ChangeFieldRules,
  ChangeFieldRulesLabels,
} from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/components/ListProgramStep/components/ChangeFieldStepEditor/utils/ChangeFieldStepEditor.constants'
import StepSourceSelector from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/components/ListProgramStep/components/StepSourceSelector/StepSourceSelector'
import {
  StepErrors,
  getUpdatedStepsWithInvalidValue,
} from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/components/ListProgramStep/utils/ListProgramStep.utils'
import { useProgramStepsAndDetailsQueries } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/utils/ProgramStepsAndDetails.graphQL'
import UsePersonalizationFieldsModal from '@src/pages/listingPages/ListMaintenancePrograms/components/UsePersonalizationFieldsModal/UsePersonalizationFieldsModal'
import { ListMaintenanceProgramsContext } from '@src/pages/listingPages/ListMaintenancePrograms/context/ListMaintenancePrograms.context'
import { ListField } from '@utils/listingPage/listMaintenancePrograms.utils'

import './ChangeFieldStepEditor.css'

interface ChangeFieldStepEditorProps {
  className?: string
  dataTest?: string
  step: ProgramFieldSetStep
  onChange: (step: Partial<ProgramFieldSetStep>) => void
  stepErrors?: StepErrors
  position: number
}

const rootClass = 'change-field-step-editor'

const ChangeFieldStepEditor: FC<ChangeFieldStepEditorProps> = (props: ChangeFieldStepEditorProps) => {
  const { dataTest = rootClass, className = '', step, onChange: onChangeProp, stepErrors, position } = props

  const { missingValue = false, missingList = false } = { ...stepErrors }

  const {
    campaignId,
    fieldName,
    forceUseOfSourceList = false,
    listId = '',
    listName = '',
    listRecordCount = 0,
    not = false,
    rule = ChangeFieldRules.SET_VALUE,
    scoreSheetId,
    srcId = '',
    srcName = '',
    srcRecordCount = 0,
    value,
  } = step
  const {
    update,
    values: { programDetails, hasMarketingSource, programSourceFields, scoreSheets, campaigns, stepsWithInvalidValue },
  } = useContext(ListMaintenanceProgramsContext)

  const { sourceList = [] } = { ...programDetails }

  const defaultProgramSource = sourceList.length > 0 ? sourceList[0] : undefined
  const useDifferentFields = hasMarketingSource && listId !== defaultProgramSource?.baseId
  const [selectedListFields, setSelectedListFields] = useState<ListField[]>([])
  const [showPersonalizationModal, setShowPersonalizationModal] = useState(false)
  const [selectedFieldType, setSelectedFieldType] = useState<string>()
  const [hasValidValue, setHasValidValue] = useState(true)
  const [loadingListFields, setLoadingListFields] = useState(useDifferentFields)

  const fields = useDifferentFields ? selectedListFields : programSourceFields
  const showInputError = !value && missingValue

  const { getListSchemaRequest, validateFieldsTypesRequest } = useProgramStepsAndDetailsQueries()

  const { t } = useTranslation()

  const validateValue = (value: string, fieldType?: string) => {
    const type = fieldType ?? selectedFieldType
    if (type && !hasMarketingSource) {
      if (type === 'DATE' || type === 'DATETIME') {
        setHasValidValue(!!DataTypeList.find(({ id }) => id === type)?.formatOptions?.some((format) => isMatch(value, format)))
      } else {
        update({ isValidatingStepValue: true })
        validateFieldsTypesRequest(value, type).then(({ data }) => {
          if (data?.validateDataTypes && data.validateDataTypes.length) {
            setHasValidValue(!!data.validateDataTypes[0]?.valid)
            update({ isValidatingStepValue: false })
          }
        })
      }
    }
  }

  const onChange = (newStepData: Partial<ProgramFieldSetStep>) => {
    const hasCampaignScore = newStepData.rule === ChangeFieldRules.SET_CAMPAIGN_SCORE
    const hasScoreSheet = newStepData.rule === ChangeFieldRules.SET_CAMPAIGN_SCORE || newStepData.rule === ChangeFieldRules.SET_BEHAVIORAL_SCORE
    onChangeProp({
      ...newStepData,
      ...(hasCampaignScore && !step.campaignId && campaigns.length > 0 ? { campaignId: campaigns[0].id } : {}),
      ...(hasScoreSheet && !step.scoreSheetId && scoreSheets.length > 0 ? { scoreSheetId: scoreSheets[0].id } : {}),
    })
  }

  useEffect(() => {
    if (useDifferentFields && listId) {
      setLoadingListFields(true)
      getListSchemaRequest(listId)
        .then(({ data }) => {
          if (data?.listSchemas.length !== 0) {
            const headers = [...(data?.listSchemas[0].schema?.headers ?? [])].sort().map((field) => ({ field, type: 'TEXT' }))
            setSelectedListFields(headers)
          }
        })
        .finally(() => {
          setLoadingListFields(false)
        })
    }
  }, [listId])

  useEffect(() => {
    if (fields && fieldName) {
      const actualField = fields.find(({ field }) => field === fieldName)
      const fieldType = actualField?.type || 'TEXT'
      setSelectedFieldType(fieldType)
      if (value) {
        validateValue(value, fieldType)
      }
    }
  }, [fieldName])

  useEffect(() => {
    update({ stepsWithInvalidValue: getUpdatedStepsWithInvalidValue(stepsWithInvalidValue, position, hasValidValue) })
  }, [hasValidValue])

  const renderSetValueOptions = () => (
    <MoreOptions className={classNames(`${rootClass}__more-options`, `${rootClass}__more-options-personalization`)}>
      <Tooltip
        triggerClassName={`${rootClass}__more-options-personalization-button`}
        trigger={
          <Button disabled={selectedFieldType !== 'TEXT'} buttonType={ButtonType.FLOAT} onClick={() => setShowPersonalizationModal(true)}>
            <Svg name={SvgNames.personalization} type={SvgType.ICON} />
          </Button>
        }
      >
        {t(selectedFieldType === 'TEXT' ? 'Use personalization fields' : 'Personalization fields can only be used with text type fields')}
      </Tooltip>
      <InputWithStatus
        dataTest={`${dataTest}-set-value-input`}
        value={value}
        onChange={({ target: { value } }) => {
          onChange({ value: value || '' })
          validateValue(value)
        }}
        onChangeDebounce={INPUT_DEBOUNCE_TIME}
        /* eslint-disable-next-line jsx-a11y/no-autofocus */
        autoFocus={showInputError || !hasValidValue}
        hasCustomError={showInputError || !hasValidValue}
        showIconOnValid={false}
        customTooltipErrorMessages={t('Provide a valid value')}
      />
      <Typography
        className={classNames(`${rootClass}__set-value-input-error`, {
          [`${rootClass}__set-value-input-error-visible`]: showInputError || !hasValidValue,
        })}
        type={TextType.ERROR}
        text={t(showInputError ? 'Please enter a value' : 'Provide a valid value')}
      />
    </MoreOptions>
  )

  const showInput = rule === ChangeFieldRules.DECREMENT_BY || rule === ChangeFieldRules.INCREMENT_BY

  const getOptions = useCallback(
    (values: Partial<{ id: string; name: string }>[]) => {
      return values.map(({ id, name }) => ({
        label: name || '',
        value: id || '',
      }))
    },
    [scoreSheets, campaigns]
  )

  const scoreSheetsOptions = getOptions(scoreSheets)
  const campaignsOptions = getOptions(campaigns)

  const fieldsOptions = useMemo(
    () =>
      fields.map<SelectV2SingleOption>(({ field }) => ({
        label: field,
        value: field,
      })),
    [fields]
  )

  const fieldsRulesOptions = useMemo(
    () =>
      Object.values(ChangeFieldRules).map<SelectV2SingleOption>((value) => ({
        label: t(ChangeFieldRulesLabels[value]),
        value: value,
      })),
    []
  )

  const scoreSheetsSelectedOption = scoreSheetsOptions.find(({ value }) => value === scoreSheetId)
  const campaignsSelectedOption = campaignsOptions.find(({ value }) => value === campaignId)
  const fieldsSelectedOption = fieldsOptions.find(({ value }) => value === fieldName)
  const fieldsRulesSelectedOption = fieldsRulesOptions.find(({ value }) => value === rule)

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {loadingListFields ? (
        <Loader />
      ) : (
        <>
          {showPersonalizationModal && fieldName && (
            <UsePersonalizationFieldsModal
              listFields={fields.map(({ field }) => field)}
              onClose={() => setShowPersonalizationModal(false)}
              onInsert={(personalization) => onChange({ value: (value || '').concat(personalization) })}
              column={fieldName}
              isOpen
            />
          )}
          {hasMarketingSource && (
            <StepSourceSelector
              dataTest={`${dataTest}-source-list-selector`}
              initiallyShowListPicker={!forceUseOfSourceList}
              noSourceRadioText={t('Use current source list')}
              selectSourceRadioText={t('Use another list')}
              onChange={(newSourceData, useDefaultSource) => {
                const defaultSource = {
                  listId: defaultProgramSource?.baseId,
                  listName: defaultProgramSource?.name,
                  listRecordCount: defaultProgramSource?.count,
                }

                onChange({ ...(useDefaultSource ? defaultSource : newSourceData), forceUseOfSourceList: useDefaultSource })
              }}
              sourceId={{ listId }}
              sourceName={{ listName }}
              recordsCount={{ listRecordCount }}
              isListsOnly
              showErrorOnUnselectedSource={missingList}
            />
          )}
          <Container>
            <SelectV2
              dataTest={`${dataTest}-field-select`}
              label={t('List field to update')}
              isClearable={false}
              options={fieldsOptions}
              defaultValue={fieldsSelectedOption}
              onChange={(option) => onChange({ fieldName: option?.value })}
            />
            <div className={`${rootClass}__update-options`}>
              <div className={classNames(`${rootClass}__update-options-select`, { [`${rootClass}__update-options-select-wider`]: !showInput })}>
                <SelectV2
                  dataTest={`${dataTest}-update-value-select`}
                  label={t('Update value as follows')}
                  isClearable={false}
                  options={fieldsRulesOptions}
                  defaultValue={fieldsRulesSelectedOption}
                  onChange={(option) => onChange({ rule: option?.value })}
                />
              </div>
              {showInput && (
                <Input
                  className={`${rootClass}__update-options-input`}
                  dataTest={`${dataTest}-update-value-input`}
                  type={'number'}
                  value={value}
                  /* eslint-disable-next-line jsx-a11y/no-autofocus */
                  autoFocus={showInputError}
                  onChange={({ target: { value = '' } }) => onChange({ value })}
                  onChangeDebounce={INPUT_DEBOUNCE_TIME}
                  error={showInputError}
                />
              )}
              {rule !== ChangeFieldRules.SET_VALUE &&
                rule !== ChangeFieldRules.SET_CAMPAIGN_SCORE &&
                rule !== ChangeFieldRules.SET_BEHAVIORAL_SCORE && (
                  <Typography
                    className={classNames(`${rootClass}__update-options-input-error`, {
                      [`${rootClass}__update-options-input-error-visible`]: showInputError && showInput,
                    })}
                    type={TextType.ERROR}
                    text={t('Please enter a value')}
                  />
                )}
            </div>
            {(rule === ChangeFieldRules.SET_CAMPAIGN_SCORE || rule === ChangeFieldRules.SET_BEHAVIORAL_SCORE) && (
              <MoreOptions dataTest={`${dataTest}-score-options`} className={`${rootClass}__more-options`}>
                <SelectV2
                  isClearable={false}
                  label={t('Choose score sheet')}
                  options={scoreSheetsOptions}
                  defaultValue={scoreSheetsSelectedOption}
                  onChange={(option) => onChange({ scoreSheetId: option?.value })}
                />
                {rule === ChangeFieldRules.SET_CAMPAIGN_SCORE && (
                  <SelectV2
                    isClearable={false}
                    label={t('Choose score sheet')}
                    options={campaignsOptions}
                    defaultValue={campaignsSelectedOption}
                    onChange={(option) => onChange({ campaignId: option?.value })}
                  />
                )}
              </MoreOptions>
            )}
            {rule === ChangeFieldRules.SET_VALUE && renderSetValueOptions()}
          </Container>
          <StepSourceSelector
            initiallyShowListPicker={!!srcId}
            noSourceRadioText={t('Apply to all contacts in the program')}
            selectSourceRadioText={t('Apply if contact')}
            notInSegment={not}
            onChange={onChange}
            sourceId={{ srcId }}
            sourceName={{ srcName }}
            recordsCount={{ srcRecordCount }}
            hasSelect
            showErrorOnUnselectedSource={false}
          />
        </>
      )}
    </div>
  )
}

export default ChangeFieldStepEditor
