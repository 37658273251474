import React, { FC, ReactNode, useCallback, useMemo, useRef, useState } from 'react'

import { ApolloQueryResult, useApolloClient, useQuery } from '@apollo/client'
import getSalesUsers from '@graphql/queries/getAllSalesUsers'
import { GetAllSalesUsersQuery, LoadCrmSalesUsersQuery, QueryGetAllSalesUsersArgs, UserResponse } from '@graphql/types/query-types'
import SalesUsersContext from '@src/pages/Settings/Users/context/SalesUsersContext'
import { useAllUsers } from '@src/pages/Settings/Users/context/UsersContext'
import ImportSalesUsersModalContainer from '@src/pages/Settings/Users/tabs/SalesUsers/components/ImportSalesUsersModal/ImportSalesUsersModalContainer'
import SalesPermissionsModalContainer from '@src/pages/Settings/Users/tabs/SalesUsers/components/SalesPermissionModal/SalesPermissionsModalContainer'
import { filterNotEmptyArray } from '@utils/array'
import { logNewRelicError } from '@utils/new-relic.utils'

interface SalesUsers {
  data: {}
  loading: boolean
  networkStatus: number
}

export interface RefreshSalesUsersRef {
  refreshSalesUsers: () => Promise<SalesUsers>
  refreshCRMUsers?: () => Promise<ApolloQueryResult<LoadCrmSalesUsersQuery>>
}
interface Props {
  children: ReactNode
  loadingMutation: boolean
}

const SalesUsersContextProvider: FC<Props> = ({ children, loadingMutation }) => {
  const { isAdministrator } = useAllUsers()

  const client = useApolloClient()

  const { data, loading, error, refetch } = useQuery<GetAllSalesUsersQuery, QueryGetAllSalesUsersArgs>(getSalesUsers, {
    client,
    fetchPolicy: 'network-only',
    variables: { sortColumn: 'USER_DETAILS', sortDirection: 'ASC' },
    notifyOnNetworkStatusChange: true,
  })

  if (error) {
    logNewRelicError(error)
  }

  const salesUsers = useMemo<UserResponse[]>(() => (data?.getAllSalesUsers?.users ?? []).filter(filterNotEmptyArray), [data])
  const maxAllowedCountForPortal = useMemo<number>(() => data?.getAllSalesUsers?.maxAllowedCountForPortal || 0, [data])
  const maxAllowedCountForCrm = useMemo<number>(() => data?.getAllSalesUsers?.maxAllowedCountForCrm || 0, [data])
  const enableLaunch = useMemo<boolean>(() => data?.getAllSalesUsers?.enableSalesUserEmailApproval ?? false, [data])
  const enableImportCRM = useMemo<boolean>(() => data?.getAllSalesUsers?.salesForceConnection ?? false, [data])
  const crmUsersCount = useMemo<number>(() => salesUsers.filter(({ isCRM }) => !!isCRM).length || 0, [salesUsers])
  const portalUsersCount = useMemo<number>(() => salesUsers.filter(({ isCRM }) => !isCRM).length || 0, [salesUsers])
  const allowEditSalesPermission = useMemo<boolean>(() => isAdministrator && !!salesUsers.length, [isAdministrator, salesUsers.length])

  const [salesPermissionModalVisible, setSalesPermissionModalVisible] = useState<boolean>(false)
  const onEditSalesPermissions = useCallback(() => setSalesPermissionModalVisible(true), [])
  const onCloseSalesPermissions = useCallback(() => setSalesPermissionModalVisible(false), [])

  const [importSalesUsersModalVisible, setImportSalesUsersModalVisible] = useState<boolean>(false)
  const onImportSalesUsers = useCallback(() => setImportSalesUsersModalVisible(true), [])
  const onCloseImportSalesUsers = useCallback(() => setImportSalesUsersModalVisible(false), [])

  const refetchRefs = useRef<RefreshSalesUsersRef>({ refreshSalesUsers: refetch })

  return (
    <SalesUsersContext.Provider
      value={{
        salesUsers,
        loading: loading || loadingMutation,
        maxAllowedCountForCrm,
        maxAllowedCountForPortal,
        enableImportCRM,
        enableLaunch,
        crmUsersCount,
        portalUsersCount,
        onEditSalesPermissions,
        onImportSalesUsers,
        allowEditSalesPermission,
        refetchRefs: refetchRefs.current,
      }}
    >
      {children}
      {isAdministrator && <SalesPermissionsModalContainer isOpen={salesPermissionModalVisible} onCancel={onCloseSalesPermissions} />}
      {isAdministrator && <ImportSalesUsersModalContainer isOpen={importSalesUsersModalVisible} onCancel={onCloseImportSalesUsers} />}
    </SalesUsersContext.Provider>
  )
}

export default SalesUsersContextProvider
