import React, { FC, ReactNode, RefObject, useEffect, useRef, useState } from 'react'
import { useDrop } from 'react-dnd'

import classNames from 'classnames'

import { COMMON_DROPPABLE_TYPES } from '@complex/ListingPage/Utils/ListingPage.constants'
import { ListingPageSession } from '@complex/ListingPage/Utils/ListingPage.utils'
import CaretIcon, { CaretIconDirection } from '@components/CaretIcon'
import MenuItem, { MenuItemProps } from '@components/Menu/components/MenuItem/MenuItem'
import { SvgType } from '@components/Svg'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { TIME_BEFORE_OPENING } from '@utils/contactSegments/contactSegments.utils'
import { removeItem, setItem } from '@utils/sessionStorage'

import './CollapsibleMenuItemWithHeader.css'

export interface CollapsibleMenuItemWithHeaderProps extends MenuItemProps {
  collapsedMenu: boolean
  header: string
  children?: ReactNode
  isOpen?: boolean
  scrollAreaRef?: RefObject<HTMLDivElement>
}

export interface State {
  open: boolean
  scrollTop: boolean
  timer: any
}

const rootClass = 'collapsible-menu-item-with-header'

const CollapsibleMenuItemWithHeader: FC<CollapsibleMenuItemWithHeaderProps> = (props: CollapsibleMenuItemWithHeaderProps) => {
  const { children, collapsedMenu, header, isOpen = false, scrollAreaRef, dataTest = rootClass, className = '' } = props
  const [state, setState] = useState<State>({ open: collapsedMenu || isOpen, scrollTop: false, timer: undefined })
  const { open, scrollTop, timer } = state

  const headerRef = useRef<HTMLDivElement>(null)

  const [{ isOver }, drop] = useDrop({
    accept: open ? [] : COMMON_DROPPABLE_TYPES,
    collect: (monitor) => ({ isOver: monitor.isOver() }),
  })

  drop(headerRef)

  useEffect(() => {
    timer && clearTimeout(timer)
    if (isOver && !open) {
      const timer = setTimeout(() => setState({ ...state, open: true }), TIME_BEFORE_OPENING)
      setState({ ...state, timer })
    }
  }, [isOver])

  useEffect(() => {
    setState({ ...state, open: isOpen || open })
  }, [isOpen])

  useEffect(() => {
    const scrollArea = scrollAreaRef?.current
    if (scrollArea) {
      scrollArea.addEventListener('scroll', () => handleScroll())
      return () => scrollArea.removeEventListener('scroll', () => handleScroll())
    }
  }, [scrollAreaRef, open])

  const handleScroll = (event?: Event) => {
    const scrollArea = (event?.target as HTMLDivElement) ?? headerRef.current
    const offset = (event ? scrollArea?.scrollTop : scrollArea?.offsetTop) ?? 0
    if (open) {
      setState((state: State) => ({ ...state, scrollTop: offset > 0 }))
    }
  }

  const saveMenuStateSession = (isOpen: boolean) => {
    if (!isOpen) {
      if (header.includes('Folder')) {
        setItem(ListingPageSession.FOLDER_MENU_SECTION_CLOSED, 'true')
      } else if (header.includes('Tag')) {
        setItem(ListingPageSession.TAG_MENU_SECTION_CLOSED, 'true')
      } else {
        setItem(ListingPageSession.CUSTOM_MENU_SECTION_CLOSED, 'true')
      }
    } else {
      if (header.includes('Folder')) {
        removeItem(ListingPageSession.FOLDER_MENU_SECTION_CLOSED)
      } else if (header.includes('Tag')) {
        removeItem(ListingPageSession.TAG_MENU_SECTION_CLOSED)
      } else {
        removeItem(ListingPageSession.CUSTOM_MENU_SECTION_CLOSED)
      }
    }
  }

  const onToggleMenu = () => {
    const isOpen = collapsedMenu || !open
    saveMenuStateSession(isOpen)
    setState({ ...state, open: isOpen, scrollTop: false })
  }

  const isSticky = header.includes('Folders') || header.includes('Tags')

  return (
    <div className={classNames(rootClass, className)}>
      <div
        className={classNames(`${rootClass}__header`, {
          [`${rootClass}__header-open`]: open,
          [`${rootClass}__scrolling`]: scrollTop,
          [`${rootClass}__custom-filter`]: !isSticky,
        })}
        ref={headerRef}
      >
        <div
          role={'button'}
          tabIndex={0}
          onKeyDown={(keyDownEvent) => (keyDownEvent.key === ' ' || keyDownEvent.key === 'Enter' ? onToggleMenu() : undefined)}
          className={classNames(`${rootClass}__header-text`, {
            [`${rootClass}__header-text-collapsed`]: collapsedMenu,
          })}
          data-testid={`${dataTest}__header`}
          data-test={`${dataTest}__header`}
          onClick={() => onToggleMenu()}
        >
          {!collapsedMenu && <CaretIcon direction={CaretIconDirection.RIGHT} toggle={open} type={SvgType.VERY_SMALL_ICON} />}
          <Typography text={header} weight={TextWeight.BOLD} />
        </div>
        {children}
      </div>
      {open && <MenuItem {...props} onScroll={handleScroll} />}
    </div>
  )
}

export default CollapsibleMenuItemWithHeader
