import { Dispatch, SetStateAction } from 'react'
import { Row } from 'react-table'

import { ListPickerEmptyListingOptions } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import { Status } from '@components/StatusToast/StatusToast'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import { SyncedSegment } from '@graphql/types/microservice/crm-types'
import { FoldersWithCountQuery } from '@graphql/types/query-types'
import { BasicFolderState, Folder } from '@interface/Folder'
import { ItemSubType, ItemType } from '@utils/categorization'
import { SegmentDefinition } from '@utils/contactSegments/contactSegments.utils'
import { CRM_STATUS, CRMSourceOption } from '@utils/crm.utils'
import { FilterDefinition } from '@utils/filter'
import { ListCategory } from '@utils/lists'

export type GetSegmentDefinition = (externalId: string) => Promise<SegmentDefinition | undefined>
export type SelectedListsType = { [key in ListCategory]: any[] }

export interface ListsState {
  lists?: PreProcessedList[]
  listsLoading?: boolean
}

export interface TagsState {
  tags: LabelDto[]
  tagsLoading: boolean
}

export enum SEARCH_RESULT_LISTS {
  CONTACTS = 'contacts',
  FORM_SUBMISSIONS = 'formSubmissions',
  MARKETING = 'marketing',
  WEBINAR = 'webinar',
  EXTENSION = 'extension',
}

export const SearchResultsListCategory: { [key in SEARCH_RESULT_LISTS]: ListCategory } = {
  [SEARCH_RESULT_LISTS.CONTACTS]: ListCategory.ACCOUNTS,
  [SEARCH_RESULT_LISTS.FORM_SUBMISSIONS]: ListCategory.FORM_SUBMISSIONS,
  [SEARCH_RESULT_LISTS.MARKETING]: ListCategory.MARKETING,
  [SEARCH_RESULT_LISTS.WEBINAR]: ListCategory.WEBINAR,
  [SEARCH_RESULT_LISTS.EXTENSION]: ListCategory.EXTENSION,
}

export type SearchResultTypes = Exclude<ListCategory, ListCategory.FORM_SUBMISSIONS_UCL | ListCategory.UNIFIED_LIST | ListCategory.WEBINAR_UCL>

export const SearchResultType: { [key in SearchResultTypes]: SEARCH_RESULT_LISTS } = {
  [ListCategory.ACCOUNTS]: SEARCH_RESULT_LISTS.CONTACTS,
  [ListCategory.FORM_SUBMISSIONS]: SEARCH_RESULT_LISTS.FORM_SUBMISSIONS,
  [ListCategory.MARKETING]: SEARCH_RESULT_LISTS.MARKETING,
  [ListCategory.WEBINAR]: SEARCH_RESULT_LISTS.WEBINAR,
  [ListCategory.EXTENSION]: SEARCH_RESULT_LISTS.EXTENSION,
}

export interface ParentsHierarchy {
  [key: string]: PreProcessedList[]
}

export interface SearchState {
  count?: number
  lists?: Partial<SelectedListsType>
  query?: string
  loading?: boolean
  filterLoading?: boolean
  value?: string
  parentsHierarchy?: { [key: string]: PreProcessedList[] }
  rowsExpanded?: Row[]
}

export interface ListPickerModalState {
  allLoaded: boolean
  campaigns: Record<string, string>
  crmMapping: Record<string, { displayName: string; entityFields: Record<string, string> }>
  crmSourceOptions?: CRMSourceOption[]
  currentFilter?: FilterDefinition
  currentFolder?: Folder
  currentPage: number
  currentTag?: LabelDto
  defaultFilterId?: string
  disableLegacyLists: boolean
  disableTopListCount?: boolean
  disableUnifiedLists: boolean
  disabledLists: string[]
  disabledListTooltipText: string
  emptyListingOption: ListPickerEmptyListingOptions
  filters: FilterDefinition[]
  foldersState: BasicFolderState
  hasToExpandRows: { [key in ListCategory]: Row[] }
  hasToSetDefaultFolder?: boolean
  hasUnifiedFormSubmissions?: boolean
  headerShadow: boolean
  hideUCL?: boolean
  hideAllUCLListTypes?: boolean
  infoHoverLoading: boolean
  lazyLoading: boolean
  listType: ListCategory
  listsState: ListsState
  loading: boolean
  metadataLoading: boolean
  multiSelect: boolean
  preSelectedListsInit: boolean
  scoreSheets: Record<string, string>
  search?: SearchState
  selectedLists: SelectedListsType
  segmentTreePage?: {
    segment?: PreProcessedList
    segmentAncestors?: PreProcessedList[]
    segmentChildren?: PreProcessedList[]
    segmentTreeLoading?: boolean
    listType?: ListCategory
  }
  segmentDefinition: SegmentDefinition
  statusToast: { message: string; status: Status; showToast: boolean }
  subscriptionMapping: Record<string, string>
  supportedBehaviors: Record<string, string>
  tagsState: TagsState
  viewingSelectedLists: boolean
  isFirstLoading: boolean
  isLegacyForms: boolean
  isLegacyWebinars: boolean
  selectAllSegmentsOfSelectedList: boolean
  unifiedListData?: FoldersWithCountQuery
}

export type DisableRowByCriteria = (value: any) => boolean | undefined
type PerformSearch = (searchAll: boolean, searchInAllLists: boolean) => void
type GetCRMSourcedSegmentDetails = (segmentExternalIds: string[]) => Promise<SyncedSegment[] | undefined>
type SetClassicSegmentAncestorsTree = (segmentId: string, listType: ListCategory) => void
type SetClassicSegmentChildrenTree = SetClassicSegmentAncestorsTree
type SetSegmentAncestorsTree = (segmentId: number, listType: ListCategory) => void
type SetSegmentChildrenTree = SetSegmentAncestorsTree
export type ShowTooltipByCriteria = (row: Row) => string | undefined

export interface ListPickerModalAPI {
  values: ListPickerModalState
  setState: Dispatch<SetStateAction<ListPickerModalState>>
  disableRowByCriteria?: DisableRowByCriteria
  performSearch: PerformSearch
  getSegmentDefinition: GetSegmentDefinition
  getCRMSourcedSegmentDetails: GetCRMSourcedSegmentDetails
  setClassicSegmentAncestorsTree: SetClassicSegmentAncestorsTree
  setClassicSegmentChildrenTree: SetClassicSegmentChildrenTree
  setSegmentAncestorsTree: SetSegmentAncestorsTree
  setSegmentChildrenTree: SetSegmentChildrenTree
  showTooltipByCriteria?: ShowTooltipByCriteria
}

export interface PreProcessedList {
  id: string
  baseId: string
  categorizationId: number
  disabled: boolean
  isEditable: boolean
  name: string
  parent?: string
  position: number
  size: number
  tags?: LabelDto[]
  type: string
  subTypes?: ItemSubType[]
  subRows?: PreProcessedList[]
  updatedTime: string
  itemType: ItemType
  crmStatus?: CRM_STATUS | null
  crmErrorDetails?: string
}
