import { gql } from '@apollo/client'

export default gql`
  mutation updateExportSyncJob($exportSyncJobDTO: ExportSyncJobDTOInput) {
    updateExportSyncJob(exportSyncJobDTO: $exportSyncJobDTO) {
      accountId
      createdTime
      createdTimeExecutionOffsetDateTime
      cron {
        dayOfMonth
        dayOfWeek
        everyHour
        hours
        minutes
        month
        seconds
        year
      }
      cronString
      enabled
      errorServiceMessage
      exportDefinitionId
      exportSegmentId
      exportSyncJobExecutionDTOS {
        executionEnd
        executionEndOffsetDateTime
        executionStart
        executionStartOffsetDateTime
        exportFileId
        exportSyncJobExecutionId
        exportSyncJobId
        isRunning
        logErrorMessage
        processedBy
        status
        statusMessage
      }
      exportSyncJobId
      filePath
      hasToRunNow
      isRunning
      lastExecution
      lastExecutionOffsetDateTime
      nextExecution
      nextExecutionOffsetDateTime
      scheduleTimeZone
      status
      statusMessage
      updatedTime
      updatedTimeExecutionOffsetDateTime
    }
  }
`
