import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { TextWeight } from '@components/Typography/Typography'

import './ItemActivityDetailsSection.css'

interface Props {
  className?: string
  dataTest?: string
  children: ReactNode
  action?: Function
  actionText?: string
  title: string
}

const rootClass = 'item-activity-details-section'

const ItemActivityDetailsSection: FC<Props> = (props: Props) => {
  const { children, action, actionText, title, dataTest = rootClass, className = '' } = props

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__title`}>
        <Typography className={`${rootClass}__title-text`} text={title} weight={TextWeight.BOLD} />
        {action && actionText && <TextLink text={actionText} hideIcon size={TextLinkSize.LARGE} onClick={() => action()} />}
      </div>
      {children}
    </div>
  )
}

export default ItemActivityDetailsSection
