import React, { FC } from 'react'

import classNames from 'classnames'

import Label from '@components/Label/Label'

import { RadioProps } from '../Radio/Radio'

import './radiogroup.css'

const rootClass = 'radiogroup'

interface RadioGroupProps {
  children: React.ReactElement<RadioProps>[]
  label?: string
  verticalLayout?: boolean
  className?: string
  dataTest?: string
}

export const RadioGroup: FC<RadioGroupProps> = (props: RadioGroupProps) => {
  const { label, children, verticalLayout = false, className, dataTest = rootClass } = props

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <div
        className={classNames(`${rootClass}`, {
          [`${rootClass}--vertical`]: verticalLayout,
          [`${rootClass}--horizontal`]: !verticalLayout,
        })}
      >
        {children}
      </div>
    </div>
  )
}

export default RadioGroup
