import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import Container from '@components/Container'
import InfoStatus, { InfoStatusTypes } from '@components/InfoStatus/InfoStatus'
import { SvgNames } from '@components/Svg'
import { TableV2 } from '@components/TableV2/TableV2'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import {
  formsUpgradeMapFieldsColumns,
  renderMapToCell,
} from '@src/pages/UpgradeAssistant/components/FormsUpgradeModal/components/FormsUpgradeMapFields/utils/FormsUpgradeMapFields.utils'
import { FormFieldUsage, FormsUpgradeModalContext } from '@src/pages/UpgradeAssistant/components/FormsUpgradeModal/context/FormsUpgradeModal.context'
import { useFormsUpgradeModalRequests } from '@src/pages/UpgradeAssistant/components/FormsUpgradeModal/GraphQL/FormsUpgradeModalRequests.graphQL'
import { CellContext, SortingState } from '@tanstack/react-table'

import './FormsUpgradeMapFields.css'

interface FormsUpgradeMapFieldsProps {
  className?: string
  dataTest?: string
  unifiedListFieldMappings: UnifiedListFieldMapping[]
  loadingUnifiedListFieldMappings: boolean
}

export const defaultSortingBy: SortingState = [
  {
    id: 'status',
    desc: true,
  },
]

const rootClass = 'forms-upgrade-map-fields'

const FormsUpgradeMapFields: FC<FormsUpgradeMapFieldsProps> = (props: FormsUpgradeMapFieldsProps) => {
  const { dataTest = rootClass, className = '', unifiedListFieldMappings, loadingUnifiedListFieldMappings } = props

  const {
    update,
    values: { formFields, forms },
  } = useContext(FormsUpgradeModalContext)

  const { t } = useTranslation()

  const [loadingFormFields, setLoadingFormFields] = useState(formFields.length === 0)

  const { getFormFieldsUsageRequest } = useFormsUpgradeModalRequests()

  const hasInvalidFieldMappings = useMemo(() => formFields.some(({ isMapped, isValid }) => isMapped && !isValid), [formFields])

  const renderMapToCellCallback = useCallback(
    (cellContext: CellContext<FormFieldUsage, unknown>) => renderMapToCell(cellContext, unifiedListFieldMappings, update, t),
    [t, unifiedListFieldMappings, update]
  )

  const tableColumns = useMemo<ColumnDefWithAdditionalProps<FormFieldUsage>[]>(
    () => formsUpgradeMapFieldsColumns(renderMapToCellCallback),
    [renderMapToCellCallback]
  )

  useEffect(() => {
    if (formFields.length === 0 && forms.length > 0 && !loadingUnifiedListFieldMappings) {
      setLoadingFormFields(true)
      getFormFieldsUsageRequest(forms.map(({ externalId = '' }) => externalId))
        .then(({ data }) => {
          if (data?.getFormFieldsUsage) {
            const formFields = data.getFormFieldsUsage.reduce<FormFieldUsage[]>((formFields, formField) => {
              if (formField) {
                const uclFieldMapping = unifiedListFieldMappings?.find(({ displayName }) => displayName === formField.fieldName)
                return [
                  ...formFields,
                  {
                    fieldName: formField.fieldName,
                    formsInfo: formField.formsInfo,
                    dataType: formField.dataType,
                    fieldMapping: uclFieldMapping?.displayName,
                    isMapped: true,
                    isValid: true,
                    uclColumnIndex: uclFieldMapping?.columnIndex,
                  },
                ]
              }
              return formFields
            }, [])
            update({ formFields })
          }
        })
        .finally(() => {
          setLoadingFormFields(false)
        })
    }
  }, [loadingUnifiedListFieldMappings, forms])

  return (
    <>
      {hasInvalidFieldMappings && (
        <InfoStatus
          className={`${rootClass}__error-alert`}
          dataTest={`${dataTest}-error-alert`}
          status={InfoStatusTypes.Warning}
          svgName={SvgNames.errorAlert}
          message={
            <div>
              <Typography text={t('There are some field mapping errors:')} weight={TextWeight.BOLD} />
              <Typography text={`  •  ${t(`Duplicate field mappings on the same form are not allowed. Please fix the errors below to continue.`)}`} />
            </div>
          }
        />
      )}
      <Container className={classNames(rootClass, className)} dataTest={dataTest}>
        <Typography
          className={`${rootClass}__header`}
          text={t('Map form fields to Act-On Contacts')}
          type={TextType.SECTION_HEADER}
          weight={TextWeight.MEDIUM}
        />
        <Typography
          text={t(
            'Form fields can be mapped to your Contacts list, or be form specific. Any fields not mapped to Contacts will be recorded as a form field.'
          )}
          type={TextType.BODY_TEXT_LIGHT}
        />
        <TableV2
          sortingBy={defaultSortingBy}
          data={formFields}
          columns={tableColumns}
          loading={loadingUnifiedListFieldMappings || loadingFormFields}
          enableInsideLoader
          enableSorting
        />
      </Container>
    </>
  )
}

export default FormsUpgradeMapFields
