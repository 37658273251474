import { ReactNode } from 'react'

import { Status } from '@components/StatusToast/StatusToast'

export const rootClass = 'upgrade-log-modal'
export interface UpgradeLogModalState {
  pageIndex: number
  pageSize: number
  loading?: boolean
  items: LogItem[]
  totalItems: number
  statusToast: { statusMessage: string | ReactNode; status: Status; showStatusToast: boolean }
  showConfirmationModal: boolean
  itemToRevert?: LogItem
}

export type AssetAuditDTO = {
  accountId?: number
  assetId?: string
  assetType?: string
  createdTime?: number
  event?: string
  extraInfo?: any
  userId?: number
  userName?: string
}
export enum AssetType {
  FORM = 'FORM',
  FORM_TEMPLATE = 'FORM_TEMPLATE',
  PROGRAM = 'AUTOMATED_PROGRAM',
}

export enum EventType {
  UPGRADE = 'UPGRADE',
  FAILED_UPGRADE = 'FAILED UPGRADE',
  REVERTED_UPGRADE = 'REVERTED UPGRADE',
  FAILED_REVERTED_UPGRADE = 'FAILED REVERTED UPGRADE',
}
export interface LogItem {
  name: string
  accountId: number
  userId: number
  assetId: string
  userName: string
  assetType: AssetType
  createdTime: number
  event: EventType
  status?: string
  assetDeleted?: boolean
  extraInfo: any
}
export const defaultState: UpgradeLogModalState = {
  pageIndex: 0,
  pageSize: 10,
  loading: false,
  items: [],
  totalItems: 0,
  statusToast: { statusMessage: '', status: Status.SUCCESS, showStatusToast: false },
  showConfirmationModal: false,
}
