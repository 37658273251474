import { SvgNames } from '@components/Svg'

export interface AlertFilter {
  id?: number
  type: AlertFilterType
  conditions: AlertFilterCondition[]
  logicalCondition?: LogicalCondition
}

export enum AlertFilterType {
  ALL_VISITORS = 'All visitors',
  ANONYMOUS_VISITORS = 'Anonymous visitors',
  KNOWN_VISITORS = 'Known visitors',
  MARKETING_PROSPECTS = 'Marketing prospects',
  MEDIA_DOWNLOADS = 'Media downloads',
  MEMBERSHIP = 'Membership',
  MY_LEADS_AND_CONTACTS = 'My leads and contacts',
  PAGE_VISITS = 'Page visits',
  UNASSIGNED_VISITORS = 'Unassigned visitors',
}

export const filtersIcons: { [key in AlertFilterType]: SvgNames } = {
  [AlertFilterType.ALL_VISITORS]: SvgNames.threeUsers,
  [AlertFilterType.ANONYMOUS_VISITORS]: SvgNames.userAnonymous,
  [AlertFilterType.KNOWN_VISITORS]: SvgNames.userChecked,
  [AlertFilterType.MARKETING_PROSPECTS]: SvgNames.marketingProspects,
  [AlertFilterType.MEDIA_DOWNLOADS]: SvgNames.download,
  [AlertFilterType.MEMBERSHIP]: SvgNames.addressNoFill,
  [AlertFilterType.MY_LEADS_AND_CONTACTS]: SvgNames.twoUsers,
  [AlertFilterType.PAGE_VISITS]: SvgNames.pageVisits,
  [AlertFilterType.UNASSIGNED_VISITORS]: SvgNames.removeAccess,
}

export const visitorsFilters: AlertFilterType[] = [
  AlertFilterType.ALL_VISITORS,
  AlertFilterType.ANONYMOUS_VISITORS,
  AlertFilterType.KNOWN_VISITORS,
  AlertFilterType.MARKETING_PROSPECTS,
  AlertFilterType.MY_LEADS_AND_CONTACTS,
  AlertFilterType.UNASSIGNED_VISITORS,
]

export const activitiesFilters: AlertFilterType[] = [AlertFilterType.MEDIA_DOWNLOADS, AlertFilterType.MEMBERSHIP, AlertFilterType.PAGE_VISITS]

export interface AlertFilterCondition {
  logicalCondition?: LogicalCondition
  field: ConditionField
  condition: Condition
  value: string
}

export enum LogicalCondition {
  AND = 'and',
  OR = 'or',
  NOT = 'not',
}

export enum Condition {
  IS = 'is',
  IS_NOT = 'is not',
  CONTAINS = 'contains',
  NOT_CONTAINS = 'does not contain',
  STARTS_WITH = 'starts with',
  ENDS_WITH = 'ends with',
  BLANK = 'is blank',
  NOT_BLANK = 'is not blank',
}

export enum ConditionField {
  AREA_CODE = 'Area code',
  COMPANY_NAME = 'Company name',
  EMAIL_ADDRESS = 'Email address',
  FIRST_NAME = 'First name',
  LAST_NAME = 'Last name',
  COUNTRY = 'Country',
  POSTAL_CODE = 'Postal code',
  STATE = 'State',
  IP_ADDRESS = 'IP address',
}

export const contactDetailsFields = [
  ConditionField.AREA_CODE,
  ConditionField.COMPANY_NAME,
  ConditionField.EMAIL_ADDRESS,
  ConditionField.FIRST_NAME,
  ConditionField.LAST_NAME,
]

export const locationDetailsFields = [ConditionField.COUNTRY, ConditionField.POSTAL_CODE, ConditionField.STATE]

export const otherDetailsFields = [ConditionField.IP_ADDRESS]
