import { MIRRORED_LISTS_ID, UNIFIED_CONTACTS_ID } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import { Program, ProgramAlertStep, ProgramMessage, ProgramSegmentDetails, ProgramSource, ProgramStep } from '@graphql/types/query-types'
import { ProgramState, ProgramStepType, SEGMENT_TYPES, Step } from '@utils/program/program.constants'
import { EditProgramState } from '@utils/program/program.constants'
import { onlyUnique } from '@utils/utils'

export const NEW_SEGMENT_NAME = 'newLocalSegment-'

export function getLocalSegment(program: Program, segmentId?: string) {
  return program.localSegmentDetails?.find((segmentDetail) => {
    return segmentDetail.id === segmentId
  })
}

export const getUpdatedProgramSources = (program: Program, sources: ProgramSource[]): Program => {
  const newSources = sources.reduce((acc: ProgramSource[], cur) => {
    if (program.sources.find((sourceList) => sourceList.id === cur.id)) {
      return acc
    }
    return [...acc, cur]
  }, [])
  return {
    ...program,
    sources: [...program.sources, ...newSources],
  }
}

export const getUpdatedProgramMessages = (program: Program, messages: ProgramMessage[]): Program => {
  const newMessages = messages.reduce((acc: ProgramMessage[], cur) => {
    if (program.messages.find((message) => message.id === cur.id)) {
      return acc
    }
    return [...acc, cur]
  }, [])
  return {
    ...program,
    messages: [...program.messages, ...newMessages],
  }
}

export function getStepTemplate(templates: ProgramStep[], stepType: ProgramStepType, programName?: string): Step | undefined {
  const step = templates.find((template) => template.stepType === stepType) as Step
  if (step) {
    return {
      ...step,
      ...(step.stepType === ProgramStepType.ALERT && programName ? { subject: `${(step as ProgramAlertStep).subject} ${programName}` } : {}),
    }
  }
}

export function getTimeMask(value: string) {
  const chars = value.replace(/_/g, '').split('')

  let hours: any
  if (chars[0] == '1') {
    if (chars[1] == ':') {
      hours = [/[0-1]/]
    } else {
      hours = [/[0-1]/, chars[0] == '1' ? /[0-2]/ : /[0-9]/]
    }
  } else {
    hours = [/[2-9]/]
  }

  const minutes = [/[0-5]/, /[0-9]/]

  return hours.concat(':').concat(minutes)
}

export function getIsListSegment(segment: string) {
  return segment === SEGMENT_TYPES.IN || segment === SEGMENT_TYPES.NOT_IN
}

export function getNewSegmentName(localSegmentDetails: ProgramSegmentDetails[]) {
  let index = 0
  for (const localSegment of localSegmentDetails) {
    if (localSegment.id.includes(NEW_SEGMENT_NAME)) {
      const newIndex = parseInt(localSegment.id.split(NEW_SEGMENT_NAME)[1])
      if (newIndex >= index) {
        index = newIndex + 1
      }
    }
  }
  return `${NEW_SEGMENT_NAME}${index}`
}

interface SegmentNot {
  not: boolean
  segmentMode: string
}

export function getSegmentAndNotValue(segmentMode: string): SegmentNot {
  let segment = segmentMode
  const not = segment === SEGMENT_TYPES.NOT_MESSAGE_SEND || segment === SEGMENT_TYPES.NOT_IN
  if (segment === SEGMENT_TYPES.NOT_MESSAGE_SEND) {
    segment = SEGMENT_TYPES.MESSAGE_SEND
  }
  return {
    not,
    segmentMode: segment,
  }
}

export function getNewSegment(srcId: string, messageIds: string[], segmentMode: string): ProgramSegmentDetails {
  return {
    id: srcId,
    count: 1,
    countlimit: 'insome',
    idset: messageIds.filter((messageId: string) => messageId !== ''),
    name: segmentMode,
    whats: [segmentMode],
    __typename: 'ProgramSegmentDetails',
  }
}

export function getSharedHeaders(sourceLists: ProgramSource[]) {
  let sharedHeaders: string[] = []
  if (sourceLists.length > 0) {
    sharedHeaders = sourceLists[0].headers.filter(onlyUnique)
    for (let i = 1; i < sourceLists.length; i++) {
      sharedHeaders = [...sharedHeaders, ...sourceLists[i].headers].filter(onlyUnique)
    }
  }
  return sharedHeaders
}

export function getUnionHeaders(sourceLists: ProgramSource[]) {
  let sharedHeaders: string[] = []
  if (sourceLists.length > 0) {
    sharedHeaders = sourceLists[0].headers
    for (let i = 1; i < sourceLists.length; i++) {
      sharedHeaders = sharedHeaders.filter((value) => sourceLists[i].headers.includes(value))
    }
  }
  return sharedHeaders
}

interface Message {
  title?: string
  subject?: string
}

export function getProgramMessageName(message: Message): string {
  return message.title ?? message.subject ?? '[Untitled]'
}

export const hasAOContactsSource = (program: Program) => {
  return program.sources.some(
    (source) => source.baseId === UNIFIED_CONTACTS_ID || source.baseId.startsWith(MIRRORED_LISTS_ID) || source.id.startsWith(MIRRORED_LISTS_ID)
  )
}

export const hasLegacyListSource = (program: Program) => {
  return !!program.sources.length && !hasAOContactsSource(program)
}

export const getAOContactsSource = (name: string, sourceList: ProgramSource[] | undefined) =>
  sourceList?.find((source) => source.baseId === UNIFIED_CONTACTS_ID && source.name === name)

export const hasMirroredListSource = (program: Program) => program.sources.find((source) => source.baseId.startsWith(MIRRORED_LISTS_ID))

export const getCurrentProgramStatus = (program?: Program): ProgramState => {
  return program?.runStatus?.isRunning ? ProgramState.RUNNING : program?.runStatus?.valid ? ProgramState.PAUSED : ProgramState.ERROR
}

export const getEditProgram = (state: any): EditProgramState => state.editProgram

export default {
  getLocalSegment,
  getUpdatedProgramSources,
  getUpdatedProgramMessages,
  getStepTemplate,
  getTimeMask,
  getIsListSegment,
  getNewSegmentName,
  getSegmentAndNotValue,
  getNewSegment,
  getSharedHeaders,
  getProgramMessageName,
}
