import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import SectionHeadline from '@components/SectionHeadline'
import { SectionHeadlineType } from '@components/SectionHeadline/SectionHeadline'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './CustomizeSection.css'

interface Props {
  title: string
  description: string
  isEditing: boolean
  setIsEditing: (isEditing: boolean) => void
  children: ReactNode
  handleApply: () => void
  handleCancel: () => void
  applyDisabled: boolean
  borderTop?: boolean
  className?: string
  dataTest?: string
}

const rootClass = 'customize-section'

const CustomizeSection: FC<Props> = (props) => {
  const { title, description, children, handleApply, handleCancel, isEditing, applyDisabled, setIsEditing, borderTop = false, dataTest } = props
  const { t } = useTranslation()

  const renderActions = () => {
    return (
      isEditing && (
        <div className={`${rootClass}__actions`}>
          <Button buttonType={ButtonType.PRIMARY} onClick={handleApply} disabled={applyDisabled} dataTest={`${rootClass}-save-button`}>
            {t('Apply')}
          </Button>
          <Button buttonType={ButtonType.TEXT} onClick={handleCancel} dataTest={`${rootClass}-cancel-button`}>
            {t('Cancel')}
          </Button>
        </div>
      )
    )
  }

  return (
    <section>
      <div className={classNames(`${rootClass}__header`, { [`${rootClass}__header-border-top`]: borderTop })}>
        <div>
          <SectionHeadline type={SectionHeadlineType.SMALL}>{t(title)}</SectionHeadline>
          <Typography text={t(description)} dataTest={`${dataTest}-description`} />
        </div>
        <div className={`${rootClass}__edit`}>
          {isEditing ? (
            <>
              <Svg name={SvgNames.pencil} type={SvgType.ICON} />
              <Typography
                text={t('Editing')}
                type={TextType.BODY_TEXT_LIGHT}
                lineHeight={LineHeight.MEDIUM_LARGE}
                weight={TextWeight.MEDIUM}
                inline
              />
            </>
          ) : (
            <Button buttonType={ButtonType.FLOAT_TEAL} dataTest={`${rootClass}-edit-button`} onClick={() => setIsEditing(true)}>
              {t('Edit')}
            </Button>
          )}
        </div>
      </div>
      {children}
      {renderActions()}
    </section>
  )
}

export default CustomizeSection
