import { gql } from '@apollo/client'

export default gql`
  query getShortUrl($shortUrl: String!) {
    getShortUrl(shortUrl: $shortUrl) {
      id
      longUrl
      shortPath
      shortUrl
      domainName
      assetId
      assetType
      accountId
      https
    }
  }
`
