import { gql } from '@apollo/client'

export default gql`
  query getAllActivity($pageNumber: Long, $segmentId: String, $pageSize: Long, $type: String) {
    getActivityAudit(pageNumber: $pageNumber, segmentId: $segmentId, pageSize: $pageSize, type: $type) {
      activityIdentifier
      activityName
      author
      authorId
      date
      type
    }
    getActivityAutomatedPrograms(pageNumber: $pageNumber, segmentId: $segmentId, pageSize: $pageSize) {
      activityIdentifier
      activityName
      author
      authorId
      date
      type
    }
    getActivityMessages(pageNumber: $pageNumber, segmentId: $segmentId, pageSize: $pageSize) {
      activityIdentifier
      activityName
      author
      authorId
      date
      type
    }
    getActivitySMS(pageNumber: $pageNumber, segmentId: $segmentId, pageSize: $pageSize) {
      activityIdentifier
      activityName
      author
      authorId
      date
      type
    }
    getActivityImport(pageNumber: $pageNumber, segmentId: $segmentId, pageSize: $pageSize) {
      activityIdentifier
      activityName
      author
      authorId
      date
      type
    }
    getActivityFTP(pageNumber: $pageNumber, segmentId: $segmentId, pageSize: $pageSize) {
      activityIdentifier
      activityName
      author
      authorId
      date
      type
    }
    getActivityListMaintenancePrograms(pageNumber: $pageNumber, segmentId: $segmentId, pageSize: $pageSize) {
      accountId
      activityIdentifier
      activityName
      author
      authorId
      createdTime
      date
      enabled
      id
      path
      segmentId
      type
      updatedTime
    }
  }
`
