import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import './formGroup.css'

interface Props {
  children: ReactNode
  className?: string
  dataTest?: string
}

const rootClass = 'form-group'

export const FormGroup: FC<Props> = (props: Props) => {
  const { children, className, dataTest = 'form-group' } = props
  return (
    <div data-test={dataTest} className={classNames(rootClass, className)}>
      {children}
    </div>
  )
}

export default FormGroup
