import { useMemo } from 'react'

import { FetchPolicy, useApolloClient } from '@apollo/client'
import findSentMessagesByName from '@graphql/queries/findSentMessagesByName'
import getAllFavoriteSentMessages from '@graphql/queries/getAllFavoriteSentMessages'
import getAllSentMessages from '@graphql/queries/getAllSentMessages'
import getAllSentMessagesByFilter from '@graphql/queries/getAllSentMessagesByFilter'
import getCountFavoriteSentMessages from '@graphql/queries/getCountFavoriteSentMessages'
import getSentMessagesByFolder from '@graphql/queries/getSentMessagesByFolder'
import sentMessageDates from '@graphql/queries/sentMessageDates'
import {
  FindSentMessagesByNameQuery,
  FindSentMessagesByNameQueryVariables,
  GetAllFavoriteSentMessagesQuery,
  GetAllFavoriteSentMessagesQueryVariables,
  GetAllSentMessagesByFilterQuery,
  GetAllSentMessagesByFilterQueryVariables,
  GetAllSentMessagesQuery,
  GetAllSentMessagesQueryVariables,
  GetCountFavoriteSentMessagesQuery,
  GetCountFavoriteSentMessagesQueryVariables,
  GetSentMessagesByFolderQuery,
  GetSentMessagesByFolderQueryVariables,
  SentMessageDatesQuery,
  SentMessageDatesQueryVariables,
} from '@graphql/types/query-types'
import { createGraphQLQuery } from '@utils/apollo'

export const useEmailSentPickerRequests = () => {
  const actonClient = useApolloClient()
  // These queries could take several seconds especially with the 'hide specific people' filter off, so we will avoid unnecessary fetches
  const fetchPolicy: FetchPolicy = 'cache-first'
  const options = { fetchPolicy }

  return useMemo(
    () => ({
      getAllFavoriteSentMessagesRequest: createGraphQLQuery<GetAllFavoriteSentMessagesQuery, GetAllFavoriteSentMessagesQueryVariables>(
        actonClient,
        getAllFavoriteSentMessages,
        options
      ),
      sentMessageDatesRequest: createGraphQLQuery<SentMessageDatesQuery, SentMessageDatesQueryVariables>(actonClient, sentMessageDates),
      getSentMessagesByFolderRequest: createGraphQLQuery<GetSentMessagesByFolderQuery, GetSentMessagesByFolderQueryVariables>(
        actonClient,
        getSentMessagesByFolder,
        options
      ),
      getAllSentMessagesRequest: createGraphQLQuery<GetAllSentMessagesQuery, GetAllSentMessagesQueryVariables>(actonClient, getAllSentMessages),
      getCountFavoriteSentMessagesRequest: createGraphQLQuery<GetCountFavoriteSentMessagesQuery, GetCountFavoriteSentMessagesQueryVariables>(
        actonClient,
        getCountFavoriteSentMessages,
        options
      ),
      findSentMessagesByNameRequest: createGraphQLQuery<FindSentMessagesByNameQuery, FindSentMessagesByNameQueryVariables>(
        actonClient,
        findSentMessagesByName,
        options
      ),
      getAllSentMessagesByFilterRequest: createGraphQLQuery<GetAllSentMessagesByFilterQuery, GetAllSentMessagesByFilterQueryVariables>(
        actonClient,
        getAllSentMessagesByFilter,
        options
      ),
    }),
    [actonClient]
  )
}
