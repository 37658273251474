import React, { useEffect, useState } from 'react'

import { MediaPickerModalType } from '@components/AssetPickers/MediaPickerModal/MediaPickerModal.utils'
import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'

import LandingPageComposerCustomEditor from '../LandingPageComposerCustomEditor/LandingPageComposerCustomEditor'

const LandingPageComposerCustomCodeCss = () => {
  const {
    values: {
      landingPage: { customCss },
    },
    api: { update },
  } = useComposerContext()

  const [customCssCode, setCustomCssCode] = useState<string>(customCss || '')

  useEffect(() => {
    update({ landingPage: { customCss: customCssCode } })
  }, [customCssCode])

  const onCodeChange = (code: string) => {
    setCustomCssCode(code)
  }

  const onSelectButtonClick = () => {
    update({ showMediaPickerModal: true, mediaPickerModalType: MediaPickerModalType.CSS })
  }

  return (
    <div>
      <LandingPageComposerCustomEditor
        mode="css"
        value={customCssCode || ''}
        onChange={onCodeChange}
        onSelectButtonClick={onSelectButtonClick}
        title="LandingPageComposer.CustomCode.Css.Title"
        description="LandingPageComposer.CustomCode.Css.Desc"
        editorTitle="LandingPageComposer.CustomCode.Css.Editor.Title"
        buttonText="LandingPageComposer.CustomCode.Css.Button"
        copyText="Copy"
      />
    </div>
  )
}

export default LandingPageComposerCustomCodeCss
