import { SeriesLegendItemClickEventObject } from 'highcharts'

import { LineChartProps } from '@components/LineChart/LineChart'
import { ChartField, defaultChartSettingState } from '@const/Chart.constants'
import {
  DateToCount,
  GetAutomatedProgramCountsQuery,
  ProgramExitConditions,
  ProgramInOutResponse,
  QueryGetAutomatedProgramCountsArgs,
  QueryGetAutomatedProgramEnterExitCountsArgs,
} from '@graphql/types/query-types'
import { ProgramPerformanceChartContainerState } from '@src/pages/programs/dashboard/components/ProgramPerformance/components/ProgramPerformanceDetail/components/ProgramPerformanceChartContainer/ProgramPerformanceChartContainer'
import { ProgramPerformanceTab } from '@src/pages/programs/dashboard/components/ProgramPerformance/ProgramPerformance.constants'
import { EarlyExitSegmentInfo } from '@src/pages/programs/dashboard/ProgramDashboard.constants'
import { convertStringToDate, DateType, DateTypeData, DEFAULT_DATE_QUANTITIES } from '@utils/date'

export type QueryResponse = GetAutomatedProgramCountsQuery | ProgramInOutResponse
export type QueryArgs = QueryGetAutomatedProgramEnterExitCountsArgs | QueryGetAutomatedProgramCountsArgs

const MAX_RAW_EXIT_CONDITIONS = 4

export const programPerformanceChartContainerDefaultState: ProgramPerformanceChartContainerState = {
  dateType: DateType.MONTH,
  dateTypeQuantity: DEFAULT_DATE_QUANTITIES[DateType.MONTH],
  chartProps: {} as LineChartProps,
  showEmptyChart: false,
  chartSettings: defaultChartSettingState,
  chartLoading: true,
}

export type ChartFieldVisibility = {
  [chartTab in ProgramPerformanceTab]: boolean[]
}

export const defaultChartFieldVisibility = {
  [ProgramPerformanceTab.All_CONTACTS]: [],
  [ProgramPerformanceTab.EARLY_EXITS]: [],
  [ProgramPerformanceTab.IN_PROGRAM]: [],
  [ProgramPerformanceTab.STANDARD_EXITS]: [],
}

export type ChartFieldClickCallback = (chartTab: ProgramPerformanceTab, fieldIndex: number, visible: boolean) => void

export const getChartFieldVisibleProps = (
  chartTab: ProgramPerformanceTab,
  fieldIndex: number,
  chartFieldVisiblility: ChartFieldVisibility,
  onChartFieldClick: ChartFieldClickCallback
) => {
  const visible = chartFieldVisiblility && chartFieldVisiblility[chartTab] ? chartFieldVisiblility[chartTab][fieldIndex] : true
  return {
    visible: visible ?? true,
    events: {
      legendItemClick: (event: SeriesLegendItemClickEventObject) => {
        if (onChartFieldClick) {
          onChartFieldClick(chartTab, fieldIndex, !event.target.visible)
        }
      },
    },
  }
}

export const getChartFields = (
  fields: ChartField[],
  enterDay: DateTypeData[],
  exitDay: DateTypeData[],
  chartTab: ProgramPerformanceTab,
  chartFieldVisiblility: ChartFieldVisibility,
  onChartFieldClick: ChartFieldClickCallback
) => {
  return fields.map((field: ChartField, idx: number) => ({
    ...field,
    ...getChartFieldVisibleProps(chartTab, idx, chartFieldVisiblility, onChartFieldClick),
    data:
      fields[idx].name === 'Entries'
        ? enterDay.map((enter: DateTypeData) => parseInt(enter.count))
        : exitDay.map((exit: DateTypeData) => parseInt(exit.count)),
  }))
}

export const getExitConditionFields = (
  exitConditions: ProgramExitConditions[],
  earlyExitSegmentInfos: EarlyExitSegmentInfo[],
  chartTab: ProgramPerformanceTab,
  chartFieldVisiblility: ChartFieldVisibility,
  onChartFieldClick: ChartFieldClickCallback
) => {
  const rawExitConditions = exitConditions
    .map((exitCondition: ProgramExitConditions, idx: number) => {
      if (!exitCondition.filterable) {
        return {
          name: exitCondition.listName,
          y: exitCondition.count,
          custom: {
            initialText: '',
            imgSrc: 'empty',
            boldText: exitCondition.listName,
          },
        }
      }
      const exitSegment = earlyExitSegmentInfos.find((segInfo) => segInfo.id === exitCondition.evaluationId)
      if (exitSegment || exitCondition.evaluationId === 'deleted') {
        const commonFields = {
          name: exitCondition.evaluationId,
          y: exitCondition.count,
          ...getChartFieldVisibleProps(chartTab, idx, chartFieldVisiblility, onChartFieldClick),
        }

        return exitSegment
          ? {
              ...commonFields,
              custom: {
                initialText: exitSegment.condition,
                imgSrc: exitSegment.exitType,
                boldText: exitSegment.name,
                secondText: exitSegment.secondAction,
                secondImgSrc: exitSegment.secondExitType,
                secondBoldText: exitSegment.secondList,
              },
            }
          : {
              ...commonFields,
              custom: {
                initialText: '',
                imgSrc: 'empty',
                boldText: 'Deleted',
              },
            }
      } else {
        return {
          name: 'empty',
          y: exitCondition.count,
          custom: {
            initialText: '',
            imgSrc: '',
            boldText: '',
          },
        }
      }
    })
    .filter((exitCondition) => exitCondition.name !== 'empty')
    .filter((exitCondition) => exitCondition.y > 0)
    .sort((a, b) => (a.y > b.y ? -1 : 1))

  if (rawExitConditions.length <= MAX_RAW_EXIT_CONDITIONS + 1) {
    return rawExitConditions
  } else {
    const baseConditions = rawExitConditions.slice(0, MAX_RAW_EXIT_CONDITIONS)
    const otherConditions = rawExitConditions.slice(MAX_RAW_EXIT_CONDITIONS).reduce((acc: number, curr) => acc + curr.y, 0)
    const otherConditionsCount = rawExitConditions.slice(MAX_RAW_EXIT_CONDITIONS).length
    return [
      ...baseConditions,
      {
        name: 'empty',
        y: otherConditions,
        custom: {
          initialText: `Other Reasons (${otherConditionsCount})`,
          imgSrc: 'empty',
          boldText: '',
        },
      },
    ]
  }
}

export const sortEnterExitData = (data: DateToCount[], dateType: DateType) => {
  return data
    .map((entry: DateToCount) => ({
      ...entry,
      date: convertStringToDate(entry.date, dateType),
    }))
    .sort((a: any, b: any) => a.date.getTime() - b.date.getTime())
}

export const getDateType = (currentTab: ProgramPerformanceTab, chartProps: any) => {
  return currentTab === ProgramPerformanceTab.All_CONTACTS
    ? chartProps.allContacts.dateType
    : currentTab === ProgramPerformanceTab.STANDARD_EXITS
    ? chartProps.standardExits.dateType
    : DateType.MONTH
}

export const getDateTypeQuantity = (currentTab: ProgramPerformanceTab, chartProps: any) => {
  return currentTab === ProgramPerformanceTab.All_CONTACTS
    ? chartProps.allContacts.dateTypeQuantity
    : currentTab === ProgramPerformanceTab.STANDARD_EXITS
    ? chartProps.standardExits.dateTypeQuantity
    : DEFAULT_DATE_QUANTITIES[DateType.MONTH]
}
