import React, { FC, useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'

import { useApolloClient } from '@apollo/client'
import { SavingMessage } from '@components/AutoSavingStatus/AutoSavingStatus'
import PageContainer from '@components/PageContainer'
import PageError from '@components/PageError/PageError'
import { rootContext } from '@const/globals'
import getSentInitialMessage from '@graphql/microservices/sms-management/getSentInitialMessage'
import { GetSentInitialMessageQuery, GetSentInitialMessageQueryVariables } from '@graphql/types/microservice/sms-management-types'
import { MarketingListResponse, RecipientsPerCreditMultiplierResponse, RecipientTimezoneResponse } from '@graphql/types/query-types'
import { Errors } from '@interface/common'
import { smsStateDefaultValues } from '@src/pages/sms/context/smsStateDefaultValues'
import { SMSListingStatusToast } from '@src/pages/sms/listing/SMSListingContainer'
import { useAccountSettings } from '@utils/account/account.utils'
import useLoading from '@utils/hooks/useLoading'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'
import { MatchParams } from '@utils/types'

import EditSMS from './EditSMS'
import {
  createMessage,
  getMessage,
  getUpdatedCost,
  saveMessageUtil,
  sendSMSMessageUtil,
  scheduleSMSLaunchUtil,
  updateMessageUtil,
  canSendUtil,
  canScheduleUtil,
  calculateCreditsByRecipients,
} from './utils/EditSMSContainerUtils'
import SegmentedMessage from './utils/segmentationCalculator'
import { SMSContext } from '../context/SMSContext'

export type Personalization = {
  fieldName: String
  maxLength: Number
  fallbackText: String
}

export interface PersonalizationLabelValue {
  label: string
  value: string
}

export interface SMSMessage {
  title: string
  description: string
  messageText: string
  launchId: string
  recipients: MarketingListResponse[]
  recipientsPerCreditMultiplier?: RecipientsPerCreditMultiplierResponse[]
  recipientTimezones: RecipientTimezoneResponse[]
  accountId?: string
  smsMessageId?: string
  status?: string
}

export interface State {
  smsMessage: SMSMessage
  senderCode: string
  availableCredits: number
  smsNextMonthCredits: number
  costToSend: number
  suppressions: string[]
  segmentedMessage: SegmentedMessage
  messageCaretPosition: number
  errors: Errors
  pageError: boolean
  saved: boolean
  sendMessageStatus: SMSListingStatusToast
  scheduledSendTime: number
  isMarketerTimezone: boolean
  savingMessage: SavingMessage
  totalRecipients: number
  internationalSendingEnabled: boolean
  sentInitialMessage?: boolean
  [key: string]: any
}

export const MAX_MESSAGE_LENGTH = 1600

type AllProps = RouteComponentProps<MatchParams>

const defaultState: State = smsStateDefaultValues

const EditSMSContainer: FC<AllProps> = (props: AllProps) => {
  const { match } = props
  const [doCreate, createLoading] = useLoading(createMessage)
  const [existingLoading, setExistingLoading] = useState(false)
  const [containerValues, setContainerValues] = useState<State>(defaultState)

  const { accountId } = useAccountSettings()
  const { client: smsClient } = useMicroserviceClient({ serviceName: MicroserviceClients.SMS_MANAGEMENT })
  const { data: sentInitialMessage } = useQueryOnMount<GetSentInitialMessageQuery, GetSentInitialMessageQueryVariables>(getSentInitialMessage, {
    client: smsClient,
    fetchPolicy: 'network-only',
    variables: {
      accountId,
    },
  })

  const client = useApolloClient()

  const {
    smsMessage: { launchId },
    pageError,
    saved,
    sendMessageStatus,
  } = containerValues
  const history = useHistory()
  useEffect(() => {
    if (!launchId && match.params.id === 'new') {
      doCreate(setContainerValues, containerValues, client as any)
    }
  }, [launchId, createLoading, match.params.id])

  useEffect(() => {
    if (match.params.id && match.params.id !== 'new') {
      setExistingLoading(true)
      getMessage(match.params.id, setContainerValues, containerValues, client as any).then(() => setExistingLoading(false))
    }
  }, [match.params.id])

  useEffect(() => {
    if (sentInitialMessage?.getSentInitialMessage) {
      setContainerValues({ ...containerValues, sentInitialMessage: sentInitialMessage?.getSentInitialMessage })
    }
  }, [sentInitialMessage, containerValues.smsMessage.launchId])

  useEffect(() => {
    const recipientsWithMultiplierCredits = calculateCreditsByRecipients(
      containerValues.smsMessage.recipientsPerCreditMultiplier ?? [],
      containerValues.internationalSendingEnabled
    )
    const costToSend = getUpdatedCost(containerValues, recipientsWithMultiplierCredits)
    setContainerValues({
      ...containerValues,
      costToSend,
    })
  }, [])

  useEffect(() => {
    if (saved) {
      const nextTab = sendMessageStatus.statusMessage.indexOf('send') > -1 ? 'sent' : 'scheduled'
      history.push(`${rootContext}/sms/messages`, { tab: nextTab, status: sendMessageStatus })
    }
  }, [saved])

  const sendSMSMessage = () => sendSMSMessageUtil(setContainerValues, containerValues, client as any)

  const scheduleMessageLaunch = () => scheduleSMSLaunchUtil(setContainerValues, containerValues, client as any)

  const saveMessage = (smsMessage: SMSMessage, messageCaretPosition?: number) => {
    saveMessageUtil(smsMessage, messageCaretPosition || 0, setContainerValues, containerValues, client as any)
  }

  const updater = (field: string, value: any) => setContainerValues({ ...containerValues, [field]: value })

  const updateMessage = (message: string) => updateMessageUtil(message, containerValues, setContainerValues)

  const canSend = (): boolean => canSendUtil(containerValues)

  const canSchedule = (): boolean => canScheduleUtil(containerValues)

  if (pageError) {
    return (
      <PageContainer>
        <PageError center />
      </PageContainer>
    )
  }

  return (
    <SMSContext.Provider
      value={{ values: containerValues, updater, canSend, canSchedule, updateMessage, saveMessage, sendSMSMessage, scheduleMessageLaunch }}
    >
      <EditSMS loading={createLoading || existingLoading} isNew={match.params.id === 'new'} />
    </SMSContext.Provider>
  )
}

EditSMSContainer.displayName = 'SMSContainer'
export default EditSMSContainer
