import { ApolloClient } from '@apollo/client'
import { State } from '@complex/ListPickerModalV2/ListPickerWrapper'
import listSchemaQuery from '@graphql/queries/listSchema'
import { ListSchemaQuery, ListSchemaQueryVariables, ProgramSource } from '@graphql/types/query-types'
import { logError } from '@utils/env'

export const getListSchemaUtils = (
  state: State,
  setState: Function,
  client: ApolloClient<any>,
  listIds: string[],
  onProgramSourcesUpdate: Function,
  onClose: Function
) => {
  client
    .query<ListSchemaQuery, ListSchemaQueryVariables>({
      query: listSchemaQuery,
      fetchPolicy: 'network-only',
      variables: {
        listIds,
      },
    })
    .then((data) => {
      const newProgramSources: ProgramSource[] = data.data.listSchemas.map((listSchema) => {
        const source = state.loadingLists?.find((list) => list.id == listSchema.listId)
        const baseId = source?.baseId
        const count = source?.size
        return {
          id: listSchema.listId,
          baseId: baseId ?? '',
          name: listSchema.displayName,
          headers: listSchema.schema?.headers ?? [],
          count,
          __typename: 'ProgramSource',
        }
      })

      onProgramSourcesUpdate(newProgramSources)
      setState({
        ...state,
        loadingLists: undefined,
      })
    })
    .catch((error) => {
      logError(error)
      alert('An issue occurred trying to add lists to program')
      onClose()
    })
}
