import aceBuild from 'ace-builds/package.json'
import * as ace from 'ace-builds/src-noconflict/ace'

/**
 * Helper function to get the major version from the full version string.
 * Removes any non-numeric prefix (e.g., '^', '~') and returns only the major version.
 * @param version - The full version string (e.g., '^2.4.7', '3.5.1')
 * @returns The major version (e.g., '2', '3')
 */
const getMajorVersion = (version: string): string => {
  // Remove any non-numeric characters at the beginning
  const sanitizedVersion = version.replace(/[^0-9.]/g, '')
  return sanitizedVersion.split('.')[0] // Return the major version
}

/**
 * Sets the basePath and module URL for Ace Editor based on the major version.
 */
export const configureAce = () => {
  const majorAceVersion = getMajorVersion(aceBuild.version)

  // Set basePath and worker URLs dynamically
  ace.config.set('basePath', `https://cdn.jsdelivr.net/npm/ace-builds@${majorAceVersion}/src-noconflict/`)
  ace.config.setModuleUrl(
    'ace/mode/javascript_worker',
    `https://cdn.jsdelivr.net/npm/ace-builds@${majorAceVersion}/src-noconflict/worker-javascript.js`
  )
  ace.config.setModuleUrl('ace/mode/html_worker', `https://cdn.jsdelivr.net/npm/ace-builds@${majorAceVersion}/src-noconflict/worker-html.js`)
  ace.config.setModuleUrl('ace/mode/json_worker', `https://cdn.jsdelivr.net/npm/ace-builds@${majorAceVersion}/src-noconflict/worker-json.js`)
  ace.config.setModuleUrl('ace/mode/css_worker', `https://cdn.jsdelivr.net/npm/ace-builds@${majorAceVersion}/src-noconflict/worker-css.js`)
}
