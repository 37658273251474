import React, { Dispatch, SetStateAction } from 'react'
import { Row } from 'react-table'

import { TFunction } from 'i18next'

import { YesNo } from '@components/ConfirmationModal'
import { ConfirmationModalProps } from '@components/ConfirmationModal/ConfirmationModal'
import { ProgressBarStep } from '@components/ProgressBar/ProgressBar'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { renderRelativeDate } from '@components/Table/components/tableColumns'
import { RowAction } from '@components/Table/Table'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { GetItemQuery } from '@graphql/types/microservice/categorization-types'
import { rootClass, SelectFormsStepState } from '@src/pages/UpgradeAssistant/components/FormsUpgradeModal/components/SelectFormsStep/SelectFormsStep'
import { errorStatusToast, UpgradeAssistantState } from '@src/pages/UpgradeAssistant/UpgradeAssistant.contants'
import { ItemType } from '@utils/categorization'
import { getStandardFormattedDate, relativeDate } from '@utils/date'
import { Form } from '@utils/forms'
import { logNewRelicError } from '@utils/new-relic.utils'
import { FetchPromise } from '@utils/types'

export const rootText = 'Forms.Upgrade.Modal.'

interface GetRowActionsParams {
  setState: Dispatch<SetStateAction<SelectFormsStepState>>
}

export enum FormsUpgradeSteps {
  SELECT_FORMS = 'Select forms',
  MAP_FIELDS = 'Maps fields',
  CONFIGURE = 'Configure',
  REVIEW = 'Review import',
  CONFIRMATION = 'Confirmation',
}

export const getInitialSteps = (t: Function): ProgressBarStep[] => [
  { key: FormsUpgradeSteps.SELECT_FORMS, header: t(`${rootText}Step.One.Label`), isActive: true, isCompleted: false },
  { key: FormsUpgradeSteps.MAP_FIELDS, header: t(`${rootText}Step.Two.Label`), isActive: false, isCompleted: false },
  { key: FormsUpgradeSteps.CONFIGURE, header: t(`${rootText}Step.Three.Label`), isActive: false, isCompleted: false },
  { key: FormsUpgradeSteps.REVIEW, header: t(`${rootText}Step.Four.Label`), isActive: false, isCompleted: false },
  { key: FormsUpgradeSteps.CONFIRMATION, isActive: false, isCompleted: false, hidden: true },
]

export const getColumns = (): ColumnDefWithAdditionalProps<Form>[] => {
  return [
    {
      header: 'Form title',
      accessorKey: 'name',
      textAlign: 'left',
      maxSize: 200,
      cell: ({ row: { original } }) => (
        <div className={`${rootClass}__name-with-icon`}>
          <Svg name={SvgNames.survey} type={SvgType.LARGER_ICON} />
          <TextWithTooltipOnEllip
            typographyProps={{
              title: original.name,
              text: original.name,
              weight: TextWeight.REGULAR,
            }}
          />
        </div>
      ),
    },
    {
      header: 'Submits',
      accessorKey: 'submits',
      textAlign: 'right',
      maxSize: 100,
    },
    {
      header: 'Last updated',
      accessorKey: 'lastUpdated',
      textAlign: 'right',
      maxSize: 100,
      cell: ({ row: { original } }) =>
        original.lastUpdated
          ? renderRelativeDate(relativeDate(original.lastUpdated, true, false), getStandardFormattedDate(original.lastUpdated))
          : '',
    },
  ]
}

export const useRowActions = ({ setState }: GetRowActionsParams): RowAction[] => {
  const { t } = useTranslation()
  return [
    {
      label: t('Preview form'),
      icon: SvgNames.zoom,
      onClick: ({ original }: Row<Form>) => setState((state) => ({ ...state, formToPreview: original })),
    },
    {
      label: t('Remove form'),
      icon: SvgNames.delete,
      onClick: ({ original }: Row<Form>) => setState((state) => ({ ...state, formToRemove: original })),
    },
  ]
}

export const getConfirmationModalProps = (
  showExitConfirmationModal: boolean,
  showDeleteConfirmationModal: boolean,
  onDeleteConfirmationModalAnswer: (answer: YesNo) => void,
  onExitConfirmationModalAnswer: (answer: YesNo) => void,
  forms: Form[],
  t: TFunction
): ConfirmationModalProps => {
  if (showExitConfirmationModal) {
    return {
      isOpen: showExitConfirmationModal,
      deleteButtonText: t('Exit Upgrade Assistant'),
      title: t(`Are you sure?`),
      onAnswer: onExitConfirmationModalAnswer,
      body: t(`${rootText}ExitConfirmationModal.Body`, { count: forms.length }),
    }
  } else {
    return {
      isOpen: showDeleteConfirmationModal,
      deleteButtonText: t('Yes, delete'),
      title: t(`${rootText}DeleteConfirmationModal.Title`),
      onAnswer: onDeleteConfirmationModalAnswer,
      body: (
        <Typography
          type={TextType.BODY_TEXT_LIGHT}
          text={t(`${rootText}DeleteConfirmationModal.Body`)}
          tagProps={{ bold: { weight: TextWeight.BOLD } }}
          values={{ count: forms.length }}
          inline
        />
      ),
    }
  }
}

export const getItemById = (
  getItemByIdRequest: (externalId: string, type: ItemType) => FetchPromise<GetItemQuery>,
  externalId: string,
  type: ItemType,
  setState: Function
) => {
  getItemByIdRequest(externalId, type)
    .then((response) => {
      const selectedForm: Form = {
        ...JSON.parse(response.data?.getItem?.item ?? '{}'),
        ...response.data?.getItem,
      }
      setState((state: UpgradeAssistantState) => ({
        ...state,
        selectedSources: { [type === ItemType.FORM ? 'Forms' : 'Templates']: [selectedForm] },
      }))
    })
    .catch((e) => {
      logNewRelicError(e)
      setState((state: UpgradeAssistantState) => ({
        ...state,
        statusToast: errorStatusToast,
      }))
    })
}
