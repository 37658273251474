import React, { createContext } from 'react'

import { ListPickerModalAPI } from '@complex/ListPickerModalV2/utils/interfaces/ListPickerModalInterfaces'
import { EmptyListingProps } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { Status } from '@components/StatusToast/StatusToast'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { EXPRESSION_EVALUATION_TYPE } from '@utils/contactSegments/contactSegments.utils'
import { ListCategory } from '@utils/lists'

export const UNIFIED_CONTACTS_ID = 'l-unified-contacts'
export const MIRRORED_LISTS_ID = 'l-mirrored-'

export const WEBEX_ATTENDANCE_ID = 'wbxf-'
export const CITRIX_ATTENDANCE_ID = 'ctrxf-'
export const WEBEX_ATTENDANCE_WEBINAR_ID = 'wbxwb-'
export const ZOOM_ATTENDANCE_ID = 'zoom-'

export const DIRECT_SELECT_SEGMENTS_TYPE = 'Explicitly Selected Group'
export const EXTENSION_LIST_TYPE = 'Extension'

export const unifiedListTypes = [ListCategory.UNIFIED_LIST, ListCategory.FORM_SUBMISSIONS_UCL, ListCategory.WEBINAR_UCL]
export const legacyListTypes = [
  ListCategory.EXTENSION,
  ListCategory.ACCOUNTS,
  ListCategory.WEBINAR,
  ListCategory.MARKETING,
  ListCategory.FORM_SUBMISSIONS,
]

export const selectedListsInitialState = {
  [ListCategory.UNIFIED_LIST]: [],
  [ListCategory.MARKETING]: [],
  [ListCategory.ACCOUNTS]: [],
  [ListCategory.FORM_SUBMISSIONS]: [],
  [ListCategory.FORM_SUBMISSIONS_UCL]: [],
  [ListCategory.WEBINAR]: [],
  [ListCategory.WEBINAR_UCL]: [],
  [ListCategory.EXTENSION]: [],
}

export const getDefaultState = (initialList?: ListCategory) => ({
  allLoaded: false,
  campaigns: {},
  crmMapping: { '': { displayName: '', entityFields: { '': '' } } },
  currentPage: 0,
  disabledLists: [],
  disableLegacyLists: false,
  disableUnifiedLists: false,
  disabledListTooltipText: '',
  emptyListingOption: ListPickerEmptyListingOptions.EMPTY_OTHERS,
  filters: [],
  foldersState: { folders: [], foldersLoading: false },
  hasToExpandRows: selectedListsInitialState,
  headerShadow: false,
  infoHoverLoading: false,
  lazyLoading: false,
  listsState: { lists: [], listsLoading: false },
  listType: initialList || ListCategory.MARKETING,
  loading: true,
  metadataLoading: false,
  multiSelect: true,
  preSelectedListsInit: false,
  scoreSheets: {},
  segmentDefinition: { type: '', customBooleanExpression: null, expressions: [], expressionEvaluationType: 'AND' as EXPRESSION_EVALUATION_TYPE },
  selectedLists: selectedListsInitialState,
  statusToast: { message: '', status: Status.FAIL, showToast: false },
  subscriptionMapping: {},
  supportedBehaviors: {},
  tagsState: { tags: [], tagsLoading: false },
  unifiedLists: [],
  updateLoaded: false,
  updatedSelectedLists: [],
  viewingSelectedLists: false,
  isFirstLoading: true,
  isLegacyForms: false,
  selectAllSegmentsOfSelectedList: false,
})

export enum ListPickerEmptyListingOptions {
  EMPTY_FOLDER = 'EMPTY_FOLDER',
  EMPTY_TAG = 'EMPTY_TAG',
  EMPTY_FAVORITES = 'EMPTY_FAVORITES',
  EMPTY_CREATED_BY_ME = 'EMPTY_CREATED_BY_ME',
  EMPTY_RECENT = 'EMPTY_RECENT',
  EMPTY_OTHERS = 'EMPTY_OTHERS',
  EMPTY_CRM_SOURCED = 'EMPTY_CRM_SOURCED',
}

export const getEmptyListingObject = (option: ListPickerEmptyListingOptions, rootClass: string, t: Function, name?: string) => {
  const EmptyListingOptions: { [key in ListPickerEmptyListingOptions]: EmptyListingProps } = {
    [ListPickerEmptyListingOptions.EMPTY_FOLDER]: {
      imgSrc: StaticImageNames.emptyFolder,
      headline: 'This folder is empty',
      text: (
        <div>
          <Typography text={`${t("You haven't stored anything in")} `} type={TextType.BODY_TEXT_LIGHT} inline />
          <Tooltip
            trigger={<Typography className={'ellip'} text={name} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.BOLD} />}
            triggerClassName={`${rootClass}__empty-listing-text-name-trigger`}
          >
            {name}
          </Tooltip>
          <Typography text={'.'} type={TextType.BODY_TEXT_LIGHT} inline />
        </div>
      ),
    },
    [ListPickerEmptyListingOptions.EMPTY_TAG]: {
      imgSrc: StaticImageNames.emptyTag,
      headline: "You haven't used this tag yet",
      text: (
        <div>
          <Typography text={`${t('Nothing has been tagged with')} `} type={TextType.BODY_TEXT_LIGHT} inline />
          <Tooltip
            trigger={<Typography className={'ellip'} text={name} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.BOLD} />}
            triggerClassName={`${rootClass}__empty-listing-text-name-trigger`}
          >
            {name}
          </Tooltip>
          <Typography text={'.'} type={TextType.BODY_TEXT_LIGHT} inline />
        </div>
      ),
    },
    [ListPickerEmptyListingOptions.EMPTY_FAVORITES]: {
      imgSrc: StaticImageNames.emptyFavorites,
      headline: "You don't have any favorites yet",
      text: 'Add hearts to items that you want to easily find later.',
    },
    [ListPickerEmptyListingOptions.EMPTY_CREATED_BY_ME]: {
      imgSrc: StaticImageNames.emptyCreatedByMe,
      headline: "You haven't created any of these yet",
      text: "You'll find items that you created here.",
    },
    [ListPickerEmptyListingOptions.EMPTY_RECENT]: {
      imgSrc: StaticImageNames.emptyRecent,
      headline: "You haven't used these recently",
      text: "You'll find items that you used recently here.",
    },
    [ListPickerEmptyListingOptions.EMPTY_OTHERS]: {
      imgSrc: StaticImageNames.errorNothingFound,
      headline: 'Nothing to see here!',
      text: "We couldn't find any results to show you.",
    },
    [ListPickerEmptyListingOptions.EMPTY_CRM_SOURCED]: {
      imgSrc: StaticImageNames.emptyContactsMedium,
      text: 'It looks like you don’t have any CRM sourced segments just yet.',
      headline: 'No CRM Sourced Segments',
    },
  }
  return EmptyListingOptions[option]
}

export const ListPickerModalContext = createContext<ListPickerModalAPI>({ values: getDefaultState() } as any)
