import React, { FC } from 'react'

import classNames from 'classnames'

import Caution from '@components/Caution/Caution'
import { useTranslation } from '@const/globals'
import ProgramPerformanceChartContainer from '@src/pages/programs/dashboard/components/ProgramPerformance/components/ProgramPerformanceDetail/components/ProgramPerformanceChartContainer/ProgramPerformanceChartContainer'
import { getContactsUrl, ProgramPerformanceTab } from '@src/pages/programs/dashboard/components/ProgramPerformance/ProgramPerformance.constants'
import { EarlyExitSegmentInfo } from '@src/pages/programs/dashboard/ProgramDashboard.constants'
import { EmptyState } from '@utils/program/program.constants'
import { ProgramStep } from '@utils/program/ProgramSteps.constants'

import ProgramPerformanceDetailTableContainer from './components/ProgramPerformanceTableContainer/ProgramPerformanceDetailTableContainer'

import './ProgramPerformanceDetail.css'

export type ProgramPerformanceDetailProps = {
  programPerformanceTab: ProgramPerformanceTab
  tableTitle: string
  allowReentrantAddresses: boolean
  emptyState: EmptyState
  hideChart?: boolean
  totalContacts: number
  showDuplicatesWarning?: boolean
  filter?: string
  downloadUrl?: string
  programId: string
  programSteps?: ProgramStep[]
  noUpdate: boolean
  dataTest?: string
  className?: string
  tableSubtitle: string
  earlyExitSegmentInfos: EarlyExitSegmentInfo[]
}

const rootClass = 'program-performance-detail'

const ProgramPerformanceDetail: FC<ProgramPerformanceDetailProps> = (props: ProgramPerformanceDetailProps) => {
  const {
    programPerformanceTab,
    tableTitle,
    allowReentrantAddresses,
    emptyState,
    tableSubtitle,
    hideChart,
    totalContacts,
    showDuplicatesWarning,
    programId,
    noUpdate,
    programSteps,
    earlyExitSegmentInfos,
    dataTest = rootClass,
    className = '',
  } = props
  const { t } = useTranslation()

  const activeTabModifier =
    programPerformanceTab === ProgramPerformanceTab.All_CONTACTS
      ? 'all-contacts'
      : programPerformanceTab === ProgramPerformanceTab.IN_PROGRAM
      ? 'in-program'
      : programPerformanceTab === ProgramPerformanceTab.EARLY_EXITS
      ? 'early-exits'
      : programPerformanceTab === ProgramPerformanceTab.STANDARD_EXITS
      ? 'standard-exits'
      : ''

  const hideTableWhenNoContacts = totalContacts === 0 && !hideChart

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {!hideChart && (
        <ProgramPerformanceChartContainer
          noUpdate={noUpdate}
          programPerformanceTab={programPerformanceTab}
          totalContacts={totalContacts}
          programId={programId}
          earlyExitSegmentInfos={earlyExitSegmentInfos}
          activeTabModifier={activeTabModifier}
          emptyState={emptyState}
        />
      )}
      {showDuplicatesWarning && <Caution message={t('Program is set to allow contacts to reenter. Duplicate contacts may be listed.')} />}
      {!hideTableWhenNoContacts && (
        <ProgramPerformanceDetailTableContainer
          programId={programId}
          programSteps={programSteps}
          totalContacts={totalContacts}
          programPerformanceTab={programPerformanceTab}
          tableTitle={tableTitle}
          allowReentrantAddresses={allowReentrantAddresses}
          emptyState={emptyState}
          tableSubtitle={tableSubtitle}
          downloadUrl={getContactsUrl(programId, programPerformanceTab)}
          activeTabModifier={activeTabModifier}
        />
      )}
    </div>
  )
}

export default ProgramPerformanceDetail
