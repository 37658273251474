import { ApolloClient } from '@apollo/client'
import { PushPolicy } from '@graphql/types/microservice/crm-sync-types'
import { EntityTypeDisplay, OptInOutVisibility } from '@graphql/types/microservice/crm-types'
import {
  DataManagementContextAPI,
  EntityColumn,
  EntityTable,
  RecordModalState,
  State,
  SubscriptionInfo,
  SyncSchedule,
} from '@src/pages/datamanagement/context/DataManagementContext'
import { SyncResult } from '@src/pages/datamanagement/utils/DataManagement.constants'

export const mockSyncSchedule: SyncSchedule = {
  frequency: 'Weekly',
  period: '8',
  scheduledTime: {
    hour: 3,
    minute: 15,
    ampm: 'AM',
  },
  schedule: 'Schedule',
  dayOfTheWeek: '5',
  dayOfTheMonth: '8',
  pullOptOut: true,
  pushOptOut: true,
  isOptInOutSupported: true,
  optInOutVisibility: {} as OptInOutVisibility,
}

const entities: EntityTypeDisplay[] = [
  {
    display: 'Contacts',
    identifier: 'Contacts',
    visible: true,
    nameColumns: [],
    pkColumn: 'id',
    recordCount: 100,
  },
  {
    display: 'Leads',
    identifier: 'Leads',
    visible: true,
    nameColumns: [],
    pkColumn: 'id',
    recordCount: 50,
  },
  {
    display: 'Opportunities',
    identifier: 'Opportunities',
    visible: true,
    nameColumns: [],
    pkColumn: 'id',
    recordCount: 10,
  },
]

export const mockStateValues: State = {
  syncSchedule: {} as SyncSchedule,
  originalSyncSchedule: {} as SyncSchedule,
  errors: {},
  loading: false,
  loadingULFMappings: false,
  pageError: false,
  saved: false,
  hasUpdates: false,
  openDiscardModal: false,
  syncInfo: entities,
  entityTable: [],
  recordModal: {} as RecordModalState,
  token: 'test1234',
  accountContext: 'test1234',
  lastSync: 0,
  lastSyncResult: {
    syncResult: 'Started' as SyncResult,
    showWarning: false,
  },
  accountTimeZone: 'US/Pacific',
  mutationResultState: {
    resultPerEntity: [],
    isOptInOutSaved: false,
    isSyncScheduleSaved: false,
    areFieldMappingsSaved: false,
    areReportMappingsSaved: false,
    attemptToSaveScheduleChanges: false,
    attemptToSaveOptInOutChanges: false,
    attemptToSaveEntityChanges: [],
    attemptToSaveFieldMappingChanges: false,
    attemptToSaveReportMappingsChanges: false,
  },
  runningSyncStatus: {
    isSyncingNow: false,
    syncState: '',
    isCanceling: false,
  },
  connectorType: '',
  scoreSheetsList: [],
  fieldMappings: [],
  fieldMappingStatus: new Map(),
  standardFieldsMap: new Map(),
  rowHistoryStatus: new Map(),
  crmContactData: {
    done: false,
    standardRows: [],
    customRows: [],
    stdFields: [],
    dataAvailable: false,
  },
  nonCrmContactsData: {
    standardRows: [],
    customRows: [],
    index: 0,
  },
  showCrmContactValidationToast: { open: false, text: '' },
  clients: {
    listSrvClient: {} as ApolloClient<any>,
    crmSyncSrvClient: {} as ApolloClient<any>,
    hotProspectsClient: {} as ApolloClient<any>,
    crmEntityMappingClient: {} as ApolloClient<any>,
  },
  duplicatedFieldsError: { show: false, message: '' },
  saveUCLChanges: false,
  reportMappingsData: [],
  saveReportMappingChanges: false,
  fetchNonCRMData: false,
  unifiedListFieldMappings: [],
  selectingImportContactsSource: false,
  importContactsSource: '',
}

const subscriptionInfo: SubscriptionInfo = {
  subscriber: 'test',
  description: 'test',
}

const col1: EntityColumn = {
  name: 'colNm1',
  label: 'colLabel1',
  dataType: 'String',
  required: true,
  selected: true,
  pickListValues: [],
  used: true,
  usedBy: [subscriptionInfo],
}

const col2: EntityColumn = {
  name: 'colNm2',
  label: 'colLabel2',
  dataType: 'String',
  required: false,
  selected: true,
  pickListValues: [],
  used: true,
  usedBy: [subscriptionInfo],
}

const col3: EntityColumn = {
  name: 'colNm3',
  label: 'colLabel3',
  dataType: 'String',
  required: false,
  selected: false,
  pickListValues: [],
  used: true,
  usedBy: [subscriptionInfo],
}

export const entityContactTable: EntityTable = {
  crmName: 'Sforce',
  entityType: {
    displayName: 'Contacts',
    entityTypeIdentifier: 'Contacts',
    visible: true,
    nameColumns: [],
    primaryKeyColumn: 'id',
    recordCount: 100,
  },
  nameColumns: ['contact_col1', 'contact_col2 ', 'contact_col3'],
  primaryKeyColumn: 'id',
  entityDisplayName: 'Contacts',
  columns: [col1, col2, col3],
  rows: [
    { entityTypeDataMapping: { col1: 'row1data1', col2: 'row1data2', col3: 'row1data3' } },
    { entityTypeDataMapping: { col1: 'row2data1', col2: 'row2data2', col3: 'row2data3' } },
    { entityTypeDataMapping: { col1: 'row3data1', col2: 'row3data2', col3: 'row3data3' } },
    { entityTypeDataMapping: { col1: 'row4data1', col2: 'row4data2', col3: 'row4data3' } },
    { entityTypeDataMapping: { col1: 'row5data1', col2: 'row5data2', col3: 'row5data3' } },
    { entityTypeDataMapping: { col1: 'row6data1', col2: 'row6data2', col3: 'row6data3' } },
    { entityTypeDataMapping: { col1: 'row7data1', col2: 'row7data2', col3: 'row7data3' } },
  ],
  maxSelectedColumns: 3,
  numSelectedColumns: 2,
  message: '',
}

export const entityLeadTable: EntityTable = {
  crmName: 'Sforce',
  entityType: {
    displayName: 'Leads',
    entityTypeIdentifier: 'Leads',
    visible: true,
    nameColumns: [],
    primaryKeyColumn: 'id',
    recordCount: 100,
  },
  nameColumns: ['lead_col1', 'lead_col2 ', 'lead_col3'],
  primaryKeyColumn: 'id',
  entityDisplayName: 'Leads',
  columns: [col1, col2, col3],
  rows: [],
  maxSelectedColumns: 3,
  numSelectedColumns: 2,
  message: '',
}

export const entityOpptTable: EntityTable = {
  crmName: 'Sforce',
  entityType: {
    displayName: 'Opportunities',
    entityTypeIdentifier: 'Opportunities',
    visible: true,
    nameColumns: [],
    primaryKeyColumn: 'id',
    recordCount: 100,
  },
  nameColumns: ['oppt_col1', 'oppt_col2 ', 'oppt_col3'],
  primaryKeyColumn: 'id',
  entityDisplayName: 'Opportunities',
  columns: [col1, col2, col3],
  rows: [],
  maxSelectedColumns: 3,
  numSelectedColumns: 2,
  message: '',
}

export const pushPolicyMock: PushPolicy = {
  allowPush: true,
  enabledFields: ['E-mail Address', 'First Name', 'Last Name', 'Business Fax', 'Cell Phone', 'Birth date', 'Other City'],
  listId: 'l-unified-contacts',
}

export const dataManagementContextMock: DataManagementContextAPI = {
  values: mockStateValues,
  update: () => undefined,
  userUpdates: () => undefined,
  nestedUpdate: () => undefined,
  save: () => undefined,
  discard: () => undefined,
  getLastSyncResult: () => undefined,
  updateEntityTypes: () => undefined,
  savePushPolicy: () => Promise.resolve(),
  refreshCRMData: () => undefined,
}
