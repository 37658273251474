import React, { FC, Fragment, useState } from 'react'

import classNames from 'classnames'
import InnerHtml from 'dangerously-set-html-content'

import BackButton from '@components/BackButton/BackButton'
import Button, { ButtonType } from '@components/Button'
import { ButtonTextAlign } from '@components/Button/Button'
import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Pill, { PillSize, PillType } from '@components/Pill/Pill'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Svg, { SvgType } from '@components/Svg/Svg'
import SvgNames from '@components/Svg/SvgNames'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { ModalHeaderListStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { PreviewAsset } from '@graphql/types/query-types'
import {
  PreviewAndImportModalProps,
  PreviewAndImportModalState,
  PreviewAssetsByType,
} from '@src/pages/MarketingNetwork/Inbox/components/PreviewAndImportModal/PreviewAndImport.types'
import { ChangeReplace, ImportDeclineOptions, NoPreviewTypes, PreviewAssetType } from '@src/pages/MarketingNetwork/Inbox/Inbox.constants'
import { typeToText } from '@src/pages/MarketingNetwork/Inbox/Inbox.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import { titleCase } from '@utils/strings'

import './PreviewAndImportModal.css'

const rootClass = 'preview-and-import-modal'

type Props = PreviewAndImportModalProps &
  PreviewAndImportModalState & {
    toggleSection: (assetType: PreviewAssetType) => void
    changeCurrentAsset: (id: string, type: string) => void
  }

const PreviewAndImportModal: FC<Props> = (props: Props) => {
  const {
    id,
    title,
    type,
    fromUser,
    fromOrg,
    changeOrReplace,
    previewInfo,
    dataTest = rootClass,
    className = '',
    doAction,
    onClose,
    isOpen,
    assets = [],
    currentAsset,
    changeCurrentAsset,
    expandedSections,
    toggleSection,
    assetsByType,
    actionType,
    showAction,
  } = props

  const { t } = useTranslation()
  const { newLPComposerCreateBlank } = useAccountSettings()

  const [acting, setActing] = useState(false)

  const centerPreview = currentAsset.id === id && currentAsset.type === type
  const previewAsset = assets.find((asset) => asset && asset.id === currentAsset.id && asset.type === currentAsset.type)

  const onChangePreview = (previewId: string, type: string) => {
    changeCurrentAsset(previewId, type)
  }

  const noPreviewAvailable = NoPreviewTypes.includes(currentAsset.type as PreviewAssetType)

  const header = (
    <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__header`}>
      {!showAction && <BackButton onClick={() => onClose()} className={`${rootClass}__header__back-button`} />}
      <Typography className={`${rootClass}__header__title`} text={`${typeToText(type, false)}: ${title}`} {...ModalHeaderListStyle} />
    </ModalHeader>
  )

  const renderAssetIndicator = (indicator: ChangeReplace) => {
    return (
      <Tooltip
        alwaysShowOnHover
        position={'top'}
        trigger={
          <Typography
            text={indicator === ChangeReplace.CHANGE ? 'C' : 'R'}
            type={TextType.BODY_TEXT_SMALL}
            weight={TextWeight.BOLD}
            className={`${rootClass}__indicator`}
          />
        }
      >
        {t(indicator === ChangeReplace.CHANGE ? 'Copy' : 'Replace')}
      </Tooltip>
    )
  }

  const renderTitle = (title?: string, isSelected?: boolean) => {
    return (
      <TextWithTooltipOnEllip
        typographyProps={{
          text: title ? title : `[${t('Untitled')}]`,
          type: title ? TextType.BODY_TEXT : TextType.BODY_TEXT_LIGHT,
          className: `${rootClass}__assets-asset-text`,
          weight: title ? (isSelected ? TextWeight.MEDIUM : TextWeight.REGULAR) : TextWeight.ITALIC,
        }}
      />
    )
  }

  const renderPill = (beeComposer: boolean) => {
    if (beeComposer && newLPComposerCreateBlank && type === 'LANDINGPAGE') {
      return <Pill type={PillType.SECONDARY} text={t('Beta')} size={PillSize.SMALL} noMargin className={`${rootClass}__assets-asset-pill`} />
    }

    return (
      !beeComposer &&
      (type === 'TEMPLATE' || type === 'AUTORESPONSE') && (
        <Pill type={PillType.GRAY} text={t('Legacy')} size={PillSize.SMALL} noMargin className={`${rootClass}__assets-asset-pill`} />
      )
    )
  }

  const renderAsset = (asset: PreviewAsset) => {
    const isSelected = asset.id === currentAsset.id
    const beeComposer = asset.beeComposer

    return (
      <div
        role={'button'}
        tabIndex={0}
        onKeyDown={(keyDownEvent) => (keyDownEvent.key === ' ' ? onChangePreview(asset.id, asset.type) : undefined)}
        onClick={() => onChangePreview(asset.id, asset.type)}
        className={classNames(`${rootClass}__assets-asset`, {
          [`${rootClass}__assets-asset-selected`]: isSelected,
        })}
        key={asset.id}
      >
        <div className={`${rootClass}__assets-asset-title-container`}>
          {asset.changeOrReplace && renderAssetIndicator(asset.changeOrReplace as ChangeReplace)}
          {renderTitle(asset.title, isSelected)}
          {beeComposer !== null && renderPill(beeComposer ?? false)}
        </div>
      </div>
    )
  }

  const renderAssetSection = (assetType: PreviewAssetType, assetsByType: PreviewAssetsByType) => {
    const isExpanded = expandedSections.includes(assetType)
    const previewAssets = assetsByType[assetType]
    const assetCount = previewAssets.length

    if (assetCount === 0) {
      return undefined
    }

    return (
      <Fragment key={assetType}>
        <div
          role={'button'}
          tabIndex={0}
          onKeyDown={(keyDownEvent) => (keyDownEvent.key === ' ' ? toggleSection(assetType) : undefined)}
          className={classNames(`${rootClass}__assets-section`, { [`${rootClass}__assets-section-expanded`]: isExpanded })}
          onClick={() => toggleSection(assetType)}
        >
          <Svg
            name={isExpanded ? SvgNames.caretFillDown : SvgNames.caretFillRight}
            type={SvgType.VERY_SMALL_ICON}
            dataTest={`expander-${assetType}`}
            className={`${rootClass}__expander`}
          />
          <Typography text={`${typeToText(assetType)} (${assetCount})`} weight={TextWeight.MEDIUM} className={`${rootClass}__assets-section-text`} />
        </div>

        {isExpanded && previewAssets?.map((asset) => renderAsset(asset))}
        <div className={`${rootClass}__divider`} />
      </Fragment>
    )
  }

  const renderPrimaryTitle = () => {
    const isSelected = currentAsset.id === id
    return (
      <TextWithTooltipOnEllip
        typographyProps={{
          text: title,
          className: `${rootClass}__assets-primary-text`,
          weight: isSelected ? TextWeight.MEDIUM : TextWeight.REGULAR,
        }}
      />
    )
  }

  const renderPrimary = () => {
    const isSelected = currentAsset.id === id
    const beeComposer = previewInfo?.beeComposer
    return (
      <Button
        buttonType={ButtonType.TEXT}
        buttonTextAlign={ButtonTextAlign.LEFT}
        className={classNames(`${rootClass}__assets-primary`, { [`${rootClass}__assets-asset-selected`]: isSelected })}
        onClick={() => onChangePreview(id, type)}
      >
        {changeOrReplace && <div className={`${rootClass}__assets-primary-indicator`}>{renderAssetIndicator(changeOrReplace as ChangeReplace)}</div>}
        {renderPrimaryTitle()}
        {beeComposer !== null && renderPill(beeComposer ?? false)}
      </Button>
    )
  }

  const renderAssets = () => {
    return (
      <div className={`${rootClass}__assets`}>
        {renderPrimary()}
        <div className={`${rootClass}__divider`} />
        <div className={`${rootClass}__assets-list`}>
          {assetsByType &&
            Object.keys(assetsByType).map((assetType) => {
              return assetsByType[assetType as PreviewAssetType].length > 0
                ? renderAssetSection(assetType as PreviewAssetType, assetsByType)
                : undefined
            })}
        </div>
      </div>
    )
  }

  const renderPreview = () => {
    if (noPreviewAvailable) {
      return (
        <EmptyListing
          className={`${rootClass}__empty-listing`}
          headline={t('No preview available')}
          text={t('Inbox.EmptyListing.Preview')}
          imgSrc={StaticImageNames.emptySearch}
          size={EmptyListingSize.MEDIUM}
          fullHeight
          withoutBorder
        />
      )
    }
    if (currentAsset.id === id && !previewInfo) {
      return <Typography text={t('There is no preview information for this asset')} type={TextType.BODY_TEXT_WHITE} weight={TextWeight.MEDIUM} />
    }
    if (currentAsset.id === id && currentAsset.type === type && previewInfo) {
      if (previewInfo.isIframe) {
        return <iframe title={'preview asset'} src={previewInfo.url} className={`${rootClass}__preview-container`} data-test={`${dataTest}-iframe`} />
      }
      return <InnerHtml html={previewInfo.contents ?? ''} className={`${rootClass}__preview-container`} />
    } else {
      if (previewAsset) {
        if (previewAsset.type === PreviewAssetType.IMAGE) {
          return <img src={previewAsset.url} alt={previewAsset.title} className={`${rootClass}__preview-image`} />
        }
        if (previewAsset.url && previewAsset.url !== 'null') {
          return <iframe title={'preview asset'} src={previewAsset.url} className={`${rootClass}__preview-container`} />
        } else if (previewAsset.contents) {
          return (
            <iframe
              title={'preview asset'}
              srcDoc={previewAsset.contents ?? ''}
              className={`${rootClass}__preview-container`}
              data-test={`${dataTest}-iframe`}
            />
          )
        }
        // Should never hit this
        return ''
      } else {
        // Should never hit this
        return ''
      }
    }
  }

  const renderBlurb = () => (
    <div className={`${rootClass}__blurb`}>
      <Typography text={t('Inbox.Preview.Blurb')} tagProps={{ bold: { weight: TextWeight.MEDIUM } }} values={{ from: fromUser, fromOrg }} inline />
    </div>
  )

  const takeAction = () => {
    setActing(true)
    doAction && doAction()
  }

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
      <ModalBody className={`${rootClass}__body`}>
        <div
          className={classNames(`${rootClass}__left`, {
            [`${rootClass}__left-no-footer`]: !showAction,
          })}
        >
          {renderBlurb()}
          {renderAssets()}
        </div>
        <div
          className={classNames(`${rootClass}__right`, {
            [`${rootClass}__right-center`]: centerPreview,
            [`${rootClass}__right-no-footer`]: !showAction,
            [`${rootClass}__right-no-preview`]: noPreviewAvailable,
          })}
        >
          {renderPreview()}
        </div>
      </ModalBody>
      {showAction && (
        <ModalFooter footerType={ModalFooterType.Form} className={`${rootClass}__footer`}>
          <Button buttonType={ButtonType.TERTIARY} onClick={onClose} dataTest={`${dataTest}-button-tertiary`}>
            {t('Close')}
          </Button>
          <Button
            buttonType={actionType === ImportDeclineOptions.IMPORT ? ButtonType.PRIMARY : ButtonType.DELETE}
            onClick={takeAction}
            disabled={acting}
            dataTest={`${dataTest}-button-primary`}
          >
            {t(titleCase(actionType))}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  )
}

export default PreviewAndImportModal
