import { PreProcessedList, SelectedListsType } from '@complex/ListPickerModalV2/utils/interfaces/ListPickerModalInterfaces'
import { unifiedListTypes } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import { renderTooltip, rowFinder } from '@complex/ListPickerModalV2/utils/ListPickerModalTableUtils'
import { TableColumn } from '@components/Table/Table'
import { TextAlign, TextType } from '@components/Typography/Typography'
import { ListCategory } from '@utils/lists'
import { getFormattedNumber } from '@utils/numbers'

export const columns: TableColumn[] = [
  {
    Header: 'List/Segment',
    accessor: 'name',
    align: 'left',
    Cell: ({ cell }) => renderTooltip(cell.value),
    flexColumn: true,
  },
  {
    Header: 'Source',
    accessor: 'type',
    align: 'left',
    Cell: ({ cell }) => renderTooltip(cell.value),
    className: 'selected-lists__source',
    maxWidth: 124,
  },
  {
    Header: 'Records',
    accessor: 'size',
    align: 'right',
    Cell: ({ cell: { value } }) => (value !== undefined ? getFormattedNumber(value) : ''),
    className: 'selected-lists__records',
    maxWidth: 54,
  },
]

export const UCLColumns: TableColumn[] = [
  {
    Header: 'List/Segment',
    accessor: 'name',
    align: 'left',
    Cell: ({ cell }) => renderTooltip(cell.value),
    flexColumn: true,
  },
  {
    Header: 'Tags',
    accessor: 'tags',
    align: 'left',
    className: 'selected-lists__tags',
    maxWidth: 132,
  },
  {
    Header: 'Source',
    accessor: 'type',
    align: 'left',
    Cell: ({ cell }) => renderTooltip(cell.value),
    className: 'selected-lists__ucl-source',
    maxWidth: 124,
  },
  {
    Header: 'Records',
    accessor: 'size',
    align: 'right',
    Cell: ({ cell: { value } }) => (value !== undefined ? getFormattedNumber(value) : ''),
    className: 'selected-lists__records',
    maxWidth: 54,
  },
]

interface GetSelectedListsTablePropsParams {
  lists: PreProcessedList[]
  listType: ListCategory
  multiSelect: boolean
  selectedLists: SelectedListsType
}

const getSelectedListsTableProps = ({ lists, listType, multiSelect, selectedLists }: GetSelectedListsTablePropsParams) => ({
  autoResetSelectedRows: false,
  checkboxWidth: 16,
  columns: unifiedListTypes.includes(listType) ? UCLColumns : columns,
  customRowFinder: rowFinder,
  data: lists as any[],
  defaultSelectedRows: selectedLists[listType],
  hideIcon: false,
  isSingleSelect: !multiSelect,
  textAlign: TextAlign.LEFT,
  textType: TextType.BODY_TEXT_LIGHT,
  useHeaderCheckbox: multiSelect,
  useCheckboxes: true,
})

const getSelectedListsUCLTableProps = (values: GetSelectedListsTablePropsParams) => {
  const baseProps = getSelectedListsTableProps(values)
  return {
    ...baseProps,
    readOnlyTags: true,
    tags: [],
  }
}

type BuildSelectedListsTablePropsParams = GetSelectedListsTablePropsParams

export const buildSelectedListsTableProps = ({ lists, listType, multiSelect, selectedLists }: BuildSelectedListsTablePropsParams) => {
  const baseTableValues = {
    listType,
    lists,
    multiSelect,
    selectedLists,
  }
  return {
    ...(unifiedListTypes.includes(listType) ? getSelectedListsUCLTableProps(baseTableValues) : getSelectedListsTableProps(baseTableValues)),
  }
}
