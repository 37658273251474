import { ApolloError } from '@apollo/client'
import { UserResponse } from '@graphql/types/query-types'
import { createSafeContext, useSafeContext } from '@utils/account/account.utils'
interface MarketingUsers {
  data: {}
  loading: boolean
  networkStatus: number
}

export interface MarketingUsersContextAPI {
  users: UserResponse[]
  loading: boolean
  error: ApolloError | undefined
  refetch: () => Promise<MarketingUsers>
  maxCount: number
}

const MarketingUsersContext = createSafeContext<MarketingUsersContextAPI>()
export const useMarketingUsers = () => useSafeContext(MarketingUsersContext)

export default MarketingUsersContext
