import { createContext, MutableRefObject } from 'react'

import { Dayjs } from 'dayjs'

import Bee from '@beefree.io/sdk'
import { BulkModalProps } from '@components/BulkActionsModal/BulkActionsModal'
import { ConfirmationModalProps } from '@components/ConfirmationModal/ConfirmationModal'
import { DeleteConfirmationModalProps } from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import { KeyValue } from '@components/KeyValuePicker/KeyValuePicker'
import { SpinnerProps } from '@components/Spinner/Spinner'
import { StatusToastProps } from '@components/StatusToast/StatusToast'
import { DeliverabilityConcernResponse } from '@graphql/types/mutation-types'
import {
  AssetReportRecipientDetails,
  FromAddresses,
  ListsValidation,
  LitmusClientPreviewResponse,
  LitmusSpamResult,
  PersonalizationFieldErrors,
  PersonalizationFieldsErrorsForSources,
  SendToListResponse,
  ValidationResponse,
  WebinarConfig,
} from '@graphql/types/query-types'
import { CommonComposerAPI, CommonComposerState, CommonComposerTab } from '@utils/composer/commonComposer/CommonComposer.context'
import { DUPLICATED_AS_DRAFT_ID, DUPLICATED_AS_TEMPLATE_ID, SAVED_AS_COPY_ID } from '@utils/composer/EmailModal.constants'
import { isCustomerCareLogin } from '@utils/cookie'
import { DeepPartial } from '@utils/types'

import { IEntityContentJsonExtended, IPluginDisplayConditionExtended } from '../beeEditor/beeEditorTypes'
import { beeClientConfig } from '../beeEditor/constants'
import { defaultSendMethod } from '../emailComposer/constants'
import { mockEmailBeeTemplate } from '../emailComposer/templates/beeTemplate'
import {
  ComposerFormTypeModalProps,
  ExtendedMessageType,
  messageConfigurationSettings,
  MessageConfigurationType,
  SettingsForm,
} from '../emailComposer/types'
import { BlockedModalProps, DuplicateModalProps } from '../landingPage/types'

export interface SendtoList {
  srcId: string
  srcName: string
  isList: boolean
  baseId: string
  size: number
}

// LATER considered as STANDARD in BE
export type SEND_TYPE = 'STANDARD' | 'LATER' | 'TIME_ZONE' | 'STAGGERED' | 'ADAPTIVE' | ''

export enum SendTimeType {
  STANDARD = 'STANDARD',
  LATER = 'LATER',
  TIME_ZONE = 'TIME_ZONE',
  STAGGERED = 'STAGGERED',
  ADAPTIVE = 'ADAPTIVE',
}

export interface SendMethod {
  sendtype?: SendTimeType
  senddate?: Date
  sendtime?: string
  sendtimezone?: string
  defaultsendtimezone?: string
  staggertimezone?: string
  staggerdatestart?: Dayjs
  staggerdateend?: Dayjs
  staggertimestart?: string
  staggertimeend?: string
  adaptivedatestart?: Dayjs
  adaptivedateend?: Dayjs
}

export interface SendToContact {
  id: string
  email: string
  name: string
  srcId?: string
}

export interface ListWithContacts {
  id: string
  name: string
  contacts: SendToContact[]
}

export interface Webinar {
  type: string
  scheduledTime: number
  programId?: string
  groupId?: string
  taskId?: string
}

/**
 * EmailMessage contains any fields that should be saved with the message.
 * Changes to these values will trigger an autosave.
 * Please do not add container state fields to this type.
 */
export type EmailMessage = {
  // Settings
  id: string
  /** Need to use only for A/B test and RSS emails publish
   * all requests will be made with temporal 'id' except Publish changes */
  publishId?: string
  title: string
  sender?: string
  senderUUID?: string
  fallbackSender?: string
  replyToSender?: string
  subject: string
  previewText: string
  analyticsFields: KeyValue[]
  subscriptionCategoryId: string
  hasPrePopulatedUTMParams?: boolean
  campaignId: string
  isTransactional: boolean
  isTriggered: boolean
  messageType: ExtendedMessageType
  retrieveMessageType?: ExtendedMessageType
  webinar?: Webinar
  webinarConfig?: WebinarConfig
  isWebinarScheduled?: boolean
  programId?: string
  groupId?: string
  taskId?: string
  isAbTestRunning?: boolean
  isAbTestsSent?: boolean
  abTestVariation?: string
  // Content
  templateJson: IEntityContentJsonExtended
  templateHtml: string
  isPlainTextOnly?: boolean
  isUploadHtmlOnly?: boolean
  /**
   * Template blocks are repositioned when the plain text value changes,
   * so this copy of the data outside of the template JSON is required
   * to keep track of content that may temporarily not be present in the value
   */
  plainTextOnlyDynamicContent: IPluginDisplayConditionExtended[]
  // Send
  sendto_lists?: SendtoList[]
  sendto_contacts?: SendToContact[]
  suppress_dups: boolean
  suppress_lists?: SendtoList[]
  individualRecipientsSourceIds?: (string | undefined)[]
  overrideFatigueRules: boolean
  sendMethod: SendMethod
  isCrmLaunch: boolean
  isCrmSentNote: boolean
  restricteLists?: (SendToListResponse | undefined)[]
  /**
   * Recipient details from the asset reports (sent message report/form report/landing page report).
   * Only instantiated when the message was created from one of the asset report touchpoints
   */
  assetReportRecipientDetails?: AssetReportRecipientDetails

  /** Custom plain text */
  customTextPart: string
  lastUpdatedBy?: string
  // ETE Developer API Details
  apiDetailsPayload?: string
}

export type SavedEmailMessageTemplate = DeepPartial<Pick<EmailMessage, 'templateJson' | 'templateHtml'>>

export enum EmailPreviewPlatform {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
  INBOX = 'inbox',
}

export type LitmusPreview = LitmusClientPreviewResponse & { index: number }

export interface PreviewContact {
  id: string
  index: number
  name?: string
  email: string
}

export type DynamicContentListItem = IPluginDisplayConditionExtended & { uuid?: string; rowIndex: number }

export type EmailPreview = {
  loadingPersonalization: boolean
  loadingLitmusPreviews: boolean
  loadingPlainText: boolean
  html: string
  plainText: string
  formattedPlainText: string
  formattedSubject: string
  formattedPreviewText: string
  isDarkMode: boolean
  isPlainText: boolean
  isCodeModalOpen: boolean
  isPreviewPersonalizationOn: boolean
  isPreviewDynamicContentOn: boolean
  platform: EmailPreviewPlatform
  contactsList: PreviewContact[]
  dynamicContentList: DynamicContentListItem[]
  selectedContact: PreviewContact | null
  litmusPreviews: LitmusPreview[]
  selectedLitmusPreview: LitmusPreview | null
  personalizedSender?: { senderName?: string; senderEmail?: string }
}

export const DEFAULT_EMAIL_COMPOSER_TAB = CommonComposerTab.DESIGN

export enum ValidationSectionType {
  SENDER = 'sender',
  SUBJECT = 'subject',
  RECIPIENTS = 'recipients',
  CONTENT = 'content',
  ANTI_SPAM = 'anti_spam',
  DELIVERABILITY = 'deliverability',
  SEND_METHOD = 'send_method',
  TRANSACTION_EMAIL = 'transactional_email',
  TRACKING = 'tracking',
}

export type ValidationSectionInfo = { errors?: number; warnings?: number; loading?: boolean }

export type EmailSender = Required<Pick<FromAddresses, 'email' | 'name' | 'uuid' | 'isDefault'>>

export interface EmailComposerModalState {
  statusToast: Pick<StatusToastProps, 'title' | 'message' | 'status' | 'hasTimeout'> | undefined
  spinner: SpinnerProps | undefined
  confirmation: Partial<ConfirmationModalProps> | undefined
  deleteConfirmation: Partial<DeleteConfirmationModalProps> | undefined
  editPlainText: true | undefined
  formTypeModal: ComposerFormTypeModalProps | undefined
  bulkErrorModal: BulkModalProps | undefined
  duplicateModal?: DuplicateModalProps
  blockedModal?: BlockedModalProps
}

export interface ClickthroughLinks {
  type: string
  label: string | HTMLAnchorElement | null
  url: string | null
  valid: boolean
}

export interface PlaceholderBlocks {
  button: boolean
  html: boolean
  list: boolean
  paragraph: boolean | string
  title: boolean
  tableHeader: boolean
  tableRow: boolean
  webinar?: string
}

export interface EmptyBlocks {
  heading: number
  paragraph: number
  list: number
  button: number
  social: number
  html: number
  icons: number
  menu: number
}

export interface EmptyRow {
  condition: string
}

export interface ContentValidations {
  htmlSize: number
  isHtmlSizeValid: boolean
  isClickthroughLoading: boolean
  isClickthroughError: boolean
  hasDefaultPlaceholderBlock: boolean
  hasEmptyBlock: boolean
  clickthroughLinks: ClickthroughLinks[]
  invalidClickthroughLinks: ClickthroughLinks[]
  prohibitedDataURIs: string[]
  placeholderBlocks: PlaceholderBlocks
  emptyBlocks: EmptyBlocks
  emptyRows: EmptyRow[]
  isOnlyDynamicRows: boolean
  hasRssBlock: boolean
  svgUrls: string[]
}

export interface SettingsValidations {
  alwaysShowInlineValidations: Partial<Record<keyof SettingsForm, boolean>>
  isAnalyticsFieldsIncomplete: boolean
  subjectEmojiCountExceeded: boolean
  subjectLengthExceeded: boolean
  subjectRequiredForETE: boolean
  subjectLineContainsOnlyEmojis: boolean
  previewLengthExceeded: boolean
  titleMissing: boolean
  subjectMissing: boolean
  listsIncompatibleWithSalesforceOwner: string[]
  listsIncompatibleWithPersonalizedFrom: string[]
  subjectPersonalizationErrors?: PersonalizationFieldsErrorsForSources[]
  previewPersonalizationErrors?: PersonalizationFieldsErrorsForSources[]
  loadingPersonalizationErrors?: boolean
  failedPersonalizationErrors?: boolean
}

export interface EmailValidations {
  isEmailValidated: boolean
  isValidationsLoading: boolean
  isContentMissing: boolean
  hasZeroRecipients: boolean
  settingsValidations: SettingsValidations
  contentValidations: ContentValidations & ValidationResponse
  deliverabilityValidations: DeliverabilityConcernResponse
  litmusSpamResults: LitmusSpamResult | null
  personalization: { personalizationErrors?: PersonalizationFieldErrors[] | null; lastChecked?: number; noContent?: boolean }
  recipients: ListsValidation
  validationSectionsStatus: Partial<{ [key in ValidationSectionType]: ValidationSectionInfo }>
  contentPersonalizationErrors?: PersonalizationFieldsErrorsForSources[]
  errorsCount?: number
  warningsCount?: number
  needToCheckValidation?: boolean
}

export interface MessageLimits {
  monthlyLimit: number
  currentUsage: number
}

export interface ComposerHistoryState {
  previousTab?: CommonComposerTab
  fromValidationSection?: ValidationSectionType
  visitedTabs?: Partial<Record<CommonComposerTab, boolean>>
  [SAVED_AS_COPY_ID]?: string
  [DUPLICATED_AS_DRAFT_ID]?: string
  [DUPLICATED_AS_TEMPLATE_ID]?: string
}

export interface DetectedURLChanges {
  hasStartId?: boolean
  hasToParam?: boolean
  isNewMessage?: boolean
  plainTextMode?: boolean
  uploadHtmlMode?: boolean
  debugMode?: boolean
  isBlankMessage?: boolean
  isNewAssetReportMessage?: boolean
}

export interface EmailComposerState extends CommonComposerState {}

export type OnStatusChangeFunc = (info: ValidationSectionInfo, name: ValidationSectionType) => void

export type EmailComposerAPI = CommonComposerAPI<EmailComposerState>

export type EmailComposerProviderContext = {
  values: EmailComposerState
  api: EmailComposerAPI
}

export const DEFAULT_EMAIL_TITLE = 'Untitled Email'

export const defaultEmailComposerValidations: EmailValidations = {
  isEmailValidated: false,
  isValidationsLoading: false,
  isContentMissing: true,
  hasZeroRecipients: false,
  needToCheckValidation: false,
  contentValidations: {
    htmlSize: 0,
    isHtmlSizeValid: false,
    isClickthroughLoading: false,
    isClickthroughError: false,
    hasDefaultPlaceholderBlock: false,
    hasEmptyBlock: false,
    clickthroughLinks: [],
    invalidClickthroughLinks: [],
    prohibitedDataURIs: [],
    placeholderBlocks: {
      button: false,
      html: false,
      list: false,
      paragraph: false,
      title: false,
      tableHeader: false,
      tableRow: false,
      webinar: '',
    },
    emptyBlocks: {
      heading: 0,
      paragraph: 0,
      list: 0,
      button: 0,
      social: 0,
      html: 0,
      icons: 0,
      menu: 0,
    },
    emptyRows: [],
    isOnlyDynamicRows: false,
    hasRssBlock: false,
    svgUrls: [],
  },
  settingsValidations: {
    alwaysShowInlineValidations: {},
    isAnalyticsFieldsIncomplete: false,
    subjectEmojiCountExceeded: false,
    subjectLineContainsOnlyEmojis: false,
    subjectLengthExceeded: false,
    subjectMissing: false,
    subjectRequiredForETE: false,
    previewLengthExceeded: false,
    titleMissing: false,
    listsIncompatibleWithSalesforceOwner: [],
    listsIncompatibleWithPersonalizedFrom: [],
  },
  deliverabilityValidations: {},
  litmusSpamResults: {},
  personalization: {},
  recipients: {},
  validationSectionsStatus: {},
}

export const defaultEmailComposerState: EmailComposerState = {
  landingPage: {},
  publicUrlManager: {},
  message: {
    id: '',
    title: DEFAULT_EMAIL_TITLE,
    subject: '',
    previewText: '',
    templateJson: mockEmailBeeTemplate,
    templateHtml: '',
    analyticsFields: [],
    sendMethod: defaultSendMethod,
    isCrmLaunch: false,
    subscriptionCategoryId: '',
    campaignId: '',
    sendto_lists: [],
    suppress_dups: true,
    individualRecipientsSourceIds: [],
    overrideFatigueRules: false,
    suppress_lists: [],
    isTransactional: false,
    isTriggered: false,
    messageType: 'DRAFT',
    isCrmSentNote: false,
    customTextPart: '',
    plainTextOnlyDynamicContent: [],
  },
  retrieveMessageSuccess: false,
  createMessageSuccess: false,
  sendErrorCount: false,
  lastSavedTitle: DEFAULT_EMAIL_TITLE,
  isPreview: false,
  isTestSend: false,
  isSend: false,
  isClickthroughLinks: false,
  isCustomerCareSaveModal: false,
  isReviewAndSendModal: false,
  tab: DEFAULT_EMAIL_COMPOSER_TAB,
  loading: true,
  isBeeLoading: true,
  isPlainTextComposerLoading: false,
  isSaving: false,
  isIndicatingAutoSave: false,
  autoSaveFailure: false,
  autoSaveSuccess: undefined,
  beeEditorRef: { current: undefined } as unknown as MutableRefObject<Bee>,
  isCoeditingPrimaryUser: true,
  beeConfig: undefined,
  personalizations: undefined,
  emailSenders: [],
  analyticsDomains: [],
  subscriptionCategories: [],
  campaigns: [],
  hasFatigueRules: true,
  modalState: {
    statusToast: undefined,
    spinner: undefined,
    confirmation: undefined,
    deleteConfirmation: undefined,
    editPlainText: undefined,
    formTypeModal: undefined,
    bulkErrorModal: undefined,
  },
  preview: {
    loadingPersonalization: false,
    loadingLitmusPreviews: false,
    loadingPlainText: false,
    html: '',
    plainText: '',
    formattedPlainText: '',
    formattedSubject: '',
    formattedPreviewText: '',
    isDarkMode: false,
    isPlainText: false,
    isCodeModalOpen: false,
    isPreviewPersonalizationOn: false,
    isPreviewDynamicContentOn: false,
    platform: EmailPreviewPlatform.DESKTOP,
    contactsList: [],
    dynamicContentList: [],
    selectedContact: null,
    litmusPreviews: [],
    selectedLitmusPreview: null,
  },
  validations: defaultEmailComposerValidations,
  launchApproval: 'allowed to launch',
  isCoEditing: false,
  editingUsernames: [],
  messageLimits: {
    monthlyLimit: 10,
    currentUsage: 9,
  },
  savedRowCategories: [],
  savedRows: [],
  savedRowDuplicateError: false,
  recipientsCount: 0,
  previousSendType: SendTimeType.STANDARD,
  lastEmailContentEditSession: undefined,
  isInitializeBeeEditor: false,
  showEmailTooLargeWarning: false,
  emailTooLargeWarningDismissed: false,
  disabledFeatures: {
    autoSave: isCustomerCareLogin(),
    editPlainText: false,
  },
  messageConfiguration: messageConfigurationSettings[MessageConfigurationType.DRAFT],
  detectedURLChanges: {
    hasStartId: false,
    hasToParam: false,
    isNewMessage: false,
    plainTextMode: false,
    uploadHtmlMode: false,
  },
  beeClientId: beeClientConfig.CLIENT_ID,
  beeClientSecret: beeClientConfig.CLIENT_SECRET,
}

export const defaultEmailComposerAPI: EmailComposerAPI = {
  update: () => null,
  updateModal: () => null,
  updatePreview: () => null,
  updateValidations: () => null,
  onTest: () => null,
  onEditPlainText: () => null,
  onPreview: () => null,
  onTabChange: () => null,
  onStatusChange: () => null,
  onJoinSession: () => null,
  onCreateSubscriptionCategory: () => Promise.resolve(undefined),
  onCreateEmailContentEditSession: () => Promise.resolve(undefined),
  onCreateCampaign: () => Promise.resolve(undefined),
  onConvertToTemplate: (_discardDraft: boolean) => Promise.resolve({ errors: 'No handler defined' }),
  onClose: () => null,
  onSave: () => Promise.resolve(true),
  onSaveAndClose: () => Promise.resolve(),
  onSaveAsCopy: () => Promise.resolve(),
  onAutoSave: () => Promise.resolve(),
  onSaveAsDraft: () => Promise.resolve(),
  onSaveAsTemplate: () => Promise.resolve(),
  onCheckSubjectAndPreviewText: () => Promise.resolve(),
  onDiscardAndClose: () => Promise.resolve(),
  onGetRowsConfigurations: () => undefined,
  eventHooks: {},
}

export const defaultEmailComposerContext: EmailComposerProviderContext = {
  values: defaultEmailComposerState,
  api: defaultEmailComposerAPI,
}

export const EmailComposerContext = createContext<EmailComposerProviderContext>(defaultEmailComposerContext)
