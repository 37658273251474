import { ProgramSource } from '@graphql/types/query-types'
import { BaseList, UpgradedSource } from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'

export const getSource = (originalSource: ProgramSource | undefined, upgradedSource: BaseList): UpgradedSource => ({
  source: {
    id: originalSource?.id ?? '',
    sourceName: originalSource?.name ?? '',
    count: originalSource?.count ?? 0,
  },
  newSource: upgradedSource,
  removed: false,
  upgradeId: '',
})
