import React, { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useApolloClient, useMutation } from '@apollo/client'
import AddressModal from '@components/AddressModal/AddressModal'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import manageCompanyAddress from '@graphql/mutations/manageCompanyAddress'
import { ManageAddressMutation } from '@graphql/types/mutation-types'
import { AddressResponse, CompanyPageResponse, MutationManageAddressArgs } from '@graphql/types/query-types'
import WithLoadingAndError from '@hoc/withLoadingAndError/withLoadingAndError'
import { StatusToastType } from '@interface/StatusToast'
import { logNewRelicError } from '@utils/new-relic.utils'

export enum AddressTypes {
  DEFAULT = 'default',
  OTHER = 'other',
}

export enum AddressMutationOptionTypes {
  ADD = 'add',
  CHANGE = 'change',
  REMOVE = 'remove',
}

export type AddressMutationFunctionType = (
  addressToSave: AddressResponse,
  addressType: AddressTypes,
  optionType: AddressMutationOptionTypes,
  UUID?: string
) => void

interface AddressModalContainerProps {
  isOpen: boolean
  handleModalClose: () => void
  setToastStatus?: (value: StatusToastType) => void
  refetchCompanyInfo?: () => void
  companyInfo?: CompanyPageResponse
  selectedAddressToEdit?: AddressResponse
  onCreateAddress?: () => void
  className?: string
  dataTest?: string
}

const rootClass = 'address-modal-container'

const AddressModalContainer: FC<AddressModalContainerProps> = (props: AddressModalContainerProps) => {
  const { isOpen, handleModalClose, selectedAddressToEdit, setToastStatus, onCreateAddress, refetchCompanyInfo, companyInfo } = props
  const { t } = useTranslation()
  const client = useApolloClient()
  const [duplicatedNameAddress, setDuplicatedNameAddress] = useState<AddressResponse | undefined>(undefined)
  const isDefaultChecked = useMemo<boolean>(() => !!selectedAddressToEdit && !selectedAddressToEdit?.UUID, [selectedAddressToEdit])

  const [manageAddress] = useMutation<ManageAddressMutation, MutationManageAddressArgs>(manageCompanyAddress, {
    client,
    fetchPolicy: 'no-cache',
  })

  const onMutateAddress = useCallback<AddressMutationFunctionType>((address, addressType, optionType, UUID) => {
    manageAddress({
      variables: {
        address,
        addressType,
        optionType,
        UUID,
      },
    })
      .then(() => {
        const statusMessage =
          optionType === AddressMutationOptionTypes.ADD
            ? t('Company.Address.Add.Success.Message')
            : optionType === AddressMutationOptionTypes.REMOVE
            ? t('Company.Address.Delete.Success.Message', { addressName: address.name })
            : t('Company.Address.Change.Success.Message')
        setToastStatus &&
          setToastStatus({
            showStatus: true,
            title: t('Success!'),
            statusMessage,
            successStatus: true,
          })
        onCreateAddress && onCreateAddress()
        refetchCompanyInfo && refetchCompanyInfo()
      })
      .catch((error) => {
        setToastStatus &&
          setToastStatus({
            showStatus: true,
            statusMessage: t('Something went wrong on our end. Please try again.'),
            successStatus: false,
          })
        logNewRelicError(error)
      })
  }, [])

  const handleSaveAddress = useCallback(
    (addressToSave: AddressResponse) => {
      const { UUID, ...address } = addressToSave
      const changingDefault = addressToSave.name === companyInfo?.defaultAccountAddress?.name
      const addressType = changingDefault ? AddressTypes.DEFAULT : AddressTypes.OTHER
      const optionType = changingDefault || UUID ? AddressMutationOptionTypes.CHANGE : AddressMutationOptionTypes.ADD
      onMutateAddress(address, addressType, optionType, UUID)
    },
    [companyInfo?.defaultAccountAddress?.name, selectedAddressToEdit, onMutateAddress]
  )

  const prepareSaveAddress = useCallback(
    (address: AddressResponse, oldName: string) => {
      /** Checking address existence */
      if (companyInfo?.defaultAccountAddress?.name === address.name && oldName !== address.name) {
        setDuplicatedNameAddress(address)
      } else {
        const duplicateAddress = companyInfo?.address?.find((adr) => adr?.name && adr.name === address.name && adr.name !== oldName)
        if (duplicateAddress) {
          setDuplicatedNameAddress({ ...address, UUID: duplicateAddress.UUID })
        } else {
          handleSaveAddress(address)
        }
      }
    },
    [companyInfo]
  )

  const onNameExistsModalAnswer = useCallback(
    (answer: YesNo) => {
      if (answer === YesNo.YES && duplicatedNameAddress) {
        handleSaveAddress(duplicatedNameAddress)
      }
      setDuplicatedNameAddress(undefined)
    },
    [duplicatedNameAddress, handleSaveAddress]
  )

  const onDelete = useCallback(() => {
    if (!selectedAddressToEdit || !selectedAddressToEdit.UUID) return
    const { UUID, ...restAddress } = selectedAddressToEdit
    onMutateAddress(restAddress, AddressTypes.OTHER, AddressMutationOptionTypes.REMOVE, UUID)
  }, [onMutateAddress, selectedAddressToEdit])

  return (
    <div className={rootClass}>
      <AddressModal
        handleModalClose={handleModalClose}
        onDelete={onDelete}
        onSave={prepareSaveAddress}
        isOpen={isOpen}
        isDefault={isDefaultChecked}
        address={selectedAddressToEdit}
      />
      {!!duplicatedNameAddress && (
        <ConfirmationModal
          isYesNo
          isOpen={!!duplicatedNameAddress}
          title={t('Address.Name.Exist.Modal.Title', { duplicateName: duplicatedNameAddress?.name ?? '' })}
          yesButtonText={t('Replace')}
          noButtonText={t('Continue Editing')}
          body={t('Address.Name.Exist.Modal.Message')}
          onAnswer={onNameExistsModalAnswer}
          className={rootClass}
        />
      )}
    </div>
  )
}

export default WithLoadingAndError(AddressModalContainer)
