import React, { ReactNode } from 'react'
import { Row } from 'react-table'

import { TFunction } from 'i18next'

import {
  FilterCounts,
  ListingPageItem,
  ListPageAPI,
  ListPageCommonState,
  SetError,
  Update,
} from '@complex/ListingPage/Context/ListingPageCommon.context'
import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import { ExportToFtpFileModalProps } from '@components/ExportToFtpFileModal/ExportToFtpFileModal'
import { SelectAssetV2Props, SelectAssetV2RowItem } from '@components/SelectAssetV2/SelectAssetV2'
import { FileSelectWithSearchModalProps } from '@components/SelectFtpFileWithSearchModal/SelectFtpFileWithSearchModal'
import { Status } from '@components/StatusToast/StatusToast'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { rootContext } from '@const/globals'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { FormSubmissionSummaryDto } from '@graphql/types/microservice/entity-join-types'
import { ExportSyncJobDto, FtpFileDto } from '@graphql/types/microservice/entity-upload-types'
import { GetFormPreviewQuery, LabelDto } from '@graphql/types/query-types'
import { ALL_ACTON_CONTACTS_SEGMENT_NAME, EXPORT_ACTION } from '@src/pages/ContactSegments/utils/ContactSegments.constants'
import { WebinarType } from '@src/pages/listingPages/FormsJoinView/components/DetailsCardWrapper/utils/DetailsCardWrapper.utils'
import {
  formsJoinViewURLByItemType,
  getDetailsURL,
  ListRelatedForms,
  WebinarAttendanceAndStatusData,
} from '@src/pages/listingPages/FormsJoinView/FormsJoinViewListingPageContainer.constants'
import {
  DuplicateSegmentRequest,
  RelatedFormsRequest,
  WebinarAttendanceDataRequest,
  WebinarCompletedRequest,
  WebinarRelatedFormsRequest,
} from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.graphQL'
import {
  doExport,
  FormJoinViewSession,
  FormsJoinViewUpdate,
  onDuplicateSegmentResponse,
} from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.utils'
import { filterNotEmptyArray } from '@utils/array'
import { ItemType } from '@utils/categorization'
import { FtpExportExecutionStatus, Segment } from '@utils/contactSegments/contactSegments.utils'
import { formatDateWithAbbreviations } from '@utils/date'
import { recentFilter } from '@utils/filter'
import { logNewRelicError } from '@utils/new-relic.utils'
import { getItem, removeItem } from '@utils/sessionStorage'
import { FetchPromise } from '@utils/types'

export const getFtpExportSyncJobsList = (
  segment: Segment,
  update: FormsJoinViewUpdate,
  getExportSyncJobListRequest: (segmentId?: string) => Promise<ExportSyncJobDto[]>
) => {
  update({ loadingFtpExportSyncs: true })
  getExportSyncJobListRequest(segment.name !== ALL_ACTON_CONTACTS_SEGMENT_NAME ? segment.externalId : undefined).then((data) => {
    update({ ftpExportSyncJobs: data })
    if (data.length === 1) {
      window.open(`${rootContext}/exportContacts/${data[0].exportSyncJobId}/options`, '_self')
    }
    update({ loadingFtpExportSyncs: false, selectingFtpExports: true, selectingExportAction: false })
  })
}

const getExportActions = (
  update: FormsJoinViewUpdate,
  t: TFunction,
  segmentToExport: Segment,
  getExportSyncJobListRequest: (segmentId?: string) => Promise<ExportSyncJobDto[]>
): { [key in EXPORT_ACTION]: (segmentToExport: Segment) => void } => {
  return {
    [EXPORT_ACTION.CSV]: () => {
      if (segmentToExport) {
        doExport(segmentToExport, update, t)
        update({ selectingExportAction: false, segmentToExport: undefined })
      }
    },
    [EXPORT_ACTION.CSV_WITH_UPLOADED_FILES]: () => {
      if (segmentToExport) {
        doExport(segmentToExport, update, t, true)
        update({ selectingExportAction: false, segmentToExport: undefined })
      }
    },
    [EXPORT_ACTION.MANAGE_FTP]: () => segmentToExport && getFtpExportSyncJobsList(segmentToExport, update, getExportSyncJobListRequest),
    [EXPORT_ACTION.NEW_FTP]: () =>
      update({
        loadingFtpFiles: true,
        selectingFtpFile: true,
        selectingExportAction: false,
      }),
  }
}

export const getSelectExportActionProps = (
  update: FormsJoinViewUpdate,
  t: TFunction,
  segmentToExport: Segment | undefined,
  ftpExportSegmentsExecutionDetails: Record<string, FtpExportExecutionStatus>,
  getExportSyncJobListRequest: (segmentId?: string) => Promise<ExportSyncJobDto[]>,
  newFormFileUploadBlock?: boolean,
  isFTPConnectorActive?: boolean
): SelectAssetV2Props => {
  return {
    isOpen: true,
    headerText: 'SelectExportContactsActionModal.Header',
    subHeaderText: 'SelectExportContactsActionModal.SubHeader',
    primaryButtonText: 'Export',
    onCancel: () => update({ selectingExportAction: false }),
    onAction: (selectedItem) =>
      segmentToExport && getExportActions(update, t, segmentToExport, getExportSyncJobListRequest)[selectedItem as EXPORT_ACTION](segmentToExport),
    rowItems: [
      {
        title: t('SelectExportContactsActionModal.Csv.Title'),
        description: t('SelectExportContactsActionModal.Csv.Description'),
        name: EXPORT_ACTION.CSV,
      },
      ...(newFormFileUploadBlock
        ? [
            {
              title: t('SelectExportContactsActionModal.Csv.With.Files.Title'),
              description: t('SelectExportContactsActionModal.Csv.With.Files.Description'),
              name: EXPORT_ACTION.CSV_WITH_UPLOADED_FILES,
            },
          ]
        : []),
      ...(isFTPConnectorActive
        ? [
            {
              title: t('SelectExportContactsActionModal.ManageFtp.Title'),
              description: t('SelectExportContactsActionModal.ManageFtp.Description'),
              name: EXPORT_ACTION.MANAGE_FTP,
              hidden: !ftpExportSegmentsExecutionDetails[segmentToExport?.externalId ?? ''],
            },
          ]
        : []),
      ...(isFTPConnectorActive
        ? [
            {
              title: t('SelectExportContactsActionModal.NewFtp.Title'),
              description: t('SelectExportContactsActionModal.NewFtp.Description'),
              name: EXPORT_ACTION.NEW_FTP,
            },
          ]
        : []),
    ],
    initialSelectedAsset: EXPORT_ACTION.CSV,
  }
}

export const getSelectFtpExportFileProps = (
  update: FormsJoinViewUpdate,
  t: TFunction,
  segmentToExport: Segment | undefined,
  history: any,
  isWebinarSubmissions: boolean,
  rootClass: string,
  ftpFiles: FtpFileDto[],
  loadingFtpFiles: boolean
): FileSelectWithSearchModalProps => {
  return {
    isOpen: true,
    onClose: () => update({ selectingFtpFile: false }),
    onAction: (file) =>
      segmentToExport &&
      history.push(`${rootContext}/exportContacts`, {
        selectedFtpFile: file,
        exportSegment: segmentToExport,
        backUrl: `${rootContext}/content/${isWebinarSubmissions ? 'webinarSubmissions' : 'formSubmissions'}`,
      }),
    files: ftpFiles,
    loading: loadingFtpFiles,
    footerActionElement: (
      <Button
        className={`${rootClass}__action-button`}
        buttonType={ButtonType.INFO}
        iconPosition={ButtonIconPosition.LEFT}
        onClick={() => update({ creatingFtpFile: true, selectingFtpFile: false })}
      >
        <Svg name={SvgNames.downloadCloud} type={SvgType.ICON} />
        {t('Export to a new file')}
      </Button>
    ),
  }
}

export const customPreviewItemCall = async (
  selectedRows: ListingPageItem[],
  update: Update,
  setError: SetError,
  getFormPreview: (formId: string) => FetchPromise<GetFormPreviewQuery>
) => {
  const { data, errors } = await getFormPreview(selectedRows[0].externalId ?? '')
  if (data) {
    update({ previewHtml: data.getFormPreview })
  } else if (errors) {
    setError(JSON.stringify(errors), errors)
  }
}

export const getExportToFtpFileProps = (
  segmentToExport: Segment | undefined,
  update: FormsJoinViewUpdate,
  history: any
): ExportToFtpFileModalProps => {
  return {
    isOpen: true,
    defaultFileName: segmentToExport?.name,
    onClose: () => update({ creatingFtpFile: false }),
    onAction: (fileName) =>
      segmentToExport &&
      history.push(`${rootContext}/exportContacts`, {
        selectedFtpFile: { name: `${fileName}.csv`, urlPath: `/${fileName}.csv`, size: 0 },
        exportSegment: segmentToExport,
        backUrl: `${rootContext}/content/formSubmissions`,
      }),
    handleBack: () => update({ loadingFtpFiles: true, creatingFtpFile: false, selectingFtpFile: true }),
  }
}

export const getSelectFtpExportSyncProps = (
  update: FormsJoinViewUpdate,
  segmentToExport: Segment | undefined,
  history: any,
  loadingFtpExportSyncs: boolean,
  ftpExportSyncJobs: ExportSyncJobDto[]
): SelectAssetV2Props => {
  return {
    isOpen: true,
    headerText: 'SelectFtpExportSyncJobModal.Header',
    subHeaderText: 'SelectFtpExportSyncJobModal.SubHeader',
    onAction: (job) => segmentToExport && history.push(`${rootContext}/exportContacts/${job}/options`),
    loading: loadingFtpExportSyncs,
    onCancel: () => update({ selectingFtpExports: false }),
    rowItems: ftpExportSyncJobs.map((job): SelectAssetV2RowItem => {
      const executionDto = job.exportSyncJobExecutionDTOS?.filter(filterNotEmptyArray) ?? []
      return {
        name: job.exportSyncJobId.toString(),
        title: (job.filePath ?? '').substring(1),
        description: `Last export date: ${executionDto[0]?.executionEnd ? formatDateWithAbbreviations(executionDto[0].executionEnd, true) : '-'}`,
      }
    }),
    initialSelectedAsset: ftpExportSyncJobs[0]?.exportSyncJobId.toString() ?? '',
  }
}
export const onExport = async (
  segment: Segment,
  update: FormsJoinViewUpdate,
  t: TFunction,
  getFtpConnectionStatusRequest: () => Promise<boolean>,
  newFormFileUploadBlock?: boolean
) => {
  update({ loading: true })
  const connectorActive = await getFtpConnectionStatusRequest()
    .then((status) => status)
    .catch(() => update({ loading: false }))
  update({ loading: false })

  if (connectorActive) {
    update({ segmentToExport: segment, selectingExportAction: true, isFTPConnectorActive: true })
  } else if (newFormFileUploadBlock) {
    update({ segmentToExport: segment, selectingExportAction: true })
  } else {
    doExport(segment, update, t)
  }
}

export const onDuplicateSegmentUtils = async (
  newName: string,
  tags: LabelDto[],
  folderId: number | undefined,
  sourceId: string,
  listingPageValues: ListPageCommonState,
  listingPageAPI: ListPageAPI,
  t: TFunction,
  duplicateSegment: DuplicateSegmentRequest
) => {
  try {
    const { data, errors } = await duplicateSegment({
      newName,
      labels: tags,
      folderId: !folderId || isNaN(folderId) ? undefined : folderId,
      srcId: sourceId,
    })
    onDuplicateSegmentResponse(data, errors, newName, folderId, tags, listingPageValues, listingPageAPI, t)
  } catch (error) {
    logNewRelicError(error)
    listingPageAPI.update({
      statusToast: {
        statusMessage: t('ListPage.FormsJoinView.Duplicate.Fail'),
        status: Status.FAIL,
        showStatusToast: true,
      },
    })
  }
}

export const getCustomFilterCountsUtils = async (
  itemType: ItemType,
  getCountForRecentFormSubmissionsRequest: () => Promise<number | void>,
  getCountForRecentWebinarRegistrationsRequest: () => Promise<number | void>
) => {
  const newCounts: FilterCounts = {}

  const recentCount = await (itemType === ItemType.FORM_SUBMISSION
    ? getCountForRecentFormSubmissionsRequest()
    : getCountForRecentWebinarRegistrationsRequest())

  if (recentCount) {
    newCounts[recentFilter.name] = recentCount
  }

  return newCounts
}

export const onTableRowClickedUtils = (row: Row<ItemDto>, update: FormsJoinViewUpdate, history: any, itemType: ItemType) => {
  const currentURL = formsJoinViewURLByItemType[itemType]
  update({
    showDetails: true,
    activeSubmission: row.original as ItemDto,
    segmentToExport: row.original as Segment,
  })
  history.push(
    `${rootContext}/${getDetailsURL(currentURL)}?externalId=${row.original.externalId}&type=${itemType}&backTo=${encodeURIComponent(currentURL)}`
  )
}

export const getForms = (
  id: string,
  isWebinarSubmissions: boolean,
  activeSubmission: ItemDto,
  getRelatedForms: RelatedFormsRequest,
  getWebinarRelatedFormsRequest: WebinarRelatedFormsRequest
) => {
  if (isWebinarSubmissions) {
    if (activeSubmission && activeSubmission.subTypeDTO) {
      const webinarId = 'webinarId' in activeSubmission ? (activeSubmission.webinarId as string) : ''
      const webinarType = (activeSubmission.subTypeDTO[0]?.name as string).toUpperCase() as WebinarType
      if (webinarType) {
        return getWebinarRelatedFormsRequest(webinarId, webinarType)
      }
    }
    return getRelatedForms(id)
  }
}

export const getWebinarStatusAndData = (
  id: string,
  activeSubmission: ItemDto,
  update: FormsJoinViewUpdate,
  getWebinarCompletedRequest: WebinarCompletedRequest,
  getWebinarAttendanceDataRequest: WebinarAttendanceDataRequest,
  webinarAttendanceData: WebinarAttendanceAndStatusData
) => {
  const match = id.match(/-([^-\s]+)$/)
  const webinarId = match ? match[1] : ''
  const webinarType =
    activeSubmission.subTypeDTO && activeSubmission.subTypeDTO[0] && ((activeSubmission.subTypeDTO[0].name as string).toUpperCase() as WebinarType)
  if (webinarType && !activeSubmission.parentId && !(activeSubmission as Segment).parent) {
    Promise.all([getWebinarCompletedRequest(webinarId, webinarType), getWebinarAttendanceDataRequest(webinarId, webinarType)])
      .then(([completed, attendanceData]) => {
        update({
          webinarAttendanceData: {
            ...webinarAttendanceData,
            completed,
            attendanceData,
          },
        })
      })
      .catch(() => {
        update({ webinarAttendanceData: { ...webinarAttendanceData, error: true } })
      })
  }
}

export const getInitialStatusToast = (update: FormsJoinViewUpdate) => {
  const incomingStatusToast = getItem(FormJoinViewSession.INCOMING_STATUS_TOAST)
  if (incomingStatusToast != null) {
    const status = JSON.parse(incomingStatusToast) as {
      statusMessage: string | ReactNode
      status: Status
      showStatusToast: boolean
      folderName?: string
    }
    update({ statusToast: status })
    removeItem(FormJoinViewSession.INCOMING_STATUS_TOAST)
  }
}

export const getFormWithCrmPushErrors = (item: ItemDto, relatedForms: ListRelatedForms, formsWithCrmPushErrors: string[]) => {
  const forms = relatedForms[item.externalId as keyof ListRelatedForms] as FormSubmissionSummaryDto[]
  if (forms) {
    const formIds = forms.map((form) => form.formId)
    return formIds.find((formId) => formsWithCrmPushErrors?.includes(formId ?? ''))
  }
}
