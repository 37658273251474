import React, { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import { Status } from '@components/StatusToast/StatusToast'
import { rootContext, undefinedReturnedFunction, useTranslation } from '@const/globals'
import { SegmentMappingInput } from '@graphql/types/microservice/segment-types'
import { List } from '@interface/foldersLists/List'
import { COPY_SEGMENT_LOG_PARAM } from '@src/pages/ContactSegments/components/CopySegments/constants/CopySegments.constants'
import {
  ColumnAttributes,
  CopySegmentsContainerInitialState,
  CopySegmentsContainerState,
  CopySegmentsContext,
  MappedField,
  SegmentActions,
} from '@src/pages/ContactSegments/components/CopySegments/context/CopySegments.context'
import CopySegments from '@src/pages/ContactSegments/components/CopySegments/CopySegments'
import { useCopySegmentsListServiceRequests } from '@src/pages/ContactSegments/components/CopySegments/GraphQL/CopySegmentsRequests.list.graphQL'
import {
  GetCopySegmentLogRequestParams,
  useCopySegmentsSegmentServiceRequests,
} from '@src/pages/ContactSegments/components/CopySegments/GraphQL/CopySegmentsRequests.segment.graphQL'
import {
  getUnifiedListFieldMappingsUtils,
  loadUnifiedListFieldMappingsUtils,
} from '@src/pages/ContactSegments/components/CopySegments/GraphQL/utils/CopySegmentsRequests.list.utils'
import {
  checkForUnmappedSegmentFieldsUtils,
  deleteClassicSegmentsUtils,
  deleteSegmentByCopyIdUtils,
  getCopySegmentCountUtils,
  getCopySegmentLogUtils,
  getSegmentDefinitionFieldsUtils,
  getUnifiedListFieldsFromSourceUtils,
  migrateClassicSegmentsUtils,
  moveToTrashUtils,
} from '@src/pages/ContactSegments/components/CopySegments/GraphQL/utils/CopySegmentsRequests.segment.utils'
import { getSegmentParentRelationsUtils, updateMappedFieldUtils } from '@src/pages/ContactSegments/components/CopySegments/utils/CopySegments.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import { SegmentType } from '@utils/segments'

const CopySegmentsContainer: FC = () => {
  const [state, setState] = useState<CopySegmentsContainerState>({
    ...CopySegmentsContainerInitialState,
  })
  const {
    segmentsWithFilterExpressions,
    fieldNamesBySegment,
    segments,
    mappedFields,
    unifiedListFieldMappings,
    parentLocation,
    parentType,
    segmentAction,
    itemsToRemove,
  } = state
  const { hasCopySegments, userName, timeZoneId } = useAccountSettings()
  const { t } = useTranslation()
  const history = useHistory<{
    segmentsCopiedMessage?: { successCount: number; failedCount: number; inProgress: number }
  }>()
  const {
    checkForUnmappedSegmentFieldsRequest,
    deleteClassicSegmentsRequest,
    deleteSegmentByCopyIdRequest,
    getCopySegmentCountRequest,
    getCopySegmentLogRequest,
    getSegmentDefinitionFieldsRequest,
    getUnifiedListFieldsFromSourceRequest,
    migrateClassicSegmentsRequest,
    moveToTrashRequest,
  } = useCopySegmentsSegmentServiceRequests()
  const { getUnifiedListFieldMappingsRequest } = useCopySegmentsListServiceRequests()

  useEffect(() => {
    if (!hasCopySegments) {
      history.push(`${rootContext}/classic/dashboard`)
    }
  }, [hasCopySegments])

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search)
    if (queryParams.has(COPY_SEGMENT_LOG_PARAM)) {
      update({ showSegmentCopyLog: true })
    }
  }, [])

  useEffect(() => {
    if (unifiedListFieldMappings.length === 0) {
      loadUnifiedListFieldMappingsUtils({ getUnifiedListFieldMappingsRequest, t, update })
    }
  }, [])

  const update = (values: Partial<CopySegmentsContainerState>) => setState((state) => ({ ...state, ...values }))

  const removeSegment = (segment: List) =>
    setState(({ segments, lists, ...state }) => {
      const filteredSegments = segments.filter((s) => s.id !== segment.id)
      const segmentsBaseIds = filteredSegments.map(({ baseId }) => baseId)
      return { ...state, segments: filteredSegments, lists: lists.filter(({ id }) => segmentsBaseIds.includes(id)) }
    })

  const getUnmappedFieldSegments = () =>
    segments.filter(
      ({ id }) =>
        !segmentsWithFilterExpressions.find(({ segmentId }) => segmentId === id)?.value &&
        !fieldNamesBySegment[id]?.some((fieldName) => {
          const fieldFound = mappedFields.find(({ marketingListField }) => marketingListField === fieldName)
          return fieldFound !== undefined ? !!fieldFound.allContactsField : false
        })
    )

  const updateMappedField = (mappedField: MappedField) => updateMappedFieldUtils({ mappedField, setState })

  const performPostMigrationAction = async () => {
    const actions: { [action in SegmentActions]: () => Promise<void> } = {
      [SegmentActions.ARCHIVE]: async () => await moveToTrash(),
      [SegmentActions.DELETE]: async () => await deleteClassicSegments(),
      [SegmentActions.NOTHING]: async () => undefinedReturnedFunction(),
    }
    await actions[segmentAction]()
  }

  // GraphQL requests

  const checkForUnmappedSegmentFields = () =>
    checkForUnmappedSegmentFieldsUtils({ checkForUnmappedSegmentFieldsRequest, segmentIds: segments.map(({ id }) => id), t, update })

  const getCopySegmentCount = () => getCopySegmentCountUtils({ getCopySegmentCountRequest, t, update })

  const moveToTrash = async () => await moveToTrashUtils({ moveToTrashRequest, listIds: itemsToRemove, t, update })

  const undoCopySegments = (copyId: number, paginationParams: GetCopySegmentLogRequestParams) =>
    deleteSegmentByCopyIdUtils({ deleteSegmentByCopyIdRequest, copyId, t, update }).then((result) => {
      if (result) {
        getCopySegmentLog(paginationParams)
      }
    })

  const deleteClassicSegments = () => deleteClassicSegmentsUtils({ deleteClassicSegmentsRequest, segmentIds: itemsToRemove, t, update })

  const getSegmentDefinitionFields = () => {
    const segmentIds = segments.map(({ id }) => id)
    getSegmentDefinitionFieldsUtils({ getSegmentDefinitionFieldsRequest, segmentIds, t, update })
  }

  const getUnifiedListFieldMappings = () => getUnifiedListFieldMappingsUtils({ getUnifiedListFieldMappingsRequest, t, update })

  const getUnifiedListFieldsFromSource = () =>
    getUnifiedListFieldsFromSourceUtils({ getUnifiedListFieldsFromSourceRequest, sourceId: parentLocation?.id ?? '', t, update })

  const migrateClassicSegments = async () => {
    update({
      isMigrating: true,
      statusToast: {
        message: t('We’re copying your segments now.'),
        status: Status.SUCCESS,
        showStatusToast: true,
      },
    })

    const mappedFieldsInput = mappedFields.reduce(
      (map, { allContactsField, marketingListField }) => ({ ...map, [marketingListField]: allContactsField ?? '' }),
      {}
    )
    const columnAttributes: ColumnAttributes[] = mappedFields
      .filter(
        ({ isNew, allContactsField }) =>
          isNew ||
          (!isNew && parentType === SegmentType.SUBMISSION && unifiedListFieldMappings?.some((mapping) => mapping.displayName === allContactsField))
      )
      .map(({ columnId, dataType, dataFormat, allContactsField }) => ({
        columnId,
        dataFormat,
        dataType,
        displayName: allContactsField ?? '',
        standardName: allContactsField ?? '',
      }))
    const unmappedFieldsSegments = getUnmappedFieldSegments()
    const filteredSegments = segments.filter((segment) =>
      unmappedFieldsSegments.every((unmappedFieldsSegment) => unmappedFieldsSegment.id !== segment.id)
    )
    const segmentParentRelations = getSegmentParentRelationsUtils(filteredSegments)
    const segmentMapping: SegmentMappingInput = {
      parentId: parentLocation?.id,
      mappedFields: mappedFieldsInput,
      columnAttributes,
      segmentParentRelations,
    }

    const segmentCopyResponse = await migrateClassicSegmentsUtils({
      migrateClassicSegmentsRequest,
      segmentMapping,
      t,
      type: parentType,
      username: userName,
      update,
    })

    if (segmentCopyResponse !== undefined) {
      await performPostMigrationAction()

      update({ isMigrating: false })

      const { segmentCopyLogs = [] } = segmentCopyResponse
      if (segmentCopyLogs.length > 0 && segmentCopyLogs[0]) {
        const { success = [], fails = [], inProgress = [] } = segmentCopyLogs[0]
        history.push(`${rootContext}/segments`, {
          segmentsCopiedMessage: {
            successCount: success.length,
            failedCount: fails.length,
            inProgress: inProgress.length,
          },
        })
      }
    }
  }

  const getCopySegmentLog = (paginationParams: GetCopySegmentLogRequestParams) =>
    getCopySegmentLogUtils({ getCopySegmentLogRequest, t, timeZone: timeZoneId ?? '', update, ...paginationParams })

  return (
    <CopySegmentsContext.Provider
      value={{
        values: state,
        checkForUnmappedSegmentFields,
        getCopySegmentCount,
        getCopySegmentLog,
        getSegmentDefinitionFields,
        getUnifiedListFieldMappings,
        getUnifiedListFieldsFromSource,
        migrateClassicSegments,
        removeSegment,
        undoCopySegments,
        updateMappedField,
        update,
      }}
    >
      {hasCopySegments && <CopySegments />}
    </CopySegmentsContext.Provider>
  )
}

export default CopySegmentsContainer
