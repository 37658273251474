import { useApolloClient } from '@apollo/client'
import getContactFormActivities from '@graphql/microservices/entity-join/getContactFormActivities'
import * as getContactFormSubmissionData from '@graphql/microservices/entity-join/getContactSubmissionData'
import getWebinarContactRegistrationData from '@graphql/microservices/entity-join/getWebinarContactRegistrationData'
import getContactSubmissionData from '@graphql/microservices/segment/getContactSubmissionData'
import getFormSummaryData from '@graphql/queries/getFormSummaryData'
import listSchemaQuery from '@graphql/queries/listSchema'
import {
  GetContactFormActivitiesQuery,
  GetContactFormActivitiesQueryVariables,
  GetContactSubmissionDataQuery,
  GetContactSubmissionDataQueryVariables,
  GetWebinarContactRegistrationDataQuery,
  GetWebinarContactRegistrationDataQueryVariables,
} from '@graphql/types/microservice/entity-join-types'
import {
  GetContactSubmissionDataQuery as GetContactDataQuery,
  GetContactSubmissionDataQueryVariables as GetContactDataQueryVariables,
} from '@graphql/types/microservice/segment-types'
import {
  GetFormSummaryDataQuery,
  GetFormSummaryDataQueryVariables,
  ListColumnWithPreview,
  ListSchemaQuery,
  ListSchemaQueryVariables,
} from '@graphql/types/query-types'
import { WebinarType } from '@src/pages/listingPages/FormsJoinView/components/DetailsCardWrapper/utils/DetailsCardWrapper.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export interface DetailsCardWrapperRequests {
  getContactData: (listId: string, contactId: string, fields: string[]) => FetchPromise<GetContactSubmissionDataQuery>
  getContactFormsActivities: (contactId: string, type?: string) => FetchPromise<GetContactFormActivitiesQuery>
  getSubmissionDetails: (listId: string, uuid: string, email?: string) => FetchPromise<GetContactSubmissionDataQuery>
  getFormSummaryDetails: (formId: string) => FetchPromise<GetFormSummaryDataQuery>
  getWebinarContactRegistrationDetails: (
    webinarId: string,
    webinarType: WebinarType,
    uuid: string
  ) => FetchPromise<GetWebinarContactRegistrationDataQuery>
  getListSchemaRequest: (listId: string) => Promise<ListColumnWithPreview[]>
}

export const useDetailsCardWrapperRequests = (): DetailsCardWrapperRequests => {
  const { client: entityJoinClient } = useMicroserviceClient({ serviceName: MicroserviceClients.ENTITY_JOIN })
  const { client: segmentClient } = useMicroserviceClient({ serviceName: MicroserviceClients.SEGMENT })
  const actonClient = useApolloClient()

  const getContactData = (listId: string, contactId: string, fields: string[]) =>
    segmentClient.query<GetContactDataQuery, GetContactDataQueryVariables>({
      query: getContactSubmissionData,
      variables: {
        listId,
        contactId,
        fields,
      },
    })

  const getContactFormsActivities = (contactId: string, activityType?: string) =>
    entityJoinClient.query<GetContactFormActivitiesQuery, GetContactFormActivitiesQueryVariables>({
      query: getContactFormActivities,
      variables: {
        contactId,
        activityType,
      },
    })

  const getSubmissionDetails = (listId: string, uuid: string, email?: string) =>
    entityJoinClient.query<GetContactSubmissionDataQuery, GetContactSubmissionDataQueryVariables>({
      query: getContactFormSubmissionData.default,
      variables: {
        listId,
        uuid,
        email,
      },
    })

  const getFormSummaryDetails = (formId: string) =>
    actonClient.query<GetFormSummaryDataQuery, GetFormSummaryDataQueryVariables>({
      query: getFormSummaryData,
      fetchPolicy: 'network-only',
      variables: {
        formId,
      },
    })

  const getWebinarContactRegistrationDetails = (webinarId: string, webinarType: WebinarType, uuid: string) =>
    entityJoinClient.query<GetWebinarContactRegistrationDataQuery, GetWebinarContactRegistrationDataQueryVariables>({
      query: getWebinarContactRegistrationData,
      fetchPolicy: 'network-only',
      variables: {
        uuid,
        webinarId,
        webinarType,
      },
    })

  const getListSchemaRequest = (listId: string) => {
    return actonClient
      .query<ListSchemaQuery, ListSchemaQueryVariables>({
        query: listSchemaQuery,
        fetchPolicy: 'network-only',
        variables: {
          listIds: [listId],
        },
      })
      .then(({ data }) => data.listSchemas[0].schema?.headersWithPreviews as ListColumnWithPreview[])
  }

  return {
    getContactData,
    getContactFormsActivities,
    getSubmissionDetails,
    getFormSummaryDetails,
    getWebinarContactRegistrationDetails,
    getListSchemaRequest,
  }
}
