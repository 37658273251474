import React, { FC, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { Update } from '@complex/ListingPage/Context/ListingPageCommon.context'
import AutomatedProgramPickerModal from '@components/AssetPickers/AutomatedProgramPickerModal/AutomatedProgramPickerModal'
import { useTranslation } from '@const/globals'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { TemplateIdDescription } from '@graphql/types/mutation-types'
import { Program } from '@graphql/types/query-types'
import CreateProgramTemplateModal from '@src/pages/listingPages/AutomatedPrograms/components/CreateProgramTemplateModal/CreateProgramTemplateModal'
import { getAutomatedProgramTemplatesListingPageRequests } from '@src/pages/listingPages/AutomatedProgramTemplates/GraphQL/AutomatedProgramTemplatesListingPageRequests.graphQL'
import { AutomatedProgramTemplateCustomModals } from '@src/pages/listingPages/AutomatedProgramTemplates/utils/ListingPage.constants'
import { ProgramAssetType } from '@utils/program/program.constants'

interface CreateNewTemplateProps {
  update: Update
}

type ProgramWithExternalId = Program & {
  externalId: string
}

const CreateNewTemplate: FC<CreateNewTemplateProps> = (props: CreateNewTemplateProps) => {
  const { update } = props
  const [showPicker, setShowPicker] = useState(true)
  const [showCreate, setShowCreate] = useState(false)
  const [selectedProgram, setSelectedProgram] = useState<ProgramWithExternalId>()
  const apolloClient = useApolloClient()
  const { t } = useTranslation()

  const onSubmit = (selections: ItemDto[]) => {
    const program = selections[0] as ProgramWithExternalId
    setSelectedProgram(program)
    setShowPicker(false)
    setShowCreate(true)
  }

  if (showPicker) {
    return (
      <AutomatedProgramPickerModal
        isOpen
        primaryButtonText={t('Next')}
        onClose={() => update && update({ showCustomModal: false })}
        onSubmit={(items) => onSubmit(items as ItemDto[])}
        isSingleSelect
      />
    )
  } else if (showCreate && selectedProgram) {
    const lists = selectedProgram.sources.map((source) => ({
      id: source.id,
      title: source.name,
      type: ProgramAssetType.LIST,
    }))
    const messages = selectedProgram.messages.map((source) => ({
      id: source.id,
      title: source.name,
      type: ProgramAssetType.LIST,
    }))
    const assets = [...lists, ...messages]

    return (
      <CreateProgramTemplateModal
        isOpen
        title={selectedProgram.name ?? ''}
        assets={assets}
        onClose={() => update({ showCustomModal: false })}
        onAction={async (title: string, descriptionMapping: TemplateIdDescription[]) => {
          const { createTemplateRequest } = getAutomatedProgramTemplatesListingPageRequests(apolloClient)
          const { data, errors } = await createTemplateRequest(selectedProgram?.externalId, title, descriptionMapping)
          if (data?.createProgramTemplate) {
            update({
              showCustomModal: true,
              customTableAction: AutomatedProgramTemplateCustomModals.CREATE_TEMPLATE_SUCCESS,
              fetchItems: true,
            })
          } else if (errors) {
            update({
              showCustomModal: true,
              customTableAction: AutomatedProgramTemplateCustomModals.CREATE_TEMPLATE_ERROR,
            })
          }
        }}
      />
    )
  }

  return null
}

export default CreateNewTemplate
