import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import DateRangePicker, { DateRangeValueType } from '@components/DateRangePicker/DateRangePicker'
import Label from '@components/Label/Label'
import { TimePickerV2Format } from '@components/TimePickerV2/TimePickerV2'
import TimeRangePicker from '@components/TimeRangePicker/TimeRangePicker'
import TimeZoneSelectV2 from '@components/TimeZoneSelectV2/TimeZoneSelectV2'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { getHourAndMinuteFromTime } from '@src/pages/EmailComposer/utils/EmailComposer.utils'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { defaultTimezone, getDynamicTimezones } from '@utils/timezones'

dayjs.extend(utc)
dayjs.extend(timezone)

interface StaggeredSuffixProps {
  showValidationErrorsByDefault: boolean
  rootClass: string
  dataTest: string
}

const StaggeredSuffix: FC<StaggeredSuffixProps> = ({ showValidationErrorsByDefault, rootClass }) => {
  const {
    api: { update },
    values: {
      message: {
        sendMethod: { staggerdatestart = null, staggerdateend = null, staggertimestart, staggertimeend, staggertimezone },
      },
    },
  } = useContext(EmailComposerContext)
  const { t } = useTranslation()

  const staggerDefaultDate = useMemo<DateRangeValueType>(() => [staggerdatestart, staggerdateend], [staggerdatestart, staggerdateend])
  const staggerDefaultTime = useMemo<[string, string] | undefined>(
    () => (staggertimestart && staggertimeend ? [staggertimestart, staggertimeend] : undefined),
    [staggertimestart, staggertimeend]
  )

  const [showDateErrors, setShowDateErrors] = useState(showValidationErrorsByDefault)
  const [showTimeErrors, setShowTimeErrors] = useState(showValidationErrorsByDefault)

  const handleDateBlur = useCallback(() => setShowDateErrors(true), [])
  const handleDateFocus = useCallback(() => setShowDateErrors(false), [])
  const handleTimeBlur = useCallback(() => setShowTimeErrors(true), [])
  const handleTimeFocus = useCallback(() => setShowTimeErrors(false), [])

  const handleStaggeredDateRangeChange = useCallback(
    (dates: DateRangeValueType) => {
      if (dates) {
        const staggerdatestart = dates[0] || undefined
        const staggerdateend = dates[1] || undefined
        update({
          message: {
            sendMethod: { staggerdatestart, staggerdateend },
          },
        })
      } else {
        update({
          message: {
            sendMethod: { staggerdatestart: undefined, staggerdateend: undefined },
          },
        })
      }
    },
    [update]
  )

  const handleStaggeredTimeRangeChange = useCallback(
    (dates: [string, string]) => {
      update({
        message: {
          sendMethod: {
            staggertimestart: dates[0],
            staggertimeend: dates[1],
          },
        },
      })
    },
    [update]
  )

  const handleTimeZoneChange = useCallback(
    (value: string) => {
      update({ message: { sendMethod: { staggertimezone: value } } })
    },
    [update]
  )

  useEffect(() => {
    if (staggerdatestart || staggerdateend) {
      setShowDateErrors(true)
    }

    if (staggertimestart || staggertimeend) {
      setShowTimeErrors(true)
    }
  }, [staggerdatestart, staggerdateend, staggertimestart, staggertimeend])

  const { timeMissing, startTimeInPast, endTimeInPast, dateMissing, startDateInPast, endDateInPast, isTimeDiffLessThanHour } = useMemo(() => {
    const timeMissing = !staggertimestart || !staggertimeend
    if (!staggerdatestart || !staggerdatestart.isValid() || !staggerdateend || !staggerdateend.isValid()) {
      return { dateMissing: true, timeMissing }
    }
    const startDateInPast = staggerdatestart.endOf('day').valueOf() < dayjs().startOf('day').valueOf()
    const endDateInPast = staggerdateend.endOf('day').valueOf() < dayjs().startOf('day').valueOf()
    if (startDateInPast || endDateInPast) {
      return { startDateInPast, endDateInPast }
    }
    const { hour: hourStart, minute: minuteStart } = getHourAndMinuteFromTime(staggertimestart)
    const { hour: hourEnd, minute: minuteEnd } = getHourAndMinuteFromTime(staggertimeend)
    if (typeof hourStart !== 'number' || typeof minuteStart !== 'number' || typeof hourEnd !== 'number' || typeof minuteEnd !== 'number') {
      return { timeMissing: true }
    }

    const parsedTimeStart = dayjs(staggertimestart, TimePickerV2Format.HH_MM)
    let sendOnStart = staggerdatestart.hour(parsedTimeStart.hour()).minute(parsedTimeStart.minute())

    const parsedTimeEnd = dayjs(staggertimeend, TimePickerV2Format.HH_MM)
    let sendOnEnd = staggerdateend.hour(parsedTimeEnd.hour()).minute(parsedTimeEnd.minute())

    if (staggertimezone) {
      sendOnStart = sendOnStart.tz(staggertimezone, true)
      sendOnEnd = sendOnEnd.tz(staggertimezone, true)
    }

    if (!sendOnStart.isValid()) {
      return { timeMissing: true }
    }

    const startTimeInPast = sendOnStart.valueOf() < dayjs().valueOf()
    const endTimeInPast = sendOnEnd.valueOf() < dayjs().valueOf()

    const timeDifferenceInHours = parsedTimeEnd.diff(parsedTimeStart, 'hour', true)
    const isTimeDiffLessThanHour = timeDifferenceInHours < 1

    if (startTimeInPast || endTimeInPast || isTimeDiffLessThanHour) {
      return { startTimeInPast, endTimeInPast, isTimeDiffLessThanHour }
    }

    return {}
  }, [staggerdatestart, staggerdateend, staggertimestart, staggertimeend, staggertimezone])

  const timeRangeErrorMessage = useMemo(() => {
    if (!showTimeErrors) {
      return undefined
    }

    switch (true) {
      case timeMissing:
        return 'Time range is missing'

      case startTimeInPast && endTimeInPast:
        return 'Start and end time are in the past'

      case startTimeInPast && !endTimeInPast:
        return 'Start time is in the past'

      case isTimeDiffLessThanHour && !(startTimeInPast || endTimeInPast):
        return 'Daily send window must be at least 1 hour'

      default:
        return undefined
    }
  }, [endTimeInPast, isTimeDiffLessThanHour, showTimeErrors, startTimeInPast, timeMissing])

  const dateRangeErrorMessage = useMemo(() => {
    if (!showDateErrors) {
      return undefined
    }

    switch (true) {
      case dateMissing:
        return 'Date range is missing'

      case startDateInPast && endDateInPast:
        return 'Start and end dates are in the past'

      case startDateInPast && !endDateInPast:
        return 'Start date is in the past'

      default:
        return undefined
    }
  }, [dateMissing, endDateInPast, endTimeInPast, showDateErrors, startDateInPast, startTimeInPast])

  const getTimezoneOptions = useMemo(() => {
    return getDynamicTimezones()
  }, [])

  return (
    <div className={`${rootClass}__send-staggered-suffix`}>
      <div className={`${rootClass}__send-staggered-suffix-top`}>
        <Label required>
          <Typography text={t('Date range')} weight={TextWeight.MEDIUM} inline />
        </Label>
        <DateRangePicker
          onCalendarSubmit={handleStaggeredDateRangeChange}
          onlyFutureDates
          defaultRange={staggerDefaultDate}
          onFocus={handleDateFocus}
          onBlur={handleDateBlur}
          errorMessage={dateRangeErrorMessage}
          hideErrorIcon
        />
      </div>
      <div>
        <Label required>
          <Typography text={t('Daily send window')} weight={TextWeight.MEDIUM} inline />
        </Label>
        <TimeRangePicker
          defaultValue={staggerDefaultTime}
          onSubmit={handleStaggeredTimeRangeChange}
          onFocus={handleTimeFocus}
          onBlur={handleTimeBlur}
          errorMessage={timeRangeErrorMessage}
          hideErrorIcon
        />
      </div>
      <Label>
        <Typography text={t('Time zone')} weight={TextWeight.MEDIUM} inline />
      </Label>
      <TimeZoneSelectV2
        label={
          getTimezoneOptions.find((timezone) => {
            return timezone.value === staggertimezone
          })?.label || defaultTimezone.name
        }
        selectProps={{
          options: getTimezoneOptions,
          value: {
            label:
              getTimezoneOptions.find((timezone) => {
                return timezone.value === staggertimezone
              })?.label || defaultTimezone.name,
            value: staggertimezone || defaultTimezone.name,
          },
          onChange: (e) => {
            handleTimeZoneChange(e?.value || defaultTimezone.name)
          },
        }}
      />
    </div>
  )
}

export default StaggeredSuffix
