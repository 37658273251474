import { useApolloClient } from '@apollo/client'
import { PAGE_SIZE } from '@complex/ListPickerModalV2/utils/clients/ListPickerModalCategorizationClientUtils'
import { SearchState } from '@complex/ListPickerModalV2/utils/interfaces/ListPickerModalInterfaces'
import favoritesLists from '@graphql/queries/favoritesLists'
import folderLists from '@graphql/queries/folderLists'
import foldersWithCountQuery from '@graphql/queries/foldersWithCount'
import getFolderListsByType from '@graphql/queries/getFolderListsByType'
import listPoll from '@graphql/queries/listPoll'
import searchListsQuery from '@graphql/queries/searchLists'
import searchListsByIdsQuery from '@graphql/queries/searchListsByIds'
import {
  FavoritesListsQuery,
  FavoritesListsQueryVariables,
  FolderListsQuery,
  FolderListsQueryVariables,
  FoldersWithCountQuery,
  FoldersWithCountQueryVariables,
  GetFolderListsByTypeQuery,
  GetFolderListsByTypeQueryVariables,
  ListPollQuery,
  ListPollQueryVariables,
  SearchListsByIdsQuery,
  SearchListsByIdsQueryVariables,
  SearchListsQuery,
  SearchListsQueryVariables,
} from '@graphql/types/query-types'
import { Folder } from '@interface/Folder'
import { ListCategory } from '@utils/lists'
import { FetchPromise } from '@utils/types'

type GetFoldersWithCountRequestParams = { listType: ListCategory }
type GetFavoritesListsRequestParams = GetFoldersWithCountRequestParams
type GetFolderListsByTypeRequestParams = GetFoldersWithCountRequestParams & { pageNumber: number }
type GetFolderListsRequestParams = GetFoldersWithCountRequestParams & { folder?: Folder; listType: ListCategory }
type SearchLegacyListsByIdsRequestParams = { listsString: string }
type SearchListsRequestParams = { search?: SearchState }
type ListPollRequestParams = { segmentId: string }

export type GetFoldersWithCountRequestType = (params: GetFoldersWithCountRequestParams) => FetchPromise<FoldersWithCountQuery>
export type GetFavoritesListsRequestType = (params: GetFavoritesListsRequestParams) => FetchPromise<FavoritesListsQuery>
export type GetFolderListsByTypeRequestType = (params: GetFolderListsByTypeRequestParams) => FetchPromise<GetFolderListsByTypeQuery>
export type GetFolderListsRequestType = (params: GetFolderListsRequestParams) => FetchPromise<FolderListsQuery>
export type ListPollRequestType = (params: ListPollRequestParams) => FetchPromise<ListPollQuery>
export type SearchLegacyListsByIdsRequestType = (params: SearchLegacyListsByIdsRequestParams) => FetchPromise<SearchListsByIdsQuery>
export type SearchListsRequestType = (params: SearchListsRequestParams) => FetchPromise<SearchListsQuery>

interface UseListPickerModalClassicResponse {
  getFoldersWithCountRequest: GetFoldersWithCountRequestType
  getFavoritesListsRequest: GetFavoritesListsRequestType
  getFolderListsByTypeRequest: GetFolderListsByTypeRequestType
  getFolderListsRequest: GetFolderListsRequestType
  listPollRequest: ListPollRequestType
  searchLegacyListsByIdsRequest: SearchLegacyListsByIdsRequestType
  searchListsRequest: SearchListsRequestType
}

export const useListPickerModalClassicRequests = (): UseListPickerModalClassicResponse => {
  const { query } = useApolloClient()

  const getFoldersWithCountRequest = async ({ listType }: GetFoldersWithCountRequestParams) =>
    await query<FoldersWithCountQuery, FoldersWithCountQueryVariables>({
      query: foldersWithCountQuery,
      fetchPolicy: 'network-only',
      variables: {
        type: listType,
        noMirroredJoinLists: true,
      },
    })

  const getFavoritesListsRequest = async ({ listType }: GetFavoritesListsRequestParams) =>
    await query<FavoritesListsQuery, FavoritesListsQueryVariables>({
      query: favoritesLists,
      fetchPolicy: 'network-only',
      variables: {
        type: listType,
        noMirroredJoinLists: true,
      },
    })

  const getFolderListsByTypeRequest = async ({ listType, pageNumber }: GetFolderListsByTypeRequestParams) =>
    query<GetFolderListsByTypeQuery, GetFolderListsByTypeQueryVariables>({
      query: getFolderListsByType,
      fetchPolicy: 'network-only',
      variables: {
        type: listType,
        pageNumber,
        pageSize: PAGE_SIZE,
        noMirroredJoinLists: true,
      },
    })

  const getFolderListsRequest = async ({ folder, listType }: GetFolderListsRequestParams) =>
    await query<FolderListsQuery, FolderListsQueryVariables>({
      query: folderLists,
      fetchPolicy: 'network-only',
      variables: {
        type: listType,
        id: `${folder?.id}`,
        noMirroredJoinLists: true,
      },
    })

  const listPollRequest = async ({ segmentId }: ListPollRequestParams) =>
    await query<ListPollQuery, ListPollQueryVariables>({
      query: listPoll,
      fetchPolicy: 'network-only',
      variables: {
        id: segmentId,
      },
    })

  const searchLegacyListsByIdsRequest = async ({ listsString }: SearchLegacyListsByIdsRequestParams) =>
    await query<SearchListsByIdsQuery, SearchListsByIdsQueryVariables>({
      query: searchListsByIdsQuery,
      fetchPolicy: 'network-only',
      variables: {
        ids: listsString,
      },
    })

  const searchListsRequest = async ({ search }: SearchListsRequestParams) =>
    await query<SearchListsQuery, SearchListsQueryVariables>({
      query: searchListsQuery,
      fetchPolicy: 'network-only',
      variables: {
        query: search?.query ?? '',
        noMirroredJoinLists: true,
      },
    })

  return {
    getFoldersWithCountRequest,
    getFavoritesListsRequest,
    getFolderListsByTypeRequest,
    getFolderListsRequest,
    listPollRequest,
    searchLegacyListsByIdsRequest,
    searchListsRequest,
  }
}
