import React, { FC } from 'react'

import { useImagePickerModalContentContext } from '@components/AssetPickers/ImagePickerModal/context/ImagePickerModalContent.context'
import { ImagePickerSource } from '@components/AssetPickers/ImagePickerModal/utils/ImagePickerModal.utils'
import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { rootContext, useTranslation } from '@const/globals'
import { FilterTypes } from '@utils/filter'

const rootClass = 'image-picker-modal-content__empty-listing'

const ImagePickerModalEmptyListing: FC<{ dataTest?: string }> = ({ dataTest = rootClass }) => {
  const { t } = useTranslation()
  const { currentFolderAllItemsCount, searchTerm, filterState, onUploadImage } = useImagePickerModalContentContext()

  const { activeSource, activeFolder, activeFilter } = filterState

  const getProps = () => {
    if (!currentFolderAllItemsCount) {
      if (activeSource === ImagePickerSource.LOGOS) {
        return {
          imgSrc: StaticImageNames.logosEmpty,
          headline: t('AssetPicker.Image.Table.EmptyListing.Headline', { context: activeSource }),
          text: t('AssetPicker.Image.Table.EmptyListing.Text', { context: activeSource }),
          linkText: t('Go to Account Logos'),
          link: `${rootContext}/content/branding`,
          hideIcon: false,
        }
      } else if (typeof activeFolder === 'number') {
        return {
          imgSrc: StaticImageNames.emptyFolder,
          headline: t('This folder is empty'),
        }
      } else if (activeFilter?.name === FilterTypes.FAVORITES) {
        return {
          imgSrc: StaticImageNames.emptyFavorites,
          headline: t('You don’t have any favorites yet'),
          text: t('Add hearts to items that you want to easily find later.'),
        }
      } else {
        return {
          imgSrc: StaticImageNames.logosEmpty,
          headline: t('AssetPicker.Image.Table.EmptyListing.Headline', { context: activeSource }),
          text: t('AssetPicker.Image.Table.EmptyListing.Text', { context: activeSource }),
          buttonText: t('Upload an image'),
          buttonOnClick: onUploadImage,
        }
      }
    } else {
      return {
        imgSrc: StaticImageNames.emptySearch,
        headline: t('Empty.Listing.No.Results.Headline', { term: searchTerm }),
        text: 'Empty.Listing.No.Results.Text',
      }
    }
  }

  return <EmptyListing {...getProps()} size={EmptyListingSize.LARGE} withoutBorder fullHeight className={rootClass} dataTest={dataTest} />
}

export default ImagePickerModalEmptyListing
