import React, { FC, KeyboardEventHandler, MouseEventHandler, ReactNode, useCallback } from 'react'

import classNames from 'classnames'

import {
  advancedOptionsText,
  ColumnMergeType,
  contactPreferencesOptions,
  ContactStatus,
  FirstLineOption,
  MergeStrategy,
  Quote,
  UpsertContactSetting,
  Delimiter,
  ImportContactsRejectRules,
} from '@complex/ImportContacts/ImportContacts.constants'
import CaretIcon, { CaretIconDirection } from '@components/CaretIcon'
import Checkbox from '@components/Checkbox'
import CheckboxGroup from '@components/CheckboxGroup/CheckboxGroup'
import Container from '@components/Container'
import InfoTooltip, { InfoTooltipIconSize } from '@components/InfoTooltip/InfoTooltip'
import InputV2 from '@components/InputV2/InputV2'
import { LabelV2 } from '@components/LabelV2/LabelV2'
import MoreOptions from '@components/MoreOptions/MoreOptions'
import Pill from '@components/Pill/Pill'
import Radio from '@components/Radio'
import RadioGroup from '@components/RadioGroup'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { SvgNames, SvgType } from '@components/Svg'
import Typography, { LineHeight, TextType, TextWeight, TypographyProps } from '@components/Typography/Typography'
import { INPUT_DEBOUNCE_TIME, useTranslation } from '@const/globals'
import { useAccountSettings } from '@utils/account/account.utils'

import './AdvancedImportOptions.css'

interface AdvancedOptionSection {
  title: string
  content: ReactNode
  hidden?: boolean
  tooltip?: string
}

export type AdvancedImportOptionsProps = {
  previewHeadersOptions: SelectV2SingleOption[]
  showExternalIdMatchError?: boolean
  loadingMappingScreen?: boolean
  useCustomPrefix?: boolean
  removeEntriesFromList?: boolean
  mergeStrategy?: MergeStrategy
  fieldsSeparator: Delimiter
  fieldQuotes: Quote
  firstLineContains: FirstLineOption
  updateAction?: UpsertContactSetting
  mergeRule: ColumnMergeType
  externalIdMatch: SelectV2SingleOption | undefined | null
  contactPreference: SelectV2SingleOption
  segmentSelectedOption?: SelectV2SingleOption
  rejectRules?: ImportContactsRejectRules
  showObjectTypeError?: boolean
  objectType?: string
  showAdvancedOptions: boolean
  onUpdateActionChange?: (updateAction: UpsertContactSetting) => void
  onChangeWithFirstRowsUpdate: (field: string, value: FirstLineOption | Delimiter | Quote) => void
  onMergeRuleChange: (mergeRule: ColumnMergeType) => void
  onMergeStrategyChange?: (newMergeStrategy: MergeStrategy, newExternalIdMatch?: SelectV2SingleOption) => void
  onContactPreferenceChange: (option?: SelectV2SingleOption) => void
  onRemoveEntriesChange?: (removeEntriesFromList: boolean) => void
  onRejectRulesChange?: (rule: string, checked: boolean) => void
  onUseCustomPrefixChange?: (useCustomPrefix: boolean) => void
  onObjectTypeChange?: (objectType: string, error: boolean) => void
  onShowAdvancedOptionsChange: (showAdvancedOptions: boolean) => void
}
const rootClass = 'advanced-import-options'
const rootText = `ImportContactsV2.Sections.ImportOptions`

export const AdvancedImportOptions: FC<AdvancedImportOptionsProps> = (props: AdvancedImportOptionsProps) => {
  const {
    firstLineContains,
    contactPreference,
    fieldsSeparator,
    fieldQuotes,
    externalIdMatch,
    showExternalIdMatchError = false,
    mergeStrategy,
    previewHeadersOptions,
    updateAction,
    mergeRule,
    loadingMappingScreen,
    useCustomPrefix,
    segmentSelectedOption,
    removeEntriesFromList,
    rejectRules,
    showObjectTypeError = false,
    objectType,
    showAdvancedOptions,
    onChangeWithFirstRowsUpdate,
    onMergeRuleChange,
    onContactPreferenceChange,
    onUpdateActionChange,
    onMergeStrategyChange,
    onRemoveEntriesChange,
    onRejectRulesChange,
    onUseCustomPrefixChange,
    onObjectTypeChange,
    onShowAdvancedOptionsChange,
  } = props

  const { t } = useTranslation()

  const { canHideObjectTypeCustomPrefix, hasFTPMirrorImport } = useAccountSettings()

  const onHeaderClick = useCallback<MouseEventHandler>(() => {
    onShowAdvancedOptionsChange(!showAdvancedOptions)
  }, [showAdvancedOptions])

  const onHeaderKeyDown = useCallback<KeyboardEventHandler<HTMLDivElement>>(
    (event) => {
      if ((event.key = 'Enter')) {
        onShowAdvancedOptionsChange(!showAdvancedOptions)
      }
    },
    [showAdvancedOptions]
  )

  const getTypographyProps = (option: string, example: string): Partial<TypographyProps> => ({
    values: { option },
    tagProps: {
      light: { type: TextType.BODY_TEXT_LIGHT },
    },
    tagComponents: {
      Example: renderExamplePill(example),
    },
  })

  const renderExamplePill = (text: string): ReactNode => <Pill className={`${rootClass}__pill`} text={text} noMargin textWeight={TextWeight.BOLD} />

  const renderSection = ({ title, content, tooltip, hidden = false }: AdvancedOptionSection) => {
    if (!hidden) {
      return (
        <div className={`${rootClass}__advanced-section`} key={title}>
          <div className={`${rootClass}__advanced-section-header`}>
            <Typography className={`${rootClass}__advanced-section-header-title`} text={t(title)} weight={TextWeight.MEDIUM} />
            {tooltip && <InfoTooltip iconSize={InfoTooltipIconSize.MEDIUM}>{tooltip}</InfoTooltip>}
          </div>
          <div className={`${rootClass}__advanced-section-content`}>{content}</div>
        </div>
      )
    }
  }

  const renderMergeStrategyContent = () => {
    return mergeStrategy && onMergeStrategyChange ? (
      <RadioGroup verticalLayout>
        <Radio
          label={t(advancedOptionsText[MergeStrategy.Email])}
          checked={mergeStrategy === MergeStrategy.Email}
          onChange={() => onMergeStrategyChange(MergeStrategy.Email)}
        />
        <Radio
          label={t(advancedOptionsText[MergeStrategy.ExternalId])}
          checked={mergeStrategy === MergeStrategy.ExternalId}
          onChange={() => onMergeStrategyChange(MergeStrategy.ExternalId, externalIdMatch ?? previewHeadersOptions[0])}
        />
        {mergeStrategy === MergeStrategy.ExternalId ? (
          <div className={`${rootClass}__external-id-rule-container`}>
            <SelectV2
              className={classNames(`${rootClass}__select`, `${rootClass}__external-id-rule-select`)}
              options={previewHeadersOptions}
              value={externalIdMatch}
              onChange={(option) => onMergeStrategyChange(mergeStrategy, option)}
              isClearable={false}
              isDisabled={loadingMappingScreen}
              error={showExternalIdMatchError}
              errorMessage={t('Selecting a field is required when matching on External Id')}
            />
            <MoreOptions>
              <div className={`${rootClass}__external-id-rule-object-type-label`}>
                <LabelV2 className={`${rootClass}__external-id-rule-label`} label={'ObjectType'} withoutMargin />
                <InfoTooltip>{t('The object type defaults to “custom”. You can enter an appended value such as “custom.myobjecttype”.')}</InfoTooltip>
              </div>
              <div className={`${rootClass}__external-id-rule-object-type`}>
                {useCustomPrefix && <Typography text={'custom.'} lineHeight={LineHeight.LARGER} />}
                <div className={`${rootClass}__external-id-rule-object-type-input`}>
                  <InputV2
                    error={showObjectTypeError}
                    value={objectType}
                    inputInfo={{ errorText: t('Object Type is required when matching on External Id'), enabled: showObjectTypeError }}
                    placeholder={t('Enter object description')}
                    onChangeDebounce={INPUT_DEBOUNCE_TIME}
                    onChange={({ target: { value = '' } }) => {
                      onObjectTypeChange && onObjectTypeChange(value, !value)
                    }}
                    onBlur={({ target }) => {
                      if (!target.value && mergeStrategy === MergeStrategy.ExternalId) {
                        onObjectTypeChange && onObjectTypeChange('', true)
                      }
                    }}
                  />
                </div>
              </div>
              {canHideObjectTypeCustomPrefix && (
                <Checkbox
                  className={`${rootClass}__external-id-rule-checkbox`}
                  label={t('Do not append “custom” prefix to objectType uploads')}
                  checked={!useCustomPrefix}
                  onChange={(dontUseCustomPrefix) => {
                    onUseCustomPrefixChange && onUseCustomPrefixChange(dontUseCustomPrefix)
                  }}
                />
              )}
            </MoreOptions>
          </div>
        ) : (
          <></>
        )}
      </RadioGroup>
    ) : (
      <Radio label={t(advancedOptionsText[MergeStrategy.Email])} checked disabled />
    )
  }

  const sections: AdvancedOptionSection[] = [
    {
      title: `${rootText}.Fields.Title`,
      content: (
        <RadioGroup verticalLayout>
          <Radio
            className={rootClass}
            label={t(`${rootText}.Fields`)}
            labelTitle={t(advancedOptionsText[FirstLineOption.NAMES])}
            checked={firstLineContains === FirstLineOption.NAMES}
            onChange={() => onChangeWithFirstRowsUpdate('firstLineContains', FirstLineOption.NAMES)}
            labelTypography={{
              values: { option: t(advancedOptionsText[FirstLineOption.NAMES]) },
              tagProps: {
                light: { type: TextType.BODY_TEXT_LIGHT },
              },
              tagComponents: {
                FirstName: renderExamplePill('FirstName'),
                LastName: renderExamplePill('LastName'),
                State: renderExamplePill('State'),
              },
            }}
          />
          <Radio
            className={rootClass}
            label={t(`${rootText}.Fields`)}
            labelTitle={t(advancedOptionsText[FirstLineOption.VALUES])}
            checked={firstLineContains === FirstLineOption.VALUES}
            onChange={() => onChangeWithFirstRowsUpdate('firstLineContains', FirstLineOption.VALUES)}
            labelTypography={{
              values: { option: t(advancedOptionsText[FirstLineOption.VALUES]) },
              tagProps: {
                light: { type: TextType.BODY_TEXT_LIGHT },
              },
              tagComponents: {
                FirstName: renderExamplePill('John'),
                LastName: renderExamplePill('Doe'),
                State: renderExamplePill('CA'),
              },
            }}
          />
        </RadioGroup>
      ),
    },
    {
      title: `${rootText}.FieldsSeparator.Title`,
      content: (
        <RadioGroup verticalLayout>
          <Radio
            className={rootClass}
            label={t(`${rootText}.FieldsSeparator`)}
            labelTitle={t(advancedOptionsText[Delimiter.Comma])}
            checked={fieldsSeparator === Delimiter.Comma}
            onChange={() => onChangeWithFirstRowsUpdate('fieldsSeparator', Delimiter.Comma)}
            labelTypography={getTypographyProps(advancedOptionsText[Delimiter.Comma], ',')}
          />
          <Radio
            className={rootClass}
            label={t(`${rootText}.FieldsSeparator`)}
            labelTitle={t(advancedOptionsText[Delimiter.Tab])}
            checked={fieldsSeparator === Delimiter.Tab}
            onChange={() => onChangeWithFirstRowsUpdate('fieldsSeparator', Delimiter.Tab)}
            labelTypography={getTypographyProps(advancedOptionsText[Delimiter.Tab], 'Tab')}
          />
          <Radio
            className={rootClass}
            label={t(`${rootText}.FieldsSeparator`)}
            labelTitle={t(advancedOptionsText[Delimiter.SemiColon])}
            checked={fieldsSeparator === Delimiter.SemiColon}
            onChange={() => onChangeWithFirstRowsUpdate('fieldsSeparator', Delimiter.SemiColon)}
            labelTypography={getTypographyProps(advancedOptionsText[Delimiter.SemiColon], ';')}
          />
        </RadioGroup>
      ),
    },
    {
      title: `${rootText}.Quoted.Title`,
      content: (
        <RadioGroup verticalLayout>
          <Radio
            className={rootClass}
            label={t(`${rootText}.Quoted`)}
            labelTitle={t(advancedOptionsText[Quote.SimpleQuote])}
            checked={fieldQuotes === Quote.SimpleQuote}
            onChange={() => onChangeWithFirstRowsUpdate('fieldQuotes', Quote.SimpleQuote)}
            labelTypography={getTypographyProps(advancedOptionsText[Quote.SimpleQuote], "'")}
          />
          <Radio
            className={rootClass}
            label={t(`${rootText}.Quoted`)}
            labelTitle={t(advancedOptionsText[Quote.Quote])}
            checked={fieldQuotes === Quote.Quote}
            onChange={() => onChangeWithFirstRowsUpdate('fieldQuotes', Quote.Quote)}
            labelTypography={getTypographyProps(advancedOptionsText[Quote.Quote], '"')}
          />
        </RadioGroup>
      ),
    },
    {
      title: `${rootText}.Update.Title`,
      hidden: !updateAction,
      content: (
        <RadioGroup verticalLayout>
          <Radio
            className={rootClass}
            label={t(advancedOptionsText[UpsertContactSetting.OldestContact])}
            checked={updateAction === UpsertContactSetting.OldestContact}
            onChange={() => onUpdateActionChange && onUpdateActionChange(UpsertContactSetting.OldestContact)}
          />
          <Radio
            className={rootClass}
            label={t(advancedOptionsText[UpsertContactSetting.SkipExistingContact])}
            checked={updateAction === UpsertContactSetting.SkipExistingContact}
            onChange={() => onUpdateActionChange && onUpdateActionChange(UpsertContactSetting.SkipExistingContact)}
          />
          <Radio
            className={rootClass}
            label={t(advancedOptionsText[UpsertContactSetting.SkipNewContact])}
            checked={updateAction === UpsertContactSetting.SkipNewContact}
            onChange={() => onUpdateActionChange && onUpdateActionChange(UpsertContactSetting.SkipNewContact)}
          />
        </RadioGroup>
      ),
    },
    {
      title: `${rootText}.Merge.Title`,
      tooltip: t(`${rootText}.Merge.Tooltip`),
      content: (
        <RadioGroup verticalLayout>
          <Radio
            label={t(advancedOptionsText[ColumnMergeType.IfEmpty])}
            checked={mergeRule === ColumnMergeType.IfEmpty}
            onChange={() => onMergeRuleChange(ColumnMergeType.IfEmpty)}
          />
          <Radio
            label={t(advancedOptionsText[ColumnMergeType.Overwrite])}
            checked={mergeRule === ColumnMergeType.Overwrite}
            onChange={() => onMergeRuleChange(ColumnMergeType.Overwrite)}
          />
        </RadioGroup>
      ),
    },
    {
      title: `${rootText}.MatchRules.Title`,
      content: renderMergeStrategyContent(),
    },
    {
      title: `${rootText}.RejectRules.Title`,
      hidden: !rejectRules,
      content: (
        <CheckboxGroup verticalLayout>
          <Checkbox
            label={t(advancedOptionsText[ContactStatus.OptOut])}
            checked={rejectRules?.rejectOptOut}
            onChange={() => onRejectRulesChange && onRejectRulesChange('rejectOptOut', !rejectRules?.rejectOptOut)}
          />
          <Checkbox
            label={t(advancedOptionsText[ContactStatus.HardBounce])}
            checked={rejectRules?.rejectHardBounces}
            onChange={() => onRejectRulesChange && onRejectRulesChange('rejectHardBounces', !rejectRules?.rejectHardBounces)}
          />
          <Checkbox
            label={t(advancedOptionsText[ContactStatus.SoftBounce])}
            checked={rejectRules?.rejectSuppressedDomains}
            onChange={() => onRejectRulesChange && onRejectRulesChange('rejectSuppressedDomains', !rejectRules?.rejectSuppressedDomains)}
          />
        </CheckboxGroup>
      ),
    },
    {
      title: `Remove entries from list`,
      hidden: !hasFTPMirrorImport || removeEntriesFromList === undefined,
      content: (
        <Checkbox
          label={t(
            !segmentSelectedOption && mergeStrategy === MergeStrategy.ExternalId
              ? `Remove records from All Contacts list if not in import list`
              : `Remove records from the target segment if not in the import list.`
          )}
          checked={removeEntriesFromList}
          onChange={onRemoveEntriesChange}
          disabled={!segmentSelectedOption && mergeStrategy === MergeStrategy.Email}
        />
      ),
    },
    {
      title: `${rootText}.ContactPreference.Title`,
      tooltip: t(`${rootText}.ContactPreference.Tooltip`),
      content: (
        <SelectV2
          className={`${rootClass}__select`}
          placeholder={`${rootText}.ContactPreference.Select`}
          defaultValue={contactPreference}
          onChange={onContactPreferenceChange}
          inputIcon={SvgNames.userUnselected}
          options={contactPreferencesOptions}
          isClearable={false}
        />
      ),
    },
  ]

  return (
    <Container>
      <div className={`${rootClass}__advanced-header`} role={'button'} tabIndex={0} onKeyDown={onHeaderKeyDown} onClick={onHeaderClick}>
        <Typography text={t('Advanced options')} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.MEDIUM} lineHeight={LineHeight.MEDIUM_LARGE} />
        <CaretIcon direction={CaretIconDirection.RIGHT} toggle={showAdvancedOptions} isFilled={false} type={SvgType.ICON} />
      </div>
      <div className={classNames(`${rootClass}__advanced-content`, { [`${rootClass}__advanced-content-visible`]: showAdvancedOptions })}>
        {sections.map(renderSection)}
      </div>
    </Container>
  )
}
