import { gql } from '@apollo/client'

export default gql`
  query getStatSendableContacts($segmentId: Long, $externalId: String) {
    getStatSendableContacts(segmentId: $segmentId, externalId: $externalId) {
      date
      description
      externalId
      percentageValue
      value
      values {
        date
        description
        externalId
        percentageValue
        value
        values {
          date
          description
          externalId
          percentageValue
          value
        }
      }
    }
  }
`
