import { createContext } from 'react'

import { ListMaintenanceProgramsContextAPI, ListMaintenanceProgramsContextState } from '@utils/listingPage/listMaintenancePrograms.utils'

export const listMaintenanceProgramsContextInitialState: ListMaintenanceProgramsContextState = {
  campaigns: [],
  isEditing: false,
  programSourceFields: [],
  scoreSheets: [],
  stepsWithInvalidValue: [],
}

export const ListMaintenanceProgramsContext = createContext<ListMaintenanceProgramsContextAPI>({
  values: listMaintenanceProgramsContextInitialState,
} as any)
