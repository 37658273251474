enum SvgNames {
  abSplit = 'ab-split',
  activities = 'activities',
  actionUndoDisabled = 'action-undo-disabled',
  actionRedoDisabled = 'action-redo-disabled',
  actionUndoEnabled = 'action-undo-enabled',
  actionRedoEnabled = 'action-redo-enabled',
  actOnPush = 'act-on-push',
  addCircle = 'add-circle',
  addCloud = 'add-cloud',
  addContacts = 'add-contacts',
  addContactsNoFill = 'add-contacts-no-fill',
  addFolder = 'add-folder',
  addFolderGray = 'add-folder-gray',
  addFolderLightGray = 'add-folder-light-gray',
  addGroup = 'add-group',
  addLocation = 'add-location',
  addLinkedRow = 'add-linked-row',
  addRounded = 'add-rounded',
  addRoundedNoFill = 'add-rounded-no-fill',
  addRow = 'add-row',
  address = 'address',
  addressNoFill = 'address-no-fill',
  addSavedGroup = 'add-saved-group',
  afterHoursMoon = 'after-hours-moon',
  airplane = 'airplane',
  airPlaneFilled = 'airPlaneFilled',
  airPlaneFilledSelected = 'airplane-filled-selected',
  airplaneLanding = 'airplane-landing',
  airplaneLandingV2 = 'airplane-landingV2',
  airplaneOutline = 'airplane-outline',
  airplanePaper = 'airplane-paper',
  airplaneCloud = 'airplane-cloud',
  appendedList = 'appended-list',
  application = 'application',
  arrowDown = 'arrow-down-regular',
  arrowDownPlain = 'arrow-down',
  arrowEmpty = 'arrow-empty',
  arrowExpand = 'arrow-expand',
  arrowDiverge = 'arrow-diverge',
  arrowDivergeGreyLight = 'arrow-diverge-grey-light',
  arrowMapping = 'arrow-mapping',
  arrowMerge = 'arrow-merge',
  arrowRight = 'arrow-right',
  arrowRightGray = 'arrow-right-gray',
  arrowLeft = 'arrow-left',
  arrowShrink = 'arrow-shrink',
  arrowUp = 'arrow-up-regular',
  arrowUpPlain = 'arrow-up',
  arrowWithCircle = 'arrow-with-circle',
  assetLink = 'asset-link',
  assetLinkHover = 'asset-link-hover',
  atSign = 'at-sign',
  audienceCenter = 'audience-center',
  audienceCenterSelected = 'audience-center-selected',
  automation = 'automation',
  automationSelected = 'automation-selected',
  bellRing = 'bell-ring',
  bellOff = 'bell-off',
  bellOffRounded = 'bell-off-rounded',
  bellOffRoundedRed = 'bell-off-rounded-red',
  bellTime = 'bell-time',
  blankMessage = 'blank-message',
  block = 'block',
  branch = 'branch',
  buildings = 'buildings',
  calendar = 'calendar',
  calendarAdd = 'calendar-add',
  calendarAddNoFill = 'calendar-add-nofill',
  calendarSync = 'calendar-sync',
  calendlyLogo = 'calendly-logo',
  cancelCircle = 'cancel-circle',
  cancelSend = 'cancel-send',
  caretDown = 'caret-down',
  caretDownSmall = 'caret-down-small',
  caretRight = 'caret-right',
  caretLeft = 'caret-left',
  caretRigthDouble = 'caret-right-double',
  caretLeftDouble = 'caret-left-double',
  caretFillDown = 'caret-fill-down',
  caretFillLeft = 'caret-fill-left',
  caretFillRight = 'caret-fill-right',
  chevronDown = 'chevron-down',
  chevronLeft = 'chevron-left',
  chevronRight = 'chevron-right',
  chevronUp = 'chevron-up',
  caretFillUp = 'caret-fill-up',
  cautionYellow = 'caution-yellow',
  cautionError = 'caution-error',
  cellphoneOff = 'cellphone-off',
  cellphoneSolid = 'cellphone-solid',
  chartBar = 'chart-bar',
  chat = 'chat',
  chatSolid = 'chat-solid',
  checkboxEmpty = 'checkbox-empty',
  checkboxTeal = 'checkbox-teal',
  checkCircle = 'check-circle',
  checkCircleFull = 'check-circle-full',
  circleIndeterminate = 'circle-indeterminate',
  checkLight = 'check-light',
  check = 'check',
  checkIconTeal = 'check-icon-teal',
  checkThin = 'check-thin',
  checkSuccess = 'check-success',
  checkSuccessSolid = 'check-success-solid',
  checkTeal = 'check-teal',
  checkError = 'check-error',
  circle = 'circle',
  circleGreen = 'circle-green',
  clearCache = 'clear-cache',
  clearHistory = 'clear-history',
  clearIndicator = 'clear-indicator',
  clipboardCheck = 'clipboard-check',
  clipboardCheckColorless = 'clipboard-check-colorless',
  clipboardContent = 'clipboard-content',
  clock = 'clock',
  clockSelected = 'clock-selected',
  cloneSegment = 'clone-segment',
  close = 'close',
  cloud = 'cloud',
  cloudCheck = 'cloud-check',
  cloudOff = 'cloud-off',
  codeIcon = 'code-icon',
  computerSolid = 'computer-solid',
  cogRefresh = 'cog-refresh',
  collapseAll = 'collapse-all',
  collapseMenu = 'collapse-menu',
  columnOrder = 'column-order',
  connectionStatusRed = 'connection-status-red',
  connectionStatusGreen = 'connection-status-green',
  contactReport = 'contact-report',
  contactsSelected = 'contacts-selected',
  contacts = 'contacts',
  contactBook = 'contact-book',
  contentSelected = 'content-selected',
  content = 'content',
  copyStep = 'copy-step',
  copy = 'copy',
  createSubSegmentActive = 'create-subsegment-active',
  createSubsegment = 'create-subsegment',
  createSubsegmentNoFill = 'create-subsegment-no-fill',
  createWebinar = 'create-webinar',
  crm = 'crm',
  crmCloudLine = 'crm-cloud-line',
  crmCloudLineNoFill = 'crm-cloud-line-nofill',
  crmCloudSolid = 'crm-cloud-solid',
  csvFile = 'csv-file',
  curvedArrow = 'curved-arrow',
  customAccountFields = 'custom-account-fields',
  customAccountFieldsSelected = 'custom-account-fields-selected',
  customData = 'custom-data',
  customDataSolid = 'custom-data-solid',
  customLink = 'custom-link',
  customerCare = 'customer-care',
  databaseAdd = 'database-add',
  databaseClear = 'database-clear',
  datePickerArrow = 'date-picker-arrow',
  delete = 'delete',
  deleteAllContacts = 'delete-all-contacts',
  deleteRounded = 'delete-rounded',
  directSelect = 'direct-select',
  downArrow = 'down-arrow',
  download = 'download',
  downloadCloud = 'download-cloud',
  draft = 'draft',
  drag = 'drag',
  driveNetwork = 'drive-network',
  dropdownIndicator = 'dropdown-indicator',
  dynamicContentBlock = 'dynamic-content-block',
  dynamicContentBlockHover = 'dynamic-content-block-hover',
  dynamicsPush = 'dynamics-push',
  editClassic = 'edit-classic',
  editClassicNoFill = 'edit-classic-nofill',
  editContactsColumns = 'edit-contacts-columns',
  email = 'email',
  emailSolid = 'email-solid',
  emailSolidBlueBackground = 'email-solid-blue-background',
  emoji = 'emoji',
  error = 'error',
  errorAlert = 'error-alert',
  errorCircle = 'error-circle',
  errorHollow = 'error-hollow',
  errorSolid = 'error-solid',
  eventDetails = 'event-details',
  excelFile = 'excel-file',
  exitProgram = 'exit-program',
  exit = 'exit',
  expandAll = 'expand-all',
  export = 'export',
  extension = 'extension',
  externalLink = 'external-link',
  externalLinkSelected = 'external-link-selected',
  externalLinkUnselected = 'external-link-unselected',
  fail = 'fail',
  failure = 'failure',
  favoritesSelected = 'favorites-selected',
  favoritesUnselected = 'favorites-unselected',
  fax = 'fax',
  feedback = 'feedback',
  feedbackNew = 'feedback-new',
  fieldSearch = 'field-search',
  fifthMedal = '5thMedal',
  fileCSV = 'file-csv',
  fileLink = 'fileLink',
  fileLinkSelected = 'file-link-selected',
  fileUpload = 'file-upload',
  fileUploadGray = 'file-upload-gray',
  firstMedal = '1stMedal',
  floppyDisk = 'floppy-disk',
  folderClosed = 'folder-closed',
  folderOpened = 'folder-opened',
  folderNested = 'folder-nested',
  folderNestedGray = 'folder-nested-gray',
  folderLightGray = 'folder-light-gray',
  folderTeal = 'folder-teal',
  folder = 'folder',
  footerBlock = 'footer-block',
  footerBlockHover = 'footer-block-hover',
  form = 'form',
  formV2 = 'formV2',
  formLinkFilled = 'form-link-filled',
  formSimple = 'form-simple',
  formUnselected = 'form-unselected',
  formSimpleSelected = 'form-simple-selected',
  formSimpleSelectedWhite = 'form-simple-selected-white',
  formSimpleTextTeal = 'form-simple-text-teal',
  formSimpleOutline = 'form-simple-outline',
  formSubmitted = 'form-submitted',
  formViewed = 'form-viewed',
  fourthMedal = '4thMedal',
  collapse = 'collapse',
  createForm = 'create-form',
  createFormActive = 'create-form-active',
  createFormFromTemplate = 'create-form-from-template',
  createFormFromTemplateActive = 'create-form-from-template-active',
  createFormFromTemplateDisabled = 'create-form-from-template-disabled',
  createLandingPage = 'create-landing-page',
  createLandingPageActive = 'create-landing-page-active',
  createLandingPageFromTemplate = 'create-landing-page-from-template',
  createLandingPageFromTemplateActive = 'create-landing-page-from-template-active',
  createLandingPageFromTemplateDisabled = 'create-landing-page-from-template-disabled',
  formSubmission = 'formSubmission',
  gauge = 'gauge',
  gear = 'gear',
  generate = 'generate',
  globe = 'globe',
  goToStep = 'go-to-step',
  googleMeetLogo = 'google-meet-logo',
  goto = 'goto',
  graphBar = 'graph-bar',
  graphLine = 'graph-line',
  graphLineTeal = 'graph-line-teal',
  gridView = 'grid-view',
  groupAdd = 'group-add',
  groupRounded = 'group-rounded',
  groupSubtractedRounded = 'group-subtracted-rounded',
  hashtag = 'hashtag',
  headerBlock = 'header-block',
  headerBlockHover = 'header-block-hover',
  hide = 'hide',
  hideAlt = 'hide-alt',
  hideGreen = 'hide-green',
  hideDark = 'hide-dark',
  homeSelected = 'home-selected',
  home = 'home',
  hospitalSelected = 'hospital-selected',
  hospital = 'hospital',
  hourglass = 'hourglass',
  icon = 'icon',
  iconWhite = 'icon-white',
  image = 'image',
  imageBlock = 'image-block',
  imageBlockHover = 'image-block-hover',
  images = 'images',
  inbox = 'inbox',
  inboundSelected = 'inbound-selected',
  inbound = 'inbound',
  info = 'info',
  infoFilledWhite = 'info-filled-white',
  infoHover = 'info-hover-filled',
  infoTeal = 'info-teal',
  infoRest = 'info-rest',
  inputStatusActive = 'input-status-active',
  inputStatusInvalid = 'input-status-invalid',
  inputStatusInvalidNoFill = 'input-status-invalid-no-fill',
  inputStatusSuccess = 'input-status-success',
  jobTitle = 'job-title',
  jobTitleLight = 'job-title-light',
  jobTitleBlueBackground = 'job-title-blue-background',
  key = 'key',
  keyWhite = 'key-white',
  lastModified = 'last-modified',
  letterNoSend = 'letter-no-send',
  letter = 'letter',
  letterLight = 'letter-light',
  lightBulb = 'light-bulb',
  lightBulbIcon = 'light-bulb-icon',
  lightBulbIconEmpty = 'light-bulb-icon-empty',
  lightBulbNoBackground = 'light-bulb-no-background',
  lightBulbSelected = 'light-bulb-selected',
  lightBulbUnselected = 'light-bulb-unselected',
  linked = 'linked',
  linkedUnselected = 'linked-unselected',
  listCheck = 'list-check',
  listCheckNoFill = 'list-check-no-fill',
  listMaintenance = 'list-maintenance',
  lists = 'lists',
  listsSelected = 'lists-selected',
  listView = 'list-view',
  listsAndSegments = 'lists-and-segments',
  location = 'location',
  locationFill = 'location-fill',
  lock = 'lock',
  lockLight = 'lock-light',
  lockFilled = 'lock-filled',
  lockedMessage = 'locked-message',
  logo = 'logo',
  logoWhite = 'logo-white',
  logOutArrow = 'log-out-arrow',
  logOutSlimArrow = 'log-out-slim-arrow',
  magicWand = 'magic-wand',
  mailAdd = 'mail-add',
  mailbox = 'mailbox',
  mailboxLine = 'mailbox-line',
  mailboxLineDisabled = 'mailbox-line-disabled',
  mailboxSolid = 'mailbox-solid',
  marketingNetwork = 'marketing-network',
  marketingNetworkSelected = 'marketing-network-selected',
  marketingProspects = 'marketing-prospects',
  megaphone = 'megaphone',
  media = 'media',
  mediaLibrary = 'media-library',
  megaphoneFilled = 'megaphone-filled',
  megaphoneLine = 'megaphone-line',
  megaphoneLineNoFill = 'megaphone-line-no-fill',
  minus = 'minus',
  minusCircle = 'minus-circle',
  migrate = 'migrate',
  mopoNav = 'mopo-nav',
  moveFolder = 'move-folder',
  moveStep = 'move-step',
  msDynamicsLine = 'ms-dynamics-line',
  msDynamicsSolid = 'ms-dynamics-solid',
  name = 'name',
  netsuiteLine = 'netsuite-line',
  netsuitePush = 'netsuite-push',
  netsuiteSolid = 'netsuite-solid',
  linkedArrow = 'linked-arrow',
  newspaper = 'newspaper',
  noImage = 'noImage',
  nurture = 'nurture',
  office = 'office',
  officeNoFill = 'office-nofill',
  opportunityCrm = 'opportunity-crm',
  optOut = 'opt-out',
  optOutSelected = 'opt-out-selected',
  optOutUnselected = 'opt-out-unselected',
  optimize = 'optimize',
  organization = 'organization',
  outbound = 'outbound',
  outboundSelected = 'outbound-selected',
  outgoingWebhook = 'outgoing-webhook',
  overflowMenu = 'overflow-menu',
  page = 'page',
  pageVisits = 'page-visits',
  pagesInsert = 'pages-insert',
  paginationControlsBack = 'pagination-controls-back',
  paginationControlsEnd = 'pagination-controls-end',
  paginationControlsNext = 'pagination-controls-next',
  paginationControlsStart = 'pagination-controls-start',
  pass = 'pass',
  pause = 'pause',
  pauseCircle = 'pause-circle',
  pdfFile = 'pdf-file',
  pencil = 'pencil',
  personalization = 'personalization',
  percentage = 'percentage',
  phone = 'phone',
  phoneLight = 'phone-light',
  phoneNumber = 'phone-number',
  pinned = 'pinned',
  play = 'play',
  playNoFill = 'play-no-fill',
  plus = 'plus',
  plusLight = 'plus-light',
  previewOn = 'preview-on',
  previewOff = 'preview-off',
  print = 'print',
  question = 'question',
  questionActive = 'question-active',
  questionMark = 'question-mark',
  redo = 'redo',
  refresh = 'refresh',
  reload = 'reload',
  reloadNoFill = 'reload-no-fill',
  reset = 'reset',
  resetNoFill = 'reset-no-fill',
  removeAccess = 'remove-access',
  removeContact = 'remove-contact',
  removeFromFolder = 'remove-from-folder',
  reportsSelected = 'reports-selected',
  reports = 'reports',
  resourcesSelected = 'resources-selected',
  resources = 'resources',
  rocket = 'rocket',
  room = 'room',
  rss = 'rss',
  rssHover = 'rss-hover',
  rssFullContent = 'rss-full-content',
  rssSummaryContent = 'rss-summary-content',
  rssTitleOnly = 'rss-title-only',
  rssImageTop = 'rss-image-top',
  rssImageRight = 'rss-image-right',
  rssImageBottom = 'rss-image-bottom',
  rssImageLeft = 'rss-image-left',
  rssAlignmentLeft = 'rss-alignment-left',
  rssAlignmentMiddle = 'rss-alignment-middle',
  rssAlignmentRight = 'rss-alignment-right',
  salesforceLine = 'salesforce-line',
  salesforcePush = 'salesforce-push',
  salesforceSolid = 'salesforce-solid',
  salesUser = 'sales-user',
  scale = 'scale',
  score = 'score',
  search = 'search',
  searchActive = 'search-active',
  searchBlueBackground = 'search-blue-background',
  secondMedal = '2ndMedal',
  segment = 'segment',
  segmentFilters = 'segment-filters',
  segmentFiltersUnselected = 'segment-filters-unselected',
  segmentLogos = 'segment-logos',
  segmentsSelected = 'segments-selected',
  segmentsUnselected = 'segments-unselected',
  sendLine = 'send-line',
  sendSms = 'send-sms',
  sendSolid = 'send-solid',
  sendTime = 'send-time',
  settingsThreeLinesSelected = 'settings-three-lines-selected',
  settingsThreeLines = 'settings-three-lines',
  settingsTwoLines = 'settings-two-lines',
  settingsTwoLinesNoFill = 'settings-two-lines-nofill',
  share = 'share',
  shareToCatalog = 'share-to-catalog',
  shieldCheck = 'shield-check',
  shieldCheckSolid = 'shield-check-solid',
  shieldKeyLock = 'shield-key-lock',
  show = 'show',
  showGreen = 'show-green',
  showDark = 'show-dark',
  signUp = 'sign-up',
  signUpNoFill = 'sign-up-nofill',
  signUpForm = 'sign-up-form',
  smsSelected = 'sms-selected',
  sms = 'sms',
  start = 'start',
  steps = 'steps',
  spamCheckRest = 'spam-check-rest',
  spamCheckSuccess = 'spam-check-success',
  spamCheckWarning = 'spam-check-warning',
  spamCheckFail = 'spam-check-fail',
  spamCheckSuccessNoFill = 'spam-check-success-no-fill',
  spamCheckWarningNoFill = 'spam-check-warning-no-fill',
  spamCheckFailNoFill = 'spam-check-fail-no-fill',
  spinner = 'spinner',
  success = 'success',
  successNoFill = 'success-no-fill',
  sugarLine = 'sugar-line',
  sugarPush = 'sugar-push',
  sugarSolid = 'sugar-solid',
  summaryReportIcon = 'summary-report-icon',
  survey = 'survey',
  system = 'system',
  template = 'template',
  test = 'test',
  threeUsers = 'three-users',
  threeUsersSelected = 'three-users-selected',
  thumbsUpSelected = 'thumbs-up-selected',
  thumbsUpUnselected = 'thumbs-up-unselected',
  time = 'time',
  timeEstimate = 'time-estimate',
  timezone = 'timezone',
  tag = 'tag',
  tagOutline = 'tag-outline',
  tap = 'tap',
  target = 'target',
  tealSpinner = 'teal-spinner',
  thirdMedal = '3rdMedal',
  toggle = 'toggle',
  toggleOn = 'toggle-on',
  toggleOff = 'toggle-off',
  toggles = 'toggles',
  togglesOutline = 'toggles-outline',
  textAlerts = 'text-alerts',
  turnIntoGroup = 'turn-into-group',
  twoUsers = 'two-users',
  twoUsersNoFill = 'two-users-no-fill',
  twoWayArrow = 'two-way-arrow',
  undo = 'undo',
  ungroup = 'ungroup',
  union = 'union',
  unlinked = 'unlinked',
  unlock = 'unlock',
  unpinned = 'unpinned',
  unpinnedDisabled = 'unpinned-disabled',
  upArrow = 'up-arrow',
  upload = 'upload',
  uploadCloud = 'upload-cloud',
  uploadCloud2 = 'upload-cloud-2',
  uploaded = 'uploaded',
  url = 'url',
  userAnonymous = 'user-anonymous',
  userChecked = 'user-checked',
  userRounded = 'user-rounded',
  userRoundedNoFill = 'user-rounded-no-fill',
  userCircle = 'user-circle',
  userSubtractedRounded = 'user-subtracted-rounded',
  viewProgram = 'view-program',
  userAddFilled = 'user-add-filled',
  userList = 'user-list',
  userOff = 'user-off',
  userOffSelected = 'user-off-selected',
  userSelected = 'user-selected',
  usersSelected = 'users-selected',
  usersSelectedFull = 'users-selected-full',
  userUnselected = 'user-unselected',
  usersUnselectedFull = 'users-unselected-full',
  usersUnselected = 'users-unselected',
  warning = 'warning',
  warningShield = 'warning-shield',
  warningSolid = 'warning-solid',
  warningSolidNoFill = 'warning-solid-no-fill',
  warningCircle = 'warning-circle',
  webexLogo = 'webex-logo',
  webhooks = 'webhooks',
  webhook = 'webhook',
  webinar = 'webinar',
  webinarAttended = 'webinar-attended',
  webinarRegistered = 'webinar-registered',
  webinarOutline = 'webinar-outline',
  world = 'world',
  zendeskLine = 'zendesk-line',
  zendeskPush = 'zendesk-push',
  zendeskSolid = 'zendesk-solid',
  zoom = 'zoom',
  zoomLogo = 'zoom-logo',
  labelIcon = 'label-icon',
  folderOutlined = 'folder-outlined',
  envelopeCircle = 'envelope-circle',
  humansCircle = 'humans-circle',
  sendCircle = 'send-circle',
  imageLeftCurve = 'image-left-curve',
}

export type SvgNamesStrings = keyof typeof SvgNames

export default SvgNames
