import { gql } from '@apollo/client'

export default gql`
  mutation copyProgramMessage($programId: String, $messageId: String) {
    copyProgramMessage(programId: $programId, messageId: $messageId) {
      id
      title
      isBeeComposer
    }
  }
`
