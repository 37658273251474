import React, { ChangeEvent, FC, FocusEvent, ForwardedRef, ReactNode, forwardRef } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'

import classNames from 'classnames'

import caretDownSVG from '@assets/inline/caret-down.svg'
import Label from '@components/Label/Label'
import { getUUID } from '@const/globals'

import './select.css'

export enum SelectSize {
  REGULAR = 'REGULAR',
  SMALL = 'SMALL',
}

export interface SelectProps {
  value?: string
  defaultValue?: string
  children?: ReactNode
  id?: string
  disabled?: boolean
  name?: string
  label?: string | ReactNode
  register?: UseFormRegisterReturn<string>
  ref?: ForwardedRef<HTMLSelectElement>
  size?: SelectSize
  onChange?(event: ChangeEvent<HTMLSelectElement>): void
  onFocus?(event: FocusEvent<HTMLSelectElement>): void
  className?: string
  dataTest?: string
}

const rootClass = 'select'

/**
 * @deprecated use <SelectV2 instead
 */
const Select: FC<SelectProps> = forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  const { id = getUUID(), label, size = SelectSize.REGULAR, className, register, dataTest = 'select', ...rest } = props

  return (
    <>
      {label && <Label htmlFor={id}>{label}</Label>}
      <select
        ref={ref}
        id={id}
        className={classNames(rootClass, className, {
          [`${rootClass}__small`]: size === SelectSize.SMALL,
        })}
        style={{ backgroundImage: `url(${caretDownSVG})` }}
        data-test={dataTest}
        {...rest}
        {...register}
      >
        {props.children}
      </select>
    </>
  )
})

export default Select
