import React, { FC } from 'react'

import { AlignValue, OptionsStackingValue, VerticalAlignValue } from 'highcharts'

import Chart from '@components/Chart/Chart'
import { ChartProps, topLegendProps } from '@const/Chart.constants'

export interface Average {
  legend: string
  label: string
  percentage: number
}

interface Props extends ChartProps {
  average?: Average
}

const rootClass = 'progress-chart'

const ProgressChart: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '', title = '', subtitle = '', fields, average } = props
  const total = fields.reduce((sum, column) => sum + (column.data as number), 0)

  const generateAverage = (average: Average) => {
    const y = total * (average.percentage / 100)
    return {
      name: average.legend,
      type: 'line',
      data: [
        { x: -20, y, percentage: average.percentage, dataLabels: { enabled: false } },
        { x: 35, y, percentage: average.percentage },
      ],
    }
  }

  const chartColumnData: Array<any> = fields
    .map((field) => ({
      name: field.label,
      data: [field.data],
      color: field.color,
    }))
    .reverse()

  const columnDataCombined = average ? [generateAverage(average)].concat(chartColumnData) : chartColumnData

  const options = {
    tooltip: {
      enabled: false,
    },
    legend: {
      ...topLegendProps,
      x: 0,
      reversed: true,
    },
    subtitle: subtitle,
    xAxis: {
      gridLineColor: '#FFFFFF',
      min: average ? -10 : undefined,
      max: average ? 42 : undefined,
      labels: {
        enabled: false,
      },

      visible: false,
    },
    yAxis: {
      gridLineWidth: 0,
      labels: {
        enabled: false,
      },
      min: 0,
      max: total,
      endOnTick: false,
      title: {
        text: null,
      },
    },
    plotOptions: {
      bar: {
        stacking: 'normal' as OptionsStackingValue,
        pointWidth: 36,
        borderRadius: 4,
        enableMouseTracking: false,
        dataLabels: [
          {
            enabled: true,
            useHTML: true,
            formatter: function (this: any): string {
              return `
                <div style="padding: 0 6px">
                  ${Math.round(this.percentage || 0)}%
                </div>
              `
            },
            style: {
              fontFamily: 'Open Sans, Helvetica, Arial, sans-serif',
              textOutline: 'none',
              color: '#444444',
              fontSize: '12px',
              fontWeight: 'bold',
            },
          },
          {
            enabled: true,
            formatter: function (this: any): string {
              return `<tspan y='8'>${this.y.toLocaleString()}</tspan>`
            },
            verticalAlign: 'top' as VerticalAlignValue,
            align: 'center' as AlignValue,
            y: -20,
            style: {
              fontFamily: 'Open Sans, Helvetica, Arial, sans-serif',
              textOutline: 'none',
              color: '#444444',
              fontSize: '12px',
            },
          },
        ],
      },
      line: {
        lineWidth: 1,
        zIndex: 5,
        color: '#304A71',
        enableMouseTracking: false,
        marker: {
          enabled: false,
        },
        dataLabels: {
          enabled: true,
          align: 'center' as AlignValue,
          verticalAlign: 'below' as VerticalAlignValue,
          formatter: function (this: any): string {
            return `${average?.label || 'Average'} <b>${this.percentage ? Math.round(this.percentage * 10) / 10 : 0}%</b>`
          },
          style: {
            fontFamily: 'Open Sans, Helvetica, Arial, sans-serif',
            color: '#193661',
            textOutline: 'none',
            fontSize: '12px',
            fontWeight: 'normal',
          },
        },
      },
    },
  }

  return (
    <Chart
      series={columnDataCombined}
      chartType="bar"
      chartHeight={213}
      title={title}
      subtitle={options.subtitle}
      xAxis={options.xAxis}
      yAxis={options.yAxis}
      legend={options.legend}
      tooltip={options.tooltip}
      plotOptions={options.plotOptions}
      dataTest={dataTest}
      className={className}
    />
  )
}

export default ProgressChart
