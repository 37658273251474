import { gql } from '@apollo/client'

export default gql`
  mutation editPermissions($permissions: UserPermissionsInput, $id: String!, $launchApproval: String) {
    editPermissions(permissions: $permissions, id: $id, launchApproval: $launchApproval) {
      status
      message
      count
      deletedUsersIds
      newUsersIds
      importedUsers
      noImportedUsers
      duplicatedUsers
    }
  }
`
