import React, { FC, RefObject, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useApolloClient, useQuery } from '@apollo/client'
import EmptyListing from '@components/EmptyListing/EmptyListing'
import ListPageScrollArea from '@components/ListPageScrollArea/ListPageScrollArea'
import Loader from '@components/Loader'
import PageContainer from '@components/PageContainer/PageContainer'
import PageHeader from '@components/PageHeader'
import { PageHeaderType } from '@components/PageHeadline/PageHeadline'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import StatusToast from '@components/StatusToast/StatusToast'
import Tabs, { TabItemData } from '@components/TabsAO/TabsAO'
import { rootContext, useTranslation } from '@const/globals'
import createApiClientCredentials from '@graphql/mutations/createApiClientCredentials'
import getCustomAccountSettingsPage from '@graphql/queries/getCustomAccountSettingsPage'
import { CreateApiClientCredentialsMutation, CreateApiClientCredentialsMutationVariables } from '@graphql/types/mutation-types'
import { CustomAccountSettings, GetCustomAccountSettingsPageQuery, GetCustomAccountSettingsPageQueryVariables } from '@graphql/types/query-types'
import { StatusToastType } from '@interface/StatusToast'
import {
  BROKEN_INPUT_URL_KEY,
  UNPUBLISHED_INPUT_URL_KEY,
} from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/AccountRedirects/constants/accountRedirects.constants'
import APIAccessKeys from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/APIAccessKeys/APIAccessKeys'
import CompanyContainer from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Company/CompanyContainer'
import ContentPersonalization from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/ContentPersonalization/ContentPersonalization'
import FormDomains from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/FormDomains/FormDomains'
import Labs from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Labs/Labs'
import SecurityContainer from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Security/SecurityContainer'
import { logNewRelicError } from '@utils/new-relic.utils'
import { removeItem } from '@utils/sessionStorage'

import AccountRedirects, { AccountRedirectsInitialValues } from './components/AccountRedirects/AccountRedirects'

const convertURLToTab = (url: string) => {
  const curr = url.split('/').pop()

  switch (curr) {
    case 'customAccountSettings':
    case 'account-redirects':
      return 'AccountRedirects'
    case 'form-domains':
      return 'FormDomains'
    case 'api-access-keys':
      return 'APIAccessKeys'
    case 'company':
      return 'Company'
    case 'content-personalizations':
      return 'ContentPersonalizations'
    case 'security-policies':
      return 'Security'
    case 'labs':
      return 'Labs'
    default:
      return 'AccountRedirects'
  }
}

const convertTabToURL = (tab: string) => {
  switch (tab) {
    case 'AccountRedirects':
      return 'account-redirects'
    case 'FormDomains':
      return 'form-domains'
    case 'APIAccessKeys':
      return 'api-access-keys'
    case 'Company':
      return 'company'
    case 'ContentPersonalizations':
      return 'content-personalizations'
    case 'Security':
      return 'security-policies'
    case 'Labs':
      return 'labs'
    default:
      return 'account-redirects'
  }
}

const rootClass = 'custom-account-settings'

const CustomAccountSettingsContainer: FC = () => {
  const history = useHistory()
  const url = history.location.pathname

  const [currentTab, setCurrentTab] = useState<string>(() => convertURLToTab(url) ?? 'account-redirects')
  const [tabContentData, setTabContentData] = useState<Partial<CustomAccountSettings>>()
  const [toast, setToastStatus] = useState<StatusToastType>()
  const [createApiCredentialsLoading, setCreateApiCredentialsLoading] = useState<boolean>(false)
  const [accountRedirectInitialData, setAccountRedirectInitialData] = useState<AccountRedirectsInitialValues>({
    brokenInitialInputValue: undefined,
    brokenIsEditing: false,
    brokenFocused: true,
    unpublishedInitialInputValue: undefined,
    unpublishedIsEditing: false,
    unpublishedFocused: true,
  })

  const { t } = useTranslation()

  const client = useApolloClient()
  const { data, loading, error, refetch } = useQuery<GetCustomAccountSettingsPageQuery, GetCustomAccountSettingsPageQueryVariables>(
    getCustomAccountSettingsPage,
    {
      client,
      fetchPolicy: 'network-only',
    }
  )

  const createApiClientCredentialsRequests = async (variables: CreateApiClientCredentialsMutationVariables) => {
    return await client.mutate<CreateApiClientCredentialsMutation, CreateApiClientCredentialsMutationVariables>({
      mutation: createApiClientCredentials,
      errorPolicy: 'all',
      variables,
    })
  }

  const createApiCredentials = async () => {
    setCreateApiCredentialsLoading(true)
    const { data, errors } = await createApiClientCredentialsRequests({ force: true })

    if (data && data?.createApiClientCredentials.statusCode === 200) {
      refetch()
      setToastStatus({
        showStatus: true,
        statusMessage: t('API credentials created'),
        successStatus: true,
      })
    } else {
      setCreateApiCredentialsLoading(false)
      setToastStatus({
        showStatus: true,
        statusMessage: t('API credentials already exist'),
        successStatus: false,
      })
      logNewRelicError(errors)
    }
  }

  const invalidApiKeys = (settings: GetCustomAccountSettingsPageQuery['getCustomAccountSettingsPage']) => {
    return (
      !settings.clientID ||
      !settings.clientSecret ||
      // These are Event-Triggered email keys and are not valid API keys
      // https://developer.act-on.com/hc/en-us/articles/8363454018711-Request-API-Credentials
      settings.clientID === 'Flk9CWWjIi8b01V5plU8bJiVyk0a' ||
      settings.clientSecret === 'SMHPzbrgK8kDxd_GfSRvG6PCxn4a'
    )
  }

  useEffect(() => {
    if (!loading && data?.getCustomAccountSettingsPage) {
      const tabData = !invalidApiKeys(data?.getCustomAccountSettingsPage)
        ? data?.getCustomAccountSettingsPage
        : { ...data?.getCustomAccountSettingsPage, clientID: undefined, clientSecret: undefined }
      setTabContentData(tabData)
      setCreateApiCredentialsLoading(false)
    }
    if (error) {
      logNewRelicError(error)
    }
  }, [loading, error, data])

  useEffect(() => {
    return () => {
      removeItem(UNPUBLISHED_INPUT_URL_KEY)
      removeItem(BROKEN_INPUT_URL_KEY)
    }
  }, [])

  useEffect(() => {
    const newUrlTab = convertURLToTab(history.location.pathname)
    if (newUrlTab !== currentTab) {
      setCurrentTab(newUrlTab)
    }
  }, [history.location.pathname])

  const setTabsData = (data: any, scrollAreaRef: RefObject<HTMLDivElement>): TabItemData[] => {
    const { brokenClickthroughPersonalization, unpublishedLandingPage, clientID, clientSecret } = data
    const tabs: TabItemData[] = []
    const { brokenInitialInputValue, brokenIsEditing, unpublishedInitialInputValue, unpublishedIsEditing, brokenFocused, unpublishedFocused } =
      accountRedirectInitialData

    tabs.push({
      index: 'AccountRedirects',
      label: t('Account Redirects'),
      content: (
        <AccountRedirects
          setToastStatus={setToastStatus}
          brokenFocused={brokenFocused}
          unpublishedFocused={unpublishedFocused}
          brokenClickUrl={brokenClickthroughPersonalization.url}
          unpublishedUrl={unpublishedLandingPage.url}
          brokenInitialInputValue={brokenInitialInputValue || brokenClickthroughPersonalization.url}
          brokenIsEditing={brokenIsEditing}
          unpublishedInitialInputValue={unpublishedInitialInputValue || unpublishedLandingPage.url}
          unpublishedIsEditing={unpublishedIsEditing}
          updateInitialState={setAccountRedirectInitialData}
        />
      ),
    })

    tabs.push({
      index: 'FormDomains',
      label: t('Form Domains'),
      content: <FormDomains setToastStatus={setToastStatus} scrollableElement={scrollAreaRef} dataTest={'form-domains'} />,
    })

    tabs.push({
      index: 'APIAccessKeys',
      label: t('API Access Keys'),
      content: (
        <APIAccessKeys
          clientId={clientID}
          clientSecret={clientSecret}
          loading={createApiCredentialsLoading}
          createApiCredentials={createApiCredentials}
        />
      ),
    })

    tabs.push({
      index: 'Company',
      label: t('Company'),
      content: <CompanyContainer setToastStatus={setToastStatus} dataTest={'company'} />,
    })

    tabs.push({
      index: 'ContentPersonalizations',
      label: t('Content Personalizations'),
      content: <ContentPersonalization setToastStatus={setToastStatus} scrollableElement={scrollAreaRef} dataTest={'content-personalization'} />,
    })

    tabs.push({
      index: 'Security',
      label: t('Security & Policies'),
      content: <SecurityContainer setToastStatus={setToastStatus} />,
    })

    tabs.push({
      index: 'Labs',
      label: t('Labs'),
      content: <Labs setToastStatus={setToastStatus} />,
    })
    return tabs
  }

  const updateTabContent = (tabName: string, scrollAreaRef: RefObject<HTMLDivElement>) => {
    if (tabName === 'AccountRedirects') {
      setTabsData(tabContentData, scrollAreaRef)
    }
  }

  return (
    <PageContainer className={rootClass} noPadding>
      {!loading && toast?.showStatus && (
        <StatusToast
          isSuccess={toast.successStatus}
          message={toast.statusMessage}
          title={toast.title}
          closeStatus={() => {
            setToastStatus({ showStatus: false })
          }}
        />
      )}
      {loading && <Loader center />}

      <ListPageScrollArea>
        {(_tableRef, _menuRef, scrollAreaRef) => (
          <div className={`${rootClass}__content`}>
            <PositionContainer>
              <PageHeader
                primaryText={t('Custom Account Settings')}
                headlineType={PageHeaderType.SUB_HEADER}
                leftContent
                className={`${rootClass}__page-header`}
              />
              {!loading && !!tabContentData && tabContentData.isAdministrator && (
                <Tabs
                  childData={setTabsData(tabContentData, scrollAreaRef)}
                  defaultValue={currentTab}
                  onChange={(newTab: string) => {
                    updateTabContent(newTab, scrollAreaRef)
                    setCurrentTab(newTab)
                    const newTabUrl = convertTabToURL(newTab)
                    history.push(`${rootContext}/settings/customAccountSettings/${newTabUrl}`)
                  }}
                />
              )}
              {!loading && !!tabContentData && !tabContentData.isAdministrator && (
                <EmptyListing
                  headline={t('CustomAccountSettings.Empty.Headline')}
                  text={t('CustomAccountSettings.Empty.Description')}
                  imgSrc={StaticImageNames.errorNothingFound}
                />
              )}
            </PositionContainer>
          </div>
        )}
      </ListPageScrollArea>
    </PageContainer>
  )
}

export default CustomAccountSettingsContainer
