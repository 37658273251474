import { gql } from '@apollo/client'

export default gql`
  mutation submitPollResponse(
    $accountId: String!
    $messageId: String!
    $blockName: String!
    $recId: String
    $srcId: String
    $choice: [String]
    $comment: String
  ) {
    submitPollResponse(
      accountId: $accountId
      messageId: $messageId
      blockName: $blockName
      recId: $recId
      srcId: $srcId
      choice: $choice
      comment: $comment
    ) {
      status
      message
    }
  }
`
