import { ProgramErrors, ProgramFieldSetStep, ProgramStep } from '@graphql/types/query-types'
import { EXPANDED_STEPS_SESSION } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/utils/ProgramStepsListing.constants'
import { ProgramStepType } from '@utils/program/program.constants'
import { getItem, setItem } from '@utils/sessionStorage'

export interface StepErrors {
  isValid: boolean
  missingList: boolean
  missingSource: boolean
  missingValue: boolean
  deletedField: boolean
}

export const deletedFieldError = 'This field has been deleted'
export const deletedListError = 'This list has been deleted, please choose another list'
export const missingListError = 'Target list is not defined'
export const missingSourceError = 'Source list or segment is not defined'

const isMissingValue = (step: ProgramStep, stepErrors: ProgramErrors) => {
  if (stepErrors.type === ProgramStepType.FIELD_SET) {
    const setFieldStep = step as ProgramFieldSetStep
    return !!stepErrors?.errors.includes('Missing value') && !setFieldStep.value
  }
  return false
}

const isMissingList = (step: ProgramStep, stepErrors: ProgramErrors) => {
  const setFieldStep = step as ProgramFieldSetStep
  return !!stepErrors?.errors.includes('Target list is not defined') || !setFieldStep.listName
}

export const getStepErrors = (step: ProgramStep, programErrors: ProgramErrors[]) => {
  const stepErrors = programErrors.find(({ id, name }) =>
    step.stepId && !step.stepId.includes('newStep') ? id === step.stepId : name === step.displayName
  )

  const errors = {
    missingValue: !!stepErrors && isMissingValue(step, stepErrors),
    missingList: !!stepErrors && isMissingList(step, stepErrors),
    missingSource: !!stepErrors?.errors.includes(missingSourceError),
    deletedField: !!stepErrors?.errors.includes(deletedFieldError),
  }

  return { ...errors, isValid: Object.values(errors).every((value) => !value) }
}

export const getUpdatedStepsWithInvalidValue = (stepsWithInvalidValue: number[], position: number, hasValidValue = true) => {
  const isIncluded = stepsWithInvalidValue.includes(position)
  if (isIncluded && hasValidValue) {
    return stepsWithInvalidValue.filter((currentPosition) => currentPosition !== position)
  }
  if (!isIncluded && !hasValidValue) {
    return [...new Set([...stepsWithInvalidValue, position])].sort()
  }
  return stepsWithInvalidValue
}

export const getStepExpandedState = (stepId = '', programId?: number) =>
  JSON.parse(getItem(`${EXPANDED_STEPS_SESSION}:${programId}:${stepId}`) ?? 'false')

export const setStepExpandedState = (stepId = '', expanded: boolean, programId?: number) =>
  setItem(`${EXPANDED_STEPS_SESSION}:${programId}:${stepId}`, JSON.stringify(expanded))
