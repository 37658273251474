import { useCallback, useContext, useEffect, useMemo } from 'react'

import _ from 'lodash'

import { IPluginRow, ModuleTypes } from '@beefree.io/sdk/dist/types/bee'
import { AddOnModal, isParagraphModule } from '@src/pages/EmailComposer/utils/BeeEditor.types'
import { checkRowContentIsEmpty } from '@src/pages/EmailComposer/utils/BeeEditor.utils'
import { IPluginRowExtended } from '@utils/composer/beeEditor/beeEditorTypes'
import { defaultEmailComposerValidations, EmailComposerContext, PlaceholderBlocks } from '@utils/composer/context/EmailComposer.context'
import { isHtmlEmpty } from '@utils/isHtmlEmpty'
import { converterHtmlToBytes } from '@utils/strings'

import { findProhibitedDataURIs, findRssBlock, MAX_BYTE_SIZE } from '../components/EmailComposerPreview/EmailComposerPreview.utils'

export enum BlocksDefaultText {
  TITLE = "I'm a new title block",
  PARAGRAPH = "I'm a new paragraph block.",
  LIST = 'This is an unordered list',
  HTML = "I'm a new HTML block.",
  BUTTON = 'Button text',
  TABLE_HEADER = 'Add header text',
  TABLE_ROW = 'Add text',
}

enum BeeEditorBlockNames {
  HEADER = 'header',
  FOOTER = 'footer',
  VIDEO = 'video',
  IMAGE = 'image',
  BUTTON = 'button',
  HEADING = 'title',
  PARAGRAPH = 'paragraph',
  HTML = 'html',
  LIST = 'list',
  ICONS = 'icons',
  SOCIAL = 'social',
  EVENT = 'event',
  SIGNATURES = 'signatures',
  GENERATIVE = 'AI Create',
  TABLE = 'table',
  MENU = 'menu',
}

// Currently Table is missing from Bee Editor ModuleTypes, so add CustomModuleTypes for it
enum CustomModuleTypes {
  TABLE = 'mailup-bee-newsletter-modules-table',
  WEBINAR = 'mailup-bee-newsletter-modules-addon',
}

export const FOLLOW_UP_WEBINAR_PARAGRAPH_PLACEHOLDER = 'Enter your follow-up text here'

interface TableCell {
  cells: { html: string }[]
}

const webinarBlockPlaceholders = ['[ADD MORE INFO HERE]', '[ADD YOUR REGISTRATION INSTRUCTIONS HERE]', 'Enter you follow-up text here']

export const useEmailTemplateUIValidations = () => {
  const {
    values: {
      message: { templateHtml, templateJson, isPlainTextOnly },
    },
    api: { updateValidations },
  } = useContext(EmailComposerContext)

  const htmlSize = useMemo(() => converterHtmlToBytes(templateHtml), [templateHtml])
  const isHtmlSizeValid = useMemo(() => htmlSize <= MAX_BYTE_SIZE, [htmlSize])

  const getBlocksWithPlaceholder = useCallback<() => PlaceholderBlocks>(() => {
    const getHTMLTextContent = (html: string) => {
      const parser = new DOMParser()
      const doc = parser.parseFromString(html, 'text/html')
      return doc.body.textContent
    }

    const findWebinarPlaceholderBlocks = (html: string) => {
      const parser = new DOMParser()
      const doc = parser.parseFromString(html, 'text/html')

      const noRegistrationDescription = doc.getElementById('no-registration-description')
      const registrationDescription = doc.getElementById('registration-description')

      const description = noRegistrationDescription
        ? noRegistrationDescription.textContent
        : registrationDescription
        ? registrationDescription.textContent
        : null

      return webinarBlockPlaceholders.find((item) => item === description)
    }

    const templateModules: any[] = _.flattenDepth(
      templateJson?.page?.rows?.map((row: IPluginRow) => row?.columns?.map((column) => column.modules)),
      2
    )

    const result = { ...defaultEmailComposerValidations.contentValidations.placeholderBlocks }

    for (const module of templateModules) {
      if (Object.values(result).every((isDefault) => isDefault)) {
        break
      }

      const { type, descriptor }: any = module

      switch (type as string) {
        case ModuleTypes.ADDON:
          if (!result.webinar) {
            result.webinar = findWebinarPlaceholderBlocks(descriptor?.html?.html)
          }
          break
        case ModuleTypes.BUTTON:
          if (!result.button) {
            result.button = getHTMLTextContent(descriptor?.button?.label)?.trim() === BlocksDefaultText.BUTTON
          }
          break
        case ModuleTypes.HTML:
          if (!result.html) {
            result.html = getHTMLTextContent(descriptor?.html?.html)?.trim() === BlocksDefaultText.HTML
          }
          break
        case ModuleTypes.LIST:
          if (!result.list) {
            result.list = getHTMLTextContent(descriptor?.list?.html)?.trim() === BlocksDefaultText.LIST
          }
          break
        case ModuleTypes.PARAGRAPH:
          if (!result.paragraph) {
            result.paragraph =
              getHTMLTextContent(descriptor?.paragraph?.html)?.trim() === FOLLOW_UP_WEBINAR_PARAGRAPH_PLACEHOLDER
                ? FOLLOW_UP_WEBINAR_PARAGRAPH_PLACEHOLDER
                : getHTMLTextContent(descriptor?.paragraph?.html)?.trim() === BlocksDefaultText.PARAGRAPH
          }
          break
        case ModuleTypes.HEADING:
          if (!result.title) {
            result.title = getHTMLTextContent(descriptor?.heading?.text)?.trim() === BlocksDefaultText.TITLE
          }
          break
        case CustomModuleTypes.TABLE:
          const { headers, rows } = descriptor.table?.content ?? {}
          if (!result.tableHeader && Array.isArray(headers)) {
            result.tableHeader = Array.isArray(headers[0].cells) && headers[0].cells[0]?.html === BlocksDefaultText.TABLE_HEADER
          }
          if (!result.tableRow && Array.isArray(rows)) {
            result.tableRow = Array.isArray(rows[0].cells) && rows[0].cells[0]?.html === BlocksDefaultText.TABLE_ROW
          }
          break
      }
    }

    return result
  }, [templateJson])

  const findEmptyBlocksCount = useCallback((): Record<string, number> => {
    const emptyBlocksCount: Record<string, number> = {}
    // Reset empty additional blocks count
    Object.values(BeeEditorBlockNames).forEach((value) => (emptyBlocksCount[value] = 0))

    const increaseAdditionalBlocksCount = (block: BeeEditorBlockNames) => emptyBlocksCount[block]++
    const isValidHtmlWithText = (htmlString?: string): boolean => {
      if (!htmlString) {
        return false
      }
      const parser = new DOMParser()

      try {
        const doc = parser.parseFromString(htmlString.replace(/\u200B/g, ''), 'text/html')
        return !isHtmlEmpty(doc.body)
      } catch (error) {
        // Parsing error, likely due to invalid HTML
        return false
      }
    }

    templateJson?.page?.rows?.forEach((row: IPluginRowExtended) => {
      const { rowInternal, columns } = row
      if (rowInternal?.uid && checkRowContentIsEmpty(row)) {
        // Header and Footer blocks are 'row' type and have own rowInternal
        switch (rowInternal.uid) {
          case AddOnModal.HEADER_BLOCK:
            increaseAdditionalBlocksCount(BeeEditorBlockNames.HEADER)
            return
          case AddOnModal.FOOTER_BLOCK:
            increaseAdditionalBlocksCount(BeeEditorBlockNames.FOOTER)
            return
        }
      } else {
        if (Array.isArray(columns)) {
          columns.forEach(({ modules }) => {
            if (Array.isArray(modules)) {
              // All other Blocks are in columns modules
              modules.forEach(({ type, descriptor, moduleInternal }: any) => {
                switch (type as string) {
                  case ModuleTypes.MENU:
                    if (!descriptor.menuItemsList?.items.length || descriptor.menuItemsList.items.every((item: { text: string }) => !item.text)) {
                      increaseAdditionalBlocksCount(BeeEditorBlockNames.MENU)
                    }
                    return
                  case ModuleTypes.VIDEO:
                    if (!descriptor.video?.src) {
                      increaseAdditionalBlocksCount(BeeEditorBlockNames.VIDEO)
                    }
                    return
                  case ModuleTypes.IMAGE:
                    if (!descriptor.image?.src) {
                      increaseAdditionalBlocksCount(BeeEditorBlockNames.IMAGE)
                    }
                    return
                  case ModuleTypes.ICONS:
                    if (!descriptor.iconsList?.icons?.length) {
                      increaseAdditionalBlocksCount(BeeEditorBlockNames.ICONS)
                    }
                    return
                  case ModuleTypes.SOCIAL:
                    if (!descriptor.iconsList?.icons?.length) {
                      increaseAdditionalBlocksCount(BeeEditorBlockNames.SOCIAL)
                    }
                    return
                  case ModuleTypes.HTML:
                    if (!isValidHtmlWithText(descriptor.html?.html)) {
                      increaseAdditionalBlocksCount(BeeEditorBlockNames.HTML)
                    }
                    return
                  case ModuleTypes.BUTTON:
                    if (!isValidHtmlWithText(descriptor.button?.label)) {
                      increaseAdditionalBlocksCount(BeeEditorBlockNames.BUTTON)
                    }
                    return
                  case ModuleTypes.HEADING:
                    if (!isValidHtmlWithText(descriptor.heading?.text)) {
                      increaseAdditionalBlocksCount(BeeEditorBlockNames.HEADING)
                    }
                    return
                  case ModuleTypes.PARAGRAPH:
                    if (!isValidHtmlWithText(descriptor.paragraph?.html)) {
                      increaseAdditionalBlocksCount(BeeEditorBlockNames.PARAGRAPH)
                    }
                    return
                  case ModuleTypes.LIST:
                    if (!isValidHtmlWithText(descriptor.list?.html)) {
                      increaseAdditionalBlocksCount(BeeEditorBlockNames.LIST)
                    }
                    return
                  case CustomModuleTypes.TABLE:
                    const { headers, rows } = descriptor.table?.content ?? {}
                    const allCells = [
                      ...(headers?.flatMap((header: TableCell) => header.cells) || []),
                      ...(rows?.flatMap((row: TableCell) => row.cells) || []),
                    ]

                    if (!Array.isArray(rows)) {
                      increaseAdditionalBlocksCount(BeeEditorBlockNames.TABLE)
                    } else {
                      // checking every cell for emptiness
                      if (allCells.every((row) => !isValidHtmlWithText(row.html))) {
                        increaseAdditionalBlocksCount(BeeEditorBlockNames.TABLE)
                      }
                    }
                    return
                  case ModuleTypes.ADDON:
                    switch (moduleInternal?.uid) {
                      case AddOnModal.EVENT_BLOCK:
                        if (!descriptor.html?.html) {
                          increaseAdditionalBlocksCount(BeeEditorBlockNames.EVENT)
                        }
                        return
                      case AddOnModal.SIGNATURE_BLOCK:
                        if (!descriptor.html?.html) {
                          increaseAdditionalBlocksCount(BeeEditorBlockNames.SIGNATURES)
                        }
                        return
                      case AddOnModal.GENERATIVE_EMAIL:
                        if (!descriptor.mixed?.html) {
                          increaseAdditionalBlocksCount(BeeEditorBlockNames.GENERATIVE)
                        }
                        return
                    }
                    return
                }
              })
            }
          })
        }
      }
    })

    return emptyBlocksCount
  }, [templateJson])

  const findEmptyRowsWithConditions = useCallback(() => {
    const emptyRowsWithConditions = templateJson.page.rows.filter(
      (row) =>
        row.type === 'one-column-empty' &&
        row.columns.some((column) => {
          // Plain text columns will always contain one paragraph module but may not have content
          const isEmpty =
            isPlainTextOnly && isParagraphModule(column.modules[0])
              ? column.modules[0].descriptor.paragraph.html.trim() === ''
              : !column.modules.length
          return isEmpty && !!row.container.displayCondition
        })
    )
    return emptyRowsWithConditions.map((row) => ({
      condition: row.container.displayCondition?.label,
    }))
  }, [templateJson, isPlainTextOnly])

  const getIsOnlyDynamicRows = useCallback(() => {
    if (isPlainTextOnly) {
      return !templateJson.page.rows.filter((item) => {
        const module = item.columns[0].modules[0]
        return !item.container.displayCondition && isParagraphModule(module) && module.descriptor.paragraph.html.trim() !== ''
      }).length
    }
    return !templateJson.page.rows.filter((item) => {
      return ![`${AddOnModal.HEADER_BLOCK}`, `${AddOnModal.FOOTER_BLOCK}`].includes(`${item.rowInternal?.uid}`) && !item.container.displayCondition
    }).length
  }, [templateJson, isPlainTextOnly])

  const findSvgImageUrls = useCallback(() => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(templateHtml, 'text/html')

    // Find all img tags
    const imgTags = doc.querySelectorAll('img')
    const svgUrls: string[] = []

    // Filter for SVG images by checking the src attribute
    imgTags.forEach((img) => {
      const src = img.getAttribute('src') || ''
      if (src.toLowerCase().endsWith('.svg')) {
        svgUrls.push(src)
      }
    })

    return svgUrls
  }, [templateHtml])

  useEffect(() => {
    const prohibitedDataURIs = findProhibitedDataURIs(templateHtml)
    const emptyBlocks = findEmptyBlocksCount()
    const hasEmptyBlock = Object.values(emptyBlocks).some((block) => !!block)
    const placeholderBlocks = getBlocksWithPlaceholder()
    const hasDefaultPlaceholderBlock = Object.values(placeholderBlocks).some((block) => block)
    const emptyRows = findEmptyRowsWithConditions()
    const isOnlyDynamicRows = getIsOnlyDynamicRows()
    const hasRssBlock = findRssBlock(templateHtml, isPlainTextOnly)
    const svgUrls = findSvgImageUrls()

    updateValidations({
      contentValidations: {
        htmlSize,
        isHtmlSizeValid,
        hasDefaultPlaceholderBlock,
        hasEmptyBlock,
        prohibitedDataURIs,
        placeholderBlocks,
        emptyBlocks,
        emptyRows,
        isOnlyDynamicRows,
        hasRssBlock,
        svgUrls,
      },
    })
  }, [
    findEmptyBlocksCount,
    getBlocksWithPlaceholder,
    templateHtml,
    htmlSize,
    isHtmlSizeValid,
    updateValidations,
    findEmptyRowsWithConditions,
    getIsOnlyDynamicRows,
    findSvgImageUrls,
  ])
}
