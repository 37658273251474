import React, { ChangeEventHandler, FC, useCallback, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Input from '@components/Input/Input'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { INPUT_DEBOUNCE_TIME, useTranslation } from '@const/globals'
import { Recipient } from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertRecipients/components/RecipientsList/RecipientsList'
import RecipientSelectList from '@src/pages/WebsiteProspectorAlerts/components/RecipientSelect/components/RecipientSelectList/RecipientSelectList'

import './RecipientSelect.css'

interface RecipientSelectProps {
  className?: string
  dataTest?: string
  recipients: Recipient[]
}

const rootClass = 'recipient-select'
export const ALL_RECIPIENTS_LIST = 'all'

const RecipientSelect: FC<RecipientSelectProps> = (props: RecipientSelectProps) => {
  const { dataTest = rootClass, className = '', recipients } = props

  const [showDropdown, setShowDropdown] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState<string | undefined>()
  const [search, setSearch] = useState<string>()

  const containerRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()

  const clickListener = (e: MouseEvent) => {
    const { current: inputElement } = containerRef
    if (inputElement && !inputElement.contains(e.target as Node)) {
      setShowDropdown(false)
      setSelectedGroup(undefined)
    }
  }

  const renderFooter = () => (
    <div className={classNames(`${rootClass}__footer`, { [`${rootClass}__footer-add-group`]: selectedGroup })}>
      {selectedGroup ? (
        <Button buttonType={ButtonType.PRIMARY} onClick={() => !selectedGroup && setSelectedGroup(ALL_RECIPIENTS_LIST)}>
          {t('Add group')}
        </Button>
      ) : (
        <>
          <Typography text={t('View all users')} type={TextType.NORMAL_TEXT_TEAL_LARGE} weight={TextWeight.MEDIUM} />
          <div className={`${rootClass}__footer-icon`}>
            <Svg name={SvgNames.arrowRight} type={SvgType.LARGER_ICON} />
          </div>
        </>
      )}
    </div>
  )

  const onInputChangeCallback: ChangeEventHandler<HTMLInputElement> = useCallback(({ target: { value } }) => {
    setSearch(value)
    setSelectedGroup(value ? ALL_RECIPIENTS_LIST : undefined)
  }, [])

  useEffect(() => {
    document.addEventListener('click', clickListener)
    return () => document.removeEventListener('click', clickListener)
  })

  return (
    <div
      role={'button'}
      tabIndex={0}
      className={classNames(rootClass, className)}
      data-test={dataTest}
      onKeyDown={(keyDownEvent) => (keyDownEvent.key === ' ' ? setShowDropdown(true) : undefined)}
      onClick={() => setShowDropdown(true)}
      ref={containerRef}
    >
      <Input
        dataTest={`${dataTest}-input`}
        className={`${rootClass}__input`}
        type={'search'}
        placeholder={t('Add users, groups, and emails')}
        onChangeDebounce={INPUT_DEBOUNCE_TIME}
        onChange={onInputChangeCallback}
      />
      {showDropdown && (
        <div className={`${rootClass}__content`}>
          <RecipientSelectList
            dataTest={`${dataTest}-list`}
            className={classNames(`${rootClass}__list`, { [`${rootClass}__list-no-footer`]: selectedGroup === ALL_RECIPIENTS_LIST })}
            recipients={recipients}
            selectedGroup={selectedGroup}
            search={search}
            onSelectGroup={(group) => setSelectedGroup(group)}
          />
          {selectedGroup !== ALL_RECIPIENTS_LIST && renderFooter()}
        </div>
      )}
    </div>
  )
}

export default RecipientSelect
