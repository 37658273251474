import { gql } from '@apollo/client'

export default gql`
  mutation duplicateFormTemplate($templateId: String!, $newName: String, $tags: [LabelDto], $folderId: Int) {
    duplicateFormTemplate(templateId: $templateId, newName: $newName, tags: $tags, folderId: $folderId) {
      id
      name
    }
  }
`
