import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import { YesNo } from '@components/ConfirmationModal'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import { Status } from '@components/StatusToast/StatusToast'
import Typography, { ModalBodyStyle, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { useFormsJoinViewListingPageEntityJoinRequests } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.entityJoin.graphQL'
import { useFormsJoinViewListingPageRequests } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.graphQL'
import { ItemType } from '@utils/categorization'
import { logNewRelicError } from '@utils/new-relic.utils'

import './DeleteContactsConfirmationModal.css'

interface DeleteContactsConfirmationModalProps {
  update: Function
  ids: string[]
  actionType: ActionType
  className?: string
  dataTest?: string
  itemType: ItemType
}

export enum ActionType {
  DELETE = 'DELETE',
  CLEAR = 'CLEAR',
}

type ContentType = {
  title: string
  body: ReactNode
  deleteButtonText: string
  onConfirm: () => void
}

const rootClass = 'delete-contacts-confirmation-modal'

const DeleteContactsConfirmationModal: FC<DeleteContactsConfirmationModalProps> = (props: DeleteContactsConfirmationModalProps) => {
  const { dataTest = rootClass, className = '', update, ids, actionType, itemType } = props
  const { deleteAllContacts } = useFormsJoinViewListingPageRequests()
  const { clearAllRecords } = useFormsJoinViewListingPageEntityJoinRequests()
  const { t } = useTranslation()

  const clearContacts = () => {
    clearAllRecords(ids)
      .then(() => {
        update({
          statusToast: {
            statusMessage: 'Records successfully cleared',
            status: Status.SUCCESS,
            showStatusToast: true,
          },
          showCustomModal: false,
          fetchItems: true,
          loading: true,
        })
      })
      .catch((error) => {
        update(errorStatus)
        logNewRelicError(error)
      })
  }

  const deleteContacts = () => {
    deleteAllContacts(ids)
      .then(() => {
        update({
          statusToast: {
            statusMessage: "We're deleting your contacts now. This may take some time. Check back later for an updated contact list.",
            status: Status.SUCCESS,
            showStatusToast: true,
          },
          showCustomModal: false,
          fetchItems: true,
          loading: true,
        })
      })
      .catch((error) => {
        update(errorStatus)
        logNewRelicError(error)
      })
  }

  const contentByType: { [key in ActionType]: ContentType } = {
    [ActionType.CLEAR]: {
      title: t(`ListPage.FormsJoinView.${itemType}.ConfirmationModal.ClearRecords.Title`),
      body: t(`ListPage.FormsJoinView.${itemType}.ConfirmationModal.ClearRecords.Body`),
      deleteButtonText: t(`ListPage.FormsJoinView.${itemType}.ConfirmationModal.ClearRecords.Title`),
      onConfirm: clearContacts,
    },
    [ActionType.DELETE]: {
      title: t('Delete these contacts'),
      body: (
        <>
          <Typography
            text={t('The contacts’ records and activity histories will be deleted from Act-On Contacts and all Segments. ')}
            {...ModalBodyStyle}
            inline
          />
          <Typography text={t('You will not be able to undo this action.')} {...ModalBodyStyle} weight={TextWeight.BOLD} inline />
        </>
      ),
      deleteButtonText: t('Delete'),
      onConfirm: deleteContacts,
    },
  }

  const errorStatus = {
    statusToast: {
      statusMessage: 'Something went wrong on our end. Please try again.',
      status: Status.FAIL,
      showStatusToast: true,
    },
  }

  const { title, body, deleteButtonText, onConfirm } = contentByType[actionType]

  return (
    <DeleteConfirmationModal
      className={classNames(rootClass, className)}
      dataTest={dataTest}
      isOpen
      title={title}
      body={body}
      deleteButtonText={deleteButtonText}
      onAnswer={async (answer: YesNo) => {
        if (answer === YesNo.YES) {
          if (ids.length) {
            onConfirm()
          }
        } else {
          update({ showCustomModal: false })
        }
      }}
    />
  )
}

export default DeleteContactsConfirmationModal
