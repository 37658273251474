import { useState } from 'react'

import { Column } from '@components/ColumnsOrderModal/components/DraggableColumn/DraggableColumn'
import { buildColumns } from '@components/ContactsDetails/utils/ContactsDetails.utils'
import getColumnIndexOrder from '@graphql/microservices/segment/getColumnIndexOrder'
import getContacts from '@graphql/microservices/segment/getContacts'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import {
  GetColumnIndexOrderQuery,
  GetColumnIndexOrderQueryVariables,
  GetContactsQuery,
  GetContactsQueryVariables,
  Page,
  PageInput,
} from '@graphql/types/microservice/segment-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { useUnifiedContactList } from '@utils/hooks/useUnifiedContactList'

export interface SegmentServiceRequests {
  getContactsRequest: (page: PageInput) => Promise<Page | undefined>
  getColumnsRequest: (externalId: string, headers: string[]) => Promise<Column[] | undefined>
}

export const useSegmentService = (): SegmentServiceRequests => {
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.SEGMENT })

  const { getUnifiedListFieldMappingsRequest } = useUnifiedContactList({ fetchOnMount: false })

  const [unifiedListFieldMappings, setUnifiedListFieldMappings] = useState<UnifiedListFieldMapping[]>()

  const getContactsRequest = async (page: PageInput) => {
    const { data } = await client.query<GetContactsQuery, GetContactsQueryVariables>({
      query: getContacts,
      fetchPolicy: 'network-only',
      variables: {
        page,
        isSubmissionList: false,
      },
    })
    if (data.getContacts) {
      return data.getContacts
    }
  }

  const getUCLFieldMappings = async (): Promise<UnifiedListFieldMapping[]> => {
    if (!unifiedListFieldMappings) {
      const updatedUnifiedListFieldMappings = (await getUnifiedListFieldMappingsRequest()) ?? []
      setUnifiedListFieldMappings(updatedUnifiedListFieldMappings)
      return updatedUnifiedListFieldMappings
    }
    return unifiedListFieldMappings
  }

  const getColumnsRequest = async (externalId: string, headers: string[]): Promise<Column[] | undefined> => {
    const unifiedListFieldMappings = await getUCLFieldMappings()
    try {
      const { data } = await client.query<GetColumnIndexOrderQuery, GetColumnIndexOrderQueryVariables>({
        query: getColumnIndexOrder,
        fetchPolicy: 'network-only',
        variables: {
          segmentId: externalId,
        },
      })
      if (data.getColumnIndexOrder) {
        const { order = [], fixed = [], hidden = [] } = data.getColumnIndexOrder
        return buildColumns(order as number[], hidden as number[], fixed as number[], headers, unifiedListFieldMappings)
      }
    } catch (error) {
      return buildColumns([], [], [], headers, unifiedListFieldMappings)
    }
  }

  return {
    getContactsRequest,
    getColumnsRequest,
  }
}
