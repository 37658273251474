export const formGeoIPFields = ['_GEO_CITY', '_GEO_COUNTRY', '_GEO_COUNTRY_CODE', '_GEO_NAME', '_GEO_POSTAL_CODE', '_GEO_STATE']

export const formSubmissionFields = [
  '_AO_UUID',
  '_BROWSER',
  '_CAMPAIGN',
  '_CAMPAIGNID',
  '_EMAIL_REFERRER',
  '_FORM',
  '_FORM_URL',
  '_IPADDR',
  '_JSTZO',
  '_REFERRER',
  '_SEARCH',
  '_TIME',
]
