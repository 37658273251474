import React, { ReactNode, FC, useCallback, useEffect, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { ButtonSize } from '@components/Button/Button'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './ClickToCopy.css'

export enum ClickToCopyType {
  WHITE_BG = 'WHITE_BG',
  HOVER_ROW_BG = 'HOVER_ROW_BG',
  TIGHT_SPACING = 'TIGHT_SPACING',
}

export type ClickToCopyProps = {
  value: string
  text: ReactNode
  type?: ClickToCopyType
  copyText?: string
  copyClicked?: (event: React.MouseEvent<HTMLButtonElement>) => any
  className?: string
  dataTest?: string
}

const rootClass = 'click-to-copy'

export const ClickToCopy: FC<ClickToCopyProps> = ({
  value,
  text,
  type = ClickToCopyType.WHITE_BG,
  copyText = 'Copy',
  copyClicked,
  className,
  dataTest,
}: ClickToCopyProps) => {
  const [showSvg, setShowSvg] = useState(false)
  const [hideTimeout, setHideTimeout] = useState<any>()

  const { t } = useTranslation()

  const handleClick = useCallback<(event: React.MouseEvent<HTMLButtonElement>) => void>(
    (event) => {
      event.preventDefault()
      setShowSvg(true)
      navigator.clipboard.writeText(value)
      copyClicked && copyClicked(event)
      setHideTimeout(setTimeout(() => setShowSvg(false), 1500))
    },
    [copyClicked, value]
  )
  useEffect(() => {
    return () => {
      hideTimeout && clearTimeout(hideTimeout)
    }
  }, [hideTimeout])

  return (
    <div
      className={classNames(rootClass, className, {
        [`${rootClass}__white-bg`]: type === ClickToCopyType.WHITE_BG,
        [`${rootClass}__hover-row-bg`]: type === ClickToCopyType.HOVER_ROW_BG,
        [`${rootClass}__tight-spacing`]: type === ClickToCopyType.TIGHT_SPACING,
      })}
      data-test={dataTest}
    >
      {typeof text === 'string' ? <Typography text={t(text)} /> : text}
      <Button
        buttonType={ButtonType.TEXT_TEAL}
        buttonSize={ButtonSize.SMALL}
        onClick={handleClick}
        className={classNames(`${rootClass}__button`, {
          [`${rootClass}__button-clicked`]: showSvg,
        })}
        dataTest={`${dataTest}-button`}
      >
        {t(copyText)}
      </Button>
      {showSvg && (
        <Svg
          name={SvgNames.clipboardCheckColorless}
          type={SvgType.ICON}
          fill={SvgColor.TEXT_TEAL}
          className={`${rootClass}__svg`}
          dataTest={`${dataTest}-svg`}
        />
      )}
    </div>
  )
}

export default ClickToCopy
