import React, { FC } from 'react'

import classNames from 'classnames'

import './DonutChartSimple.css'

interface Props {
  chartColor: string
  backgroundColor: string
  percentage: number
  className?: string
  dataTest?: string
}

const rootClass = 'donut-chart-simple'

const DonutChartSimple: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '', chartColor, backgroundColor, percentage } = props
  return (
    <svg width="100%" height="100%" className={classNames(rootClass, className)} data-test={dataTest} viewBox="0 0 40 40">
      <circle className="donut-ring-border" cx="20" cy="20" r="15.91549430918954" fill="transparent" strokeWidth="7.5" stroke={chartColor}></circle>
      <circle className="donut-hole" cx="20" cy="20" r="15.91549430918954" fill="transparent"></circle>
      <circle className="donut-ring" cx="20" cy="20" r="15.91549430918954" fill="transparent" strokeWidth="6" stroke={backgroundColor}></circle>
      <circle
        className="donut-segment"
        cx="20"
        cy="20"
        r="15.91549430918954"
        fill="transparent"
        strokeWidth="6"
        strokeDasharray={`${percentage} ${100 - percentage}`}
        strokeDashoffset="25"
        stroke={chartColor}
      ></circle>
    </svg>
  )
}

export default DonutChartSimple
