import React, { FC } from 'react'

import classNames from 'classnames'

import SelectV2 from '@components/SelectV2/SelectV2'
import { useTranslation } from '@const/globals'
import {
  getBooleanConditionOptions,
  getBooleanValueOptions,
  TextEmptyComparison,
  TextNotEmptyComparison,
} from '@src/pages/SegmentComposer/SegmentComposer.constants'

export interface BooleanCriteriaType {
  criteria?: string
  value: boolean
}

interface BooleanCriteriaProps {
  className?: string
  dataTest?: string
  onChange: (booleanCriteria: BooleanCriteriaType) => void
  booleanCriteria: BooleanCriteriaType
}

const rootClass = 'boolean-criteria'
const defaultBooleanConditionIndex = 0
const defaultBooleanValueIndex = 0

const BooleanCriteria: FC<BooleanCriteriaProps> = (props: BooleanCriteriaProps) => {
  const { dataTest = rootClass, className = '', booleanCriteria, onChange } = props
  const { t } = useTranslation()
  const booleanConditionOptions = getBooleanConditionOptions(t)
  const booleanValueOptions = getBooleanValueOptions(t)
  const { criteria = booleanConditionOptions[defaultBooleanConditionIndex], value } = booleanCriteria

  const criteriaOption = booleanConditionOptions.find((option) => option.value === criteria) || booleanConditionOptions[defaultBooleanConditionIndex]
  const valueOption = booleanValueOptions.find((option) => (option.value === '1') === value) || booleanValueOptions[defaultBooleanValueIndex]

  const renderNextCondition = () => {
    const { value } = criteriaOption
    if (value in TextEmptyComparison || value in TextNotEmptyComparison) {
      return null
    }
    return (
      <SelectV2
        className={classNames(`${rootClass}__boolean-value-select`, className)}
        dataTest={`${dataTest}-value-select`}
        options={booleanValueOptions}
        value={valueOption}
        onChange={(criteria) => onChange({ ...booleanCriteria, value: criteria?.value === '1' })}
        isClearable={false}
        isSearchable={false}
        menuListCustomStyle={{ minWidth: 'max-content' }}
      />
    )
  }

  return (
    <>
      <SelectV2
        className={classNames(`${rootClass}__boolean-criteria-select`, className)}
        dataTest={`${dataTest}-criteria-select`}
        options={booleanConditionOptions}
        value={criteriaOption}
        onChange={(criteria) => onChange({ ...booleanCriteria, criteria: criteria?.value })}
        isClearable={false}
        isSearchable={false}
      />
      {renderNextCondition()}
    </>
  )
}

export default BooleanCriteria
