import React, { FC, RefObject, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import CaretIcon, { CaretIconDirection } from '@components/CaretIcon'
import MenuItem, { MenuItemProps } from '@components/Menu/components/MenuItem/MenuItem'
import { SvgType } from '@components/Svg'
import Typography, { TextWeight } from '@components/Typography/Typography'

import './MenuItemWithHeader.css'

import Button, { ButtonType } from '@components/Button/Button'

export interface Props extends MenuItemProps {
  header: string
  isOpen?: boolean
  scrollAreaRef?: RefObject<HTMLDivElement>
}

export interface State {
  open: boolean
  scrollTop: boolean
}

const rootClass = 'menu-item-with-header'

const MenuItemWithHeader: FC<Props> = (props: Props) => {
  const { header, isOpen = false, scrollAreaRef, dataTest = rootClass, className = '' } = props
  const [state, setState] = useState<State>({ open: isOpen, scrollTop: false })
  const { open, scrollTop } = state

  const headerRef = useRef<HTMLDivElement>(null)

  useEffect(() => setState({ ...state, open: isOpen || open }), [isOpen])

  useEffect(() => {
    const scrollArea = scrollAreaRef?.current
    if (scrollArea) {
      scrollArea.addEventListener('scroll', handleScroll)
      return () => scrollArea.removeEventListener('scroll', handleScroll)
    }
  }, [scrollAreaRef, open])

  const handleScroll = () => {
    const offsetTop = headerRef.current?.offsetTop
    if (open && offsetTop !== undefined) {
      setState((state: State) => ({
        ...state,
        scrollTop: offsetTop > 0,
      }))
    }
  }

  return (
    <div className={classNames(rootClass, className)}>
      <div
        className={classNames(`${rootClass}__header`, {
          [`${rootClass}__header-open`]: open,
          [`${rootClass}__scrolling`]: scrollTop,
        })}
        ref={headerRef}
      >
        <Button
          buttonType={ButtonType.TEXT}
          className={classNames(`${rootClass}__header-text`)}
          dataTest={`${dataTest}__header`}
          onClick={() => setState({ ...state, open: !open, scrollTop: false })}
        >
          <CaretIcon direction={CaretIconDirection.RIGHT} toggle={open} type={SvgType.VERY_SMALL_ICON} className={`${rootClass}__header-icon`} />
          <Typography text={header} weight={TextWeight.MEDIUM} />
        </Button>
      </div>
      {open && <MenuItem {...props} className={`${rootClass}__item`} onScroll={handleScroll} />}
    </div>
  )
}

export default MenuItemWithHeader
