import React, { FC } from 'react'

import classNames from 'classnames'

import Toggle from '@components/Toggle'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './PercentageAction.css'

const rootClass = 'percentage-actions'

interface Props {
  showPercentages: boolean
  onChange: () => void
}

const PercentageAction: FC<Props> = ({ showPercentages, onChange }: Props) => {
  const { t } = useTranslation()
  return (
    <Tooltip
      triggerClassName={classNames(`${rootClass}__button`, 'no-print')}
      trigger={
        <div className={`${rootClass}__action`}>
          <Typography text={'Show %'} weight={TextWeight.MEDIUM} inline />
          <Toggle isOn={showPercentages} onToggle={onChange} />
        </div>
      }
    >
      {t(`${showPercentages ? 'Hide' : 'Show'} monthly comparison data`)}
    </Tooltip>
  )
}

export default PercentageAction
