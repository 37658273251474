import { useTranslation } from '@utils/const/globals'
import { allDraftsFilter } from '@utils/filter'

import { renderDraftsSearchTableColumns, renderDraftsTableColumns } from './EmailDraftsPicker.tables'
import { EmailPickerProps, EmailType } from '../useEmailPickerProps'

export const useEmailDraftPickerProps = () => {
  const { t } = useTranslation()

  const config: EmailPickerProps = {
    customSources: {
      allItemFilter: { ...allDraftsFilter, name: 'All emails' },
      itemName: 'Email',
      label: 'Draft emails',
      mainColumnName: 'Email Title',
      value: EmailType.DRAFT,
      itemType: EmailType.DRAFT,
      sidebarProps: {
        hideTags: true,
        hideFolders: false,
      },
    },
    i18nListPageKey: 'Emails.DraftsAndTemplates',
    renderSearchColumns: (_searchAllItems, _currentFolder, search, folders) => renderDraftsSearchTableColumns(search, folders, t),
    renderTableColumns: renderDraftsTableColumns(t),
    renderCustomFilters: () => [],
    hasCustomFilters: false,
    subTypes: [],
    hasCreatedByMe: true,
    hasFavorites: true,
    sortingBy: [{ id: 'lastUpdated', desc: true }],
    // Users do not currently have access to the React drafts listing page and so have no way of adding tags
    hasTags: false,
  }
  return config
}
