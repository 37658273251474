import { gql } from '@apollo/client'

export default gql`
  query getUnifiedListFieldsFromSource($sourceId: String) {
    getUnifiedListFieldsFromSource(sourceId: $sourceId) {
      columnIndex
      dataType
      deleted
      displayName
      hidden
      ingestFieldMappings {
        format
        mapping
        objectType
        source
        sourceId
      }
      readOnly
      required
      standardFieldId
      standardFieldKey
      typeMetaData
      unifiedListColumnId
    }
  }
`
