import React, { ChangeEvent, FC, useCallback, useContext, useMemo, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Container from '@components/Container'
import InfoTooltip from '@components/InfoTooltip/InfoTooltip'
import Input from '@components/Input/Input'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import { Status } from '@components/StatusToast/StatusToast'
import { TOAST_TEXT_CLASSNAME } from '@components/Toast/Toast'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { ModalBodyStyle, TextWeight } from '@components/Typography/Typography'
import { INPUT_DEBOUNCE_TIME, useTranslation } from '@const/globals'
import { SegmentStatus } from '@graphql/types/microservice/ml-audience-types'
import { ALL_ACTON_CONTACTS_SEGMENT_NAME } from '@src/pages/ContactSegments/utils/ContactSegments.constants'
import { RecommendedSegmentsContext } from '@src/pages/RecommendedSegments/utils/RecommendedSegments.context'
import { useRecommendedSegmentsRequests } from '@src/pages/RecommendedSegments/utils/RecommendedSegments.graphQL'
import { getRecommendedSegmentFormattedDate } from '@src/pages/RecommendedSegments/utils/RecommendedSegments.utils'

import './CreateSuggestedSegmentModal.css'

interface CreateSuggestedSegmentModalProps {
  className?: string
  dataTest?: string
  onPreviewClick: VoidFunction
  isOpen?: boolean
}

const rootClass = 'create-suggested-segment-modal'

const CreateSuggestedSegmentModal: FC<CreateSuggestedSegmentModalProps> = (props: CreateSuggestedSegmentModalProps) => {
  const { dataTest = rootClass, className = '', isOpen = false } = props

  const {
    update,
    loadSegments,
    values: { allParentSegments, segmentToCreate },
  } = useContext(RecommendedSegmentsContext)

  const { updateSegment } = useRecommendedSegmentsRequests()

  const { t } = useTranslation()

  const [segmentName, setSegmentName] = useState(`${t('AI SEGMENT')} ${getRecommendedSegmentFormattedDate()} | ${segmentToCreate?.name}`)

  const parentSegment = useMemo(() => {
    if (segmentToCreate?.parentSegmentId) {
      const parentSegment = allParentSegments.find(({ id }) => id === segmentToCreate.parentSegmentId)
      return parentSegment && parentSegment.name !== ALL_ACTON_CONTACTS_SEGMENT_NAME ? parentSegment : undefined
    }
  }, [segmentToCreate, allParentSegments])

  const handleClose = () => {
    update({ segmentToCreate: undefined })
  }

  const handleAction = () => {
    if (segmentToCreate?.id) {
      updateSegment(segmentToCreate.id, 'ACCEPTED' as SegmentStatus)
        .then(({ data }) => {
          update({
            status: {
              message: (
                <Typography
                  className={classNames(TOAST_TEXT_CLASSNAME, 'ellip', `segment-created-toast`)}
                  text={`${t('Segment created!')} <bold>${data?.updateSegment?.name}</bold>`}
                  tagProps={{ bold: { weight: TextWeight.BOLD } }}
                  inline
                />
              ),
              status: Status.SUCCESS,
            },
          })
          loadSegments()
        })
        .catch(() => {
          update({
            status: {
              message: t('There was a problem processing your request'),
              status: Status.FAIL,
            },
          })
        })
        .finally(() => {
          handleClose()
        })
    }
  }

  const onInputChange = useCallback(({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSegmentName(value)
  }, [])

  const header = (
    <ModalHeader headerType={ModalHeaderType.List} className={`${rootClass}__header`}>
      {t('Create suggested segment')}
    </ModalHeader>
  )

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
      <ModalBody className={`${rootClass}__body`}>
        <div className={`${rootClass}__body-header`}>
          <Typography text={t('Create a segment based on the following criteria')} {...ModalBodyStyle} inline />
          <InfoTooltip>{t('Segment reflects contacts that match this criteria at the time of the initial query')}</InfoTooltip>
        </div>
        <Container className={`${rootClass}__segment-info`}>
          <Typography text={getRecommendedSegmentFormattedDate()} {...ModalBodyStyle} />
          <Typography text={segmentToCreate?.name} weight={TextWeight.MEDIUM} />
          <Typography text={`Estimated # of contacts in the segment: {{estimate}}`} values={{ estimate: segmentToCreate?.memberEstimate ?? '-' }} />
          {!!parentSegment && <Typography text={`Parent segment: {{name}}`} values={{ name: parentSegment?.name }} />}
          <Tooltip
            triggerClassName={`${rootClass}__preview-segment-button`}
            inline={false}
            disabledTrigger
            trigger={
              <Button disabled buttonType={ButtonType.TEXT_TEAL}>
                {t('Preview Segment')}
              </Button>
            }
          >
            {t('Coming Soon')}
          </Tooltip>
        </Container>
        <div>
          <Input label={t('Name this segment')} value={segmentName} onChangeDebounce={INPUT_DEBOUNCE_TIME} onChange={onInputChange} />
        </div>
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.List} className={`${rootClass}__footer`}>
        <Button buttonType={ButtonType.TERTIARY} onClick={handleClose} dataTest={`${dataTest}-button-tertiary`}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} onClick={handleAction} dataTest={`${dataTest}-button-primary`}>
          {t('Create segment')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CreateSuggestedSegmentModal
