import { RefObject, useCallback, useEffect, useRef } from 'react'

interface UseDetectInteractionProps {
  containerRef: RefObject<HTMLElement>
  requireInitialInteraction?: boolean
  onOutside?: VoidFunction
  onInside?: VoidFunction
}
export const useDetectInteraction = (props: UseDetectInteractionProps) => {
  const { containerRef, requireInitialInteraction = true, onOutside, onInside } = props
  const isActive = useRef(false)

  useEffect(() => {
    if (!onOutside && !onInside) {
      return
    }
    const handleEvent = (e: Event) => {
      if (!containerRef.current?.contains(e.target as HTMLElement)) {
        if (!requireInitialInteraction || isActive.current) {
          onOutside?.()
          isActive.current = false
        }
      } else {
        isActive.current = true
        onInside?.()
      }
    }
    document.addEventListener('click', handleEvent)
    document.addEventListener('keydown', handleEvent)
    document.addEventListener('focus', handleEvent)
    document.addEventListener('blur', handleEvent)
    return () => {
      document.removeEventListener('click', handleEvent)
      document.removeEventListener('keydown', handleEvent)
      document.addEventListener('focus', handleEvent)
      document.addEventListener('blur', handleEvent)
    }
  }, [containerRef, onOutside, onInside, requireInitialInteraction])

  const setInsideInteraction = useCallback((value: boolean) => {
    isActive.current = value
  }, [])

  return { setInsideInteraction }
}
