import Cookies from 'js-cookie'

import { USER_NOT_VALID_ERROR, CACHE_BUSTER, isCypress, isJest } from '@const/globals'
import { Metrics } from '@newrelic/browser-agent/features/metrics'
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'

const ERRORS_TO_MUTE = ['ResizeObserver loop limit exceeded', 'ResizeObserver loop completed with undelivered notifications']
const NEWRELIC_DISBABLED = isCypress() || isJest()

function setupNewRelic() {
  if (NEWRELIC_DISBABLED) {
    return
  }
  const options = {
    init: { privacy: { cookies_enabled: true } },
    info: { beacon: 'bam-cell.nr-data.net', errorBeacon: 'bam-cell.nr-data.net', licenseKey: '270986c6ad', applicationID: '901463161', sa: 1 },
    loader_config: { accountID: '2404857', trustKey: '2409163', agentID: '901463161', licenseKey: '270986c6ad', applicationID: '901463161' },
    features: [Metrics],
  }

  new BrowserAgent(options)
  window.newrelic.setApplicationVersion(CACHE_BUSTER)
}
setupNewRelic()

export function logNewRelicError(error: any, contextDescription?: string) {
  if (NEWRELIC_DISBABLED) {
    return
  }

  if (error?.length && Array.isArray(error[0]) && error[0].some((err: any) => err.extensions?.errorCode === USER_NOT_VALID_ERROR)) {
    return
  }

  if (ERRORS_TO_MUTE.find((e) => error?.toString().includes(e))) {
    return
  }

  const customerAccountId = Cookies.get('lastAccountId')
  const customerUserId = Cookies.get('lastUserId')
  window.newrelic && window.newrelic.noticeError(error, { contextDescription, customerAccountId, customerUserId })
}

export default {
  logNewRelicError,
}
