import React, { FC } from 'react'

import classNames from 'classnames'

import CaretIcon, { CaretIconDirection } from '@components/CaretIcon'
import DropDown from '@components/DropDown'
import { DropDownType } from '@components/DropDown/DropDown'
import DropDownActions, { MenuItem } from '@components/DropDownActions/DropDownActions'
import { SvgType } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { DATA_PERIOD, dateRangeDropdownText } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.constants'

import './DataCardsHeaderWithDateSelect.css'

interface DataCardsHeaderWithDateSelectProps {
  className?: string
  dataTest?: string
  headerText: string
  dateRangeDropDownOpen: boolean
  dropdownItems: MenuItem[]
  days: DATA_PERIOD
  onDropdownOpen: (value: boolean) => void
  onDropdownClose: VoidFunction
}

const rootClass = 'data-cards-header-with-date-select'

const DataCardsHeaderWithDateSelect: FC<DataCardsHeaderWithDateSelectProps> = (props: DataCardsHeaderWithDateSelectProps) => {
  const { dataTest = rootClass, className = '', dateRangeDropDownOpen, days, dropdownItems, headerText, onDropdownOpen, onDropdownClose } = props
  const { t } = useTranslation()

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Typography text={headerText} weight={TextWeight.MEDIUM} type={TextType.BODY_TEXT_LARGE} />
      <div className={`${rootClass}__dropdown`}>
        <Typography text={`${t('Show')}:`} weight={TextWeight.SEMI_MEDIUM} className={`${rootClass}__dropdown-label`} />
        <DropDown
          dataTest={`${dataTest}-dropdown`}
          className={classNames(rootClass, className)}
          isOpen={dateRangeDropDownOpen}
          toggleOpen={(open) => onDropdownOpen(open)}
          trigger={
            <div className={`${rootClass}__dropdown-trigger`}>
              <Typography text={dateRangeDropdownText[days]} weight={TextWeight.MEDIUM} />
              <CaretIcon direction={CaretIconDirection.DOWN} type={SvgType.SMALL_ICON} isFilled={false} />
            </div>
          }
          align={'end'}
          type={DropDownType.STYLED}
        >
          <DropDownActions closeDropDown={() => onDropdownClose()} menuItems={dropdownItems} />
        </DropDown>
      </div>
    </div>
  )
}

export default DataCardsHeaderWithDateSelect
