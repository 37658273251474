import React, { FC, useEffect, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { Row } from 'react-table'

import classNames from 'classnames'

import NestedTableRow, { NestedTableRowProps } from '@components/ActionableNestedTable/components/NestedTableRow/NestedTableRow'
import { isOverUpperHalf } from '@components/ColumnsOrderModal/utils/ColumnsOrderModal.utils'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { ItemType } from '@utils/categorization'

import './NestedTableRowWithDnD.css'

interface Props {
  className?: string
  dataTest?: string
  selectedRows: Row[]
  onRowsSort: (selectedRows: Row[], droppedOnRow: Row, above: boolean, parentRowId?: string) => void
  canDropCallback: (row: Row, item: Item, itemType: string, isOverTop: boolean) => boolean
  nestedTableRowProps: NestedTableRowProps
  toggleAllRowsSelected: (allSelected: boolean) => void
  itemType?: ItemType
}

const rootClass = 'nested-table-row-dnd'

export enum SelectionType {
  ROWS,
  SUBROWS,
  MIXED,
}

export type Item = {
  rows: Row[]
  selectionType: SelectionType
  toggleAllRowsSelected: (allSelected: boolean) => void
}

const selectionType = (rows: Row[]) => {
  if (rows.some(({ id }) => id.includes('.'))) {
    const pattern = new RegExp(`^${rows[0].id.split('.')[0]}\.\d*`)
    return rows.every(({ id }) => pattern.test(id)) ? SelectionType.SUBROWS : SelectionType.MIXED
  } else {
    return SelectionType.ROWS
  }
}

/**
 * @deprecated use <TableV2 instead
 */
const NestedTableRowWithDnD: FC<Props> = (props: Props) => {
  const {
    dataTest = rootClass,
    onRowsSort,
    selectedRows,
    className = '',
    nestedTableRowProps,
    canDropCallback,
    toggleAllRowsSelected,
    itemType = ItemType.SEGMENT,
  } = props
  const { row } = nestedTableRowProps
  const [isOverTop, setIsOverTop] = useState<boolean>(false)
  const [isHover, setHover] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: itemType,
      item: () => {
        const rows = [...selectedRows]

        if (!selectedRows.includes(row)) {
          row.toggleRowSelected(true)
          rows.push(row)
        }

        return { rows, selectionType: selectionType(rows), toggleAllRowsSelected } as Item
      },
      isDragging: () => selectedRows.includes(row),
      collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    }),
    [selectedRows]
  )

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: itemType,
    drop: (item: Item) => {
      toggleAllRowsSelected(false)
      if (onRowsSort && !item.rows.includes(row)) {
        onRowsSort(item.rows, row, isOverTop, item.selectionType === SelectionType.SUBROWS ? row.id.slice(0, row.id.lastIndexOf('.')) : undefined)
      }
      return undefined
    },
    hover: (_item, monitor) => setIsOverTop(isOverUpperHalf(ref, monitor)),
    canDrop: (item: Item, monitor) =>
      monitor.isOver() && canDropCallback(row, item, monitor.getItemType()?.toString() || '', isOverUpperHalf(ref, monitor)),
    collect: (monitor) => ({ isOver: monitor.isOver(), canDrop: monitor.canDrop() }),
  })

  useEffect(() => {
    // Disable browser d&d preview
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  drop(ref)

  return (
    <div
      ref={ref}
      data-test={dataTest}
      className={classNames(rootClass, className, {
        [`${rootClass}-cannot-drop`]: isOver && !canDrop,
        [`${rootClass}-dragging`]: isDragging,
        [`${rootClass}-over`]: isOver && !isOverTop,
        [`${rootClass}-over-top`]: isOver && isOverTop,
      })}
    >
      <div
        data-test={`${dataTest}-drag-source-${row.id}`}
        className={`${rootClass}-drag-source`}
        ref={drag}
        onFocus={() => setHover(true)}
        onBlur={() => setHover(false)}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Svg name={SvgNames.drag} type={SvgType.ICON} />
      </div>
      <NestedTableRow {...nestedTableRowProps} isHover={isHover} />
    </div>
  )
}

export default NestedTableRowWithDnD
