import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { MessageRecipient } from '@src/pages/EmailComposer/utils/EmailComposer.types'

export const getDefaultSelectedRows = (initial: MessageRecipient[], listContacts: MessageRecipient[]) =>
  Object.fromEntries(
    initial.map((recip) => {
      const rowId = listContacts.findIndex((listContact) => listContact.id === recip.id)
      return [rowId, true]
    })
  )

export const sendDetailsSelectContactsModalColumns: ColumnDefWithAdditionalProps<MessageRecipient, any>[] = [
  {
    header: 'Contact name',
    accessorKey: 'name',
    maxSize: 300,
    textAlign: 'left',
    sortingFn: 'auto',
  },
  {
    header: 'Email',
    accessorKey: 'email',
    textAlign: 'left',
    sortingFn: 'auto',
  },
]
