import React, { FC, useMemo, useState } from 'react'

import classNames from 'classnames'

import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { CRMTableCriteriaType } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/CRMExpression/CRMExpression.interfaces'
import {
  crmTableCategoryMock,
  crmTableDataMock,
} from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/CRMExpression/CRMExpression.mocks'
import DateCriteria, { DateCriteriaType } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/DateCriteria/DateCriteria'
import NumericCriteria, {
  NumericCriteriaType,
} from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/NumericCriteria/NumericCriteria'
import TextCriteria, { TextCriteriaType } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/TextCriteria/TextCriteria'

import './CRMExpression.css'

interface CRMExpressionProps {
  className?: string
  dataTest?: string
}

const rootClass = 'crm-expression'

const CRMExpression: FC<CRMExpressionProps> = (props: CRMExpressionProps) => {
  const { dataTest = rootClass, className = '' } = props

  const tableCategoryOptions = useMemo(() => crmTableCategoryMock.map((category) => ({ label: category, value: category })), [])
  const tableDataOptions = useMemo(() => crmTableDataMock.map(({ name, type }) => ({ label: name, value: name, extraOptions: { type } })), [])

  const [tableCategory, setTableCategory] = useState<SelectV2SingleOption>(tableCategoryOptions[0])
  const [tableData, setTableData] = useState<SelectV2SingleOption<{ type: CRMTableCriteriaType }>>(tableDataOptions[0])
  const [textCriteria, setTextCriteria] = useState<TextCriteriaType>({ criteria: 'EQUALS', values: [] })
  const [numericCriteria, setNumericCriteria] = useState<NumericCriteriaType>({ criteria: 'EQUALS' })
  const [dateCriteria, setDateCriteria] = useState<DateCriteriaType>({ criteria: 'IS_EQUAL' })

  const onTableCategoryChange = (option?: SelectV2SingleOption) => {
    if (option) {
      setTableCategory(option)
    }
  }

  const onTableDataChange = (option?: SelectV2SingleOption) => {
    if (option) {
      setTableData(option as SelectV2SingleOption<{ type: CRMTableCriteriaType }>)
    }
  }

  const onTextCriteriaCreate = (inputValue: string) => {
    if (!textCriteria.values.find((option) => option.label === inputValue)) {
      setTextCriteria({ ...textCriteria, values: [...textCriteria.values, { label: inputValue, value: inputValue }] })
    }
  }

  const onTextCriteriaChange = (criteria: TextCriteriaType) => {
    setTextCriteria(criteria)
  }

  const onNumericCriteriaChange = (criteria: NumericCriteriaType) => {
    setNumericCriteria(criteria)
  }

  const onDateCriteriaChange = (criteria: DateCriteriaType) => {
    setDateCriteria(criteria)
  }

  const renderNextCondition = () => {
    if (tableData?.extraOptions?.type) {
      switch (tableData.extraOptions.type) {
        case 'TEXT':
          return (
            <TextCriteria
              dataTest={`${dataTest}-text-criteria`}
              onChange={onTextCriteriaChange}
              onCreate={onTextCriteriaCreate}
              textCriteria={textCriteria}
            />
          )
        case 'NUMERIC':
          return <NumericCriteria dataTest={`${dataTest}-numeric-criteria`} onChange={onNumericCriteriaChange} numericCriteria={numericCriteria} />
        case 'DATE':
          return <DateCriteria dataTest={`${dataTest}-date-criteria`} onChange={onDateCriteriaChange} dateCriteria={dateCriteria} />
      }
    }
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <SelectV2
        className={`${rootClass}__select`}
        dataTest={`${dataTest}-table-category-select`}
        options={tableCategoryOptions}
        onChange={onTableCategoryChange}
        value={tableCategory}
        isClearable={false}
        isSearchable={false}
      />
      {tableCategory && (
        <SelectV2
          className={`${rootClass}__select`}
          dataTest={`${dataTest}-table-data-select`}
          options={tableDataOptions}
          onChange={onTableDataChange}
          value={tableData}
          isClearable={false}
          isSearchable={false}
        />
      )}
      {renderNextCondition()}
    </div>
  )
}

export default CRMExpression
