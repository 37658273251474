import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Label from '@components/Label'
import Select from '@components/Select/Select'
import { SelectOption } from '@interface/Select'

import './SelectWithSubContent.css'

interface SelectWithSubContentProps {
  children: ReactNode
  options: SelectOption[]
  onSubmit: (approval: string) => void
  label?: ReactNode
  subLabel?: ReactNode
  showSubContent?: boolean
  status?: SelectWithSubContentTypes
  className?: string
  dataTest?: string
  value?: string
  defaultValue?: string
}

export enum SelectWithSubContentTypes {
  Active = 'ACTIVE',
  Caution = 'CAUTION',
  Warning = 'WARNING',
}

const rootClass = 'select-with-sub-content'

const SelectWithSubContent: FC<SelectWithSubContentProps> = (props: SelectWithSubContentProps) => {
  const {
    children,
    options,
    onSubmit,
    label,
    subLabel,
    showSubContent = false,
    status = SelectWithSubContentTypes.Active,
    dataTest = rootClass,
    ...rest
  } = props

  return (
    <div className={rootClass} data-test={dataTest}>
      {!!label && <Label>{label}</Label>}
      <Select dataTest={`${dataTest}-select`} onChange={(event) => onSubmit(event.target.value)} {...rest}>
        {options.map(({ value, label }) => (
          <option key={value} value={value}>
            {label || value}
          </option>
        ))}
      </Select>
      {showSubContent && (
        <div
          className={classNames(`${rootClass}__container`, {
            [`${rootClass}__container--active`]: status === SelectWithSubContentTypes.Active,
            [`${rootClass}__container--caution`]: status === SelectWithSubContentTypes.Caution,
            [`${rootClass}__container--warning`]: status === SelectWithSubContentTypes.Warning,
          })}
        >
          {!!subLabel && <Label>{subLabel}</Label>}
          {children}
        </div>
      )}
    </div>
  )
}

export default SelectWithSubContent
