import getFormsByColumnName from '@graphql/microservices/form/getFormsByColumnName'
import getFormsToMigrate from '@graphql/microservices/form/getFormsToMigrate'
import migrateForms from '@graphql/microservices/form/migrateForms'
import {
  FormDto,
  GetFormsByColumnNameQuery,
  GetFormsByColumnNameQueryVariables,
  GetFormsToMigrateQuery,
  GetFormsToMigrateQueryVariables,
  MigrateFormsMutation,
  MigrateFormsMutationVariables,
} from '@graphql/types/microservice/form-types'
import { filterNotEmptyArray } from '@utils/array'
import { logError } from '@utils/env'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

interface FormServiceRequests {
  getFormsByColumnNameRequest: (columnName: string) => Promise<FormDto[]>
  getFormsToMigrateRequest: () => FetchPromise<GetFormsToMigrateQuery>
  migrateFormsRequest: (formIds: string[]) => FetchPromise<MigrateFormsMutation>
}

export const useFormService = (): FormServiceRequests => {
  const {
    client: { query, mutate },
  } = useMicroserviceClient({ serviceName: MicroserviceClients.FORM })

  const getFormsByColumnNameRequest = async (columnName: string) => {
    const { data, error } = await query<GetFormsByColumnNameQuery, GetFormsByColumnNameQueryVariables>({
      query: getFormsByColumnName,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        columnName,
      },
    })
    if (error) {
      logError(error)
    }
    return data.getFormsByColumnName?.filter(filterNotEmptyArray) ?? []
  }

  const getFormsToMigrateRequest = () => {
    return query<GetFormsToMigrateQuery, GetFormsToMigrateQueryVariables>({
      query: getFormsToMigrate,
      fetchPolicy: 'network-only',
    })
  }

  const migrateFormsRequest = (formIds: string[]) => {
    return mutate<MigrateFormsMutation, MigrateFormsMutationVariables>({
      mutation: migrateForms,
      variables: {
        formIds,
      },
    })
  }

  return {
    getFormsByColumnNameRequest,
    getFormsToMigrateRequest,
    migrateFormsRequest,
  }
}
