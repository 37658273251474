import { ComplexityUIValue, getComplexityUIValues } from './security.utils'

export interface PasswordValidationMessages {
  validationMessage: string
  toasterMessage: string
}

export enum PasswordErrorTypesEnum {
  REUSE = 1,
  QWERTY = 2,
  DICTIONARY = 3,
  OLD_PASSWORD = 4,
}

export const PASSWORD_VALIDATION_MESSAGES = new Map<PasswordErrorTypesEnum, PasswordValidationMessages>([
  [PasswordErrorTypesEnum.REUSE, { validationMessage: `This password has been used already`, toasterMessage: `You can’t reuse recent passwords` }],
  [
    PasswordErrorTypesEnum.QWERTY,
    {
      validationMessage: `Your password can’t contain any common qwerty sequences.`,
      toasterMessage: `Your password can’t contain any common sequences, such as “qwerty,” “1234,” “abcdegf,” etc.`,
    },
  ],
  [
    PasswordErrorTypesEnum.DICTIONARY,
    {
      validationMessage: `Your password can’t contain any common dictionary words.`,
      toasterMessage: `Your password can’t contain any common dictionary words.`,
    },
  ],
  [
    PasswordErrorTypesEnum.OLD_PASSWORD,
    { validationMessage: `This password is invalid`, toasterMessage: `The current password you entered is invalid.` },
  ],
])

export const getErrorMessages = (
  errorTypes: PasswordErrorTypesEnum[],
  defaultErrorMessage: PasswordValidationMessages = {
    validationMessage: `Something went wrong on our end.`,
    toasterMessage: `Something went wrong on our end.`,
  }
): PasswordValidationMessages[] => {
  return errorTypes.map((key: PasswordErrorTypesEnum) => PASSWORD_VALIDATION_MESSAGES.get(key) ?? defaultErrorMessage)
}

export const getComplexityValidators = (key: string, length: number): { (value: string): boolean }[] => {
  return getComplexityUIValues(key, length)
    .filter((v) => v.visible)
    .map((item: ComplexityUIValue) => item.validator)
}
