import { gql } from '@apollo/client'

export default gql`
  query countNonDeliverable($srcId: String) {
    countNonDeliverable(srcId: $srcId) {
      body
      message
      objectId
      statusCode
    }
  }
`
