import React, { useCallback, useContext, useEffect, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import Spinner, { LoaderSize } from '@components/Spinner/Spinner'
import StaticImage from '@components/StaticImage/StaticImage'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Svg, { SvgNames } from '@components/Svg'
import { SvgColor, SvgType } from '@components/Svg/Svg'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Toggle from '@components/Toggle'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextAlign, TextType, TextWeight } from '@components/Typography/Typography'
import { LITMUS_COUNT_URL, LITMUS_TESTING_URL } from '@src/pages/EmailComposer/components/EmailComposerPreview/EmailComposerPreview.utils'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { useEmailComposerRequests } from '@utils/composer/emailComposer/GraphQL/EmailComposerRequests.graphQL'
import { useTranslation } from '@utils/const/globals'
import { logNewRelicError } from '@utils/new-relic.utils'
import { downloadImage } from '@utils/utils'

import EmailComposerPreviewInboxSidebar from './components/EmailComposerPreviewInboxSidebar/EmailComposerPreviewInboxSidebar'

import './EmailComposerPreviewInbox.css'

const rootClass = 'email-composer-preview-inbox'

const EmailComposerPreviewInbox = () => {
  const {
    values: {
      message: { id },
      preview: { loadingLitmusPreviews, selectedLitmusPreview, litmusPreviews },
    },
    api: { updatePreview },
  } = useContext(EmailComposerContext)

  const { t } = useTranslation()

  const { getLitmusPreviewsRequest, getLitmusPreviewBlockedImageRequest } = useEmailComposerRequests()

  const [isToggleOn, setIsToggleOn] = useState<boolean>(false)
  const [imageLoading, setImageLoading] = useState(false)
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false)
  const [blockedImage, setBlockedImage] = useState<string>('')

  const image = isToggleOn && blockedImage ? blockedImage : selectedLitmusPreview?.fullImage
  const currentContactIndex = selectedLitmusPreview ? selectedLitmusPreview.index + 1 : 0
  const isDisabledPrev = !selectedLitmusPreview || selectedLitmusPreview?.index === 0
  const isDisabledNext = !selectedLitmusPreview || currentContactIndex === litmusPreviews.length

  const onToggle = (isOn: boolean) => {
    setIsToggleOn(isOn)
    isOn && setConfirmModalOpen(true)
  }

  const loadLitmusPreviews = useCallback(async () => {
    updatePreview({ loadingLitmusPreviews: true })
    const { data, errors } = await getLitmusPreviewsRequest({ msgId: id, text: false })
    if (errors) {
      logNewRelicError(errors, 'Getting Litmus Previews')
    } else if (data?.getLitmusPreviews) {
      const formattedLitmusPreviews = data?.getLitmusPreviews.previews?.map((preview, index) => ({ ...preview, index })) || []
      updatePreview({ litmusPreviews: formattedLitmusPreviews, loadingLitmusPreviews: false })
    }
  }, [getLitmusPreviewsRequest, id, updatePreview])

  const onConfirmAnswer = useCallback(
    async (answer: YesNo) => {
      const isYes = answer === YesNo.YES
      const regex = /\/v1\/emails\/([^\/]+)\/previews\/([^\/]+)\//
      const match = selectedLitmusPreview?.fullImage?.match(regex)

      if (isYes && match && match[1] && match[2]) {
        setConfirmModalOpen(false)
        setImageLoading(true)
        const { data, errors } = await getLitmusPreviewBlockedImageRequest({
          emailGuid: match[1],
          client: match[2],
        })

        if (errors) {
          logNewRelicError(errors, 'Getting Litmus preview blocked image')
        } else if (data?.getLitmusPreviewBlockedImage) {
          setBlockedImage(data?.getLitmusPreviewBlockedImage)
        }
        setImageLoading(false)
      } else {
        setConfirmModalOpen(false)
        setIsToggleOn(false)
      }
    },
    [getLitmusPreviewBlockedImageRequest, selectedLitmusPreview?.fullImage]
  )

  const onUpdateLitmusPreview = useCallback(async () => {
    const regex = /\/v1\/emails\/([^\/]+)\/previews\/([^\/]+)\//
    const match = selectedLitmusPreview?.fullImage?.match(regex)

    if (selectedLitmusPreview && match && match[2]) {
      setImageLoading(true)
      const { data, errors } = await getLitmusPreviewsRequest({ msgId: id, text: false, client: match[2] })
      if (errors) {
        logNewRelicError(errors, 'Update Litmus Preview')
      } else if (data?.getLitmusPreviews) {
        if (data?.getLitmusPreviews?.previews) {
          const updatedSelectedLitmusPreview = { ...data?.getLitmusPreviews?.previews[0], index: selectedLitmusPreview.index }
          const updatedLitmusPreviews = litmusPreviews.map((preview) => {
            return preview.index === updatedSelectedLitmusPreview.index ? updatedSelectedLitmusPreview : preview
          })
          updatePreview({ litmusPreviews: updatedLitmusPreviews, selectedLitmusPreview: updatedSelectedLitmusPreview })
        }
      }
      setImageLoading(false)
    }
  }, [getLitmusPreviewsRequest, id, litmusPreviews, selectedLitmusPreview, updatePreview])

  const onChange = (index: number) => {
    if (litmusPreviews) {
      const newSelectedLitmusPreview = litmusPreviews[index]
      newSelectedLitmusPreview && updatePreview({ selectedLitmusPreview: newSelectedLitmusPreview })
    }
  }

  useEffect(() => {
    setImageLoading(true)
  }, [selectedLitmusPreview])

  useEffect(() => {
    !selectedLitmusPreview && updatePreview({ selectedLitmusPreview: litmusPreviews[0] })
  }, [selectedLitmusPreview, litmusPreviews])

  useEffect(() => {
    // Load all images before showing
    litmusPreviews.forEach(({ fullImage }) => {
      if (fullImage) {
        const loadImg = new Image()
        loadImg.src = fullImage
      }
    })
  }, [litmusPreviews])

  const emptyState = (
    <div className={`${rootClass}__empty`} data-test={`${rootClass}-empty`}>
      <StaticImage className={`${rootClass}__empty-image`} name={StaticImageNames.inboxPreviewEmpty} />
      <Typography text={t(`EmailComposer.Preview.Inbox.Empty.Title`)} type={TextType.PAGE_HEADLINE} lineHeight={LineHeight.VERY_LARGE} />
      <Typography text={t(`EmailComposer.Preview.Inbox.Empty.Description`)} type={TextType.BODY_TEXT_LIGHT} textAlign={TextAlign.CENTER} />
      <Button
        className={`${rootClass}__empty-button`}
        iconPosition={ButtonIconPosition.LEFT}
        buttonType={ButtonType.PRIMARY}
        onClick={loadLitmusPreviews}
        dataTest={`${rootClass}-empty-state-button`}
      >
        <Svg name={SvgNames.reloadNoFill} type={SvgType.LARGER_ICON} />
        {t(`EmailComposer.Preview.Inbox.Empty.Button`)}
      </Button>
      <TextLink size={TextLinkSize.LARGE} lineHeight={LineHeight.SMALL} onClick={() => window.open(LITMUS_TESTING_URL, '_blank')}>
        {t(`EmailComposer.Preview.Inbox.Empty.Link`)}
      </TextLink>
    </div>
  )

  const loadingState = (
    <div className={`${rootClass}__loading`}>
      <Spinner size={LoaderSize.XLARGE} />
      <Typography text={t(`EmailComposer.Preview.Inbox.Loading.Title`)} type={TextType.PAGE_HEADLINE} lineHeight={LineHeight.LARGER} />
      <Typography text={t(`EmailComposer.Preview.Inbox.Loading.Description`)} type={TextType.BODY_TEXT_LIGHT} textAlign={TextAlign.CENTER} />
    </div>
  )

  return (
    <div className={rootClass} data-test={rootClass}>
      <div className={`${rootClass}__wrapper`}>
        {selectedLitmusPreview && (
          <div className={`${rootClass}__header`} data-test={`${rootClass}-header`}>
            <div className={`${rootClass}__header-title`}>
              <Typography
                className={`${rootClass}__header-title-text`}
                text={selectedLitmusPreview?.title}
                type={TextType.DATA_CARD_MODAL_HEADER}
                weight={TextWeight.MEDIUM}
                dataTest={`${rootClass}-header-title-typography`}
              />
              <Tooltip
                align="start"
                trigger={
                  <Button buttonType={ButtonType.ICON} onClick={onUpdateLitmusPreview}>
                    <Svg name={SvgNames.reloadNoFill} type={SvgType.LARGER_ICON} dataTest={`${rootClass}-header-title-svg`} />
                  </Button>
                }
              >
                {t('EmailComposer.Preview.Inbox.Header.Refresh.Tooltip')}
              </Tooltip>
            </div>
            <div className={`${rootClass}__header-actions`}>
              <Typography text={`Showing ${currentContactIndex} of ${litmusPreviews.length}`} />
              <div className={`${rootClass}__header-buttons`}>
                <Button
                  buttonType={ButtonType.WHITE}
                  disabled={isDisabledPrev}
                  onClick={() => selectedLitmusPreview && onChange(selectedLitmusPreview.index - 1)}
                  dataTest={`${rootClass}-header-buttons-left`}
                >
                  <Svg name={SvgNames.caretLeft} fill={SvgColor.TEXT_GRAY} dataTest={`${rootClass}-header-buttons-left-svg`} />
                </Button>
                <Button
                  buttonType={ButtonType.WHITE}
                  disabled={isDisabledNext}
                  onClick={() => selectedLitmusPreview && onChange(selectedLitmusPreview.index + 1)}
                  dataTest={`${rootClass}-header-buttons-right`}
                >
                  <Svg name={SvgNames.caretRight} fill={SvgColor.TEXT_GRAY} dataTest={`${rootClass}-header-buttons-right-svg`} />
                </Button>
              </div>
            </div>
          </div>
        )}
        <div
          className={classNames(`${rootClass}__container`, {
            'flex-justify-center': (loadingLitmusPreviews && !litmusPreviews.length) || !litmusPreviews.length,
          })}
        >
          {loadingLitmusPreviews && !litmusPreviews.length ? (
            loadingState
          ) : !litmusPreviews.length ? (
            emptyState
          ) : (
            <div className={`${rootClass}__content`}>
              <div className={`${rootClass}__image-container`}>
                {!imageLoading && selectedLitmusPreview && !selectedLitmusPreview?.hasBlockedImage && (
                  <div className={`${rootClass}__image-warning`}>
                    <div className={`${rootClass}__image-warning-circle`}>
                      <Svg name={SvgNames.warningSolidNoFill} type={SvgType.ICON} />
                    </div>
                    <Typography text={t('EmailComposer.Preview.Inbox.Warning.Message')} />
                  </div>
                )}
                {selectedLitmusPreview && imageLoading && <Spinner size={LoaderSize.MEDIUM} />}
                <img
                  className={classNames(`${rootClass}__image`, {
                    [`${rootClass}__image--loading`]: imageLoading,
                  })}
                  alt={selectedLitmusPreview?.platform}
                  src={image}
                  onLoad={() => setImageLoading(false)}
                  data-test={`${rootClass}-image-container-img`}
                />
              </div>
            </div>
          )}
        </div>
        {litmusPreviews.length ? (
          <div className={`${rootClass}__footer`}>
            <div className={`${rootClass}__footer-toggles`}>
              <div className={`${rootClass}__footer-toggles-wrapper`}>
                <Typography
                  type={isToggleOn ? undefined : TextType.BODY_TEXT_GRAY}
                  text={t('Image blocking')}
                  weight={TextWeight.MEDIUM}
                  lineHeight={LineHeight.MEDIUM}
                />
                <Tooltip
                  className={`${rootClass}__footer-tooltip`}
                  alignTextCenter
                  align="start"
                  hide={selectedLitmusPreview?.hasBlockedImage}
                  trigger={
                    <Toggle
                      large
                      isOn={isToggleOn}
                      disabled={!selectedLitmusPreview?.hasBlockedImage || imageLoading}
                      onToggle={(isOn) => onToggle(isOn)}
                      dataTest={`${rootClass}-image-blocking-toggle`}
                    />
                  }
                >
                  {t(`EmailComposer.Preview.Inbox.Toggle.Tooltip`)}
                </Tooltip>
              </div>
            </div>
            <Button
              buttonType={ButtonType.OUTLINE}
              disabled={!selectedLitmusPreview || imageLoading}
              onClick={() => downloadImage(`${image}`, `${selectedLitmusPreview?.title}`)}
            >
              <Svg name={SvgNames.download} type={SvgType.LARGER_ICON} />
              {t('Download')}
            </Button>
          </div>
        ) : null}
      </div>
      {!!litmusPreviews.length && (
        <EmailComposerPreviewInboxSidebar
          loadLitmusPreviews={loadLitmusPreviews}
          resetBlockedImage={() => {
            setIsToggleOn(false)
          }}
        />
      )}
      <ConfirmationModal
        isYesNo
        isOpen={confirmModalOpen}
        title={t('Are you sure?')}
        noButtonText={'Cancel'}
        yesButtonText={'Enable image blocking'}
        body={
          <>
            <Typography
              text={t('EmailComposer.Preview.Inbox.Confiramtion.Desc')}
              type={TextType.BODY_TEXT_LIGHT}
              tagComponents={{
                TextLink: <TextLink link={LITMUS_COUNT_URL} />,
              }}
            />
          </>
        }
        onAnswer={onConfirmAnswer}
      />
    </div>
  )
}

export default EmailComposerPreviewInbox
