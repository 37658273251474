import React, { FC } from 'react'

import classNames from 'classnames'
import { Point, SeriesOptions, TooltipOptions } from 'highcharts'

import { eventsOptions, getBackgroundColor, plotOptions } from '@components/ActivityGaugeChart/ActivityGaugeChartUtils'
import Chart from '@components/Chart/Chart'
import Typography from '@components/Typography/Typography'
import { ChartProps } from '@const/Chart.constants'
import { formatNumber } from '@utils/numbers'

import './ActivityGaugeChart.css'

export interface ActivityGaugeChartProps extends ChartProps {
  maxValue?: number
  actualValue: number
  total: number
  isCPMAccount?: boolean
  showDate?: boolean
  backgroundColor?: string
  showCenterPercentage?: boolean
}

const rootClass = 'activity-gauge-chart'

const ActivityGaugeChart: FC<ActivityGaugeChartProps> = (props: ActivityGaugeChartProps) => {
  const {
    fields,
    dataTest = rootClass,
    className = '',
    maxValue = 100,
    actualValue = 1,
    total = 1,
    isCPMAccount,
    showDate = true,
    backgroundColor,
    showCenterPercentage = true,
  } = props

  const series: SeriesOptions[] = fields.map((field) => ({
    ...field,
    type: 'solidgauge',
    icon: 'circle',
  }))

  const percentage = parseInt(((actualValue / total) * 100).toFixed(0))

  const getTooltipFormat = ({ color, series, y }: Point, showDate?: boolean) => {
    const date = new Date()
    const header = `<tspan style="color:${color}; font-size: larger" x="8" dy="15">&#x25cf;</tspan><b style="line-height: 1 ; font-size: small"> ${series.name}</b>`
    const subheader = `<b style="line-height: 1.5">${formatNumber(y ?? 0)}% (${actualValue} out of ${total})</b>`
    const footer = `${date.toLocaleString('default', { month: 'short' })} 1, ${date.getFullYear()} - Now`

    return `${header}<br>${subheader}${showDate ? `<br>${footer}` : ''}`
  }

  const defaultPane = {
    startAngle: 0,
    endAngle: 360,
    background: [
      {
        outerRadius: '140%',
        innerRadius: '90%',
        backgroundColor: backgroundColor ?? getBackgroundColor(percentage, isCPMAccount),
        borderWidth: 0,
      },
    ],
    size: 50,
  }

  const tooltip: TooltipOptions = {
    backgroundColor: 'rgba(247, 247, 247, 0.85)',
    borderRadius: 8,
    borderWidth: 1.5,
    followPointer: true,
    outside: true,
    pointFormatter: function (this): string {
      return getTooltipFormat(this, showDate)
    },
    shadow: false,
    style: {
      fontSize: '12px',
      height: 56,
      lineWidth: 2,
      padding: '6px 12px 6px 12px',
      width: 164,
    },
    useHTML: true,
    valueSuffix: '%',
  }

  const yAxis = {
    min: 0,
    max: maxValue, // max value % total
    lineWidth: 0,
    tickPositions: [],
  }

  return (
    <div className={`${rootClass}__chart-container`}>
      <div className={`${rootClass}__wrapper`}>
        <div className={`${rootClass}__tooltip-container`}>
          <Typography
            className={classNames(
              `${rootClass}__tooltip-value`,
              { [`${rootClass}__tooltip-value-overflow-supersize`]: percentage >= 10000 },
              { [`${rootClass}__tooltip-value-overflow-oversize`]: percentage >= 1000 },
              { [`${rootClass}__tooltip-value-overflow`]: percentage >= (isCPMAccount ? 100 : 95) },
              { [`${rootClass}__tooltip-value-warning`]: percentage < (isCPMAccount ? 100 : 95) },
              { [`${rootClass}__tooltip-value-default`]: percentage < 90 },
              { [`${rootClass}__tooltip-value-lower`]: percentage < 10 }
            )}
            text={showCenterPercentage ? `${percentage ?? 0}%` : undefined}
            inline
          />
        </div>
        <Chart
          dataTest={dataTest}
          noContainer
          disableMenu
          series={series}
          chartType={'solidgauge'}
          chartHeight={75}
          chartWidth={75}
          alignValue={0}
          className={className}
          xAxis={undefined}
          yAxis={yAxis}
          legend={{ enabled: false }}
          tooltip={tooltip}
          pane={defaultPane}
          events={eventsOptions}
          plotOptions={plotOptions}
        />
      </div>
    </div>
  )
}
export default ActivityGaugeChart
