import React, { ReactNode } from 'react'

import IndeterminateCheckbox from '@components/Table/components/IndeterminateCheckbox'
import { renderCheckboxCell, TableColumn } from '@components/Table/Table'
import Tooltip from '@components/Tooltip/Tooltip'

export const toggleSelectedRowsUtils = (selectedRowIds: Record<string, boolean>, toggleAllRowsSelected: (set: boolean) => void) => {
  const selectedCount = Object.keys(selectedRowIds).filter((key) => selectedRowIds[key]).length
  toggleAllRowsSelected(selectedCount === 0)
}

export const renderCheckboxUtils = (
  useHeaderCheckbox: boolean,
  getToggleAllRowsSelectedProps: any,
  toggleAllRowsSelected: () => void,
  checkboxLabel?: string,
  disabled?: boolean
) => {
  const { title, ...rest } = getToggleAllRowsSelectedProps()
  return useHeaderCheckbox ? (
    <div>
      <IndeterminateCheckbox {...rest} disabled={disabled} onChange={toggleAllRowsSelected} />
    </div>
  ) : (
    <span title={checkboxLabel ? title : ''}>{checkboxLabel ?? ''}</span>
  )
}

const renderCheckboxWithTooltip = (checkBox: ReactNode, disabledCheckboxTooltipText: string) => (
  <Tooltip trigger={checkBox}>{disabledCheckboxTooltipText}</Tooltip>
)

export const getCheckboxColumnUtils = (
  renderCheckboxHeader: ({ getToggleAllRowsSelectedProps }: any) => JSX.Element,
  useHeaderCheckbox: boolean,
  useStickyColumns: boolean,
  rootClass: string,
  checkboxCellOverride?: (row: any) => ReactNode,
  checkboxWidth?: number,
  disabledCheckboxTooltipText?: string
) => {
  const CheckboxColumn: TableColumn = {
    accessor: 'selection',
    align: 'left',
    Header: renderCheckboxHeader,
    disableSortBy: true,
    className: useHeaderCheckbox ? `${rootClass}__use-checkbox` : `${rootClass}__no-header-checkbox`,
    Cell: ({ row }) => {
      if (checkboxCellOverride) {
        return checkboxCellOverride(row)
      } else if (!!disabledCheckboxTooltipText && row.original?.disabled) {
        return renderCheckboxWithTooltip(renderCheckboxCell(row, disabledCheckboxTooltipText), disabledCheckboxTooltipText)
      }
      return renderCheckboxCell(row, disabledCheckboxTooltipText)
    },
    minWidth: checkboxWidth,
    maxWidth: checkboxWidth,
    sticky: useStickyColumns ? 'left' : '',
  }
  return CheckboxColumn
}

export const getStickyColumnUtils = (rootClass: string) => {
  const StickyColumn: TableColumn = {
    Header: '',
    accessor: 'sticky-margin',
    align: 'left',
    className: `${rootClass}__sticky-margin-column`,
    disableSortBy: true,
    minWidth: 24,
    maxWidth: 24,
    sticky: 'left',
  }
  return StickyColumn
}

export const showHeaderActionsUtils = (selectedRowIds: Record<string, boolean>, headerActions: string | any[]) => {
  return headerActions?.length && Object.keys(selectedRowIds).length > 0
}
