import { ComponentClass, ComponentType, FC, lazy } from 'react'

import { rootContext } from '@const/globals'

const PollBlockResponse = lazy(() => import('@src/pages/PollBlockResponse/PollBlockResponse'))

export interface RouteConfig {
  path: string
  component: FC<any> | ComponentClass<any> | ComponentType<any> | null
  noProd?: boolean
  title?: string
  exact?: boolean
}

const allPubicRoutes: RouteConfig[] = [
  {
    path: `${rootContext}/pollBlockResponse`,
    component: PollBlockResponse,
    title: 'Poll Block Response',
  },
]

export default allPubicRoutes
