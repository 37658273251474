import React, { FC, useMemo } from 'react'

import classNames from 'classnames'

import NumberInput from '@components/NumberInput/NumberInput'
import NumberRangeInput from '@components/NumberRangeInput/NumberRangeInput'
import SelectV2 from '@components/SelectV2/SelectV2'
import { useTranslation } from '@const/globals'
import { NumberRange } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/ProfileExpression/ProfileExpression.constants'
import {
  getNumericConditionOptions,
  NumericRangeComparison,
  TextEmptyComparison,
  TextNotEmptyComparison,
} from '@src/pages/SegmentComposer/SegmentComposer.constants'

export interface NumericCriteriaType {
  criteria?: string
  numberRange?: NumberRange
  numberValue?: number
}
export interface NumericCriteriaProps {
  className?: string
  dataTest?: string
  onChange: (numericCriteria: NumericCriteriaType) => void
  numericCriteria: NumericCriteriaType
  symbol?: string
}

const rootClass = 'numeric-criteria'
const defaultNumericConditionIndex = 0
const NumericCriteria: FC<NumericCriteriaProps> = (props: NumericCriteriaProps) => {
  const { dataTest = rootClass, className = '', numericCriteria, onChange, symbol } = props
  const { t } = useTranslation()
  const numericConditionOptions = getNumericConditionOptions(t)
  const { criteria = numericConditionOptions[defaultNumericConditionIndex], numberRange, numberValue } = numericCriteria

  const criteriaOption = useMemo(
    () => numericConditionOptions.find((option) => option.value === criteria) || numericConditionOptions[defaultNumericConditionIndex],
    [criteria, numericConditionOptions]
  )

  const renderNextCondition = () => {
    const { value } = criteriaOption
    if (value in TextEmptyComparison || value in TextNotEmptyComparison) {
      return null
    }
    if (value in NumericRangeComparison) {
      return (
        <NumberRangeInput
          dataTest={`${dataTest}-number-range-input`}
          minValue={numberRange?.rangeStart}
          maxValue={numberRange?.rangeEnd}
          onMinChange={(rangeStart) => onChange({ ...numericCriteria, numberRange: { ...numberRange, rangeStart } })}
          onMaxChange={(rangeEnd) => onChange({ ...numericCriteria, numberRange: { ...numberRange, rangeEnd } })}
          symbol={symbol}
        />
      )
    } else {
      return (
        <NumberInput
          dataTest={`${dataTest}-number-input`}
          value={numberValue}
          onChange={(numberValue) => onChange({ ...numericCriteria, numberValue })}
          symbol={symbol}
        />
      )
    }
  }

  return (
    <>
      <SelectV2
        className={classNames(`${rootClass}__numeric-criteria-select`, className)}
        dataTest={`${dataTest}-criteria-select`}
        options={numericConditionOptions}
        value={criteriaOption}
        onChange={(criteria) => onChange({ ...numericCriteria, criteria: criteria?.value })}
        isClearable={false}
        isSearchable={false}
      />
      {renderNextCondition()}
    </>
  )
}

export default NumericCriteria
