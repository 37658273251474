import { useApolloClient } from '@apollo/client'
import { getSegmentContacts } from '@components/ContactsDetails/utils/ContactsDetails.utils'
import auditList from '@graphql/microservices/categorization/auditList'
import getItem from '@graphql/microservices/categorization/getItem'
import fetchFormSubmissionsForCards from '@graphql/microservices/data-lake-query/fetchFormSubmissionsForCards'
import getFtpConnectionStatus from '@graphql/microservices/entity-upload/getFtpConnectionStatus'
import getFtpFiles from '@graphql/microservices/entity-upload/getFtpFiles'
import getLastSegmentExecution from '@graphql/microservices/entity-upload/getLastSegmentExecution'
import getLastSegmentExportExecution from '@graphql/microservices/entity-upload/getLastSegmentExportExecution'
import listExportSyncJobBySegmentId from '@graphql/microservices/entity-upload/listExportSyncJobBySegmentId'
import download from '@graphql/microservices/file-upload/download'
import retrieveAttachments from '@graphql/microservices/file-upload/retrieveAttachments'
import cloneSegment from '@graphql/microservices/segment/cloneSegment'
import { default as deleteAllContactsMutation } from '@graphql/microservices/segment/deleteAllContacts'
import getSegmentFilter from '@graphql/microservices/segment/getSegmentFilter'
import getStatSendableContacts from '@graphql/microservices/segment/getStatSendableContacts'
import regenerateSplits from '@graphql/microservices/segment/regenerateSplits'
import createWebinarSegments from '@graphql/mutations/createWebinarSegments'
import refreshRecordCount from '@graphql/mutations/refreshRecordCount'
import { default as getFormPreviewQuery } from '@graphql/queries/getFormPreview'
import isWebinarCompleted from '@graphql/queries/isWebinarCompleted'
import { AuditListQuery, AuditListQueryVariables, GetItemQuery, GetItemQueryVariables } from '@graphql/types/microservice/categorization-types'
import { FetchFormSubmissionsForCardsQuery, FetchFormSubmissionsForCardsQueryVariables } from '@graphql/types/microservice/data-lake-query-types'
import { FormSubmissionSummaryDto, WebinarType, WebinarAttendanceData } from '@graphql/types/microservice/entity-join-types'
import {
  ExportSyncJobDto,
  FtpFileDto,
  GetFtpConnectionStatusQuery,
  GetFtpConnectionStatusQueryVariables,
  GetFtpFilesQuery,
  GetFtpFilesQueryVariables,
  GetLastSegmentExecutionQuery,
  GetLastSegmentExecutionQueryVariables,
  GetLastSegmentExportExecutionQuery,
  GetLastSegmentExportExecutionQueryVariables,
  ListExportSyncJobBySegmentIdQuery,
  ListExportSyncJobBySegmentIdQueryVariables,
} from '@graphql/types/microservice/entity-upload-types'
import {
  DownloadQuery,
  QueryDownloadArgs,
  RetrieveAttachmentsQuery,
  RetrieveAttachmentsQueryVariables,
} from '@graphql/types/microservice/file-upload-types'
import { GetStatSendableContactsQuery, GetStatSendableContactsQueryVariables, StatValueDto } from '@graphql/types/microservice/segment-types'
import {
  GetSegmentFilterQuery,
  GetSegmentFilterQueryVariables,
  DeleteAllContactsMutation,
  DeleteAllContactsMutationVariables,
  RegenerateSplitsMutation,
  RegenerateSplitsMutationVariables,
  CloneSegmentMutation,
  CloneSegmentMutationVariables,
} from '@graphql/types/microservice/segment-types'
import {
  CreateWebinarSegmentsMutation,
  CreateWebinarSegmentsMutationVariables,
  RefreshRecordCountMutation,
  RefreshRecordCountMutationVariables,
} from '@graphql/types/mutation-types'
import {
  GetFormPreviewQuery,
  GetFormPreviewQueryVariables,
  IsWebinarCompletedQuery,
  IsWebinarCompletedQueryVariables,
} from '@graphql/types/query-types'
import { DATA_PERIOD } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.constants'
import { filterNotEmptyArray } from '@utils/array'
import { ItemType } from '@utils/categorization'
import { FtpExecutionStatus, FtpExportExecutionStatus } from '@utils/contactSegments/contactSegments.utils'
import { getTimeStampPastDate } from '@utils/date'
import { logError } from '@utils/env'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'
export type DuplicateSegmentRequest = (
  segmentInput: Omit<CloneSegmentMutationVariables, 'type' | 'subSegments'>
) => FetchPromise<CloneSegmentMutation>

export type WebinarRelatedFormsRequest = (webinarId: string, webinarType: WebinarType) => Promise<FormSubmissionSummaryDto[]>

export type RelatedFormsRequest = (id: string) => Promise<FormSubmissionSummaryDto[]>

export type WebinarCompletedRequest = (sessionKey: string, webinarType: WebinarType) => Promise<boolean>

export type WebinarAttendanceDataRequest = (webinarId: string, webinarType: WebinarType) => Promise<WebinarAttendanceData>

export interface FormsJoinViewListingPageRequests {
  createWebinarSegmentsRequest: (variables: CreateWebinarSegmentsMutationVariables) => FetchPromise<CreateWebinarSegmentsMutation>
  duplicateSegment: DuplicateSegmentRequest
  getFormPreview: (formId: string) => FetchPromise<GetFormPreviewQuery>
  regenerateSplit: (id: string) => FetchPromise<RegenerateSplitsMutation>
  getActivitiesAudits: (formId: string, type?: ItemType, page?: number, pageSize?: number) => Promise<AuditListQuery>
  refreshRecords: (listIds: string[], type: ItemType) => FetchPromise<RefreshRecordCountMutation>
  deleteAllContacts: (ids: string[]) => FetchPromise<DeleteAllContactsMutation>
  getSegmentDefinitionRequest: (externalId: string) => FetchPromise<GetSegmentFilterQuery>
  getItemDetailsRequest: (type: ItemType, itemId?: number | string, externalId?: string) => FetchPromise<GetItemQuery>
  getSendableContactsRequest: (externalId: string) => Promise<StatValueDto>
  getAllFormsData: (days: DATA_PERIOD) => Promise<FetchFormSubmissionsForCardsQuery>
  getExportSyncJobListRequest: (segmentId?: string) => Promise<ExportSyncJobDto[]>
  getLastSegmentExecutionRequest: (allContactsId?: string) => Promise<Record<string, FtpExecutionStatus>>
  getLastSegmentExportExecutionRequest: (allContactsId?: string) => Promise<Record<string, FtpExportExecutionStatus>>
  getFtpConnectionStatusRequest: () => Promise<boolean>
  getFtpFilesRequest: () => Promise<FtpFileDto[]>
  getWebinarCompletedRequest: WebinarCompletedRequest
  retrieveAttachmentsById: (variables: RetrieveAttachmentsQueryVariables) => FetchPromise<RetrieveAttachmentsQuery>
  getPreviewOrDownloadUrl: (variables: QueryDownloadArgs) => FetchPromise<DownloadQuery>
  getSegmentContactsRequest: (id: string, itemType: ItemType, isActiveBounceSegment: boolean, pageSize?: number) => Promise<string[]>
}

export const useFormsJoinViewListingPageRequests = (): FormsJoinViewListingPageRequests => {
  const actonClient = useApolloClient()
  const { client: segmentClient } = useMicroserviceClient({ serviceName: MicroserviceClients.SEGMENT })
  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })
  const { client: dataLakeClient } = useMicroserviceClient({ serviceName: MicroserviceClients.DATA_LAKE_QUERY })
  const { client: entityUploadClient } = useMicroserviceClient({ serviceName: MicroserviceClients.ENTITY_UPLOAD })
  const { client: fileUploadClient } = useMicroserviceClient({ serviceName: MicroserviceClients.FILE_UPLOAD_PROXY })

  const getSegmentContactsRequest = (id: string, itemType: ItemType, isActiveBounceSegment: boolean, pageSize = 1) =>
    getSegmentContacts(
      {
        id,
        pageSize,
        pageNumber: 0,
        search: '',
      },
      segmentClient,
      isActiveBounceSegment,
      itemType === ItemType.FORM_SUBMISSION
    ).then((data) => data.headers as string[])

  const createWebinarSegmentsRequest = (variables: CreateWebinarSegmentsMutationVariables) => {
    return actonClient.mutate<CreateWebinarSegmentsMutation, CreateWebinarSegmentsMutationVariables>({
      mutation: createWebinarSegments,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables,
    })
  }

  const deleteAllContacts = (segmentIds: string[]) =>
    segmentClient.mutate<DeleteAllContactsMutation, DeleteAllContactsMutationVariables>({
      mutation: deleteAllContactsMutation,
      variables: {
        segmentIds,
      },
    })

  const duplicateSegment = (segmentInput: CloneSegmentMutationVariables) =>
    segmentClient.mutate<CloneSegmentMutation, CloneSegmentMutationVariables>({
      mutation: cloneSegment,
      variables: {
        ...segmentInput,
        type: ItemType.FORM_SUBMISSION,
        subSegments: false,
      },
    })

  const getAllFormsData = async (days: DATA_PERIOD) => {
    return await dataLakeClient
      .query<FetchFormSubmissionsForCardsQuery, FetchFormSubmissionsForCardsQueryVariables>({
        query: fetchFormSubmissionsForCards,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        variables: {
          afterTimestamp: getTimeStampPastDate(days),
        },
      })
      .then(({ data }) => data.fetchFormSubmissionsForCards as FetchFormSubmissionsForCardsQuery)
  }

  const getFormPreview = (formId: string) =>
    actonClient.query<GetFormPreviewQuery, GetFormPreviewQueryVariables>({
      query: getFormPreviewQuery,
      errorPolicy: 'all',
      variables: {
        formId,
      },
      fetchPolicy: 'no-cache',
    })

  const regenerateSplit = (id: string) =>
    segmentClient.mutate<RegenerateSplitsMutation, RegenerateSplitsMutationVariables>({
      mutation: regenerateSplits,
      errorPolicy: 'all',
      variables: {
        srcId: id,
        sendToCategorizationService: true,
      },
    })

  const getActivitiesAudits = (externalId: string, type = ItemType.FORM_SUBMISSION, page = 0, pageSize = 50) =>
    categorizationClient
      .query<AuditListQuery, AuditListQueryVariables>({
        query: auditList,
        variables: {
          externalId,
          pageSize,
          pageNumber: page,
          type,
        },
      })
      .then(({ data }) => data.auditList as AuditListQuery)

  const refreshRecords = (listIds: string[], type: ItemType) =>
    actonClient.mutate<RefreshRecordCountMutation, RefreshRecordCountMutationVariables>({
      mutation: refreshRecordCount,
      variables: {
        listIds,
        type,
      },
    })

  const getSegmentDefinitionRequest = (externalId: string) =>
    segmentClient.query<GetSegmentFilterQuery, GetSegmentFilterQueryVariables>({
      query: getSegmentFilter,
      variables: {
        segmentId: externalId,
      },
    })

  const getItemDetailsRequest = (type: ItemType, itemId?: number | string, externalId?: string) =>
    categorizationClient.query<GetItemQuery, GetItemQueryVariables>({
      query: getItem,
      fetchPolicy: 'network-only',
      variables: {
        itemId,
        externalId,
        type,
      },
    })

  const getSendableContactsRequest = (externalId: string) =>
    segmentClient
      .query<GetStatSendableContactsQuery, GetStatSendableContactsQueryVariables>({
        query: getStatSendableContacts,
        variables: {
          externalId,
        },
      })
      .then(({ data }) => data.getStatSendableContacts as StatValueDto)

  const getExportSyncJobListRequest = (segmentId?: string) =>
    entityUploadClient
      .query<ListExportSyncJobBySegmentIdQuery, ListExportSyncJobBySegmentIdQueryVariables>({
        query: listExportSyncJobBySegmentId,
        variables: {
          segmentId,
        },
      })
      .then(
        ({ data }) =>
          (data.listExportSyncJobBySegmentId?.filter((syncJob) => filterNotEmptyArray(syncJob) && syncJob.enabled) as ExportSyncJobDto[]) ?? []
      )

  const getLastSegmentExecutionRequest = (allContactsId?: string) =>
    entityUploadClient
      .query<GetLastSegmentExecutionQuery, GetLastSegmentExecutionQueryVariables>({
        query: getLastSegmentExecution,
        variables: {
          allContactsId,
        },
      })
      .then(({ data }) => {
        const ids = Object.keys(data.getLastSegmentExecution)
        const executions: Record<string, FtpExecutionStatus> = ids.reduce((mappedIds: Record<string, FtpExecutionStatus>, id: string) => {
          mappedIds[id] = {
            lastExecution: data.getLastSegmentExecution[id].lastExecution,
            running: data.getLastSegmentExecution[id].running,
            status: data.getLastSegmentExecution[id].status,
            previewImportSyncJobs: data.getLastSegmentExecution[id].previewImportSyncJobs,
          }
          return mappedIds
        }, {})
        return executions
      })

  const getLastSegmentExportExecutionRequest = (allContactsId?: string) =>
    entityUploadClient
      .query<GetLastSegmentExportExecutionQuery, GetLastSegmentExportExecutionQueryVariables>({
        query: getLastSegmentExportExecution,
        variables: {
          allContactsId,
        },
      })
      .then(({ data }) => {
        const ids = Object.keys(data.getLastSegmentExportExecution)
        const executions: Record<string, FtpExportExecutionStatus> = ids.reduce((mappedIds: Record<string, FtpExportExecutionStatus>, id: string) => {
          mappedIds[id] = {
            lastExecution: data.getLastSegmentExportExecution[id].lastExecution,
            running: data.getLastSegmentExportExecution[id].running,
            status: data.getLastSegmentExportExecution[id].status,
            previewExportSyncJobs: data.getLastSegmentExportExecution[id].previewExportSyncJobs,
          }
          return mappedIds
        }, {})
        return executions
      })

  const getFtpConnectionStatusRequest = () =>
    entityUploadClient
      .query<GetFtpConnectionStatusQuery, GetFtpConnectionStatusQueryVariables>({
        fetchPolicy: 'network-only',
        query: getFtpConnectionStatus,
      })
      .then(({ data }) => data.getFtpConnectionStatus)

  const getFtpFilesRequest = () =>
    entityUploadClient
      .query<GetFtpFilesQuery, GetFtpFilesQueryVariables>({
        fetchPolicy: 'network-only',
        query: getFtpFiles,
      })
      .then(({ data }) => data.getFtpFiles as FtpFileDto[])

  const getWebinarCompletedRequest = (sessionKey: string, webinarType: WebinarType) =>
    actonClient
      .query<IsWebinarCompletedQuery, IsWebinarCompletedQueryVariables>({
        query: isWebinarCompleted,
        fetchPolicy: 'network-only',
        variables: {
          sessionKey,
          webinarType,
        },
      })
      .then(({ data }) => data.isWebinarCompleted as boolean)

  const retrieveAttachmentsById = (variables: RetrieveAttachmentsQueryVariables) =>
    fileUploadClient
      .query<RetrieveAttachmentsQuery, RetrieveAttachmentsQueryVariables>({
        query: retrieveAttachments,
        fetchPolicy: 'network-only',
        variables,
      })
      .catch((errors) => {
        logError(errors)
        return { data: undefined, errors: errors }
      })

  const getPreviewOrDownloadUrl = (variables: QueryDownloadArgs) =>
    fileUploadClient
      .query<DownloadQuery, QueryDownloadArgs>({
        query: download,
        fetchPolicy: 'network-only',
        variables,
      })
      .catch((errors) => {
        logError(errors)
        return { data: undefined, errors: errors }
      })

  return {
    createWebinarSegmentsRequest,
    duplicateSegment,
    getFormPreview,
    regenerateSplit,
    getActivitiesAudits,
    refreshRecords,
    deleteAllContacts,
    getSegmentDefinitionRequest,
    getItemDetailsRequest,
    getSendableContactsRequest,
    getAllFormsData,
    getExportSyncJobListRequest,
    getLastSegmentExecutionRequest,
    getLastSegmentExportExecutionRequest,
    getFtpConnectionStatusRequest,
    getFtpFilesRequest,
    getWebinarCompletedRequest,
    retrieveAttachmentsById,
    getPreviewOrDownloadUrl,
    getSegmentContactsRequest,
  }
}
