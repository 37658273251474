import React, { FC, useState } from 'react'
import { useController, UseFormReturn } from 'react-hook-form'

import AddressModalContainer from '@components/AddressModal/AddressModalContainer'
import Button, { ButtonType } from '@components/Button'
import Checkbox from '@components/Checkbox/Checkbox'
import CheckboxGroup from '@components/CheckboxGroup/CheckboxGroup'
import InputV2 from '@components/InputV2/InputV2'
import { LabelType, LabelV2 } from '@components/LabelV2/LabelV2'
import { SelectV2GroupedOption, SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import SelectV2Popover from '@components/SelectV2Popover/SelectV2Popover'
import Svg, { SvgNames } from '@components/Svg'
import { SvgColor, SvgContainerType, SvgType } from '@components/Svg/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { URL_VALIDATION_MESSAGES, validateEventUrl } from '@src/pages/EmailComposer/EmailModals/components/EventModal/utils/EventModal.utils'
import { CustomAddonModalForm } from '@src/pages/EmailComposer/utils/Addons/customAddons.types'
import { checkURLValidity } from '@utils/formUtils'
import { renderBoldTextOnFirstMatch } from '@utils/typography'

import './LocationDetails.css'

interface LocationDetailsV2Props {
  form: UseFormReturn<CustomAddonModalForm>
  addressOptions: SelectV2GroupedOption[]
  refetchAddressOptions: Function
  className?: string
  dataTest?: string
}

const rootClass = 'location-details'

const LocationDetails: FC<LocationDetailsV2Props> = (props: LocationDetailsV2Props) => {
  const { form, addressOptions, refetchAddressOptions } = props

  const { t } = useTranslation()

  const [addressModalOpen, setAddressModalOpen] = useState<boolean>(false)
  const [addressSelectOpen, setAddressSelectOpen] = useState<boolean>(false)
  const [registrationLinkError, setRegistrationLinkError] = useState<string | undefined>()

  const { field: selectedAddress } = useController({ control: form.control, name: 'locationDetails.selectedAddress' })
  const { field: roomDetails } = useController({ control: form.control, name: 'locationDetails.roomDetails' })
  const { field: includeMapToAddress } = useController({ control: form.control, name: 'locationDetails.includeMapToAddress' })
  const { field: includeRegistration } = useController({ control: form.control, name: 'locationDetails.includeRegistration' })
  const { field: registrationLabel } = useController({ control: form.control, name: 'locationDetails.registrationLabel' })
  const { field: registrationLink } = useController({
    control: form.control,
    name: 'locationDetails.registrationLink',
    rules: {
      validate: includeRegistration.value ? (url: string) => validateEventUrl(url, t, setRegistrationLinkError) : undefined,
    },
  })

  const {
    locationDetails: { includeRegistration: includeRegistrationIsChecked },
  } = form.getValues()

  const isUserAddress = selectedAddress?.value?.label?.includes('User Address')
  const isDefaultAdddress = selectedAddress?.value?.label?.includes('Default Account Address')
  const buttonProps = { buttonType: ButtonType.INFO, className: `${rootClass}__select-popover__trigger` }

  const renderMenuAction = () => (
    <Button
      buttonType={ButtonType.OUTLINE}
      fullWidth
      onClick={() => {
        setAddressModalOpen(true)
        setAddressSelectOpen(false)
      }}
    >
      <Svg name={SvgNames.addLocation} type={SvgType.LARGER_ICON} containerType={SvgContainerType.STANDARD} />
      {t('Add an address')}
    </Button>
  )

  const renderAnchor = () => (
    <div className={`${rootClass}__select-popover-selected`}>
      <div className={`${rootClass}__select-popover-selected-icon`}>
        <Svg
          name={isUserAddress ? SvgNames.userCircle : isDefaultAdddress ? SvgNames.buildings : SvgNames.location}
          fill={SvgColor.TEXT_TEAL}
          type={SvgType.SELECTV2_OPTION_ICON}
          containerType={SvgContainerType.LARGE}
        />
      </div>
      <div>
        <LabelV2 label={selectedAddress?.value?.label} labelType={LabelType.medium} required />
        <Typography text={selectedAddress?.value?.subText} type={TextType.BODY_TEXT_SMALL_LIGHT} />
      </div>
    </div>
  )

  const renderCustomOption = (data: SelectV2SingleOption, search?: string) => (
    <>
      <Typography
        inline
        dataTest={'selectv2-option-value'}
        text={search ? renderBoldTextOnFirstMatch({ search, value: data.label }) : data.label}
        weight={TextWeight.MEDIUM}
      />
      {data.subText && <Typography text={data.subText} type={TextType.BODY_TEXT_LIGHT} lineHeight={LineHeight.MEDIUM_SMALL} />}
    </>
  )

  return (
    <div className={rootClass}>
      {addressModalOpen ? (
        <AddressModalContainer
          loading={false}
          onCreateAddress={() => refetchAddressOptions(true)}
          isOpen={addressModalOpen}
          handleModalClose={() => {
            setAddressModalOpen(false)
            setAddressSelectOpen(true)
          }}
        />
      ) : (
        <div className={`${rootClass}__select-card`}>
          <SelectV2Popover
            renderTrigger={() => <Button {...buttonProps}>{t('Change')}</Button>}
            renderAnchor={renderAnchor}
            selectProps={{
              groupedOptions: addressOptions,
              placeholder: t('Search addresses'),
              onChange: selectedAddress.onChange,
              insideModal: true,
              value: selectedAddress.value,
              renderCustomOption,
              renderMenuAction,
              menuIsOpen: addressSelectOpen,
              optionsWithLargePadding: true,
              minSearchOptions: 0,
            }}
            className={`${rootClass}__select-popover`}
          />
          <InputV2
            placeholder={t('EmailComposer.EventModal.AddRoomDetails')}
            leadingIcon={SvgNames.room}
            value={roomDetails.value}
            onChange={roomDetails.onChange}
          />
          <CheckboxGroup>
            <Checkbox
              label={t('EmailComposer.EventModal.IncludeMapToAddress')}
              onChange={includeMapToAddress.onChange}
              checked={includeMapToAddress.value}
            />
            <Checkbox
              label={t('EmailComposer.EventModal.IncludeRegistration')}
              dataTest={`${rootClass}-include-registration`}
              onChange={includeRegistration.onChange}
              checked={includeRegistration.value}
            />
          </CheckboxGroup>
          {includeRegistrationIsChecked && (
            <>
              <div>
                <InputV2
                  leadingIcon={undefined}
                  labelProps={{ label: t('EmailComposer.EventModal.RegistrationButtonText'), labelType: LabelType.medium }}
                  placeholder={t('EmailComposer.EventModal.RegistrationButtonText.Placeholder')}
                  value={registrationLabel.value}
                  onChange={registrationLabel.onChange}
                />
              </div>
              <div>
                <InputV2
                  placeholder={'https://'}
                  leadingIcon={SvgNames.linkedUnselected}
                  labelProps={{ label: t('EmailComposer.EventModal.RegistrationButtonLink.Label'), labelType: LabelType.medium }}
                  value={registrationLink.value}
                  onChange={registrationLink.onChange}
                  error={!!registrationLinkError}
                  statusProps={{ showErrorOnChange: true, validityFunctions: [checkURLValidity], tooltipErrorMessages: URL_VALIDATION_MESSAGES }}
                  inputInfo={{ enabled: !!registrationLinkError, errorText: registrationLinkError }}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default LocationDetails
