import React, { FC, useCallback, useMemo } from 'react'

import classNames from 'classnames'

import EmptyListing from '@components/EmptyListing/EmptyListing'
import Loader from '@components/Loader/Loader'
import PageContainer from '@components/PageContainer/PageContainer'
import PageHeader from '@components/PageHeader/PageHeader'
import { useTranslation } from '@const/globals'
import AnalyticsUpsell from '@src/pages/reports/components/AnalyticsUpsell/AnalyticsUpsell'
import AnalyticsUpsellInfoHover from '@src/pages/reports/components/AnalyticsUpsellInfoHover/AnalyticsUpsellInfoHover'
import { Action, LiveboardEmbed, RuntimeFilter } from '@thoughtspot/visual-embed-sdk/react'

import './Liveboard.css'

interface LiveboardProps {
  title: string
  liveboardId?: string
  queryError?: string
  visibleActions?: Action[]
  headerExtra?: React.ReactNode
  runtimeFilters?: RuntimeFilter[]
  hideAnalyticsUpsell: boolean
  onDismiss: () => void
  previewAction?: (id: string, title: string) => void
  loading: boolean
  className?: string
  dataTest?: string
}

const VISIBLE_ACTIONS = [
  Action.LiveboardInfo,
  Action.Present,
  Action.Explore,
  Action.Download,
  Action.DownloadAsCsv,
  Action.DownloadAsXlsx,
  Action.DownloadAsPdf,
  Action.DownloadAsPng,
  Action.CrossFilter,
  Action.RemoveCrossFilter,
  Action.DrillDown,
  Action.DrillExclude,
  Action.DrillInclude,
  Action.AnswerChartSwitcher,
]

const rootClass = 'liveboard'

const Liveboard: FC<LiveboardProps> = (props: LiveboardProps) => {
  const {
    title,
    visibleActions,
    previewAction,
    liveboardId,
    headerExtra,
    runtimeFilters,
    loading,
    queryError,
    hideAnalyticsUpsell,
    onDismiss,
    dataTest = rootClass,
    className = '',
  } = props
  const { t } = useTranslation()

  const allVisibleActions = useMemo(() => (visibleActions ? [...VISIBLE_ACTIONS, ...visibleActions] : VISIBLE_ACTIONS), [visibleActions])

  // NOTE: Leaving these for later use...
  // const onInit = useCallback(() => {
  //   // eslint-disable-next-line no-console
  //   console.log(EmbedEvent.Init, {})
  // }, [])
  // const onLoad = useCallback(() => {
  //   // eslint-disable-next-line no-console
  //   console.log(EmbedEvent.Load, {})
  // }, [])

  const onCustomAction = useCallback(
    (e: any) => {
      if (e.data.id === 'show-message-preview' && previewAction) {
        const selectedValue = e.data.contextMenuPoints.clickedPoint.selectedAttributes[0].value
        let msgColumnPosition = -1
        e.data.embedAnswerData.columns.forEach((col: any, idx: number) => {
          if (col.column.name === 'Message Id') {
            msgColumnPosition = idx
          }
        })
        let row = -1
        e.data.embedAnswerData.data[0].columnDataLite[0].dataValue.forEach((val: string, idx: number) => {
          // Only find the first message with this name...
          if (row === -1 && val === selectedValue) {
            row = idx
          }
        })
        const msgId = row > -1 ? e.data.embedAnswerData.data[0].columnDataLite[msgColumnPosition].dataValue[row] : -1
        previewAction(msgId, selectedValue)
      }
    },
    [previewAction]
  )

  // const onFiltersChanged = useCallback((e: any) => {
  //   console.log('onFiltersChanged: ', e)
  // }, [])

  return (
    <PageContainer noPadding className={classNames(rootClass, className)} dataTest={dataTest}>
      <PageHeader primaryText={title} className={`${rootClass}__header`}>
        {!loading && headerExtra}
        {!loading && hideAnalyticsUpsell && <AnalyticsUpsellInfoHover className={`${rootClass}__header-upsell`} />}
      </PageHeader>
      {loading && <Loader center />}
      {!loading && queryError && <EmptyListing headline={t('Unable to load dashboard')} text={queryError} />}
      {!loading && !queryError && liveboardId && (
        <>
          {!hideAnalyticsUpsell && <AnalyticsUpsell onDismiss={onDismiss} />}
          <LiveboardEmbed
            fullHeight
            liveboardId={liveboardId}
            onCustomAction={onCustomAction}
            runtimeFilters={runtimeFilters}
            // onFilterChanged={onFiltersChanged}
            visibleActions={allVisibleActions}
          />
        </>
      )}
    </PageContainer>
  )
}

export default Liveboard
