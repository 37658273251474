import { gql } from '@apollo/client'

export default gql`
  mutation editUser($personalInfo: OverviewManageProfile!, $id: String!) {
    editUser(personalInfo: $personalInfo, id: $id) {
      status
      message
      count
      deletedUsersIds
      newUsersIds
      importedUsers
      noImportedUsers
      duplicatedUsers
    }
  }
`
