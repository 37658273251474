import { gql } from '@apollo/client'

export default gql`
  query getMessageDetails($launchId: String!) {
    getMessageDetails(launchId: $launchId) {
      messageText
      messageTitle
      description
      sentBy
      sentDate
      contactLists {
        listName
        listType
        totalContacts
      }
      noPhoneNumbers
      duplicates
      messagesSent
      notOptIns
      billableCredits
      failedPersonalizationCount
      recipientsPerCreditMultiplier {
        creditMultiplier
        contacts
        contactsPerCountry {
          country
          contacts
        }
      }
      creditsCharged
      suppressedInternationalContacts
    }
  }
`
