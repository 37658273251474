import React, { FC, ReactNode, UIEvent, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Row } from 'react-table'

import classNames from 'classnames'

import AddToSegmentModal from '@components/AddToSegmentModal/AddToSegmentModal'
import ColumnsOrderModal from '@components/ColumnsOrderModal/ColumnsOrderModal'
import { Column } from '@components/ColumnsOrderModal/components/DraggableColumn/DraggableColumn'
import { YesNo } from '@components/ConfirmationModal'
import { mapContactIdToUuid, uuidColumns } from '@components/ContactsDetails/utils/ContactsDetails.utils'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import { MenuItem } from '@components/DropDownActions/DropDownActions'
import EmptyListing from '@components/EmptyListing/EmptyListing'
import Search, { SearchType } from '@components/Search/Search'
import { Status } from '@components/StatusToast/StatusToast'
import SvgNames from '@components/Svg/SvgNames'
import { HeaderAction, RowAction } from '@components/Table/Table'
import { MultipleTables } from '@components/TableV2/components/MultipleTables/MultipleTables'
import TableWithMenu from '@components/TableWithMenu/TableWithMenu'
import Typography, { ModalBodyStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import {
  contactDetailsEmptyStateData,
  formatContactsUtils,
  getColumnsHeadersUtils,
  getColumnsHeadersUtilsV2,
  getColumnsUtils,
  getContactDetailsEmptyState,
  getTableLabel,
  onSendEmailClick,
  saveColumnsUtils,
} from '@src/pages/ContactSegments/components/ContactsDetails/utils/ContactsDetails.utils'
import {
  getBouncesContactsHeaderActions,
  getBouncesContactsRowActions,
} from '@src/pages/ContactSegments/components/ContactSegmentsActions/ContactPreferencesActions/BouncesContacts.actions'
import {
  ContactsTableActions,
  getContactsHeaderActions,
  getContactsRowActions,
} from '@src/pages/ContactSegments/components/ContactSegmentsActions/Contacts.actions'
import {
  getChangeBounceStatusConfirmationModal,
  onRemoveFromBounce,
} from '@src/pages/ContactSegments/utils/ContactPreferences/ContactPreferences.utils'
import { deleteContactsUtils, getSegmentContacts, removeContactsFromSegmentUtils } from '@src/pages/ContactSegments/utils/Contacts.utils'
import { CONTACTS_DETAILS_URL } from '@src/pages/ContactSegments/utils/ContactSegmentsSession.utils'
import { Row as RowV2 } from '@tanstack/react-table'
import { useAccountSettings } from '@utils/account/account.utils'
import { BouncesSegments, bouncesVerbs } from '@utils/categorization'
import { Contact } from '@utils/contact/contact.constants'
import { saveSelectedContactsToLocalStorage, Segment } from '@utils/contactSegments/contactSegments.utils'
import { ContactSegmentsContext } from '@utils/contactSegments/context/ContactSegmentsContext'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { useUnifiedContactList } from '@utils/hooks/useUnifiedContactList'
import useWindowSize from '@utils/hooks/useWindowSize'

import './ContactsDetails.css'

interface Props {
  className?: string
  dataTest?: string
  segment: Segment
  isBounceSegment?: boolean
}

interface ConfirmationModalData {
  body: string | ReactNode
  title: string
  button: string
  onAnswer: (answer: YesNo) => void
}

interface ContactDetailsState {
  currentTab: string
  pageSize: number
  pageIndex: number
  scrolling: boolean
  search: string
  dropdownOpen: boolean
  messageDropdownOpen: boolean
  contactsToDelete: number[] | string[]
  selectedContacts: Contact[]
  maxCellWidth: number
  showColumnsOrderModal: boolean
  showAddToSegmentModal: boolean
  contactsHeaders: Column[]
  loading: boolean
  hasNextPage: boolean
  confirmationModalData?: ConfirmationModalData
}

const rootClass = 'contacts-details'

const PAGE_SIZE_OPTIONS = [10, 20, 50, 100]
enum MAX_WIDTHS {
  small = 180,
  medium = 228,
  large = 276,
}

const initialState: ContactDetailsState = {
  currentTab: 'sent',
  pageSize: PAGE_SIZE_OPTIONS[1],
  pageIndex: 0,
  scrolling: false,
  search: '',
  dropdownOpen: false,
  messageDropdownOpen: false,
  contactsToDelete: [],
  selectedContacts: [],
  maxCellWidth: MAX_WIDTHS.small,
  showColumnsOrderModal: false,
  showAddToSegmentModal: false,
  contactsHeaders: [],
  loading: true,
  hasNextPage: false,
}

const ContactsDetails: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, segment, className = '', isBounceSegment } = props
  const [state, setState] = useState<ContactDetailsState>({ ...initialState })
  const {
    pageSize,
    pageIndex,
    scrolling,
    search,
    selectedContacts,
    maxCellWidth,
    contactsToDelete,
    showColumnsOrderModal,
    showAddToSegmentModal,
    contactsHeaders,
    loading = false,
    hasNextPage,
    confirmationModalData,
  } = state
  const { userAllowedToDelete, disableSegmentModifications, hasNewSegmentComposerUI } = useAccountSettings()
  const {
    values: { segmentContacts, isProcessingAction, search: segmentSearch, searchAllItems, mappedIdsToUuids },
    update,
  } = useContext(ContactSegmentsContext)

  const { t } = useTranslation()

  const { client: segmentClient } = useMicroserviceClient({ serviceName: MicroserviceClients.SEGMENT })
  const { client: listClient } = useMicroserviceClient({ serviceName: MicroserviceClients.LIST })

  const { unifiedListFieldMappings = [] } = useUnifiedContactList()

  const size = useWindowSize()
  const history = useHistory()
  const bodyRef = useRef<HTMLDivElement>(null)
  const isDirectSelect = segment?.type === 'Direct Select'
  const totalCount = segmentContacts?.totalCount || 0
  const noContacts = useMemo(() => !loading && !Object.keys(segmentContacts?.contacts ?? {}).length, [loading, segmentContacts])
  const pageCount = totalCount ? Math.ceil(totalCount / pageSize) : 0

  const tableMenuItems: MenuItem[] = useMemo(
    () => [
      {
        text: 'Manage column order',
        icon: SvgNames.columnOrder,
        onClick: () => setState((state) => ({ ...state, showColumnsOrderModal: true })),
      },
    ],
    []
  )

  useEffect(() => {
    if (!location.pathname.includes(CONTACTS_DETAILS_URL)) {
      history.push(CONTACTS_DETAILS_URL, { showDetails: segment })
    } else {
      history.replace(location.pathname, { showDetails: segment })
    }
  }, [])

  const formatContacts = useMemo(() => formatContactsUtils(segmentContacts, contactsHeaders), [segmentContacts, contactsHeaders])

  const getColumnsHeaders = useMemo(
    () => getColumnsHeadersUtils(contactsHeaders, maxCellWidth, formatContacts, search),
    [contactsHeaders, maxCellWidth, formatContacts, search]
  )

  const getColumnsHeadersV2 = useMemo(() => getColumnsHeadersUtilsV2(contactsHeaders, search), [contactsHeaders, search])

  const changePageOptions = (pageIndex: number, pageSize: number) => {
    setState({ ...state, pageSize, pageIndex })
  }

  const onDeleteContactClick = (row?: Row<Contact>) => {
    if (row) {
      setState((state) => ({
        ...state,
        contactsToDelete: [mappedIdsToUuids[row.original.recId] ?? segmentContacts.rowIds[row.original.recId]],
      }))
    } else {
      if (selectedContacts.length > 0) {
        setState((state) => ({
          ...state,
          contactsToDelete: selectedContacts.map(({ recId }) => mappedIdsToUuids[recId] ?? segmentContacts.rowIds[recId]),
        }))
      }
    }
  }

  const onRemoveFromBounceClick = (contacts: Contact[]) => {
    setState((state) => ({
      ...state,
      confirmationModalData: {
        ...getChangeBounceStatusConfirmationModal(contacts, contactsHeaders, segment, t),
        onAnswer: (answer: YesNo) => {
          if (answer === YesNo.YES) {
            onRemoveFromBounce(contacts, segment, segmentClient)
              .then(loadContacts)
              .catch(() =>
                update({
                  statusToast: {
                    statusMessage: t(`Something went wrong on our end. No contacts have been changed.`),
                    status: Status.FAIL,
                    showStatusToast: true,
                  },
                })
              )
          }
        },
      },
    }))
  }

  const loadColumns = (headers: string[]) => {
    getColumnsUtils(segment, headers as string[], segmentClient)
      .then((columns) => {
        setState((state) => ({
          ...state,
          contactsHeaders: columns ?? headers.map((name, id) => ({ name, id } as Column)),
          loading: false,
        }))
      })
      .catch(() => setState((state) => ({ ...state, loading: false })))
  }

  const loadContacts = () => {
    const isInactiveBounceSegment = segment.externalId === bouncesVerbs[BouncesSegments.INACTIVE]
    const pageNumber = isInactiveBounceSegment ? pageIndex + 1 : pageIndex

    setState((state) => ({ ...state, selectedContacts: [], loading: true }))

    getSegmentContacts(
      {
        id: isBounceSegment && !isInactiveBounceSegment ? segment?.classicExternalId : segment?.externalId || undefined,
        pageNumber,
        pageSize,
        search,
      },
      isInactiveBounceSegment,
      segmentClient
    )
      .then(({ headers, ...rest }) => {
        update({ segmentContacts: { headers, ...rest } })
        setState((state) => ({ ...state, hasNextPage: rest.hasNextPage || false }))
        loadColumns(headers as string[])
      })
      .catch(() => setState((state) => ({ ...state, loading: false })))
  }

  useEffect(() => {
    update({ mappedIdsToUuids: mapContactIdToUuid(segmentContacts) })
  }, [segmentContacts])

  const onAddToSegmentClick = (_contacts?: Contact[]) => {
    setState((state) => ({ ...state, showAddToSegmentModal: true }))
  }

  const onRemoveContactFromSegment = (recIds?: string[]) => {
    const contactsIds = recIds ? recIds : selectedContacts.map(({ recId }) => recId)
    setState((state) => ({ ...state, loading: true }))
    removeContactsFromSegmentUtils(segment.externalId, contactsIds, segmentClient).then((response) => {
      response.data?.removeContactsFromSegment && loadContacts()
    })
  }

  const onRowSelectionChanged = (ids: string[], contacts: Row<Contact>[]) => {
    if (ids.length > 0) {
      if (ids.length !== selectedContacts.length) {
        const selectedContacts = ids.map((id) => contacts[parseInt(id)].original)
        setState((state) => ({ ...state, selectedContacts }))
      }
    } else if (selectedContacts.length > 0) {
      setState((state) => ({ ...state, selectedContacts: [] }))
    }
  }

  const onRowSelectionChangedV2 = (rowIds: string[], rows: RowV2<Contact>[]) => {
    if (rowIds.length > 0) {
      if (rowIds.length !== selectedContacts.length) {
        const selectedContacts = rowIds.map((id) => rows[parseInt(id)].original)
        setState((state) => ({ ...state, selectedContacts }))
      }
    } else if (selectedContacts.length > 0) {
      setState((state) => ({ ...state, selectedContacts: [] }))
    }
  }

  const tableActions: { [key in ContactsTableActions]: VoidFunction } = {
    [ContactsTableActions.ADD_TO_SEGMENT]: () => onAddToSegmentClick(selectedContacts),
    [ContactsTableActions.EXPORT]: () => undefined,
    [ContactsTableActions.SEND_EMAIL]: () => {
      saveSelectedContactsToLocalStorage(selectedContacts, contactsHeaders, unifiedListFieldMappings)
      onSendEmailClick(selectedContacts)
    },
    [ContactsTableActions.SEND_SMS]: () => undefined,
    [ContactsTableActions.REMOVE_FROM_SEGMENT]: () => onRemoveContactFromSegment(),
    [ContactsTableActions.REMOVE_FROM_BOUNCE]: () => onRemoveFromBounceClick(selectedContacts),
    [ContactsTableActions.DELETE]: () => onDeleteContactClick(),
  }

  const doTableAction = (action: ContactsTableActions) => {
    if (tableActions.hasOwnProperty(action)) {
      tableActions[action]()
    }
  }

  const onScroll = ({ target }: UIEvent<HTMLDivElement>) => {
    if (!scrolling && (target as HTMLDivElement).scrollTop > 0) {
      setState({ ...state, scrolling: true })
    } else if (scrolling && (target as HTMLDivElement).scrollTop === 0) {
      setState({ ...state, scrolling: false })
    }
  }

  const headerActions: HeaderAction[] = useMemo(
    () =>
      isBounceSegment
        ? getBouncesContactsHeaderActions(segment, doTableAction, userAllowedToDelete, disableSegmentModifications)
        : getContactsHeaderActions(isDirectSelect, doTableAction, userAllowedToDelete, hasNewSegmentComposerUI, disableSegmentModifications),
    [segment, selectedContacts, contactsHeaders]
  )

  const rowActions: RowAction[] = useMemo(
    () =>
      isBounceSegment
        ? getBouncesContactsRowActions(segment, onDeleteContactClick, onRemoveFromBounceClick, userAllowedToDelete, disableSegmentModifications)
        : getContactsRowActions(
            isDirectSelect,
            onDeleteContactClick,
            onRemoveContactFromSegment,
            onAddToSegmentClick,
            userAllowedToDelete,
            contactsHeaders,
            unifiedListFieldMappings,
            hasNewSegmentComposerUI,
            disableSegmentModifications
          ),
    [segment, isDirectSelect, contactsHeaders, unifiedListFieldMappings]
  )

  useEffect(() => {
    const width = size.width || 0
    setState({ ...state, maxCellWidth: width <= 1600 ? MAX_WIDTHS.small : width <= 2000 ? MAX_WIDTHS.medium : MAX_WIDTHS.large })
  }, [size])

  useEffect(() => {
    loadContacts()
  }, [pageSize, pageIndex, segment.id, search])

  useEffect(() => {
    if (segmentContacts?.contacts && !isProcessingAction) {
      loadContacts()
    }
  }, [isProcessingAction])

  useEffect(() => {
    return () => update({ segmentContacts: {} })
  }, [])

  useEffect(() => {
    if (contactsToDelete.length > 0) {
      setState((state) => ({
        ...state,
        confirmationModalData: {
          title: contactsToDelete.length === 1 ? 'Delete this contact' : 'Delete these contacts',
          body: (
            <>
              <Typography
                text={t(
                  contactsToDelete.length === 1
                    ? 'The contact record and activity history will be deleted from Act-On Contacts and all Segments. '
                    : 'The contacts’ records and activity histories will be deleted from Act-On Contacts and all Segments. '
                )}
                {...ModalBodyStyle}
                inline
              />
              <Typography text={t('You will not be able to undo this action.')} {...ModalBodyStyle} weight={TextWeight.BOLD} inline />
            </>
          ),
          button: 'Delete',
          onAnswer: (answer: YesNo) => {
            const hasUuids = typeof contactsToDelete[0] === 'string'
            if (answer === YesNo.YES) {
              update({ isProcessingAction: true })
              deleteContactsUtils(
                !hasUuids ? contactsToDelete.map((recordId) => recordId as number) : [],
                hasUuids ? contactsToDelete.map((uuid) => uuid as string) : [],
                listClient
              )
                .then(() => {
                  update({
                    isProcessingAction: false,
                    statusToast: {
                      statusMessage: `We're deleting your contacts now. This may take some time. Check back later for an updated contact list.`,
                      status: Status.SUCCESS,
                      showStatusToast: true,
                    },
                  })
                })
                .catch(() => update({ isProcessingAction: false }))
            }
            setState((state) => ({ ...state, contactsToDelete: [] }))
          },
        },
      }))
    }
  }, [contactsToDelete])

  const renderTopSection = () => (
    <div className={`${rootClass}__top-section`}>
      <Typography
        className={classNames([`${rootClass}__top-section--label`, 'ellip'])}
        type={TextType.BODY_TEXT}
        weight={TextWeight.BOLD}
        text={getTableLabel(search, totalCount, loading, t)}
      />
      <Search
        incomingValue={search}
        onChangeHandler={(value) => {
          setState({ ...state, pageIndex: 0, search: value })
        }}
        placeholder={'Search'}
        searchType={SearchType.LARGE}
        canClear
      />
    </div>
  )

  const renderEmptyState = () => {
    const emptyState = getContactDetailsEmptyState(segment, isBounceSegment, search)
    const { headline, text, buttonText, ...props } = contactDetailsEmptyStateData[emptyState]({
      isDirectSelect,
      segment,
      segmentSearch,
      searchAllItems,
    })
    return <EmptyListing {...props} headline={t(headline)} text={t(text)} buttonText={t(buttonText)} />
  }

  const [enableTableV2, setEnableTableV2] = useState(false)

  return (
    <div
      className={classNames(rootClass, className, {
        [`${rootClass}__header-shadow`]: scrolling,
      })}
      data-test={dataTest}
    >
      {confirmationModalData && (
        <DeleteConfirmationModal
          dataTest={`${dataTest}--delete-confirmation-modal`}
          title={t(confirmationModalData.title)}
          body={confirmationModalData.body}
          deleteButtonText={confirmationModalData.button}
          onAnswer={(answer) => {
            confirmationModalData.onAnswer(answer)
            setState((state) => ({ ...state, confirmationModalData: undefined }))
          }}
          isOpen
        />
      )}
      {showAddToSegmentModal && (
        <AddToSegmentModal onClose={() => setState((state) => ({ ...state, showAddToSegmentModal: false }))} segment={segment} isOpen />
      )}
      {showColumnsOrderModal && (
        <ColumnsOrderModal
          isOpen={showColumnsOrderModal}
          onClose={() => setState((state) => ({ ...state, showColumnsOrderModal: false }))}
          onSave={(columns: Column[], checked) => {
            saveColumnsUtils(columns, segment, segmentClient, checked).then(() => {
              loadContacts()
              setState((state) => ({ ...state, showColumnsOrderModal: false }))
            })
          }}
          checkboxLabel={'Apply changes to all sub-segments'}
          columns={contactsHeaders.filter(({ name }) => !uuidColumns.includes(name))}
        />
      )}
      {renderTopSection()}
      {noContacts ? (
        renderEmptyState()
      ) : (
        <>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
          <div onClick={() => setEnableTableV2((prev) => !prev)}>{' '}</div>
          <MultipleTables
            enableTableV2={enableTableV2}
            tableV2Props={{
              rowActions,
              headerActions,
              loading: loading || isProcessingAction,
              enableCheckbox: true,
              data: formatContacts,
              enableOuterLoader: true,
              columns: getColumnsHeadersV2,
              enablePagesInput: search === '',
              stickyColumns: ['cellCheckbox'],
              enablePaginate: true,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              tableMenuItems: !selectedContacts.length ? tableMenuItems : [],
              paginationState: {
                pageIndex,
                pageSize,
                controlledPageCount: search !== '' && hasNextPage ? 2 : pageCount,
              },
              onRowSelectionChanged: onRowSelectionChangedV2,
              fetchData: changePageOptions,
            }}
            oldTable={
              <TableWithMenu
                className={`${rootClass}__table`}
                data={formatContacts}
                columns={getColumnsHeaders}
                bodyRef={bodyRef}
                // keeping the count on 2 to show the "next page" icon
                controlledPageCount={search !== '' && hasNextPage ? 2 : pageCount}
                showPages={search === ''}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                canLastPage={search === ''}
                fetchData={changePageOptions}
                headerActions={headerActions}
                loading={loading || isProcessingAction}
                onRowSelectionChanged={onRowSelectionChanged}
                initialState={{
                  pageIndex,
                  pageSize,
                  selectedRowIds: selectedContacts.reduce((acc, { id }) => ({ ...acc, [id]: true }), {}),
                }}
                canPaginate
                hasOverflow
                hasHeadersWithEllipsis
                useCheckboxes
                useHeaderCheckbox
                useStickyColumns
                rowActions={rowActions}
                onScroll={onScroll}
                menuItems={selectedContacts.length === 0 ? tableMenuItems : []}
                updatePageOnKeyPress
              />
            }
          />
        </>
      )}
    </div>
  )
}

export default ContactsDetails
