import React, { ReactNode } from 'react'

import { ListPageAPI, ListPageCommonState } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { YesNo } from '@components/ConfirmationModal/index'
import DeleteWithConfirmationModal from '@components/DeleteWithConfirmationModal/DeleteWithConfirmationModal'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import {
  AccountSharePermissions,
  AccountShareUpdateProps,
} from '@src/pages/listingPages/AccountManagement/AccountManagementListingPageContainer.types'
import AccountManagementPermissionsModal from '@src/pages/listingPages/AccountManagement/components/AccountManagementPermissionsModal/AccountManagementPermissionsModal'
import AssignContactsModal from '@src/pages/listingPages/AccountManagement/components/AssignContactsModal/AssignContactsModal'
import { AssignedManager } from '@src/pages/listingPages/AccountManagement/components/AssignManagersModal/AssignManagersModal'
import AssignManagersModalContainer from '@src/pages/listingPages/AccountManagement/components/AssignManagersModal/AssignManagersModalContainer'
import CreateAccountModal, {
  CreateAccountModalState,
} from '@src/pages/listingPages/AccountManagement/components/CreateAccountModal/CreateAccountModal'
import { SalesUser } from '@src/pages/listingPages/AccountManagement/GraphQL/AccountManagementListingPageRequests.graphQL'
import { AccountManagementCustomTableActions } from '@src/pages/listingPages/AccountManagement/utils/AccountManagementListingPage.tables'
import { Account, CustomActionHelpers } from '@src/pages/listingPages/AutomatedPrograms/AutomatedProgramsListingPage.constants'

type AccountManagementCustomTableActionsWithModals =
  | Exclude<AccountManagementCustomTableActions, AccountManagementCustomTableActions.LOGIN | AccountManagementCustomTableActions.MOVE_TO_FOLDER>
  | 'CREATE'

export const renderCustomModal = (
  customTableAction: Exclude<AccountManagementCustomTableActionsWithModals, AccountManagementCustomTableActions.REMOVE_FROM_FOLDER> | 'CREATE',
  listPageValues: ListPageCommonState,
  listPageAPI: ListPageAPI,
  customActionHelpers: CustomActionHelpers,
  maxContacts: number,
  parentAccountUsedContacts: number,
  t: Function
) => {
  const { update, setStatusToast, setError } = listPageAPI
  const { createAccount, updateAssignedContacts, updateSharePermissions, deleteAccount, assignManagers, assignSalesUsers } = customActionHelpers
  const { selectedRows, statusToast } = listPageValues
  const selectedRow = selectedRows[0]
  const row = selectedRow as Account

  const renderSharePermissions = () => {
    // using `any` type for now to avoid list error
    const accounts: AccountSharePermissions[] = selectedRows.map((row: any) => ({
      id: row.accountId,
      name: row.name ?? 'none',
      canShare: row.canShare,
      canSubscribe: row.canSubscribe,
    }))

    return (
      <AccountManagementPermissionsModal
        isOpen
        accounts={accounts}
        onClose={() => update({ showCustomModal: false })}
        onAction={(permissions: AccountShareUpdateProps) => {
          const accountSharePermissions = accounts.map((account) => ({ accountId: account.id, ...permissions }))
          updateSharePermissions(accountSharePermissions, accounts, update, setStatusToast, setError)
        }}
      />
    )
  }

  const renderCreateAccount = () => {
    // close any open status toast from, say, a previous create account action
    if (statusToast.showStatusToast) {
      update({ statusToast: { ...statusToast, showStatusToast: false } })
    }
    return (
      <CreateAccountModal
        isOpen
        maxContacts={maxContacts - parentAccountUsedContacts}
        onClose={() => update({ showCustomModal: false })}
        onAction={(account: CreateAccountModalState) => {
          createAccount({ ...account, activeContacts: account.activeContacts ?? 0 }, update, setStatusToast, setError)
        }}
      />
    )
  }

  const customModal: {
    [key in Exclude<AccountManagementCustomTableActionsWithModals, AccountManagementCustomTableActions.REMOVE_FROM_FOLDER>]: () => ReactNode
  } = {
    ['CREATE']: renderCreateAccount,
    [AccountManagementCustomTableActions.DELETE_ACCOUNT]: () => (
      <DeleteWithConfirmationModal
        isOpen
        title={t('AccountManagement.Delete.Title', { name: row.name })}
        deleteButtonText={t('ListPage.AccountManagement.DeleteItem.Title', { count: 1 })}
        bodyText={
          <Typography
            text={t('ListPage.AccountManagement.DeleteItem.Body')}
            tagProps={{ medium: { weight: TextWeight.BOLD, inline: true } }}
            type={TextType.BODY_TEXT_LIGHT}
          />
        }
        onAnswer={(answer: YesNo) => {
          if (answer === YesNo.YES) {
            deleteAccount(selectedRow.accountId, row.name, update, setStatusToast, setError)
          } else {
            update({ showCustomModal: false })
          }
        }}
      />
    ),
    [AccountManagementCustomTableActions.ASSIGN_SALES_USERS]: () => (
      <AssignManagersModalContainer
        isOpen
        isManagers={false}
        accountId={selectedRow.accountId}
        onAction={(assignments: AssignedManager[]) => {
          const sales: SalesUser[] = assignments.map((assigned) => ({
            name: assigned.name,
            salesUser: assigned.isAssigned,
            userId: parseInt(assigned.id),
          }))
          assignSalesUsers(selectedRow.accountId, row.name, sales, update, setStatusToast, setError)
        }}
        onClose={() => update({ showCustomModal: false })}
      />
    ),
    [AccountManagementCustomTableActions.ASSIGN_MANAGERS]: () => (
      <AssignManagersModalContainer
        isOpen
        isManagers
        accountId={selectedRow.accountId}
        onAction={(assignments: AssignedManager[]) => {
          const managers = assignments.map((assigned) => ({
            name: assigned.name,
            manager: assigned.isAssigned,
            userId: parseInt(assigned.id),
          }))
          assignManagers(selectedRow.accountId, row.name, managers, update, setStatusToast, setError)
        }}
        onClose={() => update({ showCustomModal: false })}
      />
    ),
    [AccountManagementCustomTableActions.ASSIGN_ACTIVE_CONTACTS]: () => (
      <AssignContactsModal
        isOpen
        activeContacts={row.activeContacts}
        usedContacts={row.usedContacts}
        maxContacts={maxContacts - parentAccountUsedContacts}
        onAction={(activeContacts: number) => {
          updateAssignedContacts(selectedRow.accountId, row.name, activeContacts, update, setStatusToast, setError)
        }}
        onClose={() => update({ showCustomModal: false })}
      />
    ),
    [AccountManagementCustomTableActions.SHARE_PERMISSIONS]: () => {
      return renderSharePermissions()
    },
    [AccountManagementCustomTableActions.SHARE_PERMISSION_ITEMS]: () => {
      return renderSharePermissions()
    },
  }

  if (!(customTableAction in customModal)) {
    return
  }

  return customModal[customTableAction]()
}
