import { gql } from '@apollo/client'

export default gql`
  mutation startProgram($programId: String!, $confirmedStartParam: Int, $lastRowIdString: String, $skipPendingParam: Int, $runNow: Int) {
    startProgram(
      programId: $programId
      confirmedStartParam: $confirmedStartParam
      lastRowIdString: $lastRowIdString
      skipPendingParam: $skipPendingParam
      runNow: $runNow
    ) {
      id
      status
      message
      errorCode
      lastRowId
      scheduled
      pending
      needsConfirmation
      reenterContacts
    }
  }
`
