import React, { useContext, useEffect, useRef } from 'react'
import { GroupHeadingProps } from 'react-select'

import classNames from 'classnames'

import NumberBadge from '@components/NumberBadge/NumberBadge'
import { SelectV2Context } from '@components/SelectV2/SelectV2.context'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { css } from '@emotion/css'

import { findGroupIndexWithLabel, findGroupWithLabel } from '../SelectV2.utils'
import { selectRootClass } from '../SelectV2.utils'

const MIN_DIVIDER_WIDTH = 100

const CustomGroupHeading = (props: GroupHeadingProps<any>) => {
  const { children, getStyles } = props
  const {
    values: { currentOptions, inputValue, hideNumberBadge, showGroupsWithoutLabel },
  } = useContext(SelectV2Context)

  const dividerRef = useRef<HTMLDivElement>(null)

  const group = findGroupWithLabel(currentOptions, `${children}`)
  const groupIndex = findGroupIndexWithLabel(currentOptions, `${children}`)
  const groupCount = inputValue
    ? group?.options.filter((option) => {
        const label = option.label.toLowerCase()
        const value = option.value.toLowerCase()
        const input = inputValue.toLowerCase()
        return label.includes(input) || value.includes(input)
      }).length
    : group?.options.length

  useEffect(() => {
    const divider = dividerRef.current

    if (divider) {
      if (!showGroupsWithoutLabel && divider.offsetWidth < MIN_DIVIDER_WIDTH) {
        divider.style.visibility = 'hidden'
      } else {
        divider.style.visibility = 'visible'
      }
    }
  }, [children])

  const renderDivider = () => (
    <div
      className={classNames(`${selectRootClass}__group-heading__divider`, {
        [`${selectRootClass}__group-heading__divider--full-width`]: showGroupsWithoutLabel,
      })}
      ref={dividerRef}
    />
  )

  if (showGroupsWithoutLabel) {
    if (groupIndex === 0) {
      return null
    } else {
      return renderDivider()
    }
  }

  return (
    <div
      className={classNames(css(getStyles('groupHeading', props)), `${selectRootClass}__group-heading`, {
        [`${selectRootClass}__group-heading--full-width`]: showGroupsWithoutLabel,
      })}
    >
      <Tooltip
        triggerClassName={`${selectRootClass}__group-heading__tooltip-trigger`}
        ellipOnTrigger
        trigger={
          <Typography
            inline
            text={children}
            lineHeight={LineHeight.MEDIUM_SMALL}
            type={TextType.NORMAL_TEXT_TEAL}
            weight={TextWeight.BOLD}
            className={`${selectRootClass}__group-heading__label`}
            dataTest={`selectv2-group-heading-label`}
          />
        }
      >
        {children}
      </Tooltip>
      {renderDivider()}
      {!hideNumberBadge && group?.label && (
        <NumberBadge value={groupCount ?? 0} maxValue={100} className={`${selectRootClass}__group-heading__badge`} />
      )}
    </div>
  )
}

export default CustomGroupHeading
