import { EmptyListingProps, EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { TableWithLoaderProps } from '@components/TableWithLoader/TableWithLoader'

interface Props {
  t: (s: any, options?: any) => any
  isAdmin: boolean
  haveData: boolean
  searchTerm: string
  onAddUsers: () => void
}

const getTableProps: (props: Props) => Partial<TableWithLoaderProps & EmptyListingProps> = ({ t, isAdmin, haveData, searchTerm, onAddUsers }) => ({
  size: EmptyListingSize.MEDIUM,
  hideIcon: false,
  headline: haveData ? t('Sales.Users.Table.Empty.Search.Headline', { searchTerm }) : t('Sales.Users.Table.Empty.Headline'),
  text: t(haveData ? 'Sales.Users.Table.Empty.Search.Text' : 'Sales.Users.Table.Empty.Text'),
  imgSrc: haveData ? StaticImageNames.emptySearch : StaticImageNames.emptyContacts,
  buttonText: !haveData && isAdmin ? t('Sales.Users.Table.Empty.Button.Text') : undefined,
  buttonOnClick: !haveData && isAdmin ? onAddUsers : undefined,
})

export default getTableProps
