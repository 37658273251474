import React, { FC, Key } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Caution from '@components/Caution/Caution'
import Container from '@components/Container'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { PushMethod } from '@src/pages/datamanagement/components/PushSetting/PushSetting'

import './ReviewPushModal.css'

interface ReviewPushModalProps {
  className?: string
  dataTest?: string
  onCancel: () => void
  onSave: () => void
  pushMethod: Key
  rows: { column: string; value: string | undefined }[][]
}

const rootClass = 'review-push-modal'

const ReviewPushModal: FC<ReviewPushModalProps> = (props: ReviewPushModalProps) => {
  const { dataTest = rootClass, className = '', onCancel, onSave, pushMethod, rows } = props

  const { t } = useTranslation()

  const modalHeader = (
    <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__header`}>
      {t('Review push to CRM changes')}
    </ModalHeader>
  )

  const containerHeader = (
    <div className={`${rootClass}__container-header`}>
      <Svg name={SvgNames.reset} type={SvgType.LARGER_ICON} />
      <Typography text={'These fields will be changed.'} weight={TextWeight.MEDIUM} lineHeight={LineHeight.TINY} />
    </div>
  )

  const renderHeader = () => {
    if (rows.length > 0) {
      return (
        <div className={`${rootClass}__simple-list-header`}>
          {rows[0].map(({ column }) => {
            const isAllowPush = column === t('ALLOW PUSH')
            const isActonContacts = column === t('ACT-ON CONTACTS')
            return (
              <div
                key={column}
                className={classNames(`${rootClass}__simple-list-header-title`, {
                  [`${rootClass}__simple-list-header-title-allow`]: isAllowPush,
                  [`${rootClass}__simple-list-header-title-teal`]: isActonContacts,
                  [`${rootClass}__simple-list-header-title-blue`]: !isActonContacts && !isAllowPush,
                })}
              >
                <Typography text={t(column)} type={TextType.TABLE_HEADER} lineHeight={LineHeight.SMALL} />
              </div>
            )
          })}
        </div>
      )
    }
  }

  const renderRows = () =>
    rows.map((row) => (
      <div key={`row-${row[row.length - 1].value}`} className={`${rootClass}__simple-list-row`}>
        {row.map(({ column, value }) => {
          return column === t('ALLOW PUSH') ? (
            <div className={classNames(`${rootClass}__simple-list-row-cell`, `${rootClass}__simple-list-row-cell-allow`)}>
              <Svg name={pushMethod === PushMethod.ALLOW ? SvgNames.twoWayArrow : SvgNames.arrowRightGray} type={SvgType.LARGER_ICON} />
            </div>
          ) : (
            <div className={`${rootClass}__simple-list-row-cell`}>
              <TextWithTooltipOnEllip
                typographyProps={{
                  text: value || '-',
                  ...(!value ? { type: TextType.BODY_TEXT_LIGHT } : {}),
                }}
              />
            </div>
          )
        })}
      </div>
    ))

  return (
    <Modal dataTest={dataTest} className={classNames(rootClass, className)} isOpen header={modalHeader}>
      <ModalBody>
        <Caution className={`${rootClass}__caution`} message={t('DataManagement.ReviewPushModal.Caution')} />
        <Container header={containerHeader}>
          <div className={`${rootClass}__simple-list`}>
            {renderHeader()}
            {renderRows()}
          </div>
        </Container>
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form}>
        <Button buttonType={ButtonType.TERTIARY} onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} onClick={onSave}>
          {t('Ok')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ReviewPushModal
