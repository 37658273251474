import React, { FC, RefObject, useContext, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'
import { TooltipOptions } from 'highcharts'

import Accordion from '@components/Accordion/Accordion'
import PieChart from '@components/PieChart/PieChart'
import ScrollArea from '@components/ScrollArea/ScrollArea'
import { SvgColor } from '@components/Svg/Svg'
import { SwitchOptions } from '@components/Switch/Switch'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ABSplitsChartPlotOptions, MAX_SCROLL_SPLITS } from '@src/pages/ContactSegments/components/ABSplit/ABSplit.constants'
import { getTotals } from '@src/pages/ContactSegments/components/ABSplit/ABSplit.utils'
import { ABSplitContext, Split } from '@src/pages/ContactSegments/components/ABSplit/ABSplitContext'
import { formatNumber } from '@utils/numbers'

import './ABSplitSummary.css'

interface ABSplitSummaryProps {
  className?: string
  dataTest?: string
  segmentName: string
}

interface ABSPlitSummaryState {
  lastValidSplits: Split[]
  actualContacts: number
  splitsScrolled: { top: boolean; bottom: boolean }
  suppressedSplitsScrolled: { top: boolean; bottom: boolean }
}

const rootClass = 'ab-split-summary'

const ABSplitSummary: FC<ABSplitSummaryProps> = (props: ABSplitSummaryProps) => {
  const { dataTest = rootClass, className = '', segmentName } = props

  const {
    values: {
      splits,
      availableContacts,
      selectedDistribution,
      suppressedSplits,
      inputError,
      suppressedContacts,
      nonDeliverableContacts,
      numberOfSplits,
    },
  } = useContext(ABSplitContext)
  const [state, setState] = useState<ABSPlitSummaryState>({
    lastValidSplits: splits,
    actualContacts: new Set([...suppressedContacts, ...nonDeliverableContacts]).size,
    splitsScrolled: { top: true, bottom: false },
    suppressedSplitsScrolled: { top: true, bottom: false },
  })
  const { lastValidSplits, actualContacts, splitsScrolled, suppressedSplitsScrolled } = state
  const { t } = useTranslation()
  const splitsScrollAreaRef = useRef<HTMLDivElement>(null)
  const suppressedSplitsScrollAreaRef = useRef<HTMLDivElement>(null)

  const handleScroll = (event: Event, suppressing: boolean) => {
    const scrollArea = event?.target as HTMLDivElement
    const scrollStatus = { top: scrollArea.scrollTop === 0, bottom: scrollArea.scrollHeight - scrollArea.scrollTop === scrollArea.clientHeight }
    setState((state) => (suppressing ? { ...state, suppressedSplitsScrolled: scrollStatus } : { ...state, splitsScrolled: scrollStatus }))
  }
  const handleScrollAreaRefChange = (ref: RefObject<HTMLDivElement>, suppressing: boolean) => {
    const scrollArea = ref?.current
    if (scrollArea) {
      scrollArea.addEventListener('scroll', (e) => handleScroll(e, suppressing))
      return () => scrollArea.removeEventListener('scroll', (e) => handleScroll(e, suppressing))
    }
  }
  useEffect(() => {
    if (!inputError) {
      setState((state) => ({ ...state, lastValidSplits: splits }))
    }
  }, [splits])

  useEffect(() => {
    setState((state) => ({ ...state, actualContacts: new Set([...suppressedContacts, ...nonDeliverableContacts]).size }))
  }, [suppressedContacts, nonDeliverableContacts])

  useEffect(() => {
    handleScrollAreaRefChange(splitsScrollAreaRef, false)
  }, [splitsScrollAreaRef.current, suppressedSplitsScrollAreaRef.current])

  useEffect(() => {
    handleScrollAreaRefChange(suppressedSplitsScrollAreaRef, true)
  }, [suppressedSplitsScrollAreaRef.current])

  const getExtra = (suppressing: boolean) => {
    const { fixedTotalAmount, fixedSplitsNumber } = getTotals(splits)
    const actualContacts = availableContacts - fixedTotalAmount - (suppressing ? new Set([...suppressedContacts, ...nonDeliverableContacts]).size : 0)
    const actualSplits = numberOfSplits - fixedSplitsNumber
    return actualContacts % actualSplits
  }
  const renderSplits = (splits: Split[], suppressing = false) => {
    const splitsRef = suppressing ? suppressedSplitsScrollAreaRef : splitsScrollAreaRef
    const scrolledSplits = suppressing ? suppressedSplitsScrolled : splitsScrolled
    return (
      <ScrollArea showOnEvent={'always'} className={classNames(`${rootClass}__scroll`, `${className}-scroll`)} register={splitsRef}>
        {splits.length > MAX_SCROLL_SPLITS && !scrolledSplits.top && (
          <div className={classNames(`${rootClass}__gradient`, `${rootClass}__gradient-top`)} />
        )}
        {splits.map((split, index) => (
          <div key={index} className={classNames(`${rootClass}__typography-wrapper`, `${rootClass}__item`)} data-test={`${dataTest}-sirak__${index}`}>
            <Typography
              className={`${rootClass}__typography`}
              text={'ListPage.ABSplits.Summary.IndividualSplit'}
              type={TextType.BODY_TEXT_LIGHT}
              values={{
                name: split.name,
                splitContacts:
                  selectedDistribution === SwitchOptions.PERCENT
                    ? `${Number(split.value).toFixed(2)}% (${
                        Math.floor((Number(split.value) * (availableContacts - (suppressing ? actualContacts : 0))) / 100) +
                        (index === splits.length - 1 ? getExtra(suppressing) : 0)
                      })`
                    : split.value,
              }}
              tagProps={{ medium: { weight: TextWeight.MEDIUM } }}
              inline
            />
          </div>
        ))}
        {splits.length > MAX_SCROLL_SPLITS && !scrolledSplits.bottom && (
          <div className={classNames(`${rootClass}__gradient`, `${rootClass}__gradient-bottom`)} />
        )}
      </ScrollArea>
    )
  }

  const getChartData = () =>
    lastValidSplits.map((split) => ({
      name: `${segmentName} ${split.name}`,
      y: Number(split.value),
    }))

  const tooltipSplitsFormatter: TooltipOptions = {
    headerFormat: '',
    pointFormatter: function (this): string {
      return `<tspan style="color:${this.color}" x="8" dy="15">&#x25cf;</tspan> ${this.name}<br/><b> ${
        selectedDistribution === SwitchOptions.NUMBER ? `${formatNumber(this.y ?? 0)} contacts` : `${Number(this.y).toFixed(2)} %`
      }</b> `
    },
    useHTML: true,
    outside: true,
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Typography text={segmentName} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.MEDIUM} className={'ellip'} />
      <PieChart
        disableMenu
        disableLegend
        noContainer
        chartHeight={160}
        chartWidth={160}
        className={`${rootClass}__chart`}
        fields={[{ name: 'Splits', colorByPoint: true, data: getChartData() }]}
        showTooltip
        tooltip={tooltipSplitsFormatter}
        plotOptions={ABSplitsChartPlotOptions}
      />
      <div className={`${rootClass}__divider`} />
      <div>
        <Accordion
          className={`${rootClass}__accordion-radix`}
          childData={[
            {
              index: '0',
              header: (
                <div className={classNames(`${rootClass}__accordion-wrapper`)} data-test={`${rootClass}-accordion-control`}>
                  <Typography
                    className={classNames(`${rootClass}__accordion`, `${rootClass}__typography`)}
                    text={'ListPage.ABSplits.Summary.AccordionTitle.PlannedSplits'}
                    values={{ availableContacts: availableContacts }}
                    tagProps={{ medium: { weight: TextWeight.MEDIUM } }}
                    dataTest={'Inbox-accordion'}
                    inline
                  />
                </div>
              ),
              content: renderSplits(lastValidSplits),
            },
          ]}
          chevronLeft
          chevronFillColor={SvgColor.LIGHT_GRAY}
        />
      </div>
      <div className={`${rootClass}__divider`} />
      {suppressedSplits.length > 0 && (
        <>
          <div className={classNames(`${rootClass}__typography-wrapper`, `${rootClass}__total`)}>
            <Typography
              className={`${rootClass}__typography`}
              text={'ListPage.ABSplits.Summary.Total.Suppressed'}
              values={{ suppressedTotal: actualContacts }}
              tagProps={{ medium: { weight: TextWeight.MEDIUM } }}
              inline
            />
          </div>
          <div className={`${rootClass}__divider`} />
          <div>
            <Accordion
              className={`${rootClass}__accordion-radix`}
              childData={[
                {
                  index: '0',
                  header: (
                    <div className={`${rootClass}__accordion-wrapper`}>
                      <Typography
                        text={t('Split contacts after suppression')}
                        className={classNames(`${rootClass}__accordion`, `${rootClass}__typography`)}
                      />
                    </div>
                  ),
                  content: renderSplits(suppressedSplits, true),
                },
              ]}
              chevronLeft
              chevronFillColor={SvgColor.LIGHT_GRAY}
            />
          </div>
          <div className={`${rootClass}__divider`} />
        </>
      )}
      <div className={classNames(`${rootClass}__typography-wrapper`, `${rootClass}__total`)}>
        <Typography
          className={classNames(`${rootClass}__typography`, `${rootClass}__total-final`)}
          text={'ListPage.ABSplits.Summary.Total'}
          type={TextType.BODY_TEXT_LARGE}
          weight={TextWeight.BOLD}
          values={{ totalContacts: availableContacts - actualContacts }}
          tagProps={{ large: { type: TextType.BODY_TEXT_LARGE }, bold: { weight: TextWeight.BOLD } }}
          inline
        />
      </div>
    </div>
  )
}

export default ABSplitSummary
