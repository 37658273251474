import React, { FC, useEffect, useState } from 'react'
import { FieldValues, UseFormRegister } from 'react-hook-form'

import { useApolloClient } from '@apollo/client'
import ListPickerModal from '@complex/ListPickerModal/ListPickerModal'
import { ListPickerModal as ListPickerModalV2 } from '@complex/ListPickerModalV2/ListPickerModal'
import { MIRRORED_LISTS_ID } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import ConfirmationModal from '@components/ConfirmationModal'
import Loader from '@components/Loader/Loader'
import Select from '@components/Select/Select'
import { useTranslation } from '@const/globals'
import listSchemaQuery from '@graphql/queries/listSchema'
import { ListSchemaQuery, ListSchemaQueryVariables, Program, ProgramSource } from '@graphql/types/query-types'
import { List } from '@interface/foldersLists/List'
import { useAccountSettings } from '@utils/account/account.utils'
import { logNewRelicError } from '@utils/new-relic.utils'
import programUtils, { hasAOContactsSource, hasLegacyListSource } from '@utils/program/program'

import { getListSelectUtils } from './listSelectUtils'

export const ADD_LIST = 'addList'

export interface Props {
  srcId?: string
  program: Program
  register: UseFormRegister<FieldValues>
  dataTest?: string
  className?: string
  onProgramSourcesUpdate(programSources: ProgramSource[]): void
  onChange?(listId: string): void
  name?: string
  isListsOnly?: boolean
  isListsAndUCLOnly?: boolean
  showUnifiedList?: boolean
}

export interface State {
  addingList: boolean
  previouslySelectedList: string
  additionalLists: List[]
  loadingLists?: List[]
  loadListSchemaError?: string
}

const rootClass = 'list-select'

export const ListSelect: FC<Props> = (props: Props) => {
  const {
    srcId,
    program,
    register,
    dataTest = rootClass,
    className,
    name = 'srcId',
    isListsOnly = false,
    isListsAndUCLOnly = false,
    showUnifiedList,
  } = props
  const { t } = useTranslation()
  const { hasShowActOnContactsTab, phase3FormSubmission } = useAccountSettings()
  const client = useApolloClient()

  const localSegmentDetails = programUtils.getLocalSegment(program, srcId)

  const [state, setState] = useState<State>({
    addingList: false,
    previouslySelectedList: !localSegmentDetails ? srcId ?? '' : '',
    additionalLists: [],
  })

  const listSelectUtils = getListSelectUtils(props, setState)

  useEffect(() => {
    if (state.loadingLists) {
      let listIds = state.loadingLists.map((list) => list.id)

      // remove duplicates
      listIds = listIds.reduce((acc, cur) => {
        if (acc.includes(cur) || program.sources.find((source) => source.id === cur)) {
          return acc
        }
        return [...acc, cur]
      }, [] as string[])

      client
        .query<ListSchemaQuery, ListSchemaQueryVariables>({
          query: listSchemaQuery,
          fetchPolicy: 'network-only',
          variables: {
            listIds,
          },
        })
        .then((data) => listSelectUtils.loadListSchemaSuccess(data))
        .catch((error) => {
          listSelectUtils.loadListSchemaError(error)
          setState((state) => ({ ...state, loadListSchemaError: 'Failed to load lists details' }))
          logNewRelicError(error)
        })
    }
  }, [client, listSelectUtils, program.sources, state.loadingLists])

  function getListOptions() {
    return program.sources?.reduce(
      (acc, cur) => {
        if (
          (cur.baseId?.startsWith('l-unified-contacts') && showUnifiedList === false) ||
          ((isListsOnly || isListsAndUCLOnly) &&
            !cur.id.startsWith('l-') &&
            !cur.baseId?.startsWith('l-unified-contacts') &&
            !cur.baseId?.startsWith(MIRRORED_LISTS_ID))
        ) {
          return acc
        }
        return [
          ...acc,
          <option key={cur.id} value={cur.id}>
            {cur.name}
          </option>,
        ]
      },
      [
        <option key="empty" value="">
          --- {t('Select List or Segment')} ---
        </option>,
      ]
    )
  }

  if (state.loadingLists) {
    return <Loader center />
  }

  const closeLoadListSchemaError = () => setState({ ...state, loadListSchemaError: undefined })

  const defaultSelectedLists =
    isListsOnly || isListsAndUCLOnly
      ? program.sources?.filter(({ id, baseId }) => id.startsWith('l-') || baseId.startsWith('l-unified-contacts'))
      : program.sources

  const renderListPicker = () =>
    hasShowActOnContactsTab ? (
      <ListPickerModalV2
        isListsOnly={isListsOnly}
        isListsAndUCLOnly={isListsAndUCLOnly}
        dataTest={`${dataTest}-list-picker-modal`}
        isOpen={true}
        disableLegacyLists={hasAOContactsSource(program)}
        disableUnifiedLists={hasLegacyListSource(program)}
        defaultSelectedLists={defaultSelectedLists.map((source) => source.id)}
        hasUnifiedFormSubmissions={phase3FormSubmission}
        closeModal={() => {
          setState({
            ...state,
            addingList: false,
          })
        }}
        submitLists={(lists) => {
          setState({
            ...state,
            loadingLists: lists,
            addingList: false,
          })
        }}
        restrictMixingLegacyListsAndUCL
      />
    ) : (
      <ListPickerModal
        hasAoContacts={hasAOContactsSource(program)}
        isListsOnly={isListsOnly}
        dataTest={`${dataTest}-list-picker-modal`}
        isOpen={true}
        selectedLists={defaultSelectedLists.map((source) => source.id)}
        closeModal={() => {
          setState({
            ...state,
            addingList: false,
          })
        }}
        submitLists={(lists) => {
          setState({
            ...state,
            loadingLists: lists,
            addingList: false,
          })
        }}
      />
    )

  return (
    <>
      {!!state.loadListSchemaError && (
        <ConfirmationModal isOpen body={state.loadListSchemaError} title={'Loading error'} closeModal={closeLoadListSchemaError} />
      )}
      {state.addingList && renderListPicker()}
      <Select
        dataTest={`${dataTest}-list-select`}
        className={className}
        value={state.previouslySelectedList}
        name={name}
        register={register(name, {
          onChange: listSelectUtils.listChange,
        })}
      >
        {getListOptions()}
        <option value={ADD_LIST}>
          --- {isListsOnly ? t('Click To Add New List To The Program') : t('Click To Add New List/Segment To The Program')} ---
        </option>
      </Select>
    </>
  )
}

export default ListSelect
