import React, { FC, ReactNode, useCallback, useState } from 'react'

import classNames from 'classnames'

import {
  DuplicateListingPageItem,
  ListPageAPI,
  ListPageCommonState,
  PageHeaderProps,
  SetStatusToast,
  SidebarProps,
  Update,
} from '@complex/ListingPage/Context/ListingPageCommon.context'
import ListingPageContainer from '@complex/ListingPage/ListingPageContainer'
import { CatalogLink } from '@complex/ListingPage/Utils/ListingPage.constants'
import FormsPickerModal from '@components/AssetPickers/FormsPickerModal/FormsPickerModal'
import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import Checkbox from '@components/Checkbox'
import MoveToFolderModal from '@components/MoveToFolderModal/MoveToFolderModal'
import { Status } from '@components/StatusToast/StatusToast'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ItemDto } from '@graphql/types/query-types'
import { useFormTemplatesListingPageRequests } from '@src/pages/listingPages/FormTemplates/GraphQL/FormTemplatesListingPageRequests.graphQL'
import { FormTemplatesCustomTableActions, tableProps } from '@src/pages/listingPages/FormTemplates/utils/FormTemplatesListingPage.tables'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import { allFormTemplatesFilter, FilterTypes } from '@utils/filter'
import {
  FormBuilderMessageData,
  FormBuilderMessage,
  isFormBuilderMessage,
  openFormBuilderWindow,
  FORM_TEMPLATE_SUCCESS_FLAG,
  getFormsPreviewUrl,
} from '@utils/forms'
import { UpdateState } from '@utils/types'

import { getCustomEmptyListingProps } from './utils/FormTemplatesListingPage.Helpers'

import './FormTemplatesListingPageContainer.css'

interface FormTemplatesListingPageContainerProps {
  className?: string
  dataTest?: string
}

type FormTemplateActionsWithModals =
  | FormTemplatesCustomTableActions.CREATE_FROM_FORM_PICKER
  | FormTemplatesCustomTableActions.CREATE_FROM_FORM_OPTIONS

export interface FormTemplatesListingPageContainerState {
  importFormSettings: boolean
  createTemplateFromFormId?: string
  windowMessages: FormBuilderMessage[]
}

const rootClass = 'form-templates-listing-page-container'

const FormTemplatesListingPageContainer: FC<FormTemplatesListingPageContainerProps> = (props: FormTemplatesListingPageContainerProps) => {
  const { dataTest = rootClass } = props
  const { deleteFormTemplatesRequest, duplicateFormTemplatesRequest, createTemplateFromFormRequest } = useFormTemplatesListingPageRequests()
  const { userAllowedToCreateContent, userAllowedToDeleteContent, hasReactWrapper } = useAccountSettings()
  const [containerValues, setContainerValues] = useState<FormTemplatesListingPageContainerState>({
    importFormSettings: false,
    windowMessages: [],
  })
  const updateContainer: UpdateState<FormTemplatesListingPageContainerState> = (fields) => {
    setContainerValues((containerValues) => ({ ...containerValues, ...fields }))
  }

  const { importFormSettings, createTemplateFromFormId, windowMessages } = containerValues

  const { t } = useTranslation()

  const sidebarProps: SidebarProps = {
    sidebarHeader: 'Template Manager',
    hasRecent: false,
    hasCreatedByMe: true,
    allItemFilter: allFormTemplatesFilter,
    customFilterSelected: false,
  }

  const deleteFormTemplates = async (items: ItemDto[]) =>
    deleteFormTemplatesRequest({ templateIds: items.map((item) => item.externalId ?? '').filter((id) => !!id) })

  const getItemPreview = (listPageValues: ListPageCommonState, update: Update) => {
    const { selectedRows } = listPageValues
    update({ previewUrl: getFormsPreviewUrl(selectedRows[0].externalId ?? '') })
  }

  const onWindowMessage = useCallback(
    async (event: MessageEvent<FormBuilderMessageData>) => {
      if (isFormBuilderMessage(event.data)) {
        const eventExists = windowMessages.filter((message) => message.formId === event.data.formId).length
        const source = event.source ? (event.source as Window) : null
        !eventExists && updateContainer({ windowMessages: [...windowMessages, { ...event.data, windowName: source?.name ?? '' }] })
      }
    },
    [windowMessages]
  )

  const onWindowActive = useCallback(
    async (_event: Event, update: Update, setStatusToast: SetStatusToast, items: ItemDto[]) => {
      if (sessionStorage.getItem(FORM_TEMPLATE_SUCCESS_FLAG)) {
        setStatusToast(t('Template successfully created'), Status.SUCCESS)
        sessionStorage.removeItem(FORM_TEMPLATE_SUCCESS_FLAG)
        return
      }

      if (!windowMessages.length) {
        return
      }

      const message = windowMessages[0]
      const isCreated = !items.some((item) => message.formId === item.externalId)

      const formTitle = message.formTitle
      const status = (
        <Typography
          text={isCreated ? 'ListPage.Forms.FormCreated' : 'ListPage.Forms.FormUpdated'}
          tagProps={{ bold: { weight: TextWeight.MEDIUM } }}
          values={{ formTitle }}
          inline
        />
      )
      setStatusToast(status, Status.SUCCESS)
      update({ fetchItems: true, fetchFolders: true, fetchFilterCounts: true })

      updateContainer({ windowMessages: [] })
    },
    [windowMessages]
  )

  const renderCustomModal = (customTableAction: FormTemplateActionsWithModals, listPageValues: ListPageCommonState, listPageAPI: ListPageAPI) => {
    const { update, setError } = listPageAPI
    const { folders } = listPageValues

    const closeModal = () => update({ customTableAction: undefined, showCustomModal: false, fetchItems: true })

    const customModal: {
      [key in FormTemplateActionsWithModals]: () => ReactNode
    } = {
      [FormTemplatesCustomTableActions.CREATE_FROM_FORM_PICKER]: () => {
        const onSelectForm = async (items: ItemDto[]) => {
          updateContainer({ createTemplateFromFormId: items[0].externalId })
          update({ customTableAction: FormTemplatesCustomTableActions.CREATE_FROM_FORM_OPTIONS })
        }

        return (
          <FormsPickerModal
            isOpen
            isSingleSelect
            excludeClassic
            primaryButtonText={'ListPage.FormTemplates.FormPicker.PrimaryButton'}
            secondaryButtonText={'ListPage.FormTemplates.FormPicker.SecondaryButton'}
            titleText={'ListPage.FormTemplates.FormPicker'}
            onSubmit={(items) => onSelectForm(items as ItemDto[])}
            onClose={closeModal}
          />
        )
      },
      [FormTemplatesCustomTableActions.CREATE_FROM_FORM_OPTIONS]: () => {
        const onCreateTemplate = async (folderId: number) => {
          const { data, errors } = await createTemplateFromFormRequest({
            formId: createTemplateFromFormId ?? '',
            folderId,
            importSettings: importFormSettings,
          })

          if (data) {
            openFormBuilderWindow('createTemplate', data.createTemplateFromForm.id, 'istemplate=true', hasReactWrapper)
            update({ fetchFolders: true, fetchFilterCounts: true })
            sessionStorage.setItem(FORM_TEMPLATE_SUCCESS_FLAG, '1')
          }

          if (errors) {
            setError(t('There was a problem creating your template'), errors)
          }
          closeModal()
        }

        return (
          <MoveToFolderModal
            folders={folders}
            addToFolder={true}
            description={t('Save to folder')}
            primaryButtonText={t('Create')}
            headerText={t('Create template')}
            folderPrimaryButtonText={t('Select')}
            options={
              <Checkbox label={t('Include Response and Ad Blocker settings')} onChange={(value) => updateContainer({ importFormSettings: value })} />
            }
            onMoveClick={onCreateTemplate}
            onClose={closeModal}
            isOpen
          />
        )
      },
    }

    if (!(customTableAction in customModal)) {
      return
    }

    return customModal[customTableAction]()
  }

  const onCreateTemplate = (update: Update) =>
    update({ showCustomModal: true, customTableAction: FormTemplatesCustomTableActions.CREATE_FROM_FORM_PICKER })

  const renderCreateButton = (update: Update) => (
    <Button
      buttonType={ButtonType.PRIMARY}
      iconPosition={ButtonIconPosition.LEFT}
      disabled={!userAllowedToCreateContent}
      onClick={() => onCreateTemplate(update)}
      className={classNames(`${rootClass}__create-template`, {
        [`${rootClass}__create-template-disabled`]: !userAllowedToCreateContent,
      })}
    >
      <Svg type={SvgType.ICON} name={SvgNames.plus} />
      {t('Create Template')}
    </Button>
  )

  const pageHeaderProps: PageHeaderProps = {
    pageTitle: 'Forms',
    renderPageHeaderContent: (update) => (
      <>
        <Button
          className={classNames(`${rootClass}__content-catalog`, `listing-page-header__catalog-button`)}
          buttonType={ButtonType.FLOAT}
          isLink
          to={CatalogLink.FORM}
          target={'_blank'}
        >
          <Svg type={SvgType.ICON} name={SvgNames.shareToCatalog} />
          <Typography lineHeight={LineHeight.SMALL} text={t('Content catalog')} className={'listing-page-header__catalog-text'} />
        </Button>
        {userAllowedToCreateContent ? (
          renderCreateButton(update)
        ) : (
          <Tooltip trigger={renderCreateButton(update)}>{t('Ask your administrator for permission to do this')}</Tooltip>
        )}
      </>
    ),
  }

  const goToCatalog = () => window.open(CatalogLink.FORM, '_blank')

  const duplicateFormTemplate = async (params: DuplicateListingPageItem, listPageAPI: ListPageAPI) => {
    const { listingPageItem, newName, tags, folderId } = params
    const { update, setStatusToast, setError } = listPageAPI

    const { data, errors } = await duplicateFormTemplatesRequest({ templateId: listingPageItem?.externalId ?? '-1', newName, tags, folderId })
    if (data) {
      openFormBuilderWindow(
        `editFormTemplate-${data.duplicateFormTemplate.id}`,
        `${data.duplicateFormTemplate.id}`,
        'istemplate=true',
        hasReactWrapper
      )
      setStatusToast(t('Your form template was duplicated'), Status.SUCCESS)

      update({ fetchFilterCounts: true, fetchFolders: true, fetchItems: true })
    } else {
      setError(t('Your form template could not be duplicated'), errors)
    }
  }

  return (
    <ListingPageContainer
      listingPageProps={{
        canPreview: true,
        hasTabs: true,
        canDeleteItems: userAllowedToDeleteContent,
        canEdit: userAllowedToCreateContent,
        canCreate: userAllowedToCreateContent,
        customDeleteItemsCall: deleteFormTemplates,
        customDuplicateItem: duplicateFormTemplate,
        canDuplicate: true,
        isDeleteCallWithBulkResponse: true,
        shareToChildAccountsIcon: SvgNames.formSimple,
        customPreviewItemCall: getItemPreview,
        getCustomEmptyListingProps: (setFilter, update, filter) =>
          getCustomEmptyListingProps(setFilter, update, onCreateTemplate, goToCatalog, filter),
        renderCustomModal,
        sidebarProps,
        pageHeaderProps,
        sortBy: [{ id: 'updatedAt', desc: true, fieldType: 'TIMESTAMP' }],
        tableProps: tableProps(t, userAllowedToCreateContent, hasReactWrapper),
        filterInfoHoverText: {
          [FilterTypes.CREATED_BY_ME]: 'Only includes templates created May 2023 or after',
        },
        onWindowMessage,
        onWindowActive,
      }}
      dataTest={dataTest}
      itemType={ItemType.FORM_TEMPLATE}
    />
  )
}

export default FormTemplatesListingPageContainer
