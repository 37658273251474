import React, { FC } from 'react'

import classNames from 'classnames'

import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './ErrorMessage.css'

type Props = {
  className?: string
  dataTest?: string
  type: ErrorType
  fileName?: string
}

export enum ErrorType {
  EMPTY_FILE = 'EMPTY_FILE',
  SINGLE_FILE = 'SINGLE_FILE',
  EXTENSION = 'EXTENSION',
  SIZE = 'SIZE',
}

const rootClass = 'error-message'

const ErrorMessage: FC<Props> = (props: Props) => {
  const { type, fileName, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()

  const ErrorTypes = {
    SINGLE_FILE: <Typography text={t('Please import one file at a time.')} type={TextType.BODY_TEXT_LIGHT} inline />,
    EXTENSION: (
      <>
        <div className={classNames(`${rootClass}__wrapper`, `${rootClass}__wrapper-extension`)}>
          <Typography text={t("Unfortunately, we can't process ")} type={TextType.BODY_TEXT_LIGHT} inline />
          <Tooltip position={'top'} trigger={<Typography text={fileName} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.BOLD} inline />}>
            <div className={`${rootClass}__filename`}>{fileName}</div>
          </Tooltip>
          <Typography text={'.'} type={TextType.BODY_TEXT_LIGHT} inline />
        </div>
        <Typography text={t('Please select a .csv file type.')} type={TextType.BODY_TEXT_LIGHT} />
      </>
    ),
    SIZE: (
      <div className={classNames(`${rootClass}__wrapper`, `${rootClass}__wrapper-size`)}>
        <Typography text={t('Your file, ')} type={TextType.BODY_TEXT_LIGHT} inline />
        <Tooltip position={'top'} trigger={<Typography text={fileName} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.BOLD} inline />}>
          <div className={`${rootClass}__filename`}>{fileName}</div>
        </Tooltip>
        <Typography text={', '} type={TextType.BODY_TEXT_LIGHT} inline />
        <Typography
          text={t('is too large to upload. Please reduce your file size to 1GB or less and try again.')}
          type={TextType.BODY_TEXT_LIGHT}
          inline
        />
      </div>
    ),
    EMPTY_FILE: (
      <div className={classNames(`${rootClass}__wrapper`, `${rootClass}__wrapper-empty`)}>
        <Typography text={t('Your file, ')} type={TextType.BODY_TEXT_LIGHT} inline />
        <Tooltip position={'top'} trigger={<Typography text={fileName} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.BOLD} inline />}>
          <div className={`${rootClass}__filename`}>{fileName}</div>
        </Tooltip>
        <Typography text={t(', is empty. Please upload a .csv file with some values.')} type={TextType.BODY_TEXT_LIGHT} inline />
      </div>
    ),
  }
  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {ErrorTypes[type]}
    </div>
  )
}

export default ErrorMessage
