import React from 'react'

import { TFunction } from 'i18next'

import { TableV2RowData } from '@components/TableV2/tableV2TS/interfaces'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType } from '@components/Typography/Typography'
import { textByFeatureId } from '@src/pages/RecommendedSegments/utils/RecommendedSegments.constants'
import { SegmentationCriteria } from '@src/pages/RecommendedSegments/utils/RecommendedSegments.interfaces'

export type SegmentationCriteriaRow = TableV2RowData<SegmentationCriteria> & SegmentationCriteria

export const getSelectedCriteriaRowsIds = (
  selectedCriteria: SegmentationCriteria[],
  availableCriteria: SegmentationCriteria[]
): Record<string, boolean> => {
  const selectedIds: Record<string, boolean> = {}
  selectedCriteria.forEach((criteria) => {
    const currentSelectedCriteria = availableCriteria.find((availableCriteria) => availableCriteria.category === criteria.category)
    if (currentSelectedCriteria) {
      const currentSelectedCriteriaIndex = availableCriteria.indexOf(currentSelectedCriteria)
      criteria.features.forEach((attributeSelected) => {
        const attributeId = `${currentSelectedCriteriaIndex}.${currentSelectedCriteria.features.indexOf(attributeSelected)}`
        selectedIds[attributeId] = true
      })
      if (criteria.features.length === availableCriteria[currentSelectedCriteriaIndex].features.length) {
        selectedIds[currentSelectedCriteriaIndex] = true
      }
    }
  })
  return selectedIds
}

export const getCriteriaSettingsColumns = (
  selectedAttributesAmountById: Map<number, number>,
  t: TFunction
): ColumnDefWithAdditionalProps<SegmentationCriteriaRow>[] => [
  {
    accessorKey: 'category',
    header: 'Criteria',
    textAlign: 'left',
    enableFlexCell: true,
    cell: ({ row: { original, originalSubRows, id } }) => {
      if (originalSubRows?.length) {
        return t(original.category)
      } else {
        const attributeId = id.split('.').pop()
        const attribute = attributeId ? original.features[parseInt(attributeId)] : ''
        return (
          <Tooltip
            alwaysShowOnHover
            position={'right'}
            trigger={
              <Typography
                text={t(textByFeatureId[attribute] ?? attribute)}
                type={original.disabled ? TextType.BODY_TEXT_LIGHT : TextType.BODY_TEXT}
              />
            }
            hide={!original.disabled}
          >
            {t('Some fields are not mapped to your CRM and can not be added to the AI Insights model')}
          </Tooltip>
        )
      }
    },
  },
  {
    accessorKey: 'features',
    header: '',
    textAlign: 'right',
    minSize: 100,
    maxSize: 100,
    cell: ({ row }) => (row.originalSubRows?.length ? `${selectedAttributesAmountById.get(parseInt(row.id))}/${row.original.features.length}` : ''),
  },
]
