import React, { FC, useCallback, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import { SvgNames } from '@components/Svg'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { prevPageLinks } from '@src/pages/EmailComposer/utils/EmailComposer.constants'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { useEmailComposerRequests } from '@utils/composer/emailComposer/GraphQL/EmailComposerRequests.graphQL'
import { setLocalStorageItem } from '@utils/window'

interface WebinarUnscheduleModalProps {
  dataTest?: string
}

const rootClass = 'webinar-unschedule-modal'

export const WebinarUnscheduleModal: FC<WebinarUnscheduleModalProps> = (props: WebinarUnscheduleModalProps) => {
  const { dataTest = rootClass } = props

  const {
    values: {
      message: { programId, groupId, taskId },
    },
    api: { update },
  } = useContext(EmailComposerContext)

  const { t } = useTranslation()
  const history = useHistory()

  const { unscheduleWebinarMessage } = useEmailComposerRequests()

  const handleClose = useCallback(() => {
    if (window.opener) {
      window.close()
    } else {
      history.push(prevPageLinks.webinar)
    }
  }, [history])

  const handleUnscheduleWebinar = useCallback(() => {
    if (programId && groupId && taskId) {
      unscheduleWebinarMessage({ programId, groupId, taskId }).then((data) => {
        if (data?.unScheduleWebinarMessage?.success) {
          update({ message: { isWebinarScheduled: false } })
          if (window.opener) {
            setLocalStorageItem('messageSaved', `${Date.now()}`)
          }
        }
      })
    }
  }, [programId, groupId, taskId, unscheduleWebinarMessage, update])

  return (
    <ConfirmationModal
      isOpen
      dataTest={dataTest}
      className={rootClass}
      title={t('Webinar.Unschedule.Modal.Title')}
      isYesNo
      okButtonText={t('Got it')}
      titleIcon={SvgNames.warning}
      yesButtonText={t('Webinar.Unschedule.Modal.YES')}
      body={<Typography className="push-down-x2 push-up-x2" text={t('Webinar.Unschedule.Modal.Description')} type={TextType.BODY_TEXT_LIGHT} />}
      onAnswer={(answer) => {
        if (answer === YesNo.YES) {
          handleUnscheduleWebinar()
        } else {
          handleClose()
        }
      }}
      closeModal={handleClose}
    />
  )
}
