import { gql } from '@apollo/client'

export default gql`
  query getLandingPageFolders {
    getLandingPageFolders {
      id
      name
    }
  }
`
