import React, { FC, ReactNode, useContext } from 'react'

import classNames from 'classnames'

import ScheduleSelector from '@complex/ScheduleSelector/ScheduleSelector'
import Button, { ButtonType } from '@components/Button'
import Container from '@components/Container'
import InfoTooltip, { InfoTooltipIconSize } from '@components/InfoTooltip/InfoTooltip'
import Loader from '@components/Loader'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { getExportOptions } from '@src/pages/ExportContacts/components/ExportOptionsScreen/utils/ExportOptionsScreen.utils'
import { ExportContactsSteps } from '@src/pages/ExportContacts/utils/ExportContacts.constants'
import { ExportContactsContext } from '@src/pages/ExportContacts/utils/ExportContacts.context'

import './ExportOptionsScreen.css'

interface ExportOptionsScreenProps {
  className?: string
  dataTest?: string
}

const rootClass = 'export-options-screen'

const ExportOptionsScreen: FC<ExportOptionsScreenProps> = (props: ExportOptionsScreenProps) => {
  const { dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const {
    values: { schedule, firstLineContains, fieldsSeparator, fieldQuotes, loadingExportFields },
    update,
    handleOnSectionChange,
  } = useContext(ExportContactsContext)

  const renderSection = (title: string, content: ReactNode, tooltip?: string) => (
    <div className={`${rootClass}__section`} key={title}>
      <div className={classNames({ [`${rootClass}__tooltip`]: tooltip })}>
        <Typography className={`${rootClass}__section-header`} text={t(title)} weight={TextWeight.MEDIUM} />
        {tooltip && <InfoTooltip iconSize={InfoTooltipIconSize.MEDIUM}>{tooltip}</InfoTooltip>}
      </div>
      <div className={`${rootClass}__section-content`}>{content}</div>
    </div>
  )

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {loadingExportFields ? (
        <Loader center />
      ) : (
        <>
          <Container title={t('ExportContacts.Sections.ExportOptions')} description={t('ExportContacts.Sections.ExportOptions.Description')}>
            <div className={`${rootClass}__frequency`}>
              <Typography
                text={t('ExportContacts.Sections.ExportOptions.Frequency')}
                className={`${rootClass}__frequency-label`}
                weight={TextWeight.MEDIUM}
              />
              <ScheduleSelector
                defaultValue={schedule}
                onChange={(schedule) => {
                  const isScheduled = schedule.type !== 'NEVER'
                  update({ schedule: { ...schedule, isScheduled: isScheduled, isUnscheduled: !isScheduled } })
                }}
                manualLabel={t('Off')}
                manualScheduleTooltip={{ show: false }}
                showHeader={false}
              />
            </div>
            {getExportOptions({ firstLineContains, fieldsSeparator, fieldQuotes }, update, t).map(
              (option) => !option.hidden && renderSection(option.title, option.content, option.tooltip)
            )}
          </Container>
          <div className={`${rootClass}__button-next`}>
            <Button buttonType={ButtonType.PRIMARY} onClick={() => handleOnSectionChange(ExportContactsSteps.CHOOSE_FIELDS, true)}>
              {t('Next')}
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default ExportOptionsScreen
