import React, { FC, Fragment, ReactNode, useContext, useMemo } from 'react'

import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'
import { Box } from '@src/pages/programs/UpgradeCommonComponents/Box/Box'
import { StepPill } from '@src/pages/programs/UpgradeCommonComponents/StepPill/StepPill'
import {
  UpgradedProgramCopyStep,
  UpgradedProgramRecordSetStep,
  UpgradedProgramSendStep,
  UpgradedProgramStep,
  UpgradedSourceUpgradeStepType,
  UpgradeManagerContext,
} from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'
import { StepToUpgradeType } from '@src/pages/programs/upgradeManager/helper/UpgradeManager.helper'
import {
  getChangeFieldValueStepValues,
  getCopyToListStepSource,
  getSendEmailMessageStepSource,
  getSourceUpgradeStepSource,
} from '@src/pages/programs/UpgradePrograms/components/ReviewUpgrade/sections/RemovedStepsSection/RemovedStepsSection.utils'
import { ProgramStepType } from '@utils/program/program.constants'

import './RemovedStepsSection.css'

interface RemovedStepsSectionProps {
  steps: UpgradedProgramStep[]
}

const rootClass = 'removed-steps-section'
const rootText = 'UpgradePrograms.Page.ReviewUpgrade.RemovedStepsSection.'

export const RemovedStepsSection: FC<RemovedStepsSectionProps> = ({ steps }: RemovedStepsSectionProps) => {
  const {
    values: {
      program: { sources },
    },
  } = useContext(ProgramManagerContext)
  const {
    values: { stepsToUpgrade },
  } = useContext(UpgradeManagerContext)
  const { t } = useTranslation()
  const originalSteps = stepsToUpgrade.filter((step) => steps.some((upgradedStep) => upgradedStep.stepId === step.stepId))

  const stepValues: { [key in StepToUpgradeType]: (step: UpgradedProgramStep) => ReactNode } = useMemo(
    () => ({
      [ProgramStepType.FIELD_SET]: (step: UpgradedProgramStep) => getChangeFieldValueStepValues(step as UpgradedProgramRecordSetStep, sources, t),
      [ProgramStepType.COPY]: (step: UpgradedProgramStep) => getCopyToListStepSource(step as UpgradedProgramCopyStep, sources, t),
      [ProgramStepType.SEND]: (step: UpgradedProgramStep) => getSendEmailMessageStepSource(step as UpgradedProgramSendStep),
      [ProgramStepType.BRANCH]: (step: UpgradedProgramStep) => getSourceUpgradeStepSource(step as UpgradedSourceUpgradeStepType, sources, t),
      [ProgramStepType.WAIT_UNTIL_IN_SEGMENT]: (step: UpgradedProgramStep) =>
        getSourceUpgradeStepSource(step as UpgradedSourceUpgradeStepType, sources, t),
    }),
    [originalSteps]
  )

  return (
    <div className={rootClass}>
      <Typography text={t(`${rootText}Title`, { count: originalSteps.length })} type={TextType.VALIDATION_ERROR_LARGE} weight={TextWeight.BOLD} />
      <Box className={`${rootClass}__steps`}>
        {originalSteps.map((step, index) => (
          <Fragment key={step.stepId}>
            {index !== 0 && <div className={'divider'} />}
            <div>
              <Typography text={t('Step')} />
              <StepPill step={step} />
              {stepValues[step.stepType as StepToUpgradeType](step)}
            </div>
          </Fragment>
        ))}
      </Box>
    </div>
  )
}
