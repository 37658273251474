import { gql } from '@apollo/client'

export default gql`
  mutation stopAbTestMessage($messageId: String!) {
    stopAbTestMessage(messageId: $messageId) {
      status
      message
    }
  }
`
