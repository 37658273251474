import React from 'react'

import { TFunction } from 'i18next'

import { FilterCounts, RenderCustomFiltersParams } from '@complex/ListingPage/Context/ListingPageCommon.context'
import Checkbox from '@components/Checkbox'
import CheckboxGroup from '@components/CheckboxGroup/CheckboxGroup'
import { SvgNames } from '@components/Svg'
import { getMoreFiltersSubTypes } from '@utils/forms'
import { toTrainCase } from '@utils/strings'

const rootClass = `forms-picker-modal`

const getMoreFilters = (connectedCrm: string, filterCounts: FilterCounts) => {
  return getMoreFiltersSubTypes(connectedCrm)
    .filter((subType) => {
      return (filterCounts[subType.name] ?? 0) > 0
    })
    .sort((a, b) => (a.name > b.name ? 1 : -1))
}

export const renderCustomFilters = (
  { activeSubTypes, menuActions, filterCounts, renderCustomFilterWithCount }: RenderCustomFiltersParams,
  connectedCrm: string,
  t: TFunction
) => {
  const moreFilters = getMoreFilters(connectedCrm, filterCounts)
  if (!moreFilters.length) {
    return []
  }

  return [
    {
      header: t('More Filters'),
      isOpen: true,
      icon: SvgNames.toggles,
      dataTest: 'menu-items',
      content: (
        <>
          <CheckboxGroup className={`listing-page__custom-filters`} verticalLayout>
            {moreFilters.map((subType) =>
              renderCustomFilterWithCount(
                <Checkbox
                  key={subType.name}
                  label={subType.label}
                  checked={activeSubTypes.includes(subType.name)}
                  onChange={() => menuActions.clickSubType(subType.name)}
                  dataTest={`${rootClass}-${toTrainCase(subType.label)}-filter`}
                />,
                filterCounts[subType.name],
                subType.name
              )
            )}
          </CheckboxGroup>
        </>
      ),
    },
  ]
}
