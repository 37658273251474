import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import MoreOptions from '@components/MoreOptions/MoreOptions'
import Radio from '@components/Radio'
import { RadioProps } from '@components/Radio/Radio'
import { isFunction } from '@utils/utils'

import './RadioWithOptions.css'

interface RadioWithOptionsProps extends RadioProps {
  className?: string
  dataTest?: string
  children?: ReactNode | ((checked: boolean) => ReactNode)
  showOptionsWhenUnchecked?: boolean
}

const rootClass = 'radio-with-options'

const RadioWithOptions: FC<RadioWithOptionsProps> = (props: RadioWithOptionsProps) => {
  const { dataTest = rootClass, checked, children, className = '', showOptionsWhenUnchecked = false, ...radioProps } = props
  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Radio className={classNames(`${rootClass}__radio`, { [`${className}__radio`]: className })} checked={checked} {...radioProps} />
      {(checked || showOptionsWhenUnchecked) && children && (
        <MoreOptions className={classNames(`${rootClass}__options-container`, { [`${className}__options-container`]: className })}>
          {isFunction(children) ? children(!!checked) : children}
        </MoreOptions>
      )}
    </div>
  )
}

export default RadioWithOptions
