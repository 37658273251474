import { gql } from '@apollo/client'

export default gql`
  mutation createFormFromTemplate($templateId: String!, $folderId: Int!) {
    createFormFromTemplate(templateId: $templateId, folderId: $folderId) {
      id
      name
    }
  }
`
