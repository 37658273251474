import React, { useContext } from 'react'
import { Row } from 'react-table'

import classNames from 'classnames'
// eslint-disable-next-line no-restricted-imports
import { format, isThisWeek, isToday, isTomorrow } from 'date-fns'
import { TFunction } from 'i18next'

import { TableActions } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.constants'
import { getActionProps } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.utils'
import {
  ListingPageCommonContext,
  ListPageCommonState,
  ListPageTableActionCustomProps,
  TableProps,
  Update,
} from '@complex/ListingPage/Context/ListingPageCommon.context'
import Button, { ButtonType } from '@components/Button/Button'
import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { RowAction } from '@components/Table/Table'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import ListProgramInfoHover from '@src/pages/listingPages/ListMaintenancePrograms/components/ListProgramInfoHover/ListProgramInfoHover'
import { CellContext } from '@tanstack/react-table'
import { ItemType } from '@utils/categorization'
import { ListMaintenanceProgramDto, SetProgram } from '@utils/listingPage/listMaintenancePrograms.utils'

export enum ListMaintenanceProgramsCustomTableActions {
  VIEW_PROGRAM_DETAILS = 'VIEW_PROGRAM_DETAILS',
  RUN_NOW = 'RUN_NOW',
  EDIT = 'EDIT',
}

const rowActionCustomProps: ListPageTableActionCustomProps[] = [
  { name: ListMaintenanceProgramsCustomTableActions.EDIT, position: 0, isInDropdown: false },
  { name: ListMaintenanceProgramsCustomTableActions.RUN_NOW, position: 1, isInDropdown: false },
  { name: 'ADD_TO_FAVORITES', position: 2, isInDropdown: false },
  { name: 'ADD_TO_FOLDER', position: 3, isInDropdown: true },
  { name: 'MOVE_TO_FOLDER', position: 4, isInDropdown: true },
  { name: 'REMOVE_FROM_FOLDER', position: 5, isInDropdown: true },
  { name: 'DELETE', position: 6, isInDropdown: true },
]

const headerActionCustomProps: ListPageTableActionCustomProps[] = [
  { name: 'ADD_TO_FAVORITES', position: 0, isInDropdown: false },
  { name: 'MANAGE_TAGS', position: 1, isInDropdown: false },
  { name: 'MOVE_TO_FOLDER', position: 2, isInDropdown: false },
  { name: 'REMOVE_FROM_FOLDER', position: 3, isInDropdown: false },
  { name: 'DELETE', position: 4, isInDropdown: true },
]

const onCustomTableAction = (
  customAction: ListMaintenanceProgramsCustomTableActions,
  listingPageUpdate: Update,
  listingPageState: ListPageCommonState,
  runProgram: (program: ListMaintenanceProgramDto, update: Update, state: ListPageCommonState) => void,
  setProgram: SetProgram,
  program?: ListMaintenanceProgramDto
) => {
  const { selectedRows } = listingPageState
  const actions: { [key in ListMaintenanceProgramsCustomTableActions]: () => void } = {
    [ListMaintenanceProgramsCustomTableActions.EDIT]: () => {
      setProgram(selectedRows[0] as ListMaintenanceProgramDto, { detailsPage: true })
    },
    [ListMaintenanceProgramsCustomTableActions.RUN_NOW]: () => {
      runProgram(selectedRows[0] as ListMaintenanceProgramDto, listingPageUpdate, listingPageState)
    },
    [ListMaintenanceProgramsCustomTableActions.VIEW_PROGRAM_DETAILS]: () => {
      setProgram(program)
    },
  }

  actions[customAction]()
}

const getCustomRowActions = (tableActions: TableActions, userAllowedToCreatePrograms: boolean, t: TFunction): RowAction[] => [
  {
    label: t('Run now'),
    icon: SvgNames.play,
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(ListMaintenanceProgramsCustomTableActions.RUN_NOW, row),
    hidden: ({ original: { valid } }: Row<ListMaintenanceProgramDto>) => !valid,
    ...getActionProps(ListMaintenanceProgramsCustomTableActions.RUN_NOW, rowActionCustomProps),
  },
  {
    hidden: !userAllowedToCreatePrograms,
    label: t('Edit'),
    icon: SvgNames.pencil,
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(ListMaintenanceProgramsCustomTableActions.EDIT, row),
    ...getActionProps(ListMaintenanceProgramsCustomTableActions.EDIT, rowActionCustomProps),
  },
]

export const getNextRunDate = (nextRun: number) => {
  let dateFormat = "MMM d 'at' h:mm a"
  if (isToday(nextRun)) {
    dateFormat = "'Today at' h:mm a"
  } else if (isTomorrow(nextRun)) {
    dateFormat = "'Tomorrow at' h:mm a"
  } else if (isThisWeek(nextRun)) {
    dateFormat = "EEEE 'at' h:mm a"
  }
  return format(nextRun, dateFormat)
}

const useRenderProgramName = (program: ListMaintenanceProgramDto, rootClass: string, search?: string) => {
  const {
    update,
    values,
    values: {
      listingPageProps: {
        tableProps: { onCustomTableAction, clickableColumnOptions },
      },
    },
  } = useContext(ListingPageCommonContext)

  const handleClick = () => {
    const { action } = { ...clickableColumnOptions }
    if (action?.customTableAction && onCustomTableAction) {
      onCustomTableAction(action.customTableAction, update, values, program)
    } else {
      update({ selectedRows: [program], ...action })
    }
  }

  return (
    <div
      key={`program-${program.externalId}`}
      className={`${rootClass}__program-name-cell`}
      role={'button'}
      tabIndex={0}
      onClick={handleClick}
      onKeyDown={(keyDownEvent) => (keyDownEvent.key === 'Enter' ? handleClick() : undefined)}
    >
      {!program.valid && <Svg name={SvgNames.warningSolid} type={SvgType.ICON} />}
      <Tooltip
        trigger={search ? renderBoldTextOnMatch(program.name, search, true) : <Typography text={program.name} inline />}
        position={'top'}
        ellipOnTrigger
      >
        {program.name}
      </Tooltip>
      <ListProgramInfoHover program={program} isHidden />
    </div>
  )
}

export const renderSearchColumns = (
  rootClass: string,
  t: TFunction,
  setProgram: SetProgram,
  canCreate: boolean,
  search?: string
): ColumnDefWithAdditionalProps<ListMaintenanceProgramDto, any>[] => [
  {
    header: 'Program Name',
    accessorKey: 'name',
    textAlign: 'left',
    hideTooltip: true,
    enableFlexCell: true,
    cell: ({ row: { original } }) => (() => useRenderProgramName(original, rootClass, search))(),
  },
  {
    header: 'Tags',
    accessorKey: 'tags',
    textAlign: 'left',
    enableFlexCell: true,
    enableCustomCellValue: true,
    maxSize: 180,
  },
  {
    header: 'Next Run',
    accessorKey: 'nextRunTS',
    textAlign: 'left',
    maxSize: 220,
    cell: ({ row: { original }, getValue }: CellContext<ListMaintenanceProgramDto, ListMaintenanceProgramDto['nextRunTS']>) => {
      const { scheduled, valid } = original
      return valid ? (
        <Typography text={scheduled ? getNextRunDate(getValue()) : t('Manual')} />
      ) : (
        <Tooltip
          hide={canCreate}
          trigger={
            <Button
              buttonType={ButtonType.TEXT}
              className={classNames({ [`${rootClass}__fix-errors-cell`]: !canCreate })}
              onClick={() => canCreate && setProgram(original, { fixErrors: true })}
            >
              <Typography text={t('Fix errors')} type={canCreate ? TextType.LINK : TextType.BODY_TEXT_LIGHT} weight={TextWeight.MEDIUM} />
            </Button>
          }
        >
          {t('Ask your administrator for permission to do this')}
        </Tooltip>
      )
    },
  },
]

const renderColumns = (
  rootClass: string,
  setProgram: SetProgram,
  canCreate: boolean,
  t: TFunction
): ColumnDefWithAdditionalProps<ListMaintenanceProgramDto, any>[] => [
  {
    header: 'Program Name',
    accessorKey: 'name',
    textAlign: 'left',
    enableClickableCell: true,
    hideTooltip: true,
    cell: ({ row: { original } }) => (() => useRenderProgramName(original, rootClass))(),
  },
  {
    header: 'Tags',
    accessorKey: 'tags',
    textAlign: 'left',
    maxSize: 180,
    disableSorting: true,
    enableCustomCellValue: true,
  },
  {
    header: 'Next Run',
    accessorKey: 'nextRunTS',
    textAlign: 'left',
    maxSize: 220,
    cell: ({ row: { original }, getValue }: CellContext<ListMaintenanceProgramDto, ListMaintenanceProgramDto['nextRunTS']>) => {
      const { scheduled, valid } = original
      return valid ? (
        <Typography text={scheduled ? getNextRunDate(getValue()) : t('Manual')} />
      ) : (
        <Tooltip
          hide={canCreate}
          trigger={
            <Button
              buttonType={ButtonType.TEXT}
              className={classNames({ [`${rootClass}__fix-errors-cell`]: !canCreate })}
              onClick={() => canCreate && setProgram(original, { fixErrors: true })}
            >
              <Typography text={t('Fix errors')} type={canCreate ? TextType.LINK : TextType.BODY_TEXT_LIGHT} weight={TextWeight.MEDIUM} />
            </Button>
          }
        >
          {t('Ask your administrator for permission to do this')}
        </Tooltip>
      )
    },
  },
]

export const getTableProps = (
  rootClass: string,
  runProgram: (program: ListMaintenanceProgramDto) => void,
  setProgram: SetProgram,
  userAllowedToCreatePrograms: boolean,
  t: TFunction
): TableProps => ({
  actonAssetType: ItemType.LIST_MAINTENANCE_PROGRAM,
  clickableColumnOptions: {
    colIndex: 0,
    action: {
      customTableAction: ListMaintenanceProgramsCustomTableActions.VIEW_PROGRAM_DETAILS,
    },
  },
  columns: renderColumns(rootClass, setProgram, userAllowedToCreatePrograms, t),
  getCustomRowActions: (actions) => getCustomRowActions(actions, userAllowedToCreatePrograms, t),
  hasAutoSelectedRows: false,
  hasExpander: false,
  headerActionCustomProps,
  listPage: 'ListMaintenancePrograms',
  onCustomTableAction: (customAction, listingPageUpdate, listPageValues, selectedItem) => {
    onCustomTableAction(customAction, listingPageUpdate, listPageValues, runProgram, setProgram, selectedItem as ListMaintenanceProgramDto)
  },
  renderSearchColumns: (_searchInAllItems, _currentFolder, search) =>
    renderSearchColumns(rootClass, t, setProgram, userAllowedToCreatePrograms, search),
  rowActionCustomProps,
  shareModalText: '',
})
