import { gql } from '@apollo/client'

export default gql`
  mutation upsertMessage($isWelcomeMessage: Boolean!, $accountId: Long!, $smsMessage: SmsMessageInput, $userId: String) {
    upsertMessage(isWelcomeMessage: $isWelcomeMessage, accountId: $accountId, smsMessage: $smsMessage, userId: $userId) {
      accountId
      description
      messageText
      smsMessageId
      status
      title
    }
  }
`
