import React, { FC, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/Button'
import InfoHoverCard from '@components/InfoHoverCard/InfoHoverCard'
import Svg, { SvgNames, SvgType } from '@components/Svg/index'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { LEARN_MORE_URL, UPSELL_LINK } from '@src/pages/reports/components/reports.constants'

import './AnalyticsUpsellInfoHover.css'

interface AnalyticsUpsellInfoHoverProps {
  className?: string
  dataTest?: string
}

const rootClass = 'analytics-upsell-info-hover'

const AnalyticsUpsellInfoHover: FC<AnalyticsUpsellInfoHoverProps> = (props: AnalyticsUpsellInfoHoverProps) => {
  const { dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const [iconHovered, setIconHovered] = useState(false)

  const header = <Typography text={t('Want to make this liveboard truly yours?')} type={TextType.BANNER_HEADER} weight={TextWeight.BOLD} />

  const onMouseEnter = () => setIconHovered(true)

  const onMouseExit = () => setIconHovered(false)

  return (
    <InfoHoverCard
      header={header}
      icon={
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseExit} className={`${rootClass}__icon-container`}>
          {iconHovered && <Svg className={`${rootClass}__icon`} name={SvgNames.lightBulbIcon} type={SvgType.VERY_LARGE_ICON} />}
          {!iconHovered && <Svg className={`${rootClass}__icon`} name={SvgNames.lightBulbIconEmpty} type={SvgType.VERY_LARGE_ICON} />}
        </div>
      }
      className={classNames(rootClass, className)}
      dataTest={dataTest}
    >
      <Typography text={t('With Advanced Analytics, you can customize & create your own data analysis.')} weight={TextWeight.MEDIUM_LIGHT} />
      <Button buttonType={ButtonType.PRIMARY} onClick={() => window.open(UPSELL_LINK, `_blank`)} className={`${rootClass}__action`}>
        {t('Upgrade to Analytics')}
      </Button>
      <TextLink
        text={'Learn more about Analytics'}
        onClick={() => window.open(LEARN_MORE_URL, `_blank`)}
        size={TextLinkSize.LARGE}
        className={`${rootClass}__learn`}
      />
    </InfoHoverCard>
  )
}

export default AnalyticsUpsellInfoHover
