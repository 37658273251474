import React, { FC, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { bindActionCreators, compose } from 'redux'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { useTranslation } from '@const/globals'
import setCustomLink from '@graphql/mutations/setCustomLink'
import loadCustomLinkTab from '@graphql/queries/loadCustomLinkTab'
import { CustomLinkItemInput, MutationSetCustomLinkArgs, SetCustomLinkMutation } from '@graphql/types/mutation-types'
import { LoadCrmSalesUsersQueryVariables, LoadCustomLinkTabQuery } from '@graphql/types/query-types'
import { StatusToastType } from '@interface/StatusToast'
import CustomLink from '@src/pages/Content/Branding/tabs/CustomLink/CustomLink'
import { loadAccount, AccountActions } from '@utils/account/actions'
import mapStateToProps from '@utils/navigation/state/mapStateToProps'
import { logNewRelicError } from '@utils/new-relic.utils'

export type SortedCustomLinkInfo = { key: keyof Omit<CustomLinkItemInput, 'hidden'>; value: string }[]

interface Props {
  setToastStatus: (value: StatusToastType) => void
}

type AllProps = Props & { loadAccount: AccountActions['loadAccount'] }

const CustomLinkContainer: FC<AllProps> = ({ setToastStatus, loadAccount }) => {
  const { t } = useTranslation()
  const client = useApolloClient()
  const [customLinkInfo, setCustomLinkInfo] = useState<CustomLinkItemInput>({ url: '', text: '', hint: '', hidden: false })

  const { data, loading, error } = useQuery<LoadCustomLinkTabQuery, LoadCrmSalesUsersQueryVariables>(loadCustomLinkTab, {
    client,
    fetchPolicy: 'network-only',
    variables: { userTypes: '', forcePull: false },
    notifyOnNetworkStatusChange: true,
  })

  const [setMutation, { loading: loadingSet }] = useMutation<SetCustomLinkMutation, MutationSetCustomLinkArgs>(setCustomLink, {
    client,
    fetchPolicy: 'no-cache',
  })

  const handleSetCustomLink = useCallback<(customLinkItemInput: CustomLinkItemInput) => void>(
    (customLinkItemInput) => {
      return setMutation({
        variables: {
          customLinkItemInput,
        },
      })
        .then(({ data }) => {
          if (data?.setCustomLink?.status === 'ok') {
            const { url = '', hint = '', text, hidden = false } = data.setCustomLink
            setCustomLinkInfo({ url, hint, text, hidden })
            setToastStatus({
              showStatus: true,
              title: t('Set.Custom.Link.Success.Title', { context: hidden ? 'hide' : 'show' }),
              statusMessage: t('Set.Custom.Link.Success.Message', { context: hidden ? 'hide' : 'show' }),
              successStatus: true,
            })
            loadAccount({ client, loading: false })
          } else {
            setToastStatus({
              showStatus: true,
              statusMessage: t('Something went wrong on our end. Please try again'),
              successStatus: false,
            })
          }
        })
        .catch((error) => {
          logNewRelicError(error)
        })
    },
    [t, setMutation, loadAccount]
  )

  useEffect(() => {
    const { hint = '', text = '', url = '', hidden = false } = { ...data?.loadCustomLinkTab }
    setCustomLinkInfo({ hint, text, url, hidden })
  }, [data?.loadCustomLinkTab])

  if (error) {
    logNewRelicError(error)
  }

  return <CustomLink info={customLinkInfo} setInfo={handleSetCustomLink} loading={loading || loadingSet} />
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({ loadAccount }, dispatch)

export default compose<React.ComponentType<Props>>(connect(mapStateToProps, mapDispatchToProps))(CustomLinkContainer)
