import React, { useCallback, useState } from 'react'

import classNames from 'classnames'

import DropDown from '@components/DropDown'
import { DropDownType } from '@components/DropDown/DropDown'
import DropDownActions, { MenuItem } from '@components/DropDownActions/DropDownActions'
import { RowAction } from '@components/TableV2/tableV2TS/interfaces'
import { useTranslation } from '@const/globals'
import { Row } from '@tanstack/react-table'
import { isFunction } from '@utils/utils'

import './TableV2RowDropDownActions.css'

interface Props<T> {
  row: Row<T>
  dataTest?: string
  rowActions: RowAction<T>[]
  onToggleDropDown?: (open: boolean) => void
}

const rootClass = 'tableV2-row-drop-down-actions'

export const TableV2RowDropDownActions = <T extends {}>({ dataTest = rootClass, onToggleDropDown, rowActions, row }: Props<T>) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const { t } = useTranslation()

  const dropdownActions: MenuItem[] = rowActions.map(
    ({ disabled, hidden, tooltipMessage, icon, topSection, label, onClick, inFooter, footerSectionLine, hasTooltip }) => {
      const actionIcon = isFunction(icon) ? icon(row) : icon
      return {
        disabled,
        tooltipMessage,
        icon: actionIcon,
        onClick,
        hidden,
        topSection,
        row,
        text: label,
        inFooter,
        footerSectionLine,
        hasTooltip,
      } as MenuItem
    }
  )

  const closeDropDown = useCallback(
    (open: boolean) => {
      setDropdownOpen(open)
      setTimeout(() => {
        onToggleDropDown?.(open)
      })
    },
    [onToggleDropDown]
  )

  return (
    <DropDown
      dataTest={dataTest}
      isOpen={dropdownOpen}
      toggleOpen={closeDropDown}
      hasOverflowIcon
      sideOffset={8}
      overflowIconTooltipText={t('More actions')}
      type={DropDownType.STYLED}
      className={classNames(rootClass, { [`${rootClass}__open`]: dropdownOpen })}
    >
      <DropDownActions menuItems={dropdownActions} closeDropDown={() => closeDropDown(false)} />
    </DropDown>
  )
}
