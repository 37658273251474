import { gql } from '@apollo/client'

export default gql`
  query getListMaintenanceProgramErrors($programId: String!) {
    getListMaintenanceProgramErrors(programId: $programId) {
      status
      valid
      stepErrors {
        errors
        id
        name
        type
      }
      mainErrors
      exitErrors
      isRunning
    }
  }
`
