import React, { FC, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import Table, { TableProps } from '@components/Table/Table'

import './TableWithLoader.css'

export type TableWithLoaderProps = TableProps & {
  loading: boolean
  autoHeight?: boolean
  fixedHeight?: number
}

const rootClass = 'table-with-loader'

/**
 * @deprecated use <TableV2 instead
 */
const TableWithLoader: FC<TableWithLoaderProps> = (props: TableWithLoaderProps) => {
  const { loading = false, emptyText, fixedHeight, data, dataTest = rootClass, className = '', autoHeight } = props
  const [divHeight, setDivHeight] = useState(0)

  const tableEl = useRef<HTMLDivElement>(null)

  const tableProps: TableProps = { ...props }

  useEffect(() => {
    if (!loading) {
      setDivHeight(tableEl?.current?.clientHeight || 0)
    }
  }, [loading])

  return (
    <div
      className={classNames(rootClass, className)}
      ref={tableEl}
      style={{
        minHeight: autoHeight ? divHeight : fixedHeight ? fixedHeight : 0,
      }}
    >
      {loading && (
        <div className={`${rootClass}__loader-wrapper`}>
          <Loader blackout={false} loaderType={LoaderTypes.page} />
        </div>
      )}
      <div className={loading ? `${rootClass}__table-loading-state` : ''}>
        <Table dataTest={dataTest} className={className} {...tableProps} emptyText={loading ? '' : emptyText} data={loading ? [] : data} />
      </div>
    </div>
  )
}

export default TableWithLoader
