import React, { FC } from 'react'

import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './ReportMappingHeader.css'

export interface ReportMappingHeaderProps {
  dataTest?: string
  className?: string
}

const rootClass = 'report-mapping-header'

const ReportMappingHeader: FC<ReportMappingHeaderProps> = (props: ReportMappingHeaderProps) => {
  const { dataTest = rootClass } = props

  const { t } = useTranslation()

  return (
    <div className={rootClass} data-test={dataTest}>
      <Typography type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} text={t('Map CRM fields to Act-On Reporting Fields')} />
      <Typography className={`${rootClass}__subtitle`} type={TextType.BODY_TEXT_LIGHT} text={t('Report.Mapping.Header.Subtitle')} />
    </div>
  )
}

export default ReportMappingHeader
