import { gql } from '@apollo/client'

export default gql`
  query retrieveBeeLandingPage($pageId: String) {
    retrieveBeeLandingPage(pageId: $pageId) {
      messageType
      beeEditorPage
      emailContentHtml
      settings {
        id
        name
        createdAt
        createdBy
        lastUpdated
        lastUpdatedBy
        enabled
        embeddedForms {
          id
          name
        }
        defaultUrlGroup {
          id
          name
          publicUrl
          seoFriendlyUrl
          shortUrl
        }
        urlGroups {
          id
          name
          publicUrl
          seoFriendlyUrl
          shortUrl
        }
        prettyURLPrefix
        hasUrlShortener
        redirectConfig {
          redirect
          redirectType
          options
        }
        seoInfo {
          pageName
          image {
            accountId
            id
            name
            url
          }
          title
          pageDescription
          allowIndexing
        }
        isBee
        customCode {
          customJavascript
          customCss
          jsFiles {
            id
            name
            content
          }
          cssFiles {
            id
            name
            content
          }
        }
        publishInfo {
          isLandingPagePublished
          hasUnpublishedChanges
          lastPublishedAt
        }
      }
      isValidJson
    }
  }
`
