import React, { FC, useEffect, useRef, useState, KeyboardEvent } from 'react'
import { useFormContext } from 'react-hook-form'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Container from '@components/Container'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { Source } from '@src/pages/Webhooks/IncomingWebhooks/IncomingWebhooksContext'

import './WebhookSourceSelector.css'

interface WebhookSourceSelectorProps {
  className?: string
  dataTest?: string
  source?: Source
  onChange?: (source: Source) => void
  disabled?: boolean
}

const rootClass = 'webhook-source-selector'
const rootTranslation = 'Incoming.Webhooks.CreateModal.SourceSelector'

const WebhookSourceSelector: FC<WebhookSourceSelectorProps> = (props: WebhookSourceSelectorProps) => {
  const { dataTest = rootClass, className = '', source = Source.Custom, onChange, disabled = false } = props

  const { register } = useFormContext()

  const [showMenu, setShowMenu] = useState<boolean>(false)

  const containerRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLDivElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()

  useEffect(() => {
    register('source')
  }, [])

  useEffect(() => {
    const clickListener = (e: MouseEvent) => {
      if (!menuRef.current?.contains(e.target as Node) && !buttonRef.current?.contains(e.target as Node)) {
        setShowMenu(false)
      }
    }

    window.addEventListener('click', clickListener, { capture: true })
    return () => {
      window.removeEventListener('click', clickListener, { capture: true })
    }
  }, [])

  useEffect(() => {
    const closeDropdown = () => setShowMenu(false)

    if (containerRef.current && menuRef.current) {
      const { width, left, top, height } = containerRef.current.getBoundingClientRect()
      menuRef.current.style.width = `${width}px`
      menuRef.current.style.left = `${left}px`
      menuRef.current.style.top = `${top + height}px`
    }

    const pageContainer = document.getElementById('page-container')
    const modalBody = document.getElementById('modal-body')
    const scrollWrapper = modalBody?.contains(containerRef.current) ? modalBody : pageContainer
    scrollWrapper?.addEventListener('scroll', closeDropdown)
    window.addEventListener('resize', closeDropdown)
    return () => {
      scrollWrapper?.removeEventListener('scroll', closeDropdown)
      window.removeEventListener('resize', closeDropdown)
    }
  }, [containerRef.current, menuRef.current, showMenu])

  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }

  const onSubmitOption = (source: Source) => {
    setShowMenu(false)
    onChange?.(source)
  }

  const onKeyDown = (event: KeyboardEvent<HTMLDivElement>, source: Source) => {
    if (event.key === ' ' || event.key === 'Enter') {
      onSubmitOption(source)
    }
  }

  const contentBySource: { [key in Source]: { name: string; description: string; icon: SvgNames } } = {
    [Source.Custom]: {
      name: 'Custom webhook',
      description: 'Configure custom webhook rules and content mappings',
      icon: SvgNames.webhooks,
    },
    [Source.Calendly]: {
      name: 'Calendly webhook',
      description: 'Configure an incoming webhook to connect Act-On to Calendly',
      icon: SvgNames.calendlyLogo,
    },
  }

  const selectedSource = contentBySource[source]

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest} ref={containerRef}>
      <div className={`${rootClass}__label`}>
        <Typography
          text={`${rootTranslation}.Label`}
          tagProps={{ bold: { weight: TextWeight.MEDIUM, type: TextType.BODY_TEXT } }}
          type={TextType.BODY_TEXT_LIGHT}
          inline
        />
      </div>
      <Container className={`${rootClass}__container`}>
        <Svg name={selectedSource.icon} type={SvgType.VERY_LARGE_ICON} />
        <div data-test={`${dataTest}-webhook-type`}>
          <Typography text={t(selectedSource.name)} weight={TextWeight.MEDIUM} />
          <Typography text={t(selectedSource.description)} type={TextType.BODY_TEXT_SMALL_LIGHT} lineHeight={LineHeight.MEDIUM_SMALL} />
        </div>
        {!disabled && (
          <Button dataTest={`${dataTest}-show-menu-button`} buttonType={ButtonType.INFO} onClick={toggleMenu} register={buttonRef}>
            {t('Change')}
          </Button>
        )}
      </Container>
      <div className={`${rootClass}__option-container`} ref={menuRef} style={{ ...(showMenu ? {} : { display: 'none' }) }}>
        {Object.values(Source).map((option) => {
          const { name, description, icon } = contentBySource[option]
          const isSelected = option === source
          return (
            <div
              role={'option'}
              aria-selected={isSelected}
              tabIndex={0}
              data-test={`${dataTest}-option-${option}`}
              className={classNames(`${rootClass}__option`, { [`${rootClass}__option-selected`]: isSelected })}
              key={option}
              onClick={() => onSubmitOption(option)}
              onKeyDown={(event) => onKeyDown(event, option)}
            >
              <Svg name={icon} type={SvgType.VERY_LARGE_ICON} />
              <div>
                <Typography text={t(name)} weight={TextWeight.MEDIUM} />
                <Typography text={t(description)} type={TextType.BODY_TEXT_SMALL_LIGHT} lineHeight={LineHeight.MEDIUM_SMALL} />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default WebhookSourceSelector
