import React, { FC, useState } from 'react'

import classNames from 'classnames'

import ConfirmationModal from '@components/ConfirmationModal'
import { YesNo } from '@components/ConfirmationModal/ConfirmationModal'
import Container from '@components/Container'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Toggle from '@components/Toggle'
import { useTranslation } from '@const/globals'

import './InternationalSending.css'

type Props = {
  hasInternationalSending: boolean
  onChangeInternationalSending: (isEnabled: boolean) => void
  className?: string
  dataTest?: string
}

const rootClass = 'international-sending'
const CREDITS_URL = 'https://connect.act-on.com/hc/en-us/articles/4403505041687'

const InternationalSending: FC<Props> = (props: Props) => {
  const { hasInternationalSending, onChangeInternationalSending, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const [showConfirmation, setShowConfirmation] = useState(false)

  const onChange = (hasInternationalSending: boolean) => {
    if (!hasInternationalSending && !showConfirmation) {
      setShowConfirmation(true)
    } else {
      onChangeInternationalSending(hasInternationalSending)
    }
  }

  const confirmationBody = t(
    'Your account will not be able to send to numbers outside of the US and Canada. This includes messages that are scheduled to send.'
  )

  const toggleLabel = t('Allow messages to be sent outside the United States and Canada')

  return (
    <>
      {showConfirmation && (
        <ConfirmationModal
          isOpen
          isYesNo
          title={t('Are you sure you want this off?')}
          body={confirmationBody}
          yesButtonText={t('Yes, turn off')}
          onAnswer={(answer) => {
            if (answer === YesNo.YES) {
              onChange(false)
            }
            setShowConfirmation(false)
          }}
          className={`${rootClass}__confirmation`}
        />
      )}
      <Container
        className={classNames(rootClass, className)}
        data-test={dataTest}
        title={'Sending outside US and Canada'}
        description={
          'Choose whether your account can send messages to numbers outside of the United States and Canada. Credits per send vary by country.'
        }
      >
        <div className={`${rootClass}__link`}>
          <TextLink text={t('See credits per country')} link={CREDITS_URL} size={TextLinkSize.LARGE} />
        </div>
        <div className={`${rootClass}__toggle`}>
          <Toggle isOn={hasInternationalSending} label={toggleLabel} onToggle={onChange} noLeftMargin />
        </div>
      </Container>
    </>
  )
}

export default InternationalSending
