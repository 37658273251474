import { gql } from '@apollo/client'

export default gql`
  mutation restoreProgramFromBackup($srcProgramId: String, $bckProgramId: String) {
    restoreProgramFromBackup(srcProgramId: $srcProgramId, bckProgramId: $bckProgramId) {
      isTemp
      id
      name
      description
      type
      sforceNote
      allowLooping
      suppressBadEmails
      allowReentrantAddresses
      useAlternateUniqueField
      allowBypassFatigueRules
      alternateUniqueFieldHeader
      historyDisabled
      historyDisabledStarted
      historyDisabledStopped
      sourceList {
        id
        baseId
        name
        count
        headers
      }
      sources {
        id
        baseId
        name
        count
        headers
      }
      messages {
        id
        name
        isNewComposer
        beeComposer
      }
      conditionalMessages {
        id
        name
        isNewComposer
        beeComposer
      }
      exit {
        stepId
        stepType
        exitChoices {
          displayName
          stepType
          srcId
          not
          listId
          update
          score
        }
      }
      firstTrackId
      tracks {
        id
        name
        steps {
          stepType
          stepId
          displayName
          track
          depth
          letter
        }
        stepId
        stepType
        displayName
        letter
      }
      runStatus {
        valid
        isRunning
      }
      schedule {
        nextTS
        nextTime
        type
        hour
        minute
        ampm
        interval
        timestamp
        waitUntilDate
        waitUntilDateFormatted
        triggerAllowed
        isScheduled
        isUnscheduled
        isTriggered
        weekdaysOnly
        hasEndTime
        endHour
        endMinute
        endAmpm
      }
      timeZoneId
      timeZoneNames {
        id
        name
      }
      status
      steps {
        stepType
        stepId
        displayName
        track
        depth
        letter
      }
      stepTemplates {
        stepType
        stepId
        displayName
        track
        depth
        letter
      }
      isReportingTooLarge
      crm {
        crmName
        crmListPrefix
        crmImageSmall
        crmImageLarge
        taskStepEnabled
        salesforceCampaignStepEnabled
        createStepEnabled
        crmType
        campaignDisplayName
        canCustomizeCampaignStatus
        canCreateCampaign
        canSyncProgramList
        isDynamicCampaign
        crmModelEnabled
        crmModelNotCurrent
        crmCampaignsAreLimited
        modelEntityLimit
        defaultTypes
        crmEnabled
      }
      users {
        id
        email
        firstName
        lastName
      }
      localSegmentDetails {
        id
        count
        countlimit
        name
        queryId
        any
        dateSelector
        idset
        whats
        profileFilters {
          header
          op
          value
          format
        }
        behaviorFilters {
          idSet
          whats
          isScore
        }
      }
      isNew
      lastUpdated
      startedOn
      createdOn
      lastRun
      creator
      lastRowId
      lastEditedBy
      lastActivity
      hasActOnContactList
      doneCount
      isAOCUpgraded
      srcId
    }
  }
`
