import { Row } from 'react-table'

import { ApplyAndRemoveTags } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { LabelDto } from '@graphql/types/microservice/categorization-types'

export interface Favorite {
  isFavorite: boolean
}

export interface TagManagerParams {
  onApplyAndRemoveTags: ApplyAndRemoveTags
  onCreateTag: Function
  selectedIds: number[]
  t: Function
  tags: LabelDto[]
}

export interface TableActions {
  deleteItems: () => void
  moveToFolder: () => void
  setFavoriteItems: () => void
  deleteFromFolder: () => void
  copyToCatalog: () => void
  clickRowAction: (row: any, rowAction: ListPageRowActions) => void
  customTableRowAction: (customTableAction: string, row: Row) => void
  customTableHeaderAction: (customTableAction: string) => void
}

export type ListPageTableActions =
  | 'PREVIEW'
  | 'SET_FAVORITE'
  | 'DELETE'
  | 'ADD_TO_FOLDER'
  | 'MOVE_TO_FOLDER'
  | 'SHARE_TO_ACCOUNTS'
  | 'ADD_TO_FAVORITES'
  | 'SHARE_TO_CATALOG'
  | 'REMOVE_FROM_FOLDER'
  | 'MANAGE_TAGS'
  | 'DUPLICATE'

export type ListPageRowActions = Exclude<ListPageTableActions, 'ADD_TO_FAVORITES' | 'MANAGE_TAGS' | 'MOVE_TO_FOLDER'>

export const MAX_STANDARD_ACTIONS_COUNT = 4
