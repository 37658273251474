import React, { FC, useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import SvgNames from '@components/Svg/SvgNames'
import { HeaderAction } from '@components/Table/Table'
import { MIN_PAGINATION_SIZE } from '@components/TableV2/components/Pagination/TableV2Pagination'
import { TableV2 } from '@components/TableV2/TableV2'
import { renderLockCell } from '@components/TableV2/utils/tableV2Utils'
import { rootContext, useTranslation } from '@const/globals'
import { draftMessagesTableColumns } from '@src/pages/sms/utils/sms.utils'
import { Row } from '@tanstack/react-table'
import { Message } from '@utils/sms.utils'

import './DraftMessagesTable.css'

type Props = {
  messages: Message[]
  loading: boolean
  pageSize: number
  pageIndex: number
  totalCount: number
  fetchData: (pageIndex: number, pageSize: number) => void
  onDeleteDrafts: (msgIds: string[]) => void
  dataTest?: string
}

export const rootClass = 'draft-messages-table'
const DISABLED_ROW_STATUS = 'LOCKED_DRAFT'

const DraftMessagesTable: FC<Props> = (props: Props) => {
  const { messages, loading, onDeleteDrafts, pageSize, pageIndex, totalCount, fetchData } = props
  const [selected, setSelected] = useState<string[]>([])
  const { t } = useTranslation()
  const history = useHistory()

  const onRowSelectionChanged = useCallback((ids: string[]) => setSelected(ids), [])

  const deleteAction = useCallback(() => {
    onDeleteDrafts(selected)
    setSelected([])
  }, [onDeleteDrafts, selected])

  const headerActions: HeaderAction[] = useMemo(
    () => [
      {
        label: 'Delete',
        icon: SvgNames.delete,
        onClick: deleteAction,
      },
    ],
    [deleteAction]
  )

  const rowDisabled = useCallback((row: Row<Message>) => row.original.status === DISABLED_ROW_STATUS, [])

  const overrideCheckboxCell = useCallback(
    (row: Row<Message>) => ({
      cellStatus: row.original.status === 'LOCKED_DRAFT',
      cellValue: renderLockCell(row),
    }),
    []
  )

  const onRowClicked = useCallback((row: Row<Message>) => history.push(`${rootContext}/sms/${row.original.launchId ?? '0'}/edit`), [history])

  const emptyState = useMemo(
    () => ({ size: EmptyListingSize.MEDIUM, imgSrc: StaticImageNames.smsDraftsEmpty, headline: t('You don’t have any saved drafts.') }),
    [t]
  )

  return (
    <div className={rootClass}>
      <TableV2
        loading={loading}
        data={messages}
        columns={draftMessagesTableColumns}
        enableSorting={true}
        enableCheckbox={true}
        headerActions={headerActions}
        emptyState={emptyState}
        rowUniqueIdKey={'launchId'}
        rowDisabled={rowDisabled}
        paginationState={{ pageIndex, pageSize, controlledPageCount: Math.ceil(totalCount / pageSize) }}
        enablePaginate={totalCount >= MIN_PAGINATION_SIZE}
        fetchData={fetchData}
        onRowClicked={onRowClicked}
        onRowSelectionChanged={onRowSelectionChanged}
        overrideCheckboxCell={overrideCheckboxCell}
      />
    </div>
  )
}

export default DraftMessagesTable
