import React, { FC, useContext, useEffect, useState } from 'react'

import classNames from 'classnames'

import { FilterableListData } from '@components/FilterableList/FilterableList'
import FilterableListPair from '@components/FilterableListPair/FilterableListPair'
import Label from '@components/Label/Label'
import { useTranslation } from '@const/globals'
import ColumnByEntityType from '@graphql/microservices/crm/ColumnByEntityType'
import { Column, GetColumnByEntityTypeQuery, GetColumnByEntityTypeQueryVariables } from '@graphql/types/microservice/crm-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'

import { DataManagementContext } from '../../context/DataManagementContext'

import './EntityLoader.css'

type Props = {
  className?: string
  dataTest?: string
  entityType: string
  onMaxColumnsChange?: (maxColumns: number, totalColumns: number) => void
}

const rootClass = 'entity-loader'

type State = {
  data: FilterableListData[]
  selected: FilterableListData[]
  numSelectedColumns: number
  maxSelectedColumns: number
  dataLoaded: boolean
  updatedCol: Column[]
}

const EntityLoader: FC<Props> = (props: Props) => {
  const { entityType, dataTest = rootClass, className = '', onMaxColumnsChange } = props
  const { userUpdates } = useContext(DataManagementContext)
  const { t } = useTranslation()

  const [state, setState] = useState<State>({
    data: [],
    selected: [],
    numSelectedColumns: 0,
    maxSelectedColumns: 0,
    dataLoaded: false,
    updatedCol: [],
  })

  const {
    values: { entityTable },
  } = useContext(DataManagementContext)

  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.CRM })

  const { loading, data } = useQueryOnMount<GetColumnByEntityTypeQuery, GetColumnByEntityTypeQueryVariables>(ColumnByEntityType, {
    client,
    fetchPolicy: 'network-only',
    variables: {
      type: entityType as string,
    },
  })

  useEffect(() => {
    if (!loading) {
      const colList = entityTable
        .filter((fieldbyEntity) => fieldbyEntity.entity === entityType)
        .map((fieldByEntity) => fieldByEntity.columns)
        .pop()

      const markUserChangedCols = data?.fieldsForEntityType?.map((fetchedCol) => {
        if (fetchedCol !== undefined) {
          if (colList === undefined) {
            return fetchedCol
          } else {
            const hasUserChangedCol =
              colList?.filter((updatedColumn) => updatedColumn.name === fetchedCol?.name && updatedColumn.selected !== fetchedCol?.selected)
                ?.length !== 0
            const colUpdate: Column = {
              dataType: fetchedCol.dataType,
              label: fetchedCol.label,
              name: fetchedCol.name,
              required: fetchedCol.required,
              selected: hasUserChangedCol ? !fetchedCol.selected : fetchedCol.selected,
            }
            return colUpdate
          }
        }
      })

      const columns: FilterableListData[] = markUserChangedCols?.map((column, idx) => ({
        selected: column?.selected ?? false,
        disabled: column?.required ?? false,
        FieldName: column?.name ?? '',
        fieldValue: column?.label ?? '',
        entityColumn: column,
        id: idx,
      })) || [
        {
          selected: false,
          disabled: false,
          FieldName: '',
          fieldValue: '',
          entityColumn: {},
          id: 0,
        },
      ]

      const selected = getSelectedList(columns)

      setState({
        ...state,
        data: columns,
        maxSelectedColumns: columns.length,
        selected: selected,
        numSelectedColumns: selected.length,
        dataLoaded: true,
      })

      onMaxColumnsChange?.(selected.length, columns.length)
    }
  }, [data, loading])

  const getSelectedList = (data: FilterableListData[]): FilterableListData[] => data.filter((item) => item.selected)

  const onRowSelectionChanged = (updatedData: FilterableListData[]) => {
    const selected = getSelectedList(updatedData)

    const colListWithSelectionUpdate = state.data.map((col) => {
      const isColumnSelected = selected.filter((selectedCol) => selectedCol.entityColumn === col.entityColumn).length > 0
      const colObjectWithUpdateSelection: Column = {
        selected: isColumnSelected,
        dataType: col.entityColumn.dataType,
        label: col.entityColumn.label,
        name: col.entityColumn.name,
        pickListValues: col.entityColumn.pickListValues,
        required: col.entityColumn.required,
      }

      const newCol: FilterableListData = {
        selected: isColumnSelected,
        disabled: col.required,
        FieldName: col.FieldName,
        fieldValue: col.fieldValue,
        id: 0,
        entityColumn: colObjectWithUpdateSelection,
      }
      return newCol
    })

    const updatedCol = colListWithSelectionUpdate.map((col) => col['entityColumn'])
    if (entityTable.filter((fieldByEntity) => fieldByEntity.entity === entityType).length === 0) {
      entityTable.push({
        entity: entityType as string,
        columns: updatedCol,
      })
    } else {
      entityTable.filter((fieldByEntity) => fieldByEntity.entity === entityType).forEach((fieldByEntity) => (fieldByEntity.columns = updatedCol))
    }

    userUpdates('entityTable', entityTable)
    setState({ ...state, selected, data: updatedData, numSelectedColumns: selected.length, updatedCol: updatedCol })
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__row`}>
        <div className={`${rootClass}__filterable`}>
          <FilterableListPair
            key={state.maxSelectedColumns}
            accessor={'fieldValue'}
            data={state.data}
            label={t('Selected fields')}
            placeholder={t('Search fields...')}
            onItemSelectionChanged={onRowSelectionChanged}
            emptyText={t('No fields found.')}
            loading={loading && !state.dataLoaded}
          />
        </div>
        <div className={`${rootClass}__col_count`}>
          {!loading && state.dataLoaded && (
            <Label className={`${rootClass}__col_count_label`}>
              <div>
                <span className={`${rootClass}__bold`}>{state.numSelectedColumns} </span>
                <span className={`${rootClass}__less-bold`}>
                  {t('of')} {state.maxSelectedColumns} {t('fields selected')}
                </span>
              </div>
            </Label>
          )}
        </div>
      </div>
    </div>
  )
}

export default EntityLoader
