import { ComponentProps, createContext } from 'react'

import { ListingPageItem } from '@complex/ListingPage/Context/ListingPageCommon.context'
import StatusToast from '@components/StatusToast/StatusToast'
import { ConfigInput, Problem, Segment } from '@graphql/types/microservice/ml-audience-types'
import { List } from '@interface/foldersLists/List'
import { DEFAULT_AI_AUDIENCE_LIST } from '@src/pages/RecommendedSegments/utils/RecommendedSegments.constants'
import { SegmentsInsightsSettings } from '@src/pages/RecommendedSegments/utils/RecommendedSegments.interfaces'

export type SegmentItem = ListingPageItem & { recordsCount: number }

type Update = (values: Partial<RecommendedSegmentsContextState>) => void

export interface RecommendedSegmentsContextState {
  allContactsSegment?: SegmentItem
  feedbackModalEnabled: boolean
  hasUpdatedSettings: boolean
  allParentSegments: List[]
  loadingSegments: boolean
  loadingSettings: boolean
  problems?: Problem[]
  segmentToCreate?: Segment
  segments?: Segment[]
  selectedParent: List
  settings?: SegmentsInsightsSettings
  status?: Pick<ComponentProps<typeof StatusToast>, 'message' | 'status'>
}

export interface RecommendedSegmentsContextAPI {
  loadSegments: VoidFunction
  loadSettings: VoidFunction
  resetSettings: VoidFunction
  saveSettings: (settings: Partial<ConfigInput>) => void
  setSelectedParent: (segmentId: string) => void
  update: Update
  values: RecommendedSegmentsContextState
}

export const recommendedSegmentsContextInitialState: RecommendedSegmentsContextState = {
  feedbackModalEnabled: true,
  hasUpdatedSettings: false,
  allParentSegments: [],
  loadingSegments: true,
  loadingSettings: true,
  selectedParent: DEFAULT_AI_AUDIENCE_LIST,
}

export const RecommendedSegmentsContext = createContext<RecommendedSegmentsContextAPI>({
  values: recommendedSegmentsContextInitialState,
} as any)
