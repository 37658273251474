import React, { FC, useCallback, useEffect, useRef, useState } from 'react'

import { useApolloClient, useMutation } from '@apollo/client'
import BulkActionsModal from '@components/BulkActionsModal/BulkActionsModal'
import Modal from '@components/Modal'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import addUsersMutation from '@graphql/mutations/addUsers'
import { AddUsersMutation, MutationAddUsersArgs, UserInput } from '@graphql/types/mutation-types'
import InviteUsersModalContent from '@src/pages/Settings/Users/components/InviteUsersModal/InviteUsersModalContent'
import InviteUsersModalHeader from '@src/pages/Settings/Users/components/InviteUsersModal/InviteUsersModalHeader/InviteUsersModalHeader'
import { useMarketingUsers } from '@src/pages/Settings/Users/context/MarketingUsersContext'
import { useSalesUsers } from '@src/pages/Settings/Users/context/SalesUsersContext'
import { useAllUsers } from '@src/pages/Settings/Users/context/UsersContext'
import { filterNotEmptyArray } from '@utils/array'
import { logNewRelicError } from '@utils/new-relic.utils'

import './InviteUsersModal.css'

interface Props {
  isOpen: boolean
  onClose: () => void
  dataTest?: string
}

const rootClass = 'invite-users-modal'

const InviteUsersModalContainer: FC<Props> = (props: Props) => {
  const { isOpen, onClose, dataTest = rootClass } = props
  const { t } = useTranslation()
  const [isAddSingle, setIsAddSingle] = useState<boolean>(true)
  const [importErrorInfo, setImportErrorInfo] = useState<{ imported: string[]; notImported: string[]; hasDuplicated: boolean } | undefined>(undefined)
  const { setToastStatus, setLoadingMutation } = useAllUsers()
  const { refetchRefs } = useSalesUsers()
  const { refetch: refetchMarketing } = useMarketingUsers()
  const refetchRef = useRef<Function>(refetchMarketing)

  const client = useApolloClient()

  const [addUsers] = useMutation<AddUsersMutation, MutationAddUsersArgs>(addUsersMutation, {
    client,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    //On open ‘Add users’ Modal always keep Single User Form as default
    isOpen && setIsAddSingle(true)
  }, [isOpen])

  const handleSave = useCallback<(userInput: UserInput) => void>(
    (userInput) => {
      onClose()
      const { userType, email = [] } = userInput
      const isSales: boolean = userType.includes('sales')
      setLoadingMutation(true, isSales)
      addUsers({
        variables: {
          userInput,
        },
      })
        .then(({ data }) => {
          const { addUsers } = { ...data }
          const { importedUsers, noImportedUsers, duplicatedUsers } = { ...addUsers }
          refetchRef.current = isSales ? refetchRefs.refreshSalesUsers : refetchMarketing
          const count = email.length
          const imported = (importedUsers ?? []).filter(filterNotEmptyArray)
          const notImported = (noImportedUsers ?? []).filter(filterNotEmptyArray)
          const duplicated = (duplicatedUsers ?? []).filter(filterNotEmptyArray)
          if (addUsers?.status === 'ok') {
            if (!notImported.length) {
              setToastStatus({
                showStatus: true,
                title: t('Success!'),
                statusMessage: (
                  <Typography
                    text={'Invite.Users.Success.Message'}
                    inline
                    values={{
                      count,
                      userType: userType.replace(' user', ''),
                      email: email[0],
                    }}
                    tagProps={{ bold: { weight: TextWeight.BOLD } }}
                  />
                ),
                successStatus: true,
              })
              refetchRef.current()
            } else if (notImported.length === 1 && !imported.length) {
              setToastStatus({
                showStatus: true,
                statusMessage: t('Invite.Users.Error.Message', duplicated.length ? { context: 'userExist' } : { count: 1 }),
                successStatus: false,
              })
            } else {
              setImportErrorInfo({
                imported,
                notImported: notImported.map((email) => email + (duplicated.includes(email) ? ` (${t('email exists')})` : '')),
                hasDuplicated: !!duplicated.length,
              })
            }
          } else if (addUsers?.status === 'error') {
            setToastStatus({
              showStatus: true,
              statusMessage: t('Invite.Users.Error.Message', { count }),
              successStatus: false,
            })
          }
        })
        .catch((error) => {
          logNewRelicError(error)
        })
        .finally(() => {
          setLoadingMutation(false, isSales)
        })
    },
    [onClose, addUsers, setToastStatus, setLoadingMutation]
  )

  const onBulkModalClose = useCallback(() => {
    setImportErrorInfo(undefined)
    refetchRef.current()
  }, [])

  const toggleSingleMultiple = useCallback(() => setIsAddSingle((current) => !current), [])

  return (
    <>
      <Modal
        isOpen={isOpen}
        header={<InviteUsersModalHeader isAddSingle={isAddSingle} onClick={toggleSingleMultiple} />}
        className={rootClass}
        dataTest={dataTest}
      >
        <InviteUsersModalContent isAddSingle={isAddSingle} onCancel={onClose} onSave={handleSave} />
      </Modal>
      <BulkActionsModal
        isOpen={!!importErrorInfo}
        errorMessages={importErrorInfo?.notImported ?? []}
        successMessages={importErrorInfo?.imported ?? []}
        title={t('Invite.Users.Error.Modal.Title')}
        warnings={t(`Invite.Users.Error.Modal.Caution${importErrorInfo?.hasDuplicated ? '_hasDuplicated' : ''}`)}
        onClose={onBulkModalClose}
        successTitle={t('Invite.Users.Error.Modal.Success.Title')}
        errorTitle={t('Invite.Users.Error.Modal.Error.Title')}
        className={`${rootClass}__error-modal`}
        dataTest={`${dataTest}-error-modal`}
      />
    </>
  )
}

export default InviteUsersModalContainer
