import { Dispatch, SetStateAction } from 'react'

import {
  GetAllItemsRequestType,
  GetCreatedByMeSegmentsRequestType,
  GetFavoriteSegmentsRequestType,
  GetFoldersRequestType,
  GetItemChildrenHierarchyRequestType,
  GetRecentSegmentsRequestType,
  GetSegmentHierarchyRequestType,
  GetSegmentsInFolderAndTagsRequestType,
  GetSegmentsInFolderRequestType,
  GetTagsRequestType,
  SearchSegmentsByFilterRequestType,
  SearchSegmentsByFilterTypes,
  SearchSegmentsByTagsRequestType,
  SearchSegmentsRequestType,
} from '@complex/ListPickerModalV2/utils/graphql/ListPickerModalCategorizationRequests.graphQL'
import {
  GetCRMSegmentsInfoRequestType,
  SyncedSegmentDetailsRequestType,
} from '@complex/ListPickerModalV2/utils/graphql/ListPickerModalCRMRequests.graphQL'
import { DisableRowByCriteria, ListPickerModalState, PreProcessedList } from '@complex/ListPickerModalV2/utils/interfaces/ListPickerModalInterfaces'
import {
  buildLists,
  buildListsHierarchy,
  disableListUtils,
  setLists,
  setSearchResults,
  showFailStatusToast,
} from '@complex/ListPickerModalV2/utils/ListPickerModalUtils'
import {
  LabelDto,
  SearchByAuthorQuery,
  SearchByRecentQuery,
  SearchQuery,
  SearchSegmentsByFavoritesQuery,
} from '@graphql/types/microservice/categorization-types'
import { AccountSettings, ItemDto } from '@graphql/types/query-types'
import { Folder } from '@interface/Folder'
import { doFilterBounce, doFilterSms, ItemSubType, ItemType } from '@utils/categorization'
import { favoriteFilter, FilterTypes } from '@utils/filter'
import { sortFoldersByName } from '@utils/folderUtils'
import { ListCategory } from '@utils/lists'
import { logNewRelicError } from '@utils/new-relic.utils'

export const DEFAULT_PAGE_NUMBER = 0
export const PAGE_SIZE = 50

interface SegmentIdParam {
  segmentId: number
}

interface SetStateParam {
  setState: Dispatch<SetStateAction<ListPickerModalState>>
}

interface ListTypeParam {
  listType: ListCategory
}

interface OptionalParams {
  type?: ItemType
  hasSMS?: boolean
  subTypes?: ItemSubType[]
}

interface GetUnifiedListBaseParams extends OptionalParams, SetStateParam, ListTypeParam {
  disabledLists: string[]
  disableLists: boolean
  syncedSegmentDetailsRequest: SyncedSegmentDetailsRequestType
  getCRMSegmentsInfoRequest: GetCRMSegmentsInfoRequestType
  hasCRMConnected: boolean
  disableRowByCriteria: DisableRowByCriteria | undefined
  accountSettings?: AccountSettings
}

export interface GetUnifiedListWithPageParams extends GetUnifiedListBaseParams {
  pageNumber: number
  hasDefaultSelectedLists: boolean
}

interface GetUnifiedListSearchParams extends OptionalParams, SetStateParam, ListTypeParam {
  search: string
  disableRowByCriteria: DisableRowByCriteria | undefined
}

export const doSearchUCLListsUtils = (
  {
    setState,
    searchSegmentsRequest,
    search,
    type = ItemType.SEGMENT,
    listType,
    disableRowByCriteria,
  }: GetUnifiedListSearchParams & { searchSegmentsRequest: SearchSegmentsRequestType },
  currentFolder?: Folder
) =>
  searchSegmentsRequest({ type, search, currentFolder })
    .then(({ data }) => setSearchResults(setState, buildLists(data?.search?.items as ItemDto[], listType), type, disableRowByCriteria))
    .catch(() => showFailStatusToast(setState, `An unexpected error occurred while searching${!!currentFolder ? 'folder' : ''} lists.`))

export const searchUCLListsUtils = (
  {
    setState,
    searchSegmentsRequest,
    search,
    type = ItemType.SEGMENT,
  }: GetUnifiedListSearchParams & { searchSegmentsRequest: SearchSegmentsRequestType },
  currentFolder?: Folder
) =>
  searchSegmentsRequest({ type, search, currentFolder })
    .then(({ data }) => data)
    .catch((e) => {
      logNewRelicError(e)
      showFailStatusToast(setState, `An unexpected error occurred while searching${!!currentFolder ? 'folder' : ''} lists.`)
      return {}
    })

const getQueryData = (data: SearchSegmentsByFilterTypes | null | undefined, filter: FilterTypes) =>
  filter === FilterTypes.FAVORITES
    ? (data as SearchSegmentsByFavoritesQuery).searchByFavorites
    : filter === FilterTypes.RECENT
    ? (data as SearchByRecentQuery).searchByRecent
    : filter === FilterTypes.CREATED_BY_ME
    ? (data as SearchByAuthorQuery).searchByAuthor
    : filter === FilterTypes.CONTACT_PREFERENCES || filter === FilterTypes.CRM_SOURCED
    ? (data as SearchQuery).search?.items
    : undefined

export const doSearchUCLListsByFilterUtils = (
  {
    setState,
    searchSegmentsByFilterRequest,
    search,
    type = ItemType.SEGMENT,
    listType,
    disableRowByCriteria,
  }: GetUnifiedListSearchParams & { searchSegmentsByFilterRequest: SearchSegmentsByFilterRequestType },
  filter: FilterTypes,
  { hasPurchasedSMS, isUsingExternalSegmentationService }: AccountSettings
) =>
  searchSegmentsByFilterRequest({ filter, type, search })
    .then(({ data }) => {
      let queryData = getQueryData(data, filter) as ItemDto[]
      if (queryData !== null && queryData !== undefined) {
        if (type === ItemType.BOUNCE) {
          if (!hasPurchasedSMS) {
            queryData = doFilterSms(queryData)
          }
          if (isUsingExternalSegmentationService) {
            queryData = doFilterBounce(queryData)
          }
        }
        setSearchResults(setState, buildLists(queryData ?? [], listType), type, disableRowByCriteria)
      }
    })
    .catch(() => showFailStatusToast(setState, 'An unexpected error occurred while searching lists.'))

export const doSearchUCLListsByTagUtils = (
  {
    setState,
    searchSegmentsByTagsRequest,
    search,
    type = ItemType.SEGMENT,
    listType,
    disableRowByCriteria,
  }: GetUnifiedListSearchParams & { searchSegmentsByTagsRequest: SearchSegmentsByTagsRequestType },
  currentTag: LabelDto
) =>
  searchSegmentsByTagsRequest({ type, search, currentTag })
    .then(({ data }) => setSearchResults(setState, buildLists(data?.searchByLabels as ItemDto[], listType), type, disableRowByCriteria))
    .catch(() => showFailStatusToast(setState, 'An unexpected error occurred while searching tagged lists.'))

export const getBouncesUtils = async (
  getAllItemsRequest: GetAllItemsRequestType,
  setState: Function,
  { hasPurchasedSMS, isUsingExternalSegmentationService }: AccountSettings
) => {
  try {
    const { data } = await getAllItemsRequest({ type: ItemType.BOUNCE })
    if (data) {
      let items = data.getAllItems as ItemDto[]
      if (!hasPurchasedSMS) {
        items = doFilterSms(items)
      }

      if (isUsingExternalSegmentationService) {
        items = doFilterBounce(items)
      }

      return items
    } else {
      showFailStatusToast(setState, 'An unexpected error occurred while fetching segments.')
    }
  } catch {
    showFailStatusToast(setState, 'An unexpected error occurred while fetching segments.')
  }
}
export const getUnifiedListCRMSourced = ({
  setState,
  syncedSegmentDetailsRequest,
  getCRMSegmentsInfoRequest,
  hasCRMConnected,
  listType,
  request: getAllItemsRequest,
  pageNumber,
  disabledLists,
  disableLists,
  type = ItemType.SEGMENT,
  subTypes = [ItemSubType.CRM_SOURCED],
  disableRowByCriteria,
  accountSettings,
}: GetUnifiedListWithPageParams & { request: GetAllItemsRequestType }) => {
  getAllItemsRequest({ type, subTypes, pageNumber })
    .then(({ data }) => {
      let items = data?.getAllItems as ItemDto[]

      if (type === ItemType.BOUNCE) {
        if (!accountSettings?.hasPurchasedSMS) {
          items = doFilterSms(items)
        }
        if (accountSettings?.isUsingExternalSegmentationService) {
          items = doFilterBounce(items)
        }
      }

      setLists(
        setState,
        syncedSegmentDetailsRequest,
        getCRMSegmentsInfoRequest,
        hasCRMConnected,
        listType,
        items,
        pageNumber,
        disabledLists,
        disableLists,
        disableRowByCriteria
      )
    })
    .catch(() => showFailStatusToast(setState, 'An unexpected error occurred while fetching all segments.'))
}

export const getUnifiedListAllSegmentsUtils = ({
  setState,
  request: getAllItemsRequest,
  syncedSegmentDetailsRequest,
  getCRMSegmentsInfoRequest,
  hasCRMConnected,
  listType,
  pageNumber,
  disabledLists,
  disableLists,
  type = ItemType.SEGMENT,
  disableRowByCriteria,
  accountSettings,
}: GetUnifiedListWithPageParams & { request: GetAllItemsRequestType }) =>
  getAllItemsRequest({ type, pageNumber })
    .then(({ data }) => {
      let items = data?.getAllItems as ItemDto[]

      if (type === ItemType.BOUNCE) {
        if (!accountSettings?.hasPurchasedSMS) {
          items = doFilterSms(items)
        }
        if (accountSettings?.isUsingExternalSegmentationService) {
          items = doFilterBounce(items)
        }
      }

      setLists(
        setState,
        syncedSegmentDetailsRequest,
        getCRMSegmentsInfoRequest,
        hasCRMConnected,
        listType,
        items,
        pageNumber,
        disabledLists,
        disableLists,
        disableRowByCriteria
      )
    })
    .catch(() => showFailStatusToast(setState, 'An unexpected error occurred while fetching all segments.'))

export const getUnifiedListFavoritesUtils = ({
  setState,
  request: getFavoriteSegmentRequest,
  syncedSegmentDetailsRequest,
  getCRMSegmentsInfoRequest,
  hasCRMConnected,
  listType,
  pageNumber,
  disabledLists,
  disableLists,
  type = ItemType.SEGMENT,
  disableRowByCriteria,
}: GetUnifiedListWithPageParams & { request: GetFavoriteSegmentsRequestType }) =>
  getFavoriteSegmentRequest({ pageNumber, type })
    .then(({ data }) =>
      setLists(
        setState,
        syncedSegmentDetailsRequest,
        getCRMSegmentsInfoRequest,
        hasCRMConnected,
        listType,
        data?.getFavoriteItems as ItemDto[],
        pageNumber,
        disabledLists,
        disableLists,
        disableRowByCriteria
      )
    )
    .catch(() => showFailStatusToast(setState, 'An unexpected error occurred while fetching favorite lists.'))

export const checkAndSetUCLFavorites = async ({
  setState,
  request: getFavoriteSegmentRequest,
  syncedSegmentDetailsRequest,
  getCRMSegmentsInfoRequest,
  hasCRMConnected,
  listType,
  pageNumber,
  disabledLists,
  disableLists,
  type = ItemType.SEGMENT,
  disableRowByCriteria,
}: GetUnifiedListWithPageParams & {
  request: GetFavoriteSegmentsRequestType
}) => {
  try {
    const { data } = await getFavoriteSegmentRequest({ pageNumber, type })
    if (data?.getFavoriteItems && data.getFavoriteItems?.length > 0) {
      setState((state) => ({ ...state, currentFilter: favoriteFilter, loading: false }))
      setLists(
        setState,
        syncedSegmentDetailsRequest,
        getCRMSegmentsInfoRequest,
        hasCRMConnected,
        listType,
        data?.getFavoriteItems as ItemDto[],
        pageNumber,
        disabledLists,
        disableLists,
        disableRowByCriteria
      )
      return true
    }
    return false
  } catch (e) {
    logNewRelicError(e)
    showFailStatusToast(setState, 'An unexpected error occurred while fetching favorite lists.')
  }
}

export const getUnifiedListCreatedByMeUtils = ({
  setState,
  request: getCreatedByMeSegmentsRequest,
  syncedSegmentDetailsRequest,
  getCRMSegmentsInfoRequest,
  hasCRMConnected,
  listType,
  pageNumber,
  disabledLists,
  disableLists,
  disableRowByCriteria,
}: GetUnifiedListWithPageParams & { request: GetCreatedByMeSegmentsRequestType }) =>
  getCreatedByMeSegmentsRequest({ pageNumber })
    .then(({ data }) =>
      setLists(
        setState,
        syncedSegmentDetailsRequest,
        getCRMSegmentsInfoRequest,
        hasCRMConnected,
        listType,
        data?.getItemsByAuthor as ItemDto[],
        pageNumber,
        disabledLists,
        disableLists,
        disableRowByCriteria
      )
    )
    .catch(() => showFailStatusToast(setState, 'An unexpected error occurred while fetching lists created by me.'))

export const getUnifiedListRecentsUtils = ({
  setState,
  request: getRecentSegmentsRequest,
  syncedSegmentDetailsRequest,
  getCRMSegmentsInfoRequest,
  hasCRMConnected,
  listType,
  pageNumber,
  disabledLists,
  disableLists,
  disableRowByCriteria,
}: GetUnifiedListWithPageParams & { request: GetRecentSegmentsRequestType }) =>
  getRecentSegmentsRequest({ pageNumber })
    .then(({ data }) =>
      setLists(
        setState,
        syncedSegmentDetailsRequest,
        getCRMSegmentsInfoRequest,
        hasCRMConnected,
        listType,
        data?.getRecentItems as ItemDto[],
        pageNumber,
        disabledLists,
        disableLists,
        disableRowByCriteria
      )
    )
    .catch(() => showFailStatusToast(setState, 'An unexpected error occurred while fetching recent lists.'))

export const getUnifiedListFoldersUtils = async (getFoldersRequest: GetFoldersRequestType, setState: Function, type: ItemType = ItemType.SEGMENT) => {
  try {
    const { data } = await getFoldersRequest({ type })
    if (data) {
      const folders = data.getFolders
      if (folders) {
        setState((state: ListPickerModalState) => {
          return {
            ...state,
            foldersState: { folders: sortFoldersByName(folders, true), foldersLoading: false },
          }
        })
      }
    } else {
      showFailStatusToast(setState, 'An unexpected error occurred while fetching folders.')
    }
  } catch {
    showFailStatusToast(setState, 'An unexpected error occurred while fetching folders.')
  }
}

export const getUnifiedListFolderItemsUtils = (
  {
    setState,
    getSegmentsInFolderRequest,
    syncedSegmentDetailsRequest,
    getCRMSegmentsInfoRequest,
    hasCRMConnected,
    listType,
    pageNumber,
    disabledLists,
    disableLists,
    type = ItemType.SEGMENT,
    disableRowByCriteria,
  }: GetUnifiedListWithPageParams & {
    getSegmentsInFolderRequest: GetSegmentsInFolderRequestType
  },
  folderId: number | undefined
) =>
  getSegmentsInFolderRequest({ folderId, type, pageNumber })
    .then(({ data }) =>
      setLists(
        setState,
        syncedSegmentDetailsRequest,
        getCRMSegmentsInfoRequest,
        hasCRMConnected,
        listType,
        data?.getItemsInFolder as ItemDto[],
        pageNumber,
        disabledLists,
        disableLists,
        disableRowByCriteria
      )
    )
    .catch(() => showFailStatusToast(setState, 'An unexpected error occurred while fetching folder lists.'))

export const getUnifiedListTagsUtils = async (
  getTagsRequest: GetTagsRequestType,
  setState: Dispatch<SetStateAction<ListPickerModalState>>,
  types: ItemType[] = [ItemType.SEGMENT, ItemType.BOUNCE]
) =>
  getTagsRequest({ types })
    .then(({ data }) =>
      setState((state: ListPickerModalState) => ({
        ...state,
        tagsState: { tags: data?.getLabels as LabelDto[], tagsLoading: false },
      }))
    )
    .catch(() => showFailStatusToast(setState, 'An unexpected error occurred while fetching tags.'))

export const getUnifiedListTagItemsUtils = (
  {
    setState,
    getSegmentsInFolderAndTagsRequest,
    syncedSegmentDetailsRequest,
    getCRMSegmentsInfoRequest,
    hasCRMConnected,
    listType,
    pageNumber,
    disabledLists,
    disableLists,
    type = ItemType.SEGMENT,
    disableRowByCriteria,
    accountSettings,
  }: GetUnifiedListWithPageParams & { getSegmentsInFolderAndTagsRequest: GetSegmentsInFolderAndTagsRequestType },
  tagId: number
) =>
  getSegmentsInFolderAndTagsRequest({ type, tagId, pageNumber })
    .then(({ data }) => {
      let items = data?.getItemsInFolderAndLabels as ItemDto[]

      // here if type is SEGMENT it means in getSegmentsInFolderAndTagsRequest it ask for BOUNCE as well
      if (type === ItemType.SEGMENT) {
        if (!accountSettings?.hasPurchasedSMS) {
          items = doFilterSms(items)
        }
        if (accountSettings?.isUsingExternalSegmentationService) {
          items = doFilterBounce(items)
        }
      }
      setLists(
        setState,
        syncedSegmentDetailsRequest,
        getCRMSegmentsInfoRequest,
        hasCRMConnected,
        listType,
        items,
        pageNumber,
        disabledLists,
        disableLists,
        disableRowByCriteria
      )
    })
    .catch(() => showFailStatusToast(setState, 'An unexpected error occurred while fetching tagged lists.'))

interface SetSegmentAncestorsTreeUtilsParams extends SegmentIdParam, SetStateParam, ListTypeParam {
  getSegmentHierarchyRequest: GetSegmentHierarchyRequestType
}

export const setSegmentAncestorsTreeUtils = ({ segmentId, setState, listType, getSegmentHierarchyRequest }: SetSegmentAncestorsTreeUtilsParams) =>
  getSegmentHierarchyRequest({ segmentId })
    .then(({ data }) =>
      setState((state) => ({
        ...state,
        segmentTreePage: {
          ...state.segmentTreePage,
          segmentAncestors: buildLists(data?.getItemHierarchy as ItemDto[], listType).reverse() as PreProcessedList[],
        },
      }))
    )
    .catch((e) => logNewRelicError(e))

interface SetSegmentChildrenTreeUtilsParams extends SegmentIdParam, SetStateParam, ListTypeParam {
  getItemChildrenHierarchyRequest: GetItemChildrenHierarchyRequestType
  disableRowByCriteria: DisableRowByCriteria | undefined
}

export const setSegmentChildrenTreeUtils = ({
  segmentId,
  setState,
  listType,
  getItemChildrenHierarchyRequest,
  disableRowByCriteria,
}: SetSegmentChildrenTreeUtilsParams) =>
  getItemChildrenHierarchyRequest({ segmentId })
    .then(({ data }) =>
      setState((state) => ({
        ...state,
        segmentTreePage: {
          ...state.segmentTreePage,
          segmentChildren: disableListUtils(
            buildListsHierarchy(buildLists(data?.getItemChildrenHierarchy as ItemDto[], listType)),
            state.disabledLists,
            state.disableUnifiedLists || state.disableLegacyLists,
            disableRowByCriteria
          ),
        },
      }))
    )
    .catch((e) => logNewRelicError(e))
