import React, { FC, useContext, useState } from 'react'

import classNames from 'classnames'

import ListPickerModal from '@complex/ListPickerModalV2/ListPickerModal'
import Button, { ButtonType } from '@components/Button'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { Program } from '@graphql/types/query-types'
import { List } from '@interface/foldersLists/List'
import { ListMaintenanceProgramsContext } from '@src/pages/listingPages/ListMaintenancePrograms/context/ListMaintenancePrograms.context'
import { useAccountSettings } from '@utils/account/account.utils'
import { ListProgramSource } from '@utils/listingPage/listMaintenancePrograms.utils'

import './ListProgramSourceSelector.css'

interface ListProgramSourceSelectorProps {
  className?: string
  dataTest?: string
  defaultSource?: ListProgramSource
  isEditing?: boolean
  buttonType?: ButtonType.INFO | ButtonType.SECONDARY
  onSubmit: (list: List | undefined) => void
  showErrorOnUnselectedSource?: boolean
  isListsOnly?: boolean
}

const rootClass = 'list-program-source-selector'

const ListProgramSourceSelector: FC<ListProgramSourceSelectorProps> = (props: ListProgramSourceSelectorProps) => {
  const {
    dataTest = rootClass,
    className = '',
    defaultSource,
    isEditing = false,
    buttonType = ButtonType.INFO,
    onSubmit,
    showErrorOnUnselectedSource = false,
    isListsOnly = false,
  } = props

  const {
    values: { hasMarketingSource = false, programDetails },
  } = useContext(ListMaintenanceProgramsContext)

  const { phase3FormSubmission } = useAccountSettings()

  const { sourceList } = { ...programDetails } as Program

  const [showListPicker, setShowListPicker] = useState(false)

  const { t } = useTranslation()

  const submitLists = ([list]: List[]) => {
    onSubmit(list)
    setShowListPicker(false)
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {isEditing && (
        <Button dataTest={`${dataTest}-button`} className={`${rootClass}__button`} buttonType={buttonType} onClick={() => setShowListPicker(true)}>
          <Svg name={SvgNames.listCheckNoFill} />
          {t('Select source')}
        </Button>
      )}
      {defaultSource ? (
        <div className={`${rootClass}__info`}>
          <Typography
            className={'ellip'}
            text={defaultSource.name}
            type={TextType.BODY_TEXT_SMALL}
            weight={TextWeight.MEDIUM}
            lineHeight={LineHeight.MEDIUM_SMALL}
          />
          <Typography
            dataTest={`${dataTest}-records-amount`}
            text={`${defaultSource.count} ${t('contacts')}`}
            type={TextType.BODY_TEXT_SMALL_LIGHT}
            lineHeight={LineHeight.MEDIUM_SMALL}
          />
        </div>
      ) : (
        <div className={`${rootClass}__no-list-selected`}>
          {showErrorOnUnselectedSource && <Svg name={SvgNames.warningSolid} type={SvgType.ICON} />}
          <Typography text={t(`No list selected`)} weight={TextWeight.ITALIC} type={TextType.BODY_TEXT_LIGHT} />
        </div>
      )}
      {showListPicker && (
        <ListPickerModal
          closeModal={() => setShowListPicker(false)}
          isOpen
          submitLists={submitLists}
          multiSelect={false}
          defaultSelectedLists={[...(defaultSource ? [defaultSource.id] : [])]}
          hideUCL={sourceList.length > 0 && hasMarketingSource}
          hideLegacyLists={sourceList.length > 0 && !hasMarketingSource}
          isListsOnly={isListsOnly}
          hasUnifiedFormSubmissions={phase3FormSubmission && !hasMarketingSource}
          hideLegacyListToggle
        />
      )}
    </div>
  )
}

export default ListProgramSourceSelector
