import React, { FC, useCallback, useContext, useMemo, useState } from 'react'

import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import DateRangePicker, { DateRangeValueType } from '@components/DateRangePicker/DateRangePicker'
import Label from '@components/Label/Label'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'

dayjs.extend(utc)
dayjs.extend(timezone)

interface AdaptiveSuffixProps {
  showValidationErrorsByDefault: boolean
  rootClass: string
  dataTest: string
}

const AdaptiveSuffix: FC<AdaptiveSuffixProps> = ({ showValidationErrorsByDefault, rootClass }) => {
  const {
    api: { update },
    values: {
      message: {
        sendMethod: { adaptivedatestart = null, adaptivedateend = null },
      },
    },
  } = useContext(EmailComposerContext)
  const { t } = useTranslation()

  const defaultRange = useMemo<DateRangeValueType>(() => [adaptivedatestart, adaptivedateend], [adaptivedatestart, adaptivedateend])

  const [showDateErrors, setShowDateErrors] = useState(showValidationErrorsByDefault)

  const handleDateBlur = useCallback(() => setShowDateErrors(true), [])
  const handleDateFocus = useCallback(() => setShowDateErrors(false), [])

  const handleAdaptiveDateRangeChange = useCallback(
    (dates: DateRangeValueType) => {
      if (dates) {
        const adaptivedatestart = dates[0] || undefined
        const adaptivedateend = dates[1] || undefined
        update({
          message: {
            sendMethod: { adaptivedatestart, adaptivedateend },
          },
        })
      }
    },
    [update]
  )

  const { dateMissing, startDateInPast, endDateInPast } = useMemo(() => {
    if (!adaptivedatestart || !adaptivedatestart.isValid() || !adaptivedateend || !adaptivedateend.isValid()) {
      return { dateMissing: true }
    }
    const startDateInPast = adaptivedatestart.endOf('day').valueOf() < dayjs().startOf('day').valueOf()
    const endDateInPast = adaptivedateend.endOf('day').valueOf() < dayjs().startOf('day').valueOf()
    if (startDateInPast || startDateInPast) {
      return { startDateInPast, endDateInPast }
    }
    return {}
  }, [adaptivedatestart, adaptivedateend])

  const dateRangeErrorMessage = showDateErrors
    ? dateMissing
      ? 'Date range is missing'
      : startDateInPast
      ? endDateInPast
        ? 'Start and end dates are in the past'
        : 'Start date is in the past'
      : undefined
    : undefined

  return (
    <div className={`${rootClass}__send-adaptive-suffix`}>
      <Typography text={t('SendDetails.SendMethod.Adaptive.Suffix.Main')} weight={TextWeight.MEDIUM} />
      <ul>
        <li>
          <Typography text={t('SendDetails.SendMethod.Adaptive.Suffix.PointOne')} />
        </li>
        <li>
          <Typography text={t('SendDetails.SendMethod.Adaptive.Suffix.PointTwo')} />
        </li>
        <li>
          <Typography text={t('SendDetails.SendMethod.Adaptive.Suffix.PointThree')} />
        </li>
      </ul>
      <Label required>
        <Typography text={t('Date range')} weight={TextWeight.MEDIUM} inline />
      </Label>
      <DateRangePicker
        onCalendarSubmit={handleAdaptiveDateRangeChange}
        onFocus={handleDateFocus}
        onBlur={handleDateBlur}
        allowFutureDates
        onlyFutureDates
        defaultRange={defaultRange}
        errorMessage={dateRangeErrorMessage}
        allowDays={6}
        hideErrorIcon
      />
    </div>
  )
}

export default AdaptiveSuffix
