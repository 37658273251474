import React, { FC, ReactNode, useState } from 'react'

import Button, { ButtonType } from '@components/Button'
import Loader from '@components/Loader'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import RadioCard from '@components/RadioCard/RadioCard'
import RadioCardGroup from '@components/RadioCardGroup/RadioCardGroup'
import { SvgNames } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './SelectAssetV2.css'

export interface SelectAssetV2RowItem {
  iconSelected?: SvgNames
  iconUnselected?: SvgNames
  title: string
  description: string
  name: string
  disabled?: boolean
  disabledTooltip?: string
  disabledSvgName?: SvgNames
  baseSvgFill?: SvgColor
  hidden?: boolean
}

export interface SelectAssetV2Props {
  className?: string
  dataTest?: string
  isOpen: boolean
  rowItems: SelectAssetV2RowItem[]
  extraContent?: ReactNode
  onAction: (row: string) => void
  onCancel: () => void
  onChange?: (row: string) => void
  headerText: string
  subHeaderText?: string
  primaryButtonText?: string
  initialSelectedAsset: string
  loading?: boolean
}

const rootClass = 'select-asset-v2'

const SelectAssetV2: FC<SelectAssetV2Props> = (props: SelectAssetV2Props) => {
  const {
    className = rootClass,
    rowItems,
    onAction,
    headerText,
    subHeaderText,
    extraContent,
    isOpen,
    onCancel,
    onChange,
    initialSelectedAsset,
    primaryButtonText = 'Next',
    loading = false,
  } = props
  const { t } = useTranslation()
  const [method, setMethod] = useState<string>(initialSelectedAsset)

  const modalHeader = (
    <ModalHeader headerType={ModalHeaderType.List} className={`${rootClass}__header`}>
      {t(headerText)}
    </ModalHeader>
  )

  const renderList = () => {
    return rowItems
      .filter((item) => !item.hidden)
      .map((item: SelectAssetV2RowItem) => {
        return (
          <RadioCard
            dataTest={`${rootClass}-row`}
            isHugeIcon={false}
            title={item.title}
            description={item.description}
            svgName={item.iconUnselected}
            hoverSvgName={item.iconSelected}
            disabled={item.disabled}
            disabledSvgName={item.disabledSvgName}
            baseSvgFill={item.baseSvgFill}
            tooltipText={item.disabledTooltip}
            key={item.name}
            className={`${rootClass}__radio-card`}
          />
        )
      })
  }

  return (
    <Modal isOpen={isOpen} header={modalHeader} className={className} dataTest={rootClass}>
      <ModalBody className={`${rootClass}__body`}>
        {loading ? (
          <Loader className={`${rootClass}__loader`} />
        ) : (
          <>
            <Typography text={t(subHeaderText)} type={TextType.NORMAL_TEXT_GRAY_LARGE} />
            <RadioCardGroup
              className={`${rootClass}__row-container`}
              data-test={`${rootClass}-row`}
              selectedOption={method}
              onSelect={(key) => {
                onChange?.(`${key}`)
                setMethod(`${key}`)
              }}
            >
              {renderList()}
            </RadioCardGroup>
          </>
        )}
        {extraContent}
      </ModalBody>
      <ModalFooter className={`${rootClass}__footer`}>
        <Button
          buttonType={ButtonType.TERTIARY}
          onClick={() => onCancel()}
          disabled={false}
          className={`${rootClass}__close-button`}
          dataTest={`${rootClass}__close-button`}
        >
          {'Cancel'}
        </Button>
        <Button
          buttonType={ButtonType.PRIMARY}
          className={`${rootClass}__save-button`}
          dataTest={`${rootClass}__save-button`}
          onClick={() => onAction(method)}
        >
          {t(primaryButtonText)}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default SelectAssetV2
