import React, { FC } from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'

import { bindActionCreators } from 'redux'

import { useTranslation } from '@const/globals'
import { useAccountSettings } from '@utils/account/account.utils'
import documentUtils from '@utils/document'
import navigationActions, { NavigationActions } from '@utils/navigation/state/actions'

import { RouteConfig } from './allRoutes'
import ErrorBoundary from '../ErrorBoundary'

type CustomRouteProps = RouteConfig & NavigationActions

export const CustomRoute: FC<CustomRouteProps> = (props: CustomRouteProps) => {
  const { title, component, path, exact } = props
  const { t } = useTranslation()
  const { appTitle, isAgencyAccount } = useAccountSettings()

  React.useEffect(() => {
    documentUtils.setTitle(t(title), !isAgencyAccount ? appTitle : undefined)
    props.setNavigationPath(path)
  }, [])

  if (!component) return null

  return (
    <ErrorBoundary>
      <Route path={props.path} component={component} exact={exact} />
    </ErrorBoundary>
  )
}

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: any) => bindActionCreators({ ...navigationActions }, dispatch)

export default connect(null, mapDispatchToProps)(CustomRoute)
