import { gql } from '@apollo/client'

export default gql`
  query DataManagementPageLoad {
    entityTypes {
      display
      identifier
      visible
      nameColumns
      pkColumn
      recordCount
    }

    syncSchedule {
      mode
      hourInterval
      dayOfWeek
      dayOfMonth
      hour
      minute
      ampm
      automaticSchedule
    }

    optInOutSettings {
      optInOutSyncSupported
      optInOutPullEnabled
      optInOutPushEnabled
    }

    lastSync {
      ended
      syncResult
    }

    syncStatus

    emailOptInOutVisibility {
      visibility
      errorInstruction
      moreInfoUrl
    }
  }
`
