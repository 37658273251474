import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { LabelType, LabelV2 } from '@components/LabelV2/LabelV2'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'
import { FieldDefinition, ProgramAdditionalEntity } from '@graphql/types/query-types'
import { EditCRMStepV2Context } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.context'
import { useEditCRMStepV2Requests } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.graphQL'
import { ProgramCreateInCRMStepExt } from '@utils/program/program.constants'

interface CRMPickListFieldProps {
  className?: string
  dataTest?: string
  field: FieldDefinition
  onChange: (option?: SelectV2SingleOption) => void
  value?: string
  entityIndex: number
  entity?: ProgramAdditionalEntity
}

const CRMPickListField: FC<CRMPickListFieldProps> = (props: CRMPickListFieldProps) => {
  const { dataTest, className = '', field, value = '', entityIndex, entity, onChange } = props
  const { displayName, required, fieldName, pickListValues = [], picklistDependentOn = '', pickListField = '', pickListEntity = '' } = field
  const [options, setOptions] = useState<SelectV2SingleOption[]>()
  const { getProgramStepDependentPicklistValues } = useEditCRMStepV2Requests()
  const { watch } = useFormContext<ProgramCreateInCRMStepExt>()
  const additionalEntityFields = watch(`additionalEntities.${entityIndex}.fields`)
  const dependentFieldValue = useMemo(() => additionalEntityFields?.find(({ key }) => key === picklistDependentOn)?.value, [additionalEntityFields])
  const { t } = useTranslation()

  const {
    values: { fieldDefinitionsByEntity },
  } = useContext(EditCRMStepV2Context)

  const entityType = entity?.entityType ?? ''

  const fieldDefinitions = fieldDefinitionsByEntity?.get(entityType)

  const dependentField = fieldDefinitions?.find(({ fieldName }) => fieldName === picklistDependentOn)

  const dependentFieldDisplayName = dependentField?.displayName

  useEffect(() => {
    setOptions(pickListValues.map<SelectV2SingleOption>((value) => ({ label: value, value })))
    if (dependentFieldValue && dependentFieldValue != '') {
      getProgramStepDependentPicklistValues(pickListEntity, picklistDependentOn, dependentFieldValue ?? '', pickListField).then((data) => {
        if (data.crmProgramStepDependentPicklistValues && data.crmProgramStepDependentPicklistValues.length) {
          setOptions(data.crmProgramStepDependentPicklistValues.map<SelectV2SingleOption>((value) => ({ label: value, value })))
        }
      })
    }
  }, [dependentFieldValue])

  return (
    <div className={`${className}-select`} data-test={`${dataTest}-pick-list`}>
      <LabelV2 label={displayName} required={required} labelType={LabelType.input} />
      <Tooltip
        trigger={
          <SelectV2
            options={options}
            dataTest={`${dataTest}-field-${fieldName}`}
            isRequired={required}
            isClearable={!required}
            onChange={onChange}
            value={options?.find((option) => option.value === value)}
            insideModal
            isDisabled={!!picklistDependentOn && !dependentFieldValue}
          />
        }
        hide={!picklistDependentOn || !!dependentFieldValue}
        fullWidth
      >
        {t(`EditCrmStepV2.FieldsSelector.PickList.DependentFieldWarning`, { fieldName: dependentFieldDisplayName ?? picklistDependentOn })}
      </Tooltip>
    </div>
  )
}

export default CRMPickListField
