export interface CurrentToggleConfig {
  title: string
  feature: string
  disabled: boolean
  tooltip?: string
}

const CURRENT_TOGGLE: CurrentToggleConfig = {
  title: 'New menu',
  feature: 'ActonUI',
  disabled: true,
  tooltip: 'new navigation and search experience',
}

export default {
  getCurrentToggle: () => CURRENT_TOGGLE,
}
