import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Svg from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './DirtyBanner.css'

type Props = {
  expanded: boolean
  fullWidth?: boolean
  onSave: () => void
  onDiscard: () => void
  className?: string
  dataTest?: string
  saveDisabled?: boolean
  discardButtonText?: string
  saveButtonText?: string
  saveButtonTooltipText?: string
}

const rootClass = 'dirty-banner'

const DirtyBanner: FC<Props> = (props: Props) => {
  const {
    onSave,
    onDiscard,
    dataTest = rootClass,
    className = '',
    saveDisabled = false,
    discardButtonText,
    saveButtonText,
    saveButtonTooltipText,
  } = props
  const { t } = useTranslation()

  const saveButton = (
    <Button className={`${rootClass}__button-save`} buttonType={ButtonType.PRIMARY} disabled={saveDisabled} onClick={onSave}>
      {saveButtonText ?? t('Save')}
    </Button>
  )

  const renderMain = () => (
    <div className={`${rootClass}__main`}>
      <div className={`${rootClass}__position`}>
        <div className={`${rootClass}__content`}>
          <Typography text={t('Unsaved Changes')} className={`${rootClass}__title`} type={TextType.BANNER_HEADER} />
          <div className={`${rootClass}__buttons`}>
            {!!saveButtonTooltipText && saveDisabled ? (
              <Tooltip trigger={saveButton} triggerClassName={`${rootClass}__button-save-container`}>
                {t(saveButtonTooltipText)}
              </Tooltip>
            ) : (
              saveButton
            )}
            <Button className={`${rootClass}__button-discard`} buttonType={ButtonType.TRANSPARENT} onClick={onDiscard}>
              {discardButtonText ?? t('Discard')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div className={classNames(rootClass, className)} data-test={`${dataTest}`}>
      <div className={`${rootClass}__nav`}>
        <Svg dataTest="navigation-logo-dark" name={SvgNames.logoWhite} />
      </div>
      <div className={`${rootClass}__nav-mobile`}>
        <Svg dataTest="navigation-logo-dark" name={SvgNames.iconWhite} />
      </div>
      {renderMain()}
    </div>
  )
}

export default DirtyBanner
