import { ModalHeaderFormStyle, ModalHeaderListStyle, ModalHeaderStyle } from '@components/Typography/Typography'
import utils from '@utils/utils'

import { getModalCount } from './Modal'

export const MODAL_BASE_Z_INDEX = 20000

export function setAriaHidden(hidden: boolean, root: HTMLDivElement) {
  root?.setAttribute('aria-hidden', `${hidden}`)
}

const manageBodyClass = (addClass: boolean, root: HTMLDivElement, modalRoot: HTMLDivElement) => {
  if (addClass) {
    root?.classList.add('modal--open')
    modalRoot?.classList.add('modal-root--visible')
  } else {
    root?.classList.remove('modal--open')
    modalRoot?.classList.remove('modal-root--visible')
  }
}

export function updateModalBackgroundZIndex() {
  const backZIndex = MODAL_BASE_Z_INDEX + 2 * getModalCount()
  const modalBack = document.getElementById('modal-root-background')
  if (modalBack) {
    modalBack.style.zIndex = `${backZIndex}`
  }
}

export function openModal(root: HTMLDivElement = utils.getApplicationRoot(), modalRoot: HTMLDivElement = utils.getModalRoot()) {
  manageBodyClass(true, root, modalRoot)
  setAriaHidden(true, root)
  updateModalBackgroundZIndex()
}

export function closeModal(root: HTMLDivElement = utils.getApplicationRoot(), modalRoot: HTMLDivElement = utils.getModalRoot()) {
  const modals = getModalCount()
  if (modals === 0) {
    manageBodyClass(false, root, modalRoot)
    setAriaHidden(false, root)
  }
  updateModalBackgroundZIndex()
}

// Duplicating these here because many, many existing modals import them from ModalHeaderV1 and I don't want to pollute the PR
export enum ModalHeaderType {
  Basic = 'basic',
  Form = 'form',
  List = 'list',
}

export const styleMap = {
  [ModalHeaderType.Basic]: ModalHeaderStyle,
  [ModalHeaderType.Form]: ModalHeaderFormStyle,
  [ModalHeaderType.List]: ModalHeaderListStyle,
}

export type ModalType = 'basic' | 'form' | 'list'

export default {
  openModal,
  closeModal,
}
