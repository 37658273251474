import { SyncedSegment, SyncedSegmentDetailsQuery } from '@graphql/types/microservice/crm-types'
import { logNewRelicError } from '@utils/new-relic.utils'
import { FetchPromise } from '@utils/types'

type SyncedSegmentDetailsRequestParams = { segmentIdentifiers: string[] }
type SyncedSegmentDetailsRequestType = (params: SyncedSegmentDetailsRequestParams) => FetchPromise<SyncedSegmentDetailsQuery>

export const ALL_ACTON_CONTACTS_SEGMENT_NAME = 'All Act-On Contacts'

export const getCRMSourcedSegmentDetailsUtils = async (
  segmentIdentifiers: string[],
  syncedSegmentDetailsRequest: SyncedSegmentDetailsRequestType
): Promise<SyncedSegment[] | undefined> => {
  try {
    if (segmentIdentifiers.length > 0) {
      const { data } = await syncedSegmentDetailsRequest({ segmentIdentifiers })
      if (data?.syncedSegmentDetails) {
        return data.syncedSegmentDetails as SyncedSegment[]
      }
    } else {
      return []
    }
  } catch (e) {
    logNewRelicError(e)
  }
}
