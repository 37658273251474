import React, { FC, useContext, useState } from 'react'

import { ListPickerModalContext } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import BackButton from '@components/BackButton/BackButton'
import { ModalHeader } from '@components/Modal'
import Search, { SearchProps, SearchType } from '@components/Search/Search'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { ModalHeaderFormStyle } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './ListPickerModalHeader.css'

interface ListPickerModalHeaderProps {
  className?: string
  dataTest?: string
  doSearch: (value: string) => void
  hideSearchBar: boolean
  multiSelect: boolean
  onClearSearch: VoidFunction
  onBackArrowClick?: () => void
  title?: string
}

const rootClass = 'list-picker-modal-header'

const ListPickerModalHeader: FC<ListPickerModalHeaderProps> = (props: ListPickerModalHeaderProps) => {
  const { doSearch, hideSearchBar, multiSelect, onClearSearch, dataTest = rootClass, onBackArrowClick, title } = props

  const {
    values: { currentFilter, currentFolder, currentTag, viewingSelectedLists },
  } = useContext(ListPickerModalContext)

  const [search, setSearch] = useState('')

  const { t } = useTranslation()

  const onClear = () => {
    setSearch('')
    onClearSearch()
  }

  const searchProps: SearchProps = {
    canClear: true,
    className: `${rootClass}__search-input`,
    clearOnChange: [currentFilter, currentFolder, currentTag],
    dataTest: `${dataTest}-search-input`,
    incomingValue: '',
    onChangeHandler: (value: string) => (value ? doSearch(value) : onClear()),
    placeholder: t('Search list'),
    searchType: SearchType.LARGE,
    instantChangeUpdate: (value: string) => setSearch(value),
  }

  const getHeaderTitle = () => {
    if (viewingSelectedLists) {
      return t(`You selected the following`)
    }
    if (title) {
      return title
    }
    return t(`Select List${multiSelect ? 's' : ''} ${multiSelect ? '&' : 'or'} Segment${multiSelect ? 's' : ''}`)
  }

  return (
    <ModalHeader className={rootClass} hideBorder>
      <div className={`${rootClass}__header`}>
        {onBackArrowClick && <BackButton onClick={onBackArrowClick} />}
        <Typography text={getHeaderTitle()} {...ModalHeaderFormStyle} />
      </div>
      {!viewingSelectedLists && !hideSearchBar && (
        <Tooltip trigger={<Search {...searchProps} />} align={'start'} hide={search.length > 2}>
          {t('Please enter at least 3 characters to search')}
        </Tooltip>
      )}
    </ModalHeader>
  )
}

export default ListPickerModalHeader
