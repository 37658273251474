import React, { FC } from 'react'

import DownloadButton from '@components/DownloadButton/DownloadButton'
import PageContainer from '@components/PageContainer'
import PageHeader from '@components/PageHeader'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import { useTranslation } from '@const/globals'
import { OptStatus } from '@graphql/types/microservice/sms-management-types'
import InitialMessageSending from '@src/pages/sms/initialMessage/components/InitialMessageSending/InitialMessageSending'
import { SortByState } from '@utils/sms.utils'

import { SMSOptOutTable } from './components/SMSOptoutTable/SMSOptoutTable'

import './smsOptout.css'

const rootClass = 'sms-opt-outs'

interface SMSOptoutProps {
  entries: OptStatus[] | []
  pageSize: number | 10
  pageIndex: number | 0
  totalCount: number | 0
  sortBy: SortByState[]
  loading?: boolean
  isInitialMessageInSending?: boolean
  onChangeSort: (sortBy: SortByState[]) => void
  fetchData: (pageIndex: number, pageSize: number) => void
  dataTest?: string
  downloadUrl: string
}

const SMSOptout: FC<SMSOptoutProps> = (props: SMSOptoutProps) => {
  const {
    entries,
    loading = false,
    isInitialMessageInSending,
    dataTest,
    totalCount,
    sortBy,
    pageSize,
    pageIndex,
    fetchData,
    onChangeSort,
    downloadUrl,
  } = props
  const { t } = useTranslation()
  const downloadDate = new Date()
  const downloadDateStr = downloadDate.toDateString().replace(/ /g, '-')

  return (
    <PageContainer dataTest={dataTest}>
      {!loading && isInitialMessageInSending && <InitialMessageSending />}
      <PositionContainer>
        <div className={rootClass}>
          <PageHeader primaryText={t('SMS Opt-Out List')} leftContent dataTest={rootClass}>
            {entries.length > 0 && (
              <DownloadButton
                url={downloadUrl}
                className={`${rootClass}__download-btn`}
                filename={downloadDateStr}
                label={'Download CSV'}
                dataTest={`${dataTest}__upload-button`}
              />
            )}
          </PageHeader>
          <SMSOptOutTable
            entries={entries}
            totalCount={totalCount}
            pageSize={pageSize}
            pageIndex={pageIndex}
            fetchData={fetchData}
            sortBy={sortBy}
            onChangeSort={onChangeSort}
            loading={loading}
          />
        </div>
      </PositionContainer>
    </PageContainer>
  )
}

export default SMSOptout
