import { gql } from '@apollo/client'
import { ITEM_FIELDS } from '@graphql/microservices/categorization/fragments'

export default gql`
  ${ITEM_FIELDS}
  query SearchSegments(
    $pageNumber: Int
    $allSegments: Boolean
    $folder: Long
    $query: String
    $field: String
    $pageSize: Int
    $type: String
    $folderSubTypes: [String]
    $subTypes: [String]
    $searchHierarchy: Boolean
  ) {
    search(
      pageNumber: $pageNumber
      allSegments: $allSegments
      folder: $folder
      query: $query
      field: $field
      pageSize: $pageSize
      type: $type
      folderSubTypes: $folderSubTypes
      subTypes: $subTypes
      searchHierarchy: $searchHierarchy
    ) {
      totalCount
      items {
        ...ItemFields
      }
    }
  }
`
