import { gql } from '@apollo/client'

export default gql`
  query personalizeMessage($smsPersonalizedMessageState: SmsPersonalizedMessageState!) {
    personalizeMessage(smsPersonalizedMessageState: $smsPersonalizedMessageState) {
      recId
      messageText
    }
  }
`
