import React from 'react'

import Svg, { SvgNames, SvgType } from '@components/Svg'

import { OptInsSourceList } from './OptInsSourceLists.constants'

export const getListIcon = (list?: OptInsSourceList) => {
  if (!list) {
    return SvgNames.uploaded
  }

  switch (list.listType) {
    case 'CRM':
      return SvgNames.crm
    case 'SEGMENT':
      return SvgNames.segment
    case 'MARKETING':
      return SvgNames.contacts
    case 'EXTENSION':
      return SvgNames.extension
    case 'WEBINAR':
      return SvgNames.webinar
    case 'FORM_SUBMISSION':
      return SvgNames.formSubmission
    default:
      return SvgNames.uploaded
  }
}

export const getListFromRow = (row: any, lists: OptInsSourceList[]) => {
  return lists.find((list) => list.listDisplayName === row.cell.row.original.listDisplayName)
}

export const renderListType = (row: any, lists: OptInsSourceList[]) => (
  <Svg name={getListIcon(getListFromRow(row ?? undefined, lists))} type={SvgType.LARGER_ICON} />
)
