import * as yup from 'yup'

yup.addMethod(yup.object, 'uniqueProperty', function (propertyName, message) {
  const parent = this as any
  if (!('test' in parent)) {
    return true
  }

  return parent.test('unique', message, (objects: any) => {
    const keys = Object.keys(objects)
      .map((key) => (objects[key] ?? {})[propertyName])
      .filter((property) => !!property)
    const uniqueKeys = [...new Set(keys)]
    return keys.length === uniqueKeys.length
  })
})
