import { useCallback, useEffect } from 'react'

import { blockedBroadcastChannel } from '@utils/composer/commonComposer/broadcastChannel'
import { BlockedModalProps } from '@utils/composer/landingPage/types'
import { useTranslation } from '@utils/const/globals'
import { getSessionStorageItem, setSessionStorageItem } from '@utils/window'

interface TabVisibilityConfig {
  isModalOpened?: boolean
  onTabVisible?: () => void | Promise<boolean | undefined>
  onTabHidden?: () => void | Promise<void>
  closeButtonConfig?: {
    onClick?: () => void
    buttonText: string
    fallbackMessage?: string
  }
  modalConfig: {
    title: string
    bodyText: string
    buttonProps?: {
      text?: string
      onClick?: () => void
    }
  }
  onModalShow: (modalProps: BlockedModalProps) => void
  accountId: string
}

export const isTabBlocked = JSON.parse(getSessionStorageItem('isTabBlocked') || 'false')

export const useComposerBlockedTab = ({ isModalOpened, closeButtonConfig, modalConfig, accountId, onModalShow }: TabVisibilityConfig) => {
  const { t } = useTranslation()

  const handleClose = useCallback(() => {
    if (closeButtonConfig?.onClick) {
      closeButtonConfig.onClick()
      return
    }

    if (window.opener) {
      window.close()
    } else {
      alert(closeButtonConfig?.fallbackMessage || t('Blocked.LoggedOut.CantClose'))
    }
  }, [closeButtonConfig])

  const customButtonProps: BlockedModalProps = {
    title: modalConfig.title,
    bodyText: modalConfig.bodyText,
    customButtonProps: {
      onClick: handleClose,
      text: modalConfig.buttonProps?.text || closeButtonConfig?.buttonText || '',
    },
  }

  useEffect(() => {
    if (isTabBlocked) {
      onModalShow(customButtonProps)
    }

    const handleBroadcastMessage = (event: MessageEvent) => {
      if (event.data.type === 'LOGIN' && event.data.accountId !== accountId) {
        onModalShow(customButtonProps)
        setSessionStorageItem('isTabBlocked', 'true')
      }
    }

    blockedBroadcastChannel.addEventListener('message', handleBroadcastMessage)

    return () => {
      blockedBroadcastChannel.removeEventListener('message', handleBroadcastMessage)
    }
  }, [accountId, t, isModalOpened])
}
