import React, { FC, useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import axios from 'axios'

import { SelectOption } from '@components/MultiSelect/MultiSelect'
import getTagClicks from '@graphql/microservices/email-event/getTagClicks'
import { TagNameClick, GetTagClicksQuery, GetTagClicksQueryVariables } from '@graphql/types/microservice/email-event-types'
import { useAccountSettings } from '@utils/account/account.utils'
import useMicroserviceClient, { useMicroserviceUrl, MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'
import { SortByState } from '@utils/sms.utils'
import { download } from '@utils/utils'

import TagsTab from './TagsTab'
import { LinkPerformanceLinkLocationState } from '../../utils/LinkPerformance.utils'
import { Direction, TagNameClickSortColumns } from '../../utils/LinkPerformance.utils'

const PAGE_SIZE = 10

const DEFAULT_SORT_BY: TagSortBy = {
  id: TagNameClickSortColumns.Tagname,
  direction: Direction.Asc,
}

export type TagSortBy = {
  id: TagNameClickSortColumns
  direction: Direction
}

type TabsTabContainerProps = {
  messageId: string
  allTags: SelectOption[]
}

const TagsTabContainer: FC<TabsTabContainerProps> = (props: TabsTabContainerProps) => {
  const { messageId, allTags } = props

  const history = useHistory()
  const location = useLocation()
  const locationState: LinkPerformanceLinkLocationState = location?.state as LinkPerformanceLinkLocationState

  const { accountId } = useAccountSettings()

  const { client, token, aoAccountContext } = useMicroserviceClient({ serviceName: MicroserviceClients.EMAIL_EVENT })
  const downloadUrl = useMicroserviceUrl({ serviceName: MicroserviceClients.EMAIL_EVENT })

  const getSortColumn = (column: TagNameClickSortColumns) => {
    return column == TagNameClickSortColumns.Tagname
      ? 'tagName'
      : column === TagNameClickSortColumns.Totalclicks
      ? 'totalClicks'
      : column === TagNameClickSortColumns.Uniqueclicks
      ? 'uniqueClicks'
      : ''
  }

  const [sortBy, setSortBy] = useState<SortByState[]>([
    {
      id: locationState?.tagSortColumn ? getSortColumn(locationState.tagSortColumn) : getSortColumn(DEFAULT_SORT_BY.id),
      desc:
        locationState?.tagSortDirection && locationState.tagSortDirection === Direction.Desc
          ? true
          : DEFAULT_SORT_BY.direction == Direction.Desc
          ? true
          : false,
    },
  ])

  const [tagTags, setTagTags] = useState<string[]>(locationState?.tagTags || [])
  const [tagSortDirection, setTagSortDirection] = useState<Direction>(locationState?.tagSortDirection || DEFAULT_SORT_BY.direction)
  const [tagSortColumn, setTagSortColumn] = useState<TagNameClickSortColumns>(locationState?.tagSortColumn || DEFAULT_SORT_BY.id)
  const [tagData, setTagData] = useState<TagNameClick[]>([])
  const [tagSize, setTagSize] = useState(locationState?.tagPageSize || PAGE_SIZE)
  const [tagPage, setTagPage] = useState(locationState?.tagPage || 0)
  const [tagTotalCount, setTagTotalCount] = useState(0)
  const [tagTotalClicks, setTagTotalClicks] = useState(0)
  const [tagTotalUniqueClicks, setTagTotalUniqueClicks] = useState(0)
  const [resetPageIndex, setResetPageIndex] = useState(false)

  const {
    loading,
    error: pageError,
    data,
  } = useQueryOnMount<GetTagClicksQuery, GetTagClicksQueryVariables>(getTagClicks, {
    client,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      accountId,
      messageId,
      tagNames: tagTags,
      sortDirection: tagSortDirection,
      sortColumn: tagSortColumn,
      size: tagSize,
      page: tagPage,
    },
  })

  const updateData = (data: GetTagClicksQuery) => {
    const { getTagClicks } = data
    const { totalCount, totalClickCount, totalUniqueCount } = getTagClicks ?? { totalCount: 0, totalClickCount: 0, totalUniqueCount: 0 }

    const tagDataCleaned = getTagClicks?.tagNameList
      ? getTagClicks.tagNameList.map((tag) => {
          return {
            id: tag?.id || '',
            tagName: tag?.tagName || '',
            totalClicks: tag?.totalClicks,
            uniqueClicks: tag?.uniqueClicks,
          }
        })
      : []

    setTagData(tagDataCleaned)
    setTagTotalCount(totalCount)
    setTagTotalClicks(totalClickCount)
    setTagTotalUniqueClicks(totalUniqueCount)
    setResetPageIndex(false)
  }

  const fetchTagData = (pageIndex: number, pageSize: number) => {
    const locationState = {
      ...(history.location.state as Object),
      tagPageSize: pageSize,
      tagPage: pageIndex,
    }

    history.replace(location.pathname, locationState)
    setTagPage(pageIndex)
    setTagSize(pageSize)
  }

  const tagSort = (newSortBy: SortByState[]) => {
    const sortColumn =
      newSortBy && newSortBy[0]
        ? newSortBy[0].id === 'tagName'
          ? TagNameClickSortColumns.Tagname
          : newSortBy[0].id === 'totalClicks'
          ? TagNameClickSortColumns.Totalclicks
          : newSortBy[0].id === 'uniqueClicks'
          ? TagNameClickSortColumns.Uniqueclicks
          : DEFAULT_SORT_BY.id
        : DEFAULT_SORT_BY.id

    const direction = sortBy && sortBy.length && sortBy[0].desc ? Direction.Desc : Direction.Asc

    const newTagPage = sortBy[0].id !== newSortBy[0].id ? 0 : tagPage

    const locationState = {
      ...(history.location.state as Object),
      tagSortDirection: direction,
      tagSortColumn: sortColumn,
      tagPage: newTagPage,
    }

    history.replace(location.pathname, locationState)
    setTagSortColumn(sortColumn)
    setTagSortDirection(direction)
    setSortBy(newSortBy)
    setTagPage(newTagPage)
  }

  const tagOnsubmit = (selected: string[]) => {
    const locationState = {
      ...(history.location.state as Object),
      tagTags: selected,
      tagPage: 0,
    }

    history.replace(location.pathname, locationState)
    setTagTags(selected)
    setTagPage(0)
    setResetPageIndex(true)
  }

  const onDownload = () => {
    axios({
      url: `${downloadUrl}/email/click/report/tag/${accountId}/${messageId}`,
      method: 'POST',
      headers: { authorization: token, AOAccountContext: aoAccountContext, 'Content-Type': 'application/json' },
      responseType: 'blob',
      data: tagTags,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      download(url, 'results.csv')
    })
  }

  useEffect(() => {
    if (data) {
      updateData(data)
    }
  }, [data])

  return (
    <TagsTab
      data={tagData}
      tagOptions={allTags.map((tag) => ({
        ...tag,
        selected: tagTags.includes(tag.value) ? true : false,
      }))}
      sortBy={sortBy}
      onSort={tagSort}
      tagOnsubmit={tagOnsubmit}
      onDownload={onDownload}
      totalCount={tagTotalCount}
      totalClicks={tagTotalClicks}
      uniqueClicks={tagTotalUniqueClicks}
      pageSize={tagSize}
      pageIndex={tagPage}
      loading={loading}
      fetchData={fetchTagData}
      pageError={pageError}
      resetPageIndex={resetPageIndex}
    ></TagsTab>
  )
}

export default TagsTabContainer
