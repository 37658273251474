import { useCallback, useRef, useState } from 'react'

import { TableV2Props } from '@components/TableV2/tableV2TS/interfaces'
import { RowSelectionState } from '@tanstack/react-table'
import { DataWithId, Defined } from '@utils/types'

import { ItemizedExpandedState } from '../tableV2TS/types'

export interface PersistedRowState {
  idSelectedGroups: { [groupId: string]: string[] | undefined }
  rowSelectedGroups: { [groupId: string]: RowSelectionState | undefined }
  idExpandedGroups: { [groupId: string]: string[] | undefined }
  rowExpandedGroups: { [groupId: string]: ItemizedExpandedState | undefined }
}

export interface PersistedRowStateProps {
  /** If this is provided then the selection state will track different groups of items separately */
  groupId?: string
  /** If true, only one item can be selected at a time */
  isSingleSelect?: boolean
}

interface PersistedRowStateTableProps<T extends DataWithId> {
  onRowSelectionChanged: Defined<TableV2Props<T>['onRowSelectionChanged']>
  onRowsChanged: Defined<TableV2Props<T>['onRowsChanged']>
  onRowExpanded: Defined<TableV2Props<T>['onRowExpanded']>
  defaultSelectedRows: TableV2Props<T>['defaultSelectedRows']
  defaultExpandedRows: TableV2Props<T>['defaultExpandedRows']
}

export interface PersistedRowStateValues<T extends DataWithId> {
  /** Selected item IDs grouped by group ID */
  idSelectedGroups: Record<string, string[] | undefined>
  /** Selected item IDs in the current group */
  idSelectedState: string[]
  /** Expanded item IDs grouped by group ID */
  idExpandedGroups: Record<string, string[] | undefined>
  /** Expanded item IDs in the current group */
  idExpandedState: string[]
  /** Contains all the table props that should be passed into the table to integrate with this hook */
  tableProps: PersistedRowStateTableProps<T>
}

const DEBOUNCE_ROW_CHANGE = 50

/**
 * For use when a component flow has multiple table views and the same items need to remain selected.
 * `TableV2` row IDs are (usually) indexes that are automatically generated by React Table and may differ from the item IDs.
 * This is definitely true if the table has subRows enabled.
 * This persists a list of item IDs that have been selected and returns selection state that is compatible with the rows currently in the table.
 *
 * @note Only requires that the data type for these rows has an 'id' field.
 */
export const usePersistedRowState = <T extends DataWithId>(props?: PersistedRowStateProps): PersistedRowStateValues<T> => {
  const { isSingleSelect, groupId = 'DEFAULT' } = props ?? {}
  const lastRowChange = useRef(0)

  const [state, setState] = useState<PersistedRowState>({
    idSelectedGroups: {},
    rowSelectedGroups: {},
    idExpandedGroups: {},
    rowExpandedGroups: {},
  })
  const { idSelectedGroups, rowSelectedGroups, idExpandedGroups, rowExpandedGroups } = state

  const updateRowSelectedState = useCallback(
    (rowId: string, currentState: RowSelectionState): RowSelectionState => {
      const selectionState: RowSelectionState = { [rowId]: true }
      return isSingleSelect ? selectionState : { ...currentState, ...selectionState }
    },
    [isSingleSelect]
  )

  const shouldDebounce = () => {
    // Whenever onRowsChanged triggers it is either because the table has initialized or the user started searching.
    // This will be followed by several triggers of onRowSelectionChanged (reasons unknown),
    // however processing those will conflict with what onRowsChanged is doing.
    const passedThreshold = new Date().valueOf() - lastRowChange.current >= DEBOUNCE_ROW_CHANGE
    if (passedThreshold) {
      lastRowChange.current = new Date().valueOf()
    }
    return !passedThreshold
  }

  /** Update our selected item IDs to reflect what the user has selected/deselected */
  const onRowSelectionChanged = useCallback<Defined<TableV2Props<T>['onRowSelectionChanged']>>(
    (selectedRowIds, currentRows) => {
      if (shouldDebounce()) {
        return
      }
      setState(({ idSelectedGroups, rowSelectedGroups, ...rest }) => {
        const newRowIdMap = currentRows.reduce<Record<string, string>>((prev, row) => ({ ...prev, [String(row.id)]: String(row.original.id) }), {})
        const newItemIdMap = currentRows.reduce<Record<string, string>>((prev, row) => ({ ...prev, [String(row.original.id)]: String(row.id) }), {})
        const idSelectedState = idSelectedGroups[groupId] ?? []

        const selectedItemIds = selectedRowIds.map((rowId) => newRowIdMap[rowId])
        const deselectedItemIds = idSelectedState.filter(
          // Of the items that were selected before, which ones are currently in the table but not part of selectedRowIds?
          (id) => Object.values(newRowIdMap).includes(id) && !selectedItemIds.includes(id)
        )
        const combinedItemIds = isSingleSelect ? selectedItemIds : [...new Set([...idSelectedState, ...selectedItemIds])]
        const newIdSelectedState = combinedItemIds.filter((id) => !deselectedItemIds.includes(id))

        const newRowSelectedState = newIdSelectedState.reduce<RowSelectionState>((prev, itemId) => {
          const rowId = newItemIdMap[itemId]
          if (rowId) {
            return updateRowSelectedState(rowId, prev)
          }
          return prev
        }, {})

        const result = {
          ...rest,
          idSelectedGroups: { ...idSelectedGroups, [groupId]: newIdSelectedState },
          rowSelectedGroups: { ...rowSelectedGroups, [groupId]: newRowSelectedState },
        }
        return result
      })
    },
    [setState, updateRowSelectedState, groupId, isSingleSelect]
  )

  /** Translate our current selected item IDs into a new selection state for the changed rows */
  const onRowsChanged = useCallback<Defined<TableV2Props<T>['onRowsChanged']>>(
    (rows) => {
      if (shouldDebounce()) {
        return
      }
      setState(({ idSelectedGroups, rowSelectedGroups, rowExpandedGroups, ...rest }): PersistedRowState => {
        const idSelectedState = idSelectedGroups[groupId] ?? []

        // If we get new rows and some of them are already selected, it is because the component using this hook already set some default selected rows
        const alreadySelectedIds = rows.filter((row) => row.getIsSelected()).map((row) => String(row.original.id))
        const newIdSelectedState = [...new Set([...idSelectedState, ...alreadySelectedIds])]

        const newRowSelectionState = rows.reduce<RowSelectionState>((prev, row) => {
          if (newIdSelectedState.includes(String(row.original.id))) {
            return updateRowSelectedState(row.id, prev)
          }
          return prev
        }, {})

        // If table already set some default expanded rows
        const alreadyExpandedRows = rows.filter((row) => row.getIsExpanded()).map((row) => row.id)
        const updatedRowExpandedState = alreadyExpandedRows.reduce<ItemizedExpandedState>((prev, rowId) => {
          return { ...prev, [rowId]: true }
        }, {})

        const result = {
          ...rest,
          idSelectedGroups: { ...idSelectedGroups, [groupId]: newIdSelectedState },
          rowSelectedGroups: { ...rowSelectedGroups, [groupId]: newRowSelectionState },
          rowExpandedGroups: { ...rowExpandedGroups, [groupId]: { ...rowExpandedGroups[groupId], ...updatedRowExpandedState } },
        }
        return result
      })
    },
    [groupId, updateRowSelectedState]
  )

  const onRowExpanded = useCallback<Defined<TableV2Props<T>['onRowExpanded']>>(
    (row) => {
      setState(({ idExpandedGroups, ...rest }): PersistedRowState => {
        const idExpandedState = idExpandedGroups[groupId] ?? []
        // This callback fires before the row is actually toggled
        const isExpanded = !row.getIsExpanded()

        const newIdExpandedState = isExpanded
          ? [...idExpandedState, String(row.original.id)]
          : idExpandedState.filter((id) => id !== String(row.original.id))

        const newRowExpandedState = { ...rowExpandedGroups[groupId], [row.id]: isExpanded }
        return {
          ...rest,
          idExpandedGroups: { ...idExpandedGroups, [groupId]: [...new Set(newIdExpandedState)] },
          rowExpandedGroups: { ...rowExpandedGroups, [groupId]: newRowExpandedState },
        }
      })
    },
    [groupId, rowExpandedGroups]
  )

  const tableProps: PersistedRowStateTableProps<T> = {
    onRowSelectionChanged,
    onRowsChanged,
    onRowExpanded,
    defaultSelectedRows: rowSelectedGroups[groupId],
    defaultExpandedRows: rowExpandedGroups[groupId],
  }

  return {
    idSelectedGroups,
    idSelectedState: idSelectedGroups[groupId] ?? [],
    idExpandedGroups,
    idExpandedState: idExpandedGroups[groupId] ?? [],
    tableProps,
  }
}
