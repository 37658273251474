import { useApolloClient } from '@apollo/client'
import deleteContentFragment from '@graphql/mutations/deleteContentFragment'
import getContentFragmentPreview from '@graphql/queries/getContentFragmentPreview'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { DeleteContentFragmentMutation, DeleteContentFragmentMutationVariables } from '@graphql/types/mutation-types'
import { GetContentFragmentPreviewQuery, GetContentFragmentPreviewQueryVariables } from '@graphql/types/query-types'
import { FetchPromise } from '@utils/types'

export interface ContentFragmentListingPageRequests {
  deleteItemsRequest: (fragments: ItemDto[]) => FetchPromise<DeleteContentFragmentMutation>
  getContentFragmentPreviewRequest: (itemId: string) => FetchPromise<GetContentFragmentPreviewQuery>
}

export const useContentFragmentListingPageRequests = (): ContentFragmentListingPageRequests => {
  const actonClient = useApolloClient()

  const deleteItemsRequest = async (fragments: ItemDto[]) =>
    await actonClient.mutate<DeleteContentFragmentMutation, DeleteContentFragmentMutationVariables>({
      mutation: deleteContentFragment,
      errorPolicy: 'all',
      variables: {
        fragmentIds: fragments.map(({ id }) => id),
      },
    })

  const getContentFragmentPreviewRequest = async (itemId: string) =>
    await actonClient.query<GetContentFragmentPreviewQuery, GetContentFragmentPreviewQueryVariables>({
      query: getContentFragmentPreview,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        id: itemId,
      },
    })

  return {
    deleteItemsRequest,
    getContentFragmentPreviewRequest,
  }
}
