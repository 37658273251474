import { gql } from '@apollo/client'

export default gql`
  mutation setFieldsForEntityType($setFieldsForEntityType: FieldsPerEntityInput) {
    setFieldsForEntityType(setFieldsForEntityType: $setFieldsForEntityType) {
      columns {
        dataType
        label
        name
        pickListValues
        required
        selected
      }
      entityType
    }
  }
`
