import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Typography, { TextAlign, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './UploadProgress.css'

export type UploadProgressProps = {
  progress: number
  text?: string | ReactNode
  headline?: string
  className?: string
  dataTest?: string
}

const rootClass = 'upload-progress'

const UploadProgress: FC<UploadProgressProps> = (props: UploadProgressProps) => {
  const { progress, text, headline, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {headline && <Typography text={t(headline)} type={TextType.MODAL_HEADLINE} />}
      {text && <Typography className={`${rootClass}__text`} text={t(text)} type={TextType.BODY_TEXT} textAlign={TextAlign.CENTER} />}
      <div className={`${rootClass}__progress-bar-wrapper`}>
        <progress className={`${rootClass}__progress-bar`} value={progress} max="100" />
        <Typography text={`${progress}%`} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.REGULAR} className={`${rootClass}__percentage`} inline />
      </div>
    </div>
  )
}

export default UploadProgress
