import { gql } from '@apollo/client'

export default gql`
  query getEmailMessagesReportDataLake($onRefresh: Boolean, $timeZoneId: String, $startTime: String, $endTime: String) {
    getEmailMessagesReportDataLake(onRefresh: $onRefresh, timeZoneId: $timeZoneId, startTime: $startTime, endTime: $endTime) {
      currentDate
      currentTime
      monthlySummary {
        daySummaryMap
        days {
          bounces
          clicks
          dayOfMonth
          dayOfWeek
          name
          opens
          optOuts
          sends
        }
        name
        totalBounces
        totalClicks
        totalOpens
        totalOptOuts
        totalSends
      }
      weeklySummary {
        weekdayCounts {
          name
          totalBounces
          totalClicks
          totalOpens
          totalOptOuts
          totalSends
        }
      }
    }
  }
`
