import React, { ChangeEvent, FC, useCallback, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import InputWithStatus from '@components/InputWithStatus/InputWithStatus'
import Loader from '@components/Loader'
import Modal, { ModalBody, ModalHeader, ModalFooter } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { checkURLWithoutHTTPValidity } from '@utils/formUtils'

import './LandingPagesUrlModal.css'

interface LandingPagesUrlModalProps {
  className?: string
  dataTest?: string
  isOpen: boolean
  loading: boolean
  incorrectUrl: boolean
  setIncorrectUrl: (value: boolean) => void
  onClose: () => void
  onSubmit: (url: string) => void
}

const rootClass = 'landing-pages-url-modal'

const LandingPagesUrlModal: FC<LandingPagesUrlModalProps> = (props: LandingPagesUrlModalProps) => {
  const { dataTest = rootClass, className = '', isOpen, loading, incorrectUrl, setIncorrectUrl, onSubmit, onClose } = props

  const { t } = useTranslation()
  const [url, setUrl] = useState<string>('')
  const [isValid, setIsValid] = useState<boolean>(true)

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setUrl(e.target.value)
      setIsValid(!checkURLWithoutHTTPValidity(e.target.value))
      incorrectUrl && setIncorrectUrl(false)
    },
    [incorrectUrl, setIncorrectUrl]
  )

  const header = (
    <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__header`}>
      {t('LandingPages.UrlModal.Title')}
    </ModalHeader>
  )

  return (
    <Modal className={classNames(rootClass, className)} isOpen={isOpen} header={header}>
      <ModalBody>
        <div className={`${rootClass}__container`}>
          {loading ? (
            <Loader center className={`${rootClass}__loader`} />
          ) : (
            <>
              <Typography text={t('LandingPages.UrlModal.Info')} type={TextType.BODY_TEXT_SMALL_LIGHT} className={`${rootClass}__info`} />
              <Typography
                text={t('LandingPages.UrlModal.Input')}
                type={TextType.BODY_TEXT_SMALL}
                weight={TextWeight.MEDIUM}
                className={`${rootClass}__input-name`}
              />
              <InputWithStatus
                fullWidth
                value={url}
                onChange={onInputChange}
                onBlur={onInputChange}
                validityFunctions={[checkURLWithoutHTTPValidity]}
                hasCustomError={!isValid || incorrectUrl}
                customErrorMessage={!isValid ? t('LandingPages.UrlModal.ErrorMsg') : t('LandingPages.UrlModal.ErrorMsg_other')}
                customTooltipErrorMessages={!isValid ? t('LandingPages.UrlModal.ErrorMsg') : t('LandingPages.UrlModal.ErrorMsg_other')}
                placeholder={t('LandingPages.UrlModal.Placeholder')}
                dataTest={`${dataTest}-input`}
              />
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form} className={`${rootClass}__footer`}>
        <Button buttonType={ButtonType.TERTIARY} onClick={() => onClose()} disabled={loading} dataTest={`${dataTest}-button-tertiary`}>
          {t('Back')}
        </Button>
        <Button
          buttonType={ButtonType.PRIMARY}
          onClick={() => onSubmit(url)}
          disabled={loading || !url || !isValid || incorrectUrl}
          dataTest={`${dataTest}-button-primary`}
        >
          {t('Create')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default LandingPagesUrlModal
