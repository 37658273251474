import React, { FC, useCallback, useEffect, useState } from 'react'

import { ApolloClient } from '@apollo/client'
import { OptInStatus } from '@graphql/types/microservice/sms-management-types'
import { processOptInResults, useOptInsResults } from '@src/pages/sms/marketingListOptIns/components/SMSOptinViewAll/OptInView.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import useMicroserviceClient, { MicroserviceClients, getAuthenticatedPromise } from '@utils/hooks/useMicroserviceClient'
import { logNewRelicError } from '@utils/new-relic.utils'
import { SortByState } from '@utils/sms.utils'

import SMSOptinViewAll from './SMSOptinViewAll'

export interface State {
  totalCount: number
  pageIndex: number
  pageSize: number
  sortBy: SortByState[]
  searchTerm: string
  client?: ApolloClient<any>
  entries: OptInStatus[]
  lastContactAddedDate: string
  downloadUrl: string
}

interface Props {
  dataTest?: string
}

const defaultSortBy: SortByState = {
  id: 'optInDate',
  desc: true,
}

const defaultState: State = {
  totalCount: 0,
  pageIndex: 0,
  pageSize: 10,
  entries: [],
  sortBy: [defaultSortBy],
  searchTerm: '',
  lastContactAddedDate: '2021-04-21T13:00:00',
  downloadUrl: '',
}

const convertAccessorToSort = (id: string) => {
  switch (id) {
    case 'contactName':
      return 'CONTACTNAME'
    case 'listName':
      return 'LISTNAME'
    case 'phoneNumber':
      return 'PHONENUMBER'
    case 'optInSource':
      return 'OPTINSOURCE'
    case 'optInDate':
      return 'OPTINDATE'
    default:
      throw new Error(`Unknown accessor: ${id}`)
  }
}

export const SMSOptinViewAllContainer: FC<Props> = () => {
  const { accountId, accountTimeZoneId } = useAccountSettings()
  const { client, token, aoAccountContext } = useMicroserviceClient({ serviceName: MicroserviceClients.SMS_MANAGEMENT })
  const [state, setState] = useState<State>({ ...defaultState, client })
  const [downloadUrl, setDownloadUrl] = useState('')

  const { loading, data, error } = useOptInsResults({
    client: state.client,
    accountId,
    page: state.pageIndex,
    size: state.pageSize,
    searchTerm: state.searchTerm,
    sortColumn: convertAccessorToSort(state.sortBy[0].id),
    sortDirection: state.sortBy[0].desc ? 'DESC' : 'ASC',
  })

  useEffect(() => {
    getAuthenticatedPromise(MicroserviceClients.SMS_MANAGEMENT, `/uploads/optin/download/${accountId}`, token, aoAccountContext).then(
      (url: string) => {
        setDownloadUrl(url)
      }
    )
  }, [])

  useEffect(() => {
    const entries = processOptInResults(data)
    const totalCount = data?.getOptInStatusByAccountId.totalContacts ?? 0
    const lastContactAddedDate = data?.getOptInStatusByAccountId.lastContactAddedDate ?? state.lastContactAddedDate

    setState((prevState) => ({ ...prevState, totalCount, entries, lastContactAddedDate }))
  }, [data])

  useEffect(() => {
    if (error) {
      logNewRelicError(error)
    }
  }, [error])

  const fetchData = useCallback((pageIndex: number, pageSize: number) => {
    setState((prevState) => ({ ...prevState, pageIndex, pageSize }))
  }, [])

  const onChangeSort = useCallback((sortBy: Array<any>) => {
    const validSortBy = sortBy.length > 0 ? sortBy : [defaultSortBy]
    setState((prevState) => ({ ...prevState, sortBy: validSortBy }))
  }, [])

  return (
    <SMSOptinViewAll
      entries={state.entries}
      lastSyncDate={state.lastContactAddedDate}
      accountTimeZoneId={accountTimeZoneId ?? ''}
      loading={loading}
      totalCount={state.totalCount}
      pageIndex={state.pageIndex}
      pageSize={state.pageSize}
      fetchData={fetchData}
      sortBy={state.sortBy}
      downloadUrl={downloadUrl}
      onChangeSort={onChangeSort}
    />
  )
}

export default SMSOptinViewAllContainer
