import React, { MutableRefObject, ReactNode } from 'react'

import { ListPageAPI, ListPageCommonState, RenderCustomFiltersParams, Update } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { ListingPageStatusToast } from '@complex/ListingPage/Utils/ListingPage.constants'
import { ListingPageSession } from '@complex/ListingPage/Utils/ListingPage.utils'
import ListPickerModal from '@complex/ListPickerModalV2/ListPickerModal'
import { DEFAULT_SCHEDULE } from '@complex/ScheduleSelector/utils/ScheduleSelector.utils'
import { CollapsibleMenuItemWithHeaderData } from '@components/CollapsibleMenu/CollapsibleMenu'
import { EmptyListingProps } from '@components/EmptyListing/EmptyListing'
import Radio from '@components/Radio'
import RadioGroup from '@components/RadioGroup'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { Status } from '@components/StatusToast/StatusToast'
import { SvgNames } from '@components/Svg'
import { TOAST_TEXT_CLASSNAME } from '@components/Toast/Toast'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { rootContext } from '@const/globals'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { ListMaintenanceProgramInput, RunProgramNowMutation } from '@graphql/types/mutation-types'
import { ProgramSchedule } from '@graphql/types/query-types'
import { List } from '@interface/foldersLists/List'
import CreateProgramModal from '@src/pages/listingPages/ListMaintenancePrograms/components/CreateProgramModal/CreateProgramModal'
import {
  FilterDefinition,
  favoriteFilter,
  allProgramsFilter,
  createdByMeFilter,
  FilterTypes,
  draftProgramsFilter,
  manualProgramFilter,
  scheduledProgramFilter,
} from '@utils/filter'
import { logNewRelicError } from '@utils/new-relic.utils'
import { getItem } from '@utils/sessionStorage'
import { FetchPromise } from '@utils/types'

export const LIST_MAINTENANCE_PROGRAMS_URL = `${rootContext}/automation/listMaintenance`

export enum ListMaintenanceProgramsCustomModals {
  CREATE_PROGRAM = 'CREATE_PROGRAM',
  LIST_PICKER = 'LIST_PICKER',
}

export const DEFAULT_LIST_PROGRAM: ListMaintenanceProgramInput = {
  name: '',
  description: '',
  type: 'LIST_MAINT',
  sourceId: '',
  scheduleChanged: true,
  schedule: DEFAULT_SCHEDULE,
}

export const renderCustomModal = (
  customTableAction: ListMaintenanceProgramsCustomModals,
  { update: updateListingPage }: ListPageAPI,
  saveProgram: (programData: Partial<ListMaintenanceProgramInput>, updateListingPage: Update) => void,
  t: Function,
  newProgramRef: MutableRefObject<ListMaintenanceProgramInput>
): ReactNode => {
  const onCancel = () => {
    newProgramRef.current = DEFAULT_LIST_PROGRAM
    updateListingPage({ showCustomModal: false })
  }

  const customModal: { [key in ListMaintenanceProgramsCustomModals]: () => ReactNode } = {
    [ListMaintenanceProgramsCustomModals.CREATE_PROGRAM]: () => (
      <CreateProgramModal
        onClose={onCancel}
        onContinue={(newProgramData: ListMaintenanceProgramInput) => {
          if (newProgramRef.current.sourceId) {
            saveProgram(newProgramData, updateListingPage)
          } else {
            newProgramRef.current = { ...newProgramRef.current, ...newProgramData }
            updateListingPage({ customTableAction: ListMaintenanceProgramsCustomModals.LIST_PICKER })
          }
        }}
        defaultProgram={newProgramRef.current}
      />
    ),
    [ListMaintenanceProgramsCustomModals.LIST_PICKER]: () => (
      <ListPickerModal
        closeModal={onCancel}
        headerTitle={t('Select Source')}
        isOpen
        multiSelect={false}
        onBackArrowClick={() => updateListingPage({ customTableAction: ListMaintenanceProgramsCustomModals.CREATE_PROGRAM })}
        submitLists={([list]: List[]) => saveProgram({ sourceId: list.id }, updateListingPage)}
      />
    ),
  }
  return customModal[customTableAction]()
}

export const getCustomEmptyListingProps = (
  setFilter: (filterDefinition: FilterDefinition, customFilterSelected: boolean) => void,
  update: Update,
  filter?: FilterDefinition
): EmptyListingProps => {
  if (filter === favoriteFilter) {
    return {
      imgSrc: StaticImageNames.emptyFavorites,
      headline: "You don't have any favorites yet",
      text: 'Add hearts to programs that you want to easily find later',
      buttonText: 'View all programs',
      buttonOnClick: () => setFilter(allProgramsFilter, false),
    }
  } else if (filter === createdByMeFilter) {
    return {
      imgSrc: StaticImageNames.emptyCreatedByMe,
      headline: "You haven't created any programs yet",
      text: 'You’ll find programs here once you’ve created them',
      buttonText: 'View all programs',
      buttonOnClick: () => setFilter(allProgramsFilter, false),
    }
  } else if (filter === scheduledProgramFilter) {
    return {
      imgSrc: StaticImageNames.clock,
      headline: 'ListPage.ListMaintenancePrograms.EmptyState.Scheduled.Headline',
      text: 'ListPage.ListMaintenancePrograms.EmptyState.Scheduled.Text',
      buttonText: 'View all programs',
      buttonOnClick: () => setFilter(allProgramsFilter, false),
    }
  } else if (filter === manualProgramFilter) {
    return {
      imgSrc: StaticImageNames.noProgramHistory,
      headline: 'ListPage.ListMaintenancePrograms.EmptyState.Manual.Headline',
      text: 'ListPage.ListMaintenancePrograms.EmptyState.Manual.Text',
      buttonText: 'View all programs',
      buttonOnClick: () => setFilter(allProgramsFilter, false),
    }
  } else {
    return {
      imgSrc: StaticImageNames.emptyChart,
      headline: 'Nothing to see here!',
      text: "We couldn't find any results to show you",
      buttonText: 'Create a program',
      buttonOnClick: () => update({ showCustomModal: true, customTableAction: ListMaintenanceProgramsCustomModals.CREATE_PROGRAM }),
    }
  }
}

export const runProgramUtils = (
  onRunProgram: (externalId: string) => FetchPromise<RunProgramNowMutation>,
  { externalId }: ItemDto,
  onSuccess: (statusToast: ListingPageStatusToast) => void,
  t: Function,
  isListingPage: boolean
) => {
  return onRunProgram(externalId ?? '')
    .then(({ data }) => {
      let statusToast: ListPageCommonState['statusToast'] = { statusMessage: '', showStatusToast: true, status: Status.FAIL }
      const message = data?.runProgramNow?.message
      if (!message) {
        const statusMessage = <Typography text={t('Success! The program is running')} tagProps={{ bold: { weight: TextWeight.BOLD } }} inline />
        statusToast = {
          ...statusToast,
          status: Status.SUCCESS,
          statusMessage: isListingPage ? statusMessage : <div className={TOAST_TEXT_CLASSNAME}>{statusMessage}</div>,
        }
      } else {
        statusToast = {
          ...statusToast,
          statusMessage: t(
            message === 'Program is already running'
              ? 'The program is already running. Please try again later.'
              : "We encountered an error and the program couldn't be run. Please try again later."
          ),
        }
      }
      onSuccess(statusToast)
      return !message
    })
    .catch((error) => {
      logNewRelicError(error)
      return false
    })
}

export const renderCustomFilters = ({ menuActions, activeFilter, t }: RenderCustomFiltersParams): CollapsibleMenuItemWithHeaderData[] => [
  {
    header: t('Program Status'),
    isOpen: getItem(ListingPageSession.CUSTOM_MENU_SECTION_CLOSED) !== 'true',
    icon: SvgNames.toggles,
    dataTest: 'menu-items',
    content: (
      <RadioGroup className={`listing-page__custom-filters`} verticalLayout>
        <Radio
          label={t(FilterTypes.SCHEDULED)}
          checked={activeFilter?.name === scheduledProgramFilter.name}
          onChange={() => menuActions.clickCustomFilter(scheduledProgramFilter)}
          dataTest={`list-page-container-section-filter`}
          boldOnChecked
        />
        <Radio
          label={t(FilterTypes.MANUAL)}
          checked={activeFilter?.name === manualProgramFilter.name}
          onChange={() => menuActions.clickCustomFilter(manualProgramFilter)}
          dataTest={`list-page-container-section-filter`}
          boldOnChecked
        />
        <Radio
          label={t(FilterTypes.DRAFT)}
          checked={activeFilter?.name === draftProgramsFilter.name}
          onChange={() => menuActions.clickCustomFilter(draftProgramsFilter)}
          dataTest={`list-page-container-section-filter`}
          boldOnChecked
        />
      </RadioGroup>
    ),
  },
]

export const getActiveFilter = (filter: string) => {
  const customFilters: { [key: string]: FilterDefinition } = {
    [FilterTypes.SCHEDULED]: scheduledProgramFilter,
    [FilterTypes.MANUAL]: manualProgramFilter,
    [FilterTypes.DRAFT]: draftProgramsFilter,
  }
  return customFilters[filter]
}

export const getCustomFilterParams = (activeFilter?: FilterDefinition) => ({
  field: activeFilter?.name === draftProgramsFilter.name ? 'valid' : 'scheduled',
  query: `${activeFilter?.name === scheduledProgramFilter.name}`,
})

export const handleScheduleChange = (schedule: ProgramSchedule) => {
  return { ...schedule, hour: schedule.hour === 0 ? 12 : schedule.hour }
}
