import { createContext, ReactNode } from 'react'

import { Column } from '@components/ColumnsOrderModal/components/DraggableColumn/DraggableColumn'
import { YesNo } from '@components/ConfirmationModal'
import { PAGE_SIZE_OPTIONS } from '@components/ContactsDetails/ContactsDetails.constants'
import { FilesData } from '@components/SegmentDetails/SegmentDetailsContainer'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { StatusToastType } from '@interface/StatusToast'
import { Contact } from '@utils/contact/contact.constants'
import { FileUplaodModalType } from '@utils/formUtils'

interface ConfirmationModalData {
  body: string | ReactNode
  title: string
  button: string
  onAnswer: (answer: YesNo) => void
}

export interface ContactsDetailsContainerState {
  confirmationModalData?: ConfirmationModalData
  loading: boolean
  pageSize: number
  search: string
  pageIndex: number
  columns: Column[]
  showColumnsOrderModal: boolean
  contactsToDelete: number[] | string[]
  hasNextPage: boolean
  statusToast: StatusToastType
  mappedIdsToUuids: Record<string, string>
}

export const ContactsDetailsContainerInitialState: ContactsDetailsContainerState = {
  loading: true,
  pageSize: PAGE_SIZE_OPTIONS[1],
  search: '',
  pageIndex: 0,
  columns: [],
  showColumnsOrderModal: false,
  contactsToDelete: [],
  hasNextPage: false,
  statusToast: { statusMessage: '', showStatus: false, successStatus: true, title: '' },
  mappedIdsToUuids: {},
}

type Update = (fields: Partial<ContactsDetailsContainerState>) => void
type LoadContacts = () => void
type OnRemoveContactFromSegment = (recIds?: string[]) => void
type OnRemoveFromBounceClick = (contacts: Contact[]) => void
type OnColumnsSave = (columns: Column[], checked: boolean) => void
type OnDeleteContacts = () => void
type onToggleDownloadModal = (type: FileUplaodModalType, contact: string[], headerText?: string) => void
type onDownloadOrPreviewFile = (fileIds: number[], type?: FileUplaodModalType, initialAttachmentsData?: FilesData[]) => void

export interface ContactsDetailsAPI {
  values: ContactsDetailsContainerState & { unifiedListFieldMappings: UnifiedListFieldMapping[] }
  update: Update
  loadContacts: LoadContacts
  onRemoveContactFromSegment: OnRemoveContactFromSegment
  onRemoveFromBounceClick: OnRemoveFromBounceClick
  onColumnsSave: OnColumnsSave
  onDeleteContacts: OnDeleteContacts
  toggleDownloadModal?: onToggleDownloadModal
  handleDownloadOrPreviewFile?: onDownloadOrPreviewFile
}

export const ContactsDetailsContextMock: ContactsDetailsAPI = {
  values: {
    ...ContactsDetailsContainerInitialState,
    unifiedListFieldMappings: [],
    loading: false,
  },
  loadContacts: () => undefined,
  update: () => undefined,
  onDeleteContacts: () => undefined,
  onRemoveFromBounceClick: () => undefined,
  onColumnsSave: () => undefined,
  onRemoveContactFromSegment: () => undefined,
}

export const ContactsDetailContext = createContext<ContactsDetailsAPI>({ values: ContactsDetailsContainerInitialState } as any)
