import React from 'react'

// eslint-disable-next-line no-restricted-imports
import { format } from 'date-fns'
import { TFunction } from 'i18next'

import { ImportLogData, ImportLogDataRow, importLogRootClass } from '@complex/ImportLog/ImportLog'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { RowAction } from '@components/TableV2/tableV2TS/interfaces'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography from '@components/Typography/Typography'
import { ImportSyncJobDto } from '@graphql/types/microservice/entity-upload-types'
import { ImportDetailsStatus } from '@graphql/types/microservice/list-types'
import { CellContext } from '@tanstack/react-table'
import { formatDateWithAbbreviations } from '@utils/date'

export enum ImportContactsSources {
  FTP = 'FTP',
  // For local .csv files or existing lists
  LOCAL = 'LOCAL',
}

const DAYS_TO_EXPIRE = 13

export const importLogColumns: ColumnDefWithAdditionalProps<ImportLogDataRow>[] = [
  {
    header: 'Import Name',
    accessorKey: 'name',
    textAlign: 'left',
    minSize: 350,
    sortingFn: 'caseInsensitive',
    enableCustomCellValue: true,
    cell: ({ cell }: CellContext<ImportLogDataRow, unknown>) => (
      <TextWithTooltipOnEllip typographyProps={{ text: cell?.getValue<string>?.(), className: `${importLogRootClass}__filename` }} />
    ),
  },
  {
    header: 'Result',
    accessorKey: 'result',
    cell: (cell) => {
      const { result, hasFailed } = cell.row.original
      return (
        <div className={`${importLogRootClass}__status`}>
          <Svg name={hasFailed ? SvgNames.errorSolid : SvgNames.checkSuccess} type={SvgType.ICON} />
          <Typography text={result} />
        </div>
      )
    },
    textAlign: 'left',
    minSize: 150,
    sortingFn: 'caseInsensitive',
  },
  {
    header: 'Source',
    accessorKey: 'source',
    textAlign: 'left',
    minSize: 150,
    sortingFn: 'caseInsensitive',
  },
  {
    header: 'Type',
    accessorKey: 'scheduleType',
    textAlign: 'left',
    minSize: 150,
    sortingFn: 'caseInsensitive',
  },
  {
    header: 'Record added',
    accessorKey: 'recordsAdded',
    textAlign: 'left',
    minSize: 150,
  },
  {
    header: 'Record modified',
    accessorKey: 'recordsModified',
    textAlign: 'left',
    maxSize: 150,
  },
  {
    header: 'Record failed',
    accessorKey: 'recordsFailed',
    textAlign: 'left',
    maxSize: 150,
  },
  {
    header: 'Import Date',
    accessorKey: 'date',
    textAlign: 'left',
    minSize: 200,
    sortingFn: (row) => (row.original.date ? -1 : 1),
  },
  {
    header: 'Imported By',
    accessorKey: 'importedBy',
    textAlign: 'left',
    minSize: 200,
    sortingFn: 'caseInsensitive',
    cell: ({ cell }: CellContext<ImportLogDataRow, unknown>) => <TextWithTooltipOnEllip typographyProps={{ text: cell?.getValue<string>?.() }} />,
  },
]

export const importLogRowActions = (t: TFunction, onImportSettingsClick: (importLog: ImportLogDataRow) => void): RowAction<ImportLogDataRow>[] => [
  {
    disabled: (row) => !row?.original.reportLink,
    icon: SvgNames.download,
    label: (row) => row?.original.reportLinkTooltip ?? '',
    onClick: (row) => window.open(row.original.reportLink, '_blank'),
    tooltipMessage: (row) => row?.original.reportLinkTooltip ?? '',
  },
  {
    hidden: (row) => Array.isArray(row) || row?.original.source !== 'FTP import',
    icon: SvgNames.gear,
    disabled: (row) => !row?.original.importSyncJob || !row?.original.importSyncJob.enabled,
    tooltipMessage: t('No import sync job found'),
    onClick: ({ original }) => onImportSettingsClick(original),
    label: t('Import settings'),
  },
]

export const getImportLogData = (
  importDetailsStatus: ImportDetailsStatus[],
  t: TFunction,
  importSyncJobs: ImportSyncJobDto[]
): ImportLogDataRow[] => {
  return importDetailsStatus.map<ImportLogData>((importDetails: ImportDetailsStatus) => {
    const metadata = JSON.parse(importDetails.typeMetaData || '{}')

    const failed = !!metadata?.purgatoryFileId
    const date = new Date(importDetails.createdTime)
    date.setDate(date.getDate() + DAYS_TO_EXPIRE)
    const expired = date < new Date()

    const tooltip = expired
      ? t('This file is no longer available')
      : failed
      ? t(`Click to download rejected records. This link will expire on {{date}}`, { date: format(date, 'dd/MM/yyyy') })
      : t('Download file')

    const fileId = metadata.purgatoryFileId ?? importDetails.sourceId ?? ''
    const importSyncJob = importSyncJobs.find(({ importSyncJobId }) => importSyncJobId === metadata.importSyncJobId)

    return {
      name: metadata?.name ?? importDetails.sourceId,
      source: importDetails.source === 'CSV' ? t('CSV Upload') : importDetails.source === 'FTP' ? t('FTP import') : t('List import'),
      date: formatDateWithAbbreviations(importDetails.createdTime, true),
      hasFailed: failed,
      result: failed ? t('Failed') : t('Completed'),
      importSyncJob,
      importedBy: metadata?.userName ?? importDetails.userId,
      recordsAdded: importDetails.totalAdded,
      recordsModified: importDetails.totalUpdated,
      recordsFailed: importDetails.totalRejected,
      reportLink: !expired ? `${location.pathname}/download?isDecoded=true&fileId=${fileId}` : undefined,
      reportLinkTooltip: tooltip,
      scheduleType: importDetails.source === 'FTP' && importSyncJob?.cron ? t('Scheduled') : t('Manual'),
    }
  })
}
