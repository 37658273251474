import React from 'react'

import { TableV2 } from '@components/TableV2/TableV2'
import { TableV2Props } from '@components/TableV2/tableV2TS/interfaces'

import { checkUrlForTableV2 } from '../../utils/tableV2Utils'

interface MultipleTablesProps<T> {
  tableV2Props?: TableV2Props<T>
  enableTableV2?: boolean
  oldTable: JSX.Element
}

export const MultipleTables = <T extends {}>({ tableV2Props, enableTableV2, oldTable }: MultipleTablesProps<T>) => (
  <>{tableV2Props && (checkUrlForTableV2() || enableTableV2) ? <TableV2 {...tableV2Props} /> : oldTable}</>
)
