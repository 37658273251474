import React, { FC, useCallback, useState } from 'react'

import { PersonalizationFieldErrors, SpamTestResult } from '@graphql/types/query-types'
import CheckStatusCard from '@src/pages/EmailComposer/components/common/CheckStatusCard/CheckStatusCard'
import { CheckCardStatus, CheckCardType } from '@src/pages/EmailComposer/components/common/CheckStatusCard/CheckStatusCard.utils'
import { CheckStatusModal } from '@src/pages/EmailComposer/components/common/CheckStatusCard/CheckStatusModal/CheckStatusModal'

import './CheckStatusCard.css'

export interface CheckStatusCardContainerProps {
  checkApi?: () => Promise<void>
  status?: CheckCardStatus
  type?: CheckCardType
  spamTests?: SpamTestResult[]
  personalizationErrors?: PersonalizationFieldErrors[]
  isInPreview?: boolean
  disableCheck?: boolean
  tooltipContentKey?: string
  issuesCount?: number
  lastChecked?: number
  className?: string
  dataTest?: string
}

export const CheckStatusCardContainer: FC<CheckStatusCardContainerProps> = ({ checkApi, status, dataTest, issuesCount, ...rest }) => {
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const handleViewResults = useCallback(() => setShowModal(true), [])
  const handleModalDone = useCallback(() => setShowModal(false), [])

  const handleCheck = useCallback(() => {
    if (checkApi) {
      setLoading(true)
      checkApi().finally(() => setLoading(false))
    }
  }, [checkApi])

  const _status = loading ? CheckCardStatus.LOADING : status

  return (
    <>
      <CheckStatusCard
        onCheck={handleCheck}
        onViewResults={handleViewResults}
        status={_status}
        dataTest={dataTest}
        issuesCount={issuesCount}
        {...rest}
      />
      {showModal && (
        <CheckStatusModal
          onCheck={handleCheck}
          onDone={handleModalDone}
          status={_status}
          dataTest={`${dataTest}-modal`}
          issuesCount={issuesCount}
          {...rest}
        />
      )}
    </>
  )
}
