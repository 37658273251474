import React, { FC, useCallback, useState } from 'react'

import classNames from 'classnames'

import Caution from '@components/Caution/Caution'
import Container from '@components/Container'
import Loader from '@components/Loader'
import { TextWeight } from '@components/Typography/Typography'
import ImageURLChecker from '@components/UploadImage/components/ImageURLChecker'
import { useTranslation } from '@const/globals'
import BrandingSectionHeader from '@src/pages/Content/Branding/components/BrandingSectionHeader/BrandingSectionHeader'
import FavIconForm from '@src/pages/Content/Branding/tabs/WebIdentity/FavIcon/components/FavIconForm'
import FavIconView from '@src/pages/Content/Branding/tabs/WebIdentity/FavIcon/components/FavIconTabBar/FavIconView'
import { FAV_ICON_MORE_INFO_LINK } from '@src/pages/Content/Branding/tabs/WebIdentity/webIdentity.constants'

import './FavIcon.css'

interface Props {
  loading: boolean
  url: string
  onSaveUrl: (url: string) => void
  onRemoveImage: () => void
  isDefaultURL: boolean
  className?: string
  dataTest?: string
}

const rootClass = 'fav-icon'

const FavIcon: FC<Props> = (props) => {
  const { loading, url, onSaveUrl, onRemoveImage, isDefaultURL, className, dataTest = rootClass } = props
  const { t } = useTranslation()
  const [isEditing, setEditing] = useState<boolean>(false)
  const [isValidURL, setIsValidURL] = useState<boolean | undefined>(undefined)

  const handleSave = useCallback((url: string) => {
    setEditing(false)
    onSaveUrl(url)
  }, [])

  const handleCancel = useCallback(() => setEditing(false), [])

  return (
    <Container className={classNames(rootClass, className)} dataTest={dataTest}>
      <ImageURLChecker url={url} onStatusChange={setIsValidURL} />
      <BrandingSectionHeader
        titleKey="Favicon"
        descriptionKey="FavIcon.Description"
        onEdit={setEditing}
        isEditing={isEditing}
        moreInfoLink={FAV_ICON_MORE_INFO_LINK}
        className={`${rootClass}__header`}
        dataTest={`${dataTest}-header`}
      />
      {loading || isValidURL === undefined ? (
        <Loader />
      ) : (
        <>
          {isDefaultURL && (
            <Caution
              textLines={[{ span: t('FavIcon.No.Custom.Title'), weight: TextWeight.BOLD }, { span: t('FavIcon.No.Custom.Description') }]}
              className={`${rootClass}__caution`}
              dataTest={dataTest}
            />
          )}
          {isEditing ? (
            <FavIconForm
              url={url}
              onSave={handleSave}
              onRemoveImage={onRemoveImage}
              isDefaultURL={isDefaultURL}
              isValidURL={isValidURL}
              onCancel={handleCancel}
            />
          ) : (
            <FavIconView url={url} isDefaultURL={isDefaultURL} isValidURL={isValidURL} dataTest={`${dataTest}__view`} />
          )}
        </>
      )}
    </Container>
  )
}

export default FavIcon
