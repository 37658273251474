import React, { FC, Key, useCallback, useEffect, useState } from 'react'

import classNames from 'classnames'

import { useApolloClient, useMutation } from '@apollo/client'
import { useFolderRequests } from '@complex/ListingPage/GraphQL/Folders.graphQL'
import { useTagRequests } from '@complex/ListingPage/GraphQL/Tags.graphQL'
import DuplicateFolderModal from '@components/DuplicateModal/DuplicateFolderModal'
import Modal, { ModalBody } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import ModalFooterV2 from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import ModalHeaderV2 from '@components/Modal/components/ModalHeaderV2/ModalHeaderV2'
import Pill, { PillSize, PillType } from '@components/Pill/Pill'
import RadioCard from '@components/RadioCard/RadioCard'
import RadioCardGroup from '@components/RadioCardGroup/RadioCardGroup'
import { useSegmentDetailsQueries } from '@components/SegmentDetails/SegmentDetails.graphQL'
import { SvgNames } from '@components/Svg'
import Typography, { ModalBodyStyle, TextWeight } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import copyLandingPageOrTemplate from '@graphql/mutations/copyLandingPageOrTemplate'
import createBeeLandingPage from '@graphql/mutations/createBeeLandingPage'
import {
  CopyLandingPageOrTemplateMutation,
  CopyLandingPageOrTemplateMutationVariables,
  CreateBeeLandingPageMutation,
  CreateBeeLandingPageMutationVariables,
} from '@graphql/types/mutation-types'
import { ItemDto, LabelDto } from '@graphql/types/query-types'
import { useLandingPageComposerRequests } from '@src/pages/LandingPageComposer/GraphQL/LandingPageComposerRequests.graphQL'
import { useAccountSettings } from '@utils/account/account.utils'
import { filterNotEmptyArray } from '@utils/array'
import { ItemType } from '@utils/categorization'
import { CreateLandingPageMethod, CreateLandingPageModalProps } from '@utils/composer/landingPage/types'
import { sortFoldersByName } from '@utils/folderUtils'
import { Folder } from '@utils/interface/Folder'
import { useFetchLandingPageLazy } from '@utils/landingPages'

import { BetaBannerCard } from '../BetaBannerCard/BetaBannerCard'

import './CreateLandingPageModal.css'

const rootClass = 'create-landing-page-modal'

const CreateLandingPageModal: FC<CreateLandingPageModalProps> = (props: CreateLandingPageModalProps) => {
  const {
    dataTest = rootClass,
    className = '',
    landingPageId,
    startId,
    duplicateModalProps,
    onCreate,
    onCancel,
    update,
    isOpen,
    showAdditionalOptions,
    disableTemplateOption,
    isSaveFailed,
    isLandingListingPage,
  } = props
  const {
    handleDuplicateCreate,
    onInputChange,
    headerTitleKey,
    itemNameLabel,
    namePlaceholder,
    onDuplicateCancel,
    onSubmitText,
    itemName,
    defaultName,
    isLandingPageComposer,
  } = duplicateModalProps ?? {}

  const [itemToCloneTags, setItemToCloneTags] = useState<ItemDto[]>()
  const [activeFolderId, setActiveFolderId] = useState()
  const [tags, setTags] = useState<LabelDto[]>([])
  const [folders, setFolders] = useState<Folder[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [method, setMethod] = useState<Key>(CreateLandingPageMethod.NEW)
  const [createBetaModal, setCreateBetaModal] = useState<boolean>(false)
  const [isBetaToggleOn, setIsBetaToggleOn] = useState<boolean>(window.localStorage.getItem('isBetaToggleOn') === 'true')
  const [includeUnpublishedChanges, setIncludeUnpublishedChanges] = useState(true)
  const [disableSave, setDisableSave] = useState(false)

  const { t } = useTranslation()
  const { newLPComposerCreateBlank } = useAccountSettings()

  const client = useApolloClient()

  const { getItemDetailsRequest } = useSegmentDetailsQueries()
  const { getAllFoldersRequest } = useFolderRequests()
  const { createTagRequest, getAllTagsRequest } = useTagRequests()

  const { showToggle, loading: isLPLoading } = useFetchLandingPageLazy(
    landingPageId,
    newLPComposerCreateBlank && !!(isLandingListingPage || isLandingPageComposer)
  )
  const { getLastPublishedMessage } = useLandingPageComposerRequests()

  const [setMutation] = useMutation<CreateBeeLandingPageMutation, CreateBeeLandingPageMutationVariables>(createBeeLandingPage, {
    client,
    fetchPolicy: 'no-cache',
  })

  const [setCopyLandingPageOrTemplateMutation] = useMutation<CopyLandingPageOrTemplateMutation, CopyLandingPageOrTemplateMutationVariables>(
    copyLandingPageOrTemplate,
    {
      client,
      fetchPolicy: 'no-cache',
    }
  )

  const getItemDetails = useCallback(() => {
    getItemDetailsRequest(ItemType.LANDING_PAGE, undefined, landingPageId).then(({ data }) => {
      const { folderId, labels } = data?.getItem ?? {}
      setItemToCloneTags(labels as ItemDto[])
      setActiveFolderId(folderId)
    })
  }, [getItemDetailsRequest, landingPageId])

  const getTags = useCallback(() => {
    getAllTagsRequest([ItemType.LANDING_PAGE], []).then(({ data }) => {
      if (data?.getLabels) {
        setTags(data.getLabels.filter(filterNotEmptyArray))
      }
    })
  }, [getAllTagsRequest])

  const getFolders = useCallback(() => {
    setLoading(true)
    getAllFoldersRequest(ItemType.LANDING_PAGE, [], true)
      .then(({ data }) => {
        if (data?.getFolders) {
          setFolders(sortFoldersByName(data.getFolders))
        }
      })
      .finally(() => setLoading(false))
  }, [getAllFoldersRequest])

  const handleCreateTag = useCallback(
    (tag: LabelDto) => {
      createTagRequest(ItemType.LANDING_PAGE, tag).then(({ errors }) => {
        if (!errors) {
          getTags()
        }
      })
    },
    [createTagRequest]
  )

  const createLandingPage = (name: string, tags: LabelDto[], folderIdToClone: number | undefined) => {
    return setMutation({
      variables: {
        createBeeLandingPageInput: {
          folderId: folderIdToClone,
          tags: tags,
          title: name,
        },
      },
    }).then((data) => {
      window.open(`${rootContext}/content/landingPage/${data.data?.createBeeLandingPage.id}/settings`, '_blank')
      onCancel?.()
    })
  }

  const createLandingPageFromTemplate = (name: string, tags: LabelDto[], folderIdToClone: number | undefined) => {
    return setCopyLandingPageOrTemplateMutation({
      variables: {
        folderId: folderIdToClone,
        id: startId,
        title: name,
        toTemplate: false,
        tags: tags,
      },
    }).then((data) => {
      window.open(`${rootContext}/content/landingPage/${data.data?.copyLandingPageOrTemplate}/settings?firstEdit=true`, '_blank')
      onCancel?.()
    })
  }

  const handleToggle = (isOn: boolean) => {
    setIsBetaToggleOn(isOn)
    window.localStorage.setItem('isBetaToggleOn', `${isOn}`)
  }

  useEffect(() => {
    if (createBetaModal || !!duplicateModalProps) {
      getTags()
      getFolders()

      if (duplicateModalProps) {
        getItemDetails()
      }
    }
  }, [createBetaModal, duplicateModalProps])

  useEffect(() => {
    if (isSaveFailed) {
      setLoading(false)
    }
  }, [isSaveFailed])

  const handleUnpublishedChangesToggle = useCallback(
    async (include: boolean) => {
      if (!include && landingPageId) {
        setDisableSave(true)
        try {
          const lastPublishedMessageData = await getLastPublishedMessage(landingPageId)
          update?.({ landingPage: { lastPublishedMessageData: lastPublishedMessageData } })
        } finally {
          setDisableSave(false)
        }
      } else {
        update?.({ landingPage: { lastPublishedMessageData: undefined } })
      }
      setIncludeUnpublishedChanges(include)
    },
    [landingPageId, update]
  )

  const header = <ModalHeaderV2 headerType={ModalHeaderType.Form} className={`${rootClass}__header`} headerText={t('CreateLandingPageModal.Title')} />

  const createButtonText = CreateLandingPageMethod.NEW === method && !isBetaToggleOn ? 'Create' : 'Next'

  const BETA_LINK = 'https://connect.act-on.com/hc/en-us/articles/28614216871831'

  return createBetaModal || !!duplicateModalProps ? (
    <DuplicateFolderModal
      isBee
      hideIcon
      tags={tags}
      folders={folders}
      activeFolderId={activeFolderId}
      itemToCloneTags={itemToCloneTags}
      isSaveFailed={isSaveFailed}
      loading={loading || isLPLoading}
      itemNameRequired
      dropDownSvgIcon={SvgNames.folderNestedGray}
      headerTitleKey={headerTitleKey || t('CreateLandingPageModal.BetaCreate.Title')}
      itemNameLabel={itemNameLabel || t('CreateLandingPageModal.BetaCreate.Label')}
      namePlaceholder={namePlaceholder || t('CreateLandingPageModal.BetaCreate.Label')}
      itemName={itemName ?? ''}
      defaultName={defaultName ?? ''}
      itemNameDescription={t('CreateLandingPageModal.BetaCreate.Description')}
      onSubmitText={onSubmitText || t('Create')}
      onTagCreate={handleCreateTag}
      onCancel={onDuplicateCancel || (() => setCreateBetaModal(false))}
      onClone={startId ? createLandingPageFromTemplate : handleDuplicateCreate || createLandingPage}
      isLandingListingPage={isLandingListingPage}
      isLandingPageComposer={isLandingPageComposer}
      onInputChange={onInputChange}
      showLPToggleToIncludeChanges={showToggle}
      includeUnpublishedChanges={includeUnpublishedChanges}
      onIncludeUnpublishedChangesToggle={handleUnpublishedChangesToggle}
      disableSaveButton={disableSave}
      preventAutoClosingModal={newLPComposerCreateBlank && !!duplicateModalProps}
      newLPComposerCreateBlank={newLPComposerCreateBlank}
    />
  ) : (
    <Modal paddingV2 className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
      <ModalBody className={`${rootClass}__body`}>
        <Typography text={t('CreateLandingPageModal.Info')} {...ModalBodyStyle} className={`${rootClass}__info`} />
        {newLPComposerCreateBlank && <BetaBannerCard onToggle={handleToggle} isToggleOn={isBetaToggleOn} betaLink={BETA_LINK} />}
        <RadioCardGroup selectedOption={method} showAdditionalOptions={showAdditionalOptions} onSelect={(key: Key) => setMethod(key)}>
          <RadioCard
            title={
              <>
                <Typography text={t('CreateLandingPageModal.Options.New')} weight={TextWeight.MEDIUM} inline />
                {newLPComposerCreateBlank && isBetaToggleOn && <Pill text={t('BETA')} type={PillType.SECONDARY} size={PillSize.REGULAR} />}
              </>
            }
            description={t('CreateLandingPageModal.Options.New.Description')}
            svgName={SvgNames.createLandingPage}
            hoverSvgName={SvgNames.createLandingPageActive}
            key={CreateLandingPageMethod.NEW}
            className={`${rootClass}__new-option`}
          />
          <RadioCard
            title={t('CreateLandingPageModal.Options.Template')}
            description={t('CreateLandingPageModal.Options.Template.Description')}
            tooltipText={disableTemplateOption && t('CreateLandingPageModal.Options.Template.Tooltip')}
            disabled={disableTemplateOption}
            svgName={SvgNames.createLandingPageFromTemplate}
            hoverSvgName={SvgNames.createLandingPageFromTemplateActive}
            disabledSvgName={SvgNames.createLandingPageFromTemplateDisabled}
            key={CreateLandingPageMethod.TEMPLATE}
            className={`${rootClass}__template-option`}
          />
          <RadioCard
            title={t('CreateLandingPageModal.Options.Code')}
            description={t('CreateLandingPageModal.Options.Code.Description')}
            key={CreateLandingPageMethod.CODE}
            className={`${rootClass}__code-option`}
            isAdditionalOption
          />
          <RadioCard
            title={t('CreateLandingPageModal.Options.Url')}
            description={t('CreateLandingPageModal.Options.Url.Description')}
            key={CreateLandingPageMethod.URL}
            className={`${rootClass}__url-option`}
            isAdditionalOption
          />
        </RadioCardGroup>
      </ModalBody>
      <ModalFooterV2
        footerType={ModalFooterType.Form}
        className={`${rootClass}__footer`}
        onClose={() => onCancel?.()}
        buttons={{
          cancelButtonLabel: t('Cancel'),
          actionButtonLabel: t(createButtonText),
          actionButtonOnClick: () =>
            newLPComposerCreateBlank && isBetaToggleOn && method === CreateLandingPageMethod.NEW
              ? setCreateBetaModal(true)
              : onCreate?.(method as CreateLandingPageMethod),
          actionButtonDataProperties: {
            'data-product': !isBetaToggleOn && method === CreateLandingPageMethod.NEW ? 'create_from_scratch_legacy' : 'create_from_scratch_new',
          },
        }}
      />
    </Modal>
  )
}

export default CreateLandingPageModal
