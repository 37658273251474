import { useCallback, useEffect, useState } from 'react'

import equal from 'fast-deep-equal/es6'

import { useApolloClient, useQuery } from '@apollo/client'
import getLandingPageQuery from '@graphql/queries/getLandingPage'
import { EmbeddedForm, GetLandingPageQuery, GetLandingPageQueryVariables } from '@graphql/types/query-types'
import { useAccountSettings } from '@utils/account/account.utils'
import { filterNotEmptyArray } from '@utils/array'
import { RequiredEmbeddedForm, LandingPageType } from '@utils/landingPages'
import { logNewRelicError } from '@utils/new-relic.utils'

const filterEmbeddedForms = (forms?: (EmbeddedForm | undefined)[]): RequiredEmbeddedForm[] => {
  if (!forms) {
    return []
  }
  return (forms.filter((form) => filterNotEmptyArray(form) && form.id && form.name) as RequiredEmbeddedForm[]).sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  )
}

const useLandingPage = (
  id: string
): {
  loadingLP: boolean
  updatingLP: boolean
  landingPage: LandingPageType | undefined
  updateLandingPage: (landingPage: Partial<LandingPageType>) => void
  refetchLP: () => void
} => {
  const client = useApolloClient()
  const { userAllowedToCreateContent } = useAccountSettings()
  const [landingPage, setLandingPage] = useState<LandingPageType>()
  const [updatingLP, setUpdatingLP] = useState<boolean>(false)

  const { loading, error, data, refetch } = useQuery<GetLandingPageQuery, GetLandingPageQueryVariables>(getLandingPageQuery, {
    client,
    fetchPolicy: 'network-only',
    variables: { id },
  })

  useEffect(() => {
    if (error) {
      logNewRelicError(error)
    }
    if (!loading && data?.getLandingPage) {
      setLandingPage((cur) => {
        const embeddedForms = filterEmbeddedForms(data.getLandingPage?.embeddedForms)
        if (!cur) {
          return { ...data.getLandingPage, embeddedForms, canEdit: userAllowedToCreateContent }
        } else if (!equal(cur, { ...data.getLandingPage, canEdit: userAllowedToCreateContent })) {
          setUpdatingLP(true)
          setTimeout(() => {
            setUpdatingLP(false)
          }, 1000)
          return { ...data.getLandingPage, embeddedForms, canEdit: userAllowedToCreateContent }
        }
        return cur
      })
    }
  }, [error, loading, data, userAllowedToCreateContent])

  const updateLandingPage = useCallback(
    (landingPage: Partial<LandingPageType>) => setLandingPage((cur) => ({ ...cur, ...landingPage } as LandingPageType)),
    []
  )

  return {
    loadingLP: loading,
    updatingLP,
    landingPage,
    updateLandingPage,
    refetchLP: refetch,
  }
}

export default useLandingPage
