import { ReactNode } from 'react'

import { ListPageTableActionCustomProps, SetError, Update } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { Status } from '@components/StatusToast/StatusToast'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { AccountSharePermissions } from '@src/pages/listingPages/AccountManagement/AccountManagementListingPageContainer.types'
import {
  CreateAccountRequestParams,
  SharePermissionsInput,
  AccountManager,
  SalesUser,
} from '@src/pages/listingPages/AccountManagement/GraphQL/AccountManagementListingPageRequests.graphQL'

export interface Account extends ItemDto {
  name: string
  activeContacts: number
  usedContacts: number
  usedSeats: number
  totalSeats: number
}

export interface AutomatedProgramsListingPageContainerProps {
  className?: string
  dataTest?: string
  hasUpgradablePrograms?: boolean
}

export enum AutomatedProgramsCustomTableActions {
  SHOW_DASHBOARD = 'showDashboard',
  EDIT = 'edit',
  START = 'start',
  PAUSE = 'pause',
  CLASSIC_EDITOR = 'classicEditor',
  CREATE_TEMPLATE = 'createTemplate',
}

export enum StartProgramResult {
  FAILED = 'failed',
  ADDED = 'added',
  EXITED = 'exited',
}

export enum AutomatedProgramsCustomModals {
  START = 'start',
  START_ERROR = 'startError',
  START_ADD = 'startAdd',
  START_EXIT = 'startExit',
  PAUSE = 'pause',
  CREATE_TEMPLATE = 'createTemplate',
  CLEAR_PROGRAM_HISTORY = 'clearProgramHistory',
}

export const headerActionCustomProps: ListPageTableActionCustomProps[] = [
  { name: 'ADD_TO_FAVORITES', position: 0, hasTopSection: false, isInDropdown: false },
  { name: 'MANAGE_TAGS', position: 1, hasTopSection: false },
  { name: 'MOVE_TO_FOLDER', position: 2, hasTopSection: false },
  { name: 'REMOVE_FROM_FOLDER', position: 3, hasTopSection: false, isInDropdown: false },
  { name: 'DELETE', position: 4, hasTopSection: false, isInDropdown: true },
]

export const rowActionCustomProps: ListPageTableActionCustomProps[] = [
  { name: AutomatedProgramsCustomTableActions.SHOW_DASHBOARD, position: 0, hasTopSection: false },
  { name: AutomatedProgramsCustomTableActions.EDIT, position: 1, hasTopSection: false },
  { name: AutomatedProgramsCustomTableActions.START, position: 2, hasTopSection: false },
  { name: AutomatedProgramsCustomTableActions.PAUSE, position: 3, hasTopSection: false },
  { name: 'ADD_TO_FAVORITES', position: 4, hasTopSection: false },
  { name: 'DUPLICATE', position: 6, hasTopSection: true, isInDropdown: true },
  { name: AutomatedProgramsCustomTableActions.CREATE_TEMPLATE, position: 7, hasTopSection: false, isInDropdown: true },
  { name: AutomatedProgramsCustomTableActions.CLASSIC_EDITOR, position: 5, hasTopSection: true, isInDropdown: true },
  { name: 'MOVE_TO_FOLDER', position: 8, hasTopSection: true, isInDropdown: true },
  { name: 'REMOVE_FROM_FOLDER', position: 9, hasTopSection: false, isInDropdown: true },
  { name: 'DELETE', position: 10, hasTopSection: true, isInDropdown: true },
]

export const AP_HELP_START_PAUSE_LINK = 'https://connect.act-on.com/hc/en-us/articles/4411370088343-Start-or-Pause-an-Automated-Program'

export interface CustomActionHelpers {
  createAccount: (
    createAccountParams: CreateAccountRequestParams,
    update: Update,
    setStatusToast: (message: string | ReactNode, status: Status, folderName?: string) => void,
    setError: SetError
  ) => void
  loginToChildAccount: (accountId: string, update: Update) => void
  deleteAccount: (
    accountId: string,
    accountNamae: string,
    update: Update,
    setStatusToast: (message: string | ReactNode, status: Status, folderName?: string) => void,
    setError: SetError
  ) => void
  updateAssignedContacts: (
    accountId: string,
    accountName: string,
    contacts: number,
    update: Update,
    setStatusToast: (message: string | ReactNode, status: Status, folderName?: string) => void,
    setError: SetError
  ) => void
  updateSharePermissions: (
    sharePermissions: SharePermissionsInput[],
    accounts: AccountSharePermissions[],
    update: Update,
    setStatusToast: (message: string | ReactNode, status: Status, folderName?: string) => void,
    setError: SetError
  ) => void
  assignManagers: (
    accountId: string,
    accountName: string,
    managers: AccountManager[],
    update: Update,
    setStatusToast: (message: string | ReactNode, status: Status, folderName?: string) => void,
    setError: SetError
  ) => void
  assignSalesUsers: (
    accountId: string,
    accountName: string,
    salesUsers: SalesUser[],
    update: Update,
    setStatusToast: (message: string | ReactNode, status: Status, folderName?: string) => void,
    setError: SetError
  ) => void
}

export interface AccountDataTotals {
  activeContacts: {
    children: number
    total: number
  }
  usedContacts: {
    current: number
    total: number
  }
  childAccounts: {
    current: number
    total: number
  }
  parentAccountUsedContacts: number
}

export const defaultAccountData: AccountDataTotals = {
  activeContacts: {
    children: 0,
    total: 0,
  },
  usedContacts: {
    current: 0,
    total: 0,
  },
  childAccounts: {
    current: 0,
    total: 0,
  },
  parentAccountUsedContacts: 0,
}

export enum AutomatedProgramsSession {
  UPGRADE_PROGRAMS_BANNER = 'automatedPrograms:upgradeProgramsBanner',
}
