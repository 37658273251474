import React, { FC, useEffect, useMemo, useState } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'

import { FieldDefinition, Program, StringKeyValue } from '@graphql/types/query-types'
import { yupResolver } from '@hookform/resolvers/yup'
import EditCRMStepV2 from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/EditCRMStepV2'
import {
  editCRMStepV2InitialState,
  STEP_DETAILS_KEY,
  getSchema,
  DetailsDataType,
  opportunitiesEntities,
  primaryEntitiesWithHiddenFields,
} from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.constants'
import {
  EditCRMStepV2Context,
  EditCRMStepV2State,
} from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.context'
import { useEditCRMStepV2Requests } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.graphQL'
import { EditCRMStepV2EntityStep } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.interfaces'
import CreateInCRMDetailsV2 from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStepV2/components/CreateInCRMDetailsV2/CreateInCRMDetailsV2'
import useCRM, { CRMConnectorType } from '@utils/hooks/useCRM'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'
import { ProgramCreateInCRMStepExt } from '@utils/program/program.constants'

interface EditCRMStepV2ContainerPropsRunning {
  onSubmit?: (step: ProgramCreateInCRMStepExt) => void
  closeModal?: VoidFunction
  isRunning?: true
}

interface EditCRMStepV2ContainerPropsNotRunning {
  onSubmit: (step: ProgramCreateInCRMStepExt) => void
  closeModal: VoidFunction
  isRunning?: false
}

type EditCRMStepV2ContainerProps = (EditCRMStepV2ContainerPropsRunning | EditCRMStepV2ContainerPropsNotRunning) & {
  className?: string
  dataTest?: string
  program: Program
  step: ProgramCreateInCRMStepExt
  isModal?: boolean
}

export const EditCRMStepV2Container: FC<EditCRMStepV2ContainerProps> = (props: EditCRMStepV2ContainerProps) => {
  const { className, dataTest, step, program, isRunning, closeModal, onSubmit, isModal = false } = props

  const [state, setState] = useState<EditCRMStepV2State>({
    ...editCRMStepV2InitialState,
    showSteps: !!step.letter && !program.crm?.crmModelNotCurrent,
    steps: [
      { title: STEP_DETAILS_KEY, key: STEP_DETAILS_KEY, completed: !!step.displayName && !!step.pushType, open: true },
      ...(step.additionalEntities?.map<EditCRMStepV2EntityStep>(({ entityType = '', entitySingular }) => ({
        title: entitySingular ?? entityType,
        key: entityType,
        open: false,
        completed: true,
      })) ?? []),
    ],
  })
  const { showSteps, steps, fieldDefinitionsByEntity, entitiesReferences, entities } = state

  const update = useDeepUpdate(setState)

  const { connectorType } = useCRM()

  const { getCreateTypes, getPeopleFields, getIntersectionSchema, getReferenceEntities } = useEditCRMStepV2Requests()

  const yupSchema = useMemo(() => getSchema(fieldDefinitionsByEntity), [fieldDefinitionsByEntity])

  const form = useForm<ProgramCreateInCRMStepExt>({
    resolver: yupResolver(yupSchema),
    defaultValues: {
      ...step,
      staticFields: step.staticFields ?? [],
      additionalEntities: step.additionalEntities ?? [],
      pushType: step.pushType ?? '',
      pushNewRecords: !!step.letter && step.pushNewRecords,
      canUpdateExistingContacts: !!step.letter && step.canUpdateExistingContacts,
    },
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })
  const { control, watch, setValue } = form

  const fields = watch('additionalEntities', [])
  const pushType = watch('pushType')
  const pushNewRecords = watch('pushNewRecords')

  const { remove } = useFieldArray<ProgramCreateInCRMStepExt>({ control, name: 'additionalEntities' })

  const getEntityFields = async (entity: string): Promise<FieldDefinition[]> => {
    const { crmIntersectionSchema = [] } = await getIntersectionSchema(entity)
    return crmIntersectionSchema.map(({ fieldDefinition }) => fieldDefinition)
  }

  const removeStep = ({ key: removedStepKey }: EditCRMStepV2EntityStep) => {
    const getStepIndexToOpen = () => {
      const removedStepIndex = steps.findIndex((entity) => entity.key === removedStepKey)
      if (removedStepIndex >= 0 && steps[removedStepIndex].open) {
        return removedStepIndex === steps.length - 1 ? removedStepIndex - 1 : removedStepIndex + 1
      }
      return -1
    }

    const matchingKeys = opportunitiesEntities.includes(removedStepKey.toString())
      ? entities.filter(({ value }) => opportunitiesEntities.includes(value as string)).map(({ value }) => value)
      : [removedStepKey]

    if (fields) {
      const indicesToRemove = matchingKeys
        .map((key) => fields.findIndex(({ entityType }) => entityType === key))
        .filter((index) => index >= 0)
        .sort((a, b) => b - a)

      indicesToRemove.forEach((index) => remove(index))

      const stepIndexToOpen = getStepIndexToOpen()
      const updatedSteps = steps.reduce((updatedSteps: EditCRMStepV2EntityStep[], step, index) => {
        if (!matchingKeys.includes(step.key as string)) {
          updatedSteps.push(index === stepIndexToOpen ? { ...step, open: true } : step)
        }
        return updatedSteps
      }, [])

      update({ steps: updatedSteps })
    }
  }

  const getFieldsReferences = async (fieldDefinitions: FieldDefinition[]) => {
    const fieldsWithReference = fieldDefinitions.filter((field) => field.dataType === DetailsDataType.REFERENCE)
    const responses = await Promise.all(fieldsWithReference.map(({ referenceEntity = '' }) => getReferenceEntities(referenceEntity)))
    const referenceEntities = new Map<string, StringKeyValue[]>()
    fieldsWithReference.forEach(({ referenceEntity = '' }, index) => {
      referenceEntities.set(referenceEntity, responses[index].crmReferenceEntities)
    })
    return referenceEntities
  }

  const hideBooleanFields = (fieldDefinitions: FieldDefinition[]) => {
    return fieldDefinitions.map((field) => ({
      ...field,
      required: field.dataType !== DetailsDataType.BOOLEAN && field.required,
    }))
  }

  const loadReferences = async (entities: string[]) => {
    update({ loadingReferences: true })
    const fields = await Promise.all(entities.map(getEntityFields))
    const filteredFields = fields.map(hideBooleanFields)
    const allEntitiesReferences = await Promise.all(filteredFields.map(getFieldsReferences))
    const entitiesReferencesTemp = new Map(entitiesReferences)
    const fieldDefinitionsByEntityTemp = new Map(fieldDefinitionsByEntity)
    allEntitiesReferences.forEach((references) => {
      references.forEach((value, key) => {
        entitiesReferencesTemp.set(key, value)
      })
    })
    entities.forEach((entity, index) => {
      fieldDefinitionsByEntityTemp.set(entity, filteredFields[index])
    })
    if (connectorType === CRMConnectorType.SALESFORCE) {
      primaryEntitiesWithHiddenFields.forEach((key) => {
        const fields = fieldDefinitionsByEntityTemp.get(key)
        if (fields) {
          fieldDefinitionsByEntityTemp.set(
            key,
            fields.map((field) => ({ ...field, required: false }))
          )
        }
      })
    }
    update({
      loadingReferences: false,
      fieldDefinitionsByEntity: fieldDefinitionsByEntityTemp,
      entitiesReferences: entitiesReferencesTemp,
    })
  }

  useEffect(() => {
    if (showSteps && steps.length > 0) {
      const entities = steps.reduce((selectedEntities: string[], { key }) => {
        const entity = key === STEP_DETAILS_KEY && pushType ? pushType : (key as string)
        if (!fieldDefinitionsByEntity.has(entity)) {
          return [...selectedEntities, entity]
        }
        return selectedEntities
      }, [])
      update({ showErrorBanner: steps.some(({ key }) => opportunitiesEntities.includes(key as string)) && pushType === 'Lead' })
      if (entities.length > 0) {
        loadReferences(entities)
      }
    }
  }, [steps, showSteps, pushType, pushNewRecords])

  const loadInitialData = async () => {
    const createTypesPromise = getCreateTypes()
    const peopleFieldsPromise = getPeopleFields()
    await Promise.all([createTypesPromise, peopleFieldsPromise]).then(([{ crmCreateTypes }, { crmPeopleFields }]) => {
      update({
        entities: crmCreateTypes,
        recordTypes: crmPeopleFields,
        loadingInitialValues: false,
      })
      if (crmPeopleFields.length > 0 && !crmPeopleFields.some(({ value }) => value === step.pushType)) {
        setValue('pushType', '')
      }
    })
  }

  useEffect(() => {
    if (!program.crm?.crmModelNotCurrent) {
      loadInitialData()
    }
  }, [])

  return (
    <EditCRMStepV2Context.Provider value={{ values: state, update, removeStep, program }}>
      <FormProvider {...form}>
        {isRunning || !onSubmit ? (
          <CreateInCRMDetailsV2 step={step} closeModal={closeModal} isModal={isModal} />
        ) : (
          <EditCRMStepV2 className={className} dataTest={dataTest} onSubmit={onSubmit} program={program} closeModal={closeModal} />
        )}
      </FormProvider>
    </EditCRMStepV2Context.Provider>
  )
}
