import { gql } from '@apollo/client'

export default gql`
  query getRepliesReportsByMessageId(
    $accountId: Long!
    $sortDirection: Direction
    $sortColumn: RepliesSortColumns
    $searchTerm: String
    $size: Int!
    $messageId: Long!
    $page: Int!
  ) {
    getRepliesReportsByMessageId(
      accountId: $accountId
      sortDirection: $sortDirection
      sortColumn: $sortColumn
      searchTerm: $searchTerm
      size: $size
      messageId: $messageId
      page: $page
    ) {
      smsReplies {
        contactName
        phoneNumber
        repliedOnDate
        replyText
      }
      totalCount
    }
  }
`
