import React, { FC, useContext } from 'react'

import { ProgramCopyStep } from '@graphql/types/query-types'
import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'
import {
  UpgradedProgramAppendToSegmentStep,
  UpgradedSource,
  UpgradeManagerContext,
} from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'
import { getSource } from '@src/pages/programs/UpgradePrograms/components/ReviewUpgrade/sections/FixStepIssuesSection/components/ReviewCurrentStep/ReviewCurrentStep.utils'
import { ReviewSourceReplacement } from '@src/pages/programs/UpgradePrograms/components/ReviewUpgrade/sections/FixStepIssuesSection/components/ReviewCurrentStep/steps/components/ReviewSourceReplacement/ReviewSourceReplacement'

interface ReviewCopyToListStepProps {
  step: UpgradedProgramAppendToSegmentStep
}

export const ReviewCopyToListStep: FC<ReviewCopyToListStepProps> = ({ step }: ReviewCopyToListStepProps) => {
  const {
    values: { upgradedStepSources, stepsToUpgrade },
  } = useContext(UpgradeManagerContext)
  const {
    values: {
      program: { sources },
    },
  } = useContext(ProgramManagerContext)
  const upgradedSource = upgradedStepSources[step.stepId ?? ''][step.upgradeId ?? '']
  const originalStep = stepsToUpgrade?.find((originalStep) => originalStep.stepId === step.stepId) as ProgramCopyStep
  const programOriginalSource = sources.find((source) => source.id === originalStep?.listId ?? '')
  const source: UpgradedSource = getSource(programOriginalSource, upgradedSource)

  return <ReviewSourceReplacement source={source} />
}
