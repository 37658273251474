import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import classNames from 'classnames'

import { FetchResult } from '@apollo/client'
import Button, { ButtonType } from '@components/Button'
import Container from '@components/Container'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Toggle from '@components/Toggle'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import { UpdateNotifyOnShareMutation } from '@graphql/types/microservice/thoughtspot-types'
import { StatusToastType } from '@interface/StatusToast'

import './AnalyticsNotifications.css'

interface AnalyticsNotificationsProps {
  setToastStatus: (value: StatusToastType) => void
  getValueAPI: () => Promise<boolean>
  onSave: (value: boolean) => Promise<FetchResult<UpdateNotifyOnShareMutation>>
  className?: string
  dataTest?: string
}

const rootClass = 'analytics-notifications'

const AnalyticsNotifications: FC<AnalyticsNotificationsProps> = ({ setToastStatus, getValueAPI, onSave, dataTest = rootClass, className }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const [toggleValue, setToggleValue] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)

  const getValue = useCallback(() => {
    setLoading(true)
    getValueAPI()
      .then((value) => {
        setError(false)
        setToggleValue(value)
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false))
  }, [getValueAPI])

  const handleToggle = useCallback(
    (value: boolean) => {
      setLoading(true)
      return onSave(value)
        .then(() => {
          setToggleValue(value)
          setToastStatus({
            showStatus: true,
            title: value ? t('Success!') : t('Email notifications disabled'),
            statusMessage: value ? t('Your email notifications are now enabled.') : t('You won’t receive notifications when messages are launched.'),
            successStatus: true,
          })
        })
        .catch(() => {
          setToastStatus({
            showStatus: true,
            statusMessage: t('Failed to save notification settings.'),
            successStatus: false,
          })
        })
        .finally(() => setLoading(false))
    },
    [onSave, setToastStatus, t]
  )

  useEffect(() => {
    const notificationsPath = `${rootContext}/profile/notifications`
    const searchParams = new URLSearchParams(window.location.search)
    if (window.location.pathname === notificationsPath && searchParams.get('unsubscribeAnalytics') === 'true') {
      handleToggle(false).then(() => {
        history.replace(notificationsPath)
      })
    } else {
      getValue()
    }
  }, [])

  return (
    <Container className={classNames(rootClass, className, 'flex-align-center')} dataTest={dataTest}>
      <div>
        <Typography text={t('Analytics notifications')} type={TextType.SECTION_HEADER} className={`${rootClass}__title`} />
        <Typography text={t('Analytics.Notifications.Description')} type={TextType.BODY_TEXT_LIGHT} />
      </div>
      {error ? (
        <Tooltip
          trigger={
            <Button buttonType={ButtonType.FLOAT_TEAL} onClick={getValue} disabled={loading} dataTest={`${dataTest}-retry-button`}>
              <Svg name={SvgNames.refresh} fill={SvgColor.TEXT_TEAL} type={SvgType.ICON} />
              {t('Retry')}
            </Button>
          }
        >
          {t('Failed to get settings')}
        </Tooltip>
      ) : (
        <Toggle large isOn={toggleValue} noRightMargin onToggle={handleToggle} disabled={loading} dataTest={`${dataTest}-toggle`} />
      )}
    </Container>
  )
}

export default AnalyticsNotifications
