import React, { useEffect } from 'react'

import Loader from '@components/Loader'
import PageContainer from '@components/PageContainer'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import loginAsParent from '@graphql/microservices/account/loginAsParent'
import { LoginAsParentMutation, LoginAsParentMutationVariables } from '@graphql/types/microservice/account-types'
import { useAccountSettings } from '@utils/account/account.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { logNewRelicError } from '@utils/new-relic.utils'

const BackToParent = () => {
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.ACCOUNT })
  const { accountId, parentId, userId, userEmail } = useAccountSettings()
  const parentAccountId = parentId === '0' ? accountId : parentId

  useEffect(() => {
    const getParentLogin = async () => {
      const { data, errors } = await client.mutate<LoginAsParentMutation, LoginAsParentMutationVariables>({
        mutation: loginAsParent,
        variables: {
          accountId,
          parentAccountId,
          userId,
          email: userEmail,
        },
      })

      if (data?.loginAsParent) {
        const { domain, redirectLink } = data.loginAsParent
        window.open(`${domain}${redirectLink}&backToParent=true`, '_self')
      }

      if (errors) {
        logNewRelicError(errors)
      }
    }

    if (accountId && parentAccountId && userId) {
      getParentLogin()
    }
  }, [accountId, client, parentAccountId, userId])

  return (
    <PageContainer>
      <PositionContainer>
        <Loader center={true} />
      </PositionContainer>
    </PageContainer>
  )
}

export default BackToParent
