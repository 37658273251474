import { gql } from '@apollo/client'

export default gql`
  query scoreSheets {
    scoreSheets {
      id
      name
    }
  }
`
