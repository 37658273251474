import { useApolloClient } from '@apollo/client'
import getItem from '@graphql/microservices/categorization/getItem'
import createFormFromTemplate from '@graphql/mutations/createFormFromTemplate'
import createTemplateFromForm from '@graphql/mutations/createTemplateFromForm'
import deleteFormTemplates from '@graphql/mutations/deleteFormTemplates'
import duplicateFormTemplate from '@graphql/mutations/duplicateFormTemplate'
import getFormTemplatePreview from '@graphql/queries/getFormTemplatePreview'
import { GetItemQuery, GetItemQueryVariables } from '@graphql/types/microservice/categorization-types'
import {
  CreateFormFromTemplateMutation,
  CreateFormFromTemplateMutationVariables,
  CreateTemplateFromFormMutation,
  CreateTemplateFromFormMutationVariables,
  DeleteFormTemplatesMutation,
  DeleteFormTemplatesMutationVariables,
  DuplicateFormTemplateMutation,
  DuplicateFormTemplateMutationVariables,
} from '@graphql/types/mutation-types'
import { GetFormTemplatePreviewQuery, GetFormTemplatePreviewQueryVariables } from '@graphql/types/query-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export interface FormTemplatesListingPageRequests {
  deleteFormTemplatesRequest: (variables: DeleteFormTemplatesMutationVariables) => FetchPromise<DeleteFormTemplatesMutation>
  duplicateFormTemplatesRequest: (variables: DuplicateFormTemplateMutationVariables) => FetchPromise<DuplicateFormTemplateMutation>
  createFormTemplatesRequest: (variables: CreateFormFromTemplateMutationVariables) => FetchPromise<CreateFormFromTemplateMutation>
  getFormTemplatesPreviewRequest: (variables: GetFormTemplatePreviewQueryVariables) => FetchPromise<GetFormTemplatePreviewQuery>
  createTemplateFromFormRequest: (variables: CreateTemplateFromFormMutationVariables) => FetchPromise<CreateTemplateFromFormMutation>
  getItemRequest: (variables: GetItemQueryVariables) => FetchPromise<GetItemQuery>
}

export const useFormTemplatesListingPageRequests = (): FormTemplatesListingPageRequests => {
  const actonClient = useApolloClient()
  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })

  const createTemplateFromFormRequest = async (variables: CreateTemplateFromFormMutationVariables) => {
    return await actonClient.mutate<CreateTemplateFromFormMutation, CreateTemplateFromFormMutationVariables>({
      mutation: createTemplateFromForm,
      errorPolicy: 'all',
      variables,
    })
  }

  const getFormTemplatesPreviewRequest = async (variables: GetFormTemplatePreviewQueryVariables) => {
    return await actonClient.query<GetFormTemplatePreviewQuery, GetFormTemplatePreviewQueryVariables>({
      query: getFormTemplatePreview,
      errorPolicy: 'all',
      variables,
    })
  }

  const createFormTemplatesRequest = async (variables: CreateFormFromTemplateMutationVariables) => {
    return await actonClient.mutate<CreateFormFromTemplateMutation, CreateFormFromTemplateMutationVariables>({
      mutation: createFormFromTemplate,
      errorPolicy: 'all',
      variables,
    })
  }

  const deleteFormTemplatesRequest = async (variables: DeleteFormTemplatesMutationVariables) => {
    return await actonClient.mutate<DeleteFormTemplatesMutation, DeleteFormTemplatesMutationVariables>({
      mutation: deleteFormTemplates,
      errorPolicy: 'all',
      variables,
    })
  }

  const duplicateFormTemplatesRequest = async (variables: DuplicateFormTemplateMutationVariables) => {
    return await actonClient.mutate<DuplicateFormTemplateMutation, DuplicateFormTemplateMutationVariables>({
      mutation: duplicateFormTemplate,
      errorPolicy: 'all',
      variables,
    })
  }

  const getItemRequest = async (variables: GetItemQueryVariables) => {
    return await categorizationClient.query<GetItemQuery, GetItemQueryVariables>({
      query: getItem,
      errorPolicy: 'all',
      variables,
      fetchPolicy: 'no-cache',
    })
  }

  return {
    deleteFormTemplatesRequest,
    duplicateFormTemplatesRequest,
    createFormTemplatesRequest,
    getFormTemplatesPreviewRequest,
    createTemplateFromFormRequest,
    getItemRequest,
  }
}
