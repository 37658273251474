export enum FieldSetOptions {
  INCR = 'INCR',
  DECR = 'DECR',
  CONST = 'CONST',
  SCORE = 'SCORE',
  CAMPAIGN = 'CAMPAIGN',
  UID = 'UID',
  NOOP = 'NOP',
}

export interface CFSOperation {
  fieldName: string
  rule: FieldSetOptions
  value: string
  error?: boolean | string
}

export enum CFSConditionType {
  EMAIL,
  LIST,
}

export interface CFSCondition {
  condition: string
  name: string
  type: CFSConditionType
  msgId: string
  operations: CFSOperation[]
}
