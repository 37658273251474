import { createContext, ReactNode } from 'react'

import { contactDetailsMockProps } from '@components/ContactsDetails/ContactsDetails.mock'
import { itemActivityMockSegment } from '@components/ItemActivity/ItemActivity.mock'
import { Status } from '@components/StatusToast/StatusToast'
import { PageInput } from '@graphql/types/microservice/segment-types'
import { Folder } from '@interface/Folder'
import { ItemType } from '@utils/categorization'
import { Segment } from '@utils/contactSegments/contactSegments.utils'

type Update = (values: Partial<SegmentDetailsContainerState>) => void

export interface SegmentDetailsContainerState {
  segmentContacts: PageInput
  folders: Folder[]
  items: Segment[]
  statusToast: { statusMessage: string | ReactNode; status: Status; showStatusToast: boolean }
  segment: Segment | undefined
  itemType: ItemType
  backUrl: string
  showCustomModal: boolean
  customAction?: string
  fetchItems: boolean
}

export const SegmentDetailsContainerInitialState: SegmentDetailsContainerState = {
  segmentContacts: {},
  folders: [],
  items: [],
  statusToast: { statusMessage: '', showStatusToast: false, status: Status.SUCCESS },
  segment: undefined,
  itemType: ItemType.SEGMENT,
  backUrl: '',
  showCustomModal: false,
  fetchItems: false,
}

export const SegmentDetailsMockContext = {
  update: () => undefined,
  values: {
    segmentContacts: contactDetailsMockProps.segmentContacts,
    folders: [],
    items: [],
    statusToast: { statusMessage: '', status: Status.SUCCESS, showStatusToast: false },
    segment: itemActivityMockSegment,
    itemType: ItemType.SEGMENT,
    backUrl: '',
    showCustomModal: false,
    fetchItems: false,
  },
}

export interface SegmentDetailsContextAPI {
  update: Update
  values: SegmentDetailsContainerState
}

export const SegmentDetailsContext = createContext<SegmentDetailsContextAPI>({
  values: SegmentDetailsContainerInitialState,
} as any)
