import React from 'react'

import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { TabItemData } from '@components/TabsAO/TabsAO'
import Tooltip from '@components/Tooltip/Tooltip'

export type ContactState = 'inProgram' | 'earlyExit' | 'standardExit' | 'all'

export interface ContactTypeCount {
  name: ContactState
  count: number
}

export interface ContactCountByWeek {
  date: string
  entries: number
  exits: number
  contactState: ContactState
}

export interface ContactDetails {
  name: string
  email: string
  sourceList: string
  entryDate: string
  status: ContactState
  currentStep?: string
  exitDate?: string
  earlyExitReason?: string
  [key: string]: any
}

export enum ProgramPerformanceTab {
  All_CONTACTS = 'allContacts',
  IN_PROGRAM = 'inProgram',
  EARLY_EXITS = 'earlyExit',
  STANDARD_EXITS = 'standardExit',
}

export const DEFAULT_PERFORMANCE_TAB = ProgramPerformanceTab.All_CONTACTS

export const DownloadContactsCSVParamsMAP = new Map<ProgramPerformanceTab, string>([
  [ProgramPerformanceTab.All_CONTACTS, 'ALL'],
  [ProgramPerformanceTab.IN_PROGRAM, 'IN_PROGRAM'],
  [ProgramPerformanceTab.EARLY_EXITS, 'EARLY_EXIT'],
  [ProgramPerformanceTab.STANDARD_EXITS, 'EXITED'],
])

export const getContactsUrl = (id: string, activeTab: ProgramPerformanceTab) =>
  `/acton/internalapi/program/contact/download/${id}?&step=&state=${DownloadContactsCSVParamsMAP.get(activeTab)}`

export const ProgramPerformanceTabs: TabItemData[] = [
  {
    index: ProgramPerformanceTab.All_CONTACTS,
    label: (
      <div className={'program-performance__all-contacts-tab'}>
        <span>All Contacts </span>
        <Tooltip trigger={<Svg name={SvgNames.info} type={SvgType.ICON} className="tabs-ao__split-container-tooltip" />}>
          This is the total of all contacts who entered the program over time. This total includes contacts in program, early exits, and standard
          exits.
        </Tooltip>
      </div>
    ),
    ariaLabel: 'All Contacts',
    themeName: 'delivered',
  },
  {
    index: ProgramPerformanceTab.IN_PROGRAM,
    label: 'Contacts in Program',
    themeName: 'clicked',
  },
  {
    index: ProgramPerformanceTab.EARLY_EXITS,
    label: 'Early Exit Contacts',
    themeName: 'earlyexit',
  },
  {
    index: ProgramPerformanceTab.STANDARD_EXITS,
    label: 'Standard Exit Contacts',
    themeName: 'standardexit',
  },
]

export enum ProgramScheduleType {
  TRIGGERED = 'TRIGGERED',
  NEVER = 'NEVER',
  SCHEDULED = 'SCHEDULED',
}

export enum ProgramScheduleFrequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export const AJB_HELP_LINK = 'https://connect.act-on.com/hc/en-us/articles/360056536613-Automated-Journey-Builder-User-Guide'
