import { useMemo } from 'react'

import { useApolloClient } from '@apollo/client'
import getAllSalesUsers from '@graphql/queries/getAllSalesUsers'
import getAllScheduledMessages from '@graphql/queries/getAllScheduledMessages'
import {
  GetAllSalesUsersQuery,
  GetAllSalesUsersQueryVariables,
  GetAllScheduledMessagesQuery,
  GetAllScheduledMessagesQueryVariables,
} from '@graphql/types/query-types'
import { createGraphQLQuery } from '@utils/apollo'

export const useEmailScheduledRequests = () => {
  const actonClient = useApolloClient()

  return useMemo(
    () => ({
      getAllScheduledMessagesRequest: createGraphQLQuery<GetAllScheduledMessagesQuery, GetAllScheduledMessagesQueryVariables>(
        actonClient,
        getAllScheduledMessages
      ),
      getAllSalesUsersRequest: createGraphQLQuery<GetAllSalesUsersQuery, GetAllSalesUsersQueryVariables>(actonClient, getAllSalesUsers),
    }),
    [actonClient]
  )
}
