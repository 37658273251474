import { useCallback, useContext, useEffect } from 'react'

import { DEFAULT_FILTERS } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { useTranslation } from '@const/globals'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import { ItemType } from '@utils/categorization'
import { buildComplexFoldersTreeHeader, sortFoldersByName } from '@utils/folderUtils'

import { useFolderSubTypes } from './useFolderSubTypes'
import { MENU_ITEM_INDEX, MenuActions } from '../Components/Sidebar/Utils/Sidebar.utils'
import { GetSidebarMenuItems, ListingPageCommonContext } from '../Context/ListingPageCommon.context'
import { useFolderRequests } from '../GraphQL/Folders.graphQL'
import { useTagRequests } from '../GraphQL/Tags.graphQL'

export const useSidebarBase = (menuActions: MenuActions, getSidebarMenuItems: GetSidebarMenuItems) => {
  const {
    values,
    values: {
      listingPageProps,
      listingPageProps: {
        hasSecondaryFolders,
        hasSecondaryTags,
        secondaryItemType,
        sidebarProps: { hideFolders, hideTags, customFilterSelected, renderCustomFilters },
      },
      itemType,
      activeTagId,
      activeFolderId,
      fetchFolders,
      fetchTags,
      folders,
      folderPath,
      tags,
      filterActive,
      defaultSubTypes,
      filterCounts,
    },
    update,
    setError,
  } = useContext(ListingPageCommonContext)

  const { t } = useTranslation()

  const folderSubTypes = useFolderSubTypes(listingPageProps)
  const { getAllFoldersRequest } = useFolderRequests(folderSubTypes)
  const { getAllTagsRequest } = useTagRequests()

  useEffect(() => {
    if (fetchFolders && !hideFolders) {
      onGetAllFoldersRequest()

      if (hasSecondaryFolders) {
        onGetAllFoldersRequest(true)
      }
    }

    if (fetchTags && !hideTags) {
      onGetAllTagsRequest()

      if (hasSecondaryTags) {
        onGetAllTagsRequest(true)
      }
    }
  }, [fetchFolders, fetchTags])

  const defaultOpenedFolders = folderPath.map(({ id }) => id).slice(0, -1)

  const clearFoldersToExpand = useCallback(() => update({ hasToExpandFolders: [] }), [])

  const onGetAllFoldersRequest = async (fetchSecondaryFolders = false) => {
    const checkEnableLandingPageFlag = fetchSecondaryFolders && itemType === ItemType.EMAIL_TEMPLATE
    const { data, errors } = await getAllFoldersRequest(
      `${fetchSecondaryFolders ? secondaryItemType : itemType}`,
      defaultSubTypes,
      checkEnableLandingPageFlag
    )

    if (data && data.getFolders) {
      const folders = data.getFolders

      if (fetchSecondaryFolders) {
        update({
          secondaryFolders: sortFoldersByName(folders, true),
          fetchFolders: false,
        })
      } else {
        update({
          folders: sortFoldersByName(folders, true),
          folderPath: activeFolderId ? buildComplexFoldersTreeHeader(activeFolderId, folders as FolderData[]) : folderPath,
          fetchFolders: false,
        })
      }
    } else if (errors) {
      update({ fetchFolders: false })
      setError(t('An unexpected error occurred while fetching folders.'), errors)
    }
  }

  const onGetAllTagsRequest = async (fetchSecondaryTags = false) => {
    const { data, errors } = await getAllTagsRequest([`${fetchSecondaryTags ? secondaryItemType : itemType}`], defaultSubTypes)

    if (data) {
      const tags = Array.isArray(data.getLabels) ? (data.getLabels as LabelDto[]) : ([] as LabelDto[])

      if (fetchSecondaryTags) {
        update({ secondaryTags: tags, fetchTags: false })
      } else {
        update({ tags, fetchTags: false })
      }
    } else if (errors) {
      update({ fetchTags: false })
      setError(t('An unexpected error occurred while fetching tags.'), errors)
    }
  }

  const menuItems = useCallback(
    () => getSidebarMenuItems({ values, t, menuActions, defaultOpenedFolders, clearFoldersToExpand }),
    [
      renderCustomFilters,
      filterCounts,
      DEFAULT_FILTERS,
      folders,
      activeFolderId,
      defaultOpenedFolders,
      tags,
      activeTagId,
      filterActive,
      values,
      menuActions,
      t,
    ]
  )

  const getActiveMenuItemIndex = () => {
    if (activeFolderId) {
      return MENU_ITEM_INDEX.FOLDERS
    } else if (activeTagId) {
      return MENU_ITEM_INDEX.TAGS
    } else if (customFilterSelected) {
      return MENU_ITEM_INDEX.CUSTOM_FILTERS
    } else {
      return MENU_ITEM_INDEX.FILTERS
    }
  }

  return { getActiveMenuItemIndex, menuItems }
}
