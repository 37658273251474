import { gql } from '@apollo/client'

export default gql`
  query getCustomAccountPwdPolicies {
    getCustomAccountPwdPolicies {
      history
      expiration
      complexity
      length
    }
  }
`
