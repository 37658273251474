import { gql } from '@apollo/client'

export default gql`
  query getSubscriptionChildToParentCategories($parentAccountId: Long!, $childAccountId: Long!) {
    getSubscriptionChildToParentCategories(parentAccountId: $parentAccountId, childAccountId: $childAccountId) {
      childCategories {
        accountId
        classicId
        deleted
        description
        id
        subscriptionCategory
      }
      mapping {
        childCategoryId
        parentCategoryId
      }
      parentCategories {
        accountId
        classicId
        deleted
        description
        id
        subscriptionCategory
      }
    }
  }
`
