import React, { FC, useState } from 'react'

import classNames from 'classnames'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import TextLink from '@components/TextLink/TextLink'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { FormSubmissionSummaryDto } from '@graphql/types/microservice/entity-join-types'

import './RelatedFormsList.css'

interface RelatedFormsListProps {
  className?: string
  dataTest?: string
  nameMaxWidth?: number
  maxFormsToDisplay?: number
  relatedForms: FormSubmissionSummaryDto[]
  isWebinarRegistration?: boolean
}

const rootClass = 'related-forms-list'

const RelatedFormsList: FC<RelatedFormsListProps> = (props: RelatedFormsListProps) => {
  const { className = '', relatedForms, nameMaxWidth, maxFormsToDisplay, isWebinarRegistration } = props
  const { t } = useTranslation()
  const [expandedForms, setExpandedForms] = useState<boolean>(false)
  const formsToRender = maxFormsToDisplay && !expandedForms ? relatedForms.slice(0, maxFormsToDisplay) : relatedForms
  return (
    <>
      {relatedForms.length ? (
        <>
          {formsToRender &&
            formsToRender.map((form, index) => (
              <div key={index} className={classNames(rootClass, className)}>
                {form.formName ? (
                  <>
                    <div className={`${rootClass}__name`} style={{ maxWidth: nameMaxWidth ? `${nameMaxWidth}rem` : 'unset' }}>
                      <Svg name={isWebinarRegistration ? SvgNames.webinarOutline : SvgNames.formSimple} type={SvgType.ICON} />
                      <Typography text={form.formName} weight={TextWeight.BOLD} className={'ellip'} />
                    </div>
                    <Typography
                      text={`RelatedForms.${isWebinarRegistration ? 'Registrations' : 'Submissions'}.Count${
                        form.totalContacts ? '' : '.SingleContact'
                      }`}
                      values={{
                        count: form.totalSubmissions,
                        totalContacts: form.totalContacts,
                      }}
                      type={TextType.BODY_TEXT_GRAY_SMALL}
                      weight={TextWeight.MEDIUM_LIGHT}
                    />
                  </>
                ) : (
                  <Typography text={t('No forms associated')} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.BOLD} />
                )}
              </div>
            ))}
          {maxFormsToDisplay && relatedForms.length > maxFormsToDisplay && (
            <TextLink
              text={expandedForms ? t('- Show less') : t('+ Show more')}
              hideIcon
              onClick={() => {
                setExpandedForms(!expandedForms)
              }}
              dataTest={`${rootClass}-control`}
            />
          )}
        </>
      ) : (
        <Typography text={t('No forms associated')} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.BOLD} />
      )}
    </>
  )
}

export default RelatedFormsList
