import { gql } from '@apollo/client'

export default gql`
  mutation editSeoFriendlyUrl($pageId: String!, $urlId: String!, $seoName: String!) {
    editSeoFriendlyUrl(pageId: $pageId, urlId: $urlId, seoName: $seoName) {
      id
      name
      publicUrl
      seoFriendlyUrl
      shortUrl
    }
  }
`
