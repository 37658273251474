import Cookies from 'js-cookie'

export enum LoginCookie {
  MFA = 'ao_mfa',
  CUSTOMER_CARE = 'ccl',
  USER = 'uc',
}

export const getCookie = (cookie: string, name: string) => {
  const parts = cookie.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop()?.split(';').shift()
}

export function listenCookieChange(callback: (value: string) => void, interval = 1000) {
  let lastCookie = document.cookie
  setInterval(() => {
    const cookie = document.cookie
    if (cookie !== lastCookie) {
      try {
        callback(cookie)
      } finally {
        lastCookie = cookie
      }
    }
  }, interval)
}

export const isCustomerCareLogin = () => !!Cookies.get(LoginCookie.CUSTOMER_CARE)

export default {
  getCookie,
  listenCookieChange,
}
