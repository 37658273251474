import React, { FC } from 'react'
import { Control, DeepMap, FieldError, useFieldArray } from 'react-hook-form'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Container from '@components/Container'
import Input from '@components/Input/Input'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { Rule } from '@graphql/types/microservice/segment-types'
import { ContactSettingsFields } from '@src/pages/Settings/OtherSettings/ContactSettings/ContactSettings.utils'

import { ContactSettings } from '../../ContactSettings'

import './FatigueSuppressionRules.css'

interface Props {
  className?: string
  dataTest?: string
  disabled: boolean
  errors?: DeepMap<Record<string, any>, FieldError>
  register: Control<any>['register']
  control?: Control<ContactSettings, any>
}

const emptyFatigueRule: Rule = {
  maxMessages: 5,
  timespan: 5,
}

const rootClass = 'fatigue-suppression-rules'

const LIMIT = 'maxMessages'
const PERIOD = 'timespan'

const FatigueSuppressionRules: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '', disabled, errors, register, control } = props

  const { t } = useTranslation()

  const {
    fields: fatigueRules,
    append,
    remove,
  } = useFieldArray({
    control,
    name: ContactSettingsFields.FATIGUE_RULES,
  })

  const getError = (field: keyof Rule, index: number): FieldError | undefined => {
    if (errors?.fatigueRules && errors.fatigueRules[index]) {
      return errors.fatigueRules[index][field]
    }
  }

  const renderError = (field: keyof Rule, index: number) => {
    const error = getError(field, index)
    if (error) {
      return (
        <Typography dataTest={`${dataTest}__${field}-error`} text={t(error.message)} type={TextType.ERROR} className={`${className}__input-error`} />
      )
    }
  }

  return (
    <Container className={`${className}__container`} dataTest={dataTest} noBottom>
      <Typography
        dataTest={`${dataTest}__header`}
        className={`${className}__header`}
        text={t('Fatigue Suppression Rules')}
        type={TextType.SECTION_HEADER}
        weight={TextWeight.MEDIUM}
      />
      <Typography
        text={t(
          'Create a set of rules that limit the number of marketing emails a recipient can be sent over a specified time period. Event Reminder and Form Auto-Response emails are not subject to this limit.'
        )}
        type={TextType.BODY_TEXT_LIGHT}
      />
      {fatigueRules.map((rule, index: number) => {
        return (
          <div
            key={rule.id}
            className={classNames(`${className}__input-section`, `${rootClass}__rules`)}
            data-test={`${dataTest}__fatigue-rule-${index}`}
          >
            <div className={`${className}__input-container`}>
              <Input
                min={0}
                type="number"
                disabled={disabled}
                label={t('Message limit')}
                className={`${className}__input`}
                defaultValue={`${rule.maxMessages ?? 0}`}
                dataTest={`input-fatigue-rule-limit-${index}`}
                register={register(`${ContactSettingsFields.FATIGUE_RULES}[${index}].${LIMIT}`)}
              />
              {renderError(LIMIT, index)}
            </div>
            <div className={`${className}__input-container`}>
              <Input
                min={0}
                type="number"
                disabled={disabled}
                label={t('Time Period (days)')}
                className={`${className}__input`}
                defaultValue={`${rule.timespan ?? 0}`}
                dataTest={`input-fatigue-rule-period-${index}`}
                register={register(`${ContactSettingsFields.FATIGUE_RULES}[${index}].${PERIOD}`)}
              />
              {renderError(PERIOD, index)}
            </div>
            <Tooltip
              triggerClassName={`${rootClass}__delete-rule`}
              trigger={
                <Button dataTest={`${dataTest}-delete-rule-${index}`} buttonType={ButtonType.ICON} disabled={disabled} onClick={() => remove(index)}>
                  <Svg name={SvgNames.delete} />
                </Button>
              }
            >
              {t('Delete rule')}
            </Tooltip>
          </div>
        )
      })}
      <div className={`${rootClass}__button-section`}>
        <Button dataTest={`${dataTest}-add-rule`} buttonType={ButtonType.FLOAT} disabled={disabled} onClick={() => append(emptyFatigueRule)}>
          <Svg name={SvgNames.plus} type={SvgType.ICON} />
          {t(fatigueRules.length > 0 ? 'Add another' : 'Add')}
        </Button>
      </div>
    </Container>
  )
}

export default FatigueSuppressionRules
