import React, { FC } from 'react'

import HTMLMediaCard from '@components/HTMLMediaCard/HTMLMediaCard'
import Spinner, { LoaderSize } from '@components/Spinner/Spinner'
import { useTranslation } from '@const/globals'
import { useEmailLayoutsModalContentContext } from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/context/EmailLayoutsModalContent.context'
import {
  getSystemDefaultTooltipText,
  isSystemDefaultLayout,
} from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/utils/EmailLayoutsModal.utils'

interface EmailLayoutsModalGridViewProps {
  rootClass: string
  dataTest?: string
}

const EmailLayoutsModalGridView: FC<EmailLayoutsModalGridViewProps> = ({ rootClass, dataTest = rootClass }) => {
  const { layouts, loading, layoutsType, onPreview, onSelect, selectedId, sendPlainText } = useEmailLayoutsModalContentContext()
  const { t } = useTranslation()

  if (loading) {
    return <Spinner size={LoaderSize.MEDIUM} className={`${rootClass}__spinner`} dataTest={`${dataTest}-spinner`} />
  }

  return (
    <div className={rootClass} data-test={dataTest}>
      {layouts.map((layout) => {
        const { stringId, id, isDefault, title = '', html = '', text = '' } = layout
        const isSystem = isSystemDefaultLayout(id)
        const isSelected = stringId === selectedId

        return (
          <HTMLMediaCard
            key={stringId}
            title={title}
            html={sendPlainText ? text : html}
            isDefault={isDefault}
            isSelected={isSelected}
            mainActionLabel={t('Email.Layout.Modal.Select.Label', { layoutsType })}
            infoTooltip={isSystem ? getSystemDefaultTooltipText(t, layoutsType) : ''}
            onSelect={() => onSelect(layout)}
            onPreview={() => onPreview(layout)}
            dataTest={`${dataTest}-card-${stringId}`}
          />
        )
      })}
    </div>
  )
}

export default EmailLayoutsModalGridView
