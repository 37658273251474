import { gql } from 'apollo-boost'

export enum Status {
  CREATED = 'CREATED',
  SUBMITTED = 'SUBMITTED',
  ABANDONED = 'ABANDONED',
  COMPLETED = 'COMPLETED',
}
export default gql`
  query getImportStatus($status: Page_ImportDetailsStatusInput) {
    importDetailsStatus(importDetailsStatusPage: $status) {
      list {
        typeMetaData
        status
      }
    }
  }
`
