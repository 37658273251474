import React from 'react'

import classNames from 'classnames'

import { Row } from '@tanstack/react-table'

import './SpacerBlock.css'

export interface RowDepth {
  depth: number
}

interface SpacerBlockProps<T> extends RowDepth {
  rows: Row<T>[]
  parentIndex: number
  spacerIndex: number
  className?: string
  dataTest?: string
}

interface SpacerBlockWrapperProps<T> extends Omit<SpacerBlockProps<T>, 'spacerIndex'> {
  cellValue: JSX.Element
}

const FIRST_LEVEL = 1
export const MAX_NESTING = 5

export const rootClassSpacerBlock = 'spacer-block-V2'

const renderSpacers = (initialLength: number) => {
  const length = initialLength > MAX_NESTING ? MAX_NESTING : initialLength - 2
  return Array.from({ length }, (_, i: number) => i + 1)
}

export const SpacerBlock = <T extends { depth: number }>(props: SpacerBlockProps<T>) => {
  const { spacerIndex, parentIndex, depth, rows } = props

  const nextIndex = parentIndex + 1
  const prevIndex = parentIndex - 1
  const nextRowDifference = nextIndex < rows.length ? depth - rows[nextIndex].original.depth : -1
  const nextRowLimit = depth - nextRowDifference
  const previousRowDifference = prevIndex > -1 ? depth - rows[prevIndex].original.depth : -1
  const prevRowLimit = depth - previousRowDifference

  const renderHalfBottomBorder = nextRowDifference > 0 && nextRowLimit < spacerIndex
  const renderHalfTopBorder = previousRowDifference > 0 && prevRowLimit < spacerIndex
  const className =
    spacerIndex === 1 ? `${rootClassSpacerBlock}-small` : depth === spacerIndex || spacerIndex === MAX_NESTING ? '' : `${rootClassSpacerBlock}-large`
  return spacerIndex !== MAX_NESTING ? (
    <div
      className={classNames(className, {
        [`${rootClassSpacerBlock}-border`]: !renderHalfBottomBorder && !renderHalfTopBorder && spacerIndex !== FIRST_LEVEL,
      })}
      data-test={rootClassSpacerBlock}
    >
      {!(depth === spacerIndex || spacerIndex === MAX_NESTING) && <div className={`${rootClassSpacerBlock}__line`}></div>}
    </div>
  ) : (
    <></>
  )
}

export const SpacerBlockWrapper = <T extends { depth: number }>({ rows, depth, parentIndex, cellValue }: SpacerBlockWrapperProps<T>) => (
  <div className={`${rootClassSpacerBlock}__wrapper`} data-test={`${rootClassSpacerBlock}__wrapper`}>
    {renderSpacers(depth).map((spacerIndex) => (
      <SpacerBlock key={`${spacerIndex}-depth-${depth}`} rows={rows} depth={depth} parentIndex={parentIndex} spacerIndex={spacerIndex} />
    ))}
    {cellValue}
  </div>
)
