import { UnifiedListFieldMappingInput, UnifiedListFieldMappingMutation } from '@graphql/types/microservice/list-types'
import { FieldSetOptions } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditFieldSetStep/EditFieldSetStep'
import { UpgradedProgramRecordSetStep, UpgradedProgramStep } from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'
import { ProgramStepType } from '@utils/program/program.constants'
import { FetchPromise } from '@utils/types'

export type FieldSetOperation = Exclude<FieldSetOptions, FieldSetOptions.NOOP>

export enum DataType {
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  EMAIL = 'EMAIL',
  MULTI_TEXT = 'MULTI_TEXT',
  NUMBER = 'NUMBER',
  SCORE = 'SCORE',
  TEXT = 'TEXT',
}

export const FieldSetValues: { [key in Exclude<FieldSetOptions, FieldSetOptions.NOOP>]: string } = {
  [FieldSetOptions.INCR]: 'AutomatedPrograms.UpgradeManager.FieldReplacement.IncreaseTheValueBy',
  [FieldSetOptions.DECR]: 'AutomatedPrograms.UpgradeManager.FieldReplacement.DecreaseTheValueBy',
  [FieldSetOptions.CONST]: 'AutomatedPrograms.UpgradeManager.FieldReplacement.SetTheValueTo',
  [FieldSetOptions.SCORE]: 'AutomatedPrograms.UpgradeManager.FieldReplacement.SetTheValueToBehavioralScore',
  [FieldSetOptions.CAMPAIGN]: 'AutomatedPrograms.UpgradeManager.FieldReplacement.SetTheValueToCampaignScore',
  [FieldSetOptions.UID]: 'AutomatedPrograms.UpgradeManager.FieldReplacement.AssignUniqueNumberInList',
}

export const createNewFieldMappings = (
  upgradedSteps: UpgradedProgramStep[],
  createNewUCLFieldMapping: (...fieldMappings: UnifiedListFieldMappingInput[]) => FetchPromise<UnifiedListFieldMappingMutation>
): FetchPromise<UnifiedListFieldMappingMutation> | undefined => {
  const changeFieldValueSteps = upgradedSteps.reduce(
    (steps: UpgradedProgramStep[], step) => (step.stepType === ProgramStepType.FIELD_SET ? [...steps, step] : steps),
    []
  ) as UpgradedProgramRecordSetStep[]

  const allOperations = [
    ...changeFieldValueSteps.map(({ conditions }) => conditions.map(({ operations }) => operations)).flat(),
    ...changeFieldValueSteps.map(({ operations }) => operations).flat(),
  ].flat()

  const newFields = allOperations.reduce(
    (newFields: { name: string; isNew: boolean; dataType?: DataType | undefined }[], operation) =>
      operation.newField?.isNew ? [...newFields, operation.newField] : newFields,
    []
  )

  const newUnifiedListFieldMappingInputs: UnifiedListFieldMappingInput[] = [...new Set(newFields.map(({ name }) => name))].map((newFieldName) => ({
    displayName: newFieldName,
    dataType: newFields.find(({ name }) => newFieldName === name)?.dataType ?? DataType.TEXT,
  }))

  if (newUnifiedListFieldMappingInputs.length > 0) {
    return createNewUCLFieldMapping(...newUnifiedListFieldMappingInputs)
  }
}
