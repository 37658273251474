import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/Button'
import { ModalType } from '@components/Modal/modalUtils'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'

import Typography, { ModalHeaderFormStyle, ModalHeaderListStyle, ModalHeaderStyle } from '../../Typography/Typography'

import './modalHeader.css'

export enum ModalHeaderType {
  Basic = 'basic',
  Form = 'form',
  List = 'list',
}

const styleMap = {
  [ModalHeaderType.Basic]: ModalHeaderStyle,
  [ModalHeaderType.Form]: ModalHeaderFormStyle,
  [ModalHeaderType.List]: ModalHeaderListStyle,
}

export interface ModalHeaderProps {
  children?: ReactNode
  hideBorder?: boolean
  className?: string
  closeButton?(): void
  dataTest?: string
  headerType?: ModalType
  hasHeaderIcon?: boolean
}

const rootClass = 'modal-header'

/**
 * @deprecated use <ModalHeaderV2 instead
 */
export const ModalHeader: FC<ModalHeaderProps> = (props: ModalHeaderProps) => {
  const { dataTest = 'modal-header', closeButton, className, hideBorder, hasHeaderIcon, headerType, children } = props
  const { t } = useTranslation()
  const headerContent = !!headerType && typeof children === 'string' ? <Typography text={children} {...styleMap[headerType]} /> : children

  return (
    <>
      <div
        className={classNames(`${rootClass}__inner`, className, {
          [`${rootClass}__inner--no-border`]: hideBorder || headerType === ModalHeaderType.Basic,
          [`${rootClass}__inner--default`]: headerType === ModalHeaderType.Basic,
          [`${rootClass}__inner--icon`]: hasHeaderIcon,
          [`${rootClass}__inner--form`]: headerType === ModalHeaderType.Form,
          [`${rootClass}__inner--list`]: headerType === ModalHeaderType.List,
        })}
        data-test={dataTest}
      >
        {headerContent}
      </div>
      {props.closeButton && (
        <Tooltip
          position={'top'}
          triggerClassName={`${rootClass}__close-button`}
          trigger={
            <Button tabIndex={-1} dataTest={`${dataTest}__close-button`} buttonType={ButtonType.ICON} type="button" onClick={closeButton}>
              <Svg name={SvgNames.close} type={SvgType.VERY_LARGE_ICON} />
            </Button>
          }
        >
          {t('Close')}
        </Tooltip>
      )}
    </>
  )
}

export default ModalHeader
