import { gql } from '@apollo/client'

export const lists = gql`
  fragment List on List {
    id
    position
    name
    description
    baseId
    isList
    size
    tsModified
    isFavorite
    isSforce
    isSugar
    isNsuite
    isMSDyn
    isSL
    isCollapsed
    isTest
    isCrmLeadContact
    isForwarded
    isShared
    isPurchasedData
    isPurchasedProspector
    isFormSubmission
    isWebinar
    isImported
    isSubscription
    isExtension
    isSegment
    isUploaded
    isCrm
    isSmsCompatible
  }
`

export const allListsChildren = gql`
  ${lists}

  fragment AllListsChildren on ListInfo {
    ...List
    children {
      ...List
      children {
        ...List
        children {
          ...List
          children {
            ...List
            children {
              ...List
              children {
                ...List
                children {
                  ...List
                  children {
                    ...List
                    children {
                      ...List
                      children {
                        ...List
                        children {
                          ...List
                          children {
                            ...List
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const folderLists = gql`
  ${allListsChildren}

  fragment FolderListsFragment on FolderListInfo {
    id
    name
    entries {
      ...AllListsChildren
    }
  }
`
