import React, { FC, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useApolloClient, useMutation } from '@apollo/client'
import { useImageLibraryFolders } from '@components/AssetPickers/ImagePickerModal/graphQL/ImagePickerModal.graphQL'
import { ImagePickerModalInsertCallback, ImagePickerSource } from '@components/AssetPickers/ImagePickerModal/utils/ImagePickerModal.utils'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import uploadImage from '@graphql/mutations/uploadImage'
import { UploadImageMutation, UploadImageMutationVariables } from '@graphql/types/mutation-types'
import { filterNotEmptyArray } from '@utils/array'
import { StatusToastType } from '@utils/interface/StatusToast'

import ImagePickerWithActionsModal, { ImageToInsertParams } from './ImagePickerWithActionsModal'
import { getSourceMetadataFromImageURl, imageUrlToBase64, InsertImageFlow } from './utils/ImagePickerWithActionsModal.utils'

import './ImagePickerWithActionsModal.css'

export interface ImagePickerWithActionsModalContainerProps {
  handleImagePickerDone: ImagePickerModalInsertCallback
  imageUrlToReplace?: string
  hideUploadFromURL?: boolean
  isStory?: boolean
  accept?: string
  imageMaxSize?: number
  allowSvg?: boolean
}

const getInitialFlow = (source?: ImagePickerSource) => {
  switch (source) {
    case ImagePickerSource.EXTERNAL:
      return InsertImageFlow.URL
    default:
      return InsertImageFlow.BROWSE
  }
}

const ImagePickerWithActionsModalContainer: FC<ImagePickerWithActionsModalContainerProps> = ({
  handleImagePickerDone,
  imageUrlToReplace,
  hideUploadFromURL,
  isStory,
  accept,
  imageMaxSize,
  allowSvg,
}) => {
  const { t } = useTranslation()
  const client = useApolloClient()

  const isUploadFlowRef = useRef(false)
  const isUrlFlowRef = useRef(false)
  const isLogosOptionSelectedRef = useRef(false)
  const localImageUrlToReplace = useRef('')

  const { folders } = useImageLibraryFolders()

  const [isInsertButtonEnabled, setIsInsertButtonEnabled] = useState(false)
  const { source, url: urlToReplace } = getSourceMetadataFromImageURl(
    localImageUrlToReplace.current ? localImageUrlToReplace.current : imageUrlToReplace
  )

  const initialFlow: InsertImageFlow = getInitialFlow(source)
  const initialFlowIsURl = initialFlow === InsertImageFlow.URL
  const [toast, setToastStatus] = useState<StatusToastType>()
  const [imageParams, setImageParams] = useState<ImageToInsertParams>({ url: initialFlowIsURl ? urlToReplace : undefined })
  const [flow, setFlow] = useState<InsertImageFlow>(initialFlow)

  const isUploadFlow = flow === InsertImageFlow.UPLOAD
  const isURLFlow = flow === InsertImageFlow.URL
  const isBrowseFlow = flow === InsertImageFlow.BROWSE
  const showInsertButtonEnabled = isUploadFlowRef.current || isInsertButtonEnabled || isUrlFlowRef.current

  const [uploadImageMutation, { loading }] = useMutation<UploadImageMutation, UploadImageMutationVariables>(uploadImage, {
    client,
    fetchPolicy: 'no-cache',
  })

  const onUploadImage = useCallback(
    (variables: UploadImageMutationVariables) =>
      uploadImageMutation({ variables }).then(({ data }) => {
        if (!data) {
          return Promise.reject()
        }
        return data
      }),
    [uploadImageMutation]
  )

  const folderOptions: SelectV2SingleOption[] = folders
    .map((folder) =>
      folder?.name ? { label: folder.name, value: typeof folder.folderId === 'number' ? folder.folderId.toString() : folder.name } : undefined
    )
    .filter(filterNotEmptyArray)

  const handleInsertFromPicker = useCallback<ImagePickerModalInsertCallback>(
    (data, isLogos) => {
      isLogosOptionSelectedRef.current = !!isLogos
      setIsInsertButtonEnabled(false)

      if (data) {
        handleImagePickerDone(data)
      } else {
        handleImagePickerDone(undefined)
      }
    },
    [handleImagePickerDone]
  )

  const handleInsert = useCallback(async () => {
    const { validUrl, imageType, imageBase64 } = imageParams
    setIsInsertButtonEnabled(false)
    if (!!(isURLFlow && validUrl)) {
      const base64 = await imageUrlToBase64(validUrl)
      const type = new URL(validUrl).pathname.split('.').pop()
      onUploadImage({ base64File: base64 as string, extension: type, folderName: '', fileName: '' }).then((data) =>
        handleImagePickerDone({ url: data.uploadImage.previewUrl, id: data.uploadImage.id, title: data.uploadImage.title })
      )
    } else if (imageBase64 && imageType) {
      onUploadImage({ base64File: imageBase64 as string, extension: imageType, folderName: '', fileName: '' }).then((data) =>
        handleImagePickerDone({ url: data.uploadImage.previewUrl, id: data.uploadImage.id, title: data.uploadImage.title })
      )
    }
  }, [imageParams, isURLFlow, handleImagePickerDone, onUploadImage, t])

  return (
    <ImagePickerWithActionsModal
      toast={toast}
      loading={loading}
      folders={folderOptions}
      imageParams={imageParams}
      isBrowseFlow={isBrowseFlow}
      isURLFlow={isURLFlow}
      isUploadFlow={isUploadFlow}
      isStory={isStory}
      setFlow={setFlow}
      handleInsert={handleInsert}
      setToastStatus={setToastStatus}
      setImageParams={setImageParams}
      handleImagePickerDone={handleImagePickerDone}
      handleInsertFromPicker={handleInsertFromPicker}
      showInsertButtonEnabled={showInsertButtonEnabled}
      hideUploadFromURL={hideUploadFromURL}
      imageUrlToReplace={localImageUrlToReplace.current ? localImageUrlToReplace.current : imageUrlToReplace}
      accept={accept}
      imageMaxSize={imageMaxSize}
      allowSvg={allowSvg}
    />
  )
}

export default ImagePickerWithActionsModalContainer
