import React, { FC, ReactNode, useMemo } from 'react'

import Checkbox from '@components/Checkbox'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import ProgramStepIcon from '@src/pages/programs/edit/components/ProgramFlow/components/ProgramStepIcon'
import { ChangeFieldValueUpgradeStep } from '@src/pages/programs/upgradeManager/components/steps/ChangeFieldValueUpgradeStep/ChangeFieldValueUpgradeStep'
import { CopyToListUpgradeStep } from '@src/pages/programs/upgradeManager/components/steps/CopyToListUpgradeStep/CopyToListUpgradeStep'
import { SendEmailMessageUpgradeStep } from '@src/pages/programs/upgradeManager/components/steps/SendEmailMessageUpgradeStep/SendEmailMessageUpgradeStep'
import { SourceUpgradeStep } from '@src/pages/programs/upgradeManager/components/steps/SourceUpgradeStep/SourceUpgradeStep'
import { UpgradedProgramStep } from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'
import { StepToUpgradeType } from '@src/pages/programs/upgradeManager/helper/UpgradeManager.helper'
import { ProgramStepType } from '@utils/program/program.constants'

import './CurrentStep.css'

interface CurrentStepProps {
  children?: ReactNode
  currentStep?: UpgradedProgramStep
  upgradedCurrentStep?: UpgradedProgramStep
  onChange: (removed: boolean) => void
}

const rootClass = 'current-step'
const rootText = 'AutomatedPrograms.UpgradeManager.CurrentStep.'

export const CurrentStep: FC<CurrentStepProps> = (props: CurrentStepProps) => {
  const { children, currentStep, upgradedCurrentStep, onChange } = props

  const { t } = useTranslation()

  const steps: { [key in StepToUpgradeType]: () => ReactNode } = useMemo(
    () => ({
      [ProgramStepType.FIELD_SET]: () => <ChangeFieldValueUpgradeStep key={currentStep?.stepId} />,
      [ProgramStepType.COPY]: () => <CopyToListUpgradeStep key={currentStep?.stepId} />,
      [ProgramStepType.SEND]: () => <SendEmailMessageUpgradeStep key={currentStep?.stepId} />,
      [ProgramStepType.BRANCH]: () => <SourceUpgradeStep key={currentStep?.stepId} />,
      [ProgramStepType.WAIT_UNTIL_IN_SEGMENT]: () => <SourceUpgradeStep key={currentStep?.stepId} />,
    }),
    [currentStep?.stepId]
  )

  return (
    <div className={rootClass}>
      <div className={`${rootClass}__header`}>
        <div className={`${rootClass}__step-container`}>
          <Typography text={t(`${rootText}Title`)} type={TextType.BODY_TEXT} weight={TextWeight.MEDIUM} lineHeight={LineHeight.MEDIUM} />
          <div className={`${rootClass}__step`}>
            <ProgramStepIcon className={`${rootClass}__step-icon`} stepType={currentStep?.stepType as ProgramStepType} />
            <div className={`${rootClass}__step-info`}>
              <Typography
                text={currentStep?.letter}
                type={TextType.BODY_TEXT_SMALL_LIGHT}
                weight={TextWeight.MEDIUM}
                lineHeight={LineHeight.MEDIUM_SMALL}
              />
              <Typography text={currentStep?.displayName} type={TextType.BODY_TEXT} weight={TextWeight.MEDIUM} lineHeight={LineHeight.MEDIUM} />
            </div>
          </div>
        </div>
        {children}
      </div>
      {steps[currentStep?.stepType as StepToUpgradeType]?.()}
      <Checkbox label={t(`${rootText}Remove.Label`)} onChange={onChange} checked={upgradedCurrentStep?.removed} />
    </div>
  )
}
