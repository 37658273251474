import React, { FC, useContext, useEffect, useMemo, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import restartAbTest from '@graphql/mutations/restartAbTest'
import setAbWinner from '@graphql/mutations/setAbWinner'
import {
  RestartAbTestMutation,
  RestartAbTestMutationVariables,
  SetAbWinnerMutation,
  SetAbWinnerMutationVariables,
} from '@graphql/types/mutation-types'
import { ProgramStepCounts, ProgramTrack } from '@graphql/types/query-types'
import ProgramSteps from '@src/pages/programs/dashboard/components/ProgramSteps/ProgramSteps'
import { getSortedProgramSteps } from '@src/pages/programs/dashboard/components/ProgramSteps/ProgramSteps.utils'
import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'
import { ProgramStepType } from '@utils/program/program.constants'
import { ProgramStep, ProgramWithStepData, StepData } from '@utils/program/ProgramSteps.constants'

export interface ExpandableSteps {
  [key: string]: boolean
}

export interface AbToastPopup {
  setWinnerSuccess: boolean
  setWinnerFail: boolean
  showWinnerToast: boolean
  resetTestSuccess: boolean
  resetTestFail: boolean
  showResetTestPop: boolean
  showResetAbTestToast: boolean
  programId: string
  stepId: string
  abWinner: string
}

const DEFAULT_ABTOASTPOPUP_STATE: AbToastPopup = {
  setWinnerSuccess: false,
  setWinnerFail: false,
  showWinnerToast: false,
  resetTestSuccess: false,
  resetTestFail: false,
  showResetTestPop: false,
  showResetAbTestToast: false,
  programId: '',
  stepId: '',
  abWinner: '',
}

const getProgramStepData = (tracks: ProgramTrack[], programStepCounts: ProgramStepCounts[]): StepData[] => {
  return tracks.flatMap((track) => {
    return track.steps.map((step) => {
      const stepCountsForStep = programStepCounts.find((programStepCount) => programStepCount.stepId === step.stepId)
      const exitedSteps = (stepCountsForStep?.exitCount ?? 0) + (stepCountsForStep?.deletedStepCount ?? 0)
      return {
        ...step,
        stepId: step.stepId ?? '1',
        completed: stepCountsForStep?.completedStepCount ?? 0,
        waiting: stepCountsForStep?.inStepCount ?? 0,
        exited: exitedSteps,
      }
    })
  })
}

interface ProgramStepsContainerProps {
  isShownInManager?: boolean
  tabChange?: (tab: string) => void
}

const ProgramStepsContainer: FC<ProgramStepsContainerProps> = ({ isShownInManager, tabChange }: ProgramStepsContainerProps) => {
  const {
    values: { program, programCounts, programErrors, programUrlId },
    moveNext,
    refreshProgram,
  } = useContext(ProgramManagerContext)

  const programWithStepData: ProgramWithStepData = useMemo(() => {
    const { tracks } = program
    let programStepCounts = programCounts.programStepCounts
    if (isShownInManager && program.tracks.length > 0) {
      if (program.tracks[0].steps[0].stepType !== ProgramStepType.START) {
        const startStep = {
          stepType: ProgramStepType.START,
          displayName: 'Start',
          stepId: 'start1',
          letter: '',
          depth: 0,
          description: '',
        }

        tracks[0].steps = [startStep, ...tracks[0].steps]
      }

      programStepCounts = [
        {
          __typename: 'ProgramStepCounts',
          stepId: 'start1',
          completedStepCount: programCounts.programCounts.enteredContacts,
          inStepCount: programCounts.pendingSize,
          exitCount: programCounts.totalSuppressions,
          deletedStepCount: 0,
        },
        ...programStepCounts,
      ]
    }
    return {
      ...program,
      stepData: getProgramStepData(tracks, programStepCounts),
    }
  }, [program, programCounts])

  const setABWinner = (programId: string, stepId: string, msgId: string, name: string) => {
    client
      .mutate<SetAbWinnerMutation, SetAbWinnerMutationVariables>({
        mutation: setAbWinner,
        variables: {
          programId: programId,
          stepId: stepId,
          msgId: msgId,
        },
      })
      .then(() => {
        setAbToastPopupState({ ...DEFAULT_ABTOASTPOPUP_STATE, setWinnerSuccess: true, showWinnerToast: true, abWinner: name })
        refreshProgram()
      })
  }

  const resetAbTest = (programId: string, stepId: string) => {
    setAbToastPopupState({ ...DEFAULT_ABTOASTPOPUP_STATE, showResetTestPop: true, programId: programId, stepId: stepId })
  }

  const steps = useMemo(() => {
    return getSortedProgramSteps(programWithStepData, programErrors, moveNext, {}, setABWinner, resetAbTest)
  }, [programWithStepData, programErrors])

  const [expansions, setExpansions] = useState(
    steps.reduce((acc: { [key: string]: boolean }, step) => ({ ...acc, [step.stepId]: false }), {} as ExpandableSteps)
  )
  const [initialized, setInitialized] = useState(false)
  const [localSteps, setLocalSteps] = useState<ProgramStep[]>(steps)
  const client = useApolloClient()
  const [abToastPopupState, setAbToastPopupState] = useState<AbToastPopup>(DEFAULT_ABTOASTPOPUP_STATE)

  const updateLocalSteps = () => {
    const updatedSteps = getSortedProgramSteps(programWithStepData, programErrors, moveNext, expansions, setABWinner, resetAbTest)
    setLocalSteps(updatedSteps)
  }

  useEffect(() => {
    if (initialized) {
      updateLocalSteps()
    } else {
      setInitialized(true)
    }
  }, [initialized])

  useEffect(() => {
    updateLocalSteps()
  }, [expansions, program.runStatus.isRunning, steps])

  const closeToast = () => setAbToastPopupState({ ...DEFAULT_ABTOASTPOPUP_STATE, showWinnerToast: false, showResetAbTestToast: false })

  const closeModal = (reset: boolean) => {
    if (reset) {
      client
        .mutate<RestartAbTestMutation, RestartAbTestMutationVariables>({
          mutation: restartAbTest,
          variables: {
            programId: abToastPopupState.programId,
            stepId: abToastPopupState.stepId,
          },
        })
        .then(() => {
          setAbToastPopupState({ ...DEFAULT_ABTOASTPOPUP_STATE, showResetAbTestToast: true })
          refreshProgram()
        })
    }

    setAbToastPopupState({ ...DEFAULT_ABTOASTPOPUP_STATE })
  }
  const handleExpandCollapseAll = (isExpanded: boolean) => {
    const updatedExpansions = {} as ExpandableSteps
    Object.entries(expansions).forEach(([key]) => {
      updatedExpansions[key] = isExpanded
    })
    setExpansions(updatedExpansions)
  }

  return (
    <ProgramSteps
      steps={localSteps}
      lastUpdated={program.lastUpdated}
      programId={programUrlId}
      tabChange={tabChange}
      isShownInManager={isShownInManager}
      onRefresh={refreshProgram}
      handleExpandCollapseAll={handleExpandCollapseAll}
      showAbWinnerToast={abToastPopupState.showWinnerToast}
      showResetAbTestToast={abToastPopupState.showResetAbTestToast}
      abWinner={abToastPopupState.abWinner}
      closeToast={closeToast}
      showResetAbTestPop={abToastPopupState.showResetTestPop}
      closeModal={closeModal}
    />
  )
}

export default ProgramStepsContainer
