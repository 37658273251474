import React, { FC } from 'react'

import classNames from 'classnames'

import { ApolloError } from '@apollo/client'
import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { useTranslation } from '@const/globals'
import { EmptyState } from '@utils/program/program.constants'

import './ProgramEmptyChart.css'

interface Props {
  error?: ApolloError
  className?: string
  dataTest?: string
  emptyState: EmptyState
}

const rootClass = 'program-empty-chart'

const ProgramEmptyChart: FC<Props> = (props: Props) => {
  const { error, className, dataTest = rootClass, emptyState } = props
  const { t } = useTranslation()

  const renderError = () => {
    return (
      <EmptyListing
        headline={`${t(`Program contacts could not be retrieved at this time`)}`}
        imgSrc={StaticImageNames.errorStateAllContacts}
        text={t(`Engineering has been alerted.`)}
      />
    )
  }

  const renderEmptyChart = () => {
    return (
      <EmptyListing
        headline={emptyState.headline}
        imgSrc={emptyState.imgSrc}
        size={EmptyListingSize.MEDIUM}
        text={emptyState.text}
        linkText={emptyState.linkText}
        link={emptyState.link}
        hideIcon={false}
        className={`${rootClass}__empty-listing`}
      />
    )
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {error ? renderError() : renderEmptyChart()}
    </div>
  )
}

export default ProgramEmptyChart
