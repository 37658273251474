import { gql } from '@apollo/client'

export default gql`
  query getSubTypesByTypes($types: [String], $subTypes: [String]) {
    getSubTypesByTypes(types: $types, subTypes: $subTypes) {
      count
      itemType
      name
    }
  }
`
