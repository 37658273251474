import React, { FC, useEffect, useRef, useState } from 'react'

import Liveboard from '@src/pages/reports/Liveboard/Liveboard'
import { RuntimeFilter } from '@thoughtspot/visual-embed-sdk/lib/src/react/all-types-export'
import { Action } from '@thoughtspot/visual-embed-sdk/react'
import { useAccountSettings } from '@utils/account/account.utils'
import { tsInitializer, useThoughtSpotService } from '@utils/hooks/microservices/useThoughtspotService'
import useUserSettings from '@utils/hooks/useUserSettings'

interface LiveboardProps {
  title: string
  liveboardId?: string
  visibleActions?: Action[]
  headerExtra?: React.ReactNode
  runtimeFilters?: RuntimeFilter[]
  previewAction?: (id: string, title: string) => void
  queryError?: string
  loading: boolean
  className?: string
  dataTest?: string
}

interface LiveboardState {
  hideAnalyticsUpsell: boolean
}

const LiveboardContainer: FC<LiveboardProps> = (props: LiveboardProps) => {
  const { title, liveboardId, visibleActions, previewAction, headerExtra, runtimeFilters, queryError, loading, className, dataTest } = props
  const { userEmail, hasAdvancedAnalytics } = useAccountSettings()
  const initialized = useRef<boolean>(false)
  const { tsAuth, thoughtSpotHost } = useThoughtSpotService(userEmail)
  const [state, setState] = useState<LiveboardState>({
    hideAnalyticsUpsell: hasAdvancedAnalytics,
  })
  const { userSettings, setUserSetting } = useUserSettings()

  useEffect(() => {
    if (!initialized.current && userEmail) {
      tsInitializer(thoughtSpotHost, userEmail, tsAuth)
      initialized.current = true
    }
  }, [tsAuth, userEmail])

  useEffect(() => {
    if (userSettings) {
      setState((prevState) => ({ ...prevState, hideAnalyticsUpsell: userSettings.hideAnalyticsUpsell || hasAdvancedAnalytics }))
    }
  }, [hasAdvancedAnalytics, userSettings])

  const onDismiss = () => setUserSetting('hideAnalyticsUpsell', true)

  return (
    <Liveboard
      title={title}
      visibleActions={visibleActions}
      previewAction={previewAction}
      headerExtra={headerExtra}
      runtimeFilters={runtimeFilters}
      loading={loading}
      queryError={queryError}
      liveboardId={liveboardId}
      hideAnalyticsUpsell={state.hideAnalyticsUpsell}
      onDismiss={onDismiss}
      className={className}
      dataTest={dataTest}
    />
  )
}

export default LiveboardContainer
