import { gql } from '@apollo/client'

export default gql`
  query getRevenueImpactOpportunities($endDate: String, $timeZoneId: String, $startDate: String) {
    getRevenueImpactOpportunities(endDate: $endDate, timeZoneId: $timeZoneId, startDate: $startDate) {
      allOpportunities {
        count
        revenue
        topCampaign
        topMonth
        topSource
        trend
      }
      newOpportunities {
        analytics {
          count
          revenue
          topCampaign
          topMonth
          topSource
          trend
        }
        byCampaign {
          count
          name
          revenue
        }
        byDate {
          count
          date
          revenue
        }
        bySource {
          count
          name
          revenue
        }
      }
      wonOpportunities {
        analytics {
          count
          revenue
          topCampaign
          topMonth
          topSource
          trend
        }
        byCampaign {
          count
          name
          revenue
        }
        byDate {
          count
          date
          revenue
        }
        bySource {
          count
          name
          revenue
        }
      }
    }
  }
`
