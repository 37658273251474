import { ReactNode } from 'react'

import { ProgramBaseStep } from '@graphql/types/mutation-types'
import { Program } from '@graphql/types/query-types'

export interface StepData {
  stepId: string
  completed: number
  exited: number
  stepType: string
  displayName: string
  waiting: number
}

export interface ProgramStep extends StepData {
  description: ReactNode
  depth: number
  goToStepId?: string
  letter?: string
}

export interface ProgramWithStepData extends Program {
  stepData: StepData[]
}

export type ProgramBaseStepWithStepNum = ProgramBaseStep & ProgramStep & { stepNumber?: string }

export const timeZoneNames = [
  {
    id: 'Etc/GMT+12',
    name: 'GMT-12:00 Etc/GMT+12',
  },
  {
    id: 'US/Samoa',
    name: 'GMT-11:00 US/Samoa',
  },
  {
    id: 'US/Hawaii',
    name: 'GMT-10:00 US/Hawaii',
  },
  {
    id: 'Pacific/Marquesas',
    name: 'GMT-09:30 Pacific/Marquesas',
  },
  {
    id: 'US/Alaska',
    name: 'GMT-09:00 US/Alaska',
  },
  {
    id: 'US/Pacific',
    name: 'GMT-08:00 US/Pacific',
  },
  {
    id: 'US/Arizona',
    name: 'GMT-07:00 US/Arizona',
  },
  {
    id: 'US/Mountain',
    name: 'GMT-07:00 US/Mountain',
  },
  {
    id: 'US/Central',
    name: 'GMT-06:00 US/Central',
  },
  {
    id: 'US/Eastern',
    name: 'GMT-05:00 US/Eastern',
  },
  {
    id: 'America/Caracas',
    name: 'GMT-04:30 America/Caracas',
  },
  {
    id: 'Brazil/West',
    name: 'GMT-04:00 Brazil/West',
  },
  {
    id: 'Canada/Atlantic',
    name: 'GMT-04:00 Canada/Atlantic',
  },
  {
    id: 'Canada/Newfoundland',
    name: 'GMT-03:30 Canada/Newfoundland',
  },
  {
    id: 'Canada/Saskatchewan',
    name: 'GMT-06:00 Canada/Saskatchewan',
  },
  {
    id: 'Chile/Continental',
    name: 'GMT-03:00 Chile/Continental',
  },
  {
    id: 'America/Sao_Paulo',
    name: 'GMT-02:00 America/Sao_Paulo',
  },
  {
    id: 'Brazil/DeNoronha',
    name: 'GMT-02:00 Brazil/DeNoronha',
  },
  {
    id: 'Atlantic/Cape_Verde',
    name: 'GMT-01:00 Atlantic/Cape_Verde',
  },
  {
    id: 'Europe/London',
    name: 'GMT+00:00 Europe/London',
  },
  {
    id: 'Zulu',
    name: 'GMT+00:00 Zulu',
  },
  {
    id: 'Europe/Berlin',
    name: 'GMT+01:00 Europe/Berlin',
  },
  {
    id: 'Israel',
    name: 'GMT+02:00 Israel',
  },
  {
    id: 'Asia/Baghdad',
    name: 'GMT+03:00 Asia/Baghdad',
  },
  {
    id: 'Europe/Helsinki',
    name: 'GMT+02:00 Europe/Helsinki',
  },
  {
    id: 'Europe/Kaliningrad',
    name: 'GMT+02:00 Europe/Kaliningrad',
  },
  {
    id: 'Iran',
    name: 'GMT+03:30 Iran',
  },
  {
    id: 'Europe/Moscow',
    name: 'GMT+03:00 Europe/Moscow',
  },
  {
    id: 'Asia/Dubai',
    name: 'GMT+04:00 Asia/Dubai',
  },
  {
    id: 'Asia/Kabul',
    name: 'GMT+04:30 Asia/Kabul',
  },
  {
    id: 'Asia/Karachi',
    name: 'GMT+05:00 Asia/Karachi',
  },
  {
    id: 'IST',
    name: 'GMT+05:30 IST',
  },
  {
    id: 'Asia/Katmandu',
    name: 'GMT+05:45 Asia/Katmandu',
  },
  {
    id: 'Asia/Dacca',
    name: 'GMT+06:00 Asia/Dacca',
  },
  {
    id: 'Asia/Rangoon',
    name: 'GMT+06:30 Asia/Rangoon',
  },
  {
    id: 'Asia/Bangkok',
    name: 'GMT+07:00 Asia/Bangkok',
  },
  {
    id: 'Asia/Kuala_Lumpur',
    name: 'GMT+08:00 Asia/Kuala_Lumpur',
  },
  {
    id: 'Asia/Singapore',
    name: 'GMT+08:00 Asia/Singapore',
  },
  {
    id: 'Australia/West',
    name: 'GMT+08:00 Australia/West',
  },
  {
    id: 'PRC',
    name: 'GMT+08:00 PRC',
  },
  {
    id: 'Australia/Eucla',
    name: 'GMT+08:45 Australia/Eucla',
  },
  {
    id: 'Japan',
    name: 'GMT+09:00 Japan',
  },
  {
    id: 'ROK',
    name: 'GMT+09:00 ROK',
  },
  {
    id: 'Australia/Darwin',
    name: 'GMT+09:30 Australia/Darwin',
  },
  {
    id: 'Australia/Brisbane',
    name: 'GMT+10:00 Australia/Brisbane',
  },
  {
    id: 'Pacific/Guam',
    name: 'GMT+10:00 Pacific/Guam',
  },
  {
    id: 'Australia/Adelaide',
    name: 'GMT+10:30 Australia/Adelaide',
  },
  {
    id: 'Australia/Melbourne',
    name: 'GMT+11:00 Australia/Melbourne',
  },
  {
    id: 'Pacific/Norfolk',
    name: 'GMT+11:30 Pacific/Norfolk',
  },
  {
    id: 'Kwajalein',
    name: 'GMT+12:00 Kwajalein',
  },
  {
    id: 'Pacific/Auckland',
    name: 'GMT+13:00 Pacific/Auckland',
  },
  {
    id: 'Pacific/Fiji',
    name: 'GMT+13:00 Pacific/Fiji',
  },
  {
    id: 'Pacific/Chatham',
    name: 'GMT+13:45 Pacific/Chatham',
  },
  {
    id: 'Pacific/Kiritimati',
    name: 'GMT+14:00 Pacific/Kiritimati',
  },
]
