import * as React from 'react'
import { I18nextProvider } from 'react-i18next'

import i18n from 'i18n'

import App from './App'

export const AppLocal = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <App i18n={i18n} />
    </I18nextProvider>
  )
}

export default AppLocal
