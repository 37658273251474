import { gql } from '@apollo/client'

export default gql`
  query getCountQuery($folderSubTypes: [String], $field: String, $values: [String], $subTypes: [String], $type: String) {
    getCountQuery(folderSubTypes: $folderSubTypes, field: $field, values: $values, subTypes: $subTypes, type: $type) {
      count
      field
      folderSubType
      itemType
      subType
      value
    }
  }
`
