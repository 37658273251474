import React from 'react'

import classNames from 'classnames'

import { getStickyColsWidths, isColumnIdEqual } from '@components/TableV2/utils/tableV2ColumnUtils'
import Typography from '@components/Typography/Typography'
import { defaultColumnSizing, flexRender, Table } from '@tanstack/react-table'

import { ColumnDefWithAdditionalProps } from '../../tableV2TS/types'
import { getCellStyles } from '../../utils/tableV2Utils'

import './tfoot.css'

interface TFoot<T> {
  table: Table<T>
  stickyColumns?: string[]
}

const rootClass = 'tableV2-tfoot'

export const TFoot = <T extends {}>({ table, stickyColumns }: TFoot<T>) => {
  const stickyColsWidths = stickyColumns && getStickyColsWidths(stickyColumns.length)

  return (
    <tfoot className={rootClass}>
      {table.getFooterGroups().map((footerGroup) => {
        return (
          <tr key={footerGroup.id} className={`${rootClass}__row`}>
            {footerGroup.headers.map((header, i) => {
              const { column } = header
              const { columnDef }: { columnDef: ColumnDefWithAdditionalProps<T> } = column
              const cellRadio = header.id === 'cellRadio'
              const cellCheckbox = header.id === 'cellCheckbox'
              const { maxSize, minSize } = defaultColumnSizing
              const { maxSize: maxWidth, minSize: minWidth } = columnDef
              const headerValue = flexRender(header.column.columnDef.footer, header.getContext())
              const cellStyles = getCellStyles(i, columnDef, maxWidth, maxSize, minWidth, minSize, stickyColumns, stickyColsWidths)

              return (
                <th
                  data-sticky
                  key={header.id}
                  className={classNames(`${rootClass}__th`, {
                    [`${rootClass}__radio`]: cellRadio,
                    [`${rootClass}__checkbox`]: cellCheckbox,
                    [`${rootClass}__sticky-column`]: !!stickyColumns?.length && isColumnIdEqual(stickyColumns, header),
                  })}
                  style={cellStyles}
                >
                  <Typography text={header.isPlaceholder ? null : headerValue} key={header.id} />
                </th>
              )
            })}
          </tr>
        )
      })}
    </tfoot>
  )
}
