import { gql } from '@apollo/client'

export default gql`
  mutation createActOnCampaign($name: String!, $description: String) {
    createActOnCampaign(name: $name, description: $description) {
      id
      name
      description
    }
  }
`
