import React, { ChangeEvent, FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import { AltViewOption } from '@components/ReportHeader/ReportHeader'
import Select from '@components/Select/Select'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Typography, { TextType } from '@components/Typography/Typography'

import './TimespanPicker.css'

type Props = {
  className?: string
  dataTest?: string
  startTime: string
  endTime?: string
  handleChange: (directionClicked: DirectionOptions) => void
  nextDisabled?: boolean
  prevDisabled?: boolean
  altView?: boolean
  altViewOptions?: AltViewOption[]
  altViewDropdownChange?: (event: ChangeEvent<HTMLSelectElement>) => void
}

export enum DirectionOptions {
  DEFAULT = 'none',
  NEXT = '+',
  PREV = '-',
}

const rootClass = 'timespan-picker'

const TimespanPicker: FC<Props> = (props: Props) => {
  const {
    dataTest = rootClass,
    className = '',
    startTime,
    endTime,
    nextDisabled = false,
    prevDisabled = false,
    handleChange,
    altView = false,
    altViewOptions,
    altViewDropdownChange,
  } = props

  return (
    <div className={classNames(rootClass, className)} data-testid={dataTest} data-test={dataTest}>
      {altView && (
        <Select
          dataTest={`${dataTest}-select`}
          onChange={altViewDropdownChange}
          value={altViewOptions ? (altViewOptions[0].selected ? altViewOptions[0].value : altViewOptions[1].value) : undefined}
          className={`${rootClass}`}
        >
          {altViewOptions &&
            altViewOptions.map((option) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
        </Select>
      )}
      <div className={classNames(altView ? `${rootClass}__wrapper_alt` : `${rootClass}__wrapper`)}>
        <Button
          buttonType={ButtonType.TEXT}
          iconPosition={ButtonIconPosition.FLOAT}
          onClick={() => {
            handleChange(DirectionOptions.PREV)
          }}
          dataTest={`${dataTest}-previous`}
          disabled={prevDisabled}
        >
          <Svg name={SvgNames.chevronLeft} type={SvgType.LARGER_ICON} />
        </Button>
        <Typography
          className={classNames(`${rootClass}__date__range`)}
          type={TextType.BODY_TEXT_SMALL}
          text={`${startTime} ${endTime ? `- ${endTime}` : ``}`}
        />
        <Button
          buttonType={ButtonType.TEXT}
          iconPosition={ButtonIconPosition.FLOAT}
          onClick={() => {
            handleChange(DirectionOptions.NEXT)
          }}
          dataTest={`${dataTest}-next`}
          disabled={nextDisabled}
        >
          <Svg name={SvgNames.chevronRight} type={SvgType.LARGER_ICON} />
        </Button>
      </div>
    </div>
  )
}

export default TimespanPicker
