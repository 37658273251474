import React, { FC } from 'react'

import { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { MIN_PAGINATION_SIZE } from '@components/TableV2/components/Pagination/TableV2Pagination'
import { TableV2 } from '@components/TableV2/TableV2'
import { useTranslation } from '@const/globals'
import { OptStatus } from '@graphql/types/microservice/sms-management-types'
import { SortByState } from '@utils/sms.utils'

import { smsOptOutColumns } from './SMSOptoutTable.utils'

interface SMSOptOutListingProps {
  totalCount: number
  pageSize: number | 10
  pageIndex: number | 0
  sortBy: SortByState[]
  entries: OptStatus[]
  loading: boolean
  fetchData: (pageIndex: number, pageSize: number) => void
  onChangeSort: (sortBy: SortByState[]) => void
  dataTest?: string
}

type SMSOptOutListingAllProps = SMSOptOutListingProps

export const SMSOptOutTable: FC<SMSOptOutListingAllProps> = (props: SMSOptOutListingAllProps) => {
  const { loading, entries, totalCount, pageSize, pageIndex, sortBy, fetchData, onChangeSort } = props
  const pageCount = Math.ceil(totalCount / pageSize)

  const { t } = useTranslation()

  return (
    <TableV2
      loading={loading}
      data={entries}
      columns={smsOptOutColumns}
      enablePaginate={totalCount > MIN_PAGINATION_SIZE}
      fetchData={fetchData}
      sortingBy={sortBy}
      onSort={onChangeSort}
      manualSorting={false}
      paginationState={{
        pageSize,
        pageIndex,
        controlledPageCount: pageCount,
      }}
      emptyState={{
        imgSrc: StaticImageNames.smsOptOutEmpty,
        size: EmptyListingSize.MEDIUM,
        headline: t('No phone numbers have been opted out yet'),
        withoutBorder: true,
      }}
    />
  )
}
