import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import './Grid.css'

interface Props {
  size?: number
  children?: ReactNode
  row?: boolean
  className?: string
  dataTest?: string
}

const rootClass = 'grid'

const Grid: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, row, className = '', children, size } = props

  const gridSize = size ? size : 100

  return (
    <div
      className={classNames(rootClass, className, {
        [`${rootClass}__row`]: row,
        [`${rootClass}__column`]: !row,
        [`${rootClass}__column--${gridSize}`]: !row,
      })}
      data-test={dataTest}
    >
      {row ? <>{children}</> : <div className={`${rootClass}__cell`}>{children}</div>}
    </div>
  )
}

export default Grid
