import { gql } from '@apollo/client'

export default gql`
  mutation setSalesUserPriv($changableUserId: String, $priv: String, $optionOrUserId: String) {
    setSalesUserPriv(changableUserId: $changableUserId, priv: $priv, optionOrUserId: $optionOrUserId) {
      status
      message
      count
      deletedUsersIds
      newUsersIds
      importedUsers
      noImportedUsers
      duplicatedUsers
    }
  }
`
