import React, { FC, useCallback, useEffect, useState } from 'react'

import classNames from 'classnames'
import equal from 'fast-deep-equal/es6'

import Button, { ButtonType } from '@components/Button'
import { DropDownContent, DropDownMenuRoot, DropDownTriggerItem } from '@components/DropDown'
import { SortDropDownOption } from '@components/SortDropDown/sortDropDown.types'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ColumnSort } from '@tanstack/react-table'

import './SortDropDown.css'

export interface SortDropDownProps {
  options: SortDropDownOption[]
  onSelect: (selectedSort: ColumnSort) => void
  selectedOption: ColumnSort
  label?: string
  className?: string
  dataTest?: string
}

const rootClass = 'sort-drop-down'

const SortDropDown: FC<SortDropDownProps> = (props) => {
  const { t } = useTranslation()
  const { options, onSelect, selectedOption, label = t('Sort'), className, dataTest = rootClass } = props

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedId, setSelectedId] = useState<string>(selectedOption.id)
  const currentSortOption = options.find(({ id }) => id == selectedId)

  const onlySortColumn = options.length === 1
  const buttonLabel = t(
    onlySortColumn ? options[0][selectedOption.desc ? 'descLabel' : 'ascLabel'] : options.find(({ id }) => id == selectedOption.id)?.label ?? 'Select'
  )

  useEffect(() => {
    if (!isOpen) {
      setSelectedId(selectedOption.id)
    }
  }, [isOpen, selectedOption])

  const handleSelect = useCallback(
    (sortOption: ColumnSort) => {
      if (!equal(selectedOption, sortOption)) {
        onSelect(sortOption)
      }
      setIsOpen(false)
    },
    [onSelect, selectedOption]
  )

  const handleOpenDropdown = useCallback(() => setIsOpen(true), [])

  const getOptionKeyDownHandler = useCallback(
    (id: string, descFirst?: boolean) => (keyDownEvent: React.KeyboardEvent<HTMLDivElement>) => {
      if (keyDownEvent.key === '' || keyDownEvent.key === 'Enter') {
        handleSelect({ id, desc: !!descFirst })
      }
    },
    [handleSelect]
  )

  const getOrderKeyDownHandler = useCallback(
    (desc: boolean) => (keyDownEvent: React.KeyboardEvent<HTMLDivElement>) => {
      if (keyDownEvent.key === '' || keyDownEvent.key === 'Enter') {
        handleSelect({ id: selectedId, desc })
      }
    },
    [selectedId, handleSelect]
  )

  const getOptionSelectHandler = useCallback((id: string, descFirst?: boolean) => () => handleSelect({ id, desc: !!descFirst }), [handleSelect])

  const getOrderSelectHandler = useCallback((desc: boolean) => () => handleSelect({ id: selectedId, desc }), [selectedId, handleSelect])

  const renderGroupName = useCallback(
    (name: string) => <Typography text={t(name).toUpperCase()} type={TextType.TABLE_HEADER} className={`${rootClass}__menu-group-name`} />,
    [t]
  )

  const renderOption = useCallback(
    ({
      id,
      isSelected,
      label,
      onKeyDown,
      onClick,
    }: {
      id: string
      isSelected: boolean
      label: string
      onKeyDown: (keyDownEvent: React.KeyboardEvent<HTMLDivElement>) => void
      onClick: VoidFunction
    }) => (
      <div
        className={classNames(`${rootClass}__menu-option`, {
          [`${rootClass}__menu-option--selected`]: isSelected,
        })}
        key={id}
        tabIndex={0}
        role="button"
        onKeyDown={onKeyDown}
        onClick={onClick}
        data-test={`${dataTest}-option-${id}`}
      >
        <Typography text={t(label)} />
        {isSelected && (
          <div className={`${rootClass}__menu-option-indicator-container`}>
            <Svg name={SvgNames.check} fill={SvgColor.TEAL} type={SvgType.LARGER_ICON} />
          </div>
        )}
      </div>
    ),
    [t, dataTest]
  )

  const renderSortOrders = useCallback(() => {
    if (!currentSortOption) {
      return null
    }
    const { descLabel, ascLabel, id, descFirst } = currentSortOption
    const isSelectedOption = id === selectedOption.id

    const sortOrders = [
      { label: ascLabel, desc: false },
      { label: descLabel, desc: true },
    ].map(({ label, desc }) => {
      const isSelected = isSelectedOption && selectedOption.desc === desc
      return renderOption({
        label,
        isSelected,
        id: `${id}-${desc ? 'desc' : 'asc'}`,
        onKeyDown: getOrderKeyDownHandler(desc),
        onClick: getOrderSelectHandler(desc),
      })
    })

    return descFirst ? sortOrders.reverse() : sortOrders
  }, [currentSortOption, renderOption, selectedOption, getOrderKeyDownHandler, getOrderSelectHandler])

  if (!options.length) {
    return null
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <DropDownMenuRoot open={isOpen} onOpenChange={setIsOpen}>
        {/*This trigger needed to align Dropdown Content*/}
        <DropDownTriggerItem className={`${rootClass}__drop-down__trigger`} />
        <Typography className={`${rootClass}__label`} text={`${label}:`} weight={TextWeight.MEDIUM} />
        <Button className={`${rootClass}__trigger`} onClick={handleOpenDropdown} buttonType={ButtonType.TERTIARY} dataTest={`${dataTest}-trigger`}>
          <Typography
            text={buttonLabel}
            type={isOpen ? TextType.EMPHASIZED_TEXT_TEAL : undefined}
            weight={TextWeight.MEDIUM}
            dataTest={`${dataTest}-trigger-label`}
          />
          <Svg name={SvgNames.caretDown} fill={isOpen ? SvgColor.TEXT_TEAL : undefined} />
        </Button>
        <DropDownContent className={`${rootClass}__menu__wrapper`} align="start">
          <div className={`${rootClass}__menu`} data-test={`${dataTest}-menu`}>
            {onlySortColumn ? (
              <div className={`${rootClass}__menu-group`}>
                {renderGroupName(options[0].label)}
                {renderSortOrders()}
              </div>
            ) : (
              <>
                <div className={`${rootClass}__menu-group`}>
                  {renderGroupName(t('Sort by'))}
                  {options.map(({ id, label, descFirst }) => {
                    const isSelected = id === selectedId
                    return renderOption({
                      id,
                      label,
                      isSelected,
                      onKeyDown: getOptionKeyDownHandler(id, descFirst),
                      onClick: getOptionSelectHandler(id, descFirst),
                    })
                  })}
                </div>
                <div className={`${rootClass}__menu-group`} data-test={`${dataTest}-menu-order`}>
                  {renderGroupName('Order')}
                  {renderSortOrders()}
                </div>
              </>
            )}
          </div>
        </DropDownContent>
      </DropDownMenuRoot>
    </div>
  )
}

export default SortDropDown
