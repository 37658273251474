import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import getItem from '@graphql/microservices/categorization/getItem'
import getTopDirectSelectSegments from '@graphql/microservices/categorization/getTopDirectSelectSegments'
import {
  GetItemQuery,
  GetItemQueryVariables,
  GetTopDirectSelectSegmentsQuery,
  GetTopDirectSelectSegmentsQueryVariables,
  ItemDto,
} from '@graphql/types/microservice/categorization-types'
import { ItemType } from '@utils/categorization'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'

export interface ImportContactsCategorizationRequests {
  getTopDirectSelectSegmentsRequest: (pageNumber: number, query?: string) => Promise<SelectV2SingleOption[]>
  getSegmentById: (segmentId: string) => Promise<ItemDto | undefined>
}

export const useProgramsCategorizationRequests = (): ImportContactsCategorizationRequests => {
  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })

  const getTopDirectSelectSegmentsRequest = async (pageNumber: number = 0, query?: string) => {
    const { data } = await categorizationClient.query<GetTopDirectSelectSegmentsQuery, GetTopDirectSelectSegmentsQueryVariables>({
      query: getTopDirectSelectSegments,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables: {
        pageNumber,
        pageSize: 50,
        type: ItemType.SEGMENT,
        query,
        field: 'name',
      },
    })
    if (data?.getTopDirectSelectSegments) {
      const items = data.getTopDirectSelectSegments.map((item) => JSON.parse(item?.item ?? ''))
      return items.map(({ id, name }) => ({ label: name, value: id })).filter((entry: SelectV2SingleOption) => entry.label !== '')
    } else {
      return []
    }
  }

  const getSegmentById = async (segmentId: string) => {
    return categorizationClient
      .query<GetItemQuery, GetItemQueryVariables>({
        query: getItem,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        variables: {
          externalId: segmentId,
          type: ItemType.SEGMENT,
        },
      })
      .then(({ data }) => {
        if (data.getItem) {
          return data.getItem as ItemDto
        }
      })
  }

  return {
    getTopDirectSelectSegmentsRequest,
    getSegmentById,
  }
}
