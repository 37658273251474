import React, { ChangeEvent, FC, useCallback, useRef, useState } from 'react'

import Button, { ButtonType } from '@components/Button'
import FormRow from '@components/FormRow'
import InputWithMaxLength from '@components/InputWithMaxLength/InputWithMaxLength'
import InputWithStatus from '@components/InputWithStatus/InputWithStatus'
import LabelWithSvgTooltip from '@components/LabelWithTooltipIcon/LabelWithSvgTooltip'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Toggle from '@components/Toggle'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { CustomLinkItemInput } from '@graphql/types/query-types'
import { SortedCustomLinkInfo } from '@src/pages/Content/Branding/tabs/CustomLink/CustomLinkContainer'
import { checkURLValidity } from '@utils/formUtils'

import './CustomLinkForm.css'

interface Props {
  info: SortedCustomLinkInfo
  onSave: (data: CustomLinkItemInput) => void
  onCancel: () => void
  showInfo: boolean
  dataTest?: string
}

const labels = new Map<keyof CustomLinkItemInput, string>([
  ['url', 'Custom Link URL'],
  ['text', 'Display Text'],
  ['hint', 'Link Hint'],
])

const rootClass = 'custom-link__form'

const getInfoObject = (info: { key: keyof Omit<CustomLinkItemInput, 'hidden'>; value: string }[]) => {
  const result: Omit<CustomLinkItemInput, 'hidden'> = { url: '', hint: '', text: '' }
  info.forEach(({ key, value }) => (result[key] = value))
  return result
}

const CustomLinkForm: FC<Props> = (props) => {
  const { info, showInfo, onSave, onCancel, dataTest = rootClass } = props
  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(showInfo)
  const stateRef = useRef<CustomLinkItemInput>({ ...getInfoObject(info), hidden: !showInfo })
  const [isTouched, setIsTouched] = useState<boolean>(false)
  const [isValid, setIsValid] = useState<boolean>(!!stateRef.current.url && !!stateRef.current.text)
  const [urlInvalid, setUrlInvalid] = useState<boolean>(!!checkURLValidity(stateRef.current.url))

  const handleSave = useCallback(() => onSave(stateRef.current), [onSave])
  const handleToggle = useCallback((show: boolean) => {
    setIsTouched(true)
    setShow(show)
    stateRef.current.hidden = !show
  }, [])

  const onInputChange = useCallback(
    (key: keyof Omit<CustomLinkItemInput, 'hidden'>) => (e: ChangeEvent<HTMLInputElement>) => {
      stateRef.current[key] = e.target.value
      setIsValid(!!stateRef.current.url && !!stateRef.current.text)
      setIsTouched(true)
      key === 'url' && setUrlInvalid(!!checkURLValidity(e.target.value))
    },
    []
  )

  const disableSave = !isTouched || (show && (!isValid || urlInvalid))

  return (
    <div className={rootClass} data-test={dataTest}>
      <div className={`${rootClass}__header`}>
        <Svg
          name={show ? SvgNames.previewOn : SvgNames.hide}
          type={SvgType.LARGE_ICON}
          className={`${rootClass}__header-eye-svg`}
          dataTest={`${dataTest}-eye-svg`}
        />
        <Typography
          text={t('Show custom link')}
          type={show ? TextType.BODY_TEXT_LARGE : TextType.BODY_TEXT_LARGE_LIGHT}
          weight={TextWeight.MEDIUM}
          lineHeight={LineHeight.MEDIUM_LARGE}
        />
        <Tooltip trigger={<Toggle large isOn={show} onToggle={handleToggle} dataTest={`${dataTest}-toggle`} />}>
          {t('Custom.Link.Toggle.Tooltip', { context: show ? 'hide' : 'show' })}
        </Tooltip>
      </div>
      <div className={`${rootClass}__content`}>
        {info.map(({ key, value }) => {
          const inputProps = {
            disabled: !show,
            dataTest: `${dataTest}-input-${key}`,
            onChange: onInputChange(key),
          }
          return (
            <FormRow key={key} className={`${rootClass}__content-row`}>
              <LabelWithSvgTooltip
                required={key !== 'hint'}
                requiredTextType={TextType.BODY_TEXT_LIGHT}
                label={
                  <Typography text={t(labels.get(key))} weight={TextWeight.MEDIUM} type={show ? TextType.BODY_TEXT : TextType.BODY_TEXT_LIGHT} />
                }
                tooltipContent={t('Custom.Link.Row.Tooltip', { context: key })}
                svgType={SvgType.LARGER_ICON}
              />
              {key === 'url' ? (
                <InputWithStatus {...inputProps} value={value} hasCustomError={urlInvalid} customTooltipErrorMessages={'Provide valid URL'} />
              ) : (
                <InputWithMaxLength inputProps={{ ...inputProps, defaultValue: value }} maxLength={key === 'hint' ? 100 : 36} showLimitError={show} />
              )}
            </FormRow>
          )
        })}
      </div>
      <div className={`${rootClass}__actions`}>
        <Button buttonType={ButtonType.PRIMARY} onClick={handleSave} dataTest={`${dataTest}-save-button`} disabled={disableSave}>
          {t('Save')}
        </Button>
        <Button buttonType={ButtonType.TEXT} onClick={onCancel} dataTest={`${dataTest}-cancel-button`}>
          {t('Cancel')}
        </Button>
      </div>
    </div>
  )
}

export default CustomLinkForm
