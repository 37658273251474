import { LineSegmentData } from '@components/SegmentedSingleLineChart/SegmentedSingleLineChart'

export const mapAndLimitArray = (array: LineSegmentData[], maxSegments: number, itemName: string): LineSegmentData[] => {
  const limitedArray = array.slice(0, maxSegments)
  const sumOfRemainingAmounts = array.slice(maxSegments).reduce((acc, curr) => acc + curr.amount, 0)
  limitedArray[maxSegments] = {
    amount: sumOfRemainingAmounts,
    tooltipTitle: `Other ${itemName} (${array.length - maxSegments} of ${array.length})`,
  }
  return limitedArray
}
