import React, { FC, useCallback, useContext, useMemo, useState } from 'react'

import classNames from 'classnames'

import ListPickerModal from '@complex/ListPickerModalV2/ListPickerModal'
import { PersonalizationModals } from '@complex/Personalization/Personalization.constants'
import PersonalizationSidebarContainer from '@complex/Personalization/PersonalizationSidebarContainer'
import PersonalizationTableContainer from '@complex/Personalization/PersonalizationTableContainer'
import { PersonalizationModalContext } from '@complex/Personalization/utils/Personalization.context'
import Button, { ButtonType } from '@components/Button/index'
import ModalBody from '@components/Modal/components/ModalBody'
import ModalFooter, { ModalFooterType } from '@components/Modal/components/ModalFooter'
import ModalHeader from '@components/Modal/components/ModalHeader'
import Modal from '@components/Modal/Modal'
import Search from '@components/Search/Search'
import StatusToast from '@components/StatusToast/StatusToast'
import Typography, { ModalBodyStyle, ModalHeaderStyle, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { List } from '@interface/foldersLists/List'
// eslint-disable-next-line no-restricted-imports
import { EmailComposerContext, SendtoList } from '@utils/composer/context/EmailComposer.context'
import { FilterTypes } from '@utils/filter'
import { StatusToastType } from '@utils/interface/StatusToast'

import { setupToast } from './utils/Personalization.utils'

import './Personalization.css'

interface PersonalizationProps {
  onSearch: (newSearch: string) => void
  onClose: VoidFunction
  onInsert: VoidFunction
  title?: string
  className?: string
  dataTest?: string
}

const rootClass = 'personalization'

const Personalization: FC<PersonalizationProps> = (props: PersonalizationProps) => {
  const { onSearch, onClose, onInsert, title = 'Personalize', dataTest = rootClass, className = '' } = props
  const { skipFallbackText, isAutomatedPrograms, isOpen, activeModal, canSubmit, searchText, selectedItem, update } =
    useContext(PersonalizationModalContext)

  const {
    api: { update: updateEmailComposer },
    values: {
      message: { sendto_contacts, sendto_lists },
      recipientsCount,
    },
  } = useContext(EmailComposerContext)

  const { t } = useTranslation()
  const [toast, setToastStatus] = useState<StatusToastType>()

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  const skipFallback = useMemo(
    () =>
      skipFallbackText ||
      (selectedItem?.group &&
        (selectedItem.group === FilterTypes.CUSTOM_ACCOUNT_FIELDS ||
          (selectedItem.group === FilterTypes.SENDER_FIELDS && !selectedItem.title.includes('Account Date')))),
    [skipFallbackText, selectedItem?.group, selectedItem?.title]
  )

  const handleAction = useCallback(() => {
    skipFallback ? onInsert() : update({ activeModal: PersonalizationModals.FALLBACK })
  }, [update, onInsert, skipFallback])

  const header = useMemo(
    () => (
      <ModalHeader className={`${rootClass}__header`}>
        <div className={`${rootClass}__header-items`}>
          <Typography text={t(title)} {...ModalHeaderStyle} />
        </div>
        <div className={`${rootClass}__header-extra`}>
          <Search
            incomingValue={searchText}
            placeholder={t('Search all fields')}
            canClear
            onChangeHandler={onSearch}
            dataTest={`${dataTest}-search`}
          />
        </div>
      </ModalHeader>
    ),
    [dataTest, onSearch, searchText, t]
  )

  const renderSelectedItem = useMemo(() => {
    const text = t(selectedItem?.id ? `PersonalizationModal.Footer.SelectedItem` : 'PersonalizationModal.Footer.NoSelection')
    const values = { group: selectedItem?.group, selectedItem: selectedItem?.title }

    return (
      <Typography
        text={text}
        values={values}
        tagProps={{ bold: { weight: TextWeight.MEDIUM } }}
        {...ModalBodyStyle}
        inline
        dataTest={`${rootClass}-footer-typography-single`}
      />
    )
  }, [selectedItem, t])

  const listPickerSubmitLists = useCallback(
    (lists: List[]) => {
      const updatedSendToLists: SendtoList[] = lists.map((lst) => ({
        srcId: lst.id,
        baseId: lst.baseId,
        srcName: lst.name,
        size: lst.size ?? 0,
        isList: true,
      }))

      const currentRecipientsCount = recipientsCount + (sendto_contacts?.length ?? 0) + updatedSendToLists.reduce((acc, { size }) => acc + size, 0)

      updateEmailComposer({
        message: { sendto_lists: updatedSendToLists },
        recipientsCount: currentRecipientsCount,
      })

      update({ activeModal: PersonalizationModals.DEFAULT })

      setupToast({
        setToastStatus,
        showStatus: true,
        successStatus: true,
        typographyProps: {
          text: t('Personalization.toastSuccessMessage', {
            name: lists[0].name,
            count: lists.length,
          }),
        },
      })
    },
    [update, sendto_contacts?.length, updateEmailComposer, t]
  )

  const listPickerCloseModal = useCallback(
    () =>
      update({
        activeModal: PersonalizationModals.DEFAULT,
        activeFilter: isAutomatedPrograms ? FilterTypes.SOURCE_LIST_FIELDS : FilterTypes.RECIPIENT_LIST_FIELDS,
      }),
    [update]
  )

  const personalizationModalUtils = useCallback(
    (activeModal: PersonalizationModals) => {
      switch (activeModal) {
        case PersonalizationModals.ADD_RECIPIENTS:
          const sendToListIds = sendto_lists?.filter(({ isList }) => isList).map(({ srcId }) => srcId)
          return (
            <ListPickerModal
              isOpen
              submitDisabledWithoutSelection
              headerTitle={t('Select list or segment')}
              onBackArrowClick={() => update({ activeModal: PersonalizationModals.DEFAULT })}
              dataTest={`${dataTest}-list-picker-modal`}
              submitButtonText={t('Add recipients')}
              defaultSelectedLists={sendToListIds}
              submitTooltipText="Personalization.addRecipientModal.footerButtonText"
              closeModal={listPickerCloseModal}
              submitLists={listPickerSubmitLists}
            />
          )

        default:
          return (
            <Modal addZIndexBy={100} className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
              <>
                {toast?.showStatus && (
                  <StatusToast
                    className={`${rootClass}__toaster`}
                    isSuccess={toast.successStatus}
                    message={toast.statusMessage}
                    title={toast.title}
                    customTimeoutTime={3000}
                    closeStatus={() => {
                      setToastStatus({ showStatus: false })
                    }}
                  />
                )}
                <ModalBody className={`${rootClass}__body`}>
                  <PersonalizationSidebarContainer />
                  <PersonalizationTableContainer />
                </ModalBody>
                <ModalFooter footerType={ModalFooterType.List} className={`${rootClass}__footer-inner`}>
                  <div className={`${rootClass}__footer`}>
                    <div className={`${rootClass}__footer-section`}>{renderSelectedItem}</div>
                    <div className={`${rootClass}__footer-section`}>
                      <Button buttonType={ButtonType.TERTIARY} onClick={handleClose} dataTest={`${dataTest}-button-tertiary`}>
                        {t('Cancel')}
                      </Button>
                      <Button
                        buttonType={ButtonType.PRIMARY}
                        onClick={handleAction}
                        disabled={!selectedItem?.id && !canSubmit}
                        dataTest={`${dataTest}-button-primary`}
                      >
                        {t(skipFallback ? 'Insert' : 'Next')}
                      </Button>
                    </div>
                  </div>
                </ModalFooter>
              </>
            </Modal>
          )
      }
    },
    [
      sendto_lists,
      t,
      dataTest,
      listPickerCloseModal,
      listPickerSubmitLists,
      className,
      isOpen,
      header,
      toast?.showStatus,
      toast?.successStatus,
      toast?.statusMessage,
      toast?.title,
      renderSelectedItem,
      handleClose,
      handleAction,
      selectedItem?.id,
      canSubmit,
      skipFallback,
      update,
    ]
  )

  return personalizationModalUtils(activeModal)
}

export default Personalization
