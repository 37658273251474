import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import Container from '@components/Container/index'
import InfoTooltip from '@components/InfoTooltip/InfoTooltip'
import SectionHeadline from '@components/SectionHeadline/index'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Toggle from '@components/Toggle'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, LineHeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { hasCrmEnabledById } from '@src/pages/EmailComposer/components/SendDetails/components/SendDetailsAttachNote/SendDetailsAttachNote.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import { EmailComposerContext, SendToContact } from '@utils/composer/context/EmailComposer.context'
import useCRM, { CRMConnectorType } from '@utils/hooks/useCRM'

import './SendDetailsAttachNote.css'

interface SendDetailsAttachNoteProps {
  className?: string
  dataTest?: string
}

interface CRMObject {
  copyText: string
  tooltipText: string
}

const CRM_SENT_NOTE_INFO = 'https://connect.act-on.com/hc/en-us/articles/360060904113-Add-a-Note-in-your-CRM-when-Sending-an-Email'

const rootClass = 'send-details-attach-note'

const SendDetailsAttachNote: FC<SendDetailsAttachNoteProps> = (props: SendDetailsAttachNoteProps) => {
  const { dataTest = rootClass, className = '' } = props
  const { hasCRMConnected, connectorType } = useCRM()
  const {
    api: { update },
    values: {
      message,
      message: { isCrmSentNote },
      isEmailCrmFirstEdit,
    },
  } = useContext(EmailComposerContext)

  const { t } = useTranslation()
  const { crmNoteCheckboxOffByDefault, messageNotePushStreamingPush } = useAccountSettings()

  // Local state to track if first edit has been consumed
  const [firstEditConsumed, setFirstEditConsumed] = useState(false)

  const conditionalCRMText: { [key in CRMConnectorType]: CRMObject } = {
    [CRMConnectorType.SALESFORCE]: { copyText: 'SendDetails.AttachNote.Salesforce.Copy', tooltipText: 'SendDetails.AttachNote.Salesforce.Tooltip' },
    [CRMConnectorType.MS_DYNAMICS]: {
      copyText: messageNotePushStreamingPush ? 'SendDetails.AttachNote.MSDynamics.Message.Streaming.Copy' : 'SendDetails.AttachNote.MSDynamics.Copy',
      tooltipText: messageNotePushStreamingPush ? '' : 'SendDetails.AttachNote.MSDynamics.Tooltip',
    },
    [CRMConnectorType.SUGAR]: { copyText: 'SendDetails.AttachNote.Sugar.Copy', tooltipText: 'SendDetails.AttachNote.Sugar.Tooltip' },
    [CRMConnectorType.ZENDESK]: { copyText: 'SendDetails.AttachNote.Zendesk.Copy', tooltipText: 'SendDetails.AttachNote.Zendesk.Tooltip' },
    [CRMConnectorType.NETSUITE]: { copyText: 'SendDetails.AttachNote.Netsuite.Copy', tooltipText: 'SendDetails.AttachNote.Netsuite.Tooltip' },
    [CRMConnectorType.SALESLOGIC]: { copyText: 'SendDetails.AttachNote.SalesLogic.Copy', tooltipText: 'SendDetails.AttachNote.SalesLogic.Tooltip' },
    [CRMConnectorType.NOT_CONNECTED]: { copyText: '', tooltipText: '' },
  }

  const sendToContacts = message.sendto_contacts
  const {
    [connectorType]: { copyText, tooltipText },
  } = conditionalCRMText

  const { isCrmEnabledRecipientListSelected, hasCrmEnabledDisabledRecipientListSelected } = useMemo(() => {
    let isCrmEnabledRecipientListSelected: boolean = false
    let hasCrmEnabledDisabledRecipientListSelected: boolean = false

    if (sendToContacts) {
      const hasCrmListContacts = sendToContacts.some((list: SendToContact) => hasCrmEnabledById(list.id))
      if (hasCrmListContacts) {
        isCrmEnabledRecipientListSelected = true
        const doesNotHaveCrmListContacts = sendToContacts.some((list: SendToContact) => !hasCrmEnabledById(list.id))
        if (doesNotHaveCrmListContacts) {
          hasCrmEnabledDisabledRecipientListSelected = true
        }
      }
    }

    if (!isCrmEnabledRecipientListSelected) {
      isCrmEnabledRecipientListSelected = !!message.sendto_lists?.some((list) => {
        return hasCrmEnabledById(list.srcId) || (list.baseId && hasCrmEnabledById(list.baseId))
      })
    }
    if (!hasCrmEnabledDisabledRecipientListSelected) {
      const doesNotHaveCrmListIds =
        message.sendto_lists?.some((list) => !hasCrmEnabledById(list.srcId) && list.baseId && !hasCrmEnabledById(list.baseId)) || false
      hasCrmEnabledDisabledRecipientListSelected = isCrmEnabledRecipientListSelected && doesNotHaveCrmListIds
    }
    return {
      isCrmEnabledRecipientListSelected,
      hasCrmEnabledDisabledRecipientListSelected,
    }
  }, [message.sendto_lists, message.sendto_contacts])

  const updateCrmSentNote = useCallback(() => {
    update({ message: { isCrmSentNote: !isCrmSentNote }, isEmailCrmFirstEdit: false })
  }, [isCrmSentNote, update])

  useEffect(() => {
    if (isEmailCrmFirstEdit && !firstEditConsumed) {
      if (crmNoteCheckboxOffByDefault) {
        update({ message: { isCrmSentNote: false } })
      } else {
        update({ message: { isCrmSentNote: true } })
      }
      // Mark first edit as consumed
      setFirstEditConsumed(true)
    }
  }, [isEmailCrmFirstEdit, crmNoteCheckboxOffByDefault, update, firstEditConsumed])

  return (
    <>
      {hasCRMConnected && (
        <Container className={classNames(rootClass, className)} dataTest={`${dataTest}-container`}>
          <div className={`${rootClass}__info-container`}>
            <div className={`${rootClass}__conditional-text-container`}>
              <SectionHeadline className={`${rootClass}__section-header`}>{t(copyText)}</SectionHeadline>
              {tooltipText && <InfoTooltip>{t(tooltipText)}</InfoTooltip>}
            </div>
            <Typography
              className={`${rootClass}__section-sub-text`}
              text={t('SendDetails.AttachNote.Sub')}
              type={TextType.BODY_TEXT_GRAY_SMALL}
              lineHeight={LineHeight.MEDIUM_SMALL}
            />
            <TextLink text={t('Learn more')} link={CRM_SENT_NOTE_INFO} size={TextLinkSize.LARGE} lineHeight={LineHeight.SMALL} />
            {hasCrmEnabledDisabledRecipientListSelected && isCrmSentNote && (
              <div className={`${rootClass}__info-container_crm-warning`}>
                <Svg name={SvgNames.spamCheckWarningNoFill} type={SvgType.MEDIUM_LARGE_ICON} />
                <Typography text={t('SendDetails.AttachNote.CrmWarning')} type={TextType.BODY_TEXT_GRAY_SMALL} lineHeight={LineHeight.MEDIUM_SMALL} />
              </div>
            )}
          </div>
          <div className={`${rootClass}__toggle-container`}>
            <Tooltip
              hide={isCrmEnabledRecipientListSelected}
              trigger={
                <Toggle
                  isOn={isCrmSentNote && isCrmEnabledRecipientListSelected}
                  disabled={!isCrmEnabledRecipientListSelected}
                  onToggle={updateCrmSentNote}
                  large
                  noLeftMargin
                  noRightMargin
                  dataTest={`${dataTest}-toggle`}
                />
              }
            >
              {t('SendDetails.AttachNote.Salesforce.Toggle.Tooltip')}
            </Tooltip>
          </div>
        </Container>
      )}
    </>
  )
}

export default SendDetailsAttachNote
