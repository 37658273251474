import { FilterQueryParams } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { MIRRORED_LISTS_ID } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import { GetAllItemsQuery, GetRecentItemsQuery } from '@graphql/types/microservice/categorization-types'
import { ItemDto, Program } from '@graphql/types/query-types'
import { ItemType } from '@utils/categorization'
import { logNewRelicError } from '@utils/new-relic.utils'
import { FetchPromise } from '@utils/types'

export const getSuggestedAutomatedProgramsRequestResultsUtils = async (
  getSuggestedAutomatedProgramsRequest: (search: string, currentPage: number, params?: FilterQueryParams) => FetchPromise<GetRecentItemsQuery>,
  search: string,
  currentPage: number,
  params?: FilterQueryParams
) => {
  try {
    const { data } = await getSuggestedAutomatedProgramsRequest(search, currentPage, params)
    return {
      data:
        data && data.getRecentItems
          ? (data.getRecentItems.filter((item) =>
              (JSON.parse(item?.item || '{}') as Program).sources.find((source) => !source.baseId.startsWith(MIRRORED_LISTS_ID))
            ) as ItemDto[])
          : [],
    }
  } catch (error) {
    logNewRelicError(error)
    return { data: [], error: 'Something went wrong on our end. Please try again.' }
  }
}

export const getAllItemsRequestResultsUtils = async (
  getAllItemsTemplatesRequest: (type: ItemType, search: string, params?: FilterQueryParams) => FetchPromise<GetAllItemsQuery>,
  type: ItemType,
  search: string,
  params?: FilterQueryParams
) => {
  try {
    const { data } = await getAllItemsTemplatesRequest(type, search, params)
    return { data: data && data.getAllItems ? (data.getAllItems as ItemDto[]) : [] }
  } catch (error) {
    logNewRelicError(error)
    return { data: [], error: 'Something went wrong on our end. Please try again.' }
  }
}
