import { TFunction } from 'i18next'

import { SecurityPageResponse } from '@graphql/types/query-types'
import { convertHoursToMilliseconds, formatDateWithAbbreviations, formatSendTSDate, relativeDate } from '@utils/date'
import { hasNumberRegExp, hasSpecialCharacterRegExp, hasUpperAndLowerCaseRegExp } from '@utils/formUtils'

export interface ComplexityUIValue {
  text: string
  visible: boolean
  validator: (value: string) => boolean
}

const PASSWORD_EXPIRATION_MIN_TIME = 30

export const getComplexityUIValues = (key: string, length: number): ComplexityUIValue[] => {
  const complexity = !!key ? Number(key) + 1 : 0
  return [
    {
      text: `Use ${length} or more characters`,
      visible: !!length,
      validator: (value: string) => {
        return value.length >= length
      },
    },
    {
      text: 'Use upper and lower case letters',
      visible: complexity > 1,
      validator: (value: string) => {
        return hasUpperAndLowerCaseRegExp.test(value)
      },
    },
    {
      text: 'Use a number (0-9)',
      visible: complexity > 2,
      validator: (value: string) => {
        return hasNumberRegExp.test(value)
      },
    },
    {
      text: 'Use a special character (e.g. !@#$)',
      visible: complexity > 3,
      validator: (value: string) => {
        return hasSpecialCharacterRegExp.test(value)
      },
    },
  ]
}

export const getPasswordCreationText = (t: TFunction, data?: SecurityPageResponse) => {
  const value = +(data?.passwordChangeDataMs ?? 0)
  const creationDate = relativeDate(value, false, false, 'day')
  const creationDaysPassed = +creationDate.split(' ')[0]

  if (creationDaysPassed < 1) {
    const time = formatDateWithAbbreviations(value).replace(' ', '')
    return t(`Your password was last changed today at {{time}}.`, { time })
  }

  return t(`Your password was last changed {{count}} days ago on {{timestamp}}.`, {
    count: creationDaysPassed,
    timestamp: formatSendTSDate(value),
  })
}

export const getExpirationWarningText = (data: SecurityPageResponse | undefined, t: TFunction) => {
  if (!data?.passwordChangeDataMs || !data?.passwordExpirationPolicy || data?.passwordExpirationPolicy === 'Never') {
    return
  }

  const expirationPeriod = getExpirationPeriod(data)

  if (expirationPeriod > PASSWORD_EXPIRATION_MIN_TIME || expirationPeriod < 0) {
    return
  }

  if (expirationPeriod < 1) {
    return t('Profile.Security.Password.Expiration.Today')
  }

  const date = formatSendTSDate(+data.passwordChangeDataMs + convertHoursToMilliseconds(expirationPeriod * 24))

  return t(`Profile.Security.Password.Expiration`, { expirationPeriod, date, count: expirationPeriod })
}

export const getExpirationPeriod = (data?: SecurityPageResponse) => {
  if (!data?.passwordChangeDataMs || !data?.passwordExpirationPolicy || data?.passwordExpirationPolicy === 'Never') {
    return PASSWORD_EXPIRATION_MIN_TIME + 1
  }
  const creationDate = relativeDate(+data.passwordChangeDataMs, false, false, 'day')
  const creationPeriod = creationDate.split(' ')[0]
  return +data.passwordExpirationPolicy - +creationPeriod
}
