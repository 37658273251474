import React, { FC, Fragment } from 'react'

import { parse } from 'node-html-parser'

import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { RssItem } from '@graphql/types/query-types'

import { RssAlignment, RssFormat, RssLayout } from '../../RssModalContainer'

import './RssBlock.css'

interface RssBlockProps {
  rssFeedItems: RssItem[]
  includeAuthor: boolean
  includeDate: boolean
  includeReadMoreLink: boolean
  selectedLayoutOption?: SelectV2SingleOption
  textAlignment: RssAlignment
  formatOption: RssFormat
  id: string
  isPlainText: boolean
  plainText?: string
  className?: string
  dataTest?: string
}

const rootClass = 'rss-block'

export const RssBlock: FC<RssBlockProps> = (props: RssBlockProps) => {
  const {
    id,
    rssFeedItems,
    isPlainText,
    plainText,
    formatOption,
    includeAuthor,
    includeDate,
    includeReadMoreLink,
    textAlignment,
    selectedLayoutOption,
  } = props

  const renderRssImage = (rssItem: RssItem) => {
    const html = parse(rssItem?.content ?? '')
    const imageElement = html.getElementsByTagName('img')[0]
    const imgStyles = {
      width: selectedLayoutOption?.value === RssLayout.TOP || selectedLayoutOption?.value === RssLayout.BOTTOM ? '100%' : '12rem',
      borderRadius: '4px',
    }

    if (imageElement && formatOption === RssFormat.FULL_CONTENT) {
      const src = imageElement.getAttribute('src')
      const alt = imageElement.getAttribute('alt')

      return (
        <Fragment>
          <td>
            {alt ? (
              <a key={src} href={rssItem.link} target={'_blank'} rel={'noreferrer'} style={{ display: 'block' }}>
                <img src={src} alt={alt} style={{ ...imgStyles }} data-test={`${rootClass}-rss-image`} />
              </a>
            ) : undefined}
          </td>
        </Fragment>
      )
    }
  }

  const renderRssContent = (rssItem: RssItem) => {
    let subTitle

    if (includeDate && includeAuthor && rssItem.date !== '' && rssItem.author !== '') {
      subTitle = `${rssItem.date} | ${rssItem.author}`
    } else if (includeDate && rssItem.date !== '') {
      subTitle = rssItem.date
    } else if (includeAuthor && rssItem.author !== '') {
      subTitle = rssItem.author
    }

    const titleStyles = {
      paddingBottom: '8px',
      fontWeight: '600',
      color: '#0068A5',
      fontSize: '16px',
      lineHeight: '24px',
      margin: '0',
      display: 'block',
      textDecoration: 'none',
      width: '95%',
    }
    const subTitleStyles = { paddingBottom: '16px', color: '#222', fontSize: '12px', lineHeight: '18px', margin: '0' }
    const readMoreStyles = {
      color: '#0068A5',
      fontSize: '12px',
      fontWeight: '600',
      display: 'block',
      lineHeight: '18px',
      textDecoration: 'none',
      paddingTop: '8px',
    }

    const parser = new DOMParser()
    const parsedHtml = parser.parseFromString(rssItem?.description || '', 'text/html')

    const images = parsedHtml.querySelectorAll('img')

    images.forEach((img) => {
      img.style.width = '100%'
    })

    const modifiedDescription = parsedHtml.body.innerHTML

    const renderDescription = () => {
      return <div style={{ fontSize: '14px', lineHeight: 1.3 }} dangerouslySetInnerHTML={{ __html: modifiedDescription || '' }} />
    }

    return (
      <td>
        <a key={rssItem.title} href={rssItem.link} target={'_blank'} rel={'noreferrer'} style={{ ...titleStyles }}>
          {rssItem.title}
        </a>
        {(includeAuthor || includeDate) && (
          <p key={subTitle} style={{ ...subTitleStyles }}>
            {subTitle}
          </p>
        )}
        {(formatOption === RssFormat.FULL_CONTENT || formatOption === RssFormat.SUMMARY) && renderDescription()}
        {includeReadMoreLink && (
          <a key={rssItem.link} href={rssItem.link} style={{ ...readMoreStyles }} target={'_blank'} rel="noreferrer">
            Read more
          </a>
        )}
      </td>
    )
  }

  const renderPlainTextView = () => (
    <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', fontSize: '14px', lineHeight: 'initial' }}>
      <p>{`--- RSS Block ---`}</p>
      <p>{plainText}</p>
      <p>{`--- RSS Block ---`}</p>
    </pre>
  )

  const renderHtmlView = () => (
    <>
      {rssFeedItems.map((rssItem, idx) => {
        const image = renderRssImage(rssItem)

        return (
          <Fragment key={`fragment-${idx}`}>
            {selectedLayoutOption?.value === RssLayout.TOP && (
              <Fragment key={`fragment-row-${idx}`}>
                {image && <tr style={{ verticalAlign: 'top' }}>{image}</tr>}
                <tr style={{ textAlign: textAlignment }}>{renderRssContent(rssItem)}</tr>
              </Fragment>
            )}
            {selectedLayoutOption?.value === RssLayout.BOTTOM && (
              <Fragment key={`fragment-row-${idx}`}>
                <tr style={{ textAlign: textAlignment, verticalAlign: 'top' }}>{renderRssContent(rssItem)}</tr>
                {image && <tr style={{ verticalAlign: 'top' }}>{renderRssImage(rssItem)}</tr>}
              </Fragment>
            )}
            {selectedLayoutOption?.value === RssLayout.LEFT && (
              <tr style={{ textAlign: textAlignment, verticalAlign: 'top' }}>
                {renderRssImage(rssItem)}
                {renderRssContent(rssItem)}
              </tr>
            )}
            {selectedLayoutOption?.value === RssLayout.RIGHT && (
              <tr style={{ textAlign: textAlignment, verticalAlign: 'top' }}>
                {renderRssContent(rssItem)}
                {renderRssImage(rssItem)}
              </tr>
            )}
          </Fragment>
        )
      })}
    </>
  )

  return (
    <>
      {isPlainText ? (
        renderPlainTextView()
      ) : (
        <table id={id} style={{ borderSpacing: '24px' }} className={rootClass}>
          <tbody>{renderHtmlView()}</tbody>
        </table>
      )}
    </>
  )
}
