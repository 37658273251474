import React, { FC, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import { Status } from '@components/StatusToast/StatusToast'
import { useTranslation } from '@const/globals'
import { ExportSyncJobDto } from '@graphql/types/microservice/entity-upload-types'
import { ReviewExportFields } from '@src/pages/ExportContacts/components/ReviewExportFields/ReviewExportFields'
import ReviewExportOptions from '@src/pages/ExportContacts/components/ReviewExportOptions/ReviewExportOptions'
import { useExportContactsRequest } from '@src/pages/ExportContacts/GraphQL/ExportContacts.graphql'
import { ExportContactsContext } from '@src/pages/ExportContacts/utils/ExportContacts.context'
import { FormJoinViewSession } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.utils'
import { ContactSegmentsSession } from '@utils/contactSegments/contactSegments.utils'
import { setItem } from '@utils/sessionStorage'

import './ReviewExport.css'

interface ReviewExportProps {
  className?: string
  dataTest?: string
}

const rootClass = 'review-export'

const ReviewExport: FC<ReviewExportProps> = (props: ReviewExportProps) => {
  const { dataTest = rootClass, className = '' } = props

  const [isSaving, setIsSaving] = useState(false)

  const { t } = useTranslation()
  const history = useHistory()

  const {
    values: { schedule, exportSyncJobId, backUrl },
    update,
  } = useContext(ExportContactsContext)

  const sessionKey: { [key: string]: string } = {
    segments: ContactSegmentsSession.INCOMING_STATUS_TOAST,
    formSubmissions: FormJoinViewSession.INCOMING_STATUS_TOAST,
  }

  const { createExportSyncJobRequest, updateExportSyncJobRequest } = useExportContactsRequest()

  const onSaveResponseHandler = async (response: Promise<ExportSyncJobDto>, hasToRunNow: boolean) => {
    const onError = () => {
      update({
        statusToast: {
          statusMessage: t('Something went wrong on our end. Please try again.'),
          status: Status.FAIL,
          showStatusToast: true,
        },
      })
    }
    const { exportSyncJobId } = await response
    if (exportSyncJobId) {
      update({ exportSyncJobId })
      if (hasToRunNow) {
        setItem(
          sessionKey[backUrl.substring(backUrl.lastIndexOf('/') + 1)],
          JSON.stringify({
            statusMessage: t('Your file has been exported to the FTP server.'),
            status: Status.SUCCESS,
            showStatusToast: true,
          })
        )
      }
      history.push(backUrl)
    } else {
      onError()
    }
  }

  const doExport = async (hasToRunNow = true) => {
    let response: Promise<ExportSyncJobDto>
    if (exportSyncJobId !== undefined) {
      response = updateExportSyncJobRequest({ hasToRunNow })
    } else {
      response = createExportSyncJobRequest({ hasToRunNow })
    }
    await onSaveResponseHandler(response, hasToRunNow)
  }

  const showConfirmationModal = () => {
    setIsSaving(true)
  }

  const onConfirmationModalAnswer = (answer: YesNo) => {
    setIsSaving(false)
    if (answer === YesNo.YES) {
      doExport()
    } else {
      history.push(backUrl)
    }
  }

  const onExportLater = () => {
    setIsSaving(false)
    doExport(false)
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <ConfirmationModal
        isOpen={isSaving}
        title={t('ExportContacts.Sections.ReviewExport.Confirmation.Title')}
        body={t(
          schedule.isScheduled
            ? 'ExportContacts.Sections.ReviewExport.Confirmation.Body.Scheduled'
            : 'ExportContacts.Sections.ReviewExport.Confirmation.Body.NotScheduled'
        )}
        isYesNo
        yesButtonText={t('ExportContacts.Sections.ReviewExport.Confirmation.Body.Now')}
        onAnswer={onConfirmationModalAnswer}
        {...(schedule.isScheduled
          ? {
              footerActionElement: (
                <Button buttonType={ButtonType.INFO} onClick={onExportLater}>
                  {t('ExportContacts.Sections.ReviewExport.Confirmation.Body.Later')}
                </Button>
              ),
            }
          : {})}
      />
      <ReviewExportFields />
      <ReviewExportOptions />
      <Button className={`${rootClass}__save-button`} buttonType={ButtonType.PRIMARY} onClick={showConfirmationModal}>
        {t('Save')}
      </Button>
    </div>
  )
}

export default ReviewExport
