import React, { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react'

import { ConfigProvider, TimePicker } from 'antd'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { EventValue } from 'rc-picker/lib/interface'

import { DateRangeValueType } from '@components/DateRangePicker/DateRangePicker'
import Svg, { SvgNames, SvgType } from '@components/Svg/index'
import { SvgColor } from '@components/Svg/Svg'
import {
  TimePickerV2ErrorMessage,
  TimePickerV2Footer,
  TimePickerV2HelperMessage,
  TimePickerV2Label,
  TimePickerV2SuffixIcon,
} from '@components/TimePickerV2/components/TimePickerV2.components'
import { timePickerV2ThemeProps } from '@components/TimePickerV2/constants/TimePickerV2.constants'
import { TimePickerV2Format } from '@components/TimePickerV2/TimePickerV2'

import './TimeRangePicker.css'

export interface TimeRangePickerProps extends Pick<React.ComponentProps<typeof TimePicker>, 'hourStep' | 'minuteStep' | 'secondStep' | 'disabled'> {
  onSubmit: (range: [string, string]) => void
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  format?: TimePickerV2Format
  defaultValue?: [string, string] | [number, number]
  label?: string
  required?: boolean
  tooltipContent?: string | ReactNode | ReactNode[]
  smallLabel?: boolean
  allowClear?: boolean
  helperMessage?: string | React.ReactNode
  errorMessage?: string | React.ReactNode
  isParentModal?: boolean
  hideErrorIcon?: boolean
  className?: string
  dataTest?: string
}

const rootClass = 'time-range-picker'

const TimeRangePicker: FC<TimeRangePickerProps> = (props: TimeRangePickerProps) => {
  const {
    onSubmit,
    onFocus,
    onBlur,
    defaultValue,
    label,
    required,
    tooltipContent,
    disabled,
    allowClear,
    smallLabel,
    format = TimePickerV2Format.HH_MM,
    hourStep = 1,
    minuteStep = 5,
    secondStep = 5,
    helperMessage,
    errorMessage,
    isParentModal,
    hideErrorIcon,
    dataTest = rootClass,
    className = '',
  } = props

  const timePickerRef = useRef<HTMLDivElement>(null)
  const panelRef = useRef<HTMLDivElement>(null)
  const [selectionChange, setSelectionChange] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const _defaultValue: DateRangeValueType | null =
    defaultValue && dayjs(defaultValue[0], format).isValid() && dayjs(defaultValue[1], format).isValid()
      ? [dayjs(defaultValue[0], format), dayjs(defaultValue[1], format)]
      : null

  const handleOpenChange = useCallback((open: boolean) => {
    setOpen(open)
    setSelectionChange(false)
  }, [])

  const handleSubmit = useCallback(() => {
    // It is nearly impossible to modify Antd "Ok" button and have "Cancel" button aside of it
    // Getting hidden "Ok" button, to simulate same behavior on "Apply" button click
    const antdOkBtn = panelRef.current?.getElementsByClassName('ant-btn-primary')[0]
    if (!!antdOkBtn && 'click' in antdOkBtn && typeof antdOkBtn.click === 'function') {
      antdOkBtn.click()
      setSelectionChange(false)
    }
  }, [])

  const handleCancel = useCallback(() => {
    // Simulating outside click to close popupContainer
    const mouseDownEvent = new MouseEvent('mousedown', { bubbles: true })
    timePickerRef.current?.dispatchEvent(mouseDownEvent)
  }, [])

  const handleChangeSubmit = useCallback(
    (_: DateRangeValueType, formatString: [string, string]) => {
      onSubmit(formatString)
      handleCancel()
    },
    [onSubmit, handleCancel]
  )

  const renderFooter = useCallback(() => {
    const props = { handleSubmit, handleCancel, selectionChange, dataTest, rootClass }
    return <TimePickerV2Footer {...props} />
  }, [handleSubmit, handleCancel, selectionChange, dataTest])

  const panelRender = useCallback((panel: ReactNode) => <div ref={panelRef}>{panel}</div>, [])

  const disabledHours = useCallback(() => {
    if (defaultValue) {
      const hours = []
      const currentHour = Number(`${defaultValue[0]}`.split(':')[0])

      for (let i = currentHour - 1; i >= 0; i--) {
        hours.push(i)
      }

      return hours
    } else {
      return []
    }
  }, [defaultValue])

  const disabledMinutes = useCallback(
    (selectedHour: number) => {
      if (defaultValue) {
        const minutes = []
        const currentHour = Number(`${defaultValue[0]}`.split(':')[0])
        const currentMinute = Number(`${defaultValue[0]}`.split(':')[1].split(' ')[0])

        if (selectedHour === currentHour) {
          for (let i = currentMinute - 1; i >= 0; i--) {
            minutes.push(i)
          }
        }

        return minutes
      } else {
        return []
      }
    },
    [defaultValue]
  )

  const disabledTime = useCallback(
    (_: EventValue<dayjs.Dayjs>, type: 'start' | 'end') => {
      return {
        disabledHours: type === 'end' ? disabledHours : () => [],
        disabledMinutes: type === 'end' ? disabledMinutes : () => [],
      }
    },
    [disabledHours, disabledMinutes]
  )

  useEffect(() => {
    const inputs = timePickerRef.current?.getElementsByTagName('input')
    const handlePanelClick = (e: MouseEvent) => {
      // Checking if inner cell is clicked
      if (!!e.target && e.target instanceof Element && e.target.classList.contains('ant-picker-time-panel-cell-inner')) {
        // Picker should have 2 inputs
        if (inputs?.length === 2) {
          // 'mouseup' fires before input values change,
          // thus we use a timeout to ensure accurate input value checking
          setTimeout(() => {
            // If both inputs have selected time value enable selectionChange
            if (Array.from(inputs).some((input) => !!input.value)) {
              setSelectionChange(true)
            }
          }, 100)
        }
      }
    }
    if (open && panelRef.current) {
      // Since there is no callback for time selection change in TimePicker.Range
      // need to manage it manually with panel click listener
      panelRef.current.addEventListener('mouseup', handlePanelClick)
    }

    return () => {
      if (open && panelRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        panelRef.current.removeEventListener('mouseup', handlePanelClick)
      }
    }
  }, [open])

  return (
    <ConfigProvider
      theme={{
        token: timePickerV2ThemeProps,
      }}
    >
      <div className={classNames(rootClass, className)} data-test={dataTest} ref={timePickerRef}>
        <TimePickerV2Label
          labelKey={label}
          tooltipContent={tooltipContent}
          required={required}
          dataTest={`${dataTest}-label`}
          smallLabel={smallLabel}
          rootClass={rootClass}
          disabled={disabled}
        />
        <TimePicker.RangePicker
          renderExtraFooter={renderFooter}
          panelRender={panelRender}
          onChange={handleChangeSubmit}
          onOpenChange={handleOpenChange}
          disabledTime={disabledTime}
          onFocus={onFocus}
          onBlur={onBlur}
          separator={<Svg name={SvgNames.datePickerArrow} type={SvgType.ICON} />}
          suffixIcon={<TimePickerV2SuffixIcon open={open} disabled={disabled} haveError={!!errorMessage} dataTest={dataTest} rootClass={rootClass} />}
          clearIcon={
            <Svg
              className={`${rootClass}__clear-icon`}
              name={SvgNames.clearIndicator}
              fill={SvgColor.TAB_GRAY}
              hoverFill={SvgColor.TEXT_GRAY}
              type={SvgType.VERY_LARGE_ICON}
            />
          }
          allowClear={allowClear}
          showNow={false}
          use12Hours
          format={format}
          hourStep={hourStep}
          minuteStep={minuteStep}
          secondStep={secondStep}
          defaultValue={_defaultValue}
          status={errorMessage && !open ? 'error' : undefined}
          disabled={disabled}
          className={classNames(`${rootClass}-picker`, {
            ['ant-picker-focused__popup-open']: open,
          })}
          popupClassName={classNames(`${rootClass}-panel`, {
            [`${rootClass}__parent-modal`]: isParentModal,
          })}
          data-test={`${rootClass}-input`}
        />
        <TimePickerV2ErrorMessage errorMessage={errorMessage} dataTest={dataTest} hideErrorIcon={hideErrorIcon} />
        <TimePickerV2HelperMessage helperMessage={helperMessage} dataTest={dataTest} />
      </div>
    </ConfigProvider>
  )
}

export default TimeRangePicker
