import { useContext, createContext, Context, ConsumerProps } from 'react'

import { AccountSettings } from '@graphql/types/query-types'

export const NAMESPACE = 'account'

export const LOG_OUT = 'LOG_OUT'

export const defaultValue = Symbol('context default value')
type DefaultValue = Symbol | {}

export function createSafeContext<T>() {
  return createContext<T | DefaultValue>(defaultValue)
}

export function useSafeContext<T>(TheContext: Context<T | DefaultValue>): T {
  const value = useContext(TheContext)
  if (value === defaultValue) throw new Error('no value provided for context')
  return value as T
}

export function createSafeConsumer<T>(TheContext: Context<T | DefaultValue>) {
  return function SafeConsumer({ children }: ConsumerProps<T>) {
    const value = useSafeContext(TheContext)
    return children(value)
  }
}

export const AccountContext = createSafeContext<AccountSettings>()

export const useAccountSettings = () => useSafeContext(AccountContext)
export const AccountSettingsConsumer = createSafeConsumer(AccountContext)

export const useGetFeatureFlag = (field: keyof AccountSettings) => {
  const accountSettings = useAccountSettings()

  if (accountSettings) {
    if (!Object.keys(accountSettings).length) {
      return false
    }

    const setting: any = accountSettings[field]
    if (typeof setting === 'boolean') {
      return setting as boolean
    }
  }

  throw new Error('field is not a feature flag')
}
