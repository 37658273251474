export const timePickerV2ThemeProps = {
  fontFamily: 'Open Sans',
  colorTextDisabled: '#767676',
  colorBgContainerDisabled: '#F2F2F299',
  colorPrimaryHover: '#AEAEAE',
  colorPrimaryBg: '#E5F9F9',
  colorTextBase: '#444444',
  colorTextPlaceholder: '#767676',
  colorBorder: '#E3E3E3',
  colorBorderSecondary: '#E3E3E3',
  colorError: '#F25656',
  borderRadius: 4,
}
