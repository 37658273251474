import React, { FC, useState } from 'react'

import classNames from 'classnames'

import Caution from '@components/Caution/Caution'
import InputV2 from '@components/InputV2/InputV2'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import ModalFooterV2, { ModalFooterV2ButtonProps } from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import ModalHeaderV2 from '@components/Modal/components/ModalHeaderV2/ModalHeaderV2'
import Modal, { ModalBody } from '@components/Modal/index'
import Spinner from '@components/Spinner/Spinner'
import Svg, { SvgNames, SvgType } from '@components/Svg/index'
import { SvgColor } from '@components/Svg/Svg'
import { TableV2 } from '@components/TableV2/TableV2'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { SendTestEmailPersonalizationFallback } from '@graphql/types/query-types'
import SendTestConfirmationModal, {
  SendTestConfirmationModalStatus,
} from '@src/pages/EmailComposer/EmailModals/components/SendTestEmailModal/components/SendTestConfirmationModal/SendTestConfirmationModal'
import { CellContext } from '@tanstack/react-table'
import {
  contactFieldsFilter,
  contentPersonalizationFieldsFilter,
  FilterTypes,
  listOrSegmentFieldsFilter,
  msdynamicsFieldsFilter,
  netsuiteFieldsFilter,
  salesforceFieldsFilter,
  senderFieldsFilter,
  sugarCRMFieldsFilter,
  zendeskFieldsFilter,
} from '@utils/filter'

import './SendTestEmailPersonalizationModal.css'

export interface PersonalizationField {
  name: string
  value?: string
  type: FilterTypes
}

interface SendTestEmailPersonalizationModalProps {
  isOpen: boolean
  personalizationFields: PersonalizationField[]
  loadingSend: boolean
  onClose: (goBack?: boolean) => void
  onAction: (personalizationFallbacks: SendTestEmailPersonalizationFallback[]) => Promise<boolean>
  className?: string
  dataTest?: string
}

const getFilterTypeIcon = (type: FilterTypes) => {
  switch (type) {
    case FilterTypes.CONTACT_FIELDS:
      return contactFieldsFilter.svgUnselected
    case FilterTypes.CUSTOM_ACCOUNT_FIELDS:
      return contentPersonalizationFieldsFilter.svgUnselected
    case FilterTypes.MSDYNAMICS_FIELDS:
      return msdynamicsFieldsFilter.svgUnselected
    case FilterTypes.NETSUITE_FIELDS:
      return netsuiteFieldsFilter.svgUnselected
    case FilterTypes.RECIPIENT_LIST_FIELDS:
      return listOrSegmentFieldsFilter.svgUnselected
    case FilterTypes.SALESFORCE_FIELDS:
      return salesforceFieldsFilter.svgUnselected
    case FilterTypes.SENDER_FIELDS:
      return senderFieldsFilter.svgUnselected
    case FilterTypes.SUGAR_CRM_FIELDS:
      return sugarCRMFieldsFilter.svgUnselected
    case FilterTypes.ZENDESK_FIELDS:
      return zendeskFieldsFilter.svgUnselected
  }
}

const rootClass = 'send-test-email-personalization-modal'
const baseI18n = 'EmailComposer.SendTestEmailPersonalizationModal.'

const SendTestEmailPersonalizationModal: FC<SendTestEmailPersonalizationModalProps> = (props: SendTestEmailPersonalizationModalProps) => {
  const { dataTest = rootClass, className = '', onAction, onClose, isOpen, personalizationFields, loadingSend } = props

  const [sendStatus, setSendStatus] = useState<SendTestConfirmationModalStatus>()
  const [personalizationFallbacks, setPersonalizationFallbacks] = useState<SendTestEmailPersonalizationFallback[]>(
    personalizationFields.map((field) => ({ personalization: field.name, fallback: '' }))
  )

  const { t } = useTranslation()

  const handleAction = () => {
    onAction(personalizationFallbacks)
      .then((resSuccess) => setSendStatus(resSuccess ? SendTestConfirmationModalStatus.SUCCESS : SendTestConfirmationModalStatus.ERROR))
      .catch(() => setSendStatus(SendTestConfirmationModalStatus.ERROR))
  }

  const handleClose = (goBack?: boolean) => {
    onClose(goBack)
  }

  const onChangePersonalizationField = (personalization: string, value: string) => {
    const updatedPersonalizationFallbacks = personalizationFallbacks.map((personalizationFallback) => {
      if (personalizationFallback.personalization === personalization) {
        return { ...personalizationFallback, fallback: value }
      } else {
        return personalizationFallback
      }
    })
    setPersonalizationFallbacks(updatedPersonalizationFallbacks)
  }

  const getPersonalizationCellValue = (cell: CellContext<PersonalizationField, any>) => {
    return personalizationFallbacks.find((fallback) => fallback.personalization === cell.cell.row.original['name'])?.fallback
  }

  const header = (
    <ModalHeaderV2
      headerType={ModalHeaderType.Form}
      headerText={t(`${baseI18n}Title`)}
      iconProps={{ hasBackButton: true, onClick: () => handleClose(true) }}
      className={`${rootClass}__header`}
    />
  )

  const footerButtons: ModalFooterV2ButtonProps = {
    actionButtonOnClick: handleAction,
    actionButtonLabel: t(`${baseI18n}SendButton`),
    actionButtonDisabled: loadingSend,
    cancelButtonLabel: t('Cancel'),
  }

  const personalizationFieldsColumns: ColumnDefWithAdditionalProps<PersonalizationField>[] = [
    {
      header: t(`${baseI18n}Field`),
      accessorKey: 'name',
      textAlign: 'left',
      cell: (cell: CellContext<PersonalizationField, any>) => (
        <div className={`${rootClass}__field`}>
          <Svg
            name={getFilterTypeIcon(cell.cell.row.original.type) ?? SvgNames.crmCloudSolid}
            type={SvgType.LARGER_ICON}
            fill={SvgColor.LIGHT_GRAY}
            dataTest={`${dataTest}-type-icon`}
          />
          <TextWithTooltipOnEllip
            typographyProps={{ text: cell.getValue(), weight: TextWeight.MEDIUM }}
            tooltipProps={{ children: cell.getValue() }}
          />
        </div>
      ),
    },
    {
      header: t(`${baseI18n}Fallback`),
      accessorKey: 'value',
      textAlign: 'left',
      enableLongContent: true,
      cell: (cell: CellContext<PersonalizationField, any>) => (
        <InputV2
          placeholder={t(`${baseI18n}Input.Placeholder`)}
          value={getPersonalizationCellValue(cell)}
          onBlur={(event) => onChangePersonalizationField(cell.cell.row.original['name'], event.target.value)}
        />
      ),
    },
  ]

  if (sendStatus && !loadingSend) {
    return <SendTestConfirmationModal onResend={handleAction} onCancel={() => onClose(false)} status={sendStatus} />
  }

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header} paddingV2>
      <ModalBody className={`${rootClass}__body`}>
        {loadingSend ? (
          <Spinner className={`${rootClass}__spinner`} text="Send.Test.Email.Spinner.Text" dataTest={`${dataTest}-spinner`} />
        ) : (
          <>
            <Caution
              alignTop
              message={
                <Typography
                  text={t(`${baseI18n}Caution`, { count: personalizationFields.length })}
                  tagProps={{ medium: { weight: TextWeight.MEDIUM } }}
                />
              }
              className={`${rootClass}__caution`}
            />
            <TableV2 data={personalizationFields} columns={personalizationFieldsColumns} withoutBorders />
          </>
        )}
      </ModalBody>
      <ModalFooterV2
        footerType={ModalFooterType.Form}
        buttons={footerButtons}
        onClose={() => handleClose(false)}
        className={`${rootClass}__footer`}
        dataTest={`${dataTest}-footer`}
      />
    </Modal>
  )
}

export default SendTestEmailPersonalizationModal
