import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/Button'
import InfoTooltip, { InfoTooltipIconSize } from '@components/InfoTooltip/InfoTooltip'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { FilterDefinition } from '@utils/filter'
import { formatNumber } from '@utils/numbers'

import './Filter.css'

interface Props {
  className?: string
  dataTest?: string
  action: (filter: FilterDefinition) => void
  filter: FilterDefinition
  count?: number
  hideCount?: boolean
  icon?: SvgNames | undefined
  textLineHeight?: LineHeight
  textWeight?: TextWeight
  isActive?: boolean
  svgType?: SvgType
}

const rootClass = 'filter'

const Filter: FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const {
    action,
    filter,
    icon,
    isActive = false,
    dataTest = rootClass,
    className = '',
    textLineHeight = LineHeight.MEDIUM_LARGE,
    textWeight = TextWeight.BOLD,
    svgType = SvgType.ICON,
    count,
    hideCount = false,
  } = props

  return (
    <Button
      buttonType={ButtonType.TEXT}
      className={classNames(rootClass, className, { [`${rootClass}--active`]: isActive })}
      dataTest={dataTest}
      disabled={filter.disabled}
      onClick={() => action(filter)}
    >
      {icon && <Svg className={classNames(`${rootClass}__icon`, { [`${rootClass}__icon-active`]: isActive })} name={icon} type={svgType} />}
      {!filter.tooltip && (
        <Typography
          className={classNames('ellip', `${rootClass}__name`)}
          lineHeight={textLineHeight}
          text={t(filter.name)}
          weight={isActive ? textWeight : TextWeight.REGULAR}
          inline
        />
      )}
      {filter.tooltip && (
        <div className={classNames(`${rootClass}__name`, `${rootClass}__name-container`)}>
          <Typography
            className={'ellip'}
            lineHeight={textLineHeight}
            text={t(filter.name)}
            weight={isActive ? textWeight : TextWeight.REGULAR}
            inline
          />
          {filter.tooltipIcon === SvgNames.lock ? (
            <Tooltip trigger={<Svg name={filter.tooltipIcon} type={SvgType.ICON} />} triggerClassName={`${rootClass}__name-lock`}>
              {t(filter.tooltip)}
            </Tooltip>
          ) : (
            <InfoTooltip inline={false} iconSize={InfoTooltipIconSize.SMALL}>
              <Typography
                text={t(filter.tooltip)}
                type={TextType.BODY_TEXT_WHITE}
                tagProps={{ medium: { weight: TextWeight.MEDIUM, inline: true } }}
              />
            </InfoTooltip>
          )}
        </div>
      )}
      {count !== undefined && !hideCount && (
        <Typography type={TextType.BODY_TEXT_LIGHT} text={formatNumber(count || 0)} className={`${rootClass}__count`} />
      )}
    </Button>
  )
}

export default Filter
