import { Dispatch, SetStateAction } from 'react'

import { YesNo } from '@components/ConfirmationModal'
import { rootContext } from '@const/globals'

export interface CustomizeState {
  isEditingAppearance: boolean
  isEditingAlerts: boolean
  showResetModal: boolean
  showPublishModal: boolean
  showDiscardModal: boolean
  showSaveBanner: boolean
  imageButtonActive: boolean
  customTitle: CustomTitle
  customLogo: CustomLogo
  customAlertAddress: CustomAlertAddress
  selectedFile?: File
}

export interface CustomLogo {
  editingLogo?: string
  appliedLogo?: string
  appliedLogoBase64?: string | ArrayBuffer | null
}

export interface CustomTitle {
  editingTitle?: string
  appliedTitle?: string
}

export interface CustomAlertAddress {
  editingAddress?: string
  appliedAddress?: string
}

export const MAX_IMAGE_SIZE = 10000
export const DEFAULT_TITLE = 'Act-On Service'
export const DEFAULT_ALERT_ADDRESS = 'alert@alerts.act-on.com'
export const DEFAULT_LOGO = `${rootContext}/static/image/logo.png`

export const defaultCustomLogoState: CustomLogo = {
  editingLogo: undefined,
  appliedLogo: undefined,
  appliedLogoBase64: undefined,
}

export const defaultCustomTitleState: CustomTitle = {
  editingTitle: undefined,
  appliedTitle: undefined,
}

export const defaultCustomAlertAddress: CustomAlertAddress = {
  editingAddress: undefined,
  appliedAddress: undefined,
}

export const defaultState: CustomizeState = {
  isEditingAppearance: false,
  isEditingAlerts: false,
  showResetModal: false,
  showDiscardModal: false,
  showPublishModal: false,
  showSaveBanner: false,
  imageButtonActive: false,
  customTitle: { ...defaultCustomTitleState },
  customLogo: { ...defaultCustomLogoState },
  customAlertAddress: { ...defaultCustomAlertAddress },
}

export const resetToSystemDefaults: CustomizeState = {
  isEditingAppearance: false,
  isEditingAlerts: false,
  showResetModal: false,
  showDiscardModal: false,
  showPublishModal: false,
  showSaveBanner: true,
  imageButtonActive: false,
  customTitle: { ...defaultCustomTitleState, appliedTitle: DEFAULT_TITLE },
  customLogo: { ...defaultCustomLogoState, appliedLogo: DEFAULT_LOGO },
  customAlertAddress: { ...defaultCustomAlertAddress, appliedAddress: DEFAULT_ALERT_ADDRESS },
}

export const getCustomizeModalProps = (
  state: CustomizeState,
  setState: Dispatch<SetStateAction<CustomizeState>>,
  onSave: (customTitle?: string, customLogo?: string, customAlertAddress?: string) => void
) => {
  return {
    showResetProps: {
      isOpen: state.showResetModal,
      onAnswer: (answer: YesNo) => {
        if (answer === YesNo.YES) {
          setState({ ...resetToSystemDefaults })
        } else {
          setState({ ...state, showResetModal: false, isEditingAppearance: false, isEditingAlerts: false })
        }
      },
    },
    showPublishProps: {
      isOpen: state.showPublishModal,
      onAnswer: (answer: YesNo) => {
        if (answer === YesNo.YES) {
          setState({
            ...state,
            showSaveBanner: false,
            showPublishModal: false,
            isEditingAlerts: false,
            isEditingAppearance: false,
          })

          onSave(state.customTitle.appliedTitle, state.customLogo.appliedLogo, state.customAlertAddress.appliedAddress)
        } else {
          setState({
            ...state,
            showPublishModal: false,
            isEditingAppearance: false,
            isEditingAlerts: false,
          })
        }
      },
    },
    showDiscardProps: {
      isOpen: state.showDiscardModal,
      onAnswer: (answer: YesNo) => {
        if (answer === YesNo.YES) {
          setState({ ...defaultState })
        } else {
          setState({
            ...state,
            showDiscardModal: false,
            isEditingAppearance: false,
            isEditingAlerts: false,
          })
        }
      },
    },
  }
}
