import React, { FC } from 'react'

import EmptyListing, { EmptyListingProps, EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import Table, { TableProps } from '@components/Table/Table'

type Props = TableProps &
  EmptyListingProps & {
    loading: boolean
  }

/**
 * @deprecated use <TableV2 instead
 */
const TableWithEmptyListing: FC<Props> = (props: Props) => {
  const { data, loading, emptyText, className } = props

  if (loading) {
    return null
  }

  const tableProps: TableProps = { ...props, className }
  const emptyListingProps: EmptyListingProps = { ...props, className }
  return data.length > 0 || emptyText ? (
    <Table className={className} {...tableProps} />
  ) : (
    <EmptyListing className={className} size={EmptyListingSize.MEDIUM} {...emptyListingProps} />
  )
}

export default TableWithEmptyListing
