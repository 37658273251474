import React, { FC } from 'react'

import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import { ModalHeader } from '@components/Modal'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Svg, { SvgNames } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { ModalHeaderFormStyle } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

interface Props {
  refreshCRM: () => void
}

const rootClass = 'import-sales-users-modal__header'
const dataTest = rootClass

const ImportSalesUsersModalHeader: FC<Props> = ({ refreshCRM }) => {
  const { t } = useTranslation()

  return (
    <ModalHeader headerType={ModalHeaderType.Form} className={rootClass} dataTest={dataTest}>
      <Typography text={t('Import.Sales.Users.Modal.Title')} {...ModalHeaderFormStyle} />
      <Tooltip
        position="left"
        trigger={
          <Button
            buttonType={ButtonType.FLOAT}
            onClick={refreshCRM}
            iconPosition={ButtonIconPosition.LEFT}
            dataTest={`${dataTest}-button`}
            className={`${rootClass}-button`}
          >
            <Svg name={SvgNames.optimize} />
            {t('Refresh CRM data')}
          </Button>
        }
      >
        {t('Refresh.CRM.Data.Tooltip')}
      </Tooltip>
    </ModalHeader>
  )
}

export default ImportSalesUsersModalHeader
