import React, { FC, ReactNode, useRef, useState } from 'react'

import classNames from 'classnames'

import Container from '@components/Container/Container'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2Props } from '@components/SelectV2/SelectV2.props'
import { SvgNames } from '@components/Svg'
import { PopoverContentProps } from '@radix-ui/react-popover'
import * as Popover from '@radix-ui/react-popover'

import './SelectV2Popover.css'

export interface SelectV2PopoverProps extends PopoverContentProps {
  renderTrigger: (text?: string) => ReactNode
  selectProps: Partial<SelectV2Props>
  hideSearchInput?: boolean
  renderAnchor?: () => ReactNode
  className?: string
  dataTest?: string
  isDisabled?: boolean
  maxMenuHeight?: number
}

const rootClass = 'selectV2-popover'

const SelectV2Popover: FC<SelectV2PopoverProps> = (props: SelectV2PopoverProps) => {
  const {
    selectProps,
    selectProps: { onChange },
    className,
    align,
    hideSearchInput = false,
    isDisabled,
    renderAnchor,
    renderTrigger,
    maxMenuHeight,
  } = props

  const [showSelect, setShowSelect] = useState<boolean>(selectProps.menuIsOpen ?? false)
  const menuPortalTarget = useRef<HTMLElement>(null)

  const renderSelectContainerWrapper = (children: ReactNode) => (
    <Container className={classNames(`${rootClass}__container`, { [`${rootClass}__container-hide-search`]: hideSearchInput })}>{children}</Container>
  )

  const renderPopoverTrigger = () => (
    <Popover.Trigger disabled={isDisabled} className={`${rootClass}-trigger`} data-test={`${rootClass}-trigger`} asChild>
      {renderTrigger()}
    </Popover.Trigger>
  )

  return (
    <div className={classNames(rootClass, className, { [`${rootClass}__open`]: showSelect })} data-test={rootClass}>
      <Popover.Root onOpenChange={(open) => setShowSelect(open)} open={showSelect}>
        {renderAnchor ? (
          <Popover.Anchor className={classNames(`${rootClass}__anchor`, `${className}__anchor`)}>
            {renderAnchor()}
            {renderPopoverTrigger()}
          </Popover.Anchor>
        ) : (
          renderPopoverTrigger()
        )}
        <Popover.Content align={align} sideOffset={6} onEscapeKeyDown={() => setShowSelect(false)} hideWhenDetached={true}>
          <SelectV2
            {...selectProps}
            menuPortalTarget={menuPortalTarget?.current}
            onChange={(option) => {
              onChange && onChange(option)
              setShowSelect(!option)
            }}
            hideDropdownIndicator
            inputIcon={SvgNames.search}
            isClearable={false}
            renderSelectContainerWrapper={renderSelectContainerWrapper}
            isSearchable
            menuIsOpen
            nestedSearch
            menuShouldScrollIntoView
            maxMenuHeight={maxMenuHeight ?? 240}
            className={classNames(`${rootClass}__selectV2`, { [`${rootClass}__selectV2__hide-search-input`]: hideSearchInput })}
            dataTest={`${rootClass}-select`}
          />
        </Popover.Content>
      </Popover.Root>
    </div>
  )
}

export default SelectV2Popover
