import React, { CSSProperties, FC, useEffect, useMemo, useRef } from 'react'
import { VariableSizeList } from 'react-window'

import classNames from 'classnames'

import { MergeStrategy } from '@complex/ImportContacts/ImportContacts.constants'
import Button, { ButtonType } from '@components/Button'
import { ButtonWeight } from '@components/Button/Button'
import { Checkbox } from '@components/Checkbox/Checkbox'
import Container from '@components/Container'
import CustomPrompt from '@components/CustomPrompt/CustomPrompt'
import InfoTooltip, { InfoTooltipIconSize } from '@components/InfoTooltip/InfoTooltip'
import Input from '@components/Input/Input'
import SectionHeadline from '@components/SectionHeadline'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { ListItem } from '@components/SimpleList/SimpleList'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import ColumnState from '@src/pages/importcontacts/components/ColumnState/ColumnState'
import DataReviewSidebar from '@src/pages/importcontacts/components/MappingScreen/components/DataReviewSidebar/DataReviewSidebar'
import { getDuplicates } from '@src/pages/importcontacts/components/MappingScreen/utils/MappingScreen.utils'
import {
  ColumnStateType,
  DataTypeList,
  DateFormatDisplay,
  DateTimeFormatDisplay,
  MappingPreview,
} from '@src/pages/importcontacts/context/ImportContactsContext'
import { IMPORT_CONTACTS_URL } from '@src/pages/importcontacts/utils/ImportContactsContainerUtils'
import { EXTERNAL_ID_FIELD_KEY } from '@src/pages/ImportContactsV2/utils/ImportContactsV2.constants'

import './MappingScreen.css'

interface AuxiliaryFunctions {
  handleCustomField: (index: number, isCustom: boolean) => void
  handleDataFormatChange: (index: number, value: string) => void
  handleDataTypeChange: (index: number, dataType: string) => void
  handleFieldChange: (index: number, fieldMapping: UnifiedListFieldMapping) => void
  handleFieldInput: (index: number, value: string) => void
  handleMergeChange: (index: number, value: string) => void
  setPreviewIndex: (index?: number) => void
}

type Props = {
  className?: string
  dataTest?: string
  fileSelected?: File | null
  listName: string
  mappingPreview: MappingPreview[]
  mergeStrategy?: MergeStrategy
  previewHeader: string[]
  previewIndex: number | undefined
  previewRecords: ListItem[][]
  shouldChangeUrlPath?: boolean
  visibleFields: UnifiedListFieldMapping[]
} & AuxiliaryFunctions

const rootClass = 'mapping-screen'
const IMPORT_CONTACTS_MAPPING_PATH = '/mapping'
const FIXED_SIZE_LIST_HEIGHT = 400
const SMALL_ROW_HEIGHT = 98
const LARGE_ROW_HEIGHT = 182

const mergeOptions = [
  { value: 'IF_EMPTY', label: 'Fill if blank' },
  { value: 'OVERWRITE', label: 'Overwrite' },
]

const MappingScreen: FC<Props> = (props: Props) => {
  const {
    dataTest = rootClass,
    className = '',
    previewHeader,
    previewIndex,
    previewRecords,
    shouldChangeUrlPath = true,
    mappingPreview,
    mergeStrategy,
    visibleFields,
    fileSelected,
    listName,
    handleFieldChange,
    handleDataTypeChange,
    handleDataFormatChange,
    handleCustomField,
    handleFieldInput,
    handleMergeChange,
    setPreviewIndex,
  } = props

  const listRef = useRef<VariableSizeList>(null)

  const duplicates = useMemo(() => getDuplicates(mappingPreview), [mappingPreview])

  const fieldErrors = useMemo(
    () =>
      previewRecords.reduce((items, item) => {
        return previewIndex != undefined ? [...items, item[previewIndex]] : items
      }, []),
    [previewIndex, previewRecords]
  )

  const visibleFieldsOptions = useMemo(() => {
    return visibleFields.map<SelectV2SingleOption>(({ columnIndex, displayName = '', standardFieldKey }) => ({
      label: displayName,
      value: `${columnIndex}`,
      isDisabled: mergeStrategy === MergeStrategy.ExternalId && standardFieldKey === EXTERNAL_ID_FIELD_KEY,
      extraOptions: {
        standardFieldKey,
      },
    }))
  }, [mergeStrategy, visibleFields])

  const dataTypeOptions = useMemo(() => {
    return DataTypeList.map<SelectV2SingleOption>(({ label, id }) => ({
      label,
      value: id,
    }))
  }, [])

  const { t } = useTranslation()

  useEffect(() => {
    const showCustomPrompt = (e: Event) => {
      e.preventDefault()
      e.returnValue = true
      return true
    }

    if (shouldChangeUrlPath && location.pathname.includes(IMPORT_CONTACTS_URL) && !location.pathname.includes(IMPORT_CONTACTS_MAPPING_PATH)) {
      history.replaceState({}, '', `${location.pathname}${IMPORT_CONTACTS_MAPPING_PATH}`)
    }
    window.addEventListener('beforeunload', showCustomPrompt)

    return () => {
      window.removeEventListener('beforeunload', showCustomPrompt)
      if (shouldChangeUrlPath && location.pathname.includes(IMPORT_CONTACTS_URL) && location.pathname.includes(IMPORT_CONTACTS_MAPPING_PATH)) {
        history.replaceState({}, '', location.pathname.replace(`${IMPORT_CONTACTS_MAPPING_PATH}`, ''))
      }
    }
  }, [])

  const handleMappingFieldChange = (column: MappingPreview, value: string) => {
    const fieldMapping = visibleFields.find(({ columnIndex }) => columnIndex.toString() === value)
    handleFieldChange(column.index, fieldMapping as UnifiedListFieldMapping)
  }

  const renderHeaders = () => {
    return (
      <div className={classNames(`${rootClass}__grid`, `${rootClass}__grid-headers`, `${rootClass}__title-text`)}>
        <div className={classNames(`${rootClass}__grid-columns`, `${rootClass}__grid-header`, `${rootClass}__title`)}>
          <Typography text={t('COLUMNS')} type={TextType.FIELD_MAPPING_SUB_HEADER} weight={TextWeight.BOLD} />
        </div>
        <div className={classNames(`${rootClass}__grid-arrow`, `${rootClass}__grid-header`, `${rootClass}__title`)}>
          <div className={classNames(`${rootClass}__title-text`, `${rootClass}__icon`)}>
            <Svg name={SvgNames.arrowRight} type={SvgType.LARGER_ICON} className={`${rootClass}__icon-arrow`} />
          </div>
        </div>
        <div className={classNames(`${rootClass}__grid-existing`, `${rootClass}__grid-header`, `${rootClass}__title`)}>
          <Typography text={t('EXISTING FIELDS')} type={TextType.FIELD_MAPPING_SUB_HEADER} weight={TextWeight.BOLD} />
        </div>
        <div className={classNames(`${rootClass}__grid-data`, `${rootClass}__grid-header`, `${rootClass}__title`)}>
          <Typography text={t('DATA TYPE')} type={TextType.FIELD_MAPPING_SUB_HEADER} weight={TextWeight.BOLD} />
        </div>
        <div className={classNames(`${rootClass}__grid-merge`, `${rootClass}__grid-header`, `${rootClass}__title`)}>
          <Typography text={t('MERGE RULE')} type={TextType.FIELD_MAPPING_SUB_HEADER} weight={TextWeight.BOLD} />
          <InfoTooltip className={`${rootClass}__grid-merge-tooltip`} iconSize={InfoTooltipIconSize.SMALL}>
            {t('When a field already has a value in Act-On, choose how to handle the data from your file.')}
          </InfoTooltip>
        </div>
      </div>
    )
  }

  const renderFormatOptions = (formatOptions: SelectV2SingleOption[], column: MappingPreview) => {
    const { dataFormat, index, mappingIndex } = column
    const formatSelectedOption = formatOptions.find(({ value }) => value === dataFormat)
    return (
      <div className={`${rootClass}__format-container`}>
        <div className={`${rootClass}__format-wrapper`}>
          <div className={`${rootClass}__format-text`}>
            <Typography text={t('Use Data Preview and choose your incoming date format:')} />
          </div>
          <div className={`${rootClass}__format-options`}>
            <SelectV2
              key={`${index}-${mappingIndex}`}
              placeholder={t('Select')}
              truncateOptions
              hideCheckMark
              options={formatOptions}
              defaultValue={formatSelectedOption}
              onChange={(option) => handleDataFormatChange(index, option?.value || '')}
            />
          </div>
        </div>
      </div>
    )
  }

  const getFieldStateIcon = (columnState: ColumnStateType | null | undefined) =>
    columnState && <ColumnState dataTest={`${rootClass}-column-state`} state={columnState} />

  const getColumnState = (duplicatesCount: number, isCustomAndDuplicate: boolean, isSelectedAndDuplicate: boolean, column: MappingPreview) => {
    const { isNewCustomFieldDeleted, isNewCustomFieldInUse, state } = column

    if (duplicatesCount > 1 || isCustomAndDuplicate || isSelectedAndDuplicate) {
      return ColumnStateType.DUPLICATE
    }
    if (isNewCustomFieldDeleted) {
      return ColumnStateType.CUSTOM_FIELD_DELETED
    }
    if (isNewCustomFieldInUse) {
      return ColumnStateType.CUSTOM_FIELD_IN_USE
    }
    return state
  }

  const renderRow = ({ index, style }: { index: number; style: CSSProperties }) => {
    const column = mappingPreview[index]
    const { dataType, friendlyName, index: columnIndex, isCustom, mappingIndex, displayName } = column
    const type = DataTypeList.find((type) => type.id === dataType)
    const isCustomAndDuplicate = duplicates.includes(friendlyName)
    const isSelectedAndDuplicate = duplicates.includes(displayName as string)
    const duplicatesCount = mappingPreview.reduce((total, { mappingIndex: duplicateMappingIndex }) => {
      const isDuplicate = mappingIndex !== undefined && mappingIndex !== '' && mappingIndex === duplicateMappingIndex
      return total + (isDuplicate ? 1 : 0)
    }, 0)

    if (type?.formatOptions && style.height !== LARGE_ROW_HEIGHT) {
      setTimeout(() => listRef.current?.resetAfterIndex(index), 500)
    }

    const formatOptions = type?.formatOptions?.map<SelectV2SingleOption>((option) => ({
      label: type.id === 'DATE' ? DateFormatDisplay[option] : type.id === 'DATETIME' ? DateTimeFormatDisplay[option] : option,
      value: option,
    }))

    const visibleFieldSelectedOption = visibleFieldsOptions.find(({ value }) => value === `${mappingPreview[columnIndex].mappingIndex}`)
    const dataTypeSelectedOption = dataTypeOptions.find(({ value }) => value === mappingPreview[column.index].dataType)
    const mergeSelectedOption = mergeOptions.find(({ value }) => value === column.merge)
    const columnState = getColumnState(duplicatesCount, isCustomAndDuplicate, isSelectedAndDuplicate, column)

    const isDisabled =
      mergeStrategy === MergeStrategy.ExternalId && visibleFieldSelectedOption?.extraOptions?.standardFieldKey === EXTERNAL_ID_FIELD_KEY

    return (
      <div style={style} key={index} data-test={`${dataTest}-field-${index}`} className={`${rootClass}__row-container`}>
        <div
          className={classNames(`${rootClass}__grid`, `${rootClass}__grid-field-row`, {
            [`${rootClass}__grid-selected`]: index === previewIndex,
          })}
          key={index}
        >
          <div className={`${rootClass}__grid-column`}>
            <TextWithTooltipOnEllip typographyProps={{ text: previewHeader[index] }} />
            <Button
              className={`${rootClass}__grid-column-preview-button`}
              buttonType={ButtonType.TEXT_TEAL}
              weight={ButtonWeight.MEDIUM}
              onClick={() => setPreviewIndex(index)}
            >
              <>
                <Svg name={SvgNames.previewOn} />
                {t('Preview')}
              </>
            </Button>
          </div>
          <div className={classNames(`${rootClass}__grid-arrow`, `${rootClass}__title`)}>
            <span className={`${rootClass}__title-text`}>{getFieldStateIcon(columnState)}</span>
          </div>
          <div className={classNames(`${rootClass}__grid-existing`, `${rootClass}__title`)}>
            {isCustom ? (
              <>
                <Input
                  defaultValue={friendlyName}
                  onBlur={(e) => handleFieldInput(columnIndex, e.target.value)}
                  dataTest={dataTest}
                  className={rootClass}
                  placeholder={t('Enter New Field Name')}
                />
              </>
            ) : (
              <SelectV2
                key={`${columnIndex}-${mappingIndex}`}
                placeholder={t('Will not import')}
                truncateOptions
                hideCheckMark
                options={visibleFieldsOptions}
                defaultValue={visibleFieldSelectedOption}
                onChange={(option) => {
                  handleMappingFieldChange(column, option?.value || '')
                  listRef.current?.resetAfterIndex(index)
                }}
                isDisabled={isDisabled}
              />
            )}
            <Checkbox
              tabIndex={-1}
              checked={isCustom}
              onChange={() => {
                handleCustomField(index, isCustom)
                listRef.current?.resetAfterIndex(index)
              }}
              label={t('Create a new field')}
              disabled={isDisabled}
            />
          </div>
          <div className={classNames(`${rootClass}__grid-data`, `${rootClass}__title`)}>
            {!column.dataType || column.isCustom ? (
              <SelectV2
                key={`${columnIndex}-${mappingIndex}`}
                placeholder={t('Select')}
                truncateOptions
                hideCheckMark
                isDisabled={!column.isCustom}
                options={dataTypeOptions}
                defaultValue={dataTypeSelectedOption}
                onChange={(option) => {
                  handleDataTypeChange(column.index, option?.value || '')
                  listRef.current?.resetAfterIndex(index)
                }}
              />
            ) : (
              <Typography className={`${rootClass}__text`} text={column.dataType.toLowerCase()} />
            )}
          </div>
          <div className={classNames(`${rootClass}__grid-merge`, `${rootClass}__title`)}>
            <SelectV2
              key={`${mergeSelectedOption?.value || columnIndex}-${mappingIndex}`}
              className={rootClass}
              truncateOptions
              hideCheckMark
              isClearable={false}
              isDisabled={column.isCustom || isDisabled}
              options={mergeOptions}
              defaultValue={mergeSelectedOption}
              onChange={(option) => handleMergeChange(column.index, option?.value || '')}
            />
          </div>
        </div>
        {type && formatOptions && renderFormatOptions(formatOptions, column)}
      </div>
    )
  }

  const getItemSize = (index: number) => {
    const column = mappingPreview[index]
    const { dataType } = column
    const type = DataTypeList.find((type) => type.id === dataType)
    return type?.formatOptions ? LARGE_ROW_HEIGHT : SMALL_ROW_HEIGHT
  }

  return (
    <>
      {previewIndex !== undefined && (
        <DataReviewSidebar
          fieldName={previewHeader[previewIndex]}
          fieldsStatus={fieldErrors}
          fieldsAmount={mappingPreview.length}
          fieldIndex={previewIndex}
          setPreviewIndex={setPreviewIndex}
        />
      )}
      <CustomPrompt when title={'Incomplete Mapping'} body={'If you leave this page, any mapping work will be lost.'} okButtonText={'Leave page'} />
      <Container className={classNames(rootClass, className)} data-test={dataTest}>
        <div className={`${rootClass}__filename`}>
          <SectionHeadline>{t('Field mapping')}</SectionHeadline>
          <InfoTooltip>
            <Typography
              text={'MappingScreen.Headline.Tooltip'}
              type={TextType.BODY_TEXT_WHITE}
              values={{ fileName: fileSelected ? fileSelected.name : listName }}
            />
          </InfoTooltip>
        </div>
        <Typography type={TextType.BODY_TEXT_LIGHT}>{t('Map desired imported fields to Act-On Contacts fields.')}</Typography>
        <Typography type={TextType.BODY_TEXT_LIGHT} className={`${rootClass}__second-text`}>
          {t(
            'For new fields, use Data Preview and select the appropriate data type. Newly added fields will be available for all Act-On Contacts and can be managed in Data Management.'
          )}
        </Typography>
        <div className={`${rootClass}__grid`}>
          <div className={classNames(`${rootClass}__grid-left`, `${rootClass}__section`)}>
            <Typography text={t('Imported Fields')} type={TextType.FIELD_MAPPING_HEADER_SMALL} weight={TextWeight.BOLD} />
          </div>
          <div className={classNames(`${rootClass}__grid-right`, `${rootClass}__section`)}>
            <Typography text={t('Act-On Contacts')} type={TextType.NORMAL_TEXT_TEAL_SMALL} weight={TextWeight.BOLD} lineHeight={LineHeight.SMALL} />
          </div>
        </div>
        {renderHeaders()}
        <div className={`${rootClass}__grid-wrapper`}>
          <VariableSizeList height={FIXED_SIZE_LIST_HEIGHT} itemCount={mappingPreview.length} itemSize={getItemSize} width={'100%'} ref={listRef}>
            {renderRow}
          </VariableSizeList>
        </div>
      </Container>
    </>
  )
}

export default MappingScreen
