import { Action } from '@interface/Action'

import actionTypes from './actionTypes'

function showNavigation(): Action {
  return {
    type: actionTypes.showNavigation,
  }
}

function hideNavigation(): Action {
  return {
    type: actionTypes.hideNavigation,
  }
}

function hideMenu(): Action {
  return {
    type: actionTypes.hideMenu,
  }
}

export interface SetNavigationPathPayload {
  path?: string
}

export interface ToggleNavigationExpandedPayload {
  expanded: boolean
}

export interface ToggleNavigationDisabledPayload {
  disabled: boolean
}

export interface ToggleModalOpenedPayload {
  modalOpen: boolean
}

function setNavigationPath(path?: string) {
  return {
    type: actionTypes.setNavigationPath,
    payload: {
      path,
    },
  }
}

function toggleExpanded(expanded: boolean) {
  return {
    type: actionTypes.toggleExpanded,
    payload: {
      expanded,
    },
  }
}

function toggleDisabled(disabled: boolean) {
  return {
    type: actionTypes.toggleDisabled,
    payload: {
      disabled,
    },
  }
}

function toggleModalOpened(modalOpen: boolean) {
  return {
    type: actionTypes.toggleModalOpened,
    payload: {
      modalOpen,
    },
  }
}

export interface NavigationActions {
  showNavigation(): Action
  hideNavigation(): Action
  hideMenu(): Action
  setNavigationPath(path?: string): Action<SetNavigationPathPayload>
  toggleExpanded(expanded: boolean): Action<ToggleNavigationExpandedPayload>
  toggleDisabled(disabled: boolean): Action<ToggleNavigationDisabledPayload>
  toggleModalOpened(modalOpen: boolean): Action<ToggleModalOpenedPayload>
}

const actions: NavigationActions = {
  showNavigation,
  hideNavigation,
  hideMenu,
  setNavigationPath,
  toggleExpanded,
  toggleDisabled,
  toggleModalOpened,
}

export default actions
