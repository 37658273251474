import { gql } from '@apollo/client'

export default gql`
  query loadCustomLinkTab {
    loadCustomLinkTab {
      status
      message
      url
      hint
      text
      hidden
    }
  }
`
