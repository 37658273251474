import { gql } from '@apollo/client'

export default gql`
  mutation uploadMediaFile($base64File: String, $fileName: String, $extension: String, $folderName: String) {
    uploadMediaFile(base64File: $base64File, fileName: $fileName, extension: $extension, folderName: $folderName) {
      id
      title
      previewUrl
      linkUrl
      docType
      modified
      created
      isFavorite
      size
      downloadLink
    }
  }
`
