import React, { FC } from 'react'

import classNames from 'classnames'

import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { SMSContextAPI } from '@src/pages/sms/context/SMSContext'
import MessageComposer from '@src/pages/sms/edit/components/MessageComposer/MessageComposer'

import './InitialMessageEditor.css'

import Button, { ButtonType } from '@components/Button/Button'

type Props = {
  className?: string
  dataTest?: string
  context: SMSContextAPI
}

const explainerText = [
  {
    text: 'Opt-In date (required)',
  },
  {
    text: 'Your company name',
  },
  {
    text: 'How often you will send SMS',
  },
  {
    text: 'Click to start with a template',
    buttons: [
      {
        label: 'simple',
        template: 'Welcome to SMS from [insert company name]. You signed up on [date of Opt-in] for up to [X] msgs per [mo/wk].',
      },
      {
        label: 'legalese',
        template:
          'Thanks for opting into SMS from [insert company name] on [date of Opt-in]. Txt and data rates may apply. Up to X msgs per [mo/wk]. [privacy policy link without tracking]',
      },
    ],
  },
]

const optOutMsg = 'Txt STOP to OptOut'

const rootClass = 'initial-message-editor'

const InitialMessageEditor: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '', context } = props
  const { values, saveMessage } = context

  const { t } = useTranslation()

  const insertTemplate = (template: string) => {
    const updatedMsg = { ...values.smsMessage, messageText: template }
    saveMessage(updatedMsg)
    return false
  }

  const renderExplainerText = () => {
    const items = explainerText.map((text) => {
      const spanElements = text.buttons?.map((button) => {
        return (
          <Button
            dataTest={button.label}
            inline
            buttonType={ButtonType.TEXT}
            key={button.label}
            onClick={() => insertTemplate(`${button.template} ${optOutMsg}`)}
          >
            <Typography text={t(button.label)} type={TextType.LINK} className={`${rootClass}__${button.label}`} inline />
          </Button>
        )
      })

      return (
        <li key={text.text}>
          <Typography
            text={t(text.text)}
            type={TextType.BODY_TEXT}
            weight={TextWeight.MEDIUM}
            lineHeight={LineHeight.MEDIUM}
            inline={!!text.buttons}
          />
          {spanElements}
        </li>
      )
    })
    return <ul>{items}</ul>
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div>
        <Typography text={t('Message Composer')} type={TextType.SECTION_HEADER} className={`${rootClass}__header`} />
        <Typography
          text={t(
            'Send a one-time message to all Contacts who are currently opted in to SMS. Opt-Out instructions are automatically appended. Your message should include the following:'
          )}
          type={TextType.BODY_TEXT_LIGHT}
          lineHeight={LineHeight.MEDIUM}
        />
        {renderExplainerText()}
        <MessageComposer
          context={context}
          showCalendarIcon
          showUrlIcon
          showPersonalizationIcon={false}
          showOptOutIcon={false}
          isInitialMessageEditor
        />
      </div>
    </div>
  )
}

export default InitialMessageEditor
