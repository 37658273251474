import React, { FC } from 'react'

import EmailLayoutsModal from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/EmailLayoutsModal'
import { useGetEmailTemplateLayouts } from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/graphQL/EmailLayoutsModal.graphQL'
import { EmailTemplateLayoutType } from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/utils/EmailLayoutsModal.utils'
import { IAddOnCallback } from '@src/pages/EmailComposer/utils/BeeEditor.types'

export interface EmailLayoutsModalContainerProps {
  onAddOnDone: IAddOnCallback
  layoutsType: EmailTemplateLayoutType
  layoutIdToReplace?: string
  sendPlainText?: boolean
}

const EmailLayoutsModalContainer: FC<EmailLayoutsModalContainerProps> = (props) => {
  const handleGetEmailLayouts = useGetEmailTemplateLayouts(props.layoutsType)
  return <EmailLayoutsModal {...props} onGetEmailLayouts={handleGetEmailLayouts} />
}

export default EmailLayoutsModalContainer
