import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import { useApolloClient, useQuery } from '@apollo/client'
import Button, { ButtonType } from '@components/Button'
import Caution from '@components/Caution/Caution'
import FormRow from '@components/FormRow/FormRow'
import Input from '@components/Input/Input'
import Loader from '@components/Loader'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Radio from '@components/Radio/Radio'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import getPersonalizationChoices from '@graphql/queries/getPersonalizationChoices'
import { AccountVcMap, LabelValueField } from '@graphql/types/mutation-types'
import { GetPersonalizationChoicesQuery, GetPersonalizationChoicesQueryVariables } from '@graphql/types/query-types'
import { logNewRelicError } from '@utils/new-relic.utils'

import { PersonalizationLabelValue } from '../../EditSMSContainer'

import './Personalization.css'

interface Props {
  closePersonalization: (personalization: string) => void
}

interface Errors {
  selectedField: string | undefined
  maxLength: string | undefined
  fallback: string | undefined

  [key: string]: string | undefined
}

interface State {
  recipientListOpen: boolean
  senderListOpen: boolean
  selectedField: string
  maxLength: string
  fallbackText: string
  canSubmit: boolean
  dirtyFields: { [key: string]: string }
  errors: {
    recipientList: Errors
    senderList: Errors
  }
}

interface RenderFormRowProps {
  radioLabel: string
  radioHelper: string
  isOpen: boolean
  showRecipientFields?: boolean
  showSendersError?: boolean
  listFields: PersonalizationLabelValue[]
  dataTest: string
}

const defaultErrors = {
  selectedField: undefined,
  maxLength: undefined,
  fallback: undefined,
}

export const rootClass = 'sms-personalization'

const crmRedirectLink =
  'https://connect.act-on.com/hc/en-us/articles/360033436074-How-to-Personalize-Email-Content-with-CRM-Data#Error_message:_Please_visit_CRM_Administration_in_Settings_and_set_up_a_recurring_sync_to_enable_Field_Name_Personalization'

const createPersonalizations = (
  accountVCMaps: Pick<AccountVcMap, 'name'>[] = [],
  vcFields: LabelValueField[] = [],
  crmFields: LabelValueField[] = []
) => {
  const vcMaps: PersonalizationLabelValue[] = accountVCMaps.map((vcMap) => ({
    label: vcMap.name,
    value: vcMap.name,
  }))

  const vcFieldMaps: PersonalizationLabelValue[] = vcFields.map((vcField) => ({
    label: vcField.label,
    value: vcField.value,
  }))

  const accountPersonalizations: PersonalizationLabelValue[] = [...new Set([...vcMaps, ...vcFieldMaps])].sort((a, b) => (a.label < b.label ? -1 : 1))

  const crmPersonalizations: PersonalizationLabelValue[] = crmFields
    .map((crmField) => ({
      label: crmField.label,
      value: crmField.value,
    }))
    .sort((a, b) => (a.label < b.label ? -1 : 1))

  return {
    accountPersonalizations,
    crmPersonalizations,
  }
}

export const Personalization: FC<Props> = ({ closePersonalization }: Props) => {
  const [state, setState] = useState<State>({
    recipientListOpen: true,
    senderListOpen: false,
    selectedField: '',
    maxLength: '',
    fallbackText: '',
    canSubmit: false,
    dirtyFields: {},
    errors: {
      recipientList: defaultErrors,
      senderList: defaultErrors,
    },
  })
  const { t } = useTranslation()

  const client = useApolloClient()
  const menuPortalTarget = useRef<HTMLElement>(null)

  const { loading, error, data } = useQuery<GetPersonalizationChoicesQuery, GetPersonalizationChoicesQueryVariables>(getPersonalizationChoices, {
    client: client as any,
  })

  useEffect(() => {
    if (error) {
      logNewRelicError(error)
    }
  }, [error])

  const { accountPersonalizations, crmPersonalizations } = createPersonalizations(
    data ? data.getPersonalizationChoices.accountVCMaps : [],
    data ? data.getPersonalizationChoices.vcFields : [],
    data ? data.getPersonalizationChoices.crmFields : []
  )

  const recipientFields: PersonalizationLabelValue[] = accountPersonalizations.filter((personalization) => personalization.label.length).length
    ? accountPersonalizations
    : []
  const senderFields: PersonalizationLabelValue[] = crmPersonalizations.filter((personalization) => personalization.label.length).length
    ? crmPersonalizations
    : []

  const checkErrors = (newState: State) => {
    let canSubmit = true
    const { errors } = newState
    const currentList = newState.recipientListOpen ? 'recipientList' : 'senderList'
    const maxLength = Number(newState.maxLength)

    if (state.dirtyFields['selectedField']) {
      if (
        !newState.selectedField ||
        newState.selectedField === '' ||
        newState.selectedField === t('EMPTY') ||
        newState.selectedField === t('Select Field')
      ) {
        errors[currentList].selectedField = t('Required')
        canSubmit = false
      } else {
        errors[currentList].selectedField = undefined
      }
    }

    if (state.dirtyFields['maxLength']) {
      if (newState.maxLength === '') {
        errors[currentList].maxLength = t('Required')
        canSubmit = false
      } else if (isNaN(maxLength) || maxLength < 1 || maxLength > 99) {
        errors[currentList].maxLength = t('Must be a number between 1 and 99')
        canSubmit = false
      } else {
        errors[currentList].maxLength = undefined
      }
      if (newState.fallbackText.length > maxLength) {
        errors[currentList].fallback = t('Cannot be longer than the specified max length')
        canSubmit = false
      } else {
        errors[currentList].fallback = undefined
      }
    }

    if (state.dirtyFields['fallbackText']) {
      if (!newState.fallbackText || newState.fallbackText === '') {
        errors[currentList].fallback = t('Required')
        canSubmit = false
      } else if (newState.fallbackText.length > maxLength) {
        errors[currentList].fallback = t('Cannot be longer than the specified max length')
        canSubmit = false
      } else {
        errors[currentList].fallback = undefined
      }
    }
    canSubmit = canSubmit && !!newState.fallbackText.length && !!newState.maxLength.length && !!newState.selectedField.length
    return { canSubmit, errors }
  }

  const updateState = (field: string, value: string) => {
    const newState = {
      ...state,
      [field]: value,
    }

    const { canSubmit, errors } = checkErrors(newState)
    setState({ ...newState, canSubmit, errors, dirtyFields: { ...state.dirtyFields, [field]: field } })
  }

  const submitPersonalization = () => {
    closePersonalization(`{{={{${state.selectedField}(${state.maxLength})}}|${state.fallbackText}}}`)
  }

  const fallbackHelper = t(
    'Fallback text is used when the selected field value is not available or if longer than max length. Fallback text is also used if the field does not exist in the list.'
  )

  const renderRadioLabel = (label: string, helper: string) => {
    return (
      <>
        <Typography text={t(label)} inline weight={TextWeight.MEDIUM} />
        <Typography text={t(helper)} type={TextType.BODY_TEXT_LIGHT} inline className={`${rootClass}__row-info`} />
      </>
    )
  }

  const renderInputLabel = (label: string, infoText: string) => (
    <span>
      {t(label)}{' '}
      <span title={t(infoText)}>
        <Svg name={SvgNames.info} type={SvgType.ICON} />
      </span>
    </span>
  )

  const renderMaxLengthLabel = () => renderInputLabel('Max length', 'A number between 1 and 99 that sets the max character limit for field values')
  const renderFallbackTextLabel = () =>
    renderInputLabel('Fallback text', 'Literal text to replace field values that cannot be displayed. Cannot be longer than the max length.')
  const noRecipientsLabel =
    'No recipients have been added to this message yet. Add recipients on the Recipients Tab to use this personalization option.'
  const noSendersLabel =
    'Expecting to see more fields? Using CRM Personalization fields requires regular syncing from your CRM. Visit the CRM Administration page in Settings to configure a Recurring Synchronization.'

  const getFieldError = (fieldName: string) => state.errors[state.recipientListOpen ? 'recipientList' : 'senderList'][fieldName]

  const fieldHasError = (fieldName: string): boolean => {
    const error = getFieldError(fieldName)
    return (error && error !== '') as boolean
  }

  const renderError = (fieldName: string) => {
    const error = getFieldError(fieldName)
    return error ? <Typography type={TextType.ERROR} className={`${rootClass}__inputs-error`} text={error} /> : null
  }

  const toggleOpenList = () => {
    setState({
      ...state,
      recipientListOpen: !state.recipientListOpen,
      senderListOpen: !state.senderListOpen,
    })
  }

  const showEmpty = (span: ReactNode, dataTest: string) => <Caution dataTest={dataTest}>{span}</Caution>

  const noRecipientsSpan = <span>{t(noRecipientsLabel)}</span>
  const noSendersSpans = (
    <div>
      <Typography text={t(noSendersLabel)} inline />
      <TextLink text={t(' More info')} link={crmRedirectLink} size={TextLinkSize.LARGE} />
    </div>
  )
  const renderFormRow = ({
    radioLabel,
    radioHelper,
    isOpen,
    listFields,
    showRecipientFields = true,
    showSendersError = false,
    dataTest,
  }: RenderFormRowProps) => (
    <FormRow>
      <div className={`${rootClass}__list-selector`}>
        <Radio
          labelChildren={renderRadioLabel(radioLabel, radioHelper)}
          dataTest={`${rootClass}-${dataTest}-list-select`}
          name="recipientListSelector"
          checked={isOpen}
          onChange={toggleOpenList}
        />
      </div>
      <div
        className={classNames(`${rootClass}__inputs`, {
          [`${rootClass}__inputs-hidden`]: !isOpen,
        })}
      >
        {showRecipientFields && !error ? (
          <>
            <div>
              <SelectV2
                dataTest={`${rootClass}-${dataTest}-field-select`}
                options={listFields}
                defaultValue={listFields.find(({ value }) => value === state.selectedField)}
                placeholder={'Select a field'}
                menuPortalTarget={menuPortalTarget.current}
                className={classNames({
                  [`${rootClass}__error`]: fieldHasError('selectedField'),
                })}
                onChange={(option) => updateState('selectedField', (option as SelectV2SingleOption)?.value || '')}
              />
              {renderError('selectedField')}
            </div>
            {(showSendersError || error) && showEmpty(noSendersSpans, 'senders')}
            <div className={`${rootClass}__inputs-fields`}>
              <div className={`${rootClass}__input-max-length`}>
                <Input
                  name={`maxLength`}
                  label={renderMaxLengthLabel()}
                  error={fieldHasError('maxLength')}
                  dataTest={`${rootClass}-${dataTest}-max-length`}
                  onChange={(event) => updateState('maxLength', event.target.value)}
                  disabled={showSendersError}
                />
                {renderError('maxLength')}
              </div>
              <div className={`${rootClass}__input-fallback`}>
                <Input
                  name={`fallbackText`}
                  label={renderFallbackTextLabel()}
                  error={fieldHasError('fallback')}
                  dataTest={`${rootClass}-${dataTest}-fallback`}
                  onChange={(event) => updateState('fallbackText', event.target.value)}
                  disabled={showSendersError}
                />
                {renderError('fallback')}
              </div>
            </div>
            <Typography text={fallbackHelper} type={TextType.BODY_TEXT_SMALL_LIGHT} className={`${rootClass}__fallback-helper`} />
          </>
        ) : (
          showEmpty(noRecipientsSpan, 'recipients')
        )}
      </div>
    </FormRow>
  )

  return (
    <>
      {loading && <Loader center />}
      {!loading && (
        <Modal
          className={`${rootClass}`}
          dataTest={rootClass}
          isOpen
          header={<ModalHeader headerType={ModalHeaderType.Form}>{t('Personalize')}</ModalHeader>}
        >
          <ModalBody className={`${rootClass}__body`}>
            {renderFormRow({
              radioLabel: 'Recipient List Field',
              radioHelper: 'Field names defined within the Standard Field Names',
              isOpen: state.recipientListOpen,
              listFields: recipientFields,
              showRecipientFields: recipientFields.length > 0,
              dataTest: 'recipient-list',
            })}
            {renderFormRow({
              radioLabel: 'Sender Field',
              radioHelper: 'Account and message sender fields',
              isOpen: state.senderListOpen,
              listFields: senderFields,
              showSendersError: senderFields.length === 0,
              dataTest: 'sender',
            })}
          </ModalBody>
          <ModalFooter footerType={ModalFooterType.Form}>
            <Button buttonType={ButtonType.TERTIARY} onClick={() => closePersonalization('')} dataTest={`${rootClass}-close-button`}>
              {t('Cancel')}
            </Button>
            <Button buttonType={ButtonType.PRIMARY} dataTest={`${rootClass}-save-button`} disabled={!state.canSubmit} onClick={submitPersonalization}>
              {t('Select')}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export default Personalization
