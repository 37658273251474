import React, { FC, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { MenuItem } from '@components/DropDownActions/DropDownActions'
import { EmptyListingProps } from '@components/EmptyListing/EmptyListing'
import Modal, { ModalBody, ModalFooter } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import PreviewAssetModalHeader from '@components/PreviewAssetModal/components/PreviewAssetModalHeader'
import { useTranslation } from '@const/globals'

import { getAssetPreviewMode } from './PreviewAssetModal.utils'
import PreviewAssetModalContent from './PreviewAssetModalContent'

import './PreviewAssetModal.css'

export interface PreviewAssetModalProps {
  isOpen: boolean
  onClose: () => void
  title: string
  assetSrc?: string
  previewHtml?: string
  actions?: MenuItem[]
  size?: PreviewModalSize
  background?: PreviewModalBackground
  contentYPadding?: boolean
  error?: boolean
  loading?: boolean
  assetType?: string
  isStory?: boolean
  checkIsPreviewEmpty?: boolean
  checkIsPreviewEmptyTimeout?: number
  className?: string
  dataTest?: string
  onBackButtonClick?: VoidFunction
  useTitlePrefix?: boolean
  titlePrefix?: string
  onSubmit?: () => void
  submitButtonText?: string
  closeButtonText?: string
  emptyListingProps?: Partial<EmptyListingProps>
}

export enum PreviewModalSize {
  MEDIUM = 'medium',
  SMALL = 'small',
}

export enum PreviewModalBackground {
  WHITE = 'white',
  GREY = 'grey',
}

const rootClass = 'preview-asset-modal'

const PreviewAssetModal: FC<PreviewAssetModalProps> = (props) => {
  const {
    isOpen,
    assetSrc,
    onClose,
    title,
    actions,
    size,
    contentYPadding,
    background,
    className,
    dataTest = rootClass,
    useTitlePrefix,
    titlePrefix,
    onBackButtonClick,
    onSubmit,
    closeButtonText,
    submitButtonText,
  } = props
  const { t } = useTranslation()
  const [error, setError] = useState(false)
  const [fullModalHeight, setFullModalHeight] = useState(false)
  const isImage = getAssetPreviewMode(assetSrc) === 'image'

  return (
    <Modal
      isOpen={isOpen}
      header={
        <PreviewAssetModalHeader
          title={title}
          useTitlePrefix={useTitlePrefix}
          titlePrefix={titlePrefix}
          dataTest={`${dataTest}-header`}
          actions={actions}
          onBackButtonClick={onBackButtonClick}
        />
      }
      className={classNames(rootClass, className, {
        [`${rootClass}__medium`]: size === PreviewModalSize.MEDIUM,
        [`${rootClass}__full-padding`]: contentYPadding,
        [`${rootClass}__full-height`]: fullModalHeight && !error && !isImage,
      })}
      dataTest={dataTest}
    >
      <ModalBody
        className={classNames(`${rootClass}__body`, {
          [`${rootClass}__body-grey`]: background === PreviewModalBackground.GREY,
        })}
        dataTest={`${dataTest}-body`}
      >
        <PreviewAssetModalContent {...props} onError={setError} onFullHeight={setFullModalHeight} />
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form} className={`${rootClass}__footer`}>
        <Button buttonType={!!onSubmit ? ButtonType.TERTIARY : ButtonType.TRANSPARENT} onClick={onClose} dataTest={`${dataTest}-close-button`}>
          {t(closeButtonText ?? 'Close')}
        </Button>
        {!!onSubmit ? (
          <Button buttonType={ButtonType.PRIMARY} onClick={onSubmit} dataTest={`${dataTest}-submit-button`}>
            {t(submitButtonText ?? 'Submit')}
          </Button>
        ) : (
          <></>
        )}
      </ModalFooter>
    </Modal>
  )
}

export default PreviewAssetModal
