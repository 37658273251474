import React, { FC, useMemo } from 'react'

import classNames from 'classnames'

import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2Option } from '@components/SelectV2/SelectV2.props'
import { useTranslation } from '@const/globals'
import MultiTextInput from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/MultiTextInput/MultiTextInput'
import { renderSingleValue } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/utils/SegmentComposerBuild.utils'
import { getTextConditionOptions, TextEmptyComparison, TextNotEmptyComparison } from '@src/pages/SegmentComposer/SegmentComposer.constants'

export interface TextCriteriaType {
  criteria?: string
  values: SelectV2Option[]
}

interface TextCriteriaProps {
  className?: string
  dataTest?: string
  onChange: (textCriteria: TextCriteriaType) => void
  onCreate: (inputValue: string) => void
  textCriteria: TextCriteriaType
}

const rootClass = 'text-criteria'
const defaultTextConditionGroupIndex = 0
const defaultTextConditionIndex = 0

const TextCriteria: FC<TextCriteriaProps> = (props: TextCriteriaProps) => {
  const { dataTest = rootClass, className = '', onCreate, textCriteria, onChange } = props
  const { t } = useTranslation()
  const textConditionOptions = getTextConditionOptions(t)
  const { criteria = textConditionOptions[defaultTextConditionGroupIndex].options[defaultTextConditionIndex], values } = textCriteria

  const criteriaOption = useMemo(
    () =>
      textConditionOptions
        .map((group) => group.options)
        .flat()
        .find((option) => option.value === criteria) || textConditionOptions[defaultTextConditionGroupIndex].options[defaultTextConditionIndex],
    [criteria, textConditionOptions]
  )
  const renderNextCondition = () => {
    const { value } = criteriaOption
    if (value in TextEmptyComparison || value in TextNotEmptyComparison) {
      return null
    }
    return (
      <MultiTextInput selectedOptions={values} onChange={(values: SelectV2Option[]) => onChange({ ...textCriteria, values })} onCreate={onCreate} />
    )
  }

  return (
    <>
      <SelectV2
        className={classNames(`${rootClass}__text-criteria-select`, className)}
        dataTest={`${dataTest}-criteria-select`}
        renderSingleValue={renderSingleValue}
        groupedOptions={textConditionOptions}
        value={criteriaOption}
        onChange={(criteria) => onChange({ ...textCriteria, criteria: criteria?.value })}
        isClearable={false}
        isSearchable={false}
        showGroupsWithoutLabel
      />
      {renderNextCondition()}
    </>
  )
}

export default TextCriteria
