import React, { FC, useEffect, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { useTranslation } from '@const/globals'
import { SMSContext } from '@src/pages/sms/context/SMSContext'
import { smsStateDefaultValues } from '@src/pages/sms/context/smsStateDefaultValues'
import { optOutMsg } from '@src/pages/sms/edit/components/MessageComposer/MessageComposer'
import { SMSMessage, State } from '@src/pages/sms/edit/EditSMSContainer'
import {
  canScheduleUtil,
  canSendUtil,
  createMessage,
  saveMessageUtil,
  scheduleSMSLaunchUtil,
  sendSMSMessageUtil,
  updateMessageUtil,
} from '@src/pages/sms/edit/utils/EditSMSContainerUtils'
import InitialMessage from '@src/pages/sms/initialMessage/InitialMessage'
import InitialMessageSplash from '@src/pages/sms/initialMessage/InitialMessageSplash'

const defaultState: State = smsStateDefaultValues

const InitialMessageContainer: FC = () => {
  const { t } = useTranslation()
  const smsMessage = defaultState.smsMessage
  const initialMessageDefaultState = {
    ...defaultState,
    smsMessage: {
      ...smsMessage,
      title: t('Initial Message'),
      messageText: t(optOutMsg),
    },
  }
  const [containerValues, setContainerValues] = useState<State>(initialMessageDefaultState)
  const [showSplash, setShowSplash] = useState<boolean>(true)
  const [showInitialMessageSending, setShowInitialMessageSending] = useState<boolean>(false)

  const client = useApolloClient()

  useEffect(() => {
    createMessage(setContainerValues, containerValues, client as any)
  }, [])

  useEffect(() => {
    if (containerValues.smsMessage.launchId) {
      saveMessageUtil(containerValues.smsMessage, containerValues.messageCaretPosition, setContainerValues, containerValues, client as any, true)
    }
  }, [containerValues.smsMessage.launchId])

  const sendSMSMessage = () => {
    setShowInitialMessageSending(true)
    sendSMSMessageUtil(setContainerValues, containerValues, client as any, true)
  }

  const scheduleMessageLaunch = () => {
    setShowInitialMessageSending(true)
    scheduleSMSLaunchUtil(setContainerValues, containerValues, client as any, true)
  }

  const saveMessage = (smsMessage: SMSMessage, messageCaretPosition?: number) => {
    saveMessageUtil(smsMessage, messageCaretPosition || 0, setContainerValues, containerValues, client as any, true)
  }

  const updateMessage = (message: string) => updateMessageUtil(message, containerValues, setContainerValues)

  const updater = (field: string, value: any) => {
    setContainerValues({ ...containerValues, [field]: value })
  }

  const canSend = () => canSendUtil(containerValues, true)
  const canSchedule = () => canScheduleUtil(containerValues)

  const showEditor = () => setShowSplash(false)

  return (
    <SMSContext.Provider
      value={{ values: containerValues, updater, saveMessage, sendSMSMessage, canSend, canSchedule, updateMessage, scheduleMessageLaunch }}
    >
      {showSplash && <InitialMessageSplash showEditor={showEditor} />}
      {!showSplash && <InitialMessage sending={showInitialMessageSending} />}
    </SMSContext.Provider>
  )
}

export default InitialMessageContainer
