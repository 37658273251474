import React, { FC, useCallback } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { ButtonWeight } from '@components/Button/Button'
import Container from '@components/Container'
import Dots from '@components/Dots/Dots'
import PillLabel, { PillLabelType } from '@components/PillLabel/PillLabel'
import Spinner from '@components/Spinner/Spinner'
import StaticImage from '@components/StaticImage/StaticImage'
import Svg, { SvgNames } from '@components/Svg'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { MARKETING_DOMAIN_NEED_HELP_LINK } from '@src/pages/Content/Branding/tabs/WebIdentity/webIdentity.constants'
import { CheckCardStatus, CheckCardType, getCheckCardImage } from '@src/pages/EmailComposer/components/common/CheckStatusCard/CheckStatusCard.utils'
import { CheckStatusCardContainerProps } from '@src/pages/EmailComposer/components/common/CheckStatusCard/CheckStatusCardContainer'

import './CheckStatusCard.css'

export interface CheckStatusCardProps
  extends Pick<
    CheckStatusCardContainerProps,
    'status' | 'type' | 'isInPreview' | 'disableCheck' | 'tooltipContentKey' | 'issuesCount' | 'className' | 'dataTest'
  > {
  onCheck: VoidFunction
  onViewResults: VoidFunction
}

const rootClass = 'check-status-card'

const CheckStatusCard: FC<CheckStatusCardProps> = ({
  onCheck,
  onViewResults,
  type = CheckCardType.SPAM,
  status = CheckCardStatus.DEFAULT,
  isInPreview,
  disableCheck,
  tooltipContentKey,
  issuesCount = 0,
  className,
  dataTest = rootClass,
}) => {
  const { t } = useTranslation()

  const isPersonalization = type === CheckCardType.PERSONALIZATION
  const isDefault = status === CheckCardStatus.DEFAULT
  const isSuccess = status === CheckCardStatus.SUCCESS
  const isError = status === CheckCardStatus.ERROR
  const isFailure = status === CheckCardStatus.FAILURE
  const isWarning = status === CheckCardStatus.WARNING
  const loading = status === CheckCardStatus.LOADING
  const _isInPreview = isInPreview && !isPersonalization
  const showPill = isSuccess || isFailure
  const showViewResultsButton = !_isInPreview && (isPersonalization ? isFailure || isWarning : showPill)
  const isCheckAgain = !_isInPreview && (showPill || showViewResultsButton)
  const mainIsViewResults = _isInPreview && showPill
  const _disableCheck = disableCheck && isDefault

  const handleCheck = useCallback(() => {
    mainIsViewResults ? onViewResults() : onCheck()
  }, [onCheck, mainIsViewResults, onViewResults])

  const translationOptions = { context: status, count: issuesCount }

  return (
    <Container className={classNames(rootClass, className, { [`${rootClass}__in-preview`]: _isInPreview })} dataTest={dataTest}>
      <div className={`${rootClass}__image push-left-x3`}>
        {loading ? (
          <Spinner dataTest={`${dataTest}-spinner`} />
        ) : (
          <StaticImage name={getCheckCardImage(type, status)} dataTest={`${dataTest}-image`} />
        )}
      </div>
      <div className={`${rootClass}__text ${_isInPreview ? '' : 'push-left-x4'}`}>
        <div className={`flex-align-center push-up${_isInPreview ? '' : '-x2'}`}>
          <Typography
            text={t(`EmailComposer.Check.Status.${type}.${_isInPreview ? 'InPreview.' : ''}Title`, translationOptions)}
            type={TextType.SECTION_HEADER}
            weight={TextWeight.MEDIUM}
            lineHeight={LineHeight.MEDIUM_LARGE}
            dataTest={`${dataTest}-title`}
          />
          {showPill && (
            <PillLabel
              type={isSuccess ? PillLabelType.SUCCESS : PillLabelType.ERROR}
              text={t(isSuccess ? 'Passed!' : 'Failed')}
              textType={TextType.BODY_TEXT}
              dataTest={`${dataTest}-pill`}
            />
          )}
        </div>
        <Typography
          text={t(`EmailComposer.Check.Status.${type}.${_isInPreview ? 'InPreview.' : ''}Description`, translationOptions)}
          type={TextType.BODY_TEXT_LIGHT}
          tagProps={{ medium: { weight: TextWeight.MEDIUM, inline: true } }}
          tagComponents={{
            TextLink: <TextLink hideIcon size={TextLinkSize.LARGE} link={MARKETING_DOMAIN_NEED_HELP_LINK} />,
          }}
        />
      </div>
      <Tooltip
        hide={!_disableCheck}
        triggerClassName={`${rootClass}__tooltip-trigger`}
        position="bottom"
        trigger={
          <Button
            buttonType={showViewResultsButton ? ButtonType.TEXT_TEAL : ButtonType.OUTLINE}
            onClick={handleCheck}
            className={classNames(`${rootClass}__button-main`, {
              [`${rootClass}__button-check-again`]: isCheckAgain,
              [`${rootClass}__button-check-again-teal`]: showViewResultsButton,
            })}
            disabled={loading || _disableCheck}
            weight={ButtonWeight.MEDIUM}
            fullWidth
            dataTest={`${dataTest}-button`}
          >
            {isCheckAgain && <Svg name={SvgNames.generate} className="push-left" />}
            {loading ? (
              <Dots customSize={{ padding: 0, width: '24px', height: '16px' }} className={`${rootClass}__dots`} />
            ) : (
              t(mainIsViewResults ? 'View results' : isError ? 'Try again' : isCheckAgain ? 'Check again' : 'Check now')
            )}
          </Button>
        }
        childrenProps={{ tagProps: { bold: { weight: TextWeight.BOLD, inline: false } } }}
      >
        {tooltipContentKey ?? `EmailComposer.Check.Status.${type}.Tooltip`}
      </Tooltip>
      {showViewResultsButton && (
        <Button
          buttonType={ButtonType.OUTLINE}
          onClick={onViewResults}
          className={`${rootClass}__button-main push-right-x3`}
          dataTest={`${dataTest}-button-results`}
        >
          {t(isPersonalization ? (isWarning ? 'View warnings' : 'View errors') : 'View results')}
        </Button>
      )}
    </Container>
  )
}

export default CheckStatusCard
