import React, { FC, useCallback, useMemo } from 'react'

import { useApolloClient, useMutation } from '@apollo/client'
import Loader from '@components/Loader'
import Modal, { ModalHeader } from '@components/Modal'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import { useTranslation } from '@const/globals'
import setSalesUserPriv from '@graphql/mutations/setSalesUserPriv'
import { SetSalesUserPrivMutation, SetSalesUserPrivMutationVariables } from '@graphql/types/mutation-types'
import { UserResponse } from '@graphql/types/query-types'
import { useSalesUsers } from '@src/pages/Settings/Users/context/SalesUsersContext'
import { useAllUsers } from '@src/pages/Settings/Users/context/UsersContext'
import EditLaunchPermissionsModalContent from '@src/pages/Settings/Users/tabs/SalesUsers/components/EditLaunchPermissionsModal/EditLaunchPermissionsModalContent'
import { logNewRelicError } from '@utils/new-relic.utils'

import './EditLaunchPermissionsModal.css'

interface EditLaunchPermissionsModalProps {
  onCancel: () => void
  user?: UserResponse
  dataTest?: string
}

const rootClass = 'launch-permission-modal'

const EditLaunchPermissionsModal: FC<EditLaunchPermissionsModalProps> = (props: EditLaunchPermissionsModalProps) => {
  const { user, onCancel, dataTest = rootClass } = props
  const { t } = useTranslation()
  const { setToastStatus } = useAllUsers()
  const { refetchRefs } = useSalesUsers()
  const isOpen = useMemo(() => !!user, [user])

  const client = useApolloClient()

  const [updatePermissions, { loading }] = useMutation<SetSalesUserPrivMutation, SetSalesUserPrivMutationVariables>(setSalesUserPriv, {
    client,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const onSave = useCallback<(optionOrUserId: string) => void>(
    (optionOrUserId) => {
      onCancel()
      if (!user?.id) {
        return
      }
      updatePermissions({
        variables: { changableUserId: user.id, priv: 'LAUNCH_APPROVAL', optionOrUserId },
      })
        .then(({ data }) => {
          if (data?.setSalesUserPriv?.status === 'ok') {
            setToastStatus({
              showStatus: true,
              title: t('Success!'),
              statusMessage: t('Launch.Permissions.Change.Success.Message'),
              successStatus: true,
            })
            refetchRefs.refreshSalesUsers()
          } else {
            setToastStatus({
              showStatus: true,
              statusMessage: t('Launch.Permissions.Change.Error.Message'),
              successStatus: false,
            })
          }
        })
        .catch((error) => {
          logNewRelicError(error)
        })
    },
    [updatePermissions, user?.id]
  )

  return (
    <Modal
      isOpen={isOpen}
      className={rootClass}
      dataTest={dataTest}
      header={
        <ModalHeader headerType={ModalHeaderType.Form} dataTest={`${dataTest}-header`}>
          {t('Edit Launch Permissions')}
        </ModalHeader>
      }
    >
      {loading ? <Loader center /> : <EditLaunchPermissionsModalContent user={user} onSave={onSave} onCancel={onCancel} dataTest={dataTest} />}
    </Modal>
  )
}

export default EditLaunchPermissionsModal
