import React, { FC } from 'react'

import ChooserScreen, { ChooserButton, ChooserGroup } from '@components/ChooserScreen'
import Modal, { ModalBody, ModalHeader } from '@components/Modal'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import { CrmNames } from '@const/crmNames'
import { useTranslation } from '@const/globals'
import { Program } from '@graphql/types/query-types'
import { useGetFeatureFlag } from '@utils/account/account.utils'
import { logError } from '@utils/env'
import useCRM from '@utils/hooks/useCRM'
import { getStepTemplate, hasAOContactsSource } from '@utils/program/program'
import { ProgramStepType, Step } from '@utils/program/program.constants'

import ProgramStepIcon from '../ProgramStepIcon/ProgramStepIcon'

import './addStepModal.css'

interface AddStepModalProps {
  isOpen: boolean
  closeModal(): void
  program: Program
  dataTest?: string
  newStepId: number
  onAddStep(step: Step): void
}

const rootClass = 'add-step-modal'

export function getDisplayName(stepType: ProgramStepType, program: Program, t: Function) {
  switch (stepType) {
    case ProgramStepType.WAIT:
      return t('Wait')
    case ProgramStepType.SEND:
      return t('Send Email Message')
    case ProgramStepType.SMS:
      return t('Send SMS Message')
    case ProgramStepType.OPT_OUT:
      return t('Add to Opt-Out List')
    case ProgramStepType.COPY:
      return t('Copy Contact to Another List')
    case ProgramStepType.APPEND_TO_SEGMENT:
      return t('Append to segment')
    case ProgramStepType.FIELD_SET:
      return t('Change Contact Field Value')
    case ProgramStepType.ALERT:
      return t('Send Alert')
    case ProgramStepType.BRANCH:
      return t('If Some Condition is Met')
    case ProgramStepType.EXTERNAL_API:
      return t('Send contact information through an external API call')
    case ProgramStepType.TASK:
      return `${t('Assign a task in')} ${program.crm?.crmName}`
    case ProgramStepType.CRM_CREATE:
      return `${t('Create Records in')} ${program.crm?.crmName}`
    case ProgramStepType.CRM_CAMPAIGN:
      return `${t('Add to')} ${program.crm?.crmName} ${program.crm?.campaignDisplayName}`
    case ProgramStepType.OUTGOING_WEBHOOK:
      return t('Outgoing Webhook')
    case ProgramStepType.UPDATE_SUBSCRIPTION:
      return t('Update subscription')
    default:
      return t('Enter Descriptive Name')
  }
}

export const AddStepModal: FC<AddStepModalProps> = (props: AddStepModalProps) => {
  const { onAddStep, program, closeModal, isOpen, dataTest = 'add-step-modal' } = props
  const exclusivelyActOnContacts = useGetFeatureFlag('exclusivelyActOnContacts')
  const hasShowActOnContactsTab = useGetFeatureFlag('hasShowActOnContactsTab')
  const hasPurchasedSMS = useGetFeatureFlag('hasPurchasedSMS')
  const hasOutgoingWebhookStep = useGetFeatureFlag('hasOutgoingWebhookStep')
  const hasNewCRMStep = useGetFeatureFlag('hasNewCRMStep')
  const aOContactsSource = hasAOContactsSource(program)
  const { hasCRMConnected } = useCRM()

  const { t } = useTranslation()

  const addNewStep = (stepType: ProgramStepType) => {
    const step = getStepTemplate(program.stepTemplates, stepType, program.name)
    if (step) {
      onAddStep({
        ...step,
        stepId: `newstep${props.newStepId}`,
        stepType,
        displayName: getDisplayName(stepType, program, t),
      } as Step)
    } else {
      logError('Unable to find this step')
      closeModal()
    }
  }

  const modalHeader = (
    <ModalHeader closeButton={closeModal} className={`${rootClass}__modal-header`} headerType={ModalHeaderType.Form}>
      {t('Add New Step')}
    </ModalHeader>
  )

  const renderCopyButton = (
    <ChooserButton
      dataTest={`${dataTest}-copy-button`}
      customIcon={true}
      title={t('Copy to List')}
      description={t('Copy contacts to another list')}
      icon={<ProgramStepIcon stepType={ProgramStepType.COPY} aOContactsSource={aOContactsSource} />}
      onClick={() => {
        addNewStep(ProgramStepType.COPY)
      }}
    />
  )

  const renderAppendButton = (
    <ChooserButton
      dataTest={`${dataTest}-append-button`}
      customIcon={true}
      title={t('Append to segment')}
      description={t('Add contacts to a segment')}
      icon={<ProgramStepIcon stepType={ProgramStepType.APPEND_TO_SEGMENT} aOContactsSource={aOContactsSource} />}
      onClick={() => {
        addNewStep(ProgramStepType.APPEND_TO_SEGMENT)
      }}
    />
  )

  const renderAppropriateCopyButton = () => {
    return program.sources.length === 0 ? (
      <>
        {!exclusivelyActOnContacts && renderCopyButton}
        {hasShowActOnContactsTab && renderAppendButton}
      </>
    ) : aOContactsSource ? (
      renderAppendButton
    ) : (
      renderCopyButton
    )
  }

  return (
    <Modal isOpen={isOpen} className={rootClass} dataTest={dataTest} header={modalHeader}>
      <ModalBody>
        <ChooserScreen isGrouped={true}>
          <ChooserGroup>
            <ChooserButton
              dataTest={`${dataTest}-send-button`}
              customIcon={true}
              title={t('Send Email')}
              description={t('Send a message to all contacts')}
              icon={<ProgramStepIcon stepType={ProgramStepType.SEND} />}
              onClick={() => {
                addNewStep(ProgramStepType.SEND)
              }}
            />
            <ChooserButton
              dataTest={`${dataTest}-sms-button`}
              customIcon={true}
              title={t('Send SMS')}
              description={t('Send a text to opted-in contacts')}
              icon={<ProgramStepIcon stepType={ProgramStepType.SMS} />}
              className={hasPurchasedSMS ? '' : `${rootClass}__hide-button`}
              onClick={() => {
                addNewStep(ProgramStepType.SMS)
              }}
            />
            <ChooserButton
              dataTest={`${dataTest}-wait-button`}
              customIcon={true}
              title={t('Wait')}
              description={t('Insert a wait period')}
              icon={<ProgramStepIcon stepType={ProgramStepType.WAIT} />}
              onClick={() => {
                addNewStep(ProgramStepType.WAIT)
              }}
            />
            <ChooserButton
              dataTest={`${dataTest}-branch-button`}
              customIcon={true}
              title={t('Branch')}
              description={t('Send contacts to another path')}
              icon={<ProgramStepIcon stepType={ProgramStepType.BRANCH} />}
              onClick={() => {
                addNewStep(ProgramStepType.BRANCH)
              }}
            />
            <ChooserButton
              dataTest={`${dataTest}-opt-out-button`}
              customIcon={true}
              title={t('Opt-Out')}
              description={t('Add contacts to Opt-Out list')}
              icon={<ProgramStepIcon stepType={ProgramStepType.OPT_OUT} />}
              onClick={() => {
                addNewStep(ProgramStepType.OPT_OUT)
              }}
            />
          </ChooserGroup>
          <ChooserGroup>
            <ChooserButton
              dataTest={`${dataTest}-change-field-button`}
              customIcon={true}
              title={t('Change Field')}
              description={t('Set values in one or more fields')}
              icon={<ProgramStepIcon stepType={ProgramStepType.FIELD_SET} />}
              onClick={() => {
                addNewStep(ProgramStepType.FIELD_SET)
              }}
            />
            {aOContactsSource ? (
              <ChooserButton
                dataTest={`${dataTest}-update-subscription-button`}
                customIcon={true}
                title={t('Update subscription')}
                description={t('Manage subscription categories')}
                icon={<ProgramStepIcon stepType={ProgramStepType.UPDATE_SUBSCRIPTION} />}
                isNew
                onClick={() => {
                  addNewStep(ProgramStepType.UPDATE_SUBSCRIPTION)
                }}
              />
            ) : (
              <></>
            )}
            {renderAppropriateCopyButton()}
            <ChooserButton
              dataTest={`${dataTest}-alert-button`}
              customIcon={true}
              title={t('Send Alert')}
              description={t('Send an alert email')}
              icon={<ProgramStepIcon stepType={ProgramStepType.ALERT} />}
              onClick={() => {
                addNewStep(ProgramStepType.ALERT)
              }}
            />
            <ChooserButton
              dataTest={`${dataTest}-external-api-button`}
              customIcon={true}
              title={t('API')}
              description={t('Send contacts info via API call')}
              icon={<ProgramStepIcon stepType={ProgramStepType.EXTERNAL_API} />}
              onClick={() => {
                addNewStep(ProgramStepType.EXTERNAL_API)
              }}
            />
            {hasOutgoingWebhookStep ? (
              <ChooserButton
                dataTest={`${dataTest}-outgoing-webhooks-button`}
                customIcon={true}
                title={t('Outgoing Webhooks')}
                description={t('Send contacts info via webhooks')}
                icon={<ProgramStepIcon stepType={ProgramStepType.OUTGOING_WEBHOOK} />}
                onClick={() => {
                  addNewStep(ProgramStepType.OUTGOING_WEBHOOK)
                }}
                tooltip={aOContactsSource ? undefined : t('This step is only available when using All Contacts segments')}
                disabled={!aOContactsSource}
              />
            ) : (
              <></>
            )}
          </ChooserGroup>
          <ChooserGroup>
            <ChooserButton
              render={program.crm?.taskStepEnabled && hasCRMConnected && !hasNewCRMStep}
              dataTest={`${dataTest}-task-button`}
              customIcon={true}
              title={t('Add a Task')}
              description={`${t('Assign a task in')} ${program.crm?.crmName}`}
              icon={<ProgramStepIcon stepType={ProgramStepType.TASK} />}
              onClick={() => {
                addNewStep(ProgramStepType.TASK)
              }}
            />
            <ChooserButton
              render={program.crm?.salesforceCampaignStepEnabled && hasCRMConnected}
              dataTest={`${dataTest}-campaign-button`}
              customIcon={true}
              title={program.crm?.crmName === CrmNames.SALESFORCE ? t('Add to Campaign') : t('Add to CRM')}
              description={
                program.crm?.crmName === CrmNames.SALESFORCE ? `${t('Assign to a')} Salesforce ${t('Campaign')}` : `${t('Add to')} MS Dynamics`
              }
              icon={<ProgramStepIcon stepType={ProgramStepType.CRM_CAMPAIGN} />}
              onClick={() => {
                addNewStep(ProgramStepType.CRM_CAMPAIGN)
              }}
            />
            <ChooserButton
              render={program.crm?.createStepEnabled && hasCRMConnected}
              dataTest={`${dataTest}-create-step-button`}
              customIcon={true}
              title={t('Create in CRM')}
              description={`${t('Create new records in')} ${program.crm?.crmName}`}
              icon={<ProgramStepIcon stepType={ProgramStepType.CRM_CREATE} />}
              onClick={() => {
                addNewStep(ProgramStepType.CRM_CREATE)
              }}
            />
          </ChooserGroup>
        </ChooserScreen>
      </ModalBody>
    </Modal>
  )
}

export default AddStepModal
