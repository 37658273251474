import { gql } from '@apollo/client'

export default gql`
  query getImportDefinition($importDefinitionId: Long) {
    getImportDefinition(importDefinitionId: $importDefinitionId) {
      accountId
      bounceStatus
      createdTime
      createdTimeExecutionOffsetDateTime
      defaultMergeRule
      delimiter
      duplicateId
      hasHeader
      importDefinitionId
      importDefinitionName
      importId
      importSegmentIds
      isEnabled
      mappings {
        actonColumn
        actonColumnId
        actonStandardKey
        csvColumn
        csvColumnPosition
        dataFormat
        dataType
        importDefinition {
          accountId
          bounceStatus
          createdTime
          defaultMergeRule
          delimiter
          duplicateId
          enabled
          hasHeader
          importDefinitionId
          importDefinitionName
          importId
          importSegmentIds
          longCreatedTime
          longUpdatedTime
          mappings {
            actonColumn
            actonColumnId
            actonStandardKey
            csvColumn
            csvColumnPosition
            dataFormat
            dataType
            importMappingId
            mergeType
          }
          mergeStrategy
          mirrorImport
          objectType
          quote
          rejectHardBounces
          rejectOptOut
          rejectSuppressedDomains
          source
          updatedTime
          upsertContactSetting
          userId
          userName
        }
        importMappingId
        mergeType
      }
      mergeStrategy
      mirrorImport
      objectType
      quote
      rejectHardBounces
      rejectOptOut
      rejectSuppressedDomains
      source
      updatedTime
      updatedTimeExecutionOffsetDateTime
      upsertContactSetting
      userId
      userName
    }
  }
`
