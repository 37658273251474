import { Dispatch, SetStateAction } from 'react'

import { SegmentParentRelationInput } from '@graphql/types/microservice/segment-types'
import { List } from '@interface/foldersLists/List'
import { StepContentProps } from '@src/pages/ContactSegments/components/CopySegments/components/StepContent/StepContent'
import { stepBaseText } from '@src/pages/ContactSegments/components/CopySegments/constants/CopySegments.constants'
import { CopySegmentsContainerState, MappedField, Steps } from '@src/pages/ContactSegments/components/CopySegments/context/CopySegments.context'

type StepBasePropType = Pick<StepContentProps, 'title' | 'subTitle' | 'buttonText'>
export const stepProps: { [key in Steps]: StepBasePropType } = {
  [Steps.ONE]: { title: `${stepBaseText}One.Title`, subTitle: `${stepBaseText}One.SubTitle`, buttonText: `${stepBaseText}One.Button` },
  [Steps.TWO]: {
    title: `${stepBaseText}Two.Title`,
    subTitle: `${stepBaseText}Two.SubTitle`,
    buttonText: `${stepBaseText}Two.Button`,
  },
  [Steps.THREE]: {
    title: `${stepBaseText}Three.Title`,
    subTitle: `${stepBaseText}Three.SubTitle`,
  },
  [Steps.FOUR]: {
    title: `${stepBaseText}Four.Title`,
    subTitle: `${stepBaseText}Four.SubTitle`,
  },
}

interface UpdateMappedFieldUtilsParams {
  mappedField: MappedField
  setState: Dispatch<SetStateAction<CopySegmentsContainerState>>
}

export const updateMappedFieldUtils = ({ mappedField, setState }: UpdateMappedFieldUtilsParams) => {
  setState((state: CopySegmentsContainerState) => {
    const index = state.mappedFields.findIndex(({ marketingListField }) => marketingListField === mappedField.marketingListField)
    if (index !== -1) {
      return { ...state, mappedFields: [...state.mappedFields.slice(0, index), mappedField, ...state.mappedFields.slice(index + 1)] }
    } else {
      return { ...state, mappedFields: [...state.mappedFields, mappedField] }
    }
  })
}

const getSelectedChildren = (children: List[], segments: List[]): List[] => {
  const selectedChildren: List[] = []
  children.forEach((child) => {
    if (segments.find(({ id }) => id === child.id)) {
      selectedChildren.push({
        ...child,
        children: child.children && child.children.length > 0 ? [...getSelectedChildren(child.children, segments)] : [],
      })
    } else if (child.children && child.children.length > 0) {
      selectedChildren.push(...getSelectedChildren(child.children, segments))
    }
  })
  return selectedChildren
}

const removeChildrenFromMainArray = (segments: List[], children?: List[]): List[] => {
  let updatedSegments: List[] = segments
  const currentChildren = children ?? segments.filter(({ children }) => children && children.length > 0).flatMap(({ children }) => children)

  if (currentChildren) {
    currentChildren.forEach((child) => {
      updatedSegments = updatedSegments.filter(({ id }) => id !== child.id)
      if (child.children && child.children.length > 0) {
        updatedSegments = removeChildrenFromMainArray(updatedSegments, child.children)
      }
    })
  }

  return updatedSegments
}

const buildParentRelations = (segments: List[], parentSegmentId?: string): SegmentParentRelationInput[] => {
  let result: SegmentParentRelationInput[] = []

  segments.forEach((item) => {
    result.push({ segmentId: item.id, parentSegmentId })

    if (item.children.length > 0) {
      const children = buildParentRelations(item.children, item.id)
      result = result.concat(children)
    }
  })

  return result
}

export const getSegmentParentRelationsUtils = (segments: List[]): SegmentParentRelationInput[] => {
  const segmentsWithFilteredChildren: List[] = segments.reduce(
    (total: List[], current) => [...total, { ...current, children: getSelectedChildren(current.children, segments) }],
    []
  )
  const segmentsWithRemovedChildren = removeChildrenFromMainArray(segmentsWithFilteredChildren)
  return buildParentRelations(segmentsWithRemovedChildren)
}
