import { gql } from '@apollo/client'
import { allListsChildren } from '@graphql/queries/fragments/lists'

export default gql`
  ${allListsChildren}

  query GetFolderListsByType($type: String!, $pageNumber: Int, $pageSize: Int, $noMirroredJoinLists: Boolean) {
    getFolderListsByType(type: $type, pageNumber: $pageNumber, pageSize: $pageSize, noMirroredJoinLists: $noMirroredJoinLists) {
      ...AllListsChildren
    }
  }
`
