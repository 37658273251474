import React, { FC, useContext, useEffect } from 'react'

import classNames from 'classnames'

import { ListingPageCommonContext } from '@complex/ListingPage/Context/ListingPageCommon.context'
import PreviewAssetModalContent from '@components/PreviewAssetModal/PreviewAssetModalContent'

import AssetPickerPreviewMetadata from './AssetPickerPreviewMetadata/AssetPickerPreviewMetadata'

import './AssetPickerPreview.css'

interface AssetPickerPreviewContainerProps {
  className?: string
  dataTest?: string
}

const rootClass = 'asset-picker-preview'

const AssetPickerPreviewContainer: FC<AssetPickerPreviewContainerProps> = (props) => {
  const { className, dataTest = className } = props
  const {
    values,
    update,
    setError,
    values: {
      previewUrl,
      previewHtml,
      previewMetadata,
      renderPreview,
      loading,
      listingPageProps: { customPreviewItemCall },
    },
  } = useContext(ListingPageCommonContext)

  useEffect(() => {
    customPreviewItemCall && customPreviewItemCall(values, update, setError)
  }, [])

  return (
    <div className={classNames(`${rootClass}`, { [`${rootClass}--has-metadata`]: previewMetadata })}>
      {renderPreview ? (
        <div className={`${rootClass}__custom-content`} data-test={`${dataTest}-custom-content`}>
          {renderPreview()}
        </div>
      ) : previewUrl || previewHtml ? (
        <div className={`${rootClass}__content`}>
          <PreviewAssetModalContent
            title={'Asset Preview'}
            assetSrc={previewUrl}
            previewHtml={previewHtml}
            loading={loading}
            checkIsPreviewEmpty
            {...props}
          />
        </div>
      ) : null}
      {previewMetadata && <AssetPickerPreviewMetadata {...previewMetadata} />}
    </div>
  )
}

export default AssetPickerPreviewContainer
