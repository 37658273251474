import React, { FC } from 'react'

import classNames from 'classnames'

import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import {
  FORMAT_OPTIONS,
  FormatOptions,
  MethodOptions,
} from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditAPIStep/EditAPItep.constants'

import './APIStepDetail.css'

import Tooltip from '@components/Tooltip/Tooltip'

export interface Header {
  key: string
  value: string
}

export interface APIStepDetailProps {
  url: string
  method: MethodOptions
  format: FormatOptions
  headers: string[]
  httpHeaders: Header[]
  customFields: Header[]
  className?: string
  dataTest?: string
}

export const getFormatOption = (rawFormat: FormatOptions) => {
  const formatOptionRaw = FORMAT_OPTIONS.find((opt) => opt[0] === rawFormat)
  return formatOptionRaw?.[1] ?? ''
}

const MIN_URL_ELLIP_LENGTH = 70

const rootClass = 'api-step-detail'

const APIStepDetail: FC<APIStepDetailProps> = (props: APIStepDetailProps) => {
  const { url, method, format, headers, /* httpHeaders, customFields, */ dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const formatOption = getFormatOption(format)

  const renderSimple = (label: string, value: string, elide = false) => (
    <div className={`${rootClass}__field`}>
      <Typography text={`${t(label)}:`} className={`${rootClass}__label`} />{' '}
      {value !== '' && elide && (
        <Tooltip
          trigger={<Typography text={value} weight={TextWeight.MEDIUM} className={'ellip'} />}
          triggerClassName={`ellip ${rootClass}__field-elided`}
        >
          {value}
        </Tooltip>
      )}
      {value !== '' && !elide && <Typography text={value} weight={TextWeight.MEDIUM} />}
      {value === '' && <Typography text={t('undefined')} weight={TextWeight.ITALIC} />}
    </div>
  )

  // const renderComplexValue = (key: string, value: string) => (
  //   <div className={`${rootClass}__field-complex-values-value`} key={key}>
  //     <Typography text={`${key}:`} className={`${rootClass}__field-complex-values-value-label`} inline />{' '}
  //     <Typography text={value} weight={TextWeight.MEDIUM} className={`${rootClass}__field-complex-values-value-value`} inline />
  //   </div>
  // )
  //
  // const renderComplex = (label: string, value: Header[]) => (
  //   <div className={`${rootClass}__field-complex`}>
  //     <div className={`${rootClass}__label`}>
  //       <Typography text={`${t(label)}:`} inline />
  //     </div>
  //     <div className={`${rootClass}__field-complex-values`}>{value.map((val) => renderComplexValue(val.key, val.value))}</div>
  //   </div>
  // )

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {renderSimple('URL', url, url.length > MIN_URL_ELLIP_LENGTH)}
      {renderSimple('Method', method)}
      {renderSimple('Format', formatOption)}
      {headers.length > 0 && renderSimple('Headers', headers.join(', '))}
      {/* httpHeaders.length > 0 && renderComplex('HTTP Headers', httpHeaders) */}
      {/* customFields.length > 0 && renderComplex('Custom Fields', customFields */}
    </div>
  )
}

export default APIStepDetail
