import addFromCRMEnabled from '@graphql/microservices/crm/addFromCRMEnabled'
import saveSyncedSegment from '@graphql/microservices/crm/saveSyncedSegment'
import segmentSources from '@graphql/microservices/crm/segmentSources'
import setHadSuccessfulSync from '@graphql/microservices/crm/setHadSuccessfulSync'
import syncedSegmentCountInfo from '@graphql/microservices/crm/syncedSegmentCountInfo'
import syncedSegmentDetails from '@graphql/microservices/crm/syncedSegmentDetails'
import syncNow from '@graphql/microservices/crm/syncNow'
import validateSources from '@graphql/microservices/crm/validateSources'
import {
  AddFromCrmEnabledQuery,
  AddFromCrmEnabledQueryVariables,
  SaveSyncedSegmentMutation,
  SaveSyncedSegmentMutationVariables,
  SegmentSourcesQuery,
  SegmentSourcesQueryVariables,
  SetHadSuccessfulSyncMutation,
  SetHadSuccessfulSyncMutationVariables,
  SyncedSegmentCountInfoQuery,
  SyncedSegmentCountInfoQueryVariables,
  SyncedSegmentDetailsQuery,
  SyncedSegmentDetailsQueryVariables,
  SyncedSegmentDtoInput,
  SyncNowQuery,
  SyncNowQueryVariables,
  ValidateSourcesQuery,
  ValidateSourcesQueryVariables,
} from '@graphql/types/microservice/crm-types'
import { CRMSourceOption } from '@utils/crm.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

type AddFromCRMEnabledRequestParams = { connectedCrmName: string }
type GetSegmentSourcesRequestParams = AddFromCRMEnabledRequestParams & { crmSourceOption: CRMSourceOption }
type SaveSyncedSegmentRequestParams = { syncedSegmentDto: SyncedSegmentDtoInput }
type SyncedSegmentDetailsRequestParams = { segmentIdentifiers: string[] }
type ValidateSourcesRequestParams = AddFromCRMEnabledRequestParams & { segmentSourceTypeName: string; sourceIdentifiers: string[] }

export type AddFromCRMEnabledRequestType = (params: AddFromCRMEnabledRequestParams) => FetchPromise<AddFromCrmEnabledQuery>
export type GetSegmentSourcesRequestType = (params: GetSegmentSourcesRequestParams) => FetchPromise<SegmentSourcesQuery>
export type GetSyncedSegmentCountInfoRequestType = () => FetchPromise<SyncedSegmentCountInfoQuery>
export type SaveSyncedSegmentRequestType = (params: SaveSyncedSegmentRequestParams) => FetchPromise<SaveSyncedSegmentMutation>
type SetSuccessfulSyncFlagRequestType = (params: SetHadSuccessfulSyncMutationVariables) => FetchPromise<SetHadSuccessfulSyncMutation>
export type SyncedSegmentDetailsRequestType = (params: SyncedSegmentDetailsRequestParams) => FetchPromise<SyncedSegmentDetailsQuery>
export type SyncNowRequestType = () => FetchPromise<SyncNowQuery>
export type ValidateSourcesRequestType = (params: ValidateSourcesRequestParams) => FetchPromise<ValidateSourcesQuery>

export interface AddContactsFromCRMRequests {
  addFromCRMEnabledRequest: AddFromCRMEnabledRequestType
  getSegmentSourcesRequest: GetSegmentSourcesRequestType
  getSyncedSegmentCountInfoRequest: GetSyncedSegmentCountInfoRequestType
  saveSyncedSegmentRequest: SaveSyncedSegmentRequestType
  setSuccessfulSyncFlagRequest: SetSuccessfulSyncFlagRequestType
  syncedSegmentDetailsRequest: SyncedSegmentDetailsRequestType
  syncNowRequest: SyncNowRequestType
  validateSourcesRequest: ValidateSourcesRequestType
}

export const useAddContactsFromCRMRequests = (): AddContactsFromCRMRequests => {
  const { client: crmClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CRM })

  const addFromCRMEnabledRequest = async ({ connectedCrmName }: AddFromCRMEnabledRequestParams) =>
    await crmClient.query<AddFromCrmEnabledQuery, AddFromCrmEnabledQueryVariables>({
      query: addFromCRMEnabled,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables: { connectedCrmName },
    })

  const getSegmentSourcesRequest = async ({ connectedCrmName, crmSourceOption: { name: segmentSourceTypeName } }: GetSegmentSourcesRequestParams) =>
    await crmClient.query<SegmentSourcesQuery, SegmentSourcesQueryVariables>({
      query: segmentSources,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables: { connectedCrmName, segmentSourceTypeName },
    })

  const getSyncedSegmentCountInfoRequest = async () =>
    await crmClient.query<SyncedSegmentCountInfoQuery, SyncedSegmentCountInfoQueryVariables>({
      query: syncedSegmentCountInfo,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    })

  const saveSyncedSegmentRequest = async ({ syncedSegmentDto }: SaveSyncedSegmentRequestParams) =>
    await crmClient.mutate<SaveSyncedSegmentMutation, SaveSyncedSegmentMutationVariables>({
      mutation: saveSyncedSegment,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables: { setSyncedSegmentDto: syncedSegmentDto },
    })

  const setSuccessfulSyncFlagRequest = async (variables: SetHadSuccessfulSyncMutationVariables) =>
    await crmClient.mutate<SetHadSuccessfulSyncMutation, SetHadSuccessfulSyncMutationVariables>({
      mutation: setHadSuccessfulSync,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables,
    })

  const syncedSegmentDetailsRequest = async ({ segmentIdentifiers }: SyncedSegmentDetailsRequestParams) =>
    await crmClient.query<SyncedSegmentDetailsQuery, SyncedSegmentDetailsQueryVariables>({
      query: syncedSegmentDetails,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables: { segmentIdentifiers },
    })

  const syncNowRequest = async () =>
    await crmClient.query<SyncNowQuery, SyncNowQueryVariables>({
      query: syncNow,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    })

  const validateSourcesRequest = async ({ connectedCrmName, segmentSourceTypeName, sourceIdentifiers }: ValidateSourcesRequestParams) =>
    await crmClient.query<ValidateSourcesQuery, ValidateSourcesQueryVariables>({
      query: validateSources,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables: { connectedCrmName, segmentSourceTypeName, sourceIdentifiers },
    })

  return {
    addFromCRMEnabledRequest,
    getSegmentSourcesRequest,
    getSyncedSegmentCountInfoRequest,
    saveSyncedSegmentRequest,
    setSuccessfulSyncFlagRequest,
    syncedSegmentDetailsRequest,
    syncNowRequest,
    validateSourcesRequest,
  }
}
