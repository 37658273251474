import React, { FC, useCallback, useContext, useEffect, useMemo, useRef } from 'react'

import { useApolloClient } from '@apollo/client'
import getLitmusSpamResults from '@graphql/queries/getLitmusSpamResults'
import { GetLitmusSpamResultsQuery, GetLitmusSpamResultsQueryVariables, LitmusSpamResult, SpamTestResult } from '@graphql/types/query-types'
import { CheckCardStatus } from '@src/pages/EmailComposer/components/common/CheckStatusCard/CheckStatusCard.utils'
import {
  CheckStatusCardContainer,
  CheckStatusCardContainerProps,
} from '@src/pages/EmailComposer/components/common/CheckStatusCard/CheckStatusCardContainer'
import { handleSpamCheckModal } from '@src/pages/EmailComposer/utils/EmailComposerAPI.utils'
import { filterNotEmptyArray } from '@utils/array'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { CHECK_LITMUS_SPAM_RESULTS_INTERVAL, CHECK_LITMUS_SPAM_RESULTS_TIMEOUT } from '@utils/composer/EmailModal.constants'
import { useTranslation } from '@utils/const/globals'
import { isCustomerCareLogin } from '@utils/cookie'

type SpamCheckCardProps = Pick<CheckStatusCardContainerProps, 'className' | 'isInPreview' | 'dataTest'>

const rootClass = 'spam-check-card'

const detectPending = (result?: LitmusSpamResult | null) =>
  result
    ? {
        duration: result.testDurationMs || 0,
        pending: result.spamTests?.filter(filterNotEmptyArray).some(({ completed }) => !completed) || false,
      }
    : { pending: false, duration: 0 }

const SPAM_ASSASSIN_APP_NAME = 'SpamAssassin'

export const SpamCheckCard: FC<SpamCheckCardProps> = (props) => {
  const {
    values: {
      validations: { litmusSpamResults },
      message: { id, webinar },
      haveUnsavedChanges,
      messageConfiguration: {
        messageType,
        saveButton,
        reviewAndSend: { disableEditings },
      },
    },
    api: { updateValidations, onSave, updateModal },
  } = useContext(EmailComposerContext)

  const { separateSaveAndClose } = saveButton ?? {}

  const pendingRef = useRef<{ pending: boolean; duration: number }>(detectPending(litmusSpamResults))
  const isTimedOut = useRef<boolean>(false)
  const { t } = useTranslation()

  const client = useApolloClient()
  const { status, issuesCount, spamTests, lastChecked } = useMemo(() => {
    if (!litmusSpamResults) {
      return { status: isTimedOut.current ? CheckCardStatus.TIMEOUT : CheckCardStatus.ERROR }
    } else {
      if (!litmusSpamResults.spamTests) {
        return { status: CheckCardStatus.DEFAULT }
      } else {
        const spamTests = litmusSpamResults.spamTests.filter(
          // Temporary not showing SpamAssassin results
          (test) => filterNotEmptyArray(test) && test.appNameLong !== SPAM_ASSASSIN_APP_NAME
        ) as SpamTestResult[]
        const issuesCount = spamTests.reduce((acc, test) => {
          return acc + (test.reasons ? test.reasons.filter(filterNotEmptyArray).length : 0)
        }, 0)
        const pending = spamTests.some(({ completed }) => !completed)
        pendingRef.current.pending = pending
        pendingRef.current.duration = litmusSpamResults?.testDurationMs || 0
        const hasError = litmusSpamResults.error || spamTests.some(({ pass }) => !pass)

        return {
          status: pending ? CheckCardStatus.LOADING : hasError ? CheckCardStatus.FAILURE : CheckCardStatus.SUCCESS,
          issuesCount,
          spamTests,
          lastChecked: litmusSpamResults.createdAt,
        }
      }
    }
  }, [litmusSpamResults])

  const handleLitmusCheckError = useCallback(() => updateValidations({ litmusSpamResults: null }), [updateValidations])

  const litmusQuery = useCallback(
    (testId?: string) => {
      isTimedOut.current = false
      return client
        .query<GetLitmusSpamResultsQuery, GetLitmusSpamResultsQueryVariables>({
          query: getLitmusSpamResults,
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
          variables: { msgId: id, testId },
        })
        .then(({ data }) => {
          if (data.getLitmusSpamResults) {
            updateValidations({ litmusSpamResults: data.getLitmusSpamResults })
          } else {
            handleLitmusCheckError()
          }
        })
        .catch(() => handleLitmusCheckError())
    },
    [client, handleLitmusCheckError, id, updateValidations]
  )

  const handleCheck = useCallback(
    (testId?: string) => {
      const isCustomerCare = isCustomerCareLogin()
      if ((separateSaveAndClose || isCustomerCare) && haveUnsavedChanges && status !== 'loading') {
        handleSpamCheckModal(messageType, updateModal, onSave, litmusQuery, testId, isCustomerCare, webinar?.scheduledTime)
        return Promise.resolve()
      } else {
        return litmusQuery(testId)
      }
    },
    [separateSaveAndClose, haveUnsavedChanges, status, messageType, updateModal, onSave, litmusQuery]
  )

  useEffect(() => {
    let intervalId: NodeJS.Timeout
    if (litmusSpamResults?.testId) {
      intervalId = setInterval(() => {
        if (pendingRef.current.duration >= CHECK_LITMUS_SPAM_RESULTS_TIMEOUT) {
          isTimedOut.current = true
          handleLitmusCheckError()
        } else {
          pendingRef.current.pending ? handleCheck(litmusSpamResults.testId) : clearInterval(intervalId)
        }
      }, CHECK_LITMUS_SPAM_RESULTS_INTERVAL)
    }

    return () => {
      clearInterval(intervalId)
    }
  }, [handleCheck, handleLitmusCheckError, litmusSpamResults?.testId])

  return (
    <CheckStatusCardContainer
      {...props}
      disableCheck={disableEditings}
      tooltipContentKey={disableEditings ? t('EmailComposer.Resend.Disabled') : ''}
      checkApi={handleCheck}
      status={status}
      issuesCount={issuesCount}
      spamTests={spamTests}
      lastChecked={lastChecked}
      dataTest={rootClass}
    />
  )
}
