import recordErrorsByOrigin from '@graphql/microservices/crm-sync/recordErrorsByOrigin'
import { RecordErrorsByOriginQuery, RecordErrorsByOriginQueryVariables } from '@graphql/types/microservice/crm-sync-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export interface CrmPushErrorsModalRequests {
  getFormCRMPushErrorsRequest: (variables: RecordErrorsByOriginQueryVariables) => FetchPromise<RecordErrorsByOriginQuery>
}

export const useCrmPushErrorsModalRequests = (): CrmPushErrorsModalRequests => {
  const { client: crmSyncClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CRM_SYNC })

  const getFormCRMPushErrorsRequest = async (variables: RecordErrorsByOriginQueryVariables) => {
    return await crmSyncClient.query<RecordErrorsByOriginQuery, RecordErrorsByOriginQueryVariables>({
      query: recordErrorsByOrigin,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: {
        ...variables,
        offset: 0,
        count: 100,
      },
    })
  }

  return {
    getFormCRMPushErrorsRequest,
  }
}
