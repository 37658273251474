import { gql } from '@apollo/client'

export default gql`
  mutation deleteUsers($ids: [String]!, $domain: String, $key: String, $isRemote: Boolean) {
    deleteUsers(ids: $ids, domain: $domain, key: $key, isRemote: $isRemote) {
      status
      message
      count
      deletedUsersIds
      newUsersIds
      importedUsers
      noImportedUsers
      duplicatedUsers
    }
  }
`
