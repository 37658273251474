import { gql } from '@apollo/client'

export default gql`
  mutation saveListMaintenanceProgram($program: ListMaintenanceProgramInput!) {
    saveListMaintenanceProgram(program: $program) {
      status
      message
    }
  }
`
