import React, { FC, ReactNode } from 'react'

import './AssetPickerSidebar.css'

type AssetPickerSidebarProps = {
  children?: ReactNode
}

const rootClass = 'asset-picker-sidebar'

/**
 * This component can wrap and apply styling to any sidebar-like component
 * that contains standard sidebar menu items like Filters, Tags, Folders, etc.
 */
const AssetPickerSidebar: FC<AssetPickerSidebarProps> = (props) => {
  return <div className={rootClass}>{props.children}</div>
}

export default AssetPickerSidebar
