import React, { FC, useCallback, useContext, useEffect, useState } from 'react'

import { Status } from '@components/StatusToast/StatusToast'
import { SendTestEmailInput } from '@graphql/types/mutation-types'
import { SendTestEmailPersonalizationFallback } from '@graphql/types/query-types'
import SendTestEmailPersonalizationModal, {
  PersonalizationField,
} from '@src/pages/EmailComposer/EmailModals/components/SendTestEmailModal/components/SendTestEmailPersonalizationModal/SendTestEmailPersonalizationModal'
import {
  useCheckForPersonalization,
  useSendTestEmail,
  useSendTestEmailInfo,
} from '@src/pages/EmailComposer/EmailModals/components/SendTestEmailModal/graphQL/SendTestEmailModal.graphQL'
import SendTestEmailModal from '@src/pages/EmailComposer/EmailModals/components/SendTestEmailModal/SendTestEmailModal'
import { useAccountSettings } from '@utils/account/account.utils'
import { filterNotEmptyArray } from '@utils/array'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { logNewRelicError } from '@utils/new-relic.utils'
import { detectPersonalizationSyntaxes } from '@utils/personalization'

export interface SendTestEmailModalContainerProps {
  onClose: VoidFunction
}

const SendTestEmailModalContainer: FC<SendTestEmailModalContainerProps> = (props) => {
  const { userEmail } = useAccountSettings()
  const info = useSendTestEmailInfo()
  const { handleTestSend, loadingSend } = useSendTestEmail()
  const { handleCheckPersonalization } = useCheckForPersonalization()
  const {
    values: {
      message: { subject, previewText, id, templateJson, templateHtml },
      detectedURLChanges: { plainTextMode },
    },
    api: { updateValidations, updateModal },
  } = useContext(EmailComposerContext)

  const [showFallbackModal, setShowFallbackModal] = useState(false)
  const [sendVars, setSendVars] = useState<Omit<SendTestEmailInput, 'id'>>()
  const [uuidsForDisplayConditions, setUuidsForDisplayConditions] = useState<string[]>([])

  const [personalizationFields, setPersonalizationFields] = useState<PersonalizationField[]>([])

  useEffect(() => {
    const hasPersonalization = () => {
      const subjectFields = detectPersonalizationSyntaxes(subject)
      const previewFields = detectPersonalizationSyntaxes(previewText)
      // Detect function detects ENV fields, which are not relevant for personalization
      const messageFields = detectPersonalizationSyntaxes(templateHtml).filter((field) => !field.startsWith('{{Env.'))
      return [...subjectFields, ...previewFields, ...messageFields].length > 0
    }

    if (hasPersonalization()) {
      handleCheckPersonalization({ subject, htmlOrText: templateHtml, preview: previewText, msgId: id }).then((result) => {
        setPersonalizationFields(result ?? [])
        return false
      })
    }
  }, [handleCheckPersonalization, id, previewText, subject, templateHtml])

  const onSend = useCallback(
    (vars: Omit<SendTestEmailInput, 'id'>, uuidsForDisplayConditions: string[]) => {
      return handleTestSend({ ...vars, id }, uuidsForDisplayConditions).then((resSuccess) => {
        resSuccess && updateValidations({ deliverabilityValidations: { isTestLaunch: true } })
        return resSuccess
      })
    },
    [handleTestSend, id, updateValidations]
  )

  const handleSend = useCallback(
    (vars: Omit<SendTestEmailInput, 'id'>, uuidsForDisplayConditions: string[]) => {
      if (personalizationFields.length > 0) {
        setSendVars(vars)
        setUuidsForDisplayConditions(uuidsForDisplayConditions)
        setShowFallbackModal(true)
        return Promise.reject(false)
      } else {
        return onSend(vars, uuidsForDisplayConditions)
      }
    },
    [personalizationFields.length, onSend]
  )

  const handlePersonalizationSend = useCallback(
    (personalizationFallbacks: SendTestEmailPersonalizationFallback[]) => {
      return sendVars ? onSend({ ...sendVars, personalizationFallbacks }, uuidsForDisplayConditions) : Promise.resolve(false)
    },
    [onSend, sendVars, uuidsForDisplayConditions]
  )

  const dynamicContentOptions = templateJson.page.rows
    .map((row, i) => {
      if (row?.container?.displayCondition?.label) {
        return { label: row?.container?.displayCondition?.label, value: `${i}`, extraOptions: { uuid: row.uuid } }
      }
    })
    .filter(filterNotEmptyArray)

  useEffect(() => {
    if (info.error) {
      updateModal('statusToast', { message: 'There was an error loading Test List contacts and segments', status: Status.FAIL })
      logNewRelicError(info.error, 'Failed to load test email info')
    }
  }, [info.error])

  return showFallbackModal ? (
    <SendTestEmailPersonalizationModal
      isOpen
      loadingSend={loadingSend}
      personalizationFields={personalizationFields}
      onClose={(goBack?: boolean) => {
        setShowFallbackModal(false)
        if (!goBack) {
          props.onClose()
        }
      }}
      onAction={handlePersonalizationSend}
    />
  ) : (
    <SendTestEmailModal
      {...props}
      {...info}
      userEmail={userEmail}
      onTestSend={handleSend}
      loadingSend={loadingSend}
      subject={subject}
      plainTextMode={plainTextMode}
      hasPersonalization={personalizationFields.length > 0}
      dynamicContentOptions={dynamicContentOptions}
    />
  )
}

export default SendTestEmailModalContainer
