import React from 'react'

import { Average } from '@components/ProgressChart/ProgressChart'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { HighlightedCol, renderColWithTitle } from '@components/Table/components/tableColumns'
import { TableColumn } from '@components/Table/Table'
import { TabItemData } from '@components/TabsAO/TabsAO'
import { ChartField } from '@const/Chart.constants'
import { RecipientsPerCreditMultiplierResponse } from '@graphql/types/query-types'
import { getColorValue } from '@utils/document'

export interface ReportTabDataField extends ChartField {
  isPrimary?: boolean
  uniques?: number
}

export interface ReportEmptyState {
  text: string
  headline: string
  graphic: StaticImageNames
}

export interface ReportTabData {
  [key: string]: {
    title: string
    fields: ReportTabDataField[]
    average?: Average
    columns: TableColumn[]
    infoText: string
    emptyState: ReportEmptyState
  }
}

export enum ReportTabs {
  SUMMARY = 'summary',
  DELIVERED = 'delivered',
  CLICKED = 'clicked',
  REPLIED = 'replied',
  FAILED = 'failed',
  OPTED_OUT = 'optedout',
}

const neutralColor = getColorValue('--neutral')

export const tabsMockData: TabItemData[] = [
  {
    index: 'summary',
    label: 'Sent',
    labelContent: {
      primary: <Svg name={SvgNames.summaryReportIcon} type={SvgType.LARGER_ICON} />,
      split: <Svg name={SvgNames.airplane} type={SvgType.LARGER_ICON} />,
    },
    themeName: 'base',
    content: '',
  },
  {
    index: 'delivered',
    label: 'Delivered',
    labelContent: {
      primary: '0',
      secondary: '0%',
      split: <Svg name={SvgNames.hospital} type={SvgType.LARGER_ICON} />,
    },
    themeName: 'delivered',
    content: '',
  },
  {
    index: 'clicked',
    label: 'Clicked',
    labelContent: {
      primary: '0',
      secondary: '0%',
      split: <Svg name={SvgNames.letter} type={SvgType.LARGER_ICON} />,
    },
    themeName: 'clicked',
    content: '',
  },
  {
    index: 'replied',
    label: 'Replied',
    labelContent: {
      primary: '0',
      secondary: '0%',
      split: <Svg name={SvgNames.megaphone} type={SvgType.LARGER_ICON} />,
    },
    themeName: 'replied',
    content: '',
  },
  {
    index: 'failed',
    label: 'Failed',
    labelContent: {
      primary: '0',
      secondary: '0%',
      split: <Svg name={SvgNames.cellphoneOff} type={SvgType.LARGER_ICON} />,
    },
    themeName: 'failed',
    content: '',
  },
  {
    index: 'optedout',
    label: 'Opted out',
    themeName: 'optedout',
    labelContent: {
      primary: '0',
      secondary: '0%',
      split: <Svg name={SvgNames.checkCircle} type={SvgType.LARGER_ICON} />,
    },
    content: '',
  },
]

export const FAILED_FIELDS = [
  {
    data: 0,
    uniques: 0,
    label: 'Other',
    isPrimary: true,
    color: getColorValue('--failure-other'),
  },
  {
    data: 0,
    uniques: 0,
    label: 'Blocked by Carrier',
    color: getColorValue('--failure-filtered'),
  },
  {
    data: 0,
    uniques: 0,
    label: 'Fatigue',
    color: getColorValue('--failure-fatigue'),
  },
  {
    data: 0,
    uniques: 0,
    label: 'Unreachable Destination',
    color: getColorValue('--failure-unreachable'),
  },
  {
    data: 0,
    uniques: 0,
    label: 'Queue Overflow',
    color: getColorValue('--failure-overflow'),
  },
  {
    data: 0,
    uniques: 0,
    label: 'Landline',
    color: getColorValue('--failure-landline'),
  },
  {
    data: 0,
    uniques: 0,
    label: 'Queue Maxed Out',
    color: getColorValue('--failure-from-max'),
  },
  {
    data: 0,
    uniques: 0,
    label: 'Region not Permitted',
    color: getColorValue('--failure-region-permission'),
  },
  {
    data: 0,
    uniques: 0,
    label: 'Unknown Destination',
    color: getColorValue('--failure-unknown-destination'),
  },
  {
    data: 0,
    uniques: 0,
    label: 'Unknown Error',
    color: getColorValue('--failure-unknown'),
  },
  {
    data: 0,
    uniques: 0,
    label: 'Unsubscribed Recipient',
    color: getColorValue('--failure-unsubscribed'),
  },
]

export const reportTabData: ReportTabData = {
  delivered: {
    title: 'SMS Send Details',
    infoText: 'All recipients who were **sent** the message',
    fields: [
      {
        data: 0,
        uniques: 0,
        label: 'Delivered',
        isPrimary: true,
        color: getColorValue('--border-delivered'),
      },
      {
        data: 0,
        label: 'Was Not Delivered',
        color: neutralColor,
      },
    ],
    average: {
      percentage: 0,
      label: 'AVG Delivery Rate',
      legend: 'Your Average SMS Delivery Rate',
    },
    columns: [
      {
        Header: 'Name',
        accessor: 'contactName',
        align: 'left',
        minWidth: 156,
        sortType: 'caseInsensitive',
        Cell: (row: any) => renderColWithTitle(row),
        flexColumn: true,
        fallback: 'No name provided',
        style: {
          fontStyle: 'italic',
        },
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneNumber',
        align: 'left',
        minWidth: 132,
        maxWidth: 132,
      },
    ],
    emptyState: {
      text: 'You don’t have any delivered messages at the moment.',
      headline: 'No messages delivered',
      graphic: StaticImageNames.errorStateAllContacts,
    },
  },
  clicked: {
    title: 'SMS Click Details',
    infoText: 'All **clicks** on the message',
    fields: [
      {
        data: 0,
        uniques: 0,
        label: 'Clicked',
        isPrimary: true,
        color: getColorValue('--border-clicked'),
      },
      {
        data: 0,
        label: 'Did Not Click',
        color: neutralColor,
      },
    ],
    average: {
      percentage: 0,
      label: 'AVG Click Rate',
      legend: 'Your Average SMS Click Rate',
    },
    columns: [
      {
        Header: 'Name',
        accessor: 'contactName',
        align: 'left',
        minWidth: 156,
        sortType: 'caseInsensitive',
        Cell: (row: any) => renderColWithTitle(row),
        flexColumn: true,
        fallback: 'No name provided',
        style: {
          fontStyle: 'italic',
        },
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneNumber',
        align: 'left',
        minWidth: 132,
        maxWidth: 132,
      },
      {
        Header: 'Clicked On',
        accessor: 'clickedOnDate',
        align: 'left',
        sortType: 'statusDateTime',
        minWidth: 156,
        maxWidth: 156,
      },
    ],
    emptyState: {
      text: 'You don’t have any new clicks at the moment.',
      headline: 'No clicks yet',
      graphic: StaticImageNames.smsClicksEmpty,
    },
  },
  replied: {
    title: 'SMS Reply Details',
    infoText: 'All **replies** to the message',
    fields: [
      {
        data: 0,
        uniques: 0,
        label: 'Replied',
        isPrimary: true,
        color: getColorValue('--border-replied'),
      },
      {
        data: 0,
        label: 'Did Not Reply',
        color: neutralColor,
      },
    ],
    average: {
      percentage: 0,
      label: 'AVG Reply Rate',
      legend: 'Your Average SMS Reply Rate',
    },
    columns: [
      {
        Header: 'Name',
        accessor: 'contactName',
        align: 'left',
        minWidth: 156,
        maxWidth: 156,
        sortType: 'caseInsensitive',
        Cell: (row: any) => renderColWithTitle(row),
        fallback: 'No name provided',
        style: {
          fontStyle: 'italic',
        },
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneNumber',
        align: 'left',
        minWidth: 132,
        maxWidth: 132,
      },
      {
        Header: 'Reply',
        accessor: 'replyText',
        minWidth: 132,
        flexColumn: true,
        align: 'left',
        className: 'table__spacing-right',
        Cell: (row: any) => renderHighlightCell(row),
      },
      {
        Header: 'Replied On',
        accessor: 'repliedOnDate',
        align: 'left',
        minWidth: 144,
        maxWidth: 144,
        sortType: 'statusDateTime',
      },
    ],
    emptyState: {
      text: 'You don’t have any new replies at the moment.',
      headline: 'No replies yet',
      graphic: StaticImageNames.smsRepliesEmpty,
    },
  },
  failed: {
    title: 'SMS Failed Send Details',
    infoText: 'All recipients who **did not receive** the message',
    fields: FAILED_FIELDS,
    columns: [
      {
        Header: 'Name',
        accessor: 'contactName',
        align: 'left',
        minWidth: 156,
        maxWidth: 156,
        sortType: 'caseInsensitive',
        Cell: (row: any) => renderColWithTitle(row),
        fallback: 'No name provided',
        style: {
          fontStyle: 'italic',
        },
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneNumber',
        align: 'left',
        minWidth: 132,
        maxWidth: 132,
      },
      {
        Header: 'Reason',
        accessor: 'reason',
        align: 'left',
        minWidth: 156,
        flexColumn: true,
        sortType: 'caseInsensitive',
      },
    ],
    emptyState: {
      text: 'All messages were delivered successfully.',
      headline: 'Great News!',
      graphic: StaticImageNames.emptyCelebrateSmall,
    },
  },
  optedout: {
    title: 'SMS Opt-Out Details',
    infoText: 'All recipients who **opted-out** after receiving the message',
    fields: [
      {
        data: 55,
        uniques: 50,
        label: 'Opted Out',
        isPrimary: true,
        color: getColorValue('--border-optedout'),
      },
      {
        data: 1238,
        label: 'Not Opted-out',
        color: neutralColor,
      },
    ],
    average: {
      percentage: 4,
      label: 'AVG Opt-Out Rate',
      legend: 'Your Average SMS Opt-out Rate',
    },
    columns: [
      {
        Header: 'Name',
        accessor: 'contactName',
        align: 'left',
        minWidth: 156,
        sortType: 'caseInsensitive',
        Cell: (row: any) => renderColWithTitle(row),
        flexColumn: true,
        style: {
          fontStyle: 'italic',
        },
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneNumber',
        align: 'left',
        minWidth: 132,
        maxWidth: 132,
      },
      {
        Header: 'Opted-Out On',
        accessor: 'optedOutOnDate',
        align: 'left',
        minWidth: 156,
        maxWidth: 156,
        sortType: 'statusDateTime',
      },
    ],
    emptyState: {
      text: 'No recipients have opted out after receiving this message.',
      headline: 'No opt-outs',
      graphic: StaticImageNames.smsOptOutEmpty,
    },
  },
}

const renderHighlightCell = (row: any) => <HighlightedCol row={row} />

interface Failure {
  twilio: string
  label: string
  color: string
}

export const Failures: Failure[] = [
  {
    twilio: 'Message filtered',
    label: 'Blocked by Carrier',
    color: getColorValue('--failure-filtered'),
  },
  {
    twilio: 'Fatigue',
    label: 'Fatigue',
    color: getColorValue('--failure-filtered'),
  },
  {
    twilio: 'Unreachable destination handset',
    label: 'Unreachable Destination',
    color: getColorValue('--failure-unreachable'),
  },
  {
    twilio: 'Queue overflow',
    label: 'Queue overflow',
    color: getColorValue('--failure-overflow'),
  },
  {
    twilio: 'Landline or unreachable carrier',
    label: 'Landline',
    color: getColorValue('--failure-landline'),
  },
  {
    twilio: `This 'From' number has exceeded the maximum number of queued messages`,
    label: 'Queue Maxed Out',
    color: getColorValue('--failure-from-max'),
  },
  {
    twilio: "Permission to send an SMS has not been enabled for the region indicated by the 'To' number",
    label: 'Region not Permitted',
    color: getColorValue('--failure-region-permission'),
  },
  {
    twilio: 'Unknown destination handset',
    label: 'Unknown Destination',
    color: getColorValue('--failure-unknown-destination'),
  },
  {
    twilio: 'Unknown error',
    label: 'Unknown Error',
    color: getColorValue('--failure-unknown'),
  },
  {
    twilio: 'Attempt to send to unsubscribed recipient',
    label: 'Unsubscribed Recipient',
    color: getColorValue('--failure-unsubscribed'),
  },
  {
    twilio: 'other',
    label: 'Other',
    color: getColorValue('--failure-other'),
  },
]

export interface MessageDetailsType {
  messageText: string
  messageTitle: string
  description: string
  sentBy?: string
  createdBy?: string
  sentDate: number
  contactLists?: {
    listName: string
    listType: string
    totalContacts: number
  }[]
  noPhoneNumbers: number
  duplicates: number
  notOptIns: number
  messagesSent: number
  billableCredits: number
  failedPersonalizationCount: number
  suppressedInternationalContacts: number
  recipientsPerCreditMultiplier: RecipientsPerCreditMultiplierResponse[]
}
