import React, { FC } from 'react'

import classNames from 'classnames'

import ProgramHeader from '@complex/ProgramHeader/ProgramHeader'
import { useTranslation } from '@const/globals'
import { ProgramPerformanceTab } from '@src/pages/programs/dashboard/components/ProgramPerformance/ProgramPerformance.constants'

import './ProgramPerformanceTableHeader.css'

export type ProgramPerformanceTableHeaderProps = {
  tableSubtitle: string
  tableTitle: string
  programPerformanceTab: ProgramPerformanceTab
  onSearch?: (filter: string) => void
  searchTerm?: string
  downloadUrl?: string
  showReentryWarning: boolean
  className?: string
  dataTest?: string
}

const rootClass = 'program-performance-table-header'

const ProgramPerformanceTableHeader: FC<ProgramPerformanceTableHeaderProps> = (props: ProgramPerformanceTableHeaderProps) => {
  const { tableTitle, tableSubtitle, downloadUrl, onSearch, searchTerm = '', showReentryWarning, className = '', dataTest = rootClass } = props
  const { t } = useTranslation()
  const downloadTooltip = !searchTerm ? { tooltipText: t('Download') } : {}

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={classNames(`${rootClass}__title-search`)}>
        {downloadUrl && (
          <>
            <ProgramHeader
              {...downloadTooltip}
              downloadUrl={downloadUrl}
              tableTitle={t(tableTitle)}
              headerText={t(tableTitle)}
              tableSubtitle={t(tableSubtitle)}
              showReentryWarning={showReentryWarning}
              isProgramDashboard
              searchPlaceholder={t('Search list')}
              onSearch={onSearch}
              searchIncomingValue={searchTerm}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default ProgramPerformanceTableHeader
