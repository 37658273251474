import React, { useContext } from 'react'
import { components, ValueContainerProps } from 'react-select'

import classNames from 'classnames'

import Dots, { LoaderSize } from '@components/Dots/Dots'
import { selectRootClass } from '@components/SelectV2/SelectV2.utils'
import Svg, { SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'

import { SelectV2Context } from '../SelectV2.context'

const CustomValueContainer = (props: ValueContainerProps<any>) => {
  const { children, hasValue } = props
  const {
    values: { error, inputIcon, showIconOnSelect, searchLoading, hasSearchOnClick, isMulti },
  } = useContext(SelectV2Context)

  return (
    <>
      {!!children && inputIcon && (showIconOnSelect ? hasValue : true) && (
        <div
          className={classNames(`${selectRootClass}__control-icon`, {
            [`${selectRootClass}__control-icon--multi`]: isMulti,
          })}
        >
          <Svg
            name={inputIcon}
            className={!!error ? `${selectRootClass}__control-icon-error` : `${selectRootClass}__control-icon-default`}
            type={SvgType.LARGER_ICON}
            fill={!!error ? SvgColor.ERROR_TEXT : SvgColor.LIGHT_GRAY}
          />
        </div>
      )}
      <components.ValueContainer {...props}>{children}</components.ValueContainer>
      {searchLoading && !hasSearchOnClick && (
        <Dots
          className={classNames(`${selectRootClass}__control-dots`, {
            [`${selectRootClass}__control-dots--multi`]: isMulti,
          })}
          size={LoaderSize.SMALL}
        />
      )}
    </>
  )
}

export default CustomValueContainer
