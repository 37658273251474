import { gql } from '@apollo/client'

export default gql`
  query getWelcomeMessage($accountId: Long!) {
    getWelcomeMessage(accountId: $accountId) {
      errorCode
      errorMessage
      smsMessage {
        accountId
        description
        messageText
        smsMessageId
        status
        title
      }
      statusCode
    }
  }
`
