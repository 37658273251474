import React, { useEffect, useMemo } from 'react'

import classNames from 'classnames'
import { AnyAction } from 'redux'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'

import { updateInputReducer } from '../input.utils'

import './PasswordInfoBlock.css'

interface PasswordInfoBlockProps {
  inputValue: string
  dispatch: React.Dispatch<AnyAction>
}

const rootClass = 'password-block'

const MIN_CHARACTERS = 6

export const PasswordInfoBlock = ({ inputValue, dispatch }: PasswordInfoBlockProps) => {
  const hasMinLength = inputValue.length >= MIN_CHARACTERS
  const hasUppercase = /[A-Z\p{Lu}]/u.test(inputValue)
  const hasLowercase = /[a-z\p{Ll}]/u.test(inputValue)
  const hasNumber = /[0-9]/.test(inputValue)
  const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-/|]/.test(inputValue)
  const emptyValue = inputValue == ''

  const blocks = useMemo(
    () => [
      { text: 'At least 6 characters', success: hasMinLength },
      { text: 'Uppercase and lowercase letters', success: hasUppercase && hasLowercase },
      { text: 'At least one number (0-9)', success: hasNumber },
      { text: 'At least one special character', success: hasSpecialCharacter },
    ],
    [hasLowercase, hasMinLength, hasNumber, hasSpecialCharacter, hasUppercase]
  )

  useEffect(() => {
    const isPassValid = hasMinLength && hasUppercase && hasLowercase && hasNumber && hasSpecialCharacter
    dispatch(updateInputReducer('PASSWORD_VALIDATION_ERROR', !isPassValid && !emptyValue))
  }, [dispatch, emptyValue, hasLowercase, hasMinLength, hasNumber, hasSpecialCharacter, hasUppercase])

  return (
    <div className={`${rootClass}__wrapper`}>
      {blocks.map((block) => {
        const statusIcon =
          !block.success && !emptyValue ? SvgNames.errorCircle : block.success ? SvgNames.inputStatusSuccess : SvgNames.inputStatusActive

        return (
          <div key={block.text} className={rootClass}>
            <Svg
              className={classNames(`${rootClass}__svg`, {
                [`${rootClass}__status-success`]: block.success && !emptyValue,
                [`${rootClass}__status-error`]: !block.success && !emptyValue,
                [`${rootClass}__status-default`]: !block.success && emptyValue,
              })}
              type={SvgType.ICON}
              name={statusIcon}
              dataTest={rootClass}
            />
            <Typography
              className={classNames({
                [`${rootClass}__status-success`]: block.success && !emptyValue,
                [`${rootClass}__status-error`]: !block.success && !emptyValue,
              })}
              text={block.text}
              type={TextType.NORMAL_TEXT_GRAY}
              lineHeight={LineHeight.MEDIUM_SMALL}
            />
          </div>
        )
      })}
    </div>
  )
}
