import { gql } from '@apollo/client'

export default gql`
  query getInboxAssetsDataWithoutPreviewInfo {
    getInboxAssetsDataWithoutPreviewInfo {
      id
      type
      title
      fromUser
      fromOrg
      date
      sharedAccountId
    }
  }
`
