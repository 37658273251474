import React, { FC } from 'react'

import classNames from 'classnames'

import chooserUtils from '@components/ChooserScreen/chooserUtils'

import { ChooserButtonProps } from './ChooserButton'
import { ChooserGroupProps } from './ChooserGroup'

import './chooserScreen.css'

export interface ChooserScreenProps {
  className?: string
  dataTest?: string
  isGrouped?: boolean
  children: React.ReactElement<ChooserButtonProps | ChooserGroupProps> | React.ReactElement<ChooserButtonProps | ChooserGroupProps>[]
}

export const rootClass = 'chooser-screen'

export const ChooserScreen: FC<ChooserScreenProps> = (props: ChooserScreenProps) => {
  const { isGrouped = false, dataTest = 'chooser-screen', className, children } = props

  let minimalButtons = false
  if (!isGrouped) {
    const numberOfChildren = chooserUtils.getNumberOfRenderedChildren(children)
    minimalButtons = numberOfChildren <= 3
  }

  return (
    <div
      data-test={dataTest}
      className={classNames(rootClass, className, {
        [`${rootClass}--minimal-buttons`]: minimalButtons,
        [`${rootClass}--grouped`]: isGrouped,
      })}
    >
      {children}
    </div>
  )
}

export default ChooserScreen
