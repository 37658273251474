import React, { FC, Key, useState } from 'react'

import classNames from 'classnames'

import BackButton from '@components/BackButton/BackButton'
import Button, { ButtonType } from '@components/Button'
import ButtonIcon, { ButtonIconType } from '@components/ButtonIcon/ButtonIcon'
import Checkbox from '@components/Checkbox'
import CheckboxGroup from '@components/CheckboxGroup/CheckboxGroup'
import InfoTooltip from '@components/InfoTooltip/InfoTooltip'
import InputV2 from '@components/InputV2/InputV2'
import { ValidityReturnType } from '@components/InputWithStatus/InputWithStatus'
import { LabelType, LabelV2 } from '@components/LabelV2/LabelV2'
import { LinkTextButton } from '@components/LinkTextButton/LinkTextButton'
import Modal, { ModalBody } from '@components/Modal'
import ModalFooter, { ModalFooterType } from '@components/Modal/components/ModalFooter'
import ModalHeader, { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import RadioCard from '@components/RadioCard/RadioCard'
import RadioCardGroup from '@components/RadioCardGroup/RadioCardGroup'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import SelectV2Popover from '@components/SelectV2Popover/SelectV2Popover'
import { SvgNames } from '@components/Svg'
import { SvgType } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { RssItem } from '@graphql/types/query-types'
import { renderBoldTextOnFirstMatch } from '@utils/typography'

import { RssBlock } from './components/RssBlock/RssBlock'
import { DEFAULT_NUM_ITEM_OPTION, OPTIONS, RssAlignment, RssFormat, RssState, Rss_CONNECT_ARTICLE } from './RssModalContainer'

import './RSSModal.css'

interface RssModalProps {
  url: string
  rssFeedItems: RssItem[]
  selectedLayoutOption?: SelectV2SingleOption
  includeDate: boolean
  includeAuthor: boolean
  includeReadMoreLink: boolean
  numItemsToDisplay?: string
  formatOption: Key
  textAlignment: RssAlignment
  layoutOptions: SelectV2SingleOption[]
  isValidUrl: boolean
  id: string
  isPlainText: boolean
  isLandingPage?: boolean
  updateState: (toUpdate: Partial<RssState>) => void
  onClose: () => void
  validateAndGetRssFeed: (url: string) => Promise<ValidityReturnType | undefined>
  handleInsert: () => void
  getRssPlainText: () => Promise<void>
  plainText?: string
  className?: string
  dataTest?: string
}

const rootClass = 'rss-modal'

const RssModal: FC<RssModalProps> = (props: RssModalProps) => {
  const {
    url,
    rssFeedItems,
    selectedLayoutOption,
    textAlignment,
    includeDate,
    includeAuthor,
    layoutOptions,
    includeReadMoreLink,
    formatOption,
    numItemsToDisplay,
    isValidUrl,
    id,
    isPlainText,
    plainText,
    isLandingPage,
    updateState,
    validateAndGetRssFeed,
    onClose,
    handleInsert,
    getRssPlainText,
    dataTest = rootClass,
    className = '',
  } = props

  const { t } = useTranslation()

  const [showRssLayout, setShowRssLayout] = useState<boolean>(false)
  const showImageLayout = formatOption === RssFormat.FULL_CONTENT && rssFeedItems.every((item) => item.content !== '') && !isPlainText
  const showContentAlignment = !isPlainText

  const renderOptionsBody = () => (
    <div className={`${rootClass}__options`} data-test={dataTest}>
      <Typography
        text={t('EmailComposer.RssModal.Description', { context: isLandingPage ? 'landingPage' : 'default' })}
        type={TextType.BODY_TEXT_LIGHT}
        tagComponents={{
          LinkTextButton: <LinkTextButton link={Rss_CONNECT_ARTICLE} hideIconLeft />,
        }}
      />
      <div>
        <InputV2
          labelProps={{ label: <Typography text={t('EmailComposer.RssModal.Input.Label')} weight={TextWeight.MEDIUM} /> }}
          placeholder={t('EmailComposer.RssModal.Input.Placeholder')}
          value={url}
          statusProps={{
            customTooltipErrorMessages: t('EmailComposer.RssModal.Input.Label.Error'),
            showStatus: true,
            onlyValidateOnBlur: true,
            validityFunctions: [async (url) => await validateAndGetRssFeed(url)],
          }}
          leadingIcon={SvgNames.linkedUnselected}
          dataTest={`${rootClass}-input`}
        />
      </div>
      <div>
        <LabelV2 label={t('Format settings')} labelType={LabelType.medium} className={`${rootClass}__label`} />
        <RadioCardGroup
          selectedOption={formatOption}
          onSelect={(formatOption: Key) => {
            if (formatOption === RssFormat.TITLE && isPlainText) {
              updateState({ formatOption, includeDate: false, includeAuthor: false, includeReadMoreLink: false })
            } else {
              updateState({ formatOption })
            }
          }}
        >
          <RadioCard
            title={t('EmailComposer.RssModal.RadioCard.FullContent.Title')}
            titleWeight={TextWeight.MEDIUM}
            description={t('EmailComposer.RssModal.RadioCard.FullContent.Description')}
            svgName={SvgNames.rssFullContent}
            key={RssFormat.FULL_CONTENT}
            className={`${rootClass}__radio-card`}
            dataTest={`${rootClass}-full-content-radio-card`}
          />
          <RadioCard
            title={t('EmailComposer.RssModal.RadioCard.SummaryContent.Title')}
            titleWeight={TextWeight.MEDIUM}
            description={t('EmailComposer.RssModal.RadioCard.SummaryContent.Description')}
            svgName={SvgNames.rssSummaryContent}
            key={RssFormat.SUMMARY}
            className={`${rootClass}__radio-card`}
            dataTest={`${rootClass}-summary-content-radio-card`}
          />
          <RadioCard
            title={t('EmailComposer.RssModal.RadioCard.TitleContent.Title')}
            titleWeight={TextWeight.MEDIUM}
            description={t('EmailComposer.RssModal.RadioCard.TitleContent.Description')}
            svgName={SvgNames.rssTitleOnly}
            key={RssFormat.TITLE}
            className={`${rootClass}__radio-card`}
            dataTest={`${rootClass}-title-radio-card`}
          />
        </RadioCardGroup>
      </div>
      <div className={`${rootClass}__select-group`}>
        <div className={`${rootClass}__select-group-label`}>
          <Typography text={t('EmailComposer.RssModal.Select.Label')} weight={TextWeight.MEDIUM} />
          <InfoTooltip inline>
            {t('EmailComposer.RssModal.Select.Label.InfoTooltip', { context: isLandingPage ? 'landingPage' : 'default' })}
          </InfoTooltip>
        </div>
        <SelectV2
          insideModal
          isClearable={false}
          isSearchable={false}
          maxMenuHeight={200}
          options={OPTIONS}
          defaultValue={DEFAULT_NUM_ITEM_OPTION}
          value={!!numItemsToDisplay ? { value: numItemsToDisplay, label: numItemsToDisplay } : DEFAULT_NUM_ITEM_OPTION}
          onChange={(numItemsToDisplay) => numItemsToDisplay && updateState({ numItemsToDisplay })}
          className={`${rootClass}__select-group-selectV2`}
        />
      </div>
      <div>
        <LabelV2 label={t('EmailComposer.RssModal.Checkbox.Group.Label')} labelType={LabelType.medium} className={`${rootClass}__label`} />
        <CheckboxGroup className={`${rootClass}__checkbox-group`}>
          <Checkbox
            checked={includeDate}
            label={t('EmailComposer.RssModal.Checkbox.Date.Label')}
            disabled={formatOption === RssFormat.TITLE && isPlainText}
            onChange={(checked) => {
              updateState({ includeDate: checked })
            }}
            dataTest={`${rootClass}-date-checkbox`}
          />
          <Checkbox
            checked={includeAuthor}
            label={t('EmailComposer.RssModal.Checkbox.Author.Label')}
            disabled={formatOption === RssFormat.TITLE && isPlainText}
            onChange={(checked) => updateState({ includeAuthor: checked })}
            dataTest={`${rootClass}-author-checkbox`}
          />
          <Checkbox
            checked={includeReadMoreLink}
            label={t('EmailComposer.RssModal.Checkbox.ReadMore.Label')}
            disabled={formatOption === RssFormat.TITLE && isPlainText}
            onChange={(checked) => updateState({ includeReadMoreLink: checked })}
            dataTest={`${rootClass}-readMore-checkbox`}
          />
        </CheckboxGroup>
      </div>
    </div>
  )

  const renderAnchor = () => (
    <div className={`${rootClass}__anchor`}>
      <div className={`${rootClass}__anchor-svg`}>{selectedLayoutOption?.renderItemIcon?.()}</div>
      <div>
        <LabelV2 label={t(selectedLayoutOption?.label)} labelType={LabelType.medium} />
        <Typography text={t(selectedLayoutOption?.subText)} type={TextType.BODY_TEXT_SMALL_LIGHT} />
      </div>
    </div>
  )

  const renderTrigger = () => <Button buttonType={ButtonType.INFO}>{t('Change')}</Button>

  const renderLayoutBody = () => (
    <>
      {showImageLayout && (
        <div data-test={`${rootClass}-layout-options`}>
          <SelectV2Popover
            hideSearchInput
            renderTrigger={renderTrigger}
            renderAnchor={renderAnchor}
            selectProps={{
              options: layoutOptions,
              value: selectedLayoutOption,
              insideModal: true,
              onChange: (selectedLayoutOption) => updateState({ selectedLayoutOption }),
              renderCustomOption: (data: SelectV2SingleOption, search?: string) => (
                <>
                  <Typography
                    inline
                    dataTest={'selectv2-option-value'}
                    text={search ? renderBoldTextOnFirstMatch({ search, value: data.label }) : data.label}
                    weight={TextWeight.MEDIUM}
                  />
                  {data.subText && <Typography text={data.subText} type={TextType.BODY_TEXT_LIGHT} />}
                </>
              ),
            }}
            className={`${rootClass}__selectV2-popover`}
          />
        </div>
      )}
      {showContentAlignment && (
        <div>
          <div className={`${rootClass}__alignment`}>
            <Typography text={t('Content alignment')} weight={TextWeight.MEDIUM} />
            <div className={`${rootClass}__button-icons`}>
              <Tooltip
                trigger={
                  <ButtonIcon
                    icon={SvgNames.rssAlignmentLeft}
                    type={ButtonIconType.SECONDARY}
                    iconSize={SvgType.LARGE_ICON}
                    onClick={() => updateState({ textAlignment: RssAlignment.LEFT })}
                    className={classNames(`${rootClass}__button-icon`, {
                      [`${rootClass}__button-icons-active`]: textAlignment === RssAlignment.LEFT,
                    })}
                  />
                }
              >
                {t('EmailComposer.RssModal.Alignment.Left')}
              </Tooltip>
              <Tooltip
                trigger={
                  <ButtonIcon
                    icon={SvgNames.rssAlignmentMiddle}
                    type={ButtonIconType.SECONDARY}
                    iconSize={SvgType.LARGE_ICON}
                    onClick={() => updateState({ textAlignment: RssAlignment.CENTER })}
                    className={classNames(`${rootClass}__button-icon`, {
                      [`${rootClass}__button-icons-active`]: textAlignment === RssAlignment.CENTER,
                    })}
                  />
                }
              >
                {t('EmailComposer.RssModal.Alignment.Center')}
              </Tooltip>
              <Tooltip
                trigger={
                  <ButtonIcon
                    icon={SvgNames.rssAlignmentRight}
                    type={ButtonIconType.SECONDARY}
                    iconSize={SvgType.LARGE_ICON}
                    onClick={() => updateState({ textAlignment: RssAlignment.RIGHT })}
                    className={classNames(`${rootClass}__button-icon`, {
                      [`${rootClass}__button-icons-active`]: textAlignment === RssAlignment.RIGHT,
                    })}
                  />
                }
              >
                {t('EmailComposer.RssModal.Alignment.Right')}
              </Tooltip>
            </div>
          </div>
        </div>
      )}
      <div className={classNames(`${rootClass}__rss-feed`, { [`${rootClass}__rss-feed-plain-text`]: isPlainText })}>
        <RssBlock
          id={id}
          rssFeedItems={rssFeedItems.slice(0, parseInt(numItemsToDisplay ?? ''))}
          includeAuthor={includeAuthor}
          includeDate={includeDate}
          selectedLayoutOption={selectedLayoutOption}
          includeReadMoreLink={includeReadMoreLink}
          textAlignment={textAlignment}
          formatOption={formatOption as RssFormat}
          isPlainText={isPlainText}
          plainText={plainText}
        />
      </div>
    </>
  )

  const handleNext = async () => {
    await getRssPlainText()
    setShowRssLayout(true)
  }

  return (
    <Modal
      isOpen
      className={classNames(rootClass, className)}
      dataTest={dataTest}
      header={
        <ModalHeader headerType={ModalHeaderType.Form} className={classNames({ [`${rootClass}__header-with-back-button`]: showRssLayout })}>
          {showRssLayout && <BackButton onClick={() => setShowRssLayout(false)} />}
          <Typography text={t('EmailComposer.RssModal.Title')} type={TextType.DATA_CARD_MODAL_HEADER} />
        </ModalHeader>
      }
    >
      <ModalBody className={classNames(`${rootClass}__body`, { [`${rootClass}__body-plain-text-preview`]: isPlainText && showRssLayout })}>
        {showRssLayout ? renderLayoutBody() : renderOptionsBody()}
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form}>
        <Button buttonType={ButtonType.TERTIARY} onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button
          buttonType={ButtonType.PRIMARY}
          dataTest={showRssLayout ? `${rootClass}-insert-button` : `${rootClass}-next-button`}
          onClick={() => (showRssLayout ? handleInsert() : handleNext())}
          disabled={!isValidUrl}
        >
          {t(showRssLayout ? 'Insert' : 'Next')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default RssModal
