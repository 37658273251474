import { useCallback } from 'react'

import { useApolloClient } from '@apollo/client'
import createLandingPageFromEmailTemplate from '@graphql/mutations/createLandingPageFromEmailTemplate'
import deleteEmailTemplates from '@graphql/mutations/deleteEmailTemplates'
import duplicateEmailTemplate from '@graphql/mutations/duplicateEmailTemplate'
import getEmailTemplatePreview from '@graphql/queries/getEmailTemplatePreview'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import {
  CreateLandingPageFromEmailTemplateMutation,
  CreateLandingPageFromEmailTemplateMutationVariables,
  DeleteEmailTemplatesMutation,
  DeleteEmailTemplatesMutationVariables,
  DuplicateEmailTemplateMutation,
  DuplicateEmailTemplateMutationVariables,
} from '@graphql/types/mutation-types'
import { GetEmailTemplatePreviewQuery, GetEmailTemplatePreviewQueryVariables, ItemDto } from '@graphql/types/query-types'
import { FetchPromise } from '@utils/types'

export interface EmailTemplatesListingPageRequests {
  deleteItemsRequest: (items: ItemDto[]) => FetchPromise<DeleteEmailTemplatesMutation>
  getItemPreviewRequest: (itemId: string) => FetchPromise<GetEmailTemplatePreviewQuery>
  createLandingPageFromTemplateRequest: (
    variables: CreateLandingPageFromEmailTemplateMutationVariables
  ) => FetchPromise<CreateLandingPageFromEmailTemplateMutation>
  duplicateEmailTemplateRequest: (
    templateId: string,
    newName: string,
    tags: LabelDto[],
    folderId: number
  ) => FetchPromise<DuplicateEmailTemplateMutation>
}

export const useEmailTemplatesListingPageRequests = (): EmailTemplatesListingPageRequests => {
  const actonClient = useApolloClient()

  const deleteItemsRequest = useCallback(
    async (items: ItemDto[]) => {
      const templatesIds = items.map(({ externalId }) => externalId ?? '')
      return await actonClient.mutate<DeleteEmailTemplatesMutation, DeleteEmailTemplatesMutationVariables>({
        mutation: deleteEmailTemplates,
        errorPolicy: 'all',
        variables: { templatesIds },
      })
    },
    [actonClient]
  )

  const getItemPreviewRequest = useCallback(
    async (templateId: string) =>
      await actonClient.query<GetEmailTemplatePreviewQuery, GetEmailTemplatePreviewQueryVariables>({
        query: getEmailTemplatePreview,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        variables: { templateId },
      }),
    [actonClient]
  )

  const createLandingPageFromTemplateRequest = useCallback(
    async (variables: CreateLandingPageFromEmailTemplateMutationVariables) =>
      await actonClient.mutate<CreateLandingPageFromEmailTemplateMutation, CreateLandingPageFromEmailTemplateMutationVariables>({
        mutation: createLandingPageFromEmailTemplate,
        errorPolicy: 'all',
        variables,
      }),
    [actonClient]
  )

  const duplicateEmailTemplateRequest = useCallback(
    async (templateId: string, newName: string, tags: LabelDto[], folderId: number) =>
      await actonClient.mutate<DuplicateEmailTemplateMutation, DuplicateEmailTemplateMutationVariables>({
        mutation: duplicateEmailTemplate,
        errorPolicy: 'all',
        variables: {
          templateId,
          newName,
          tags,
          folderId,
        },
      }),
    [actonClient]
  )

  return {
    deleteItemsRequest,
    getItemPreviewRequest,
    createLandingPageFromTemplateRequest,
    duplicateEmailTemplateRequest,
  }
}
