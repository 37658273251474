import React, { FC, ReactNode, useMemo } from 'react'

import { useApolloClient, useQuery } from '@apollo/client'
import getAllMarketingUsers from '@graphql/queries/getAllMarketingUsers'
import { GetAllMarketingUsersQuery, GetAllMarketingUsersQueryVariables, UserResponse } from '@graphql/types/query-types'
import MarketingUsersContext from '@src/pages/Settings/Users/context/MarketingUsersContext'
import { filterNotEmptyArray } from '@utils/array'

interface Props {
  children: ReactNode
  loadingMutation: boolean
}

const MarketingUsersContextProvider: FC<Props> = ({ children, loadingMutation }) => {
  const client = useApolloClient()
  const { data, loading, error, refetch } = useQuery<GetAllMarketingUsersQuery, GetAllMarketingUsersQueryVariables>(getAllMarketingUsers, {
    client,
    fetchPolicy: 'network-only',
    variables: { sortColumn: 'USER_DETAILS', sortDirection: 'ASC' },
    notifyOnNetworkStatusChange: true,
  })

  const users = useMemo<UserResponse[]>(() => (data?.getAllMarketingUsers?.marketingUsers ?? []).filter(filterNotEmptyArray), [data])
  const maxCount = useMemo<number>(() => data?.getAllMarketingUsers?.maxUsers || 0, [data])

  return (
    <MarketingUsersContext.Provider value={{ users, loading: loading || loadingMutation, maxCount, refetch, error }}>
      {children}
    </MarketingUsersContext.Provider>
  )
}

export default MarketingUsersContextProvider
