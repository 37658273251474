import React, { Dispatch, FC, SetStateAction } from 'react'
import { connect } from 'react-redux'

import { bindActionCreators, compose } from 'redux'

import { loadAccount } from '@utils/account/actions'
import mapStateToProps from '@utils/navigation/state/mapStateToProps'

import { useTemplateCatalogModalRequests } from './graphql/TemplateCatalogModal.graphql'
import TemplateCatalogModal from './TemplateCatalogModal'

export interface TemplateCatalogModalContainerProps {
  setShowTemplateCatalog: Dispatch<SetStateAction<boolean>>
  isStory?: boolean
}

const TemplateCatalogModalContainer: FC<TemplateCatalogModalContainerProps> = ({ setShowTemplateCatalog, ...props }) => {
  const {
    getTemplateCatalogCategories,
    getTemplateCatalogItems,
    createMessage,
    getBeeTemplate,
    getRecentlyViewedIds,
    getRecentlyViewedTemplates,
    updateRecentlyViewed,
    updateEmailManagementRecentlyViewed,
  } = useTemplateCatalogModalRequests()
  return (
    <TemplateCatalogModal
      {...props}
      isOpen
      setShowTemplateCatalog={setShowTemplateCatalog}
      onGetCategories={getTemplateCatalogCategories}
      onGetTemplates={getTemplateCatalogItems}
      onUseTemplate={createMessage}
      onGetSingleTemplate={getBeeTemplate}
      onGetRecentlyViewedIds={getRecentlyViewedIds}
      onUpdateRecentlyViewed={updateRecentlyViewed}
      onUpdateEmailManagementRecentlyViewed={updateEmailManagementRecentlyViewed}
      onGetRecentlyViewedTemplates={getRecentlyViewedTemplates}
    />
  )
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({ loadAccount }, dispatch)

export default compose<React.ComponentType<TemplateCatalogModalContainerProps>>(connect(mapStateToProps, mapDispatchToProps))(
  TemplateCatalogModalContainer
)
