import { FrameViewState } from '@utils/iframe/iframe'

import { getIFrameView } from './selectors'

export interface FrameViewStateProps {
  iframeView: FrameViewState
}

function mapStateToProps(state: any): FrameViewStateProps {
  return { iframeView: getIFrameView(state) }
}

export default mapStateToProps
