import React, { FC, useCallback, useRef, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Container from '@components/Container'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import Svg, { SvgNames } from '@components/Svg'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ClickthroughLinkInput, ClickthroughLinkTestResponse } from '@graphql/types/query-types'
import ClickthroughLinkBlock from '@src/pages/Content/LandingPages/LandingPagesManager/components/LandingPageManagerClickthroughLinks/components/ClickthroughLinkBlock'
import { useLandingPageContext } from '@src/pages/Content/LandingPages/LandingPagesManager/context/LandingPagesManager.context'

import './ClickthroughLinks.css'

interface ClickthroughLinksProps {
  testLink: (link: ClickthroughLinkInput) => Promise<ClickthroughLinkTestResponse>
  dataTest?: string
}

export interface TestLinkCBs {
  [key: string]: () => Promise<void>
}

const LEARN_MORE = 'https://connect.act-on.com/hc/en-us/articles/360023941653-Using-Landing-Page-Reports#Clickthrough_Links'

const rootClass = 'clickthrough-links'

const ClickthroughLinks: FC<ClickthroughLinksProps> = ({ testLink, dataTest = rootClass }) => {
  const { t } = useTranslation()
  const { clickthroughLinks } = useLandingPageContext()
  const [loadingTestAll, setLoadingTestAll] = useState(false)
  const testLinkCBs = useRef<TestLinkCBs>({})

  const handleTestAll = useCallback(() => {
    setLoadingTestAll(true)
    Promise.all(Object.values(testLinkCBs.current).map((cb) => cb())).finally(() => setLoadingTestAll(false))
  }, [testLinkCBs])

  return (
    <PositionContainer innerDivClassname={rootClass} dataTest={dataTest}>
      <div className={`${rootClass}__header`} data-test={`${dataTest}_header`}>
        <div className="push-left-x4">
          <Typography text={t('Clickthrough Links')} type={TextType.PAGE_HEADER} />
          <Typography
            text="ClickthroughLinks.SubHeader"
            type={TextType.BODY_TEXT_LIGHT}
            tagComponents={{
              TextLink: <TextLink link={LEARN_MORE} size={TextLinkSize.LARGE} />,
            }}
            className="push-down-x3"
            dataTest={`${dataTest}-subHeader`}
          />
        </div>
        <Button
          buttonType={ButtonType.INFO}
          onClick={handleTestAll}
          disabled={loadingTestAll}
          className={`${rootClass}__header-test-button`}
          dataTest={`${dataTest}-test-all-button`}
        >
          <Svg name={SvgNames.test} />
          {t('Test all links')}
        </Button>
      </div>
      <Container className={classNames(`${rootClass}__list`, 'push-down-x4')} dataTest={`${dataTest}-list`}>
        <div className={classNames(`${rootClass}__list-header`, 'flex-align-center')}>
          <Typography text={t('Link text').toUpperCase()} type={TextType.TABLE_HEADER} lineHeight={LineHeight.SMALL} />
          <Typography text={t('Destination URL').toUpperCase()} type={TextType.TABLE_HEADER} lineHeight={LineHeight.SMALL} />
        </div>
        {clickthroughLinks.map((link) => (
          <ClickthroughLinkBlock key={link.code} onTestLink={testLink} testLinksCbRef={testLinkCBs.current} {...link} />
        ))}
      </Container>
    </PositionContainer>
  )
}

export default ClickthroughLinks
