import { gql } from '@apollo/client'

export default gql`
  query entityType($offset: Int, $entityType: String, $count: Int, $sort: Boolean) {
    entityType(offset: $offset, entityType: $entityType, count: $count, sort: $sort) {
      entityTypeDataMapping
      entityTypeFields {
        label
        name
      }
    }
  }
`
