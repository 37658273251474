import React, { FC, useCallback, useEffect, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { SignatureItem } from '@graphql/types/query-types'
import { SortDirection } from '@utils/common'
import { FilterTypes } from '@utils/filter'

import { useSignaturesRequests } from './GraphQL/useSignaturesRequests'
import SignaturesModal from './SignaturesModal'
import {
  SignaturesModalContainerInitialState,
  SignaturesModalContainerState,
  SignaturesModalContext,
  SignaturesModalProps,
} from './SignaturesModal.context'

const SignaturesModalContainer: FC<SignaturesModalProps> = (props: SignaturesModalProps) => {
  const client = useApolloClient()
  const { getAllSignatures } = useSignaturesRequests(client)

  const [state, setState] = useState<SignaturesModalContainerState>(SignaturesModalContainerInitialState)
  const { data: signatures, activeFilter, selectedSort } = state

  const signatureType = activeFilter === FilterTypes.MY_SIGNATURES ? 'user' : activeFilter === FilterTypes.ACCOUNT_SIGNATURES ? 'account' : undefined
  const sortDirection = selectedSort.desc ? SortDirection.DESC : SortDirection.ASC

  const update = useCallback((fields: Partial<SignaturesModalContainerState>) => {
    setState((state: SignaturesModalContainerState) => ({ ...state, ...fields }))
  }, [])

  const getSignatures = useCallback(async () => {
    update({ loading: true })
    const { data, error } = await getAllSignatures({
      signatureType,
      sortDirection,
      pageNumber: 0,
    })
    if (data.getAllSignature?.signatures?.length && !error) {
      const loadedSignatures = (data.getAllSignature.signatures?.filter((value) => !!value) ?? []) as SignatureItem[]
      update({
        data: loadedSignatures,
        filterCounts: {
          [FilterTypes.ALL_SIGNATURES]: data.getAllSignature.totalCount,
          [FilterTypes.MY_SIGNATURES]: data.getAllSignature.usersCount,
          [FilterTypes.ACCOUNT_SIGNATURES]: data.getAllSignature.accountsCount,
        },
        loading: false,
      })
    } else {
      update({ loading: false })
    }
  }, [signatureType, sortDirection])

  const loadNextPage = async (page: number) => {
    update({ nextPageLoading: true })
    const { data, error } = await getAllSignatures({
      signatureType,
      sortDirection,
      pageNumber: page,
    })
    if (data.getAllSignature?.signatures?.length && !error) {
      const loadedSignatures = (data.getAllSignature.signatures?.filter((value) => !!value) ?? []) as SignatureItem[]
      update({
        data: [...signatures, ...loadedSignatures],
        nextPageLoading: false,
      })
    }
  }

  useEffect(() => {
    getSignatures()
  }, [getSignatures])

  return (
    <SignaturesModalContext.Provider value={{ values: state, update, loadNextPage }}>
      <SignaturesModal {...props} />
    </SignaturesModalContext.Provider>
  )
}

export default SignaturesModalContainer
