import React, { ComponentProps, FC, useMemo } from 'react'

import classNames from 'classnames'

import HoverSVG from '@components/HoverSVG/HoverSVG'
import { SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Tooltip from '@components/Tooltip/Tooltip'

import './InfoTooltip.css'

export enum InfoTooltipIconSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export type InfoTooltipProps = Omit<ComponentProps<typeof Tooltip>, 'trigger'> & {
  tooltipClassName?: string
  iconSize?: InfoTooltipIconSize
  hasDarkBackground?: boolean
  ignoreFillOnHover?: boolean
}

const rootClass = 'info-tooltip'

const InfoTooltip: FC<InfoTooltipProps> = (props: InfoTooltipProps) => {
  const {
    className,
    dataTest,
    iconSize = InfoTooltipIconSize.MEDIUM,
    tooltipClassName,
    hasDarkBackground,
    ignoreFillOnHover = false,
    ...propsRest
  } = props

  const { containerClassName, iconType } = useMemo<{ containerClassName: string; iconType: SvgType }>(() => {
    if (iconSize === InfoTooltipIconSize.MEDIUM) {
      return { containerClassName: `${rootClass}__medium`, iconType: SvgType.LARGER_ICON }
    } else if (iconSize === InfoTooltipIconSize.LARGE) {
      return { containerClassName: `${rootClass}__large`, iconType: SvgType.LARGE_ICON }
    } else {
      return { containerClassName: `${rootClass}__small`, iconType: SvgType.ICON }
    }
  }, [iconSize])

  return (
    <div className={classNames(containerClassName, className, `${rootClass}__container`)}>
      <Tooltip
        {...propsRest}
        className={tooltipClassName}
        dataTest={dataTest}
        trigger={
          <HoverSVG
            dataTest={`${dataTest}-svg`}
            className={`${rootClass}__svg`}
            svg={SvgNames.info}
            hoverSvg={hasDarkBackground ? SvgNames.infoFilledWhite : SvgNames.infoHover}
            type={iconType}
            fill={hasDarkBackground ? SvgColor.WHITE : undefined}
            ignoreFillOnHover={ignoreFillOnHover}
            hoverFill={hasDarkBackground ? undefined : SvgColor.TEXT_TEAL}
          />
        }
      />
    </div>
  )
}

export default InfoTooltip
