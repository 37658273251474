import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { ButtonWeight } from '@components/Button/Button'
import ButtonWithLoader from '@components/ButtonWithLoader/ButtonWithLoader'
import { LinkTextButton } from '@components/LinkTextButton/LinkTextButton'
import Modal, { ModalBody } from '@components/Modal'
import StaticImage from '@components/StaticImage/StaticImage'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Typography, { LineHeight, TextAlign, TextType, TextWeight } from '@components/Typography/Typography'

import './IllustrationModal.css'

interface IllustrationModalButtonProps {
  title: string
  onClick: () => void
  disabled?: boolean
}

export enum IllustrationModalImageSize {
  REGULAR = 'regular',
  SMALL = 'small',
}

export interface IllustrationModalProps {
  className?: string
  dataTest?: string
  illustration: StaticImageNames
  imageSize?: IllustrationModalImageSize
  preHeader?: string
  header: string | ReactNode
  centerHeader?: boolean
  bodyText: string | ReactNode
  disclaimerText?: string
  confirmButton?: IllustrationModalButtonProps
  loading?: boolean
  confirmWithLoader?: boolean
  closeButton?: IllustrationModalButtonProps
  closeButtonClass?: string
  linkUrl?: string
  linkText?: string
}

const rootClass = 'illustration-modal'

export const IllustrationModal: FC<IllustrationModalProps> = ({
  dataTest = rootClass,
  className,
  illustration,
  imageSize = IllustrationModalImageSize.REGULAR,
  closeButton,
  closeButtonClass,
  confirmButton,
  confirmWithLoader,
  loading,
  preHeader,
  header,
  centerHeader,
  bodyText,
  disclaimerText,
  linkUrl,
  linkText = 'More info',
}) => {
  const actionButtonClassnames = classNames(`${rootClass}__button push-down-x2`, {
    [`push-up-x2`]: !closeButton,
  })

  const actionButton = confirmWithLoader ? (
    <ButtonWithLoader
      onClick={confirmButton?.onClick}
      loading={loading}
      weight={ButtonWeight.MEDIUM}
      className={actionButtonClassnames}
      dataTest={`${dataTest}-confirm`}
    >
      {confirmButton?.title}
    </ButtonWithLoader>
  ) : (
    <Button
      onClick={confirmButton?.onClick}
      weight={ButtonWeight.MEDIUM}
      buttonType={ButtonType.PRIMARY}
      className={actionButtonClassnames}
      dataTest={`${dataTest}-confirm`}
    >
      {confirmButton?.title}
    </Button>
  )

  return (
    <Modal isOpen className={classNames(rootClass, className)} dataTest={dataTest}>
      <ModalBody className={`${rootClass}__body`}>
        <div className={`${rootClass}__img-wrapper`}>
          <StaticImage
            name={illustration}
            className={classNames(`${rootClass}__img`, `${rootClass}__img-${imageSize}`)}
            dataTest={`${dataTest}-img-${illustration}`}
          />
        </div>
        {preHeader && (
          <Typography
            text={preHeader.toUpperCase()}
            weight={TextWeight.BOLD}
            type={TextType.BODY_TEXT_SMALL}
            lineHeight={LineHeight.MEDIUM_SMALL}
            dataTest={`${dataTest}-pre-header`}
          />
        )}
        {typeof header === 'string' ? (
          <Typography
            text={header}
            weight={TextWeight.MEDIUM}
            lineHeight={LineHeight.LARGER}
            textAlign={centerHeader ? TextAlign.CENTER : TextAlign.LEFT}
            type={TextType.MODAL_HEADLINE_LARGE}
            dataTest={`${dataTest}-header`}
          />
        ) : (
          header
        )}
        {typeof bodyText === 'string' ? (
          <Typography text={bodyText} className={`${rootClass}__body-text`} textAlign={TextAlign.CENTER} dataTest={`${dataTest}-body-text`} />
        ) : (
          bodyText
        )}
        {linkUrl && <LinkTextButton link={linkUrl} text={linkText} hideIconLeft dataTest={`${dataTest}-link`} />}
        {confirmButton && actionButton}
        {closeButton && (
          <Button
            onClick={closeButton.onClick}
            weight={ButtonWeight.MEDIUM}
            buttonType={ButtonType.TRANSPARENT}
            disabled={closeButton.disabled}
            className={classNames(`${rootClass}__button push-up-x2`, closeButtonClass)}
            dataTest={`${dataTest}-close`}
          >
            {closeButton.title}
          </Button>
        )}
        {disclaimerText && (
          <Typography
            text={disclaimerText}
            textAlign={TextAlign.CENTER}
            type={TextType.BODY_TEXT_SMALL}
            lineHeight={LineHeight.MEDIUM_SMALL}
            className={`${rootClass}__disclaimer-text`}
            dataTest={`${dataTest}-disclaimer-text`}
          />
        )}
      </ModalBody>
    </Modal>
  )
}
