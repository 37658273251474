import React, { useContext, useMemo, useState } from 'react'

import SingleLineChart from '@components/SingleLineChart/SingleLineChart'
import Tab from '@components/TabsAO/TabAO'
import Tabs from '@components/TabsAO/TabsAO'
import Toggletip from '@components/Toggletip/Toggletip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import {
  bytesToSize,
  ERROR_PERCENTAGE,
  MAX_BYTE_SIZE,
  WARNING_PERCENTAGE,
} from '@src/pages/EmailComposer/components/EmailComposerPreview/EmailComposerPreview.utils'
import { useEmailValidations } from '@src/pages/EmailComposer/hooks/useEmailValidations'
import { useGetEmailValidations } from '@src/pages/EmailComposer/hooks/useGetEmailValidations'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { useTranslation } from '@utils/const/globals'

import EmailComposerPreviewDetails from '../EmailComposerPreviewDetails/EmailComposerPreviewDetails'
import EmailComposerPreviewValidations from '../EmailComposerPreviewValidations/EmailComposerPreviewValidations'

import './EmailComposerPreviewSidebar.css'

const rootClass = 'email-composer-preview-sidebar'

const EmailComposerPreviewSidebar = () => {
  const {
    values: {
      validations: {
        isValidationsLoading,
        isContentMissing,
        contentValidations: { htmlSize },
      },
    },
  } = useContext(EmailComposerContext)

  const [activeTab, setActiveTab] = useState<string>('details')

  const { t } = useTranslation()

  const { allFailsCount } = useEmailValidations()
  useGetEmailValidations()

  const percentageOfHtmlSize = useMemo(() => (htmlSize / MAX_BYTE_SIZE) * 100, [htmlSize])

  const tabs = useMemo(
    () => [
      {
        index: 'details',
        label: t('EmailComposer.Preview.Sidebar.Tab.Details'),
        component: <EmailComposerPreviewDetails />,
      },
      {
        index: 'check_for_errors',
        label: (
          <div className={`${rootClass}__tabs-tab`}>
            {t('EmailComposer.Preview.Sidebar.Tab.Errors')}
            {!isContentMissing && !!allFailsCount && !isValidationsLoading && (
              <div className={`${rootClass}__tabs-tab-pill`}>
                <Typography text={allFailsCount} weight={TextWeight.MEDIUM} lineHeight={LineHeight.MEDIUM_SMALL} />
              </div>
            )}
          </div>
        ),
        component: <EmailComposerPreviewValidations />,
      },
    ],
    [t, isContentMissing, allFailsCount, isValidationsLoading]
  )

  const items = useMemo(
    () =>
      tabs.map((tab) => {
        return (
          <Tab index={tab.index} key={tab.index}>
            {tab.component}
          </Tab>
        )
      }),
    [tabs]
  )

  const tabStructure = useMemo(
    () =>
      tabs.map((tab) => {
        return { ...tab, index: tab.index, build: '' }
      }),
    [tabs]
  )

  return (
    <div className={rootClass} data-test={rootClass}>
      <Tabs className={`${rootClass}__tabs`} defaultValue={activeTab} onChange={setActiveTab} childData={tabStructure}>
        {items}
      </Tabs>
      <SingleLineChart
        className={`${rootClass}__email-size`}
        title={
          <div className={`${rootClass}__email-size-title`}>
            <Typography text={t('EmailComposer.Preview.Email.Size.Title')} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
            <Toggletip
              isDismissable
              ellipTitle={false}
              title={t('EmailComposer.Preview.Email.Size.Toogltip.Title')}
              description={
                <Typography
                  text={t('EmailComposer.Preview.Email.Size.Toogltip.Description')}
                  tagProps={{ bold: { weight: TextWeight.BOLD } }}
                  inline
                />
              }
            />
          </div>
        }
        text={
          <Typography
            text={`Approx. ${bytesToSize(htmlSize).replace(/ +/g, '')} out of 102KB`}
            type={TextType.BODY_TEXT_SMALL}
            lineHeight={LineHeight.MEDIUM_SMALL}
          />
        }
        actualValue={htmlSize}
        total={MAX_BYTE_SIZE}
        fractionDigits={3}
        showWarning
        showError
        showHelperTextIcon
        helperText={t('EmailComposer.Preview.Email.Size.Helper.Text', {
          context: percentageOfHtmlSize >= ERROR_PERCENTAGE ? 'error' : percentageOfHtmlSize >= WARNING_PERCENTAGE ? 'warning' : 'default',
        })}
      />
    </div>
  )
}

export default EmailComposerPreviewSidebar
