import React, { FC } from 'react'

import classNames from 'classnames'

import Loader from '@components/Loader/Loader'
import PageContainer from '@components/PageContainer'
import PageError from '@components/PageError'
import TextBubble, { BackgroundColor } from '@components/TextBubble/TextBubble'
import Typography, { TextWeight, TextType, LineHeight } from '@components/Typography/Typography'
import { SmsMessageContent, SmsReplyContent } from '@graphql/types/microservice/sms-management-types'
import { formatSendTSDate } from '@utils/date'

import './SMSPreview.css'

type Props = {
  loading: boolean
  error?: Error
  className?: string
  dataTest?: string
  smsMessage?: SmsMessageContent
  smsReplies?: SmsReplyContent[]
}

const rootClass = 'sms-preview'

const SMSPreview: FC<Props> = (props: Props) => {
  const { loading, error, smsMessage, smsReplies, className = '' } = props
  const sentTimestamp = smsMessage?.timestamp ? formatSendTSDate(smsMessage?.timestamp as number, { includeTime: true }) : ''

  if (error) {
    return (
      <PageContainer noChrome>
        <PageError center />
      </PageContainer>
    )
  }

  return (
    <PageContainer noChrome className={classNames(rootClass, className)}>
      {loading ? (
        <Loader center />
      ) : (
        <>
          <div className={`${rootClass}__sms-row ${rootClass}__sms-row__reverse`}>
            <div className={`${rootClass}__sms-block`}>
              <div className={`${rootClass}__timestamp ${rootClass}__timestamp__right`}>
                <Typography text={sentTimestamp} type={TextType.BODY_TEXT_LIGHT_TINY} weight={TextWeight.BOLD} lineHeight={LineHeight.MEDIUM} />
              </div>
              <TextBubble
                text={smsMessage?.smsMessageText}
                className={`${rootClass}__text-bubble`}
                backgroundColor={BackgroundColor.TEAL}
                noMargin
                radiusRight
                roundedRadius
              />
            </div>
          </div>
          {smsReplies?.map((reply, index) => (
            <div key={index} className={`${rootClass}__sms-row`}>
              <div className={`${rootClass}__sms-block`}>
                <div className={`${rootClass}__timestamp`}>
                  <Typography
                    text={formatSendTSDate(reply.timestamp, { includeTime: true })}
                    type={TextType.BODY_TEXT_LIGHT_TINY}
                    weight={TextWeight.BOLD}
                    lineHeight={LineHeight.MEDIUM}
                  />
                </div>
                <TextBubble text={reply.smsReplyText} className={`${rootClass}__text-bubble`} noMargin roundedRadius />
              </div>
            </div>
          ))}
        </>
      )}
    </PageContainer>
  )
}

export default SMSPreview
