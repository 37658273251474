import React, { FC, LegacyRef, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import ReactAce from 'react-ace'

import classNames from 'classnames'

import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import ButtonCopy from '@components/ButtonCopy/ButtonCopy'
import { Ace } from '@components/CodeEditor/Ace'
import { disableClicks } from '@components/PreviewAssetModal/PreviewAssetModal.utils'
import StaticImage from '@components/StaticImage/StaticImage'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { Status } from '@components/StatusToast/StatusToast'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { CommonComposerTab } from '@utils/composer/commonComposer/CommonComposer.context'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { useTranslation } from '@utils/const/globals'
import { getItem, setItem } from '@utils/sessionStorage'

import { downloadHtml } from '../../../EmailComposerPreview/EmailComposerPreview.utils'
import { MIN_EDITOR_WIDTH_PX, MIN_FOOTER_WIDTH_PX, MIN_IFRAME_WIDTH_PX } from '../../utils/UploadHtmlComposer.utils'
import UploadHtmlComposerFooter from '../UploadHtmlComposerFooter/UploadHtmlComposerFooter'

import './CodeEditorWithPreview.css'

export interface CodeEditorWithPreviewProps {
  content: string
  onCodeChange: (code: string) => void
  className?: string
  dataTest?: string
  undo: () => void
  redo: () => void
  insert: (textToInsert: string) => void
  isUndoEnabled?: boolean
  isRedoEnabled?: boolean
  aceRef: LegacyRef<ReactAce>
}

const PREVIEW_IFRAME_WIDTH = 'previewIframeWidth'
const EDITOR_WIDTH = 'editorWidth'

const rootClass = 'code-editor-with-preview'

export const CodeEditorWithPreview: FC<CodeEditorWithPreviewProps> = ({
  content,
  onCodeChange,
  undo,
  redo,
  insert,
  isUndoEnabled,
  isRedoEnabled,
  aceRef,
}: CodeEditorWithPreviewProps) => {
  const { t } = useTranslation()
  const [html, setHtml] = useState<string>(content)

  const {
    api: { update },
  } = useContext(EmailComposerContext)

  const inputRef = useRef<HTMLTextAreaElement>(null)

  const copyCode = useCallback((): void => {
    navigator.clipboard.writeText(html)
  }, [html])

  const handleCodeChange = useCallback(
    (newCode: string) => {
      setHtml(newCode)
      onCodeChange && onCodeChange(newCode)
    },
    [onCodeChange]
  )

  const iframeRef = useRef<HTMLIFrameElement>(null)
  const iframeContainerRef = useRef<HTMLDivElement>(null)
  const editorRef = useRef<HTMLDivElement>(null)

  const [isResizing, setIsResizing] = useState(false)

  const [iframeWidth, setIframeWidth] = useState<number | string>(parseFloat(getItem(PREVIEW_IFRAME_WIDTH) ?? '') || '50%')
  const [editorWidth, setEditorWidth] = useState<number | string>(parseFloat(getItem(EDITOR_WIDTH) ?? '') || '50%')
  const [isEditorMinWidth, setIsEditorMinWidth] = useState<boolean>(parseFloat(editorWidth.toString()) === 396)
  const [isFooterMinWidth, setIsFooterMinWidth] = useState<boolean>(parseFloat(getItem(EDITOR_WIDTH) ?? '') <= 460)

  const startResizing = (e: React.MouseEvent) => {
    setIsResizing(true)
    e.preventDefault()
  }

  const stopResizing = () => {
    setIsResizing(false)
  }

  const handleResizing = useCallback(
    (e: MouseEvent) => {
      if (isResizing && iframeContainerRef.current) {
        const containerClientWidth = iframeContainerRef.current.parentElement!.clientWidth

        const newIframeWidthPx = e.clientX - iframeContainerRef.current.parentElement!.offsetLeft
        const minIframeWidthPx = MIN_IFRAME_WIDTH_PX
        const maxIframeWidthPx = containerClientWidth - minIframeWidthPx

        if (newIframeWidthPx >= minIframeWidthPx && newIframeWidthPx <= maxIframeWidthPx) {
          const newIframeWidthPct = (newIframeWidthPx / containerClientWidth) * 100
          const newEditorWidthPct = 100 - newIframeWidthPct

          setIframeWidth(`${newIframeWidthPct}%`)
          setEditorWidth(`${newEditorWidthPct}%`)

          const editorWidthPx = (newEditorWidthPct / 100) * containerClientWidth
          setIsEditorMinWidth(editorWidthPx <= MIN_EDITOR_WIDTH_PX)
          setIsFooterMinWidth(editorWidthPx <= MIN_FOOTER_WIDTH_PX)

          setItem(PREVIEW_IFRAME_WIDTH, `${newIframeWidthPct}%`)
          setItem(EDITOR_WIDTH, `${newEditorWidthPct}%`)
        }
      }
    },
    [isResizing]
  )

  useLayoutEffect(() => {
    const initialIframeWidth = getItem(PREVIEW_IFRAME_WIDTH) ?? '50%'
    const initialEditorWidth = getItem(EDITOR_WIDTH) ?? '50%'

    setIframeWidth(initialIframeWidth)
    setEditorWidth(initialEditorWidth)

    const recalculateLayout = () => {
      if (iframeContainerRef.current) {
        const containerClientWidth = iframeContainerRef.current.parentElement!.clientWidth

        const newIframeWidthPct = parseFloat(getItem(PREVIEW_IFRAME_WIDTH) ?? '50')
        const newEditorWidthPct = 100 - newIframeWidthPct

        const adjustedIframeWidthPct =
          newIframeWidthPct < (MIN_IFRAME_WIDTH_PX / containerClientWidth) * 100
            ? (MIN_IFRAME_WIDTH_PX / containerClientWidth) * 100
            : newIframeWidthPct
        const adjustedEditorWidthPct =
          newEditorWidthPct < (MIN_EDITOR_WIDTH_PX / containerClientWidth) * 100
            ? (MIN_EDITOR_WIDTH_PX / containerClientWidth) * 100
            : 100 - adjustedIframeWidthPct

        setIframeWidth(`${adjustedIframeWidthPct}%`)
        setEditorWidth(`${adjustedEditorWidthPct}%`)

        setIsEditorMinWidth(adjustedEditorWidthPct < (MIN_EDITOR_WIDTH_PX / containerClientWidth) * 100)
        setIsFooterMinWidth(adjustedEditorWidthPct < (MIN_FOOTER_WIDTH_PX / containerClientWidth) * 100)
      }
    }

    const observer = new ResizeObserver(() => {
      recalculateLayout()
    })

    if (iframeContainerRef.current) {
      observer.observe(iframeContainerRef.current.parentElement!)
    }

    return () => {
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    setHtml(content)
    if (aceRef && typeof aceRef !== 'string' && 'current' in aceRef && aceRef.current) {
      aceRef.current.editor.focus()
    }
  }, [content, aceRef])

  useEffect(() => {
    const handleMouseUp = () => {
      if (isResizing) {
        stopResizing()
      }
    }

    const handleMouseMove = (e: MouseEvent) => {
      if (isResizing) {
        handleResizing(e)
      }
    }

    const attachEvents = (doc: Document) => {
      doc.addEventListener('mousemove', handleMouseMove)
      doc.addEventListener('mouseup', handleMouseUp)
    }

    const detachEvents = (doc: Document) => {
      doc.removeEventListener('mousemove', handleMouseMove)
      doc.removeEventListener('mouseup', handleMouseUp)
    }

    if (isResizing) {
      attachEvents(document)

      if (iframeRef.current?.contentDocument) {
        attachEvents(iframeRef.current.contentDocument)
      }

      return () => {
        detachEvents(document)

        if (iframeRef.current?.contentDocument) {
          detachEvents(iframeRef.current.contentDocument)
        }
      }
    }
  }, [handleResizing, isResizing, iframeRef])

  const isContentMissing = !html?.trim().length

  const onDownloadSuccess = useCallback(() => {
    update({
      tab: CommonComposerTab.DESIGN,
      modalState: {
        statusToast: {
          status: Status.SUCCESS,
          message: <Typography text="EmailComposer.UploadHtml.Download.Success" tagProps={{ bold: { weight: TextWeight.BOLD } }} inline />,
        },
      },
    })
  }, [update])

  const renderDownloadButton = useCallback(() => {
    if (isEditorMinWidth) {
      return (
        <Tooltip
          trigger={
            <Button
              iconPosition={ButtonIconPosition.LEFT}
              buttonType={ButtonType.FLOAT_TEAL}
              onClick={() => {
                downloadHtml(html)
                onDownloadSuccess()
              }}
              className={`${rootClass}__editor-header-actions-download`}
              disabled={isContentMissing}
            >
              <Svg name={SvgNames.download} type={SvgType.LARGER_ICON} fill={SvgColor.TEXT_TEAL} />
            </Button>
          }
        >
          {t('Download')}
        </Tooltip>
      )
    } else {
      return (
        <Button
          iconPosition={ButtonIconPosition.LEFT}
          buttonType={ButtonType.FLOAT_TEAL}
          onClick={() => {
            downloadHtml(html)
            onDownloadSuccess()
          }}
          className={`${rootClass}__editor-header-actions-download`}
          disabled={isContentMissing}
        >
          <Svg name={SvgNames.download} type={SvgType.LARGER_ICON} fill={SvgColor.TEXT_TEAL} />
          <Typography text={t('Download')} type={TextType.LINK} weight={TextWeight.MEDIUM} lineHeight={LineHeight.MEDIUM_LARGE} />
        </Button>
      )
    }
  }, [html, isContentMissing, isEditorMinWidth, onDownloadSuccess, t])

  return (
    <div className={rootClass}>
      <div ref={iframeContainerRef} className={`${rootClass}__preview-container`} style={{ width: iframeWidth, flexBasis: iframeWidth }}>
        {!isContentMissing ? (
          <iframe className={`${rootClass}__preview`} title="Act-On Test Message" srcDoc={disableClicks(html)} ref={iframeRef} />
        ) : (
          <div className={`${rootClass}__preview-empty`}>
            <StaticImage name={StaticImageNames.envelopeTeal} />
            <Typography
              text={t('EmailComposer.UploadHtml.EmptyState.Title')}
              type={TextType.PAGE_HEADLINE}
              weight={TextWeight.MEDIUM}
              className="push-down-x2"
            />
            <Typography text={t('EmailComposer.UploadHtml.EmptyState.Description')} type={TextType.BODY_TEXT_LIGHT} className="push-down-x2" />
          </div>
        )}

        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div className={`${rootClass}__resize`} onMouseDown={startResizing} onMouseUp={stopResizing} />
      </div>

      <div className={`${rootClass}__editor`} ref={editorRef} style={{ flexBasis: editorWidth }}>
        <div className={classNames(`${rootClass}__editor-header`, { [`${rootClass}__editor-header-icon-only`]: isEditorMinWidth })}>
          <Typography inline text={t('HTML source code')} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
          <div className={classNames(`${rootClass}__editor-header-actions`, { [`${rootClass}__editor-header-actions-icon-only`]: isEditorMinWidth })}>
            {renderDownloadButton()}
            <ButtonCopy
              onClick={copyCode}
              iconOnly={isEditorMinWidth}
              disabled={isContentMissing}
              className={`${rootClass}__editor-header-actions-copy`}
            />
          </div>
        </div>
        <Ace
          aceRef={aceRef}
          content={html}
          mode="html"
          theme="github"
          onCodeChange={handleCodeChange}
          options={{
            highlightGutterLine: true,
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            showFoldWidgets: true,
            tabSize: 2,
            showPrintMargin: false,
            highlightSelectedWord: false,
            scrollPastEnd: false,
            wrap: true,
            indentedSoftWrap: true,
            cursorStyle: 'ace',
            newLineMode: true,
            mergeUndoDeltas: false,
            useWorker: true,
          }}
          editorProps={{
            $blockScrolling: false,
          }}
          className={`${rootClass}__ace-editor`}
          height="calc(100% - 112px)"
          width="100%"
          fontSize={14}
          scrollMargin={[12]}
        />
        <UploadHtmlComposerFooter
          isMinWidth={isFooterMinWidth}
          className={`${rootClass}__footer`}
          inputRef={inputRef}
          isUndoEnabled={isUndoEnabled ?? false}
          isRedoEnabled={isRedoEnabled ?? false}
          undo={undo}
          redo={redo}
          insert={insert}
        />
      </div>
    </div>
  )
}
