import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import HoverSVG from '@components/HoverSVG/HoverSVG'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

interface FooterProps {
  handleSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void
  handleCancel: () => void
  selectionChange: boolean
  rootClass: string
  dataTest: string
  hideCancel?: boolean
}

const TimePickerV2Footer: FC<FooterProps> = ({ rootClass, hideCancel, handleSubmit, handleCancel, selectionChange, dataTest }) => {
  const { t } = useTranslation()

  return (
    <div className={`${rootClass}__footer`} data-test={`${dataTest}-footer`}>
      {!hideCancel && (
        <Button buttonType={ButtonType.TERTIARY} onClick={handleCancel} dataTest={`${dataTest}-cancel-button`}>
          {t('Cancel')}
        </Button>
      )}
      <Button
        buttonType={ButtonType.PRIMARY}
        fullWidth={hideCancel}
        disabled={!selectionChange}
        onClick={handleSubmit}
        dataTest={`${dataTest}-apply-button`}
      >
        {t('Apply')}
      </Button>
    </div>
  )
}

interface ErrorMessage {
  errorMessage?: string | React.ReactNode
  dataTest: string
  hideErrorIcon?: boolean
  rootClass?: string
}

const TimePickerV2ErrorMessage: FC<ErrorMessage> = ({ errorMessage, dataTest, hideErrorIcon, rootClass }) => {
  const { t } = useTranslation()

  if (!errorMessage) {
    return null
  }

  return (
    <div className={classNames('push-down', 'flex-align-center', `${rootClass}__error-wrapper`)}>
      {!hideErrorIcon && (
        <Svg
          name={SvgNames.inputStatusInvalidNoFill}
          type={SvgType.ICON}
          fill={SvgColor.ERROR_TEXT}
          dataTest={`${dataTest}-error-icon`}
          className="push-left"
        />
      )}
      <Typography
        text={typeof errorMessage === 'string' ? t(errorMessage) : errorMessage}
        type={TextType.ERROR_NEW}
        lineHeight={LineHeight.MEDIUM_SMALL}
        dataTest={`${dataTest}-error`}
      />
    </div>
  )
}

interface HelperMessage {
  helperMessage?: string | React.ReactNode
  dataTest: string
}

const TimePickerV2HelperMessage: FC<HelperMessage> = ({ helperMessage, dataTest }) => {
  const { t } = useTranslation()

  if (!helperMessage) {
    return null
  }

  return (
    <Typography
      text={typeof helperMessage === 'string' ? t(helperMessage) : helperMessage}
      type={TextType.NORMAL_TEXT_GRAY}
      className="push-down"
      dataTest={`${dataTest}-help`}
    />
  )
}

interface Label {
  dataTest: string
  rootClass: string
  disabled?: boolean
  smallLabel?: boolean
  labelKey?: string
  required?: boolean
  tooltipContent?: string | ReactNode | ReactNode[]
}

const TimePickerV2Label: FC<Label> = ({ labelKey, required, tooltipContent, disabled = false, smallLabel = false, dataTest, rootClass }) => {
  const { t } = useTranslation()

  if (!labelKey) {
    return null
  }

  return (
    <div className={classNames(`${rootClass}__label`, 'push-up', 'flex-align-center')}>
      <Typography
        text={t(labelKey)}
        weight={TextWeight.MEDIUM}
        inline
        type={disabled ? TextType.BODY_TEXT_SMALL_LIGHT : smallLabel ? TextType.BODY_TEXT_SMALL : undefined}
        dataTest={`${dataTest}-label`}
      />
      {required && (
        <Typography
          text={`(${t('required')})`}
          type={TextType.BODY_TEXT_SMALL_LIGHT}
          weight={TextWeight.MEDIUM_LIGHT}
          inline
          dataTest={`${dataTest}-label-required`}
        />
      )}
      {tooltipContent && (
        <Tooltip
          triggerClassName={`${rootClass}__label-icon-wrapper`}
          trigger={<HoverSVG svg={SvgNames.info} hoverSvg={SvgNames.infoHover} fill={SvgColor.LIGHT_GRAY} hoverFill={SvgColor.TEXT_TEAL} />}
        >
          {typeof tooltipContent === 'string' ? t(tooltipContent) : tooltipContent}
        </Tooltip>
      )}
    </div>
  )
}

interface SuffixIcon {
  open: boolean
  disabled?: boolean
  haveError: boolean
  errorMessage?: string
  dataTest: string
  rootClass: string
}

const TimePickerV2SuffixIcon: FC<SuffixIcon> = ({ open, disabled, haveError, dataTest, rootClass, errorMessage }) => {
  const { t } = useTranslation()

  return (
    <Tooltip
      align="start"
      alignOffset={-9}
      sideOffset={-2}
      trigger={
        haveError ? (
          <div className={`${rootClass}__error-icon-container`}>
            <Svg className={`${rootClass}__error-icon`} name={SvgNames.errorSolid} type={SvgType.LARGER_ICON} fill={SvgColor.REMOVE_RED} />
          </div>
        ) : (
          <Svg
            className={`${rootClass}__clock-icon`}
            name={SvgNames.clock}
            type={SvgType.VERY_LARGE_ICON}
            fill={open ? SvgColor.TEXT_TEAL : haveError ? SvgColor.REMOVE_RED : SvgColor.TAB_GRAY}
            hoverFill={haveError || open ? undefined : SvgColor.TEXT_GRAY}
            dataTest={`${dataTest}-clock-icon`}
          />
        )
      }
      disableTooltip={disabled}
      triggerClassName={`${rootClass}__clock-icon-trigger`}
      dataTest={`${dataTest}-clock-icon-trigger`}
    >
      {t(haveError ? errorMessage : 'Show times')}
    </Tooltip>
  )
}

export { TimePickerV2Footer, TimePickerV2ErrorMessage, TimePickerV2Label, TimePickerV2SuffixIcon, TimePickerV2HelperMessage }
