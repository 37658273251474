import React, { FC, useEffect } from 'react'
import { FieldValues, UseFormRegister } from 'react-hook-form'

import { useApolloClient, useQuery } from '@apollo/client'
import Loader from '@components/Loader/Loader'
import PageError from '@components/PageError/PageError'
import globals from '@const/globals'
import crmIntersectionSchema from '@graphql/queries/crmIntersectionSchema'
import {
  CrmIntersectionSchema,
  CrmIntersectionSchemaQuery,
  CrmIntersectionSchemaQueryVariables,
  ProgramAdditionalEntity,
} from '@graphql/types/query-types'
import { logNewRelicError } from '@utils/new-relic.utils'

import StepComponentMapper from './StepComponentMapper'
import { KeyValueWithId } from '../../EditCreateInCRMStep'

interface Props {
  additionalEntity: ProgramAdditionalEntity
  updateAdditionalEntities(entity: ProgramAdditionalEntity): void
  dataTest?: string
  register: UseFormRegister<FieldValues>
  rootClass: string
}

const AdditionalEntity: FC<Props> = (props: Props) => {
  const { additionalEntity, register, updateAdditionalEntities, rootClass = 'crm-field', dataTest = 'crm-field' } = props
  const client = useApolloClient()
  const {
    loading,
    error,
    data: intersectionData,
  } = useQuery<CrmIntersectionSchemaQuery, CrmIntersectionSchemaQueryVariables>(crmIntersectionSchema, {
    client: client as any,
    variables: {
      entity: additionalEntity.entityType ?? '',
    },
  })

  useEffect(() => {
    if (error) {
      logNewRelicError(error)
    }
  }, [error])

  const fields = additionalEntity.fields
    ? (additionalEntity.fields.reduce((acc, curr) => {
        if (curr.key) {
          return {
            ...acc,
            [curr.key]: curr.key,
          }
        }
        return acc
      }, {}) as { [key: string]: string })
    : {}

  const updateAdditionalEntity = (key: string, value: string[]) => {
    const updatedEntity = {
      ...additionalEntity,
      [key]: value,
    }

    updateAdditionalEntities(updatedEntity)
  }

  const crmIntersectionSchemaFields: CrmIntersectionSchema[] = intersectionData?.crmIntersectionSchema ? intersectionData.crmIntersectionSchema : []

  const requiredFields = crmIntersectionSchemaFields
    .filter((field: CrmIntersectionSchema) => field.fieldDefinition && field.fieldDefinition.required && !fields.hasOwnProperty(field.key))
    .reduce((acc: KeyValueWithId[], curr) => {
      return [...acc, { key: curr.key, value: '', id: globals.getUUID() }]
    }, []) as KeyValueWithId[]

  React.useEffect(() => {
    if (requiredFields.length) {
      const totalFields = additionalEntity.fields ? [...requiredFields, ...additionalEntity.fields] : requiredFields
      const updatedEntity = {
        ...additionalEntity,
        fields: totalFields,
      }
      updateAdditionalEntities(updatedEntity)
    }
  })

  return (
    <>
      {loading && <Loader center />}
      {error && <PageError />}
      {!error && !loading && (
        <StepComponentMapper
          fieldName={'fields'}
          fields={additionalEntity.fields ?? []}
          updateStep={updateAdditionalEntity}
          register={register}
          rootClass={rootClass}
          crmIntersections={crmIntersectionSchemaFields}
          addText={'Add Field'}
          dataTest={`${dataTest}__entity-field`}
          showAdd
        />
      )}
    </>
  )
}

export default AdditionalEntity
