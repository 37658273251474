import { MutableRefObject } from 'react'

import { SvgNames } from '@components/Svg'
import { TabItemData } from '@components/TabsAO/TabsAO'
import { TriggerButtonOption } from '@components/TriggerButton/TriggerButton'
import { rootContext, TOP_BAR_HEIGHT } from '@const/globals'
import { DeleteProgramsMutation } from '@graphql/types/mutation-types'
import { ProgramFieldSetStep, ProgramStatus, ProgramStep, ProgramErrors as ProgramError, ProgramSource } from '@graphql/types/query-types'
import {
  deletedFieldError,
  deletedListError,
  missingListError,
} from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/components/ListProgramStep/utils/ListProgramStep.utils'
import { EDIT_STEP_EVENT } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/utils/ProgramStepsListing.constants'
import { ProgramStepsAndDetailsTabs } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/utils/ProgramStepsAndDetails.constants'
import {
  ListField,
  ListMaintenanceProgramDto,
  ListMaintenanceProgramsContextState,
  ListProgramSource,
} from '@utils/listingPage/listMaintenancePrograms.utils'
import { ProgramErrors } from '@utils/program/program.constants'
import { ProgramStepType } from '@utils/program/program.constants'
import { FetchPromise } from '@utils/types'

export const getProgramStepsAndDetailsTabs = (t: Function): TabItemData[] => [
  {
    index: ProgramStepsAndDetailsTabs.PROGRAM_STEPS,
    label: t('Program Steps'),
  },
  {
    index: ProgramStepsAndDetailsTabs.PROGRAM_DETAILS,
    label: t('Program Details'),
  },
]

interface ActionsDropdownParams {
  canDelete: boolean
  canEdit: boolean
  runProgram: () => void
  setShowDeleteModal: (showDeleteModal: boolean) => void
  t: Function
  onAddStep: () => void
  onViewErrorSummary: () => void
  hasValidProgram: boolean
  runProgramEnabled: boolean
}

export const getProgramStepsAndDetailsActions = ({
  canDelete,
  canEdit,
  runProgram,
  setShowDeleteModal,
  t,
  onAddStep,
  onViewErrorSummary,
  hasValidProgram,
  runProgramEnabled,
}: ActionsDropdownParams): TriggerButtonOption[] => [
  ...(!hasValidProgram
    ? [
        {
          text: t('View error summary'),
          icon: SvgNames.warning,
          onClick: () => onViewErrorSummary(),
        },
      ]
    : []),
  {
    text: t('Add a step'),
    icon: SvgNames.plusLight,
    disabled: !canEdit,
    disabledTooltip: t('Ask your administrator for permission to do this'),
    onClick: () => onAddStep(),
  },
  {
    text: t('Run program now'),
    icon: SvgNames.playNoFill,
    disabled: !runProgramEnabled,
    disabledTooltip: t("You can't run a program that contains errors"),
    onClick: () => runProgram(),
  },
  {
    text: t('Delete program'),
    icon: SvgNames.delete,
    disabled: !canDelete,
    disabledTooltip: t('Ask your administrator for permission to do this'),
    onClick: () => setShowDeleteModal(true),
  },
]

export const getDeleteConfirmationModalProps = (
  deletePrograms: (programs: ListMaintenanceProgramDto[]) => FetchPromise<DeleteProgramsMutation>,
  setShowDeleteModal: (showDeleteModal: boolean) => void,
  setProgram: (program: string | undefined | ListMaintenanceProgramDto) => void,
  t: Function,
  program?: ListMaintenanceProgramDto
) => ({
  buttonText: t('Delete'),
  deleteAction: () => {
    if (program) {
      deletePrograms([program]).then(() => {
        setProgram(undefined)
      })
    }
    setShowDeleteModal(false)
  },
  onClose: () => setShowDeleteModal(false),
  text: t('This list maintenance program will be permanently deleted.'),
  textBold: t('You cannot undo this action.'),
  title: t('Delete program'),
})

export const setProgramErrors = (
  errors: ProgramErrors,
  isMarketingList: boolean,
  update: (values: Partial<ListMaintenanceProgramsContextState>) => void,
  stepsDeletedFieldChecked: MutableRefObject<Boolean>,
  stepsMissedListChecked: MutableRefObject<Boolean>
) => {
  const programErrors: ProgramErrors = {
    ...errors,
    stepErrors: errors.stepErrors.map((error) => ({
      ...error,
      stepType: (error.type as ProgramStepType) ?? 'error',
      showAOContactsIcon: !(isMarketingList && error.type === ProgramStepType.COPY),
    })),
  }
  update({ programStatus: programErrors as ProgramStatus })
  stepsDeletedFieldChecked.current = false
  stepsMissedListChecked.current = false
}

export const scrollToStep = (stepIndex: number, pageContainer: HTMLDivElement | null, stepsReferences: Map<number, HTMLDivElement>) => {
  if (pageContainer) {
    const stepReference = stepsReferences.get(stepIndex)
    if (stepReference) {
      const { top } = stepReference.getBoundingClientRect()
      pageContainer.scrollTo({ top: top - TOP_BAR_HEIGHT, behavior: 'smooth' })
    }
  }
}

export const validateSteps = (
  steps: ProgramStep[],
  pageContainer: HTMLDivElement | null,
  stepsReferences: Map<number, HTMLDivElement>,
  stepsWithInvalidValue: number[]
) => {
  if (pageContainer) {
    const unnamedStepIndex = steps.findIndex(({ displayName }) => !displayName)
    if (unnamedStepIndex !== -1 || stepsWithInvalidValue.length) {
      const firstStepWithErrors = unnamedStepIndex !== -1 && unnamedStepIndex < stepsWithInvalidValue[0] ? unnamedStepIndex : stepsWithInvalidValue[0]
      scrollToStep(firstStepWithErrors, pageContainer, stepsReferences)
      return false
    }
  }
  return true
}

export const getFormattedURL = (url: string) => url.replace(`${rootContext}/`, '')

export const triggerStepEditEvent = (stepPosition: number) => {
  const event = new CustomEvent(EDIT_STEP_EVENT, { detail: { stepPosition } })
  document.dispatchEvent(event)
}

export const stepHasDeletedField = (step: ProgramStep, programSourceFields: ListField[], hasMarketingSource = false) => {
  if (step.stepType === ProgramStepType.FIELD_SET) {
    const { fieldName = '' } = step as ProgramFieldSetStep
    if (hasMarketingSource) {
      return fieldName.startsWith('.deleted_')
    } else {
      return !programSourceFields.map(({ field }) => field).includes(fieldName)
    }
  }
}

export const stepHasDeletedList = (step: ProgramFieldSetStep, hasMarketingSource = false) => {
  if (hasMarketingSource) {
    if (step.stepType === ProgramStepType.FIELD_SET) {
      return !step.listName
    }

    if (step.stepType === ProgramStepType.COPY) {
      return !!(!step.listName && step.listId)
    }

    if (step.stepType === ProgramStepType.COPY_FROM) {
      return !!(!step.srcName && step.srcId)
    }
  }
}

export const stepHasMissingList = (step: ProgramFieldSetStep, hasMarketingSource = false) => {
  if (hasMarketingSource) {
    if (step.stepType === ProgramStepType.COPY) {
      return !step.listName && !step.listId
    }

    if (step.stepType === ProgramStepType.COPY_FROM) {
      return !step.srcName && !step.srcId
    }
  }
}

export const getDeletedFieldErrors = (steps: ProgramStep[], programSourceFields: ListField[], hasMarketingSource = false) => {
  if (steps && programSourceFields.length) {
    return steps.reduce((errors: ProgramError[], step) => {
      const hasDeletedField = stepHasDeletedField(step, programSourceFields, hasMarketingSource)
      if (hasDeletedField) {
        const defaultError = {
          id: step.stepId || '',
          name: step.displayName,
          type: step.stepType,
          stepType: step.stepType,
          errors: [deletedFieldError],
        }
        return [...errors, defaultError]
      }
      return errors
    }, [])
  }
  return []
}

export const getDeletedListError = (steps: ProgramStep[], programSourceFields: ListField[], hasMarketingSource = false) => {
  if (steps && programSourceFields.length) {
    return steps.reduce((errors: ProgramError[], step) => {
      const hasDeletedList = stepHasDeletedList(step, hasMarketingSource)
      if (hasDeletedList) {
        const defaultError = {
          id: step.stepId || '',
          name: step.displayName,
          type: step.stepType,
          stepType: step.stepType,
          errors: [deletedListError],
        }
        return [...errors, defaultError]
      }
      return errors
    }, [])
  }
  return []
}

export const getMissingListError = (steps: ProgramStep[], programSourceFields: ListField[], hasMarketingSource = false) => {
  if (steps && programSourceFields.length) {
    return steps.reduce((errors: ProgramError[], step) => {
      const hasMissingList = stepHasMissingList(step, hasMarketingSource)
      if (hasMissingList) {
        const defaultError = {
          id: step.stepId || '',
          name: step.displayName,
          type: step.stepType,
          stepType: step.stepType,
          errors: [missingListError],
        }
        return [...errors, defaultError]
      }
      return errors
    }, [])
  }
  return []
}

export const getUpdatedStepErrors = (programStatus: ProgramStatus, newErrors: ProgramError[]): ProgramError[] | undefined => {
  return newErrors.reduce((newStepErrors: ProgramError[], newErrors) => {
    const stepErrorsIndex = newStepErrors.findIndex(({ id }) => id === newErrors.id)
    if (stepErrorsIndex !== -1) {
      const currentStepErrors = newStepErrors[stepErrorsIndex]
      if (!currentStepErrors.errors.includes(deletedFieldError) && !currentStepErrors.errors.includes(deletedListError)) {
        newStepErrors[stepErrorsIndex] = {
          ...currentStepErrors,
          errors: [...currentStepErrors.errors, ...newErrors.errors],
        }
      }
      return newStepErrors
    } else {
      return [...newStepErrors, newErrors]
    }
  }, programStatus?.stepErrors ?? [])
}

export const isFormSubmissionSource = (source: ProgramSource | ListProgramSource) => {
  const FORM_SUBMISSIONS_PREFIX = 'l-mirrored-list-form'
  return source?.id.startsWith(FORM_SUBMISSIONS_PREFIX)
}
