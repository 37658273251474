import { SegmentSourceType } from '@graphql/types/microservice/crm-types'
import { CRMConnectorType } from '@utils/hooks/useCRM'

export const CRM_CONTACT_OWNER_ID = 'default'

export enum TableType {
  STANDARD,
  CUSTOM,
}

export enum FieldValidationState {
  FAIL,
  OK,
  WARN,
  DUP_STD,
  DUP_CUST,
  MISSING_DATA_TYPE,
  MISSING_CUSTOM_NAME,
  DUP_CRM_PRIMARY,
  CUST_MATCH_STD,
}

export enum CRM_STATUS {
  SF_REPORT_MAX_RECORDS_REACHED = 'SF_REPORT_MAX_RECORDS_REACHED',
  SF_REPORT_ID_FIELD_MISSING = 'SF_REPORT_ID_FIELD_MISSING',
  SF_REPORT_UNSUPPORTED_FORMAT = 'SF_REPORT_UNSUPPORTED_FORMAT',
  SF_REPORT_UNSUPPORTED_TYPE = 'SF_REPORT_UNSUPPORTED_TYPE',
  SF_REPORT_NOT_FOUND = 'SF_REPORT_NOT_FOUND',
  CRM_SYNC_FAILED = 'CRM_SYNC_FAILED',
  ON_GOING_SYNC = 'ON_GOING_SYNC',
}

export interface CRMSourceOption extends SegmentSourceType {}

export const DEFAULT_SYNCS_REMAINING = 3
export const DEFAULT_TOTAL_SYNCS = 10

export const CRM_CONNECTOR_DEFINITIONS: { [key: string]: string } = {
  [CRMConnectorType.MS_DYNAMICS]: 'MSDYN',
  [CRMConnectorType.NETSUITE]: 'NSUITE',
  [CRMConnectorType.SALESFORCE]: 'SFORCE',
  [CRMConnectorType.SUGAR]: 'SUGAR',
  [CRMConnectorType.ZENDESK]: 'ZENDESK',
}

export const getSourceTitle = (sourceName: string, crmSourceOptions: CRMSourceOption[]) => {
  const matchingSourceOption = crmSourceOptions.find(({ name }) => name === sourceName)
  return matchingSourceOption ? `${matchingSourceOption.title}s` : ''
}
