import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import { SvgColor } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'

import Radio, { RadioType } from '../Radio/Radio'
import Svg, { SvgNames, SvgType } from '../Svg'
import Typography, { LineHeight, TextType, TextWeight } from '../Typography/Typography'

import './RadioCard.css'

export interface RadioCardProps {
  title: string | ReactNode
  titleWeight?: TextWeight
  description: string | ReactNode
  isSelected?: boolean
  svgName?: SvgNames
  hoverSvgName?: SvgNames
  disabledSvgName?: SvgNames
  baseSvgFill?: SvgColor
  hoverSvgFill?: SvgColor
  className?: string
  dataTest?: string
  isAdditionalOption?: boolean
  onClick?: () => void
  disabled?: boolean
  tooltipText?: string
  tooltipClassName?: string
  style?: STYLE
  isHugeIcon?: boolean
}

const rootClass = 'radio-card'

export enum STYLE {
  RADIO_IN,
  RADIO_OUT,
}

const RadioCard: FC<RadioCardProps> = (props: RadioCardProps) => {
  const {
    title,
    titleWeight,
    description,
    svgName,
    hoverSvgName,
    disabledSvgName,
    baseSvgFill,
    hoverSvgFill,
    isSelected,
    onClick,
    disabled,
    tooltipText,
    tooltipClassName,
    dataTest = rootClass,
    className = '',
    style = STYLE.RADIO_IN,
    isHugeIcon = true,
  } = props

  const renderSvg = () => {
    const iconType = isHugeIcon ? SvgType.HUGE_ICON : SvgType.LARGE_ICON
    if (svgName && hoverSvgName) {
      return (
        <div className={classNames(`${rootClass}__svg`, { [`${rootClass}__no-width`]: !isHugeIcon })}>
          <Svg className={classNames(`${rootClass}__svg--hover`)} name={hoverSvgName} type={iconType} fill={hoverSvgFill} />
          <Svg className={classNames(`${rootClass}__svg--blur`)} name={svgName} type={iconType} fill={baseSvgFill} />
          {disabled && disabledSvgName && <Svg className={classNames(`${rootClass}__svg--disabled`)} name={disabledSvgName} type={iconType} />}
        </div>
      )
    } else if (svgName) {
      return (
        <div className={classNames(`${rootClass}__svg`)}>
          <Svg name={svgName} type={iconType} />
          {disabled && disabledSvgName && <Svg className={classNames(`${rootClass}__svg--disabled`)} name={disabledSvgName} type={iconType} />}
        </div>
      )
    }
  }

  const renderBaseCard = (children?: ReactNode) => {
    return (
      <div
        className={classNames(rootClass, className, {
          [`${rootClass}--selected`]: isSelected,
          [`${rootClass}--disabled`]: disabled,
          [`${rootClass}--small-card`]: !isHugeIcon,
        })}
        data-test={dataTest}
        onClick={!disabled ? onClick : undefined}
        tabIndex={0}
        onKeyDown={(keyDownEvent) => (keyDownEvent.key === ' ' && onClick ? onClick() : undefined)}
        role={'radio'}
        aria-checked={isSelected}
      >
        {renderSvg()}
        {children}
      </div>
    )
  }

  const renderCardRadioIn = () => {
    const content = (
      <>
        <Radio
          type={RadioType.CHECKMARK}
          checked={isSelected}
          onChange={!disabled ? onClick : undefined}
          disabled={disabled}
          className={classNames(`${rootClass}__radio`)}
        />
        <div className={`${rootClass}__text`}>
          <Typography text={title} weight={titleWeight ?? TextWeight.MEDIUM} className={classNames(`${rootClass}__text__title`)} />
          <Typography
            text={description}
            type={TextType.BODY_TEXT_SMALL_LIGHT}
            className={classNames(`${rootClass}__text__description`)}
            lineHeight={LineHeight.MEDIUM_SMALL}
          />
        </div>
      </>
    )
    return renderBaseCard(content)
  }

  const renderCardRadioOut = () => {
    const content = (
      <div className={`${rootClass}__text`}>
        <Typography text={title} weight={titleWeight ?? TextWeight.REGULAR} className={classNames(`${rootClass}__text__title`)} />
        <Typography text={description} type={TextType.BODY_TEXT_LIGHT} className={classNames(`${rootClass}__text__description`)} />
      </div>
    )
    return (
      <div className={`${rootClass}__radio-out`}>
        <Radio
          type={RadioType.DEFAULT}
          checked={isSelected}
          onChange={!disabled ? onClick : undefined}
          disabled={disabled}
          className={classNames(`${rootClass}__radio`)}
        />
        {renderBaseCard(content)}
      </div>
    )
  }

  const renderCard: { [key in STYLE]: ReactNode } = {
    [STYLE.RADIO_IN]: renderCardRadioIn(),
    [STYLE.RADIO_OUT]: renderCardRadioOut(),
  }

  return (
    <Tooltip
      className={classNames(`${rootClass}__tooltip`, tooltipClassName)}
      hide={!tooltipText}
      sideOffset={0}
      trigger={renderCard[style]}
      alignTextCenter={disabled}
      autoCloseDuration={10000}
    >
      <Typography text={tooltipText} type={TextType.BODY_TEXT_WHITE} lineHeight={!isHugeIcon ? LineHeight.MEDIUM_LARGE : undefined} />
    </Tooltip>
  )
}

export default RadioCard
