import { FilterQueryParams } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import getAllItems from '@graphql/microservices/categorization/getAllItems'
import getRecentItems from '@graphql/microservices/categorization/getRecentItems'
import {
  GetAllItemsQuery,
  GetAllItemsQueryVariables,
  GetRecentItemsQuery,
  GetRecentItemsQueryVariables,
} from '@graphql/types/microservice/categorization-types'
import { ItemSubTypeDtoName, ItemType } from '@utils/categorization'
import { SortDirection } from '@utils/common'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export interface AutomatedProgramsPickerCategorizationRequests {
  getSuggestedAutomatedProgramsRequest: (search: string, currentPage: number, params?: FilterQueryParams) => FetchPromise<GetRecentItemsQuery>
  getAllItemsRequest: (type: ItemType, search: string, params?: FilterQueryParams) => FetchPromise<GetAllItemsQuery>
}

enum ConditionOperator {
  And = 'AND',
  Or = 'OR',
}
export const useAutomatedProgramsPickerCategorizationRequests = (): AutomatedProgramsPickerCategorizationRequests => {
  const {
    client: { query },
  } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })

  const getSuggestedAutomatedProgramsRequest = async (search: string, currentPage: number, params?: FilterQueryParams) =>
    await query<GetRecentItemsQuery, GetRecentItemsQueryVariables>({
      query: getRecentItems,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        ...params,
        fieldType: 'string',
        orderBy: 'lastUpdated',
        direction: SortDirection.DESC,
        field: 'name',
        pageNumber: currentPage,
        pageSize: 50,
        query: search,
        days: 90,
        type: ItemType.PROGRAM,
        subTypes: [
          ItemSubTypeDtoName.AUTOMATED_PROGRAM_RUNNING,
          ItemSubTypeDtoName.AUTOMATED_PROGRAM_DRAFT,
          ItemSubTypeDtoName.AUTOMATED_PROGRAM_NO_AOC,
        ],
        subTypeCondition: {
          subType: {
            itemType: ItemType.PROGRAM,
            name: ItemSubTypeDtoName.AUTOMATED_PROGRAM_RUNNING,
          },
          conditionOperator: ConditionOperator.Or,
          subTypeCondition: {
            subType: {
              itemType: ItemType.PROGRAM,
              name: ItemSubTypeDtoName.AUTOMATED_PROGRAM_DRAFT,
            },
            conditionOperator: ConditionOperator.Or,
            subTypeCondition: {
              subType: {
                itemType: ItemType.PROGRAM,
                name: ItemSubTypeDtoName.AUTOMATED_PROGRAM_NO_AOC,
              },
              conditionOperator: ConditionOperator.And,
            },
          },
        },
      },
    })

  const getAllItemsRequest = async (type: ItemType, search: string, params?: FilterQueryParams) =>
    await query<GetAllItemsQuery, GetAllItemsQueryVariables>({
      query: getAllItems,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        ...params,
        type,
        query: search,
      },
    })

  return {
    getSuggestedAutomatedProgramsRequest,
    getAllItemsRequest,
  }
}
