import { gql } from '@apollo/client'

export default gql`
  query getRepliesReportsByLaunchId(
    $launchId: String!
    $accountId: Long!
    $sortDirection: Direction
    $sortColumn: RepliesSortColumns
    $searchTerm: String
    $size: Int!
    $page: Int!
  ) {
    getRepliesReportsByLaunchId(
      launchId: $launchId
      accountId: $accountId
      sortDirection: $sortDirection
      sortColumn: $sortColumn
      searchTerm: $searchTerm
      size: $size
      page: $page
    ) {
      smsReplies {
        contactName
        phoneNumber
        repliedOnDate
        replyText
      }
      totalCount
    }
  }
`
