import React, { FC, useCallback, useEffect, useState } from 'react'

import Accordion from '@components/Accordion/Accordion'
import InputWithMaxLength from '@components/InputWithMaxLength/InputWithMaxLength'
import { LabelType, LabelV2 } from '@components/LabelV2/LabelV2'
import Spinner from '@components/Spinner/Spinner'
import { SvgColor } from '@components/Svg/Svg'
import TextArea from '@components/TextArea/TextArea'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { SendTestEmailOptionsRefType } from '@src/pages/EmailComposer/EmailModals/components/SendTestEmailModal/utils/SendTestEmailModal.utils'

import './SendTestEmailOptions.css'

interface SendTestEmailOptionsProps {
  optionsRef: React.MutableRefObject<SendTestEmailOptionsRefType>
  subjectPrefix: string
  subject: string
  dataTest?: string
  loading?: boolean
}

const rootClass = 'send-test-email-options'

const SendTestEmailOptions: FC<SendTestEmailOptionsProps> = ({ optionsRef, subjectPrefix, subject, loading, dataTest = rootClass }) => {
  const { t } = useTranslation()
  const [prefix, setPrefix] = useState<string>(subjectPrefix)

  useEffect(() => {
    setPrefix(subjectPrefix)
    optionsRef.current.subjectPrefix = subjectPrefix
  }, [subjectPrefix, optionsRef])

  const handlePrefixChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      setPrefix(value)
      optionsRef.current.subjectPrefix = value
    },
    [optionsRef]
  )

  const handleTextChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => (optionsRef.current.comment = event.target.value),
    [optionsRef]
  )

  const content = (
    <>
      <LabelV2
        label={t('Subject line prefix')}
        labelType={LabelType.medium}
        tooltip={{ content: t('The previously used subject line prefix is automatically saved.'), minimalPadding: false }}
        dataTest={`${dataTest}-tags-label`}
      />
      <InputWithMaxLength
        inputProps={{ defaultValue: prefix, onChange: handlePrefixChange, trim: false, dataTest: `${dataTest}-input` }}
        maxLength={20}
        showLimitError
        className={`${rootClass}__input push-up-x3`}
      />
      <Typography
        text="Send.Test.Email.Dynamic.Subject"
        tagProps={{ subheader: { type: TextType.SUB_HEADER, weight: TextWeight.BOLD } }}
        values={{ prefix, subject }}
        inline
        noTrim
        dataTest={`${dataTest}-dynamic-subject`}
      />
      <div className="push-down-x4">
        <TextArea
          onChange={handleTextChange}
          name="textarea"
          maxCharacterProps={{ maxLength: 600 }}
          label={<Typography text={t('Optional instructions or comments')} weight={TextWeight.MEDIUM} />}
          placeholder={t('Please type your message here')}
          defaultValue={optionsRef.current.comment}
          className={`${rootClass}__textarea`}
          dataTest={`${dataTest}-textarea`}
        />
      </div>
    </>
  )

  return (
    <div className={rootClass} data-test={dataTest}>
      <Accordion
        childData={[
          {
            index: '0',
            header: <Typography text={t('Test email options')} type={TextType.NORMAL_TEXT_TEAL_LARGER} weight={TextWeight.MEDIUM} />,
            content: loading ? <Spinner /> : content,
          },
        ]}
        chevronLeft
        chevronAnimation={'right-down'}
        chevronFillColor={SvgColor.TEXT_TEAL}
        className={`${rootClass}__accordion`}
        dataTest={`${dataTest}-accordion`}
      />
    </div>
  )
}

export default SendTestEmailOptions
