import api from '@utils/api'

export interface LabsToggleInterface {
  status: string
  feature: string
  enabled: boolean
}

const NewFeatureToggleService = {
  toggleLabsFeature: (feature: string, isOn: boolean, ts: number) =>
    api
      .doFetch('toggleLabsFeature', {
        method: 'POST',
        queryParams: {
          feature,
          sc: 1,
          set: isOn ? 1 : 0,
          ts,
        },
      })
      .then((result) => result as LabsToggleInterface),
}

export default NewFeatureToggleService
