import { gql } from '@apollo/client'

export default gql`
  query getPersonalizationChoices($combineDateFunctions: Boolean) {
    getPersonalizationChoices(combineDateFunctions: $combineDateFunctions) {
      vcFields {
        label
        value
        groupId
      }
      accountVCMaps {
        name
        mapping
      }
      crmFields {
        label
        value
        groupId
      }
    }
  }
`
