import { gql } from '@apollo/client'

export default gql`
  query getTrueOpenSettings {
    getTrueOpenSettings {
      securitySystemsFilterEnabled
      privacySystemsFilterEnabled
    }
  }
`
