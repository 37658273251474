import React, { FC, useMemo, useRef } from 'react'

import classNames from 'classnames'

import { LabelType, LabelV2 } from '@components/LabelV2/LabelV2'
import Radio from '@components/Radio'
import RadioGroup from '@components/RadioGroup'
import RadioWithOptions from '@components/RadioWithOptions/RadioWithOptions'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { useTranslation } from '@const/globals'
import { FieldDefinition } from '@graphql/types/query-types'
import {
  CRMStepDateOption,
  CRMStepSpecificDateOptions,
  DEFAULT_DUE_DATE,
  IMMEDIATELY_KEY,
} from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.constants'

import './CRMDateField.css'

interface CRMDateFieldProps {
  className?: string
  dataTest?: string
  field: FieldDefinition
  onChange?: (value: string) => void
  value?: string
}

const rootClass = 'crm-date-field'

const CRMDateField: FC<CRMDateFieldProps> = (props: CRMDateFieldProps) => {
  const { dataTest = rootClass, className = '', field, onChange, value } = props
  const { displayName, required } = field

  const { t } = useTranslation()

  const defaultDueDateRef = useRef(DEFAULT_DUE_DATE)

  const dateOptions = useMemo<SelectV2SingleOption[]>(() => {
    return Object.entries(CRMStepSpecificDateOptions).map(([key, value]) => ({
      label: t(value),
      value: key,
    }))
  }, [])

  const onRadioChange = (option: CRMStepDateOption) => () => {
    if (onChange) {
      switch (option) {
        case CRMStepDateOption.NONE:
          onChange('')
          break
        case CRMStepDateOption.IMMEDIATELY:
          onChange(IMMEDIATELY_KEY)
          break
        default:
          onChange(defaultDueDateRef.current)
      }
    }
  }

  const onSelectChange = (option?: SelectV2SingleOption) => {
    if (option && onChange) {
      onChange(option.value)
      defaultDueDateRef.current = option.value
    }
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <LabelV2 label={displayName} required={required} labelType={LabelType.input} />
      <RadioGroup verticalLayout>
        {!required ? <Radio label={t(CRMStepDateOption.NONE)} onChange={onRadioChange(CRMStepDateOption.NONE)} checked={!value} /> : <></>}
        <Radio label={t(CRMStepDateOption.IMMEDIATELY)} onChange={onRadioChange(CRMStepDateOption.IMMEDIATELY)} checked={value === IMMEDIATELY_KEY} />
        <RadioWithOptions
          className={`${rootClass}__radio-with-options`}
          label={t(CRMStepDateOption.RELATIVE)}
          onChange={onRadioChange(CRMStepDateOption.RELATIVE)}
          checked={!!value && value !== IMMEDIATELY_KEY}
        >
          <SelectV2 options={dateOptions} isClearable={false} onChange={onSelectChange} defaultValue={value} insideModal />
        </RadioWithOptions>
      </RadioGroup>
    </div>
  )
}

export default CRMDateField
