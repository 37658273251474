import React, { FC, useContext } from 'react'

import classNames from 'classnames'

import { ListingPageCommonContext } from '@complex/ListingPage/Context/ListingPageCommon.context'
import StaticImage from '@components/StaticImage/StaticImage'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './ListingPageHelpSection.css'

interface ListingPageHelpSectionProps {
  className?: string
  dataTest?: string
}

const rootClass = 'listing-page-help-section'

const ListingPageHelpSection: FC<ListingPageHelpSectionProps> = (props: ListingPageHelpSectionProps) => {
  const { dataTest = rootClass, className = '' } = props
  const {
    values: {
      listingPageProps: { helpSectionLink },
    },
  } = useContext(ListingPageCommonContext)

  const { t } = useTranslation()

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <StaticImage name={StaticImageNames.lifeSaver} className={`${rootClass}__life-saver-image`} />
      <Typography text={t('Do you need some help?')} type={TextType.BODY_TEXT_GRAY_MEDIUM} weight={TextWeight.MEDIUM} />
      <Typography
        text={t('Check out the guide for tips, tricks, and troubleshooting help.')}
        type={TextType.BODY_TEXT_GRAY_SMALL}
        className={`${rootClass}__middle-text`}
      />
      <TextLink text={t('View the guide')} target={helpSectionLink} size={TextLinkSize.LARGE} />
    </div>
  )
}

export default ListingPageHelpSection
