import React, { Children, FC, ReactElement, ReactNode, ReactNodeArray, useEffect, useMemo, useRef, useState } from 'react'

import classNames from 'classnames'

import { ButtonType } from '@components/Button'
import DisabledTabWithTooltip from '@components/DisabledTabWithTooltip/DisabledTabWithTooltip'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import TriggerButton, { TriggerButtonOption } from '@components/TriggerButton/TriggerButton'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import * as TabsRadix from '@radix-ui/react-tabs'
import { useTranslation } from '@utils/const/globals'

import Tab, { TabProps } from './TabAO'

import './TabsAO.css'

export interface TabItemData {
  index: string
  parent?: string
  triggerButtonOption?: TriggerButtonOption
  themeName?: string
  label: string | ReactNode
  // pass ariaLabel when label is a ReactNode
  ariaLabel?: string
  labelContent?: {
    primary: string | ReactNode
    secondary?: string
    split?: string | ReactNode
  }
  content?: string | ReactNode | ReactNodeArray
  isTabLocked?: boolean
  disabled?: boolean
  dotStepCompleted?: boolean
  tooltip?: string
  icon?: SvgNames
  inactiveIcon?: SvgNames
  onClick?: () => void
  alwaysRender?: boolean
}

export enum TabOrientation {
  horizontal = 'horizontal',
  vertical = 'vertical',
}

export enum TabStyle {
  REGULAR = 'REGULAR',
  BOXY = 'BOXY',
  SPLIT = 'SPLIT',
  CARD = 'CARD',
  SOLID = 'SOLID',
  PILL = 'PILL',
  DOT = 'DOT',
}

export enum TabsType {
  UNIFIED = 'unified',
}

type TabChild = ReactNode | boolean

export interface TabsProps {
  childData: TabItemData[]
  children?: TabChild[]
  defaultValue?: string
  orientation?: TabOrientation
  tabStyle?: TabStyle
  tabsType?: TabsType
  onChange?: (tab: string) => void
  tabListContent?: ReactNode
  className?: string
  dataTest?: string
}

interface ActiveTabDataState {
  tabUnderlineWidth: number
  tabUnderlineHeight: number
  tabUnderlineLeft: number
}

const getParentTab = (childData: TabItemData[], index: string) => {
  const tabItem = childData.find((tab) => tab.index === index)
  return tabItem?.parent ?? ''
}

const rootClass = 'tabs-ao'

const Tabs: FC<TabsProps> = (props: TabsProps) => {
  const {
    children,
    childData,
    orientation,
    defaultValue,
    tabStyle = TabStyle.REGULAR,
    tabsType,
    dataTest = rootClass,
    className = '',
    onChange,
    tabListContent,
  } = props
  const [openParentTab, setOpenParentTab] = useState('')
  const [activeTabIndex, setActiveTabIndex] = useState<string>(defaultValue || childData[0].index)
  const [activeTabData, setActiveTabData] = useState<ActiveTabDataState>({ tabUnderlineWidth: 0, tabUnderlineHeight: 0, tabUnderlineLeft: 0 })
  const [withTransition, setWithTransition] = useState(false)
  const [activeParentTab, setActiveParentTab] = useState(getParentTab(childData, defaultValue ?? ''))
  const isBoxy = tabStyle === TabStyle.BOXY
  const isSplit = tabStyle === TabStyle.SPLIT
  const isCard = tabStyle === TabStyle.CARD
  const isSolid = tabStyle === TabStyle.SOLID
  const isPill = tabStyle === TabStyle.PILL
  const isDot = tabStyle === TabStyle.DOT
  const tabsRef = useRef<Record<string, HTMLButtonElement | null>>({})
  let tabPositionChanged = false
  const { t } = useTranslation()

  useEffect(() => {
    if (isCard) {
      const setTabPosition = () => {
        const currentTab = tabsRef.current[activeTabIndex]
        !tabPositionChanged &&
          setActiveTabData({
            tabUnderlineLeft: currentTab?.offsetLeft ?? 0,
            tabUnderlineWidth: currentTab?.clientWidth ?? 0,
            tabUnderlineHeight: currentTab?.clientHeight ?? 0,
          })
        tabPositionChanged = true
        setTimeout(() => {
          setWithTransition(true)
        }, 100)
      }

      setTabPosition()
      window.addEventListener('resize', setTabPosition)

      return () => window.removeEventListener('resize', setTabPosition)
    }
  }, [activeTabIndex, tabsRef])

  useEffect(() => {
    setActiveParentTab(getParentTab(childData, defaultValue ?? ''))
  }, [defaultValue])

  if ((isBoxy || isSplit || isSolid) && orientation === TabOrientation.vertical) {
    throw new Error('Boxy, Solid and Split styles cannot be used with vertical orientation')
  }

  // these value are dependent on tab count, hence computing and applying in code.
  const getWidthStyles = () => {
    if (isBoxy) {
      const tabCount = childData.length
      return { maxWidth: `${tabCount * 9 + (tabCount - 1)}rem`, minWidth: `${tabCount * 8.5 + (tabCount - 1) / 2}rem` }
    } else {
      return {}
    }
  }

  const renderBoxyContent = (item: TabItemData) => (
    <div className={`${rootClass}__label-content`}>
      <Typography
        text={item.labelContent?.primary}
        aria-label={'tab-additional-content'}
        weight={TextWeight.BOLD}
        className={`${rootClass}__label-content-primary`}
        dataTest={`${item.index}-primary`}
      />
      <Typography text={item.labelContent?.secondary} type={TextType.BODY_TEXT_SMALL_LIGHT} dataTest={`${item.index}-secondary`} />
    </div>
  )

  const renderPrimaryTabs = (item: TabItemData, itemType: TextType, className: string) => {
    return isCard ? renderCardTab(item) : renderPrimary(item, itemType, className)
  }

  const renderCardTab = (item: TabItemData) => {
    return (
      <Typography
        text={item.label}
        type={TextType.BODY_TEXT_WHITE_SMALL}
        weight={TextWeight.BOLD}
        lineHeight={LineHeight.MEDIUM_SMALL}
        className={`${rootClass}__tab-card-item`}
      />
    )
  }

  const renderPrimary = (item: TabItemData, itemType: TextType, className: string) => {
    const trigger = (
      <div
        className={classNames({
          [`${rootClass}__primary-icon`]: item.icon,
          [`${rootClass}__primary-icon-pill`]: item.icon && isPill,
        })}
      >
        <Typography text={item.label} type={itemType} className={className} dataTest={`${item.index}-tab`} weight={TextWeight.MEDIUM} />
        {item.icon && (
          <>
            {isPill ? (
              <div className={`${rootClass}__primary-icon-pill__icon-container`}>
                <Svg name={activeTabIndex === item.index ? item.icon : item.inactiveIcon ? item.inactiveIcon : item.icon} type={SvgType.ICON} />
              </div>
            ) : (
              <Svg name={item.icon} type={SvgType.ICON} />
            )}
          </>
        )}
      </div>
    )

    return item.tooltip ? renderTooltip(trigger, item) : trigger
  }

  const renderLockedTab = (item: TabItemData, child: ReactNode) => {
    return (
      <DisabledTabWithTooltip
        svgName={SvgNames.lock}
        tooltipText={t('EmailComposer.Resend.Disabled')}
        tabsRef={tabsRef}
        item={item}
        className="disabled-locked__tab"
        dataTest={dataTest}
        svgType={SvgType.MEDIUM}
        child={child}
      />
    )
  }

  const renderDot = (item: TabItemData, itemType: TextType, index: number) => {
    const trigger = (
      <div className={`${rootClass}__tab-dot-section`}>
        <div className={`${rootClass}__tab-dot-item`}>
          <div
            className={classNames(`${rootClass}__tab-dot-button-wrapper`, {
              [`${rootClass}__tab-dot-button-wrapper-active`]: item.index === activeTabIndex,
            })}
          >
            <div
              className={classNames(`${rootClass}__tab-dot-button`, {
                [`${rootClass}__tab-dot-button-disabled`]: item.dotStepCompleted && item.index !== activeTabIndex,
                [`${rootClass}__tab-dot-button-active`]: item.index === activeTabIndex,
              })}
            />
          </div>
          <Typography
            text={item.label}
            type={itemType}
            className={`${rootClass}__tab-dot-label`}
            dataTest={`${item.index}-tab`}
            weight={TextWeight.MEDIUM}
          />
        </div>
        {index != childData.length - 1 && <div className={`${rootClass}__tab-dot-connector`} />}
      </div>
    )
    return item.tooltip ? renderTooltip(trigger, item) : trigger
  }

  const renderSplitContent = (item: TabItemData) => (
    <div className={`${rootClass}__split-container`}>
      <div>
        {renderPrimary(item, TextType.BODY_TEXT_SMALL, '')}
        <div className={`${rootClass}__label-content`}>
          <Typography
            text={item.labelContent?.primary}
            type={TextType.BODY_TEXT_LARGE}
            aria-label={'tab-additional-content'}
            weight={TextWeight.BOLD}
            className={`${rootClass}__label-content-primary`}
            dataTest={`${item.index}-primary`}
          />
          <Typography
            text={item.labelContent?.secondary}
            type={TextType.BODY_TEXT_SMALL_LIGHT}
            dataTest={`${item.index}-secondary`}
            className={`${rootClass}__split-secondary`}
            weight={TextWeight.MEDIUM}
          />
        </div>
      </div>
      <div className={`${rootClass}__split-content`}>{item.labelContent?.split}</div>
    </div>
  )

  const applySubTabTriggers = (tabs: TabItemData[]): TriggerButtonOption[] => {
    const modifiedTabs = tabs.map(({ triggerButtonOption, ...tab }) => {
      if (onChange) {
        const triggerTab = () => {
          onChange(tab.index)
          setActiveParentTab(tab.parent ?? '')
        }
        return { ...triggerButtonOption, onClick: () => triggerTab() }
      }
      return triggerButtonOption
    })
    return modifiedTabs as TriggerButtonOption[]
  }

  const renderPrimaryWithSubTabs = (item: TabItemData, subTabs: TabItemData[]) => {
    const isActive = activeParentTab === item.index

    const triggerButton = (
      <TriggerButton
        dropDownClassName={classNames(`${rootClass}__tab`, {
          [`${rootClass}__tab--active`]: isActive,
          [`${rootClass}__tab--inactive`]: !isActive,
          [`${rootClass}__tab--disabled`]: item.disabled,
          [`${rootClass}__tab--dropdown-open`]: openParentTab === item.index,
        })}
        contentClassName={`${rootClass}__tab__drop-down`}
        key={item.index}
        disabled={item.disabled}
        title={item.tooltip}
        buttonType={ButtonType.DEFAULT}
        label={item.label as string}
        options={applySubTabTriggers(subTabs)}
        onDropDownToggle={(isOpen) => setOpenParentTab(isOpen ? item.index : '')}
        dataTest={`trigger-button-${item.index}`}
        caretFill={isActive ? SvgColor.TEXT_TEAL : SvgColor.TEXT_GRAY}
        triggerOnHover
      />
    )

    return !item.tooltip ? triggerButton : renderTooltip(triggerButton, item)
  }

  const renderTooltip = (trigger: ReactNode, item: TabItemData) => (
    <Tooltip key={item.index} position={'bottom'} align={'start'} trigger={trigger}>
      {item.tooltip}
    </Tooltip>
  )

  const renderTabListWithContent = (tabList: ReactNode) => (
    <div className={`${rootClass}__list-with-content`}>
      <div className={`${rootClass}__list-with-content__triggers`}>{tabList}</div>
      <div className={`${rootClass}__list-with-content__content`}>{tabListContent}</div>
    </div>
  )

  const renderTabList = () => {
    const tabList = childData.map((item, index) => {
      const className = item.index === defaultValue && !isBoxy && !isSplit ? '' : `${rootClass}__unselected`
      const itemType = isBoxy || isSplit ? TextType.BODY_TEXT_GRAY_SMALL : TextType.BODY_TEXT_GRAY
      const ariaLabel = item.ariaLabel ? item.ariaLabel : typeof item.label === 'string' ? item.label : ''
      const subTabs = !item.parent ? childData.filter((child) => child.triggerButtonOption && child.parent === item.index) : []
      const hasSubTabs = !isBoxy && !isSplit && subTabs.length

      return hasSubTabs ? (
        renderPrimaryWithSubTabs(item, subTabs)
      ) : item.isTabLocked ? (
        renderLockedTab(item, item.label)
      ) : !item.parent ? (
        <TabsRadix.Trigger
          onMouseDown={(e) => {
            if (e.button === 0 && !e.ctrlKey) {
              item.onClick?.()
              setActiveTabIndex(item.index)
            }
          }}
          onKeyDown={(e) => {
            if (e.key === ' ' || e.key === 'Enter') {
              item.onClick?.()
              setActiveTabIndex(item.index)
            }
          }}
          ref={(el) => {
            if (tabsRef) {
              tabsRef.current[item.index] = el
            }
          }}
          className={classNames(`${rootClass}__tab`, {
            [`${rootClass}__theme-${item.themeName}`]: (isBoxy || isSplit) && item.themeName,
            [`${rootClass}__tab-split`]: isSplit,
            [`${rootClass}__tab-boxy`]: isBoxy,
            [`${rootClass}__tab-solid`]: isSolid,
            [`${rootClass}__tab-card`]: isCard,
            [`${rootClass}__tab-pill`]: isPill,
            [`${rootClass}__tab-dot`]: isDot,
            [`${rootClass}__tab--disabled`]: item.disabled || item.isTabLocked,
            [`${rootClass}__tab--active`]: item.index === activeTabIndex,
            [`${rootClass}__tab-card-active`]: isCard && item.index === activeTabIndex && !withTransition,
            [`${rootClass}__tab-pill-active`]: isPill && item.index === activeTabIndex,
            [`${rootClass}__tab-pill-inactive`]: isPill && item.index !== activeTabIndex,
            [`${rootClass}__tab--locked`]: item.isTabLocked,
          })}
          value={item.index}
          key={item.index}
          disabled={item.disabled}
          title={isSplit ? item.tooltip : undefined}
          role={'tab'}
          aria-label={ariaLabel}
        >
          {!isSplit && !isDot && renderPrimaryTabs(item, itemType, className)}
          {isBoxy && item.labelContent && renderBoxyContent(item)}
          {isSplit && item.labelContent && renderSplitContent(item)}
          {isDot && renderDot(item, itemType, index)}
        </TabsRadix.Trigger>
      ) : null
    })
    return tabListContent ? renderTabListWithContent(tabList) : tabList
  }

  const renderTabContent = useMemo(() => {
    if (children) {
      const reactChildren = children.filter((child) => typeof child !== 'boolean')
      return Children.map(reactChildren as ReactElement<TabProps>[], (child) => {
        return { ...child, props: { ...child.props, key: child.props.index, isSelected: child.props.index === defaultValue } }
      })
    } else {
      return childData.map((item, idx) => <Tab key={idx} {...item} isSelected={item.index === defaultValue} />)
    }
  }, [defaultValue, children, childData])

  const handleTabChange = (tab: string) => {
    setActiveParentTab('')
    onChange && onChange(tab)
  }

  return (
    <TabsRadix.Root
      className={classNames(rootClass, className, {
        [`${rootClass}__boxy`]: isBoxy,
        [`${rootClass}__split`]: isSplit,
        [`${rootClass}__solid`]: isSolid,
      })}
      data-test={dataTest}
      orientation={orientation}
      value={defaultValue}
      onValueChange={handleTabChange}
      activationMode="manual"
    >
      <TabsRadix.List
        className={classNames(`${rootClass}__list`, {
          [`${className}__list`]: className,
          [`${rootClass}__tab-cards`]: isCard,
          [`${rootClass}__tab-pill`]: isPill,
          [`${rootClass}__tab-dot`]: isDot,
          [`${rootClass}__type-${tabsType}`]: tabsType,
        })}
        style={getWidthStyles()}
      >
        {isDot ? <div className={`${rootClass}__tab-dot-items`}>{renderTabList()}</div> : renderTabList()}
        {isCard && (
          <div
            className={classNames('glider', { ['glider__with-transition']: withTransition })}
            style={{ left: activeTabData.tabUnderlineLeft, width: activeTabData.tabUnderlineWidth, height: activeTabData.tabUnderlineHeight }}
          />
        )}
      </TabsRadix.List>
      {renderTabContent}
    </TabsRadix.Root>
  )
}

export default Tabs
