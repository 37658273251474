import React, { FC } from 'react'

import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { useTranslation } from '@const/globals'

import './CompanyFooter.css'

interface Props {
  onAdd: () => void
  dataTest?: string
}

const rootClass = 'company__footer'

const CompanyFooter: FC<Props> = (props: Props) => {
  const { onAdd, dataTest = rootClass } = props
  const { t } = useTranslation()

  return (
    <div className={rootClass}>
      <Button
        buttonType={ButtonType.SECONDARY}
        onClick={() => onAdd()}
        iconPosition={ButtonIconPosition.LEFT}
        className={`${rootClass}-add-button`}
        dataTest={`${dataTest}-button__add-address`}
      >
        <Svg name={SvgNames.plus} type={SvgType.LARGER_ICON} />
        {t('Add address')}
      </Button>
    </div>
  )
}

export default CompanyFooter
