import React, { FC } from 'react'

import classNames from 'classnames'
import Highcharts, {
  ChartEventsOptions,
  ChartOptions,
  ExportingOptions,
  LegendOptions,
  NavigationOptions,
  PaneOptions,
  PlotOptions,
  ResponsiveOptions,
  SeriesOptions,
  TooltipOptions,
  XAxisOptions,
  YAxisOptions,
} from 'highcharts'
import ChartModuleMore from 'highcharts/highcharts-more.js'
import highchartsAccessibility from 'highcharts/modules/accessibility'
import ExportData from 'highcharts/modules/export-data'
import Exporting from 'highcharts/modules/exporting'
import highchartsMap from 'highcharts/modules/map'
import HCSoldGauge from 'highcharts/modules/solid-gauge'
import HighchartsReact from 'highcharts-react-official'

import { ApolloError } from '@apollo/client'
import Container from '@components/Container'
import {
  LegendLocation,
  MapTypes,
  allLegendProps,
  chartOptions,
  defaultExportingOptions,
  defaultXAxisOptions,
  defaultYAxisOptions,
  mapChartOptions,
  mapColorAxisOptions,
  mapNavigationOptions,
  navigationOptions,
  subTitleOptions,
  titleOptions,
  tooltipOptions,
} from '@const/Chart.constants'
import usMapData from '@highcharts/map-collection/countries/us/us-all.geo.json'
import worldMapData from '@highcharts/map-collection/custom/world.geo.json'

import Loader from '../Loader'
import PageError from '../PageError'

import './Chart.css'

Exporting(Highcharts)
ExportData(Highcharts)
highchartsAccessibility(Highcharts)
highchartsMap(Highcharts)
ChartModuleMore(Highcharts)
HCSoldGauge(Highcharts)

type Props = {
  series: Array<SeriesOptions>
  chartType: string
  chartMarginTop?: number
  mapType?: MapTypes
  chartHeight?: number
  title?: string
  subtitle?: string
  xAxis?: XAxisOptions
  yAxis?: YAxisOptions
  plotOptions?: PlotOptions
  legend?: LegendOptions
  legendLocation?: LegendLocation
  tooltip?: TooltipOptions
  responsive?: ResponsiveOptions
  ExtraElement?: React.ReactNode
  disablePrint?: boolean
  disableMenu?: boolean
  className?: string
  dataTest?: string
  loading?: boolean
  error?: ApolloError
  navigation?: NavigationOptions
  pane?: PaneOptions
  events?: ChartEventsOptions
  noContainer?: boolean
  chartWidth?: number
  alignValue?: number
  horizontalAlign?: number
  exportingOptions?: ExportingOptions
  showTicksOnEmptyData?: boolean
  hideXAxisLine?: boolean
}

const isEmptyData = (series: Array<any>) => !series.length || series.every(({ data }) => !data || (Array.isArray(data) && !data.length))
const rootClass = 'chart'

const Chart: FC<Props> = (props: Props) => {
  const {
    series,
    chartType,
    chartHeight = 300,
    chartWidth,
    chartMarginTop,
    title,
    subtitle,
    xAxis = defaultXAxisOptions,
    yAxis = defaultYAxisOptions,
    plotOptions = {},
    legend = allLegendProps,
    legendLocation = LegendLocation.BOTTOM,
    tooltip = tooltipOptions,
    responsive,
    ExtraElement,
    mapType = MapTypes.US,
    disableMenu = false,
    disablePrint = false,
    dataTest = rootClass,
    className = '',
    loading,
    error,
    navigation = navigationOptions,
    pane,
    events,
    noContainer = false,
    alignValue,
    horizontalAlign = 0,
    exportingOptions,
    showTicksOnEmptyData,
    hideXAxisLine,
  } = props

  const titleConfig = title
    ? {
        ...titleOptions,
        text: title,
        margin: legendLocation === LegendLocation.BOTTOM ? 30 : 20,
      }
    : {
        style: { display: 'none' },
      }

  const subTitleConfig = subtitle
    ? {
        ...subTitleOptions,
        text: subtitle,
      }
    : {
        style: { display: 'none' },
      }

  const constructorType = chartType === 'map' ? 'mapChart' : 'chart'

  const exporting =
    exportingOptions ||
    (disablePrint
      ? defaultExportingOptions
      : disableMenu
      ? { enabled: false }
      : {
          ...defaultExportingOptions,
          buttons: {
            contextButton: {
              ...defaultExportingOptions.buttons?.contextButton,
              menuItems: ['printChart', 'separator', ...(defaultExportingOptions.buttons?.contextButton?.menuItems ?? [])],
            },
          },
        })

  if (hideXAxisLine) {
    xAxis.lineWidth = 0
    yAxis.startOnTick = false
    yAxis.min = yAxis.min ?? 0
  }

  if (showTicksOnEmptyData && isEmptyData(series)) {
    yAxis.min = 0
    yAxis.max = 10
    yAxis.labels = { ...yAxis.labels, formatter: () => `` }
  } else {
    /** Need to reset yAxis props because Highcharts behavior.
    F.e. When we set yAxis.min to 0 initially, Highcharts will use this value as the minimum value for the axis scale.
    If later set yAxis.min to undefined, Highcharts will continue to use the initial value of 0 as the minimum value,
    unless we explicitly set it to a different value. */
    yAxis.min = yAxis.min ?? null
    yAxis.max = yAxis.max ?? null
    yAxis.labels = { ...yAxis.labels, formatter: yAxis.labels?.formatter }
  }

  const filteredCountries = worldMapData.features.filter((country) => country.id !== 'NC')
  const newWorldMap = { ...worldMapData, features: filteredCountries }

  const marginTop = chartMarginTop ? { marginTop: chartMarginTop } : {}
  const options =
    chartType === 'map'
      ? {
          chart: {
            ...mapChartOptions,
            type: chartType,
            height: chartHeight,
            width: chartWidth ?? null,
            map: mapType === MapTypes.US ? usMapData : newWorldMap,
            events,
          } as ChartOptions,
          title: titleConfig,
          subtitle: subTitleConfig,
          series,
          navigation,
          plotOptions,
          legend,
          responsive,
          xAxis,
          yAxis,
          colorAxis: mapColorAxisOptions,
          mapNavigation: mapNavigationOptions,
          credits: {
            enabled: false,
          },
          exporting,
        }
      : {
          chart: {
            ...chartOptions,
            ...marginTop,
            type: chartType,
            height: chartHeight,
            spacingTop: alignValue,
            spacingBottom: alignValue,
            spacingRight: horizontalAlign,
            spacingLeft: horizontalAlign,
            width: chartWidth ?? null,
            animation: false,
            events,
          } as ChartOptions,
          title: titleConfig,
          subtitle: subTitleConfig,
          series,
          navigation,
          plotOptions,
          legend,
          responsive,
          xAxis,
          yAxis,
          tooltip,
          credits: {
            enabled: false,
          },
          exporting,
          pane,
        }

  return (
    <>
      {noContainer ? (
        <div className={classNames(rootClass, className)}>
          {ExtraElement && ExtraElement}
          <HighchartsReact constructorType={constructorType} accessibility={{ enabled: true }} highcharts={Highcharts} options={options} />
        </div>
      ) : (
        <Container className={classNames(rootClass, className)} dataTest={`${dataTest}`}>
          {ExtraElement && ExtraElement}
          {loading || error ? (
            <div
              className={`${rootClass}__loader`}
              style={{
                height: `${chartHeight}px`,
              }}
            >
              {loading && <Loader />}
              {error && <PageError message={error?.message} center={false} />}
            </div>
          ) : (
            <HighchartsReact constructorType={constructorType} accessibility={{ enabled: true }} highcharts={Highcharts} options={options} />
          )}
        </Container>
      )}
    </>
  )
}

export default Chart
