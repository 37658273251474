import * as yup from 'yup'

import { ProgramOutgoingWebhookStepExt } from '@utils/program/program.constants'

export const outgoingWebhookStepColumns = ['First Name', 'Last Name', 'Email', 'Owner Email', 'Owner Name']

export const outgoingWebhookStepSchema = yup.object<ProgramOutgoingWebhookStepExt>().shape({
  displayName: yup.string().required(`A step name is required.`),
  endpoints: yup.array().min(1, `At least one endpoint is required.`),
  headers: yup
    .array()
    .of(yup.string())
    .test('headers', 'At least one column is required', (headers) => {
      return !!headers?.some((header) => header !== undefined && header !== '')
    }),
})
