import React, { FC, useState } from 'react'

import classNames from 'classnames'

import BackButton from '@components/BackButton/BackButton'
import Button, { ButtonType } from '@components/Button'
import Input from '@components/Input'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Typography, { ModalHeaderStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './ExportToFtpFileModal.css'

export interface ExportToFtpFileModalProps {
  className?: string
  dataTest?: string
  isOpen: boolean
  onClose: () => void
  onAction: (fileName: string) => void
  handleBack: () => void
  defaultFileName?: string
}

const rootClass = 'export-to-ftp-file-modal'

export const ExportToFtpFileModal: FC<ExportToFtpFileModalProps> = (props: ExportToFtpFileModalProps) => {
  const { dataTest = rootClass, className = '', isOpen, defaultFileName, onClose, onAction, handleBack } = props
  const [fileName, setFileName] = useState<string>(defaultFileName ?? '')
  const { t } = useTranslation()

  const renderModalBody = () => (
    <ModalBody>
      <Typography text={t('ExportToFtpModal.Description')} type={TextType.BODY_TEXT_LIGHT} />
      <Typography
        text={t('ExportToFtpModal.Input.Label')}
        weight={TextWeight.MEDIUM}
        type={TextType.BODY_TEXT_SMALL}
        tagProps={{ light: { type: TextType.BODY_TEXT_SMALL_LIGHT, inline: true } }}
        className={`${rootClass}__label`}
      />
      <Input
        placeholder={defaultFileName ?? t('ExportToFtpModal.Input.Placeholder')}
        value={fileName}
        dataTest={`${dataTest}-input`}
        onChange={(e) => {
          setFileName(e.target.value)
        }}
      />
    </ModalBody>
  )

  const modalHeader = (
    <ModalHeader headerType={ModalHeaderType.List} className={`${rootClass}__header`}>
      <BackButton onClick={handleBack} />
      <Typography text={t('ExportToFtpModal.Title')} {...ModalHeaderStyle} />
    </ModalHeader>
  )

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={modalHeader}>
      {renderModalBody()}
      <ModalFooter footerType={ModalFooterType.List} className={`${rootClass}__footer`}>
        <Button buttonType={ButtonType.TERTIARY} onClick={onClose} dataTest={`${dataTest}-button-tertiary`}>
          {t('Cancel')}
        </Button>
        <Button
          buttonType={ButtonType.PRIMARY}
          onClick={() => fileName && onAction(fileName)}
          dataTest={`${dataTest}-button-primary`}
          disabled={!fileName}
        >
          {t('Next')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
