import { ApolloClient } from '@apollo/client'
import getItemHierarchy from '@graphql/microservices/categorization/getItemHierarchy'
import { GetItemHierarchyQuery, GetItemHierarchyQueryVariables } from '@graphql/types/microservice/categorization-types'
import { Segment } from '@utils/contactSegments/contactSegments.utils'
import { buildSegmentHierarchy, buildSegments } from '@utils/contactSegments/contactSegments.utils'

export const getSegmentHierarchyUtils = (segmentId: number, client: ApolloClient<any>): Promise<Segment[]> => {
  return client
    .query<GetItemHierarchyQuery, GetItemHierarchyQueryVariables>({
      query: getItemHierarchy,
      fetchPolicy: 'network-only',
      variables: {
        itemId: segmentId,
      },
    })
    .then(({ data: { getItemHierarchy } }) => buildSegmentHierarchy(buildSegments(getItemHierarchy)))
    .catch(() => [])
}
