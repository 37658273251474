import React, { FC, useCallback, useContext, useState } from 'react'

import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import Caution from '@components/Caution/Caution'
import ConfirmationModal from '@components/ConfirmationModal/ConfirmationModal'
import { YesNo } from '@components/ConfirmationModal/index'
import { LoaderSize } from '@components/Dots/Dots'
import { IllustrationModal } from '@components/IllustrationModal/IllustrationModal'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { Status } from '@components/StatusToast/StatusToast'
import { TimePickerV2Format } from '@components/TimePickerV2/TimePickerV2'
import Typography, { TextAlign, TextType, TextWeight } from '@components/Typography/Typography'
import { ALLOWED_TO_LAUNCH } from '@src/pages/EmailComposer/utils/BeeEditor.constants'
import { detectEmailType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { SaveComposerCommonType } from '@utils/composer/commonComposer/CommonComposer.context'
import { EmailComposerContext, SendTimeType } from '@utils/composer/context/EmailComposer.context'
import { useEmailComposerRequests } from '@utils/composer/emailComposer/GraphQL/EmailComposerRequests.graphQL'
import { rootContext, useTranslation } from '@utils/const/globals'
import { isCustomerCareLogin } from '@utils/cookie'
import { formatNumber } from '@utils/numbers'
import { setLocalStorageItem } from '@utils/window'

import './SendConfirmationModal.css'

dayjs.extend(utc)
dayjs.extend(timezone)

interface SendConfirmationModalProps {
  onClose: () => void
  saveComposerEmail: SaveComposerCommonType
}

const rootClass = 'send-confirmation-modal'

export const SendConfirmationModal: FC<SendConfirmationModalProps> = ({ onClose, saveComposerEmail }) => {
  const {
    values,
    values: {
      haveUnsavedChanges,
      message: { id, sendMethod, messageType, webinar },
      recipientsCount,
      launchApproval,
      detectedURLChanges: { plainTextMode },
    },
    api: { update, updateModal },
  } = useContext(EmailComposerContext)

  const requiresApproval = launchApproval.toLocaleLowerCase() !== ALLOWED_TO_LAUNCH
  const launchName = launchApproval.toLocaleLowerCase() === 'not allowed to launch' ? 'an approver' : launchApproval

  const { t } = useTranslation()

  const [loading, setLoading] = useState<boolean>(false)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [showSaveModal, setShowSaveModal] = useState<boolean>(!!haveUnsavedChanges)

  const { sendMessageRequest } = useEmailComposerRequests()

  const isSendNow = sendMethod.sendtype === 'STANDARD'
  const isCustomerCare = isCustomerCareLogin()

  const { isEmailCRM, isEmailWebinar } = detectEmailType(messageType)

  const handleCloseComposer = useCallback(() => {
    update({ isSend: false })
    if (window.opener) {
      window.close()
    } else {
      if (isEmailCRM) {
        window.close()
      } else {
        window.open(
          requiresApproval
            ? `${rootContext}/classic/if/_messages/listingScheduled.jsp`
            : sendMethod.sendtype === 'STANDARD'
            ? `${rootContext}/classic/if/_messages/sentMessageListing.jsp`
            : `${rootContext}/classic/if/messages/listingScheduled.jsp`,
          '_self'
        )
      }
    }
  }, [requiresApproval, sendMethod.sendtype, update])

  const handleSend = useCallback(async () => {
    setLoading(true)
    const { data, errors } = await sendMessageRequest({ messageId: id })

    if (errors) {
      update({ modalState: { statusToast: { status: Status.FAIL, message: t('EmailComposer.Send.Toast_fail') } } })
    } else if (data?.sendMessage?.status === 'ok') {
      if (isEmailWebinar) {
        setLocalStorageItem('messageSaved', `${Date.now()}`)
      }
      setShowSuccess(true)
    } else {
      handleCloseComposer()
    }
    setLoading(false)
  }, [id, sendMessageRequest, handleCloseComposer, t, update])

  const { sendtype, sendtimezone, senddate, sendtime } = sendMethod
  let sendOn = undefined
  let from = undefined
  let to = undefined
  let timeZone = undefined
  let interval = undefined

  if (sendtype === SendTimeType.LATER) {
    const parsedTime = dayjs(sendtime, TimePickerV2Format.HH_MM)
    const rawOn = dayjs(senddate).hour(parsedTime.hour()).minute(parsedTime.minute()).tz(sendtimezone, true)
    sendOn = `${rawOn.format('dddd, MMMM D')}, at ${rawOn.format('h:mm A')}`
    timeZone = sendtimezone
  } else if (sendtype === SendTimeType.TIME_ZONE) {
    const parsedTime = dayjs(sendtime, TimePickerV2Format.HH_MM)
    const rawOn = dayjs(senddate).hour(parsedTime.hour()).minute(parsedTime.minute())
    sendOn = `${rawOn.format('dddd, MMMM D')}, at ${rawOn.format('h:mm A')}`
  } else if (sendtype === SendTimeType.STAGGERED) {
    const { staggertimezone, staggerdatestart, staggerdateend, staggertimestart, staggertimeend } = sendMethod
    timeZone = staggertimezone
    from = dayjs(staggerdatestart).tz(timeZone, true)
    to = dayjs(staggerdateend).tz(timeZone, true)
    interval = `${staggertimestart} - ${staggertimeend}`
  } else if (sendtype === SendTimeType.ADAPTIVE) {
    const { adaptivedatestart, adaptivedateend } = sendMethod
    from = dayjs(adaptivedatestart)
    to = dayjs(adaptivedateend)
  }

  const bodyText = t(
    `EmailComposer.Send.Confirmation.Modal.${sendtype}${requiresApproval && sendtype === SendTimeType.STANDARD ? '_APPROVAL' : ''}`,
    {
      contactsCount: formatNumber(recipientsCount || 0),
      from,
      to,
      sendOn,
      timeZone,
      interval,
      formatParams: {
        from: { weekday: 'long', month: 'short', day: 'numeric' },
        to: { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' },
        sendOn: {
          weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
          timeZone,
        },
      },
    }
  )

  const approvalBodyText = requiresApproval ? (
    <div className={`${rootClass}__approver`}>
      <Typography
        text={t('EmailComposer.Send.Confirmation.Modal.APPROVAL', { launchName })}
        textAlign={TextAlign.CENTER}
        tagProps={{ medium: { weight: TextWeight.MEDIUM, inline: true } }}
      />
    </div>
  ) : (
    ''
  )

  const getHeader = () => {
    if (requiresApproval) {
      return showSuccess ? 'Success! Your email is awaiting approval.' : 'Ready to submit for approval?'
    } else {
      return showSuccess
        ? isSendNow
          ? 'Success! Your email’s on its way—another job well done.'
          : 'Success! Your email’s been scheduled. Now, we wait...'
        : 'Ready to send?'
    }
  }

  const handleUnsavedChanges = useCallback(() => {
    const isWebinarMessage = messageType === 'WEBINAR_MESSAGE'
    const isCautionType = ['FORM_AUTO_RESPONSE', 'PROGRAM_MESSAGE', 'WEBINAR_MESSAGE'].includes(messageType)

    return (
      <ConfirmationModal
        isOpen={showSaveModal}
        title={t(
          `EmailComposer.Preview.${plainTextMode ? 'EditPlainText.' : 'SendConfirmation.'}UnsavedChanges.Modal.Title${
            isWebinarMessage ? `.${messageType}` : ''
          }`,
          {
            context: webinar?.scheduledTime ? 'scheduled' : 'default',
          }
        )}
        body={
          <>
            <Typography
              text={t(
                `EmailComposer.Preview.${plainTextMode ? 'EditPlainText.' : ''}UnsavedChanges.Modal.BodyText${
                  isWebinarMessage ? `.${messageType}` : isCustomerCare ? '.DEFAULT' : ''
                }`,
                {
                  context: webinar?.scheduledTime ? 'scheduled' : 'default',
                  date: dayjs(webinar?.scheduledTime).format('MMMM D, YYYY'),
                  time: dayjs(webinar?.scheduledTime).format('HH:MMA'),
                }
              )}
              type={TextType.BODY_TEXT_LIGHT}
              tagProps={{ bold: { weight: TextWeight.BOLD, inline: true }, medium: { weight: TextWeight.MEDIUM, inline: true } }}
              className="push-down-x2 push-up-x2"
            />
            {!(isWebinarMessage && !webinar?.scheduledTime) && isCautionType && (
              <Caution
                message={
                  <Typography
                    text={t(`EmailComposer.Preview.EditPlainText.UnsavedChanges.Modal.Caution.${messageType}`)}
                    tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
                  />
                }
              />
            )}
          </>
        }
        yesButtonText={t('Save and continue')}
        noButtonText={t('Cancel')}
        isYesNo
        onAnswer={(answer) => {
          if (answer === YesNo.YES) {
            updateModal('spinner', { size: LoaderSize.LARGE })
            saveComposerEmail(values).then(() => {
              updateModal('spinner', undefined)
              setShowSaveModal(false)
            })
          } else {
            update({ isSend: false })
            setShowSaveModal(false)
          }
        }}
      />
    )
  }, [messageType, saveComposerEmail, t, updateModal, webinar?.scheduledTime])

  return haveUnsavedChanges ? (
    handleUnsavedChanges()
  ) : (
    <IllustrationModal
      illustration={showSuccess ? StaticImageNames.successCircle : StaticImageNames.messagesTeal}
      header={getHeader()}
      bodyText={
        <>
          {approvalBodyText}
          <Typography
            text={bodyText}
            type={TextType.SECTION_HEADER}
            textAlign={TextAlign.CENTER}
            tagProps={{ medium: { weight: TextWeight.MEDIUM } }}
            inline
          />
        </>
      }
      confirmButton={
        showSuccess
          ? undefined
          : {
              title: requiresApproval ? 'Submit for approval' : isSendNow ? 'Send now' : 'Schedule email',
              onClick: handleSend,
            }
      }
      centerHeader
      confirmWithLoader
      loading={loading}
      closeButtonClass={showSuccess ? 'push-down-x2' : undefined}
      closeButton={{
        title: showSuccess ? 'Close email composer' : 'Cancel',
        onClick: showSuccess ? handleCloseComposer : onClose,
        disabled: loading,
      }}
      className={requiresApproval ? rootClass : undefined}
    />
  )
}
