import React, { FC, Fragment } from 'react'

import classNames from 'classnames'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import { ChangeFieldRules } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/components/ListProgramStep/components/ChangeFieldStepEditor/utils/ChangeFieldStepEditor.constants'
import { deletedListError } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/components/ListProgramStep/utils/ListProgramStep.utils'
import {
  CFSCondition,
  CFSConditionType,
  CFSOperation,
} from '@src/pages/programs/dashboard/components/ProgramSteps/components/ChangeFieldStepDetail/ChangeFieldStepDetail.constants'
import { getRuleText } from '@src/pages/programs/dashboard/components/ProgramSteps/ProgramSteps.utils'

import './ChangeFieldStepDetail.css'

export interface ChangeFieldStepDetailProps {
  programId: string
  listName: string
  operations: CFSOperation[]
  conditions: CFSCondition[]
  className?: string
  dataTest?: string
}

const rootClass = 'change-field-step-detail'

const ChangeFieldStepDetail: FC<ChangeFieldStepDetailProps> = (props: ChangeFieldStepDetailProps) => {
  const { programId, listName, operations, conditions, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const hasConditions = conditions.length > 0
  const baseUrl = `${rootContext}/classic/if/messages/messageSentFrame.jsp?id=${programId}&msgid=`

  const renderOperation = (operation: CFSOperation, key: string) => {
    const escapeHtml = (value = '') => value.replace(/</g, '&lt;').replace(/>/g, '&gt;')

    const ruleValueHasAngleBrackets = operation.value.includes('>') || operation.value.includes('<')
    const rulesWithoutValues: string[] = [
      ChangeFieldRules.UNIQUE_NUMBER,
      ChangeFieldRules.SET_TIMEZONE,
      ChangeFieldRules.CLEAR_VALUE,
      ChangeFieldRules.SET_CURRENT_DATE,
    ]
    const showValue = !rulesWithoutValues.includes(operation.rule)
    const ruleValue = operation.value || '(undefined)'
    const operationValueText = t(getRuleText(operation.rule), { value: ruleValueHasAngleBrackets ? escapeHtml(ruleValue) : ruleValue })

    return (
      <div className={`${rootClass}__operation`} key={key}>
        <Typography text={`${t('Change field')}: `} />
        {operation.error && (
          <Tooltip
            triggerClassName={`${rootClass}__operation-error`}
            hide={typeof operation.error === 'boolean'}
            trigger={<Svg name={SvgNames.warningSolid} type={SvgType.ICON} />}
          >
            {operation.error}
          </Tooltip>
        )}
        <Typography text={`${operation.fieldName || '(undefined)'}, `} weight={operation.fieldName ? TextWeight.MEDIUM : TextWeight.ITALIC} />
        <TextWithTooltipOnEllip
          typographyProps={{
            text: operationValueText,
            weight: showValue && !operation.value ? TextWeight.ITALIC : TextWeight.REGULAR,
            shouldUnescape: ruleValueHasAngleBrackets,
          }}
          tooltipProps={{
            children: operationValueText,
          }}
        />
      </div>
    )
  }

  const renderSingleton = () => {
    return operations.map((operation) => <div key={operation.fieldName}>{renderOperation(operation, `k-else-${operation.fieldName}`)}</div>)
  }

  const renderConditionName = (icon: SvgNames, name: string, msgId: string) =>
    icon === SvgNames.contacts ? (
      <>
        <div className={`${rootClass}__condition-welcome`}>
          <Svg name={icon} type={SvgType.ICON} /> <Typography text={name} weight={TextWeight.MEDIUM} inline />
        </div>
      </>
    ) : (
      <div className={`${rootClass}__condition-email`}>
        <Svg name={icon} type={SvgType.ICON} /> <TextLink text={name} link={`${baseUrl}${msgId}`} size={TextLinkSize.LARGE} hideIcon />
      </div>
    )

  const renderComplex = (ifStatement: string, operations: CFSOperation[], name: string, msgId: string, icon: SvgNames) => (
    <Fragment key={`${ifStatement}-${name}`}>
      <div>
        <Typography text={ifStatement} inline /> {renderConditionName(icon, name, msgId)}
      </div>
      {operations.map((operation) => renderOperation(operation, `${ifStatement}-${operation.fieldName}`))}
    </Fragment>
  )

  const renderList = () => (
    <>
      {conditions.map((condition) => {
        const ifStatement = condition.condition
        return renderComplex(
          ifStatement,
          condition.operations,
          condition.name,
          condition.msgId,
          condition.type === CFSConditionType.EMAIL ? SvgNames.letter : SvgNames.contacts
        )
      })}
      {operations.length > 0 && (
        <div>
          <Typography text={t('ELSE')} inline />
        </div>
      )}
      {operations.map((operation, idx) => renderOperation(operation, `k-otherwise-${idx}`))}
    </>
  )

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div>
        <Typography text={`${t('List to Modify')}: `} inline />
        {!listName && <Tooltip trigger={<Svg name={SvgNames.warningSolid} type={SvgType.ICON} />} textBold={deletedListError} />}
        <Typography text={listName} weight={TextWeight.MEDIUM} inline />
      </div>
      {!hasConditions && renderSingleton()}
      {hasConditions && renderList()}
    </div>
  )
}

export default ChangeFieldStepDetail
