import { useCallback } from 'react'

import { useApolloClient } from '@apollo/client'
import getEmailTemplateLayouts from '@graphql/queries/getEmailTemplateLayouts'
import { GetEmailTemplateLayoutsQuery, GetEmailTemplateLayoutsQueryVariables, HeaderResponse, FooterResponse } from '@graphql/types/query-types'
import { EMAIL_LAYOUTS_DEFAULT_PAGE_SIZE } from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/constants/EmailLayoutsModal.constants'
import { EmailTemplateLayoutType } from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/utils/EmailLayoutsModal.utils'
import { filterNotEmptyArray } from '@utils/array'

export type EmailTemplateLayout = (HeaderResponse | FooterResponse) & { stringId: string }
export type GetEmailTemplateLayoutsPromiseType = (variables: GetEmailTemplateLayoutsQueryVariables) => Promise<EmailTemplateLayout[]>

export const proceedEmailTemplateLayouts = (layouts: (HeaderResponse | FooterResponse | undefined)[] | undefined): EmailTemplateLayout[] => {
  const filteredLayouts = layouts
    ? (layouts.filter((layout) => filterNotEmptyArray(layout) && typeof layout.id === 'number') as (HeaderResponse | FooterResponse)[])
    : []
  return filteredLayouts.map((layout) => ({ ...layout, stringId: `${layout.id}` }))
}
export const useGetEmailTemplateLayouts = (layoutsType: EmailTemplateLayoutType): GetEmailTemplateLayoutsPromiseType => {
  const client = useApolloClient()

  return useCallback(
    (variables: GetEmailTemplateLayoutsQueryVariables) =>
      client
        .query<GetEmailTemplateLayoutsQuery, GetEmailTemplateLayoutsQueryVariables>({
          query: getEmailTemplateLayouts,
          fetchPolicy: 'network-only',
          variables: { ...variables, pageSize: EMAIL_LAYOUTS_DEFAULT_PAGE_SIZE, layoutsType },
        })
        .then(({ data }) => {
          if (data?.getEmailTemplateLayouts) {
            const { headers, footers } = data.getEmailTemplateLayouts
            return proceedEmailTemplateLayouts(layoutsType === EmailTemplateLayoutType.HEADER ? headers : footers)
          } else {
            return Promise.reject()
          }
        }),
    [client, layoutsType]
  )
}
