import React, { FC, useContext, useEffect, useState } from 'react'

import classNames from 'classnames'

import Container from '@components/Container/index'
import InfoStatus, { InfoStatusTypes } from '@components/InfoStatus/InfoStatus'
import { LinkTextButton, LinkTextButtonSize } from '@components/LinkTextButton/LinkTextButton'
import SectionHeadline from '@components/SectionHeadline/SectionHeadline'
import { SvgNames } from '@components/Svg/index'
import Svg, { SvgColor, SvgType } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { FormManagerContext } from '@src/pages/Content/Forms/FormManager/context/FormManager.context'
import { useFormManagerRequests } from '@src/pages/Content/Forms/FormManager/GraphQL/FormManagerRequests.graphQL'
import {
  FORMS_SUBMISSIONS_URL,
  WEBINARS_SUBMISSIONS_URL,
  getDetailsURL,
} from '@src/pages/listingPages/FormsJoinView/FormsJoinViewListingPageContainer.constants'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import { logNewRelicError } from '@utils/new-relic.utils'
import { getFormattedNumber } from '@utils/numbers'

import './SubmissionDetails.css'

interface SubmissionDetailsProps {
  className?: string
  dataTest?: string
}

const rootClass = 'submission-details'
const AOC_ZOOM = 'l-mirrored-list-zoom'
const AOC_CITRIX = 'l-mirrored-list-citrix'
const AOC_WEBEX = 'l-mirrored-list-webex'
const SEND_TO_AOC = 'l-mirrored-list'

const SubmissionDetails: FC<SubmissionDetailsProps> = (props: SubmissionDetailsProps) => {
  const { dataTest = rootClass, className = '' } = props
  const {
    values: {
      formId,
      submissionDetails: { name, contactCount, sentToAOC, segmentName },
    },
  } = useContext(FormManagerContext)
  const [listLink, setListLink] = useState('')
  const { phase3FormSubmission } = useAccountSettings()
  const { getFormItemRequest } = useFormManagerRequests()
  const { t } = useTranslation()

  const getFormItem = async () => {
    const { data, errors } = await getFormItemRequest(formId)
    if (errors) {
      logNewRelicError(errors)
    }

    if (data) {
      const { item = '{}' } = data.getItem as ItemDto
      const { listId } = JSON.parse(item)
      const sendToAOC = listId.startsWith(SEND_TO_AOC)
      const isWebinar = listId.startsWith(AOC_ZOOM) || listId.startsWith(AOC_CITRIX) || listId.startsWith(AOC_WEBEX)
      const detailUrl = isWebinar ? WEBINARS_SUBMISSIONS_URL : FORMS_SUBMISSIONS_URL
      const type = isWebinar ? ItemType.WEBINAR_SUBMISSION : ItemType.FORM_SUBMISSION

      const link =
        sendToAOC && phase3FormSubmission
          ? `${rootContext}/${getDetailsURL(detailUrl)}?externalId=${listId}&type=${type}`
          : `${rootContext}/classic/if/_lists/formSubmissionListing.jsp?formId=${listId}&reactBack=1`

      setListLink(link)
    }
  }

  useEffect(() => {
    getFormItem()
  }, [formId])

  const statusMessage = segmentName ? (
    <div className={`${rootClass}__segment`}>
      <Typography
        text={t('FormManager.SubmissionDetails.SentToAOCAndSegment')}
        type={TextType.BODY_TEXT_SMALL}
        className={`${rootClass}__segment-base`}
      />
      <Tooltip
        trigger={
          <Typography
            text={` ${segmentName}`}
            type={TextType.BODY_TEXT_SMALL}
            weight={TextWeight.MEDIUM}
            dataTest={`${dataTest}-segment-name`}
            className={'ellip'}
          />
        }
        triggerClassName={classNames(`${rootClass}__segment-link`, 'ellip')}
      >
        {segmentName}
      </Tooltip>
    </div>
  ) : (
    <Typography text={t('FormManager.SubmissionDetails.SentToAOC')} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.MEDIUM} />
  )

  return (
    <Container className={classNames(rootClass, className)} data-test={dataTest}>
      <SectionHeadline>{t('Submission List')}</SectionHeadline>
      <div className={`${rootClass}__details`}>
        <Svg name={SvgNames.contacts} fill={SvgColor.TEAL} type={SvgType.LARGER_ICON} className={`${rootClass}__details-svg`} />
        <div className={`${rootClass}__details-list`}>
          <Typography text={name} weight={TextWeight.MEDIUM} />
          <Typography
            text={t('FormManager.SubmissionDetails.ContactsCount', { contactCount: getFormattedNumber(contactCount) })}
            type={TextType.BODY_TEXT_LIGHT}
          />
        </div>
        {listLink && (
          <LinkTextButton
            text={t('View List')}
            onClick={() => window.open(listLink, 'listFrame')}
            dataTest={`${dataTest}-submission-list-link`}
            size={LinkTextButtonSize.SMALL}
            hideIcons
          />
        )}
      </div>
      {sentToAOC && <InfoStatus message={statusMessage} status={InfoStatusTypes.Informative} className={`${rootClass}__info`} />}
    </Container>
  )
}

export default SubmissionDetails
