import React, { FC, ReactNode, useState } from 'react'

import classNames from 'classnames'

import DropDown, { DropDownType } from '@components/DropDown/DropDown'
import ScrollArea from '@components/ScrollArea/ScrollArea'
import TagManagerBaseItem from '@components/TagManager/components/TagManagerBaseItem/TagManagerBaseItem'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { LabelDto } from '@graphql/types/microservice/categorization-types'

import './TagViewer.css'

interface Props {
  className?: string
  dataTest?: string
  onToggleDropDown: (open: boolean) => void
  tags: LabelDto[]
  title?: string
  trigger: ReactNode
}

const rootClass = 'tag-viewer'

const TagViewer: FC<Props> = (props: Props) => {
  const { onToggleDropDown, tags, title = 'View Tags', trigger, dataTest = rootClass, className = '' } = props
  const [dropDownOpen, setDropDownOpen] = useState<boolean>(false)
  const { t } = useTranslation()

  const toggleDropDown = () => {
    onToggleDropDown(!dropDownOpen)
    setDropDownOpen(() => !dropDownOpen)
  }

  return (
    <DropDown
      className={classNames(rootClass, className, {
        [`${rootClass}__drop-down-open`]: dropDownOpen,
      })}
      dataTest={dataTest}
      alignContentEnd
      isOpen={dropDownOpen}
      toggleOpen={toggleDropDown}
      type={DropDownType.STYLED}
      trigger={trigger}
    >
      <div className={`${rootClass}__content`}>
        <Typography text={t(title)} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.MEDIUM} />
        <ScrollArea showOnEvent={'scroll'}>
          <div className={`${rootClass}__tags`}>
            {tags.map(({ color, name }) => (
              <TagManagerBaseItem color={color as string} name={name as string} key={name} />
            ))}
          </div>
          {tags.length > 6 && <div className={`${rootClass}__tags-gradient`} />}
        </ScrollArea>
      </div>
    </DropDown>
  )
}

export default TagViewer
