import React, { CSSProperties, FC, useMemo } from 'react'

import classNames from 'classnames'

import './ProgressLine.css'

interface ProgressLineProps {
  className?: string
  dataTest?: string
  actualValue: number
  total: number
}

const rootClass = 'progress-line'

const ProgressLine: FC<ProgressLineProps> = (props: ProgressLineProps) => {
  const { dataTest = rootClass, className = '', total, actualValue } = props
  const percentage = useMemo(() => (total === 0 ? 0 : (actualValue / total) * 100), [actualValue, total])
  const lineLength: CSSProperties = { width: `${actualValue > total ? total : percentage}%` }
  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div data-test={`${dataTest}__total`} className={`${rootClass}__total`} />
      <div style={lineLength} data-test={`${dataTest}__length`}>
        <div
          data-test={`${dataTest}__actual`}
          className={classNames(`${rootClass}__actual`, {
            [`${rootClass}__actual-hidden`]: actualValue === 0,
          })}
        />
      </div>
    </div>
  )
}

export default ProgressLine
