import { gql } from '@apollo/client'

export default gql`
  query getLitmusPreviews($msgId: String, $text: Boolean, $recipientId: String, $client: String) {
    getLitmusPreviews(msgId: $msgId, text: $text, recipientId: $recipientId, client: $client) {
      createdAt
      testId
      previews {
        fullImage
        group
        platform
        thumbnail
        title
        hasBlockedImage
      }
    }
  }
`
