import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Cell, Column, Row, useExpanded, useFlexLayout, useTable } from 'react-table'

import classNames from 'classnames'

import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { TableColumn } from '@components/Table/Table'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { RESPONSIVE_WIDTH_MAX, RESPONSIVE_WIDTH_MIN } from '@const/globals'

import './NestedTable.css'

interface ColoredLabel {
  text: string
  className: string
}

interface Props {
  data: Row[]
  columns: TableColumn[]
  coloredLabels?: ColoredLabel[]
  initialState?: any
  className?: string
  dataTest?: string
}

const rootClass = 'nested-table'
const ENHANCED_COL_WIDTH = 300

/**
 * @deprecated use <TableV2 instead
 */
const NestedTable: FC<Props> = (props: Props) => {
  const { data, columns, coloredLabels, initialState, dataTest = rootClass, className = '' } = props

  const totalRowCellWidth = data.length ? columns.filter((_col, idx) => idx > 0).reduce((a, c) => a + ((c.maxWidth as number) ?? 0), 0) : 0
  const [tableWidth, setTableWidth] = useState<number>(totalRowCellWidth)
  const tableRef = useRef(null)

  useEffect(() => {
    const curr = tableRef.current || { offsetWidth: 0 }
    const newWidth = Math.max(Math.min(curr.offsetWidth, RESPONSIVE_WIDTH_MAX), RESPONSIVE_WIDTH_MIN)
    setTableWidth(newWidth)
  }, [tableRef])
  const flexColBaseWidth = tableWidth - totalRowCellWidth
  const flexColumnWidth = flexColBaseWidth < ENHANCED_COL_WIDTH ? ENHANCED_COL_WIDTH : flexColBaseWidth
  const flexColumnMinWidth = flexColumnWidth < ENHANCED_COL_WIDTH ? flexColumnWidth : ENHANCED_COL_WIDTH

  const expanderCol = {
    accessor: 'expander',
    Header: columns[0].Header,
    align: 'left',
    minWidth: flexColumnMinWidth,
    width: flexColumnWidth,
    flexColumn: true,
    className: `${rootClass}__expander`,
    Cell: (row: any) => renderExpanderCell(row.row, row.data.length - 1),
  }

  const getColorClassName = (text: string) => {
    return coloredLabels?.find((coloredLabel) => coloredLabel.text === text)?.className ?? ''
  }

  const renderExpanderCell = (row: Row, size: number) => {
    const text = row.values[columns[0].accessor]
    const colorClassName = coloredLabels ? getColorClassName(text) : ''
    return row.canExpand ? (
      <div
        tabIndex={0}
        data-test={`${dataTest}__expander-ctr-${row.canExpand ? 'parent' : 'child'}`}
        role={'button'}
        aria-label={`${dataTest}__expander`}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            row.toggleRowExpanded()
          }
        }}
        {...row.getToggleRowExpandedProps({
          style: {
            paddingLeft: `${row.depth * 2}rem`,
            marginRight: `-${row.depth * 2}rem`,
          },
        })}
      >
        <Svg
          className={row.isExpanded ? `${rootClass}__expand-toggled` : `${rootClass}__expand-toggle`}
          name={SvgNames.caretFillRight}
          type={SvgType.SMALLER_ICON}
        />

        <div className={`${rootClass}__expander-text`}>
          <Typography text={text} className={colorClassName} inline />
        </div>
      </div>
    ) : size === parseInt(row.id) ? (
      <div style={{ paddingLeft: `${row.depth * 2}rem` }}>
        <Typography text={text} className={`${rootClass}__expander-text`} inline weight={TextWeight.BOLD} />
      </div>
    ) : (
      <div style={{ paddingLeft: `${row.depth * 2}rem` }}>
        <Typography text={text} className={`${rootClass}__expander-text`} inline />
      </div>
    )
  }

  const enhancedColumns = useMemo(() => [expanderCol, ...columns], [])
  const tableInstance = useTable(
    {
      columns: enhancedColumns as Column<any>[],
      data,
      initialState,
    },
    useFlexLayout,
    useExpanded
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance
  const columnCount = enhancedColumns.length

  const getColumnAlignClass = (id: string, isTD = true, isLast = false) => {
    const column = enhancedColumns.find((col) => col.accessor === id)
    const base = `${rootClass}__${isTD ? 'td' : 'th'}`
    const last = isLast ? `${base}-last` : ''
    const extra = column && column.className ? column.className : ''
    const align = column ? (column.align ? `${rootClass}__${column.align}` : `${rootClass}__left`) : `${rootClass}__left`
    return `${base} ${last} ${align} ${extra}`
  }

  const renderCell = (cell: Cell, isLast: boolean, lastRow: boolean) => {
    const content =
      cell.column.id === 'expander' ? (
        cell.render('Cell')
      ) : lastRow ? (
        <Typography text={cell.render('Cell')} weight={TextWeight.BOLD} />
      ) : (
        <Typography text={cell.render('Cell')} />
      )

    if (isLast) {
      return (
        <div className={`${rootClass}__td-last-inner`}>
          <Typography text={content} />
        </div>
      )
    }

    return content
  }

  const rawTableProps = getTableProps()
  const style = { ...rawTableProps.style, minWidth: 'auto' }
  const tableProps = { ...rawTableProps, style }
  return (
    <div className={`${rootClass}__holder`} data-test={dataTest}>
      <div className={classNames(rootClass, className)} {...tableProps} ref={tableRef}>
        <div className={`${rootClass}__thead`}>
          {headerGroups.map((headerGroup, i) => {
            const rawHeaderGroupProps = headerGroup.getHeaderGroupProps()
            const style = { ...rawHeaderGroupProps.style, width: `auto`, minWidth: 'auto' }
            const headerGroupProps = { ...rawHeaderGroupProps, style }
            return (
              <div className={`${rootClass}__tr`} {...headerGroupProps} key={`trh-${i}`}>
                {headerGroup.headers
                  .filter((column) => column.id !== columns[0].accessor)
                  .map((column, idx) => {
                    const rawHeaderProps = column.getHeaderProps()
                    const style = {
                      ...rawHeaderProps.style,
                      // @ts-ignore
                      flex: `1 0 ${column.flexColumn ? 'auto' : `${column.maxWidth}px`}`,
                      // @ts-ignore
                      width: `${column.flexColumn ? 'auto' : `${column.maxWidth}px`}`,
                      // @ts-ignore
                      maxWidth: `${column.flexColumn ? flexColumnWidth : column.maxWidth}px`,
                    }
                    const headerProps = { ...rawHeaderProps, style }
                    return (
                      <th
                        {...headerProps}
                        className={classNames(getColumnAlignClass(column.id, false, idx === columnCount - 2))}
                        tabIndex={0}
                        key={`trh-${i}-${idx}`}
                      >
                        <Typography text={column.render('Header')} type={TextType.TABLE_HEADER} />
                      </th>
                    )
                  })}
              </div>
            )
          })}
        </div>
        <div className={`${rootClass}__tbody`} {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            const rawRowProps = row.getRowProps()
            const style = { ...rawRowProps.style, minWidth: 'auto' }
            const rowProps = { ...rawRowProps, style }
            return (
              <div
                {...rowProps}
                className={classNames(`${rootClass}__tr`, {
                  [`${rootClass}__row-last`]: i === rows.length - 1,
                  [`${rootClass}__row-selected`]: row.id.length === 1 && row.isExpanded,
                })}
                key={`tr-${i}`}
              >
                {row.cells
                  .filter((cell) => cell.column.id !== columns[0].accessor)
                  .map((cell, idx) => {
                    const rawCellProps = cell.getCellProps()
                    const style = {
                      ...rawCellProps.style,
                      // @ts-ignore
                      flex: `1 0 ${cell.column.flexColumn ? 'auto' : `${cell.column.maxWidth}px`}`,
                      // @ts-ignore
                      width: `${cell.column.flexColumn ? 'auto' : `${cell.column.maxWidth}px`}`,
                      // @ts-ignore
                      maxWidth: `${cell.column.flexColumn ? flexColumnWidth : cell.column.maxWidth}px`,
                    }
                    const cellProps = { ...rawCellProps, style }
                    return (
                      <div {...cellProps} className={getColumnAlignClass(cell.column.id, true, idx === columnCount - 2)} key={`td-${i}-${idx}`}>
                        {renderCell(cell, idx === columnCount - 2, rows.length - 1 === i)}
                      </div>
                    )
                  })}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default NestedTable
