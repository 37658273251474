import React, { FC, useCallback, useRef, useState } from 'react'

import Button, { ButtonType } from '@components/Button'
import InputTags from '@components/InputTags/InputTags'
import { CommonInputEmailTagsProps, InputTagsErrorsType } from '@components/InputTags/InputTags.utils'
import { LabelType, LabelV2 } from '@components/LabelV2/LabelV2'
import Modal, { ModalBody, ModalHeader } from '@components/Modal'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import Spinner from '@components/Spinner/Spinner'
import Svg, { SvgNames } from '@components/Svg'
import { SvgColor, SvgType } from '@components/Svg/Svg'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { SendTestEmailInput } from '@graphql/types/mutation-types'
import SendTestConfirmationModal, {
  SendTestConfirmationModalStatus,
} from '@src/pages/EmailComposer/EmailModals/components/SendTestEmailModal/components/SendTestConfirmationModal/SendTestConfirmationModal'
import SendTestEmailModalFooter from '@src/pages/EmailComposer/EmailModals/components/SendTestEmailModal/components/SendTestEmailModalFooter'
import SendTestEmailOptions from '@src/pages/EmailComposer/EmailModals/components/SendTestEmailModal/components/SendTestEmailOptions/SendTestEmailOptions'
import { SendTestEmailInfo } from '@src/pages/EmailComposer/EmailModals/components/SendTestEmailModal/graphQL/SendTestEmailModal.graphQL'
import { SendTestEmailModalContainerProps } from '@src/pages/EmailComposer/EmailModals/components/SendTestEmailModal/SendTestEmailModalContainer'
import {
  SendTestEmailOptionsRefType,
  TestListEmailsRefType,
} from '@src/pages/EmailComposer/EmailModals/components/SendTestEmailModal/utils/SendTestEmailModal.utils'
import { filterNotEmptyArray } from '@utils/array'
import { emailRegExp } from '@utils/formUtils'

import TestListModalContainer from './components/TestListModal/TestListModalContainer'
import { MANAGE_TEST_LIST_CONTACTS_URL } from './constants/SendTestEmailModal.constants'

import './SendTestEmailModal.css'

interface SendTestEmailModalProps extends SendTestEmailModalContainerProps, SendTestEmailInfo {
  userEmail: string
  onTestSend: (vars: Omit<SendTestEmailInput, 'id'>, uuidsForDisplayConditions: string[]) => Promise<boolean>
  loadingSend: boolean
  subject: string
  dynamicContentOptions: SelectV2SingleOption[]
  plainTextMode?: boolean
  hasPersonalization?: boolean
  dataTest?: string
}

const rootClass = 'send-test-email-modal'

const SendTestEmailModal: FC<SendTestEmailModalProps> = ({
  segments,
  onClose,
  userEmail,
  onTestSend,
  loading,
  loadingSend,
  subjectPrefix,
  subject,
  plainTextMode = false,
  dynamicContentOptions,
  hasPersonalization,
  dataTest = rootClass,
}) => {
  const { t } = useTranslation()
  const _userEmail = userEmail.toLowerCase()
  // boolean value will indicate if it's the logged-in user's email
  const additionalEmailsRef = useRef<{ [key: string]: boolean }>({ [_userEmail]: true })
  // string {[id]:email} pairs list
  const testListEmailsRef = useRef<TestListEmailsRefType>({})
  const optionsRef = useRef<SendTestEmailOptionsRefType>({ subjectPrefix, plainText: plainTextMode })
  const [inputTagsHasError, setInputTagsHasError] = useState<boolean>(false)
  const [emailsCount, setEmailsCount] = useState<number>(Object.keys(additionalEmailsRef.current).length)
  const [showTestListModal, setShowTestListModal] = useState<boolean>(false)
  const [sendStatus, setSendStatus] = useState<SendTestConfirmationModalStatus>()
  const [conditions, setConditions] = useState<SelectV2SingleOption[]>([])

  const handleTagsChange = useCallback(
    (emailTags: string[]) => {
      setEmailsCount(emailTags.length)
      const newAdditionalEmails: { [key: string]: boolean } = {}
      const newTestListEmails: { [key: string]: string } = {}
      emailTags.forEach((emailTag) => {
        const testListEmail = Object.entries(testListEmailsRef.current).find(([_, email]) => email === emailTag)
        if (testListEmail) {
          const testListEmailId = testListEmail[0]
          newTestListEmails[testListEmailId] = emailTag
          delete testListEmailsRef.current[testListEmailId]
        } else if (emailTag in additionalEmailsRef.current) {
          newAdditionalEmails[emailTag] = additionalEmailsRef.current[emailTag]
        } else {
          newAdditionalEmails[emailTag] = emailTag.toLowerCase() === _userEmail
        }
      })
      additionalEmailsRef.current = newAdditionalEmails
      testListEmailsRef.current = newTestListEmails
    },
    [_userEmail]
  )

  const handleErrorStatusChange = useCallback<(errors: InputTagsErrorsType) => void>(
    ({ hasValidationError, hasDuplicationError }) => setInputTagsHasError(hasDuplicationError || hasValidationError),
    []
  )

  const tagValidation = useCallback<(email: string) => string | undefined>(
    (email) => {
      if (!emailRegExp.test(email)) {
        return t('Email.Tag.Validation.Tooltip.Msg')
      }
    },
    [t]
  )

  const handleTestListModalFinish = useCallback(() => setShowTestListModal(false), [])
  const removeUserEmailFromList = useCallback(() => delete additionalEmailsRef.current[_userEmail], [_userEmail])
  const handleOpenTestListModal = useCallback(() => setShowTestListModal(true), [])

  const getTooltipInfo = useCallback<(email: string) => string | undefined>(
    (email) => {
      if (email.toLowerCase() === _userEmail) {
        return t('Logged in user’s email address')
      }
      if (email in additionalEmailsRef.current) {
        return t('Additional recipient')
      }
      return t('Test list recipient')
    },
    [_userEmail, t]
  )

  const handleSend = useCallback(() => {
    const variables = {
      ...optionsRef.current,
      contactIds: Object.keys(testListEmailsRef.current),
      hasSelf: additionalEmailsRef.current[_userEmail] || Object.values(testListEmailsRef.current).includes(_userEmail),
      emails: Object.entries(additionalEmailsRef.current)
        .filter(([email, isSelf]) => !isSelf && email)
        .map(([email]) => email),
    }

    const uuids = conditions.map((condition) => (condition.extraOptions?.uuid ? condition.extraOptions.uuid : undefined)).filter(filterNotEmptyArray)

    onTestSend(variables, uuids)
      .then((resSuccess) => setSendStatus(resSuccess ? SendTestConfirmationModalStatus.SUCCESS : SendTestConfirmationModalStatus.ERROR))
      .catch(() => setSendStatus(SendTestConfirmationModalStatus.ERROR))
  }, [onTestSend, _userEmail, conditions])

  const handleCheckboxChange = useCallback((plainText: boolean) => (optionsRef.current.plainText = plainText), [])

  if (sendStatus && !loadingSend) {
    return <SendTestConfirmationModal onResend={handleSend} onCancel={onClose} status={sendStatus} />
  }

  const renderTestListModal = () => (
    <TestListModalContainer
      hasUserEmailDefault={additionalEmailsRef.current[_userEmail]}
      onFinish={handleTestListModalFinish}
      onRemoveUserEmail={removeUserEmailFromList}
      selectedEmailsRef={testListEmailsRef}
      segments={segments}
      loading={loading}
      userEmail={additionalEmailsRef.current[_userEmail] ? _userEmail : undefined}
    />
  )

  return (
    <>
      {!showTestListModal ? (
        <Modal
          className={rootClass}
          dataTest={dataTest}
          isOpen
          paddingV2
          header={
            <ModalHeader className={`${rootClass}__header`} dataTest={`${dataTest}-header`} headerType={ModalHeaderType.Form}>
              {t('Send test email')}
            </ModalHeader>
          }
        >
          <ModalBody className={`${rootClass}__body`}>
            {loadingSend ? (
              <Spinner className={`${rootClass}__spinner`} text="Send.Test.Email.Spinner.Text" dataTest={`${dataTest}-spinner`} />
            ) : (
              <>
                {dynamicContentOptions.length > 0 && (
                  <SelectV2
                    insideModal
                    isClearable
                    useToggleTip
                    isSearchable={false}
                    label={t('Send.Test.Dynamic.Select')}
                    className={`${rootClass}__dynamic-content`}
                    tooltipMessage={t('Send.Test.Dynamic.Select.Tooltip')}
                    placeholder={'Select display conditions'}
                    onChangeMultiple={(option) => setConditions(option)}
                    options={dynamicContentOptions}
                    inputIcon={SvgNames.arrowDivergeGreyLight}
                  />
                )}
                <Typography
                  text={t('Send.Test.Email.Description')}
                  type={TextType.BODY_TEXT_LIGHT}
                  tagComponents={{
                    TextLink: <TextLink link={MANAGE_TEST_LIST_CONTACTS_URL} size={TextLinkSize.LARGE} hideIcon />,
                  }}
                  className="push-up-x4"
                  dataTest={`${dataTest}-subHeader`}
                />
                <div className={`${rootClass}__body__email-count`}>
                  <LabelV2 label={t('Send.Test.Email.Tags.Label')} labelType={LabelType.medium} dataTest={`${dataTest}-tags-label`} />
                  <Typography
                    type={TextType.BODY_TEXT_SMALL_LIGHT}
                    text={t(`Send.Test.Email.Tags.Count`, { count: emailsCount })}
                    dataTest={`${dataTest}-tags-count`}
                  />
                </div>
                <InputTags
                  onChange={handleTagsChange}
                  onErrorStatusChange={handleErrorStatusChange}
                  tagValidation={tagValidation}
                  getTooltipInfo={getTooltipInfo}
                  initialValue={[...Object.keys(additionalEmailsRef.current), ...Object.values(testListEmailsRef.current)]}
                  noMarginBottom
                  errorMessage={t('Send.Test.Email.Error.Text')}
                  duplicationErrorTooltip={t('Send.Test.Email.Duplication.Tooltip')}
                  duplicationNotCaseSensitive
                  {...CommonInputEmailTagsProps}
                  dataTest={`${dataTest}-input-tags`}
                />
                <Typography
                  text={t('Send.Test.Email.Helper.Text')}
                  type={TextType.NORMAL_TEXT_GRAY}
                  className="push-down"
                  dataTest={`${dataTest}-helper-text`}
                />
                <Button
                  buttonType={ButtonType.OUTLINE}
                  onClick={handleOpenTestListModal}
                  className="push-down-x2 push-up-x4"
                  dataTest={`${dataTest}-button-add`}
                >
                  <Svg name={SvgNames.plus} fill={SvgColor.TEXT_TEAL} type={SvgType.STANDARD_BUTTON_ICON} />
                  {t('Add test list recipients')}
                </Button>
                <SendTestEmailOptions
                  loading={loading}
                  optionsRef={optionsRef}
                  subjectPrefix={subjectPrefix}
                  subject={subject}
                  dataTest={`${dataTest}-options`}
                />
              </>
            )}
          </ModalBody>
          <SendTestEmailModalFooter
            handleCheckboxChange={handleCheckboxChange}
            onClose={onClose}
            onSend={handleSend}
            disableSend={loading || inputTagsHasError || !emailsCount || loadingSend}
            zeroRecipients={!emailsCount}
            hideCheckbox={loadingSend}
            plainTextMode={plainTextMode}
            showNext={hasPersonalization}
            className={`${rootClass}__footer`}
            defaultChecked={optionsRef.current.plainText}
            dataTest={`${rootClass}-footer`}
          />
        </Modal>
      ) : (
        renderTestListModal()
      )}
    </>
  )
}

export default SendTestEmailModal
