import React from 'react'
import { Row } from 'react-table'

import { TFunction } from 'i18next'

import { ListPickerModal } from '@complex/ListPickerModalV2/ListPickerModal'
import { MIRRORED_LISTS_ID } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { List } from '@interface/foldersLists/List'
import { ClassicModalHandlerProps, ClassicModalType } from '@src/components/ClassicModalHandler/ClassicModalHandler'
import { BouncesAndOptOutsModal } from '@src/pages/Contacts/BouncesAndOptOutsModal/BouncesAndOptOutsModal'
import GenerativeEmailModal from '@src/pages/Content/Email/Editor/components/GenerativeEmailModal/GenerativeEmailModal'
import ManageContactsOrStartModalWrapper from '@src/pages/programs/dashboard/components/ProgramPerformance/components/ManageContactsOrStartModal/ManageContactsorStartModalWrapper'
import iframe from '@utils/iframe/iframe'

const submitListPickerLists = (sourceName: string, lists: string[] | List[], getRootIFrameOnly?: boolean) =>
  iframe.postClassicMessage({ [sourceName]: lists, getRootIFrameOnly })

const submitManageContactsOrStartModalWrapper = (sourceName: string, isAdd: boolean) => iframe.postMessage({ [sourceName]: isAdd })

const submitAIGeneratedText = (sourceName: string, text: string) => iframe.postClassicMessage({ [sourceName]: text })

const disableItem = (id: string): boolean => {
  return !id.startsWith(MIRRORED_LISTS_ID)
}

const showDisableTooltip = (row: Row<ItemDto>, t: TFunction) => {
  return disableItem(row.original.id) ? t('This list is not compatible with Act-On Contacts forms.') : undefined
}

export const renderModalUtils = ({ showModal, message, onClose, type, t }: ClassicModalHandlerProps) => {
  const rootIframe = document.getElementById('root-iframe')
  !!rootIframe && rootIframe.focus()
  const checkDisable = message?.sourceName === 'formSubmitList' && message?.hasUnifiedFormSubmissions
  const modals = {
    [ClassicModalType.LIST_PICKER]: (
      <ListPickerModal
        isOpen
        closeModal={onClose}
        defaultSelectedLists={message?.selectedListsIds}
        disabledLists={message?.disabledListsIds}
        disabledListTooltipText={message?.disabledListTooltipText}
        isListsOnly={message?.isListsOnly}
        multiSelect={message?.multiSelect}
        hideLegacyLists={message?.hideLegacyLists}
        hideUCL={message?.hideUCL}
        initialList={message?.initialList}
        hasUnifiedFormSubmissions={message?.hasUnifiedFormSubmissions}
        submitLists={(lists: List[]) => {
          submitListPickerLists(
            message?.sourceName as string,
            message?.sourceName === 'formSubmitList' ? lists : lists.map(({ id }) => id),
            message?.getRootIFrameOnly
          )
          onClose()
        }}
        disableRowByCriteria={checkDisable ? (value) => disableItem(value.id) : undefined}
        showTooltipByCriteria={checkDisable ? (row) => showDisableTooltip(row, t) : undefined}
        restrictedToLegacyLists={message?.restrictedToLegacyLists}
        hideLegacyListToggle={message?.sourceName === 'formSubmitList'}
      />
    ),
    [ClassicModalType.MANAGE_CONTACTS_OR_START_MODAL]: (
      <ManageContactsOrStartModalWrapper
        isOpen
        isStart={message?.isStart}
        pendingContacts={message?.pendingContacts}
        onSubmit={(isAdd) => {
          submitManageContactsOrStartModalWrapper(message?.sourceName as string, isAdd)
          onClose()
        }}
        onClose={() => onClose()}
      />
    ),
    [ClassicModalType.AI_GENERATED_EMAIL]: (
      <GenerativeEmailModal
        isOpen
        onAction={(prompt: string) => {
          submitAIGeneratedText(message?.sourceName, prompt)
          onClose()
        }}
        onClose={onClose}
        subject={message?.subjectLine ?? ''}
      />
    ),
    [ClassicModalType.OPT_IN_EMAIL]: <BouncesAndOptOutsModal isOpen={showModal} onCloseModal={onClose} />,
  }
  return modals[type]
}
