import React, { useContext } from 'react'

import Caution from '@components/Caution/Caution'
import Modal, { ModalBody } from '@components/Modal'
import ModalFooterV2 from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { useTranslation } from '@utils/const/globals'

import './SaveConfirmationModal.css'

import { CommonComposerTab, SaveComposerCommonType } from '@utils/composer/commonComposer/CommonComposer.context'

const rootClass = 'save-confirmation-modal'

const SaveConfirmationModal = ({ saveComposerEmail }: { saveComposerEmail: SaveComposerCommonType }) => {
  const {
    values,
    api: { update, onTabChange, onPreview },
  } = useContext(EmailComposerContext)

  const { t } = useTranslation()

  const handleSave = () => {
    saveComposerEmail(values).then(() => {
      onTabChange(CommonComposerTab.REVIEW)
      onPreview()
    })
    update({ isCustomerCareSaveModal: false })
  }

  return (
    <Modal isOpen paddingV2 className={rootClass}>
      <ModalBody>
        <Svg name={SvgNames.warning} type={SvgType.VERY_LARGE_ICON} />
        <Typography text={t('EmailComposer.Save.Confirmation.Modal.Title')} type={TextType.MODAL_HEADLINE_CONFIRMATION} weight={TextWeight.MEDIUM} />
        <Typography text={t('EmailComposer.Save.Confirmation.Modal.Description')} type={TextType.BODY_TEXT_LIGHT} />
        <Caution
          message={
            <Typography text={t(`EmailComposer.Save.Confirmation.Modal.Caution`)} tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }} />
          }
        />
      </ModalBody>
      <ModalFooterV2
        buttons={{ cancelButtonLabel: 'Cancel', actionButtonLabel: 'Save and continue', actionButtonOnClick: handleSave }}
        onClose={() => {
          update({ isCustomerCareSaveModal: false })
          onTabChange(CommonComposerTab.REVIEW)
          onPreview()
        }}
      />
    </Modal>
  )
}

export default SaveConfirmationModal
