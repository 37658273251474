import React, { FC, Key, ReactElement, useState } from 'react'

import classNames from 'classnames'

import ListPickerModal from '@complex/ListPickerModalV2/ListPickerModal'
import { PreProcessedList } from '@complex/ListPickerModalV2/utils/interfaces/ListPickerModalInterfaces'
import InfoAction from '@components/InfoAction/InfoAction'
import { renderLoader } from '@components/Loader/Loader'
import Modal, { ModalBody } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import ModalFooterV2 from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import ModalHeaderV2 from '@components/Modal/components/ModalHeaderV2/ModalHeaderV2'
import RadioCard, { RadioCardProps } from '@components/RadioCard/RadioCard'
import RadioCardGroup from '@components/RadioCardGroup/RadioCardGroup'
import { SegmentSaveModal } from '@components/SegmentSaveModal/SegmentSaveModal'
import SuccessModal from '@components/SuccessModal/SuccessModal'
import { SvgColor, SvgNames } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import { Folder } from '@interface/Folder'
import { List } from '@interface/foldersLists/List'
import { SegmentInput } from '@interface/Segment'
import { filterNotEmptyArray } from '@utils/array'
import { ItemType } from '@utils/categorization'
import { Segment } from '@utils/contactSegments/contactSegments.utils'
import { useCategorizationService } from '@utils/hooks/microservices/useCategorizationService'

import './AddToSegmentModal.css'

export interface AddToSegmentModalProps {
  className?: string
  dataTest?: string
  onClose: VoidFunction
  segment: Segment
  isOpen: boolean
}

export enum SegmentCreationOption {
  NEW_SEGMENT = 'newSegment',
  NEW_SUBSEGMENT = 'newSubsegment',
  EXISTING_SEGMENT = 'existingSegment',
}

const rootClass = 'add-to-segment-modal'

const AddToSegmentModal: FC<AddToSegmentModalProps> = (props: AddToSegmentModalProps) => {
  const { dataTest = rootClass, className = '', onClose, isOpen, segment } = props

  const { t } = useTranslation()

  const [folders, setFolders] = useState<Folder[]>()
  const [isCreatingNewSegment, setIsCreatingNewSegment] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isSelectingExistingSegment, setIsSelectingExistingSegment] = useState(false)
  const [isShowingSuccessModal, setIsShowingSuccessModal] = useState(false)
  const [selectedExistingSegment, setSelectedExistingSegment] = useState<List>()
  const [selectedOption, setSelectedOption] = useState<SegmentCreationOption>(SegmentCreationOption.NEW_SEGMENT)
  const [tags, setTags] = useState<LabelDto[]>()

  const {
    allActOnContactsSegment,
    getAllFolders,
    getAllTags,
    loading: isLoadingAllContactsSegment,
  } = useCategorizationService({ fetchAllActonContactsSegment: true })

  const hasNewSubsegmentOption = allActOnContactsSegment?.externalId !== segment.externalId
  const segmentInput: SegmentInput = {
    name: '',
    description: '',
    folderId: segment.folderId,
    labels: segment.tags,
    isDirectSelect: true,
  }

  const getTags = async (): Promise<LabelDto[] | undefined> => {
    if (!tags) {
      return await getAllTags({ type: ItemType.SEGMENT })
    }
  }

  const getFolders = async (): Promise<Folder[] | undefined> => {
    if (!folders) {
      const fetchedFolders = await getAllFolders({ type: ItemType.SEGMENT })
      return fetchedFolders?.filter(filterNotEmptyArray) as Folder[]
    }
  }

  const fetchAssets = async () => {
    setIsLoading(true)
    try {
      const [foldersResponse, tagsResponse] = await Promise.all([getFolders(), getTags()])
      if (foldersResponse) {
        setFolders(foldersResponse.filter(filterNotEmptyArray) as Folder[])
      }
      if (tagsResponse) {
        setTags(tagsResponse)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const onRadioCardSelect = (selectedCard: Key) => {
    const newSelectedOption = selectedCard as SegmentCreationOption
    if (hasNewSubsegmentOption || (!hasNewSubsegmentOption && newSelectedOption !== SegmentCreationOption.NEW_SUBSEGMENT)) {
      setSelectedOption(newSelectedOption)
    }
  }

  const onNextClick = async () => {
    if (selectedOption === SegmentCreationOption.EXISTING_SEGMENT) {
      setIsSelectingExistingSegment(true)
    } else {
      if (!folders || !tags) {
        await fetchAssets()
      }
      setIsCreatingNewSegment(true)
    }
  }

  const onCreateTag = (newTag: LabelDto) => {
    if (tags) {
      setTags([...tags, newTag])
    }
  }

  const onNewSegmentModalClose = () => {
    setIsCreatingNewSegment(false)
  }

  const onNewSegmentModalSave = async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000))
  }

  const onExistingModalSave = async (lists: List[]) => {
    setSelectedExistingSegment(lists[0])
    await new Promise((resolve) => setTimeout(resolve, 2000))
  }

  /**
   * Higher-order function to handle form submission.
   *
   * This function wraps a given callback function and manages the state changes
   * for creating a new segment, selecting an existing segment, and saving.
   * It ensures that the saving state is set to true before the callback is executed
   * and resets the state after the callback is completed.
   *
   * @template T - The type of the arguments passed to the callback function.
   * @template R - The return type of the callback function.
   * @param {(...args: T) => Promise<R>} callback - The callback function to be executed.
   * @returns A new function that wraps the callback with state management.
   */
  const handleSubmit = <T extends unknown[], R>(callback: (...args: T) => Promise<R>) => {
    return async (...args: T): Promise<R> => {
      if (isCreatingNewSegment) {
        setIsCreatingNewSegment(false)
      } else {
        setIsSelectingExistingSegment(false)
      }
      setIsSaving(true)

      try {
        return await callback(...args)
      } finally {
        setIsSaving(false)
        setIsShowingSuccessModal(true)
      }
    }
  }

  const onListPickerClose = () => {
    setIsSelectingExistingSegment(false)
  }

  /**
   * Determines if a row in the ListPicker should be disabled.
   *
   * The row is disabled if:
   * - The list type is not 'Direct Select'.
   * - The list has a parent and the parent is different from the parent of the current segment.
   * - The list ID is the same as the external ID of the current segment.
   *
   * @param {PreProcessedList} row - The list row to check.
   * @returns {boolean} - Returns true if the row should be disabled, otherwise false.
   */
  const isListPickerRowDisabled = (row: PreProcessedList): boolean => {
    return row.type !== 'Direct Select' || (!!row.parent && row.parent !== segment.parent) || row.id === segment.externalId
  }

  const renderListPickerBanner = () => <InfoAction svgName={SvgNames.lightBulb} message={t('AddToSegmentModal.ListPicker.Banner')} />

  if (isLoading || isLoadingAllContactsSegment || isSaving) {
    return renderLoader('loader--white-background')
  }

  if (isShowingSuccessModal) {
    return (
      <SuccessModal
        dataTest={`${dataTest}-success-modal`}
        headline={{ text: t('AddToSegmentModal.Success.Headline', { context: selectedOption }) }}
        body={{
          text: t('AddToSegmentModal.Success.Body', { ...selectedExistingSegment, context: selectedOption }),
          tagProps: {
            medium: { weight: TextWeight.MEDIUM },
            bold: { weight: TextWeight.BOLD },
          },
          inline: true,
        }}
        secondaryButtonText={t('AddToSegmentModal.Success.SecondaryButton', { context: selectedOption })}
        onPrimaryClick={onClose}
        // TODO: Implement onSecondaryClick
        onSecondaryClick={() => undefined}
        primaryButtonText={t('Continue editing')}
        isOpen
      />
    )
  }

  if (isSelectingExistingSegment) {
    return (
      <ListPickerModal
        dataTest={`${dataTest}-list-picker-modal`}
        headerTitle={t('AddToSegmentModal.ListPicker.Title')}
        submitButtonText={t('AddToSegmentModal.ListPicker.Submit')}
        customBanner={renderListPickerBanner()}
        closeModal={onListPickerClose}
        submitLists={handleSubmit(onExistingModalSave)}
        disableRowByCriteria={isListPickerRowDisabled}
        multiSelect={false}
        submitDisabledWithoutSelection
        hideLegacyLists
        isOpen
      />
    )
  }

  if (isCreatingNewSegment && folders && tags) {
    return (
      <SegmentSaveModal
        dataTest={`${dataTest}-segment-save-modal`}
        segment={selectedOption === SegmentCreationOption.NEW_SUBSEGMENT ? segmentInput : undefined}
        folders={folders}
        onClose={onNewSegmentModalClose}
        onSave={handleSubmit(onNewSegmentModalSave)}
        onCreateTag={onCreateTag}
        tags={tags}
        hasDescription
        isOpen
      />
    )
  }

  const renderRadioCards = (option: SegmentCreationOption): ReactElement<RadioCardProps> => {
    const iconByOption: { [key in SegmentCreationOption]: SvgNames } = {
      [SegmentCreationOption.NEW_SEGMENT]: SvgNames.plusLight,
      [SegmentCreationOption.NEW_SUBSEGMENT]: SvgNames.createSubsegmentNoFill,
      [SegmentCreationOption.EXISTING_SEGMENT]: SvgNames.arrowMerge,
    }
    const icon = iconByOption[option]
    return (
      <RadioCard
        dataTest={`${rootClass}-radio-card-${option}`}
        className={`${rootClass}__radio-card`}
        baseSvgFill={SvgColor.BACKGROUND_GRAY}
        hoverSvgFill={SvgColor.TEXT_TEAL}
        isHugeIcon={false}
        key={option}
        title={t('AddToSegmentModal.Options.Title', { context: option })}
        description={t('AddToSegmentModal.Options.Description', { ...segment, context: option })}
        svgName={icon}
        hoverSvgName={icon}
      />
    )
  }

  const header = <ModalHeaderV2 headerText={t('AddToSegmentModal.Header')} headerType={ModalHeaderType.Form} className={`${rootClass}__header`} />

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header} paddingV2>
      <ModalBody className={`${rootClass}__body`}>
        <Typography text={t('AddToSegmentModal.Body')} type={TextType.BODY_TEXT_LIGHT} inline />
        <RadioCardGroup className={`${rootClass}__radio-card-container`} onSelect={onRadioCardSelect} selectedOption={selectedOption}>
          {renderRadioCards(SegmentCreationOption.NEW_SEGMENT)}
          {hasNewSubsegmentOption ? renderRadioCards(SegmentCreationOption.NEW_SUBSEGMENT) : <></>}
          {renderRadioCards(SegmentCreationOption.EXISTING_SEGMENT)}
        </RadioCardGroup>
      </ModalBody>
      <ModalFooterV2
        footerType={ModalFooterType.Form}
        className={`${rootClass}__footer`}
        dataTest={`${dataTest}-footer`}
        buttons={{ actionButtonLabel: t('Next'), actionButtonOnClick: onNextClick, cancelButtonLabel: t('Cancel') }}
        onClose={onClose}
      />
    </Modal>
  )
}

export default AddToSegmentModal
