import { gql } from '@apollo/client'

export default gql`
  query getEmailContentValidations($html: String, $plainText: String) {
    getEmailContentValidations(html: $html, plainText: $plainText) {
      hasHtmlOptOut
      hasTextOptOut
    }
  }
`
