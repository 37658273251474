import React, { FC, useMemo, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Svg, { SvgNames } from '@components/Svg'
import { TableV2 } from '@components/TableV2/TableV2'
import { RowAction, TableV2RowData } from '@components/TableV2/tableV2TS/interfaces'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { ModalBodyStyle, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { InboxAssetResponse } from '@graphql/types/query-types'
import {
  GetPreviewParam,
  PreviewAndImportModalContentProps,
  TransformedPreviewData,
} from '@src/pages/MarketingNetwork/Inbox/components/PreviewAndImportModal/PreviewAndImport.types'
import PreviewAndImportModalContainer from '@src/pages/MarketingNetwork/Inbox/components/PreviewAndImportModal/PreviewAndImportModalContainer'
import { ImportDeclineOptions, NoPreviewTypes, PreviewAssetType } from '@src/pages/MarketingNetwork/Inbox/Inbox.constants'
import { typeToText } from '@src/pages/MarketingNetwork/Inbox/Inbox.utils'
import { CellContext, Row } from '@tanstack/react-table'
import { logNewRelicError } from '@utils/new-relic.utils'
import { titleCase } from '@utils/strings'

import './InboxImportDeclineModal.css'

export interface InboxImportDeclineModalDataProps {
  isOpen: boolean
  assets: PreviewAndImportModalContentProps[]
  actionType: ImportDeclineOptions
  className?: string
  dataTest?: string
}

interface InboxImportDeclineModalProps extends InboxImportDeclineModalDataProps {
  onClose: () => void
  onAction: () => void
  getPreviewItem: (item: GetPreviewParam) => Promise<TransformedPreviewData>
}

const MIN_TOOLTIP_LENGTH = 25

export const renderCell = (text: string) =>
  text.length > MIN_TOOLTIP_LENGTH ? (
    <Tooltip trigger={<Typography text={text} type={TextType.BODY_TEXT} />}>{text}</Tooltip>
  ) : (
    <Typography text={text} type={TextType.BODY_TEXT} />
  )

const rootClass = 'inbox-import-decline-modal'

interface InboxImportDeclineModalRow {
  id: string
  title: string
  type: PreviewAssetType
}

type InboxImportDeclineModalTableRow = InboxImportDeclineModalRow & TableV2RowData<InboxImportDeclineModalRow>

const InboxImportDeclineModal: FC<InboxImportDeclineModalProps> = (props: InboxImportDeclineModalProps) => {
  const { actionType, assets, dataTest = rootClass, className = '', onAction, onClose, isOpen, getPreviewItem } = props
  const [acting, setActing] = useState(false)
  const [loadingPreviewId, setLoadingPreviewId] = useState('')

  const [previewAsset, setPreviewAsset] = useState<InboxAssetResponse>()

  const { t } = useTranslation()

  const actionWord = useMemo(() => titleCase(actionType), [actionType])

  const tableData: InboxImportDeclineModalTableRow[] = useMemo(() => {
    return assets
      .map(
        (asset) =>
          ({
            id: asset.id,
            type: asset.type as PreviewAssetType,
            title: asset.title,
          } as InboxImportDeclineModalTableRow)
      )
      .sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1))
  }, [assets])

  const tableColumns = useMemo(
    (): ColumnDefWithAdditionalProps<InboxImportDeclineModalTableRow>[] => [
      {
        accessorKey: 'title',
        header: 'Asset Name',
        cell: (cell: CellContext<InboxImportDeclineModalTableRow, any>) => renderCell(cell.getValue()),
        sortingFn: 'alphanumeric',
        textAlign: 'left',
        minSize: 120,
        maxSize: 120,
      },
      {
        accessorKey: 'type',
        header: 'Asset Type',
        cell: (cell: CellContext<InboxImportDeclineModalTableRow, any>) => renderCell(typeToText(cell.getValue(), false)),
        sortingFn: 'alphanumeric',
        textAlign: 'left',
        minSize: 120,
        maxSize: 120,
      },
    ],
    [assets]
  )

  const handleAction = () => {
    setActing(true)
    onAction()
  }

  const handleClose = () => {
    onClose()
  }

  const onShowPreview = async (id?: string) => {
    if (id) {
      const asset = assets.find((asset) => asset.id === id)
      if (asset) {
        setLoadingPreviewId(asset.id)
        getPreviewItem(asset)
          .then((data: TransformedPreviewData) => {
            if (data) {
              const newData = {
                ...asset,
                assets: data.assets,
                changeOrReplace: data.changeOrReplace,
                previewInfo: data.previewInfo,
              }
              setPreviewAsset(newData)
            }
          })
          .catch((error) => logNewRelicError(error))
          .finally(() => setLoadingPreviewId(''))
      }
    } else {
      setPreviewAsset(undefined)
      if (assets.length === 1) {
        onClose()
      }
    }
  }

  const header = (
    <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__header`}>
      {t('Inbox.ImportDeclineModal.Title', { word: actionWord, count: assets.length })}
    </ModalHeader>
  )

  const subheader =
    actionType === ImportDeclineOptions.IMPORT
      ? t('Inbox.ImportDeclineModal.Subheader.Import', { count: assets.length })
      : t('Inbox.ImportDeclineModal.Subheader.Decline', { count: assets.length })

  const tableRowActions: RowAction<InboxImportDeclineModalTableRow>[] = [
    {
      label: 'Preview',
      icon: (row) => {
        if (row?.original.id === loadingPreviewId) {
          return <Loader loaderType={LoaderTypes.row} />
        }
        return <Svg name={SvgNames.previewOn} />
      },
      hidden: (row: Row<InboxImportDeclineModalTableRow> | Row<InboxImportDeclineModalTableRow>[]) =>
        NoPreviewTypes.includes((row as Row<any>).original.type as PreviewAssetType),
      onClick: (row: Row<InboxImportDeclineModalTableRow>) => {
        onShowPreview(row.original.id)
      },
    },
  ]

  const renderTable = () => <TableV2 data={tableData} columns={tableColumns} rowActions={tableRowActions} withoutBorders />

  const renderPreview = () =>
    previewAsset && (
      <PreviewAndImportModalContainer
        {...previewAsset}
        isOpen={!!previewAsset}
        showAction={assets.length === 1}
        actionType={actionType}
        onClose={() => onShowPreview()}
        onAction={onAction}
      />
    )

  return (
    <>
      {renderPreview()}
      <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
        <ModalBody className={`${rootClass}__body`}>
          <Typography text={subheader} {...ModalBodyStyle} />
          {renderTable()}
        </ModalBody>
        <ModalFooter footerType={ModalFooterType.Form} className={`${rootClass}__footer`}>
          <Button buttonType={ButtonType.TERTIARY} onClick={handleClose} dataTest={`${dataTest}-button-tertiary`}>
            {t('Cancel')}
          </Button>
          <Button
            buttonType={actionType === ImportDeclineOptions.IMPORT ? ButtonType.PRIMARY : ButtonType.DELETE}
            onClick={handleAction}
            disabled={acting}
            dataTest={`${dataTest}-button-primary`}
          >
            {t('Inbox.ImportDeclineModal.Action', { word: actionWord, count: assets.length })}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default InboxImportDeclineModal
