import React from 'react'

import { TFunction } from 'i18next'

import SplitButton, { SplitButtonOptionsProps } from '@components/SplitButton/SplitButton'
import { SvgNames } from '@components/Svg'
import { RetrieveMediaFilesByFolderQuery } from '@graphql/types/query-types'
import { Folder } from '@utils/interface/Folder'
import { MediaFileFlow } from '@utils/media'

import { MediaItem } from './MediaPickerModal.context'

export enum MediaPickerModalType {
  JS = 'js',
  CSS = 'css',
}

export const getMediaActions = (t: TFunction, onAction: (action: MediaFileFlow) => void, userAllowedToCreate: boolean): SplitButtonOptionsProps[] => [
  {
    key: 'upload',
    title: t('AssetPicker.Media.Action.Upload'),
    icon: SvgNames.fileUploadGray,
    disabled: !userAllowedToCreate,
    disabledTooltip: t('Ask your administrator for permission to do this'),
    onClick: () => onAction(MediaFileFlow.UPLOAD),
  },
  {
    key: 'link',
    title: t('AssetPicker.Media.Action.Add'),
    icon: SvgNames.url,
    disabled: !userAllowedToCreate,
    disabledTooltip: t('Ask your administrator for permission to do this'),
    onClick: () => onAction(MediaFileFlow.LINK),
  },
]

export const renderMediaActions = (t: TFunction, onAction: (action: MediaFileFlow) => void, userAllowedToCreate: boolean) => {
  return <SplitButton options={getMediaActions(t, onAction, userAllowedToCreate)} />
}

export const getMediaItemsFromResponse = (response: RetrieveMediaFilesByFolderQuery) => {
  // We don't need the Favorites folder and it causes duplicate entries
  const folderData = response.retrieveMediaFilesByFolder.folders
    .filter((folder) => folder.name !== 'Favorites')
    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))

  const folders: Folder[] = folderData.map((folder, index) => ({
    id: folder.id ?? -1,
    name: folder.isDefault ? 'Default Folder' : folder.name,
    itemCount: folder.entries.length,
    parentId: -1,
    position: index,
    subFolders: [],
  }))

  const items: MediaItem[] = folderData.flatMap((folder) => {
    return folder.entries.map(
      (item): MediaItem => ({
        ...{ ...item, size: Number(item.size) },
        folderId: folder.id ?? -1,
      })
    )
  })

  return { folders, items }
}
