import React, { FC } from 'react'

import classNames from 'classnames'

import InfoHoverCard from '@components/InfoHoverCard/InfoHoverCard'
import InfoHoverHeader from '@components/InfoHoverHeader/InfoHoverHeader'
import { SvgNames, SvgType } from '@components/Svg'
import Svg from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ProgramScheduleType } from '@src/pages/programs/dashboard/components/ProgramSettings/ProgramSettings'
import { getStandardFormattedDate } from '@utils/date'

import './AutomatedProgramsInfoHover.css'

export interface AutomatedProgramsInfoHoverProps {
  name: string
  lastUpdated: number
  updatedBy: string
  description: string
  scheduleType: ProgramScheduleType
  scheduleInterval: number
  scheduleWeekdaysOnly: boolean
  nextScheduledAddDate: string
  className?: string
  dataTest?: string
}

const rootClass = 'automated-programs-info-hover'

const AutomatedProgramsInfoHover: FC<AutomatedProgramsInfoHoverProps> = (props: AutomatedProgramsInfoHoverProps) => {
  const {
    name,
    lastUpdated,
    updatedBy,
    description,
    scheduleType,
    scheduleInterval,
    scheduleWeekdaysOnly,
    nextScheduledAddDate,
    dataTest = rootClass,
    className = '',
  } = props
  const { t } = useTranslation()
  const isScheduled = [ProgramScheduleType.WEEKLY, ProgramScheduleType.MONTHLY, ProgramScheduleType.DAILY].indexOf(scheduleType) > -1
  const lastModifiedText =
    updatedBy === ''
      ? t('AutomatedProgramsInfoHover.LastModified', { relativeUpdated: getStandardFormattedDate(lastUpdated, true) })
      : t('AutomatedProgramsInfoHover.LastModified.WithName', { relativeUpdated: getStandardFormattedDate(lastUpdated, true), updatedBy })

  const header = (
    <div className={`${rootClass}__header`}>
      <InfoHoverHeader
        title={
          <Typography text={name} weight={TextWeight.MEDIUM} type={TextType.BODY_TEXT_WHITE} className={classNames({ ['ellip-clamp']: true })} />
        }
      />
      <div className={`${rootClass}__modified`}>
        <Svg name={SvgNames.lastModified} className={`${rootClass}__refresh`} type={SvgType.ICON} />
        <Typography
          text={lastModifiedText}
          type={TextType.BODY_TEXT_LIGHTEST_GRAY_SMALL}
          tagProps={{ bold: { weight: TextWeight.MEDIUM, inline: true, type: TextType.BODY_TEXT_WHITE_SMALL } }}
        />
      </div>
    </div>
  )

  const dailyFrequencyText = `${t('Contacts enter the program')} ${
    scheduleInterval === 0 ? scheduleType?.toLowerCase() : `${t('every')} ${scheduleInterval} ${t('hours')}`
  }${scheduleWeekdaysOnly ? ` ${t('only on weekdays')}` : ''}`

  const nonDailyFrequencyText = `${t('Contacts enter the program')} ${scheduleType?.toLowerCase()}`

  const scheduleTooltip = (
    <div>
      <Typography text={t('There are new contacts waiting to enter the program.')} type={TextType.BODY_TEXT_WHITE} weight={TextWeight.BOLD} inline />{' '}
      <Typography
        text={`${scheduleType === ProgramScheduleType.DAILY ? dailyFrequencyText : nonDailyFrequencyText}. ${t(
          'New contacts will enter the program on'
        )} ${nextScheduledAddDate}.`}
        type={TextType.BODY_TEXT_WHITE}
        inline
      />
    </div>
  )

  const scheduleText = isScheduled
    ? 'Pending contacts added on a schedule'
    : scheduleType === ProgramScheduleType.TRIGGERED
    ? 'Pending contacts added immediately'
    : 'Pending contacts added manually'

  const schedule = (
    <div className={`${rootClass}__schedule-ctr`}>
      <Typography
        text={t(scheduleText)}
        type={TextType.BODY_TEXT_SMALL}
        inline
        dataTest={`${dataTest}-schedule-text`}
        className={`${rootClass}__schedule`}
      />
      {isScheduled && <Tooltip trigger={<Svg name={SvgNames.info} type={SvgType.SMALLER_ICON} />}>{scheduleTooltip}</Tooltip>}
    </div>
  )

  const renderBody = () =>
    description ? (
      <>
        <Typography text={t('About this program')} type={TextType.TABLE_HEADER} className={`${rootClass}__body-title`} />
        <Typography text={description} type={TextType.BODY_TEXT_SMALL} />
      </>
    ) : undefined

  return (
    <InfoHoverCard
      header={header}
      footer={schedule}
      sideOffset={2}
      openDelay={350}
      closeDelay={150}
      className={classNames(rootClass, className, {
        [`${rootClass}-scroll-hide`]: !description,
      })}
      data-test={dataTest}
    >
      {renderBody()}
    </InfoHoverCard>
  )
}

export default AutomatedProgramsInfoHover
