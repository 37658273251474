import React, { Dispatch, SetStateAction } from 'react'
import { Row } from 'react-table'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import { RowAction } from '@components/Table/Table'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { CopyLogState } from '@src/pages/ContactSegments/components/CopySegments/components/CopyLog/CopyLog'
import { LogStatusMapped, SegmentsCopiedHistoryLog } from '@src/pages/ContactSegments/components/CopySegments/context/CopySegments.context'

const statusIcon = {
  [LogStatusMapped.SUCCESS]: SvgNames.checkSuccess,
  [LogStatusMapped.FAILED]: SvgNames.errorSolid,
  [LogStatusMapped.PARTIALLY_FAILED]: SvgNames.errorSolid,
  [LogStatusMapped.IN_PROGRESS]: SvgNames.spamCheckWarning,
}

export const useColumns: () => ColumnDefWithAdditionalProps<SegmentsCopiedHistoryLog, any>[] = () => {
  const { t } = useTranslation()
  return [
    {
      header: 'Parent',
      accessorKey: 'parent',
      textAlign: 'left',
      sortingFn: 'textCaseSensitive',
      maxSize: 300,
      cell: ({ row: { original } }) => <TextWithTooltipOnEllip typographyProps={{ text: original.parent }} />,
    },
    {
      header: 'Segments',
      accessorKey: 'segmentsInfo',
      textAlign: 'left',
      sortingFn: 'alphanumeric',
      maxSize: 180,
      cell: ({ row: { original } }) => <TextWithTooltipOnEllip typographyProps={{ text: original.segmentsInfo }} />,
    },
    {
      header: 'Status',
      accessorKey: 'status',
      textAlign: 'left',
      sortingFn: 'textCaseSensitive',
      maxSize: 240,
      cell: ({ row: { original } }) => (
        <Tooltip
          trigger={
            <div className={'copy-log__status'}>
              <Svg name={statusIcon[original.status]} type={SvgType.MEDIUM} />
              <Typography text={original.status} />
            </div>
          }
        >
          {original.success.length > 0 && (
            <>
              <Typography text={t('Succeeded:')} type={TextType.BODY_TEXT_WHITE} weight={TextWeight.BOLD} />
              <Typography text={` ${original.success.join(', ')}`} type={TextType.BODY_TEXT_WHITE} />
            </>
          )}
          {original.inProgress.length > 0 && (
            <>
              <Typography text={t('In progress:')} type={TextType.BODY_TEXT_WHITE} weight={TextWeight.BOLD} />
              <Typography text={` ${original.inProgress.join(', ')}`} type={TextType.BODY_TEXT_WHITE} />
            </>
          )}
          {original.fails.length > 0 && (
            <>
              <Typography text={t('Failed:')} type={TextType.BODY_TEXT_WHITE} weight={TextWeight.BOLD} />
              <Typography text={` ${original.fails.join(', ')}`} type={TextType.BODY_TEXT_WHITE} />
            </>
          )}
        </Tooltip>
      ),
    },
    {
      header: 'User',
      accessorKey: 'userName',
      textAlign: 'left',
      sortingFn: 'textCaseSensitive',
      maxSize: 180,
      cell: ({ row: { original } }) => <TextWithTooltipOnEllip typographyProps={{ text: original.userName }} />,
    },
    {
      header: 'Date',
      accessorKey: 'date',
      textAlign: 'left',
      sortingFn: 'datetime',
      maxSize: 180,
    },
  ]
}

export const useRowActions = (setState: Dispatch<SetStateAction<CopyLogState>>): RowAction[] => {
  const { t } = useTranslation()
  return [
    {
      label: t('Undo'),
      icon: SvgNames.reset,
      hidden: ({ original }: Row<SegmentsCopiedHistoryLog>) => original.status === LogStatusMapped.FAILED,
      onClick: ({ original }: Row<SegmentsCopiedHistoryLog>) => setState((state) => ({ ...state, undoActionId: (original as any).copyId })),
    },
  ]
}
