import { gql } from '@apollo/client'

export default gql`
  mutation deleteContentFragment($fragmentIds: [Float!]) {
    deleteContentFragment(fragmentIds: $fragmentIds) {
      status
      message
    }
  }
`
