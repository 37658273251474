import React, { FC, useContext } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/Button'
import { SvgNames, SvgType } from '@components/Svg/index'
import Svg from '@components/Svg/Svg'
import { TableV2 } from '@components/TableV2/TableV2'
import { RowAction } from '@components/TableV2/tableV2TS/interfaces'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { FormManagerContext, FormManagerEmbed } from '@src/pages/Content/Forms/FormManager/context/FormManager.context'
import { CellContext, SortingState } from '@tanstack/react-table'

import './EmbedCodes.css'

interface EmbedCodesProps {
  className?: string
  dataTest?: string
}

export const defaultSortingBy: SortingState = [
  {
    id: 'name',
    desc: false,
  },
]

const rootClass = 'embed-codes'

const EmbedCodes: FC<EmbedCodesProps> = (props: EmbedCodesProps) => {
  const { dataTest = rootClass, className = '' } = props
  const {
    toggleModal,
    setCurrentRow,
    values: { embeds },
  } = useContext(FormManagerContext)
  const { t } = useTranslation()

  const columns: ColumnDefWithAdditionalProps<FormManagerEmbed, any>[] = [
    {
      header: 'Label',
      textAlign: 'left',
      accessorKey: 'name',
      sortingFn: 'caseInsensitive',
      enableClickableCell: true,
      maxSize: 350,
    },
    {
      header: 'Hosted URL',
      textAlign: 'left',
      accessorKey: 'externalURL',
      enableClickableCell: true,
      hideTooltip: true,
      cell: (row: CellContext<FormManagerEmbed, any>) => (
        <TextLink text={row.getValue()} onClick={() => window.open(row.getValue())} size={TextLinkSize.LARGE} />
      ),
    },
  ]

  const rowActions: RowAction<FormManagerEmbed>[] = [
    {
      label: 'Edit',
      icon: SvgNames.pencil,
      onClick: (row) => {
        setCurrentRow(row.original.id, row.original.name)
        toggleModal('editEmbedCode')
      },
    },
    {
      label: 'Code',
      icon: SvgNames.codeIcon,
      onClick: (row) => {
        setCurrentRow(row.original.id, row.original.name)
        toggleModal('embedCode')
      },
    },
    {
      label: 'Delete',
      icon: SvgNames.delete,
      onClick: (row) => {
        setCurrentRow(row.original.id, row.original.name)
        toggleModal('deleteEmbedCode')
      },
    },
  ]

  const renderEmpty = (
    <div className={`${rootClass}__empty`}>
      <Typography text={t('No embed codes have been created for this form')} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.MEDIUM} />
    </div>
  )

  const onCreateClick = () => {
    setCurrentRow()
    toggleModal('createEmbedCode')
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {embeds.length === 0 && renderEmpty}
      {embeds.length > 0 && <TableV2 data={embeds} columns={columns} rowActions={rowActions} withoutBorders sortingBy={defaultSortingBy} />}
      <Button buttonType={ButtonType.SECONDARY} onClick={onCreateClick} className={`${rootClass}__create-code`} dataTest={`${dataTest}-create-code`}>
        <Svg name={SvgNames.plus} type={SvgType.LARGER_ICON} className={`${rootClass}__create-code-svg`} />
        <Typography text={t('Create embed code')} weight={TextWeight.MEDIUM} />
      </Button>
    </div>
  )
}

export default EmbedCodes
