import React, { ComponentProps, FC, useContext, useEffect, useState } from 'react'

import classNames from 'classnames'

import InfoText from '@components/InfoText'
import SectionHeadline from '@components/SectionHeadline'
import Typography, { TextType } from '@components/Typography/Typography'
import { getUUID, useTranslation } from '@const/globals'
import { StandardField, UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import FieldMappingRow from '@src/pages/datamanagement/components/NonCrmContacts/FieldMappingRow/FieldMappingRow'
import { DataManagementContext, Row } from '@src/pages/datamanagement/context/DataManagementContext'

import './SchemaEditor.css'

type Props = {
  className?: string
  dataTest?: string
  schemaType?: SchemaType
  handleInsert: any
  handleUpdate: any
  handleDelete: any
}

export enum SchemaType {
  CUSTOM,
  STANDARD,
}

const rootClass = 'schema-editor'

const SchemaEditor: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '', schemaType = SchemaType.STANDARD, handleInsert, handleUpdate, handleDelete } = props

  const { t } = useTranslation()

  const {
    values: { nonCrmContactsData, standardFieldsMap },
  } = useContext(DataManagementContext)

  const [stdFields, setStdFields] = useState<StandardField[]>([])

  useEffect(() => {
    if (standardFieldsMap.size > 0) {
      const fields = [...standardFieldsMap.values()].filter((field) => !field.required)
      setStdFields(fields)
    }
  }, [standardFieldsMap])

  // Method that renders the headers above the table
  const getContainerHeaders = () => {
    let headline
    let info

    if (schemaType === SchemaType.CUSTOM) {
      headline = t('Act-On Custom Fields')
      info = t('Add your own custom fields and select their data types. (Optional)')
    } else {
      headline = t('Act-On Standard Fields')
      info = t('Select Standard Fields for your Act-On Contacts.')
    }

    return (
      <div>
        <SectionHeadline>
          <Typography text={headline} type={TextType.SECTION_HEADER} />
        </SectionHeadline>
        <InfoText>
          <Typography text={info} type={TextType.BODY_TEXT_SMALL_LIGHT} />
        </InfoText>
      </div>
    )
  }

  const isValid = (field: UnifiedListFieldMapping) => {
    if (schemaType === SchemaType.STANDARD) {
      return field.standardFieldKey && !field.deleted
    } else {
      return !field.standardFieldKey && !field.deleted
    }
  }

  // Calling the method to render the rows for every mapping
  const getRows = () => {
    const rows: Row[] = schemaType === SchemaType.STANDARD ? nonCrmContactsData.standardRows : nonCrmContactsData.customRows
    const fieldMappingRowProps: ComponentProps<typeof FieldMappingRow> = {
      schemaType,
      handleDelete,
      handleInsert,
      handleUpdate,
      standardFieldOptions: stdFields,
    }
    const emailAddressRow: Row = {
      id: 0,
      mapping: {
        standardFieldKey: 'email',
        dataType: 'EMAIL',
        displayName: 'E-mail Address',
        hidden: false,
        readOnly: false,
      },
      status: 1,
    }
    return [...(rows.some(({ mapping }) => mapping.standardFieldKey === emailAddressRow.mapping.standardFieldKey) ? [] : [emailAddressRow]), ...rows]
      .map((row) => isValid(row.mapping) && <FieldMappingRow key={`row-${row.id}`} row={row} {...fieldMappingRowProps} />)
      .concat(<FieldMappingRow key={getUUID()} {...fieldMappingRowProps} newRow />)
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {getContainerHeaders()}
      <div className={`${rootClass}__table`}>
        <FieldMappingRow schemaType={schemaType} handleInsert={handleInsert} handleDelete={handleDelete} handleUpdate={handleUpdate} isHeader />
        <hr className={`${rootClass}__divider`} />
        <div className={`${rootClass}__rows`}>{getRows()}</div>
      </div>
    </div>
  )
}

export default SchemaEditor
