import { gql } from '@apollo/client'

export default gql`
  query getBeeTemplateCategories {
    getBeeTemplateCategories {
      result {
        categories {
          count
          id
          name
          parent
        }
        parent
      }
      sizeAllTemplates
    }
  }
`
