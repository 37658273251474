import { ApolloClient } from '@apollo/client'
import { Status } from '@components/StatusToast/StatusToast'
import { SelectionType } from '@components/TableV2/tableV2TS/enums'
import { Item } from '@components/TableV2/tableV2TS/types'
import deleteContacts from '@graphql/microservices/list/deleteContacts'
import deleteAllBounceContacts from '@graphql/microservices/segment/deleteAllBounceContacts'
import deleteAllContacts from '@graphql/microservices/segment/deleteAllContacts'
import getContacts from '@graphql/microservices/segment/getContacts'
import getContactsFromBounce from '@graphql/microservices/segment/getContactsFromBounce'
import removeContactsFromSegment from '@graphql/microservices/segment/removeContactsFromSegment'
import { FormDto, GetFormsToMigrateQuery, MigrateFormsMutation } from '@graphql/types/microservice/form-types'
import { DeleteContactsMutation, DeleteContactsMutationVariables } from '@graphql/types/microservice/list-types'
import {
  DeleteAllBounceContactsMutation,
  DeleteAllBounceContactsMutationVariables,
  DeleteAllContactsMutation,
  DeleteAllContactsMutationVariables,
  GetContactsFromBounceQuery,
  GetContactsFromBounceQueryVariables,
  GetContactsQuery,
  GetContactsQueryVariables,
  PageInput,
  RemoveContactsFromSegmentMutation,
  RemoveContactsFromSegmentMutationVariables,
} from '@graphql/types/microservice/segment-types'
import { GetAllContactsUpgradeCompletedQuery } from '@graphql/types/query-types'
import { SetContainerValues } from '@interface/ListPage.context'
import { State } from '@src/pages/ContactSegments/ContactSegments'
import { BANNER_TYPE } from '@src/pages/UpgradeAssistant/UpgradeAssistant.contants'
import { Row } from '@tanstack/react-table'
import { ItemType } from '@utils/categorization'
import { ContactSegmentsSession, ContactSegmentsState, Segment } from '@utils/contactSegments/contactSegments.utils'
import { Update } from '@utils/contactSegments/context/ContactSegmentsContext'
import { logNewRelicError } from '@utils/new-relic.utils'
import { getItem, removeItem, setItem } from '@utils/sessionStorage'
import { FetchPromise } from '@utils/types'

export const deleteAllContactsUtils = (
  segments: Segment[],
  setContainerValues: SetContainerValues<ContactSegmentsState>,
  client: ApolloClient<any>
) => {
  if (segments.length) {
    setContainerValues((containerValues) => ({ ...containerValues, isProcessingAction: true }))
    const isBounce = segments[0].itemType === ItemType.BOUNCE
    const variables = isBounce ? { verb: segments[0].externalId } : { segmentIds: segments.map(({ externalId }) => externalId) }
    client
      .mutate<
        DeleteAllContactsMutation | DeleteAllBounceContactsMutation,
        DeleteAllContactsMutationVariables | DeleteAllBounceContactsMutationVariables
      >({
        mutation: isBounce ? deleteAllBounceContacts : deleteAllContacts,
        variables,
      })
      .then(() => {
        setContainerValues((containerValues) => ({
          ...containerValues,
          isProcessingAction: false,
          statusToast: {
            statusMessage: `We're deleting your contacts now. This may take some time. Check back later for an updated contact list.`,
            status: Status.SUCCESS,
            showStatusToast: true,
          },
        }))
      })
  }
}

export const getSegmentContacts = (page: PageInput, isInactiveBounceSegment = false, client: ApolloClient<any>) =>
  client
    .query<GetContactsQuery & GetContactsFromBounceQuery, GetContactsQueryVariables & GetContactsFromBounceQueryVariables>({
      query: isInactiveBounceSegment ? getContactsFromBounce : getContacts,
      fetchPolicy: 'network-only',
      variables: {
        page,
        isSubmissionList: false,
      },
    })
    .then(({ data: { getContacts, getContactsFromBounce } }) => ({ ...(getContacts ?? getContactsFromBounce) }))

export const deleteContactsUtils = (recordIds: number[], uuids: string[], client: ApolloClient<any>) =>
  client.mutate<DeleteContactsMutation, DeleteContactsMutationVariables>({
    mutation: deleteContacts,
    variables: {
      recordIds,
      uuids,
    },
  })

export const removeContactsFromSegmentUtils = (segmentId: string, contactIds: string[], client: ApolloClient<any>) =>
  client.mutate<RemoveContactsFromSegmentMutation, RemoveContactsFromSegmentMutationVariables>({
    mutation: removeContactsFromSegment,
    variables: { segmentId, recIds: contactIds },
  })

export const onFormsUpdateUtils = (
  formsIds: string[],
  migrateFormsRequest: (formIds: string[]) => FetchPromise<MigrateFormsMutation>,
  t: Function,
  update: Update,
  setState: Function
) => {
  setState((state: State) => ({
    ...state,
    showUpdateFormsModal: false,
  }))
  migrateFormsRequest(formsIds)
    .then(() => {
      update({
        statusToast: {
          statusMessage: 'Update.Form.Submissions.Status.Updating',
          status: Status.LOADING,
          showStatusToast: true,
        },
      })
      setState((state: State) => ({
        ...state,
        showUpdateFormsCaution: false,
      }))
      setItem(ContactSegmentsSession.FORMS_UPDATE_NOTIFICATION, new Date().toDateString())
      setItem(ContactSegmentsSession.FORMS_UPDATE_DONE, 'true')
    })
    .catch((e) => {
      update({
        statusToast: {
          statusMessage: t('Update.Form.Submissions.Status.Error'),
          status: Status.FAIL,
          showStatusToast: true,
        },
      })
      logNewRelicError(e)
    })
}

export const checkFormsToMigrate = (
  getFormsToMigrateRequest: () => FetchPromise<GetFormsToMigrateQuery>,
  t: Function,
  update: Update,
  setState: Function
) => {
  getFormsToMigrateRequest()
    .then(({ data }) => {
      const forms = data?.getFormsToMigrate as FormDto[]
      const updateDone = getItem(ContactSegmentsSession.FORMS_UPDATE_DONE)
      if (updateDone === 'true' && forms.length > 0) {
        removeItem(ContactSegmentsSession.FORMS_UPDATE_DONE)
        update({
          statusToast: {
            statusMessage: t('Update.Form.Submissions.Status.Success'),
            status: Status.SUCCESS,
            showStatusToast: true,
          },
        })
      }
      setState((state: State) => ({
        ...state,
        formsToMigrate: forms,
        showUpdateFormsCaution: !!data?.getFormsToMigrate?.length,
      }))
    })
    .catch((e) => logNewRelicError(e))
}

export const getUpgradeBannerStatus = (getAllContactsUpgrade: () => FetchPromise<GetAllContactsUpgradeCompletedQuery>, update: Update) => {
  getAllContactsUpgrade()
    .then((response) => {
      const { data } = response
      const completedItems = (data?.getAllContactsUpgradeCompleted.filter((item) => !!item) as string[]) ?? []
      update({
        allContactsUpgradeDone: completedItems.includes(BANNER_TYPE),
      })
    })
    .catch((e) => {
      logNewRelicError(e)
      update({
        statusToast: {
          statusMessage: 'Something went wrong on our end.',
          status: Status.FAIL,
          showStatusToast: true,
        },
      })
    })
}

export const canDropInSegments = (
  { original, getIsExpanded }: Row<Segment>,
  { selectionType, rows }: Item<Segment>,
  itemType: string,
  isOverTop: boolean
) => {
  const target = original as Segment
  const dropInsideExpandedParentSegment = target.subRows?.length && !isOverTop && getIsExpanded()
  const isSegmentType = itemType === ItemType.SEGMENT || itemType === ItemType.UNSORTABLE_SEGMENT

  // Only enable the segments sorting for the All Segments filter for now
  if (!dropInsideExpandedParentSegment && isSegmentType && selectionType !== SelectionType.MIXED) {
    // Segments must be siblings to enable them to be sorted
    return rows.every((row) => {
      const source = row.original as Segment
      return target.parent == source.parent
    })
  }
  return false
}
