import { AllowedSendWindow } from '@graphql/types/microservice/sms-management-types'

export type RecipientTimezoneResponse = {
  tzOffset: string
  recipients: number
}

export interface AllowedSendWindowProcessed {
  sendTimeStart: string
  startHour: number
  startMinute: number
  startText: string
  sendTimeEnd: string
  endHour: number
  endMinute: number
  endText: string
  overrideAllowed: boolean
  recipientsOutsideAllowedWindow: number
}

export const defaultAllowedSendWindowState: AllowedSendWindowProcessed = {
  sendTimeStart: '09:00',
  startHour: 9,
  startMinute: 0,
  startText: '9:00am',
  sendTimeEnd: '17:00',
  endHour: 17,
  endMinute: 0,
  endText: '5:00pm',
  overrideAllowed: false,
  recipientsOutsideAllowedWindow: 0,
}

export const processAllowedWindowMessageData = (
  getAllowedSendWindow: AllowedSendWindow,
  allowedSendWindow: AllowedSendWindowProcessed,
  recipientTimezoneResponses: RecipientTimezoneResponse[],
  baseTimezoneOffset: number,
  sendHour: number,
  sendMinute: number,
  isRecipientTimezone: boolean
) => {
  const sendTimeStart = getAllowedSendWindow.sendTimeStart ?? allowedSendWindow.sendTimeStart
  const startHour = parseInt(sendTimeStart.split(':')[0])
  const startMinute = parseInt(sendTimeStart.split(':')[1])
  const startAM = startHour < 12
  const startText = startAM
    ? `${startHour === 0 ? 12 : startHour}:${startMinute < 10 ? '0' : ''}${startMinute}am`
    : `${startHour === 0 || startHour === 12 ? 12 : startHour - 12}:${startMinute < 10 ? '0' : ''}${startMinute}pm`

  const sendTimeEnd = getAllowedSendWindow.sendTimeEnd ?? allowedSendWindow.sendTimeEnd
  const endHour = parseInt(sendTimeEnd.split(':')[0])
  const endMinute = parseInt(sendTimeEnd.split(':')[1])
  const endAM = endHour < 12
  const endText = endAM ? `${endHour === 0 ? 12 : endHour}:${endMinute}am` : `${endHour - 12}:${endMinute < 10 ? '0' : ''}${endMinute}pm`

  const allowedWindowTimes = {
    startHour,
    startMinute,
    endHour,
    endMinute,
  }

  const recipientsOutsideAllowedWindow = recipientTimezoneResponses.reduce((acc, recipientTimezone) => {
    return isTimeInAllowedWindow(
      baseTimezoneOffset,
      parseInt(recipientTimezone.tzOffset),
      sendHour,
      sendMinute,
      allowedWindowTimes,
      isRecipientTimezone
    )
      ? acc
      : acc + recipientTimezone.recipients
  }, 0)

  const overrideAllowed = getAllowedSendWindow.allowOverride

  return {
    sendTimeStart,
    startHour,
    startMinute,
    startText,
    sendTimeEnd,
    endHour,
    endMinute,
    endText,
    recipientsOutsideAllowedWindow,
    overrideAllowed,
  }
}

export interface AllowedWindowTimes {
  startHour: number
  startMinute: number
  endHour: number
  endMinute: number
}

export const isTimeInAllowedWindow = (
  baseTimezoneOffset: number,
  comparisonTimezoneOffset: number,
  sendHour: number,
  sendMinute: number,
  allowedWindowTimes: AllowedWindowTimes,
  isRecipientTimezone: boolean
) => {
  const shift = comparisonTimezoneOffset - baseTimezoneOffset
  let shiftedHour = isRecipientTimezone ? sendHour : sendHour + shift
  if (shiftedHour > 24) {
    shiftedHour = shiftedHour - 24
  }
  const shiftedDate = new Date()
  shiftedDate.setHours(shiftedHour, sendMinute)

  const allowedStartDate = new Date()
  allowedStartDate.setHours(allowedWindowTimes.startHour, allowedWindowTimes.startMinute)

  const allowedEndDate = new Date()
  allowedEndDate.setHours(allowedWindowTimes.endHour, allowedWindowTimes.endMinute)

  return allowedStartDate <= shiftedDate && shiftedDate <= allowedEndDate
}
