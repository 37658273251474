import React from 'react'

import classNames from 'classnames'

import { DEFAULT_FILTERS, MenuActions } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { GetSidebarMenuItemsParams, ListPageCommonState } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { hasSubType, ListingPageSession } from '@complex/ListingPage/Utils/ListingPage.utils'
import { Item } from '@components/ActionableNestedTable/components/NestedTableRowWithDnD/NestedTableRowWithDnD'
import Button, { ButtonType } from '@components/Button/Button'
import { CollapsibleMenuItemWithHeaderData } from '@components/CollapsibleMenu/CollapsibleMenu'
import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import FilterContainer from '@components/FilterContainer/FilterContainer'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import SortableFolders from '@components/SortableFolders/SortableFolders'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import TagsList from '@components/TagsList/TagsList'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import { formatNumber } from '@utils/numbers'
import { getItem } from '@utils/sessionStorage'

// import './SidebarMenuItems.css'

const itemRowClass = 'menu-item'
// const rootClass = 'sidebar-menu-items'

const getDefaultFilters = ({
  t,
  menuActions,
  values: {
    filterActive,
    filterCounts,
    selectedCustomSource,
    listingPageProps: {
      sidebarProps: {
        allItemFilter,
        hasFavorites,
        hasRecommended,
        hasRecent,
        hasCreatedByMe,
        getCustomDefaultFilters,
        customDefaultFilters,
        hideAllItem,
        hideFilterCount,
      },
    },
  },
}: GetSidebarMenuItemsParams) => {
  const filters = [...DEFAULT_FILTERS]
  const customFilters = customDefaultFilters ? customDefaultFilters : getCustomDefaultFilters ? getCustomDefaultFilters(menuActions) : undefined
  if (customFilters) {
    customFilters.forEach((filter) => (filter.position ? filters.splice(filter.position - 1, 0, filter) : filters.push(filter)))
  }

  return {
    content: (
      <FilterContainer
        filters={[selectedCustomSource?.allItemFilter ?? allItemFilter, ...filters]}
        filterCounts={filterCounts}
        activeFilter={filterActive}
        filterAction={menuActions.clickFilter}
        hideAllItem={hideAllItem}
        hideCount={hideFilterCount}
        hasFavorites={hasFavorites}
        hasRecent={hasRecent}
        hasRecommended={hasRecommended}
        hasCreatedByMe={hasCreatedByMe}
      />
    ),
    header: t('Filters'),
    icon: SvgNames.segmentFilters,
    showHeaderWhenCollapsed: true,
    dataTest: 'menu-items-filters',
  }
}

const getFolders = (
  values: ListPageCommonState,
  t: Function,
  menuActions: MenuActions,
  defaultOpenedFolders: number[],
  hasToExpandFolders: number[],
  clearFoldersToExpand?: () => void
) => {
  const {
    folders,
    activeFolderId,
    fetchFolders,
    showSalesUsersControls,
    isSalesUser,
    readOnlyFolders,
    listingPageProps: {
      sidebarProps: { hasSalesUsersAction },
      tableProps: { listPage },
      hideMoveItems,
    },
  } = values

  const isFolderSectionExpanded = getItem(ListingPageSession.FOLDER_MENU_SECTION_CLOSED) !== 'true' || !!showSalesUsersControls

  const emptyLinkProps = readOnlyFolders
    ? {
        text: t(`ListPage.${listPage}.Folders.EmptyListing.ReadOnly`),
      }
    : {
        linkText: t('Add a folder'),
        text: t(`ListPage.${listPage}.Folders.EmptyListing`),
        onLinkClick: menuActions.addFolder,
      }

  return {
    action: (
      <>
        {hasSalesUsersAction && !isSalesUser && (
          <Tooltip
            dataTest={`${itemRowClass}-toggle-sales-users`}
            position="top"
            align={'end'}
            alignOffset={3}
            trigger={
              <Button
                buttonType={ButtonType.ICON}
                className={classNames(`${itemRowClass}__toggle-sales-users-button`, {
                  [`${itemRowClass}__toggle-sales-users-button-active`]: showSalesUsersControls,
                })}
                onClick={menuActions.toggleSalesUsersControls}
                noPadding
              >
                <Svg
                  className={classNames(`${itemRowClass}__toggle-sales-users-icon`)}
                  name={showSalesUsersControls ? SvgNames.usersSelected : SvgNames.usersUnselected}
                  type={SvgType.LARGE_ICON}
                  dataTest={`${itemRowClass}-toggle-sales-users-icon`}
                />
              </Button>
            }
          >
            {t('ListPage.Common.SalesUsers.ToggleControlsTooltip')}
          </Tooltip>
        )}
        {!hideMoveItems && (
          <Tooltip
            dataTest={`${itemRowClass}-add-folder`}
            alignOffset={10}
            position="top"
            trigger={
              <Button buttonType={ButtonType.ICON} className={classNames(`${itemRowClass}__add-button`)} onClick={menuActions.addFolder}>
                <Svg
                  className={classNames(`${itemRowClass}__add-icon`)}
                  name={SvgNames.plusLight}
                  type={SvgType.ICON}
                  dataTest={`${itemRowClass}-add-folder-icon`}
                />
              </Button>
            }
          >
            {t('Add folder')}
          </Tooltip>
        )}
      </>
    ),
    content:
      folders.length > 0 || fetchFolders ? (
        <SortableFolders
          className={classNames(`${itemRowClass}__folders`)}
          activeFolder={activeFolderId}
          folders={folders}
          hideMoveItems={hideMoveItems}
          defaultOpenedFolders={defaultOpenedFolders}
          hasToExpandFolders={hasToExpandFolders}
          clearFoldersToExpand={clearFoldersToExpand}
          onClick={menuActions.clickFolder}
          onActionClick={readOnlyFolders ? undefined : menuActions.clickFolderAction}
          onDrop={(_item: Item, folder: FolderData) => menuActions.dropItem(folder)}
          toggleProps={{
            showToggle: showSalesUsersControls ?? false,
            isToggleOn: (folder) => hasSubType('sales', folder.subTypeDTOS),
            onToggleClick: menuActions.toggleSalesUsersEnabled,
          }}
        />
      ) : (
        <EmptyListing
          {...emptyLinkProps}
          imgSrc={StaticImageNames.aocNoFolders}
          size={EmptyListingSize.SMALL}
          textType={TextType.BODY_TEXT_SMALL_LIGHT}
          withoutBorder
        />
      ),
    header: folders.length > 0 ? t(`Folders ({{count}})`, { count: folders.length }) : t('Folders'),
    icon: SvgNames.folderClosed,
    isOpen: isFolderSectionExpanded,
    dataTest: 'menu-items-folders',
  }
}

const getTags = (values: ListPageCommonState, t: Function, menuActions: MenuActions) => {
  const {
    tags,
    activeTagId,
    fetchTags,
    listingPageProps: {
      tableProps: { listPage },
    },
  } = values

  return {
    action: (
      <Tooltip
        dataTest={`${itemRowClass}-add-tag`}
        position="top"
        trigger={
          <Button
            buttonType={ButtonType.ICON}
            className={classNames(`${itemRowClass}__add-button`, `${itemRowClass}__add-button`)}
            onClick={menuActions.addTag}
          >
            <Svg
              className={classNames(`${itemRowClass}__add-icon`, `${itemRowClass}__add-icon`)}
              name={SvgNames.plusLight}
              type={SvgType.ICON}
              dataTest={`${itemRowClass}-add-tag-icon`}
            />
          </Button>
        }
      >
        {t('Add tag')}
      </Tooltip>
    ),
    content:
      (tags && tags.length > 0) || fetchTags ? (
        <TagsList
          onClick={menuActions.clickTag}
          onActionClick={menuActions.clickTagAction}
          selectedId={activeTagId}
          tags={tags}
          className={classNames(`${itemRowClass}__tags`, `${itemRowClass}__tags`)}
        />
      ) : (
        <EmptyListing
          imgSrc={StaticImageNames.aocNoTags}
          linkText={'Add a tag'}
          text={t(`ListPage.${listPage}.Tags.EmptyListing`)}
          onLinkClick={menuActions.addTag}
          size={EmptyListingSize.SMALL}
          textType={TextType.BODY_TEXT_SMALL_LIGHT}
          withoutBorder
        />
      ),
    header: tags && tags.length > 0 ? t(`Tags ({{count}})`, { count: tags.length }) : t('Tags'),
    icon: SvgNames.tag,
    isOpen: getItem(ListingPageSession.TAG_MENU_SECTION_CLOSED) !== 'true',
    dataTest: 'menu-items-tags',
  }
}

const rootClass = 'sidebar-container'

const renderCustomFilterWithCount = (children: JSX.Element, count?: number, key?: string | number) => (
  <div className={classNames(`${rootClass}__custom-filter`, `ellip`)} key={key}>
    {children}
    <Typography
      type={TextType.BODY_TEXT_LIGHT}
      className={`${rootClass}__custom-filter__count`}
      text={formatNumber(count ? count : 0)}
      lineHeight={LineHeight.SMALL}
    />
  </div>
)

const showCustomFilters = ({
  menuActions,
  t,
  values: {
    activeSubTypes,
    filterCounts,
    filterActive,
    defaultSubTypes,
    listingPageProps: {
      sidebarProps: { allItemFilter, renderCustomFilters },
    },
  },
}: GetSidebarMenuItemsParams) =>
  !!renderCustomFilters
    ? renderCustomFilters({
        activeFilter: filterActive ?? allItemFilter,
        activeSubTypes,
        defaultSubTypes,
        filterCounts,
        menuActions,
        renderCustomFilterWithCount,
        t,
      })
    : []

const showFolders = ({
  clearFoldersToExpand,
  defaultOpenedFolders,
  menuActions,
  t,
  values,
  values: {
    hasToExpandFolders,
    listingPageProps: {
      sidebarProps: { hideFolders },
    },
  },
}: GetSidebarMenuItemsParams) =>
  hideFolders ? [] : [getFolders(values, t, menuActions, defaultOpenedFolders, hasToExpandFolders, clearFoldersToExpand)]

const showTags = ({
  menuActions,
  t,
  values,
  values: {
    listingPageProps: {
      sidebarProps: { hideTags },
    },
  },
}: GetSidebarMenuItemsParams) => (hideTags ? [] : [getTags(values, t, menuActions)])

export const getSidebarMenuItems = (params: GetSidebarMenuItemsParams): CollapsibleMenuItemWithHeaderData[] => [
  getDefaultFilters(params),
  ...showCustomFilters(params),
  ...showFolders(params),
  ...showTags(params),
]
