import { MutableRefObject } from 'react'

import dayjs from 'dayjs'
import * as H from 'history'

import { TableV2RowData } from '@components/TableV2/tableV2TS/interfaces'
import { TextWeight } from '@components/Typography/Typography'
import { MessageContentResponse, RetrieveMessageQuery } from '@graphql/types/query-types'
import { CommonComposerTab } from '@utils/composer/commonComposer/CommonComposer.context'
import { OmitMessageType } from '@utils/composer/commonComposer/types'
import {
  ComposerHistoryState,
  SendMethod as ContextSendMethod,
  EmailComposerAPI,
  EmailComposerState,
  EmailMessage,
  SavedEmailMessageTemplate,
} from '@utils/composer/context/EmailComposer.context'
import { LandingPageComposerState } from '@utils/composer/context/LandingPageComposer.context'
import { EmailComposerRequests } from '@utils/composer/emailComposer/GraphQL/EmailComposerRequests.graphQL'
import { ExtendedMessageType } from '@utils/composer/emailComposer/types'
import { DeepPartial, FetchPromise } from '@utils/types'

interface CreateOrGetMessageCommonProps {
  userId: string
  startId: string | null
  messageId: string
  accountId: string
  isPlainTextOnly?: boolean
  messageType: EmailMessage['messageType']
  containerValuesRef: React.MutableRefObject<EmailComposerState>
  history: H.History<ComposerHistoryState>
  enableEmailDraftsReact: boolean
  update: EmailComposerAPI['update']
  getEmailMessage: (messageId: string) => FetchPromise<RetrieveMessageQuery>
  changeLastSavedTemplate: (message: SavedEmailMessageTemplate) => void
  messageContent?: OmitMessageType<MessageContentResponse>
}
export interface GetMessageById extends Omit<CreateOrGetMessageCommonProps, 'messageConfiguration'> {
  isFromStart?: boolean
  setDefaultValues?: boolean
}

export interface HandleCreateOrGetMessage extends CreateOrGetMessageCommonProps {
  startId: string | null
  isFromDefault?: boolean
  messageType: ExtendedMessageType
  isUploadHtmlOnly?: boolean
  shouldCreateMessage: string | boolean | null
  shouldFillMessageData: boolean | null
  update: EmailComposerAPI['update']
  createEmptyFormAutoResponseBeeMsg: (id: string, isCustomHtml: boolean) => Promise<string | undefined>
  createMessage: EmailComposerRequests['createMessage']
  loadFromAddressesRequest: EmailComposerRequests['loadFromAddressesRequest']
  isNewAssetReportMessage?: boolean
  assetReportLocalStorageKey?: TouchpointsLocalStorageKeys
}

export interface HandleCreateOrGetBlankMessage {
  isPlainTextOnly?: boolean
  shouldCreateMessage: boolean
  computedMsgType: ExtendedMessageType
  data: DeepPartial<EmailComposerState> | null | undefined
  update: EmailComposerAPI['update']
  createMessage: EmailComposerRequests['createMessage']
  getCommonCreateNewMessageFields: (id: string, message?: DeepPartial<EmailMessage>) => Record<string, boolean | DeepPartial<EmailMessage>>
}
export interface MessageRecipient extends TableV2RowData<MessageRecipient> {
  id: string
  isList: boolean
  name: string
  size: number
  email?: string
  srcId?: string
}

export const isEmailComposerTab = (tab: string | undefined): tab is CommonComposerTab => {
  return !!Object.values(CommonComposerTab).find((value) => value === tab)
}

export type SaveEmailHelperProps = {
  containerValues: EmailComposerState
  autoSave: boolean
  userId: string
  timeZoneId?: string
  saveContent: boolean
  isTemplateChanged: boolean
}

export interface RetrieveMessageById {
  containerValuesRef: React.MutableRefObject<EmailComposerState>
  userId: string
  accountId: string
  setDefaultValues?: boolean
  message: OmitMessageType<MessageContentResponse>
  isFirstEdit?: boolean
  isPlainTextOnly?: boolean
}

export enum PrevPageParamsEnum {
  emailTemplate = 'emailTemplate',
  drafts = 'drafts',
  classicDrafts = 'classicDrafts',
  default = 'default',
  forms = 'forms',
  abTest = 'abTest',
  webinar = 'webinar',
  rss = 'rss',
  landingPage = 'landingPage',
}

export enum TouchpointsLocalStorageKeys {
  SENT_MESSAGE_REPORT_DETAILS = 'sentMessageReport:details',
  FORM_REPORT_DETAILS = 'formReport:details',
  LANDING_PAGE_REPORT_DETAILS = 'landingPageReport:details',
  CONTACT_SEGMENTS_SELECTED_LISTS = 'contactSegments:selectedLists',
  CONTACT_SEGMENTS_SELECTED_CONTACTS = 'contactSegments:selectedContacts',
}

export interface ProcessCurrentDateForLaterOrTimezone {
  currentTimezoneDate: dayjs.Dayjs
  givenDate: dayjs.Dayjs
  currentTimezoneAmOrPm: string
  givenAmOrPm: string
  checkTime: boolean
  sendDetailsFieldsToUpdate: Partial<ContextSendMethod>
  resetProps: Partial<ContextSendMethod>
}

export interface ProcessCurrentDateForStaggerOrAdaptive {
  start: dayjs.Dayjs
  end: dayjs.Dayjs
  currentTimezoneDate: dayjs.Dayjs
  sendDetailsFieldsToUpdate: Partial<ContextSendMethod>
  resetProps: Partial<ContextSendMethod>
  fieldToOverride: string
  timeInPast?: boolean
}

export type ConfirmationEmailIds = Record<string, { msgid: string; ts: number }>
export type SaveComposerEmailType = (containerValues: EmailComposerState, autoSave?: boolean) => Promise<boolean>
export type ContainerValuesRefType = MutableRefObject<EmailComposerState | LandingPageComposerState>

export interface ValidationOptions {
  text: string
  tagProps: {
    medium: {
      weight: TextWeight
    }
  }
  inline: boolean
  onClick?: () => void
}

export type WebinarMessageTypeWithoutAutosave = 'rejected' | 'accepted' | 'declined' | 'pending'
