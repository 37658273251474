import React, { FC, useContext, useState } from 'react'
import { ConnectDragSource } from 'react-dnd'

import classNames from 'classnames'

import { ButtonIconPosition, ButtonType } from '@components/Button'
import Checkbox from '@components/Checkbox'
import { YesNo } from '@components/ConfirmationModal'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import Svg, { SvgColor, SvgNames, SvgType } from '@components/Svg'
import TriggerButton from '@components/TriggerButton/TriggerButton'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import BehaviorExpression from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/BehaviorExpression/BehaviorExpression'
import CRMExpression from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/CRMExpression/CRMExpression'
import CustomDataExpression from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/CustomDataExpression/CustomDataExpression'
import ProfileExpression from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/ProfileExpression/ProfileExpression'
import ConditionGroup from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/Row/components/ConditionGroup/ConditionGroup'
import ScoreExpression from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/ScoreExpression/ScoreExpression'
import SegmentMembershipExpression from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/SegmentMembershipExpression/SegmentMembershipExpression'
import SMSExpression from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/SMSExpression/SMSExpression'
import SubscriptionExpression from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/SubscriptionExpression/SubscriptionExpression'
import { SystemExpression } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/SystemExpression/__stories__/SystemExpression.stories'
import {
  Criteria,
  CRITERIA_WITH_LINKED_ROWS,
  dropdownFooter,
  getCriteriaOptions,
  iconByCriteria,
  isSegmentComposerRow,
  RowAction,
} from '@src/pages/SegmentComposer/components/SegmentComposerBuild/utils/SegmentComposerBuild.utils'
import { SegmentComposerContext } from '@src/pages/SegmentComposer/SegmentComposer.context'
import { ExpressionGroup, ExpressionRow } from '@src/pages/SegmentComposer/SegmentComposer.interfaces'

import './Row.css'

export interface RowProps {
  className?: string
  dataTest?: string
  isDragged?: boolean
  row: ExpressionRow
  onSelect: (selected: boolean) => void
  dragSource?: ConnectDragSource
  isRecentlyDropped?: boolean
}

const rootClass = 'segment-composer-row'

const Row: FC<RowProps> = (props: RowProps) => {
  const { dataTest = rootClass, className = '', isDragged, row, onSelect, dragSource, isRecentlyDropped } = props
  const {
    factor: { id, subRows },
  } = row

  const { t } = useTranslation()

  const {
    values: { segmentDefinition },
    onAddLinkedRow,
    update,
  } = useContext(SegmentComposerContext)

  const { groups } = segmentDefinition

  const criteriaOptions = getCriteriaOptions(t)

  const hasSubRows = !!subRows && subRows.length > 0

  const [selectedCriteria, setSelectedCriteria] = useState<SelectV2SingleOption>(criteriaOptions[0])
  const [deleteOpen, setDeleteOpen] = useState(false)

  const onActionClick = (action: RowAction) => {
    const rowActionHandlers = {
      [RowAction.ADD]: () => id && onAddLinkedRow(id),
      [RowAction.DUPLICATE]: () => false,
      [RowAction.DELETE]: () => setDeleteOpen(true),
      [RowAction.GROUP]: () => false,
    }
    rowActionHandlers[action]()
  }

  const actionsOptions = [
    {
      text: t('SegmentComposer.Build.Row.Actions.Add'),
      icon: SvgNames.addLinkedRow,
      onClick: () => onActionClick(RowAction.ADD),
      hidden: !CRITERIA_WITH_LINKED_ROWS.includes(selectedCriteria.value as Criteria),
    },
    {
      text: t('SegmentComposer.Build.Row.Actions.Duplicate'),
      icon: SvgNames.copyStep,
      onClick: () => onActionClick(RowAction.DUPLICATE),
    },
    {
      text: t('SegmentComposer.Build.Row.Actions.Group'),
      icon: SvgNames.turnIntoGroup,
      onClick: () => onActionClick(RowAction.GROUP),
    },
    {
      text: t('SegmentComposer.Build.Row.Actions.Delete'),
      icon: SvgNames.delete,
      onClick: () => onActionClick(RowAction.DELETE),
    },
  ]

  const renderRowDefinition = (criteria: Criteria) => {
    const criteriaDefinition = {
      [Criteria.PROFILE]: <ProfileExpression />,
      [Criteria.BEHAVIOR]: <BehaviorExpression />,
      [Criteria.SCORE]: <ScoreExpression />,
      [Criteria.SMS]: <SMSExpression />,
      [Criteria.SUBSCRIPTION]: <SubscriptionExpression />,
      [Criteria.SEGMENT]: <SegmentMembershipExpression />,
      [Criteria.SYSTEM]: <SystemExpression />,
      [Criteria.CRM]: <CRMExpression />,
      [Criteria.CUSTOM_DATA]: <CustomDataExpression />,
    }
    return criteriaDefinition[criteria]
  }

  const getRowDefinitionText = () => {
    // TODO: get the actual row definition text for each row type
    return 'Row definition text Mock'
  }

  /**
   * Updates a specific row within the root group or any nested group with new values and
   * returns the updated root expression group.
   *
   * @param {Partial<ExpressionRow>} newValues - An object containing the properties to be updated
   *                                             for the target row. Only properties provided in
   *                                             this object will be updated.
   * @returns {ExpressionGroup} - The updated root expression group after applying the changes.
   */
  const editRow = (newValues: Partial<ExpressionRow>): ExpressionGroup => {
    const updatedRoot = { ...groups[0] }
    const editRowRecursively = (group: ExpressionGroup) => {
      group.rows.forEach((element, index) => {
        if (isSegmentComposerRow(element)) {
          if (element.factor.id === id) {
            group.rows[index] = { ...group.rows[index], ...newValues }
          }
        } else {
          if (element.rows) {
            editRowRecursively(element as ExpressionGroup)
          }
        }
      })
    }
    editRowRecursively(updatedRoot)
    return updatedRoot
  }

  const onCriteriaChange = (option: SelectV2SingleOption) => {
    if (option.value !== selectedCriteria.value) {
      const updatedRootGroup = editRow({
        factor: {
          ...row.factor,
          subRows: [],
        },
      })
      update({
        segmentDefinition: {
          ...segmentDefinition,
          groups: [updatedRootGroup],
        },
      })
    }
    setSelectedCriteria(option as SelectV2SingleOption)
  }

  return (
    <div
      className={classNames(rootClass, className, {
        [`${rootClass}__draggable`]: dragSource,
        [`${rootClass}__dragged`]: isDragged,
        [`${rootClass}__dropped`]: isRecentlyDropped,
      })}
      data-test={dataTest}
    >
      <DeleteConfirmationModal
        title={t('Are you sure?')}
        body={
          <Typography
            text={t('SegmentComposer.Build.Row.DeleteModal.Text', {
              rowDefinition: getRowDefinitionText(),
            })}
            tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
          />
        }
        deleteButtonText={t('Delete')}
        isOpen={deleteOpen}
        onAnswer={(answer: YesNo) => {
          if (answer === YesNo.YES) {
            // TODO: do the delete action
          }
          setDeleteOpen(false)
        }}
      />
      <div className={`${rootClass}__left-actions`}>
        <div className={classNames(`${rootClass}__left-actions-drag`)} data-test={`${dataTest}-draggable-section`} ref={dragSource}>
          <Svg name={SvgNames.drag} type={SvgType.ICON} fill={SvgColor.LIGHT_GRAY} />
        </div>
        <Checkbox dataTest={`${dataTest}-checkbox`} onChange={onSelect} />
      </div>
      <div className={`${rootClass}__row-wrapper`}>
        <div className={`${rootClass}__criteria-wrapper`}>
          <div className={`${rootClass}__criteria-select-container`}>
            <SelectV2
              dataTest={`${dataTest}-criteria-select`}
              className={`${rootClass}__criteria-select`}
              options={criteriaOptions}
              defaultValue={selectedCriteria}
              onChange={(option) => onCriteriaChange(option as SelectV2SingleOption)}
              isClearable={false}
              showIconOnSelect
              renderSingleValue={() => ''}
              inputIcon={iconByCriteria[selectedCriteria?.value as Criteria]}
              isSearchable={false}
            />
            {hasSubRows && <div className={`${rootClass}__criteria-select-depth-indicator`} />}
          </div>
          <div
            className={classNames(`${rootClass}__row-definition`, {
              [`${rootClass}__row-definition-with-padding`]: hasSubRows,
            })}
          >
            {renderRowDefinition(selectedCriteria?.value as Criteria)}
          </div>
          <TriggerButton
            dataTest={`${dataTest}-action-button`}
            className={`${rootClass}__action-button`}
            dropDownProps={{ align: 'end', footer: dropdownFooter }}
            alignRight={false}
            label={''}
            useLabel={false}
            options={actionsOptions}
            buttonType={ButtonType.FLOAT}
            svgName={SvgNames.overflowMenu}
            svgIcon={SvgType.LARGER_ICON}
            showCaretIcon={false}
            iconPosition={ButtonIconPosition.FLOAT}
          />
        </div>
        {hasSubRows && <ConditionGroup row={row}>{subRows.map(() => renderRowDefinition(selectedCriteria?.value as Criteria))}</ConditionGroup>}
      </div>
    </div>
  )
}

export default Row
