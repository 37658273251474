import React from 'react'
import { Row } from 'react-table'

import { TableActions } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.constants'
import { getActionProps } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.utils'
import {
  ItemDtoWithBeeComposer,
  ListPageCommonState,
  ListPageTableActionCustomProps,
  TableProps,
  Update,
} from '@complex/ListingPage/Context/ListingPageCommon.context'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { SvgNames } from '@components/Svg'
import { renderRelativeDate } from '@components/Table/components/tableColumns'
import { HeaderAction, RowAction } from '@components/Table/Table'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { rootContext } from '@const/globals'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { PrevPageParamsEnum } from '@src/pages/EmailComposer/utils/EmailComposer.types'
import TemplateNameColumn from '@src/pages/listingPages/EmailTemplates/components/TemplateNameColumn'
import { EmailTemplate } from '@src/pages/listingPages/EmailTemplates/utils/EmailTemplates.constants'
import { ItemType } from '@utils/categorization'
import { getStandardFormattedDate, relativeDate } from '@utils/date'
import { renderPathToFolder } from '@utils/searchUtils'

export enum EmailTemplatesCustomTableActions {
  EDIT = 'EDIT',
  CREATE_LANDING_PAGE_FROM_TEMPLATE = 'CREATE_LANDING_PAGE_FROM_TEMPLATE',
  CREATE_MESSAGE = 'CREATE_MESSAGE',
}

const rowActionCustomProps: ListPageTableActionCustomProps[] = [
  { name: 'EDIT', position: 0, hasTopSection: false, isInDropdown: false },
  { name: 'PREVIEW', position: 1, hasTopSection: false, isInDropdown: false },
  { name: 'ADD_TO_FAVORITES', position: 2, hasTopSection: false, isInDropdown: false },
  { name: 'CREATE_MESSAGE', position: 3, hasTopSection: false, isInDropdown: false },
  { name: 'CREATE_LANDING_PAGE_FROM_TEMPLATE', position: 4, hasTopSection: false, isInDropdown: true },
  { name: 'DUPLICATE', position: 5, hasTopSection: true, isInDropdown: true },
  { name: 'SHARE_TO_CATALOG', position: 6, hasTopSection: false, isInDropdown: true },
  { name: 'SHARE_TO_ACCOUNTS', position: 7, hasTopSection: false, isInDropdown: true },
  { name: 'MOVE_TO_FOLDER', position: 8, hasTopSection: true, isInDropdown: true },
  { name: 'REMOVE_FROM_FOLDER', position: 9, hasTopSection: false, isInDropdown: true },
  { name: 'DELETE', position: 10, hasTopSection: true, isInDropdown: true },
]

const headerActionCustomProps: ListPageTableActionCustomProps[] = [
  { name: 'ADD_TO_FAVORITES', position: 0, hasTopSection: false, isInDropdown: false },
  { name: 'MANAGE_TAGS', position: 1, hasTopSection: false, isInDropdown: false },
  { name: 'MOVE_TO_FOLDER', position: 2, hasTopSection: false, isInDropdown: false },
  { name: 'REMOVE_FROM_FOLDER', position: 3, hasTopSection: false, isInDropdown: false },
  { name: 'SHARE_TO_CATALOG', position: 4, hasTopSection: false, isInDropdown: false },
  { name: 'DELETE', position: 5, hasTopSection: false, isInDropdown: true },
]

export const onCustomTableAction = (customTableAction: EmailTemplatesCustomTableActions, update: Update, listPageValues: ListPageCommonState) => {
  const { selectedRows } = listPageValues
  const selectedRow = selectedRows[0]
  if (!selectedRow) {
    alert('no selected row')
    return
  }
  const selectedRowId = selectedRow.externalId ?? '-1'

  const isBeeComposer = selectedRow?.beeComposer

  const customAction: { [key in EmailTemplatesCustomTableActions]: () => void } = {
    [EmailTemplatesCustomTableActions.EDIT]: () => {
      const URL = isBeeComposer
        ? `${rootContext}/content/emails/${selectedRowId}/content?prevPage=${PrevPageParamsEnum.emailTemplate}&messageConfigurationType=template`
        : `${rootContext}/classic/if/_compose/emailMessage.jsp?send=0&template=1&id=${selectedRowId}`
      window.open(URL, '_blank')
    },
    [EmailTemplatesCustomTableActions.CREATE_LANDING_PAGE_FROM_TEMPLATE]: () => {
      update({ showCustomModal: true, customTableAction: EmailTemplatesCustomTableActions.CREATE_LANDING_PAGE_FROM_TEMPLATE })
    },
    [EmailTemplatesCustomTableActions.CREATE_MESSAGE]: () => {
      const URL = isBeeComposer
        ? `${rootContext}/content/emails/new?messageConfigurationType=draft&startId=${selectedRowId}&to=draft`
        : `${rootContext}/classic/if/_compose/emailMessage.jsp?start=template&startId=${selectedRowId}`
      window.open(URL, '_blank')
    },
  }
  customAction[customTableAction]()
}

const getCustomRowActions = (
  tableActions: TableActions,
  t: Function,
  canEdit: boolean,
  canCreateLandingPageFromBeeEmail?: boolean,
  isAgencyAccount?: boolean | undefined,
  isClientAccount?: boolean | undefined,
  newLPComposerCreateBlank?: boolean | undefined
): RowAction[] => [
  {
    label: t('Edit'),
    icon: SvgNames.pencil,
    disabled: !canEdit,
    hidden: !canEdit,
    tooltipMessage: 'Ask your administrator for permission to do this',
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(EmailTemplatesCustomTableActions.EDIT, row),
    ...getActionProps(EmailTemplatesCustomTableActions.EDIT, rowActionCustomProps),
  },
  {
    label: t('Create email from template'),
    icon: SvgNames.mailAdd,
    disabled: !canEdit,
    hidden: !canEdit,
    tooltipMessage: 'Ask your administrator for permission to do this',
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(EmailTemplatesCustomTableActions.CREATE_MESSAGE, row),
    ...getActionProps(EmailTemplatesCustomTableActions.CREATE_MESSAGE, rowActionCustomProps),
  },
  {
    label: t('Create landing page'),
    icon: SvgNames.appendedList,
    hasTooltip: true,
    disabled: (row?: Row<ItemDtoWithBeeComposer>) =>
      (!newLPComposerCreateBlank && (isAgencyAccount || isClientAccount) && row?.original.beeComposer) ||
      (row?.original.beeComposer && !canCreateLandingPageFromBeeEmail) ||
      (row?.original.beeComposer && (row?.original?.isPlainTextOnly || row?.original?.isCustomMessage)) ||
      !canEdit,

    tooltipMessage: (row?: Row<ItemDtoWithBeeComposer>) =>
      row?.original.beeComposer || !isAgencyAccount
        ? t('Beta.Emails.Tooltip.Message', {
            context: row?.original?.isPlainTextOnly ? 'plainText' : row?.original?.isCustomMessage ? 'uploadHtml' : 'default',
          })
        : !canEdit
        ? 'Ask your administrator for permission to do this'
        : '',
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(EmailTemplatesCustomTableActions.CREATE_LANDING_PAGE_FROM_TEMPLATE, row),
    ...getActionProps(EmailTemplatesCustomTableActions.CREATE_LANDING_PAGE_FROM_TEMPLATE, rowActionCustomProps),
  },
]

const getCustomHeaderActions = (_tableActions: TableActions, _t: Function): HeaderAction[] => []

const clickableColumnOptions = {
  colIndex: 0,
  action: { customTableAction: EmailTemplatesCustomTableActions.EDIT },
}

const renderItemNameVal = (original: ItemDto, svgName: SvgNames | null, name: string, search: string, rootClass: string) => {
  if (!original) {
    return ''
  }
  return <TemplateNameColumn original={original} svgName={svgName} name={name} search={search} rootClass={rootClass} />
}

const renderSearchColumns = (
  searchInAllItems: boolean | undefined,
  currentFolder: FolderData,
  search: string,
  folders: FolderData[],
  t: Function
): ColumnDefWithAdditionalProps<EmailTemplate>[] => [
  {
    header: 'Template Title',
    accessorKey: 'name',
    textAlign: 'left',
    cell: ({ row: { original } }) => renderItemNameVal(original, null, original.name, search, 'list-page-table-container'),
  },
  {
    header: 'Tags',
    accessorKey: 'tags',
    textAlign: 'left',
    maxSize: 180,
    disableSorting: true,
    enableCustomCellValue: true,
  },
  {
    header: 'Location',
    accessorKey: 'folderId',
    textAlign: 'left',
    maxSize: 140,
    cell: (row: any) =>
      renderPathToFolder(ItemType.EMAIL_TEMPLATE, row.getValue(), folders, searchInAllItems, 'list-page-table-container', t, currentFolder),
  },
  {
    header: 'Last Updated',
    accessorKey: 'lastUpdated',
    textAlign: 'left',
    maxSize: 140,
    cell: ({
      cell: {
        row: {
          original: { lastUpdated },
        },
      },
    }) => renderRelativeDate(relativeDate(lastUpdated, true, false), getStandardFormattedDate(lastUpdated)),
  },
]

const tableV2Columns: ColumnDefWithAdditionalProps<EmailTemplate>[] = [
  {
    header: 'Template Title',
    accessorKey: 'name',
    textAlign: 'left',
    cell: ({ row: { original } }) => renderItemNameVal(original, null, original.name, '', 'email-templates-listing-page-container'),
  },
  {
    header: 'Tags',
    accessorKey: 'tags',
    textAlign: 'left',
    maxSize: 180,
    disableSorting: true,
    enableCustomCellValue: true,
  },
  {
    header: 'Created',
    accessorKey: 'createdAt',
    textAlign: 'left',
    maxSize: 150,
    cell: ({
      cell: {
        row: {
          original: { createdTime },
        },
      },
    }) => renderRelativeDate(relativeDate(createdTime, true, false), getStandardFormattedDate(createdTime)),
  },
  {
    header: 'Last Updated',
    accessorKey: 'lastUpdated',
    textAlign: 'left',
    fieldType: 'TIMESTAMP',
    maxSize: 150,
    cell: ({
      cell: {
        row: {
          original: { lastUpdated },
        },
      },
    }) => renderRelativeDate(relativeDate(lastUpdated, true, false), getStandardFormattedDate(lastUpdated)),
  },
]

export const tableProps = (
  t: Function,
  canEdit: boolean,
  canCreateLandingPageFromBeeEmail?: boolean,
  isAgencyAccount?: boolean,
  isClientAccount?: boolean,
  newLPComposerCreateBlank?: boolean
): TableProps => ({
  columns: tableV2Columns,
  hasAutoSelectedRows: false,
  hasExpander: false,
  rowActionCustomProps,
  renderSearchColumns: (searchInAllItems: boolean | undefined, currentFolder: FolderData, search: string, folders: FolderData[]) =>
    renderSearchColumns(searchInAllItems, currentFolder, search, folders, t),
  headerActionCustomProps,
  listPage: 'EmailTemplates',
  getCustomHeaderActions: (tableActions) => getCustomHeaderActions(tableActions, t),
  getCustomRowActions: (tableActions) =>
    getCustomRowActions(tableActions, t, canEdit, canCreateLandingPageFromBeeEmail, isAgencyAccount, isClientAccount, newLPComposerCreateBlank),
  onCustomTableAction,
  clickableColumnOptions,
  actonAssetType: 'TEMPLATE',
  shareModalText: '',
})
