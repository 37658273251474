import React, { ComponentProps, FC, useContext, useMemo } from 'react'

import classNames from 'classnames'
// eslint-disable-next-line no-restricted-imports
import { isToday } from 'date-fns'
import isYesterday from 'date-fns/isYesterday'

import Caution from '@components/Caution/Caution'
import InfoTooltip from '@components/InfoTooltip/InfoTooltip'
import Loader from '@components/Loader'
import Typography from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { Segment } from '@graphql/types/microservice/ml-audience-types'
import CustomInsightsResultsSection from '@src/pages/RecommendedSegments/components/CustomInsightsResultsSection/CustomInsightsResultsSection'
import { RecommendedSegmentsContext } from '@src/pages/RecommendedSegments/utils/RecommendedSegments.context'
import { formatSendTSDate, getFormattedTime } from '@utils/date'

import './CustomInsightsResults.css'

interface CustomInsightsResultsProps {
  className?: string
  dataTest?: string
}

const rootClass = 'custom-insights-results'

const CustomInsightsResults: FC<CustomInsightsResultsProps> = (props: CustomInsightsResultsProps) => {
  const { dataTest = rootClass, className = '' } = props

  const {
    values: { loadingSegments, problems, segments },
  } = useContext(RecommendedSegmentsContext)

  const { t } = useTranslation()

  const segmentsGroupedByDay = useMemo(
    () =>
      (segments ?? []).reduce((dates: Map<string, Segment[]>, segment) => {
        if (segment.updateTime) {
          const date = formatSendTSDate(Number.parseInt(segment.updateTime))
          const segmentsOnSameDate = dates.get(date) ?? []
          dates.set(date, [...segmentsOnSameDate, segment])
        }
        return dates
      }, new Map()),
    [segments]
  )

  const renderLastUpdatedLabel = (segments: Segment[]) => {
    const getTypographyProps = (date: number): Partial<ComponentProps<typeof Typography>> => {
      const dateIsToday = isToday(date)
      const dateIsisYesterday = isYesterday(date)
      return {
        text: `Last updated: ${dateIsToday ? 'Today at ' : dateIsisYesterday ? 'Yesterday at ' : ''}{{date}}`,
        values: { date: dateIsToday || dateIsisYesterday ? getFormattedTime(date) : formatSendTSDate(date) },
      }
    }

    if (segments?.length) {
      return (
        <div className={`${rootClass}__last-updated-label`}>
          <Typography {...getTypographyProps(parseInt(segments[0].updateTime ?? '0'))} />
          <InfoTooltip>{t(`New insights generated every 24 hours`)}</InfoTooltip>
        </div>
      )
    }
  }

  const renderInsights = () => {
    const [newestSegmentsListByDay, ...segmentsRestByDay] = Array.from(segmentsGroupedByDay.entries())
    const [_, newestSegments] = newestSegmentsListByDay ?? []
    return [
      <CustomInsightsResultsSection
        key={`section-latest`}
        segments={newestSegments ?? []}
        header={t(`Latest insights`)}
        subheader={renderLastUpdatedLabel(newestSegments ?? [])}
      >
        {problems?.map(({ problemType, description }) => {
          const problemDescription = description?.endsWith('.') ? description : `${description}.`
          return (
            <Caution
              key={`${problemType}-${description}`}
              message={`${problemDescription} ${t('Generating new insights will be attempted again tomorrow.')}`}
            />
          )
        })}
      </CustomInsightsResultsSection>,
      ...(segmentsRestByDay ?? []).map(([header, segments]) => (
        <CustomInsightsResultsSection key={`section-from-${header}`} segments={segments ?? []} header={header} />
      )),
    ]
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {loadingSegments ? <Loader className={`${rootClass}__loader`} /> : renderInsights()}
    </div>
  )
}

export default CustomInsightsResults
