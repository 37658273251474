import React, { FC, useCallback } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { ButtonWeight } from '@components/Button/Button'
import Container from '@components/Container'
import SectionHeadline from '@components/SectionHeadline'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { useLandingPageContext } from '@src/pages/Content/LandingPages/LandingPagesManager/context/LandingPagesManager.context'
import { openLandingPageSEOReportWindow } from '@utils/landingPages'

import './LandingPageSettings.css'

interface LandingPageSettingsProps {
  manageUrls: () => void
  className?: string
  dataTest?: string
}

interface SettingsBlock {
  key: 'Public' | 'SEO'
  title: string
  icon: SvgNames
  buttonLabel: string
  onClick?: () => void
}

const rootClass = 'landing-page-settings'

const LandingPageSettings: FC<LandingPageSettingsProps> = ({ manageUrls, dataTest = rootClass, className = '' }: LandingPageSettingsProps) => {
  const { t } = useTranslation()
  const {
    landingPage: { id },
  } = useLandingPageContext()

  const handleView = useCallback(() => openLandingPageSEOReportWindow(id), [id])

  const blockData: SettingsBlock[] = [
    {
      key: 'Public',
      title: 'Public URL manager',
      icon: SvgNames.url,
      buttonLabel: 'Manage URLs',
      onClick: manageUrls,
    },
    {
      key: 'SEO',
      title: 'SEO report',
      icon: SvgNames.graphLineTeal,
      buttonLabel: 'View report',
      onClick: handleView,
    },
  ]

  return (
    <Container className={classNames(rootClass, className)} dataTest={dataTest}>
      <SectionHeadline className={`${rootClass}__headline`}>{t('Settings')}</SectionHeadline>
      {blockData.map(({ key, icon, buttonLabel, title, onClick }) => (
        <div key={key} className={`${rootClass}__block`}>
          <Svg name={icon} type={SvgType.LARGER_ICON} fill={SvgColor.TEAL} />
          <div>
            <Typography text={t(title)} weight={TextWeight.MEDIUM} className={`${rootClass}__block-title`} />
            <Typography text={t(`Landing.Page.Manager.Settings.${key}.Sub.Header`)} type={TextType.NORMAL_TEXT_GRAY} />
          </div>
          <Button
            buttonType={ButtonType.TEXT_TEAL}
            onClick={onClick}
            weight={ButtonWeight.MEDIUM}
            className={`${rootClass}__block-button`}
            dataTest={`${dataTest}-button-${key}`}
          >
            {t(buttonLabel)}
          </Button>
        </div>
      ))}
    </Container>
  )
}

export default LandingPageSettings
