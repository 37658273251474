import React, { FC, useContext } from 'react'

import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { UpgradeManagerContext } from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'
import { ReviewUpgradeSegmentReplacement } from '@src/pages/programs/UpgradePrograms/components/ReviewUpgrade/sections/components/ReviewUpgradeSegmentReplacement/ReviewUpgradeSegmentReplacement'

import './SegmentsToReplaceSection.css'

const rootClass = 'segments-to-replace-section'
const rootText = 'UpgradePrograms.Page.ReviewUpgrade.SegmentsToReplaceSection.'

export const SegmentsToReplaceSection: FC = () => {
  const {
    values: { upgradedEarlyExitSources, upgradedSources },
  } = useContext(UpgradeManagerContext)

  const { t } = useTranslation()

  const filteredUpgradedSources = upgradedSources?.filter(({ removed }) => !removed)
  const filteredUpgradedEarlyExitSources = upgradedEarlyExitSources?.filter(({ removed }) => !removed) ?? []

  return (
    <div className={rootClass}>
      <Typography
        text={t('AutomatedPrograms.UpgradeManager.SidebarMenu.Progress.ChooseSegments')}
        type={TextType.BODY_TEXT}
        weight={TextWeight.MEDIUM}
        lineHeight={LineHeight.MEDIUM}
      />
      {filteredUpgradedSources?.length !== 0 && (
        <div className={`${rootClass}__segments`}>
          <Typography
            text={t('AutomatedPrograms.UpgradeManager.SourceReplacement.ContactSource.Current')}
            type={TextType.BODY_TEXT}
            lineHeight={LineHeight.MEDIUM}
          />
          <div />
          <Typography text={t('AutomatedPrograms.UpgradeManager.NewSource.Replacement')} type={TextType.BODY_TEXT} lineHeight={LineHeight.MEDIUM} />
          {filteredUpgradedSources?.map((source, index) => (
            <ReviewUpgradeSegmentReplacement key={index} source={source} />
          ))}
        </div>
      )}
      {filteredUpgradedEarlyExitSources.length !== 0 && (
        <div className={`${rootClass}__segments`}>
          <Typography text={t(`${rootText}EarlyExitSegments`)} type={TextType.BODY_TEXT} lineHeight={LineHeight.MEDIUM} />
          <div />
          <Typography text={t('AutomatedPrograms.UpgradeManager.NewSource.Replacement')} type={TextType.BODY_TEXT} lineHeight={LineHeight.MEDIUM} />
          {filteredUpgradedEarlyExitSources
            ?.filter(({ removed }) => !removed)
            .map((source, index) => (
              <ReviewUpgradeSegmentReplacement key={index} source={source} />
            ))}
        </div>
      )}
    </div>
  )
}
