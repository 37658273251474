import React, { ReactNode } from 'react'

import classNames from 'classnames'

import MediaPickerModal, { MediaPickerModalProps } from '@components/AssetPickers/MediaPickerModal/MediaPickerModal'
import AssetSelect from '@components/AssetSelect/AssetSelect'
import BackButton from '@components/BackButton/BackButton'
import Button, { ButtonType } from '@components/Button'
import Label from '@components/Label'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import { SvgNames } from '@components/Svg'
import Typography, { ModalHeaderStyle } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import ExpirationDate from '@src/pages/EmailComposer/EmailModals/components/MediaFileModal/ExpirationDate/ExpirationDate'
import { AssetLinkPickerReturnType, AssetLinkTypes, isMediaItem } from '@utils/composer/EmailModal.constants'

import './SelectAssetModal.css'

export type AddAssetFlow = {
  text: string
  icon: SvgNames
  type: AssetLinkTypes.FORM | AssetLinkTypes.LANDING_PAGES | AssetLinkTypes.FILE
  actionName: string
}

interface SelectAssetModalProps<Asset extends AssetLinkPickerReturnType> {
  className?: string
  dataTest?: string
  handleBack: () => void
  headerText: string
  mainButtonSvg: SvgNames
  mainButtonText: string
  assetType: AddAssetFlow['type']
  handleChange: (item?: AssetLinkPickerReturnType) => void
  handlePickerBack: () => void
  handlePickerClose: () => void
  handlePickerModalOpen: () => void
  selectedAsset?: Asset
  description: string
  assetFlows: AddAssetFlow[]
  extraContent?: ReactNode
  handleClose: () => void
  handleInsert: () => void
  isOpen: boolean
  pickerModalOpen: boolean
  handleFlow: (flowName: string | undefined) => void
}

const rootClass = 'select-asset-modal'

const SelectAssetModal = <Asset extends AssetLinkPickerReturnType>(props: SelectAssetModalProps<Asset>) => {
  const {
    dataTest = rootClass,
    className = '',
    handleBack,
    handlePickerBack,
    handlePickerClose,
    description,
    mainButtonSvg,
    mainButtonText,
    headerText,
    handleClose,
    handleChange,
    handlePickerModalOpen,
    handleInsert,
    isOpen,
    handleFlow,
    selectedAsset,
    pickerModalOpen,
  } = props
  const { t } = useTranslation()

  const mediaPickerProps: MediaPickerModalProps = {
    onSingleSubmit: (item) => {
      handleChange?.(item)
    },
    onBack: () => handlePickerBack(),
    onClose: () => handlePickerClose(),
    onAction: (action) => handleFlow(action),
    preSelectedItem: isMediaItem(selectedAsset) ? selectedAsset : undefined,
    isOpen: true,
  }

  const header = (
    <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__header`}>
      <BackButton onClick={handleBack} />
      <Typography text={headerText} {...ModalHeaderStyle} />
    </ModalHeader>
  )

  const selectedAssetName = selectedAsset ? ('title' in selectedAsset ? selectedAsset.title : selectedAsset.name) : undefined

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
      <ModalBody className={`${rootClass}__body`}>
        <div className={`${rootClass}__radio-group`}>
          {pickerModalOpen && <MediaPickerModal {...mediaPickerProps} />}
          <Label className={`${rootClass}__sub-headers`}>{description}</Label>
          <AssetSelect
            selectedAssets={selectedAssetName}
            placeholder={mainButtonText}
            svgIcon={mainButtonSvg}
            onClick={() => handlePickerModalOpen()}
          />
        </div>
        <ExpirationDate />
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form} className={`${rootClass}__footer`}>
        <Button
          buttonType={ButtonType.TERTIARY}
          onClick={handleClose}
          className={`${rootClass}__footer-button-cancel`}
          dataTest={`${dataTest}-button-tertiary`}
        >
          {t('Cancel')}
        </Button>
        <Button
          className={`${rootClass}__footer-button-insert`}
          buttonType={ButtonType.PRIMARY}
          onClick={() => handleInsert()}
          disabled={!selectedAsset}
          dataTest={`${dataTest}-button-primary`}
        >
          {t('Insert')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default SelectAssetModal
