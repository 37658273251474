import { ApolloQueryResult } from '@apollo/client'
import { GetAllMarketingUsersQuery, GetAllSalesUsersQuery, OverviewManageProfile, UserResponse } from '@graphql/types/query-types'
import { StatusToastType } from '@interface/StatusToast'
import { createSafeContext, useSafeContext } from '@utils/account/account.utils'

export interface UsersContextAPI {
  isAdministrator: boolean
  setToastStatus: (value: StatusToastType) => void
  setLoadingMutation: (loading: boolean, isSales: boolean) => void
  handleResendVerification: (usersToResend: UserResponse[]) => Promise<any>
  onDeleteUsers: (usersToDelete: UserResponse[], refetch: Function, isSales: boolean) => void
  onEditProfile: (
    userData: OverviewManageProfile,
    refetch: () => Promise<ApolloQueryResult<GetAllMarketingUsersQuery | GetAllSalesUsersQuery>>
  ) => void
  onAddUsers: () => void
}

const UsersContext = createSafeContext<UsersContextAPI>()
export const useAllUsers = () => useSafeContext(UsersContext)

export default UsersContext
