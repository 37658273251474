import { gql } from '@apollo/client'

export default gql`
  query getCustomizeManagedAccountsData {
    getCustomizeManagedAccountsData {
      alertsFromAddress
      title
      logoPath
      isDefault
    }
  }
`
