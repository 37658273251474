import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import classNames from 'classnames'
import _ from 'lodash'
import { useDebouncedCallback } from 'use-debounce'

import Bee from '@beefree.io/sdk'
import { useFetchPersonalizations } from '@complex/Personalization/hooks/useFetchPersonalizations'
import { getRecipientSrcIds } from '@complex/Personalization/utils/Personalization.utils'
import PageContainer from '@components/PageContainer'
import { Status } from '@components/StatusToast/StatusToast'
import { MessageType } from '@graphql/types/query-types'
import SaveConfirmationModal from '@src/pages/EmailComposer/EmailModals/components/SaveConfirmationModal/SaveConfirmationModal'
import { SendConfirmationModal } from '@src/pages/EmailComposer/EmailModals/components/SendConfirmationModal/SendConfirmationModal'
import SendTestEmailModalContainer from '@src/pages/EmailComposer/EmailModals/components/SendTestEmailModal/SendTestEmailModalContainer'
import { useAutoSaveOnChanges } from '@src/pages/EmailComposer/hooks/useAutoSaveOnChanges'
import { useMayLoseChanges } from '@src/pages/EmailComposer/hooks/useMayLoseChanges'
import { getClassicFormData, handleCreateOrGetMessage } from '@src/pages/EmailComposer/utils/createOrGetMessageHelper'
import { WebinarMessageTypeWithoutAutosave } from '@src/pages/EmailComposer/utils/EmailComposer.types'
import { detectEmailType, getValidTab } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import {
  getRecipientsCount,
  getSessionStorageRecipients,
  setRecipientsFromTouchpoints,
} from '@src/pages/EmailComposer/utils/EmailComposerRecipients.utils'
import { convertToTemplate, saveAsCopy, saveAsDraft, saveAsTemplate, saveMessage } from '@src/pages/EmailComposer/utils/SaveMessageHelper'
import { ContactSettingsFields, getContactSettingsUtils } from '@src/pages/Settings/OtherSettings/ContactSettings/ContactSettings.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import { CommonComposerTab, SaveComposerCommonType } from '@utils/composer/commonComposer/CommonComposer.context'
import { isTabBlocked, useComposerBlockedTab } from '@utils/composer/commonComposer/hooks/useComposerBlockedTab'
import { ComposerContext, ComposerContextType } from '@utils/composer/context/ComposerContext.context'
import {
  ComposerHistoryState,
  defaultEmailComposerState,
  EmailComposerAPI,
  EmailComposerContext,
  EmailComposerState,
  EmailPreviewPlatform,
  SavedEmailMessageTemplate,
} from '@utils/composer/context/EmailComposer.context'
import { useEmailComposerRequests } from '@utils/composer/emailComposer/GraphQL/EmailComposerRequests.graphQL'
import { ExtendedMessageType, MessageConfiguration } from '@utils/composer/emailComposer/types'
import { AUTOSAVE_DEBOUNCE_TIME } from '@utils/composer/EmailModal.constants'
import { SHOULD_FILL_MESSAGE_ID_STORAGE_KEY } from '@utils/composer/settings.constants'
import { useTranslation } from '@utils/const/globals'
import { isCustomerCareLogin } from '@utils/cookie'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { getLocalStorageItem, removeSessionStorageItem, setSessionStorageItem } from '@utils/window'

import { checkIsContentMissing } from './components/EmailComposerPreview/EmailComposerPreview.utils'
import EmailComposerPreviewContainer from './components/EmailComposerPreview/EmailComposerPreviewContainer'
import { EmailComposer } from './EmailComposer'
import ClickthroughLinksModal from './EmailModals/components/ClickthroughLinksModal/ClickthroughLinksModal'
import { getWebinarHTMLFields } from './EmailModals/components/ManageWebinarDetailsModal/components/WebinarBlock/utils/webinarBlock.utils'
import ReviewAndSendModal from './EmailModals/components/ReviewAndSendModal/ReviewAndSendModal'
import { useFetchSettingsData } from './hooks/useFetchSettingsData'
import { useGetEmailValidations } from './hooks/useGetEmailValidations'
import { useUserTimezone } from './hooks/useUserTimezone'
import { getRowsConfiguration, IS_FIRST_MSG_OPENER } from './utils/BeeEditor.constants'
import { EmailComposerContainerProps } from './utils/BeeEditor.types'
import { UPDATE_SESSION_DELAY } from './utils/EmailComposer.constants'
import {
  composerURLIdHandler,
  getActiveContacts,
  getLastEmailContentEditSession,
  handleGoToPrevPage,
  setFormConfirmationEmail,
} from './utils/EmailComposer.utils'
import {
  beeEventHooks,
  checkSubjectAndPreviewText,
  closeMessage,
  createCampaign,
  createEmailContentEditSession,
  createSubscriptionCategory,
  discardEmail,
  handleContactsFromCRM,
  handleEditPlainText,
  joinSession,
  onPreview,
  saveAndClose,
  tabChange,
  testMessage,
} from './utils/EmailComposerAPI.utils'
import { saveEmailHelper } from './utils/saveEmailHelper'

import './EmailComposer.css'

const rootClass = 'email-composer'

const EmailComposerContainer: FC<EmailComposerContainerProps> = (props) => {
  const { match, disabledFeatures = {}, isStory = false } = props

  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory<ComposerHistoryState>()
  const { enableEmailDraftsReact, userId, allowNoOptOut, transactionalAllowNoOptOut, timeZoneId, accountId, accountTimeZoneId } = useAccountSettings()
  const { client: segmentClient } = useMicroserviceClient({ serviceName: MicroserviceClients.SEGMENT })
  const {
    createCampaignRequest,
    createSubscriptionCategoryRequest,
    getActiveContactsRequest,
    getLastEmailContentEditSessionRequest,
    createEmailContentEditSessionRequest,
    saveComposerEmailRequest,
    createMessage,
    deleteEmailDrafts,
    getEmailMessage,
    createEmptyFormAutoResponseBeeMsg,
    checkSubjectAndPreviewTextRequest,
    loadFromAddressesRequest,
    getEmailContentValidationsRequest,
    getContactsFromCrmFilterRequest,
    getContactsFromCrmAccountsRequest,
    getContactsFromCrmEmailsRequest,
    discardEmailChangesRequest,
    updateMessageErrorsCountRequest,
    updateEmailContentEditSessionRequest,
  } = useEmailComposerRequests()
  const { isEmailTemplate } = detectEmailType(match.params.id as MessageType)

  const [containerValues, setContainerValues] = useState<EmailComposerState>({
    ...defaultEmailComposerState,
    disabledFeatures: {
      ...defaultEmailComposerState.disabledFeatures,
      ...disabledFeatures,
      autoSave: defaultEmailComposerState.disabledFeatures.autoSave,
      editPlainText: defaultEmailComposerState.disabledFeatures.editPlainText,
    },
    isStory,
    tab: getValidTab(match.params.tab as CommonComposerTab, isEmailTemplate),
  })

  const isFirstRenderRef = useRef(true)
  const containerValuesRef = useRef<EmailComposerState>({ ...containerValues })
  const lastSavedTemplate = useRef<SavedEmailMessageTemplate>({
    templateHtml: containerValues.message.templateHtml,
    templateJson: { ...containerValues.message.templateJson },
  })

  const beeEditorRef = useRef<Bee>()
  const lastChangeTimeStamp = useRef<number>(0)

  const {
    loading,
    isBeeLoading,
    isPlainTextComposerLoading,
    sendErrorCount,
    message,
    isPreview,
    isTestSend,
    isSend,
    isClickthroughLinks,
    isCustomerCareSaveModal,
    isReviewAndSendModal,
    tab,
    messageConfiguration,
    retrieveMessageSuccess,
    validations: { errorsCount, isValidationsLoading },
  } = containerValues

  const update = useDeepUpdate(setContainerValues, containerValuesRef)
  const updateModal: EmailComposerAPI['updateModal'] = useCallback((modal, value, closeOthers = true) => {
    setContainerValues((state) => {
      const modalState = { ...state.modalState }
      if (closeOthers) {
        Object.keys(modalState).forEach((key) => {
          modalState[key as keyof typeof modalState] = undefined
        })
      }
      return { ...state, modalState: { ...modalState, [modal]: value } }
    })
  }, [])

  const isUploadHtml = containerValuesRef.current.detectedURLChanges.uploadHtmlMode
  const isPlainTextMode = containerValuesRef.current.detectedURLChanges.plainTextMode
  const isHtmlOrTextMode = isUploadHtml || isPlainTextMode

  const editSession = containerValuesRef.current?.lastEmailContentEditSession
  const sessionId = containerValuesRef.current?.sessionId
  const isCoEditing = containerValuesRef.current?.isCoEditing

  useComposerBlockedTab({
    accountId,
    isModalOpened: !!containerValues.modalState.blockedModal || isTabBlocked,
    closeButtonConfig: {
      buttonText: t('Blocked.LoggedOut.Button.Text'),
    },
    modalConfig: {
      title: t('Blocked.Modal.Title'),
      bodyText: t('Blocked.LoggedOut.Text', { accountId }),
    },
    onModalShow: (modalProps) => {
      updateModal('blockedModal', modalProps)
    },
  })

  useEffect(() => {
    if (isHtmlOrTextMode && (editSession || sessionId) && !isCoEditing) {
      onAutoSave(true)
      const updateSession = () => {
        updateEmailContentEditSessionRequest(editSession ? editSession?.id : sessionId)
      }

      const interval = setInterval(updateSession, UPDATE_SESSION_DELAY)

      updateSession()

      return () => clearInterval(interval)
    }
  }, [containerValuesRef.current.detectedURLChanges, sessionId, editSession, isHtmlOrTextMode, updateEmailContentEditSessionRequest, isCoEditing])

  useEffect(() => {
    if (containerValues.detectedURLChanges.plainTextMode || containerValues.detectedURLChanges.uploadHtmlMode) {
      update({ isBeeLoading: false })
    }
  }, [containerValues.detectedURLChanges.plainTextMode, containerValues.detectedURLChanges.uploadHtmlMode])

  const webinarConfig = containerValuesRef.current.message.webinarConfig

  useEffect(() => {
    const computedMessageType = webinarConfig?.messageCategory
    const webinarTypesWithoutAutosave: WebinarMessageTypeWithoutAutosave[] = ['rejected', 'accepted', 'declined', 'pending']
    if (computedMessageType) {
      if (webinarTypesWithoutAutosave.includes(computedMessageType as WebinarMessageTypeWithoutAutosave)) {
        update({ disabledFeatures: { autoSave: true } })
      } else {
        update({ disabledFeatures: { autoSave: isCustomerCareLogin() } })
      }
    }
  }, [update, webinarConfig])

  useMayLoseChanges(containerValuesRef)
  useUserTimezone(update, message)

  const handleSendTestEmailClose = useCallback(() => update({ isTestSend: false }), [update])
  const handleSendEmailClose = useCallback(() => update({ isSend: false }), [update])

  const shouldKeepAutoSaveSuccess = messageConfiguration.reviewAndSend.displayAsTab

  const autoSaveDebounce = useDebouncedCallback(
    async () => {
      update({ isIndicatingAutoSave: true })

      const success = await saveComposerEmail(containerValuesRef.current, true)
      if (!success) {
        update({ autoSaveFailure: true })
      } else {
        if (!shouldKeepAutoSaveSuccess || containerValues.validations.needToCheckValidation) {
          update({ autoSaveSuccess: true })
        }
      }
    },
    AUTOSAVE_DEBOUNCE_TIME,
    { trailing: true }
  )

  const onAutoSave = useCallback(
    async (forceEnabled = false) => {
      if (containerValuesRef.current.isCoEditing) {
        return
      }

      if (!forceEnabled && containerValuesRef.current.disabledFeatures.autoSave) {
        return
      }
      update({ isSaving: true })
      autoSaveDebounce()
    },
    [autoSaveDebounce, update]
  )

  const onMessageChangeDetected = useCallback(
    (callAutoSave: boolean) => {
      lastChangeTimeStamp.current = Date.now()
      update({ haveUnsavedChanges: true })
      callAutoSave && onAutoSave()
    },
    [onAutoSave, update]
  )

  useAutoSaveOnChanges(onMessageChangeDetected, message)

  const messageTemplateChangedAfterLastSave = useCallback(
    (message: SavedEmailMessageTemplate) =>
      lastSavedTemplate.current.templateHtml !== message.templateHtml || !_.isEqual(lastSavedTemplate.current.templateJson, message.templateJson),
    []
  )

  const changeLastSavedTemplate = useCallback((message: SavedEmailMessageTemplate) => {
    lastSavedTemplate.current.templateJson = _.cloneDeep(message.templateJson)
    lastSavedTemplate.current.templateHtml = message.templateHtml
  }, [])

  const saveComposerEmail = useCallback<SaveComposerCommonType>(
    async (containerValues: EmailComposerState, autoSave = false) => {
      const isFirstEdit = new URLSearchParams(history.location.search).get('firstEdit')
      const isUploadHtmlFirstEdit = containerValuesRef.current.isUploadHtmlFirstEdit

      update({ isSaving: true })
      // not saveContent only if autoSave and content is not changed
      const isTemplateChanged = messageTemplateChangedAfterLastSave(containerValues.message)
      const saveContent = !autoSave || isTemplateChanged

      const { isEmailWebinar } = detectEmailType(containerValues.message.messageType)

      const variables = saveEmailHelper(
        {
          containerValues,
          autoSave,
          userId,
          timeZoneId,
          isTemplateChanged,
          saveContent: (isEmailWebinar && !!isFirstEdit) || (isHtmlOrTextMode && isUploadHtmlFirstEdit) || saveContent,
        },
        !!isFirstEdit,
        isHtmlOrTextMode,
        isUploadHtmlFirstEdit
      )
      const saveTime = Date.now()
      const { formDataId } = getClassicFormData()

      const success = !!variables.messageContent.messageId ? await saveComposerEmailRequest(variables) : false
      if (!success) {
        updateModal('statusToast', {
          status: Status.FAIL,
          title: t('EmailComposer.Save.Status.Toast.Error.Title'),
          message: t('EmailComposer.Save.Status.Toast.Error.Message'),
        })
      } else {
        if (isEmailWebinar && !isFirstEdit) {
          update({ savedWithTempId: true })
        }
        if (isHtmlOrTextMode) {
          update({ isUploadHtmlFirstEdit: false })
        }
        changeLastSavedTemplate(containerValues.message)
      }
      const savingEnd = lastChangeTimeStamp.current < saveTime
      update({ isSaving: !savingEnd, isSaveFailed: !success, haveUnsavedChanges: !(success && savingEnd) })

      if (formDataId) {
        setFormConfirmationEmail(formDataId, containerValues.message.id)
      }

      return success
    },
    [saveComposerEmailRequest, t, update, updateModal, userId, messageTemplateChangedAfterLastSave, changeLastSavedTemplate, isHtmlOrTextMode]
  )

  const checkOptOutValidPromise = useCallback(() => {
    const {
      message: { templateHtml, isTransactional, isPlainTextOnly },
    } = containerValuesRef.current
    // if opt-out check is useless do not have to call api
    return allowNoOptOut || (transactionalAllowNoOptOut && isTransactional)
      ? Promise.resolve({ hasHtmlOptOut: true, hasTextOptOut: true })
      : getEmailContentValidationsRequest({
          html: isPlainTextOnly ? undefined : templateHtml,
          plainText: `${containerValuesRef.current.preview.plainText}`,
        })
  }, [allowNoOptOut, transactionalAllowNoOptOut, getEmailContentValidationsRequest])

  const api = useMemo(
    (): EmailComposerAPI => ({
      update,
      onAutoSave,
      updateModal,
      updatePreview: (fields) => update({ preview: { ...fields } }),
      onEditPlainText: (onConfirm?: VoidFunction) => handleEditPlainText(containerValuesRef, updateModal, saveComposerEmail, onConfirm),
      updateValidations: (fields) => update({ validations: { ...fields } }),
      onJoinSession: joinSession(containerValuesRef, beeEditorRef),
      onTabChange: tabChange(containerValuesRef, history, update),
      onStatusChange: (status, name) => {
        update({
          validations: {
            validationSectionsStatus: {
              [name]: status,
            },
          },
        })
      },
      onTest: () => testMessage(containerValuesRef, update, updateModal, saveComposerEmail),
      onPreview: () => onPreview(containerValuesRef, beeEditorRef, update, false, autoSaveDebounce),
      onCreateCampaign: createCampaign(containerValuesRef, createCampaignRequest, update),
      onCreateSubscriptionCategory: createSubscriptionCategory(containerValuesRef, createSubscriptionCategoryRequest, update),
      onCreateEmailContentEditSession: createEmailContentEditSession(createEmailContentEditSessionRequest),
      onConvertToTemplate: convertToTemplate(containerValuesRef, createMessage, deleteEmailDrafts, saveComposerEmail, update, history),
      onClose: () => closeMessage(containerValuesRef, history, enableEmailDraftsReact, updateModal, discardEmailChangesRequest),
      onSave: (pureSave = false, isSilentSave = false) =>
        saveMessage(
          checkOptOutValidPromise,
          containerValuesRef,
          updateModal,
          api.onTabChange,
          saveComposerEmail,
          update,
          history,
          enableEmailDraftsReact,
          pureSave,
          isSilentSave
        ),
      onSaveAsCopy: saveAsCopy(containerValuesRef, history, createMessage, saveComposerEmail, update),
      onSaveAsDraft: saveAsDraft(containerValuesRef, createMessage, saveComposerEmail, update, history),
      onCheckSubjectAndPreviewText: checkSubjectAndPreviewText(containerValuesRef, message, checkSubjectAndPreviewTextRequest, update),
      onSaveAsTemplate: saveAsTemplate(containerValuesRef, createMessage, saveComposerEmail, update, history),
      onSaveAndClose: saveAndClose(containerValuesRef, history, enableEmailDraftsReact, saveComposerEmail, updateModal, t),
      onDiscardAndClose: () => discardEmail(containerValuesRef, history, enableEmailDraftsReact, deleteEmailDrafts, updateModal),
      onGetRowsConfigurations: () => getRowsConfiguration(),
      eventHooks: beeEventHooks(containerValuesRef, createEmailContentEditSession(createEmailContentEditSessionRequest), update, onAutoSave),
    }),
    [containerValuesRef, updateModal, t, enableEmailDraftsReact, saveComposerEmail, checkOptOutValidPromise]
  )

  const fetchSettingsData = useFetchSettingsData(userId, update, api.updateModal)
  const fetchPersonalizations = useFetchPersonalizations()
  const fetchInitialData = async () => {
    await fetchSettingsData()
    const personalizations = await fetchPersonalizations(getRecipientSrcIds(message.individualRecipientsSourceIds, message.sendto_lists)?.join(','))
    update({ personalizations })
  }

  const handleCrmContacts = async (messageType: ExtendedMessageType, messageConfiguration: MessageConfiguration, queryParams?: URLSearchParams) => {
    const crmListId = queryParams?.get('crmemaillist')

    if (crmListId && queryParams) {
      await handleContactsFromCRM(queryParams, update, {
        getContactsFromCrmFilterRequest,
        getContactsFromCrmAccountsRequest,
        getContactsFromCrmEmailsRequest,
      })
    } else if (messageType === 'CRM_MESSAGE') {
      const { crmContacts } = getSessionStorageRecipients()
      update({
        message: {
          individualRecipientsSourceIds: crmContacts[0]?.contacts.map((contact) => contact.id),
          sendto_contacts: crmContacts[0]?.contacts,
          isCrmSentNote: messageConfiguration.sendDetails.enableAttachSentNoteToggle,
        },
      })
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (isFirstRenderRef.current) {
        fetchInitialData()
        const queryString = isStory ? match.url.slice(match.url.indexOf('?')) : location.search
        const {
          messageId,
          messageType,
          startId,
          messageConfiguration,
          isFromDefault,
          plainTextMode,
          uploadHtmlMode,
          isNewAssetReportMessage,
          assetReportLocalStorageKey,
        } = composerURLIdHandler(queryString, match, update)
        const shouldCreateMessage = !messageId || startId
        const shouldFillMessageData = getLocalStorageItem(SHOULD_FILL_MESSAGE_ID_STORAGE_KEY) === messageId
        const referrerParams = document.referrer.includes('?') ? new URLSearchParams(document.referrer.split('?')[1]) : undefined
        const shouldSetRecipients = referrerParams

        if (referrerParams?.get('crmemaillist') || messageType === 'CRM_MESSAGE') {
          await handleCrmContacts(messageType, messageConfiguration, referrerParams)
        } else if (shouldSetRecipients) {
          setRecipientsFromTouchpoints(update, referrerParams)
        }

        handleCreateOrGetMessage({
          history,
          userId,
          accountId,
          startId,
          messageId,
          messageType,
          containerValuesRef,
          shouldCreateMessage,
          shouldFillMessageData,
          enableEmailDraftsReact,
          isPlainTextOnly: plainTextMode,
          isUploadHtmlOnly: uploadHtmlMode,
          update,
          createMessage,
          getEmailMessage,
          changeLastSavedTemplate,
          loadFromAddressesRequest,
          createEmptyFormAutoResponseBeeMsg,
          isFromDefault,
          isNewAssetReportMessage,
          assetReportLocalStorageKey,
        })

        const autoSave = messageConfiguration?.saveButton?.autoSaveWithSeparateSaveAndClose
          ? false
          : messageConfiguration?.saveButton?.separateSaveAndClose
          ? true
          : containerValues.disabledFeatures.autoSave

        update({ message: { messageType }, disabledFeatures: { autoSave } })

        isFirstRenderRef.current = false
      }
    }

    fetchData()
  }, [userId])

  useEffect(() => {
    const isFirstEdit = new URLSearchParams(history.location.search).get('firstEdit')

    if (isFirstEdit && !loading) {
      const { id, templateJson, webinarConfig } = containerValuesRef.current.message
      const { updatedJson } = getWebinarHTMLFields(templateJson, accountId, id, true, webinarConfig, accountTimeZoneId, true)

      update({ message: { templateJson: updatedJson } })
    }
  }, [isBeeLoading, loading])

  useEffect(() => {
    const messageID = message.id
    const publishID = message.publishId
    const canPublish = messageConfiguration.canPublish
    const { isEmailABTest, isEmailRSS, isEmailTemplate, isEmailProgram } = detectEmailType(message.messageType)

    const messageId = match.params.id
    const startId = new URLSearchParams(location.search).get('startId')
    const isNewEmail = match.params.id === 'new'
    const isBlankMessage = match.params.id === 't-bee-blank'
    const isUploadHtml = containerValuesRef.current.detectedURLChanges.uploadHtmlMode
    const isPlainText = containerValuesRef.current.detectedURLChanges.plainTextMode
    const isHtmlOrTextMode = isUploadHtml || isPlainText

    if ((startId && isNewEmail) || (messageId && !isNewEmail && !isBlankMessage && !isEmailProgram)) {
      if (retrieveMessageSuccess) {
        getLastEmailContentEditSession(message, getLastEmailContentEditSessionRequest, update, createEmailContentEditSessionRequest, isHtmlOrTextMode)
      }
    } else {
      getLastEmailContentEditSession(message, getLastEmailContentEditSessionRequest, update, createEmailContentEditSessionRequest, isHtmlOrTextMode)
    }

    const shouldGoToPrevPage = (messageID && !isEmailABTest && !isEmailRSS && !canPublish) || (publishID && publishID !== match.params.id)

    if (shouldGoToPrevPage) {
      handleGoToPrevPage(message, history, tab)
    }

    const newTab = getValidTab(tab, isEmailTemplate)
    newTab !== tab && api.onTabChange(newTab)
  }, [message.id, update, retrieveMessageSuccess])

  const uploadHtmlMode = containerValuesRef.current.detectedURLChanges.uploadHtmlMode

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (uploadHtmlMode) {
        setSessionStorageItem(IS_FIRST_MSG_OPENER, 'true')
      }

      if (uploadHtmlMode && isCoEditing) {
        removeSessionStorageItem(IS_FIRST_MSG_OPENER)
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isCoEditing, uploadHtmlMode])

  useEffect(() => {
    // NOTE: bill, 11/3/23
    // Need the following call for the Suppression section on Send Details screen
    getContactSettingsUtils(segmentClient).then((settings) => {
      const hasFatigueRules = settings[ContactSettingsFields.FATIGUE_RULES] && settings[ContactSettingsFields.FATIGUE_RULES].length > 0
      update({ hasFatigueRules })
    })

    getActiveContacts(getActiveContactsRequest, update)
  }, [])

  useEffect(() => {
    if (checkIsContentMissing(message.templateHtml)) {
      api.updateValidations({ isContentMissing: true })
    } else {
      api.updateValidations({ isContentMissing: false })
    }
  }, [
    api,
    containerValues.detectedURLChanges.plainTextMode,
    containerValues.detectedURLChanges.uploadHtmlMode,
    message.customTextPart,
    message.templateHtml,
  ])

  useEffect(() => {
    const { sendto_lists, sendto_contacts } = message
    getRecipientsCount(update, sendto_lists, sendto_contacts)
  }, [message.sendto_lists, message.sendto_contacts, message.suppress_lists, update])

  const removeParamFromUrl = useCallback(() => {
    const searchParams = new URLSearchParams(history.location.search)
    searchParams.delete('firstEdit')

    const newQueryString = searchParams.toString()

    const newUrl = `${window.location.pathname}?${newQueryString}`
    history.replace(newUrl)
  }, [])

  useEffect(() => {
    const { publishId } = message

    if (sendErrorCount && !isValidationsLoading) {
      if (errorsCount) {
        updateMessageErrorsCountRequest({ messageId: publishId, errorsCount }).then((data) => {
          if (data?.updateMessageErrorsCount) {
            removeParamFromUrl()
          }
        })
      } else {
        removeParamFromUrl()
      }
    }
  }, [sendErrorCount, updateMessageErrorsCountRequest, errorsCount, isValidationsLoading])

  useEffect(() => {
    const { isEmailRSS } = detectEmailType(messageConfiguration.messageType)
    if (retrieveMessageSuccess && isEmailRSS && match.params.id.startsWith('d')) {
      saveComposerEmail(containerValuesRef.current)
    }
  }, [retrieveMessageSuccess])

  useGetEmailValidations()

  useEffect(() => {
    const pageContainer = document.getElementsByClassName('email-composer__mobile-review')[0]
    if (pageContainer) {
      pageContainer.scrollTo(0, 0)
    }
  }, [containerValuesRef.current.preview.platform])

  return (
    <ComposerContext.Provider value={{ type: ComposerContextType.EMAIL }}>
      <EmailComposerContext.Provider value={{ values: { ...containerValues, beeEditorRef }, api }}>
        {isPreview && !loading && <EmailComposerPreviewContainer />}
        {isTestSend && <SendTestEmailModalContainer onClose={handleSendTestEmailClose} />}
        {isSend && <SendConfirmationModal onClose={handleSendEmailClose} saveComposerEmail={saveComposerEmail} />}
        {isClickthroughLinks && <ClickthroughLinksModal />}
        {isCustomerCareSaveModal && <SaveConfirmationModal saveComposerEmail={saveComposerEmail} />}
        {isReviewAndSendModal && <ReviewAndSendModal saveComposerEmail={saveComposerEmail} />}
        <PageContainer
          noChrome
          className={classNames(`${rootClass}__page`, {
            [`${rootClass}__mobile-review`]: containerValuesRef.current.preview.platform === EmailPreviewPlatform.MOBILE,
          })}
        >
          <EmailComposer
            isStory={isStory}
            isBeeEditorLoading={isBeeLoading}
            isPlainTextComposerLoading={isPlainTextComposerLoading}
            editorClassname={classNames(`${rootClass}__composer`, {
              [`${rootClass}__hide-editor`]: isBeeLoading,
            })}
          />
        </PageContainer>
      </EmailComposerContext.Provider>
    </ComposerContext.Provider>
  )
}

export default EmailComposerContainer
