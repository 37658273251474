import { PERIOD_GROUPED_BY } from '@utils/contactSegments/contactSegments.utils'

export const PeriodValues = {
  SEVEN_DAYS: { amount: 7, groupBy: PERIOD_GROUPED_BY.Daily },
  HALF_YEAR: { amount: 6, groupBy: PERIOD_GROUPED_BY.Monthly },
  YEAR: { amount: 12, groupBy: PERIOD_GROUPED_BY.Monthly },
}

export const TotalContactsPeriodValues = {
  ...PeriodValues,
  QUARTER: { amount: 3, groupBy: PERIOD_GROUPED_BY.Monthly },
}

export const QuarterValues: { [key: string]: string[] } = {
  Q1: ['Jan', 'Feb', 'Mar'],
  Q2: ['Apr', 'May', 'Jun'],
  Q3: ['Jul', 'Aug', 'Sep'],
  Q4: ['Oct', 'Nov', 'Dec'],
}
