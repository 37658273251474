import { ItemDto } from '@graphql/types/query-types'

export enum ItemType {
  ACCOUNT = 'ACCOUNT',
  BOUNCE = 'BOUNCE',
  CAMPAIGN = 'CAMPAIGN',
  CUSTOM_OBJECT = 'CUSTOM_OBJECT',
  EMAIL_DRAFT = 'EMAIL_DRAFT',
  EMAIL_TEMPLATE = 'EMAIL_TEMPLATE',
  FORM = 'FORM',
  FORM_SUBMISSION = 'FORM_SUBMISSION',
  FORM_TEMPLATE = 'FORM_TEMPLATE',
  FRAGMENT = 'FRAGMENT',
  LANDING_PAGE = 'LANDING_PAGE',
  LANDING_PAGE_TEMPLATE = 'LANDING_PAGE_TEMPLATE',
  LIST_MAINTENANCE_PROGRAM = 'LIST_MAINTENANCE_PROGRAM',
  OUTGOING_WEBHOOK_ENDPOINT = 'OUTGOING_WEBHOOK_ENDPOINT',
  POLL_CHOICE = 'POLL_CHOICE',
  PROGRAM = 'AUTOMATED_PROGRAM',
  PROGRAM_STEP = 'PROGRAM_STEP',
  PROGRAM_TEMPLATE = 'PROGRAM_TEMPLATE',
  SEGMENT = 'SEGMENT',
  SUBSCRIPTION_CATEGORY = 'SUBSCRIPTION_CATEGORY',
  TABLE_COLUMN = 'TABLE_COLUMN',
  UNSORTABLE_SEGMENT = 'UNSORTABLE_SEGMENT',
  WEBINAR_SUBMISSION = 'WEBINAR_REGISTRATION',
  WEBINAR = 'WEBINAR',
  WEB_PAGE = 'WEB_PAGE',
}

export enum ItemSubType {
  AUTOMATED_PROGRAM_DRAFT = 'AUTOMATED_PROGRAM_DRAFT',
  AUTOMATED_PROGRAM_NO_AOC = 'AUTOMATED_PROGRAM_NO_AOC',
  AUTOMATED_PROGRAM_RUNNING = 'AUTOMATED_PROGRAM_RUNNING',
  CRM_SOURCED = 'CRM_SOURCED',
  NO_PHASE_3 = 'noPhase3',
}

export enum ItemSubTypeDtoName {
  AUTOMATED_PROGRAM_DRAFT = 'draft',
  AUTOMATED_PROGRAM_NO_AOC = 'hasNotActOnContactList',
  AUTOMATED_PROGRAM_RUNNING = 'running',
  CRM_SOURCED = 'crm_sourced',
  NO_PHASE_3 = 'noPhase3',
}

export const ITEM_SUB_TYPE_FROM_DTO: { [key in ItemSubTypeDtoName]: ItemSubType } = {
  [ItemSubTypeDtoName.AUTOMATED_PROGRAM_RUNNING]: ItemSubType.AUTOMATED_PROGRAM_RUNNING,
  [ItemSubTypeDtoName.AUTOMATED_PROGRAM_NO_AOC]: ItemSubType.AUTOMATED_PROGRAM_NO_AOC,
  [ItemSubTypeDtoName.AUTOMATED_PROGRAM_DRAFT]: ItemSubType.AUTOMATED_PROGRAM_DRAFT,
  [ItemSubTypeDtoName.CRM_SOURCED]: ItemSubType.CRM_SOURCED,
  [ItemSubTypeDtoName.NO_PHASE_3]: ItemSubType.NO_PHASE_3,
}

export enum BouncesSegments {
  HARD_BOUNCES = 'Hard Bounces',
  INACTIVE = 'Inactive Soft Bounce',
  OPT_OUT = 'Opt Outs',
  SMS_OPT_IN = 'SMS Opt In',
  SMS_OPT_OUT = 'SMS Opt Out',
  SOFT_BOUNCES = 'Soft Bounces',
  SPAM_COMPLAINTS = 'Spam Complaints',
}

export const invalidSmsBouncesVerbs = {
  [BouncesSegments.SMS_OPT_IN]: 'si',
  [BouncesSegments.SMS_OPT_OUT]: 'st',
}

export const smsBouncesVerbs = {
  [BouncesSegments.SMS_OPT_IN]: 'i-sms',
  [BouncesSegments.SMS_OPT_OUT]: 't-sms',
}

export const doFilterBounce = (items: ItemDto[]) => {
  const smsSegmentsIds = Object.values(smsBouncesVerbs)
  const invalidSmsSegmentsIds = Object.values(invalidSmsBouncesVerbs)
  return items.filter(
    (item) =>
      !smsSegmentsIds.includes(item?.externalId ?? '') &&
      !invalidSmsSegmentsIds.includes(item?.externalId ?? '') &&
      item?.externalId !== bouncesVerbs[BouncesSegments.INACTIVE]
  )
}

export const doFilterSms = (items: ItemDto[]) => {
  const smsSegmentsIds = Object.values(smsBouncesVerbs)
  const invalidSmsSegmentsIds = Object.values(invalidSmsBouncesVerbs)
  return items.filter((item) => !smsSegmentsIds.includes(item?.externalId ?? '') && !invalidSmsSegmentsIds.includes(item?.externalId ?? ''))
}

export const bouncesVerbs = {
  [BouncesSegments.HARD_BOUNCES]: 'B',
  [BouncesSegments.SOFT_BOUNCES]: 'b',
  [BouncesSegments.INACTIVE]: 'v',
  [BouncesSegments.OPT_OUT]: 't',
  [BouncesSegments.SPAM_COMPLAINTS]: 'm',
  ...smsBouncesVerbs,
}
export const isBounceSegment = (verb: string) => Object.values(bouncesVerbs).includes(verb)

export const getSegmentName = (id: string, name: string): string => {
  const namesById: { [key: string]: string } = {
    [bouncesVerbs[BouncesSegments.INACTIVE]]: BouncesSegments.INACTIVE,
  }
  return namesById[id] ?? name
}

const noItemText = { itemText: '', capitalText: '', completeItemText: '', listPage: '' }

export const ITEM_TYPES: { [key in ItemType]: { itemText: string; capitalText: string; completeItemText: string; listPage: string } } = {
  [ItemType.CUSTOM_OBJECT]: { itemText: 'custom data', capitalText: 'Custom Data', completeItemText: 'Custom Data', listPage: '.CustomDataJoinView' },
  [ItemType.PROGRAM_STEP]: noItemText,
  [ItemType.TABLE_COLUMN]: noItemText,
  [ItemType.UNSORTABLE_SEGMENT]: noItemText,
  [ItemType.POLL_CHOICE]: noItemText,
  [ItemType.ACCOUNT]: { itemText: 'account', capitalText: 'Accounts', completeItemText: 'Accounts', listPage: '.AccountManagement' },
  [ItemType.BOUNCE]: { itemText: 'bounce', capitalText: 'Bounces', completeItemText: 'Bounces', listPage: '.Bounces' },
  [ItemType.FRAGMENT]: {
    itemText: 'fragment',
    capitalText: 'Fragments',
    completeItemText: 'Content Fragments',
    listPage: '.ContentFragments',
  },
  [ItemType.LIST_MAINTENANCE_PROGRAM]: {
    itemText: 'program',
    capitalText: 'Programs',
    completeItemText: 'List Maintenance Programs',
    listPage: '.ListMaintenancePrograms',
  },
  [ItemType.OUTGOING_WEBHOOK_ENDPOINT]: {
    itemText: 'endpoint',
    capitalText: 'Endpoints',
    completeItemText: 'Outgoing Webhook Endpoints',
    listPage: '',
  },
  [ItemType.PROGRAM]: {
    itemText: 'program',
    capitalText: 'Programs',
    completeItemText: 'Automated Programs',
    listPage: '.AutomatedPrograms',
  },
  [ItemType.PROGRAM_TEMPLATE]: {
    itemText: 'template',
    capitalText: 'Templates',
    completeItemText: 'Program Templates',
    listPage: '.ProgramTemplates',
  },
  [ItemType.SEGMENT]: {
    itemText: 'segment',
    capitalText: 'Segments',
    completeItemText: 'Segments',
    listPage: '.ContactSegments',
  },
  [ItemType.FORM]: { itemText: 'form', capitalText: 'Forms', completeItemText: 'Forms', listPage: '.Forms' },
  [ItemType.FORM_SUBMISSION]: {
    itemText: 'submission',
    capitalText: 'Submissions',
    completeItemText: 'Form Submissions',
    listPage: '.FormsJoinView',
  },
  [ItemType.FORM_TEMPLATE]: { itemText: 'template', capitalText: 'Templates', completeItemText: 'Form Templates', listPage: '.FormTemplates' },
  [ItemType.LANDING_PAGE]: { itemText: 'landing page', capitalText: 'Landing Pages', completeItemText: 'Landing Pages', listPage: '.LandingPages' },
  [ItemType.LANDING_PAGE_TEMPLATE]: {
    itemText: 'template',
    capitalText: 'Templates',
    completeItemText: 'Landing Page Templates',
    listPage: '.LandingPageTemplates',
  },
  [ItemType.EMAIL_TEMPLATE]: {
    itemText: 'template',
    capitalText: 'Templates',
    completeItemText: 'Email Templates',
    listPage: '.EmailTemplates',
  },
  [ItemType.EMAIL_DRAFT]: { itemText: 'draft', capitalText: 'Drafts', completeItemText: 'Drafts', listPage: '.Drafts' },
  [ItemType.SUBSCRIPTION_CATEGORY]: {
    itemText: 'category',
    capitalText: 'Category',
    completeItemText: 'subscription category',
    listPage: '.SubscriptionCategory',
  },
  [ItemType.CAMPAIGN]: { itemText: 'campaign', capitalText: 'Campaigns', completeItemText: 'Campaigns', listPage: '.Campaigns' },
  [ItemType.WEBINAR_SUBMISSION]: {
    itemText: 'submission',
    capitalText: 'Submissions',
    completeItemText: 'Webinar Submissions',
    listPage: '.FormsJoinView',
  },
  [ItemType.WEBINAR]: {
    itemText: 'webinar',
    capitalText: 'Webinars',
    completeItemText: 'Webinars',
    listPage: '.Webinars',
  },
  [ItemType.WEB_PAGE]: {
    itemText: 'web page',
    capitalText: 'Web Pages',
    completeItemText: 'Web Pages',
    listPage: '.WebPage',
  },
}
