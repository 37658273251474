import React, { FC } from 'react'

import classNames from 'classnames'

import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@utils/const/globals'

import './Pill.css'

export enum PillSize {
  LARGE = 'LARGE',
  REGULAR = 'REGULAR',
  SMALL = 'SMALL',
  EXTRA_SMALL = 'EXTRA_SMALL',
}

export enum PillType {
  PRIMARILY = 'PRIMARILY',
  SECONDARY = 'SECONDARY',
  GRAY = 'GRAY',
  BORDERED = 'BORDERED',
}

interface Props {
  text?: string
  size?: PillSize
  type?: PillType
  className?: string
  dataTest?: string
  noMargin?: boolean
  textWeight?: TextWeight
  cursorPointer?: boolean
  renderWithTranslation?: boolean
}

const rootClass = 'pill'

const sizeToTypographyType = {
  [PillSize.EXTRA_SMALL]: TextType.BODY_TEXT_WHITE_TINY,
  [PillSize.SMALL]: TextType.BODY_TEXT_WHITE_TINY,
  [PillSize.REGULAR]: TextType.BODY_TEXT_WHITE_SMALL,
  [PillSize.LARGE]: TextType.BODY_TEXT_WHITE_REGULAR,
}

const Pill: FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  const {
    text,
    size = PillSize.REGULAR,
    type = PillType.PRIMARILY,
    dataTest = rootClass,
    className = '',
    noMargin,
    textWeight,
    cursorPointer = false,
    renderWithTranslation = false,
  } = props
  return (
    <Typography
      text={renderWithTranslation ? t(text) : text}
      className={classNames(rootClass, className, {
        [`${rootClass}__extra-small`]: size === PillSize.EXTRA_SMALL,
        [`${rootClass}__small`]: size === PillSize.SMALL,
        [`${rootClass}__secondary`]: type === PillType.SECONDARY,
        [`${rootClass}__gray`]: type === PillType.GRAY,
        [`${rootClass}__bordered`]: type === PillType.BORDERED,
        [`${rootClass}__no-margin`]: noMargin,
        [`${rootClass}__cursor-pointer`]: cursorPointer,
      })}
      type={sizeToTypographyType[size]}
      dataTest={dataTest}
      weight={textWeight}
    />
  )
}

export default Pill
