import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useApolloClient, useMutation } from '@apollo/client'
import { useImageLibraryFolders } from '@components/AssetPickers/ImagePickerModal/graphQL/ImagePickerModal.graphQL'
import { ImagePickerModalInsertCallback, ImagePickerSource } from '@components/AssetPickers/ImagePickerModal/utils/ImagePickerModal.utils'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import uploadImage from '@graphql/mutations/uploadImage'
import { UploadImageMutation, UploadImageMutationVariables } from '@graphql/types/mutation-types'
import { ImageUploadResponse } from '@graphql/types/query-types'
import InsertImageModal, { ImageToInsertParams } from '@src/pages/EmailComposer/EmailModals/components/ImageFileModal/InsertImageModal'
import { IFilePickerCallback } from '@src/pages/EmailComposer/utils/BeeEditor.types'
import { findDynamicImageFields } from '@src/pages/EmailComposer/utils/EmailComposer.utils'
import { filterNotEmptyArray } from '@utils/array'
import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'
import { StatusToastType } from '@utils/interface/StatusToast'

import { ImageFallbackModal } from './components/ImageFallbackModal/ImageFallbackModal'
import { addSourceMetadataToImageURl, getSourceMetadataFromImageURl, InsertImageFlow } from './utils/InsertImageModal.utils'

import './InsertImageModal.css'

export type SelectDynamicItemsState = {
  selectedItemDynamicImage?: SelectedDynamicImage
  selectedItemFallbackImage?: SelectedFallbackImage
}
export interface InsertImageModalContainerProps {
  handleImagePickerDone: IFilePickerCallback
  imageUrlToReplace?: string
  isStory?: boolean
  selectedDynamicModalFields?: SelectDynamicItemsState
  allowSvg?: boolean
}

export interface SelectedDynamicImage {
  id: string
  title: string
}

export interface SelectedFallbackImage {
  id: string
  url: string
  title: string
}

const getInitialFlow = (source?: ImagePickerSource) => {
  switch (source) {
    case ImagePickerSource.DYNAMIC_IMAGE:
      return InsertImageFlow.DYNAMIC_IMAGE
    case ImagePickerSource.DYNAMIC_LOGO:
      return InsertImageFlow.DYNAMIC_LOGO
    case ImagePickerSource.EXTERNAL:
      return InsertImageFlow.URL
    default:
      return InsertImageFlow.BROWSE
  }
}

const InsertImageModalContainer: FC<InsertImageModalContainerProps> = ({ handleImagePickerDone, imageUrlToReplace, isStory, allowSvg }) => {
  const { t } = useTranslation()
  const client = useApolloClient()
  const {
    values: {
      message: { templateJson },
      landingPage: { isLandingPage },
    },
    api: { update },
  } = useComposerContext()

  const isUploadFlowRef = useRef(false)
  const isUrlFlowRef = useRef(false)
  const uploadImageDatRef = useRef<ImageUploadResponse | undefined>()
  const isLogosOptionSelectedRef = useRef(false)
  const localImageUrlToReplace = useRef('')

  const { folders } = useImageLibraryFolders(isLandingPage)

  const [isInsertButtonEnabled, setIsInsertButtonEnabled] = useState(false)
  const {
    source,
    url: urlToReplace,
    listId,
    dynamicField,
    fallbackTitle,
  } = getSourceMetadataFromImageURl(localImageUrlToReplace.current ? localImageUrlToReplace.current : imageUrlToReplace)

  const initialFlow: InsertImageFlow = getInitialFlow(source)
  const initialFlowIsURl = initialFlow === InsertImageFlow.URL
  const [toast, setToastStatus] = useState<StatusToastType>()
  const [imageParams, setImageParams] = useState<ImageToInsertParams>({ url: initialFlowIsURl ? urlToReplace : undefined })
  const [flow, setFlow] = useState<InsertImageFlow>(initialFlow)
  const [closeModal, setCloseModal] = useState(false)
  const [hideDynamicImageOption, setHideDynamicImageOption] = useState(false)

  const { selectedItemDynamicImage, selectedItemFallbackImage } = findDynamicImageFields(templateJson, listId, dynamicField) ?? {}

  const [selectedDynamicItems, setSelectedDynamicItems] = useState<SelectDynamicItemsState>({
    selectedItemDynamicImage,
    ...(selectedItemFallbackImage?.url && { selectedItemFallbackImage: { ...selectedItemFallbackImage, title: fallbackTitle ?? '' } }),
  })

  const isDynamicImageFlow = flow === InsertImageFlow.DYNAMIC_IMAGE || flow === InsertImageFlow.DYNAMIC_LOGO
  const isSourceDynamicFlow = source === ImagePickerSource.DYNAMIC_IMAGE || source === ImagePickerSource.DYNAMIC_LOGO
  const isUploadFlow = flow === InsertImageFlow.UPLOAD
  const isURLFlow = flow === InsertImageFlow.URL
  const isBrowseFlow = flow === InsertImageFlow.BROWSE
  const isReplaceFlow = !!imageUrlToReplace
  const showInsertButtonEnabled = isUploadFlowRef.current || isInsertButtonEnabled || isUrlFlowRef.current

  const [dynamicImageModalStates, setDynamicImageModalStates] = useState({
    showPickerModal: false,
    showDynamicImageModal: isDynamicImageFlow,
    showListFieldModal: false,
  })

  const handleCancel = useCallback(() => {
    handleImagePickerDone(undefined)
    if (isDynamicImageFlow || isSourceDynamicFlow) {
      setDynamicImageModalStates((prevState) => ({ ...prevState, showDynamicImageModal: false }))
      setCloseModal(true)
    }
  }, [handleImagePickerDone, isDynamicImageFlow, isSourceDynamicFlow])

  const handleBack = useCallback(
    (backFromFallback?: boolean) => {
      const hasSelectedFallbackItem = !!selectedDynamicItems.selectedItemFallbackImage?.title
      const hasSelectedDynamicItem = !!selectedDynamicItems.selectedItemFallbackImage?.title

      if (backFromFallback) {
        setIsInsertButtonEnabled(!hasSelectedFallbackItem)
      } else if (!hasSelectedFallbackItem && !hasSelectedDynamicItem) {
        setIsInsertButtonEnabled(false)
      } else {
        setIsInsertButtonEnabled(!backFromFallback || !hasSelectedFallbackItem)
      }

      setFlow(InsertImageFlow.BROWSE)
      setDynamicImageModalStates((prevState) => ({ ...prevState, showDynamicImageModal: false }))
      if (isSourceDynamicFlow || selectedDynamicItems.selectedItemFallbackImage?.title) {
        if (backFromFallback && !isUploadFlowRef.current) {
          localImageUrlToReplace.current = addSourceMetadataToImageURl({
            source: isLogosOptionSelectedRef.current ? ImagePickerSource.DYNAMIC_LOGO : ImagePickerSource.DYNAMIC_IMAGE,
            selectedField: selectedDynamicItems?.selectedItemDynamicImage?.title,
            listId: selectedDynamicItems?.selectedItemDynamicImage?.id,
            id: selectedDynamicItems.selectedItemFallbackImage?.id,
            url: selectedDynamicItems?.selectedItemFallbackImage?.url as string,
            fallbackTitle: selectedDynamicItems.selectedItemFallbackImage?.title,
          })
        }
      }
    },
    [
      isSourceDynamicFlow,
      selectedDynamicItems?.selectedItemDynamicImage?.id,
      selectedDynamicItems?.selectedItemDynamicImage?.title,
      selectedDynamicItems.selectedItemFallbackImage?.id,
      selectedDynamicItems.selectedItemFallbackImage?.title,
      selectedDynamicItems.selectedItemFallbackImage?.url,
    ]
  )

  const [uploadImageMutation, { loading }] = useMutation<UploadImageMutation, UploadImageMutationVariables>(uploadImage, {
    client,
    fetchPolicy: 'no-cache',
  })

  const onUploadImage = useCallback(
    (variables: UploadImageMutationVariables) =>
      uploadImageMutation({ variables }).then(({ data }) => {
        if (!data) {
          return Promise.reject()
        }
        return data
      }),
    [uploadImageMutation]
  )

  const folderOptions: SelectV2SingleOption[] = folders
    .map((folder) =>
      folder?.name ? { label: folder.name, value: typeof folder.folderId === 'number' ? folder.folderId.toString() : folder.name } : undefined
    )
    .filter(filterNotEmptyArray)

  const handleInsertFromPicker = useCallback<ImagePickerModalInsertCallback>(
    (data, isLogos) => {
      isLogosOptionSelectedRef.current = !!isLogos
      setIsInsertButtonEnabled(false)

      if (data) {
        const { id, modified, title, linkUrl = '', url } = data

        if (hideDynamicImageOption) {
          setSelectedDynamicItems((prevState) => ({ ...prevState, selectedItemFallbackImage: { id, title, url: isLogos ? url : linkUrl } }))
          setDynamicImageModalStates((prevState) => ({ ...prevState, showDynamicImageModal: true }))
          setFlow(isLogosOptionSelectedRef.current ? InsertImageFlow.DYNAMIC_LOGO : InsertImageFlow.DYNAMIC_IMAGE)
        } else {
          const source = hideDynamicImageOption ? ImagePickerSource.DYNAMIC_IMAGE : isLogos ? ImagePickerSource.LOGOS : ImagePickerSource.LIBRARY
          handleImagePickerDone({ url: addSourceMetadataToImageURl({ url: isLogos ? url : linkUrl, source, id, modified }) })
        }
      } else {
        handleImagePickerDone(undefined)
      }
    },
    [handleImagePickerDone, hideDynamicImageOption]
  )
  const handleInsert = useCallback(() => {
    const { imageBase64, imageType, title, folder, validUrl } = imageParams

    setIsInsertButtonEnabled(false)
    switch (true) {
      case isUrlFlowRef.current && !selectedDynamicItems.selectedItemFallbackImage?.url && !!(isURLFlow && validUrl):
        handleImagePickerDone({
          url: addSourceMetadataToImageURl({
            url: validUrl ?? '',
            source: isLogosOptionSelectedRef.current ? ImagePickerSource.DYNAMIC_LOGO : ImagePickerSource.DYNAMIC_IMAGE,
            id: '',
            selectedField: selectedDynamicItems?.selectedItemDynamicImage?.title,
            listId: selectedDynamicItems?.selectedItemDynamicImage?.id,
            fallbackTitle: validUrl,
          }),
        })

        isUrlFlowRef.current = false
        break
      case isUploadFlowRef.current && !selectedDynamicItems.selectedItemFallbackImage?.url:
        handleImagePickerDone({
          url: addSourceMetadataToImageURl({
            source: ImagePickerSource.DYNAMIC_IMAGE,
            selectedField: selectedDynamicItems?.selectedItemDynamicImage?.title,
            listId: selectedDynamicItems?.selectedItemDynamicImage?.id,
            id: uploadImageDatRef.current?.id,
            url: uploadImageDatRef.current?.linkUrl ?? '',
            fallbackTitle: uploadImageDatRef.current?.title,
          }),
        })
        isUploadFlowRef.current = false
        break
      case isDynamicImageFlow:
        const url = addSourceMetadataToImageURl({
          source: isLogosOptionSelectedRef.current ? ImagePickerSource.DYNAMIC_LOGO : ImagePickerSource.DYNAMIC_IMAGE,
          selectedField: selectedDynamicItems?.selectedItemDynamicImage?.title,
          listId: selectedDynamicItems?.selectedItemDynamicImage?.id,
          id: selectedDynamicItems.selectedItemFallbackImage?.id,
          url: selectedDynamicItems?.selectedItemFallbackImage?.url as string,
          fallbackTitle: selectedDynamicItems.selectedItemFallbackImage?.title,
        })
        handleImagePickerDone({ url })
        update({ message: { templateJson } })
        setDynamicImageModalStates((prevState) => ({ ...prevState, showDynamicImageModal: false }))
        break

      case isUploadFlow:
        if (imageBase64 && imageType) {
          const setUploadError = () =>
            setToastStatus({
              showStatus: true,
              statusMessage: t('Image upload failed. Please try again.'),
              successStatus: false,
            })
          onUploadImage({ base64File: imageBase64, extension: imageType, folderName: folder?.label, fileName: title })
            .then((data) => {
              const { linkUrl: url, id, modified, title } = data.uploadImage

              if (url && id) {
                if (!(isSourceDynamicFlow && hideDynamicImageOption)) {
                  handleImagePickerDone({
                    url: addSourceMetadataToImageURl({ url, source: ImagePickerSource.LIBRARY, id, modified }),
                  })
                } else {
                  isUploadFlowRef.current = true
                  uploadImageDatRef.current = data.uploadImage
                  localImageUrlToReplace.current = addSourceMetadataToImageURl({
                    id,
                    url,
                    source: ImagePickerSource.DYNAMIC_IMAGE,
                    selectedField: selectedDynamicItems?.selectedItemDynamicImage?.title,
                    listId: selectedDynamicItems?.selectedItemDynamicImage?.id,
                    fallbackTitle: title,
                  })
                  setFlow(InsertImageFlow.DYNAMIC_IMAGE)
                  setSelectedDynamicItems((prevState) => ({ ...prevState, selectedItemFallbackImage: { id, title, url } }))
                }
              } else {
                setUploadError()
              }
            })
            .catch(() => setUploadError())
        }
        break

      case !!(isURLFlow && validUrl):
        if (isDynamicImageFlow || isSourceDynamicFlow || !!hideDynamicImageOption) {
          isUrlFlowRef.current = true
          setFlow(InsertImageFlow.DYNAMIC_IMAGE)
          setSelectedDynamicItems((prevState) => ({
            ...prevState,
            selectedItemFallbackImage: { id: '', title: validUrl ?? '', url: validUrl ?? '' },
          }))
        } else {
          handleImagePickerDone({ url: addSourceMetadataToImageURl({ url: validUrl ?? '', source: ImagePickerSource.EXTERNAL }) })
        }
        break

      default:
        break
    }

    if ((isDynamicImageFlow || isSourceDynamicFlow) && !isUploadFlow && !(isURLFlow && validUrl)) {
      setCloseModal(true)
    }
  }, [
    imageParams,
    isDynamicImageFlow,
    isSourceDynamicFlow,
    isUploadFlow,
    isURLFlow,
    selectedDynamicItems.selectedItemFallbackImage?.url,
    selectedDynamicItems.selectedItemFallbackImage?.id,
    selectedDynamicItems.selectedItemFallbackImage?.title,
    selectedDynamicItems?.selectedItemDynamicImage?.title,
    selectedDynamicItems?.selectedItemDynamicImage?.id,
    handleImagePickerDone,
    update,
    templateJson,
    hideDynamicImageOption,
    onUploadImage,
    t,
  ])

  useEffect(() => {
    if (source === ImagePickerSource.DYNAMIC_LOGO) {
      isLogosOptionSelectedRef.current = true
    }
  }, [source])

  useEffect(() => {
    if (isDynamicImageFlow) {
      setDynamicImageModalStates((prevState) => ({ ...prevState, showDynamicImageModal: true }))
    }
  }, [isDynamicImageFlow])

  if (closeModal) {
    return null
  }

  return dynamicImageModalStates.showDynamicImageModal ? (
    <ImageFallbackModal
      selectedItemDynamicImage={selectedDynamicItems?.selectedItemDynamicImage}
      selectedItemFallbackImage={selectedDynamicItems?.selectedItemFallbackImage}
      onBack={handleBack}
      onCancel={handleCancel}
      onInsert={handleInsert}
      dynamicImageModalStates={dynamicImageModalStates}
      setSelectedDynamicItems={setSelectedDynamicItems}
      setDynamicImageModalStates={setDynamicImageModalStates}
      setHideDynamicImageOption={setHideDynamicImageOption}
    />
  ) : (
    <InsertImageModal
      toast={toast}
      loading={loading}
      folders={folderOptions}
      imageParams={imageParams}
      isBrowseFlow={isBrowseFlow}
      isReplaceFlow={isReplaceFlow}
      isURLFlow={isURLFlow}
      isUploadFlow={isUploadFlow}
      isStory={isStory}
      setFlow={setFlow}
      handleInsert={handleInsert}
      setToastStatus={setToastStatus}
      setImageParams={setImageParams}
      handleImagePickerDone={handleImagePickerDone}
      handleInsertFromPicker={handleInsertFromPicker}
      setDynamicImageModalStates={setDynamicImageModalStates}
      showImagePickerBackButton={hideDynamicImageOption}
      hideDynamicImageOption={hideDynamicImageOption}
      showInsertButtonEnabled={showInsertButtonEnabled}
      isLandingPage={isLandingPage}
      imageUrlToReplace={localImageUrlToReplace.current ? localImageUrlToReplace.current : imageUrlToReplace}
      allowSvg={allowSvg}
    />
  )
}

export default InsertImageModalContainer
