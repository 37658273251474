import React, { FC, useCallback } from 'react'

import { useApolloClient, useMutation } from '@apollo/client'
import { CustomField } from '@components/KeyValuePicker/KeyValuePicker'
import addListContacts from '@graphql/mutations/addListContacts'
import { AddContactsInput, AddListContactsMutation, AddListContactsMutationVariables } from '@graphql/types/mutation-types'
import QuickAddContact from '@src/pages/EmailComposer/components/SendDetails/components/QuickAddContact/QuickAddContact'
import { MessageRecipient } from '@src/pages/EmailComposer/utils/EmailComposer.types'

interface QuickAddContactContainerProps {
  handleCancel: () => void
  options: MessageRecipient[]
  handleData: (data?: { listId: string; size: number; count: number }) => void
  className?: string
  dataTest?: string
}

const QuickAddContactContainer: FC<QuickAddContactContainerProps> = ({ handleData, ...rest }) => {
  const client = useApolloClient()

  const [addContacts, { loading }] = useMutation<AddListContactsMutation, AddListContactsMutationVariables>(addListContacts, {
    client,
    fetchPolicy: 'no-cache',
  })

  const handleAddContacts = useCallback(
    (contacts: CustomField[][], listId?: string) => {
      if (listId) {
        const addContactsInput = contacts.map((row) => {
          return row.reduce(
            (acc: AddContactsInput, { id, value = '' }) => {
              if (id === 'lastName' || id === 'firstName' || id === 'email') {
                acc[id] = value || ''
              }
              return acc
            },
            { email: '' }
          )
        })
        addContacts({ variables: { srcId: listId, addContactsInput } })
          .then(({ data }) => {
            if (!!data?.addListContacts?.contacts?.length) {
              handleData({ listId, count: data.addListContacts.contacts.length, size: data.addListContacts.count ?? 0 })
            } else {
              handleData()
            }
          })
          .catch(() => handleData())
      }
    },
    [addContacts, handleData]
  )

  return <QuickAddContact handleAddContacts={handleAddContacts} loading={loading} {...rest} />
}

export default QuickAddContactContainer
