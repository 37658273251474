import React, { FC } from 'react'

import Button, { ButtonProps, ButtonType } from '@components/Button/Button'
import Svg, { SvgNames, SvgType } from '@components/Svg'

type CloseButtonProps = Omit<ButtonProps, 'buttonType' | 'children'>

const CloseButton: FC<CloseButtonProps> = (props: CloseButtonProps) => {
  const { dataTest = 'close-button', ...rest } = props
  return (
    <Button buttonType={ButtonType.NAVIGATION_BUTTON} dataTest={dataTest} {...rest}>
      <Svg name={SvgNames.close} type={SvgType.LARGER_ICON} dataTest={`${dataTest}-svg`} />
    </Button>
  )
}

export default CloseButton
