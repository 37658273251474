import React from 'react'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextAlign, TextType, TextWeight, TypographyProps } from '@components/Typography/Typography'

const rootClass = 'data-analytics'

export const renderArrow = (isPositive: boolean) => (
  <Svg name={isPositive ? SvgNames.arrowUpPlain : SvgNames.arrowDownPlain} type={SvgType.LARGER_ICON} dataTest={`${rootClass}__card-arrow-icon`} />
)

export const renderInfo = (tooltipTypographyProps: TypographyProps) => (
  <Tooltip
    className={`${rootClass}__card-info-tooltip`}
    trigger={<Svg name={SvgNames.info} type={SvgType.ICON} dataTest={`${rootClass}__card-info-icon`} />}
  >
    <Typography
      type={TextType.BODY_TEXT_WHITE_SMALL}
      lineHeight={LineHeight.MEDIUM_SMALL}
      textAlign={TextAlign.CENTER}
      tagProps={{ bold: { weight: TextWeight.BOLD } }}
      {...tooltipTypographyProps}
    />
  </Tooltip>
)
