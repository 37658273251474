import { gql } from '@apollo/client'

export default gql`
  mutation createApiClientCredentials($force: Boolean) {
    createApiClientCredentials(force: $force) {
      statusCode
      reasonPhrase
    }
  }
`
