import { ChartEventsOptions, PlotOptions, SVGPathArray } from 'highcharts'

export const getBackgroundColor = (percentage: number, isCPMAccount?: boolean) => {
  if (isCPMAccount ? percentage >= 100 : percentage >= 95) {
    return 'rgba(242, 86, 86, 0.25)'
  } else if (percentage >= 90) {
    return 'rgba(255, 205, 0, 0.25)'
  } else {
    return 'rgba(0, 186, 190, 0.2)'
  }
}
export const getTrackColor = (percentage: number, isCPMAccount?: boolean) => {
  if (percentage < 90) {
    return '#00BABE'
  } else if (isCPMAccount ? percentage < 100 : percentage < 95) {
    return '#FFCD00'
  } else {
    return '#F25656'
  }
}

export const plotOptions = {
  solidgauge: {
    dataLabels: {
      enabled: false,
    },
    linecap: 'round',
    stickyTracking: false,
    rounded: true,
  },
} as PlotOptions

export const eventsOptions: ChartEventsOptions = {
  render: function () {
    const path: SVGPathArray = [
      ['M', -5, 0],
      ['L', 5, 0],
      ['M', 0, -5],
      ['L', 5, 0, 0, 5],
    ]
    this.renderer
      .path(path)
      .attr({
        stroke: '#FFFFFF',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
        'stroke-width': 1,
        zIndex: 10,
      })
      .translate(this.plotWidth / 2, this.plotHeight / 8.5)
      .add(this.series[0].graph)
  },
}
