import { Dispatch, MutableRefObject, SetStateAction, useEffect } from 'react'

import { Status } from '@components/StatusToast/StatusToast'
import validateDataTypes from '@graphql/microservices/list/validateDataTypes'
import { FieldValidationResult, UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { MappingScreenValues } from '@src/pages/importcontacts/components/MappingScreen/MappingScreenContainer'
import { getDuplicates } from '@src/pages/importcontacts/components/MappingScreen/utils/MappingScreen.utils'
import { ColumnStateType, DataTypeList, MappingPreview, VALIDATION_MESSAGE } from '@src/pages/importcontacts/context/ImportContactsContext'
import { getMappingValidationMessageUtils } from '@src/pages/importcontacts/utils/ImportContactsContainerUtils'
import { EXTERNAL_ID_FIELD_KEY } from '@src/pages/ImportContactsV2/utils/ImportContactsV2.constants'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { useUnifiedContactList } from '@utils/hooks/useUnifiedContactList'
import { editElement } from '@utils/utils'

type SimplifiedMappingScreenValues = Omit<MappingScreenValues, 'hasHeaders'>

interface FieldMappingValidatorProps<T extends SimplifiedMappingScreenValues> {
  containerValues: SimplifiedMappingScreenValues
  setContainerValues: Dispatch<SetStateAction<T>>
  setStatusToast: (statusToast: { statusMessage: VALIDATION_MESSAGE; status: Status; showStatusToast: boolean }) => void
  validationProcessRef: MutableRefObject<{ inProgress: boolean; reviewClicked: boolean }>
}

const OBJECT_TYPE_FIELD_NAME = 'ObjectType'

export const useFieldMappingValidator = <T extends SimplifiedMappingScreenValues>({
  containerValues,
  setStatusToast,
  validationProcessRef,
  setContainerValues,
}: FieldMappingValidatorProps<T>) => {
  const { mappingPreview, previewRecords, nonDeletedFields, deletedFields, inputOnBlurTriggered } = containerValues

  const { client: listService } = useMicroserviceClient({ serviceName: MicroserviceClients.LIST })

  const { getUnifiedListFieldMappingsRequest } = useUnifiedContactList({ fetchOnMount: false, getDeletedMappings: true })

  const getDuplicatedMappingsDisplayNames = (mappings: UnifiedListFieldMapping[] = nonDeletedFields) => {
    const customFieldsNames = mappingPreview.filter(({ isCustom }) => isCustom).map(({ friendlyName }) => friendlyName)
    const mappingsDisplayNames = mappings.map(({ displayName }) => displayName)
    return customFieldsNames.filter((customField) => mappingsDisplayNames.includes(customField))
  }

  const checkMappingDuplication = (field: MappingPreview) => {
    const { friendlyName, displayName, isCustom } = field
    const isDuplicate = getDuplicates(containerValues.mappingPreview).includes(displayName || friendlyName)
    const isNewCustomFieldInUse = !isDuplicate && getDuplicatedMappingsDisplayNames().includes(displayName || friendlyName)
    const isNewCustomFieldDeleted = !isDuplicate && getDuplicatedMappingsDisplayNames(deletedFields).includes(displayName || friendlyName)
    return {
      ...field,
      isDuplicate,
      isNewCustomFieldInUse: isCustom && isNewCustomFieldInUse,
      isNewCustomFieldDeleted: isCustom && isNewCustomFieldDeleted,
    }
  }

  const checkDuplicates = () => {
    setContainerValues((containerValues) => ({
      ...containerValues,
      inputOnBlurTriggered: false,
      mappingPreview: containerValues.mappingPreview.map(checkMappingDuplication),
    }))
  }

  const updateStatusToast = () => {
    const statusMessage = getMappingValidationMessageUtils(mappingPreview, false, getDuplicates, getDuplicatedMappingsDisplayNames)
    const statusToast =
      statusMessage === VALIDATION_MESSAGE.VALIDATION_SUCCESFUL
        ? { statusMessage, status: Status.SUCCESS, showStatusToast: true }
        : { statusMessage, status: Status.FAIL, showStatusToast: true }
    setStatusToast(statusToast)
  }

  const getValidationFields = (index: number) => {
    const type = DataTypeList.find((type) => type.id === mappingPreview[index].dataType)
    if (type && (!type.formatOptions || (type.formatOptions && mappingPreview[index].dataType && mappingPreview[index].dataFormat))) {
      return {
        dataType: mappingPreview[index].dataType,
        format: mappingPreview[index].dataFormat || null,
        dataValues: previewRecords.map((previewRecord) => previewRecord[index].value),
      }
    } else {
      return false
    }
  }

  const getColumnState = (validationResults: FieldValidationResult[]) => {
    const numInvalidRows = validationResults.reduce((acc: number, columnResult: FieldValidationResult) => acc + (!columnResult.valid ? 1 : 0), 0)
    return numInvalidRows > 0 ? ColumnStateType.WARNING : ColumnStateType.SUCCESS
  }

  const updateMappingPreviewColumnState = (validationResults: FieldValidationResult[], previewRecordsColumnIndex: number) => {
    const { isCustom, dataType } = mappingPreview[previewRecordsColumnIndex]
    if (!isCustom || dataType) {
      setContainerValues((containerValues) => ({
        ...containerValues,
        mappingPreview: editElement(
          previewRecordsColumnIndex,
          { state: validationResults.length ? getColumnState(validationResults) : ColumnStateType.SUCCESS },
          containerValues.mappingPreview
        ),
        previewRecords: containerValues.previewRecords.map((previewRecord, index) =>
          editElement(previewRecordsColumnIndex, { hasError: validationResults.length ? !validationResults[index].valid : false }, previewRecord)
        ),
      }))
    }
  }

  const validateColumn = (index: number) => {
    if (mappingPreview[index].dataType !== 'TEXT') {
      const validationFields = getValidationFields(index)
      if (validationFields && !inputOnBlurTriggered) {
        validationProcessRef.current = { ...validationProcessRef.current, inProgress: true }
        listService
          .query({
            query: validateDataTypes,
            fetchPolicy: 'network-only',
            variables: {
              dataTypeValues: validationFields,
            },
          })
          .then(({ data }) => {
            updateMappingPreviewColumnState(data.validateDataTypes, index)
            if (validationProcessRef.current.reviewClicked && validationProcessRef.current.inProgress) {
              updateStatusToast()
            }
            validationProcessRef.current = { inProgress: false, reviewClicked: false }
          })
      } else {
        validationProcessRef.current = { inProgress: false, reviewClicked: false }
      }
    } else {
      updateMappingPreviewColumnState([], index)
    }
    checkDuplicates()
  }

  const validateAllFields = () => {
    setContainerValues((containerValues) => ({ ...containerValues, validateAllTrigger: false }))
    mappingPreview.forEach(({ dataType, index }) => !!dataType && validateColumn(index))
  }

  const loadFields = () => {
    getUnifiedListFieldMappingsRequest().then((fields) => {
      const { visibleFields, deletedFields, nonDeletedFields } = fields.reduce<{
        deletedFields: UnifiedListFieldMapping[]
        nonDeletedFields: UnifiedListFieldMapping[]
        visibleFields: UnifiedListFieldMapping[]
      }>(
        (acc, field) =>
          field.deleted
            ? {
                ...acc,
                deletedFields: [...acc.deletedFields, field],
              }
            : {
                ...acc,
                nonDeletedFields: [...acc.nonDeletedFields, field],
                ...((!field.hidden || field.standardFieldKey === EXTERNAL_ID_FIELD_KEY) && field.displayName !== OBJECT_TYPE_FIELD_NAME
                  ? { visibleFields: [...acc.visibleFields, field] }
                  : {}),
              },
        { deletedFields: [], nonDeletedFields: [], visibleFields: [] }
      )
      setContainerValues((containerValues) => ({
        ...containerValues,
        nonDeletedFields,
        deletedFields,
        visibleFields: visibleFields.sort((a: UnifiedListFieldMapping, b: UnifiedListFieldMapping) =>
          (a.displayName ? a.displayName : '') < (b.displayName ? b.displayName : '') ? -1 : 1
        ),
      }))
    })
  }

  useEffect(() => {
    loadFields()
  }, [])

  return {
    validateColumn,
    validateAllFields,
    loadFields,
  }
}
