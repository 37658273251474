import React, { FC, useCallback, useMemo } from 'react'

import { TableV2 } from '@components/TableV2/TableV2'
import { TableV2Props } from '@components/TableV2/tableV2TS/interfaces'
import { useTranslation } from '@const/globals'
import { BeeTemplate } from '@graphql/types/microservice/email-management-types'
import { Row as RowV2 } from '@tanstack/table-core/build/lib/types'

import { getImagePickerRowActions, getTemplateCatalogTableColumns } from './TemplateCatalogModalTableView.utils'
import { useTemplateCatalogModalContentContext } from '../../TemplateCatalogModal.context'

interface TemplateCatalogModalTableViewProps {
  onSelect: (item: BeeTemplate) => void
}

const TemplateCatalogModalTableView: FC<TemplateCatalogModalTableViewProps> = ({ onSelect }) => {
  const { t } = useTranslation()

  const { selectedItem, catalogItems, templatesLoading, searchText, loadingNextData, onPreview } = useTemplateCatalogModalContentContext()

  const defaultSelectedRows = useMemo(() => (selectedItem?.id ? { [selectedItem.id]: true } : undefined), [selectedItem])

  const handleTemplateSelect = useCallback(
    (rowIds: string[], rows: RowV2<BeeTemplate>[]) => {
      const templateId = rowIds[0]
      if (templateId) {
        const selectedRow = rows.find(({ id }) => id === templateId)?.original
        if (selectedRow) {
          onSelect(selectedRow)
        }
      }
    },
    [onSelect]
  )

  const tableV2Props: TableV2Props<BeeTemplate> = {
    columns: getTemplateCatalogTableColumns(t, searchText) as any,
    onRowSelectionChanged: handleTemplateSelect,
    data: templatesLoading ? [] : catalogItems,
    rowActions: getImagePickerRowActions(t, onPreview),
    loading: templatesLoading || loadingNextData,
    enableRadio: true,
    enableStickyHeader: true,
    withoutBorders: true,
    enableLazyLoading: true,
    manualSorting: true,
    resetSorting: false,
    enableInsideLoader: true,
    rowUniqueIdKey: 'id',
    defaultSelectedRows,
  }

  return <TableV2 {...tableV2Props} />
}

export default TemplateCatalogModalTableView
