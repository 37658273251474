import { gql } from '@apollo/client'

export default gql`
  query searchByMultipleFields(
    $folderSubTypes: [String]
    $folderDTO: FolderDTOInput
    $searchHierarchy: Boolean
    $pageNumber: Int
    $folder: Long
    $allItems: Boolean
    $query: String
    $pageSize: Int
    $subTypes: [String]
    $fields: [String]
    $type: String
  ) {
    searchByMultipleFields(
      folderSubTypes: $folderSubTypes
      folderDTO: $folderDTO
      searchHierarchy: $searchHierarchy
      pageNumber: $pageNumber
      folder: $folder
      allItems: $allItems
      query: $query
      pageSize: $pageSize
      subTypes: $subTypes
      fields: $fields
      type: $type
    ) {
      accountId
      createdTime
      enabled
      folders {
        accountId
        createdTime
        depth
        enabled
        id
        itemCount
        name
        parentId
        position
        subFolders {
          accountId
          createdTime
          depth
          enabled
          id
          itemCount
          name
          parentId
          position
          subFolders {
            accountId
            createdTime
            depth
            enabled
            id
            itemCount
            name
            parentId
            position
            subTypeDTOS {
              count
              itemType
              name
            }
            updatedTime
            views
          }
          updatedTime
          views
        }
        subTypeDTOS {
          count
          itemType
          name
        }
        updatedTime
        views
      }
      id
      items {
        accountId
        authorId
        createdTime
        enabled
        externalId
        favoriteUsers
        folderId
        hasToAudit
        id
        isFavorite
        item
        itemLevel
        labels {
          accountId
          color
          createdTime
          enabled
          id
          itemCount
          name
          updatedTime
          views
        }
        parentId
        position
        rootParent
        subTypeDTO {
          count
          itemType
          name
        }
        updatedTime
        username
        views
      }
      labels {
        accountId
        color
        createdTime
        enabled
        id
        itemCount
        name
        updatedTime
        views
      }
      query
      totalCount
      updatedTime
    }
  }
`
