import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import DropDown from '@components/DropDown'
import { DropDownType } from '@components/DropDown/DropDown'
import InputV2 from '@components/InputV2/InputV2'
import InputWithColorPicker from '@components/InputWithColorPicker/InputWithColorPicker'
import ScrollArea from '@components/ScrollArea/ScrollArea'
import { SvgNames } from '@components/Svg/index'
import TagManagerAppliedItem from '@components/TagManager/components/TagManagerAppliedItem/TagManagerAppliedItem'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { NO_COLOR, sortTagsByName, TagColorsObjects } from '@utils/tags'

import Svg, { SvgType } from '../Svg'

import './TagManager.css'

interface Props {
  className?: string
  dataTest?: string
  appliedTags: LabelDto[]
  buttonText?: string
  createTagsAllowed?: boolean
  onApplyAndRemove: (tagsToApply: LabelDto[], tagsToRemove: number[]) => void
  onCreate?: (color: string, name: string) => void
  onToggleDropDown?: (open: boolean) => void
  tags: LabelDto[]
  title?: string
  allowExternalOpen?: boolean
  externalOpen?: boolean
  trigger: ReactNode
  dropDownContentProps?: Partial<DropdownMenu.DropdownMenuContentProps>
}

interface State {
  appliedTagsChanged: boolean
  selectedColor?: string
  dropDownOpen: boolean
  enterPressed: boolean
  filteredTags: LabelDto[]
  tagInput: string
  updatedAppliedTags: LabelDto[]
  updatedTags: LabelDto[]
  warning: string
}

const rootClass = 'tag-manager'
const EMPTY_INPUT_WARNING = 'Tag names must be at least 1 character'
const INPUT_EXISTS = 'That tag name already exists'

const TagManager: FC<Props> = (props: Props) => {
  const {
    appliedTags = [],
    buttonText,
    createTagsAllowed = true,
    onCreate,
    onToggleDropDown,
    onApplyAndRemove,
    tags,
    title,
    allowExternalOpen = false,
    externalOpen = false,
    trigger,
    dataTest = rootClass,
    className = '',
    dropDownContentProps,
  } = props
  const [state, setState] = useState<State>({
    appliedTagsChanged: false,
    selectedColor: NO_COLOR,
    dropDownOpen: allowExternalOpen ? externalOpen : false,
    enterPressed: false,
    filteredTags: [],
    tagInput: '',
    updatedAppliedTags: [],
    updatedTags: [],
    warning: '',
  })
  const { appliedTagsChanged, selectedColor, dropDownOpen, enterPressed, filteredTags, tagInput, updatedAppliedTags, updatedTags, warning } = state
  const { t } = useTranslation()
  const notAppliedTags = tags.filter(({ name }) => appliedTags.every(({ name: appliedTagName }) => name !== appliedTagName))
  const trimmedInput = tagInput.trim()
  const canCreate = createTagsAllowed && trimmedInput && !warning
  const inputRef = useRef<HTMLInputElement>(null)

  const sortAndFilter = (tagsToSort: LabelDto[]) => {
    const sorted = sortTagsByName(tagsToSort)
    return selectedColor === NO_COLOR
      ? sorted
      : sorted.filter(({ color, name }) => (tagInput ? name?.toLowerCase().includes(tagInput.toLowerCase()) : color === selectedColor))
  }

  useEffect(() => {
    if (allowExternalOpen) {
      setState((state) => ({ ...state, dropDownOpen: externalOpen }))
    }
  }, [allowExternalOpen, externalOpen])

  useEffect(() => {
    if (dropDownOpen) {
      setState({
        ...state,
        updatedAppliedTags: sortAndFilter(appliedTags),
        updatedTags: sortAndFilter(notAppliedTags),
      })
    }
  }, [dropDownOpen])

  useEffect(() => {
    if (enterPressed) {
      handleApply()
    }
  }, [enterPressed])

  useEffect(() => inputRef.current?.focus(), [filteredTags])

  useEffect(() => {
    if (updatedAppliedTags.length > 0 || updatedTags.length > 0) {
      setState({
        ...state,
        appliedTagsChanged: JSON.stringify(updatedAppliedTags) !== JSON.stringify(sortAndFilter(appliedTags)),
        filteredTags: [...sortAndFilter(updatedAppliedTags), ...sortAndFilter(updatedTags)],
      })
    }
  }, [updatedAppliedTags, updatedTags])

  const applyTag = (name: string) => {
    const isApplied = updatedAppliedTags.some(({ name: tagName }) => tagName === name)
    const tag = (filteredTags.find(({ name: tagName }) => tagName === name) || {
      name,
      color: selectedColor,
    }) as LabelDto
    setState({
      ...state,
      updatedAppliedTags: isApplied ? updatedAppliedTags.filter(({ name: tagName }) => tagName !== name) : [...updatedAppliedTags, tag],
      updatedTags: isApplied ? [...updatedTags, tag] : updatedTags.filter(({ name: tagName }) => tagName !== name),
    })
  }

  const createAndApply = () => {
    const tagExists = updatedAppliedTags.some(({ name }) => name === trimmedInput) || updatedTags.some(({ name }) => name === trimmedInput)
    if (!tagExists) {
      toggleDropDown()
      // Negative id will denote a tag that hasn't been created yet
      const tempId = -1 * new Date().valueOf()
      setState((state) => {
        return {
          ...state,
          enterPressed: true,
          updatedAppliedTags: [...updatedAppliedTags, { color: selectedColor, name: trimmedInput, id: tempId } as LabelDto],
        }
      })
    }
  }

  const filterByColor = (colorPicked: string, input?: string) =>
    setState((state) => {
      const filteredTags = [
        ...updatedAppliedTags.filter(({ color }) => color === colorPicked),
        ...updatedTags.filter(({ color }) => color === colorPicked),
      ]
      return {
        ...state,
        selectedColor: colorPicked,
        filteredTags,
        tagInput: input ?? tagInput,
        warning: '',
      }
    })

  const getApplyButton = () => (
    <Button
      buttonType={ButtonType.PRIMARY}
      className={classNames({ [`${rootClass}__button-disabled`]: !appliedTagsChanged })}
      disabled={!appliedTagsChanged}
      fullWidth
      onClick={handleApply}
    >
      {t(buttonText ?? 'Apply')}
    </Button>
  )

  const colorPicked = (colorPicked: string) => {
    if (!tagInput) {
      if (colorPicked === NO_COLOR) {
        setState((state) => {
          return { ...state, filteredTags: [...updatedAppliedTags, ...updatedTags] }
        })
      } else {
        filterByColor(colorPicked)
      }
    } else {
      setState({ ...state, selectedColor: colorPicked })
    }
  }

  const removeTempIds = (tempTags: LabelDto[]) => {
    // Temp IDs must be removed before passing tags to the callback that does the creation
    return tempTags.map((tag) => (tag.id < 0 ? { color: tag.color, name: tag.name } : tag))
  }

  const handleApply = () => {
    const newAppliedTags = removeTempIds(updatedAppliedTags)
    const tagsNames = appliedTags.map(({ name }) => name)
    const updatedTagsNames = newAppliedTags.map(({ name }) => name)
    const tagsToApply = newAppliedTags.filter(({ name }) => !tagsNames.includes(name))
    const tagsToRemove = appliedTags.filter(({ name }) => !updatedTagsNames.includes(name)).map(({ id }) => id)
    onApplyAndRemove(tagsToApply, tagsToRemove)
    !enterPressed && toggleDropDown()
  }

  const handleCreate = () => {
    const tagExists =
      updatedAppliedTags.some(({ name }) => name?.toLowerCase() === trimmedInput.toLowerCase()) ||
      updatedTags.some(({ name }) => name?.toLowerCase() === trimmedInput.toLowerCase())
    if (!tagExists) {
      onCreate && onCreate(selectedColor as string, trimmedInput)
      setState({
        ...state,
        updatedTags: [...updatedTags, { color: selectedColor, name: trimmedInput } as LabelDto],
      })
      applyTag(trimmedInput)
    }
  }

  const onInputChange = (tagInput: string) => {
    if (tagInput.length <= 35) {
      if (tagInput) {
        setState((state) => {
          const filteredTags = [
            ...updatedAppliedTags.filter(({ name }) => name?.toLowerCase().includes(tagInput.toLowerCase())),
            ...updatedTags.filter(({ name }) => name?.toLowerCase().includes(tagInput.toLowerCase())),
          ]
          const exists = filteredTags.some(({ name }) => name?.toLowerCase() === tagInput.toLowerCase())
          return { ...state, warning: exists ? INPUT_EXISTS : '', tagInput, filteredTags }
        })
      } else if (!tagInput) {
        setState((state) => ({ ...state, filteredTags: tags, tagInput, warning: '' }))
      } else if (selectedColor === NO_COLOR) {
        setState((state) => {
          return { ...state, filteredTags: [...updatedAppliedTags, ...updatedTags] }
        })
      } else {
        filterByColor(selectedColor as string, '')
      }
    }
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Override dropdown menu's a11y behavior
    if (e.key === 'Enter') {
      e.stopPropagation()
      canCreate && createAndApply()
      !trimmedInput && setState({ ...state, warning: EMPTY_INPUT_WARNING })
    } else if (e.key === ' ') {
      e.stopPropagation()
    }
  }

  const toggleDropDown = () => {
    onToggleDropDown && onToggleDropDown(!dropDownOpen)
    if (!allowExternalOpen) {
      setState((state) => ({ ...state, dropDownOpen: !dropDownOpen, enterPressed: false }))
    }
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <DropDown
        className={classNames(`${rootClass}__drop-down`, {
          [`${rootClass}__drop-down-open`]: dropDownOpen,
        })}
        alignContentEnd
        isOpen={dropDownOpen}
        toggleOpen={toggleDropDown}
        type={DropDownType.STYLED}
        trigger={trigger}
        dropdownContentProps={dropDownContentProps}
      >
        <div className={`${rootClass}__content`}>
          <div className={`${rootClass}__header`}>
            {!!title && <Typography text={t(title)} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.MEDIUM} />}
            {createTagsAllowed ? (
              <div className={`${rootClass}__header-tag-creation`}>
                <InputWithColorPicker
                  key={tagInput}
                  colorPicked={colorPicked}
                  colorPickerIcon={SvgNames.tag}
                  colors={TagColorsObjects}
                  defaultColor={selectedColor as string}
                  inputWithMaxLengthProps={{
                    hideLengthIfEmpty: true,
                    maxLength: 35,
                    inputProps: {
                      defaultValue: tagInput,
                      ref: inputRef,
                      onKeyDown: (e) => onKeyDown(e),
                      onChange: (e) => onInputChange(e.currentTarget.value),
                      register: inputRef,
                    },
                  }}
                />
                <Tooltip
                  position={'top'}
                  triggerClassName={classNames(`${rootClass}__header-tag-creation-button`, {
                    [`${rootClass}__header-tag-creation-button-enabled`]: canCreate,
                  })}
                  trigger={
                    <Button
                      dataTest={`${rootClass}__header-tag-creation-button`}
                      buttonType={canCreate ? ButtonType.PRIMARY : ButtonType.SECONDARY}
                      className={classNames({ [`${rootClass}__button-disabled`]: !canCreate })}
                      disabled={!canCreate}
                      onClick={() => canCreate && handleCreate()}
                    >
                      <Svg
                        className={classNames(`${rootClass}__header-tag-creation-button-icon`, {
                          [`${rootClass}__header-tag-creation-button-icon-enabled`]: canCreate,
                        })}
                        name={SvgNames.plus}
                        type={SvgType.ICON}
                      />
                    </Button>
                  }
                >
                  {t(canCreate ? 'Create tag' : warning ? warning : EMPTY_INPUT_WARNING)}
                </Tooltip>
              </div>
            ) : (
              <InputV2
                value={tagInput}
                placeholder={'Search tags'}
                fullWidth
                leadingIcon={SvgNames.search}
                onChange={(e) => onInputChange(e.currentTarget.value)}
                onKeyDown={(e) => onKeyDown(e)}
                ref={inputRef}
              />
            )}
            {canCreate && (
              <div className={`${rootClass}__header-tag-creation-info`}>
                <Typography text={t('Press')} lineHeight={LineHeight.TINY} type={TextType.BODY_TEXT_LIGHT_TINY} inline />
                <div className={`${rootClass}__header-tag-creation-info-enter`}>
                  <Typography text={t('Enter')} lineHeight={LineHeight.TINY} type={TextType.BODY_TEXT_TINY} weight={TextWeight.MEDIUM} inline />
                </div>
                <Typography text={t('to create and apply a new tag')} lineHeight={LineHeight.TINY} type={TextType.BODY_TEXT_LIGHT_TINY} inline />
              </div>
            )}
            {warning && <Typography className={`${rootClass}__error`} text={t(warning)} lineHeight={LineHeight.TINY} type={TextType.ERROR_SMALL} />}
          </div>
          {filteredTags.length > 0 && (
            <>
              <div className={`${rootClass}__division`} />
              <Typography text={`${t('Choose tags')}:`} lineHeight={LineHeight.TINY} type={TextType.BODY_TEXT_SMALL} />
            </>
          )}
          <div className={`${rootClass}__tags-wrapper`}>
            <ScrollArea showOnEvent={'scroll'}>
              <div className={`${rootClass}__tags`}>
                {tags.length === 0 || filteredTags.length === 0 ? (
                  <div className={`${rootClass}__empty-state`}>
                    <Typography text={t("We couldn't find any tags")} weight={TextWeight.MEDIUM} />
                    {canCreate && <Typography text={t('You can try adding a new tag above')} />}
                  </div>
                ) : (
                  <>
                    {filteredTags.map(({ color, name }) => {
                      const isApplied = updatedAppliedTags.some(({ name: tagName }) => tagName === name)
                      return <TagManagerAppliedItem action={applyTag} applied={isApplied} color={color as string} name={name as string} key={name} />
                    })}
                    {filteredTags.length > 0 && <div className={`${rootClass}__tags-wrapper-bottom`} />}
                  </>
                )}
              </div>
              {filteredTags.length >= 6 && <div className={`${rootClass}__tags-gradient`} />}
            </ScrollArea>
          </div>
          <div className={`${rootClass}__footer`}>
            <div className={`${rootClass}__division`} />
            {appliedTagsChanged ? (
              getApplyButton()
            ) : (
              <Tooltip triggerClassName={`${rootClass}__tooltip-disabled-appy-button`} position={'top'} trigger={getApplyButton()}>
                {t('No changes have been made')}
              </Tooltip>
            )}
          </div>
        </div>
      </DropDown>
    </div>
  )
}

export default TagManager
