import { gql } from '@apollo/client'

export default gql`
  query sendMessage($messageId: String!) {
    sendMessage(messageId: $messageId) {
      status
      message
    }
  }
`
