import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import ListPickerModal from '@complex/ListPickerModalV2/ListPickerModal'
import { usePersonalizationRequests } from '@complex/Personalization/utils/usePersonalizationRequests.graphQL'
import { DynamicImageModalStates, IImagePickerItem } from '@components/AssetPickers/ImagePickerModal/utils/ImagePickerModal.utils'
import AssetSelect from '@components/AssetSelect/AssetSelect'
import { EditModal } from '@components/EditModal/EditModal'
import FormRow from '@components/FormRow'
import InfoTooltip from '@components/InfoTooltip/InfoTooltip'
import { LabelType, LabelV2 } from '@components/LabelV2/LabelV2'
import { LinkTextButton, LinkTextButtonSize } from '@components/LinkTextButton/LinkTextButton'
import { SvgNames } from '@components/Svg'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ListFieldResponse } from '@graphql/types/query-types'
import { ReactSetStateAction } from '@utils/interface/common'

import { DynamicImageFieldsModal } from './DynamicImageFieldsModal/DynamicImageFieldsModal'
import { SelectDynamicItemsState, SelectedDynamicImage } from '../../InsertImageModalContainer'

import './ImageFallbackModal.css'

export interface PersonalizationFallbackModalProps {
  onInsert: () => void
  onCancel: () => void
  onBack?: (backFromFallback?: boolean) => void
  setHideDynamicImageOption: ReactSetStateAction<boolean>
  setSelectedDynamicItems: ReactSetStateAction<SelectDynamicItemsState>
  selectedItemDynamicImage?: SelectedDynamicImage
  selectedItemFallbackImage?: IImagePickerItem
  dataTest?: string
  isStory?: boolean
  dynamicImageModalStates: DynamicImageModalStates
  setDynamicImageModalStates: ReactSetStateAction<DynamicImageModalStates>
}

const link = 'https://connect.act-on.com/hc/en-us/articles/26235061531671'

const rootClass = 'image-fallback-modal'

export const ImageFallbackModal: FC<PersonalizationFallbackModalProps> = (props: PersonalizationFallbackModalProps) => {
  const {
    dynamicImageModalStates,
    selectedItemDynamicImage,
    selectedItemFallbackImage,
    dataTest = rootClass,
    onBack,
    onInsert,
    onCancel,
    setSelectedDynamicItems,
    setDynamicImageModalStates,
    setHideDynamicImageOption,
  } = props

  const { t } = useTranslation()

  const handleCancel = useCallback(() => {
    onCancel()
  }, [onCancel])

  const handleInsert = useCallback(() => {
    onInsert()
  }, [onInsert])

  const { showListFieldModal, showPickerModal } = dynamicImageModalStates
  const [canSubmit, setCanSubmit] = useState(false)
  const { getListFieldsRequest } = usePersonalizationRequests()
  const [listFieldsState, setListFieldsState] = useState<ListFieldResponse>()

  useEffect(() => {
    if (selectedItemDynamicImage && selectedItemFallbackImage) {
      setCanSubmit(true)
    }
  }, [selectedItemDynamicImage, selectedItemFallbackImage])

  const getListContacts = async (listId: string) => {
    const { getListsFields } = (await getListFieldsRequest(listId)) ?? {}
    const fields = getListsFields?.[0]

    if (getListsFields) {
      setListFieldsState(fields)
    }
  }

  const handleSelectFieldOnBack = useCallback(
    () => setDynamicImageModalStates((prevState) => ({ ...prevState, showPickerModal: true, showListFieldModal: false })),
    [setDynamicImageModalStates]
  )

  const closeModal = useCallback(() => {
    setDynamicImageModalStates((prevState) => ({ ...prevState, showPickerModal: false, showListFieldModal: false }))
  }, [setDynamicImageModalStates])

  const modalProps = useMemo(
    () => ({
      header: { content: <Typography text={t('DynamicImage.FallbackModal.title')} type={TextType.DATA_CARD_MODAL_HEADER} /> },
      body: {
        description: (
          <Typography
            inline
            text={t('DynamicImage.FallbackModal.description')}
            type={TextType.NORMAL_TEXT_GRAY_LARGE}
            className={`${rootClass}__description`}
            tagComponents={{
              LinkTextButton: <LinkTextButton inline hideIconLeft text={t('Learn more')} size={LinkTextButtonSize.MEDIUM} link={link} />,
            }}
          />
        ),
        content: (
          <>
            <FormRow>
              <div className={`${rootClass}__label-wrapper`}>
                <LabelV2
                  withoutMargin
                  required
                  className={`${rootClass}__label`}
                  labelType={LabelType.medium}
                  label={t('DynamicImage.FallbackModal.dynamicImageLocation')}
                />
                <InfoTooltip inline>{t('DynamicImage.FallbackModal.dynamicImageLocation.tooltip')}</InfoTooltip>
              </div>

              <AssetSelect
                key={selectedItemDynamicImage?.title}
                placeholder={t('DynamicImage.FallbackModal.dynamicImageLocationField.placeholder')}
                selectedAssets={selectedItemDynamicImage?.title}
                svgIcon={SvgNames.listCheckNoFill}
                onClick={() => setDynamicImageModalStates((prevState) => ({ ...prevState, showPickerModal: true }))}
                replaceText={selectedItemDynamicImage ? t('Replace') : t('Select')}
                dataTest={`${rootClass}-dynamic-field`}
              />
            </FormRow>
            <FormRow>
              <div className={`${rootClass}__label-wrapper`}>
                <LabelV2
                  withoutMargin
                  required
                  className={`${rootClass}__label`}
                  labelType={LabelType.medium}
                  label={t('DynamicImage.FallbackModal.fallbackImage')}
                />
                <InfoTooltip inline>{t('DynamicImage.FallbackModal.fallbackImage.tooltip')}</InfoTooltip>
              </div>
              <AssetSelect
                placeholder={t('DynamicImage.FallbackModal.fallbackImageField.placeholder')}
                selectedAssets={selectedItemFallbackImage?.title}
                svgIcon={SvgNames.image}
                onClick={() => {
                  setDynamicImageModalStates((prevState) => ({ ...prevState, showDynamicImageModal: false }))
                  setHideDynamicImageOption(true)
                  onBack?.(true)
                }}
                replaceText={selectedItemFallbackImage ? t('Replace') : t('Select')}
                dataTest={`${rootClass}-fallback-field`}
              />
            </FormRow>
          </>
        ),
      },
    }),
    [onBack, selectedItemDynamicImage, selectedItemFallbackImage, setDynamicImageModalStates, setHideDynamicImageOption, showPickerModal, t]
  )

  useEffect(() => {
    setHideDynamicImageOption(false)
  }, [])

  const onBackArrowClick = () => setDynamicImageModalStates((prevState) => ({ ...prevState, showPickerModal: false, showDynamicImageModal: true }))
  return (
    <>
      {showListFieldModal && (
        <DynamicImageFieldsModal
          isOpen
          onAction={(selectedItem) => {
            const { id, name } = selectedItem ?? {}
            setSelectedDynamicItems((prevState) => ({ ...prevState, selectedItemDynamicImage: { id: id ?? '', title: name ?? '' } }))
            setDynamicImageModalStates((prevState) => ({ ...prevState, showListFieldModal: false }))
          }}
          onClose={closeModal}
          handleBack={handleSelectFieldOnBack}
          data={listFieldsState}
          selectedRow={selectedItemDynamicImage}
        />
      )}

      {showPickerModal && (
        <ListPickerModal
          onBackArrowClick={onBackArrowClick}
          submitDisabledWithoutSelection
          defaultSelectedLists={[selectedItemDynamicImage?.id ?? '']}
          multiSelect={false}
          submitButtonText={t('Next')}
          isOpen={showPickerModal}
          headerTitle={t('Select List or Segment')}
          closeModal={closeModal}
          submitLists={(lists) => {
            const { id } = lists[0]
            getListContacts(id)
            setDynamicImageModalStates((prevState) => ({ ...prevState, showPickerModal: false, showListFieldModal: true }))
          }}
        />
      )}

      <EditModal
        canSubmit={canSubmit}
        dataTest={dataTest}
        onBack={onBack}
        handleCancel={handleCancel}
        handlePrimaryButton={handleInsert}
        modalProps={modalProps}
      />
    </>
  )
}
