import { MessageConfiguration, MessageConfigurationType } from '@utils/composer/emailComposer/types'

export enum MessageConfigurationWebinarType {
  INVITATION = 'INVITATION',
  FOLLOWUP = 'FOLLOWUP',
  LASTCHANCE = 'LASTCHANCE',
  REMINDER = 'REMINDER',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  DECLINED = 'DECLINED',
  PENDING = 'PENDING',
}

export const HeaderMessageTypeMapping: { [key: string]: string } = {
  DRAFT: 'Draft',
  FORM_AUTO_RESPONSE: 'Form confirmation',
  PROGRAM_MESSAGE: 'Automated program',
  CRM_MESSAGE: '',
  RESEND_MESSAGE: '',
  SENT: 'Sent',
  TEMPLATE: 'Template',
  AB_TEST_MESSAGE: 'A/B Test',
  WEBINAR_MESSAGE: 'Webinar',
  RSS_MESSAGE: 'RSS email',
  BLANK_MESSAGE: '',
  OPT_IN: 'Opt-In Campaign',
}

export const HeaderWebinarMessageTypeMapping: { [key: string]: string } = {
  INVITATION: 'Invitation',
  LASTCHANCE: 'Last chance',
  REMINDER: 'Reminder',
  FOLLOWUP: 'Follow-up',
}

export interface MessageConfigurationContent {
  showRSSBlock?: boolean
  rssBlockRequired?: boolean
  webinarBlockRequired?: boolean
  eventBlockRequired?: boolean
}

export type MessageConfigurationSettingsType = Record<MessageConfigurationType, MessageConfiguration>

const commonWebinarConfig: MessageConfiguration = {
  messageType: 'WEBINAR_MESSAGE',
  settings: {
    hideEte: true,
    hideTransactional: false,
    hideSubscriptionCategory: false,
  },
  sendDetails: {
    hideSendDetails: false,
    hideSendMethodStaggered: true,
    hideSendMethodTimezone: true,
    hideSendMethodManuallyTimezone: true,
    hideRecipients: false,
  },
  preview: {
    hidePreviewPersonalization: false,
    hideToRecipients: false,
  },
  reviewAndSend: {
    displayAsTab: false,
    reviewAndSendLabel: 'Review and send',
    hideRecipientsValidation: false,
    hideSendMethodValidation: false,
    hideSendNow: false,
  },
  saveButton: {
    saveAndClose: true,
    saveAsCopy: true,
    saveAsTemplate: true,
    copyToDraft: false,
    separateSaveAndClose: false,
    pureSave: true,
    autoSaveWithSeparateSaveAndClose: false,
  },
  content: {
    webinarBlockRequired: true,
  },
}
const commonRegistrationWebinarConfig: MessageConfiguration = {
  messageType: 'WEBINAR_MESSAGE',
  settings: {
    hideEte: false,
    hideTransactional: false,
    hideSubscriptionCategory: false,
    isTransactionalEnabled: true,
  },
  sendDetails: {
    hideSendDetails: true,
    hideSendMethodStaggered: true,
    hideSendMethodTimezone: true,
    hideSendMethodManuallyTimezone: true,
    hideRecipients: false,
  },
  preview: {
    hidePreviewPersonalization: false,
    hideToRecipients: false,
  },
  reviewAndSend: {
    displayAsTab: true,
    keepTabs: true,
    reviewAndSendLabel: 'Review and send',
    hideRecipientsValidation: true,
    hideSendMethodValidation: true,
    hideCheckEmails: true,
    hideSendNow: false,
    hideTransactionalEmailValidation: true,
    hideCheckPersonalizations: true,
    hideDeliverability: false,
  },
  saveButton: {
    saveAndClose: false,
    saveAsCopy: false,
    saveAsTemplate: false,
    copyToDraft: false,
    separateSaveAndClose: true,
    pureSave: false,
  },
  content: {
    webinarBlockRequired: true,
  },
  canPublish: false,
}

export const getWebinarCategoryMessageConfigurations = (
  webinarName: string | undefined,
  messageType: string | undefined,
  webinarType: string | undefined
): MessageConfiguration | undefined => {
  if (!messageType || !webinarType) return undefined
  const webinarNameTitle = webinarName ?? ''
  const messageConfigurationTypeName = getMessageConfigurationTypeName(webinarNameTitle, messageType)

  switch (webinarType) {
    case MessageConfigurationWebinarType.INVITATION:
    case MessageConfigurationWebinarType.REMINDER:
    case MessageConfigurationWebinarType.FOLLOWUP:
    case MessageConfigurationWebinarType.LASTCHANCE:
      if (isWebex(messageConfigurationTypeName)) {
        return {
          ...commonWebinarConfig,
          saveButton: {
            ...commonWebinarConfig.saveButton,
            saveAndClose: false,
            saveAsCopy: false,
            saveAsTemplate: false,
            separateSaveAndClose: false,
            separateDiscardAndPublish: true,
            autoSaveWithSeparateSaveAndClose: true,
            pureSave: webinarType === MessageConfigurationWebinarType.LASTCHANCE,
          },
          sendDetails: { hideSendDetails: true },
          reviewAndSend: { displayAsTab: true, keepTabs: true },
          ...(webinarType !== MessageConfigurationWebinarType.LASTCHANCE && {
            canPublish: true,
          }),
          ...(webinarType !== MessageConfigurationWebinarType.FOLLOWUP
            ? {
                settings: { ...commonWebinarConfig.settings },
              }
            : {}),
        } as MessageConfiguration
      } else if (isGoToWebinar(messageConfigurationTypeName)) {
        return {
          ...commonWebinarConfig,
          settings: {
            ...commonWebinarConfig.settings,
            hideTransactional: webinarType !== MessageConfigurationWebinarType.LASTCHANCE,
            hideEte: webinarType === MessageConfigurationWebinarType.FOLLOWUP,
          },
          sendDetails: { hideSendDetails: true },
          ...(webinarType !== MessageConfigurationWebinarType.LASTCHANCE && {
            canPublish: true,
          }),
          saveButton: {
            ...commonWebinarConfig.saveButton,
            saveAndClose: false,
            saveAsCopy: false,
            saveAsTemplate: false,
            separateSaveAndClose: false,
            separateDiscardAndPublish: true,
            autoSaveWithSeparateSaveAndClose: true,
            pureSave: webinarType === MessageConfigurationWebinarType.LASTCHANCE,
          },
          reviewAndSend: { displayAsTab: true, keepTabs: true },
        } as MessageConfiguration
      } else {
        return {
          ...commonWebinarConfig,
          canPublish: false,
        }
      }
    case MessageConfigurationWebinarType.ACCEPTED:
    case MessageConfigurationWebinarType.PENDING:
    case MessageConfigurationWebinarType.REJECTED:
    case MessageConfigurationWebinarType.DECLINED:
      if (isWebex(messageConfigurationTypeName)) {
        return {
          ...commonRegistrationWebinarConfig,
          ...(webinarType === MessageConfigurationWebinarType.ACCEPTED
            ? {
                settings: { ...commonWebinarConfig.settings, hideTransactional: true },
              }
            : {}),
          settings: { ...commonWebinarConfig.settings, hideEte: false },
          reviewAndSend: {
            displayAsTab: true,
            keepTabs: true,
            hideRecipientsValidation: true,
            hideTransactionalEmailValidation: webinarType === MessageConfigurationWebinarType.ACCEPTED,
          },
        }
      } else if (isGoToWebinar(messageConfigurationTypeName)) {
        return {
          ...commonRegistrationWebinarConfig,
          ...(webinarType === MessageConfigurationWebinarType.ACCEPTED
            ? {
                settings: { ...commonWebinarConfig.settings, hideTransactional: true, hideEte: false },
                reviewAndSend: { ...commonRegistrationWebinarConfig.reviewAndSend, hideTransactionalEmailValidation: true },
              }
            : {}),
          settings: { ...commonWebinarConfig.settings, hideEte: false },
          reviewAndSend: {
            displayAsTab: true,
            keepTabs: true,
            hideRecipientsValidation: true,
            hideTransactionalEmailValidation: webinarType === MessageConfigurationWebinarType.ACCEPTED,
          },
        }
      } else {
        return commonRegistrationWebinarConfig
      }
    default:
      return undefined
  }
}

function isWebex(type: string): boolean {
  return type === MessageConfigurationType.WEBEX_MULTI
}

function isGoToWebinar(type: string): boolean {
  return type === MessageConfigurationType.GOTOWEBINAR_MULTI
}

function getMessageConfigurationTypeName(name: string, type: string) {
  if (name === 'webex' && type === 'multi') {
    return 'WEBEX_MULTI'
  } else if (name === 'goto') {
    return 'GOTOWEBINAR_MULTI'
  } else return name
}
