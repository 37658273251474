import React, { RefObject, useEffect, useMemo, useState } from 'react'
import { Row } from 'react-table'

import classNames from 'classnames'
import equal from 'fast-deep-equal/es6/react'

import ActionableNestedTableWithEmptyListing from '@components/ActionableNestedTableWithEmptyListing/ActionableNestedTableWithEmptyListing'
import Container from '@components/Container'
import { EmptyListingProps } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { SvgNames } from '@components/Svg'
import { HeaderAction, RowAction, TableColumn } from '@components/Table/Table'
import { MultipleTables } from '@components/TableV2/components/MultipleTables/MultipleTables'
import { TableV2Props } from '@components/TableV2/tableV2TS/interfaces'
import Typography, { TextType, TextWeight, TypographyProps } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import { Folder } from '@interface/Folder'
import { FilterDefinition } from '@utils/filter'

import FolderSearchFilterButtons, { FolderSearchOption } from './components/FolderSearchFilterButtons/FolderSearchFilterButtons'

import './FolderSearch.css'

interface Props<T, Col> {
  className?: string
  dataTest?: string
  headerActions?: HeaderAction[]
  rowActions?: RowAction[]
  onRowSelect?: (rows: Row[]) => void
  onRowClicked?: (row: any) => void
  search: string
  currentFolder?: Folder
  currentTag?: LabelDto
  currentFilter?: FilterDefinition
  searchAllFilter: FilterDefinition
  data: any[]
  resultsLabel?: string
  loading?: boolean
  onSearch: (inAllFolders: boolean) => void
  columns: Col[]
  tableV2Props?: TableV2Props<T>
  hasExpander?: boolean
  resultsLabelAboveFilters?: boolean
  itemType?: string
  largeResultsLabel?: boolean
  resetSelectedRowsOnChange?: any[]
  alwaysShowResultsLabel?: boolean
  /** Initial state to search all values */
  searchAll?: boolean
  reference?: RefObject<HTMLDivElement>
  hideSearchResultsLabel?: boolean
  hideSearchInFilters?: boolean
}

export type FolderSearchProps<T = {}, Col = TableColumn> = Props<T, Col> & EmptyListingProps & { enableTableV2?: boolean }

const rootClass = 'folder-search'

const FolderSearch = <T extends {}>(props: FolderSearchProps<T>) => {
  const {
    dataTest = rootClass,
    className = '',
    headerActions,
    rowActions,
    onRowSelect,
    search,
    currentFolder,
    currentTag,
    resetSelectedRowsOnChange,
    currentFilter,
    itemType,
    searchAllFilter,
    data,
    loading = false,
    onSearch,
    onRowClicked,
    columns,
    resultsLabel,
    searchAll: searchAllProp,
    tableV2Props,
    resultsLabelAboveFilters = false,
    largeResultsLabel = false,
    alwaysShowResultsLabel = false,
    reference,
    enableTableV2,
    hideSearchResultsLabel,
    hideSearchInFilters,
    ...EmptyListingProps
  } = props

  const getSearchAllState = () => {
    return searchAllProp ?? equal(currentFilter, searchAllFilter)
  }
  const [searchAll, setSearchAll] = useState<boolean>(getSearchAllState())

  const { t } = useTranslation()

  useEffect(() => {
    onSearch(searchAll)
  }, [search, searchAll])

  useEffect(() => {
    setSearchAll(getSearchAllState())
  }, [searchAllProp])

  const searchOptions: FolderSearchOption[] = useMemo(() => {
    const searchAllFilterOption: FolderSearchOption = {
      name: t(searchAllFilter.name),
      searchAll: true,
      icon: searchAllFilter.svgUnselected,
      active: searchAll,
    }

    const filter = currentTag ?? currentFolder ?? currentFilter
    if (filter && !equal(filter, searchAllFilter)) {
      const options: { [key: string]: FolderSearchOption } = {
        [typeof currentFolder]: { name: currentFolder?.name ?? '', icon: SvgNames.folder, active: !searchAll },
        [typeof currentTag]: { name: currentTag?.name ?? '', icon: SvgNames.tag, active: !searchAll, color: currentTag?.color },
        [typeof currentFilter]: {
          name: t(currentFilter?.name) ?? '',
          icon: currentFilter?.svgUnselected ?? SvgNames.segmentsUnselected,
          active: !searchAll,
        },
      }

      return [options[typeof filter], searchAllFilterOption]
    }
    return [searchAllFilterOption]
  }, [currentTag, currentFolder, currentFilter, searchAllFilter, searchAll])

  const renderResultsLabel = () => {
    const textProps: Partial<TypographyProps> = largeResultsLabel
      ? { weight: TextWeight.BOLD, type: TextType.BODY_TEXT_LARGE }
      : { weight: TextWeight.MEDIUM }
    return loading || data?.length > 0 || alwaysShowResultsLabel ? (
      <Typography className={`${rootClass}__title`} text={resultsLabel} {...textProps} />
    ) : null
  }

  const ActionableNestedTableWithEmptyListingProps = {
    reference: reference,
    className: classNames({ [`${className}__list`]: className, [`${className}__list-empty`]: data.length === 0 }, `${rootClass}__list`),
    data: data,
    columns: columns,
    useCheckboxes: true,
    resetSelectedRowsOnChange: resetSelectedRowsOnChange,
    useHeaderCheckbox: true,
    onRowSelectionChanged: onRowSelect,
    onRowClicked: onRowClicked,
    headerActions: headerActions,
    rowActions: rowActions,
    checkboxWidth: 12,
    fixedHeader: true,
    hasOverflow: true,
    loading: loading,
    onLoading: () => undefined,
    allLoaded: true,
    imgSrc: StaticImageNames.emptySearch,
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Container className={`${rootClass}__container`}>
        {!hideSearchResultsLabel && resultsLabelAboveFilters && renderResultsLabel()}
        {!hideSearchInFilters && (
          <div className={`${rootClass}__select-folder`}>
            <Typography text={t('Search in:')} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.MEDIUM} />
            <FolderSearchFilterButtons itemType={itemType} onChange={setSearchAll} options={searchOptions} />
          </div>
        )}
        {!resultsLabelAboveFilters && renderResultsLabel()}
        <MultipleTables
          tableV2Props={tableV2Props}
          oldTable={<ActionableNestedTableWithEmptyListing {...ActionableNestedTableWithEmptyListingProps} {...EmptyListingProps} />}
          enableTableV2={enableTableV2}
        />
      </Container>
    </div>
  )
}

export default FolderSearch
