export const apiDetailsJson = `{
  "envelope": {
    "toAddress": "REPLACE_WITH_TO_ADDRESS_EMAIL",
    "ccAddressList": [],
    "fromAddress": "REPLACE_WITH_FROM_ADDRESS_EMAIL",
    "fromDisplayName": "REPLACE_WITH_DISPLAY_NAME",
    "replyToAddress": "REPLACE_WITH_REPLY_TO_ADDRESS_EMAIL"
  },
  "content": {
    "subjectLine": "OPTIONALLY_REPLACE_TEMPLATE_SUBJECT_LINE",
    "personalizationDataMap": {
      "Account.COMPANY": "REPLACE_WITH_PERSONALIZATION",
      "Account.BIZ_ADDRESS": "REPLACE_WITH_PERSONALIZATION"
    },
    "previewText": "OPTIONALLY_REPLACE_TEMPLATE_PREVIEW_TEXT"
  },
  "metadata": {
    "transactional": "This is a required boolean Field Type. Send true or false depending on the message type. Refer to API documentation for further details",
    "externalId": "OPTIONAL_IDENTIFIER_FROM_YOUR_SYSTEM",
    "tagList": []
  },
  "actions": {
    "trackOpens": true,
    "trackClicks": true,
    "deliveryWhenDelayed": "FALSE"
  }
}`
