import { gql } from '@apollo/client'

export default gql`
  query loginUserInfo($parentAccountId: Long!, $userId: Long!) {
    loginUserInfo(parentAccountId: $parentAccountId, userId: $userId) {
      admin
      assignedAccounts
    }
  }
`
