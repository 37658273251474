import React, { FC, useMemo, useState } from 'react'

import PageContainer from '@components/PageContainer/PageContainer'
import PageHeader from '@components/PageHeader'
import { PageHeaderType } from '@components/PageHeadline/PageHeadline'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import StatusToast from '@components/StatusToast/StatusToast'
import Tabs, { TabItemData } from '@components/TabsAO/TabsAO'
import { useTranslation } from '@const/globals'
import { StatusToastType } from '@interface/StatusToast'
import CustomLinkContainer from '@src/pages/Content/Branding/tabs/CustomLink/CustomLinkContainer'
import LogosContainer from '@src/pages/Content/Branding/tabs/Logos/LogosContainer'
import WebIdentityContainer from '@src/pages/Content/Branding/tabs/WebIdentity/WebIdentityContainer'

const rootClass = 'branding'

const BrandingContainer: FC = () => {
  const { t } = useTranslation()
  const [currentTab, setCurrentTab] = useState<string>('logos')
  const [toast, setToastStatus] = useState<StatusToastType>()
  const tabsData = useMemo<TabItemData[]>(
    () => [
      { index: 'logos', label: t('Logos'), content: <LogosContainer setToastStatus={setToastStatus} /> },
      { index: 'webIdentity', label: t('Web Identity'), content: <WebIdentityContainer setToastStatus={setToastStatus} /> },
      { index: 'customLink', label: t('Custom Link'), content: <CustomLinkContainer setToastStatus={setToastStatus} /> },
    ],
    [t]
  )
  return (
    <PageContainer className={rootClass}>
      {toast?.showStatus && (
        <StatusToast
          className={`${rootClass}__toast`}
          isSuccess={toast.successStatus}
          message={toast.statusMessage}
          title={toast.title}
          closeStatus={() => {
            setToastStatus({ showStatus: false })
          }}
        />
      )}
      <div className={`${rootClass}__content`}>
        <PositionContainer>
          <PageHeader primaryText={t('Branding')} headlineType={PageHeaderType.SUB_HEADER} leftContent dataTest={rootClass} />
          <Tabs childData={tabsData} defaultValue={currentTab} onChange={setCurrentTab} />
        </PositionContainer>
      </div>
    </PageContainer>
  )
}

export default BrandingContainer
