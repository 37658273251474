import { ExtendedItemDto } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { PersonalizationItem } from '@complex/Personalization/utils/Personalization.context'
import { MediaItem } from '@components/AssetPickers/MediaPickerModal/MediaPickerModal.context'
import { SelectAssetV2RowItem } from '@components/SelectAssetV2/SelectAssetV2'
import { SvgNames } from '@components/Svg'
import { legacyActonContext } from '@utils/const/globals'

export enum AssetLinkTypes {
  LANDING_PAGES = 'LANDING_PAGES',
  FILE = 'FILE',
  VIEW_IN_BROWSER = 'VIEW_IN_BROWSER',
  OPT_OUT = 'OPT_OUT',
  FORM = 'FORM',
}

export const SAVED_AS_TEMPLATE_ID = 'savedEmailTemplateId'
export const SAVED_AS_COPY_ID = 'savedEmailCopyId'
export const SAVED_AS_DRAFT_ID = 'savedAsDraftId'
export const DUPLICATED_AS_DRAFT_ID = 'duplicatedAsDraftId'
export const DUPLICATED_AS_TEMPLATE_ID = 'duplicatedAsTemplateId'
export const AUTOSAVE_SUCCESS_DELAY = 4000
export const AUTOSAVE_ALL_CHANGES_SAVED_DELAY = 1000
export const AUTOSAVE_DONE_DELAY = 2500
export const AUTOSAVE_DEBOUNCE_TIME = 1000

export type AssetLinkPickerReturnType = ExtendedItemDto | MediaItem | PersonalizationItem

export const isMediaItem = (item?: AssetLinkPickerReturnType): item is MediaItem => !isPersonalizationItem(item) && !!item && 'title' in item
export const isPersonalizationItem = (item?: AssetLinkPickerReturnType): item is PersonalizationItem => !!item && 'group' in item

export const getAssetLinkName = (selectedAsset?: AssetLinkPickerReturnType) => {
  if (!selectedAsset) {
    return
  }

  return isMediaItem(selectedAsset) ? selectedAsset.title : isPersonalizationItem(selectedAsset) ? selectedAsset.title : selectedAsset.name
}

export const getEmailComposerRows = (
  subscriptionManagementEnabled = true,
  classicFormsDisabled = false,
  excludeOptOutLink = false,
  isLandingPage = false
): SelectAssetV2RowItem[] => [
  {
    iconSelected: SvgNames.airPlaneFilledSelected,
    iconUnselected: SvgNames.airPlaneFilled,
    title: 'Landing Page',
    description: `Link to an Act-On landing page you've created`,
    name: AssetLinkTypes.LANDING_PAGES,
  },
  {
    iconSelected: SvgNames.fileLinkSelected,
    iconUnselected: SvgNames.fileLink,
    title: 'Media file',
    description: `Link to an asset from your Media Library or a file from your device`,
    name: AssetLinkTypes.FILE,
  },
  ...(classicFormsDisabled
    ? []
    : [
        {
          iconSelected: SvgNames.formLinkFilled,
          iconUnselected: SvgNames.formUnselected,
          title: 'Form',
          description: `Link to a Classic Act-On form`,
          name: AssetLinkTypes.FORM,
        },
      ]),
  ...(excludeOptOutLink || isLandingPage
    ? []
    : [
        {
          iconSelected: SvgNames.optOutSelected,
          iconUnselected: SvgNames.optOutUnselected,
          title: 'Opt-out',
          description: subscriptionManagementEnabled
            ? `Link to unsubscribe or view subscription management preferences`
            : 'Link to unsubscribe from all emails',
          name: AssetLinkTypes.OPT_OUT,
        },
      ]),
  ...(isLandingPage
    ? []
    : [
        {
          iconSelected: SvgNames.externalLinkSelected,
          iconUnselected: SvgNames.externalLinkUnselected,
          title: 'View in browser',
          description: `Link that allows the user to view the message in their browser`,
          name: AssetLinkTypes.VIEW_IN_BROWSER,
        },
      ]),
]

export const getViewInBrowserLink = (accountId: string) =>
  `${window.location.protocol}//${window.location.host}${legacyActonContext}/rif/${accountId}/{{Env.MsgId}}/-/{{Env.RecId}}/{{Env.SrcId}}/showPreparedMessage`

export const CHECK_LITMUS_SPAM_RESULTS_INTERVAL = 5000

export const CHECK_LITMUS_SPAM_RESULTS_TIMEOUT = 10 * 60 * 1000
