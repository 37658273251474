import React, { FC, useContext } from 'react'

import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2Props, SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import Toggletip, { ToggletipSide, ToggletipTriggerSize } from '@components/Toggletip/Toggletip'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType } from '@components/Typography/Typography'
import { Campaign, SubscriptionCategoryGq } from '@graphql/types/query-types'
import { detectEmailType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { MAX_SELECT_SEARCH_INPUT_LENGTH, useTranslation } from '@utils/const/globals'

import { SettingsFormChangeHandler } from '../../../../EmailComposerSettings.utils'

import './InternalTrackingPane.css'

interface AnalyticsTrackingProps {
  className?: string
  dataTest?: string
  onFieldChange: SettingsFormChangeHandler
}

const LEARN_MORE_LINK = 'https://connect.act-on.com/hc/en-us/articles/360023944553-How-to-Send-Emails-to-Subscription-Categories'

const rootClass = 'internal-tracking-pane'

const InternalTrackingPane: FC<AnalyticsTrackingProps> = (props) => {
  const { dataTest = rootClass, onFieldChange } = props
  const {
    values: {
      message: { campaignId, subscriptionCategoryId, messageType },
      messageConfiguration: {
        settings: { hideSubscriptionCategory, hideCampaign, disableInternalTracking },
      },
      campaigns,
      subscriptionCategories,
    },
    api: { onCreateCampaign },
  } = useContext(EmailComposerContext)
  const { subscriptionManagementEnabled, subscriptionManagementIsLive } = useAccountSettings()
  const { t } = useTranslation()
  const { isEmailTemplate } = detectEmailType(messageType)

  const getSelectedOption = (option: Campaign | SubscriptionCategoryGq | undefined): SelectV2SingleOption | undefined => {
    if (option) {
      return {
        value: option.id ?? '',
        label: option.name ?? '',
      }
    }
  }

  const campaignProps: Partial<SelectV2Props> = {
    options: campaigns.map((campaign) => ({ label: campaign.name ?? '', value: campaign.id })),
    value: getSelectedOption(campaigns.find((campaign) => campaign.id === campaignId)),
    maxSearchInputLength: MAX_SELECT_SEARCH_INPUT_LENGTH,
    onCreate: async (name: string) => {
      const campaign = await onCreateCampaign(name)
      campaign && onFieldChange('campaignId', campaign.id)
    },
  }

  const subscriptionCategoryProps: Partial<SelectV2Props> = {
    options: subscriptionCategories.map((category) => ({ label: category.name ?? '', value: category.id })),
    value: getSelectedOption(subscriptionCategories.find((category) => category.id === subscriptionCategoryId)),
    maxSearchInputLength: MAX_SELECT_SEARCH_INPUT_LENGTH,
  }

  return (
    <div className={`${rootClass}`}>
      {!hideSubscriptionCategory && subscriptionManagementEnabled && subscriptionManagementIsLive && (
        <Tooltip
          hide={!disableInternalTracking}
          position={'top'}
          sideOffset={-28}
          trigger={
            <>
              <SelectV2
                label={
                  <div className="toggletip-label">
                    {t('Subscription category')}
                    <Toggletip
                      title={t('Subscription Category')}
                      description={t('EmailComposer.SubscriptionCategory.Toogletip')}
                      side={ToggletipSide.TOP}
                      link={LEARN_MORE_LINK}
                      sideOffset={0}
                      linkText={t('EmailComposer.SubscriptionCategory.Toogletip.Link')}
                      triggerSize={ToggletipTriggerSize.MEDIUM}
                      isDismissable
                    />
                  </div>
                }
                placeholder={t('EmailComposer.Settings.InternalTracking.SubscriptionCategory.Placeholder')}
                {...subscriptionCategoryProps}
                itemType={ItemType.SUBSCRIPTION_CATEGORY}
                isSearchable
                isClearable
                isDisabled={disableInternalTracking}
                onChange={(option) => onFieldChange('subscriptionCategoryId', option?.value ?? '')}
                dataTest={`${dataTest}-subscription-category`}
              />
            </>
          }
        >
          <Typography text={t('EmailComposer.Resend.Disabled')} type={TextType.BODY_TEXT_WHITE} />
        </Tooltip>
      )}
      {!hideCampaign && (
        <div className={`${rootClass}__campaign`}>
          <Tooltip
            hide={!disableInternalTracking}
            position={'top'}
            sideOffset={-28}
            trigger={
              <SelectV2
                label={t('Act-On campaign')}
                placeholder={t('EmailComposer.Settings.InternalTracking.Campaign.Placeholder')}
                {...campaignProps}
                itemType={ItemType.CAMPAIGN}
                isSearchable
                isClearable
                onChange={(option) => onFieldChange('campaignId', option?.value ?? '')}
                dataTest={`${dataTest}-campaign`}
                isDisabled={isEmailTemplate || disableInternalTracking}
              />
            }
          >
            <Typography text={t('EmailComposer.Resend.Disabled')} type={TextType.BODY_TEXT_WHITE} />
          </Tooltip>

          {isEmailTemplate && (
            <Typography text={t('EmailComposer.Settings.InternalTracking.Campaign.Template')} type={TextType.BODY_TEXT_SMALL_LIGHT} />
          )}
        </div>
      )}
    </div>
  )
}

export default InternalTrackingPane
