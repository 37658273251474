import { gql } from '@apollo/client'
import { ITEM_FIELDS } from '@graphql/microservices/categorization/fragments'

export default gql`
  ${ITEM_FIELDS}
  query SearchSegmentsByRecent(
    $pageNumber: Int
    $field: String
    $query: String
    $pageSize: Int
    $type: String
    $subTypes: [String]
    $folderSubTypes: [String]
    $searchHierarchy: Boolean
  ) {
    searchByRecent(
      pageNumber: $pageNumber
      field: $field
      query: $query
      pageSize: $pageSize
      type: $type
      subTypes: $subTypes
      folderSubTypes: $folderSubTypes
      searchHierarchy: $searchHierarchy
    ) {
      ...ItemFields
    }
  }
`
