import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { ExportDataEventObject } from 'highcharts'
import { cloneDeep, zip } from 'lodash'

import ColumnChart from '@components/ColumnChart/ColumnChart'
import { DateRangeValueType } from '@components/DateRangePicker/DateRangePicker'
import LineChart from '@components/LineChart/LineChart'
import SingleSelectDropdown from '@components/SingleSelectDropdown/SingleSelectDropdown'
import Toggle from '@components/Toggle'
import Typography from '@components/Typography/Typography'
import { exportingOptionsWithIcons } from '@const/Chart.constants'
import { useTranslation } from '@const/globals'
import { OpportunitiesSectionData } from '@graphql/types/microservice/data-lake-query-types'
import { UserRevenueInput } from '@graphql/types/query-types'
import ChartSection from '@src/pages/reports/revenueImpactNew/components/ChartSection/ChartSection'
import {
  columnChartPlotOptions,
  defaultLegendOptions,
  defaultOptions,
  getXAxisCommonOptions,
  isOneDayRange,
  lineChartPlotOptions,
  xAxisOtherUIGroupingKey,
} from '@src/pages/reports/revenueImpactNew/components/ChartSection/ChartSection.utils'

import {
  getOpportunitiesFirstOptions,
  OPPORTUNITIES_SECOND_OPTIONS,
  OpportunitiesFirstOption,
  OpportunitiesSecondOption,
  OpportunitiesTabIndex,
  TABS_OPTIONS,
} from './Opportunities.constants'
import getOpportunitiesFormattedData, { OpportunitiesDataColumnChartField } from './utils/opporrtunities.formattedData'
import { tooltip, yAxisLabels } from './utils/opportunities.chartUtils'
import { BEFORE_PRINT_SIZE } from '../../RevenueImpactNew.utils'

import './Opportunities.css'

export interface OpportunitiesProps {
  loading: boolean
  data: OpportunitiesSectionData
  range: DateRangeValueType
  onPreserveDataChange: (obj: Partial<UserRevenueInput>) => void
  isToggleOn?: boolean
  dataTest?: string
}

const firstOptionDefaultValue = OpportunitiesFirstOption.BY_SOURCE
const secondOptionDefaultValue = OpportunitiesSecondOption.COUNT

const rootClass = 'opportunities'

const Opportunities: FC<OpportunitiesProps> = (props) => {
  const { loading, data, range, onPreserveDataChange, isToggleOn, dataTest = rootClass } = props

  const { t } = useTranslation()
  const { analytics, bySource, byCampaign, byDate, startTime, endTime } = useMemo(() => getOpportunitiesFormattedData(data, range, t), [data, range])

  const [firstSelectedValue, setFirstSelectedValue] = useState<OpportunitiesFirstOption>(firstOptionDefaultValue)
  const [secondSelectedValue, setSecondSelectedValue] = useState<OpportunitiesSecondOption>(secondOptionDefaultValue)
  const [tabSelectedValue, setTabSelectedValue] = useState<OpportunitiesTabIndex>(OpportunitiesTabIndex.All)
  const [showOther, setShowOther] = useState<boolean>(!!isToggleOn)
  const isColumnChart = firstSelectedValue !== OpportunitiesFirstOption.OVER_TIME
  const isRevenue = secondSelectedValue === OpportunitiesSecondOption.REVENUE
  const isLeadSource = firstSelectedValue === OpportunitiesFirstOption.BY_SOURCE

  const Chart = isColumnChart ? ColumnChart : LineChart

  const {
    categories: selectedColumnCategories,
    data: selectedColumnFields,
  }: {
    categories: string[]
    data: OpportunitiesDataColumnChartField[]
  } = isColumnChart ? (isLeadSource ? bySource : byCampaign)[tabSelectedValue][secondSelectedValue] : { categories: [], data: [] }

  const otherCategories = selectedColumnCategories.slice(7)
  const otherData = selectedColumnFields.map((field) => field.data.slice(7))
  const hasOtherCategory = otherData[0]?.length > 1
  const showToggle = hasOtherCategory && isColumnChart

  const hideOverTime = isOneDayRange(startTime, endTime)

  const firstOptions = useMemo(() => getOpportunitiesFirstOptions(hideOverTime, t), [hideOverTime, t])

  useEffect(() => {
    if (hideOverTime) {
      setFirstSelectedValue((cur) => (cur === OpportunitiesFirstOption.OVER_TIME ? OpportunitiesFirstOption.BY_SOURCE : cur))
    }
  }, [hideOverTime])

  const columnCategories = hasOtherCategory
    ? showOther
      ? [...selectedColumnCategories.slice(0, 7), xAxisOtherUIGroupingKey]
      : selectedColumnCategories.slice(0, 7)
    : [...selectedColumnCategories]

  const columnFields = selectedColumnFields.map(({ data, ...rest }) => ({
    ...rest,
    data: hasOtherCategory ? (showOther ? [...data.slice(0, 7), data.slice(7).reduce((a, c) => a + c, 0)] : data.slice(0, 7)) : [...data],
  }))

  const allDateFields = byDate[secondSelectedValue]

  const analyticsData = analytics[tabSelectedValue]

  const filteredLineChart = allDateFields.filter(
    (_field, index) =>
      (tabSelectedValue === OpportunitiesTabIndex.NEW_OP && index === 0) ||
      (tabSelectedValue === OpportunitiesTabIndex.WON_OP && index === 1) ||
      tabSelectedValue === OpportunitiesTabIndex.All
  )

  const onToggleChange = useCallback(
    (isOn: boolean) => {
      setShowOther(isOn)
      onPreserveDataChange({ opportunityShowOther: isOn })
    },
    [onPreserveDataChange]
  )

  const onFirstSelectChange = useCallback((value?: OpportunitiesFirstOption) => {
    if (value) {
      setFirstSelectedValue(value)
    }
  }, [])

  const onSecondSelectChange = useCallback((value?: OpportunitiesSecondOption) => {
    if (value) {
      setSecondSelectedValue(value)
    }
  }, [])

  const handleTabChange = useCallback((index: string) => setTabSelectedValue(index as OpportunitiesTabIndex), [])

  const options = {
    ...defaultOptions,
    showTicksOnEmptyData: true,
    hideXAxisLine: true,
    isDateTime: !isColumnChart,
    fields: loading ? [] : isColumnChart ? columnFields : cloneDeep(filteredLineChart),
    categories: isColumnChart ? columnCategories : undefined,
    plotOptions: isColumnChart ? columnChartPlotOptions(isRevenue) : lineChartPlotOptions,
    legendOptions: { ...defaultLegendOptions, x: showToggle ? 558 : 420 },
    exportingOptions: { ...exportingOptionsWithIcons(t), chartOptions: { legend: { x: 6 } } },
    yAxisLabels: yAxisLabels(isRevenue),
    xAxisOptions: getXAxisCommonOptions({
      missingLabelReplacer: t(isLeadSource ? 'No lead source' : 'No campaign type'),
      isColumnChart,
      startTime,
      endTime,
    }),
    tooltip: tooltip(startTime, endTime, isColumnChart, isRevenue, isLeadSource, isRevenue ? byDate.count : byDate.revenue, otherData),
    events: {
      exportData: function (data: ExportDataEventObject) {
        if (showOther && otherData.length) {
          data.dataRows.pop()
        }
        data.dataRows.push(...zip<any>(otherCategories, otherData[0], otherData[1]))
      },
      beforePrint: function (this: any) {
        this.exportSVGElements[0].box.hide()
        this.exportSVGElements[1].hide()
        this.setSize(BEFORE_PRINT_SIZE)
      },
      afterPrint: function (this: any) {
        this.exportSVGElements[0].box.show()
        this.exportSVGElements[1].show()
        this.setSize(null)
      },
    },
  }

  return (
    <ChartSection
      className={rootClass}
      title={t('Opportunities')}
      analyticsData={analyticsData}
      loading={loading}
      tabOptions={TABS_OPTIONS}
      defaultTabValue={tabSelectedValue}
      onTabChange={handleTabChange}
      dataTest={dataTest}
    >
      <Chart
        {...options}
        ExtraElement={
          <div className={`${rootClass}__actions`} data-test={`${dataTest}__actions`}>
            <SingleSelectDropdown
              options={firstOptions}
              defaultValue={firstSelectedValue}
              onSubmit={onFirstSelectChange}
              className={`${rootClass}__actions-select`}
              dataTest={`${dataTest}__actions-select`}
            />
            <SingleSelectDropdown
              options={OPPORTUNITIES_SECOND_OPTIONS}
              defaultValue={secondSelectedValue}
              onSubmit={onSecondSelectChange}
              className={`${rootClass}__actions-select`}
              dataTest={`${dataTest}__actions-select`}
            />
            {showToggle && (
              <div className={`${rootClass}__actions-toggle`} data-test={`${dataTest}__actions-toggle`}>
                <Toggle isOn={showOther && hasOtherCategory} onToggle={onToggleChange} />
                <Typography text={t('Show Other')} inline />
              </div>
            )}
          </div>
        }
      />
    </ChartSection>
  )
}

export default Opportunities
