import React, { FC, useContext } from 'react'

import classNames from 'classnames'

import { Status } from '@components/StatusToast/StatusToast'
import { useTranslation } from '@const/globals'
import InputWithCopy from '@src/pages/Webhooks/IncomingWebhooks/components/InputWithCopy/InputWithCopy'
import { IncomingWebhooksContext } from '@src/pages/Webhooks/IncomingWebhooks/IncomingWebhooksContext'

import './TestWebhookURL.css'

interface TestWebhookURLProps {
  className?: string
  dataTest?: string
  url: string
}

const rootClass = 'test-webhook-url'

const TestWebhookURL: FC<TestWebhookURLProps> = (props: TestWebhookURLProps) => {
  const { dataTest = rootClass, className = '', url } = props

  const { update } = useContext(IncomingWebhooksContext)

  const { t } = useTranslation()

  const copyToClipboard = () => {
    update({ statusToast: { status: Status.SUCCESS, statusMessage: 'Incoming.Webhooks.Toast.CopyUrl' } })
  }

  return (
    <div className={classNames(rootClass, className)}>
      <InputWithCopy dataTest={dataTest} name={'url'} value={url} copyButtonTooltip={t('Copy URL to clipboard')} onCopy={copyToClipboard} />
    </div>
  )
}

export default TestWebhookURL
