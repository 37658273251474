import { getFolderById } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { CustomSource } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { ModalHeaderV2Props } from '@components/Modal/components/ModalHeaderV2/ModalHeaderV2'
import { SearchProps, SearchType } from '@components/Search/Search'
import { useTranslation } from '@utils/const/globals'
import { UpdateState } from '@utils/types'

import { getItemName } from './AssetPickerModalUtil'
import { AssetPickerModalProps } from '../AssetPickerModal'
import { AssetPickerCommonState } from '../Context/AssetPicker.context'

export interface UseAssetPickerHeaderProps {
  modalProps: AssetPickerModalProps
  modalState: AssetPickerCommonState
  update: UpdateState<AssetPickerCommonState>
  rootClass: string
}

export const useAssetPickerHeaderProps = (props: UseAssetPickerHeaderProps): ModalHeaderV2Props => {
  const {
    modalProps,
    modalProps: {
      onBack,
      titleText = `AssetPicker.${modalProps.tableProps.listPage}.Header.Title`,
      isSingleSelect,
      dataTest = 'asset-picker-header',
      tableProps: { listPage },
    },
    modalState: {
      selectedViewItems,
      isViewingSelected,
      listingPageState,
      listingPageState: {
        items,
        searchItemsResults,
        selectedCustomSource,
        showPreview,
        filterActive,
        search,
        activeFolderId,
        folders,
        tags,
        activeTagId,
        selectedRows: actionRows,
      },
    },
    update,
    rootClass,
  } = props

  const { t } = useTranslation()

  const handleSearchChange = (value: string) => {
    if (value === search) {
      return
    }
    update({ listingPageState: { ...listingPageState, search: value, loading: true, fetchItems: value === '' } })
  }

  const getSearchPlaceholder = (customSource?: CustomSource) => {
    if (activeFolderId || activeTagId) {
      const filter = activeFolderId ? getFolderById(activeFolderId, folders) : tags.find((tag) => tag.id === activeTagId)
      if (filter) {
        return t(`AssetPicker.Search.Placeholder.Filter`, { filter: filter.name })
      }
    }
    const placeholder = customSource?.searchPlaceholder ?? customSource?.itemName?.toLowerCase()
    if (placeholder) {
      return t(`Search ${placeholder}s`)
    } else {
      return t(`AssetPicker.${listPage}.Search.Placeholder`)
    }
  }

  let searchProps: SearchProps | undefined
  if (!isViewingSelected && !showPreview) {
    searchProps = {
      canClear: true,
      className: `${rootClass}__search-input`,
      dataTest: `${dataTest}-search-input`,
      incomingValue: search,
      onChangeHandler: (value: string) => handleSearchChange(value),
      placeholder: getSearchPlaceholder(selectedCustomSource),
      searchType: SearchType.LARGE,
      clearOnChange: [filterActive, activeFolderId, activeTagId],
    }
  }

  let iconProps: ModalHeaderV2Props['iconProps'] | undefined
  if (showPreview) {
    iconProps = {
      hasBackButton: true,
      onClick: () => update({ listingPageState: { ...listingPageState, showPreview: false } }),
    }
  } else if (onBack && !isViewingSelected) {
    iconProps = { hasBackButton: true, onClick: onBack }
  }

  const itemName = getItemName(actionRows[0]?.id, items, searchItemsResults, selectedViewItems)
  const headerText = showPreview
    ? t(`AssetPicker.Header.Preview.Title`, { itemName })
    : isViewingSelected
    ? t('AssetPicker.ViewSelected.Title')
    : t(titleText, { count: isSingleSelect ? 1 : 2 })

  return { iconProps, headerText, searchProps }
}
