import React, { FC } from 'react'

import Button, { ButtonType } from '@components/Button'
import Container from '@components/Container'
import StaticImage from '@components/StaticImage/StaticImage'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { useTranslation } from '@const/globals'

import './FirstTime.css'

type Props = {
  setShowFirstTimePage: any
  userUpdates: any
}

const rootClass = 'first-time-modal'

const FirstTime: FC<Props> = (props: Props) => {
  const { setShowFirstTimePage, userUpdates } = props
  const { t } = useTranslation()

  const closeWindow = () => {
    // this will hide this page and show the 'save page' banner
    userUpdates('saveUCLChanges', true)
    userUpdates('updateRow', false)
    setShowFirstTimePage(false)
  }

  return (
    <Container>
      <div className={`${rootClass}__parent`}>
        <div className={`${rootClass}__img-parent`}>
          <StaticImage name={StaticImageNames.emptyLost} className={`${rootClass}__img`} />
        </div>

        <div className={`${rootClass}__top-text`}>{t('Looks like your first time here')}</div>
        <div className={`${rootClass}__lower-text`}>
          {t(
            'You’re within minutes of consolidating all your marketable individuals into Act-On Contacts. With improved data management, they’ll be kept in sync with your CRM requiring less work on your part. Begin with our smart mapping defaults to bring over the desired fields from your CRM.'
          )}
        </div>
        <Button buttonType={ButtonType.PRIMARY} className={`${rootClass}__close-button`} onClick={() => closeWindow()}>
          {t('Get Started')}
        </Button>
      </div>
    </Container>
  )
}

export default FirstTime
