import React, { FC } from 'react'

import PositionContainer from '@components/PositionContainer/PositionContainer'

import './LandingPageManagerClickMap.css'

interface LandingPageManagerClickMap {
  dataTest?: string
}

const rootClass = 'landing-page-click-map'

const LandingPageManagerClickMap: FC<LandingPageManagerClickMap> = ({ dataTest = rootClass }) => {
  return (
    <PositionContainer innerDivClassname={rootClass} dataTest={dataTest}>
      &quot;Click map&quot; page coming soon
    </PositionContainer>
  )
}

export default LandingPageManagerClickMap
