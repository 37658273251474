import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import { ApolloQueryResult, FetchResult, MutationFunctionOptions } from '@apollo/client'
import { SortBy } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { ButtonType } from '@components/Button'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import ImageMediaCard from '@components/ImageMediaCard/ImageMediaCard'
import InfoStatus, { InfoStatusTypes } from '@components/InfoStatus/InfoStatus'
import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { SvgNames } from '@components/Svg'
import TableWithLoaderAndEmptyListing from '@components/TableWithLoaderAndEmptyListing/TableWithLoaderAndEmptyListing'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { getUUID, legacyActonContext, useTranslation } from '@const/globals'
import {
  AddLogoMutation,
  DeleteLogoMutation,
  DuplicateLogoMutation,
  Exact,
  LogoAttributesInput,
  Maybe,
  UpdateLogoAttributesMutation,
  UpdateLogoDefaultMutation,
} from '@graphql/types/mutation-types'
import { GetAllLogosQuery, LogoItem } from '@graphql/types/query-types'
import { StatusToastType } from '@interface/StatusToast'
import AddEditLogoModal from '@src/pages/Content/Branding/tabs/Logos/components/AddEditLogoModal/AddEditLogoModal'
import LogosHeader from '@src/pages/Content/Branding/tabs/Logos/components/LogosHeader'
import PreviewLogoModal from '@src/pages/Content/Branding/tabs/Logos/components/PreviewLogoModal/PreviewLogoModal'
import { REMOVE_DEFAULT_SUCCESS, REMOVE_ID, SET_DEFAULT_SUCCESS } from '@src/pages/Content/Branding/tabs/Logos/Logos.constants'
import {
  ActionTypes,
  getAllActions,
  getConfirmationBody,
  getGridViewActions,
  getGridViewDropDownActions,
} from '@src/pages/Content/Branding/tabs/Logos/utils/Logos.GridView.actions'
import { useRowActions } from '@src/pages/Content/Branding/tabs/Logos/utils/Logos.rowActions'
import { tableColumns } from '@src/pages/Content/Branding/tabs/Logos/utils/Logos.tableColumns'
import { filterNotEmptyArray } from '@utils/array'
import { logNewRelicError } from '@utils/new-relic.utils'

import './logos.css'

const rootClass = 'logos'

export const enum ViewType {
  GRID_VIEW = 'grid',
  LIST_VIEW = 'list',
}

interface LogosProps {
  data: LogoItem[]
  refetch: () => Promise<ApolloQueryResult<GetAllLogosQuery>>
  deleteLogo: (
    options?: MutationFunctionOptions<DeleteLogoMutation, Exact<{ id?: Maybe<string> }>> | undefined
  ) => Promise<FetchResult<DeleteLogoMutation>>
  addLogo: (
    options?: MutationFunctionOptions<AddLogoMutation, Exact<{ data?: Maybe<LogoAttributesInput> }>> | undefined
  ) => Promise<FetchResult<AddLogoMutation>>
  duplicateLogo: (
    options?: MutationFunctionOptions<DuplicateLogoMutation, Exact<{ data?: Maybe<LogoAttributesInput> }>> | undefined
  ) => Promise<FetchResult<DuplicateLogoMutation>>
  editLogo: (
    options?: MutationFunctionOptions<UpdateLogoAttributesMutation, Exact<{ id?: Maybe<string>; data?: Maybe<LogoAttributesInput> }>> | undefined
  ) => Promise<FetchResult<AddLogoMutation>>
  setLogos: (logos: LogoItem[]) => void
  updateLogoDefault: (
    options?: MutationFunctionOptions<UpdateLogoDefaultMutation, Exact<{ id?: Maybe<string> }>> | undefined
  ) => Promise<FetchResult<UpdateLogoDefaultMutation>>
  className?: string
  dataTest?: string
  loading: boolean
  addLogoLoading: boolean
  duplicateLogoLoading: boolean
  editLogoLoading: boolean
  deleteLogoLoading: boolean
  setToastStatus: (value: StatusToastType) => void
}

const defaultSortBy = {
  sortBy: [{ id: 'title', desc: false }],
}

const Logos: FC<LogosProps> = (props: LogosProps) => {
  const {
    dataTest = rootClass,
    className = '',
    data,
    loading,
    addLogoLoading,
    duplicateLogoLoading,
    editLogoLoading,
    deleteLogoLoading,
    deleteLogo,
    addLogo,
    duplicateLogo,
    editLogo,
    updateLogoDefault,
    refetch,
    setToastStatus,
    setLogos,
  } = props
  const [view, setView] = useState<ViewType>(ViewType.GRID_VIEW)
  const [openAddEditLogoModal, setOpenAddEditLogoModal] = useState<boolean>(false)
  const [openEditLogoWarningModal, setOpenEditLogoWarningModal] = useState<boolean>(false)
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false)
  const [logoToPreview, setLogoToPreview] = useState<LogoItem | undefined>(undefined)
  const [logoToEdit, setLogoToEdit] = useState<LogoItem | undefined>(undefined)
  const [logoToDelete, setLogoToDelete] = useState<LogoItem | undefined>(undefined)
  const [defaultLogoSelected, setDefaultLogoSelected] = useState<boolean>(true)
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false)
  const columns = useMemo(() => tableColumns(rootClass), [])
  const { t } = useTranslation()

  const handleImageEdited = (event: StorageEvent) => {
    if (event.key === 'imageEdit' && event.newValue === 'Image has been edited') {
      refetch()
    }
  }

  useEffect(() => {
    window.addEventListener('storage', handleImageEdited)
    return () => {
      window.removeEventListener('storage', handleImageEdited)
    }
  }, [])

  useEffect(() => {
    if (!loading && data.length) {
      const isDefaultLogoSelected = data.some((logo) => logo.isDefault)
      setDefaultLogoSelected(isDefaultLogoSelected)

      if (logoToPreview) {
        const previewLogo = data.find((logo) => logo.id === logoToPreview?.id)
        setLogoToPreview(previewLogo)
      }
    }
  }, [data, setDefaultLogoSelected, loading, logoToPreview])

  const handlePreview = useCallback(
    (logo: LogoItem) => {
      setLogoToPreview(logo)
    },
    [setLogoToPreview]
  )

  const handleEdit = useCallback(
    (logo: LogoItem, isDuplicate: boolean) => {
      setLogoToEdit(logo)
      setOpenAddEditLogoModal(true)
      setIsDuplicate(isDuplicate)
    },
    [setLogoToEdit, setOpenAddEditLogoModal, setIsDuplicate, isDuplicate]
  )

  const handleDelete = useCallback(
    (logo: LogoItem) => {
      setLogoToDelete(logo)
      setShowDeleteConfirmationModal(true)
    },
    [setShowDeleteConfirmationModal, setLogoToDelete]
  )

  const handleOpenWithEditor = useCallback(
    (logo: LogoItem) => {
      if (logo.isLocal) {
        const url = `${legacyActonContext}/imageEditor/editor.jsp?type=logo&id=${logo.id}`
        logoToPreview && setLogoToPreview(undefined)
        window.open(url, '_blank')
      } else {
        setOpenEditLogoWarningModal(true)
      }
    },
    [logoToPreview]
  )

  const handleUpdateLogoDefault = useCallback(
    (logo: LogoItem) => {
      const defaultLogoId = logo?.isDefault ? REMOVE_ID : logo?.id
      const successMessage = logo?.isDefault ? REMOVE_DEFAULT_SUCCESS : SET_DEFAULT_SUCCESS

      updateLogoDefault({ variables: { id: defaultLogoId } }).then((response) => {
        if (response.data?.updateLogoDefault) {
          logoToPreview && setLogoToPreview(undefined)
          setToastStatus({
            showStatus: true,
            title: t('Success!'),
            statusMessage: t(successMessage, {
              logoTitle: logo?.title,
            }),
            successStatus: true,
          })
          refetch()
        } else {
          response.errors?.map((error) => logNewRelicError(error.message))
        }
      })
    },
    [updateLogoDefault, logoToPreview]
  )

  const rowActions = useRowActions(handlePreview, handleEdit, handleDelete, handleOpenWithEditor, handleUpdateLogoDefault)
  const gridViewActions = useCallback((logo: LogoItem) => getGridViewActions(logo, handleEdit, handleDelete, t), [handleEdit, handleDelete, t])
  const gridViewDropDownActions = useCallback(
    (logo: LogoItem) => getGridViewDropDownActions(logo, handleEdit, handleOpenWithEditor, handleUpdateLogoDefault, t),
    [handleEdit, handleOpenWithEditor, handleUpdateLogoDefault, t]
  )

  const allActions = useCallback(
    (logo: LogoItem) =>
      getAllActions(
        logo,
        handleOpenWithEditor,
        handleUpdateLogoDefault,
        handleEdit,
        handleDelete,
        t,
        logoToEdit ? ActionTypes.EDIT : logoToPreview ? ActionTypes.PREVIEW : undefined
      ),
    [handleOpenWithEditor, handleUpdateLogoDefault, handleEdit, logoToEdit, logoToPreview, handleDelete, t]
  )

  const handleDeleteConfirmationAnswer = useCallback(
    (answer: YesNo) => {
      if (answer === YesNo.YES) {
        if (isDuplicate) {
          setIsDuplicate(false)
          setLogoToEdit(undefined)
          setShowDeleteConfirmationModal(false)
          setOpenAddEditLogoModal(false)
        } else {
          logoToPreview && setLogoToPreview(undefined)
          logoToEdit && setLogoToEdit(undefined)
          deleteLogo({ variables: { id: logoToDelete?.id } }).then((response) => {
            setShowDeleteConfirmationModal(false)
            if (response.data?.deleteLogo) {
              setToastStatus({
                showStatus: true,
                title: t('Success!'),
                statusMessage: t('Logo.Delete.Success', {
                  logoTitle: logoToDelete?.title,
                }),
                successStatus: true,
              })
              refetch()
            } else {
              response.errors?.map((error) => logNewRelicError(error.message))
            }
          })
        }
      } else {
        setShowDeleteConfirmationModal(false)
      }
    },
    [setShowDeleteConfirmationModal, logoToDelete, logoToPreview, logoToEdit, setToastStatus, deleteLogo, refetch, isDuplicate]
  )

  const onSort = useCallback(
    (sortBy: SortBy[]) => {
      if (sortBy.length > 0) {
        const desc = sortBy[0].desc
        let sortedLogos
        if (!desc) {
          sortedLogos = data
            ?.filter(filterNotEmptyArray)
            .sort((a, b) => ((a.title as string).toLowerCase() > (b.title as string).toLowerCase() ? 1 : -1))
        } else {
          sortedLogos = data
            ?.filter(filterNotEmptyArray)
            .sort((a, b) => ((a.title as string).toLowerCase() < (b.title as string).toLowerCase() ? 1 : -1))
        }
        const logos = [...sortedLogos.filter(({ isDefault }) => isDefault), ...sortedLogos.filter(({ isDefault }) => !isDefault)]
        setLogos(logos)
      }
    },
    [data]
  )

  const renderGrid = () => {
    const gridData = [...data]
    const sortedLogos = gridData
      ?.filter(filterNotEmptyArray)
      .sort((a, b) => ((a.title as string).toLowerCase() > (b.title as string).toLowerCase() ? 1 : -1))
    const logos = [...sortedLogos.filter(({ isDefault }) => isDefault), ...sortedLogos.filter(({ isDefault }) => !isDefault)]
    return (
      <>
        <div className={`${rootClass}__loader-wrapper`}>{loading && <Loader blackout={false} loaderType={LoaderTypes.page} />}</div>
        <div className={classNames(`${rootClass}__grid`, { [`${rootClass}__grid-loading-state`]: loading })} data-test={`${rootClass}__grid`}>
          {logos.map((logo: LogoItem) => {
            return (
              <ImageMediaCard
                key={getUUID()}
                onSelect={() => setLogoToPreview(logo)}
                mainActionLabel={t('Preview Logo')}
                actions={gridViewActions(logo)}
                dropdownActions={gridViewDropDownActions(logo)}
                imageSrc={logo.url ?? ''}
                title={logo.title}
                isDefault={logo.isDefault}
              />
            )
          })}
        </div>
      </>
    )
  }

  const renderView = (view: ViewType) => {
    switch (view) {
      case ViewType.GRID_VIEW:
        return renderGrid()
      case ViewType.LIST_VIEW:
        return (
          <TableWithLoaderAndEmptyListing
            data={data}
            columns={columns}
            loading={loading}
            rowActions={rowActions}
            onSort={onSort}
            initialState={defaultSortBy}
            manualSortBy
          />
        )
    }
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <PreviewLogoModal
        onCancel={() => {
          setLogoToPreview(undefined)
        }}
        logo={logoToPreview}
        actions={allActions}
        isDuplicate={isDuplicate}
      />
      <ConfirmationModal
        isOpen={openEditLogoWarningModal}
        title={t('Cannot Edit Logo')}
        body={t('This logo is hosted on an external site and cannot be edited in Act-On.')}
        closeModal={() => setOpenEditLogoWarningModal(false)}
        dataTest={`${dataTest}__edit-logo-warning`}
      />
      <AddEditLogoModal
        onCancel={(isSuccess: boolean, touched: boolean) => {
          if (isDuplicate && !isSuccess && touched) {
            setShowDeleteConfirmationModal(true)
          } else {
            setOpenAddEditLogoModal(false)
            setLogoToEdit(undefined)
            setIsDuplicate(false)
          }
        }}
        showModal={openAddEditLogoModal}
        setOpenAddEditLogoModal={setOpenAddEditLogoModal}
        logo={logoToEdit}
        addLogo={addLogo}
        duplicateLogo={duplicateLogo}
        editLogo={editLogo}
        refetch={refetch}
        setToastStatus={setToastStatus}
        isDuplicate={isDuplicate}
        isPreview={!!logoToPreview}
        setLogoToPreview={setLogoToPreview}
        isLoading={addLogoLoading || editLogoLoading || duplicateLogoLoading}
      />
      <ConfirmationModal
        isDelete
        deleteButtonText={isDuplicate ? 'Yes, cancel' : undefined}
        deleteDisabled={deleteLogoLoading}
        isOpen={showDeleteConfirmationModal}
        title={t('Are you sure?')}
        body={getConfirmationBody(t, isDuplicate)}
        onAnswer={handleDeleteConfirmationAnswer}
        className={rootClass}
        dataTest={`${dataTest}__confirm-delete-logo`}
      />
      <LogosHeader setShowAddLogoModal={setOpenAddEditLogoModal} logosCount={data.length} defaultView={view} setView={setView} loading={loading} />
      {!defaultLogoSelected && (
        <InfoStatus
          className={`${rootClass}__caution`}
          status={InfoStatusTypes.Caution}
          svgName={SvgNames.cautionYellow}
          message={
            <div className={`${rootClass}__caution-default-logo`}>
              <Typography text={t('No default logo selected')} weight={TextWeight.BOLD} />
              <Typography text={t('Select a default logo to use in signatures, outgoing emails, and landing pages to boost your brand messaging.')} />
            </div>
          }
        />
      )}
      {loading || data.length > 0 ? (
        renderView(view)
      ) : (
        <EmptyListing
          size={EmptyListingSize.MEDIUM}
          headline={t('You haven’t added any logos yet')}
          text={t('Add logos here to use in your emails, landing pages, and forms. Logos are a great way to boost your brand presence.')}
          imgSrc={StaticImageNames.logosEmpty}
          buttonType={ButtonType.PRIMARY}
          buttonText={t('Add logo')}
          buttonPlusIcon
          buttonOnClick={() => {
            setOpenAddEditLogoModal(true)
          }}
        />
      )}
    </div>
  )
}

export default Logos
