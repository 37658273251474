import { gql } from '@apollo/client'

export default gql`
  mutation initUnifiedList($connectedCrmName: String) {
    initUnifiedList(connectedCrmName: $connectedCrmName) {
      body
      reason
      status
    }
  }
`
