import React from 'react'

import Typography, { TextWeight } from '@components/Typography/Typography'

export const parseErrorSetToRunMessage = (t: Function, errorSetToRunMessage?: string, rootClass?: string) => {
  const messageTitle = errorSetToRunMessage?.includes(':') ? errorSetToRunMessage?.split(':')[2].trim() : errorSetToRunMessage
  let errorReason

  if (errorSetToRunMessage) {
    if (errorSetToRunMessage?.includes('Missing subject-line')) {
      errorReason = `Your program could not be started. Missing subject-line for message {{messageTitle}}`
    } else if (errorSetToRunMessage?.includes('Invalid or missing sender')) {
      errorReason = `Your program could not be started. Invalid or missing sender for message {{messageTitle}}`
    } else if (errorSetToRunMessage?.includes('Invalid or missing opt-out')) {
      errorReason = `Your program could not be started. Invalid or missing opt-out link for message {{messageTitle}}`
    } else if (errorSetToRunMessage?.includes('The program is already starting')) {
      errorReason = "Your program could not be started because it's already starting. It should be running momentarily."
    } else {
      errorReason = `Your program could not be started. Failed to add list trigger`
    }
  }

  return errorReason ? (
    <Typography
      text={t(`${errorReason}`)}
      values={{ messageTitle }}
      tagProps={{ bold: { weight: TextWeight.BOLD } }}
      inline
      className={`${rootClass}__error-toast-message`}
    />
  ) : (
    t('Your program could not be started.')
  )
}
