import { GetAllItemsRequestType } from '@src/pages/ContactSegments/GraphQL/ContactSegments.categorization.graphQL'
import { showStatusToast } from '@src/pages/ContactSegments/GraphQL/utils/ContactSegmentsRequests.helper'
import { ItemType } from '@utils/categorization'
import { parseItem } from '@utils/contactSegments/contactSegments.utils'
import { Update } from '@utils/contactSegments/context/ContactSegmentsContext'
import { logNewRelicError } from '@utils/new-relic.utils'

const rootText = 'ListPage.ContactSegments.CopySegments.Requests.Categorization'

interface BaseUpdate {
  update: Update
}

interface ShowCopySegmentsBannerInitiallyUtilsParams extends BaseUpdate {
  getAllItemsRequest: GetAllItemsRequestType
}

export const showCopySegmentsBannerInitiallyUtils = async ({ getAllItemsRequest, update }: ShowCopySegmentsBannerInitiallyUtilsParams) => {
  try {
    const { data, errors } = await getAllItemsRequest({ pageNumber: 0, pageSize: 50, type: ItemType.SEGMENT })
    if (data?.getAllItems !== undefined) {
      const parsedData = data?.getAllItems?.map((item) => parseItem(item)).filter((item) => item?.isEditable)
      if (parsedData?.length < 10) {
        update({ showUpgradeBanner: true })
      }
    } else if (errors) {
      showStatusToast(update, `${rootText}.GetAllItems`)
      logNewRelicError(errors)
    }
  } catch (e) {
    showStatusToast(update, `${rootText}.GetAllItems`)
    logNewRelicError(e)
  }
}
