import React, { useMemo } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Svg, { SvgType } from '@components/Svg'
import { RowAction } from '@components/TableV2/tableV2TS/interfaces'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'
import { Row } from '@tanstack/react-table'
import { toTrainCase } from '@utils/strings'
import { isFunction } from '@utils/utils'

import './TableV2RowPrimaryAction.css'

interface Props<T> {
  dataTest?: string
  rowAction: RowAction<T>
  row: Row<T>
}

const rootClass = 'tableV2-row-primary-action'

export const TableV2RowPrimaryAction = <T extends {}>({ dataTest = rootClass, rowAction, row }: Props<T>) => {
  const { t } = useTranslation()

  const getActionProps = ({ disabled, icon, label, tooltipMessage, hidden }: RowAction<T>) => {
    const isHidden = isFunction(hidden) ? hidden(row) : hidden
    const isDisabled = isFunction(disabled) ? disabled(row) : disabled
    const actionIcon = isFunction(icon)
      ? icon(row)
      : icon && (
          <Svg
            className={classNames({
              [`${rootClass}__svg-disabled`]: isDisabled,
            })}
            name={icon}
            type={SvgType.LARGER_ICON}
          />
        )

    const actionLabel = t(isDisabled ? (isFunction(tooltipMessage) ? tooltipMessage(row) : tooltipMessage) : isFunction(label) ? label(row) : label)
    return { isHidden, isDisabled, icon: actionIcon, label: actionLabel }
  }

  const { onClick, link, isHidden, isDisabled, icon, label, tooltipAlign, tooltipAlignOffset, inFooter } = useMemo(
    () => ({ ...rowAction, ...getActionProps(rowAction) }),
    [rowAction, row]
  )

  if (isHidden) {
    return null
  }

  if (inFooter) {
    return null
  }

  return (
    <Tooltip
      triggerClassName={`${rootClass}__tooltip`}
      disabledTrigger={isDisabled}
      position={'top'}
      align={tooltipAlign}
      alignOffset={tooltipAlignOffset}
      arrowOffset={10}
      sideOffset={2}
      trigger={
        <Button
          disabled={isDisabled}
          dataTest={`${dataTest}-${toTrainCase(label)}-button`}
          className={classNames(`${rootClass}__button`, { [`${rootClass}__button-disabled`]: isDisabled })}
          buttonType={ButtonType.TABLE_ACTION}
          onClick={(e) => {
            e.stopPropagation()
            onClick(row)
          }}
          isLink={!!link}
          to={link}
        >
          {icon && icon}
        </Button>
      }
    >
      {label}
    </Tooltip>
  )
}
