import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import _ from 'lodash'

import Modal, { ModalBody } from '@components/Modal'
import Spinner, { LoaderSize } from '@components/Spinner/Spinner'
import { BeeEmailContentDto, BeeSingleTemplateResponse, BeeTemplate } from '@graphql/types/microservice/email-management-types'
import { FilterTypes } from '@utils/filter'

import TemplateCatalogModalContent from './components/TemplateCatalogModalContent/TemplateCatalogModalContent'
import TemplateCatalogModalFooter from './components/TemplateCatalogModalFooter'
import TemplateCatalogModalHeader from './components/TemplateCatalogModalHeader'
import { TemplateCatalogModalPreview } from './components/TemplateCatalogModalPreview/TemplateCatalogModalPreview'
import {
  CreateBeeTemplateMessagePromiseType,
  GetBeeTemplatesRecentlyViewedByIdsPromiseType,
  GetCategoriesPromiseType,
  GetRecentlyViewedTemplatesPromiseType,
  GetSingleBeeTemplatePromiseType,
  GetTemplatesPromiseType,
  UpdateBeeTemplatesRecentlyViewedPromiseType,
  UpdateEmailManagementBeeTemplatesRecentlyViewedPromiseType,
} from './graphql/TemplateCatalogModal.graphql'
import { TemplateCatalogModalContext, TemplateCatalogSubType } from './TemplateCatalogModal.context'
import { TemplateCatalogModalSidebarContainer } from './TemplateCatalogModalSidebarContainer'
import {
  CatalogFolderItem,
  getCategoriesFromResponse,
  mapToBeeTemplate,
  navigateToBeeComposer,
  TEMPLATES_DEFAULT_PAGE_SIZE,
} from './utils/TemplateCatalogModal.utils'
import { ImagePickerView } from '../ImagePickerModal/utils/ImagePickerModal.utils'

import './TemplateCatalogModal.css'

export interface TemplateCatalogModalProps {
  className?: string
  dataTest?: string
  isOpen: boolean
  onGetTemplates: GetTemplatesPromiseType
  onGetCategories: GetCategoriesPromiseType
  onUseTemplate: CreateBeeTemplateMessagePromiseType
  onGetSingleTemplate: GetSingleBeeTemplatePromiseType
  onGetRecentlyViewedIds: GetBeeTemplatesRecentlyViewedByIdsPromiseType
  onUpdateRecentlyViewed: UpdateBeeTemplatesRecentlyViewedPromiseType
  onUpdateEmailManagementRecentlyViewed: UpdateEmailManagementBeeTemplatesRecentlyViewedPromiseType
  onGetRecentlyViewedTemplates: GetRecentlyViewedTemplatesPromiseType
  setShowTemplateCatalog: Dispatch<SetStateAction<boolean>>
}

const rootClass = 'template-catalog-modal'

const saveScrollSessionKey = `${rootClass}-scroll-top`

const TemplateCatalogModal: FC<TemplateCatalogModalProps> = (props: TemplateCatalogModalProps) => {
  const {
    dataTest = rootClass,
    isOpen,
    setShowTemplateCatalog,
    onGetTemplates,
    onGetCategories,
    onUseTemplate,
    onGetSingleTemplate,
    onGetRecentlyViewedIds,
    onUpdateRecentlyViewed,
    onGetRecentlyViewedTemplates,
    onUpdateEmailManagementRecentlyViewed,
  } = props

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [allLoaded, setAllLoaded] = useState<boolean>(false)
  const [templatesData, setTemplatesData] = useState<BeeTemplate[] | BeeEmailContentDto[]>([])
  const [categoriesData, setCategoriesData] = useState<CatalogFolderItem[]>([])
  const [templatesLoading, setTemplatesLoading] = useState<boolean>(true)
  const [categoriesLoading, setCategoriesLoading] = useState<boolean>(true)
  const [recenlyViewedLoading, setRecenlyViewedLoading] = useState<boolean>(false)
  const [previewItemLoading, setPreviewItemLoading] = useState<boolean>(false)
  const [createTemplateLoading, setCreateTemplateLoading] = useState<boolean>(false)
  const [filterCounts, setFilterCounts] = useState<{ [key in string]: number | undefined }>({})
  const [activeSubTypes, setActiveSubTypes] = useState<TemplateCatalogSubType[]>([])
  const [selectedItem, setSelectedItem] = useState<BeeTemplate>()
  const [previewItem, setPreviewItem] = useState<BeeSingleTemplateResponse>()
  const [activeFilter, setActiveFilter] = useState<string>(FilterTypes.ALL_CATALOG_TEMPLATES)
  const [isPreview, setIsPreview] = useState<boolean>(false)
  const [view, setView] = useState<ImagePickerView>(ImagePickerView.GRID)
  const [recentlyViewedIds, setRecentlyViewedIds] = useState<Array<string | undefined>>([])

  const [loadingNextData, setLoadingNextData] = useState<boolean>(false)

  const controllerRef = useRef<AbortController | null>(null)

  const createNewAbortController = () => {
    if (controllerRef.current) {
      controllerRef.current.abort()
    }
    controllerRef.current = new AbortController()
  }

  const variables = useMemo(
    () => ({
      filter: activeSubTypes.map((subType) => subType.id),
      search: searchTerm,
      pageSize: TEMPLATES_DEFAULT_PAGE_SIZE,
    }),
    [activeSubTypes, searchTerm]
  )

  useEffect(() => {
    const fetchCategoriesAndRecentlyViewed = async () => {
      setCategoriesLoading(true)
      try {
        const { data: categoriesData } = await onGetCategories()

        if (categoriesData) {
          const { data: recentlyViewedIds } = await onGetRecentlyViewedIds()

          if (activeFilter === FilterTypes.RECENTLY_VIEWED) {
            setRecentlyViewedIds(recentlyViewedIds?.getBeeTemplatesRecentlyView ?? [])
          } else {
            setRecentlyViewedIds((cur) => _.union(recentlyViewedIds?.getBeeTemplatesRecentlyView ?? [], cur))
          }

          setCategoriesData(getCategoriesFromResponse(categoriesData))
          setFilterCounts({
            [FilterTypes.ALL_CATALOG_TEMPLATES]: categoriesData?.getBeeTemplateCategories?.sizeAllTemplates,
            [FilterTypes.RECENTLY_VIEWED]: recentlyViewedIds?.getBeeTemplatesRecentlyView?.length,
          })
        }
      } finally {
        setCategoriesLoading(false)
        setRecenlyViewedLoading(false)
      }
    }

    if (!isPreview) {
      fetchCategoriesAndRecentlyViewed()
    }
  }, [isPreview])

  const getBeeRecentlyViewedTemplates = useCallback(async () => {
    try {
      setTemplatesLoading(true)
      setCurrentPage(1)

      const { data } = await onGetRecentlyViewedTemplates({
        ...variables,
        filter: activeSubTypes.map((subType) => subType.id),
        beeTemplateIds: recentlyViewedIds,
      })

      const result = data?.getBeeRecentlyTemplates?.filter((item): item is BeeEmailContentDto => item !== undefined)?.map(mapToBeeTemplate) ?? []
      if (result) {
        setTemplatesData(result as BeeEmailContentDto[])
      }
    } finally {
      setTemplatesLoading(false)
    }
  }, [activeSubTypes, recentlyViewedIds, variables])

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        setTemplatesLoading(true)
        setCurrentPage(1)

        const { data } = await onGetTemplates({ ...variables, pageNumber: 1 })

        const result = data?.getBeeTemplates?.results?.filter((item): item is BeeTemplate => item !== undefined) ?? []

        if (result) {
          setAllLoaded(result.length < TEMPLATES_DEFAULT_PAGE_SIZE)
          setTemplatesData(result.filter((item) => item.id !== 'empty'))
        }
      } finally {
        setTemplatesLoading(false)
      }
    }

    if (activeFilter === FilterTypes.RECENTLY_VIEWED) {
      if (!recenlyViewedLoading) {
        getBeeRecentlyViewedTemplates()
      }
    } else {
      fetchTemplates()
    }
  }, [searchTerm, activeSubTypes, variables, activeFilter, recenlyViewedLoading])

  const handleClose = () => {
    if (controllerRef.current) {
      controllerRef.current.abort()
    }
    setShowTemplateCatalog(false)
  }

  const handleBackArrowClick = () => {
    if (controllerRef.current) {
      controllerRef.current.abort()
    }
    setPreviewItemLoading(false)
    setCreateTemplateLoading(false)
    if (activeFilter === FilterTypes.RECENTLY_VIEWED) {
      setRecenlyViewedLoading(true)
    }
    setIsPreview(false)
  }

  const handleSearch = (term: string) => {
    if (term === '') {
      setCurrentPage(1)
    }
    setSearchTerm(term)
  }

  const handleSubTypeChange = useCallback(
    (subType: TemplateCatalogSubType) => {
      if (activeSubTypes.some((item) => item.id === subType.id)) {
        setActiveSubTypes(activeSubTypes.filter((filter) => filter.id !== subType.id))
      } else {
        setActiveSubTypes([...activeSubTypes, subType])
      }
    },
    [activeSubTypes]
  )

  const handleClearSubTypes = () => {
    setActiveSubTypes([])
  }

  const handleSelectItem = (item: BeeTemplate) => {
    setSelectedItem(item)
  }

  const handleSelectFilter = (filterName: string) => {
    setSearchTerm('')
    handleClearSubTypes()
    setActiveFilter(filterName)
  }

  const handlePreviewItem = useCallback((item: BeeTemplate) => {
    const id = item.id ?? ''
    setIsPreview(true)
    setPreviewItemLoading(true)

    onGetSingleTemplate(id)
      .then(({ data }) => {
        const previewData = data?.getSingleBeeTemplate
        setPreviewItem(previewData)

        if (previewData) {
          const variables = {
            beeEmailContentData: {
              categories: previewData.categories,
              publishAt: previewData?.published_at,
              searchTerms: previewData?.tags,
              templateId: previewData?.id,
              thumbnailLargeUrl: previewData?.thumbnail_large,
              thumbnailUrl: previewData?.thumbnail,
              title: previewData?.title,
            },
          }

          onUpdateRecentlyViewed(previewData?.id ?? '')
          onUpdateEmailManagementRecentlyViewed(variables)
        }
      })
      .finally(() => {
        setPreviewItemLoading(false)
      })
  }, [])

  const handleLoadNext = useCallback(async () => {
    if (!allLoaded && activeFilter !== FilterTypes.RECENTLY_VIEWED) {
      try {
        setLoadingNextData(true)
        const { data } = await onGetTemplates({
          ...variables,
          pageNumber: currentPage + 1,
        })

        const result = (data?.getBeeTemplates?.results || []).filter((item): item is BeeTemplate => item !== undefined)

        setCurrentPage((prevPage) => prevPage + 1)
        setTemplatesData((cur) => _.unionBy(cur as BeeTemplate[], result as BeeTemplate[], 'id'))

        setAllLoaded(result.length < TEMPLATES_DEFAULT_PAGE_SIZE)
      } finally {
        setLoadingNextData(false)
        setTemplatesLoading(false)
      }
    }
    return Promise.resolve()
  }, [activeSubTypes, currentPage, searchTerm, allLoaded, variables, activeFilter])

  const handleUseTemplate = useCallback(() => {
    createNewAbortController()
    const signal = controllerRef.current?.signal
    const id = isPreview ? previewItem?.id : selectedItem?.id

    if (id) {
      setCreateTemplateLoading(true)
      onUseTemplate(id, signal)
        .then(({ data }) => {
          const variables = {
            beeEmailContentData: {
              publishAt: selectedItem?.published_at,
              searchTerms: selectedItem?.categories,
              templateId: selectedItem?.id,
              thumbnailLargeUrl: selectedItem?.thumbnail_large,
              thumbnailUrl: selectedItem?.thumbnail,
              title: selectedItem?.title,
            },
          }
          const templateId = data?.createBeeTemplateMessage ?? ''
          if (!isPreview && activeFilter !== FilterTypes.RECENTLY_VIEWED) {
            onUpdateRecentlyViewed(selectedItem?.id ?? '')
            onUpdateEmailManagementRecentlyViewed(variables)
          }
          setShowTemplateCatalog(false)
          navigateToBeeComposer(templateId)
        })
        .finally(() => {
          setCreateTemplateLoading(false)
        })
    }

    return () => {
      if (controllerRef.current) {
        controllerRef.current.abort()
      }
    }
  }, [isPreview, previewItem, selectedItem])

  const handleViewChange = useCallback((view: ImagePickerView) => {
    setView(view)
  }, [])

  useEffect(() => {
    setTemplatesData([])
    setCurrentPage(1)
  }, [activeSubTypes])

  const hideSearch = useMemo(
    () => isPreview || createTemplateLoading || (activeFilter === FilterTypes.RECENTLY_VIEWED && !templatesLoading && !templatesData.length),
    [activeFilter, createTemplateLoading, isPreview, templatesData.length, templatesLoading]
  )

  return (
    <>
      <Modal data-test={dataTest} isOpen={isOpen} className={rootClass}>
        <TemplateCatalogModalHeader
          onBack={handleBackArrowClick}
          onSearch={handleSearch}
          searchTerm={searchTerm}
          hideSearch={hideSearch}
          isPreview={isPreview}
        />
        {categoriesLoading || previewItemLoading || createTemplateLoading ? (
          <Spinner className={`${rootClass}__loader`} size={LoaderSize.LARGE} />
        ) : (
          <ModalBody className={`${rootClass}__body`}>
            <TemplateCatalogModalContext.Provider
              value={{
                previewItem,
                catalogItems: templatesData,
                loadingNextData,
                templatesLoading,
                searchText: searchTerm,
                categoriesLoading,
                allCategories: categoriesData,
                filterCounts,
                activeSubTypes,
                selectedItem,
                activeFilter,
                onSubTypeChange: handleSubTypeChange,
                onSubTypesClear: handleClearSubTypes,
                onTemplateSelect: handleSelectItem,
                onFilterChange: handleSelectFilter,
                onPreview: handlePreviewItem,
              }}
            >
              {isPreview ? (
                <TemplateCatalogModalPreview setIsPreview={setIsPreview} onSearchTermSelect={handleSearch} />
              ) : (
                <>
                  <TemplateCatalogModalSidebarContainer />
                  <TemplateCatalogModalContent
                    view={view}
                    onLoadNext={handleLoadNext}
                    handleViewChange={handleViewChange}
                    allLoaded={allLoaded}
                    saveScrollSessionKey={saveScrollSessionKey}
                  />
                </>
              )}
            </TemplateCatalogModalContext.Provider>
          </ModalBody>
        )}
        <TemplateCatalogModalFooter
          onSubmit={handleUseTemplate}
          onCancel={handleClose}
          onPreview={() => handlePreviewItem(selectedItem!)}
          isPreview={isPreview}
          selectedTitle={isPreview ? previewItem?.title : selectedItem?.title}
          previewLoading={previewItemLoading}
          createTemplateLoading={createTemplateLoading}
        />
      </Modal>
    </>
  )
}

export default TemplateCatalogModal
