import { gql } from '@apollo/client'

export default gql`
  query lastSync {
    lastSync {
      ended
      entitySyncKeyValueList {
        entitySyncDetailBean {
          appendCount
          deleteCount
          detail
          errorCount
          errorInstructions
          errorMsg
          errors {
            email
            errorMsg
            recId
          }
          jobId
          op
          skipCount
          status
          type
          updateCount
        }
        modelEntityTypeString
      }
      pullOptInDeleteCount
      pullOptOutAppendCount
      pushOptInDeleteCount
      pushOptOutAppendCount
      started
      syncResult
    }
  }
`
