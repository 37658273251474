import { gql } from '@apollo/client'

export default gql`
  query getSingleBeeTemplate($id: String) {
    getSingleBeeTemplate(id: $id) {
      categories
      collections
      context
      description
      designer {
        avatar_url
        description
        display_name
        id
        short_description
      }
      htmlData
      htmlUrl
      id
      is_blank
      json_data
      order
      published_at
      short_description
      tags
      template_type
      thumbnail
      thumbnail_large
      title
    }
  }
`
