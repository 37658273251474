import { gql } from '@apollo/client'

export default gql`
  query loadCrmSalesUsers($userTypes: String, $forcePull: Boolean) {
    loadCrmSalesUsers(userTypes: $userTypes, forcePull: $forcePull) {
      sForceUsers {
        login
        loginKey
        email
        firstName
        lastName
        externalId
        role
        isActOnUser
        isNotAvailable
        zip
        country
        title
        cell
        userId
        phone
        street
        street2
        state
        fax
        city
      }
      isUpdated
      maxSforceSalesUsers
      numSforceSalesUsers
      numSforceSalesUsersAvailable
      status
      message
    }
  }
`
