import { useEffect, useRef } from 'react'

import _ from 'lodash'

import { EmailMessage } from '@utils/composer/context/EmailComposer.context'
import { LandingPage } from '@utils/composer/context/LandingPageComposer.context'

import { getRowDisplayConditions } from '../utils/DisplayConditions.utils'

const getJsonTriggeredContent = (message: EmailMessage) => {
  // Any JSON-only change that needs to trigger autosave should be compiled here for comparison
  return { displayCondition: getRowDisplayConditions(message), plainTextOnlyDynamicContent: message.plainTextOnlyDynamicContent }
}

const omitList: Array<keyof LandingPage> = [
  'globalRedirectUrl',
  'hasUnpublishedChanges',
  'isLandingPagePublished',
  'createLandingPageFields',
  'lastPublishedTime',
  'showErrors',
  'isLandingPageEnabled',
  'hasLandingPageIndividualUrlError',
  'lastPublishedMessageData',
  'customJavaScript',
  'customCss',
  'isPublishSaveCalled',
  'isDetectedChangesOnDesignTab',
  'isNewHtmlGenerated',
]

export const useAutoSaveOnChanges = (
  onMessageChangeDetected: (callAutoSave: boolean, isTitleChanged?: boolean) => void,
  message: EmailMessage,
  landingPage?: LandingPage
) => {
  const lastSavedMessage = useRef<EmailMessage>(message)
  const lastSavedLandingPage = useRef<LandingPage>(landingPage || {})
  const receivedJsonOnlyChange = useRef(false)

  useEffect(() => {
    const allowedToSave =
      !!lastSavedMessage.current.id &&
      // Checking for changing the default templateHtml value.
      // When a message is new, do not trigger autosave as long as the user hasn't made any changes
      // (except if the message is a plain text only message, where the template can legitimately be completely blank)
      (lastSavedMessage.current.isPlainTextOnly || lastSavedMessage.current.isUploadHtmlOnly || !!lastSavedMessage.current.templateHtml)

    if (allowedToSave) {
      // We manage BeeEditor changes with 2 callbacks 'onChange' and 'onSend'.
      // When 'onChange' is triggered, it updates 'templateJson'. Following that, 'onSend' is called,
      // which updates 'templateHtml'. There might be a delay in these callbacks, which could lead to
      // unwanted duplicate calls to the onSave API if the delay exceeds AUTOSAVE_DEBOUNCE_TIME.
      // To prevent this, compare the html and json separately.

      const messageChangeDetected =
        landingPage?.isLandingPage && _.isEmpty(landingPage)
          ? !_.isEqual({ ...lastSavedMessage.current }, { ...message })
          : !_.isEqual(_.omit({ ...lastSavedMessage.current }, ['templateHtml']), _.omit({ ...message }, ['templateHtml']))

      const isMessageTitleChanged = !_.isEqual(lastSavedMessage.current.title, message.title)

      const omitListWithCustomCodeChanged = !landingPage?.customCodeChanged ? [...omitList, 'customCodeChanged'] : omitList

      const landingPageChangeDetected = !_.isEqual(
        _.omit(lastSavedLandingPage.current, omitListWithCustomCodeChanged),
        _.omit(landingPage, omitListWithCustomCodeChanged)
      )

      const jsonChangeDetected = !_.isEqual(getJsonTriggeredContent(lastSavedMessage.current), getJsonTriggeredContent(message))

      // If we receive a JSON-only change then the next thing we receive will be regenerated HTML,
      // which will not be different from the last saved message but we still want to save the json
      const shouldSave = messageChangeDetected || landingPageChangeDetected || receivedJsonOnlyChange.current
      const jsonOnlyChange = jsonChangeDetected && !messageChangeDetected

      if (shouldSave) {
        onMessageChangeDetected(true, isMessageTitleChanged)
        receivedJsonOnlyChange.current = false
      } else if (jsonOnlyChange) {
        onMessageChangeDetected(false)
        receivedJsonOnlyChange.current = true
      }
    }
    return () => {
      lastSavedMessage.current = _.cloneDeep(message)
      lastSavedLandingPage.current = _.cloneDeep(landingPage || {})
    }
  }, [onMessageChangeDetected, message, landingPage])
}
