import React, { MouseEvent } from 'react'
import { Row } from 'react-table'

import { TableActions } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.constants'
import { getActionProps } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.utils'
import { ItemDtoRow, ListPageTableActionCustomProps, TableProps, Update } from '@complex/ListingPage/Context/ListingPageCommon.context'
import ClipboardCopy, { CopyTextSize } from '@components/ClipboardCopy/ClipboardCopy'
import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import PillLabel from '@components/PillLabel/PillLabel'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { SvgNames, SvgType } from '@components/Svg'
import Svg from '@components/Svg/Svg'
import { HeaderAction, RowAction } from '@components/Table/Table'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { AccountManagementRow } from '@src/pages/listingPages/AccountManagement/AccountManagementListingPageContainer.types'
import { CustomActionHelpers } from '@src/pages/listingPages/AutomatedPrograms/AutomatedProgramsListingPage.constants'
import { ItemType } from '@utils/categorization'
import { sendBroadcastMessage } from '@utils/composer/commonComposer/broadcastChannel'
import { formatNumber, getFormattedNumber } from '@utils/numbers'
import { renderPathToFolder } from '@utils/searchUtils'

import '../AccountManagementListingPage.css'

export enum AccountManagementCustomTableActions {
  LOGIN = 'LOGIN',
  SHARE_PERMISSIONS = 'SHARE_PERMISSIONS',
  ASSIGN_ACTIVE_CONTACTS = 'ASSIGN_ACTIVE_CONTACTS',
  ASSIGN_MANAGERS = 'ASSIGN_MANAGERS',
  ASSIGN_SALES_USERS = 'ASSIGN_SALES_USERS',
  MOVE_TO_FOLDER = 'MOVE_TO_FOLDER',
  REMOVE_FROM_FOLDER = 'REMOVE_FROM_FOLDER',
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
  SHARE_PERMISSION_ITEMS = 'SHARE_PERMISSION_ITEMS',
}

const rowActionCustomProps: ListPageTableActionCustomProps[] = [
  { name: AccountManagementCustomTableActions.LOGIN, position: 0, hasTopSection: false, isInDropdown: false },
  { name: AccountManagementCustomTableActions.SHARE_PERMISSIONS, position: 1, hasTopSection: false, isInDropdown: true },
  { name: AccountManagementCustomTableActions.ASSIGN_ACTIVE_CONTACTS, position: 2, hasTopSection: true, isInDropdown: true },
  { name: AccountManagementCustomTableActions.ASSIGN_MANAGERS, position: 3, hasTopSection: true, isInDropdown: true },
  { name: AccountManagementCustomTableActions.ASSIGN_SALES_USERS, position: 4, hasTopSection: false, isInDropdown: true },
  { name: AccountManagementCustomTableActions.MOVE_TO_FOLDER, position: 6, hasTopSection: true, isInDropdown: true },
  { name: AccountManagementCustomTableActions.REMOVE_FROM_FOLDER, position: 7, hasTopSection: false, isInDropdown: true },
  { name: AccountManagementCustomTableActions.DELETE_ACCOUNT, position: 8, hasTopSection: true, isInDropdown: true },
]

const headerActionCustomProps: ListPageTableActionCustomProps[] = [
  { name: AccountManagementCustomTableActions.SHARE_PERMISSION_ITEMS, position: 0, isInDropdown: false },
]

const onCustomTableAction = (
  customTableAction: Exclude<
    AccountManagementCustomTableActions,
    AccountManagementCustomTableActions.MOVE_TO_FOLDER | AccountManagementCustomTableActions.REMOVE_FROM_FOLDER
  >,
  update: Update,
  customActionHelpers: CustomActionHelpers,
  selectedItem?: ItemDto
) => {
  const customAction: {
    [key in Exclude<
      AccountManagementCustomTableActions,
      AccountManagementCustomTableActions.MOVE_TO_FOLDER | AccountManagementCustomTableActions.REMOVE_FROM_FOLDER
    >]: () => void
  } = {
    [AccountManagementCustomTableActions.LOGIN]: () => {
      const { loginToChildAccount } = customActionHelpers
      loginToChildAccount(selectedItem?.accountId, update)
    },
    [AccountManagementCustomTableActions.SHARE_PERMISSIONS]: () => {
      update({ customTableAction: AccountManagementCustomTableActions.SHARE_PERMISSIONS, showCustomModal: true })
    },
    [AccountManagementCustomTableActions.ASSIGN_ACTIVE_CONTACTS]: () => {
      update({ customTableAction: AccountManagementCustomTableActions.ASSIGN_ACTIVE_CONTACTS, showCustomModal: true })
    },
    [AccountManagementCustomTableActions.ASSIGN_MANAGERS]: () => {
      update({ customTableAction: AccountManagementCustomTableActions.ASSIGN_MANAGERS, showCustomModal: true })
    },
    [AccountManagementCustomTableActions.ASSIGN_SALES_USERS]: () => {
      update({ customTableAction: AccountManagementCustomTableActions.ASSIGN_SALES_USERS, showCustomModal: true })
    },
    [AccountManagementCustomTableActions.DELETE_ACCOUNT]: () => {
      update({ customTableAction: AccountManagementCustomTableActions.DELETE_ACCOUNT, showCustomModal: true })
    },
    [AccountManagementCustomTableActions.SHARE_PERMISSION_ITEMS]: () => {
      update({ customTableAction: AccountManagementCustomTableActions.SHARE_PERMISSION_ITEMS, showCustomModal: true })
    },
  }
  customAction[customTableAction]()
}

const isHidden = (row: AccountManagementRow, isAdmin: boolean) => !isAdmin || row.parent === 'true'

const getCustomRowActions = (
  tableActions: TableActions,
  t: Function,
  isAdmin: boolean,
  currentOrgId: string,
  hasAgencyAssignSalesUsers: boolean,
  currentUserAccounts: number[]
): RowAction[] => [
  {
    label: t('Login'),
    icon: SvgNames.logOutSlimArrow,
    hidden: (row: Row<ItemDto>) => {
      return row.original.accountId.toString() === currentOrgId || !currentUserAccounts.find((account) => account === row.original.accountId)
    },
    onClick: (row: Row<ItemDto>) => {
      sendBroadcastMessage({ type: 'LOGIN', payload: row.original.accountId.toString() })
      return tableActions.customTableRowAction(AccountManagementCustomTableActions.LOGIN, row)
    },
    ...getActionProps(AccountManagementCustomTableActions.LOGIN, rowActionCustomProps),
  },
  {
    label: t('Share permissions'),
    icon: SvgNames.share,
    hidden: (row: Row<ItemDto>) => {
      return isHidden(row.original as AccountManagementRow, isAdmin)
    },
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(AccountManagementCustomTableActions.SHARE_PERMISSIONS, row),
    ...getActionProps(AccountManagementCustomTableActions.SHARE_PERMISSIONS, rowActionCustomProps),
  },
  {
    label: t('Assign active contacts'),
    icon: SvgNames.userUnselected,
    hidden: (row: Row<ItemDto>) => {
      return isHidden(row.original as AccountManagementRow, isAdmin)
    },
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(AccountManagementCustomTableActions.ASSIGN_ACTIVE_CONTACTS, row),
    ...getActionProps(AccountManagementCustomTableActions.ASSIGN_ACTIVE_CONTACTS, rowActionCustomProps),
  },
  {
    label: t('Assign managers'),
    icon: SvgNames.threeUsers,
    hidden: (row: Row<ItemDto>) => {
      return isHidden(row.original as AccountManagementRow, isAdmin)
    },
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(AccountManagementCustomTableActions.ASSIGN_MANAGERS, row),
    ...getActionProps(AccountManagementCustomTableActions.ASSIGN_MANAGERS, rowActionCustomProps),
  },
  {
    label: t('Assign sales users'),
    icon: SvgNames.salesUser,
    hidden: (row: Row<ItemDto>) => isHidden(row.original as AccountManagementRow, isAdmin) || !hasAgencyAssignSalesUsers,
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(AccountManagementCustomTableActions.ASSIGN_SALES_USERS, row),
    ...getActionProps(AccountManagementCustomTableActions.ASSIGN_SALES_USERS, rowActionCustomProps),
  },
  {
    label: t('Delete account'),
    icon: SvgNames.delete,
    hidden: (row: Row<ItemDto>) => {
      return isHidden(row.original as AccountManagementRow, isAdmin)
    },
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(AccountManagementCustomTableActions.DELETE_ACCOUNT, row),
    ...getActionProps(AccountManagementCustomTableActions.DELETE_ACCOUNT, rowActionCustomProps),
  },
]

const getCustomHeaderActions = (tableActions: TableActions, t: Function): HeaderAction[] => [
  {
    label: t('Share permissions'),
    icon: SvgNames.share,
    hasTooltip: true,
    hidden: () => false,
    onClick: (_event: MouseEvent<HTMLButtonElement> | Event) =>
      tableActions.customTableHeaderAction(AccountManagementCustomTableActions.SHARE_PERMISSION_ITEMS),
    ...getActionProps(AccountManagementCustomTableActions.SHARE_PERMISSION_ITEMS, headerActionCustomProps),
  },
]

const renderMarketingSeats = (row: any) => {
  const usedSeats = row.original.usedSeats === '' ? 0 : row.original.usedSeats
  const remaining = row.original.totalSeats - usedSeats
  return `${remaining < 0 ? 0 : remaining}/${row.original.totalSeats}`
}

const renderUsedContacts = (row: any, rootClass: string, t: Function) => {
  const usedContacts = row.original.usedContacts

  const activeContacts = row.original.activeContacts
  const usedPct = parseInt(usedContacts) / parseInt(activeContacts)
  const indicator = usedPct >= 1 || (activeContacts === 0 && usedContacts > 0) ? 'error' : usedPct > 0.8 ? 'warning' : ''
  const icon = indicator === 'warning' ? SvgNames.warningSolidNoFill : SvgNames.errorSolid
  const count = activeContacts === 0 && usedContacts > 0 ? 'Over 100' : Math.round(usedPct * 100)
  const tip = t('AccountManagement.Tables.UsedContacts.Warning', { count })

  return (
    <div className={`${rootClass}__used-contacts`}>
      {indicator && (
        <Tooltip trigger={<Svg name={icon} type={SvgType.ICON} className={`${rootClass}__used-contacts-${indicator}`} />}>
          <Typography text={tip} type={TextType.BODY_TEXT_WHITE} tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }} />
        </Tooltip>
      )}
      <div>{getFormattedNumber(usedContacts)}</div>
    </div>
  )
}

const renderItemNameVal = (original: ItemDto, search: string, rootClass: string, t: Function) => {
  const item = original as any
  const trigger = search ? renderBoldTextOnMatch(item.name, search, true) : item.name
  const fullTrigger =
    item.parent === 'true' ? (
      <div className={`${rootClass}__name`}>
        <TextWithTooltipOnEllip
          tooltipProps={{
            children: item.name,
          }}
          typographyProps={{
            text: trigger,
            weight: TextWeight.MEDIUM,
          }}
        />
        <PillLabel text={t('Parent')} />
      </div>
    ) : (
      <TextWithTooltipOnEllip
        tooltipProps={{
          children: item.name,
        }}
        typographyProps={{
          text: trigger,
          weight: TextWeight.MEDIUM,
        }}
      />
    )

  return (
    <>
      {fullTrigger}
      <div className={`${rootClass}__name-id`}>
        <Typography text={`${t('Account ID')}: ${item.accountId}`} type={TextType.BODY_TEXT_SMALL_LIGHT} />
        <ClipboardCopy value={item.accountId} text={t('Copy ID')} size={CopyTextSize.REGULAR} lineHeight={LineHeight.TINY} />
      </div>
    </>
  )
}

const renderSearchColumns = (
  searchInAllItems: boolean | undefined,
  currentFolder: FolderData,
  search: string,
  folders: FolderData[],
  t: Function
): ColumnDefWithAdditionalProps<ItemDtoRow, any>[] => [
  {
    header: 'Account Name',
    accessorKey: 'name',
    textAlign: 'left',
    cell: (cell) => renderItemNameVal(cell.row.original, search, 'account-management-listing-page', t),
  },
  {
    header: 'Location',
    accessorKey: 'folderId',
    textAlign: 'left',
    maxSize: 150,
    disableSorting: true,
    cell: (cell) => renderPathToFolder(ItemType.ACCOUNT, cell.getValue(), folders, searchInAllItems, 'list-page-table-container', t, currentFolder),
  },
  {
    header: 'Active Contacts',
    accessorKey: 'activeContacts',
    textAlign: 'right',
    maxSize: 150,
    fieldType: 'integer',
    cell: (cell) => formatNumber(cell.getValue()),
  },
  {
    header: 'Used Contacts',
    accessorKey: 'usedContacts',
    textAlign: 'right',
    maxSize: 150,
    fieldType: 'integer',
    cell: (cell) => renderUsedContacts(cell.row, 'account-management-listing-page', t),
  },
  {
    header: 'Marketing Seats Left',
    accessorKey: 'marketingSeats',
    textAlign: 'right',
    maxSize: 200,
    cell: (cell) => renderMarketingSeats(cell.row),
  },
]

const getTableV2Columns = (t: Function): ColumnDefWithAdditionalProps<ItemDto, any>[] => [
  {
    header: 'Account Name',
    accessorKey: 'name',
    textAlign: 'left',
    cell: (cell) => renderItemNameVal(cell.row.original, '', 'account-management-listing-page', t),
  },
  {
    header: 'Active Contacts',
    accessorKey: 'activeContacts',
    textAlign: 'right',
    maxSize: 150,
    fieldType: 'integer',
    cell: (cell) => formatNumber(cell.getValue()),
  },
  {
    header: 'Used Contacts',
    accessorKey: 'usedContacts',
    textAlign: 'right',
    maxSize: 150,
    fieldType: 'integer',
    cell: (cell) => renderUsedContacts(cell.row, 'account-management-listing-page', t),
  },
  {
    header: 'Marketing Seats Left',
    accessorKey: 'usedSeats',
    textAlign: 'right',
    maxSize: 200,
    fieldType: 'integer',
    cell: (cell) => renderMarketingSeats(cell.row),
    sortingFn: (rowA: any, rowB: any) => rowA.original.totalSeats - rowA.original.usedSeats - (rowB.original.totalSeats - rowB.original.usedSeats),
  },
]

export const tableProps = (
  t: Function,
  isAdmin: boolean,
  hasAgencyAssignSalesUsers: boolean,
  customActionHelpers: CustomActionHelpers,
  currentOrgId: string,
  currentUserAccounts: number[],
  isRowSelectionDisabled?: TableProps['isRowSelectionDisabled']
): TableProps => ({
  columns: getTableV2Columns(t),
  hasAutoSelectedRows: false,
  hasExpander: false,
  rowActionCustomProps,
  renderSearchColumns: (searchInAllItems: boolean | undefined, currentFolder: FolderData, search: string, folders: FolderData[]) =>
    renderSearchColumns(searchInAllItems, currentFolder, search, folders, t) as any,
  headerActionCustomProps,
  listPage: 'AccountManagement',
  getCustomHeaderActions: isAdmin ? (tableActions) => getCustomHeaderActions(tableActions, t) : () => [] as HeaderAction[],
  getCustomRowActions: (tableActions) => getCustomRowActions(tableActions, t, isAdmin, currentOrgId, hasAgencyAssignSalesUsers, currentUserAccounts),
  onCustomTableAction: (customAction, update, listPageValues) =>
    onCustomTableAction(customAction, update, customActionHelpers, listPageValues.selectedRows[0]),
  actonAssetType: 'ACCOUNT',
  shareModalText: '',
  isRowSelectionDisabled,
})
