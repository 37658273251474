import { SubTypeConditionDtoInput } from '@graphql/types/microservice/categorization-types'
import { ItemType } from '@utils/categorization'

import { ListingPageSubType } from '../Context/ListingPageCommon.context'

enum ConditionOperator {
  And = 'AND',
  Or = 'OR',
}

interface UseSubTypeCondition {
  activeSubTypes: string[]
  subTypes?: ListingPageSubType[]
  itemType?: ItemType
}

export const useSubTypeCondition = (props: UseSubTypeCondition) => {
  const { activeSubTypes, subTypes, itemType } = props

  const subTypesWithAndCondition = subTypes?.filter((subType) => !!subType.hasAndCondition).map((subType) => subType.name)

  if (!subTypesWithAndCondition) {
    return
  }

  const subTypesWithOrCondition = activeSubTypes.filter(function (el) {
    return !subTypesWithAndCondition?.includes(el)
  })

  const filterActiveSubTypes = (subType: string) => activeSubTypes.includes(subType)

  const sortedActiveSubTypes = [...subTypesWithOrCondition.filter(filterActiveSubTypes), ...subTypesWithAndCondition.filter(filterActiveSubTypes)]

  const subTypeConditionFunc = (activeSubTypes: string[], index = 0): SubTypeConditionDtoInput => {
    return {
      subType: {
        itemType: itemType,
        name: activeSubTypes[index],
      },
      conditionOperator: subTypesWithOrCondition.includes(activeSubTypes[index]) ? ConditionOperator.Or : ConditionOperator.And,
      ...(activeSubTypes[index + 1] && { subTypeCondition: subTypeConditionFunc(activeSubTypes, index + 1) }),
    }
  }

  return activeSubTypes.length > 1 ? subTypeConditionFunc(sortedActiveSubTypes) : undefined
}
