import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonProps, ButtonType } from '@components/Button/Button'
import Svg, { SvgNames, SvgType } from '@components/Svg'

import './BackButton.css'

export interface BackButtonProps extends Omit<ButtonProps, 'buttonType' | 'children'> {
  noXMargin?: boolean
}

const rootClass = 'back-button'

const BackButton: FC<BackButtonProps> = (props: BackButtonProps) => {
  const { dataTest = rootClass, className, noXMargin, ...rest } = props
  return (
    <Button
      buttonType={ButtonType.NAVIGATION_BUTTON}
      className={classNames(className, {
        [`${rootClass}__with-x-margin`]: !noXMargin,
      })}
      dataTest={dataTest}
      {...rest}
    >
      <Svg name={SvgNames.arrowLeft} type={SvgType.MEDIUM_LARGE_ICON} dataTest={`${dataTest}-svg`} />
    </Button>
  )
}

export default BackButton
