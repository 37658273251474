import React, { FC, PropsWithChildren, useEffect, useMemo } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Spinner, { LoaderSize } from '@components/Spinner/Spinner'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight, TypographyProps } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import ValidationsList, { Validation, ValidationSectionStatus } from '@src/pages/EmailComposer/components/common/ValidationsList/ValidationsList'
import { OnStatusChangeFunc, ValidationSectionType } from '@utils/composer/context/EmailComposer.context'

import './ValidationSection.css'
export interface ValidationsSectionProps {
  type: ValidationSectionType
  title: string
  subTitle?: string | TypographyProps
  status?: ValidationSectionStatus
  validations?: Validation[]
  buttonLabel?: string
  isLoading?: boolean
  onClick?: VoidFunction
  onStatusChange: OnStatusChangeFunc
  className?: string
  dataTest?: string
  disableEditBtn?: boolean
  tooltipContentKey?: string
}

const rootClass = 'validation-section'

const ValidationSection: FC<PropsWithChildren<ValidationsSectionProps>> = ({
  type,
  title,
  subTitle,
  status,
  validations,
  buttonLabel,
  isLoading,
  onClick,
  onStatusChange,
  children,
  className,
  dataTest = rootClass,
  disableEditBtn = false,
  tooltipContentKey = '',
}) => {
  const { t } = useTranslation()
  const [svgName, _status] = useMemo(
    () =>
      status
        ? status === ValidationSectionStatus.ERROR
          ? [SvgNames.spamCheckFail, status]
          : status === ValidationSectionStatus.WARNING
          ? [SvgNames.spamCheckWarning, status]
          : status === ValidationSectionStatus.REST
          ? [SvgNames.spamCheckRest, status]
          : [SvgNames.spamCheckSuccess, status]
        : validations
        ? validations.some(({ loading }) => loading)
          ? [SvgNames.spamCheckRest, ValidationSectionStatus.REST]
          : validations.some(({ status }) => status === ValidationSectionStatus.ERROR)
          ? [SvgNames.spamCheckFail, ValidationSectionStatus.ERROR]
          : validations.some(({ status }) => status === ValidationSectionStatus.WARNING)
          ? [SvgNames.spamCheckWarning, ValidationSectionStatus.WARNING]
          : [SvgNames.spamCheckSuccess, ValidationSectionStatus.SUCCESS]
        : [SvgNames.spamCheckRest, ValidationSectionStatus.REST],
    [validations, status]
  )

  const subTitleProps = useMemo<null | TypographyProps>(
    () =>
      subTitle
        ? typeof subTitle === 'string'
          ? {
              text: t(subTitle),
              weight: TextWeight.MEDIUM,
            }
          : subTitle
        : null,
    [subTitle, t]
  )

  useEffect(() => {
    if (isLoading) {
      onStatusChange({ loading: true }, type)
    } else if (!validations) {
      onStatusChange({ loading: false, errors: 0, warnings: 0 }, type)
    } else {
      const loading = validations.some(({ loading }) => loading)
      if (loading) {
        onStatusChange({ loading }, type)
      } else {
        const { errors, warnings } = validations.reduce(
          (acc, { status }) => {
            switch (status) {
              case ValidationSectionStatus.ERROR:
                acc.errors = acc.errors + 1
                break
              case ValidationSectionStatus.WARNING:
                acc.warnings = acc.warnings + 1
            }
            return acc
          },
          { errors: 0, warnings: 0 }
        )
        onStatusChange({ errors, warnings, loading: false }, type)
      }
    }

    return () => {
      onStatusChange({ errors: 0, warnings: 0, loading: false }, type)
    }
  }, [onStatusChange, validations, type, isLoading])

  return (
    <div className={classNames(`${rootClass}__wrapper`, { [`${className}__wrapper`]: !!className })} data-test={dataTest}>
      <div className={classNames(rootClass, className)} data-test={dataTest}>
        {isLoading ? (
          <Spinner unsizeWrapper size={LoaderSize.MEDIUM} />
        ) : (
          <Svg name={svgName} type={SvgType.LARGE_ICON} dataTest={`${dataTest}-status`} />
        )}
        <div className={classNames('push-right-x3', `${rootClass}__content`)}>
          <Typography
            text={t(title)}
            type={TextType.SECTION_HEADER}
            weight={TextWeight.MEDIUM}
            dataTest={`${dataTest}-title`}
            className={subTitleProps ? undefined : 'push-up-x2'}
          />
          {subTitleProps && (
            <Typography
              dataTest={`${dataTest}-subtitle`}
              {...subTitleProps}
              className={classNames(`${rootClass}__content-subtitle`, subTitleProps.className)}
            />
          )}
          <ValidationsList validations={validations} dataTest={`${dataTest}-list`} className={className ? `${className}__list` : undefined} />
        </div>
        <Tooltip
          hide={!disableEditBtn}
          triggerClassName={`${rootClass}__tooltip-trigger`}
          position="top"
          trigger={
            buttonLabel && (
              <div className="push-right-x4">
                <Button
                  disabled={disableEditBtn}
                  className={`${rootClass}-button`}
                  buttonType={ButtonType.OUTLINE}
                  onClick={onClick}
                  dataTest={`${dataTest}-button`}
                >
                  {t(buttonLabel)}
                </Button>
              </div>
            )
          }
        >
          {t(tooltipContentKey)}
        </Tooltip>
      </div>
      {children}
    </div>
  )
}

export default ValidationSection
