import { gql } from '@apollo/client'

export default gql`
  query getMessagePlainText($messageId: String, $dynamicContentIds: [String]) {
    getMessagePlainText(messageId: $messageId, dynamicContentIds: $dynamicContentIds) {
      plainText
      customTextPart
      status
    }
  }
`
