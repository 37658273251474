import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import classNames from 'classnames'
import _ from 'lodash'
import { useDebouncedCallback } from 'use-debounce'

import Bee from '@beefree.io/sdk'
import { BulkModalProps } from '@components/BulkActionsModal/BulkActionsModal'
import PageContainer from '@components/PageContainer'
import { Status } from '@components/StatusToast/StatusToast'
import { checkIsContentMissing } from '@src/pages/EmailComposer/components/EmailComposerPreview/EmailComposerPreview.utils'
import { useAutoSaveOnChanges } from '@src/pages/EmailComposer/hooks/useAutoSaveOnChanges'
import { useMayLoseChanges } from '@src/pages/EmailComposer/hooks/useMayLoseChanges'
import { EmailComposerContainerProps } from '@src/pages/EmailComposer/utils/BeeEditor.types'
import { getClassicFormData } from '@src/pages/EmailComposer/utils/createOrGetMessageHelper'
import { composerURLIdHandler, getLastEmailContentEditSession, setFormConfirmationEmail } from '@src/pages/EmailComposer/utils/EmailComposer.utils'
import {
  beeEventHooks,
  checkSubjectAndPreviewText,
  closeMessage,
  createCampaign,
  createEmailContentEditSession,
  createSubscriptionCategory,
  discardEmail,
  handleEditPlainText,
  joinSession,
  onPreview,
  saveAndClose,
  tabChange,
  testMessage,
} from '@src/pages/EmailComposer/utils/EmailComposerAPI.utils'
import { getValidTab } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { convertToTemplate, saveAsCopy, saveAsDraft, saveAsTemplate, saveMessage } from '@src/pages/EmailComposer/utils/SaveMessageHelper'
import { useAccountSettings } from '@utils/account/account.utils'
import { CommonComposerTab, SaveComposerCommonType } from '@utils/composer/commonComposer/CommonComposer.context'
import { isTabBlocked, useComposerBlockedTab } from '@utils/composer/commonComposer/hooks/useComposerBlockedTab'
import { ComposerContext, ComposerContextType } from '@utils/composer/context/ComposerContext.context'
import { ComposerHistoryState, SavedEmailMessageTemplate } from '@utils/composer/context/EmailComposer.context'
import {
  defaultLandingPageComposerState,
  LandingPageComposerAPI,
  LandingPageComposerContext,
  LandingPageComposerState,
  LandingPageRedirectUrl,
  LandingPageVisibilityStatus,
} from '@utils/composer/context/LandingPageComposer.context'
import { useEmailComposerRequests } from '@utils/composer/emailComposer/GraphQL/EmailComposerRequests.graphQL'
import { AUTOSAVE_DEBOUNCE_TIME } from '@utils/composer/EmailModal.constants'
import { rootContext, useTranslation } from '@utils/const/globals'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'

import { useLandingPageComposerRequests } from './GraphQL/LandingPageComposerRequests.graphQL'
import { LandingPageComposer } from './LandingPageComposer'
import { handleGetLandingPage } from './utils/LandingPageComposer.utils'
import { saveLandingPageHelper } from './utils/saveLandingPageHelper'
import EmailComposerPreviewContainer from '../EmailComposer/components/EmailComposerPreview/EmailComposerPreviewContainer'
import ClickthroughLinksModal from '../EmailComposer/EmailModals/components/ClickthroughLinksModal/ClickthroughLinksModal'
import { removePointerEventsNone, replaceUserSelectNone } from '../EmailComposer/EmailModals/components/FormBlockModal/FormBlock.utils'
import { useFetchSettingsData } from '../EmailComposer/hooks/useFetchSettingsData'
import { getRowsConfiguration } from '../EmailComposer/utils/BeeEditor.constants'

import './LandingPageComposer.css'

const rootClass = 'landing-page-composer'

const LandingPageComposerContainer: FC<EmailComposerContainerProps> = (props) => {
  const { match, disabledFeatures = {}, isStory = false } = props

  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory<ComposerHistoryState>()
  const { enableEmailDraftsReact, userId, newLPComposerCreateBlank, accountName, accountId } = useAccountSettings()
  const {
    getLastEmailContentEditSessionRequest,
    createEmailContentEditSessionRequest,
    createMessage,
    deleteEmailDrafts,
    discardEmailChangesRequest,
    createCampaignRequest,
    createSubscriptionCategoryRequest,
    checkSubjectAndPreviewTextRequest,
  } = useEmailComposerRequests()

  const { createLandingPage } = useLandingPageComposerRequests()

  const { retrieveBeeLandingPageRequest, saveBeeLandingPageRequest } = useLandingPageComposerRequests()

  const [containerValues, setContainerValues] = useState<LandingPageComposerState>({
    ...defaultLandingPageComposerState,
    disabledFeatures: {
      ...defaultLandingPageComposerState.disabledFeatures,
      ...disabledFeatures,
      autoSave: !newLPComposerCreateBlank,
    },
    isStory,
    tab: getValidTab(match.params.tab as CommonComposerTab, false),
  })
  const isFirstRenderRef = useRef(true)
  const containerValuesRef = useRef<LandingPageComposerState>(containerValues)
  const lastSavedTemplate = useRef<SavedEmailMessageTemplate>({
    templateHtml: replaceUserSelectNone(containerValues.message.templateHtml ?? ''),
    templateJson: { ...containerValues.message.templateJson },
  })

  const beeEditorRef = useRef<Bee>()
  const lastChangeTimeStamp = useRef<number>(0)

  const { loading, isBeeLoading, message, landingPage, tab, isPreview, isClickthroughLinks } = containerValues

  const update = useDeepUpdate(setContainerValues, containerValuesRef)

  const updateModal: LandingPageComposerAPI['updateModal'] = useCallback((modal, value, closeOthers = true) => {
    setContainerValues((state) => {
      const modalState = { ...state.modalState }
      if (closeOthers) {
        Object.keys(modalState).forEach((key) => {
          modalState[key as keyof typeof modalState] = undefined
        })
      }
      return { ...state, modalState: { ...modalState, [modal]: value } }
    })
  }, [])

  useComposerBlockedTab({
    accountId,
    isModalOpened: !!containerValues.modalState.blockedModal || isTabBlocked,
    closeButtonConfig: {
      buttonText: t('Blocked.LoggedOut.Button.Text'),
    },
    modalConfig: {
      title: t('Blocked.Modal.Title'),
      bodyText: t('Blocked.LoggedOut.Text', { accountId }),
    },
    onModalShow: (modalProps) => {
      updateModal('blockedModal', modalProps)
    },
  })

  useMayLoseChanges(containerValuesRef)

  const autoSaveDebounce = useDebouncedCallback(
    async () => {
      update({ isIndicatingAutoSave: true })

      const success = await saveComposerEmail(containerValuesRef.current, true)
      if (!success) {
        update({ autoSaveFailure: true })
      }
    },
    AUTOSAVE_DEBOUNCE_TIME,
    { trailing: true }
  )

  const onAutoSave = useCallback(
    async (forceEnabled = false) => {
      if (containerValuesRef.current.isCoEditing) {
        return
      }

      if (!forceEnabled && containerValuesRef.current.disabledFeatures.autoSave) {
        return
      }
      update({ isSaving: true })
      autoSaveDebounce()
    },
    [autoSaveDebounce, update]
  )

  const onLandingPageChangeDetected = useCallback(
    (callAutoSave: boolean, isTitleChanged?: boolean) => {
      lastChangeTimeStamp.current = Date.now()
      update({ haveUnsavedChanges: true, landingPage: { isDetectedChangesOnDesignTab: tab == CommonComposerTab.DESIGN } })

      if (newLPComposerCreateBlank) {
        //prevent showing unpublished changes status for other tabs
        if ((tab == CommonComposerTab.DESIGN || !!landingPage.lastPublishedTime) && !isTitleChanged) {
          update({
            landingPage: {
              hasUnpublishedChanges: newLPComposerCreateBlank && callAutoSave && !!landingPage.lastPublishedTime,
            },
          })
        }

        callAutoSave && onAutoSave()
      }
    },
    [update, newLPComposerCreateBlank, tab, landingPage.lastPublishedTime, onAutoSave]
  )

  useAutoSaveOnChanges(onLandingPageChangeDetected, message, landingPage)

  const messageTemplateChangedAfterLastSave = useCallback(
    (message: SavedEmailMessageTemplate) =>
      lastSavedTemplate.current.templateHtml !== message.templateHtml || !_.isEqual(lastSavedTemplate.current.templateJson, message.templateJson),
    []
  )

  const changeLastSavedTemplate = useCallback((message: SavedEmailMessageTemplate) => {
    lastSavedTemplate.current.templateJson = _.cloneDeep(message.templateJson)
    lastSavedTemplate.current.templateHtml = message.templateHtml
  }, [])

  const saveComposerEmail = useCallback<SaveComposerCommonType>(
    async (containerValues: LandingPageComposerState, autoSave = false, isFromCopy, LPpublished) => {
      update({ isSaving: true })
      // not saveContent only if autoSave and content is not changed

      if (containerValues.message.templateHtml.includes('pointer-events-none')) {
        containerValues.message.templateHtml = removePointerEventsNone(containerValues.message.templateHtml)
      }
      const saveContent = !autoSave || messageTemplateChangedAfterLastSave(containerValues.message)
      const variables = saveLandingPageHelper({
        containerValues,
        autoSave,
        saveContent,
        newLPComposerCreateBlank,
        published: LPpublished,
      })

      const saveTime = Date.now()
      const { formDataId } = getClassicFormData()

      const success = !!variables.pageContent.messageId ? await saveBeeLandingPageRequest(variables) : false
      if (!success) {
        const title =
          newLPComposerCreateBlank && isFromCopy
            ? t('LandingPageComposer.Save.Status.Toast.CopyError.Title')
            : t('LandingPageComposer.Save.Status.Toast.Error.Title')

        const description =
          newLPComposerCreateBlank && isFromCopy
            ? t('LandingPageComposer.Save.Status.Toast.CopyError.Message')
            : t('LandingPageComposer.Save.Status.Toast.Error.Message')

        updateModal(
          'statusToast',
          {
            status: Status.FAIL,
            title: title,
            message: description,
          },
          !containerValuesRef.current.modalState.duplicateModal
        )

        update({ autoSaveFailure: true })
      } else {
        update({ landingPage: { customCodeChanged: false } })
        changeLastSavedTemplate(containerValues.message)
      }
      const savingEnd = lastChangeTimeStamp.current < saveTime
      update({
        isSaving: !savingEnd,
        isSaveFailed: !success,
        haveUnsavedChanges: !(success && savingEnd),
        landingPage: {
          isLandingPageStatusChanged: success ? false : landingPage.isLandingPageStatusChanged,
        },
      })

      if (formDataId) {
        setFormConfirmationEmail(formDataId, containerValues.message.id)
      }

      return success
    },
    [
      update,
      messageTemplateChangedAfterLastSave,
      newLPComposerCreateBlank,
      saveBeeLandingPageRequest,
      landingPage.isLandingPageStatusChanged,
      t,
      updateModal,
      changeLastSavedTemplate,
    ]
  )

  const bulkError = useCallback((): BulkModalProps => {
    const {
      landingPageMetaTitle,
      landingPageUrlType,
      landingPageId,
      hasLandingPageIndividualUrlError,
      landingPageIndividualUrl,
      landingPageVisibility,
      isLandingPageEnabled,
      globalRedirectUrl,
      isLandingPagePublished,
    } = containerValuesRef.current.landingPage
    const errorMessages = []
    if (!landingPageMetaTitle && landingPageVisibility === LandingPageVisibilityStatus.SHOW) {
      errorMessages.push('A page title is required')
    }
    if (
      (landingPageUrlType === LandingPageRedirectUrl.LANDING_PAGE && !landingPageId) ||
      (landingPageUrlType === LandingPageRedirectUrl.INDIVIDUAL && (hasLandingPageIndividualUrlError || !landingPageIndividualUrl))
    ) {
      errorMessages.push('A valid redirect URL is required')
    }
    if (
      !globalRedirectUrl &&
      (newLPComposerCreateBlank ? !isLandingPagePublished : !isLandingPageEnabled) &&
      landingPageUrlType === LandingPageRedirectUrl.GLOBAL
    ) {
      errorMessages.push('A valid redirect URL is required for disabled landing pages')
    }

    return {
      isOpen: true,
      dataTest: `${rootClass}-bulk-action-modal`,
      errorMessages: errorMessages,
      successMessages: [],
      title: t('LandingPage.Errors.Title'),
      errorTitle: 'Main errors',
      warnings: ['We detected some problems in your landing page. You cannot save the page until the following errors are addressed.'],
      primaryButtonText: 'Fix errors',
      onClose: () => {
        update({ landingPage: { showErrors: true } })
        updateModal('bulkErrorModal', undefined)
      },
      onAction: () => {
        api.onTabChange(CommonComposerTab.SETTINGS)
        update({ landingPage: { showErrors: true } })
        updateModal('bulkErrorModal', undefined)
      },
    }
  }, [updateModal])

  useEffect(() => {
    if (!newLPComposerCreateBlank) {
      const lastUpdated = containerValuesRef.current.lastEdited
      const createdAt = containerValuesRef.current.createdAt
      if (!isBeeLoading && lastUpdated === createdAt) {
        saveComposerEmail(containerValuesRef.current)
      }
    }
  }, [containerValuesRef.current.message.templateHtml, isBeeLoading])

  const api = useMemo(
    (): LandingPageComposerAPI => ({
      update,
      onAutoSave,
      updateModal,
      updatePreview: (fields) => update({ preview: { ...fields }, loading: false }),
      onEditPlainText: (onConfirm?: VoidFunction) => handleEditPlainText(containerValuesRef, updateModal, saveComposerEmail, onConfirm),
      updateValidations: (fields) => update({ validations: { ...fields } }),
      onJoinSession: joinSession(containerValuesRef, beeEditorRef),
      onTabChange: tabChange(containerValuesRef, history, update),
      onStatusChange: (status, name) => {
        update({
          validations: {
            validationSectionsStatus: {
              [name]: status,
            },
          },
        })
      },
      onTest: () => testMessage(containerValuesRef, update, updateModal, saveComposerEmail),
      onPreview: () => onPreview(containerValuesRef, beeEditorRef, update, containerValuesRef.current.landingPage.isLandingPage),
      onCreateCampaign: createCampaign(containerValuesRef, createCampaignRequest, update),
      onCreateSubscriptionCategory: createSubscriptionCategory(containerValuesRef, createSubscriptionCategoryRequest, update),
      onCreateEmailContentEditSession: createEmailContentEditSession(createEmailContentEditSessionRequest),
      onConvertToTemplate: convertToTemplate(containerValuesRef, createMessage, deleteEmailDrafts, saveComposerEmail, update),
      onClose: () => closeMessage(containerValuesRef, history, enableEmailDraftsReact, updateModal, discardEmailChangesRequest),
      onSave: (pureSave = true, isSilentSave = false, LPpublished, lastPublishedMessageData) =>
        saveMessage(
          undefined,
          containerValuesRef,
          updateModal,
          api.onTabChange,
          saveComposerEmail,
          update,
          history,
          enableEmailDraftsReact,
          pureSave,
          isSilentSave,
          newLPComposerCreateBlank,
          bulkError(),
          LPpublished,
          lastPublishedMessageData
        ),
      onSaveAsCopy: (title?: string) =>
        saveAsCopy(
          containerValuesRef,
          history,
          createMessage,
          saveComposerEmail,
          update,
          createLandingPage,
          updateModal,
          title,
          newLPComposerCreateBlank
        )(),
      onSaveAsDraft: saveAsDraft(containerValuesRef, createMessage, saveComposerEmail, update),
      onCheckSubjectAndPreviewText: checkSubjectAndPreviewText(containerValuesRef, message, checkSubjectAndPreviewTextRequest, update),
      onSaveAsTemplate: saveAsTemplate(containerValuesRef, createMessage, saveComposerEmail, update),
      onSaveAndClose: saveAndClose(containerValuesRef, history, enableEmailDraftsReact, saveComposerEmail, updateModal, t),
      onDiscardAndClose: () => discardEmail(containerValuesRef, history, enableEmailDraftsReact, deleteEmailDrafts, updateModal),
      onGetRowsConfigurations: () => getRowsConfiguration(newLPComposerCreateBlank),
      eventHooks: beeEventHooks(containerValuesRef, createEmailContentEditSession(createEmailContentEditSessionRequest), update, onAutoSave),
    }),
    [update, onAutoSave, updateModal, history, saveComposerEmail, message, enableEmailDraftsReact, t, newLPComposerCreateBlank, bulkError]
  )
  const fetchSettingsData = useFetchSettingsData(userId, update, api.updateModal)
  const fetchInitialData = async () => await fetchSettingsData()

  useEffect(() => {
    fetchInitialData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (isFirstRenderRef.current) {
        const queryString = isStory ? match.url.slice(match.url.indexOf('?')) : location.search
        const { messageId, messageType } = composerURLIdHandler(queryString, match, update)

        handleGetLandingPage({
          messageId,
          containerValuesRef,
          update,
          changeLastSavedTemplate,
          retrieveBeeLandingPageRequest,
          newLPComposerCreateBlank,
          accountName,
        })

        const autoSave = containerValues.disabledFeatures.autoSave

        update({ message: { messageType }, disabledFeatures: { autoSave } })

        isFirstRenderRef.current = false
      }
    }

    fetchData()
  }, [userId])

  useEffect(() => {
    const messageID = message.id

    getLastEmailContentEditSession(message, getLastEmailContentEditSessionRequest, update)

    if (messageID) {
      history.replace(`${rootContext}/content/landingPage/${messageID}/${tab}`)
    }

    const newTab = getValidTab(tab, false)
    newTab !== tab && api.onTabChange(newTab)
  }, [message.id, update])

  useEffect(() => {
    if (checkIsContentMissing(message.templateHtml)) {
      api.updateValidations({ isContentMissing: true })
    } else {
      api.updateValidations({ isContentMissing: false })
    }
  }, [api, message.templateHtml])

  useEffect(() => {
    // This is to sync the content of LP when the user is on the design tab and the save button is clicked
    if (
      containerValuesRef.current.landingPage.isDetectedChangesOnDesignTab &&
      containerValuesRef.current.landingPage.isPublishSaveCalled &&
      containerValuesRef.current.landingPage.isNewHtmlGenerated &&
      tab === CommonComposerTab.DESIGN
    ) {
      const timeout = setTimeout(() => {
        update({ isSaving: true })
        api.onSave(true, true, newLPComposerCreateBlank).then(() => {
          update({
            isSaving: false,
            haveUnsavedChanges: false,
            landingPage: { isDetectedChangesOnDesignTab: false, isPublishSaveCalled: false, isNewHtmlGenerated: false },
          })
        })
      }, AUTOSAVE_DEBOUNCE_TIME)

      return () => clearTimeout(timeout)
    }
  }, [
    landingPage.isDetectedChangesOnDesignTab,
    landingPage.isPublishSaveCalled,
    landingPage.isNewHtmlGenerated,
    tab,
    update,
    api,
    newLPComposerCreateBlank,
  ])

  return (
    <ComposerContext.Provider value={{ type: ComposerContextType.LANDING_PAGE }}>
      <LandingPageComposerContext.Provider value={{ values: { ...containerValues, beeEditorRef }, api }}>
        {isPreview && !loading && <EmailComposerPreviewContainer />}
        {isClickthroughLinks && <ClickthroughLinksModal />}
        <PageContainer noChrome className={`${rootClass}__page`}>
          <LandingPageComposer
            isStory={isStory}
            isBeeEditorLoading={isBeeLoading}
            editorClassname={classNames(`${rootClass}__composer`, {
              [`${rootClass}__hide-editor`]: isBeeLoading,
            })}
          />
        </PageContainer>
      </LandingPageComposerContext.Provider>
    </ComposerContext.Provider>
  )
}

export default LandingPageComposerContainer
