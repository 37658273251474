import { gql } from '@apollo/client'

export default gql`
  query getLaunchesForAccountPaginated($size: Int, $page: Int, $status: String) {
    getLaunchesForAccountPaginated(size: $size, page: $page, status: $status) {
      totalCount
      messages {
        launchId
        title
        description
        lastModifiedTime
        sentCount
        queuedCount
        respondedCount
        clickedCount
        bouncedCount
        optedOutCount
        userFriendlyName
        status
        scheduledSendTime
        recipientCount
        scheduleTimeZone
      }
    }
  }
`
