import React, { ComponentProps, FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import BackButton from '@components/BackButton/BackButton'
import { Button, ButtonIconPosition, ButtonType } from '@components/Button/Button'
import DropDown, { DropDownType } from '@components/DropDown/DropDown'
import DropDownActions, { MenuItem } from '@components/DropDownActions/DropDownActions'
import { LinkTextButton } from '@components/LinkTextButton/LinkTextButton'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import { ModalType, styleMap } from '@components/Modal/modalUtils'
import Search, { SearchProps } from '@components/Search/Search'
import SplitButton, { SplitButtonOptionsProps } from '@components/SplitButton/SplitButton'
import Svg, { SvgNames, SvgType } from '@components/Svg/index'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'

import './ModalHeaderV2.css'

interface ModalHeaderIconProps {
  hasBackButton?: boolean
  icon?: ReactNode // EditStepModal for reference
  onClick?: () => void
}

// Can be a single button or a Dropdown (as in PreviewAssetModalHeader)
// Only pass one of these props
export interface ModalHeaderActionButtonProps {
  actions?: MenuItem[]
  button?: ComponentProps<typeof Button>
  linkTextButton?: ComponentProps<typeof LinkTextButton>
  splitButton?: SplitButtonOptionsProps[]
}

export interface ModalHeaderV2Props {
  headerType?: ModalType
  headerText: string | ReactNode
  hideBorder?: boolean
  iconProps?: ModalHeaderIconProps
  searchProps?: SearchProps
  actionButtonProps?: ModalHeaderActionButtonProps
  closeButton?: () => void
  className?: string
  dataTest?: string
}

const rootClass = 'modal-header-v2'

const ModalHeaderV2: FC<ModalHeaderV2Props> = (props: ModalHeaderV2Props) => {
  const {
    headerText,
    actionButtonProps,
    closeButton,
    hideBorder,
    headerType = 'basic',
    iconProps,
    searchProps,
    className = '',
    dataTest = rootClass,
  } = props
  const { hasBackButton, icon, onClick } = iconProps || {}
  const { t } = useTranslation()

  const [showActionMenu, setShowActionMenu] = useState<boolean>(false)
  const handleCloseDropdown = useCallback(() => setShowActionMenu(false), [])

  const hasHeaderIcon = !!icon || hasBackButton

  const actionButton = useMemo(() => {
    if (actionButtonProps?.button) {
      return (
        <Button {...actionButtonProps.button} dataTest={`${dataTest}-action-button`} tabIndex={0}>
          {actionButtonProps.button.children}
        </Button>
      )
    }

    if (actionButtonProps?.linkTextButton) {
      return (
        <LinkTextButton {...actionButtonProps.linkTextButton} dataTest={`${dataTest}-action-link-text-button`}>
          {actionButtonProps.linkTextButton.children}
        </LinkTextButton>
      )
    }

    if (actionButtonProps?.actions) {
      return (
        <DropDown
          className={`${rootClass}__actions-dropdown`}
          isOpen={showActionMenu}
          toggleOpen={setShowActionMenu}
          type={DropDownType.STYLED}
          align="end"
          dataTest={`${dataTest}-actions-dropdown`}
          trigger={
            <Button
              iconPosition={ButtonIconPosition.RIGHT}
              buttonType={ButtonType.FLOAT}
              dataTest={`${dataTest}-actions-button`}
              className={`${rootClass}__actions-button`}
            >
              {t('Actions')}
              <Svg name={SvgNames.caretFillDown} />
            </Button>
          }
        >
          <DropDownActions menuItems={actionButtonProps.actions} closeDropDown={handleCloseDropdown} />
        </DropDown>
      )
    }

    if (actionButtonProps?.splitButton) {
      return <SplitButton options={actionButtonProps.splitButton} dataTest={`${dataTest}-actions-split-button`} />
    }

    return
  }, [actionButtonProps, dataTest, handleCloseDropdown, showActionMenu, t])

  const backButton = useMemo(
    () =>
      hasBackButton ? <BackButton className={`${rootClass}__back-button`} onClick={onClick} dataTest={`${dataTest}-back`} tabIndex={0} /> : undefined,
    [hasBackButton, onClick, dataTest]
  )

  const search = useMemo(
    () => (searchProps ? <Search canClear {...searchProps} className={`${rootClass}__search`} dataTest={`${dataTest}-search`} /> : undefined),
    [dataTest, searchProps]
  )

  useEffect(() => {
    // Search should be the first navigable element, no convenient way to achieve this with tabIndex right now
    if (hasBackButton && searchProps) {
      const element = document.querySelector<HTMLInputElement>(`input.${rootClass}__search`)
      element?.focus()
    }
  }, [hasBackButton, searchProps])

  return (
    <>
      <div
        className={classNames(`${rootClass}__inner`, className, {
          [`${rootClass}__inner-no-border`]: hideBorder || headerType === ModalHeaderType.Basic,
          [`${rootClass}__inner-default`]: headerType === ModalHeaderType.Basic,
          [`${rootClass}__inner-form`]: headerType === ModalHeaderType.Form,
          [`${rootClass}__inner-list`]: headerType === ModalHeaderType.List,
        })}
        data-test={dataTest}
      >
        <div
          className={classNames(`${rootClass}__left`, {
            [`${rootClass}__left-icon`]: hasHeaderIcon,
            [`${rootClass}__left-action`]: !!actionButtonProps && searchProps === undefined,
            [`${rootClass}__left-search`]: actionButtonProps === undefined && !!searchProps,
            [`${rootClass}__left-action-split-and-search`]: !!actionButtonProps?.splitButton && !!searchProps,
            [`${rootClass}__left-action-actions-and-search`]: !!actionButtonProps?.actions && !!searchProps,
            [`${rootClass}__left-action-button-and-search`]: !!actionButtonProps?.button && !!searchProps,
          })}
        >
          {backButton}
          {icon && <div className={`${rootClass}__left-icon`}>{icon}</div>}
          <TextWithTooltipOnEllip typographyProps={{ text: headerText, ...styleMap[headerType] }} />
        </div>
        <div className={`${rootClass}__right`}>
          {search}
          {actionButton}
          {closeButton && (
            <Tooltip
              position={'top'}
              triggerClassName={`${rootClass}__close-button`}
              trigger={
                <Button tabIndex={-1} dataTest={`${dataTest}__close-button`} buttonType={ButtonType.ICON} type="button" onClick={closeButton}>
                  <Svg name={SvgNames.close} type={SvgType.VERY_LARGE_ICON} />
                </Button>
              }
            >
              {t('Close')}
            </Tooltip>
          )}
        </div>
      </div>
    </>
  )
}

export default ModalHeaderV2
