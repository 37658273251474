import React, { ComponentProps, FC, useContext, useMemo, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { renderLoader } from '@components/Loader/Loader'
import PageContainer from '@components/PageContainer'
import PageHeader from '@components/PageHeader'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import ProgressBar from '@components/ProgressBar/ProgressBar'
import StatusToast from '@components/StatusToast/StatusToast'
import { useTranslation } from '@const/globals'
import { Program } from '@graphql/types/query-types'
import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'
import { ProgramManagerTabs } from '@src/pages/programs/manager/ProgramManager.constants'
import { UpgradeManagerContext, UpgradeStep } from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'
import { hasEarlyExitConditionsToUpdate, useUpgradeProgramSteps } from '@src/pages/programs/upgradeManager/helper/UpgradeManager.helper'
import { ProgramCurrentlyRunningModal } from '@src/pages/programs/UpgradePrograms/components/ProgramCurrentlyRunningModal/ProgramCurrentlyRunningModal'
import { ReviewUpgrade } from '@src/pages/programs/UpgradePrograms/components/ReviewUpgrade/ReviewUpgrade'
import { SelectProgram } from '@src/pages/programs/UpgradePrograms/components/SelectProgram/SelectProgram'
import { SuccessfullyUpgraded } from '@src/pages/programs/UpgradePrograms/components/SuccessfullyUpgraded/SuccessfullyUpgraded'

import './UpgradePrograms.css'

export interface ProgramFromItemDto extends Program {
  externalId: string
}

const rootClass = 'upgrade-programs'
const rootText = 'UpgradePrograms.Page.'

export const UpgradePrograms: FC = () => {
  const {
    values: { program },
    setContainerValues,
  } = useContext(ProgramManagerContext)
  const {
    values: { activeUpgradeStep, isUpgrading, contactsCount = 0, statusToast, stepsToUpgrade, upgradedSteps },
    update,
    upgradeProgram,
  } = useContext(UpgradeManagerContext)

  const [showProgramCurrentlyRunningModal, setShowProgramCurrentlyRunningModal] = useState(false)

  const { t } = useTranslation()

  const { steps } = useUpgradeProgramSteps()

  const isReviewing = activeUpgradeStep === UpgradeStep.REVIEW_UPGRADE
  const backToFixSteps = stepsToUpgrade.length > 0
  const backToEarlyExitConditions = useMemo(() => {
    return hasEarlyExitConditionsToUpdate(program)
  }, [program?.exit?.exitChoices, program?.sources])

  const onCancel = () => {
    if (backToFixSteps) {
      update({
        activeUpgradeStep: UpgradeStep.FIX_STEP_ISSUES,
        currentStep: stepsToUpgrade[0],
        upgradedCurrentStep: upgradedSteps[0],
        showUpgradeSidePanel: true,
        currentStepPosition: 1,
      })
      setContainerValues({ tab: ProgramManagerTabs.JOURNEY_BUILDER, showUpgrade: false })
    } else if (backToEarlyExitConditions) {
      setContainerValues({ tab: ProgramManagerTabs.EARLY_EXITS, showUpgrade: false })
      update({ activeUpgradeStep: UpgradeStep.EARLY_EXITS, showUpgradeSidePanel: true })
    } else {
      setContainerValues({ tab: ProgramManagerTabs.SETTINGS, showUpgrade: false })
      update({ activeUpgradeStep: UpgradeStep.CONTACT_SOURCES, showUpgradeSidePanel: true })
    }
  }

  const onSubmit = () => {
    if (program?.runStatus?.isRunning) {
      setShowProgramCurrentlyRunningModal(() => true)
    } else {
      upgradeProgram()
    }
  }

  const onModalCancel = () => setShowProgramCurrentlyRunningModal(() => false)

  const onModalSubmit = () => {
    upgradeProgram()
    setShowProgramCurrentlyRunningModal(() => false)
  }

  const onStatusToastClose = () => {
    if (statusToast) {
      update({ statusToast: { ...statusToast, showStatusToast: false } })
    }
  }

  const pageHeaderCustomLinkCallbackProp: Partial<ComponentProps<typeof PageHeader>> =
    activeUpgradeStep === UpgradeStep.REVIEW_UPGRADE
      ? { customLinkCallback: onCancel }
      : activeUpgradeStep === UpgradeStep.SELECT_PROGRAM
      ? { linkBack: true }
      : {}

  return (
    <>
      {showProgramCurrentlyRunningModal && <ProgramCurrentlyRunningModal onSubmit={onModalSubmit} onCancel={onModalCancel} />}
      {isUpgrading && renderLoader('loader--white-background')}
      {statusToast?.showStatusToast && <StatusToast message={statusToast.message} status={statusToast.status} closeStatus={onStatusToastClose} />}
      <PageContainer className={classNames(rootClass, { [`${rootClass}__on-review-page`]: isReviewing })} dataTest={rootClass}>
        <PositionContainer noOverflow>
          <PageHeader primaryText={t(`${rootText}Header`)} leftContent {...pageHeaderCustomLinkCallbackProp} />
          <ProgressBar className={`${rootClass}__progress-bar`} steps={steps} />
          {activeUpgradeStep === UpgradeStep.SELECT_PROGRAM && <SelectProgram />}
          {activeUpgradeStep === UpgradeStep.REVIEW_UPGRADE && <ReviewUpgrade />}
          {activeUpgradeStep === UpgradeStep.SUCCESSFULLY_UPGRADED && <SuccessfullyUpgraded contactsCount={contactsCount} />}
        </PositionContainer>
      </PageContainer>
      {isReviewing && (
        <div className={`${rootClass}__footer`}>
          <Button buttonType={ButtonType.SECONDARY} onClick={onCancel}>
            {t('Back')}
          </Button>
          <Button buttonType={ButtonType.PRIMARY} onClick={onSubmit}>
            {t('Submit')}
          </Button>
        </div>
      )}
    </>
  )
}
