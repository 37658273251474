import React, { FC, useCallback, useContext, useEffect } from 'react'

import getAllAvailableFields from '@graphql/microservices/crm-entity-mapping/getAllAvailableFields'
import getFieldMappingDefinitions from '@graphql/microservices/crm-entity-mapping/getFieldMappingDefinitions'
import {
  GetAllAvailableFieldsQuery,
  GetAllAvailableFieldsQueryVariables,
  GetFieldMappingDefinitionsQuery,
  GetFieldMappingDefinitionsQueryVariables,
} from '@graphql/types/microservice/crm-entity-mapping-types'
import ReportMapping from '@src/pages/datamanagement/components/ReportMapping/ReportMapping'
import { DataManagementContext, ReportMappingsData, SelectOptionsType } from '@src/pages/datamanagement/context/DataManagementContext'
import { CrmEntityType } from '@src/pages/datamanagement/utils/DataManagement.constants'
import { filterNotEmptyArray } from '@utils/array'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'
import { logNewRelicError } from '@utils/new-relic.utils'

const ReportMappingContainer: FC = () => {
  const {
    userUpdates,
    update,
    values: { reportMappingsData },
  } = useContext(DataManagementContext)

  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.CRM_ENTITY_MAPPING })

  const {
    data: mappingDefinitions,
    error: mappingDefinitionsError,
    loading: mappingDefinitionsLoading,
  } = useQueryOnMount<GetFieldMappingDefinitionsQuery, GetFieldMappingDefinitionsQueryVariables>(getFieldMappingDefinitions, {
    client,
    fetchPolicy: 'network-only',
  })

  const {
    data: allAvailableFields,
    error: allAvailableFieldsError,
    loading: allAvailableFieldsLoading,
  } = useQueryOnMount<GetAllAvailableFieldsQuery, GetAllAvailableFieldsQueryVariables>(getAllAvailableFields, {
    client,
    fetchPolicy: 'network-only',
  })

  const updateRow = useCallback(
    (rowId: number, entityType: CrmEntityType, selectedOption?: SelectOptionsType) => {
      const data: ReportMappingsData[] = reportMappingsData.map((reportMapping) => {
        return reportMapping.entityType === entityType
          ? {
              ...reportMapping,
              mappingList: reportMapping.mappingList.map((mapping) => {
                return {
                  ...mapping,
                  selectedOption:
                    mapping.id === rowId
                      ? selectedOption && selectedOption.id !== mapping.defaultOption?.id
                        ? { id: selectedOption.id, value: selectedOption.value }
                        : undefined
                      : mapping.selectedOption,
                }
              }),
            }
          : reportMapping
      })

      userUpdates('reportMappingsData', data)
    },
    [reportMappingsData]
  )

  useEffect(() => {
    if (mappingDefinitions && allAvailableFields && !allAvailableFieldsLoading && !mappingDefinitionsLoading) {
      const notEmptyMappingDefinitions = mappingDefinitions.getFieldMappingDefinitions?.filter(filterNotEmptyArray)
      const notEmptyAllAvailableFields = allAvailableFields.getAllAvailableFields?.filter(filterNotEmptyArray)

      const data = notEmptyMappingDefinitions?.map((mappingDefinition) => {
        const fields = notEmptyAllAvailableFields?.find((item) => {
          return item.crmEntityType === mappingDefinition.entityType
        })?.crmField

        const options = fields?.map((field) => {
          return {
            id: field?.fieldName,
            value: field?.displayName,
            dataType: field?.dataType,
          }
        })

        const mappingList = mappingDefinition.reportFieldMappingDefinitions?.map((reportFieldMappingDefinition, index) => {
          const definition = (mappingDefinition?.reportFieldMappingDefinitions && mappingDefinition?.reportFieldMappingDefinitions[index]) || {}
          const { crmField, defaultCrmField } = definition
          return {
            id: index,
            options: options?.filter((option) => option.dataType === definition.reportField?.dataType),
            reportField: reportFieldMappingDefinition?.reportField,
            mappingStatus: definition.mappingStatus,
            selectedOption: crmField
              ? {
                  id: crmField?.fieldName,
                  value: crmField?.displayName,
                }
              : undefined,
            defaultOption: defaultCrmField
              ? {
                  id: defaultCrmField?.fieldName,
                  value: defaultCrmField?.displayName,
                }
              : undefined,
          }
        })

        return {
          entityType: mappingDefinition.entityType,
          mappingList,
        }
      })

      update('reportMappingsData', data as ReportMappingsData[])
      update('saveReportMappingChanges', true)
    }

    if (allAvailableFieldsError || mappingDefinitionsError) {
      logNewRelicError(allAvailableFieldsError || mappingDefinitionsError)
    }
  }, [allAvailableFields, allAvailableFieldsLoading, allAvailableFieldsError, mappingDefinitions, mappingDefinitionsError, mappingDefinitionsLoading])

  return (
    <ReportMapping reportMappingsData={reportMappingsData} loading={allAvailableFieldsLoading || mappingDefinitionsLoading} updateRow={updateRow} />
  )
}

export default ReportMappingContainer
