import React, { FC, useCallback } from 'react'

import Container from '@components/Container'
import { PwdPolicyInput } from '@graphql/types/mutation-types'
import { PwdPolicyResponse } from '@graphql/types/query-types'

import AutomaticLogoutForm, { AutomaticLogoutFormProps } from './components/AutomaticLogoutForm/AutomaticLogoutForm'
import GlobalBccPoliciesEditContent, {
  GlobalBccPoliciesEditContentProps,
} from './components/GlobalBccPoliciesEditContent/GlobalBccPoliciesEditContent'
import PasswordPoliciesForm from './components/PasswordPoliciesForm/PasswordPoliciesForm'
import SecuritySection, { EditContent } from './components/SecuritySection/SecuritySection'
import { SECURITY_GLOBAL_BCC_MORE_INFO_URL } from './constants/security.options'

interface Props {
  pwdPolicies?: PwdPolicyResponse
  autoLogout?: string
  isAdmin?: boolean
  globalBcc: string
  hasGlobalBCC: boolean
  onChangePwdPolicies: (policies: PwdPolicyInput) => void
  onChangeAutoLogoutTimeout: (timeoutInSec: string) => void
  onChangeBCCPolicy: (email: string) => void
  loadingPwdPolicies: boolean
  loadingAutoLogout: boolean
  loadingGlobalBcc: boolean
  dataTest?: string
}

const rootClass = 'security'

const Security: FC<Props> = (props: Props) => {
  const {
    pwdPolicies,
    autoLogout,
    globalBcc,
    hasGlobalBCC,
    onChangeAutoLogoutTimeout,
    onChangePwdPolicies,
    onChangeBCCPolicy,
    loadingPwdPolicies,
    loadingAutoLogout,
    loadingGlobalBcc,
    isAdmin,
    dataTest = rootClass,
  } = props

  const renderPwdPoliciesForm = useCallback(
    (props: EditContent) => <PasswordPoliciesForm data={pwdPolicies as PwdPolicyResponse} {...props} />,
    [pwdPolicies]
  )
  const renderAutoLogoutForm = useCallback(
    (props: AutomaticLogoutFormProps) => <AutomaticLogoutForm timeoutBySec={autoLogout} {...props} />,
    [autoLogout]
  )
  const renderGlobalBCCForm = useCallback(
    (props: Omit<GlobalBccPoliciesEditContentProps, 'data'>): JSX.Element => <GlobalBccPoliciesEditContent data={globalBcc} {...props} />,
    [globalBcc]
  )

  return (
    <>
      <Container data-test={dataTest} className={`${rootClass}__container`}>
        {!!pwdPolicies && (
          <SecuritySection
            loading={loadingPwdPolicies}
            titleKey={'Password.Policies'}
            descriptionKey={'Security.Pwd.Policies.Description'}
            onSave={onChangePwdPolicies}
            EditingContent={renderPwdPoliciesForm}
            dataTest={`${dataTest}__pwd-policies`}
          />
        )}
        {!!autoLogout && (
          <SecuritySection
            loading={loadingAutoLogout}
            titleKey={'Automatic.Logout'}
            descriptionKey={'Security.Auto.Logout.Description'}
            onSave={onChangeAutoLogoutTimeout}
            EditingContent={renderAutoLogoutForm}
            dataTest={`${dataTest}__auto-logout`}
          />
        )}
      </Container>
      {hasGlobalBCC && isAdmin && (
        <Container>
          <SecuritySection
            EditingContent={renderGlobalBCCForm}
            displayValue={globalBcc.length < 1 ? 'Not set' : globalBcc}
            moreInfoLink={SECURITY_GLOBAL_BCC_MORE_INFO_URL}
            descriptionKey={'Security.Global.BCC.Description'}
            loading={loadingGlobalBcc}
            onSave={onChangeBCCPolicy}
            titleKey={'Global.BCC.Title'}
            dataTest={`${dataTest}__global-bcc`}
          />
        </Container>
      )}
    </>
  )
}

export default Security
