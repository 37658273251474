import React, { FC, useMemo } from 'react'

import classNames from 'classnames'

import { Checkbox } from '@components/Checkbox/Checkbox'
import SingleSelectDropdown from '@components/SingleSelectDropdown/SingleSelectDropdown'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { AlertScheduleState } from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertSchedule/AlertSchedule'
import { daysOfWeek } from '@utils/date'

import { getSendAlertOnOptions, getHoursOptions, SendAlertOn } from '../../utils/AlertSchedule.utils'

import './ImmediatelyOptions.css'

interface ImmediatelyOptionsProps {
  className?: string
  dataTest?: string
  sendOn?: SendAlertOn
  onChange: (values: Partial<AlertScheduleState>) => void
}

const rootClass = 'immediately-options'

const ImmediatelyOptions: FC<ImmediatelyOptionsProps> = (props: ImmediatelyOptionsProps) => {
  const { dataTest = rootClass, className = '', sendOn = SendAlertOn.EVERY_DAY, onChange } = props

  const { t } = useTranslation()

  const hoursOptions = useMemo(getHoursOptions, [])

  const days = useMemo(() => {
    const daysWeek = daysOfWeek(t)
    return [...daysWeek.slice(1), daysWeek[0]]
  }, [])

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Typography text={t('Allow notifications')} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.MEDIUM} />
      <div
        className={classNames({
          [`${rootClass}__options`]: sendOn !== SendAlertOn.CUSTOM,
        })}
      >
        <SingleSelectDropdown
          dataTest={`${dataTest}-frequency`}
          value={sendOn}
          defaultValue={SendAlertOn.EVERY_DAY}
          options={getSendAlertOnOptions(t)}
          onSubmit={(value) => value && onChange({ sendOn: value as SendAlertOn })}
        />
        {sendOn !== SendAlertOn.CUSTOM ? (
          <>
            <Typography text={t('from')} inline />
            <SingleSelectDropdown
              className={`${rootClass}__dropdown-time-select`}
              placeholder={t('Select a time')}
              options={hoursOptions}
              onSubmit={() => undefined}
            />
            <Typography text={t('to')} inline />
            <SingleSelectDropdown
              className={`${rootClass}__dropdown-time-select`}
              placeholder={t('Select a time')}
              options={hoursOptions}
              onSubmit={() => undefined}
            />
          </>
        ) : (
          days.map((day) => (
            <div key={`custom-option-${day}`} className={`${rootClass}__options-custom`}>
              <Checkbox label={day} />
              <SingleSelectDropdown
                className={`${rootClass}__dropdown-time-select`}
                placeholder={t('Select a time')}
                options={hoursOptions}
                onSubmit={() => undefined}
              />
              <Typography text={t('to')} inline />
              <SingleSelectDropdown
                className={`${rootClass}__dropdown-time-select`}
                placeholder={t('Select a time')}
                options={hoursOptions}
                onSubmit={() => undefined}
              />
            </div>
          ))
        )}
      </div>
      {sendOn === SendAlertOn.WEEKDAYS && (
        <Typography
          className={`${rootClass}__note`}
          text={t('Recipients won’t receive this alert on Saturday or Sunday.')}
          type={TextType.BODY_TEXT_SMALL_LIGHT}
          lineHeight={LineHeight.MEDIUM_SMALL}
        />
      )}
    </div>
  )
}

export default ImmediatelyOptions
