import React, { FC, useCallback, useContext, useMemo, useState } from 'react'

import classNames from 'classnames'

import PageContainer from '@components/PageContainer'
import PageHeader from '@components/PageHeader'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import StatusToast from '@components/StatusToast/StatusToast'
import { useTranslation } from '@const/globals'
import CreateSuggestedSegmentModal from '@src/pages/RecommendedSegments/components/CreateSuggestedSegmentModal/CreateSuggestedSegmentModal'
import CustomInsightsResults from '@src/pages/RecommendedSegments/components/CustomInsightsResults/CustomInsightsResults'
import PreviewSegmentModal from '@src/pages/RecommendedSegments/components/PreviewSegmentModal/PreviewSegmentModal'
import SegmentsInsightsSettingsContainer from '@src/pages/RecommendedSegments/components/SegmentsInsightsSettingsContainer/SegmentsInsightsSettingsContainer'
import { RecommendedSegmentsContext } from '@src/pages/RecommendedSegments/utils/RecommendedSegments.context'

import './RecommendedSegments.css'

interface RecommendedSegmentsProps {
  className?: string
  dataTest?: string
}

const rootClass = 'recommended-segments'

const RecommendedSegments: FC<RecommendedSegmentsProps> = (props: RecommendedSegmentsProps) => {
  const { dataTest = rootClass, className = '' } = props

  const {
    update,
    values: { segmentToCreate, status },
  } = useContext(RecommendedSegmentsContext)

  const [showPreviewModal, setShowPreviewModal] = useState(false)

  const { t } = useTranslation()

  const hasRedirect = useMemo<boolean>(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    return urlSearchParams.has('redirect')
  }, [])

  const changePreviewModalState = useCallback(() => {
    setShowPreviewModal(!showPreviewModal)
  }, [showPreviewModal])

  const closeStatusToast = useCallback(() => update({ status: undefined }), [])

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {!!status && <StatusToast {...status} message={status?.message} closeStatus={closeStatusToast} />}
      {segmentToCreate && <CreateSuggestedSegmentModal onPreviewClick={changePreviewModalState} isOpen={!!segmentToCreate && !showPreviewModal} />}
      {showPreviewModal && <PreviewSegmentModal onClose={changePreviewModalState} />}
      <PageContainer>
        <PositionContainer>
          <PageHeader primaryText={t('AI Audience Insights')} linkBack={hasRedirect} />
          <SegmentsInsightsSettingsContainer />
          <CustomInsightsResults className={`${rootClass}__results`} dataTest={`${dataTest}-results`} />
        </PositionContainer>
      </PageContainer>
    </div>
  )
}

export default RecommendedSegments
