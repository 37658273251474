import React, { FC, useMemo } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import ClipboardCopy, { CopyTextSize } from '@components/ClipboardCopy/ClipboardCopy'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Toggletip, { ToggletipSide } from '@components/Toggletip/Toggletip'
import Typography, { ModalHeaderFormStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { SubscriptionManagementDTO } from '@src/pages/SubscriptionManagement/SubcriptionManagement.constants'
import { useAccountSettings } from '@utils/account/account.utils'

import './UrlVersionModal.css'

interface UrlVersionModalProps {
  className?: string
  dataTest?: string
  version: SubscriptionManagementDTO
  onClose: () => void
}

const SMART_REDIRECT_URL = '/acton/rif/{{Env.AccountId}}/{{Env.MsgId}}/-/{{Env.RecId}}/{{Env.SrcId}}/zout?sid={{Env.Code}}'
const rootClass = 'url-version-modal'

const UrlVersionModal: FC<UrlVersionModalProps> = (props: UrlVersionModalProps) => {
  const { dataTest = rootClass, version, onClose } = props
  const { t } = useTranslation()
  const { accountResponseServerName, hasSubscriptionManagementMultilanguageAutoDetect } = useAccountSettings()

  const url = useMemo(
    () =>
      accountResponseServerName +
      SMART_REDIRECT_URL +
      (version.default ? '' : version.version ? `&languageVersion=${version.version}` : `&languageVersion=${version.name}`),
    [accountResponseServerName, version.default, version.name, version.version]
  )

  const smartUrl = useMemo(() => accountResponseServerName + SMART_REDIRECT_URL + `&smartRedirect`, [accountResponseServerName])

  const copyUrlText = useMemo(() => t('Copy URL'), [])

  const modalHeader = useMemo(
    () => (
      <ModalHeader headerType={ModalHeaderType.Form} dataTest={`${dataTest}-modal-header`}>
        <Typography text={t('Get.Public.URL')} {...ModalHeaderFormStyle} />
      </ModalHeader>
    ),
    []
  )
  const urlContent = (text: string) => (
    <div className={`${rootClass}__url-display`}>
      <Typography text={t(text)} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.MEDIUM} />
    </div>
  )

  return (
    <Modal className={classNames(rootClass)} dataTest={dataTest} isOpen header={modalHeader}>
      <ModalBody className={`${rootClass}__modal-body`}>
        <div className={`${rootClass}__body-items`}>
          <div className={`${rootClass}__body-items-align`}>
            <div className={`${rootClass}__block`}>
              <div className={`${rootClass}__url-tooltip`}>
                <Typography text={urlContent(t('Version URL'))} />
                <Toggletip
                  side={ToggletipSide.TOP}
                  description={t('Subscription.Management.Modal.Versions.URL')}
                  triggerOnHover
                  className={`${rootClass}__tooltip`}
                />
              </div>
              <ClipboardCopy text={copyUrlText} value={url} textWeight={TextWeight.MEDIUM} size={CopyTextSize.REGULAR}></ClipboardCopy>
            </div>
            <Typography text={url} type={TextType.BODY_TEXT} weight={TextWeight.REGULAR} className={`${rootClass}__typography-word`} />
          </div>
          {version.version && hasSubscriptionManagementMultilanguageAutoDetect && (
            <div className={`${rootClass}__body-items-align`}>
              <div className={`${rootClass}__block`}>
                <div className={`${rootClass}__url-tooltip`}>
                  <Typography text={urlContent(t('Smart Redirect URL'))} />
                  <Toggletip
                    side={ToggletipSide.TOP}
                    description={t('Subscription.Management.Modal.SmartRedirect')}
                    triggerOnHover
                    className={`${rootClass}__tooltip`}
                  />
                </div>
                <ClipboardCopy text={copyUrlText} value={smartUrl} textWeight={TextWeight.MEDIUM} size={CopyTextSize.REGULAR}></ClipboardCopy>
              </div>
              <Typography text={smartUrl} type={TextType.BODY_TEXT} weight={TextWeight.REGULAR} className={`${rootClass}__typography-word`} />
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button buttonType={ButtonType.TRANSPARENT} onClick={onClose}>
          {t('Close')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default UrlVersionModal
