import { useEffect, useState } from 'react'

import { ListingPageItem } from '@complex/ListingPage/Context/ListingPageCommon.context'
import addTag from '@graphql/microservices/categorization/addTag'
import getFolders from '@graphql/microservices/categorization/getFolders'
import getItem from '@graphql/microservices/categorization/getItem'
import getTags from '@graphql/microservices/categorization/getTags'
import searchSegments from '@graphql/microservices/categorization/searchSegments'
import {
  AddTagMutation,
  AddTagMutationVariables,
  FolderDto,
  GetFoldersQuery,
  GetFoldersQueryVariables,
  GetItemQuery,
  GetItemQueryVariables,
  GetTagsQuery,
  GetTagsQueryVariables,
  ItemDto,
  SearchSegmentsQuery,
  SearchSegmentsQueryVariables,
} from '@graphql/types/microservice/categorization-types'
import { LabelDto } from '@graphql/types/query-types'
import { filterNotEmptyArray } from '@utils/array'
import { ItemType } from '@utils/categorization'
import { logError } from '@utils/env'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'

const ALL_ACTON_CONTACTS_SEGMENT_NAME = 'All Act-On Contacts'

interface CategorizationServiceRequests {
  allActOnContactsSegment: (ListingPageItem & { recordsCount: number }) | undefined
  createTags: (variables: AddTagMutationVariables) => Promise<LabelDto[] | undefined>
  getAllActOnContactsSegment: () => Promise<(ListingPageItem & { recordsCount: number }) | undefined>
  getAllFolders: (variables: GetFoldersQueryVariables) => Promise<FolderDto[] | undefined>
  getAllTags: (variables: GetTagsQueryVariables) => Promise<LabelDto[] | undefined>
  getItemRequest: (variables: GetItemQueryVariables) => Promise<ItemDto | undefined>
  loading: boolean
}

interface CategorizationServiceProps {
  fetchAllActonContactsSegment?: boolean
}

export const useCategorizationService = (props?: CategorizationServiceProps): CategorizationServiceRequests => {
  const { fetchAllActonContactsSegment = false } = { ...props }

  const {
    client: { query, mutate },
  } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })

  const [allActOnContactsSegment, setAllActOnContactsSegment] = useState<(ListingPageItem & { recordsCount: number }) | undefined>(undefined)
  const [loading, setLoading] = useState(fetchAllActonContactsSegment)

  const createTags = async (variables: AddTagMutationVariables) => {
    const { data, errors } = await mutate<AddTagMutation, AddTagMutationVariables>({
      mutation: addTag,
      variables,
    })
    if (data) {
      return data.addLabels?.filter(filterNotEmptyArray) ?? []
    }
    if (errors) {
      logError(errors)
    }
  }

  const getItemRequest = (variables: GetItemQueryVariables) => {
    return query<GetItemQuery, GetItemQueryVariables>({
      query: getItem,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables,
    }).then(({ data }) => data.getItem)
  }

  const getAllFolders = async (variables: GetFoldersQueryVariables) => {
    const { data, error } = await query<GetFoldersQuery, GetFoldersQueryVariables>({
      query: getFolders,
      fetchPolicy: 'network-only',
      variables,
    })
    if (data) {
      return data.getFolders?.filter(filterNotEmptyArray)
    }
    if (error) {
      logError(error)
    }
  }

  const getAllTags = async (variables: GetTagsQueryVariables) => {
    const { data, error } = await query<GetTagsQuery, GetTagsQueryVariables>({
      query: getTags,
      fetchPolicy: 'network-only',
      variables,
    })
    if (data) {
      return data.getLabels?.filter(filterNotEmptyArray)
    }
    if (error) {
      logError(error)
    }
  }

  const getAllActOnContactsSegment = async () => {
    setLoading(true)
    const { data } = await query<SearchSegmentsQuery, SearchSegmentsQueryVariables>({
      fetchPolicy: 'network-only',
      query: searchSegments,
      variables: {
        allSegments: true,
        field: 'name',
        query: ALL_ACTON_CONTACTS_SEGMENT_NAME,
        type: ItemType.SEGMENT,
      },
    })
    setLoading(false)
    if (data) {
      const items = data.search?.items as ItemDto[]
      return items
        .filter((segment) => {
          const parsedItem = JSON.parse(segment.item ?? '{}')
          return 'name' in parsedItem && parsedItem.name === ALL_ACTON_CONTACTS_SEGMENT_NAME
        })
        .reduce((_: (ListingPageItem & { recordsCount: number }) | undefined, segment) => {
          const parsedItem = JSON.parse(segment.item ?? '{}')
          return { ...segment, ...parsedItem }
        }, undefined)
    }
  }

  useEffect(() => {
    const doGetAllActOnContactsSegment = async () => await getAllActOnContactsSegment()
    if (!allActOnContactsSegment && fetchAllActonContactsSegment) {
      doGetAllActOnContactsSegment().then(setAllActOnContactsSegment)
    }
  }, [])

  return {
    allActOnContactsSegment,
    createTags,
    getAllActOnContactsSegment,
    getAllFolders,
    getAllTags,
    getItemRequest,
    loading,
  }
}
