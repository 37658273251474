import React from 'react'
import { ValidateResult } from 'react-hook-form'

import { KeyValue } from '@components/KeyValuePicker/KeyValuePicker'
import { getPersonalizationCount, getSanitizedInputLength } from '@components/PersonalizationTagInput/PersonalizationTagInput.utils'
import { SelectV2Props } from '@components/SelectV2/SelectV2.props'
import { renderOptionWithEmail } from '@components/SelectV2/SelectV2.utils.render'
import { SvgNames } from '@components/Svg'
import Svg, { SvgColor, SvgProps, SvgType } from '@components/Svg/Svg'
import Typography, { LineHeight, TextType, TextWeight, TypographyProps } from '@components/Typography/Typography'
import { MAX_SELECT_SEARCH_INPUT_LENGTH } from '@const/globals'
import { EmailMessage, SettingsValidations } from '@utils/composer/context/EmailComposer.context'
import { SettingsForm } from '@utils/composer/emailComposer/types'
import { getEmojiCount } from '@utils/formUtils'

export const DYNAMIC_SENDERS_URL = 'https://connect.act-on.com/hc/en-us/articles/360023759214-Personalized-From-Addresses'
export const PLAIN_TEXT_URL = 'https://connect.act-on.com/hc/en-us/articles/26291960850199'

export type SubjectValidation = {
  recommendedLength: boolean
  recommendedEmojiCount: boolean
}

export const PREVIEW_RECOMMENDED_LENGTH = 90
export const SUBJECT_RECOMMENDED_LENGTH = 60
export const SUBJECT_RECOMMENDED_EMOJI_COUNT = 1

export type ValidationStatus = 'ERROR' | 'WARNING' | 'NEUTRAL' | 'VALID'

export const renderValidation = (message: string | undefined, status: ValidationStatus, dataTest?: string) => {
  if (!message) {
    return null
  }
  const isError = status === 'ERROR'
  const svgProps = getTextValidationSvgProps(status)
  const textType = isError ? TextType.ERROR_NEW : TextType.BODY_TEXT_SMALL_LIGHT
  return (
    <div className="flex-align-center" data-test={dataTest}>
      <Svg type={SvgType.LARGER_ICON} {...svgProps} className={isError ? 'push-left' : 'push-left-x2'} />
      <Typography text={message} type={textType} lineHeight={LineHeight.MEDIUM_SMALL} tagProps={{ bold: { weight: TextWeight.BOLD } }} inline />
    </div>
  )
}
export const getTextValidationStatus = (validationStatus: ValidateResult, text: string): ValidationStatus => {
  return !validationStatus ? (!text.length ? 'NEUTRAL' : 'VALID') : 'WARNING'
}

export const getTextValidationSvgProps = (status: ValidationStatus): SvgProps => {
  return status === 'ERROR'
    ? { name: SvgNames.inputStatusInvalidNoFill, type: SvgType.ICON, fill: SvgColor.ERROR_TEXT }
    : status === 'WARNING'
    ? {
        name: SvgNames.spamCheckWarningNoFill,
        fill: SvgColor.YELLOW_CAUTION,
      }
    : status === 'VALID'
    ? { name: SvgNames.successNoFill, fill: SvgColor.SUCCESS_GREEN }
    : { name: SvgNames.inputStatusActive }
}

export const emailSettingsSelectProps: SelectV2Props = {
  isRequired: true,
  isClearable: true,
  isSearchable: true,
  insideModal: true,
  minMenuHeight: 250,
  renderCustomOption: renderOptionWithEmail,
  renderSingleValue: renderOptionWithEmail,
  maxSearchInputLength: MAX_SELECT_SEARCH_INPUT_LENGTH,
}

export const emailSettingsTypographyProps: TypographyProps = {
  type: TextType.BODY_TEXT_SMALL_LIGHT,
  lineHeight: LineHeight.MEDIUM_SMALL,
}

export type SettingsFormChangeHandler = <T extends keyof SettingsForm>(field: T, value: SettingsForm[T]) => void

export const validateSubjectAndPreviewText = ({ subject, previewText }: Pick<EmailMessage, 'subject' | 'previewText'>) => {
  const validation: Pick<
    SettingsValidations,
    'subjectLengthExceeded' | 'subjectEmojiCountExceeded' | 'previewLengthExceeded' | 'subjectLineContainsOnlyEmojis'
  > = {
    subjectLengthExceeded: getSanitizedInputLength(subject) >= SUBJECT_RECOMMENDED_LENGTH,
    subjectEmojiCountExceeded: getEmojiCount(subject) > SUBJECT_RECOMMENDED_EMOJI_COUNT,
    subjectLineContainsOnlyEmojis:
      !!subject.length &&
      !!getEmojiCount(subject) &&
      getEmojiCount(subject) === getSanitizedInputLength(subject, true) + getPersonalizationCount(subject),
    previewLengthExceeded: getSanitizedInputLength(previewText) > PREVIEW_RECOMMENDED_LENGTH,
  }
  return validation
}

export const isAnalyticsFieldsIncomplete = (fields: KeyValue[]) => fields.some((field) => !field.key || !field.value)

export const modifyJsonToEteDetails = (json: string, id: string) => {
  const formattedJson = JSON.stringify(JSON.parse(json), null, 2)
  return `
    <div>
      Use templateId: <b>${id}</b> in the Event Triggered Email API call with this request body:
    </div>
    <pre><code>${formattedJson}</code></pre>
  `
}
