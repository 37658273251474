import { Action } from '@interface/Action'
import account from '@utils/account/reducer'
import { iframeView } from '@utils/iframe/iframe'
import navigation from '@utils/navigation/state/reducer'
import editProgram from '@utils/program/programReducer'

import pipelineReducers from '../store/pipelineReducer'

const appReducer = pipelineReducers({
  account,
  navigation,
  iframeView,
  editProgram,
})

const rootReducer = (state: any, action: Action): any => {
  return appReducer(state, action)
}

export default rootReducer
