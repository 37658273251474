import { SvgNames } from '@components/Svg'

export enum Ribbons {
  First = 'firstMedal',
  Second = 'secondMedal',
  Third = 'thirdMedal',
  Fourth = 'fourthMedal',
  Fifth = 'fifthMedal',
}

export enum RibbonColor {
  Empty = 'empty',
  First = 'first',
  Second = 'second',
  Third = 'third',
  Fourth = 'fourth',
  Fifth = 'fifth',
}

export interface RibbonMedals {
  svg: SvgNames
  title: string
  subTitle: string
  background: string
}
