export function getItem(key: string) {
  return sessionStorage.getItem(key)
}

export function setItem(key: string, value: string) {
  return sessionStorage.setItem(key, value)
}

export function removeItem(key: string) {
  return sessionStorage.removeItem(key)
}

export function clearSession() {
  return sessionStorage.clear()
}

export default {
  getItem,
  setItem,
  removeItem,
  clearSession,
}
