import React, { FC, useCallback, useState } from 'react'

import Typography from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import CompanyInfoSection from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Company/components/CompanyInfoSection/CompanyInfoSection'
import AccountTimezoneModal from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Company/components/Modals/AccountTimezoneModal/AccountTimezoneModal'

interface Props {
  creatorTimezone: string
  onChangeTimezone: (name: string) => void
  dataTest?: string
}

const AccountTimezone: FC<Props> = (props: Props) => {
  const { creatorTimezone, onChangeTimezone, dataTest = 'timezone' } = props
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { t } = useTranslation()

  const onSave = useCallback(
    (newTimezone: string) => {
      onChangeTimezone(newTimezone)
      setModalOpen(false)
    },
    [onChangeTimezone]
  )

  return (
    <>
      <CompanyInfoSection
        title={t('Account.Timezone.Title')}
        required
        description={<Typography text={t('Account.Timezone.Name.Description')} inline />}
        value={creatorTimezone}
        onEdit={() => setModalOpen(true)}
        dataTest={`${dataTest}__section`}
      />
      <AccountTimezoneModal timezone={creatorTimezone} isOpen={modalOpen} onSave={onSave} onCancel={() => setModalOpen(false)} />
    </>
  )
}

export default AccountTimezone
