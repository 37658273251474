import React, { Component, RefObject } from 'react'

import { TOP_BAR_HEIGHT } from '@const/globals'
import windowUtils from '@utils/window'

import './iframe.css'

export const getHeight = (navigationVisible: boolean): number => windowUtils.getWindowHeight() - (navigationVisible ? TOP_BAR_HEIGHT : 0)

export interface IframeProps {
  id: string
  src: string | null
  navigationVisible: boolean
  forwardedRef?: RefObject<HTMLIFrameElement>
}

interface State {
  src: string | null
}

export class IFrame extends Component<IframeProps, State> {
  state = {
    src: null,
  }
  componentDidMount(): void {
    windowUtils.addEventListener('resize', this.resizeListener)
    this.setState({
      src: this.props.src,
    })
  }

  componentWillUnmount(): void {
    windowUtils.removeEventListener('resize', this.resizeListener)
  }

  componentDidUpdate(): void {
    const { src } = this.props
    if (src !== this.state.src) {
      this.setState({
        src,
      })
    }
  }

  resizeListener = () => {
    const { forwardedRef } = this.props
    if (forwardedRef?.current) {
      forwardedRef.current.style.height = `${getHeight(this.props.navigationVisible)}px`
    }
  }

  render() {
    const { id, navigationVisible, forwardedRef, src } = this.props
    return (
      <iframe
        title={'iframe'}
        id={id}
        className="iframe"
        frameBorder="0"
        ref={forwardedRef}
        src={this.state.src || src || ''}
        style={{
          marginTop: navigationVisible ? `${TOP_BAR_HEIGHT}px` : '0',
          height: `${getHeight(navigationVisible)}px`,
        }}
      />
    )
  }
}

export default IFrame
