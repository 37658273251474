import React, { ChangeEvent, FC, ReactElement, useEffect, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Input from '@components/Input/Input'
import Loader from '@components/Loader'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import { RadioProps } from '@components/Radio/Radio'
import RadioGroup from '@components/RadioGroup'
import RadioWithOptions from '@components/RadioWithOptions/RadioWithOptions'
import SingleSelectDropdown from '@components/SingleSelectDropdown/SingleSelectDropdown'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { ModalBodyStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { LabelValueField } from '@graphql/types/query-types'
import { SelectOption } from '@interface/Select'
import { useUsePersonalizationFieldsModalRequests } from '@src/pages/listingPages/ListMaintenancePrograms/components/UsePersonalizationFieldsModal/utils/UsePersonalizationFieldsModalRequests.graphQL'

import './UsePersonalizationFieldsModal.css'

interface UsePersonalizationFieldsModalProps {
  className?: string
  dataTest?: string
  column: string
  isOpen: boolean
  listFields: string[]
  onInsert: (personalizationField: string) => void
  onClose: () => void
}

interface UsePersonalizationFieldsModalState {
  accountFields: LabelValueField[]
  crmFields: LabelValueField[]
  fallback?: string
  loading: boolean
  selectedField?: string
  selectedOption: PersonalizationFieldsOption
  standardFields: string[]
}

enum PersonalizationFieldsOption {
  LIST_FIELDS = 'LIST_FIELDS',
  ACCOUNT_FIELDS = 'ACCOUNT_FIELDS',
  CRM_FIELDS = 'CRM_FIELDS',
  STANDARD_FIELDS = 'STANDARD_FIELDS',
}

const usePersonalizationFieldsModalDefaultState: UsePersonalizationFieldsModalState = {
  accountFields: [],
  crmFields: [],
  loading: true,
  selectedOption: PersonalizationFieldsOption.LIST_FIELDS,
  standardFields: [],
}

const rootClass = 'use-personalization-fields-modal'
const CREATED_DATE_FIELD_KEY = 'createdDate'
const UPDATED_DATE_FIELD_KEY = 'updatedDate'

const UsePersonalizationFieldsModal: FC<UsePersonalizationFieldsModalProps> = (props: UsePersonalizationFieldsModalProps) => {
  const { dataTest = rootClass, className = '', onInsert, onClose, column, listFields = [], isOpen } = props

  const [state, setState] = useState<UsePersonalizationFieldsModalState>(usePersonalizationFieldsModalDefaultState)
  const { selectedOption, selectedField, fallback, standardFields, accountFields, crmFields, loading } = state

  const { getStandardFieldsRequest, getPersonalizationChoicesRequest } = useUsePersonalizationFieldsModalRequests()

  const { t } = useTranslation()

  const optionsData: { [key in PersonalizationFieldsOption]: { fields: SelectOption[]; label: string } } = {
    [PersonalizationFieldsOption.LIST_FIELDS]: { fields: listFields.map((value) => ({ value })), label: 'Selected list fields' },
    [PersonalizationFieldsOption.STANDARD_FIELDS]: { fields: standardFields.map((value) => ({ value })), label: 'Standard contact fields' },
    [PersonalizationFieldsOption.CRM_FIELDS]: { fields: crmFields, label: 'Connected CRM fields' },
    [PersonalizationFieldsOption.ACCOUNT_FIELDS]: { fields: accountFields, label: 'Account fields' },
  }

  const onSubmit = () => {
    const personalization = `{{${selectedField}}}`
    onInsert(fallback ? `{{=${personalization}|${fallback}}}` : personalization)
    onClose()
  }

  const radioChangeHandler = ({ target: { id } }: ChangeEvent<HTMLInputElement>) => {
    const selectedOption = id as PersonalizationFieldsOption
    const { fields = [] } = optionsData[selectedOption]
    setState((state) => ({ ...state, selectedOption, selectedField: fields.length ? fields[0].value : '' }))
  }

  useEffect(() => {
    Promise.all([getPersonalizationChoicesRequest(), getStandardFieldsRequest()]).then(
      ([getPersonalizationChoicesResponse, getStandardFieldsResponse]) => {
        const { accountFields, crmFields } = getPersonalizationChoicesResponse
        setState((state) => ({
          ...state,
          accountFields,
          crmFields,
          standardFields: getStandardFieldsResponse.reduce((standardFields: string[], { label = '', key = '' }) => {
            return key !== CREATED_DATE_FIELD_KEY && key !== UPDATED_DATE_FIELD_KEY ? [...standardFields, label] : standardFields
          }, []),
          loading: false,
        }))
      }
    )
  }, [])

  useEffect(() => {
    setState((state) => ({ ...state, selectedField: undefined }))
  }, [selectedOption])

  const renderRadioOption = (option: PersonalizationFieldsOption) => {
    const { fields = [], label } = optionsData[option]
    if (fields.length) {
      return (
        <RadioWithOptions
          key={option}
          className={`${rootClass}__radio-option`}
          id={option}
          checked={selectedOption === option}
          onChange={radioChangeHandler}
          label={t(label)}
        >
          <div>
            <Typography
              className={`${rootClass}__dropdown-label`}
              text={t('Personalization field to map')}
              type={TextType.BODY_TEXT_SMALL}
              weight={TextWeight.MEDIUM}
            />
            <SingleSelectDropdown
              dataTest={`${dataTest}-field-dropdown`}
              className={classNames(`${rootClass}__dropdown`, { [`${rootClass}__dropdown-unselected`]: !selectedField })}
              placeholder={t('Select field')}
              onSubmit={(value) => setState((state) => ({ ...state, selectedField: value }))}
              options={[...fields]
                .sort(({ label: labelA = '' }, { label: labelB = '' }) => (labelA > labelB ? 1 : -1))
                .map(({ value, label }) => ({ value, label }))}
            />
          </div>
          <Input
            dataTest={`${dataTest}-fallback-input`}
            className={`${rootClass}__input`}
            placeholder={t('Optional fallback text')}
            value={fallback}
            onChange={({ target: { value } }) => setState((state) => ({ ...state, fallback: value }))}
            label={
              <div className={`${rootClass}__input-message`}>
                <Typography text={t('Fallback text')} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.MEDIUM} />
                <Tooltip className={`${rootClass}__input-tooltip`} trigger={<Svg name={SvgNames.info} type={SvgType.ICON} />} alignTextCenter>
                  {t('Literal text to display for contacts that don’t have a value for the selected field')}
                </Tooltip>
              </div>
            }
          />
        </RadioWithOptions>
      )
    }
  }

  const header = (
    <ModalHeader headerType={ModalHeaderType.List} className={`${rootClass}__header`}>
      {t('Use Personalization Fields')}
    </ModalHeader>
  )

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
      <ModalBody className={`${rootClass}__body`}>
        <Typography
          text={t(`Select a personalization field to map to the {{column}} field selected in the previous step.`)}
          values={{ column }}
          tagProps={{ bold: { weight: TextWeight.BOLD } }}
          inline
          {...ModalBodyStyle}
        />
        {loading ? (
          <Loader className={`${rootClass}__loader`} />
        ) : (
          <RadioGroup className={`${rootClass}__radio-container`} verticalLayout>
            {Object.values(PersonalizationFieldsOption).map(renderRadioOption) as ReactElement<RadioProps>[]}
          </RadioGroup>
        )}
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.List} className={`${rootClass}__footer`}>
        <Button buttonType={ButtonType.TERTIARY} onClick={onClose} dataTest={`${dataTest}-button-cancel`}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} onClick={onSubmit} dataTest={`${dataTest}-button-insert`} disabled={!selectedField}>
          {t('Insert')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default UsePersonalizationFieldsModal
