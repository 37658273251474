import React, { FC, Key, useContext, useEffect, useMemo, useRef } from 'react'
import { useController, useFieldArray, useFormContext } from 'react-hook-form'

import classNames from 'classnames'

import Caution from '@components/Caution/Caution'
import CheckboxCard from '@components/CheckboxCard/CheckboxCard'
import { CheckboxCardGroup } from '@components/CheckboxCardGroup/CheckboxCardGroup'
import InfoTooltip from '@components/InfoTooltip/InfoTooltip'
import InputV2 from '@components/InputV2/InputV2'
import Radio from '@components/Radio'
import RadioGroup from '@components/RadioGroup'
import RadioWithOptions from '@components/RadioWithOptions/RadioWithOptions'
import { SvgNames } from '@components/Svg'
import Toggle from '@components/Toggle'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { FieldDefinition, StringKeyValue } from '@graphql/types/query-types'
import CRMFieldsSelector from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/components/CRMFieldsSelector/CRMFieldsSelector'
import {
  DetailsDataType,
  iconByRecordType,
  IMMEDIATELY_KEY,
} from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.constants'
import { EditCRMStepV2Context } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.context'
import {
  FieldDefinitionWithValue,
  SelectableCardData,
} from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.interfaces'
import { hasValidFields } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.utils'
import useCRM from '@utils/hooks/useCRM'
import { ProgramCreateInCRMStepExt } from '@utils/program/program.constants'
import { editElement } from '@utils/utils'

import './StepDetails.css'

interface StepDetailsProps {
  className?: string
  dataTest?: string
}

type UpdatePolicyOption = 'DO_NOT_UPDATE' | 'CREATE_EVERYTIME' | 'UPDATE_EXISTING'

type PushPolicyOption = 'USE_LIST_PUSH_POLICY' | 'UPDATE_ALL' | 'UPDATE_BLANK'

const rootClass = 'step-details'

export const StepDetails: FC<StepDetailsProps> = (props: StepDetailsProps) => {
  const { dataTest = rootClass, className = '' } = props

  const {
    update: contextUpdate,
    values: { recordTypes, steps, fieldDefinitionsByEntity, showErrorBanner },
  } = useContext(EditCRMStepV2Context)

  const { t } = useTranslation()

  const { connectorType } = useCRM()

  const recordsTypesCards = useMemo<SelectableCardData[]>(() => {
    return recordTypes.map(({ key = '', value }) => ({
      title: key,
      value,
      description: t('EditCrmStepV2.StepDetails.RecordType.Description', { recordType: key.toLowerCase() }),
      iconLeft: iconByRecordType[key] ?? SvgNames.crmCloudLineNoFill,
    }))
  }, [recordTypes, t])

  const { control, watch } = useFormContext<ProgramCreateInCRMStepExt>()

  const { append, prepend, remove, update, fields } = useFieldArray({ control, name: 'staticFields' })

  const { field: pushType } = useController({ control, name: 'pushType' })
  const { field: insertAlways } = useController({ control, name: 'insertAlways' })
  const { field: updateExisting } = useController({ control, name: 'updateExisting' })
  const { field: useListPushPolicy } = useController({ control, name: 'useListPushPolicy' })
  const { field: pushNewRecords } = useController({ control, name: 'pushNewRecords' })
  const { field: updateOnlyBlankFields } = useController({ control, name: 'updateOnlyBlankFields' })
  const { field: displayNameController } = useController({ control, name: 'displayName' })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    displayNameController.onChange(e.target.value)
  }

  const canUpdateExistingContacts = watch('canUpdateExistingContacts')

  const allAvailableStaticFields = useMemo(
    () => (pushType.value ? fieldDefinitionsByEntity.get(pushType.value) ?? [] : []),
    [fieldDefinitionsByEntity, pushType.value]
  )
  const requiredStaticFields = useMemo(() => allAvailableStaticFields.filter(({ required }) => required), [allAvailableStaticFields])

  const lastPushTypeUpdatedWithRequiredFields = useRef<string>()

  const onPushPolicyChange = (pushPolicy: PushPolicyOption) => {
    switch (pushPolicy) {
      case 'USE_LIST_PUSH_POLICY':
        updateOnlyBlankFields.onChange(false)
        useListPushPolicy.onChange(true)
        break
      case 'UPDATE_ALL':
        updateOnlyBlankFields.onChange(false)
        useListPushPolicy.onChange(false)
        break
      case 'UPDATE_BLANK':
        updateOnlyBlankFields.onChange(true)
        useListPushPolicy.onChange(false)
        break
    }
  }

  const onUpdatePolicyChange = (policy: UpdatePolicyOption) => {
    const clearUpdateExistingFlags = () => {
      useListPushPolicy.onChange(false)
      updateOnlyBlankFields.onChange(false)
    }

    switch (policy) {
      case 'DO_NOT_UPDATE':
        insertAlways.onChange(false)
        updateExisting.onChange(false)
        clearUpdateExistingFlags()
        break
      case 'CREATE_EVERYTIME':
        insertAlways.onChange(true)
        updateExisting.onChange(false)
        clearUpdateExistingFlags()
        break
      case 'UPDATE_EXISTING':
        insertAlways.onChange(false)
        updateExisting.onChange(true)
        break
    }
  }

  const onRemoveField = (key: string) => {
    const fields = watch('staticFields', [])
    if (fields) {
      const fieldIndex = fields.findIndex((field) => field.key === key)
      if (fieldIndex >= 0) {
        remove(fieldIndex)
      }
    }
  }

  const onAddField = ({ fieldName, dataType, required }: FieldDefinition) => {
    const newField: StringKeyValue = {
      key: fieldName,
      value: dataType === DetailsDataType.BOOLEAN ? 'false' : dataType === DetailsDataType.DATE && required ? 'day_0' : '',
    }
    append(newField)
  }

  const onFieldChange = ({ field, value }: FieldDefinitionWithValue) => {
    const fields = watch('staticFields', [])
    if (fields) {
      const fieldIndex = fields.findIndex(({ key = '' }) => key === field.fieldName)
      if (fieldIndex >= 0) {
        update(fieldIndex, { key: field.fieldName, value })
      }
    }
  }

  const onPushTypeChange = (selectedKey: Key) => {
    if (selectedKey !== pushType.value) {
      remove()
      pushType.onChange(selectedKey as string)
    }
  }

  useEffect(() => {
    const hasValidPush = !!pushType.value && hasValidFields(requiredStaticFields, fields)
    const completed = !!displayNameController.value && ((!!pushNewRecords.value && hasValidPush) || !pushNewRecords.value)
    if (steps.length >= 1 && steps[0].completed !== completed) {
      const updatedSteps = editElement(0, { completed, error: !completed }, steps)
      contextUpdate({ steps: updatedSteps })
    }
  }, [displayNameController.value, fields, pushNewRecords.value, pushType.value, requiredStaticFields])

  /*
   * If it's pushing new records and the push type changes, then add the required fields to the static fields list
   */
  useEffect(() => {
    if (pushType.value !== lastPushTypeUpdatedWithRequiredFields.current && requiredStaticFields.length > 0) {
      if (pushNewRecords.value && pushType.value) {
        const missingRequiredFields = requiredStaticFields.filter(({ fieldName }) => !fields.some(({ key }) => key === fieldName))
        const requiredFieldsKeyValues: StringKeyValue[] = missingRequiredFields.map((field) => ({
          key: field.fieldName,
          value:
            field.dataType === DetailsDataType.BOOLEAN ? 'false' : field.dataType === DetailsDataType.DATE && field.required ? IMMEDIATELY_KEY : '',
        }))
        prepend(requiredFieldsKeyValues)
        lastPushTypeUpdatedWithRequiredFields.current = pushType.value
      } else {
        remove()
        lastPushTypeUpdatedWithRequiredFields.current = undefined
      }
    }
  }, [requiredStaticFields, pushType.value, pushNewRecords.value, lastPushTypeUpdatedWithRequiredFields.current])

  const onToggleSwitch = (isOn: boolean) => {
    pushNewRecords.onChange(isOn)
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__name`}>
        <Typography
          text={t('EditCrmStepV2.StepDetails.StepName')}
          tagProps={{ light: { weight: TextWeight.MEDIUM_LIGHT, type: TextType.BODY_TEXT_SMALL_LIGHT } }}
          weight={TextWeight.MEDIUM}
          type={TextType.BODY_TEXT_SMALL}
          inlineBlock
        />
        <InputV2
          dataTest={`${dataTest}-input-name`}
          placeholder={t('EditCrmStepV2.StepDetails.StepName.Placeholder', { crmName: connectorType })}
          className={`${rootClass}__name-input`}
          name={'displayName'}
          value={displayNameController.value}
          onChange={handleChange}
          handleValueChangeFromOuter
        />
      </div>
      <div className={`${rootClass}__options`}>
        {showErrorBanner && !!pushNewRecords.value && (
          <Caution isError={!!pushNewRecords.value} message={<Typography text={t('EditCrmStepV2.StepDetails.Error')} weight={TextWeight.BOLD} />} />
        )}
        <div className={classNames(`${rootClass}__options-label`, 'flex-align-center')}>
          <div className={'flex-align-center'}>
            <Typography
              text={t(
                canUpdateExistingContacts ? 'EditCrmStepV2.StepDetails.CreateNewRecords' : 'EditCrmStepV2.StepDetails.CreateNewRecords.NonExisting'
              )}
              weight={TextWeight.MEDIUM}
            />
            <InfoTooltip
              text={t(
                canUpdateExistingContacts
                  ? 'EditCrmStepV2.StepDetails.CreateNewRecords.Tooltip'
                  : 'EditCrmStepV2.StepDetails.CreateNewRecords.NonExisting.Tooltip'
              )}
            />
          </div>
          {!canUpdateExistingContacts && <Toggle isOn={!!pushNewRecords.value} onToggle={onToggleSwitch} />}
        </div>
        {pushNewRecords.value && (
          <CheckboxCardGroup
            className={recordsTypesCards.length > 3 ? `${rootClass}__cards-group-extended` : `${rootClass}__cards-group`}
            onSelect={onPushTypeChange}
            selectedOption={pushType.value}
          >
            {recordsTypesCards.map((card) => (
              <CheckboxCard {...card} hideIconRight key={card.value} />
            ))}
          </CheckboxCardGroup>
        )}
        {pushType.value && pushNewRecords.value && canUpdateExistingContacts && (
          <div>
            <RadioGroup className={`${rootClass}__options-radios`} verticalLayout>
              <Radio
                checked={!insertAlways.value && !updateExisting.value}
                dataTest={`${dataTest}__DO_NOT_UPDATE`}
                label={t('EditCrmStepV2.StepDetails.RadioOption.DoNotUpdate')}
                onChange={() => onUpdatePolicyChange('DO_NOT_UPDATE')}
              />
              <Radio
                checked={insertAlways.value && !updateExisting.value}
                dataTest={`${dataTest}__CREATE_EVERYTIME`}
                label={t('EditCrmStepV2.StepDetails.RadioOption.CreateEverytime')}
                onChange={() => onUpdatePolicyChange('CREATE_EVERYTIME')}
              />
              <RadioWithOptions
                checked={updateExisting.value}
                dataTest={`${dataTest}__UPDATE_EXISTING`}
                label={t('EditCrmStepV2.StepDetails.RadioOption.UpdateExisting')}
                onChange={() => onUpdatePolicyChange('UPDATE_EXISTING')}
                className={`${rootClass}__update`}
              >
                <div className={`${rootClass}__options-update`}>
                  <Typography text={t('EditCrmStepV2.StepDetails.RadioOption.ExistingRecords')} weight={TextWeight.MEDIUM} />
                  <RadioGroup verticalLayout>
                    <Radio
                      label={t('EditCrmStepV2.StepDetails.RadioOption.ExistingRecords.UseListPushPolicy')}
                      checked={useListPushPolicy.value}
                      onChange={() => onPushPolicyChange('USE_LIST_PUSH_POLICY')}
                    />
                    <Radio
                      label={t('EditCrmStepV2.StepDetails.RadioOption.ExistingRecords.UpdateAll')}
                      checked={!useListPushPolicy.value && !updateOnlyBlankFields.value}
                      onChange={() => onPushPolicyChange('UPDATE_ALL')}
                    />
                    <Radio
                      label={t('EditCrmStepV2.StepDetails.RadioOption.ExistingRecords.UpdateBlank')}
                      checked={!useListPushPolicy.value && updateOnlyBlankFields.value}
                      onChange={() => onPushPolicyChange('UPDATE_BLANK')}
                    />
                  </RadioGroup>
                </div>
              </RadioWithOptions>
            </RadioGroup>
          </div>
        )}
        {!!pushNewRecords.value && pushType.value && (
          <>
            <div className={`${rootClass}__divider`} />
            <CRMFieldsSelector
              availableFields={allAvailableStaticFields}
              header={{
                title: t('Fields to set on new records'),
              }}
              selectedFields={fields}
              onRemoveField={onRemoveField}
              onAddField={onAddField}
              onChange={onFieldChange}
              hasHelpers
            />
          </>
        )}
      </div>
    </div>
  )
}
