import { gql } from '@apollo/client'

export default gql`
  query multipleScoresheets {
    multipleScoresheets {
      id
      name
    }
  }
`
