import React, { FC, useContext, useEffect } from 'react'

import lastSync from '@graphql/microservices/crm/lastSync'
import { LastSyncQuery, LastSyncQueryVariables } from '@graphql/types/microservice/crm-types'
import { DataManagementContext } from '@src/pages/datamanagement/context/DataManagementContext'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'

type Props = {
  className?: string
  dataTest?: string
}

const rootClass = 'last-sync-end-time'

const LastSyncEndTime: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '' } = props
  const { update } = useContext(DataManagementContext)

  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.CRM })

  const { loading, data } = useQueryOnMount<LastSyncQuery, LastSyncQueryVariables>(lastSync, {
    client,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!loading && data && data.lastSync?.ended) {
      update('lastSync', data?.lastSync?.ended)
    }
  }, [loading, data])
  return <div className={className} data-test={dataTest} />
}

export default LastSyncEndTime
