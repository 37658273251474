import React, { FC, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { useApolloClient, useMutation, useQuery, useLazyQuery } from '@apollo/client'
import { useTranslation } from '@const/globals'
import deleteContactFromProgram from '@graphql/mutations/deleteContactFromProgram'
import programQuery from '@graphql/queries/automatedPrograms'
import getProgramContacts from '@graphql/queries/getProgramContacts'
import { DeleteContactFromProgramMutation, DeleteContactFromProgramMutationVariables } from '@graphql/types/mutation-types'
import { GetProgramContactsQuery, GetProgramContactsQueryVariables, ProgramQuery, ProgramQueryVariables } from '@graphql/types/query-types'
import { getStepId } from '@src/pages/programs/dashboard/ProgramSteps.utils'
import { SortByState } from '@utils/sms.utils'
import { RouteProps, MatchParams } from '@utils/types'

import ProgramContacts from './ProgramContacts'

export interface State {
  data: ProgramContactList[]
  totalCount: number
  pageSize: number
  pageIndex: number
  pageCount: number
  query: string
  sortBy: SortByState[]
  stepId: string
  loading: boolean
}

export interface ProgramContactList {
  row?: number
  email?: string
  name?: string
  listId?: string
  listName?: string
  count?: number
  since?: number
  recId?: string
  deleted?: boolean
  when?: string
  reason?: string
  reasonDetails?: string
}

const defaultSortBy: SortByState = {
  id: 'when',
  desc: true,
}

const defaultState: State = {
  data: [],
  totalCount: 0,
  pageIndex: 0,
  pageSize: 10,
  pageCount: 1,
  sortBy: [defaultSortBy],
  query: '',
  stepId: '',
  loading: true,
}

type ProgramContactsContainerProps = RouteComponentProps<MatchParams> & RouteProps

const ProgramContactsContainer: FC<ProgramContactsContainerProps> = ({ match }: ProgramContactsContainerProps) => {
  const client = useApolloClient()
  const { t } = useTranslation()
  const contactsUrl = (id: string) => `/acton/internalapi/program/contact/download/${id}?&step=${state.stepId}&state=${match.params.state}`

  const [state, setState] = useState<State>(defaultState)

  const { data: program } = useQuery<ProgramQuery, ProgramQueryVariables>(programQuery, {
    query: programQuery,
    fetchPolicy: 'network-only',
    variables: {
      id: match.params.id,
    },
  })

  const getSortColumn = (sortId: string) => {
    return sortId === 'when' ? 'DATEADDED' : sortId === 'listName' ? 'SOURCELIST' : sortId.toUpperCase()
  }

  const [deleteContact, { loading: deleteContactLoading }] = useMutation<DeleteContactFromProgramMutation, DeleteContactFromProgramMutationVariables>(
    deleteContactFromProgram,
    {
      client: client,
      fetchPolicy: 'no-cache',
    }
  )

  const [getContacts, { error: pageError, data, refetch }] = useLazyQuery<GetProgramContactsQuery, GetProgramContactsQueryVariables>(
    getProgramContacts,
    {
      client: client as any,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      variables: {
        programId: match.params.id,
        stepId: state.stepId,
        state: match.params.state,
        perPage: state.pageSize,
        currentPage: state.pageIndex,
        query: state.query,
        sortColumn: getSortColumn(state.sortBy[0].id),
        sortDirection: state.sortBy[0].desc ? 'DESC' : 'ASC',
      },
    }
  )

  useEffect(() => {
    if (state.stepId) {
      getContacts()
    }
  }, [state.stepId])

  useEffect(() => {
    if (data?.getProgramContacts && data.getProgramContacts.programContacts) {
      setState({
        ...state,
        data: data.getProgramContacts.programContacts,
        totalCount: data.getProgramContacts.totalRecords,
        pageCount: data.getProgramContacts.totalPages,
        loading: false,
      })
    }
  }, [data])

  useEffect(() => {
    if (program && match.params.step && !state.stepId) {
      const stepId = getStepId(program.program, match.params.step)
      setState({ ...state, stepId: stepId })
    }
  }, [program])

  const fetchData = (pageIndex: number, pageSize: number) => {
    setState({ ...state, pageIndex, pageSize })
  }

  const onChangeSort = (sortBy: Array<any>) => {
    const validSortBy = sortBy.length > 0 ? sortBy : [defaultSortBy]
    setState({ ...state, sortBy: validSortBy, pageIndex: 0 })
  }

  const onSearch = (query: string) => {
    setState({ ...state, query, pageIndex: 0 })
  }

  const getTitle = (state: string) => {
    switch (state) {
      case 'WAITING':
        return t('Contacts Waiting')
      case 'COMPLETED':
        return t('Contacts Completed')
      case 'EARLY_EXIT':
        return t('Early Exit Contacts')
      case 'EXITED':
        return t('Standard Exit Contacts')
      default:
        return ''
    }
  }

  const title = `${getTitle(match.params.state)}, ${t('Step')} ${match.params.step}`
  const showReentryWarning = match.params.state !== 'WAITING' && (program?.program.allowReentrantAddresses ?? false)

  return (
    <ProgramContacts
      title={title}
      showReentryWarning={showReentryWarning}
      showRemoveContactAction={match.params.state === 'WAITING'}
      data={state.data}
      sortBy={state.sortBy}
      pageSize={state.pageSize}
      pageIndex={state.pageIndex}
      pageCount={state.pageCount}
      totalCount={state.totalCount}
      loading={state.loading}
      deleteContactLoading={deleteContactLoading}
      pageError={pageError}
      fetchData={fetchData}
      onChangeSort={onChangeSort}
      onSearch={onSearch}
      deleteContact={deleteContact}
      programId={match.params.id}
      searchIncomingValue={state.query}
      isEmptySearch={state.data.length === 0}
      downloadUrl={contactsUrl(match.params.id)}
      tableTitle={'Contact Entries'}
      refetch={refetch}
    />
  )
}

export default ProgramContactsContainer
