import React, { useEffect } from 'react'

import getWelcomeMessage from '@graphql/microservices/sms-management/getWelcomeMessage'
import { Query, QueryGetWelcomeMessageArgs } from '@graphql/types/microservice/sms-management-types'
import WelcomeMessage from '@src/pages/sms/marketingListOptIns/components/WelcomeMessage/WelcomeMessage'
import { MessageStatus } from '@src/pages/sms/marketingListOptIns/MarketingListOptIns.constants'
import { useAccountSettings } from '@utils/account/account.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'
import { logNewRelicError } from '@utils/new-relic.utils'

const WelcomeMessageContainer = () => {
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.SMS_MANAGEMENT })

  const {
    accountId,
    userSettings: { isAdministrator },
  } = useAccountSettings()

  const { data, error, loading, refetch } = useQueryOnMount<Query, QueryGetWelcomeMessageArgs>(getWelcomeMessage, {
    client,
    fetchPolicy: 'network-only',
    variables: {
      accountId,
    },
  })

  useEffect(() => {
    if (error) {
      logNewRelicError(error)
    }
  }, [error])

  const onSave = () => refetch()

  return (
    <WelcomeMessage
      message={data?.getWelcomeMessage?.smsMessage?.messageText ?? ''}
      launchId={data?.getWelcomeMessage?.smsMessage?.smsMessageId ?? ''}
      status={(data?.getWelcomeMessage?.smsMessage?.status as MessageStatus) ?? MessageStatus.WELCOME_DEFAULT}
      isAdmin={isAdministrator}
      onSave={onSave}
      loading={loading}
      error={error}
    />
  )
}

export default WelcomeMessageContainer
