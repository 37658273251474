export enum SegmentMembership {
  MEMBER = 'is_member_of',
  NOT_MEMBER = 'is_not_member_of',
  PREVIOUS_MEMBER = 'was_member_of',
  NOT_PREVIOUS_MEMBER = 'was_not_member_of',
}

export const segmentMembershipOptionLabels = {
  [SegmentMembership.MEMBER]: 'SegmentComposer.Build.SegmentMembership.Options.Member',
  [SegmentMembership.NOT_MEMBER]: 'SegmentComposer.Build.SegmentMembership.Options.NotMember',
  [SegmentMembership.PREVIOUS_MEMBER]: 'SegmentComposer.Build.SegmentMembership.Options.PreviousMember',
  [SegmentMembership.NOT_PREVIOUS_MEMBER]: 'SegmentComposer.Build.SegmentMembership.Options.NotPreviousMember',
}
