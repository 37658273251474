import React, { FC, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import classNames from 'classnames'

import ConfirmationModal from '@components/ConfirmationModal'
import { rootContext, useTranslation } from '@const/globals'

import './InitialMessageSending.css'

type Props = {
  className?: string
  dataTest?: string
}

const rootClass = 'initial-message-sending'

const InitialMessageSending: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const history = useHistory()

  const handleClose = useCallback(() => {
    history.push(`${rootContext}/classic/dashboard`)
  }, [])

  return (
    <ConfirmationModal
      isOpen
      title={t('Initial.Message.Sending.Confirmation.Title')}
      okButtonText={t('Initial.Message.Sending.Confirmation.Button')}
      body={t('Initial.Message.Sending.Confirmation.Content')}
      closeModal={handleClose}
      className={classNames(rootClass, className)}
      dataTest={dataTest}
    />
  )
}

export default InitialMessageSending
