import React, { FC, useContext } from 'react'
import { useHistory } from 'react-router'

import classNames from 'classnames'

import { MIRRORED_LISTS_ID } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import { ButtonSize } from '@components/Button/Button'
import PageHeader from '@components/PageHeader'
import Svg, { SvgNames } from '@components/Svg'
import TriggerButton, { TriggerButtonOption } from '@components/TriggerButton/TriggerButton'
import UpgradeHelper from '@components/UpgradeHelper/UpgradeHelper'
import { useTranslation } from '@const/globals'
import ProgramStatus from '@src/pages/programs/dashboard/components/ProgramStatus/ProgramStatus'
import { ProgramActionsOptions } from '@src/pages/programs/dashboard/ProgramDashboard.constants'
import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'
import { UpgradeManagerContext } from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'
import { useAccountSettings } from '@utils/account/account.utils'
import { ProgramState } from '@utils/program/program.constants'

import './ProgramManagerHeader.css'

interface ProgramManagerHeaderProps {
  className?: string
  dataTest?: string
}

const rootClass = 'program-manager-header'

const ProgramManagerHeader: FC<ProgramManagerHeaderProps> = (props: ProgramManagerHeaderProps) => {
  const { dataTest = rootClass } = props
  const {
    values: { program, programStarting, programState, isEditing, programCounts, unsavedChanges, canEdit },
    toggleProgramManagerModalsState,
    editProgram,
    pauseProgram,
    saveProgram,
    cancelEdit,
  } = useContext(ProgramManagerContext)
  const {
    values: { showUpgradeSidePanel },
    update,
  } = useContext(UpgradeManagerContext)
  const { t } = useTranslation()
  const { hasAutomatedProgramsUpgrade } = useAccountSettings()
  const history = useHistory()
  const hasPrevPage = history.length > 1
  const isNewProgram = history.location.pathname.includes('/new') || history.location.pathname.includes('/debug')

  const onUpgradeHelperClick = () => update({ showUpgradeSidePanel: !showUpgradeSidePanel })

  const showUpgradeHelper =
    isEditing &&
    hasAutomatedProgramsUpgrade &&
    !isNewProgram &&
    !program.hasActOnContactList &&
    !program.sources.find((source) => source.baseId.startsWith(MIRRORED_LISTS_ID))

  const editOrPauseAndEdit = () => {
    if (programState === ProgramState.RUNNING) {
      toggleProgramManagerModalsState('showPauseProgram')
    } else {
      editProgram()
    }
  }

  const getActionsButtonOptions = () => {
    const options: TriggerButtonOption[] = []

    if (programState === ProgramState.PAUSED) {
      options.push({
        ...ProgramActionsOptions.start,
        icon: programStarting ? SvgNames.playNoFill : SvgNames.play,
        text: t(ProgramActionsOptions.start.text),
        onClick: () => toggleProgramManagerModalsState('showStartProgram'),
        disabled: programStarting,
      })
    }

    if (programState === ProgramState.RUNNING) {
      options.push({
        ...ProgramActionsOptions.pause,
        text: t(ProgramActionsOptions.pause.text),
        onClick: () => pauseProgram(),
      })
    }

    if (programState === ProgramState.ERROR) {
      options.push({
        ...ProgramActionsOptions.errors,
        text: t(ProgramActionsOptions.errors.text),
        disabled: !canEdit,
        disabledTooltip: 'Ask your administrator for permission to do this',
        onClick: () => toggleProgramManagerModalsState('showErrors'),
      })
    }

    if (!isEditing && canEdit) {
      options.push({
        ...ProgramActionsOptions.edit,
        text: t(ProgramActionsOptions.edit.text),
        onClick: editOrPauseAndEdit,
      })
    }

    if (programState === ProgramState.RUNNING) {
      const disabled = programCounts.pendingSize === 0 || program.schedule?.isTriggered
      const tooltip = programCounts.pendingSize === 0 ? 'There are no pending contacts to add.' : 'Contacts are entered immediately'
      options.push({
        ...ProgramActionsOptions.add,
        text: `${t(ProgramActionsOptions.add.text)} ${disabled ? '' : `(${programCounts.pendingSize})`}`,
        disabled,
        disabledTooltip: t(tooltip),
        onClick: () => toggleProgramManagerModalsState('showAddContactsModal'),
      })
    }

    if (programState !== ProgramState.RUNNING && program.startedOn > 0) {
      options.push({
        ...ProgramActionsOptions.clear,
        text: t(ProgramActionsOptions.clear.text),
        onClick: () => toggleProgramManagerModalsState('showClearHistory'),
      })
    }

    return options
  }

  const onCancel = () => {
    if (unsavedChanges) {
      toggleProgramManagerModalsState('showCancelEdit')
    } else {
      cancelEdit()
    }
  }

  const renderEditingButtons = () => (
    <>
      <Button onClick={onCancel} buttonType={ButtonType.TERTIARY}>
        {t('Cancel')}
      </Button>
      <Button
        className={`${rootClass}__button--primary`}
        dataTest={`${dataTest}-save`}
        onClick={saveProgram}
        buttonType={ButtonType.PRIMARY}
        iconPosition={ButtonIconPosition.LEFT}
        disabled={!unsavedChanges}
        title={t('Save Program')}
      >
        <Svg name={SvgNames.check} />
        {t('Save')}
      </Button>
    </>
  )

  return (
    <PageHeader primaryText={program?.name ?? ''} background linkBack={hasPrevPage} leftContent className={rootClass} dataTest={dataTest}>
      {showUpgradeHelper && (
        <UpgradeHelper className={classNames({ [`${rootClass}__extra-margin`]: unsavedChanges })} onClick={onUpgradeHelperClick} />
      )}
      <ProgramStatus programStarting={programStarting} programState={programState} isEditing={isEditing && unsavedChanges} />
      {isEditing && unsavedChanges ? (
        renderEditingButtons()
      ) : (
        <TriggerButton
          label={t('Actions')}
          options={getActionsButtonOptions()}
          buttonType={ButtonType.PRIMARY}
          buttonSize={ButtonSize.LARGE}
          alignRight
          dataTest={`${dataTest}-actions`}
        />
      )}
    </PageHeader>
  )
}

export default ProgramManagerHeader
