import React, { FC } from 'react'

import Search, { SearchType } from '@components/Search/Search'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import TooltipButton from '@components/TooltipButton/TooltipButton'
import { useTranslation } from '@const/globals'
import { useSalesUsers } from '@src/pages/Settings/Users/context/SalesUsersContext'
import { useAllUsers } from '@src/pages/Settings/Users/context/UsersContext'
import SalesUsersCountInfo from '@src/pages/Settings/Users/tabs/SalesUsers/components/SalesUsersCountInfo'
import { useAccountSettings } from '@utils/account/account.utils'

interface Props {
  onSearch: (state: string) => void
  updateFromCRM: () => void
  searchTerm: string
  className?: string
  dataTest?: string
}

const rootClass = 'sales-users__table-info-bar'

const SalesUsersTableInfoBar: FC<Props> = (props) => {
  const { onSearch, searchTerm, dataTest = rootClass, updateFromCRM } = props
  const { onEditSalesPermissions, allowEditSalesPermission } = useSalesUsers()
  const { isSF } = useAccountSettings()
  const { isAdministrator } = useAllUsers()
  const { t } = useTranslation()

  return (
    <div className={rootClass} data-test={dataTest}>
      <SalesUsersCountInfo />
      {isAdministrator && isSF && (
        <div className={`${rootClass}__actions`} data-test={`${dataTest}-actions`}>
          <TooltipButton title={t('Update from CRM')} dataTest={`${dataTest}-update-button`} onClick={() => updateFromCRM()}>
            <Svg name={SvgNames.optimize} type={SvgType.LIGHT_SMALLER_ICON} />
          </TooltipButton>
          {allowEditSalesPermission && (
            <TooltipButton title={t('Sales.Permissions.Edit.Tooltip')} dataTest={`${dataTest}-edit-button`} onClick={onEditSalesPermissions}>
              <Svg name={SvgNames.key} type={SvgType.LIGHT_SMALLER_ICON} />
            </TooltipButton>
          )}
        </div>
      )}
      <Search
        className={`${rootClass}__search`}
        dataTest={`${dataTest}-search-input`}
        incomingValue={searchTerm}
        onChangeHandler={onSearch}
        placeholder={t('Search users')}
        searchType={SearchType.LARGE}
        canClear
        clearOnChange={[searchTerm]}
      />
    </div>
  )
}

export default SalesUsersTableInfoBar
