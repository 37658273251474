import React, { FC, ReactNode, useCallback, useState } from 'react'

import classNames from 'classnames'

import Accordion, { AccordionItemData, AccordionProps } from '@components/Accordion/Accordion'
import { DropDownType } from '@components/DropDown/DropDown'
import DropDown from '@components/DropDown/index'
import DropDownActions, { MenuItem } from '@components/DropDownActions/DropDownActions'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { toTrainCase } from '@utils/strings'

import './PaneDrawer.css'

export interface PaneDrawerItem {
  title: string | ReactNode
  key?: string
  icon?: SvgNames
  dropdownMenuItems?: MenuItem[]
  content: ReactNode
  disabled?: boolean
  disabledTooltip?: ReactNode
}

interface PaneDrawerProps {
  className?: string
  dataTest?: string
  panes: PaneDrawerItem[]
  accordionType?: AccordionProps['type']
  defaultOpenPanes?: (string | ReactNode)[]
}

const rootClass = 'pane-drawer'

const PaneDrawer: FC<PaneDrawerProps> = (props: PaneDrawerProps) => {
  const { dataTest = rootClass, className = '', panes, defaultOpenPanes, accordionType } = props

  const [show, setShow] = useState<string | undefined>()
  const handleCloseDropdown = useCallback(() => setShow(undefined), [])

  const getTitleText = (title: string | ReactNode): string => {
    return typeof title === 'string' ? title : 'component'
  }

  const defaultOpenPaneTitles = defaultOpenPanes?.map((pane) => getTitleText(pane)) || []

  const renderDisabledPane = (pane: PaneDrawerItem): AccordionItemData => {
    const titleText = getTitleText(pane.title)

    return {
      header: (
        <Tooltip
          isPopover
          minimalPadding={false}
          className={`${rootClass}__pane__tooltip`}
          trigger={
            <div className={classNames(`${rootClass}__pane`, `${rootClass}__pane--disabled`)} data-test={`${dataTest}-${toTrainCase(titleText)}`}>
              <Svg name={SvgNames.lockFilled} type={SvgType.MEDIUM_LARGE_ICON} />
              <Typography type={TextType.SECTION_HEADER_DISABLED} weight={TextWeight.MEDIUM} text={pane.title} />
            </div>
          }
        >
          {pane.disabledTooltip}
        </Tooltip>
      ),
      content: <></>,
      index: titleText,
      disabled: true,
    }
  }

  const renderDropdownMenu = (dropdownMenuItems: MenuItem[], paneKey: string) => {
    return (
      <DropDown
        isOpen={show === paneKey}
        toggleOpen={() => {
          show === paneKey ? setShow(undefined) : setShow(paneKey)
        }}
        type={DropDownType.STYLED}
        hasOverflowIcon
        className={`${rootClass}__dropdown`}
        dataTest={`${dataTest}-dropdown`}
      >
        <DropDownActions menuItems={dropdownMenuItems} closeDropDown={handleCloseDropdown} />
      </DropDown>
    )
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Accordion
        className={classNames(`${rootClass}__pane`)}
        defaultValue={defaultOpenPaneTitles}
        type={accordionType}
        childData={panes.map((pane) => {
          const titleText = getTitleText(pane.title)

          return pane.disabled
            ? renderDisabledPane(pane)
            : {
                header: (
                  <div className={`${rootClass}__pane__inner-header`}>
                    {titleText === 'component' ? (
                      pane.title
                    ) : (
                      <Typography text={pane.title} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} lineHeight={LineHeight.MEDIUM_LARGE} />
                    )}
                    {pane.icon && (
                      <Svg
                        name={pane.icon}
                        className={`${rootClass}__pane__inner-header-icon`}
                        dataTest={`${dataTest}-${toTrainCase(titleText)}-icon`}
                      />
                    )}
                    {pane.dropdownMenuItems && renderDropdownMenu(pane.dropdownMenuItems, pane?.key || (pane.title as string))}
                  </div>
                ),
                content: (
                  <div className={`${rootClass}__pane__content`} data-test={`${dataTest}-${toTrainCase(titleText)}-content`}>
                    {pane.content}
                  </div>
                ),
                index: titleText,
              }
        })}
        chevronLeft
        chevronAnimation={'right-down'}
      />
    </div>
  )
}

export default PaneDrawer
