import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import CaretIcon, { CaretIconDirection } from '@components/CaretIcon/CaretIcon'
import Checkbox from '@components/Checkbox/Checkbox'
import EmptyList from '@components/EmptyList/EmptyList'
import { getListPickerUtil, ListPickerUtil } from '@components/ListPicker/listPickerUtil'
import Loader from '@components/Loader/Loader'
import PageError from '@components/PageError'
import SearchResultSummary from '@components/SearchResultSummary/SearchResultSummary'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { useTranslation } from '@const/globals'
import { Folder } from '@interface/foldersLists/Folder'
import { List } from '@interface/foldersLists/List'
import { SearchResults } from '@interface/foldersLists/Search'
import { getFolderName } from '@utils/folders'
import { getListCategoryName, ListCategory } from '@utils/lists'
import { getCountDisplay } from '@utils/numbers'
import { INDENTION_UNIT, SPACING_UNIT } from '@utils/sizes'

import './listPicker.css'

const rootClass = 'list-picker'

export interface ListsState {
  lists?: List[]
  loading?: boolean
  error?: boolean
}

export interface FoldersListsState {
  folders?: Folder[]
  loading?: boolean
  error?: boolean
}

export interface SearchState {
  search?: SearchResults
  query?: string
  loading?: boolean
  error?: boolean
}

export interface State {
  collapsedRows: string[]
  collapsedFolders: string[]
}

export interface Props {
  lists?: ListsState
  folders?: FoldersListsState
  search?: SearchState
  dataTest?: string
  selectedLists: string[]
  unifiedLists: string[]
  actonClassicLists: string[]
  className?: string
  updateSelectedLists(lists: string[]): void
  clearSearch?(): void
  disableCollapse?: boolean
  customScrollClass?: string
  isListsOnly?: boolean
  smsCompatibleLists?: boolean
  importUnifiedCompatibleLists?: boolean
  listType: ListCategory
  cannotAddLists: boolean
  multiSelect?: boolean
}

export function getCollapser(dataTest: string, collapsed: boolean, t: Function, id: string, handleCollapse: (id: string, collapse: boolean) => void) {
  if (collapsed) {
    return (
      <button
        data-test={`${dataTest}-expand-${id}`}
        title={t('Expand Row')}
        onClick={(e) => {
          handleCollapse(id, false)
          e.preventDefault()
          return false
        }}
      >
        <CaretIcon direction={CaretIconDirection.RIGHT} />
      </button>
    )
  }

  return (
    <button
      data-test={`${dataTest}-collapse-${id}`}
      title={t('Collapse Row')}
      onClick={(e) => {
        handleCollapse(id, true)
        e.preventDefault()
        return false
      }}
    >
      <CaretIcon direction={CaretIconDirection.DOWN} />
    </button>
  )
}

export function getRowCollapser(
  dataTest: string,
  rowCollapsed: boolean,
  t: Function,
  list: List,
  handleCollapseRow: (listId: string, collapse: boolean) => void
) {
  if (list.children?.length === 0) return null

  return getCollapser(dataTest, rowCollapsed, t, list.id, handleCollapseRow)
}

export function getFolderCollapser(
  dataTest: string,
  folderCollapsed: boolean,
  t: Function,
  folder: Folder,
  handleCollapseFolder: (folderId: string, collapse: boolean) => void
) {
  if (!folder.entries || folder.entries.length === 0) return null

  return getCollapser(dataTest, folderCollapsed, t, `${folder.id}`, handleCollapseFolder)
}

export function getListIcon(list: List) {
  if (list.isCrm) {
    return SvgNames.crm
  } else if (list.isSegment) {
    return SvgNames.segment
  } else if (list.isTest) {
    return SvgNames.test
  } else if (list.isExtension) {
    return SvgNames.extension
  } else if (list.isWebinar) {
    return SvgNames.webinar
  } else if (list.isFormSubmission) {
    return SvgNames.formSubmission
  }
  return SvgNames.uploaded
}

export function getLists(
  dataTest: string,
  t: Function,
  index: number,
  lists: List[],
  listPickerUtil: ListPickerUtil,
  allSelected: boolean,
  category: ListCategory
): ReactNode[] {
  const state = listPickerUtil.state()
  const props = listPickerUtil.props()
  const { isListsOnly, multiSelect = true, importUnifiedCompatibleLists } = props

  return lists.map((list, i) => {
    const rowCollapsed = list.children?.length > 0 && state.collapsedRows.includes(list.id)

    const disableCollapse = props.disableCollapse || isListsOnly
    const disabled = listPickerUtil.isDisabled(list, category)

    const padding = disableCollapse ? 0 : index * SPACING_UNIT + (list.children?.length === 0 ? INDENTION_UNIT : 0)
    const isSelected = allSelected || props.selectedLists.includes(list.id)
    return (
      <React.Fragment key={list.id}>
        <div className={`${rootClass}__row`} data-test={`${dataTest}-lists-${i}`}>
          <div className={`${rootClass}__col`}>
            {multiSelect ? (
              // eslint-disable-next-line jsx-a11y/label-has-associated-control
              <label>
                <Checkbox
                  title={t('Select List/Segment')}
                  checked={!disabled && isSelected}
                  disabled={disabled}
                  onChange={() => {
                    listPickerUtil.handleListChanged(list.id, !isSelected)
                    return false
                  }}
                />
              </label>
            ) : (
              // eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
              <label
                title={
                  disabled && importUnifiedCompatibleLists
                    ? list.isCrm
                      ? t("CRM Contacts based segments can't be used")
                      : t("Act-On Contacts based segments can't be used")
                    : ''
                }
                className={classNames(`${rootClass}__icon-holder`)}
                onClick={() => {
                  if (!disabled) {
                    listPickerUtil.handleListChanged(list.id, !isSelected)
                  }
                  return false
                }}
              >
                {!isSelected && (
                  <Svg
                    className={classNames(`${rootClass}__list-icon`, {
                      [`${rootClass}__list-icon-disabled`]: disabled,
                    })}
                    name={SvgNames.uploaded}
                  />
                )}
                {isSelected && (
                  <div className={`${rootClass}__checked-icon`}>
                    <Svg name={SvgNames.check} />
                  </div>
                )}
              </label>
            )}
          </div>
          <div className={`${rootClass}__col`}>
            <label title={list.name} style={{ paddingLeft: `${padding}px` }}>
              {!disableCollapse && getRowCollapser(dataTest, rowCollapsed, t, list, listPickerUtil.handleCollapseRow)}
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div
                onClick={(e) => {
                  if (!disableCollapse && list.children?.length) {
                    listPickerUtil.handleCollapseRow(list.id, !rowCollapsed)
                  } else {
                    if (!disabled) {
                      listPickerUtil.handleListChanged(list.id, !isSelected)
                    }
                  }
                  e.preventDefault()
                  return false
                }}
                className={`${rootClass}__text`}
              >
                {list.name}
                {disabled && props.smsCompatibleLists && (
                  <span title={t('SMS recipient lists must have a mobile phone number column.')} className={`${rootClass}__disabled-icon`}>
                    <Svg name={SvgNames.cellphoneOff} type={SvgType.ICON} />
                  </span>
                )}
              </div>
            </label>
          </div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            className={`${rootClass}__col`}
            onClick={() => {
              if (!disabled) {
                listPickerUtil.handleListChanged(list.id, !isSelected)
              }
              return false
            }}
          >
            <label title={list.description ?? ''}>
              <Svg name={getListIcon(list)} />
              <span className={`${rootClass}__text`}>{list.description}</span>
            </label>
          </div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            className={`${rootClass}__col`}
            onClick={() => {
              if (!disabled) {
                listPickerUtil.handleListChanged(list.id, !isSelected)
              }
              return false
            }}
          >
            <label>{getCountDisplay(list.size ?? 0)}</label>
          </div>
        </div>
        {!isListsOnly &&
          !rowCollapsed &&
          list.children?.length > 0 &&
          getLists(dataTest, t, index + 1, list.children, listPickerUtil, allSelected, category)}
      </React.Fragment>
    )
  })
}

export function getFolders(
  dataTest: string,
  t: Function,
  index = 0,
  folders: Folder[],
  listPickerUtil: ListPickerUtil,
  allSelected: boolean,
  category: ListCategory,
  collapsable?: boolean
): ReactNode[] {
  const state = listPickerUtil.state()
  const props = listPickerUtil.props()

  return folders.map((folder, i) => {
    const folderCollapsed = folder.entries && folder.entries.length > 0 && state.collapsedFolders.includes(`${folder.id}`)

    const canCollapse = !props.disableCollapse && collapsable !== false
    return (
      <React.Fragment key={`${folder.id}`}>
        <div
          data-test={`${dataTest}-folders-${i}`}
          className={classNames(`${rootClass}__folder`, {
            [`${rootClass}__folder--collapsable`]: canCollapse,
          })}
        >
          <div className={`${rootClass}__folder-row`}>
            {canCollapse && getFolderCollapser(dataTest, folderCollapsed ?? false, t, folder, listPickerUtil.handleCollapseFolder)}
            <Svg className={`${rootClass}__folder-icon`} name={SvgNames.folder} /> {getFolderName(folder, t)}
          </div>
          {!folderCollapsed && folder.entries && getLists(dataTest, t, index + 1, folder.entries, listPickerUtil, allSelected, category)}
        </div>
      </React.Fragment>
    )
  })
}

export function getSearch(dataTest: string, t: Function, listPickerUtil: ListPickerUtil, allSelected: boolean) {
  const props: Props = listPickerUtil.props()

  const getSearchResultDisplay = (name: string, category: ListCategory, folders?: Folder[]) => {
    if (!folders) return null
    return (
      <>
        <div className={`${rootClass}__search-section`}>{name}</div>
        {getFolders(dataTest, t, 0, folders, listPickerUtil, allSelected, category, false)}
      </>
    )
  }

  const contacts: Folder[] | undefined = props.search?.search?.contacts
    ? [
        {
          id: -3,
          name: t('Contacts'),
          entries: props.search.search.contacts,
          count: 0,
          isContacts: true,
          isDefault: false,
        },
      ]
    : undefined
  return (
    <div className={`${rootClass}__search`}>
      {props.search?.query && (
        <SearchResultSummary
          term={props.search.query}
          count={props.search?.search?.count ?? 0}
          name={t('items')}
          dataTest={`${dataTest}-search`}
          onClear={() => {
            if (props.clearSearch) {
              props.clearSearch()
            }
          }}
        />
      )}
      <div className={`${rootClass}__search-body`}>
        {getSearchResultDisplay(getListCategoryName(ListCategory.ACCOUNTS, t), ListCategory.ACCOUNTS, contacts)}
        {getSearchResultDisplay(getListCategoryName(ListCategory.MARKETING, t), ListCategory.MARKETING, props.search?.search?.marketing)}
        {getSearchResultDisplay(
          getListCategoryName(ListCategory.FORM_SUBMISSIONS, t),
          ListCategory.FORM_SUBMISSIONS,
          props.search?.search?.formSubmissions
        )}
        {getSearchResultDisplay(getListCategoryName(ListCategory.WEBINAR, t), ListCategory.WEBINAR, props.search?.search?.webinar)}
        {getSearchResultDisplay(getListCategoryName(ListCategory.UNIFIED_LIST, t), ListCategory.UNIFIED_LIST, props.search?.search?.unifiedLists)}
      </div>
    </div>
  )
}

export function getList(dataTest: string, t: Function, listPickerUtil: ListPickerUtil, allSelected: boolean) {
  const props = listPickerUtil.props()

  const emptyList = <EmptyList message={t('No lists in this folder')} isPicker key="empty-list" />

  if (props.search?.search) {
    return getSearch(dataTest, t, listPickerUtil, allSelected)
  }
  if (props.folders?.folders) {
    return [getFolders(dataTest, t, 0, props.folders.folders, listPickerUtil, allSelected, props.listType), emptyList]
  }
  if (props.lists?.lists && props.lists.lists.length > 0) {
    return getLists(dataTest, t, 0, props.lists.lists, listPickerUtil, allSelected, props.listType)
  }

  return emptyList
}

function getBody(dataTest: string, t: Function, listPickerUtil: ListPickerUtil) {
  if (listPickerUtil.isError()) {
    return <PageError center />
  }

  if (listPickerUtil.isLoading()) {
    return <Loader center />
  }

  const props = listPickerUtil.props()
  const { multiSelect = true } = props
  const allSelected = listPickerUtil.getAllSelected()

  const disableSelectAll =
    (props.listType !== ListCategory.UNIFIED_LIST && props.unifiedLists.length > 0) ||
    (props.listType === ListCategory.UNIFIED_LIST && props.actonClassicLists.length > 0)

  return (
    <>
      {!props.search?.search && (
        <div
          className={classNames(`${rootClass}__header`, {
            [`${rootClass}__header--folders`]: props.folders?.folders,
          })}
        >
          <div
            className={classNames(`${rootClass}__col`, {
              [`${rootClass}__col-hide`]: !multiSelect,
            })}
          >
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>
              <Checkbox
                title={t('Select All Lists/Segments')}
                checked={allSelected}
                disabled={disableSelectAll}
                onChange={(checked) => {
                  listPickerUtil.handleListChanged(null, checked)
                }}
              />
            </label>
          </div>
          <div className={`${rootClass}__col`}>
            <label>{t('LIST/SEGMENT')}</label>
          </div>
          <div className={`${rootClass}__col`}>
            <label>{t('SOURCE')}</label>
          </div>
          <div className={`${rootClass}__col`}>
            <label>{t('RECORDS')}</label>
          </div>
        </div>
      )}
      {getList(dataTest, t, listPickerUtil, allSelected)}
    </>
  )
}

export const ListPicker: FC<Props> = (props: Props) => {
  const { customScrollClass, className, dataTest = 'list-picker' } = props

  const { t } = useTranslation()
  const [state, setState] = React.useState<State>({
    collapsedRows: [],
    collapsedFolders: [],
  })

  React.useEffect(() => {
    if (props.folders?.loading || props.lists?.loading || props.search?.loading) {
      setState({
        ...state,
        collapsedRows: [],
        collapsedFolders: [],
      })
    }
  }, [props.folders, props.lists, props.search])

  const listPicker = getListPickerUtil(state, setState, props)

  return (
    <div data-test={dataTest} className={classNames(rootClass, className, customScrollClass)}>
      {getBody(dataTest, t, listPicker)}
    </div>
  )
}

export default ListPicker
