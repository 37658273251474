import React, { FC } from 'react'

import classNames from 'classnames'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './SendSMSStepDetail.css'

interface Props {
  message: string
  className?: string
  dataTest?: string
}

const rootClass = 'send-sms-step-detail'

const SendSMSStepDetail: FC<Props> = (props: Props) => {
  const { message, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__label`}>
        <Typography text={t('Send SMS')} inline />
        <Svg name={SvgNames.sms} type={SvgType.ICON} />
      </div>
      {message !== '' && <Typography className={`${rootClass}__message`} text={message} />}
      {message === '' && <Typography className={`${rootClass}__message`} text={t('undefined')} weight={TextWeight.ITALIC} />}
    </div>
  )
}

export default SendSMSStepDetail
