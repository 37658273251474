import { gql } from '@apollo/client'

export default gql`
  mutation setBrandingFavicon($url: String!) {
    setBrandingFavicon(url: $url) {
      status
      errorMsg
      item {
        url
        isDefault
      }
    }
  }
`
