import getItem from '@graphql/microservices/categorization/getItem'
import { GetItemQuery, GetItemQueryVariables } from '@graphql/types/microservice/categorization-types'
import { ItemType } from '@utils/categorization'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

interface ProgramManagerCategorizationServiceRequests {
  getItemByIdRequest: (externalId: string, type: ItemType) => FetchPromise<GetItemQuery>
}

export const useProgramManagerCategorizationServiceRequests = (): ProgramManagerCategorizationServiceRequests => {
  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })

  const getItemByIdRequest = (externalId: string, type: ItemType) => {
    return categorizationClient.query<GetItemQuery, GetItemQueryVariables>({
      query: getItem,
      fetchPolicy: 'network-only',
      variables: {
        externalId,
        type,
      },
    })
  }

  return {
    getItemByIdRequest,
  }
}
