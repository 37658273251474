import React, { FC, ReactNode, RefObject, useEffect } from 'react'
import { connect } from 'react-redux'

import classNames from 'classnames'

import mapStateToProps, { PageContainerStateProps } from './state/mapStateToProps'

import './pageContainer.css'

const rootClass = 'page-container'

interface PageContainerProps {
  children: ReactNode
  noPadding?: boolean
  alwaysPadding?: boolean
  noOverflow?: boolean
  noChrome?: boolean
  noPrintClass?: boolean
  className?: string
  dataTest?: string
  hasDynamicHeight?: boolean
  register?: RefObject<HTMLDivElement>
}

export type PageContainerAllProps = PageContainerProps & PageContainerStateProps

export const PageContainer: FC<PageContainerAllProps> = (props: PageContainerAllProps) => {
  const {
    noPadding = false,
    alwaysPadding = false,
    noOverflow = false,
    noChrome = false,
    noPrintClass,
    className,
    dataTest = rootClass,
    register,
    hasDynamicHeight,
  } = props

  const checkContainerHeight = () => {
    const container = document.querySelector(`#${rootClass}`) as HTMLDivElement
    if (container) {
      container.style.height = `calc(100vh - ${container.offsetTop}px)`
    }
  }

  useEffect(() => {
    if (hasDynamicHeight) {
      checkContainerHeight()
    }
  }, [className])

  useEffect(() => {
    if (hasDynamicHeight) {
      window.addEventListener('resize', checkContainerHeight)
      return () => {
        window.removeEventListener('resize', checkContainerHeight)
      }
    }
  }, [])

  return (
    <div
      id={rootClass}
      className={classNames(rootClass, className, {
        [`${rootClass}--collapsed-nav`]: !props.navigation?.expanded,
        [`${rootClass}--no-chrome`]: noChrome,
        [`${rootClass}--no-padding`]: noPadding,
        [`${rootClass}--always-padding`]: alwaysPadding,
        [`${rootClass}--no-overflow`]: noOverflow,
        [`${rootClass}--print-class`]: !noPrintClass,
      })}
      data-test={dataTest}
      ref={register}
    >
      {props.children}
    </div>
  )
}

PageContainer.displayName = 'PageContainer'
export default connect(mapStateToProps)(PageContainer)
