import React, { FC, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/Button'
import DataCardHeader, { DataCardHeaderProps } from '@components/DataCard/components/DataCardHeader/DataCardHeader'
import DropDown from '@components/DropDown'
import { DropDownType } from '@components/DropDown/DropDown'
import DropDownActions, { DropDownActionsType, MenuItem } from '@components/DropDownActions/DropDownActions'
import Svg, { SvgType } from '@components/Svg'
import svgNames from '@components/Svg/SvgNames'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'

import './DataCardHeaderWithOptions.css'

export type DataCardHeaderWithOptionsProps = DataCardHeaderProps & {
  options: DataCardHeaderOptionType[]
  defaultOption?: DataCardHeaderOptionType
}

export type DataCardHeaderOptionType = {
  key?: string | number
  onClick?: (option: DataCardHeaderOptionType) => void
  value: string
  valueOnSelect: string
}

interface State {
  open: boolean
  selectedOption: DataCardHeaderOptionType
}

const rootClass = 'data-card-header-with-options'
const DataCardHeaderWithOptions: FC<DataCardHeaderWithOptionsProps> = (props: DataCardHeaderWithOptionsProps) => {
  const { options, defaultOption, dataTest = rootClass, className = '' } = props
  const [state, setState] = useState<State>({ open: false, selectedOption: !!defaultOption ? defaultOption : options[0] })
  const { open, selectedOption } = state
  const menuItems = options.map((option) => {
    return { onClick: () => optionSelected(option), row: option, text: option.value }
  }) as MenuItem[]

  const optionSelected = (selectedOption: DataCardHeaderOptionType) => {
    const { onClick } = selectedOption
    if (onClick) {
      onClick(selectedOption)
    }
    setState({ open: !open, selectedOption })
  }

  const toggleOpen = () => setState({ ...state, open: !open })

  const renderTrigger = () => {
    return (
      <Button buttonType={ButtonType.TEXT} className={`${rootClass}__trigger`} onClick={(e) => e.stopPropagation()}>
        <Typography
          className={`${rootClass}__selected`}
          text={selectedOption.valueOnSelect}
          type={open ? TextType.BODY_TEXT_SMALL : TextType.BODY_TEXT_GRAY_SMALL}
          weight={TextWeight.MEDIUM}
        />
        <Svg
          className={classNames(`${rootClass}__caret`, { [`${rootClass}__caret-open`]: open })}
          name={svgNames.caretFillDown}
          type={SvgType.VERY_SMALL_ICON}
        />
      </Button>
    )
  }

  return (
    <DataCardHeader {...props} className={classNames(rootClass, className)} dataTest={dataTest}>
      <DropDown trigger={renderTrigger()} isOpen={open} toggleOpen={toggleOpen} type={DropDownType.STYLED}>
        <DropDownActions closeDropDown={toggleOpen} menuItems={menuItems} type={DropDownActionsType.SMALL} />
      </DropDown>
    </DataCardHeader>
  )
}

export default DataCardHeaderWithOptions
