import React from 'react'

import { TFunction } from 'i18next'

import {
  AssetPickerTableType,
  renderDefaultNameColumn,
  renderDefaultDateColumn,
} from '@complex/AssetPickerModal/Components/Table/AssetPickerTable.utils'
import { ItemDtoRow } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import Tooltip from '@components/Tooltip/Tooltip'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { longDateFormat } from '@utils/date/dateUtils'

const rootClass = 'webinars-picker-modal'

const renderItemNameVal = (original: ItemDto, search: string, rootClass: string) => {
  const itemName = (original as any)?.title ?? ''
  const trigger = search ? renderBoldTextOnMatch(itemName, search) : itemName

  return (
    <div className={`${rootClass}__item-name ellip`}>
      <div className={`${rootClass}__item-name-typography`}>
        <Tooltip trigger={trigger} minimalPadding ellipOnTrigger>
          {itemName}
        </Tooltip>
      </div>
    </div>
  )
}

const durationFormat = (durationMinutes: number, t: TFunction) => {
  if (durationMinutes < 60) {
    return t('Duration', { context: 'minute', count: durationMinutes })
  } else {
    return t('Duration', { context: 'hour', count: Number((durationMinutes / 60).toPrecision(1)) })
  }
}

export const renderTableColumns = (t: TFunction): ColumnDefWithAdditionalProps<ItemDtoRow, any>[] => [
  renderDefaultNameColumn(t('Webinar'), '', rootClass, renderItemNameVal),
  {
    ...renderDefaultDateColumn(AssetPickerTableType.DEFAULT, t('Duration'), 'duration', (duration) => durationFormat(duration, t)),
    minSize: 175,
    maxSize: 175,
  },
  { ...renderDefaultDateColumn(AssetPickerTableType.DEFAULT, t('Event date'), 'date', longDateFormat), minSize: 200, maxSize: 200 },
]

export const renderSearchTableColumns = (search: string, _folders: FolderData[], t: TFunction): ColumnDefWithAdditionalProps<ItemDtoRow, any>[] => [
  renderDefaultNameColumn(t('Webinar'), search, rootClass, renderItemNameVal),
  {
    ...renderDefaultDateColumn(AssetPickerTableType.DEFAULT, t('Duration'), 'duration', (duration) => durationFormat(duration, t)),
    minSize: 175,
    maxSize: 175,
  },
  { ...renderDefaultDateColumn(AssetPickerTableType.DEFAULT, t('Event date'), 'date', longDateFormat), minSize: 200, maxSize: 200 },
]
