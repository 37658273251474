import React, { FC, useContext, useEffect, useState } from 'react'

import Modal, { ModalBody, ModalHeader } from '@components/Modal'
import { useTranslation } from '@const/globals'
import crmSyncResults from '@graphql/microservices/crm/crmSyncResults'
import { CrmSyncResultsQuery, CrmSyncResultsQueryVariables } from '@graphql/types/microservice/crm-types'
import { DataManagementContext } from '@src/pages/datamanagement/context/DataManagementContext'
import { buildLastSyncStatus } from '@src/pages/datamanagement/context/DataManagementUtil'
import { useGetFeatureFlag } from '@utils/account/account.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'

import SyncResultsTable from './components/SyncResultsTable/SyncResultsTable'

import './SyncResults.css'

const rootClass = 'sync-results'

type Props = {
  setShowResults?: (value: boolean) => void
}

export interface SyncChange {
  type?: string
  appended?: number
  updated?: number
  deleted?: number
}

export interface OptActivity {
  optOutPull?: string
  optInPull?: string
  optOutPush?: string
  optInPush?: string
}

export interface SyncResult {
  changes: SyncChange[]
  activity: OptActivity
  syncDate: string
}

const initActivity: OptActivity[] = [
  {
    optOutPull: 'N/A',
    optInPull: 'N/A',
    optOutPush: 'N/A',
    optInPush: 'N/A',
  },
]

const SyncResults: FC<Props> = (props: Props) => {
  const { setShowResults } = props
  const { t } = useTranslation()

  const {
    values: { syncInfo, lastSync, runningSyncStatus, connectorType, accountTimeZone, syncSchedule },
  } = useContext(DataManagementContext)
  const [syncTime, setSyncTime] = useState<string>()
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.CRM })

  const { data } = useQueryOnMount<CrmSyncResultsQuery, CrmSyncResultsQueryVariables>(crmSyncResults, {
    client,
    fetchPolicy: 'network-only',
  })

  const closeModal = () => {
    if (setShowResults) {
      setShowResults(false)
    }
  }

  const hasShowActOnContactsTab = useGetFeatureFlag('hasShowActOnContactsTab')

  const [results, setSyncResults] = useState<SyncResult>()
  const [activity, setActivity] = useState<OptActivity[]>(initActivity)

  const getDisplayName = (modelEntityTypeIdentifier: string): string => {
    const matchedEntities = syncInfo.filter((crmEntity) => crmEntity.identifier === modelEntityTypeIdentifier)
    return matchedEntities.length > 0 && matchedEntities[0].display !== undefined ? matchedEntities[0].display : modelEntityTypeIdentifier
  }

  const shouldDisplayEntity = (modelEntityTypeIdentifier?: string): boolean => {
    return syncInfo.filter((crmEntity) => crmEntity.identifier === modelEntityTypeIdentifier && crmEntity.visible).length > 0
  }

  useEffect(() => {
    if (data && data.lastSync) {
      const entitySyncResult: SyncChange[] =
        data?.lastSync?.entitySyncKeyValueList?.map((entitySyncResult) => {
          if (shouldDisplayEntity(entitySyncResult?.modelEntityTypeString)) {
            const syncChangeResult: SyncChange = {
              type: entitySyncResult?.modelEntityTypeString
                ? getDisplayName(entitySyncResult?.modelEntityTypeString)
                : entitySyncResult?.modelEntityTypeString,
              appended: entitySyncResult?.entitySyncDetailBean?.appendCount,
              updated: entitySyncResult?.entitySyncDetailBean?.updateCount,
              deleted: entitySyncResult?.entitySyncDetailBean?.deleteCount,
            }
            return syncChangeResult
          } else {
            return {}
          }
        }) || []

      const syncResult: SyncResult = {
        changes: entitySyncResult ? entitySyncResult : [],
        syncDate: data?.lastSync?.started,
        activity: {
          optOutPull: data?.lastSync?.pullOptOutAppendCount,
          optInPull: data?.lastSync?.pullOptInDeleteCount,
          optOutPush: data?.lastSync?.pushOptOutAppendCount,
          optInPush: data?.lastSync?.pushOptInDeleteCount,
        },
      }

      setSyncResults(syncResult)

      setActivity([
        {
          optOutPull: data?.lastSync?.pullOptOutAppendCount,
          optInPull: data?.lastSync?.pullOptInDeleteCount,
          optOutPush: data?.lastSync?.pushOptOutAppendCount,
          optInPush: data?.lastSync?.pushOptInDeleteCount,
        },
      ])
    }
  }, [data])

  useEffect(() => {
    const status = t(buildLastSyncStatus(lastSync, runningSyncStatus.isSyncingNow, accountTimeZone), {
      connectedCrm: connectorType,
    })
    setSyncTime(status)
  }, [lastSync])

  const closeButton = { closeButton: closeModal }

  const createTableRow = () => {
    return (
      <div>
        <div className={`${rootClass}__sub-header`}>
          {t('Last sync completed on')} {syncTime}
        </div>
        <div className={`${rootClass}__table-title`}>{t('Entity Activity')}</div>
        <div className={`${rootClass}__results`}>
          <SyncResultsTable results={results?.changes ? results?.changes : []} />
        </div>
        {syncSchedule.isOptInOutSupported && (
          <div>
            <div className={`${rootClass}__table-title`}>{t('Opt-In & Opt-Out Activity')}</div>
            <div className={`${rootClass}__opt-activity2`}>
              <div className={`${rootClass}__opt-header`}>{t('OPT-OUT PUSH')}</div>
              <div className={`${rootClass}__opt-header`}>{t('OPT-OUT PULL')}</div>
              <div className={`${rootClass}__opt-header`}>{t('OPT-IN PUSH')}</div>
              <div className={`${rootClass}__opt-header`}>{t('OPT-IN PULL')}</div>
              <div className={`${rootClass}__opt-item`}>{activity[0].optOutPush}</div>
              <div className={`${rootClass}__opt-item`}>{activity[0].optOutPull}</div>
              <div className={`${rootClass}__opt-item`}>{activity[0].optInPush}</div>
              <div className={`${rootClass}__opt-item`}>{activity[0].optInPull}</div>
            </div>
          </div>
        )}
      </div>
    )
  }

  const resultBodyWithModal = () => {
    return (
      <Modal
        className={`${rootClass}`}
        header={
          <ModalHeader {...closeButton} className={`${rootClass}__title-text`}>
            {t('CRM to Act-On Synchronization Results')}
          </ModalHeader>
        }
        isOpen={!!setShowResults}
        allowFullScreen
        noPadding
      >
        <ModalBody className={`${rootClass}__panel`}>{createTableRow()}</ModalBody>
      </Modal>
    )
  }

  const getResultWithoutModal = () => {
    return (
      <div className={`${rootClass}`}>
        <div className={`${rootClass}__title-text`}>{t('CRM to Act-On Synchronization Results')}</div>
        <div className={`${rootClass}__panel-padding`}>{createTableRow()}</div>
      </div>
    )
  }
  return hasShowActOnContactsTab ? getResultWithoutModal() : resultBodyWithModal()
}

export default SyncResults
