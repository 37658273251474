import React, { FC, useState } from 'react'

import { ApolloError, ApolloQueryResult, MutationFunctionOptions } from '@apollo/client'
import ProgramHeader from '@complex/ProgramHeader/ProgramHeader'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import PageContainer from '@components/PageContainer'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import StatusToast from '@components/StatusToast/StatusToast'
import { SvgNames } from '@components/Svg'
import { ColWithTooltip } from '@components/Table/components/tableColumns'
import { RowAction, TableColumn } from '@components/Table/Table'
import TableWithEmptyListing from '@components/TableWithEmptyListing/TableWithEmptyListing'
import { useTranslation } from '@const/globals'
import { DeleteContactFromProgramMutation, QueryGetProgramContactsArgs } from '@graphql/types/mutation-types'
import { Exact, GetProgramContactsQuery, Maybe } from '@graphql/types/query-types'
import withLoadingAndError from '@hoc/withLoadingAndError/withLoadingAndError'
import { StatusToastType } from '@interface/StatusToast'
import { ContactViewPanel, openContactView } from '@utils/contacts'
import { logNewRelicError } from '@utils/new-relic.utils'
import { SortByState } from '@utils/sms.utils'

import { ProgramContactList } from './ProgramContactsContainer'

import './ProgramContacts.css'

interface Props {
  title: string
  showReentryWarning: boolean
  loading: boolean
  pageError?: ApolloError
  data: ProgramContactList[]
  totalCount: number
  pageSize: number | 50
  pageIndex: number | 0
  pageCount: number
  fetchData: (pageIndex: number, pageSize: number) => void
  onChangeSort: (sortBy: Array<any>, resetPageIndex: boolean) => void
  sortBy: SortByState[]
  searchIncomingValue?: string
  showRemoveContactAction: boolean
  searchPlaceholder?: string
  linkOptions?: {}
  onSearch: (query: string) => void
  deleteContact: (
    options?: MutationFunctionOptions<DeleteContactFromProgramMutation, Exact<{ programId?: Maybe<string>; recId?: Maybe<string> }>> | undefined
  ) => Promise<any>
  dataTest?: string
  isEmptySearch: boolean
  downloadUrl: string
  tableTitle: string
  deleteContactLoading: boolean
  programId: string
  refetch?: (variables?: QueryGetProgramContactsArgs) => Promise<ApolloQueryResult<GetProgramContactsQuery>>
}

export interface State {
  searchQuery?: string
}

const rootClass = 'program-contacts'

const ProgramContacts: FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const {
    dataTest = rootClass,
    title,
    showReentryWarning,
    loading,
    data,
    onSearch,
    deleteContact,
    fetchData,
    onChangeSort,
    totalCount,
    pageSize,
    pageIndex,
    pageCount,
    sortBy,
    showRemoveContactAction,
    searchIncomingValue = '',
    searchPlaceholder = t('Search list'),
    isEmptySearch,
    downloadUrl,
    tableTitle,
    deleteContactLoading,
    programId,
    refetch,
  } = props

  const [toast, setToastStatus] = useState<StatusToastType>()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [recId, setRecId] = useState('')

  const renderCell = (row: any) => <ColWithTooltip row={row} />
  const [resetPageIndex, setResetPageIndex] = useState(false)

  const columns: TableColumn[] = [
    {
      Header: 'Name',
      accessor: 'name',
      align: 'left',
      Cell: renderCell,
      minWidth: 200,
      maxWidth: 200,
      sortType: 'caseInsensitive',
    },
    {
      Header: 'Email',
      accessor: 'email',
      align: 'left',
      Cell: renderCell,
      minWidth: 220,
      flexColumn: true,
      sortType: 'statusDateTime',
    },
    {
      Header: 'Source List',
      accessor: 'listName',
      align: 'left',
      Cell: renderCell,
      minWidth: 170,
      maxWidth: 170,
      sortType: 'caseInsensitive',
    },
    {
      Header: 'Date Added',
      accessor: 'when',
      align: 'left',
      Cell: renderCell,
      minWidth: 200,
      maxWidth: 200,
    },
  ]

  const handleSort = (sortBy: Array<any>) => {
    setResetPageIndex(false)
    onChangeSort(sortBy, true)
  }

  const handleSearch = (query: string) => {
    setResetPageIndex(true)
    onSearch(query)
  }

  const rowActions: RowAction[] = [
    {
      label: t('Exit Contact'),
      icon: SvgNames.removeContact,
      onClick: (row) => {
        setRecId(row.original.recId)
        setShowConfirmation(true)
      },
    },
  ]
  const renderTable = () => {
    return isEmptySearch ? (
      <EmptyListing
        headline={t(`No results found`)}
        imgSrc={StaticImageNames.emptySearch}
        size={EmptyListingSize.MEDIUM}
        text={t(`There were no contacts matching your search.`)}
      />
    ) : (
      <TableWithEmptyListing
        className={`${rootClass}__table`}
        data-test={`${dataTest}`}
        data={data}
        loading={loading}
        columns={columns}
        canPaginate
        resetPageIndex={resetPageIndex}
        onSort={handleSort}
        fetchData={fetchData}
        controlledPageCount={pageCount}
        disableSortRemove
        manualSortBy
        disableMultiSort
        rowActions={showRemoveContactAction ? rowActions : []}
        text={t('No contacts')}
        initialState={{ pageSize, pageIndex, sortBy }}
        onRowClicked={onRowClicked}
      />
    )
  }

  const onRowClicked = (row: any) => {
    openContactView(row?.original.recId, ContactViewPanel.SUMMARY)
  }

  return (
    <>
      {showConfirmation && (
        <ConfirmationModal
          isOpen
          isDelete
          title={t('Exit Contact?')}
          body={t('This contact will exit the program.')}
          deleteButtonText={t('Exit from program')}
          onAnswer={(answer) => {
            if (answer === YesNo.YES) {
              setShowConfirmation(false)
              deleteContact({
                variables: {
                  programId,
                  recId,
                },
              })
                .then(() => {
                  setToastStatus({
                    showStatus: true,
                    statusMessage: 'The contact exited the program.',
                    successStatus: true,
                  })
                  if (refetch) {
                    refetch()
                  }
                })
                .catch((error) => {
                  setToastStatus({
                    showStatus: true,
                    statusMessage: 'An error occurred. This contact did not exit the program.',
                    successStatus: false,
                  })
                  logNewRelicError(error)
                })
            } else {
              setShowConfirmation(false)
            }
          }}
        />
      )}
      {!deleteContactLoading && toast?.showStatus && (
        <StatusToast
          isSuccess={toast.successStatus}
          message={toast.statusMessage}
          title={toast.title}
          closeStatus={() => {
            setToastStatus({ showStatus: false })
          }}
        />
      )}
      <PageContainer className={rootClass} data-test={dataTest}>
        <PositionContainer noOverflow innerDivClassname={`${rootClass}__position-inner`}>
          <ProgramHeader
            primaryText={t(title)}
            downloadUrl={downloadUrl}
            tableTitle={t(tableTitle)}
            showReentryWarning={showReentryWarning}
            searchIncomingValue={searchIncomingValue}
            searchPlaceholder={searchPlaceholder}
            totalCount={totalCount}
            onSearch={handleSearch}
          />
          {renderTable()}
        </PositionContainer>
      </PageContainer>
    </>
  )
}

export default withLoadingAndError(ProgramContacts)
