import React from 'react'
import { Row } from 'react-table'

import { TFunction } from 'i18next'

import { getFavoriteProps, getFavoriteSvg } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.utils'
import Button, { ButtonType } from '@components/Button'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { HeaderAction, RowAction } from '@components/Table/Table'
import TagManager from '@components/TagManager/TagManager'
import Tooltip from '@components/Tooltip/Tooltip'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import { AccountSettings } from '@graphql/types/query-types'
import { ALL_ACTON_CONTACTS_SEGMENT_NAME } from '@src/pages/ContactSegments/utils/ContactSegments.constants'
import { goCreateSegment, sendEmailToSegment } from '@src/pages/ContactSegments/utils/ContactSegmentsContainerUtils'
import { onRowExpanded } from '@src/pages/ContactSegments/utils/ContactSegmentsSession.utils'
import { ItemSubType, ItemType } from '@utils/categorization'
import { goEditCRMSourcedSegment, goEditSegment, saveSelectedListsToLocalStorage, Segment } from '@utils/contactSegments/contactSegments.utils'
import { CRM_STATUS } from '@utils/crm.utils'

export enum SegmentTableActions {
  DELETE = 'delete',
  MANAGE_TAGS = 'manageTags',
  DELETE_FROM_FOLDER = 'deleteFromFolder',
  MOVE_TO_FOLDER = 'moveToFolder',
  REFRESH_COUNT = 'refreshRecordCount',
  SEND_EMAIL = 'sendEmail',
  SEND_SMS = 'sendSms',
  SET_FAVORITE = 'setFavorite',
}

export enum SegmentRowActions {
  CLONE = 'clone',
  DELETE = 'delete',
  DELETE_ALL_CONTACTS = 'deleteAllContacts',
  DELETE_FROM_FOLDER = 'deleteFromFolder',
  EXPORT = 'export',
  MOVE_TO_FOLDER = 'moveToFolder',
  REFRESH_COUNT = 'refreshRecordCount',
  SET_FAVORITE = 'setFavorite',
  SYNC_CRM_SEGMENT = 'syncCRMSegment',
  EDIT_CRM = 'editCRMSegment',
  SPLIT = 'split',
  REGENERATE_SPLIT = 'regenerateSplit',
  MANAGE_FTP_SYNC = 'manageFtpSync',
}

export interface TagManagerParams {
  onApplyAndRemoveTags: (rows: any[], tagsToApply: LabelDto[], tagsToRemove: number[]) => void
  onCreateTag: Function
  selectedSegmentsIds: number[]
  t: Function
  tags: LabelDto[]
}

const renderTagManager = ({ onApplyAndRemoveTags, onCreateTag, selectedSegmentsIds, t, tags }: TagManagerParams) => {
  const title = 'Add Tags'
  return (
    <TagManager
      appliedTags={[]}
      buttonText={'Add'}
      tags={tags}
      trigger={
        <Tooltip
          trigger={
            <Button buttonType={ButtonType.TABLE_ACTION}>
              <Svg name={SvgNames.tagOutline} />
            </Button>
          }
        >
          {t(title)}
        </Tooltip>
      }
      title={title}
      onApplyAndRemove={(tagsToApply: LabelDto[], tagsToRemove: number[]) => onApplyAndRemoveTags(selectedSegmentsIds, tagsToApply, tagsToRemove)}
      onCreate={(color, tagName) => onCreateTag({ color, name: tagName })}
    />
  )
}

export const getSegmentsHeaderActions = (
  rootClass: string,
  selectedSegments: Segment[],
  doTableAction: (action: SegmentTableActions) => void,
  tagManagerParams: TagManagerParams,
  allowToDelete: boolean,
  disableSegmentModifications: boolean,
  t: TFunction
): HeaderAction[] => {
  const isHiddenRemoveFromFolder = !!selectedSegments.length && selectedSegments.every(({ folderId }) => !folderId)

  return [
    {
      ...getFavoriteProps(selectedSegments, rootClass),
      hasTooltip: true,
      onClick: () => doTableAction(SegmentTableActions.SET_FAVORITE),
    },
    { embeddedComponent: renderTagManager(tagManagerParams) },
    {
      label: () => (selectedSegments.some(({ folderId }) => folderId) ? t('Move to folder') : t('Add to folder')),
      hasTooltip: true,
      icon: SvgNames.moveFolder,
      onClick: () => doTableAction(SegmentTableActions.MOVE_TO_FOLDER),
    },
    {
      hidden: isHiddenRemoveFromFolder,
      label: t('Remove from folder'),
      hasTooltip: true,
      icon: SvgNames.removeFromFolder,
      onClick: () => doTableAction(SegmentTableActions.DELETE_FROM_FOLDER),
    },
    {
      label: t('Send email'),
      inDropdown: !isHiddenRemoveFromFolder,
      hasTooltip: isHiddenRemoveFromFolder,
      icon: SvgNames.letter,
      onClick: () => doTableAction(SegmentTableActions.SEND_EMAIL),
    },
    {
      label: t('Refresh count'),
      hasTooltip: true,
      icon: SvgNames.refresh,
      inDropdown: true,
      disabled: disableSegmentModifications,
      tooltipMessage: disableSegmentModifications ? t('Segment.Tooltip.Disabled.Migration') : undefined,
      onClick: () => doTableAction(SegmentTableActions.REFRESH_COUNT),
    },
    {
      disabled: !allowToDelete || selectedSegments.some(({ isEditable }) => !isEditable) || disableSegmentModifications,
      tooltipMessage: disableSegmentModifications
        ? t('Segment.Tooltip.Disabled.Migration')
        : allowToDelete
        ? t('Cannot delete a system generated segment')
        : t('Ask your administrator for permission to do this'),
      label: 'Delete segments',
      inDropdown: true,
      hasTooltip: true,
      icon: SvgNames.delete,
      iconSize: SvgType.ICON,
      onClick: () => doTableAction(SegmentTableActions.DELETE),
    },
  ]
}

export const getSegmentsRowActions = (
  rootClass: string,
  currentURL: string,
  activeFolderId: number | undefined,
  setState: Function,
  onActionClick: (action: SegmentRowActions, row: Row<Segment>) => void,
  search: string,
  searchAll = true,
  isFtpConnectorActive: boolean,
  t: TFunction,
  { isUsingExternalSegmentationService, userAllowedToDownload, userAllowedToDelete, disableSegmentModifications }: AccountSettings
): RowAction[] => [
  {
    label: ({ original: { isFavorite } }: Row<Segment>) => (isFavorite ? 'Remove Favorite' : 'Favorite'),
    icon: ({ original: { isFavorite } }: Row<Segment>) => getFavoriteSvg(isFavorite, rootClass),
    onClick: (row) => onActionClick(SegmentRowActions.SET_FAVORITE, row),
  },
  {
    label: 'Refresh count',
    icon: SvgNames.refresh,
    disabled: disableSegmentModifications,
    tooltipMessage: disableSegmentModifications ? t('Segment.Tooltip.Disabled.Migration') : undefined,
    hasTooltip: disableSegmentModifications,
    onClick: (row) => onActionClick(SegmentRowActions.REFRESH_COUNT, row),
  },
  {
    disabled: ({ original: { isEditable } }: Row<Segment>) => !isEditable || disableSegmentModifications,
    hidden: ({ original: { itemType, parent, subTypes } }: Row<Segment>) =>
      itemType === ItemType.BOUNCE || (!!subTypes?.includes(ItemSubType.CRM_SOURCED) && !parent),
    tooltipMessage: disableSegmentModifications ? t('Segment.Tooltip.Disabled.Migration') : t('Cannot edit a system generated segment'),
    hasTooltip: true,
    label: 'Edit',
    icon: SvgNames.pencil,
    onClick: ({ original }: Row<Segment>) => goEditSegment(original, currentURL, { search, searchAll }),
  },
  {
    disabled: ({ original: { isEditable, parent } }: Row<Segment>) => !isEditable || !!parent || disableSegmentModifications,
    tooltipMessage: ({ original: { isEditable, parent } }: Row<Segment>) =>
      disableSegmentModifications
        ? t('Segment.Tooltip.Disabled.Migration')
        : !isEditable
        ? t('Cannot edit a system generated segment')
        : !!parent
        ? t('Cannot edit a subsegment')
        : '',
    label: 'Edit CRM segment',
    icon: SvgNames.pencil,
    hidden: ({ original: { parent, subTypes } }: Row<Segment>) => !subTypes?.includes(ItemSubType.CRM_SOURCED) || !!parent,
    onClick: ({ original }: Row<Segment>) => goEditCRMSourcedSegment(original),
  },
  {
    disabled: ({ original: { isEditable, crmStatus } }: Row<Segment>) =>
      !isEditable || crmStatus === CRM_STATUS.ON_GOING_SYNC || disableSegmentModifications,
    hidden: ({ original: { subTypes, parent } }: Row<Segment>) => !subTypes?.includes(ItemSubType.CRM_SOURCED) || !!parent,
    tooltipMessage: ({ original: { isEditable } }: Row<Segment>) =>
      disableSegmentModifications
        ? t('Segment.Tooltip.Disabled.Migration')
        : isEditable
        ? t('Not available for CRM synced segments')
        : t('Cannot edit a system generated segment'),
    hasTooltip: true,
    label: 'Manage segment',
    icon: SvgNames.usersUnselectedFull,
    inDropdown: true,
    onClick: ({ original }: Row<Segment>) => goEditSegment(original, currentURL, { search, searchAll }),
  },
  {
    disabled: ({ original: { crmStatus } }: Row<Segment>) => crmStatus === CRM_STATUS.ON_GOING_SYNC,
    tooltipMessage: 'Sync in progress',
    hidden: ({ original: { subTypes, syncedSegmentId } }: Row<Segment>) => !subTypes?.includes(ItemSubType.CRM_SOURCED) || !syncedSegmentId,
    hasTooltip: true,
    label: 'Sync with CRM',
    icon: SvgNames.reload,
    inDropdown: true,
    onClick: (row: Row<Segment>) => onActionClick(SegmentRowActions.SYNC_CRM_SEGMENT, row),
  },
  {
    hidden: ({ original: { itemType } }: Row<Segment>) => itemType === ItemType.BOUNCE,
    label: 'Send email',
    icon: SvgNames.letter,
    inDropdown: true,
    topSection: ({ original: { subTypes } }: Row<Segment>) => !!subTypes?.includes(ItemSubType.CRM_SOURCED),
    onClick: ({ original }: Row<Segment>) => {
      saveSelectedListsToLocalStorage([original])

      sendEmailToSegment(original.externalId)
    },
  },
  {
    disabled: ({ original: { name } }: Row<Segment>) => name === ALL_ACTON_CONTACTS_SEGMENT_NAME || disableSegmentModifications,
    hidden: ({ original: { itemType } }: Row<Segment>) => itemType === ItemType.BOUNCE,
    tooltipMessage: disableSegmentModifications ? t('Segment.Tooltip.Disabled.Migration') : t('Use the New segment button above'),
    hasTooltip: true,
    label: 'Create subsegment',
    icon: SvgNames.createSubsegment,
    inDropdown: true,
    topSection: true,
    onClick: (row: Row) => {
      const { folderId, externalId } = row.original as Segment
      goCreateSegment(currentURL, { activeFolderId: folderId ?? activeFolderId, parentId: externalId })
      onRowExpanded(row, setState)
    },
  },
  {
    label: 'Generate A/B split',
    disabled: disableSegmentModifications,
    tooltipMessage: disableSegmentModifications ? t('Segment.Tooltip.Disabled.Migration') : undefined,
    hasTooltip: disableSegmentModifications,
    hidden: ({ original: { name } }: Row<Segment>) => isUsingExternalSegmentationService || name === ALL_ACTON_CONTACTS_SEGMENT_NAME,
    inDropdown: true,
    icon: SvgNames.arrowDiverge,
    onClick: (row) => {
      onActionClick(SegmentRowActions.SPLIT, row)
    },
  },
  {
    label: 'Regenerate splits',
    hidden: ({ original: { hasSplitsToRegenerate } }: Row<Segment>) => isUsingExternalSegmentationService || !hasSplitsToRegenerate,
    inDropdown: true,
    icon: SvgNames.reload,
    onClick: (row) => {
      onActionClick(SegmentRowActions.REGENERATE_SPLIT, row)
    },
  },
  {
    hidden: ({ original: { itemType, type } }: Row<Segment>) =>
      isUsingExternalSegmentationService || itemType === ItemType.BOUNCE || type === 'Direct Select',
    disabled: disableSegmentModifications,
    tooltipMessage: disableSegmentModifications ? t('Segment.Tooltip.Disabled.Migration') : undefined,
    hasTooltip: disableSegmentModifications,
    label: 'Duplicate',
    icon: SvgNames.cloneSegment,
    inDropdown: true,
    onClick: (row) => onActionClick(SegmentRowActions.CLONE, row),
  },
  {
    disabled: !userAllowedToDownload,
    tooltipMessage: 'Ask your administrator for permission to do this',
    label: 'Export',
    icon: SvgNames.export,
    inDropdown: true,
    onClick: (row) => onActionClick(SegmentRowActions.EXPORT, row),
  },
  {
    label: 'Manage FTP sync',
    icon: SvgNames.reloadNoFill,
    inDropdown: true,
    hidden: ({ original }: { original: Segment }) => !original.ftpData?.import.syncJobs.length || !isFtpConnectorActive,
    onClick: (row) => onActionClick(SegmentRowActions.MANAGE_FTP_SYNC, row),
  },
  {
    hidden: ({ original: { itemType } }: Row<Segment>) => itemType === ItemType.BOUNCE,
    label: ({ original: { folderId } }: { original: Segment }) => (folderId ? 'Move to folder' : 'Add to folder'),
    icon: SvgNames.moveFolder,
    inDropdown: true,
    topSection: true,
    onClick: (row) => onActionClick(SegmentRowActions.MOVE_TO_FOLDER, row),
  },
  {
    hidden: ({ original: { folderId, itemType } }: Row<Segment>) => !folderId || itemType === ItemType.BOUNCE,
    disabled: (row: any) => row?.original.folderId === null,
    label: 'Remove from folder',
    icon: SvgNames.removeFromFolder,
    inDropdown: true,
    hasTooltip: false,
    tooltipMessage: `Can't remove unassigned folder`,
    onClick: (row) => onActionClick(SegmentRowActions.DELETE_FROM_FOLDER, row),
  },
  {
    disabled: ({ original: { isEditable, itemType } }: Row<Segment>) =>
      disableSegmentModifications || (itemType === ItemType.SEGMENT && (!userAllowedToDelete || !isEditable)),
    tooltipMessage: disableSegmentModifications
      ? 'Segment.Tooltip.Disabled.Migration'
      : userAllowedToDelete
      ? 'Cannot delete all contacts of a system generated segment'
      : 'Ask your administrator for permission to do this',
    hasTooltip: true,
    topSection: true,
    label: 'Delete all contacts',
    icon: SvgNames.deleteAllContacts,
    inDropdown: true,
    onClick: (row) => onActionClick(SegmentRowActions.DELETE_ALL_CONTACTS, row),
  },
  {
    hidden: ({ original: { itemType } }: Row<Segment>) => itemType === ItemType.BOUNCE,
    disabled: (row) => !row?.original.isEditable || disableSegmentModifications,
    tooltipMessage: disableSegmentModifications ? t('Segment.Tooltip.Disabled.Migration') : t('Cannot delete a system generated segment'),
    hasTooltip: true,
    label: 'Delete segment',
    icon: SvgNames.delete,
    inDropdown: true,
    onClick: (row) => onActionClick(SegmentRowActions.DELETE, row),
  },
]
