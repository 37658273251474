import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { RibbonMedals } from '@components/CarouselDataCard/CarouselDataCardUtils'
import DataCard, { DataCardProps } from '@components/DataCard/DataCard'
import Loader from '@components/Loader'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'

import './CarouselDataCard.css'

interface CarouselDataCardProps extends DataCardProps {
  ribbonMedalsData: RibbonMedals[]
  loading: boolean
  emptyCard?: RibbonMedals
}

const rootClass = 'carousel-data-card'

const CarouselDataCard: FC<CarouselDataCardProps> = (props: CarouselDataCardProps) => {
  const { dataTest = rootClass, className = '', ribbonMedalsData, loading, emptyCard } = props

  const totalPages = ribbonMedalsData.length

  const [currentPage, setCurrentPage] = useState(0)

  const goToPage = (page: number) => {
    setCurrentPage(page)
  }

  const nextPage = () => {
    let nextPage = currentPage + 1
    if (nextPage >= totalPages) {
      nextPage = 0
    }
    setCurrentPage(nextPage)
  }

  const renderPageIndicator = () => {
    const indicators = []

    for (let i = 0; i < totalPages; i++) {
      const isActive = i === currentPage

      indicators.push(
        <button key={i} className={`${rootClass}__dot ${isActive ? `${rootClass}__dot--active` : ''}`} onClick={() => goToPage(i)}></button>
      )
    }
    return indicators
  }

  useEffect(() => {
    setCurrentPage(0)
  }, [ribbonMedalsData])

  const renderCards = (ribbonMedalsData: RibbonMedals[]) => (
    <div className={`${rootClass}__wrapper`} data-test={`${dataTest}-content`}>
      <div className={classNames(`${rootClass}__ribbon`, `${rootClass}__ribbon-${ribbonMedalsData[currentPage].background}`)}>
        <Svg name={ribbonMedalsData[currentPage].svg} type={SvgType.MEDIUM} fill={emptyCard ? SvgColor.BUTTON_HOVER_GRAY : undefined} />
      </div>
      <div className={classNames(rootClass, `${rootClass}__title-section`, 'ellip', { [`${rootClass}__title-section-empty`]: emptyCard })}>
        <TextWithTooltipOnEllip
          typographyProps={{
            text: ribbonMedalsData[currentPage].title,
            type: TextType.BODY_TEXT,
            weight: TextWeight.BOLD,
          }}
        />
        <Typography
          text={ribbonMedalsData[currentPage].subTitle}
          type={emptyCard ? TextType.BODY_TEXT_SMALL : TextType.BODY_TEXT_GRAY_SMALL}
          className={classNames({ [`${rootClass}__subtitle-empty`]: emptyCard })}
        />
        {!emptyCard && <div className={`${rootClass}__indicator`}>{renderPageIndicator()}</div>}
      </div>
      <div className={`${rootClass}__button`} data-test={`${rootClass}__button`}>
        <Button
          className={classNames({ [`${rootClass}__button-empty`]: emptyCard })}
          buttonType={ButtonType.NAVIGATION_BUTTON}
          type="button"
          onClick={nextPage}
          disabled={!!emptyCard}
        >
          <Svg name={SvgNames.arrowRight} type={SvgType.MEDIUM_LARGE_ICON} fill={emptyCard ? SvgColor.BUTTON_GRAY : undefined} />
        </Button>
      </div>
    </div>
  )

  return (
    <DataCard {...props} className={classNames(rootClass, className)} dataTest={dataTest}>
      {!loading ? emptyCard ? renderCards([emptyCard]) : renderCards(ribbonMedalsData) : <Loader className={`${rootClass}__loader`} />}
    </DataCard>
  )
}

export default CarouselDataCard
