import React, { ChangeEvent, FC, Fragment, useMemo, useState } from 'react'

import classNames from 'classnames'

import { MAX_STANDARD_ACTIONS_COUNT } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.constants'
import Button, { ButtonType } from '@components/Button'
import DropDown from '@components/DropDown'
import { DropDownType } from '@components/DropDown/DropDown'
import DropDownActions, { MenuItem } from '@components/DropDownActions/DropDownActions'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import IndeterminateCheckbox from '@components/Table/components/IndeterminateCheckbox'
import { ActionType } from '@components/TableV2/tableV2TS/enums'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'
import { toTrainCase } from '@utils/strings'
import { isFunction } from '@utils/utils'

import Typography, { TextType, TextWeight } from '../../Typography/Typography'
import { HeaderAction, RowAction } from '../Table'

import './tableComponents.css'

interface Props {
  count: number
  total: number
  actions: HeaderAction[]
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onDeselectAll?: () => void
  useStickyActionHeader?: boolean
  selectedRows?: any
  selectedRowsCount?: number
  dataTest?: string
}

export const splitActions = (actions: (RowAction | HeaderAction)[], data?: any, actionType?: ActionType) => {
  const visibleActions = actions.filter(({ hidden }) => !(isFunction(hidden) ? hidden(data) : hidden))

  return visibleActions.reduce(
    (tempActions: any[], currentAction: RowAction | HeaderAction) => {
      const isInDropdown = currentAction.inDropdown
      const isHeaderActionInDropDown = actionType === ActionType.Header && visibleActions.length > MAX_STANDARD_ACTIONS_COUNT ? isInDropdown : false
      const isRowActionInDropDown = actionType === ActionType.Row ? isInDropdown : false
      const folderOrTagActions = actionType === ActionType.FolderOrTag ? isInDropdown : false

      return isHeaderActionInDropDown || isRowActionInDropDown || folderOrTagActions
        ? [tempActions[0], [...tempActions[1], currentAction]]
        : [[...tempActions[0], currentAction], tempActions[1]]
    },
    [[], []]
  )
}

const rootClass = 'table-action-header'

const TableActionHeader: FC<Props> = (props: Props) => {
  const {
    count,
    total,
    actions,
    onChange,
    onDeselectAll,
    useStickyActionHeader = false,
    dataTest = rootClass,
    selectedRows,
    selectedRowsCount,
  } = props
  const { t } = useTranslation()

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const [primaryActions, secondaryActions]: HeaderAction[][] = useMemo(
    () => splitActions(actions, selectedRows, ActionType.Header),
    [actions, selectedRows]
  )
  const renderDelete = ({ label: labelAction, onClick }: HeaderAction) => {
    const label = isFunction(labelAction) ? labelAction() : labelAction
    return (
      <Button buttonType={ButtonType.DELETE} className={`${rootClass}__delete`} onClick={onClick}>
        {label}
      </Button>
    )
  }
  const renderAction = ({
    disabled = false,
    label: labelAction,
    icon: iconAction,
    iconSize,
    hasTooltip,
    onClick: onClickItem,
    deselectAllAction,
  }: HeaderAction) => {
    const svgType = iconSize ? iconSize : hasTooltip ? SvgType.LARGER_ICON : SvgType.ICON
    const icon = isFunction(iconAction) ? iconAction() : <Svg name={iconAction as SvgNames} type={svgType} />
    const label = isFunction(labelAction) ? labelAction() : labelAction
    const buttonDataTest = `${dataTest}-${toTrainCase(label as string)}-button`
    const isDisabled = isFunction(disabled) ? disabled() : disabled
    const onClick = !isDisabled ? onClickItem : undefined
    return (
      <Fragment key={label}>
        {hasTooltip ? (
          <Tooltip
            sideOffset={6}
            trigger={
              <Button disabled={isDisabled} buttonType={ButtonType.TABLE_ACTION} onClick={onClick} dataTest={buttonDataTest}>
                {icon && icon}
              </Button>
            }
          >
            {t(label)}
          </Tooltip>
        ) : (
          <Button
            disabled={isDisabled}
            buttonType={ButtonType.FLOAT}
            onClick={(e) => {
              deselectAllAction && onDeselectAll && onDeselectAll()
              onClick && onClick(e)
            }}
            dataTest={buttonDataTest}
          >
            {icon && icon} {t(label)}
          </Button>
        )}
      </Fragment>
    )
  }

  const renderComponent = ({ embeddedComponent }: HeaderAction, index: number) => <Fragment key={index}>{embeddedComponent}</Fragment>

  const dropdownActions = secondaryActions.map(({ disabled, tooltipMessage, hasTooltip, icon, label, onClick, hidden }) => {
    return { disabled, tooltipMessage, hasTooltip, icon, onClick, text: label, hidden } as MenuItem
  })

  const isDeleteOnly = (action: HeaderAction) => action.label === 'Delete' && !action.hasTooltip

  const renderPrimaryActions = useMemo(
    () =>
      primaryActions.map((action, index) =>
        action.embeddedComponent
          ? renderComponent(action, index)
          : isDeleteOnly(primaryActions[0])
          ? renderDelete(action as HeaderAction)
          : renderAction(action as HeaderAction)
      ),
    [primaryActions]
  )

  return count === 0 ? null : (
    <div className={classNames(rootClass, { [`${rootClass}-sticky`]: useStickyActionHeader })} data-test={dataTest}>
      <div className={classNames(`${rootClass}__checkbox`, { [`${rootClass}-sticky__checkbox`]: useStickyActionHeader })}>
        <IndeterminateCheckbox
          indeterminate={total !== count}
          checked={total === count}
          onChange={onChange}
          dataTest={`${dataTest}-indeterminate-checkbox`}
        />
      </div>
      <Typography
        text={`${selectedRowsCount ? selectedRowsCount : count} ${t(count > 1 ? 'items selected' : 'item selected')}`}
        type={TextType.BODY_TEXT_WHITE}
        className={classNames(`${rootClass}__count`, { [`${rootClass}-sticky__count`]: useStickyActionHeader })}
        weight={TextWeight.MEDIUM}
      />

      <div className={classNames(`${rootClass}__actions`, { [`${rootClass}-sticky__actions`]: useStickyActionHeader })}>
        {renderPrimaryActions}
        {secondaryActions.length > 0 && (
          <DropDown
            className={classNames(`${rootClass}__actions-drop-down`, { [`${rootClass}__actions-drop-down-open`]: dropdownOpen })}
            isOpen={dropdownOpen}
            toggleOpen={setDropdownOpen}
            hasOverflowIcon
            sideOffset={8}
            type={DropDownType.STYLED}
          >
            <DropDownActions menuItems={dropdownActions} closeDropDown={() => setDropdownOpen(false)} />
          </DropDown>
        )}
      </div>
    </div>
  )
}

export default TableActionHeader
