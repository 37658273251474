import { gql } from '@apollo/client'

export default gql`
  query getFormContacts($formId: String!, $search: String, $pageNumber: Int, $pageSize: Int, $sortColumn: String, $sortDirection: Direction) {
    getFormContacts(
      formId: $formId
      search: $search
      pageNumber: $pageNumber
      pageSize: $pageSize
      sortColumn: $sortColumn
      sortDirection: $sortDirection
    ) {
      formContacts {
        id
        name
        email
        lastViewedAt
        submittedAt
        referringUrl
        formUrl
      }
      page
      totalPages
      perPage
      totalRecords
      sortDirection
      sortColumn
    }
  }
`
