import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Dots, { LoaderSize } from '@components/Dots/Dots'
import Input, { InputProps, InputSize, InputType } from '@components/Input/Input'
import Svg, { SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'

import './InputWithClear.css'

export type InputWithClearProps = {
  inputProps?: InputProps
  onClear?: () => void
  showClearOverride?: boolean
  clearIndicatorFill?: SvgColor
  dataTest?: string
  isLoading?: boolean
}

interface State {
  showClear: boolean
}

const rootClass = 'input-with-clear'

/**
 * @deprecated use <InputV2 instead
 */
const InputWithClear: FC<InputWithClearProps> = (props: InputWithClearProps) => {
  const { inputProps, onClear, showClearOverride, clearIndicatorFill, isLoading } = props
  const { label, onChange, size, value, dataTest = rootClass, className = '' } = inputProps || {}
  const [state, setState] = useState<State>({ showClear: false })
  const { t } = useTranslation()

  useEffect(() => setState({ showClear: showClearOverride != undefined ? showClearOverride : value !== '' }), [value, showClearOverride])

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (showClearOverride == undefined) {
      setState({ ...state, showClear: event.target.value !== '' })
    }
    if (onChange) {
      onChange(event)
    }
  }

  const onClearHandler = () => {
    if (onClear) {
      onClear()
    }
    setState({ showClear: false })
  }

  const onKeyPressHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (inputProps?.onKeyPress) {
      inputProps.onKeyPress(event)
    }
  }

  const onKeyDownHandlerSVG = (event: React.KeyboardEvent<SVGSVGElement>) => {
    if (event.keyCode === 32) {
      onClearHandler()
    }
  }

  return (
    <div
      className={classNames(rootClass, className, {
        [`${rootClass}--has-loading`]: isLoading,
        [`${rootClass}--small`]: props.inputProps?.size === InputSize.SMALL,
      })}
      data-test={dataTest}
    >
      <Input {...inputProps} onChange={onChangeHandler} onKeyPress={onKeyPressHandler} data-test={`${dataTest}__input`}></Input>
      {isLoading && state.showClear && (
        <Dots size={LoaderSize.SMALL} className={classNames(`${rootClass}__loader`, { [`${rootClass}__loader--labelled`]: !!label })} />
      )}
      <div
        className={classNames(`${rootClass}__clear-button-divider`, {
          [`${rootClass}__clear-button-divider-visible`]: state.showClear && props.inputProps?.size !== InputSize.SMALL,
          [`${rootClass}__clear-button-divider-labelled`]: label,
        })}
      ></div>
      <Button
        buttonType={ButtonType.DEFAULT}
        className={classNames(`${rootClass}__clear-button`, {
          [`${rootClass}__clear-button-labelled`]: label,
          [`${rootClass}__clear-button-small`]: size === InputSize.SMALL,
          [`button--float--quantity`]: inputProps?.inputType === InputType.QUANTITY,
          [`${rootClass}__clear-button-visible`]: state.showClear,
        })}
        dataTest={`${dataTest}-clear`}
        onClick={onClearHandler}
        tabIndex={-1}
        noPadding
      >
        <Tooltip
          inline={false}
          sideOffset={3}
          trigger={
            <div className={`${rootClass}__clear-button-svg-wrapper`}>
              <Svg name={SvgNames.clearIndicator} type={SvgType.ICON} tabIndex={0} onKeyDown={onKeyDownHandlerSVG} fill={clearIndicatorFill} />
            </div>
          }
        >
          {t('Clear search')}
        </Tooltip>
      </Button>
    </div>
  )
}

export default InputWithClear
