import React, { FC, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { Column } from '@components/ColumnsOrderModal/components/DraggableColumn/DraggableColumn'
import ContactsDetailsContainer from '@components/ContactsDetails/ContactsDetailsContainer'
import DropDown from '@components/DropDown'
import { DropDownType } from '@components/DropDown/DropDown'
import DropDownActions, { MenuItem } from '@components/DropDownActions/DropDownActions'
import { CardsOptions } from '@components/InfoCard/InfoCard'
import ItemActivity, { ActivityTypes, SectionType } from '@components/ItemActivity/ItemActivity'
import { getSegmentActivities } from '@components/ItemActivity/ItemActivityUtils'
import PageContainer from '@components/PageContainer'
import PageHeader from '@components/PageHeader'
import { SegmentDetailsContainerState } from '@components/SegmentDetails/context/SegmentDetails.context'
import {
  DownloadCSVOptions,
  getAllSegmentStats,
  getModalRowItems,
  isNonSendableSegment,
  segmentPerformanceCards,
  sendEmailToSegment,
} from '@components/SegmentDetails/SegmentDetails.utils'
import { FilesData } from '@components/SegmentDetails/SegmentDetailsContainer'
import SelectAssetV2 from '@components/SelectAssetV2/SelectAssetV2'
import { Status } from '@components/StatusToast/StatusToast'
import { SvgNames } from '@components/Svg'
import { HeaderAction, RowAction } from '@components/Table/Table'
import Tabs, { TabItemData } from '@components/TabsAO/TabsAO'
import { useTranslation } from '@const/globals'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { PageInput, SegmentActivityDto, StatValueDto } from '@graphql/types/microservice/segment-types'
import { AccountSettings } from '@graphql/types/query-types'
import { Row } from '@tanstack/react-table'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import { Contact } from '@utils/contact/contact.constants'
import { getListDownloadLink, saveSelectedListsToLocalStorage, Segment } from '@utils/contactSegments/contactSegments.utils'
import { FileUplaodModalType } from '@utils/formUtils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { download } from '@utils/utils'

import './SegmentDetails.css'

interface Props {
  className?: string
  dataTest?: string
  segment: Segment
  itemType: ItemType
  contactsTableLabelSuffix?: string
  returnLink: string
  canExport?: boolean
  canSendMessages?: boolean
  customDropdownItems?: (
    segment: Segment,
    update: (values: Partial<SegmentDetailsContainerState>) => void,
    itemType: ItemType,
    accountSettings: AccountSettings
  ) => MenuItem[]
  customTabsTitles?: { contacts?: string; details?: string }
  customContactsTableHeaderActions?: (selectedContacts: Contact[], unifiedListFieldMappings: UnifiedListFieldMapping[]) => HeaderAction[]
  customContactsTableRowActions?: (
    unifiedListFieldMappings: UnifiedListFieldMapping[],
    contactHeaders?: Column[],
    newFormFileUploadBlock?: boolean,
    segmentContacts?: PageInput,
    userAllowedToDownload?: boolean
  ) => RowAction[]
  customPerformanceCards?: CardsOptions
  activityCustomSections?: SectionType[]
  onItemFolderChange: (folderId: number) => void
  onApplyAndRemoveTagsSuccess: (updatedTags: LabelDto[]) => void
  onContactRowClicked?: (row: Row<Contact>, uuid: string) => void
  update: (values: Partial<SegmentDetailsContainerState>) => void
  segmentContacts: PageInput
  pageHeader?: string
  previousCount?: number
  toggleDownloadModal?: (type: FileUplaodModalType, contact: string[], headerText?: string) => void
  handleDownloadOrPreviewFile?: (fileIds: number[], type?: FileUplaodModalType, initialAttachmentsData?: FilesData[]) => void
}

interface SegmentDetailsState {
  dropdownOpen: boolean
  currentTab: TABS
  exportedFile: string
  selectedContacts: Contact[]
  sendableContacts: StatValueDto
}

const rootClass = 'segment-details'

enum TABS {
  CONTACTS_DETAILS = 'contacts-details',
  SEGMENT_ACTIVITY = 'segment-activity',
}

const SegmentDetails: FC<Props> = (props: Props) => {
  const {
    dataTest = rootClass,
    segment,
    canExport = true,
    canSendMessages = true,
    className = '',
    itemType,
    activityCustomSections,
    returnLink,
    customTabsTitles,
    contactsTableLabelSuffix,
    customDropdownItems,
    customContactsTableRowActions,
    customPerformanceCards,
    customContactsTableHeaderActions,
    onItemFolderChange,
    onApplyAndRemoveTagsSuccess,
    onContactRowClicked,
    segmentContacts,
    update,
    pageHeader,
    previousCount,
    toggleDownloadModal,
    handleDownloadOrPreviewFile,
  } = props

  const [state, setState] = useState<SegmentDetailsState>({
    dropdownOpen: false,
    currentTab: TABS.CONTACTS_DETAILS,
    exportedFile: '',
    selectedContacts: [],
    sendableContacts: { value: 0, percentageValue: 0 },
  })

  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false)

  const { currentTab, dropdownOpen, exportedFile, selectedContacts, sendableContacts } = state

  const accountSettings = useAccountSettings()
  const { userAllowedToDownload, newFormFileUploadBlock } = accountSettings
  const { t } = useTranslation()
  const totalCount = segmentContacts?.totalCount || 0
  const isBounceSegment = useMemo(() => itemType === ItemType.BOUNCE, [segment])
  const { client: segmentClient } = useMicroserviceClient({ serviceName: MicroserviceClients.SEGMENT })

  const onHeaderActionClick = (dropdownOpen: boolean) => setState((state) => ({ ...state, dropdownOpen: dropdownOpen }))

  useEffect(() => {
    if (exportedFile !== '') {
      update({
        statusToast: {
          statusMessage: t(`ListPage.Common.Export`, { count: totalCount }),
          status: Status.SUCCESS,
          showStatusToast: true,
        },
      })
      download(exportedFile, exportedFile.substr(exportedFile.lastIndexOf('/') + 1))
      setState((state) => ({ ...state, exportedFile: '' }))
    }
  }, [exportedFile])

  useEffect(() => {
    if (!customPerformanceCards) {
      getAllSegmentStats(segment.externalId, segmentClient, update)
    }
  }, [segment.externalId])

  const segmentDetailsMenuItems = [
    ...((customDropdownItems && customDropdownItems(segment, update, itemType, accountSettings)) ?? []),
    ...(canExport
      ? [
          {
            disabled: !userAllowedToDownload,
            hasTooltip: !userAllowedToDownload,
            tooltipMessage: 'Ask your administrator for permission to do this',
            icon: newFormFileUploadBlock ? SvgNames.download : SvgNames.export,
            text: newFormFileUploadBlock ? 'Download' : 'Export as CSV',
            onClick: () => (newFormFileUploadBlock ? setShowDownloadModal(true) : doExport()),
          },
        ]
      : []),
  ]

  const useRenderActivityCol = (row: any) => {
    const { original: activity } = row as Row<SegmentActivityDto>
    const segmentActivities = useMemo(() => getSegmentActivities(t, !!segment.parent, itemType), [segment])
    const activityType = (activity.type || '') as ActivityTypes
    return segmentActivities.hasOwnProperty(activityType) ? segmentActivities[activityType](activity) : ''
  }

  const performanceCards = useMemo(() => segmentPerformanceCards(segment.recordsCount, sendableContacts, t), [sendableContacts])

  const itemActivityTagsProps = {
    onApplyAndRemoveTagsSuccess: onApplyAndRemoveTagsSuccess,
  }

  const listingTabs: TabItemData[] = [
    {
      index: TABS.CONTACTS_DETAILS,
      label: t(customTabsTitles?.contacts || 'Contacts'),
      content: (
        <ContactsDetailsContainer
          segment={segment}
          segmentContacts={segmentContacts}
          customRowActions={customContactsTableRowActions}
          customHeaderActions={customContactsTableHeaderActions}
          onContactsLoaded={(contacts) => update({ segmentContacts: contacts })}
          contactsTableLabelSuffix={contactsTableLabelSuffix}
          onContactRowClicked={onContactRowClicked}
          onContactsSelected={(selectedContacts) => setState((state) => ({ ...state, selectedContacts }))}
          itemType={itemType}
          previousCount={previousCount}
          toggleDownloadModal={toggleDownloadModal}
          handleDownloadOrPreviewFile={handleDownloadOrPreviewFile}
          selectedContacts={selectedContacts}
          resetSelectionWhenPageChanges
        />
      ),
    },
    {
      index: TABS.SEGMENT_ACTIVITY,
      label: t(customTabsTitles?.details || 'Segment Details'),
      content: (
        <ItemActivity
          segment={segment}
          itemType={itemType}
          onItemFolderChange={onItemFolderChange}
          customSections={activityCustomSections}
          renderActivityCol={useRenderActivityCol}
          performanceCards={customPerformanceCards ?? performanceCards}
          {...itemActivityTagsProps}
        />
      ),
    },
  ]

  const handleOnChange = (currentTab: string) => setState((state) => ({ ...state, currentTab: currentTab as TABS }))
  const doExport = (isZip?: boolean) => setState((state) => ({ ...state, exportedFile: getListDownloadLink(segment, isZip) }))
  const closeMessageDropDown = () => setState((state) => ({ ...state, dropdownOpen: !dropdownOpen }))

  return (
    <>
      {showDownloadModal && (
        <SelectAssetV2
          primaryButtonText={t('Download')}
          isOpen
          className={`${rootClass}__download-modal`}
          headerText={t('Download')}
          subHeaderText={t('ListPage.FormsJoinView.DownloadModal.ExtraContent')}
          onAction={(row) => {
            const isZipDownload = row === DownloadCSVOptions.DOWNLOAD_AS_CSV_WITH_UPLOADED
            doExport(isZipDownload)
            setShowDownloadModal(false)
          }}
          rowItems={getModalRowItems(t)}
          onCancel={() => setShowDownloadModal(false)}
          initialSelectedAsset={DownloadCSVOptions.DOWNLOAD_AS_CSV}
        />
      )}
      <PageContainer className={classNames(rootClass, className)} dataTest={dataTest}>
        <PageHeader
          primaryText={pageHeader ?? segment.name}
          linkUrl={returnLink}
          linkOptions={{ state: 'details' }}
          leftContent
          className={`${rootClass}__header`}
        >
          {!(isBounceSegment && isNonSendableSegment(segment)) && canSendMessages && (
            <Button
              className={`${rootClass}__send-message-button`}
              buttonType={ButtonType.PRIMARY}
              onClick={() => {
                saveSelectedListsToLocalStorage([segment])

                sendEmailToSegment(segment.externalId)
              }}
            >
              {t('Send message')}
            </Button>
          )}
          {segmentDetailsMenuItems.length > 0 && (
            <DropDown
              className={classNames({ [`${rootClass}__drop-down-open`]: dropdownOpen })}
              hasOverflowIcon
              isOpen={dropdownOpen}
              toggleOpen={onHeaderActionClick}
              type={DropDownType.STYLED}
            >
              <DropDownActions className={`${rootClass}__drop-down`} menuItems={segmentDetailsMenuItems} closeDropDown={closeMessageDropDown} />
            </DropDown>
          )}
        </PageHeader>
        {isBounceSegment ? (
          <ContactsDetailsContainer
            itemType={ItemType.BOUNCE}
            segment={segment}
            segmentContacts={segmentContacts}
            onContactsLoaded={(contacts) => update({ segmentContacts: contacts })}
            isBounceSegment
            onContactRowClicked={onContactRowClicked}
            onContactsSelected={(selectedContacts) => setState((state) => ({ ...state, selectedContacts }))}
            previousCount={previousCount}
            selectedContacts={selectedContacts}
            resetSelectionWhenPageChanges
          />
        ) : (
          <Tabs key={currentTab} childData={listingTabs} defaultValue={currentTab} onChange={handleOnChange} />
        )}
      </PageContainer>
    </>
  )
}

export default SegmentDetails
