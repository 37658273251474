import React, { FC, useMemo } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Svg, { SvgType } from '@components/Svg'
import { RowAction } from '@components/Table/Table'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'
import { toTrainCase } from '@utils/strings'
import { isFunction } from '@utils/utils'

import './TableRowPrimaryAction.css'

interface Props {
  dataTest?: string
  rowAction: RowAction
  row: any
}

const rootClass = 'table-row-primary-action'

const TableRowPrimaryAction: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, rowAction, row } = props

  const { t } = useTranslation()

  const getActionProps = ({ disabled, icon, label, tooltipMessage, hidden }: RowAction) => {
    const isHidden = isFunction(hidden) ? hidden(row) : hidden
    const isDisabled = isFunction(disabled) ? disabled(row) : disabled
    const actionIcon = isFunction(icon)
      ? icon(row)
      : icon && (
          <Svg
            className={classNames({
              [`${rootClass}__svg-disabled`]: isDisabled,
            })}
            name={icon}
            type={SvgType.LARGER_ICON}
          />
        )
    const actionLabel = t(isDisabled ? tooltipMessage : isFunction(label) ? label(row) : label)
    return { isHidden, isDisabled, icon: actionIcon, label: actionLabel }
  }

  const { onClick, link, isHidden, isDisabled, icon, label } = useMemo(() => ({ ...rowAction, ...getActionProps(rowAction) }), [rowAction, row])

  if (isHidden) {
    return null
  }

  return (
    <Tooltip
      triggerClassName={`${rootClass}__tooltip`}
      disabledTrigger={isDisabled}
      position={'top'}
      sideOffset={3}
      arrowOffset={10}
      trigger={
        <Button
          disabled={isDisabled}
          dataTest={`${dataTest}-${toTrainCase(label)}-button`}
          className={classNames(`${rootClass}__button`, { [`${rootClass}__button-disabled`]: isDisabled })}
          buttonType={ButtonType.TABLE_ACTION}
          onClick={() => onClick(row)}
          isLink={!!link}
          to={link}
        >
          {icon && icon}
        </Button>
      }
    >
      {label}
    </Tooltip>
  )
}

export default TableRowPrimaryAction
