import { gql } from '@apollo/client'
import { allListsChildren } from '@graphql/queries/fragments/lists'

export default gql`
  ${allListsChildren}

  query FoldersWithCount($type: String!, $noMirroredJoinLists: Boolean) {
    foldersWithCount(type: $type, noMirroredJoinLists: $noMirroredJoinLists) {
      name
      id
      count
      isSpecial
      isDefault
      isContacts
      entries {
        ...AllListsChildren
      }
    }
  }
`
