import { SvgNames } from '@components/Svg'
import { CookieMode } from '@graphql/types/query-types'

export const COOKIE_MODE: { [key: string]: CookieMode } = {
  standard: 'STANDARD',
  noCookie: 'NOCOOKIES',
}

export const BEACON_STATUS_TEXT = {
  active: 'Your beacon is actively collecting visitor data',
  inactive: 'Complete the steps below to track your visitors',
}

export const BEACON_STATUS_SVG_NAMES = {
  standard: SvgNames.connectionStatusGreen,
  noCookie: SvgNames.connectionStatusRed,
}

export const BEACON_SETTINGS_MORE_INFO = 'https://connect.act-on.com/hc/en-us/articles/360023755134-Beacon-Tracker-Overview'
export const BEACON_SETTINGS_LINK = 'https://connect.act-on.com/hc/en-us/articles/360023758034-Integrating-a-Tag-Manager-and-Cookie-Consent-Provider'
export const BEACON_SETTINGS_INTERNAL_LINK = '/app/classic/if/_wvt/index.jsp'
