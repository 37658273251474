import React, { ReactNode } from 'react'

import { TFunction } from 'i18next'

import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { SvgNames } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography, { TextAlign, TextType, TextWeight } from '@components/Typography/Typography'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { SegmentDefinitionResponse } from '@graphql/types/microservice/segment-types'
import { MappingFieldState } from '@src/pages/ContactSegments/components/CopySegments/components/StepThree/components/MappingField/MappingField'
import {
  FieldStatusType,
  MappedField,
  SegmentWithFilterExpression,
} from '@src/pages/ContactSegments/components/CopySegments/context/CopySegments.context'
import { DataTypeList, DateFormatDisplay, DateTimeFormatDisplay } from '@src/pages/importcontacts/context/ImportContactsContext'

const fieldStatusText = 'Copy.Segments.Page.Step.Three.Field.Status.'

export interface FieldStatusValues {
  name: SvgNames
  color: SvgColor
  message: string | ReactNode
  type: FieldStatusType
}

export const FieldStatusValues: { [key in FieldStatusType]: FieldStatusValues } = {
  [FieldStatusType.SUCCESS]: {
    name: SvgNames.checkCircle,
    color: SvgColor.SUCCESS_GREEN,
    message: `${fieldStatusText}Success`,
    type: FieldStatusType.SUCCESS,
  },
  [FieldStatusType.DUPLICATE]: {
    name: SvgNames.cancelSend,
    color: SvgColor.ERROR,
    message: `${fieldStatusText}Duplicated`,
    type: FieldStatusType.DUPLICATE,
  },
  [FieldStatusType.EMPTY_DATA_TYPE]: {
    name: SvgNames.cancelSend,
    color: SvgColor.ERROR,
    message: `${fieldStatusText}EmptyDataType`,
    type: FieldStatusType.EMPTY_DATA_TYPE,
  },
  [FieldStatusType.EMPTY_DATA_FORMAT]: {
    name: SvgNames.cancelSend,
    color: SvgColor.ERROR,
    message: `${fieldStatusText}EmptyDataFormat`,
    type: FieldStatusType.EMPTY_DATA_FORMAT,
  },
  [FieldStatusType.EMPTY_NEW_FIELD]: {
    name: SvgNames.cancelSend,
    color: SvgColor.ERROR,
    message: `${fieldStatusText}EmptyNewField`,
    type: FieldStatusType.EMPTY_NEW_FIELD,
  },
  [FieldStatusType.NEW_FIELD_DELETED]: {
    name: SvgNames.warning,
    color: SvgColor.YELLOW_CAUTION,
    message: `${fieldStatusText}Deleted`,
    type: FieldStatusType.NEW_FIELD_DELETED,
  },
  [FieldStatusType.NEW_FIELD_IN_USE]: {
    name: SvgNames.cancelSend,
    color: SvgColor.ERROR,
    message: `${fieldStatusText}NewInUse`,
    type: FieldStatusType.NEW_FIELD_IN_USE,
  },
  [FieldStatusType.SEGMENT_WITH_UNMAPPED_FIELDS]: {
    name: SvgNames.warning,
    color: SvgColor.YELLOW_CAUTION,
    message: '',
    type: FieldStatusType.SEGMENT_WITH_UNMAPPED_FIELDS,
  },
}

export const getDataTypeFormatOptionsUtils = (dataType: string) =>
  (DataTypeList.find(({ id }) => id === dataType)?.formatOptions || []).map((option) => ({
    label: dataType === 'DATE' ? DateFormatDisplay[option] : dataType === 'DATETIME' ? DateTimeFormatDisplay[option] : option,
    value: option,
  }))

interface ValidateExistingFieldParams {
  allContactsField?: string
  mappedFields: MappedField[]
}

const validateExistingField = ({ allContactsField, mappedFields }: ValidateExistingFieldParams) => {
  const isDuplicate = mappedFields.some((mappedField) => !!mappedField.allContactsField && mappedField.allContactsField === allContactsField)
  return isDuplicate ? FieldStatusValues[FieldStatusType.DUPLICATE] : FieldStatusValues[FieldStatusType.SUCCESS]
}

interface ValidateNewFieldParams extends ValidateExistingFieldParams {
  state: MappingFieldState
  allContactsFieldsOptions: SelectV2SingleOption[]
  deletedAllContactsFields: UnifiedListFieldMapping[]
}

const validateNewField = ({ state, allContactsField, allContactsFieldsOptions, deletedAllContactsFields, mappedFields }: ValidateNewFieldParams) => {
  const customFieldInUse = allContactsFieldsOptions.some((option) => option.label === allContactsField)
  if (customFieldInUse) {
    return FieldStatusValues[FieldStatusType.NEW_FIELD_IN_USE]
  }

  if (allContactsField === '') {
    return FieldStatusValues[FieldStatusType.EMPTY_NEW_FIELD]
  }

  const isDuplicate = mappedFields.some((mappedField) => !!mappedField.allContactsField && mappedField.allContactsField === allContactsField)
  if (isDuplicate) {
    return FieldStatusValues[FieldStatusType.DUPLICATE]
  }

  const { selectedDataFormatOption, selectedDataTypeOption } = state

  if (!selectedDataTypeOption) {
    return FieldStatusValues[FieldStatusType.EMPTY_DATA_TYPE]
  }

  if (['DATE', 'DATETIME'].includes(selectedDataTypeOption.value) && !selectedDataFormatOption) {
    return FieldStatusValues[FieldStatusType.EMPTY_DATA_FORMAT]
  }

  const isDeleted = deletedAllContactsFields.some((deletedField) => deletedField.displayName === allContactsField)
  if (isDeleted) {
    return FieldStatusValues[FieldStatusType.NEW_FIELD_DELETED]
  }

  return FieldStatusValues[FieldStatusType.SUCCESS]
}

type ValidateFieldParams = ValidateExistingFieldParams & ValidateNewFieldParams

export const validateField = ({ state, allContactsField, mappedFields, allContactsFieldsOptions, deletedAllContactsFields }: ValidateFieldParams) => {
  const { isNew } = state
  if (isNew) {
    return validateNewField({ state, allContactsField, allContactsFieldsOptions, deletedAllContactsFields, mappedFields })
  } else if (!!allContactsField) {
    return validateExistingField({ allContactsField, mappedFields })
  }
}

interface CheckIfHasSegmentWithUnmappedFieldsParams {
  segmentsWithFilterExpressions: SegmentWithFilterExpression[]
  marketingListFieldProp: string
  segmentNamesByField: SegmentDefinitionResponse[]
  fieldNamesBySegment: { [key: string]: string[] }
  mappedFields: MappedField[]
  t: TFunction
}

export const getUnmappedFieldsWarning = ({
  segmentsWithFilterExpressions,
  segmentNamesByField,
  fieldNamesBySegment,
  mappedFields,
  marketingListFieldProp,
  t,
}: CheckIfHasSegmentWithUnmappedFieldsParams) => {
  const fieldFound = segmentNamesByField.find(({ fieldName }) => fieldName === marketingListFieldProp)
  if (!!fieldFound) {
    const warningSegments = fieldFound.segmentInfo?.filter((segmentInfo) => {
      if (segmentsWithFilterExpressions.find(({ segmentId }) => segmentId === segmentInfo?.segmentId)?.value) {
        return false
      }
      const fieldNamesBySegmentFound = Object.entries(fieldNamesBySegment).find(([segment]) => segment === segmentInfo?.segmentId)
      if (fieldNamesBySegmentFound && fieldNamesBySegmentFound.length > 1) {
        return !fieldNamesBySegmentFound[1].some((field) => {
          const foundField = mappedFields.find(({ marketingListField }) => marketingListField === field)
          return !!foundField?.allContactsField
        })
      }
    })

    return !!warningSegments && warningSegments?.length > 0
      ? {
          ...FieldStatusValues[FieldStatusType.SEGMENT_WITH_UNMAPPED_FIELDS],
          message: (
            <>
              <Typography
                className={'step-three-mapping-field__tooltip-header'}
                type={TextType.SECTION_HEADER}
                weight={TextWeight.BOLD}
                text={t(`${fieldStatusText}SegmentWithUnmappedFields`)}
              />
              {warningSegments.map((segment) => (
                <Typography key={segment?.segmentId} textAlign={TextAlign.LEFT} type={TextType.BODY_TEXT_WHITE_REGULAR} text={segment?.segmentName} />
              ))}
            </>
          ),
        }
      : undefined
  }
}
