import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
dayjs.extend(quarterOfYear)

const prevMonthEnd = dayjs().startOf('month').subtract(1, 'day')
const yesterday = dayjs().subtract(1, 'day').endOf('day')
const prevQuarterEnd = dayjs()
  .startOf('year')
  .add((dayjs().quarter() - 1) * 3, 'month')
  .subtract(1, 'day')

export const dateRangePresets: {
  label: string
  value: [Dayjs, Dayjs]
}[] = [
  { label: 'Previous Month', value: [prevMonthEnd.startOf('month'), prevMonthEnd.endOf('month')] },
  { label: 'Previous Quarter', value: [prevQuarterEnd.subtract(2, 'month').startOf('month'), prevQuarterEnd.endOf('month')] },
  { label: 'Previous Year', value: [dayjs().subtract(1, 'year').startOf('day'), yesterday] },
]

export const rangePickerThemeProps = {
  fontFamily: 'Open Sans',
  colorTextDisabled: '#AEAEAE',
  colorPrimary: '#007B80',
  colorPrimaryBg: '#E5F9F9',
  colorTextBase: '#444444',
  colorTextPlaceholder: '#767676',
  colorBorder: '#E3E3E3',
  colorBorderSecondary: '#E3E3E3',
  borderRadius: 4,
}

export const dateRangePickerDefaultFormat = 'MMM D, YYYY'
