import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/Button'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './AddButton.css'
export type TooltipPosition = 'left' | 'top' | 'right' | 'bottom' | undefined
interface AddButtonProps {
  className?: string
  dataTest?: string
  onClick?: () => void
  disabled?: boolean
  label?: string
  isDelete?: boolean
  tooltip?: string
  position?: TooltipPosition
}

const rootClass = 'add-button'

const AddButton: FC<AddButtonProps> = (props: AddButtonProps) => {
  const { dataTest = rootClass, className = '', disabled = false, onClick, label, isDelete = false, tooltip, position = 'right' } = props

  const { t } = useTranslation()

  return (
    <Tooltip
      hide={!tooltip}
      disabledTrigger={disabled}
      position={position}
      trigger={
        <Button
          buttonType={ButtonType.ICON}
          className={classNames(rootClass, {
            [`${rootClass}__no-label`]: !label,
            [`${rootClass}__delete`]: isDelete,
            [`${rootClass}__disabled`]: disabled,
            [className]: className,
          })}
          dataTest={dataTest}
          disabled={disabled}
          onClick={() => !disabled && onClick?.()}
        >
          <Svg name={isDelete ? SvgNames.deleteRounded : SvgNames.addRounded} type={SvgType.LARGER_ICON} />
          {label && <Typography text={t(label)} type={TextType.NORMAL_TEXT_TEAL_LARGE} weight={TextWeight.MEDIUM} />}
        </Button>
      }
    >
      {tooltip}
    </Tooltip>
  )
}

export default AddButton
