import React, { useCallback } from 'react'
import { useHistory } from 'react-router'

import Button, { ButtonType } from '@components/Button'
import { MenuItem } from '@components/DropDownActions/DropDownActions'
import { Status } from '@components/StatusToast/StatusToast'
import { SvgNames } from '@components/Svg'
import { RowAction } from '@components/Table/Table'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { confirmPlainTextEdit } from '@src/pages/EmailComposer/components/EmailComposerPreview/EmailComposerPreview.render.utils'
import {
  actions,
  ComposerPreviewActions,
  downloadHtml,
  printHtml,
} from '@src/pages/EmailComposer/components/EmailComposerPreview/EmailComposerPreview.utils'
import { hasDisplayConditions } from '@src/pages/EmailComposer/utils/DisplayConditions.utils'
import { detectEmailType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'
import { ComposerHistoryState, EmailPreviewPlatform } from '@utils/composer/context/EmailComposer.context'
import { legacyActonContext, useTranslation } from '@utils/const/globals'

import EmailComposerPreviewActions from './components/EmailComposerPreviewActions/EmailComposerPreviewActions'
import EmailComposerPreviewTabs from './components/EmailComposerPreviewTabs/EmailComposerPreviewTabs'

import './EmailComposerPreviewHeader.css'

const rootClass = 'email-composer-preview-header'

const EmailComposerPreviewHeader = () => {
  const {
    values: {
      preview: { html, platform },
      message,
      disabledFeatures,
      isCoeditingPrimaryUser,
      messageConfiguration,
      landingPage: { isLandingPage },
      messageConfiguration: { messageType },
      haveUnsavedChanges,
      detectedURLChanges: { plainTextMode, uploadHtmlMode },
    },
    api: { update, updatePreview, onTest, updateModal, onSave, onEditPlainText, onTabChange },
  } = useComposerContext()

  const separateSaveAndClose = messageConfiguration?.saveButton?.separateSaveAndClose

  const { accountId, connections } = useAccountSettings()
  const history = useHistory<ComposerHistoryState>()
  const { t } = useTranslation()

  const { isEmailBlankMessage } = detectEmailType(messageType)

  const tabs = [
    { label: 'Desktop', value: 'desktop', disabled: false },
    { label: 'Mobile', value: 'mobile', disabled: false },
    ...(!isLandingPage ? [{ label: 'Inbox', value: 'inbox', disabled: !connections?.litmus }] : []),
  ]

  const showSuccessToast = () => {
    update({
      modalState: {
        statusToast: {
          status: Status.SUCCESS,
          message: <Typography text={t('EmailComposer.Preview.Download.Success.Toast')} inline tagProps={{ bold: { weight: TextWeight.BOLD } }} />,
        },
      },
    })
  }

  const handleClickAction = (action?: string) => {
    switch (action) {
      case ComposerPreviewActions.VIEW_HTML_CODE:
        updatePreview({ isCodeModalOpen: true })
        break
      case ComposerPreviewActions.EDIT_PLAIN_TEXT:
        const onConfirm = () => updateModal('editPlainText', true)
        const hasDynamicContent = hasDisplayConditions(message)
        const showWarning = !disabledFeatures.editPlainText && !!message.customTextPart
        if (haveUnsavedChanges) {
          onEditPlainText(onConfirm)
        } else if (hasDynamicContent && !showWarning) {
          confirmPlainTextEdit(updateModal, t, onConfirm)
          break
        } else {
          onConfirm()
        }
        break
      case ComposerPreviewActions.TEST:
        onTest()
        break
      case ComposerPreviewActions.PRINT:
        printHtml(html)
        break
      case ComposerPreviewActions.LIVE_PREVIEW:
        window.open(`${legacyActonContext}/messages/landingPagePreview.jsp?a=${accountId}&p=${message.id}`, '_blank')
        break
    }
  }

  const dropDownContent = (action: string): MenuItem[] | undefined => {
    switch (action) {
      case ComposerPreviewActions.DOWNLOAD:
        return [
          {
            text: 'Download HTML',
            icon: SvgNames.codeIcon,
            hasTooltip: true,
            onClick: () => {
              downloadHtml(html)
              showSuccessToast()
            },
          },
          {
            text: 'Export to PDF',
            icon: SvgNames.pdfFile,
            hasTooltip: true,
            onClick: () => printHtml(html),
          },
        ]
      default:
        return undefined
    }
  }

  const getActionProps = useCallback(
    (action: ComposerPreviewActions): Partial<RowAction> | undefined => {
      if (action === ComposerPreviewActions.EDIT_PLAIN_TEXT) {
        return {
          hidden: disabledFeatures.editPlainText || isLandingPage || isEmailBlankMessage,
          disabled: !isCoeditingPrimaryUser,
          tooltipMessage: !isCoeditingPrimaryUser ? t('EmailComposer.Preview.Plain.Text.Edit.Disabled.Tooltip') : undefined,
        }
      }
    },
    [disabledFeatures, isCoeditingPrimaryUser, t]
  )

  const handleClosePreview = useCallback(() => {
    update({ isPreview: false })
  }, [onTabChange, history])

  return (
    <div className={rootClass} data-test={rootClass}>
      <Typography text={t('Preview mode')} type={TextType.MODAL_HEADLINE} lineHeight={LineHeight.LARGE} />
      <EmailComposerPreviewTabs
        tabs={tabs}
        activeTab={platform}
        haveUnsavedChanges={haveUnsavedChanges ?? false}
        separateSaveAndClose={separateSaveAndClose}
        messageType={messageType}
        webinarScheduledTime={message.webinar?.scheduledTime}
        onChange={(value) => updatePreview({ platform: value as EmailPreviewPlatform })}
        onSave={onSave}
        updateModal={updateModal}
      />
      <div className={`${rootClass}__actions`}>
        <EmailComposerPreviewActions
          messageType={messageType}
          actions={actions(handleClickAction, getActionProps, messageType, plainTextMode ?? false, uploadHtmlMode ?? false, isLandingPage)}
          dropDownContent={dropDownContent}
        />
        <Button buttonType={ButtonType.PRIMARY} onClick={handleClosePreview} dataTest={`${rootClass}-done-button`}>
          {t('Done')}
        </Button>
      </div>
    </div>
  )
}

export default EmailComposerPreviewHeader
