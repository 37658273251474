import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { MIN_TABLE_ROWS_FOR_PAGINATION } from '@const/globals'
import { ProgramContact } from '@graphql/types/query-types'
import { ProgramPerformanceTableContainerState } from '@src/pages/programs/dashboard/components/ProgramPerformance/components/ProgramPerformanceDetail/components/ProgramPerformanceTableContainer/ProgramPerformanceDetailTableContainer'
import {
  allContactsColumns,
  allContactsColumnsV2,
  earlyExitsColumns,
  earlyExitsColumnsV2,
  EMPTY_SEARCH_DIFF,
  EMPTY_TABLE_DIFF,
  ERROR_TABLE_DIFF,
  inProgramColumns,
  inProgramColumnsV2,
  standardExitsColumns,
  standardExitsColumnsV2,
  TABLE_HEIGHT,
  TABLE_ROW_HEIGHT,
} from '@src/pages/programs/dashboard/components/ProgramPerformance/components/ProgramPerformanceDetail/ProgramPerformanceDetail.constants'
import { ProgramPerformanceTab } from '@src/pages/programs/dashboard/components/ProgramPerformance/ProgramPerformance.constants'
import { ProgramManagerLocationStateProps } from '@src/pages/programs/manager/ProgramManager.constants'
import { ProgramStepType, ProgramStepTypeLabel } from '@utils/program/program.constants'
import { ProgramStep } from '@utils/program/ProgramSteps.constants'
import { SortByState } from '@utils/sms.utils'
import { getKeyName, getValueByKey } from '@utils/strings'

export interface CommonContactData {
  name: string
  email: string
  sourceList: string
  recId: string
}

export interface AllContactsData extends CommonContactData {
  entryDate: string
  status: string
}
export interface InProgramContactsData extends CommonContactData {
  entryDate: string
  step: string
  title: string
}
export interface EarlyExitsContactsData extends CommonContactData {
  exitReason: string
  exitDate: string
}
export interface StandardExistsContactsData extends CommonContactData {
  exitDate: string
  step: string
}

const defaultSortBy: SortByState = {
  id: 'entryDate',
  desc: true,
}

export const DEFAULT_STATE: ProgramPerformanceTableContainerState = {
  tableData: [],
  pageIndex: 0,
  pageSize: 10,
  sortBy: [defaultSortBy],
  pageCount: 0,
  searchTerm: '',
  showToastErr: false,
  totalRecords: 0,
  canPaginate: true,
  showEmptyTable: false,
  showEmptySearch: false,
  showError: false,
  resetPageIndex: false,
}

export const getColumns = (programPerformanceTab: ProgramPerformanceTab) => {
  switch (programPerformanceTab) {
    case ProgramPerformanceTab.All_CONTACTS:
      return allContactsColumns
    case ProgramPerformanceTab.IN_PROGRAM:
      return inProgramColumns
    case ProgramPerformanceTab.EARLY_EXITS:
      return earlyExitsColumns
    case ProgramPerformanceTab.STANDARD_EXITS:
      return standardExitsColumns
  }
}
export const getV2Columns = (programPerformanceTab: ProgramPerformanceTab): ColumnDefWithAdditionalProps<any>[] => {
  switch (programPerformanceTab) {
    case ProgramPerformanceTab.All_CONTACTS:
      return allContactsColumnsV2
    case ProgramPerformanceTab.IN_PROGRAM:
      return inProgramColumnsV2
    case ProgramPerformanceTab.EARLY_EXITS:
      return earlyExitsColumnsV2
    case ProgramPerformanceTab.STANDARD_EXITS:
      return standardExitsColumnsV2
  }
}

export const getContactData = (
  programContact: ProgramContact,
  programPerformanceTab: ProgramPerformanceTab,
  programSteps?: ProgramStep[]
): AllContactsData | InProgramContactsData | EarlyExitsContactsData | StandardExistsContactsData => {
  const commonContactData: CommonContactData = {
    name: programContact.name,
    email: programContact.email ?? '',
    sourceList: programContact.listName !== '' ? programContact.listName : '[Source not Found]',
    recId: programContact.recId,
  }
  const currentStep = programSteps?.find((programStep) => programStep.stepId === programContact.currentStepId)
  const exitStep = programSteps?.find((programStep) => programStep.stepId === programContact.exitedStepId)

  switch (programPerformanceTab) {
    case ProgramPerformanceTab.All_CONTACTS:
      return {
        ...commonContactData,
        entryDate: programContact.when,
        status: programContact.status,
      } as AllContactsData
    case ProgramPerformanceTab.IN_PROGRAM:
      const currentStepType = currentStep?.stepType ?? ''
      const currentLabelKey = getKeyName(currentStepType, ProgramStepType)
      const currentStepLabel = getValueByKey(currentLabelKey ?? '', ProgramStepTypeLabel)
      const step = `${currentStepLabel} ${currentStep?.letter ?? ''}`
      const title = programContact.currentStepName === currentStepLabel ? step : programContact.currentStepName ?? ''

      return {
        ...commonContactData,
        entryDate: programContact.when,
        step,
        title,
      } as InProgramContactsData

    case ProgramPerformanceTab.EARLY_EXITS:
      const reason = (programContact.earlyExitReason?.charAt(0).toUpperCase() ?? '') + programContact.earlyExitReason?.slice(1)

      return {
        ...commonContactData,
        exitReason: reason,
        exitDate: programContact.when,
      } as EarlyExitsContactsData
    case ProgramPerformanceTab.STANDARD_EXITS:
      return {
        ...commonContactData,
        exitDate: programContact.when,
        step: `${programContact.exitedStepName} ${exitStep?.letter ?? ''}`,
      } as StandardExistsContactsData
  }
}

export const getContainerHeight = (state: ProgramPerformanceTableContainerState, enableTableV2?: boolean) => {
  const numRows =
    state.showEmptyTable || state.showError
      ? MIN_TABLE_ROWS_FOR_PAGINATION - 1
      : state.tableData.length === 0
      ? state.totalRecords > 0 && state.totalRecords < MIN_TABLE_ROWS_FOR_PAGINATION
        ? state.totalRecords
        : state.pageSize
      : state.tableData.length

  const height = (enableTableV2 ? 47.4 : TABLE_ROW_HEIGHT) * numRows

  return state.showError
    ? height - ERROR_TABLE_DIFF
    : state.showEmptyTable
    ? state.showEmptySearch
      ? height - EMPTY_SEARCH_DIFF
      : height - EMPTY_TABLE_DIFF
    : height + TABLE_HEIGHT
}

export const getDefaultSort = (programPerformanceTab: ProgramPerformanceTab, locationState: ProgramManagerLocationStateProps) => {
  const lsSortColumn =
    locationState && locationState[`${programPerformanceTab}SortColumn`] ? locationState[`${programPerformanceTab}SortColumn`] : undefined

  const lsSortDesc =
    locationState && locationState[`${programPerformanceTab}SortDesc`] !== undefined ? locationState[`${programPerformanceTab}SortDesc`] : true

  return programPerformanceTab === ProgramPerformanceTab.EARLY_EXITS || programPerformanceTab === ProgramPerformanceTab.STANDARD_EXITS
    ? ([
        {
          id: lsSortColumn ?? 'exitDate',
          desc: lsSortDesc,
        },
      ] as SortByState[])
    : ([{ id: lsSortColumn ?? 'entryDate', desc: lsSortDesc }] as SortByState[])
}

export const getSortColumn = (sortId: string, programPerformanceTab: ProgramPerformanceTab) => {
  return sortId === 'entryDate' || sortId === 'exitDate'
    ? 'DATEADDED'
    : sortId === 'step'
    ? programPerformanceTab === ProgramPerformanceTab.IN_PROGRAM
      ? 'CURRENTSTEP'
      : 'EXITSTEP'
    : sortId === 'exitReason'
    ? 'EARLYEXITREASON'
    : sortId.toUpperCase()
}
