import { gql } from '@apollo/client'

export default gql`
  query validateDataTypes($dataTypeValues: ColumnDataTypeDetailsInput) {
    validateDataTypes(dataTypeValues: $dataTypeValues) {
      data
      message
      valid
    }
  }
`
