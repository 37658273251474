import { gql } from '@apollo/client'

export default gql`
  mutation deleteEmailDrafts($draftIds: [String]) {
    deleteEmailDrafts(draftIds: $draftIds) {
      status
      successIds {
        id
        name
      }
      failedIds {
        id
        name
        reason
      }
    }
  }
`
