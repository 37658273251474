import React, { FC, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useApolloClient, useQuery } from '@apollo/client'
import { CardsOptions } from '@components/InfoCard/InfoCard'
import { SwitchOptions } from '@components/Switch/Switch'
import { renderTooltipHeader } from '@components/Table/components/tableColumns'
import { TableColumn } from '@components/Table/Table'
import { ChartSettingState, defaultChartSettingState } from '@const/Chart.constants'
import { rootContext, useTranslation } from '@const/globals'
import getSmsPerformanceResponse from '@graphql/queries/getSmsPerformanceResponse'
import { GetSmsPerformanceResponseQuery, GetSmsPerformanceResponseQueryVariables, ProgramSmsMessageCountsResponse } from '@graphql/types/query-types'
import { sortEnterExitData } from '@src/pages/programs/dashboard/components/ProgramPerformance/components/ProgramPerformanceDetail/components/ProgramPerformanceChartContainer/ProgramPerformanceChartContainer.utils'
import { getStepFromStepLetter, getUIStepName } from '@src/pages/programs/dashboard/ProgramSteps.utils'
import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'
import { Row } from '@tanstack/react-table'
import { DateType, DEFAULT_DATE_QUANTITIES, getDateString } from '@utils/date'
import useUserSettings from '@utils/hooks/useUserSettings'
import { getFormattedNumber } from '@utils/numbers'
import { ProgramSmsSendStepExt } from '@utils/program/program.constants'
import { SortByState } from '@utils/sms.utils'

import EmailSMSPerformance from './EmailSMSPerformance'
import { getSMSHeaderStrings, smsColumns, smsColumnsV2, smsTableArgs } from './EmailSMSPerformance.constants'
import { calculatePercent, EmailSMSPerformanceFunctions } from './EmailSMSPerformance.utils'

export interface SMSPerformanceData {
  clicked: [string, string]
  delivered: [string, string]
  failed: [string, string]
  optOut: [string, string]
  replied: [string, string]
  sent: string
  step: string
  stepName: string
}

interface State {
  dateTypeQuantity: number
  dateType: DateType
  data: ProgramSmsMessageCountsResponse[]
  tableData: any[]
  fields: any[]
  pageSize: number
  pageIndex: number
  sortBy: SortByState[]
  canPaginate: boolean
  totalRecords: number
  categories: string[]
  cardsOptions?: CardsOptions
  chartType: SwitchOptions
  showEmptyMessage: boolean
  firstLoad: boolean
  chartSettings: ChartSettingState
  chartLoading: boolean
}

export interface ProgramContactList {
  row?: number
  email?: string
  name?: string
  listId?: string
  listName?: string
  count?: number
  since?: number
  recId?: string
  deleted?: boolean
  when?: string
  reason?: string
  reasonDetails?: string
}

const defaultSortBy: SortByState = {
  id: 'stepName',
  desc: false,
}

const defaultState: State = {
  dateTypeQuantity: DEFAULT_DATE_QUANTITIES[DateType.MONTH],
  dateType: DateType.MONTH,
  fields: [],
  categories: [],
  data: [],
  tableData: [],
  pageSize: 10,
  pageIndex: 0,
  totalRecords: 0,
  canPaginate: true,
  sortBy: [defaultSortBy],
  chartType: SwitchOptions.PERCENT,
  showEmptyMessage: false,
  firstLoad: true,
  chartLoading: true,
  chartSettings: defaultChartSettingState,
}
interface Props {
  dataTest?: string
}

const LEARN_MORE_LINK =
  'https://success.act-on.com/acton/attachment/9883/u-2b2a00c8-c1e6-49ac-98ad-ef52c85ab163/0/-/-/-/-/In-App%3A%20Automated%20Program%20SMS%20Article'

const SMSPerformanceContainer: FC<Props> = (props: Props) => {
  const {
    values: { program, programUrlId },
    refreshProgram,
  } = useContext(ProgramManagerContext)
  const [state, setState] = useState<State>({
    ...defaultState,
  })
  const [enableTableV2, setEnableTableV2] = useState(false)

  const [columns, setColumns] = useState<TableColumn[]>(smsColumns)

  const history = useHistory()

  const client = useApolloClient()

  const { userSettings, setUserSetting } = useUserSettings()

  useEffect(() => {
    if (state.chartLoading && userSettings !== undefined) {
      const getChart = userSettings['programManagerCharts']
      const { sms } = getChart
      setState({
        ...state,
        dateType: sms.dateType,
        dateTypeQuantity: sms.dateTypeQuantity,
        chartSettings: getChart,
        chartLoading: false,
      })
    }
  }, [userSettings])

  const { t } = useTranslation()

  const {
    loading,
    error: pageError,
    data,
  } = useQuery<GetSmsPerformanceResponseQuery, GetSmsPerformanceResponseQueryVariables>(getSmsPerformanceResponse, {
    client: client as any,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      programId: programUrlId,
      unitsAgo: state.dateTypeQuantity,
      groupByString: state.dateType.toUpperCase(),
      size: state.pageSize,
      page: state.pageIndex,
      sortColumn: state.sortBy[0].id.toUpperCase(),
      sortDirection: state.sortBy[0].desc ? 'DESC' : 'ASC',
    },
  })

  const setColumnHeaders = () => {
    const updatedColumns = columns.map((column) => {
      const [title, tooltip] = getSMSHeaderStrings(column.accessor, state.chartType === SwitchOptions.PERCENT)
      const Header = tooltip ? { Header: renderTooltipHeader(title, tooltip) } : {}
      return { ...column, ...Header }
    })
    setColumns(updatedColumns)
  }

  useEffect(() => {
    if (!loading && !pageError && !state.chartLoading) {
      updateData(data)
    }
    if (pageError) {
      setState({
        ...state,
        showEmptyMessage: true,
      })
    }
    setColumnHeaders()
  }, [loading, pageError, state.chartLoading])

  useEffect(() => setColumnHeaders, [state.chartType])

  const getTableData = (data: any[], chartType: SwitchOptions) => {
    return data.map((a) => ({
      step: a.stepId ? getUIStepName(program, a.stepId) : '',
      stepName: a.stepName,
      sent: `${a.uniqueSentCount}`,
      delivered: formatData(chartType, a.uniqueDeliveredCount, a.uniqueSentCount, 1),
      clicked: formatData(chartType, a.uniqueClickedCount, a.uniqueDeliveredCount, 2),
      replied: formatData(chartType, a.uniqueRepliedCount, a.uniqueDeliveredCount, 1),
      failed: formatData(chartType, a.uniqueFailedCount, a.uniqueSentCount, 1),
      optOut: formatData(chartType, a.uniqueOptOutsCount, a.uniqueDeliveredCount, 1),
    }))
  }

  const formatData = (chartType: SwitchOptions, numerator: number, denominator: number, fixed: number) => {
    const percentage = calculatePercent(numerator, denominator, fixed)
    const value = getFormattedNumber(numerator)
    if (chartType == SwitchOptions.PERCENT) {
      return [percentage, value]
    } else {
      return [value, percentage]
    }
  }

  const onDateChange = (dateType: DateType, dateTypeQuantity: number) => {
    setState({
      ...state,
      dateType: dateType,
      dateTypeQuantity,
      chartSettings: { ...state.chartSettings, sms: { dateType: dateType, dateTypeQuantity: dateTypeQuantity } },
    })
  }

  useEffect(() => {
    if (!state.chartLoading) {
      setUserSetting('programManagerCharts', state.chartSettings)
    }
  }, [state.chartSettings])

  const chartTypeSwitch = (chartType: SwitchOptions) => {
    setState({ ...state, chartType: chartType, tableData: getTableData(state.data, chartType) })
  }

  const updateData = (data: any) => {
    const {
      getSmsPerformanceResponse: { programSmsMessageSendsResponse, programSmsMessagesCountsWithTotalsResponse },
    } = data
    if (programSmsMessageSendsResponse) {
      const sortedProgramSmsMessageSends = sortEnterExitData(programSmsMessageSendsResponse.smsDateToCountResponse, state.dateType)
      const categories = sortedProgramSmsMessageSends.map((entry: any) => getDateString(entry.date, state.dateType))
      const fieldData = sortedProgramSmsMessageSends.map((day: { count: string }) => parseInt(day.count))
      const rawData: ProgramSmsMessageCountsResponse[] = programSmsMessagesCountsWithTotalsResponse.programSmsMessageCountsResponse
      const tableData = getTableData(rawData, state.chartType)

      setState({
        ...state,
        categories: categories,
        fields: [
          {
            name: 'All SMS Messages',
            data: fieldData,
            color: '#A184DD',
          },
        ],
        totalRecords: programSmsMessagesCountsWithTotalsResponse.totalRecords,
        data: rawData,
        tableData,
        showEmptyMessage: !tableData.length,
        firstLoad: false,
      })
    }
  }

  const fetchData = (pageIndex: number, pageSize: number) => {
    setState({
      ...state,
      pageIndex: pageIndex,
      pageSize: pageSize,
    })
  }

  const onChangeSort = (sortBy: SortByState[]) => {
    const validSortBy = sortBy.length > 0 ? sortBy : [defaultSortBy]
    setState({ ...state, sortBy: validSortBy })
    //TODO:: the logic on this page needs improvement
    updateData(data)
  }

  const getIdFromStepName = (stepName: string) => {
    const [trackNumber, stepNumber] = getStepFromStepLetter(stepName)
    const track = program.tracks[trackNumber]
    const step = track.steps[stepNumber]
    if (step) {
      const tsStep = step as ProgramSmsSendStepExt
      return tsStep.smsMessageId
    } else {
      return '-1'
    }
  }

  const openReport = (row: any) => {
    if (row.values.sent !== '0') {
      const messageId = getIdFromStepName(row.values.step)
      history.push({
        pathname: `${rootContext}/sms/${messageId}/report/summary`,
      })
    }
  }

  const openReportV2 = (row: Row<SMSPerformanceData>) => {
    if (row.original.sent !== '0') {
      const messageId = getIdFromStepName(row.original.step)
      history.push({
        pathname: `${rootContext}/sms/${messageId}/report/summary`,
      })
    }
  }

  const helpMessage = {
    text: 'Learn more about using SMS in Automated Programs',
    url: LEARN_MORE_LINK,
  }

  return (
    <EmailSMSPerformance
      helpMessage={helpMessage}
      {...EmailSMSPerformanceFunctions}
      {...smsTableArgs}
      {...props}
      lastUpdated={program.lastUpdated}
      //@ts-ignore should be removed after switching to tableV2
      columns={enableTableV2 ? smsColumnsV2 : columns}
      onDateChange={onDateChange}
      title={t('All SMS Messages')}
      categories={state.categories}
      hideLegend={true}
      fields={state.fields}
      dateType={state.dateType}
      dateTypeQuantity={state.dateTypeQuantity}
      chartType={state.chartType}
      canSwitchChartType={false}
      onSwitchChartType={chartTypeSwitch}
      canPaginate={state.canPaginate}
      controlledPageCount={Math.ceil(state.totalRecords / state.pageSize)}
      pageSize={state.pageSize}
      pageIndex={state.pageIndex}
      onRefresh={refreshProgram}
      data={state.tableData}
      fetchData={fetchData}
      onSort={onChangeSort}
      sortBy={state.sortBy}
      openReport={enableTableV2 ? openReportV2 : openReport}
      emailPerformance={false}
      showEmptyMessage={state.showEmptyMessage}
      loading={(loading && state.firstLoad) || state.chartLoading}
      chartLoading={(loading && !state.firstLoad) || state.chartLoading}
      pageError={pageError}
      enableTableV2={enableTableV2}
      setEnableTableV2={setEnableTableV2}
    />
  )
}

export default SMSPerformanceContainer
