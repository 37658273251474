import React, { FC } from 'react'

import Typography, { TextType, TextWeight } from '@components/Typography/Typography'

import './CompanyInfoSectionMeta.css'

interface Props {
  value: string
  title?: string
  dataTest?: string
}

const rootClass = 'company__info-section__meta'

const CompanyInfoSectionMeta: FC<Props> = (props: Props) => {
  const { value, title, dataTest = 'section-meta' } = props
  return (
    <div className={rootClass} data-test={dataTest}>
      {title && <Typography text={title} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} className={`${rootClass}-title`} />}
      <Typography text={value} type={TextType.BODY_TEXT_LIGHT} className={`${rootClass}-value`} weight={TextWeight.MEDIUM} />
    </div>
  )
}

export default CompanyInfoSectionMeta
