import { gql } from '@apollo/client'

export default gql`
  query sentMessages($dateRangeCode: String!, $sales: String, $showAll: String, $userFilter: String) {
    sentMessages(dateRangeCode: $dateRangeCode, sales: $sales, showAll: $showAll, userFilter: $userFilter) {
      msgId
      senderId
      senderName
      actonAlerts
      blockTypes
      subject
      previewUrl
      scheduled
      isPlainText
      isFavorite
      isNewComposer
      toDesc
      toPeople
      successCount
      failedCount
      state
      transactional
      autoResponse
      noSend
      altSender
      templateId
      optInCampaign
      beeComposer
      launchType
      endTime
      programType
    }
  }
`
