import React, { Fragment, ReactNode } from 'react'

import { MAX_FOLDER_DEPTH } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { getFolderById } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { SelectOption } from '@components/NestedDropDown/NestedDropDown'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { SvgNames } from '@components/Svg'
import { GetFoldersQuery } from '@graphql/types/microservice/categorization-types'
import { Folder } from '@interface/Folder'
import { filterNotEmptyArray } from '@utils/array'
import { DEFAULT_FOLDER_NAME } from '@utils/folders'

import { FilterDefinition } from './filter'
import { toCamelCase } from './strings'

export const folderNameExistsAtSameLevel = (folderId: number | undefined, folderName: string, folders: FolderData[]): boolean => {
  if (folderId !== undefined) {
    const currentFolder = getFolderById(folderId, folders as Folder[])
    if (currentFolder) {
      return currentFolder.subFolders ? currentFolder.subFolders.some((folder) => folder.name === folderName) : false
    }
  }
  return folders.some((folder) => folder.name === folderName)
}

export const getAllFolders = (folders: FolderData[], movingItem = false): ReactNode => {
  return folders.map((folder: FolderData) => getFolderOptions(folder, movingItem))
}

export const getFolderOptions = ({ id, name, subFolders, depth = MAX_FOLDER_DEPTH }: FolderData, movingSegment: boolean): ReactNode => {
  return (
    <Fragment key={id}>
      <option value={id} disabled={depth > MAX_FOLDER_DEPTH && !movingSegment}>{`${getIndentation(depth)}${name}`}</option>
      {subFolders && subFolders.map((folder: FolderData) => getFolderOptions(folder, movingSegment))}
    </Fragment>
  )
}

export const getFolderDropDownOptions = (folderData: FolderData[] | undefined, movingSegment = false): SelectOption[] => {
  return (
    folderData?.map(({ id, name, subFolders, depth }) => ({
      name,
      mapping: id.toString(),
      filtered: false,
      icon: SvgNames.folderLightGray,
      selectedIcon: SvgNames.folderTeal,
      disabled: depth === MAX_FOLDER_DEPTH && !movingSegment,
      nestedOptions: subFolders && subFolders.length !== 0 ? getFolderDropDownOptions(subFolders, movingSegment) : undefined,
    })) ?? []
  )
}

const getIndentation = (depth = 0) => '\u00A0'.repeat(depth * 4)

export const sortFoldersByName = (folders: GetFoldersQuery['getFolders'], sortByDefaultFolder = false): Folder[] => {
  const filteredFolders = (
    folders
      ? folders
          .filter(filterNotEmptyArray)
          .map(({ subFolders, ...rest }) => ({ ...rest, subFolders: subFolders ? sortFoldersByName(subFolders) : undefined }))
      : []
  ) as Folder[]

  filteredFolders.sort((a, b) => (a.name ?? '').toLowerCase().localeCompare((b.name ?? '').toLowerCase()))

  if (sortByDefaultFolder) {
    const defaultFolderIndex = filteredFolders.findIndex((folder) => folder.name === DEFAULT_FOLDER_NAME)

    if (defaultFolderIndex !== -1) {
      filteredFolders.unshift(filteredFolders.splice(defaultFolderIndex, 1)[0])
    }
  }

  return filteredFolders
}

export const folderExists = (folderId: number, folders: Folder[]): boolean => {
  return folders.some((folder) => folder.id === folderId || (folder.subFolders && folderExists(folderId, folder.subFolders)))
}

export const flattenFolders = (folders: FolderData[]): FolderData[] => {
  const subFolders = folders
    .map(({ subFolders }) => subFolders)
    .filter((subFolder) => subFolder)
    .flat()
  return [...folders, ...(subFolders.length === 0 ? [] : flattenFolders(subFolders as unknown as FolderData[]))]
}

const buildFoldersTree = (folderId: number, folders: FolderData[]): string[] => {
  const folder = folders.find(({ id }) => folderId === id)
  return folder
    ? !folder.parentId || folder.parentId === folderId
      ? [folder.name]
      : [...buildFoldersTree(folder.parentId, folders), folder.name]
    : []
}

export const buildHeader = (folderId: number, folders: FolderData[]): string[] => {
  const flatFolders = flattenFolders(folders)
  return buildFoldersTree(folderId, flatFolders)
}

const buildComplexFoldersTree = (folderId: number, folders: FolderData[]): FolderData[] => {
  const folder = folders.find(({ id }) => folderId === id)
  return folder ? (!folder.parentId || folder.parentId === folderId ? [folder] : [...buildComplexFoldersTree(folder.parentId, folders), folder]) : []
}

export const buildComplexFoldersTreeHeader = (folderId: number, folders: FolderData[]): FolderData[] => {
  const flatFolders = flattenFolders(folders)
  return buildComplexFoldersTree(folderId, flatFolders)
}

export const getFolderCount = (folders: FolderData[]): number =>
  folders.reduce((count: number, { subFolders }) => count + 1 + (subFolders ? getFolderCount(subFolders) : 0), 0)

export const svgNamesToCamelCase = (filter?: FilterDefinition) => ({
  name: filter?.name,
  svgSelected: toCamelCase(filter?.svgSelected),
  svgUnselected: toCamelCase(filter?.svgUnselected),
})
