import React, { FC, useEffect, useState } from 'react'

import AssignManagersModal, {
  AssignedManager,
  AssignManagersModalProps,
} from '@src/pages/listingPages/AccountManagement/components/AssignManagersModal/AssignManagersModal'
import { useAccountManagementListingPageRequests } from '@src/pages/listingPages/AccountManagement/GraphQL/AccountManagementListingPageRequests.graphQL'
import { useAccountSettings } from '@utils/account/account.utils'
import { logNewRelicError } from '@utils/new-relic.utils'

type AssignManagersModalContainerProps = Omit<AssignManagersModalProps, 'managers' | 'loading'> & {
  isManagers: boolean
  accountId: string
}

const AssignManagersModalContainer: FC<AssignManagersModalContainerProps> = (props: AssignManagersModalContainerProps) => {
  const { isManagers, accountId, ...rest } = props
  const { parentId, accountId: originalAccountId, userId } = useAccountSettings()
  const parentAccountId = parentId === '0' ? originalAccountId : parentId

  const { getMarketingNetworkManagersRequest, getSalesUsersRequest } = useAccountManagementListingPageRequests(userId, parentAccountId ?? '0')

  const [managers, setManagers] = useState<AssignedManager[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getMarketingNetworkManagers = async () => {
      const { data, errors } = await getMarketingNetworkManagersRequest(accountId)

      if (errors) {
        logNewRelicError(errors)
        alert(`Error getting network managers: ${errors[0].message}`)
      }

      if (data?.marketingNetworkManagers?.managers) {
        const managers: AssignedManager[] = data.marketingNetworkManagers.managers
          .filter((mgr) => (mgr.name ?? '').trim() !== '') // Probably don't need this but BE mock data has it
          .map((mgr) => ({
            name: mgr?.name ?? '',
            isAssigned: mgr?.manager ?? false,
            id: mgr?.userId.toString() ?? '-1',
          }))
        setManagers(managers)
        setLoading(false)
      }
    }

    const getSalesUsers = async () => {
      const { data, errors } = await getSalesUsersRequest(accountId)

      if (errors) {
        logNewRelicError(errors)
        alert(`Error getting sales users: ${errors[0].message}`)
      }

      if (data?.salesUsers?.salesUsers) {
        const sales: AssignedManager[] = data.salesUsers.salesUsers.map((sale) => ({
          name: sale?.name ?? '',
          isAssigned: sale?.salesUser ?? false,
          id: sale?.userId.toString() ?? '-1',
        }))
        setManagers(sales)
        setLoading(false)
      }
    }

    if (isManagers) {
      getMarketingNetworkManagers()
    } else {
      getSalesUsers()
    }
  }, [])

  return <AssignManagersModal {...rest} isManagers={isManagers} managers={managers} loading={loading} />
}

export default AssignManagersModalContainer
