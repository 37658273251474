import { TFunction } from 'i18next'

import { ApolloClient } from '@apollo/client'
import { SelectAssetV2RowItem } from '@components/SelectAssetV2/SelectAssetV2'
import { legacyActonContext, rootContext } from '@const/globals'
import getStatSendableContacts from '@graphql/microservices/segment/getStatSendableContacts'
import { StatValueDto } from '@graphql/types/microservice/segment-types'
import { BouncesSegments } from '@utils/categorization'
import { Segment } from '@utils/contactSegments/contactSegments.utils'
import { getFormattedNumber } from '@utils/numbers'

export const SEGMENTS_LIST_URL = `${rootContext}/segments`
export const SEGMENT_ACTIVITY_URL = `${SEGMENTS_LIST_URL}/details`
enum SegmentStats {
  SENDABLE_CONTACTS = 'Sendable Contacts',
}
export const segmentPerformanceCards = (totalContacts: number, sendableContacts: StatValueDto, t: Function) => ({
  cardsTitle: t('Performance'),
  cardsSubTitle: '',
  cards: [
    {
      label: t('Total Contacts'),
      labelContent: {
        primary: getFormattedNumber(totalContacts),
      },
    },
    {
      label: t(SegmentStats.SENDABLE_CONTACTS),
      tooltip: t('Total contacts in this segment who are eligible to receive email'),
      labelContent: {
        primary: `${sendableContacts.percentageValue}%`,
        secondary: sendableContacts.value.toString(),
      },
    },
  ],
})

export const getAllSegmentStats = (externalId: string, client: ApolloClient<any>, setState: Function) => {
  Object.values(SegmentStats).map((stat) => getSegmentStat(externalId, stat, client, setState))
}

const getSegmentStat = (externalId: string, stat: SegmentStats, client: ApolloClient<any>, setState: Function) => {
  const getStatsQuery = {
    [SegmentStats.SENDABLE_CONTACTS]: { query: getStatSendableContacts, field: 'getStatSendableContacts', stateValue: 'sendableContacts' },
  }
  const { query, field, stateValue } = getStatsQuery[stat]

  client
    .query({ query, fetchPolicy: 'network-only', variables: { externalId } })
    .then(({ data }) => setState((state: any) => ({ ...state, [stateValue]: data[field] })))
}

export const isNonSendableSegment = ({ name }: Segment) =>
  name === BouncesSegments.SPAM_COMPLAINTS || name === BouncesSegments.HARD_BOUNCES || name === BouncesSegments.OPT_OUT

export const sendEmailToSegment = (segmentId: string) => {
  window.open(`${legacyActonContext}/_compose/start.jsp?srcId=${segmentId}&allContacts=1&selectedLists=1`, `_blank`)
}

export enum DownloadCSVOptions {
  DOWNLOAD_AS_CSV = 'download_as_csv',
  DOWNLOAD_AS_CSV_WITH_UPLOADED = 'download_as_csv_with_uploaded',
}

export const getModalRowItems = (t: TFunction): SelectAssetV2RowItem[] => {
  return [
    {
      title: t('ListPage.FormsJoinView.DownloadModal.CSV.Title'),
      description: t('ListPage.FormsJoinView.DownloadModal.CSV.Description'),
      name: DownloadCSVOptions.DOWNLOAD_AS_CSV,
    },
    {
      title: t('ListPage.FormsJoinView.DownloadModal.CSV.WithFiles.Title'),
      description: t('ListPage.FormsJoinView.DownloadModal.CSV.WithFiles.Description'),
      name: DownloadCSVOptions.DOWNLOAD_AS_CSV_WITH_UPLOADED,
    },
  ]
}
