import React, { FC, useCallback, useMemo } from 'react'

import {
  getImagePickerTableColumns,
  getImagePickerRowActions,
} from '@components/AssetPickers/ImagePickerModal/components/ImagePickerModalContent/ImagePickerModalTable.utils'
import { useImagePickerModalContentContext } from '@components/AssetPickers/ImagePickerModal/context/ImagePickerModalContent.context'
import {
  convertToBytes,
  getIsUnsupportedFileType,
  getTooltipText,
  IImagePickerItem,
} from '@components/AssetPickers/ImagePickerModal/utils/ImagePickerModal.utils'
import { TableV2 } from '@components/TableV2/TableV2'
import { TableV2Props } from '@components/TableV2/tableV2TS/interfaces'
import { useTranslation } from '@const/globals'
import { SortingState } from '@tanstack/react-table'
import { Row as RowV2 } from '@tanstack/table-core/build/lib/types'

const ImagePickerModalTable: FC = () => {
  const { t } = useTranslation()
  const {
    isLogos,
    imageItems,
    loading,
    loadingNextData,
    onSelect,
    onPreview,
    searchTerm,
    sortBy,
    onSortColumnChange,
    selectedId,
    imageMaxSize,
    accept,
  } = useImagePickerModalContentContext()

  const handleImageSelect = useCallback(
    (rowIds: string[], rows: RowV2<IImagePickerItem>[]) => {
      const imageId = rowIds[0]
      if (imageId) {
        const selectedRow = rows.find(({ id }) => id === imageId)?.original
        if (selectedRow) {
          onSelect(selectedRow)
        }
      }
    },
    [onSelect]
  )
  const handleSortColumnsChange = useCallback((sortingState: SortingState) => onSortColumnChange(sortingState[0]), [onSortColumnChange])
  const defaultSelectedRows = useMemo(() => (selectedId ? { [selectedId]: true } : undefined), [selectedId])

  const getMaxSizeExeeded = (size: string) => {
    return imageMaxSize ? imageMaxSize <= convertToBytes(size) : false
  }

  const getDisabledFileTypes = (url: string) => {
    return accept ? getIsUnsupportedFileType(accept ?? '', url) : false
  }

  const tableV2Props: TableV2Props<IImagePickerItem> = {
    columns: getImagePickerTableColumns(t, isLogos, searchTerm),
    data: loading ? [] : imageItems,
    loading: loading || loadingNextData,
    onRowSelectionChanged: handleImageSelect,
    rowActions: getImagePickerRowActions(t, onPreview),
    enableRadio: true,
    enableStickyHeader: true,
    withoutBorders: true,
    enableLazyLoading: true,
    manualSorting: true,
    resetSorting: true,
    sortingBy: [sortBy],
    onSort: handleSortColumnsChange,
    enableInsideLoader: true,
    rowUniqueIdKey: 'id',
    defaultSelectedRows,
    rowDisabled: (row) => getMaxSizeExeeded(row.original.size ?? '') || getDisabledFileTypes(row.original.url),
    hasDisabledRowStyles: true,
    rowTooltip: (row) => {
      const { size, url } = row.original
      return getTooltipText(getMaxSizeExeeded(size ?? ''), getDisabledFileTypes(url), accept, imageMaxSize, t)
    },
    tooltipProps: {
      position: 'left',
      withoutTail: false,
      sideOffset: -10,
    },
  }

  return <TableV2 {...tableV2Props} />
}

export default ImagePickerModalTable
