import React, { FC, useEffect, useState } from 'react'
import { useDrop } from 'react-dnd'

import classNames from 'classnames'

import { COMMON_DROPPABLE_TYPES } from '@complex/ListingPage/Utils/ListingPage.constants'
import { Item } from '@components/ActionableNestedTable/components/NestedTableRowWithDnD/NestedTableRowWithDnD'
import Folder, { FolderData, FolderProps } from '@components/SortableFolders/components/Folder/Folder'
import { TIME_BEFORE_OPENING } from '@utils/contactSegments/contactSegments.utils'

import './FolderWithDnD.css'

type FolderWithDnDProps = FolderProps & {
  onDrop: (item: Item, folder: FolderData) => void
}

const rootClass = 'folder-with-dnd'

const FolderWithDnD: FC<FolderWithDnDProps> = (props: FolderWithDnDProps) => {
  const { className = '', onCaretClick, isOpen, folder, onDrop, ...folderProps } = props
  const [timer, setTimer] = useState<any>()

  const [{ isOver }, drop] = useDrop({
    accept: COMMON_DROPPABLE_TYPES,
    drop: (item: Item) => {
      item.toggleAllRowsSelected(false)
      onDrop(item, folder)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  })

  useEffect(() => {
    timer && clearTimeout(timer)
    if (isOver && !isOpen) {
      setTimer(setTimeout(() => onCaretClick(folder), TIME_BEFORE_OPENING))
    }
  }, [isOver])

  return (
    <Folder
      className={classNames(rootClass, className, { [`${rootClass}__is-over`]: isOver })}
      onCaretClick={onCaretClick}
      folder={folder}
      isOpen={isOpen}
      {...folderProps}
      reference={drop}
    />
  )
}

export default FolderWithDnD
