import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'

import * as yup from 'yup'

import Checkbox from '@components/Checkbox/Checkbox'
import FormRow from '@components/FormRow/FormRow'
import Input from '@components/Input/Input'
import { ModalBody } from '@components/Modal'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { Program, ProgramSource } from '@graphql/types/query-types'
import { yupResolver } from '@hookform/resolvers/yup'
import CopyToListStep from '@src/pages/programs/dashboard/components/ProgramSteps/components/CopyToListStep/CopyToListStep'
import { findListName } from '@src/pages/programs/dashboard/components/ProgramSteps/ProgramSteps.utils'
import { getUpdatedProgramSources } from '@utils/program/program'
import { ProgramCopyStepExt, Step, Track } from '@utils/program/program.constants'

import ListSelect from '../../../ListSelect/ListSelect'

const rootClass = 'edit-copy-step'

interface Props {
  step: Step
  program: Program
  tracks: Track[]
  isRunning: boolean
  saveStepAndProgram(step: Step | null, program?: Program): void
  submitId: string
  dataTest?: string
}

export const handleFormSubmit = (data: any, step: ProgramCopyStepExt, props: Props) => {
  const { saveStepAndProgram } = props

  saveStepAndProgram({
    ...step,
    displayName: data.displayName,
    listId: data.listId,
    update: data.update,
  })
}

export function getListOptions(program: Program, t: Function) {
  return program.sources?.reduce(
    (acc, cur) => {
      return [
        ...acc,
        <option key={cur.id} value={cur.id}>
          {cur.name}
        </option>,
      ]
    },
    [
      <option key="empty" value="">
        --- {t('Select List')} ---
      </option>,
    ]
  )
}

const schema = yup.object().shape({
  displayName: yup.string().required('Step Name is required.'),
  listId: yup.string().required('List or segment is required.'),
})

const EditCopyStep: FC<Props> = (props: Props) => {
  const { saveStepAndProgram, step: baseStep, program, isRunning, submitId, dataTest = 'edit-wait-step' } = props

  const step = baseStep as ProgramCopyStepExt

  const [updateList, setUpdateList] = useState(step.update ?? false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })
  const { t } = useTranslation()

  const onSubmit = (data: any) => {
    handleFormSubmit({ ...data, update: updateList }, step, props)
  }

  const onProgramSourcesUpdate = (sources: ProgramSource[]) => {
    saveStepAndProgram(null, getUpdatedProgramSources(program, sources))
  }

  const renderForm = () => (
    <form data-test={dataTest} onSubmit={handleSubmit(onSubmit)} className={rootClass}>
      <FormRow>
        <Input
          className={`${rootClass}__step-name`}
          label={t('Step Name')}
          defaultValue={step.displayName}
          name="displayName"
          register={register('displayName')}
        />
        {errors.displayName && <span className="error">{t(errors.displayName.message)}</span>}
      </FormRow>
      <FormRow>
        <ListSelect
          onProgramSourcesUpdate={onProgramSourcesUpdate}
          name="listId"
          srcId={step.listId}
          register={register}
          program={program}
          isListsOnly
          showUnifiedList={false}
        />
        {errors.listId && <span className="error">{t(errors.listId.message)}</span>}
      </FormRow>
      <FormRow>
        <Checkbox
          label={t('Update records in the destination List')}
          name="update"
          defaultChecked={updateList}
          onChange={() => setUpdateList(!updateList)}
        />
      </FormRow>
      <button type="submit" id={submitId} hidden />
    </form>
  )

  const renderView = () => {
    const listName = findListName(step.listId ?? '', program.sources)
    return (
      <>
        <Typography text={step.displayName} weight={TextWeight.MEDIUM} type={TextType.SECTION_HEADER} />
        <CopyToListStep listName={listName} update={step.update ?? false} listId={step?.listId ?? ''} isCopy />
      </>
    )
  }

  return <ModalBody>{isRunning ? renderView() : renderForm()}</ModalBody>
}

export default EditCopyStep
