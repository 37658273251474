import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './SidebarMenu.css'

interface SidebarMenuProps {
  className?: string
  dataTest?: string
  children: ReactNode
  onClose: VoidFunction
  icon?: SvgNames
  header: string
  onCancel?: VoidFunction
  onSubmit?: VoidFunction
  submitButtonText?: string
  cancelButtonText?: string
  submitButtonDisabled?: boolean
}

const rootClass = 'sidebar-menu'

const SidebarMenu: FC<SidebarMenuProps> = (props: SidebarMenuProps) => {
  const {
    dataTest = rootClass,
    className = '',
    children,
    onClose,
    icon,
    header,
    onCancel,
    onSubmit,
    cancelButtonText = 'Cancel',
    submitButtonText = 'Ok',
    submitButtonDisabled = false,
  } = props
  const { t } = useTranslation()

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__header`}>
        <div className={`${rootClass}__header-left`}>
          {icon && <Svg name={icon} type={SvgType.LARGE_ICON} fill={SvgColor.TEXT_TEAL} />}
          <Typography text={header} type={TextType.DATA_CARD_MODAL_HEADER} />
        </div>
        <Button
          dataTest={`${dataTest}-close-button`}
          className={`${rootClass}__header-close-button`}
          tabIndex={-1}
          buttonType={ButtonType.ICON}
          onClick={() => onClose()}
        >
          <Svg name={SvgNames.close} type={SvgType.VERY_LARGE_ICON} />
        </Button>
      </div>
      <div className={`${rootClass}__body`}>{children}</div>
      <div className={`${rootClass}__footer`}>
        {!!onCancel && (
          <Button buttonType={ButtonType.SECONDARY} onClick={onCancel}>
            {t(cancelButtonText)}
          </Button>
        )}
        {!!onSubmit && (
          <Button buttonType={ButtonType.PRIMARY} onClick={onSubmit} disabled={submitButtonDisabled}>
            {t(submitButtonText)}
          </Button>
        )}
      </div>
    </div>
  )
}

export default SidebarMenu
