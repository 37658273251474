import React, { FC, Fragment, Key, useCallback, useContext, useMemo } from 'react'
import { useHistory } from 'react-router'

import classNames from 'classnames'

import Container from '@components/Container/index'
import { LinkTextButton, LinkTextButtonSize } from '@components/LinkTextButton/LinkTextButton'
import Radio from '@components/Radio/index'
import RadioCard from '@components/RadioCard/RadioCard'
import RadioCardGroup from '@components/RadioCardGroup/RadioCardGroup'
import RadioGroup from '@components/RadioGroup/RadioGroup'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Toggletip, { ToggletipSide, ToggletipTriggerSize } from '@components/Toggletip/Toggletip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { renderValidation } from '@src/pages/EmailComposer/components/EmailComposerSettings/EmailComposerSettings.utils'
import AdaptiveSuffix from '@src/pages/EmailComposer/components/SendDetails/components/SendDetailsSendMethod/components/AdaptiveSuffix'
import StaggeredSuffix from '@src/pages/EmailComposer/components/SendDetails/components/SendDetailsSendMethod/components/StaggeredSuffix'
import TimezoneSuffix from '@src/pages/EmailComposer/components/SendDetails/components/SendDetailsSendMethod/components/TimezoneSuffix'
import {
  CHOOSE_ARTICLE_LINK,
  SendLaterOption,
  sendLaterOptions,
} from '@src/pages/EmailComposer/components/SendDetails/components/SendDetailsSendMethod/SendDetailsSendMethod.constants'
import { ComposerHistoryState, EmailComposerContext, SendTimeType, ValidationSectionType } from '@utils/composer/context/EmailComposer.context'

import './SendDetailsSendMethod.css'

import { CommonComposerTab } from '@utils/composer/commonComposer/CommonComposer.context'

interface SendDetailsSendMethodProps {
  className?: string
  dataTest?: string
}

const rootClass = 'send-details-send-method'

const SendDetailsSendMethod: FC<SendDetailsSendMethodProps> = (props: SendDetailsSendMethodProps) => {
  const { dataTest = rootClass, className = '' } = props
  const {
    api: { update, onTabChange },
    values: {
      message: {
        sendMethod: { sendtype },
      },
      messageConfiguration: {
        sendDetails: { hideSendMethodStaggered },
      },
      previousSendType,
    },
  } = useContext(EmailComposerContext)
  const { t } = useTranslation()
  const history = useHistory<ComposerHistoryState>()
  const isTimezone = sendtype === SendTimeType.TIME_ZONE
  const showValidationErrors = history.location.state?.fromValidationSection === ValidationSectionType.SEND_METHOD
  const showNoMethodError = useMemo(() => showValidationErrors && !sendtype, [showValidationErrors, sendtype])

  const selectedBaseCardOption = useMemo(
    () => (sendtype === SendTimeType.STANDARD ? sendtype : sendtype ? SendTimeType.LATER : undefined),
    [sendtype]
  )

  const handleCardSelect = useCallback(
    (optionKey: Key) => {
      const isLaterSelected = selectedBaseCardOption === SendTimeType.LATER
      const clickInsideLater = isLaterSelected && optionKey !== SendTimeType.STANDARD
      if (!clickInsideLater) {
        update({
          message: {
            sendMethod: {
              sendtype:
                optionKey === SendTimeType.STANDARD
                  ? optionKey
                  : optionKey === SendTimeType.TIME_ZONE || previousSendType === SendTimeType.TIME_ZONE
                  ? SendTimeType.TIME_ZONE
                  : SendTimeType.LATER,
            },
          },
          previousSendType: sendtype,
        })
      }
    },
    [selectedBaseCardOption, update, previousSendType, sendtype]
  )

  const renderSendImmediatelySuffix = () => (
    <div className={`${rootClass}__send-now-suffix`}>
      <Typography text={t('SendDetails.SendMethod.Immediately.Suffix.Second')} weight={TextWeight.BOLD} />
      <TextLink
        text={t('SendDetails.SendMethod.Immediately.Suffix.Ready')}
        size={TextLinkSize.LARGE}
        hideIcon
        onClick={() => onTabChange(CommonComposerTab.REVIEW)}
      />
    </div>
  )

  const renderSendLaterOptionLabel = (opt: SendLaterOption) =>
    opt.tooltip ? (
      <div className={`${rootClass}__cards-later-options-tooltip`}>
        <Typography text={t(opt.label)} className="push-left-half" />
        <Toggletip
          description={t(opt.tooltip)}
          linkText={t('Learn more')}
          link={opt.tooltipLink}
          triggerSize={ToggletipTriggerSize.SMALL}
          side={ToggletipSide.TOP}
          isDismissable
        />
      </div>
    ) : (
      t(opt.label)
    )

  const changeSendLaterOption = (id: SendTimeType) => {
    const newSendType = previousSendType === SendTimeType.TIME_ZONE ? SendTimeType.TIME_ZONE : id
    update({ message: { sendMethod: { sendtype: newSendType } }, previousSendType: sendtype })
  }

  const renderSendLaterOption = (opt: SendLaterOption) => {
    const id = isTimezone && opt.id === SendTimeType.LATER ? SendTimeType.TIME_ZONE : opt.id
    return (
      <Fragment key={id}>
        <Radio
          label={renderSendLaterOptionLabel(opt)}
          checked={sendtype === id}
          onChange={() => changeSendLaterOption(id)}
          name={id}
          value={id}
          dataTest={`${dataTest}-radio-${id.toLowerCase()}`}
          withTitle={false}
        />
        <Typography text={t(opt.text)} type={TextType.BODY_TEXT_SMALL_LIGHT} className={`${rootClass}__cards-later-options-text`} />
      </Fragment>
    )
  }

  const renderSendLaterDescription = () =>
    !sendtype || sendtype === SendTimeType.STANDARD ? (
      t('SendDetails.SendMethod.Card.Later.Desc')
    ) : (
      <>
        <Typography text={t('SendDetails.SendMethod.Card.Later.Desc')} type={TextType.BODY_TEXT_SMALL_LIGHT} />
        <RadioGroup verticalLayout className={`${rootClass}__cards-later-options`}>
          {sendLaterOptions
            .filter((opt) => (hideSendMethodStaggered ? opt.id !== SendTimeType.STAGGERED : true))
            .map((opt) => renderSendLaterOption(opt))}
        </RadioGroup>
      </>
    )

  return (
    <>
      <Container className={classNames(rootClass, className, { [`${rootClass}__error`]: showNoMethodError })} data-test={dataTest}>
        <Typography text={t('Send Method')} type={TextType.PAGE_HEADER} className="push-up" />
        <Typography text={t('SendDetails.SendMethod.Sub')} />
        <LinkTextButton text={t('SendDetails.SendMethod.LearnMore')} hideIconLeft link={CHOOSE_ARTICLE_LINK} size={LinkTextButtonSize.SMALL} />
        <RadioCardGroup selectedOption={selectedBaseCardOption} onSelect={handleCardSelect} className={`${rootClass}__cards`}>
          <RadioCard
            title={t('SendDetails.SendMethod.Card.Now.Title')}
            description={t('SendDetails.SendMethod.Card.Now.Desc')}
            key={SendTimeType.STANDARD}
            className={`${rootClass}__radio-now`}
            dataTest={`${dataTest}-radio-now`}
          />
          <RadioCard
            title={t('SendDetails.SendMethod.Card.Later.Title')}
            description={renderSendLaterDescription()}
            key={SendTimeType.LATER}
            className={`${rootClass}__radio-later`}
            dataTest={`${dataTest}-radio-card-later`}
          />
        </RadioCardGroup>
        {sendtype === SendTimeType.STANDARD && renderSendImmediatelySuffix()}
        {(sendtype === SendTimeType.LATER || sendtype === SendTimeType.TIME_ZONE) && (
          <TimezoneSuffix rootClass={rootClass} dataTest={dataTest} showValidationErrorsByDefault={showValidationErrors} />
        )}
        {sendtype === SendTimeType.STAGGERED && (
          <StaggeredSuffix rootClass={rootClass} dataTest={dataTest} showValidationErrorsByDefault={showValidationErrors} />
        )}
        {sendtype === SendTimeType.ADAPTIVE && (
          <AdaptiveSuffix showValidationErrorsByDefault={showValidationErrors} rootClass={rootClass} dataTest={dataTest} />
        )}
      </Container>
      {showNoMethodError && renderValidation('SendDetails.SendMethod.NoMethod', 'ERROR', 'send-details-no-send-method')}
    </>
  )
}

export default SendDetailsSendMethod
