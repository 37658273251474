export const MIN_PAGINATION_ROWS = 10

export enum MessageStatus {
  PROGRAM_DRAFT = 'PROGRAM_DRAFT',
  PROGRAM_SAVED = 'PROGRAM_SAVED',
  MANUAL_DRAFT = 'MANUAL_DRAFT',
  MANUAL_SAVED = 'MANUAL_SAVED',
  WELCOME_DEFAULT = 'WELCOME_DEFAULT',
  WELCOME_USER = 'WELCOME_USER',
}
