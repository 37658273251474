import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/Button'
import Modal, { ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Typography, { ModalHeaderFormStyle } from '@components/Typography/Typography'
import { getUUID, useTranslation } from '@const/globals'
import { AccountSettings, Program } from '@graphql/types/query-types'
import {
  EditOutgoingWebhookStep,
  EditAddCrmStep,
  EditAlertStep,
  EditAPIStep,
  EditBranchStep,
  EditCopyStep,
  EditCreateInCRMStep,
  EditFieldSetStep,
  EditGotoStep,
  EditOptOutStep,
  EditSendStep,
  EditSmsStep,
  EditTaskStep,
  EditWaitStep,
} from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps'
import EditAppendToSegmentStepContainer from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditAppendToSegmentStep/EditAppendToSegmentStepContainer'
import EditCreateInCRMStepV2 from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStepV2/EditCreateInCRMStepV2'
import EditUpdateSubscriptionStep from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditUpdateSubscriptionStep/EditUpdateSubscriptionStep'
import ProgramStepIcon from '@src/pages/programs/edit/components/ProgramFlow/components/ProgramStepIcon/ProgramStepIcon'
import { useAccountSettings } from '@utils/account/account.utils'
import { hasAOContactsSource } from '@utils/program/program'
import { ProgramStepType, Step, Track } from '@utils/program/program.constants'

import './editStepModal.css'

interface EditStepModalProps {
  isOpen: boolean
  isViewOnly: boolean
  step: Step
  program: Program
  tracks: Track[]
  accountSettings?: AccountSettings
  closeModal(): void
  saveStepAndProgram(step: Step | null, program?: Program): void
  dataTest?: string
}

const rootClass = 'edit-step-modal'

export const EditStepModal: FC<EditStepModalProps> = (props: EditStepModalProps) => {
  const { step, tracks, closeModal, saveStepAndProgram, program, accountSettings, isOpen, isViewOnly, dataTest = rootClass } = props
  const aOContactsSource = hasAOContactsSource(program)
  const submitId = getUUID()
  const { hasNewCRMStep } = useAccountSettings()

  const { t } = useTranslation()

  const getEditBody = () => {
    switch (step.stepType) {
      case ProgramStepType.GOTO:
        return (
          <>
            <EditGotoStep step={step} tracks={tracks} submitId={submitId} saveStepAndProgram={saveStepAndProgram} isRunning={isViewOnly} />
            {renderEditFooter()}
          </>
        )
      case ProgramStepType.WAIT:
      case ProgramStepType.WAIT_UNTIL:
      case ProgramStepType.WAIT_UNTIL_IN_SEGMENT:
        return (
          <>
            <EditWaitStep
              step={step}
              tracks={tracks}
              submitId={submitId}
              saveStepAndProgram={saveStepAndProgram}
              program={program}
              isRunning={isViewOnly}
            />
            {renderEditFooter()}
          </>
        )
      case ProgramStepType.OPT_OUT:
        return (
          <>
            <EditOptOutStep step={step} tracks={tracks} submitId={submitId} saveStepAndProgram={saveStepAndProgram} isRunning={isViewOnly} />
            {renderEditFooter()}
          </>
        )
      case ProgramStepType.BRANCH:
        return (
          <>
            <EditBranchStep
              step={step}
              tracks={tracks}
              saveStepAndProgram={saveStepAndProgram}
              program={program}
              isRunning={isViewOnly}
              submitId={submitId}
            />
            {renderEditFooter()}
          </>
        )
      case ProgramStepType.COPY:
        return (
          <>
            <EditCopyStep
              step={step}
              tracks={tracks}
              submitId={submitId}
              saveStepAndProgram={saveStepAndProgram}
              program={program}
              isRunning={isViewOnly}
            />
            {renderEditFooter()}
          </>
        )
      case ProgramStepType.APPEND_TO_SEGMENT:
        return (
          <>
            <EditAppendToSegmentStepContainer
              step={step}
              program={program}
              submitId={submitId}
              saveStepAndProgram={saveStepAndProgram}
              closeModal={closeModal}
              isRunning={isViewOnly}
            />
            {renderEditFooter()}
          </>
        )
      case ProgramStepType.ALERT:
        return (
          <>
            <EditAlertStep
              step={step}
              tracks={tracks}
              submitId={submitId}
              saveStepAndProgram={saveStepAndProgram}
              program={program}
              isRunning={isViewOnly}
            />
            {renderEditFooter()}
          </>
        )
      case ProgramStepType.SEND:
        return (
          <>
            <EditSendStep step={step} submitId={submitId} program={program} saveStepAndProgram={saveStepAndProgram} isRunning={isViewOnly} />
            {renderEditFooter()}
          </>
        )
      case ProgramStepType.EXTERNAL_API:
        return (
          <>
            <EditAPIStep
              step={step}
              submitId={submitId}
              closeModal={closeModal}
              program={program}
              saveStepAndProgram={saveStepAndProgram}
              isRunning={isViewOnly}
            />
            {renderEditFooter()}
          </>
        )
      case ProgramStepType.TASK:
        return (
          <>
            <EditTaskStep step={step} submitId={submitId} program={program} saveStepAndProgram={saveStepAndProgram} isRunning={isViewOnly} />
            {renderEditFooter()}
          </>
        )
      case ProgramStepType.CRM_CREATE:
        return hasNewCRMStep ? (
          <EditCreateInCRMStepV2
            step={step}
            saveStepAndProgram={saveStepAndProgram}
            closeModal={closeModal}
            program={program}
            isRunning={isViewOnly}
          />
        ) : (
          <>
            <EditCreateInCRMStep
              step={step}
              submitId={submitId}
              closeModal={closeModal}
              program={program}
              saveStepAndProgram={saveStepAndProgram}
              isRunning={isViewOnly}
            />
            {!program.crm?.crmModelNotCurrent && renderEditFooter()}
          </>
        )
      case ProgramStepType.CRM_CAMPAIGN: {
        return <EditAddCrmStep step={step} closeModal={closeModal} program={program} saveStepAndProgram={saveStepAndProgram} isRunning={isViewOnly} />
      }
      case ProgramStepType.SMS:
        return <EditSmsStep step={step} closeModal={closeModal} program={program} saveStepAndProgram={saveStepAndProgram} isRunning={isViewOnly} />
      case ProgramStepType.FIELD_SET:
        return (
          <>
            <EditFieldSetStep
              step={step}
              tracks={tracks}
              submitId={submitId}
              saveStepAndProgram={saveStepAndProgram}
              program={program}
              accountSettings={accountSettings}
              isRunning={isViewOnly}
            />
            {renderEditFooter()}
          </>
        )
      case ProgramStepType.OUTGOING_WEBHOOK:
        return (
          <>
            <EditOutgoingWebhookStep
              closeModal={closeModal}
              isRunning={isViewOnly}
              saveStepAndProgram={saveStepAndProgram}
              step={step}
              submitId={submitId}
            />
            {renderEditFooter()}
          </>
        )
      case ProgramStepType.UPDATE_SUBSCRIPTION:
        return (
          <>
            <EditUpdateSubscriptionStep
              closeModal={closeModal}
              isRunning={isViewOnly}
              saveStepAndProgram={saveStepAndProgram}
              step={step}
              submitId={submitId}
            />
            {renderEditFooter()}
          </>
        )
    }
    return (
      <ModalFooter footerType={ModalFooterType.Form}>
        <Button onClick={closeModal} buttonType={ButtonType.PRIMARY}>
          Close
        </Button>
      </ModalFooter>
    )
  }

  const getStepTitle = () => {
    switch (step.stepType) {
      case ProgramStepType.GOTO:
        return 'Go To Step'
      case ProgramStepType.WAIT:
        return 'Wait'
      case ProgramStepType.WAIT_UNTIL:
        return 'Wait'
      case ProgramStepType.WAIT_UNTIL_IN_SEGMENT:
        return 'Wait'
      case ProgramStepType.OPT_OUT:
        return 'Opt-Out'
      case ProgramStepType.BRANCH:
        return 'Branch'
      case ProgramStepType.COPY:
        return 'Copy To List'
      case ProgramStepType.APPEND_TO_SEGMENT:
        return 'Append to segment'
      case ProgramStepType.ALERT:
        return 'Send Alert'
      case ProgramStepType.SEND:
        return 'Send Message'
      case ProgramStepType.SMS:
        return 'Send SMS'
      case ProgramStepType.TASK:
        return 'Add a Task'
      case ProgramStepType.EXTERNAL_API:
        return 'API'
      case ProgramStepType.CRM_CREATE:
        return 'Create in CRM'
      case ProgramStepType.CRM_CAMPAIGN:
        return 'Add to CRM'
      case ProgramStepType.FIELD_SET:
        return 'Change Field'
      case ProgramStepType.OUTGOING_WEBHOOK:
        return 'Outgoing Webhook'
      case ProgramStepType.UPDATE_SUBSCRIPTION:
        return 'Update Subscription'
    }
    return null
  }

  const renderEditFooter = () => {
    return (
      <ModalFooter footerType={ModalFooterType.Form} flexEnd>
        {isViewOnly ? (
          <Button buttonType={ButtonType.TERTIARY} onClick={closeModal} dataTest={`${dataTest}-close-button`}>
            {t('Close')}
          </Button>
        ) : (
          <>
            <Button buttonType={ButtonType.TERTIARY} onClick={closeModal} dataTest={`${dataTest}-close-button`}>
              {t('Cancel')}
            </Button>
            <Button
              buttonType={ButtonType.PRIMARY}
              dataTest={`${dataTest}-save-button`}
              className={`${rootClass}__submit`}
              onClick={() => {
                document.getElementById(submitId)?.click()
              }}
            >
              {t('Submit')}
            </Button>
          </>
        )}
      </ModalFooter>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      dataTest={dataTest}
      className={classNames(rootClass, {
        [`${rootClass}__running`]: isViewOnly,
      })}
      header={
        <ModalHeader headerType={ModalHeaderType.Form} hasHeaderIcon>
          <ProgramStepIcon aOContactsSource={aOContactsSource} step={props.step} />
          <Typography text={t(getStepTitle())} {...ModalHeaderFormStyle} className={`${rootClass}__title`} />
        </ModalHeader>
      }
    >
      {getEditBody()}
    </Modal>
  )
}

export default EditStepModal
