import React from 'react'

import { TFunction } from 'i18next'

import { YesNo } from '@components/ConfirmationModal/ConfirmationModal'
import { SvgNames } from '@components/Svg/index'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { EmailComposerModalState } from '@utils/composer/context/EmailComposer.context'

export const confirmPlainTextEdit = (
  updateModal: <T extends EmailComposerModalState, K extends keyof T>(modal: K, value: T[K], closeOthers?: boolean) => void,
  t: TFunction,
  onConfirm: VoidFunction
) => {
  updateModal('confirmation', {
    title: t('EmailComposer.Preview.EditPlainText.DynamicContent.Modal.Title'),
    titleIcon: SvgNames.warning,
    isYesNo: true,
    body: (
      <Typography
        text={t('EmailComposer.Preview.EditPlainText.DynamicContent.Modal.Body')}
        type={TextType.BODY_TEXT_LIGHT}
        tagProps={{ medium: { weight: TextWeight.MEDIUM, inline: true } }}
      />
    ),
    yesButtonText: t('EmailComposer.Preview.EditPlainText.DynamicContent.Modal.Primary'),
    noButtonText: t('Cancel'),
    onAnswer: (answer) => {
      updateModal('confirmation', undefined)
      if (answer === YesNo.YES) {
        onConfirm()
      }
    },
  })
}
