import React, { FC, useCallback, useEffect, useState } from 'react'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import Loader from '@components/Loader'
import { useTranslation } from '@const/globals'
import setCompanyName from '@graphql/mutations/setCompanyName'
import setCreatorTimezone from '@graphql/mutations/setCreatorTimezone'
import getCompanyInfo from '@graphql/queries/getCustomAccountCompanyInfo'
import {
  SetCompanyNameMutation,
  SetCompanyNameMutationVariables,
  SetCreatorTimezoneMutation,
  SetCreatorTimezoneMutationVariables,
} from '@graphql/types/mutation-types'
import {
  AddressResponse,
  CompanyPageResponse,
  GetCustomAccountCompanyInfoQuery,
  GetCustomAccountCompanyInfoQueryVariables,
} from '@graphql/types/query-types'
import { StatusToastType } from '@interface/StatusToast'
import Company from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Company/Company'
import {
  AddressMutationOptionTypes,
  AddressTypes,
} from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Company/enums/company.enums'
import { logNewRelicError } from '@utils/new-relic.utils'

export type AddressMutationFunctionType = (
  addressToSave: AddressResponse,
  addressType: AddressTypes,
  optionType: AddressMutationOptionTypes,
  UUID?: string
) => void

interface Props {
  setToastStatus: (value: StatusToastType) => void
  dataTest?: string
}

const CompanyContainer: FC<Props> = (props: Props) => {
  const { setToastStatus, dataTest } = props
  const [companyInfo, setCompanyInfo] = useState<CompanyPageResponse | undefined>(undefined)
  const { t } = useTranslation()
  const client = useApolloClient()

  const { data, loading, error, refetch } = useQuery<GetCustomAccountCompanyInfoQuery, GetCustomAccountCompanyInfoQueryVariables>(getCompanyInfo, {
    client,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const [changeCompanyName] = useMutation<SetCompanyNameMutation, SetCompanyNameMutationVariables>(setCompanyName, {
    client,
    fetchPolicy: 'no-cache',
  })

  const [changeAccountTimezone] = useMutation<SetCreatorTimezoneMutation, SetCreatorTimezoneMutationVariables>(setCreatorTimezone, {
    client,
    fetchPolicy: 'no-cache',
  })

  const onChangeName = useCallback((name: string) => {
    changeCompanyName({
      variables: {
        name,
      },
    })
      .then(() => {
        setToastStatus({
          showStatus: true,
          title: t('Success!'),
          statusMessage: t('Company.Name.Change.Success.Message'),
          successStatus: true,
        })
        refetch()
      })
      .catch((error) => {
        setToastStatus({
          showStatus: true,
          statusMessage: t('Something went wrong on our end. Please try again.'),
          successStatus: false,
        })
        logNewRelicError(error)
      })
  }, [])

  const onChangeTimezone = useCallback((timezone: string) => {
    changeAccountTimezone({
      variables: {
        timezone,
      },
    })
      .then(() => {
        setToastStatus({
          showStatus: true,
          title: t('Success!'),
          statusMessage: t('Timezone.Name.Change.Success.Message'),
          successStatus: true,
        })
        refetch()
      })
      .catch((error) => {
        setToastStatus({
          showStatus: true,
          statusMessage: t('Something went wrong on our end. Please try again.'),
          successStatus: false,
        })
        logNewRelicError(error)
      })
  }, [])

  useEffect(() => {
    if (!loading && data?.getCustomAccountCompanyInfo) {
      setCompanyInfo(data.getCustomAccountCompanyInfo)
    }
    if (error) {
      logNewRelicError(error)
    }
  }, [loading, error, data])

  if (loading) return <Loader center />

  if (!companyInfo) return null

  return (
    <Company
      companyInfo={companyInfo}
      setToastStatus={setToastStatus}
      onChangeName={onChangeName}
      onChangeTimezone={onChangeTimezone}
      dataTest={dataTest}
      refetchCompanyInfo={refetch}
    />
  )
}

export default CompanyContainer
