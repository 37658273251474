import { MouseEvent } from 'react'
import React from 'react'
import { Row } from 'react-table'

import { TFunction } from 'i18next'

import { TableActions } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.constants'
import { getActionProps } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.utils'
import { TableProps, Update, ListPageCommonState, ListPageTableActionCustomProps } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { SvgNames } from '@components/Svg'
import { RowAction, HeaderAction } from '@components/Table/Table'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { CustomObject } from '@src/pages/listingPages/CustomDataJoinView/utils/CustomDataJoinViewListingPage.interfaces'
import { getCustomObjectDisplayName } from '@src/pages/listingPages/CustomDataJoinView/utils/CustomDataJoinViewListingPage.utils'
import { ItemType } from '@utils/categorization'
import { relativeDate } from '@utils/date'
import { renderItemNameVal, renderPathToFolder } from '@utils/searchUtils'

export enum CustomDataJoinViewCustomTableActions {
  REFRESH = 'REFRESH',
}

enum CommonTableActions {
  MANAGE_TAGS = 'MANAGE_TAGS',
  MOVE_TO_FOLDER = 'MOVE_TO_FOLDER',
  ADD_TO_FAVORITES = 'ADD_TO_FAVORITES',
  REMOVE_FROM_FOLDER = 'REMOVE_FROM_FOLDER',
}

const rowActionCustomProps: ListPageTableActionCustomProps[] = [
  { name: CommonTableActions.ADD_TO_FAVORITES, position: 0, hasTopSection: false, isInDropdown: false },
  { name: CustomDataJoinViewCustomTableActions.REFRESH, position: 1, hasTopSection: false, isInDropdown: false },
  { name: CommonTableActions.REMOVE_FROM_FOLDER, position: 2, hasTopSection: false, isInDropdown: true },
  { name: CommonTableActions.MOVE_TO_FOLDER, position: 3, hasTopSection: false, isInDropdown: true },
]

const headerActionCustomProps: ListPageTableActionCustomProps[] = [
  { name: CommonTableActions.ADD_TO_FAVORITES, position: 0, hasTopSection: false, isInDropdown: false },
  { name: CommonTableActions.MANAGE_TAGS, position: 1, hasTopSection: false, isInDropdown: false },
  { name: CustomDataJoinViewCustomTableActions.REFRESH, position: 2, hasTopSection: false, isInDropdown: false },
  { name: CommonTableActions.MOVE_TO_FOLDER, position: 3, hasTopSection: false, isInDropdown: true },
  { name: CommonTableActions.REMOVE_FROM_FOLDER, position: 4, hasTopSection: false, isInDropdown: true },
]

const onCustomTableAction = (customTableAction: CustomDataJoinViewCustomTableActions, _update: Update, _listPageValues: ListPageCommonState) => {
  const customAction: { [key in CustomDataJoinViewCustomTableActions]: () => void } = {
    [CustomDataJoinViewCustomTableActions.REFRESH]: () => {
      alert(CustomDataJoinViewCustomTableActions.REFRESH)
    },
  }
  customAction[customTableAction]()
}

const getCustomRowActions = (tableActions: TableActions, t: Function): RowAction[] => [
  {
    label: t('Refresh'),
    icon: SvgNames.refresh,
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(CustomDataJoinViewCustomTableActions.REFRESH, row),
    ...getActionProps(CustomDataJoinViewCustomTableActions.REFRESH, rowActionCustomProps),
  },
]

const getCustomHeaderActions = (tableActions: TableActions, t: Function): HeaderAction[] => [
  {
    label: t('Refresh'),
    icon: SvgNames.refresh,
    hasTooltip: true,
    onClick: (_event: MouseEvent<HTMLButtonElement> | Event) => tableActions.customTableHeaderAction(CustomDataJoinViewCustomTableActions.REFRESH),
    ...getActionProps(CustomDataJoinViewCustomTableActions.REFRESH, headerActionCustomProps),
  },
]

const renderSearchColumns = (
  searchInAllItems: boolean | undefined,
  currentFolder: FolderData,
  search: string,
  folders: FolderData[],
  t: Function
): ColumnDefWithAdditionalProps<CustomObject, unknown>[] => [
  {
    header: 'Name',
    accessorKey: 'dataset_name',
    textAlign: 'left',
    cell: ({ row }) =>
      search ? renderItemNameVal(SvgNames.segmentsUnselected, getCustomObjectDisplayName(row.original), search, 'list-page-table-container') : null,
    enableFlexCell: true,
  },
  {
    header: 'Tags',
    accessorKey: 'tags',
    textAlign: 'left',
    maxSize: 200,
  },
  {
    header: 'Location',
    accessorKey: 'folderId',
    textAlign: 'left',
    maxSize: 200,
    cell: ({ cell }) =>
      renderPathToFolder(ItemType.CUSTOM_OBJECT, cell.getValue<number>(), folders, searchInAllItems, 'list-page-table-container', t, currentFolder),
  },
  {
    header: 'Last updated',
    accessorKey: 'updatedTime',
    textAlign: 'right',
    maxSize: 150,
    cell: ({ cell }) => relativeDate(cell.getValue<number>() ?? 0, true),
  },
]

export const tableProps = (t: TFunction, onRowClicked: (selectedItem: Row<CustomObject>) => void): TableProps => ({
  onRowClicked,
  columns: tableV2Columns,
  hasAutoSelectedRows: false,
  hasExpander: false,
  rowActionCustomProps,
  renderSearchColumns: (searchInAllItems: boolean | undefined, currentFolder: FolderData, search: string, folders: FolderData[]) =>
    renderSearchColumns(searchInAllItems, currentFolder, search, folders, t),
  headerActionCustomProps,
  listPage: 'CustomDataJoinView',
  getCustomHeaderActions: (tableActions) => getCustomHeaderActions(tableActions, t),
  getCustomRowActions: (tableActions) => getCustomRowActions(tableActions, t),
  onCustomTableAction,
  actonAssetType: ItemType.CUSTOM_OBJECT,
  shareModalText: '',
})

const tableV2Columns: ColumnDefWithAdditionalProps<CustomObject, unknown>[] = [
  {
    header: 'Name',
    accessorKey: 'dataset_name',
    textAlign: 'left',
    cell: ({ row }) => <TextWithTooltipOnEllip typographyProps={{ text: getCustomObjectDisplayName(row.original) }} />,
  },
  {
    header: 'Tags',
    accessorKey: 'tags',
    textAlign: 'left',
    maxSize: 200,
    enableSorting: false,
    enableCustomCellValue: true,
  },
  {
    header: 'Records',
    accessorKey: 'records',
    textAlign: 'right',
    maxSize: 100,
  },
  {
    header: 'Last updated',
    accessorKey: 'updatedTime',
    textAlign: 'right',
    maxSize: 150,
    cell: ({ cell }) => relativeDate(cell.getValue<number>() ?? 0, true),
  },
]
