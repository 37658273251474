import React, { FC, useContext } from 'react'

import classNames from 'classnames'

import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Toggle from '@components/Toggle'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { useTranslation } from '@utils/const/globals'

import { TRANSACTION_EMAIL_LINK } from './TransactionalEmailPane.utils'
import { SettingsFormChangeHandler } from '../../../../EmailComposerSettings.utils'

import './TransactionalEmailPane.css'

interface TransactionalEmailPaneProps {
  className?: string
  dataTest?: string
  isDisabled?: boolean
  onFieldChange: SettingsFormChangeHandler
}

const rootClass = 'transactional-email-pane'

const TransactionalEmailPane: FC<TransactionalEmailPaneProps> = (props) => {
  const { dataTest = rootClass, isDisabled, onFieldChange } = props
  const {
    api: { update },
    values: {
      message: { isTransactional },
      messageConfiguration: {
        settings: { disableTransactionalEmail },
      },
    },
  } = useContext(EmailComposerContext)
  const { t } = useTranslation()

  const handleTransactionalToggleChange = (isOn: boolean) => {
    onFieldChange('isTransactional', isOn)
    update({ message: { suppress_dups: !isOn } })
  }

  const toggleContainer = (
    <div
      className={classNames(`${rootClass}__toggle-container`, {
        [`${rootClass}__toggle-container-disabled`]: isDisabled || disableTransactionalEmail,
      })}
    >
      <Tooltip
        hide={!isDisabled}
        trigger={
          <div className={`${rootClass}__toggle-container__text`}>
            <Typography
              type={isDisabled ? TextType.BODY_TEXT_LIGHT : TextType.BODY_TEXT}
              weight={TextWeight.BOLD}
              text={t('EmailComposer.Settings.TransactionalEmail.Toggle.Header')}
            />
            <Typography
              text={t('EmailComposer.Settings.TransactionalEmail.Toggle.Description')}
              type={isDisabled ? TextType.BODY_TEXT_LIGHT : TextType.BODY_TEXT}
            />
          </div>
        }
      >
        {t(`EmailComposer.Settings.TransactionalEmail.Disabled.Tooltip`)}
      </Tooltip>
      <Tooltip
        hide={!disableTransactionalEmail}
        position={'left'}
        trigger={
          <Toggle isOn={isTransactional} onToggle={handleTransactionalToggleChange} large disabled={isDisabled || disableTransactionalEmail} />
        }
      >
        <Typography text={t('EmailComposer.Resend.Disabled')} type={TextType.BODY_TEXT_WHITE} />
      </Tooltip>
    </div>
  )

  return (
    <div className={`${rootClass}`}>
      <Typography
        type={TextType.BODY_TEXT_LIGHT}
        text={'EmailComposer.Settings.TransactionalEmail.Description'}
        tagProps={{ bold: { weight: TextWeight.BOLD } }}
        tagComponents={{
          TextLink: <TextLink link={TRANSACTION_EMAIL_LINK} size={TextLinkSize.LARGE} />,
        }}
        dataTest={`${dataTest}-description`}
        inline
      />
      {toggleContainer}
    </div>
  )
}

export default TransactionalEmailPane
