import React, { FC, useRef, useState } from 'react'

import classNames from 'classnames'
import { v4 as clipboardKey } from 'uuid'

import Button, { ButtonIconPosition, ButtonProps, ButtonType } from '@components/Button/Button'
import { SvgNames } from '@components/Svg/index'
import Svg, { SvgColor, SvgType } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './ButtonCopy.css'

interface ButtonCopyProps extends Omit<ButtonProps, 'children' | 'buttonType'> {
  className?: string
  dataTest?: string
  buttonText?: string
  iconOnly?: boolean
}

const COPIED_DELAY = 2000

const rootClass = 'button-copy'

const ButtonCopy: FC<ButtonCopyProps> = (props: ButtonCopyProps) => {
  const { onClick, disabled, dataTest = rootClass, className = '', buttonText = 'Copy', iconOnly = false } = props
  const { t } = useTranslation()
  const [clicked, setClicked] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>()

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onClick) {
      buttonRef.current?.blur()
      setClicked(true)
      setTimeout(() => {
        setClicked(false)
      }, COPIED_DELAY)
      onClick(e)
    }
  }

  return (
    <Tooltip
      key={clipboardKey()}
      isPopover
      hide={!iconOnly}
      trigger={
        <Button
          disabled={disabled}
          iconPosition={ButtonIconPosition.LEFT}
          buttonType={ButtonType.FLOAT_TEAL}
          onClick={handleClick}
          clickStyled={clicked && !iconOnly}
          register={buttonRef}
          className={classNames(rootClass, className)}
          dataTest={dataTest}
        >
          <Svg
            name={clicked && !iconOnly ? SvgNames.checkSuccessSolid : SvgNames.cloneSegment}
            type={SvgType.LARGER_ICON}
            fill={clicked && !iconOnly ? SvgColor.WHITE : disabled ? SvgColor.TEXT_GRAY : SvgColor.TEXT_TEAL}
            className={classNames({
              [`${rootClass}__svg`]: clicked,
              [`${rootClass}__svg-disabled`]: disabled && !iconOnly,
            })}
          />
          {!iconOnly && (
            <Typography
              text={t(clicked ? 'Copied!' : buttonText)}
              type={clicked ? TextType.BODY_TEXT_WHITE : disabled ? TextType.BODY_TEXT : TextType.LINK}
              weight={TextWeight.MEDIUM}
              lineHeight={LineHeight.MEDIUM_LARGE}
            />
          )}
        </Button>
      }
    >
      {t(clicked ? 'Copied!' : buttonText)}
    </Tooltip>
  )
}

export default ButtonCopy
