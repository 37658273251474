import React, { FC } from 'react'

import classNames from 'classnames'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextWeight } from '@components/Typography/Typography'

import './PageHeaderOption.css'

interface PageHeaderOptionProps {
  className?: string
  dataTest?: string
  text: string
  isSelected: boolean
  selectedIcon: SvgNames
  unselectedIcon: SvgNames
}

const rootClass = 'page-header-option'

const PageHeaderOption: FC<PageHeaderOptionProps> = (props: PageHeaderOptionProps) => {
  const { dataTest = rootClass, className = '', text, isSelected, selectedIcon, unselectedIcon } = props
  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div>
        <Svg
          className={isSelected ? `${rootClass}__option-svg` : `${rootClass}__option-svg-unselected`}
          name={isSelected ? selectedIcon : unselectedIcon}
          type={SvgType.LARGER_ICON}
        />
      </div>
      <Typography text={text} weight={TextWeight.MEDIUM} />
    </div>
  )
}

export default PageHeaderOption
