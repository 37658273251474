import { useCallback } from 'react'

import { useApolloClient } from '@apollo/client'
import uploadEmailCode from '@graphql/mutations/uploadEmailCode'
import { UploadEmailCodeMutation, UploadEmailCodeMutationVariables } from '@graphql/types/mutation-types'
import { FetchPromise } from '@utils/types'

export type UploadEmailCodePromiseType = (
  fileBase64: string,
  msgId: string,
  fileName: string,
  signal?: AbortSignal
) => FetchPromise<UploadEmailCodeMutation>

export interface UploadEmailCodeRequestTypes {
  uploadEmailCodeRequest: UploadEmailCodePromiseType
}

export const useUploadEmailRequest = (): UploadEmailCodeRequestTypes => {
  const actonClient = useApolloClient()

  const uploadEmailCodeRequest = useCallback(
    async (fileBase64: string, msgId: string, fileName: string, signal?: AbortSignal) =>
      await actonClient.mutate<UploadEmailCodeMutation, UploadEmailCodeMutationVariables>({
        mutation: uploadEmailCode,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        variables: { fileBase64, msgId, fileName },
        context: { fetchOptions: { signal } },
      }),
    [actonClient]
  )

  return {
    uploadEmailCodeRequest,
  }
}
