import React, { FC, useContext, useMemo } from 'react'

import classNames from 'classnames'

import Container from '@components/Container'
import Spinner, { LoaderSize } from '@components/Spinner/Spinner'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import AntiSpamValidations from '@src/pages/EmailComposer/components/common/AntiSpamValidations/AntiSpamValidations'
import ContentValidations from '@src/pages/EmailComposer/components/common/ContentValidations/ContentValidations'
import DeliverabilityValidations from '@src/pages/EmailComposer/components/common/DeliverabilityValidations/DeliverabilityValidations'
import { PersonalizationCheckCard } from '@src/pages/EmailComposer/components/common/PersonalizationCheckCard/PersonalizationCheckCard'
import { SpamCheckCard } from '@src/pages/EmailComposer/components/common/SpamCheckCard/SpamCheckCard'
import EmailTracking from '@src/pages/EmailComposer/components/ReviewAndSend/components/ReviewAndSendMain/components/EmailTracking'
import { RecipientsSection } from '@src/pages/EmailComposer/components/ReviewAndSend/components/ReviewAndSendMain/components/RecipientsSection/RecipientsSection'
import { SenderDetailsSection } from '@src/pages/EmailComposer/components/ReviewAndSend/components/ReviewAndSendMain/components/SenderDetailsSection'
import { SendMethodSection } from '@src/pages/EmailComposer/components/ReviewAndSend/components/ReviewAndSendMain/components/SendMethodSection'
import { SubjectValidationSection } from '@src/pages/EmailComposer/components/ReviewAndSend/components/ReviewAndSendMain/components/SubjectValidationSection'
import { TransactionalEmailSection } from '@src/pages/EmailComposer/components/ReviewAndSend/components/ReviewAndSendMain/components/TransactionalEmailSection'
import { useEmailValidations } from '@src/pages/EmailComposer/hooks/useEmailValidations'
import { useGetEmailValidations } from '@src/pages/EmailComposer/hooks/useGetEmailValidations'
import { useValidationSelectionsCount } from '@src/pages/EmailComposer/hooks/useValidationSelectionsCount'
import { EmailComposerContext, EmailPreviewPlatform } from '@utils/composer/context/EmailComposer.context'

import './ReviewAndSendMain.css'

interface ReviewAndSendMainProps {
  className: string
  dataTest?: string
}

const rootClass = 'review-and-send-main'

const ReviewAndSendMain: FC<ReviewAndSendMainProps> = ({ className, dataTest = rootClass }) => {
  const { t } = useTranslation()
  const {
    values: {
      validations: { validationSectionsStatus },
      preview: { platform },
      messageConfiguration: {
        reviewAndSend: {
          hideCheckFullPersonalization,
          hideRecipientsValidation,
          hideSendMethodValidation,
          hideTransactionalEmailValidation,
          disableEditSenderBtn,
        },
      },
      detectedURLChanges: { plainTextMode, uploadHtmlMode },
    },
  } = useContext(EmailComposerContext)

  useGetEmailValidations()

  const { contentValidationsList, antiSpamValidationsList, deliverabilityValidationsList } = useEmailValidations()

  const loadingValidation = useMemo(() => Object.values(validationSectionsStatus).some(({ loading }) => !!loading), [validationSectionsStatus])
  const { errors, warnings } = useValidationSelectionsCount()

  return (
    <div
      className={classNames(rootClass, className, {
        [`${rootClass}__mobile-review`]: platform === EmailPreviewPlatform.MOBILE,
      })}
      data-test={dataTest}
    >
      <Container noPadding>
        <div className={`${rootClass}__header flex-align-center`}>
          <Typography
            text={t(plainTextMode ? 'Review plain text email' : uploadHtmlMode ? 'Review.HTML.Email' : 'Review email')}
            type={TextType.MODAL_HEADLINE_LARGE}
            lineHeight={LineHeight.LARGER}
            weight={TextWeight.MEDIUM}
          />
          {loadingValidation ? (
            <Spinner size={LoaderSize.SMALL} unsizeWrapper />
          ) : (
            <div className="flex-align-center">
              <Typography
                text="Critical.Errors.Count"
                values={{ count: errors }}
                tagProps={{ medium: { weight: TextWeight.MEDIUM, className: undefined, inline: true } }}
                className={`${rootClass}__header__errors inset-left-x2`}
              />
              <Typography
                text="Warnings.Count"
                values={{ count: warnings }}
                tagProps={{ medium: { weight: TextWeight.MEDIUM, className: undefined, inline: true } }}
                className="inset-right-x2"
              />
            </div>
          )}
        </div>
        <SenderDetailsSection disableEditSenderBtn={disableEditSenderBtn} />
        <SubjectValidationSection />
        {!hideRecipientsValidation && <RecipientsSection />}
        {!hideTransactionalEmailValidation && <TransactionalEmailSection />}
        <ContentValidations getValidations={contentValidationsList} isInReview />
        <AntiSpamValidations validations={antiSpamValidationsList} isInReview />
        <DeliverabilityValidations getValidations={deliverabilityValidationsList} isInReview />
        {!hideSendMethodValidation && <SendMethodSection />}
        <EmailTracking rootClass={`${rootClass}__email-tracking`} />
      </Container>
      <SpamCheckCard />
      {!hideCheckFullPersonalization && <PersonalizationCheckCard />}
    </div>
  )
}

export default ReviewAndSendMain
