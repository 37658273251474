import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { MultiStyledStrings, renderTextLines } from '@components/Caution/Caution'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Typography, { LineHeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './InfoAction.css'

type ButtonControls = {
  label: string
  onClick: () => void
}

export enum InfoActionType {
  NORMAL = 'NORMAL',
  BANNER = 'BANNER',
}

type Props = {
  message?: string | ReactNode
  children?: ReactNode
  className?: string
  dataTest?: string
  multiline?: boolean
  textLines?: MultiStyledStrings[]
  primaryButton?: ButtonControls
  secondaryButton?: ButtonControls
  svgName?: SvgNames
  type?: InfoActionType
  animateDown?: boolean
}

const rootClass = 'info-action'

const InfoAction: FC<Props> = (props: Props) => {
  const {
    message,
    children,
    multiline,
    dataTest = rootClass,
    className = '',
    textLines,
    primaryButton,
    secondaryButton,
    svgName,
    type,
    animateDown = false,
  } = props

  const { t } = useTranslation()

  const renderWithT = message && typeof message === 'string' && !textLines

  return (
    <div
      data-test={`${dataTest}__info-action`}
      className={classNames(rootClass, className, {
        [`${rootClass}--multiline`]: multiline,
        [`${rootClass}--banner`]: type === InfoActionType.BANNER,
        [`${rootClass}--animate-down`]: animateDown,
      })}
    >
      <div className={`${rootClass}--wrapper`}>
        <span className={`${rootClass}__svg`}>
          <Svg name={svgName ? svgName : SvgNames.userAddFilled} type={SvgType.MEDIUM_LARGE_ICON} />
        </span>
        {textLines && renderTextLines(textLines)}
        {renderWithT && <Typography text={t(message)} lineHeight={LineHeight.MEDIUM_SMALL} />}
        {!renderWithT && message}
        {children ?? null}
      </div>
      {(primaryButton || secondaryButton) && (
        <div className={classNames(`${rootClass}--wrapper`, { [`${rootClass}--wrapper-buttons`]: !secondaryButton })}>
          {primaryButton && (
            <Button buttonType={ButtonType.PRIMARY} onClick={primaryButton.onClick} dataTest={`${rootClass}-primary-button`}>
              {primaryButton.label}
            </Button>
          )}
          {secondaryButton && (
            <Button
              className={`${rootClass}--secondary`}
              buttonType={ButtonType.TERTIARY}
              onClick={secondaryButton.onClick}
              dataTest={`${rootClass}-secondary-button`}
            >
              {secondaryButton.label}
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

export default InfoAction
