import React, { FC, ReactNode } from 'react'

import AssetPickerModal, { AssetPickerModalBaseProps, AssetPickerModalProps } from '@complex/AssetPickerModal/AssetPickerModal'
import { AssetPickerListingPageProps, AssetPickerSidebarProps, AssetPickerTableProps } from '@complex/AssetPickerModal/Context/AssetPicker.context'
import { FilterQueryParams } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { CustomSource, RenderSearchColumnsV2 } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { useAutomatedProgramsPickerCategorizationRequests } from '@components/AssetPickers/AutomatedProgramPickerModal/GraphQL/AutomatedProgramPickerModalRequests.categorization.graphQL'
import {
  getAllItemsRequestResultsUtils,
  getSuggestedAutomatedProgramsRequestResultsUtils,
} from '@components/AssetPickers/AutomatedProgramPickerModal/GraphQL/utils/AutomatedProgramPickerModalRequests.categorization.utils'
import { useTranslation } from '@const/globals'
import { Program } from '@graphql/types/query-types'
import { Row } from '@tanstack/react-table'
import { ItemType } from '@utils/categorization'
import {
  allProgramsFilter,
  CustomFilterDefinition,
  draftProgramsFilter,
  FilterDefinition,
  FilterTypes,
  pausedProgramsFilter,
  runningProgramsFilter,
  suggestedAutomatedProgramsFilter,
} from '@utils/filter'
import { ProgramState } from '@utils/program/program.constants'

import { renderCustomFilters } from './AutomatedProgramPickerModal.sidebar'
import { getInfoBanner, renderSearchTableColumns, renderTableColumns } from './AutomatedProgramPickerModal.tables'
import { FolderData } from '../../SortableFolders/components/Folder/Folder'

import './AutomatedProgramPickerModal.css'

interface AutomatedProgramPickerModalProps extends AssetPickerModalBaseProps {
  hasSuggestedPrograms?: boolean
  disabledRowTooltip?: (program: Program) => string | ReactNode
}

const AutomatedProgramPickerModal: FC<AutomatedProgramPickerModalProps> = (props) => {
  const { disableRowByCriteria, hasSuggestedPrograms, disabledRowTooltip } = props
  const { getSuggestedAutomatedProgramsRequest, getAllItemsRequest } = useAutomatedProgramsPickerCategorizationRequests()
  const { t } = useTranslation()

  const customFilters = [runningProgramsFilter, pausedProgramsFilter, draftProgramsFilter]

  const getCustomActiveFilter = (filter: string) => customFilters.find((item) => item.name === filter)

  const getCustomFilterParams = (activeFilter?: FilterDefinition) => {
    const values = {
      [runningProgramsFilter.name]: ProgramState.RUNNING,
      [pausedProgramsFilter.name]: ProgramState.PAUSED,
      [draftProgramsFilter.name]: ProgramState.DRAFT,
    }
    const query = activeFilter && activeFilter.name in values ? values[activeFilter.name] : ''
    return { field: 'status', query }
  }

  const renderSearchColumns: RenderSearchColumnsV2 = (
    _searchInAllItems: boolean | undefined,
    _currentFolder: FolderData,
    search: string,
    folders: FolderData[]
  ) => renderSearchTableColumns(search, folders, t, disableRowByCriteria, disabledRowTooltip)

  const tableProps: AssetPickerTableProps = {
    columns: renderTableColumns(t, disableRowByCriteria, disabledRowTooltip),
    renderSearchColumns,
    listPage: 'AutomatedPrograms',
    sortingBy: [{ id: 'lastUpdated', desc: true }],
    hasDisabledRowStyles: true,
    rowDisabled: disableRowByCriteria,
    rowTooltip: disabledRowTooltip ? ({ original }: Row<Program>) => disabledRowTooltip(original) : undefined,
  }

  const customSources: CustomSource[] = [
    {
      allItemFilter: suggestedAutomatedProgramsFilter,
      customRequestFilters: [
        {
          filter: suggestedAutomatedProgramsFilter,
          request: (currentPage = 0, params?: FilterQueryParams) =>
            getSuggestedAutomatedProgramsRequestResultsUtils(getSuggestedAutomatedProgramsRequest, '', currentPage, params),
          searchRequest: (query: string, currentPage = 0, params?: FilterQueryParams) =>
            getSuggestedAutomatedProgramsRequestResultsUtils(getSuggestedAutomatedProgramsRequest, query, currentPage, params),
        },
        {
          filter: allProgramsFilter,
          request: (_currentPage?: number, params?: FilterQueryParams) =>
            getAllItemsRequestResultsUtils(getAllItemsRequest, ItemType.PROGRAM, '', params),
          searchRequest: (query: string, _currentPage?: number, params?: FilterQueryParams) =>
            getAllItemsRequestResultsUtils(getAllItemsRequest, ItemType.PROGRAM, query, params),
        },
      ],
      filterInfoBanner: getInfoBanner,
      itemName: 'Program',
      label: 'Automated Programs',
      mainColumnName: 'Program title',
      value: suggestedAutomatedProgramsFilter.name,
    },
  ]

  const programsCustomDefaultFilters: CustomFilterDefinition[] = [
    {
      ...allProgramsFilter,
      position: 1,
    },
  ]

  const sidebarProps: AssetPickerSidebarProps = {
    hasFavorites: true,
    hasRecent: false,
    hasCreatedByMe: true,
    allItemFilter: hasSuggestedPrograms ? suggestedAutomatedProgramsFilter : allProgramsFilter,
    customDefaultFilters: hasSuggestedPrograms ? programsCustomDefaultFilters : undefined,
    renderCustomFilters: (params) => renderCustomFilters(params, t),
    customSources: hasSuggestedPrograms ? customSources : undefined,
    hideFilterCount: hasSuggestedPrograms,
  }

  const listingPageProps: AssetPickerListingPageProps = {
    getCustomActiveFilter,
    getCustomFilterParams,
    sidebarProps,
    hasCustomRequests: hasSuggestedPrograms,
    customFilters: [
      {
        field: 'status',
        values: [
          { id: FilterTypes.DRAFT, value: 'draft' },
          { id: FilterTypes.RUNNING, value: 'running' },
          { id: FilterTypes.PAUSED, value: 'stopped' },
        ],
      },
    ],
  }

  const assetPickerProps: AssetPickerModalProps = {
    listingPageProps,
    tableProps,
    hasTags: true,
    hasCustomFilters: true,
    hasSidebar: true,
    hasPreview: false,
    itemType: ItemType.PROGRAM,
    hideRecordsCount: hasSuggestedPrograms,
    ...props,
  }

  return <AssetPickerModal {...assetPickerProps} />
}

export default AutomatedProgramPickerModal
