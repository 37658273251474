import React, { FC, useState } from 'react'
import { useController, UseFormReturn } from 'react-hook-form'

import classNames from 'classnames'

import AddressModal from '@components/AddressModal/AddressModal'
import Button, { ButtonType } from '@components/Button'
import { LabelType, LabelV2 } from '@components/LabelV2/LabelV2'
import Modal, { ModalBody } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import ModalFooterV2 from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import ModalHeaderV2 from '@components/Modal/components/ModalHeaderV2/ModalHeaderV2'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2GroupedOption, SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import SelectV2Popover from '@components/SelectV2Popover/SelectV2Popover'
import Spinner from '@components/Spinner/Spinner'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor, SvgContainerType } from '@components/Svg/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { AddressResponse } from '@graphql/types/query-types'
import { MapBlockModalForm } from '@src/pages/EmailComposer/utils/Addons/customAddons.types'
import { renderBoldTextOnFirstMatch } from '@utils/typography'

import { parseAddress } from './MapBlockModal.utils'

import './MapBlockModal.css'

export const mapRatioOptions = [
  { label: 'Landscape (16:9)', value: '16' },
  { label: 'Portrait (4:3)', value: '4' },
  { label: 'Square (1:1)', value: '1' },
  { label: 'Portrait (3:4)', value: '3' },
  { label: 'Landscape (9:16)', value: '9' },
]

export const mapRatioConverted = [
  { label: '16', value: '640x360' },
  { label: '4', value: '640x480' },
  { label: '1', value: '640x640' },
  { label: '3', value: '480x640' },
  { label: '9', value: '360x640' },
]

export const mapZoomOptions = [
  { label: 'Very far', value: '8' },
  { label: 'Far', value: '12' },
  { label: 'Default', value: '15' },
  { label: 'Close', value: '17' },
  { label: 'Very close', value: '19' },
]

const mapLinkOptions = [
  { label: 'Google Maps', value: 'google' },
  { label: 'None', value: '' },
]

export interface MapBlockValues {
  addressName?: string
  address?: string
  city?: string
  country?: string
  state?: string
  street?: string
  street2?: string
  zip?: string
  isCustomAddress?: boolean
  mapRatio?: string
  mapZoom?: string
  link?: string
}

export interface MapBlockModalProps {
  className?: string
  dataTest?: string
  loading: boolean
  form: UseFormReturn<MapBlockModalForm>
  addressOptions?: SelectV2GroupedOption[]
  mapBlock: MapBlockValues
  onClose: () => void
  onAction: (mapBlock: MapBlockValues) => void
  isOpen: boolean
}

const rootClass = 'map-block-modal'

const MapBlockModal: FC<MapBlockModalProps> = (props: MapBlockModalProps) => {
  const { dataTest = rootClass, className = '', loading, form, addressOptions, mapBlock, onClose, onAction, isOpen } = props
  const [addressModalOpen, setAddressModalOpen] = useState<boolean>(false)
  const [addressSelectOpen, setAddressSelectOpen] = useState<boolean>(false)
  const [state, setState] = useState<MapBlockValues>({
    ...mapBlock,
  })

  const { t } = useTranslation()

  const { field: selectedAddress } = useController({ control: form.control, name: 'selectedAddress' })

  const isUserAddress = selectedAddress?.value?.label?.includes('User Address')
  const isDefaultAdddress = selectedAddress?.value?.label?.includes('Default Account Address')
  const buttonProps = { buttonType: ButtonType.INFO, className: `${rootClass}__select-popover__trigger` }

  const handleAction = () => {
    onAction({
      ...state,
      addressName: selectedAddress?.value?.label,
      address: selectedAddress?.value?.subText,
      street: selectedAddress?.value?.extraOptions?.street,
      street2: selectedAddress?.value?.extraOptions?.street2,
      city: selectedAddress?.value?.extraOptions?.city,
      state: selectedAddress?.value?.extraOptions?.state,
      zip: selectedAddress?.value?.extraOptions?.zip,
      country: selectedAddress?.value?.extraOptions?.country,
    })
  }

  const handleClose = () => {
    onClose()
  }

  const renderMenuAction = (isEdit: boolean) => (
    <Button
      className={`${rootClass}__menu-action-button`}
      buttonType={ButtonType.OUTLINE}
      fullWidth
      noTopBottomPadding
      onClick={() => {
        setAddressModalOpen(true)
        setAddressSelectOpen(false)
      }}
    >
      <Svg name={SvgNames.addLocation} type={SvgType.LARGER_ICON} containerType={SvgContainerType.STANDARD} />
      {isEdit ? t('Edit custom address') : t('Use a custom address')}
    </Button>
  )

  const getAnchorSvgName = (isCustomAddress?: boolean) => {
    if (selectedAddress?.value?.subText) {
      return isUserAddress ? SvgNames.userCircle : isDefaultAdddress || isCustomAddress ? SvgNames.buildings : SvgNames.location
    }
    return SvgNames.locationFill
  }

  const renderAnchor = (isCustomAddress?: boolean) => (
    <div className={`${rootClass}__select-popover-selected`}>
      <div className={`${rootClass}__select-popover-selected-icon`}>
        <Svg
          name={getAnchorSvgName(isCustomAddress)}
          fill={SvgColor.TEXT_TEAL}
          type={SvgType.SELECTV2_OPTION_ICON}
          containerType={SvgContainerType.LARGE}
        />
      </div>
      <div>
        <LabelV2 label={selectedAddress?.value?.label || 'No address selected'} labelType={LabelType.medium} />
        <Typography text={selectedAddress?.value?.subText || 'An address is required to add a map'} type={TextType.BODY_TEXT_SMALL_LIGHT} />
      </div>
    </div>
  )

  const renderCustomOption = (data: SelectV2SingleOption, search?: string) => (
    <>
      <Typography
        inline
        dataTest={'selectv2-option-value'}
        text={search ? renderBoldTextOnFirstMatch({ search, value: data.label }) : data.label}
        weight={TextWeight.MEDIUM}
      />
      {data.subText && <Typography text={data.subText} type={TextType.BODY_TEXT_LIGHT} lineHeight={LineHeight.MEDIUM_SMALL} />}
    </>
  )

  const getMapImageUrl = () => {
    const baseUrl = 'https://maps.googleapis.com/maps/api/staticmap'
    const address = encodeURIComponent(selectedAddress.value?.subText ?? '')
    const zoom = mapZoomOptions.find((option) => option.value === state.mapZoom)?.value
    const size = mapRatioConverted.find((option) => option.label === state.mapRatio)?.value
    const scale = 2
    const apiKey = process.env.NX_GOOGLE_MAPS_API_KEY

    return `${baseUrl}?center=${address}&zoom=${zoom}&size=${size}&scale=${scale}&markers=${address}&key=${apiKey}`
  }

  const header = (
    <ModalHeaderV2 headerType={ModalHeaderType.Form} className={`${rootClass}__header`} headerText={t(`EmailComposer.MapBlockModal.Header`)} />
  )
  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
      <ModalBody className={`${rootClass}__body`}>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {selectedAddress.value?.subText ? (
              <img
                style={{
                  height: 405,
                }}
                alt={'map'}
                src={getMapImageUrl()}
              />
            ) : (
              <div className={`${rootClass}__no-address-selected`}>
                <Svg name={SvgNames.location} type={SvgType.VERY_LARGE_ICON} containerType={SvgContainerType.STANDARD} fill={SvgColor.LIGHT_GRAY} />
              </div>
            )}
            <div className={`${rootClass}__address-container`}>
              <LabelV2 label={t('EmailComposer.MapBlockModal.Location')} labelType={LabelType.small} />
              {addressModalOpen ? (
                <AddressModal
                  isOpen
                  canDelete={false}
                  modalTitle={t('EmailComposer.MapBlockModal.CustomAddress')}
                  actionButtonLabel={!state.isCustomAddress ? t('Add') : t('Update')}
                  address={state.isCustomAddress ? selectedAddress.value?.extraOptions : undefined}
                  handleModalClose={() => {
                    setAddressModalOpen(false)
                  }}
                  onSave={(address: AddressResponse) => {
                    selectedAddress.onChange(
                      parseAddress(
                        { name: SvgNames.actOnPush, type: SvgType.SELECTV2_OPTION_ICON, containerType: SvgContainerType.LARGE },
                        { ...address, name: 'Custom address' }
                      )
                    )
                    setState((state) => ({ ...state, isCustomAddress: true }))
                  }}
                />
              ) : (
                <SelectV2Popover
                  renderTrigger={() => <Button {...buttonProps}>{t(selectedAddress?.value?.subText ? 'Change' : 'Select')}</Button>}
                  renderAnchor={() => renderAnchor(state.isCustomAddress)}
                  maxMenuHeight={414}
                  selectProps={{
                    groupedOptions: addressOptions,
                    placeholder: t('Search addresses'),
                    onChange: (value) => {
                      selectedAddress.onChange(value)
                      setState((state) => ({ ...state, isCustomAddress: false }))
                    },
                    insideModal: true,
                    value: selectedAddress.value,
                    renderCustomOption,
                    renderMenuAction: () => renderMenuAction(!!state.isCustomAddress),
                    menuIsOpen: addressSelectOpen,
                    optionsWithLargePadding: true,
                    minSearchOptions: 0,
                  }}
                  className={`${rootClass}__select-popover`}
                />
              )}
            </div>
            <div className={`${rootClass}__configs`}>
              <SelectV2
                insideModal
                isClearable={false}
                label={t('EmailComposer.MapBlockModal.AspectRatio')}
                options={mapRatioOptions}
                onChange={(event) => setState((state) => ({ ...state, mapRatio: event?.value }))}
                value={mapRatioOptions.find((option) => option.value === state.mapRatio)}
                defaultValue={mapRatioOptions[0].value}
                className={`${rootClass}__configs-select-ratio`}
              />
              <SelectV2
                insideModal
                isClearable={false}
                label={t('EmailComposer.MapBlockModal.MapZoom')}
                options={mapZoomOptions}
                onChange={(event) => setState((state) => ({ ...state, mapZoom: event?.value }))}
                value={mapZoomOptions.find((option) => option.value === state.mapZoom)}
                defaultValue={mapZoomOptions[2].value}
                className={`${rootClass}__configs-select-zoom`}
              />
              <SelectV2
                insideModal
                isClearable={false}
                label={t('EmailComposer.MapBlockModal.MapLink')}
                options={mapLinkOptions}
                onChange={(event) => setState((state) => ({ ...state, link: event?.value }))}
                value={mapLinkOptions.find((option) => option.value === state.link)}
                defaultValue={mapLinkOptions[0].value}
                className={`${rootClass}__configs-select-link`}
              />
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooterV2
        footerType={ModalFooterType.Form}
        className={`${rootClass}__footer`}
        buttons={{
          actionButtonLabel: t('EmailComposer.MapBlockModal.ActionButtonLabel'),
          actionButtonOnClick: handleAction,
          cancelButtonLabel: t('Cancel'),
        }}
        onClose={handleClose}
      />
    </Modal>
  )
}

export default MapBlockModal
