import React, { FC, useState } from 'react'

import Button, { ButtonType } from '@components/Button/Button'
import Container from '@components/Container'
import FormRow from '@components/FormRow/FormRow'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import HighlightableTextArea from '@src/pages/sms/edit/components/MessageComposer/components/HighlightableTextArea'
import { MAX_MESSAGE_LENGTH } from '@src/pages/sms/edit/EditSMSContainer'
import { OPT_IN_PERSONALIZATION } from '@src/pages/sms/utils/sms.utils'

import { SMSContextAPI } from '../../../context/SMSContext'
import Personalization from '../Personalization/Personalization'
import PreviewModal from '../PreviewModal/PreviewModal'
import ShortenerModal, { ShortenerModalResponse, recIdPersonalization } from '../ShortenerModal/ShortenerModal'

import './messageComposer.css'

interface Props {
  context: SMSContextAPI
  showPreview?: boolean
  maxMessageLength?: number
  dataTest?: string
  rootClass?: string
  showPersonalizationIcon?: boolean
  showOptOutIcon?: boolean
  showCalendarIcon?: boolean
  showUrlIcon?: boolean
  isInitialMessageEditor?: boolean
}

interface State {
  isPersonalizationOpen: boolean
  isShortenerModalOpen: boolean
  messageCaretPosition: number
  isPreviewModalOpen: boolean
  editingShortUrl: ShortUrlEdit | undefined
  messageText: string
  isErrDisplayed: boolean
}

type ShortUrlEdit = {
  shortUrl: string
  doTracking: boolean
  start: number
  end: number
}

export const optOutMsg = 'Txt STOP to OptOut'

const MessageComposer: FC<Props> = (props: Props) => {
  const {
    context,
    rootClass = 'message-composer',
    dataTest = 'message-composer',
    showPreview = false,
    maxMessageLength = MAX_MESSAGE_LENGTH,
    showPersonalizationIcon = true,
    showOptOutIcon = true,
    showCalendarIcon,
    isInitialMessageEditor,
  } = props
  const {
    values,
    values: { smsMessage },
    updateMessage,
    saveMessage,
  } = context

  const assetId = values.smsMessage.launchId || values.smsMessage.smsMessageId || ''
  const assetType = values.smsMessage.launchId ? 'SMS' : 'AJB'

  const [state, setState] = useState<State>({
    isPersonalizationOpen: false,
    isShortenerModalOpen: false,
    isPreviewModalOpen: false,
    messageCaretPosition: 0,
    editingShortUrl: undefined,
    messageText: optOutMsg,
    isErrDisplayed: false,
  })

  const hasShortUrlOn = assetId

  const { t } = useTranslation()

  const closeShortenerModal = () => {
    setState({
      ...state,
      isShortenerModalOpen: false,
      editingShortUrl: undefined,
    })
  }

  const insertLinkSave = (data: ShortenerModalResponse) => {
    let messageText: string
    if (state.editingShortUrl !== undefined) {
      messageText = `${smsMessage.messageText.slice(0, state.editingShortUrl.start)}${data.shortUrl}${smsMessage.messageText.slice(
        state.editingShortUrl.end
      )}`
    } else {
      messageText = `${smsMessage.messageText.slice(0, values.messageCaretPosition)}${data.shortUrl}${smsMessage.messageText.slice(
        values.messageCaretPosition
      )}`
    }
    saveMessage({
      ...smsMessage,
      messageText,
    })

    closeShortenerModal()
  }

  const checkSelectedShortUrl = () => {
    const patter = new RegExp(
      '(http://|https://)?(([a-zA-Z0-9]+).)?([a-zA-Z0-9]+).[a-zA-Z0-9]*.([a-z]{3}.)?([a-z]+)?/l/([a-zA-Z0-9]+)(/' + recIdPersonalization + ')?',
      'gm'
    )
    let match
    // Scan the text looking for short urls to check if cursor is hover one of them
    while ((match = patter.exec(smsMessage.messageText))) {
      if (state.messageCaretPosition >= match.index && state.messageCaretPosition <= patter.lastIndex) {
        let shortUrl = match[0]
        let doTracking = false
        const trackingPatter = new RegExp('/' + recIdPersonalization + '$')
        if (shortUrl.match(trackingPatter)) {
          doTracking = true
          shortUrl = shortUrl.replace(trackingPatter, '')
        }
        return {
          shortUrl,
          doTracking,
          start: match.index,
          end: patter.lastIndex,
        }
      }
    }
  }

  const openShortenerModal = () => {
    setState({
      ...state,
      isShortenerModalOpen: true,
      editingShortUrl: checkSelectedShortUrl(),
    })
  }

  const openPreviewModal = () => {
    setState({
      ...state,
      isPreviewModalOpen: true,
    })
  }

  const closePreviewModal = () => {
    setState({
      ...state,
      isPreviewModalOpen: false,
    })
  }

  const onChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const defaultText = new RegExp(`${optOutMsg}$`, 'g')
    if (isInitialMessageEditor && !e.target.value.match(defaultText)) {
      setState({ ...state, isErrDisplayed: true })
    } else {
      updateMessage(e.target.value)
      setState({ ...state, isErrDisplayed: false })
    }
  }

  const onBlur = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState({ ...state, messageCaretPosition: e.target.selectionStart })
    saveMessage({ ...smsMessage, messageText: e.target.value }, e.target.selectionStart)
  }

  const closePersonalization = (personalization: string) => {
    if (personalization) {
      saveMessage({
        ...smsMessage,
        messageText: `${smsMessage.messageText.slice(0, values.messageCaretPosition)}${personalization}${smsMessage.messageText.slice(
          values.messageCaretPosition
        )}`,
      })
    }
    setState({ ...state, isPersonalizationOpen: false })
  }

  const setOptInPersonalization = () => {
    saveMessage({
      ...smsMessage,
      messageText: `${smsMessage.messageText.slice(0, values.messageCaretPosition)}${OPT_IN_PERSONALIZATION}${smsMessage.messageText.slice(
        values.messageCaretPosition
      )}`,
    })
  }

  const onAddOptOut = () => {
    saveMessage({ ...smsMessage, messageText: `${smsMessage.messageText} ${t(optOutMsg)}` })
  }
  const optOutDisabled = smsMessage.messageText.includes(t('Txt STOP to OptOut'))
  const optOutTitle = t(optOutDisabled ? 'Opt-out Text already added' : 'Add Opt-out Text')

  const label = () => (
    <>
      {t('Message Text')}
      <span className={`${rootClass}__svg`}>
        {showPreview && (
          <Button buttonType={ButtonType.FLOAT} onClick={openPreviewModal} title={t('Message Preview')}>
            <Svg name={SvgNames.previewOn} type={SvgType.ICON} />
          </Button>
        )}
        {showCalendarIcon && (
          <Button buttonType={ButtonType.FLOAT} onClick={setOptInPersonalization} title={t('Insert Opt-in date')}>
            <Svg name={SvgNames.calendar} type={SvgType.ICON} />
          </Button>
        )}
        {hasShortUrlOn && (
          <Button buttonType={ButtonType.FLOAT} onClick={openShortenerModal} title={t('Insert Short Link')}>
            <Svg name={SvgNames.url} type={SvgType.ICON} />
          </Button>
        )}
        {showPersonalizationIcon && (
          <Button
            buttonType={ButtonType.FLOAT}
            onClick={() => {
              setState({ ...state, isPersonalizationOpen: true })
            }}
            title={t('Add Personalization Text')}
          >
            <Svg name={SvgNames.personalization} type={SvgType.ICON} />
          </Button>
        )}
        {showOptOutIcon && (
          <Button
            buttonType={ButtonType.FLOAT}
            disabled={optOutDisabled}
            dataTest={`${dataTest}__opt-out-button`}
            onClick={onAddOptOut}
            title={optOutTitle}
          >
            <Svg name={SvgNames.optOut} type={SvgType.ICON} />
          </Button>
        )}
      </span>
    </>
  )

  return (
    <Container title={'Message Composer'}>
      {state.isPersonalizationOpen && <Personalization closePersonalization={closePersonalization} />}
      {state.isShortenerModalOpen && (
        <ShortenerModal
          dataTest={`shortener-modal`}
          isOpen={state.isShortenerModalOpen}
          insertLinkSave={insertLinkSave}
          closeModal={closeShortenerModal}
          shortUrlEdit={state.editingShortUrl}
          assetId={assetId}
          assetType={assetType}
        />
      )}
      {state.isPreviewModalOpen && <PreviewModal dataTest={`preview-modal`} isOpen={state.isPreviewModalOpen} closeModal={closePreviewModal} />}
      <div className={`${rootClass}`}>
        <HighlightableTextArea
          dataTest={`${dataTest}__text-area`}
          label={label()}
          labelDisplay="flex"
          name={'Message Text'}
          required
          value={smsMessage.messageText}
          maxLength={maxMessageLength}
          onChangeHandler={onChangeHandler}
          onBlur={onBlur}
          hasError={values.errors.incompatibleCharacter || values.errors.sizeError}
        />
        {state.isErrDisplayed && <Typography text={t(`"${optOutMsg}" must be included`)} type={TextType.ERROR} dataTest={`${rootClass}__error`} />}
        <FormRow className={`${rootClass}__info`}>
          <div className={`${rootClass}__errors`}>
            {values.errors.incompatibleCharacter && (
              <div className={`${rootClass}__error`}>
                <span>{t('Only GSM-7 characters are allowed')}</span>
              </div>
            )}
            {values.errors.sizeError && (
              <div className={`${rootClass}__error`}>
                <span>{t(`Message text has a limit of ${maxMessageLength} characters`)}</span>
              </div>
            )}
          </div>
          <div className={`${rootClass}__counter`}>
            {`${t('Characters')}`}
            <span className={`${rootClass}__counter--bold`}>{values.segmentedMessage.size}</span>
            <span className={`${rootClass}__counter--bullet`}>{'\u2022'}</span>
            {t('Messages')}
            <span className={`${rootClass}__counter--bold`}>{values.segmentedMessage.segments.length}</span>
          </div>
        </FormRow>
      </div>
    </Container>
  )
}

export default MessageComposer
