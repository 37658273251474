import { gql } from '@apollo/client'

export default gql`
  query getFatigueRule($accountId: Long!) {
    getFatigueRule(accountId: $accountId) {
      fatigueFrequency
      fatigueInterval
    }
  }
`
