import { gql } from '@apollo/client'

export default gql`
  query getPreviewInfoForAsset($assetType: String!, $assetId: String!, $sharedAccountId: String!) {
    getPreviewInfoForAsset(assetType: $assetType, assetId: $assetId, sharedAccountId: $sharedAccountId) {
      parentAssetChangeOrReplace
      parentAssetPreviewInfo {
        isIframe
        isDownload
        isHTML
        url
        contents
        beeComposer
      }
      assets {
        id
        title
        type
        changeOrReplace
        contents
        url
        beeComposer
      }
    }
  }
`
