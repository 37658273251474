import React, { FC, useCallback, useEffect, useRef, useState } from 'react'

import HTMLPreview from '@components/HTMLPreview/HTMLPreview'
import Modal, { ModalBody } from '@components/Modal'
import EmailLayoutsModalContent from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/components/EmailLayoutsModalContent/EmailLayoutsModalContent'
import EmailLayoutsModalFooter from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/components/EmailLayoutsModalFooter'
import EmailLayoutsModalHeader from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/components/EmailLayoutsModalHeader'
import { EMAIL_LAYOUTS_DEFAULT_PAGE_SIZE } from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/constants/EmailLayoutsModal.constants'
import EmailLayoutsModalContentContext from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/context/EmailLayoutsModalContent.context'
import { EmailLayoutsModalContainerProps } from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/EmailLayoutsModalContainer'
import {
  EmailTemplateLayout,
  GetEmailTemplateLayoutsPromiseType,
} from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/graphQL/EmailLayoutsModal.graphQL'
import {
  EmailLayoutsModalView,
  initialEmailLayoutsModalSortState,
} from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/utils/EmailLayoutsModal.utils'
import { LAYOUT_DATA_ATTRIBUTE } from '@src/pages/EmailComposer/utils/BeeEditor.constants'
import { prepareCustomAddOnHTML } from '@src/pages/EmailComposer/utils/BeeEditor.utils'
import { ColumnSort } from '@tanstack/react-table'
import { SortDirection } from '@utils/common'

import './EmailLayoutsModal.css'

interface EmailLayoutsModalProps extends EmailLayoutsModalContainerProps {
  onGetEmailLayouts: GetEmailTemplateLayoutsPromiseType
  dataTest?: string
}

const rootClass = 'email-layouts-modal'

const saveScrollSessionKey = `${rootClass}-scroll-top`

const EmailLayoutsModal: FC<EmailLayoutsModalProps> = ({
  onAddOnDone,
  onGetEmailLayouts,
  layoutsType,
  layoutIdToReplace,
  sendPlainText,
  dataTest = rootClass,
}) => {
  const [layouts, setLayouts] = useState<EmailTemplateLayout[]>([])
  const [loadingData, setLoadingData] = useState<boolean>(true)
  const [loadingNextData, setLoadingNextData] = useState<boolean>(false)
  const [allLoaded, setAllLoaded] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [sortBy, setSortBy] = useState<ColumnSort>(initialEmailLayoutsModalSortState)
  const [selectedLayout, setSelectedLayout] = useState<EmailTemplateLayout>()
  const [layoutToPreview, setLayoutToPreview] = useState<EmailTemplateLayout>()
  const viewTypeRef = useRef<EmailLayoutsModalView>(EmailLayoutsModalView.GRID)

  const isReplaceFlow = !!layoutIdToReplace
  const layoutToInsert = layoutToPreview ?? selectedLayout
  const disableReplace = !!layoutIdToReplace && layoutToInsert?.stringId === layoutIdToReplace

  const handleCancel = useCallback(() => (layoutToPreview ? setLayoutToPreview(undefined) : onAddOnDone(undefined)), [layoutToPreview, onAddOnDone])
  const handlePreview = useCallback(() => selectedLayout && setLayoutToPreview(selectedLayout), [selectedLayout])
  const handleBack = useCallback(() => setLayoutToPreview(undefined), [])

  const handleInsert = useCallback(() => {
    if (!layoutToInsert) {
      return onAddOnDone(undefined)
    }

    const modules = sendPlainText
      ? [{ type: 'text', text: layoutToInsert.text }]
      : [{ type: 'html', html: prepareCustomAddOnHTML(layoutToInsert.html, 'data-column-type', `${layoutsType}-${layoutToInsert.stringId}`) }]

    onAddOnDone({
      type: 'rowAddon',
      value: {
        name: `${layoutsType}-${layoutToInsert.stringId}`,
        columns: [
          {
            weight: 12,
            modules,
          },
        ] as unknown as string,
        metadata: {
          [LAYOUT_DATA_ATTRIBUTE]: layoutToInsert.stringId,
        } as unknown as string,
      },
    })
  }, [onAddOnDone, layoutToInsert, layoutsType])

  useEffect(() => {
    setLoadingData(true)
    onGetEmailLayouts({ search: searchTerm, sortDirection: sortBy.desc ? SortDirection.DESC : SortDirection.ASC })
      .then((layouts) => {
        setLayouts(layouts)
        setAllLoaded(layouts.length < EMAIL_LAYOUTS_DEFAULT_PAGE_SIZE)
      })
      .finally(() => setLoadingData(false))
  }, [onGetEmailLayouts, searchTerm, sortBy])

  useEffect(() => {
    if (!layoutIdToReplace || !layouts.length || selectedLayout) {
      return
    }
    // Try to preselect image that much urlToReplace, if images loaded and user didn't select one yet
    const preselectLayout = layouts.find(({ stringId }) => stringId === layoutIdToReplace)
    if (preselectLayout) {
      setSelectedLayout(preselectLayout)
    }
  }, [layoutIdToReplace, layouts, selectedLayout])

  const handleLoadNext = useCallback(() => {
    if (!allLoaded) {
      setLoadingNextData(true)
      return onGetEmailLayouts({
        pageNumber: currentPage + 1,
        search: searchTerm,
        sortDirection: sortBy.desc ? SortDirection.DESC : SortDirection.ASC,
      })
        .then((layouts) => {
          setCurrentPage(currentPage + 1)
          setLayouts((cur) => [...cur, ...layouts])
          setAllLoaded(layouts.length < EMAIL_LAYOUTS_DEFAULT_PAGE_SIZE)
        })
        .finally(() => setLoadingNextData(false))
    }

    return Promise.resolve()
  }, [allLoaded, onGetEmailLayouts, currentPage, sortBy, searchTerm])

  return (
    <Modal
      className={rootClass}
      dataTest={dataTest}
      isOpen
      noPadding
      header={
        <EmailLayoutsModalHeader
          onBack={handleBack}
          onSearch={setSearchTerm}
          searchTerm={searchTerm}
          layoutsType={layoutsType}
          isReplaceFlow={isReplaceFlow}
          className={`${rootClass}__header`}
          dataTest={`${dataTest}-header`}
          previewItemTitle={layoutToPreview?.title}
        />
      }
    >
      <ModalBody className={`${rootClass}__body`} dataTest={`${dataTest}-body`}>
        {layoutToPreview ? (
          <HTMLPreview html={layoutToPreview.html ?? ''} dataTest={`${dataTest}-preview`} />
        ) : (
          <EmailLayoutsModalContentContext.Provider
            value={{
              layoutsType,
              layouts,
              loading: loadingData,
              loadingNextData,
              onSelect: setSelectedLayout,
              onPreview: setLayoutToPreview,
              sortBy,
              onSortColumnChange: setSortBy,
              selectedId: selectedLayout?.stringId,
              searchTerm,
              sendPlainText,
            }}
          >
            <EmailLayoutsModalContent
              viewTypeRef={viewTypeRef}
              onLoadNext={handleLoadNext}
              allLoaded={allLoaded}
              saveScrollSessionKey={saveScrollSessionKey}
              dataTest={`${dataTest}-content`}
            />
          </EmailLayoutsModalContentContext.Provider>
        )}
      </ModalBody>
      <EmailLayoutsModalFooter
        onInsert={handleInsert}
        onCancel={handleCancel}
        onPreview={handlePreview}
        layoutsType={layoutsType}
        isPreview={!!layoutToPreview}
        hasSelected={!!selectedLayout}
        selectedTitle={selectedLayout?.title}
        isReplaceFlow={isReplaceFlow}
        disableReplace={disableReplace}
        className={`${rootClass}__footer`}
        dataTest={`${dataTest}-footer`}
      />
    </Modal>
  )
}

export default EmailLayoutsModal
