import React from 'react'

import classNames from 'classnames'

import { ButtonIconProps, ButtonIconSize, ButtonIconType } from '@components/ButtonIcon/ButtonIcon'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography, { LineHeight, TextType, TextWeight, TypographyProps } from '@components/Typography/Typography'

export const typographyProps: Partial<TypographyProps> = {
  type: TextType.BODY_TEXT_GRAY,
  weight: TextWeight.MEDIUM,
  inline: true,
}

export const buttonProps: Partial<ButtonIconProps> = { type: ButtonIconType.LIGHT_TEAL_ON_HOVER, size: ButtonIconSize.LARGE }

export const renderValidation = (text: string | undefined) => {
  if (!text) {
    return null
  }
  return (
    <div className={classNames('flex-align-center', 'display-conditions-field__validation-message')}>
      <Svg type={SvgType.ICON} name={SvgNames.inputStatusInvalidNoFill} fill={SvgColor.ERROR_TEXT} className={'push-left'} />
      <Typography
        text={text}
        type={TextType.ERROR_NEW}
        lineHeight={LineHeight.MEDIUM_SMALL}
        tagProps={{ bold: { weight: TextWeight.BOLD } }}
        inline
      />
    </div>
  )
}
