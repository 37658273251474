import { createContext } from 'react'

import { BreadcrumbsItemType } from '@components/Breadcrumbs/Breadcrumbs'
import { Column } from '@components/ColumnsOrderModal/components/DraggableColumn/DraggableColumn'
import { ContactDetailsContainerProps } from '@components/ContactsDetails/ContactsDetailsContainer'
import { SegmentError } from '@components/SegmentSaveModal/SegmentSaveModal'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { Status } from '@components/StatusToast/StatusToast'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import { PageInput } from '@graphql/types/microservice/segment-types'
import { Folder } from '@interface/Folder'
import { ListPageStatusToast } from '@interface/ListPage.context'
import { SegmentInput } from '@interface/Segment'
import {
  CombineRowsType,
  ConditionType,
  EXPRESSION_EVALUATION_TYPE,
  NumericComparison,
  SaveType,
  SegmentComposerTab,
  SegmentDefinitionType,
  TextComparison,
} from '@src/pages/SegmentComposer/SegmentComposer.constants'
import { ComposerSegmentDefinition } from '@src/pages/SegmentComposer/SegmentComposer.interfaces'
import { Contact } from '@utils/contact/contact.constants'
import { EXPRESSION_TYPE, Segment } from '@utils/contactSegments/contactSegments.utils'

export interface OnSaveClickParams {
  isSavingFromModal?: boolean
  type?: SaveType
  segmentInput?: SegmentInput
}

interface DirectSelectSegmentComposerState {
  allContactsSegment?: Segment
  columns?: Column[]
  columnsToSave?: ContactDetailsContainerProps['columnsToSave']
  defaultSelectedContacts: Contact[]
  parentContactsPage: PageInput
  isViewingSelectedContacts: boolean
  selectedContacts: Contact[]
}

export interface SegmentComposerState extends DirectSelectSegmentComposerState {
  errors: Set<SegmentError>
  folders: Folder[]
  isClosing: boolean
  isSaving?: boolean
  loadingAssets: boolean
  selectedRows: string[]
  segment: SegmentInput
  segmentHierarchy: Segment[]
  segmentPath: BreadcrumbsItemType[]
  showFirstSaveModal: boolean
  showErrorsModal: boolean
  statusToast: ListPageStatusToast
  tags: LabelDto[]
  currentTab: string
  hasUnsavedChanges: boolean
  segmentDefinition: ComposerSegmentDefinition
  scoreSheetOptions: SelectV2SingleOption[]
  campaignOptions: SelectV2SingleOption[]
  senderOptions: SelectV2SingleOption[]
  subscriptionCategories: SelectV2SingleOption[]
  uclFieldsOptions: SelectV2SingleOption[]
}

export interface SegmentComposerAPI {
  onSaveClick: (params: OnSaveClickParams) => Promise<SegmentInput | undefined>
  values: SegmentComposerState & { isNew: boolean; segmentId?: string }
  update: (newState: Partial<SegmentComposerState>) => void
  onAddLinkedRow: (id: string) => void
}

export const segmentDefinitionInitialValues: ComposerSegmentDefinition = {
  type: SegmentDefinitionType.QUERY,
  customBooleanExpression: null,
  groups: [
    {
      id: 'root',
      name: 'Root Group',
      operator: CombineRowsType.AND,
      expanded: true,
      rows: [
        {
          factor: {
            condition: {
              type: ConditionType.TEXT_LIST,
              comparison: TextComparison.CONTAINS,
              values: ['test value', 'test value 2', 'test value 3'],
            },
            field: 'VALID_TEXT',
            type: EXPRESSION_TYPE.SMS,
            id: 'row1',
          },
        },
        {
          id: 'row-group-1',
          name: 'Group 1',
          expanded: true,
          rows: [
            {
              factor: {
                condition: {
                  type: ConditionType.TEXT_LIST,
                  comparison: TextComparison.CONTAINS,
                  values: ['test value', 'test value 2', 'test value 3'],
                },
                field: 'PHONE_IN_COUNTRY',
                type: EXPRESSION_TYPE.SMS,
                id: 'row2',
              },
            },
            {
              id: 'row-group-3',
              name: 'Group 1.1',
              expanded: true,
              rows: [
                {
                  factor: {
                    condition: {
                      comparison: NumericComparison.GREATER_THAN,
                      value: 5,
                      type: ConditionType.NUMERIC_VALUE,
                    },
                    type: EXPRESSION_TYPE.BEHAVIOR,
                    field: 'VALID_NUMBER',
                    id: 'row3',
                  },
                },
              ],
              operator: CombineRowsType.AND,
            },
            {
              factor: {
                condition: {
                  comparison: TextComparison.EQUALS,
                  type: ConditionType.TEXT_LIST,
                  values: ['New customers', 'Recurrent customers'],
                },
                field: 'VALID_TEXT',
                type: EXPRESSION_TYPE.BEHAVIOR,
                id: 'row4',
              },
            },
          ],
          operator: CombineRowsType.AND,
        },
        {
          id: 'row-group-2',
          name: 'Group 2',
          expanded: true,
          rows: [
            {
              factor: {
                condition: {
                  comparison: TextComparison.EQUALS,
                  type: ConditionType.TEXT_LIST,
                  values: ['New customers', 'Recurrent customers'],
                },
                field: 'VALID_TEXT',
                type: EXPRESSION_TYPE.BEHAVIOR,
                id: 'row5',
              },
            },
            {
              factor: {
                condition: {
                  comparison: TextComparison.EQUALS,
                  type: ConditionType.TEXT_LIST,
                  values: ['New customers', 'Recurrent customers'],
                },
                field: 'VALID_TEXT',
                type: EXPRESSION_TYPE.BEHAVIOR,
                id: 'row6',
              },
            },
            {
              factor: {
                condition: {
                  type: ConditionType.TEXT_LIST,
                  comparison: TextComparison.CONTAINS,
                  values: ['test value', 'test value 2', 'test value 3'],
                },
                field: 'PHONE_IN_COUNTRY',
                type: EXPRESSION_TYPE.SMS,
                id: 'row7',
              },
            },
          ],
          operator: CombineRowsType.OR,
        },
      ],
    },
  ],
  expressionEvaluationType: 'AND' as EXPRESSION_EVALUATION_TYPE,
}

export const segmentComposerInitialState: Omit<SegmentComposerState, 'segment'> = {
  defaultSelectedContacts: [],
  errors: new Set(),
  folders: [],
  parentContactsPage: {},
  currentTab: SegmentComposerTab.BUILD,
  isClosing: false,
  isViewingSelectedContacts: false,
  loadingAssets: true,
  selectedContacts: [],
  selectedRows: [],
  showFirstSaveModal: false,
  showErrorsModal: false,
  statusToast: {
    statusMessage: '',
    status: Status.SUCCESS,
    showStatusToast: false,
  },
  tags: [],
  hasUnsavedChanges: false,
  segmentDefinition: segmentDefinitionInitialValues,
  scoreSheetOptions: [],
  campaignOptions: [],
  senderOptions: [],
  subscriptionCategories: [],
  uclFieldsOptions: [],
  segmentHierarchy: [],
  segmentPath: [],
}

export const SegmentComposerContext = createContext<SegmentComposerAPI>({ values: segmentComposerInitialState } as any)
