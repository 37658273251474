import React, { FC, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { useApolloClient, useQuery } from '@apollo/client'
import programQuery from '@graphql/queries/automatedPrograms'
import getProgramContacts from '@graphql/queries/getProgramContacts'
import { GetProgramContactsQuery, GetProgramContactsQueryVariables, ProgramQuery, ProgramQueryVariables } from '@graphql/types/query-types'
import { SortByState } from '@utils/sms.utils'
import { MatchParams } from '@utils/types'

import ProgramSuppressions from './ProgramSuppressions'

interface State {
  data: ProgramContactList[]
  programName: string
  totalCount: number
  pageSize: number
  pageIndex: number
  pageCount: number
  query: string
  sortBy: SortByState[]
  resetPageIndex: boolean
}

interface ProgramContactList {
  row?: number
  email?: string
  name?: string
  listId?: string
  listName?: string
  count?: number
  since?: number
  recId?: string
  deleted?: boolean
  when?: string
  reason?: string
  reasonDetails?: string
}

const defaultSortBy: SortByState = {
  id: 'when',
  desc: true,
}

const defaultState: State = {
  data: [],
  programName: '',
  totalCount: 0,
  pageIndex: 0,
  pageSize: 10,
  pageCount: 1,
  sortBy: [defaultSortBy],
  query: '',
  resetPageIndex: false,
}

type ProgramSuppressionsContainerProps = RouteComponentProps<MatchParams>

const ProgramSuppressionsContainer: FC<ProgramSuppressionsContainerProps> = ({ match }: ProgramSuppressionsContainerProps) => {
  const client = useApolloClient()
  const [state, setState] = useState<State>(defaultState)
  const contactsUrl = (id: string) => `/acton/internalapi/program/contact/download/${id}?&stepid=""&state=SUPPRESS`

  const { loading: programLoading, data: program } = useQuery<ProgramQuery, ProgramQueryVariables>(programQuery, {
    query: programQuery,
    fetchPolicy: 'network-only',
    variables: {
      id: match.params.id,
    },
  })

  const getSortColumn = (sortId: string) => {
    return sortId === 'when' ? 'DATEADDED' : sortId === 'listName' ? 'SOURCELIST' : sortId.toUpperCase()
  }

  const {
    loading,
    error: pageError,
    data,
  } = useQuery<GetProgramContactsQuery, GetProgramContactsQueryVariables>(getProgramContacts, {
    client: client as any,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      programId: match.params.id,
      stepId: '',
      state: 'SUPPRESS',
      perPage: state.pageSize,
      currentPage: state.pageIndex,
      query: state.query,
      sortColumn: getSortColumn(state.sortBy[0].id),
      sortDirection: state.sortBy[0].desc ? 'DESC' : 'ASC',
    },
  })

  useEffect(() => {
    if (data?.getProgramContacts && data.getProgramContacts.programContacts) {
      updateData(data.getProgramContacts)
    }
  }, [data])

  useEffect(() => {
    if (program) {
      setState({ ...state, programName: program.program.name })
    }
  }, [program])

  const updateData = (data: any) => {
    const { programContacts, totalRecords, totalPages } = data

    const contacts = programContacts.map((entry: any) => {
      return entry.reason === 'deleted'
        ? {
            ...entry,
            reasonDetails: 'Contact was deleted',
          }
        : {
            ...entry,
          }
    })

    setState({ ...state, data: contacts, totalCount: totalRecords, pageCount: totalPages, resetPageIndex: false })
  }

  const fetchData = (pageIndex: number, pageSize: number) => {
    setState({ ...state, pageIndex, pageSize })
  }

  const onChangeSort = (sortBy: Array<any>) => {
    const validSortBy = sortBy.length > 0 ? sortBy : [defaultSortBy]
    setState({ ...state, sortBy: validSortBy, pageIndex: 0 })
  }

  const onSearch = (query: string) => {
    setState({ ...state, query, pageIndex: 0, resetPageIndex: true })
  }

  const removeContact = (recId: string) => {
    alert(recId)
  }

  return (
    <ProgramSuppressions
      {...state}
      title={`${state.programName ?? ''} Suppression List`}
      programId={match.params.id}
      loading={loading && programLoading}
      pageError={pageError}
      fetchData={fetchData}
      onChangeSort={onChangeSort}
      onSearch={onSearch}
      removeContact={removeContact}
      isEmptySearch={state.data.length === 0}
      downloadUrl={contactsUrl(match.params.id)}
      tableTitle={'Contact Entries'}
    />
  )
}

export default ProgramSuppressionsContainer
