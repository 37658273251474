import React, { FC, useCallback, useEffect, useRef, useState } from 'react'

import { PageHeaderProps, SidebarProps, Update } from '@complex/ListingPage/Context/ListingPageCommon.context'
import ListingPageContainer from '@complex/ListingPage/ListingPageContainer'
import Button, { ButtonType } from '@components/Button'
import Loader from '@components/Loader'
import StatusToast, { Status } from '@components/StatusToast/StatusToast'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ListMaintenanceProgramInput } from '@graphql/types/query-types'
import ProgramStepsAndDetailsContainer from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/ProgramStepsAndDetailsContainer'
import { ProgramStepsAndDetailsTabs } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/utils/ProgramStepsAndDetails.constants'
import {
  ListMaintenanceProgramsContext,
  listMaintenanceProgramsContextInitialState,
} from '@src/pages/listingPages/ListMaintenancePrograms/context/ListMaintenancePrograms.context'
import { useListMaintenanceProgramsListingPageRequests } from '@src/pages/listingPages/ListMaintenancePrograms/GraphQL/ListMaintenanceProgramsListingPageRequests.graphQL'
import {
  DEFAULT_LIST_PROGRAM,
  getActiveFilter,
  getCustomEmptyListingProps,
  getCustomFilterParams,
  ListMaintenanceProgramsCustomModals,
  renderCustomFilters,
  renderCustomModal,
} from '@src/pages/listingPages/ListMaintenancePrograms/utils/Helpers'
import { getTableProps } from '@src/pages/listingPages/ListMaintenancePrograms/utils/ListMaintenancePrograms.tables'
import { useListMaintenanceProgramsHandler } from '@src/pages/listingPages/ListMaintenancePrograms/utils/ListMaintenanceProgramsHandler'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import { allProgramsFilter } from '@utils/filter'
import { ListMaintenanceProgramsContextAPI, ListMaintenanceProgramsContextState } from '@utils/listingPage/listMaintenancePrograms.utils'
import { logNewRelicError } from '@utils/new-relic.utils'

import './ListMaintenanceProgramsListingPageContainer.css'

interface ListMaintenanceProgramsListingPageContainerProps {
  className?: string
  dataTest?: string
}

const rootClass = 'list-maintenance-programs-listing-page-container'

const ListMaintenanceProgramsListingPageContainer: FC<ListMaintenanceProgramsListingPageContainerProps> = (
  props: ListMaintenanceProgramsListingPageContainerProps
) => {
  const { dataTest = rootClass } = props

  const [containerValues, setContainerValues] = useState<ListMaintenanceProgramsContextState>(listMaintenanceProgramsContextInitialState)
  const { loadingProgram, program, statusToast } = containerValues

  const { userAllowedToCreatePrograms, userAllowedToDeletePrograms } = useAccountSettings()

  const newProgramRef = useRef(DEFAULT_LIST_PROGRAM)
  const newProgramButtonRef = useCallback((button: HTMLButtonElement) => {
    if (button && userAllowedToCreatePrograms) {
      const searchParams = new URLSearchParams(window.location.search)
      const listId = searchParams.get('listId')
      if (listId && button) {
        newProgramRef.current = { ...newProgramRef.current, sourceId: listId }
        button.click()
      }
    }
  }, [])

  const { createProgramRequest, deleteProgramsRequest } = useListMaintenanceProgramsListingPageRequests()

  const update = (values: Partial<ListMaintenanceProgramsContextState>) => {
    setContainerValues((state) => ({ ...state, ...values }))
  }

  const { deletePrograms, runProgram, setProgram, getProgram } = useListMaintenanceProgramsHandler({ update })

  const { t } = useTranslation()

  const saveProgram = (programData: Partial<ListMaintenanceProgramInput>, updateListingPage: Update) => {
    createProgramRequest({ ...newProgramRef.current, ...programData })
      .then((programId) => {
        update({ loadingProgram: true, newProgramId: programId })
        setProgram(programId)
      })
      .catch((error) => {
        logNewRelicError(error)
        updateListingPage({
          statusToast: {
            statusMessage: 'Something went wrong on our end. Please try again.',
            status: Status.FAIL,
            showStatusToast: true,
          },
        })
      })
      .finally(() => {
        newProgramRef.current = DEFAULT_LIST_PROGRAM
        updateListingPage({ showCustomModal: false })
      })
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has('programId')) {
      const tab = searchParams.get('tab')
      const backTo = searchParams.get('backTo') ?? ''
      update({ loadingProgram: true })
      setProgram(searchParams.get('programId') ?? '', { detailsPage: tab === ProgramStepsAndDetailsTabs.PROGRAM_DETAILS, backTo })
    }
  }, [])

  const sidebarProps: SidebarProps = {
    sidebarHeader: 'Program Manager',
    hasRecent: true,
    hasCreatedByMe: true,
    allItemFilter: allProgramsFilter,
    renderCustomFilters,
  }

  const pageHeaderProps: PageHeaderProps = {
    pageTitle: 'List Maintenance Programs',
    renderPageHeaderContent: (listingPageUpdate) =>
      userAllowedToCreatePrograms ? (
        <Button
          register={newProgramButtonRef}
          className={`${rootClass}__create-program-button`}
          buttonType={ButtonType.PRIMARY}
          onClick={() => listingPageUpdate({ showCustomModal: true, customTableAction: ListMaintenanceProgramsCustomModals.CREATE_PROGRAM })}
        >
          <Svg type={SvgType.ICON} name={SvgNames.plus} />
          <Typography text={t('New program')} type={TextType.BODY_TEXT_WHITE} />
        </Button>
      ) : null,
  }

  const context: ListMaintenanceProgramsContextAPI = {
    values: containerValues,
    deletePrograms,
    getProgram,
    runProgram,
    setProgram,
    update,
  }

  return (
    <ListMaintenanceProgramsContext.Provider value={context}>
      {statusToast?.showStatusToast && (
        <StatusToast
          message={statusToast?.statusMessage}
          status={statusToast.status}
          closeStatus={() => update({ statusToast: { ...statusToast, showStatusToast: false } })}
        />
      )}
      {loadingProgram ? (
        <Loader center />
      ) : program ? (
        <ProgramStepsAndDetailsContainer />
      ) : (
        <ListingPageContainer
          listingPageProps={{
            canDeleteItems: userAllowedToDeletePrograms,
            canShareToChildAccounts: false,
            canShareToCatalog: false,
            customDeleteItemsCall: deleteProgramsRequest,
            getCustomActiveFilter: getActiveFilter,
            getCustomEmptyListingProps,
            getCustomFilterParams: getCustomFilterParams,
            renderCustomModal: (customTableAction, _listPageValues, listPageAPI) =>
              renderCustomModal(customTableAction, listPageAPI, saveProgram, t, newProgramRef),
            pageHeaderProps,
            sidebarProps,
            tableProps: getTableProps(rootClass, runProgram, setProgram, userAllowedToCreatePrograms, t),
          }}
          dataTest={dataTest}
          itemType={ItemType.LIST_MAINTENANCE_PROGRAM}
        />
      )}
    </ListMaintenanceProgramsContext.Provider>
  )
}

export default ListMaintenanceProgramsListingPageContainer
