import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/Button'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'

import './UpgradeHelper.css'

interface UpgradeHelperProps {
  className?: string
  dataTest?: string
  onClick: () => void
}

const rootClass = 'upgrade-helper'

const UpgradeHelper: FC<UpgradeHelperProps> = (props: UpgradeHelperProps) => {
  const { dataTest = rootClass, className = '', onClick } = props
  const { t } = useTranslation()
  return (
    <Tooltip
      className={classNames(rootClass, className)}
      dataTest={dataTest}
      trigger={
        <Button className={`${rootClass}__button`} buttonType={ButtonType.ICON} onClick={onClick}>
          <Svg name={SvgNames.lightBulbNoBackground} type={SvgType.LARGE_ICON} />
        </Button>
      }
      position={'top'}
      align={'center'}
    >
      {t('Upgrade to Audience Center')}
    </Tooltip>
  )
}

export default UpgradeHelper
