import { useMemo } from 'react'

import { useApolloClient } from '@apollo/client'
import getWebinars from '@graphql/queries/getWebinars'
import getWebinarsByFilter from '@graphql/queries/getWebinarsByFilter'
import getWebinarsByTitle from '@graphql/queries/getWebinarsByTitle'
import {
  GetWebinarsByFilterQuery,
  GetWebinarsByFilterQueryVariables,
  GetWebinarsByTitleQuery,
  GetWebinarsByTitleQueryVariables,
  GetWebinarsQuery,
  GetWebinarsQueryVariables,
} from '@graphql/types/query-types'
import { createGraphQLQuery } from '@utils/apollo'

export const useWebinarRequests = () => {
  const actonClient = useApolloClient()

  return useMemo(
    () => ({
      getWebinarsRequest: createGraphQLQuery<GetWebinarsQuery, GetWebinarsQueryVariables>(actonClient, getWebinars),
      getWebinarsByFilterRequest: createGraphQLQuery<GetWebinarsByFilterQuery, GetWebinarsByFilterQueryVariables>(actonClient, getWebinarsByFilter),
      getWebinarsByTitle: createGraphQLQuery<GetWebinarsByTitleQuery, GetWebinarsByTitleQueryVariables>(actonClient, getWebinarsByTitle),
    }),
    [actonClient]
  )
}
