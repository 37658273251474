import React, { FC } from 'react'

import classNames from 'classnames'

import DownloadButton from '@components/DownloadButton/DownloadButton'
import MultiSelect from '@components/MultiSelect/MultiSelect'
import TableWithLoaderAndEmptyListing from '@components/TableWithLoaderAndEmptyListing/TableWithLoaderAndEmptyListing'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import { useTranslation } from '@const/globals'
import withError from '@hoc/withError/withError'

import { SharedTabProps, tagColumns } from '../../utils/LinkPerformance.utils'

import './TagsTab.css'

type Props = SharedTabProps

const rootClass = 'tags-tab'

const TagsTab: FC<Props> = (props: Props) => {
  const {
    dataTest = rootClass,
    className = '',
    data,
    tagOptions,
    tagOnsubmit,
    onDownload,
    fetchData,
    onSort,
    loading,
    totalCount,
    totalClicks,
    uniqueClicks,
    pageSize,
    pageIndex,
    sortBy,
  } = props

  const { t } = useTranslation()

  const pageCount = Math.ceil(totalCount / pageSize)

  const hasSelected = tagOptions.find((option) => option.selected)

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__inputs`}>
        <div className={`${rootClass}__left-inputs`}>
          <MultiSelect title={'Tags'} options={tagOptions} onSubmit={tagOnsubmit} className={`${rootClass}__multi-select`} />
          {hasSelected && (
            <TextLink
              text={t('Clear Filters')}
              onClick={() => tagOnsubmit([])}
              className={`${rootClass}__clear-text`}
              dataTest={`${dataTest}__clear`}
              size={TextLinkSize.LARGE}
              hideIcon
            />
          )}
        </div>
        <DownloadButton downloadFn={onDownload} label={'Export Results'} hasToolTip={false} />
      </div>

      <TableWithLoaderAndEmptyListing
        className={`${rootClass}__table-wrapper`}
        data={data}
        columns={tagColumns(totalClicks, uniqueClicks)}
        hasFooter
        loading={loading}
        text={'No Tags'}
        initialState={{ pageSize, pageIndex, sortBy }}
        canPaginate
        onSort={onSort}
        fetchData={fetchData}
        controlledPageCount={pageCount}
        manualSortBy
        disableSortRemove
        disableMultiSort
        autoHeight
      />
    </div>
  )
}

export default withError(TagsTab)
