export enum SEGMENT_TYPES {
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  MESSAGE_OPEN = 'MESSAGE_OPEN',
  MESSAGE_CLICK = 'MESSAGE_CLICK',
  MESSAGE_SENT_NOT_OPEN_CLICK = 'MESSAGE_SENT_NOT_OPEN_CLICK',
  MESSAGE_SENT_NOT_CLICK = 'MESSAGE_SENT_NOT_CLICK',
  MESSAGE_SENT_NOT_OPEN = 'MESSAGE_SENT_NOT_OPEN',
  MESSAGE_SEND = 'MESSAGE_SEND',
  NOT_MESSAGE_SEND = 'NOT_MESSAGE_SEND',
}

export const SEGMENT_OPTIONS = [
  {
    type: SEGMENT_TYPES.IN,
    text: 'If Prospect Is In',
  },
  {
    type: SEGMENT_TYPES.NOT_IN,
    text: 'If Prospect Is Not In',
  },
  {
    type: SEGMENT_TYPES.MESSAGE_OPEN,
    text: 'If Prospect Opened Message',
  },
  {
    type: SEGMENT_TYPES.MESSAGE_CLICK,
    text: 'If Prospect Clicked On Message',
  },
  {
    type: SEGMENT_TYPES.MESSAGE_SENT_NOT_OPEN_CLICK,
    text: 'If Prospect Was Sent, But Did Not Open Or Click On Message',
  },
  {
    type: SEGMENT_TYPES.MESSAGE_SENT_NOT_CLICK,
    text: 'If Prospect Was Sent, But Did Not Click On Message',
  },
  {
    type: SEGMENT_TYPES.MESSAGE_SENT_NOT_OPEN,
    text: 'If Prospect Was Sent, But Did Not Open Message',
  },
  {
    type: SEGMENT_TYPES.MESSAGE_SEND,
    text: 'If Prospect Was Sent Message',
  },
  {
    type: SEGMENT_TYPES.NOT_MESSAGE_SEND,
    text: 'If Prospect Was Not Sent Message',
  },
]
