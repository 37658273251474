import { gql } from '@apollo/client'

export default gql`
  query getAutomatedProgramEnterExitCounts($programId: String!, $noUpdate: Boolean!, $unitsAgo: Int!, $groupBy: String!) {
    getAutomatedProgramEnterExitCounts(programId: $programId, noUpdate: $noUpdate, unitsAgo: $unitsAgo, groupBy: $groupBy) {
      enterDay {
        date
        count
      }
      exitDay {
        date
        count
      }
      totalEnters
      totalExits
    }
  }
`
