export const isIframeEmpty = (iframe: HTMLIFrameElement): boolean => {
  if (!iframe || !iframe.contentDocument || !iframe.contentDocument.body) {
    return true
  }

  return isHtmlEmpty(iframe.contentDocument.body)
}

const stripScriptAndStyleNodes = (parentNode: HTMLElement) => {
  const parent = parentNode.cloneNode(true)
  const scriptNodes: Node[] = []
  parent.childNodes.forEach((node) => {
    if (node.nodeName === 'SCRIPT' || node.nodeName === 'STYLE') {
      scriptNodes.push(node)
    }
  })
  scriptNodes.forEach((node) => parent.removeChild(node))
  return parent
}

export const isHtmlEmpty = (container: HTMLElement | null): boolean => {
  if (!container) {
    return true
  }

  const visibleTags = ['img', 'object', 'hr', 'audio', 'video', 'button', 'input', 'select', 'textarea']
  const firstVisibleElement = container.querySelector(visibleTags.join(','))

  // Check landing page conditions
  const firstVisibleElementIsTrackerImage = (firstVisibleElement as HTMLImageElement)?.src?.endsWith('endline.gif')
  const iframeText = (stripScriptAndStyleNodes(container).textContent ?? '').trim()
  const onlyContainsClicksHeatmap = iframeText === 'Hide Clicks'
  const iframes = container.querySelectorAll('iframe')
  const textExists = iframeText.length > 0 && !onlyContainsClicksHeatmap

  if (textExists || (firstVisibleElement && !firstVisibleElementIsTrackerImage)) {
    return false
  }

  for (const iframe of iframes) {
    if (!isIframeEmpty(iframe)) {
      return false
    }
  }

  return true
}
