import { SvgNames } from '@components/Svg'
import { ColumnStateType, VALIDATION_MESSAGE } from '@src/pages/importcontacts/context/ImportContactsContext'

const rootClass = 'column-state'

interface ColumnState {
  dataTest: string
  name: SvgNames
  className: string
  text: string
  textBold?: string
}

export const columnStates: { [key: string]: ColumnState } = {
  [ColumnStateType.WARNING]: {
    dataTest: `${rootClass}-warning`,
    name: SvgNames.warning,
    className: `${rootClass}__warning`,
    text: 'Caution: Some field values do not match the selected data type.',
  },
  [ColumnStateType.EXCEPTION]: {
    dataTest: `${rootClass}-exception`,
    name: SvgNames.cancelSend,
    className: `${rootClass}__exception`,
    text: 'Unable to map field: too many values do not match the selected data type. Use data preview and try again.',
  },
  [ColumnStateType.DUPLICATE]: {
    dataTest: `${rootClass}-duplicate`,
    name: SvgNames.cancelSend,
    className: `${rootClass}__exception`,
    text: VALIDATION_MESSAGE.DUPLICATED_FIELDS,
  },
  [ColumnStateType.CUSTOM_FIELD_IN_USE]: {
    dataTest: `${rootClass}-custom-in-use`,
    name: SvgNames.cancelSend,
    className: `${rootClass}__exception`,
    text: VALIDATION_MESSAGE.CUSTOM_FIELD_IN_USE,
  },
  [ColumnStateType.CUSTOM_FIELD_DELETED]: {
    dataTest: `${rootClass}-custom-deleted`,
    name: SvgNames.warning,
    className: `${rootClass}__warning`,
    textBold: 'This field was previously removed from your list',
    text: 'If you restore it, previous data in this field will be restored, but may be out of date. If you don’t wish to restore the previous data, give this field a new name.',
  },
  [ColumnStateType.SUCCESS]: {
    dataTest: `${rootClass}-success`,
    name: SvgNames.checkCircle,
    className: `${rootClass}__check`,
    text: 'Mapping successful!',
  },
}
