import { TabItemData } from '@components/TabsAO/TabsAO'
import { SelectOption } from '@interface/Select'

export enum OpportunitiesFirstOption {
  BY_SOURCE = 'bySource',
  BY_CAMPAIGN = 'byCampaign',
  OVER_TIME = 'overTime',
}

export const getOpportunitiesFirstOptions = (hideOverTime: boolean, t: (s: any) => any): (SelectOption & { value: OpportunitiesFirstOption })[] => [
  {
    value: OpportunitiesFirstOption.BY_SOURCE,
    label: 'Lead source',
  },
  {
    value: OpportunitiesFirstOption.BY_CAMPAIGN,
    label: 'Campaign Type',
  },
  {
    value: OpportunitiesFirstOption.OVER_TIME,
    label: 'Over Time',
    disabled: hideOverTime,
    disabledTooltip: t('Revenue.OverTime.Disabled.Tooltip'),
  },
]

export enum OpportunitiesSecondOption {
  COUNT = 'count',
  REVENUE = 'revenue',
}

export const OPPORTUNITIES_SECOND_OPTIONS: {
  value: OpportunitiesSecondOption
  label: string
}[] = [
  {
    value: OpportunitiesSecondOption.COUNT,
    label: 'Count',
  },
  {
    value: OpportunitiesSecondOption.REVENUE,
    label: 'Revenue',
  },
]

export enum OpportunitiesTabIndex {
  All = 'all',
  NEW_OP = 'newOp',
  WON_OP = 'wonOp',
}

export const TABS_OPTIONS: (TabItemData & { index: OpportunitiesTabIndex })[] = [
  {
    index: OpportunitiesTabIndex.All,
    label: 'All Opportunities',
  },
  {
    index: OpportunitiesTabIndex.NEW_OP,
    label: 'New Opportunities',
  },
  {
    index: OpportunitiesTabIndex.WON_OP,
    label: 'Won Opportunities',
  },
]
