import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { Update } from '@src/pages/ContactSegments/components/AddContactsFromCRM/AddContactsFromCRMContext'
import { showFailedStatusToast } from '@src/pages/ContactSegments/components/AddContactsFromCRM/Helpers/AddContactsFromCRM.crm.helper'
import { logNewRelicError } from '@utils/new-relic.utils'

import { GetCRMSourcedSegmentsRequestType, SearchCRMSourcedSegmentsRequestType } from '../GraphQL/AddContactsFromCRMRequests.categorization.graphQL'

interface GetCRMSourcedSegmentsUtilsParams {
  getCRMSourcedSegmentsRequest: GetCRMSourcedSegmentsRequestType
  pageNumber: number
  update: Update
}

interface SearchCRMSourcedSegmentsUtilsParams {
  searchCRMSourcedSegmentsRequest: SearchCRMSourcedSegmentsRequestType
  search: string
  update: Update
}

const convertToSelectOption = (segments: ItemDto[]): SelectV2SingleOption[] =>
  segments?.map((item) => {
    const { id: value, name: label } = JSON.parse(item?.item ?? '')
    return { label, value }
  })

export const getCRMSourcedSegmentsUtils = async ({ getCRMSourcedSegmentsRequest, pageNumber, update }: GetCRMSourcedSegmentsUtilsParams) => {
  try {
    const { data } = await getCRMSourcedSegmentsRequest({ pageNumber })
    if (data?.getAllItems) {
      return data.getAllItems
    } else {
      showFailedStatusToast({ update, statusMessage: 'AddContactsFromCRM.StatusToast.Failed.GetAllItems' })
      return []
    }
  } catch (error) {
    logNewRelicError(error)
    showFailedStatusToast({ update, statusMessage: 'AddContactsFromCRM.StatusToast.Failed.GetAllItems' })
    return []
  }
}

export const getCRMSourcedSegmentOptionsUtils = async (params: GetCRMSourcedSegmentsUtilsParams) => {
  const segments = await getCRMSourcedSegmentsUtils(params)
  return convertToSelectOption(segments as ItemDto[])
}

export const searchCRMSourcedSegmentsUtils = async ({ searchCRMSourcedSegmentsRequest, search, update }: SearchCRMSourcedSegmentsUtilsParams) => {
  try {
    const { data } = await searchCRMSourcedSegmentsRequest({ search })
    if (data?.search) {
      return data.search?.items
    } else {
      return []
    }
  } catch (error) {
    logNewRelicError(error)
    showFailedStatusToast({ update, statusMessage: 'AddContactsFromCRM.StatusToast.Failed.SearchSegments' })
    return []
  }
}

export const searchCRMSourcedSegmentOptionsUtils = async (params: SearchCRMSourcedSegmentsUtilsParams) => {
  const segments = await searchCRMSourcedSegmentsUtils(params)
  return convertToSelectOption(segments as ItemDto[])
}
