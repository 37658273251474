import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import PageContainer from '@components/PageContainer/PageContainer'
import PageHeader from '@components/PageHeader'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import StatusToast, { Status } from '@components/StatusToast/StatusToast'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { RowAction } from '@components/Table/Table'
import { TableV2 } from '@components/TableV2/TableV2'
import Typography, { ModalBodyStyle, TextAlign, TextType, TextWeight } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import { SubscriptionManagementDtoInput } from '@graphql/types/mutation-types'
import AddEditVersionModal from '@src/pages/SubscriptionManagement/components/AddEditVersionModal/AddEditVersionModal'
import PreviewVersionModal from '@src/pages/SubscriptionManagement/components/PreviewVersionModal/PreviewVersionModal'
import UrlVersionModal from '@src/pages/SubscriptionManagement/components/UrlVersionModal/UrlVersionModal'
import { useSubscriptionManagementRequests } from '@src/pages/SubscriptionManagement/GraphQL/SubscriptionManagementRequest.graphQL'
import { languagesVersionsOptions } from '@src/pages/SubscriptionManagement/mocks/subscriptionManagementMocks'
import { SubscriptionManagementDTO, VersionState } from '@src/pages/SubscriptionManagement/SubcriptionManagement.constants'
import { columnsSubscriptionManagement, getRowActions } from '@src/pages/SubscriptionManagement/SubscriptionManagement.table.utils'
import {
  deleteSM,
  initSubscriptionManagementLanguages,
  initSubscriptionManagementVersions,
} from '@src/pages/SubscriptionManagement/SubscriptionManagement.utils'

import './SubscriptionManagement.css'

interface SubscriptionManagementProps {
  dataTest?: string
}

export interface SubscriptionManagementState {
  versions: SubscriptionManagementDTO[]
  showCreateEditVersionModal: boolean
  showUrlVersionModal: boolean
  showPreviewVersionModal: boolean
  languages: { value: string; label: string }[]
  selectedVersion?: SubscriptionManagementDTO
  showDeleteModal: boolean
  statusToast: { statusMessage: string | ReactNode; status: Status; showStatusToast: boolean }
  loading?: boolean
}

export const initialState: SubscriptionManagementState = {
  versions: [],
  showCreateEditVersionModal: false,
  showUrlVersionModal: false,
  languages: languagesVersionsOptions,
  showDeleteModal: false,
  statusToast: { statusMessage: '', status: Status.SUCCESS, showStatusToast: false },
  loading: false,
  showPreviewVersionModal: false,
}
const rootClass = 'subscription-management'

const SubscriptionManagement: FC<SubscriptionManagementProps> = (props: SubscriptionManagementProps) => {
  const { dataTest = rootClass } = props
  const [state, setState] = useState<SubscriptionManagementState>(initialState)
  const {
    versions,
    showCreateEditVersionModal,
    languages,
    showDeleteModal,
    selectedVersion,
    statusToast,
    loading,
    showUrlVersionModal,
    showPreviewVersionModal,
  } = state
  const { t } = useTranslation()
  const { getSubscriptionManagement, getSMAvailableLanguages, deleteSMVersion } = useSubscriptionManagementRequests()

  useEffect(() => {
    setState((state: SubscriptionManagementState) => ({ ...state, loading: true }))
    initSubscriptionManagementLanguages(getSMAvailableLanguages, setState)
    initSubscriptionManagementVersions(getSubscriptionManagement, setState)
  }, [])

  const onEditAction = (managementDTO: SubscriptionManagementDTO) => {
    managementDTO.default
      ? window.open(`${rootContext}/classic/if/optinout/settings.jsp`, '_self')
      : setState((state: SubscriptionManagementState) => ({ ...state, showCreateEditVersionModal: true, selectedVersion: managementDTO }))
  }

  const onURLAction = useCallback((managementDTO: SubscriptionManagementDTO) => {
    setState((state: SubscriptionManagementState) => ({ ...state, showUrlVersionModal: true, selectedVersion: managementDTO }))
  }, [])

  const handlePreview = (managementDTO: SubscriptionManagementDTO) => {
    setState((state: SubscriptionManagementState) => ({ ...state, showPreviewVersionModal: true, selectedVersion: managementDTO }))
  }

  const onDeleteAction = (managementDTO: SubscriptionManagementDTO) => {
    setState((state: SubscriptionManagementState) => ({ ...state, showDeleteModal: true, selectedVersion: managementDTO }))
  }

  const handleDeleteModalAnswer = (ans: YesNo) => {
    if (ans === YesNo.YES) {
      const deleteVersion: SubscriptionManagementDtoInput = {
        name: selectedVersion?.name,
        description: selectedVersion?.description,
        languageValue: selectedVersion?.version,
        enabled: selectedVersion?.isEnabled,
      }
      deleteSM(deleteSMVersion, setState, deleteVersion).then(() => initSubscriptionManagementVersions(getSubscriptionManagement, setState))
    }
    setState((state: SubscriptionManagementState) => ({ ...state, showDeleteModal: false, selectedVersion: undefined }))
  }

  const onCreateEditVersionClick = () => {
    setState((state: SubscriptionManagementState) => ({ ...state, showCreateEditVersionModal: true }))
  }

  const onCloseCreateEditVersionClick = () => {
    setState((state: SubscriptionManagementState) => ({ ...state, showCreateEditVersionModal: false, selectedVersion: undefined }))
  }

  const onClosePreviewVersionClick = () => {
    setState((state: SubscriptionManagementState) => ({ ...state, showPreviewVersionModal: false, selectedVersion: undefined }))
  }

  const onCloseURLVersionClick = useCallback(() => {
    setState((state: SubscriptionManagementState) => ({ ...state, showUrlVersionModal: false, selectedVersion: undefined }))
  }, [])

  const filterLanguagesOptions = (): { value: string; label: string }[] => {
    const filteredOptions = versions.map((version) => version.version)
    const languagesVersion = filteredOptions.filter((lan) => !lan.includes(selectedVersion?.version as string))
    return languages.filter((language) => !languagesVersion.includes(language.value))
  }

  const rowActions = useMemo<RowAction[]>(() => getRowActions(onEditAction, handlePreview, onURLAction, onDeleteAction), [])

  const deleteVersionModalBody = () => {
    return selectedVersion?.status === VersionState.DISABLED ? (
      <>
        <Typography text={t('Delete ')} {...ModalBodyStyle} inline />
        <Typography text={`${selectedVersion?.name}. `} inline weight={TextWeight.MEDIUM} type={TextType.BODY_TEXT_LIGHT} />
      </>
    ) : (
      <Typography textAlign={TextAlign.LEFT} text={t('Subscription.Management.Delete.Modal')} {...ModalBodyStyle} />
    )
  }

  const getLabelByValue = (value: string) => languages.find((lang) => lang.value === value)?.label ?? ''

  return (
    <PageContainer className={rootClass}>
      {showUrlVersionModal && selectedVersion && <UrlVersionModal version={selectedVersion} onClose={onCloseURLVersionClick} />}
      {showCreateEditVersionModal && (
        <AddEditVersionModal
          version={selectedVersion}
          dropDownLanguages={filterLanguagesOptions()}
          onClose={onCloseCreateEditVersionClick}
          selected={selectedVersion ? getLabelByValue(selectedVersion.version) : undefined}
        />
      )}
      {showDeleteModal && (
        <ConfirmationModal
          isDelete
          deleteButtonText={t('Delete')}
          isOpen={true}
          title={t('Are you sure?')}
          onAnswer={handleDeleteModalAnswer}
          body={deleteVersionModalBody()}
        />
      )}
      {statusToast?.showStatusToast && (
        <StatusToast
          message={statusToast?.statusMessage}
          status={statusToast.status}
          closeStatus={() => setState((state) => ({ ...state, statusToast: { ...statusToast, showStatusToast: false } }))}
        />
      )}
      {showPreviewVersionModal && <PreviewVersionModal version={selectedVersion} onClose={onClosePreviewVersionClick}></PreviewVersionModal>}
      <PositionContainer innerDivClassname={`${rootClass}__position`}>
        <PageHeader primaryText={t('Subscription Management')} leftContent dataTest={dataTest} className={`${rootClass}__header`}>
          <Button
            buttonType={ButtonType.PRIMARY}
            iconPosition={ButtonIconPosition.LEFT}
            className={`${rootClass}__main-button`}
            onClick={onCreateEditVersionClick}
          >
            <Svg name={SvgNames.plus} type={SvgType.LARGER_ICON} />
            {t('Create language version')}
          </Button>
        </PageHeader>
        <Typography text={t('All language Versions')} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.MEDIUM} className={`${rootClass}__label`} />
        <TableV2
          data={versions}
          enableSorting
          columns={columnsSubscriptionManagement(t)}
          rowActions={rowActions}
          loading={loading}
          enableInsideLoader
        ></TableV2>
      </PositionContainer>
    </PageContainer>
  )
}

export default SubscriptionManagement
