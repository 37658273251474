import { gql } from '@apollo/client'

export default gql`
  query crmUsers($type: CrmType!) {
    crmUsers(type: $type) {
      maxUsers
      numberOfUsers
      numberOfUsersAvailable
      users {
        email
        extId
        firstName
        isActOnUser
        isNotAvailable
        lastName
        login
        loginKey
        role
      }
      updated
    }
  }
`
