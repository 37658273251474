import { t } from 'i18next'

import { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { LEARN_MORE_LINK } from '@src/pages/Settings/OtherSettings/FromAddresses/utils/FromAddresses.constants'

const getTableProps = (isAdmin: boolean, haveData: boolean, setOpenAddSenderModal: (state: boolean) => void) => ({
  size: EmptyListingSize.MEDIUM,
  hideIcon: false,
  headline: t(haveData ? 'No results found' : 'No sender addresses yet'),
  text: t(
    haveData
      ? 'There were no results matching your search.'
      : isAdmin
      ? 'Add email addresses to send from.'
      : 'You must be an admin user to add new addresses.'
  ),
  imgSrc: haveData ? StaticImageNames.emptySearch : StaticImageNames.envelope,
  buttonText: !haveData && isAdmin ? t('Add Sender') : undefined,
  buttonOnClick: !haveData && isAdmin ? () => setOpenAddSenderModal(true) : undefined,
  linkText: haveData ? undefined : t('Learn more about adding From Addresses'),
  link: haveData ? undefined : LEARN_MORE_LINK,
})

export default getTableProps
