import React, { useContext } from 'react'
import { SingleValueProps } from 'react-select'

import classNames from 'classnames'

import { SelectV2Context } from '@components/SelectV2/SelectV2.context'
import { isSingleValueType, selectRootClass } from '@components/SelectV2/SelectV2.utils'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { css } from '@emotion/css'

import { SelectV2Option } from '../SelectV2.props'

const CustomSingleValue = (props: SingleValueProps<SelectV2Option>) => {
  const { children, getStyles, innerProps, isDisabled, data } = props
  const {
    values: { isPaginator, inputGroup, renderSingleValue },
  } = useContext(SelectV2Context)

  const text = inputGroup ? `${inputGroup.label}: ${children}` : children
  return (
    <div
      className={classNames(css(getStyles('singleValue', props)), { [`${selectRootClass}__single-value-paginator`]: isPaginator })}
      {...innerProps}
    >
      <Typography
        inline
        text={isSingleValueType(data) && renderSingleValue ? renderSingleValue(data) : text}
        lineHeight={LineHeight.LARGE}
        type={isDisabled ? TextType.BODY_TEXT_LIGHT : TextType.BODY_TEXT}
        weight={TextWeight.MEDIUM_LIGHT}
        dataTest={'selectv2-single-value'}
      />
    </div>
  )
}

export default CustomSingleValue
