import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Label from '@components/Label/Label'
import Typography, { TextType, TextWeight, TypographyProps } from '@components/Typography/Typography'
import { getUUID } from '@const/globals'

import './radio.css'

export enum RadioType {
  DEFAULT = 'default',
  CHECKMARK = 'checkmark',
}

const rootClass = 'radio'

export interface RadioProps {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: () => void
  label?: string
  labelDisplay?: string
  labelChildren?: ReactNode
  labelTitle?: string
  labelTypography?: Partial<TypographyProps>
  name?: string
  value?: string
  checked?: boolean
  register?: any
  id?: string
  disabled?: boolean
  withTitle?: boolean
  error?: boolean
  className?: string
  dataTest?: string
  boldOnChecked?: boolean
  title?: string
  type?: RadioType
  ref?: React.MutableRefObject<HTMLInputElement>
}

export const Radio: FC<RadioProps> = (props: RadioProps) => {
  const {
    name,
    value,
    checked,
    onChange,
    label,
    labelChildren,
    labelDisplay = '',
    labelTitle = '',
    register,
    disabled,
    withTitle = true,
    dataTest = 'radio',
    className,
    onClick,
    error,
    title = '',
    type,
    ref,
    boldOnChecked = false,
    labelTypography,
  } = props
  const id = props.id ?? getUUID()
  return (
    <div className={classNames(`${rootClass}__container`, className, { [`${rootClass}--checked`]: checked, [`${rootClass}--${type}`]: type })}>
      <input
        className={classNames(rootClass, {
          [`${rootClass}__error`]: error,
        })}
        ref={ref}
        id={id}
        type="radio"
        name={name}
        value={value}
        onClick={onClick}
        checked={checked}
        onChange={onChange}
        data-test={dataTest}
        disabled={disabled}
        title={title}
        {...register}
      />
      <Label
        title={withTitle ? labelTitle || label : ''}
        htmlFor={id}
        display={labelDisplay}
        className={classNames(`${rootClass}__label`, `${className}__radio-label`, {
          [`${rootClass}__label--no-label`]: !label && !labelChildren,
        })}
      >
        {label && (
          <Typography
            text={label}
            type={disabled ? TextType.BODY_TEXT_LIGHT : TextType.BODY_TEXT}
            weight={boldOnChecked && checked ? TextWeight.BOLD : TextWeight.REGULAR}
            {...labelTypography}
            inline={!!labelChildren}
          />
        )}
        {labelChildren}
      </Label>
    </div>
  )
}

export default Radio
