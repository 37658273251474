import React, { useContext, useRef } from 'react'
import { components, MenuListProps } from 'react-select'

import classNames from 'classnames'

import Dots, { LoaderSize } from '@components/Dots/Dots'
import CustomCreateOption from '@components/SelectV2/components/CustomCreateOption/CustomCreateOption'
import { SelectV2Context } from '@components/SelectV2/SelectV2.context'
import { SelectV2Option } from '@components/SelectV2/SelectV2.props'
import { selectRootClass } from '@components/SelectV2/SelectV2.utils'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'

const rootClass = 'custom-menu-list'

const CustomMenuList = (props: MenuListProps<SelectV2Option>) => {
  const { children, selectProps } = props
  const {
    values: { inputValue, isCreatable = false, searchLoading, hasSearchOnClick, searchBoxPlaceholder },
    update,
  } = useContext(SelectV2Context)
  const outerRef = useRef<HTMLDivElement>()

  const lastOptionProps = Array.isArray(children) ? children[children.length - 1].props : undefined
  const isNew = !!lastOptionProps?.data?.__isNew__ || false
  const hasCreateOption = ((!inputValue && !!lastOptionProps) || isNew) && isCreatable

  const childrenSize = Array.isArray(children) ? children.length : 0
  const hideMenuList = (childrenSize === 1 && isNew) || (childrenSize === 0 && hasSearchOnClick)

  const createOptionHeight = hasCreateOption ? outerRef.current?.offsetHeight ?? 0 : 0
  const menuActionHeight = selectProps.menuActionRef?.current?.offsetHeight ?? 0
  const maxHeight = props.maxHeight - createOptionHeight - menuActionHeight

  const renderSearchInput = () => {
    return (
      <div
        className={classNames(`${rootClass}__search-input-wrapper`, {
          [`${rootClass}__search-input-wrapper-no-bottom-border`]: !hideMenuList || hasCreateOption,
        })}
      >
        <div className={`${rootClass}__search-input-content`}>
          <div className={classNames(`${selectRootClass}__control-icon`)}>
            <Svg
              name={SvgNames.search}
              className={`${selectRootClass}__control-icon-default`}
              type={SvgType.LARGER_ICON}
              fill={SvgColor.LIGHT_GRAY}
            />
          </div>
          <input
            aria-autocomplete="list"
            aria-label={selectProps['aria-label']}
            aria-labelledby={selectProps['aria-labelledby']}
            autoCorrect="off"
            autoComplete="off"
            spellCheck="false"
            type="text"
            value={inputValue}
            onChange={(e) =>
              selectProps.onInputChange(e.currentTarget.value, {
                action: 'input-change',
                prevInputValue: inputValue,
              })
            }
            onMouseDown={(e) => {
              e.stopPropagation()
              e.currentTarget.focus()
            }}
            onKeyDown={(e) => {
              if (e.key === 'Escape' || e.key === 'Tab') {
                update({ inputValue: '', hasSearchOpen: false })
                e.stopPropagation()
              }
            }}
            onTouchEnd={(e) => {
              e.stopPropagation()
              e.currentTarget.focus()
            }}
            onFocus={() => {
              update({
                hasSearchOpen: true,
              })
            }}
            placeholder={searchBoxPlaceholder}
            /* eslint-disable-next-line jsx-a11y/no-autofocus */
            autoFocus
          />
          {searchLoading && <Dots className={classNames(`${selectRootClass}__control-dots`)} size={LoaderSize.SMALL} />}
        </div>
      </div>
    )
  }

  return (
    <div className={rootClass}>
      {hasSearchOnClick && renderSearchInput()}
      {!hideMenuList && (
        <components.MenuList
          {...props}
          className={classNames(props.className, {
            [`${rootClass}-with-search`]: hasSearchOnClick,
            [`${rootClass}-with-create`]: hasCreateOption,
          })}
          maxHeight={maxHeight}
        >
          {children}
        </components.MenuList>
      )}
      {hasCreateOption && (
        <CustomCreateOption {...lastOptionProps} outerRef={outerRef}>
          {children}
        </CustomCreateOption>
      )}
    </div>
  )
}

export default CustomMenuList
