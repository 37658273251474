import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/Button'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import { ProgramPerformanceTab } from '@src/pages/programs/dashboard/components/ProgramPerformance/ProgramPerformance.constants'
import { ProgramManagerTabs } from '@src/pages/programs/manager/ProgramManager.constants'

import './ProgramStepLink.css'

interface Props {
  value: string
  programId: string
  column: string
  uiStepName?: string
  linkState?: Object
  tabChange?: (tab: string, subtab?: string) => void
  className?: string
  dataTest?: string
}

const rootClass = 'program-step-link'

const ProgramStepLink: FC<Props> = (props: Props) => {
  const { value, uiStepName, column, programId, tabChange, linkState, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const isZero = value === '0'
  const isStartCol = uiStepName === ''
  const isWaitingNoLink = !isZero && isStartCol && column === 'waiting'
  const isCompletedLink = !isZero && isStartCol && column === 'completed'
  const isEarlyExitLink = !isZero && isStartCol && column === 'early_exit'
  const isDash = !isStartCol && value === '-'
  const to = isEarlyExitLink
    ? `${rootContext}/programs/${programId}/suppressed`
    : `${rootContext}/programs/${programId}/contacts/${column.toUpperCase()}/${uiStepName}`

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {isZero && <Typography text={value} />}
      {isDash && <Typography text={value} type={TextType.BODY_TEXT_LIGHT} />}
      {isWaitingNoLink && <Tooltip trigger={<Typography text={value} />}>{t('Program.Start.Link.Waiting', { value })}</Tooltip>}
      {isCompletedLink && (
        <Tooltip
          trigger={
            <Button
              buttonType={ButtonType.TEXT}
              onClick={() => tabChange && tabChange(ProgramManagerTabs.PROGRAM_PERFORMANCE, ProgramPerformanceTab.All_CONTACTS)}
            >
              {value}
            </Button>
          }
        >
          {t('Program.Start.Link.Completed', { value })}
        </Tooltip>
      )}
      {isEarlyExitLink && (
        <Tooltip
          trigger={
            <Button buttonType={ButtonType.TEXT} isLink to={to} linkState={linkState}>
              {value}
            </Button>
          }
        >
          {t('Program.Start.Link.Exited', { value })}
        </Tooltip>
      )}
      {!isZero && !isDash && !isWaitingNoLink && !isEarlyExitLink && !isCompletedLink && (
        <Button buttonType={ButtonType.TEXT} isLink to={to} linkState={linkState}>
          {value}
        </Button>
      )}
    </div>
  )
}

export default ProgramStepLink
