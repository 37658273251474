import React, { FC, ReactNode, useEffect, useState } from 'react'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import ClipboardCopy, { CopyTextSize } from '@components/ClipboardCopy/ClipboardCopy'
import CodeBlockV2 from '@components/CodeBlockV2/CodeBlockV2'
import InfoStatus, { InfoStatusTypes } from '@components/InfoStatus/InfoStatus'
import Loader from '@components/Loader'
import PageContainer from '@components/PageContainer'
import PageHeader from '@components/PageHeader'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import Radio from '@components/Radio'
import StatusToast from '@components/StatusToast/StatusToast'
import { SvgNames } from '@components/Svg'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import { TOAST_TEXT_CLASSNAME } from '@components/Toast/Toast'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import setBeaconCookieMode from '@graphql/mutations/setBeaconCookieMode'
import getBeaconSettings from '@graphql/queries/beaconSettings'
import { SetBeaconCookieModeMutation, SetBeaconCookieModeMutationVariables } from '@graphql/types/mutation-types'
import { BeaconSettingsQuery, BeaconSettingsQueryVariables } from '@graphql/types/query-types'
import { StatusToastType } from '@interface/StatusToast'
import { logNewRelicError } from '@utils/new-relic.utils'

import {
  BEACON_SETTINGS_INTERNAL_LINK,
  BEACON_SETTINGS_LINK,
  BEACON_SETTINGS_MORE_INFO,
  BEACON_STATUS_SVG_NAMES,
  COOKIE_MODE,
} from './utils/beaconSettings.constants'

import './BeaconSettings.css'

const rootClass = 'beacon-settings'

export const BeaconSettings: FC = () => {
  const [isActive, setIsActive] = useState<boolean>(false)
  const [toast, setToastStatus] = useState<StatusToastType>()
  const [isEnabled, setIsEnabled] = useState<boolean>(true)
  const [cookieMode, setCookieMode] = useState<string>('')
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [copyCode, setCopyCode] = useState<string>('')

  const { t } = useTranslation()

  const client = useApolloClient()
  const { data, loading, error, refetch } = useQuery<BeaconSettingsQuery, BeaconSettingsQueryVariables>(getBeaconSettings, {
    client,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!loading && data?.beaconSettings) {
      const { enabled, administrator, cookieMode, copypasta, copypastaDict = {}, beaconData } = data.beaconSettings
      setCookieMode(cookieMode)
      setIsEnabled(enabled)
      setIsActive(enabled && cookieMode === COOKIE_MODE.standard && !!beaconData && beaconData?.isBeaconActive)
      setIsAdmin(administrator)
      copypasta && setCopyCode(copypasta ?? copypastaDict[cookieMode])
    }
    if (error) {
      logNewRelicError(error)
    }
  }, [loading, error, data])

  const [saveBeaconSettings, { loading: saveLoading }] = useMutation<SetBeaconCookieModeMutation, SetBeaconCookieModeMutationVariables>(
    setBeaconCookieMode,
    {
      client,
      fetchPolicy: 'no-cache',
    }
  )

  const getStatusMessage = () => {
    const statusText = isActive ? 'Active' : 'Inactive'

    return (
      <>
        <Typography text={t('Beacon is')} type={TextType.BODY_TEXT_SMALL} inline />
        &nbsp;
        <Typography text={t(statusText)} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.BOLD} inline />
      </>
    )
  }

  const getStatusText = () => {
    return isActive ? (
      <div>
        Go to&nbsp;
        <TextLink
          dataTest={`${rootClass}__status-link`}
          text={'Website Prospector'}
          link={BEACON_SETTINGS_INTERNAL_LINK}
          size={TextLinkSize.SMALL}
          hideIcon
        />
        &nbsp;to view visitor activity
      </div>
    ) : (
      'Complete the steps below to track your website visitors with the Beacon'
    )
  }

  const getToastMessage = (cookieMode: string): ReactNode => {
    const modeText = cookieMode === COOKIE_MODE.standard ? 'Standard.' : 'Anonymous.'

    return (
      <span className={TOAST_TEXT_CLASSNAME}>
        <Typography text={t('Your cookie mode has been changed to')} inline />
        &nbsp;
        <Typography text={t(modeText)} weight={TextWeight.BOLD} inline />
      </span>
    )
  }

  const changeRadioButton = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cookieMode = e.target.value
    setCookieMode(cookieMode)
    saveBeaconSettings({
      variables: {
        enabled: isEnabled,
        cookieMode,
      },
    })
      .then(() => {
        setToastStatus({
          showStatus: true,
          statusMessage: getToastMessage(cookieMode),
          successStatus: true,
        })
        refetch()
      })
      .catch((error) => {
        setToastStatus({
          showStatus: true,
          statusMessage: t('Something went wrong on our end. Beacon settings not saved.'),
          successStatus: false,
        })
        setCookieMode(cookieMode === COOKIE_MODE.standard ? COOKIE_MODE.noCookie : COOKIE_MODE.standard)
        logNewRelicError(error)
      })
  }

  return (
    <PageContainer>
      {!saveLoading && toast?.showStatus && (
        <StatusToast
          isSuccess={toast.successStatus}
          message={toast.statusMessage}
          title={toast.title}
          closeStatus={() => {
            setToastStatus({ showStatus: false })
          }}
        />
      )}
      <>
        {loading && <Loader center />}
        <PositionContainer>
          {!loading && (
            <div className={`${rootClass}__container`}>
              <PageHeader primaryText={t('Beacon Settings')} leftContent />

              <div>
                <Typography
                  text={t('Use the Act-On Beacon to identify and track visitors to your web properties.')}
                  type={TextType.BODY_TEXT_LIGHT}
                  lineHeight={LineHeight.MEDIUM}
                  inline
                />
                <TextLink
                  className={`${rootClass}__header-info-link`}
                  text={t('More info')}
                  link={BEACON_SETTINGS_MORE_INFO}
                  size={TextLinkSize.LARGE}
                />
              </div>

              <InfoStatus
                status={isActive ? InfoStatusTypes.Active : InfoStatusTypes.Warning}
                svgName={isActive ? BEACON_STATUS_SVG_NAMES.standard : BEACON_STATUS_SVG_NAMES.noCookie}
                message={getStatusMessage()}
                statusText={getStatusText()}
                className={`${rootClass}__status-section`}
              />

              <div className={`${rootClass}__section`}>
                <Typography
                  className={`${rootClass}__section-header`}
                  text={t('1. Select your cookie mode')}
                  type={TextType.SECTION_HEADER}
                  weight={TextWeight.MEDIUM}
                />
                {!isAdmin && (
                  <InfoStatus status={InfoStatusTypes.Informative} svgName={SvgNames.lockFilled} className={`${rootClass}__instructions`}>
                    <Typography
                      text={t('Please contact an account Admin user to modify the beacon mode.')}
                      type={TextType.BODY_TEXT_SMALL}
                      lineHeight={LineHeight.EXTRA_TINY}
                    />
                  </InfoStatus>
                )}
                <div className={`${rootClass}__section-content`}>
                  <Radio
                    labelChildren={
                      <>
                        <Typography
                          text={t('Standard')}
                          type={!isAdmin ? TextType.BODY_TEXT_LIGHT : TextType.BODY_TEXT}
                          weight={TextWeight.MEDIUM}
                          inline
                        />
                        &nbsp;
                        <Typography text={t('(recommended)')} type={!isAdmin ? TextType.BODY_TEXT_LIGHT : TextType.BODY_TEXT} inline />
                      </>
                    }
                    checked={cookieMode === COOKIE_MODE.standard}
                    dataTest={`${rootClass}-radio-standard`}
                    name="beaconSettings"
                    onChange={changeRadioButton}
                    disabled={!isAdmin}
                    withTitle={false}
                    value={COOKIE_MODE.standard}
                  />
                  <Radio
                    labelChildren={
                      <Typography
                        text={t('Anonymous')}
                        type={!isAdmin ? TextType.BODY_TEXT_LIGHT : TextType.BODY_TEXT}
                        weight={TextWeight.MEDIUM}
                        inline
                      />
                    }
                    checked={cookieMode === COOKIE_MODE.noCookie}
                    dataTest={`${rootClass}-radio-disabled`}
                    name="beaconSettings"
                    onChange={changeRadioButton}
                    disabled={!isAdmin}
                    withTitle={false}
                    value={COOKIE_MODE.noCookie}
                  />
                  <Typography
                    className={`${rootClass}__setting-description`}
                    text={t(
                      'Prompt visitors to accept a tracking cookie. This will identify each visitor and track their web views in their Act-On Activity History and Website Prospector.'
                    )}
                    type={TextType.BODY_TEXT_LIGHT}
                  />
                  <Typography
                    className={`${rootClass}__setting-description`}
                    text={t(
                      'Track visitors anonymously in the Website Prospector. Cookies will not be stored in the browser. This setting is only recommended when you must meet strict privacy regulations.'
                    )}
                    type={TextType.BODY_TEXT_LIGHT}
                    inline
                  />
                </div>
                <TextLink
                  className={`${rootClass}__section-link`}
                  link={BEACON_SETTINGS_LINK}
                  text={t("Learn how to customize your website's cookie consent banner")}
                  size={TextLinkSize.LARGE}
                />
              </div>

              <div className={`${rootClass}__section`}>
                <Typography
                  className={`${rootClass}__section-header`}
                  text={t('2. Install the Beacon')}
                  type={TextType.SECTION_HEADER}
                  weight={TextWeight.MEDIUM}
                />

                <Typography
                  className={`${rootClass}__section-description`}
                  text={t(
                    "Add the below code to the web properties you'd like to track. Insert this code before the closing </body> tag. You can skip this step for Act-On Landing Pages and Forms – we'll track these automatically. "
                  )}
                  type={TextType.BODY_TEXT_LIGHT}
                />

                <ClipboardCopy
                  tooltipText={'Copied'}
                  className={`${rootClass}-clipboard-copy`}
                  size={CopyTextSize.LARGE}
                  lineHeight={LineHeight.TINY}
                  text="Copy code to clipboard"
                  value={copyCode}
                />
                <CodeBlockV2 content={copyCode} footer={false} />
              </div>

              <div className={`${rootClass}__section`}>
                <Typography
                  className={`${rootClass}__section-header`}
                  text={t('3. Confirm Beacon is Tracking')}
                  type={TextType.SECTION_HEADER}
                  weight={TextWeight.MEDIUM}
                />

                <div className={`${rootClass}__section-description`}>
                  <Typography
                    text={t('When the Beacon is actively tracking your visitors, the above status will change to ')}
                    type={TextType.BODY_TEXT_LIGHT}
                    inline
                  />
                  <Typography text={t('Beacon is Active.')} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.BOLD} inline />
                </div>
              </div>
            </div>
          )}
        </PositionContainer>
      </>
    </PageContainer>
  )
}

export default BeaconSettings
