import { gql } from '@apollo/client'

export default gql`
  mutation setSyncSchedule($setSyncSchedule: SyncScheduleInput) {
    setSyncSchedule(setSyncSchedule: $setSyncSchedule) {
      MILLISECONDS_PER_HOUR
      MILLISECONDS_PER_MINUTE
      ampm
      automaticSchedule
      dayOfMonth
      dayOfWeek
      hour
      hourInterval
      minute
      mode
    }
  }
`
