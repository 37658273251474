import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'

import AssetPickerSidebar from '@complex/AssetPickerModal/Components/Sidebar/AssetPickerSidebar'
import {
  getImageSourceOptions,
  getInitialImagePickerFilterState,
  IImagePickerFilterState,
  ImagePickerSource,
  initialImagePickerSortStateLibrary,
  initialImagePickerSortStateLogos,
} from '@components/AssetPickers/ImagePickerModal/utils/ImagePickerModal.utils'
import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import FilterContainer from '@components/FilterContainer/FilterContainer'
import Menu, { MenuItemData } from '@components/Menu/Menu'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import SortableFolders from '@components/SortableFolders/SortableFolders'
import Spinner from '@components/Spinner/Spinner'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { ImagesFolderResponse } from '@graphql/types/query-types'
import { ColumnSort } from '@tanstack/react-table'
import { useTranslation } from '@utils/const/globals'
import { allImagesFilter, allLogosFilter, favoriteFilter, FilterDefinition, FilterTypes } from '@utils/filter'

interface ImagePickerModalSidebarProps {
  folders: ImagesFolderResponse[]
  filtersState: IImagePickerFilterState
  setFiltersState: Dispatch<SetStateAction<IImagePickerFilterState>>
  onSortColumnChange: (sortBy: ColumnSort) => void
  setCurrentFolderAllItemsCount: Dispatch<SetStateAction<number | undefined>>
  setDataLoading: Dispatch<SetStateAction<boolean>>
  loading: boolean
  logosTotalCount?: number
  allowSvg?: boolean
  dataTest?: string
}

const rootClass = 'image-picker-modal__sidebar'

const ImagePickerModalSidebar: FC<ImagePickerModalSidebarProps> = ({
  folders,
  filtersState,
  setFiltersState,
  onSortColumnChange,
  setCurrentFolderAllItemsCount,
  setDataLoading,
  loading,
  logosTotalCount,
  allowSvg,
  dataTest = rootClass,
}) => {
  const { t } = useTranslation()
  const { activeSource, activeFilter, activeFolder } = filtersState
  const isLogosSource = activeSource === ImagePickerSource.LOGOS
  const [filterFavorites, setFilterFavorites] = useState(false)

  const { filterCounts, sortableFolders } = useMemo(() => {
    const ALL_NAME = allImagesFilter.name
    const FAVORITE_NAME = favoriteFilter.name
    const filterCounts = { [ALL_NAME]: 0, [FAVORITE_NAME]: 0 }
    const sortableFolders: FolderData[] = []
    folders.forEach(({ entryCount, name = '', folderId }, index) => {
      const idIsNumber = typeof folderId === 'number'
      if (!idIsNumber && name === 'Favorite') {
        filterCounts[FAVORITE_NAME] = entryCount ?? 0
      } else if (idIsNumber) {
        filterCounts[ALL_NAME] = filterCounts[ALL_NAME] + (entryCount ?? 0)
        sortableFolders.push({ id: folderId, name, position: index, itemCount: entryCount })
      }
    })
    return { filterCounts, sortableFolders }
  }, [folders])

  useEffect(() => {
    if (activeSource === ImagePickerSource.LOGOS) {
      setCurrentFolderAllItemsCount(logosTotalCount)
    } else if (activeFilter) {
      setCurrentFolderAllItemsCount(filterCounts[activeFilter.name])
    } else if (typeof activeFolder === 'number') {
      setCurrentFolderAllItemsCount(sortableFolders.find(({ id }) => id === activeFolder)?.itemCount)
    }
  }, [activeSource, activeFilter, activeFolder, filterCounts, sortableFolders, setCurrentFolderAllItemsCount, logosTotalCount])

  const imageSourceOptions = getImageSourceOptions(t)

  const handleSelectSource = useCallback(
    (option?: SelectV2SingleOption) => {
      if (option) {
        setFiltersState((current) => {
          if (current.activeSource !== option.value) {
            const isLogos = option.value === ImagePickerSource.LOGOS
            setDataLoading(true)
            onSortColumnChange(isLogos ? initialImagePickerSortStateLogos : initialImagePickerSortStateLibrary)
            const hasFolderId = typeof current.activeFolder === 'number'
            return isLogos
              ? { ...current, ...getInitialImagePickerFilterState(isLogos) }
              : {
                  ...current,
                  activeSource: ImagePickerSource.LIBRARY,
                  activeFilter: hasFolderId ? undefined : filterFavorites ? favoriteFilter : allImagesFilter,
                }
          }
          return current
        })
      }
    },
    [setFiltersState, setDataLoading, onSortColumnChange, filterFavorites]
  )

  const handleFilterClick = useCallback(
    (filter: FilterDefinition) => {
      setFiltersState((current) => {
        if (current?.activeFilter?.name !== filter.name) {
          setDataLoading(true)
          setFilterFavorites(filter.name === favoriteFilter.name)
          return { ...current, activeFilter: filter, activeFolder: undefined }
        }
        return current
      })
    },
    [setFiltersState, setDataLoading]
  )

  const handleFolderClick = useCallback(
    (folderId: number) => {
      setFiltersState((current) => {
        if (current?.activeFolder !== folderId) {
          setDataLoading(true)
          return { ...current, activeFolder: folderId, activeFilter: undefined }
        }
        return current
      })
    },
    [setFiltersState, setDataLoading]
  )

  const filterContainerProps = isLogosSource
    ? { filters: [allLogosFilter], activeFilter, filterCounts: { [FilterTypes.ALL_LOGOS]: logosTotalCount } }
    : {
        filters: [allImagesFilter, favoriteFilter],
        filterCounts,
        activeFilter,
      }

  const items: MenuItemData[] = [
    {
      content: (
        <SelectV2
          options={imageSourceOptions}
          onChange={handleSelectSource}
          insideModal
          isClearable={false}
          defaultValue={isLogosSource ? imageSourceOptions[1] : imageSourceOptions[0]}
          dataTest={`${dataTest}-select`}
        />
      ),
      isSelect: true,
    },
    {
      content: <FilterContainer filterAction={handleFilterClick} {...filterContainerProps} dataTest={`${dataTest}-filters`} />,
    },
  ]

  if (!isLogosSource) {
    items.push({
      content: loading ? (
        <Spinner />
      ) : sortableFolders.length > 0 ? (
        <SortableFolders activeFolder={activeFolder} folders={sortableFolders} onClick={handleFolderClick} dataTest={`${dataTest}-folders`} />
      ) : (
        <EmptyListing
          imgSrc={StaticImageNames.aocNoFolders}
          text={t('AssetPicker.Sidebar.EmptyListing.Folders')}
          size={EmptyListingSize.SMALL}
          textType={TextType.BODY_TEXT_SMALL_LIGHT}
          withoutBorder
        />
      ),
      isOpen: true,
      header: `${t('Folders')} (${sortableFolders.length})`,
    })
  }

  return (
    <AssetPickerSidebar>
      <Menu items={items} dataTest={`${dataTest}-menu`} className={`${rootClass}__menu`} menuSize="" />
      {!allowSvg && (
        <div className={`${rootClass}__blurb`} data-test={`${dataTest}-blurb`}>
          <Typography text={t('Image Compatibility')} className="push-up" weight={TextWeight.MEDIUM} />
          <Typography text={t('Image.Picker.Modal.Sidebar.Blurb')} />
        </div>
      )}
    </AssetPickerSidebar>
  )
}

export default ImagePickerModalSidebar
