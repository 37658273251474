import { useEffect, useRef, useState } from 'react'

import getUnifiedListFieldMappings from '@graphql/microservices/list/getUnifiedListFieldMappings'
import standardFields from '@graphql/microservices/list/standardFields'
import unifiedListFieldMapping from '@graphql/microservices/list/unifiedListFieldMapping'
import {
  GetUnifiedListFieldMappingsQuery,
  GetUnifiedListFieldMappingsQueryVariables,
  StandardField,
  StandardFieldsQuery,
  StandardFieldsQueryVariables,
  UnifiedListFieldMapping,
  UnifiedListFieldMappingInput,
  UnifiedListFieldMappingMutation,
  UnifiedListFieldMappingMutationVariables,
} from '@graphql/types/microservice/list-types'
import { filterNotEmptyArray } from '@utils/array'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

interface UseUnifiedContactListProps {
  getDeletedMappings?: boolean
  fetchOnMount?: boolean
}

export const useUnifiedContactList = (props?: UseUnifiedContactListProps) => {
  const { getDeletedMappings = false, fetchOnMount = true } = { ...props }

  const { client: listServiceClient } = useMicroserviceClient({ serviceName: MicroserviceClients.LIST })

  const [unifiedListFieldMappings, setUnifiedListFieldMappings] = useState<UnifiedListFieldMapping[]>()
  const [loading, setLoading] = useState(true)

  const alreadyRequested = useRef(false)

  const createNewUCLFieldMapping = (...fieldMappings: UnifiedListFieldMappingInput[]): FetchPromise<UnifiedListFieldMappingMutation> => {
    return listServiceClient.mutate<UnifiedListFieldMappingMutation, UnifiedListFieldMappingMutationVariables>({
      mutation: unifiedListFieldMapping,
      variables: {
        unifiedListFieldMapping: fieldMappings,
      },
    })
  }

  const getStandardFieldsRequest = async (): Promise<StandardField[]> => {
    return listServiceClient
      .query<StandardFieldsQuery, StandardFieldsQueryVariables>({
        query: standardFields,
      })
      .then(({ data }) => data.standardFields?.filter(filterNotEmptyArray) ?? [])
  }

  const getUnifiedListFieldMappingsRequest = (): Promise<UnifiedListFieldMapping[]> =>
    listServiceClient
      .query<GetUnifiedListFieldMappingsQuery, GetUnifiedListFieldMappingsQueryVariables>({
        query: getUnifiedListFieldMappings,
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        const mappings = (data.unifiedListFieldMappings ?? []) as UnifiedListFieldMapping[]
        return getDeletedMappings ? mappings : mappings.filter((mapping) => !mapping?.hidden && !mapping?.deleted)
      })

  useEffect(() => {
    if (!alreadyRequested.current && fetchOnMount) {
      getUnifiedListFieldMappingsRequest()
        .then((mappings) => {
          setUnifiedListFieldMappings(mappings)
        })
        .finally(() => {
          setLoading(false)
        })
    }
    alreadyRequested.current = true
  }, [])

  return {
    unifiedListFieldMappings,
    loading,
    createNewUCLFieldMapping,
    getStandardFieldsRequest,
    getUnifiedListFieldMappingsRequest,
  }
}
