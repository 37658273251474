import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

export interface ModalFooterActionProps {
  children: ReactNode
  className?: string
}

const rootClass = 'modal-footer-action'

export const ModalFooterAction: FC<ModalFooterActionProps> = (props: ModalFooterActionProps) => (
  <div className={classNames(rootClass, props.className)}>{props.children}</div>
)

export default ModalFooterAction
