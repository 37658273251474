import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ExtendedItemDto, ListingPageItem } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { SelectV2GroupedOption, SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { FormPublicUrl, UrlGroup } from '@graphql/types/query-types'
import AssetLinkModal from '@src/pages/EmailComposer/EmailModals/components/AssetLinkModal/AssetLinkModal'
import { useAssetLinkModalRequests } from '@src/pages/EmailComposer/EmailModals/components/AssetLinkModal/GraphQL/AssetLinkModal.graphql'
import { AssetLinkTypes } from '@utils/composer/EmailModal.constants'
import { logNewRelicError } from '@utils/new-relic.utils'

import './AssetLinkModal.css'

type AssetNameAndLink = {
  name?: string
  url?: string
}

type UrlOption = Omit<Partial<FormPublicUrl & UrlGroup>, '__typename'> | undefined

interface AssetLinkModalProps {
  isOpen: boolean
  assetType: AssetLinkTypes.FORM | AssetLinkTypes.LANDING_PAGES
  handleBackClick: () => void
  handleCancel: () => void
  handleInsert: (asset: AssetNameAndLink) => void
  className?: string
  dataTest?: string
}

export const DEFAULT_URL_OPTION = { label: 'Default: Public URL', value: '' }

const rootClass = 'asset-link-modal'

const AssetLinkModalContainer: FC<AssetLinkModalProps> = (props: AssetLinkModalProps) => {
  const { isOpen, handleBackClick, handleCancel, handleInsert, dataTest = rootClass, assetType } = props

  const { t } = useTranslation()
  const [selectedAsset, setSelectedAsset] = useState<ListingPageItem>()
  const [selectedUrl, setSelectedUrl] = useState<SelectV2SingleOption>()
  const [urlOptions, setUrlOptions] = useState<SelectV2GroupedOption[]>([])

  const { getFormPublicUrlsRequest, getLandingPageRequest } = useAssetLinkModalRequests()

  const setUrls = (options?: UrlOption[]) => {
    const urlOptions = options?.map((option) => getUrlOptions(option))

    if (urlOptions) {
      setUrlOptions(urlOptions)
      setSelectedUrl({ label: `Default: ${urlOptions[0].options[0].label}`, value: urlOptions[0].options[0].value })
    }
  }

  const getUrlOptions = (option?: UrlOption) => {
    const urls = [
      {
        label: t('Public URL'),
        value: (assetType === AssetLinkTypes.FORM ? option?.url : option?.publicUrl) ?? '',
      },
      {
        label: t('Prefilled Form URL'),
        value: option?.prefilledUrl ?? '',
      },
      {
        label: t('SEO Friendly URL'),
        value: (assetType === AssetLinkTypes.FORM ? option?.seoUrl : option?.seoFriendlyUrl) ?? '',
      },
      {
        label: t('Short URL'),
        value: option?.shortUrl ?? '',
      },
    ]

    return {
      options: urls.filter((url) => url.value),
      label: option?.name ?? '',
    }
  }

  const getFormUrls = async (formId = '') => {
    const { data, errors } = await getFormPublicUrlsRequest({ formId })

    if (errors) {
      logNewRelicError(errors)
    }

    return data?.getFormPublicURLs
  }

  const getLandingPageUrls = async (id = '') => {
    const { data, errors } = await getLandingPageRequest({ id })

    if (errors) {
      logNewRelicError(errors)
    } else if (data) {
      const { defaultUrlGroup, urlGroups } = data.getLandingPage

      return [{ ...defaultUrlGroup }, ...(urlGroups ?? [])]
    }
  }

  const handleSelectAsset = async (item: ExtendedItemDto) => {
    setSelectedAsset(item)

    const urls = assetType === AssetLinkTypes.FORM ? await getFormUrls(item.externalId) : await getLandingPageUrls(item.externalId)
    setUrls(urls)
  }

  const handleOnChange = (option?: SelectV2SingleOption) => setSelectedUrl(option)

  return (
    <div className={rootClass}>
      <AssetLinkModal
        isOpen={isOpen}
        selectedAsset={selectedAsset}
        selectedUrl={selectedUrl ?? DEFAULT_URL_OPTION}
        urlOptions={urlOptions}
        assetType={assetType}
        handleBackClick={handleBackClick}
        handleCancel={handleCancel}
        handleInsert={handleInsert}
        handleSelectAsset={handleSelectAsset}
        handleOnChange={handleOnChange}
        dataTest={dataTest}
      />
    </div>
  )
}

export default AssetLinkModalContainer
