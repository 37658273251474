import React, { FC, ReactNode, useEffect, useState } from 'react'

import { MenuActions } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import {
  ListingPageSubType,
  ListPageCommonState,
  PageHeaderProps,
  SetError,
  SetFilter,
  SidebarProps,
  Update,
} from '@complex/ListingPage/Context/ListingPageCommon.context'
import ListingPageContainer from '@complex/ListingPage/ListingPageContainer'
import Button, { ButtonIconPosition, ButtonType } from '@components/Button/Button'
import StatusToast, { Status } from '@components/StatusToast/StatusToast'
import { SvgNames, SvgType } from '@components/Svg/index'
import Svg from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { AccountManagementRow, AccountSharePermissions } from '@src/pages/listingPages/AccountManagement/AccountManagementListingPageContainer.types'
import AccountManagementDataCards from '@src/pages/listingPages/AccountManagement/components/AccountManagementDataCards/AccountManagementDataCards'
import {
  AccountManager,
  CreateAccountRequestParams,
  SalesUser,
  SharePermissionsInput,
  useAccountManagementListingPageRequests,
} from '@src/pages/listingPages/AccountManagement/GraphQL/AccountManagementListingPageRequests.graphQL'
import {
  getActiveFilter,
  getCustomDefaultFilters,
  getCustomEmptyListingProps,
  getCustomFilterParams,
} from '@src/pages/listingPages/AccountManagement/utils/AccountManagementListingPage.filters'
import { renderCustomModal } from '@src/pages/listingPages/AccountManagement/utils/AccountManagementListingPage.modals'
import { tableProps } from '@src/pages/listingPages/AccountManagement/utils/AccountManagementListingPage.tables'
import {
  AccountDataTotals,
  CustomActionHelpers,
  defaultAccountData,
} from '@src/pages/listingPages/AutomatedPrograms/AutomatedProgramsListingPage.constants'
import { Row } from '@tanstack/react-table'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import { allAccountsFilter, FilterDefinition, myAccountsFilter, unassignedAccountsFilter } from '@utils/filter'
import { logNewRelicError } from '@utils/new-relic.utils'

import './AccountManagementListingPage.css'

interface AccountManagementListingPageContainerProps {
  isAccountAdmin: boolean
  isStory?: boolean
  className?: string
  dataTest?: string
}

const rootClass = 'account-management-listing-page-container'

const AccountManagementListingPageContainer: FC<AccountManagementListingPageContainerProps> = (props: AccountManagementListingPageContainerProps) => {
  const { isAccountAdmin, isStory, dataTest = rootClass } = props
  const { accountId, parentId, userId, hasAgencyAssignSalesUsers, userEmail, enableGoldenChildToast } = useAccountSettings()
  const parentAccountId = parentId === '0' ? accountId : parentId
  const [maxAccountsReached, setMaxAccountsReached] = useState(false)
  const [updateAccountData, setUpdateAccountData] = useState(true)
  const [updateLoginInfo, setUpdateLoginInfo] = useState(true)
  const [accountData, setAccountData] = useState<AccountDataTotals>(defaultAccountData)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [currentUserAccounts, setCurrentUserAccounts] = useState<number[]>([])
  const [showGoldenChildToast, setShowGoldenChildToast] = useState(false)

  const { t } = useTranslation()

  const {
    getAccountDataRequest,
    getLoginUserInfoRequest,
    createAccountRequest,
    deleteItemRequest,
    loginAsChildRequest,
    updateAssignContactsRequest,
    updateSharePermissionsRequest,
    updateManagersRequest,
    updateSalesUsersRequest,
  } = useAccountManagementListingPageRequests(userId, parentAccountId ?? '0')

  useEffect(() => {
    const getLoginInfo = async () => {
      const { data, errors } = await getLoginUserInfoRequest()

      if (data?.loginUserInfo?.assignedAccounts) {
        setCurrentUserAccounts(data.loginUserInfo.assignedAccounts)
      }

      if (errors) {
        logNewRelicError(errors)
        setErrorMessage(t('Unable to retrieve the list of child accounts you manage.'))
      }
      setUpdateLoginInfo(false)
    }

    if (updateLoginInfo) {
      getLoginInfo()
    }
  }, [updateLoginInfo])

  useEffect(() => {
    const getAccountData = async () => {
      try {
        const { data, errors } = await getAccountDataRequest()

        if (data?.accountData) {
          const currentUsedContacts = data.accountData.usedContacts?.reduce((acc, contacts) => acc + (contacts?.usedContacts ?? 0), 0) ?? 0
          const parentAccountUsedContacts = data.accountData.totalUsedContacts - currentUsedContacts

          setAccountData({
            activeContacts: {
              children: data.accountData.childrenActiveContacts,
              total: data.accountData.totalActiveContacts,
            },
            usedContacts: {
              current: data.accountData.totalUsedContacts,
              total: data.accountData.totalActiveContacts,
            },
            childAccounts: {
              current: data.accountData.currentChildAccounts,
              total: data.accountData.totalChildAccounts,
            },
            parentAccountUsedContacts,
          })
        }

        if (errors) {
          logNewRelicError(errors)
          setErrorMessage(`Error retrieving account data: ${errors[0].message}`)
        }
      } catch (_e) {
        setErrorMessage(t('Unable to connect to the Act-On service'))
      }

      setUpdateAccountData(false)
    }

    if (isAccountAdmin && updateAccountData) {
      getAccountData()
    }
  }, [updateAccountData])

  useEffect(() => {
    setMaxAccountsReached(accountData.childAccounts.current >= accountData.childAccounts.total)
  }, [accountData.childAccounts])

  const createAccount = async (
    createAccountParams: CreateAccountRequestParams,
    update: Update,
    setStatusToast: (message: string | ReactNode, status: Status, folderName?: string, hasTimeout?: boolean) => void,
    setError: SetError
  ) => {
    if (enableGoldenChildToast) {
      setShowGoldenChildToast(true)
      update({ showCustomModal: false })
      createAccountRequest(createAccountParams)
      return
    }

    const { data, errors } = await createAccountRequest(createAccountParams)

    if (data?.createAccount?.success?.name) {
      update({ showCustomModal: false, fetchItems: true })
      const toastMsg = <Typography text={t('ListPage.AccountManagement.CreateAccount.Success')} className={`${rootClass}__create-account-success`} />
      setStatusToast(toastMsg, Status.SUCCESS, undefined, false)
      setUpdateAccountData(true)
      return
    }

    update({ showCustomModal: false })

    if (data?.createAccount?.failure?.name) {
      logNewRelicError(data.createAccount.failure)
      setError(t('ListPage.AccountManagement.CreateAccount.Error', { error: `${data?.createAccount?.failure.reason} ` }), Status.FAIL)
    }

    if (errors) {
      logNewRelicError(errors)
      setError(t('ListPage.AccountManagement.CreateAccount.Error'), Status.FAIL)
    }
  }

  const deleteAccount = async (
    accountId: string,
    name: string,
    update: Update,
    setStatusToast: (message: string | ReactNode, status: Status, folderName?: string) => void,
    setError: SetError
  ) => {
    const { data, errors } = await deleteItemRequest(accountId)

    if (data?.deleteAccount) {
      update({ showCustomModal: false, fetchItems: true })
      const toastMsg = (
        <Typography
          text={t('ListPage.AccountManagement.DeleteAccount.Success', { name })}
          tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
        />
      )
      setStatusToast(toastMsg, Status.SUCCESS)
      setUpdateAccountData(true)
    }

    if (errors) {
      logNewRelicError(errors)
      update({ showCustomModal: false })
      const toastMsg = (
        <Typography
          text={t('ListPage.AccountManagement.DeleteAccount.Error', { name })}
          tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
        />
      )
      setError(toastMsg, Status.FAIL)
    }
  }

  const loginToChildAccount = async (accountId: string, update: Update) => {
    const { data, errors } = await loginAsChildRequest(accountId, userEmail)

    if (data?.loginAsChild) {
      const { domain, redirectLink } = data.loginAsChild
      window.open(`${domain}${redirectLink}`, '_self')
    }

    if (errors) {
      logNewRelicError(errors)
      update({
        loading: false,
        initialPageLoading: false,
        isEditingTag: false,
        statusToast: {
          statusMessage: t('ListPage.AccountManagement.LoginAsChild.Error'),
          status: Status.FAIL,
          showStatusToast: true,
        },
      })
    }
  }

  const updateAssignedContacts = async (
    accountId: string,
    name: string,
    contacts: number,
    update: Update,
    setStatusToast: (message: string | ReactNode, status: Status, folderName?: string) => void,
    setError: SetError
  ) => {
    const { data, errors } = await updateAssignContactsRequest(accountId, userId, contacts)

    if (errors) {
      logNewRelicError(errors)
      update({ showCustomModal: false })
      const toastMsg = (
        <Typography
          text={t('ListPage.AccountManagement.AssignContacts.Error', { name })}
          tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
        />
      )
      setError(toastMsg, Status.FAIL)
    }

    if (data) {
      update({ showCustomModal: false, fetchItems: true })
      const toastMsg = (
        <Typography
          text={t('ListPage.AccountManagement.AssignContacts.Success', { name })}
          tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
        />
      )
      setStatusToast(toastMsg, Status.SUCCESS)
      setUpdateAccountData(true)
    }
  }

  const updateSharePermissions = async (
    sharePermissions: SharePermissionsInput[],
    accounts: AccountSharePermissions[],
    update: Update,
    setStatusToast: (message: string | ReactNode, status: Status, folderName?: string) => void,
    setError: SetError
  ) => {
    const { data, errors } = await updateSharePermissionsRequest(sharePermissions)

    if (errors) {
      logNewRelicError(errors)
      update({ showCustomModal: false })
      const toastMsg = (
        <Typography
          text={t('ListPage.AccountManagement.AssignPermissions.Error', { name: accounts.map((account) => account.name), count: accounts.length })}
          tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
        />
      )
      setError(toastMsg, Status.FAIL)
    }

    if (data) {
      update({ showCustomModal: false, fetchItems: true, forceResetSelectedRows: true })
      const toastMsg = (
        <Typography
          text={t('ListPage.AccountManagement.AssignPermissions.Success', { name: accounts.map((account) => account.name), count: accounts.length })}
          tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
        />
      )
      setStatusToast(toastMsg, Status.SUCCESS)
    }
  }

  const assignManagers = async (
    accountId: string,
    name: string,
    managers: AccountManager[],
    update: Update,
    setStatusToast: (message: string | ReactNode, status: Status, folderName?: string) => void,
    setError: SetError
  ) => {
    const { data, errors } = await updateManagersRequest(accountId, managers)

    if (errors) {
      logNewRelicError(errors)
      update({ showCustomModal: false })
      const toastMsg = (
        <Typography
          text={t('ListPage.AccountManagement.AssignManagers.Error', { name })}
          tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
        />
      )
      setError(toastMsg, Status.FAIL)
    }

    if (data) {
      update({ showCustomModal: false, fetchItems: true })
      const toastMsg = (
        <Typography
          text={t('ListPage.AccountManagement.AssignManagers.Success', { name })}
          tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
        />
      )
      setStatusToast(toastMsg, Status.SUCCESS)
      setUpdateLoginInfo(true)
    }
  }

  const assignSalesUsers = async (
    accountId: string,
    name: string,
    salesUsers: SalesUser[],
    update: Update,
    setStatusToast: (message: string | ReactNode, status: Status, folderName?: string) => void,
    setError: SetError
  ) => {
    const { data, errors } = await updateSalesUsersRequest(accountId, salesUsers)

    if (errors) {
      logNewRelicError(errors)
      update({ showCustomModal: false })
      const toastMsg = (
        <Typography
          text={t('ListPage.AccountManagement.AssignSalesUsers.Error', { name })}
          tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
        />
      )
      setError(toastMsg, Status.FAIL)
    }

    if (data) {
      update({ showCustomModal: false, fetchItems: true })
      const toastMsg = (
        <Typography
          text={t('ListPage.AccountManagement.AssignSalesUsers.Success', { name })}
          tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
        />
      )
      setStatusToast(toastMsg, Status.SUCCESS)
    }
  }

  const renderDataCards = () => <AccountManagementDataCards showData={isAccountAdmin} accountData={accountData} loading={updateAccountData} />

  const sidebarProps: SidebarProps = {
    getCustomDefaultFilters: (menuActions: MenuActions) => getCustomDefaultFilters(isAccountAdmin, userId, menuActions),
    sidebarHeader: 'Accounts',
    hasRecent: false,
    hasFavorites: false,
    hasCreatedByMe: false,
    hideTags: true,
    allItemFilter: isAccountAdmin ? allAccountsFilter : myAccountsFilter,
    customFilterSelected: false,
  }

  const renderHeaderButton = (update: Update) => (
    <Button
      buttonType={ButtonType.PRIMARY}
      iconPosition={ButtonIconPosition.LEFT}
      disabled={maxAccountsReached}
      dataTest={`${dataTest}-create`}
      onClick={() => update({ showCustomModal: true, customTableAction: 'CREATE' })}
    >
      <Svg name={SvgNames.plus} type={SvgType.ICON} />
      {t('Create account')}
    </Button>
  )

  const pageHeaderProps: PageHeaderProps = {
    pageTitle: 'Account Management',
    pageHeaderClassName: isAccountAdmin ? '' : `${rootClass}__page-header`,
    renderPageHeaderContent: (update: Update) =>
      isAccountAdmin &&
      (maxAccountsReached ? (
        <Tooltip trigger={renderHeaderButton(update)}>{t('ListPage.AccountManagement.HeaderButton.Disabled')}</Tooltip>
      ) : (
        renderHeaderButton(update)
      )),
  }

  const customActionHelpers: CustomActionHelpers = {
    createAccount,
    deleteAccount,
    loginToChildAccount,
    updateAssignedContacts,
    updateSharePermissions,
    assignManagers,
    assignSalesUsers,
  }

  const rowDisabled = (row: Row<AccountManagementRow>) => {
    return row.original.parent === 'true'
  }

  const subTypes: ListingPageSubType[] = [
    { label: myAccountsFilter.name, name: `accounts-${userId}`, behaveAsFilter: myAccountsFilter },
    { label: unassignedAccountsFilter.name, name: unassignedAccountsFilter.name, behaveAsFilter: unassignedAccountsFilter },
  ]

  return (
    <>
      {showGoldenChildToast && (
        <StatusToast
          title={t('ListPage.AccountManagement.CreateAccount.DelayToast.Title')}
          message={t('ListPage.AccountManagement.CreateAccount.DelayToast.Message')}
          status={Status.SUCCESS}
          hasTimeout={false}
          closeStatus={() => {
            setShowGoldenChildToast(false)
          }}
          className={`golden-child-toast`}
        />
      )}
      {errorMessage && <StatusToast message={errorMessage} closeStatus={() => setErrorMessage(undefined)} status={Status.FAIL} />}
      <ListingPageContainer
        listingPageProps={{
          readOnlyFolders: !isAccountAdmin,
          externalDataLoading: updateLoginInfo,
          renderDataCards,
          hideDeleteItems: true,
          hasTabs: isAccountAdmin,
          hideMoveItems: !isAccountAdmin,
          canShareToCatalog: false,
          canShareToChildAccounts: false,
          canPreview: false,
          initialFilter: isAccountAdmin ? allAccountsFilter : myAccountsFilter,
          defaultSubTypes: isAccountAdmin ? undefined : [`accounts-${userId}`],
          sortBy: [{ id: 'name', desc: false }],
          searchFields: ['name', 'accountId'],
          subTypes,
          getCustomActiveFilter: getActiveFilter,
          // Leaving this to satisfy typing, but it's not used
          getCustomEmptyListingProps: (
            setFilter: (filterDefinition: FilterDefinition, customFilterSelected: boolean) => void,
            _update: Update,
            filter?: FilterDefinition
          ) => getCustomEmptyListingProps({ setFilter, filter, isStory, isAdmin: isAccountAdmin }),
          getCustomDefaultEmptyListingProps: (values: ListPageCommonState, setFilter: SetFilter) => {
            const filter = values.filterActive
            const search = values.search
            return getCustomEmptyListingProps({ setFilter, filter, isStory, isAdmin: isAccountAdmin, search })
          },
          getCustomFilterParams,
          renderCustomModal: (customTableAction, listPageValues, listPageAPI) =>
            renderCustomModal(
              customTableAction,
              listPageValues,
              listPageAPI,
              customActionHelpers,
              accountData.activeContacts.total - accountData.activeContacts.children - 1,
              accountData.parentAccountUsedContacts,
              t
            ),
          sidebarProps,
          pageHeaderProps,
          tableProps: tableProps(t, isAccountAdmin, hasAgencyAssignSalesUsers, customActionHelpers, accountId, currentUserAccounts, rowDisabled),
        }}
        className={rootClass}
        dataTest={dataTest}
        itemType={ItemType.ACCOUNT}
      />
    </>
  )
}

export default AccountManagementListingPageContainer
