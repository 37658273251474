import React, { FC } from 'react'
import { Controller, FieldError, useFieldArray, useFormContext } from 'react-hook-form'

import classNames from 'classnames'

import AddButton from '@components/AddButton/AddButton'
import Input from '@components/Input/Input'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { SelectOption } from '@interface/Select'
import { renderInfoTooltip } from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/utils/CreateIncomingWebhookModal.utils'
import { ContentMapping } from '@src/pages/Webhooks/IncomingWebhooks/IncomingWebhooksContext'
import { ItemType } from '@utils/categorization'

import './ActOnContactsContentMapping.css'

interface ActOnContactsContentMappingProps {
  className?: string
  dataTest?: string
  fieldMappings: UnifiedListFieldMapping[]
}

const rootClass = 'act-on-contacts-content-mapping'
const rootTranslation = 'Incoming.Webhooks.CreateModal.ContentMapping.ActOnContacts'

const ActOnContactsContentMapping: FC<ActOnContactsContentMappingProps> = (props: ActOnContactsContentMappingProps) => {
  const { dataTest = rootClass, className = '', fieldMappings } = props

  const {
    register,
    control,
    formState: { errors },
    watch,
  } = useFormContext()

  const {
    append,
    remove,
    fields: webhookMappings,
  } = useFieldArray({
    control,
    name: 'contentMappings',
  })

  const { t } = useTranslation()

  const getOptions = (): SelectOption[] =>
    fieldMappings.map(({ columnIndex, displayName }) => ({
      value: columnIndex.toString(),
      label: displayName,
    }))

  const selectedOptions = (): SelectOption[] => {
    const webhookMappings = Array.isArray(watch('contentMappings')) ? watch('contentMappings') : [watch('contentMappings')]
    const selectedColumnIndexes = (webhookMappings as (ContentMapping & { id: string })[]).map(({ destinationField }) => destinationField)

    return fieldMappings
      .filter(({ columnIndex }) => !selectedColumnIndexes.includes(columnIndex.toString()))
      .map(({ columnIndex, displayName }) => ({
        value: columnIndex.toString(),
        label: displayName,
      }))
  }

  const onAddRow = () => {
    append({ destinationField: '', sourceField: '' })
  }

  const onDeleteRow = (rowIndex: number) => {
    remove(rowIndex)
  }

  const renderHeader = (text: string, tooltip: string) => (
    <div className={`${rootClass}__header`}>
      <Typography text={t(text)} weight={TextWeight.MEDIUM} />
      {renderInfoTooltip(t(tooltip))}
    </div>
  )

  const getRowErrors = (index: number): { sourceField?: FieldError; destinationField?: FieldError } => {
    const contentMappingsErrors = errors?.contentMappings
    return Array.isArray(contentMappingsErrors) && contentMappingsErrors.length > index ? contentMappingsErrors[index] : {}
  }

  const renderRow = ({ id, sourceField, destinationField }: { id: string; sourceField: string; destinationField: string }, index: number) => {
    const contentMappingsErrors = getRowErrors(index)
    const invalidNameError = contentMappingsErrors?.sourceField
    const invalidFieldError = contentMappingsErrors?.destinationField
    const fieldsOptions: SelectV2SingleOption[] = getOptions().map(({ value, label }) => ({ label: label ?? '', value: value }))
    const selectedFieldOption = fieldsOptions.find(({ value }) => value === destinationField)
    const remainingOptions: SelectV2SingleOption[] = selectedOptions().map(({ value, label }) => ({ label: label ?? '', value: value }))

    return (
      <div data-test={`${dataTest}-mapping`} className={`${rootClass}__row`} key={id}>
        <div className={`${rootClass}__row-name`}>
          <Input
            dataTest={`${dataTest}-input-name`}
            error={!!invalidNameError}
            defaultValue={sourceField}
            register={register(`contentMappings.${index}.sourceField`)}
          />
          {!!invalidNameError && (
            <div className={`${rootClass}__error`}>
              <Svg name={SvgNames.inputStatusInvalidNoFill} type={SvgType.ICON} />
              <Typography type={TextType.ERROR} text={t(invalidNameError.message)} lineHeight={LineHeight.MEDIUM_SMALL} />
            </div>
          )}
        </div>
        <div className={`${rootClass}__row-field`}>
          <Controller
            control={control}
            render={({ field: { onChange, onBlur } }) => (
              <SelectV2
                key={`${fieldsOptions?.length}-${index}`}
                className={classNames({ [`${rootClass}__input-error`]: invalidFieldError })}
                dataTest={`${dataTest}-select-field`}
                isClearable={false}
                isSearchable
                insideModal
                defaultValue={selectedFieldOption}
                itemType={ItemType.SEGMENT}
                onBlur={onBlur}
                onChange={(option) => {
                  if (option !== undefined) {
                    onChange({ target: { value: option.value } })
                  }
                }}
                options={remainingOptions}
                placeholder={t('Select a field...')}
                error={!!invalidFieldError}
                errorMessage={invalidFieldError?.message}
              />
            )}
            name={`contentMappings.${index}.destinationField`}
          />
        </div>
        <AddButton
          dataTest={`${dataTest}-delete-button`}
          className={`${rootClass}__delete`}
          onClick={() => onDeleteRow(index)}
          tooltip={t('Remove field')}
          isDelete
        />
      </div>
    )
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__header-row`}>
        {renderHeader('Name', `${rootTranslation}.Name.Tooltip`)}
        {renderHeader('Act-On field', `${rootTranslation}.Field.Tooltip`)}
      </div>
      {webhookMappings.map((contentMappings, index) => renderRow(contentMappings as ContentMapping & { id: string }, index))}
      <div className={`${rootClass}__footer`}>
        <AddButton dataTest={`${dataTest}-add-button`} label={t('Add field')} onClick={onAddRow} />
      </div>
    </div>
  )
}

export default ActOnContactsContentMapping
