import { EmptyListingProps, EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { TableV2RowData } from '@components/TableV2/tableV2TS/interfaces'
import { InboxAssetResponseWithoutPreviewInfo } from '@graphql/types/query-types'
import {
  GetPreviewParam,
  PreviewAndImportModalContentProps,
  PreviewAssetsByType,
} from '@src/pages/MarketingNetwork/Inbox/components/PreviewAndImportModal/PreviewAndImport.types'
import { Row, SortingState } from '@tanstack/react-table'
import { AssetTypes } from '@utils/actonAssetTypes'
import { VoidFunction } from '@utils/types'

export enum InboxModals {
  IMPORT = 'import',
  DECLINE = 'decline',
}

export const defaultSortingBy: SortingState = [
  {
    id: 'date',
    desc: true,
  },
]

export const getEmptyListingProps = (t: Function, search: string, buttonOnClick: VoidFunction): EmptyListingProps => {
  const keyModifier = search ? '.Search' : ''
  const imgSrc = search ? StaticImageNames.emptySearch : StaticImageNames.emptyShareAsset
  const buttonProps = search ? { buttonText: t('Inbox.EmptyListing.Search.Button'), buttonOnClick } : {}

  return {
    headline: t(`Inbox.EmptyListing${keyModifier}.Headline`, { search }),
    text: t(`Inbox.EmptyListing${keyModifier}.Text`),
    imgSrc,
    size: EmptyListingSize.MEDIUM,
    hideIcon: false,
    ...buttonProps,
  }
}

export const MORE_INFO_URL = 'https://www.act-on.com/'

export enum PreviewAssetType {
  EMAIL_TEMPLATE = 'TEMPLATE',
  LANDING_PAGE = 'LANDINGPAGE',
  LANDING_PAGE_TEMPLATE = 'LANDINGPAGE_TEMPLATE',
  FORM = 'FORM',
  CONTENT_FRAGMENT = 'CONTENT_FRAGMENT',
  EMAIL_HEADER = 'HEADER',
  EMAIL_FOOTER = 'FOOTER',
  STATIONARY = 'STATIONERY',
  LOGO = 'LOGO',
  IMAGE = 'IMAGE',
  DOC = 'DOC',
  AUTORESPONSE_SHARE = 'AUTORESPONSE',
}

export const NoPreviewTypes = [AssetTypes.PROGRAM_TEMPLATE, PreviewAssetType.STATIONARY]

export const AssetsByTypeDefault: PreviewAssetsByType = {
  [PreviewAssetType.EMAIL_TEMPLATE]: [],
  [PreviewAssetType.LANDING_PAGE]: [],
  [PreviewAssetType.LANDING_PAGE_TEMPLATE]: [],
  [PreviewAssetType.FORM]: [],
  [PreviewAssetType.CONTENT_FRAGMENT]: [],
  [PreviewAssetType.EMAIL_HEADER]: [],
  [PreviewAssetType.EMAIL_FOOTER]: [],
  [PreviewAssetType.STATIONARY]: [],
  [PreviewAssetType.LOGO]: [],
  [PreviewAssetType.IMAGE]: [],
  [PreviewAssetType.DOC]: [],
  [PreviewAssetType.AUTORESPONSE_SHARE]: [],
}

export enum ChangeReplace {
  CHANGE = 'change',
  REPLACE = 'replace',
}

export enum ImportDeclineOptions {
  IMPORT = 'import',
  DECLINE = 'decline',
}

export type InboxAsset = InboxAssetResponseWithoutPreviewInfo & TableV2RowData<any>

export interface InboxActionErrorResult {
  actionType: ImportDeclineOptions
  successes: string[]
  errors: string[]
}

export interface InboxDataProps {
  assets: InboxAsset[]
  selectedRows: PreviewAndImportModalContentProps[]
  search?: string
  showImport?: boolean
  showImportSuccess?: boolean
  showDecline?: boolean
  showDeclineSuccess?: boolean
  actionErrors?: InboxActionErrorResult
  successToast: string
}

export interface InboxProps extends InboxDataProps {
  loading: boolean
  closeSuccessToast: () => void
  onSearch: (term: string) => void
  onShowModal: (modal: string, row?: Row<InboxAsset>) => void
  onImport: () => void
  onDecline: () => void
  onCloseErrorModal: () => void
  onRowSelectionChanged?: (rowIds: string[], rows: Row<InboxAsset>[]) => void
  className?: string
  dataTest?: string
  getPreviewItem: (item: GetPreviewParam) => Promise<any>
  loadingPreview: boolean
}
