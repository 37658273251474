import React, { Fragment, useMemo, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import DropDown from '@components/DropDown'
import { DropDownType } from '@components/DropDown/DropDown'
import DropDownActions, { MenuItem } from '@components/DropDownActions/DropDownActions'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { ActionType, Selection } from '@components/TableV2/tableV2TS/enums'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { HeaderGroup, Row, flexRender } from '@tanstack/react-table'
import { toTrainCase } from '@utils/strings'
import { isFunction } from '@utils/utils'

import { HeaderAction } from '../../tableV2TS/interfaces'
import { selections, splitActions } from '../../utils/tableV2Utils'

import './TableV2ActionHeader.css'

interface TableV2ActionProps<T> {
  dataTest?: string
  buttonType?: ButtonType
  selectedRows: Row<T>[]
  actions: HeaderAction<T>[]
  headerGroups: HeaderGroup<T>[]
  useStickyActionHeader?: boolean
  headerActionCountText: string
  stickyHeaderTopPosition?: number
  onDeselectAll?: () => void
}

const rootClass = 'tableV2-action-header'

export const TableV2ActionHeader = <T extends {}>({
  actions,
  selectedRows,
  headerGroups,
  headerActionCountText,
  dataTest = rootClass,
  stickyHeaderTopPosition,
  onDeselectAll,
}: TableV2ActionProps<T>) => {
  const { t } = useTranslation()

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const [primaryActions, secondaryActions] = useMemo(() => splitActions(actions, selectedRows, ActionType.Header), [actions, selectedRows])

  const renderDelete = ({ label: labelAction, onClick }: HeaderAction<T>, index: number) => {
    const label = isFunction(labelAction) ? labelAction() : labelAction
    return (
      <Button buttonType={ButtonType.DELETE} className={`${rootClass}__delete`} onClick={onClick} key={index}>
        {label}
      </Button>
    )
  }
  const renderAction = (
    { disabled = false, label: labelAction, icon: iconAction, iconSize, hasTooltip, onClick: onClickItem, deselectAllAction }: HeaderAction<T>,
    index: number
  ) => {
    const svgType = iconSize ? iconSize : hasTooltip ? SvgType.LARGER_ICON : SvgType.ICON
    const icon = isFunction(iconAction) ? iconAction() : iconAction ? <Svg name={iconAction as SvgNames} type={svgType} /> : null
    const label = isFunction(labelAction) ? labelAction() : labelAction
    const buttonDataTest = `${dataTest}-${toTrainCase(label)}-button`
    const isDisabled = isFunction(disabled) ? disabled() : disabled
    const onClick = !isDisabled ? onClickItem : undefined
    return (
      <Fragment key={label}>
        {hasTooltip ? (
          <Tooltip
            trigger={
              <Button disabled={isDisabled} buttonType={ButtonType.TABLE_ACTION} onClick={onClick} dataTest={buttonDataTest}>
                {icon}
              </Button>
            }
          >
            {t(label)}
          </Tooltip>
        ) : (
          <Button
            disabled={isDisabled}
            buttonType={actions[index].buttonType ?? ButtonType.FLOAT}
            onClick={(e) => {
              deselectAllAction && onDeselectAll && onDeselectAll()
              onClick?.(e)
            }}
            dataTest={buttonDataTest}
          >
            {icon} {t(label)}
          </Button>
        )}
      </Fragment>
    )
  }

  const renderComponent = ({ embeddedComponent }: HeaderAction<T>, index: number) => <Fragment key={index}>{embeddedComponent}</Fragment>

  const dropdownActions = secondaryActions.map(({ disabled, tooltipMessage, hasTooltip, icon, label, onClick, hidden }) => {
    return { disabled, tooltipMessage, hasTooltip, icon, onClick, text: label, hidden } as MenuItem
  })

  const isDeleteOnly = (action: HeaderAction<T>) => action.label === 'Delete'

  const renderPrimaryActions = useMemo(
    () =>
      (primaryActions as HeaderAction<T>[]).map((action, index) => {
        return action.embeddedComponent
          ? renderComponent(action, index)
          : isDeleteOnly(primaryActions[0] as HeaderAction<T>)
          ? renderDelete(action, index)
          : renderAction(action, index)
      }),
    [primaryActions]
  )

  const firstHeader = headerGroups?.[0]?.headers[0]
  const selection =
    selections.includes(firstHeader?.column.id as Selection) && flexRender(firstHeader.column.columnDef.header, firstHeader.getContext())

  return (
    <div className={rootClass} data-test={dataTest} style={{ top: `${stickyHeaderTopPosition}px` }}>
      <div className={`${rootClass}__checkbox`}>{selection}</div>
      <div className={`${rootClass}__items`}>
        <div className={`${rootClass}__total-count`}>
          <Typography text={headerActionCountText} type={TextType.BODY_TEXT_WHITE} weight={TextWeight.MEDIUM} dataTest="header-action-count-text" />
        </div>
        <div className={`${rootClass}__actions`}>
          {renderPrimaryActions}
          {secondaryActions.length > 0 && (
            <DropDown
              className={classNames(`${rootClass}__actions-drop-down`, { [`${rootClass}__actions-drop-down-open`]: dropdownOpen })}
              isOpen={dropdownOpen}
              toggleOpen={setDropdownOpen}
              hasOverflowIcon
              type={DropDownType.STYLED}
            >
              <DropDownActions menuItems={dropdownActions} closeDropDown={() => setDropdownOpen(false)} />
            </DropDown>
          )}
        </div>
      </div>
    </div>
  )
}
