import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react'

import classNames from 'classnames'

import BackButton from '@components/BackButton/BackButton'
import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import HTMLPreview from '@components/HTMLPreview/HTMLPreview'
import Modal, { ModalBody, ModalHeader, ModalFooter } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, ModalHeaderStyle, TextWeight } from '@components/Typography/Typography'
import { TextType } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import { SignatureItem } from '@graphql/types/query-types'
import { SIGNATURE_DATA_ATTRIBUTE } from '@src/pages/EmailComposer/utils/BeeEditor.constants'
import { prepareCustomAddOnHTML } from '@src/pages/EmailComposer/utils/BeeEditor.utils'
import { detectEmailType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'

import { SignaturesModalContext, SignaturesModalProps } from './SignaturesModal.context'
import SignaturesModalContentContainer from './SignaturesModalContentContainer'
import SignaturesModalSidebarContainer from './SignaturesModalSidebarContainer'

import './signaturesModal.css'

const SIGNATURES_PAGE = `${rootContext}/classic/branding/tab/signatures`

const rootClass = 'signatures-modal'

const SignaturesModal: FC<SignaturesModalProps> = (props: SignaturesModalProps) => {
  const { className, dataTest = rootClass, isOpen, selectedSignatureId, onClose, onAction } = props

  const {
    values: {
      message: { messageType },
    },
  } = useContext(EmailComposerContext)

  const {
    update,
    values: { data, selectedSignature, previewSignature },
  } = useContext(SignaturesModalContext)

  const { t } = useTranslation()

  const isSystem = previewSignature?.ID === 0
  const title = `EmailComposer.Signatures.Modal.${isSystem ? 'SystemPreset' : previewSignature?.isUser ? 'MySignature' : 'AccountSignature'}`

  const handleBack = useCallback(() => {
    if (previewSignature) {
      update({ previewSignature: undefined })
    }
  }, [previewSignature, update])

  const handleInsert = useCallback(() => {
    const signature = previewSignature || selectedSignature
    signature &&
      onAction({
        type: 'html',
        value: { html: prepareCustomAddOnHTML(signature.html, SIGNATURE_DATA_ATTRIBUTE, signature.ID) },
      })
  }, [onAction, previewSignature, selectedSignature])

  const handleSignaturePreview = useCallback(
    (signature: SignatureItem) => {
      update({ previewSignature: signature })
    },
    [update]
  )

  useEffect(() => {
    if (selectedSignatureId) {
      update({ selectedSignature: data.find((signature: SignatureItem) => signature.ID?.toString() === selectedSignatureId) })
    }
  }, [data, selectedSignatureId, update])

  const { isEmailCRM } = detectEmailType(messageType)

  const header = (
    <ModalHeader headerType={ModalHeaderType.List} className={`${rootClass}__header`}>
      <div className={`${rootClass}__header-section`}>
        {previewSignature && <BackButton onClick={handleBack} noXMargin />}
        <div className={`${rootClass}__header-subsection`} data-test={`${dataTest}-header-subsection`}>
          <Typography text={t(previewSignature ? title : 'EmailComposer.Signatures.Modal.Title')} {...ModalHeaderStyle} />
          {previewSignature && (
            <>
              {isSystem && (
                <Tooltip
                  className={`${rootClass}__header-tooltip`}
                  position="right"
                  align="end"
                  isPopover
                  inline={false}
                  minimalPadding={false}
                  trigger={<Svg name={SvgNames.info} type={SvgType.LARGER_ICON} fill={SvgColor.LIGHT_GRAY} />}
                  activeTrigger={<Svg name={SvgNames.infoHover} type={SvgType.LARGER_ICON} fill={SvgColor.TEXT_TEAL} />}
                >
                  {t('EmailComposer.Signatures.MediaCard.Tooltip')}
                </Tooltip>
              )}
              {(previewSignature?.isUserDefault || previewSignature?.isAccountDefault) && (
                <Typography text={`(${t('default')})`} type={TextType.MODAL_HEADER_SUB_INFO} />
              )}
            </>
          )}
        </div>
      </div>
      <div className={`${rootClass}__header-section`}>
        {!isEmailCRM && (
          <Button
            className={`${rootClass}__header-manage-button`}
            dataTest={`${rootClass}-header-manage-button`}
            buttonType={ButtonType.FLOAT}
            iconPosition={ButtonIconPosition.LEFT}
            onClick={() => window.open(SIGNATURES_PAGE, '_blank')}
          >
            <Svg name={SvgNames.gear} type={SvgType.ICON} fill={SvgColor.BUTTON_GRAY} />
            <Typography text={t('EmailComposer.Signatures.Modal.ManageButton')} weight={TextWeight.MEDIUM} lineHeight={LineHeight.SMALL} />
          </Button>
        )}
      </div>
    </ModalHeader>
  )

  const renderPreviewSelectedSignature = useMemo(
    () => (
      <>
        {selectedSignature ? (
          <Button
            className={`${rootClass}__footer-preview-button`}
            dataTest={`${dataTest}-footer-preview-button`}
            buttonType={ButtonType.INFO}
            iconPosition={ButtonIconPosition.LEFT}
            onClick={() => handleSignaturePreview(selectedSignature)}
          >
            <Svg name={SvgNames.zoom} />
            {t('EmailComposer.Signatures.Modal.PreviewButton')}
          </Button>
        ) : (
          <Typography
            text={t(`EmailComposer.Signatures.Modal.NoSelectedInfo`)}
            type={TextType.BODY_TEXT_SMALL_LIGHT}
            lineHeight={LineHeight.MEDIUM_SMALL}
            inline
          />
        )}
      </>
    ),
    [selectedSignature]
  )

  return (
    <>
      <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
        <ModalBody className={`${rootClass}__body`}>
          {previewSignature ? (
            <HTMLPreview html={previewSignature.html ?? ''} dataTest={`${dataTest}-preview`} />
          ) : (
            <>
              <SignaturesModalSidebarContainer />
              <SignaturesModalContentContainer />
            </>
          )}
        </ModalBody>
        <ModalFooter className={`${rootClass}__footer`} footerType={ModalFooterType.List}>
          <div className={`${rootClass}__footer`}>
            <div className={`${rootClass}__footer-section`}>{!previewSignature && renderPreviewSelectedSignature}</div>
            <div className={`${rootClass}__footer-section`}>
              <Button buttonType={ButtonType.TERTIARY} onClick={!!previewSignature ? handleBack : onClose} dataTest={`${dataTest}-button-tertiary`}>
                {t(!!previewSignature ? 'Back' : 'Cancel')}
              </Button>
              <Button
                buttonType={ButtonType.PRIMARY}
                disabled={
                  (!selectedSignature && !previewSignature) || selectedSignatureId === (previewSignature || selectedSignature)?.ID?.toString()
                }
                onClick={handleInsert}
                dataTest={`${dataTest}-button-primary`}
              >
                {!!selectedSignatureId ? t('EmailComposer.Signatures.Modal.ReplaceButton') : t('EmailComposer.Signatures.Modal.InsertButton')}
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default SignaturesModal
