import React, { useCallback, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/Button'
import Svg, { SvgNames, SvgType } from '@components/Svg'

import './switchButtons.css'

export const rootClass = 'switch-buttons'

export interface SwitchButtonsProps<T> {
  icons: { icon: SvgNames; key: T }[]
  onChange?: (activeKey: T) => void
  defaultActive?: string
  className?: string
  dataTest?: string
}

const SwitchButtons = <T extends string>(props: SwitchButtonsProps<T>) => {
  const { icons, onChange, defaultActive, dataTest = rootClass } = props
  const [activeKey, setActiveKey] = useState<string | undefined>(defaultActive)

  const handleClick = useCallback(
    (key: T) => {
      setActiveKey(key)
      onChange && onChange(key)
    },
    [onChange]
  )

  return (
    <div className={rootClass}>
      {icons.map(({ icon, key }) => (
        <Button
          buttonType={ButtonType.ICON}
          key={key}
          className={classNames(`${rootClass}__item`, { [`${rootClass}__item-active`]: key === activeKey })}
          onClick={() => handleClick(key)}
          dataTest={`${dataTest}-button-${key}`}
        >
          <Svg name={icon} dataTest={`${dataTest}-icon-${key}`} type={SvgType.MEDIUM_LARGE_ICON} className={`${rootClass}__item-icon`} />
        </Button>
      ))}
    </div>
  )
}

export default SwitchButtons
