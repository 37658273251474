import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Container from '@components/Container'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { getUUID } from '@const/globals'

import './ComplexList.css'

export interface ComplexListColumn {
  title: string | ReactNode
  className?: string
}

export interface ComplexListRow {
  label: string
  data: ReactNode[]
  dataPlain?: string[]
  dataWithPercent?: string[][]
  children?: ComplexListRow[]
  title?: string
  className?: string
  isHighlighted?: boolean
}

interface Props {
  title: string
  subTitle?: string
  columns: ComplexListColumn[]
  rows: ComplexListRow[]
  actions?: ReactNode
  className?: string
  dataTest?: string
  isHoverEnabled?: boolean
}

const rootClass = 'complex-list'

const ComplexList: FC<Props> = (props: Props) => {
  const { title, subTitle, columns, rows, actions, dataTest = rootClass, className = '', isHoverEnabled = false } = props

  const renderColumnLabels = () => {
    return (
      <div className={`${rootClass}__label-row`} data-test={`${dataTest}-label-row`}>
        <div className={`${rootClass}__label-column`}>&nbsp;</div>
        {columns.map((col, idx) => (
          <Typography text={col.title} className={`${rootClass}__label-data`} type={TextType.TABLE_HEADER} key={`title-${idx}`} />
        ))}
      </div>
    )
  }

  const renderCell = (col: string | ReactNode, isChild: boolean) => {
    if (isHoverEnabled && typeof col === 'string' && col.includes('%')) {
      const [pct, number] = col?.split('-')
      return (
        <div className={`${rootClass}__num-pct-parent`}>
          <Typography
            text={pct}
            type={isChild ? TextType.BODY_TEXT_LIGHT : TextType.BODY_TEXT}
            weight={isChild ? TextWeight.REGULAR : TextWeight.BOLD}
            dataTest={`${dataTest}-data-cell`}
            inline
            className={`${rootClass}__pct-display`}
          />
          <Typography
            text={number}
            type={isChild ? TextType.BODY_TEXT_LIGHT : TextType.BODY_TEXT}
            weight={isChild ? TextWeight.REGULAR : TextWeight.BOLD}
            dataTest={`${dataTest}-data-cell`}
            inline
            className={`${rootClass}__number-display`}
          />
        </div>
      )
    } else if (typeof col === 'string') {
      return (
        <Typography
          text={col}
          type={isChild ? TextType.BODY_TEXT_LIGHT : TextType.BODY_TEXT}
          weight={isChild ? TextWeight.REGULAR : TextWeight.BOLD}
          dataTest={`${dataTest}-data-cell`}
        />
      )
    } else {
      return col
    }
  }

  const renderRow = (row: ComplexListRow, isLast = false, isChild = false): ReactNode => {
    const childClassname = isChild ? `${rootClass}__row-child` : ''
    const labelClassname = isChild ? `${rootClass}__label-column-child` : ''
    const lastRowClass = isLast && isChild ? `${rootClass}__row-last` : isLast ? `${rootClass}__row-last-parent` : ''
    const hasChildClassname = row.children ? `${rootClass}__row-parent` : ''
    const childCount = row.children?.length ?? 0
    const rowKey = getUUID(true)

    return (
      <div key={`row-${rowKey}`}>
        <div
          className={classNames(`${rootClass}__row`, hasChildClassname, childClassname, lastRowClass, row.className)}
          data-test={`${dataTest}-row${isChild ? '-child' : ''}`}
        >
          <div className={classNames(`${rootClass}__label-column`, labelClassname)} data-test={`${dataTest}-label-cell`}>
            <Typography text={row.label} type={isChild ? TextType.BODY_TEXT_LIGHT : TextType.BODY_TEXT} inline />
            {row.title && (
              <span title={row.title} className={`${rootClass}__label-column-svg`}>
                <Svg name={SvgNames.info} type={SvgType.LARGER_ICON} />
              </span>
            )}
          </div>
          {row.data.map((col: string | ReactNode, idx: number) => {
            return (
              <div
                className={classNames(`${rootClass}__label-data`, columns[idx].className, { [`${rootClass}__highlighted`]: row.isHighlighted })}
                key={`data-${idx}`}
              >
                {renderCell(col, isChild)}
              </div>
            )
          })}
        </div>
        {row.children && row.children.map((child, idx) => renderRow(child, idx === childCount - 1, true))}
      </div>
    )
  }

  return (
    <Container className={classNames(rootClass, className)} dataTest={dataTest}>
      <div className={`${rootClass}__title-row`}>
        <Typography
          text={title}
          type={TextType.SECTION_HEADER}
          className={`${rootClass}__title-row-text`}
          dataTest={`${dataTest}__title`}
          weight={TextWeight.MEDIUM}
        />
        {actions && <div className={`${rootClass}__title-row-actions`}>{actions}</div>}
      </div>
      {subTitle && <Typography text={subTitle} type={TextType.BODY_TEXT_SMALL_LIGHT} />}
      {renderColumnLabels()}
      {rows.map((row, idx) => renderRow(row, idx === rows.length - 1))}
    </Container>
  )
}

export default ComplexList
