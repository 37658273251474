import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import { EmailComposerContext, EmailPreviewPlatform } from '@utils/composer/context/EmailComposer.context'

import EmailComposerPreviewIframe from '../../EmailComposerPreview/components/EmailComposerPreviewContent/components/EmailComposerPreviewIframe/EmailComposerPreviewIframe'
import EmailComposerPreviewMobile from '../../EmailComposerPreview/components/EmailComposerPreviewContent/components/EmailComposerPreviewMobile/EmailComposerPreviewMobile'
import EmailComposerPreviewTabs from '../../EmailComposerPreview/components/EmailComposerPreviewHeader/components/EmailComposerPreviewTabs/EmailComposerPreviewTabs'

import '../ReviewAndSend.css'

interface ReviewAndSendSidebarProps {
  rootClass: string
  dataTest?: string
}

const ReviewAndSendSidebar: FC<ReviewAndSendSidebarProps> = ({ rootClass, dataTest = rootClass }) => {
  const {
    values: {
      message: { templateHtml, isPlainTextOnly, templateJson },
      validations: { isContentMissing },
      preview: { html, plainText, platform },
      detectedURLChanges: { plainTextMode },
      loading,
      isSaving,
    },
    api: { updatePreview },
  } = useContext(EmailComposerContext)
  const reviewFrame = useRef<HTMLIFrameElement>(null)
  const [scaleScore, setScaleScore] = useState<number>(0)
  const [isAtBottom, setIsAtBottom] = useState(false)

  const iframeWrapper = document.getElementsByClassName('email-composer-preview-iframe__wrapper')[0]

  useEffect(() => {
    const updateScaleScore = () => {
      if (iframeWrapper && !isSaving) {
        const templateWidth = templateJson.page.body.content.computedStyle.messageWidth
        // If cannot retrieve message width, a scale of 1 will allow the preview to load
        const scale = !templateWidth ? 1 : iframeWrapper.clientWidth / parseInt(templateWidth)

        setScaleScore(scale)
      }
    }

    const checkIframeWrapper = () => {
      const iframeWrapper = document.getElementsByClassName('email-composer-preview-iframe__wrapper')[0]
      if (iframeWrapper) {
        updateScaleScore()
      } else {
        setTimeout(checkIframeWrapper, 100)
      }
    }

    checkIframeWrapper()
  }, [iframeWrapper, isSaving, scaleScore])

  useEffect(() => {
    updatePreview({ platform: EmailPreviewPlatform.DESKTOP })
  }, [isSaving])

  // Scroll handling for .page-container to check if user is at the bottom
  const handleScroll = () => {
    const pageContainer = document.querySelector('.page-container')
    if (pageContainer) {
      if (pageContainer.scrollHeight - pageContainer.scrollTop === pageContainer.clientHeight) {
        setIsAtBottom(true)
      } else {
        setIsAtBottom(false)
      }
    }
  }

  useEffect(() => {
    const pageContainer = document.querySelector('.page-container')
    if (pageContainer) {
      pageContainer.addEventListener('scroll', handleScroll)
    }

    // Cleanup the event listener on unmount
    return () => {
      if (pageContainer) {
        pageContainer.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  const showPlainText = isPlainTextOnly || plainTextMode
  const srcValue = showPlainText ? plainText : templateHtml || html

  const renderContent = useCallback(() => {
    switch (platform) {
      case EmailPreviewPlatform.DESKTOP:
        return <EmailComposerPreviewIframe html={updatedMobile} plainTextMode={showPlainText} scaleScore={scaleScore} />
      case EmailPreviewPlatform.MOBILE:
        return <EmailComposerPreviewMobile hideRotate={true} hideInfo={true} previewFrameRef={reviewFrame} srcValue={templateHtml} />
      default:
        return scaleScore && <EmailComposerPreviewIframe html={srcValue} plainTextMode={showPlainText} scaleScore={scaleScore} />
    }
  }, [platform, scaleScore, showPlainText, srcValue, templateHtml, templateJson])

  if (loading) {
    return null
  }

  const tabs = [
    { label: 'Desktop', value: 'desktop', disabled: false },
    { label: 'Mobile', value: 'mobile', disabled: false },
  ]

  const parser = new DOMParser()
  const doc = parser.parseFromString(srcValue, 'text/html')

  const styleTags = doc.querySelectorAll('style')

  let updatedDesktopCSS = ''
  let updatedMobileCSS = ''

  styleTags.forEach((styleTag) => {
    if (styleTag.textContent) {
      const mobileCleanedCSS = styleTag.textContent.replace(/\.mobile_hide\s*{[^}]*}|@media[^{]*{[^}]*\.mobile_hide\s*{[^}]*}[^}]*}/gi, '')
      updatedDesktopCSS += `${mobileCleanedCSS}\n.desktop_hide { display: none !important; }`
      updatedMobileCSS += mobileCleanedCSS
    }
  })

  const mobileDoc = doc.cloneNode(true) as Document
  mobileDoc.querySelectorAll('style').forEach((styleTag, index) => {
    styleTag.textContent = updatedMobileCSS.split('\n')[index] || ''
  })
  const updatedMobile = showPlainText ? templateHtml : mobileDoc.documentElement.outerHTML

  const desktopDoc = doc.cloneNode(true) as Document
  desktopDoc.querySelectorAll('style').forEach((styleTag, index) => {
    styleTag.textContent = updatedDesktopCSS.split('\n')[index] || ''
  })

  return (
    <div className={rootClass} data-test={dataTest}>
      <div className="background_scrollbottom" style={{ display: isAtBottom ? 'block' : 'none' }}></div>
      <div
        className={classNames(`${rootClass}__iframe-wrapper`, {
          [`${rootClass}__iframe-wrapper-plain`]: plainTextMode,
          [`${rootClass}__iframe-wrapper-content-missing`]: isContentMissing,
          [`${rootClass}__iframe-wrapper-mobile`]: platform === EmailPreviewPlatform.MOBILE,
        })}
      >
        {renderContent()}
        <div className={`${rootClass}__tabs-container`}>
          <EmailComposerPreviewTabs
            tabs={tabs}
            activeTab={platform === EmailPreviewPlatform.INBOX ? EmailPreviewPlatform.DESKTOP : platform}
            onChange={(value) => updatePreview({ platform: value as EmailPreviewPlatform })}
            haveUnsavedChanges={false}
            messageType={'AB_TEST_MESSAGE'}
            onSave={() => {}}
            updateModal={() => {}}
          />
        </div>
      </div>
    </div>
  )
}

export default ReviewAndSendSidebar
