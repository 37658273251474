import React, { FC, MutableRefObject, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Row } from 'react-table'

import classNames from 'classnames'

import { ButtonType } from '@components/Button'
import { Button, ButtonSize } from '@components/Button/Button'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import CustomPrompt from '@components/CustomPrompt/CustomPrompt'
import DeleteConfirmation from '@components/DeleteConfirmation/DeleteConfirmation'
import PageContainer from '@components/PageContainer'
import PageHeader from '@components/PageHeader'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import Svg, { SvgNames } from '@components/Svg'
import TabAO from '@components/TabsAO/TabAO'
import Tabs from '@components/TabsAO/TabsAO'
import TriggerButton from '@components/TriggerButton/TriggerButton'
import { useTranslation } from '@const/globals'
import { ProgramStep } from '@graphql/types/query-types'
import ProgramDetails from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramDetails/ProgramDetails'
import ProgramStepsDataCards from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsDataCards/ProgramStepsDataCards'
import ProgramStepsListing from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/ProgramStepsListing'
import { SAVE_PROGRAM_EVENT } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/utils/ProgramStepsListing.constants'
import {
  programStepsAndDetailsInitialState,
  ProgramStepsAndDetailsTabs,
} from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/utils/ProgramStepsAndDetails.constants'
import {
  getDeleteConfirmationModalProps,
  getProgramStepsAndDetailsActions,
  getProgramStepsAndDetailsTabs,
  scrollToStep,
  triggerStepEditEvent,
  validateSteps,
} from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/utils/ProgramStepsAndDetails.utils'
import { ListMaintenanceProgramsContext } from '@src/pages/listingPages/ListMaintenancePrograms/context/ListMaintenancePrograms.context'
import { LIST_MAINTENANCE_PROGRAMS_URL } from '@src/pages/listingPages/ListMaintenancePrograms/utils/Helpers'
import ProgramErrorsModal from '@src/pages/programs/dashboard/components/ProgramErrorsModal/ProgramErrorsModal'
import ProgramStatus from '@src/pages/programs/dashboard/components/ProgramStatus/ProgramStatus'
import { useAccountSettings } from '@utils/account/account.utils'
import { ProgramState } from '@utils/program/program.constants'

import './ProgramStepsAndDetails.css'

interface ProgramStepsAndDetailsProps {
  className?: string
  dataTest?: string
  backUrlRef?: MutableRefObject<string | undefined>
  onAddStep: (position: number, step?: ProgramStep) => void
  onEditStep: ({ index }: Row<ProgramStep>) => void
  onDeleteStep: ({ original }: Row<ProgramStep>) => void
  onSaveChanges: (checkForErrors?: boolean) => void
  onCancelChanges: () => void
  reloadProgram: (useCache: boolean) => void
  loading?: boolean
}

export interface ProgramStepsAndDetailsState {
  currentTab: string
  showCancelConfirmation?: boolean
  showDeleteModal: boolean
}

const rootClass = 'program-steps-and-details'

const ProgramStepsAndDetails: FC<ProgramStepsAndDetailsProps> = (props: ProgramStepsAndDetailsProps) => {
  const {
    dataTest = rootClass,
    className = '',
    onAddStep,
    onDeleteStep,
    onEditStep,
    backUrlRef,
    onSaveChanges,
    onCancelChanges,
    reloadProgram,
    loading,
  } = props

  const {
    deletePrograms,
    runProgram,
    setProgram,
    update,
    values: { program, isEditing, programDetails, programStatus, showErrorsModal, stepsWithInvalidValue, isValidatingStepValue },
  } = useContext(ListMaintenanceProgramsContext)

  const [state, setState] = useState<ProgramStepsAndDetailsState>(programStepsAndDetailsInitialState())
  const { currentTab, showDeleteModal, showCancelConfirmation } = state

  const pageContainerRef = useRef<HTMLDivElement>(null)
  const stepsRefs = useRef<Map<number, HTMLDivElement>>(new Map())

  const { t } = useTranslation()

  const { userAllowedToCreatePrograms, userAllowedToDeletePrograms } = useAccountSettings()

  const { push } = useHistory()

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.get('fixErrors') === 'true' && programStatus?.stepErrors && programDetails?.steps) {
      if (programStatus.stepErrors.length > 0) {
        update({ showErrorsModal: true })
      }
      searchParams.delete('fixErrors')
      push({ pathname: LIST_MAINTENANCE_PROGRAMS_URL, search: searchParams.toString() })
    }
  }, [programStatus, programDetails])

  const setShowDeleteModal = useCallback((showDeleteModal: boolean) => setState((state) => ({ ...state, showDeleteModal })), [])

  const onTabChange = useCallback((tab: string) => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('tab', tab)
    push({ pathname: LIST_MAINTENANCE_PROGRAMS_URL, search: searchParams.toString() })
    setState((state) => ({ ...state, currentTab: tab }))
  }, [])

  const listingTabs = useMemo(() => getProgramStepsAndDetailsTabs(t), [])

  const actions = useMemo(() => {
    return getProgramStepsAndDetailsActions({
      canDelete: userAllowedToDeletePrograms,
      canEdit: userAllowedToCreatePrograms,
      runProgram: () => program && runProgram(program).then((hasRun) => hasRun && reloadProgram(false)),
      setShowDeleteModal,
      t,
      hasValidProgram: !!programStatus?.valid,
      runProgramEnabled: !!program?.valid,
      onViewErrorSummary: () => update({ showErrorsModal: true }),
      onAddStep: () => {
        onAddStep((programDetails?.steps ?? []).length)
        if (currentTab === ProgramStepsAndDetailsTabs.PROGRAM_DETAILS) {
          onTabChange(ProgramStepsAndDetailsTabs.PROGRAM_STEPS)
        }
      },
    })
  }, [isEditing, programDetails, program, currentTab, programStatus])

  const onErrorsModalFixErrorsClick = () => {
    if (programStatus) {
      update({ showErrorsModal: false })
      if (isEditing) {
        onSaveChanges(false)
      } else {
        if (programStatus.stepErrors.length > 0 && programDetails?.steps) {
          const firstStepId = programStatus.stepErrors[0].id
          const firstStepToEdit = programDetails.steps.findIndex(({ stepId }) => stepId === firstStepId)
          triggerStepEditEvent(firstStepToEdit)
          update({ stepBeingEditedIndex: firstStepToEdit })
          scrollToStep(firstStepToEdit, pageContainerRef.current, stepsRefs.current)
        }
      }
      update({ isEditing: !isEditing })
    }
  }

  const onSaveClick = () => {
    const allStepsAreNamed = validateSteps(programDetails?.steps ?? [], pageContainerRef.current, stepsRefs.current, stepsWithInvalidValue)
    const event = new CustomEvent(SAVE_PROGRAM_EVENT)
    document.dispatchEvent(event)
    if (allStepsAreNamed) {
      onSaveChanges()
    }
  }

  const deleteConfirmationProps = useMemo(
    () => getDeleteConfirmationModalProps(deletePrograms, setShowDeleteModal, setProgram, t, program),
    [program]
  )

  if (!program) {
    return null
  }

  return (
    <PageContainer className={classNames(rootClass, className)} dataTest={dataTest} register={pageContainerRef}>
      {showErrorsModal && programStatus && !programStatus.valid && (
        <ProgramErrorsModal
          errors={programStatus}
          isSaving={isEditing}
          isListMaintenanceProgram
          fixErrors={onErrorsModalFixErrorsClick}
          closeModal={() => update({ showErrorsModal: false })}
          hasStepPrefix={false}
          disabled={!userAllowedToCreatePrograms}
        />
      )}
      {showCancelConfirmation && (
        <ConfirmationModal
          body={t('Leave.And.Lose.Changes.Message')}
          cancelButtonText={t('Keep editing')}
          deleteButtonText={t('Discard changes')}
          title={t('You have unsaved changes')}
          onAnswer={(answer) => {
            if (answer === YesNo.YES) {
              onCancelChanges()
            }
            setState((state) => ({ ...state, showCancelConfirmation: false }))
          }}
          isDelete
          isOpen
        />
      )}
      {showDeleteModal && <DeleteConfirmation {...deleteConfirmationProps} />}
      <CustomPrompt
        when={isEditing}
        title={t('You have unsaved changes')}
        body={t('Leave.And.Lose.Changes.Message')}
        cancelButtonText={t('Keep editing')}
        okButtonText={t('Discard changes')}
        showOnLocationChange={({ search }) => !search.includes(program?.externalId)}
      />
      <PositionContainer>
        <PageHeader dataTest={dataTest} className={`${rootClass}__page-header`} leftContent linkUrl={backUrlRef?.current} primaryText={program.name}>
          {(isEditing || (programStatus && !programStatus?.valid)) && <ProgramStatus programState={ProgramState.ERROR} isEditing={isEditing} />}
          {isEditing ? (
            <>
              <Button
                className={`${rootClass}__edit-mode-cancel`}
                buttonType={ButtonType.TERTIARY}
                inline
                onClick={() => setState((state) => ({ ...state, showCancelConfirmation: true }))}
              >
                {t('Cancel')}
              </Button>
              <Button
                disabled={!userAllowedToCreatePrograms || isValidatingStepValue}
                title={userAllowedToCreatePrograms ? '' : t('Ask your administrator for permission to do this')}
                className={`${rootClass}__edit-mode-save`}
                buttonType={ButtonType.PRIMARY}
                onClick={onSaveClick}
                inline
              >
                <Svg name={SvgNames.check} />
                {t('Save changes')}
              </Button>
            </>
          ) : (
            <TriggerButton
              buttonSize={ButtonSize.LARGE}
              buttonType={ButtonType.PRIMARY}
              dataTest={`${dataTest}-actions`}
              label={t('Actions')}
              options={actions}
            />
          )}
        </PageHeader>
        <Tabs childData={listingTabs} defaultValue={currentTab} onChange={onTabChange}>
          <TabAO index={ProgramStepsAndDetailsTabs.PROGRAM_STEPS}>
            <ProgramStepsDataCards loading={loading} switchTab={onTabChange} />
            <ProgramStepsListing
              steps={programDetails?.steps ?? []}
              loading={loading}
              onDeleteStep={onDeleteStep}
              onAddStep={onAddStep}
              onEditStep={onEditStep}
              stepsRefs={stepsRefs}
            />
          </TabAO>
          <TabAO index={ProgramStepsAndDetailsTabs.PROGRAM_DETAILS}>
            <ProgramDetails loading={loading} />
          </TabAO>
        </Tabs>
      </PositionContainer>
    </PageContainer>
  )
}

export default ProgramStepsAndDetails
