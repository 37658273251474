import { SelectOption } from '@interface/Select'

export enum NewLeadsOption {
  BY_SOURCE = 'bySource',
  OVER_TIME = 'overTime',
}

export const getNewLeadsOptions = (hideOverTime: boolean, t: (s: any) => any): (SelectOption & { value: NewLeadsOption })[] => [
  {
    value: NewLeadsOption.BY_SOURCE,
    label: 'Lead source',
  },
  {
    value: NewLeadsOption.OVER_TIME,
    label: 'Over time',
    disabled: hideOverTime,
    disabledTooltip: t('Revenue.OverTime.Disabled.Tooltip'),
  },
]
