import { gql } from '@apollo/client'

export default gql`
  query loadFromAddressesPage($sortColumn: String, $sortDirection: Direction) {
    loadFromAddressesPage(sortColumn: $sortColumn, sortDirection: $sortDirection) {
      email
      cell
      fax
      isVerified
      isDefault
      name
      phone
      title
      uuid
    }
  }
`
