import React, { FC, useState } from 'react'

import classNames from 'classnames'

import { AssetPickerModalBaseProps } from '@complex/AssetPickerModal/AssetPickerModal'
import { ExtendedItemDto } from '@complex/ListingPage/Context/ListingPageCommon.context'
import ListPickerModal from '@complex/ListPickerModalV2/ListPickerModal'
import AutomatedProgramPickerModal from '@components/AssetPickers/AutomatedProgramPickerModal/AutomatedProgramPickerModal'
import EmailPickerModal from '@components/AssetPickers/EmailPickerModal/EmailPickerModal'
import EmailTemplatesPickerModal from '@components/AssetPickers/EmailTemplatesPickerModal/EmailTemplatesPickerModal'
import FormsPickerModal from '@components/AssetPickers/FormsPickerModal/FormsPickerModal'
import FormTemplatesPickerModal from '@components/AssetPickers/FormTemplatesPickerModal/FormTemplatesPickerModal'
import LandingPagesPickerModal from '@components/AssetPickers/LandingPagesPickerModal/LandingPagesPickerModal'
import LandingPageTemplatesPickerModal from '@components/AssetPickers/LandingPageTemplatesPickerModal/LandingPageTemplatesPickerModal'
import MediaPickerModal from '@components/AssetPickers/MediaPickerModal/MediaPickerModal'
import { MediaItem } from '@components/AssetPickers/MediaPickerModal/MediaPickerModal.context'
import WebinarsPickerModal from '@components/AssetPickers/WebinarsPickerModal/WebinarsPickerModal'
import WebPagePickerModal from '@components/AssetPickers/WebPagePickerModal/WebPagePickerModal'
import AssetSelect from '@components/AssetSelect/AssetSelect'
import { useTranslation } from '@const/globals'
import { AssetTypes } from '@utils/actonAssetTypes'

import './AssetPickerSelector.css'

interface AssetPickerSelectorProps {
  className?: string
  dataTest?: string
  selectedAssets?: ExtendedItemDto[]
  onSelect: (assets: ExtendedItemDto[]) => void
  assetType: AssetTypes
}

const rootClass = 'asset-picker-selector'

const AssetPickerSelector: FC<AssetPickerSelectorProps> = (props: AssetPickerSelectorProps) => {
  const { dataTest = rootClass, className = '', onSelect, selectedAssets, assetType } = props

  const [showAssetPicker, setShowAssetPicker] = useState(false)
  const [isViewingSelected, setIsViewingSelected] = useState(false)

  const { t } = useTranslation()

  const selectedAssetsIds = selectedAssets?.map(({ id }) => id)
  const lowerCaseAssetType = assetType.toLowerCase()

  const openAssetPicker = (viewSelectedAssets = false) => {
    setShowAssetPicker(true)
    setIsViewingSelected(viewSelectedAssets)
  }

  const closeAssetPicker = () => {
    setShowAssetPicker(false)
    setIsViewingSelected(false)
  }

  const onView = () => {
    openAssetPicker(true)
  }

  const onSubmit = (assets: ExtendedItemDto[]) => {
    onSelect(assets)
    closeAssetPicker()
  }

  const commonAssetPickerProps: AssetPickerModalBaseProps = {
    preSelectedRowIds: selectedAssetsIds,
    onClose: closeAssetPicker,
    onSubmit,
    isSingleSelect: false,
    isViewingSelected,
    isOpen: true,
  }

  const renderFormsPicker = () => {
    switch (assetType) {
      case AssetTypes.EMAIL_TEMPLATE:
        return <EmailTemplatesPickerModal {...commonAssetPickerProps} />
      case AssetTypes.FORM:
        return <FormsPickerModal {...commonAssetPickerProps} />
      case AssetTypes.FORM_TEMPLATE:
        return <FormTemplatesPickerModal {...commonAssetPickerProps} />
      case AssetTypes.LANDING_PAGE:
        return <LandingPagesPickerModal {...commonAssetPickerProps} />
      case AssetTypes.LANDING_PAGE_TEMPLATE:
        return <LandingPageTemplatesPickerModal {...commonAssetPickerProps} />
      case AssetTypes.MEDIA:
        const onSubmitMedia = (media: MediaItem) => {
          const item: ExtendedItemDto = {
            id: media.id,
            name: media.title,
          }
          onSubmit([item])
        }

        let preSelectedItem: MediaItem | undefined
        if (selectedAssets && selectedAssets.length > 0) {
          preSelectedItem = {
            created: selectedAssets[0].createdTime ?? 0,
            docType: '',
            isFavorite: !!selectedAssets[0].isFavorite,
            modified: selectedAssets[0].updatedTime ?? 0,
            previewUrl: '',
            id: selectedAssets[0].id,
            title: selectedAssets[0].name ?? '',
            size: selectedAssets[0].count ?? 0,
            folderId: selectedAssets[0].folderId ?? '',
          }
        }
        return (
          <MediaPickerModal
            onClose={closeAssetPicker}
            preSelectedItem={preSelectedItem}
            onSingleSubmit={onSubmitMedia}
            onBack={closeAssetPicker}
            isOpen
          />
        )
      case AssetTypes.PROGRAM:
        return <AutomatedProgramPickerModal {...commonAssetPickerProps} />
      case AssetTypes.SEGMENT:
        return (
          <ListPickerModal
            closeModal={closeAssetPicker}
            defaultSelectedLists={selectedAssetsIds}
            submitLists={onSubmit}
            isViewingSelected={isViewingSelected}
            isOpen
          />
        )
      case AssetTypes.EMAIL:
        return <EmailPickerModal {...commonAssetPickerProps} />
      case AssetTypes.LANDING_PAGE_LINK:
        closeAssetPicker()
        return null
      case AssetTypes.LINK:
        closeAssetPicker()
        return null
      case AssetTypes.SMS:
        closeAssetPicker()
        return null
      case AssetTypes.WEBINAR:
        return <WebinarsPickerModal {...commonAssetPickerProps} />
      case AssetTypes.WEB_PAGE:
        return <WebPagePickerModal {...commonAssetPickerProps} />
    }
  }

  return (
    <>
      {showAssetPicker && renderFormsPicker()}
      <AssetSelect
        className={classNames(rootClass, className)}
        dataTest={dataTest}
        placeholder={t('AssetPickerSelector.Placeholder', { context: lowerCaseAssetType })}
        selectedAssets={selectedAssets?.map(({ name = '' }) => name)}
        onClick={openAssetPicker}
        onSecondaryClick={onView}
        assetType={lowerCaseAssetType}
        allowMultiple
      />
    </>
  )
}

export default AssetPickerSelector
