import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import ReactAce from 'react-ace'

import classNames from 'classnames'

import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'

import { CodeEditorWithPreview } from './components/CodeEditorWithPreview/CodeEditorWithPreview'
import { htmlToUploadHtmlJson } from './utils/UploadHtmlComposer.utils'

import './UploadHtmlComposer.css'

const rootClass = 'upload-html-composer'

interface UploadHtmlComposerProps {
  className?: string
}

export const UploadHtmlComposer: FC<UploadHtmlComposerProps> = ({ className }) => {
  const {
    values: {
      message: { templateHtml },
    },
    api: { update },
  } = useContext(EmailComposerContext)

  const [isUndoEnabled, setIsUndoEnabled] = useState<boolean>(false)
  const [isRedoEnabled, setIsRedoEnabled] = useState<boolean>(false)

  const editorRef = useRef<ReactAce>(null)

  useEffect(() => {
    const editor = editorRef.current?.editor
    const session = editor?.getSession()
    const undoManager = session?.getUndoManager()

    const updateUndoRedoStatus = () => {
      if (undoManager) {
        setIsUndoEnabled(undoManager.hasUndo())
        setIsRedoEnabled(undoManager.hasRedo())
      }
    }

    editor?.on('change', () => {
      updateUndoRedoStatus()
    })

    return () => {
      editor?.off('change', updateUndoRedoStatus)
    }
  }, [])

  const handleUndo = () => {
    const editor = editorRef.current?.editor
    if (editor) {
      editor.undo()
      setIsRedoEnabled(editor.getSession().getUndoManager().hasRedo())
    }
  }

  const handleRedo = () => {
    const editor = editorRef.current?.editor
    if (editor) {
      editor.redo()
      setIsUndoEnabled(editor.getSession().getUndoManager().hasUndo())
    }
  }

  const handleInsertText = (textToInsert: string) => {
    const editor = editorRef.current?.editor
    const cursorPosition = editor?.getCursorPosition()

    editor?.session.insert(cursorPosition!, textToInsert)
  }

  const onCodeChange = (code: string) => {
    update({
      message: {
        templateJson: htmlToUploadHtmlJson(code),
        templateHtml: code,
      },
    })
  }

  useEffect(() => {
    onCodeChange(templateHtml)
  }, [templateHtml])

  return (
    <div className={classNames(rootClass, className)}>
      <CodeEditorWithPreview
        content={templateHtml}
        onCodeChange={onCodeChange}
        undo={handleUndo}
        redo={handleRedo}
        insert={handleInsertText}
        isRedoEnabled={isRedoEnabled}
        isUndoEnabled={isUndoEnabled}
        aceRef={editorRef}
      />
    </div>
  )
}
