import { Update } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { FilterDefinition, favoriteFilter, allDraftsFilter, createdByMeFilter } from '@utils/filter'

export const getCustomEmptyListingProps = (
  setFilter: (filterDefinition: FilterDefinition, customFilterSelected: boolean) => void,
  _update: Update,
  goToCatalog: () => void,
  filter?: FilterDefinition
) => {
  if (filter === favoriteFilter) {
    return {
      imgSrc: StaticImageNames.emptyFavorites,
      size: EmptyListingSize.LARGE,
      headline: 'ListPage.Drafts.EmptyState.Favorites.Headline',
      text: 'ListPage.Drafts.EmptyState.Favorites.Text',
      buttonText: 'ListPage.Drafts.EmptyState.Favorites.ButtonText',
      buttonOnClick: () => setFilter(allDraftsFilter, false),
    }
  } else if (filter === createdByMeFilter) {
    return {
      imgSrc: StaticImageNames.emptyCreatedByMe,
      size: EmptyListingSize.LARGE,
      headline: 'ListPage.Drafts.EmptyState.CreatedByMe.Headline',
      text: 'ListPage.Drafts.EmptyState.CreatedByMe.Text',
    }
  } else {
    return {
      imgSrc: StaticImageNames.emptyChart,
      size: EmptyListingSize.LARGE,
      headline: 'ListPage.Drafts.EmptyState.AllDrafts.Headline',
      text: 'ListPage.Drafts.EmptyState.AllDrafts.Text',
      linkText: 'ListPage.Drafts.EmptyState.AllDrafts.LinkText',
      link: 'https://connect.act-on.com/hc/en-us/articles/360023758214-Landing-Page-Composer-Overview',
      hideIcon: false,
      buttonText: 'ListPage.Drafts.EmptyState.AllDrafts.ButtonText',
      buttonOnClick: () => goToCatalog(),
    }
  }
}
