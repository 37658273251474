import React, { ChangeEvent, FC, useContext, useMemo, useState } from 'react'

import Checkbox from '@components/Checkbox'
import InputV2 from '@components/InputV2/InputV2'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import {
  DataType,
  FieldSetOperation,
  FieldSetValues,
} from '@src/pages/programs/upgradeManager/components/steps/ChangeFieldValueUpgradeStep/components/FieldReplacement/FieldReplacement.utils'
import {
  UpgradedProgramRecordSetStep,
  UpgradedStepCondition,
  UpgradedStepOperation,
  UpgradeManagerContext,
} from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'

import './FieldReplacement.css'

interface FieldReplacementProps {
  operation: UpgradedStepOperation
  conditionId?: string
}

const rootClass = 'field-replacement'
const parentCommonClass = 'change-field-value-upgrade-step__common-condition'
const rootText = 'AutomatedPrograms.UpgradeManager.FieldReplacement.'
const INPUT_DEBOUNCE_TIME = 500

const textByDataType: { [key in DataType]: string } = {
  [DataType.TEXT]: 'Text',
  [DataType.NUMBER]: 'Number',
  [DataType.DATE]: 'Date',
  [DataType.BOOLEAN]: 'Boolean',
  [DataType.DATETIME]: 'Datetime',
  [DataType.EMAIL]: 'Email',
  [DataType.MULTI_TEXT]: 'Multi Text',
  [DataType.SCORE]: 'Score',
}

export const FieldReplacement: FC<FieldReplacementProps> = ({
  operation: { operationId, fieldName, value, rule, newField },
  conditionId,
}: FieldReplacementProps) => {
  const {
    values: { allContactsFieldsOptions, standardFields, upgradedCurrentStep },
    update,
  } = useContext(UpgradeManagerContext)

  const [isNew, setIsNew] = useState(!!newField?.isNew)

  const fieldNameMatchesStandardField = useMemo(
    () => standardFields?.some((field) => field.label === newField?.name),
    [standardFields, newField?.name]
  )

  const fieldNameMatchesUCLField = useMemo(
    () => !!allContactsFieldsOptions && allContactsFieldsOptions.some((option) => option.value === newField?.name),
    [allContactsFieldsOptions, newField?.name]
  )

  const dataTypeOptions = useMemo<SelectV2SingleOption[]>(() => {
    return Object.values(DataType).map((dataType) => ({
      value: dataType,
      label: textByDataType[dataType],
    }))
  }, [])

  const dataTypeSelectedOption = useMemo(
    () => dataTypeOptions.find((option) => option.value === (newField?.dataType ?? DataType.TEXT)),
    [dataTypeOptions, newField]
  )

  const { t } = useTranslation()

  const upgradedChangeFieldValueStep = upgradedCurrentStep as UpgradedProgramRecordSetStep

  const selectedOption = useMemo(() => {
    return allContactsFieldsOptions?.find((option) => option.value === newField?.name)
  }, [allContactsFieldsOptions, newField?.name])

  const getUpgradedConditions = (newFieldName: string, conditions: UpgradedStepCondition[], createNewField: boolean, dataType?: DataType) => {
    return conditions.map((condition) =>
      condition.conditionId === conditionId
        ? {
            ...condition,
            operations: getUpgradedOperations(newFieldName, condition.operations, createNewField, dataType),
          }
        : condition
    )
  }

  const getUpgradedOperations = (newFieldName: string, operations: UpgradedStepOperation[], createNewField: boolean, dataType?: DataType) => {
    return operations.map((operation) => {
      if (operation.operationId === operationId) {
        return {
          ...operation,
          newField: {
            name: newFieldName,
            isNew: createNewField,
            dataType: dataType ?? operation.newField?.dataType ?? DataType.TEXT,
          },
        }
      } else {
        return operation
      }
    })
  }

  const onChange = (newFieldName = '', isNewField: boolean, dataType?: DataType) => {
    if (upgradedChangeFieldValueStep) {
      update({
        upgradedCurrentStep: {
          ...upgradedChangeFieldValueStep,
          ...(conditionId
            ? { conditions: getUpgradedConditions(newFieldName, upgradedChangeFieldValueStep.conditions, isNewField, dataType) }
            : { operations: getUpgradedOperations(newFieldName, upgradedChangeFieldValueStep.operations, isNewField, dataType) }),
          upgraded: true,
        } as UpgradedProgramRecordSetStep,
      })
    }
  }

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value.trim(), true)
  }

  const onFieldSelectChange = (option?: SelectV2SingleOption) => {
    onChange(option?.value, false)
  }

  const onDataTypeSelectChange = (option?: SelectV2SingleOption) => {
    onChange(newField?.name, true, (option?.value as DataType) ?? DataType.TEXT)
  }

  const onNewFieldCheckboxChange = () => {
    setIsNew(!isNew)
    if (!isNew) {
      onChange(fieldName, true)
    } else {
      onChange(undefined, false)
    }
  }

  return (
    <div key={operationId} className={rootClass}>
      <div className={`${rootClass}__current-field-container`}>
        <div className={`${rootClass}__value-container`}>
          <Typography text={t(`${rootText}CurrentField`)} weight={TextWeight.MEDIUM} lineHeight={LineHeight.MEDIUM} />
          <Typography className={`${rootClass}__current-field`} text={fieldName} />
        </div>
        {!!rule && (
          <div className={`${parentCommonClass}-container`}>
            <Typography className={parentCommonClass} text={t(FieldSetValues[rule as FieldSetOperation])} lineHeight={LineHeight.MEDIUM} />
            <Typography className={`${parentCommonClass}-source`} text={value} type={TextType.BODY_TEXT_SMALL} lineHeight={LineHeight.MEDIUM_SMALL} />
          </div>
        )}
      </div>
      <Svg className={`${rootClass}__arrow`} name={SvgNames.arrowRight} type={SvgType.LARGE_ICON} />
      <div className={`${rootClass}__value-container`}>
        <Typography text={t(`${rootText}ActOnContactsFieldSelect`)} weight={TextWeight.MEDIUM} lineHeight={LineHeight.MEDIUM} />
        {isNew ? (
          <>
            <div>
              <InputV2
                value={newField?.name ?? fieldName}
                onChange={onInputChange}
                onChangeDebounce={INPUT_DEBOUNCE_TIME}
                error={fieldNameMatchesStandardField || fieldNameMatchesUCLField}
                inputInfo={{
                  errorText: fieldNameMatchesStandardField
                    ? t('Please pick a name that is not already used in Standard Fields')
                    : t('Please pick a name that is not already used in Act-On Contacts'),
                  enabled: fieldNameMatchesStandardField || fieldNameMatchesUCLField,
                }}
                disabled={upgradedCurrentStep?.removed}
              />
            </div>
            <SelectV2
              options={dataTypeOptions}
              value={dataTypeSelectedOption}
              onChange={onDataTypeSelectChange}
              isDisabled={upgradedCurrentStep?.removed}
              isClearable={false}
              insideModal
            />
          </>
        ) : (
          <SelectV2
            options={allContactsFieldsOptions}
            onChange={onFieldSelectChange}
            insideModal
            value={selectedOption}
            isDisabled={upgradedCurrentStep?.removed}
          />
        )}
        <Checkbox
          label={t('Create a new field')}
          onChange={onNewFieldCheckboxChange}
          checked={isNew}
          disabled={upgradedCurrentStep?.removed}
          showTickOnDisabled
        />
      </div>
    </div>
  )
}
