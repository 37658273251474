import React, { ReactNode } from 'react'
import { Row } from 'react-table'

import { ApolloClient } from '@apollo/client'
import { TableActions } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.constants'
import { getActionProps } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.utils'
import { ListPageCommonContextAPI, ListPageCommonState, Update } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { CatalogLink } from '@complex/ListingPage/Utils/ListingPage.constants'
import { EmptyListingProps } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import StatusToast, { Status } from '@components/StatusToast/StatusToast'
import { SvgNames } from '@components/Svg'
import { RowAction } from '@components/Table/Table'
import { rootContext } from '@const/globals'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import CreateNewTemplate from '@src/pages/listingPages/AutomatedProgramTemplates/components/CreateNewTemplate/CreateNewTemplate'
import CreateProgramFromTemplate from '@src/pages/listingPages/AutomatedProgramTemplates/components/CreateProgramFromTemplate/CreateProgramFromTemplate'
import { getAutomatedProgramTemplatesListingPageRequests } from '@src/pages/listingPages/AutomatedProgramTemplates/GraphQL/AutomatedProgramTemplatesListingPageRequests.graphQL'
import {
  AutomatedProgramTemplateCustomModals,
  AutomatedProgramTemplatesCustomTableActions,
  ProgramTemplateListing,
  rowActionCustomProps,
} from '@src/pages/listingPages/AutomatedProgramTemplates/utils/ListingPage.constants'
import { allTemplatesFilter, createdByMeFilter, favoriteFilter, FilterDefinition } from '@utils/filter'

export const getCustomEmptyListingProps = (
  setFilter: (filterDefinition: FilterDefinition, customFilterSelected: boolean) => void,
  update: Update,
  filter?: FilterDefinition
): EmptyListingProps => {
  if (filter === favoriteFilter) {
    return {
      imgSrc: StaticImageNames.emptyFavorites,
      headline: "You don't have any favorites yet",
      text: 'Add hearts to templates that you want to easily find later',
      buttonText: 'View all templates',
      buttonOnClick: () => setFilter(allTemplatesFilter, false),
    }
  } else if (filter === createdByMeFilter) {
    return {
      imgSrc: StaticImageNames.emptyCreatedByMe,
      headline: 'You haven’t created any templates yet',
      text: 'You’ll find templates here once you’ve created them',
      buttonText: 'Create Template',
      buttonOnClick: () => update({ showCustomModal: true, customTableAction: AutomatedProgramTemplateCustomModals.CREATE_TEMPLATE }),
    }
  } else {
    return {
      imgSrc: StaticImageNames.emptyChart,
      headline: "Let's start building",
      text: 'ListPage.Common.Templates.EmptyState.AllFilter.Text',
      buttonText: 'Check out our template catalog',
      buttonOnClick: () => window.open(CatalogLink.PROGRAM, '_blank'),
    }
  }
}

export const getCustomFilterParams = (_activeFilter?: FilterDefinition) => ({ field: '', query: '' })

const renderShowPopupStatus = (message: string, status: Status, update: Update, t: Function, fetchItems = false) => {
  return <StatusToast message={t(message)} status={status} closeStatus={() => update({ showCustomModal: false, fetchItems })} />
}

const onSubmitCreateProgram = async (row: ProgramTemplateListing, apolloClient: ApolloClient<any>, update: Update) => {
  const { createProgramFromTemplateRequest } = getAutomatedProgramTemplatesListingPageRequests(apolloClient)

  const assets = row.m_descriptions.map((asset) => ({ id: asset.id, description: '' }))

  const { data, errors } = await createProgramFromTemplateRequest(row.externalId, assets)
  if (data) {
    window.location.assign(`${rootContext}/automation/programs/${data.createProgramFromTemplate}`)
  } else if (errors) {
    update({ showCustomModal: true, customTableAction: AutomatedProgramTemplateCustomModals.CREATE_PROGRAM_ERROR })
  }
}

const noSelectRowActions = [
  AutomatedProgramTemplateCustomModals.CREATE_TEMPLATE,
  AutomatedProgramTemplateCustomModals.CREATE_TEMPLATE_ERROR,
  AutomatedProgramTemplateCustomModals.CREATE_TEMPLATE_SUCCESS,
]

export const renderCustomModal = (
  customTableAction: AutomatedProgramTemplateCustomModals,
  listPageValues: ListPageCommonState,
  listPageAPI: Partial<ListPageCommonContextAPI<ListPageCommonState>>,
  apolloClient: ApolloClient<object>,
  t: Function
) => {
  const { update } = listPageAPI
  const { selectedRows } = listPageValues

  if (selectedRows.length === 0 && noSelectRowActions.indexOf(customTableAction) < 0) return

  const customModal: { [key in AutomatedProgramTemplateCustomModals]: () => ReactNode } = {
    [AutomatedProgramTemplateCustomModals.CREATE_PROGRAM]: () => {
      const row = selectedRows[0] as ProgramTemplateListing
      if (!row || !update) {
        return undefined
      }

      return (
        <CreateProgramFromTemplate
          isOpen
          assets={row.m_descriptions}
          onCreate={() => onSubmitCreateProgram(row, apolloClient, update)}
          onClose={() => update && update({ showCustomModal: false })}
        />
      )
    },
    [AutomatedProgramTemplateCustomModals.CREATE_PROGRAM_ERROR]: () =>
      update && renderShowPopupStatus('Your program could not be created', Status.FAIL, update, t),
    [AutomatedProgramTemplateCustomModals.CREATE_TEMPLATE]: () => {
      return update ? <CreateNewTemplate update={update} /> : undefined
    },
    [AutomatedProgramTemplateCustomModals.CREATE_TEMPLATE_SUCCESS]: () =>
      update && renderShowPopupStatus('Your template was created', Status.SUCCESS, update, t),
    [AutomatedProgramTemplateCustomModals.CREATE_TEMPLATE_ERROR]: () =>
      update && renderShowPopupStatus('Your template could not be created', Status.FAIL, update, t),
  }

  return customModal[customTableAction]()
}

export const getCustomRowActionsUtils = (tableActions: TableActions, t: Function, canCreate: boolean): RowAction[] => {
  return [
    {
      label: t('Create Program From Template'),
      icon: SvgNames.appendedList,
      disabled: !canCreate,
      hasTooltip: !canCreate,
      tooltipMessage: 'Ask your administrator for permission to do this',
      onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(AutomatedProgramTemplatesCustomTableActions.CREATE_PROGRAM, row),
      ...getActionProps(AutomatedProgramTemplatesCustomTableActions.CREATE_PROGRAM, rowActionCustomProps),
    },
  ]
}
