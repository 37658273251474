import React, { useMemo } from 'react'

import classNames from 'classnames'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ProgramState } from '@utils/program/program.constants'

export const useStatusValues = () => {
  const { t } = useTranslation()
  return useMemo(
    () => ({
      [ProgramState.DRAFT]: {
        icon: <Svg name={SvgNames.warningSolid} type={SvgType.ICON} />,
        text: t('Draft with errors'),
      },
      [ProgramState.ERROR]: {
        icon: <Svg name={SvgNames.warningSolid} type={SvgType.ICON} />,
        text: t('Error'),
      },
      [ProgramState.PAUSED]: {
        icon: <div className={classNames(`program-selected__circle`, `program-selected__circle-stopped`)}>&nbsp;</div>,
        text: t('Paused'),
      },
      [ProgramState.RUNNING]: {
        icon: <div className={classNames(`program-selected__circle`, `program-selected__circle-running`)}>&nbsp;</div>,
        text: t('Running'),
      },
    }),
    []
  )
}

export const getDisabledRowTooltip = (t: Function) => (
  <Typography text={t('UpgradePrograms.Page.SelectProgram.DisableTooltip')} type={TextType.BODY_TEXT_WHITE} />
)
