import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import './TableList.css'

export interface TableListRow {
  left: ReactNode
  right: ReactNode
  isTeal?: boolean
  className?: string
}

export interface Props {
  rows: TableListRow[]
  totalRow?: TableListRow
  hasBorder?: boolean
  className?: string
  dataTest?: string
}

const rootClass = 'table-list'

const TableList: FC<Props> = (props: Props) => {
  const { rows, totalRow, hasBorder, dataTest = rootClass, className = '' } = props

  const renderRow = (row: TableListRow, key: string, isTotal?: boolean) => {
    const isTeal = row.isTeal && hasBorder
    return (
      <div
        className={classNames(`${rootClass}__row`, row.className, {
          [`${rootClass}__row-total`]: isTotal,
          [`${rootClass}__row-no-border`]: !hasBorder,
          [`${rootClass}__row-teal`]: isTeal,
        })}
        key={key}
      >
        {row.left}
        {row.right}
      </div>
    )
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div
        className={classNames(`${rootClass}__table`, {
          [`${rootClass}__table-border`]: hasBorder,
        })}
      >
        {rows.map((row, idx) => renderRow(row, `id-${idx}`, false))}
      </div>
      {totalRow && renderRow(totalRow, 'tl-total-row', true)}
    </div>
  )
}

export default TableList
