import React, { FC, ReactNode, useState } from 'react'

import classNames from 'classnames'

import { ButtonType } from '@components/Button/index'
import ConfirmationModal, { ConfirmationModalProps, YesNo } from '@components/ConfirmationModal/ConfirmationModal'
import Input from '@components/Input/Input'
import { SvgNames } from '@components/Svg/index'
import Svg, { SvgColor, SvgType } from '@components/Svg/Svg'
import Typography, { LineHeight, ModalBodyStyle, ModalHeaderConfirmStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './DeleteWithConfirmationModal.css'

interface DeleteWithConfirmationModalProps extends Omit<ConfirmationModalProps, 'body'> {
  bodyText: string | ReactNode
  confirmationWord?: string
  className?: string
  dataTest?: string
}

const rootClass = 'delete-with-confirmation-modal'

const DeleteWithConfirmationModal: FC<DeleteWithConfirmationModalProps> = (props: DeleteWithConfirmationModalProps) => {
  const { title, bodyText, confirmationWord = 'DELETE', deleteButtonText, isOpen, onAnswer, dataTest = rootClass, className = '' } = props
  const [confirmWord, setConfirmWord] = useState<string>('')
  const { t } = useTranslation()

  const answered = (answer: YesNo) => {
    if (onAnswer) {
      onAnswer(answer)
      setConfirmWord('')
    }
  }

  const body = (
    <>
      {typeof bodyText === 'string' ? <Typography text={bodyText} {...ModalBodyStyle} /> : bodyText}
      <div className={`${rootClass}__confirmation-body-input`}>
        <Typography
          text={t('DeleteWithConfirmationModal.Confirmation.Text', { word: confirmationWord })}
          className={`${rootClass}__confirmation-body-input-text`}
          {...ModalBodyStyle}
          type={TextType.BODY_TEXT_SMALL}
          lineHeight={LineHeight.TINY}
          weight={TextWeight.MEDIUM}
        />
        <Input
          value={confirmWord}
          onChange={({ target: { value } }) => setConfirmWord(value)}
          dataTest={`${dataTest}-input`}
          className={`${rootClass}__confirmation-body-input-elem`}
        />
      </div>
    </>
  )

  const titleElem = (
    <div className={`${rootClass}__title`}>
      <div className={`${rootClass}__title-svg`}>
        <Svg name={SvgNames.warning} type={SvgType.VERY_LARGE_ICON} fill={SvgColor.TEXT_GRAY} />
      </div>
      <Typography text={title} {...ModalHeaderConfirmStyle} />
    </div>
  )

  return (
    <ConfirmationModal
      isOpen={isOpen}
      isDelete
      title={titleElem}
      body={body}
      deleteButtonText={deleteButtonText}
      deleteButtonType={ButtonType.ERASE}
      deleteDisabled={confirmWord !== confirmationWord}
      onAnswer={(answer) => answered(answer)}
      className={classNames(rootClass, className)}
      data-test={dataTest}
    />
  )
}

export default DeleteWithConfirmationModal
