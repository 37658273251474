import { gql } from '@apollo/client'

export default gql`
  query getEmailComposerRowsCategories {
    getEmailComposerRowsCategories {
      id
      isDefault
      name
    }
  }
`
