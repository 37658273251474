import { OverviewResponse } from '@graphql/types/query-types'
import { BaseViewModel } from '@src/models/base.view-model'

export class OverviewVM extends BaseViewModel<OverviewResponse> {
  protected readonly data: OverviewResponse

  constructor(data: OverviewResponse) {
    super()
    this.data = data
  }

  getData(): OverviewResponse {
    return this.data
  }

  getFullName(): string {
    return `${this.data?.firstName} ${this.data?.lastName}`.trim()
  }

  getTitle(): string {
    return !this.data?.firstName && !this.data?.lastName ? this.data.login : this.getFullName()
  }

  getUserInfo(): string {
    const userType = this.data.userType === 'Admin User' ? 'Admin' : this.data.userType
    return this.data?.jobTitle ? this.data?.jobTitle : userType
  }

  getProfileAddress(): string {
    const { street = '', street2 = '', city = '', state = '', zip = '', country = '' } = this.data
    const address: string[] = []

    if (!!street) {
      address.push(street)
    }

    if (!!street2) {
      address.push(street2)
    }

    if (!!city) {
      address.push(city)
    }

    if (!!state || !!zip) {
      address.push(`${state} ${zip}`.trim())
    }

    if (!!country) {
      address.push(country)
    }

    return address.join(', ')
  }
}

export default OverviewVM
