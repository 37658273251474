import * as React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { createRoot } from 'react-dom/client'
import 'regenerator-runtime/runtime.js'

import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client'
import introspection from '@graphql/types/possible-types'
import '@styles/utils.css'
import { cleanTypename, logoutOnAuthError } from '@utils/apollo'
import '@utils/const/globals'
import { initHighcharts } from '@utils/highcharts'
import store from '@utils/store'

import { AppLocal } from './components/App'

initHighcharts()

let url = '/acton/graphql/'
const params = new URLSearchParams(window.location.search)
if (params.get('useMocks') === 'true') {
  url = `http://${params.get('mockServer')}:8083/graphql?mockUser=${params.get('mockUser')}`
}

const logOutOnAuthError = logoutOnAuthError()
const cleanTypeName = cleanTypename()
const cache = new InMemoryCache({
  possibleTypes: introspection.possibleTypes,
  typePolicies: {
    FolderListInfo: {
      keyFields: ['id', 'name', 'entries'],
    },
    PreviewAsset: {
      keyFields: ['id', 'type'],
    },
    CitrixWebinarResponse: {
      keyFields: ['ctxId', 'title'],
    },
    InboxAssetResponseWithoutPreviewInfo: {
      keyFields: ['id', 'sharedAccountId'],
    },
  },
})

// Setup Apollo with GraphQL
const httpLink = new HttpLink({ uri: url, fetchOptions: {} })
const client = new ApolloClient({
  // @ts-ignore
  link: ApolloLink.from([logOutOnAuthError, cleanTypeName, httpLink]),
  cache,
  credentials: 'include',
})

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <BrowserRouter>
          <AppLocal />
        </BrowserRouter>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>
)
