import { Status } from '@components/StatusToast/StatusToast'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { SegmentDefinitionResponse } from '@graphql/types/microservice/segment-types'
import { Update } from '@src/pages/ContactSegments/components/CopySegments/context/CopySegments.context'

export const showStatusToast = (update: Update, message: string) =>
  update({
    statusToast: {
      message,
      status: Status.FAIL,
      showStatusToast: true,
    },
  })

export const filterAndSortContactsFields = (allContactsFields: UnifiedListFieldMapping[]) =>
  allContactsFields
    .filter((field: UnifiedListFieldMapping) => !field.hidden && !field.deleted)
    .sort((a: UnifiedListFieldMapping, b: UnifiedListFieldMapping) => {
      return (a.displayName ? a.displayName : '') < (b.displayName ? b.displayName : '') ? -1 : 1
    })

export const getDeletedContactsFields = (allContactsFields: UnifiedListFieldMapping[]) =>
  allContactsFields.filter((field: UnifiedListFieldMapping) => field.deleted)

export const buildSegmentIdFieldNamesMap = (segmentDefinitionFields: SegmentDefinitionResponse[]) => {
  const result: { [key: string]: string[] } = {}
  segmentDefinitionFields.forEach(({ fieldName, segmentInfo }) => {
    segmentInfo?.forEach((segmentInfo) => {
      if (segmentInfo?.segmentId && fieldName) {
        if (!result[segmentInfo?.segmentId]) {
          result[segmentInfo?.segmentId] = []
        }
        result[segmentInfo?.segmentId].push(fieldName)
      }
    })
  })
  return result
}
