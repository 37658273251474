import React, { FC } from 'react'

import classNames from 'classnames'

import AffectedContactsDataCard from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsDataCards/components/AffectedContactsDataCard/AffectedContactsDataCard'
import NextRunDataCard from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsDataCards/components/NextRunDataCard/NextRunDataCard'
import { ProgramStepsAndDetailsTabs } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/utils/ProgramStepsAndDetails.constants'

import './ProgramStepsDataCards.css'

interface ProgramStepsDataCardsProps {
  className?: string
  dataTest?: string
  loading?: boolean
  switchTab: (newTab: ProgramStepsAndDetailsTabs) => void
}

const rootClass = 'program-steps-data-cards'

const ProgramStepsDataCards: FC<ProgramStepsDataCardsProps> = (props: ProgramStepsDataCardsProps) => {
  const { dataTest = rootClass, className = '', loading, switchTab } = props

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <AffectedContactsDataCard />
      <NextRunDataCard loading={loading} switchTab={switchTab} />
    </div>
  )
}

export default ProgramStepsDataCards
