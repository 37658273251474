import { ApolloClient } from '@apollo/client'
import getAllSignature from '@graphql/queries/getAllSignature'
import { GetAllSignatureQuery, GetAllSignatureQueryVariables } from '@graphql/types/query-types'

export const useSignaturesRequests = (client: ApolloClient<any>) => {
  const getAllSignatures = async (params: GetAllSignatureQueryVariables) => {
    return await client.query<GetAllSignatureQuery, GetAllSignatureQueryVariables>({
      query: getAllSignature,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: { ...params },
    })
  }

  return {
    getAllSignatures,
  }
}
