import React, { FC } from 'react'

import classNames from 'classnames'

import ColumnChart from '@components/ColumnChart/ColumnChart'
import { ColWithLegend, renderColWithTitle } from '@components/Table/components/tableColumns'
import Table, { TableColumn } from '@components/Table/Table'
import { ChartField } from '@const/Chart.constants'
import { useTranslation } from '@const/globals'

import './SummaryReport.css'

interface TableField {
  action: string
  color?: string
  indented?: boolean
  uniqueCount?: number
  uniquePercent?: string
  totalCount?: number
}

type Props = {
  chartDetails: ChartField[]
  tableData: TableField[]
  className?: string
  dataTest?: string
}

const rootClass = 'sms-summary-report'

const SummaryReport: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '', chartDetails, tableData } = props
  const { t } = useTranslation()

  const renderColWithLegend = (row: any) => {
    return <ColWithLegend row={row} />
  }

  const columns: TableColumn[] = [
    {
      Header: 'Action',
      Cell: (row: any) => renderColWithLegend(row),
      accessor: 'action',
      align: 'left',
      flexColumn: true,
      disableSortBy: true,
    },
    {
      Header: 'Unique Count',
      accessor: 'uniqueCount',
      minWidth: 100,
      maxWidth: 100,
      align: 'right',
      disableSortBy: true,
    },
    {
      Header: 'Unique %',
      Cell: (row: any) => renderColWithTitle(row),
      accessor: 'uniquePercent',
      minWidth: 100,
      maxWidth: 100,
      align: 'right',
      disableSortBy: true,
    },
    {
      Header: 'Total Count',
      accessor: 'totalCount',
      minWidth: 100,
      maxWidth: 100,
      align: 'right',
      disableSortBy: true,
    },
  ]

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <ColumnChart title={t('SMS Engagement Details')} fields={chartDetails} className={`${rootClass}__chart-container-base`} />
      <div className={`${rootClass}__table-container`} data-test={dataTest}>
        <Table data={tableData} columns={columns} className={rootClass} dataTest={`${dataTest}__table`} />
      </div>
    </div>
  )
}

export default SummaryReport
