import { useCallback, useRef, useState } from 'react'

import { ApolloQueryResult, useApolloClient, useMutation } from '@apollo/client'
import { useTranslation } from '@const/globals'
import editUser from '@graphql/mutations/editUser'
import { EditUserMutation, EditUserMutationVariables, OverviewManageProfile } from '@graphql/types/mutation-types'
import { GetAllMarketingUsersQuery, GetAllSalesUsersQuery, UserResponse } from '@graphql/types/query-types'
import { StatusToastType } from '@interface/StatusToast'

const useEditProfile = (setToastStatus: (value: StatusToastType) => void) => {
  const [userData, setUserData] = useState<OverviewManageProfile>({})
  const [showModal, setShowModal] = useState<boolean>(false)
  const refetchRef = useRef<Function | undefined>(undefined)
  const userId = useRef<string | undefined>(undefined)

  const { t } = useTranslation()
  const client = useApolloClient()
  const [onSave] = useMutation<EditUserMutation, EditUserMutationVariables>(editUser, {
    client,
    fetchPolicy: 'no-cache',
  })

  const onClose = useCallback(() => {
    setShowModal(false)
  }, [])

  const handleEditProfile = useCallback((data: OverviewManageProfile) => {
    onSave({ variables: { id: userId.current ?? '', personalInfo: data } }).then(() => {
      setToastStatus({
        showStatus: true,
        title: t('Success!'),
        statusMessage: t(`Your profile information has been saved.`),
        successStatus: true,
      })
      setShowModal(false)
      refetchRef.current && refetchRef.current()
    })
  }, [])

  const getProfileFormData = (data: UserResponse): OverviewManageProfile => {
    const { firstName, lastName, title, streetAddress, streetAddress2, city, state, zip, country, cell, fax, phone, timezone, extUserId } = data

    return {
      firstName,
      lastName,
      jobTitle: title,
      phoneNumber: phone,
      mobileNumber: cell,
      faxNumber: fax,
      street: streetAddress,
      streetLine2: streetAddress2,
      city,
      state,
      zipCode: zip,
      country,
      timeZone: timezone,
      extUserId,
    }
  }

  const onEditProfile = useCallback(
    (userData: UserResponse, refetch: () => Promise<ApolloQueryResult<GetAllMarketingUsersQuery | GetAllSalesUsersQuery>>) => {
      setUserData(getProfileFormData(userData))
      userId.current = userData.id
      setShowModal(true)
      refetchRef.current = refetch
    },
    []
  )

  return { userData, onClose, showModal, handleEditProfile, onEditProfile }
}

export default useEditProfile
