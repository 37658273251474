import { gql } from '@apollo/client'

export default gql`
  query getOptInStatusByAccountId(
    $accountId: Long!
    $sortDirection: Direction
    $sortColumn: OptInStatusSortColumns
    $searchTerm: String
    $size: Int!
    $page: Int!
    $optInState: OptInState!
  ) {
    getOptInStatusByAccountId(
      accountId: $accountId
      sortDirection: $sortDirection
      sortColumn: $sortColumn
      searchTerm: $searchTerm
      size: $size
      page: $page
      optInState: $optInState
    ) {
      lastContactAddedDate
      optInStatus {
        contactName
        listName
        optInDate
        optInSource
        phoneNumber
      }
      totalContacts
    }
  }
`
