import React, { FC, useContext } from 'react'

import classNames from 'classnames'

import ActivityGaugeChart from '@components/ActivityGaugeChart/ActivityGaugeChart'
import DividedContentDataCard from '@components/DividedContentDataCard/DividedContentDataCard'
import TextLink from '@components/TextLink/TextLink'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import { ChartFieldData } from '@const/Chart.constants'
import { legacyActonContext, useTranslation } from '@const/globals'
import { ListMaintenanceProgramsContext } from '@src/pages/listingPages/ListMaintenancePrograms/context/ListMaintenancePrograms.context'

import './AffectedContactsDataCard.css'

interface AffectedContactsDataCardProps {
  className?: string
  dataTest?: string
}

const rootClass = 'affected-contacts-data-card'

const AffectedContactsDataCard: FC<AffectedContactsDataCardProps> = (props: AffectedContactsDataCardProps) => {
  const { dataTest = rootClass, className = '' } = props

  const {
    values: { programDetails },
  } = useContext(ListMaintenanceProgramsContext)

  const { t } = useTranslation()

  const { doneCount = 0, sourceList = [] } = { ...programDetails }
  const source = sourceList.length > 0 ? sourceList[0] : undefined
  const sourceCount = source?.count ?? 0
  const cappedDoneCount = doneCount > sourceCount ? sourceCount : doneCount

  const openSource = (id: string) => {
    window.open(`${legacyActonContext}/lists/viewList.jsp?id=${id}`, 'previewSource', 'width=1000,height=600')
  }

  const renderContactsAffected = () => {
    if (source) {
      return (
        <div className={`${rootClass}__contacts-affected`}>
          <Typography
            text={'{{cappedDoneCount}}<light>/{{sourceCount}}</light>'}
            values={{ cappedDoneCount, sourceCount }}
            type={TextType.DATA_CARD_TEXT}
            tagProps={{ light: { type: TextType.BODY_TEXT_LARGE_LIGHT } }}
            inline
          />
          <Tooltip
            className={`${rootClass}__contacts-affected-source`}
            trigger={
              <Typography
                className={`ellip`}
                text={`in {{source.name}}`}
                values={{ source }}
                type={TextType.BODY_TEXT_SMALL}
                tagComponents={{
                  TextLink: <TextLink hideIcon onClick={() => openSource(source.id)} />,
                }}
                lineHeight={LineHeight.MEDIUM_LARGE}
              />
            }
          >
            {source.name}
          </Tooltip>
        </div>
      )
    }
  }

  const renderChart = () => {
    if (source) {
      const percentage = source.count ? parseFloat(((cappedDoneCount / source.count) * 100).toFixed(2)) : 0
      const chartFields = [
        {
          name: t('Affected contacts'),
          data: [
            {
              radius: '140%',
              color: '#00BABE',
              y: percentage,
              innerRadius: '90%',
              dataLabels: { shadow: true },
            },
          ] as ChartFieldData[],
        },
      ]
      return (
        <ActivityGaugeChart
          actualValue={cappedDoneCount}
          total={source.count ?? cappedDoneCount}
          showDate={false}
          fields={chartFields}
          backgroundColor={'rgba(0, 186, 190, 0.2)'}
        />
      )
    }
  }

  return (
    <DividedContentDataCard
      className={classNames(rootClass, className)}
      dataTest={dataTest}
      headerText={t('Affected Contacts')}
      leftContent={renderContactsAffected()}
      rightContent={renderChart()}
    />
  )
}

export default AffectedContactsDataCard
