import { useContext } from 'react'

import { useApolloClient } from '@apollo/client'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import deleteFolder from '@graphql/microservices/categorization/deleteFolder'
import getFolders from '@graphql/microservices/categorization/getFolders'
import renameFolder from '@graphql/microservices/categorization/renameFolder'
import setFolder from '@graphql/microservices/categorization/setFolder'
import updateSubTypeByFolderIds from '@graphql/microservices/categorization/updateSubTypeByFolderIds'
import getLandingPageFolders from '@graphql/queries/getLandingPageFolders'
import {
  DeleteFolderMutation,
  DeleteFolderMutationVariables,
  FolderDto,
  FolderDtoInput,
  GetFoldersQuery,
  GetFoldersQueryVariables,
  RenameFolderMutation,
  RenameFolderMutationVariables,
  SetFolderMutation,
  SetFolderMutationVariables,
  UpdateSubTypeByFolderIdsMutation,
  UpdateSubTypeByFolderIdsMutationVariables,
} from '@graphql/types/microservice/categorization-types'
import { GetLandingPageFoldersQuery, GetLandingPageFoldersQueryVariables } from '@graphql/types/query-types'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

import { ListingPageCommonContext } from '../Context/ListingPageCommon.context'

export interface GetFolderRequests {
  getAllFoldersRequest: (type: string, subTypes?: string[], checkEnableLandingPageFlag?: boolean) => FetchPromise<GetFoldersQuery>
  saveFolderRequest: (type: string, folder: FolderDto) => FetchPromise<SetFolderMutation>
  updateSubTypeByFolderIdsRequest: (variables: UpdateSubTypeByFolderIdsMutationVariables) => FetchPromise<UpdateSubTypeByFolderIdsMutation>
  renameFolderRequest: (type: string, folder: FolderDto) => FetchPromise<RenameFolderMutation>
  deleteFolderRequest: (type: string, folder: FolderData) => FetchPromise<DeleteFolderMutation>
}

export const useFolderRequests = (folderSubTypes: string[] = []): GetFolderRequests => {
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })
  const apolloClient = useApolloClient()
  const { enableLandingPageSectionReact } = useAccountSettings()
  const {
    values: { selectedCustomSource },
  } = useContext(ListingPageCommonContext)

  const getAllFoldersRequest = (type: string, subTypes?: string[], checkEnableLandingPageFlag?: boolean) => {
    if (checkEnableLandingPageFlag && type === ItemType.LANDING_PAGE && !enableLandingPageSectionReact) {
      // This is temporary solution to get LandingPages from Classic.
      // This will be rollback in sync with backEnd
      return apolloClient
        .query<GetLandingPageFoldersQuery, GetLandingPageFoldersQueryVariables>({
          query: getLandingPageFolders,
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
        })
        .then(({ data: { getLandingPageFolders, ...restData }, ...rest }) => ({ ...rest, data: { ...restData, getFolders: getLandingPageFolders } }))
    } else {
      return client.query<GetFoldersQuery, GetFoldersQueryVariables>({
        query: getFolders,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        variables: {
          subTypes,
          type,
          folderSubTypes,
        },
      })
    }
  }

  const saveFolderRequest = (type: string, folder: FolderDto) =>
    client.mutate<SetFolderMutation, SetFolderMutationVariables>({
      mutation: setFolder,
      errorPolicy: 'all',
      variables: {
        type,
        folder: folder as FolderDtoInput,
      },
    })

  const renameFolderRequest = (type: string, folder: FolderDto) =>
    client.mutate<RenameFolderMutation, RenameFolderMutationVariables>({
      mutation: renameFolder,
      errorPolicy: 'all',
      variables: {
        type,
        newName: folder.name,
        folderId: folder.id,
      },
    })

  const deleteFolderRequest = (type: string, folder: FolderData) =>
    client.mutate<DeleteFolderMutation, DeleteFolderMutationVariables>({
      mutation: deleteFolder,
      errorPolicy: 'all',
      variables: {
        type,
        folderId: folder.id,
      },
    })

  const updateSubTypeByFolderIdsRequest = (variables: UpdateSubTypeByFolderIdsMutationVariables) =>
    client.mutate<UpdateSubTypeByFolderIdsMutation, UpdateSubTypeByFolderIdsMutationVariables>({
      mutation: updateSubTypeByFolderIds,
      errorPolicy: 'all',
      variables,
    })

  return <GetFolderRequests>{
    getAllFoldersRequest: selectedCustomSource?.customRequestGeneral?.getAllFoldersRequest ?? getAllFoldersRequest,
    saveFolderRequest,
    renameFolderRequest,
    deleteFolderRequest,
    updateSubTypeByFolderIdsRequest,
  }
}
