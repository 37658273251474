import React, { FC } from 'react'

import { TableV2 } from '@components/TableV2/TableV2'
import { OptInStatus } from '@graphql/types/microservice/sms-management-types'
import { SortByState } from '@utils/sms.utils'

import { optInsTableColumns } from './OptInView.utils'

interface OptInsTableProps {
  totalCount: number
  pageSize: number | 10
  pageIndex: number | 0
  hidePagination?: boolean
  entries: OptInStatus[]
  loading: boolean
  emptyText: string
  className?: string
  fetchData: (pageIndex: number, pageSize: number) => void
  sortBy: SortByState[]
  onChangeSort: (sortBy: Array<any>) => void
}

const OptInsTable: FC<OptInsTableProps> = (props: OptInsTableProps) => {
  const { loading, entries, totalCount, emptyText, pageSize, pageIndex, hidePagination, fetchData, sortBy, onChangeSort } = props
  const pageCount = Math.ceil(totalCount / pageSize)

  return (
    <TableV2
      loading={loading}
      data={entries}
      columns={optInsTableColumns}
      enablePaginate={!hidePagination}
      manualSorting={true}
      enableOuterLoader={true}
      styles={{ maxWidth: 885 }}
      paginationState={{
        pageSize,
        pageIndex,
        controlledPageCount: pageCount,
        dropdownInsideModal: true,
      }}
      sortingBy={sortBy}
      emptyState={{
        text: emptyText,
      }}
      fetchData={fetchData}
      onSort={onChangeSort}
    />
  )
}

export default OptInsTable
