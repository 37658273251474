import { TFunction } from 'i18next'

import {
  AssetPickerTableType,
  renderDefaultNameColumn,
  renderDefaultRelativeDateColumn,
  renderDefaultSubmitsColumn,
} from '@complex/AssetPickerModal/Components/Table/AssetPickerTable.utils'
import { ItemDtoRow } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'

import { renderEmailNameCell } from '../utils/EmailPicker.tables.utils'

const rootClass = 'email-picker-modal'

const mediumColumnSize = { maxSize: 190, minSize: 190 }

export const renderScheduledTableColumns = (t: TFunction): ColumnDefWithAdditionalProps<ItemDtoRow, any>[] => [
  renderDefaultNameColumn(t('Email Title'), '', rootClass, (row, search, rootClass) => renderEmailNameCell(row, search, rootClass, t, false)),
  { ...renderDefaultSubmitsColumn(AssetPickerTableType.DEFAULT, t('Scheduled by'), 'scheduledBy'), ...mediumColumnSize },
  {
    ...renderDefaultRelativeDateColumn(AssetPickerTableType.DEFAULT, t('Send Date'), 'sentTime'),
    ...mediumColumnSize,
  },
]

export const renderScheduledSearchTableColumns = (
  search: string,
  _folders: FolderData[],
  t: TFunction
): ColumnDefWithAdditionalProps<ItemDtoRow, any>[] => [
  {
    ...renderDefaultNameColumn(t('Email Title'), search, rootClass, (row, search, rootClass) =>
      renderEmailNameCell(row, search, rootClass, t, false)
    ),
  },
  {
    ...renderDefaultSubmitsColumn(AssetPickerTableType.DEFAULT, t('Scheduled by'), 'scheduledBy'),
    cell: (cell) => {
      return search ? renderBoldTextOnMatch(cell.getValue(), search) : cell.getValue()
    },
    ...mediumColumnSize,
  },
  {
    ...renderDefaultRelativeDateColumn(AssetPickerTableType.SEARCH, t('Send Date'), 'sentTime'),
    ...mediumColumnSize,
  },
]
