import React, { FC, useContext, useEffect, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Input from '@components/Input/Input'
import InputWithStatus from '@components/InputWithStatus/InputWithStatus'
import LabelWithSvgTooltip from '@components/LabelWithTooltipIcon/LabelWithSvgTooltip'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { FormManagerContext, FormManagerEmbed } from '@src/pages/Content/Forms/FormManager/context/FormManager.context'
import { checkURLValidity } from '@utils/formUtils'

import './CreateEditEmbedCodeModal.css'

interface CreateEditEmbedCodeModalProps {
  isOpen: boolean
  isCreate: boolean
  onClose: () => void
  onAction: (name: string, externalUrl: string, id?: string) => void
  className?: string
  dataTest?: string
}

interface CreateEditEmbedCodeModalState {
  name: string
  externalUrl: string
  canSubmit: boolean
}

const defaultState: CreateEditEmbedCodeModalState = {
  name: '',
  externalUrl: '',
  canSubmit: false,
}

const rootClass = 'create-edit-embed-code-modal'

const getActiveEmbedData = (embeds: FormManagerEmbed[], currentId: string) => {
  const active = embeds.find((embed) => embed.id === currentId)
  return active ? { name: active.name, externalUrl: active.externalURL, canSubmit: false } : defaultState
}

const CreateEditEmbedCodeModal: FC<CreateEditEmbedCodeModalProps> = (props: CreateEditEmbedCodeModalProps) => {
  const { isCreate, dataTest = rootClass, className = '', onAction, onClose, isOpen } = props
  const { t } = useTranslation()

  const {
    values: { embeds, row },
  } = useContext(FormManagerContext)

  const currentEmbedData = row ? getActiveEmbedData(embeds, row.id) : defaultState

  const [state, setState] = useState<CreateEditEmbedCodeModalState>({ ...currentEmbedData })

  useEffect(() => {
    const eitherBlanks = state.name === '' || state.externalUrl === ''
    const twoUnchanged = state.name === currentEmbedData.name && state.externalUrl === currentEmbedData.externalUrl
    const externalUrlValid = !checkURLValidity(state.externalUrl)
    if (state.canSubmit && (eitherBlanks || twoUnchanged || !externalUrlValid)) {
      setState({ ...state, canSubmit: false })
    }
    if (!state.canSubmit && externalUrlValid && !eitherBlanks && !twoUnchanged) {
      setState({ ...state, canSubmit: true })
    }
  }, [state.name, state.externalUrl])

  const handleAction = () => {
    onAction(state.name, state.externalUrl, !isCreate && row ? row.id : undefined)
    onClose()
  }

  const onNameChange = (name: string) => setState({ ...state, name })

  const onExternalUrlChange = (externalUrl: string) => {
    setState({ ...state, externalUrl })
  }

  const header = (
    <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__header`}>
      {t('FormManager.CreateEditEmbedCodeModal.Header', { action: isCreate ? 'Create' : 'Edit' })}
    </ModalHeader>
  )

  const externalUrlLabel = (
    <Typography text={t('External URL')} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.MEDIUM} className={`${rootClass}__label-external-typo`} />
  )

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
      <ModalBody className={`${rootClass}__body`}>
        <Typography text={t('FormManager.CreateEditEmbedCodeModal.Info')} type={TextType.BODY_TEXT_LIGHT} className={`${rootClass}__info`} />
        <Input
          required
          value={state.name}
          label={t('Name')}
          placeholder={t('e.g. Google AdWords Campaign')}
          onChange={({ target: { value } }) => onNameChange(value)}
          dataTest={`${dataTest}-input-name`}
        />
        <div className={`${rootClass}__url`}>
          <LabelWithSvgTooltip
            required
            alignOffset={-6}
            position="right"
            label={externalUrlLabel}
            tooltipContent={t('FormManager.CreateEditEmbedCodeModal.ExternalUrlTooltip')}
            className={`${rootClass}__label-external`}
          />
          <InputWithStatus
            value={state.externalUrl}
            placeholder={'https://website.com'}
            validityFunctions={[checkURLValidity]}
            tooltipErrorMessages={{
              invalidUrl: 'Provide valid URL',
            }}
            showStatus={!!state.externalUrl}
            onChange={({ target: { value } }) => onExternalUrlChange(value)}
            dataTest={`${dataTest}-input-external-url`}
            fullWidth
          />
        </div>
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form} className={`${rootClass}__footer`}>
        <Button buttonType={ButtonType.TERTIARY} onClick={onClose} dataTest={`${dataTest}-button-tertiary`}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} disabled={!state.canSubmit} onClick={handleAction} dataTest={`${dataTest}-button-primary`}>
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CreateEditEmbedCodeModal
