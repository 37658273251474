import React from 'react'

import { FilterCounts, RenderCustomFiltersParams } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { ListingPageSession } from '@complex/ListingPage/Utils/ListingPage.utils'
import Checkbox from '@components/Checkbox'
import CheckboxGroup from '@components/CheckboxGroup/CheckboxGroup'
import { CollapsibleMenuItemWithHeaderData } from '@components/CollapsibleMenu/CollapsibleMenu'
import { SvgNames } from '@components/Svg'
import { landingPageTemplateSubTypes } from '@utils/landingPages'
import { getItem } from '@utils/sessionStorage'

const getMoreFilters = (filterCounts: FilterCounts) => {
  return landingPageTemplateSubTypes
    .filter((subType) => {
      return (filterCounts[subType.name] ?? 0) > 0
    })
    .sort((a, b) => (a.name > b.name ? 1 : -1))
}

export const renderCustomFilters = ({
  activeSubTypes,
  menuActions,
  filterCounts,
  renderCustomFilterWithCount,
}: RenderCustomFiltersParams): CollapsibleMenuItemWithHeaderData[] => {
  const moreFilters = getMoreFilters(filterCounts)
  return [
    ...(!moreFilters.length
      ? []
      : [
          {
            className: 'filter-container',
            header: 'More Filters',
            isOpen: getItem(ListingPageSession.CUSTOM_MENU_SECTION_CLOSED) !== 'true',
            icon: SvgNames.toggles,
            dataTest: 'menu-items',
            content: (
              <CheckboxGroup className={`listing-page__custom-filters`} verticalLayout>
                {moreFilters.map((filter) =>
                  renderCustomFilterWithCount(
                    <Checkbox
                      label={filter.label}
                      checked={activeSubTypes.includes(filter.name)}
                      onChange={() => menuActions.clickSubType(filter.name)}
                      dataTest={`list-page-container-section-filter`}
                      key={filter.name}
                    />,
                    filterCounts[filter.name]
                  )
                )}
              </CheckboxGroup>
            ),
          },
        ]),
  ]
}
