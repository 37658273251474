import React, { FC, Fragment, useContext, useEffect, useMemo } from 'react'

import classNames from 'classnames'

import Typography, { LineHeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { Program, ProgramRecordSetStep } from '@graphql/types/query-types'
import { SEGMENT_TYPES } from '@src/pages/programs/edit/components/ProgramFlow/components/ConditionalSelect/segmentOptions'
import { FieldSetOptions } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditFieldSetStep/EditFieldSetStep'
import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'
import ChangeFieldValueSourceUpgrade from '@src/pages/programs/upgradeManager/components/steps/ChangeFieldValueUpgradeStep/components/ChangeFieldValueSourceUpgrade/ChangeFieldValueSourceUpgrade'
import { Condition } from '@src/pages/programs/upgradeManager/components/steps/ChangeFieldValueUpgradeStep/components/Condition/Condition'
import { FieldReplacement } from '@src/pages/programs/upgradeManager/components/steps/ChangeFieldValueUpgradeStep/components/FieldReplacement/FieldReplacement'
import { ConditionType } from '@src/pages/programs/upgradeManager/components/steps/Steps.utils'
import {
  UpgradedProgramRecordSetStep,
  UpgradedStepCondition,
  UpgradedStepOperation,
  UpgradeManagerContext,
} from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'

import './ChangeFieldValueUpgradeStep.css'

const rootClass = 'change-field-value-upgrade-step'
const upgradeManagerClass = 'upgrade-manager'

export const hasSourceList = (condition: UpgradedStepCondition, program: Program) => {
  return program.localSegmentDetails.every(
    (details) => details.id !== condition.srcId || [SEGMENT_TYPES.IN, SEGMENT_TYPES.NOT_IN].includes(details.name as SEGMENT_TYPES)
  )
}

export const ChangeFieldValueUpgradeStep: FC = () => {
  const {
    values: { allContactsFieldsOptions, currentStep, upgradedCurrentStep },
    update,
  } = useContext(UpgradeManagerContext)

  const {
    values: { program },
  } = useContext(ProgramManagerContext)

  const { t } = useTranslation()
  const step = currentStep as ProgramRecordSetStep

  const { conditions, operations } = step
  const upgradedChangeFieldValueStep = upgradedCurrentStep as UpgradedProgramRecordSetStep

  const getMatchingOperationFields = (operations: UpgradedStepOperation[]) => {
    return operations.map((operation) => {
      const defaultMatchingField = allContactsFieldsOptions?.find((option) => option.value === operation.fieldName)
      if (defaultMatchingField && !operation.newField) {
        return {
          ...operation,
          newField: {
            name: defaultMatchingField.value,
            isNew: false,
          },
        }
      } else {
        return operation
      }
    })
  }

  useEffect(() => {
    if (
      (upgradedChangeFieldValueStep?.operations.length === 0 || upgradedChangeFieldValueStep?.operations.some(({ operationId }) => !operationId)) &&
      (upgradedChangeFieldValueStep.conditions.length === 0 || upgradedChangeFieldValueStep?.conditions.some(({ conditionId }) => !conditionId)) &&
      !upgradedChangeFieldValueStep?.removed &&
      !!currentStep
    ) {
      let upgradedOperations: UpgradedStepOperation[] = []
      let upgradedConditions: UpgradedStepCondition[] = []
      if (!!conditions && conditions?.length > 0) {
        upgradedConditions = conditions?.map((condition, index) => {
          const operations =
            condition?.operations?.map((operation, operationIndex) => ({
              ...operation,
              operationId: `condition-${index}-operation-${operationIndex}`,
            })) ?? []
          return {
            ...condition,
            conditionId: `${step.stepId}-condition-${index}`,
            operations: getMatchingOperationFields(operations),
          }
        })
      }
      if (!!operations && operations?.length > 0) {
        upgradedOperations = operations?.map((operation, operationIndex) => ({ ...operation, operationId: `operation-${operationIndex}` })) ?? []
      }
      update({
        upgradedCurrentStep: {
          ...currentStep,
          conditions: upgradedConditions,
          operations: getMatchingOperationFields(upgradedOperations),
        } as UpgradedProgramRecordSetStep,
      })
    }
  }, [conditions, operations])

  const filteredOperations = useMemo(
    () =>
      upgradedChangeFieldValueStep.operations.filter(
        (operation) => operation.fieldName !== FieldSetOptions.NOOP && operation.rule !== FieldSetOptions.NOOP
      ),
    [upgradedChangeFieldValueStep.operations]
  )

  return (
    <div className={classNames(rootClass, `${upgradeManagerClass}__step`)}>
      {upgradedChangeFieldValueStep?.conditions?.map((condition, index) => {
        const isUsingList = hasSourceList(condition, program)
        const originalCondition = step.conditions?.[index]
        const conditionSource = isUsingList ? program.sources.find(({ id }) => id === originalCondition?.srcId) : undefined
        if (isUsingList && conditionSource) {
          return (
            <Fragment key={condition.conditionId}>
              <ChangeFieldValueSourceUpgrade condition={condition} source={conditionSource} />
              <Condition condition={condition} type={index === 0 ? ConditionType.IF : ConditionType.ELSE_IF} />
            </Fragment>
          )
        } else {
          return <Condition key={condition.conditionId} condition={condition} type={index === 0 ? ConditionType.IF : ConditionType.ELSE_IF} />
        }
      })}
      {filteredOperations.length > 0 && (
        <div key={`${rootClass}-operations-conditions`} className={'condition'}>
          {upgradedChangeFieldValueStep?.conditions?.length !== 0 && (
            <Typography
              key={`${rootClass}-operations-otherwise`}
              className={`${rootClass}__common-condition`}
              text={t('Otherwise')}
              lineHeight={LineHeight.MEDIUM}
            />
          )}
          {filteredOperations.map((operation) => (
            <FieldReplacement key={operation.operationId} operation={operation} />
          ))}
        </div>
      )}
    </div>
  )
}
