import React, { FC, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import classNames from 'classnames'

import { DateRangeValueType } from '@components/DateRangePicker/DateRangePicker'
import InfoAction, { InfoActionType } from '@components/InfoAction/InfoAction'
import { renderLoader } from '@components/Loader/Loader'
import PageContainer from '@components/PageContainer/PageContainer'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import useSticky from '@components/StickyContainer/useSticky'
import { SvgNames } from '@components/Svg'
import { rootContext, useTranslation } from '@const/globals'
import { CampaignSectionData, LeadsSectionData, OpportunitiesSectionData, WonOpportunities } from '@graphql/types/microservice/data-lake-query-types'
import { UserRevenueInput } from '@graphql/types/query-types'

import CampaignTable from './components/campaignTable/CampaignTable'
import DataCards from './components/dataCards/DataCards'
import NewLeads from './components/NewLeads/NewLeads'
import Opportunities from './components/Opportunities/Opportunities'
import RevenueImpactHeader, { RevenueImpactHeaderProps } from './components/revenueImpactHeader/RevenueImpactHeader'

import './RevenueImpactNew.css'

export interface RevenueImpactProps {
  loading: boolean
  preservedData: UserRevenueInput
  handlePreserveDataChanges: (obj: Partial<UserRevenueInput>) => void
  range: DateRangeValueType
  headerProps: Omit<RevenueImpactHeaderProps, 'isSticky'>
  headerCardsData: WonOpportunities
  newLeadsData: LeadsSectionData
  opportunitiesData: OpportunitiesSectionData
  tableData: CampaignSectionData
  className?: string
  dataTest?: string
}

const rootClass = 'revenue-impact'

const RevenueImpactNew: FC<RevenueImpactProps> = (props: RevenueImpactProps) => {
  const {
    loading,
    preservedData,
    handlePreserveDataChanges,
    range,
    headerProps,
    headerCardsData,
    newLeadsData,
    opportunitiesData,
    tableData,
    className = '',
    dataTest = rootClass,
  } = props
  const { t } = useTranslation()
  const history = useHistory()
  const { scrollingAreaRef, isSticky } = useSticky()

  const updateCRMFields = useCallback<() => void>(() => history.push(`${rootContext}/datamanagement/report-mapping`), [])

  return (
    <PageContainer className={classNames(rootClass, className)} data-test={dataTest} register={scrollingAreaRef}>
      {loading && renderLoader('loader--white-background')}
      <PositionContainer>
        <RevenueImpactHeader isSticky={isSticky} {...headerProps} />
        <DataCards data={headerCardsData} range={range} loading={loading} />
        <InfoAction
          svgName={SvgNames.lightBulb}
          message={t('Use custom data mapping for your CRM fields to get the most accurate revenue report data.')}
          secondaryButton={{
            label: t('Update Custom Fields'),
            onClick: updateCRMFields,
          }}
          className={`${rootClass}__status-section`}
          type={InfoActionType.NORMAL}
        />
        <NewLeads
          loading={loading}
          data={newLeadsData}
          range={range}
          isToggleOn={preservedData.newLeadShowOther}
          onPreserveDataChange={handlePreserveDataChanges}
        />
        <Opportunities
          loading={loading}
          data={opportunitiesData}
          range={range}
          isToggleOn={preservedData.opportunityShowOther}
          onPreserveDataChange={handlePreserveDataChanges}
        />
        <CampaignTable loading={loading} data={tableData} onUpdateCRMFields={updateCRMFields} />
      </PositionContainer>
    </PageContainer>
  )
}

export default RevenueImpactNew
