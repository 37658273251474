import { Row } from 'react-table'

import { TableColumn } from '@components/Table/Table'
import { RevenueImpactContainerState } from '@src/pages/reports/revenueImpact/RevenueImpactContainer'

export const BASE_CHART_HEIGHT = 270
export const LEGEND_HEIGHT_UNIT = 42
export const QUARTER_DATE_RANGE = Array(5).fill('Quarter')
export const MONTH_DATE_RANGE = Array(13).fill('Month')

export enum DateIncrement {
  NEXT_QUARTER = 3,
  PREV_QUARTER = -3,
  NEXT_MONTH = 1,
  PREV_MONTH = -1,
}

export enum StageTypeLabel {
  OPEN = 'OPEN',
  CLOSED_WON = 'CLOSED_WON',
  CLOSED_LOST = 'CLOSED_LOST',
}

export interface ColumnFields {
  campaigns: string
  opportunities: string
  won: string
  percentage: string
  pipeline: string
  revenue: string
  average: string
  subRows?: Row[]
}

interface CampaignCommon {
  [key: string]: number | string | StageTypes[] | Campaigns[]
  actualCost: number
  opportunitiesAmount: number
  closedOpportunitiesAmount: number
  wonOpportunitiesAmount: number
  openOpportunitiesAmount: number
  closedOpportunities: number
  createdOpportunities: number
  lostOpportunities: number
  openOpportunities: number
  opportunities: number
  wonOpportunities: number
  type: string
}

export interface StageTypes {
  type: string
  stages: Stages[]
}

export interface Stages extends CampaignCommon {
  stageName: string
}

export interface Campaigns extends CampaignCommon {
  name: string
  stageTypes: StageTypes[]
}

export interface CampaignGroups extends CampaignCommon {
  campaigns: Campaigns[]
}

export interface LeadFields {
  count: number
  leadSource: string
}

export interface AccumulationFields {
  created: number[]
  won: number[]
  closed: number[]
  wonAmt: number[]
}

export const columns: TableColumn[] = [
  {
    Header: 'Campaigns',
    accessor: 'campaigns',
    align: 'left',
    maxWidth: 100,
  },
  {
    Header: 'Opportunities',
    accessor: 'opportunities',
    align: 'right',
    maxWidth: 120,
  },
  {
    Header: 'Won',
    accessor: 'won',
    align: 'right',
    maxWidth: 80,
  },
  {
    Header: '%',
    accessor: 'percentage',
    align: 'right',
    maxWidth: 85,
  },
  {
    Header: 'Pipeline',
    accessor: 'pipeline',
    align: 'right',
    maxWidth: 120,
  },
  {
    Header: 'Revenue',
    accessor: 'revenue',
    align: 'right',
    maxWidth: 125,
  },
  {
    Header: 'Average',
    accessor: 'average',
    align: 'right',
    maxWidth: 125,
  },
]

export const colorLabels = [
  {
    text: 'Open',
    className: 'color-label-open',
  },
  {
    text: 'Closed/Won',
    className: 'color-label-won',
  },
  {
    text: 'Closed/Lost',
    className: 'color-label-lost',
  },
]

export const defaultState: RevenueImpactContainerState = {
  columns: columns,
  opportunityTrends: {
    categories: [],
    fields: [],
  },
  opportunitiesNetChange: {
    categories: [],
    fields: [],
  },
  revenueBooked: {
    categories: [],
    fields: [],
  },
  outcomes: {
    categories: [],
    fields: [],
  },
  tableData: [],
  leadsConverted: {
    fields: [
      {
        name: '',
        data: [],
      },
    ],
  },
  leadsCreated: {
    fields: [
      {
        name: '',
        data: [],
      },
    ],
  },
  campaignGroups: [],
  startTime: '',
  startTimePresentation: '',
  curQuarterStart: '',
  curQuarterEnd: '',
  endTime: '',
  nextDisabled: true,
  prevDisabled: false,
  altViewOptions: [
    { label: 'Quarterly', value: 'quarterly', selected: true },
    { label: 'Monthly', value: 'monthly', selected: false },
  ],
  quarterSelected: true,
  dateRange: [''],
  fiscalCalendarIsUpdated: false,
  updatedDate: '',
  className: '',
}

export const outcomeFields = [
  {
    name: 'Opportunities',
    color: '#A084DD',
    data: [],
  },
  {
    name: 'Wins',
    color: '#7DE7C1',
    data: [],
  },
]

export const opportunityTrendsFields = [
  {
    name: 'Created',
    color: '#a084dd',
    data: [],
  },
  {
    name: 'Won',
    color: '#5ae3bb',
    data: [],
  },
]

export const opportunitiesNetChangeFields = [
  {
    name: 'Growth',
    data: [],
    color: '#a084dd',
    showInLegend: false,
    negativeColor: '#E4E6F2',
    states: {
      hover: {
        brightness: 0.05,
      },
    },
  },
]

export const revenueBookedFields = [
  {
    name: 'Revenue Booked',
    data: [],
    showInLegend: false,
    color: '#7de7c1',
  },
]

export const defaultReplacementText = {
  leadsConvertedText: 'New Opportunities Created',
  trendChartTitle: 'Opportunity Trends',
  netChangeColChartTitle: 'Net Change in # of Opportunities',
}
