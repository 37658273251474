import React, { FC, ReactNode, useCallback, useRef, useState } from 'react'

import { ConfigProvider, TimePicker } from 'antd'
import classNames from 'classnames'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import {
  TimePickerV2ErrorMessage,
  TimePickerV2Footer,
  TimePickerV2HelperMessage,
  TimePickerV2Label,
  TimePickerV2SuffixIcon,
} from '@components/TimePickerV2/components/TimePickerV2.components'
import { timePickerV2ThemeProps } from '@components/TimePickerV2/constants/TimePickerV2.constants'
import { useTranslation } from '@const/globals'

import './TimePickerV2.css'

export enum TimePickerV2Format {
  DEFAULT = 'h:mm:ss A',
  HH_MM = 'h:mm A',
}

type TimePickerStatus = 'warning' | 'error'

export interface TimePickerV2Props extends Pick<React.ComponentProps<typeof TimePicker>, 'hourStep' | 'minuteStep' | 'secondStep' | 'disabled'> {
  onSubmit: (time: Dayjs | null, dateString: string) => void
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  labelKey?: string
  required?: boolean
  tooltipContent?: string | ReactNode | ReactNode[]
  isParentModal?: boolean
  allowClear?: boolean
  helperMessage?: string | React.ReactNode
  errorMessage?: string | React.ReactNode
  inlineError?: boolean
  hideCancel?: boolean
  // defaultValue should correspond format
  format?: TimePickerV2Format
  status?: TimePickerStatus
  defaultValue?: string | number
  smallLabel?: boolean
  hideErrorIcon?: boolean
  className?: string
  dataTest?: string
}

const rootClass = 'time-picker-v2'

const TimePickerV2: FC<TimePickerV2Props> = ({
  onSubmit,
  onBlur,
  onFocus,
  labelKey,
  required,
  tooltipContent,
  helperMessage,
  errorMessage,
  hideCancel,
  format = TimePickerV2Format.DEFAULT,
  inlineError = false,
  hourStep = 1,
  status,
  minuteStep = 5,
  secondStep = 5,
  isParentModal = false,
  allowClear = true,
  defaultValue,
  smallLabel = false,
  disabled,
  hideErrorIcon,
  dataTest = rootClass,
  className,
}) => {
  const { t } = useTranslation()
  const timePickerRef = useRef<HTMLDivElement>(null)
  const panelRef = useRef<HTMLDivElement>(null)
  const [selectionChange, setSelectionChange] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const _defaultValue = defaultValue && dayjs(defaultValue, format).isValid() ? dayjs(defaultValue, format) : undefined

  const handleChangeSubmit = useCallback(
    (time: Dayjs | null, dateString: string) => {
      onSubmit(time, dateString)
    },
    [onSubmit]
  )

  const handleSelect = useCallback(() => setSelectionChange(true), [])

  const handleOpenChange = useCallback((open: boolean) => {
    setOpen(open)
    setSelectionChange(false)
  }, [])

  const handleSubmit = useCallback(() => {
    // It is nearly impossible to modify Antd "Ok" button and have "Cancel" button aside of it
    // Getting hidden "Ok" button, to simulate same behavior on "Apply" button click
    const antdOkBtn = panelRef.current?.getElementsByClassName('ant-btn-primary')[0]
    if (!!antdOkBtn && 'click' in antdOkBtn && typeof antdOkBtn.click === 'function') {
      antdOkBtn.click()
    }
  }, [])

  const handleCancel = useCallback(() => {
    // Simulating outside click to close popupContainer
    const mouseDownEvent = new MouseEvent('mousedown', { bubbles: true })
    timePickerRef.current?.dispatchEvent(mouseDownEvent)
  }, [])

  const renderFooter = useCallback(() => {
    const props = { hideCancel, handleSubmit, handleCancel, selectionChange, dataTest, rootClass }
    return <TimePickerV2Footer {...props} />
  }, [hideCancel, handleSubmit, handleCancel, selectionChange, dataTest])

  const panelRender = useCallback((panel: ReactNode) => <div ref={panelRef}>{panel}</div>, [])

  return (
    <ConfigProvider
      theme={{
        token: timePickerV2ThemeProps,
      }}
    >
      <div className={classNames(rootClass, className)} ref={timePickerRef} data-test={dataTest}>
        <TimePickerV2Label
          labelKey={labelKey}
          tooltipContent={tooltipContent}
          required={required}
          dataTest={dataTest}
          smallLabel={smallLabel}
          rootClass={rootClass}
          disabled={disabled}
        />
        <TimePicker
          renderExtraFooter={renderFooter}
          panelRender={panelRender}
          onChange={handleChangeSubmit}
          onSelect={handleSelect}
          onOpenChange={handleOpenChange}
          suffixIcon={
            <TimePickerV2SuffixIcon
              open={open}
              disabled={disabled}
              haveError={!!errorMessage}
              dataTest={dataTest}
              rootClass={rootClass}
              errorMessage={!!errorMessage && typeof errorMessage === 'string' ? errorMessage : undefined}
            />
          }
          clearIcon={
            <Svg
              className={`${rootClass}__clear-icon`}
              name={SvgNames.clearIndicator}
              fill={SvgColor.TAB_GRAY}
              hoverFill={SvgColor.TEXT_GRAY}
              type={SvgType.VERY_LARGE_ICON}
            />
          }
          onBlur={onBlur}
          onFocus={onFocus}
          allowClear={allowClear}
          showNow={false}
          use12Hours
          placeholder={t('Select time')}
          format={format}
          hourStep={hourStep}
          minuteStep={minuteStep}
          secondStep={secondStep}
          defaultValue={_defaultValue}
          status={status && !open ? 'error' : undefined}
          disabled={disabled}
          className={classNames(`${rootClass}-picker`, {
            ['ant-picker-focused__popup-open']: open,
          })}
          popupClassName={classNames(`${rootClass}-panel`, {
            [`${rootClass}__parent-modal`]: isParentModal,
          })}
          data-test={`${rootClass}-input`}
        />
        {!inlineError && (
          <TimePickerV2ErrorMessage errorMessage={errorMessage} dataTest={dataTest} hideErrorIcon={hideErrorIcon} rootClass={className} />
        )}
        <TimePickerV2HelperMessage helperMessage={helperMessage} dataTest={dataTest} />
      </div>
    </ConfigProvider>
  )
}

export default TimePickerV2
