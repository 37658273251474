import React, { FC } from 'react'

import classNames from 'classnames'

import { DropDownActionsType } from '@components/DropDownActions/DropDownActions'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { toTrainCase } from '@utils/strings'

export interface DropDownActionItemProps {
  className?: string
  closeDropDown: () => void
  icon?: any
  isDisabled: boolean
  text: string
  title?: string
  typographyType: TextType
  type?: DropDownActionsType
  onClick: (row?: any) => void
  row?: any
  hasEllipsis?: boolean
}

const DropDownActionItem: FC<DropDownActionItemProps> = (props: DropDownActionItemProps) => {
  const { className, closeDropDown, icon, isDisabled, text, typographyType, type, title, onClick, row, hasEllipsis = false } = props
  const { t } = useTranslation()
  const isSmall = type === DropDownActionsType.SMALL
  const dataTest = `drop-down-action-item-${toTrainCase(text)}`

  const textProps = {
    className: classNames({ [`${className}-small-item`]: isSmall }),
    text: t(text),
    type: typographyType,
    lineHeight: title ? LineHeight.MEDIUM_SMALL : undefined,
  }

  const titleProps = {
    text: t(title),
    type: TextType.BODY_TEXT,
    weight: TextWeight.MEDIUM,
    lineHeight: LineHeight.MEDIUM,
  }

  const textTypography = hasEllipsis ? <TextWithTooltipOnEllip typographyProps={textProps} /> : <Typography {...textProps} />

  return (
    <div
      role={'button'}
      tabIndex={0}
      className={classNames(className, { [`${className}-small`]: isSmall })}
      onKeyDown={(keyDownEvent) => {
        if (!isDisabled && keyDownEvent.key === ' ') {
          closeDropDown()
          onClick(row)
        }
        keyDownEvent.stopPropagation()
      }}
      onClick={(e) => {
        if (!isDisabled) {
          setTimeout(() => {
            onClick(row)
          })
          closeDropDown()
        }
        e.stopPropagation()
      }}
      data-test={dataTest}
    >
      {!!icon && icon}
      {title && (
        <div className={`${className}__title-container`}>
          <Typography {...titleProps} />
          <div className={`${className}__text-with-title`}>{textTypography}</div>
        </div>
      )}
      {!title && textTypography}
    </div>
  )
}

export default DropDownActionItem
