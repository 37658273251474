import React, { FC } from 'react'

import classNames from 'classnames'

import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './SearchResultSummary.css'

type Props = {
  term: string
  count: number
  name: string
  onClear: () => void
  className?: string
  dataTest?: string
}

const rootClass = 'search-result-summary'

const SearchResultSummary: FC<Props> = (props: Props) => {
  const { term, count, name, onClear, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div>
        <Typography text={`"${term}"`} type={TextType.SECTION_HEADER} dataTest={`${dataTest}__term`} />
        <Typography text={`${count} ${name} ${t('found')}`} type={TextType.BODY_TEXT_GRAY_TINY} dataTest={`${dataTest}__result`} />
      </div>
      <div>
        <TextLink text={t('Clear')} onClick={onClear} dataTest={`${dataTest}__clear`} size={TextLinkSize.LARGE} hideIcon />
      </div>
    </div>
  )
}

export default SearchResultSummary
