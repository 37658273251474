import React, { FC } from 'react'

import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { ProgramStep } from '@graphql/types/query-types'
import ProgramStepIcon from '@src/pages/programs/edit/components/ProgramFlow/components/ProgramStepIcon'
import { UpgradedProgramStep } from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'
import { ProgramStepType } from '@utils/program/program.constants'

import './StepPill.css'

interface StepPillProps {
  step: ProgramStep | UpgradedProgramStep
}

const rootClass = 'step-pill'
export const StepPill: FC<StepPillProps> = ({ step }: StepPillProps) => {
  return (
    <div className={rootClass}>
      <ProgramStepIcon className={`${rootClass}__icon`} stepType={step?.stepType as ProgramStepType} />
      <div className={`${rootClass}__info`}>
        <Typography text={step?.letter} type={TextType.BODY_TEXT_SMALL_LIGHT} weight={TextWeight.MEDIUM} lineHeight={LineHeight.MEDIUM_SMALL} />
        <Typography text={step?.displayName} type={TextType.BODY_TEXT} weight={TextWeight.MEDIUM} lineHeight={LineHeight.MEDIUM} />
      </div>
    </div>
  )
}
