import React, { FC, useRef } from 'react'

import classNames from 'classnames'

import InputV2 from '@components/InputV2/InputV2'
import Typography, { TextType } from '@components/Typography/Typography'

import './NumberInput.css'

export interface NumberInputProps {
  className?: string
  dataTest?: string
  symbol?: string
  value?: number
  onChange: (value?: number) => void
  placeholder?: string
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const rootClass = 'number-input'
const MIN_WIDTH = '42px'

const NumberInput: FC<NumberInputProps> = (props: NumberInputProps) => {
  const { dataTest = rootClass, className = '', symbol, onChange, value, placeholder = '', onBlur, onFocus } = props
  const inputRef = useRef<HTMLInputElement>(null)

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = inputRef.current
    const newValue = event.target.value
    if (input) {
      if (newValue) {
        input.style.width = MIN_WIDTH
        input.style.width = `${input.scrollWidth + 1}px`
      } else {
        input.style.removeProperty('width')
      }
    }
    onChange(newValue ? parseFloat(newValue) : undefined)
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    /**
     * Since the "type:number" doesn't actually work on Firefox, we need to prevent the user from typing anything that is not a number
     * Allowing: Integers | Backspace | Tab | Delete | Left & Right arrow keys
     **/
    const regex = new RegExp(/(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight|ArrowUp|ArrowDown)/)

    return !event.key.match(regex) && event.preventDefault()
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <InputV2
        ref={inputRef}
        className={classNames(`${rootClass}__input-v2`, { [`${rootClass}__input-v2-symbol`]: symbol })}
        value={value?.toString()}
        onKeyDown={onKeyDown}
        onChange={handleOnChange}
        handleValueChangeFromOuter
        type={'number'}
        placeholder={placeholder}
        dataTest={`${dataTest}-input-v2`}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      {symbol && (
        <div className={`${rootClass}__symbol`} data-test={`${dataTest}-symbol`}>
          <Typography text={symbol} type={TextType.BODY_TEXT_SMALL} />
        </div>
      )}
    </div>
  )
}

export default NumberInput
