import React, { FC, useContext, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import classNames from 'classnames'

import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { InputDefinition } from '@graphql/types/microservice/webhooks-incoming-management-types'
import {
  getFieldsRelatedData,
  HMACAuthenticationField,
} from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/components/IncomingWebhookAuthentication/components/IncomingWebhookHMACAuthentication/IncomingWebhookHMACAuthentication.constants'
import IncomingWebhookInput, {
  InputField,
} from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/components/IncomingWebhookAuthentication/components/IncomingWebhookInput/IncomingWebhookInput'
import IncomingWebhookSelect, {
  SelectField,
} from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/components/IncomingWebhookAuthentication/components/IncomingWebhookSelect/IncomingWebhookSelect'
import { IncomingWebhooksContext, Source } from '@src/pages/Webhooks/IncomingWebhooks/IncomingWebhooksContext'

import './IncomingWebhookHMACAuthentication.css'

interface IncomingWebhookHMACAuthenticationProps {
  className?: string
  dataTest?: string
}

const rootClass = 'incoming-webhook-hmac-authentication'
const rootTranslation = 'Incoming.Webhooks.CreateModal.Authentication.HMAC'

const IncomingWebhookHMACAuthentication: FC<IncomingWebhookHMACAuthenticationProps> = (props: IncomingWebhookHMACAuthenticationProps) => {
  const { dataTest = rootClass, className = '' } = props
  const {
    values: { customWebhookSignatureOptions },
  } = useContext(IncomingWebhooksContext)
  const {
    control,
    getValues,
    formState: { errors },
    register,
  } = useFormContext<InputDefinition>()

  const source = getValues('source')

  const renderedOptions: { [key: string]: SelectV2SingleOption[] } = useMemo(
    () => ({
      [HMACAuthenticationField.ALGORITHM]: (customWebhookSignatureOptions?.hashAlgorithmOptions as SelectV2SingleOption[]) ?? [],
      [HMACAuthenticationField.ENCODING]: (customWebhookSignatureOptions?.encodingOptions as SelectV2SingleOption[]) ?? [],
    }),
    [customWebhookSignatureOptions]
  )
  const renderInputLabel = (field: InputField) => {
    const fieldRelatedData = getFieldsRelatedData(field as HMACAuthenticationField, errors)
    const inputProps = { ...fieldRelatedData, register, rootTranslation }

    return <IncomingWebhookInput {...inputProps} />
  }
  const renderSelect = (field: SelectField) => {
    const fieldRelatedData = getFieldsRelatedData(field as HMACAuthenticationField, errors)
    const options = renderedOptions[field as string]

    const selectProps = {
      ...fieldRelatedData,
      control,
      currentOption: options?.find(({ value }) => value === getValues(fieldRelatedData.name)),
      field,
      options,
      register,
      rootTranslation,
    }

    return <IncomingWebhookSelect {...selectProps} />
  }

  return (
    <div className={classNames(rootClass, className, { [`${rootClass}__calendly-input`]: source === Source.Calendly })} data-test={dataTest}>
      {source === Source.Custom && (
        <>
          {renderSelect(HMACAuthenticationField.ALGORITHM)}
          {renderSelect(HMACAuthenticationField.ENCODING)}
          {renderInputLabel(HMACAuthenticationField.SIGNATURE_HEADER)}
        </>
      )}
      {renderInputLabel(HMACAuthenticationField.SIGNING_SECRET)}
    </div>
  )
}

export default IncomingWebhookHMACAuthentication
