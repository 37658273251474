import React, { ComponentProps, FC, Fragment } from 'react'

import classNames from 'classnames'

import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import DynamicListRow from '@src/pages/datamanagement/components/CrmContacts/components/DynamicList/components/DynamicListRow/DynamicListRow'
import {
  ColumnType,
  DynamicListRowData,
  RowType,
} from '@src/pages/datamanagement/components/CrmContacts/components/DynamicList/utils/DynamicList.interfaces'

import './DynamicList.css'

type Props = Pick<ComponentProps<typeof DynamicListRow>, 'updateRow' | 'deleteRow' | 'extraInfo'> & {
  dataTest?: string
  mappings: DynamicListRowData[]
  mouseOverRow?: (rowData: DynamicListRowData) => void
  mouseLeaveRow?: () => void
  headerMap?: Map<string, string>
}

const rootClass = 'dynamic-list'

const DynamicList: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, mappings, updateRow, extraInfo, deleteRow, mouseOverRow, mouseLeaveRow, headerMap } = props

  const { t } = useTranslation()

  const renderHeader = () => {
    const mapping = mappings[0]
    if (mapping?.columns) {
      const header = mapping.columns.map((item, index) => {
        if (item.type !== ColumnType.ICON && item.type !== ColumnType.BLANK) {
          const isToggleOption = item.column === t('ALLOW PUSH')
          const isCRMColumn = item.column !== t('DATA TYPE') && item.column !== t('ACT-ON CONTACTS') && !isToggleOption
          return (
            <div
              data-test={`${dataTest}-header-item-${index}`}
              className={classNames(`${rootClass}__header-item`, {
                [`${rootClass}__header-item-crm`]: isCRMColumn,
                [`${rootClass}__header-item-toggle`]: isToggleOption,
              })}
              key={index}
            >
              <Typography
                type={TextType.FIELD_MAPPING_SUB_HEADER}
                text={headerMap && item.column && headerMap.get(item.column) ? headerMap.get(item.column)?.toUpperCase() : item.column?.toUpperCase()}
                weight={TextWeight.BOLD}
                lineHeight={LineHeight.SMALL}
              />
            </div>
          )
        }
      })

      return <div className={`${rootClass}__header`}>{header}</div>
    }
  }

  // walk the list and display stuff
  const mapping = mappings.map((dynamicListRow) => {
    if (dynamicListRow.type === RowType.TEMPORARY) {
      const dynamicListRowFirstCol = dynamicListRow.columns[0] as JSX.Element
      return <Fragment key={dynamicListRow.key}>{dynamicListRowFirstCol}</Fragment>
    } else {
      return (
        <div
          className={`${rootClass}__list-row`}
          key={dynamicListRow.key}
          onMouseEnter={() => mouseOverRow?.(dynamicListRow)}
          onMouseLeave={() => mouseLeaveRow?.()}
        >
          <DynamicListRow row={dynamicListRow} deleteRow={deleteRow} updateRow={updateRow} extraInfo={extraInfo} />
        </div>
      )
    }
  })

  return (
    <div className={`${rootClass}`} data-test={`${dataTest}`}>
      {renderHeader()}
      {mapping}
    </div>
  )
}

export default DynamicList
