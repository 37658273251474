import React, { useState, FC, useCallback, useRef, useEffect } from 'react'
import AceEditor from 'react-ace'

import classNames from 'classnames'

import { ExtendedItemDto } from '@complex/ListingPage/Context/ListingPageCommon.context'
import Accordion from '@components/Accordion/Accordion'
import FormsPickerModal from '@components/AssetPickers/FormsPickerModal/FormsPickerModal'
import AssetSelect from '@components/AssetSelect/AssetSelect'
import Button, { ButtonType } from '@components/Button'
import { ButtonWeight } from '@components/Button/Button'
import { Ace } from '@components/CodeEditor/Ace'
import { renderLoader } from '@components/Loader/Loader'
import Modal, { ModalBody } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import ModalFooterV2 from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import ModalHeaderV2 from '@components/Modal/components/ModalHeaderV2/ModalHeaderV2'
import StatusToast from '@components/StatusToast/StatusToast'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tab from '@components/TabsAO/TabAO'
import Tabs, { TabStyle } from '@components/TabsAO/TabsAO'
import Toggle from '@components/Toggle'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { EXTERNAL_WINDOW_PATH, rootContext, useTranslation } from '@const/globals'
import { IAddOnResponseHTMLExtended } from '@src/pages/EmailComposer/utils/BeeEditor.types'
import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'
import { useGetFormsListingPageRequests } from '@src/pages/listingPages/Forms/GraphQL/FormsListingPageRequests.graphQL'
import { StatusToastType } from '@utils/interface/StatusToast'
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '@utils/window'

import { wrapWithPointerEventsNone } from './FormBlock.utils'

import './FormBlockModal.css'

export interface FormCustomFields {
  formLoadJs: string
  formSubmitJs: string
  showPrefilled: boolean
  excludeCss: boolean
  formId: string
  name?: string
}
interface FormBlockModalProps {
  onClose: () => void
  onAction: (data: IAddOnResponseHTMLExtended) => void
  className?: string
  dataTest?: string
  customFieldValues: FormCustomFields
}

const rootClass = 'form-modal'

const CREATED_FORM_FROM_LP = 'createdFromLPForm'
const EDITED_FORM_FROM_LP = 'editedFromLPForm'

export enum LandingPageFormCodeTabs {
  FORM_LOAD_JS = 'formLoad',
  FORM_SUBMIT_JS = 'formSubmit',
}

const TAB_OPTIONS = [
  { index: LandingPageFormCodeTabs.FORM_LOAD_JS, label: 'Form load JS' },
  { index: LandingPageFormCodeTabs.FORM_SUBMIT_JS, label: 'Form submit JS' },
]

const FormBlockModal: FC<FormBlockModalProps> = ({ customFieldValues, dataTest = rootClass, className, onClose, onAction }) => {
  const {
    values: {
      isSaving,
      message: { id },
    },
  } = useComposerContext()
  const { getFormPreviewRequest } = useGetFormsListingPageRequests()
  const [showPrefilled, setShowPrefilled] = useState(customFieldValues?.showPrefilled ?? false)
  const [excludeCss, setExcludeCss] = useState(customFieldValues?.excludeCss ?? false)
  const [asset, setSelectedAsset] = useState(customFieldValues?.name ?? '')
  const [assetID, setSelectedAssetId] = useState(customFieldValues?.formId ?? '')
  const [isFormPickerOpen, setIsFormPickerOpen] = useState(false) // Controls FormsPickerModal visibility
  const [tab, setTab] = useState<LandingPageFormCodeTabs>(LandingPageFormCodeTabs.FORM_LOAD_JS)
  const [toast, setToast] = useState<StatusToastType | undefined>(undefined)
  const [formLoadJs, setFormLoadJs] = useState(customFieldValues?.formLoadJs ?? '')
  const [formSubmitJs, setFormSubmitJs] = useState(customFieldValues?.formSubmitJs ?? '')
  const [isDirty, setIsDirty] = useState(false)
  useEffect(() => {
    if (customFieldValues?.formId) {
      setSelectedAssetId(customFieldValues.formId)
      setSelectedAsset(customFieldValues.name || '')
    }
  }, [customFieldValues])
  useEffect(() => {
    setIsDirty(
      formLoadJs !== customFieldValues?.formLoadJs ||
        formSubmitJs !== customFieldValues?.formSubmitJs ||
        showPrefilled !== customFieldValues?.showPrefilled ||
        excludeCss !== customFieldValues?.excludeCss ||
        assetID !== customFieldValues?.formId
    )
  }, [formLoadJs, formSubmitJs, showPrefilled, excludeCss, assetID, customFieldValues])
  const editorLoadRef = useRef<AceEditor>(null)
  const editorSubmitRef = useRef<AceEditor>(null)
  const { t } = useTranslation()

  const handleShowPrefilledToggleChange = () => setShowPrefilled((prev) => !prev)
  const handleExcludeCssToggleChange = () => setExcludeCss((prev) => !prev)
  const handleTabChange = useCallback((index: string) => setTab(index as LandingPageFormCodeTabs), [])

  const handleCodeChange = (newValue: string, tab: LandingPageFormCodeTabs) => {
    if (tab === LandingPageFormCodeTabs.FORM_LOAD_JS) {
      setFormLoadJs(newValue)
    } else {
      setFormSubmitJs(newValue)
    }
  }
  const openFormPicker = () => setIsFormPickerOpen(true)
  const closeFormPicker = () => setIsFormPickerOpen(false)

  const onSelectForm = (items: ExtendedItemDto[]) => {
    if (items && items.length > 0 && items[0].name && items[0].externalId) {
      setSelectedAsset(items[0].name)
      setSelectedAssetId(items[0].externalId)
    }
    closeFormPicker()
  }

  const handleRedirect = () => {
    window.open(`${rootContext}/classic/if${EXTERNAL_WINDOW_PATH}/forms/blank`)
    setLocalStorageItem(CREATED_FORM_FROM_LP, id)
  }

  const handleInsertForm = async () => {
    const { data } = await getFormPreviewRequest({ formId: assetID })
    const previewHtml = data?.getFormPreview ?? ''
    const nonPointerHtml = wrapWithPointerEventsNone(previewHtml)
    if (data?.getFormPreview) {
      onAction({
        type: 'html',
        value: {
          html: nonPointerHtml,
          customFields: {
            showPrefilled: showPrefilled,
            excludeCss: excludeCss,
            formLoadJs: formLoadJs,
            formSubmitJs: formSubmitJs,
            formId: assetID,
            name: asset,
          },
        },
      })
    }
  }

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event && event.data) {
        if (event.data.formId && event.data.formTitle) {
          setSelectedAssetId(event.data.formId)
          setSelectedAsset(event.data.formTitle)
          setIsDirty(true)
          setToast({
            showStatus: true,
            statusMessage: t(`FormBlock.Toast.Success`, {
              formTitle: event.data.formTitle,
              context: getLocalStorageItem(CREATED_FORM_FROM_LP) ? 'created' : 'updated',
            }),
            successStatus: true,
          })
          removeLocalStorageItem(CREATED_FORM_FROM_LP)
          removeLocalStorageItem(EDITED_FORM_FROM_LP)
        }
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [t])

  const handleEditForm = () => {
    window.open(`${rootContext}/classic/if${EXTERNAL_WINDOW_PATH}/forms/${assetID}`)
    setLocalStorageItem(EDITED_FORM_FROM_LP, id)
  }

  if (isSaving) {
    return renderLoader('loader--white-background')
  }
  const aceEditorProps = {
    mode: 'javascript',
    theme: 'github',
    height: '504px',
    width: '100%',
    fontSize: 14,
    className: `${rootClass}__editor`,
  }

  return (
    <>
      <Modal
        isOpen
        className={classNames(rootClass, className)}
        dataTest={dataTest}
        header={<ModalHeaderV2 headerType={ModalHeaderType.Form} headerText={t('Manage form')} dataTest={`${dataTest}-header`} />}
      >
        {toast?.showStatus && (
          <StatusToast
            isSuccess={toast.successStatus}
            message={toast.statusMessage}
            closeStatus={() => {
              setToast({ ...toast, showStatus: false })
            }}
          />
        )}
        <ModalBody className={`${rootClass}__body`}>
          <div className="form-manage-block">
            <AssetSelect
              dataTest={`${dataTest}-asset-select`}
              selectedAssets={asset}
              replaceText={t('Select')}
              svgIcon={SvgNames.formSimple}
              labelProps={{
                label: (
                  <div className={`${rootClass}__select-label`}>
                    <Typography text={t('LandingPages.FormBlock.Select.Title')} weight={TextWeight.MEDIUM} />
                    <Typography text={t('(required)')} type={TextType.BODY_TEXT_LIGHT} />
                  </div>
                ),
              }}
              placeholder={t('Select existing form')}
              className={`${rootClass}__personalization`}
              onClick={() => (asset ? handleEditForm() : openFormPicker())}
              secondaryEditButton="Replace"
              secondaryText="Edit"
              onSecondaryEditButtonClick={openFormPicker}
            />
            <Button
              weight={ButtonWeight.MEDIUM}
              buttonType={ButtonType.FLOAT_TEAL}
              onClick={handleRedirect}
              dataTest={`${dataTest}-button-tertiary`}
              disabled={false}
              className={`${rootClass}__new-form-button`}
            >
              <Svg name={SvgNames.plus} type={SvgType.SMALLER_ICON} />
              {t('New form')}
            </Button>
          </div>

          <div className={`${rootClass}__toggle-container`}>
            <div className={`${rootClass}__toggle-container__text`}>
              <Typography
                className="toggle_title"
                type={TextType.BODY_TEXT}
                weight={TextWeight.BOLD}
                text={t('LandingPages.FormBlock.ShowPrefilled.Title')}
              />
              <Typography className="toggle_desc" text={t('LandingPages.FormBlock.ShowPrefilled.Description')} type={TextType.BODY_TEXT_LIGHT} />
            </div>
            <Toggle dataTest={`${dataTest}-show-prefilled-toggle`} isOn={showPrefilled} onToggle={handleShowPrefilledToggleChange} noLeftMargin />
          </div>
          <div className={`${rootClass}__toggle-container`}>
            <div className={`${rootClass}__toggle-container__text`}>
              <Typography
                className="toggle_title"
                type={TextType.BODY_TEXT}
                weight={TextWeight.BOLD}
                text={t('LandingPages.FormBlock.ExcludeCss.Title')}
              />
              <Typography className="toggle_desc" text={t('LandingPages.FormBlock.ExcludeCss.Description')} type={TextType.BODY_TEXT_LIGHT} />
            </div>
            <Toggle dataTest={`${dataTest}-exclude-css-toggle`} isOn={excludeCss} onToggle={handleExcludeCssToggleChange} noLeftMargin />
          </div>
          <Accordion
            className={`${rootClass}__options__accordion`}
            dataTest={`${rootClass}-options`}
            childData={[
              {
                index: '0',
                header: <Typography text={t('LandingPages.FormBlock.CustomFormJavascipt')} type={TextType.BODY_TEXT} weight={TextWeight.MEDIUM} />,
                content: (
                  <Tabs
                    defaultValue={tab}
                    tabStyle={TabStyle.REGULAR}
                    childData={TAB_OPTIONS}
                    onChange={handleTabChange}
                    className={`${rootClass}__tabs`}
                  >
                    <Typography
                      text={
                        tab === LandingPageFormCodeTabs.FORM_LOAD_JS
                          ? t('LandingPages.FormBlock.CustomFormJavasciptLoad.Description')
                          : t('LandingPages.FormBlock.CustomFormJavascipt.Description')
                      }
                      type={TextType.BODY_TEXT_LIGHT}
                    />
                    <Tab index={LandingPageFormCodeTabs.FORM_LOAD_JS}>
                      <Ace
                        aceRef={editorLoadRef}
                        content={formLoadJs}
                        onCodeChange={(newValue) => handleCodeChange(newValue, LandingPageFormCodeTabs.FORM_LOAD_JS)}
                        options={{
                          enableBasicAutocompletion: true,
                          enableLiveAutocompletion: true,
                        }}
                        {...aceEditorProps}
                      />
                    </Tab>
                    <Tab index={LandingPageFormCodeTabs.FORM_SUBMIT_JS}>
                      <Ace
                        aceRef={editorSubmitRef}
                        content={formSubmitJs}
                        onCodeChange={(newValue) => handleCodeChange(newValue, LandingPageFormCodeTabs.FORM_SUBMIT_JS)}
                        options={{
                          enableBasicAutocompletion: true,
                          enableLiveAutocompletion: true,
                        }}
                        {...aceEditorProps}
                      />
                    </Tab>
                  </Tabs>
                ),
              },
            ]}
            chevronLeft
          />
        </ModalBody>

        <ModalFooterV2
          footerType={ModalFooterType.Form}
          onClose={onClose}
          dataTest={`${dataTest}`}
          buttons={{
            actionButtonDisabled: !assetID,
            actionButtonLabel: !customFieldValues?.formId ? t('Add form') : t('Save form'),
            actionButtonOnClick: customFieldValues && !isDirty ? onClose : handleInsertForm,
            cancelButtonLabel: t('Cancel'),
          }}
        />
        {isFormPickerOpen && (
          <FormsPickerModal
            isOpen
            isSingleSelect
            excludeClassic
            primaryButtonText={t('Select')}
            secondaryButtonText={t('ListPage.FormTemplates.FormPicker.SecondaryButton')}
            titleText={t('ListPage.FormTemplates.FormPicker')}
            onSubmit={onSelectForm}
            onClose={closeFormPicker}
            preSelectedRowIds={assetID ? [assetID] : undefined}
          />
        )}
      </Modal>
    </>
  )
}

export default FormBlockModal
