import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import Caution from '@components/Caution/Caution'
import ColumnChart from '@components/ColumnChart/ColumnChart'
import ComplexList from '@components/ComplexList/ComplexList'
import LineChart from '@components/LineChart/LineChart'
import PageContainer from '@components/PageContainer/PageContainer'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import ReportHeader from '@components/ReportHeader/ReportHeader'
import { ChartField, LegendLocation } from '@const/Chart.constants'
import { DAY_INTERVAL, useTranslation } from '@const/globals'
import withLoadingAndError from '@hoc/withLoadingAndError/withLoadingAndError'
import DataWithArrow from '@src/pages/reports/components/DataWithArrow/DataWithArrow'
import PercentageAction from '@src/pages/reports/components/PercentageAction/PercentageAction'
import { daysOfWeek } from '@utils/date'
import { ReportProps } from '@utils/reports/reports.utils'

interface Props extends ReportProps {
  dailyActivity: { fields: ChartField[] }
  monthlyLaunchDetails: {
    categories: string[]
    fields: ChartField[]
  }
  dataLakeUseError: boolean
}

const rootClass = 'email-messages'

const EmailMessages: FC<Props> = (props: Props) => {
  const {
    monthlyComparison,
    dailyActivity,
    monthlyLaunchDetails,
    opensByDay,
    clicksByDay,
    activityByDay,
    updatedDate,
    startTime,
    endTime,
    onDateChange,
    nextDisabled,
    prevDisabled,
    refreshOnClick,
    dataLakeUseError,
    dataTest = rootClass,
    className = '',
  } = props

  const { t } = useTranslation()
  const [showPercentages, setShowPercentages] = useState<boolean>(false)
  const [monthlyComparisonData, setMonthlyComparisonData] = useState(monthlyComparison.rows)
  const days = daysOfWeek(t)

  useEffect(() => {
    const buildPct = (dataWithPercent: string[][], dataPlain: string[]) => {
      if (showPercentages) {
        return dataWithPercent.map((data, idx) => {
          const vsTitle = ((monthlyComparison.columns[idx - 1]?.title as string) ?? '').split(' ')[0].substr(0, 3).toUpperCase() ?? ''
          const valueProps = data.length === 2 ? { changePercent: data[1], vsTitle } : {}

          return <DataWithArrow key={data[0]} text={data[0]} {...valueProps} />
        })
      }

      return dataPlain
    }

    setMonthlyComparisonData(
      monthlyComparison.rows.map((row) => ({
        ...row,
        data: buildPct(row.dataWithPercent ?? [], row.dataPlain ?? []),
      }))
    )
  }, [showPercentages, monthlyComparison])

  const buildAction = <PercentageAction showPercentages={showPercentages} onChange={() => setShowPercentages(!showPercentages)} />

  return (
    <PageContainer className={classNames(rootClass, className)} dataTest={dataTest}>
      <PositionContainer>
        <ReportHeader
          reportType={t('Email Messages Summary Report')}
          startTime={startTime}
          endTime={endTime}
          prevDisabled={prevDisabled}
          nextDisabled={nextDisabled}
          updatedDate={updatedDate}
          handleChange={onDateChange}
          refreshOnClick={refreshOnClick}
        />
        {dataLakeUseError && <Caution message={'Data Lake conditional feature must be enabled to use it as a data source. Classic will be used.'} />}
        <ComplexList
          title={t('Monthly Comparison')}
          subTitle={t('Counts are Totals')}
          columns={monthlyComparison.columns}
          rows={monthlyComparisonData}
          actions={buildAction}
        />
        <LineChart fields={dailyActivity.fields} title={t('Daily Activity')} tickInterval={14 * DAY_INTERVAL} isDateTime />
        <ColumnChart
          fields={monthlyLaunchDetails.fields}
          categories={monthlyLaunchDetails.categories}
          title={t('Monthly Launch Details')}
          legendLocation={LegendLocation.TOP}
        />
        {opensByDay && (
          <LineChart
            fields={opensByDay.fields}
            categories={opensByDay.categories}
            title={t('Opens by Day of Month')}
            tickInterval={2}
            tooltipValueSuffix={` ${t('Opens')}`}
          />
        )}
        {clicksByDay && (
          <LineChart
            fields={clicksByDay.fields}
            categories={clicksByDay.categories}
            title={t('Clicks by Day of Month')}
            tickInterval={2}
            tooltipValueSuffix={` ${t('Clicks')}`}
          />
        )}
        <ColumnChart fields={activityByDay.fields} categories={days} title={t('Activity by Day of Week')} legendLocation={LegendLocation.TOP} />
      </PositionContainer>
    </PageContainer>
  )
}

export default withLoadingAndError(EmailMessages)
