import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './label.css'

interface LabelProps {
  children: ReactNode
  className?: string
  htmlFor?: string | void
  display?: string
  title?: string
  required?: boolean
  requiredTextType?: TextType
  dataTest?: string
}

const rootClass = 'label'

/**
 * @deprecated use <LabelV2 instead
 */
export const Label: FC<LabelProps> = (props: LabelProps) => {
  const { children, className, dataTest, htmlFor = '', display = 'block', title, required, requiredTextType = TextType.BODY_TEXT_SMALL_LIGHT } = props
  const { t } = useTranslation()
  return (
    <label
      title={title}
      className={classNames(rootClass, className)}
      htmlFor={htmlFor}
      data-test={dataTest ?? 'input-label'}
      style={{
        display,
      }}
    >
      {children}
      {required && <Typography text={`(${t('required')})`} inline type={requiredTextType} className={`${rootClass}-required`} />}
    </label>
  )
}

export default Label
