import { gql } from '@apollo/client'

export default gql`
  query getItemsInFolder(
    $folderDTO: FolderDTOInput
    $pageNumber: Int
    $query: String
    $orderBy: String
    $pageSize: Int
    $subTypes: [String]
    $type: String
    $subTypeCondition: SubTypeConditionDTOInput
    $folderSubTypes: [String]
    $folder: Long
    $field: String
    $matchAllSubTypes: Boolean
    $fieldType: String
    $direction: String
  ) {
    getItemsInFolder(
      folderDTO: $folderDTO
      pageNumber: $pageNumber
      query: $query
      orderBy: $orderBy
      pageSize: $pageSize
      subTypes: $subTypes
      type: $type
      subTypeCondition: $subTypeCondition
      folderSubTypes: $folderSubTypes
      folder: $folder
      field: $field
      matchAllSubTypes: $matchAllSubTypes
      fieldType: $fieldType
      direction: $direction
    ) {
      accountId
      authorId
      createdTime
      enabled
      externalId
      favoriteUsers
      folderId
      hasToAudit
      id
      isFavorite
      item
      itemLevel
      labels {
        accountId
        color
        createdTime
        enabled
        id
        itemCount
        name
        updatedTime
        views
      }
      parentId
      position
      rootParent
      subTypeDTO {
        count
        itemType
        name
      }
      updatedTime
      username
      views
    }
  }
`
