import React, { ChangeEvent, FC, ReactNode, RefObject, useCallback, useEffect, useMemo, useState } from 'react'
import { Row } from 'react-table'

import classNames from 'classnames'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import ActionableNestedTableWithEmptyListing from '@components/ActionableNestedTableWithEmptyListing/ActionableNestedTableWithEmptyListing'
import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import ClipboardCopy from '@components/ClipboardCopy/ClipboardCopy'
import { YesNo } from '@components/ConfirmationModal'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import FormRow from '@components/FormRow'
import InfoAction from '@components/InfoAction/InfoAction'
import InputWithStatus from '@components/InputWithStatus/InputWithStatus'
import Modal, { ModalHeader } from '@components/Modal'
import { ModalBody } from '@components/Modal/components/ModalBody'
import { ModalFooter, ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Search, { SearchType } from '@components/Search/Search'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { ColWithTooltip } from '@components/Table/components/tableColumns'
import { HeaderAction, RowAction, TableColumn } from '@components/Table/Table'
import { MultipleTables } from '@components/TableV2/components/MultipleTables/MultipleTables'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { ColWithTooltip as ColWithTooltipV2 } from '@components/TableV2/utils/tableV2ColumnUtils'
import TextArea from '@components/TextArea/TextArea'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import addPersonalization from '@graphql/mutations/addPersonalization'
import deleteMarkedPersonalization from '@graphql/mutations/deleteMarkedPersonalization'
import deletePersonalization from '@graphql/mutations/deletePersonalization'
import putPersonalization from '@graphql/mutations/putPersonalization'
import getAllPersonalizations from '@graphql/queries/getAllPersonalizations'
import {
  AddPersonalizationMutation,
  AddPersonalizationMutationVariables,
  DeleteMarkedPersonalizationMutation,
  DeleteMarkedPersonalizationMutationVariables,
  DeletePersonalizationMutation,
  DeletePersonalizationMutationVariables,
  PutPersonalizationMutation,
  PutPersonalizationMutationVariables,
} from '@graphql/types/mutation-types'
import { CustomPersonalization, GetAllPersonalizationsQuery, GetAllPersonalizationsQueryVariables } from '@graphql/types/query-types'
import { StatusToastType } from '@interface/StatusToast'
import { logNewRelicError } from '@utils/new-relic.utils'

import {
  CONTENT_PERSONALIZATION_MORE_INFO_LINK,
  CONTENT_PERSONALIZATION_VALIDATION_MESSAGES,
  CONTENT_VARIABLE_MAX_LENGTH,
} from './constants/contentPersonalization.constants'
import { STICKY_HEADER_POSITION } from '../../utils/CustomAccountSettings.utils'

import './ContentPersonalization.css'

interface Props {
  setToastStatus: (value: StatusToastType) => void
  scrollableElement: RefObject<HTMLDivElement>
  dataTest?: string
}

const rootClass = 'content-personalization'

const ContentPersonalization: FC<Props> = (props: Props) => {
  const { dataTest, setToastStatus, scrollableElement } = props

  const [personalizations, setPersonalizations] = useState<CustomPersonalization[]>([])
  const [personalizationsFiltered, setPersonalizationsFiltered] = useState<CustomPersonalization[]>([])
  const [textareaValidity, setTextareaValidity] = useState<boolean | undefined>()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [isMultipleDelete, setIsMultipleDelete] = useState<boolean>(false)
  const [activePersonalization, setActivePersonalization] = useState<string>('')
  const [activePersonalizationVariable, setActivePersonalizationVariable] = useState<string>('')
  const [deleteKey, setDeleteKey] = useState<string>('')
  const [multipleDeleteKeys, setMultipleDeleteKeys] = useState<string[]>([])
  const [contentValue, setContentValue] = useState<string>('')
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [contentTouched, setContentTouched] = useState<boolean>(false)

  const client = useApolloClient()
  const { data, loading, error, refetch } = useQuery<GetAllPersonalizationsQuery, GetAllPersonalizationsQueryVariables>(getAllPersonalizations, {
    client,
    fetchPolicy: 'network-only',
    variables: {
      sortColumn: 'CONTENTNAME',
      sortDirection: 'ASC',
    },
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    if (!loading && data?.getAllPersonalizations) {
      const personalizationData = data.getAllPersonalizations
      setPersonalizations(personalizationData)
      filterPersonalizations(personalizationData, searchTerm)
    }
    if (error) {
      logNewRelicError(error)
    }
  }, [loading, error, data, searchTerm])

  useEffect(() => {
    filterPersonalizations(personalizations, searchTerm)
  }, [personalizations, searchTerm])

  const [savePersonalization, { loading: loadingSave }] = useMutation<AddPersonalizationMutation, AddPersonalizationMutationVariables>(
    addPersonalization,
    {
      client,
      fetchPolicy: 'no-cache',
    }
  )

  const [editPersonalization, { loading: loadingEdit }] = useMutation<PutPersonalizationMutation, PutPersonalizationMutationVariables>(
    putPersonalization,
    {
      client,
      fetchPolicy: 'no-cache',
    }
  )

  const [onDeletePersonalization, { loading: loadingDelete }] = useMutation<DeletePersonalizationMutation, DeletePersonalizationMutationVariables>(
    deletePersonalization,
    {
      client,
      fetchPolicy: 'no-cache',
    }
  )

  const [onDeleteMultiPersonalization, { loading: loadingDeleteMulti }] = useMutation<
    DeleteMarkedPersonalizationMutation,
    DeleteMarkedPersonalizationMutationVariables
  >(deleteMarkedPersonalization, {
    client,
    fetchPolicy: 'no-cache',
  })
  const tableLoading = loading || loadingSave || loadingEdit || loadingDelete || loadingDeleteMulti

  const { t } = useTranslation()

  const headerActions: HeaderAction[] = [
    {
      label: t('Delete'),
      icon: SvgNames.pencil,
      onClick: () => {
        setIsDeleteModalOpen(true)
        setIsMultipleDelete(true)
      },
    },
  ]

  const getVariableTableHeader = useCallback(() => {
    return (
      <div className={`${rootClass}__table-custom-header`}>
        <div className={`${rootClass}__table-custom-header-title`}>{t('VARIABLE')}</div>
        <Tooltip trigger={<Svg name={SvgNames.info} type={SvgType.MEDIUM} />} position="top" align="center">
          {t('Insert this variable to personalize your Act-On content')}
        </Tooltip>
      </div>
    )
  }, [t])

  const columns: TableColumn[] = [
    {
      Header: t('CONTENT NAME'),
      accessor: 'key',
      align: 'left',
      isSorted: false,
      flexColumn: true,
      Cell: (row) => <ColWithTooltip row={row} />,
    },
    {
      Header: t('DISPLAYED CONTENT'),
      accessor: 'value',
      align: 'left',
      isSorted: false,
      minWidth: 360,
      maxWidth: 360,
      Cell: (row) => <ColWithTooltip row={row} />,
    },
    {
      Header: getVariableTableHeader(),
      accessor: 'accessor',
      isSorted: false,
      align: 'left',
      minWidth: 240,
      maxWidth: 240,
      Cell: (row) => getVariableCellContent(row.value),
    },
  ]

  const rowActions: RowAction[] = [
    {
      label: 'Edit',
      icon: SvgNames.pencil,
      onClick: (e) => {
        setActivePersonalization(e.original.key)
        setContentValue(e.original.value)
        setActivePersonalizationVariable(e.original.accessor)
        setIsModalOpen(true)
        setIsEditing(true)
      },
    },
    {
      label: 'Delete',
      icon: SvgNames.delete,
      onClick: (e) => {
        setDeleteKey(e.original.key)
        setIsDeleteModalOpen(true)
        setIsMultipleDelete(false)
      },
    },
  ]

  const getVariableCellContent = useCallback(
    (value: string): ReactNode => {
      return (
        <ClipboardCopy
          value={`{{${value}}}`}
          text={
            <Tooltip
              trigger={<div className={classNames('ellip', `${rootClass}__table-accessor-field`)}>&#123;&#123;{value}&#125;&#125;</div>}
              position="top"
              align="center"
            >
              {t('Copy to Clipboard')}
            </Tooltip>
          }
          hideIcon
          tooltipText={t('Copied')}
          textType={TextType.BODY_TEXT}
          lineHeight={LineHeight.MEDIUM}
          textWeight={TextWeight.REGULAR}
          className={`${rootClass}__copy`}
        />
      )
    },
    [t]
  )

  const contentPersonalizationColumns = useMemo(
    (): ColumnDefWithAdditionalProps<CustomPersonalization>[] => [
      {
        header: 'CONTENT NAME',
        textAlign: 'left',
        accessorKey: 'key',
        enableCustomCellValue: true,
        cell: (cell) => ColWithTooltipV2({ cell }),
      },
      {
        header: 'DISPLAYED CONTENT',
        textAlign: 'left',
        accessorKey: 'value',
        enableCustomCellValue: true,
        cell: (cell) => ColWithTooltipV2({ cell }),
      },
      {
        header: () => getVariableTableHeader(),
        textAlign: 'left',
        maxSize: 350,
        accessorKey: 'accessor',
        enableCustomCellValue: true,
        disableSorting: true,
        cell: (cell) => getVariableCellContent(cell.getValue<string>()),
      },
    ],
    [getVariableCellContent, getVariableTableHeader]
  )

  const closeModal = () => {
    setIsModalOpen(false)
    setActivePersonalization('')
    setIsEditing(false)
    setTextareaValidity(undefined)
    setContentValue('')
    setContentTouched(false)
  }

  const onInputChange = (value: string) => {
    setActivePersonalization(value)
  }

  const checkSpaceValidity = (value: string) => {
    const v = value.trim()

    if (v.includes(' ')) {
      return { errorMessageKey: 'hasSpace' }
    }
  }

  const checkMaxLength = (value: string) => {
    const v = value.trim()

    if (v.length > CONTENT_VARIABLE_MAX_LENGTH) {
      return { errorMessageKey: 'maxLength' }
    }
  }

  const checkUniqueValidity = (value: string) => {
    if (personalizations.filter((v) => !isEditing || v.key !== activePersonalization).findIndex((p) => p.key === value) > -1) {
      return { errorMessageKey: 'isUnique' }
    }
  }

  const isButtonEnabled = (value: string) => {
    return !!value && !checkSpaceValidity(value) && !checkMaxLength(value) && !checkUniqueValidity(value)
  }

  const onTextAreaChange = (value: string) => {
    setContentValue(value)
    const isValid = !!value
    setTextareaValidity(isValid)
  }

  const onTextAreaBlur = (value: string) => {
    const isValid = !!value
    setTextareaValidity(isValid)
  }

  const onSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm)
  }

  const onDelete = () => {
    onDeletePersonalization({ variables: { key: deleteKey } }).then(() => {
      setToastStatus({
        showStatus: true,
        statusMessage: t("Success! We've removed the content personalization(s)."),
        successStatus: true,
      })
      refetch()
    })
  }

  const contentNameInfo = () => {
    return (
      <div className={`${rootClass}__modal-body-description`}>
        <Typography
          text={t('You can use this name when adding your custom content to messages, landing pages, and forms. Example:')}
          type={TextType.BODY_TEXT_SMALL_LIGHT}
          lineHeight={LineHeight.MEDIUM_SMALL}
          inline
        />
        &nbsp;
        <Typography
          text={<>&#123;&#123;{t('AccountCustom.your-content-name')}&#125;&#125;.</>}
          type={TextType.BODY_TEXT_SMALL_LIGHT}
          lineHeight={LineHeight.MEDIUM_SMALL}
          inline
        />
      </div>
    )
  }

  const filterPersonalizations = (data: CustomPersonalization[], searchTerm: string) => {
    const loweredSearchTerm = searchTerm.toLowerCase()
    searchTerm === ''
      ? setPersonalizationsFiltered(data)
      : setPersonalizationsFiltered(
          data.filter(
            (p) =>
              p.key.toLowerCase().includes(loweredSearchTerm) ||
              p.value.toLowerCase().includes(loweredSearchTerm) ||
              `{{${p.accessor.toLowerCase()}}}`.includes(loweredSearchTerm)
          )
        )
  }

  const onMultipleDelete = () => {
    onDeleteMultiPersonalization({ variables: { markedPersonalization: multipleDeleteKeys } }).then(() => {
      setToastStatus({
        showStatus: true,
        statusMessage: t("Success! We've removed the content personalization(s)."),
        successStatus: true,
      })
      refetch()
    })
  }

  const getTextArea = (
    <TextArea
      name="displayedContent"
      error={textareaValidity !== undefined && !textareaValidity}
      defaultValue={contentValue}
      rows={4}
      placeholder={t('Enter the content that will display when you use the content personalization')}
      className={`${rootClass}__modal-body-textarea`}
      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
        onTextAreaChange(e.target.value)
      }}
      onBlur={(e: ChangeEvent<HTMLTextAreaElement>) => {
        onTextAreaBlur(e.target.value)
      }}
      onFocus={() => setContentTouched(true)}
    />
  )

  return (
    <div data-test={dataTest} className={rootClass}>
      {isDeleteModalOpen && (
        <DeleteConfirmationModal
          title={t('Are you sure?')}
          body={t(
            'Deleting these content personalizations will affect any Landing Pages or Forms where they are currently in use. Messages containing these personalizations may fail if the values are missing.'
          )}
          deleteButtonText={t('Yes, delete')}
          onAnswer={(answer) => {
            if (answer === YesNo.YES) {
              isMultipleDelete ? onMultipleDelete() : onDelete()
            }
            setIsDeleteModalOpen(false)
          }}
          isOpen={isDeleteModalOpen}
        />
      )}
      <Modal
        isOpen={isModalOpen}
        className={`${rootClass}__modal`}
        header={
          <ModalHeader headerType={ModalHeaderType.Form}>
            {t(isEditing ? t('Edit Content Personalization') : t('Add Content Personalization'))}
          </ModalHeader>
        }
      >
        <ModalBody className={`${rootClass}__modal-body`}>
          <FormRow>
            <Typography
              className={`${rootClass}__modal-body-label`}
              type={TextType.BODY_TEXT_SMALL}
              weight={TextWeight.MEDIUM}
              text={t('Content Name')}
            />
            {!isEditing ? (
              <InputWithStatus
                className={`${rootClass}__modal-body-input`}
                placeholder={t('Enter a name for your content')}
                dataTest={`${dataTest}-modal-input`}
                value={activePersonalization}
                required
                validityFunctions={[checkSpaceValidity, checkMaxLength, checkUniqueValidity]}
                tooltipProps={{ position: 'top', align: 'end', alignOffset: 14 }}
                tooltipErrorMessages={CONTENT_PERSONALIZATION_VALIDATION_MESSAGES}
                onChange={(e) => onInputChange(e.target.value)}
              />
            ) : (
              <Typography
                className={`${rootClass}__modal-body-name`}
                type={TextType.BODY_TEXT_LIGHT}
                text={activePersonalizationVariable}
                lineHeight={LineHeight.MEDIUM_LARGE}
              />
            )}
            {!isEditing && contentNameInfo()}
          </FormRow>
          <FormRow>
            <Typography
              className={`${rootClass}__modal-body-label`}
              type={TextType.BODY_TEXT_SMALL}
              weight={TextWeight.MEDIUM}
              text={t('Displayed Content')}
            />
            <Tooltip
              className={`${rootClass}__tooltip`}
              trigger={getTextArea}
              position={'top'}
              align={'end'}
              hide={!contentTouched || contentValue.length > 0}
            >
              {t('Provide a value')}
            </Tooltip>
            <Typography
              text={
                <span>
                  This is what will display to your contacts. You can make changes to the content here to update every location you use&nbsp;
                  &#123;&#123;AccountCustom.your-content-name&#125;&#125;.
                </span>
              }
              type={TextType.BODY_TEXT_SMALL_LIGHT}
              lineHeight={LineHeight.MEDIUM_SMALL}
              className={`${rootClass}__modal-body-description`}
            />
          </FormRow>
        </ModalBody>
        <ModalFooter footerType={ModalFooterType.Form}>
          <Button buttonType={ButtonType.TERTIARY} onClick={closeModal} dataTest={`${dataTest}-close-button`}>
            {t('Cancel')}
          </Button>
          <Button
            buttonType={ButtonType.PRIMARY}
            dataTest={`${dataTest}-save-button`}
            className={`${rootClass}_save-button`}
            disabled={!isButtonEnabled(activePersonalization) || textareaValidity === undefined || !textareaValidity}
            onClick={() => {
              const variables = { key: activePersonalization.trim(), value: contentValue.trim() }
              closeModal()
              !isEditing
                ? savePersonalization({ variables }).then(() => {
                    setToastStatus({
                      showStatus: true,
                      statusMessage: t("Success! We've added this content personalization."),
                      successStatus: true,
                    })
                    refetch()
                  })
                : editPersonalization({ variables }).then(() => {
                    setToastStatus({
                      showStatus: true,
                      statusMessage: t("Success! We've edited this content personalization."),
                      successStatus: true,
                    })
                    refetch()
                  })
            }}
          >
            {t(isEditing ? 'Save' : 'Add')}
          </Button>
        </ModalFooter>
      </Modal>

      <InfoAction
        svgName={SvgNames.lightBulb}
        message={
          <div>
            <Typography
              text={t(
                'Use account-wide content personalizations to keep information like employee names, copyright year, or legal disclaimers consistent across your messages, landing pages, and forms.'
              )}
              inline
            />
            &nbsp;
            <TextLink text={t('More info')} link={CONTENT_PERSONALIZATION_MORE_INFO_LINK} size={TextLinkSize.LARGE} />
          </div>
        }
        className={`${rootClass}__info-action`}
      />
      <div className={`${rootClass}__actions`}>
        <Button
          buttonType={ButtonType.PRIMARY}
          iconPosition={ButtonIconPosition.LEFT}
          dataTest={`${dataTest}-button__add`}
          onClick={() => {
            setIsModalOpen(true)
          }}
        >
          <Svg name={SvgNames.plus} type={SvgType.LARGER_ICON} />
          {t('Add personalization')}
        </Button>
        <Search
          className={`${rootClass}__actions-search`}
          dataTest={`${dataTest}-search-input`}
          incomingValue={searchTerm}
          onChangeHandler={onSearch}
          placeholder={t('Search personalizations')}
          searchType={SearchType.LARGE}
          canClear
          clearOnChange={[searchTerm]}
        />
      </div>
      <MultipleTables
        tableV2Props={{
          loading: tableLoading,
          allLoaded: !tableLoading,
          data: personalizationsFiltered,
          columns: contentPersonalizationColumns,
          rowActions,
          headerActions,
          enableCheckbox: true,
          enableInsideLoader: true,
          enableStickyHeader: true,
          stickyHeaderTopPosition: STICKY_HEADER_POSITION,
          emptyState: {
            headline: personalizations.length === 0 ? t('No content personalizations set') : t('No results found'),
            text: personalizations.length === 0 ? t('Add your custom content personalizations.') : t('There were no results matching your search.'),
            buttonText: personalizations.length === 0 ? t('Add personalization') : undefined,
            imgSrc: StaticImageNames.emptySearch,
            size: EmptyListingSize.MEDIUM,
            buttonOnClick: () => setIsModalOpen(true),
          },
          onRowSelectionChanged: setMultipleDeleteKeys,
          rowUniqueIdKey: 'key',
        }}
        oldTable={
          <ActionableNestedTableWithEmptyListing
            data={personalizationsFiltered}
            columns={columns}
            className={`${rootClass}__table`}
            useCheckboxes
            useHeaderCheckbox
            headerActions={headerActions}
            rowActions={rowActions}
            onRowSelectionChanged={(selectedRows: Row<{}>[], rows?: Row<{}>[]) => {
              const rowIds = selectedRows.map((a: Row) => a.id)
              const filtered = rows && rows.filter((row: any) => rowIds.includes(row.id))
              if (rowIds.length > 0) {
                setMultipleDeleteKeys(filtered ? filtered.map((row: any) => row.original.key) : [])
              } else if (multipleDeleteKeys.length > 0) {
                setMultipleDeleteKeys([])
              }
            }}
            headline={personalizations.length === 0 ? t('No content personalizations set') : t('No results found')}
            text={personalizations.length === 0 ? t('Add your custom content personalizations.') : t('There were no results matching your search.')}
            buttonText={personalizations.length === 0 ? t('Add personalization') : undefined}
            buttonOnClick={() => {
              setIsModalOpen(true)
            }}
            imageWithTitle={false}
            fixedHeader
            imgSrc={StaticImageNames.emptySearch}
            size={EmptyListingSize.MEDIUM}
            loading={tableLoading}
            allLoaded={!tableLoading}
            scrollableElement={scrollableElement}
            onLoading={() => {
              // empty required method
            }}
            dataTest={`${dataTest}__table`}
          />
        }
      ></MultipleTables>
    </div>
  )
}

export default ContentPersonalization
