import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { FormatFile } from '@const/globals'
import { FtpFileDto } from '@graphql/types/microservice/entity-upload-types'

import './SelectedFile.css'

interface SelectedFileProps {
  className?: string
  dataTest?: string
  selectedFile: FtpFileDto
  onDelete: VoidFunction
}

const rootClass = 'selected-file'

const SelectedFile: FC<SelectedFileProps> = (props: SelectedFileProps) => {
  const { dataTest = rootClass, className = '', selectedFile, onDelete } = props

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__details`}>
        <Svg className={`${rootClass}__icon`} name={SvgNames.page} />
        <div>
          <TextWithTooltipOnEllip typographyProps={{ text: selectedFile.name, weight: TextWeight.BOLD, className: `${rootClass}__details-name` }} />
          <Typography text={FormatFile.readableBytes(selectedFile.size)} type={TextType.BODY_TEXT_SMALL_LIGHT} />
        </div>
      </div>
      <Button
        buttonType={ButtonType.ICON}
        dataTest={`${dataTest}-delete-button`}
        className={`${rootClass}__remove-file-button`}
        onClick={onDelete}
        noPadding
      >
        <Svg className={`${rootClass}__remove-file-icon`} name={SvgNames.close} type={SvgType.SMALLER_ICON} fill={SvgColor.TEXT_GRAY} />
      </Button>
    </div>
  )
}

export default SelectedFile
