import React, { ReactNode, useState } from 'react'

import classNames from 'classnames'

import { getFolderById } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import Button, { ButtonType } from '@components/Button'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import NestedDropDown from '@components/NestedDropDown/NestedDropDown'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { SvgNames } from '@components/Svg'
import Typography, { ModalBodyStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { Folder } from '@interface/Folder'
import { getFolderDropDownOptions } from '@utils/folderUtils'

import './MoveToFolderModal.css'

export interface MoveToFolderModalProps<T> {
  addToFolder: boolean
  className?: string
  dataTest?: string
  folders: FolderData[]
  isOpen: boolean
  description?: string
  primaryButtonText?: string
  headerText?: string
  folderPrimaryButtonText?: string
  options?: ReactNode
  movableItems?: T[]
  onClose: () => void
  onMoveClick: (folderId: number) => void
}

interface State {
  folderId: number | null
}

const rootClass = 'move-to-folder'

const MoveToFolderModal = <T extends ItemDto>(props: MoveToFolderModalProps<T>) => {
  const {
    addToFolder,
    dataTest = rootClass,
    className = '',
    isOpen,
    movableItems,
    onClose,
    onMoveClick,
    folders,
    description,
    options,
    headerText,
    primaryButtonText,
    folderPrimaryButtonText,
  } = props
  const [state, setState] = useState<State>({
    folderId: null,
  })
  const { folderId } = state
  const { t } = useTranslation()

  const onFolderSelect = (folderId: number) => {
    setState({ ...state, folderId })
  }

  const header = (
    <ModalHeader headerType={ModalHeaderType.Form}>{t(headerText ? headerText : addToFolder ? 'Add to folder' : 'Move to folder')}</ModalHeader>
  )

  const itemsLength = movableItems?.length

  return (
    <Modal className={classNames(rootClass, className)} dataTest={dataTest} isOpen={isOpen} header={header} showOverflow>
      <ModalBody>
        {description && renderDescription(description)}
        {itemsLength && (addToFolder ? getAddToFolderTypographies(itemsLength) : getMoveToFolderTypographies(movableItems, folders as Folder[]))}
        <NestedDropDown
          options={getFolderDropDownOptions(folders, true)}
          footerPrimaryButtonText={folderPrimaryButtonText ? folderPrimaryButtonText : addToFolder ? t('Add here') : t('Move here')}
          onSubmit={(selected) => selected && onFolderSelect(parseInt(selected))}
          placeholderIcon={SvgNames.moveFolder}
          title={t('Select a folder')}
          withTitle
          withFooter
        />
        {options && renderOptions(options)}
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form}>
        <Button dataTest={`${dataTest}--cancel-button`} buttonType={ButtonType.TERTIARY} onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button
          dataTest={`${dataTest}--move-button`}
          buttonType={ButtonType.PRIMARY}
          disabled={!folderId}
          onClick={() => (state.folderId ? onMoveClick(state.folderId) : false)}
        >
          {t(primaryButtonText ? primaryButtonText : addToFolder ? 'Add' : 'Move')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default MoveToFolderModal

const getAddToFolderTypographies = (itemLength: number) => {
  return (
    <div className={`${rootClass}__title`}>
      <Typography text={'MoveToFolderModal.AddItem.Text'} values={{ count: itemLength }} {...ModalBodyStyle} inline />
    </div>
  )
}

const renderOptions = (options: ReactNode) => {
  return <div className={`${rootClass}__options`}>{options}</div>
}

const renderDescription = (description: string) => {
  return (
    <div className={`${rootClass}__title`}>
      <Typography text={description} weight={TextWeight.MEDIUM} type={TextType.BODY_TEXT} />
    </div>
  )
}

const getMoveToFolderTypographies = <T extends ItemDto>(items: T[], folders: Folder[]) => {
  const firstFolder = items.find((item) => item.folderId !== undefined)
  const folderText = firstFolder ? getFolderById(firstFolder.folderId, folders)?.name : ''

  return (
    <div className={`${rootClass}__title`}>
      <Typography
        text={'MoveToFolderModal.MoveItem.Text'}
        tagProps={{ bold: { weight: TextWeight.BOLD } }}
        values={{ count: items.length, folder: folderText }}
        {...ModalBodyStyle}
        inline
      />
    </div>
  )
}
