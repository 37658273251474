import { gql } from '@apollo/client'

export default gql`
  mutation editUrlGroup($pageId: String!, $name: String!, $urlId: String!) {
    editUrlGroup(pageId: $pageId, name: $name, urlId: $urlId) {
      id
      name
      publicUrl
      seoFriendlyUrl
      shortUrl
    }
  }
`
