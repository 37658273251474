import React, { FC } from 'react'

import { ButtonType } from '@components/Button'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import { ModalHeader } from '@components/Modal'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography, { ModalHeaderStyle, TextType, TextWeight, TypographyProps } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { UrlGroup } from '@graphql/types/mutation-types'

import './ManageURLsConfirmationModal.css'

interface Props {
  handleConfirmGroupDelete: (answer: YesNo) => void
  handleConfirmSeoURLRemove: (answer: YesNo) => void
  handleConfirmShortURLRemove: (answer: YesNo) => void
  handleSetupShortURL: (answer: YesNo) => void
  handleCloseGenerateLimitModal: () => void
  openGenerateLimitModal: boolean
  groupToDelete?: UrlGroup
  shortURLToDelete?: string
  seoUrlToDelete?: string
  groupIdToSetupShort?: string
  dataTest?: string
}

interface ConfirmationModalProps {
  titleKey: string
  bodyTypographyProps: Partial<TypographyProps>
  onAnswer?: (answer: YesNo) => void
  onClose?: () => void
  dataTest: string
  deleteButtonText?: string
  cancelButtonText?: string
}

const rootClass = 'manage-urls-confirmation-modal'

const ManageURLsConfirmationModal: FC<Props> = ({
  handleConfirmGroupDelete,
  handleConfirmSeoURLRemove,
  handleConfirmShortURLRemove,
  handleSetupShortURL,
  handleCloseGenerateLimitModal,
  groupToDelete,
  seoUrlToDelete,
  shortURLToDelete,
  groupIdToSetupShort,
  openGenerateLimitModal,
  dataTest = rootClass,
}: Props) => {
  const { t } = useTranslation()

  const modalProps: ConfirmationModalProps | undefined = !!groupToDelete
    ? {
        titleKey: 'Confirm.URL.Group.Delete.Modal.Title',
        bodyTypographyProps: {
          text: t('Confirm.URL.Group.Delete.Modal.Message', { name: groupToDelete?.name }),
          tagProps: { bold: { weight: TextWeight.BOLD } },
        },
        onAnswer: handleConfirmGroupDelete,
        dataTest: 'delete-group',
      }
    : !!shortURLToDelete
    ? {
        titleKey: 'Remove Short URL',
        bodyTypographyProps: {
          text: t('Confirm.Short.URL.Remove.Modal.Message'),
        },
        onAnswer: handleConfirmShortURLRemove,
        deleteButtonText: 'Remove',
        dataTest: 'remove-short-url',
      }
    : !!groupIdToSetupShort
    ? {
        titleKey: 'Setup Short URL',
        bodyTypographyProps: {
          text: t('Setup.Short.URL.Modal.Message'),
        },
        onAnswer: handleSetupShortURL,
        dataTest: 'setup-short-url',
      }
    : !!seoUrlToDelete
    ? {
        titleKey: 'Confirm.Seo.URL.Delete.Modal.Title',
        bodyTypographyProps: {
          text: t('Confirm.Seo.URL.Delete.Modal.Message'),
          tagProps: { bold: { weight: TextWeight.BOLD } },
        },
        onAnswer: handleConfirmSeoURLRemove,
        deleteButtonText: 'Delete URL',
        cancelButtonText: 'Continue editing',
        dataTest: 'delete-seo-url',
      }
    : openGenerateLimitModal
    ? {
        titleKey: 'Unable.Generate.Short.URL.Modal.Title',
        bodyTypographyProps: {
          text: t('Unable.Generate.Short.URL.Modal.Message'),
        },
        onClose: handleCloseGenerateLimitModal,
        dataTest: 'unable-generate-short-url',
      }
    : undefined

  if (!modalProps) {
    return null
  }

  return (
    <ConfirmationModal
      isYesNo={!openGenerateLimitModal}
      isDelete={!(groupIdToSetupShort || openGenerateLimitModal)}
      yesButtonText={t('Setup')}
      deleteButtonText={modalProps.deleteButtonText}
      cancelButtonText={modalProps.cancelButtonText}
      isOpen
      deleteButtonType={ButtonType.ERASE}
      title={
        openGenerateLimitModal ? (
          <ModalHeader className={`${rootClass}__generate-limit-header`} hideBorder>
            <Svg name={SvgNames.warning} type={SvgType.VERY_LARGE_ICON} fill={SvgColor.TEXT_GRAY} />
            <Typography text={t(modalProps.titleKey)} {...ModalHeaderStyle} type={TextType.MODAL_HEADLINE_CONFIRMATION} />
          </ModalHeader>
        ) : (
          t(modalProps.titleKey)
        )
      }
      body={<Typography type={TextType.BODY_TEXT_LIGHT} inline className={`${rootClass}__message`} {...modalProps.bodyTypographyProps} />}
      onAnswer={modalProps.onAnswer}
      closeModal={modalProps.onClose}
      className={rootClass}
      dataTest={`${dataTest}-${modalProps.dataTest}`}
    />
  )
}

export default ManageURLsConfirmationModal
