import { gql } from '@apollo/client'

export default gql`
  query GetColumnByEntityType($type: String) {
    fieldsForEntityType(entityType: $type) {
      name
      label
      dataType
      required
      selected
    }
  }
`
