import getAllItems from '@graphql/microservices/categorization/getAllItems'
import searchSegments from '@graphql/microservices/categorization/searchSegments'
import {
  GetAllItemsQuery,
  GetAllItemsQueryVariables,
  SearchSegmentsQuery,
  SearchSegmentsQueryVariables,
} from '@graphql/types/microservice/categorization-types'
import { ItemSubType, ItemType } from '@utils/categorization'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

interface GetCRMSourcedSegmentsRequestParams {
  pageNumber: number
}

interface SearchCRMSourcedSegmentsRequestParams {
  search: string
}

export type GetCRMSourcedSegmentsRequestType = (params: GetCRMSourcedSegmentsRequestParams) => FetchPromise<GetAllItemsQuery>
export type SearchCRMSourcedSegmentsRequestType = (params: SearchCRMSourcedSegmentsRequestParams) => FetchPromise<SearchSegmentsQuery>

export interface AddContactsFromCRMCategorizationRequests {
  getCRMSourcedSegmentsRequest: GetCRMSourcedSegmentsRequestType
  searchCRMSourcedSegmentsRequest: SearchCRMSourcedSegmentsRequestType
}

export const useAddContactsFromCRMCategorizationRequests = (): AddContactsFromCRMCategorizationRequests => {
  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })

  const getCRMSourcedSegmentsRequest = async ({ pageNumber }: GetCRMSourcedSegmentsRequestParams) =>
    await categorizationClient.query<GetAllItemsQuery, GetAllItemsQueryVariables>({
      query: getAllItems,
      errorPolicy: 'all',
      variables: {
        type: ItemType.SEGMENT,
        subTypes: [ItemSubType.CRM_SOURCED],
        pageNumber,
        pageSize: 50,
      },
    })

  const searchCRMSourcedSegmentsRequest = async ({ search }: SearchCRMSourcedSegmentsRequestParams) =>
    await categorizationClient.query<SearchSegmentsQuery, SearchSegmentsQueryVariables>({
      query: searchSegments,
      errorPolicy: 'all',
      variables: {
        allSegments: true,
        field: 'name',
        type: ItemType.SEGMENT,
        subTypes: [ItemSubType.CRM_SOURCED],
        pageNumber: 0,
        pageSize: 1000,
        query: search,
      },
    })

  return {
    getCRMSourcedSegmentsRequest,
    searchCRMSourcedSegmentsRequest,
  }
}
