import { AutomatedProgramCountsResponse, Program } from '@graphql/types/query-types'
import { Action } from '@interface/Action'
import { DefaultProgramManagerProgram } from '@src/pages/programs/manager/context/DefaultProgramManagerProgram'
import {
  ProgramManagerAPI,
  ProgramManagerErrors,
  ProgramManagerModalsState,
  ProgramManagerValuesAPI,
  ProgramManagerPopupsState,
} from '@src/pages/programs/manager/context/ProgramManager.context'
import { ProgramManagerTabs } from '@src/pages/programs/manager/ProgramManager.constants'
import { NavigationActions } from '@utils/navigation/state/actions'
import actionTypes from '@utils/navigation/state/actionTypes'
import { initialNavigationState } from '@utils/navigation/state/reducer'
import { ProgramErrors } from '@utils/program/program.constants'
import { ProgramState } from '@utils/program/program.constants'

export const DEFAULT_POPUP_STATE: ProgramManagerPopupsState = {
  exitSuccess: false,
  exitFail: false,
  addSuccess: false,
  addFail: false,
  errorSetToPause: false,
  errorSetToRun: false,
  moveToNextSuccess: false,
  moveToNextFail: false,
  clearHistorySuccess: false,
  clearHistoryFail: false,
  saveSuccess: false,
  saveError: false,
  loadError: false,
  copyProgramMessageSuccess: false,
  copyProgramMessageFail: false,
}

const programManagerModalsState: ProgramManagerModalsState = {
  showStartProgram: false,
  showPauseProgram: false,
  showErrors: false,
  showFeedbackBanner: false,
  showAddContactsModal: false,
  showExitContactsModal: false,
  showClearHistory: false,
  showConfirmSave: false,
  showCancelEdit: false,
}

const DEFAULT_PROGRAM_MANAGER_ERRORS: ProgramManagerErrors = {
  programNotFound: false,
  countsNotFound: false,
}

export const DEFAULT_PROGRAM_COUNTS: AutomatedProgramCountsResponse = {
  totalSuppressions: 0,
  totalEarlyExits: 0,
  isHistoryDisabled: false,
  programCounts: {
    enteredContacts: 0,
    earlyExitCount: 0,
    doneCount: 0,
    deletedCount: 0,
  },
  pendingSize: 0,
  reEnterContacts: false,
  programStepCounts: [],
  exitConditions: [],
}

export const DEFAULT_PROGRAM_ERRORS: ProgramErrors = {
  mainErrors: [],
  exitErrors: [],
  stepErrors: [],
}

const values: ProgramManagerValuesAPI = {
  program: DefaultProgramManagerProgram,
  programUrlId: '',
  programCounts: DEFAULT_PROGRAM_COUNTS,
  programStarting: false,
  programErrors: DEFAULT_PROGRAM_ERRORS,
  programManagerErrors: DEFAULT_PROGRAM_MANAGER_ERRORS,
  programManagerModalsState,
  programManagerPopupsState: DEFAULT_POPUP_STATE,
  programState: ProgramState.RUNNING,
  reenterProgramContacts: false,
  hasAOContactsSource: false,
  pendingSize: 0,
  contactSize: 1,
  isEditing: false,
  canEdit: true,
  isNew: false,
  isPausing: false,
  isStarting: false,
  isSaving: false,
  tab: ProgramManagerTabs.SETTINGS,
  subTab: '',
  unsavedChanges: false,
  saveFinal: undefined,
  loading: false,
  saving: false,
  navigationDisabled: false,
  navigateFromCancelModal: false,
  showUpgrade: false,
}

const actions: NavigationActions = {
  showNavigation: () => ({} as Action),
  hideNavigation: () => ({} as Action),
  hideMenu: () => ({} as Action),
  setNavigationPath: (path?: string) => ({
    type: actionTypes.setNavigationPath,
    payload: {
      path,
    },
  }),
  toggleExpanded: (expanded: boolean) => ({
    type: actionTypes.toggleExpanded,
    payload: {
      expanded,
    },
  }),
  toggleDisabled: (disabled: boolean) => ({
    type: actionTypes.toggleDisabled,
    payload: {
      disabled,
    },
  }),
  toggleModalOpened: (modalOpen: boolean) => ({
    type: actionTypes.toggleModalOpened,
    payload: {
      modalOpen,
    },
  }),
}

const ProgramManagerContextFunctions = {
  updateProgramAndSetUnsaved: (program: Program, hasAOContactsSource = false) =>
    // eslint-disable-next-line no-console
    console.log(`Program: ${JSON.stringify(program)}, hasAOContactsSource: ${hasAOContactsSource}`),
  // eslint-disable-next-line no-console
  setLoading: (loading: boolean) => console.log(`Loading ${loading}`),
  // eslint-disable-next-line no-console
  tabChange: (tab: string) => console.log(`New tab: ${tab}`),
  // eslint-disable-next-line no-console
  subTabChange: (subTab: string) => console.log(`New subTab: ${subTab}`),
  // eslint-disable-next-line no-console
  editProgram: () => console.log('Edit program'),
  // eslint-disable-next-line no-console
  cancelEdit: () => console.log('Cancel edit'),
  // eslint-disable-next-line no-console
  saveProgram: () => console.log('Save program'),
  // eslint-disable-next-line no-console
  saveProgramFinal: () => console.log('Save program final'),
  // eslint-disable-next-line no-console
  startProgram: (skipPending: boolean) => console.log(`StartProgrm, skip pending: ${skipPending ? 'true' : 'false'}`),
  // eslint-disable-next-line no-console
  pauseProgram: () => console.log('Pause program'),
  // eslint-disable-next-line no-console
  clearProgramHistory: () => console.log(`Clear program history`),
  // eslint-disable-next-line no-console
  addContactsToProgram: () => console.log('addContactsToProgram'),
  // eslint-disable-next-line no-console
  exitContactsFromProgram: () => console.log('exitContactsToProgram'),
  // eslint-disable-next-line no-console
  moveNext: (stepId: string) => console.log(`Move ${stepId} contacts to next step`),
  // eslint-disable-next-line no-console
  setProgramManagerErrors: (error: keyof ProgramManagerErrors, val: boolean) => console.log(`Program Manager error ${error} is ${val}`),
  // eslint-disable-next-line no-console
  toggleProgramManagerModalsState: (modal: keyof ProgramManagerModalsState) => console.log(`Toggle: ${modal}`),
  // eslint-disable-next-line no-console
  toggleNavigationDisabled: (disabled: boolean) => console.log(`Navigation disabled ${disabled}`),
  // eslint-disable-next-line no-console
  closePopup: (fieldToClose: keyof ProgramManagerPopupsState) => console.log(`Close ${fieldToClose}`),
  // eslint-disable-next-line no-console
  openPopup: (fieldToClose: keyof ProgramManagerPopupsState) => console.log(`Open ${fieldToClose}`),
  // eslint-disable-next-line no-console
  fixErrors: () => console.log('Fixing errors'),
  // eslint-disable-next-line no-console
  refreshProgram: () => console.log('Refreshing program data'),
  /* eslint-enable no-console */
  navigationActions: actions,
  navigation: initialNavigationState,
  // eslint-disable-next-line no-console
  setContainerValues: () => console.log('Updating context'),
}

export const ProgramManagerContextValues: ProgramManagerAPI = {
  values,
  ...ProgramManagerContextFunctions,
}
