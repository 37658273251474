import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react'

import classNames from 'classnames'

import { PersonalizationContainer } from '@complex/Personalization/PersonalizationContainer'
import { getRecipientSrcIds } from '@complex/Personalization/utils/Personalization.utils'
import Button, { ButtonType } from '@components/Button'
import ButtonIcon, { ButtonIconSize, ButtonIconType } from '@components/ButtonIcon/ButtonIcon'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import InfoStatus, { InfoStatusTypes } from '@components/InfoStatus/InfoStatus'
import { LinkTextButton } from '@components/LinkTextButton/LinkTextButton'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Spinner from '@components/Spinner/Spinner'
import { Status } from '@components/StatusToast/StatusToast'
import { SvgNames } from '@components/Svg'
import Svg from '@components/Svg/Svg'
import TextArea from '@components/TextArea/TextArea'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { ModalBodyStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import OptOutLinkSelector from '@src/pages/EmailComposer/components/common/OptOutLinkSelector/OptOutLinkSelector'
import { hasDisplayConditions, stripDisplayConditions } from '@src/pages/EmailComposer/utils/DisplayConditions.utils'
import { detectEmailType, detectWebinarType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { useTextCommands } from '@utils/hooks/useTextCommands'

import { useLoadPlainText } from '../../../hooks/useLoadPlainText'

import './EditPlainTextModal.css'

interface EditPlainTextModalProps {
  className?: string
  dataTest?: string
}

const rootClass = 'edit-plain-text-modal'

const EditPlainTextModal: FC<EditPlainTextModalProps> = (props: EditPlainTextModalProps) => {
  const { dataTest = rootClass, className = rootClass } = props
  const {
    values: {
      isStory,
      isSaving: isSaveInProgress,
      preview: { plainText, loadingPlainText },
      message: { customTextPart, sendto_contacts, sendto_lists, individualRecipientsSourceIds, messageType, webinarConfig },
      message,
      disabledFeatures,
    },
    api: { update, onAutoSave, updateModal },
  } = useContext(EmailComposerContext)

  const [inputValue, setInputValue] = useState(customTextPart || stripDisplayConditions(message, plainText))
  const [showLoading, setShowLoading] = useState(false)
  const [isWaitingForSave, setWaitingForSave] = useState(false)
  const [showClearWarning, setShowClearWarning] = useState(false)
  const [showSaveWarning, setShowSaveWarning] = useState(false)
  const [showPersonalizations, setShowPersonalizations] = useState(false)

  const { isEmailBlankMessage, isEmailTemplate, isEmailABTest, isEmailProgram, isEmailForm } = detectEmailType(messageType)
  const { isAccepted, isPending, isRejected, isDeclined } = detectWebinarType(webinarConfig)

  const isFirstMount = useRef(true)
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const showRegenerateFromHtml = useMemo(() => {
    const hasDynamicContent = hasDisplayConditions(message)
    const isTextChanged = inputValue.trim() !== plainText.trim()
    return hasDynamicContent || isTextChanged
  }, [plainText, inputValue, message])

  const { t } = useTranslation()
  const { undo, redo, clear, insert, reset, isUndoEnabled, isRedoEnabled, onButtonMouseDown, onDropDownToggle, onTextChange } =
    useTextCommands(inputRef)

  const hasDynamicContent = useMemo(() => hasDisplayConditions(message), [message])

  const loadPlainText = useLoadPlainText()

  const handleLoadPlainText = async () => {
    const data = await loadPlainText()
    if (data === undefined) {
      updateModal('statusToast', { message: t('EmailComposer.Preview.Plain.Text.Edit.FetchFailure'), status: Status.FAIL })
      updateModal('editPlainText', undefined, false)
    } else {
      const { plainText } = data
      if (!customTextPart && plainText && plainText !== inputValue) {
        setInputValue(stripDisplayConditions(message, plainText))
      }
    }
  }

  useEffect(() => {
    if (isFirstMount.current) {
      handleLoadPlainText()
      delayedInputFocus()
      isFirstMount.current = false
    }
  }, [])

  const handleClose = () => updateModal('editPlainText', undefined)

  const delayedInputFocus = () => {
    // Give the warning modals time to close
    setTimeout(() => inputRef.current?.focus(), 100)
  }

  const handleSave = () => {
    if (!customTextPart && showRegenerateFromHtml) {
      setShowSaveWarning(true)
    } else {
      handleSaveConfirmation(YesNo.YES)
    }
  }

  const handleSaveConfirmation = async (answer: YesNo) => {
    setShowSaveWarning(false)
    if (answer === YesNo.YES) {
      const newValue = inputValue.trim() !== plainText.trim() ? inputRef.current?.value : ''
      update({ message: { customTextPart: newValue } })

      if (disabledFeatures.autoSave) {
        handleClose()
      } else {
        await onAutoSave()
        setWaitingForSave(true)
        setShowLoading(true)
      }
    }
  }

  useEffect(() => {
    if (!isSaveInProgress && isWaitingForSave) {
      setShowLoading(false)
      setWaitingForSave(false)
      handleClose()
    }
  }, [isSaveInProgress, isWaitingForSave])

  const handleRegenerate = async () => {
    if (hasDynamicContent) {
      reset('')
      handleSaveConfirmation(YesNo.YES)
    } else {
      reset(plainText)
      delayedInputFocus()
    }
  }

  const handleTextChange = () => {
    onTextChange()
    setInputValue(inputRef.current?.value ?? '')
  }

  const handleClear = (answer: YesNo) => {
    answer === YesNo.YES && clear()
    setShowClearWarning(false)
    delayedInputFocus()
  }

  const renderClearModal = () => {
    return (
      <DeleteConfirmationModal
        onAnswer={handleClear}
        title={t('Are you sure?')}
        deleteButtonText={'Clear all'}
        body={t('EmailComposer.Preview.Plain.Text.Edit.ClearModal.Body')}
        isOpen
        dataTest={`${dataTest}-clear-modal`}
      />
    )
  }

  const renderSaveModal = () => {
    return (
      <ConfirmationModal
        title={'Are you sure?'}
        body={
          <>
            <Typography text={t(`EmailComposer.Preview.Plain.Text.Edit.SaveModal.Body`)} {...ModalBodyStyle} />
            <InfoStatus
              svgName={SvgNames.cautionYellow}
              statusText={
                <Typography
                  text={t('EmailComposer.Preview.Plain.Text.Edit.SaveModal.Warning')}
                  tagProps={{ bold: { type: TextType.BODY_TEXT, weight: TextWeight.BOLD } }}
                  {...ModalBodyStyle}
                  inline
                />
              }
              status={InfoStatusTypes.Caution}
            />
          </>
        }
        isYesNo
        yesButtonText={'Confirm'}
        noButtonText={'Continue editing'}
        onAnswer={handleSaveConfirmation}
        isOpen
        dataTest={`${dataTest}-save-modal`}
      />
    )
  }

  const specificIdsFromEmailComposer = useMemo(
    () => getRecipientSrcIds(individualRecipientsSourceIds, sendto_lists),
    [individualRecipientsSourceIds, sendto_lists]
  )

  const disableSegmentFields = isEmailABTest || isEmailForm || isEmailProgram || isAccepted || isPending || isRejected || isDeclined

  const renderPersonalizationModal = () => {
    return (
      <PersonalizationContainer
        isOpen
        disableAddRecipientsButton={!!sendto_contacts?.length}
        specificIds={specificIdsFromEmailComposer}
        closePersonalization={() => setShowPersonalizations(false)}
        doneCallback={(data) => {
          setShowPersonalizations(false)
          data?.value && insert(data.value)
        }}
        isStory={isStory}
        disableListOrSegmentFields={disableSegmentFields}
        disableListOrSegmentFieldsForTemplate={isEmailBlankMessage || isEmailTemplate}
      />
    )
  }

  const renderSaveButton = () => {
    const isEmpty = inputValue.trim() === ''
    const button = (
      <Button buttonType={ButtonType.PRIMARY} onClick={() => handleSave()} disabled={showLoading || isEmpty} dataTest={`${dataTest}-save-button`}>
        {t('Save')}
      </Button>
    )
    return isEmpty ? <Tooltip trigger={button}>{t('EmailComposer.Preview.Plain.Text.Edit.Save.Disabled.Tooltip')}</Tooltip> : button
  }

  const key = customTextPart ? customTextPart : plainText

  return (
    <>
      {showClearWarning && renderClearModal()}
      {showSaveWarning && renderSaveModal()}
      {showPersonalizations && renderPersonalizationModal()}(
      <Modal
        className={classNames(rootClass, className, { [`${rootClass}--hidden`]: showClearWarning || showSaveWarning || showPersonalizations })}
        data-test={dataTest}
        isOpen
        header={
          <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__header`}>
            {t('EmailComposer.Preview.Plain.Text.Edit.Title')}
          </ModalHeader>
        }
      >
        <ModalBody className={`${rootClass}__body`}>
          <div className={`${rootClass}__toolbar`}>
            <div className={`${rootClass}__toolbar__insert`}>
              <Button
                buttonType={ButtonType.FLOAT_TEAL}
                onClick={() => setShowPersonalizations(true)}
                onMouseDown={onButtonMouseDown}
                disabled={showLoading}
                minimalPadding
                dataTest={`${dataTest}-personalize-button`}
              >
                <Svg name={SvgNames.userUnselected} />
                {t('Personalize')}
              </Button>
              <OptOutLinkSelector showLoading={showLoading} insert={insert} onDropDownToggle={onDropDownToggle} />
            </div>
            <div className={`${rootClass}__toolbar__history`}>
              <LinkTextButton
                onClick={() => setShowClearWarning(true)}
                onMouseDown={onButtonMouseDown}
                hideIconLeft
                hideIconRight
                disabled={showLoading}
                dataTest={`${dataTest}-clear-button`}
              >
                {t('Clear all')}
              </LinkTextButton>
              <span className={`${rootClass}__divider`} />
              <Tooltip
                trigger={
                  <ButtonIcon
                    type={ButtonIconType.TERTIARY}
                    icon={isUndoEnabled ? SvgNames.actionUndoEnabled : SvgNames.actionUndoDisabled}
                    size={ButtonIconSize.LARGE}
                    onClick={undo}
                    onMouseDown={onButtonMouseDown}
                    disabled={!isUndoEnabled || showLoading}
                    dataTest={`${dataTest}-undo-button`}
                  />
                }
                position={'bottom'}
              >
                {isUndoEnabled ? t('Undo') : t('EmailComposer.Preview.Plain.Text.Edit.NoUndo')}
              </Tooltip>
              <Tooltip
                trigger={
                  <ButtonIcon
                    type={ButtonIconType.TERTIARY}
                    icon={isRedoEnabled ? SvgNames.actionRedoEnabled : SvgNames.actionRedoDisabled}
                    size={ButtonIconSize.LARGE}
                    onClick={redo}
                    onMouseDown={onButtonMouseDown}
                    disabled={!isRedoEnabled || showLoading}
                    dataTest={`${dataTest}-redo-button`}
                  />
                }
                position={'bottom'}
              >
                {isRedoEnabled ? t('Redo') : t('EmailComposer.Preview.Plain.Text.Edit.NoRedo')}
              </Tooltip>
            </div>
          </div>
          {showLoading ? (
            <Spinner dataTest={`${dataTest}-spinner`} />
          ) : (
            <>
              {hasDynamicContent && (
                <InfoStatus
                  className={`${rootClass}__dynamic-content-warning`}
                  status={InfoStatusTypes.Caution}
                  svgName={SvgNames.cautionYellow}
                  statusText={t(
                    'Dynamic content has been removed from the plain text version of your email. To enable dynamic content in your plain text version, you’ll need to regenerate from HTML.'
                  )}
                />
              )}
              <TextArea
                key={key}
                name={'plainText'}
                inputRef={inputRef}
                value={inputValue}
                onChange={handleTextChange}
                dataTest={`${dataTest}-textarea`}
              />
            </>
          )}
        </ModalBody>
        <ModalFooter footerType={ModalFooterType.Form} className={`${rootClass}__footer`}>
          <div className={`${rootClass}__footer__extra`}>
            {!loadingPlainText && showRegenerateFromHtml ? (
              <Button buttonType={ButtonType.INFO} disabled={showLoading} onClick={handleRegenerate} dataTest={`${dataTest}-regenerate-button`}>
                {t('EmailComposer.Preview.Plain.Text.Edit.Regenerate')}
              </Button>
            ) : null}
          </div>
          <Button buttonType={ButtonType.TERTIARY} onClick={handleClose} dataTest={`${dataTest}-cancel-button`}>
            {t('Cancel')}
          </Button>
          {renderSaveButton()}
        </ModalFooter>
      </Modal>
      )
    </>
  )
}

export default EditPlainTextModal
