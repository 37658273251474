import { gql } from '@apollo/client'

export default gql`
  query getEmailTemplateLayouts($pageNumber: Int, $pageSize: Int, $search: String, $sortDirection: Direction, $layoutsType: String) {
    getEmailTemplateLayouts(pageNumber: $pageNumber, pageSize: $pageSize, search: $search, sortDirection: $sortDirection, layoutsType: $layoutsType) {
      headers {
        id
        text
        html
        title
        isDefault
      }
      footers {
        id
        text
        html
        title
        isDefault
      }
    }
  }
`
