import { SvgNames } from '@components/Svg'
import { TabItemData } from '@components/TabsAO/TabsAO'
import { rootContext } from '@const/globals'
import { DateType } from '@utils/date'

export const MOVE_NEXT_RETRIES = 12 // every 10 seconds for two minutes

export const PROGRAM_LIST_URL = `${rootContext}/classic/programList`
export const REACT_PROGRAM_LIST_URL = `${rootContext}/automation/programs`
export const AP_LIST_URL = `${rootContext}/automation/programs`

export enum ProgramManagerTabs {
  BUILD = 'build',
  JOURNEY_BUILDER = 'journey-builder',
  SETTINGS = 'settings',
  SMS_PERFORMANCE = 'sms-performance',
  EMAIL_PERFORMANCE = 'email-performance',
  STEPS = 'steps',
  PROGRAM_PERFORMANCE = 'program-performance',
  REPORT = 'report',
  EARLY_EXITS = 'early-exits',
  LISTS_SEGMENTS = 'lists-segments',
  PROGRAM_MESSAGES = 'program-messages',
}

export const DEFAULT_PROGRAM_MANAGER_TAB = ProgramManagerTabs.JOURNEY_BUILDER

interface GetProgramManagerTabsStructure {
  t?: (key: string) => string
  hasFlowErrors?: boolean
  hasExitErrors?: boolean
  hasSettingsError?: boolean
  isReportDisabled?: boolean
  hasSms?: boolean
}

export const PROGRAMS_URL = `${rootContext}/automation/programs`

export const getProgramManagerTabsStructure = ({
  t = (key: string) => key,
  hasFlowErrors = false,
  hasExitErrors = false,
  hasSettingsError = false,
  isReportDisabled = false,
  hasSms = true,
}: GetProgramManagerTabsStructure): TabItemData[] => {
  const tabs = [
    {
      index: ProgramManagerTabs.BUILD,
      label: t('Build'),
    },
    {
      index: ProgramManagerTabs.JOURNEY_BUILDER,
      parent: 'build',
      triggerButtonOption: {
        text: t('Program flow'),
        icon: hasFlowErrors ? SvgNames.errorHollow : SvgNames.viewProgram,
        onClick: () => null,
      },
      label: '',
    },
    {
      index: ProgramManagerTabs.PROGRAM_MESSAGES,
      parent: 'build',
      triggerButtonOption: {
        text: t('Program messages'),
        icon: SvgNames.letter,
        onClick: () => null,
      },
      label: '',
    },
    {
      index: ProgramManagerTabs.LISTS_SEGMENTS,
      parent: 'build',
      triggerButtonOption: {
        text: t('Lists & segments'),
        icon: SvgNames.listsAndSegments,
        onClick: () => null,
      },
      label: '',
    },
    {
      index: ProgramManagerTabs.EARLY_EXITS,
      parent: 'build',
      triggerButtonOption: {
        text: t('Early exits'),
        icon: hasExitErrors ? SvgNames.errorHollow : SvgNames.exit,
        onClick: () => null,
      },
      label: '',
    },
    {
      index: ProgramManagerTabs.REPORT,
      label: t('Report'),
      ...(isReportDisabled ? { disabled: true, tooltip: t('You will be able to access reports once the program has been saved.') } : {}),
    },
    {
      index: ProgramManagerTabs.PROGRAM_PERFORMANCE,
      parent: 'report',
      triggerButtonOption: {
        text: t('Program performance'),
        icon: SvgNames.graphBar,
        onClick: () => null,
      },
      label: '',
    },
    {
      index: ProgramManagerTabs.STEPS,
      parent: 'report',
      triggerButtonOption: {
        text: t('Steps'),
        icon: SvgNames.steps,
        onClick: () => null,
      },
      label: '',
    },
    {
      index: ProgramManagerTabs.EMAIL_PERFORMANCE,
      parent: 'report',
      triggerButtonOption: {
        text: t('Email performance'),
        icon: SvgNames.letter,
        onClick: () => null,
      },
      label: '',
    },
    {
      index: ProgramManagerTabs.SETTINGS,
      label: t('Settings'),
      icon: hasSettingsError ? SvgNames.error : undefined,
    },
  ]

  if (hasSms) {
    tabs.push({
      index: ProgramManagerTabs.SMS_PERFORMANCE,
      parent: 'report',
      triggerButtonOption: {
        text: t('SMS performance'),
        icon: SvgNames.sendSms,
        onClick: () => null,
      },
      label: '',
    })
  }

  return tabs
}

export type ProgramManagerRouteProps = {
  location: { state?: ProgramManagerLocationStateProps }
}

export type ProgramManagerLocationStateProps = {
  tab?: string
  subTab?: string
  allConChartType?: string
  allConDateType?: DateType
  allConDateTypeQuantity?: number
  allContactsSortColumn?: string
  allContactsSortDesc?: boolean
  allContactsPageSize?: number
  allContactsPageIndex?: number
  inProgramSortColumn?: string
  inProgramSortDesc?: boolean
  inProgramPageSize?: number
  inProgramPageIndex?: number
  earlyExitSortColumn?: string
  earlyExitSortDesc?: boolean
  earlyExitPageSize?: number
  earlyExitPageIndex?: number
  standardExitSortColumn?: string
  standardExitSortDesc?: boolean
  standardExitPageSize?: number
  standardExitPageIndex?: number
  emailTabSortColumn?: string
  emailTabSortDesc?: boolean
}
