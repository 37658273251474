import React, { FC, KeyboardEvent, ReactNode, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import Loader from '@components/Loader'
import Modal, { ModalBody, ModalFooterAction } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import ModalFooterV2 from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import ModalHeaderV2 from '@components/Modal/components/ModalHeaderV2/ModalHeaderV2'
import ScrollArea from '@components/ScrollArea/ScrollArea'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { FtpFileDto } from '@graphql/types/microservice/entity-upload-types'
import { formatDateWithAbbreviations } from '@utils/date'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'
import { editElement } from '@utils/utils'

import './SelectFtpFileWithSearchModal.css'

export interface FileSelectWithSearchModalProps {
  className?: string
  dataTest?: string
  isOpen: boolean
  onClose: () => void
  onAction: (selectedFile: FtpFileDto) => void
  files: FtpFileDto[]
  loading: boolean
  headerText?: string
  footerActionElement?: ReactNode
  description?: string
}

interface FileRow {
  file: FtpFileDto
  selected: boolean
  index: number
}
interface State {
  selectedFile?: FtpFileDto
  searchTerm: string
  rows: FileRow[]
}
const rootClass = 'file-select-with-search-modal'

const SelectFtpFileWithSearchModal: FC<FileSelectWithSearchModalProps> = (props: FileSelectWithSearchModalProps) => {
  const {
    dataTest = rootClass,
    className = '',
    isOpen,
    onClose,
    onAction,
    files,
    loading = true,
    headerText = 'Select a file',
    footerActionElement,
    description,
  } = props
  const { t } = useTranslation()
  const [state, setState] = useState<State>({
    searchTerm: '',
    rows: [],
  })
  const { selectedFile, searchTerm, rows } = state
  const update = useDeepUpdate(setState)

  const filteredRows = useMemo(() => {
    if (searchTerm) {
      return rows.filter(({ file }) => (file.name ?? '').toLowerCase().includes(searchTerm.toLowerCase()))
    }
    return rows
  }, [rows, searchTerm])

  const handleSearch = (newTerm: string) => {
    update({ searchTerm: newTerm })
  }

  useEffect(() => {
    update({ rows: files.map((file, index) => ({ file, index, selected: false })) })
  }, [files])

  const onSubmitClick = () => {
    if (selectedFile) {
      onAction(selectedFile)
    }
  }

  const onKeyDown = (event: KeyboardEvent<HTMLDivElement>, row: FileRow) => {
    if (event.key === ' ' || event.key === 'Enter') {
      onAction(row.file)
    }
  }

  const handleFileClick = (file: FtpFileDto, index: number) => {
    const allRowsAsUnselected = rows.map((row) => ({ ...row, selected: false }))
    update({ selectedFile: file, rows: editElement(index, { selected: true }, allRowsAsUnselected) })
  }

  const renderFileList = () => {
    return filteredRows.map(({ file, selected, index }) => (
      <div
        className={classNames(`${rootClass}__file`, { [`${rootClass}__file-selected`]: selected })}
        key={file.name ?? index}
        onClick={() => handleFileClick(rows[index].file, index)}
        role={'option'}
        aria-selected={selected}
        tabIndex={0}
        onKeyDown={(event) => onKeyDown(event, rows[index])}
      >
        <Svg name={SvgNames.csvFile} type={SvgType.LARGE_ICON} fill={SvgColor.ICON_GRAY} />
        <div className={`${rootClass}__file-text`}>
          <Typography text={file.name} type={TextType.BODY_TEXT} weight={TextWeight.MEDIUM} />
          <Typography text={formatDateWithAbbreviations(file.timestamp, true)} type={TextType.BODY_TEXT_SMALL_LIGHT} />
        </div>
      </div>
    ))
  }

  const modalHeader = (
    <ModalHeaderV2
      className={`${rootClass}__header`}
      headerType={ModalHeaderType.List}
      headerText={t(headerText)}
      searchProps={{
        placeholder: t('Search files'),
        onChangeHandler: (value) => handleSearch(value),
        incomingValue: '',
        className: `${className}__search`,
        canClear: true,
        dataTest: `${dataTest}-search`,
      }}
    />
  )

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={modalHeader}>
      <ScrollArea showOnEvent={'always'} className={`${rootClass}__scroll`}>
        <ModalBody className={`${rootClass}__body`}>
          {searchTerm && !filteredRows.length && (
            <EmptyListing
              imgSrc={StaticImageNames.emptySearch}
              size={EmptyListingSize.MEDIUM}
              text={t('SelectFtpFileWithSearchModal.Search.EmptyState.Text')}
              headline={t('No results found')}
              withoutBorder
            />
          )}
          {searchTerm && filteredRows.length !== 0 && (
            <Typography
              className={`${rootClass}__files-search-header`}
              text={t('SelectFtpFileWithSearchModal.Search.Results')}
              values={{ count: filteredRows.length, searchTerm: searchTerm }}
              weight={TextWeight.BOLD}
            />
          )}
          {description && <Typography text={description} type={TextType.BODY_TEXT_LIGHT} className={`${rootClass}__description`} />}
          <div className={`${rootClass}__files`}>{loading ? <Loader className={`${rootClass}__loader`} /> : renderFileList()}</div>
        </ModalBody>
      </ScrollArea>
      <ModalFooterV2
        footerType={ModalFooterType.List}
        className={`${rootClass}__footer`}
        customContent={<ModalFooterAction>{footerActionElement}</ModalFooterAction>}
        buttons={{
          actionButtonOnClick: onSubmitClick,
          actionButtonLabel: t('Select'),
          actionButtonDisabled: !selectedFile,
          cancelButtonLabel: t('Cancel'),
        }}
        onClose={onClose}
      />
    </Modal>
  )
}

export default SelectFtpFileWithSearchModal
