import React from 'react'

import { ApolloClient } from '@apollo/client'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import getAllAccountProvisioningData from '@graphql/microservices/segment/getAllAccountProvisioningData'
import getAllSegmentGrowth from '@graphql/microservices/segment/getAllSegmentGrowth'
import getDataCards from '@graphql/microservices/segment/getDataCards'
import {
  GetAllAccountProvisioningDataQuery,
  GetAllAccountProvisioningDataQueryVariables,
  GetAllSegmentGrowthQuery,
  GetAllSegmentGrowthQueryVariables,
  GetDataCardsQuery,
  GetDataCardsQueryVariables,
  GroupBy,
  StatValueDto,
} from '@graphql/types/microservice/segment-types'
import { State, TotalContacts } from '@src/pages/ContactSegments/components/DataCardsContainer/DataCardsContainer'
import { QuarterValues } from '@src/pages/ContactSegments/components/DataCardsContainer/utils/DataCardsContainerConstants'
import { PERIOD_GROUPED_BY } from '@utils/contactSegments/contactSegments.utils'
import { DataCardsModalInfo } from '@utils/contactSegments/contactSegments.utils'
import { TotalContactsModalInfo } from '@utils/contactSegments/context/ContactSegmentsContext'
import { chartDateDataBuilder, PERIOD_MODAL, textDropDown } from '@utils/contactSegments/dataCards.utils'
import { DateType, formatSendTSDate } from '@utils/date'
import { formatNumber } from '@utils/numbers'

export const getActiveContactsUtils = (setState: Function, client: ApolloClient<any>) => {
  client
    .query<GetDataCardsQuery, GetDataCardsQueryVariables>({
      query: getDataCards,
      fetchPolicy: 'network-only',
    })
    .then(({ data }) => {
      if (data.getDataCards) {
        const { used = 0, available = 0, remainingDays, isCPMAccount } = data.getDataCards
        setState((state: State) => {
          return {
            ...state,
            activeContacts: { actualValue: used, total: available, isCPMAccount: isCPMAccount, remainingDays: remainingDays },
            loading: false,
          }
        })
      }
    })
    .catch(() => {
      setState((state: State) => ({ ...state, loading: false }))
    })
}

const getDailyDate = (date: number) => new Date(date).toLocaleString('en-us', { month: 'short', day: 'numeric' })

const getMonthlyDate = (date: number) => new Date(date).toLocaleString('en-us', { month: 'short' })

const getQuarterDate = (date: number) => {
  const month = new Date(date).toLocaleString('en-us', { month: 'short' })
  return Object.keys(QuarterValues).find((key) => QuarterValues[key].includes(month))
}

const getPeriodDate = (date: number, groupBy: PERIOD_GROUPED_BY) => {
  const PeriodDates: { [key in PERIOD_GROUPED_BY]: Function } = {
    DAILY: () => getDailyDate(date),
    MONTHLY: () => getMonthlyDate(date),
    QUARTER: () => getQuarterDate(date),
    YEARLY: () => undefined,
  }
  return PeriodDates[groupBy]()
}

export const getTotalContactsUtils = (setState: Function, client: ApolloClient<any>, amount: number, groupBy: PERIOD_GROUPED_BY) => {
  client
    .query<GetAllSegmentGrowthQuery, GetAllSegmentGrowthQueryVariables>({
      query: getAllSegmentGrowth,
      fetchPolicy: 'network-only',
      variables: {
        amount,
        groupBy: groupBy as unknown as GroupBy,
      },
    })
    .then(({ data }) => {
      if (data.getAllSegmentGrowth) {
        let chartData: TotalContacts = {
          name: [],
          y: [],
        }
        const description = data.getAllSegmentGrowth.description
        const labelValue = data.getAllSegmentGrowth.value === null ? 0 : data.getAllSegmentGrowth.value
        const labelPercentage = data.getAllSegmentGrowth.percentageValue === null ? 0 : data.getAllSegmentGrowth.percentageValue
        data.getAllSegmentGrowth.values?.map((dataValue: StatValueDto | undefined) => {
          if (dataValue) {
            const { date, value } = dataValue
            const formattedDate = getPeriodDate(date, groupBy)
            chartData = { ...chartData, name: [...chartData.name, formattedDate], y: [...chartData.y, value] }
          }
        })
        if (chartData.y.length <= amount) {
          const filledArray = [...new Array(amount - chartData.y.length).fill(0), ...chartData.y]
          chartData = { ...chartData, y: filledArray }
        }
        chartData = {
          ...chartData,
          name: amount === 7 ? chartDateDataBuilder(DateType.DAY, 7) : chartDateDataBuilder(DateType.MONTH, amount, true),
        }
        const dataList = chartData.y.map((_value: number, index: number) => [chartData.name[index], chartData.y[index]])
        setState((state: State) => {
          return { ...state, totalContacts: { chartData: dataList, labelValue, labelPercentage, description }, loading: false }
        })
      }
    })
}

export const getAllAccountProvisioningUtils = (
  client: ApolloClient<any>,
  amount: number,
  groupBy: PERIOD_GROUPED_BY,
  setState: Function,
  caller: PERIOD_MODAL
) => {
  setState((containerValues: State) => ({ ...containerValues, loadingChart: true }))
  client
    .query<GetAllAccountProvisioningDataQuery, GetAllAccountProvisioningDataQueryVariables>({
      query: getAllAccountProvisioningData,
      fetchPolicy: 'network-only',
      variables: {
        amount,
        groupBy: groupBy as unknown as GroupBy,
      },
    })
    .then(({ data }) => {
      const { text, value } = textDropDown[caller]
      let chartData: DataCardsModalInfo = {
        name: text,
        categories: [],
        contactsUsed: [],
        lastUpdated: '',
      }
      if (data.getAllAccountProvisioningData?.values && data.getAllAccountProvisioningData.values.length > 0) {
        const date = data.getAllAccountProvisioningData.date
        chartData = { ...chartData, lastUpdated: formatSendTSDate(date, { includeTime: true }) }
        data.getAllAccountProvisioningData.values?.map((dataValue) => {
          if (dataValue) {
            const { used } = dataValue
            chartData = {
              ...chartData,
              contactsUsed: [...chartData.contactsUsed, used || 0],
            }
          }
        })
        if (chartData.contactsUsed.length <= value) {
          const filledArray = [...new Array(value - chartData.contactsUsed.length).fill(0), ...chartData.contactsUsed]
          chartData = { ...chartData, contactsUsed: filledArray }
        }
        chartData = {
          ...chartData,
          categories:
            (textDropDown[caller].text as PERIOD_MODAL) === PERIOD_MODAL.Week
              ? chartDateDataBuilder(DateType.DAY, 7)
              : chartDateDataBuilder(DateType.MONTH, textDropDown[caller].value),
        }
        setState((containerValues: State) => ({
          ...containerValues,
          dataCardsModalInfo: chartData,
          loadingChart: false,
          dataCardsModalEmptyState: false,
        }))
      } else {
        setState((containerValues: State) => ({
          ...containerValues,
          dataCardsModalInfo: chartData,
          dataCardsModalEmptyState: true,
          loadingChart: false,
        }))
      }
    })
}

export const getModalTotalContactsUtils = (
  client: ApolloClient<any>,
  amount: number,
  groupBy: PERIOD_GROUPED_BY,
  setState: Function,
  caller: PERIOD_MODAL
) => {
  setState((containerValues: State) => ({ ...containerValues, loadingChart: true }))
  client
    .query<GetAllSegmentGrowthQuery, GetAllSegmentGrowthQueryVariables>({
      query: getAllSegmentGrowth,
      fetchPolicy: 'network-only',
      variables: {
        amount,
        groupBy: groupBy as unknown as GroupBy,
      },
    })
    .then(({ data }) => {
      const { text, value } = textDropDown[caller]
      let chartData: TotalContactsModalInfo = {
        name: text,
        categories: [],
        contactsUsed: [],
        lastUpdated: formatSendTSDate(data.getAllSegmentGrowth?.date, { includeTime: true }),
      }
      if (data.getAllSegmentGrowth && data.getAllSegmentGrowth.values && data.getAllSegmentGrowth.values.length > 0) {
        data.getAllSegmentGrowth.values?.map((dataValue) => {
          if (dataValue) {
            const { value } = dataValue
            chartData = {
              ...chartData,
              contactsUsed: [...chartData.contactsUsed, value || 0],
            }
          }
        })
        if (chartData.contactsUsed.length <= value) {
          const filledArray = [...new Array(value - chartData.contactsUsed.length).fill(0), ...chartData.contactsUsed]
          chartData = { ...chartData, contactsUsed: filledArray }
        }
        chartData = {
          ...chartData,
          categories:
            (textDropDown[caller].text as PERIOD_MODAL) === PERIOD_MODAL.Week
              ? chartDateDataBuilder(DateType.DAY, 7)
              : chartDateDataBuilder(DateType.MONTH, textDropDown[caller].value),
        }
        setState((containerValues: State) => ({
          ...containerValues,
          dataCardsModalInfo: chartData,
          loadingChart: false,
          dataCardsModalEmptyState: false,
        }))
      } else {
        setState((containerValues: State) => ({
          ...containerValues,
          dataCardsModalInfo: chartData,
          dataCardsModalEmptyState: true,
          loadingChart: false,
        }))
      }
    })
}

export const renderTotalContactsLabelTooltip = (t: Function, labelValue: number, labelPercentage: number, description: string) => {
  return (
    <>
      <div>
        <Typography text={`${t('You have')} `} type={TextType.BODY_TEXT_WHITE_SMALL} lineHeight={LineHeight.VERY_TINY} inline />
        <Typography
          text={formatNumber(labelValue)}
          type={TextType.BODY_TEXT_WHITE_SMALL}
          weight={TextWeight.BOLD}
          lineHeight={LineHeight.VERY_TINY}
          inline
        />
        <Typography text={` ${t('total contacts.')}`} type={TextType.BODY_TEXT_WHITE_SMALL} lineHeight={LineHeight.VERY_TINY} inline />
      </div>
      <Typography
        text={` ${labelPercentage > 0 ? '+' : ''}${labelPercentage.toFixed(2)}% `}
        type={TextType.BODY_TEXT_WHITE_SMALL}
        weight={TextWeight.BOLD}
        lineHeight={LineHeight.VERY_TINY}
        inline
      />
      <Typography
        text={`${t('compared to the past')} ${description}`}
        type={TextType.BODY_TEXT_WHITE_SMALL}
        lineHeight={LineHeight.VERY_TINY}
        inline
      />
    </>
  )
}

export const renderActiveContactsLabelTooltip = (t: Function, actualValue: number, activeContactsPercentage: number) => {
  return (
    <>
      <div>
        <Typography text={`${t('You’ve used')} `} type={TextType.BODY_TEXT_WHITE_SMALL} lineHeight={LineHeight.VERY_TINY} inline />
        <Typography text={actualValue} type={TextType.BODY_TEXT_WHITE_SMALL} weight={TextWeight.BOLD} lineHeight={LineHeight.VERY_TINY} inline />
        <Typography
          text={` (${activeContactsPercentage.toFixed(2)}%) ${t('of your')} `}
          type={TextType.BODY_TEXT_WHITE_SMALL}
          lineHeight={LineHeight.VERY_TINY}
          inline
        />
      </div>
      <Typography text={t('active contacts this month.')} type={TextType.BODY_TEXT_WHITE_SMALL} lineHeight={LineHeight.VERY_TINY} inline />
    </>
  )
}
