import { gql } from '@apollo/client'

export default gql`
  query getContactsFromCrmEmails($listId: String!, $crmEmails: [String!]) {
    getContactsFromCrmEmails(listId: $listId, crmEmails: $crmEmails) {
      id
      name
      email
    }
  }
`
