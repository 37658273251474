import React, { FC, useContext, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import classNames from 'classnames'

import ButtonWithLoader from '@components/ButtonWithLoader/ButtonWithLoader'
import { Status } from '@components/StatusToast/StatusToast'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { useCreateIncomingWebhookModalRequests } from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/utils/CreateIncomingWebhookModal.graphQL'
import {
  renderAuthenticationContainer,
  renderInfoTooltip,
} from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/utils/CreateIncomingWebhookModal.utils'
import InputWithCopy from '@src/pages/Webhooks/IncomingWebhooks/components/InputWithCopy/InputWithCopy'
import StatusMessage from '@src/pages/Webhooks/IncomingWebhooks/components/StatusMessage/StatusMessage'
import { IncomingWebhooksContext } from '@src/pages/Webhooks/IncomingWebhooks/IncomingWebhooksContext'

import './IncomingWebhookTokenAuthentication.css'

interface IncomingWebhookTokenAuthenticationProps {
  className?: string
  dataTest?: string
  token?: string
  hasInvalidToken?: boolean
}

const rootClass = 'incoming-webhook-token-authentication'
const rootTranslation = 'Incoming.Webhooks.CreateModal.Authentication.Token'

const IncomingWebhookTokenAuthentication: FC<IncomingWebhookTokenAuthenticationProps> = (props: IncomingWebhookTokenAuthenticationProps) => {
  const { dataTest = rootClass, className = '', token = '', hasInvalidToken } = props

  const { update } = useContext(IncomingWebhooksContext)

  const [loading, setLoading] = useState(false)

  const {
    register,
    setValue,
    formState: { dirtyFields },
  } = useFormContext()

  const { t } = useTranslation()

  const { generateToken } = useCreateIncomingWebhookModalRequests()

  const hasNewToken = !!dirtyFields.authentication?.token

  useEffect(() => {
    register('authentication.token')
  }, [])

  const onChange = (token: string) => {
    setValue('authentication.token', token, { shouldValidate: true, shouldDirty: true })
  }

  const onGenerateToken = () => {
    setLoading(true)
    generateToken()
      .then((token) => {
        onChange(token)
      })
      .catch(() => {
        update({
          statusToast: {
            status: Status.FAIL,
            statusMessage: 'There was an error generating your token. Please try again.',
          },
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onCopyToClipboard = () => {
    update({
      statusToast: {
        status: Status.SUCCESS,
        statusMessage: 'Incoming.Webhooks.Toast.CopyToken',
      },
    })
  }

  const renderBearerLabel = () => (
    <div className={`${rootClass}__input-label`}>
      <Typography text={t(`Bearer token`)} weight={TextWeight.MEDIUM} inline />
      {renderInfoTooltip(t(`${rootTranslation}.Input.Tooltip`))}
    </div>
  )

  const renderRecentlyGeneratedToken = () => <InputWithCopy dataTest={`${dataTest}-input`} onCopy={onCopyToClipboard} value={token} />

  const renderPreviouslyGeneratedToken = () => <Typography text={t(`${rootTranslation}.AlreadyGenerated`)} type={TextType.BODY_TEXT_LIGHT} />

  return (
    <div className={classNames(rootClass, className, { [`${rootClass}__token-generated`]: token })} data-test={dataTest}>
      {token && hasNewToken && (
        <StatusMessage
          className={`${rootClass}__success-message`}
          header={t(`${rootTranslation}.Message.Header`)}
          subheader={t(`${rootTranslation}.Message.Subheader`)}
          hasSmallHeader
        />
      )}
      {token && !loading ? (
        <div className={`${rootClass}__token-container`}>
          {renderBearerLabel()}
          {hasNewToken ? renderRecentlyGeneratedToken() : renderPreviouslyGeneratedToken()}
        </div>
      ) : (
        renderAuthenticationContainer({
          loading,
          text: t(`${rootTranslation}.Container.${loading ? 'Generating' : hasInvalidToken ? 'Error' : 'Empty'}`),
          className: `${rootClass}__empty-container`,
          dashed: !token,
          error: hasInvalidToken,
        })
      )}
      <ButtonWithLoader dataTest={`${dataTest}-generate`} className={`${rootClass}__button-generate`} loading={loading} onClick={onGenerateToken}>
        <Svg name={SvgNames.refresh} type={SvgType.ICON} />
        {t(token ? `Regenerate token` : `Generate token`)}
      </ButtonWithLoader>
    </div>
  )
}

export default IncomingWebhookTokenAuthentication
