import React, { FC, useMemo, useState } from 'react'

import classNames from 'classnames'

import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { useTranslation } from '@const/globals'
import { getSMSStatusOptions } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/SMSExpression/SMSExpression.utils'
import { countries } from '@utils/countries'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'

import './SMSExpression.css'

interface SMSExpressionProps {
  className?: string
  dataTest?: string
}

interface SMSExpressionState {
  smsStatus: SelectV2SingleOption
  selectedCountries: SelectV2SingleOption[]
}
const rootClass = 'sms-expression'
const smsStatusIndex = 0
const maxMenuHeight = 240

const SMSExpression: FC<SMSExpressionProps> = (props: SMSExpressionProps) => {
  const { dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const smsStatusOptions = getSMSStatusOptions(t)
  const defaultSMSStatus = smsStatusOptions[smsStatusIndex]

  const [state, setState] = useState<SMSExpressionState>({
    smsStatus: defaultSMSStatus,
    selectedCountries: [],
  })
  const { smsStatus, selectedCountries } = state
  const update = useDeepUpdate(setState)

  const countryOptions = useMemo(() => {
    return Object.keys(countries).map((country) => ({ label: t(`Country.${country}`), value: country }))
  }, [countries])

  const renderNextCondition = () => {
    if (smsStatus.extraOptions) {
      const { nextConditionType } = smsStatus.extraOptions
      if (nextConditionType === 'COUNTRY') {
        return (
          <SelectV2
            dataTest={`${dataTest}-country-select`}
            className={`${rootClass}__country-select`}
            placeholder={t('Select countries')}
            options={countryOptions}
            value={selectedCountries}
            maxMenuHeight={maxMenuHeight}
            onChangeMultiple={(selectedCountries) => update({ selectedCountries })}
            showGroupsWithoutLabel
            truncateMultiValues
            truncateOptions
            minSearchOptions={1}
          />
        )
      }
    }
    return null
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <SelectV2
        dataTest={`${dataTest}-status-select`}
        className={`${rootClass}__status-select`}
        options={smsStatusOptions}
        defaultValue={smsStatus}
        onChange={(smsStatus) => update({ smsStatus: { ...smsStatus, extraOptions: smsStatus?.extraOptions } })}
        isClearable={false}
        isSearchable={false}
      />
      {renderNextCondition()}
    </div>
  )
}

export default SMSExpression
