import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import Accordion, { AccordionItemData } from '@components/Accordion/Accordion'
import Button, { ButtonType } from '@components/Button'
import Input from '@components/Input'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { ModalBodyStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { TemplateIdDescription } from '@graphql/types/mutation-types'
import { ProgramAssetType } from '@utils/program/program.constants'

import './CreateProgramTemplateModal.css'

export interface ProgramAsset {
  id: string
  title: string
  description?: string
  type: ProgramAssetType
}

interface CreateProgramTemplateModalProps {
  isOpen: boolean
  title: string
  assets: ProgramAsset[]
  onClose: () => void
  onAction: (title: string, descriptionMapping: TemplateIdDescription[]) => void
  className?: string
  dataTest?: string
}

interface CreateProgramTemplateModalState {
  localAssets: ProgramAsset[]
  localTitle: string
  canSave: boolean
}

const MAX_DESCRIPTION_LENGTH = 140

const rootClass = 'create-program-template-modal'

const CreateProgramTemplateModal: FC<CreateProgramTemplateModalProps> = (props: CreateProgramTemplateModalProps) => {
  const { title, assets, dataTest = rootClass, className = '', onAction, onClose, isOpen } = props
  const [state, setState] = useState<CreateProgramTemplateModalState>({
    localAssets: assets.map((asset) => ({ ...asset, description: asset.description ?? '' })),
    localTitle: title,
    canSave: false,
  })
  const { localAssets, localTitle } = state

  const { t } = useTranslation()

  useEffect(() => {
    const canSave = localTitle !== '' && localAssets.reduce((acc, curr) => acc && curr.description !== '', true)
    setState({ ...state, canSave })
  }, [localTitle, localAssets])

  const handleAction = () => {
    setState({ ...state, localAssets })
    onAction(
      localTitle,
      localAssets.map((asset) => ({
        id: asset.id,
        description: asset.description ?? '',
      }))
    )
  }

  const handleClose = () => {
    setState({ ...state, localAssets: [] })
    onClose()
  }

  const onChangeAssetDescription = (id: string, description: string) => {
    const updatedAssets = localAssets.map((asset) => {
      return asset.id === id ? { ...asset, description } : { ...asset }
    })
    setState({ ...state, localAssets: updatedAssets })
  }

  const onChangeTitle = (localTitle: string) => setState({ ...state, localTitle })

  const header = (
    <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__header`}>
      {t('Save Program as Template')}
    </ModalHeader>
  )

  const getAccordionData = (): AccordionItemData[] => {
    return [
      {
        index: 'main',
        header: (
          <div className={`${rootClass}__accordion-header`}>
            <div className={`${rootClass}__accordion-header-title`}>
              <Typography text={t('Describe template assets')} weight={TextWeight.MEDIUM} />
              <Tooltip trigger={<Svg name={SvgNames.info} />} triggerClassName={`${rootClass}__accordion-header-title-icon`}>
                {t('CreateProgramTemplate.assets.tooltip')}
              </Tooltip>
            </div>
            <div>
              <Typography
                text={t('CreateProgramTemplate.assets.count', { count: assets.length })}
                type={TextType.BODY_TEXT_LIGHT}
                dataTest={`${dataTest}-asset-count`}
              />
            </div>
          </div>
        ),
        content: (
          <div className={`${rootClass}__asset-row-container`}>
            {assets.map((asset, idx) => (
              <div key={asset.id} className={`${rootClass}__asset-row`} data-test={`${dataTest}-asset-row`}>
                <div className={`${rootClass}__asset-row-left`}>
                  <Svg
                    name={
                      asset.type === ProgramAssetType.EMAIL
                        ? SvgNames.emailSolid
                        : asset.type === ProgramAssetType.SEGMENT
                        ? SvgNames.segment
                        : SvgNames.uploaded
                    }
                    type={SvgType.ICON}
                  />
                  <Tooltip
                    triggerClassName={`${rootClass}__asset-row-left-title`}
                    trigger={
                      <Typography
                        text={asset.title}
                        type={TextType.BODY_TEXT_SMALL}
                        weight={TextWeight.MEDIUM}
                        className={classNames(`${rootClass}__asset-row-left-title`, 'ellip')}
                        dataTest={`${dataTest}-asset-row-title`}
                      />
                    }
                  >
                    {asset.title}
                  </Tooltip>
                </div>
                <Input
                  value={asset.description}
                  maxlength={MAX_DESCRIPTION_LENGTH}
                  dataTest={`${dataTest}-asset-${idx}`}
                  onChange={({ target: { value } }) => onChangeAssetDescription(asset.id, value)}
                  className={`${rootClass}__asset-row-input`}
                  placeholder={t('CreateProgramTemplate.assets.placeholder')}
                />
              </div>
            ))}
          </div>
        ),
      },
    ]
  }

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
      <ModalBody className={`${rootClass}__body`}>
        <Typography text={t('CreateProgramTemplate.main.text')} {...ModalBodyStyle} />
        <Input
          label={`${t('Template name')} (${t('required')})`}
          placeholder={t('Give this new template a name')}
          value={localTitle}
          required
          onChange={({ target: { value } }) => onChangeTitle(value)}
          className={`${rootClass}__template-name`}
          dataTest={`${dataTest}-template-name`}
        />
        <Accordion
          childData={getAccordionData()}
          chevronLeft
          className={`${rootClass}__accordion`}
          defaultValue={'main'}
          chevronFillColor={SvgColor.LIGHT_GRAY}
        />
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form} className={`${rootClass}__footer`}>
        <Button buttonType={ButtonType.TERTIARY} onClick={handleClose} dataTest={`${dataTest}-button-tertiary`}>
          {t('Close')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} onClick={handleAction} disabled={!state.canSave} dataTest={`${dataTest}-button-primary`}>
          {t('Save template')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CreateProgramTemplateModal
