import { useApolloClient } from '@apollo/client'
import { FilterQueryParams } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import getAllItems from '@graphql/microservices/categorization/getAllItems'
import getRecentItems from '@graphql/microservices/categorization/getRecentItems'
import getFormPreview from '@graphql/queries/getFormPreview'
import {
  GetAllItemsQuery,
  GetAllItemsQueryVariables,
  GetRecentItemsQuery,
  GetRecentItemsQueryVariables,
} from '@graphql/types/microservice/categorization-types'
import { GetFormPreviewQuery, GetFormPreviewQueryVariables } from '@graphql/types/query-types'
import { ItemType } from '@utils/categorization'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export interface FormsAndTemplatesPickerRequests {
  getFormPreviewRequest: (variables: GetFormPreviewQueryVariables) => FetchPromise<GetFormPreviewQuery>
  getSuggestedFormsRequest: (search: string, params?: FilterQueryParams) => FetchPromise<GetRecentItemsQuery>
  getAllItemsRequest: (type: ItemType, search: string, params?: FilterQueryParams) => FetchPromise<GetAllItemsQuery>
}

enum ConditionOperator {
  And = 'AND',
  Or = 'OR',
}
export const useFormsAndTemplatesPickerRequests = (): FormsAndTemplatesPickerRequests => {
  const actonClient = useApolloClient()
  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })

  const getFormPreviewRequest = async (variables: GetFormPreviewQueryVariables) =>
    await actonClient.query<GetFormPreviewQuery, GetFormPreviewQueryVariables>({
      query: getFormPreview,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables,
    })

  const getSuggestedFormsRequest = async (search: string, params?: FilterQueryParams) =>
    await categorizationClient.query<GetRecentItemsQuery, GetRecentItemsQueryVariables>({
      query: getRecentItems,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        ...params,
        type: ItemType.FORM,
        query: search,
        subTypes: ['new', 'activeForm', 'noPhase3'],
        days: 180,
        subTypeCondition: {
          subType: {
            itemType: ItemType.FORM,
            name: 'new',
          },
          conditionOperator: ConditionOperator.And,
          subTypeCondition: {
            subType: {
              itemType: ItemType.FORM,
              name: 'activeForm',
            },
            conditionOperator: ConditionOperator.And,
            subTypeCondition: {
              subType: {
                itemType: ItemType.FORM,
                name: 'noPhase3',
              },
              conditionOperator: ConditionOperator.And,
            },
          },
        },
      },
    })

  const getAllItemsRequest = async (type: ItemType, search: string, params?: FilterQueryParams) => {
    return await categorizationClient.query<GetAllItemsQuery, GetAllItemsQueryVariables>({
      query: getAllItems,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        ...params,
        type,
        query: search,
      },
    })
  }

  return {
    getFormPreviewRequest,
    getSuggestedFormsRequest,
    getAllItemsRequest,
  }
}
