import React, { FC, useContext, useEffect } from 'react'
import { Prompt, useHistory } from 'react-router-dom'

import { ComposerHeaderProps } from '@complex/GlobalComposerHeader/components/ComposerHeader/ComposerHeader'
import { ComposerHeaderBottomProps } from '@complex/GlobalComposerHeader/components/ComposerHeaderBottom/ComposerHeaderBottom'
import GlobalComposerHeader from '@complex/GlobalComposerHeader/GlobalComposerHeader'
import { ButtonType } from '@components/Button'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import { SplitButtonType } from '@components/SplitButton/SplitButton'
import StatusToast from '@components/StatusToast/StatusToast'
import { SvgNames } from '@components/Svg'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { rootContext, undefinedReturnedFunction, useTranslation } from '@const/globals'
import FirstSegmentSaveModal from '@src/pages/SegmentComposer/components/FirstSegmentSaveModal/FirstSegmentSaveModal'
import SegmentComposerBuild from '@src/pages/SegmentComposer/components/SegmentComposerBuild/SegmentComposerBuild'
import { SegmentComposerSettings } from '@src/pages/SegmentComposer/components/SegmentComposerSettings/SegmentComposerSettings'
import SegmentErrorsModal from '@src/pages/SegmentComposer/components/SegmentErrorsModal/SegmentErrorsModal'
import { SaveType, SegmentComposerSearchParam, SegmentComposerTab, tabByError } from '@src/pages/SegmentComposer/SegmentComposer.constants'
import { SegmentComposerContext } from '@src/pages/SegmentComposer/SegmentComposer.context'

import './SegmentComposer.css'

interface SegmentComposerProps {
  dataTest?: string
}

const rootClass = 'segment-composer'

export const SegmentComposer: FC<SegmentComposerProps> = (props: SegmentComposerProps) => {
  const { dataTest = rootClass } = props
  const { t } = useTranslation()
  const {
    onSaveClick,
    values: {
      currentTab,
      errors,
      isClosing,
      isSaving,
      segment,
      showErrorsModal,
      showFirstSaveModal,
      statusToast,
      hasUnsavedChanges,
      statusToast: { status, showStatusToast, statusMessage },
    },
    update,
  } = useContext(SegmentComposerContext)
  const { name, lastModifiedDate } = segment

  const history = useHistory()

  const showPrompt = hasUnsavedChanges && !isClosing && !isSaving

  const onTabChange = (tab: string) => update({ currentTab: tab })

  const renderBuildContent = () => <SegmentComposerBuild />
  const renderSettingsContent = () => <SegmentComposerSettings />
  const renderReviewContent = () => <Typography text={t('Review')} />

  const closePage = (answer = YesNo.YES) => {
    if (answer === YesNo.YES) {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const backTo = urlSearchParams.get(SegmentComposerSearchParam.BACK_TO) ?? rootContext
      history.push(backTo)
    } else {
      update({ isClosing: false })
    }
  }

  const onCloseClick = () => {
    update({ isClosing: true })
  }

  const headerTopProps: ComposerHeaderProps = {
    logoLink: 'segments',
    splitButtonType: SplitButtonType.PRIMARY,
    splitButtonOptions: [
      { key: 'save', title: 'Save', onClick: () => onSaveClick({ type: SaveType.SAVE }) },
      { key: 'saveClose', title: 'Save and close', onClick: () => onSaveClick({ type: SaveType.SAVE_AND_CLOSE }) },
      { key: 'saveCopy', title: 'Save as copy', onClick: () => onSaveClick({ type: SaveType.SAVE_AS_COPY }), disabled: !segment.id },
    ],
    closeButtonProps: { type: ButtonType.WHITE, text: 'Close', onClick: onCloseClick },
    hasSaveIndicator: true,
    lastEdited: lastModifiedDate,
    hasUnsavedChanges: hasUnsavedChanges,
    hasPopoverCard: false,
    isAutoSaveDisabled: true,
    isSaving: isSaving,
    inlineEditingProps: {
      title: name,
      onChange: () => undefined,
      onSave: (value: string) => update({ segment: { ...segment, name: value }, hasUnsavedChanges: true }),
    },
  }

  const headerBottomProps: ComposerHeaderBottomProps = {
    ...(!segment.isDirectSelect && {
      floatButtons: [
        {
          text: t('Test contact'),
          icon: SvgNames.test,
          disabled: false,
          onClick: undefinedReturnedFunction,
        },
      ],
    }),
    tabsProps: {
      defaultValue: currentTab,
      onChange: onTabChange,
      childData: [
        { index: SegmentComposerTab.BUILD, label: t('Build'), content: renderBuildContent() },
        { index: SegmentComposerTab.SETTINGS, label: t('Settings'), content: renderSettingsContent() },
        { index: SegmentComposerTab.REVIEW, label: t('Review'), content: renderReviewContent() },
      ],
    },
  }

  const onFirstSaveModalClose = () => {
    update({ showFirstSaveModal: false })
  }

  const onErrorsModalClose = () => {
    update({ showErrorsModal: false })
  }

  const onFixErrors = () => {
    if (errors.size > 0) {
      update({ showErrorsModal: false, currentTab: tabByError[Array.from(errors)[0]] })
    }
  }

  const onStatusToastClose = () => {
    update({ statusToast: { ...statusToast, showStatusToast: false } })
  }

  useEffect(() => {
    const showCustomPrompt = (event: Event) => {
      if (showPrompt) {
        event.preventDefault()
        return true
      }
    }

    window.addEventListener('beforeunload', showCustomPrompt)
    return () => {
      window.removeEventListener('beforeunload', showCustomPrompt)
    }
  }, [showPrompt])

  useEffect(() => {
    if (isClosing && !hasUnsavedChanges) {
      closePage()
    }
  }, [hasUnsavedChanges, history, isClosing])

  return (
    <div className={rootClass} data-test={dataTest}>
      <ConfirmationModal
        isOpen={isClosing && hasUnsavedChanges}
        titleIcon={SvgNames.warning}
        title={t('You have unsaved changes')}
        body={t('SegmentComposer.ConfirmationModal.UnsavedChanges.Body')}
        cancelButtonText={t('Keep editing')}
        deleteButtonText={t('Close anyways')}
        onAnswer={closePage}
        isDelete
      />
      <Prompt when={showPrompt} message={t('SegmentComposer.Prompt.Message')} />
      {showStatusToast && (
        <StatusToast
          message={<Typography text={statusMessage} tagProps={{ bold: { weight: TextWeight.BOLD } }} inline />}
          closeStatus={onStatusToastClose}
          status={status}
        />
      )}
      <SegmentErrorsModal onClose={onErrorsModalClose} onFixErrors={onFixErrors} isOpen={showErrorsModal} />
      {showFirstSaveModal && <FirstSegmentSaveModal onClose={onFirstSaveModalClose} isOpen />}
      <GlobalComposerHeader dataTest={dataTest} headerProps={headerTopProps} headerBottomProps={headerBottomProps} />
    </div>
  )
}
