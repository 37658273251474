import React, { FC, useCallback, useState } from 'react'

import classNames from 'classnames'

import DisplayToggleCardToggletip, { DisplayToggleCardToggletipProps } from '@components/DisplayToggleCard/components/DisplayToggleCardToggletip'
import HoverSVG, { HoverFillColors } from '@components/HoverSVG/HoverSVG'
import { SvgColor, SvgType } from '@components/Svg/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'

import './DisplayToggleCard.css'

export interface DisplayToggleCardProps {
  label: string
  toggletipProps: DisplayToggleCardToggletipProps
  defaultOpen?: boolean
  onClick?: (open: boolean) => void
  className?: string
  dataTest?: string
  showIconTooltip?: string
  hideIconTooltip?: string
}

const rootClass = 'display-toggle-card'

const DisplayToggleCard: FC<DisplayToggleCardProps> = ({
  label,
  toggletipProps,
  defaultOpen = false,
  onClick,
  className,
  dataTest = rootClass,
  showIconTooltip,
  hideIconTooltip,
}) => {
  const [open, setOpen] = useState<boolean>(defaultOpen)

  const handleClick = useCallback(
    () =>
      setOpen((open) => {
        onClick?.(!open)
        return !open
      }),
    [onClick]
  )

  const ShowHideIcon = () => {
    return (
      <HoverSVG
        className={`${rootClass}__icon`}
        dataTest={`${dataTest}-svg`}
        svg={open ? SvgNames.show : SvgNames.hideAlt}
        hoverSvg={open ? SvgNames.show : SvgNames.hideAlt}
        type={SvgType.VERY_LARGE_ICON}
        hoverFillColor={HoverFillColors.TEXT_TEAL}
        fill={open ? SvgColor.TEXT_GRAY : SvgColor.TAB_GRAY}
        allowPropagation
      />
    )
  }

  return (
    <button
      className={classNames(rootClass, className, 'flex-align-center', { [`${rootClass}__close`]: !open })}
      data-test={dataTest}
      onClick={handleClick}
    >
      <div className={`${rootClass}__label`}>
        <Typography
          text={label}
          type={open ? undefined : TextType.BODY_TEXT_LIGHT}
          weight={TextWeight.MEDIUM}
          className="push-left"
          dataTest={`${dataTest}-label`}
        />
        <DisplayToggleCardToggletip dataTest={`${dataTest}-toggletip`} {...toggletipProps} />
      </div>
      {showIconTooltip && hideIconTooltip ? (
        <Tooltip trigger={<ShowHideIcon />}>{open ? showIconTooltip : hideIconTooltip}</Tooltip>
      ) : (
        <ShowHideIcon />
      )}
    </button>
  )
}

export default DisplayToggleCard
