import { gql } from '@apollo/client'

export default gql`
  query getAccountDetails {
    getAccountDetails {
      url
      companyName
    }
  }
`
