import React, { FC, useContext, useMemo } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Container from '@components/Container'
import SectionHeadline from '@components/SectionHeadline'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import { TableColumn } from '@components/Table/Table'
import TableWithEmptyListing from '@components/TableWithEmptyListing/TableWithEmptyListing'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import { Program } from '@graphql/types/query-types'
import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'
import { getStandardFormattedDate } from '@utils/date'
import { ProgramState } from '@utils/program/program.constants'

import { DEFAULT_SCHEDULE } from '../../ProgramDashboard.constants'
import ProgramOptions from '../ProgramOptions/ProgramOptions'
import { ProgramOptionDetails } from '../ProgramOptions/ProgramOptions.contants'
import ProgramSchedule from '../ProgramSchedule/ProgramSchedule'

import './ProgramSettings.css'

export enum ProgramScheduleType {
  TRIGGERED = 'TRIGGERED',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  NEVER = 'NEVER',
}

export interface Schedule {
  scheduleType: ProgramScheduleType
  interval?: number
  weekdaysOnly?: boolean
  hour?: number
  minute?: number
  ampm?: number
  hasEndTime?: boolean
  endHour?: number
  endMinute?: number
  endAmpm?: number
}

export interface ProgramOption {
  allowLooping?: boolean
  allowBypassFatigueRules?: boolean
  allowReentrantAddresses?: boolean
  suppressBadEmails?: boolean
  historyDisabled?: boolean
  useAlternateUniqueField?: boolean
  sforceNote?: boolean
}

export interface ContactSource {
  list: string
  records: number
}

interface Props {
  program?: Program
  loading: boolean
  isShownInBuilder?: boolean
  isShownInManager?: boolean
  className?: string
  dataTest?: string
}

const rootClass = 'program-settings'

const ProgramSettings: FC<Props> = (props: Props) => {
  const {
    values: { program: programFromContext, programUrlId, programCounts, programState },
  } = useContext(ProgramManagerContext)
  const { program: programFromProps, loading, isShownInBuilder, isShownInManager, className = '', dataTest = rootClass } = props
  const { t } = useTranslation()

  // TODO: Remove programFromProps when AJB is merged into Dashboard
  const program = programFromProps ?? programFromContext

  const {
    id: programId,
    name: programName,
    creator: createdBy,
    createdOn,
    startedOn,
    lastUpdated,
    lastEditedBy,
    description,
    timeZoneId: timeZone,
    sourceList,
    alternateUniqueFieldHeader: alternateUniqueFieldName,
  } = program

  const schedule =
    { ...program.schedule, scheduleType: (program.schedule?.type as ProgramScheduleType) ?? ProgramScheduleType.NEVER } ?? DEFAULT_SCHEDULE

  const options = {
    allowLooping: program.allowLooping,
    allowBypassFatigueRules: program.allowBypassFatigueRules,
    allowReentrantAddresses: program.allowReentrantAddresses,
    sforceNote: program.sforceNote,
    suppressBadEmails: program.suppressBadEmails,
    historyDisabled: program.historyDisabled,
    useAlternateUniqueField: program.useAlternateUniqueField,
  }

  const { totalSuppressions: suppressedCount } = programCounts

  const contactSources =
    sourceList.length === 0
      ? [
          {
            list: t('Source(s) not set'),
            records: '-',
          },
        ]
      : sourceList
          .map((source) => ({
            list: source.name,
            records: source?.count ?? 0,
          }))
          .sort((a, b) => (a.list.toLowerCase() > b.list.toLowerCase() ? 1 : -1))

  const columns: TableColumn[] = useMemo(
    () => [
      {
        Header: 'Source List/Segments',
        accessor: 'list',
        align: 'left',
        className: `${rootClass}__source-col`,
        minWidth: 390,
        flexColumn: true,
      },
      {
        Header: 'Records',
        accessor: 'records',
        align: 'right',
        className: `${rootClass}__source-col`,
        minWidth: 120,
        maxWidth: 120,
      },
    ],
    []
  )

  const openSuppressed = () => {
    return true
  }

  const renderSidebarDetail = (label: string, value: string, tooltip?: string, isItalic?: boolean, extraText?: string) => (
    <div className={classNames(`${rootClass}__detail`)}>
      {tooltip && (
        <>
          <Typography weight={TextWeight.MEDIUM} text={label} type={TextType.BODY_TEXT_SMALL} inline />
          <Tooltip
            trigger={<Svg name={SvgNames.info} type={SvgType.ICON} className={`${rootClass}__info-icon`} />}
            triggerClassName={`${rootClass}__detail-label`}
          >
            {tooltip}
          </Tooltip>
        </>
      )}
      {!tooltip && <Typography weight={isItalic ? TextWeight.ITALIC : TextWeight.MEDIUM} text={label} type={TextType.BODY_TEXT_SMALL} />}
      <Typography text={value} type={TextType.BODY_TEXT_LIGHT} />
      {extraText && (
        <Typography
          text={extraText}
          type={TextType.BODY_TEXT_SMALL_LIGHT}
          lineHeight={LineHeight.MEDIUM}
          className={`${rootClass}__detail-extra-text`}
        />
      )}
    </div>
  )

  const renderProgramButton = () =>
    programState === ProgramState.RUNNING ? (
      <Tooltip
        fullWidth
        trigger={
          <span>
            <Button buttonType={ButtonType.PRIMARY} fullWidth disabled className={`${rootClass}__button-disabled`}>
              {t('Edit settings')}
            </Button>
          </span>
        }
      >
        {t('You can only view a running program. Please pause the program to edit it.')}
      </Tooltip>
    ) : (
      <Button
        buttonType={ButtonType.PRIMARY}
        dataTest={`${rootClass}__edit-settings-button`}
        isLink
        linkState={{
          currentTab: 0,
        }}
        to={`${rootContext}/programs/${programId}/edit`}
        fullWidth
      >
        {t('Edit Settings')}
      </Button>
    )

  const optionKeyValues = ProgramOptionDetails.map((detail) => options[detail.optionKey])
  const isOptionSelected = optionKeyValues.reduce((acc, cur) => acc || cur)

  const suppressedLink = isShownInManager
    ? `${rootContext}/automation/programs/${programUrlId}/suppressed`
    : `${rootContext}/programs/${programId}/suppressed`

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__left-container`}>
        <Container className={`${rootClass}__sources-container`} noSidePadding dataTest={`${dataTest}-contact-sources`} title={'Contact Sources'}>
          <div className={`${rootClass}__section-header`}>
            {!isShownInBuilder && !isShownInManager && suppressedCount === 0 && (
              <Typography
                type={TextType.BODY_TEXT_SMALL_LIGHT}
                lineHeight={LineHeight.MEDIUM}
                text={`${suppressedCount} ${t('suppressed contacts')}`}
                inline
              />
            )}
            {!isShownInBuilder && !isShownInManager && suppressedCount > 0 && (
              <div>
                <Svg className={`${rootClass}__section-header__link-icon`} name={SvgNames.userOff} type={SvgType.ICON} fill={SvgColor.TEXT_TEAL} />
                <Button
                  buttonType={ButtonType.TEXT}
                  onClick={openSuppressed}
                  inline
                  to={suppressedLink}
                  isLink
                  dataTest={`${dataTest}-suppressed-link`}
                >
                  <Typography
                    type={TextType.LINK_LARGE}
                    weight={TextWeight.MEDIUM}
                    lineHeight={LineHeight.MEDIUM}
                    text={`${suppressedCount} ${t('suppressed contacts')}`}
                    inline
                  />
                </Button>
              </div>
            )}
          </div>
          <TableWithEmptyListing
            data={contactSources}
            loading={loading}
            columns={columns}
            className={`${rootClass}__table`}
            data-test={`${dataTest}`}
            text={t('No sources')}
            canPaginate={contactSources.length > 10}
            manualPagination={false}
            initialState={{ pageSize: 10, pageIndex: 0 }}
          />
        </Container>
        <Container className={`${rootClass}__schedule-container`} title={'Contacts from Source(s) will enter the Program'}>
          <ProgramSchedule schedule={schedule} timeZone={timeZone} />
        </Container>
        <Container className={`${rootClass}__options-container`} noBottom title={'Options'}>
          <Typography
            text={isOptionSelected ? `${t(`You've enabled these options for this program`)}:` : t('No options selected')}
            type={TextType.BODY_TEXT_SMALL_LIGHT}
          />
          <ProgramOptions options={options} alternateUniqueFieldName={alternateUniqueFieldName} />
        </Container>
        {false && (
          <Container title={'Change History'}>
            <Typography text={'Created by John Doe on August 10, 2020'} lineHeight={LineHeight.MEDIUM_LARGE} />
            <Typography text={'Last edited by John Doe on August 10, 2020'} type={TextType.BODY_TEXT_LIGHT} lineHeight={LineHeight.MEDIUM_LARGE} />
          </Container>
        )}
      </div>
      <div className={`${rootClass}__right-container`}>
        {!isShownInBuilder && !isShownInManager && renderProgramButton()}
        {(isShownInBuilder || isShownInManager) && <SectionHeadline>{t('Program Details')}</SectionHeadline>}
        <div className={`${rootClass}__program-details`}>
          {renderSidebarDetail(t('Program name'), programName)}
          {description?.trim() && renderSidebarDetail(t('Program Description'), description)}
          {renderSidebarDetail(
            t('Timezone'),
            timeZone,
            'This time zone is used to interpret all schedule option times and all scheduled delay times in this program.',
            false
          )}
          {renderSidebarDetail(t('Created'), `${getStandardFormattedDate(createdOn)} ${t('by')} ${createdBy}`)}
          {renderSidebarDetail(
            t('Started'),
            startedOn === 0 ? t('Not Started') : getStandardFormattedDate(startedOn),
            t('Date the program status was most recently changed to Running'),
            startedOn === 0
          )}
          {renderSidebarDetail(t('Last updated'), `${getStandardFormattedDate(lastUpdated)} ${lastEditedBy ? `${t('by')} ${lastEditedBy}` : ''}`)}
        </div>
      </div>
    </div>
  )
}

export default ProgramSettings
