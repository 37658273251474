import React, { FC } from 'react'

import Button, { ButtonType } from '@components/Button'
import CollapsibleBanner from '@components/CollapsibleBanner/CollapsibleBanner'
import { LinkTextButton } from '@components/LinkTextButton/LinkTextButton'
import { SvgNames, SvgType } from '@components/Svg'
import Typography from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'

import './UpgradeAssistantBanner.css'

interface UpgradeAssistantBannerProps {
  dataTest?: string
  toggleBanner: (collapse: boolean) => void
  onDismiss: () => void
}

const rootClass = 'upgrade-assistant-banner'

const LEARN_MORE_LINK = 'https://connect.act-on.com/hc/en-us/articles/21310851698327-Act-On-Contacts-Upgrade-Helper-Copy-Segments'
const UPGRADE_ASSISTANT_URL = `${rootContext}/upgradeAssistant`

const UpgradeAssistantBanner: FC<UpgradeAssistantBannerProps> = (props: UpgradeAssistantBannerProps) => {
  const { dataTest = rootClass, toggleBanner, onDismiss } = props
  const { t } = useTranslation()

  const renderBody = (
    <div className={`${rootClass}__body`}>
      <Typography text={t('UpgradeAssistant.Banner.Body.Text')} />
      <LinkTextButton text={t('UpgradeAssistant.Banner.Body.LearnMore')} hideIconLeft link={LEARN_MORE_LINK} />
    </div>
  )

  const renderFooter = (
    <div className={`${rootClass}__footer`}>
      <Button dataTest={`${dataTest}-button-upgrade`} buttonType={ButtonType.PRIMARY} isLink to={UPGRADE_ASSISTANT_URL}>
        {t('UpgradeAssistant.Banner.Footer.Button.Upgrade')}
      </Button>
      <Button dataTest={`${dataTest}-button-dismiss`} className={`${rootClass}__actions-done`} buttonType={ButtonType.FLOAT_TEAL} onClick={onDismiss}>
        {t('Dismiss')}
      </Button>
    </div>
  )

  return (
    <div>
      <CollapsibleBanner
        dataTest={dataTest}
        className={rootClass}
        hideCollapsedContent
        body={renderBody}
        footer={renderFooter}
        title={t('Upgrade to Audience Center')}
        icon={SvgNames.lightBulbNoBackground}
        iconType={SvgType.LARGE_ICON}
        toggleCollapse={toggleBanner}
      />
    </div>
  )
}

export default UpgradeAssistantBanner
