export const enum SelectionType {
  ROWS,
  SUBROWS,
  MIXED,
}

export const enum SORT_DIRECTION {
  asc,
  desc,
}

export const enum Selection {
  Checkbox = 'cellCheckbox',
  Radio = 'cellRadio',
}

export const enum ActionType {
  Header = 'header',
  Row = 'row',
  FolderOrTag = 'folderOrTag',
}
