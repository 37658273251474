import React, { FC, useState } from 'react'

import classNames from 'classnames'

import { Checkbox } from '@components/Checkbox/Checkbox'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import Typography, { ModalBodyStyle, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './ClearHistoryModal.css'

interface Props {
  allowReentrantAddresses: boolean
  isOpen: boolean
  showReentrantCheckbox: boolean
  onClearHistory: (allowReentrantAddresses: boolean) => void
  onClose: () => void
  className?: string
  dataTest?: string
}

const rootClass = 'clear-history-modal'

const ClearHistoryModal: FC<Props> = (props: Props) => {
  const { allowReentrantAddresses, showReentrantCheckbox, isOpen, onClearHistory, onClose, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const [showConfirmContent, setShowConfirmContent] = useState(false)
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(allowReentrantAddresses)

  const clearHistory = () => {
    onClearHistory(isCheckboxChecked)
  }

  const closeModal = () => {
    setShowConfirmContent(false)
    onClose()
  }

  const onContinue = () => setShowConfirmContent(true)

  const renderMainContent = () => {
    return (
      <>
        <Typography
          text={t(
            'This action will reset the program history and dashboard reports. Sent Messages will retain their data. All contacts will be removed, but they will keep their activity history for program-related events.'
          )}
          {...ModalBodyStyle}
        />
        {showReentrantCheckbox && (
          <div className={`${rootClass}__checkbox`}>
            <Checkbox
              label={t('Allow contacts to re-enter the program')}
              checked={isCheckboxChecked}
              onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
              dataTest={`${dataTest}-checkbox`}
            />
          </div>
        )}
      </>
    )
  }

  const renderContinueContent = () => (
    <>
      <Typography text={t('You cannot undo this action.')} {...ModalBodyStyle} weight={TextWeight.BOLD} dataTest={`${rootClass}-cannot-undo`} />
      <Typography text={t('Are you sure you want to continue?')} {...ModalBodyStyle} className={`${rootClass}__confirm-continue`} />
    </>
  )

  const bodyContent = !showConfirmContent ? renderMainContent() : renderContinueContent()

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title={`${t('Clear Program History')}?`}
      body={bodyContent}
      isDelete
      cancelButtonText={t('Cancel')}
      deleteButtonText={!showConfirmContent ? t('Continue') : t('Yes, clear history')}
      onAnswer={(answer) => {
        if (answer === YesNo.YES) {
          !showConfirmContent ? onContinue() : clearHistory()
        } else {
          closeModal()
        }
      }}
      className={classNames(rootClass, className)}
      data-test={dataTest}
    />
  )
}

export default ClearHistoryModal
