import { legacyActonContext, rootContext } from '@const/globals'

export const DRAFTS_COMPOSER_URL = `${legacyActonContext}/_compose/start.jsp`
export const DRAFTS_BUILDER_URL = `${rootContext}/classic/if/legacy/emailMessage`
export const DRAFTS_BEE_COMPOSER_URL = `${rootContext}/content/emails`

export const openDraftsComposerWindow = (windowName = '') => {
  return window.open(`${DRAFTS_COMPOSER_URL}?folderId=-1`, windowName)
}

export const openDraftEditorWindow = (draftId: string, windowName = '', isBeeComposer?: boolean, prevPageParam?: string) => {
  const URL = isBeeComposer ? `${DRAFTS_BEE_COMPOSER_URL}/${draftId}/content?prevPage=${prevPageParam}` : `${DRAFTS_BUILDER_URL}?id=${draftId}`
  return window.open(URL, windowName)
}

export interface DraftBuilderMessage {
  emailId: string
  emailTitle: string
  isEditing: boolean
}

export const isDraftBuilderMessage = (data: MessageEvent<any>['data']) => data instanceof Object && 'emailId' in data && 'emailTitle' in data
