import React, { FC, useMemo } from 'react'

import classNames from 'classnames'

import DropdownWithToggles from '@components/DropdownWithToggles/DropdownWithToggles'
import { DropdownWithTogglesProps } from '@components/DropdownWithToggles/DropdownWithToggles.mocks'
import PreviewAssetModal, { PreviewModalSize } from '@components/PreviewAssetModal/PreviewAssetModal'
import StatusToast from '@components/StatusToast/StatusToast'
import { useTranslation } from '@const/globals'
import { TrueOpenFilter } from '@src/pages/reports/EmailPerformance/EmailPerformanceContainer'
import LiveboardContainer from '@src/pages/reports/Liveboard/LiveboardContainer'
import { Action, RuntimeFilterOp } from '@thoughtspot/visual-embed-sdk'
import { RuntimeFilter } from '@thoughtspot/visual-embed-sdk/lib/src/react/all-types-export'
import { useAccountSettings } from '@utils/account/account.utils'

import './EmailPerformance.css'

interface EmailPerformanceProps {
  liveboardId?: string
  loading: boolean
  previewTitle: string
  previewHtml?: string
  showPreviewRequestError: boolean
  queryError?: string
  previewAction: (msgId: string, previewTitle: string) => void
  closePreview: () => void
  closeError: () => void
  trueOpenFilter: TrueOpenFilter
  trueOpenCurrentValue: string
  trueOpenToggles: DropdownWithTogglesProps['toggles']
  toggleTrueOpen: (toggleId: TrueOpenFilter, value: boolean) => void
  className?: string
  dataTest?: string
}

const TRUE_OPEN_MORE_INFO_LINK =
  'https://success.act-on.com/acton/attachment/9883/u-37089073-16fa-4eb8-bbff-7d39a2bfb487/0/-/-/-/-/True%20Open%20%22learn%20more%22%20link%20inside%20runtime%20filter'

const rootClass = 'email-performance'

const EmailPerformance: FC<EmailPerformanceProps> = (props: EmailPerformanceProps) => {
  const {
    liveboardId,
    loading,
    previewHtml,
    previewTitle,
    // Commenting out until issues are sorted
    // previewAction,
    closePreview,
    showPreviewRequestError,
    trueOpenFilter,
    trueOpenCurrentValue,
    trueOpenToggles,
    toggleTrueOpen,
    closeError,
    queryError,
    dataTest = rootClass,
    className = '',
  } = props

  const { enableEmailPerformanceTrueOpenFilter } = useAccountSettings()
  const { t } = useTranslation()

  const visibleActions = useMemo(() => ['show-message-preview' as Action], [])

  const runtimeFilters: RuntimeFilter[] | undefined = useMemo(() => {
    return trueOpenFilter === TrueOpenFilter.PRIVACY
      ? [
          {
            columnName: 'True Open',
            operator: RuntimeFilterOp.NOT_IN,
            values: ['Privacy System'],
          },
        ]
      : trueOpenFilter === TrueOpenFilter.SECURITY
      ? [
          {
            columnName: 'True Open',
            operator: RuntimeFilterOp.NOT_IN,
            values: ['Security System'],
          },
        ]
      : trueOpenFilter === TrueOpenFilter.ALL
      ? [
          {
            columnName: 'True Open',
            operator: RuntimeFilterOp.IN,
            values: ['Person'],
          },
        ]
      : undefined
  }, [trueOpenFilter])

  const headerExtra = useMemo(() => {
    const onChange = (toggleId: string, value: boolean) => {
      toggleTrueOpen(toggleId as TrueOpenFilter, value)
    }

    return enableEmailPerformanceTrueOpenFilter ? (
      <DropdownWithToggles
        toggles={trueOpenToggles}
        onChange={onChange}
        currentValue={trueOpenCurrentValue}
        name={t('True Open')}
        moreInfoLink={TRUE_OPEN_MORE_INFO_LINK}
        className={`${rootClass}__true-open`}
        dataTest={`${dataTest}-true-open`}
      />
    ) : undefined
  }, [enableEmailPerformanceTrueOpenFilter, t, toggleTrueOpen, trueOpenToggles])

  return (
    <>
      {showPreviewRequestError && <StatusToast message={t('Unable to load message preview')} closeStatus={closeError} />}
      <PreviewAssetModal
        isOpen={!!previewHtml}
        title={previewTitle}
        previewHtml={previewHtml}
        size={PreviewModalSize.MEDIUM}
        onClose={closePreview}
        className={`${rootClass}__preview`}
        dataTest={`${dataTest}-preview`}
      />
      <LiveboardContainer
        liveboardId={liveboardId}
        title={t('Email Performance')}
        visibleActions={visibleActions}
        headerExtra={headerExtra}
        runtimeFilters={runtimeFilters}
        // previewAction={previewAction}
        loading={loading}
        queryError={queryError}
        className={classNames(rootClass, className)}
        data-test={dataTest}
      />
    </>
  )
}

export default EmailPerformance
