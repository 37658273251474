import { gql } from '@apollo/client'

export default gql`
  mutation loginAsChild($parentAccountId: Long!, $accountId: Long!, $userId: Long!, $email: String) {
    loginAsChild(parentAccountId: $parentAccountId, accountId: $accountId, userId: $userId, email: $email) {
      domain
      loginKey
      redirectLink
    }
  }
`
