import React, { FC, useMemo, useState } from 'react'

import classNames from 'classnames'

import RowActions from '@components/RowActions/RowActions'
import SvgNames from '@components/Svg/SvgNames'
import { RowAction } from '@components/Table/Table'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import { formatNumber } from '@utils/numbers'
import { TagRowActions } from '@utils/tags'

import './Tag.css'

interface Props {
  className?: string
  dataTest?: string
  onClick: (tag: LabelDto) => void
  tag: LabelDto
  isSelected: boolean
  onActionClick?: (tag: LabelDto, action: TagRowActions) => void
}

const rootClass = 'tag'

const Tag: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, tag, onClick, isSelected, onActionClick, className = '' } = props
  const [isCursorHovering, setIsCursorHovering] = useState<boolean>(false)

  const actions: RowAction[] = useMemo(
    () =>
      onActionClick
        ? [
            {
              label: 'Edit',
              icon: SvgNames.pencil,
              hasTooltip: true,
              onClick: () => onActionClick && onActionClick(tag, TagRowActions.EDIT),
            },
            {
              label: 'Delete',
              icon: SvgNames.delete,
              hasTooltip: true,
              onClick: () => onActionClick && onActionClick(tag, TagRowActions.DELETE),
            },
          ]
        : [],
    [tag]
  )

  return (
    <div
      role={'button'}
      tabIndex={0}
      data-test={dataTest}
      className={classNames(rootClass, className, { [`${rootClass}-hover`]: isCursorHovering, [`${rootClass}--selected`]: isSelected })}
      onMouseEnter={() => setIsCursorHovering(true)}
      onFocus={() => setIsCursorHovering(true)}
      onBlur={() => setIsCursorHovering(false)}
      onMouseLeave={() => setIsCursorHovering(false)}
      onClick={() => onClick(tag)}
      onKeyDown={(keyDownEvent) => (keyDownEvent.key === ' ' || keyDownEvent.key === 'Enter' ? onClick(tag) : undefined)}
    >
      <div className={`${rootClass}-title ellip`}>
        <div className={`${rootClass}-color`} style={{ backgroundColor: tag.color }} />
        <TextWithTooltipOnEllip
          typographyProps={{ text: tag.name, weight: isSelected ? TextWeight.BOLD : TextWeight.REGULAR }}
          tooltipProps={{ children: tag.name }}
        />
      </div>
      <Typography
        text={formatNumber(tag.itemCount || 0)}
        type={isSelected ? TextType.BODY_TEXT : TextType.BODY_TEXT_LIGHT}
        weight={isSelected ? TextWeight.BOLD : TextWeight.REGULAR}
      />
      {isCursorHovering && onActionClick && (
        <div className={`${rootClass}__actions-container`}>
          <RowActions actions={actions} dataTest={`tag-row-actions`} />
        </div>
      )}
    </div>
  )
}

export default Tag
