import React, { FC, useCallback, useContext, useEffect, useMemo, useRef } from 'react'

import classNames from 'classnames'

import { ViewSelectedMedia } from '@components/AssetPickers/MediaPickerModal/components/ViewSelectedMedia'
import { MediaItem, MediaPickerModalContext, getMediaFilters } from '@components/AssetPickers/MediaPickerModal/MediaPickerModal.context'
import {
  getMediaEmptyListingProps,
  getMediaRowActions,
  renderMediaSearchTableColumns,
  renderMediaTableColumns,
} from '@components/AssetPickers/MediaPickerModal/MediaPickerModal.table'
import FolderSearch, { FolderSearchProps } from '@components/FolderSearch/FolderSearch'
import { TableV2 } from '@components/TableV2/TableV2'
import { TableV2Props } from '@components/TableV2/tableV2TS/interfaces'
import { getRowSelectionState } from '@components/TableV2/utils/commonUtils'
import { Row, RowSelectionState } from '@tanstack/react-table'
import { useTranslation } from '@utils/const/globals'
import { allMediaFilesFilter, allMediaFilter } from '@utils/filter'

const rootClass = 'media-picker-modal'

type MediaPickerModalTableContainerProps = {
  className?: string
  isCssOrJs?: boolean
}

export const MediaPickerModalTableContainer: FC<MediaPickerModalTableContainerProps> = ({ className, isCssOrJs }) => {
  const { update, onLoadNextPage, ...state } = useContext(MediaPickerModalContext)
  const contentRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()

  const {
    searchText,
    data,
    selectedItem,
    selectedItems,
    folders,
    loading,
    filterLoading,
    allItemsLoaded,
    activeFilter,
    activeFolder,
    isViewingSelected,
    sortingBy,
  } = state

  const selectionState = useMemo<RowSelectionState>(
    () => getRowSelectionState(isCssOrJs ? (selectedItems?.length ? selectedItems : []) : selectedItem ? [selectedItem] : []),
    [isCssOrJs, selectedItem, selectedItems]
  )

  const emptyListingProps = useMemo(
    () =>
      getMediaEmptyListingProps(
        state,
        () => update({ activeFilter: isCssOrJs ? allMediaFilter.name : allMediaFilesFilter.name, activeSubTypes: [], activeFolder: undefined }),
        t
      ),
    [state, update, t]
  )

  const handleSearchTable = useCallback((inAllFolders: boolean) => update({ searchAllData: inAllFolders }), [update])

  useEffect(() => {
    if (!searchText) {
      update({ searchAllData: false })
    }
  }, [searchText, update])

  const columns = useMemo(() => {
    if (searchText) {
      return renderMediaSearchTableColumns(searchText, folders, t)
    } else {
      return renderMediaTableColumns(t)
    }
  }, [searchText, folders, t])

  const onRowSelectionChanged = (rowIds: string[], rows: Row<MediaItem>[]) => {
    if (rows.length && !filterLoading) {
      if (isCssOrJs) {
        const selectedMedia = rowIds.reduce((selectedMedia: MediaItem[], rowId) => {
          const row = rows.find(({ id }) => id === rowId)
          return row ? [...selectedMedia, row.original] : selectedMedia
        }, [])

        const combinedSelections = [
          ...(selectedItems ?? []).filter((item) => !data.some((contact) => contact.id === item.id)),
          ...selectedMedia,
        ].filter((item, index, array) => array.findIndex((contact) => contact.id === item.id) === index)

        if (combinedSelections.length !== selectedItems?.length || !combinedSelections.every((item, index) => item.id === selectedItems[index]?.id)) {
          update({ selectedItems: combinedSelections })
        }
      } else {
        const selectedItemRow = rows.find(({ id }) => id === rowIds[0])
        update({ selectedItem: selectedItemRow?.original })
      }
    }
  }

  const tableV2Props: TableV2Props<MediaItem> = {
    columns,
    data: filterLoading ? [] : data,
    rowUniqueIdKey: 'id',
    loading: loading || filterLoading,
    allLoaded: allItemsLoaded,
    defaultSelectedRows: selectionState,
    rowActions: getMediaRowActions(update),
    enableRadio: !isCssOrJs,
    enableCheckbox: isCssOrJs,
    sortingBy,
    enableSorting: true,
    enableStickyHeader: true,
    withoutBorders: true,
    enableLazyLoading: true,
    emptyState: emptyListingProps,
    onLoading: onLoadNextPage,
    onRowSelectionChanged,
    rowDisabledTitle: '',
    scrollableElementRef: contentRef,
    enableInsideLoader: true,
    resetSelectionWhenPageChanges: false,
  }

  const folderSearchProps: FolderSearchProps<MediaItem> = useMemo(
    () => ({
      tableV2Props: { ...tableV2Props, onLoading: undefined, manualSorting: false, onSort: undefined, enableLazyLoading: false },
      data: tableV2Props.data,
      columns: [],
      search: searchText,
      onSearch: handleSearchTable,
      searchAllFilter: isCssOrJs ? allMediaFilter : allMediaFilesFilter,
      currentFilter: getMediaFilters(isCssOrJs).find((filter) => filter.name === activeFilter),
      currentFolder: folders.find((folder) => folder.id === activeFolder),
      resultsLabel: t('AssetPicker.Search.Results', { count: tableV2Props.data.length, searchTerm: searchText }),
      resultsLabelAboveFilters: true,
      largeResultsLabel: true,
      alwaysShowResultsLabel: true,
      loading: loading || filterLoading,
      enableTableV2: true,
    }),
    [tableV2Props, searchText, handleSearchTable, isCssOrJs, folders, t, loading, filterLoading, activeFilter, activeFolder]
  )

  return (
    <div className={classNames(`${rootClass}__table`, className)} ref={contentRef}>
      {isViewingSelected ? (
        <ViewSelectedMedia tableV2Props={tableV2Props} />
      ) : searchText ? (
        <FolderSearch {...folderSearchProps} />
      ) : (
        <TableV2 {...tableV2Props} />
      )}
    </div>
  )
}
