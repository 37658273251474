import React, { FC, useCallback, useContext, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { PersonalizationItem } from '@src/../../../libs/components/complex/Personalization/utils/Personalization.context'

import Pill, { PillSize, PillType } from '@components/Pill/Pill'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { PersonalizationFieldsErrorsForSources } from '@graphql/types/query-types'
import ValidationSection from '@src/pages/EmailComposer/components/common/ValidationSection/ValidationSection'
import { Validation, ValidationSectionStatus } from '@src/pages/EmailComposer/components/common/ValidationsList/ValidationsList'
import { filterNotEmptyArray } from '@utils/array'
import { CommonComposerTab } from '@utils/composer/commonComposer/CommonComposer.context'
import { EmailComposerContext, ValidationSectionType } from '@utils/composer/context/EmailComposer.context'
import useCRM from '@utils/hooks/useCRM'
import { getPersonalizationValidationType, PersonalizationValidationType } from '@utils/personalization'

interface SubjectValidationSectionProps {
  dataTest?: string
  disableEditRecipientsBtn?: boolean
}

type PersonalizationValidations = Record<PersonalizationValidationType, string[]>

const calcPersonalizationErrors = (errors?: PersonalizationFieldsErrorsForSources[]): PersonalizationValidations => {
  const validations: PersonalizationValidations = { MISSING: [], CRM: [], MALFORMED: [], RECIPIENTS: [] }

  errors?.forEach((error) => {
    const validationType = getPersonalizationValidationType(error)
    //replace ={{asd}}|qwe or ={{asd}} or asd|qwe to asd
    const field = `${error.field}`.replace(/^={{([^}]*)}}$|^={{([^}]*)}}\|[^|]*$|^([^|]*)\|[^|]*$|^([^|]*)$/, '$1$2$3$4')
    switch (validationType) {
      case 'RECIPIENTS':
        validations.RECIPIENTS.push(`${field}:${error.lists.filter(filterNotEmptyArray).join(',')}`)
        break
      case 'CRM':
        validations.CRM.push(field)
        break
      case 'MALFORMED':
        validations.MALFORMED.push(field)
        break
      case 'MISSING':
        validations.MISSING.push(field)
    }
  })

  return validations
}

const calcPersonalizationWithoutFallback = (personalizations: PersonalizationItem[] | undefined, text: string) => {
  //get {{asd}} from {{={{asd}}}} or {{asd}}
  const getFieldsWithoutFallback = /\{\{([^|{}]+)\}\}(?![^{}]*\|)/g

  return text
    .match(getFieldsWithoutFallback)
    ?.map((match) => {
      const fieldName = match.replace(/\{\{([^|]*?)\}\}/g, '$1')
      const foundPersonalization = personalizations?.find((personalization) => {
        return [personalization.mapping, personalization.title].some((pField) => pField === fieldName)
      })
      return foundPersonalization?.title || fieldName
    })
    .filter((match) => {
      const isFilteredOut = personalizations?.some((personalization) => {
        const exactMatch = match.split('(')[0]

        return (
          [personalization.title, personalization.mapping].includes(exactMatch) &&
          ['Custom account fields', 'Sender fields'].includes(personalization.group)
        )
      })
      return !isFilteredOut
    })
}

const rootClass = 'subject-validation-section'

export const SubjectValidationSection: FC<SubjectValidationSectionProps> = ({ dataTest = rootClass, disableEditRecipientsBtn }) => {
  const {
    values: {
      personalizations,
      message: { subject, previewText },
      validations: {
        settingsValidations: {
          subjectLineContainsOnlyEmojis,
          subjectLengthExceeded,
          previewLengthExceeded,
          subjectPersonalizationErrors,
          previewPersonalizationErrors,
          failedPersonalizationErrors: error,
          loadingPersonalizationErrors: loading,
        },
      },
      messageConfiguration: {
        reviewAndSend: { disableEditings },
      },
      detectedURLChanges: { plainTextMode, uploadHtmlMode },
    },
    api: { onTabChange, onStatusChange, onCheckSubjectAndPreviewText },
  } = useContext(EmailComposerContext)

  const { connectorType } = useCRM()
  const { t } = useTranslation()

  const getPersonalizationTitle = (textWithoutFallback: string) => {
    const item = personalizations?.find((item) => item.mapping === textWithoutFallback.split('(')[0])
    return item?.title
  }

  const formatPersonalizationText = (input: string) => {
    const regex = /{{=\{\{(.*?)\}\}\|(.*?)\}}|{{(\^?.*?)}}/g
    let match
    const elements = []
    let lastIndex = 0

    while ((match = regex.exec(input)) !== null) {
      const [placeholder, text, fallbackText, textWithoutFallback] = match

      elements.push(input.substring(lastIndex, match.index))

      if (text && fallbackText) {
        // For the pattern {{={{text}}|title}}
        elements.push(
          <>
            {' '}
            <Tooltip alwaysShowOnHover trigger={<Pill text={text} type={PillType.BORDERED} size={PillSize.LARGE} cursorPointer noMargin />}>
              <Typography
                text={t('EmailComposer.Subject.Validation.Subject.Fallback.Tooltip', { fallback: fallbackText })}
                tagProps={{ bold: { weight: TextWeight.BOLD } }}
                type={TextType.BODY_TEXT_WHITE}
                inline
              />
            </Tooltip>{' '}
          </>
        )
      } else if (textWithoutFallback) {
        // For the pattern {{...}} or {{^...}}
        elements.push(
          <>
            {' '}
            <Pill text={getPersonalizationTitle(textWithoutFallback)} type={PillType.BORDERED} size={PillSize.LARGE} noMargin cursorPointer />{' '}
          </>
        )
      }

      lastIndex = match.index + placeholder.length
    }
    elements.push(input.substring(lastIndex))

    return <Typography text={elements} className="push-right-half" />
  }

  const validations = useMemo<Validation[]>(() => {
    const subjectErrors = calcPersonalizationErrors(subjectPersonalizationErrors)
    const subjectPersonalizationsWithoutFallback = calcPersonalizationWithoutFallback(personalizations, subject)
    const hasSubjectErrors = Object.values(subjectErrors).some((errors) => errors.length > 0)

    const previewErrors = calcPersonalizationErrors(previewPersonalizationErrors)
    const previewPersonalizationsWithoutFallback = calcPersonalizationWithoutFallback(personalizations, previewText)
    const hasPreviewErrors = Object.values(previewErrors).some((errors) => errors.length > 0)

    const validations: Validation[] = []

    if (!subject) {
      validations.push({
        key: 'subject',
        status: ValidationSectionStatus.ERROR,
        title: 'EmailComposer.Subject.Validation.Subject',
        titleProps: {
          values: { context: 'error' },
        },
      })
    } else {
      if (loading) {
        validations.push({
          key: 'subject',
          status: ValidationSectionStatus.REST,
          title: 'EmailComposer.Subject.Validation.Subject.Loading',
          loading,
        })
      } else if (error) {
        validations.push({
          key: 'subject',
          status: ValidationSectionStatus.WARNING,
          title: 'EmailComposer.Subject.Validation.Subject.Retry',
          hideLinkIcon: true,
          onClick: () => onCheckSubjectAndPreviewText(),
        })
      } else if (hasSubjectErrors) {
        Object.keys(subjectErrors).forEach((k) => {
          const key = k as PersonalizationValidationType
          if (subjectErrors[key].length) {
            if (key === 'RECIPIENTS') {
              subjectErrors[key].forEach((field, index) => {
                validations.push({
                  key: `subject.${key}.${index}`,
                  status: ValidationSectionStatus.ERROR,
                  title: `EmailComposer.Subject.Validation.Subject.Error.${key}`,
                  titleProps: {
                    values: { field: field.split(':')[0] },
                  },
                  options: field.split(':')[1].split(','),
                })
              })
            } else {
              validations.push({
                key: `subject.${key}`,
                status: ValidationSectionStatus.ERROR,
                title: `EmailComposer.Subject.Validation.Subject.Error.${key}`,
                titleProps: {
                  values: { fields: subjectErrors[key].join(', '), connectorType },
                },
              })
            }
          }
        })
      } else if (!subjectLengthExceeded && !subjectLineContainsOnlyEmojis) {
        if (subjectPersonalizationsWithoutFallback?.length) {
          validations.push({
            key: 'subject',
            status: ValidationSectionStatus.WARNING,
            title: 'EmailComposer.Subject.Validation.Subject.Fallback',
            titleProps: {
              values: { fields: subjectPersonalizationsWithoutFallback.join(', ') },
            },
          })
        } else {
          validations.push({
            key: 'subject',
            status: ValidationSectionStatus.SUCCESS,
            title: (
              <Trans
                i18nKey="EmailComposer.Subject.Validation.Subject"
                components={{
                  medium: <Typography weight={TextWeight.MEDIUM} />,
                  subject: formatPersonalizationText(subject),
                }}
                context="success"
              />
            ),
            alignContent: true,
          })
        }
      }

      if (subjectLengthExceeded) {
        validations.push({
          key: 'subjectLength',
          status: ValidationSectionStatus.WARNING,
          title: 'EmailComposer.Subject.Validation.Subject.Length.Exceed',
        })
      }
      if (subjectLineContainsOnlyEmojis) {
        validations.push({
          key: 'subjectLineOnlyEmojis',
          status: ValidationSectionStatus.WARNING,
          title: 'EmailComposer.Subject.Validation.Subject.Only.Emojis',
        })
      }
    }

    if (!previewText && !plainTextMode && !uploadHtmlMode) {
      validations.push({
        key: 'previewText',
        status: ValidationSectionStatus.WARNING,
        title: 'EmailComposer.Subject.Validation.PreviewText',
        titleProps: {
          values: { context: 'warning' },
        },
      })
    } else {
      if (loading && !plainTextMode && !uploadHtmlMode) {
        validations.push({
          key: 'previewText',
          status: ValidationSectionStatus.REST,
          title: 'EmailComposer.Subject.Validation.PreviewText.Loading',
          loading,
        })
      } else if (error) {
        validations.push({
          key: 'previewText',
          status: ValidationSectionStatus.WARNING,
          title: 'EmailComposer.Subject.Validation.PreviewText.Retry',
          hideLinkIcon: true,
          onClick: () => onCheckSubjectAndPreviewText(),
        })
      } else if (hasPreviewErrors) {
        Object.keys(previewErrors).forEach((k) => {
          const key = k as PersonalizationValidationType
          if (previewErrors[key].length) {
            if (key === 'RECIPIENTS') {
              previewErrors[key].forEach((field, index) => {
                validations.push({
                  key: `previewText.${key}.${index}`,
                  status: ValidationSectionStatus.ERROR,
                  title: `EmailComposer.Subject.Validation.PreviewText.Error.${key}`,
                  titleProps: {
                    values: { field: field.split(':')[0] },
                  },
                  options: field.split(':')[1].split(','),
                })
              })
            } else {
              validations.push({
                key: `previewText.${key}`,
                status: ValidationSectionStatus.ERROR,
                title: `EmailComposer.Subject.Validation.PreviewText.Error.${key}`,
                titleProps: {
                  values: { fields: previewErrors[key].join(', '), connectorType },
                },
              })
            }
          }
        })
      } else if (!previewLengthExceeded && !plainTextMode && !uploadHtmlMode) {
        if (previewPersonalizationsWithoutFallback?.length) {
          validations.push({
            key: 'previewText',
            status: ValidationSectionStatus.WARNING,
            title: 'EmailComposer.Subject.Validation.PreviewText.Fallback',
            titleProps: {
              values: { fields: previewPersonalizationsWithoutFallback.join(', ') },
            },
          })
        } else {
          validations.push({
            key: 'previewText',
            status: ValidationSectionStatus.SUCCESS,
            title: (
              <Trans
                i18nKey="EmailComposer.Subject.Validation.PreviewText"
                components={{ medium: <Typography weight={TextWeight.MEDIUM} />, previewText: formatPersonalizationText(previewText) }}
                context="success"
              />
            ),
            alignContent: true,
          })
        }
      }

      if (previewLengthExceeded) {
        validations.push({
          key: 'previewLength',
          status: ValidationSectionStatus.WARNING,
          title: 'EmailComposer.Subject.Validation.PreviewText.Length.Exceed',
        })
      }
    }

    return validations
  }, [
    subjectPersonalizationErrors,
    previewPersonalizationErrors,
    subject,
    subjectLineContainsOnlyEmojis,
    previewText,
    plainTextMode,
    uploadHtmlMode,
    loading,
    error,
    subjectLengthExceeded,
    onCheckSubjectAndPreviewText,
    previewLengthExceeded,
  ])

  const handleClick = useCallback<VoidFunction>(
    () => onTabChange(CommonComposerTab.SETTINGS, { fromValidationSection: ValidationSectionType.SUBJECT }),
    [onTabChange]
  )

  return (
    <ValidationSection
      disableEditBtn={disableEditings || disableEditRecipientsBtn}
      type={ValidationSectionType.SUBJECT}
      dataTest={dataTest}
      title="Subject"
      buttonLabel="Edit subject"
      tooltipContentKey={disableEditings ? t('EmailComposer.Resend.Disabled') : ''}
      validations={validations}
      onClick={handleClick}
      onStatusChange={onStatusChange}
    />
  )
}
