import { MutableRefObject } from 'react'
import { UseFormRegisterReturn, ControllerRenderProps } from 'react-hook-form'

import emojiRegex from 'emoji-regex'
import GraphemeSplitter from 'grapheme-splitter'
import tlds from 'tlds'

import { ValidityReturnType } from '@components/InputWithStatus/InputWithStatus'

export const hasUpperAndLowerCaseRegExp = new RegExp(`^(?=.*?[A-Z])(?=.*?[a-z])`)
export const hasNumberRegExp = new RegExp(`.*[0-9].*`)
export const hasSpecialCharacterRegExp = new RegExp(`^.*?[#?!@$%^&*-]`)
export const emailRegExp = new RegExp(
  `^(([^<>()[\\]\\.,;:\\s@"]+(\\.[^<>()[\\]\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+(${tlds
    .join('|')
    .toLowerCase()})))$`
)
export const AllUrlsRegExp = new RegExp(/(http[s]?:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{1,255}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/, 'g')
export const URLRegExp = new RegExp(/^(http[s]?:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{1,255}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/)
export const URLRegExpWithoutHTTP = new RegExp(/^(https:\/\/)[a-zA-Z0-9][-a-zA-Z0-9@:%._\+~#=]{1,255}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/)

export const domainRegExp = new RegExp(/http[s]?:\/\/(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,10}(:[0-9]{1,5})?(\/.*)?$/)
export const hyperlinkPrefixRegExp = new RegExp(/^(?:\S+:\/\/)?[^\/]+\/?$/)
export const IPRegExp = new RegExp(
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(-((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)|(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)))?$/
)

/** Count emojis as a unit since a single emoji can consist of anywhere from 2 to 6 UTF-16 characters */
export const getEmojiCount = (text: string | undefined) => {
  const splitter = new GraphemeSplitter()
  const graphemes = splitter.splitGraphemes(text ?? '')
  const emojiGraphemes = graphemes.filter((grapheme) => grapheme.match(emojiRegex()))
  return emojiGraphemes.length
}

export const EmojiRegExp = /\p{Extended_Pictographic}/gu

export const checkEmailValidity = (email: string): ValidityReturnType | undefined => {
  if (!emailRegExp.test(email) && email !== '') {
    return { errorMessageKey: 'emailInvalid' }
  }
}

export const checkURLValidity = (url: string): undefined | ValidityReturnType => {
  if (!URLRegExp.test(url)) {
    return { errorMessageKey: 'invalidUrl' }
  }
}

export const checkURLWithoutHTTPValidity = (url: string): undefined | ValidityReturnType => {
  if (!URLRegExpWithoutHTTP.test(url)) {
    return { errorMessageKey: 'invalidUrl' }
  }
}

export const checkMaxCharactersValidity = (str: string, maxCharacters: number): undefined | ValidityReturnType => {
  if (str.length > maxCharacters) {
    return { errorMessageKey: 'exceededMaxCharacters' }
  }
}

export const insertTextAtCursor = (element: HTMLTextAreaElement | HTMLInputElement | null, text: string) => {
  if (!element) {
    return ''
  }
  const startPos = element.selectionStart
  const endPos = element.selectionEnd
  const value = element.value
  const newValue =
    startPos !== null && endPos !== null ? `${value.substring(0, startPos)}${text}${value.substring(endPos, value.length)}` : `${value}${text}`
  return newValue
}

export const collapseSelectionRange = () => {
  const sel = window.getSelection()
  if (!sel) {
    return
  }
  const range = sel.getRangeAt(0)
  if (sel.rangeCount) {
    range.collapse(false)
    sel.removeAllRanges()
    sel.addRange(range)
  }
}

export const placeCaretAfterNode = (node: Node) => {
  if (!node || !node.parentNode) {
    return
  }
  const sel = window.getSelection()
  if (!sel) {
    return
  }
  if (sel.rangeCount) {
    const range = sel.getRangeAt(0)
    // Workaround for wrong cursor position after non-text node
    const bufferNode = document.createTextNode('')

    range.setStartAfter(node)
    range.insertNode(bufferNode)
    range.setStartAfter(bufferNode)
    range.collapse(true)
    sel.removeAllRanges()
    sel.addRange(range)
  }
}

export const registerWithRefHook = (
  fieldRegister: UseFormRegisterReturn<any> | ControllerRenderProps<any, any>,
  ref: MutableRefObject<HTMLElement | null>
) => {
  const { ref: refCallback, ...register } = fieldRegister
  return {
    ...register,
    ref: (e: HTMLElement) => {
      refCallback(e)
      ref.current = e
    },
  }
}

export const getFormErrorMessageList = <T extends Record<string, any>>(formStateErrors: T, maxDepth = 10, currentDepth = 0) => {
  if (currentDepth > maxDepth) {
    return []
  }

  let messages: string[] = []
  for (const k in formStateErrors) {
    const key = k as keyof T
    if (key === 'ref') {
      // Recursing this key will blow up the call stack
      continue
    }
    if (typeof formStateErrors[key] === 'object' && formStateErrors[key] !== null) {
      messages = [...messages, ...getFormErrorMessageList(formStateErrors[key], maxDepth, currentDepth + 1)]
    } else if (['message', 'messageText'].includes(String(key)) && !!formStateErrors[key]) {
      messages.push(formStateErrors[key])
    }
  }
  return messages
}

export enum FileUplaodModalType {
  PREVIEW = 'preview',
  DOWNLOAD = 'download',
}
