import { gql } from '@apollo/client'

export default gql`
  mutation shareAssetToCatalog($ids: [String!], $type: String!) {
    shareAssetToCatalog(ids: $ids, type: $type) {
      creator
      message
      status
      successIds
      failedIds {
        id
        name
        reason
      }
    }
  }
`
