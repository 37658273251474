import React, { FC, useContext, useMemo } from 'react'
import { useDragLayer, XYCoord } from 'react-dnd'

import classNames from 'classnames'

import SegmentDefinition from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/SegmentDefinition/SegmentDefinition'
import { getBuilderDefinitionElementById } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/utils/SegmentComposerBuild.utils'
import { CombineRowsType } from '@src/pages/SegmentComposer/SegmentComposer.constants'
import { SegmentComposerContext } from '@src/pages/SegmentComposer/SegmentComposer.context'

import './SegmentComposerDragLayer.css'

interface SegmentComposerDragLayerProps {
  className?: string
  dataTest?: string
}

const getItemStyles = (currentOffset: XYCoord | null) => {
  if (!currentOffset) {
    return {
      display: 'none',
    }
  }
  const { x, y } = currentOffset
  return {
    transform: `translate(${x}px, ${y}px)`,
  }
}

const rootClass = 'segment-composer-drag-layer'

const SegmentComposerDragLayer: FC<SegmentComposerDragLayerProps> = (props: SegmentComposerDragLayerProps) => {
  const { dataTest = rootClass, className = '' } = props

  const {
    values: {
      segmentDefinition: { groups },
    },
  } = useContext(SegmentComposerContext)

  const { isDragging, item, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem() as { id: string; operator?: CombineRowsType } | null,
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  const itemBeingDragged = useMemo(() => {
    if (item?.id && groups.length > 0) {
      return getBuilderDefinitionElementById(item.id, groups[0])
    }
  }, [groups, item])

  if (!isDragging || !itemBeingDragged) {
    return null
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div style={getItemStyles(currentOffset)}>
        <SegmentDefinition dataTest={`${dataTest}-preview`} builderElement={itemBeingDragged} operator={item?.operator} isPreview />
      </div>
    </div>
  )
}

export default SegmentComposerDragLayer
