import React, { FC, useCallback } from 'react'

import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { CrmEntityType } from '@graphql/types/microservice/crm-entity-mapping-types'
import { ReportMappingsData, SelectOptionsType } from '@src/pages/datamanagement/context/DataManagementContext'

import MappingField from './MappingField'

import './MappingFieldsList.css'

export interface MappingFieldsListProps {
  dataTest?: string
  className?: string
  reportMapping: ReportMappingsData
  sectionHeader?: CrmEntityType
  updateRow: (rowId: number, entityType: CrmEntityType, option?: SelectOptionsType) => void
}

const rootClass = 'mapping-fields-list'

const MappingFieldsList: FC<MappingFieldsListProps> = (props: MappingFieldsListProps) => {
  const { reportMapping, updateRow, dataTest = rootClass } = props
  const { entityType, mappingList } = reportMapping
  const { t } = useTranslation()

  const renderListHeader = useCallback(
    () => (
      <div className={`${rootClass}__header`} data-test={`${dataTest}-header-${entityType}`}>
        <Typography text={t(`${entityType} CRM FIELD`)} type={TextType.TABLE_HEADER} />
        <Typography text={t(`${entityType} ACT-ON FIELD`)} type={TextType.TABLE_HEADER} />
        <Typography text={t('STATUS')} type={TextType.TABLE_HEADER} />
      </div>
    ),
    [dataTest, entityType, t]
  )

  const renderSection = useCallback(() => {
    return mappingList.map((mapping) => {
      return <MappingField data={mapping} entityType={entityType} updateRow={updateRow} key={entityType} />
    })
  }, [entityType, mappingList, updateRow])

  return (
    <>
      <div className={rootClass} data-test={dataTest}>
        {entityType && renderListHeader()}
        <div className={`${rootClass}_section`} data-test={`${dataTest}-section-${entityType}`}>
          {renderSection()}
        </div>
      </div>
    </>
  )
}

export default MappingFieldsList
