import React, { FC } from 'react'

import classNames from 'classnames'

import Caution from '@components/Caution/Caution'
import { useTranslation } from '@const/globals'

interface Props {
  className?: string
  dataTest?: string
}

const rootClass = 'contacts-reentry-warning'

const ContactsReentryWarning: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()

  return (
    <Caution
      message={t('Program is set to allow contacts to reenter. Duplicate contacts may be listed.')}
      className={classNames(rootClass, className)}
      dataTest={dataTest}
    />
  )
}

export default ContactsReentryWarning
