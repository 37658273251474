import { gql } from '@apollo/client'

export default gql`
  query getLastPublishedMessageContent($contentId: String!) {
    getLastPublishedMessageContent(contentId: $contentId) {
      json
      html
    }
  }
`
