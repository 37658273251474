import React, { FC, useEffect, useRef, useState } from 'react'

import { ListingPageItem } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { UNIFIED_CONTACTS_ID } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import { Status } from '@components/StatusToast/StatusToast'
import { useTranslation } from '@const/globals'
import { ConfigInput, Segment } from '@graphql/types/microservice/ml-audience-types'
import { List } from '@interface/foldersLists/List'
import {
  parseConfigToSettings,
  renderUpdatedSettingsToast,
} from '@src/pages/RecommendedSegments/components/SegmentsInsightsSettingsContainer/utils/SegmentsInsightsSettingsContainer.utils'
import RecommendedSegments from '@src/pages/RecommendedSegments/RecommendedSegments'
import { DEFAULT_AI_AUDIENCE_LIST } from '@src/pages/RecommendedSegments/utils/RecommendedSegments.constants'
import {
  RecommendedSegmentsContext,
  RecommendedSegmentsContextAPI,
  recommendedSegmentsContextInitialState,
  RecommendedSegmentsContextState,
} from '@src/pages/RecommendedSegments/utils/RecommendedSegments.context'
import { useRecommendedSegmentsRequests } from '@src/pages/RecommendedSegments/utils/RecommendedSegments.graphQL'
import { SegmentsInsightsSettings } from '@src/pages/RecommendedSegments/utils/RecommendedSegments.interfaces'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'
import { logNewRelicError } from '@utils/new-relic.utils'

const RecommendedSegmentsContainer: FC = () => {
  const [state, setState] = useState<RecommendedSegmentsContextState>(recommendedSegmentsContextInitialState)
  const { settings } = state

  const { t } = useTranslation()

  const {
    getAccountRegistration,
    getConfiguration,
    getDefaultFeatures,
    getProblems,
    getAllContactsSegment,
    getSegment,
    getSegmentItemById,
    resetConfiguration,
    setConfiguration,
    setSelectedParentRequest,
  } = useRecommendedSegmentsRequests()

  const update = useDeepUpdate(setState)

  const allContactsSegmentRef = useRef<ListingPageItem & { recordsCount: number }>()
  const initialDataLoadedRef = useRef(false)

  const loadParentSegments = async (segments: Segment[]) => {
    if (segments?.length) {
      const allParentSegmentsIds = segments.reduce((allParentSegmentsIds: Set<string>, segment) => {
        if (segment.parentSegmentId) {
          allParentSegmentsIds.add(segment.parentSegmentId)
        }
        return allParentSegmentsIds
      }, new Set<string>())
      Promise.all([...allParentSegmentsIds].map((parentId) => getSegmentByExternalId(parentId))).then((parents) => {
        update({ allParentSegments: parents })
      })
    }
  }

  const loadSegments = async () => {
    update({ loadingSegments: true })
    return getSegment().then((segments) => {
      const sortedSegments = segments.sort((a, b) => (parseInt(a.updateTime || '0') > parseInt(b.updateTime || '0') ? -1 : 1))
      update({
        segments: sortedSegments,
        loadingSegments: false,
      })
      loadParentSegments(sortedSegments)
    })
  }

  const getSegmentByExternalId = async (externalId: string) => {
    const allContactsSegment = allContactsSegmentRef.current
    if (allContactsSegment && (externalId === allContactsSegment?.externalId || externalId === UNIFIED_CONTACTS_ID)) {
      return {
        id: allContactsSegment.externalId,
        name: allContactsSegment.name,
        size: allContactsSegment.recordsCount,
      } as List
    }
    const { data: segmentData } = await getSegmentItemById(externalId)
    if (segmentData?.getItem) {
      const { externalId, item = '{}' } = segmentData.getItem
      const parsedItem = JSON.parse(item)
      return {
        id: externalId,
        name: parsedItem.name,
        size: parsedItem.recordsCount,
      } as List
    }
  }

  const getSelectedParent = async (selectedParent?: string) => {
    let selectedParentSegment = selectedParent || UNIFIED_CONTACTS_ID
    if (!selectedParent) {
      const { data: accountRegistrationData } = await getAccountRegistration()
      selectedParentSegment = accountRegistrationData?.accountRegistration?.selectedParentSegment || UNIFIED_CONTACTS_ID
    }
    const segment = await getSegmentByExternalId(selectedParentSegment)
    return segment ?? DEFAULT_AI_AUDIENCE_LIST
  }

  const setSelectedParent = (segmentId: string) => {
    setSelectedParentRequest(segmentId)
      .then(async ({ data }) => {
        const selectedParent = await getSelectedParent(data?.setSelectedParentSegment?.selectedParentSegment)
        update({ selectedParent, hasUpdatedSettings: true })
      })
      .catch((error) => {
        logNewRelicError(error)
        update({
          status: {
            status: Status.FAIL,
            message: t('Something went wrong on our end. Please try again.'),
          },
        })
      })
  }

  const loadSettings = async (updateLoadingStatus?: boolean) => {
    if (updateLoadingStatus) {
      update({ loadingSettings: true })
    }
    return Promise.all([getConfiguration(), getDefaultFeatures(), getSelectedParent()])
      .then(([{ data: configData }, defaultFeatures, selectedParent]) => {
        if (configData) {
          update({
            ...(updateLoadingStatus ? { loadingSettings: false } : {}),
            selectedParent,
            settings: {
              ...settings,
              ...parseConfigToSettings(configData),
              availableFeatures: defaultFeatures,
              invalidFeatures: configData.config?.data?.invalidFeaturesCat,
            } as SegmentsInsightsSettings,
          })
        }
      })
      .catch((error) => {
        logNewRelicError(error)
        if (updateLoadingStatus) {
          update({ loadingSettings: false })
        }
      })
  }

  const saveSettings = (settings: Partial<ConfigInput>) => {
    update({ loadingSettings: true })
    setConfiguration(settings)
      .then(async () => {
        await loadSettings(true)
        update({ hasUpdatedSettings: true })
      })
      .catch((error) => {
        logNewRelicError(error)
        update({
          loadingSettings: false,
          status: {
            status: Status.FAIL,
            message: t('Something went wrong on our end. Please try again.'),
          },
        })
      })
  }

  const resetSettings = () => {
    update({ loadingSettings: true })
    resetConfiguration().then(async () => {
      await loadSettings(true)
      update({ status: renderUpdatedSettingsToast() })
    })
  }

  const loadProblems = async () => {
    const problems = await getProblems()
    update({ problems })
  }

  const loadAllContactsSegment = async () => {
    const allContactsSegment = await getAllContactsSegment()
    update({ allContactsSegment })
    allContactsSegmentRef.current = allContactsSegment
  }

  const initialPageLoad = async () => {
    await loadAllContactsSegment()
    Promise.all([loadSegments(), loadSettings(), loadProblems()]).then(() => {
      update({ loadingSettings: false })
    })
  }

  useEffect(() => {
    if (!initialDataLoadedRef.current) {
      initialPageLoad()
      initialDataLoadedRef.current = true
    }
  }, [])

  const context: RecommendedSegmentsContextAPI = {
    values: state,
    loadSegments,
    loadSettings,
    resetSettings,
    saveSettings,
    setSelectedParent,
    update,
  }

  return (
    <RecommendedSegmentsContext.Provider value={context}>
      <RecommendedSegments />
    </RecommendedSegmentsContext.Provider>
  )
}

export default RecommendedSegmentsContainer
