import React, { FC, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { MenuItem } from '@components/DropDownActions/DropDownActions'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import SectionHeadline from '@components/SectionHeadline'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { LogoItem } from '@graphql/types/query-types'
import LogoModalsHeader from '@src/pages/Content/Branding/tabs/Logos/components/LogoModalsHeader'

import './previewLogoModal.css'

interface Props {
  logo: LogoItem | undefined
  actions: (logo: LogoItem) => MenuItem[]
  onCancel: () => void
  isDuplicate: boolean
  dataTest?: string
}

const rootClass = 'preview-logo-modal'

const PreviewLogoModal: FC<Props> = (props: Props) => {
  const { logo, actions, onCancel, isDuplicate, dataTest = rootClass } = props
  const [imageName, setImageName] = useState<string>('')
  const [invalidImg, setInvalidImg] = useState<Boolean>(false)
  const { t } = useTranslation()

  const onClose = () => {
    setInvalidImg(false)
    onCancel()
  }

  return (
    <Modal
      isOpen={!!logo}
      className={classNames(rootClass)}
      dataTest={dataTest}
      header={
        <ModalHeader headerType={ModalHeaderType.Form}>
          <LogoModalsHeader actions={actions} logo={logo} isDuplicate={isDuplicate} isPreview />
        </ModalHeader>
      }
    >
      <ModalBody>
        <div className={`${rootClass}__body`}>
          {invalidImg ? (
            <div className={`${rootClass}__body-error`}>
              <Svg name={SvgNames.noImage} type={SvgType.VERY_LARGE_ICON} />
              <SectionHeadline>{t('Invalid.Image.Error.Title')}</SectionHeadline>
              <Typography
                text={
                  <Typography
                    text={'Invalid.Image.Error.Msg'}
                    values={{ imageName }}
                    tagComponents={{ name: <span className={`${rootClass}__error-name`} /> }}
                  />
                }
                className={`${rootClass}__error-message`}
              />
            </div>
          ) : (
            <img
              src={logo?.url}
              alt={logo?.alt}
              className={`${rootClass}__body-img`}
              onError={({ currentTarget }) => {
                const fileName = logo?.url?.substring(logo?.url.lastIndexOf('/') + 1).split('?')[0]
                currentTarget.onerror = null
                setImageName(fileName || 'image')
                setInvalidImg(true)
              }}
            />
          )}
        </div>
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form}>
        <Button buttonType={ButtonType.TRANSPARENT} onClick={onClose} dataTest={`${dataTest}-close-button`}>
          {t('Close')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default PreviewLogoModal
