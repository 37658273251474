import React, { ChangeEvent, FC, useCallback, useMemo, useState } from 'react'

import FormRow from '@components/FormRow'
import Grid from '@components/Grid/Grid'
import Input from '@components/Input/Input'
import InputTags from '@components/InputTags/InputTags'
import { CommonInputEmailTagsProps, InputTagsErrorsType } from '@components/InputTags/InputTags.utils'
import Label from '@components/Label'
import LabelWithSvgTooltip from '@components/LabelWithTooltipIcon/LabelWithSvgTooltip'
import SingleSelectDropdown from '@components/SingleSelectDropdown/SingleSelectDropdown'
import TextArea from '@components/TextArea/TextArea'
import { useTranslation } from '@const/globals'
import { userTypeOptions, UserTypeValue } from '@src/pages/Settings/Users/components/InviteUsersModal/const/userType.const'
import { InviteUsersForm, InviteUsersValueChangesFuncType } from '@src/pages/Settings/Users/components/InviteUsersModal/InviteUsersModalContent'
import { isValidEmail } from '@src/pages/Settings/Users/components/InviteUsersModal/utils/emailValidation'
import { LEARN_MORE_LINK } from '@src/pages/Settings/Users/constants/users.constants'
import { useMarketingUsers } from '@src/pages/Settings/Users/context/MarketingUsersContext'
import { useSalesUsers } from '@src/pages/Settings/Users/context/SalesUsersContext'

import './InviteBulkUsersForm.css'

interface Props {
  onValueChange: InviteUsersValueChangesFuncType
  values: InviteUsersForm
  availableRemaining: number
  dataTest?: string
}

const NUMBERS_MAX_LENGTH = 32

const rootClass = 'invite-bulk-users-form'

const InviteBulkUsersForm: FC<Props> = (props: Props) => {
  const { onValueChange, values, availableRemaining, dataTest = rootClass } = props
  const { t } = useTranslation()
  const [inputTagsHasError, setInputTagsHasError] = useState<boolean>(false)
  const { salesUsers } = useSalesUsers()
  const { users: marketingUsers } = useMarketingUsers()

  const tagsErrorMessage = useMemo<string | undefined>(() => {
    const context: string | undefined = inputTagsHasError ? 'standard' : availableRemaining < 0 ? 'limit' : undefined
    const userType = values.userType === 'sales user' ? 'sales' : 'marketing'
    return context ? t('Invite.Users.Bulk.Email.Error.Message', { context, userType }) : undefined
  }, [inputTagsHasError, availableRemaining, values.userType])

  const onInputChange = useCallback(
    (name: keyof InviteUsersForm) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onValueChange({ [name]: e.target.value })
    },
    [onValueChange]
  )

  const onEmailsChange = useCallback(
    (emails: string[]) => {
      onValueChange({ email: emails })
    },
    [onValueChange]
  )

  const tagValidation = useCallback<(newEmail: string) => string | undefined>(
    (newEmail) => {
      if (!isValidEmail(newEmail)) {
        return t('Email.Tag.Validation.Tooltip.Msg')
      }
      if ([...salesUsers, ...marketingUsers].some(({ email }) => email === newEmail)) {
        return t('Email.Tag.Existence.Error.Msg')
      }
    },
    [isValidEmail, salesUsers, marketingUsers]
  )
  const handleErrorStatusChange = useCallback<(errors: InputTagsErrorsType) => void>(
    ({ hasValidationError, hasDuplicationError }) => {
      const hasError = hasDuplicationError || hasValidationError
      onValueChange({ hasEmailError: hasError })
      setInputTagsHasError(hasError)
    },
    [onValueChange]
  )

  const handleUserTypeSelect = useCallback(
    (type?: string) => onValueChange({ userType: (type ?? UserTypeValue.MARKETING) as UserTypeValue }),
    [onValueChange]
  )

  return (
    <div className={rootClass}>
      <Grid row>
        <Grid size={50}>
          <FormRow>
            <LabelWithSvgTooltip
              label={t('User Type')}
              tooltipContent={t('Invite.Users.Modal.Type.Tooltip.Message')}
              link={LEARN_MORE_LINK}
              linkText={t('Learn more')}
              align="center"
              alignTextCenter
            />
            <SingleSelectDropdown
              options={userTypeOptions}
              onSubmit={handleUserTypeSelect}
              defaultValue={values.userType}
              allowDeselect={false}
              dataTest={`${dataTest}-select-userType`}
            />
          </FormRow>
        </Grid>
        <Grid size={50}>
          <FormRow>
            <Label>{t('Title')}</Label>
            <Input dataTest={`${dataTest}-input-title`} onChange={onInputChange('title')} maxlength={NUMBERS_MAX_LENGTH} />
          </FormRow>
        </Grid>
      </Grid>
      <FormRow className={`${rootClass}__emails-form-row`}>
        <Label required>{t('Email addresses')}</Label>
        <InputTags
          onChange={onEmailsChange}
          tagValidation={tagValidation}
          errorMessage={tagsErrorMessage}
          onErrorStatusChange={handleErrorStatusChange}
          duplicationErrorTooltip={t('Email.Tag.Duplication.Error.Msg')}
          {...CommonInputEmailTagsProps}
          showErrorMessage={!!tagsErrorMessage}
          dataTest={`${dataTest}-input-emails`}
        />
      </FormRow>
      <FormRow>
        <LabelWithSvgTooltip
          label={t('Custom Welcome Message')}
          tooltipContent={t('Custom.Welcome.Message.Tooltip')}
          align="center"
          alignTextCenter
        />
        <TextArea
          value={values.customWelcomeMessage}
          onChange={onInputChange('customWelcomeMessage')}
          name={`${dataTest}-textarea`}
          placeholder={t('Invite.Users.Modal.TextArea.Placeholder')}
          rows={7}
          resize={false}
          className={`${rootClass}__textarea`}
          dataTest={`${dataTest}-textarea`}
        />
      </FormRow>
    </div>
  )
}

export default InviteBulkUsersForm
