import React, { FC, ReactNode, useState } from 'react'

import classNames from 'classnames'

import Svg, { SvgColor, SvgNames, SvgType } from '@components/Svg'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'

import './CheckboxCard.css'

export interface CheckboxCardProps {
  className?: string
  dataTest?: string
  title: string
  description?: string
  iconLeft: SvgNames
  hoverIconLeft?: SvgNames
  iconRight?: SvgNames
  disabled?: boolean
  error?: boolean
  onSelect?: () => void
  hideIconRight?: boolean
  isSelected?: boolean
  inDropdown?: boolean
  tooltip?: string | ReactNode
}

interface State {
  isHovering: boolean
}

const rootClass = 'checkbox-card'

const CheckboxCard: FC<CheckboxCardProps> = (props: CheckboxCardProps) => {
  const {
    dataTest = rootClass,
    className = '',
    title,
    iconRight = SvgNames.plusLight,
    iconLeft,
    hoverIconLeft = iconLeft,
    onSelect,
    description,
    error,
    disabled,
    hideIconRight = false,
    isSelected = false,
    inDropdown = false,
    tooltip,
  } = props

  const [state, setState] = useState<State>({
    isHovering: false,
  })

  const update = useDeepUpdate(setState)

  const { isHovering } = state

  const selectedColor = isSelected && !error && !disabled
  const dropdownSelectedColor = inDropdown && isSelected && !error && !disabled
  const hoverColor = isHovering && !isSelected && !disabled
  const iconRightFill = dropdownSelectedColor ? SvgColor.TEAL : selectedColor ? SvgColor.TEXT_TEAL : hoverColor ? SvgColor.TEAL : SvgColor.BUTTON_GRAY

  return (
    <Tooltip
      trigger={
        <div
          className={classNames(rootClass, className, {
            [`${rootClass}__selected`]: isSelected && !error && !disabled && !inDropdown,
            [`${rootClass}__hovered`]: isHovering && !error && !disabled && !inDropdown,
            [`${rootClass}__error`]: error,
            [`${rootClass}__no-border`]: inDropdown,
            [`${rootClass}__dropdown-focus`]: inDropdown && (isHovering || isSelected),
            [`${rootClass}__dropdown-selected`]: inDropdown && isSelected,
          })}
          data-test={dataTest}
          onKeyDown={(keyDownEvent) => (onSelect && (keyDownEvent.key === '' || keyDownEvent.key === 'Enter') ? onSelect() : undefined)}
          onMouseEnter={() => update({ isHovering: true })}
          onMouseLeave={() => update({ isHovering: false })}
          onClick={disabled ? undefined : onSelect}
          role={'button'}
          tabIndex={0}
        >
          {iconLeft && (
            <div
              className={classNames(`${rootClass}__icon-left`, {
                [`${rootClass}__icon-left-selected`]: (isHovering || isSelected) && !error && !disabled,
                [`${rootClass}__icon-left-disabled`]: disabled,
              })}
            >
              <Svg name={(isHovering || isSelected) && !error ? hoverIconLeft : iconLeft} type={SvgType.LARGER_ICON} dataTest={iconLeft} />
            </div>
          )}
          <div className={`${rootClass}__information`}>
            <TextWithTooltipOnEllip
              typographyProps={{
                text: title,
                type: disabled ? TextType.BODY_TEXT_LIGHTER : undefined,
                weight: TextWeight.MEDIUM,
              }}
            />
            {description && (
              <Typography
                className={classNames(`${rootClass}__information-description`, 'ellip')}
                text={description}
                type={TextType.BODY_TEXT_SMALL_LIGHT}
                lineHeight={LineHeight.MEDIUM_SMALL}
              />
            )}
          </div>
          {iconRight && !hideIconRight && (
            <div className={`${rootClass}__icon-right`}>
              <Svg
                name={isSelected ? SvgNames.check : iconRight}
                type={SvgType.LARGER_ICON}
                fill={disabled ? SvgColor.BORDER_GRAY : iconRightFill}
                className={classNames({ [`${rootClass}__icon-error`]: error })}
              />
            </div>
          )}
        </div>
      }
      fullWidth
      hide={!disabled}
    >
      {tooltip}
    </Tooltip>
  )
}

export default CheckboxCard
