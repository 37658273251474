import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'

import equal from 'fast-deep-equal/es6/react'

import { CustomSourceItems, ExtendedItemDto } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { rootContext } from '@const/globals'
import AddContactsFromCRM from '@src/pages/ContactSegments/components/AddContactsFromCRM/AddContactsFromCRM'
import {
  AddContactsFromCRMContainerInitialState,
  AddContactsFromCRMContainerState,
  AddContactsFromCRMContext,
  RADIO_OPTION,
  SourcedSegmentDefaultValues,
} from '@src/pages/ContactSegments/components/AddContactsFromCRM/AddContactsFromCRMContext'
import { useAddContactsFromCRMCategorizationRequests } from '@src/pages/ContactSegments/components/AddContactsFromCRM/GraphQL/AddContactsFromCRMRequests.categorization.graphQL'
import { useAddContactsFromCRMClassicRequests } from '@src/pages/ContactSegments/components/AddContactsFromCRM/GraphQL/AddContactsFromCRMRequests.classic.graphQL'
import { useAddContactsFromCRMRequests } from '@src/pages/ContactSegments/components/AddContactsFromCRM/GraphQL/AddContactsFromCRMRequests.crm.graphQL'
import { useAddContactsFromCRMListRequests } from '@src/pages/ContactSegments/components/AddContactsFromCRM/GraphQL/AddContactsFromCRMRequests.list.graphQL'
import {
  discardChanges,
  getInitialSourcesValuesUtils,
  removeCRMSource,
  setSegmentName,
  validateSourcesOnChange,
} from '@src/pages/ContactSegments/components/AddContactsFromCRM/Helpers/AddContactsFromCRM.crm.helper'
import {
  getCRMSourcedSegmentOptionsUtils,
  getCRMSourcedSegmentsUtils,
  searchCRMSourcedSegmentOptionsUtils,
} from '@src/pages/ContactSegments/components/AddContactsFromCRM/Utils/AddContactsFromCRM.categorization.utils'
import { isAValidSegmentNameUtils } from '@src/pages/ContactSegments/components/AddContactsFromCRM/Utils/AddContactsFromCRM.classic.utils'
import {
  getOptionsDetailsUtils,
  getSegmentSourcesUtils,
  getSourceOptionsUtils,
  getSyncedSegmentCountInfoUtils,
  saveSyncedSegmentUtils,
  syncNowUtils,
  syncedSegmentDetailsUtils,
  validateSourcesUtils,
} from '@src/pages/ContactSegments/components/AddContactsFromCRM/Utils/AddContactsFromCRM.crm.utils'
import { getUnifiedListFieldMappingsUtils } from '@src/pages/ContactSegments/components/AddContactsFromCRM/Utils/AddContactsFromCRM.list.utils'
import { CONTACT_SEGMENTS_CURRENT_SECTION, SEGMENTS_LIST_URL } from '@src/pages/ContactSegments/utils/ContactSegmentsSession.utils'
import { ContactSegmentsSession, buildSegments } from '@utils/contactSegments/contactSegments.utils'
import { getSourceTitle } from '@utils/crm.utils'
import { crmSourcedFilter } from '@utils/filter'
import { useCRMService } from '@utils/hooks/microservices/useCRMService'
import useCRM from '@utils/hooks/useCRM'
import { setItem } from '@utils/sessionStorage'

const AddContactsFromCRMContainer: FC = () => {
  const segmentId = new URLSearchParams(useLocation().search).get('id')
  const history = useHistory()
  const [state, setState] = useState<AddContactsFromCRMContainerState>({
    ...AddContactsFromCRMContainerInitialState,
    radioOption: !!segmentId ? RADIO_OPTION.EDIT : RADIO_OPTION.CREATE,
  })
  const {
    crmSourceOptions,
    hasUCLInitialized,
    invalidSources,
    isOngoingSync,
    options,
    pickedSources,
    radioOption,
    optionsDetails,
    segmentName,
    selectedOption,
    selectedSourcedSegment,
    sources,
  } = state

  const { isAValidSegmentNameRequest } = useAddContactsFromCRMClassicRequests()
  const {
    getSegmentSourcesRequest,
    getSyncedSegmentCountInfoRequest,
    saveSyncedSegmentRequest,
    syncedSegmentDetailsRequest,
    syncNowRequest,
    validateSourcesRequest,
  } = useAddContactsFromCRMRequests()
  const { getCRMSourcedSegmentsRequest, searchCRMSourcedSegmentsRequest } = useAddContactsFromCRMCategorizationRequests()
  const { getUnifiedListFieldMappingsRequest } = useAddContactsFromCRMListRequests()
  const { getSourceOptionsRequest } = useCRMService()

  const { connectorType } = useCRM()

  const initRef = useRef(true)
  const { push } = useHistory()

  const isNewSegment = radioOption === RADIO_OPTION.CREATE

  const update = (values: Partial<AddContactsFromCRMContainerState>) => setState((state) => ({ ...state, ...values }))

  const isAValidSegmentName = async (segmentName: string) =>
    await isAValidSegmentNameUtils({
      isAValidSegmentNameRequest,
      segmentName,
      update,
    })

  const onSave = (syncNowTriggered: boolean) => {
    const selectedSourceTypeName = Object.entries(sources).filter((sources) => !!sources[1].length)[0][0]
    const factorIdentifierNameMap = sources[selectedSourceTypeName].reduce((map, { id, name = '' }) => ({ ...map, [id]: name }), {})
    saveSyncedSegmentUtils({
      connectorType,
      syncedSegmentDto: {
        factorIdentifierNameMap,
        isOngoingSync,
        segmentIdentifier: isNewSegment ? undefined : selectedOption?.value,
        segmentName: isNewSegment ? segmentName?.trim() : selectedOption?.label,
        selectedSourceTypeName: crmSourceOptions.find(({ title }) => `${title}s` === selectedSourceTypeName)?.name,
      },
      saveSyncedSegmentRequest,
      update,
    }).then((success) => {
      if (success) {
        setItem(CONTACT_SEGMENTS_CURRENT_SECTION, ContactSegmentsSession.FILTER)
        setItem(ContactSegmentsSession.FILTER, JSON.stringify(crmSourcedFilter))
        push(SEGMENTS_LIST_URL, { crmSourcedSegmentSaved: syncNowTriggered })
      }
    })
  }

  const onSyncNow = () => syncNowUtils({ syncNowRequest })

  const onDiscard = () => discardChanges(setSegmentDetails, options, segmentId as string, update)

  const onRemoveSource = useCallback((source: ExtendedItemDto) => removeCRMSource(source, setState), [pickedSources, selectedSourcedSegment, sources])

  const getSegmentSources = useCallback(
    () => getSegmentSourcesUtils({ connectorType, crmSourceOption: crmSourceOptions[0], getSegmentSourcesRequest, update }),
    [connectorType, crmSourceOptions, getSegmentSourcesRequest]
  )

  const getOptionsDetails = (segmentIdentifiers: string[]) =>
    getOptionsDetailsUtils({
      segmentIdentifiers,
      syncedSegmentDetailsRequest,
    })

  const setSegmentDetails = (init: boolean, segmentIdentifiers: string[]) =>
    syncedSegmentDetailsUtils({
      init,
      segmentIdentifiers,
      previousSelectedSources: selectedSourcedSegment?.originalSources as CustomSourceItems,
      syncedSegmentDetailsRequest,
      update,
      crmSourceOptions,
    })

  const validateSources = (segmentSourceTypeName: string, sourceIdentifiers: string[]) =>
    validateSourcesUtils({
      connectorType,
      invalidSources,
      segmentSourceTypeName,
      sourceIdentifiers,
      update,
      validateSourcesRequest,
    })

  const setDefaultSegmentName = () => setSegmentName(isAValidSegmentName, sources, setState)

  const validateSourcesOnSourcesChange = async () => validateSourcesOnChange(validateSources, sources, update, crmSourceOptions)

  const getInitialSourcesValues = () =>
    getInitialSourcesValuesUtils(optionsDetails, selectedSourcedSegment as SourcedSegmentDefaultValues, crmSourceOptions)

  const getCRMSourcedSegmentOptions = async (pageNumber = 0) =>
    await getCRMSourcedSegmentOptionsUtils({ getCRMSourcedSegmentsRequest, pageNumber, update })

  const searchCRMSourcedSegmentOptions = async (search: string) =>
    await searchCRMSourcedSegmentOptionsUtils({ searchCRMSourcedSegmentsRequest, search, update })

  useEffect(() => {
    const sourcesValues = Object.values(sources)
      .filter((sources) => !!sources.length)
      .flatMap((items) => items)

    let sourcesChanged = !!sourcesValues.length
    if (radioOption === RADIO_OPTION.EDIT && !!selectedSourcedSegment) {
      const initialSourcesValues = getInitialSourcesValues()
      sourcesChanged = !equal(initialSourcesValues, sourcesValues)
    }

    const sourcesAdded = !!sourcesValues?.length && !segmentName
    const segmentNameAdded = !sourcesValues?.length && !!segmentName
    const sourcesAndSegmentNameAdded = !!sourcesValues?.length && !!segmentName

    const onGoingSyncChanged = isOngoingSync !== selectedSourcedSegment?.ongoingSync
    const noSelectedOption = !!sourcesValues?.length && !selectedOption

    isNewSegment
      ? update({ isDirty: sourcesAdded || segmentNameAdded || sourcesAndSegmentNameAdded })
      : update({ isDirty: onGoingSyncChanged || sourcesChanged || noSelectedOption })
  }, [isOngoingSync, radioOption, segmentName, selectedSourcedSegment, sources])

  const { pickedSourcesEntries, currentSourceType, hasDifferentTypeSourcesSelected } = useMemo(() => {
    const pickedSourcesEntries = Object.entries(pickedSources).filter((sources) => !!sources[1]?.length)
    let currentSourceType = ''
    let hasDifferentTypeSourcesSelected = false
    if (!!pickedSourcesEntries.length || (selectedSourcedSegment && !isNewSegment)) {
      currentSourceType =
        !!selectedSourcedSegment && !isNewSegment
          ? getSourceTitle(selectedSourcedSegment.sourceType ?? '', crmSourceOptions)
          : pickedSourcesEntries[0][0]
      hasDifferentTypeSourcesSelected = !!Object.entries(sources)
        .filter((sources) => sources[0] !== currentSourceType)
        .flatMap((sources) => sources[1]).length
    }

    return { pickedSourcesEntries, currentSourceType, hasDifferentTypeSourcesSelected }
  }, [pickedSources, radioOption, selectedOption, selectedSourcedSegment, sources])

  useEffect(() => {
    if (!!pickedSourcesEntries.length || selectedSourcedSegment) {
      const sourcesItems = [...(selectedSourcedSegment?.originalSources[currentSourceType] || []), ...(pickedSources[currentSourceType] || [])]
      const removedDuplicates = sourcesItems.filter(
        ({ id }: any, index: number, arr: any[]) => arr.findIndex(({ id: idDuplicated }) => id === idDuplicated) === index
      )
      const { [currentSourceType]: _, ...differentTypeSources } = pickedSources

      update({
        showDifferentTypeError: hasDifferentTypeSourcesSelected,
        sources: { [currentSourceType]: removedDuplicates, ...differentTypeSources },
      })
    }
  }, [pickedSources, selectedSourcedSegment])

  useEffect(() => {
    update({
      showDifferentTypeError: hasDifferentTypeSourcesSelected,
    })
  }, [hasDifferentTypeSourcesSelected, sources])

  useEffect(() => {
    if (!!selectedOption && radioOption === RADIO_OPTION.EDIT) {
      setSegmentDetails(false, [selectedOption.value])
    }
  }, [radioOption, selectedOption])

  useEffect(() => {
    if (!!segmentId && !!options.length && initRef.current) {
      initRef.current = false
      setSegmentDetails(true, [segmentId])
      update({ selectedOption: options.find(({ value }) => value === segmentId) })
    }
  }, [segmentId, options])

  useEffect(() => {
    update({ invalidSources: {} })
    validateSourcesOnSourcesChange()
    isNewSegment && !segmentName && setDefaultSegmentName()
  }, [sources])

  useEffect(() => {
    if (hasUCLInitialized) {
      getSyncedSegmentCountInfoUtils({ getSyncedSegmentCountInfoRequest, update })
      getCRMSourcedSegmentsUtils({ getCRMSourcedSegmentsRequest, pageNumber: 0, update }).then(async (items) => {
        if (items) {
          const selectableExternalIds = items
            .map((item) => JSON.parse(item?.item ?? ''))
            .filter(({ isEditable, parent }) => isEditable && !parent)
            .map(({ id }) => id)
          const segments = buildSegments(items)
          const selectableSegments = segments.filter(({ externalId }) => selectableExternalIds.includes(externalId))
          const options: SelectV2SingleOption[] = selectableSegments.map((item) => ({ value: item.externalId, label: item.name }))
          const optionsDetails = await getOptionsDetails(selectableSegments.map(({ externalId }) => externalId))
          update({ options, optionsDetails, segments: selectableSegments })
        }
      })
    }
  }, [hasUCLInitialized])

  useEffect(() => {
    getSourceOptionsUtils({ connectorType, getSourceOptionsRequest, update })
    getUnifiedListFieldMappingsUtils({ getUnifiedListFieldMappingsRequest }).then((unifiedListFieldMappings) => {
      const hasUCLInitialized = unifiedListFieldMappings?.length !== 0
      if (hasUCLInitialized) {
        update({ hasUCLInitialized })
      } else {
        history.push(`${rootContext}/classic`)
      }
    })
  }, [])

  return (
    <AddContactsFromCRMContext.Provider
      value={{
        values: state,
        isAValidSegmentName,
        getCRMSourcedSegmentOptions,
        getSegmentSources,
        removeSource: onRemoveSource,
        searchCRMSourcedSegmentOptions,
        update,
      }}
    >
      {hasUCLInitialized && <AddContactsFromCRM onSave={onSave} onSyncNow={onSyncNow} onDiscard={onDiscard} />}
    </AddContactsFromCRMContext.Provider>
  )
}

export default AddContactsFromCRMContainer
