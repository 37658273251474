import { useEffect } from 'react'

export const useWindowBlur = (focused: boolean, handleBlur: (focused: boolean) => void) => {
  useEffect(() => {
    const windowBlur = () => handleBlur(false)

    if (focused) {
      window.focus()
      window?.addEventListener('blur', windowBlur)
    }

    return () => {
      window.removeEventListener('blur', windowBlur)
    }
  }, [focused, handleBlur])
}
