import React, { useEffect, useState } from 'react'

import { t } from 'i18next'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import Button from '@components/Button'
import { ButtonType } from '@components/Button/Button'
import Caution from '@components/Caution/Caution'
import { LinkTextButton, LinkTextButtonSize } from '@components/LinkTextButton/LinkTextButton'
import Loader from '@components/Loader'
import PageContainer from '@components/PageContainer'
import PageHeader from '@components/PageHeader'
import { PageHeaderType } from '@components/PageHeadline/PageHeadline'
import Pill, { PillSize, PillType } from '@components/Pill/Pill'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import StatusToast from '@components/StatusToast/StatusToast'
import Svg, { SvgColor, SvgNames } from '@components/Svg'
import Toggle from '@components/Toggle'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import setTrueOpenSettings from '@graphql/mutations/setTrueOpenSettings'
import getTrueOpenSettings from '@graphql/queries/getTrueOpenSettings'
import { SetTrueOpenSettingsMutation, SetTrueOpenSettingsMutationVariables } from '@graphql/types/mutation-types'
import { GetTrueOpenSettingsQuery, GetTrueOpenSettingsQueryVariables } from '@graphql/types/query-types'
import { logError } from '@utils/env'
import { StatusToastType } from '@utils/interface/StatusToast'

import './TrueOpenSettings.css'

const link = 'https://success.act-on.com/acton/attachment/9883/u-37089073-16fa-4eb8-bbff-7d39a2bfb487/0/-/-/-/-/True%20Open%20learn%20more'

const rootClass = 'trueOpenSettings'

const TrueOpenSettings = () => {
  const [loading, setLoading] = useState(false)
  const [toast, setToastStatus] = useState<StatusToastType>()
  const [securitySystemsEnabled, setSecuritySystemsEnabled] = useState(false)
  const [privacySystemsEnabled, setPrivacySystemsEnabled] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)

  const client = useApolloClient()
  const {
    data,
    refetch,
    loading: loadingQuery,
  } = useQuery<GetTrueOpenSettingsQuery, GetTrueOpenSettingsQueryVariables>(getTrueOpenSettings, {
    client,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const [updateTrueOpenSettings, { loading: updateTrueOpenSettingsLoading }] = useMutation<
    SetTrueOpenSettingsMutation,
    SetTrueOpenSettingsMutationVariables
  >(setTrueOpenSettings, {
    client,
    fetchPolicy: 'no-cache',
  })

  const options = [
    {
      title: 'True.Open.Settings.Security.Systems',
      text: 'True.Open.Settings.Security.Systems.text',
      toggle: (value: boolean) => setSecuritySystemsEnabled(value),
      isOn: securitySystemsEnabled,
    },
    {
      title: 'True.Open.Settings.Privacy.Systems',
      text: 'True.Open.Settings.Privacy.Systems.text',
      toggle: (value: boolean) => setPrivacySystemsEnabled(value),
      isOn: privacySystemsEnabled,
    },
  ]

  useEffect(() => {
    if (data?.getTrueOpenSettings && !isEditMode) {
      const { securitySystemsFilterEnabled, privacySystemsFilterEnabled } = data.getTrueOpenSettings

      setSecuritySystemsEnabled(!!securitySystemsFilterEnabled)
      setPrivacySystemsEnabled(!!privacySystemsFilterEnabled)
    }

    setLoading(loadingQuery || updateTrueOpenSettingsLoading)
  }, [loadingQuery, data, updateTrueOpenSettingsLoading, isEditMode])

  const onSaveClicked = async () => {
    await updateTrueOpenSettings({
      variables: {
        input: {
          securitySystemsFilterEnabled: securitySystemsEnabled,
          privacySystemsFilterEnabled: privacySystemsEnabled,
        },
      },
    })
      .then(async () => {
        await refetch()
        setToastStatus({ successStatus: true, showStatus: true, title: t('Success!'), statusMessage: t('Your changes have been saved.') })
        setIsEditMode(false)
      })
      .catch((error) => {
        logError(error instanceof Error ? error.message : 'Unknown error during save')
        setToastStatus({
          successStatus: false,
          showStatus: true,
          title: t('UnableTo.Save.Changes'),
          statusMessage: t('UnableTo.Save.Changes.desc'),
        })
      })
  }

  const onCancel = () => {
    if (data?.getTrueOpenSettings) {
      const { securitySystemsFilterEnabled, privacySystemsFilterEnabled } = data.getTrueOpenSettings
      setSecuritySystemsEnabled(!!securitySystemsFilterEnabled)
      setPrivacySystemsEnabled(!!privacySystemsFilterEnabled)
    }
    setIsEditMode(false)
  }

  return (
    <PageContainer className={rootClass} noPadding dataTest={`${rootClass}-page-container`}>
      {!loading && toast?.showStatus && (
        <StatusToast
          isSuccess={toast.successStatus}
          message={toast.statusMessage}
          title={toast.title}
          closeStatus={() => setToastStatus({ showStatus: false })}
        />
      )}
      <PositionContainer>
        <PageHeader
          primaryText={t('True.Open.Settings')}
          headlineType={PageHeaderType.SUB_HEADER}
          leftContent
          className={`${rootClass}__page-header`}
          headlineClassName={`${rootClass}__page-header-headline`}
        >
          <Pill noMargin text="Beta" type={PillType.SECONDARY} size={PillSize.SMALL} />
        </PageHeader>
        <Typography
          inline
          dataTest={`${rootClass}-description`}
          text={t('True.Open.Settings.Description')}
          type={TextType.NORMAL_TEXT_GRAY_LARGE}
          className={`${rootClass}__description`}
          tagComponents={{
            LinkTextButton: (
              <LinkTextButton
                dataTest={`${rootClass}-learn-more-link`}
                inline
                hideIconLeft
                text={t('Learn more')}
                size={LinkTextButtonSize.MEDIUM}
                link={link}
              />
            ),
          }}
        />
        {loading ? (
          <Loader center />
        ) : (
          <section className={`${rootClass}__reporting-wrapper`}>
            <div className={`${rootClass}__reporting-top-section`}>
              <Typography
                dataTest={`${rootClass}-reporting-top-section-title`}
                inline
                text={t('True.Open.Settings.Reporting')}
                type={TextType.BODY_TEXT_LARGE}
                weight={TextWeight.MEDIUM}
                lineHeight={LineHeight.MEDIUM_LARGE}
              />
              {isEditMode ? (
                <div className={`${rootClass}__reporting-top-section-title-edit`}>
                  <Svg name={SvgNames.pencil} fill={SvgColor.LIGHT_GRAY} style={{ width: '12px' }} />

                  <Typography
                    dataTest={`${rootClass}-reporting-top-section-title-edit-text`}
                    text={t('Editing')}
                    type={TextType.BODY_TEXT_LIGHT}
                    weight={TextWeight.MEDIUM}
                    lineHeight={LineHeight.SMALL}
                  />
                </div>
              ) : (
                <Button
                  dataTest={`${rootClass}-reporting-top-section-edit-button`}
                  buttonType={ButtonType.FLOAT_TEAL}
                  onClick={() => setIsEditMode(!isEditMode)}
                >
                  {t('Edit')}
                </Button>
              )}
            </div>

            <div className={`${rootClass}__reporting-bottom-section`}>
              {options.map(({ isOn, text, title, toggle }) => (
                <div key={title} className={`${rootClass}__reporting-bottom-section-option`}>
                  <div className={`${rootClass}__reporting-bottom-section-option-content`} key={title}>
                    <div>
                      <Typography
                        dataTest={`${rootClass}-reporting-bottom-section-option-content-title`}
                        className={`${rootClass}__reporting-bottom-section-option-content-title`}
                        text={t(title)}
                        type={TextType.BODY_TEXT}
                        weight={TextWeight.MEDIUM}
                        lineHeight={LineHeight.MEDIUM}
                      />
                      <Typography
                        dataTest={`${rootClass}-reporting-bottom-section-option-content-text`}
                        text={t(text)}
                        type={TextType.BODY_TEXT_GRAY}
                        lineHeight={LineHeight.MEDIUM}
                      />
                    </div>
                    <Toggle
                      dataTest={`${rootClass}-reporting-bottom-section-option-${title === options[0].title ? 'security' : 'privacy'}-toggle-${
                        isOn ? 'on' : 'off'
                      }`}
                      isOn={isOn}
                      onToggle={toggle}
                      noLeftMargin
                      noRightMargin
                      disabled={!isEditMode}
                      large={true}
                    />
                  </div>
                </div>
              ))}
            </div>

            {isEditMode && (
              <>
                <Caution
                  dataTest={`${rootClass}-caution`}
                  className={`${rootClass}__caution`}
                  message={
                    <Typography
                      inline
                      text={t('True.Open.Settings.Caution')}
                      className={`${rootClass}__description`}
                      tagComponents={{
                        LinkTextButton: (
                          <LinkTextButton
                            dataTest={`${rootClass}-learn-more-link`}
                            inline
                            hideIcons
                            text={t('Learn more')}
                            size={LinkTextButtonSize.MEDIUM}
                            link={link}
                          />
                        ),
                      }}
                    />
                  }
                />
                <div className={`${rootClass}__actions`}>
                  <Button buttonType={ButtonType.TEXT} onClick={onCancel} dataTest={`${rootClass}-cancel-button`}>
                    {t('Cancel')}
                  </Button>
                  <Button buttonType={ButtonType.PRIMARY} onClick={onSaveClicked} dataTest={`${rootClass}-save-button`}>
                    {t('Save')}
                  </Button>
                </div>
              </>
            )}
          </section>
        )}
      </PositionContainer>
    </PageContainer>
  )
}

export default TrueOpenSettings
