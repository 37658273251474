import React, { FC } from 'react'

import { useApolloClient } from '@apollo/client'
import { ListPageCommonContextAPI, ListPageCommonState, PageHeaderProps, SidebarProps } from '@complex/ListingPage/Context/ListingPageCommon.context'
import ListingPageContainer from '@complex/ListingPage/ListingPageContainer'
import { CatalogLink } from '@complex/ListingPage/Utils/ListingPage.constants'
import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { checkUrlForTableV2 } from '@components/TableV2/utils/tableV2Utils'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { getAutomatedProgramTemplatesListingPageRequests } from '@src/pages/listingPages/AutomatedProgramTemplates/GraphQL/AutomatedProgramTemplatesListingPageRequests.graphQL'
import { getCustomEmptyListingProps, getCustomFilterParams, renderCustomModal } from '@src/pages/listingPages/AutomatedProgramTemplates/utils/Helpers'
import { AutomatedProgramTemplateCustomModals } from '@src/pages/listingPages/AutomatedProgramTemplates/utils/ListingPage.constants'
import { tableProps, tablePropsV2 } from '@src/pages/listingPages/AutomatedProgramTemplates/utils/ListingPage.tables'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import { allTemplatesFilter } from '@utils/filter'

import './AutomatedProgramTemplatesListingPageContainer.css'

interface AutomatedProgramTemplatesListingPageContainerProps {
  className?: string
  dataTest?: string
}

const rootClass = 'automated-program-templates-listing-page-container'

const AutomatedProgramTemplatesListingPageContainer: FC<AutomatedProgramTemplatesListingPageContainerProps> = (
  props: AutomatedProgramTemplatesListingPageContainerProps
) => {
  const { dataTest = rootClass } = props
  const { userAllowedToCreatePrograms, userAllowedToDeletePrograms } = useAccountSettings()
  const { t } = useTranslation()
  const appoloClient = useApolloClient()

  const apolloClient = useApolloClient()
  const { deleteItemsRequest } = getAutomatedProgramTemplatesListingPageRequests(apolloClient)

  const sidebarProps: SidebarProps = {
    sidebarHeader: 'Template Manager',
    hasRecent: false,
    hasCreatedByMe: true,
    allItemFilter: allTemplatesFilter,
  }

  const pageHeaderButtonTitle = userAllowedToCreatePrograms ? {} : { title: t('Ask your administrator for permission to do this'), disabled: true }

  const pageHeaderProps: PageHeaderProps = {
    pageTitle: 'Program Templates',
    renderPageHeaderContent: (update) => (
      <>
        <Button
          className={`${rootClass}__catalog`}
          buttonType={ButtonType.TERTIARY}
          iconPosition={ButtonIconPosition.LEFT}
          isLink
          to={CatalogLink.PROGRAM}
          disabled={!userAllowedToCreatePrograms}
          target={'_blank'}
        >
          <Svg type={SvgType.ICON} name={SvgNames.shareToCatalog} />
          <Typography text={t('Content catalog')} />
        </Button>
        <Button
          {...pageHeaderButtonTitle}
          className={`${rootClass}__new-template`}
          buttonType={ButtonType.PRIMARY}
          iconPosition={ButtonIconPosition.LEFT}
          onClick={() => update({ showCustomModal: true, customTableAction: AutomatedProgramTemplateCustomModals.CREATE_TEMPLATE })}
        >
          <Svg type={SvgType.ICON} name={SvgNames.plus} />
          <Typography text={'New template'} type={TextType.BODY_TEXT_WHITE} />
        </Button>
      </>
    ),
  }

  return (
    <ListingPageContainer
      listingPageProps={{
        customDeleteItemsCall: deleteItemsRequest,
        canDeleteItems: userAllowedToDeletePrograms,
        canEdit: userAllowedToCreatePrograms,
        canCreate: userAllowedToCreatePrograms,
        getCustomEmptyListingProps,
        getCustomActiveFilter: (_filter: string) => undefined,
        getCustomFilterParams,
        renderCustomModal: (
          customTableAction: AutomatedProgramTemplateCustomModals,
          listPageValues: ListPageCommonState,
          listPageAPI: Partial<ListPageCommonContextAPI<ListPageCommonState>>
        ) => renderCustomModal(customTableAction, listPageValues, listPageAPI, appoloClient, t),
        sidebarProps,
        pageHeaderProps,
        sortBy: [{ id: 'm_creationTime', desc: true, fieldType: 'TIMESTAMP' }],
        hasTabs: true,
        tableProps: checkUrlForTableV2() ? tablePropsV2(t, userAllowedToCreatePrograms) : tableProps(t, userAllowedToCreatePrograms),
      }}
      className={rootClass}
      dataTest={dataTest}
      itemType={ItemType.PROGRAM_TEMPLATE}
    />
  )
}

export default AutomatedProgramTemplatesListingPageContainer
