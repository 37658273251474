import React, { FC } from 'react'
import { FieldValues, UseFormRegister } from 'react-hook-form'

import MessagePickerModal from '@complex/MessagePickerModal'
import { MessageType } from '@complex/MessagePickerModal/messagePickerModalUtils'
import Select from '@components/Select/Select'
import { useTranslation } from '@const/globals'
import { ProgramMessage } from '@graphql/types/query-types'
import { Message } from '@interface/messages/Message'

export const ADD_MESSAGE = 'addMessage'

export interface State {
  value?: string
  addingMessage: boolean
}

interface MessageSelectProps {
  messages: Message[]
  onMessageUpdate(message: Message): void
  name: string
  register: UseFormRegister<FieldValues>
  noMessageLabel?: string
  value?: string
  label?: string
  programId?: string
  programName?: string
  allowedMessageCategories?: MessageType[]
  onChange?(event: React.ChangeEvent<HTMLSelectElement>): void
  programWillOwnMessage?: boolean
  className?: string
  dataTest?: string
}

export function getMessageOptions(messages: Message[], t: Function, noMessageLabel?: string) {
  return messages?.reduce(
    (acc, cur) => {
      return [
        ...acc,
        <option key={cur.id} value={cur.id}>
          {cur.name ?? cur.title ?? cur.subject}
        </option>,
      ]
    },
    [
      <option key="empty" value="">
        --- {noMessageLabel ? noMessageLabel : t('Select Message')} ---
      </option>,
    ]
  )
}

export const MessageSelect: FC<MessageSelectProps> = (props: MessageSelectProps) => {
  const {
    dataTest = 'message-select',
    label,
    noMessageLabel,
    className,
    name,
    register,
    messages,
    value,
    programId,
    programName,
    onMessageUpdate,
    allowedMessageCategories,
    onChange,
  } = props

  const { t } = useTranslation()

  const [state, setState] = React.useState<State>({
    value: value ?? '',
    addingMessage: false,
  })

  const messageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (onChange) {
      onChange(event)
    }
    if (event.target.value === ADD_MESSAGE) {
      setState({
        ...state,
        addingMessage: true,
      })
    } else {
      setState({
        ...state,
        value: event.target.value,
      })
    }
  }

  return (
    <>
      {state.addingMessage && (
        <MessagePickerModal
          dataTest={`${dataTest}-message-picker-modal`}
          programId={programId}
          programName={programName}
          isOpen
          multiSelect={false}
          closeModal={() => {
            setState({
              ...state,
              addingMessage: false,
            })
          }}
          onSubmitMessage={(messages: ProgramMessage[]) => {
            const message = messages[0]
            onMessageUpdate(message)
            setState({
              ...state,
              addingMessage: false,
              value: message.id,
            })
          }}
          allowedMessageCategories={allowedMessageCategories}
          programWillOwnMessage={props.programWillOwnMessage}
        />
      )}
      <Select
        label={label}
        dataTest={`${dataTest}-select`}
        register={register(name, { onChange: messageChange })}
        name={name}
        value={state.value}
        className={className}
      >
        {getMessageOptions(messages, t, noMessageLabel)}
        <option value={ADD_MESSAGE}>--- {t('Click To Add New Message To The Program')} ---</option>
      </Select>
    </>
  )
}

export default MessageSelect
