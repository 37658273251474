import React, { Dispatch, SetStateAction, useContext } from 'react'
import { Row } from 'react-table'

import { SvgNames } from '@components/Svg'
import { RowAction } from '@components/Table/Table'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { List } from '@interface/foldersLists/List'
import { StepTwoState } from '@src/pages/ContactSegments/components/CopySegments/components/StepTwo/StepTwo'
import { CopySegmentsContext } from '@src/pages/ContactSegments/components/CopySegments/context/CopySegments.context'

export const useColumns = (): ColumnDefWithAdditionalProps<List>[] => {
  const {
    values: { segments },
  } = useContext(CopySegmentsContext)
  const isTableEmpty = segments.length === 0
  return [
    {
      header: 'Segments',
      accessorKey: 'name',
      textAlign: 'left',
      maxSize: 200,
      cell: ({ row: { original } }) => (
        <TextWithTooltipOnEllip
          typographyProps={{
            title: original.name,
            text: original.name,
            type: isTableEmpty ? TextType.BODY_TEXT_LIGHT : undefined,
            weight: isTableEmpty ? TextWeight.ITALIC : TextWeight.REGULAR,
          }}
        />
      ),
    },
    {
      header: 'Records',
      accessorKey: 'size',
      textAlign: 'left',
      maxSize: 100,
    },
  ]
}

interface GetRowActionsParams {
  setState: Dispatch<SetStateAction<StepTwoState>>
}

export const useRowActions = ({ setState }: GetRowActionsParams): RowAction[] => {
  const { t } = useTranslation()
  return [
    {
      label: t('Copy.Segments.Page.Step.Two.DeleteConfirmationModal.DeleteButtonText'),
      icon: SvgNames.delete,
      onClick: ({ original }: Row<List>) => setState((state) => ({ ...state, segmentToRemove: original })),
    },
  ]
}
