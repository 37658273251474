import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { ExtraInfo } from '@src/pages/datamanagement/components/CrmContacts/components/DynamicList/utils/DynamicList.interfaces'
import { checkFieldUsage, removeTemporaryRow } from '@src/pages/datamanagement/components/CrmContacts/CrmContactUtil'
import { UseCRMContactsRowHandlerProps } from '@src/pages/datamanagement/components/CrmContacts/utils/useCRMContactsRowHandler/useCRMContactsRowHandler'
import { DataManagementContext } from '@src/pages/datamanagement/context/DataManagementContext'
import { TableType } from '@utils/crm.utils'
import { useFormService } from '@utils/hooks/microservices/useFormService'

export type CRMContactsDeleteRowFunction = (rowId: number, extraInfo?: ExtraInfo) => void

type UseCRMContactsDeleteRowHandlerFunction = {
  deleteRow: CRMContactsDeleteRowFunction
}

type UseCRMContactsDeleteRowHandlerProps = Pick<UseCRMContactsRowHandlerProps, 'dataLoaded' | 'updateDisplayedIcons'>

const NEW_ROWS_IDS_BASE = 1000

export const useCRMContactsDeleteRowHandler = (props: UseCRMContactsDeleteRowHandlerProps): UseCRMContactsDeleteRowHandlerFunction => {
  const { dataLoaded, updateDisplayedIcons } = props

  const {
    userUpdates,
    update,
    values: { fieldMappings, fieldMappingStatus, fieldMappingUsageWarning, pushPolicy, unifiedListFieldMappings },
  } = useContext(DataManagementContext)

  const { t } = useTranslation()

  const { getFormsByColumnNameRequest } = useFormService()

  const removeFromDataLoaded = (rowId: number, extraInfo?: ExtraInfo) => {
    if (extraInfo?.tableType === TableType.STANDARD) {
      const standardRowsUpdated = removeTemporaryRow(dataLoaded.standardRows).filter((row) => row.id !== rowId)
      updateDisplayedIcons(standardRowsUpdated.concat(dataLoaded.customRows).filter((row) => row.id !== -1))
    } else {
      const customRowsUpdated = removeTemporaryRow(dataLoaded.customRows).filter((row) => row.id !== rowId)
      updateDisplayedIcons(dataLoaded.standardRows.concat(customRowsUpdated).filter((row) => row.id !== -1))
    }
  }

  const deleteNewField = (rowId: number, extraInfo?: ExtraInfo) => {
    removeFromDataLoaded(rowId, extraInfo)
    const localCopy = fieldMappings.filter((entry) => entry.columnIndex != rowId)
    userUpdates('fieldMappings', localCopy)
    const rows = extraInfo?.tableType === TableType.STANDARD ? dataLoaded.standardRows : dataLoaded.customRows
    const row = rows.find(({ id }) => id === rowId)
    const actonContactsColumn = row?.columns.find(({ column }) => column === t('ACT-ON CONTACTS'))
    const displayName =
      actonContactsColumn?.staticValue || actonContactsColumn?.values?.find(({ key }) => key === actonContactsColumn.selected)?.display
    const enabledFields = (pushPolicy?.enabledFields ?? []).filter((field) => field !== displayName)
    update('pushPolicy', { ...pushPolicy, allowPush: !!pushPolicy?.allowPush, enabledFields })
  }

  const deleteExistingField = async (row: number | UnifiedListFieldMapping, extraInfo?: ExtraInfo) => {
    // find the matching entry in unifiedFieldsListMapping
    const field = typeof row === 'number' ? unifiedListFieldMappings.find(({ columnIndex }) => columnIndex === row) : row
    if (field) {
      const isBeingUsed = await checkFieldUsage(field, update, getFormsByColumnNameRequest, fieldMappingUsageWarning)
      if (isBeingUsed) {
        return
      }

      removeFromDataLoaded(field.columnIndex, extraInfo)

      // make a copy so we can edit it
      const deleteRow = { ...field }
      if (deleteRow) {
        deleteRow.deleted = true
      }

      // insert it into our array
      const entryLocation = fieldMappings.findIndex((item) => item.columnIndex === field.columnIndex)
      const updatedMappings =
        entryLocation === -1
          ? [...fieldMappings, deleteRow]
          : [...fieldMappings.slice(0, entryLocation), deleteRow, ...fieldMappings.slice(entryLocation + 1)]

      userUpdates('fieldMappings', updatedMappings)
      const enabledFields = (pushPolicy?.enabledFields ?? []).filter((field) => field !== deleteRow.displayName)
      update('pushPolicy', { ...pushPolicy, allowPush: !pushPolicy?.allowPush, enabledFields })

      if (fieldMappingStatus.has(field.columnIndex)) {
        fieldMappingStatus.delete(field.columnIndex)
      }
    }
  }

  const deleteRow = async (rowId: number, extraInfo?: ExtraInfo) => {
    // is this a new row?
    if (rowId >= NEW_ROWS_IDS_BASE || fieldMappings.filter((entry) => entry.columnIndex === rowId).length > 0) {
      deleteNewField(rowId, extraInfo)
    } else {
      await deleteExistingField(rowId, extraInfo)
    }
  }

  return { deleteRow }
}
