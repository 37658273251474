import React, { FC, useState } from 'react'

import classNames from 'classnames'
import { useDebouncedCallback } from 'use-debounce'

import Button, { ButtonType } from '@components/Button'
import { ButtonSize } from '@components/Button/Button'
import StatusToast, { Status } from '@components/StatusToast/StatusToast'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { logNewRelicError } from '@utils/new-relic.utils'
import { download } from '@utils/utils'

import './DownloadButton.css'

interface Props {
  url?: string
  filename?: string
  downloadFn?: () => void
  getDownloadUrl?: () => Promise<string>
  label?: string
  tooltipText?: string
  className?: string
  dataTest?: string
  hasToolTip?: boolean
}

const rootClass = 'download-button'

const DEBOUNCE_TIME = 500

const DownloadButton: FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const {
    url,
    filename,
    label,
    tooltipText = t('Download'),
    dataTest = rootClass,
    hasToolTip = true,
    className = '',
    downloadFn,
    getDownloadUrl,
  } = props
  const [error, setError] = useState(false)

  const onDownload = async () => {
    const finalUrl = await Promise.resolve(url ?? (getDownloadUrl && getDownloadUrl()))
    if (finalUrl && filename) {
      try {
        download(finalUrl, filename)
      } catch (e) {
        logNewRelicError(e)
        setError(true)
      }
    } else if (downloadFn) {
      downloadFn()
    }
  }

  const debouncedDownload = useDebouncedCallback(onDownload, DEBOUNCE_TIME)

  const buttonSize = label ? { buttonSize: ButtonSize.MEDIUM } : {}

  const button = () => (
    <Button {...buttonSize} buttonType={ButtonType.ICON} onClick={debouncedDownload} data-test={dataTest}>
      <Svg name={SvgNames.download} type={SvgType.LARGER_ICON} />
      {label && <Typography text={label} inline className={`${rootClass}__label`} />}
    </Button>
  )

  const renderButton = () => {
    return hasToolTip ? (
      <Tooltip triggerClassName={classNames(rootClass, className)} trigger={button()}>
        <Typography text={tooltipText} type={TextType.BODY_TEXT_WHITE_SMALL} lineHeight={LineHeight.MEDIUM} />
      </Tooltip>
    ) : (
      <div>{button()}</div>
    )
  }

  return (
    <>
      {error && <StatusToast message={t('Unable to generate file for download')} status={Status.FAIL} closeStatus={() => setError(false)} />}
      {renderButton()}
    </>
  )
}

export default DownloadButton
