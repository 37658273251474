import { ProgramAppendToSegmentStep, ProgramCopyFromStep, ProgramCopyStep, ProgramFieldSetStep } from '@graphql/types/query-types'
import { ChangeFieldRules } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/components/ListProgramStep/components/ChangeFieldStepEditor/utils/ChangeFieldStepEditor.constants'
import { StepTypesFields } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/components/ListProgramStep/components/StepTypeSelector/StepTypeSelector'
import { ProgramStepType } from '@utils/program/program.constants'

export enum UpdateType {
  APPEND_AND_MERGE_WITH_UPDATE = 'INSERT_OR_OVERWRITE',
  APPEND_AND_MERGE = 'INSERT_OR_UPDATE',
  MERGE_WITH_UPDATE = 'OVERWRITE',
  MERGE = 'UPDATE',
}

export const marketingListStepOptions: StepTypesFields[] = [
  {
    type: ProgramStepType.FIELD_SET,
    header: 'Change field value',
    subheader: 'Set values in one or more fields',
  },
  {
    type: ProgramStepType.COPY,
    header: 'Copy to a list',
    subheader: 'Add contacts to a list',
  },
  {
    type: ProgramStepType.COPY_FROM,
    header: 'Copy from a segment',
    subheader: 'Add contacts from a segment',
    isActonContacts: true,
  },
]
export const actonContactsStepOptions = (showAppendToSegment: boolean): StepTypesFields[] => [
  {
    type: ProgramStepType.FIELD_SET,
    header: 'Change field value',
    subheader: 'Set values in one or more fields',
    isActonContacts: true,
  },
  ...(showAppendToSegment
    ? [
        {
          type: ProgramStepType.APPEND_TO_SEGMENT,
          header: 'Append to a segment',
          subheader: 'Add contacts to a segment',
          isActonContacts: true,
        },
      ]
    : []),
]

export const defaultCopyFromSegmentStep: Omit<ProgramCopyFromStep, 'displayName'> = {
  stepType: ProgramStepType.COPY_FROM,
  srcId: '',
  pruneRecords: false,
  updateType: UpdateType.APPEND_AND_MERGE,
}

export const defaultCopyToListStep: Omit<ProgramCopyStep, 'displayName'> = {
  stepType: ProgramStepType.COPY,
  srcId: '',
  all: true,
  not: false,
  listId: '',
  update: false,
  updateType: UpdateType.APPEND_AND_MERGE,
}

export const defaultAppendToSegmentStep: Omit<ProgramAppendToSegmentStep, 'displayName'> = {
  stepType: ProgramStepType.APPEND_TO_SEGMENT,
  srcId: '',
  all: true,
  not: false,
  listId: '',
  pruneRecords: false,
}

export const defaultChangeFieldValueStep: Omit<ProgramFieldSetStep, 'displayName'> = {
  stepType: ProgramStepType.FIELD_SET,
  srcId: '',
  not: false,
  listId: 'l-unified-contacts',
  listName: '',
  fieldName: '',
  value: '',
  rule: ChangeFieldRules.SET_VALUE,
  campaignId: '',
  scoreSheetId: '',
  canPushSF: false,
}
