import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Modal, { ModalBody } from '@components/Modal'
import StaticImage from '@components/StaticImage/StaticImage'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Typography, { LineHeight, TextAlign, TextType, TextWeight } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'

import './SuccessfullyUpgradedModal.css'

interface SuccessfullyUpgradedModalProps {
  className?: string
  dataTest?: string
  contactsAdded: number
  programName: string
  programId: string
  onBackToUpgradeAssistant: () => void
  isOpen: boolean
}

const rootClass = 'successfully-upgraded-modal'

const SuccessfullyUpgradedModal: FC<SuccessfullyUpgradedModalProps> = (props: SuccessfullyUpgradedModalProps) => {
  const { dataTest = rootClass, className = '', isOpen, contactsAdded = 0, programName = '', programId = '', onBackToUpgradeAssistant } = props

  const { t } = useTranslation()

  const onViewProgramSettings = () => {
    window.open(`${rootContext}/automation/programs/${programId}/settings`, '_self')
  }

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen}>
      <ModalBody className={`${rootClass}__body`}>
        <StaticImage name={StaticImageNames.successCircle} />
        <Typography
          type={TextType.MODAL_HEADLINE_LARGE}
          lineHeight={LineHeight.LARGER}
          weight={TextWeight.MEDIUM}
          text={t('The program has been restarted')}
        />
        <Typography
          type={TextType.BODY_TEXT_LIGHT}
          text={'{{name}} has been restarted. {{contactsAdded}} new contacts have entered the program.'}
          values={{ name: programName, contactsAdded }}
          textAlign={TextAlign.CENTER}
        />
        <div className={`${rootClass}__buttons-container`}>
          <Button buttonType={ButtonType.PRIMARY} onClick={onViewProgramSettings}>
            {t('View the program settings')}
          </Button>
          <Button buttonType={ButtonType.WHITE} onClick={onBackToUpgradeAssistant}>
            {t('Back to Upgrade Assistant')}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default SuccessfullyUpgradedModal
