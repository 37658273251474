import React, { FC, useEffect, useMemo, useState } from 'react'
import { Prompt } from 'react-router-dom'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import Caution from '@components/Caution/Caution'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import DirtyBanner from '@components/DirtyBanner/DirtyBanner'
import { DiscardModalActions } from '@components/DiscardModal/DiscardModal'
import InfoAction from '@components/InfoAction/InfoAction'
import Loader from '@components/Loader'
import PageContainer from '@components/PageContainer'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import StatusToast from '@components/StatusToast/StatusToast'
import { SvgNames } from '@components/Svg'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import saveIpAddresses from '@graphql/mutations/saveIpAddresses'
import getIpAddresses from '@graphql/queries/getIpAddresses'
import { SaveIgnoreIpsMutation, SaveIgnoreIpsMutationVariables } from '@graphql/types/mutation-types'
import { GetIgnoreIpsQuery, GetIgnoreIpsQueryVariables } from '@graphql/types/query-types'
import { StatusToastType } from '@interface/StatusToast'
import SingleInput from '@src/pages/Settings/OtherSettings/IpAddresses/components/SingleInput/SingleInput'
import { MORE_INFO } from '@src/pages/Settings/OtherSettings/IpAddresses/utils/IpAddressesToIgnore.constants'
import { logNewRelicError } from '@utils/new-relic.utils'

import './ipAddressesToIgnore.css'

const rootClass = 'ip-addresses'

const IpAddressesToIgnore: FC = () => {
  const [openDiscardModal, setOpenDiscardModal] = useState(false)
  const [toast, setToastStatus] = useState<StatusToastType>()
  const [ipAddresses, setIpAddresses] = useState<string[][]>([])
  const [columnsArray, setColumnsArray] = useState<string[][]>([])
  const [columnLength, setColumnLength] = useState<number>(10)
  const [ipsValidity, setIpsValidity] = useState<object>({})
  const [discardAction, setDiscardAction] = useState<string>('')
  const [showSaveBanner, setShowSaveBanner] = useState(false)
  const { t } = useTranslation()

  const client = useApolloClient()
  const { data, loading, error, refetch } = useQuery<GetIgnoreIpsQuery, GetIgnoreIpsQueryVariables>(getIpAddresses, {
    client,
    fetchPolicy: 'network-only',
  })

  const variables = useMemo(
    () => ({
      ips: ipAddresses.flat(1),
    }),
    [ipAddresses]
  )

  const [saveIps] = useMutation<SaveIgnoreIpsMutation, SaveIgnoreIpsMutationVariables>(saveIpAddresses, {
    client,
    fetchPolicy: 'no-cache',
    variables: variables,
  })

  useEffect(() => {
    const ips = data?.getIgnoreIps?.ips
    const maxNumberEntries = data?.getIgnoreIps?.maxNumberEntries
    if (!loading && ips && maxNumberEntries) {
      const columnMaxLength = Math.max(Math.ceil(maxNumberEntries / 3), 10)
      setColumnLength(columnMaxLength)
      const arr: string[][] = [
        Array(columnMaxLength).fill(''),
        Array(columnMaxLength).fill(''),
        Array(maxNumberEntries - 2 * columnMaxLength).fill(''),
      ]
      ips.forEach((value: string, index: number) => {
        const columnIndex = Math.floor(index / columnMaxLength)
        const arrayIndex = index - columnIndex * columnMaxLength
        arr[columnIndex][arrayIndex] = value
      })
      setIpAddresses(arr)
      setColumnsArray(JSON.parse(JSON.stringify(arr)))
    }

    if (error) {
      logNewRelicError(error)
    }
  }, [loading, data])

  return (
    <>
      <Prompt
        when={JSON.stringify(ipAddresses) !== JSON.stringify(columnsArray)}
        message={t('You have unsaved changes. Are you sure you want to leave?')}
      />
      <PageContainer className={rootClass}>
        {showSaveBanner && (
          <DirtyBanner
            expanded
            onSave={() => {
              if (Object.values(ipsValidity).includes(false)) {
                setToastStatus({
                  showStatus: true,
                  statusMessage: t('Something doesn’t look right. Please ensure all IP addresses have been entered correctly.'),
                  successStatus: false,
                })
              } else {
                saveIps()
                  .then(() => {
                    setToastStatus({
                      showStatus: true,
                      statusMessage: t('Got it. We’ll exclude activity from these IP addresses.'),
                      successStatus: true,
                    })
                    refetch()
                  })
                  .catch((error) => {
                    logNewRelicError(error)
                  })
                setIpsValidity({})
                setShowSaveBanner(false)
              }
            }}
            onDiscard={() => {
              setOpenDiscardModal(true)
              setShowSaveBanner(false)
              setIpsValidity({})
            }}
          />
        )}
        {openDiscardModal && (
          <ConfirmationModal
            isOpen={openDiscardModal}
            isDelete
            title={t('Are you sure?')}
            body={t("If you discard now, you'll lose any changes you've made.")}
            deleteButtonText={t('Discard Changes')}
            cancelButtonText={t('Continue Editing')}
            onAnswer={(answer) => {
              if (answer === YesNo.YES) {
                setIpAddresses(JSON.parse(JSON.stringify(columnsArray)))
                setDiscardAction(DiscardModalActions.DISCARD)
              } else {
                setDiscardAction(DiscardModalActions.CONTINUE)
              }
              setOpenDiscardModal(false)
            }}
            className={`${rootClass}__confirmation`}
          />
        )}
        {loading && <Loader center />}
        {!loading && (
          <div className={rootClass}>
            {!loading && toast?.showStatus && (
              <StatusToast
                isSuccess={toast.successStatus}
                message={toast.statusMessage}
                title={toast.title}
                closeStatus={() => {
                  setToastStatus({ showStatus: false })
                }}
              />
            )}
            <PositionContainer>
              <Typography className={`${rootClass}__title`} text={'IP Addresses to Ignore'} type={TextType.PAGE_HEADER} />
              <div className={`${rootClass}__sub-header-container`}>
                <Typography
                  className={`${rootClass}__sub-header`}
                  text={t(
                    `Add IP addresses to exclude activity such as clicks and opens. This removes unwanted activity from all future account reports (website visitors, forms, landing pages, email messages). To get you started, we've added some common anti-spam filters.`
                  )}
                  type={TextType.BODY_TEXT_LIGHT}
                />
                <TextLink text={t('More info')} link={MORE_INFO} className={`${rootClass}__more-info`} size={TextLinkSize.LARGE} />
              </div>
              <InfoAction
                className={`${rootClass}__info-action`}
                message={t(
                  'Enter addresses in one of the following formats: a single IP address, a range between two IP addresses, or as a range in the last octet. Examples: 198.51.100.10, 203.0.113.0-203.0.113.254, and 233.252.0.0-255'
                )}
                svgName={SvgNames.lightBulb}
              />
              <div className={`${rootClass}__inner-container`}>
                <Caution message={t('You may experience slow performance while we process changes to your IP settings.')} />
                <div className={`${rootClass}__list-container`}>
                  <div className={`${rootClass}__list`}>
                    {ipAddresses &&
                      ipAddresses.map((ips: Array<string>, columnIndex: number) => {
                        return (
                          <div className={`${rootClass}__ip-column`} key={columnIndex}>
                            {ips.map((item: string, index: number) => {
                              return (
                                <div key={index} className={`${rootClass}__single-input-container`}>
                                  <SingleInput
                                    value={item}
                                    index={index}
                                    columnIndex={columnIndex}
                                    columnLength={columnLength}
                                    discardModal={openDiscardModal}
                                    state={ipAddresses}
                                    setState={setIpAddresses}
                                    ipsValidity={ipsValidity}
                                    updateValidity={setIpsValidity}
                                    initialData={columnsArray}
                                    discardAction={discardAction}
                                    setDiscardAction={setDiscardAction}
                                    setShowSaveBanner={setShowSaveBanner}
                                  />
                                </div>
                              )
                            })}
                          </div>
                        )
                      })}
                  </div>
                </div>
              </div>
            </PositionContainer>
          </div>
        )}
      </PageContainer>
    </>
  )
}

export default IpAddressesToIgnore
