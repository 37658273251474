import React, { FC, useContext, useMemo } from 'react'

import classNames from 'classnames'

import Breadcrumbs, { BreadcrumbsItemType } from '@components/Breadcrumbs/Breadcrumbs'
import DirectSelectSegmentBuilder from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/DirectSelectSegmentBuilder/DirectSelectSegmentBuilder'
import QuerySegmentBuilder from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/QuerySegmentBuilder/QuerySegmentBuilder'
import { SegmentComposerContext } from '@src/pages/SegmentComposer/SegmentComposer.context'
import { getSegmentPathBreadcrumb } from '@src/pages/SegmentComposer/SegmentComposer.utils'

import './SegmentComposerBuild.css'

interface SegmentComposerBuildProps {
  className?: string
  dataTest?: string
  // TODO: only for VQA in storybook, remove it after implementing the backend integration
  isEmpty?: boolean
}

const rootClass = 'segment-composer-build'

const SegmentComposerBuild: FC<SegmentComposerBuildProps> = (props: SegmentComposerBuildProps) => {
  const { dataTest = rootClass, className = '', isEmpty } = props

  const {
    values: { segment, segmentPath },
  } = useContext(SegmentComposerContext)

  const { name } = segment

  const segmentTreeBreadcrumbs = useMemo<BreadcrumbsItemType[]>(() => getSegmentPathBreadcrumb(segmentPath, name), [name, segmentPath])

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__breadcrumbs-container`}>
        <Breadcrumbs className={`${rootClass}__breadcrumbs`} breadcrumbs={segmentTreeBreadcrumbs} />
      </div>
      {segment.isDirectSelect ? (
        <DirectSelectSegmentBuilder dataTest={`${dataTest}-direct-select`} />
      ) : (
        <QuerySegmentBuilder dataTest={`${dataTest}-query`} isEmpty={isEmpty} />
      )}
    </div>
  )
}

export default SegmentComposerBuild
