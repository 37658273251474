import { gql } from '@apollo/client'

export default gql`
  mutation deleteWebhookDefinition($webhookId: String!) {
    deleteWebhookDefinition(webhookId: $webhookId) {
      status
      body
    }
  }
`
