import React, { MouseEvent } from 'react'

// eslint-disable-next-line no-restricted-imports
import { format } from 'date-fns'

import { SvgNames } from '@components/Svg'
import { HeaderAction, RowAction } from '@components/TableV2/tableV2TS/interfaces'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography from '@components/Typography/Typography'
import { InboxAsset, InboxModals } from '@src/pages/MarketingNetwork/Inbox/Inbox.constants'
import { typeToText } from '@src/pages/MarketingNetwork/Inbox/Inbox.utils'
import { CellContext, Row } from '@tanstack/react-table'
import { getAbbreviatedTimeZone } from '@utils/date'

const renderCell = (text: string, dataTest?: string) => <TextWithTooltipOnEllip typographyProps={{ text, dataTest }} />

export const inboxColumns: ColumnDefWithAdditionalProps<InboxAsset>[] = [
  {
    accessorKey: 'title',
    header: () => 'Asset Name',
    cell: (cell: CellContext<InboxAsset, any>) => renderCell(cell.getValue(), 'inbox-asset-name'),
    sortingFn: 'alphanumeric',
    textAlign: 'left',
  },
  {
    accessorKey: 'type',
    header: () => 'Asset Type',
    cell: (cell: CellContext<InboxAsset, any>) => renderCell(typeToText(cell.getValue(), false), 'inbox-asset-type'),
    sortingFn: (rowA: Row<InboxAsset>, rowB: Row<InboxAsset>) =>
      typeToText(rowA.getValue<string>('type'), false) > typeToText(rowB.getValue<string>('type'), false) ? 1 : -1,
    textAlign: 'left',
    maxSize: 252,
  },
  {
    accessorKey: 'fromUser',
    header: () => 'Sender',
    cell: (cell: CellContext<InboxAsset, any>) => renderCell(cell.getValue()),
    sortingFn: 'alphanumeric',
    textAlign: 'left',
    maxSize: 160,
  },
  {
    accessorKey: 'date',
    header: () => 'Date',
    cell: (cell: CellContext<InboxAsset, any>) => {
      const value = cell.getValue() ? cell.getValue().valueOf() : ''
      if (value) {
        return (
          <Tooltip
            neverEllip
            trigger={
              <Typography text={`${format(value, `MMM d, yyyy 'at' h:mm a`)} ${getAbbreviatedTimeZone(value)}`} className={'inbox__created'} />
            }
          >{`${format(value, `MMM d, yyyy 'at' h:mm a`)} ${getAbbreviatedTimeZone(value)}`}</Tooltip>
        )
      }
      return ''
    },
    sortingFn: (rowA: Row<InboxAsset>, rowB: Row<InboxAsset>) => rowA.getValue<number>('date') - rowB.getValue<number>('date'),
    textAlign: 'left',
    minSize: 242,
  },
]

interface InboxRowActionsProps {
  onImportPreview: (row?: Row<InboxAsset>) => void
  onDeclinePreview: (row?: Row<InboxAsset>) => void
}

export const inboxRowActions = ({ onImportPreview, onDeclinePreview }: InboxRowActionsProps): RowAction<InboxAsset>[] => {
  return [
    {
      label: 'Import',
      icon: SvgNames.checkCircle,
      onClick: (row) => onImportPreview(row),
    },
    {
      label: 'Decline',
      icon: SvgNames.errorCircle,
      onClick: (row) => onDeclinePreview(row),
    },
  ]
}

export const headerRowActions = (onShowModal: (modal: string) => void): HeaderAction<InboxAsset>[] => {
  return [
    {
      label: 'Import',
      hasTooltip: true,
      icon: SvgNames.checkCircle,
      onClick: (_event: MouseEvent<HTMLButtonElement> | Event) => onShowModal(InboxModals.IMPORT),
    },
    {
      label: 'Decline',
      hasTooltip: true,
      icon: SvgNames.errorCircle,
      onClick: (_event: MouseEvent<HTMLButtonElement> | Event) => onShowModal(InboxModals.DECLINE),
    },
  ]
}
