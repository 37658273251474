import React, { FC } from 'react'

import SectionHeadline from '@components/SectionHeadline'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { AddressResponse } from '@graphql/types/query-types'
import CompanyInfoSection from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Company/components/CompanyInfoSection/CompanyInfoSection'
import { OTHER_ADDRESSES_INFO_LINK } from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Company/constants/company.constants'

import './AddressesList.css'

interface Props {
  addresses: (AddressResponse | undefined)[]
  onAddressEdit: (address: AddressResponse) => void
}
export const addressToString = ({ street, street2, city, state, zip, country }: AddressResponse): string =>
  `${[street, street2, city, state].filter((field) => !!field).join(', ')} ${[zip, country].filter((field) => !!field).join(', ')}`

const rootClass = 'company__addresses-list'

const AddressesList: FC<Props> = (props: Props) => {
  const { addresses, onAddressEdit } = props
  const { t } = useTranslation()

  if (!addresses.length) {
    return null
  }

  return (
    <div className={rootClass}>
      <SectionHeadline className={`${rootClass}-title`}>{t('Other addresses')}</SectionHeadline>
      <div className={`${rootClass}-description`}>
        <Typography text={t('Other.Addresses.Description')} inline />
        &nbsp;
        <TextLink text={t('More info')} link={OTHER_ADDRESSES_INFO_LINK} size={TextLinkSize.LARGE} />
      </div>
      {addresses.map(
        (address) =>
          address?.name && (
            <CompanyInfoSection key={address.UUID} metaTitle={address.name} value={addressToString(address)} onEdit={() => onAddressEdit(address)} />
          )
      )}
    </div>
  )
}

export default AddressesList
