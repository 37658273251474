import React from 'react'

import { TFunction } from 'i18next'

import { RenderCustomFiltersParams } from '@complex/ListingPage/Context/ListingPageCommon.context'
import Radio from '@components/Radio'
import RadioGroup from '@components/RadioGroup'
import { SvgNames } from '@components/Svg'
import { scheduledByMarketingFilter, scheduledByMeFilter, scheduledBySalesFilter } from '@utils/filter'
import { toTrainCase } from '@utils/strings'

const rootClass = 'email-picker-modal'

export const emailScheduledPickerFilters = [scheduledByMarketingFilter, scheduledBySalesFilter, scheduledByMeFilter]

export const renderScheduledCustomFilters = (
  { activeFilter, menuActions, filterCounts, renderCustomFilterWithCount }: RenderCustomFiltersParams,
  t: TFunction
) => [
  {
    header: t('Scheduled by'),
    isOpen: true,
    icon: SvgNames.toggles,
    dataTest: 'menu-items',
    content: (
      <RadioGroup className={`listing-page__custom-filters`} verticalLayout>
        {emailScheduledPickerFilters.map((filter) =>
          renderCustomFilterWithCount(
            <Radio
              key={filter.name}
              label={filter.name}
              checked={activeFilter.name === filter.name}
              onChange={() => menuActions.clickCustomFilter(filter)}
              dataTest={`${rootClass}-${toTrainCase(filter.name)}-filter`}
            />,
            filterCounts[filter.name],
            filter.name
          )
        )}
      </RadioGroup>
    ),
  },
]
