import React, { FC, ReactNode, RefObject } from 'react'

import classNames from 'classnames'

import Typography, { TextType, TextWeight } from '@components/Typography/Typography'

import './pageHeadline.css'

const rootClass = 'page-headline'

export enum PageHeaderType {
  HEADER = 'HEADER',
  SUB_HEADER = 'SUB_HEADER',
  LARGE_HEADER = 'LARGE_HEADER',
  SECTION_HEADER = 'SECTION_HEADER',
  PAGE_HEADLINE = 'PAGE_HEADLINE',
}

interface PageHeadlineProps {
  type?: PageHeaderType
  title?: string
  children: ReactNode
  className?: string
  dataTest?: string
  reference?: RefObject<HTMLDivElement>
}

const TEXT_TYPES: { [key: string]: any } = {
  [PageHeaderType.SECTION_HEADER]: TextType.SECTION_HEADER,
  [PageHeaderType.SUB_HEADER]: TextType.PAGE_HEADER,
  [PageHeaderType.LARGE_HEADER]: TextType.PAGE_HEADLINE,
  [PageHeaderType.HEADER]: TextType.MODAL_HEADLINE,
}

/**
 * @deprecated use <Typography instead
 */
export const PageHeadline: FC<PageHeadlineProps> = (props: PageHeadlineProps) => {
  const { dataTest = rootClass, className, title, type = PageHeaderType.HEADER, reference } = props

  return (
    <Typography
      dataTest={dataTest}
      title={title}
      className={classNames(rootClass, className)}
      text={props.children}
      type={TEXT_TYPES[type]}
      weight={TextWeight.BOLD}
      reference={reference}
    />
  )
}

export default PageHeadline
