import { gql } from '@apollo/client'

export default gql`
  query getLogo($id: String) {
    getLogo(id: $id) {
      id
      title
      url
      alt
      target
      tooltip
      isDefault
      isLocal
      duplicated
      dimensions
      size
    }
  }
`
