import React, { FC, ReactNode, useEffect, useRef } from 'react'

import classNames from 'classnames'

import ScrollArea from '@components/ScrollArea/ScrollArea'

import './MenuItem.css'

export interface MenuItemProps {
  className?: string
  dataTest?: string
  content: ReactNode
  onScroll?: (event?: Event) => void
  hasOverflow?: boolean
}

const rootClass = 'menu-item'

const MenuItem: FC<MenuItemProps> = (props: MenuItemProps) => {
  const { content, onScroll, hasOverflow = false, dataTest = rootClass, className = '' } = props

  const scrollAreaRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (scrollAreaRef.current && onScroll) {
      const scrollArea: HTMLDivElement = scrollAreaRef?.current
      if (scrollArea) {
        scrollArea.addEventListener('scroll', onScroll)
        return () => scrollArea.removeEventListener('scroll', onScroll)
      }
    }
  }, [scrollAreaRef])

  if (hasOverflow) {
    return (
      <ScrollArea
        className={classNames(rootClass, className, `${rootClass}__scroll`)}
        data-test={dataTest}
        showOnEvent={'scroll'}
        register={scrollAreaRef}
      >
        {content}
      </ScrollArea>
    )
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {content}
    </div>
  )
}

export default MenuItem
