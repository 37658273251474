import { gql } from '@apollo/client'

export default gql`
  mutation addSforceUser($ids: [String]) {
    addSforceUser(ids: $ids) {
      sForceUsers {
        login
        loginKey
        email
        firstName
        lastName
        externalId
        role
        isActOnUser
        isNotAvailable
        zip
        country
        title
        cell
        userId
        phone
        street
        street2
        state
        fax
        city
      }
      isUpdated
      maxSforceSalesUsers
      numSforceSalesUsers
      numSforceSalesUsersAvailable
      status
      message
    }
  }
`
