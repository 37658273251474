import { useCallback } from 'react'

import { detectEmailType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'
import { useEmailComposerRequests } from '@utils/composer/emailComposer/GraphQL/EmailComposerRequests.graphQL'
import { logNewRelicError } from '@utils/new-relic.utils'

import { plainTextJsonToValue } from '../../PlainTextComposer/PlainTextComposer.utils'

export const useLoadPlainText = () => {
  const {
    api: { updatePreview },
    values: {
      message: { id, templateJson, isPlainTextOnly, messageType },
    },
  } = useComposerContext()
  const { getMessagePlainTextRequest } = useEmailComposerRequests()

  const { isLandingPage } = detectEmailType(messageType)

  return useCallback(
    async (dynamicContentIds?: (string | undefined)[]) => {
      updatePreview({ loadingPlainText: true })

      if (!isPlainTextOnly && !isLandingPage) {
        const { data, errors } = await getMessagePlainTextRequest({ messageId: id, dynamicContentIds })
        if (errors) {
          logNewRelicError(errors, 'Fetching email plain text')
          updatePreview({ loadingPlainText: false })
          return undefined
        } else if (data?.getMessagePlainText?.status === 'ok') {
          const trimmedAutoText = data?.getMessagePlainText.plainText?.trim()
          updatePreview({ plainText: trimmedAutoText, loadingPlainText: false })

          return { plainText: trimmedAutoText }
        }
      } else {
        const newPlainText = plainTextJsonToValue(templateJson).trim()
        updatePreview({ plainText: newPlainText, loadingPlainText: false })
        return { plainText: newPlainText }
      }
    },
    [updatePreview, getMessagePlainTextRequest, id, isLandingPage]
  )
}
