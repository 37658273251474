import { gql } from '@apollo/client'

export default gql`
  mutation setOptInOut($optInOutSettingsBean: OptInOutSettingsBeanInput) {
    setOptInOutSettings(setOptInOutSettings: $optInOutSettingsBean) {
      optInOutSyncSupported
      optInOutPullEnabled
      optInOutPushEnabled
    }
  }
`
