import React, { FC } from 'react'

import classNames from 'classnames'

import StaticImageNames, { StaticImageSvgs } from '@components/StaticImage/StaticImageNames'
import { getStaticImageRoot } from '@const/globals'

export interface StaticImageProps {
  name: StaticImageNames
  withTitle?: boolean
  altText?: string
  isStory?: boolean
  className?: string
  dataTest?: string
}

const rootClass = 'static-image'
const rootContext = getStaticImageRoot()

const StaticImage: FC<StaticImageProps> = (props: StaticImageProps) => {
  const { name, altText, isStory, dataTest = rootClass, className = '', withTitle = true } = props
  const location = window.location
  const isStorybook = location.search.includes('viewMode=story')
  const basePath = isStory || isStorybook ? '' : `${rootContext}/static`
  const ext = StaticImageSvgs.includes(name) ? 'svg' : 'png'

  return (
    <img
      src={`${basePath}/image/${name}.${ext}`}
      title={withTitle ? altText : ''}
      alt={altText}
      className={classNames(rootClass, className)}
      data-test={dataTest}
    />
  )
}

export default StaticImage
