import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import SwitchButtons from '@components/SwitchButtons/SwitchButtons'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ViewType } from '@src/pages/Content/Branding/tabs/Logos/Logos'

const rootClass = 'logos__sub-header'
const dataTest = rootClass

interface LogosHeaderProps {
  logosCount: number
  setView: (activeKey: ViewType) => void
  setShowAddLogoModal: (show: boolean) => void
  defaultView: ViewType
  loading: boolean
}

const LogosHeader: FC<LogosHeaderProps> = (props: LogosHeaderProps) => {
  const { logosCount, setView, setShowAddLogoModal, loading, defaultView } = props
  const { t } = useTranslation()
  return (
    <div className={classNames(`${rootClass}__container`, { [`${rootClass}__container-visibility`]: loading })}>
      <div className={`${rootClass}__container-info-title`}>
        <Typography type={TextType.PAGE_HEADER_SEMI_BOLD} text={t('Logos.Sub.Header.Title')} dataTest={`${dataTest}-title`} />
        <Typography
          type={TextType.BODY_TEXT_LARGE_LIGHT}
          weight={TextWeight.REGULAR}
          lineHeight={LineHeight.LARGER}
          text={`(${logosCount})`}
          dataTest={`${dataTest}-title-count`}
        />
      </div>
      <div className={`${rootClass}__container-actions`}>
        <SwitchButtons
          onChange={setView}
          icons={[
            { icon: SvgNames.gridView, key: ViewType.GRID_VIEW },
            { icon: SvgNames.listView, key: ViewType.LIST_VIEW },
          ]}
          defaultActive={defaultView}
        />
        {logosCount > 0 && (
          <Button
            buttonType={ButtonType.PRIMARY}
            iconPosition={ButtonIconPosition.LEFT}
            onClick={() => setShowAddLogoModal(true)}
            dataTest={`${rootClass}-button__add`}
          >
            <Svg name={SvgNames.plus} type={SvgType.LARGER_ICON} />
            {t('Add logo')}
          </Button>
        )}
      </div>
    </div>
  )
}

export default LogosHeader
