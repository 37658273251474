import React, { FC, PropsWithChildren, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import Spinner, { LoaderSize, SpinnerProps } from '@components/Spinner/Spinner'
import { getItem, setItem } from '@utils/sessionStorage'

import './LazyLoadOnScrollContainer.css'

interface Props {
  onLoadMoreData: () => Promise<any>
  allLoaded?: boolean
  hideSpinner?: boolean
  loadingNextData?: boolean
  // Don't forget to clear sessionStorage value in Parent component
  saveScrollSessionKey?: string
  spinnerProps?: SpinnerProps
  className?: string
  dataTest?: string
}

const rootClass = 'lazy-load-on-scroll-container'

const LazyLoadOnScrollContainer: FC<PropsWithChildren<Props>> = ({
  children,
  onLoadMoreData,
  loadingNextData,
  allLoaded,
  hideSpinner,
  saveScrollSessionKey,
  spinnerProps,
  dataTest = rootClass,
  className,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const currentScrollTopRef = useRef<number>(0)

  const handleScroll = useCallback(() => {
    if (loading || allLoaded || loadingNextData) {
      return
    }
    setLoading(true)
    onLoadMoreData().finally(() => setLoading(false))
  }, [loading, allLoaded, loadingNextData, onLoadMoreData])

  useEffect(() => {
    const container = containerRef.current

    const onScroll = () => {
      if (container) {
        const { scrollTop, clientHeight, scrollHeight } = container
        currentScrollTopRef.current = scrollTop
        if (scrollTop && scrollTop + clientHeight >= scrollHeight) {
          handleScroll()
        }
      }
    }

    container?.addEventListener('scroll', onScroll)

    return () => {
      container?.removeEventListener('scroll', onScroll)
    }
  }, [handleScroll])

  useLayoutEffect(() => {
    /** Using useLayoutEffect to guarantee this cleanup
     * before parent component's useEffect() cleanup */
    if (saveScrollSessionKey) {
      const top = Number(getItem(saveScrollSessionKey))
      if (top) {
        containerRef.current?.scrollTo({ top })
      }
    }
    return () => {
      if (saveScrollSessionKey) {
        setItem(saveScrollSessionKey, `${currentScrollTopRef.current}`)
      }
    }
  }, [saveScrollSessionKey])

  const { className: spinnerClassName, ...restSpinnerProps } = { ...spinnerProps }

  return (
    <div ref={containerRef} className={classNames(rootClass, className)} data-test={dataTest}>
      {children}
      {!hideSpinner && (loading || loadingNextData) && (
        <Spinner
          size={LoaderSize.MEDIUM}
          className={classNames(`${rootClass}__spinner`, spinnerClassName)}
          dataTest={`${dataTest}-spinner`}
          {...restSpinnerProps}
        />
      )}
    </div>
  )
}

export default LazyLoadOnScrollContainer
