import React, { Component, ReactNode } from 'react'

import PageError from '@components/PageError/PageError'
import { logError } from '@utils/env'

interface Props {
  children: ReactNode
}

export default class ErrorBoundary extends Component<Props> {
  state = {
    hasError: false,
    error: null,
    errorInfo: null,
  }

  renderErrorComponent = () => {
    return <PageError center canCancel />
  }

  componentDidCatch = (error: any, errorInfo: any) => {
    const { componentStack } = errorInfo
    logError(componentStack)

    this.setState({
      hasError: true,
      error,
      errorInfo: componentStack,
    })
  }

  render() {
    const { hasError, error } = this.state
    const { children } = this.props
    return hasError && error ? this.renderErrorComponent() : children
  }
}
