import React, { FC } from 'react'

import Button, { ButtonType } from '@components/Button'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Typography, { ModalBodyStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { AccountSettings } from '@graphql/types/query-types'

import './accountDetailsModal.css'

export interface AccountDetailsModalProps {
  isOpen: boolean
  closeModal: () => void
  accountSettings?: AccountSettings
  dataTest?: string
}

type FieldValue = number | string | undefined

const CONTACT_ADDRESS = 'accountsreceivable@act-on.com'
const rootClass = 'account-details-modal'

export const AccountDetailsModal: FC<AccountDetailsModalProps> = (props: AccountDetailsModalProps) => {
  const { isOpen, closeModal, accountSettings, dataTest = 'account-details-modal' } = props

  if (!accountSettings) {
    throw new Error('account details not available')
  }

  const { t } = useTranslation()

  const accountDetailsDataMap = new Map<string, FieldValue>([
    ['Account', `${accountSettings.accountName} (${accountSettings.accountId})`],
    ['Account Type', accountSettings.paymentType],
    ['Account Users', accountSettings.maxMarketingUsers],
    ['Active Contacts', accountSettings.maxSends],
  ])

  const renderField = (label: string, value: FieldValue, index: number) => (
    <div className={`${rootClass}__field-item`} key={index}>
      <Typography text={`${t(label)}:`} className={`${rootClass}__label`} {...ModalBodyStyle} inline />{' '}
      <Typography text={value} {...ModalBodyStyle} weight={TextWeight.MEDIUM} inline />
    </div>
  )

  return (
    <Modal
      isOpen={isOpen}
      dataTest={dataTest}
      className={rootClass}
      header={<ModalHeader headerType={ModalHeaderType.Form}>{t('Account Details')}</ModalHeader>}
    >
      <ModalBody>
        <div className={`${rootClass}__body`} data-test={`${dataTest}__body`}>
          <div className={`${rootClass}__fields`}>
            {Array.from(accountDetailsDataMap.entries()).map(([label, value], i) => renderField(label, value, i))}
          </div>
          <div className={`${rootClass}__contact`}>
            <Typography inline text={t('For invoice inquiries please contact')} {...ModalBodyStyle} />
            <a href={`mailto:${CONTACT_ADDRESS}`}>
              <Typography inline text={CONTACT_ADDRESS} type={TextType.LINK} />
            </a>
          </div>
        </div>
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form}>
        <Button buttonType={ButtonType.PRIMARY} onClick={closeModal} dataTest={`${dataTest}-close-button`}>
          {t('Close')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default AccountDetailsModal
