import { TFunction } from 'i18next'

export const getSubscriptionStatusOptions = (t: TFunction) => [
  {
    label: t('Is subscribed to'),
    value: 'IS_SUBSCRIBED_TO',
  },
  {
    label: t('Is unsubscribed from'),
    value: 'IS_UNSUBSCRIBED_FROM',
  },
  {
    label: t('Is not subscribed to'),
    value: 'IS_NOT_SUBSCRIBED_TO',
  },
  {
    label: t('Is not unsubscribed from'),
    value: 'IS_NOT_UNSUBSCRIBED_FROM',
  },
  {
    label: t('Preference specified'),
    value: 'PREFERENCE_SPECIFIED',
  },
  {
    label: t('No preference specified'),
    value: 'NO_PREFERENCE_SPECIFIED',
  },
]
