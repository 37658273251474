import { formatNumber } from '@utils/numbers'

export function labelFormatter(this: any) {
  return `
    <div 
        style="
          padding: 6px 12px;
          border-radius: 5px;
          border: 1px solid ${this.color};
          background-color: rgba(247, 247, 247, 0.85);
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
        "
      >
      <b>
        <tspan 
          style="
            color: ${this.color}; 
            font-size: large;
            line-height: 0.5;
          "
        >
          &#x25CF;
        </tspan>
        ${(this.percentage ?? 0).toFixed(2)}% 
      </b>
      (${formatNumber(this.y ?? 0)} exists)
    </div>
  `
}
