import React, { FC, useState, useCallback, useMemo } from 'react'

import classNames from 'classnames'

import Container from '@components/Container'
import Loader from '@components/Loader'
import { CustomLinkItemInput } from '@graphql/types/query-types'
import BrandingSectionHeader from '@src/pages/Content/Branding/components/BrandingSectionHeader/BrandingSectionHeader'
import CustomerSupportInfo from '@src/pages/Content/Branding/tabs/CustomLink/components/CustomerSupportInfo/CustomerSupportInfo'
import CustomLinkForm from '@src/pages/Content/Branding/tabs/CustomLink/components/CustomLinkForm/CustomLinkForm'
import { SortedCustomLinkInfo } from '@src/pages/Content/Branding/tabs/CustomLink/CustomLinkContainer'

import './CustomLink.css'

interface Props {
  info: CustomLinkItemInput
  setInfo: (data: CustomLinkItemInput) => void
  loading: boolean
  className?: string
  dataTest?: string
}

const rootClass = 'custom-link'

const CustomLink: FC<Props> = (props) => {
  const { info, setInfo, loading, className, dataTest = rootClass } = props
  const [isEditing, setEditing] = useState<boolean>(false)

  const handleSetup = useCallback(() => setEditing(true), [])
  const handleCancel = useCallback(() => setEditing(false), [])
  const handleSave = useCallback(
    (data: CustomLinkItemInput) => {
      setInfo(data)
      setEditing(false)
    },
    [setInfo]
  )
  const sortedInfo = useMemo<SortedCustomLinkInfo>(
    () => [
      { key: 'url', value: info.url },
      { key: 'text', value: info.text ?? '' },
      { key: 'hint', value: info.hint },
    ],
    [info]
  )

  const showInfo = useMemo<boolean>(() => !info.hidden && !!info.url && !!info.text, [info])

  return (
    <Container className={classNames(rootClass, className)} dataTest={dataTest}>
      <BrandingSectionHeader
        titleKey="Custom link"
        descriptionKey="Custom.Link.Description"
        onEdit={setEditing}
        isEditing={isEditing}
        dataTest={`${dataTest}__header`}
      />
      {loading ? (
        <Loader />
      ) : isEditing ? (
        <CustomLinkForm info={sortedInfo} showInfo={showInfo} onSave={handleSave} onCancel={handleCancel} dataTest={`${dataTest}__form`} />
      ) : (
        <CustomerSupportInfo info={sortedInfo} showInfo={showInfo} onSetup={handleSetup} dataTest={`${dataTest}__customer-support`} />
      )}
    </Container>
  )
}

export default CustomLink
