import React from 'react'

import Modal, { ModalBody } from '@components/Modal'
import ModalFooterV2 from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@utils/const/globals'

import './PollBlockRemovedModal.css'

const rootClass = 'poll-block-removed-modal'

interface PollBlockRemovedModalProps {
  onOk: () => void
  webinarRemoved?: boolean
  pollBlockRemoved?: boolean
  pollAndWebinarRemoved?: boolean
  webinarAndPollRemoved?: boolean
  title: string
  text: string
  list?: string[] // Added list as an optional prop
}

const PollBlockRemovedModal = ({ onOk, title, text, webinarAndPollRemoved, list }: PollBlockRemovedModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal isOpen paddingV2 className={rootClass}>
      <ModalBody>
        <Svg name={SvgNames.warning} type={SvgType.VERY_LARGE_ICON} />
        <Typography text={t(title)} type={TextType.MODAL_HEADLINE_CONFIRMATION} weight={TextWeight.MEDIUM} />
        <Typography text={t(text)} type={TextType.BODY_TEXT_LIGHT} />

        {webinarAndPollRemoved && list && (
          <div className="options-block">
            {list.map((item) => (
              <div key={item} className={`options-block__item`}>
                <Typography type={TextType.BODY_TEXT_LIGHT} text={item} weight={TextWeight.MEDIUM_LIGHT} />
              </div>
            ))}
          </div>
        )}
      </ModalBody>
      <ModalFooterV2 buttons={{ actionButtonOnClick: onOk, actionButtonLabel: 'Ok' }} />
    </Modal>
  )
}

export default PollBlockRemovedModal
