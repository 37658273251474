import React, { FC, useMemo } from 'react'

import Table, { TableColumn } from '@components/Table/Table'

import { SyncChange } from '../../SyncResults'

import './SyncResultsTable.css'

const rootClass = 'sync-results-table'

interface SyncResultsProps {
  results: SyncChange[]
  dataTest?: string
}

const SyncResultsTable: FC<SyncResultsProps> = (props: SyncResultsProps) => {
  const { results } = props

  const columns: TableColumn[] = [
    {
      Header: '',
      accessor: 'type',
      align: 'left',
      minWidth: 120,
      flexColumn: true,
      disableSortBy: true,
    },
    {
      Header: 'New',
      accessor: 'appended',
      align: 'right',
      width: 90,
      disableSortBy: true,
    },
    {
      Header: 'Updated',
      accessor: 'updated',
      align: 'right',
      width: 90,
      disableSortBy: true,
    },
    {
      Header: 'Deleted',
      accessor: 'deleted',
      align: 'right',
      width: 90,
      disableSortBy: true,
    },
  ]

  return <Table data={results} columns={useMemo(() => columns, [])} className={`${rootClass}__table`} />
}

export default SyncResultsTable
