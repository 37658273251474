import { gql } from '@apollo/client'

export default gql`
  query getCopySegmentLog($pageNumber: Int, $orderBy: CopyLogField, $pageSize: Int, $direction: String) {
    getCopySegmentLog(pageNumber: $pageNumber, orderBy: $orderBy, pageSize: $pageSize, direction: $direction) {
      copyId
      dateTime
      fails
      inProgress
      names
      parent
      status
      success
      user
    }
  }
`
