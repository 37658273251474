import { ProfileExpressionState } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/ProfileExpression/ProfileExpression.constants'
import {
  ConditionType,
  DateComparison,
  DateRangeComparison,
  DateValueAbsolute,
  NumericComparison,
  NumericRangeComparison,
  TextEmptyComparison,
  TextNotEmptyComparison,
} from '@src/pages/SegmentComposer/SegmentComposer.constants'
import {
  DateRangeCondition,
  DateValueCondition,
  NumericRangeCondition,
  NumericValueCondition,
  TextEmptyCondition,
  TextListCondition,
  TextNotEmptyCondition,
} from '@src/pages/SegmentComposer/SegmentComposer.interfaces'

export const getNewCondition = (value: string, state: ProfileExpressionState) => {
  const { textCriteria, numericCriteria } = state
  const { numberRange, numberValue } = numericCriteria
  const { values } = textCriteria
  if (value === TextNotEmptyComparison.TEXT_NOT_EMPTY) {
    return {
      type: ConditionType.TEXT_NOT_EMPTY,
    } as TextNotEmptyCondition
  }
  if (value === TextEmptyComparison.TEXT_EMPTY) {
    return {
      type: ConditionType.TEXT_EMPTY,
    } as TextEmptyCondition
  }
  if (value in NumericComparison) {
    return {
      type: ConditionType.NUMERIC_VALUE,
      value: numberValue,
      comparison: value,
    } as NumericValueCondition
  }
  if (value in NumericRangeComparison) {
    return {
      type: ConditionType.NUMERIC_RANGE,
      rangeStart: numberRange?.rangeStart,
      rangeEnd: numberRange?.rangeEnd,
      comparison: value,
    } as NumericRangeCondition
  }
  if (value in DateComparison) {
    const absolute: DateValueAbsolute = {
      type: 'absolute',
      textValue: new Date(),
    }
    return {
      type: ConditionType.DATE_VALUE,
      value: absolute,
      dateFormat: '',
      comparison: value,
    } as unknown as DateValueCondition
  }
  if (value in DateRangeComparison) {
    return {
      type: ConditionType.DATE_RANGE,
      rangeStart: new Date(),
      rangeEnd: new Date(),
      dateFormat: '',
      comparison: value,
    } as DateRangeCondition
  }

  // Default value
  return {
    type: ConditionType.TEXT_LIST,
    values: values.map((value) => value.label),
    comparison: value,
  } as TextListCondition
}
