import { gql } from '@apollo/client'

export default gql`
  query retrieveClassicAssetFoldersByType($assetType: String) {
    retrieveClassicAssetFoldersByType(assetType: $assetType) {
      name
      id
    }
  }
`
