import React, { FC, useState, useCallback, useMemo, useEffect } from 'react'

import Button, { ButtonType } from '@components/Button'
import FormRow from '@components/FormRow'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import TimeZoneSelectV2, { TimeZoneSelectV2Type } from '@components/TimeZoneSelectV2/TimeZoneSelectV2'
import { useTranslation } from '@const/globals'
import { getDynamicTimezones } from '@utils/timezones'

interface Props {
  timezone: string
  isOpen: boolean
  onSave: (name: string) => void
  onCancel: () => void
  dataTest?: string
}

const rootClass = 'edit-account-timezone-modal'

const AccountTimezoneModal: FC<Props> = (props: Props) => {
  const { timezone, isOpen, onSave, onCancel, dataTest = rootClass } = props
  const [currentTimezone, setCurrentTimezone] = useState<string>(timezone)
  const [selectedOption, setSelectedOption] = useState<SelectV2SingleOption>()
  const isTouched = useMemo<boolean>(() => currentTimezone !== timezone, [selectedOption, timezone])
  const { t } = useTranslation()
  const timeZoneOptions = getDynamicTimezones().map((timezone) => ({ label: timezone.label.replace('_', ' '), value: timezone.value }))

  const onChange = (option: SelectV2SingleOption | undefined) => {
    if (option?.value != null) {
      setSelectedOption(option)
      setCurrentTimezone(option.value)
    }
  }

  useEffect(() => {
    setCurrentTimezone(timezone)
  }, [timezone])

  const handleCancel = useCallback(() => {
    setCurrentTimezone(timezone)
    onCancel()
  }, [timezone, onCancel])

  const handleSave = useCallback(() => {
    onSave(currentTimezone)
  }, [currentTimezone, onSave])

  return (
    <Modal
      className={rootClass}
      isOpen={isOpen}
      header={<ModalHeader headerType={ModalHeaderType.Form}>{t('Account.Timezone.Edit.Title')}</ModalHeader>}
    >
      <ModalBody>
        <FormRow>
          <TimeZoneSelectV2
            type={TimeZoneSelectV2Type.MINIMAL}
            label={currentTimezone}
            selectProps={{
              defaultValue: selectedOption,
              value: selectedOption,
              onChange,
              options: timeZoneOptions,
            }}
          />
        </FormRow>
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form}>
        <Button buttonType={ButtonType.TERTIARY} onClick={handleCancel} dataTest={`${dataTest}-cancel-button`}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} onClick={handleSave} disabled={!isTouched || !currentTimezone} dataTest={`${dataTest}-save-button`}>
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default AccountTimezoneModal
