import React from 'react'

import classNames from 'classnames'

import {
  AssetPickerTableType,
  renderDefaultLocationColumn,
  renderDefaultNameColumn,
  renderDefaultRelativeDateColumn,
  renderDefaultTagsColumn,
} from '@complex/AssetPickerModal/Components/Table/AssetPickerTable.utils'
import { ItemDtoRow } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import Pill, { PillSize, PillType } from '@components/Pill/Pill'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import { allLandingPageTemplates } from '@utils/filter'

const rootClass = 'landing-page-templates-picker-modal'

const renderItemNameVal = (original: ItemDtoRow, search: string, rootClass: string) => {
  const itemName = (original as any)?.name ?? ''
  const trigger = search ? renderBoldTextOnMatch(itemName, search) : itemName

  const isBeeComposer = original?.beeComposer

  return (
    <div className={classNames(`${rootClass}__item-name ellip`, 'flex-align-center')}>
      <div className={classNames(`${rootClass}__item-name-typography`, { ['push-left']: isBeeComposer })}>
        <TextWithTooltipOnEllip
          typographyProps={{
            text: trigger,
          }}
          tooltipProps={{ children: itemName }}
        />
      </div>
      {isBeeComposer && <Pill text={'BETA'} size={PillSize.SMALL} type={PillType.SECONDARY} noMargin renderWithTranslation />}
    </div>
  )
}

export const renderTableColumns = (t: Function): ColumnDefWithAdditionalProps<ItemDtoRow, any>[] => [
  ...[renderDefaultNameColumn(t('Template Title'), '', rootClass, renderItemNameVal)],
  ...[renderDefaultTagsColumn(AssetPickerTableType.DEFAULT, t('Tags'))],
  ...[renderDefaultRelativeDateColumn(AssetPickerTableType.DEFAULT, t('Last Updated'), 'lastUpdated')],
]

export const renderSearchTableColumns = (search: string, folders: FolderData[], t: Function): ColumnDefWithAdditionalProps<ItemDtoRow, any>[] => [
  ...[renderDefaultNameColumn(t('Template Title'), search, rootClass, renderItemNameVal)],
  ...[renderDefaultLocationColumn(AssetPickerTableType.SEARCH, t('Location'), folders, t(allLandingPageTemplates.name))],
  ...[renderDefaultTagsColumn(AssetPickerTableType.SEARCH, t('Tags'))],
  ...[renderDefaultRelativeDateColumn(AssetPickerTableType.SEARCH, t('Last Updated'), 'lastUpdated')],
]
