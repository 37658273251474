import { StrictSortingState } from '@complex/ListingPage/Hooks/useFrontendFiltering'
import { BeeSingleTemplateResponse, BeeTemplate } from '@graphql/types/microservice/email-management-types'
import { createSafeContext, useSafeContext } from '@utils/account/account.utils'
import { allCatalogTemplatesFilter, recentlyViewedFilter } from '@utils/filter'
import { Folder } from '@utils/interface/Folder'

import { CatalogFolderItem } from './utils/TemplateCatalogModal.utils'

export interface TemplateCatalogSubType {
  id: string
  name: string
  parent?: string
}

export interface TemplateCatalogModalContextAPI {
  allCategories: CatalogFolderItem[]
  catalogItems: BeeTemplate[]
  categoriesLoading: boolean
  templatesLoading: boolean
  loadingNextData?: boolean
  searchText: string
  searchAllData: boolean
  activeFolder?: number
  activeSubTypes: TemplateCatalogSubType[]
  selectedItem?: BeeTemplate
  sortingBy: StrictSortingState<any>
  folders: Folder[]
  previewItem?: BeeSingleTemplateResponse
  activeFilter?: string
  resetPageNumber?: boolean
  filterCounts?: { [key in string]: number | undefined }
  onTemplateSelect: (item: BeeTemplate) => void
  onFilterChange: (filterName: string) => void
  onSubTypeChange: (subType: TemplateCatalogSubType) => void
  onSubTypesClear: () => void
  onPreview: (item: BeeTemplate) => void
  onSeachTermSelect: (term: string) => void
}

export const templateCatalogFilters = [allCatalogTemplatesFilter, recentlyViewedFilter]

export const TemplateCatalogModalContext = createSafeContext<TemplateCatalogModalContextAPI>()
export const useTemplateCatalogModalContentContext = () => useSafeContext(TemplateCatalogModalContext)
