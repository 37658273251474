import React, { FC, HTMLAttributeAnchorTarget, ReactNode, RefObject } from 'react'
import { Link } from 'react-router-dom'
import { HeaderGroup } from 'react-table'

import classNames from 'classnames'

import { NestedTableRowState } from '@components/ActionableNestedTable/components/NestedTableRow/NestedTableRow'
import BaseTag from '@components/BaseTag/BaseTag'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { TableColumn } from '@components/Table/Table'
import TagManager from '@components/TagManager/TagManager'
import TagManagerTrigger from '@components/TagManagerTrigger/TagManagerTrigger'
import TagManagerTriggerWithNumber from '@components/TagManagerTriggerWithNumber/TagManagerTriggerWithNumber'
import TagManagerTriggerWithText from '@components/TagManagerTriggerWithText/TagManagerTriggerWithText'
import TagViewer from '@components/TagViewer/TagViewer'
import TagWithName from '@components/TagWithName/TagWithName'
import TextBubble from '@components/TextBubble/TextBubble'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import { sortTagsByName } from '@utils/tags'
import { NO_COLOR } from '@utils/tags'

const rootClass = 'table'

export const BlankColHeader: FC = () => <span>&nbsp;</span>

interface LinkToProp {
  pathname: string
}

interface EnhancedColWithTitleProps {
  row: any
  extraContent?: ReactNode
  linkTo?: LinkToProp
  title?: string
  target?: HTMLAttributeAnchorTarget | undefined
}

export const EnhancedColWithTitle: FC<EnhancedColWithTitleProps> = ({ row, extraContent, linkTo, title, target }: EnhancedColWithTitleProps) => (
  <>
    <div className="ellip" title={title ? title : row.cell && row.cell.value}>
      {linkTo && (
        <Link className={`ellip`} to={linkTo} target={target}>
          {row.cell.value}
        </Link>
      )}
      {!linkTo && row.cell.value}
    </div>
    {extraContent}
  </>
)

interface ColWithTitleProps {
  row: any
  title?: string
}

export const ColWithTitle: FC<ColWithTitleProps> = ({ row, title }: ColWithTitleProps) => {
  const finalTitle = title ?? (row.data[row.cell.row.index].tooltip || row.cell.value)
  const value = row.cell.value ? row.cell.value : row.column.fallback ? row.column.fallback : ''

  return (
    <div className={classNames(`ellip`, `${rootClass}__ellip`)} {...(typeof finalTitle === 'string' ? { title: finalTitle } : {})}>
      <div className={classNames(`ellip`, `${rootClass}__ellip__wrapper`)}>{value}</div>
    </div>
  )
}

interface ColWithUnderlineProps {
  row: any
  title?: string
}

const parseValueAndTooltip = ({ row, title }: ColWithUnderlineProps) => {
  const cellValue = row.cell.value
  const value = (Array.isArray(cellValue) ? cellValue[0] : cellValue) ?? ''
  const tooltip = title ? title : Array.isArray(cellValue) ? cellValue[1] : value
  return { value, tooltip }
}

export const ColWithUnderline: FC<ColWithUnderlineProps> = ({ row, title }: ColWithUnderlineProps) => {
  const { value, tooltip } = parseValueAndTooltip({ row, title })
  return (
    <Tooltip
      position={'top'}
      trigger={
        <div data-test={'__underline'} className={`${rootClass}__underlined`}>
          {value}
        </div>
      }
    >
      <div>{tooltip}</div>
    </Tooltip>
  )
}

export const ColWithTooltip: FC<ColWithUnderlineProps> = ({ row, title }: ColWithUnderlineProps) => {
  const { value, tooltip } = parseValueAndTooltip({ row, title })
  return (
    <div className={classNames(`${rootClass}__ellip`)}>
      <Tooltip position={'top'} trigger={<div className={classNames(`ellip`)}>{value}</div>}>
        <div>{tooltip}</div>
      </Tooltip>
    </div>
  )
}

interface EnhancedColWithTooltipProps extends ColWithUnderlineProps {
  extraContent: ReactNode
}

export const EnhancedColWithTooltip: FC<EnhancedColWithTooltipProps> = ({ row, title, extraContent }: EnhancedColWithTooltipProps) => {
  return (
    <>
      <ColWithTooltip row={row} title={title} />
      {extraContent}
    </>
  )
}

export const renderColWithTitle = (row: any) => <ColWithTitle row={row} />

export const renderColWithTooltip = (row: any, title?: string) => <ColWithTooltip row={row} title={title} />

export const renderColWithUnderline = (row: any, title?: string) => {
  return <ColWithUnderline row={row} title={title} />
}

export const renderEnhancedColWithTooltip = ({ row, title, extraContent }: EnhancedColWithTooltipProps) => (
  <EnhancedColWithTooltip extraContent={extraContent} row={row} title={title} />
)

export const renderRelativeDate = (triggerDate?: string, tooltipDate?: string): JSX.Element => (
  <Tooltip trigger={triggerDate} ellipOnTrigger inline={false}>
    {tooltipDate}
  </Tooltip>
)

interface ColWithLegendProps {
  row: any
  color?: string
}

export const ColWithLegend: FC<ColWithLegendProps> = ({ row }: ColWithLegendProps) => {
  const color = row.data[row.cell.row.index].color
  const indented = row.data[row.cell.row.index].indented
  return (
    <div className={classNames(`${rootClass}__legend-container`, { [`${rootClass}__indented`]: indented })}>
      {row.cell.value} {color && <span className={`${rootClass}__legend`} style={{ backgroundColor: color }} />}
    </div>
  )
}

interface MutedColProps {
  row: any
  checkCol: string
  checkVal: string
}

export const MutedCol: FC<MutedColProps> = ({ row, checkCol, checkVal }: MutedColProps) => {
  const className = row.row.original[checkCol] === checkVal ? `${rootClass}__muted` : ''
  return <div className={className}>{row.cell.value}</div>
}

interface ColWithTitleLinkProps {
  row: any
  titleCol?: string
  linkTo: LinkToProp
  disabled?: boolean
}

export const ColWithTitleLink: FC<ColWithTitleLinkProps> = ({ row, linkTo, titleCol }: ColWithTitleLinkProps) => {
  const {
    cell: { value },
  } = row
  const text = value === 'Untitled' ? <em>{value}</em> : value
  const title = titleCol ? `${value}: ${row.data[row.cell.row.index][titleCol]}` : value
  return (
    <Link className={`ellip`} to={linkTo} title={title}>
      {text}
    </Link>
  )
}

interface HighlightedColProps {
  row: any
}

export const HighlightedCol: FC<HighlightedColProps> = ({ row }: HighlightedColProps) =>
  row?.cell ? <TextBubble text={row.cell.value} className={`${rootClass}__col-highlighted`} /> : null

interface ColumnHeaderProps {
  column: HeaderGroup
  hasEllipsis?: boolean
}

export const renderColumnHeader = ({ column, hasEllipsis }: ColumnHeaderProps) =>
  hasEllipsis ? (
    <Tooltip ellipOnTrigger trigger={column.render('Header')}>
      {column.id === 'selection' ? 'Select/Unselect All' : `${column.Header}`}
    </Tooltip>
  ) : (
    column.render('Header')
  )

export const getColumnAlignClass = (columns: TableColumn[], id: string, isTD = true) => {
  const column = columns.find((col) => col.accessor === id)
  const base = `${rootClass}__${isTD ? 'td' : 'th'}`
  const extra = column && column.className ? column.className : ''
  const align = column ? (column.align ? `${rootClass}__${column.align}` : '') : ''
  return `${base} ${align} ${extra}`
}

interface TagColProps {
  appliedTags: LabelDto[]
  onApplyAndRemoveTags: (rows: number[], tagsToApply: LabelDto[], tagsToRemove: number[]) => void
  onCreateTag: Function
  readOnlyTags: boolean
  rootClass: string
  row: any
  selectedTagId: number
  setState: Function
  t: Function
  tagAction?: (name: string) => void
  tags: LabelDto[]
  numberOfTagsDisplayed: number
  tagsRef: RefObject<HTMLDivElement>
  waitingTagRef: RefObject<HTMLDivElement>
}

interface RenderTagManagerTriggerParams {
  appliedTags: LabelDto[]
  numberOfTagsDisplayed: number
  rootClass: string
}

const renderTagManagerTrigger = ({ appliedTags, numberOfTagsDisplayed, rootClass }: RenderTagManagerTriggerParams) => {
  if (appliedTags.length === 0) {
    return <TagManagerTriggerWithText className={`${rootClass}__tag-manager-trigger-with-text`} />
  } else if (appliedTags.length > numberOfTagsDisplayed) {
    return <TagManagerTriggerWithNumber number={appliedTags.length - numberOfTagsDisplayed} />
  } else {
    return <TagManagerTrigger />
  }
}

const renderTagManagerTriggerWithTooltip = (title: string, rest: RenderTagManagerTriggerParams) => (
  <Tooltip position={'top'} trigger={renderTagManagerTrigger(rest)}>
    {title}
  </Tooltip>
)

export const renderTags = ({
  appliedTags,
  numberOfTagsDisplayed = 3,
  onApplyAndRemoveTags,
  onCreateTag,
  readOnlyTags,
  rootClass,
  row,
  selectedTagId,
  setState,
  t,
  tagAction,
  tags,
  tagsRef,
  waitingTagRef,
}: TagColProps) => {
  const title = readOnlyTags ? 'View Tags' : 'Manage Tags'
  const sortedAppliedTags = sortTagsByName(appliedTags)
  let tagsDisplayed = sortedAppliedTags.slice(0, numberOfTagsDisplayed)

  let selectedTagFound: LabelDto
  if (selectedTagId) {
    selectedTagFound = appliedTags.find(({ id }) => selectedTagId === id) as LabelDto
    if (selectedTagFound) {
      const filteredTags = tagsDisplayed.filter(({ id }) => selectedTagId !== id)
      tagsDisplayed = [selectedTagFound, ...filteredTags.slice(0, numberOfTagsDisplayed - 1)]
    }
  }
  const onToggleDropdown = (hover: boolean) =>
    setState((state: NestedTableRowState) => {
      return { ...state, hover }
    })

  return (
    <>
      {sortedAppliedTags[numberOfTagsDisplayed] && (
        <TagWithName
          key={numberOfTagsDisplayed}
          className={`${rootClass}__hidden-tag`}
          tagNameRef={waitingTagRef}
          color={sortedAppliedTags[numberOfTagsDisplayed].color as string}
          name={sortedAppliedTags[numberOfTagsDisplayed].name as string}
        />
      )}
      <div ref={tagsRef} className={`${rootClass}__tags`}>
        {tagsDisplayed.map(({ color = NO_COLOR, name }) => {
          const isSelected = selectedTagId !== 0 && selectedTagFound?.name === name
          return tagsDisplayed.length != 1 && !isSelected ? (
            <BaseTag key={name} color={color} name={name as string} onClick={tagAction} />
          ) : (
            <TagWithName key={name} color={color} name={name as string} onClick={tagAction} />
          )
        })}
        {readOnlyTags ? (
          <TagViewer
            onToggleDropDown={onToggleDropdown}
            tags={sortTagsByName(appliedTags)}
            trigger={renderTagManagerTriggerWithTooltip(t(title), { appliedTags, numberOfTagsDisplayed, rootClass })}
          />
        ) : (
          <TagManager
            appliedTags={appliedTags}
            tags={tags}
            trigger={renderTagManagerTriggerWithTooltip(t(title), { appliedTags, numberOfTagsDisplayed, rootClass })}
            onApplyAndRemove={(tagsToApply: LabelDto[], tagsToRemove: number[]) =>
              onApplyAndRemoveTags ? onApplyAndRemoveTags([row.original.id], tagsToApply, tagsToRemove) : false
            }
            onCreate={(color, tagName) => (onCreateTag ? onCreateTag({ color, name: tagName }) : false)}
            onToggleDropDown={onToggleDropdown}
            title={title}
          />
        )}
      </div>
    </>
  )
}

export const renderIconTextCol = (icon: SvgNames, text: string) => {
  return (
    <div className={classNames('ellip', `${rootClass}__text-icon-col`)}>
      <Svg name={icon} type={SvgType.LARGER_ICON} />
      <Typography inline text={text} />
    </div>
  )
}

export const renderTextIconLinkCol = (text: string, icon: SvgNames, linkText: string, linkUrl: string, target = '_blank', onClick?: () => void) => {
  return (
    <div className={classNames('ellip', `${rootClass}__text-icon-link-col`)}>
      <Typography text={text} />
      <a href={linkUrl} target={target} onClick={onClick} className={'ellip'}>
        <Svg name={icon} type={SvgType.LARGER_ICON} />
        <Typography className={'ellip'} inline text={linkText} type={TextType.LINK} weight={TextWeight.MEDIUM} />
      </a>
    </div>
  )
}

export const renderTooltipHeader = (title: string, tooltip: string) => (
  <Tooltip trigger={<Typography text={title} type={TextType.TABLE_HEADER} />}>{tooltip}</Tooltip>
)

export const renderClickableCol = (text: string, row: any, onClick: () => void, customRender?: (row: any) => ReactNode, hideTooltip?: boolean) => {
  const content = (
    <div
      role={'button'}
      tabIndex={0}
      onKeyDown={(keyDownEvent) => (keyDownEvent.key === 'Enter' ? onClick : undefined)}
      className={`ellip`}
      onClick={onClick}
    >
      {customRender ? customRender(row) : <Typography text={text} />}
    </div>
  )

  return hideTooltip ? (
    content
  ) : (
    <Tooltip position={'top'} hide={hideTooltip} trigger={content} ellipOnTrigger>
      {text}
    </Tooltip>
  )
}
