import React, { FC, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'

import * as yup from 'yup'

import { useApolloClient } from '@apollo/client'
import Button, { ButtonType } from '@components/Button'
import Caution from '@components/Caution/Caution'
import { Checkbox } from '@components/Checkbox/Checkbox'
import FormRow from '@components/FormRow/FormRow'
import Input from '@components/Input/Input'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import TextLink from '@components/TextLink/TextLink'
import Typography, { TextType } from '@components/Typography/Typography'
import { getUUID, useTranslation } from '@const/globals'
import createShortUrl from '@graphql/mutations/createShortUrl'
import updateShortUrl from '@graphql/mutations/updateShortUrl'
import getShortUrl from '@graphql/queries/getShortUrl'
import {
  CreateShortUrlMutation,
  CreateShortUrlMutationVariables,
  UpdateShortUrlMutation,
  UpdateShortUrlMutationVariables,
} from '@graphql/types/mutation-types'
import { GetShortUrlQuery, GetShortUrlQueryVariables } from '@graphql/types/query-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { logNewRelicError } from '@utils/new-relic.utils'

import './shortenerModal.css'

interface Props {
  isOpen: boolean
  insertLinkSave(data: FieldValues): void
  closeModal: () => void
  dataTest?: string
  shortUrlEdit?: ShortUrlEdit
  assetType: string
  assetId: string
}

interface State {
  url: string
  shortUrl: string
  prevShortedUrl: string
  domain: string
  shortUrlId: number
  doTracking: boolean
  callFailure: boolean
  isUpdate: boolean
  hasChange: boolean
}

export type ShortenerModalResponse = {
  url: string
  shortUrl: string
}

export type ShortUrlEdit = {
  shortUrl: string
  doTracking: boolean
}

const rootClass = 'shortener-modal'

const schema = yup.object().shape({
  url: yup.string().required('URL is required.').url('Must be a valid URL'),
})

export const recIdPersonalization = 'XXXXXXXX'

export const ShortenerModal: FC<Props> = (props: Props) => {
  const { isOpen, dataTest = rootClass, insertLinkSave, closeModal, shortUrlEdit, assetType, assetId } = props

  const client = useApolloClient()
  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })

  const [state, setState] = useState<State>({
    url: '',
    shortUrl: '',
    prevShortedUrl: '',
    domain: '',
    shortUrlId: -1,
    doTracking: true,
    callFailure: false,
    isUpdate: false,
    hasChange: false,
  })

  const { t } = useTranslation()

  const submitId = getUUID(true)

  const initialize = async () => {
    let newState = {}
    try {
      if (shortUrlEdit !== undefined) {
        const dataShortUrl = await client.query<GetShortUrlQuery, GetShortUrlQueryVariables>({
          query: getShortUrl,
          fetchPolicy: 'network-only',
          variables: {
            shortUrl: shortUrlEdit.shortUrl,
          },
        })

        if (dataShortUrl.data && dataShortUrl.data.getShortUrl) {
          const shortUrl = dataShortUrl.data.getShortUrl
          newState = {
            ...state,
            isUpdate: true,
            url: shortUrl.longUrl,
            shortUrlId: shortUrl.id,
            shortUrl: shortUrl.shortUrl,
            prevShortedUrl: shortUrl.longUrl,
            doTracking: shortUrlEdit.doTracking,
          }

          setState({ ...state, ...newState })
        }
        if (dataShortUrl.error) {
          setState({ ...state, callFailure: true })
          logNewRelicError(dataShortUrl.error)
        }
      }
    } catch (e) {
      setState({ ...state, ...newState, callFailure: true })
      logNewRelicError(e)
    }
  }

  React.useEffect(() => {
    initialize()
  }, [])

  const onSubmit = async (submitData: FieldValues) => {
    const { url, shortUrl, prevShortedUrl, shortUrlId } = state
    trigger()
    setState({ ...state, hasChange: false })
    if (schema.isValidSync({ url }) && url !== prevShortedUrl) {
      if (shortUrlId !== -1) {
        doUpdateShortUrl()
          .then((data) => {
            if (data.data && data.data.updateShortUrl) {
              const { shortUrl } = data.data.updateShortUrl
              submitData.url = url
              submitData.shortUrl = shortUrl
              finishSubmit(submitData)
            } else {
              setState({ ...state, callFailure: true, hasChange: true })
            }
          })
          .catch((error) => {
            setState({ ...state, callFailure: true, hasChange: true })
            logNewRelicError(error)
          })
      } else {
        doCreateShortUrl()
          .then((data) => {
            if (data.data && data.data.createShortUrlDefaultDomain) {
              const { shortUrl } = data.data.createShortUrlDefaultDomain
              submitData.url = url
              submitData.shortUrl = shortUrl
              finishSubmit(submitData)
            } else {
              setState({ ...state, callFailure: true, hasChange: true })
            }
          })
          .catch((error) => {
            setState({ ...state, callFailure: true, hasChange: true })
            logNewRelicError(error)
          })
      }
    } else {
      submitData.url = url
      submitData.shortUrl = shortUrl
      finishSubmit(submitData)
    }
  }

  const finishSubmit = (data: FieldValues) => {
    if (state.doTracking) {
      data.shortUrl = data.shortUrl + '/' + recIdPersonalization
    }
    insertLinkSave(data)
  }

  const doUpdateShortUrl = async () => {
    return client.mutate<UpdateShortUrlMutation, UpdateShortUrlMutationVariables>({
      mutation: updateShortUrl,
      variables: {
        longUrl: state.url,
        shortUrl: state.shortUrl,
        id: state.shortUrlId,
      },
    })
  }

  const doCreateShortUrl = async () => {
    return client.mutate<CreateShortUrlMutation, CreateShortUrlMutationVariables>({
      mutation: createShortUrl,
      variables: {
        longUrl: state.url,
        assetId: assetId,
        assetType: assetType,
      },
    })
  }

  const openUrlPreview = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const url = state.url
    trigger()
    if (schema.isValidSync({ url })) {
      window.open(url, '_blank', 'width=1000,height=600')
    }
    e.preventDefault()
    return false
  }

  return (
    <Modal
      isOpen={isOpen}
      dataTest={dataTest}
      className={rootClass}
      header={<ModalHeader headerType={ModalHeaderType.Form}>{t('Insert Short URL')}</ModalHeader>}
    >
      <ModalBody>
        <form data-test={dataTest} onSubmit={handleSubmit(onSubmit)}>
          <FormRow>
            <Input
              className={`${rootClass}__url`}
              label={t('URL')}
              defaultValue={state.url}
              dataTest={`${dataTest}-url`}
              register={register('url', {
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setState({ ...state, [e.target.name]: e.target.value, hasChange: true })
                },
              })}
            />
            {errors?.url && <Typography text={t(errors.url.message)} type={TextType.ERROR} />}
          </FormRow>
          <div className={`${rootClass}__link_preview`}>
            <TextLink text={t('Test this link')} link="#" onClick={openUrlPreview} />
          </div>
          {state.callFailure && <Caution message={'Short URL service network call failed'} />}
          <button type="submit" id={submitId} hidden />
        </form>
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form}>
        <div className={`${rootClass}__link_tracking`}>
          <Checkbox
            dataTest={`${dataTest}__link-tracking`}
            label={t('Enable Link Tracking')}
            checked={state.doTracking}
            register={register('doTracking')}
            onChange={(checked) => {
              setState({ ...state, doTracking: checked, hasChange: true })
            }}
            name="doTracking"
          />
        </div>
        <Button dataTest={`${dataTest}-close-button`} buttonType={ButtonType.TERTIARY} onClick={closeModal}>
          {t('Cancel')}
        </Button>
        <Button
          disabled={state.url === '' || !state.hasChange}
          dataTest={`${dataTest}-save-button`}
          buttonType={ButtonType.PRIMARY}
          onClick={() => document.getElementById(submitId)?.click()}
        >
          {state.isUpdate ? t('Update') : t('Insert')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ShortenerModal
