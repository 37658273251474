import { gql } from '@apollo/client'

export default gql`
  mutation addPersonalization($key: String, $value: String) {
    addPersonalization(key: $key, value: $value) {
      statusCode
      reasonPhrase
    }
  }
`
