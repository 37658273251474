import { gql } from '@apollo/client'

export default gql`
  query getRevenuePropertiesFromUserSetting {
    getRevenuePropertiesFromUserSetting {
      newLeadShowOther
      opportunityShowOther
    }
  }
`
