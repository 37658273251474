import accountData from '@graphql/microservices/account/accountData'
import createAccount from '@graphql/microservices/account/createAccount'
import deleteAccount from '@graphql/microservices/account/deleteAccount'
import loginAsChild from '@graphql/microservices/account/loginAsChild'
import loginUserInfo from '@graphql/microservices/account/loginUserInfo'
import marketingNetworkManagers from '@graphql/microservices/account/marketingNetworkManagers'
import salesUsers from '@graphql/microservices/account/salesUsers'
import updateAssignContacts from '@graphql/microservices/account/updateAssignContacts'
import updateManagers from '@graphql/microservices/account/updateManagers'
import updateSalesUsers from '@graphql/microservices/account/updateSalesUsers'
import updateSharePermissions from '@graphql/microservices/account/updateSharePermissions'
import {
  CreateAccountMutation,
  CreateAccountMutationVariables,
  DeleteAccountMutation,
  DeleteAccountMutationVariables,
  AccountDataQuery,
  AccountDataQueryVariables,
  MarketingNetworkManagersQuery,
  MarketingNetworkManagersQueryVariables,
  SalesUsersQuery,
  SalesUsersQueryVariables,
  LoginAsChildMutation,
  LoginAsChildMutationVariables,
  UpdateAssignContactsMutation,
  UpdateAssignContactsMutationVariables,
  UpdateManagersMutation,
  UpdateManagersMutationVariables,
  UpdateSalesUsersMutation,
  UpdateSalesUsersMutationVariables,
  UpdateSharePermissionsMutation,
  UpdateSharePermissionsMutationVariables,
  LoginUserInfoQuery,
  LoginUserInfoQueryVariables,
} from '@graphql/types/microservice/account-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export interface CreateAccountRequestParams {
  zipCode: string
  country: string
  city: string
  name: string
  street1: string
  activeContacts: number
  state: string
  street2: string
}

export interface AccountManager {
  manager: boolean
  name: string
  userId: number
}

export interface SalesUser {
  salesUser: boolean
  name: string
  userId: number
}

export interface SharePermissionsInput {
  accountId: string
  canShare: boolean
  canSubscribe: boolean
}

export interface AccountManagementListingPageRequests {
  getAccountDataRequest: () => FetchPromise<AccountDataQuery>
  getLoginUserInfoRequest: () => FetchPromise<LoginUserInfoQuery>
  createAccountRequest: (params: CreateAccountRequestParams) => FetchPromise<CreateAccountMutation>
  deleteItemRequest: (accountId: string) => FetchPromise<DeleteAccountMutation>
  loginAsChildRequest: (accountId: string, email: string) => FetchPromise<LoginAsChildMutation>
  getMarketingNetworkManagersRequest: (accountId: string) => FetchPromise<MarketingNetworkManagersQuery>
  getSalesUsersRequest: (accountId: string) => FetchPromise<SalesUsersQuery>
  updateAssignContactsRequest: (accountId: string, userId: string, activeContacts: number) => FetchPromise<UpdateAssignContactsMutation>
  updateManagersRequest: (accountId: string, managers: AccountManager[]) => FetchPromise<UpdateManagersMutation>
  updateSalesUsersRequest: (accountId: string, salesUsers: SalesUser[]) => FetchPromise<UpdateSalesUsersMutation>
  updateSharePermissionsRequest: (sharePermissions: SharePermissionsInput[]) => FetchPromise<UpdateSharePermissionsMutation>
}

export const useAccountManagementListingPageRequests = (userId: string, parentAccountId: string): AccountManagementListingPageRequests => {
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.ACCOUNT })

  const getAccountDataRequest = () => {
    return client.query<AccountDataQuery, AccountDataQueryVariables>({
      query: accountData,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        parentAccountId,
        userId,
      },
    })
  }

  const getLoginUserInfoRequest = () => {
    return client.mutate<LoginUserInfoQuery, LoginUserInfoQueryVariables>({
      mutation: loginUserInfo,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        parentAccountId,
        userId,
      },
    })
  }

  const createAccountRequest = (params: CreateAccountRequestParams) => {
    return client.mutate<CreateAccountMutation, CreateAccountMutationVariables>({
      mutation: createAccount,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        ...params,
        parentAccountId,
        userId,
      },
    })
  }

  const deleteItemRequest = (accountId: string) => {
    return client.mutate<DeleteAccountMutation, DeleteAccountMutationVariables>({
      mutation: deleteAccount,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: { accountId, parentAccountId, userId },
    })
  }

  const loginAsChildRequest = (accountId: string, email: string) => {
    return client.mutate<LoginAsChildMutation, LoginAsChildMutationVariables>({
      mutation: loginAsChild,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        accountId,
        parentAccountId,
        userId,
        email,
      },
    })
  }

  const getMarketingNetworkManagersRequest = (accountId: string) => {
    return client.query<MarketingNetworkManagersQuery, MarketingNetworkManagersQueryVariables>({
      query: marketingNetworkManagers,
      errorPolicy: 'all',
      variables: {
        accountId,
        parentAccountId,
        userId,
      },
    })
  }

  const getSalesUsersRequest = (accountId: string) => {
    return client.query<SalesUsersQuery, SalesUsersQueryVariables>({
      query: salesUsers,
      errorPolicy: 'all',
      variables: {
        accountId,
        parentAccountId,
        userId,
      },
    })
  }

  const updateAssignContactsRequest = (accountId: string, userId: string, activeContacts: number) => {
    return client.mutate<UpdateAssignContactsMutation, UpdateAssignContactsMutationVariables>({
      mutation: updateAssignContacts,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        accountId,
        activeContacts,
        parentAccountId,
        userId,
      },
    })
  }

  const updateManagersRequest = (accountId: string, managers: AccountManager[]) => {
    return client.mutate<UpdateManagersMutation, UpdateManagersMutationVariables>({
      mutation: updateManagers,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        parentAccountId,
        accountId,
        managers,
        userId,
      },
    })
  }

  const updateSalesUsersRequest = (accountId: string, salesUsers: SalesUser[]) => {
    return client.mutate<UpdateSalesUsersMutation, UpdateSalesUsersMutationVariables>({
      mutation: updateSalesUsers,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        parentAccountId,
        accountId,
        salesUsers,
        userId,
      },
    })
  }

  const updateSharePermissionsRequest = (sharePermissions: SharePermissionsInput[]) => {
    return client.mutate<UpdateSharePermissionsMutation, UpdateSharePermissionsMutationVariables>({
      mutation: updateSharePermissions,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        sharePermissions,
        userId,
        parentAccountId,
      },
    })
  }

  return {
    getAccountDataRequest,
    getLoginUserInfoRequest,
    createAccountRequest,
    deleteItemRequest,
    loginAsChildRequest,
    getMarketingNetworkManagersRequest,
    getSalesUsersRequest,
    updateAssignContactsRequest,
    updateManagersRequest,
    updateSalesUsersRequest,
    updateSharePermissionsRequest,
  }
}
