import { gql } from 'apollo-boost'

export default gql`
  query syncRecordErrors($count: Int, $offset: Int) {
    recordErrors(count: $count, offset: $offset) {
      email
      error
      firstName
      pushRecordErrorId
      recId
    }
    recordErrorsCount
  }
`
