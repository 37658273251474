import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react'

import _ from 'lodash'

import { IPluginRow } from '@beefree.io/sdk/dist/types/bee'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { confirmPlainTextEdit } from '@src/pages/EmailComposer/components/EmailComposerPreview/EmailComposerPreview.render.utils'
import { BlocksDefaultText } from '@src/pages/EmailComposer/hooks/useEmailTemplateUIValidations'
import { detectDisplayConditionsInHeaderFooter, hasDisplayConditions } from '@src/pages/EmailComposer/utils/DisplayConditions.utils'
import { detectEmailType, getValidationOptions } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import { filterNotEmptyArray } from '@utils/array'
import { IPluginModuleWithInternalModule, IEntityContentJsonExtended } from '@utils/composer/beeEditor/beeEditorTypes'
import { EmailComposerContext, PlaceholderBlocks, SettingsValidations } from '@utils/composer/context/EmailComposer.context'
import { useEmailComposerRequests } from '@utils/composer/emailComposer/GraphQL/EmailComposerRequests.graphQL'
import { PERSONALIZED_FROM_ADDRESS_OPTION_VALUE, SALESFORCE_OWNER_OPTION_VALUE } from '@utils/composer/settings.constants'
import { rootContext, useTranslation } from '@utils/const/globals'
import useCRM from '@utils/hooks/useCRM'
import { converterHtmlToBytes, ensureFirstLetterIsCapitalized } from '@utils/strings'

import { Validation, ValidationOption, ValidationSectionStatus } from '../components/common/ValidationsList/ValidationsList'
import { bytesToSize, MAX_BYTE_SIZE, MAX_COMPOSER_BYTE_SIZE } from '../components/EmailComposerPreview/EmailComposerPreview.utils'
import { isAnalyticsFieldsIncomplete, validateSubjectAndPreviewText } from '../components/EmailComposerSettings/EmailComposerSettings.utils'
import { handleRemovedWebinarModal } from '../EmailModals/components/ManageWebinarDetailsModal/components/WebinarBlock/utils/webinarBlock.utils'
import { WebinarCustomFields } from '../EmailModals/components/ManageWebinarDetailsModal/components/WebinarBlock/WebinarBlock'
import { ANTI_SPAM_PHYSICAL_MAILING_LINK } from '../utils/EmailComposer.constants'

interface ErrorMessagesAndLinks {
  'No sender domain DKIM': { message: string; link: string }
  'No envelope from domain found': { message: string; link: string }
  [key: string]: { message: string; link: string }
}

type PlaceholderBlocksErrorMessages = {
  [K in keyof PlaceholderBlocks]: { name: string; message: string }
}

const domainConfigMessagesAndLinks: ErrorMessagesAndLinks = {
  'No sender domain DKIM': {
    message: `EmailComposer.Preview.Validations.Deliverability.DKIM`,
    link: 'https://connect.act-on.com/hc/en-us/articles/360025707374',
  },
  'No envelope from domain found': {
    message: `EmailComposer.Preview.Validations.Deliverability.Envelope`,
    link: 'https://connect.act-on.com/hc/en-us/articles/360025707434',
  },
  'Envelope from domain is not correctly setup': {
    message: `EmailComposer.Preview.Validations.Deliverability.Envelope.Incorrect`,
    link: '',
  },
  'No sender domain DMARC found': {
    message: `EmailComposer.Preview.Validations.Deliverability.Dmarc`,
    link: 'https://connect.act-on.com/hc/en-us/articles/360023939293-What-Is-DMARC',
  },
}

export const useEmailValidations = () => {
  const {
    values: {
      message,
      message: { id, templateHtml, isTransactional, customTextPart, templateJson, isPlainTextOnly, webinarConfig },
      validations: {
        isValidationsLoading,
        isEmailValidated,
        isContentMissing,
        contentValidations,
        deliverabilityValidations,
        contentPersonalizationErrors,
      },
      messageConfiguration,
      haveUnsavedChanges,
      disabledFeatures: { editPlainText },
      personalizations,
      beeEditorRef,
      detectedURLChanges: { uploadHtmlMode },
    },
    api: { onTabChange, updateModal, onEditPlainText, update },
  } = useContext(EmailComposerContext)

  const { isEmailBlankMessage, isOptInEmail, isEmailForm } = detectEmailType(messageConfiguration.messageType)

  const paragraphPlaceholderBlock = contentValidations.placeholderBlocks.paragraph

  const webinarParagraphPlaceholderMessage = typeof paragraphPlaceholderBlock === 'string' ? paragraphPlaceholderBlock : BlocksDefaultText.PARAGRAPH

  const placeholderBlocksErrorMessages: PlaceholderBlocksErrorMessages = {
    button: { name: 'Button', message: BlocksDefaultText.BUTTON },
    html: { name: 'HTML', message: BlocksDefaultText.HTML },
    list: { name: 'List', message: BlocksDefaultText.LIST },
    paragraph: { name: 'Paragraph', message: webinarParagraphPlaceholderMessage },
    title: { name: 'Title', message: BlocksDefaultText.TITLE },
    tableHeader: { name: 'Table', message: BlocksDefaultText.TABLE_HEADER },
    tableRow: { name: 'Table', message: BlocksDefaultText.TABLE_ROW },
  }
  const disabledEditings = messageConfiguration.reviewAndSend.disableEditings
  const { allowNoOptOut, transactionalAllowNoOptOut, accountId } = useAccountSettings()
  const { t } = useTranslation()
  const { connectorType } = useCRM()

  const {
    hasOptOutLinkInHtml,
    hasOptOutLinkInText,
    hasPhysicalAddressInHtml,
    hasPhysicalAddressInText,
    hasAccountCompanyInText,
    hasAccountCompanyInHtml,
    hasBizAddress,
    mobileAndDesktopValidation,
    hasDefaultPlaceholderBlock,
    hasEmptyBlock,
    signatureWarning,
    invalidClickthroughLinks,
    prohibitedDataURIs,
    isClickthroughLoading,
    isClickthroughError,
    placeholderBlocks,
    emptyBlocks,
    emptyRows,
    hasRssBlock,
    svgUrls,
  } = contentValidations

  const { isTestLaunch, isUserAddressInHtml, isUserAddressInText, domainConfig } = deliverabilityValidations
  const {
    hasDesktopCompanyName,
    hasDesktopOptOutLink,
    hasDesktopPhysicalAddressInHtml,
    hasMobileCompanyName,
    hasMobileOptOutLink,
    hasMobilePhysicalAddressInHtml,
  } = mobileAndDesktopValidation || {}
  const htmlSize = useMemo(() => converterHtmlToBytes(templateHtml), [templateHtml])
  const isHtmlSizeValid = useMemo(() => htmlSize <= MAX_BYTE_SIZE, [htmlSize])
  const isExceedComposerLimit = useMemo(() => htmlSize >= MAX_COMPOSER_BYTE_SIZE, [htmlSize])
  const isEditDisabled = messageConfiguration.reviewAndSend?.disableEditings

  const handlePlainTextEdit = useCallback(() => {
    const hasDynamicContent = hasDisplayConditions(message)
    const onConfirm = () => updateModal('editPlainText', true)
    const showWarning = !editPlainText && !!customTextPart
    if (haveUnsavedChanges) {
      onEditPlainText(onConfirm)
    } else if (hasDynamicContent && !showWarning) {
      confirmPlainTextEdit(updateModal, t, onConfirm)
    } else {
      onConfirm()
    }
  }, [customTextPart, editPlainText, haveUnsavedChanges, message, onEditPlainText, t, updateModal])

  const { dynamicContentErrors } = useMemo(() => {
    const { dynamicContentValidationResponse, dynamicPlainTextValidationResponse, isOnlyDynamicRows } = contentValidations
    const dynamicContentValidationResponseData = (isPlainTextOnly ? dynamicPlainTextValidationResponse : dynamicContentValidationResponse) ?? {}
    const { dynamicPersonalizationFieldErrors } = dynamicContentValidationResponseData ?? {}

    let dynamicContentOptions: ValidationOption[] =
      !messageConfiguration.reviewAndSend.hideCheckFullPersonalization && dynamicPersonalizationFieldErrors
        ? dynamicPersonalizationFieldErrors.filter(filterNotEmptyArray).map(({ isCrmField, field, lists }) => {
            const labelName =
              personalizations?.find((personalization) => [personalization.mapping, personalization.title].includes(field))?.title || field

            return {
              text: 'EmailComposer.Preview.Validations.Content.Dynamic.Personalization.Item',
              values: { context: isCrmField ? 'crm' : 'regular', field: labelName, connectorType },
              tagProps: { medium: { weight: TextWeight.MEDIUM } },
              inline: true,
              list: isCrmField ? undefined : lists.filter(filterNotEmptyArray),
            }
          })
        : []

    const { header, footer } = detectDisplayConditionsInHeaderFooter(templateJson)
    const headerFooterOptions: ValidationOption[] = [
      ...(header
        ? [{ text: 'EmailComposer.Preview.Validations.Content.Dynamic.Header', inline: true, tagProps: { medium: { weight: TextWeight.MEDIUM } } }]
        : []),
      ...(footer
        ? [{ text: 'EmailComposer.Preview.Validations.Content.Dynamic.Footer', inline: true, tagProps: { medium: { weight: TextWeight.MEDIUM } } }]
        : []),
    ]
    dynamicContentOptions = [...headerFooterOptions, ...dynamicContentOptions]

    if (emptyRows) {
      dynamicContentOptions = [
        ...dynamicContentOptions,
        ...emptyRows.filter(filterNotEmptyArray).map(({ condition }) => ({
          text: `EmailComposer.Preview.Validations.Content.Dynamic.Empty.Row${isPlainTextOnly ? '.PlainText' : ''}`,
          values: { field: `${condition}` },
          tagProps: { medium: { weight: TextWeight.MEDIUM } },
          inline: true,
        })),
        ...(isOnlyDynamicRows
          ? [
              {
                text: `EmailComposer.Preview.Validations.Content.Dynamic.Fallback${isPlainTextOnly ? '.PlainText' : ''}_error`,
                tagProps: { medium: { weight: TextWeight.MEDIUM } },
                inline: true,
              },
            ]
          : []),
      ]
    }
    const dynamicContentErrors = dynamicContentOptions.length
      ? {
          key: 'dynamic_content_personalization',
          title: `EmailComposer.Preview.Validations.Content.Dynamic.Personalization${isPlainTextOnly ? '.PlainText' : ''}_error`,
          status: ValidationSectionStatus.ERROR,
          options: dynamicContentOptions,
        }
      : null

    return { dynamicContentErrors }
  }, [contentValidations, hasAccountCompanyInHtml, hasAccountCompanyInText, isPlainTextOnly])

  const antiSpamOptOutLinkOptions = useMemo(
    () => getValidationOptions('OPT_OUT_LINK', contentValidations, isPlainTextOnly, handlePlainTextEdit),
    [contentValidations, handlePlainTextEdit, isPlainTextOnly]
  )

  const antiSpamCompanyNameOptions = useMemo(
    () => getValidationOptions('COMPANY_NAME', contentValidations, isPlainTextOnly, handlePlainTextEdit),
    [contentValidations, handlePlainTextEdit, isPlainTextOnly]
  )

  const antiSpamPhysicalAddressOptions = useMemo(
    () => getValidationOptions('PHYSICAL_ADDRESS', contentValidations, isPlainTextOnly, handlePlainTextEdit),
    [contentValidations, handlePlainTextEdit, isPlainTextOnly]
  )

  const antiSpamOptOutLinkMessageContext = useMemo(() => {
    if (isPlainTextOnly) {
      if (hasOptOutLinkInText) {
        return 'success'
      }
      return 'error'
    }

    switch (true) {
      case (hasOptOutLinkInHtml || (hasDesktopOptOutLink && hasMobileOptOutLink)) && hasOptOutLinkInText:
        return 'success'
      case !hasOptOutLinkInHtml && !hasOptOutLinkInText && !hasDesktopOptOutLink && !hasMobileOptOutLink:
        return 'error'
      default:
        return 'error_specific'
    }
  }, [hasOptOutLinkInHtml, hasOptOutLinkInText, hasDesktopOptOutLink, hasMobileOptOutLink, isPlainTextOnly])

  const antiSpamCompanyNameMessageContext = useMemo(() => {
    if (isPlainTextOnly) {
      if (hasAccountCompanyInText) {
        return 'success'
      }
      return 'warning'
    }

    switch (true) {
      case (hasAccountCompanyInHtml || (hasDesktopCompanyName && hasMobileCompanyName)) && hasAccountCompanyInText:
        return 'success'
      case !hasAccountCompanyInHtml && !hasAccountCompanyInText && !hasDesktopCompanyName && !hasMobileCompanyName:
        return 'warning'
      default:
        return 'warning_specific'
    }
  }, [hasAccountCompanyInHtml, hasAccountCompanyInText, hasDesktopCompanyName, hasMobileCompanyName, isPlainTextOnly])

  const antiSpampPhysicalAddressMessageContext = useMemo(() => {
    if (isPlainTextOnly) {
      if (hasPhysicalAddressInText) {
        return 'success'
      }
      return 'warning'
    }

    switch (true) {
      case (hasPhysicalAddressInHtml || (hasDesktopPhysicalAddressInHtml && hasMobilePhysicalAddressInHtml)) &&
        hasPhysicalAddressInText &&
        hasBizAddress:
        return 'success'
      case !hasPhysicalAddressInHtml && !hasPhysicalAddressInText && !hasDesktopPhysicalAddressInHtml && !hasMobilePhysicalAddressInHtml:
        return 'warning'
      case !hasBizAddress:
        return 'error_not_set'
      default:
        return 'warning_specific'
    }
  }, [
    hasPhysicalAddressInHtml,
    hasPhysicalAddressInText,
    hasDesktopPhysicalAddressInHtml,
    hasMobilePhysicalAddressInHtml,
    hasBizAddress,
    isPlainTextOnly,
  ])

  const antiSpamValidationsList: (Validation & { isSeparate?: boolean })[] = useMemo(
    () =>
      isEmailValidated
        ? [
            ...(antiSpamOptOutLinkMessageContext !== 'success'
              ? [
                  {
                    key: 'opt_out_link',
                    title: `EmailComposer.Preview.Validations.Anti.Spam.OptOutLink`,
                    titleProps: {
                      values: {
                        context: antiSpamOptOutLinkMessageContext,
                      },
                    },
                    status:
                      allowNoOptOut || (transactionalAllowNoOptOut && isTransactional)
                        ? ValidationSectionStatus.WARNING
                        : ValidationSectionStatus.ERROR,
                    options: antiSpamOptOutLinkOptions,
                    hideLinkIcon: true,
                    infoTooltipContent: (
                      <Typography
                        text={t('EmailComposer.Preview.Validations.Anti.Spam.OptOutLink.Tooltip', {
                          context: isTransactional ? 'transactional' : 'default',
                        })}
                        type={TextType.BODY_TEXT_WHITE}
                      />
                    ),
                  },
                ]
              : []),
            ...(antiSpamCompanyNameMessageContext !== 'success'
              ? [
                  {
                    key: 'company_name',
                    title: `EmailComposer.Preview.Validations.Anti.Spam.CompanyName`,
                    titleProps: {
                      values: {
                        context: antiSpamCompanyNameMessageContext,
                      },
                    },
                    status: ValidationSectionStatus.WARNING,
                    options: antiSpamCompanyNameOptions,
                    hideLinkIcon: true,
                    link: `${rootContext}/settings/customAccountSettings`,
                    infoTooltipContent: (
                      <Typography
                        text={t('EmailComposer.Preview.Validations.Anti.Spam.CompanyName.Tooltip', {
                          context: isTransactional ? 'transactional' : 'default',
                        })}
                        type={TextType.BODY_TEXT_WHITE}
                        tagProps={{ medium: { weight: TextWeight.MEDIUM, inline: true } }}
                        inline
                      />
                    ),
                  },
                ]
              : []),
            ...(antiSpampPhysicalAddressMessageContext !== 'success'
              ? [
                  {
                    key: 'physical_address',
                    title: `EmailComposer.Preview.Validations.Anti.Spam.PhysicalAddress`,
                    titleProps: {
                      values: {
                        context: antiSpampPhysicalAddressMessageContext,
                      },
                    },
                    status:
                      antiSpampPhysicalAddressMessageContext === 'error_not_set' ? ValidationSectionStatus.ERROR : ValidationSectionStatus.WARNING,
                    options: antiSpamPhysicalAddressOptions,
                    hideLinkIcon: true,
                    link: `${
                      antiSpampPhysicalAddressMessageContext ? ANTI_SPAM_PHYSICAL_MAILING_LINK : rootContext + '/settings/customAccountSettings'
                    }`,
                    infoTooltipContent: (
                      <Typography
                        text={t('EmailComposer.Preview.Validations.Anti.Spam.PhysicalAddress.Tooltip', {
                          context: isTransactional ? 'transactional' : 'default',
                        })}
                        type={TextType.BODY_TEXT_WHITE}
                        tagProps={{ medium: { weight: TextWeight.MEDIUM, inline: true } }}
                        inline
                      />
                    ),
                  },
                ]
              : []),
          ]
        : [],
    [
      isEmailValidated,
      allowNoOptOut,
      transactionalAllowNoOptOut,
      isTransactional,
      antiSpamCompanyNameMessageContext,
      t,
      antiSpamOptOutLinkOptions,
      antiSpamCompanyNameOptions,
      antiSpamPhysicalAddressOptions,
      antiSpamOptOutLinkMessageContext,
      antiSpampPhysicalAddressMessageContext,
    ]
  )

  const { antiSpamWarningsCount, antiSpamFailsCount } = useMemo(
    () =>
      antiSpamValidationsList.reduce(
        (acc, { status }) => {
          switch (status) {
            case ValidationSectionStatus.ERROR:
              acc.antiSpamFailsCount = acc.antiSpamFailsCount + 1
              break
            case ValidationSectionStatus.WARNING:
              acc.antiSpamWarningsCount = acc.antiSpamWarningsCount + 1
              break
          }
          return acc
        },
        {
          antiSpamWarningsCount: 0,
          antiSpamFailsCount: 0,
        }
      ),
    [antiSpamValidationsList]
  )

  const {
    personalizationFormattingErrors,
    personalizationRecipientErrors,
    personalizationCRMErrors,
    personalizationFallbackErrors,
    personalizationFormWarnings,
  } = useMemo(() => {
    const personalizationFormattingErrors: ValidationOption[] = []
    const personalizationRecipientErrors: string[] = []
    const personalizationFormWarnings: string[] = []
    const personalizationCRMErrors: string[] = []
    const personalizationFallbackErrors: string[] = []
    contentPersonalizationErrors?.forEach((error) => {
      const { hasFallbackText, lists, field, isInvalidFormat, isCrmField } = error
      const errorType = isInvalidFormat ? 'Invalid' : !!lists.length ? (isCrmField ? 'CRM' : 'Missing') : ''
      //replace ={{asd}}|qwe or ={{asd}} or asd|qwe to asd
      const fieldName = field.replace(/^={{([^}]*)}}$|^={{([^}]*)}}\|[^|]*$|^([^|]*)\|[^|]*$|^([^|]*)$/, '$1$2$3$4')
      const labelName =
        personalizations?.find((personalization) => [personalization.mapping, personalization.title].includes(fieldName))?.title || fieldName
      if (errorType) {
        switch (errorType) {
          case 'Invalid':
            personalizationFormattingErrors.push({
              text: `EmailComposer.Preview.Validations.Content.PersonalizationErrors.Formatting.Item`,
              values: { field: `{{${labelName}}}` },
              tagProps: { medium: { weight: TextWeight.MEDIUM } },
              inline: true,
            })
            break
          case 'Missing':
            personalizationRecipientErrors.push(`${labelName}::${lists.filter(filterNotEmptyArray).join(',')}`)
            personalizationFormWarnings.push(`${labelName}::${lists.filter(filterNotEmptyArray).join(',')}`)
            break
          case 'CRM':
            personalizationCRMErrors.push(labelName)
            break
        }
      } else if (!hasFallbackText) {
        personalizationFallbackErrors.push(labelName)
      }
    })
    return {
      personalizationFormattingErrors,
      personalizationRecipientErrors,
      personalizationCRMErrors,
      personalizationFallbackErrors,
      personalizationFormWarnings,
    }
  }, [contentPersonalizationErrors, personalizations])

  const placeholderBlocksErrorTexts: ValidationOption[] = useMemo(() => {
    if (isPlainTextOnly || uploadHtmlMode) {
      return []
    }
    return Object.entries(placeholderBlocks).reduce((acc: ValidationOption[], [block, isDefault]) => {
      if (block === 'webinar' && placeholderBlocks[block]) {
        acc.push({
          text: `EmailComposer.Preview.Validations.Content.Placeholder.Item`,
          values: { block: 'Webinar', message: placeholderBlocks[block] },
          tagProps: { medium: { weight: TextWeight.MEDIUM } },
          inline: true,
        })
      }
      if (isDefault) {
        const { name, message } = placeholderBlocksErrorMessages[block as keyof PlaceholderBlocks] ?? {}
        if (name && message) {
          acc.push({
            text: `EmailComposer.Preview.Validations.Content.Placeholder.Item`,
            values: { block: name, message },
            tagProps: { medium: { weight: TextWeight.MEDIUM } },
            inline: true,
          })
        }
      }
      return acc
    }, [])
  }, [placeholderBlocks, isPlainTextOnly])

  const emptyBlocksErrorTexts = useMemo(() => {
    if (isPlainTextOnly || uploadHtmlMode) {
      return []
    }
    return Object.entries(emptyBlocks)
      .filter((block) => !!block[1])
      .map((block) => {
        return {
          text: `EmailComposer.Preview.Validations.Content.Empty.Item`,
          values: { count: block[1], block: ensureFirstLetterIsCapitalized(block[0]) },
          tagProps: { medium: { weight: TextWeight.MEDIUM } },
          inline: true,
        }
      })
  }, [emptyBlocks, isPlainTextOnly])

  const deletedWebinarsBlocks: Validation[] = useMemo(() => {
    const deletedWebinars = templateJson.page.deletedRows
    if (!deletedWebinars?.length) return []

    return deletedWebinars.map((webinar) => {
      const html = webinar.descriptor?.html
      const customFields = html?.customFields as WebinarCustomFields

      const { blockId, webinarName } = customFields
      const { parentRowUUID, columnUUID, parentRowInitialState, isRemovedEntireRow, hasDeletedGridItem } = webinar as IPluginModuleWithInternalModule

      return {
        key: `webinar_block-${blockId}`,
        title: t(`EmailComposer.Preview.Validations.Content.WebinarBlock`, { webinarName }),
        status: ValidationSectionStatus.WARNING,
        hideLinkIcon: true,
        onClick: () =>
          handleRemovedWebinarModal({
            t,
            blockId,
            accountId,
            columnUUID,
            webinarName,
            parentRowUUID,
            beeEditorRef,
            webinarConfig,
            messageId: id,
            hasDeletedGridItem,
            isRemovedEntireRow,
            parentRowInitialState: parentRowInitialState as IPluginRow,
            templateJson: templateJson as IEntityContentJsonExtended<WebinarCustomFields>,
            update,
            onTabChange,
            updateModal,
          }),
      }
    })
  }, [accountId, beeEditorRef, id, onTabChange, t, templateJson, update, updateModal, webinarConfig])

  const contentValidationsList: (onViewResults?: () => void) => Validation[] = useCallback(
    (onViewResults) => {
      const items: Validation[] = [
        ...(messageConfiguration.content?.rssBlockRequired && !hasRssBlock
          ? [
              {
                key: 'rss_block',
                title: `EmailComposer.Preview.Validations.Content.RSSBlock`,
                status: ValidationSectionStatus.ERROR,
              },
            ]
          : []),
        ...(messageConfiguration.content?.webinarBlockRequired && deletedWebinarsBlocks.length ? deletedWebinarsBlocks : []),
        ...(isPlainTextOnly || uploadHtmlMode || isOptInEmail
          ? []
          : [
              {
                key: 'plain_text',
                title: `EmailComposer.Preview.Validations.Content.PlainText`,
                status: !customTextPart ? ValidationSectionStatus.SUCCESS : ValidationSectionStatus.WARNING,
                titleProps: {
                  values: {
                    context: disabledEditings ? 'disabled' : !customTextPart ? 'success' : 'custom',
                    button: isEmailBlankMessage ? '' : '<TextLink>Edit plain text</TextLink>',
                  },
                },
                onClick: () => !isEditDisabled && handlePlainTextEdit(),
                hideLinkIcon: true,
              },
            ]),
        {
          key: 'clickthrough_links',
          title: `EmailComposer.Preview.Validations.Content.Clickthrough.Links`,
          titleProps: {
            values: {
              context: isClickthroughLoading
                ? 'loading'
                : isClickthroughError
                ? 'warning_error'
                : !invalidClickthroughLinks.length
                ? 'success'
                : 'warning',
              count: invalidClickthroughLinks.length,
            },
          },
          status: !invalidClickthroughLinks.length ? ValidationSectionStatus.SUCCESS : ValidationSectionStatus.WARNING,
          loading: isClickthroughLoading,
          hideLinkIcon: true,
          onClick: onViewResults,
        },
        ...(svgUrls.length
          ? [
              {
                key: 'svg_urls',
                title: 'EmailComposer.Preview.Validations.Content.SvgUrls',
                status: ValidationSectionStatus.WARNING,
                options: svgUrls,
              },
            ]
          : []),
        ...(!isHtmlSizeValid
          ? [
              {
                key: 'email_size',
                title: `EmailComposer.Preview.Validations.Content.EmailSize`,
                titleProps: {
                  values: {
                    context: isExceedComposerLimit ? 'error' : 'warning',
                    size: bytesToSize(htmlSize),
                  },
                },
                status: isExceedComposerLimit ? ValidationSectionStatus.ERROR : ValidationSectionStatus.WARNING,
                infoTooltipContent: t(`EmailComposer.Preview.Validations.Content.EmailSize.Tooltip`, {
                  context: isExceedComposerLimit ? 'error' : 'warning',
                }),
              },
            ]
          : []),
        ...(!!prohibitedDataURIs.length
          ? [
              {
                key: 'prohibited_uri',
                title: `EmailComposer.Preview.Validations.Content.ProhibitedURI_error`,
                status: ValidationSectionStatus.ERROR,
                infoTooltipContent: (
                  <Typography
                    text={t('EmailComposer.Preview.Validations.Content.ProhibitedURI.Tooltip', {
                      list: `<ul>${prohibitedDataURIs.map((uri) => `<li>${uri}</li>`).join('')}</ul>`,
                    })}
                    type={TextType.BODY_TEXT_WHITE}
                    tagComponents={{
                      ul: <ul></ul>,
                      li: (
                        <li
                          style={{ width: '280px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', listStylePosition: 'inside' }}
                        ></li>
                      ),
                    }}
                    inline
                  />
                ),
              },
            ]
          : []),

        ...(!messageConfiguration.reviewAndSend.hideCheckFullPersonalization && !!personalizationFormattingErrors?.length && !isValidationsLoading
          ? [
              {
                key: 'personalization_field_formatting_error',
                title: `EmailComposer.Preview.Validations.Content.PersonalizationErrors.Formatting`,
                status: ValidationSectionStatus.ERROR,
                options: personalizationFormattingErrors,
              },
            ]
          : []),
        ...(!messageConfiguration.reviewAndSend.hideCheckFullPersonalization && !!personalizationRecipientErrors?.length && !isValidationsLoading
          ? [
              ...personalizationRecipientErrors.map((field, index) => {
                return {
                  key: `personalization_field_recipient_error_${index}`,
                  title: `EmailComposer.Preview.Validations.Content.PersonalizationErrors.Recipient`,
                  status: ValidationSectionStatus.ERROR,
                  titleProps: {
                    values: { field: field.split('::')[0] },
                  },
                  options: field.split('::')[1].split(','),
                }
              }),
            ]
          : []),
        ...(!!personalizationFormWarnings?.length && !isValidationsLoading && isEmailForm
          ? [
              ...personalizationFormWarnings.map((field, index) => {
                return {
                  key: `personalization_field_form_error_${index}`,
                  title: `EmailComposer.Preview.Validations.Content.Missing.Personalization.Form`,
                  status: ValidationSectionStatus.WARNING,
                  titleProps: {
                    values: { field: field.split('::')[0] },
                  },
                  options: field.split('::')[1].split(','),
                }
              }),
            ]
          : []),
        ...(!messageConfiguration.reviewAndSend.hideCheckFullPersonalization && !!personalizationCRMErrors?.length && !isValidationsLoading
          ? [
              {
                key: 'personalization_field_crm_error',
                title: `EmailComposer.Preview.Validations.Content.PersonalizationErrors.CRM`,
                status: ValidationSectionStatus.ERROR,
                titleProps: {
                  values: { field: personalizationCRMErrors.join(', '), connectorType },
                },
              },
            ]
          : []),
        ...(!messageConfiguration.reviewAndSend.hideCheckFullPersonalization && !!personalizationFallbackErrors?.length && !isValidationsLoading
          ? [
              {
                key: 'personalization_field_fallback_error',
                title: `EmailComposer.Preview.Validations.Content.PersonalizationErrors.Fallback`,
                status: ValidationSectionStatus.WARNING,
                titleProps: {
                  values: { field: personalizationFallbackErrors.join(', ') },
                },
              },
            ]
          : []),
        ...(hasEmptyBlock && !isPlainTextOnly && !uploadHtmlMode!
          ? [
              {
                key: 'empty_blocks',
                title: `EmailComposer.Preview.Validations.Content.Empty_error`,
                status: !hasEmptyBlock ? ValidationSectionStatus.SUCCESS : ValidationSectionStatus.ERROR,
                options: emptyBlocksErrorTexts,
              },
            ]
          : []),
        ...(hasDefaultPlaceholderBlock && !isPlainTextOnly && !uploadHtmlMode
          ? [
              {
                key: 'placeholder_blocks',
                title: `EmailComposer.Preview.Validations.Content.Placeholder_error`,
                status: ValidationSectionStatus.ERROR,
                options: placeholderBlocksErrorTexts,
              },
            ]
          : []),
        ...(!!signatureWarning
          ? [
              {
                key: 'signature_warning',
                title: `${signatureWarning}`,
                status: ValidationSectionStatus.WARNING,
              },
            ]
          : []),
        ...(dynamicContentErrors && !uploadHtmlMode ? [dynamicContentErrors] : []),
      ]

      return items
    },
    [
      messageConfiguration.reviewAndSend.hideCheckFullPersonalization,
      messageConfiguration.content?.rssBlockRequired,
      messageConfiguration.content?.webinarBlockRequired,
      personalizationFallbackErrors,
      isValidationsLoading,
      hasRssBlock,
      deletedWebinarsBlocks,
      isPlainTextOnly,
      uploadHtmlMode,
      isOptInEmail,
      customTextPart,
      disabledEditings,
      isEmailBlankMessage,
      isClickthroughLoading,
      isClickthroughError,
      invalidClickthroughLinks.length,
      isHtmlSizeValid,
      isExceedComposerLimit,
      htmlSize,
      t,
      prohibitedDataURIs,
      personalizationFormattingErrors,
      personalizationRecipientErrors,
      personalizationCRMErrors,
      connectorType,
      hasEmptyBlock,
      emptyBlocksErrorTexts,
      hasDefaultPlaceholderBlock,
      placeholderBlocksErrorTexts,
      signatureWarning,
      dynamicContentErrors,
      isEditDisabled,
      svgUrls,
      handlePlainTextEdit,
    ]
  )

  const { contentWarningCount, contentFailsCount } = useMemo(
    () =>
      contentValidationsList().reduce(
        (acc, { status }) => {
          switch (status) {
            case ValidationSectionStatus.ERROR:
              acc.contentFailsCount = acc.contentFailsCount + 1
              break
            case ValidationSectionStatus.WARNING:
              acc.contentWarningCount = acc.contentWarningCount + 1
              break
          }
          return acc
        },
        {
          contentWarningCount: 0,
          contentFailsCount: 0,
        }
      ),
    [contentValidationsList]
  )

  const deliverabilityValidationsList: () => Validation[] = useCallback(
    () => [
      ...(domainConfig?.domainConfigErrors?.map((error) => ({
        key: error || '',
        title: `${domainConfigMessagesAndLinks[`${error}`].message}_warning`,
        status: ValidationSectionStatus.WARNING,
        link: domainConfigMessagesAndLinks[`${error}`].link,
      })) || []),
      ...(isUserAddressInHtml || isUserAddressInText
        ? [
            {
              key: 'user_address',
              title: `EmailComposer.Preview.Validations.Deliverability.UserAddress_warning`,
              status: ValidationSectionStatus.WARNING,
              link: `${rootContext}/profile`,
              hideLinkIcon: true,
            },
          ]
        : []),
      ...(!isTestLaunch
        ? [
            {
              key: 'test',
              title: `EmailComposer.Preview.Validations.Deliverability.Test_warning`,
              status: ValidationSectionStatus.WARNING,
              link: ``,
            },
          ]
        : []),
    ],
    [isHtmlSizeValid, htmlSize, domainConfig?.domainConfigErrors, isUserAddressInHtml, isUserAddressInText, isTestLaunch]
  )

  const getContentStatus = useCallback(() => {
    if (isContentMissing) {
      return ValidationSectionStatus.REST
    } else if (!contentFailsCount && !!contentWarningCount) {
      return ValidationSectionStatus.WARNING
    } else if (!!contentFailsCount) {
      return ValidationSectionStatus.ERROR
    } else {
      return ValidationSectionStatus.SUCCESS
    }
  }, [isContentMissing, contentFailsCount, contentWarningCount])

  const getAntiSpamStatus = useCallback(() => {
    if (isContentMissing) {
      return ValidationSectionStatus.REST
    } else if (!!antiSpamFailsCount) {
      return ValidationSectionStatus.ERROR
    } else if (!!antiSpamWarningsCount) {
      return ValidationSectionStatus.WARNING
    } else {
      return ValidationSectionStatus.SUCCESS
    }
  }, [isContentMissing, antiSpamFailsCount])

  const getDeliverabilityStatus = useCallback(() => {
    if (isContentMissing) {
      return ValidationSectionStatus.REST
    } else if (!isHtmlSizeValid || !isTestLaunch || isUserAddressInHtml || isUserAddressInText || !!domainConfig?.domainConfigErrors?.length) {
      return ValidationSectionStatus.WARNING
    } else {
      return ValidationSectionStatus.SUCCESS
    }
  }, [isContentMissing, isHtmlSizeValid, isTestLaunch, isUserAddressInHtml, isUserAddressInText, domainConfig?.domainConfigErrors?.length])

  return {
    contentFailsCount,
    contentWarningCount,
    antiSpamWarningsCount,
    antiSpamFailsCount,
    allFailsCount: antiSpamFailsCount + contentFailsCount,
    antiSpamValidationsList,
    deliverabilityValidationsList,
    contentValidationsList,
    getContentStatus,
    getAntiSpamStatus,
    getDeliverabilityStatus,
  }
}

export const useEmailSenderValidations = () => {
  const {
    values: {
      haveUnsavedChanges,
      message: { sender, id, sendto_lists, sendto_contacts },
    },
    api: { updateValidations },
  } = useContext(EmailComposerContext)
  const { validateSenderRequest } = useEmailComposerRequests()
  const evaluateCriteria = useCallback(() => sender === PERSONALIZED_FROM_ADDRESS_OPTION_VALUE || sender === SALESFORCE_OWNER_OPTION_VALUE, [sender])
  const needToCheckValidation = useRef(evaluateCriteria())

  useEffect(() => {
    if (!sendto_lists?.length && !sendto_contacts?.length) {
      updateValidations({
        settingsValidations: {
          listsIncompatibleWithSalesforceOwner: [],
          listsIncompatibleWithPersonalizedFrom: [],
        },
      })
    } else {
      needToCheckValidation.current = evaluateCriteria()
    }
  }, [evaluateCriteria, updateValidations, sendto_lists, sendto_contacts])

  useEffect(() => {
    // if save is finished checking sender validation
    if (id && !haveUnsavedChanges && needToCheckValidation.current) {
      needToCheckValidation.current = false
      validateSenderRequest({ msgId: id }).then(({ data }) => {
        if (data?.validateEmailSender) {
          const { salesforceOwnerErrorSourceIds, personalizeFromErrorSourceIds } = data.validateEmailSender
          updateValidations({
            settingsValidations: {
              listsIncompatibleWithSalesforceOwner: salesforceOwnerErrorSourceIds?.filter(filterNotEmptyArray) ?? [],
              listsIncompatibleWithPersonalizedFrom: personalizeFromErrorSourceIds?.filter(filterNotEmptyArray) ?? [],
            },
          })
        }
      })
    }
  }, [updateValidations, id, haveUnsavedChanges, validateSenderRequest])
}

export const useSubjectAndPreviewTextValidations = () => {
  const {
    values: {
      haveUnsavedChanges,
      message: { subject, previewText, id, sendto_lists, sendto_contacts },
      disabledFeatures: { autoSave: disabledAutosave },
      autoSaveSuccess,
    },
    api: { onCheckSubjectAndPreviewText, update },
  } = useContext(EmailComposerContext)
  const needToCheckValidation = useRef(!!subject || !!previewText || !!sendto_lists)
  const contactsSrcLists = useRef({ lastChecked: {}, actual: {} })

  useEffect(() => {
    contactsSrcLists.current.actual =
      sendto_lists?.reduce((acc, item) => {
        if (item.srcId) {
          acc[item.srcId] = true
        }
        return acc
      }, {} as { [key: string]: boolean }) ?? {}
    needToCheckValidation.current =
      !!subject ||
      !!previewText ||
      (!!sendto_lists &&
        (!Object.keys(contactsSrcLists.current.actual).length || !_.isEqual(contactsSrcLists.current.actual, contactsSrcLists.current.lastChecked)))
  }, [subject, previewText, sendto_lists])

  useEffect(() => {
    // calculating actual contactsSrcLists
    contactsSrcLists.current.actual =
      sendto_contacts?.reduce((acc, item) => {
        if (item.srcId) {
          acc[item.srcId] = true
        }
        return acc
      }, {} as { [key: string]: boolean }) ?? {}
    needToCheckValidation.current = needToCheckValidation.current || !_.isEqual(contactsSrcLists.current.actual, contactsSrcLists.current.lastChecked)
  }, [sendto_contacts])

  useEffect(() => {
    if (id && needToCheckValidation.current && (!haveUnsavedChanges || disabledAutosave)) {
      needToCheckValidation.current = false
      contactsSrcLists.current.lastChecked = { ...contactsSrcLists.current.actual }
      onCheckSubjectAndPreviewText()
    }
  }, [disabledAutosave, haveUnsavedChanges, id, onCheckSubjectAndPreviewText])

  useEffect(() => {
    // if save is finished checking Subject and PreviewText validation
    if (id && needToCheckValidation.current && (!haveUnsavedChanges || disabledAutosave) && autoSaveSuccess) {
      needToCheckValidation.current = false
      contactsSrcLists.current.lastChecked = { ...contactsSrcLists.current.actual }
      onCheckSubjectAndPreviewText()
    }
  }, [onCheckSubjectAndPreviewText, haveUnsavedChanges, id, subject, disabledAutosave, autoSaveSuccess, update])
}

export const useSettingsValidations = () => {
  const {
    values: {
      message: { title, previewText, subject, messageType, analyticsFields, isTriggered },
    },
    api: { updateValidations },
  } = useContext(EmailComposerContext)
  const { isEmailTemplate } = detectEmailType(messageType)

  useEffect(() => {
    const settingsValidations: Partial<SettingsValidations> = {
      isAnalyticsFieldsIncomplete: isAnalyticsFieldsIncomplete(analyticsFields),
      ...validateSubjectAndPreviewText({ subject, previewText }),
      titleMissing: !title,
      subjectMissing: !subject,
      subjectRequiredForETE: isEmailTemplate && !subject && isTriggered,
    }
    updateValidations({ settingsValidations })
  }, [updateValidations, title, previewText, subject, messageType, analyticsFields, isTriggered])
}
