import React, { FC, useContext, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import { TableV2 } from '@components/TableV2/TableV2'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { useGetPreviewSegmentModalRequests } from '@src/pages/RecommendedSegments/components/PreviewSegmentModal/utils/PreviewSegmentModal.graphQL'
import { RecommendedSegmentsContext } from '@src/pages/RecommendedSegments/utils/RecommendedSegments.context'

import './PreviewSegmentModal.css'

interface PreviewSegmentModalProps {
  className?: string
  dataTest?: string
  onClose: VoidFunction
}

interface PreviewSegmentModalState {
  contacts: Object[]
  headers: string[]
  loading: boolean
}

const rootClass = 'preview-segment-modal'
const PAGE_SIZE = 50

const PreviewSegmentModal: FC<PreviewSegmentModalProps> = (props: PreviewSegmentModalProps) => {
  const { dataTest = rootClass, className = '', onClose } = props

  const {
    values: { segmentToCreate },
  } = useContext(RecommendedSegmentsContext)

  const [state, setState] = useState<PreviewSegmentModalState>({
    contacts: [],
    headers: [],
    loading: true,
  })
  const { loading, contacts, headers } = state

  const { t } = useTranslation()

  const { getContactsRequest } = useGetPreviewSegmentModalRequests()

  useEffect(() => {
    if (segmentToCreate) {
      getContactsRequest({ id: segmentToCreate.segmentId, pageNumber: 0, pageSize: PAGE_SIZE }).then(({ contacts, headers }) => {
        setState((state) => ({ ...state, contacts, headers, loading: false }))
      })
    }
  }, [segmentToCreate])

  const header = (
    <ModalHeader headerType={ModalHeaderType.List} className={`${rootClass}__header`}>
      {t('Preview segment')}
    </ModalHeader>
  )

  const columns = useMemo<ColumnDefWithAdditionalProps<any>[]>(() => {
    return headers.map((header) => ({
      header,
      accessorKey: header,
      maxSize: 200,
      textAlign: 'left',
    }))
  }, [headers])

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen header={header}>
      <ModalBody className={`${rootClass}__body`}>
        <Typography
          className={`${rootClass}__segment-name`}
          text={segmentToCreate?.name}
          type={TextType.BODY_TEXT_LARGE}
          weight={TextWeight.MEDIUM}
        />
        <Typography
          text={t('Segment reflects contacts who match this criteria at the time of the initial query.')}
          type={TextType.BODY_TEXT_SMALL_LIGHT}
        />
        <TableV2 data={contacts} columns={columns} loading={loading} enableOuterLoader />
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.List} className={`${rootClass}__footer`}>
        <Button buttonType={ButtonType.PRIMARY} onClick={onClose} dataTest={`${dataTest}-button-primary`}>
          {t('Close preview')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default PreviewSegmentModal
