import React, { FC } from 'react'

import classNames from 'classnames'

import Typography, { TextType, TextWeight } from '@components/Typography/Typography'

import './PillLabel.css'

export enum PillLabelType {
  DEFAULT = 'default',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  NOT_SET = 'not-set',
  CUSTOM = 'custom',
}

interface Props {
  text?: string
  type?: PillLabelType
  textType?: TextType
  className?: string
  noMargin?: boolean
  dataTest?: string
}

const rootClass = 'pill-label'

const PillLabel: FC<Props> = (props: Props) => {
  const { text, type = PillLabelType.DEFAULT, textType = TextType.BODY_TEXT_LIGHT_TINY, dataTest = rootClass, className, noMargin } = props
  return (
    <Typography
      text={text}
      className={classNames(rootClass, className, {
        [`${rootClass}__default`]: type === PillLabelType.DEFAULT,
        [`${rootClass}__success`]: type === PillLabelType.SUCCESS,
        [`${rootClass}__warning`]: type === PillLabelType.WARNING,
        [`${rootClass}__error`]: type === PillLabelType.ERROR,
        [`${rootClass}__not-set`]: type === PillLabelType.NOT_SET,
        [`${rootClass}__custom`]: type === PillLabelType.CUSTOM,
        [`${rootClass}__no-margin`]: noMargin,
      })}
      weight={TextWeight.BOLD}
      type={textType}
      dataTest={dataTest}
    />
  )
}

export default PillLabel
