import React, { FC, useRef } from 'react'

import { useApolloClient, useQuery } from '@apollo/client'
import { getListPickerModalUtil, ListPickerModalUtil, LoadedLists } from '@complex/ListPickerModal/listPickerModalUtil'
import Button, { ButtonType } from '@components/Button'
import Caution from '@components/Caution/Caution'
import FolderListPicker, { FoldersState } from '@components/FolderListPicker/FolderListPicker'
import Input, { InputType } from '@components/Input'
import ListPicker, { FoldersListsState, ListsState, SearchState } from '@components/ListPicker/ListPicker'
import Modal, { ModalBody, ModalFooter, ModalFooterAction, ModalHeader, ModalSubHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Select from '@components/Select/Select'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Typography, { ModalHeaderListStyle } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import favoritesListsQuery from '@graphql/queries/favoritesLists'
import folderListsQuery from '@graphql/queries/folderLists'
import foldersWithCountQuery from '@graphql/queries/foldersWithCount'
import searchListsQuery from '@graphql/queries/searchLists'
import searchListsByIdsQuery from '@graphql/queries/searchListsByIds'
import {
  FavoritesListsQuery,
  FavoritesListsQueryVariables,
  FolderListsQuery,
  FolderListsQueryVariables,
  FoldersWithCountQuery,
  FoldersWithCountQueryVariables,
  SearchListsByIdsQuery,
  SearchListsByIdsQueryVariables,
  SearchListsInfo,
  SearchListsQuery,
  SearchListsQueryVariables,
} from '@graphql/types/query-types'
import { Folder } from '@interface/foldersLists/Folder'
import { List } from '@interface/foldersLists/List'
import { FAVORITES_FOLDER_ID } from '@utils/folders'
import { ListCategory, GET_LIST_CATEGORIES } from '@utils/lists'

import './listPickerModal.css'

const rootClass = 'list-picker-modal'

export interface State {
  listType: string
  folders?: FoldersState
  lists?: ListsState
  search?: SearchState
  listFolders?: FoldersListsState
  currentFolder?: Folder
  selectedLists: string[]
  updateLoaded: boolean
  loadedLists: { [key: string]: LoadedLists }
  viewingSelectedLists: boolean
  selectedListsFolders?: SearchState
  modalBodyHeight?: number
  unifiedLists: string[]
  actonClassicLists: string[]
}

export interface Props {
  isOpen: boolean
  closeModal(): void
  initialList?: ListCategory
  dataTest?: string
  selectedLists?: string[]
  submitLists(lists: List[]): void
  listPickerModalUtil?: ListPickerModalUtil
  isListsOnly?: boolean
  smsCompatibleLists?: boolean
  importUnifiedCompatibleLists?: boolean
  submitButtonText?: string
  cancelButtonText?: string
  submitDisabledWithoutSelection?: boolean
  multiSelect?: boolean
  hasQueryBasedSegmentWarning?: boolean
  showUnifiedList?: boolean
  hasAoContacts?: boolean
}

/**
 * @deprecated use <ListPickerModalV2 instead
 */
export const ListPickerModal: FC<Props> = (props: Props) => {
  const {
    closeModal,
    initialList = ListCategory.MARKETING,
    isOpen,
    dataTest = 'list-picker-modal',
    isListsOnly = false,
    smsCompatibleLists = false,
    importUnifiedCompatibleLists = false,
    submitButtonText = 'Submit',
    cancelButtonText = 'Cancel',
    submitDisabledWithoutSelection = false,
    selectedLists = [],
    multiSelect = true,
    hasQueryBasedSegmentWarning = false,
    showUnifiedList,
    hasAoContacts,
  } = props

  const client = useApolloClient()
  const [state, setState] = React.useState<State>({
    listType: initialList,
    selectedLists: [],
    loadedLists: {},
    viewingSelectedLists: false,
    unifiedLists: [],
    actonClassicLists: [],
    updateLoaded: false,
  })

  const { t } = useTranslation()

  // Check if the user has any unified lists
  const { data } = useQuery<FoldersWithCountQuery, FoldersWithCountQueryVariables>(foldersWithCountQuery, {
    fetchPolicy: 'network-only',
    variables: {
      type: ListCategory.UNIFIED_LIST,
      noMirroredJoinLists: false,
    },
  })

  const hasUnifiedLists = !importUnifiedCompatibleLists && data && data.foldersWithCount[0].count > 0
  const listPickerModalUtil = props.listPickerModalUtil ?? getListPickerModalUtil(props, state, setState, t)
  const searchInputRef = useRef<HTMLInputElement | null>(null)

  let cannotAddLists = false
  const showError = () => {
    const { actonClassicLists, listType, unifiedLists, search, loadedLists, selectedLists } = state

    if (
      (!!actonClassicLists.length && listType === ListCategory.UNIFIED_LIST) ||
      (!!unifiedLists.length && listType !== ListCategory.UNIFIED_LIST) ||
      (!!search && (!!actonClassicLists.length || !!unifiedLists.length))
    ) {
      cannotAddLists = !state.search
      return <Caution message={'Act-On Contact Segments cannot be combined with legacy Lists or Segments.'} />
    } else {
      const containsQueryBasedSegment = selectedLists.some((listId) => {
        const list: List = loadedLists[listId].list
        return list.isSegment && list.description === 'Segment'
      })

      if (containsQueryBasedSegment && hasQueryBasedSegmentWarning) {
        return (
          <Caution
            message={t(
              'Importing this query-based segment will merge data with existing Act-On Contacts and add new records that are not already in Act-On Contacts. This will not import the segment criteria. Please re-create this query segment in Act-On Contacts as needed.'
            )}
          />
        )
      }
    }
  }

  React.useEffect(() => {
    if (isOpen && state.listType !== undefined) {
      setState((state) => {
        return {
          ...state,
          folders: {
            loading: true,
          },
          lists: undefined,
          listFolders: undefined,
          search: undefined,
        }
      })
    }
  }, [state.listType, isOpen])

  React.useEffect(() => {
    const listsString = selectedLists.join(',')

    if (selectedLists.length) {
      client
        .query<SearchListsByIdsQuery, SearchListsByIdsQueryVariables>({
          query: searchListsByIdsQuery,
          fetchPolicy: 'network-only',
          variables: {
            ids: listsString,
          },
        })
        .then((data) => {
          const searchListsByIds = data.data.searchListsByIds as SearchListsInfo
          const loadedLists = listPickerModalUtil.addSearchLoadedLists(searchListsByIds, state.loadedLists, t)
          const filteredSelectedLists = selectedLists.filter((list) => list in loadedLists)

          setState((state) => {
            return {
              ...state,
              loadedLists,
              selectedLists: filteredSelectedLists,
              updateLoaded: true,
            }
          })
        })
        .catch((error) => listPickerModalUtil.loadFolderError(error))
    }
  }, [selectedLists])

  React.useEffect(() => {
    if (state.updateLoaded) {
      listPickerModalUtil.updateSelectedLists(state.selectedLists)
    }
  }, [state.updateLoaded])

  React.useEffect(() => {
    if (state.folders?.loading === true) {
      client
        .query<FoldersWithCountQuery, FoldersWithCountQueryVariables>({
          query: foldersWithCountQuery,
          fetchPolicy: 'network-only',
          variables: {
            type: state.listType,
            noMirroredJoinLists: false,
          },
        })
        .then((data) => listPickerModalUtil.loadFoldersSuccess(data, state.listType === ListCategory.UNIFIED_LIST))
        .catch((error) => listPickerModalUtil.loadFolderError(error))
    }
  }, [state.folders])

  React.useEffect(() => {
    if (state.currentFolder !== undefined && state.folders?.folders && !state.lists) {
      if (state.currentFolder?.id === FAVORITES_FOLDER_ID) {
        setState((state) => {
          return {
            ...state,
            listFolders: {
              loading: true,
            },
            lists: undefined,
          }
        })
      } else {
        setState((state) => {
          return {
            ...state,
            lists: {
              loading: true,
            },
            listFolders: undefined,
          }
        })
      }
    }
  }, [state.folders, state.currentFolder])

  React.useEffect(() => {
    if (state.listFolders?.loading === true) {
      client
        .query<FavoritesListsQuery, FavoritesListsQueryVariables>({
          query: favoritesListsQuery,
          fetchPolicy: 'network-only',
          variables: {
            type: state.listType,
            noMirroredJoinLists: false,
          },
        })
        .then((data) => listPickerModalUtil.loadListFoldersSuccess(data))
        .catch((error) => listPickerModalUtil.loadListFoldersError(error))
    } else if (state.lists?.loading == true) {
      client
        .query<FolderListsQuery, FolderListsQueryVariables>({
          query: folderListsQuery,
          fetchPolicy: 'network-only',
          variables: {
            type: state.listType,
            id: `${state.currentFolder?.id}`,
            noMirroredJoinLists: false,
          },
        })
        .then((data) => listPickerModalUtil.loadListsSuccess(data))
        .catch((error) => listPickerModalUtil.loadListsError(error))
    }
  }, [state.lists, state.listFolders])

  React.useEffect(() => {
    if (state.search?.loading === true) {
      client
        .query<SearchListsQuery, SearchListsQueryVariables>({
          query: searchListsQuery,
          fetchPolicy: 'network-only',
          variables: {
            query: state.search?.query ?? '',
            noMirroredJoinLists: false,
          },
        })
        .then((data) => listPickerModalUtil.loadSearchSuccess(data))
        .catch((error) => listPickerModalUtil.loadSearchError(error))
    }
  }, [state.search])

  const AOContactsFilter = GET_LIST_CATEGORIES().filter((list) => {
    return hasAoContacts || hasAoContacts === undefined ? list.type !== 'FORM_SUBMISSIONS' : list.type !== 'UNIFIED_LIST'
  })

  return (
    <Modal
      dataTest={dataTest}
      className={rootClass}
      isOpen={isOpen}
      useCustomScrollAreas={`${rootClass}__custom-scroll-area`}
      noPadding={true}
      header={
        <ModalHeader className={`${rootClass}__header`} headerType={ModalHeaderType.List}>
          <Typography
            text={`${t('Select Lists')} ${!props.isListsOnly || hasUnifiedLists ? t('& Segments') : ''}`}
            {...ModalHeaderListStyle}
            inline
          />
          <Input
            dataTest={`${dataTest}-search-input`}
            ref={searchInputRef}
            inputType={InputType.SEARCH}
            className={`${rootClass}__search-input`}
            placeholder={`${t('Search All')}...`}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                listPickerModalUtil.doSearch(searchInputRef.current?.value)
              }
            }}
          />
        </ModalHeader>
      }
      subHeader={
        state.viewingSelectedLists ? (
          <ModalSubHeader className={`${rootClass}__selected-list-header`}>
            <span className={`${rootClass}__selected-list-header-title`}>{t('Selected Lists')}</span>
            <Button
              dataTest={`${dataTest}-back-to-list-button`}
              buttonType={ButtonType.TERTIARY}
              className={`${rootClass}__selected-list-header-button`}
              onClick={() => {
                setState((state) => {
                  return {
                    ...state,
                    viewingSelectedLists: false,
                  }
                })
              }}
            >
              {t('Back to all Lists')}
            </Button>
          </ModalSubHeader>
        ) : (
          <>{showError()}</>
        )
      }
    >
      <ModalBody className={`${rootClass}__body`}>
        {!state.search && !state.viewingSelectedLists && (
          <FolderListPicker
            dataTest={`${dataTest}-folder-list-picker`}
            folders={state.folders}
            listType={state.listType}
            currentFolderId={state.currentFolder?.id ?? 0}
            customScrollClass={`${rootClass}__custom-scroll-area`}
            changeFolder={(newFolder: Folder) => {
              setState((state) => {
                return {
                  ...state,
                  currentFolder: newFolder,
                  lists: undefined,
                }
              })
            }}
            folderHeader={
              <Select
                dataTest={`${dataTest}-list-change-select`}
                defaultValue={state.listType}
                disabled={state.folders?.loading}
                onChange={(event) => {
                  listPickerModalUtil.listChanged(event.target.value)
                }}
              >
                {AOContactsFilter.filter(
                  (category) => category.type !== ListCategory.UNIFIED_LIST || (hasUnifiedLists && showUnifiedList !== false)
                ).map((listCategory) => (
                  <option key={listCategory.type} value={listCategory.type}>
                    {t(listCategory.text)}
                  </option>
                ))}
              </Select>
            }
          >
            <ListPicker
              customScrollClass={`${rootClass}__custom-scroll-area`}
              className={`${rootClass}__lists`}
              lists={state.lists}
              folders={state.listFolders}
              selectedLists={state.selectedLists}
              updateSelectedLists={listPickerModalUtil.updateSelectedLists}
              isListsOnly={isListsOnly}
              smsCompatibleLists={smsCompatibleLists}
              importUnifiedCompatibleLists={importUnifiedCompatibleLists}
              listType={state.listType as ListCategory}
              cannotAddLists={cannotAddLists}
              unifiedLists={state.unifiedLists}
              actonClassicLists={state.actonClassicLists}
              multiSelect={multiSelect}
            />
          </FolderListPicker>
        )}
        {state.viewingSelectedLists && (
          <ListPicker
            customScrollClass={`${rootClass}__custom-scroll-area`}
            disableCollapse={true}
            search={state.selectedListsFolders}
            updateSelectedLists={listPickerModalUtil.updateSelectedLists}
            selectedLists={state.selectedLists}
            isListsOnly={isListsOnly}
            smsCompatibleLists={smsCompatibleLists}
            importUnifiedCompatibleLists={importUnifiedCompatibleLists}
            listType={state.listType as ListCategory}
            cannotAddLists={cannotAddLists}
            unifiedLists={state.unifiedLists}
            actonClassicLists={state.actonClassicLists}
            multiSelect={multiSelect}
          />
        )}
        {state.search && !state.viewingSelectedLists && (
          <div className={`${rootClass}__search`}>
            <ListPicker
              dataTest={`${dataTest}-search-list-picker`}
              customScrollClass={`${rootClass}__custom-scroll-area`}
              search={state.search}
              updateSelectedLists={listPickerModalUtil.updateSelectedLists}
              selectedLists={state.selectedLists}
              smsCompatibleLists={smsCompatibleLists}
              importUnifiedCompatibleLists={importUnifiedCompatibleLists}
              clearSearch={() => {
                if (searchInputRef.current?.value) {
                  searchInputRef.current.value = ''
                }
                setState({
                  ...state,
                  search: undefined,
                })
              }}
              listType={state.listType as ListCategory}
              cannotAddLists={cannotAddLists}
              unifiedLists={state.unifiedLists}
              actonClassicLists={state.actonClassicLists}
              multiSelect={multiSelect}
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.List}>
        <ModalFooterAction>
          <button onClick={listPickerModalUtil.toggleSelectedLists}>
            <div className={`${rootClass}__selected-lists`}>
              {state.selectedLists.length} {t('Lists selected')}
              {state.viewingSelectedLists && <Svg type={SvgType.LARGER_ICON} name={SvgNames.hide} />}
              {!state.viewingSelectedLists && <Svg type={SvgType.LARGER_ICON} name={SvgNames.show} />}
            </div>
            <div className={`${rootClass}__total-records`}>
              {listPickerModalUtil.getTotalRecords()} {t('Records')}
            </div>
          </button>
        </ModalFooterAction>
        <Button buttonType={ButtonType.TERTIARY} onClick={closeModal} dataTest={`${dataTest}-close-button`}>
          {t(cancelButtonText)}
        </Button>
        <Button
          buttonType={ButtonType.PRIMARY}
          dataTest={`${dataTest}-save-button`}
          onClick={listPickerModalUtil.submitList}
          disabled={submitDisabledWithoutSelection && !state.selectedLists.length}
        >
          {t(submitButtonText)}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ListPickerModal
