import React, { FC } from 'react'

import classNames from 'classnames'

import SingleContentDataCard from '@components/SingleContentDataCard/SingleContentDataCard'
import SingleLineChart, { SingleLineChartColor } from '@components/SingleLineChart/SingleLineChart'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { AttendanceCard } from '@src/pages/listingPages/FormsJoinView/components/WebinarsJoinViewDataCards/utils/WebinarsJoinViewDataCards.constants'
import { ItemType } from '@utils/categorization'
import { getFormattedNumber } from '@utils/numbers'

import './WebinarAttendanceDataCard.css'

interface WebinarAttendanceDataCardProps {
  className?: string
  dataTest?: string
  attendance: AttendanceCard
  loading: boolean
}

const rootClass = 'webinar-attendance-data-card'

const WebinarAttendanceDataCard: FC<WebinarAttendanceDataCardProps> = (props: WebinarAttendanceDataCardProps) => {
  const { dataTest = rootClass, className = '', attendance, loading } = props
  const { attended, registrations } = attendance

  const { t } = useTranslation()

  return (
    <SingleContentDataCard
      headerText={t(`ListPage.FormsJoinView.${ItemType.WEBINAR_SUBMISSION}.DataCards.Attendance.Header`)}
      className={classNames(rootClass, className)}
      dataTest={dataTest}
    >
      {loading ? (
        <Typography
          text={t(`ListPage.FormsJoinView.${ItemType.WEBINAR_SUBMISSION}.DataCards.Attendance.LoadingMessage`)}
          type={TextType.BODY_TEXT_LARGE}
          weight={TextWeight.BOLD}
          lineHeight={LineHeight.MEDIUM_LARGE}
        />
      ) : (
        <SingleLineChart
          actualValue={attended}
          title={
            <Typography
              text={`ListPage.FormsJoinView.${ItemType.WEBINAR_SUBMISSION}.DataCards.Attendance.Total`}
              values={{ count: registrations, actual: getFormattedNumber(attended), total: getFormattedNumber(registrations) }}
              tagProps={{
                bold: {
                  weight: TextWeight.BOLD,
                  type: TextType.BODY_TEXT_LARGE,
                  lineHeight: LineHeight.MEDIUM_LARGE,
                },
              }}
              weight={TextWeight.MEDIUM}
              type={TextType.BODY_TEXT_SMALL_LIGHT}
              lineHeight={LineHeight.MEDIUM_SMALL}
              inline
            />
          }
          total={registrations}
          actualColor={SingleLineChartColor.TEAL}
          smallerTotal
          percentageFixedColor={SingleLineChartColor.TEXT_TEAL}
        />
      )}
    </SingleContentDataCard>
  )
}

export default WebinarAttendanceDataCard
