import React, { FC } from 'react'

import classNames from 'classnames'

import { UNIFIED_CONTACTS_ID } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ProgramFieldSetStep, ProgramSource, ProgramStep } from '@graphql/types/query-types'
import {
  actonContactsStepOptions,
  defaultAppendToSegmentStep,
  defaultChangeFieldValueStep,
  defaultCopyFromSegmentStep,
  defaultCopyToListStep,
  marketingListStepOptions,
} from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/components/ListProgramStep/components/StepTypeSelector/utils/StepTypeSelector.constants'
import ProgramStepIcon from '@src/pages/programs/edit/components/ProgramFlow/components/ProgramStepIcon'
import { ProgramStepType } from '@utils/program/program.constants'

import './StepTypeSelector.css'

interface StepTypeSelectorProps {
  className?: string
  dataTest?: string
  isMarketingList?: boolean
  onClick: (step: Partial<ProgramStep>) => void
  defaultFieldName?: string
  programSource?: ProgramSource
  showAppendToSegmentStep?: boolean
}

export interface StepTypesFields {
  type: ProgramStepType
  header: string
  subheader: string
  isActonContacts?: boolean
}

const rootClass = 'step-type-selector'

const StepTypeSelector: FC<StepTypeSelectorProps> = (props: StepTypeSelectorProps) => {
  const { dataTest = rootClass, className = '', isMarketingList, onClick, defaultFieldName, programSource, showAppendToSegmentStep = false } = props

  const { t } = useTranslation()

  const defaultStepByType: { [key: string]: Partial<ProgramStep> } = {
    [ProgramStepType.FIELD_SET]: {
      ...defaultChangeFieldValueStep,
      listName: programSource?.name ?? '',
      listId: isMarketingList ? programSource?.baseId : UNIFIED_CONTACTS_ID,
      ...(defaultFieldName ? { fieldName: defaultFieldName } : {}),
      ...(isMarketingList ? { forceUseOfSourceList: true } : {}),
    } as ProgramFieldSetStep,
    [ProgramStepType.COPY]: defaultCopyToListStep,
    [ProgramStepType.COPY_FROM]: defaultCopyFromSegmentStep,
    [ProgramStepType.APPEND_TO_SEGMENT]: defaultAppendToSegmentStep,
  }

  const renderStepOption = ({ type, header, subheader, isActonContacts }: StepTypesFields) => (
    <div
      role={'button'}
      tabIndex={0}
      onKeyDown={(keyDownEvent) => (keyDownEvent.key === ' ' ? onClick(defaultStepByType[type]) : undefined)}
      data-test={`${dataTest}-${type}`}
      key={`step-type-${type}`}
      className={`${rootClass}__type`}
      onClick={() => onClick(defaultStepByType[type])}
    >
      <ProgramStepIcon stepType={type} aOContactsSource={isActonContacts} />
      <div className={`${rootClass}__type-definition`}>
        <Typography text={t(header)} type={TextType.BODY_TEXT} weight={TextWeight.MEDIUM} />
        <Typography text={t(subheader)} type={TextType.BODY_TEXT_SMALL_LIGHT} />
      </div>
      <Svg name={SvgNames.chevronRight} type={SvgType.ICON} />
    </div>
  )

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {(isMarketingList ? marketingListStepOptions : actonContactsStepOptions(showAppendToSegmentStep)).map(renderStepOption)}
    </div>
  )
}

export default StepTypeSelector
