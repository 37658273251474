import React, { FC } from 'react'

import AssetPickerModal, { AssetPickerModalBaseProps } from '@complex/AssetPickerModal/AssetPickerModal'
import { AssetPickerListingPageProps, AssetPickerSidebarProps, AssetPickerTableProps } from '@complex/AssetPickerModal/Context/AssetPicker.context'
import { RenderSearchColumnsV2, ListPageCommonState, SetError, Update } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { useEmailTemplatesPickerRequests } from '@components/AssetPickers/EmailTemplatesPickerModal/GraphQL/EmailTemplatesPickerRequests.graphQL'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { useTranslation } from '@const/globals'
import { ItemType } from '@utils/categorization'
import { allEmailTemplatesFilter } from '@utils/filter'

import { renderCustomFilters } from './EmailTemplatesPickerModal.sidebar'
import { renderSearchTableColumns, renderTableColumns } from './EmailTemplatesPickerModal.tables'

import './EmailTemplatesPickerModal.css'

const EmailTemplatesPickerModal: FC<AssetPickerModalBaseProps> = (props) => {
  const { t } = useTranslation()

  const { getItemPreviewRequest } = useEmailTemplatesPickerRequests()

  const customPreviewItemCall = async (listPageValues: ListPageCommonState, update: Update, setError: SetError) => {
    const { selectedRows } = listPageValues

    update({ loading: true })
    const { data, errors } = await getItemPreviewRequest(selectedRows[0].externalId ?? '')

    if (data) {
      update({ loading: false, previewHtml: '' /* ex: data.getContentFragmentPreview.fragmentHtml */ })
    } else if (errors) {
      setError('' /* ex: AssetPicker.ContentFragments.Preview' */, errors)
    }
  }

  // const customFilters = []
  //
  // const getCustomActiveFilter = (filter: string) => customFilters.find((item) => item.name === filter)
  //
  // const getCustomFilterParams = (activeFilter?: FilterDefinition) => {
  //   const values = {}
  //   const query = activeFilter && activeFilter.name in values ? values[activeFilter.name] : ''
  //   return { field: '', query }
  // }

  const renderSearchColumns: RenderSearchColumnsV2 = (
    _searchInAllItems: boolean | undefined,
    _currentFolder: FolderData,
    search: string,
    folders: FolderData[]
  ) => renderSearchTableColumns(search, folders, t)

  const tableProps: AssetPickerTableProps = {
    columns: renderTableColumns(t),
    renderSearchColumns,
    listPage: 'EmailTemplates',
  }

  const sidebarProps: AssetPickerSidebarProps = {
    hasRecent: true,
    hasCreatedByMe: true,
    allItemFilter: allEmailTemplatesFilter,
    renderCustomFilters,
  }

  const listingPageProps: AssetPickerListingPageProps = {
    // getCustomActiveFilter,
    // getCustomFilterParams,
    customPreviewItemCall,
    sidebarProps,
  }

  const assetPickerProps = {
    isSingleSelect: true,
    ...props,
    listingPageProps,
    tableProps,
    hasTags: true,
    hasCustomFilters: true,
    hasSidebar: true,
    hasPreview: true,
    itemType: ItemType.EMAIL_TEMPLATE,
    primaryButtonText: t('Select'),
    hasSubTypes: false, // Set to true to fetch counts for subTypes
  }

  return <AssetPickerModal {...assetPickerProps} />
}

export default EmailTemplatesPickerModal
