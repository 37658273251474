import React, { FC, useState, useCallback, useMemo, useEffect } from 'react'

import Button, { ButtonType } from '@components/Button'
import FormRow from '@components/FormRow'
import Input from '@components/Input/Input'
import Label from '@components/Label'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import { useTranslation } from '@const/globals'

import './CompanyNameModal.css'

interface Props {
  name: string
  isOpen: boolean
  onSave: (name: string) => void
  onCancel: () => void
  dataTest?: string
}

const rootClass = 'edit-company-name-modal'

const CompanyNameModal: FC<Props> = (props: Props) => {
  const { name, isOpen, onSave, onCancel, dataTest = rootClass } = props
  const [currentName, setCurrentName] = useState<string>(name)
  const isTouched = useMemo<boolean>(() => currentName !== name, [currentName, name])
  const { t } = useTranslation()

  useEffect(() => {
    setCurrentName(name)
  }, [name])

  const handleCancel = useCallback(() => {
    setCurrentName(name)
    onCancel()
  }, [name, onCancel])

  const handleSave = useCallback(() => {
    onSave(currentName)
  }, [currentName, onSave])

  return (
    <Modal className={rootClass} isOpen={isOpen} header={<ModalHeader headerType={ModalHeaderType.Form}>{t('Edit company name')}</ModalHeader>}>
      <ModalBody>
        <FormRow>
          <Label>{t('Company name')}</Label>
          <Input
            placeholder={t('Company name')}
            value={currentName}
            dataTest={`${dataTest}-input`}
            onChange={(event) => setCurrentName(event.target.value)}
          />
        </FormRow>
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form}>
        <Button buttonType={ButtonType.TERTIARY} onClick={handleCancel} dataTest={`${dataTest}-cancel-button`}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} onClick={handleSave} disabled={!isTouched || !currentName} dataTest={`${dataTest}-save-button`}>
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CompanyNameModal
