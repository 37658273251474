import { ApolloClient } from '@apollo/client'
import getSegmentFilter from '@graphql/microservices/segment/getSegmentFilter'
import { GetSegmentFilterQuery, GetSegmentFilterQueryVariables } from '@graphql/types/microservice/segment-types'
import { SetContainerValues } from '@interface/ListPage.context'
import { SegmentDefinition, EXPRESSION_EVALUATION_TYPE, ContactSegmentsState } from '@utils/contactSegments/contactSegments.utils'

export const segmentDefinitionOptions: { [key: string]: string } = {
  query: 'QUERY',
  directSelect: 'DIRECT SELECTION',
  search: 'SEARCH',
  systemGenerated: 'SYSTEM GENERATED',
}

export const getSegmentDefinitionUtils = (
  segmentExternalId: string,
  setContainerValues: SetContainerValues<ContactSegmentsState>,
  client: ApolloClient<any>
) => {
  setContainerValues((containerValues) => ({ ...containerValues, infoHoverLoading: true }))
  client
    .query<GetSegmentFilterQuery, GetSegmentFilterQueryVariables>({
      query: getSegmentFilter,
      fetchPolicy: 'network-only',
      variables: {
        segmentId: segmentExternalId,
      },
    })
    .then(({ data }) => {
      const definition = JSON.parse(data.getSegmentFilter?.body) as SegmentDefinition
      setContainerValues((state) => ({ ...state, segmentDefinition: definition, infoHoverLoading: false }))
    })
}

export const getSegmentTypeLabel = (segmentDefinition: SegmentDefinition) =>
  segmentDefinition && segmentDefinition.type
    ? `${segmentDefinition.expressionEvaluationType === EXPRESSION_EVALUATION_TYPE.CUSTOM ? segmentDefinition.expressionEvaluationType : ''} ${
        segmentDefinitionOptions[segmentDefinition.type]
      }`
    : ''
