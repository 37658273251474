import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useHistory } from 'react-router-dom'

import classNames from 'classnames'
import equal from 'fast-deep-equal'

import ListPageTable from '@complex/ListingPage/Components/ListingPageTable/ListPageTable/ListPageTable'
import { getFolderById } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { TABLEV2_ENABLED_LISTINGS } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { ListingPageSession } from '@complex/ListingPage/Utils/ListingPage.utils'
import UpgradeAssistantBanner from '@complex/UpgradeAssistantBanner/UpgradeAssistantBanner'
import Button, { ButtonType } from '@components/Button'
import Caution from '@components/Caution/Caution'
import CollapsibleBannerCollapsedContent from '@components/CollapsibleBanner/components/CollapsibleBannerCollapsedContent/CollapsibleBannerCollapsedContent'
import DataCardsModal from '@components/DataCardsModal/DataCardsModal'
import DependenciesContentModal, {
  DependenciesItemType,
  DependenciesContentModalItem,
} from '@components/DependenciesContentModal/DependenciesContentModal'
import DragLayer from '@components/DragLayer/DragLayer'
import DropDown from '@components/DropDown'
import { DropDownType } from '@components/DropDown/DropDown'
import DropDownActions, { MenuItem } from '@components/DropDownActions/DropDownActions'
import { EmptyListingProps } from '@components/EmptyListing/EmptyListing'
import PageHeader from '@components/PageHeader'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { SvgNames } from '@components/Svg'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { FormDto } from '@graphql/types/microservice/form-types'
import ContactSegmentsActions from '@src/pages/ContactSegments/components/ContactSegmentsActions/ContactSegmentsActions'
import ContactSegmentsCollapsibleMenu from '@src/pages/ContactSegments/components/ContactSegmentsCollapsibleMenu/ContactSegmentsCollapsibleMenu'
import ContactSegmentsModals from '@src/pages/ContactSegments/components/ContactSegmentsModals/ContactSegmentsModals'
import DataCardsContainer from '@src/pages/ContactSegments/components/DataCardsContainer/DataCardsContainer'
import DoneCopySegmentsModal from '@src/pages/ContactSegments/components/DoneCopySegmentsModal/DoneCopySegmentsModal'
import UpdateFormsModal from '@src/pages/ContactSegments/components/UpdateFormsModal/UpdateFormsModal'
import { useContactSegmentsRequest } from '@src/pages/ContactSegments/GraphQL/ContactSegments.graphQL'
import { contactPreferencesColumnsV2 } from '@src/pages/ContactSegments/utils/ContactPreferences/ContactPreferences.utils'
import { canDropInSegments, checkFormsToMigrate, getUpgradeBannerStatus, onFormsUpdateUtils } from '@src/pages/ContactSegments/utils/Contacts.utils'
import { getEmptyListingOptions, NON_INITIALIZED_UCL_TOOLTIP } from '@src/pages/ContactSegments/utils/ContactSegments.constants'
import { goCreateSegment, goToDataManagement, goToImportContacts } from '@src/pages/ContactSegments/utils/ContactSegmentsContainerUtils'
import {
  clearContactSegmentsSearch,
  doSearchSegments,
  onSearchEmptyButtonClicked,
  searchColumnsUtilsV2,
} from '@src/pages/ContactSegments/utils/ContactSegmentsSearch.utils'
import {
  CONTACTS_DETAILS_URL,
  onContactSegmentsLoad,
  onHasToExpandV2,
  onRowExpandedV2,
  SEGMENTS_LIST_URL,
} from '@src/pages/ContactSegments/utils/ContactSegmentsSession.utils'
import { Row } from '@tanstack/react-table'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import { ContactSegmentsSession, goEditCRMSourcedSegment, PERIOD_GROUPED_BY, Segment } from '@utils/contactSegments/contactSegments.utils'
import { ContactSegmentsContext, getContactSegmentsColumns } from '@utils/contactSegments/context/ContactSegmentsContext'
import {
  chartTextValues,
  createChartBar,
  DATACARD_LIST,
  dataCardModalValues,
  getDropDownOptions,
  PERIOD_MODAL,
  renderEmptyState,
} from '@utils/contactSegments/dataCards.utils'
import { allSegmentsFilter, FilterTypes } from '@utils/filter'
import useCRM, { CRMConnectorType } from '@utils/hooks/useCRM'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { usePrevious } from '@utils/hooks/usePrevious'
import { useUnifiedContactList } from '@utils/hooks/useUnifiedContactList'
import { EMPTY_LISTING_OPTIONS } from '@utils/ListPage.context.default'
import { DeleteConfirmationModals } from '@utils/listPageDeleteModals'
import { getItem, setItem } from '@utils/sessionStorage'
import { ellip } from '@utils/strings'
import { download } from '@utils/utils'

import './ContactSegments.css'

interface Props {
  className?: string
  dataTest?: string
  loading: boolean
  hasUCLInitialized?: boolean
}

export interface State {
  dropdownOpen: boolean
  dataCardActive: DATACARD_LIST | undefined
  dataCardModalOpen: boolean
  hasCollapsedMenu: boolean
  expandedSegments: number[]
  showUpdateFormsModal: boolean
  showUpdateFormsCaution: boolean
  formsToMigrate: FormDto[]
}

const initialState: State = {
  dataCardActive: undefined,
  dataCardModalOpen: false,
  dropdownOpen: false,
  hasCollapsedMenu: false,
  expandedSegments: [],
  showUpdateFormsModal: false,
  showUpdateFormsCaution: false,
  formsToMigrate: [],
}

const rootClass = 'contact-segments'
const LEARN_MORE_LINK =
  'https://success.act-on.com/acton/attachment/9883/u-ff7b26e2-28ea-4a49-8d6d-1e96eafb4116/0/-/-/-/-/in%20App%3A%20%20Learn%20more%20about%20Act-On%20Contacts%20Forms%20Phase%203'

const ContactSegments: FC<Props> = (props: Props) => {
  const { loading, dataTest = rootClass, hasUCLInitialized = false } = props
  const {
    values: {
      activeFolderId,
      activeTagId,
      crmSourceOptions,
      allContactsUpgradeDone,
      emptyListingOption,
      dataCardsModalInfo,
      dataCardsModalEmptyState,
      loadingChart,
      filterActive,
      folders,
      folderPath,
      isFtpConnectorActive,
      tags,
      search,
      searchResults,
      items: segments,
      movingItem: movingSegment,
      isProcessingAction,
      foldersLoading,
      exportedFile,
      currentPage,
      allItemsLoaded: allSegmentsLoaded,
      searchItemsLoading: searchSegmentsLoading,
      searchItemsResults: searchSegmentsResults,
      searchAllItems: searchAllSegments,
      showUpgradeBanner,
      showDoneCopySegmentsModal,
    },
    applyAndRemoveTags,
    createTag,
    getDataCardModalInfo,
    getTotalContactsModalInfo,
    update,
    setFolder,
    setTag,
    setFilter,
    refreshSegments,
    sortSegments,
  } = useContext(ContactSegmentsContext)

  const { t } = useTranslation()

  const [state, setState] = useState<State>(initialState)
  const {
    dataCardActive,
    dataCardModalOpen,
    dropdownOpen,
    hasCollapsedMenu,
    expandedSegments,
    showUpdateFormsModal,
    showUpdateFormsCaution,
    formsToMigrate,
  } = state
  const history = useHistory()

  const previousSection = usePrevious({ activeFolderId, activeTagId, filterActive })

  const menuRef = useRef<HTMLDivElement>(null)
  const pageContainerRef = useRef<HTMLDivElement>(null)
  const isFirstRenderRef = useRef(true)

  const { getFormsToMigrateRequest, migrateFormsRequest, getAllContactsUpgrade } = useContactSegmentsRequest()

  const isContactPreferences = useMemo(() => filterActive?.name === FilterTypes.CONTACT_PREFERENCES, [filterActive])
  const currentFolder = useMemo(() => (activeFolderId ? getFolderById(activeFolderId, folders) : undefined), [activeFolderId, folders])
  const currentTag = useMemo(() => (activeTagId ? tags.find(({ id }) => id === activeTagId) : undefined), [activeTagId, tags])
  const searchColumnsV2 = useMemo(
    () => searchColumnsUtilsV2(t, search, rootClass, dataTest, folders, searchAllSegments, currentFolder as FolderData),
    [searchSegmentsResults, searchAllSegments]
  )

  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })

  const { connectorType } = useCRM()

  const accountSettings = useAccountSettings()
  const {
    phase3FormSubmission,
    hasCopySegments,
    hasAutomatedProgramsUpgrade,
    hasFormsUpgrade,
    disableSegmentModifications,
    exclusivelyActOnContacts,
  } = accountSettings

  const unifiedContactList = useUnifiedContactList()

  const hasInitializedUCL =
    unifiedContactList && unifiedContactList.unifiedListFieldMappings && unifiedContactList.unifiedListFieldMappings.length > 0

  const hasToShowUpgradeHelper =
    (hasAutomatedProgramsUpgrade || hasCopySegments || hasFormsUpgrade) &&
    hasInitializedUCL &&
    !exclusivelyActOnContacts &&
    allContactsUpgradeDone !== undefined &&
    !allContactsUpgradeDone

  const showUpgradeAssistantBanner = hasToShowUpgradeHelper && showUpgradeBanner

  const onDelete = (segments: Segment[]) => {
    update({
      deleteConfirmationData: segments,
      confirmationModal: segments.length === 1 ? DeleteConfirmationModals.DELETE_SEGMENTS_SINGLE : DeleteConfirmationModals.DELETE_SEGMENTS_PLURAL,
    })
  }

  const onFormsUpdate = (formsIds: string[]) => onFormsUpdateUtils(formsIds, migrateFormsRequest, t, update, setState)

  const onFormsUpdateClose = () => setState({ ...state, showUpdateFormsModal: false })

  const onFormsNotificationDismiss = () => {
    setItem(ContactSegmentsSession.FORMS_UPDATE_NOTIFICATION, new Date().toDateString())
    setState({ ...state, showUpdateFormsCaution: false })
  }

  useEffect(() => {
    if (isFirstRenderRef.current) {
      return () => {
        isFirstRenderRef.current = false
      }
    } else {
      if (previousSection && !equal(previousSection, { activeFolderId, activeTagId, filterActive })) {
        clearContactSegmentsSearch(update)
      }
    }
  }, [filterActive, activeTagId, activeFolderId])

  useEffect(() => {
    const notificationDate = getItem(ContactSegmentsSession.FORMS_UPDATE_NOTIFICATION)
    if (phase3FormSubmission && notificationDate !== new Date().toDateString()) {
      checkFormsToMigrate(getFormsToMigrateRequest, t, update, setState)
    }
    getUpgradeBannerStatus(getAllContactsUpgrade, update)
  }, [])

  useEffect(() => {
    update({ searchResults: searchSegmentsResults.length })
  }, [searchSegmentsResults])

  useEffect(() => {
    if (!foldersLoading && isFirstRenderRef.current) {
      onContactSegmentsLoad(setState)
    }
  }, [foldersLoading])

  useEffect(() => {
    if (!movingSegment && activeFolderId) {
      setFolder(activeFolderId)
    }
  }, [movingSegment])

  useEffect(() => {
    if (exportedFile !== '') {
      download(exportedFile, exportedFile.substr(exportedFile.lastIndexOf('/') + 1))
      update({ exportedFile: '' })
    }
  }, [exportedFile])

  const setAllSegmentsFilter = useCallback(() => setFilter(allSegmentsFilter), [])

  const emptyStateButtonActions: { [key in EMPTY_LISTING_OPTIONS]: () => void } = {
    [EMPTY_LISTING_OPTIONS.EMPTY_CRM_ALL_SEGMENTS]: () => goToDataManagement('SyncSchedule'),
    [EMPTY_LISTING_OPTIONS.EMPTY_NON_CRM_ALL_SEGMENTS]: () => goToImportContacts(isFtpConnectorActive, update),
    [EMPTY_LISTING_OPTIONS.EMPTY_CRM_SOURCED_SEGMENTS]: () => goEditCRMSourcedSegment(),
    [EMPTY_LISTING_OPTIONS.EMPTY_FOLDER]: () => goCreateSegment(SEGMENTS_LIST_URL, { activeFolderId }),
    [EMPTY_LISTING_OPTIONS.EMPTY_TAG]: setAllSegmentsFilter,
    [EMPTY_LISTING_OPTIONS.EMPTY_FAVORITES]: setAllSegmentsFilter,
    [EMPTY_LISTING_OPTIONS.EMPTY_CREATED_BY_ME]: () => goCreateSegment(SEGMENTS_LIST_URL),
    [EMPTY_LISTING_OPTIONS.EMPTY_RECENT]: setAllSegmentsFilter,
    [EMPTY_LISTING_OPTIONS.NOT_SET_CRM_DATA_MANAGEMENT]: () => goToDataManagement(),
    [EMPTY_LISTING_OPTIONS.NOT_SET_NON_CRM_DATA_MANAGEMENT]: () => goToDataManagement(),
    [EMPTY_LISTING_OPTIONS.EMPTY_CONTACT_PREFERENCES]: () => undefined,
  }

  const onClickTagInRow = (name: string) => {
    if (name === currentTag?.name) {
      setFilter(allSegmentsFilter)
    } else {
      const tagFound = tags.find(({ name: tagName }) => name === tagName)
      setTag(tagFound?.id)
    }
  }

  const onRowClicked = (row: Row<Segment>) => {
    update({ showDetails: row.original })
    setItem(ContactSegmentsSession.SEGMENT_DETAILS, JSON.stringify(row.original))
    history.push(CONTACTS_DETAILS_URL)
  }

  const handleSearch = (search: string) => {
    const prevSearch = getItem(`${ItemType.SEGMENT}:${ListingPageSession.SEARCH_QUERY}`)
    if (prevSearch && JSON.parse(prevSearch) === search) return

    if (!search) {
      clearContactSegmentsSearch(update)
    } else {
      setItem(`${ItemType.SEGMENT}:${ListingPageSession.SEARCH_QUERY}`, JSON.stringify(search))
      update({ searchItemsLoading: true, search })
    }
  }

  const onSearch = (searchAll: boolean) => {
    doSearchSegments(searchAll, filterActive, activeFolderId, activeTagId, search, update, categorizationClient, accountSettings)
    update({ searchAllItems: searchAll })
  }

  const disableDragPreview = useCallback((type: ItemType, targetTypes: ItemType[]) => {
    return type === ItemType.UNSORTABLE_SEGMENT && targetTypes.every((targetType) => targetType === type)
  }, [])

  const dataCardModalFunctions: { [dataCardId in DATACARD_LIST]: any } = {
    [DATACARD_LIST.ACTIVE_CONTACTS]: getDataCardModalInfo,
    [DATACARD_LIST.ACTIVE_CONTACTS_CPM]: getDataCardModalInfo,
    [DATACARD_LIST.TOTAL_CONTACTS]: getTotalContactsModalInfo,
    [DATACARD_LIST.ENGAGED_CONTACTS]: () => false,
  }

  const closeDataCardsModal = () =>
    setState({
      ...state,
      dataCardModalOpen: false,
    })
  const openDataCardsModal = (dataCardId: DATACARD_LIST) => {
    setState((state: State) => ({
      ...state,
      dataCardActive: dataCardId,
      dataCardModalOpen: true,
    }))
    const { totalContactsValue = PERIOD_MODAL.Week } = JSON.parse(getItem(ContactSegmentsSession.DATACARD_TOTAL_CONTACTS) ?? '{}')

    const { engagedContactsValue = PERIOD_MODAL.Week } = JSON.parse(getItem(ContactSegmentsSession.DATACARD_ENGAGED_CONTACTS) ?? '{}')
    const actions: { [key in PERIOD_MODAL]: () => void } = {
      [PERIOD_MODAL.Year]: () => dataCardModalFunctions[dataCardId](12, PERIOD_GROUPED_BY.Monthly, PERIOD_MODAL.Year),
      [PERIOD_MODAL.Half_Year]: () => dataCardModalFunctions[dataCardId](6, PERIOD_GROUPED_BY.Monthly, PERIOD_MODAL.Half_Year),
      [PERIOD_MODAL.Quarter]: () => dataCardModalFunctions[dataCardId](3, PERIOD_GROUPED_BY.Monthly, PERIOD_MODAL.Quarter),
      [PERIOD_MODAL.Week]: () => dataCardModalFunctions[dataCardId](7, PERIOD_GROUPED_BY.Daily, PERIOD_MODAL.Week),
    }
    actions[
      dataCardId === DATACARD_LIST.ACTIVE_CONTACTS || dataCardId === DATACARD_LIST.ACTIVE_CONTACTS_CPM
        ? PERIOD_MODAL.Quarter
        : dataCardId === DATACARD_LIST.TOTAL_CONTACTS
        ? (totalContactsValue as PERIOD_MODAL)
        : (engagedContactsValue as PERIOD_MODAL)
    ]()
  }
  const closeDropDown = () => setState({ ...state, dropdownOpen: !dropdownOpen })
  const { name, categories, contactsUsed, lastUpdated } = dataCardsModalInfo

  const toggleCollapseUpgradeBanner = (collapse: boolean) => {
    setItem(ContactSegmentsSession.UPGRADE_BANNER, (!collapse).toString())
    update({ showUpgradeBanner: !collapse })
  }

  const tableHeaderProps = {
    folderPath,
    search,
    searchResults,
    tags,
    onFolderClick: setFolder,
    activeTagId,
    currentFilter: filterActive,
    onChangeHandler: handleSearch,
    clearOnChange: [filterActive, activeFolderId, activeTagId],
  }

  const emptyListingSearch: EmptyListingProps = {
    headline: t(`Sorry, we couldn’t find any segments matching your search for “{{search}}”`, { search: ellip(search ?? '', 25) }),
    text: 'Please double check your spelling and folder selection above or browse All Segments.',
    buttonText: 'Browse All Segments',
    imgSrc: StaticImageNames.emptySearch,
    withoutBorder: true,
  }

  const emptyStateProps = search
    ? emptyListingSearch
    : getEmptyListingOptions(
        [CRMConnectorType.SALESFORCE, CRMConnectorType.SUGAR].includes(connectorType) && hasUCLInitialized,
        hasUCLInitialized,
        t,
        rootClass
      )[emptyListingOption]
  const checkedItemsType = filterActive?.name === allSegmentsFilter.name ? ItemType.SEGMENT : (ItemType.UNSORTABLE_SEGMENT as ItemType)

  const renderHeaderMainAction = (dropDownActions: MenuItem[]) => (
    <>
      {hasToShowUpgradeHelper && !disableSegmentModifications && (
        <CollapsibleBannerCollapsedContent
          onClick={toggleCollapseUpgradeBanner}
          customTooltipText={t('Upgrade to Audience Center')}
          collapsed={!showUpgradeBanner}
          icon={SvgNames.lightBulbNoBackground}
        />
      )}
      <Tooltip
        hide={hasUCLInitialized}
        position={'left'}
        trigger={
          <Button
            className={`${rootClass}__new-segment-button`}
            buttonType={ButtonType.PRIMARY}
            onClick={() => goCreateSegment(SEGMENTS_LIST_URL, { activeFolderId })}
            disabled={!hasUCLInitialized || disableSegmentModifications}
          >
            {t('Create segment')}
          </Button>
        }
      >
        {disableSegmentModifications ? t('Segment.Tooltip.Disabled.Migration') : t(NON_INITIALIZED_UCL_TOOLTIP)}
      </Tooltip>
      <DropDown
        type={DropDownType.STYLED}
        hasOverflowIcon
        isOpen={dropdownOpen}
        toggleOpen={closeDropDown}
        className={classNames({ [`${rootClass}__drop-down-open`]: dropdownOpen })}
      >
        <DropDownActions menuItems={dropDownActions} closeDropDown={closeDropDown} />
      </DropDown>
    </>
  )

  const renderPageHeader = (dropDownActions: MenuItem[]) => {
    return (
      <PageHeader
        leftContent
        primaryText={t('Audience Center')}
        dataTest={rootClass}
        className={classNames(`${rootClass}__page-header`, `${rootClass}__header`)}
      >
        {renderHeaderMainAction(dropDownActions)}
      </PageHeader>
    )
  }

  const renderCautionContent = () => {
    return (
      <div className={`${rootClass}__caution-content`}>
        <div className={`${rootClass}__caution-text`}>
          <Typography text={t('Forms.Submissions.Notification.Text', { count: formsToMigrate.length })} inline />
          <TextLink size={TextLinkSize.LARGE} text={t('Forms.Submissions.Notification.LearnMore')} link={LEARN_MORE_LINK} />
        </div>
        <div className={`${rootClass}__caution-buttons`}>
          <Button buttonType={ButtonType.FLOAT_YELLOW} onClick={() => setState({ ...state, showUpdateFormsModal: true })}>
            {t('Update')}
          </Button>
          <Button buttonType={ButtonType.TERTIARY} onClick={onFormsNotificationDismiss}>
            {t('Remind me later')}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <DragLayer disabled={disableDragPreview} />
      <ContactSegmentsActions
        className={rootClass}
        isContactPreferences={isContactPreferences}
        openDropdown={(dropdownOpen) => setState((state) => ({ ...state, dropdownOpen }))}
        hasUCLInitialized={hasUCLInitialized}
        hasUpgradeHelperAction={!!hasToShowUpgradeHelper}
      >
        {({
          callMenuAction,
          clearModals,
          clearSegmentAssets,
          clearSegmentsToDelete,
          dropDownActions,
          headerActions,
          onMoveToFolder,
          onRowSelect,
          onRowSelectionChanged,
          rowActions,
          segmentAssetToDelete,
          segmentsToDelete,
          ...modalsProps
        }) => (
          <>
            <ContactSegmentsModals {...modalsProps} clear={clearModals} onMoveClick={onMoveToFolder} />
            {segmentsToDelete && segmentsToDelete.length > 0 && segmentAssetToDelete && segmentAssetToDelete.length > 0 && (
              <DependenciesContentModal
                selectedItems={segmentsToDelete.map(({ externalId, name }) => ({ id: externalId, name }))}
                onDelete={(items: DependenciesContentModalItem[]) => {
                  clearSegmentAssets()
                  clearSegmentsToDelete()
                  onDelete(segmentsToDelete.filter(({ externalId }) => items.some(({ id }) => id === externalId)))
                }}
                onCancel={() => {
                  clearSegmentAssets()
                  clearSegmentsToDelete()
                }}
                itemType={DependenciesItemType.SEGMENT}
                customCautionMessage={t('Contact.Segments.Dependencies.Modal.Custom.Text')}
              />
            )}
            {showDoneCopySegmentsModal && <DoneCopySegmentsModal />}
            {showUpdateFormsModal && <UpdateFormsModal forms={formsToMigrate} onCancel={onFormsUpdateClose} onUpdate={onFormsUpdate} />}
            {renderPageHeader(dropDownActions)}
            <div className={`${rootClass}__page-content`} data-test={`${dataTest}-container`}>
              {showUpgradeAssistantBanner && (
                <UpgradeAssistantBanner toggleBanner={toggleCollapseUpgradeBanner} onDismiss={() => toggleCollapseUpgradeBanner(true)} />
              )}
              {showUpdateFormsCaution && <Caution className={`${rootClass}__caution`}>{renderCautionContent()}</Caution>}
              <DataCardsContainer onClick={openDataCardsModal} />
              <div className={`${rootClass}__main`}>
                <ContactSegmentsCollapsibleMenu
                  reference={menuRef}
                  outerScrollAreaRef={pageContainerRef}
                  className={rootClass}
                  hasCollapsedMenu={hasCollapsedMenu}
                  callMenuAction={callMenuAction}
                />
                <ListPageTable
                  searchPlaceholder={t('Search segments')}
                  {...tableHeaderProps}
                  {...emptyStateProps}
                  //FIXME: Change any after using tableV2 in listing pages.
                  enableTableV2={TABLEV2_ENABLED_LISTINGS.includes(checkedItemsType)}
                  onRowClicked={onRowClicked as any}
                  onRowsSort={sortSegments as any}
                  canDrop={canDropInSegments as any}
                  columns={
                    search
                      ? (searchColumnsV2 as any)
                      : isContactPreferences
                      ? contactPreferencesColumnsV2
                      : getContactSegmentsColumns(update, crmSourceOptions)
                  }
                  onAllItemsFilterClick={setAllSegmentsFilter}
                  loading={search ? searchSegmentsLoading : loading || isProcessingAction}
                  data={search ? searchSegmentsResults : segments}
                  onSearch={onSearch}
                  searchAll={searchAllSegments}
                  resultsLabel={t('Segments')}
                  searchAllFilter={allSegmentsFilter}
                  currentTag={currentTag}
                  useCheckboxes
                  hasDragDrop
                  currentFolder={currentFolder}
                  onApplyAndRemoveTags={applyAndRemoveTags}
                  onCreateTag={createTag}
                  tags={tags}
                  tagAction={onClickTagInRow}
                  buttonOnClick={search ? () => onSearchEmptyButtonClicked(setFilter, update) : emptyStateButtonActions[emptyListingOption]}
                  onLinkClick={emptyListingOption === EMPTY_LISTING_OPTIONS.EMPTY_FOLDER ? setAllSegmentsFilter : undefined}
                  onLoading={() => refreshSegments({ page: currentPage + 1 })}
                  allLoaded={allSegmentsLoaded}
                  onRowExpandedV2={<T,>(row: Row<T>) => onRowExpandedV2(row, setState)}
                  resetSelectedRowsOnChange={[filterActive, activeFolderId, activeTagId, isProcessingAction]}
                  hasToExpandV2={<T,>(row: Row<T>) => onHasToExpandV2(row, expandedSegments)}
                  rowActions={rowActions}
                  headerActions={headerActions}
                  className={`${rootClass}__contacts-table`}
                  onRowSelect={onRowSelect}
                  onRowSelectionChanged={onRowSelectionChanged}
                  autoResetSelectedRows={!!search}
                  itemType={ItemType.SEGMENT}
                />
              </div>
              {dataCardActive && (
                <DataCardsModal
                  isOpen={dataCardModalOpen}
                  closeModal={closeDataCardsModal}
                  dropDownOptions={getDropDownOptions(dataCardModalFunctions[dataCardActive])}
                  dropDownValue={name}
                  title={dataCardModalValues[dataCardActive].title}
                  textHeader={dataCardModalValues[dataCardActive].textHeader(t)}
                  textFooter={dataCardModalValues[dataCardActive].textFooter(t)}
                  chart={
                    dataCardsModalEmptyState
                      ? renderEmptyState(dataCardActive)
                      : createChartBar(
                          t(chartTextValues[dataCardActive].title),
                          loadingChart,
                          t(chartTextValues[dataCardActive].subtitle),
                          contactsUsed,
                          categories,
                          rootClass,
                          lastUpdated,
                          dataCardActive
                        )
                  }
                />
              )}
            </div>
          </>
        )}
      </ContactSegmentsActions>
    </DndProvider>
  )
}

export default ContactSegments
