import React, { FC, ReactNode, useMemo, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import ClipboardCopy, { CopyTextSize } from '@components/ClipboardCopy/ClipboardCopy'
import FormRow from '@components/FormRow'
import Input from '@components/Input/Input'
import InputV2 from '@components/InputV2/InputV2'
import { LabelV2 } from '@components/LabelV2/LabelV2'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import StatusToast, { Status } from '@components/StatusToast/StatusToast'
import Toggletip, { ToggletipSide } from '@components/Toggletip/Toggletip'
import Typography, { ModalHeaderFormStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { SubscriptionManagementDtoInput } from '@graphql/types/mutation-types'
import { useSubscriptionManagementRequests } from '@src/pages/SubscriptionManagement/GraphQL/SubscriptionManagementRequest.graphQL'
import { SubscriptionManagementDTO } from '@src/pages/SubscriptionManagement/SubcriptionManagement.constants'
import { upsertSM } from '@src/pages/SubscriptionManagement/SubscriptionManagement.utils'
import { useAccountSettings } from '@utils/account/account.utils'

import './AddEditVersionModal.css'

interface AddEditVersionModalProps {
  dataTest?: string
  version?: SubscriptionManagementDTO
  onClose: () => void
  dropDownLanguages: SelectV2SingleOption[]
  selected?: string
}

const rootClass = 'add-edit-version-modal'

export interface AddEditVersionModalState {
  name: string
  description: string
  language: string
  statusToast: { statusMessage: string | ReactNode; status: Status; showStatusToast: boolean }
}
const SMART_REDIRECT_URL = '/acton/rif/{{Env.AccountId}}/{{Env.MsgId}}/-/{{Env.RecId}}/{{Env.SrcId}}/zout?sid={{Env.Code}}'

const AddEditVersionModal: FC<AddEditVersionModalProps> = (props: AddEditVersionModalProps) => {
  const { dataTest = rootClass, version, onClose, dropDownLanguages, selected } = props
  const { t } = useTranslation()
  const [state, setState] = useState<AddEditVersionModalState>({
    name: version?.name || '',
    description: version?.description || '',
    language: version?.version || '',
    statusToast: { statusMessage: '', status: Status.FAIL, showStatusToast: false },
  })
  const { name, description, language, statusToast } = state
  const { accountResponseServerName, hasSubscriptionManagementMultilanguageAutoDetect } = useAccountSettings()
  const { upsertSMVersion } = useSubscriptionManagementRequests()

  const modalHeader = (
    <ModalHeader headerType={ModalHeaderType.Form} dataTest={`${dataTest}-modal-header`}>
      <Typography text={version ? t('Edit language version') : t('Create language version')} {...ModalHeaderFormStyle} />
    </ModalHeader>
  )

  const upsertSubscriptionVersion = () => {
    const edit = !!version
    const newVersion: SubscriptionManagementDtoInput = {
      name: edit ? version?.name : state.name,
      description: state.description,
      languageValue: edit ? version?.version : state.language,
      enabled: version?.isEnabled,
      editing: edit,
      newName: edit ? state.name : '',
      newLanguageValue: edit ? state.language : '',
    }
    upsertSM(upsertSMVersion, setState, newVersion)
  }

  const isValidString = (str: string) => {
    const regex = /^[a-zA-Z0-9\s]+$/
    if (str.length > 30) {
      return false
    }
    if (str.length > 0 && (str.trim().length === 0 || str.startsWith(' '))) {
      return false
    }
    return !str ? true : regex.test(str)
  }

  const url = useMemo(
    () =>
      accountResponseServerName +
      SMART_REDIRECT_URL +
      (version?.default ? '' : language ? `&languageVersion=${language}` : `&languageVersion=${name}`),
    [accountResponseServerName, language, name, version?.default]
  )

  const defaultValue = useMemo(() => dropDownLanguages.find((option) => option.label === selected), [dropDownLanguages, selected])

  const smartUrl = useMemo(() => accountResponseServerName + SMART_REDIRECT_URL + `&smartRedirect`, [accountResponseServerName])

  const copyUrlText = useMemo(() => t('Copy URL'), [t])

  const versionUrls = () => {
    return (
      <div className={`${rootClass}__body-items-align`}>
        <div className={`${rootClass}__block`}>
          <div className={`${rootClass}__url-tooltip`}>
            <Typography text={t('Version URL')} type={TextType.BODY_TEXT} weight={TextWeight.MEDIUM} className={`${rootClass}__urls-typography`} />
            <Toggletip
              side={ToggletipSide.TOP}
              description={t('Subscription.Management.Modal.Versions.URL')}
              triggerOnHover
              className={`${rootClass}__tooltip`}
            />
          </div>
          <ClipboardCopy text={copyUrlText} value={url} textWeight={TextWeight.MEDIUM} size={CopyTextSize.REGULAR}></ClipboardCopy>
        </div>
        <Typography
          text={
            language
              ? accountResponseServerName + SMART_REDIRECT_URL + '&languageVersion=' + language
              : accountResponseServerName + SMART_REDIRECT_URL + '&languageVersion=' + name
          }
          type={TextType.BODY_TEXT}
          weight={TextWeight.REGULAR}
          className={`${rootClass}__typography-word`}
        />
      </div>
    )
  }

  const smartRedirect = () => {
    return (
      <div className={`${rootClass}__body-items-align`}>
        <div className={`${rootClass}__block`}>
          <div className={`${rootClass}__url-tooltip`}>
            <Typography
              text={t('Smart Redirect URL')}
              type={TextType.BODY_TEXT}
              weight={TextWeight.MEDIUM}
              className={`${rootClass}__urls-typography`}
            />
            <Toggletip
              side={ToggletipSide.TOP}
              description={t('Subscription.Management.Modal.SmartRedirect')}
              triggerOnHover
              className={`${rootClass}__tooltip`}
            />
          </div>
          <ClipboardCopy text={copyUrlText} value={smartUrl} textWeight={TextWeight.MEDIUM} size={CopyTextSize.REGULAR}></ClipboardCopy>
        </div>
        <Typography
          text={
            language ? accountResponseServerName + SMART_REDIRECT_URL + '&smartRedirect' : t('You must select version language to use this option')
          }
          type={TextType.BODY_TEXT}
          weight={TextWeight.REGULAR}
          className={`${rootClass}__typography-word`}
        />
      </div>
    )
  }

  return (
    <Modal className={classNames(rootClass)} dataTest={dataTest} isOpen header={modalHeader}>
      {statusToast?.showStatusToast && (
        <StatusToast
          message={statusToast?.statusMessage}
          status={statusToast.status}
          closeStatus={() => setState((state) => ({ ...state, statusToast: { ...statusToast, showStatusToast: false } }))}
        />
      )}
      <ModalBody className={`${rootClass}__modal-body`}>
        <div className={`${rootClass}__body-items`}>
          <Typography
            text={t('Set up your customized language version for Subscription Management.')}
            type={TextType.BODY_TEXT_LIGHT}
            weight={TextWeight.REGULAR}
            className={`${rootClass}__customized-text`}
          />
          <FormRow>
            <LabelV2 labelType={'medium'} required>
              {t('Version name')}
            </LabelV2>
            <InputV2
              error={!isValidString(name)}
              maxCharacterProps={{
                maxLength: 30,
                hideLengthIfEmpty: true,
                lengthTextLineHeight: undefined,
                lengthTextType: undefined,
              }}
              statusProps={{
                customTooltipErrorMessages: t('Version name cannot contain any special characters'),
                reload: isValidString(name),
              }}
              inputInfo={{
                errorText: name.length > 30 ? t('Version.Name.MaxLength') : t('Version name cannot contain any special characters'),
                enabled: !isValidString(name),
              }}
              dataTest={`${dataTest}-input-name`}
              value={name}
              placeholder={t('Enter version name here')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setState({ ...state, name: e.target.value })
              }}
              className={`${rootClass}__inputv2`}
            />
          </FormRow>
          <FormRow>
            <LabelV2 labelType={'medium'}>{t('Description')}</LabelV2>
            <Input
              dataTest={`${dataTest}-input-description`}
              value={description}
              placeholder={t('Enter description here')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setState({ ...state, description: e.target.value })
              }}
            />
          </FormRow>
          <FormRow>
            <LabelV2 labelType={'medium'}>{t('Version language')}</LabelV2>
            <SelectV2
              defaultValue={defaultValue ?? ''}
              options={dropDownLanguages}
              dataTest={`${dataTest}-select-language`}
              insideModal
              placeholder={t('Select language')}
              onChange={(option) => setState({ ...state, language: option?.value ?? '' })}
            />
          </FormRow>
          {(name || language) && versionUrls()}
          {language && hasSubscriptionManagementMultilanguageAutoDetect && smartRedirect()}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button buttonType={ButtonType.TERTIARY} onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} disabled={!name || !isValidString(name)} onClick={upsertSubscriptionVersion}>
          {version ? t('Edit') : t('Create')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default AddEditVersionModal
