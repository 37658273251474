import crmSourceOptions from '@graphql/microservices/crm/crmSourceOptions'
import { CrmSourceOptionsQuery, CrmSourceOptionsQueryVariables } from '@graphql/types/microservice/crm-types'
import { filterNotEmptyArray } from '@utils/array'
import { CRM_CONNECTOR_DEFINITIONS, CRMSourceOption } from '@utils/crm.utils'
import useCRM from '@utils/hooks/useCRM'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'

export interface CRMServiceRequests {
  getSourceOptionsRequest: () => Promise<CRMSourceOption[] | undefined>
}

export const useCRMService = (): CRMServiceRequests => {
  const {
    client: { query },
  } = useMicroserviceClient({ serviceName: MicroserviceClients.CRM })

  const { connectorType } = useCRM()

  const getSourceOptionsRequest = async () => {
    const { data } = await query<CrmSourceOptionsQuery, CrmSourceOptionsQueryVariables>({
      query: crmSourceOptions,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables: { connectedCrmName: CRM_CONNECTOR_DEFINITIONS[connectorType] },
    })
    if (data) {
      return data.crmSourceOptions?.filter(filterNotEmptyArray)
    }
  }

  return {
    getSourceOptionsRequest,
  }
}
