import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import {
  CrmPushError,
  crmPushErrorsModalInitialState,
  CrmPushErrorsModalState,
  defaultSortingBy,
  getPushErrorsFromResponse,
} from '@complex/CrmPushErrorsModal/CrmPushErrorsModal.utils'
import { useCrmPushErrorsModalRequests } from '@complex/CrmPushErrorsModal/GraphQL/CrmPushErrorsMOdalRequests.graphQL'
import Button, { ButtonType } from '@components/Button'
import Modal, { ModalHeader, ModalBody, ModalFooter } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import StatusToast, { Status } from '@components/StatusToast/StatusToast'
import { TableV2 } from '@components/TableV2/TableV2'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { useTranslation } from '@const/globals'
import { getFormattedDate, getFormattedTime } from '@utils/date'
import useCRM from '@utils/hooks/useCRM'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'
import { logNewRelicError } from '@utils/new-relic.utils'

import './CrmPushErrorsModal.css'

interface CrmPushErrorsModalProps {
  className?: string
  dataTest?: string
  isOpen: boolean
  onClose: () => void
  origin: string
  listId: string
}

const rootClass = 'crm-push-errors-modal'

const CrmPushErrorsModal: FC<CrmPushErrorsModalProps> = (props: CrmPushErrorsModalProps) => {
  const { dataTest = rootClass, className, isOpen, onClose, origin, listId } = props
  const { hasCRMConnected, connectorType } = useCRM()
  const [state, setState] = useState<CrmPushErrorsModalState>(crmPushErrorsModalInitialState)
  const update = useDeepUpdate(setState)
  const { crmPushErrors, showStatusToast, loading } = state
  const { getFormCRMPushErrorsRequest } = useCrmPushErrorsModalRequests()

  const { t } = useTranslation()

  const getFormCRMPushErrors = async () => {
    update({ loading: true })
    const { data, errors } = await getFormCRMPushErrorsRequest({
      origin,
      listId,
    })
    update({ loading: false })
    if (errors) {
      update({ showStatusToast: true })
      logNewRelicError(errors)
      return
    }

    if (data) {
      update({ crmPushErrors: getPushErrorsFromResponse(data) })
    }
  }

  useEffect(() => {
    if (hasCRMConnected) {
      getFormCRMPushErrors()
    }
  }, [origin, listId])

  const header = (
    <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__header`}>
      {t('CRMPushErrorModal.header', { crmName: connectorType, count: crmPushErrors?.length })}
    </ModalHeader>
  )

  const renderStatusToast = () => (
    <StatusToast
      message={t('There was an error retrieving the CRM Push Errors data.')}
      status={Status.FAIL}
      closeStatus={() => update({ showStatusToast: false })}
    />
  )

  const columns: ColumnDefWithAdditionalProps<CrmPushError, any>[] = [
    {
      header: 'Date222',
      textAlign: 'left',
      accessorKey: 'timestamp',
      hideTooltip: true,
      disableSorting: true,
      maxSize: 150,
      cell: (row) => getFormattedDate(row.cell.row.original.timestamp),
    },
    {
      header: 'Time',
      textAlign: 'left',
      accessorKey: 'timestamp',
      hideTooltip: true,
      disableSorting: true,
      maxSize: 150,
      cell: (row) => getFormattedTime(row.cell.row.original.timestamp),
    },
    {
      header: 'Email',
      textAlign: 'left',
      accessorKey: 'email',
      hideTooltip: true,
      disableSorting: true,
      maxSize: 250,
    },
    {
      header: 'Description',
      textAlign: 'left',
      accessorKey: 'description',
      hideTooltip: true,
      disableSorting: true,
      enableLongContent: true,
    },
  ]
  return (
    <>
      {showStatusToast && renderStatusToast()}
      <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
        <ModalBody className={`${rootClass}__body`}>
          <TableV2 enableInsideLoader loading={loading} data={crmPushErrors ?? []} columns={columns} sortingBy={defaultSortingBy} withoutBorders />
        </ModalBody>
        <ModalFooter footerType={ModalFooterType.Form} className={`${rootClass}__footer`}>
          <Button buttonType={ButtonType.WHITE} onClick={onClose} dataTest={`${dataTest}-button-tertiary`}>
            {t('Close')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default CrmPushErrorsModal
