import { MouseEvent, KeyboardEvent } from 'react'

export type MouseOrKeyboardEvent<T extends HTMLElement> = MouseEvent<T> | KeyboardEvent<T>
export const isKeyboardEvent = <T extends HTMLElement>(event: MouseOrKeyboardEvent<T>): event is KeyboardEvent<T> => 'key' in event

/**
 * Provides an event handler that invokes an action on click and also its equivalent when navigating via keyboard.
 * Component that this is passed to needs to have support for a `tabIndex`, `onClick` and `onKeyDown` props.
 *
 * @example
 * const onClick = () => doSomething()
 * return <Component {...a11yClick(onClick, isDisabled)} />
 *
 * @example
 * const onClick = (event: MouseOrKeyboardEvent) => {
 *  if (isKeyboardEvent(event)) {
 *    // Extra key logic
 *  } else {
 *    // Extra click logic
 *  }
 * }
 * return <Component {...a11yClick(onClick, isDisabled)} />
 */
export const a11yClick = <T extends HTMLElement>(onClick: (event?: MouseOrKeyboardEvent<T>) => void, isDisabled = false) => {
  const handleClick = (event: MouseOrKeyboardEvent<T>) => {
    if (!event || isDisabled) {
      return
    }
    const shouldClick = !isKeyboardEvent(event) || event.key === ' ' || event.key === 'Enter'

    if (shouldClick) {
      onClick(event)
    }
  }

  return {
    tabIndex: isDisabled ? -1 : 0,
    onClick: (event: MouseEvent<T>) => handleClick(event),
    onKeyDown: (event: KeyboardEvent<T>) => handleClick(event),
  }
}
