import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import './StickyContainer.css'

export enum Status {
  SUCCESS = 'success',
  WARNING = 'warning',
  PAUSE = 'pause',
  FAIL = 'fail',
}

interface Props {
  className?: string
  children: ReactNode
  isSticky: boolean
}

const rootClass = 'sticky-container'

const StickyContainer: FC<Props> = (props: Props) => {
  const { children, isSticky } = props

  return (
    <div className={classNames({ [`${rootClass}__fixed-container`]: isSticky, [`${rootClass}__fixed-container-wrapper`]: !isSticky })}>
      <div className={classNames({ [`${rootClass}__fixed-container-position`]: isSticky })}>
        <div
          className={classNames({
            [`${rootClass}__fixed-container-content`]: isSticky,
            [`${rootClass}__fixed-container-wrapper-content`]: !isSticky,
          })}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default StickyContainer
