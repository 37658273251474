import { gql } from '@apollo/client'

export default gql`
  mutation updateSharePermissions($parentAccountId: Long!, $sharePermissions: [SharePermissionsInput], $userId: Long!) {
    updateSharePermissions(parentAccountId: $parentAccountId, sharePermissions: $sharePermissions, userId: $userId) {
      failureIds {
        id
        name
        reason
      }
      status
      successIds {
        id
        name
      }
    }
  }
`
