import React, { FC, useCallback } from 'react'

import { useSegmentInfoHoverCardRequests } from '@complex/ContactSegments/SegmentInfoHoverCard/GraphQL/SegmentInfoHoverCard.graphQL'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { SyncedSegment } from '@graphql/types/microservice/crm-types'
import { ListPageStatusToast } from '@interface/ListPage.context'
import { syncCrmSegment } from '@utils/contactSegments/contactSegments.utils'

import './../SegmentInfoHoverCard.css'

interface CRMSourcesDefinitionProps {
  crmSources: string[]
  crmSyncDetails?: SyncedSegment
  showStatusToast?: (toast: ListPageStatusToast) => void
}

const rootClass = 'segment-details-info-hover'
const CRMSourcesDefinition: FC<CRMSourcesDefinitionProps> = (props: CRMSourcesDefinitionProps) => {
  const { crmSources, crmSyncDetails, showStatusToast } = props

  const { setSuccessfulSyncFlagRequest, syncNowRequest } = useSegmentInfoHoverCardRequests()

  const { t } = useTranslation()

  const doSyncCrmSegment = useCallback(async () => {
    return syncCrmSegment(setSuccessfulSyncFlagRequest, syncNowRequest, t, crmSyncDetails?.syncedSegmentId, showStatusToast)
  }, [crmSyncDetails, setSuccessfulSyncFlagRequest, showStatusToast, syncNowRequest, t])

  return (
    <div className={`${rootClass}__sources-container`}>
      <div className={`${rootClass}__sources`}>
        {crmSources?.map((source) => {
          const typographyProps = {
            text: source,
            type: TextType.NORMAL_TEXT_TEAL,
            weight: TextWeight.MEDIUM,
            inline: true,
          }
          return (
            <TextWithTooltipOnEllip
              key={`${source}-name`}
              tooltipProps={{ align: 'start', triggerClassName: 'ellip' }}
              typographyProps={typographyProps}
            />
          )
        })}
      </div>
      {crmSyncDetails && crmSyncDetails.syncedSegmentId && !crmSyncDetails.ongoingSync && (
        <TextLink text={t('Sync now')} size={TextLinkSize.SMALL} hideIcon onClick={doSyncCrmSegment} />
      )}
    </div>
  )
}

export default CRMSourcesDefinition
