import React, { FC, useEffect, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import Caution from '@components/Caution/Caution'
import Container from '@components/Container'
import Radio from '@components/Radio/Radio'
import Select from '@components/Select/Select'
import Typography, { TextType } from '@components/Typography/Typography'
import { getUUID, useTranslation } from '@const/globals'
import personalizeMessage from '@graphql/queries/personalizeMessage'
import { MarketingListResponse, PersonalizeMessageQuery, PersonalizeMessageQueryVariables, SampleContact } from '@graphql/types/query-types'
import { usePrevious } from '@utils/hooks/usePrevious'
import { logNewRelicError } from '@utils/new-relic.utils'

import MessagePreviewVisual from './MessagePreviewVisual'
import { SMSContextAPI } from '../../../context/SMSContext'

import './messagePreview.css'

interface Props {
  context: SMSContextAPI
  dataTest?: string
  rootClass?: string
}

export enum MessageType {
  INDIVIDUAL,
  CONCATENATED,
}

type State = {
  messageType: MessageType
  personalization: string
  recId: string
  callFailure: boolean
}

export const MessagePreview: FC<Props> = (props: Props) => {
  const { context, rootClass = 'message-preview', dataTest = 'message-preview' } = props
  const client = useApolloClient()

  const { t } = useTranslation()

  const {
    values: {
      smsMessage: { messageText, recipients },
    },
  } = context

  const [state, setState] = useState<State>({
    messageType: MessageType.INDIVIDUAL,
    personalization: '',
    recId: '',
    callFailure: false,
  })

  const prevMessage = usePrevious(messageText)

  useEffect(() => {
    if (prevMessage !== messageText) {
      setState({
        ...state,
        recId: '',
        personalization: '',
      })
    }
  }, [prevMessage, messageText])

  const getPersonalizations = () => {
    const reducedRecipients = recipients.length
      ? recipients.flatMap((response: MarketingListResponse) => {
          return response.sampleContacts.reduce((acc, curr: SampleContact) => {
            if (curr.firstName || curr.lastName) {
              return [
                ...acc,
                <option key={getUUID(true)} value={curr.recId}>
                  {`${curr.firstName} ${curr.lastName}`}
                </option>,
              ]
            } else {
              return [
                ...acc,
                <option key={getUUID(true)} value={curr.recId}>
                  {curr.emailAddress}
                </option>,
              ]
            }
          }, [] as any)
        })
      : []
    return [
      <option key="EMPTY" value="">
        {t('Select recipient')}
      </option>,
      ...reducedRecipients,
    ]
  }

  const replacePersonalization = async (recId: string) => {
    if (recId) {
      try {
        const data = await client.query<PersonalizeMessageQuery, PersonalizeMessageQueryVariables>({
          query: personalizeMessage,
          fetchPolicy: 'network-only',
          variables: {
            smsPersonalizedMessageState: {
              recId,
              messageText,
            },
          },
        })

        if (data.data.personalizeMessage) {
          setState({
            ...state,
            personalization: data.data.personalizeMessage.messageText,
            recId,
            callFailure: false,
          })
        }
        if (data.error) {
          logNewRelicError(data.error)
        }
      } catch {
        setState({
          ...state,
          personalization: '',
          recId: '',
          callFailure: true,
        })
      }
    } else {
      setState({
        ...state,
        recId: '',
        personalization: '',
      })
    }
  }

  return (
    <Container className={rootClass} noBottom title={'Message Preview'}>
      <div className={`${rootClass}__layout`}>
        <div className={`${rootClass}__inner-container`}>
          <Typography text={t('Select a recipient to preview their message')} />
          <Select dataTest={`${dataTest}__personalization`} value={state.recId} onChange={(e) => replacePersonalization(e.target.value)}>
            {getPersonalizations()}
          </Select>
          {state.callFailure && <Caution message={'Personalization network call failed'} />}
        </div>
        <div className={`${rootClass}__inner-container`}>
          <Radio
            className={'radio-top'}
            labelChildren={<Typography text={t('Individual Message')} type={TextType.BODY_TEXT_LIGHT} />}
            dataTest={`${dataTest}__individual`}
            onChange={() => setState({ ...state, messageType: MessageType.INDIVIDUAL })}
            checked={state.messageType === MessageType.INDIVIDUAL}
          />
          <Radio
            className={'radio-bottom'}
            labelChildren={<Typography text={t('Combined Message')} type={TextType.BODY_TEXT_LIGHT} />}
            dataTest={`${dataTest}__concatenated`}
            onChange={() => setState({ ...state, messageType: MessageType.CONCATENATED })}
            checked={state.messageType === MessageType.CONCATENATED}
          />
          <Typography
            text={t('Carriers can combine multiple message parts into one message or deliver separate message parts')}
            type={TextType.BODY_TEXT_SMALL}
            className={`${rootClass}__info`}
          />
          <Typography
            text={t('Note: this is for preview purposes only and will not alter the delivery of the message')}
            type={TextType.BODY_TEXT_TINY}
          />
        </div>
        <div className={`${rootClass}__right-container`}>
          <MessagePreviewVisual context={context} messageType={state.messageType} personalization={state.personalization} />
        </div>
      </div>
    </Container>
  )
}

export default MessagePreview
