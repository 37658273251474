import { Dispatch, SetStateAction } from 'react'
import { Row } from 'react-table'

import { History } from 'history'

import { ApolloClient } from '@apollo/client'
import { ListingPageSession } from '@complex/ListingPage/Utils/ListingPage.utils'
import { rootContext } from '@const/globals'
import { SetContainerValues } from '@interface/ListPage.context'
import { ABSplitSession } from '@src/pages/ContactSegments/components/ABSplit/ABSplit.constants'
import { State } from '@src/pages/ContactSegments/ContactSegments'
import { getFoldersUtils } from '@src/pages/ContactSegments/utils/FoldersUtils'
import { getTagsUtils } from '@src/pages/ContactSegments/utils/TagUtils'
import { Row as RowV2 } from '@tanstack/react-table'
import { ItemType } from '@utils/categorization'
import { ContactSegmentsSession, ContactSegmentsState, Segment } from '@utils/contactSegments/contactSegments.utils'
import { Update } from '@utils/contactSegments/context/ContactSegmentsContext'
import { allSegmentsFilter } from '@utils/filter'
import { getItem, removeItem, setItem } from '@utils/sessionStorage'

export const CONTACT_SEGMENTS_CURRENT_SECTION = 'contactSegments:currentSection'

export const onRowExpanded = ({ original, isExpanded }: Row, setState: Function) => {
  const { id } = original as Segment
  const expandedSegments: number[] = JSON.parse(getItem(ContactSegmentsSession.EXPANDED_SEGMENTS) ?? '[]')
  const newExpandedSegments = isExpanded ? expandedSegments.filter((actualId) => id !== actualId) : [...expandedSegments, id]
  setItem(ContactSegmentsSession.EXPANDED_SEGMENTS, JSON.stringify(newExpandedSegments))
  setState((state: State) => ({ ...state, expandedSegments: newExpandedSegments }))
}

export const onRowExpandedV2 = <T>({ original, getIsExpanded }: RowV2<T>, setState: Function) => {
  const { id } = original as Segment
  const expandedSegments: number[] = JSON.parse(getItem(ContactSegmentsSession.EXPANDED_SEGMENTS) ?? '[]')
  const newExpandedSegments = getIsExpanded() ? expandedSegments.filter((actualId) => id !== actualId) : [...expandedSegments, id]
  setItem(ContactSegmentsSession.EXPANDED_SEGMENTS, JSON.stringify(newExpandedSegments))
  setState((state: State) => ({ ...state, expandedSegments: newExpandedSegments }))
}

export const onHasToExpandV2 = <T>({ original }: RowV2<T>, expandedSegments: number[]) => expandedSegments.includes((original as Segment).id)

export const onContactSegmentsLoad = (setState: Dispatch<SetStateAction<State>>) => {
  const collapsedMenu = getItem(ContactSegmentsSession.COLLAPSED_MENU)
  const expandedSegments = getItem(ContactSegmentsSession.EXPANDED_SEGMENTS)

  setState((state) => ({
    ...state,
    hasCollapsedMenu: collapsedMenu === 'true',
    expandedSegments: JSON.parse(expandedSegments ?? '[]'),
  }))
}

export const SEGMENTS_LIST_URL = `${rootContext}/segments`
export const SEGMENT_ACTIVITY_URL = `${SEGMENTS_LIST_URL}/details`
export const CONTACTS_DETAILS_URL = `${SEGMENTS_LIST_URL}/contacts`

export const clearSegmentDetails = (update: Update) => {
  removeItem(ContactSegmentsSession.SEGMENT_DETAILS)
  removeItem(ContactSegmentsSession.SEGMENT_DETAILS_TAB)
  update({ showDetails: undefined })
}

export const loadSegmentActivity = (segment: Segment, update: Update) => {
  setItem(ContactSegmentsSession.SEGMENT_DETAILS_TAB, 'showSegmentDetailsTab')
  setItem(ContactSegmentsSession.SEGMENT_DETAILS, JSON.stringify(segment))
  update({ showDetails: segment })
}

export const loadContactsDetails = (segment: Segment, update: Update) => {
  removeItem(ContactSegmentsSession.SEGMENT_DETAILS_TAB)
  setItem(ContactSegmentsSession.SEGMENT_DETAILS, JSON.stringify(segment))
  update({ showDetails: segment })
}

export const loadLocationState = (history: History<{ showDetails?: Segment }>, update: Update) => {
  const locationState = history.location.state
  const sessionState = getItem(ContactSegmentsSession.SEGMENT_DETAILS)
  const segment = locationState?.showDetails || (sessionState ? JSON.parse(sessionState) : null)

  if (!segment) {
    clearSegmentDetails(update)
  } else {
    if (location.pathname.includes(SEGMENT_ACTIVITY_URL)) {
      loadSegmentActivity(segment, update)
    } else if (location.pathname.includes(CONTACTS_DETAILS_URL)) {
      loadContactsDetails(segment, update)
    } else {
      clearSegmentDetails(update)
    }
  }
}

export const loadInitialSessionData = async (
  showDetails: Segment | undefined,
  segmentDetails: string | null,
  history: any,
  setContainerValues: SetContainerValues<ContactSegmentsState>,
  categorizationClient: ApolloClient<any>,
  refreshRecordCount: (selectedSegments: string[], segmentWasEdited?: boolean) => void,
  update: Update,
  setSectionBySessionData: (needsReRender?: boolean) => void
) => {
  let newShowDetails: Segment | undefined
  let search: string | undefined
  let searchAllItems: boolean | undefined

  const editedSegmentSessionItem = getItem(ContactSegmentsSession.EDITED_SEGMENT)
  const searchSessionItem = getItem(`${ItemType.SEGMENT}:${ListingPageSession.SEARCH_QUERY}`)
  const searchAllSessionItem = getItem(`${ItemType.SEGMENT}:${ListingPageSession.SEARCH_SHOW_ALL}`)
  const abSplitToastSessionItem = getItem(ABSplitSession.TOAST)
  const listingCurrentSectionSessionItem = getItem(CONTACT_SEGMENTS_CURRENT_SECTION)

  if (editedSegmentSessionItem) {
    const editedSegment = JSON.parse(editedSegmentSessionItem)
    if (editedSegment) {
      removeItem(ContactSegmentsSession.EDITED_SEGMENT)
      refreshRecordCount([editedSegment.externalId], true)
    }
  }

  const urlParams = new URLSearchParams(window.location.search)

  const hasClearDetailsParam = urlParams.has('clearDetails')
  if (hasClearDetailsParam) {
    removeItem(ContactSegmentsSession.SEGMENT_DETAILS)
    window.history.pushState({}, document.title, window.location.pathname)
  } else if (segmentDetails) {
    newShowDetails = JSON.parse(segmentDetails)
  }

  const hasSearchParam = urlParams.has('search')
  if (hasSearchParam) {
    search = urlParams.get('search') || undefined
    searchAllItems = urlParams.has('searchAll')
    window.history.pushState({}, document.title, window.location.pathname)
  } else if (searchSessionItem) {
    search = JSON.parse(searchSessionItem)
    searchAllItems = searchAllSessionItem !== null && JSON.parse(searchAllSessionItem) === 'true'
  }

  if (!listingCurrentSectionSessionItem) {
    setItem(ContactSegmentsSession.FILTER, JSON.stringify(allSegmentsFilter))
    setItem(CONTACT_SEGMENTS_CURRENT_SECTION, ContactSegmentsSession.FILTER)
  }

  setSectionBySessionData()

  await Promise.all([getFoldersUtils(setContainerValues, categorizationClient), getTagsUtils(setContainerValues, categorizationClient)])

  update({
    ...(!hasClearDetailsParam && segmentDetails ? { showDetails: newShowDetails } : {}),
    ...(search ? { search, searchAllItems } : {}),
    ...(abSplitToastSessionItem ? { statusToast: JSON.parse(abSplitToastSessionItem) } : {}),
    loadingSessionData: false,
  })

  if (
    !showDetails &&
    !history.location.state?.showDetails &&
    !segmentDetails &&
    (location.pathname.includes(CONTACTS_DETAILS_URL) || location.pathname.includes(SEGMENT_ACTIVITY_URL))
  ) {
    history.replace(SEGMENTS_LIST_URL, {})
  }
}
