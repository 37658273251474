import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import Button, { ButtonType } from '@components/Button'
import FormRow from '@components/FormRow'
import Input from '@components/Input/Input'
import InputWithStatus from '@components/InputWithStatus/InputWithStatus'
import Label from '@components/Label'
import Loader from '@components/Loader'
import Modal, { ModalFooter } from '@components/Modal'
import { ModalBody } from '@components/Modal/components/ModalBody'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeader } from '@components/Modal/components/ModalHeader'
import Typography, { LineHeight, ModalHeaderListStyle, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { FromAddresses } from '@graphql/types/query-types'
import { checkEmailValidity } from '@utils/formUtils'

import './addSenderModal.css'

const rootClass = 'add-sender-modal'
const headerClass = `${rootClass}__header`

interface Props {
  isOpen: boolean
  loading: boolean
  onClose: () => void
  saveFromAddress: (data: ModalData) => void
  dataTest?: string
  dataState?: ModalData
}

export interface ModalData extends FromAddresses {
  name: string
  email: string
}

const defaultState: ModalData = {
  name: '',
  email: '',
  title: '',
  phone: '',
  cell: '',
  fax: '',
}

const NUMBERS_MAX_LENGTH = 32

const AddSenderModal: FC<Props> = ({ isOpen, loading, onClose, saveFromAddress, dataTest = 'add-sender-modal', dataState }: Props) => {
  const [state, setState] = useState<ModalData>(dataState ?? defaultState)
  const [isTouched, setIsTouched] = useState<boolean>(false)
  const { t } = useTranslation()

  const isEditing = useMemo<boolean>(() => !!dataState, [dataState])
  const isDefault = useMemo<boolean>(() => !!state.isDefault, [state])
  const isValidState = useMemo<boolean>(() => !checkEmailValidity(state.email) && !!state.name.length, [state])
  const disableSave = useMemo<boolean>(() => loading || !isTouched || !isValidState, [isTouched, isValidState, loading])

  useEffect(() => {
    if (isOpen) {
      setIsTouched(false)
      setState(dataState ?? defaultState)
    }
  }, [dataState, isOpen])

  const onInputChange = (value: string, key: string) => {
    setIsTouched(true)
    setState({ ...state, [key]: value })
  }

  const handleSave = useCallback(() => {
    const { name, email, title, phone, cell, fax, uuid } = state
    saveFromAddress({ name, email, title, phone, cell, fax, uuid })
  }, [state])

  return (
    <Modal
      isOpen={isOpen}
      dataTest={dataTest}
      className={rootClass}
      header={
        <ModalHeader className={headerClass} dataTest={`${dataTest}-header`}>
          <Typography text={t(`${isEditing ? 'Edit' : 'Add'} From Address`)} {...ModalHeaderListStyle} />
          {isDefault && <Typography className={`${headerClass}--default`} type={TextType.MODAL_HEADER_SUB_INFO} text={`(${t('default')})`} />}
        </ModalHeader>
      }
    >
      {loading && <Loader center className={`${rootClass}__loader`} />}
      <ModalBody>
        <div className={`${rootClass}__input-list`}>
          <FormRow>
            <Label className={`${rootClass}__input-label`} required>
              {t('Name')}
            </Label>
            <Input
              placeholder={t('Full Name')}
              value={state.name}
              dataTest={`${rootClass}-name-input`}
              onChange={(event) => onInputChange(event.target.value, 'name')}
              /* eslint-disable-next-line jsx-a11y/no-autofocus */
              autoFocus={!isEditing}
            />
          </FormRow>
          <FormRow>
            <Label className={`${rootClass}__input-label`} required={!isEditing}>
              {t('Email Address')}
            </Label>
            {isEditing ? (
              <Typography
                dataTest={`${rootClass}-text-email`}
                text={state.email}
                type={TextType.BODY_TEXT_LIGHT}
                lineHeight={LineHeight.MEDIUM_LARGE}
              />
            ) : (
              <InputWithStatus
                placeholder={t('Email Address')}
                value={state.email}
                onChange={(event) => onInputChange(event.target.value, 'email')}
                dataTest={`${rootClass}-input-email`}
                validityFunctions={[checkEmailValidity]}
                tooltipErrorMessages={{ emailInvalid: 'Invalid email address' }}
              />
            )}
          </FormRow>
          <FormRow>
            <Label className={`${rootClass}__input-label`}>{t('Title')}</Label>
            <Input
              placeholder={t('Job Title')}
              value={state.title}
              dataTest={`${rootClass}-title-input`}
              onChange={(event) => onInputChange(event.target.value, 'title')}
            />
          </FormRow>
          <FormRow>
            <Label className={`${rootClass}__input-label`}>{t('Phone Number')}</Label>
            <Input
              placeholder={'(123) 456-7890'}
              value={state.phone}
              dataTest={`${rootClass}-phone-input`}
              onChange={(event) => onInputChange(event.target.value, 'phone')}
              maxlength={NUMBERS_MAX_LENGTH}
            />
          </FormRow>
          <FormRow>
            <Label className={`${rootClass}__input-label`}>{t('Mobile Number')}</Label>
            <Input
              placeholder={'(123) 456-7890'}
              value={state.cell}
              dataTest={`${rootClass}-mobile-input`}
              onChange={(event) => onInputChange(event.target.value, 'cell')}
              maxlength={NUMBERS_MAX_LENGTH}
            />
          </FormRow>
          <FormRow>
            <Label className={`${rootClass}__input-label`}>{t('Fax Number')}</Label>
            <Input
              placeholder={'(123) 456-7890'}
              value={state.fax}
              dataTest={`${rootClass}-fax-input`}
              onChange={(event) => onInputChange(event.target.value, 'fax')}
              maxlength={NUMBERS_MAX_LENGTH}
            />
          </FormRow>
        </div>
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form} dataTest={`${dataTest}-footer`}>
        <Button buttonType={ButtonType.TERTIARY} onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} disabled={disableSave} onClick={handleSave}>
          {t(isEditing ? 'Save' : 'Add')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default AddSenderModal
