import React, { FC, useContext, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Checkbox from '@components/Checkbox/Checkbox'
import CodeBlockV2 from '@components/CodeBlockV2/CodeBlockV2'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import { SvgNames } from '@components/Svg/index'
import Svg, { SvgType } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'
import { FormManagerContext, FormManagerEmbed } from '@src/pages/Content/Forms/FormManager/context/FormManager.context'

import './EmbedCodeModal.css'

interface EmbedCodeModalProps {
  className?: string
  dataTest?: string
  onClose: () => void
  isOpen: boolean
}

interface EmbedCodeModalState {
  noCss: boolean
  prefilled: boolean
  currentEmbedCode: string
}

const rootClass = 'embed-code-modal'

const PREFILL_LINK = 'https://connect.act-on.com/hc/en-us/articles/360023942353-Pre-filling-a-Form-with-Data'

const getActiveEmbed = (embeds: FormManagerEmbed[], currentId: string) => {
  return embeds.find((embed) => embed.id === currentId)
}

const EmbedCodeModal: FC<EmbedCodeModalProps> = (props: EmbedCodeModalProps) => {
  const { dataTest = rootClass, className = '', onClose, isOpen } = props
  const {
    values: { embeds, row },
  } = useContext(FormManagerContext)

  if (!row) {
    throw new Error('No active row')
  }

  const currentEmbed = getActiveEmbed(embeds, row.id)

  if (!currentEmbed) {
    throw new Error('No active row')
  }

  const [state, setState] = useState<EmbedCodeModalState>({
    noCss: false,
    prefilled: false,
    currentEmbedCode: currentEmbed.sourceHTML,
  })
  const { noCss, prefilled } = state

  const { t } = useTranslation()

  const handleAction = () => {
    navigator.clipboard.writeText(state.currentEmbedCode)
    onClose()
  }

  const onChangeChecks = (box: keyof EmbedCodeModalState) => {
    if (box === 'noCss') {
      const newNoCss = !noCss
      const bothChecked = newNoCss && prefilled
      const newCurrentEmbed = bothChecked
        ? currentEmbed.noCssAndPrefilledHtml
        : newNoCss
        ? currentEmbed.noCssHtml
        : prefilled
        ? currentEmbed.prefilledHtml
        : currentEmbed.sourceHTML
      setState({ ...state, noCss: newNoCss, currentEmbedCode: newCurrentEmbed })
    } else if (box === 'prefilled') {
      const newPrefilled = !prefilled
      const bothChecked = noCss && newPrefilled
      const newCurrentEmbed = bothChecked
        ? currentEmbed.noCssAndPrefilledHtml
        : newPrefilled
        ? currentEmbed.prefilledHtml
        : noCss
        ? currentEmbed.noCssHtml
        : currentEmbed.sourceHTML
      setState({ ...state, prefilled: newPrefilled, currentEmbedCode: newCurrentEmbed })
    }
  }

  const header = (
    <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__header`}>
      {t('Embed Code')}
    </ModalHeader>
  )

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
      <ModalBody className={`${rootClass}__body`}>
        <CodeBlockV2 content={state.currentEmbedCode} footer={false} />
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form} className={`${rootClass}__footer`}>
        <div className={`${rootClass}__footer__extra`}>
          <div className={`${rootClass}__footer__extra-section`}>
            <Checkbox label={t('No CSS')} checked={noCss} onChange={() => onChangeChecks('noCss')} dataTest={`${dataTest}-checkbox-no-css`} />
            <Tooltip trigger={<Svg name={SvgNames.info} type={SvgType.ICON} />}>{t('Removes form styling')}</Tooltip>
          </div>
          <div className={`${rootClass}__footer__extra-section`}>
            <Checkbox
              label={t('Show Prefilled')}
              checked={prefilled}
              onChange={() => onChangeChecks('prefilled')}
              dataTest={`${dataTest}-checkbox-prefilled`}
            />
            <Tooltip trigger={<Svg name={SvgNames.info} type={SvgType.ICON} />} link={PREFILL_LINK} linkText={t('Learn more')}>
              {t('Pre-fills form with visitor information if it’s available.')}
            </Tooltip>
          </div>
        </div>
        <Button buttonType={ButtonType.TERTIARY} onClick={onClose} dataTest={`${dataTest}-button-tertiary`}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} onClick={handleAction} dataTest={`${dataTest}-button-primary`}>
          {t('Copy and close')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default EmbedCodeModal
