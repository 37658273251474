export function getNumberOfRenderedChildren(children: any) {
  if (Array.isArray(children)) {
    return children.reduce((acc, cur) => {
      if ((cur.props as any)?.render !== false) {
        return acc + 1
      }
      return acc
    }, 0)
  } else {
    return (children.props as any)?.render !== false
  }
}

export default {
  getNumberOfRenderedChildren,
}
