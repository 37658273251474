import React, { FC, useCallback, useContext, useState } from 'react'

import { useApolloClient, useLazyQuery } from '@apollo/client'
import Button, { ButtonType } from '@components/Button'
import { ButtonWeight } from '@components/Button/Button'
import { LinkTextButton } from '@components/LinkTextButton/LinkTextButton'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Spinner, { LoaderSize } from '@components/Spinner/Spinner'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Toggletip, { ToggletipTriggerSize } from '@components/Toggletip/Toggletip'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import recipientsValidation from '@graphql/queries/recipientsValidation'
import { RecipientsValidationQuery, RecipientsValidationQueryVariables } from '@graphql/types/query-types'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'

interface CheckEmailsProps {
  modalClassName: string
  dataTest?: string
}

const rootClass = 'check-emails'
const CONNECT_ARTICLE_LINK = 'https://connect.act-on.com/hc/en-us/articles/360023938913-Create-a-List-Maintenance-Program'

export const CheckEmails: FC<CheckEmailsProps> = ({ modalClassName, dataTest = rootClass }) => {
  const { t } = useTranslation()
  const client = useApolloClient()
  const {
    values: {
      message: { id },
    },
  } = useContext(EmailComposerContext)
  const [openModal, setOpenModal] = useState(false)

  const [getRecipientsValidation, { data, loading }] = useLazyQuery<RecipientsValidationQuery, RecipientsValidationQueryVariables>(
    recipientsValidation,
    {
      client,
      fetchPolicy: 'no-cache',
      variables: { messageId: id },
    }
  )

  const { invalidEmailCount, missingEmailCount, total } = data?.recipientsValidation ?? {}

  const errorsCount = (invalidEmailCount ?? 0) + (missingEmailCount ?? 0)
  const isDefault = !data?.recipientsValidation
  const isPass = !isDefault && !errorsCount

  const handleClick = useCallback<VoidFunction>(() => getRecipientsValidation(), [getRecipientsValidation])
  const handleOpenModal = useCallback<VoidFunction>(() => setOpenModal(true), [])
  const handleCloseModal = useCallback<VoidFunction>(() => setOpenModal(false), [])
  const handleCheckAgain = useCallback<VoidFunction>(() => {
    handleCloseModal()
    handleClick()
  }, [handleClick, handleCloseModal])

  const svgIcon = isPass ? SvgNames.spamCheckSuccessNoFill : SvgNames.spamCheckWarningNoFill

  return (
    <div data-test={dataTest} className="flex-align-center">
      {loading ? (
        <Spinner size={LoaderSize.XSMALL} unsizeWrapper />
      ) : isDefault ? null : (
        <Svg name={svgIcon} type={SvgType.LARGER_ICON} fill={SvgColor.TEXT_TEAL} />
      )}
      {isDefault && !loading ? (
        <LinkTextButton
          onClick={handleClick}
          text={t('EmailComposer.Check.Emails.Addresses.Text_default')}
          className="push-left-half"
          iconNameLeft={SvgNames.search}
          hideIconRight
        />
      ) : (
        <Typography
          text="EmailComposer.Check.Emails.Addresses.Text"
          tagProps={{ medium: { weight: TextWeight.MEDIUM, className: undefined } }}
          inline
          values={{ context: loading ? 'loading' : isPass ? 'pass' : 'fail', count: errorsCount }}
          className="push-right-x2 push-left-half"
        />
      )}
      {!isPass && (
        <Toggletip
          title={t('EmailComposer.Check.Emails.Addresses.Toggle.Title')}
          description={<Typography text="EmailComposer.Check.Emails.Addresses.Toggle.Description" tagComponents={{ br: <br /> }} />}
          sideOffset={6}
          triggerSize={ToggletipTriggerSize.SMALL}
        />
      )}
      {!!errorsCount && !loading && (
        <TextLink onClick={handleOpenModal} text={t('View results')} className="push-right-x2" hideIcon size={TextLinkSize.LARGE} />
      )}
      <Modal
        isOpen={openModal}
        className={modalClassName}
        header={
          <ModalHeader className={`${modalClassName}__header`} headerType={ModalHeaderType.Form}>
            {t('EmailComposer.Check.Emails.Modal.Title')}
          </ModalHeader>
        }
      >
        <ModalBody className={`${modalClassName}__body`}>
          <div className={`${modalClassName}__info`}>
            <Typography
              text="EmailComposer.Check.Emails.Modal.Info.Total"
              values={{ errorsCount, count: total }}
              className="push-up-x2"
              tagProps={{ medium: { weight: TextWeight.MEDIUM, className: undefined, inline: true } }}
            />
            <Typography
              text="EmailComposer.Check.Emails.Modal.Info.Invalid"
              values={{ count: invalidEmailCount }}
              tagProps={{ medium: { weight: TextWeight.MEDIUM, inline: true } }}
            />
            <Typography
              text="EmailComposer.Check.Emails.Modal.Info.Missing"
              values={{ count: missingEmailCount }}
              tagProps={{ medium: { weight: TextWeight.MEDIUM, inline: true } }}
            />
            <Button buttonType={ButtonType.TEXT_TEAL} onClick={handleCheckAgain} weight={ButtonWeight.MEDIUM} className="push-down-x3">
              <Svg name={SvgNames.generate} className="push-left" />
              {t('Check again')}
            </Button>
          </div>
          <Typography
            text="EmailComposer.Check.Emails.Modal.Tip"
            tagComponents={{
              TextLink: <LinkTextButton link={CONNECT_ARTICLE_LINK} hideIcons />,
            }}
            className="push-down-x4"
          />
        </ModalBody>
        <ModalFooter className={`${modalClassName}__footer`}>
          <Button buttonType={ButtonType.PRIMARY} onClick={handleCloseModal}>
            {t('Done')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
