import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import NumberInput, { NumberInputProps } from '@components/NumberInput/NumberInput'
import NumberRangeInput, { NumberRangeInputProps } from '@components/NumberRangeInput/NumberRangeInput'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2Props, SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './NumberAndSelectInput.css'

interface NumberAndSelectInputProps {
  className?: string
  dataTest?: string
  numberRangeInputProps?: NumberRangeInputProps
  numberInputProps?: NumberInputProps
  selectV2Props: SelectV2Props
}

const rootClass = 'number-and-select-input'

const NumberAndSelectInput: FC<NumberAndSelectInputProps> = (props: NumberAndSelectInputProps) => {
  const { dataTest = rootClass, className = '', selectV2Props, numberRangeInputProps, numberInputProps } = props
  const [error, setError] = useState<string | undefined>(undefined)
  const { t } = useTranslation()
  const onErrorStatusChange = (error: string | undefined) => setError(error)
  const renderSingleValue = (option: SelectV2SingleOption) => option.label

  const selectDefaultProps: SelectV2Props = {
    placeholder: '',
    renderSingleValue,
    isClearable: false,
    isSearchable: false,
    showGroupsWithoutLabel: true,
  }

  useEffect(() => {
    if (!numberRangeInputProps) {
      setError(undefined)
    }
  }, [numberRangeInputProps])

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={classNames(`${rootClass}__container`, { [`${rootClass}__container-error`]: error })}>
        {numberRangeInputProps && <NumberRangeInput {...numberRangeInputProps} onErrorStatusChange={onErrorStatusChange} handleErrorFromOuter />}
        {numberInputProps && !numberRangeInputProps && <NumberInput {...numberInputProps} className={`${rootClass}__single-input`} />}
        <div className={`${rootClass}__divider`} />
        <SelectV2 {...selectDefaultProps} {...selectV2Props} />
      </div>
      {error && (
        <div className={`${rootClass}__error`} data-test={`${dataTest}-error`}>
          <Svg name={SvgNames.inputStatusInvalidNoFill} type={SvgType.ICON} fill={SvgColor.ERROR_TEXT} />
          <Typography text={t(error)} type={TextType.ERROR_NEW} lineHeight={LineHeight.MEDIUM_SMALL} inline />
        </div>
      )}
    </div>
  )
}

export default NumberAndSelectInput
