import { MutableRefObject } from 'react'

import { IPluginRow } from '@beefree.io/sdk/dist/types/bee'
import { filterNotEmptyArray } from '@utils/array'
import { IPluginDisplayConditionExtended, IEntityContentJsonExtended } from '@utils/composer/beeEditor/beeEditorTypes'
import { EmailMessage } from '@utils/composer/context/EmailComposer.context'
import { getUUID } from '@utils/const/globals'

import { isParagraphModule, PlainTextRSSResponse } from '../../utils/BeeEditor.types'
import {
  findDisplayConditionForTextBlock,
  getDisplayConditionsRegExp,
  getRowDisplayConditions,
  stripDisplayConditionDelimiters,
} from '../../utils/DisplayConditions.utils'

export interface PlainTextBlock {
  uuid: string
  text: string
  dynamicContent?: IPluginDisplayConditionExtended
  rss?: PlainTextRSSResponse
}

export const RSS_PATTERN = new RegExp(`(--- RSS Block \\d+ ---[\\s\\S]*?--- RSS Block \\d+ ---)`, 'gm')

export const createRSSPlainTextBlock = (data: PlainTextRSSResponse, plainTextId: number, text: string) => {
  return {
    uuid: data.value.customFields?.rssCustomFields?.id ?? '',
    text,
    rss: {
      ...data,
      plainTextId,
    },
  }
}

const getRSSPlainTextBlock = (textPart: string, map: Map<Number, PlainTextBlock>) => {
  const rssPlainTextId = textPart.match(/\d+/)

  if (rssPlainTextId) {
    return map.get(parseInt(rssPlainTextId[0]))
  }
}

export const plainTextValueToJson = (text: string, message: EmailMessage, rssBlocks: MutableRefObject<Map<Number, PlainTextBlock>>) => {
  const displayConditionPattern = getDisplayConditionsRegExp(message, true)
  const conditions = getRowDisplayConditions(message, true).filter(filterNotEmptyArray)

  const displayConditionAndRssPattern = new RegExp(
    displayConditionPattern ? `${displayConditionPattern.source}|${RSS_PATTERN.source}` : `${RSS_PATTERN.source}`,
    'g'
  )

  if (!displayConditionAndRssPattern || !text.match(displayConditionAndRssPattern)) {
    return blocksToPlainTextJson([{ uuid: getUUID(), dynamicContent: undefined, text }])
  }

  const textParts = text.split(displayConditionAndRssPattern).filter(filterNotEmptyArray)
  const blocks: PlainTextBlock[] = []

  textParts.forEach((textPart) => {
    const dynamicContent = findDisplayConditionForTextBlock(textPart, conditions)
    const newText = dynamicContent ? stripDisplayConditionDelimiters(textPart, dynamicContent) : textPart
    const { current: map } = rssBlocks ?? {}

    if (textPart.match(RSS_PATTERN.source)) {
      const rssBlock = getRSSPlainTextBlock(textPart, map)

      if (rssBlock) {
        blocks.push({ ...rssBlock, text: rssBlock.text.replace(/--- RSS Block \d+ ---\n/g, '').trim() })
      }
    } else if (dynamicContent || (!dynamicContent && newText.trim() !== '')) {
      // Blank static rows are a side effect of the conversion process, so discard them
      // However, dynamic content can be blank and will fail validation in Review & Send
      blocks.push({ uuid: getUUID(), dynamicContent, text: newText })
    }
  })
  return blocksToPlainTextJson(blocks)
}

export const plainTextJsonToValue = (templateJson: IEntityContentJsonExtended, rssBlocks?: MutableRefObject<Map<Number, PlainTextBlock>>) => {
  const result = templateJson.page.rows
    .map((row) => {
      const module = row.columns[0].modules[0] as any

      if (isParagraphModule(module)) {
        const dynamicContent = row.container.displayCondition
        if (!dynamicContent) {
          return module.descriptor.paragraph.html
        }
        const content = stripDisplayConditionDelimiters(module.descriptor.paragraph.html, dynamicContent)
        return `${dynamicContent.before}\n${content}\n${dynamicContent.after}`
      } else if (module?.moduleInternal?.entity === 'RSS') {
        if (!rssBlocks) {
          return `${module.descriptor?.html.customFields.rssCustomFields.plainText}`
        } else {
          const plainTextId = module.descriptor?.html.customFields.rssCustomFields.plainTextId
          const text = `\n--- RSS Block ${plainTextId} ---\n${module.descriptor?.html.customFields.rssCustomFields.plainText}--- RSS Block ${plainTextId} ---\n`
          const rssData: PlainTextRSSResponse = {
            type: 'html',
            plainTextId,
            value: {
              ...module.descriptor.html,
            },
          }

          rssBlocks?.current.set(plainTextId, createRSSPlainTextBlock(rssData, plainTextId, text))

          return text
        }
      }
    })
    .filter(filterNotEmptyArray)
    .join(`\n`)
  return result
}

export const blocksToPlainTextJson = (blocks: PlainTextBlock[]): IEntityContentJsonExtended => {
  return {
    page: {
      template: {
        name: '',
        type: '',
        version: '',
      },
      body: {
        type: 'mailup-bee-page-properties',
        content: {
          style: {
            'font-family': '',
            color: '',
          },
          computedStyle: {
            align: '',
            linkColor: '',
            messageBackgroundColor: '',
            messageWidth: '',
          },
        },
        container: {
          style: {
            'background-color': '',
          },
        },
        webFonts: [],
      },
      description: '',
      title: '',
      rows: blocks.map((block): IPluginRow => {
        const uuid = block.uuid ?? getUUID()
        const isRSSBlock = !!block.rss

        return {
          content: {
            computedStyle: {
              hideContentOnAmp: false,
              hideContentOnDesktop: false,
              hideContentOnHtml: false,
              hideContentOnMobile: false,
            },
          },
          type: 'one-column-empty',
          columns: [
            {
              'grid-columns': 12,
              style: {},
              modules: [
                isRSSBlock
                  ? ({
                      type: 'mailup-bee-newsletter-modules-addon',
                      descriptor: {
                        html: {
                          html: block.text,
                          customFields: {
                            rssCustomFields: {
                              ...block.rss?.value.customFields?.rssCustomFields,
                              plainTextId: block.rss?.plainTextId,
                            },
                          },
                        },
                        type: 'html',
                        computedStyle: {
                          hideContentOnAmp: false,
                          hideContentOnDesktop: false,
                          hideContentOnHtml: false,
                          hideContentOnMobile: false,
                        },
                        style: {},
                      },
                      moduleInternal: {
                        uid: 'rssBlockHandler',
                        entity: 'RSS',
                        icon: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAyNiAyNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02LjMzMzMzIDIyLjMzMjdDNi4zMzMzMyAyMy44MDQ3IDUuMTM4NjcgMjQuOTk5MyAzLjY2NjY3IDI0Ljk5OTNDMi4xOTQ2NyAyNC45OTkzIDEgMjMuODA0NyAxIDIyLjMzMjdDMSAyMC44NjA3IDIuMTk0NjcgMTkuNjY2IDMuNjY2NjcgMTkuNjY2QzUuMTM4NjcgMTkuNjY2IDYuMzMzMzMgMjAuODYwNyA2LjMzMzMzIDIyLjMzMjdaIiBzdHJva2U9IiM0NDQ0NDQiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xIDlDOS44MzYgOSAxNyAxNi4xNjQgMTcgMjUiIHN0cm9rZT0iIzQ0NDQ0NCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTEgMUMxNC4yNTQ3IDEgMjUgMTEuNzQ1MyAyNSAyNSIgc3Ryb2tlPSIjNDQ0NDQ0IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K',
                        placeholder: 'Add your RSS feed here',
                        ctaLabel: 'Manage RSS content',
                        configurationUi: {
                          contentDialogId: 'rssBlockHandler',
                          external: null,
                        },
                      },
                      uuid: `${uuid}-module`,
                    } as any)
                  : {
                      type: 'mailup-bee-newsletter-modules-paragraph',
                      descriptor: {
                        computedStyle: {
                          hideContentOnAmp: false,
                          hideContentOnDesktop: false,
                          hideContentOnHtml: false,
                          hideContentOnMobile: false,
                        },
                        style: {},
                        paragraph: {
                          html: block.text,
                          computedStyle: {},
                          style: {},
                        },
                      },
                      uuid: `${uuid}-module`,
                    },
              ],
              uuid: `${uuid}-column`,
            },
          ],
          uuid,
          container: {
            displayCondition: block.dynamicContent,
            style: {},
          },
        }
      }),
    },
    comments: {},
  }
}

export const plainTextJsonToBlocks = (templateJson: IEntityContentJsonExtended): PlainTextBlock[] => {
  const rows = templateJson.page.rows
    .map((row) => {
      const module = row.columns[0].modules[0]
      if (isParagraphModule(module)) {
        return {
          uuid: row.uuid,
          text: module.descriptor.paragraph.html,
          dynamicContent: row.container.displayCondition,
        }
      }
    })
    .filter(filterNotEmptyArray)

  if (rows.length === 0) {
    return [{ uuid: getUUID(), text: '', dynamicContent: undefined }]
  }
  return rows
}

/** Get the newline chars required to have a single blank link before/after the text surrounding the cursor */
export const getNewlinesAroundInsertedText = (element: HTMLTextAreaElement | HTMLInputElement | null) => {
  let before = 2
  let after = 2

  if (!element) {
    return { before: '', after: '' }
  }
  const value = element.value
  const startPos = element.selectionStart ?? value.length - 1
  const endPos = element.selectionEnd ?? value.length - 1

  if (startPos === 0) {
    before = 0
  } else {
    if (value[startPos - 1] === '\n') {
      --before
    }
    if (value[startPos - 2] === '\n') {
      --before
    }
  }

  if (endPos === value.length - 1) {
    after = 0
  } else {
    if (value[endPos] === '\n') {
      --after
    }
    if (value[endPos + 1] === '\n') {
      --after
    }
  }
  return {
    before: '\n'.repeat(before),
    after: '\n'.repeat(after),
  }
}
