import React, { FC, useState } from 'react'

import { ApolloQueryResult, FetchResult } from '@apollo/client'
import { useTranslation } from '@const/globals'
import { MutationManageProfileArgs } from '@graphql/types/mutation-types'
import { OverviewResponse } from '@graphql/types/query-types'
import { StatusToastType } from '@interface/StatusToast'
import AccountDetails from '@src/pages/Profile/components/Overview/component/AccountDetails/AccountDetails'
import ManageProfile from '@src/pages/Profile/components/Overview/component/ManageProfile/ManageProfile'
import ProfileOverviewModal from '@src/pages/Profile/components/Overview/component/ProfileOverviewModal/ProfileOverviewModal'
import OverviewVM from '@src/pages/Profile/components/Overview/models/overview.view-model'
import { useAccountSettings } from '@utils/account/account.utils'

import { getProfileFormData } from './utils/overview.utils'

import './Overview.css'

interface OverviewProps {
  dataTest?: string
  loading: boolean
  overviewData?: OverviewVM
  onSave: (data: MutationManageProfileArgs) => Promise<FetchResult<{ manageProfile: boolean }>>
  setToastStatus: (value: StatusToastType) => void
  onRefresh: () => Promise<ApolloQueryResult<{ loadOverviewPage: OverviewResponse }>>
}

const rootClass = 'overview'

const Overview: FC<OverviewProps> = (props: OverviewProps) => {
  const { dataTest, overviewData, onSave, loading, setToastStatus, onRefresh } = props
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const accountSettings = useAccountSettings()

  let data: OverviewResponse | undefined

  if (overviewData) {
    data = { ...overviewData?.getData() }
  }

  const { t } = useTranslation()

  return (
    <>
      {!loading && overviewData && data && (
        <div data-test={dataTest} className={rootClass}>
          {modalIsOpen && (
            <ProfileOverviewModal
              data={getProfileFormData(data)}
              isOpen={modalIsOpen}
              onAction={(overviewManageProfile) =>
                onSave({ overviewManageProfile, id: accountSettings.userId }).then(() => {
                  setToastStatus({
                    showStatus: true,
                    title: t('Success!'),
                    statusMessage: t(`Your profile has been saved.`),
                    successStatus: true,
                  })
                  setModalIsOpen(false)
                  onRefresh()
                })
              }
              onClose={() => setModalIsOpen(false)}
            />
          )}
          <div className={`${rootClass}__section`}>
            <ManageProfile profileData={overviewData} onAction={() => setModalIsOpen(true)} />
          </div>
          <div className={`${rootClass}__right-content`}>
            <div className={`${rootClass}__section`}>
              <AccountDetails
                account={`${data.companyName} (${accountSettings.accountId})`}
                userLogin={data.login}
                userType={data.userType}
                isAdmin={accountSettings.isAccountAdmin}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Overview
