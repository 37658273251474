import React, { FC, useEffect, useState } from 'react'

import Button, { ButtonType } from '@components/Button'
import InputWithStatus from '@components/InputWithStatus/InputWithStatus'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { StatusToastType } from '@interface/StatusToast'
import getAddInputInfoText from '@src/pages/Settings/OtherSettings/CustomAccountSettings/utils/CustomAccountSettings.utils'
import { URLRegExp } from '@utils/formUtils'
import { getItem, setItem } from '@utils/sessionStorage'

import { ERROR_VALIDATION_MESSAGES } from '../constants/accountRedirects.constants'

import './AccountRedirectsInput.css'

interface Props {
  url: string | undefined
  urlKey: string
  setToastStatus: (value: StatusToastType) => void
  initialInputValue: string
  isEditing: boolean
  isFocused: boolean
  header: string
  moreInfoText: string
  moreInfoLink?: string
  dataTest?: string
  onSave: (url: string) => Promise<any>
  onFocus: () => void
  onBlur: () => void
  updateIsEditing: (isEditing: boolean) => void
  updateInputValue: (value: string) => void
}

const rootClass = 'account-redirects-input'

const AccountRedirectsInput: FC<Props> = (props: Props) => {
  const {
    url,
    urlKey = '',
    header,
    onSave,
    setToastStatus,
    moreInfoText,
    moreInfoLink,
    isEditing,
    initialInputValue,
    updateIsEditing,
    updateInputValue,
    isFocused,
    onFocus,
    onBlur,
    dataTest,
  } = props

  const [editing, setEditing] = useState<boolean>(isEditing)
  const [focused, setIsFocused] = useState<boolean>(isFocused)
  const [inputValue, setInputValue] = useState<string>(initialInputValue ?? '')
  const [urlValue, setUrlValue] = useState<string | null>(getItem(urlKey) || url || '')

  useEffect(() => {
    getItem(urlKey) || setItem(urlKey, url ?? '')
    setUrlValue(getItem(urlKey) || url || '')
  }, [url])

  const onEditClicked = () => {
    setEditing(true)
    setIsFocused(true)
    updateIsEditing(true)
  }

  const onSaveClicked = () => {
    onSave(inputValue)
      .then(() => {
        setEditing(false)
        updateIsEditing(false)
        setUrlValue(inputValue)
        setItem(urlKey, inputValue)
      })
      .catch(() => {
        setToastStatus({
          showStatus: true,
          statusMessage: t('Something went wrong on our end. The URL could not be saved.'),
          successStatus: false,
        })
      })
  }

  const onCancel = () => {
    setEditing(false)
    updateIsEditing(false)
    setInputValue(getItem(urlKey) ?? '')
    updateInputValue(getItem(urlKey) ?? '')
  }

  const onUrlChanges = (url: string) => {
    setInputValue(url)
    updateInputValue(url)
  }

  const checkUrlValidity = (value: string) => {
    if (!URLRegExp.test(value)) {
      return { errorMessageKey: 'invalidUrl' }
    }
  }

  const { t } = useTranslation()
  return (
    <div className={`${rootClass}`}>
      <div className={`${rootClass}__header`}>
        <Typography text={header} type={TextType.SECTION_HEADER} dataTest={`${dataTest}__header`} />
        {!editing && (
          <Button
            buttonType={ButtonType.FLOAT_TEAL}
            onClick={onEditClicked}
            className={`${rootClass}__edit-button`}
            dataTest={`${dataTest}__edit-button`}
          >
            {t(`Edit`)}
          </Button>
        )}
        {editing && (
          <div className={`${rootClass}__header-status`}>
            <>
              <Svg name={SvgNames.pencil} type={SvgType.LIGHT_SMALLER_ICON} />
              <Typography text={t(`Editing`)} type={TextType.BODY_TEXT_LIGHT} lineHeight={LineHeight.MEDIUM_LARGE} />
            </>
          </div>
        )}
      </div>
      <div className={`${rootClass}__content`}>
        <div className={`${rootClass}__link`}>
          <Typography text={moreInfoText} type={TextType.BODY_TEXT} lineHeight={LineHeight.VERY_TINY} inline />
          &nbsp;
          {!!moreInfoLink && (
            <TextLink
              className={`${rootClass}__header-info-link`}
              dataTest={`${dataTest}__header-info-link`}
              text={t('More info')}
              link={moreInfoLink}
              size={TextLinkSize.LARGE}
            />
          )}
        </div>
        {!urlValue && !editing && <Typography text={t(`Not Set`)} type={TextType.BODY_TEXT_LIGHT} />}
        {urlValue && !editing && (
          <div className={`${rootClass}__url`}>
            <Typography className={'ellip'} text={urlValue} type={TextType.BODY_TEXT_LIGHT} dataTest={`${dataTest}__url`} />
            <TextLink text={''} link={urlValue} size={TextLinkSize.LARGE} />
          </div>
        )}
        {editing && (
          <>
            <InputWithStatus
              className={`${rootClass}__input`}
              /* eslint-disable-next-line jsx-a11y/no-autofocus */
              autoFocus={focused}
              required
              dataTest={`${dataTest}__input`}
              value={inputValue}
              validityFunctions={[checkUrlValidity]}
              tooltipErrorMessages={ERROR_VALIDATION_MESSAGES}
              onChange={(event) => onUrlChanges(event.target.value)}
              onBlur={() => {
                onBlur()
                setIsFocused(false)
              }}
              onFocus={() => {
                onFocus()
                setIsFocused(true)
              }}
            />
            {getAddInputInfoText(t, rootClass, false)}
          </>
        )}
      </div>
      {editing && (
        <div className={`${rootClass}__actions`}>
          <Button
            buttonType={ButtonType.PRIMARY}
            onClick={onSaveClicked}
            dataTest={`${dataTest}__button-save`}
            disabled={!inputValue || !!checkUrlValidity(inputValue) || inputValue === urlValue}
          >
            {t('Save')}
          </Button>
          <Button buttonType={ButtonType.TEXT} onClick={onCancel} dataTest={`${dataTest}__button-cancel`}>
            {t('Cancel')}
          </Button>
        </div>
      )}
    </div>
  )
}

export default AccountRedirectsInput
