import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import {
  getCustomTableDataBehaviorOptionsMock,
  getCustomTableDataOptionsMock,
  getCustomTableOptionsMock,
} from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/CustomDataExpression/CustomDataExpression.mocks'
import DateCriteria, { DateCriteriaType } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/DateCriteria/DateCriteria'
import DateTimeframes from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/DateTimeframes/DateTimeframes'
import { IgnoreYearPopoverProps } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/IgnoreYearPopover/IgnoreYearPopover'
import NumericCriteria, {
  NumericCriteriaType,
} from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/NumericCriteria/NumericCriteria'
import TextCriteria, { TextCriteriaType } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/TextCriteria/TextCriteria'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'

import './CustomDataExpression.css'

interface CustomDataExpressionProps {
  className?: string
  dataTest?: string
}

interface CustomDataExpressionState {
  customTable: SelectV2SingleOption
  customTableData?: SelectV2SingleOption
  customTableDataOptions: SelectV2SingleOption[]
  numericCriteria: NumericCriteriaType
  behaviorNumericCriteria: NumericCriteriaType
  textCriteria: TextCriteriaType
  dateCriteria: DateCriteriaType
  ignoreYear: boolean
}

const rootClass = 'custom-data-expression'
const defaultCustomTableIndex = 0
const defaultCustomTableDataIndex = 0

const CustomDataExpression: FC<CustomDataExpressionProps> = (props: CustomDataExpressionProps) => {
  const { dataTest = rootClass, className = '' } = props

  // TODO: get the right options from the backend
  const customTableOptions = getCustomTableOptionsMock()

  const [state, setState] = useState<CustomDataExpressionState>({
    customTable: customTableOptions[defaultCustomTableIndex],
    customTableData: customTableOptions[defaultCustomTableIndex],
    customTableDataOptions: [],
    numericCriteria: {},
    behaviorNumericCriteria: {},
    textCriteria: {
      values: [],
    },
    dateCriteria: {},
    ignoreYear: false,
  })
  const { customTable, numericCriteria, behaviorNumericCriteria, customTableData, textCriteria, dateCriteria, ignoreYear, customTableDataOptions } =
    state
  const { values } = textCriteria

  const update = useDeepUpdate(setState)

  const ignoreYearProps: IgnoreYearPopoverProps = {
    ignoreYear,
    onIgnoreYearChange: (ignoreYear) => update({ ignoreYear }),
  }

  const onValuesCreate = (inputValue: string) => {
    if (!values.find((option) => option.label === inputValue)) {
      update({ textCriteria: { ...textCriteria, values: [...values, { label: inputValue, value: inputValue }] } })
    }
  }

  const setCustomTableDataOptions = () => {
    // TODO: get the right options from the backend
    const customTableDataOptions =
      customTable.extraOptions?.type === 'BEHAVIOR' ? getCustomTableDataBehaviorOptionsMock() : getCustomTableDataOptionsMock()
    update({
      customTableDataOptions,
      customTableData: customTableDataOptions[defaultCustomTableDataIndex],
    })
  }

  useEffect(() => {
    setCustomTableDataOptions()
  }, [customTable])

  const renderNextCondition = () => {
    const type = customTableData?.extraOptions?.type
    switch (type) {
      case 'TEXT':
        return <TextCriteria onChange={(textCriteria) => update({ textCriteria })} onCreate={onValuesCreate} textCriteria={textCriteria} />
      case 'NUMERIC':
        return <NumericCriteria onChange={(numericCriteria) => update({ numericCriteria })} numericCriteria={numericCriteria || {}} />
      case 'ACTIVITY_DATE':
        return <DateTimeframes isBehavior />
      case 'DATE':
        return (
          <DateCriteria dateCriteria={dateCriteria || {}} onChange={(dateCriteria) => update({ dateCriteria })} ignoreYearProps={ignoreYearProps} />
        )
      default:
        return null
    }
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <SelectV2
        dataTest={`${dataTest}-table-select`}
        className={`${rootClass}__table-select`}
        options={customTableOptions}
        defaultValue={customTable}
        onChange={(customTable?: SelectV2SingleOption) => update({ customTable })}
        isClearable={false}
        isSearchable={false}
      />
      {customTable.extraOptions?.type === 'BEHAVIOR' && (
        <NumericCriteria onChange={(behaviorNumericCriteria) => update({ behaviorNumericCriteria })} numericCriteria={behaviorNumericCriteria} />
      )}
      <SelectV2
        dataTest={`${dataTest}-data-select`}
        className={`${rootClass}__data-select`}
        options={customTableDataOptions}
        value={customTableData}
        onChange={(customTableData?: SelectV2SingleOption) => update({ customTableData })}
        isClearable={false}
        isSearchable={false}
      />
      {renderNextCondition()}
    </div>
  )
}

export default CustomDataExpression
