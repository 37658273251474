import { RefObject, createContext } from 'react'

import { TableV2RowData } from '@components/TableV2/tableV2TS/interfaces'
import { FormManagerContextValues } from '@src/pages/Content/Forms/FormManager/context/FormManagerContext.values'

export interface FormManagerSubmissionDetails {
  id: string
  name: string
  type: 'segment' | 'list'
  contactCount: number
  sentToAOC?: boolean
  segmentId?: string
  segmentName?: string
}

export interface FormManagerCappedSignUps {
  enabled: boolean
  current: number
  total: number
}

export interface FormManagerLandingPage extends TableV2RowData<FormManagerLandingPage> {
  id: string
  title: string
  isBee: boolean
}

export interface FormManagerEmbed extends TableV2RowData<FormManagerEmbed> {
  id: string
  formId: string
  deploymentId: string
  name: string
  externalURL: string
  sourceHTML: string
  noCssHtml: string
  prefilledHtml: string
  noCssAndPrefilledHtml: string
}

export interface FormManagerPerformanceReportSource {
  name: string
  views: number
  submits: number
  uniqueSubmits: number
}

export interface FormManagerPerformanceReportActivity {
  period: string
  views: number
  submits: number
}

export interface FormManagerPerformanceReport {
  start: number
  end: number
  totalViews: number
  totalSubmits: number
  lastSubmit: number
  ipFiltering: boolean
  sources: FormManagerPerformanceReportSource
  activity: FormManagerPerformanceReportActivity[]
}

export interface FormManagerResponsesReportFieldOption {
  label: string
  responses: number
}

export interface FormManagerResponsesReportField {
  label: string
  responses: number
  options: FormManagerResponsesReportFieldOption
}

export interface FormManagerResponsesReport {
  fields: FormManagerResponsesReportField[]
}

export interface FormManagerModals {
  clearPerformanceReport: boolean
  clearPerformanceReportSuccess: boolean
  clearPerformanceReportError: boolean
  embedCode: boolean
  crmPushErrors: boolean
  ipFiltering: boolean
  deleteEmbedCode: boolean
  editEmbedCode: boolean
  createEmbedCode: boolean
  pushErrorsErr: boolean
  deleteEmbedCodeSuccess: boolean
  deleteEmbedCodeError: boolean
  editEmbedCodeSuccess: boolean
  editEmbedCodeError: boolean
  createEmbedCodeSuccess: boolean
  createEmbedCodeError: boolean
  downloadOptions: boolean
}

export const DefaultFormManagerModals: FormManagerModals = {
  clearPerformanceReport: false,
  clearPerformanceReportSuccess: false,
  clearPerformanceReportError: false,
  embedCode: false,
  crmPushErrors: false,
  ipFiltering: false,
  deleteEmbedCode: false,
  editEmbedCode: false,
  createEmbedCode: false,
  pushErrorsErr: false,
  deleteEmbedCodeSuccess: false,
  deleteEmbedCodeError: false,
  editEmbedCodeSuccess: false,
  editEmbedCodeError: false,
  createEmbedCodeSuccess: false,
  createEmbedCodeError: false,
  downloadOptions: false,
}

export interface FormManagerRow {
  id: string
  name: string
}

export interface FormManagerValues {
  formId: string
  classicFormsEnabled: boolean
  title: string
  description: string
  isClassic: boolean
  expirationDate?: number
  connectedCrm?: string
  connectedWebinar?: string
  submissionDetails: FormManagerSubmissionDetails
  cappedSignups: FormManagerCappedSignUps
  landingPages: FormManagerLandingPage[]
  embeds: FormManagerEmbed[]
  crmPushErrorsCount: number
  payments?: boolean
  performanceReport: FormManagerPerformanceReport
  responsesReport: FormManagerResponsesReport
  createdAt: number
  createdBy: string
  updatedAt: number
  lastUpdatedBy: string
  canEdit: boolean
  row?: FormManagerRow
  modals: FormManagerModals
  tab: string
  subTab: string
  loading: boolean
  formNotFound?: boolean
  reportIFrameRef: RefObject<HTMLIFrameElement> | null
  builderWindow?: Window
}

export interface FormManagerAPI {
  values: FormManagerValues
  setLoading: (loading: boolean) => void
  tabChange: (tab: string, subtab?: string) => void
  subTabChange: (subTab: string) => void
  setCurrentRow: (id?: string, name?: string) => void
  toggleModal: (modalName: keyof FormManagerModals) => void
  downloadSubmittedData: (isZip?: boolean) => void
  clearPerformanceReport: VoidFunction
  getFormCRMPushErrors: () => void
  changeIPFiltering: VoidFunction
  deleteEmbedCode: (embedCodeId: string) => void
  createEditEmbedCode: (name: string, externalUrl: string, embedCodeId?: string) => void
  onOpenBuilderWindow: (builderWindow: Window) => void
}

export const FormManagerContext = createContext<FormManagerAPI>({ values: FormManagerContextValues.values } as any)
