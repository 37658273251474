import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import { LinkTextButton, LinkTextButtonSize } from '@components/LinkTextButton/LinkTextButton'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './SegmentActivityDetailsSection.css'

interface Props {
  className?: string
  dataTest?: string
  children: ReactNode
  action?: Function
  actionText?: string
  title: string
}

const rootClass = 'segment-activity-details-section'

const SegmentActivityDetailsSection: FC<Props> = (props: Props) => {
  const { children, action, actionText, title, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__title`}>
        <Typography className={`${rootClass}__title-text`} text={t(title)} weight={TextWeight.BOLD} />
        {action && actionText && <LinkTextButton text={t(actionText)} hideIcons size={LinkTextButtonSize.LARGE} onClick={() => action()} />}
      </div>
      {children}
    </div>
  )
}

export default SegmentActivityDetailsSection
