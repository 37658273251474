import React, { FC } from 'react'

import ListingPageWithTabs from '@complex/ListingPageWithTabs/ListingPageWithTabs'
import { rootContext, useTranslation } from '@const/globals'
import AccountManagementListingPageContainer from '@src/pages/listingPages/AccountManagement/AccountManagementListingPageContainer'
import ManagersContainer from '@src/pages/MarketingNetwork/Managers/ManagersContainer'
import { useAccountSettings } from '@utils/account/account.utils'

import './AccountManagementTabbedContainer.css'

interface AccountManagementTabbedListingPageContainer {
  className?: string
  dataTest?: string
}

const AccountManagementTabbedListingPageContainer: FC<AccountManagementTabbedListingPageContainer> = (
  props: AccountManagementTabbedListingPageContainer
) => {
  const { dataTest = `AccountManagementTabbedListingPageContainer` } = props
  const { t } = useTranslation()

  // NOTE: the specific prop used in the next line may change after
  //  https://act-on.atlassian.net/browse/AO-80205 is completed
  const { isAccountAdmin } = useAccountSettings()

  const tabProps = [
    {
      label: t('Accounts'),
      component: <AccountManagementListingPageContainer isAccountAdmin />,
      pathName: `${rootContext}/marketingNetwork/accountManagement`,
      pathnameId: 'accountManagement',
    },
    {
      label: t('Managers'),
      component: <ManagersContainer />,
      pathName: `${rootContext}/marketingNetwork/managers`,
      pathnameId: 'managers',
    },
  ]

  return isAccountAdmin ? (
    <ListingPageWithTabs tabProps={tabProps} dataTest={dataTest} className={`account-management-tabbed-container`} />
  ) : (
    <AccountManagementListingPageContainer isAccountAdmin={false} />
  )
}

export default AccountManagementTabbedListingPageContainer
