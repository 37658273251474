import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Typography, { TextType, TextWeight } from '@components/Typography/Typography'

export const ValidFormatsSubheader: FC<{ dataTest?: string; accept?: string }> = ({ dataTest, accept = '.jpg, .jpeg, .gif, .png' }) => {
  const { t } = useTranslation()
  const formattedTypes = accept
    .split(', ')
    .map((type) => type.split('.')[1])
    .join(', ')
  return (
    <Typography
      text={t('Insert.Image.Modal.ValidFormats.SubHeader', { types: formattedTypes })}
      type={TextType.BODY_TEXT_LIGHT}
      tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
      dataTest={dataTest}
    />
  )
}
