import clearTestData from '@graphql/microservices/webhooks-incoming-management/clearTestData'
import deleteWebhookDefinition from '@graphql/microservices/webhooks-incoming-management/deleteWebhookDefinition'
import getSignatureTemplateList from '@graphql/microservices/webhooks-incoming-management/getSignatureTemplateList'
import getWebhookDefinitionList from '@graphql/microservices/webhooks-incoming-management/getWebhookDefinitionList'
import getWebhooksHistoricalData from '@graphql/microservices/webhooks-incoming-management/getWebhooksHistoricalData'
import hasTestData from '@graphql/microservices/webhooks-incoming-management/hasTestData'
import saveWebhookDefinition from '@graphql/microservices/webhooks-incoming-management/saveWebhookDefinition'
import setWebhookEnabledStatus from '@graphql/microservices/webhooks-incoming-management/setWebhookEnabledStatus'
import setWebhookTestedStatus from '@graphql/microservices/webhooks-incoming-management/setWebhookTestedStatus'
import {
  ClearTestDataMutation,
  DeleteWebhookDefinitionMutation,
  DeleteWebhookDefinitionMutationVariables,
  GetSignatureTemplateListQuery,
  GetSignatureTemplateListQueryVariables,
  GetWebhookDefinitionListQuery,
  GetWebhookDefinitionListQueryVariables,
  GetWebhooksHistoricalDataQuery,
  GetWebhooksHistoricalDataQueryVariables,
  HasTestDataQuery,
  HasTestDataQueryVariables,
  InputDefinition,
  SaveWebhookDefinitionMutation,
  SaveWebhookDefinitionMutationVariables,
  SetWebhookEnabledStatusMutation,
  SetWebhookEnabledStatusMutationVariables,
  SetWebhookTestedStatusMutation,
  SetWebhookTestedStatusMutationVariables,
} from '@graphql/types/microservice/webhooks-incoming-management-types'
import { IncomingWebhook } from '@src/pages/Webhooks/IncomingWebhooks/IncomingWebhooksContext'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export interface IncomingWebhooksRequests {
  getWebhooksActivityData: () => FetchPromise<GetWebhooksHistoricalDataQuery>
  getWebhooksList: () => Promise<IncomingWebhook[] | undefined>
  createWebhook: (webhook: InputDefinition) => FetchPromise<SaveWebhookDefinitionMutation>
  deleteWebhook: (webhookId: string) => FetchPromise<DeleteWebhookDefinitionMutation>
  updateWebhookEnabledStatus: (webhookId: string, enabled: boolean) => FetchPromise<SetWebhookEnabledStatusMutation>
  updateWebhookTestedStatus: (webhookId: string, tested: boolean) => FetchPromise<SetWebhookTestedStatusMutation>
  getTestData: (webhookId: string) => FetchPromise<HasTestDataQuery>
  removeTestData: (webhookId: string) => FetchPromise<ClearTestDataMutation>
  getSignatureList: () => FetchPromise<GetSignatureTemplateListQuery>
}

export const useIncomingWebhooksRequests = (): IncomingWebhooksRequests => {
  const { client: incomingWebhooksClient } = useMicroserviceClient({ serviceName: MicroserviceClients.WEBHOOKS_INCOMING_MANAGEMENT })

  const getWebhooksList = () => {
    return incomingWebhooksClient
      .query<GetWebhookDefinitionListQuery, GetWebhookDefinitionListQueryVariables>({
        query: getWebhookDefinitionList,
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        return data?.getWebhookDefinitionList?.reduce((webhooks: IncomingWebhook[], webhook) => {
          if (webhook) {
            return [
              ...webhooks,
              {
                id: webhook.webhookId ?? 0,
                name: webhook.name,
                description: webhook.description,
                enabled: webhook.enabled,
                tested: webhook.tested,
                url: webhook.url,
                source: webhook.source,
                lastRequest: webhook.lastRequestTimestamp ? Number(webhook.lastRequestTimestamp) : 0,
                authentication: webhook.authentication,
                channel: webhook.channel,
                contentMappings: webhook.contentMappings,
              } as IncomingWebhook,
            ]
          }
          return webhooks
        }, [])
      })
  }

  const createWebhook = (webhook: InputDefinition) => {
    return incomingWebhooksClient.mutate<SaveWebhookDefinitionMutation, SaveWebhookDefinitionMutationVariables>({
      mutation: saveWebhookDefinition,
      variables: { definition: webhook },
    })
  }

  const deleteWebhook = (webhookId: string) => {
    return incomingWebhooksClient.mutate<DeleteWebhookDefinitionMutation, DeleteWebhookDefinitionMutationVariables>({
      mutation: deleteWebhookDefinition,
      variables: { webhookId },
    })
  }

  const updateWebhookEnabledStatus = (webhookId: string, enabled: boolean) => {
    return incomingWebhooksClient.mutate<SetWebhookEnabledStatusMutation, SetWebhookEnabledStatusMutationVariables>({
      mutation: setWebhookEnabledStatus,
      variables: { webhookId, enabled },
    })
  }

  const updateWebhookTestedStatus = (webhookId: string, tested: boolean) => {
    return incomingWebhooksClient.mutate<SetWebhookTestedStatusMutation, SetWebhookTestedStatusMutationVariables>({
      mutation: setWebhookTestedStatus,
      variables: { webhookId, tested },
    })
  }

  const getTestData = (webhookId: string) => {
    return incomingWebhooksClient.query<HasTestDataQuery, HasTestDataQueryVariables>({
      query: hasTestData,
      fetchPolicy: 'network-only',
      variables: {
        webhookId,
      },
    })
  }

  const removeTestData = (webhookId: string) => {
    return incomingWebhooksClient.mutate({
      mutation: clearTestData,
      variables: {
        webhookId,
      },
    })
  }

  const getSignatureList = () =>
    incomingWebhooksClient.query<GetSignatureTemplateListQuery, GetSignatureTemplateListQueryVariables>({
      query: getSignatureTemplateList,
      fetchPolicy: 'network-only',
    })

  const getWebhooksActivityData = () =>
    incomingWebhooksClient.query<GetWebhooksHistoricalDataQuery, GetWebhooksHistoricalDataQueryVariables>({
      query: getWebhooksHistoricalData,
      fetchPolicy: 'network-only',
    })

  return {
    getWebhooksActivityData,
    getWebhooksList,
    createWebhook,
    deleteWebhook,
    updateWebhookEnabledStatus,
    updateWebhookTestedStatus,
    getTestData,
    removeTestData,
    getSignatureList,
  }
}
