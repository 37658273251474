import { AssetPickerCommonState, AssetPickerTableProps } from '@complex/AssetPickerModal/Context/AssetPicker.context'
import {
  CustomSourceItems,
  ExtendedItemDto,
  ItemDtoRow,
  ListPageCommonState,
  SetFilter,
} from '@complex/ListingPage/Context/ListingPageCommon.context'
import { EmptyListingProps, EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { Row, RowSelectionState } from '@tanstack/react-table'
import { Row as RowV2 } from '@tanstack/table-core/build/lib/types'
import {
  createdByMeFilter,
  favoriteFilter,
  recentFilter,
  recommendedFilter,
  suggestedFormsFilter,
  suggestedAutomatedProgramsFilter,
} from '@utils/filter'
import { UpdateState } from '@utils/types'

export const getItemName = (id: string, items: ItemDto[], searchItemsResults: ExtendedItemDto[], selectedViewItems: ItemDto[]) => {
  const item = [...items, ...searchItemsResults, ...selectedViewItems].find((item) => item.id === id)
  return item ? (item as any).name : ''
}

export const alertSelectedItems = (items: ItemDto[]) => alert(`${JSON.stringify(items)}`)

export const alertSelectedCustomSourceItems = (items: CustomSourceItems) => alert(`${JSON.stringify(items)}`)

export const onRowSelectionChangeInAllListsUtils = (
  state: AssetPickerCommonState,
  update: UpdateState<AssetPickerCommonState>,
  selectedRowIds: string[],
  rows: Row<ItemDtoRow>[]
) => {
  const {
    selectedViewItems,
    isSingleSelect,
    listingPageState: { search, items, searchItemsResults },
  } = state

  if (isSingleSelect) {
    const row = rows.find((row) => row.id === selectedRowIds[0])
    if (row) {
      update({ selectedViewItems: [row.original] })
    }
  } else {
    const loadedItems = search ? searchItemsResults : items
    const updatedSelectedRow: Row<ItemDtoRow>[] = rows.filter((row) => selectedRowIds.includes(row.id))
    const itemIds = loadedItems.map((item) => item.id)
    const updatedSelectedRowIds: string[] = updatedSelectedRow.map((row) => row.original.id)
    const updatedSelectedItems = loadedItems.filter((item) => updatedSelectedRowIds.includes(item.id))
    const removedDuplicates = selectedViewItems.filter((item) => !itemIds.includes(item.id))
    const updatedSelectedViewItems = [...removedDuplicates, ...updatedSelectedItems]
    update({ selectedViewItems: updatedSelectedViewItems })
  }
}

export const onCustomSourceRowSelectionChangeInAllListsUtils = (
  state: AssetPickerCommonState,
  update: UpdateState<AssetPickerCommonState>,
  selectedRowIds: string[],
  rows: Row<ItemDtoRow>[],
  rowDisabled?: (row: RowV2<any>) => boolean
) => {
  const {
    customSourceSelectedViewItems,
    isSingleSelect,
    isViewingSelected,
    listingPageState: { items, selectedCustomSource, loading, search, searchItemsResults },
  } = state
  const label = selectedCustomSource?.label as string
  if (isSingleSelect) {
    const row = rows.find((row) => row.id === selectedRowIds[0])
    if (row) {
      update({ selectedViewItems: [row.original], customSourceSelectedViewItems: { [label]: [row.original] } })
    }
  } else if (!loading) {
    const selectedItems = (customSourceSelectedViewItems ?? {})[label]
    const loadedItems = isViewingSelected ? selectedItems : search ? searchItemsResults : items
    const rowsIds = rows?.map(({ original }) => (original as any).id) ?? []

    const keepItems = selectedItems?.filter(({ id }) => !rowsIds?.includes(id)) ?? []
    if (!!loadedItems.length) {
      const updatedSelectedRow: Row<ItemDtoRow>[] = rows.filter((row) => (rowDisabled ? !rowDisabled(row) : true) && selectedRowIds.includes(row.id))
      const updatedSelectedRowIds: string[] = updatedSelectedRow.map((row) => row.original.id)
      const updatedSelectedItems = loadedItems.filter((item) => updatedSelectedRowIds.includes(item.id))

      const removedUnSelected = selectedItems?.filter(({ id }) => selectedRowIds.includes(id)) ?? []
      const removedDuplicates = removedUnSelected.filter((item) => !updatedSelectedRowIds.includes(item.id) && updatedSelectedRowIds.length !== 0)
      const updatedSelectedViewItems = [...keepItems, ...removedDuplicates, ...updatedSelectedItems]
      const customSourceSelectedViewItemList = { ...customSourceSelectedViewItems, [label]: updatedSelectedViewItems }
      const selectedViewItems = Object.values(customSourceSelectedViewItemList).flat()
      update({ selectedViewItems, customSourceSelectedViewItems: customSourceSelectedViewItemList })
    }
  }
}

export const onRowSelectionChangeInView = (viewItems: ItemDto[], update: Function, rowIds: string[], rows: Row<ItemDtoRow>[]) => {
  const filteredIds = rows.filter((row) => rowIds.includes(row.id)).map((item) => item.original.id)
  const filtered = viewItems.filter((item) => filteredIds.includes(item.id))
  update({ selectedViewItems: filtered })
}

export const onCustomSourceRowSelectionChangeInView = (
  customSourceSelectedViewItems: CustomSourceItems,
  source: string,
  viewItems: ItemDto[],
  update: Function,
  rowIds: string[],
  rows: Row<ItemDtoRow>[]
) => {
  const filteredIds = rows.filter((row) => rowIds.includes(row.id)).map((item) => item.original.id)
  const filtered = viewItems.filter((item) => filteredIds.includes(item.id))
  const customSourceSelectedViewItemList = { ...customSourceSelectedViewItems, [source]: filtered }
  const selectedViewItems = Object.values(customSourceSelectedViewItemList).flat()
  update({ selectedViewItems, customSourceSelectedViewItems: customSourceSelectedViewItemList })
}

export const getViewRowSelectionState = (rowCount: number): RowSelectionState => {
  const selectionState: RowSelectionState = {}
  for (let j = 0; j < rowCount; j++) {
    selectionState[j] = true
  }

  return selectionState
}

export const getRowSelectionState = (items: ItemDto[], selectedItems: ItemDto[], isSingleSelect: boolean | undefined): RowSelectionState => {
  const selectionState: RowSelectionState = {}
  for (let j = 0; j < selectedItems?.length; j++) {
    const id = selectedItems[j].id
    const index = items.findIndex((item) => item.id === id)
    if (index !== -1) {
      if (isSingleSelect) {
        return { [index]: true }
      }
      selectionState[index] = true
    }
  }
  return selectionState
}

export const getDisableSourceRowsProps = (disableRows: boolean): Partial<AssetPickerTableProps> => {
  return {
    rowDisabled: () => disableRows,
    rowDisabledTitle: disableRows ? 'You can select items only from one source.' : '',
    headerCheckboxDisabled: disableRows,
  }
}

export const updateCustomSelectedViewItems = (updatePicker: UpdateState<AssetPickerCommonState>, customSourceItems: CustomSourceItems) => {
  updatePicker({
    customSourceSelectedViewItems: customSourceItems,
    selectedViewItems: Object.values(customSourceItems ?? {}).flat(),
    loadingPreSelectedItems: false,
  })
}

export const getUnloadedItemIds = (loadedItemIds: string[], preSelectedRowIds: string[]) =>
  preSelectedRowIds.filter((itemId) => !loadedItemIds.includes(itemId))

export const getCustomDefaultEmptyListingProps = (values: ListPageCommonState, setFilter: SetFilter, t: Function): EmptyListingProps | undefined => {
  const {
    activeTagId,
    activeFolderId,
    search,
    searchItemsResults,
    filterActive,
    folderPath,
    tags,
    activeSubTypes,
    defaultSubTypes,
    listingPageProps: {
      sidebarProps: { allItemFilter },
      tableProps: { listPage },
    },
  } = values
  const activeFolderName = folderPath.find((folder) => folder.id === activeFolderId)?.name
  const activeTagName = tags.find((tag) => tag.id === activeTagId)?.name

  if (activeSubTypes.filter((subType) => !defaultSubTypes.includes(subType)).length > 0) {
    return {
      imgSrc: StaticImageNames.emptyFilter,
      headline: `AssetPicker.Common.Table.EmptyListing.SubTypes.Headline`,
      text: `AssetPicker.${listPage}.Table.EmptyListing.SubTypes.Text`,
      size: EmptyListingSize.LARGE,
      buttonText: `AssetPicker.${listPage}.Table.EmptyListing.SubTypes.ButtonText`,
      buttonOnClick: () => setFilter(allItemFilter, false, true),
      withoutBorder: true,
      fullHeight: true,
    }
  } else if (search && searchItemsResults.length === 0) {
    return {
      imgSrc: StaticImageNames.emptySearch,
      headline: t('No results found'),
      text: t('There were no results matching your search.'),
      size: EmptyListingSize.LARGE,
      withoutBorder: true,
      fullHeight: true,
    }
  } else if (activeTagId) {
    return {
      imgSrc: StaticImageNames.emptyTag,
      headline: t(`You haven’t used this tag yet`),
      text: t(`Nothing has been tagged with <bold>{{activeTagName}}</bold>`, { activeTagName }),
      size: EmptyListingSize.LARGE,
      withoutBorder: true,
      fullHeight: true,
      tagProps: { inline: true },
    }
  } else if (activeFolderId) {
    return {
      imgSrc: StaticImageNames.emptyFolder,
      headline: t('This folder is empty'),
      text: t(`You haven’t stored anything in <bold>{{activeFolderName}}</bold>`, { activeFolderName }),
      size: EmptyListingSize.LARGE,
      withoutBorder: true,
      fullHeight: true,
      tagProps: { inline: true },
    }
  }

  switch (filterActive) {
    case favoriteFilter: {
      return {
        imgSrc: StaticImageNames.emptyFavorites,
        headline: t('You don’t have any favorites yet'),
        text: t('Add hearts to items that you want to easily find later.'),
        size: EmptyListingSize.LARGE,
        withoutBorder: true,
        fullHeight: true,
      }
    }
    case createdByMeFilter: {
      return {
        imgSrc: StaticImageNames.emptyCreatedByMe,
        headline: t('You haven’t created any of these yet'),
        text: t('You’ll find items that you created here.'),
        size: EmptyListingSize.LARGE,
        withoutBorder: true,
        fullHeight: true,
      }
    }
    case recentFilter: {
      return {
        imgSrc: StaticImageNames.emptyRecent,
        headline: t('You haven’t used these recently'),
        text: t('You’ll find items that you used recently here.'),
        size: EmptyListingSize.LARGE,
        withoutBorder: true,
        fullHeight: true,
      }
    }
    case recommendedFilter: {
      return {
        imgSrc: StaticImageNames.emptyContacts,
        headline: t('We don’t have any recommended sources for you yet'),
        text: t('Recommended sources are based on previous or current CRM syncs.'),
        size: EmptyListingSize.LARGE,
        withoutBorder: true,
        fullHeight: true,
      }
    }
    case suggestedFormsFilter: {
      return {
        imgSrc: StaticImageNames.emptyChart,
        headline: t('AssetPicker.SuggestedForms.EmptyListing.Headline'),
        text: t('AssetPicker.SuggestedForms.EmptyListing.Text'),
        size: EmptyListingSize.LARGE,
        withoutBorder: true,
        fullHeight: true,
      }
    }
    case suggestedAutomatedProgramsFilter: {
      return {
        imgSrc: StaticImageNames.emptyChart,
        headline: t('AssetPicker.SuggestedAutomatedPrograms.EmptyListing.Headline'),
        text: t('AssetPicker.SuggestedAutomatedPrograms.EmptyListing.Text'),
        size: EmptyListingSize.LARGE,
        withoutBorder: true,
        fullHeight: true,
      }
    }
    default: {
      return {
        imgSrc: StaticImageNames.emptyChart,
        headline: t('You haven’t created any of these yet'),
        text: t(`AssetPicker.${listPage}.Table.EmptyListing.Text`),
        size: EmptyListingSize.LARGE,
        withoutBorder: true,
        fullHeight: true,
      }
    }
  }
}
