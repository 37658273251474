import { TFunction } from 'i18next'

import { SelectV2GroupedOption, SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { EmailSender } from '@utils/composer/context/EmailComposer.context'
import {
  PERSONALIZED_FROM_ADDRESS_OPTION_VALUE,
  SALESFORCE_OWNER_OPTION_VALUE,
  SAME_AS_SENDER_OPTION_VALUE,
} from '@utils/composer/settings.constants'

export type SettingsFormSenderOptions = {
  Sender: SelectV2GroupedOption[]
  Fallback: SelectV2GroupedOption[]
  ReplyTo: SelectV2GroupedOption[]
}

export const findSenderOption = (groupOptions: SelectV2GroupedOption[], senderId?: string): SelectV2SingleOption | undefined => {
  if (!senderId) {
    return undefined
  }
  for (const { options } of groupOptions) {
    const option = options.find(({ value }) => value === senderId)
    if (option) {
      return option
    }
  }
}

export const getSenderOption = (sender: EmailSender): SelectV2SingleOption => ({
  label: sender.name,
  value: sender.uuid,
  subText: sender.email,
})

export const getSenderOptions = (
  emailSenders: EmailSender[],
  loggedInUser: SelectV2SingleOption,
  salesforceConnected: boolean,
  hasPersonalizedFrom: boolean,
  t: TFunction
): SettingsFormSenderOptions => {
  const DYNAMIC_SENDERS = t('EmailComposer.Settings.Sender.Dynamic')
  const LOGGED_IN_USER = t('EmailComposer.Settings.Sender.LoggedIn')
  const SALESFORCE_SENDER = t('EmailComposer.Settings.Sender.Salesforce')
  const PERSONALIZED_SENDER = t('EmailComposer.Settings.Sender.Personalized')
  const APPROVED_SENDERS = t('EmailComposer.Settings.Sender.Approved')
  const REPLY_TO_SENDER = t('EmailComposer.Settings.Sender.ReplyTo.SameAsSender')

  const dynamicSenderOptions: SelectV2SingleOption[] = []
  if (salesforceConnected) {
    dynamicSenderOptions.push({ label: SALESFORCE_SENDER, value: SALESFORCE_OWNER_OPTION_VALUE })
  }
  if (hasPersonalizedFrom) {
    dynamicSenderOptions.push({ label: PERSONALIZED_SENDER, value: PERSONALIZED_FROM_ADDRESS_OPTION_VALUE })
  }

  const senderOptions = [
    {
      label: LOGGED_IN_USER,
      options: [loggedInUser],
    },
    {
      label: APPROVED_SENDERS,
      options: emailSenders.map(getSenderOption),
    },
    {
      label: DYNAMIC_SENDERS,
      options: dynamicSenderOptions,
    },
  ]

  const fallbackOptions = senderOptions.filter((group) => group.label !== DYNAMIC_SENDERS)

  const replyToOptions = fallbackOptions.map((group) => {
    if (group.label === LOGGED_IN_USER) {
      return { label: t('Default'), options: [{ value: SAME_AS_SENDER_OPTION_VALUE, label: REPLY_TO_SENDER }] }
    }
    return group
  })

  return {
    Sender: senderOptions,
    Fallback: fallbackOptions,
    ReplyTo: replyToOptions,
  }
}
