import { CSSObjectWithLabel } from 'react-select'

export const reactSelectTheme = (theme: any) => ({
  ...theme,
  color: '#444444',
  colors: {
    ...theme.colors,
    primary: 'unset',
    primary25: '#F5F6F8',
    primary50: '#E5F9F9',
    neutral5: '#304A71',
  },
})

export const inputCustomStyles = {
  input: (base: CSSObjectWithLabel) =>
    ({
      ...base,
      fontSize: 14,
    } as CSSObjectWithLabel),
}
export const getMenuListCustomStyle = (customStyle: CSSObjectWithLabel) => ({
  menuList: (base: CSSObjectWithLabel) => ({
    ...base,
    ...customStyle,
  }),
})
