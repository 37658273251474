import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { rootContext } from '@utils/const/globals'

import { T_BEE_BLANK } from '../../../EmailComposer/utils/EmailComposer.constants'

export interface EmailTemplate extends ItemDto {
  createdAt: number
  id: string
  isCustomMessage: boolean
  isNewComposer: boolean
  isTemplateAvailableInEBE: boolean
  needsPushEBE: boolean
  previewUrl: string
  publicUrl: string
  name: string
  createdBy: string
  lastUpdated: number
  lastUpdatedBy: string
}

export interface EmailTemplateMessageData {
  emailId: string
  emailTitle: string
  isEditing: boolean
}

export type EmailTemplateMessage = EmailTemplateMessageData & { windowName: string }

export const isEmailTemplateMessage = (data: MessageEvent<any>['data']) => data instanceof Object && 'emailId' in data && 'emailTitle' in data

export const BLANK_MESSAGE_SETUP_ID = 't-blank'

export const BLANK_MESSAGE_URL_BEE = `${rootContext}/content/emails/${T_BEE_BLANK}/settings?messageConfigurationType=blank_message`
