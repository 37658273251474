import { useApolloClient } from '@apollo/client'
import getItem from '@graphql/microservices/categorization/getItem'
import clearFormPerformanceReport from '@graphql/mutations/clearFormPerformanceReport'
import createFormEmbedCode from '@graphql/mutations/createFormEmbedCode'
import deleteFormEmbedCode from '@graphql/mutations/deleteFormEmbedCode'
import saveFormEmbedCode from '@graphql/mutations/saveFormEmbedCode'
import getForm from '@graphql/queries/getForm'
import getFormContacts from '@graphql/queries/getFormContacts'
import getFormSalesForcePushErrors from '@graphql/queries/getFormSalesForcePushErrors'
import { GetItemQuery, GetItemQueryVariables } from '@graphql/types/microservice/categorization-types'
import {
  ClearFormPerformanceReportMutation,
  ClearFormPerformanceReportMutationVariables,
  CreateFormEmbedCodeMutation,
  CreateFormEmbedCodeMutationVariables,
  DeleteFormEmbedCodeMutation,
  DeleteFormEmbedCodeMutationVariables,
  SaveFormEmbedCodeMutation,
  SaveFormEmbedCodeMutationVariables,
} from '@graphql/types/mutation-types'
import { GetFormContactsQuery, GetFormContactsQueryVariables, GetFormQuery, GetFormQueryVariables } from '@graphql/types/query-types'
import { GetFormSalesForcePushErrorsQuery, GetFormSalesForcePushErrorsQueryVariables } from '@graphql/types/query-types'
import { ItemType } from '@utils/categorization'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export interface FormsListingPageRequests {
  saveFormEmbedCodeRequest: (variables: SaveFormEmbedCodeMutationVariables) => FetchPromise<SaveFormEmbedCodeMutation>
  createFormEmbedCodeRequest: (variables: CreateFormEmbedCodeMutationVariables) => FetchPromise<CreateFormEmbedCodeMutation>
  deleteFormEmbedCodeRequest: (variables: DeleteFormEmbedCodeMutationVariables) => FetchPromise<DeleteFormEmbedCodeMutation>
  clearFormPerformanceReportRequest: (variables: ClearFormPerformanceReportMutationVariables) => FetchPromise<ClearFormPerformanceReportMutation>
  getFormContactsRequest: (variables: GetFormContactsQueryVariables) => FetchPromise<GetFormContactsQuery>
  getFormRequest: (variables: GetFormQueryVariables) => FetchPromise<GetFormQuery>
  getFormSalesForcePushErrorsRequest: (variables: GetFormSalesForcePushErrorsQueryVariables) => FetchPromise<GetFormSalesForcePushErrorsQuery>
  getFormItemRequest: (itemId: string) => FetchPromise<GetItemQuery>
}

export const useFormManagerRequests = (): FormsListingPageRequests => {
  const actonClient = useApolloClient()
  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })

  const getFormItemRequest = (itemId: string) => {
    return categorizationClient.query<GetItemQuery, GetItemQueryVariables>({
      query: getItem,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        externalId: itemId,
        type: ItemType.FORM,
      },
    })
  }

  const getFormRequest = async (variables: GetFormQueryVariables) => {
    return await actonClient.query<GetFormQuery, GetFormQueryVariables>({
      query: getForm,
      errorPolicy: 'all',
      variables,
      fetchPolicy: 'no-cache',
    })
  }

  const getFormContactsRequest = async (variables: GetFormContactsQueryVariables) => {
    return await actonClient.query<GetFormContactsQuery, GetFormContactsQueryVariables>({
      query: getFormContacts,
      errorPolicy: 'all',
      variables,
    })
  }

  const deleteFormEmbedCodeRequest = async (variables: DeleteFormEmbedCodeMutationVariables) => {
    return await actonClient.mutate<DeleteFormEmbedCodeMutation, DeleteFormEmbedCodeMutationVariables>({
      mutation: deleteFormEmbedCode,
      errorPolicy: 'all',
      variables,
    })
  }

  const createFormEmbedCodeRequest = async (variables: CreateFormEmbedCodeMutationVariables) => {
    return await actonClient.mutate<CreateFormEmbedCodeMutation, CreateFormEmbedCodeMutationVariables>({
      mutation: createFormEmbedCode,
      errorPolicy: 'all',
      variables,
    })
  }

  const saveFormEmbedCodeRequest = async (variables: SaveFormEmbedCodeMutationVariables) => {
    return await actonClient.mutate<SaveFormEmbedCodeMutation, SaveFormEmbedCodeMutationVariables>({
      mutation: saveFormEmbedCode,
      errorPolicy: 'all',
      variables,
    })
  }

  const clearFormPerformanceReportRequest = async (variables: ClearFormPerformanceReportMutationVariables) => {
    return await actonClient.mutate<ClearFormPerformanceReportMutation, ClearFormPerformanceReportMutationVariables>({
      mutation: clearFormPerformanceReport,
      errorPolicy: 'all',
      variables,
    })
  }

  const getFormSalesForcePushErrorsRequest = async (variables: GetFormSalesForcePushErrorsQueryVariables) => {
    return await actonClient.query<GetFormSalesForcePushErrorsQuery, GetFormSalesForcePushErrorsQueryVariables>({
      query: getFormSalesForcePushErrors,
      errorPolicy: 'all',
      variables,
    })
  }

  return {
    getFormSalesForcePushErrorsRequest,
    createFormEmbedCodeRequest,
    deleteFormEmbedCodeRequest,
    saveFormEmbedCodeRequest,
    clearFormPerformanceReportRequest,
    getFormRequest,
    getFormContactsRequest,
    getFormItemRequest,
  }
}
