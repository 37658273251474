import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react'

import { useApolloClient, useLazyQuery } from '@apollo/client'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import listsValidation from '@graphql/queries/listsValidation'
import { ListsValidationQuery, ListsValidationQueryVariables } from '@graphql/types/query-types'
import ValidationSection from '@src/pages/EmailComposer/components/common/ValidationSection/ValidationSection'
import { Validation, ValidationSectionStatus } from '@src/pages/EmailComposer/components/common/ValidationsList/ValidationsList'
import { CheckEmails } from '@src/pages/EmailComposer/components/ReviewAndSend/components/ReviewAndSendMain/components/RecipientsSection/CheckEmails'
import { renderContactsAsList } from '@src/pages/EmailComposer/components/SendDetails/components/SendDetailsRecipients/SendDetailsRecipients.utils'
import { detectEmailType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { filterNotEmptyArray } from '@utils/array'
import { CommonComposerTab } from '@utils/composer/commonComposer/CommonComposer.context'
import { EmailComposerContext, SendToContact, ValidationSectionType } from '@utils/composer/context/EmailComposer.context'
import useCRM from '@utils/hooks/useCRM'
import { formatNumber } from '@utils/numbers'

import './RecipientsSection.css'

interface RecipientsSectionProps {
  dataTest?: string
}

const rootClass = 'recipients-section'

export const RecipientsSection: FC<RecipientsSectionProps> = ({ dataTest = rootClass }) => {
  const {
    values: {
      messageLimits: { monthlyLimit, currentUsage },
      message: {
        messageType,
        sendto_lists,
        suppress_dups,
        overrideFatigueRules,
        sendto_contacts,
        suppress_lists,
        sendMethod: { sendtype },
        isCrmSentNote,
        id,
        assetReportRecipientDetails,
      },
      hasFatigueRules,
      recipientsCount,
      messageConfiguration: {
        reviewAndSend: { hideAccountLimitWarning, hideCheckEmails, hideFatigueRules, hideSuppressDups, disableEditRecipientsBtn },
      },
      validations: {
        settingsValidations: { listsIncompatibleWithSalesforceOwner },
      },
    },
    api: { onTabChange, updateValidations, onStatusChange },
  } = useContext(EmailComposerContext)
  const { t } = useTranslation()
  const client = useApolloClient()
  const { connectorType } = useCRM()

  const noRecipientsInlist = !recipientsCount && sendtype === 'STANDARD' && !assetReportRecipientDetails
  const limitReached = currentUsage >= monthlyLimit
  const haveRecipients = !!sendto_lists?.length || !!sendto_contacts?.length || !!assetReportRecipientDetails?.count

  const { isEmailCRM } = detectEmailType(messageType)

  const [getListsValidation, { data }] = useLazyQuery<ListsValidationQuery, ListsValidationQueryVariables>(listsValidation, {
    client,
    fetchPolicy: 'no-cache',
    variables: { messageId: id },
  })

  useEffect(() => {
    if (haveRecipients && !noRecipientsInlist) {
      getListsValidation()
    }
  }, [getListsValidation, noRecipientsInlist, haveRecipients])

  useEffect(() => {
    if (data?.listsValidation) {
      updateValidations({ recipients: data.listsValidation })
    }
  }, [updateValidations, data?.listsValidation])

  const allRecipientsSuppressed =
    !!suppress_lists?.length &&
    !!sendto_lists?.length &&
    sendto_lists?.every((contact) => suppress_lists?.some((list) => contact.srcId === list.srcId && contact.srcName === contact.srcName))
  const allContactsSuppressed =
    !!suppress_lists?.length &&
    !!sendto_contacts?.length &&
    sendto_contacts?.every((contact: SendToContact) => suppress_lists?.some((list) => contact.srcId === list.srcId))
  const validations = useMemo<Validation[]>(() => {
    if (!haveRecipients) {
      return [
        {
          key: 'recipients',
          status: ValidationSectionStatus.ERROR,
          title: 'EmailComposer.Recipients.Validation.NoList',
        },
      ]
    } else if (noRecipientsInlist) {
      return [
        {
          key: 'recipients',
          status: ValidationSectionStatus.ERROR,
          title: 'EmailComposer.Recipients.Validation.NoRecipients',
        },
      ]
    }

    const duplicatesStatus = suppress_dups ? ValidationSectionStatus.SUCCESS : ValidationSectionStatus.WARNING
    const salesforceOwnerError = !!listsIncompatibleWithSalesforceOwner.length

    const validations: Validation[] = [
      ...(allRecipientsSuppressed || allContactsSuppressed
        ? [
            {
              key: 'allContactsSuppressed',
              status: ValidationSectionStatus.ERROR,
              title: 'EmailComposer.Recipients.Validation.Suppressed.All',
            },
          ]
        : []),
      ...(salesforceOwnerError
        ? [
            {
              key: 'listsIncompatibleWithSalesforceOwner',
              status: ValidationSectionStatus.ERROR,
              title: 'EmailComposer.Sender.Details.Validation.Sender',
              titleProps: {
                values: { context: 'ownerError' },
              },
            },
          ]
        : []),
      ...(!hideSuppressDups
        ? [
            {
              key: 'duplicates',
              status: duplicatesStatus,
              title: 'EmailComposer.Recipients.Validation.Duplicates',
              titleProps: {
                values: { context: duplicatesStatus },
              },
              infoTooltipContent: suppress_dups ? undefined : t('EmailComposer.Recipients.Validation.Duplicates.Tooltip'),
            },
          ]
        : []),
    ]

    if (hasFatigueRules && !hideFatigueRules) {
      const fatigueStatus = !overrideFatigueRules ? ValidationSectionStatus.SUCCESS : ValidationSectionStatus.WARNING
      validations.push({
        key: 'fatigue',
        status: fatigueStatus,
        title: 'EmailComposer.Recipients.Validation.Fatigue',
        titleProps: {
          values: { context: fatigueStatus },
        },
      })
    }

    if (isCrmSentNote) {
      validations.push({
        key: 'sendCrmNote',
        status: ValidationSectionStatus.SUCCESS,
        title: 'EmailComposer.Recipients.Validation.Has_CRM_Note',
        titleProps: {
          values: { context: ValidationSectionStatus.SUCCESS },
        },
      })
    }

    const {
      deletedSuppressionListNames,
      deletedListNames,
      listsWithMissingEmailAddressColumn,
      suppressionListsWithMissingEmailAddressColumn,
      trashListNames,
      trashSuppressionListNames,
    } = data?.listsValidation ?? {}

    if (listsWithMissingEmailAddressColumn) {
      const options = listsWithMissingEmailAddressColumn.filter(filterNotEmptyArray)
      options.length &&
        validations.push({
          key: 'recipientsEmail',
          status: ValidationSectionStatus.ERROR,
          title: 'EmailComposer.Recipients.Validation.Missing.Recipients.EmailColumn',
          options,
        })
    }

    if (deletedListNames) {
      const options = deletedListNames.filter(filterNotEmptyArray)
      options.length &&
        validations.push({
          key: 'recipientsDeleted',
          status: ValidationSectionStatus.WARNING,
          title: 'EmailComposer.Recipients.Validation.Deleted.Recipients',
          options,
        })
    }

    if (trashListNames) {
      const options = trashListNames.filter(filterNotEmptyArray)
      options.length &&
        validations.push({
          key: 'recipientsDeleted',
          status: ValidationSectionStatus.WARNING,
          title: 'EmailComposer.Recipients.Validation.Deleted.Recipients',
          options,
        })
    }

    if (suppressionListsWithMissingEmailAddressColumn) {
      const options = suppressionListsWithMissingEmailAddressColumn.filter(filterNotEmptyArray)
      options.length &&
        validations.push({
          key: 'suppressionEmail',
          status: ValidationSectionStatus.WARNING,
          title: 'EmailComposer.Recipients.Validation.Missing.Suppression.EmailColumn',
          options,
        })
    }

    if (deletedSuppressionListNames) {
      const options = deletedSuppressionListNames.filter(filterNotEmptyArray)
      options.length &&
        validations.push({
          key: 'suppressionDeleted',
          status: ValidationSectionStatus.WARNING,
          title: 'EmailComposer.Recipients.Validation.Deleted.Suppression',
          options,
        })
    }

    if (trashSuppressionListNames) {
      const options = trashSuppressionListNames.filter(filterNotEmptyArray)
      options.length &&
        validations.push({
          key: 'suppressionDeleted',
          status: ValidationSectionStatus.WARNING,
          title: 'EmailComposer.Recipients.Validation.Deleted.Suppression',
          options,
        })
    }

    !hideAccountLimitWarning &&
      validations.push({
        key: 'limit',
        status: limitReached ? ValidationSectionStatus.WARNING : ValidationSectionStatus.SUCCESS,
        title: limitReached ? null : 'EmailComposer.Recipients.Validation.Limit',
      })

    return validations
  }, [suppress_dups, overrideFatigueRules, t, haveRecipients, limitReached, noRecipientsInlist, data, hasFatigueRules])

  const handleClick = useCallback<VoidFunction>(
    () => onTabChange(CommonComposerTab.SEND, { fromValidationSection: ValidationSectionType.RECIPIENTS }),
    [onTabChange]
  )

  return (
    <ValidationSection
      disableEditBtn={disableEditRecipientsBtn || !!assetReportRecipientDetails}
      tooltipContentKey={
        disableEditRecipientsBtn
          ? t('EmailComposer.Preview.Validations.EditRecipients', { connectorType })
          : t('EmailComposer.ReviewAndSend.AssetReportRecipients.EditButton.Disabled')
      }
      type={ValidationSectionType.RECIPIENTS}
      dataTest={dataTest}
      title="Recipients"
      buttonLabel="Edit recipients"
      validations={validations}
      onClick={handleClick}
      onStatusChange={onStatusChange}
      subTitle={limitReached && !hideAccountLimitWarning ? 'AccountLimitsWarning.LimitReached' : undefined}
    >
      {haveRecipients && !noRecipientsInlist && (
        <>
          <div className={`${rootClass}__list`}>
            <Typography
              text={t('EmailComposer.Recipients.Validation.Title', { context: isEmailCRM ? 'crm' : 'default', connectorType })}
              weight={TextWeight.MEDIUM}
            />
            <div className={`${rootClass}__list-content`}>
              {!!assetReportRecipientDetails ? (
                renderContactsAsList(rootClass, assetReportRecipientDetails, t)
              ) : (
                <>
                  {sendto_lists?.map(({ srcName, srcId, size }) => (
                    <Typography text={`${srcName} (${formatNumber(size)})`} key={srcId} className="push-up-half" />
                  ))}
                  {sendto_contacts && (
                    <div className="push-down-x2">
                      {sendto_contacts.map(({ id, name, email }) => (
                        <div key={id}>
                          {name && <Typography text={`${name} `} weight={TextWeight.MEDIUM_LIGHT} inline />}
                          {email && <Typography text={email} weight={TextWeight.ITALIC} inline />}
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
            {!!suppress_lists?.length && (
              <div className="push-down-x3">
                <Typography text={`${t('Selected suppressions')}:`} weight={TextWeight.MEDIUM} />
                <div className={`${rootClass}__list-content`}>
                  {suppress_lists.map(({ srcName, srcId, size }) => (
                    <Typography text={`${srcName} (${size})`} key={srcId} className="push-up-half" />
                  ))}
                </div>
              </div>
            )}
          </div>
          {!hideCheckEmails && (
            <div className={`${rootClass}__footer`}>
              <CheckEmails modalClassName={`${rootClass}__emails-modal`} />
            </div>
          )}
        </>
      )}
    </ValidationSection>
  )
}
