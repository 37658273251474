import React from 'react'

import { TFunction } from 'i18next'

import TextLink from '@components/TextLink/TextLink'
import Typography, { TextType } from '@components/Typography/Typography'

export const TRANSACTION_EMAIL_LINK = 'https://connect.act-on.com/hc/en-us/articles/360023944193-Transactional-Email-Overview'

export const getTransactionEmailDisabledTooltip = (t: TFunction) => (
  <>
    <Typography type={TextType.BODY_TEXT_WHITE} text={t(`EmailComposer.Settings.TransactionalEmail.Disabled.Tooltip`)} />
    <div>&nbsp;</div>
    <TextLink isWhite link={TRANSACTION_EMAIL_LINK}>
      {t('Read article')}
    </TextLink>
  </>
)
