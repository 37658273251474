import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import './formRow.css'

export enum FormActionPosition {
  RIGHT,
  LEFT,
}

interface Props {
  children: ReactNode
  className?: string
  dataTest?: string
  hasAction?: FormActionPosition
  noPadding?: boolean
}

const rootClass = 'form-row'

export const FormRow: FC<Props> = (props: Props) => {
  const { children, className, dataTest = 'form-row', hasAction, noPadding = false } = props
  return (
    <div
      data-test={dataTest}
      className={classNames(rootClass, className, {
        [`${rootClass}--action-right`]: hasAction === FormActionPosition.RIGHT,
        [`${rootClass}--action-left`]: hasAction === FormActionPosition.LEFT,
        [`${rootClass}--no-padding`]: noPadding,
      })}
    >
      {children}
    </div>
  )
}

export default FormRow
