import React, { FC, useEffect, useState } from 'react'

import ListingPageWithTabs from '@complex/ListingPageWithTabs/ListingPageWithTabs'
import { SmallCollapsibleBanner } from '@components/SmallCollapsibleBanner/SmallCollapsibleBanner'
import { SvgNames } from '@components/Svg'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import FormsListingPageContainer from '@src/pages/listingPages/Forms/FormsListingPageContainer'
import FormTemplatesListingPageContainer from '@src/pages/listingPages/FormTemplates/FormTemplatesListingPageContainer'
import { useTabbedContainerRequests } from '@src/pages/listingPages/TabbedContainers/GraphQL/TabbedContainerRequests.graphQL'
import { getAllContactsUpgradeStatus } from '@src/pages/listingPages/TabbedContainers/TabbedContainer.utils'
import FormsUpgradeModal from '@src/pages/UpgradeAssistant/components/FormsUpgradeModal/FormsUpgradeModal'
import { UpgradeItemType } from '@src/pages/UpgradeAssistant/UpgradeAssistant.contants'
import { useUpgradeAssistant } from '@src/pages/UpgradeAssistant/utils/useUpgradeAssistant'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import { getItem, setItem } from '@utils/sessionStorage'

import './FormAndTemplatesTabbedContainer.css'

interface FormAndTemplatesTabbedListingPageContainerProps {
  className?: string
  dataTest?: string
}

interface FormAndTemplatesTabbedListingPageContainerState {
  showFormsUpgradeBanner: boolean
  showFormsUpgradeModal: boolean
  doFetchItems: boolean
  upgradeCompleted: boolean
}

enum FormAndTemplatesSession {
  UPGRADE_FORM_BANNER = 'formAndTemplates:upgradeFormBanner',
}

const rootClass = 'form-and-templates-tabbed-container'
const LEARN_MORE_LINK =
  'https://success.act-on.com/acton/attachment/9883/u-0f9560ae-7b19-4b60-8235-97ff9bb54f41/0/-/-/-/-/In%20App%3A%20%20Act-On%20Contacts%20Form%20Submissions'

const FormAndTemplatesTabbedListingPageContainer: FC<FormAndTemplatesTabbedListingPageContainerProps> = (
  props: FormAndTemplatesTabbedListingPageContainerProps
) => {
  const { dataTest = `FormAndTemplatesTabbedListingPageContainer` } = props
  const [state, setState] = useState<FormAndTemplatesTabbedListingPageContainerState>({
    showFormsUpgradeBanner: getItem(FormAndTemplatesSession.UPGRADE_FORM_BANNER) !== 'false',
    showFormsUpgradeModal: false,
    doFetchItems: false,
    upgradeCompleted: true,
  })
  const { showFormsUpgradeBanner, showFormsUpgradeModal, doFetchItems, upgradeCompleted } = state
  const { t } = useTranslation()
  const { hasFormsUpgrade, exclusivelyActOnContacts } = useAccountSettings()
  const { getAllContactsUpgrade } = useTabbedContainerRequests()

  const { hasUpgradableItems } = useUpgradeAssistant([ItemType.FORM, ItemType.FORM_TEMPLATE])

  const showFormBanner = hasFormsUpgrade && showFormsUpgradeBanner && hasUpgradableItems && !exclusivelyActOnContacts
  const showUpgradeHelper = hasFormsUpgrade && !upgradeCompleted && !showFormsUpgradeBanner && !exclusivelyActOnContacts

  const onUpgradeBannerCollapse = () => {
    setState((state) => {
      return { ...state, showFormsUpgradeBanner: false }
    })
    setItem(FormAndTemplatesSession.UPGRADE_FORM_BANNER, 'false')
  }

  const onUpgradeBannerClick = () => {
    setState((state) => {
      return { ...state, showFormsUpgradeModal: true, doFetchItems: false }
    })
  }

  useEffect(() => {
    if (hasFormsUpgrade) {
      getAllContactsUpgradeStatus(getAllContactsUpgrade, UpgradeItemType.FORMS, (isCompleted) =>
        setState((state) => ({ ...state, upgradeCompleted: isCompleted }))
      )
    }
  }, [])

  const tabProps = [
    {
      label: t('Forms'),
      component: (
        <FormsListingPageContainer
          showUpgradeHelper={showUpgradeHelper}
          onUpgradeHelperClick={() => setState((state) => ({ ...state, showFormsUpgradeBanner: true }))}
          doFetchItems={doFetchItems}
        />
      ),
      pathName: `${rootContext}/content/forms`,
      pathnameId: 'forms',
    },
    {
      label: t('Templates'),
      component: <FormTemplatesListingPageContainer />,
      pathName: `${rootContext}/content/formTemplates`,
      pathnameId: 'formTemplates',
    },
  ]

  return (
    <>
      {showFormBanner && !upgradeCompleted && (
        <SmallCollapsibleBanner
          className={`${rootClass}__upgrade-banner`}
          isDefaultExpanded={true}
          body={
            <div className={`${rootClass}__upgrade-banner-body`}>
              <Typography
                className={`${rootClass}__upgrade-banner-text`}
                text={t('FormsAndTemplates.UpgradeBanner.Text')}
                type={TextType.BODY_TEXT}
                tagProps={{ bold: { weight: TextWeight.BOLD } }}
                tagComponents={{ TextLink: <TextLink size={TextLinkSize.LARGE} link={LEARN_MORE_LINK}></TextLink> }}
                inline
              />
            </div>
          }
          primaryButtonText={t('Upgrade forms')}
          icon={SvgNames.lightBulbNoBackground}
          onCollapse={onUpgradeBannerCollapse}
          onPrimaryButtonClick={onUpgradeBannerClick}
        />
      )}
      {showFormsUpgradeModal && (
        <FormsUpgradeModal onClose={() => setState((state) => ({ ...state, showFormsUpgradeModal: false, doFetchItems: true }))} isOpen />
      )}
      <ListingPageWithTabs
        className={showFormBanner && !upgradeCompleted ? `${rootClass}__upgrade-banner-displayed` : undefined}
        tabProps={tabProps}
        dataTest={dataTest}
        hasDynamicHeight
      />
    </>
  )
}

export default FormAndTemplatesTabbedListingPageContainer
