import React, { MouseEvent, startTransition, useMemo, useState } from 'react'

import classNames from 'classnames'

import { CellValue } from '@components/TableV2/components/CellValue/CellValue'
import { RowDepth, SpacerBlockWrapper } from '@components/TableV2/components/SpacerBlock/SpacerBlock'
import { TableV2RowAction } from '@components/TableV2/components/TableV2RowAction/TableV2RowAction'
import { TableTagState, TableV2RowProps } from '@components/TableV2/tableV2TS/interfaces'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { isColumnIdEqual } from '@components/TableV2/utils/tableV2ColumnUtils'
import { getCellStyles } from '@components/TableV2/utils/tableV2Utils'
import { defaultColumnSizing, Row } from '@tanstack/react-table'

export const TableV2Row = <T extends {}>({
  row,
  rows,
  rowRef,
  tagProps,
  dataTest,
  rootClass,
  isExpanded,
  rowActions,
  isDraggable,
  caretCellID,
  hoveredRowID,
  enableSubRow,
  stickyColumns,
  firstColumnsID,
  isCellClickable,
  stickyColsWidths,
  draggableRowProps,
  enableSpacerBlock,
  hasDisabledRowStyles,
  hasRowDisabledTitle,
  customClass,
  hasToExpand,
  rowDisabled,
  onRowClicked,
  onRowExpanded,
  setHoveredRowID,
  style,
}: TableV2RowProps<T>) => {
  const [isVisible, setVisible] = useState(false)
  const [tagState, setTagState] = useState<TableTagState>({
    hover: false,
    numberOfTagsDisplayed: tagProps?.defaultTagsNumber || 3,
    openedRowID: '',
  })

  const { isOver, canDrop, isOverTop, isDragging, draggableRootClassName, dragRef } = draggableRowProps || {}
  const parentRows = rows.filter((row) => row.depth == 0)
  const hasCustomClass = customClass?.(row)

  const draggableRowClassNames = useMemo(
    () => ({
      [`${rootClass}__row`]: true,
      [`${draggableRootClassName}`]: true,
      [`${draggableRootClassName}-cannot-drop`]: isOver && !canDrop,
      [`${draggableRootClassName}-dragging`]: isDragging,
      [`${draggableRootClassName}-over`]: isOver && !isOverTop && canDrop,
      [`${draggableRootClassName}-over-top`]: isOver && isOverTop && canDrop,
    }),
    [isOver, canDrop, isDragging, isOverTop]
  )

  const mouseEvents = useMemo(() => {
    if (isExpanded && enableSubRow) {
      return {
        onMouseEnter: (e: MouseEvent) => startTransition(() => setHoveredRowID(e.currentTarget.id)),
        onMouseLeave: () => !!hoveredRowID?.length && startTransition(() => setHoveredRowID('')),
      }
    }
  }, [enableSubRow, isExpanded])

  return (
    <tr
      style={style}
      ref={rowRef}
      id={row.id}
      key={row.id}
      onClick={() => !isCellClickable && onRowClicked?.(row, rows)}
      className={classNames(`${rootClass}__row`, {
        ...(isDraggable && draggableRowClassNames),
        [`${rootClass}__row-disabled`]: rowDisabled?.(row),
        [`${rootClass}__row-disabled-title`]: rowDisabled?.(row) && hasRowDisabledTitle,
        [`${rootClass}__row-actionable`]: !isCellClickable && !!onRowClicked,
        [`${rootClass}__row-hovered`]: (row.id === tagState.openedRowID && tagState.hover) || isVisible,
        [`${rootClass}__row-${hasCustomClass}`]: hasCustomClass,
      })}
      {...mouseEvents}
    >
      {row.getVisibleCells().map((cell, i) => {
        const { column } = cell
        const { columnDef }: { columnDef: ColumnDefWithAdditionalProps<T> } = column
        const cellRadio = column.id === 'cellRadio'
        const cellCheckbox = column.id === 'cellCheckbox'
        const isSubRow = cell.row.depth > 0
        const { maxSize, minSize } = defaultColumnSizing
        const { maxSize: maxWidth, minSize: minWidth } = columnDef
        const cellStyles = getCellStyles(i, columnDef, maxWidth, maxSize, minWidth, minSize, stickyColumns, stickyColsWidths)

        const cellValue = (
          <CellValue
            row={row}
            cell={cell}
            column={column}
            tagState={tagState}
            tagProps={tagProps}
            dataTest={dataTest}
            parentRows={parentRows}
            isDraggable={isDraggable}
            caretCellID={caretCellID}
            hoveredRowID={hoveredRowID}
            enableSubRow={enableSubRow}
            firstColumnsID={firstColumnsID}
            draggableRootClassName={draggableRootClassName}
            dragRef={dragRef}
            hasToExpand={hasToExpand}
            setTagState={setTagState}
            onRowExpanded={onRowExpanded}
            cellDisabled={rowDisabled?.(row) && hasDisabledRowStyles}
          />
        )

        return (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <td
            key={cell.id}
            className={classNames(`${rootClass}__td`, {
              [`${rootClass}__radio`]: cellRadio,
              [`${rootClass}__checkbox`]: cellCheckbox,
              [`${rootClass}__hover-sticky-cols`]: !!stickyColumns,
              [`${rootClass}__clickable-cell`]: columnDef.enableClickableCell,
              [`${rootClass}__expander-cell-with-subRow`]: isSubRow && column.id === caretCellID,
              [`${rootClass}__sticky-column`]: stickyColumns && isColumnIdEqual(stickyColumns, cell),
            })}
            onClick={() => columnDef.enableClickableCell && onRowClicked?.(row)}
            onKeyDown={(keyDownEvent) => (keyDownEvent.key === ' ' ? columnDef.enableClickableCell && onRowClicked?.(row) : undefined)}
            style={cellStyles}
          >
            {enableSpacerBlock && cell.column.id === 'description' ? (
              <SpacerBlockWrapper
                cellValue={cellValue}
                rows={rows as unknown as Row<RowDepth>[]}
                parentIndex={i}
                depth={(row.original as unknown as RowDepth).depth}
              />
            ) : (
              cellValue
            )}
          </td>
        )
      })}
      {!!rowActions?.length && !rowDisabled?.(row) && (
        <td className={`${rootClass}__row-actions`}>
          <TableV2RowAction rowActions={rowActions} row={row} isVisible={isVisible} onToggleDropDown={setVisible} />
        </td>
      )}
    </tr>
  )
}
