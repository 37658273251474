import { gql } from '@apollo/client'

export default gql`
  query getAllSenderDomainConfig {
    getAllSenderDomainConfig {
      dkimIsSubdomain
      hasDkimCnameRecord
      hasDkimTxtRecord
      hasSpfTxtRecord
      parentId
      senderDomain
    }
  }
`
