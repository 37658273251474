import React, { FC, ReactNode, useState, useEffect, RefObject, useRef, useMemo } from 'react'

import classNames from 'classnames'

import scrollbarWidth from '../Table/utils/scrollbarWidth'

import './PositionContainer.css'

export enum PositionContainerTypes {
  flex = 'flex',
}

interface Props {
  children: ReactNode | ReactNode[]
  displayType?: PositionContainerTypes
  noOverflow?: boolean
  restoreMargin?: boolean
  noInnerPadding?: boolean
  bannerBackground?: boolean
  innerDivClassname?: string
  useTabs?: boolean
  childrenFlexAdjust?: boolean
  scrollElementRef?: RefObject<HTMLDivElement>
  dataTest?: string
}

const rootClass = 'position-container'

const PositionContainer: FC<Props> = (props: Props) => {
  const {
    children,
    childrenFlexAdjust,
    noOverflow = false,
    restoreMargin,
    displayType,
    noInnerPadding,
    innerDivClassname,
    bannerBackground,
    useTabs,
    scrollElementRef,
    dataTest = rootClass,
  } = props
  const [childStyle, setChildStyle] = useState({})
  const containerRef = useRef<HTMLDivElement>(null)
  const childrenRef = useRef<HTMLDivElement>(null)
  const scrollWidth = useMemo(() => scrollbarWidth(), [])

  const adjustForScrollbar = () => {
    const scrollElement = scrollElementRef?.current
    const container = containerRef?.current

    if (scrollElement && container) {
      const marginCollapsed = window.getComputedStyle(container).getPropertyValue('margin-left') === '0px'
      const hasScrollbar = scrollElement.scrollHeight > scrollElement.clientHeight

      setChildStyle(
        hasScrollbar && !marginCollapsed
          ? {
              marginLeft: `${scrollWidth / 2}px`,
              marginRight: `-${scrollWidth / 2}px`,
            }
          : hasScrollbar
          ? {
              marginRight: `-${scrollWidth}px`,
            }
          : {}
      )
    }
  }
  useEffect(() => adjustForScrollbar(), [children])

  return (
    <div
      data-test={dataTest}
      className={classNames(rootClass, innerDivClassname, {
        [`${rootClass}__no-overflow`]: noOverflow,
        [`${rootClass}__restore-margin`]: restoreMargin,
        [`${rootClass}__no-inner-padding`]: noInnerPadding,
        [`${rootClass}__banner-background`]: bannerBackground,
      })}
      ref={containerRef}
    >
      <div
        className={classNames(`${rootClass}__children`, {
          [`${rootClass}__flex`]: displayType === PositionContainerTypes.flex,
          [`${rootClass}__use-tabs`]: useTabs,
        })}
        ref={childrenRef}
      >
        <div
          className={classNames({
            [`${rootClass}__children-adjusted`]: childrenFlexAdjust,
          })}
          style={childStyle}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default PositionContainer
