import { createContext } from 'react'

import { values } from './smsContextDefaultValues'
import { State, SMSMessage } from '../edit/EditSMSContainer'

type Updater = (field: string, value: any) => void
type UpdateMessage = (message: string) => void
type SaveMessage = (values: SMSMessage, messageCaratPosition?: number) => void
type SendSMSMessage = () => void
type ScheduleMessageLaunch = () => void
export interface SMSContextAPI {
  values: State
  updater: Updater
  canSend: () => boolean
  canSchedule: () => boolean
  updateMessage: UpdateMessage
  saveMessage: SaveMessage
  sendSMSMessage: SendSMSMessage
  scheduleMessageLaunch: ScheduleMessageLaunch
}

export const SMSContext = createContext<SMSContextAPI>({ values } as any)
