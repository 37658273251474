import React from 'react'
import { Row } from 'react-table'

import { TableActions } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.constants'
import { getActionProps } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.utils'
import { TableProps, Update, ListPageCommonState, ListPageTableActionCustomProps } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { SvgNames, SvgType } from '@components/Svg'
import Svg from '@components/Svg/index'
import { renderRelativeDate } from '@components/Table/components/tableColumns'
import { RowAction } from '@components/Table/Table'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { CellContext } from '@tanstack/react-table'
import { ItemType } from '@utils/categorization'
import { getStandardFormattedDate, relativeDate } from '@utils/date'
import { openFormBuilderWindow } from '@utils/forms'
import { renderPathToFolder } from '@utils/searchUtils'

export enum FormTemplatesCustomTableActions {
  EDIT = 'EDIT',
  CREATE_FORM = 'CREATE_FORM',
  CREATE_FROM_FORM_PICKER = 'CREATE_FROM_FORM_PICKER',
  CREATE_FROM_FORM_OPTIONS = 'CREATE_FROM_FORM_OPTIONS',
}

const rowActionCustomProps: ListPageTableActionCustomProps[] = [
  { name: 'EDIT', position: 0, hasTopSection: false, isInDropdown: false },
  { name: 'PREVIEW', position: 1, hasTopSection: false, isInDropdown: false },
  { name: 'ADD_TO_FAVORITES', position: 2, hasTopSection: false, isInDropdown: false },
  { name: 'CREATE_FORM', position: 3, hasTopSection: false, isInDropdown: false },
  { name: 'DUPLICATE', position: 4, hasTopSection: false, isInDropdown: true },
  { name: 'SHARE_TO_CATALOG', position: 5, hasTopSection: false, isInDropdown: true },
  { name: 'SHARE_TO_ACCOUNTS', position: 6, hasTopSection: false, isInDropdown: true },
  { name: 'MOVE_TO_FOLDER', position: 7, hasTopSection: true, isInDropdown: true },
  { name: 'REMOVE_FROM_FOLDER', position: 8, hasTopSection: false, isInDropdown: true },
  { name: 'DELETE', position: 9, hasTopSection: true, isInDropdown: true },
]

const headerActionCustomProps: ListPageTableActionCustomProps[] = [
  { name: 'ADD_TO_FAVORITES', position: 0, hasTopSection: false, isInDropdown: false },
  { name: 'MANAGE_TAGS', position: 1, hasTopSection: false, isInDropdown: false },
  { name: 'MOVE_TO_FOLDER', position: 2, hasTopSection: false, isInDropdown: false },
  { name: 'REMOVE_FROM_FOLDER', position: 3, hasTopSection: false, isInDropdown: false },
  { name: 'SHARE_TO_CATALOG', position: 4, hasTopSection: false, isInDropdown: false },
  { name: 'DELETE', position: 5, hasTopSection: false, isInDropdown: true },
]

const onCustomTableAction = (
  customTableAction: FormTemplatesCustomTableActions,
  update: Update,
  listPageValues: ListPageCommonState,
  hasReactWrapper: boolean
) => {
  const { selectedRows } = listPageValues
  if (!selectedRows.length) {
    return
  }
  const selectedRowId = selectedRows[0].externalId ?? ''
  const showCustomModal = (action: FormTemplatesCustomTableActions) => update({ customTableAction: action, showCustomModal: true })

  const customAction: { [key in FormTemplatesCustomTableActions]: () => void } = {
    [FormTemplatesCustomTableActions.EDIT]: () => {
      openFormBuilderWindow(`editFormTemplate-${selectedRowId}`, `${selectedRowId}`, 'istemplate=true', hasReactWrapper)
    },
    [FormTemplatesCustomTableActions.CREATE_FORM]: () => {
      openFormBuilderWindow('createFormFromTemplate', 'blank', `template=${selectedRowId}`, hasReactWrapper)
    },
    [FormTemplatesCustomTableActions.CREATE_FROM_FORM_PICKER]: () => showCustomModal(FormTemplatesCustomTableActions.CREATE_FROM_FORM_PICKER),
    [FormTemplatesCustomTableActions.CREATE_FROM_FORM_OPTIONS]: () => showCustomModal(FormTemplatesCustomTableActions.CREATE_FROM_FORM_OPTIONS),
  }
  customAction[customTableAction]()
}

const getCustomRowActions = (tableActions: TableActions, t: Function, canCreate: boolean): RowAction[] => [
  {
    label: t('Edit'),
    icon: SvgNames.pencil,
    hidden: !canCreate,
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(FormTemplatesCustomTableActions.EDIT, row),
    ...getActionProps(FormTemplatesCustomTableActions.EDIT, rowActionCustomProps),
  },
  {
    label: t('Create form from template'),
    icon: SvgNames.appendedList,
    hidden: !canCreate,
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(FormTemplatesCustomTableActions.CREATE_FORM, row),
    ...getActionProps(FormTemplatesCustomTableActions.CREATE_FORM, rowActionCustomProps),
  },
]

const clickableColumnOptions = {
  colIndex: 0,
  action: {},
}

const renderItemNameVal = (svgNames: SvgNames | null, name: string, search: string, rootClass: string) => (
  <div className={`${rootClass}__search-name`}>
    {svgNames && <Svg name={svgNames} type={SvgType.MEDIUM} />}
    <TextWithTooltipOnEllip typographyProps={{ text: search ? renderBoldTextOnMatch(name, search) : name }} tooltipProps={{ children: name }} />
  </div>
)

const renderSearchColumns = (
  searchInAllItems: boolean | undefined,
  currentFolder: FolderData,
  search: string,
  folders: FolderData[],
  t: Function
): ColumnDefWithAdditionalProps<ItemDto & { updatedAt: number }>[] => [
  {
    header: 'Form Title',
    accessorKey: 'name',
    textAlign: 'left',
    cell: (row) => renderItemNameVal(null, row.getValue<string>(), search, 'list-page-table-container'),
  },
  {
    header: 'Tags',
    accessorKey: 'tags',
    textAlign: 'left',
    maxSize: 150,
    disableSorting: true,
    enableCustomCellValue: true,
  },
  {
    header: 'Location',
    accessorKey: 'folderId',
    textAlign: 'left',
    maxSize: 150,
    cell: (row) =>
      renderPathToFolder(ItemType.FORM, row.getValue<number>(), folders, searchInAllItems, 'list-page-table-container', t, currentFolder),
  },
  {
    header: 'Last Updated',
    accessorKey: 'updatedAt',
    textAlign: 'left',
    maxSize: 150,
    cell: ({
      row: {
        original: { updatedAt },
      },
    }) => renderRelativeDate(relativeDate(updatedAt, true, false), getStandardFormattedDate(updatedAt)),
  },
]

export const tableProps = (t: Function, canCreate: boolean, hasReactWrapper: boolean): TableProps => ({
  columns: tableV2Columns(t),
  hasAutoSelectedRows: false,
  hasExpander: false,
  rowActionCustomProps,
  renderSearchColumns: (searchInAllItems: boolean | undefined, currentFolder: FolderData, search: string, folders: FolderData[]) =>
    renderSearchColumns(searchInAllItems, currentFolder, search, folders, t),
  headerActionCustomProps,
  listPage: 'FormTemplates',
  getCustomRowActions: (tableActions) => getCustomRowActions(tableActions, t, canCreate),
  onCustomTableAction: (customAction, update, listPageValues, _selectedItem) =>
    onCustomTableAction(customAction, update, listPageValues, hasReactWrapper),
  clickableColumnOptions,
  actonAssetType: 'FORM_TEMPLATE',
  shareModalText: '',
})

const tableV2Columns = (t: Function): ColumnDefWithAdditionalProps<ItemDto, any>[] => [
  {
    header: t('Template Title'),
    accessorKey: 'name',
    textAlign: 'left',
    cell: (cell: CellContext<ItemDto, string>) => renderItemNameVal(null, cell.getValue(), '', 'list-page-table-container'),
  },
  {
    header: t('Tags'),
    accessorKey: 'tags',
    textAlign: 'left',
    maxSize: 180,
    disableSorting: true,
    enableCustomCellValue: true,
  },
  {
    header: t('created Date'),
    accessorKey: 'createdAt',
    textAlign: 'left',
    fieldType: 'TIMESTAMP',
    maxSize: 150,
    cell: (cell: CellContext<ItemDto, number>) =>
      renderRelativeDate(relativeDate(cell.getValue(), true, false), getStandardFormattedDate(cell.getValue())),
  },
  {
    header: t('Last Updated'),
    accessorKey: 'updatedAt',
    textAlign: 'left',
    fieldType: 'TIMESTAMP',
    maxSize: 150,
    cell: (cell: CellContext<ItemDto, number>) =>
      renderRelativeDate(relativeDate(cell.getValue(), true, false), getStandardFormattedDate(cell.getValue())),
  },
]
