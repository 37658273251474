import { gql } from '@apollo/client'

export default gql`
  mutation updateImportSyncJob($importSyncJobDTO: ImportSyncJobDTOInput) {
    updateImportSyncJob(importSyncJobDTO: $importSyncJobDTO) {
      accountId
      createdTime
      createdTimeExecutionOffsetDateTime
      cron {
        dayOfMonth
        dayOfWeek
        everyHour
        hours
        minutes
        month
        seconds
        year
      }
      cronString
      enabled
      filePath
      hasToRunNow
      importDefinitionId
      importSyncJobExecutionDTOS {
        checkpointRow
        executionEnd
        executionEndOffsetDateTime
        executionStart
        executionStartOffsetDateTime
        importFileId
        importSyncJobExecutionId
        importSyncJobId
        invalidRows
        isRunning
        lastRowProcessed
        logErrorMessage
        processedBy
        purgatoryS3PathFile
        rejectHardBounces
        rejectOptOut
        rejectSuppressedDomains
        status
        statusMessage
        validRows
      }
      importSyncJobId
      isRunning
      lastExecution
      lastExecutionOffsetDateTime
      nextExecution
      nextExecutionOffsetDateTime
      scheduleTimeZone
      serviceErrorMessage
      status
      statusMessage
      updatedTime
      updatedTimeExecutionOffsetDateTime
    }
  }
`
