import React from 'react'

import { getFolderById } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { ListingPageItem, ListPageAPI, ListPageCommonState, SetError, SetStatusToast } from '@complex/ListingPage/Context/ListingPageCommon.context'
import DuplicateFolderModal from '@components/DuplicateModal/DuplicateFolderModal'
import { Status } from '@components/StatusToast/StatusToast'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { AddTagMutation, ItemDto, LabelDto, LabelDtoInput } from '@graphql/types/microservice/categorization-types'
import { CreateLandingPageFromEmailTemplateMutation, CreateLandingPageFromEmailTemplateMutationVariables } from '@graphql/types/mutation-types'
import { Folder } from '@interface/Folder'
import { EmailTemplatesCustomTableActions } from '@src/pages/listingPages/EmailTemplates/utils/EmailTemplatesListingPage.tables'
import { ItemType } from '@utils/categorization'
import { rootContext } from '@utils/const/globals'
import { logNewRelicError } from '@utils/new-relic.utils'
import { FetchPromise } from '@utils/types'

export type EmailTemplatesCustomTableActionsWithModals = Exclude<EmailTemplatesCustomTableActions, EmailTemplatesCustomTableActions.EDIT>

interface createLandingPageFromTemplateVariables {
  selectedRow: ListingPageItem
  requestVariables: CreateLandingPageFromEmailTemplateMutationVariables
  createLandingPageFromTemplateRequest: (
    variables: CreateLandingPageFromEmailTemplateMutationVariables
  ) => FetchPromise<CreateLandingPageFromEmailTemplateMutation>
  secondaryFolders?: Folder[]
}

interface RenderCreateAsLandingPageModalVariables {
  selectedRow: ListingPageItem
  listPageValues: ListPageCommonState
  listPageAPI: ListPageAPI
  createLandingPageFromTemplateRequest: (
    variables: CreateLandingPageFromEmailTemplateMutationVariables
  ) => FetchPromise<CreateLandingPageFromEmailTemplateMutation>
  createTagRequest: (type: string, tag: LabelDtoInput) => FetchPromise<AddTagMutation>
}

export const createLandingPageFromTemplate = async (
  variables: createLandingPageFromTemplateVariables,
  setStatusToast: SetStatusToast,
  setError: SetError
) => {
  const { requestVariables, createLandingPageFromTemplateRequest, secondaryFolders, selectedRow } = variables

  const selectedFolder = secondaryFolders ? getFolderById(requestVariables.folderId, secondaryFolders) : undefined

  const isBeeComposer = selectedRow?.beeComposer

  const { data, errors } = await createLandingPageFromTemplateRequest(requestVariables)

  if (data && data.createLandingPageFromEmailTemplate) {
    const { status, newPageId } = data.createLandingPageFromEmailTemplate

    if (status === 'ok') {
      setStatusToast(
        <Typography
          text={
            selectedFolder?.name
              ? 'ListPage.EmailTemplates.CreateLandingPageFromTemplate.Success'
              : 'ListPage.EmailTemplates.CreateLandingPageFromTemplate.Without.Folder.Success'
          }
          inline
          values={{
            itemName: requestVariables.newName,
            folderName: selectedFolder?.name,
          }}
          tagProps={{ bold: { weight: TextWeight.BOLD } }}
        />,
        Status.SUCCESS
      )
      if (isBeeComposer) {
        window.open(`${rootContext}/content/landingPage/${newPageId}/settings`, '_blank')?.focus()
      }
    } else {
      setError('ListPage.EmailTemplates.CreateLandingPageFromTemplate.Fail', Status.FAIL)
    }
  }

  if (errors) {
    setError('ListPage.EmailTemplates.CreateLandingPageFromTemplate.Fail', Status.FAIL)
    logNewRelicError(errors)
  }
}

export const createLandingPageTag = async (
  tag: LabelDtoInput,
  createTagRequest: (type: string, tag: LabelDtoInput) => FetchPromise<AddTagMutation>,
  listPageAPI: ListPageAPI
) => {
  const { errors } = await createTagRequest(`${ItemType.LANDING_PAGE}`, tag)
  const { update, setStatusToast, setError } = listPageAPI

  if (errors) {
    setError(`ListPage.Common.Tags.${tag.id ? 'Updated' : 'Added'}.Error`, Status.FAIL)
  } else {
    update({ fetchTags: true })
    setStatusToast(`ListPage.Common.Tags.${tag.id ? 'Updated' : 'Added'}`, Status.SUCCESS)
  }
}

export const renderCreateAsLandingPageModal = (variables: RenderCreateAsLandingPageModalVariables, enableLandingPageSectionReact: boolean) => {
  const { listPageAPI, listPageValues, createLandingPageFromTemplateRequest, createTagRequest, selectedRow } = variables
  const { update, setStatusToast, setError } = listPageAPI
  const { secondaryTags, secondaryFolders } = listPageValues
  const row = selectedRow as ItemDto
  const selectedRowId = row?.externalId ?? '-1'
  const defaultFolder = secondaryFolders?.find((folder) => folder.name === 'Default Folder')

  return (
    <DuplicateFolderModal
      headerTitleKey="ListPage.EmailTemplates.CreateLandingPageFromTemplate.Title"
      folders={secondaryFolders ?? []}
      tags={secondaryTags ?? []}
      // temporarily hide tags until new landing page listing page is enabled
      hideTags={!enableLandingPageSectionReact}
      namePlaceholder="ListPage.EmailTemplates.CreateLandingPageFromTemplate.NamePlaceholder"
      itemName={selectedRow.name ?? ''}
      defaultName={selectedRow.name ?? ''}
      hideIcon
      descriptionKey="ListPage.EmailTemplates.CreateLandingPageFromTemplate.Description"
      onSubmitText="Create landing page"
      activeFolderId={!enableLandingPageSectionReact && defaultFolder ? defaultFolder.id : undefined}
      onClone={(newName, tags, folderId) =>
        createLandingPageFromTemplate(
          {
            selectedRow,
            requestVariables: { id: selectedRowId, newName, tags, folderId },
            createLandingPageFromTemplateRequest,
            secondaryFolders,
          },
          setStatusToast,
          setError
        )
      }
      onTagCreate={(tag: LabelDto) => createLandingPageTag(tag, createTagRequest, listPageAPI)}
      onCancel={() => update({ showCustomModal: false })}
    />
  )
}
