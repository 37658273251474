import React, { FC, useContext, useMemo } from 'react'

import { ButtonType } from '@components/Button'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { TableV2 } from '@components/TableV2/TableV2'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { useTranslation } from '@const/globals'
import {
  getVisitorAlertsColumns,
  getVisitorAlertsRowActions,
} from '@src/pages/WebsiteProspectorAlerts/components/VisitorAlerts/components/VisitorAlertsTable/VIsitorAlertsTable.utils'
import { Alert, WebsiteProspectorContext } from '@src/pages/WebsiteProspectorAlerts/WebsiteProspectorAlertsContext'
import { Row } from '@tanstack/react-table'
import { useAccountSettings } from '@utils/account/account.utils'

import './VisitorAlertsTable.css'

interface VisitorAlertsTableProps {
  className?: string
  dataTest?: string
  onRowClicked: (row: Row<Alert>) => void
}

const rootClass = 'visitor-alerts-table'

const VisitorAlertsTable: FC<VisitorAlertsTableProps> = (props: VisitorAlertsTableProps) => {
  const { dataTest = rootClass, onRowClicked } = props
  const { t } = useTranslation()
  const {
    userSettings: { isAdministrator },
    userId,
  } = useAccountSettings()
  const {
    values: { alerts },
    update,
    onEnableChange,
    onMuteChange,
  } = useContext(WebsiteProspectorContext)
  const onDelete = (alert: Alert) => {
    update({ alertToRemove: alert })
  }
  const columns: ColumnDefWithAdditionalProps<Alert>[] = useMemo(() => getVisitorAlertsColumns(t, userId, onEnableChange, onMuteChange), [alerts])

  const rowActions = useMemo(() => getVisitorAlertsRowActions(isAdministrator, userId, onEnableChange, onMuteChange, onDelete), [alerts])

  return (
    <TableV2
      stickyColumns={['name', 'status']}
      data={alerts}
      columns={columns}
      rowActions={rowActions}
      data-test={dataTest}
      withoutBorders
      emptyState={{
        headline: 'No visitor alerts',
        text: 'Create alerts to receive email notifications to your inbox when people matching your criteria visit your website.',
        buttonText: 'Create alert',
        buttonPlusIcon: true,
        buttonType: ButtonType.PRIMARY,
        imgSrc: StaticImageNames.emptyAlerts,
        buttonOnClick: () => update({ showCreateAlertModal: true }),
        className: `${rootClass}__empty`,
      }}
      onRowClicked={(row) => onRowClicked(row)}
    />
  )
}

export default VisitorAlertsTable
