import React, { FC, memo } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import classNames from 'classnames'

import Svg from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { useTranslation } from '@const/globals'
import { THEME } from '@utils/theme'

import './poweredBy.css'

const rootClass = 'powered-by'

interface PoweredByProps {
  newLogo: boolean
  isCustomLogo: boolean
  hidePoweredBy: boolean
  theme: THEME
  expanded: boolean
  navFontColor?: string
}

export const renderPoweredBy = (newLogo: boolean, isCustomLogo: boolean, hidePoweredBy: boolean, expanded: boolean) =>
  newLogo && isCustomLogo && !hidePoweredBy && expanded

export const PoweredBy: FC<PoweredByProps> = (props: PoweredByProps) => {
  const { newLogo, isCustomLogo, hidePoweredBy, theme, expanded, navFontColor } = props
  const { t } = useTranslation()

  if (!renderPoweredBy(newLogo, isCustomLogo, hidePoweredBy, expanded)) return null

  let custom = null
  if (theme === THEME.CUSTOM) {
    custom = (
      <HelmetProvider>
        <Helmet>
          <style type="text/css">
            {`.powered-by { color: ${navFontColor}; }
            .powered-by__svg { fill: ${navFontColor}; }`}
          </style>
        </Helmet>
      </HelmetProvider>
    )
  }

  return (
    <>
      {custom}
      <div className={classNames(rootClass)}>
        {t('POWERED BY')}
        <Svg className={`${rootClass}__svg`} name={SvgNames.logoWhite} />
      </div>
    </>
  )
}

export default memo(PoweredBy)
