import React, { FC } from 'react'

import classNames from 'classnames'

import { ButtonType } from '@components/Button'
import Container from '@components/Container'
import Radio from '@components/Radio'
import RadioGroup from '@components/RadioGroup'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ProgramCopyStep } from '@graphql/types/query-types'
import ListProgramSourceSelector from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramDetails/components/ListProgramSources/components/ListProgramSourceSelector/ListProgramSourceSelector'
import StepSourceSelector from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/components/ListProgramStep/components/StepSourceSelector/StepSourceSelector'
import { StepErrors } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/components/ListProgramStep/utils/ListProgramStep.utils'
import { ProgramStepType } from '@utils/program/program.constants'

import './CopyToListStepEditor.css'

interface CopyToListStepEditorProps {
  className?: string
  dataTest?: string
  step: ProgramCopyStep
  onChange: (step: Partial<ProgramCopyStep>) => void
  stepErrors?: StepErrors
}

const rootClass = 'copy-to-list-step-editor'

const CopyToListStepEditor: FC<CopyToListStepEditorProps> = (props: CopyToListStepEditorProps) => {
  const { dataTest = rootClass, className = '', step, onChange, stepErrors } = props
  const { update = false, listId = '', listName = '', listRecordCount = 0, not = false, srcId = '', srcName = '', srcRecordCount = 0 } = step

  const { missingList = false } = { ...stepErrors }

  const { t } = useTranslation()

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <StepSourceSelector
        initiallyShowListPicker={!!srcId}
        noSourceRadioText={t('Copy contact')}
        onChange={(step, all) => onChange({ ...step, all })}
        selectSourceRadioText={t('Copy only if contact')}
        sourceId={{ srcId }}
        sourceName={{ srcName }}
        recordsCount={{ srcRecordCount }}
        notInSegment={not}
        hasSelect
        showErrorOnUnselectedSource={false}
      />
      <Container>
        <Typography text={t('Select list to add to')} />
        <ListProgramSourceSelector
          className={`${rootClass}__source-selector`}
          onSubmit={(list) => onChange({ listId: list?.id, listName: list?.name ?? '', listRecordCount: list?.size })}
          buttonType={ButtonType.SECONDARY}
          defaultSource={listId ? { id: listId, name: listName, baseId: '', count: listRecordCount } : undefined}
          isEditing
          showErrorOnUnselectedSource={missingList}
          isListsOnly
        />
        {step.stepType === ProgramStepType.COPY && (
          <RadioGroup verticalLayout>
            <Radio
              dataTest={`${dataTest}-append-copies`}
              checked={!update}
              onChange={() => onChange({ update: false })}
              label={t('Append copies to the list')}
            />
            <div className={`${rootClass}__matching-email`}>
              <Radio
                dataTest={`${dataTest}-update-records`}
                checked={update}
                onChange={() => onChange({ update: true })}
                label={t('Update records with matching email address')}
              />
              <Tooltip trigger={<Svg name={SvgNames.info} type={SvgType.LARGER_ICON} />}>
                {t('Existing values will be overwritten with non-blank values from source')}
              </Tooltip>
            </div>
          </RadioGroup>
        )}
      </Container>
    </div>
  )
}

export default CopyToListStepEditor
