import importEntity from '@graphql/microservices/entity-upload/importEntity'
import importList from '@graphql/microservices/entity-upload/importList'
import {
  ImportEntityMutation,
  ImportEntityMutationVariables,
  ImportListMutation,
  ImportListMutationVariables,
} from '@graphql/types/microservice/entity-upload-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export type ImportListRequestType = (variables: ImportListMutationVariables) => FetchPromise<ImportListMutation>
export type ImportEntityRequestType = (variables: ImportEntityMutationVariables) => FetchPromise<ImportEntityMutation>

export interface ImportContactsEntityUploadRequests {
  importListRequest: ImportListRequestType
  importEntityRequest: ImportEntityRequestType
}

export const useImportContactsEntityUploadRequests = (): ImportContactsEntityUploadRequests => {
  const { client: entityUploadClient } = useMicroserviceClient({ serviceName: MicroserviceClients.ENTITY_UPLOAD })

  const importListRequest = async (variables: ImportListMutationVariables) =>
    await entityUploadClient.mutate<ImportListMutation, ImportListMutationVariables>({
      mutation: importList,
      variables,
    })

  const importEntityRequest = async (variables: ImportEntityMutationVariables) =>
    await entityUploadClient.mutate<ImportEntityMutation, ImportEntityMutationVariables>({
      mutation: importEntity,
      variables,
    })

  return {
    importListRequest,
    importEntityRequest,
  }
}
