import React from 'react'

import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { renderBoldTextOnFirstMatch } from '@utils/typography'

import { SelectV2SingleOption } from './SelectV2.props'

export const renderOptionWithEmail = (data: SelectV2SingleOption, search?: string) => (
  <div className={`selectv2__option-value--subtext-right`}>
    <Typography
      dataTest={'selectv2-option-value'}
      text={search ? renderBoldTextOnFirstMatch({ search, value: data.label }) : data.label}
      weight={TextWeight.MEDIUM_LIGHT}
      inline
    />
    {data.subText && <Typography text={data.subText} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.ITALIC} />}
  </div>
)
