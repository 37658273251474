import React, { FC, useState } from 'react'

import Button, { ButtonType } from '@components/Button'
import InputWithStatus from '@components/InputWithStatus/InputWithStatus'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { checkEmailValidity, checkMaxCharactersValidity } from '@utils/formUtils'

import { ERASE_CONTACT_VALIDATION_MESSAGES, MAX_CHARACTERS } from '../utils/EraseContact.constants'

import './eraseConfirmInputs.css'

const rootClass = 'erase-contact-container'

interface Props {
  eraseValue: string
  setEraseValue: (value: string) => void
  confirmValue: string
  setConfirmValue: (value: string) => void
  showConfirmation: (show: boolean) => void
  emptyInput: boolean
  setEmptyInput: (value: boolean) => void
}

export enum InputTypes {
  ERASE = 'erase',
  CONFIRM = 'confirm',
}

const EraseConfirmInputs: FC<Props> = ({
  eraseValue,
  setEraseValue,
  confirmValue,
  setConfirmValue,
  showConfirmation,
  emptyInput,
  setEmptyInput,
}: Props) => {
  const { t } = useTranslation()
  const [emailsMatch, setEmailsMatch] = useState(false)

  const onInputChange = (value: string, setValueState: (value: string) => void) => {
    setValueState(value)
    emailsMatch && setEmailsMatch(false)
    emptyInput && setEmptyInput(false)
  }

  const onInputBlur = (value: string, type: InputTypes) => {
    if (value.length > 0 && (type === InputTypes.ERASE ? value === confirmValue : value === eraseValue)) {
      setEmailsMatch(true)
    }
  }

  const checkMaxCharactersValidityWrapper = (maxCharacters: number) => (value: string) => checkMaxCharactersValidity(value, maxCharacters)

  return (
    <>
      <div className={rootClass}>
        <div className={`${rootClass}__block`}>
          <Typography
            className={`${rootClass}__title`}
            text={t('1. Enter contact email address to erase')}
            type={TextType.SECTION_HEADER}
            weight={TextWeight.MEDIUM}
          />
          <Typography
            className={`${rootClass}__info`}
            text={t('The email address of the contact you wish to permanently erase from Act-On.')}
            type={TextType.BODY_TEXT_LIGHT}
            lineHeight={LineHeight.MEDIUM}
          />
          <div className={`${rootClass}__input`}>
            <InputWithStatus
              value={eraseValue}
              validityFunctions={[checkMaxCharactersValidityWrapper(MAX_CHARACTERS)]}
              onChange={(event) => {
                onInputChange(event.target.value, setEraseValue)
                emailsMatch && setEmailsMatch(false)
              }}
              onBlur={(event) => onInputBlur(event.target.value, InputTypes.ERASE)}
              hasCustomError={emailsMatch}
              customTooltipErrorMessages={'These emails cannot match.'}
              tooltipErrorMessages={ERASE_CONTACT_VALIDATION_MESSAGES}
              required
            />
          </div>
          <Typography
            className={`${rootClass}__title`}
            text={t('2. Send proof of deletion')}
            type={TextType.SECTION_HEADER}
            weight={TextWeight.MEDIUM}
          />
          <Typography
            className={`${rootClass}__info`}
            text={t(
              'Receive proof that this contact has been permanently erased. We recommend using an email address that multiple individuals in your organization can access.'
            )}
            type={TextType.BODY_TEXT_LIGHT}
            lineHeight={LineHeight.MEDIUM}
          />
          <div className={`${rootClass}__input`}>
            <InputWithStatus
              value={confirmValue}
              validityFunctions={[checkEmailValidity]}
              onChange={(event) => {
                onInputChange(event.target.value, setConfirmValue)
                emailsMatch && setEmailsMatch(false)
              }}
              onBlur={(event) => onInputBlur(event.target.value, InputTypes.CONFIRM)}
              hasCustomError={emailsMatch}
              customTooltipErrorMessages={'These emails cannot match. Enter a different email address to receive proof of deletion.'}
              tooltipErrorMessages={ERASE_CONTACT_VALIDATION_MESSAGES}
              required
            />
          </div>
          <Button
            buttonType={ButtonType.ERASE}
            disabled={!!checkMaxCharactersValidity(eraseValue, MAX_CHARACTERS) || !!checkEmailValidity(confirmValue) || eraseValue === confirmValue}
            onClick={() => showConfirmation(true)}
          >
            {t('Erase')}
          </Button>
        </div>
      </div>
    </>
  )
}

export default React.memo(EraseConfirmInputs)
