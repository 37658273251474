import React, { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { useApolloClient } from '@apollo/client'
import { MessageType } from '@complex/MessagePickerModal/messagePickerModalUtils'
import Button, { ButtonType } from '@components/Button/Button'
import CreateSegmentModal from '@components/CreateSegmentModal/CreateSegmentModal'
import FormGroup from '@components/FormGroup/FormGroup'
import FormRow from '@components/FormRow/FormRow'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Radio from '@components/Radio/Radio'
import SelectOrCreateItem from '@components/SelectOrCreateItem/SelectOrCreateItem'
import { SelectV2Option, SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import Svg, { SvgType, SvgNames } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import { getUUID, useTranslation } from '@const/globals'
import { Program, ProgramMessage, ProgramSource } from '@graphql/types/query-types'
import { ExitConditionListItem } from '@src/pages/programs/edit/components/ProgramEarlyExitConditions/ProgramEarlyExitConditions'
import ConditionalSelect from '@src/pages/programs/edit/components/ProgramFlow/components/ConditionalSelect/ConditionalSelect'
import ListSelect from '@src/pages/programs/edit/components/ProgramFlow/components/ListSelect/ListSelect'
import { getUpdatedProgramWithAOContactsSource } from '@src/pages/programs/manager/ProgramManager.utils'
import { logError } from '@utils/env'
import {
  getIsListSegment,
  getNewSegment,
  getNewSegmentName,
  getSegmentAndNotValue,
  getUpdatedProgramMessages,
  getUpdatedProgramSources,
  hasAOContactsSource,
} from '@utils/program/program'

import './EarlyExitConditionModal.css'

export interface State {
  exitCondition: ExitConditionListItem
  radioSelection: EXIT_CONDITION
  createLoading: boolean
}

interface Props {
  isOpen: boolean
  program: Program
  listOptions: SelectV2SingleOption[]
  getSegmentOptions: (currentPage: number) => Promise<SelectV2Option[]>
  searchSegment: (search: string) => Promise<SelectV2Option[]>
  defaultOption: SelectV2SingleOption | undefined
  exitCondition: ExitConditionListItem
  errorMessage?: string
  saveCondition(exitCondition: ExitConditionListItem, program?: Program): void
  updateProgram(program: Program): void
  onSubmitCreateSegmentModal: (name: string) => Promise<boolean>
  closeModal: (errorSaving?: boolean) => void
  resetErrorMessage: () => void
  dataTest?: string
}

export function handleFormSubmit(data: any, props: Props, exitCondition: ExitConditionListItem) {
  try {
    const { program, saveCondition } = props
    const { segmentMode, not } = getSegmentAndNotValue(data.segmentMode)
    const isListSegment = getIsListSegment(segmentMode)
    if (!isListSegment && data.newSegment === 'true') {
      const srcId = getNewSegmentName(program.localSegmentDetails)

      const newSegment = getNewSegment(srcId, data.messageIds, segmentMode)
      const localSegmentDetails = [...program.localSegmentDetails, newSegment]

      saveCondition(
        {
          ...exitCondition,
          srcId,
          listId: data.listId ?? '',
          not,
        },
        {
          ...program,
          localSegmentDetails,
        }
      )
    } else {
      saveCondition(
        {
          ...exitCondition,
          srcId: data.srcId,
          listId: data.listId ?? '',
          not,
        },
        program
      )
    }
  } catch (e) {
    logError(e)
    props.closeModal(true)
  }
}

export enum EXIT_CONDITION {
  JUST_EXIT,
  APPEND_LIST,
  COPY_UPDATE,
  APPEND_SEGMENT,
}

const rootClass = 'early-exit-condition-modal'

export const EarlyExitConditionModal: FC<Props> = (props: Props) => {
  const {
    isOpen,
    updateProgram,
    program,
    listOptions,
    defaultOption,
    errorMessage,
    closeModal,
    onSubmitCreateSegmentModal,
    resetErrorMessage,
    getSegmentOptions,
    searchSegment,
    dataTest = 'early-exit-condition-modal',
  } = props

  const { register, handleSubmit, reset, getValues } = useForm()
  const { t } = useTranslation()
  const client = useApolloClient()
  const hasActOnContacts = hasAOContactsSource(program)

  const getDefaultRadioSelection = () => {
    if (props.exitCondition.update) {
      return hasActOnContacts ? EXIT_CONDITION.APPEND_SEGMENT : EXIT_CONDITION.COPY_UPDATE
    } else {
      return props.exitCondition.listId.length > 0 ? EXIT_CONDITION.APPEND_LIST : EXIT_CONDITION.JUST_EXIT
    }
  }

  const [state, setState] = useState<State>({
    exitCondition: props.exitCondition,
    radioSelection: getDefaultRadioSelection(),
    createLoading: false,
  })
  const { exitCondition, createLoading } = state
  const [showCreateSegmentModal, setShowCreateSegmentModal] = useState<boolean>(false)
  const [selectedOption, setSelectedOption] = useState<SelectV2SingleOption | undefined>(defaultOption)

  const onSubmit = async (data: any) => {
    const listId = state.radioSelection === EXIT_CONDITION.APPEND_SEGMENT ? { listId: selectedOption?.value ?? '' } : {}
    const updatedProgram = listId.listId && selectedOption ? await getUpdatedProgramWithAOContactsSource(client, program, listId.listId) : program
    handleFormSubmit({ ...data, ...listId }, { ...props, program: updatedProgram }, exitCondition)
  }

  const onProgramSourcesUpdate = (sources: ProgramSource[]) => {
    updateProgram(getUpdatedProgramSources(program, sources))
  }

  const onMessageUpdate = (messages: ProgramMessage) => {
    updateProgram(getUpdatedProgramMessages(program, [messages]))
  }

  useEffect(() => {
    setSelectedOption(defaultOption)
  }, [defaultOption])

  const submitId = getUUID()

  const getListSelect = () => (
    <ListSelect
      srcId={exitCondition.listId}
      program={program}
      register={register}
      name="listId"
      onProgramSourcesUpdate={onProgramSourcesUpdate}
      isListsOnly
      className={`${rootClass}__select`}
    />
  )

  const onAddSegment = async (name: string) => {
    setState({ ...state, createLoading: true })
    const result = await onSubmitCreateSegmentModal(name)
    setState({ ...state, createLoading: false })
    if (result) {
      setShowCreateSegmentModal(false)
    }
  }

  const getAppendSegment = () => (
    <SelectOrCreateItem
      options={listOptions}
      placeholder={t('Select')}
      value={selectedOption}
      showCreateItemModal={showCreateSegmentModal}
      setShowCreateItemModal={(show: boolean) => {
        setShowCreateSegmentModal(show)
        resetErrorMessage()
      }}
      loadNewOptions={(nextPage) => getSegmentOptions(nextPage)}
      searchOptions={searchSegment}
      insideModal
      setSelectedOption={setSelectedOption}
      buttonText={t('New segment')}
      createItemModal={
        <CreateSegmentModal
          isOpen={showCreateSegmentModal}
          errorMessage={errorMessage}
          loading={createLoading}
          closeModal={() => setShowCreateSegmentModal(false)}
          onSubmit={onAddSegment}
        />
      }
    />
  )

  return (
    <Modal
      isOpen={isOpen}
      header={<ModalHeader headerType={ModalHeaderType.Form}>{t('Configure Early Exit Condition')}</ModalHeader>}
      className={rootClass}
    >
      <ModalBody className={`${rootClass}__modal-body`}>
        <form data-test={dataTest} onSubmit={handleSubmit(onSubmit)}>
          <FormGroup className="inset-up-none">
            <ConditionalSelect
              step={exitCondition}
              dataTest={`${dataTest}-conditional-select`}
              register={register}
              formData={getValues}
              formReset={reset}
              program={program}
              onProgramSourcesUpdate={onProgramSourcesUpdate}
              onMessageUpdate={onMessageUpdate}
              allowedMessageCategories={[MessageType.SENT, MessageType.SCHEDULED, MessageType.PROGRAM]}
              disableAddMessage
            />
          </FormGroup>
          <FormRow>
            <Radio
              dataTest={`${dataTest}-no-update-radio`}
              className={`${rootClass}__radio`}
              label={t('Only exit contact from the program')}
              checked={state.radioSelection === EXIT_CONDITION.JUST_EXIT}
              onChange={(event) => {
                if (event.target.checked) {
                  setState({
                    ...state,
                    exitCondition: {
                      ...exitCondition,
                      update: false,
                    },
                    radioSelection: EXIT_CONDITION.JUST_EXIT,
                  })
                }
              }}
            />
            {!hasActOnContacts && (
              <Radio
                dataTest={`${dataTest}-update-radio-false`}
                className={`${rootClass}__radio`}
                withTitle={false}
                label={t('Exit contact AND append contact to a list')}
                labelChildren={
                  <Tooltip trigger={<Svg name={SvgNames.info} type={SvgType.ICON} />}>
                    {t('Appends contact to the list even if they are already in the list')}
                  </Tooltip>
                }
                checked={state.radioSelection === EXIT_CONDITION.APPEND_LIST}
                onChange={(event) => {
                  if (event.target.checked) {
                    setState({
                      ...state,
                      exitCondition: {
                        ...exitCondition,
                        update: false,
                      },
                      radioSelection: EXIT_CONDITION.APPEND_LIST,
                    })
                  }
                }}
              />
            )}
            {state.radioSelection === EXIT_CONDITION.APPEND_LIST && getListSelect()}
            {!hasActOnContacts && (
              <Radio
                dataTest={`${dataTest}-update-radio-true`}
                className={`${rootClass}__radio`}
                withTitle={false}
                label={t('Exit contact AND copy/update contact to a list')}
                labelChildren={
                  <Tooltip trigger={<Svg name={SvgNames.info} type={SvgType.ICON} />}>
                    {t('Adds a contact to the list or updates their information if they are already in the list')}
                  </Tooltip>
                }
                checked={state.radioSelection === EXIT_CONDITION.COPY_UPDATE}
                onChange={(event) => {
                  if (event.target.checked) {
                    setState({
                      ...state,
                      exitCondition: {
                        ...exitCondition,
                        update: true,
                      },
                      radioSelection: EXIT_CONDITION.COPY_UPDATE,
                    })
                  }
                }}
              />
            )}
            {exitCondition.update && !hasActOnContacts && getListSelect()}
            {hasActOnContacts && (
              <Radio
                dataTest={`${dataTest}-append-radio-true`}
                className={`${rootClass}__radio`}
                withTitle={false}
                label={t('Exit contact AND append contact to a segment')}
                labelChildren={
                  <Tooltip trigger={<Svg name={SvgNames.info} type={SvgType.ICON} />}>
                    {t('Appends contact to the segment if they are not already in that segment')}
                  </Tooltip>
                }
                checked={state.radioSelection === EXIT_CONDITION.APPEND_SEGMENT}
                onChange={(event) => {
                  if (event.target.checked) {
                    setState({
                      ...state,
                      exitCondition: {
                        ...exitCondition,
                        update: true,
                      },
                      radioSelection: EXIT_CONDITION.APPEND_SEGMENT,
                    })
                  }
                }}
              />
            )}
            {exitCondition.update && hasActOnContacts && getAppendSegment()}
          </FormRow>
          <button type="submit" id={submitId} hidden />
        </form>
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form}>
        <Button buttonType={ButtonType.TERTIARY} onClick={() => closeModal()} dataTest={`${dataTest}-close-button`}>
          {t('Cancel')}
        </Button>
        <Button
          buttonType={ButtonType.PRIMARY}
          dataTest={`${dataTest}-save-button`}
          onClick={() => {
            document.getElementById(submitId)?.click()
          }}
        >
          {t('Submit')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default EarlyExitConditionModal
