import { gql } from '@apollo/client'

export default gql`
  mutation setAdminPermission($ids: [String], $value: Boolean, $launchApproval: String!) {
    setAdminPermission(ids: $ids, value: $value, launchApproval: $launchApproval) {
      status
      message
      count
      deletedUsersIds
      newUsersIds
      importedUsers
      noImportedUsers
      duplicatedUsers
    }
  }
`
