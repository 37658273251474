import React, { FC, useContext } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Typography, { ModalBodyStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { SMSContext, SMSContextAPI } from '@src/pages/sms/context/SMSContext'
import MessageComposer from '@src/pages/sms/edit/components/MessageComposer/MessageComposer'

import './WelcomeMessageEditor.css'

type Props = {
  closeModal: () => void
  className?: string
  dataTest?: string
}

const Templates = {
  ENTHUSIASTIC: {
    label: 'enthusiastic',
    text: `Thanks for joining SMS from COMPANY! We're glad you signed up, and will only send you X txt per [mo/wk]. Txt STOP to OptOut`,
  },
  MINIMALIST: {
    label: 'minimalist',
    text: `You signed up with COMPANY for SMS.  Up to X txts per [wk/mo]. Txt STOP to OptOut`,
  },
  LEGALESE: {
    label: 'legalese',
    text: `Thank you for signing up for SMS from COMPANY. No more than X SMS per [wk/mo]will be sent. Data rates may apply. Txt STOP to OptOut`,
  },
}
const rootClass = 'welcome-message-editor'

const explainerText = [
  {
    bold: 'Choose a warm greeting',
    regular: 'they signed up, make them feel welcome!',
  },
  {
    bold: 'Mention your Brand/Company',
    regular: 'make sure they know who you are',
  },
  {
    bold: 'Cover your bases',
    regular: 'remind them of Data Rates and Frequency',
  },
  {
    bold: `Don't include Opt-Out instructions`,
    regular: 'we take care of this automatically',
  },
]

const MAX_MESSAGE_LENGTH = 160

const WelcomeMessageEditor: FC<Props> = (props: Props) => {
  const { closeModal, dataTest = rootClass, className = '' } = props
  const context = useContext<SMSContextAPI>(SMSContext)
  const { values, sendSMSMessage, saveMessage } = context
  const { t } = useTranslation()

  const insertTemplate = (template: string) => {
    const updatedMsg = { ...values.smsMessage, messageText: template }
    saveMessage(updatedMsg)
    return false
  }

  const renderExplainerText = () => {
    const items = explainerText.map((eText) => (
      <li key={eText.bold}>
        <Typography text={t(eText.bold)} type={TextType.BODY_TEXT} weight={TextWeight.MEDIUM} inline />
        <Typography text={t(eText.regular)} {...ModalBodyStyle} inline />
      </li>
    ))

    return (
      <ul className={`${rootClass}__explainer-list`}>
        {items}
        <li>
          <Typography text={t('Try out a welcome template')} type={TextType.BODY_TEXT} weight={TextWeight.MEDIUM} inline />
          <div className={`${rootClass}__templates flex-justify-center`}>
            <Button inline buttonType={ButtonType.TEXT} onClick={() => insertTemplate(Templates.ENTHUSIASTIC.text)}>
              <Typography type={TextType.LINK} text={t(Templates.ENTHUSIASTIC.label)} inline />
            </Button>
            <Button inline buttonType={ButtonType.TEXT} onClick={() => insertTemplate(Templates.MINIMALIST.text)}>
              <Typography type={TextType.LINK} text={t(Templates.MINIMALIST.label)} inline />
            </Button>
            <Button inline buttonType={ButtonType.TEXT} onClick={() => insertTemplate(Templates.LEGALESE.text)}>
              <Typography type={TextType.LINK} text={t(Templates.LEGALESE.label)} inline />
            </Button>
          </div>
        </li>
      </ul>
    )
  }

  const saveDisabled = values.smsMessage.messageText.length === 0

  return (
    <Modal
      className={classNames(rootClass, className)}
      data-test={dataTest}
      header={<ModalHeader headerType={ModalHeaderType.Form}>{t('Welcome Message')}</ModalHeader>}
      isOpen={true}
    >
      <ModalBody>
        <Typography
          text={t('This is the message that contacts will receive when they opt-in to receive SMS messages. Here’s what we recommend:')}
          {...ModalBodyStyle}
          className={`${rootClass}__top-text`}
        />
        {renderExplainerText()}
        <MessageComposer showPersonalizationIcon context={context} showPreview maxMessageLength={MAX_MESSAGE_LENGTH} />
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form}>
        <Button buttonType={ButtonType.TERTIARY} onClick={closeModal} dataTest={`${dataTest}-close-button`}>
          {t('Cancel')}
        </Button>
        <Button
          buttonType={ButtonType.PRIMARY}
          dataTest={`${dataTest}-save-button`}
          onClick={sendSMSMessage}
          disabled={saveDisabled}
          title={saveDisabled ? t('Please enter a message to be sent') : ''}
        >
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default WelcomeMessageEditor
