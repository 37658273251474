import React, { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import TagsInput from 'react-tagsinput'

import classNames from 'classnames'

import { InputTagsErrorsType, TagsSplitFunctionType } from '@components/InputTags/InputTags.utils'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TypographyProps } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './InputTags.css'

interface RenderTagProps {
  tag: string
  onRemove: Function
  key?: number
}

interface Props {
  initialValue?: string[]
  value?: string[]
  onChange?: (tags: string[]) => void
  tagValidation?: (tag: string) => string | undefined
  getTooltipInfo?: (tag: string, index: number) => string | undefined
  onErrorStatusChange?: (errors: InputTagsErrorsType) => void
  errorMessage?: string
  duplicationErrorTooltip?: string
  placeholder?: string
  tagTypographyProps?: Omit<TypographyProps, 'text' | 'inline'>
  errorTypographyProps?: Omit<TypographyProps, 'text'>
  // Mark duplicated tags with error status
  alertDuplication?: boolean
  // Not allow to input duplicated tags
  onlyUnique?: boolean
  duplicationNotCaseSensitive?: boolean
  addOnBlur?: boolean
  showErrorMessage?: boolean
  noMarginBottom?: boolean
  className?: string
  dataTest?: string

  //rest react-tagsInput props

  // default is [InputTagKeys.Tab, InputTagKeys.Enter]
  addKeys?: number[] | string[]
  // default is [InputTagKeys.Backspace]
  removeKeys?: number[]
  // default splits by ' '. will be true, if pasteSplit function or splitRegex passed
  addOnPaste?: boolean
  pasteSplitRegExp?: RegExp
  pasteSplitFn?: TagsSplitFunctionType
}

function renderLayout(tagComponents: React.ReactElement[], inputComponent: React.ReactElement) {
  return (
    <>
      {tagComponents}
      {inputComponent}
    </>
  )
}

const rootClass = 'input-tags'

const InputTags: FC<Props> = (props: Props) => {
  const {
    initialValue = [],
    value,
    onChange,
    tagValidation,
    getTooltipInfo,
    onErrorStatusChange,
    errorMessage,
    duplicationErrorTooltip,
    placeholder,
    tagTypographyProps = {},
    errorTypographyProps = {},
    alertDuplication = true,
    onlyUnique = false,
    duplicationNotCaseSensitive = false,
    addOnBlur = true,
    showErrorMessage = false,
    noMarginBottom,
    addOnPaste,
    pasteSplitRegExp,
    pasteSplitFn,
    className,
    dataTest = rootClass,
    ...restProps
  } = props
  const [tags, setTags] = useState<string[]>(initialValue)
  const [hasError, setHasError] = useState<boolean>(false)
  const showError = useMemo<boolean>(() => showErrorMessage || hasError, [showErrorMessage, hasError])
  const { t } = useTranslation()
  const errorsRef = useRef<{ prev: InputTagsErrorsType; next: InputTagsErrorsType }>({
    prev: { hasDuplicationError: false, hasValidationError: false },
    next: { hasDuplicationError: false, hasValidationError: false },
  })
  const tagsRef = useRef<{ tag: string; hasDuplicationError: boolean; validationErrorMsg?: string; tooltipInfo?: string }[]>([])

  const renderTag = useCallback<(props: RenderTagProps) => ReactNode>(
    ({ tag, key, onRemove }) => {
      const tagInfo = tagsRef.current[key as number]
      if (!tagInfo) {
        return null
      }
      const { validationErrorMsg, hasDuplicationError, tooltipInfo } = tagInfo
      const errorMsg: string | undefined =
        validationErrorMsg || (hasDuplicationError ? duplicationErrorTooltip ?? t('Input.Tag.Duplication.Error.Msg') : undefined)
      const hasError = !!errorMsg
      const renderTooltip = hasError || !!tooltipInfo
      const tagTrigger = (
        <div
          role={'button'}
          tabIndex={0}
          onKeyDown={(keyDownEvent) => (keyDownEvent.key === ' ' ? onRemove(key) : undefined)}
          className={classNames(`${rootClass}__tag`, { [`${rootClass}__tag-error`]: hasError })}
          onClick={() => onRemove(key)}
          data-test={`${dataTest}-tag`}
        >
          {hasError && <Svg name={SvgNames.inputStatusInvalid} type={SvgType.LARGER_ICON} />}
          <Typography text={tag} {...tagTypographyProps} inline />
          <span className={`${rootClass}__remove-icon`}>
            <Svg name={SvgNames.close} type={SvgType.SMALL_ICON} />
          </span>
        </div>
      )
      return renderTooltip ? (
        <Tooltip key={key} alignTextCenter trigger={tagTrigger}>
          {errorMsg ?? tooltipInfo}
        </Tooltip>
      ) : (
        tagTrigger
      )
    },
    [tagTypographyProps, duplicationErrorTooltip, t, dataTest]
  )

  const handleChange = useCallback<(tags: string[]) => void>(
    (tags) => {
      const applyDuplicationError = !onlyUnique && alertDuplication
      tagsRef.current = []
      const trimmedTags = tags.map((_tag) => _tag.trim())
      onChange && onChange(trimmedTags)
      setTags(trimmedTags)
      trimmedTags.forEach((tag, index) => {
        const validationErrorMsg: string | undefined = tagValidation && tagValidation(tag)
        const tooltipInfo: string | undefined = getTooltipInfo && getTooltipInfo(tag, index)
        const hasDuplicationError: boolean =
          applyDuplicationError && tags.findIndex((t) => (duplicationNotCaseSensitive ? t.toLowerCase() === tag.toLowerCase() : t === tag)) !== index
        tagsRef.current[index] = { tag, validationErrorMsg, hasDuplicationError, tooltipInfo }

        // Tracking if there is a duplication or validation error on single tag
        errorsRef.current.next.hasDuplicationError = errorsRef.current.next.hasDuplicationError || hasDuplicationError
        errorsRef.current.next.hasValidationError = errorsRef.current.next.hasValidationError || !!validationErrorMsg
      })
      if (
        errorsRef.current.prev.hasValidationError !== errorsRef.current.next.hasValidationError ||
        errorsRef.current.prev.hasDuplicationError !== errorsRef.current.next.hasDuplicationError
      ) {
        onErrorStatusChange && onErrorStatusChange(errorsRef.current.next)
      }
      setHasError(errorsRef.current.next.hasValidationError || errorsRef.current.next.hasDuplicationError)

      errorsRef.current.prev = errorsRef.current.next
      errorsRef.current.next = { hasDuplicationError: false, hasValidationError: false }
    },
    [tagValidation, onlyUnique, alertDuplication, onErrorStatusChange, getTooltipInfo, onChange, duplicationNotCaseSensitive]
  )

  useEffect(() => handleChange(initialValue), [])
  useEffect(() => value && handleChange(value), [value, handleChange])

  const renderInput = useCallback(
    ({ addTag, ...props }: any) => {
      return <input type="text" data-test={`${dataTest}-input`} {...props} placeholder={placeholder} className={`${rootClass}__input`} />
    },
    [dataTest, placeholder]
  )

  const handlePasteSplit = useMemo<TagsSplitFunctionType>(
    () => pasteSplitFn || (pasteSplitRegExp ? (data: string) => data.split(pasteSplitRegExp).map((tag) => tag.trim()) : undefined),
    [pasteSplitFn, pasteSplitRegExp]
  )

  return (
    <div className={classNames(rootClass, className, { [`${rootClass}__no-margin-bottom`]: noMarginBottom || showError })} data-test={dataTest}>
      <TagsInput
        value={tags}
        onChange={handleChange}
        renderTag={renderTag}
        renderInput={renderInput}
        renderLayout={renderLayout}
        onlyUnique={onlyUnique}
        addOnBlur={addOnBlur}
        addOnPaste={addOnPaste || !!handlePasteSplit}
        pasteSplit={handlePasteSplit}
        className={`${rootClass}__content`}
        {...restProps}
      />
      {showError && (
        <Typography
          text={errorMessage}
          type={TextType.VALIDATION_ERROR}
          className={`${rootClass}__error-msg`}
          dataTest={`${dataTest}-error-msg`}
          {...errorTypographyProps}
        />
      )}
    </div>
  )
}

export default InputTags
