import React, { FC } from 'react'

import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import ComposerConditionToggleCard from '@src/pages/EmailComposer/components/EmailComposerPreview/components/ComposerConditionToggleCard/ComposerConditionToggleCard'
import { findFieldOperator } from '@src/pages/EmailComposer/EmailModals/components/DynamicContent/components/DisplayConditions/DisplayConditions.operators'
import { DynamicContentListItem } from '@utils/composer/context/EmailComposer.context'
import { useTranslation } from '@utils/const/globals'

import './DynamicContentList.css'

interface DynamicContentListProps {
  html: string
  list: DynamicContentListItem[]
  plainTextParts?: string[]
  onClick: ({ uuid, open }: { uuid: string; open: boolean }) => void
  dataTest?: string
}
const rootClass = 'dynamic-content-list'

export const DynamicContentList: FC<DynamicContentListProps> = (props) => {
  const { dataTest = rootClass, html, list, onClick, plainTextParts } = props
  const { t } = useTranslation()

  const renderContent = (rowIndex: number) => {
    if (plainTextParts) {
      return (
        <div
          dangerouslySetInnerHTML={{ __html: `<pre>${plainTextParts[rowIndex - 1]}</pre>` }}
          style={{ pointerEvents: 'none', paddingLeft: '2rem' }}
        ></div>
      )
    } else {
      const tempElement = document.createElement('div')
      tempElement.innerHTML = html
      const row = tempElement.querySelector(`.row-${rowIndex}`)?.querySelector('.row-content')
      const style = row?.getAttribute('style')
      row?.setAttribute('style', `${style};width:100%;`)
      return <div dangerouslySetInnerHTML={{ __html: `${row?.outerHTML}` }} style={{ pointerEvents: 'none' }}></div>
    }
  }

  const renderConditions = (index: number) => {
    return (
      <div className={`condition-card__container`}>
        {list[index].condition?.map((condition, conditionIndex) => {
          return (
            <>
              <div className={`condition-card`}>
                <div className={`condition-card__header`}>
                  <Typography text={`${conditionIndex + 1} Display conditions`} weight={TextWeight.MEDIUM} />
                </div>
                <div className={`condition-card__content`}>
                  {condition.predicate.map((predicate, predicateIndex) => {
                    return (
                      <div key={`${predicate.field}-${predicate.criteria}`}>
                        {predicateIndex !== 0 ? (
                          <div className="condition-card__content-or">
                            <Typography
                              text={condition.matchType === 'any' ? `or` : `and`}
                              type={TextType.BODY_TEXT_SMALL}
                              weight={TextWeight.MEDIUM}
                              lineHeight={LineHeight.MEDIUM_SMALL}
                              inline
                            />
                          </div>
                        ) : (
                          ''
                        )}
                        {predicateIndex === 0 || predicate.field !== condition.predicate[predicateIndex - 1]?.field ? (
                          <div className={'condition-card__content-tag'}>
                            <Typography
                              text={`${predicate.uiField?.title}`}
                              type={TextType.BODY_TEXT_SMALL}
                              weight={TextWeight.BOLD}
                              lineHeight={LineHeight.MEDIUM_SMALL}
                              inline
                            />
                          </div>
                        ) : (
                          ''
                        )}
                        <Typography
                          text={t(findFieldOperator(predicate.uiType)?.displayText)}
                          type={TextType.BODY_TEXT_SMALL}
                          lineHeight={LineHeight.MEDIUM_SMALL}
                          inline
                        />
                        {predicate.criteria && (
                          <div className={'condition-card__content-tag'}>
                            <Typography
                              text={`“${predicate.criteria}“`}
                              type={TextType.BODY_TEXT_SMALL}
                              weight={TextWeight.BOLD}
                              lineHeight={LineHeight.MEDIUM_SMALL}
                              inline
                            />
                          </div>
                        )}
                        {predicate.criteria_max ? (
                          <div>
                            <Typography text={'and '} type={TextType.BODY_TEXT_SMALL} lineHeight={LineHeight.MEDIUM_SMALL} inline />
                            <div className={'condition-card__content-tag'}>
                              <Typography
                                text={`“${predicate.criteria_max}“`}
                                type={TextType.BODY_TEXT_SMALL}
                                weight={TextWeight.BOLD}
                                lineHeight={LineHeight.MEDIUM_SMALL}
                                inline
                              />
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>

              {(list[index]?.condition as any)[conditionIndex + 1] ? (
                <div className="condition-card__divider-container">
                  <div className="condition-card__divider" />
                  <Typography
                    text={`OR`}
                    type={TextType.BODY_TEXT_SMALL_LIGHT}
                    weight={TextWeight.MEDIUM}
                    lineHeight={LineHeight.MEDIUM_SMALL}
                    inline
                  />
                  <div className="condition-card__divider" />
                </div>
              ) : null}
            </>
          )
        })}
      </div>
    )
  }

  return (
    <div className={rootClass} data-test={dataTest}>
      {list.map((item, index) => {
        return (
          <ComposerConditionToggleCard
            key={`${item.uuid}`}
            defaultOpen
            label={`${item.label}`}
            title={`${item.label}`}
            description={`${item.description}`}
            content={renderContent(item.rowIndex)}
            conditions={renderConditions(index)}
            onClick={(open) => onClick({ uuid: `${item.uuid}`, open })}
          />
        )
      })}
    </div>
  )
}
