import { useCallback, useRef, useState } from 'react'

import { useApolloClient, useMutation } from '@apollo/client'
import { YesNo } from '@components/ConfirmationModal'
import { useTranslation } from '@const/globals'
import deleteUsersMutation from '@graphql/mutations/deleteUsers'
import { DeleteUsersMutation, MutationDeleteUsersArgs } from '@graphql/types/mutation-types'
import { UserResponse } from '@graphql/types/query-types'
import { StatusToastType } from '@interface/StatusToast'
import { logNewRelicError } from '@utils/new-relic.utils'

export type UserToDelete = UserResponse & { id: string; name: string }
export type BulkUsersDeleteErrorInfo = { deleted: string[]; notDeleted: string[] }

const getUserWithName: (user: UserResponse & { id: string }) => UserToDelete = (user) => {
  const { fullName, firstName = '', lastName = '', email, ...rest } = user
  const name = fullName ? fullName : firstName ? `${firstName}${lastName ? ` ${lastName}` : ''}` : lastName ? lastName : email ?? ''
  return { name, ...rest }
}

const useUserDelete = (setToastStatus: (value: StatusToastType) => void, setLoadingMutation: (loading: boolean, isSales: boolean) => void) => {
  const { t } = useTranslation()
  const [usersToDelete, setUsersToDelete] = useState<UserToDelete[] | undefined>(undefined)
  const [bulkDeleteErrorInfo, setBulkDeleteErrorInfo] = useState<BulkUsersDeleteErrorInfo | undefined>(undefined)
  const deleteRef = useRef<{ refetch: Function; isSales: boolean } | undefined>(undefined)

  const client = useApolloClient()
  const [deleteUsers] = useMutation<DeleteUsersMutation, MutationDeleteUsersArgs>(deleteUsersMutation, {
    client,
    fetchPolicy: 'no-cache',
  })

  const onCloseBulkModal = useCallback(() => {
    setBulkDeleteErrorInfo(undefined)
  }, [])

  const onDeleteUsers = useCallback<(usersToDelete: UserResponse[], refetch: Function, isSales: boolean) => void>(
    (usersToDelete, refetch, isSales) => {
      setUsersToDelete(usersToDelete.filter(({ id }) => !!id).map((user) => getUserWithName(user as UserResponse & { id: string })))
      deleteRef.current = { refetch, isSales }
      deleteRef.current
    },
    []
  )

  const handleDeleteUsers = useCallback<(answer: YesNo) => void>(
    (answer) => {
      if (!usersToDelete) {
        return
      }
      if (answer === YesNo.NO) {
        setUsersToDelete(undefined)
        deleteRef.current = undefined
        return
      }

      const ids: string[] = usersToDelete.map(({ id }) => id)
      const userName: string = usersToDelete.length === 1 ? usersToDelete[0].name : ''

      setUsersToDelete(undefined)
      deleteRef.current && setLoadingMutation(true, deleteRef.current.isSales)
      deleteUsers({
        variables: {
          ids,
          isRemote: false,
        },
      })
        .then(({ data }) => {
          const { deleteUsers } = { ...data }
          if (deleteUsers?.status === 'ok') {
            if (deleteRef.current) {
              deleteRef.current.refetch()
            }
            setToastStatus({
              showStatus: true,
              title: t('Success!'),
              statusMessage: t('User.Delete.Success.Message', { count: ids.length, userName }),
              successStatus: true,
            })
          } else if (deleteUsers?.status === 'error') {
            if (userName) {
              setToastStatus({
                showStatus: true,
                statusMessage: t('User.Delete.Error.Message', { userName }),
                successStatus: false,
              })
            } else {
              const deleted = usersToDelete.filter(({ id }) => deleteUsers?.deletedUsersIds?.includes(id)).map(({ name }) => name)
              const notDeleted = usersToDelete.filter(({ id }) => !deleteUsers?.deletedUsersIds?.includes(id)).map(({ name }) => name)
              setBulkDeleteErrorInfo({ deleted, notDeleted })
              if (deleted.length && deleteRef.current) {
                deleteRef.current.refetch()
              }
            }
          }
        })
        .catch((error) => {
          logNewRelicError(error)
        })
        .finally(() => {
          deleteRef.current && setLoadingMutation(false, deleteRef.current.isSales)
        })
    },
    [t, usersToDelete, setLoadingMutation]
  )

  return { onDeleteUsers, handleDeleteUsers, usersToDelete, bulkDeleteErrorInfo, onCloseBulkModal }
}

export default useUserDelete
