export type TagsSplitFunctionType = ((data: string) => string[]) | undefined
export type InputTagsErrorsType = { hasDuplicationError: boolean; hasValidationError: boolean }

export enum InputTagKeys {
  Backspace = 8,
  Tab = 9,
  Enter = 13,
  Space = 32,
  Delete = 43,
  Comma = 188,
}

export const CommonInputEmailTagsProps = {
  pasteSplitRegExp: /\s+|,\s*/g, //RegExp for ',' or empty space
  addKeys: [InputTagKeys.Enter, InputTagKeys.Tab, InputTagKeys.Space, InputTagKeys.Comma],
  removeKeys: [InputTagKeys.Delete, InputTagKeys.Backspace],
}
