import { gql } from '@apollo/client'

export default gql`
  query getWebinarAttendanceData($webinarId: String, $webinarType: WebinarType) {
    getWebinarAttendanceData(webinarId: $webinarId, webinarType: $webinarType) {
      attendees
      registrants
    }
  }
`
