import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { t } from 'i18next'

import { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { MIN_PAGINATION_SIZE } from '@components/TableV2/components/Pagination/TableV2Pagination'
import { TableV2 } from '@components/TableV2/TableV2'
import { rootContext } from '@const/globals'
import { sentMessageColumn } from '@src/pages/sms/utils/sms.utils'
import { Message } from '@utils/sms.utils'

import './SentMessageTable.css'

const rootClass = 'sms-sent-message-table'

interface SMSSentListingProps {
  messages: Message[]
  loading: boolean
  dataTest?: string
  pageSize: number
  pageIndex: number
  totalCount: number
  fetchData: (pageIndex: number, pageSize: number) => void
}

const SentMessageTable = (props: SMSSentListingProps) => {
  const { messages, loading, pageSize, pageIndex, totalCount, fetchData } = props
  const history = useHistory()

  const onRowClicked = (row: any) => {
    if (row?.original.status !== 'SENDING') {
      history.push(`${rootContext}/sms/${row?.original.launchId ?? '0'}/report/summary`)
    }
  }

  const emptyState = useMemo(
    () => ({ headline: t("You haven't sent any messages yet."), imgSrc: StaticImageNames.emptyMessages, size: EmptyListingSize.MEDIUM }),
    []
  )

  return (
    <div className={rootClass}>
      <TableV2
        loading={loading}
        data={messages}
        columns={sentMessageColumn}
        enableSorting={true}
        emptyState={emptyState}
        paginationState={{ pageIndex, pageSize, controlledPageCount: Math.ceil(totalCount / pageSize) }}
        enablePaginate={totalCount >= MIN_PAGINATION_SIZE}
        fetchData={fetchData}
        onRowClicked={onRowClicked}
      />
    </div>
  )
}

export default SentMessageTable
