import React, { FC, useEffect, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { SelectV2Option, SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import createSegment from '@graphql/mutations/createSegment'
import { CreateSegmentMutation, CreateSegmentMutationVariables } from '@graphql/types/mutation-types'
import { Program } from '@graphql/types/query-types'
import EditAppendToSegmentStep from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditAppendToSegmentStep/EditAppendToSegmentStep'
import { useProgramsCategorizationRequests } from '@src/pages/programs/GraphQL/ProgramsRequests.categorization.graphQL'
import { getUpdatedProgramWithAOContactsSource } from '@src/pages/programs/manager/ProgramManager.utils'
import { ProgramAppendToSegmentStepExt, Step } from '@utils/program/program.constants'

interface EditAppendToSegmentStepContainerProps {
  step: Step
  program: Program
  isRunning: boolean
  saveStepAndProgram: (step: Step | null, program?: Program) => void
  closeModal: () => void
  submitId: string
}

export interface SubmitSelectOption extends SelectV2SingleOption {
  displayName: string
}

const EditAppendToSegmentStepContainer: FC<EditAppendToSegmentStepContainerProps> = (props: EditAppendToSegmentStepContainerProps) => {
  const { step: baseStep, isRunning, saveStepAndProgram, closeModal, submitId, program } = props
  const step = baseStep as ProgramAppendToSegmentStepExt

  const [defaultOption, setDefaultOption] = useState<SelectV2SingleOption | undefined>({ label: step.listName ?? '', value: step.listId ?? '' })
  const [createSegmentLoading, setCreateSegmentLoading] = useState<boolean>(false)
  const [segmentOptions, setSegmentOptions] = useState<SelectV2SingleOption[]>([])
  const client = useApolloClient()

  const { getTopDirectSelectSegmentsRequest } = useProgramsCategorizationRequests()

  const searchSegment: (search: string) => Promise<SelectV2Option[]> = async (search: string) => await getTopDirectSelectSegmentsRequest(0, search)

  const loadInitialSegments = async () => setSegmentOptions(await getTopDirectSelectSegmentsRequest(0))

  const handleFormSubmit = async (data: SubmitSelectOption) => {
    const updatedProgram = data.value ? await getUpdatedProgramWithAOContactsSource(client, program, data.value) : program
    saveStepAndProgram(
      {
        ...step,
        displayName: data.displayName,
        listId: data.value,
        listName: data.label,
        all: true,
        pruneRecords: false,
      },
      updatedProgram
    )
  }

  const createNewSegment = async (name: string) => {
    setCreateSegmentLoading(true)
    const { data } = await client.mutate<CreateSegmentMutation, CreateSegmentMutationVariables>({
      mutation: createSegment,
      variables: {
        name: name,
      },
    })

    if (data && data.createSegment) {
      setDefaultOption({ label: data.createSegment.name, value: data.createSegment.id })
      await loadInitialSegments()
      setCreateSegmentLoading(false)
    }
  }

  useEffect(() => {
    loadInitialSegments()
  }, [])

  return (
    <EditAppendToSegmentStep
      step={step}
      loading={createSegmentLoading}
      isRunning={isRunning}
      listOptions={segmentOptions}
      getSegmentOptions={getTopDirectSelectSegmentsRequest}
      searchSegment={searchSegment}
      stepTitle={step.displayName}
      defaultOption={defaultOption}
      handleFormSubmit={handleFormSubmit}
      closeModal={closeModal}
      createNewSegment={createNewSegment}
      submitId={submitId}
    />
  )
}

export default EditAppendToSegmentStepContainer
