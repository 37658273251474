import { gql } from '@apollo/client'

export default gql`
  query getForm($id: String!) {
    getForm(id: $id) {
      id
      title
      description
      list {
        listId
        listName
        isActOnContacts
        contactsCount
        sendToActOnContacts
        segmentId
        segmentName
      }
      createdAt
      updatedAt
      capSignupsEnabled
      capSignupsLimit
      connectedCrm
      connectedWebinar
      isClassic
      isPaymentsEnabled
      landingPages {
        id
        title
        isBee
      }
      embedCodes {
        formId
        embedCodeId
        deploymentId
        name
        externalURL
        sourceHtml
        prefilledHtml
        noCssHtml
        noCssAndPrefilledHtml
      }
      crmPushErrorsCount
      expirationDate
      counts {
        submits
        views
        totalSubmits
      }
      createdBy
      lastUpdatedBy
    }
  }
`
