import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import * as TabsRadix from '@radix-ui/react-tabs'

import { TabItemData } from './TabsAO'

export type TabProps = Pick<TabItemData, 'index' | 'content' | 'alwaysRender'> & {
  children?: ReactNode
  isSelected?: boolean
  className?: string
}

const rootClass = 'tabs-ao'

const Tab: FC<TabProps> = (props) => {
  const { content, index, children, isSelected, alwaysRender, className } = props
  const tabContent = children ?? content
  return (
    <TabsRadix.Content
      forceMount={alwaysRender ? true : undefined}
      className={classNames(className, `${rootClass}__panel`, { [`${rootClass}__panel--invisible`]: !isSelected && alwaysRender })}
      value={index}
      key={index}
      tabIndex={-1}
    >
      {tabContent}
    </TabsRadix.Content>
  )
}

export default Tab
