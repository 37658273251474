import { ReactNode } from 'react'

import { SvgNames } from '@components/Svg'

export interface NavigationState {
  visible: boolean
  hiddenMenu: boolean
  modalOpen: boolean
  expanded: boolean
  inIframe: boolean
  navigationPath?: string
  disabled: boolean
  globalLoading: boolean
}

export interface NavigationInterfaceOpenWindow {
  url: string
  name?: string
  width?: number
  popup?: boolean
  amc?: boolean
  externalWindowWrapper?: string
  isExternalUrl?: boolean
}

export interface NavigationInterface {
  id: string | undefined
  icon?: SvgNames | undefined // icon for the navigation item, only for root level items
  iconSelected?: SvgNames
  title?: string // if title is different from label
  label: string | undefined // string to be displayed for the navigation item
  isNew?: ReactNode
  afterImg?: SvgNames
  settings?: string[] // string values of Account settings properties to check for access
  settingsAnd?: boolean // Should settings be && together
  url?: string // url to direct link to
  alternateUrls?: string[] // url associated with new pages that also belong to this nav item
  excludeUrls?: string[] // url to exclude from the nav item
  openWindow?: NavigationInterfaceOpenWindow // on click command that will be passed down to acton class to be executed
  items?: NavigationInterface[] | undefined // all sub items for this navigation
  beta?: boolean // display beta tag on this feature
  hideAllListingFolders?: boolean
  // used by old custom dynamic nav
  oldTarget?: string
  oldType?: string | undefined
  smallFont?: boolean
  topSection?: boolean

  callBack?: (accountId?: string) => void
}

export interface OldNavigationInterface {
  faIcon?: string
  items?: OldNavigationInterface[]
  label?: string
  type?: string
  target?: string
  href?: string
}

export const getNavigation = (state: any): NavigationState => state?.navigation ?? {}
