import { useContext } from 'react'

import { CopySegmentsContext } from '@src/pages/ContactSegments/components/CopySegments/context/CopySegments.context'

export const useUnmappedFieldSegments = () => {
  const {
    values: { segments, fieldNamesBySegment, mappedFields, segmentsWithFilterExpressions },
  } = useContext(CopySegmentsContext)
  return segments.filter(
    ({ id }) =>
      !segmentsWithFilterExpressions.find(({ segmentId }) => segmentId === id)?.value &&
      !fieldNamesBySegment[id]?.some((fieldName) => {
        const fieldFound = mappedFields.find(({ marketingListField }) => marketingListField === fieldName)
        return fieldFound !== undefined ? !!fieldFound.allContactsField : false
      })
  )
}
