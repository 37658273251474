import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import DropDown from '@components/DropDown'
import { DropDownType } from '@components/DropDown/DropDown'
import DropDownActions from '@components/DropDownActions/DropDownActions'
import PageContainer from '@components/PageContainer'
import PageHeader from '@components/PageHeader'
import { isNonSendableSegment } from '@components/SegmentDetails/SegmentDetails.utils'
import StatusToast, { Status } from '@components/StatusToast/StatusToast'
import { SvgNames } from '@components/Svg'
import Tabs, { TabItemData } from '@components/TabsAO/TabsAO'
import { useTranslation } from '@const/globals'
import ContactsDetails from '@src/pages/ContactSegments/components/ContactsDetails/ContactsDetails'
import SegmentActivity from '@src/pages/ContactSegments/components/SegmentActivity/SegmentActivity'
import { sendEmailToSegment } from '@src/pages/ContactSegments/utils/ContactSegmentsContainerUtils'
import { CONTACTS_DETAILS_URL, SEGMENT_ACTIVITY_URL } from '@src/pages/ContactSegments/utils/ContactSegmentsSession.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import {
  ContactSegmentsSession,
  getListDownloadLink,
  goEditSegment,
  saveSelectedListsToLocalStorage,
  Segment,
} from '@utils/contactSegments/contactSegments.utils'
import { ContactSegmentsContext } from '@utils/contactSegments/context/ContactSegmentsContext'
import { getItem, removeItem, setItem } from '@utils/sessionStorage'
import { download } from '@utils/utils'

import './SegmentDetails.css'

interface Props {
  className?: string
  dataTest?: string
  segment: Segment
}

const rootClass = 'segment-details'

enum TABS {
  CONTACTS_DETAILS = 'contacts-details',
  SEGMENT_ACTIVITY = 'segment-activity',
}

const SegmentDetails: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, segment, className = '' } = props
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)
  const [messageDropdownOpen, setMessageDropdownOpen] = useState<boolean>(false)
  const [currentTab, setCurrentTab] = useState(getItem(ContactSegmentsSession.SEGMENT_DETAILS_TAB) ? TABS.SEGMENT_ACTIVITY : TABS.CONTACTS_DETAILS)
  const { userAllowedToDownload, disableSegmentModifications } = useAccountSettings()
  const {
    values: {
      segmentContacts,
      exportedFile,
      search,
      searchAllItems,
      statusToast: { statusMessage, status, showStatusToast },
    },
    getItem: getSegment,
    update,
    closeStatusToast,
  } = useContext(ContactSegmentsContext)
  const { t } = useTranslation()
  const history = useHistory()
  const totalCount = segmentContacts?.totalCount || 0
  const isBounceSegment = useMemo(() => segment.itemType === ItemType.BOUNCE, [segment])

  const onHeaderActionClick = (dropdownOpen: boolean) => setDropdownOpen(dropdownOpen)
  let abortController: AbortController

  useEffect(() => {
    if (exportedFile !== '') {
      update({
        statusToast: {
          statusMessage: t(`ListPage.Common.Export`, { count: totalCount }),
          status: Status.SUCCESS,
          showStatusToast: true,
        },
      })
      download(exportedFile, exportedFile.substr(exportedFile.lastIndexOf('/') + 1))
      update({ exportedFile: '' })
    }
  }, [exportedFile])

  useEffect(() => {
    abortController = getSegment(segment.id, segment.itemType)
  }, [])

  useEffect(() => () => abortController && abortController.abort(), [])

  const segmentDetailsMenuItems = [
    {
      disabled: !segment?.isEditable || disableSegmentModifications,
      hasTooltip: !segment?.isEditable || disableSegmentModifications,
      tooltipMessage: disableSegmentModifications ? 'Segment.Tooltip.Disabled.Migration' : 'System generated segments can not be edited',
      icon: SvgNames.pencil,
      text: 'Edit segment definition',
      onClick: () => {
        goEditSegment(segment, currentTab === TABS.SEGMENT_ACTIVITY ? SEGMENT_ACTIVITY_URL : CONTACTS_DETAILS_URL, {
          search,
          searchAll: searchAllItems,
        })
      },
    },
    {
      disabled: !userAllowedToDownload,
      hasTooltip: !userAllowedToDownload,
      tooltipMessage: 'Ask your administrator for permission to do this',
      icon: SvgNames.export,
      text: 'Export as CSV',
      onClick: () => doExport(),
    },
  ]

  const listingTabs: TabItemData[] = [
    {
      index: TABS.CONTACTS_DETAILS,
      label: t('Contacts'),
      content: <ContactsDetails segment={segment} />,
    },
    {
      index: TABS.SEGMENT_ACTIVITY,
      label: t('Segment Details'),
      content: <SegmentActivity segment={segment} />,
    },
  ]

  const handleOnChange = (currentTab: string) => {
    currentTab === TABS.SEGMENT_ACTIVITY
      ? setItem(ContactSegmentsSession.SEGMENT_DETAILS_TAB, 'showSegmentDetailsTab')
      : removeItem(ContactSegmentsSession.SEGMENT_DETAILS_TAB)
    setCurrentTab(currentTab as TABS)
  }

  useEffect(() => {
    history.listen(() => {
      if (location.pathname.includes(CONTACTS_DETAILS_URL)) {
        handleOnChange(TABS.CONTACTS_DETAILS)
      } else if (location.pathname.includes(SEGMENT_ACTIVITY_URL)) {
        handleOnChange(TABS.SEGMENT_ACTIVITY)
      }
    })
  }, [])

  const doExport = () => update({ exportedFile: getListDownloadLink(segment) })

  const closeMessageDropDown = () => setMessageDropdownOpen(!messageDropdownOpen)

  return (
    <PageContainer className={classNames(rootClass, className)} dataTest={dataTest}>
      {showStatusToast && <StatusToast status={status} message={statusMessage} closeStatus={closeStatusToast} />}
      <PageHeader primaryText={segment.name} linkUrl="segments" linkOptions={{ state: 'details' }} leftContent className={`${rootClass}__header`}>
        {!(isBounceSegment && isNonSendableSegment(segment)) && (
          <Button
            className={`${rootClass}__send-message-button`}
            buttonType={ButtonType.PRIMARY}
            onClick={() => {
              saveSelectedListsToLocalStorage([segment])

              sendEmailToSegment(segment.externalId)
            }}
          >
            {t('Send message')}
          </Button>
        )}
        <DropDown
          className={classNames({ [`${rootClass}__drop-down-open`]: dropdownOpen })}
          hasOverflowIcon
          isOpen={dropdownOpen}
          toggleOpen={onHeaderActionClick}
          type={DropDownType.STYLED}
        >
          <DropDownActions className={`${rootClass}__drop-down`} menuItems={segmentDetailsMenuItems} closeDropDown={closeMessageDropDown} />
        </DropDown>
      </PageHeader>
      {isBounceSegment ? (
        <ContactsDetails segment={segment} isBounceSegment />
      ) : (
        <Tabs key={currentTab} childData={listingTabs} defaultValue={currentTab} onChange={handleOnChange} />
      )}
    </PageContainer>
  )
}

export default SegmentDetails
