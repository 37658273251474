import React, { FC, forwardRef, MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import FlipMove from 'react-flip-move'
import { UseFieldArrayReturn, useFormContext, useWatch } from 'react-hook-form'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import InfoStatus, { InfoStatusTypes } from '@components/InfoStatus/InfoStatus'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { MAX_SUMMARY_CHARACTERS } from '@src/pages/Content/Email/Editor/components/GenerativeEmailModal/GenerativeEmailModal.constants'
import { useTranslation } from '@utils/const/globals'
import { getFormErrorMessageList } from '@utils/formUtils'

import DisplayConditionsGroup, { DisplayConditionsGroupProps } from './DisplayConditionsGroup/DisplayConditionsGroup'
import { DisplayConditionsForm, MultiFieldError, TogglePersonalizationsModal } from '../DisplayConditions.types'

import './DisplayConditionsBuilder.css'

interface ConditionBuilderProps {
  groupController: UseFieldArrayReturn<DisplayConditionsForm, 'groups', 'id'>
  className?: string
  dataTest?: string
  togglePersonalizations: TogglePersonalizationsModal
}

const rootClass = 'display-conditions-builder'

const DisplayConditionsBuilder: FC<ConditionBuilderProps> = (props: ConditionBuilderProps) => {
  const { togglePersonalizations, groupController, dataTest = rootClass, className = '' } = props

  const [errorMessages, setMessages] = useState<string[]>([])
  const { t } = useTranslation()
  const { control, formState } = useFormContext<DisplayConditionsForm>()
  const groups = useWatch({ control, name: 'groups' })
  const dupGroups = (formState.errors.duplicateGroups as MultiFieldError)?.message?.fields ?? []

  const flipContainer = useRef<HTMLDivElement>(null)
  const Group = useMemo(() => {
    return forwardRef((props: DisplayConditionsGroupProps, ref) => (
      <div ref={ref as MutableRefObject<HTMLDivElement>}>{<DisplayConditionsGroup {...props} />}</div>
    ))
  }, [])

  const addGroup = () => {
    groupController.append({ header: 'Display Conditions #', fields: [], groupingOperator: 'any' })
  }

  const updateErrorMessages = () => {
    const messages = [...new Set(getFormErrorMessageList(formState.errors))]
      // Don't show the too long description error in the banner (AO-92372)
      .filter((message) => !message.includes(`Maximum ${MAX_SUMMARY_CHARACTERS} characters`))
      .map((message) => {
        if (message === 'EmailComposer.DynamicContent.Error.MissingValue') {
          return 'EmailComposer.DynamicContent.Error.MissingValue.Long'
        }
        return message
      })
    setMessages(messages)
  }

  useEffect(() => {
    if (formState.submitCount > 0) {
      updateErrorMessages()
    }
  }, [formState.submitCount])

  useEffect(() => {
    updateErrorMessages()
  }, [groups, formState])

  const renderErrorMessages = () => (
    <div className={`${rootClass}__error-messages`}>
      <Typography text={t('EmailComposer.DynamicContent.Error.Banner')} type={TextType.BODY_TEXT_GRAY} weight={TextWeight.BOLD} />
      <ul>
        {errorMessages.map((message) => {
          return (
            <li key={message}>
              <Typography text={t(message)} type={TextType.BODY_TEXT_GRAY} />
            </li>
          )
        })}
      </ul>
    </div>
  )

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest} ref={flipContainer}>
      {!!errorMessages.length && (
        <InfoStatus
          className={`${rootClass}__error-messages-wrapper`}
          status={InfoStatusTypes.Warning}
          svgName={SvgNames.cautionError}
          statusText={renderErrorMessages()}
        />
      )}
      <FlipMove enterAnimation={'fade'} leaveAnimation={'fade'} className={`${rootClass}__flip-container`}>
        {groupController.fields.map((group, idx) => (
          <Group
            key={group.id}
            groupId={group.id}
            groupController={groupController}
            togglePersonalizations={togglePersonalizations}
            hasError={dupGroups.some((group) => group?.groupIdx === idx)}
          />
        ))}
      </FlipMove>
      <div>
        <Button
          onClick={() => addGroup()}
          className={`${rootClass}__add-condition`}
          buttonType={ButtonType.OUTLINE}
          dataTest={`${dataTest}-add-group`}
        >
          <Svg name={SvgNames.arrowDiverge} type={SvgType.LARGER_ICON} />
          {groupController.fields.length ? t('EmailComposer.DynamicContent.AddGroup') : t('EmailComposer.DynamicContent.AddCondition')}
        </Button>
      </div>
    </div>
  )
}

export default DisplayConditionsBuilder
