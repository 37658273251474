import { SendToListResponse } from '@graphql/types/mutation-types'
import { ListWithContactsGq } from '@graphql/types/query-types'
import { getUpdatedRecipientsCount } from '@src/pages/EmailComposer/components/SendDetails/components/SendDetailsRecipients/SendDetailsRecipients.utils'
import { MessageRecipient, TouchpointsLocalStorageKeys } from '@src/pages/EmailComposer/utils/EmailComposer.types'
import { filterNotEmptyArray } from '@utils/array'
import { EmailComposerState, ListWithContacts, SendToContact, SendtoList } from '@utils/composer/context/EmailComposer.context'
import { logNewRelicError } from '@utils/new-relic.utils'
import { DeepUpdateState } from '@utils/types'

export const getSessionStorageRecipients = () => {
  const crmStorageData = sessionStorage.getItem('sendSettings') || '[]'
  const crmContacts: ListWithContacts[] = JSON.parse(crmStorageData)
  return { crmStorageData, crmContacts }
}

export const getRecipientsCount = (update: DeepUpdateState<EmailComposerState>, lists?: SendtoList[], contacts?: SendToContact[]) => {
  const recipientLists: MessageRecipient[] =
    lists?.map((list) => {
      return {
        id: list.srcId,
        isList: true,
        name: list.srcName,
        size: list.size,
      }
    }) ?? []

  const recipientContacts: MessageRecipient[] =
    contacts?.map((contact) => {
      return {
        id: contact.id,
        isList: false,
        name: contact.name,
        size: 1,
        email: contact.email,
      }
    }) ?? []

  update({ recipientsCount: getUpdatedRecipientsCount([...recipientLists, ...recipientContacts]) })
}

export const getAssetReportDetailsFromLocalStorage = (key?: TouchpointsLocalStorageKeys) => {
  try {
    if (key) {
      const detailsData = localStorage.getItem(key)

      if (detailsData) {
        return JSON.parse(detailsData)
      }
    }
  } catch (error) {
    logNewRelicError(error)
  }
}

const setRecipientsFromAllContactsTouchpoint = (update: DeepUpdateState<EmailComposerState>, queryParams: URLSearchParams) => {
  const key = queryParams.get('selectedLists')
    ? TouchpointsLocalStorageKeys.CONTACT_SEGMENTS_SELECTED_LISTS
    : TouchpointsLocalStorageKeys.CONTACT_SEGMENTS_SELECTED_CONTACTS

  try {
    const recipientData = localStorage.getItem(key)

    if (recipientData) {
      const recipients = JSON.parse(recipientData)
      const message =
        key === TouchpointsLocalStorageKeys.CONTACT_SEGMENTS_SELECTED_LISTS
          ? {
              sendto_lists: recipients,
              suppress_dups: true,
            }
          : {
              individualRecipientsSourceIds: recipients.map((recipients: SendToContact) => recipients?.id),
              sendto_contacts: recipients.map((recipient: SendToContact) => ({
                email: recipient.email,
                id: recipient.id,
                name: recipient.name,
                srcId: recipient.srcId,
              })),
              suppress_dups: true,
            }

      update({ message })
    }
  } catch (error) {
    logNewRelicError(error)
  }
}

export const setRecipientsFromMarketingTouchpoint = (update: DeepUpdateState<EmailComposerState>, queryParams: URLSearchParams) => {
  const baseId = queryParams.get('baseId')
  const srcId = queryParams.get('srcId')
  const srcName = queryParams.get('name')
  const size = queryParams.get('size')

  if (baseId && srcId && srcName && size) {
    update({
      message: {
        sendto_lists: [
          {
            isList: true,
            baseId,
            srcId,
            srcName,
            size: parseInt(size),
          },
        ],
      },
    })
  }
}

export const setRecipientsFromTouchpoints = (update: DeepUpdateState<EmailComposerState>, queryParams?: URLSearchParams) => {
  if (queryParams) {
    if (queryParams.get('marketingTouchpoint')) {
      setRecipientsFromMarketingTouchpoint(update, queryParams)
    } else if (queryParams.get('selectedLists') || queryParams.get('selectedContacts')) {
      setRecipientsFromAllContactsTouchpoint(update, queryParams)
    }
  }
}

export const getSendToContacts = (sendToContacts?: (ListWithContactsGq | undefined)[]): undefined | SendToContact[] => {
  if (!sendToContacts) {
    return undefined
  }
  const filteredContacts = sendToContacts.reduce((acc: SendToContact[], constactsList) => {
    if (constactsList?.contacts && Array.isArray(constactsList.contacts)) {
      const contacts = constactsList.contacts.filter((contact) => filterNotEmptyArray(contact) && contact?.id)
      acc = [...acc, ...(contacts as SendToContact[])]
    }
    return acc
  }, [])

  return filteredContacts.length ? filteredContacts : undefined
}

export const getSendToLists = (sendToLists?: (SendToListResponse | undefined)[]) => {
  if (!sendToLists) {
    return
  }
  const result: SendtoList[] = [...sendToLists]
    .map((list) => ({
      srcId: list?.srcId ?? '',
      srcName: list?.srcName ?? '',
      isList: list?.isList ?? false,
      baseId: list?.baseId ?? '',
      size: list?.size ?? 0,
    }))
    .filter(filterNotEmptyArray)
  return result
}
