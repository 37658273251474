import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'

import Button, { ButtonType } from '@components/Button'
import ModalBody from '@components/Modal/components/ModalBody'
import Modal from '@components/Modal/Modal'
import StaticImage from '@components/StaticImage/StaticImage'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Typography, { ModalHeaderConfirmStyle, TextAlign, TextType, TextWeight } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import { detectEmailType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'

import './BlockedModal.css'

interface BlockedModalProps {
  username?: string
  dataTest?: string
  title?: string
  bodyText?: string
  customButtonProps?: {
    onClick: () => void
    text: string
  }
}

const rootClass = 'blocked-modal'

const BlockedModal: FC<BlockedModalProps> = (props: BlockedModalProps) => {
  const { username, dataTest = rootClass, title, bodyText, customButtonProps } = props

  const {
    values: {
      message: { messageType },
      landingPage: { isLandingPage },
    },
  } = useComposerContext()

  const { t } = useTranslation()
  const { enableEmailDraftsReact } = useAccountSettings()
  const history = useHistory()

  const handleOnClick = () => {
    const { isEmailTemplate } = detectEmailType(messageType)

    const listingPage = (() => {
      if (isLandingPage) return 'content/landingPages'
      if (isEmailTemplate) return 'content/emailTemplates'
      return enableEmailDraftsReact ? 'outbound/drafts' : 'classic/draftLists'
    })()

    history.push(`${rootContext}/${listingPage}`)
  }

  return (
    <Modal isOpen dataTest={dataTest} className={rootClass}>
      <ModalBody className={`${rootClass}__body`}>
        <StaticImage name={StaticImageNames.blocked} className={`${rootClass}__image`} />
        <Typography text={title || t('Blocked.Modal.Title')} className={`${rootClass}__title`} {...ModalHeaderConfirmStyle} />
        <Typography
          text={bodyText || t('Blocked.Modal.Text')}
          values={{ name: username, text: isLandingPage ? 'landing page' : 'email' }}
          tagProps={{ b: { weight: TextWeight.BOLD, inline: true } }}
          type={TextType.BODY_TEXT_LIGHT}
          textAlign={TextAlign.CENTER}
        />
        <Button
          buttonType={ButtonType.PRIMARY}
          className={`${rootClass}__button`}
          dataTest={`${dataTest}-button`}
          onClick={customButtonProps?.onClick || handleOnClick}
          inline
        >
          {customButtonProps?.text || t('Blocked.Modal.Button.Text')}
        </Button>
      </ModalBody>
    </Modal>
  )
}

export default BlockedModal
