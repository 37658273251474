import { Action } from '@interface/Action'
import { NavigationState } from '@utils/navigation/navigation.utils'
import editProgramActionTypes from '@utils/program/programActionTypes'
import { LARGE_BREAKPOINT } from '@utils/sizes'
import windowUtils from '@utils/window'

import { SetNavigationPathPayload, ToggleModalOpenedPayload, ToggleNavigationDisabledPayload, ToggleNavigationExpandedPayload } from './actions'
import actionTypes from './actionTypes'

const isInFrame = () => {
  const params = new URLSearchParams(window.location.search)
  return params.get('frame') === '1'
}

export const initialNavigationState: NavigationState = {
  inIframe: isInFrame(),
  visible: !isInFrame(),
  hiddenMenu: false,
  expanded: windowUtils.getWindowWidth() > LARGE_BREAKPOINT,
  disabled: false,
  modalOpen: false,
  globalLoading: false,
}

export function navigation(state = initialNavigationState, action: Action<any>) {
  switch (action.type) {
    case actionTypes.showNavigation:
      return {
        ...state,
        visible: !state.inIframe && !isInFrame(),
      }
    case actionTypes.hideMenu:
      return {
        ...state,
        hiddenMenu: true,
      }
    case actionTypes.hideNavigation:
      return {
        ...state,
        visible: false,
      }
    case actionTypes.setNavigationPath:
      return {
        ...state,
        navigationPath: (action.payload as SetNavigationPathPayload)?.path,
      }
    case actionTypes.toggleExpanded:
      return {
        ...state,
        expanded: (action.payload as ToggleNavigationExpandedPayload)?.expanded,
      }
    case actionTypes.toggleDisabled:
      return {
        ...state,
        disabled: (action.payload as ToggleNavigationDisabledPayload)?.disabled,
      }
    case actionTypes.toggleModalOpened:
      return {
        ...state,
        modalOpen: (action.payload as ToggleModalOpenedPayload)?.modalOpen,
      }
    case editProgramActionTypes.saveProgram:
    case editProgramActionTypes.saveProgramFinal:
      return {
        ...state,
        globalLoading: true,
      }
    case editProgramActionTypes.saveProgramComplete:
    case editProgramActionTypes.saveProgramError:
    case editProgramActionTypes.saveProgramFinalComplete:
    case editProgramActionTypes.saveProgramFinalError:
      return {
        ...state,
        globalLoading: false,
      }
    default:
      return state
  }
}

export default navigation
