import React, { FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import classNames from 'classnames'

import Loader from '@components/Loader'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2Props, SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { InputDefinition } from '@graphql/types/microservice/webhooks-incoming-management-types'
import { BehaviorResponse } from '@graphql/types/query-types'
import ActOnContactsContentMapping from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/components/IncomingWebhookContentMapping/components/ActOnContactsContentMapping/ActOnContactsContentMapping'
import CustomTouchPointBehaviorsContentMapping from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/components/IncomingWebhookContentMapping/components/CustomTouchPointBehaviorsContentMapping/CustomTouchPointBehaviorsContentMapping'
import IncomingWebhookContentMappingToggleSection from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/components/IncomingWebhookContentMapping/components/IncomingWebhookContentMappingToggleSection/IncomingWebhookContentMappingToggleSection'
import { useCreateIncomingWebhookModalRequests } from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/utils/CreateIncomingWebhookModal.graphQL'
import { renderInfoTooltip } from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/utils/CreateIncomingWebhookModal.utils'
import { Channel, IncomingWebhooksContext } from '@src/pages/Webhooks/IncomingWebhooks/IncomingWebhooksContext'

import './IncomingWebhookContentMapping.css'

interface IncomingWebhookContentMappingProps {
  className?: string
  dataTest?: string
  channel?: Channel
}

interface IncomingWebhookContentMappingState {
  behaviors: BehaviorResponse[]
  fieldMappings: UnifiedListFieldMapping[]
  loading: boolean
  selectedChannel?: Channel
}

const rootClass = 'incoming-webhook-content-mapping'
const rootTranslation = 'Incoming.Webhooks.CreateModal.ContentMapping'

const IncomingWebhookContentMapping: FC<IncomingWebhookContentMappingProps> = (props: IncomingWebhookContentMappingProps) => {
  const { dataTest = rootClass, className = '', channel } = props
  const {
    values: { enableContentMapping = false, webhookToEdit },
  } = useContext(IncomingWebhooksContext)

  const { clearErrors, setValue } = useFormContext<InputDefinition>()

  const [state, setState] = useState<IncomingWebhookContentMappingState>({
    behaviors: [],
    fieldMappings: [],
    loading: false,
    selectedChannel: channel,
  })
  const { behaviors, fieldMappings, loading, selectedChannel } = state
  const { t } = useTranslation()

  const { getCustomTouchPointBehaviors, getUCLFieldMappings } = useCreateIncomingWebhookModalRequests()

  useEffect(() => {
    setValue('channel', selectedChannel)
    clearErrors('contentMappings')
    const defaultMappingValues = [{ destinationField: '', sourceField: '', mappingFunction: '', mappingFunctionParams: '', mappingMetadata: '' }]
    const defaultValue =
      selectedChannel === webhookToEdit?.channel && !!webhookToEdit?.contentMappings?.length
        ? webhookToEdit?.contentMappings.map(({ destinationField, sourceField, mappingFunction, mappingFunctionParams, mappingMetadata }) => ({
            destinationField,
            sourceField,
            mappingFunction,
            mappingFunctionParams,
            mappingMetadata,
          })) ?? defaultMappingValues
        : defaultMappingValues
    setValue('contentMappings', defaultValue)
    setState((state) => ({ ...state, loading: true }))
    if (selectedChannel === Channel.CustomTouchPoints) {
      getCustomTouchPointBehaviors().then((behaviors) => setState((state) => ({ ...state, behaviors, loading: false })))
    } else {
      getUCLFieldMappings().then((fieldMappings) => {
        setState((state) => ({ ...state, fieldMappings, loading: false }))
      })
    }
  }, [selectedChannel])

  const contentByChannel: { [key in Channel]: () => ReactNode } = useMemo(
    () => ({
      [Channel.ActOnContacts]: () => <ActOnContactsContentMapping dataTest={`${dataTest}-acton-contacts`} fieldMappings={fieldMappings} />,
      [Channel.CustomTouchPoints]: () => (
        <CustomTouchPointBehaviorsContentMapping dataTest={`${dataTest}-custom-touch-point-behaviors`} behaviors={behaviors} />
      ),
    }),
    [fieldMappings, behaviors]
  )

  const channelOptions: SelectV2SingleOption[] = useMemo(
    () => [
      { label: 'Act-On Contacts', value: Channel.ActOnContacts },
      { label: 'Custom Touch Point Behaviors', value: Channel.CustomTouchPoints },
    ],
    []
  )

  const defaultChannelOption = useMemo(() => channelOptions.find(({ value }) => value === selectedChannel), [selectedChannel])

  const onChangeChannel = useCallback(
    (selectedOption?: SelectV2SingleOption) => setState((state) => ({ ...state, selectedChannel: selectedOption?.value as Channel })),
    []
  )

  const channelSelectProps: SelectV2Props = useMemo(
    () => ({
      className: `${rootClass}__select`,
      dataTest: `${rootClass}__channel-select`,
      defaultValue: defaultChannelOption,
      insideModal: true,
      isClearable: false,
      isDisabled: webhookToEdit?.contentMappings.some((mapping) => !!mapping.sourceField),
      onChange: onChangeChannel,
      options: channelOptions,
      placeholder: t(`${rootTranslation}.Channel.Placeholder`),
    }),
    [channelOptions, defaultChannelOption]
  )

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <IncomingWebhookContentMappingToggleSection />
      {enableContentMapping && (
        <>
          <div className={`${rootClass}__channel-select`}>
            <div className={`${rootClass}__channel-select-label`}>
              <span>
                <Typography
                  text={t(`${rootTranslation}.Channel.Label`)}
                  tagProps={{ bold: { weight: TextWeight.MEDIUM, type: TextType.BODY_TEXT } }}
                  type={TextType.BODY_TEXT_LIGHT}
                  inline
                />
                {renderInfoTooltip(t(`${rootTranslation}.Channel.Tooltip`))}
              </span>
            </div>
            <SelectV2 {...channelSelectProps} />
          </div>
          {loading ? <Loader className={`${rootClass}__loader`} /> : !!selectedChannel && contentByChannel[selectedChannel]()}
        </>
      )}
    </div>
  )
}

export default IncomingWebhookContentMapping
