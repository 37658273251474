import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import ButtonWithLoader from '@components/ButtonWithLoader/ButtonWithLoader'
import { EmptyListingProps, EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import Modal, { ModalBody, ModalHeader, ModalFooter } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Search from '@components/Search/Search'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { TableV2 } from '@components/TableV2/TableV2'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { ModalHeaderFormStyle, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { MarketingNetworkAccount, MarketingNetworkManager } from '@src/pages/MarketingNetwork/Managers/Managers'
import { assignAccountColumns } from '@src/pages/MarketingNetwork/Managers/Managers.tables'
import { Row, RowSelectionState } from '@tanstack/react-table'

import './ManagerAssignAccountsModal.css'

interface ManagerAssignAccountsModalProps {
  accounts: MarketingNetworkAccount[]
  manager: MarketingNetworkManager
  isOpen: boolean
  className?: string
  dataTest?: string
  onClose: () => void
  onAction: (selectedRows: MarketingNetworkAccount[]) => void
}

export interface ManagerAssignAccountsModalState {
  searchText: string
  selectedRows: MarketingNetworkAccount[]
  rowSelections: RowSelectionState
  visibleRows: MarketingNetworkAccount[]
  canSubmit: boolean
  saving: boolean
}

const rootClass = 'manager-assign-accounts-modal'

const MAX_NAME_LENGTH_WO_TOOLTIP = 25

const defaultState: ManagerAssignAccountsModalState = {
  searchText: '',
  selectedRows: [],
  visibleRows: [],
  rowSelections: {},
  canSubmit: false,
  saving: false,
}

const getEmptyListingProps = (t: Function, search: string): EmptyListingProps => {
  const keyModifier = search ? '.Search' : ''
  const imgSrc = StaticImageNames.emptySearch

  return {
    headline: t(`Inbox.EmptyListing${keyModifier}.Headline`, { search }),
    imgSrc,
    size: EmptyListingSize.MEDIUM,
    hideIcon: false,
    withoutBorder: true,
  }
}

const ManagerAssignAccountsModal: FC<ManagerAssignAccountsModalProps> = (props: ManagerAssignAccountsModalProps) => {
  const { accounts, manager, dataTest = rootClass, className = '', onAction, onClose, isOpen } = props
  const { t } = useTranslation()

  const [state, setState] = useState<ManagerAssignAccountsModalState>({
    ...defaultState,
    visibleRows: accounts.sort((a, b) => (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1)),
  })
  const { searchText, selectedRows, visibleRows, rowSelections, canSubmit } = state

  useEffect(() => {
    const selected = accounts.filter((account) => manager.accounts.includes(account.id))
    const selections = Object.fromEntries(
      selected.map((account) => {
        const rowId = accounts.indexOf(account)
        return [rowId, true]
      })
    )
    setState({ ...state, selectedRows: selected, rowSelections: selections })
  }, [])

  const handleAction = () => {
    setState({ ...state, saving: true })
    onAction(selectedRows)
  }

  const handleClose = () => {
    onClose()
  }

  const handleSearchChange = (searchText: string) => {
    if (state.saving) {
      return
    }

    const visibleRows = accounts.filter((account) => account.name.toLowerCase().includes(searchText.toLowerCase()))
    const selectedIndexes = (searchText ? visibleRows : accounts)
      .map((account, idx) => (state.selectedRows.find((selection) => selection.id === account.id) ? idx : -1))
      .filter((index) => index > -1)
      .map((index) => index.toString())
    const rowSelections = Object.fromEntries(selectedIndexes.map((row) => [row, true]))

    setState({ ...state, searchText, visibleRows, rowSelections })
  }

  const onRowSelectionChanged = (rowIds: string[], rows: Row<MarketingNetworkAccount>[]) => {
    if (rowIds.length === 0 && rows.length === accounts.length) {
      setState({ ...state, selectedRows: [], canSubmit: true })
    } else {
      const updatedSelections: MarketingNetworkAccount[] = state.selectedRows
      rows.forEach((row, idx) => {
        const isRowInSelections = rowIds.includes(idx.toString())
        const isRowNewSelection = isRowInSelections && updatedSelections.filter((selection) => selection.id === row.original.id).length === 0
        if (isRowNewSelection) {
          const account = accounts.find((account) => account.id === row.original.id)
          if (account) {
            updatedSelections.push(account)
          }
        }
        if (!isRowInSelections) {
          const isRowPreviouslySelected = updatedSelections.filter((selection) => selection.id === row.original.id).length > 0
          if (isRowPreviouslySelected) {
            const oldSelectionIdx = updatedSelections.map((select) => select.id).indexOf(row.original.id)
            updatedSelections.splice(oldSelectionIdx, 1)
          }
        }
      })

      const selectedIndexes = rows
        .map((row, idx) => (updatedSelections.find((selection) => selection.id === row.original.id) ? idx : -1))
        .filter((index) => index > -1)
        .map((index) => index.toString())

      const rowSelections = Object.fromEntries(selectedIndexes.map((row) => [row, true]))
      const canSubmit =
        manager.accounts.length !== updatedSelections.length || !manager.accounts.every((account, index) => account === updatedSelections[index].id)
      setState(() => ({ ...state, selectedRows: updatedSelections, rowSelections, canSubmit }))
    }
  }

  const header = (
    <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__header`}>
      <Typography text={t('Assign Accounts')} {...ModalHeaderFormStyle} />
      <div className={`${rootClass}__header__extra`}>
        <Search incomingValue={searchText} placeholder={'Search'} onChangeHandler={handleSearchChange} canClear dataTest={`${dataTest}-search`} />
      </div>
    </ModalHeader>
  )

  const nameTypography = (
    <Typography
      text={t('Managers.AssignAccounts.Footer', {
        name: manager.name,
        quantity: selectedRows.length,
        total: accounts.length,
      })}
      tagProps={{
        boldName: {
          weight: TextWeight.BOLD,
          className: classNames(`${rootClass}__footer__extra-name`, {
            ['ellip']: manager.name.length > MAX_NAME_LENGTH_WO_TOOLTIP,
          }),
        },
        bold: {
          weight: TextWeight.BOLD,
          className: `${rootClass}__footer__extra-count`,
        },
      }}
      className={`${rootClass}__footer__extra`}
      dataTest={`${dataTest}-footer-extra`}
    />
  )

  const footer = (
    <>
      <div className={`${rootClass}__footer__extra`}>
        {manager.name.length > MAX_NAME_LENGTH_WO_TOOLTIP ? <Tooltip trigger={nameTypography}>{manager.name}</Tooltip> : nameTypography}
      </div>
      <Button buttonType={ButtonType.TERTIARY} disabled={state.saving} onClick={handleClose} dataTest={`${dataTest}-button-tertiary`}>
        {t('Close')}
      </Button>
      <ButtonWithLoader onClick={handleAction} disabled={!canSubmit || state.saving} loading={state.saving} dataTest={`${dataTest}-button-primary`}>
        {t('Submit')}
      </ButtonWithLoader>
    </>
  )

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
      <ModalBody className={`${rootClass}__body`}>
        <TableV2
          data={visibleRows}
          columns={assignAccountColumns}
          enableStickyHeader
          enableCheckbox
          withoutBorders
          defaultSelectedRows={rowSelections}
          onRowSelectionChanged={onRowSelectionChanged}
          emptyState={getEmptyListingProps(t, searchText)}
        />
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form} className={`${rootClass}__footer`}>
        {footer}
      </ModalFooter>
    </Modal>
  )
}

export default ManagerAssignAccountsModal
