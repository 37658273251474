import { UNIFIED_CONTACTS_ID } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import crmDefinition from '@graphql/microservices/crm/crmDefinition'
import entityType from '@graphql/microservices/crm/entityType'
import getSegmentFilter from '@graphql/microservices/segment/getSegmentFilter'
import getSegmentMetadata from '@graphql/microservices/segment/getSegmentMetadata'
import { CrmDefinitionQuery, CrmDefinitionQueryVariables, EntityTypeQuery, EntityTypeQueryVariables } from '@graphql/types/microservice/crm-types'
import {
  GetSegmentFilterQuery,
  GetSegmentFilterQueryVariables,
  GetSegmentMetadataQuery,
  GetSegmentMetadataQueryVariables,
} from '@graphql/types/microservice/segment-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

type GetSegmentFilterRequestParams = { segmentId?: string }
type GetCRMNotSupportedEntitiesRequestParams = { connectedCRM: string }

export type GetSegmentFilterRequestType = (params: GetSegmentFilterRequestParams) => FetchPromise<GetSegmentFilterQuery>
export type GetSegmentMetadataRequestType = () => FetchPromise<GetSegmentMetadataQuery>
export type GetCRMNotSupportedEntitiesRequestType = (params: GetCRMNotSupportedEntitiesRequestParams) => FetchPromise<CrmDefinitionQuery>
export type GetEntityTypeCampaignRequestType = () => FetchPromise<EntityTypeQuery>

interface UseListPickerModalSegmentResponse {
  getSegmentFilterRequest: GetSegmentFilterRequestType
  getSegmentMetadataRequest: GetSegmentMetadataRequestType
  getCRMNotSupportedEntitiesRequest: GetCRMNotSupportedEntitiesRequestType
  getEntityTypeCampaignRequest: GetEntityTypeCampaignRequestType
}

export const useListPickerModalSegmentRequests = (): UseListPickerModalSegmentResponse => {
  const {
    client: { query },
  } = useMicroserviceClient({ serviceName: MicroserviceClients.SEGMENT })

  const getSegmentFilterRequest = async ({ segmentId }: GetSegmentFilterRequestParams) =>
    await query<GetSegmentFilterQuery, GetSegmentFilterQueryVariables>({
      query: getSegmentFilter,
      fetchPolicy: 'network-only',
      variables: {
        segmentId,
      },
    })

  const getSegmentMetadataRequest = async () =>
    await query<GetSegmentMetadataQuery, GetSegmentMetadataQueryVariables>({
      query: getSegmentMetadata,
      fetchPolicy: 'network-only',
      variables: {
        segmentId: UNIFIED_CONTACTS_ID,
      },
    })

  const getCRMNotSupportedEntitiesRequest = async ({ connectedCRM }: GetCRMNotSupportedEntitiesRequestParams) =>
    await query<CrmDefinitionQuery, CrmDefinitionQueryVariables>({
      query: crmDefinition,
      fetchPolicy: 'network-only',
      variables: {
        connectedCrmName: connectedCRM,
      },
    })

  const getEntityTypeCampaignRequest = async () =>
    await query<EntityTypeQuery, EntityTypeQueryVariables>({
      query: entityType,
      fetchPolicy: 'network-only',
      variables: {
        entityType: 'Campaign',
      },
    })

  return {
    getSegmentFilterRequest,
    getSegmentMetadataRequest,
    getCRMNotSupportedEntitiesRequest,
    getEntityTypeCampaignRequest,
  }
}
