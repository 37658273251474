import React, { FC, ReactNode, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import ButtonWithLoader from '@components/ButtonWithLoader/ButtonWithLoader'
import Checkbox from '@components/Checkbox'
import CheckboxGroup from '@components/CheckboxGroup/CheckboxGroup'
import InfoTooltip from '@components/InfoTooltip/InfoTooltip'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Pill from '@components/Pill/Pill'
import { SegmentDetailsContainerState } from '@components/SegmentDetails/context/SegmentDetails.context'
import { Status } from '@components/StatusToast/StatusToast'
import Typography, { ModalBodyStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { WebinarType } from '@src/pages/listingPages/FormsJoinView/components/DetailsCardWrapper/utils/DetailsCardWrapper.utils'
import { useFormsJoinViewListingPageRequests } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.graphQL'

import './CreateWebinarSegmentsModal.css'

interface CreateWebinarSegmentsModalProps {
  className?: string
  dataTest?: string
  beforeRulesStatus?: BeforeRules
  followUpRulesStatus?: FollowUpRules
  update: (fields: Partial<SegmentDetailsContainerState>) => void
  webinarType: WebinarType
  webinarId: string
  disabledFollowUpSegments?: FollowUpRules
  disabledBeforeSegments?: BeforeRules
}

export interface BeforeRules {
  approved: boolean
  rejected: boolean
  pending: boolean
}

export interface FollowUpRules {
  signedApprovedAttended: boolean
  notSignedAttended: boolean
  signedApprovedNoShow: boolean
}

const rootClass = 'create-webinar-segments-modal'

const CreateWebinarSegmentsModal: FC<CreateWebinarSegmentsModalProps> = (props: CreateWebinarSegmentsModalProps) => {
  const {
    dataTest = rootClass,
    className = '',
    followUpRulesStatus = { notSignedAttended: false, signedApprovedNoShow: false, signedApprovedAttended: false },
    beforeRulesStatus = { approved: false, rejected: false, pending: false },
    update,
    webinarType,
    webinarId,
    disabledFollowUpSegments = { notSignedAttended: false, signedApprovedNoShow: false, signedApprovedAttended: false },
    disabledBeforeSegments = { approved: false, rejected: false, pending: false },
  } = props

  const [beforeRules, setBeforeRules] = useState<BeforeRules>({ ...beforeRulesStatus })
  const [followUpRules, setFollowUpRules] = useState<FollowUpRules>({ ...followUpRulesStatus })
  const [creatingSegments, setCreatingSegments] = useState(false)

  const { approved, pending, rejected } = beforeRules
  const { signedApprovedNoShow, notSignedAttended, signedApprovedAttended } = followUpRules

  const { createWebinarSegmentsRequest } = useFormsJoinViewListingPageRequests()

  const { t } = useTranslation()

  const canSubmit = approved || pending || rejected || signedApprovedNoShow || notSignedAttended || signedApprovedAttended

  /*
   * ("A", "Did Not Sign Up & Attended")
   * ("B", "Signed Up & Approved & Attended")
   * ("C", "Signed Up & Pending Approval & Attended")
   * ("D", "Signed Up & Rejected & Attended")
   * ("E", "Attended (Unknown)")
   * ("F", "Signed Up & Approved & No Show")
   * ("G", "Signed Up & Pending Approval & No Show")
   * ("H", "Signed Up & Rejected & No Show")
   * ("I", "Did Not Sign Up & No Show")
   * ("J", "Signed Up & Approved")
   * ("K", "Signed Up & Rejected")
   * ("L", "Signed Up & Pending")
   * ("M", "All Attendees")
   */
  const segmentsByRules = (followUp: FollowUpRules, before: BeforeRules) => {
    return [
      ...(followUp.notSignedAttended ? ['A'] : []),
      ...(followUp.signedApprovedAttended ? ['B'] : []),
      ...(followUp.signedApprovedNoShow ? ['F'] : []),
      ...(before.approved ? ['J'] : []),
      ...(before.rejected ? ['K'] : []),
      ...(before.pending ? ['L'] : []),
    ]
  }

  const handleAction = async () => {
    setCreatingSegments(true)
    const { errors, data } = await createWebinarSegmentsRequest({
      webinarType: webinarType.toUpperCase() as WebinarType,
      sessionKey: webinarId,
      segments: segmentsByRules(followUpRules, beforeRules),
    })
    if (errors || data?.createWebinarSegments?.length == 0) {
      update({
        showCustomModal: false,
        statusToast: {
          statusMessage: t('Something went wrong on our end. Please try again.'),
          status: Status.FAIL,
          showStatusToast: true,
        },
      })
    } else {
      update({
        showCustomModal: false,
        fetchItems: true,
        statusToast: {
          statusMessage: t('Webinar segments created'),
          status: Status.SUCCESS,
          showStatusToast: true,
        },
      })
    }
    setCreatingSegments(false)
  }

  const handleClose = () => {
    update({ showCustomModal: false })
  }

  const header = (
    <ModalHeader headerType={ModalHeaderType.Form}>{t('ListPage.FormsJoinView.WEBINAR_REGISTRATION.CreateSegmentsModal.Title')}</ModalHeader>
  )

  const connectorPill = (text = 'and'): ReactNode => <Pill className={`${rootClass}__pill`} text={text} noMargin textWeight={TextWeight.MEDIUM} />

  const getBeforeOptions = (option: string, tooltip: string) => (
    <div className={`${rootClass}__section-rule`}>
      <Typography
        text={t('ListPage.FormsJoinView.WEBINAR_REGISTRATION.CreateSegmentsModal.Content.Before.Option')}
        values={{ option: option }}
        tagComponents={{
          Connector: connectorPill(),
        }}
        weight={TextWeight.MEDIUM}
      />
      <InfoTooltip>{t(tooltip)}</InfoTooltip>
    </div>
  )

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen header={header}>
      <ModalBody className={`${rootClass}__body`}>
        <Typography text={t('ListPage.FormsJoinView.WEBINAR_REGISTRATION.CreateSegmentsModal.Description')} {...ModalBodyStyle} />
        <div className={`${rootClass}__content`}>
          <div>
            <Typography
              text={t('ListPage.FormsJoinView.WEBINAR_REGISTRATION.CreateSegmentsModal.Content.Before.Title')}
              type={TextType.BODY_TEXT_LARGE}
              weight={TextWeight.MEDIUM}
              className={`${rootClass}__section-title`}
            />
            <CheckboxGroup verticalLayout>
              <Checkbox
                label={getBeforeOptions(
                  'Approved',
                  'ListPage.FormsJoinView.WEBINAR_REGISTRATION.CreateSegmentsModal.Content.Before.Option.Tooltip.Approved'
                )}
                checked={beforeRules.approved}
                onChange={(approved) => setBeforeRules({ ...beforeRules, approved })}
                disabled={disabledBeforeSegments.approved || beforeRulesStatus.approved}
                showTickOnDisabled
              />
              <Checkbox
                label={getBeforeOptions(
                  'Rejected',
                  'ListPage.FormsJoinView.WEBINAR_REGISTRATION.CreateSegmentsModal.Content.Before.Option.Tooltip.Rejected'
                )}
                checked={beforeRules.rejected}
                onChange={(rejected) => setBeforeRules({ ...beforeRules, rejected })}
                disabled={disabledBeforeSegments.rejected || beforeRulesStatus.rejected}
                showTickOnDisabled
              />
              <Checkbox
                label={getBeforeOptions(
                  'Pending',
                  'ListPage.FormsJoinView.WEBINAR_REGISTRATION.CreateSegmentsModal.Content.Before.Option.Tooltip.Pending'
                )}
                checked={beforeRules.pending}
                onChange={(pending) => setBeforeRules({ ...beforeRules, pending })}
                disabled={disabledBeforeSegments.pending || beforeRulesStatus.pending}
                showTickOnDisabled
              />
            </CheckboxGroup>
          </div>
          <div>
            <Typography
              text={t('ListPage.FormsJoinView.WEBINAR_REGISTRATION.CreateSegmentsModal.Content.FollowUp.Title')}
              type={TextType.BODY_TEXT_LARGE}
              weight={TextWeight.MEDIUM}
              className={`${rootClass}__section-title`}
            />
            <CheckboxGroup verticalLayout>
              <Checkbox
                label={
                  <div className={`${rootClass}__section-rule`}>
                    <Typography
                      text={'ListPage.FormsJoinView.WEBINAR_REGISTRATION.CreateSegmentsModal.Content.FollowUp.Signed.Approved.Attended'}
                      tagComponents={{
                        Connector: connectorPill(),
                      }}
                      weight={TextWeight.MEDIUM}
                    />
                    <InfoTooltip>
                      {t('ListPage.FormsJoinView.WEBINAR_REGISTRATION.CreateSegmentsModal.Content.FollowUp.Tooltip.Signed.Approved.Attended')}
                    </InfoTooltip>
                  </div>
                }
                checked={followUpRules.signedApprovedAttended}
                onChange={(signedApprovedAttended) => setFollowUpRules({ ...followUpRules, signedApprovedAttended })}
                disabled={disabledFollowUpSegments.signedApprovedAttended || followUpRulesStatus.signedApprovedAttended}
                showTickOnDisabled
              />
              <Checkbox
                label={
                  <div className={`${rootClass}__section-rule`}>
                    <Typography
                      text={'ListPage.FormsJoinView.WEBINAR_REGISTRATION.CreateSegmentsModal.Content.FollowUp.NotSigned.Attended'}
                      tagComponents={{
                        Connector: connectorPill(),
                      }}
                      weight={TextWeight.MEDIUM}
                    />
                    <InfoTooltip>
                      {t('ListPage.FormsJoinView.WEBINAR_REGISTRATION.CreateSegmentsModal.Content.FollowUp.Tooltip.NotSigned.Attended')}
                    </InfoTooltip>
                  </div>
                }
                checked={followUpRules.notSignedAttended}
                onChange={(notSignedAttended) => setFollowUpRules({ ...followUpRules, notSignedAttended })}
                disabled={disabledFollowUpSegments.notSignedAttended || followUpRulesStatus.notSignedAttended}
                showTickOnDisabled
              />
              <Checkbox
                dataTest={`${dataTest}-checkbox-signed-approved-no-show`}
                label={
                  <div className={`${rootClass}__section-rule`}>
                    <Typography
                      text={'ListPage.FormsJoinView.WEBINAR_REGISTRATION.CreateSegmentsModal.Content.FollowUp.Signed.Approved.NoShow'}
                      tagComponents={{
                        Connector: connectorPill(),
                      }}
                      weight={TextWeight.MEDIUM}
                    />
                    <InfoTooltip>
                      {t('ListPage.FormsJoinView.WEBINAR_REGISTRATION.CreateSegmentsModal.Content.FollowUp.Tooltip.Signed.Approved.NoShow')}
                    </InfoTooltip>
                  </div>
                }
                checked={followUpRules.signedApprovedNoShow}
                onChange={(signedApprovedNoShow) => setFollowUpRules({ ...followUpRules, signedApprovedNoShow })}
                disabled={disabledFollowUpSegments.signedApprovedNoShow || followUpRulesStatus.signedApprovedNoShow}
                showTickOnDisabled
              />
            </CheckboxGroup>
          </div>
        </div>
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form}>
        <Button buttonType={ButtonType.TERTIARY} onClick={handleClose} dataTest={`${dataTest}-button-tertiary`}>
          {t('Cancel')}
        </Button>
        <ButtonWithLoader
          className={`${rootClass}__submit-button`}
          loading={creatingSegments}
          onClick={handleAction}
          dataTest={`${dataTest}-button-primary`}
          disabled={!canSubmit}
        >
          {t('Create segments')}
        </ButtonWithLoader>
      </ModalFooter>
    </Modal>
  )
}

export default CreateWebinarSegmentsModal
