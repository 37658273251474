import { gql } from '@apollo/client'

export default gql`
  query checkMessageContentPersonalization($msgId: String!, $html: String!) {
    checkMessageContentPersonalization(msgId: $msgId, html: $html) {
      field
      isMissingForAll
      isCrmField
      isInvalidFormat
      lists
      hasFallbackText
    }
  }
`
