import React from 'react'

import classNames from 'classnames'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { stepThreeText } from '@src/pages/ContactSegments/components/CopySegments/components/StepThree/StepThree'

import './MappingHeaders.css'

const rootClass = 'step-three-mapping-headers'

const MappingHeaders = () => {
  const { t } = useTranslation()
  return (
    <div className={rootClass}>
      <div className={`${rootClass}__headers`}>
        <Typography
          className={`${rootClass}__headers-marketing-lists`}
          text={t(`${stepThreeText}Field.MarketingList.Column.Header`)}
          type={TextType.FIELD_MAPPING_HEADER_SMALL}
          weight={TextWeight.BOLD}
        />
        <div />
        <Typography
          className={`${rootClass}__headers-act-on-contacts`}
          text={t(`${stepThreeText}Field.AllContacts.Column.Header`)}
          type={TextType.FIELD_MAPPING_HEADER_SMALL}
          weight={TextWeight.BOLD}
        />
      </div>
      <div className={`${rootClass}__headers`}>
        <Typography className={`${rootClass}__header`} text={t(`Fields`)} type={TextType.TABLE_HEADER} inline />
        <div className={classNames(`${rootClass}__header`, `${rootClass}__header-arrow`)}>
          <Svg name={SvgNames.arrowRight} type={SvgType.LARGER_ICON} fill={SvgColor.LIGHT_GRAY} />
        </div>
        <Typography className={`${rootClass}__header`} text={t(`Fields`)} type={TextType.TABLE_HEADER} inline />
        <Typography className={`${rootClass}__header`} text={t(`Data Type`)} type={TextType.TABLE_HEADER} inline />
      </div>
    </div>
  )
}

export default MappingHeaders
