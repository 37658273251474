import { gql } from '@apollo/client'

export default gql`
  mutation createOrUpdateBeeEmailContent($beeEmailContentData: BeeEmailContentDTOInput) {
    createOrUpdateBeeEmailContent(beeEmailContentData: $beeEmailContentData) {
      categories
      publishAt
      searchTerms
      templateId
      thumbnailLargeUrl
      thumbnailUrl
      title
    }
  }
`
