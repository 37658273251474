import React, { ReactNode, useMemo, useState } from 'react'

import { t } from 'i18next'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import getSmsMessage from '@graphql/microservices/sms-management/getSmsMessage'
import { GetSmsMessageQuery, QueryGetSmsMessageArgs } from '@graphql/types/microservice/sms-management-types'
import {
  Program,
  ProgramMessage,
  ProgramSchedule,
  ProgramSegmentDetailsInput,
  ProgramSendChoice,
  ProgramSetFieldChoice,
  ProgramSetFieldOperation,
  ProgramSource,
  ProgramTrack,
} from '@graphql/types/query-types'
import { getProgramId } from '@src/pages/listingPages/AutomatedPrograms/AutomatedProgramsListingPage.helpers'
import { ChangeFieldRules } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/components/ListProgramStep/components/ChangeFieldStepEditor/utils/ChangeFieldStepEditor.constants'
import AddCRMTaskDetail from '@src/pages/programs/dashboard/components/ProgramSteps/components/AddCRMTaskDetail/AddCRMTaskDetail'
import AddToCRMDetail from '@src/pages/programs/dashboard/components/ProgramSteps/components/AddToCRMDetail/AddToCRMDetail'
import APIStepDetail, { Header } from '@src/pages/programs/dashboard/components/ProgramSteps/components/APIStepDetail/APIStepDetail'
import BranchStepDetail, {
  BranchStepDetailProps,
} from '@src/pages/programs/dashboard/components/ProgramSteps/components/BranchStepDetail/BranchStepDetail'
import ChangeFieldStepDetail from '@src/pages/programs/dashboard/components/ProgramSteps/components/ChangeFieldStepDetail/ChangeFieldStepDetail'
import {
  CFSCondition,
  CFSConditionType,
  CFSOperation,
  FieldSetOptions,
} from '@src/pages/programs/dashboard/components/ProgramSteps/components/ChangeFieldStepDetail/ChangeFieldStepDetail.constants'
import CopyToListStep from '@src/pages/programs/dashboard/components/ProgramSteps/components/CopyToListStep/CopyToListStep'
import CreateInCRMDetail, {
  EntityFields,
  StaticRecordFields,
} from '@src/pages/programs/dashboard/components/ProgramSteps/components/CreateInCRMDetail/CreateInCRMDetail'
import ExpandableStepDescription from '@src/pages/programs/dashboard/components/ProgramSteps/components/ExpandableStepDescription/ExpandableStepDescription'
import GoToStepDetail from '@src/pages/programs/dashboard/components/ProgramSteps/components/GoToStepDetail/GoToStepDetail'
import OptOutStepDetail from '@src/pages/programs/dashboard/components/ProgramSteps/components/OptOutStepDetail/OptOutStepDetail'
import SendAlertDetail from '@src/pages/programs/dashboard/components/ProgramSteps/components/SendAlertDetail/SendAlertDetail'
import SendEmailStepDetail, {
  SendChoice,
  SendEmailStepMode,
} from '@src/pages/programs/dashboard/components/ProgramSteps/components/SendEmailStepDetail/SendEmailStepDetail'
import SendSMSStepDetail from '@src/pages/programs/dashboard/components/ProgramSteps/components/SendSMSStepDetail/SendSMSStepDetail'
import StartStepDetail from '@src/pages/programs/dashboard/components/ProgramSteps/components/StartStepDetail/StartStepDetail'
import WaitStepDetail from '@src/pages/programs/dashboard/components/ProgramSteps/components/WaitStepDetail/WaitStepDetail'
import { ExpandableSteps } from '@src/pages/programs/dashboard/components/ProgramSteps/ProgramStepsContainer'
import { getLetter } from '@src/pages/programs/dashboard/ProgramSteps.utils'
import { SEGMENT_OPTIONS } from '@src/pages/programs/edit/components/ProgramFlow/components/ConditionalSelect/segmentOptions'
import {
  FormatOptions,
  MethodOptions,
} from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditAPIStep/EditAPItep.constants'
import { EditCRMStepV2Container } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/EditCRMStepV2Container'
import { CellContext } from '@tanstack/react-table'
import { useAccountSettings } from '@utils/account/account.utils'
import { dayOfWeek, dayWithOrdinal } from '@utils/date'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import programUtils, { hasAOContactsSource } from '@utils/program/program'
import {
  ProgramAlertStepExt,
  ProgramBranchStepExt,
  ProgramCopyStepExt,
  ProgramCreateInCRMStepExt,
  ProgramErrors,
  ProgramGoToStepExt,
  ProgramRecordSetStepExt,
  ProgramSendStepExt,
  ProgramSForceCampaignStepExt,
  ProgramSmsSendStepExt,
  ProgramStepType,
  ProgramTaskStepExt,
  ProgramWaitStepExt,
  ProgramWaitUntilInSegmentStepExt,
  ProgramWaitUntilStepExt,
  ProgramWebRequestStepExt,
  Step,
} from '@utils/program/program.constants'
import { ProgramBaseStepWithStepNum, ProgramStep as ProgramStepIntf, ProgramWithStepData, StepData } from '@utils/program/ProgramSteps.constants'

import ProgramStepLink from './components/ProgramStepLink/ProgramStepLink'
import { ProgramStepsProps } from './ProgramSteps'

import './ProgramSteps.css'

const rootClass = 'program-steps'

export const getRuleText = (rule: FieldSetOptions | ChangeFieldRules) => {
  switch (rule) {
    case FieldSetOptions.INCR:
      return 'Increase the value by {{value}}'
    case FieldSetOptions.DECR:
      return 'Decrease the value by {{value}}'
    case FieldSetOptions.CAMPAIGN:
      return 'Set value to campaign score to {{value}}'
    case ChangeFieldRules.SET_CURRENT_DATE:
      return 'Set field to current date'
    case FieldSetOptions.SCORE:
      return 'Set value to behavioral score to {{value}}'
    case FieldSetOptions.CONST:
      return 'Set the value to {{value}}'
    case ChangeFieldRules.CLEAR_VALUE:
      return 'Clear the value'
    case FieldSetOptions.UID:
      return 'Assign unique number in list'
    case ChangeFieldRules.SET_TIMEZONE:
      return 'Set value to timezone'
    default:
      return ''
  }
}

export const getStepData = (stepId: string, stepData: StepData[]) => stepData.find((step) => step.stepId === stepId)

const findStepName = (stepId: string, tracks: ProgramTrack[]) => {
  const steps = tracks
    .flatMap((track, idxT) => {
      return track.steps.map((step, idxS) => {
        const trackLetter = getLetter(idxT)
        // The 'A' track will contain the 'Start' step which is A-0
        const index = trackLetter === 'A' ? idxS : idxS + 1
        return {
          ...step,
          stepNumber: `${trackLetter}-${index}`,
        }
      })
    })
    .find((step) => stepId === step.stepId)

  return `${steps?.displayName} ${steps?.stepNumber}`
}

export const findFirstStepInTrack = (trackId: string, tracks: ProgramTrack[]) => {
  const foundTrack = tracks.find((track) => track.id === trackId)
  if (foundTrack) {
    return foundTrack.steps[0]
  }

  throw new Error('Could not find track')
}

export const findListName = (listId: string, lists: ProgramSource[]) => {
  return lists.find((list) => list.id === listId)?.name ?? ''
}

export const findMessageName = (msgId: string, messages: ProgramMessage[]) => {
  return messages.find((message) => message.id === msgId)?.name ?? ''
}

export const convertScheduleToTime = (schedule: ProgramSchedule, t: Function) => {
  if (schedule.isUnscheduled) {
    return ''
  }

  const weekdaysOnly = `each ${schedule.weekdaysOnly ? 'week' : ''}day`
  const specificDate = `on ${schedule.waitUntilDateFormatted}`
  const dayStr =
    schedule.type === 'DAILY'
      ? weekdaysOnly
      : schedule.type === 'DATE'
      ? specificDate
      : schedule.type === 'WEEKLY'
      ? dayOfWeek(t, (schedule?.interval ?? 1) - 1)
      : schedule.type === 'MONTHLY'
      ? dayWithOrdinal(schedule.interval)
      : ''

  const actualTime = `${schedule.hour === 0 ? 12 : schedule.hour}:${(schedule.minute ?? 0) < 10 ? `0${schedule.minute}` : schedule.minute} ${
    schedule.ampm === 0 ? 'AM' : 'PM'
  }`
  if (schedule.type === 'WEEKLY') {
    return `Every ${dayStr} at ${actualTime}`
  }
  if (schedule.type === 'MONTHLY') {
    return `On the ${dayStr} of each month at ${actualTime}`
  }

  const interval = schedule.type === 'DATE' ? 'until ' : schedule.interval === 0 ? ' at ' : ` every ${schedule.interval} hours from `
  const endTime = schedule.hasEndTime
    ? ` until ${schedule.endHour}:${(schedule.endMinute ?? 0) < 10 ? `0${schedule.endMinute}` : schedule.endMinute} ${
        schedule.endAmpm === 0 ? 'AM' : 'PM'
      }`
    : ''
  const prefix = schedule.type === 'DATE' ? 'Wait' : 'Check'

  return `${prefix} ${interval}${actualTime}${endTime} ${dayStr}`
}

export const buildWaitUntilSpecificDateStepCondition = (waitStep: ProgramWaitUntilStepExt, t: Function): ReactNode => {
  const timeStr = convertScheduleToTime(waitStep.schedule, t)

  return <Typography text={timeStr} inline />
}

export const buildWaitUntilInSegmentStepCondition = (waitStep: ProgramWaitUntilInSegmentStepExt, program: ProgramWithStepData): ReactNode => {
  const lists = program.sources
  const messages = program.messages
  const localSegmentDetails = programUtils.getLocalSegment(program, waitStep.srcId)
  let operator = (waitStep.not ? SEGMENT_OPTIONS[1] : SEGMENT_OPTIONS[0]).text.replace(/If/, 'The')
  if (localSegmentDetails) {
    const segment = getSegment(waitStep.not ?? false, localSegmentDetails)
    operator = (SEGMENT_OPTIONS.find((SEGMENT_OPTION) => SEGMENT_OPTION.type === segment) ?? { text: '' }).text.replace(/If/, 'The')
  }
  const listName = localSegmentDetails?.idset?.map((id) => findMessageName(id, messages)) ?? findListName(waitStep?.srcId ?? '', lists)
  const timeStr = convertScheduleToTime(waitStep.schedule, t)
  const waitForScheduledRun = waitStep.waitForScheduledRun
    ? 'If a contact meets the condition when they enter the step, do not proceed immediately but wait until the scheduled time for them to proceed.'
    : ''

  const listElement = listName === '' ? <Typography text={t('undefined')} weight={TextWeight.ITALIC} inline /> : <Typography text={listName} inline />

  return (
    <>
      <Typography text={t('Wait until')} inline /> <Typography text={operator.toLowerCase()} inline />{' '}
      <Svg className={`${rootClass}__icon`} name={SvgNames.contacts} type={SvgType.ICON} /> {listElement}. <Typography text={`${timeStr}.`} inline />{' '}
      <Typography text={t(waitForScheduledRun)} inline />
    </>
  )
}

const DEFAULT_PROGRAM_OPERATION: ProgramSetFieldOperation = {
  rule: '',
  value: '',
  fieldName: '',
  campaignId: '',
  scoreSheetId: '',
}

const BLANK_OPERATION: CFSOperation = {
  fieldName: '',
  rule: '' as FieldSetOptions,
  value: '',
}

const BLANK_CONDITION: CFSCondition = {
  name: '',
  condition: '',
  type: CFSConditionType.LIST,
  msgId: '',
  operations: [BLANK_OPERATION],
}

const BLANK_SEND_CHOICE = {
  msgId: '',
  name: '',
  operator: '',
  condition: '',
  isDefault: false,
}

interface RenderDetailComponentParams {
  step: Step
  program: ProgramWithStepData
  moveNext: (stepId: string) => void
  setAbWinner?: (programId: string, stepId: string, msgId: string, name: string) => void
  resetAbTest?: (programId: string, stepId: string) => void
}

export const getSegment = (not: boolean, localSegmentDetails?: ProgramSegmentDetailsInput) => {
  if (!localSegmentDetails) {
    return not ? 'NOT_IN' : 'IN'
  } else {
    return (localSegmentDetails.name ?? '') === 'MESSAGE_SEND' && not ? 'NOT_MESSAGE_SEND' : localSegmentDetails.name
  }
}

export const convertOperationtoCFSOperation = (operation?: ProgramSetFieldOperation): CFSOperation => {
  if (!operation) {
    return BLANK_OPERATION
  }
  return {
    fieldName: operation.fieldName ?? '',
    rule: operation.rule as FieldSetOptions,
    value: operation.value ?? '',
  }
}

export const convertProgramSendChoiceToSendChoice = (sendChoices: ProgramSendChoice[], program: Program): SendChoice[] => {
  const lists = program.sources
  const messages = program.messages
  return sendChoices.map((sendChoice, idx) => {
    const localSegmentDetails = programUtils.getLocalSegment(program, sendChoice.srcId)
    const segment = getSegment(sendChoice.not ?? false, localSegmentDetails)
    const operator = (SEGMENT_OPTIONS.find((SEGMENT_OPTION) => SEGMENT_OPTION.type === segment) ?? { text: '' }).text.replace(/If/, 'IF')
    return sendChoice && sendChoice.msgId
      ? {
          msgId: sendChoice.msgId,
          name: findMessageName(sendChoice.msgId, program.messages),
          operator: idx > 0 ? `ELSE ${operator}` : operator,
          condition: !localSegmentDetails
            ? findListName(sendChoice.srcId ?? '', lists)
            : localSegmentDetails && localSegmentDetails.idset
            ? localSegmentDetails.idset.map((id) => findMessageName(id, messages)).join(' or ')
            : '',
          isDefault: false,
          sent: sendChoice.sends ?? 0,
          clicked: sendChoice.clicks ?? 0,
        }
      : BLANK_SEND_CHOICE
  })
}

export const getBranchCondition = (branchStep: ProgramBranchStepExt, program: ProgramWithStepData) => {
  const localSegmentDetails = programUtils.getLocalSegment(program, branchStep.srcId)
  const segment = getSegment(branchStep.not ?? false, localSegmentDetails)
  return (SEGMENT_OPTIONS.find((SEGMENT_OPTION) => SEGMENT_OPTION.type === segment) ?? { text: '' }).text.replace(/If/, 'IF')
}

export const getBranchSource = (branchStep: ProgramBranchStepExt, program: ProgramWithStepData) => {
  const source = program.sources.find((source) => source.id === branchStep.srcId)

  if (source) {
    return {
      id: source?.id ?? '',
      name: source?.name ?? '',
    }
  }
}

export const getBranchMessages = (branchStep: ProgramBranchStepExt, program: ProgramWithStepData) => {
  const localSegmentDetails = programUtils.getLocalSegment(program, branchStep.srcId)
  const messages = localSegmentDetails?.idset?.map((id) => program.messages.find((message) => message.id === id))

  return messages
    ?.filter((message) => message !== undefined)
    .map((message) => {
      return {
        id: message?.id ?? '',
        name: message?.name ?? '',
      }
    })
}

export const convertUserIdsToNames = (program: ProgramWithStepData, userIds?: number[]) => {
  if (!userIds) {
    return []
  }

  return userIds.map((userId) => program.users?.find((user) => user.id === userId)?.email ?? '')
}

export const convertToCFSConditions = (conditions: ProgramSetFieldChoice[], program: ProgramWithStepData): CFSCondition[] => {
  return conditions.map((condition, idx) => {
    if (!condition.srcId) {
      return BLANK_CONDITION
    }

    const localSegmentDetails = condition.srcId[0] === 'l' ? undefined : programUtils.getLocalSegment(program, condition.srcId)

    const segment = getSegment(condition.not ?? false, localSegmentDetails)

    return {
      name: !localSegmentDetails
        ? findListName(condition.srcId, program.sources)
        : localSegmentDetails && localSegmentDetails.idset
        ? localSegmentDetails.idset.map((id) => findMessageName(id, program.messages)).join(' or ')
        : '',
      condition: (SEGMENT_OPTIONS.find((SEGMENT_OPTION) => SEGMENT_OPTION.type === segment) ?? { text: '' }).text.replace(
        /If/,
        idx === 0 ? 'IF' : 'ELSE IF'
      ),
      type: localSegmentDetails ? CFSConditionType.EMAIL : CFSConditionType.LIST,
      msgId: condition.srcId ?? '',
      operations: (condition.operations ?? [DEFAULT_PROGRAM_OPERATION]).map(convertOperationtoCFSOperation),
    }
  })
}

export const getDisplayedSendChoices = (program: Program, step: ProgramSendStepExt, msgId: string) => {
  const sendChoices: SendChoice[] =
    msgId === ''
      ? []
      : [
          {
            ...BLANK_SEND_CHOICE,
            msgId,
            name: findMessageName(msgId, program.messages),
            isDefault: true,
          },
        ]
  if (step.sendChoices.length > 0) {
    return [...sendChoices, ...convertProgramSendChoiceToSendChoice(step.sendChoices, program)]
  }
  return sendChoices
}

export const getBranchStepDetailProps = (step: ProgramBranchStepExt, program: ProgramWithStepData): BranchStepDetailProps => {
  const list = getBranchSource(step, program)
  const condition = getBranchCondition(step, program)
  const messages = getBranchMessages(step, program)
  const goToStep = findFirstStepInTrack(step.goToStepId ?? '', program.tracks)

  return { list, condition, messages, goToStepType: goToStep.stepType as ProgramStepType, programId: program.id, goToStepName: goToStep.displayName }
}

export const RenderDetailComponent = ({ step, program, moveNext, setAbWinner, resetAbTest }: RenderDetailComponentParams) => {
  const { id: programId, tracks, sourceList, sources: lists } = program
  const stepType = step.stepType
  const [smsMessage, setSmsMessage] = useState('')
  const hasAOContacts = hasAOContactsSource(program)
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.SMS_MANAGEMENT })

  const { accountId, hasNewCRMStep } = useAccountSettings()

  let tsStep
  const stepData = program.stepData.find((dStep) => dStep.stepId === step.stepId)
  const canMove = (stepData?.waiting ?? 0) > 0 && program.runStatus.isRunning

  const stepDetail = useMemo(() => {
    switch (stepType) {
      case ProgramStepType.START:
        return <StartStepDetail sourceLists={sourceList} />
      case ProgramStepType.BRANCH:
        tsStep = step as ProgramBranchStepExt
        const branchProps = getBranchStepDetailProps(tsStep, program)
        return <BranchStepDetail {...branchProps} />
      case ProgramStepType.GOTO:
        tsStep = step as ProgramGoToStepExt
        const stepName = findStepName(step.goToStepId ?? '', tracks)
        return <GoToStepDetail stepType={tsStep.stepType as ProgramStepType} stepName={stepName} />
      case ProgramStepType.OPT_OUT:
        return <OptOutStepDetail />
      case ProgramStepType.TASK:
        tsStep = step as ProgramTaskStepExt
        return (
          <AddCRMTaskDetail
            status={tsStep.status ?? ''}
            subject={tsStep.subject ?? ''}
            priority={tsStep.priority ?? ''}
            comment={tsStep.comment}
            ownerName={tsStep.ownerName}
            dateAmount={tsStep.dateAmount}
            dateUnit={tsStep.dateUnit}
          />
        )
      case ProgramStepType.CRM_CAMPAIGN:
        tsStep = step as ProgramSForceCampaignStepExt
        return <AddToCRMDetail campaignName={tsStep.campaignName ?? ''} status={tsStep.status ?? ''} />
      case ProgramStepType.CRM_CREATE:
        tsStep = step as ProgramCreateInCRMStepExt
        return hasNewCRMStep ? (
          <EditCRMStepV2Container step={tsStep} program={program} isRunning />
        ) : (
          <CreateInCRMDetail
            pushType={tsStep.pushType}
            additionalEntities={tsStep.additionalEntities as EntityFields[]}
            staticFields={tsStep.staticFields as StaticRecordFields[]}
            insertAlways={tsStep.insertAlways ?? false}
            pushNewRecords={tsStep.pushNewRecords ?? false}
            updateExisting={tsStep.updateExisting ?? false}
            updateMultipleMatches={tsStep.updateMultipleMatches ?? false}
            useListPushPolicy={tsStep.useListPushPolicy ?? false}
          />
        )
      case ProgramStepType.FIELD_SET:
        tsStep = step as ProgramRecordSetStepExt
        if (tsStep.listId && tsStep.conditions && tsStep.operations) {
          const listName = findListName(tsStep.listId, lists)
          return (
            <ChangeFieldStepDetail
              listName={listName}
              programId={programId}
              conditions={convertToCFSConditions(tsStep.conditions, program)}
              operations={tsStep.operations.map(convertOperationtoCFSOperation)}
            />
          )
        }
        return null
      case ProgramStepType.ALERT:
        tsStep = step as ProgramAlertStepExt
        return (
          <SendAlertDetail
            message={findMessageName(tsStep.message ?? '', program.messages)}
            crmOwner={tsStep.crmOwner ?? false}
            emails={tsStep.emails ?? []}
            subject={tsStep.subject ?? ''}
            users={convertUserIdsToNames(program, tsStep.userIds)}
          />
        )
      case ProgramStepType.COPY:
      case ProgramStepType.COPY_FROM:
      case ProgramStepType.APPEND_TO_SEGMENT:
        tsStep = step as ProgramCopyStepExt
        const listName = findListName(tsStep.listId ?? '', lists)
        return <CopyToListStep listName={listName} listId={tsStep?.listId ?? ''} update={tsStep.update ?? false} isCopy={!hasAOContacts} />
      case ProgramStepType.EXIT:
        return null
      case ProgramStepType.SEND:
        tsStep = step as ProgramSendStepExt
        const msgId = tsStep.defaultMsgId ?? ''
        const sendChoices = getDisplayedSendChoices(program, tsStep, msgId)
        const sendMode = tsStep.sendChoices.length === 0 ? 'NoMode' : tsStep.sendMode
        const handleSetAbWinner = (programId: string, stepId: string, msgId: string, name: string) =>
          setAbWinner ? setAbWinner(programId, stepId, msgId, name) : alert('failed')
        const handleResetAbTest = (programId: string, stepId: string) =>
          resetAbTest ? resetAbTest(getProgramId(programId), stepId) : alert('failed')
        return (
          <SendEmailStepDetail
            programId={programId}
            sendMode={sendMode as SendEmailStepMode}
            sendChoices={sendChoices}
            abThreshold={tsStep.abThreshold}
            abWinner={tsStep.abWinner ?? ''}
            stepId={tsStep.stepId}
            handleSetAbWinner={handleSetAbWinner}
            handleResetAbTest={handleResetAbTest}
          />
        )
      case ProgramStepType.WAIT:
        tsStep = step as ProgramWaitStepExt
        const conditionSimple = <Typography text={`${t('Wait for')} ${tsStep.delay} ${t(tsStep.delayUnit)}${tsStep.delay > 1 ? 's' : ''}`} />
        return <WaitStepDetail stepId={tsStep.stepId} condition={conditionSimple} canMove={canMove} moveNext={() => moveNext(step.stepId)} />
      case ProgramStepType.WAIT_UNTIL:
        tsStep = step as ProgramWaitUntilStepExt
        const conditionUntil = buildWaitUntilSpecificDateStepCondition(tsStep, t)
        return <WaitStepDetail stepId={tsStep.stepId} condition={conditionUntil} canMove={canMove} moveNext={() => moveNext(step.stepId)} />
      case ProgramStepType.WAIT_UNTIL_IN_SEGMENT:
        tsStep = step as ProgramWaitUntilInSegmentStepExt
        const conditionUntilSegment = buildWaitUntilInSegmentStepCondition(tsStep, program)
        return <WaitStepDetail stepId={tsStep.stepId} condition={conditionUntilSegment} canMove={canMove} moveNext={() => moveNext(step.stepId)} />
      case ProgramStepType.EXTERNAL_API:
        tsStep = step as ProgramWebRequestStepExt
        return (
          <APIStepDetail
            customFields={tsStep.custom as Header[]}
            format={tsStep.format as FormatOptions}
            headers={tsStep.headers}
            httpHeaders={tsStep.httpHeaders as Header[]}
            method={tsStep.method as MethodOptions}
            url={tsStep.url ?? ''}
          />
        )
      case ProgramStepType.SMS:
        tsStep = step as ProgramSmsSendStepExt
        client
          .query<GetSmsMessageQuery, QueryGetSmsMessageArgs>({
            query: getSmsMessage,
            fetchPolicy: 'network-only',
            variables: {
              smsMessageId: tsStep.smsMessageId,
              accountId,
            },
          })
          .then((data) => {
            if (data.data.getSmsMessage) {
              setSmsMessage(data.data.getSmsMessage?.messageText ?? '')
            }
          })

        return <SendSMSStepDetail message={smsMessage} />
      default:
        return null
    }
  }, [smsMessage])

  return stepDetail
}

interface UnsortedStep extends ProgramStepIntf {
  sorted: boolean
}

export const getSortedProgramSteps = (
  program: ProgramWithStepData,
  programErrors: ProgramErrors,
  moveNext: (stepId: string) => void,
  expansion: ExpandableSteps,
  handleSetAbWinner?: (programId: string, stepId: string, msgId: string, name: string) => void,
  handleResetAbTest?: (programId: string, stepId: string) => void
): ProgramStepIntf[] => {
  const { tracks } = program

  const steps: UnsortedStep[] = tracks.flatMap((track, idxT) => {
    return track.steps.map((step) => {
      const stepData = getStepData(step.stepId ?? '', program.stepData)
      if (stepData) {
        const tsStep = step as Step
        const errorMessage = programErrors?.stepErrors?.find((stepError) => stepError.id === tsStep.stepId)?.errors.join(', ')
        return {
          ...stepData,
          description: (
            <ExpandableStepDescription
              expansion={expansion}
              step={tsStep}
              errorMessage={errorMessage}
              program={program}
              moveNext={moveNext}
              handleSetAbWinner={step.stepType === ProgramStepType.SEND ? handleSetAbWinner : undefined}
              handleResetAbTest={step.stepType === ProgramStepType.SEND ? handleResetAbTest : undefined}
            />
          ),
          stepNumber: tsStep.letter,
          stepType: tsStep.stepType,
          displayName: tsStep.displayName,
          depth: idxT + 1,
          goToStepId: tsStep.goToStepId,
          sorted: false,
        }
      } else {
        return {} as UnsortedStep
      }
    })
  })

  const sortedSteps: ProgramStepIntf[] = []
  let currDepth = 1

  steps.forEach((step) => {
    if (!sortedSteps.some((sStep) => sStep.stepId === step.stepId)) {
      sortedSteps.push({ ...step, depth: currDepth })
      if (step.goToStepId) {
        const branchRecursion = (step: UnsortedStep) => {
          const branchToTrack = tracks.find((track) => track.id === step.goToStepId)
          if (branchToTrack) {
            currDepth += 1
            branchToTrack.steps.forEach((bStep) => {
              const branchStep = steps.find((uStep) => uStep.stepId === bStep.stepId)
              if (branchStep) {
                branchStep.sorted = true
                sortedSteps.push({ ...branchStep, depth: currDepth })
                if (branchStep.goToStepId) {
                  branchRecursion(branchStep)
                  currDepth = currDepth > 1 ? currDepth - 1 : 1
                }
              }
            })
          }
        }

        branchRecursion(step)
        currDepth = currDepth > 1 ? currDepth - 1 : 1
      }
    }
  })

  return sortedSteps
}

export const renderStepNumberV2 = (cell: CellContext<ProgramBaseStepWithStepNum, unknown>) => (
  <Typography className="ellip" text={cell.cell.row.original.stepNumber} type={TextType.BODY_TEXT_LIGHT} lineHeight={LineHeight.MEDIUM_LARGE} />
)

export const renderProgramStepLinkV2 = (
  column: string,
  cell: CellContext<ProgramBaseStepWithStepNum, unknown>,
  programId: string,
  value: string,
  tabChange?: ProgramStepsProps['tabChange']
) => <ProgramStepLink value={value} column={column} uiStepName={cell.cell.row.original.stepNumber} programId={programId} tabChange={tabChange} />
