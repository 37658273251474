import {
  AlertFilter,
  AlertFilterType,
  Condition,
  ConditionField,
  LogicalCondition,
} from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertFilters/utils/AlertFilters.utils'

export const myLeadsAndContactsMock: AlertFilter = {
  id: 1,
  type: AlertFilterType.MY_LEADS_AND_CONTACTS,
  conditions: [],
}

export const allVisitorsMock: AlertFilter = {
  id: 2,
  type: AlertFilterType.ALL_VISITORS,
  logicalCondition: LogicalCondition.AND,
  conditions: [
    {
      field: ConditionField.EMAIL_ADDRESS,
      condition: Condition.CONTAINS,
      value: '@nike',
    },
    {
      logicalCondition: LogicalCondition.AND,
      field: ConditionField.COMPANY_NAME,
      condition: Condition.NOT_CONTAINS,
      value: 'Act-On',
    },
  ],
}

export const membershipMock: AlertFilter = {
  id: 3,
  type: AlertFilterType.MEMBERSHIP,
  conditions: [],
}

export const mediaDownloadsMock: AlertFilter = {
  id: 4,
  type: AlertFilterType.MEDIA_DOWNLOADS,
  conditions: [],
}

export const alertsFiltersMock: AlertFilter[] = [
  {
    id: 0,
    type: AlertFilterType.ALL_VISITORS,
    logicalCondition: LogicalCondition.AND,
    conditions: [
      {
        field: ConditionField.EMAIL_ADDRESS,
        condition: Condition.CONTAINS,
        value: '@nike',
      },
      {
        logicalCondition: LogicalCondition.AND,
        field: ConditionField.COMPANY_NAME,
        condition: Condition.NOT_CONTAINS,
        value: 'Act-On',
      },
    ],
  },
  {
    id: 1,
    type: AlertFilterType.ANONYMOUS_VISITORS,
    logicalCondition: LogicalCondition.OR,
    conditions: [
      {
        field: ConditionField.AREA_CODE,
        condition: Condition.ENDS_WITH,
        value: '77',
      },
      {
        logicalCondition: LogicalCondition.NOT,
        field: ConditionField.STATE,
        condition: Condition.NOT_CONTAINS,
        value: 'Texas',
      },
    ],
  },
  {
    id: 2,
    type: AlertFilterType.KNOWN_VISITORS,
    logicalCondition: LogicalCondition.AND,
    conditions: [
      {
        field: ConditionField.COUNTRY,
        condition: Condition.CONTAINS,
        value: 'Canada',
      },
      {
        logicalCondition: LogicalCondition.AND,
        field: ConditionField.COMPANY_NAME,
        condition: Condition.NOT_CONTAINS,
        value: 'Act-On',
      },
    ],
  },
]
