export const timeoutOptionsInHours = [1, 2, 4, 8, 12, 24]
export const passwordLengthOptions = [4, 5, 6, 7, 8, 9, 10, 11, 12]
export const passwordComplexityOptions = [0, 1, 2, 3, 4, 5]
export const passwordExpirationDays = ['Never', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 30, 60, 90, 120, 180, 360]
export const historyPasswordsCount = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

export type SecurityOptionType = { id: string; label: string }

export const SECURITY_GLOBAL_BCC_MORE_INFO_URL =
  'https://success.act-on.com/acton/attachment/9883/u-e453234c-cc66-478a-bb85-80bc9afa9454/0/-/-/-/-/In-App%3A%20Custom%20Account%20Settings%20%3E%20Global%20BCC%20'
