import React, { useState, FC } from 'react'

import classNames from 'classnames'

import Spinner from '@components/Spinner/Spinner'

import './htmlPreview.css'

interface HTMLPreviewProps {
  html: string
  className?: string
  dataTest?: string
}

const rootClass = 'html-preview'

const HTMLPreview: FC<HTMLPreviewProps> = ({ html, className, dataTest = rootClass }) => {
  const [loading, setLoading] = useState<boolean>(true)

  const onLoad = (event: any) => {
    const iframe = event.target
    const style = document.createElement('style')
    style.textContent = `img {max-width: 100%; height: auto;}`
    iframe.width = iframe.contentWindow.document.body.scrollWidth + 24
    iframe.contentDocument.head.appendChild(style)
    iframe.height = iframe.contentWindow.document.body.scrollHeight + 24
    setTimeout(() => setLoading(false), 500)
  }

  return (
    <div
      className={classNames(rootClass, className, {
        [`${rootClass}__loading`]: loading,
      })}
      data-test={dataTest}
    >
      {loading && <Spinner />}
      <div
        className={classNames(`${rootClass}__iframe-container`, {
          [`${rootClass}__iframe-container--hidden`]: loading,
        })}
      >
        <iframe className={`${rootClass}__iframe`} title="iframe" srcDoc={html} scrolling="no" onLoad={onLoad} data-test={`${dataTest}-iframe`} />
      </div>
    </div>
  )
}

export default HTMLPreview
