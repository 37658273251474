import React, { FC, useContext, useState } from 'react'

import classNames from 'classnames'

import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { useTranslation } from '@const/globals'
import NumericCriteria, {
  NumericCriteriaType,
} from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/NumericCriteria/NumericCriteria'
import { getScoreTypeOptions } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/ScoreExpression/ScoreExpression.utils'
import { SegmentComposerContext } from '@src/pages/SegmentComposer/SegmentComposer.context'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'

import './ScoreExpression.css'

interface ScoreExpressionProps {
  className?: string
  dataTest?: string
}

interface ScoreExpressionState {
  scoreType: SelectV2SingleOption
  campaigns: SelectV2SingleOption[]
  scoreSheets: SelectV2SingleOption[]
  numericCriteria: NumericCriteriaType
}

const rootClass = 'score-expression'
const defaultScoreIndex = 0

const ScoreExpression: FC<ScoreExpressionProps> = (props: ScoreExpressionProps) => {
  const { dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const scoreTypeOptions = getScoreTypeOptions(t)
  const defaultScoreType = scoreTypeOptions[defaultScoreIndex]
  const {
    values: { scoreSheetOptions, campaignOptions },
  } = useContext(SegmentComposerContext)

  const [state, setState] = useState<ScoreExpressionState>({
    scoreType: defaultScoreType,
    numericCriteria: {},
    scoreSheets: [],
    campaigns: [],
  })

  const { scoreType, numericCriteria, scoreSheets, campaigns } = state
  const update = useDeepUpdate(setState)

  const renderNextCondition = () => {
    if (scoreType.extraOptions) {
      const nextConditionType = scoreType.extraOptions.nextConditionType
      if (nextConditionType === 'NUMERIC') {
        return <NumericCriteria numericCriteria={numericCriteria} onChange={(numericCriteria) => update({ numericCriteria })} />
      }
      if (nextConditionType === 'SCORE_SHEET') {
        return (
          <>
            <SelectV2
              dataTest={`${dataTest}-score-sheet-select`}
              className={`${rootClass}__score-sheet-select`}
              placeholder={t('Select score sheet')}
              options={scoreSheetOptions}
              value={scoreSheets}
              onChangeMultiple={(scoreSheets) => update({ scoreSheets })}
              truncateMultiValues
              minSearchOptions={1}
            />
            {scoreType.value === 'CAMPAIGN_SCORE' && (
              <SelectV2
                dataTest={`${dataTest}-campaign-select`}
                className={`${rootClass}__campaign-select`}
                placeholder={t('Select campaign')}
                options={campaignOptions}
                value={campaigns}
                onChangeMultiple={(campaigns) => update({ campaigns })}
                truncateMultiValues
                minSearchOptions={1}
              />
            )}
            <NumericCriteria numericCriteria={numericCriteria} onChange={(numericCriteria) => update({ numericCriteria })} />
          </>
        )
      }
    }
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <SelectV2
        dataTest={`${dataTest}-score-select`}
        className={`${rootClass}__score-select`}
        options={scoreTypeOptions}
        defaultValue={scoreType}
        onChange={(scoreType?: SelectV2SingleOption) => update({ scoreType })}
        isClearable={false}
        isSearchable={false}
      />
      {renderNextCondition()}
    </div>
  )
}

export default ScoreExpression
