import { useCallback } from 'react'

import { ApolloError, useApolloClient, useMutation } from '@apollo/client'
import { useTranslation } from '@const/globals'
import addSeoFriendlyUrl from '@graphql/mutations/addSeoFriendlyUrl'
import deleteSeoFriendlyUrl from '@graphql/mutations/deleteSeoFriendlyUrl'
import editSeoFriendlyUrl from '@graphql/mutations/editSeoFriendlyUrl'
import {
  AddSeoFriendlyUrlMutation,
  AddSeoFriendlyUrlMutationVariables,
  DeleteSeoFriendlyUrlMutation,
  DeleteSeoFriendlyUrlMutationVariables,
  EditSeoFriendlyUrlMutation,
  EditSeoFriendlyUrlMutationVariables,
} from '@graphql/types/mutation-types'
import { UrlGroup } from '@graphql/types/query-types'
import { useLandingPageContext } from '@src/pages/Content/LandingPages/LandingPagesManager/context/LandingPagesManager.context'
import { logNewRelicError } from '@utils/new-relic.utils'

const NAME_IS_NOT_UNIQUE_ERROR_CODE = 31

const useSeoUrlActions = (): {
  loading: boolean
  handleAddSeoUrl: (name: string, groupId: string) => void
  handleEditSeoUrl: (name: string, groupId: string) => void
  handleDeleteSeoUrl: (groupId: string) => void
} => {
  const { t } = useTranslation()
  const client = useApolloClient()
  const {
    landingPage: { id: pageId },
    updateLandingPage,
    setToastStatus,
    refetchAccountSlugs,
  } = useLandingPageContext()

  const [addSeoUrl, { loading: loadingAdd }] = useMutation<AddSeoFriendlyUrlMutation, AddSeoFriendlyUrlMutationVariables>(addSeoFriendlyUrl, {
    client,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const [editSeoUrl, { loading: loadingEdit }] = useMutation<EditSeoFriendlyUrlMutation, EditSeoFriendlyUrlMutationVariables>(editSeoFriendlyUrl, {
    client,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const [deleteSeoUrl, { loading: loadingDelete }] = useMutation<DeleteSeoFriendlyUrlMutation, DeleteSeoFriendlyUrlMutationVariables>(
    deleteSeoFriendlyUrl,
    {
      client,
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    }
  )

  const handleSuccess = useCallback(
    ({ action, urlGroups }: { action: 'Add' | 'Edit' | 'Delete'; urlGroups: (UrlGroup | undefined)[] }) => {
      // First group always is default one
      const defaultUrlGroup = urlGroups.shift()
      updateLandingPage({ defaultUrlGroup, urlGroups })

      setToastStatus({
        showStatus: true,
        statusMessage: t(`${action}.SEO.URL.Success.Message`),
        successStatus: true,
      })
    },
    [updateLandingPage]
  )

  const handleError = useCallback(({ action, errorMessage }: { action: 'Add' | 'Edit' | 'Delete'; errorMessage?: string }) => {
    setToastStatus({
      showStatus: true,
      title: errorMessage ? t('Error!') : undefined,
      statusMessage: errorMessage ?? t(`${action}.SEO.URL.Error.Message`),
      successStatus: false,
    })
  }, [])

  const handleAddSeoUrl = useCallback<(seoName: string, groupId: string) => void>(
    (seoName, groupId) => {
      if (!seoName || !pageId) {
        return
      }
      addSeoUrl({
        variables: { pageId, urlId: groupId, seoName },
      })
        .then(({ data, errors }) => {
          if (data?.addSeoFriendlyUrl && Array.isArray(data.addSeoFriendlyUrl)) {
            handleSuccess({ action: 'Add', urlGroups: data.addSeoFriendlyUrl })
            refetchAccountSlugs()
          } else {
            const error = Array.isArray(errors) ? errors[0] : undefined
            handleError({ action: 'Add', errorMessage: error?.message })
          }
        })
        .catch((error) => {
          handleError({ action: 'Add', errorMessage: prepareErrorMessage(seoName, error) })
          logNewRelicError(error)
        })
    },
    [pageId, handleError]
  )

  const handleEditSeoUrl = useCallback<(name: string, groupId: string) => void>(
    (seoName, groupId) => {
      if (!seoName || !groupId) {
        return
      }
      editSeoUrl({
        variables: { pageId, seoName, urlId: groupId },
      })
        .then(({ data, errors }) => {
          if (data?.editSeoFriendlyUrl && Array.isArray(data.editSeoFriendlyUrl)) {
            handleSuccess({ action: 'Edit', urlGroups: data.editSeoFriendlyUrl })
            refetchAccountSlugs()
          } else {
            const error = Array.isArray(errors) ? errors[0] : undefined
            handleError({ action: 'Edit', errorMessage: error?.message })
          }
        })
        .catch((error) => {
          handleError({ action: 'Edit', errorMessage: prepareErrorMessage(seoName, error) })
          logNewRelicError(error)
        })
    },
    [pageId, handleError]
  )

  const handleDeleteSeoUrl = useCallback<(groupId: string) => void>(
    (groupId) => {
      if (!groupId) {
        return
      }
      deleteSeoUrl({
        variables: { pageId, urlId: groupId },
      })
        .then(({ data }) => {
          if (data?.deleteSeoFriendlyUrl && Array.isArray(data.deleteSeoFriendlyUrl)) {
            handleSuccess({ action: 'Delete', urlGroups: data.deleteSeoFriendlyUrl })
            refetchAccountSlugs()
          } else {
            handleError({ action: 'Delete' })
          }
        })
        .catch((error) => {
          handleError({ action: 'Delete' })
          logNewRelicError(error)
        })
    },
    [pageId, handleSuccess, handleError]
  )

  const prepareErrorMessage = (name: string, error?: ApolloError) => {
    const graphQLErrors = error?.graphQLErrors ?? []

    if (graphQLErrors.find((error) => error.extensions?.errorCode === NAME_IS_NOT_UNIQUE_ERROR_CODE)) {
      return t(`SEO.URL.Unique.Name.Error.Message`, { name })
    }

    return error?.message
  }

  return { loading: loadingAdd || loadingEdit || loadingDelete, handleAddSeoUrl, handleEditSeoUrl, handleDeleteSeoUrl }
}

export default useSeoUrlActions
