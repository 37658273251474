import React, { FC, useCallback, useEffect, useState, MouseEvent } from 'react'

import classNames from 'classnames'

import { useApolloClient } from '@apollo/client'
import Button, { ButtonType } from '@components/Button/Button'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal/ConfirmationModal'
import Container from '@components/Container'
import EmptyList from '@components/EmptyList/EmptyList'
import { SelectV2Option, SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import Svg, { SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import SvgNames, { SvgNamesStrings } from '@components/Svg/SvgNames'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { getUUID, useTranslation } from '@const/globals'
import createSegment from '@graphql/mutations/createSegment'
import { CreateSegmentMutation, CreateSegmentMutationVariables } from '@graphql/types/mutation-types'
import { ExitCondition, Program } from '@graphql/types/query-types'
import EarlyExitConditionModal from '@src/pages/programs/edit/components/ProgramEarlyExitConditions/components/EarlyExitConditionModal/EarlyExitConditionModal'
import {
  getConditionalText,
  getDisplayText,
  getSelectedSegment,
  getSourceIcon,
} from '@src/pages/programs/edit/components/ProgramEarlyExitConditions/ProgramEarlyExitConditions.utils'
import { useProgramsCategorizationRequests } from '@src/pages/programs/GraphQL/ProgramsRequests.categorization.graphQL'
import { hasAOContactsSource } from '@utils/program/program'

import './programEarlyExitConditions.css'

const rootClass = 'program-early-exit-conditions'

interface AddEditExitCondition {
  exitCondition: ExitConditionListItem
  isNew: boolean
}

export interface State {
  exitConditions: ExitConditionListItem[]
  confirmDelete?: number
  editCondition?: AddEditExitCondition
  defaultOption?: SelectV2SingleOption
  segmentsOptions?: SelectV2SingleOption[]
  errorMessage?: string
}

export interface Props {
  program: Program
  isEditing: boolean
  dataTest?: string
  updateProgram: (program: Program) => void
}

export interface ExitConditionListItem extends ExitCondition {
  id: string
}

const ProgramEarlyExitConditions: FC<Props> = (props: Props) => {
  const { program, isEditing, updateProgram, dataTest = 'program-early-exit-conditions' } = props
  const {
    exit: { exitChoices },
    localSegmentDetails,
  } = program
  const hasActOnContacts = hasAOContactsSource(program)
  const { t } = useTranslation()

  const [showSaveError, setShowSaveError] = useState(false)
  const [segmentOptions, setSegmentOptions] = useState<SelectV2SingleOption[]>([])
  const [state, setState] = useState<State>({
    exitConditions: exitChoices.map((exitChoice) => ({
      ...exitChoice,
      id: getUUID(),
    })),
  })
  const { exitConditions, confirmDelete } = state

  const client = useApolloClient()

  const { getTopDirectSelectSegmentsRequest, getSegmentById } = useProgramsCategorizationRequests()

  const searchSegment: (search: string) => Promise<SelectV2Option[]> = async (search: string) => await getTopDirectSelectSegmentsRequest(0, search)

  const loadInitialSegments = async () => setSegmentOptions(await getTopDirectSelectSegmentsRequest(0))

  useEffect(() => {
    loadInitialSegments()
  }, [])

  const onEdit = useCallback(async (event: MouseEvent<HTMLButtonElement>, exitCondition: ExitConditionListItem) => {
    const selectedSegment = await getSelectedSegment(getSegmentById, exitCondition.listId)
    setState((state) => ({
      ...state,
      editCondition: {
        exitCondition,
        isNew: false,
      },
      defaultOption: selectedSegment,
    }))
    event.stopPropagation()
  }, [])

  const onSubmitCreateSegmentModal = async (name: string) => {
    try {
      const { data } = await client.mutate<CreateSegmentMutation, CreateSegmentMutationVariables>({
        mutation: createSegment,
        variables: {
          name: name,
        },
      })

      if (data && data.createSegment) {
        setState({ ...state, defaultOption: { label: data.createSegment.name, value: data.createSegment.id } })
        await loadInitialSegments()
      }
      return true
    } catch (ex: any) {
      const errorMessage = ex.message.split(': ')[1]
      setState({ ...state, errorMessage })
      return false
    }
  }

  const updateExitConditions = (exitConditions: ExitConditionListItem[], updatedProgram?: Program) => {
    const newProgram = updatedProgram ?? program
    setState({
      ...state,
      confirmDelete: undefined,
      editCondition: undefined,
      exitConditions,
    })
    updateProgram({
      ...newProgram,
      exit: {
        ...newProgram.exit,
        exitChoices: exitConditions.map((exitCondition) => ({
          ...exitCondition,
          id: undefined,
        })),
      },
    })
  }

  const getRadioSelection = (update: boolean, listId: string) => {
    if (update) {
      return hasActOnContacts ? 'Append to' : 'Copy/update to'
    } else {
      return listId.length > 0 ? 'Append to' : ''
    }
  }

  const getSecondActionList = (listId: string) => {
    const list = program.sources.find((source) => source.id === listId)
    return list ? list.name : ''
  }

  return (
    <>
      {showSaveError && (
        <ConfirmationModal
          isOpen
          body={t('Unexpected error occurred when trying to save exit condition')}
          title={t('Unable to save')}
          closeModal={() => setShowSaveError(false)}
        />
      )}
      {state.editCondition && (
        <EarlyExitConditionModal
          dataTest={`${dataTest}-early-exit-condition-modal`}
          exitCondition={state.editCondition.exitCondition}
          isOpen={true}
          program={program}
          listOptions={segmentOptions}
          getSegmentOptions={getTopDirectSelectSegmentsRequest}
          searchSegment={searchSegment}
          defaultOption={state.defaultOption}
          errorMessage={state.errorMessage}
          resetErrorMessage={() => setState({ ...state, errorMessage: undefined })}
          saveCondition={(updatedExitCondition, program) => {
            if (state.editCondition?.isNew) {
              updateExitConditions([...exitConditions, updatedExitCondition], program)
            } else {
              const exitIndex = exitConditions.findIndex((exitCondition) => exitCondition.id === updatedExitCondition.id)
              if (exitIndex >= 0) {
                const newExitConditions = [...exitConditions.slice(0, exitIndex), updatedExitCondition, ...exitConditions.slice(exitIndex + 1)]
                updateExitConditions(newExitConditions, program)
              }
            }
          }}
          updateProgram={updateProgram}
          closeModal={(errorSaving = false) => {
            setShowSaveError(errorSaving)
            setState({
              ...state,
              editCondition: undefined,
              errorMessage: undefined,
            })
          }}
          onSubmitCreateSegmentModal={onSubmitCreateSegmentModal}
        />
      )}
      <ConfirmationModal
        dataTest={`${dataTest}-confirm-delete`}
        isYesNo
        isOpen={confirmDelete !== undefined}
        body={t('Are you sure you want to remove this early exit condition?')}
        onAnswer={(answer) => {
          if (answer === YesNo.NO) {
            setState({
              ...state,
              confirmDelete: undefined,
            })
          } else if (confirmDelete !== undefined) {
            const newExitConditions = [...exitConditions.slice(0, confirmDelete), ...exitConditions.slice(confirmDelete + 1)]
            updateExitConditions(newExitConditions)
          }
        }}
      />
      <div className={rootClass} data-test={dataTest}>
        <Container
          title={'Early Exit Conditions'}
          description={
            'Contacts meeting early exit conditions will be removed from the program. Early exit conditions are evaluated when contacts move from one step to another. Contacts will not be removed while in a Wait step.'
          }
        >
          {state.exitConditions.length === 0 && (
            <div className={`${rootClass}__early-exit-empty`}>
              <EmptyList message={t('No Early Exits have been added yet.')} />
            </div>
          )}
          <div className={`${rootClass}__early-exit`}>
            {state.exitConditions.map((exitCondition, i) => {
              const source = localSegmentDetails.find((source) => source.id === exitCondition.srcId)
              const conditionalText = getConditionalText(exitCondition.not, source)
              const displayTexts = getDisplayText(program, exitCondition.srcId ?? '')
              const secondAction = getRadioSelection(exitCondition.update ?? false, exitCondition.listId)
              const secondActionIcon = getSourceIcon(exitCondition.listId) as SvgNames
              const secondActionList = secondAction ? getSecondActionList(exitCondition.listId) : ''

              return displayTexts.map((displayText) => (
                <div
                  className={classNames(`${rootClass}__early-exit-row`, {
                    [`${rootClass}__early-exit-row-with-extra`]: !!secondAction,
                  })}
                  key={`${exitCondition.id}:${displayText.text}`}
                >
                  <div className={`${rootClass}__early-exit-row-condition`}>
                    <div className={classNames(`${rootClass}__early-exit-row-condition-text`, 'ellip')}>
                      <div className={`${rootClass}__condition`}>
                        <Typography text={conditionalText} type={TextType.BODY_TEXT_LIGHT} />
                      </div>
                      {displayText.icon && (
                        <div
                          className={classNames(`${rootClass}__icon`, {
                            [`${rootClass}__icon--message`]: !displayText.isList,
                            [`${rootClass}__icon--list`]: displayText.isList,
                          })}
                        >
                          <Svg name={SvgNames[displayText.icon as SvgNamesStrings]} type={SvgType.ICON} />
                        </div>
                      )}
                      <div className={classNames(`${rootClass}__segment-text`, 'ellip')}>
                        <Tooltip trigger={<Typography text={displayText.text} weight={TextWeight.MEDIUM} className={'ellip'} />} align={'center'}>
                          {displayText.text}
                        </Tooltip>
                      </div>
                    </div>
                    {secondAction && (
                      <div className={classNames(`${rootClass}__early-exit-row-condition-extra`)}>
                        <Svg
                          name={SvgNames.curvedArrow}
                          className={`${rootClass}__early-exit-row-condition-extra-arrow`}
                          type={SvgType.MEDIUM_LARGE_ICON}
                        />
                        <div className={classNames(`${rootClass}__early-exit-row-condition-extra-inner`, 'ellip')}>
                          <Typography
                            text={secondAction}
                            type={TextType.BODY_TEXT_LIGHT}
                            className={`${rootClass}__early-exit-row-condition-extra-action`}
                          />
                          <div
                            className={classNames(`${rootClass}__icon`, {
                              [`${rootClass}__icon--message`]: !displayText.isList,
                              [`${rootClass}__icon--list`]: displayText.isList,
                            })}
                          >
                            <Svg name={secondActionIcon} type={SvgType.ICON} className={`${rootClass}__early-exit-row-condition-extra-type`} />
                          </div>
                          <div className={classNames(`${rootClass}__segment-text`, 'ellip')}>
                            <Tooltip trigger={<Typography text={secondActionList} weight={TextWeight.MEDIUM} className={'ellip'} />} align={'center'}>
                              {secondActionList}
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {isEditing && (
                    <div className={`${rootClass}__row-buttons`}>
                      <div
                        className={classNames({
                          [`${rootClass}__row-button`]: secondAction,
                        })}
                      >
                        <Button
                          buttonType={ButtonType.TABLE_ACTION}
                          data-test={`${dataTest}-edit-${exitCondition.id}`}
                          onClick={(e) => onEdit(e, exitCondition)}
                          className={`${rootClass}__edit-button`}
                        >
                          <Svg name={SvgNames.pencil} fill={SvgColor.TEXT_GRAY} />
                        </Button>
                      </div>
                      <div
                        className={classNames({
                          [`${rootClass}__row-button`]: secondAction,
                        })}
                      >
                        <Button
                          buttonType={ButtonType.TABLE_ACTION}
                          data-test={`${dataTest}-delete-${exitCondition.id}`}
                          onClick={(e) => {
                            setState({
                              ...state,
                              confirmDelete: i,
                            })
                            e.stopPropagation()
                          }}
                          className={`${rootClass}__delete-button`}
                        >
                          <Svg name={SvgNames.delete} fill={SvgColor.TEXT_GRAY} />
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              ))
            })}
          </div>
          {isEditing && (
            <div className={`${rootClass}__list-button-ctr`}>
              <Button
                className={`${rootClass}__list-button`}
                dataTest={`${dataTest}-add-button`}
                buttonType={ButtonType.SECONDARY}
                onClick={() => {
                  setState({
                    ...state,
                    editCondition: {
                      exitCondition: {
                        displayName: 'Early Exit',
                        listId: '',
                        not: false,
                        score: 0,
                        srcId: '',
                        stepType: 'exit',
                        update: false,
                        id: getUUID(),
                      },
                      isNew: true,
                    },
                  })
                }}
              >
                <Svg name={SvgNames.plus} fill={SvgColor.TEXT_GRAY} />
                {t('Add Condition')}
              </Button>
            </div>
          )}
        </Container>
      </div>
    </>
  )
}

export default ProgramEarlyExitConditions
