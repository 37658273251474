import { gql } from '@apollo/client'

export default gql`
  query getAssetAudits($assetTypes: [String], $pageSize: Int, $orderBy: String, $page: Int, $events: [String], $direction: String) {
    getAssetAudits(assetTypes: $assetTypes, pageSize: $pageSize, orderBy: $orderBy, page: $page, events: $events, direction: $direction) {
      assetAuditDTOS {
        accountId
        assetId
        assetType
        createdTime
        event
        extraInfo
        userId
        userName
      }
      totalCount
    }
  }
`
