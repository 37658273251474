import { IImagePickerFilterState, IImagePickerItem } from '@components/AssetPickers/ImagePickerModal/utils/ImagePickerModal.utils'
import { ColumnSort } from '@tanstack/react-table'
import { createSafeContext, useSafeContext } from '@utils/account/account.utils'

export interface ImagePickerModalContentContextAPI {
  imageItems: IImagePickerItem[]
  isLogos: boolean
  loading: boolean
  loadingNextData: boolean
  updateFilterState: (state: Partial<IImagePickerFilterState>) => void
  filterState: IImagePickerFilterState
  sortBy: ColumnSort
  onSortColumnChange: (sortBy: ColumnSort) => void
  onSelect: (item: IImagePickerItem) => void
  onPreview: (item: IImagePickerItem) => void
  searchTerm?: string
  activeFolderName?: string
  selectedId?: string
  currentFolderAllItemsCount?: number
  onUploadImage?: () => void
  accept?: string
  imageMaxSize?: number
}

const ImagePickerModalContentContext = createSafeContext<ImagePickerModalContentContextAPI>()
export const useImagePickerModalContentContext = () => useSafeContext(ImagePickerModalContentContext)

export default ImagePickerModalContentContext
