import { gql } from '@apollo/client'

export default gql`
  mutation upsertUserSettings($settings: [UserSettingUpsertDefinitionInput]!) {
    upsertUserSettings(settings: $settings) {
      userSettings {
        settingName
        settingType
        settingValue
      }
    }
  }
`
