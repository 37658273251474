import React, { FC, forwardRef, useState, useMemo, Fragment } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/index'
import Svg, { SvgType } from '@components/Svg/index'
import SvgNames from '@components/Svg/SvgNames'
import Typography from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import * as Popover from '@radix-ui/react-popover'

import './ButtonMenu.css'

export interface ButtonMenuOption {
  id: string
  text: string
  image: SvgNames
  imageHover: SvgNames
  dividerAfter?: boolean
}

interface ButtonMenuProps {
  triggerText: string
  options: ButtonMenuOption[]
  align?: 'start' | 'center' | 'end' | undefined
  onSelect: (option: ButtonMenuOption) => void
  onMouseDown?: (e: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
  dataTest?: string
}

const rootClass = 'button-menu'

const ButtonMenu: FC<ButtonMenuProps> = (props: ButtonMenuProps) => {
  const { triggerText, options, align, onSelect, onMouseDown, dataTest = rootClass, className = '' } = props
  const [showMenu, setShowMenu] = useState<boolean>(false)
  const [hovered, setHovered] = useState<string | undefined>(undefined)

  const { t } = useTranslation()

  const handleMouseEnter = (optionKey: string) => setHovered(optionKey)

  const handleMouseLeave = () => setHovered(undefined)

  const onClick = (selectedOption: ButtonMenuOption) => {
    setShowMenu(false)
    onSelect(selectedOption)
  }

  const Trigger = useMemo(() => {
    return forwardRef((_p, ref) => (
      <Button
        register={ref}
        buttonType={ButtonType.FLOAT_TEAL}
        className={classNames(`${rootClass}__trigger`, className, {
          [`${rootClass}__open-trigger`]: showMenu,
        })}
        dataTest={`${dataTest}-trigger`}
        onMouseDown={onMouseDown}
        onClick={() => setShowMenu(!showMenu)}
      >
        <Svg name={SvgNames.addRounded} type={SvgType.ICON} />
        {t(triggerText)}
      </Button>
    ))
  }, [showMenu, t, triggerText])

  const renderOptions = () => {
    return options.map((option, idx) => (
      <Fragment key={option.id}>
        <div
          className={`${rootClass}__option`}
          tabIndex={idx}
          role={'button'}
          onClick={() => onClick(option)}
          onKeyUp={() => onClick(option)}
          onMouseEnter={() => handleMouseEnter(option.id)}
          onMouseLeave={handleMouseLeave}
          data-test={`${dataTest}-option-${option.id}`}
        >
          <Svg name={hovered === option.id ? option.imageHover : option.image} type={SvgType.LARGE_ICON} />
          <Typography text={t(option.text)} />
        </div>
        {option.dividerAfter && <div className={`${rootClass}__divider`} />}
      </Fragment>
    ))
  }

  return (
    <div
      className={classNames(rootClass, className, {
        [`${rootClass}__open`]: showMenu,
      })}
      data-test={dataTest}
    >
      <Popover.Root onOpenChange={(open) => setShowMenu(open)} open={showMenu}>
        <Popover.Trigger asChild>
          <Trigger />
        </Popover.Trigger>
        <Popover.Content align={align} sideOffset={6} onEscapeKeyDown={() => setShowMenu(false)}>
          <div className={`${rootClass}__options`}>{renderOptions()}</div>
        </Popover.Content>
      </Popover.Root>
    </div>
  )
}

export default ButtonMenu
