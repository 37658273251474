import { gql } from '@apollo/client'

export default gql`
  query getFieldMappingDefinitions {
    getFieldMappingDefinitions {
      entityType
      reportFieldMappingDefinitions {
        mappingStatus
        crmField {
          crmEntityType
          displayName
          fieldName
          dataType
          isSynced
        }
        reportField {
          crmEntityType
          displayName
          fieldName
          dataType
        }
        defaultCrmField {
          crmEntityType
          displayName
          fieldName
          dataType
          isSynced
        }
      }
    }
  }
`
