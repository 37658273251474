import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import { LineSegmentData } from '@components/SegmentedSingleLineChart/SegmentedSingleLineChart'
import { useTranslation } from '@const/globals'
import { RegistrationDataCardInfo } from '@graphql/types/microservice/entity-join-types'
import DataCardsHeaderWithDateSelect from '@src/pages/listingPages/FormsJoinView/components/DataCardsHeaderWithDateSelect/DataCardsHeaderWithDateSelect'
import WebinarAttendanceDataCard from '@src/pages/listingPages/FormsJoinView/components/WebinarsJoinViewDataCards/components/WebinarAttendanceDataCard/WebinarAttendanceDataCard'
import WebinarCarouselDataCard from '@src/pages/listingPages/FormsJoinView/components/WebinarsJoinViewDataCards/components/WebinarCarouselDataCard/WebinarCarouselDataCard'
import WebinarRegistrationsDataCard from '@src/pages/listingPages/FormsJoinView/components/WebinarsJoinViewDataCards/components/WebinarRegistrationsDataCard/WebinarRegistrationsDataCard'
import {
  AttendanceCard,
  defaultAttendanceCardValues,
  defaultTotalRegistrationsCardValues,
  TotalRegistrationsCard,
  WEBINARS_DATE_SELECTED_OPTION,
} from '@src/pages/listingPages/FormsJoinView/components/WebinarsJoinViewDataCards/utils/WebinarsJoinViewDataCards.constants'
import { DATA_PERIOD } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.constants'
import { useFormsJoinViewListingPageEntityJoinRequests } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.entityJoin.graphQL'
import { getDateRangeDropDownActions, parseDataPeriod } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.utils'
import { filterNotEmptyArray } from '@utils/array'
import { ItemType } from '@utils/categorization'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'
import { getItem } from '@utils/sessionStorage'

import './WebinarsJoinViewDataCards.css'

interface WebinarsJoinViewDataCardsProps {
  className?: string
  dataTest?: string
}

interface WebinarsJoinViewDataCardsState {
  loading: boolean
  totalRegistrationsCard: TotalRegistrationsCard
  attendanceCard: AttendanceCard
  topPerformingWebinars: RegistrationDataCardInfo[]
  days: DATA_PERIOD
  dateRangeDropDownOpen: boolean
  registrationData: LineSegmentData[]
}

const rootClass = 'webinars-join-view-data-cards'

const WebinarsJoinViewDataCards: FC<WebinarsJoinViewDataCardsProps> = (props: WebinarsJoinViewDataCardsProps) => {
  const { dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()

  const checkIfHasSavedOption = () => {
    const savedOption = getItem(WEBINARS_DATE_SELECTED_OPTION)
    if (savedOption !== null) {
      return parseDataPeriod(parseInt(savedOption))
    } else {
      return DATA_PERIOD.Week
    }
  }

  const [state, setState] = useState<WebinarsJoinViewDataCardsState>({
    loading: true,
    totalRegistrationsCard: defaultTotalRegistrationsCardValues,
    attendanceCard: defaultAttendanceCardValues,
    topPerformingWebinars: [],
    days: checkIfHasSavedOption(),
    dateRangeDropDownOpen: false,
    registrationData: [],
  })

  const { attendanceCard, topPerformingWebinars, dateRangeDropDownOpen, days, loading, registrationData } = state

  const update = useDeepUpdate(setState)

  const { getWebinarDataCardsDataRequest } = useFormsJoinViewListingPageEntityJoinRequests()

  useEffect(() => {
    update({ loading: true })
    getWebinarDataCardsDataRequest(days)
      .then((data) => {
        const { totalAttendees, totalRegistrations, topWebinars } = data
        update({
          attendanceCard: {
            attended: totalAttendees,
            registrations: totalRegistrations,
          },
          topPerformingWebinars: topWebinars?.filter(filterNotEmptyArray) ?? [],
          registrationData:
            topWebinars?.map((webinar) => ({
              amount: webinar?.totalRegistrations,
              tooltipTitle: webinar?.webinarName,
            })) ?? [],
        })
      })
      .finally(() => {
        update({ loading: false })
      })
  }, [days])

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <DataCardsHeaderWithDateSelect
        headerText={t(`ListPage.FormsJoinView.${ItemType.WEBINAR_SUBMISSION}.DataCards.Header`)}
        dateRangeDropDownOpen={dateRangeDropDownOpen}
        days={days}
        onDropdownOpen={(open) => update({ dateRangeDropDownOpen: open })}
        onDropdownClose={() => update({ dateRangeDropDownOpen: false })}
        dropdownItems={getDateRangeDropDownActions(WEBINARS_DATE_SELECTED_OPTION, update)}
      />
      <div className={`${rootClass}__cards`}>
        <WebinarRegistrationsDataCard loading={loading} data={registrationData} />
        <WebinarAttendanceDataCard attendance={attendanceCard} loading={loading} />
        <WebinarCarouselDataCard topPerformingWebinars={topPerformingWebinars} loading={loading} />
      </div>
    </div>
  )
}

export default WebinarsJoinViewDataCards
