import React, { FC } from 'react'

import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { Box } from '@src/pages/programs/UpgradeCommonComponents/Box/Box'
import { UpgradedSource } from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'

import './RemovedSourcesSection.css'

interface RemovedSourcesSectionProps {
  sources: UpgradedSource[]
}

const rootClass = 'removed-sources-section'
const rootText = 'UpgradePrograms.Page.ReviewUpgrade.RemovedSourcesSection.'

export const RemovedSourcesSection: FC<RemovedSourcesSectionProps> = ({ sources }: RemovedSourcesSectionProps) => {
  const { t } = useTranslation()
  return (
    <Box className={rootClass}>
      <Typography text={t(`${rootText}Title`, { count: sources.length })} type={TextType.VALIDATION_ERROR_LARGE} weight={TextWeight.BOLD} />
      <div className={`${rootClass}__sources`}>
        <Typography text={t(`${rootText}SubTitle`)} />
        {sources.map((source) => (
          <Box key={source.upgradeId} className={`${rootClass}__source`} isCurrent isSourceValue>
            <TextWithTooltipOnEllip typographyProps={{ text: source?.source?.sourceName ?? '', type: TextType.BODY_TEXT }} />
            <Typography
              text={t(`AutomatedPrograms.UpgradeManager.SourceReplacement.Contacts`, { count: source.source?.count })}
              type={TextType.BODY_TEXT_LIGHT}
            />
          </Box>
        ))}
      </div>
    </Box>
  )
}
