import { i18nLanguages } from '@const/i18n'

export interface Language {
  language: string
  translation: string
  langCode: string
}

const languages = i18nLanguages.map((language: Language) => ({
  language: language.language,
  translation: language.translation,
  langCode: language.langCode,
}))

export default languages
