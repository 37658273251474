import React, { FC, useRef, useState } from 'react'

import { GraphQLError } from 'graphql'

import AssetPickerModal, { AssetPickerModalBaseProps, AssetPickerModalProps } from '@complex/AssetPickerModal/AssetPickerModal'
import { AssetPickerListingPageProps, AssetPickerSidebarProps, AssetPickerTableProps } from '@complex/AssetPickerModal/Context/AssetPicker.context'
import { FilterQueryParams } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { CustomSource, ListingPageExternalApi, RenderSearchColumnsV2 } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { LinkTextButton } from '@components/LinkTextButton/LinkTextButton'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import Typography, { TextType } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import { filterNotEmptyArray } from '@utils/array'
import { ItemType } from '@utils/categorization'
import { logNewRelicError } from '@utils/new-relic.utils'
import { partialMatch } from '@utils/searchUtils'
import { toTrainCase } from '@utils/strings'

import { useWebPageRequests } from './useWebPageRequests'
import { allWebPagesFilter, webPageDomainsFilter } from './WebPagePickerModal.sidebar'
import {
  renderDomainSearchTableColumns,
  renderWebPageSearchTableColumns,
  renderDomainTableColumns,
  renderWebPageTableColumns,
} from './WebPagePickerModal.tables'
import { convertDomainsToItemDto, convertWebPagesToItemDto } from './WebPagePickerModal.utils'

const rootClass = 'web-page-picker-modal'

import './WebPagePickerModal.css'

const WEB_PAGE_INFO_LINK = `${rootContext}/classic/if/beacon/beaconPageNames.jsp?back=../_wvt/index.jsp`
const DOMAIN_INFO_LINK = `${rootContext}/classic/_wvt/index.jsp`

const WebPagePickerModal: FC<AssetPickerModalBaseProps> = (props) => {
  const { dataTest = rootClass } = props
  const { t } = useTranslation()
  const listingApi = useRef<ListingPageExternalApi>()

  const requests = useWebPageRequests()

  const webpageRequest = async (_params?: FilterQueryParams, search?: string) => {
    const { data, errors } = (await requests.getWebPagesRequest({})) ?? {}
    let responseData = data?.getWebPages?.filter(filterNotEmptyArray) ?? []
    const responseErrors = errors ?? []
    if (search) {
      responseData = responseData.filter((page) => partialMatch(page, ['name', 'url'], search))
    }
    const results = convertWebPagesToItemDto(responseData)
    return { data: results, errors: responseErrors }
  }

  const domainRequest = async (_params?: FilterQueryParams, search?: string) => {
    let responseData: string[] = []
    let responseErrors: readonly GraphQLError[] = []

    if (search) {
      const { data, errors } = (await requests.getWebPagesDomainsFilteredRequest({ search })) ?? {}
      responseData = data?.getWebPagesDomainsFiltered?.filter(filterNotEmptyArray) ?? []
      responseErrors = errors ?? []
    } else {
      const { data, errors } = (await requests.getWebPagesDomainsRequest({})) ?? {}
      responseData = data?.getWebPagesDomains?.filter(filterNotEmptyArray) ?? []
      responseErrors = errors ?? []
    }
    const results = convertDomainsToItemDto(responseData)
    return { data: results, errors: responseErrors }
  }

  const renderInfoSidebarBlock = (i18nSubKey: string, link: string) => {
    return (
      <Typography
        text={t(`AssetPicker.WebPages.${i18nSubKey}.InfoSidebarBlock`)}
        dataTest={`${dataTest}-info-sidebar-block-${toTrainCase(i18nSubKey)}`}
        type={TextType.BODY_TEXT_LIGHT}
        tagComponents={{ LinkTextButton: <LinkTextButton hideIconLeft link={link} /> }}
        inline
      />
    )
  }

  const webpageSource: CustomSource = {
    allItemFilter: allWebPagesFilter,
    itemName: 'Web page',
    label: 'Web pages',
    mainColumnName: 'Web page name',
    filterTrigger: allWebPagesFilter,
    value: ItemType.WEB_PAGE,
    itemType: ItemType.WEB_PAGE,
    searchPlaceholder: 'web page or domain name',
    customRequestGeneral: {
      getCountQueryRequest: async () => ({}),
      getSubTypesByTypesRequest: async () => ({}),
    },
    customRequestFilters: [
      {
        filter: allWebPagesFilter,
        request: (_, params) => webpageRequest(params),
        searchRequest: (search, _, params) => webpageRequest(params, search),
      },
      {
        filter: webPageDomainsFilter,
        request: (_, params) => domainRequest(params),
        searchRequest: (search, _, params) => domainRequest(params, search),
      },
    ],
    filterInfoSidebarBlock: () => renderInfoSidebarBlock('Default', WEB_PAGE_INFO_LINK),
  }

  const domainSource: CustomSource = {
    ...webpageSource,
    mainColumnName: 'Domain filter',
    filterTrigger: webPageDomainsFilter,
    label: webPageDomainsFilter.name,
    value: 'DOMAIN',
    filterInfoSidebarBlock: () => renderInfoSidebarBlock('Domains', DOMAIN_INFO_LINK),
  }

  const [selectedCustomSource, setSelectedCustomSource] = useState(webpageSource)

  const searchColumnRenderer = selectedCustomSource.value === domainSource.value ? renderDomainSearchTableColumns : renderWebPageSearchTableColumns
  const renderSearchColumns: RenderSearchColumnsV2 = (
    _searchInAllItems: boolean | undefined,
    _currentFolder: FolderData,
    search: string,
    folders: FolderData[]
  ) => searchColumnRenderer(search, folders, t)

  const columnRenderer = selectedCustomSource.value === domainSource.value ? renderDomainTableColumns : renderWebPageTableColumns
  const tableProps: AssetPickerTableProps = {
    columns: columnRenderer(t),
    renderSearchColumns,
    listPage: 'WebPages',
    enableLazyLoading: false,
    enableSorting: true,
    sortingBy: [{ id: 'name', desc: false }],
  }

  const sidebarProps: AssetPickerSidebarProps = {
    customSources: [webpageSource, domainSource],
    hasRecent: false,
    hasCreatedByMe: false,
    hasFavorites: false,
    hideFolders: true,
    allItemFilter: allWebPagesFilter,
    getCustomFilterCounts: async () => {
      const { data, errors } = (await requests.getWebPageCountRequest({})) ?? {}
      const { data: domainData, errors: domainErrors } = (await requests.getWebPagesDomainsCountRequest({})) ?? {}
      if (errors) {
        logNewRelicError([...errors, ...(domainErrors ?? [])], 'Fetching counts for web page picker')
      }
      return {
        [allWebPagesFilter.name]: data?.getWebPageCount ?? 0,
        [webPageDomainsFilter.name]: domainData?.getWebPagesDomainsCount ?? 0,
      }
    },
    customDefaultFilters: [{ ...webPageDomainsFilter, position: 1 }],
  }

  const listingPageProps: AssetPickerListingPageProps = {
    onApiAvailable: (api) => {
      listingApi.current = api
    },
    hasCustomRequests: true,
    sidebarProps,
    subTypes: [],
  }

  const assetPickerProps: AssetPickerModalProps = {
    listingPageProps,
    tableProps,
    hasTags: false,
    hasCustomFilters: true,
    hasSidebar: true,
    hasPreview: false,
    itemType: ItemType.WEB_PAGE,
    hideRecordsCount: true,
    onSelectedCustomSourceChange: setSelectedCustomSource,
    ...props,
  }

  return <AssetPickerModal {...assetPickerProps} className={rootClass} />
}

export default WebPagePickerModal
