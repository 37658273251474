import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { ModalHeader } from '@components/Modal'
import PillLabel, { PillLabelType } from '@components/PillLabel/PillLabel'
import Svg, { SvgNames } from '@components/Svg'
import Typography, { ModalHeaderFormStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { CheckCardStatus, CheckCardType } from '@src/pages/EmailComposer/components/common/CheckStatusCard/CheckStatusCard.utils'
import { relativeDate } from '@utils/date'

interface CheckStatusModalHeaderProps {
  onCheck: VoidFunction
  onDone: VoidFunction
  type: CheckCardType
  className: string
  lastChecked?: number
  status?: CheckCardStatus
  dataTest?: string
}

export const CheckStatusModalHeader: FC<CheckStatusModalHeaderProps> = ({
  onCheck,
  onDone,
  lastChecked,
  type,
  status,
  className,
  dataTest = className,
}) => {
  const { t } = useTranslation()
  const isSpam = type === CheckCardType.SPAM
  const isSuccess = status === CheckCardStatus.SUCCESS
  const isFailure = status === CheckCardStatus.FAILURE
  const loading = status === CheckCardStatus.LOADING
  const showPill = isSpam && (isSuccess || isFailure)

  return (
    <ModalHeader className={className} dataTest={dataTest}>
      <div className="flex-align-center">
        <Typography text={t(`EmailComposer.Check.Status.${type}.Modal.Title`)} {...ModalHeaderFormStyle} dataTest={`${dataTest}-title`} />
        {showPill && (
          <PillLabel
            type={isSuccess ? PillLabelType.SUCCESS : PillLabelType.ERROR}
            text={t(isSuccess ? 'Passed!' : 'Failed')}
            textType={TextType.BODY_TEXT}
            dataTest={`${dataTest}-pill`}
          />
        )}
      </div>
      <div className={classNames(`${className}__right`, 'flex-align-center')}>
        {lastChecked && (
          <Typography
            text="Last.Checked.Date"
            values={{ context: loading ? status : undefined, date: relativeDate(lastChecked, true, false) }}
            tagProps={{ medium: { weight: TextWeight.MEDIUM } }}
            inline
          />
        )}
        <Button
          buttonType={ButtonType.OUTLINE}
          onClick={onCheck}
          disabled={loading}
          dataTest={`${dataTest}-button-check`}
          className={`${className}__button-check`}
        >
          <Svg name={SvgNames.generate} />
          {t('Check again')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} onClick={onDone} dataTest={`${dataTest}-button-done`}>
          {t('Done')}
        </Button>
      </div>
    </ModalHeader>
  )
}
