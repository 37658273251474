import React, { FC, useEffect, useState } from 'react'

import { renderLoader } from '@components/Loader/Loader'
import getOptInForms from '@graphql/microservices/sms-management/getOptInForms'
import getSentInitialMessage from '@graphql/microservices/sms-management/getSentInitialMessage'
import isInitialMessageInSendingStatus from '@graphql/microservices/sms-management/isInitialMessageInSendingStatus'
import optOut from '@graphql/microservices/sms-management/optOut'
import {
  GetSentInitialMessageQuery,
  GetSentInitialMessageQueryVariables,
  IsInitialMessageInSendingStatusQuery,
  IsInitialMessageInSendingStatusQueryVariables,
  OptStatus,
  Query,
  QueryGetOptInFormsArgs,
  QueryGetOptStatusesArgs,
} from '@graphql/types/microservice/sms-management-types'
import InitialMessageContainer from '@src/pages/sms/initialMessage'
import { useAccountSettings } from '@utils/account/account.utils'
import { getStandardFormattedDate } from '@utils/date'
import useMicroserviceClient, { MicroserviceClients, getAuthenticatedPromise } from '@utils/hooks/useMicroserviceClient'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'
import { logNewRelicError } from '@utils/new-relic.utils'
import { SortByState } from '@utils/sms.utils'

import SMSOptout from './SMSOptout'

export interface State {
  pageIndex: number
  pageSize: number
  sortBy: SortByState[]
  entries: OptStatus[]
  formOptInsCount: number
  sentInitialMessage: boolean
  downloadUrl: string
}

interface Props {
  dataTest?: string
}

const defaultSortBy: SortByState = {
  id: 'latestOptInDate',
  desc: true,
}

const defaultState: State = {
  pageIndex: 0,
  pageSize: 10,
  entries: [],
  formOptInsCount: 0,
  sentInitialMessage: false,
  sortBy: [defaultSortBy],
  downloadUrl: '',
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SMSOptoutContainer: FC<Props> = (_props: Props) => {
  const { accountId } = useAccountSettings()
  const { client, token, aoAccountContext } = useMicroserviceClient({ serviceName: MicroserviceClients.SMS_MANAGEMENT })
  const [loading, setLoading] = useState(true)

  const [state, setState] = useState<State>(defaultState)
  const [initialMessageInSendingStatus, setInitialMessageInSendingStatus] = useState<boolean>(false)

  const {
    loading: optOutsLoading,
    data,
    error,
  } = useQueryOnMount<Query, QueryGetOptStatusesArgs>(optOut, {
    client,
    fetchPolicy: 'network-only',
    variables: {
      accountId,
      page: state.pageIndex,
      size: state.pageSize,
    },
  })

  const { data: formOptInsData, loading: formOptInsLoading } = useQueryOnMount<Query, QueryGetOptInFormsArgs>(getOptInForms, {
    client,
    fetchPolicy: 'network-only',
    variables: {
      accountId,
      size: state.pageSize,
      page: state.pageIndex,
      sortDirection: 'DESC' as any,
      sortColumn: 'LATEST_OPT_IN_DATE' as any,
    },
  })

  const { data: sentInitialMessage, loading: sentInitialMessageLoading } = useQueryOnMount<
    GetSentInitialMessageQuery,
    GetSentInitialMessageQueryVariables
  >(getSentInitialMessage, {
    client,
    fetchPolicy: 'network-only',
    variables: {
      accountId,
    },
  })

  const { data: isInitialMessageInSendingStatusData, loading: isInitialMessageInSendingStatusLoading } = useQueryOnMount<
    IsInitialMessageInSendingStatusQuery,
    IsInitialMessageInSendingStatusQueryVariables
  >(isInitialMessageInSendingStatus, {
    client,
    fetchPolicy: 'network-only',
    variables: {
      accountId,
    },
  })

  useEffect(() => {
    if (sentInitialMessage?.getSentInitialMessage) {
      setState((prevState) => ({ ...prevState, sentInitialMessage: sentInitialMessage.getSentInitialMessage }))
    }
  }, [sentInitialMessage])

  useEffect(() => {
    setState((prevState) => ({ ...prevState, formOptInsCount: formOptInsData?.getOptInForms.totalCount ?? 0 }))
  }, [formOptInsData])

  useEffect(() => {
    const entries: OptStatus[] =
      data?.getOptStatuses?.optStatuses?.map((entry) => {
        const statusDate = getStandardFormattedDate(entry.statusDate, true)
        const source = entry.source === 'TWILIO' ? 'Contact Request' : entry.sourceAttribution
        return { ...entry, statusDate, source }
      }) || []
    setState((prevState) => ({ ...prevState, entries }))
  }, [data])

  useEffect(() => {
    setLoading(optOutsLoading || sentInitialMessageLoading || formOptInsLoading || isInitialMessageInSendingStatusLoading)
  }, [formOptInsLoading, isInitialMessageInSendingStatusLoading, optOutsLoading, sentInitialMessageLoading])

  useEffect(() => {
    if (isInitialMessageInSendingStatusData?.isInitialMessageInSendingStatus) {
      setInitialMessageInSendingStatus(isInitialMessageInSendingStatusData.isInitialMessageInSendingStatus)
    }
  }, [isInitialMessageInSendingStatusData])

  useEffect(() => {
    if (error) {
      logNewRelicError(error)
    }
  }, [error])

  useEffect(() => {
    getAuthenticatedPromise(
      MicroserviceClients.SMS_MANAGEMENT,
      `/uploads/optin/download/${accountId}?optInStatus=OPT_OUT`,
      token,
      aoAccountContext
    ).then((url: string) => setState((prevState) => ({ ...prevState, downloadUrl: url })))
  }, [])

  const fetchData = (pageIndex: number, pageSize: number) => {
    setState({ ...state, pageIndex, pageSize })
  }

  const onChangeSort = (sortBy: Array<any>) => {
    const validSortBy = sortBy.length > 0 ? sortBy : [defaultSortBy]
    setState({ ...state, sortBy: validSortBy })
  }

  const totalCount: number = data?.getOptStatuses?.totalCount || 0

  if (loading) {
    return renderLoader('loader--white-background')
  }

  return state.sentInitialMessage || state.formOptInsCount === 0 ? (
    <SMSOptout
      entries={state.entries}
      loading={loading}
      isInitialMessageInSending={initialMessageInSendingStatus}
      totalCount={totalCount}
      sortBy={state.sortBy}
      pageIndex={state.pageIndex}
      pageSize={state.pageSize}
      fetchData={fetchData}
      onChangeSort={onChangeSort}
      downloadUrl={state.downloadUrl}
    />
  ) : (
    <InitialMessageContainer />
  )
}

export default SMSOptoutContainer
