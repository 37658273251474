import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import Typography, { LineHeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './ScoreSheetRow.css'

export type updateRowMetadata = (metadata: object, rowId?: number) => void
type Props = {
  className?: string
  relatedRowId?: number
  updateRowMetadata: updateRowMetadata
  existingMetadata?: any
  listsrvLoading?: boolean
  scoresheetsList: any
  isShort?: boolean
}

const rootClass = 'score-sheet-row'

export interface ScoreSheetsData {
  id: string
  name: string
}

const ScoreSheetRow: FC<Props> = (props: Props) => {
  const { className, relatedRowId, updateRowMetadata, existingMetadata, listsrvLoading = false, scoresheetsList, isShort = false } = props
  const { t } = useTranslation()
  const [scoreSheetOptions, setScoreSheetOptions] = useState<SelectV2SingleOption[]>()
  const [defaultOption, setDefaultOption] = useState<string>('ss-default')
  const [rowMetadata, setRowMetadata] = useState({})

  const scoreSheeSelectedOption = scoreSheetOptions?.find((option) => option.value === defaultOption)

  useEffect(() => {
    if (!listsrvLoading) {
      if (existingMetadata && existingMetadata.hasOwnProperty('scoreSheetId')) {
        setDefaultOption(existingMetadata.scoreSheetId)
        setRowMetadata(existingMetadata)
      } else {
        updateRowMetadata({ ...rowMetadata, scoreSheetId: 'ss-default' }, relatedRowId)
      }
      const options = scoresheetsList?.scoreSheets?.map((sheet: { id: string; name: string }) => {
        return {
          value: sheet?.id,
          label: sheet?.name,
        }
      })
      setScoreSheetOptions(options)
    }
  }, [scoresheetsList])

  const updateScoreSheet = (value: string) => {
    setDefaultOption(value)
    updateRowMetadata({ ...rowMetadata, scoreSheetId: value }, relatedRowId)
  }

  return (
    <div className={classNames(rootClass, className)}>
      <div className={classNames(`${rootClass}__select-text`, { [`${className}__select-text`]: className })}>
        <Typography
          lineHeight={LineHeight.MEDIUM}
          text={
            isShort
              ? t('Select a Score Sheet.')
              : t('Score fields are associated with a Score Sheet. Select which score sheet you want to use to populate this field.')
          }
        />
      </div>
      <SelectV2
        key={`${defaultOption}-${scoreSheetOptions?.length}`}
        options={scoreSheetOptions}
        defaultValue={scoreSheeSelectedOption}
        className={classNames(`${rootClass}__select`, { [`${className}__select`]: className })}
        isClearable={false}
        hideCheckMark
        onChange={(option) => {
          updateScoreSheet((option as SelectV2SingleOption).value)
        }}
      />
    </div>
  )
}

export default ScoreSheetRow
