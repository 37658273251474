import React, { useMemo } from 'react'

import classNames from 'classnames'
import { t } from 'i18next'

import HoverSVG, { HoverFillColors } from '@components/HoverSVG/HoverSVG'
import PillLabel, { PillLabelType } from '@components/PillLabel/PillLabel'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { renderColWithTooltip } from '@components/Table/components/tableColumns'
import { TableColumn } from '@components/Table/Table'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { ColWithTooltip } from '@components/TableV2/utils/tableV2ColumnUtils'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { FromAddresses } from '@graphql/types/query-types'
import { DOMAIN_WARNING_TOOLTIP_TEXT, SenderDomainInfo } from '@src/pages/Settings/OtherSettings/FromAddresses/utils/FromAddresses.constants'
import { CellContext } from '@tanstack/react-table'

export const useTableColumns: (rootClass: string, domainConfigInfo?: SenderDomainInfo) => TableColumn[] = (rootClass, domainConfigInfo) => {
  const { t } = useTranslation()
  return useMemo<TableColumn[]>(
    () => [
      {
        Header: t('NAME'),
        accessor: 'name',
        align: 'left',
        minWidth: 200,
        maxWidth: 200,
        sortType: 'caseInsensitive',
        className: 'from-addresses__table--info',
        Cell: (row: any) => getNameWithPhoneNumbers(row, t, rootClass, domainConfigInfo),
        dataTest: (row: any) => `${rootClass}-${row.dataTest}`,
      },
      {
        Header: t('EMAIL ADDRESS'),
        accessor: 'email',
        align: 'left',
        minWidth: 230,
        maxWidth: 230,
        sortType: 'caseInsensitive',
        Cell: (row: any) => renderColWithTooltip(row),
      },
      {
        Header: t('TITLE'),
        accessor: 'title',
        align: 'left',
        sortType: 'caseInsensitive',
        minWidth: 250,
        maxWidth: 250,
        flexColumn: true,
        Cell: (row: any) => renderColWithTooltip(row),
      },
      {
        Header: t('STATUS'),
        accessor: 'status',
        align: 'left',
        disableSortBy: true,
        className: 'from-addresses__table--status',
        Cell: (row: any) => getStatus(t, row.row.original.isDefault, row.row.original.isVerified),
      },
    ],
    [rootClass, domainConfigInfo]
  )
}

export const tableV2Columns = (
  t: Function,
  rootClass: string,
  domainConfigInfo?: SenderDomainInfo
): ColumnDefWithAdditionalProps<FromAddresses>[] => [
  {
    header: t('NAME'),
    accessorKey: 'name',
    textAlign: 'left',
    maxSize: 200,
    sortingFn: 'caseInsensitive',
    cell: (cell) => getNameWithPhoneNumbersV2(cell, rootClass, domainConfigInfo),
    enableFlexCell: true,
  },
  {
    header: t('EMAIL ADDRESS'),
    accessorKey: 'email',
    textAlign: 'left',
    maxSize: 230,
    sortingFn: 'caseInsensitive',
    cell: (cell) => ColWithTooltip({ cell }),
  },
  {
    header: t('TITLE'),
    accessorKey: 'title',
    textAlign: 'left',
    sortingFn: 'caseInsensitive',
    maxSize: 250,
    cell: (cell) => ColWithTooltip({ cell }),
  },
  {
    header: t('STATUS'),
    accessorKey: 'status',
    textAlign: 'left',
    disableSorting: true,
    maxSize: 130,
    cell: (cell) => getStatus(t, cell.row.original.isDefault, cell.row.original.isVerified),
  },
]

const renderIconWithTooltip = (icon: SvgNames, className: string, tooltipText: string, t: Function) => (
  <Tooltip trigger={<Svg name={icon} type={SvgType.LARGER_ICON} className={className} />}>{t(tooltipText)}</Tooltip>
)

const getNameWithPhoneNumbers = (row: any, t: Function, rootClass: string, domainsConfigInfo?: SenderDomainInfo) => {
  const { phone, cell, fax, email } = row.row.original
  const className = `${rootClass}__tooltip`
  const phoneNumbers = <HoverSVG svg={SvgNames.infoTeal} hoverSvg={SvgNames.infoHover} hoverFillColor={HoverFillColors.TEAL} />
  const domain = email.substring(email.indexOf('@') + 1)
  const domainInfo = domainsConfigInfo && domainsConfigInfo[domain]
  const iconTooltipText = () => {
    if (!domainInfo?.hasSPF && !domainInfo?.hasDKIM) {
      return DOMAIN_WARNING_TOOLTIP_TEXT.ALL_UNSET
    }
    if (!domainInfo.hasSPF) {
      return DOMAIN_WARNING_TOOLTIP_TEXT.SPF_UNSET
    }
    return DOMAIN_WARNING_TOOLTIP_TEXT.DKIM_UNSET
  }
  const renderIcon = domainInfo && !(domainInfo.hasDKIM && domainInfo.hasSPF)
  return (
    <>
      {renderIcon && renderIconWithTooltip(SvgNames.warningSolidNoFill, `${rootClass}__icon-warning`, iconTooltipText(), t)}
      <div className={classNames(`${rootClass}__name`, { [`${rootClass}__name-margin`]: renderIcon })}>{renderColWithTooltip(row)}</div>
      {(phone || cell || fax) && (
        <Tooltip trigger={phoneNumbers} className={className}>
          {phone && <Typography text={`${t('Phone')}: ${phone}`} className={`${className}--phone`} type={TextType.BODY_TEXT_WHITE_TINY} />}
          {cell && <Typography text={`${t('Mobile')}: ${cell}`} className={`${className}--phone`} type={TextType.BODY_TEXT_WHITE_TINY} />}
          {fax && <Typography text={`${t('Fax')}: ${fax}`} className={`${className}--phone`} type={TextType.BODY_TEXT_WHITE_TINY} />}
        </Tooltip>
      )}
    </>
  )
}

const getNameWithPhoneNumbersV2 = (cell: CellContext<FromAddresses, unknown>, rootClass: string, domainsConfigInfo?: SenderDomainInfo) => {
  const { phone, fax, cell: originalCell, email } = cell.row.original
  const className = `${rootClass}__tooltip`
  const phoneNumbers = <HoverSVG svg={SvgNames.infoTeal} hoverSvg={SvgNames.infoHover} hoverFillColor={HoverFillColors.TEAL} />
  const domain = email?.substring(email?.indexOf('@') + 1) ?? ''
  const domainInfo = domainsConfigInfo?.[domain]
  const iconTooltipText = () => {
    if (!domainInfo?.hasSPF && !domainInfo?.hasDKIM) {
      return DOMAIN_WARNING_TOOLTIP_TEXT.ALL_UNSET
    }
    if (!domainInfo.hasSPF) {
      return DOMAIN_WARNING_TOOLTIP_TEXT.SPF_UNSET
    }
    return DOMAIN_WARNING_TOOLTIP_TEXT.DKIM_UNSET
  }
  const renderIcon = domainInfo && !(domainInfo.hasDKIM && domainInfo.hasSPF)
  return (
    <>
      {renderIcon && renderIconWithTooltip(SvgNames.warningSolidNoFill, `${rootClass}__icon-warning`, iconTooltipText(), t)}
      {ColWithTooltip({ cell })}
      {(phone || originalCell || fax) && (
        <Tooltip trigger={phoneNumbers} className={className}>
          {phone && <Typography text={`${t('Phone')}: ${phone}`} className={`${className}--phone`} type={TextType.BODY_TEXT_WHITE_TINY} />}
          {cell && <Typography text={`${t('Mobile')}: ${originalCell}`} className={`${className}--phone`} type={TextType.BODY_TEXT_WHITE_TINY} />}
          {fax && <Typography text={`${t('Fax')}: ${fax}`} className={`${className}--phone`} type={TextType.BODY_TEXT_WHITE_TINY} />}
        </Tooltip>
      )}
    </>
  )
}

const getStatus = (t: Function, isDefault: boolean | undefined, isVerified: boolean | undefined) => {
  const type = isDefault ? PillLabelType.DEFAULT : isVerified ? PillLabelType.SUCCESS : PillLabelType.ERROR
  const text = isDefault ? t('Default') : isVerified ? t('Verified') : t('Unverified')
  return <PillLabel type={type} text={text} noMargin dataTest={`pill-label-${text.toLowerCase()}`} />
}
