import React, { FC, useMemo, useState } from 'react'

import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import ContentExpander from '@components/ContentExpander/ContentExpander'
import IndentedTable from '@components/IndentedTable/IndentedTable'
import StatusToast, { Status } from '@components/StatusToast/StatusToast'
import { TableColumn } from '@components/Table/Table'
import { MultipleTables } from '@components/TableV2/components/MultipleTables/MultipleTables'
import { rootClassSpacerBlock } from '@components/TableV2/components/SpacerBlock/SpacerBlock'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import Typography, { LineHeight, ModalBodyStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import ProgramStepLink from '@src/pages/programs/dashboard/components/ProgramSteps/components/ProgramStepLink/ProgramStepLink'
import ProgramStepsHeader from '@src/pages/programs/dashboard/components/ProgramSteps/components/ProgramStepsHeader/ProgramStepsHeader'
import { getFormattedNumber } from '@utils/numbers'
import { ProgramStepType } from '@utils/program/program.constants'
import { ProgramBaseStepWithStepNum, ProgramStep } from '@utils/program/ProgramSteps.constants'

import { renderProgramStepLinkV2, renderStepNumberV2 } from './ProgramSteps.utils'

import './ProgramSteps.css'
export interface ProgramStepsProps {
  steps: ProgramStep[]
  lastUpdated: number
  programId: string
  handleExpandCollapseAll: (isExpanded: boolean) => void
  showAbWinnerToast: boolean
  showResetAbTestPop: boolean
  showResetAbTestToast: boolean
  isShownInManager?: boolean
  tabChange?: (tab: string) => void
  onRefresh(): void
  closeToast(): void
  closeModal: (reset: boolean) => void
  abWinner: string
  className?: string
  dataTest?: string
}

const rootClass = 'program-steps'

const ProgramSteps: FC<ProgramStepsProps> = (props: ProgramStepsProps) => {
  const {
    steps,
    lastUpdated,
    programId,
    isShownInManager,
    onRefresh,
    handleExpandCollapseAll,
    showResetAbTestToast,
    showResetAbTestPop,
    showAbWinnerToast,
    closeToast,
    closeModal,
    abWinner,
    tabChange,
  } = props
  const { t } = useTranslation()

  const [enableTableV2, setEnableTableV2] = useState(false)

  const columns: TableColumn[] = [
    {
      Header: 'Step',
      accessor: 'stepNumber',
      align: 'left',
      minWidth: 54,
      width: 54,
      maxWidth: 54,
      Cell: function renderStepNumber(row) {
        return <Typography text={row.row.original.stepNumber} type={TextType.BODY_TEXT_LIGHT} lineHeight={LineHeight.MEDIUM_LARGE} />
      },
    },
    {
      Header: 'Description',
      accessor: 'description',
      align: 'left',
      flex: true,
      minWidth: 84,
      maxWidth: 700,
    },
    {
      Header: 'Waiting',
      accessor: 'waiting',
      align: 'right',
      Cell: function renderProgramStepLink(row: any) {
        const waitTypes = [ProgramStepType.WAIT, ProgramStepType.WAIT_UNTIL, ProgramStepType.WAIT_UNTIL_IN_SEGMENT]
        const isDash = !waitTypes.includes(row.row.original.stepType) && parseInt(row.row.original.waiting) == 0
        return (
          <ProgramStepLink
            value={isDash ? '-' : getFormattedNumber(row.row.original.waiting)}
            column={'waiting'}
            uiStepName={row.row.original.stepNumber}
            programId={programId}
          />
        )
      },
      minWidth: 60,
      maxWidth: 60,
    },
    {
      Header: 'Completed',
      accessor: 'completed',
      align: 'right',
      Cell: function renderProgramStepLink(row: any) {
        const isDash = row.row.original.stepType == ProgramStepType.EXIT
        return (
          <ProgramStepLink
            value={isDash ? '-' : getFormattedNumber(row.row.original.completed)}
            column={'completed'}
            uiStepName={row.row.original.stepNumber}
            programId={programId}
            tabChange={tabChange}
          />
        )
      },
      minWidth: 94,
      maxWidth: 94,
    },
    {
      Header: 'Exited',
      accessor: 'exited',
      Cell: function renderProgramStepLink(row: any) {
        return (
          <ProgramStepLink
            value={getFormattedNumber(row.row.original.exited)}
            column={row.row.original.stepId.startsWith('exit_') ? 'exited' : 'early_exit'}
            uiStepName={row.row.original.stepNumber}
            programId={programId}
          />
        )
      },
      align: 'right',
      minWidth: 66,
      maxWidth: 66,
    },
  ]

  const renderAbWinnerToast = () => (
    <StatusToast message={t('A/B Winner Toast', { abWinner: abWinner })} status={Status.SUCCESS} closeStatus={() => closeToast()} />
  )

  const renderRestartAbTestToast = () => <StatusToast message={t('A/B Restart Toast')} status={Status.SUCCESS} closeStatus={() => closeToast()} />

  const renderRestartAbTestModal = () => (
    <ConfirmationModal
      isOpen
      className={`${rootClass}__confirmation`}
      title={t('A/B Restart Test')}
      body={
        <>
          <Typography text={t('A/B Restart Test Body One')} {...ModalBodyStyle} type={TextType.BODY_TEXT} inline />
          <Typography text={t('A/B Restart Test Body Two')} {...ModalBodyStyle} inline />
        </>
      }
      isYesNo
      yesButtonText={t('A/B Restart Test Button Two')}
      onAnswer={(answer: YesNo) => (answer === YesNo.YES ? closeModal(true) : closeModal(false))}
    />
  )

  const columnsV2 = useMemo(
    (): ColumnDefWithAdditionalProps<ProgramBaseStepWithStepNum>[] => [
      {
        header: 'Step',
        accessorKey: 'stepNumber',
        textAlign: 'left',
        maxSize: 46,
        padding: { right: 0 },
        cell: (cell) =>
          cell.row.original.depth <= 1 ? (
            <div className={`${rootClassSpacerBlock}__first-depth-values`}>{renderStepNumberV2(cell)}</div>
          ) : (
            <div className={`${rootClassSpacerBlock}__line-first`} />
          ),
      },
      {
        header: 'Description',
        accessorKey: 'description',
        textAlign: 'left',
        maxSize: 500,
        enableFlexCell: true,
        enableCustomCellValue: true,
        cell: (cell) => (
          <>
            {cell.row.original.depth > 1 && <div className={`${rootClassSpacerBlock}__last-block`}>{renderStepNumberV2(cell)}</div>}
            {cell.row.original.description}
          </>
        ),
      },
      {
        header: 'Waiting',
        accessorKey: 'waiting',
        textAlign: 'right',
        maxSize: 90,
        enableCustomCellValue: true,
        cell: (cell) => {
          const waitTypes = [ProgramStepType.WAIT, ProgramStepType.WAIT_UNTIL, ProgramStepType.WAIT_UNTIL_IN_SEGMENT]
          const isDash = !waitTypes.includes(cell.cell.row.original.stepType as ProgramStepType) && cell.row.original.waiting == 0
          const value = isDash ? '-' : getFormattedNumber(cell.row.original.waiting)
          return renderProgramStepLinkV2('waiting', cell, programId, value)
        },
      },
      {
        header: 'Completed',
        accessorKey: 'completed',
        textAlign: 'right',
        maxSize: 100,
        enableCustomCellValue: true,
        cell: (cell) => {
          const isDash = cell.row.original.stepType == ProgramStepType.EXIT
          const value = isDash ? '-' : getFormattedNumber(cell.row.original.completed)
          return renderProgramStepLinkV2('completed', cell, programId, value, tabChange)
        },
      },
      {
        header: 'Exited',
        accessorKey: 'exited',
        cell: (cell) =>
          renderProgramStepLinkV2(
            cell.row.original.stepId.startsWith('exit_') ? 'exited' : 'early_exit',
            cell,
            programId,
            getFormattedNumber(cell.row.original.exited)
          ),
        textAlign: 'right',
        maxSize: 80,
      },
    ],
    [programId, tabChange]
  )

  return (
    <div className={rootClass}>
      {showAbWinnerToast && renderAbWinnerToast()}
      {showResetAbTestToast && renderRestartAbTestToast()}
      {showResetAbTestPop && renderRestartAbTestModal()}
      <ProgramStepsHeader programId={programId} lastUpdated={lastUpdated} showViewProgram={!isShownInManager} onRefresh={onRefresh} />
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div onClick={() => setEnableTableV2((prev) => !prev)}>{' '}</div>
      <div className={`${rootClass}__table-info`}>
        <Typography text={t('Steps')} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.MEDIUM} />
        <ContentExpander dataTest={rootClass} onChange={handleExpandCollapseAll} />
      </div>
      <MultipleTables
        enableTableV2={enableTableV2}
        tableV2Props={{
          data: steps,
          columns: columnsV2,
          enableSubRow: true,
          enableSpacerBlock: true,
          styles: { borderTop: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0 },
        }}
        oldTable={<IndentedTable className={`${rootClass}__table`} data={steps as any} columns={columns} />}
      />
    </div>
  )
}

export default ProgramSteps
