import React, { FC, ReactNode, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import InfoTooltip from '@components/InfoTooltip/InfoTooltip'
import MediaCardActions, {
  MediaCardActionType,
  MediaCardDropdownActionType,
} from '@components/MediaCard/components/MediaCardActions/MediaCardActions'
import PillLabel from '@components/PillLabel/PillLabel'
import Radio from '@components/Radio'
import { RadioType } from '@components/Radio/Radio'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Svg, { SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './MediaCard.css'

export interface MediaCardProps {
  onMainAction: (e: React.MouseEvent | React.KeyboardEvent | React.ChangeEvent) => void
  mainActionLabel?: string
  actions?: MediaCardActionType[]
  dropdownActions?: MediaCardDropdownActionType[]
  /**
   * The content to render.
   * Styling that is related to the specific dimensions or presentation should have should be handled by the parent component.
   */
  bodyContent?: ReactNode
  title?: string
  description?: string
  isDefault?: boolean
  /** If this is defined, radio is displayed and the whole card triggers the main action */
  isSelected?: boolean
  /** If true, card width fits the body content. Otherwise, it is as wide as its container allows */
  fitContent?: boolean
  isBlur?: boolean
  isDisabled?: boolean
  disabledTooltip?: ReactNode
  infoTooltip?: string
  className?: string
  dataTest?: string
}

const rootClass = 'media-card'

const MediaCard: FC<MediaCardProps> = (props: MediaCardProps) => {
  const { t } = useTranslation()
  const isSelectable = props.isSelected !== undefined
  const {
    onMainAction,
    mainActionLabel = isSelectable ? t('Select') : t('Preview'),
    actions,
    dropdownActions,
    bodyContent,
    title = t('Untitled'),
    description,
    isDefault = false,
    infoTooltip,
    isDisabled = false,
    disabledTooltip,
    isSelected,
    className,
    dataTest = rootClass,
    isBlur,
    fitContent,
  } = props
  const [bodyHover, setBodyHover] = useState<boolean>(false)

  const renderSelectedIcon = () => (
    <div className={`${rootClass}__body-hover-content__selected`} data-test={`${dataTest}-check-selected`}>
      <Svg name={SvgNames.check} type={SvgType.LARGE_ICON} fill={SvgColor.WHITE} />
      <Typography text={t('Selected')} type={TextType.BODY_TEXT_WHITE} weight={TextWeight.MEDIUM} />
    </div>
  )

  const renderActionButton = () => (
    <Button
      buttonType={ButtonType.TRANSPARENT}
      onClick={onMainAction}
      className={`${rootClass}__main-action-button`}
      dataTest={`${dataTest}-main-action-button`}
    >
      {mainActionLabel}
    </Button>
  )

  const renderHoverContent = () => {
    return (
      <div
        className={classNames(`${rootClass}__body-hover`, { [`${rootClass}__body-hover-visible`]: bodyHover })}
        data-test={`${dataTest}-body-hover`}
      >
        <div className={`${rootClass}__body-hover-content`}>
          <MediaCardActions
            actions={actionsWrapper}
            dropdownActions={dropdownActions}
            onDropdownOpenChange={setBodyHover}
            className={`${rootClass}__body-hover-actions`}
            dataTest={`${dataTest}-actions`}
          />
          {isSelected ? renderSelectedIcon() : renderActionButton()}
        </div>
      </div>
    )
  }

  const renderEmptyListing = () => {
    return (
      <EmptyListing
        imgSrc={StaticImageNames.cactus}
        size={EmptyListingSize.CARD}
        text={t('Media.Card.Empty.Body.Text')}
        dataTest={`${rootClass}-body-empty`}
      />
    )
  }

  const renderContent = () => {
    const content = bodyContent || renderEmptyListing()
    return (
      <>
        {content}
        {!isDisabled && renderHoverContent()}
      </>
    )
  }

  const renderTitle = () => {
    return (
      <div className={`${rootClass}__footer-title`} data-test={`${dataTest}-footer-title`}>
        <Tooltip
          align="start"
          arrowOffset={10}
          trigger={
            <Typography
              className={`${rootClass}__footer-title__text`}
              text={title}
              type={isDisabled ? TextType.BODY_TEXT_LIGHT : undefined}
              weight={TextWeight.MEDIUM}
              inline
            />
          }
        >
          {title}
        </Tooltip>
        {infoTooltip && <InfoTooltip dataTest={`${dataTest}-info-tooltip`} text={infoTooltip} />}
        {isDefault && <PillLabel dataTest={`${dataTest}-default-pill`} text={t('Default')} />}
      </div>
    )
  }

  const renderDescription = () => {
    if (description === undefined) {
      return null
    }
    return (
      <Typography
        text={description}
        type={isDisabled ? TextType.BODY_TEXT_SMALL_LIGHT : TextType.NORMAL_TEXT_GRAY}
        lineHeight={LineHeight.MEDIUM_SMALL}
        className={`${rootClass}__footer-description`}
        dataTest={`${dataTest}-footer-description`}
      />
    )
  }

  const handleClick = (event: React.MouseEvent | React.KeyboardEvent | React.ChangeEvent) => {
    const isKey = 'key' in event
    const shouldClick = !isKey || (isKey && (event.key === ' ' || event.key === 'Enter'))

    if (!isDisabled && shouldClick) {
      event.preventDefault()
      event.stopPropagation()
      onMainAction(event)
    }
  }

  const actionsWrapper = actions?.map<MediaCardActionType>((action) => {
    // Prevent the card main action from being triggered
    return {
      ...action,
      onClick: (event) => {
        event.preventDefault()
        event.stopPropagation()
        action.onClick(event)
      },
    }
  })

  const renderMediaCard = () => (
    <div
      tabIndex={0}
      role={'button'}
      onKeyDown={handleClick}
      onClick={isSelectable ? handleClick : undefined}
      className={classNames(rootClass, className, {
        [`${rootClass}--selected`]: isSelected,
        [`${rootClass}--selectable`]: isSelectable,
        [`${rootClass}--default`]: isDefault,
        [`${rootClass}--disabled`]: isDisabled,
        [`${rootClass}--blur`]: isBlur,
        [`${rootClass}--fit-content`]: fitContent,
      })}
      data-test={dataTest}
    >
      <div className={classNames(`${rootClass}__body`, { [`${rootClass}__body--disabled`]: isDisabled })} data-test={`${dataTest}-body`}>
        {renderContent()}
      </div>
      <div
        className={classNames(`${rootClass}__footer`, {
          [`${rootClass}__footer--disabled`]: isDisabled,
        })}
        data-test={`${dataTest}-footer`}
      >
        <div className={`${rootClass}__footer-info`} data-test={`${dataTest}-footer-info`}>
          {renderTitle()}
          {renderDescription()}
        </div>
        {isSelectable && (
          <div className={`${rootClass}__radio-container`}>
            <Radio
              type={RadioType.CHECKMARK}
              checked={isSelected}
              onChange={handleClick}
              disabled={isDisabled}
              className={classNames(`${rootClass}__radio`)}
              dataTest={`${dataTest}-radio`}
            />
          </div>
        )}
      </div>
    </div>
  )

  if (isDisabled && disabledTooltip) {
    return (
      <Tooltip trigger={renderMediaCard()} position={'top'} minimalPadding={false}>
        {disabledTooltip}
      </Tooltip>
    )
  }
  return renderMediaCard()
}

export default MediaCard
