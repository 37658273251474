import React, { FC } from 'react'

import DateRangePicker, { DateRangePickerProps } from '@components/DateRangePicker/DateRangePicker'
import PrintButton from '@components/PrintButton'
import StickyContainer from '@components/StickyContainer/StickyContainer'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './RevenueImpactHeader.css'

export interface RevenueImpactHeaderProps {
  rangePickerProps: DateRangePickerProps
  lastSyncedDate?: string
  syncConfiguration?: string
  dataTest?: string
  isSticky?: boolean
  isSyncing?: boolean
  isSyncingError?: boolean
}

const rootClass = 'revenue-impact-header'

const RevenueImpactHeader: FC<RevenueImpactHeaderProps> = (props: RevenueImpactHeaderProps) => {
  const { rangePickerProps, isSyncing, isSyncingError, lastSyncedDate, syncConfiguration, isSticky = false, dataTest = rootClass } = props

  const { t } = useTranslation()

  const syncTooltip = (
    <Tooltip
      trigger={<Svg name={SvgNames.info} type={SvgType.ICON} dataTest={`${dataTest}-info-svg`} fill={SvgColor.LIGHT_GRAY} />}
      align="end"
      className={`${rootClass}__tooltip`}
      triggerClassName={`${rootClass}__info-svg`}
      dataTest={`${dataTest}-tooltip`}
    >
      {isSyncing ? (
        t('Revenue.Impact.Syncing.Tooltip')
      ) : (
        <Typography
          type={TextType.BODY_TEXT_WHITE_SMALL}
          lineHeight={LineHeight.MEDIUM_SMALL}
          text={t('Revenue.Impact.Sync.Config.Tooltip', { syncConfiguration })}
        />
      )}
    </Tooltip>
  )

  return (
    <div className={rootClass} data-test={dataTest}>
      <div className={`${rootClass}__row`}>
        <Typography type={TextType.PAGE_HEADER} text={t('Revenue Impact Report')} />
        <PrintButton rootClass={rootClass} text={t('Print report')} />
      </div>
      <div className={`${rootClass}__row`}>
        <StickyContainer isSticky={isSticky}>
          <DateRangePicker {...rangePickerProps} />
          {isSyncing ? (
            <div className={`${rootClass}__info`}>
              <Typography type={TextType.BODY_TEXT} text={t('Revenue.Impact.Syncing.Message')} />
              {isSticky ? <PrintButton rootClass={rootClass} text={t('Print report')} /> : syncTooltip}
            </div>
          ) : (
            <div className={`${rootClass}__info`}>
              {isSyncingError && (
                <Tooltip
                  trigger={
                    <Svg
                      className={`${rootClass}__info-warning-svg`}
                      dataTest={`${dataTest}__info-warning-svg`}
                      name={SvgNames.warningSolidNoFill}
                      type={SvgType.MEDIUM_LARGE_ICON}
                      fill={SvgColor.REMOVE_RED}
                    />
                  }
                  alignTextCenter
                  triggerClassName={`${rootClass}__info-svg`}
                  dataTest={`${dataTest}-tooltip`}
                >
                  {t('CRM.Syncing.Error.Tooltip')}
                </Tooltip>
              )}
              <Typography text={`${t('Last synced from CRM')}:`} inline />
              <Typography type={TextType.BODY_TEXT_LIGHT} text={lastSyncedDate || t('No data found')} />
              {isSticky ? <PrintButton rootClass={rootClass} text={t('Print report')} /> : syncTooltip}
            </div>
          )}
        </StickyContainer>
      </div>
    </div>
  )
}

export default RevenueImpactHeader
