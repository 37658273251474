import React, { FC } from 'react'
import { useForm } from 'react-hook-form'

import * as yup from 'yup'

import { MessageType } from '@complex/MessagePickerModal/messagePickerModalUtils'
import FormGroup from '@components/FormGroup/FormGroup'
import FormRow from '@components/FormRow/FormRow'
import Input from '@components/Input/Input'
import { ModalBody } from '@components/Modal'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { Program, ProgramMessage, ProgramSource } from '@graphql/types/query-types'
import { yupResolver } from '@hookform/resolvers/yup'
import BranchStepDetail from '@src/pages/programs/dashboard/components/ProgramSteps/components/BranchStepDetail/BranchStepDetail'
import { getBranchStepDetailProps } from '@src/pages/programs/dashboard/components/ProgramSteps/ProgramSteps.utils'
import {
  getIsListSegment,
  getNewSegment,
  getNewSegmentName,
  getSegmentAndNotValue,
  getUpdatedProgramMessages,
  getUpdatedProgramSources,
} from '@utils/program/program'
import { ProgramBranchStepExt, Step, Track } from '@utils/program/program.constants'
import { ProgramWithStepData } from '@utils/program/ProgramSteps.constants'

import ConditionalSelect from '../../../ConditionalSelect/ConditionalSelect'

import './editBranchStep.css'

const rootClass = 'edit-branch-step'

interface Props {
  step: Step
  program: Program
  tracks: Track[]
  isRunning: boolean
  saveStepAndProgram(step: Step | null, program?: Program): void
  submitId: string
  dataTest?: string
}

export const handleFormSubmit = (data: any, step: ProgramBranchStepExt, props: Props) => {
  const { program, saveStepAndProgram } = props
  const { segmentMode, not } = getSegmentAndNotValue(data.segmentMode)
  const isListSegment = getIsListSegment(segmentMode)
  if (!isListSegment && data.newSegment === 'true') {
    const localSegmentDetailsRaw = program.localSegmentDetails ?? []
    const srcId = getNewSegmentName(program.localSegmentDetails)
    const newSegment = getNewSegment(srcId, data.messageIds, segmentMode)
    const localSegmentDetails = [...localSegmentDetailsRaw, newSegment]

    saveStepAndProgram(
      {
        ...step,
        displayName: data.displayName,
        srcId,
        not,
      },
      {
        ...program,
        localSegmentDetails,
      }
    )
  } else {
    saveStepAndProgram({
      ...step,
      displayName: data.displayName,
      srcId: data.srcId,
      not,
    })
  }
}

const schema = yup.object().shape({
  displayName: yup.string().required('Step Name is required.'),
})

const EditBranchStep: FC<Props> = (props: Props) => {
  const { step: baseStep, saveStepAndProgram, program, isRunning, submitId, dataTest = 'edit-branch-step' } = props

  const step = baseStep as ProgramBranchStepExt

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })
  const { t } = useTranslation()

  const onSubmit = (data: any) => {
    handleFormSubmit(data, step, props)
  }

  const onProgramSourcesUpdate = (sources: ProgramSource[]) => {
    saveStepAndProgram(null, getUpdatedProgramSources(program, sources))
  }

  const onMessageUpdate = (messages: ProgramMessage) => {
    saveStepAndProgram(null, getUpdatedProgramMessages(program, [messages]))
  }

  const renderForm = () => (
    <form data-test={dataTest} onSubmit={handleSubmit(onSubmit)} className={rootClass}>
      <FormRow>
        <Input
          className={`${rootClass}__step-name`}
          label={t('Step Name')}
          defaultValue={step.displayName}
          name="displayName"
          register={register('displayName')}
        />
        {errors.displayName && <span className="error">{t(errors.displayName.message)}</span>}
      </FormRow>
      <FormGroup>
        <ConditionalSelect
          dataTest={`${dataTest}-conditional-select`}
          step={step}
          register={register}
          formReset={reset}
          formData={getValues}
          program={program}
          onProgramSourcesUpdate={onProgramSourcesUpdate}
          onMessageUpdate={onMessageUpdate}
          allowedMessageCategories={[MessageType.SENT, MessageType.SCHEDULED, MessageType.PROGRAM]}
        />
      </FormGroup>
      <button type="submit" id={submitId} hidden />
    </form>
  )

  const renderView = () => {
    if (!program || !program.tracks) {
      return undefined
    }
    return (
      <>
        <Typography text={step.displayName} weight={TextWeight.MEDIUM} type={TextType.SECTION_HEADER} />
        <BranchStepDetail {...getBranchStepDetailProps(step, program as ProgramWithStepData)} />
      </>
    )
  }

  return <ModalBody>{isRunning ? renderView() : renderForm()}</ModalBody>
}

export default EditBranchStep
