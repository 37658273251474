import { SelectOption } from '@interface/Select'

export enum SendAlertOn {
  EVERY_DAY = 'every-day',
  WEEKDAYS = 'weekdays',
  CUSTOM = 'custom',
}

export enum AlertFrequency {
  DAILY = 'daily',
  WEEKLY = 'weekly',
}

export const getSendAlertOnOptions = (t: Function): SelectOption[] => [
  {
    label: t('Every day'),
    value: SendAlertOn.EVERY_DAY,
  },
  {
    label: t('Weekdays'),
    value: SendAlertOn.WEEKDAYS,
  },
  {
    label: t('Custom'),
    value: SendAlertOn.CUSTOM,
  },
]

export const getHoursOptions = (): SelectOption[] =>
  [...Array(24).keys()].map((index) => {
    return {
      label: `${index > 12 ? index - 12 : index || 12}:00 ${index > 11 ? 'PM' : 'AM'}`,
      value: index.toString(),
    }
  })
