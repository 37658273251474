import React, { FC, useEffect, useState } from 'react'

import AccordionDetailsModal from '@components/AccordionDetailsModal/AccordionDetailsModal'
import { ACTIVITY_STATUS } from '@components/ActivityCard/utils/ActivityCard.constants'
import PreviewAssetModal, { PreviewModalSize } from '@components/PreviewAssetModal/PreviewAssetModal'
import { SvgNames } from '@components/Svg'
import { legacyActonContext, useTranslation } from '@const/globals'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { ContactRecentActivity } from '@graphql/types/microservice/entity-join-types'
import { FieldsData } from '@src/pages/listingPages/FormsJoinView/components/DetailsCardWrapper/components/SubmissionDetails/SubmissionDetails'
import { useDetailsCardWrapperRequests } from '@src/pages/listingPages/FormsJoinView/components/DetailsCardWrapper/DetailsCardWrapper.graphQL'
import {
  getContactsCardActions,
  getContactsCardHeader,
  getContactsCardSections,
  DetailDataItem,
  WebinarType,
} from '@src/pages/listingPages/FormsJoinView/components/DetailsCardWrapper/utils/DetailsCardWrapper.utils'
import { contactRequestedFields } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.constants'
import { useFormsJoinViewListingPageRequests } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.graphQL'
import { ItemType } from '@utils/categorization'
import { Contact } from '@utils/contact/contact.constants'
import { ContactInformationFields } from '@utils/contact/contact.utils'

import './DetailsCardWrapper.css'

export interface ContactInformationField {
  tooltip: string | null
  value?: string | null
  icon: SvgNames
}

interface DetailsCardWrapperProps {
  className?: string
  dataTest?: string
  selectedContact: Contact
  onCloseModal: VoidFunction
  activeSubmission: ItemDto
  itemType: ItemType
  contactHeaders: string[]
}

interface DetailsCardWrapperState {
  contactInformation?: ContactInformationFields
  contactRecentActivities: ContactRecentActivity[]
  contactSubmissionDetails: Record<string, Record<number, string>>
  submissionDetailsDataItems: DetailDataItem[]
  formPreviewData?: { title: string; htmlPreview?: string; urlPreview?: string }
  columnIndicesToNames: Record<string, string>
  webinarRegistrationResponses: Record<string, string>
  webinarRegistrationDetails: DetailDataItem
}

interface SubmissionDetailItemValidator {
  type: string
  error_message: string
}

export const detailsCardWrapperRootClass = 'details-card-wrapper'

const DetailsCardWrapper: FC<DetailsCardWrapperProps> = (props: DetailsCardWrapperProps) => {
  const { selectedContact, activeSubmission, itemType, contactHeaders, onCloseModal } = props

  const [state, setState] = useState<DetailsCardWrapperState>({
    contactInformation: undefined,
    contactRecentActivities: [],
    contactSubmissionDetails: {},
    submissionDetailsDataItems: [],
    columnIndicesToNames: {},
    webinarRegistrationResponses: {},
    webinarRegistrationDetails: { formName: '', formDetails: [] },
  })

  const {
    getContactData,
    getContactFormsActivities,
    getSubmissionDetails,
    getFormSummaryDetails,
    getWebinarContactRegistrationDetails,
    getListSchemaRequest,
  } = useDetailsCardWrapperRequests()

  const { getFormPreview } = useFormsJoinViewListingPageRequests()

  const {
    contactInformation,
    contactRecentActivities,
    formPreviewData,
    contactSubmissionDetails,
    submissionDetailsDataItems,
    webinarRegistrationResponses,
    columnIndicesToNames,
    webinarRegistrationDetails,
  } = state

  const { t } = useTranslation()

  useEffect(() => {
    if (activeSubmission && activeSubmission.externalId && selectedContact) {
      getContactData(activeSubmission.externalId, selectedContact.recId, contactRequestedFields).then(({ data }) => {
        setState((state) => ({ ...state, contactInformation: data?.getContactSubmissionData as ContactInformationFields }))
      })
      getContactFormsActivities(selectedContact.recId, itemType === ItemType.FORM_SUBMISSION ? 'FORM' : 'WEBINAR').then(({ data }) =>
        setState((state) => ({ ...state, contactRecentActivities: data?.getContactFormActivities as ContactRecentActivity[] }))
      )
      if (itemType == ItemType.WEBINAR_SUBMISSION) {
        const webinarId = 'webinarId' in activeSubmission ? (activeSubmission.webinarId as string) : ''
        getListSchemaRequest(activeSubmission.externalId).then((headers) =>
          setState((state) => ({
            ...state,
            columnIndicesToNames: headers.reduce((mappedHeaders: Record<string, string>, header) => {
              mappedHeaders[header.columnIndex] = header.columnName
              return mappedHeaders
            }, {}),
          }))
        )
        if (activeSubmission.subTypeDTO && activeSubmission.subTypeDTO[0] && activeSubmission.subTypeDTO[0].name) {
          getWebinarContactRegistrationDetails(
            webinarId,
            activeSubmission.subTypeDTO[0].name.toUpperCase() as WebinarType,
            selectedContact.uuid as string
          ).then(({ data }) => {
            setState((state) => ({
              ...state,
              webinarRegistrationResponses: data?.getWebinarContactRegistrationData[webinarId] as Record<number, string>,
            }))
          })
        }
      } else {
        getSubmissionDetails(activeSubmission.externalId, selectedContact.uuid as string).then(
          ({ data }) =>
            data &&
            setState((state) => ({ ...state, contactSubmissionDetails: data.getContactSubmissionData as Record<string, Record<number, string>> }))
        )
      }
    }
  }, [])

  useEffect(() => {
    const forms = Object.keys(contactSubmissionDetails)
    if (forms.length) {
      Promise.all(forms.reduce((result: any[], id) => [...result, getFormSummaryDetails(id)], [])).then((data) => {
        const headerFieldMap = contactHeaders.reduce<Record<string, string>>((acc, header, index) => {
          acc[header] = selectedContact.fields[index] ?? ''
          return acc
        }, {})
        setState((state) => ({
          ...state,
          submissionDetailsDataItems: data.reduce(
            (submissionDetails: DetailDataItem[], { data }) => [
              ...submissionDetails,
              {
                formName: data.getFormSummaryData.formName,
                formDetails: data.getFormSummaryData.blocks.reduce(
                  (dataItems: FieldsData[], block: any) =>
                    block.validator
                      ? [
                          ...dataItems,
                          {
                            field: {
                              name: block.listColumnName,
                              required: (block.validator as SubmissionDetailItemValidator[]).some(({ type }) => type === 'required'),
                              hidden: block.type === 'hidden',
                            },
                            response: block.type === 'hidden' ? headerFieldMap[block.listColumnName] : headerFieldMap[block.listColumnName] ?? '-',
                          },
                        ]
                      : dataItems,
                  []
                ),
              },
            ],
            []
          ),
        }))
      })
    }
  }, [contactSubmissionDetails])

  useEffect(() => {
    if (webinarRegistrationResponses) {
      setState((state) => ({
        ...state,
        webinarRegistrationDetails: {
          formName: 'name' in activeSubmission ? (activeSubmission.name as string) : '',
          formDetails: Object.keys(webinarRegistrationResponses).reduce(
            (dataItems: FieldsData[], key) => [
              ...dataItems,
              { field: { name: columnIndicesToNames[key] }, response: webinarRegistrationResponses[key] },
            ],
            []
          ),
        },
      }))
    }
  }, [columnIndicesToNames])

  const getFormSubmittedPreviewUrl = (formId: string, recId: string) =>
    `${legacyActonContext}/internalapi/FormBuilder/read/snippet/form/${formId}?recId=${recId}`

  const getContactFormPreview = async (externalId: string, formName: string, status: ACTIVITY_STATUS) => {
    if (status === ACTIVITY_STATUS.SUBMITTED) {
      setState((state) => ({
        ...state,
        formPreviewData: { title: formName, urlPreview: getFormSubmittedPreviewUrl(externalId, selectedContact.recId) },
      }))
    } else {
      const { data } = await getFormPreview(externalId)
      if (data) {
        setState((state) => ({ ...state, formPreviewData: { title: formName, htmlPreview: data.getFormPreview } }))
      }
    }
  }

  return (
    <>
      {formPreviewData ? (
        <PreviewAssetModal
          isOpen
          onClose={() => setState((state) => ({ ...state, formPreviewData: undefined }))}
          size={PreviewModalSize.MEDIUM}
          title={formPreviewData.title}
          useTitlePrefix={false}
          previewHtml={formPreviewData.htmlPreview || undefined}
          assetSrc={formPreviewData.urlPreview || undefined}
        />
      ) : (
        <AccordionDetailsModal
          isOpen={!!selectedContact}
          closeModal={() => onCloseModal()}
          sections={getContactsCardSections(
            t,
            contactRecentActivities.slice(0, 5),
            selectedContact,
            (externalId, formName, status) => getContactFormPreview(externalId, formName, status),
            submissionDetailsDataItems,
            webinarRegistrationDetails,
            itemType,
            contactInformation
          )}
          customHeaderActions={getContactsCardActions(selectedContact)}
          header={getContactsCardHeader(contactInformation)}
        />
      )}
    </>
  )
}

export default DetailsCardWrapper
