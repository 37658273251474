import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import classNames from 'classnames'

import Caution from '@components/Caution/Caution'
import { LabelType, LabelV2 } from '@components/LabelV2/LabelV2'
import Spinner, { LoaderSize } from '@components/Spinner/Spinner'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { FieldDefinition, ProgramAdditionalEntity, StringKeyValue } from '@graphql/types/query-types'
import CRMFieldsSelector from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/components/CRMFieldsSelector/CRMFieldsSelector'
import {
  DetailsDataType,
  IMMEDIATELY_KEY,
  opportunitiesEntities,
} from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.constants'
import { EditCRMStepV2Context } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.context'
import { FieldDefinitionWithValue } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.interfaces'
import {
  findKeyValueByFieldName,
  getEntityTitle,
  hasValidFields,
} from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.utils'
import { useFieldInputRenderer } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/useFieldInputRenderer'
import { ProgramCreateInCRMStepExt } from '@utils/program/program.constants'

import './EntityDetails.css'

interface EntityDetailsProps {
  className?: string
  dataTest?: string
  entity: ProgramAdditionalEntity
  index: number
}

const rootClass = 'entity-details'

const EntityDetails: FC<EntityDetailsProps> = (props: EntityDetailsProps) => {
  const { dataTest = rootClass, className = '', entity, index } = props

  const { t } = useTranslation()

  const {
    update,
    values: { fieldDefinitionsByEntity, steps },
  } = useContext(EditCRMStepV2Context)

  const fields = useMemo(() => fieldDefinitionsByEntity.get(entity.entityType ?? '') ?? [], [entity.entityType, fieldDefinitionsByEntity])

  const [loading, setLoading] = useState(!fields)

  const { control, watch } = useFormContext<ProgramCreateInCRMStepExt>()

  const additionalEntityFields = watch(`additionalEntities.${index}.fields`)
  const pushNewRecords = watch('pushNewRecords')

  const fieldRenderer = useFieldInputRenderer({ dataTest, entityIndex: index, entity: entity })

  const { requiredFields, optionalFields } = useMemo(() => {
    return fields.reduce(
      ({ requiredFields, optionalFields }: { requiredFields: FieldDefinition[]; optionalFields: FieldDefinition[] }, field) => {
        if ((field.required || field.alwaysShown) && field.dataType !== DetailsDataType.BOOLEAN && !field.hidden) {
          return { optionalFields, requiredFields: [...requiredFields, field] }
        } else {
          return {
            requiredFields,
            optionalFields: field.hidden ? optionalFields : [...optionalFields, { ...field, required: false }],
          }
        }
      },
      { requiredFields: [], optionalFields: [] }
    )
  }, [fields, additionalEntityFields])

  const userAddedEntityFields = useMemo(() => {
    const allEntityFields = entity?.fields ?? []
    return allEntityFields.filter(({ key }) => !requiredFields.some(({ fieldName }) => fieldName === key))
  }, [entity?.fields, requiredFields])

  const useFieldArrayMethods = useFieldArray({ control, name: `additionalEntities.${index}.fields` })

  /*
   * Adds a field to the additional entities
   * @param {FieldDefinition} field - The field to be added
   * @returns {void}
   */
  const onAddField = ({ fieldName, dataType, required }: FieldDefinition) => {
    const newField: StringKeyValue = {
      key: fieldName,
      value: dataType === DetailsDataType.BOOLEAN ? 'false' : dataType === DetailsDataType.DATE && required ? 'day_0' : '',
    }
    useFieldArrayMethods.append(newField)
  }

  /*
   * Removes a field from the additional entities
   * @param {string} key - The key of the field to be removed
   * @returns {void}
   */
  const onRemoveField = (key: string) => {
    const fieldIndex = (entity.fields ?? [])?.findIndex(({ key: fieldKey }) => fieldKey === key)
    if (fieldIndex >= 0) {
      useFieldArrayMethods.remove(fieldIndex)
    }
  }

  /*
   * Updates the field value in the additional entities
   * @param {FieldDefinitionWithValue} field - The field to be updated
   * @returns {void}
   */
  const onFieldChange = ({ field, value }: FieldDefinitionWithValue) => {
    const fields = entity.fields ?? []
    const fieldIndex = fields.findIndex(({ key }) => key === field.fieldName)
    if (fieldIndex >= 0) {
      const updatedField: StringKeyValue = {
        key: field.fieldName,
        value,
      }
      useFieldArrayMethods.update(fieldIndex, updatedField)
    }
  }

  /*
   * If the entity has no fields yet, add the required fields to the entity
   */
  useEffect(() => {
    if (requiredFields.length > 0 && entity.fields?.length === 0) {
      const newFields = requiredFields.map(({ fieldName, dataType, required, defaultValue }) => ({
        key: fieldName,
        value:
          defaultValue ?? (dataType === DetailsDataType.BOOLEAN ? 'false' : dataType === DetailsDataType.DATE && required ? IMMEDIATELY_KEY : ''),
      }))
      useFieldArrayMethods.append(newFields)
    }
  }, [requiredFields])

  /*
   * if all required fields are filled, then update the current step with completed = true
   */
  useEffect(() => {
    if (additionalEntityFields) {
      const isValid = hasValidFields(requiredFields, additionalEntityFields)
      update({
        steps: steps.map((step) => ({
          ...step,
          completed: step.key === entity.entityType ? isValid : step.completed,
          error: step.key === entity.entityType && !step.newStep ? !isValid : step.error,
        })),
      })
    }
  }, [additionalEntityFields, requiredFields])

  /*
   * If the field definitions are loaded, then set loading to false
   */
  useEffect(() => {
    if (fieldDefinitionsByEntity.has(entity.entityType ?? '') && loading) {
      setLoading(false)
    }
  }, [entity.entityType, fieldDefinitionsByEntity, loading])

  const renderField = (field: FieldDefinition) => {
    const fieldValue = findKeyValueByFieldName(field.fieldName, entity?.fields ?? [])?.value ?? ''
    return (
      <div className={`${rootClass}__field`} key={`${entity.entityType}-${field.fieldName}`}>
        {fieldRenderer({ field, value: fieldValue, onChange: onFieldChange })}
      </div>
    )
  }

  if (loading) {
    return <Spinner size={LoaderSize.MEDIUM} />
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {!pushNewRecords && opportunitiesEntities.includes(entity.entityType as string) && (
        <Caution message={<Typography text={t('EditCrmStepV2.StepDetails.Caution')} weight={TextWeight.BOLD} />} />
      )}
      {requiredFields.map(renderField)}
      <div className={classNames(`${rootClass}__added-fields`, { [`${rootClass}__added-fields-empty`]: userAddedEntityFields.length === 0 })}>
        {userAddedEntityFields.length > 0 && (
          <LabelV2
            label={t('EditCrmStepV2.EntityDetails.AddedLabel', { entity: getEntityTitle(entity.entityType ?? '', true) })}
            labelType={LabelType.input}
          />
        )}
        <CRMFieldsSelector
          addFieldButtonText={t('EditCrmStepV2.EntityDetails.AddFieldButton')}
          dataTest={`${dataTest}-fields-selector`}
          selectedFields={userAddedEntityFields}
          availableFields={optionalFields}
          onAddField={onAddField}
          onChange={onFieldChange}
          onRemoveField={onRemoveField}
          noBackground
          entityIndex={index}
          entity={entity}
        />
      </div>
    </div>
  )
}

export default EntityDetails
