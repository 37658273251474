import React, { FC, ReactNode, useState } from 'react'

import classNames from 'classnames'

import CaretIcon, { CaretIconDirection } from '@components/CaretIcon'
import Caution from '@components/Caution/Caution'
import InfoAction from '@components/InfoAction/InfoAction'
import Loader, { LoaderTypes } from '@components/Loader/Loader'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import Button, { ButtonType } from '../Button'

import './BulkActionsModal.css'

export interface BulkModalProps {
  className?: string
  dataTest?: string
  isOpen: boolean
  errorMessages: string[]
  successMessages: string[]
  title: string
  warnings: string | ReactNode
  warningAsInfo?: boolean
  hideCardIcons?: boolean
  primaryButtonText?: string
  onClose: () => void
  onAction?: () => void
  successTitle?: string
  errorTitle?: string
  errorDescriptions?: string[]
  showLoadingOnPrimary?: boolean
}

const rootClass = 'bulk-actions-modal'

const BulkActionsModal: FC<BulkModalProps> = (props: BulkModalProps) => {
  const {
    dataTest = rootClass,
    className = '',
    isOpen,
    errorMessages,
    errorDescriptions,
    successMessages,
    title,
    warnings,
    warningAsInfo,
    hideCardIcons,
    primaryButtonText = 'Ok',
    onClose,
    onAction,
    successTitle,
    errorTitle,
    showLoadingOnPrimary,
  } = props
  const { t } = useTranslation()
  const [inAction, setInAction] = useState(false)
  const [openRow, setOpenRow] = useState<{ [key: number]: boolean }>({})

  const onClick = (index: number) => setOpenRow({ ...openRow, [index]: !openRow[index] })

  const renderList = (error: boolean, status: string[], errorDescriptions?: string[]) => (
    <div className={`${rootClass}__card-wrapper`}>
      <div className={`${rootClass}__card-title`} data-test={error ? 'error-title' : 'success-title'}>
        {!hideCardIcons && (
          <span
            className={classNames(`${rootClass}__svg`, {
              [`${rootClass}__svg-error`]: error,
              [`${rootClass}__svg-success`]: !error,
            })}
          >
            <Svg name={error ? SvgNames.inputStatusInvalid : SvgNames.inputStatusSuccess} type={SvgType.MEDIUM_LARGE_ICON} />
          </span>
        )}
        <Typography
          text={t(error ? errorTitle || t('Error messages') : successTitle || t('Success messages'), { count: status.length })}
          weight={TextWeight.MEDIUM}
        />
      </div>
      <div className={classNames(`${rootClass}__card-body`, { [`${rootClass}__card-body--hide-icons`]: hideCardIcons })}>
        {status.map((stat, index: number) => {
          const showDescription = error && !!errorDescriptions && errorDescriptions[index]
          return (
            <>
              <div
                key={`${error}-${stat}-${index}`}
                className={classNames(`${rootClass}__card-message-row`, { [`${rootClass}__card-message-row--has-description`]: showDescription })}
                onClick={() => onClick(index)}
                tabIndex={0}
                onKeyDown={(keyDownEvent) => (keyDownEvent.key === ' ' ? onClick(index) : undefined)}
                role="button"
              >
                {showDescription && (
                  <CaretIcon
                    direction={openRow[index] ? CaretIconDirection.DOWN : CaretIconDirection.RIGHT}
                    type={SvgType.VERY_SMALL_ICON}
                    className={`${rootClass}__card-message-icon`}
                  />
                )}
                <Typography dataTest={`${rootClass}-message`} className={`${rootClass}__card-message`} text={stat} />
              </div>
              {openRow[index] && showDescription && (
                <div className={`${rootClass}__card-message-description`}>
                  <Typography type={TextType.BODY_TEXT_LIGHT} className={`${rootClass}__card-message-description`} text={errorDescriptions[index]} />
                </div>
              )}
            </>
          )
        })}
      </div>
    </div>
  )

  const renderHeader = () => (
    <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__title`}>
      {t(title)}
    </ModalHeader>
  )

  const handleAction = () => {
    if (onAction) {
      if (showLoadingOnPrimary) {
        setInAction(true)
      }
      onAction()
    } else {
      onClose()
    }
  }

  const getPrimaryButtonContent = () => (inAction && onAction ? <Loader loaderType={LoaderTypes.row} /> : t(primaryButtonText))

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={renderHeader()}>
      <ModalBody className={`${rootClass}__body`}>
        {!warningAsInfo && <Caution message={warnings} className={`${rootClass}__banner`} />}
        {warningAsInfo && <InfoAction message={warnings} className={`${rootClass}__banner`} svgName={SvgNames.lightBulb} />}
        {errorMessages.length > 0 && renderList(true, errorMessages, errorDescriptions)}
        {successMessages.length > 0 && renderList(false, successMessages)}
      </ModalBody>
      <ModalFooter className={`${rootClass}__footer`}>
        {onAction ? (
          <Button buttonType={ButtonType.TERTIARY} onClick={onClose} dataTest={`${dataTest}-button-tertiary`}>
            {t('Cancel')}
          </Button>
        ) : (
          <span></span>
        )}
        <Button
          buttonType={ButtonType.PRIMARY}
          onClick={handleAction}
          disabled={inAction}
          dataTest={`${dataTest}-button-primary`}
          className={classNames({
            [`${rootClass}__acting`]: inAction,
          })}
        >
          {getPrimaryButtonContent()}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default BulkActionsModal
