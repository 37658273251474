import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'

import classNames from 'classnames'

import { useApolloClient, useMutation, useLazyQuery } from '@apollo/client'
import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import Checkbox from '@components/Checkbox'
import LazyLoadOnScrollContainer from '@components/LazyLoadOnScrollContainer/LazyLoadOnScrollContainer'
import Radio from '@components/Radio'
import RadioGroup from '@components/RadioGroup'
import Spinner from '@components/Spinner/Spinner'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import TextArea from '@components/TextArea/TextArea'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import submitPollResponse from '@graphql/mutations/submitPollResponse'
import getCommentsPollPage from '@graphql/queries/getCommentsPollPage'
import getPollResponsePage from '@graphql/queries/getPollResponsePage'
import { SubmitPollResponseMutation, SubmitPollResponseMutationVariables } from '@graphql/types/mutation-types'
import {
  GetCommentsPollPageQuery,
  GetCommentsPollPageQueryVariables,
  GetPollResponsePageQuery,
  GetPollResponsePageQueryVariables,
  PollPageResponse,
  PollUserChoiceResponse,
} from '@graphql/types/query-types'
import { useTranslation } from '@utils/const/globals'

import './PollBlockResponse.css'

const rootClass = 'poll-block-response'

const PollBlockResponse = () => {
  const [state, setState] = useState<PollPageResponse>({})
  const [comments, setComments] = useState<(PollUserChoiceResponse | undefined)[]>()
  const [isEdit, setIsEdit] = useState<boolean>(true)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [totalComments, setTotalComments] = useState<number>(0)

  const containerRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()
  const client = useApolloClient()
  const queryParams = new URLSearchParams(window.location.search)

  const variables = {
    accountId: queryParams.get('accountId') || '',
    messageId: queryParams.get('messageId') || '',
    blockName: queryParams.get('blockId') || '',
    recId: queryParams.get('recId') || '',
    srcId: queryParams.get('sourceId') || '',
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }

  const maxTotal = useMemo(
    () =>
      state.choicesResponses?.reduce((max, current) => {
        return (current?.total || 0) > (max || 0) ? current?.total || 0 : max
      }, state.choicesResponses[0]?.total),
    [state.choicesResponses]
  )

  const totalResponses = useMemo(
    () =>
      state.choicesResponses?.reduce((accumulator, current) => {
        return accumulator + (current?.total || 0)
      }, 0),
    [state.choicesResponses]
  )

  const [getData, { data, loading: loadingGetData }] = useLazyQuery<GetPollResponsePageQuery, GetPollResponsePageQueryVariables>(
    getPollResponsePage,
    {
      client,
      fetchPolicy: 'network-only',
      variables,
    }
  )

  const [getComments, { data: commentsData }] = useLazyQuery<GetCommentsPollPageQuery, GetCommentsPollPageQueryVariables>(getCommentsPollPage, {
    client,
    fetchPolicy: 'network-only',
    variables: {
      ...variables,
      pageNumber: 0,
      pageSize: 50,
    },
  })

  const [setMutation, { loading: loadingSubmit }] = useMutation<SubmitPollResponseMutation, SubmitPollResponseMutationVariables>(submitPollResponse, {
    client,
    fetchPolicy: 'no-cache',
  })

  const handleSubmit = useCallback(() => {
    setMutation({
      variables: {
        ...variables,
        choice: state.yourChoices?.indexOfChoices?.map((index) => `${index}`),
        comment: state.yourChoices?.comment,
      },
    }).then(() => {
      getData()
      setIsEdit(false)
    })
  }, [queryParams, state])

  const handleLoadNext = useCallback(() => {
    setCurrentPage(currentPage + 1)
    return getComments({
      variables: {
        ...variables,
        pageNumber: currentPage + 1,
        pageSize: 50,
      },
    })
  }, [queryParams, currentPage])

  useEffect(() => {
    setState({ ...data?.getPollResponsePage })
    setIsEdit(!data?.getPollResponsePage?.yourChoices?.nameOfChoices?.length)
    if (!data?.getPollResponsePage?.yourChoices?.indexOfChoices?.length && queryParams.get('selectedChoice')) {
      setState((state) => ({
        ...state,
        yourChoices: {
          ...state.yourChoices,
          indexOfChoices: [Number(queryParams.get('selectedChoice'))],
        },
      }))
    }
  }, [data])

  useEffect(() => {
    getData()
    getComments()
  }, [])

  useEffect(() => {
    if (commentsData?.getCommentsPollPage) {
      setComments((comments) => [...(comments || []), ...(commentsData?.getCommentsPollPage?.pollUserChoiceResponses || [])])
      setTotalComments(commentsData?.getCommentsPollPage?.totalResponse || 0)
    }
  }, [commentsData])

  useLayoutEffect(() => {
    // Scroll to the bottom immediately before the next paint
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  }, [comments])

  const renderCheckboxes = () => {
    return (
      <div className={`${rootClass}__checkboxes`}>
        {state.choicesResponses?.map((choice, index) => {
          return (
            <div
              key={choice?.name}
              className={classNames(`${rootClass}__checkboxes-checkbox`, {
                [`${rootClass}__checkboxes-checkbox--checked`]: state.yourChoices?.indexOfChoices?.includes(index) && !isEdit,
                [`${rootClass}__checkboxes-checkbox--edit`]: isEdit,
              })}
            >
              {!isEdit ? (
                <>
                  {state.yourChoices?.indexOfChoices?.includes(index) && (
                    <Svg name={SvgNames.check} type={SvgType.LARGER_ICON} fill={SvgColor.DARK_BLUE} />
                  )}
                  <Typography text={choice?.name} type={TextType.BODY_TEXT_LARGE} className={`${rootClass}-text`} />
                </>
              ) : (
                <Checkbox
                  name={choice?.name}
                  label={<Typography text={choice?.name} type={TextType.BODY_TEXT_LARGE} />}
                  checked={state.yourChoices?.indexOfChoices?.includes(index)}
                  onChange={() => {
                    if (isEdit) {
                      if (!state.yourChoices?.indexOfChoices?.includes(index)) {
                        setState((state) => ({
                          ...state,
                          yourChoices: {
                            ...state.yourChoices,
                            indexOfChoices: [...(!!state.yourChoices?.indexOfChoices?.length ? state.yourChoices?.indexOfChoices : []), index],
                          },
                        }))
                      } else {
                        setState((state) => {
                          const updatedChoices = !!state.yourChoices?.indexOfChoices?.length ? state.yourChoices?.indexOfChoices : []
                          updatedChoices.splice(updatedChoices.indexOf(index), 1)
                          return {
                            ...state,
                            yourChoices: {
                              ...state.yourChoices,
                              indexOfChoices: [...updatedChoices],
                            },
                          }
                        })
                      }
                    }
                  }}
                />
              )}
            </div>
          )
        })}
      </div>
    )
  }

  const renderRadios = () => {
    return (
      <div className={`${rootClass}__radios`}>
        <RadioGroup verticalLayout>
          {(state.choicesResponses || [])?.map((choice, index) => {
            return (
              <div
                key={choice?.name}
                className={classNames(`${rootClass}__radios-radio`, {
                  [`${rootClass}__radios-radio--checked`]: state.yourChoices?.indexOfChoices?.includes(index) && !isEdit,
                  [`${rootClass}__radios-radio--edit`]: isEdit,
                })}
              >
                {!isEdit ? (
                  <>
                    {state.yourChoices?.indexOfChoices?.includes(index) && (
                      <Svg name={SvgNames.check} type={SvgType.LARGER_ICON} fill={SvgColor.DARK_BLUE} />
                    )}
                    <Typography text={choice?.name} type={TextType.BODY_TEXT_LARGE} className={`${rootClass}-text`} />
                  </>
                ) : (
                  <Radio
                    name={choice?.name}
                    label={choice?.name}
                    labelTypography={{ type: TextType.BODY_TEXT_LARGE }}
                    checked={state.yourChoices?.indexOfChoices?.includes(index)}
                    onChange={() =>
                      setState((state) => ({
                        ...state,
                        yourChoices: {
                          ...state.yourChoices,
                          indexOfChoices: [index],
                        },
                      }))
                    }
                  />
                )}
              </div>
            )
          })}
        </RadioGroup>
      </div>
    )
  }

  const renderComment = (comment?: string, choices?: (string | undefined)[], time?: string, key?: string, isMainComment = true) => {
    const hasComment = !!comment?.trim().length
    const commentText = hasComment ? comment : t('EmailComposer.PollBlockModal.Settings.CommentPrompt.Comment.Empty')

    return (
      <div className={`${rootClass}__comment`} key={key}>
        <div className={`${rootClass}__comment-divider`}></div>
        <div
          className={classNames(`${rootClass}__comment-content`, {
            [`${rootClass}__comment-content-main`]: isMainComment,
            [`${rootClass}__comment-content-main-textOnly`]: !time && !choices,
          })}
        >
          {time && <Typography text={time} type={TextType.BODY_TEXT_SMALL_LIGHT} lineHeight={LineHeight.MEDIUM_SMALL} />}
          {choices && (
            <Typography
              text={choices.join(', ')}
              lineHeight={LineHeight.MEDIUM}
              weight={TextWeight.BOLD}
              className={classNames(`${rootClass}-text`, { ['push-down-half']: !isMainComment })}
            />
          )}
          <Typography
            text={commentText}
            lineHeight={LineHeight.MEDIUM}
            weight={hasComment ? TextWeight.REGULAR : TextWeight.ITALIC}
            className={classNames(`${rootClass}-text`, {
              [`${rootClass}__comment-content-description-empty`]: !hasComment,
              [`${rootClass}__comment-content-description`]: !isMainComment,
            })}
          />
        </div>
      </div>
    )
  }

  const renderOtherResponses = () => {
    return (
      <div className={`${rootClass}__otherResponses`}>
        <Typography
          text={t('EmailComposer.PollBlockResponse.Other')}
          type={TextType.BODY_TEXT_LARGE}
          lineHeight={LineHeight.MEDIUM_LARGE}
          weight={TextWeight.BOLD}
          className={`${rootClass}-text`}
        />
        <div className={`${rootClass}__otherResponses-charts`}>
          {state.choicesResponses?.map((choice) => {
            return (
              <div key={choice?.name}>
                <Typography text={choice?.name} className={`${rootClass}-text`} />
                <div className={`${rootClass}__otherResponses-chart-container`}>
                  <div
                    className={classNames(`${rootClass}__otherResponses-chart`, {
                      [`${rootClass}__otherResponses-chart--empty`]: !choice?.total,
                    })}
                    style={{ width: maxTotal ? `${((choice?.total || 0) / maxTotal) * 100}%` : 0 }}
                  />
                  <Typography
                    text={choice?.total}
                    className={classNames(`${rootClass}-text`, { [`${rootClass}__otherResponses-chart--empty-label`]: !choice?.total })}
                  />
                </div>
              </div>
            )
          })}
        </div>
        <Typography
          text={t('EmailComposer.PollBlockResponse.Total', { total: totalResponses })}
          type={TextType.BODY_TEXT_LARGE}
          lineHeight={LineHeight.MEDIUM_LARGE}
          weight={TextWeight.BOLD}
          className={`${rootClass}-text`}
        />
      </div>
    )
  }

  const renderComments = () => {
    return (
      <div className={`${rootClass}__comments-container`}>
        <LazyLoadOnScrollContainer
          className={`${rootClass}__comments`}
          onLoadMoreData={handleLoadNext}
          allLoaded={(comments?.length || 0) >= (totalComments || 0)}
          saveScrollSessionKey={`${rootClass}__comments-container`}
        >
          {comments?.map((comment) => {
            return renderComment(comment?.comment, comment?.nameOfChoices, comment?.time, comment?.contactName, false)
          })}
        </LazyLoadOnScrollContainer>
      </div>
    )
  }

  return (
    <div className={rootClass} ref={containerRef}>
      {loadingGetData || loadingSubmit ? (
        <Spinner />
      ) : (
        <div className={`${rootClass}__container`}>
          {state.showImage && state.image && <img className={`${rootClass}__image`} src={state.image} alt={'poll'} />}
          <Typography
            className={classNames(`${rootClass}-text`, `${rootClass}__prompt`)}
            text={state.prompt}
            type={TextType.MODAL_HEADER_SMALL}
            lineHeight={LineHeight.MEDIUM_LARGE}
          />
          <div className={`${rootClass}__choiceHeader`}>
            <Typography
              text={t('EmailComposer.PollBlockResponse.Choice.Title')}
              type={TextType.BODY_TEXT_LARGE}
              weight={TextWeight.MEDIUM}
              className={`${rootClass}-text`}
            />
            {!isEdit && (
              <Button
                className={`${rootClass}__choiceHeader-edit`}
                buttonType={ButtonType.WHITE}
                iconPosition={ButtonIconPosition.LEFT}
                onClick={() => setIsEdit(true)}
              >
                <Svg name={SvgNames.pencil} />
                {t('EmailComposer.PollBlockResponse.Edit.Button')}
              </Button>
            )}
          </div>
          {state.allowOnlyOne ? renderRadios() : renderCheckboxes()}
          {state.allowComment && (
            <Typography
              className={classNames(`${rootClass}-text`, `${rootClass}__commentPrompt`)}
              text={state.commentPrompt}
              type={TextType.BODY_TEXT_LARGE}
              weight={TextWeight.MEDIUM}
            />
          )}
          {state.allowComment &&
            (isEdit ? (
              <TextArea
                className={`${rootClass}__textarea`}
                value={state.yourChoices?.comment}
                name={'comment'}
                placeholder={t('EmailComposer.PollBlockResponse.Comment.Placeholder')}
                resize={false}
                onChange={(event) =>
                  setState((state) => ({
                    ...state,
                    yourChoices: {
                      ...state.yourChoices,
                      comment: event.target.value,
                    },
                  }))
                }
              />
            ) : (
              <>
                {renderComment(state.yourChoices?.comment)}
                <Typography
                  className={classNames(`${rootClass}__commentPrompt`, `${rootClass}__infoText-comment`)}
                  text={t('EmailComposer.PollBlockResponse.Info.Text')}
                  type={TextType.BODY_TEXT_LARGE}
                  lineHeight={LineHeight.MEDIUM_LARGE}
                  weight={TextWeight.BOLD}
                />
              </>
            ))}
          {!isEdit && !state.allowComment && (
            <Typography
              className={classNames(`${rootClass}__infoText-comment`, `${rootClass}__infoText`)}
              text={t('EmailComposer.PollBlockResponse.Info.Text')}
              type={TextType.BODY_TEXT_LARGE}
              lineHeight={LineHeight.MEDIUM_LARGE}
              weight={TextWeight.BOLD}
            />
          )}
          {isEdit && (
            <Button
              className={`${rootClass}__submitButton`}
              buttonType={ButtonType.PRIMARY_DARK_BLUE}
              onClick={handleSubmit}
              disabled={!state.yourChoices?.indexOfChoices?.length}
            >
              {t('Submit')}
            </Button>
          )}
        </div>
      )}
      {!isEdit && state.allowRecipientResponseVisibility && !loadingGetData && !loadingSubmit && (
        <>
          {renderOtherResponses()}
          {renderComments()}
        </>
      )}
    </div>
  )
}

export default PollBlockResponse
