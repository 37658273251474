import { gql } from '@apollo/client'

export default gql`
  query segmentSources($searchTerm: String, $offset: String, $segmentSourceTypeName: String, $limit: String, $connectedCrmName: String) {
    segmentSources(
      searchTerm: $searchTerm
      offset: $offset
      segmentSourceTypeName: $segmentSourceTypeName
      limit: $limit
      connectedCrmName: $connectedCrmName
    ) {
      hasMoreData
      identifier
      label
    }
  }
`
