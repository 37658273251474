import { ListPageTableActionCustomProps } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { ProgramAsset } from '@src/pages/listingPages/AutomatedPrograms/components/CreateProgramTemplateModal/CreateProgramTemplateModal'

export enum AutomatedProgramTemplateCustomModals {
  CREATE_PROGRAM = 'createProgram',
  CREATE_PROGRAM_ERROR = 'createProgramError',
  CREATE_TEMPLATE = 'createTemplate',
  CREATE_TEMPLATE_SUCCESS = 'createTemplateSuccess',
  CREATE_TEMPLATE_ERROR = 'createTemplateError',
}

export enum AutomatedProgramTemplatesCustomTableActions {
  CREATE_PROGRAM = 'createProgram',
}

export const rowActionCustomProps: ListPageTableActionCustomProps[] = [
  { name: AutomatedProgramTemplatesCustomTableActions.CREATE_PROGRAM, position: 0, hasTopSection: false, isInDropdown: false },
  { name: 'ADD_TO_FAVORITES', position: 1, hasTopSection: false, isInDropdown: false },
  { name: 'SHARE_TO_ACCOUNTS', position: 2, hasTopSection: false, isInDropdown: true },
  { name: 'SHARE_TO_CATALOG', position: 3, hasTopSection: false, isInDropdown: true },
  { name: 'DELETE', position: 4, hasTopSection: false, isInDropdown: true },
  { name: 'MOVE_TO_FOLDER', position: 5, hasTopSection: true, isInDropdown: true },
  { name: 'REMOVE_FROM_FOLDER', position: 6, hasTopSection: false, isInDropdown: true },
]

export const headerActionCustomProps: ListPageTableActionCustomProps[] = [
  { name: 'ADD_TO_FAVORITES', position: 0, hasTopSection: false, isInDropdown: false },
  { name: 'MANAGE_TAGS', position: 1, hasTopSection: false, isInDropdown: false },
  { name: 'MOVE_TO_FOLDER', position: 2, hasTopSection: false, isInDropdown: false },
  { name: 'REMOVE_FROM_FOLDER', position: 3, hasTopSection: false, isInDropdown: false },
  { name: 'SHARE_TO_CATALOG', position: 4, hasTopSection: false, isInDropdown: false },
  { name: 'DELETE', position: 5, hasTopSection: false, isInDropdown: true },
]

export interface ProgramTemplateListing {
  id: string
  externalId: string
  m_title: string
  m_description: string
  m_creationTime: number
  m_lastEditedBy: string
  m_modifiedTime: number
  m_descriptions: ProgramAsset[]
}
