import { gql } from '@apollo/client'

export default gql`
  mutation createAccount(
    $parentAccountId: Long!
    $zipCode: String
    $country: String
    $city: String
    $name: String
    $street1: String
    $activeContacts: Int!
    $state: String
    $street2: String
    $userId: Long!
  ) {
    createAccount(
      parentAccountId: $parentAccountId
      zipCode: $zipCode
      country: $country
      city: $city
      name: $name
      street1: $street1
      activeContacts: $activeContacts
      state: $state
      street2: $street2
      userId: $userId
    ) {
      failure {
        id
        name
        reason
      }
      status
      success {
        id
        name
      }
    }
  }
`
