import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import classNames from 'classnames'

import {
  ColumnMergeType,
  contactPreferencesOptions,
  ContactStatus,
  Delimiter,
  FirstLineOption,
  Quote,
} from '@complex/ImportContacts/ImportContacts.constants'
import ImportOptionsScreen, { ImportOptionsScreenProps } from '@complex/ImportContacts/ImportOptionsScreen/ImportOptionsScreen'
import ImportLog from '@complex/ImportLog/ImportLog'
import ListPickerModal from '@complex/ListPickerModal/ListPickerModal'
import { ListPickerModal as ListPickerModalV2 } from '@complex/ListPickerModalV2/ListPickerModal'
import Button, { ButtonType } from '@components/Button'
import { ButtonWeight } from '@components/Button/Button'
import ConfirmationModal from '@components/ConfirmationModal'
import { YesNo } from '@components/ConfirmationModal/ConfirmationModal'
import Container from '@components/Container'
import DropZone from '@components/DropZone/DropZone'
import { renderLoader } from '@components/Loader/Loader'
import PageContainer from '@components/PageContainer'
import PageHeader from '@components/PageHeader'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import ProgressBar, { ProgressBarStep } from '@components/ProgressBar/ProgressBar'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import StatusToast from '@components/StatusToast/StatusToast'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Typography, { LineHeight, TextAlign, TextType, TextWeight } from '@components/Typography/Typography'
import UploadProgress, { UploadProgressProps } from '@components/UploadProgress/UploadProgress'
import { FormatFile, rootContext, useTranslation } from '@const/globals'
import { List } from '@interface/foldersLists/List'
import ImportInfo, { ImportType } from '@src/pages/importcontacts/components/ImportInfo/ImportInfo'
import ImportSummary from '@src/pages/importcontacts/components/ImportSummary/ImportSummary'
import MappingScreenContainer from '@src/pages/importcontacts/components/MappingScreen/MappingScreenContainer'
import { ImportContactsContext } from '@src/pages/importcontacts/context/ImportContactsContext'
import { useAddContactsFromCRMClassicRequests } from '@src/pages/importcontacts/GraphQL/ImportContactsRequests.classic.graphQL'
import { getImportContactsSteps, IMPORT_CONTACTS_URL } from '@src/pages/importcontacts/utils/ImportContactsContainerUtils'
import { ImportContactsSteps } from '@src/pages/ImportContactsV2/utils/ImportContactsV2.constants'
import { isAValidSegmentNameUtils } from '@src/pages/ImportContactsV2/utils/ImportContactsV2.utils'
import { useAccountSettings, useGetFeatureFlag } from '@utils/account/account.utils'

import './ImportContacts.css'

type Props = {
  dataTest?: string
  loading?: boolean
  currentStep: ProgressBarStep
}

const rootClass = 'import-contacts'

const uploadProgressProps: UploadProgressProps = {
  className: rootClass,
  progress: 0,
  headline: 'File Upload in Progress',
  text: "Great work mapping your Contacts! Please keep this window open while we finish uploading your file. Next, we'll import your Contacts and you'll be on your way.",
}

const ImportContacts: FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { dataTest = rootClass, loading, currentStep } = props
  const {
    values: {
      mappingPreview,
      previewHeader,
      statusToast: { statusMessage, status, showStatusToast },
      fileSelected,
      indexRef,
      validationTrigger,
      delimiter,
      hasHeaders,
      quoteChar,
      uploadCompleted,
      doImportList,
      error: { displayError, errorTitle, errorMessage },
      fileUploading,
      showProgress,
      uploadingProgress,
      columnCount,
      importingList,
      importStarted,
      creatingSegmentLoading,
      selectSegmentChecked,
      validSegmentName,
      listName,
      validateAllTrigger,
      visibleFields,
      inputOnBlurTriggered,
      previewRecords,
      previewIndex,
      steps,
      showAdvancedOptions,
      contactPreference,
      mergeRule,
      selectOptions,
      segmentSelectedOption,
    },
    startUpload,
    handleOnSectionChange,
    removeFormData,
    onFileSelected,
    validateColumn,
    closeModal,
    updatePreviewData,
    doImport,
    importContacts,
    closeSummary,
    onSubmitLists,
    matchFieldsWithHeaders,
    closeStatusToast,
    resetState,
    createSegment,
    getTopDirectSelectSegments,
    reviewSummary,
    update,
  } = useContext(ImportContactsContext)
  const initialized = useRef(false)
  const [showListPicker, setShowListPicker] = useState<boolean>(false)
  const [showHistory, setShowHistory] = useState(false)
  const hasShowActOnContactsTab = useGetFeatureFlag('hasShowActOnContactsTab')
  const { userEmail, exclusivelyActOnContacts, userAllowedToCreate } = useAccountSettings()

  const showMappingScreen = (uploadCompleted || fileUploading || importingList) && !showProgress && mappingPreview && !importStarted
  const history = useHistory()
  const altUpload = {
    text: 'import an existing list',
    onClick: () => {
      setShowListPicker(true)
    },
  }

  const { isAValidSegmentNameRequest } = useAddContactsFromCRMClassicRequests()
  const isAValidSegmentName = async (segmentName: string) => await isAValidSegmentNameUtils(isAValidSegmentNameRequest, segmentName, update)

  const onChangeWithFirstRowsUpdate = (field: string, value: FirstLineOption | Delimiter | Quote) => {
    switch (field) {
      case 'firstLineContains':
        update({ hasHeaders: value === FirstLineOption.NAMES })
        break
      case 'fieldsSeparator':
        update({ delimiter: value as Delimiter })
        break
      case 'fieldQuotes':
        update({ quoteChar: value as Quote })
        break
    }
  }

  const onMergeRuleChange = (mergeRule: ColumnMergeType) => {
    update({
      mergeRule,
      ...(mappingPreview.length > 0 ? { mappingPreview: mappingPreview.map((mapping) => ({ ...mapping, merge: mergeRule })) } : {}),
    })
  }

  const onSegmentSelectionChange = useCallback(
    (newSelectedOption?: SelectV2SingleOption) => {
      if (newSelectedOption) {
        update({ validSegmentName: true, segmentSelectedOption: newSelectedOption })
      } else {
        update({ segmentSelectedOption: undefined })
      }
    },
    [update]
  )

  const onContactPreferenceChange = (option: SelectV2SingleOption | undefined) => {
    update({ contactPreference: option?.value !== '' ? (option?.value as ContactStatus) : undefined })
  }

  const onImportOptionsNextClick = () => handleOnSectionChange(ImportContactsSteps.MAP_FIELDS, true)

  const onAddToSegmentChange = (checked: boolean) => {
    update({ selectSegmentChecked: checked, segmentSelectedOption: undefined })
  }

  const onShowAdvancedOptionsChange = (showAdvancedOptions: boolean) => update({ showAdvancedOptions })

  const importOptionsProps: ImportOptionsScreenProps = {
    isFileSelected: !!fileSelected || importingList,
    addToSegment: selectSegmentChecked,
    segmentSelectedOption,
    validSegmentName,
    isAValidSegmentName,
    createSegment,
    segmentOptions: selectOptions,
    getSegmentOptions: getTopDirectSelectSegments,
    externalIdMatch: undefined,
    loadingMappingScreen: false,
    hasAdvancedOptions: !importingList,
    contactPreference: contactPreferencesOptions.find((option) => option.value === contactPreference) || contactPreferencesOptions[0],
    fieldQuotes: quoteChar,
    firstLineContains: hasHeaders ? FirstLineOption.NAMES : FirstLineOption.VALUES,
    previewHeadersOptions: [],
    fieldsSeparator: delimiter,
    mergeRule,
    showAdvancedOptions,
    onChangeWithFirstRowsUpdate,
    onMergeRuleChange,
    onContactPreferenceChange,
    onSegmentSelectionChange,
    onNextClick: onImportOptionsNextClick,
    onAddToSegmentChange,
    onShowAdvancedOptionsChange,
  }

  const renderListSelection = () =>
    hasShowActOnContactsTab ? (
      <ListPickerModalV2
        isOpen={true}
        closeModal={() => setShowListPicker(false)}
        submitLists={(lists: List[]) => {
          onSubmitLists(lists)
          setShowListPicker(false)
        }}
        submitButtonText={t('Map Fields')}
        submitDisabledWithoutSelection
        multiSelect={false}
        hideUCL
        hideLegacyListToggle
      />
    ) : (
      <ListPickerModal
        isOpen={true}
        closeModal={() => setShowListPicker(false)}
        submitLists={(lists: List[]) => {
          onSubmitLists(lists)
          setShowListPicker(false)
        }}
        submitButtonText={t('Map Fields')}
        submitDisabledWithoutSelection
        multiSelect={false}
        hasQueryBasedSegmentWarning
        importUnifiedCompatibleLists
      />
    )

  useEffect(() => {
    if (fileSelected) {
      update({
        steps: getImportContactsSteps(t, false),
        importingList: false,
      })
      updatePreviewData(fileSelected)
    } else {
      resetState()
    }
  }, [fileSelected, t])

  useEffect(() => {
    if (indexRef >= 0) {
      validateColumn(indexRef)
    }
  }, [validationTrigger])

  useEffect(() => {
    if (initialized.current && fileSelected) {
      updatePreviewData(fileSelected)
    } else {
      initialized.current = true
    }
  }, [delimiter, hasHeaders, quoteChar])

  useEffect(() => {
    if (uploadCompleted || doImportList) {
      importContacts()
    }
  }, [uploadCompleted, doImportList])

  useEffect(() => {
    if (hasHeaders && showMappingScreen) {
      matchFieldsWithHeaders()
    }
  }, [previewHeader])

  useEffect(() => {
    if (!showMappingScreen && location.pathname.includes('/mapping')) {
      history.replace(`${rootContext}${IMPORT_CONTACTS_URL}`, {})
    }
  }, [])

  return (
    <PageContainer className={rootClass} dataTest={`${dataTest}-container`}>
      {loading && renderLoader()}
      {showStatusToast && <StatusToast status={status} message={statusMessage} closeStatus={closeStatusToast} />}
      {showListPicker && renderListSelection()}
      {showHistory && <ImportLog isOpen={showHistory} onClose={() => setShowHistory(false)} />}
      {displayError && (
        <ConfirmationModal
          dataTest={dataTest}
          isOpen={displayError}
          title={t(errorTitle)}
          body={errorMessage}
          okButtonText={t('Got it')}
          closeModal={closeModal}
        />
      )}
      {currentStep?.key === ImportContactsSteps.REVIEW && (
        <ConfirmationModal
          className={`${rootClass}__import-summary-modal`}
          isOpen
          isYesNo
          disabledPrimary={selectSegmentChecked ? !validSegmentName || !segmentSelectedOption?.value : false}
          onAnswer={(answer) => (answer === YesNo.YES ? doImport() : closeSummary())}
          title={t('Import summary')}
          body={<ImportSummary />}
          hideFooter={creatingSegmentLoading}
          yesButtonText={'Import Contacts'}
          noButtonText={'Cancel'}
        />
      )}
      <PositionContainer>
        <PageHeader primaryText={t('Import Contacts')} leftContent dataTest={rootClass}>
          <Button
            buttonType={ButtonType.TEXT_TEAL}
            weight={ButtonWeight.MEDIUM}
            className={`${rootClass}__header-import-log`}
            onClick={() => setShowHistory(true)}
          >
            <Svg name={SvgNames.resources} type={SvgType.LARGER_ICON} />
            {t('View Import Log')}
          </Button>
        </PageHeader>
        <ProgressBar className={`${rootClass}__progress-bar`} steps={steps} onClick={(step) => handleOnSectionChange(step.key)} />
        {currentStep?.key === ImportContactsSteps.SELECT_FILE && (
          <Container>
            <div className={`${rootClass}__upload-bullet-wrapper`}>
              <div>
                <Typography text={t('Incoming Field Names')} weight={TextWeight.BOLD} textAlign={TextAlign.LEFT} />
                <Typography
                  text={t('For easy mapping, enter your field names in the first row of your file.')}
                  type={TextType.BODY_TEXT_SMALL}
                  textAlign={TextAlign.LEFT}
                  lineHeight={LineHeight.MEDIUM}
                  inline
                />
              </div>
              <div>
                <Typography text={t('Field Separation')} weight={TextWeight.BOLD} textAlign={TextAlign.LEFT} />
                <Typography
                  text={t('Separate each field with commas, tabs, or semi-colons.')}
                  type={TextType.BODY_TEXT_SMALL}
                  textAlign={TextAlign.LEFT}
                  lineHeight={LineHeight.MEDIUM}
                  inline
                />
              </div>
              <div>
                <Typography text={t('Field Values')} weight={TextWeight.BOLD} textAlign={TextAlign.LEFT} />
                <Typography
                  text={t('To separate text values that contain a delimiter, use single or double quotes.')}
                  type={TextType.BODY_TEXT_SMALL}
                  textAlign={TextAlign.LEFT}
                  lineHeight={LineHeight.MEDIUM}
                  inline
                />
              </div>
            </div>
            {!fileSelected ? (
              <div className={`${rootClass}__drop-zone-wrapper`}>
                <DropZone
                  className={rootClass}
                  onFileSelected={onFileSelected}
                  accept={'.csv'}
                  selectFileText={t('select your CSV file')}
                  disabled={!userAllowedToCreate}
                  disabledTooltip={t('Ask your administrator for permission to do this')}
                  {...(exclusivelyActOnContacts ? {} : { altUpload })}
                />
              </div>
            ) : (
              <div className={`${rootClass}__selected-file-wrapper`}>
                <div className={`${rootClass}__selected-file-rectangle`}>
                  <Svg className={`${rootClass}__selected-file-icon`} name={SvgNames.page} />
                  <div className={`${rootClass}__selected-file-details`}>
                    <div className={`${rootClass}__selected-file-name`}>
                      <Typography
                        className={`${rootClass}__file-name`}
                        text={fileSelected.name}
                        inline={true}
                        type={TextType.BODY_TEXT}
                        weight={TextWeight.BOLD}
                      />
                      <span>
                        <Button buttonType={ButtonType.ICON} className={`${rootClass}__remove-file-icon-wrapper`} onClick={removeFormData} noPadding>
                          <Svg className={`${rootClass}__remove-file-icon`} name={SvgNames.close} type={SvgType.SMALLER_ICON} />
                        </Button>
                      </span>
                    </div>
                    <div className={`${rootClass}__file-details`}>
                      <Typography text={FormatFile.readableBytes(fileSelected.size)} inline={true} type={TextType.BODY_TEXT_LIGHT} />
                      <span className={`${rootClass}__middle-dot`} />
                      <Typography text={`${columnCount}  ${t('columns')}`} inline={true} type={TextType.BODY_TEXT_LIGHT} />
                    </div>
                  </div>
                </div>
                <div className={`${rootClass}__button-wrapper`}>
                  <Button dataTest={`${dataTest}-next-button`} buttonType={ButtonType.PRIMARY} onClick={startUpload}>
                    {t('Next')}
                  </Button>
                </div>
              </div>
            )}
          </Container>
        )}

        {currentStep?.key === ImportContactsSteps.IMPORT_OPTIONS && <ImportOptionsScreen {...importOptionsProps} />}
        {(currentStep?.key === ImportContactsSteps.MAP_FIELDS || currentStep?.key === ImportContactsSteps.REVIEW) && (
          <div>
            <MappingScreenContainer
              className={classNames(rootClass, `${rootClass}__left-container`)}
              update={update}
              fileSelected={fileSelected}
              hasHeaders={hasHeaders}
              indexRef={indexRef}
              inputOnBlurTriggered={inputOnBlurTriggered}
              listName={listName}
              mappingPreview={mappingPreview}
              previewHeader={previewHeader}
              previewIndex={previewIndex}
              previewRecords={previewRecords}
              validateAllTrigger={validateAllTrigger}
              validationTrigger={validationTrigger}
              visibleFields={visibleFields}
            />
            <div className={`${rootClass}__button-wrapper`}>
              <Button buttonType={ButtonType.PRIMARY} onClick={reviewSummary}>
                {t('Next')}
              </Button>
            </div>
          </div>
        )}
        {currentStep?.key === ImportContactsSteps.IMPORT_IN_PROGRESS && (
          <ImportInfo importAnotherClick={resetState} userEmail={userEmail} type={uploadCompleted ? ImportType.FILE : ImportType.LIST} />
        )}
        {currentStep?.key === ImportContactsSteps.UPLOAD_IN_PROGRESS && (
          <Container>
            <div className={`${rootClass}__upload-progress-wrapper`}>
              <UploadProgress {...uploadProgressProps} progress={uploadingProgress} />
            </div>
          </Container>
        )}
      </PositionContainer>
    </PageContainer>
  )
}

export default ImportContacts
