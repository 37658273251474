import { ApolloClient } from '@apollo/client'
import unifiedListFieldMappings from '@graphql/microservices/list/getUnifiedListFieldMappings'
import scoreSheets from '@graphql/microservices/list/scoreSheets'
import standardFields from '@graphql/microservices/list/standardFields'
import {
  ScoreSheetsQuery,
  ScoreSheetsQueryVariables,
  StandardFieldsQuery,
  StandardFieldsQueryVariables,
  GetUnifiedListFieldMappingsQuery,
  GetUnifiedListFieldMappingsQueryVariables,
  ScoreSheet,
  StandardField,
  UnifiedListFieldMapping,
} from '@graphql/types/microservice/list-types'
import { NonCrmContactsData } from '@src/pages/datamanagement/context/DataManagementContext'
import { FieldValidationState } from '@utils/crm.utils'

export const getScoreSheets = (client: ApolloClient<any>) => {
  return client
    .query<ScoreSheetsQuery, ScoreSheetsQueryVariables>({
      query: scoreSheets,
      fetchPolicy: 'network-only',
    })
    .then(({ data }) => data as ScoreSheet[])
}

export const getStandardFields = (client: ApolloClient<any>) => {
  return client
    .query<StandardFieldsQuery, StandardFieldsQueryVariables>({
      query: standardFields,
      fetchPolicy: 'network-only',
    })
    .then(({ data: { standardFields } }) =>
      [...(standardFields as StandardField[])]
        .sort((a: any, b: any) => (a.label < b.label ? -1 : 1))
        .reduce((map, field) => map.set(field?.key || '', field), new Map<string, StandardField>())
    )
}

const initializeData = (mappings: UnifiedListFieldMapping[]) => {
  const initialReducerData: NonCrmContactsData = { customRows: [], standardRows: [], index: 0 }

  return mappings.reduce((acc, mapping) => {
    const row = { id: acc.index, mapping: mapping, status: FieldValidationState.OK }
    const field: keyof NonCrmContactsData = mapping.standardFieldKey ? 'standardRows' : 'customRows'
    return { ...acc, [field]: [...acc[field], row], index: acc.index + 1 }
  }, initialReducerData)
}

export const isMappingRequired = (mapping: UnifiedListFieldMapping, standardFieldsMap: Map<string, StandardField>) =>
  standardFieldsMap.get(mapping.standardFieldKey || '')?.required

const buildMappings = (mappings: UnifiedListFieldMapping[], standardFieldsMap: Map<string, StandardField>) =>
  mappings
    .filter((mapping) => !isMappingRequired(mapping, standardFieldsMap) || mapping.standardFieldKey === 'email')
    .sort((a, b) => (isMappingRequired(a, standardFieldsMap) || a.columnIndex < b.columnIndex ? -1 : 1))

export const getUnifiedListFieldMappings = (
  standardFieldsMap: Map<string, StandardField>,
  client: ApolloClient<any>
): Promise<{ mappings: UnifiedListFieldMapping[]; nonCrmContactsData: NonCrmContactsData } | undefined> => {
  return client
    .query<GetUnifiedListFieldMappingsQuery, GetUnifiedListFieldMappingsQueryVariables>({
      query: unifiedListFieldMappings,
      fetchPolicy: 'network-only',
    })
    .then(({ data: { unifiedListFieldMappings } }) => {
      if (unifiedListFieldMappings) {
        const mappings = buildMappings(unifiedListFieldMappings as UnifiedListFieldMapping[], standardFieldsMap)
        const nonCrmContactsData = initializeData(mappings)
        return { mappings, nonCrmContactsData }
      }
    })
}
