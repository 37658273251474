import React, { FC } from 'react'

import { IllustrationModal } from '@components/IllustrationModal/IllustrationModal'
import { LinkTextButton } from '@components/LinkTextButton/LinkTextButton'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Typography, { TextAlign, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { TEST_EMAIL_TIPS_TO_KNOW_URL } from '@src/pages/EmailComposer/EmailModals/components/SendTestEmailModal/constants/SendTestEmailModal.constants'

import './SendTestConfirmationModal.css'

export enum SendTestConfirmationModalStatus {
  ERROR = 'error',
  SUCCESS = 'success',
}

interface SendTestConfirmationModalProps {
  onResend: VoidFunction
  onCancel: VoidFunction
  status: SendTestConfirmationModalStatus
  dataTest?: string
}

const rootClass = 'send-test-confirmation-modal'

const SendTestConfirmationModal: FC<SendTestConfirmationModalProps> = ({ onResend, onCancel, status, dataTest = rootClass }) => {
  const { t } = useTranslation()
  const isError = status === 'error'

  return (
    <IllustrationModal
      illustration={StaticImageNames[isError ? 'errorCircleMedium' : 'successCircle']}
      header={
        <Typography
          text={t('Send.Test.Email.Confirmation.Title', { context: status })}
          className={`${rootClass}__title`}
          dataTest={`${dataTest}-title`}
          weight={TextWeight.MEDIUM}
          type={TextType.MODAL_HEADLINE_CONFIRMATION}
        />
      }
      bodyText={
        <Typography
          text="Send.Test.Email.Confirmation.Text"
          type={TextType.BODY_TEXT_LIGHT}
          textAlign={TextAlign.CENTER}
          tagComponents={{
            Link: <LinkTextButton link={TEST_EMAIL_TIPS_TO_KNOW_URL} hideIconLeft inline />,
          }}
          inline
          values={{ context: status }}
          className={`${rootClass}__text`}
          dataTest={`${dataTest}-text`}
        />
      }
      confirmButton={{
        title: isError ? 'Resend' : 'Ok',
        onClick: isError ? onResend : onCancel,
      }}
      closeButton={
        isError
          ? {
              title: 'Cancel',
              onClick: onCancel,
            }
          : undefined
      }
      dataTest={dataTest}
    />
  )
}

export default SendTestConfirmationModal
