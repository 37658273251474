import React from 'react'

import { TFunction } from 'i18next'

import { ListingPageSubType, RenderCustomFiltersParams } from '@complex/ListingPage/Context/ListingPageCommon.context'
import Checkbox from '@components/Checkbox'
import CheckboxGroup from '@components/CheckboxGroup/CheckboxGroup'
import Radio from '@components/Radio'
import RadioGroup from '@components/RadioGroup'
import { SvgNames } from '@components/Svg'
import { FilterTypes, sentByMarketingFilter, sentByMeFilter, sentBySalesFilter } from '@utils/filter'

const rootClass = 'email-picker-modal'

export const emailSentPickerSubTypes: ListingPageSubType[] = [{ label: 'Hide messages to specific people', name: 'hide-messages-to-specific-people' }]

export const renderSentCustomFilters = (
  { activeSubTypes, activeFilter, menuActions, filterCounts, renderCustomFilterWithCount }: RenderCustomFiltersParams,
  t: TFunction
) => [
  {
    header: t('Filter by sender'),
    isOpen: true,
    icon: SvgNames.toggles,
    dataTest: 'menu-items',
    content: (
      <RadioGroup className={`${rootClass}__custom-filters`} verticalLayout>
        {renderCustomFilterWithCount(
          <Radio
            label={t(FilterTypes.SENT_BY_MARKETING)}
            checked={activeFilter?.name === sentByMarketingFilter.name}
            onChange={() => menuActions.clickCustomFilter(sentByMarketingFilter)}
            onClick={() => menuActions.clickCustomFilter(sentByMarketingFilter)}
            dataTest={`${rootClass}-marketing-filter`}
            boldOnChecked
          />,
          filterCounts[FilterTypes.SENT_BY_MARKETING],
          FilterTypes.SENT_BY_MARKETING
        )}
        {renderCustomFilterWithCount(
          <Radio
            label={t(FilterTypes.SENT_BY_SALES)}
            checked={activeFilter?.name === sentBySalesFilter.name}
            onChange={() => menuActions.clickCustomFilter(sentBySalesFilter)}
            onClick={() => menuActions.clickCustomFilter(sentBySalesFilter)}
            dataTest={`${rootClass}-sales-filter`}
            boldOnChecked
          />,
          filterCounts[FilterTypes.SENT_BY_SALES],
          FilterTypes.SENT_BY_SALES
        )}
        {renderCustomFilterWithCount(
          <Radio
            label={t(FilterTypes.SENT_BY_ME)}
            checked={activeFilter?.name === sentByMeFilter.name}
            onChange={() => menuActions.clickCustomFilter(sentByMeFilter)}
            onClick={() => menuActions.clickCustomFilter(sentByMeFilter)}
            dataTest={`${rootClass}-me-filter`}
            boldOnChecked
          />,
          filterCounts[FilterTypes.SENT_BY_ME],
          FilterTypes.SENT_BY_ME
        )}
      </RadioGroup>
    ),
  },
  {
    header: t('More filters'),
    isOpen: true,
    icon: SvgNames.toggles,
    dataTest: 'menu-items',
    content: (
      <CheckboxGroup className={`listing-page__custom-filters`} verticalLayout>
        {emailSentPickerSubTypes.map((subType) =>
          renderCustomFilterWithCount(
            <Checkbox
              key={subType.name}
              label={subType.label}
              checked={activeSubTypes.includes(subType.name)}
              onChange={() => menuActions.clickSubType(subType.name)}
              dataTest={`${rootClass}-${subType.name}`}
            />,
            filterCounts[subType.name],
            subType.name
          )
        )}
      </CheckboxGroup>
    ),
  },
]
