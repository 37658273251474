import { gql } from '@apollo/client'

export default gql`
  mutation deleteContacts($uuids: [String], $recordIds: [Int]) {
    deleteContacts(uuids: $uuids, recordIds: $recordIds) {
      action
      id
      message
    }
  }
`
