import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

import classNames from 'classnames'

import { ListPickerModal } from '@complex/ListPickerModalV2/ListPickerModal'
import Container from '@components/Container/Container'
import { SvgNames } from '@components/Svg/index'
import { TableV2 } from '@components/TableV2/TableV2'
import { RowAction } from '@components/TableV2/tableV2TS/interfaces'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import ToggleSection from '@components/ToggleSection/ToggleSection'
import Toggletip, { ToggletipSide } from '@components/Toggletip/Toggletip'
import Typography, { TextType } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import { List } from '@interface/foldersLists/List'
import SendDetailsSectionSplitHeader from '@src/pages/EmailComposer/components/SendDetails/components/SendDetailsSectionSplitHeader/SendDetailsSectionSplitHeader'
import { getSuppressionsColumns } from '@src/pages/EmailComposer/components/SendDetails/components/SendDetailsSuppressions/SendDetailsSuppressions.utils'
import { Row } from '@tanstack/react-table'
import { ComposerHistoryState, EmailComposerContext, SendtoList, ValidationSectionType } from '@utils/composer/context/EmailComposer.context'

import './SendDetailsSuppressions.css'

interface SendDetailsSuppressionsProps {
  className?: string
  dataTest?: string
}

interface SuppresionsState {
  currentPage: number
  pageSize: number
  lists: SendtoList[]
  currentData: SendtoList[]
  showListPicker: boolean
}

const defaultSuppresionsState: SuppresionsState = {
  currentPage: 0,
  pageSize: 10,
  lists: [],
  currentData: [],
  showListPicker: false,
}

const BOUNCES_LEARN_MORE_URL = 'https://connect.act-on.com/hc/en-us/articles/360023757294-Hard-Bounces-Soft-Bounces-and-Suppressions'

const rootClass = 'send-details-suppressions'

const SendDetailsSuppressions: FC<SendDetailsSuppressionsProps> = (props: SendDetailsSuppressionsProps) => {
  const { className = '' } = props
  const { t } = useTranslation()
  const {
    api: { update },
    values: {
      hasFatigueRules,
      message: { suppress_lists, suppress_dups, overrideFatigueRules, isTransactional, assetReportRecipientDetails },
      validations: { recipients: recipientsValidations },
    },
  } = useContext(EmailComposerContext)
  const history = useHistory<ComposerHistoryState>()
  const showValidationErrors = history.location.state?.fromValidationSection === ValidationSectionType.RECIPIENTS

  const [state, setState] = useState<SuppresionsState>(defaultSuppresionsState)

  const getCurrentData = useCallback((pageIndex: number, pageSize: number, lists: SendtoList[]) => {
    const start = pageIndex * pageSize
    return lists?.slice(start, start + pageSize) ?? []
  }, [])

  useEffect(() => {
    if (suppress_lists?.length) {
      setState((state) => ({
        ...state,
        lists: suppress_lists,
        currentData: suppress_lists.length > 10 ? getCurrentData(state.currentPage, state.pageSize, suppress_lists) : suppress_lists,
      }))
    }
    update({ message: { suppress_dups: isTransactional ? !isTransactional : suppress_dups } })
  }, [suppress_lists, getCurrentData, isTransactional])

  const listCount = state.lists?.reduce((acc, curr) => acc + curr.size, 0) ?? 0

  const removeRow = useCallback(
    (rowId: string) => {
      const updatedLists = suppress_lists?.filter((lst) => lst.srcId !== rowId)
      setState((state) => {
        const currentData = getCurrentData(state.currentPage, state.pageSize, updatedLists ?? [])
        return { ...state, currentData }
      })
      update({ message: { suppress_lists: updatedLists } })
    },
    [update, suppress_lists, getCurrentData]
  )

  const rowActions: RowAction<SendtoList>[] = useMemo(
    () => [
      {
        label: t('Remove'),
        icon: SvgNames.deleteRounded,
        onClick: (row: Row<SendtoList>) => removeRow(row.original.srcId),
      },
    ],
    [removeRow, t]
  )

  const fetchData = useCallback(
    (pageIndex: number, pageSize: number) => {
      setState((state) => {
        const currentData = getCurrentData(pageIndex, pageSize, state.lists)
        return { ...state, currentPage: pageIndex, pageSize, currentData }
      })
    },
    [getCurrentData]
  )

  const onUpdateLists = useCallback(
    (lists: List[]) => {
      const updatedLists: SendtoList[] = lists.map((lst) => ({
        srcId: lst.id,
        baseId: lst.baseId,
        isList: true,
        srcName: lst.name,
        size: lst.size ?? 0,
      }))
      setState((state) => {
        const currentData = getCurrentData(0, state.pageSize, updatedLists ?? [])
        return { ...state, currentData, showListPicker: false, lists: updatedLists }
      })
      update({ message: { suppress_lists: updatedLists } })
    },
    [update, getCurrentData]
  )

  const columns = useMemo(
    () => getSuppressionsColumns(showValidationErrors ? recipientsValidations : undefined),
    [showValidationErrors, recipientsValidations]
  )

  const suppressionTable = useMemo(
    () =>
      !state.currentData.length ? null : (
        <TableV2
          data={state.currentData}
          columns={columns}
          withoutBorders
          rowActions={rowActions}
          enableSorting
          enablePaginate={(state.lists ?? []).length > 10}
          paginationState={{
            pageIndex: state.currentPage,
            pageSize: state.pageSize,
            controlledPageCount: Math.ceil((state.lists ?? []).length / state.pageSize),
          }}
          fetchData={fetchData}
          styles={{ minWidth: 'auto' }}
        />
      ),
    [state, columns, rowActions, fetchData]
  )

  const suppressFatigueText = (
    <>
      <Typography text={t('SendDetails.Suppression.SuppressFatigue.Text')} type={TextType.BODY_TEXT_LIGHT} inline />{' '}
      <TextLink text={t('SendDetails.Suppression.SuppressFatigue.TextLink')} size={TextLinkSize.LARGE} link={`${rootContext}/contactSettings`} />
    </>
  )

  return (
    <>
      {state.showListPicker && !assetReportRecipientDetails && (
        <ListPickerModal
          isOpen={state.showListPicker}
          defaultSelectedLists={suppress_lists?.map((lst) => lst.srcId)}
          closeModal={() => setState({ ...state, showListPicker: false })}
          submitLists={onUpdateLists}
          submitButtonText={t('SendDetails.Suppression.ListPicker.Submit')}
          submitDisabledOnSelect
          submitDisabledWithoutSelection
        />
      )}
      <Container className={classNames(rootClass, className)} dataTest={`${rootClass}-container`}>
        {assetReportRecipientDetails ? (
          <SendDetailsSectionSplitHeader
            showSelectContactsButton={false}
            title={'SendDetails.Suppression.Title'}
            count={listCount}
            onClick={() => setState({ ...state, showListPicker: true })}
            className={`${rootClass}__header-variant`}
          />
        ) : (
          <>
            <SendDetailsSectionSplitHeader
              showSelectContactsButton
              title={'SendDetails.Suppression.Title'}
              subText={'SendDetails.Suppression.Sub'}
              count={listCount}
              onClick={() => setState({ ...state, showListPicker: true })}
            />
            <div className={`${rootClass}__table`}>{suppressionTable}</div>
            <ToggleSection
              withTooltip={isTransactional}
              disabled={isTransactional}
              title={t('SendDetails.Suppression.SuppressDuplicates.Title')}
              infoText={t('SendDetails.Suppression.SuppressDuplicates.Text')}
              isToggleOn={suppress_dups && !isTransactional}
              onToggle={(isOn) => update({ message: { suppress_dups: isOn } })}
              className={`${rootClass}__toggle`}
              tooltipText={t('SendDetails.Suppression.SuppressDuplicates.Tooltip.Disabled')}
              toolTipOffset={12}
            />
          </>
        )}
        {hasFatigueRules && (
          <ToggleSection
            title={t('SendDetails.Suppression.SuppressFatigue.Title')}
            infoText={suppressFatigueText}
            isToggleOn={!overrideFatigueRules}
            onToggle={(isOn) => update({ message: { overrideFatigueRules: !isOn } })}
            className={`${rootClass}__toggle`}
          />
        )}
        <div className={`${rootClass}__bounces`}>
          <Typography text={t('SendDetails.Suppression.BounceOptOut.Text')} type={TextType.BODY_TEXT_LIGHT} />
          <Toggletip
            description={t('SendDetails.Suppression.BounceOptOut.Tooltip')}
            linkText={t('Learn more')}
            link={BOUNCES_LEARN_MORE_URL}
            side={ToggletipSide.TOP}
            isDismissable
          />
        </div>
      </Container>
    </>
  )
}

export default SendDetailsSuppressions
