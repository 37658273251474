import { useContext } from 'react'

import { Status } from '@components/StatusToast/StatusToast'
import { useTranslation } from '@const/globals'
import { Program, ProgramSource, ProgramStep, ProgramTrack } from '@graphql/types/query-types'
import {
  Update,
  UpgradedProgramSendStep,
  UpgradedProgramStep,
  UpgradedSourceUpgradeStepType,
  UpgradeManagerContext,
  UpgradeStep,
} from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'
import { ProgramStepType } from '@utils/program/program.constants'

export type StepToUpgradeType =
  | ProgramStepType.BRANCH
  | ProgramStepType.COPY
  | ProgramStepType.FIELD_SET
  | ProgramStepType.SEND
  | ProgramStepType.WAIT_UNTIL_IN_SEGMENT

export type UpgradedStepsType = Exclude<StepToUpgradeType, ProgramStepType.COPY> | ProgramStepType.APPEND_TO_SEGMENT

const stepTypesToUpgrade: StepToUpgradeType[] = [
  ProgramStepType.BRANCH,
  ProgramStepType.COPY,
  ProgramStepType.FIELD_SET,
  ProgramStepType.SEND,
  ProgramStepType.WAIT_UNTIL_IN_SEGMENT,
]

const hasToUpgrade: { [key in StepToUpgradeType]: (step: ProgramStep, sources: ProgramSource[]) => boolean } = {
  [ProgramStepType.FIELD_SET]: () => true,
  [ProgramStepType.COPY]: () => true,
  [ProgramStepType.SEND]: (step, sources) =>
    !!sources.find((source) => (step as UpgradedProgramSendStep).sendChoices.find((choice) => choice.srcId === source.id)),
  [ProgramStepType.BRANCH]: (step, sources) => !!sources.find((source) => source.id === (step as UpgradedSourceUpgradeStepType).srcId),
  [ProgramStepType.WAIT_UNTIL_IN_SEGMENT]: (step, sources) => !!sources.find((source) => source.id === (step as UpgradedSourceUpgradeStepType).srcId),
}

export const getStepsToUpgrade = (tracks: ProgramTrack[], sources: ProgramSource[]): UpgradedProgramStep[] => {
  const steps = tracks.flatMap(({ steps }) => steps)
  return steps
    .filter(
      (step) => stepTypesToUpgrade.includes(step.stepType as StepToUpgradeType) && hasToUpgrade[step.stepType as StepToUpgradeType](step, sources)
    )
    .map((step) => ({ ...step, upgradeId: `step-${step.letter}`, removed: false }))
}

export const showStatusToast = (update: Update, message: string) => {
  update({
    statusToast: {
      message,
      status: Status.FAIL,
      showStatusToast: true,
    },
  })
}

export const useUpgradeProgramSteps = () => {
  const {
    values: { activeUpgradeStep },
  } = useContext(UpgradeManagerContext)
  const { t } = useTranslation()
  const rootText = 'AutomatedPrograms.UpgradeManager.SidebarMenu.Progress.'
  const isChooseSegmentsActive = activeUpgradeStep === UpgradeStep.CONTACT_SOURCES || activeUpgradeStep === UpgradeStep.EARLY_EXITS
  const isSelectingProgram = activeUpgradeStep === UpgradeStep.SELECT_PROGRAM
  const isReviewUpgrade = activeUpgradeStep === UpgradeStep.REVIEW_UPGRADE
  const isSuccessfullyUpgraded = activeUpgradeStep === UpgradeStep.SUCCESSFULLY_UPGRADED

  const steps = [
    {
      header: t(`${rootText}SelectProgram`),
      isActive: isSelectingProgram,
      isCompleted: !isSelectingProgram,
      key: UpgradeStep.SELECT_PROGRAM,
    },
    {
      header: t(`${rootText}ChooseSegments`),
      isActive: isChooseSegmentsActive,
      isCompleted: (!isChooseSegmentsActive && !isSelectingProgram) || isReviewUpgrade || isSuccessfullyUpgraded,
      key: UpgradeStep.CONTACT_SOURCES,
    },
    {
      header: t(`${rootText}FixIssues`),
      isActive: !isChooseSegmentsActive && !isSelectingProgram && !isReviewUpgrade && !isSuccessfullyUpgraded,
      isCompleted: isReviewUpgrade || isSuccessfullyUpgraded,
      key: UpgradeStep.FIX_STEP_ISSUES,
    },
    { header: t(`${rootText}ReviewUpgrade`), isActive: isReviewUpgrade, isCompleted: isSuccessfullyUpgraded, key: UpgradeStep.REVIEW_UPGRADE },
  ]
  return { steps }
}

export const hasEarlyExitConditionsToUpdate = (program: Program) => {
  const earlyExitSources = program?.exit?.exitChoices.reduce(
    (acc: { id: string }[], exit) => [...acc, { id: exit?.srcId ?? '' }, { id: exit?.listId ?? '' }],
    []
  )
  return earlyExitSources.filter((exitSource) => program?.sources?.find((source) => source.id === exitSource.id) !== undefined).length > 0
}
