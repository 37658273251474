import React from 'react'

import { Status } from '@components/StatusToast/StatusToast'
import { TOAST_TEXT_CLASSNAME } from '@components/Toast/Toast'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { ConfigQuery } from '@graphql/types/microservice/ml-audience-types'
import { rootTranslation } from '@src/pages/RecommendedSegments/components/SegmentsInsightsSettingsContainer/SegmentsInsightsSettingsContainer'
import { FeaturesCategories, SegmentationInsightsTimeRange } from '@src/pages/RecommendedSegments/utils/RecommendedSegments.constants'
import { SegmentationCriteria, SegmentsInsightsSettings } from '@src/pages/RecommendedSegments/utils/RecommendedSegments.interfaces'

const getTimeRangeFromFilter = (filter: number) => {
  switch (filter) {
    case 0:
      return SegmentationInsightsTimeRange.ALL_TIME
    case 30:
      return SegmentationInsightsTimeRange.LAST_MONTH
    case 90:
      return SegmentationInsightsTimeRange.LAST_QUARTER
    default:
      return SegmentationInsightsTimeRange.LAST_SEMESTER
  }
}

export const parseConfigToSettings = (response: ConfigQuery | undefined | null): Partial<SegmentsInsightsSettings> => {
  const configurations = response?.config
  if (configurations) {
    const timeRange = getTimeRangeFromFilter(configurations.filter ?? 0)
    const categorizedFeatures: SegmentationCriteria[] = [
      { category: FeaturesCategories.CATEGORICAL, features: configurations.data?.featuresCat as string[] },
      { category: FeaturesCategories.NUMERICAL, features: configurations.data?.featuresNum as string[] },
    ]
    return { categorizedFeatures, timeRange }
  }
  return { categorizedFeatures: [], availableFeatures: [] }
}

export const renderUpdatedSettingsToast = () => ({
  status: Status.SUCCESS,
  message: (
    <div className={TOAST_TEXT_CLASSNAME}>
      <Typography text={`${rootTranslation}.Toast.Success`} tagProps={{ bold: { weight: TextWeight.BOLD } }} inline />
    </div>
  ),
})
