import { gql } from '@apollo/client'

export default gql`
  query recordErrorsByOrigin($listId: String, $offset: Int, $origin: String, $count: Int) {
    recordErrorsByOrigin(listId: $listId, offset: $offset, origin: $origin, count: $count) {
      email
      error
      firstName
      lastErrorTime
      pushRecordErrorId
      recId
    }
  }
`
