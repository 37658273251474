import React from 'react'

import { t } from 'i18next'

import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { RowAction } from '@components/Table/Table'
import { FromAddresses } from '@graphql/types/query-types'

type RowActionClick = (row: FromAddresses) => void

const getRowActions = (
  handleEdit: RowActionClick,
  handleDelete: RowActionClick,
  handleVerify: RowActionClick,
  handleChangeDefault: (row: FromAddresses, action: 'add' | 'remove') => void
) => {
  return [
    {
      label: t('Edit'),
      icon: SvgNames.pencil,
      onClick: (row) => handleEdit(row.original),
    },
    {
      label: t('Delete'),
      icon: SvgNames.delete,
      onClick: (row) => handleDelete(row.original),
    },
    {
      label: (row) =>
        row.original.isDefault ? t('Remove as default') : row.original.isVerified ? t('Set as default') : t('Resend verification email'),
      icon: (row) =>
        row.original.isDefault ? (
          <Svg name={SvgNames.removeContact} type={SvgType.LARGER_ICON} />
        ) : row.original.isVerified ? (
          <Svg name={SvgNames.addContactsNoFill} type={SvgType.LARGER_ICON} />
        ) : (
          <Svg name={SvgNames.letter} type={SvgType.LARGER_ICON} />
        ),
      onClick: (row) => {
        const fromAddress = row.original
        fromAddress.isDefault
          ? handleChangeDefault(fromAddress, 'remove')
          : fromAddress.isVerified
          ? handleChangeDefault(fromAddress, 'add')
          : handleVerify(fromAddress)
      },
    },
  ] as RowAction[]
}

export default getRowActions
