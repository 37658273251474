import React, { FC, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { SvgNames } from '@components/Svg'
import { useTranslation } from '@const/globals'
import { InputDefinition } from '@graphql/types/microservice/webhooks-incoming-management-types'
import { BehaviorResponse } from '@graphql/types/query-types'

import './CustomTouchPointBehaviorsHeader.css'

interface CustomTouchPointBehaviorsHeaderProps {
  behaviors: BehaviorResponse[]
}

const rootClass = 'custom-touch-point-behaviors-header'
const metaData = 'contentMappings.0.mappingMetadata'
const CustomTouchPointBehaviorsHeader: FC<CustomTouchPointBehaviorsHeaderProps> = ({ behaviors }: CustomTouchPointBehaviorsHeaderProps) => {
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<InputDefinition>()
  const { t } = useTranslation()
  const error = (errors?.contentMappings ?? [])[0]

  const options: SelectV2SingleOption[] = useMemo(
    () => behaviors.map(({ description, verb }: BehaviorResponse) => ({ label: description, value: verb })),
    [behaviors]
  )

  const customBehaviorSelectProps = useMemo(
    () => ({
      dataTest: `${rootClass}__custom-behavior-select`,
      defaultValue: options.find(({ value }) => value === getValues(metaData)),
      error: !!error,
      inputIcon: SvgNames.gear,
      insideModal: true,
      isClearable: false,
      isSearchable: false,
      options,
      placeholder: t('Select a custom behavior'),
      showIconOnSelect: true,
    }),
    [options, error]
  )

  return (
    <div className={rootClass} data-test={rootClass}>
      <Controller
        control={control}
        name={metaData}
        render={({ field: { onBlur } }) => (
          <SelectV2
            {...customBehaviorSelectProps}
            onChange={(option?: SelectV2SingleOption) => {
              setValue(metaData, option?.value ?? '', { shouldDirty: true, shouldValidate: true })
            }}
            onBlur={onBlur}
          />
        )}
      />
    </div>
  )
}

export default CustomTouchPointBehaviorsHeader
