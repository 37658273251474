import { SelectV2GroupedOption, SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'

export interface DateFormatOption {
  /** Format to send to backend */
  value: string
  /** Dayjs-supported format to display a date on frontend */
  jsFormat: string
  /** Format to show the user */
  displayText: string
}

export const dateFormats: DateFormatOption[][] = [
  [
    {
      value: 'MM/dd/yyyy',
      jsFormat: 'MM/DD/YYYY',
      displayText: 'MM/DD/YYYY',
    },
    {
      value: 'dd/MM/yyyy',
      jsFormat: 'DD/MM/YYYY',
      displayText: 'DD/MM/YYYY',
    },
    {
      value: 'MM/dd/yy',
      jsFormat: 'MM/DD/YY',
      displayText: 'MM/DD/YY',
    },
    {
      value: 'dd/MM/yy',
      jsFormat: 'DD/MM/YY',
      displayText: 'DD/MM/YY',
    },
    {
      value: 'yyyy/MM/dd',
      jsFormat: 'YYYY/MM/DD',
      displayText: 'YYYY/MM/DD',
    },
  ],
  [
    {
      value: 'MM-dd-yyyy',
      jsFormat: 'MM-DD-YYYY',
      displayText: 'MM-DD-YYYY',
    },
    {
      value: 'dd-MM-yyyy',
      jsFormat: 'DD-MM-YYYY',
      displayText: 'DD-MM-YYYY',
    },
    {
      value: 'MM-dd-yy',
      jsFormat: 'MM-DD-YY',
      displayText: 'MM-DD-YY',
    },
    {
      value: 'dd-MM-yy',
      jsFormat: 'DD-MM-YY',
      displayText: 'DD-MM-YY',
    },
    {
      value: 'yyyy-MM-dd',
      jsFormat: 'YYYY-MM-DD',
      displayText: 'YYYY-MM-DD',
    },
  ],
  [
    {
      value: 'dd MMM yyyy',
      jsFormat: 'DD MMM YYYY',
      displayText: 'DD Mon YYYY',
    },
    {
      value: 'MMM dd yyyy',
      jsFormat: 'MMM DD YYYY',
      displayText: 'Mon DD YYYY',
    },
    {
      value: 'dd MMMM yyyy',
      jsFormat: 'DD MMMM YYYY',
      displayText: 'DD Month YYYY',
    },
    {
      value: 'EEE, dd MMM yyyy',
      jsFormat: 'ddd, DD MMM YYYY',
      displayText: 'Wkd, DD Mon YYYY',
    },
    {
      value: 'EEEE, dd MMMM yyyy',
      jsFormat: 'dddd, DD MMMM YYYY',
      displayText: 'Weekday, DD Month YYYY',
    },
  ],
]

export const dateFormatOptions: SelectV2GroupedOption[] = dateFormats.map((formatGroup, index) => {
  return {
    label: `${index}`,
    options: formatGroup.map((format) => ({ value: format.value, label: format.displayText })),
  }
})

export const findDateFormat = (value: string): DateFormatOption => {
  const foundFormat = dateFormats.flat().find((format) => format.value === value)
  return foundFormat ?? dateFormats[0][0]
}

export const findDateFormatOption = (value: string): SelectV2SingleOption => {
  const foundFormat = dateFormatOptions.flatMap((group) => group.options).find((option) => option.value === value)
  return foundFormat ?? dateFormatOptions[0].options[0]
}
