import { Dispatch, SetStateAction } from 'react'

import { ListingPageItem } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { Status } from '@components/StatusToast/StatusToast'
import { AddFromCRMEnabledRequestType } from '@src/pages/ContactSegments/components/AddContactsFromCRM/GraphQL/AddContactsFromCRMRequests.crm.graphQL'
import { ContactSegmentsActionsState } from '@src/pages/ContactSegments/components/ContactSegmentsActions/ContactSegmentsActions'
import { sendEmailToSegment } from '@src/pages/ContactSegments/utils/ContactSegmentsContainerUtils'
import { ItemType } from '@utils/categorization'
import { getListDownloadLink, saveSelectedListsToLocalStorage, Segment } from '@utils/contactSegments/contactSegments.utils'
import { Update } from '@utils/contactSegments/context/ContactSegmentsContext'
import { CRM_CONNECTOR_DEFINITIONS } from '@utils/crm.utils'
import { CRMConnectorType } from '@utils/hooks/useCRM'
import { DeleteConfirmationModals } from '@utils/listPageDeleteModals'
import { logNewRelicError } from '@utils/new-relic.utils'

export const onHeaderSendEmail = (selectedSegments: Segment[]) => {
  if (selectedSegments.length > 0) {
    const segmentsIds = selectedSegments.reduce((str, segment) => (str ? `${str},${segment.externalId}` : segment.externalId), '')

    saveSelectedListsToLocalStorage(selectedSegments as ListingPageItem[])

    sendEmailToSegment(segmentsIds)
  }
}

export const onHeaderSetFavorite = (
  selectedSegments: Segment[],
  setFavoriteSegments: (segmentsIds: number[], isFavorite: boolean, itemType?: ItemType) => void,
  itemType?: ItemType
) => {
  if (selectedSegments.length > 0) {
    const filter = selectedSegments.filter((segment) => !segment.isFavorite)
    const setFavorite = filter.length > 0
    const segmentIds = setFavorite ? filter.map((segment) => segment.id) : selectedSegments.map((segment) => segment.id)
    setFavoriteSegments(segmentIds, setFavorite, itemType)
  }
}

export const doExport = (segment: Segment, update: Update, t: Function) => {
  update({
    exportedFile: getListDownloadLink(segment),
    statusToast: {
      statusMessage: t(`ListPage.Common.Export`, { count: segment.recordsCount }),
      status: Status.SUCCESS,
      showStatusToast: true,
    },
  })
}

export const removeSegmentsFromFolder = (update: Update, selectedSegments: Segment[]) => {
  const segmentsWithFolder = selectedSegments.filter(({ folderId }) => folderId)
  update({
    deleteConfirmationData: segmentsWithFolder,
    confirmationModal:
      segmentsWithFolder.length > 1 ? DeleteConfirmationModals.DELETE_FROM_FOLDER_PLURAL : DeleteConfirmationModals.DELETE_FROM_FOLDER,
  })
}

export const hasSourceOptionsUtils = async (
  connectorType: CRMConnectorType,
  addFromCRMEnabledRequest: AddFromCRMEnabledRequestType,
  setState: Dispatch<SetStateAction<ContactSegmentsActionsState>>,
  update: Update
) => {
  try {
    const { data } = await addFromCRMEnabledRequest({ connectedCrmName: CRM_CONNECTOR_DEFINITIONS[connectorType] })
    setState((state: ContactSegmentsActionsState) => ({ ...state, showAddContactsFromCRMLink: !!data?.addFromCRMEnabled }))
  } catch (error) {
    logNewRelicError(error)
    update({
      statusToast: {
        statusMessage: 'AddContactsFromCRM.StatusToast.Failed.SourceOptions',
        status: Status.FAIL,
        showStatusToast: true,
      },
    })
  }
}
