import { useEffect, useMemo, useState } from 'react'

import { useUpgradeAssistantRequests } from '@src/pages/UpgradeAssistant/GraphQL/UpgradeAssistantRequests.graphQL'
import { ItemType } from '@utils/categorization'

type ValidItemTypes = ItemType.FORM | ItemType.FORM_TEMPLATE | ItemType.PROGRAM

export const useUpgradeAssistant = (itemType: ValidItemTypes | ValidItemTypes[]) => {
  const [hasUpgradableItems, setHasUpgradableItems] = useState<boolean>()

  const { getProgramsToUpgradeCount, getFormTemplatesCount, getFormToUpgradeCount } = useUpgradeAssistantRequests()

  const queryByItemType: { [key in ValidItemTypes]: () => Promise<number> } = useMemo(
    () => ({
      [ItemType.FORM]: () => getFormToUpgradeCount(15000),
      [ItemType.FORM_TEMPLATE]: getFormTemplatesCount,
      [ItemType.PROGRAM]: getProgramsToUpgradeCount,
    }),
    [getFormToUpgradeCount, getProgramsToUpgradeCount]
  )

  const updateHasUpgradableItems = async () => {
    if (Array.isArray(itemType)) {
      const itemsCountArray = await Promise.all(itemType.map((type) => queryByItemType[type]()))
      const itemsCountSum = itemsCountArray.reduce((sum, itemsCount) => sum + itemsCount, 0)
      setHasUpgradableItems(itemsCountSum > 0)
    } else {
      const itemsCount = await queryByItemType[itemType]()
      setHasUpgradableItems(itemsCount > 0)
    }
  }

  useEffect(() => {
    updateHasUpgradableItems()
  }, [itemType])

  return {
    hasUpgradableItems,
  }
}
