import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import classNames from 'classnames'
import equal from 'fast-deep-equal/es6/react'

import CaretIcon, { CaretIconDirection } from '@components/CaretIcon/CaretIcon'
import Pill, { PillSize } from '@components/Pill/Pill'
import Svg, { SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import { rootContext } from '@const/globals'
import { AccountSettings } from '@graphql/types/query-types'
import iframeViewUtils from '@src/pages/IFrameView/iframeViewUtils'
import documentUtils from '@utils/document'
import iframes from '@utils/iframe/iframe'
import { NavigationInterface } from '@utils/navigation/navigation.utils'

import { NavigationState, rootClass } from '../Navigation'
import navigationUtils, { getSelectedMenuNotation, shouldRenderCurrentNavigationItem } from '../navigationUtils'

interface NavigationMenuItemsProps {
  parentMenuNotation?: string
  navigation: NavigationInterface[]
  state: NavigationState
  setState: (val: NavigationState) => void
  t: Function
  accountSettings: AccountSettings
  iconsOnly?: boolean
  setPendingIFrameRequest: (iframeMessage?: any) => void
  expanded: boolean
  disabled: boolean
}

const MAX_ROOT_MENU_LENGTH = 3
const TOOLTIP_ALIGN_OFFSET = 23
const isCustomLink: (id?: string) => boolean = (id) => id === 'custom-link'

export const getLinkInternal = (navItem: NavigationInterface, t: Function, label: string) => {
  const customLinkLabel = (
    <label tabIndex={-1}>
      {navItem.icon && <Svg type={SvgType.VERY_LARGE_ICON} name={navItem.icon} className={`${rootClass}__item-icon-default`} />}
      <span>
        {label}
        {navItem.afterImg && <Svg name={navItem.afterImg} type={SvgType.SMALLER_ICON} className={`${rootClass}__item-after-image`} />}
        {navItem.beta && <sup>{t('BETA')}</sup>}
      </span>
    </label>
  )
  return isCustomLink(navItem.id) ? (
    <Tooltip trigger={customLinkLabel} align="start" position="bottom" alignOffset={TOOLTIP_ALIGN_OFFSET} sideOffset={0}>
      {navItem.title}
    </Tooltip>
  ) : (
    customLinkLabel
  )
}

export function getNavigationChildrenItems(
  accountSettings: AccountSettings,
  navItem: NavigationInterface,
  parentMenuNotation: string,
  state: NavigationState,
  setState: (val: NavigationState) => void,
  t: Function,
  setPendingIFrameRequest: (iframeMessage?: any) => void,
  iconsOnly?: boolean
) {
  if (!navItem) {
    return
  }

  const { appTitle, isAgencyAccount } = accountSettings
  const url = `${rootContext}/${navItem.url}`
  const isRoot = parentMenuNotation.length < MAX_ROOT_MENU_LENGTH

  const isActive = navigationUtils.checkIfNavItemMatchesUrl(state.currentURL, navItem)

  const className = classNames(`${rootClass}__item-name`, [
    {
      [`${rootClass}__item-name--no-icon`]: !navItem.icon,
      [`${rootClass}__item-name--active`]: isActive,
      [`${rootClass}__item-name--root`]: isRoot,
      [`${rootClass}__item-name--not-active`]: !isActive,
      [`${rootClass}__item-name--small-font`]: navItem.smallFont,
      [`${rootClass}__item-name--no-link`]: isCustomLink(navItem.id) && !navItem.openWindow,
    },
  ])

  const label = t(navItem.label)
  if (navItem.oldTarget && navItem.oldType === 'link') {
    return (
      <a
        data-test={`navigation-${navItem.id}`}
        title={navItem.title ? t(navItem.title) : label}
        tabIndex={iconsOnly ? -1 : 0}
        href={navItem.url}
        target={navItem.oldTarget}
        className={className}
        onFocus={() => navigationUtils.checkIfMenuShouldBeExpandedFromFocus(parentMenuNotation, state, setState)}
        onClick={() => {
          setState({
            ...state,
            stopIFrameFromOverridingState: false,
          })
        }}
      >
        {getLinkInternal(navItem, t, label)}
      </a>
    )
  }

  if (navItem.url) {
    return (
      <Link
        data-test={`navigation-${navItem.id}`}
        title={navItem.title ? t(navItem.title) : label}
        to={`${url}`}
        onFocus={() => navigationUtils.checkIfMenuShouldBeExpandedFromFocus(parentMenuNotation, state, setState)}
        className={className}
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
          navItem.callBack?.(accountSettings.accountId)
          const title = navigationUtils.getPageTitleFromURL(url.replace(`${rootContext}/`, ''), accountSettings, state.navigation)
          if (title && !e.metaKey) {
            documentUtils.setTitle(`${title}`, !isAgencyAccount ? appTitle : undefined)
          }
          if (state.currentURL === url) {
            const internalAddress = iframeViewUtils.getInternalAddressFromCurrent(
              accountSettings.isMicrosoftStartPage,
              url,
              true,
              accountSettings.userSettings.userDesktop
            )
            iframes.postMessage({
              actonNavigate: internalAddress,
            })
            setState({
              ...state,
              stopIFrameFromOverridingState: false,
              clickedMenu: parentMenuNotation,
            })
          } else {
            setState({
              ...state,
              currentURL: url,
              stopIFrameFromOverridingState: false,
              clickedMenu: parentMenuNotation,
            })
          }
        }}
      >
        {getLinkInternal(navItem, t, label)}
      </Link>
    )
  }
  if (navItem.openWindow || navItem.hideAllListingFolders) {
    return (
      <button
        data-test={`navigation-${navItem.id}`}
        title={isCustomLink(navItem.id) ? undefined : navItem.title ? t(navItem.title) : label}
        tabIndex={iconsOnly ? -1 : 0}
        className={className}
        onFocus={() => navigationUtils.checkIfMenuShouldBeExpandedFromFocus(parentMenuNotation, state, setState)}
        onClick={() => {
          if (navItem.openWindow) {
            navigationUtils.navigationActionOpenWindowInIFrame(setPendingIFrameRequest, navItem)
          } else {
            navigationUtils.navigationActionHideListingFoldersInIFrame()
          }
        }}
      >
        {getLinkInternal(navItem, t, label)}
      </button>
    )
  }
  //Only return if has a custom link but no url
  if (navItem.title) {
    navItem = { ...navItem, icon: undefined }
    return <span className={className}>{getLinkInternal(navItem, t, label)}</span>
  }
  return null
}

const propsAreEqual = (prevProps: NavigationMenuItemsProps, nextProps: NavigationMenuItemsProps) => equal(prevProps, nextProps)
const NavigationMenuItems: FC<NavigationMenuItemsProps> = React.memo((props: NavigationMenuItemsProps) => {
  const { parentMenuNotation = '', navigation, state, setState, t, accountSettings, iconsOnly, setPendingIFrameRequest, expanded, disabled } = props
  const standardNav = navigation
    .map((navItem, index) => {
      if (!shouldRenderCurrentNavigationItem(navItem.settingsAnd, accountSettings, navItem.settings)) return null
      const menuNotation = getSelectedMenuNotation(parentMenuNotation, index)
      const isRoot = menuNotation.length < MAX_ROOT_MENU_LENGTH
      if (navItem.items) {
        const curUrlBelongsToChildUrl = navigationUtils.currentUrlBelongsToNavItemsChildren(navItem.items, state.currentURL, accountSettings)
        const shouldShowChildren = navigationUtils.showRenderNavigationItemsChildren(menuNotation, state)
        const text = t(navItem.label)
        const isRootAndActive = isRoot && curUrlBelongsToChildUrl

        let maxHeight = undefined
        if (expanded || !isRoot) {
          maxHeight = shouldShowChildren
            ? `${navigationUtils.getTotalMaxHeightForSubMenu(expanded, navItem.items, menuNotation, accountSettings, state)}px`
            : 0
        }

        const depth = navigationUtils.getDepth(menuNotation)
        return (
          <li
            className={classNames(`${rootClass}__item`, [
              {
                [`${rootClass}__item--root`]: isRoot,
              },
            ])}
            key={navItem.id}
          >
            {navItem.topSection && <div className={`${rootClass}__top-section`} />}
            <button
              data-test={`navigation-${navItem.id}`}
              tabIndex={iconsOnly ? -1 : 0}
              title={text}
              className={classNames(`${rootClass}__item-name`, [
                {
                  [`${rootClass}__item-name--no-icon`]: !navItem.icon,
                  [`${rootClass}__item-name--root`]: isRoot,
                  [`${rootClass}__item-name--sub`]: !isRoot,
                  [`${rootClass}__item-name--root-active`]: isRootAndActive,
                },
              ])}
              onClick={() => {
                if (!isRoot || expanded) {
                  navigationUtils.navigationItemWithChildrenSelected(expanded, menuNotation, state, setState, isRoot, true)
                }
              }}
              onMouseEnter={() => {
                if (isRoot && !expanded && !disabled) {
                  navigationUtils.navigationItemWithChildrenSelected(expanded, menuNotation, state, setState, isRoot, false)
                }
              }}
            >
              <label tabIndex={-1}>
                {navItem.icon && !iconsOnly && (
                  <>
                    <Svg
                      type={SvgType.VERY_LARGE_ICON}
                      name={navItem.icon}
                      className={classNames(`${rootClass}__item-icon-default`, [
                        {
                          [`${rootClass}__item-icon-default--active`]: isRootAndActive,
                          [`${rootClass}__item-icon-default--active-click`]: state.menuItemSelectedByUserClick && isRootAndActive,
                        },
                      ])}
                    />
                    {navItem.iconSelected && (
                      <Svg
                        type={SvgType.VERY_LARGE_ICON}
                        name={navItem.iconSelected}
                        className={classNames(`${rootClass}__item-icon-selected`, [
                          {
                            [`${rootClass}__item-icon-selected--active`]: isRootAndActive,
                            [`${rootClass}__item-icon-selected--active-click`]: state.menuItemSelectedByUserClick && isRootAndActive,
                          },
                        ])}
                      />
                    )}
                  </>
                )}
                {navItem.icon && iconsOnly && !curUrlBelongsToChildUrl && (
                  <Svg
                    type={SvgType.VERY_LARGE_ICON}
                    name={navItem.icon}
                    className={classNames(`${rootClass}__item-icon-default`, [
                      {
                        [`${rootClass}__item-icon-default--active-hover`]: state.menuItemSelectedByUserHover && isRootAndActive,
                      },
                    ])}
                  />
                )}
                {navItem.iconSelected && iconsOnly && curUrlBelongsToChildUrl && (
                  <Svg type={SvgType.VERY_LARGE_ICON} name={navItem.iconSelected} className={`${rootClass}__item-icon-selected`} />
                )}
                {!isRoot && (
                  <CaretIcon
                    direction={CaretIconDirection.RIGHT}
                    className={classNames(`${rootClass}__item-caret`, [
                      {
                        [`${rootClass}__item-caret--down`]: shouldShowChildren,
                      },
                    ])}
                  />
                )}
                {!iconsOnly && (
                  <span>
                    {text}
                    {navItem.isNew && <Pill text={t('NEW')} size={PillSize.SMALL} />}
                  </span>
                )}
              </label>
            </button>
            {!iconsOnly && (
              <ul
                data-test={`navigation-${navItem.id}-children`}
                style={{
                  maxHeight,
                }}
                className={classNames(`${rootClass}__group`, `${rootClass}__group--depth-${depth}`, [
                  {
                    [`${rootClass}__group--visible`]: shouldShowChildren,
                    [`${rootClass}__group--visible-hover`]:
                      state.hideOverflow !== menuNotation && shouldShowChildren && state.menuItemSelectedByUserHover,
                  },
                ])}
                onMouseLeave={() => {
                  if (isRoot && !expanded && depth === 1) {
                    setState({
                      ...state,
                      stopIFrameFromOverridingState: true,
                      menuItemSelectedByUserClick: false,
                    })
                  }
                }}
              >
                <li className={`${rootClass}__mobile-header`}>{text}</li>
                <hr className={`${rootClass}__mobile-header-line`} />
                <NavigationMenuItems
                  expanded={expanded}
                  disabled={disabled}
                  state={state}
                  setState={setState}
                  accountSettings={accountSettings}
                  parentMenuNotation={menuNotation}
                  navigation={navItem.items}
                  setPendingIFrameRequest={setPendingIFrameRequest}
                  t={t}
                />
              </ul>
            )}
          </li>
        )
      }

      return (
        <li className={`${rootClass}__sub-item`} key={navItem.url || `${navItem.openWindow?.url}-${navItem.openWindow?.name}`}>
          {navItem.topSection && <div className={`${rootClass}__top-section`} />}
          {getNavigationChildrenItems(accountSettings, navItem, menuNotation, state, setState, t, setPendingIFrameRequest, iconsOnly)}
        </li>
      )
    })
    .filter((nav) => nav !== null)

  return <>{standardNav}</>
}, propsAreEqual)

NavigationMenuItems.displayName = 'NavigationMenuItems'
export default NavigationMenuItems
