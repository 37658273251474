import { escapeRegExp } from 'lodash'
import { AnyAction } from 'redux'

import { ApolloError } from '@apollo/client'
import { ComplexListColumn, ComplexListRow } from '@components/ComplexList/ComplexList'
import { SvgNames } from '@components/Svg'
import { DirectionOptions } from '@components/TimespanPicker/TimespanPicker'
import { ChartField } from '@const/Chart.constants'
import {
  DaySummary,
  LandingPageDaySummary,
  FormsAndMediaDaySummary,
  EmailMessagesDaySummary,
  MonthlySummary,
  LandingPageMonthlySummary,
  FormsAndMediaMonthlySummary,
  EmailMessagesMonthlySummary,
  WeeklySummary,
  LandingPageWeekdaySummary,
  FormsAndMediaWeekdaySummary,
  EmailMessagesWeekdaySummary,
  WeekdaySummary,
  WebsiteVisitorsReport,
  Scalars,
} from '@graphql/types/query-types'
import { monthsForLocale, dateYesterdayMidnight } from '@utils/date'
import { NavigationState } from '@utils/navigation/navigation.utils'
import { formatNumber, getLocaleRadixChar } from '@utils/numbers'
import { countriesNotInHighCharts } from '@utils/reports/reports.constants'
import { titleCase } from '@utils/strings'

export interface EmailMessageReportsDataFormat extends ReportsDataFormatCommon {
  dailyActivity: { fields: ChartField[] }
  monthlyLaunchDetails: {
    categories: string[]
    fields: ChartField[]
  }
}

export interface EmailMessageContainerState extends ReportStateCommon {
  startTimePresentation: string
  endTimePresentation: string
}

export interface FormsMediaContainerState extends ReportStateCommon {
  startTimePresentation: string
  endTimePresentation: string
}

export interface FormsAndMediaReportsDataFormat extends ReportsDataFormatCommon {
  opportunityGrowth: { fields: ChartField[] }
  monthlyFormDetails: {
    categories: string[]
    fields: ChartField[]
  }
}

export interface LandingPageReportsDataFormat extends ReportsDataFormatCommon {
  dailyVisitors: { fields: ChartField[] }
  monthlyPageDetails: {
    categories: string[]
    fields: ChartField[]
  }
}

export interface LandingPageContainerState extends ReportStateCommon {
  startTimePresentation: string
  endTimePresentation: string
}

type DaySummaryExt = DaySummary &
  Partial<LandingPageDaySummary> &
  Partial<FormsAndMediaDaySummary> &
  Partial<EmailMessagesDaySummary> &
  Partial<Visitors>

type MonthlySummaryExt = MonthlySummary &
  Partial<LandingPageMonthlySummary> &
  Partial<FormsAndMediaMonthlySummary> &
  Partial<EmailMessagesMonthlySummary> &
  Partial<WebsiteVisitorsReport> &
  Partial<Visitors>

type WeekdaySummaryExt = WeekdaySummary &
  Partial<LandingPageWeekdaySummary> &
  Partial<FormsAndMediaWeekdaySummary> &
  Partial<EmailMessagesWeekdaySummary> &
  Partial<WebsiteVisitorsReport> &
  Partial<Visitors>

type Visitors = {
  __typename?: 'VisitorDataPoint'
  companies: Scalars['Int']
  known: Scalars['Int']
  anonymous: Scalars['Int']
  bounces: Scalars['Int']
}

const monthsShort = monthsForLocale('en-US', 'short')
const monthsFull = monthsForLocale('en-US', 'long')

export enum DailyReportFieldTypes {
  VIEWS = 'views',
  CLICKS = 'clicks',
  SUBMITS = 'submits',
  MEDIA_VIEWS = 'mediaViews',
  OPENS = 'opens',
  SENDS = 'sends',
  OPT_OUTS = 'optOuts',
  BOUNCES = 'bounces',
  ANONYMOUS = 'anonymous',
  KNOWN = 'known',
  COMPANIES = 'companies',
}

export enum MonthlyReportFieldTypes {
  TOTALVIEWS = 'totalViews',
  TOTALCLICKS = 'totalClicks',
  TOTALSUBMITS = 'totalSubmits',
  TOTALMEDIAVIEWS = 'totalMediaViews',
  TOTALSENDS = 'totalSends',
  TOTALOPENS = 'totalOpens',
  TOTALOPTOUTS = 'totalOptOuts',
  TOTALBOUNCES = 'totalBounces',
  CONVERSIONS = 'conversions',
  COMPANIES = 'companies',
  ANONYMOUS = 'anonymous',
  KNOWN = 'known',
  COMPANYTOTAL = 'companyTotal',
  BOUNCESTOTAL = 'bouncesTotal',
}

export interface DailyFieldInfo {
  name: string
  fieldName: keyof DaySummaryExt
  color: string
}

export interface MonthlyFieldInfo {
  name: string
  fieldName: MonthlyReportFieldTypes
  color?: string
  excludeMonthlySummary?: boolean
  excludeDailyActivity?: boolean
  title?: string
  isHighlighted?: boolean
}

export interface ReportsDataFormatCommon {
  monthlyComparison: {
    columns: ComplexListColumn[]
    rows: ComplexListRow[]
  }
  viewsByDay?: {
    categories: string[]
    fields: ChartField[]
  }
  clicksByDay?: {
    categories: string[]
    fields: ChartField[]
  }
  opensByDay?: {
    categories: string[]
    fields: ChartField[]
  }
  submitsByDay?: {
    categories: string[]
    fields: ChartField[]
  }
  mediaViewsByDay?: {
    categories: string[]
    fields: ChartField[]
  }
  activityByDay: { fields: ChartField[] }
  updatedDate: string
}

export interface ReportStateCommon {
  startTime: string
  endTime: string
  nextDisabled?: boolean
  prevDisabled?: boolean
}

export type ReportPropsCommon = ReportStateCommon & ReportsDataFormatCommon

export interface ReportProps extends ReportPropsCommon {
  loading: boolean
  onDateChange: (direction: DirectionOptions) => void
  refreshOnClick?: () => void
  pageError?: ApolloError
  className?: string
  dataTest?: string
}

type ContainerState = EmailMessageContainerState | FormsMediaContainerState | LandingPageContainerState

const navigation: NavigationState = {
  disabled: false,
  inIframe: false,
  visible: true,
  modalOpen: false,
  expanded: true,
  globalLoading: false,
  hiddenMenu: false,
}

export const reportStore: any = {
  getState: () => {
    return {
      navigation,
      account: {
        account: {
          token: 'test',
          aoAccountContext: '',
          microserviceUrls: { 'sms-management-service': '' },
          accountSettings: { accountId: '1827', userId: '1' },
        },
      },
    }
  },
  subscribe: () => 0,
  replaceReducer: '',
  dispatch: (_value: AnyAction) => undefined,
}

export const linkReportStore: any = {
  getState: () => {
    return {
      navigation,
      account: {
        account: {
          token: 'test',
          aoAccountContext: '',
          microserviceUrls: { 'email-event-service': '' },
          accountSettings: { accountId: '1826', userId: '1' },
        },
      },
    }
  },
  subscribe: () => 0,
  replaceReducer: '',
}

export const getDailyVisitors = (summary: MonthlySummaryExt[], fieldInfo: DailyFieldInfo[]) => {
  return {
    fields: fieldInfo.map((field) => ({
      name: field.name,
      data: summary
        .map((month: MonthlySummaryExt) => month.days || [])
        .reduce((acc: DaySummaryExt[], cur: DaySummaryExt[]) => {
          return acc.concat(cur)
        }, [])
        .map((day: DaySummaryExt) => {
          return [new Date(day.name).getTime() as number, day[field.fieldName] as number]
        })
        .sort((a, b) => a[0] - b[0]),
      color: field.color,
    })),
  }
}

export const getMonthlyComparison = (summary: [MonthlySummaryExt], fieldInfo: MonthlyFieldInfo[]) => {
  return {
    columns: summary.map((month: MonthlySummaryExt) => ({
      title: `${monthsFull[new Date(month.name).getUTCMonth()]} ${new Date(month.name).getUTCFullYear()}`,
    })),
    rows: fieldInfo
      .filter(function (field) {
        return !field.excludeMonthlySummary
      })
      .map((field) => {
        const monthlyData = summary.map((month: MonthlySummaryExt) => (month[field.fieldName] as number) || 0)

        return {
          label: field.name,
          data: monthlyData.map(String),
          dataPlain: monthlyData.map((entry) => formatNumber(entry)),
          dataWithPercent: monthlyData.map((month: number, index: number, arr: number[]) => {
            return [
              formatNumber(month),
              ...(index > 0
                ? [`${arr[index - 1] > 0 ? Math.round(((month - arr[index - 1]) / arr[index - 1]) * 100).toString() : month > 0 ? '100' : '0'}%`]
                : []),
            ]
          }),
          color: field.color,
        }
      }),
  }
}

export const getMapDetails = (summary: any) => {
  return {
    data: summary.states,
  }
}

export const getMonthlyPageDetails = (summary: [MonthlySummaryExt], fieldInfo: MonthlyFieldInfo[]) => {
  const monthNames = summary.map(
    (month: MonthlySummaryExt) => `${titleCase(monthsShort[new Date(month.name).getUTCMonth()])} ${new Date(month.name).getUTCFullYear()}`
  )

  return {
    categories: monthNames,
    fields: fieldInfo.map((field) => ({
      name: field.name,
      data: summary.map((month: MonthlySummaryExt) => month[field.fieldName]).map(Number),
      color: field.color,
    })),
  }
}

export const getReportDataByDay = (summary: [MonthlySummaryExt], colors: string[], type: DailyReportFieldTypes) => ({
  categories: Array(31).map((_el, i) => (i + 1).toString()),
  fields: summary.map((month: MonthlySummaryExt, idx: number) => ({
    name: `${titleCase(monthsShort[new Date(month.name).getUTCMonth()])} ${new Date(month.name).getUTCFullYear()}`,
    data: month.days?.map((day: DaySummaryExt) => day[type] as number) || [],
    color: colors[idx],
  })),
})

export const getReportActivityByDay = (summary: WeeklySummary, fieldInfo: MonthlyFieldInfo[]) => ({
  fields: fieldInfo
    .filter(function (field) {
      return !field.excludeDailyActivity
    })
    .map((field) => ({
      name: field.name,
      data: summary.weekdayCounts?.map((weekday: WeekdaySummaryExt) => weekday[field.fieldName] as number) || [],
      color: field.color,
    })),
})

const setDateString = (dateString: string, range: number, isFirstOfMonth: boolean) => {
  const getDaysInMonth = (m: number, y: number) => {
    return m === 2 ? (y & 3 || (!(y % 25) && y & 15) ? 28 : 29) : 30 + ((m + (m >> 3)) & 1)
  }
  const [year, month] = dateString.split('-').map(Number)

  const newYear = month + range <= 0 ? year - 1 : month + range > 12 ? year + 1 : year
  const newMonth = String(month + range <= 0 ? month + range + 12 : month + range > 12 ? month + range - 12 : month + range).padStart(2, '0')
  const newDay = isFirstOfMonth ? '01' : getDaysInMonth(parseInt(newMonth), newYear)
  return `${newYear}-${newMonth}-${newDay}`
}

export const handleDateChange = (state: ContainerState, setState: React.Dispatch<React.SetStateAction<any>>, direction: DirectionOptions) => {
  let startTimeDate, endTimeDate

  if (direction === DirectionOptions.NEXT) {
    startTimeDate = setDateString(state.startTime, 1, true)
    endTimeDate =
      new Date(new Date(state.endTime).getFullYear(), new Date(state.endTime).getMonth() + 2, 0) >= dateYesterdayMidnight
        ? dateYesterdayMidnight.toISOString().substring(0, 10)
        : setDateString(state.endTime, 1, false)
  } else {
    startTimeDate = setDateString(state.startTime, -1, true)
    endTimeDate = setDateString(state.endTime, -1, false)
  }

  const startTime = startTimeDate
  const endTime = endTimeDate
  const startTimePresentation = monthsShort[Number(startTime.split('-')[1]) - 1]
  const endTimePresentation = monthsShort[Number(endTime.split('-')[1]) - 1]

  const nextDisabled = endTimeDate >= dateYesterdayMidnight.toISOString().substring(0, 10)

  setState({
    ...state,
    nextDisabled,
    startTime,
    startTimePresentation,
    endTime,
    endTimePresentation,
  })
}

export const getMockDayData = (len: number) => {
  const arr = Array(len).fill(0)
  return arr.map((_el, i) => Math.floor(Math.random() * i))
}

export const getByDayOfMonth = (colors: string[]) => {
  return {
    categories: Array(31).map((_el, i) => (i + 1).toString()),
    fields: [
      {
        name: 'Jan 2021',
        data: getMockDayData(31),
        color: colors[0],
      },
      {
        name: 'Feb 2021',
        data: getMockDayData(28),
        color: colors[1],
      },
      {
        name: 'Mar 2021',
        data: getMockDayData(30),
        color: colors[2],
      },
    ],
  }
}

export const getMapData = (mapData: any[], fieldName: string) => {
  const data = Object.values(
    mapData
      .map((month) => {
        return month[fieldName]
      })
      .reduce((pre, cur) => {
        return pre.concat(cur)
      })
      .reduce((pre: any, cur: any) => {
        const code = fieldName === 'states' ? cur.code.replace('US-', '') : cur.code
        const countryNames = countriesNotInHighCharts.find((name) => name.code === code)

        if (!pre[cur.code]) {
          pre[cur.code] = pre[cur.code] || { code: code, value: 0, name: countryNames?.name }
        }
        pre[cur.code].value += cur.count
        return pre
      }, {})
  )

  return [
    {
      name: 'visits',
      data,
    },
  ]
}

export const numberedListFormatter = (listData: any[], fieldName: string, nameField: string, label: string) => {
  const copyOfData = [...listData]

  const result = copyOfData.reverse().map((month, idx, arr) => {
    const prevMonth = arr.length + 1 === idx ? false : arr[idx - 1]

    const innerResult = month[fieldName].map((category: any, categoryIdx: number) => {
      const isSocialMedia = fieldName === 'socialMedia'
      const name = isSocialMedia ? `${category[nameField]} (${category.count})` : category[nameField]
      let svgName: [string] = ['']
      let notInTop20: [boolean] = [false]
      const prevMonthCompanyIdx = prevMonth ? prevMonth[fieldName].findIndex((obj: any) => obj[nameField] == category[nameField]) : false

      if (prevMonth && prevMonthCompanyIdx !== -1 && !isSocialMedia) {
        svgName = [categoryIdx > prevMonthCompanyIdx ? SvgNames.arrowDown : categoryIdx < prevMonthCompanyIdx ? SvgNames.arrowUp : '']
      } else if (prevMonth && prevMonthCompanyIdx === -1 && !isSocialMedia) {
        svgName = ['']
        notInTop20 = [true]
      }
      return {
        ...category,
        name,
        svgName: svgName[0],
        notInTop20: notInTop20[0],
      }
    })
    return {
      title: month.monthName,
      items: innerResult,
    }
  })
  return {
    label,
    data: result,
  }
}

export const getWebsiteVisitorsMonthlyComparison = (summary: any, fieldInfo: MonthlyFieldInfo[], subInfo: any) => {
  const summaryCopy = [...summary].reverse()
  return {
    columns: summaryCopy.map((month: MonthlySummaryExt) => ({
      title: month.monthName,
    })),
    rows: fieldInfo.map((field) => {
      const monthlyData = summaryCopy.map((month: MonthlySummaryExt) => month[field.fieldName]?.toLocaleString() || 0)
      const total = summaryCopy.map((month: any) => (month.knownTotal + month.anonymousTotal).toLocaleString() || 0)
      const pctConverter = (field: string) => {
        return summaryCopy.map((month: any, idx: number) => {
          const radixChar = new RegExp(escapeRegExp(getLocaleRadixChar()), 'g')
          const totalVisiting = parseInt(total[idx].replace(radixChar, ''))
          return month[field] !== 0 ? `${Math.round((100 * month[field]) / totalVisiting)}%-${month[field]}` : month[field]
        })
      }
      const knownPct = pctConverter('knownTotal')
      const anonymousPct = pctConverter('anonymousTotal')
      const bouncePct = pctConverter('bouncesTotal')

      return {
        label: field.name,
        data: field.name === 'People Visiting' ? total.map(String) : field.name === 'Bounces' ? bouncePct.map(String) : monthlyData.map(String),
        dataPlain: monthlyData.map(String),
        children:
          field.name === 'People Visiting'
            ? subInfo.map((subField: any) => {
                return {
                  label: subField.name,
                  data: subField.name === 'Known' ? knownPct : anonymousPct,
                }
              })
            : null,
        color: field.color,
        isHighlighted: field.isHighlighted,
        title: field.title,
      }
    }),
  }
}

export const getDaysInMonth = (startTime: string, midTime: string, endTime: string) => {
  const startDate = new Date(startTime)
  const midDate = new Date(midTime)
  const endDate = new Date(endTime)

  return {
    startDateLength: new Date(startDate.getFullYear(), startDate.getUTCMonth() + 1, 0).getDate(),
    midDateLength: new Date(midDate.getFullYear(), midDate.getUTCMonth() + 1, 0).getDate(),
    endDateLength: new Date(endDate.getFullYear(), endDate.getUTCMonth() + 1, 0).getDate(),
  }
}

export const setStartMonth = (month: any) => {
  const monthValue = month.getMonth()
  if (monthValue === 0 || monthValue === 1) {
    return monthValue + 10
  } else {
    return monthValue - 2
  }
}
