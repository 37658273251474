import React, { useEffect, useState } from 'react'

import { ApolloClient } from '@apollo/client'
import PageContainer from '@components/PageContainer'
import PageError from '@components/PageError'
import getOptInsForms from '@graphql/microservices/sms-management/getOptInForms'
import { Query, QueryGetOptInFormsArgs } from '@graphql/types/microservice/sms-management-types'
import { useAccountSettings } from '@utils/account/account.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'
import { logNewRelicError } from '@utils/new-relic.utils'
import { SortByState } from '@utils/sms.utils'

import { FormOptInsLists } from './FormOptInsLists'

export interface FormOptInsList {
  formId?: string
  formTitle?: string
  listDisplayName?: string
  listId?: string
  latestOptInDate?: string
  optInCount: number
}

interface State {
  lists: FormOptInsList[]
  totalCount: number
  pageSize: number
  pageIndex: number
  sortBy: SortByState[]
  client: ApolloClient<any>
  [key: string]: any
}

const defaultSortBy: SortByState = {
  id: 'latestOptInDate',
  desc: true,
}

const defaultState = {
  lists: [],
  totalCount: 0,
  pageIndex: 0,
  pageSize: 10,
  sortBy: [defaultSortBy],
}

const convertAccessorToSort = (id: string) => {
  switch (id) {
    case 'formId':
      return 'FORM_ID'
    case 'formTitle':
      return 'FORM_TITLE'
    case 'optInCount':
      return 'OPT_IN_COUNT'
    case 'latestOptInDate':
      return 'LATEST_OPT_IN_DATE'
    case 'listType':
      return 'LIST_TYPE'
    case 'listDisplayName':
      return 'LIST_NAME'
    default:
      throw new Error(`Unknown accessor: ${id}`)
  }
}

export const FormOptInsListsContainer = () => {
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.SMS_MANAGEMENT })

  const [loading, setLoading] = useState(true)

  const [state, setState] = useState<State>({
    ...defaultState,
    client,
  })
  const { accountId } = useAccountSettings()

  const {
    data,
    error: pageError,
    loading: optInsFormsLoading,
  } = useQueryOnMount<Query, QueryGetOptInFormsArgs>(getOptInsForms, {
    client: state.client,
    fetchPolicy: 'network-only',
    variables: {
      accountId,
      size: state.pageSize,
      page: state.pageIndex,
      sortDirection: state.sortBy[0].desc ? 'DESC' : ('ASC' as any),
      sortColumn: convertAccessorToSort(state.sortBy[0].id) as any,
    },
  })

  useEffect(() => {
    setLoading(optInsFormsLoading)
  }, [optInsFormsLoading])

  useEffect(() => {
    if (data && data.getOptInForms && data.getOptInForms.optInForms?.length) {
      setState({ ...state, lists: data.getOptInForms.optInForms, totalCount: data.getOptInForms.totalCount })
    }
  }, [data])

  useEffect(() => {
    if (pageError) {
      logNewRelicError(pageError)
    }
  }, [pageError])

  if (pageError) {
    return (
      <PageContainer>
        <PageError center />
      </PageContainer>
    )
  }

  const fetchData = (pageIndex: number, pageSize: number) => {
    setState({ ...state, pageIndex, pageSize })
  }

  const onChangeSort = (sortBy: Array<any>) => {
    const validSortBy = sortBy.length > 0 ? sortBy : [defaultSortBy]
    setState({ ...state, sortBy: validSortBy })
  }

  return (
    <FormOptInsLists
      lists={state.lists}
      pageIndex={state.pageIndex}
      pageSize={state.pageSize}
      totalCount={state.totalCount}
      sortBy={state.sortBy}
      fetchData={fetchData}
      onChangeSort={onChangeSort}
      loading={loading}
    />
  )
}
