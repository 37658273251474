import { gql } from '@apollo/client'

export default gql`
  query fetchFormSubmissionsForCards($afterTimestamp: String) {
    fetchFormSubmissionsForCards(afterTimestamp: $afterTimestamp) {
      topForms {
        formId
        formName
        totalInserts
        totalUpdates
        totalViews
      }
      totalActiveForms
      totalForms
      totalSubmissions
      totalViews
    }
  }
`
