import React, { FC, useCallback, useEffect, useState } from 'react'

import Button, { ButtonType } from '@components/Button'
import { ModalBody, ModalFooter } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import Toggle from '@components/Toggle'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { SalesPermissionsResponse } from '@graphql/types/query-types'

interface SalesPermissionModalProps {
  isOpen: boolean
  permissions?: SalesPermissionsResponse
  onSave: (permissions: SalesPermissionsResponse) => void
  onCancel: () => void
  dataTest?: string
}

enum PermissionTypes {
  ACTON = 'Acton',
  PASSWORD = 'Password',
  SIGNATURE = 'Signature',
}

const rootClass = 'sales-permission-modal'

const SalesPermissionsModal: FC<SalesPermissionModalProps> = (props: SalesPermissionModalProps) => {
  const { permissions, isOpen, onSave, onCancel, dataTest = rootClass } = props
  const { t } = useTranslation()
  const [isTouched, setIsTouched] = useState<boolean>(false)
  const [_permissions, setPermissions] = useState<{ [key in PermissionTypes]: boolean }>({
    [PermissionTypes.ACTON]: !!permissions?.hideMyActonTab,
    [PermissionTypes.SIGNATURE]: !!permissions?.hideMySignatureTab,
    [PermissionTypes.PASSWORD]: !!permissions?.hidePasswordAndOrganizationTab,
  })

  useEffect(() => {
    if (isOpen) {
      setPermissions({
        [PermissionTypes.ACTON]: !!permissions?.hideMyActonTab,
        [PermissionTypes.SIGNATURE]: !!permissions?.hideMySignatureTab,
        [PermissionTypes.PASSWORD]: !!permissions?.hidePasswordAndOrganizationTab,
      })
      setIsTouched(false)
    }
  }, [permissions, isOpen])

  const handleSave = useCallback(
    () =>
      onSave({
        hideMyActonTab: _permissions.Acton,
        hidePasswordAndOrganizationTab: _permissions.Password,
        hideMySignatureTab: _permissions.Signature,
      }),
    [onSave, _permissions]
  )

  const onToggle = useCallback((key: PermissionTypes, val: boolean) => {
    setIsTouched(true)
    setPermissions((cur) => ({
      ...cur,
      [key]: val,
    }))
  }, [])

  return (
    <>
      <ModalBody>
        {Object.entries(_permissions).map(([key, value]) => (
          <div className={`${rootClass}__section`} key={key}>
            <div className={`${rootClass}__section-description`}>
              <Typography
                text={t(`Sales.User.Permissions.${key}.Title`)}
                type={TextType.SECTION_HEADER}
                className={`${rootClass}__section-description-title`}
              />
              <Typography text={t(`Sales.User.Permissions.${key}.Description`)} type={TextType.BODY_TEXT_LIGHT} lineHeight={LineHeight.MEDIUM} />
            </div>
            <div className={`${rootClass}__section-action`}>
              <Toggle
                large
                isOn={value}
                onToggle={(val) => {
                  onToggle(key as PermissionTypes, val)
                }}
              />
            </div>
          </div>
        ))}
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form}>
        <Button buttonType={ButtonType.TERTIARY} onClick={() => onCancel()} dataTest={`${dataTest}-cancel-button`}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} onClick={handleSave} disabled={!isTouched} dataTest={`${dataTest}-save-button`}>
          {t('Save')}
        </Button>
      </ModalFooter>
    </>
  )
}

export default SalesPermissionsModal
