import { gql } from '@apollo/client'

export default gql`
  mutation addMediaLink($sourceUrl: String!, $linkName: String!, $folderId: Float) {
    addMediaLink(sourceUrl: $sourceUrl, linkName: $linkName, folderId: $folderId) {
      status
      message
    }
  }
`
