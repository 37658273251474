import React, { FC, startTransition, useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import classNames from 'classnames'

import { setCRMCampaignsUtils, setSegmentsMetadataUtils } from '@complex/ContactSegments/segmentInfoHover.utils'
import { getFolderById } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { LinkTextButton } from '@components/LinkTextButton/LinkTextButton'
import { renderLoader } from '@components/Loader/Loader'
import PageContainer from '@components/PageContainer'
import PageError from '@components/PageError/PageError'
import { useSegmentDetailsQueries } from '@components/SegmentDetails/SegmentDetails.graphQL'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { Status } from '@components/StatusToast/StatusToast'
import { TOAST_TEXT_CLASSNAME } from '@components/Toast/Toast'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import { FolderDto, LabelDto } from '@graphql/types/microservice/categorization-types'
import { SegmentDtoInput } from '@graphql/types/microservice/segment-types'
import { Folder } from '@interface/Folder'
import { ListPageStatusToast } from '@interface/ListPage.context'
import { ABSplitSession } from '@src/pages/ContactSegments/components/ABSplit/ABSplit.constants'
import { useAddContactsFromCRMRequests } from '@src/pages/ContactSegments/components/AddContactsFromCRM/GraphQL/AddContactsFromCRMRequests.crm.graphQL'
import { COPY_SEGMENT_LOG_PARAM } from '@src/pages/ContactSegments/components/CopySegments/constants/CopySegments.constants'
import {
  getAllAccountProvisioningUtils,
  getModalTotalContactsUtils,
} from '@src/pages/ContactSegments/components/DataCardsContainer/utils/DataCardsContainerUtils'
import SegmentDetails from '@src/pages/ContactSegments/components/SegmentDetails/SegmentDetails'
import { useContactSegmentsCategorizationServiceRequest } from '@src/pages/ContactSegments/GraphQL/ContactSegments.categorization.graphQL'
import { useContactSegmentsCRMServiceRequests } from '@src/pages/ContactSegments/GraphQL/ContactSegments.crm.graphQL'
import { useContactSegmentsEntityUploadServiceRequest } from '@src/pages/ContactSegments/GraphQL/ContactSegments.entity-upload.graphQL'
import { useContactSegmentsListServiceRequest } from '@src/pages/ContactSegments/GraphQL/ContactSegments.list.graphQL'
import { useContactSegmentsSegmentServiceRequest } from '@src/pages/ContactSegments/GraphQL/ContactSegments.segment.graphQL'
import { showCopySegmentsBannerInitiallyUtils } from '@src/pages/ContactSegments/GraphQL/utils/ContactSegmentsRequests.categorization.utils'
import { getImportCountUtils } from '@src/pages/ContactSegments/GraphQL/utils/ContactSegmentsRequests.list.utils'
import { getHasToCopySegmentUtils, setHasToCopySegmentUtils } from '@src/pages/ContactSegments/GraphQL/utils/ContactSegmentsRequests.segment.utils'
import { deleteAllContactsUtils } from '@src/pages/ContactSegments/utils/Contacts.utils'
import { IMPORT_SOURCE, REFRESH_TIMER } from '@src/pages/ContactSegments/utils/ContactSegments.constants'
import {
  CloneSegmentServiceMessages,
  cloneSegmentUtils,
  deleteSegmentsUtils,
  deleteSyncedSegmentsUtils,
  getCRMSourcedSegmentDetailsUtils,
  getSegmentUtils,
  getTagItemsUtils,
  getUnifiedListFieldMappingsUtils,
  refreshRecordCountUtils,
  regenerateSplitsUtils,
  saveSortedSegments,
  setFavoriteItemsUtils,
  useMicroserviceClients,
} from '@src/pages/ContactSegments/utils/ContactSegmentsContainerUtils'
import { doSearchSegments } from '@src/pages/ContactSegments/utils/ContactSegmentsSearch.utils'
import {
  clearSegmentDetails,
  CONTACT_SEGMENTS_CURRENT_SECTION,
  loadInitialSessionData,
  loadLocationState,
} from '@src/pages/ContactSegments/utils/ContactSegmentsSession.utils'
import { setFilterUtils } from '@src/pages/ContactSegments/utils/FiltersUtils'
import {
  createFolderUtils,
  deleteFolderUtils,
  deleteFromFolderUtils,
  getFolderItemsUtils,
  getFoldersUtils,
  moveSegmentsToFolderUtils,
  renameFolderUtils,
  setFolderUtils,
} from '@src/pages/ContactSegments/utils/FoldersUtils'
import {
  applyTagsUtils,
  createTagUtils,
  deleteTagUtils,
  getItemTypesByTagIdUtils,
  getTagsUtils,
  removeTagsUtils,
  setTagUtils,
} from '@src/pages/ContactSegments/utils/TagUtils'
import { useSegmentFilters } from '@src/pages/ContactSegments/utils/useSegmentFilters'
import { IMPORT_CONTACTS_URL } from '@src/pages/importcontacts/utils/ImportContactsContainerUtils'
import { getFilteredFTPFiles } from '@src/pages/ImportContactsV2/utils/ImportContactsV2.utils'
import { Row } from '@tanstack/react-table'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemSubType, ItemType } from '@utils/categorization'
import {
  buildSegment,
  ContactSegmentsSession,
  ContactSegmentsState,
  PERIOD_GROUPED_BY,
  Segment,
  sortSegmentsUtils,
} from '@utils/contactSegments/contactSegments.utils'
import { ContactSegmentsContext, values } from '@utils/contactSegments/context/ContactSegmentsContext'
import { PERIOD_MODAL } from '@utils/contactSegments/dataCards.utils'
import { getSegmentDefinitionUtils } from '@utils/contactSegments/SegmentInfoHoverCardSegmentDefinitionUtils'
import { allSegmentsFilter, FilterDefinition, FilterTypes } from '@utils/filter'
import { useCRMService } from '@utils/hooks/microservices/useCRMService'
import useCRM from '@utils/hooks/useCRM'
import { EMPTY_LISTING_OPTIONS } from '@utils/ListPage.context.default'
import { logNewRelicError } from '@utils/new-relic.utils'
import { getItem, removeItem, setItem } from '@utils/sessionStorage'

import ContactSegments from './ContactSegments'

interface Props {
  dataTest?: string
}

export const SEGMENTS_PAGE_SIZE = 50

const rootClass = 'contact-segments'

const ContactSegmentsContainer: FC<Props> = () => {
  const { categorizationClient, crmClient, listClient, segmentClient } = useMicroserviceClients()
  const { getCRMNotSupportedEntitiesRequest, getCRMSegmentsInfoRequest, deleteSyncedSegmentsRequest } =
    useContactSegmentsCRMServiceRequests(crmClient)
  const { syncedSegmentDetailsRequest } = useAddContactsFromCRMRequests()
  const { getAllItemsRequest } = useContactSegmentsCategorizationServiceRequest()
  const { getHasToCopySegmentRequest, setHasToCopySegmentRequest } = useContactSegmentsSegmentServiceRequest()
  const { getImportCountRequest } = useContactSegmentsListServiceRequest()
  const { getFtpConnectionStatusRequest, getFtpFilesRequest } = useContactSegmentsEntityUploadServiceRequest()
  const { getSourceOptionsRequest } = useCRMService()

  const [allSegmentsEmptyListing, setAllSegmentsEmptyListing] = useState(EMPTY_LISTING_OPTIONS.EMPTY_NON_CRM_ALL_SEGMENTS)
  const [segmentsRefreshedCount, setSegmentsRefreshedCount] = useState<number>(0)
  const [containerValues, setContainerValues] = useState<ContactSegmentsState>({
    ...values,
    showUpgradeBanner: getItem(ContactSegmentsSession.UPGRADE_BANNER) === 'true' ?? false,
  })
  const {
    activeFolderId,
    activeTagId,
    currentPage,
    filterActive,
    folders,
    loading,
    loadingSessionData,
    pageError,
    search,
    searchAllItems: searchAllSegments,
    items: segments,
    showDetails,
    tags,
    importContactsSource,
    selectingFtpFile,
  } = containerValues
  const history = useHistory<{
    showDetails: Segment
    crmSourcedSegmentSaved?: boolean
    segmentsCopiedMessage?: { successCount: number; failedCount: number; inProgress: number }
    openCopySegmentHistoryLog?: boolean
  }>()
  const accountSettings = useAccountSettings()
  const { hasAutomatedProgramsUpgrade, hasCopySegments, getConnectedCrm } = accountSettings
  const { hasCRMConnected } = useCRM()
  const { getItemDetailsRequest } = useSegmentDetailsQueries()
  const segmentDetails = getItem(ContactSegmentsSession.SEGMENT_DETAILS)
  const isContactsUrl = history.location.pathname.includes('/contacts')

  const hasUCLInitialized =
    allSegmentsEmptyListing !== EMPTY_LISTING_OPTIONS.NOT_SET_CRM_DATA_MANAGEMENT &&
    allSegmentsEmptyListing !== EMPTY_LISTING_OPTIONS.NOT_SET_NON_CRM_DATA_MANAGEMENT

  const { t } = useTranslation()

  const update = (values: Partial<ContactSegmentsState>) => {
    startTransition(() => setContainerValues((containerValues) => ({ ...containerValues, ...values })))
  }

  const refreshRecordCount = (selectedSegments: string[], segmentWasEdited?: boolean) => {
    refreshRecordCountUtils(selectedSegments, setContainerValues, segmentClient, segmentWasEdited)
    setSegmentsRefreshedCount((segmentsRefreshedCount) => segmentsRefreshedCount + 1)
  }

  const { filterActions, updateSegmentsWithFtpData } = useSegmentFilters({ setContainerValues, allSegmentsEmptyListing, update, refreshRecordCount })

  useEffect(() => {
    const search = new URLSearchParams(window.location.search)
    const id = search.get('id') || undefined
    const externalId = search.get('externalId') || undefined
    getSourceOptionsRequest().then((sourceOptions) => {
      update({ crmSourceOptions: sourceOptions })
    })
    getFtpConnectionStatusRequest().then((status) => update({ isFtpConnectorActive: status }))
    if ((id || externalId) && isContactsUrl) {
      clearSegmentDetails(update)
      getItemDetailsRequest(ItemType.SEGMENT, id, externalId).then(({ data }) => {
        loadInitialSessionData(
          showDetails,
          data?.getItem ? JSON.stringify(buildSegment(data?.getItem)) : null,
          history,
          setContainerValues,
          categorizationClient,
          refreshRecordCount,
          update,
          setSectionBySessionData
        )
      })
    } else {
      loadInitialSessionData(
        showDetails,
        segmentDetails,
        history,
        setContainerValues,
        categorizationClient,
        refreshRecordCount,
        update,
        setSectionBySessionData
      )
    }
    if (hasCopySegments || hasAutomatedProgramsUpgrade) {
      getHasToCopySegmentUtils({ getHasToCopySegmentRequest, update }).finally(() => {
        showCopySegmentsBannerInitiallyUtils({ getAllItemsRequest, update })
        getImportCountUtils({ getImportCountRequest, update })
      })
    }
  }, [])

  useEffect(() => {
    if (!search) {
      setContainerValues((state) => ({ ...state, searchItemsResults: [], searchAllItems: undefined }))
    }
  }, [search])

  useEffect(() => {
    getUnifiedListFieldMappingsUtils(listClient).then(({ data }) => {
      const showFirstTimePage = data.unifiedListFieldMappings?.length === 0
      setAllSegmentsEmptyListing(() => {
        if (hasCRMConnected) {
          return showFirstTimePage ? EMPTY_LISTING_OPTIONS.NOT_SET_CRM_DATA_MANAGEMENT : EMPTY_LISTING_OPTIONS.EMPTY_CRM_ALL_SEGMENTS
        } else {
          return showFirstTimePage ? EMPTY_LISTING_OPTIONS.NOT_SET_NON_CRM_DATA_MANAGEMENT : EMPTY_LISTING_OPTIONS.EMPTY_NON_CRM_ALL_SEGMENTS
        }
      })
    })
  }, [hasCRMConnected])

  useEffect(() => {
    if (filterActive?.name === FilterTypes.ALL_SEGMENTS) {
      update({ emptyListingOption: allSegmentsEmptyListing })
    }
  }, [allSegmentsEmptyListing])

  useEffect(() => {
    if (segmentsRefreshedCount > 0) {
      let count = 2
      const interval = setInterval(() => {
        setSectionBySessionData(true)
        count--
        if (count === 0) {
          clearInterval(interval)
          setSegmentsRefreshedCount((segmentsRefreshedCount) => segmentsRefreshedCount - 1)
        }
      }, REFRESH_TIMER)
    }
  }, [segmentsRefreshedCount])

  useEffect(() => {
    if (segments.length && filterActive?.name !== FilterTypes.CONTACT_PREFERENCES) {
      setSegmentsMetadata()
      if (getConnectedCrm !== 'UNSET') {
        getCRMNotSupportedEntitiesRequest({ connectedCRM: getConnectedCrm })
          .then(({ data }) => {
            if (!!data) {
              const unsupportedTypes = data.crmDefinition?.unsupportedTypes?.map((type) => type?.name)
              if (unsupportedTypes?.every((type) => type !== 'Campaign')) {
                setCRMCampaigns()
              }
            }
          })
          .catch((error) => {
            logNewRelicError(error)
          })
      }
    }
  }, [segments])

  useEffect(() => {
    if (importContactsSource != '') {
      history.push(`${rootContext}${importContactsSource === IMPORT_SOURCE.FTP ? `/importContactsV2/${importContactsSource}` : IMPORT_CONTACTS_URL}`)
    }
  }, [importContactsSource])

  useEffect(() => {
    if (selectingFtpFile) {
      getFtpFilesRequest().then((data) => update({ ftpFiles: getFilteredFTPFiles(data), loadingFtpFiles: false }))
    }
  }, [selectingFtpFile])

  useEffect(() => {
    const incomingStatusToast = getItem(ContactSegmentsSession.INCOMING_STATUS_TOAST)
    if (incomingStatusToast != null) {
      const { statusMessage, ...statusToast }: ListPageStatusToast = JSON.parse(incomingStatusToast)
      setContainerValues((containerValues) => ({
        ...containerValues,
        statusToast: {
          ...statusToast,
          statusMessage: (
            <Typography text={statusMessage} tagProps={{ medium: { weight: TextWeight.MEDIUM }, bold: { weight: TextWeight.BOLD } }} inline />
          ),
        },
      }))
      removeItem(ContactSegmentsSession.INCOMING_STATUS_TOAST)
    }
  }, [])

  const refreshFilters = () => {
    getFoldersUtils(setContainerValues, categorizationClient)
    getTagsUtils(setContainerValues, categorizationClient)
  }

  const refreshSegments = (options?: { page?: number; needsReRender?: boolean }) => {
    const { page = 0, needsReRender = false } = { ...options }
    if (search) {
      doSearchSegments(!!searchAllSegments, filterActive, activeFolderId, activeTagId, search, update, categorizationClient, accountSettings)
    }
    const pageSize = page ? SEGMENTS_PAGE_SIZE : (currentPage + 1) * SEGMENTS_PAGE_SIZE
    filterActive && setFilter(filterActive, page, needsReRender, pageSize)
    activeTagId && setTag(activeTagId, page, needsReRender, pageSize)
    activeFolderId && setFolder(activeFolderId, page, needsReRender, pageSize)
  }

  const createFolder = (folder: FolderDto) => createFolderUtils(setContainerValues, containerValues, categorizationClient, folder, t)

  const renameFolder = (folder: FolderDto) => renameFolderUtils(setContainerValues, containerValues, categorizationClient, folder, t)

  const getItemTypesUsedInTags = (tagId: number) => getItemTypesByTagIdUtils(tagId, categorizationClient)

  const createTag = (tag: LabelDto) => {
    tags.some(({ id, name }) => tag.id !== id && tag.name === name)
      ? update({
          statusToast: {
            statusMessage: 'A tag with the same name already exists.',
            status: Status.FAIL,
            showStatusToast: true,
          },
        })
      : createTagUtils(setContainerValues, containerValues, categorizationClient, tag, refreshSegments)
  }

  const cloneSegment = async (segment: Segment, newSegmentName: string, segmentTagsToClone: LabelDto[], folderId?: number) => {
    const getStatusMessage = (message: string | undefined) => {
      if (message) {
        return `${t(
          message === CloneSegmentServiceMessages.DUPLICATE_SEGMENT_NAME_NOT_ALLOWED
            ? CloneSegmentServiceMessages.DUPLICATE_SEGMENT_NAME_NOT_ALLOWED
            : CloneSegmentServiceMessages.DEFAULT
        )}`
      }
      return (
        <div className={TOAST_TEXT_CLASSNAME}>
          <Typography text={`${segment.name} `} weight={TextWeight.BOLD} inline />
          <Typography text={t('has been duplicated.')} inline />
        </div>
      )
    }

    cloneSegmentUtils(segment, newSegmentName, setContainerValues, containerValues, segmentClient, segmentTagsToClone, folderId).then((result) => {
      if (result) {
        const message = result?.message
        update({
          statusToast: {
            statusMessage: getStatusMessage(message),
            status: message ? Status.FAIL : Status.SUCCESS,
            showStatusToast: true,
          },
        })

        if (!message) {
          refreshFilters()
          if (folderId) {
            setFolder(folderId)
          } else {
            setFilter(allSegmentsFilter)
          }
        }
        return message
      }
    })
  }

  const deleteSegments = async (segments: Segment[]) => {
    const sourcedSegments = segments.filter((segment) => segment?.subTypes?.includes(ItemSubType.CRM_SOURCED)).map(({ externalId }) => externalId)
    const segmentsDto = segments
      .filter((segment) => segment.isEditable && !segment?.subTypes?.includes(ItemSubType.CRM_SOURCED))
      .map((s: Segment) => {
        const { externalId, folderId, id, isFavorite, tags, position } = s
        return { externalId, folderId, id, isFavorite, labels: tags, position } as SegmentDtoInput
      })

    await deleteSyncedSegmentsUtils(deleteSyncedSegmentsRequest, sourcedSegments)

    deleteSegmentsUtils(segmentsDto, setContainerValues, segmentClient).then(() => {
      refreshSegments({ needsReRender: true })
      refreshFilters()
    })
  }

  const deleteFromFolder = (segments: Segment[]) =>
    deleteFromFolderUtils(
      segments.map((segment) => segment.id),
      setContainerValues,
      containerValues,
      categorizationClient,
      setSectionBySessionData
    )

  const deleteTag = (tag: LabelDto) => deleteTagUtils(tag.id, setContainerValues, categorizationClient, t, setFilter, setFolder)

  const deleteAllContacts = (segments: Segment[]) => {
    deleteAllContactsUtils(segments, setContainerValues, segmentClient)
  }

  const getFolderItems = (folderId?: number, page = 0, needsReRender?: boolean, pageSize = SEGMENTS_PAGE_SIZE) =>
    getFolderItemsUtils(
      updateSegmentsWithFtpData,
      setContainerValues,
      syncedSegmentDetailsRequest,
      getCRMSegmentsInfoRequest,
      hasCRMConnected,
      categorizationClient,
      page,
      pageSize,
      folderId,
      refreshRecordCount,
      needsReRender
    )

  const getTagItems = (tagId: number | undefined, page = 0, needsReRender?: boolean, pageSize = SEGMENTS_PAGE_SIZE) =>
    getTagItemsUtils(
      updateSegmentsWithFtpData,
      setContainerValues,
      syncedSegmentDetailsRequest,
      getCRMSegmentsInfoRequest,
      hasCRMConnected,
      categorizationClient,
      page,
      pageSize,
      tagId as number,
      refreshRecordCount,
      accountSettings,
      needsReRender
    )

  const setCampaigns = (campaigns: any) => {
    setContainerValues((state) => ({ ...state, campaigns: { ...state.campaigns, ...campaigns } }))
  }
  const setSegmentsMetadata = () => {
    setSegmentsMetadataUtils(update, segmentClient, setCampaigns) //update2
  }

  const setCRMCampaigns = () => {
    setCRMCampaignsUtils(update, crmClient, setCampaigns) //update2
  }

  const moveSegmentsIntoFolder = (segments: Segment[], folderId: number, addToFolder: boolean) => {
    const cannotMove = segments.every((segment) => segment.folderId === folderId)
    if (cannotMove) {
      const folderName = getFolderById(folderId, folders)?.name
      const statusMessage = (
        <div className={TOAST_TEXT_CLASSNAME}>
          <Typography
            text={`${t(`Segment${segments.length === 1 ? ' is' : 's are'} already in target folder`)}`}
            inline
            className={`${rootClass}__toast-folder-move`}
          />
          <Typography text={folderName} weight={TextWeight.MEDIUM} inline />
        </div>
      )
      update({
        statusToast: {
          statusMessage,
          showStatusToast: true,
          status: Status.WARNING,
        },
      })
    } else {
      const segmentsIds = segments.map((segment) => segment.id)
      moveSegmentsToFolderUtils(
        segmentsIds,
        folderId,
        addToFolder,
        setContainerValues,
        containerValues,
        categorizationClient,
        t,
        setSectionBySessionData
      )
    }
  }

  const regenerateSplits = (id: string) => {
    regenerateSplitsUtils(id, segmentClient)
      .then(() => {
        refreshSegments()
        setContainerValues((containerValues) => ({
          ...containerValues,
          statusToast: {
            statusMessage: `Splits successfully regenerated`,
            status: Status.SUCCESS,
            showStatusToast: true,
          },
        }))
      })
      .catch((error) => {
        setContainerValues((containerValues) => ({
          ...containerValues,
          statusToast: {
            statusMessage: 'Something went wrong on our end. Please try again.',
            status: Status.FAIL,
            showStatusToast: true,
          },
        }))
        logNewRelicError(error)
      })
  }

  const getDataCardModalInfo = (amount: number, groupBy: PERIOD_GROUPED_BY, caller: PERIOD_MODAL) => {
    getAllAccountProvisioningUtils(segmentClient, amount, groupBy, setContainerValues, caller)
  }

  const getTotalContactsModalInfo = (amount: number, groupBy: PERIOD_GROUPED_BY, caller: PERIOD_MODAL) => {
    getModalTotalContactsUtils(segmentClient, amount, groupBy, setContainerValues, caller)
  }

  const getSegment = (segmentId: number, type = ItemType.SEGMENT) => getSegmentUtils(segmentId, type, setContainerValues, categorizationClient)

  const setFavoriteSegments = (itemsIds: number[], isFavorite: boolean, itemType = ItemType.SEGMENT) =>
    setFavoriteItemsUtils(itemsIds, isFavorite, setContainerValues, containerValues, categorizationClient, itemType)

  const closeStatusToast = () => {
    setContainerValues((containerValues) => ({
      ...containerValues,
      statusToast: { ...containerValues.statusToast, showStatusToast: false },
    }))
    setItem(ABSplitSession.TOAST, '')
  }

  const deleteFolder = (folder: FolderData) => {
    const { id, parentId } = folder
    deleteFolderUtils(id, setContainerValues, categorizationClient, t).then(() => {
      if (activeFolderId) {
        const refreshSegments =
          activeFolderId === id ||
          activeFolderId === parentId ||
          (folder.subFolders && !!getFolderById(activeFolderId, folder.subFolders as Folder[]))

        if (refreshSegments) {
          parentId ? setFolder(parentId) : setFilter(allSegmentsFilter)
        }
      }
      refreshSegments({ needsReRender: true })
    })
  }

  const setFolder = (folderId: number, page?: number, needsReRender?: boolean, pageSize?: number) => {
    getFolderItems(folderId, page, needsReRender, pageSize)
    setFolderUtils(folderId, folders, setContainerValues)
  }

  const setTag = (tagId: number, page?: number, needsReRender?: boolean, pageSize?: number) => {
    getTagItems(tagId, page, needsReRender, pageSize)
    setTagUtils(tagId, setContainerValues)
  }

  const sortSegments = (selectedRows: Row<Segment>[], droppedOnRow: Row<Segment>, above: boolean, parentRowId?: string) => {
    if (filterActive?.name === allSegmentsFilter.name) {
      const { original, index } = droppedOnRow
      const { position } = original as Segment
      const selectedSegments = selectedRows.map(({ original }) => original as Segment)
      const selectedSegmentsIds = selectedSegments.map(({ id }) => id)

      setContainerValues((state) => ({
        ...state,
        items: sortSegmentsUtils(selectedSegments, above ? index : index + 1, parentRowId, segments),
      }))
      saveSortedSegments(selectedSegmentsIds, above ? position : position + 1, setContainerValues, categorizationClient).then(() => {
        refreshSegments({ needsReRender: true })
      })
    } else {
      const section = filterActive ? 'filter' : activeFolderId ? 'folder' : 'tag'
      update({
        statusToast: {
          statusMessage: `${t(`You can not reorder segments within a ${section}.`)} ${t(
            'Change the order in All Segments and it will be reflected here.'
          )}`,
          status: Status.WARNING,
          showStatusToast: true,
        },
      })
    }
  }

  const getFtpSyncJobsList = (segment: Segment) => {
    const ftpSyncJobs = segment.ftpData?.import.syncJobs ?? []
    update({ manageFtpSyncSegmentId: segment.externalId, ftpSyncJobs })
    if (ftpSyncJobs.length === 1) {
      window.open(`${rootContext}/importContactsV2/${IMPORT_SOURCE.FTP}/${ftpSyncJobs[0].importSyncJobId}/options`, '_self')
    }
  }

  const getFtpExportSyncJobsList = (segment: Segment) => {
    const ftpExportSyncJobs = segment.ftpData?.export.syncJobs ?? []
    update({ selectingFtpExports: true, selectingExportAction: false, ftpExportSyncJobs })
    if (ftpExportSyncJobs.length === 1) {
      window.open(`${rootContext}/exportContacts/${ftpExportSyncJobs[0].exportSyncJobId}/options`, '_self')
    }
  }

  const setFilter = (filter: FilterDefinition, page?: number, needsReRender?: boolean, pageSize = SEGMENTS_PAGE_SIZE) => {
    const name = filter.name as
      | FilterTypes.ALL_SEGMENTS
      | FilterTypes.CONTACT_PREFERENCES
      | FilterTypes.CREATED_BY_ME
      | FilterTypes.CRM_SOURCED
      | FilterTypes.FAVORITES
      | FilterTypes.RECENT
    filterActions[name](page, pageSize, needsReRender)
    setFilterUtils(filter, setContainerValues)
  }

  const applyAndRemoveTags = async (segments: number[], tagsToApply: LabelDto[], tagsToRemove: number[]) => {
    try {
      const tagsApplied = await applyTagsUtils(setContainerValues, containerValues, categorizationClient, segments, tagsToApply, t)
      if (tagsApplied) {
        await removeTagsUtils(setContainerValues, containerValues, categorizationClient, segments, tagsToRemove, t)
      }
      getTagsUtils(setContainerValues, categorizationClient)
      refreshSegments({ needsReRender: true })
      return true
    } catch {
      return false
    }
  }

  /**
   * Sets the section based on session data stored in session storage.
   * Depending on the current section, it retrieves the corresponding data
   * and updates the state accordingly.
   *
   * @param {boolean} [needsReRender] - Optional flag to indicate if a re-render is needed.
   */
  const setSectionBySessionData = (needsReRender?: boolean) => {
    const currentSection = getItem(CONTACT_SEGMENTS_CURRENT_SECTION)
    if (currentSection) {
      const sectionData = getItem(currentSection)
      if (sectionData) {
        const sections: { [key: string]: VoidFunction } = {
          [ContactSegmentsSession.FOLDER]: () => setFolder(parseInt(sectionData), undefined, needsReRender),
          [ContactSegmentsSession.FILTER]: () => setFilter(JSON.parse(sectionData), undefined, needsReRender),
          [ContactSegmentsSession.TAG]: () => setTag(parseInt(sectionData), undefined, needsReRender),
        }
        sections[currentSection]?.()
      }
    }
  }

  const getCRMSourcedSegmentDetails = (segmentExternalIds: string[]) =>
    getCRMSourcedSegmentDetailsUtils(segmentExternalIds, syncedSegmentDetailsRequest)

  const getSegmentDefinition = (segmentExternalId: string) => getSegmentDefinitionUtils(segmentExternalId, setContainerValues, segmentClient)

  const doFinishCopySegments = () => setHasToCopySegmentUtils({ setHasToCopySegmentRequest, update })

  const openCopySegmentHistoryLog = () => {
    history.push(`${rootContext}/segments/copy?${COPY_SEGMENT_LOG_PARAM}`)
  }

  useEffect(() => {
    history.listen(() => loadLocationState(history, update))
    loadLocationState(history, update)
    if (history.location.state?.crmSourcedSegmentSaved !== undefined) {
      const { location } = history
      const state = { ...location.state }
      const isSyncNowTriggered = state.crmSourcedSegmentSaved
      delete state.crmSourcedSegmentSaved
      history.replace({ ...location, state })
      update({
        statusToast: {
          statusMessage: (
            <div>
              <Typography
                text={t(
                  isSyncNowTriggered
                    ? 'Segment saved and CRM sync initiated. Contacts will populate after CRM sync is complete.'
                    : 'Segment saved. Contacts will populate after next CRM sync.'
                )}
                className={classNames(TOAST_TEXT_CLASSNAME, `${rootClass}__crm-sourced-sync-toast-text`)}
              />
            </div>
          ),
          status: Status.SUCCESS,
          showStatusToast: true,
        },
      })
    }

    if (history.location.state?.segmentsCopiedMessage !== undefined) {
      const { location } = history
      const state = { ...location.state }
      const { successCount = 0, failedCount = 0, inProgress = 0 } = state.segmentsCopiedMessage || {}

      history.replace({ ...location, state: { ...history.location.state, segmentsCopiedMessage: undefined } })
      update({
        statusToast: {
          status: Status.SUCCESS,
          showStatusToast: true,
          statusMessage: (
            <div className={classNames(TOAST_TEXT_CLASSNAME, 'copy-segments__status-toast')}>
              <Typography text={`Copy.Segments.StatusToast.SuccessfullyCopiedSegments`} values={{ count: successCount }} inline />
              {failedCount > 0 && <Typography text={` ${t(`Copy.Segments.StatusToast.FailedCopiedSegments`, { count: failedCount })}`} inline />}
              {inProgress > 0 && <Typography text={` ${t(`Copy.Segments.StatusToast.InProgressCopiedSegments`, { count: inProgress })}`} inline />}
              <div>
                <Typography
                  text={t(`Copy.Segments.StatusToast.SeeLogs`)}
                  tagComponents={{
                    LinkTextButton: <LinkTextButton hideIcons onClick={openCopySegmentHistoryLog} />,
                  }}
                  inline
                />
              </div>
            </div>
          ),
        },
      })
    }
  }, [])

  if (pageError) {
    return (
      <PageContainer>
        <PageError center />
      </PageContainer>
    )
  }

  return (
    <ContactSegmentsContext.Provider
      value={{
        values: containerValues,
        applyAndRemoveTags,
        cloneSegment,
        createFolder,
        renameFolder,
        createTag,
        getItemTypesUsedInTags,
        getDataCardModalInfo,
        getTotalContactsModalInfo,
        deleteSegments,
        deleteAllContacts,
        deleteFromFolder,
        update,
        setFavoriteItems: setFavoriteSegments,
        getItem: getSegment,
        refreshRecordCount,
        moveItemsIntoFolder: moveSegmentsIntoFolder,
        closeStatusToast,
        deleteFolder,
        deleteTag,
        setFolder,
        setTag,
        setFilter,
        refreshSegments,
        sortSegments,
        getCRMSourcedSegmentDetails,
        getSegmentDefinition,
        regenerateSplits,
        doFinishCopySegments,
        getFtpSyncJobsList,
        getFtpExportSyncJobsList,
      }}
    >
      {showDetails ? (
        <SegmentDetails segment={showDetails} />
      ) : segmentDetails || isContactsUrl || loadingSessionData ? (
        renderLoader()
      ) : (
        <ContactSegments loading={loading} hasUCLInitialized={hasUCLInitialized} />
      )}
    </ContactSegmentsContext.Provider>
  )
}

export default ContactSegmentsContainer
