import { Action } from '@interface/Action'
import { hasAOContactsSource as getHasAOContactsSource } from '@utils/program/program'
import { LoadProgramCompletePayload, LoadProgramErrorPayload, UpdateProgramPayload } from '@utils/program/programActions'
import actionTypes from '@utils/program/programActionTypes'

import './sagas'

import { EditProgramState } from '@utils/program/program.constants'

const initialState: EditProgramState = {
  loading: false,
}

export function editProgram(state = initialState, action: Action<any>) {
  let program
  let hasAOContactsSource

  switch (action.type) {
    case actionTypes.loadProgram:
      return {
        ...state,
        saveStatus: undefined,
        program: undefined,
        error: undefined,
        loading: true,
      }
    case actionTypes.loadProgramComplete:
      program = (action.payload as LoadProgramCompletePayload).program
      hasAOContactsSource = getHasAOContactsSource(program)
      const tracks = program.tracks?.map((track) => ({
        ...track,
        steps: track.steps.filter((step, pos, self) => {
          return (
            self.findIndex((t) => {
              return t.stepId === step.stepId
            }) === pos
          )
        }),
      }))
      const updatedProgram = { ...program, tracks }

      return {
        ...state,
        program: updatedProgram,
        loading: false,
        hasAOContactsSource: hasAOContactsSource,
      }
    case actionTypes.setHasAOContactsSource:
      program = (action.payload as LoadProgramCompletePayload).program
      hasAOContactsSource = getHasAOContactsSource(program)
      return {
        ...state,
        hasAOContactsSource,
      }
    case actionTypes.saveProgram:
      return {
        ...state,
        saveStatus: undefined,
      }
    case actionTypes.saveProgramComplete:
      return {
        ...state,
        saveStatus: action.payload,
      }
    case actionTypes.saveProgramError:
    case actionTypes.loadProgramError:
      return {
        ...state,
        error: (action.payload as LoadProgramErrorPayload).error,
        loading: false,
      }
    case actionTypes.updateProgram:
      return {
        ...state,
        program: (action.payload as UpdateProgramPayload).program,
      }
    case actionTypes.clearProgramStatus:
      return {
        ...state,
        saveStatus: undefined,
      }
    case actionTypes.saveProgramFinalComplete:
      return {
        ...state,
        saveStatus: undefined,
        saveFinal: true,
        newProgramId: action.payload.newProgramId,
      }
    case actionTypes.saveProgramFinalError:
      return {
        ...state,
        saveStatus: undefined,
        saveFinal: false,
      }
    case actionTypes.clearFinalSaveStatus:
      return {
        ...state,
        saveFinal: undefined,
        newProgramId: undefined,
      }
    case actionTypes.createProgramMessage: {
      return {
        ...state,
        createdMessage: {
          loading: true,
        },
      }
    }
    case actionTypes.createProgramMessageRequest: {
      return {
        ...state,
        createdMessage: {
          loading: true,
        },
      }
    }
    case actionTypes.createProgramMessageComplete:
      return {
        ...state,
        createdMessage: {
          message: action.payload,
        },
      }
    case actionTypes.createProgramMessageError: {
      return {
        ...state,
        createdMessage: {
          error: action.payload,
        },
      }
    }
    case actionTypes.clearProgramMessage: {
      return {
        ...state,
        createdMessage: undefined,
      }
    }
    default:
      return state
  }
}

export default editProgram
