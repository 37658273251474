import React, { FC, ReactNode, useState } from 'react'

import classNames from 'classnames'

import './CollapsibleMenuItemHoverMenu.css'

interface Props {
  className?: string
  dataTest?: string
  content: ReactNode
  itemActive?: boolean
  trigger: ReactNode
}

const rootClass = 'collapsible-menu-item-hover-menu'

const CollapsibleMenuItemHoverMenu: FC<Props> = (props: Props) => {
  const { content, itemActive = false, trigger, dataTest = rootClass, className = '' } = props
  const [hover, setHover] = useState(false)

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <div className={classNames(`${rootClass}__trigger`, { [`${rootClass}__trigger-active`]: itemActive })}>{trigger}</div>
      {hover && (
        <div className={`${rootClass}__content-wrapper`}>
          <div className={`${rootClass}__content`}>{content}</div>
        </div>
      )}
    </div>
  )
}

export default CollapsibleMenuItemHoverMenu
