import React, { createRef, FC, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

import { bindActionCreators } from 'redux'

import { legacyActonContext, rootContext } from '@const/globals'
import IFrame from '@src/components/IFrame'
import { AccountState } from '@utils/account/reducer'
import documentUtils from '@utils/document'
import iframes from '@utils/iframe/iframe'
import actions, { FrameViewActions } from '@utils/iframe/state/actions'
import mapStateToProps, { FrameViewStateProps } from '@utils/iframe/state/mapStateToProps'

import iframeViewUtils, { buildIFrameViewUrl } from './iframeViewUtils'

interface FrameViewProps extends FrameViewActions, FrameViewStateProps {
  account: AccountState
  navigationVisible: boolean
}

export const IFrameView: FC<FrameViewProps> = (props: FrameViewProps) => {
  const {
    account,
    navigationVisible,
    iframeView: { visible },
    setIframeVisibility,
  } = props
  const { accountSettings, isAuthorized, pendingIframeMessage, results } = account
  const history = iframeViewUtils.useHistory()
  const iframeRef = createRef<HTMLIFrameElement>()

  const [iframeUrl, setIframeUrl] = useState<string | null>(null)

  const iframeUrlRef = useRef(iframeUrl)
  iframeUrlRef.current = iframeUrl

  const updateIframeUrl = () => {
    const isError = results?.error !== undefined
    let iframeUrl
    if (isError) {
      iframeUrl = {
        iframeUrl: `${legacyActonContext}/account/login.jsp`,
      }
    } else {
      iframeUrl = iframeViewUtils.getIframeUrl(account, visible)
    }
    setIframeUrl(iframeUrl.iframeUrl)
    if (iframeUrl.setIframeVisibility !== undefined) {
      setIframeVisibility(iframeUrl.setIframeVisibility)
    }
  }

  useEffect(() => {
    updateIframeUrl()
  }, [])

  useEffect(() => {
    updateIframeUrl()
  }, [accountSettings, isAuthorized, results])

  useEffect(() => {
    const root = documentUtils.getElementById('root')
    root?.classList.add(`root--iframe-${visible ? 'visible' : 'hidden'}`)
    root?.classList.remove(`root--iframe-${!visible ? 'visible' : 'hidden'}`)
  }, [visible])

  useEffect(() => {
    if (pendingIframeMessage && accountSettings) {
      if (iframeUrl === null) {
        const internalAddress = iframeViewUtils.getInternalAddressFromCurrent(
          accountSettings.isMicrosoftStartPage,
          `${rootContext}/classic/`,
          true,
          accountSettings.userSettings.userDesktop
        )
        setIframeUrl(`${legacyActonContext}/ng-ui/${internalAddress}`)
      }
    }
  }, [pendingIframeMessage, visible])

  useEffect(() => {
    return history.listen((location) => {
      if (!accountSettings) return false

      const internalAddress = iframeViewUtils.getInternalAddressFromCurrent(
        accountSettings.isMicrosoftStartPage,
        location.pathname + location.search,
        false,
        accountSettings.userSettings.userDesktop
      )
      if (internalAddress === null) {
        setIframeVisibility(false)
      } else {
        setIframeVisibility(true)
        if (iframeUrlRef.current !== null) {
          iframes.postMessage({
            actonNavigate: internalAddress,
          })
        }
        updateIframeUrl()
      }
      return true
    })
  }, [history])

  const useIframeUrl = buildIFrameViewUrl(iframeUrl as string)

  return <IFrame id="root-iframe" src={useIframeUrl} navigationVisible={navigationVisible} forwardedRef={iframeRef} />
}

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: any) => bindActionCreators({ ...actions }, dispatch)

IFrameView.displayName = 'IFrameView'
export default connect(mapStateToProps, mapDispatchToProps)(IFrameView)
