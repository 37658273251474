import React, { ChangeEvent, FC, Fragment, useContext, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Input from '@components/Input/Input'
import Select from '@components/Select/Select'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { StandardField } from '@graphql/types/microservice/list-types'
import ScoreSheetRow from '@src/pages/datamanagement/components/CrmContacts/ScoreSheetRow/ScoreSheetRow'
import { SchemaUpdateAction } from '@src/pages/datamanagement/components/NonCrmContacts/NonCrmContacts'
import { isMappingRequired } from '@src/pages/datamanagement/components/NonCrmContacts/NonCrmContacts.utils'
import { SchemaType } from '@src/pages/datamanagement/components/NonCrmContacts/SchemaEditor/SchemaEditor'
import { DataManagementContext, Row } from '@src/pages/datamanagement/context/DataManagementContext'
import { DataTypeList } from '@src/pages/importcontacts/context/ImportContactsContext'
import { FieldValidationState } from '@utils/crm.utils'

import './FieldMappingRow.css'

type Props = {
  className?: string
  dataTest?: string
  schemaType?: SchemaType
  handleInsert: any
  handleUpdate: any
  handleDelete: any
  row?: Row
  newRow?: boolean
  isHeader?: boolean
  standardFieldOptions?: StandardField[]
}

const rootClass = 'field-mapping-row'

const FieldMappingRow: FC<Props> = (props: Props) => {
  const {
    dataTest = rootClass,
    className = '',
    schemaType = SchemaType.STANDARD,
    handleInsert,
    handleDelete,
    handleUpdate,
    row = { id: -1, mapping: {}, status: FieldValidationState.OK },
    isHeader = false,
    standardFieldOptions = [],
    newRow = false,
  } = props

  const {
    values: { scoreSheetsList, standardFieldsMap },
  } = useContext(DataManagementContext)

  const { t } = useTranslation()

  const [tempDataType, setTempDataType] = useState<string>('')

  const isRequired = isMappingRequired(row.mapping, standardFieldsMap)
  const isRemovable = !newRow && !isRequired
  const isStandardField = schemaType === SchemaType.STANDARD

  // Creates the standard field options for the row if needed
  const getStandardFieldOptions = () => {
    return standardFieldOptions.map((field: StandardField) => {
      return (
        <option value={field.key} key={`${field.key}-option`}>
          {field.label}
        </option>
      )
    })
  }

  const getErrorMessage = (status: FieldValidationState) => {
    switch (status) {
      case FieldValidationState.DUP_STD:
        return t('Select each Field only once')
      case FieldValidationState.DUP_CUST:
        return t('Field name already exists')
      case FieldValidationState.CUST_MATCH_STD:
        return t('Please pick a name that is not already used in Standard Fields')
      default:
        return ''
    }
  }

  const onInputBlur = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    if (value && value.length > 0)
      if (newRow) {
        if (tempDataType !== '') {
          const rowWithDataType = {
            ...row,
            mapping: { ...row.mapping, dataType: tempDataType },
          }
          handleInsert(rowWithDataType, SchemaUpdateAction.CUSTOM_NAME, value)
          setTempDataType('')
        } else {
          handleInsert(row, SchemaUpdateAction.CUSTOM_NAME, value)
        }
      } else {
        handleUpdate(row, SchemaUpdateAction.CUSTOM_NAME, value)
      }
  }

  const onSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const updateAction = isStandardField ? SchemaUpdateAction.STANDARD : SchemaUpdateAction.CUSTOM_DATATYPE
    if (newRow) {
      if (isStandardField) {
        handleInsert(row, updateAction, event.target.value)
      } else {
        setTempDataType(event.target.value)
      }
    } else {
      if (!isStandardField || !isRequired) {
        handleUpdate(row, updateAction, event.target.value)
      }
    }
  }

  const renderRowDataType = (dataType?: string) => {
    if (schemaType === SchemaType.STANDARD) {
      return <Typography dataTest={`${dataTest}-datatype-label`} type={TextType.BODY_TEXT_GRAY} text={dataType} />
    }
    return (
      <Select
        dataTest={`${dataTest}-datatype-select`}
        name={'customDataType'}
        defaultValue={newRow ? tempDataType : row.mapping.dataType}
        onChange={onSelectChange}
      >
        <option disabled value={''}>
          {t('Data Type')}
        </option>
        {DataTypeList.map((dataType, index) => (
          <option value={dataType.id} key={`option-${index}`}>
            {dataType.label}
          </option>
        ))}
      </Select>
    )
  }

  const renderRowSelect = () => {
    if (schemaType === SchemaType.STANDARD) {
      return (
        <Select
          dataTest={`${dataTest}-standard-field-select`}
          disabled={isRequired}
          name={'standardField'}
          className={row.status !== FieldValidationState.OK ? `${rootClass}__error-field` : ''}
          defaultValue={newRow ? '' : row.mapping.standardFieldKey}
          onChange={onSelectChange}
        >
          <option disabled value={newRow ? '' : row.mapping.standardFieldKey}>
            {newRow ? t('Standard field') : row.mapping.displayName}
          </option>
          {getStandardFieldOptions()}
        </Select>
      )
    }
    return (
      <Input
        dataTest={`${dataTest}-custom-field-input`}
        defaultValue={newRow ? '' : row.mapping.displayName}
        placeholder={t('Custom field name')}
        name={'customField'}
        className={row.status !== FieldValidationState.OK ? `${rootClass}__error-field` : ''}
        onBlur={onInputBlur}
      />
    )
  }

  const buildRow = () => {
    const dataTypeLabel = schemaType === SchemaType.STANDARD ? DataTypeList.find((type) => type.id === row.mapping.dataType)?.label ?? '' : undefined
    return (
      <Fragment key={`field-row-${row.id}`}>
        <div className={classNames(`${rootClass}__row`, { [`${rootClass}__row-custom`]: schemaType === SchemaType.CUSTOM })}>
          <div className={`${rootClass}__left`}>{renderRowSelect()}</div>
          <div className={`${rootClass}__right-data`}>
            {renderRowDataType(dataTypeLabel)}
            <div
              aria-hidden={!isRemovable}
              className={classNames(`${rootClass}__delete-button`, { [`${rootClass}__delete-button-required`]: !isRemovable })}
            >
              <Button buttonType={ButtonType.REMOVE} onClick={() => isRemovable && handleDelete(row)}>
                <Svg name={SvgNames.delete} type={SvgType.LARGER_ICON} />
              </Button>
            </div>
          </div>
        </div>
        {row.status !== FieldValidationState.OK && (
          <Typography text={getErrorMessage(row.status)} type={TextType.ERROR} className={`${rootClass}__error`} />
        )}
        {scoreSheetSelector()}
      </Fragment>
    )
  }

  const scoreSheetSelector = () => {
    const existingMetadata = row.mapping.typeMetaData ? JSON.parse(row.mapping.typeMetaData) : undefined

    if (row.id !== -1 && row.mapping.dataType === 'SCORE') {
      return (
        <div className={`${rootClass}__scoresheet-selector-container`}>
          <ScoreSheetRow
            className={`${rootClass}__scoresheet-selector`}
            updateRowMetadata={updateRowMetadata}
            existingMetadata={existingMetadata}
            scoresheetsList={scoreSheetsList}
            relatedRowId={row.id}
            isShort
          />
        </div>
      )
    }
  }

  const updateRowMetadata = (metadata: any) => {
    handleUpdate(row, SchemaUpdateAction.CUSTOM_SCORESHEET, metadata, true)
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {isHeader ? (
        <div className={classNames(`${rootClass}__row`, { [`${rootClass}__row-custom`]: schemaType === SchemaType.CUSTOM })}>
          <div className={classNames(`${rootClass}__header`, `${rootClass}__left`)}>
            <Typography
              text={t(schemaType === SchemaType.STANDARD ? 'Act-On Standard Fields' : 'Act-On Custom Fields')}
              type={TextType.TABLE_HEADER}
            />
          </div>
          <div className={classNames(`${rootClass}__header`, `${rootClass}__right`)}>
            <Typography text={t('Data Type')} type={TextType.TABLE_HEADER} />
            <Tooltip
              className={`${rootClass}__tooltip`}
              trigger={<Svg name={SvgNames.questionMark} className={`${rootClass}__tooltip-icon`} type={SvgType.ICON} />}
              link={'#'}
              linkText={t('More info')}
              position={'top'}
            >
              {t("We will validate your data against the field's assigned data type.")}
            </Tooltip>
          </div>
        </div>
      ) : (
        buildRow()
      )}
    </div>
  )
}

export default FieldMappingRow
