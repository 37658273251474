import { gql } from '@apollo/client'

export default gql`
  query accountData($parentAccountId: Long!, $userId: Long!) {
    accountData(parentAccountId: $parentAccountId, userId: $userId) {
      childrenActiveContacts
      currentChildAccounts
      parentActiveContacts
      totalActiveContacts
      totalChildAccounts
      totalUsedContacts
      usedContacts {
        accountId
        usedContacts
      }
    }
  }
`
