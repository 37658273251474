import { getUUID } from '@const/globals'
import { Program, ProgramExitInput, ProgramInput, ProgramStepInput } from '@graphql/types/query-types'
import { ProgramStepType } from '@utils/program/program.constants'

export const cleanProgram = (program: Program): ProgramInput => {
  const tracks = program.tracks.map((track) => {
    const steps = track.steps
      .filter((step: ProgramStepInput) => step.stepType !== ProgramStepType.START)
      .map((step: ProgramStepInput) => ({
        stepType: step.stepType,
        stepId: step.stepType === ProgramStepType.GOTO && step.stepId?.includes('exit') ? `newstep${getUUID()}` : step.stepId,
        displayName: step.displayName,
        track: step.track,
        depth: step.depth,
        msecDelay: step.msecDelay,
        delay: step.delay,
        delayUnit: step.delayUnit,
        schedule: step.schedule,
        srcId: step.srcId,
        not: step.not,
        waitForScheduledRun: step.waitForScheduledRun,
        defaultMsgId: step.defaultMsgId,
        sendMode: step.sendMode,
        abThreshold: step.abThreshold,
        sendChoices: step.sendChoices,
        clicks: step.clicks,
        abWinner: step.abWinner,
        all: step.all,
        listId: step.listId,
        update: step.update,
        updateType: step.updateType,
        pushToSf: step.pushToSf,
        overrideSystemMap: step.overrideSystemMap,
        overrideSystemMapField: step.overrideSystemMapField,
        pruneRecords: step.pruneRecords,
        meetingId: step.meetingId,
        sessionKey: step.sessionKey,
        serviceType: step.serviceType,
        webinarKey: step.webinarKey,
        canPushSF: step.canPushSF,
        conditions: step.conditions,
        operations: step.operations,
        rule: step.rule,
        fieldName: step.fieldName,
        value: step.value,
        goToStepId: step.goToStepId,
        condition: step.condition,
        testSrcId: step.testSrcId,
        message: step.message,
        subject: step.subject,
        userIds: step.userIds,
        emails: step.emails,
        crmOwner: step.crmOwner,
        method: step.method,
        ownerId: step.ownerId,
        ownerName: step.ownerName,
        comment: step.comment,
        status: step.status,
        priority: step.priority,
        dateUnit: step.dateUnit,
        dateAmount: step.dateAmount,
        disabled: step.disabled,
        pushNewRecords: step.pushNewRecords,
        pushType: step.pushType,
        updateExisting: step.updateExisting,
        useListPushPolicy: step.useListPushPolicy,
        createType: step.createType,
        additionalEntities: step.additionalEntities
          ? step.additionalEntities.map((entity) => ({
              entityType: entity.entityType,
              entityRefKey: entity.entityRefKey,
              entitySingular: entity.entitySingular,
              requiredFields: entity.requiredFields,
              fields: entity.fields
                ? entity.fields.map((field) => ({ key: field ? field.key : undefined, value: field ? field.value : undefined }))
                : undefined,
            }))
          : undefined,
        staticFields: step.staticFields
          ? step.staticFields.map((field) => ({ key: field ? field.key : undefined, value: field ? field.value : undefined }))
          : undefined,
        updateMultipleMatches: step.updateMultipleMatches,
        insertAlways: step.insertAlways,
        dynamicCampaign: step.dynamicCampaign,
        format: step.format,
        headers: step.headers,
        endpoints: step.endpoints,
        custom: step.custom,
        httpHeaders: step.httpHeaders,
        url: step.url,
        campaignName: step.campaignName,
        campaignId: step.campaignId,
        scoreSheetId: step.scoreSheetId,
        hideContactData: step.hideContactData,
        newCampaignStatus: step.newCampaignStatus,
        smsMessageId: step.smsMessageId,
        subscribeCategories: step.subscribeCategories,
        unsubscribeCategories: step.unsubscribeCategories,
        canUpdateExistingContacts: step.canUpdateExistingContacts,
        updateOnlyBlankFields: step.updateOnlyBlankFields,
      }))

    return {
      id: track.id,
      name: track.name,
      stepId: track.stepId,
      stepType: track.stepId,
      displayName: track.displayName,
      steps: steps,
    }
  })

  const exit: ProgramExitInput = {
    ...program.exit,
    exitChoices:
      program.exit && program.exit.exitChoices
        ? program.exit.exitChoices.map((exitChoice) => ({
            displayName: exitChoice.displayName,
            stepType: exitChoice.stepType,
            srcId: exitChoice.srcId,
            not: exitChoice.not,
            listId: exitChoice.listId,
            score: exitChoice.score,
            update: exitChoice.update ?? false,
          }))
        : [],
  }

  return {
    isTemp: program.isTemp,
    id: program.id,
    name: program.name,
    description: program.description,
    type: program.type,
    sforceNote: program.sforceNote,
    allowLooping: program.allowLooping,
    suppressBadEmails: program.suppressBadEmails,
    allowReentrantAddresses: program.allowReentrantAddresses,
    useAlternateUniqueField: program.useAlternateUniqueField,
    allowBypassFatigueRules: program.allowBypassFatigueRules,
    alternateUniqueFieldHeader: program.alternateUniqueFieldHeader,
    historyDisabled: program.historyDisabled,
    historyDisabledStopped: program.historyDisabledStopped,
    sourceList: program.sourceList,
    sources: program.sources,
    messages: program.messages,
    conditionalMessages: program.conditionalMessages,
    exit,
    firstTrackId: program.firstTrackId,
    tracks,
    runStatus: program.runStatus,
    schedule: program.schedule,
    timeZoneId: program.timeZoneId,
    status: program.status,
    isReportingTooLarge: program.isReportingTooLarge,
    crm: program.crm,
    users: program.users,
    localSegmentDetails: program.localSegmentDetails,
    isNew: program.isNew,
    isAOCUpgraded: program.isAOCUpgraded,
    srcId: program.srcId,
  }
}
