import React, { FC } from 'react'

import classNames from 'classnames'

import Typography from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

interface Props {
  className?: string
  dataTest?: string
}

const rootClass = 'opt-out-step-detail'

const OptOutStepDetail: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Typography text={t('The contact will be added to your account Opt-Out List but will remain in the program.')} />
    </div>
  )
}

export default OptOutStepDetail
