import { gql } from '@apollo/client'

export default gql`
  mutation saveSyncedSegment($setSyncedSegmentDto: SyncedSegmentDtoInput) {
    saveSyncedSegment(setSyncedSegmentDto: $setSyncedSegmentDto) {
      connectedCrm
      factorIdentifierNameMap
      isOngoingSync
      segmentIdentifier
      segmentName
      selectedSourceTypeName
      uclSyncedSegmentId
    }
  }
`
