import { gql } from '@apollo/client'

export default gql`
  query getSentReportsByMessageId(
    $accountId: Long!
    $sortDirection: Direction
    $sortColumn: SentSortColumns
    $searchTerm: String
    $size: Int!
    $messageId: Long!
    $page: Int!
  ) {
    getSentReportsByMessageId(
      accountId: $accountId
      sortDirection: $sortDirection
      sortColumn: $sortColumn
      searchTerm: $searchTerm
      size: $size
      messageId: $messageId
      page: $page
    ) {
      smsSentReport {
        contactName
        phoneNumber
        status
      }
      totalCount
    }
  }
`
