import React, { FC, useCallback, useContext } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import { useTranslation } from '@const/globals'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { ComposerFormTypeModalProps } from '@utils/composer/emailComposer/types'

import './ComposerFormTypeModal.css'

const rootClass = 'composer-form-type-modal'

const ComposerFormTypeModal: FC<ComposerFormTypeModalProps> = ({
  title,
  body,
  onCancel,
  onConfirm,
  cancelLabel,
  confirmLabel,
  dataTest = rootClass,
  className,
}) => {
  const { t } = useTranslation()
  const {
    api: { updateModal },
  } = useContext(EmailComposerContext)

  const handleSave = useCallback(() => (onConfirm ? onConfirm() : updateModal('formTypeModal', undefined)), [onConfirm, updateModal])
  const handleClose = useCallback(() => (onCancel ? onCancel() : updateModal('formTypeModal', undefined)), [onCancel, updateModal])

  return (
    <Modal
      isOpen
      className={classNames(rootClass, className)}
      dataTest={dataTest}
      header={
        <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__header`} dataTest={`${dataTest}-header`}>
          {t(title)}
        </ModalHeader>
      }
    >
      <ModalBody className={`${rootClass}__body`}>{body}</ModalBody>
      <ModalFooter footerType={ModalFooterType.Form} className={`${rootClass}__footer`}>
        <Button buttonType={ButtonType.TERTIARY} onClick={handleClose} dataTest={`${dataTest}-cancel-button`}>
          {t(cancelLabel ?? 'Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} onClick={handleSave} dataTest={`${dataTest}-confirm-button`}>
          {t(confirmLabel ?? 'Ok')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ComposerFormTypeModal
