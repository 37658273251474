import { ApolloClient } from '@apollo/client'
import getAllItems from '@graphql/microservices/categorization/getAllItems'
import getItem from '@graphql/microservices/categorization/getItem'
import copyLandingPageOrTemplate from '@graphql/mutations/copyLandingPageOrTemplate'
import createTemplateFromLandingPage from '@graphql/mutations/createTemplateFromLandingPage'
import deleteLandingPageTemplates from '@graphql/mutations/deleteLandingPageTemplates'
import duplicateLandingPageTemplate from '@graphql/mutations/duplicateLandingPageTemplate'
import { GetItemQueryVariables, GetItemQuery, GetAllItemsQuery, GetAllItemsQueryVariables } from '@graphql/types/microservice/categorization-types'
import {
  CopyLandingPageOrTemplateMutation,
  CopyLandingPageOrTemplateMutationVariables,
  CreateTemplateFromLandingPageMutation,
  CreateTemplateFromLandingPageMutationVariables,
  DeleteLandingPageTemplatesMutation,
  DeleteLandingPageTemplatesMutationVariables,
  DuplicateLandingPageTemplateMutation,
  DuplicateLandingPageTemplateMutationVariables,
} from '@graphql/types/mutation-types'
import { FetchPromise } from '@utils/types'

export interface LandingPageTemplatesListingPageRequests {
  getItemRequest: (variables: GetItemQueryVariables) => FetchPromise<GetItemQuery>
  deleteLandingPageTemplatesRequest: (variables: DeleteLandingPageTemplatesMutationVariables) => FetchPromise<DeleteLandingPageTemplatesMutation>
  createLandingPageFromTemplateRequest: (variables: CopyLandingPageOrTemplateMutationVariables) => FetchPromise<CopyLandingPageOrTemplateMutation>
  duplicateLandingPageTemplateRequest: (
    variables: DuplicateLandingPageTemplateMutationVariables
  ) => FetchPromise<DuplicateLandingPageTemplateMutation>
  createTemplateFromLandingPageRequest: (
    variables: CreateTemplateFromLandingPageMutationVariables
  ) => FetchPromise<CreateTemplateFromLandingPageMutation>
  getLandingPagesRequest: () => FetchPromise<GetAllItemsQuery>
}

export const getLandingPageTemplatesListingPageRequests = (
  client: ApolloClient<object>,
  categorizationClient: ApolloClient<object>
): LandingPageTemplatesListingPageRequests => {
  const getItemRequest = async (variables: GetItemQueryVariables) => {
    return await categorizationClient.query<GetItemQuery, GetItemQueryVariables>({
      query: getItem,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables,
    })
  }

  const deleteLandingPageTemplatesRequest = async (variables: DeleteLandingPageTemplatesMutationVariables) => {
    return await client.mutate<DeleteLandingPageTemplatesMutation, DeleteLandingPageTemplatesMutationVariables>({
      mutation: deleteLandingPageTemplates,
      errorPolicy: 'all',
      variables,
    })
  }

  const createLandingPageFromTemplateRequest = async (variables: CopyLandingPageOrTemplateMutationVariables) => {
    return await client.mutate<CopyLandingPageOrTemplateMutation, CopyLandingPageOrTemplateMutationVariables>({
      mutation: copyLandingPageOrTemplate,
      errorPolicy: 'all',
      variables: {
        ...variables,
        toTemplate: false,
      },
    })
  }

  const duplicateLandingPageTemplateRequest = async (variables: DuplicateLandingPageTemplateMutationVariables) => {
    return await client.mutate<DuplicateLandingPageTemplateMutation, DuplicateLandingPageTemplateMutationVariables>({
      mutation: duplicateLandingPageTemplate,
      errorPolicy: 'all',
      variables,
    })
  }

  const createTemplateFromLandingPageRequest = async (variables: CreateTemplateFromLandingPageMutationVariables) => {
    return await client.mutate<CreateTemplateFromLandingPageMutation, CreateTemplateFromLandingPageMutationVariables>({
      mutation: createTemplateFromLandingPage,
      errorPolicy: 'all',
      variables,
    })
  }

  const getLandingPagesRequest = async () => {
    return await categorizationClient.query<GetAllItemsQuery, GetAllItemsQueryVariables>({
      query: getAllItems,
      errorPolicy: 'all',
      variables: {
        type: 'LANDING_PAGE',
        field: 'name',
        fieldType: 'string',
        pageNumber: 0,
        pageSize: 50,
      },
    })
  }

  return {
    getItemRequest,
    deleteLandingPageTemplatesRequest,
    createLandingPageFromTemplateRequest,
    duplicateLandingPageTemplateRequest,
    createTemplateFromLandingPageRequest,
    getLandingPagesRequest,
  }
}
