import { gql } from '@apollo/client'

export default gql`
  query recipientsValidation($messageId: String) {
    recipientsValidation(messageId: $messageId) {
      status
      message
      total
      errorsCount
      missingEmailCount
      invalidEmailCount
    }
  }
`
