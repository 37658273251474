import React, { ReactNode } from 'react'

import { TFunction } from 'i18next'

import { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import PillLabel, { PillLabelType } from '@components/PillLabel/PillLabel'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import { TableV2Props } from '@components/TableV2/tableV2TS/interfaces'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { CampaignTableRowData } from '@src/pages/reports/revenueImpactNew/components/campaignTable/CampaignTable.types'
import { Table } from '@tanstack/react-table'
import { getCountDisplay } from '@utils/numbers'

const rootClass = 'campaign-table'

const getPillLabel: (status?: string) => ReactNode = (status) => {
  let pillType: PillLabelType | undefined
  switch (status) {
    case 'Open':
      pillType = PillLabelType.DEFAULT
      break
    case 'Closed/Lost':
      pillType = PillLabelType.ERROR
      break
    case 'Closed/Won':
      pillType = PillLabelType.SUCCESS
      break
    default:
      pillType = undefined
  }
  return pillType ? <PillLabel type={pillType} text={status} noMargin textType={TextType.BODY_TEXT} /> : status
}

const getPathTooltip: (path: string[]) => ReactNode = (path) => {
  if (!path.length) return null
  return (
    <>
      <Tooltip alwaysShowOnHover trigger={<Typography text="..." />} className={`${rootClass}__details-cell-tooltip`}>
        {path.map((p, index) => (
          <span key={index}>
            {!!index && <Svg name={SvgNames.paginationControlsNext} type={SvgType.SMALL_ICON} />}
            {p}
          </span>
        ))}
      </Tooltip>
      <Svg name={SvgNames.paginationControlsNext} type={SvgType.SMALL_ICON} fill={SvgColor.LIGHT_GRAY} />
    </>
  )
}

const sumFooterValue = (table: Table<CampaignTableRowData>, columnID: string) => {
  return getRootRows(table).reduce((total, row) => total + (row.getValue<number>(columnID) ?? 0), 0)
}

const getFooterWonPercentage = (table: Table<CampaignTableRowData>) => {
  const totalWon = sumFooterValue(table, 'won')
  const totalOpportunities = sumFooterValue(table, 'opportunities')
  return totalOpportunities ? `${((totalWon * 100) / totalOpportunities).toFixed(1)}%` : '100%'
}

const getRootRows = (table: Table<CampaignTableRowData>) => table.getRowModel().rows.filter((row) => row.depth === 0)

const displayWith$: (str: string | number) => string = (str) => `$${str}`

const drawFooterCell: (text: ReactNode) => ReactNode = (text) => <Typography text={text} weight={TextWeight.BOLD} className={'ellip'} />

const getCampaignColumns = (t: TFunction): ColumnDefWithAdditionalProps<CampaignTableRowData>[] => [
  {
    accessorKey: 'id',
    textAlign: 'left',
    maxSize: 280,
    header: t('Campaign details'),
    enableFlexCell: true,
    cell: (rec) => {
      const { name, missingName, status, parentPath } = rec.row.original
      return (
        <>
          {parentPath && getPathTooltip(parentPath)}
          {missingName ? (
            <>
              [<Typography className="ellip" text={name} weight={TextWeight.ITALIC} />]
            </>
          ) : name ? (
            <TextWithTooltipOnEllip typographyProps={{ text: name }} />
          ) : status ? (
            getPillLabel(status)
          ) : (
            <TextWithTooltipOnEllip typographyProps={{ text: rec.getValue() as string }} />
          )}
        </>
      )
    },
    footer: () => drawFooterCell(t('Totals')),
  },
  {
    accessorKey: 'opportunities',
    textAlign: 'left',
    maxSize: 170,
    header: t('Opportunities'),
    footer: ({ table }) => drawFooterCell(sumFooterValue(table, 'opportunities')),
  },
  {
    accessorKey: 'won',
    textAlign: 'left',
    minSize: 90,
    header: t('Won'),
    footer: ({ table }) => drawFooterCell(sumFooterValue(table, 'won')),
  },
  {
    accessorKey: 'wonPercentage',
    textAlign: 'left',
    minSize: 100,
    header: `${t('Won')} %`,
    cell: ({ row }) => `${row.original.wonPercentage ?? 0}%`,
    footer: ({ table }) => drawFooterCell(getFooterWonPercentage(table)),
  },
  {
    accessorKey: 'pipeline',
    textAlign: 'left',
    minSize: 155,
    header: () => (
      <div className={`${rootClass}__pipline-header-cell`}>
        <Typography text={t('Pipeline')} type={TextType.TABLE_HEADER} inline />
        <Tooltip trigger={<Svg name={SvgNames.info} type={SvgType.ICON} fill={SvgColor.LIGHT_GRAY} />}>
          {t('Campaign.Table.Pipeline.Tooltip')}
        </Tooltip>
      </div>
    ),
    cell: ({ row }) => displayWith$(getCountDisplay(row.original.pipeline)),
    footer: ({ table }) => drawFooterCell(displayWith$(getCountDisplay(sumFooterValue(table, 'pipeline')))),
  },
  {
    accessorKey: 'revenue',
    textAlign: 'right',
    minSize: 165,
    header: t('Revenue'),
    cell: ({ row }) => displayWith$(getCountDisplay(row.original.revenue)),
    footer: ({ table }) => drawFooterCell(displayWith$(getCountDisplay(sumFooterValue(table, 'revenue')))),
  },
  {
    accessorKey: 'avgRevenue',
    textAlign: 'right',
    minSize: 160,
    header: t('AVG Revenue'),
    cell: ({ row }) => displayWith$(getCountDisplay(row.original.avgRevenue)),
    footer: ({ table }) =>
      drawFooterCell(displayWith$(getCountDisplay(Math.round(sumFooterValue(table, 'revenue') / (sumFooterValue(table, 'won') || 1))))),
  },
]
// Filtering render props, to have empty cells
const getBaseEmptyColumns = (t: TFunction): ColumnDefWithAdditionalProps<CampaignTableRowData>[] =>
  getCampaignColumns(t).map(({ cell: _, footer: __, ...rest }) => rest)

const getCampaignTableProps = (
  buttonOnClick: () => void,
  searchTerm: string,
  loading: boolean,
  t: TFunction
): Omit<TableV2Props<CampaignTableRowData>, 'data'> => ({
  columns: loading ? getBaseEmptyColumns(t) : getCampaignColumns(t),
  enableSubRow: !searchTerm,
  enableFooter: !loading && !searchTerm,
  withoutBorders: true,
  enableSorting: true,
  enableCalculatedRow: true,
  sortingBy: [{ id: 'opportunities', desc: true }],
  emptyState: {
    headline: t('Campaign.Table.Empty.HeadLine', { context: searchTerm ? 'search' : 'filter', searchTerm }),
    text: t('Campaign.Table.Empty.Text', { context: searchTerm ? 'search' : 'filter' }),
    buttonText: t('View all campaigns') as string,
    imgSrc: searchTerm ? StaticImageNames.emptySearch : StaticImageNames.emptyFilter,
    size: EmptyListingSize.MEDIUM,
    buttonOnClick,
    dataTest: `${rootClass}-empty-listing`,
    withoutBorder: true,
  },
})

export default getCampaignTableProps
