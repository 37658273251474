import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { useApolloClient, useMutation } from '@apollo/client'
import ImagePickerWithActionsModalContainer from '@components/AssetPickers/ImagePickerWithActionsModal/ImagePickerWithActionsModalContainer'
import Button, { ButtonType } from '@components/Button'
import Container from '@components/Container'
import InputV2 from '@components/InputV2/InputV2'
import { LabelType, LabelV2 } from '@components/LabelV2/LabelV2'
import Modal, { ModalBody } from '@components/Modal'
import ModalFooterV2 from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import ModalHeaderV2 from '@components/Modal/components/ModalHeaderV2/ModalHeaderV2'
import Spinner from '@components/Spinner/Spinner'
import { Status } from '@components/StatusToast/StatusToast'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import TextArea from '@components/TextArea/TextArea'
import Toggle from '@components/Toggle'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import UploadImage, { ImageURL, ImageWithType } from '@components/UploadImage/UploadImage'
import uploadImage from '@graphql/mutations/uploadImage'
import { UploadImageMutation, UploadImageMutationVariables } from '@graphql/types/mutation-types'
import { ImageAssetModelGq } from '@graphql/types/query-types'
import { useAccountSettings } from '@utils/account/account.utils'
import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'
import { LandingPageVisibilityStatus } from '@utils/composer/context/LandingPageComposer.context'

import { SettingsFormChangeHandler } from '../../utils/LandingPageComposerSettings.utils'

import './MetaDataPaneV2.css'

export interface MetaDataPaneProps {
  className?: string
  dataTest?: string
  onFieldChange: SettingsFormChangeHandler
}

const imageMaxSize = 5 * Math.pow(2, 20)

const rootClass = 'meta-data-pane-v2'

export const MetaDataPaneV2: FC<MetaDataPaneProps> = (props) => {
  const { dataTest = rootClass, className, onFieldChange } = props

  const { t } = useTranslation()
  const client = useApolloClient()
  const { accountId } = useAccountSettings()
  const {
    values: { isSaveFailed, landingPage, publicUrlManager, isStory },

    api: { update, updateModal },
  } = useComposerContext()
  const { landingPageMetaImage, landingPageVisibility, landingPageMetaTitle, landingPageMetaDescription, showErrors } = landingPage

  const landingPageVisible = landingPageVisibility === LandingPageVisibilityStatus.SHOW
  const [isSEOModalOpened, setIsSEOModalOpened] = useState<boolean>(false)
  const [isImagePickerOpen, setIsImagePickerOpen] = useState<boolean>(false)
  const [pageTitle, setPageTitle] = useState(landingPageMetaTitle)
  const [description, setDescription] = useState(landingPageMetaDescription || '')
  const [image, setImage] = useState<ImageAssetModelGq | ImageWithType | ImageURL | undefined>(landingPageMetaImage)
  const [toggleState, setToggleState] = useState<boolean>(landingPageVisible)
  const [isSavePressed, setIsSavePressed] = useState(false)

  const { landingPagePublicUrl, landingPageSeoFriendlyUrl } = publicUrlManager

  const [uploadImageMutation, { loading }] = useMutation<UploadImageMutation, UploadImageMutationVariables>(uploadImage, {
    client,
    fetchPolicy: 'no-cache',
  })

  const onUploadImage = useCallback(
    (variables: UploadImageMutationVariables) =>
      uploadImageMutation({ variables }).then(({ data }) => {
        if (!data) {
          return Promise.reject()
        }
        return data
      }),
    [uploadImageMutation]
  )

  const onImageChange = (image?: ImageWithType | ImageURL) => {
    setImage(image)
    setIsImagePickerOpen(false)
  }

  useEffect(() => {
    if (isSaveFailed && isSavePressed) {
      updateModal('statusToast', {
        status: Status.FAIL,
        title: t('LandingPageComposer.Unable.Save.Settings'),
        message: <Typography text={t('LandingPageComposer.Unable.Save.Settings.desc')} tagProps={{ b: { weight: TextWeight.BOLD } }} inline />,
      })

      setIsSavePressed(false)
    }
  }, [isSaveFailed, isSavePressed, t, updateModal])

  useEffect(() => {
    if (!landingPageMetaTitle) {
      setPageTitle('')
    }

    if (!landingPageMetaImage) {
      setImage(undefined)
    }

    if (landingPageVisible) {
      setToggleState(true)
    }

    if (!landingPageMetaDescription) {
      setDescription('')
    }
  }, [landingPageMetaDescription, landingPageVisible, landingPageMetaImage, landingPageMetaTitle])

  const renderPreviewModal = () => {
    return (
      <Modal paddingV2 className={`${rootClass}__preview`} dataTest={`${rootClass}__preview`} isOpen={isSEOModalOpened}>
        <ModalHeaderV2 headerType={'form'} headerText={t('LandingPageComposer.Settings.MetaData.Header')} />
        <ModalBody>
          <div className={`${rootClass}__preview-toggle`}>
            <LabelV2
              label={t('LandingPageComposer.Settings.MetaData.Visibility')}
              labelType={LabelType.input}
              tooltip={{
                content: t('LandingPageComposer.Settings.MetaData.Visibility.Tooltip'),
              }}
            />
            <Toggle
              dataTest={`${dataTest}-page-visibility-toggle`}
              label={t('LandingPageComposer.Settings.MetaData.Visibility.Label')}
              isOn={toggleState}
              onToggle={(value) => setToggleState(value)}
              noLeftMargin
            />
          </div>

          <InputV2
            parentWrapperClassName={`${rootClass}__page-title-wrapper`}
            className={`${rootClass}__page-title`}
            dataTest={`${dataTest}-page-title`}
            iconType={SvgType.MEDIUM}
            labelProps={{
              labelType: 'medium',
              label: t('LandingPageComposer.Settings.MetaData.Title'),
              required: true,
            }}
            value={landingPageMetaTitle ?? ''}
            handleValueChangeFromOuter
            maxCharacterProps={{ maxLength: 55 }}
            placeholder={t('LandingPageComposer.Settings.MetaData.Title.Placeholder')}
            onChange={(e) => setPageTitle(e.target.value)}
            error={!!showErrors && !pageTitle}
            onBlur={() => update({ landingPage: { showErrors: true } })}
            inputInfo={{
              enabled: !!showErrors && !pageTitle,
              hasIcon: !!showErrors && !pageTitle,
              errorText: 'A page title is required',
            }}
          />
          <TextArea
            dataTest={`${dataTest}-page-description`}
            className={`${rootClass}__fields-textarea`}
            name="landingPageMetaDescription"
            label={t('LandingPageComposer.Settings.MetaData.Description')}
            labelType={LabelType.medium}
            placeholder={t('LandingPageComposer.Settings.MetaData.Description.Placeholder')}
            value={description}
            maxCharacterProps={{ maxLength: 150 }}
            onChange={(e) => setDescription(e.target.value)}
            resize={false}
          />
          <div className={`${rootClass}__upload-image-container`}>
            <div className={`${rootClass}__upload-image-container-label`}>
              <LabelV2
                label={t('LandingPageComposer.Settings.MetaData.Image')}
                labelType={LabelType.input}
                tooltip={{ content: t('LandingPageComposer.Settings.MetaData.Image.Tooltip') }}
              />
              <Typography
                text={t('LandingPageComposer.Settings.MetaData.Image.Info')}
                type={TextType.BODY_TEXT_SMALL_LIGHT}
                lineHeight={LineHeight.MEDIUM_SMALL}
              />
            </div>
            {loading ? (
              <Spinner />
            ) : isImagePickerOpen ? (
              <ImagePickerWithActionsModalContainer
                handleImagePickerDone={onImageChange}
                hideUploadFromURL
                accept=".png, .jpg, .jpeg"
                imageMaxSize={imageMaxSize}
                allowSvg
              />
            ) : (
              <UploadImage
                isStory={isStory}
                image={(image as ImageAssetModelGq)?.url}
                onImagePickerOpenChange={(open) => setIsImagePickerOpen(open)}
                onImageChange={onImageChange}
                canSelectExistingImage
                maxSize={imageMaxSize}
                withHeaderActions
                hideUploadFromURL
                accept=".png, .jpg, .jpeg"
                useRequirementsInImagePicker
                className={`${rootClass}__upload-image`}
              />
            )}
          </div>
        </ModalBody>
        <ModalFooterV2
          dataTest={`${dataTest}-modal-footer`}
          showTopBorder
          onClose={() => {
            setIsSEOModalOpened(false)
            setImage(landingPageMetaImage)
            setDescription(landingPageMetaDescription || '')
            setToggleState(landingPageVisible)
            onFieldChange('landingPageMetaTitle', landingPageMetaTitle)
            setPageTitle(landingPageMetaTitle)
            onFieldChange('landingPageMetaDescription', landingPageMetaDescription)
            setDescription(landingPageMetaDescription || '')
            onFieldChange('landingPageMetaImage', landingPageMetaImage)
            onFieldChange('landingPageVisibility', landingPageVisibility)
          }}
          buttons={{
            actionButtonLabel: 'Save',

            actionButtonOnClick: () => {
              setIsSavePressed(true)
              onFieldChange('landingPageMetaTitle', pageTitle)
              onFieldChange('landingPageMetaDescription', description)
              onFieldChange('landingPageVisibility', toggleState ? LandingPageVisibilityStatus.SHOW : LandingPageVisibilityStatus.HIDE)

              setIsSEOModalOpened(false)

              const { id, imageBase64, imageType, title, url } = image
                ? (image as ImageWithType & ImageURL)
                : { id: undefined, imageBase64: undefined, imageType: undefined, title: undefined, url: undefined }
              if (url) {
                onFieldChange('landingPageMetaImage', {
                  accountId: Number(accountId),
                  id: id,
                  name: title,
                  url: url,
                })
              } else if (imageBase64 && imageType && title) {
                onUploadImage({ base64File: imageBase64, extension: imageType, folderName: 'Default Folder', fileName: title }).then((data) => {
                  onFieldChange('landingPageMetaImage', {
                    accountId: Number(accountId),
                    id: data.uploadImage.id,
                    name: data.uploadImage.title,
                    url: data.uploadImage.previewUrl,
                  })
                })
              } else {
                onFieldChange('landingPageMetaImage', undefined)
              }
            },
            actionButtonDisabled: !!showErrors && !pageTitle,
            cancelButtonLabel: t('Cancel'),
          }}
        />
      </Modal>
    )
  }

  return (
    <Container>
      {renderPreviewModal()}
      <div className={`${rootClass}__preview-seo-block`}>
        <div>
          <Typography
            className={classNames(`${rootClass}__title`)}
            text={t('LandingPageComposer.Settings.MetaData.Header')}
            type={TextType.BODY_TEXT_LARGE}
            weight={TextWeight.MEDIUM}
          />
          <Typography
            className={`${rootClass}__preview-header-description`}
            text={t('LandingPageComposer.Settings.MetaData.Header.Description.New')}
            type={TextType.BODY_TEXT_LIGHT}
          />
        </div>

        <Button
          buttonType={ButtonType.INFO}
          onClick={() => setIsSEOModalOpened(true)}
          className={`${rootClass}__preview-edit-button`}
          dataTest={`${dataTest}-edit-button`}
        >
          <Svg name={SvgNames.pencil} />
          {t('Edit')}
        </Button>
      </div>

      <div className={classNames(`${rootClass}__preview-content`, rootClass, className)} data-test={dataTest}>
        {landingPageMetaImage?.url && (
          <img className={classNames(`${rootClass}__preview-image`)} src={landingPageMetaImage?.url} alt={'SEO meta data'} />
        )}
        <div className={`${rootClass}__preview-meta-wrapper`}>
          <Typography className={`${rootClass}__preview-meta-name`} text={landingPageMetaTitle} type={TextType.PAGE_HEADLINE} />
          <Typography className={classNames(`${rootClass}__preview-meta-url`)} text={landingPageSeoFriendlyUrl || landingPagePublicUrl} />
          {landingPageMetaDescription && <Typography text={landingPageMetaDescription} />}
        </div>
      </div>

      <Typography
        dataTest={`${rootClass}-visible-for-search`}
        className={`${rootClass}__visible-for-search`}
        tagProps={{ b: { weight: TextWeight.MEDIUM, inline: true } }}
        text={t('LandingPageComposer.Settings.MetaData.Visibility.SearchEngine', { context: landingPageVisible ? 'visible' : 'hidden' })}
      />
    </Container>
  )
}
