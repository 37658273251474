import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router'

import classNames from 'classnames'

import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import { renderLoader } from '@components/Loader/Loader'
import Pill from '@components/Pill/Pill'
import Svg, { SvgNames } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Tab from '@components/TabsAO/TabAO'
import Tabs, { TabItemData } from '@components/TabsAO/TabsAO'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import EmailComposerModalsState from '@src/pages/EmailComposer/components/EmailComposerModalState/EmailComposerModalsState'
import PlainTextComposer from '@src/pages/EmailComposer/components/PlainTextComposer/PlainTextComposer'
import ReviewAndSendContainer from '@src/pages/EmailComposer/components/ReviewAndSend/ReviewAndSendContainer'
import SendDetails from '@src/pages/EmailComposer/components/SendDetails/SendDetails'
import BlockedModal from '@src/pages/EmailComposer/EmailModals/components/BlockedModal/BlockedModal'
import {
  useEmailSenderValidations,
  useEmailValidations,
  useSettingsValidations,
  useSubjectAndPreviewTextValidations,
} from '@src/pages/EmailComposer/hooks/useEmailValidations'
import { detectEmailType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { CommonComposerTab } from '@utils/composer/commonComposer/CommonComposer.context'
import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'
import { ComposerHistoryState, EmailComposerState } from '@utils/composer/context/EmailComposer.context'
import { isCustomerCareLogin } from '@utils/cookie'
import { converterHtmlToBytes } from '@utils/strings'

import BeeEditorContainer from './BeeEditor/BeeEditorContainer'
import { EmailComposerHeader } from './components/EmailComposerHeader/EmailComposerHeader'
import EmailComposerSettingsContainer from './components/EmailComposerSettings/EmailComposerSettingsContainer'
import { UploadHtmlComposer } from './components/UploadHtmlComposer/UploadHtmlComposer'
import ABTestBlockeModal from './EmailModals/components/ABTestBlockedModal/ABTestBlockedModal'
import { WebinarUnscheduleModal } from './EmailModals/components/WebinarUnscheduleModal/WebinarUnscheduleModal'
import { useValidationSelectionsCount } from './hooks/useValidationSelectionsCount'

import './EmailComposer.css'

const LARGE_EMAIL_SIZE_IN_KILOBYTES = 250000

const rootClass = 'email-composer'

interface EmailComposerProps {
  isStory?: boolean
  editorClassname?: string
  isBeeEditorLoading: boolean
  isPlainTextComposerLoading?: boolean
}

export const EmailComposer: FC<EmailComposerProps> = (props) => {
  const { isStory, editorClassname, isBeeEditorLoading, isPlainTextComposerLoading } = props
  const {
    values: {
      tab,
      loading,
      editingUsernames,
      isCoEditing,
      isInitializeBeeEditor,
      disabledFeatures: { autoSave: disabledAutoSave },
      message: { templateHtml, isAbTestRunning, isAbTestsSent, isWebinarScheduled, lastUpdatedBy },
      validations: { isContentMissing },
      haveUnsavedChanges,
      emailTooLargeWarningDismissed,
      showEmailTooLargeWarning,
      messageConfiguration: {
        messageType,
        reviewAndSend: { keepTabs, reviewAndSendLabel },
        sendDetails: { hideSendDetails },
        reviewAndSend: { displayAsTab, hidePreview },
        saveButton: { separateSaveAndClose } = {},
        preview: { disableTestEmail },
        design: { lockDesignTab } = {},
      },
      detectedURLChanges: { plainTextMode, uploadHtmlMode },
    },
    api: { onPreview, onTabChange, onTest, update, onAutoSave },
  } = useComposerContext<EmailComposerState>()

  const { errors, warnings } = useValidationSelectionsCount()
  useEmailSenderValidations()
  useSubjectAndPreviewTextValidations()
  useSettingsValidations()
  useEmailValidations()

  const history = useHistory<ComposerHistoryState>()
  const { t } = useTranslation()

  const { isEmailTemplate, isEmailResend, isEmailABTest } = detectEmailType(messageType)
  const userVisitedReview = history.location.state?.visitedTabs?.[CommonComposerTab.REVIEW]
  const isReview = tab === CommonComposerTab.REVIEW
  const isDesign = tab === CommonComposerTab.DESIGN

  useEffect(() => {
    const htmlBytes = converterHtmlToBytes(templateHtml)

    if (htmlBytes >= LARGE_EMAIL_SIZE_IN_KILOBYTES && !emailTooLargeWarningDismissed) {
      update({ showEmailTooLargeWarning: true })
    } else if (htmlBytes < LARGE_EMAIL_SIZE_IN_KILOBYTES && showEmailTooLargeWarning && !emailTooLargeWarningDismissed) {
      update({ showEmailTooLargeWarning: false })
    }
  }, [emailTooLargeWarningDismissed, templateHtml, update])

  const tabsData: TabItemData[] = useMemo(
    () => [
      { index: CommonComposerTab.SETTINGS, label: t('Settings') },
      {
        index: CommonComposerTab.DESIGN,
        label: t(plainTextMode ? 'Plain Text Content' : uploadHtmlMode ? 'HTML.Content' : 'Design'),
        isTabLocked: lockDesignTab,
      },
      ...(!hideSendDetails && !isEmailTemplate ? [{ index: CommonComposerTab.SEND, label: t('Send Details') }] : []),
      ...(displayAsTab && !isEmailTemplate
        ? [
            {
              index: CommonComposerTab.REVIEW,
              label: (
                <div>
                  <span>{t('Review')}</span>
                  {userVisitedReview && !loading && errors?.toString() !== '0' && (
                    <Pill className={`${rootClass}__tabs__review-pill`} text={errors?.toString()}></Pill>
                  )}
                </div>
              ),
            },
          ]
        : []),
    ],
    [loading, t, displayAsTab, errors, hideSendDetails, isEmailTemplate, userVisitedReview]
  )

  const handleTabChange = useCallback((tab: string) => onTabChange(tab as CommonComposerTab), [onTabChange])

  useEffect(() => {
    if (isEmailResend) {
      handleTabChange('send')
    }
  }, [handleTabChange, isEmailResend])

  const handlePreview = useCallback(() => {
    update({ isPreview: true })
    onPreview()
  }, [onPreview, update])

  const handleReviewAndSend = useCallback(() => {
    if (isCustomerCareLogin() && haveUnsavedChanges) {
      if (isEmailResend) {
        update({ isReviewAndSendModal: true })
      } else {
        update({ isCustomerCareSaveModal: true })
      }
    } else {
      onTabChange(CommonComposerTab.REVIEW)
      onPreview()
    }

    // in order to make sure things like validations and CANSPAM work correctly
    onAutoSave(true)
  }, [onTabChange, haveUnsavedChanges, onPreview, onAutoSave])

  const sendTestTooltipHeader = isContentMissing ? 'MissingContent' : disabledAutoSave && haveUnsavedChanges ? 'UnsavedChanges' : ''
  const sendTestTooltipBody = disableTestEmail
    ? 'ResendDisabled'
    : isContentMissing
    ? 'MissingContent'
    : disabledAutoSave && haveUnsavedChanges
    ? 'UnsavedChanges'
    : ''

  const tabListContent = useMemo(
    () => (
      <>
        {!isEmailTemplate && (
          <Tooltip
            hide={!isContentMissing}
            position={'bottom'}
            trigger={
              <Button
                buttonType={ButtonType.FLOAT_TEAL}
                onClick={handlePreview}
                disabled={isContentMissing}
                className={`${rootClass}__header-button`}
                dataTest={`${rootClass}-preview`}
              >
                <Svg name={SvgNames.zoom} />
                {t('Preview')}
              </Button>
            }
          >
            <Typography
              text={t('EmailComposer.Design.PreviewDisabled.Tooltip.HeaderText')}
              weight={TextWeight.BOLD}
              type={TextType.BODY_TEXT_WHITE}
            />
            <Typography text={t('EmailComposer.Design.PreviewDisabled.Tooltip.BodyText')} type={TextType.BODY_TEXT_WHITE} />
          </Tooltip>
        )}
        <Tooltip
          hide={disableTestEmail ? false : isCustomerCareLogin() ? !isContentMissing && !haveUnsavedChanges : !isContentMissing}
          position={'top'}
          trigger={
            <Button
              dataTest={`${rootClass}-test-email`}
              buttonType={ButtonType.FLOAT_TEAL}
              onClick={onTest}
              disabled={disableTestEmail || isContentMissing || (!separateSaveAndClose && disabledAutoSave && haveUnsavedChanges)}
              className={`${rootClass}__header-button`}
            >
              <Svg name={SvgNames.test} />
              {t('Test email')}
            </Button>
          }
        >
          {!disableTestEmail && sendTestTooltipHeader && (
            <Typography
              text={t(`EmailComposer.Design.TextEmail.Tooltip.HeaderText.${sendTestTooltipHeader}`)}
              weight={TextWeight.BOLD}
              type={TextType.BODY_TEXT_WHITE}
            />
          )}
          {sendTestTooltipBody && (
            <Typography text={t(`EmailComposer.Design.TextEmail.Tooltip.BodyText.${sendTestTooltipBody}`)} type={TextType.BODY_TEXT_WHITE} />
          )}
        </Tooltip>
        {!hidePreview && !isEmailTemplate && !displayAsTab && (
          <Button
            buttonType={ButtonType.PRIMARY}
            iconPosition={ButtonIconPosition.LEFT}
            onClick={handleReviewAndSend}
            dataTest={`${rootClass}-review-and-send`}
          >
            <Svg name={SvgNames.airplaneOutline} />
            {reviewAndSendLabel ?? t('Review & Send')}
          </Button>
        )}
        {isEmailTemplate && (
          <Button
            dataTest={`${rootClass}-preview-template`}
            buttonType={ButtonType.PRIMARY}
            iconPosition={ButtonIconPosition.LEFT}
            onClick={handlePreview}
          >
            <Svg name={SvgNames.zoom} fill={SvgColor.WHITE} />
            {t('Preview template')}
          </Button>
        )}
      </>
    ),
    [isEmailTemplate, isContentMissing, handlePreview, t, onTest, handleReviewAndSend, reviewAndSendLabel, haveUnsavedChanges]
  )

  useEffect(() => {
    update({ validations: { errorsCount: errors, warningsCount: warnings } })
  }, [errors, warnings])

  const hasCredentials = !!process.env.NX_BEE_CLIENT_ID && !!process.env.NX_BEE_CLIENT_SECRET

  const isUploadFlow = tab === CommonComposerTab.DESIGN && uploadHtmlMode
  const showLoader = isDesign && (isBeeEditorLoading || isPlainTextComposerLoading)

  return (
    <>
      <EmailComposerModalsState />
      <EmailComposerHeader />
      <Tabs
        defaultValue={tab}
        onChange={handleTabChange}
        childData={tabsData}
        tabListContent={tabListContent}
        className={classNames(`${rootClass}__tabs`, {
          ['display-none']: isReview && !keepTabs,
          [`${rootClass}__tabs__customer-care`]: isCustomerCareLogin(),
          [`${rootClass}__tabs-upload`]: isUploadFlow,
        })}
      >
        <Tab index={CommonComposerTab.SETTINGS}>
          <EmailComposerSettingsContainer />
        </Tab>
        <Tab index={CommonComposerTab.DESIGN} alwaysRender>
          {!isStory && (loading || showLoader) && renderLoader('loader--white-background')}
          {plainTextMode ? (
            <PlainTextComposer />
          ) : uploadHtmlMode ? (
            <UploadHtmlComposer />
          ) : !hasCredentials ? (
            <Typography text={'Unable to load editor, missing credentials'} dataTest={`bee-editor-placeholder`} />
          ) : isInitializeBeeEditor ? (
            <BeeEditorContainer className={editorClassname} />
          ) : null}
        </Tab>
        <Tab index={CommonComposerTab.SEND}>
          <SendDetails />
        </Tab>

        {displayAsTab && (
          <Tab index={CommonComposerTab.REVIEW}>
            <ReviewAndSendContainer />
          </Tab>
        )}
      </Tabs>
      {displayAsTab || !isReview ? <div style={{ display: 'none' }}>{<ReviewAndSendContainer />}</div> : <ReviewAndSendContainer />}
      {isCoEditing && <BlockedModal username={uploadHtmlMode || plainTextMode ? lastUpdatedBy : editingUsernames.join(', ')}></BlockedModal>}
      {isEmailABTest && isAbTestRunning && <ABTestBlockeModal isSent={isAbTestsSent} />}
      {isWebinarScheduled && <WebinarUnscheduleModal />}
    </>
  )
}
