import { gql } from '@apollo/client'

export default gql`
  query getClickReportsByMessageId(
    $accountId: Long!
    $sortDirection: Direction
    $sortColumn: ClickSortColumns
    $searchTerm: String
    $size: Int!
    $messageId: Long!
    $page: Int!
  ) {
    getClickReportsByMessageId(
      accountId: $accountId
      sortDirection: $sortDirection
      sortColumn: $sortColumn
      searchTerm: $searchTerm
      size: $size
      messageId: $messageId
      page: $page
    ) {
      smsClick {
        clickedOnDate
        contactName
        phoneNumber
      }
      totalCount
    }
  }
`
