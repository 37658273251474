import React, { FC, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import classNames from 'classnames'

import { useApolloClient } from '@apollo/client'
import ListSelector from '@components/ListSelector/ListSelector'
import MoreOptions from '@components/MoreOptions/MoreOptions'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ListInfo } from '@graphql/types/query-types'
import { List } from '@interface/foldersLists/List'
import { ContactSettings } from '@src/pages/Settings/OtherSettings/ContactSettings/ContactSettings'
import { ContactSettingsFields, searchListByIds } from '@src/pages/Settings/OtherSettings/ContactSettings/ContactSettings.utils'
import useCRM from '@utils/hooks/useCRM'

import './SalesSegments.css'

export interface SalesSegmentsProps {
  className?: string
  dataTest?: string
}

interface SalesSegmentState {
  prospectsSegment?: List
  contactsSegment?: List
  opportunitiesSegment?: List
}

type SegmentValues =
  | ContactSettingsFields.PROSPECTS_SEGMENT_ID
  | ContactSettingsFields.CONTACTS_SEGMENT_ID
  | ContactSettingsFields.OPPORTUNITIES_SEGMENT_ID

const rootClass = 'sales-segments'

const SalesSegments: FC<SalesSegmentsProps> = (props: SalesSegmentsProps) => {
  const { dataTest = rootClass, className = '' } = props
  const [state, setState] = useState<SalesSegmentState>({})
  const { prospectsSegment, contactsSegment, opportunitiesSegment } = state
  const { getValues, setValue } = useFormContext<ContactSettings>()
  const { hasCRMConnected } = useCRM()
  const client = useApolloClient()
  const { t } = useTranslation()

  const prospectsSegmentId = getValues(ContactSettingsFields.PROSPECTS_SEGMENT_ID)
  const contactsSegmentId = getValues(ContactSettingsFields.CONTACTS_SEGMENT_ID)
  const opportunitiesSegmentId = getValues(ContactSettingsFields.OPPORTUNITIES_SEGMENT_ID)

  const loadSegmentData = async (prospectsSegmentId: string, contactsSegmentId: string, opportunitiesSegmentId: string) => {
    const { data: segmentData } = await searchListByIds(
      client,
      [prospectsSegmentId, contactsSegmentId, opportunitiesSegmentId].filter((id) => id !== '').join(',')
    )
    const unifiedList = segmentData?.searchListsByIds?.unifiedLists
    if (unifiedList && unifiedList.length > 0 && unifiedList[0].entries) {
      const prospectsSegment = unifiedList[0].entries.find(({ id }) => id === prospectsSegmentId) as ListInfo
      const contactsSegment = unifiedList[0].entries.find(({ id }) => id === contactsSegmentId) as ListInfo
      const opportunitiesSegment = unifiedList[0].entries.find(({ id }) => id === opportunitiesSegmentId) as ListInfo
      if (prospectsSegment) {
        onSegmentSelectorSubmit(prospectsSegment.id, ContactSettingsFields.PROSPECTS_SEGMENT_ID, { prospectsSegment })
      }
      if (contactsSegment) {
        onSegmentSelectorSubmit(contactsSegment.id, ContactSettingsFields.CONTACTS_SEGMENT_ID, { contactsSegment })
      }
      if (opportunitiesSegment) {
        onSegmentSelectorSubmit(opportunitiesSegment.id, ContactSettingsFields.OPPORTUNITIES_SEGMENT_ID, { opportunitiesSegment })
      }
    }
  }

  const onSegmentSelectorSubmit = (listId: string, field: SegmentValues, values: Partial<SalesSegmentState>) => {
    setState((state) => ({ ...state, ...values }))
    setValue(field, listId, { shouldDirty: true, shouldValidate: true })
  }

  const onSegmentSelectorRemove = (field: SegmentValues, values: Partial<SalesSegmentState>) => {
    setState((state) => ({ ...state, ...values }))
    setValue(field, '', { shouldDirty: true, shouldValidate: true })
  }

  useEffect(() => {
    if (prospectsSegmentId || contactsSegmentId || opportunitiesSegmentId) {
      loadSegmentData(prospectsSegmentId || '', contactsSegmentId || '', opportunitiesSegmentId || '')
    }
  }, [prospectsSegmentId, contactsSegmentId, opportunitiesSegmentId])

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__prospects-section`}>
        <Typography
          text={t(hasCRMConnected ? 'Contact.Owner.Settings.Prospects.Label.CRM' : 'Contact.Owner.Settings.Prospects.Label')}
          type={TextType.BODY_TEXT}
        />
        <MoreOptions dataTest={`${dataTest}-prospects-options`} className={`${rootClass}__segment-option`}>
          <ListSelector
            selectedList={prospectsSegment}
            onSubmit={(prospectsSegment: List) =>
              onSegmentSelectorSubmit(prospectsSegment.id, ContactSettingsFields.PROSPECTS_SEGMENT_ID, { prospectsSegment })
            }
            onRemove={() => onSegmentSelectorRemove(ContactSettingsFields.PROSPECTS_SEGMENT_ID, { prospectsSegment: undefined })}
          />
        </MoreOptions>
      </div>
      <div className={`${rootClass}__contacts-section`}>
        <Typography text={t('Contact.Owner.Settings.Contacts.Label')} type={TextType.BODY_TEXT} />
        <MoreOptions dataTest={`${dataTest}-contacts-options`} className={`${rootClass}__segment-option`}>
          <ListSelector
            selectedList={contactsSegment}
            onSubmit={(contactsSegment: List) =>
              onSegmentSelectorSubmit(contactsSegment.id, ContactSettingsFields.CONTACTS_SEGMENT_ID, { contactsSegment })
            }
            onRemove={() => onSegmentSelectorRemove(ContactSettingsFields.CONTACTS_SEGMENT_ID, { contactsSegment: undefined })}
          />
        </MoreOptions>
      </div>
      <div className={`${rootClass}__opportunities-section`}>
        <Typography text={t('Contact.Owner.Settings.Opportunities.Label')} type={TextType.BODY_TEXT} />
        <MoreOptions dataTest={`${dataTest}-opportunities-options`} className={`${rootClass}__segment-option`}>
          <ListSelector
            selectedList={opportunitiesSegment}
            onSubmit={(opportunitiesSegment: List) =>
              onSegmentSelectorSubmit(opportunitiesSegment.id, ContactSettingsFields.OPPORTUNITIES_SEGMENT_ID, { opportunitiesSegment })
            }
            onRemove={() => onSegmentSelectorRemove(ContactSettingsFields.OPPORTUNITIES_SEGMENT_ID, { opportunitiesSegment: undefined })}
          />
        </MoreOptions>
      </div>
    </div>
  )
}

export default SalesSegments
