import React, { FC } from 'react'

import classNames from 'classnames'

import SegmentedSingleLineChart, { LineSegmentData } from '@components/SegmentedSingleLineChart/SegmentedSingleLineChart'
import SingleContentDataCard from '@components/SingleContentDataCard/SingleContentDataCard'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ItemType } from '@utils/categorization'

import './WebinarRegistrationsDataCard.css'

interface WebinarRegistrationsDataCardProps {
  className?: string
  dataTest?: string
  loading: boolean
  data: LineSegmentData[]
}

const rootClass = 'webinar-registrations-data-card'

const WebinarRegistrationsDataCard: FC<WebinarRegistrationsDataCardProps> = (props: WebinarRegistrationsDataCardProps) => {
  const { dataTest = rootClass, className = '', loading, data } = props
  const { t } = useTranslation()
  const MAX_ELEMENTS = 12
  return (
    <SingleContentDataCard
      headerText={t(`ListPage.FormsJoinView.${ItemType.WEBINAR_SUBMISSION}.DataCards.Registrations.Header`)}
      className={classNames(rootClass, className)}
      dataTest={dataTest}
    >
      {loading ? (
        <Typography
          text={t(`ListPage.FormsJoinView.${ItemType.WEBINAR_SUBMISSION}.DataCards.Registrations.LoadingMessage`)}
          type={TextType.BODY_TEXT_LARGE}
          weight={TextWeight.BOLD}
          lineHeight={LineHeight.MEDIUM_LARGE}
        />
      ) : (
        <SegmentedSingleLineChart
          data={data}
          emptyTitle={t(`ListPage.FormsJoinView.${ItemType.WEBINAR_SUBMISSION}.DataCards.Registrations.NoData`)}
          subtitleSuffix={'registrations'}
          elementName={'webinars'}
          smallerTotal
          maxElements={MAX_ELEMENTS}
        />
      )}
    </SingleContentDataCard>
  )
}

export default WebinarRegistrationsDataCard
