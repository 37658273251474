import React, { FC } from 'react'

import classNames from 'classnames'

import InfoHoverCard from '@components/InfoHoverCard/InfoHoverCard'
import InfoHoverHeader from '@components/InfoHoverHeader/InfoHoverHeader'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { getStandardFormattedDate } from '@utils/date'

import './DraftsInfoHover.css'

interface DraftsInfoHoverProps {
  name: string
  description: string
  createdAt: number
  createdBy: string
  lastUpdated: number
  updatedBy: string
  className?: string
  dataTest?: string
}

const rootClass = 'drafts-info-hover'

const DraftsInfoHover: FC<DraftsInfoHoverProps> = (props: DraftsInfoHoverProps) => {
  const { name, description, createdAt, createdBy, lastUpdated, updatedBy, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()

  const header = (
    <InfoHoverHeader
      title={<Typography text={name} weight={TextWeight.MEDIUM} type={TextType.BODY_TEXT_WHITE} className={classNames({ ['ellip-clamp']: true })} />}
    />
  )

  const footer = (
    <div className={`${rootClass}__body-section`}>
      <Typography text={t('History')} type={TextType.TABLE_HEADER} className={`${rootClass}__body-section-title`} />
      <div className={classNames(`${rootClass}__footer-section-line`, `${rootClass}__body-section-line-first`, 'ellip')}>
        <Typography
          text={`<medium>Created:</medium> {{date}} by {{createdBy}}`}
          values={{ date: getStandardFormattedDate(Number(createdAt), true), createdBy }}
          tagProps={{ medium: { weight: TextWeight.MEDIUM } }}
          type={TextType.BODY_TEXT_SMALL}
          className={'ellip'}
          inline
        />
      </div>
      <div className={classNames(`${rootClass}__footer-section-line`, 'ellip')}>
        <Typography
          text={`<medium>Last updated:</medium> {{date}} by {{updatedBy}}`}
          values={{ date: getStandardFormattedDate(Number(lastUpdated), true), updatedBy }}
          tagProps={{ medium: { weight: TextWeight.MEDIUM } }}
          type={TextType.BODY_TEXT_SMALL}
          className={'ellip'}
          inline
        />
      </div>
    </div>
  )

  const body = <div>{footer}</div>

  return (
    <InfoHoverCard
      header={header}
      description={description}
      descriptionTitle={'Subject line'}
      sideOffset={2}
      openDelay={350}
      closeDelay={150}
      className={classNames(rootClass, className)}
      data-test={dataTest}
    >
      {body}
    </InfoHoverCard>
  )
}

export default DraftsInfoHover
