import React, { ComponentProps, FC, useEffect, useState } from 'react'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import Caution from '@components/Caution/Caution'
import EmptyListing from '@components/EmptyListing/EmptyListing'
import InfoAction from '@components/InfoAction/InfoAction'
import Loader from '@components/Loader'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Svg, { SvgType } from '@components/Svg/Svg'
import SvgNames from '@components/Svg/SvgNames'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Toggle from '@components/Toggle'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { SERVICES_AGREEMENT_LINK, useTranslation } from '@const/globals'
import setActonLabs from '@graphql/mutations/setActonLabs'
import setEnableChatGptComposerPlugin from '@graphql/mutations/setEnableChatGptComposerPlugin'
import setEnableMlAudiencePlugin from '@graphql/mutations/setEnableMlAudiencePlugin'
import getLabs from '@graphql/queries/getLabs'
import {
  SetActonLabsMutation,
  SetActonLabsMutationVariables,
  SetEnableChatGptComposerPluginMutation,
  SetEnableChatGptComposerPluginMutationVariables,
  SetEnableMlAudiencePluginMutation,
  SetEnableMlAudiencePluginMutationVariables,
} from '@graphql/types/mutation-types'
import { StatusToastType } from '@interface/StatusToast'
import { useAIAudienceInsights } from '@src/pages/RecommendedSegments/utils/useAIAudienceInsights'
import { LABS_LINK } from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Labs/constants/labs.constants'
import { useAccountSettings } from '@utils/account/account.utils'
import useCRM from '@utils/hooks/useCRM'
import { logNewRelicError } from '@utils/new-relic.utils'

import './Labs.css'

interface Props {
  setToastStatus: (value: StatusToastType) => void
  dataTest?: string
}

const rootClass = 'labs'
const AI_AUDIENCE_INSIGHTS_INFO_LINK =
  'https://connect.act-on.com/hc/en-us/articles/16624620901655-Act-On-Audience-Insights-Helping-you-create-relevant-segments'

const Labs: FC<Props> = (props: Props) => {
  const { setToastStatus, dataTest = rootClass } = props

  const { isAccountAdmin, generativeAIEmailComposer, enableChatGptComposerPlugin, hasAIAudienceInsights, hasShowActOnContactsTab } =
    useAccountSettings()

  const { hasCRMConnected } = useCRM()

  const [labs, setLabs] = useState<any>()
  const [toggleValue, setToggleValue] = useState<boolean>(false)
  const [toggleGPTValue, setToggleGPTValue] = useState<boolean>(enableChatGptComposerPlugin)
  const [toggleAIAudienceInsightsValue, setToggleAIAudienceInsightsValue] = useState(false)

  const showChatGPT = isAccountAdmin && generativeAIEmailComposer
  const showAIAudienceInsights = hasAIAudienceInsights && hasCRMConnected && hasShowActOnContactsTab

  const { isRegistered, loading: aiAudienceLoading } = useAIAudienceInsights()
  const { t } = useTranslation()
  const client = useApolloClient()

  const { data, loading, error } = useQuery<any, any>(getLabs, {
    client,
    fetchPolicy: 'network-only',
  })

  const [saveActionLabs] = useMutation<SetActonLabsMutation, SetActonLabsMutationVariables>(setActonLabs, {
    client,
    fetchPolicy: 'no-cache',
  })

  const [saveEnableChatGptComposerPlugin] = useMutation<SetEnableChatGptComposerPluginMutation, SetEnableChatGptComposerPluginMutationVariables>(
    setEnableChatGptComposerPlugin,
    {
      client,
      fetchPolicy: 'no-cache',
    }
  )

  const [setEnableMlAudiencePluginRequest] = useMutation<SetEnableMlAudiencePluginMutation, SetEnableMlAudiencePluginMutationVariables>(
    setEnableMlAudiencePlugin,
    {
      client,
      fetchPolicy: 'no-cache',
    }
  )

  useEffect(() => {
    if (!aiAudienceLoading) {
      setToggleAIAudienceInsightsValue(isRegistered)
    }
  }, [aiAudienceLoading])

  useEffect(() => {
    if (!loading && data?.getLabs) {
      setLabs(data?.getLabs)
      setToggleValue(data.getLabs.enabled)
    }
    if (error) {
      logNewRelicError(error)
    }
  }, [loading, error, data])

  const onToggle = (enabled: boolean) => {
    setToggleValue(enabled)
    saveActionLabs({ variables: { enabled, feature: labs.settingsId } })
      .then((res: any) => {
        res.data.setActonLabs.status !== 'ok'
          ? setToggleValue(!enabled)
          : setToastStatus({
              showStatus: true,
              statusMessage: t('Success! Your changes have been saved.'),
              successStatus: true,
            })
      })
      .catch(() => {
        setToggleValue(!enabled)
      })
  }

  const onToggleChatGPT = (enableChatGptPlugin: boolean) => {
    setToggleGPTValue(enableChatGptPlugin)
    saveEnableChatGptComposerPlugin({ variables: { enableChatGptComposerPlugin: enableChatGptPlugin } })
      .then((res) => {
        !res.data?.setEnableChatGptComposerPlugin
          ? setToggleGPTValue(!enableChatGptPlugin)
          : setToastStatus({
              showStatus: true,
              statusMessage: t('Success! Your changes have been saved.'),
              successStatus: true,
            })
      })
      .catch(() => {
        setToggleGPTValue(!enableChatGptPlugin)
      })
  }

  const onToggleAIAudienceInsights = (enableAIAudienceInsights: boolean) => {
    setToggleAIAudienceInsightsValue(enableAIAudienceInsights)
    setEnableMlAudiencePluginRequest({ variables: { enableMlAudiencePlugin: enableAIAudienceInsights } })
      .then(() => {
        setToastStatus({
          showStatus: true,
          statusMessage: t('Success! Your changes have been saved.'),
          successStatus: true,
        })
      })
      .catch((error) => {
        logNewRelicError(error)
        setToggleAIAudienceInsightsValue(!enableAIAudienceInsights)
        setToastStatus({
          showStatus: true,
          statusMessage: t('Something went wrong on our end. Please try again.'),
          successStatus: false,
        })
      })
  }

  const renderCard = (
    titleProps: Partial<ComponentProps<typeof Typography>>,
    bodyProps: Partial<ComponentProps<typeof Typography>>,
    cautionBody: string,
    toggleValue: boolean,
    onToggle: (enabled: boolean) => void
  ) => (
    <>
      <div className={`${rootClass}__section`}>
        <div className={`${rootClass}__section-description`}>
          <div className={`${rootClass}__section-description-title-ctr`}>
            <Typography type={TextType.SECTION_HEADER} className={`${rootClass}__section-description-title`} {...titleProps} />
          </div>
          <Typography type={TextType.BODY_TEXT_LIGHT} lineHeight={LineHeight.MEDIUM} inline {...bodyProps} />
        </div>
        <div className={`${rootClass}__section-action`}>
          <Toggle large isOn={toggleValue} onToggle={onToggle} />
        </div>
      </div>
      <div className={`${rootClass}__section-warning`}>
        <div className={`${rootClass}__section-warning-left`}>
          <Svg name={SvgNames.info} type={SvgType.LARGE_ICON} />
        </div>
        <div className={`${rootClass}__section-warning-right`}>
          <Typography
            text={'GenerativeEmail.Labs.Caution.Title'}
            weight={TextWeight.MEDIUM}
            tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
          />
          <Typography
            text={cautionBody}
            type={TextType.BODY_TEXT_SMALL}
            lineHeight={LineHeight.MEDIUM_SMALL}
            tagComponents={{
              TextLink: <TextLink link={SERVICES_AGREEMENT_LINK} hideIcon className={`${rootClass}__section-warning-link`} />,
            }}
            className={`${rootClass}__section-warning-body`}
          />
          <TextLink text={t('GenerativeEmail.Labs.Caution.Link')} size={TextLinkSize.LARGE} link={SERVICES_AGREEMENT_LINK} />
        </div>
      </div>
    </>
  )

  const chatGPTCard = renderCard(
    { text: t('Act-On AI Create OpenAI Integration') },
    { text: t('Enables an AI-powered tool within the email editor that assists you with generating new, creative content.') },
    t('GenerativeEmail.Labs.Caution.Body'),
    toggleGPTValue,
    onToggleChatGPT
  )

  const aiAudienceInsightsCard = renderCard(
    { text: t('AI Audience Insights') },
    {
      text: t('AIAudienceInsights.Labs.Body'),
      tagComponents: { TextLink: <TextLink link={AI_AUDIENCE_INSIGHTS_INFO_LINK} size={TextLinkSize.LARGE} hideIcon /> },
    },
    t('AIAudienceInsights.Labs.Caution.Body'),
    toggleAIAudienceInsightsValue,
    onToggleAIAudienceInsights
  )

  return (
    <div data-test={dataTest} className={`${rootClass}`}>
      <InfoAction
        svgName={SvgNames.lightBulb}
        message={
          <div>
            <Typography
              text={t(
                "Customize your marketing experience with Act-On's optional Labs features below. Some Labs features are beta release, and we welcome your feedback."
              )}
              inline
            />
            &nbsp;
            <TextLink text={t('Connect with us')} link={LABS_LINK} size={TextLinkSize.LARGE} />
          </div>
        }
        className={`${rootClass}__info-action`}
      />
      {(loading || aiAudienceLoading) && <Loader center />}
      {!loading && !aiAudienceLoading && (
        <>
          {!labs && (
            <EmptyListing
              className={`${rootClass}__empty`}
              headline={t("We don't have any Act-On Labs features to showcase right now.")}
              text={t('Check back later for new features as we innovate ways to improve your Act-On experience.')}
              imgSrc={StaticImageNames.noProgramHistory}
            />
          )}
          {labs && (
            <div className={`${rootClass}__content`}>
              {showChatGPT && chatGPTCard}
              {showAIAudienceInsights && aiAudienceInsightsCard}
              <div className={`${rootClass}__section`}>
                <div className={`${rootClass}__section-description`}>
                  <Typography text={t(labs.title)} type={TextType.SECTION_HEADER} className={`${rootClass}__section-description-title`} />
                  <Typography text={t(labs.description)} type={TextType.BODY_TEXT_LIGHT} lineHeight={LineHeight.MEDIUM} inline />
                  &nbsp;
                  <TextLink text={t('More info')} link={labs.link} size={TextLinkSize.LARGE} />
                </div>
                <div className={`${rootClass}__section-action`}>
                  <Toggle
                    large
                    dataTest={`${dataTest}-labs-toggle`}
                    isOn={toggleValue}
                    onToggle={(e) => {
                      onToggle(e)
                    }}
                  />
                </div>
              </div>
              <Caution message={t(labs.caution)} />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default Labs
