import React, { FC } from 'react'

import classNames from 'classnames'

import ReviewBar from '@complex/GlobalComposerHeader/components/ReviewBar/ReviewBar'
import { ComponserHeaderTabsButtonProps, ComposerHeaderReviewBarProps } from '@complex/GlobalComposerHeader/GlobalComposerHeader'
import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import ButtonWithLoader from '@components/ButtonWithLoader/ButtonWithLoader'
import Svg from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Tabs, { TabsProps } from '@components/TabsAO/TabsAO'
import Tooltip from '@components/Tooltip/Tooltip'

import './ComposerHeaderBottom.css'

export interface ComposerHeaderBottomProps {
  className?: string
  dataTest?: string
  tabsProps: TabsProps
  floatButtons?: ComponserHeaderTabsButtonProps[]
  primaryActionButton?: ComponserHeaderTabsButtonProps
  reviewBarProps?: ComposerHeaderReviewBarProps
  showPrimaryButton?: boolean
  isCustomerCareLogin?: boolean
  loading?: boolean
}

const rootClass = 'global-composer-header-bottom'

const ComposerHeaderBottom: FC<ComposerHeaderBottomProps> = (props: ComposerHeaderBottomProps) => {
  const { dataTest = rootClass, className = '', reviewBarProps, tabsProps, primaryActionButton, floatButtons, isCustomerCareLogin, loading } = props

  const renderFloatButtons = () => {
    return floatButtons?.map(({ text, icon, tooltipContent, disabled, buttonType, hasLoader, onClick }, index) => (
      <Tooltip
        position={'bottom'}
        key={index}
        hide={!tooltipContent}
        trigger={
          <ButtonWithLoader
            loading={loading && hasLoader}
            buttonType={buttonType || ButtonType.FLOAT_TEAL}
            onClick={onClick}
            disabled={disabled}
            className={`${rootClass}__header-button`}
            dataTest={`${dataTest}-float-button-${index}`}
          >
            {icon && <Svg name={icon} />}
            {text}
          </ButtonWithLoader>
        }
      >
        {tooltipContent}
      </Tooltip>
    ))
  }

  const renderPrimaryButton = () => {
    if (!primaryActionButton) return null
    const { text, icon, tooltipContent, disabled, onClick } = primaryActionButton
    return (
      <Tooltip
        position={'bottom'}
        hide={!tooltipContent}
        trigger={
          <Button
            buttonType={ButtonType.PRIMARY}
            iconPosition={ButtonIconPosition.LEFT}
            onClick={onClick}
            disabled={disabled}
            dataTest={`${dataTest}-primary-button`}
          >
            {icon && <Svg name={icon} fill={SvgColor.WHITE} />}
            {text}
          </Button>
        }
      >
        {tooltipContent}
      </Tooltip>
    )
  }

  const tabListContent = (
    <>
      {floatButtons && renderFloatButtons()}
      {primaryActionButton && renderPrimaryButton()}
    </>
  )

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {reviewBarProps ? (
        <ReviewBar {...reviewBarProps} floatButtons={floatButtons} primaryActionButton={primaryActionButton} />
      ) : (
        <Tabs
          dataTest={`${dataTest}-tabs`}
          tabListContent={tabListContent}
          {...tabsProps}
          className={classNames(`${rootClass}__tabs`, {
            [`${rootClass}__tabs__customer-care`]: isCustomerCareLogin,
          })}
        />
      )}
    </div>
  )
}

export default ComposerHeaderBottom
