import React, { FC, useEffect, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { useTranslation } from '@const/globals'
import declineInboxAssets from '@graphql/mutations/declineInboxAssets'
import importInboxAssets from '@graphql/mutations/importInboxAssets'
import getInboxAssetsDataWithoutPreviewInfo from '@graphql/queries/getInboxAssetsDataWithoutPreviewInfo'
import getPreviewInfoForAsset from '@graphql/queries/getPreviewInfoForAsset'
import {
  DeclineInboxAssetResponse,
  DeclineInboxAssetsMutation,
  DeclineInboxAssetsMutationVariables,
  ImportInboxAssetResponse,
  ImportInboxAssetsMutation,
  ImportInboxAssetsMutationVariables,
} from '@graphql/types/mutation-types'
import {
  GetInboxAssetsDataWithoutPreviewInfoQuery,
  GetInboxAssetsDataWithoutPreviewInfoQueryVariables,
  GetPreviewInfoForAssetQuery,
  GetPreviewInfoForAssetQueryVariables,
  InboxAsset as InboxAssetInput,
} from '@graphql/types/query-types'
import {
  GetPreviewParam,
  PreviewAndImportModalContentProps,
} from '@src/pages/MarketingNetwork/Inbox/components/PreviewAndImportModal/PreviewAndImport.types'
import Inbox from '@src/pages/MarketingNetwork/Inbox/Inbox'
import { ImportDeclineOptions, InboxActionErrorResult, InboxAsset, InboxModals } from '@src/pages/MarketingNetwork/Inbox/Inbox.constants'
import { onSearchUtils } from '@src/pages/MarketingNetwork/Inbox/Inbox.utils'
import { Row } from '@tanstack/react-table'
import { useAccountSettings } from '@utils/account/account.utils'
import { logNewRelicError } from '@utils/new-relic.utils'

interface InboxContainerState {
  assets: InboxAsset[]
  visibleAssets: InboxAsset[]
  selectedRows: PreviewAndImportModalContentProps[]
  savedSelectedRows: PreviewAndImportModalContentProps[]
  previewAsset?: InboxAsset
  search: string
  showImport: boolean
  showDecline: boolean
  successToast: string
  actionErrors?: InboxActionErrorResult
  loading: boolean
  reloading: boolean
  loadingPreview: boolean
}

const defaultState: InboxContainerState = {
  assets: [],
  selectedRows: [] as PreviewAndImportModalContentProps[],
  savedSelectedRows: [] as PreviewAndImportModalContentProps[],
  visibleAssets: [],
  search: '',
  showImport: false,
  successToast: '',
  showDecline: false,
  loading: true,
  reloading: true,
  loadingPreview: false,
}

const InboxContainer: FC = () => {
  const [state, setState] = useState<InboxContainerState>(defaultState)

  const client = useApolloClient()
  const { accountId: originalAccountId, parentId } = useAccountSettings()
  const accountId = parentId === '0' ? originalAccountId : parentId
  const { t } = useTranslation()

  const getPreviewItem = async (param: GetPreviewParam) => {
    const { data } = await client.query<GetPreviewInfoForAssetQuery, GetPreviewInfoForAssetQueryVariables>({
      query: getPreviewInfoForAsset,
      variables: {
        assetId: param.id ?? '',
        assetType: param.type ?? '',
        sharedAccountId: param.sharedAccountId ?? '',
      },
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    })

    try {
      if (data.getPreviewInfoForAsset) {
        const { parentAssetChangeOrReplace, parentAssetPreviewInfo, assets } = data.getPreviewInfoForAsset
        const foundObj = state.assets.find((_asset, idx) => idx === param.index)

        return { ...foundObj, changeOrReplace: parentAssetChangeOrReplace, previewInfo: parentAssetPreviewInfo, assets: assets }
      }
    } catch (error) {
      logNewRelicError(error)
    }
  }

  useEffect(() => {
    const getItems = async () => {
      const { data, errors } = await client.query<GetInboxAssetsDataWithoutPreviewInfoQuery, GetInboxAssetsDataWithoutPreviewInfoQueryVariables>({
        query: getInboxAssetsDataWithoutPreviewInfo,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      })

      if (data.getInboxAssetsDataWithoutPreviewInfo) {
        const assets: InboxAsset[] = data.getInboxAssetsDataWithoutPreviewInfo.map((asset) => asset as InboxAsset)
        setState((prevState) => ({
          ...prevState,
          selectedRows: prevState.savedSelectedRows ? prevState.savedSelectedRows : [],
          assets,
          visibleAssets: assets,
          loading: false,
          reloading: false,
          savedSelectedRows: [],
        }))
      } else if (errors) {
        setState((prevState) => ({ ...prevState, loading: false, reloading: false, selectedRows: [...prevState.savedSelectedRows] }))
      }
    }

    if (state.loading || state.reloading) {
      getItems()
    }
  }, [client, state.loading, state.reloading])

  const onSearch = (term: string) => {
    const visibleAssets = onSearchUtils(term, state.assets)
    setState({ ...state, search: term, visibleAssets })
  }

  const onShowModal = async (modal: string, row?: Row<InboxAsset>) => {
    if (!row && !state.selectedRows.length) {
      return
    }

    const singleBulkRow = state.selectedRows.length === 1 ? state.selectedRows[0] : undefined
    const singleBulkIndex = singleBulkRow ? state.assets.findIndex((asset) => asset.id === singleBulkRow.id) : undefined

    type AssetParam = Partial<InboxAsset> & { index?: number }
    const assetParam: AssetParam = row
      ? { id: row?.original.id, sharedAccountId: row?.original.sharedAccountId, type: row?.original.type, index: row?.index }
      : singleBulkRow
      ? { id: singleBulkRow.id, sharedAccountId: singleBulkRow.sharedAccountId, type: singleBulkRow.type, index: singleBulkIndex }
      : {}

    const close = modal === InboxModals.IMPORT ? state.showImport : state.showDecline
    if (close) {
      const selectedRows = state.savedSelectedRows.length
        ? state.savedSelectedRows
        : (row?.original as unknown as PreviewAndImportModalContentProps)
        ? []
        : state.selectedRows
      setState({ ...state, showImport: false, showDecline: false, selectedRows, savedSelectedRows: [] })
      return
    }

    const getPreviewItemWithLoader = async (assetParam: AssetParam) => {
      setState((state) => ({ ...state, loadingPreview: true }))
      const response = await getPreviewItem(assetParam)
      setState((state) => ({ ...state, loadingPreview: false }))
      return response
    }

    const asset = assetParam.id ? await getPreviewItemWithLoader(assetParam) : undefined
    const selected = asset ? { selectedRows: [asset as PreviewAndImportModalContentProps], savedSelectedRows: state.selectedRows } : {}
    modal === InboxModals.DECLINE ? setState({ ...state, ...selected, showDecline: true }) : setState({ ...state, ...selected, showImport: true })
  }

  const flattenInboxAssetResponse = (response: ImportInboxAssetResponse[] | DeclineInboxAssetResponse[]) => {
    if (!response.length) {
      return { successIds: [], failedIds: [] }
    }

    if ('successImportedIds' in response[0]) {
      const data = response as ImportInboxAssetResponse[]
      const successIds = data.flatMap((value) => value.successImportedIds.map((id) => id ?? ''))
      const failedIds = data.flatMap((value) => value.failedImportedIds.map((id) => id ?? ''))

      return {
        successIds,
        failedIds,
      }
    }

    const data = response as DeclineInboxAssetResponse[]
    const successIds = data.flatMap((value) => value.successDeclinedIds.map((id) => id ?? ''))
    const failedIds = data.flatMap((value) => value.failedDeclinedIds.map((id) => id ?? ''))

    return {
      successIds,
      failedIds,
    }
  }

  const getInboxAssetMutationInput = (rows = state.selectedRows): InboxAssetInput[] => {
    const uniqueAccountIds = [...new Set(rows.map((row) => row.sharedAccountId))]
    return uniqueAccountIds.map((sharedAccountId) => {
      const assetsUnderAccount = rows.filter((row) => row.sharedAccountId === sharedAccountId)
      return {
        inboxAssetIdAndTypeList: assetsUnderAccount.map((asset) => ({ type: asset.type, id: asset.id })),
        sharedAccountId: sharedAccountId ?? accountId ?? '',
      }
    })
  }

  const onImport = async () => {
    const { data, errors } = await client.mutate<ImportInboxAssetsMutation, ImportInboxAssetsMutationVariables>({
      mutation: importInboxAssets,
      errorPolicy: 'all',
      variables: {
        inboxAssets: getInboxAssetMutationInput(),
      },
    })

    if (data?.importInboxAssets) {
      const response = flattenInboxAssetResponse(data?.importInboxAssets as ImportInboxAssetResponse[])

      if (response.failedIds?.length === 0) {
        const successToast = t('Inbox.Import.Success', { count: response.successIds.length })
        setState((prevState) => ({ ...prevState, reloading: true, showImport: false, successToast }))
      } else {
        const successes = response.successIds.map((id) => state.assets.find((asset) => asset.id === id)?.title ?? '').filter((title) => title !== '')
        const errors = response.failedIds.map((id) => state.assets.find((asset) => asset.id === id)?.title ?? '').filter((title) => title !== '')

        const actionErrors: InboxActionErrorResult = {
          actionType: ImportDeclineOptions.IMPORT,
          successes,
          errors,
        }
        setState({ ...state, showImport: false, actionErrors })
      }
    } else if (errors) {
      logNewRelicError(errors)
      const errorsA = state.selectedRows.map((asset) => asset.title)
      const actionErrors: InboxActionErrorResult = {
        actionType: ImportDeclineOptions.IMPORT,
        successes: [],
        errors: errorsA,
      }
      setState({ ...state, showImport: false, actionErrors })
    }
  }

  const onDecline = async () => {
    const { data, errors } = await client.mutate<DeclineInboxAssetsMutation, DeclineInboxAssetsMutationVariables>({
      mutation: declineInboxAssets,
      errorPolicy: 'all',
      variables: {
        inboxAssets: getInboxAssetMutationInput(),
      },
    })

    if (data?.declineInboxAssets) {
      const response = flattenInboxAssetResponse(data?.declineInboxAssets as DeclineInboxAssetResponse[])

      if (response.failedIds.length === 0) {
        const successToast = t('Inbox.Decline.Success', { count: response.successIds.length })
        setState((prevState) => ({ ...prevState, reloading: true, showDecline: false, successToast }))
      } else {
        const successes = response.successIds.map((id) => state.assets.find((asset) => asset.id === id)?.title ?? '').filter((title) => title !== '')
        const errorsD = response.failedIds.map((id) => state.assets.find((asset) => asset.id === id)?.title ?? '').filter((title) => title !== '')
        const actionErrors: InboxActionErrorResult = {
          actionType: ImportDeclineOptions.DECLINE,
          successes,
          errors: errorsD,
        }
        setState({ ...state, showDecline: false, actionErrors })
      }
    } else if (errors) {
      logNewRelicError(errors)
      const errorsA = state.selectedRows.map((asset) => asset.title)
      const actionErrors: InboxActionErrorResult = {
        actionType: ImportDeclineOptions.DECLINE,
        successes: [],
        errors: errorsA,
      }
      setState({ ...state, showDecline: false, actionErrors })
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onRowSelectionChanged = (rowIds: string[], _rows: Row<InboxAsset>[]) => {
    if (rowIds.length === 0) {
      setState({ ...state, selectedRows: [] })
    } else {
      const updatedSelections: PreviewAndImportModalContentProps[] = []
      rowIds.forEach((id) => {
        const row = state.assets.find((asset) => asset.id === id)
        if (row) {
          updatedSelections.push(row as PreviewAndImportModalContentProps)
        }
      })
      setState({ ...state, selectedRows: updatedSelections })
    }
  }

  const closeSuccessToast = () => setState((prevState) => ({ ...prevState, successToast: '' }))

  const closeErrorModal = () => setState({ ...state, successToast: '', actionErrors: undefined })

  return (
    <Inbox
      getPreviewItem={getPreviewItem}
      assets={state.visibleAssets}
      selectedRows={state.selectedRows}
      showImport={state.showImport}
      showDecline={state.showDecline}
      successToast={state.successToast}
      closeSuccessToast={closeSuccessToast}
      search={state.search}
      onSearch={onSearch}
      onShowModal={onShowModal}
      onImport={onImport}
      onDecline={onDecline}
      onCloseErrorModal={closeErrorModal}
      actionErrors={state.actionErrors}
      onRowSelectionChanged={onRowSelectionChanged}
      loading={state.loading || state.reloading}
      loadingPreview={state.loadingPreview}
    />
  )
}

export default InboxContainer
