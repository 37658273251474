import React, { FC } from 'react'

import classNames from 'classnames'

import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './AddCRMTaskDetail.css'

export interface AddCRMTaskDetailProps {
  subject: string
  priority: string
  status: string
  ownerName?: string
  comment?: string
  dateUnit?: string
  dateAmount?: string
  className?: string
  dataTest?: string
}

const rootClass = 'add-crm-task-detail'

const AddCRMTaskDetail: FC<AddCRMTaskDetailProps> = (props: AddCRMTaskDetailProps) => {
  const { subject, priority, ownerName, comment, dateUnit, dateAmount, status, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()

  const ownerNameValue = ownerName ? ownerName : 'Contact Owner'
  const renderTask = () => {
    const fields = [
      { fieldKey: 'Assigned to', fieldValue: ownerNameValue },
      { fieldKey: 'Subject', fieldValue: subject },
      { fieldKey: 'Status', fieldValue: status },
      { fieldKey: 'Priority', fieldValue: priority },
    ]
    return fields.map((fields, idx: number) => (
      <div key={`field-${idx}`}>
        <Typography text={`${t(fields.fieldKey)}: `} inline />
        {fields.fieldValue !== '' && <Typography text={fields.fieldValue} weight={TextWeight.MEDIUM} inline />}
        {fields.fieldValue === '' && <Typography text={t('undefined')} weight={TextWeight.ITALIC} inline />}
      </div>
    ))
  }

  const renderComment = () => (
    <div>
      <Typography text={`${t('Comments')}: `} inline />
      <div className={`${rootClass}__comments`}>
        <Typography text={comment} weight={TextWeight.MEDIUM} inline />
      </div>
    </div>
  )

  const renderDueDate = () => {
    const dateUnitText = dateAmount && parseInt(dateAmount) === 1 ? dateUnit : `${dateUnit}s`

    return dateAmount ? (
      <div>
        <Typography text={`${t('Due Date')}: `} inline />
        <Typography text={dateAmount} weight={TextWeight.MEDIUM} inline />
        {'  '}
        <Typography text={dateUnitText} weight={TextWeight.MEDIUM} inline />
      </div>
    ) : (
      <div>
        <Typography text={`${t('Due Date')}: `} inline />
        <Typography text={dateUnit} weight={TextWeight.MEDIUM} inline />
      </div>
    )
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {renderTask()}
      {dateUnit && renderDueDate()}
      {comment && renderComment()}
    </div>
  )
}

export default AddCRMTaskDetail
