import { NAMESPACE } from './Navigation.state.constants'

export default {
  showNavigation: `${NAMESPACE}/SHOW_NAVIGATION`,
  hideNavigation: `${NAMESPACE}/HIDE_NAVIGATION`,
  hideMenu: `${NAMESPACE}/HIDE_MENU`,
  toggleExpanded: `${NAMESPACE}/TOGGLE_EXPANDED`,
  toggleDisabled: `${NAMESPACE}/TOGGLE_DISABLED`,
  toggleModalOpened: `${NAMESPACE}/TOGGLE_MODAL_OPENED`,
  setNavigationPath: `${NAMESPACE}/SET_NAVIGATION_PATH`,
}
