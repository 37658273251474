import React, { FC, useContext, useRef } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Loader from '@components/Loader'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Typography, { ModalBodyStyle } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { rootTranslation } from '@src/pages/RecommendedSegments/components/SegmentsInsightsSettingsContainer/SegmentsInsightsSettingsContainer'
import SegmentsInsightsAudienceSettings from '@src/pages/RecommendedSegments/components/SegmentsInsightsSettingsModal/components/SegmentsInsightsAudienceSettings/SegmentsInsightsAudienceSettings'
import SegmentsInsightsCriteriaSettings from '@src/pages/RecommendedSegments/components/SegmentsInsightsSettingsModal/components/SegmentsInsightsCriteriaSettings/SegmentsInsightsCriteriaSettings'
import SegmentsInsightsTimeSettings from '@src/pages/RecommendedSegments/components/SegmentsInsightsSettingsModal/components/SegmentsInsightsTimeSettings/SegmentsInsightsTimeSettings'
import { RecommendedSegmentsContext } from '@src/pages/RecommendedSegments/utils/RecommendedSegments.context'

import './SegmentsInsightsSettingsModal.css'

interface SegmentsInsightsSettingsModalProps {
  className?: string
  dataTest?: string
  onClose: () => void
  isOpen: boolean
}

const rootClass = 'segments-insights-settings-modal'

const SegmentsInsightsSettingsModal: FC<SegmentsInsightsSettingsModalProps> = (props: SegmentsInsightsSettingsModalProps) => {
  const { dataTest = rootClass, className = '', onClose, isOpen } = props

  const {
    values: { loadingSettings },
  } = useContext(RecommendedSegmentsContext)

  const showingListPicker = useRef(false)

  const { t } = useTranslation()

  const handleClose = () => {
    onClose()
  }

  const onShowListPickerChange = (isShown: boolean) => (showingListPicker.current = isShown)

  const header = (
    <ModalHeader headerType={ModalHeaderType.List} className={`${rootClass}__header`}>
      {t(`${rootTranslation}.Header`)}
    </ModalHeader>
  )

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
      <ModalBody className={`${rootClass}__body`}>
        <Typography text={t(`${rootTranslation}.Subheader`)} {...ModalBodyStyle} />
        {loadingSettings ? (
          <Loader center />
        ) : (
          <>
            <SegmentsInsightsAudienceSettings onShowListPickerChange={onShowListPickerChange} />
            <SegmentsInsightsCriteriaSettings />
            <SegmentsInsightsTimeSettings />
          </>
        )}
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.List} className={`${rootClass}__footer`}>
        <Button buttonType={ButtonType.PRIMARY} onClick={handleClose} dataTest={`${dataTest}-button-primary`} disabled={loadingSettings}>
          {t('Done')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default SegmentsInsightsSettingsModal
