import React, { FC } from 'react'

import classNames from 'classnames'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { ItemType } from '@utils/categorization'

import './SubmissionDetails.css'

interface SubmissionDetailsProps {
  className?: string
  dataTest?: string
  formName: string
  data: FieldsData[]
  itemType: ItemType
  icon?: SvgNames
}

export interface FieldsData {
  field: { name: string; required?: boolean; hidden?: boolean }
  response: string
}

const rootClass = 'submission-details'

const SubmissionDetails: FC<SubmissionDetailsProps> = (props: SubmissionDetailsProps) => {
  const { dataTest = rootClass, className = '', data, formName, itemType, icon } = props
  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__header`}>
        {icon && <Svg name={icon} type={SvgType.LARGE_ICON} className={`${rootClass}__icon`} />}
        <Typography
          className={`${rootClass}__title`}
          text={formName}
          weight={TextWeight.MEDIUM}
          type={TextType.BODY_TEXT_LARGE}
          lineHeight={LineHeight.MEDIUM_LARGE}
        />
      </div>
      <div className={`${rootClass}__content`}>
        {data.map((item, index) => (
          <div key={index} className={`${rootClass}__item`}>
            <Typography
              className={`${rootClass}__item-field`}
              text={`ListPage.FormsJoinView.${itemType}.DetailsCard.${
                itemType === ItemType.WEBINAR_SUBMISSION ? 'Registration' : 'Submission'
              }Details.Field`}
              values={{ fieldName: item.field.name, required: item.field.required ? '*' : '', hidden: item.field.hidden ? '(hidden field)' : '' }}
              tagProps={{ bold: { weight: TextWeight.BOLD } }}
              type={TextType.BODY_TEXT_SMALL}
              lineHeight={LineHeight.MEDIUM_SMALL}
            />
            <Typography
              className={`${rootClass}__item-response`}
              text={item.response}
              type={TextType.BODY_TEXT_SMALL}
              lineHeight={LineHeight.MEDIUM_SMALL}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default SubmissionDetails
