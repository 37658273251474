import React, { FC } from 'react'

import ManageContactsOrStartModal, {
  ManageContactsOrStartModalProps,
} from '@src/pages/programs/dashboard/components/ProgramPerformance/components/ManageContactsOrStartModal/ManageContactsOrStartModal'

interface WrapperProps extends Omit<ManageContactsOrStartModalProps, 'onAddContactsToProgram' | 'onExitContactsFromProgram'> {
  onSubmit: (exitContacts: boolean) => any
}

const ManageContactsOrStartModalWrapper: FC<WrapperProps> = (props: WrapperProps) => {
  const { onSubmit, onClose, pendingContacts } = props

  return (
    <ManageContactsOrStartModal
      {...props}
      isOpen
      pendingContacts={pendingContacts ?? 0}
      onAddContactsToProgram={() => {
        onSubmit(true)
      }}
      onExitContactsFromProgram={() => {
        onSubmit(false)
      }}
      onClose={() => {
        onClose && onClose()
      }}
    />
  )
}

export default ManageContactsOrStartModalWrapper
