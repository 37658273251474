import React, { FC } from 'react'

import classNames from 'classnames'

import Search, { SearchType } from '@components/Search/Search'
import { useTranslation } from '@const/globals'
import UsersCountInfo from '@src/pages/Settings/Users/components/UsersCountInfo/UsersCountInfo'
import { NEED_MORE_LINK } from '@src/pages/Settings/Users/constants/users.constants'

interface Props {
  onSearch: (state: string) => void
  searchTerm: string
  className?: string
  dataTest?: string
  marketingUsersCount: number
  maxAllowedMarketingUsersCount: number
  loading: boolean
}

const rootClass = 'marketing-users__table-info-bar'

const MarketingUsersTableInfoBar: FC<Props> = (props) => {
  const { onSearch, searchTerm, dataTest = rootClass, marketingUsersCount, maxAllowedMarketingUsersCount, loading = false } = props
  const { t } = useTranslation()
  return (
    <div
      className={classNames(rootClass, {
        [`${rootClass}__hidden`]: loading,
      })}
    >
      <UsersCountInfo
        labelKey="Marketing.Users.Count.Label"
        tooltipKey="Marketing.Users.Count.Tooltip"
        needMoreLink={NEED_MORE_LINK}
        currentCount={maxAllowedMarketingUsersCount - marketingUsersCount}
        maxCount={maxAllowedMarketingUsersCount}
        dataTest={`${rootClass}-marketing`}
      />
      <Search
        className={`${rootClass}__search`}
        dataTest={`${dataTest}-search-input`}
        incomingValue={searchTerm}
        onChangeHandler={onSearch}
        placeholder={t('Search users')}
        searchType={SearchType.LARGE}
        canClear
        clearOnChange={[searchTerm]}
      />
    </div>
  )
}

export default MarketingUsersTableInfoBar
