import React, { FC } from 'react'

import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { TextType } from '@components/Typography/Typography'
import { useAllUsers } from '@src/pages/Settings/Users/context/UsersContext'
import { MARKETING_USERS_MORE_INFO } from '@src/pages/Settings/Users/tabs/MarketingUsers/constants/marketingUsers.constants'

const rootClass = 'marketing-users__sub-header'
const dataTest = rootClass

const MarketingUsersSubHeader: FC = () => {
  const { isAdministrator } = useAllUsers()
  return (
    <div className={`${rootClass}-container`}>
      <Typography
        text="Marketing.Users.Sub.Header.Description"
        inline
        type={TextType.BODY_TEXT_LIGHT}
        dataTest={`${dataTest}-description`}
        values={{
          context: isAdministrator ? 'admin' : 'nonAdmin',
        }}
        tagComponents={{
          TextLink: <TextLink link={MARKETING_USERS_MORE_INFO} size={TextLinkSize.LARGE} dataTest={`${dataTest}-text-link`} />,
        }}
      />
    </div>
  )
}

export default MarketingUsersSubHeader
