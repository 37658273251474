import React, { FC, useMemo, useRef } from 'react'
import { Column, Row, useFlexLayout, useTable } from 'react-table'

import classNames from 'classnames'

import SpacerBlock, { MAX_NESTING } from '@components/IndentedTable/components/SpacerBlock'
import { TableColumn } from '@components/Table/Table'
import Typography, { TextType } from '@components/Typography/Typography'

import './IndentedTable.css'

interface Props {
  data: Row[]
  columns: TableColumn[]
  initialState?: any
  ignoreHeaderHover?: boolean
  className?: string
  dataTest?: string
  expandall?: boolean
}
const rootClass = 'indented-table'

const IndentedTable: FC<Props> = (props: Props) => {
  const { data, columns, initialState, ignoreHeaderHover, dataTest = rootClass, className = '' } = props

  const tableRef = useRef(null)

  const enhancedColumns = useMemo(() => [...columns], [])
  const tableInstance = useTable(
    {
      columns: enhancedColumns as Column<any>[],
      data,
      initialState,
    },
    useFlexLayout
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance
  const getColumnAlignClass = (id: string, isTD = true) => {
    const column = enhancedColumns.find((col) => col.accessor === id)
    const base = `${rootClass}__${isTD ? 'td' : 'th'}`
    const extra = column && column.className ? column.className : ''
    const align = column ? (column.align ? `${rootClass}__${column.align}` : `${rootClass}__left`) : `${rootClass}__left`
    return `${base} ${align} ${extra}`
  }

  const renderSpacers = (initialLength: number) => {
    const length = initialLength > MAX_NESTING ? MAX_NESTING : initialLength
    return Array.from({ length }, (_, i: number) => i + 1)
  }

  const rawTableProps = getTableProps()
  const style = { ...rawTableProps.style, minWidth: 'auto' }
  const tableProps = { ...rawTableProps, style }

  return (
    <div
      className={classNames(`${rootClass}__holder`, {
        [`${className}__holder`]: className,
      })}
      data-test={dataTest}
    >
      <div
        className={classNames(rootClass, className, {
          [`${rootClass}__ignore-header-hover`]: ignoreHeaderHover,
        })}
        {...tableProps}
        ref={tableRef}
      >
        <div className={`${rootClass}__thead`}>
          {headerGroups.map((headerGroup, i) => {
            const rawHeaderGroupProps = headerGroup.getHeaderGroupProps()
            const headerGroupProps = { ...rawHeaderGroupProps, style }
            return (
              <div className={`${rootClass}__tr`} {...headerGroupProps} key={`trh-${i}`}>
                {headerGroup.headers.map((column, idx) => {
                  const rawHeaderProps = column.getHeaderProps()
                  const twoREM = 24
                  const firstOrLast = headerGroup.headers.length - 1 == idx || idx === 0
                  const style = {
                    ...rawHeaderProps.style,
                    minWidth: firstOrLast ? (column.minWidth ?? 0) + twoREM : column.minWidth,
                    maxWidth: firstOrLast ? (column.maxWidth ?? 0) + twoREM : column.maxWidth,
                  }
                  const headerProps = { ...rawHeaderProps, style }
                  return (
                    <div {...headerProps} className={getColumnAlignClass(column.id, false)} key={`trh-${i}-${idx}`}>
                      <Typography text={column.render('Header')} type={TextType.TABLE_HEADER} />
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
        <div className={`${rootClass}__tbody`} {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            const rawRowProps = row.getRowProps()
            // @ts-ignore
            const depth = row.original.depth
            const paddingLeft = depth === 1 ? '2rem' : 0
            const style = { ...rawRowProps.style, minWidth: 'auto', paddingLeft }
            const rowProps = { ...rawRowProps, style }

            return (
              <div {...rowProps} className={`${rootClass}__tr`} key={`tr-${i}`}>
                <>
                  {depth > 1 &&
                    renderSpacers(depth).map((spacerIndex) => (
                      <SpacerBlock key={`${spacerIndex}-depth-${depth}`} rows={rows} depth={depth} parentIndex={i} spacerIndex={spacerIndex} />
                    ))}
                  {row.cells.map((cell, idx) => {
                    const rawCellProps = cell.getCellProps()
                    const style = {
                      ...rawCellProps.style,
                      flex: `${cell.column.maxWidth} 0 auto`,
                      maxWidth: cell.column.maxWidth,
                    }
                    const cellProps = { ...rawCellProps, style }
                    const nextIndex = i + 1
                    const prevIndex = i - 1
                    // @ts-ignore
                    const isNextRowTop = nextIndex < rows.length && rows[nextIndex].original.depth === 1
                    // @ts-ignore
                    const isPreviousRowIndented = prevIndex > 0 && rows[prevIndex].original.depth > 1
                    return (
                      <div
                        {...cellProps}
                        className={classNames(getColumnAlignClass(cell.column.id, true), {
                          [`${rootClass}__td-bottom-border`]: depth === 1 && isNextRowTop,
                          [`${rootClass}__td-top-border`]: depth === 1 && isPreviousRowIndented,
                        })}
                        key={`td-${i}-${idx}`}
                      >
                        {cell.render('Cell')}
                      </div>
                    )
                  })}
                </>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default IndentedTable
