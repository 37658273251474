import { gql } from '@apollo/client'

export default gql`
  query getWebinarRelatedForms($webinarId: String, $webinarType: WebinarType) {
    getWebinarRelatedForms(webinarId: $webinarId, webinarType: $webinarType) {
      accountId
      createdTime
      enabled
      formId
      formName
      listId
      totalContacts
      totalSubmissions
      updatedTime
    }
  }
`
