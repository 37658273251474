import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'

import './SectionWithHeader.css'

interface SectionWithHeaderProps {
  className?: string
  dataTest?: string
  header: string
  children: ReactNode
}

const rootClass = 'section-with-header'

const SectionWithHeader: FC<SectionWithHeaderProps> = (props: SectionWithHeaderProps) => {
  const { dataTest = rootClass, className = '', header, children } = props
  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={classNames(`${rootClass}__header`, { [`${className}-header`]: className })}>
        <Typography text={header} type={TextType.NORMAL_TEXT_TEAL} lineHeight={LineHeight.MEDIUM_LARGE} weight={TextWeight.BOLD} />
      </div>
      {children}
    </div>
  )
}

export default SectionWithHeader
