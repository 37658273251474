import { REQUIRED_MESSAGE_KEY } from '@components/InputWithStatus/InputWithStatus'

export const CONTENT_PERSONALIZATION_MORE_INFO_LINK =
  'https://success.act-on.com/acton/attachment/9883/u-bc6b9e3b-5288-4cc2-8532-7abca09e4701/0/-/-/-/-/In-App%3A%20Custom%20Account%20Personalizations'
export const CONTENT_VARIABLE_MAX_LENGTH = 25

export const CONTENT_PERSONALIZATION_VALIDATION_MESSAGES = {
  [REQUIRED_MESSAGE_KEY]: 'Provide a name',
  maxLength: 'Name must be not more than 25 characters',
  hasSpace: `You can\'t use a space in content name.`,
  isUnique: 'This name is already in use. Please enter a different name.',
}
