import { match } from 'react-router'

import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { GraphQLError } from 'graphql'
import * as HistoryInterface from 'history'
import { TFunction } from 'i18next'

import { ModuleTypes } from '@beefree.io/sdk/dist/types/bee'
import { SvgNames } from '@components/Svg'
import { TimePickerV2Format } from '@components/TimePickerV2/TimePickerV2'
import { getUUID, rootContext } from '@const/globals'
import { GetDataCardsQuery } from '@graphql/types/microservice/segment-types'
import { MessageType } from '@graphql/types/mutation-types'
import { PrevPageParamsEnum, TouchpointsLocalStorageKeys } from '@src/pages/EmailComposer/utils/EmailComposer.types'
import { detectEmailType, detectMessageTypeByLetter } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { filterNotEmptyArray } from '@utils/array'
import { IEntityContentJsonExtended, IPluginRowExtended } from '@utils/composer/beeEditor/beeEditorTypes'
import { CommonComposerTab } from '@utils/composer/commonComposer/CommonComposer.context'
import {
  SendMethod as ContextSendMethod,
  DEFAULT_EMAIL_TITLE,
  EmailComposerAPI,
  EmailComposerState,
} from '@utils/composer/context/EmailComposer.context'
import { EmailComposerRequests } from '@utils/composer/emailComposer/GraphQL/EmailComposerRequests.graphQL'
import { messageConfigurationSettings, MessageConfigurationType } from '@utils/composer/emailComposer/types'
import { FORM_AUTORESPONSE_EMAIL_IDS } from '@utils/composer/settings.constants'
import { logNewRelicError } from '@utils/new-relic.utils'
import { DeepUpdateState, FetchPromise, MatchParams } from '@utils/types'
import { getSessionStorageItem, removeSessionStorageItem } from '@utils/window'

import { IS_FIRST_MSG_OPENER, SIGNATURE_DATA_ATTRIBUTE } from './BeeEditor.constants'
import { AddOnModal } from './BeeEditor.types'
import { checkRowContentIsEmpty, getDataIdAttributeFromHTML } from './BeeEditor.utils'
import { T_BEE_BLANK, UPDATE_SESSION_DELAY } from './EmailComposer.constants'
import { ConfirmationEmailIds, ProcessCurrentDateForLaterOrTimezone, ProcessCurrentDateForStaggerOrAdaptive } from './EmailComposer.types'
import { SelectedDynamicImage, SelectedFallbackImage } from '../EmailModals/components/ImageFileModal/InsertImageModalContainer'
import { getSourceMetadataFromImageURl, InsertImageFlow } from '../EmailModals/components/ImageFileModal/utils/InsertImageModal.utils'

dayjs.extend(utc)
dayjs.extend(timezone)

export const getTimeStringFromHourAndMinute = (hour?: number, minute?: number): string | undefined => {
  if (typeof hour !== 'number' || typeof minute !== 'number') {
    return undefined
  }
  const ampm = hour > 11 ? 'PM' : 'AM'
  // pass '12' instead of '0'
  const _hour = hour % 12 || 12
  return `${_hour}:${minute} ${ampm}`
}

export const getTimeStampForTimezone = (date: Date, timeZone?: string): number => {
  return dayjs(date).tz(timeZone, true).valueOf()
}

export const getHourAndMinuteFromTime = (time?: string): Partial<{ hour: number; minute: number }> => {
  if (!time) {
    return {}
  }
  const date = dayjs(time, TimePickerV2Format.HH_MM)
  if (!date.isValid) {
    return {}
  }
  return { hour: date.hour(), minute: date.minute() }
}

export const enableFeature = (flag: string) => {
  const searchParams = new URLSearchParams(window.location.search)
  const flagLower = flag.toLowerCase()

  return (
    [flag, flagLower].some((f) => searchParams.get(f) !== null) ||
    [flag, flagLower].some((f) => sessionStorage.getItem(f) !== null) ||
    [flag, flagLower].some((f) => localStorage.getItem(f) !== null)
  )
}

export const processCurrentDateForLaterOrTimezone = ({
  currentTimezoneDate,
  givenDate,
  currentTimezoneAmOrPm,
  givenAmOrPm,
  checkTime,
  sendDetailsFieldsToUpdate,
  resetProps,
}: ProcessCurrentDateForLaterOrTimezone): Partial<ContextSendMethod> => {
  let processedFields: Partial<ContextSendMethod> = sendDetailsFieldsToUpdate

  if (
    currentTimezoneDate.isAfter(givenDate, 'day') ||
    (currentTimezoneDate.isSame(givenDate, 'day') &&
      ((currentTimezoneAmOrPm === 'PM' && givenAmOrPm === 'AM') ||
        (currentTimezoneAmOrPm === 'PM' && givenAmOrPm === 'PM' && checkTime) ||
        (currentTimezoneAmOrPm === 'AM' && givenAmOrPm === 'AM' && checkTime)))
  ) {
    processedFields = {
      ...sendDetailsFieldsToUpdate,
      ...resetProps,
    }
  }

  return processedFields
}

export const processCurrentDateForStaggerOrAdaptive = ({
  start,
  end,
  currentTimezoneDate,
  sendDetailsFieldsToUpdate,
  resetProps,
  fieldToOverride,
}: ProcessCurrentDateForStaggerOrAdaptive) => {
  let processedFields: { [k: string]: unknown } = sendDetailsFieldsToUpdate

  const currDate = new Date(currentTimezoneDate.valueOf())
  currDate.setHours(0, 0, 0, 0)

  if (currDate.valueOf() > start.valueOf() && currDate.valueOf() <= end.valueOf()) {
    processedFields[fieldToOverride] = dayjs(currDate)
  } else if (currDate.valueOf() > end.valueOf()) {
    processedFields = resetProps
  }

  return processedFields
}

export const findDynamicImageFields = (
  json: IEntityContentJsonExtended,
  listId?: string | null,
  dynamicField?: string | null
): {
  selectedItemDynamicImage?: SelectedDynamicImage
  selectedItemFallbackImage?: SelectedFallbackImage
} => {
  const result: {
    selectedItemDynamicImage: SelectedDynamicImage
    selectedItemFallbackImage: SelectedFallbackImage
  } = { selectedItemDynamicImage: { id: '', title: '' }, selectedItemFallbackImage: { id: '', title: '', url: '' } }

  json.page.rows.forEach((row) => {
    row.columns.forEach((column) => {
      column.modules.forEach((module: any) => {
        if (module.type === ModuleTypes.IMAGE && module.descriptor.image.dynamicImageFields) {
          const dynamicImageFields = module.descriptor.image.dynamicImageFields

          if (dynamicImageFields?.selectedItemDynamicImage.id === listId && dynamicImageFields?.selectedItemDynamicImage.title === dynamicField) {
            result.selectedItemDynamicImage = {
              ...dynamicImageFields.selectedItemDynamicImage,
            }
            if (dynamicImageFields.selectedItemFallbackImage) {
              result.selectedItemFallbackImage = {
                ...dynamicImageFields.selectedItemFallbackImage,
              }
            }
          }
        }
      })
    })
  })

  return {
    selectedItemDynamicImage: result.selectedItemDynamicImage.id === '' ? undefined : result.selectedItemDynamicImage,
    selectedItemFallbackImage: result.selectedItemFallbackImage.id === '' ? undefined : result.selectedItemFallbackImage,
  }
}

export const addBlockIdsIntoJson = (templateJson: IEntityContentJsonExtended) => {
  if (Array.isArray(templateJson?.page?.rows)) {
    templateJson.page.rows.forEach((row: IPluginRowExtended) => {
      if (Array.isArray(row.columns)) {
        row.columns.forEach((column) => {
          if (Array.isArray(column.modules)) {
            column.modules.forEach((module: any) => {
              switch (module.type) {
                case ModuleTypes.IMAGE:
                  if (module.descriptor?.image) {
                    const src = module.descriptor.image.src
                    const { id, dynamicField, listId, fallbackTitle } = getSourceMetadataFromImageURl(src)

                    if (id) {
                      module.descriptor.image.id = id
                    }

                    if (dynamicField && (src.includes(InsertImageFlow.DYNAMIC_IMAGE) || src.includes(InsertImageFlow.DYNAMIC_LOGO))) {
                      module.descriptor.image.dynamicImageFields = {
                        dynamicSrc: `{{={{${dynamicField}}}|${src}}}`,
                        selectedItemDynamicImage: { id: listId, title: dynamicField } as SelectedDynamicImage,
                        selectedItemFallbackImage: {
                          id,
                          title: fallbackTitle,
                          url: src,
                        } as SelectedFallbackImage,
                      }
                    }
                  }
                  break
                case ModuleTypes.ADDON:
                  if (module.moduleInternal?.uid === AddOnModal.SIGNATURE_BLOCK && module.descriptor?.html?.html) {
                    const id = getDataIdAttributeFromHTML(module.descriptor.html.html, SIGNATURE_DATA_ATTRIBUTE)
                    if (id) {
                      module.descriptor.html.id = id
                    }
                  }
                  break
                case ModuleTypes.ICONS:
                  if (Array.isArray(module.descriptor?.iconsList?.icons)) {
                    module.descriptor.iconsList.icons.forEach((icon: any) => {
                      const { id } = getSourceMetadataFromImageURl(icon.image)
                      if (id) {
                        icon.id = id
                      }
                    })
                  }
                  break
              }
            })
          }
        })

        //Removing metadata from Header/Footer rows with empty content
        const isHeaderOrFooter = row.rowInternal?.uid === AddOnModal.HEADER_BLOCK || row.rowInternal?.uid === AddOnModal.FOOTER_BLOCK
        if (isHeaderOrFooter) {
          if (checkRowContentIsEmpty(row)) {
            row.metadata = {}
          }
        }
      }
    })
  }
  return templateJson
}

export const saveEmailPrecheck = (
  containerValues: EmailComposerState,
  updateModal: EmailComposerAPI['updateModal'],
  t: TFunction
): EmailComposerState | undefined => {
  const { subjectRequiredForETE, titleMissing } = containerValues.validations.settingsValidations
  if (subjectRequiredForETE) {
    updateModal('confirmation', {
      title: t('EmailComposer.SaveErrors.SubjectETE.Title'),
      body: t('EmailComposer.SaveErrors.SubjectETE.Text'),
      okButtonText: t('EmailComposer.SaveErrors.SubjectETE.Button'),
      titleIcon: SvgNames.warning,
    })
    return
  } else if (titleMissing) {
    return { ...containerValues, lastSavedTitle: DEFAULT_EMAIL_TITLE, message: { ...containerValues.message, title: DEFAULT_EMAIL_TITLE } }
  }
  return { ...containerValues }
}

export const separateSavePreCheckRequiredFields = (containerValues: EmailComposerState): string[] => {
  const {
    message: { title, subject, sender, messageType },
    validations: {
      settingsValidations: { titleMissing },
    },
  } = containerValues
  const errorsObject = {
    title: !title || titleMissing || (messageType === 'PROGRAM_MESSAGE' && title === DEFAULT_EMAIL_TITLE) ? 'Internal email title' : undefined,
    subject: !subject ? 'Subject line' : undefined,
    sender: !sender ? 'Sender' : undefined,
    leadingDot: title?.trim().startsWith('.') || subject?.trim().startsWith('.') ? 'Title or Subject should not have a leading dot' : undefined,
  }

  return Object.values(errorsObject).filter(filterNotEmptyArray)
}

const processParamWithSlash = (
  param: string
): {
  param: string
  tabParam?: string
} => {
  if (param.includes('/')) {
    const splitParam = param.split('/')
    const mainParam = splitParam[0]
    const tabParam = splitParam[1]
    return { param: mainParam, tabParam }
  }
  return { param }
}

export const getMessageConfigurationType = () => {
  const queryParams = new URLSearchParams(window.location.search)
  return queryParams.get('messageConfigurationType') || ''
}

export const composerURLIdHandler = (locationSearch: string, match: match<MatchParams>, update: DeepUpdateState<EmailComposerState>) => {
  const queryParams = new URLSearchParams(locationSearch)
  const referrerParams = document.referrer.includes('?') ? new URLSearchParams(document.referrer.split('?')[1]) : undefined
  const matchParamsId = match.params.id

  const isBlankMessage = queryParams.get('startId') === T_BEE_BLANK
  const isUploadHtml = queryParams.get('uploadHtml')
  const isUploadHtmlFirstEdit = queryParams.get('firstEdit')
  const isCRMFirstEdit = queryParams.get('firstEdit')
  const plainTextMode = !!queryParams.get('plainText')
  const uploadHtmlMode = isUploadHtml === 'true'
  const debugMode = queryParams.has('debug')
  let startIdParam = queryParams.get('startId')
  let convertToTypeParam = queryParams.get('to')

  const isFromDefault = !!queryParams.get('quickStart')
  const abTestVariation = queryParams.get('variation')

  const isNewEmail = matchParamsId === 'new'
  // If is new email should navigate to 'Settings' tab,
  // in case if no tab props provided in queryParams
  let tab: string | undefined = isNewEmail ? CommonComposerTab.SETTINGS : undefined

  const isNewAssetReportMessage = !!referrerParams?.get('reportTouchpoint')
  const fromSentMessageReport = isNewAssetReportMessage && !!referrerParams?.get('sentMessageReport')
  const fromFormReport = isNewAssetReportMessage && !!referrerParams?.get('formReport')
  const fromLandingPageReport = isNewAssetReportMessage && !!referrerParams?.get('landingPageReport')
  let assetReportLocalStorageKey

  if (fromSentMessageReport) {
    assetReportLocalStorageKey = TouchpointsLocalStorageKeys.SENT_MESSAGE_REPORT_DETAILS
  } else if (fromFormReport) {
    assetReportLocalStorageKey = TouchpointsLocalStorageKeys.FORM_REPORT_DETAILS
  } else if (fromLandingPageReport) {
    assetReportLocalStorageKey = TouchpointsLocalStorageKeys.LANDING_PAGE_REPORT_DETAILS
  }

  const messageId = isNewEmail ? '' : matchParamsId
  let messageTypeFromId: MessageType | '' = ''

  if (!messageId && !startIdParam) {
    messageTypeFromId = ''
  } else {
    messageTypeFromId = detectMessageTypeByLetter(startIdParam, matchParamsId)
  }

  if (isNewEmail && !startIdParam) {
    update({ startSession: true })
  }

  if (abTestVariation) {
    update({ message: { abTestVariation } })
  }

  if (isUploadHtml && isUploadHtmlFirstEdit) {
    update({ isUploadHtmlFirstEdit: true })
  }

  if (isCRMFirstEdit) {
    update({ isEmailCrmFirstEdit: true })
  }

  if (convertToTypeParam) {
    const { param, tabParam } = processParamWithSlash(convertToTypeParam)
    convertToTypeParam = param
    messageTypeFromId = convertToTypeParam.toUpperCase() as MessageType
    if (tabParam) {
      tab = tabParam
    }
  }

  if (startIdParam) {
    const { param, tabParam } = processParamWithSlash(startIdParam)
    startIdParam = param
    if (tabParam) {
      tab = tabParam
    }
  }

  if (tab && Object.values(CommonComposerTab).includes(tab as CommonComposerTab)) {
    update({ tab: tab as CommonComposerTab })
  }

  const computeMessageType = (messageId || startIdParam || convertToTypeParam ? messageTypeFromId : 'DRAFT') as MessageType

  //* Obtaining the configuration type from the URL and updating it if it exists; otherwise, it will be handled in retrieveMessageHelper.
  const messageConfigurationType = queryParams.get('messageConfigurationType')

  const messageConfiguration =
    messageConfigurationSettings[(messageConfigurationType?.toUpperCase() || computeMessageType) as MessageConfigurationType]

  update({
    messageConfiguration,
    detectedURLChanges: {
      hasToParam: !!convertToTypeParam,
      hasStartId: !!startIdParam,
      isNewMessage: isNewEmail,
      plainTextMode,
      uploadHtmlMode,
      isBlankMessage,
      debugMode,
      isNewAssetReportMessage,
    },
  })

  const messageType = messageConfiguration.messageType

  const { isEmailABTest, isEmailRSS, isEmailWebinar } = detectEmailType(messageConfiguration.messageType)

  if (isEmailABTest || isEmailRSS || isEmailWebinar) {
    update({ message: { publishId: matchParamsId } })
  }

  return {
    messageId,
    messageType,
    startId: startIdParam,
    messageConfiguration,
    isFromDefault,
    plainTextMode,
    uploadHtmlMode,
    isNewAssetReportMessage,
    assetReportLocalStorageKey,
  }
}

export const handleInvalidMessageError = (error?: readonly GraphQLError[]) => {
  if (error && error[0]?.extensions?.errorCode === 19) {
    return true
  }
}

export const handleRetrieveFail = (messageId: string, history: HistoryInterface.History, enableEmailDraftsReact: boolean) => {
  const prevPageQueryParam = new URLSearchParams(window.location.search).get('prevPage') as PrevPageParamsEnum
  const isDraft = prevPageQueryParam === PrevPageParamsEnum.drafts || messageId?.charAt(0) === 'd'
  const invalidRoot = isDraft ? (enableEmailDraftsReact ? 'outbound/drafts' : 'content/emailDrafts') : 'content/emailTemplates'
  history.replace(`${rootContext}/${invalidRoot}/invalid-email/${messageId}`)
}

export const getActiveContacts = async (request: () => FetchPromise<GetDataCardsQuery>, update: DeepUpdateState<EmailComposerState>) => {
  const { data, errors } = await request()
  if (errors) {
    logNewRelicError(errors, 'Getting active contacts')
  } else if (data?.getDataCards) {
    update({
      messageLimits: { monthlyLimit: data.getDataCards.available ?? 0, currentUsage: data.getDataCards.used ?? 0 },
    })
  }
}

export const getLastEmailContentEditSession = (
  message: EmailComposerState['message'],
  getLastEmailContentEditSessionRequest: EmailComposerRequests['getLastEmailContentEditSessionRequest'],
  update: DeepUpdateState<EmailComposerState>,
  createEmailContentEditSessionRequest?: EmailComposerRequests['createEmailContentEditSessionRequest'],
  isUploadHtml?: boolean
) => {
  const emailContentId = message.publishId || message.id
  if (emailContentId) {
    getLastEmailContentEditSessionRequest({ emailContentId })
      .then(async ({ data, errors }) => {
        if (errors) {
          logNewRelicError(errors, 'Getting email content last edit session')
          update({ lastEmailContentEditSession: null, startSession: true })
        } else if (data) {
          if (!data.getLastEmailContentEditSession) {
            if (isUploadHtml && createEmailContentEditSessionRequest) {
              const { data, errors } = await createEmailContentEditSessionRequest({
                emailContentId,
                sessionId: getUUID(),
                //using 'any' cause there is some issue for impoting type from 'email-management-types'
                sessionType: 'UPLOAD_HTML' as any,
              })
              if (errors) {
                logNewRelicError(errors, 'Creating email content edit session')
              } else if (data) {
                update({ sessionId: data.createEmailContentEditSession?.id })
                removeSessionStorageItem(IS_FIRST_MSG_OPENER)
              }
            } else {
              update({ startSession: true })
            }
          } else {
            if (isUploadHtml) {
              const isFirstOpener = getSessionStorageItem(IS_FIRST_MSG_OPENER)
              if (!isFirstOpener && Date.now() - data.getLastEmailContentEditSession.updatedAt <= UPDATE_SESSION_DELAY) {
                update({ isCoEditing: true })
              }
              removeSessionStorageItem(IS_FIRST_MSG_OPENER)
            }
          }

          update({ lastEmailContentEditSession: data.getLastEmailContentEditSession, isInitializeBeeEditor: true })
        }
      })
      .catch(() => update({ lastEmailContentEditSession: null, startSession: true }))
  }
}

export const handleGoToPrevPage = (message: EmailComposerState['message'], history: HistoryInterface.History, tab: CommonComposerTab) => {
  const messageID = message.id
  const prevPageQueryParam = new URLSearchParams(location.search).get('prevPage')
  const messageSettingsType = new URLSearchParams(location.search).get('messageConfigurationType')
  const messageConfigurationType = messageSettingsType ? `${prevPageQueryParam ? '&' : '?'}messageConfigurationType=${messageSettingsType}` : ''
  if (messageID) {
    prevPageQueryParam
      ? history.replace(`${rootContext}/content/emails/${messageID}/${tab}?prevPage=${prevPageQueryParam}${messageConfigurationType}`)
      : history.replace(`${rootContext}/content/emails/${messageID}/${tab}${messageConfigurationType}`)
  }
}

//* handling Classic form confirmation email part
export const setFormConfirmationEmail = (formId: string, msgId: string) => {
  let confirmationEmailIds: ConfirmationEmailIds = {}
  const currentFormObject = {
    msgid: msgId,
    ts: new Date().getTime(),
  }
  const confirmationFormIds = localStorage.getItem(FORM_AUTORESPONSE_EMAIL_IDS)

  if (confirmationFormIds) {
    confirmationEmailIds = JSON.parse(confirmationFormIds)
  }

  confirmationEmailIds[formId] = currentFormObject
  localStorage.setItem(FORM_AUTORESPONSE_EMAIL_IDS, JSON.stringify(confirmationEmailIds))
}
