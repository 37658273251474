import React, { Component } from 'react'

import classNames from 'classnames'
import { Connection } from 'jsplumb'

import ConfirmationModal, { YesNo } from '@components/ConfirmationModal/ConfirmationModal'
import Loader from '@components/Loader/Loader'
import PrintButton from '@components/PrintButton'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Toast from '@components/Toast/Toast'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { AccountSettings, Program, ProgramErrors } from '@graphql/types/query-types'
import { NavigationState } from '@utils/navigation/navigation.utils'
import { hasAOContactsSource } from '@utils/program/program'
import { ProgramStepType, Direction, ProgramFlowTree, Step } from '@utils/program/program.constants'

import BuildProgramFlow from './buildProgramFlow'
import AddStepModal from './components/AddStepModal'
import EditStepModal from './components/EditStepModal'
import ProgramFlowStep from './components/ProgramFlowStep/ProgramFlowStep'
import ProgramStepIcon from './components/ProgramStepIcon/ProgramStepIcon'
import programFlowUtil, { EditStep, EditStepPhase, ProgramCanvasSize, ProgramFlowStepInterface } from './programFlowUtil'

import './programFlow.css'

const rootClass = 'program-flow'

export interface ProgramFlowProps {
  program: Program
  programUrlId?: string
  dataTest?: string
  isViewOnly: boolean
  navigation: NavigationState
  accountSettings?: AccountSettings
  unsavedChanges?: boolean
  programErrors?: ProgramErrors[]
  setCurrentTab: (newTab: number) => void
  toggleNavigationDisabled(disabled: boolean): void
  updateProgram(program: Program): void
  getActiveSteps(tree: ProgramFlowTree): void
  t: (keyVal: string) => string
}

interface Panning {
  started: boolean
  x: number
  y: number
  startX: number
  startY: number
}

export enum EditStateAction {
  COPY,
  GOTO,
  DELETE,
  PREVENT_DELETE,
  CONVERTTOEXIT,
  MOVE,
}

export interface EditState {
  step: Step
  action: EditStateAction
}

export interface Redraw {
  stepId?: string
}

export interface State {
  zoom: number
  canPan: boolean
  panning?: Panning
  listener?: Function
  editStep?: EditStep
  canvasSize?: ProgramCanvasSize
  steps?: ProgramFlowStepInterface[]
  tree?: ProgramFlowTree
  first: boolean
  redraw?: Redraw
  newStepId: number
  editState?: EditState
  redrawLines: boolean
  width: number
  height: number
  listChange: boolean
}

let goToConnector: Connection | undefined
export class ProgramFlow extends Component<ProgramFlowProps> {
  state: State = {
    zoom: 5,
    canPan: false,
    first: true,
    newStepId: 0,
    redrawLines: false,
    width: 0,
    height: 0,
    listChange: false,
  }

  private hardStopPan = false
  private lastMouseMoveTime?: number
  private programFlowOuter = React.createRef<HTMLDivElement>()
  private programFlowInner = React.createRef<HTMLDivElement>()

  componentDidMount() {
    document.addEventListener('mouseup', this.mouseUp)
    document.addEventListener('mousemove', this.mouseMove)
    this.renderSteps()
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.mouseUp)
    document.removeEventListener('mousemove', this.mouseMove)
  }

  componentDidUpdate(prevProps: ProgramFlowProps, prevSate: State) {
    const { toggleNavigationDisabled } = this.props
    const { steps, canvasSize, panning, redraw, listChange } = this.state
    let redrawCanvas = undefined

    if (this.props.program !== prevProps.program) {
      if (this.props.unsavedChanges === false) {
        this.renderSteps(true)
      }
      redrawCanvas = redraw
    }
    if (listChange) {
      if (this.state.tree) {
        this.props.getActiveSteps(this.state.tree)
        this.setState({
          listChange: false,
        })
      }
    }
    if (!steps || redrawCanvas) {
      this.renderSteps()
    } else if (prevSate.panning?.started !== panning?.started) {
      if (panning?.started) {
        toggleNavigationDisabled(true)
      } else {
        toggleNavigationDisabled(false)
      }
    } else if (this.state.redrawLines) {
      setTimeout(() => {
        if (steps && steps !== prevSate.steps && canvasSize && this.programFlowOuter.current && this.programFlowInner.current) {
          if (this.state.first) {
            programFlowUtil.centerFirstStep(steps, this.programFlowOuter.current)
          } else {
            programFlowUtil.clearAllConnections(this.programFlowInner.current)
          }
          if (this.state.redraw?.stepId) {
            programFlowUtil.centerStep(steps, this.programFlowOuter.current, this.state.redraw.stepId)
          }
          const canPan =
            this.programFlowInner.current.offsetHeight > this.programFlowOuter.current.offsetHeight ||
            this.programFlowInner.current.offsetWidth > this.programFlowOuter.current.offsetWidth
          programFlowUtil.connectSteps(steps, this.programFlowInner.current, this.props.isViewOnly)
          this.setState({
            first: false,
            canPan,
            redraw: undefined,
            redrawLines: false,
          })
        }
      }, 1)
    }
  }

  renderSteps = (forceRedraw = false) => {
    const { program, getActiveSteps, updateProgram } = this.props
    if (program.tracks && program.tracks.length) {
      programFlowUtil.clearAllConnections(this.programFlowInner?.current)
      let tree = this.state.tree
      if (!tree || this.state.redraw || forceRedraw) {
        tree = BuildProgramFlow.buildTrees(program.tracks, this.props.t, program.stepTemplates, (tracks) => updateProgram({ ...program, tracks }))
      }
      const canvasSize = programFlowUtil.getProgramCanvasSize()
      const widthHeight = this.getWidthHeight(this.state.zoom, tree)
      getActiveSteps(tree)

      this.setState({
        tree,
        canvasSize,
        steps: programFlowUtil.getProgramFlowSteps(tree, canvasSize),
        redrawLines: true,
        ...widthHeight,
      })
    }
  }

  getWidthHeight(zoom: number, flowTree?: ProgramFlowTree) {
    const tree = flowTree ?? this.state.tree
    if (tree) {
      const canvasSize = programFlowUtil.getProgramCanvasSize()
      const numberOfColumns = programFlowUtil.getNumberOfColumns(tree)
      const zoomScale = programFlowUtil.getZoomScale(zoom)
      const width = numberOfColumns * canvasSize.columnWidth + canvasSize.columnWidth * 2
      const height = tree.gridSize.bottom * canvasSize.rowHeight + canvasSize.rowHeight
      return {
        width: width * zoomScale,
        height: height * zoomScale,
      }
    }
    return {
      width: 0,
      height: 0,
    }
  }

  zoomIn = () => {
    const zoom = this.state.zoom + 1
    if (zoom > 10) return
    const widthHeight = this.getWidthHeight(zoom)

    this.setState(
      {
        zoom,
        ...widthHeight,
      },
      () => {
        if (this.programFlowOuter.current && widthHeight.width > this.programFlowOuter.current.clientWidth) {
          const scroll = (widthHeight.width - this.programFlowOuter.current.clientWidth) / 2
          this.programFlowOuter.current.scrollTo(scroll, this.programFlowOuter.current.scrollTop)
        }
      }
    )
  }

  zoomOut = () => {
    let zoom = this.state.zoom - 1
    if (zoom < 1) {
      zoom = 0.5
    }
    const widthHeight = this.getWidthHeight(zoom)
    this.setState(
      {
        zoom,
        ...widthHeight,
      },
      () => {
        if (this.programFlowOuter.current && widthHeight.width > this.programFlowOuter.current.clientWidth) {
          const scroll = (widthHeight.width - this.programFlowOuter.current.clientWidth) / 2
          this.programFlowOuter.current.scrollTo(scroll, this.programFlowOuter.current.scrollTop)
        }
      }
    )
  }

  mouseUp = () => {
    const { panning } = this.state
    if (panning?.started) {
      this.hardStopPan = true
      setTimeout(() => {
        this.setState({
          panning: undefined,
        })
      }, 1)
    } else if (panning?.startX && panning?.startY) {
      this.setState({
        panning: undefined,
      })
    }
  }

  mouseMove = (event: MouseEvent, now: number = new Date().getTime()) => {
    const { panning } = this.state
    if (!this.hardStopPan && panning && this.programFlowOuter.current) {
      // debounce the movement to avoid glitches
      if (!this.lastMouseMoveTime || this.lastMouseMoveTime + 100 < now) {
        this.lastMouseMoveTime = now
        if (panning.started) {
          try {
            this.programFlowOuter.current.scrollTo(panning.x - event.clientX, panning.y - event.clientY)
          } catch (e) {
            this.programFlowOuter.current.scrollLeft = panning.x - event.clientX
            this.programFlowOuter.current.scrollTop = panning.y - event.clientY
          }
        } else {
          const started =
            panning.startX - 5 > event.clientX ||
            panning.startX + 5 < event.clientX ||
            panning.startY - 5 > event.clientY ||
            panning.startY + 5 < event.clientY
          if (started) {
            this.setState({
              panning: {
                ...panning,
                started: true,
              },
            })
          }
        }
      }
    }
  }

  updateStep(step: Step) {
    const { program, updateProgram } = this.props
    const { steps, tree, newStepId } = this.state
    if (!steps || !tree) return

    const editStep = {
      new: false,
      step,
      phase: EditStepPhase.EDITING,
    }
    const stepsAndTracks = programFlowUtil.getUpdatedStepsAndTracks(step, tree, steps, program.stepTemplates, editStep, newStepId)
    if (stepsAndTracks) {
      this.setState({
        steps: stepsAndTracks.steps ?? this.state.steps,
        tree: {
          ...tree,
          tracks: stepsAndTracks.tracks,
        },
        editState: undefined,
        redrawLines: true,
        newStepId: step.stepType === ProgramStepType.GOTO ? newStepId + 1 : newStepId,
      })
      updateProgram({
        ...program,
        tracks: stepsAndTracks.tracks,
      })
    }
  }

  render() {
    const { program, programUrlId, programErrors, navigation, updateProgram, isViewOnly, accountSettings, dataTest = 'program-flow' } = this.props
    const { editStep, editState, steps, canvasSize, tree, zoom, first, panning, canPan, width, height } = this.state
    const aOContactsSource = hasAOContactsSource(program)

    if (!steps || !canvasSize || !tree) {
      return <Loader center />
    }

    const zoomScale = programFlowUtil.getZoomScale(zoom)

    const deleteGoTo = () => {
      if (goToConnector) {
        programFlowUtil.deleteConnection(goToConnector, this.programFlowOuter.current)
        goToConnector = undefined
      }
    }

    return (
      <>
        {editStep?.phase === EditStepPhase.ADDING && (
          <AddStepModal
            isOpen={true}
            dataTest={`${dataTest}-add-step-modal`}
            closeModal={() => {
              this.setState({
                editStep: undefined,
              })
            }}
            program={program}
            onAddStep={(step: Step) => {
              this.setState({
                editStep: {
                  ...editStep,
                  step,
                  phase: EditStepPhase.EDITING,
                },
              })
            }}
            newStepId={this.state.newStepId}
          />
        )}
        {editStep?.phase === EditStepPhase.EDITING && editStep.step && (
          <EditStepModal
            program={program}
            isOpen
            isViewOnly={isViewOnly}
            accountSettings={accountSettings}
            dataTest={`${dataTest}-edit-step-modal`}
            closeModal={() => {
              this.setState({
                editStep: undefined,
              })
            }}
            saveStepAndProgram={(step: Step | null, updatedProgram: Program) => {
              if (!step && updatedProgram) {
                updateProgram(updatedProgram)
              } else if (step) {
                const stepsAndTracks = programFlowUtil.getUpdatedStepsAndTracks(step, tree, steps, program.stepTemplates, editStep)
                if (stepsAndTracks) {
                  this.setState({
                    steps: stepsAndTracks.steps ?? this.state.steps,
                    tree: {
                      ...tree,
                      tracks: stepsAndTracks.tracks,
                    },
                    newStepId: editStep?.new ? this.state.newStepId + 1 : this.state.newStepId,
                    redraw: editStep?.new ? { stepId: editStep?.step?.stepId } : undefined,
                    redrawLines: !editStep?.new,
                    editStep: undefined,
                    listChange: true,
                  })
                  updateProgram({
                    ...(updatedProgram ?? program),
                    tracks: stepsAndTracks.tracks,
                  })
                } else {
                  this.setState({
                    editStep: undefined,
                  })
                }
              }
            }}
            step={editStep.step}
            tracks={tree.tracks}
          />
        )}
        <ConfirmationModal
          title={this.props.t('Delete This Step?')}
          isOpen={editState?.action === EditStateAction.DELETE}
          body={this.props.t('You will not be able to undo this action.')}
          isDelete
          onAnswer={(answer) => {
            if (answer === YesNo.YES) {
              if (editState?.step) {
                const tracks = programFlowUtil.deleteStep(editState.step, tree)
                if (tracks) {
                  this.setState({
                    tree: {
                      ...tree,
                      tracks: tracks,
                    },
                    redraw: {},
                    editState: undefined,
                  })
                  updateProgram({
                    ...program,
                    tracks: tracks,
                  })
                }
              }
            } else {
              this.setState({
                editState: undefined,
              })
            }
          }}
        />
        <ConfirmationModal
          className={`${rootClass}__prevent-delete-modal`}
          isOpen={editState?.action === EditStateAction.PREVENT_DELETE}
          title={
            <>
              <Svg name={SvgNames.warning} type={SvgType.EXTRA_LARGE_ICON} />
              <Typography text={this.props.t('Program.Flow.Step.Prevent.Delete.Title')} type={TextType.DATA_CARD_TEXT} weight={TextWeight.MEDIUM} />
            </>
          }
          body={
            <Typography
              text={this.props.t(`Program.Flow.Step.Prevent.Delete.Body`)}
              tagComponents={{
                TextLink: (
                  <TextLink
                    link="https://connect.act-on.com/hc/en-us/articles/4409040945815-Edit-an-Automated-Program#heading-2"
                    size={TextLinkSize.LARGE}
                  ></TextLink>
                ),
              }}
            />
          }
          okButtonText={this.props.t('Got it')}
          closeModal={() => {
            this.setState({
              editState: undefined,
            })
          }}
        />
        <ConfirmationModal
          isYesNo
          isOpen={editState?.action === EditStateAction.CONVERTTOEXIT}
          title={this.props.t('Convert to Exit Step?')}
          body={this.props.t('The Go-to-Step settings will be lost.')}
          onAnswer={(answer) => {
            if (answer === YesNo.YES) {
              if (editState?.step) {
                const newStep = {
                  ...editState?.step,
                  goToStepId: undefined,
                  stepType: ProgramStepType.EXIT,
                }
                this.updateStep(newStep)
              }
            }
            this.setState({
              editState: undefined,
            })
          }}
        />
        {first && <Loader center />}
        {editState?.action === EditStateAction.GOTO && (
          <>
            <div className={`${rootClass}__border-top ${rootClass}__border-top--goto`} />
            <div className={`${rootClass}__border-left ${rootClass}__border-left--goto`} />
            <div className={`${rootClass}__border-right ${rootClass}__border-right--goto`} />
            <div className={`${rootClass}__border-bottom ${rootClass}__border-bottom--goto`} />
            <Toast
              icon={<ProgramStepIcon stepType={ProgramStepType.GOTO} iconOnly />}
              text={this.props.t('Choose step to send contacts to')}
              onClose={() => {
                this.setState({
                  editState: undefined,
                })
              }}
            />
          </>
        )}
        {editState?.action === EditStateAction.COPY && (
          <>
            <div className={`${rootClass}__border-top ${rootClass}__border-top--copy`} />
            <div className={`${rootClass}__border-left ${rootClass}__border-left--copy`} />
            <div className={`${rootClass}__border-right ${rootClass}__border-right--copy`} />
            <div className={`${rootClass}__border-bottom ${rootClass}__border-bottom--copy`} />
            <Toast
              icon={<Svg name={SvgNames.copyStep} type={SvgType.LARGER_ICON} />}
              text={this.props.t('Choose where to place the copied step')}
              onClose={() => {
                this.setState({
                  editState: undefined,
                })
              }}
            />
          </>
        )}
        {editState?.action === EditStateAction.MOVE && (
          <>
            <div className={`${rootClass}__border-top ${rootClass}__border-top--copy`} />
            <div className={`${rootClass}__border-left ${rootClass}__border-left--copy`} />
            <div className={`${rootClass}__border-right ${rootClass}__border-right--copy`} />
            <div className={`${rootClass}__border-bottom ${rootClass}__border-bottom--copy`} />
            <Toast
              icon={<Svg name={SvgNames.moveStep} type={SvgType.LARGER_ICON} />}
              text={this.props.t('Choose where to move the step')}
              onClose={() => {
                this.setState({
                  editState: undefined,
                })
              }}
            />
          </>
        )}
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          data-test={dataTest}
          ref={this.programFlowOuter}
          className={classNames(rootClass, {
            [`${rootClass}--nav-collapsed`]: !navigation.expanded,
            [`${rootClass}--panning`]: panning !== undefined,
            [`${rootClass}--can-pan`]: canPan && !panning,
            [`${rootClass}--copy`]: editState?.action === EditStateAction.COPY || editState?.action === EditStateAction.MOVE,
            [`${rootClass}--goto`]: editState?.action === EditStateAction.GOTO,
          })}
          onMouseDown={(event) => {
            if (this.programFlowOuter.current && canPan) {
              this.hardStopPan = false
              this.setState({
                panning: {
                  x: event.clientX + this.programFlowOuter.current.scrollLeft,
                  y: event.clientY + this.programFlowOuter.current.scrollTop,
                  startX: event.clientX,
                  startY: event.clientY,
                  started: panning?.started ?? false,
                },
              })
            }
          }}
        >
          <PrintButton rootClass={rootClass} />
          <div
            ref={this.programFlowInner}
            className={`${rootClass}__inner`}
            data-test={`${dataTest}-inner`}
            style={{
              width: `${width}px`,
              height: `${height}px`,
              transform: `scale(${zoomScale})`,
              transformOrigin: '0 0',
            }}
          >
            {steps.map((step) => (
              <ProgramFlowStep
                key={step.id}
                aOContactsSource={aOContactsSource}
                isViewOnly={isViewOnly}
                dataTest={`${dataTest}-step-${step.id}`}
                programUrlId={programUrlId}
                stepErrors={programErrors ?? undefined}
                step={{
                  ...step,
                  step: {
                    ...step.step,
                    stepType:
                      editState?.action === EditStateAction.GOTO && editState.step.stepId === step.step.stepId
                        ? ProgramStepType.GOTO
                        : step.step.stepType,
                  },
                }}
                setCurrentTab={this.props.setCurrentTab}
                changeEditState={(editState) => {
                  if (panning?.started) return
                  this.setState({
                    editState,
                  })
                }}
                editState={editState}
                copyStep={(parentStep: Step, direction?: Direction) => {
                  if (panning?.started) return
                  if (!editState?.step) return
                  const newStep = {
                    ...editState.step,
                    stepId: `newstep${this.state.newStepId}`,
                  }
                  const editStep = {
                    new: true,
                    step: newStep,
                    parentStep,
                    direction,
                    phase: EditStepPhase.ADDING,
                  }
                  const stepsAndTracks = programFlowUtil.getUpdatedStepsAndTracks(newStep, tree, steps, program.stepTemplates, editStep)
                  if (stepsAndTracks) {
                    this.setState({
                      steps: stepsAndTracks.steps ?? this.state.steps,
                      tree: {
                        ...tree,
                        tracks: stepsAndTracks.tracks,
                      },
                      newStepId: this.state.newStepId + 1,
                      redraw: { stepId: editStep?.step?.stepId },
                      editState: undefined,
                    })
                    updateProgram({
                      ...program,
                      tracks: stepsAndTracks.tracks,
                    })
                  } else {
                    this.setState({
                      editState: undefined,
                    })
                  }
                }}
                moveStep={(parentStep: Step, direction?: Direction) => {
                  if (panning?.started) return
                  if (!editState?.step) return
                  const newStep = {
                    ...editState.step,
                  }
                  const editStep = {
                    move: true,
                    step: newStep,
                    parentStep,
                    direction,
                    phase: EditStepPhase.ADDING,
                  }
                  const filteredSteps = steps.filter((step) => newStep.stepId !== step.id && `program-flow-node-${newStep.stepId}` !== step.id)
                  const stepsAndTracks = programFlowUtil.getUpdatedStepsAndTracks(newStep, tree, filteredSteps, program.stepTemplates, editStep)
                  if (stepsAndTracks) {
                    this.setState({
                      steps: stepsAndTracks.steps ?? this.state.steps,
                      tree: {
                        ...tree,
                        tracks: stepsAndTracks.tracks,
                      },
                      newStepId: this.state.newStepId + 1,
                      redraw: { stepId: editStep?.step?.stepId },
                      editState: undefined,
                    })
                    updateProgram({
                      ...program,
                      tracks: stepsAndTracks.tracks,
                    })
                  } else {
                    this.setState({
                      editState: undefined,
                    })
                  }
                }}
                updateStep={(step: Step) => {
                  this.updateStep(step)
                }}
                addNewStep={(parentStep: Step, direction?: Direction) => {
                  if (panning?.started) return
                  this.setState({
                    editStep: {
                      new: true,
                      parentStep,
                      direction,
                      phase: EditStepPhase.ADDING,
                    },
                    panning: undefined,
                  })
                }}
                editStep={(step: Step) => {
                  if (panning?.started) return
                  this.setState({
                    editStep: {
                      step,
                      phase: EditStepPhase.EDITING,
                    },
                    panning: undefined,
                  })
                }}
                drawGoTo={(stepId) => {
                  // Ensures we cannot have multiple temporary go-to lines
                  deleteGoTo()
                  goToConnector = programFlowUtil.drawGoTo(`program-flow-node-${editState?.step.stepId}`, stepId, this.programFlowOuter.current)
                }}
                deleteGoTo={deleteGoTo}
              />
            ))}
          </div>
        </div>
        <div className={classNames(`${rootClass}__zoom`, 'no-print')}>
          <button onClick={this.zoomIn} data-test={`${dataTest}-zoom-in`}>
            <Svg name={SvgNames.plus} />
          </button>
          <button onClick={this.zoomOut} data-test={`${dataTest}-zoom-out`}>
            <Svg name={SvgNames.minus} />
          </button>
        </div>
      </>
    )
  }
}

export default ProgramFlow
