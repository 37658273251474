import React, { FC, ReactNode, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import { ButtonSize, ButtonWeight } from '@components/Button/Button'
import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { useTranslation } from '@const/globals'

import './WaitStepDetail.css'

export interface WaitStepDetailProps {
  stepId: string
  condition: ReactNode
  moveNext?: (stepId: string) => void
  canMove: boolean
  className?: string
  dataTest?: string
}

const rootClass = 'wait-step-detail'

const WaitStepDetail: FC<WaitStepDetailProps> = (props: WaitStepDetailProps) => {
  const { stepId, condition, moveNext, canMove, dataTest = rootClass, className = '' } = props
  const [pending, setPending] = useState(false)

  const { t } = useTranslation()

  const renderBaseButtonContent = () => t('Move Contacts to Next Step')

  const renderPendingButtonContent = () => <Loader loaderType={LoaderTypes.row} />

  const clickHandler = () => {
    moveNext && moveNext(stepId)
    setPending(true)
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {condition}
      {canMove && (
        <Button
          buttonType={ButtonType.INFO}
          buttonSize={ButtonSize.SMALL}
          weight={ButtonWeight.MEDIUM}
          iconPosition={ButtonIconPosition.LEFT}
          disabled={pending}
          className={`${rootClass}__button`}
          dataTest={`${rootClass}-move-next`}
          onClick={clickHandler}
        >
          <Svg name={SvgNames.goToStep} type={SvgType.ICON} />
          {pending && renderPendingButtonContent()}
          {!pending && renderBaseButtonContent()}
        </Button>
      )}
    </div>
  )
}

export default WaitStepDetail
