import { gql } from '@apollo/client'

export default gql`
  mutation createEmailTemplateFromDraft($id: String!, $newName: String!, $folderId: Int, $tags: [LabelDto]) {
    createEmailTemplateFromDraft(id: $id, newName: $newName, folderId: $folderId, tags: $tags) {
      newId
      status
      message
      name
    }
  }
`
