import React, { ReactNode, FC, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'

import { ModalSubHeaderProps } from '@components/Modal/components/ModalSubHeader'
import { getUUID, isJest } from '@utils/const/globals'
import utils from '@utils/utils'

import { ModalHeaderProps } from './components/ModalHeader'
import ModalInstance from './ModalInstance'
import modalUtils from './modalUtils'

import './modal.css'

export interface State {
  isAttachedToDOM: boolean
}

interface FitModalBody {
  id: string
  suggestedHeight: number
  suggestedWidth: number
  verticalPadding?: number
  horizontalPadding?: number
}

export interface ModalProps {
  /** Determines if modal is shown or not */
  isOpen: boolean
  /** header for the modal **/
  header?: React.ReactElement<ModalHeaderProps>
  /** sub header for a modal **/
  subHeader?: React.ReactElement<ModalSubHeaderProps>
  /** full screen regardless of content */
  fullScreen?: boolean
  /** can be full screen if content requires it */
  allowFullScreen?: boolean
  children: ReactNode
  dataTest?: string
  className?: string
  /** no padding in body **/
  noPadding?: boolean
  /** css class that will be looked for to determine modal body height */
  useCustomScrollAreas?: string
  /** modal will take this element and size as much as possible to fit content **/
  fitModalBody?: FitModalBody
  /** do not allow scrolling in body **/
  noScroll?: boolean
  /** no background color **/
  clear?: boolean
  /** larger centered header **/
  useLargeHeader?: boolean
  showOverflow?: boolean
  // the total will be MODAL_BASE_Z_INDEX + zIndex if provided
  addZIndexBy?: number
  // Sets left/right padding to 2rem instead of 3.5rem
  paddingV2?: boolean
}

type Props = ModalProps

interface ActiveModalInstances {
  [key: string]: boolean
}

export const modalInstances: ActiveModalInstances = {}

export const getModalCount = (): number => {
  return Object.keys(modalInstances).length
}

export const setModalCount = (value: number): void => {
  if (!isJest()) {
    return
  }
  const modalCountDiff = value - getModalCount()
  if (modalCountDiff > 0) {
    for (let i = 0; i < modalCountDiff; ++i) {
      const id = getUUID(true)
      modalInstances[id] = true
    }
  } else if (modalCountDiff < 0) {
    const keys = Object.keys(modalInstances)
    const toRemove = Math.abs(modalCountDiff)
    for (let i = 0; i < toRemove; ++i) {
      const randomIndex = Math.floor(Math.random() * keys.length)
      const randomKey = keys[randomIndex]
      delete modalInstances[randomKey]
    }
  }
}

export const Modal: FC<Props> = (props: Props) => {
  const { isOpen, addZIndexBy } = props
  const [isAttachedToDOM, setIsAttachedToDOM] = useState(false)
  const [id] = useState(() => getUUID(true))

  useEffect(() => {
    if (isOpen) {
      modalInstances[id] = true
      modalUtils.openModal()
      setIsAttachedToDOM(true)
    }

    if (!isOpen && modalInstances[id]) {
      delete modalInstances[id]
      modalUtils.closeModal()
      setIsAttachedToDOM(false)
    }

    return () => {
      if (isOpen && modalInstances[id]) {
        delete modalInstances[id]
        modalUtils.closeModal()
        setIsAttachedToDOM(false)
      }
    }
  }, [isOpen])

  return (
    <>
      {!isOpen || !isAttachedToDOM
        ? null
        : ReactDOM.createPortal(<ModalInstance {...props} index={addZIndexBy || 2 * getModalCount()} />, utils.getModalRoot())}
    </>
  )
}

export default Modal
