import React, { FC, useContext } from 'react'

import AssetPickerSidebar from '@complex/AssetPickerModal/Components/Sidebar/AssetPickerSidebar'
import { LP_PERSONALIZATION_LEARN_MORE, PERSONALIZATION_LEARN_MORE } from '@complex/Personalization/Personalization.constants'
import { personalizationFilters, PersonalizationModalContext } from '@complex/Personalization/utils/Personalization.context'
import { CollapsibleMenuItemWithHeaderData } from '@components/CollapsibleMenu/CollapsibleMenu'
import FilterContainer from '@components/FilterContainer/FilterContainer'
import { LinkTextButton } from '@components/LinkTextButton/LinkTextButton'
import Menu from '@components/Menu/Menu'
import { SvgNames } from '@components/Svg/index'
import Typography from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import {
  contactFieldsFilter,
  contentPersonalizationFieldsFilter,
  crmFieldsFilter,
  FilterTypes,
  listOrSegmentFieldsFilter,
  listOrSegmentFieldsFilterDisabled,
  listOrSegmentFieldsFilterDisabledTemplate,
  senderFieldsFilter,
  sourceListFieldsFilter,
} from '@utils/filter'

const rootClass = 'personalization'

const PersonalizationSidebarContainer: FC = () => {
  const { update, ...state } = useContext(PersonalizationModalContext)
  const {
    filterCounts,
    activeFilter,
    crm,
    hideAccountPersonalizations,
    hideListOrSegmentFields,
    hideSenderFieldsFilter,
    isLandingPage,
    isAutomatedPrograms,
    disableListOrSegmentFields,
    disableListOrSegmentFieldsForTemplate,
    hideContactsFieldsFilter,
  } = state

  const { t } = useTranslation()

  const onFilterClick = (filterName: FilterTypes, filterSvg: SvgNames) => {
    update({ activeFilter: filterName, activeFilterSvg: filterSvg })
  }

  const getDefaultFilters = (): CollapsibleMenuItemWithHeaderData => {
    const filters = hideContactsFieldsFilter ? [] : [contactFieldsFilter]

    if (isAutomatedPrograms) {
      filters.push(sourceListFieldsFilter)
      if (!hideAccountPersonalizations) {
        filters.push(contentPersonalizationFieldsFilter)
      }
    } else {
      if (!hideListOrSegmentFields) {
        filters.push(
          disableListOrSegmentFields
            ? listOrSegmentFieldsFilterDisabled
            : disableListOrSegmentFieldsForTemplate
            ? listOrSegmentFieldsFilterDisabledTemplate
            : listOrSegmentFieldsFilter
        )
      }
      if (!hideAccountPersonalizations) {
        if (!hideSenderFieldsFilter) {
          filters.push(senderFieldsFilter)
        }
        filters.push(contentPersonalizationFieldsFilter)
      }
      filters.push(crm ?? crmFieldsFilter)
    }

    return {
      header: '',
      content: (
        <FilterContainer
          filters={filters}
          filterCounts={filterCounts}
          activeFilter={personalizationFilters.find((filter) => filter.name === activeFilter)}
          filterAction={(filter) => onFilterClick(filter.name as FilterTypes, filter.svgUnselected)}
          hasRecent={false}
          hasRecommended={false}
          hasCreatedByMe={false}
          hasFavorites={false}
          hideCount={false}
          className={`${rootClass}__sidebar--filter`}
        />
      ),
      icon: SvgNames.segmentFilters,
      dataTest: 'menu-items-filters',
    }
  }

  const items = [getDefaultFilters()]

  return (
    <div className={`${rootClass}__sidebar`}>
      <AssetPickerSidebar>
        <Menu items={items} className={`${rootClass}__sidebar__menu`} />
        {!isAutomatedPrograms && (
          <div className={`${rootClass}__sidebar-learn-more`}>
            <Typography
              className="push-up"
              text={t(isLandingPage ? 'PersonalizationModal.Sidebar.LandingPage.Blurb' : 'PersonalizationModal.Sidebar.Blurb')}
              dataTest={`${rootClass}-sidebar-typography`}
            />
            <LinkTextButton
              text={t('Learn more')}
              link={isLandingPage ? LP_PERSONALIZATION_LEARN_MORE : PERSONALIZATION_LEARN_MORE}
              dataTest={`${rootClass}-sidebar-textLink`}
              hideIconLeft
            />
          </div>
        )}
      </AssetPickerSidebar>
    </div>
  )
}

export default PersonalizationSidebarContainer
