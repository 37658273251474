import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import { DatePickerProps } from '@components/DatePicker/DatePicker'
import { DateRangePickerProps, DateRangeValueType } from '@components/DateRangePicker/DateRangePicker'
import NumberAndSelectInput from '@components/NumberAndSelectInput/NumberAndSelectInput'
import { NumberInputProps } from '@components/NumberInput/NumberInput'
import { NumberRangeInputProps } from '@components/NumberRangeInput/NumberRangeInput'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2Props, SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { useTranslation } from '@const/globals'
import DateInput from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/DateInput/DateInput'
import {
  getBehaviorTimeframeConditionOptions,
  getDateTimeframeConditionOptions,
  getMoreThanOptions,
  getNextLastOptions,
  getRelativeRangeBehaviorOptions,
  getRelativeRangeProfileOptions,
} from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/DateTimeframes/DateTimeframes.utils'
import IgnoreYearPopover, {
  IgnoreYearPopoverProps,
} from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/IgnoreYearPopover/IgnoreYearPopover'
import { NumberRange } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/ProfileExpression/ProfileExpression.constants'
import { renderSingleValue } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/utils/SegmentComposerBuild.utils'
import {
  AfterBeforeDate,
  CUSTOM_DATE,
  CUSTOM_RELATIVE_DATE,
  MORE_THAN,
  NextLastDate,
  RelativeDirection,
  RelativePeriod,
  RelativeType,
  SpecificDate,
} from '@src/pages/SegmentComposer/SegmentComposer.constants'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'

import './DateTimeframes.css'

interface DateTimeframesProps {
  className?: string
  dataTest?: string
  comparison?: SelectV2SingleOption
  ignoreYearProps?: IgnoreYearPopoverProps
  isBehavior?: boolean
}

const rootClass = 'date-timeframes'

const defaultRelatedPeriod: RelativePeriod = {
  type: RelativeType.DAYS,
  direction: RelativeDirection.PAST,
}

interface DateTimeframesState {
  relativePeriod: RelativePeriod
  specificDate?: Date
  customDateRange?: DateRangeValueType
  relativeRange?: SelectV2SingleOption
  selectedPeriod?: SelectV2SingleOption
  numberRange: NumberRange
  selectedTimeframe: SelectV2SingleOption
}

const defaultDateTimeframesState = {
  relativePeriod: defaultRelatedPeriod,
  numberRange: { rangeStart: undefined, rangeEnd: undefined },
  selectedPeriod: undefined,
}

const MONTH_FORMAT = 'MMM YYYY'
const MONTH_WITHOUT_YEAR_FORMAT = 'MMM'
const QUARTER_WITHOUT_YEAR_FORMAT = '[Q]Q'
const DEFAULT_TIMEFRAME_GROUP_INDEX = 0
const DEFAULT_TIMEFRAME_INDEX = 0
const DEFAULT_RELATIVE_RANGE_GROUP_INDEX = 0
const DEFAULT_RELATIVE_RANGE_INDEX = 0
const DEFAULT_NEXT_LAST_INDEX = 2
const DEFAULT_MORE_THAN_INDEX = 2

const DateTimeframes: FC<DateTimeframesProps> = (props: DateTimeframesProps) => {
  const { comparison, dataTest = rootClass, className = '', ignoreYearProps, isBehavior } = props
  const { t } = useTranslation()
  let groupedOptions = isBehavior ? getBehaviorTimeframeConditionOptions(t) : getDateTimeframeConditionOptions(t)

  const [state, setState] = useState<DateTimeframesState>({
    ...defaultDateTimeframesState,
    selectedTimeframe: groupedOptions[DEFAULT_TIMEFRAME_GROUP_INDEX].options[DEFAULT_TIMEFRAME_INDEX],
  })

  const { relativePeriod, specificDate, customDateRange, relativeRange, selectedPeriod, numberRange, selectedTimeframe } = state
  const update = useDeepUpdate(setState)

  // TODO: Update the "expression" on the context with the new condition

  const onTimeframeChange = (selectedTimeframe: SelectV2SingleOption) => {
    update({ ...defaultDateTimeframesState, selectedTimeframe })
  }

  const renderMoreThanSection = () => {
    const numberInputProps: NumberInputProps = {
      value: relativePeriod.count,
      onChange: (count?: number) => update({ relativePeriod: { ...relativePeriod, count } }),
    }
    // TODO: update direction
    const moreThanOptions = getMoreThanOptions(t)
    const selectV2Props: SelectV2Props = {
      options: moreThanOptions,
      value: selectedPeriod || moreThanOptions[DEFAULT_MORE_THAN_INDEX],
      onChange: (option?: SelectV2SingleOption) =>
        update({ relativePeriod: { ...relativePeriod, type: (option?.value as RelativeType) || RelativeType.DAYS }, selectedPeriod: option }),
    }

    return (
      <NumberAndSelectInput
        className={`${rootClass}__more-than-section`}
        dataTest={`${dataTest}-more-than-section`}
        numberInputProps={numberInputProps}
        selectV2Props={selectV2Props}
      />
    )
  }

  const renderIgnoreYearPopover = () => ignoreYearProps && <IgnoreYearPopover {...ignoreYearProps} className={`${rootClass}__ignore-year`} />

  const renderNextLastSection = () => {
    const numberInputProps: NumberInputProps = {
      value: relativePeriod.count,
      onChange: (count?: number) => update({ relativePeriod: { ...relativePeriod, count } }),
    }
    const nextLastOptions = getNextLastOptions(t)
    const selectV2Props: SelectV2Props = {
      options: nextLastOptions,
      value: selectedPeriod || nextLastOptions[DEFAULT_NEXT_LAST_INDEX],
      onChange: (option?: SelectV2SingleOption) =>
        update({ relativePeriod: { ...relativePeriod, type: (option?.value as RelativeType) || RelativeType.DAYS }, selectedPeriod: option }),
    }

    return (
      <>
        <NumberAndSelectInput
          className={`${rootClass}__next-last-section`}
          dataTest={`${dataTest}-next-last-section`}
          numberInputProps={numberInputProps}
          selectV2Props={selectV2Props}
        />
        {renderIgnoreYearPopover()}
      </>
    )
  }

  const renderSpecificDateSection = () => {
    const datePickerProps: DatePickerProps = {
      onChange: (specificDate?: Date) => update({ specificDate }),
      value: specificDate,
    }
    if (selectedTimeframe.value === SpecificDate.SPECIFIC_MONTH) {
      datePickerProps.picker = 'month'
      datePickerProps.placeholder = t('Select a month')
      datePickerProps.format = ignoreYearProps?.ignoreYear ? MONTH_WITHOUT_YEAR_FORMAT : MONTH_FORMAT
    } else if (selectedTimeframe.value === SpecificDate.SPECIFIC_QUARTER) {
      datePickerProps.picker = 'quarter'
      datePickerProps.placeholder = t('Select a quarter')
      datePickerProps.format = ignoreYearProps?.ignoreYear ? QUARTER_WITHOUT_YEAR_FORMAT : ''
    }
    return (
      <DateInput
        className={`${rootClass}__specific-date-section`}
        dataTest={`${dataTest}-specific-date-section`}
        datePickerProps={datePickerProps}
        ignoreYearProps={ignoreYearProps}
      />
    )
  }

  const renderCustomDateSection = () => {
    const dateRangePickerProps: DateRangePickerProps = {
      onCalendarSubmit: (customDateRange: DateRangeValueType) => {
        update({ customDateRange })
      },
      defaultRange: customDateRange,
      allowFutureDates: true,
    }
    return (
      <DateInput
        className={`${rootClass}__custom-date-section`}
        dataTest={`${dataTest}-custom-date-section`}
        dateRangePickerProps={dateRangePickerProps}
        ignoreYearProps={ignoreYearProps}
      />
    )
  }

  const renderCustomRelativeSection = () => {
    let selectV2Props: SelectV2Props = {
      onChange: (relativeRange?: SelectV2SingleOption) => update({ relativeRange }),
    }
    if (isBehavior) {
      const options = getRelativeRangeBehaviorOptions(t)
      selectV2Props = {
        ...selectV2Props,
        options,
        value: relativeRange || options[DEFAULT_RELATIVE_RANGE_INDEX],
      }
    } else {
      const groupedOptions = getRelativeRangeProfileOptions(t)
      selectV2Props = {
        ...selectV2Props,
        groupedOptions,
        value: relativeRange || groupedOptions[DEFAULT_RELATIVE_RANGE_GROUP_INDEX].options[DEFAULT_RELATIVE_RANGE_INDEX],
      }
    }
    const numberRangeInputProps: NumberRangeInputProps = {
      onMinChange: (rangeStart?: number) => update({ numberRange: { ...numberRange, rangeStart } }),
      onMaxChange: (rangeEnd?: number) => update({ numberRange: { ...numberRange, rangeEnd } }),
      minValue: numberRange.rangeStart,
      maxValue: numberRange.rangeEnd,
    }
    return (
      <>
        <NumberAndSelectInput
          className={`${rootClass}__custom-relative-section`}
          dataTest={`${dataTest}-custom-relative-section`}
          selectV2Props={selectV2Props}
          numberRangeInputProps={numberRangeInputProps}
        />
        {renderIgnoreYearPopover()}
      </>
    )
  }
  const renderDateCondition = () => {
    const { value } = selectedTimeframe
    if (value in NextLastDate) {
      return renderNextLastSection()
    }
    if (value in SpecificDate) {
      return renderSpecificDateSection()
    }
    if (value === CUSTOM_DATE) {
      return renderCustomDateSection()
    }
    if (value === CUSTOM_RELATIVE_DATE) {
      return renderCustomRelativeSection()
    }
    if (value === MORE_THAN) {
      return renderMoreThanSection()
    }
    return renderIgnoreYearPopover()
  }

  useEffect(() => {
    update({
      relativePeriod: {
        ...relativePeriod,
        direction: selectedTimeframe.value === NextLastDate.THE_NEXT ? RelativeDirection.FUTURE : RelativeDirection.PAST,
      },
    })
  }, [selectedTimeframe])

  if (comparison) {
    if (comparison.value === AfterBeforeDate.IS_BEFORE) {
      groupedOptions = groupedOptions.map((group) => ({
        label: group.label,
        options: group.options.filter((option) => option.value !== NextLastDate.THE_NEXT),
      }))
    } else if (comparison.value === AfterBeforeDate.IS_AFTER) {
      groupedOptions = groupedOptions.map((group) => ({
        label: group.label,
        options: group.options.filter((option) => option.value !== NextLastDate.THE_LAST),
      }))
    }
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <SelectV2
        className={`${rootClass}__condition-select`}
        dataTest={`${dataTest}-condition-select`}
        renderSingleValue={renderSingleValue}
        groupedOptions={groupedOptions}
        value={selectedTimeframe}
        onChange={(selectedTimeframe) => onTimeframeChange(selectedTimeframe as SelectV2SingleOption)}
        isClearable={false}
        isSearchable={false}
        showGroupsWithoutLabel
      />
      {renderDateCondition()}
    </div>
  )
}

export default DateTimeframes
