import { gql } from '@apollo/client'

export default gql`
  query deliverabilityConcern($messageId: String!) {
    deliverabilityConcern(messageId: $messageId) {
      isUserAddressInText
      isUserAddressInHtml
      isTestLaunch
      domainConfig {
        checkDomainConfig
        correctDomainConfig
        domainConfigErrors
      }
    }
  }
`
