import { TFunction } from 'i18next'

import { ProgramSource } from '@graphql/types/query-types'
import { Update } from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'
import {
  GetListSchemaRequestParams,
  GetListSchemaRequestType,
} from '@src/pages/programs/upgradeManager/graphQL/UpgradeManagerRequests.classic.graphQL'
import { showStatusToast } from '@src/pages/programs/upgradeManager/helper/UpgradeManager.helper'
import { logNewRelicError } from '@utils/new-relic.utils'

interface BaseParams {
  t: TFunction
  update: Update
}

interface GetAllCopiedSegmentsByLegacyExternalIdsUtilsParams extends GetListSchemaRequestParams, BaseParams {
  getListSchemaRequest: GetListSchemaRequestType
}

export const getListSchemaRequestUtils = async ({ getListSchemaRequest, listIds, t, update }: GetAllCopiedSegmentsByLegacyExternalIdsUtilsParams) => {
  try {
    const { data, errors } = await getListSchemaRequest({ listIds })
    if (!!data?.listSchemas) {
      const programSources: ProgramSource[] = data.listSchemas.map(({ listId, displayName, schema }) => ({
        id: listId,
        baseId: '',
        name: displayName,
        headers: schema?.headers ?? [],
      }))
      return programSources
    }
    if (errors) {
      logNewRelicError(errors)
      showStatusToast(update, t('AutomatedPrograms.UpgradeManager.Requests.Classic.GetListSchema.Error'))
    }
  } catch (e) {
    logNewRelicError(e)
    showStatusToast(update, t('AutomatedPrograms.UpgradeManager.Requests.Classic.GetListSchema.Error'))
  }
}
