import React from 'react'
import { Row } from 'react-table'

import { DirectionOptions } from '@components/TimespanPicker/TimespanPicker'
import { ChartField } from '@const/Chart.constants'
import { RevenueImpactContainerState } from '@src/pages/reports/revenueImpact/RevenueImpactContainer'
import {
  BASE_CHART_HEIGHT,
  CampaignGroups,
  Campaigns,
  colorLabels,
  ColumnFields,
  DateIncrement,
  LEGEND_HEIGHT_UNIT,
  MONTH_DATE_RANGE,
  QUARTER_DATE_RANGE,
  Stages,
  StageTypeLabel,
  StageTypes,
} from '@src/pages/reports/revenueImpact/RevenueImpactContainer.constants'
import { dateYesterdayMidnight, getDate } from '@utils/date'
import { formatNumber } from '@utils/numbers'

const newCol = (tableCol: ColumnFields) => {
  return {
    campaigns: tableCol.campaigns,
    opportunities: tableCol.opportunities,
    won: tableCol.won,
    percentage: `${tableCol.percentage}%`,
    pipeline: `$${tableCol.pipeline}`,
    revenue: `$${tableCol.revenue}`,
    average: `$${tableCol.average}`,
  }
}

const getTotals = (tableData: any) => {
  return {
    opportunities: tableData.reduce((acc: number, entry: any) => {
      return acc + entry.opportunities
    }, 0),
    won: tableData.reduce((acc: number, entry: any) => {
      return acc + entry.wonOpportunities
    }, 0),
    pipeline: tableData.reduce((acc: number, entry: any) => {
      return acc + entry.opportunitiesAmount
    }, 0),
    revenue: tableData.reduce((acc: number, entry: any) => {
      return acc + entry.wonOpportunitiesAmount
    }, 0),
  }
}

const getColData = (campaignData: CampaignGroups | Campaigns | Stages, campaignName: string, finalCol?: boolean) => {
  const { opportunities, won, revenue, pipeline } = finalCol
    ? getTotals(campaignData)
    : {
        opportunities: campaignData.opportunities,
        won: campaignData.wonOpportunities ?? 0,
        pipeline: campaignData.opportunitiesAmount,
        revenue: campaignData.wonOpportunitiesAmount ?? 0,
      }
  const percentage = won / opportunities
  const average = revenue / won

  return {
    campaigns: campaignName,
    percentage: isNaN(percentage) ? '0' : percentage === 1 ? (percentage * 100).toString() : (percentage * 100).toFixed(1),
    average: isNaN(average) ? '0' : formatNumber(average),
    opportunities: formatNumber(opportunities),
    won: formatNumber(won),
    pipeline: formatNumber(pipeline),
    revenue: formatNumber(revenue),
  }
}

const getStages = (stages: Stages[]) => {
  const sortedStages = [...stages].sort((a: Stages, b: Stages) => b.opportunities - a.opportunities)

  return sortedStages.map((stage: Stages) => ({
    ...newCol(getColData(stage, stage.stageName)),
  }))
}

const getStageTypes = (stageTypes: StageTypes[], campaignData: Campaigns) => {
  return [...stageTypes].reverse().map((stageType: StageTypes) => {
    const colorIndex = stageType.type === StageTypeLabel.OPEN ? 0 : stageType.type === StageTypeLabel.CLOSED_WON ? 1 : 2
    const colData = getTotals(stageType.stages)
    const percentage = campaignData.wonOpportunities / colData.opportunities
    const average = campaignData.wonOpportunitiesAmount / campaignData.wonOpportunities
    const isWonStageType = stageType.type !== StageTypeLabel.OPEN && stageType.type !== StageTypeLabel.CLOSED_LOST

    const tableColData = {
      campaigns: colorLabels[colorIndex].text,
      opportunities: formatNumber(colData.opportunities),
      won: isWonStageType ? formatNumber(campaignData.wonOpportunities) : '0',
      percentage: isNaN(percentage) || !isWonStageType ? '0' : percentage === 1 ? (percentage * 100).toString() : (percentage * 100).toFixed(1),
      pipeline: formatNumber(colData.pipeline),
      revenue: isWonStageType ? formatNumber(campaignData.wonOpportunitiesAmount) : '0',
      average: isNaN(average) || !isWonStageType ? '0' : formatNumber(average),
    }

    const stageData = isWonStageType
      ? [
          {
            ...stageType.stages[0],
            wonOpportunities: campaignData.wonOpportunities,
          },
        ]
      : stageType.stages

    return {
      ...newCol(tableColData),
      subRows: stageType.stages ? getStages(stageData) : undefined,
    }
  }, campaignData)
}

const getCampaigns = (campaigns: Campaigns[]) => {
  return campaigns.map((campaign: Campaigns) => {
    const campaignName = campaign.name ? campaign.name : '[No Campaign]'

    return {
      ...newCol(getColData(campaign, campaignName)),
      subRows: campaign.stageTypes ? getStageTypes(campaign.stageTypes, campaign) : undefined,
    }
  })
}

export const getTableData = (campaignGroups: CampaignGroups[], campaignHeaderText: string): Row[] => {
  const sortedCampaignGroups: any = [...campaignGroups].sort((a: CampaignGroups, b: CampaignGroups) => b.opportunities - a.opportunities)

  const table = sortedCampaignGroups.map((campaignGroup: CampaignGroups) => {
    const campaignName = campaignGroup.type ? campaignGroup.type : '[No Campaign Type]'

    if (!campaignGroup.type && campaignHeaderText) {
      const innerRow = getCampaigns(campaignGroup.campaigns).pop()
      return {
        ...innerRow,
        campaigns: campaignHeaderText,
      }
    } else
      return {
        ...newCol(getColData(campaignGroup, campaignName)),
        subRows: campaignGroup.campaigns ? getCampaigns(campaignGroup.campaigns) : undefined,
      }
  })

  table.push({ ...newCol(getColData(sortedCampaignGroups, 'Total', true)) })
  return table
}

export const getChartHeight = (leadsConverted: ChartField[], leadsCreated: ChartField[]) => {
  const leadsConvertedSize = Array.isArray(leadsConverted[0].data) ? leadsConverted[0].data.length : 1
  const leadsCreatedSize = Array.isArray(leadsCreated[0].data) ? leadsCreated[0].data.length : 1
  const legendSize = Math.max(leadsConvertedSize, leadsCreatedSize)

  return BASE_CHART_HEIGHT + legendSize * LEGEND_HEIGHT_UNIT
}

export const getDateRange = (startTime: string, quarterSelected: boolean) => {
  return quarterSelected
    ? QUARTER_DATE_RANGE.map((_entry, i: number) => {
        const time = new Date(new Date(startTime).getUTCFullYear(), new Date(startTime).getUTCMonth() - 3 * i, 1)
        return `${time.toString().substring(4, 7)} ${time.getUTCFullYear().toString()}`
      }).reverse()
    : MONTH_DATE_RANGE.map((_entry, i: number) => {
        const time = new Date(new Date(startTime).getUTCFullYear(), new Date(startTime).getUTCMonth() - i, 1)
        return `${time.toString().substring(4, 7)} ${time.getUTCFullYear().toString()}`
      }).reverse()
}

export const handleAltDateChange = (
  state: RevenueImpactContainerState,
  setState: React.Dispatch<React.SetStateAction<any>>,
  direction: DirectionOptions
) => {
  let startTime, endTime

  if (state.quarterSelected) {
    if (direction === DirectionOptions.NEXT) {
      startTime = getDate(state.startTime, DateIncrement.NEXT_QUARTER)
      endTime = getDate(state.endTime, DateIncrement.NEXT_QUARTER)
    } else {
      startTime = getDate(state.startTime, DateIncrement.PREV_QUARTER)
      endTime = getDate(state.endTime, DateIncrement.PREV_QUARTER)
    }
  } else {
    if (direction === DirectionOptions.NEXT) {
      startTime = getDate(state.startTime, DateIncrement.NEXT_MONTH)
      endTime = getDate(state.endTime, DateIncrement.NEXT_MONTH + 1, 0)
    } else {
      startTime = getDate(state.startTime, DateIncrement.PREV_MONTH)
      endTime = getDate(state.endTime, DateIncrement.PREV_MONTH + 1, 0)
    }
  }

  const nextDisabled =
    new Date(new Date(endTime).getUTCFullYear(), new Date(endTime).getUTCMonth(), new Date(endTime).getUTCDate()) >= dateYesterdayMidnight

  setState({
    ...state,
    nextDisabled,
    startTime,
    endTime,
    dateRange: getDateRange(startTime, state.quarterSelected),
  })
}

export const handleIncrementChange = (state: RevenueImpactContainerState, setState: React.Dispatch<React.SetStateAction<any>>, increment: string) => {
  const quarterSelected = increment === 'quarterly'
  let startTime, endTime

  if (increment === 'quarterly') {
    startTime = state.curQuarterStart
    endTime = state.curQuarterEnd
  } else {
    startTime = getDate(new Date().toISOString().substring(0, 10))
    endTime = getDate(new Date().toISOString().substring(0, 10), 1, 0)
  }

  setState({
    ...state,
    nextDisabled: true,
    startTime,
    endTime,
    altViewOptions: [
      {
        ...state.altViewOptions[0],
        selected: quarterSelected,
      },
      {
        ...state.altViewOptions[1],
        selected: !quarterSelected,
      },
    ],
    quarterSelected: quarterSelected,
    dateRange: getDateRange(startTime, quarterSelected),
  })
}
