import React, { FC, useState } from 'react'

import { DateRangeValueType } from '@components/DateRangePicker/DateRangePicker'
import { renderLoader } from '@components/Loader/Loader'
import { defaultDateRange } from '@src/pages/reports/revenueImpactNew/mocks/RevenueImpactNew.mock'
import RevenueImpact from '@src/pages/reports/revenueImpactNew/RevenueImpactNew'

import { useGetCRMSyncing } from './hooks/useGetCRMSyncing'
import useGetRevenueImpactData from './hooks/useGetRevenueImpactData'
import usePreservedData from './hooks/usePreservedData'

const RevenueImpactNewContainer: FC = () => {
  const [selectedRange, setSelectedRange] = useState<DateRangeValueType>(defaultDateRange)
  const { loading: loadingPreserved, preservedData, handlePreserveDataChanges } = usePreservedData()
  const { loading: loadingData, state } = useGetRevenueImpactData(selectedRange)
  const CRMSynchInfo = useGetCRMSyncing()

  if (!preservedData) {
    return renderLoader('loader--white-background')
  }

  return (
    <RevenueImpact
      {...state}
      loading={loadingPreserved || loadingData}
      preservedData={preservedData}
      handlePreserveDataChanges={handlePreserveDataChanges}
      range={selectedRange}
      headerProps={{
        rangePickerProps: { onCalendarSubmit: setSelectedRange, defaultRange: selectedRange },
        ...CRMSynchInfo,
      }}
    />
  )
}

export default RevenueImpactNewContainer
