import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { DndProvider } from 'react-dnd'

import classNames from 'classnames'

import { useApolloClient, useMutation } from '@apollo/client'
import AddButton from '@components/AddButton/AddButton'
import ButtonIcon, { ButtonIconType } from '@components/ButtonIcon/ButtonIcon'
import Checkbox from '@components/Checkbox/Checkbox'
import DraggableItem, { DraggableElement } from '@components/DraggableItem/DraggableItem'
import DragLayer from '@components/DragLayer/DragLayer'
import InfoTooltip from '@components/InfoTooltip/InfoTooltip'
import InputV2 from '@components/InputV2/InputV2'
import { LabelType, LabelV2 } from '@components/LabelV2/LabelV2'
import { LinkTextButton, LinkTextButtonSize } from '@components/LinkTextButton/LinkTextButton'
import Modal, { ModalBody } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import ModalFooterV2 from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import ModalHeaderV2 from '@components/Modal/components/ModalHeaderV2/ModalHeaderV2'
import Spinner from '@components/Spinner/Spinner'
import { SvgNames } from '@components/Svg/index'
import { SvgColor } from '@components/Svg/Svg'
import TextArea from '@components/TextArea/TextArea'
import Toggle from '@components/Toggle/Toggle'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, ModalBodyStyle, TextType, TextWeight } from '@components/Typography/Typography'
import UploadImage, { ImageURL, ImageWithType } from '@components/UploadImage/UploadImage'
import { useTranslation } from '@const/globals'
import uploadImage from '@graphql/mutations/uploadImage'
import { UploadImageMutation, UploadImageMutationVariables } from '@graphql/types/mutation-types'
import { ItemType } from '@utils/categorization'
import { dragAndDropManager } from '@utils/dnd-utils'

import './PollBlockModal.css'

export interface PollBlockValues {
  image?: string
  includeImageInEmail: boolean
  prompt: string
  choices: DraggableElement[]
  canSelectMultiple: boolean
  commentPrompt: string
  hasCommentPrompt: boolean
  canSeeResponses: boolean
}

export interface PollBlockModalProps {
  pollBlock: PollBlockValues
  className?: string
  dataTest?: string
  onClose: () => void
  onAction: (pollBlock: PollBlockValues) => void
  isOpen: boolean
}

interface PollBlockModalState extends PollBlockValues {
  hasCommentPrompt: boolean
  touched: boolean
  hasChoiceError: boolean
}

const rootClass = 'poll-block-modal'
const textBase = 'EmailComposer.PollBlockModal'
const moreInfoLink = 'https://connect.act-on.com/hc/en-us/articles/360023944513-Using-Multiple-Choice-Surveys-in-Emails'
const MAX_IMAGE_SIZE = 10 * 1024 * 1024
const MAX_PROMPT_LENGTH = 600

const PollBlockModal: FC<PollBlockModalProps> = (props: PollBlockModalProps) => {
  const { pollBlock, dataTest = rootClass, className = '', onAction, onClose, isOpen } = props
  const [state, setState] = useState<PollBlockModalState>({
    ...pollBlock,
    touched: false,
    hasChoiceError: false,
  })
  const {
    hasCommentPrompt,
    prompt,
    image,
    includeImageInEmail,
    choices,
    commentPrompt,
    canSeeResponses,
    canSelectMultiple,
    touched,
    hasChoiceError,
  } = state

  const [canSubmit, setCanSubmit] = useState(false)
  const [promptTouched, setPromptTouched] = useState(false)

  const [showPollImage, setShowPollImage] = useState(false)
  const pollImageButtonRef = useRef<HTMLButtonElement>(null)
  const isManageMode = !!pollBlock.prompt

  const client = useApolloClient()
  const { t } = useTranslation()

  const [uploadImageMutation, { loading }] = useMutation<UploadImageMutation, UploadImageMutationVariables>(uploadImage, {
    client,
    fetchPolicy: 'no-cache',
  })

  const onUploadImage = async (variables: UploadImageMutationVariables) => await uploadImageMutation({ variables })

  useEffect(() => {
    const isSubmittable = !!prompt && choices.length >= 2 && choices.every((choice) => !!choice.name)
    setCanSubmit(isSubmittable)
  }, [choices, prompt])

  useEffect(() => {
    if (image) {
      setShowPollImage(true)
    }
  }, [image])

  const handleAction = () => {
    setCanSubmit(false)
    const finalCommentPrompt = commentPrompt ?? t(`${textBase}.Settings.CommentPrompt.Placeholder`)
    onAction({ prompt, commentPrompt: finalCommentPrompt, choices, canSelectMultiple, canSeeResponses, image, includeImageInEmail, hasCommentPrompt })
  }

  const handleClose = () => {
    setCanSubmit(false)
    onClose()
  }

  const onDropChoice = (movedChoice: DraggableElement, droppedAt: number) => {
    const rawChoices = [...choices.slice(0, droppedAt), movedChoice, ...choices.slice(droppedAt)]
    rawChoices.splice(movedChoice.index + (movedChoice.index < droppedAt ? 0 : 1), 1)
    const newChoices = rawChoices.map((choice, index) => ({ ...choice, index }))
    setState({ ...state, choices: newChoices, touched: true })
  }

  const onRemoveChoice = (choice: DraggableElement) => {
    const newChoices = choices.filter((c) => c.index !== choice.index)
    const newHasChoiceError = newChoices.some((c) => c.name === '')
    setState({ ...state, choices: newChoices.map((choice, index) => ({ ...choice, index })), touched: true, hasChoiceError: newHasChoiceError })
  }

  const onChangeChoice = (newChoice: DraggableElement) => {
    const newChoices = [...choices]
    newChoices[newChoice.index] = newChoice
    setState({ ...state, choices: newChoices, touched: true, hasChoiceError: newChoice.name === '' })
  }

  const onImageChange = useCallback(
    async (data?: ImageWithType | ImageURL) => {
      const { url, imageBase64, imageType, title, linkUrl } = (data as ImageWithType & ImageURL) || {}
      if (imageBase64 && !url) {
        const { data } = await onUploadImage({ base64File: imageBase64, extension: imageType, folderName: 'Default Folder', fileName: title })
        if (data) {
          const { linkUrl, id } = data.uploadImage
          if (url && id) {
            setState((prevState) => ({ ...prevState, image: linkUrl || url, includeImageInEmail: true, touched: true }))
          }
        }
      } else if (!imageBase64 && url) {
        setState((prevState) => ({ ...prevState, image: linkUrl || url, includeImageInEmail: true, touched: true }))
      } else {
        setState((prevState) => ({ ...prevState, image: undefined, includeImageInEmail: false, touched: true }))
      }
    },
    [setState]
  )

  const header = (
    <ModalHeaderV2
      headerType={ModalHeaderType.Form}
      className={`${rootClass}__header`}
      headerText={t(`${textBase}.Title.${isManageMode ? 'Manage' : 'Create'}`)}
    />
  )

  const pollImageLabel = (
    <Typography
      text={t(`${textBase}.Image.Label`)}
      type={TextType.BODY_TEXT}
      weight={TextWeight.MEDIUM}
      tagProps={{ light: { type: TextType.BODY_TEXT_LIGHT, weight: TextWeight.REGULAR, inline: true } }}
      tagComponents={{
        Button: (
          <ButtonIcon
            icon={showPollImage ? SvgNames.caretDown : SvgNames.caretRight}
            type={ButtonIconType.TERTIARY}
            secondaryColor={SvgColor.TEXT_GRAY}
            register={pollImageButtonRef}
            className={`${rootClass}__image-toggle`}
            onClick={() => {
              pollImageButtonRef.current?.blur()
              setShowPollImage(!showPollImage)
            }}
          />
        ),
      }}
      className={classNames(`${rootClass}__image-label`, { [`${rootClass}__image-label-open`]: showPollImage })}
    />
  )

  const imageToggleLabel = (
    <div className={`${rootClass}__upload-toggle-label`}>
      <Typography text={t(`${textBase}.Image.Toggle`)} />
      <InfoTooltip>{t(`${textBase}.Image.Toggle.Tooltip`)}</InfoTooltip>
    </div>
  )

  const promptLabel = (
    <Typography
      text={t(`${textBase}.Prompt`)}
      type={TextType.BODY_TEXT}
      weight={TextWeight.MEDIUM}
      tagProps={{ mediumLight: { weight: TextWeight.MEDIUM_LIGHT, type: TextType.BODY_TEXT_LIGHT, inline: true } }}
    />
  )

  const renderChoices = () => {
    return choices.map((choice) => {
      return (
        <DraggableItem
          key={choice.index}
          item={choice}
          placeholder={t(`${textBase}.Placeholder`)}
          listingPosition={choice.index + 1}
          className={`${rootClass}__choice`}
          onDrop={onDropChoice}
          onRemove={onRemoveChoice}
          onChange={onChangeChoice}
          onBlur={onChangeChoice}
          canBeRemoved={choices.length > 2}
          error={hasChoiceError && choice.name === '' ? t(`${textBase}.Choices.Error`) : undefined}
        />
      )
    })
  }

  const renderCommentPromptInput = () => (
    <div className={`${rootClass}__comment-prompt`}>
      <div className={`${rootClass}__comment-prompt-divider`} />
      <InputV2
        value={commentPrompt}
        labelProps={{ label: <Typography text={t(`${textBase}.Settings.CommentPrompt.Label`)} weight={TextWeight.MEDIUM} /> }}
        placeholder={t(`${textBase}.Settings.CommentPrompt.Placeholder`)}
        onChange={(event) => setState({ ...state, commentPrompt: event.target.value, touched: true })}
        className={`${rootClass}__comment-prompt-label`}
        dataTest={`${dataTest}-comment-prompt`}
      />
    </div>
  )

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header} paddingV2>
      <DndProvider manager={dragAndDropManager}>
        <DragLayer itemType={ItemType.POLL_CHOICE} />
        <ModalBody className={`${rootClass}__body`}>
          <Typography
            text={t(`${textBase}.Info`)}
            {...ModalBodyStyle}
            tagComponents={{
              TextLink: <LinkTextButton link={moreInfoLink} size={LinkTextButtonSize.MEDIUM} hideIconLeft dataTest={`${rootClass}-text-link`} />,
            }}
            className={`${rootClass}__info`}
          />
          <LabelV2 label={pollImageLabel} labelType={LabelType.medium} withoutMargin className={`${rootClass}__upload-image-label`} />
          {showPollImage && (
            <div className={`${rootClass}__upload`}>
              <Typography
                text={t('EmailComposer.PollBlockModal.Image.Info')}
                type={TextType.BODY_TEXT_SMALL_LIGHT}
                lineHeight={LineHeight.MEDIUM_SMALL}
                className="push-up"
              />
              {loading ? (
                <Spinner />
              ) : (
                <UploadImage
                  image={image as string}
                  maxSize={MAX_IMAGE_SIZE}
                  canSelectExistingImage
                  onImageChange={onImageChange}
                  className={`${rootClass}__upload-image`}
                />
              )}
              <Toggle
                label={imageToggleLabel}
                isOn={includeImageInEmail}
                disabled={!image}
                onToggle={() => setState({ ...state, includeImageInEmail: !includeImageInEmail, touched: true })}
                className={`${rootClass}__upload-toggle`}
              />
            </div>
          )}
          <TextArea
            name={'prompt'}
            label={promptLabel}
            placeholder={t(`${textBase}.Prompt.Placeholder`)}
            value={prompt}
            maxCharacterProps={{ maxLength: MAX_PROMPT_LENGTH }}
            rows={3}
            resize={false}
            error={promptTouched && prompt === ''}
            onChange={(e) => setState({ ...state, prompt: e.target.value, touched: true })}
            onBlur={() => setPromptTouched(true)}
            dataTest={`${dataTest}-prompt`}
          />{' '}
          {promptTouched && prompt === '' && (
            <Typography text={t(`${textBase}.Prompt.Error`)} type={TextType.ERROR} className={`${rootClass}__error`} />
          )}
          <LabelV2 label={t(`${textBase}.Choices.Label`)} labelType={LabelType.medium} className={`${rootClass}__choices-label`} />
          {renderChoices()}
          <Tooltip
            hide={choices.length < 10}
            minimalPadding={false}
            trigger={
              <AddButton
                label={t(`${textBase}.Choices.Add`)}
                disabled={choices.length >= 10 || hasChoiceError}
                onClick={() => setState({ ...state, choices: [...choices, { name: '', index: choices.length }] })}
                dataTest={`${dataTest}-button-add-choice`}
              />
            }
          >
            {t(`${textBase}.Choices.Add.Tooltip`)}
          </Tooltip>
          <LabelV2 label={t(`${textBase}.Settings.Label`)} labelType={LabelType.medium} className={`${rootClass}__setting-label`} />
          <Checkbox
            checked={canSelectMultiple}
            label={t(`${textBase}.Settings.Multiple`)}
            onChange={() => setState({ ...state, canSelectMultiple: !canSelectMultiple, touched: true })}
            className={`${rootClass}__setting`}
          />
          <Checkbox
            checked={hasCommentPrompt}
            onChange={() => setState({ ...state, hasCommentPrompt: !hasCommentPrompt, touched: true })}
            label={t(`${textBase}.Settings.CommentPrompt`)}
            className={`${rootClass}__setting`}
            dataTest={`${dataTest}-checkbox-comment-prompt`}
          />
          {hasCommentPrompt && renderCommentPromptInput()}
          <Checkbox
            checked={canSeeResponses}
            label={t(`${textBase}.Settings.SeeResponses`)}
            onChange={() => setState({ ...state, canSeeResponses: !canSeeResponses, touched: true })}
            className={`${rootClass}__setting`}
          />
        </ModalBody>
      </DndProvider>
      <ModalFooterV2
        footerType={ModalFooterType.Form}
        className={`${rootClass}__footer`}
        buttons={{
          actionButtonLabel: t(isManageMode ? 'Save' : 'Create'),
          actionButtonOnClick: handleAction,
          actionButtonDisabled: !canSubmit || !touched || loading,
          cancelButtonLabel: t('Cancel'),
        }}
        onClose={handleClose}
      />
    </Modal>
  )
}

export default PollBlockModal
