import { gql } from '@apollo/client'

export default gql`
  query ListSchema($listIds: [String!]!, $count: Int, $readOnly: Boolean) {
    listSchemas(listIds: $listIds, count: $count, readOnly: $readOnly) {
      listId
      displayName
      schema {
        headers
        headersWithPreviews {
          columnName
          columnIndex
          isPhoneNumber
          previewRows
        }
        fields {
          id
          length
          type
          picklist
        }
      }
    }
  }
`
