import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/Button'
import SplitButton from '@components/SplitButton/SplitButton'
import Typography, { LineHeight, TextWeight } from '@components/Typography/Typography'

import { getSplitButtonOptions } from './utils/LegacyBanner.utils'

import './LegacyBanner.css'

export interface LegacyBannerProps {
  isSingleButton?: boolean
  isOptIn?: boolean
  className?: string
  dataTest?: string
  onClick?: () => void
  noMargin?: boolean
}

const rootClass = 'legacy-banner'

export const LegacyBanner: FC<LegacyBannerProps> = ({ dataTest = rootClass, isSingleButton, onClick, isOptIn, className, noMargin = false }) => {
  const { t } = useTranslation()

  const text = isOptIn ? 'Legacy.Banner.Text.Opt.In' : 'Legacy.Banner.Text'

  return (
    <div data-test={dataTest} className={classNames(className, rootClass, { [`${rootClass}__single`]: isSingleButton })}>
      <Typography
        text={t(text)}
        lineHeight={LineHeight.MEDIUM}
        weight={TextWeight.MEDIUM}
        dataTest={`${dataTest}-text`}
        className={classNames(`${rootClass}__text`, { [`${rootClass}__text-margin`]: !noMargin })}
      />
      {isSingleButton ? (
        <Button buttonType={ButtonType.WHITE} className={`${rootClass}__single-button`} onClick={onClick} dataTest={`${dataTest}-single-button`}>
          {t('Blank message')}
        </Button>
      ) : (
        <SplitButton
          options={getSplitButtonOptions()}
          className={`${rootClass}__split-button`}
          dropdownCaretFill
          largeDropdown
          dataTest={`${dataTest}-split-button`}
        />
      )}
    </div>
  )
}
