import React, { FC, useContext, useEffect, useState } from 'react'

import classNames from 'classnames'

import CRMSourcesDefinition from '@complex/ContactSegments/SegmentInfoHoverCard/components/CRMSourcesDefinition'
import { crmStatusObjects } from '@complex/ContactSegments/SegmentInfoHoverCard/SegmentInfoHoverCard.mock'
import SegmentInfoHoverCardSegmentDefinition from '@complex/ContactSegments/SegmentInfoHoverCardSegmentDefinition/SegmentInfoHoverCardSegmentDefinition'
import { getSegmentHierarchyUtils } from '@complex/ListPickerModalV2/components/ListInfoHoverCard/GraphQL/SegmentActivity.graphQL'
import { PreProcessedList } from '@complex/ListPickerModalV2/utils/interfaces/ListPickerModalInterfaces'
import { ListPickerModalContext } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import { goEditSegment } from '@complex/ListPickerModalV2/utils/ListPickerModalUtils'
import ExpandableBreadCrumbs from '@components/ExpandableBreadCrumbs/ExpandableBreadCrumbs'
import { LinkTextButton, LinkTextButtonSize } from '@components/LinkTextButton/LinkTextButton'
import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import { useAccountSettings } from '@utils/account/account.utils'
import { BouncesSegments, ItemSubType } from '@utils/categorization'
import { goEditCRMSourcedList, systemSegments } from '@utils/contactSegments/contactSegments.utils'
import { SegmentDefinition, EXPRESSION_EVALUATION_TYPE, Segment } from '@utils/contactSegments/contactSegments.utils'
import { getSegmentPath } from '@utils/contactSegments/contactSegments.utils'
import { getSegmentTypeLabel } from '@utils/contactSegments/SegmentInfoHoverCardSegmentDefinitionUtils'
import { CRM_STATUS } from '@utils/crm.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { replaceAll } from '@utils/strings'

import './ListInfoHoverCard.css'

interface ListInfoHoverCardProps {
  className?: string
  dataTest?: string
  segmentData: PreProcessedList
}

interface State {
  segmentHierarchy: Segment[]
  segmentDefinition: SegmentDefinition
  crmSourceName?: string
  crmSources?: string[]
  showErrorPopOver?: boolean
}

const rootClass = 'list-info-hover-card'

const ListInfoHoverCard: FC<ListInfoHoverCardProps> = (props: ListInfoHoverCardProps) => {
  const { dataTest = rootClass, className = '', segmentData } = props
  const { getSegmentDefinition, values, getCRMSourcedSegmentDetails } = useContext(ListPickerModalContext)
  const { crmSourceOptions = [] } = values

  const [state, setState] = useState<State>({
    segmentHierarchy: [],
    segmentDefinition: {
      type: '',
      customBooleanExpression: null,
      expressions: [],
      expressionEvaluationType: 'AND' as EXPRESSION_EVALUATION_TYPE,
    },
  })

  const isCRMSourced = segmentData.subTypes?.includes(ItemSubType.CRM_SOURCED)
  const isCRMSourcedParentSegment = isCRMSourced && !segmentData.parent

  const { t } = useTranslation()
  const { segmentHierarchy, segmentDefinition, crmSourceName, crmSources } = state
  const systemSegmentsContent = !segmentData.isEditable && systemSegments[segmentData.name]
  const crmStatusData = segmentData.crmStatus ? crmStatusObjects[segmentData.crmStatus as CRM_STATUS] : undefined
  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })
  const segmentTypeLabel = getSegmentTypeLabel(segmentDefinition)
  const { disableSegmentModifications } = useAccountSettings()

  const getSegmentPathCrumbs = () =>
    getSegmentPath(segmentData.id, segmentHierarchy).reduce((crumbs: string[], list: Segment) => [...crumbs, list.name], [])

  useEffect(() => {
    if (segmentData.isEditable) {
      getSegmentDefinition(segmentData.id).then((segmentDefinition) => {
        setState((state) => ({ ...state, segmentDefinition: segmentDefinition as SegmentDefinition }))
      })
      getSegmentHierarchyUtils(segmentData.categorizationId, categorizationClient).then((segments) => {
        setState((state) => ({ ...state, segmentHierarchy: segments }))
      })
    }
  }, [segmentData])

  useEffect(() => {
    if (isCRMSourcedParentSegment) {
      getCRMSourcedSegmentDetails([segmentData.id]).then((segmentDetails) => {
        if (!!segmentDetails) {
          const { sourceType, syncedSegmentEntityFactors, syncedSegmentQueryFactors } = segmentDetails[0] || {}
          const sources = !!syncedSegmentEntityFactors
            ? syncedSegmentEntityFactors.map((factor) => factor?.label)
            : syncedSegmentQueryFactors?.map((factor) => factor?.label)
          setState((state) => ({ ...state, crmSourceName: sourceType, crmSources: sources as string[] }))
        }
      })
    }
  }, [segmentHierarchy])

  const renderSoftBouncesContent = () => (
    <div className={classNames(`${rootClass}__system`, `${rootClass}__system-soft-bounces`)}>
      <Typography
        text={t(
          'Contacts with delivery failure for typically temporary reasons, such as a recipient mail server being off line. Email delivery will be attempted, until the '
        )}
        type={TextType.BODY_TEXT_SMALL}
        inline
      />
      <LinkTextButton text={t('soft bounce limit')} link={`${rootContext}/contactSettings`} hideIcons />
      <Typography text={t(' is reached.')} type={TextType.BODY_TEXT_SMALL} inline />
    </div>
  )

  const renderCRMStatusTitle = () => (
    <div className={`${rootClass}__crm-status-title`}>
      <Typography text={t(crmStatusData?.title)} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.MEDIUM} />
      <Svg name={crmStatusData?.icon as SvgNames} className={`${rootClass}__crm-status-icon-${crmStatusData?.icon}`} type={SvgType.ICON} />
    </div>
  )

  const renderSystemSegmentsContent = () => {
    if (segmentData.name === BouncesSegments.SOFT_BOUNCES) {
      return renderSoftBouncesContent()
    }
    return (
      <Typography
        className={`${rootClass}__system`}
        text={
          systemSegmentsContent && systemSegmentsContent !== ''
            ? t(systemSegmentsContent)
            : `${t('Contacts who have opted in to your subscription category')} ${segmentData.name}.`
        }
        type={TextType.BODY_TEXT_SMALL}
      />
    )
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={classNames(`${rootClass}__section`, { [`${rootClass}__section-tree`]: segmentData.isEditable })}>
        {isCRMSourced && !!crmStatusData && (
          <>
            <div className={`${rootClass}__crm-status`}>
              {renderCRMStatusTitle()}
              {crmStatusData.subtitle && (
                <div className={`${rootClass}__crm-status-subtitle`}>
                  <Typography text={t(crmStatusData.subtitle)} type={TextType.BODY_TEXT_SMALL} inline />
                  {crmStatusData.helpLink && <LinkTextButton hideIconLeft text={t(crmStatusData.helpLink.text)} link={crmStatusData.helpLink.link} />}
                </div>
              )}
            </div>
            {!!segmentData.crmErrorDetails && (
              <Tooltip
                className={`${rootClass}__crm-error-tooltip`}
                inline
                openOnClick
                closeOnClick
                minimalPadding={false}
                trigger={<Typography text={t('View details')} type={TextType.NORMAL_TEXT_TEAL} weight={TextWeight.MEDIUM} inline />}
                triggerClassName={`${rootClass}__crm-error-tooltip-trigger`}
                childrenProps={{ type: TextType.ERROR, weight: TextWeight.MEDIUM }}
              >
                {segmentData.crmErrorDetails}
              </Tooltip>
            )}
            <div className={`${rootClass}__divider`} />
          </>
        )}
        {segmentData.isEditable && (
          <>
            <Typography
              text={t('SEGMENT TREE')}
              type={TextType.BODY_TEXT_LIGHT_TINY}
              weight={TextWeight.MEDIUM}
              inline
              className={`${rootClass}__section-title`}
            />
            {segmentHierarchy.length !== 0 ? (
              <ExpandableBreadCrumbs crumbs={getSegmentPathCrumbs()} baseCrumb={t('All Contacts')} dataTest={segmentData.id} />
            ) : (
              <Loader loaderType={LoaderTypes.row} />
            )}
            <div className={`${rootClass}__divider`} />
            <div className={classNames(`${rootClass}__section`, `${rootClass}__section-type`)}>
              <Typography
                text={`${t('SEGMENT TYPE:')} ${
                  isCRMSourcedParentSegment
                    ? `CRM SOURCED ${replaceAll(crmSourceOptions.find(({ name }) => name === crmSourceName)?.name ?? '', '_', ' ')}`.toUpperCase()
                    : segmentTypeLabel
                }`}
                type={TextType.BODY_TEXT_LIGHT_TINY}
                weight={TextWeight.MEDIUM}
                inline
                className={`${rootClass}__section-title`}
              />
              <LinkTextButton
                disabled={disableSegmentModifications || !segmentData.isEditable}
                text={t('Edit')}
                size={LinkTextButtonSize.SMALL}
                hideIcons
                onClick={() => (isCRMSourcedParentSegment ? goEditCRMSourcedList(segmentData) : goEditSegment(segmentData, window.location.pathname))}
              />
            </div>
            {isCRMSourcedParentSegment ? (
              !crmSources?.length && !segmentData.crmErrorDetails ? (
                <Loader loaderType={LoaderTypes.row} />
              ) : !!segmentData.crmErrorDetails ? (
                <Typography text={t('No data available')} inline weight={TextWeight.MEDIUM} type={TextType.ERROR} />
              ) : (
                <CRMSourcesDefinition crmSources={crmSources as string[]} />
              )
            ) : segmentDefinition ? (
              <SegmentInfoHoverCardSegmentDefinition data={segmentDefinition} contextValues={values} />
            ) : (
              <Typography text={t('No data available')} inline weight={TextWeight.MEDIUM} type={TextType.ERROR} />
            )}
          </>
        )}
      </div>
      {!segmentData.isEditable && renderSystemSegmentsContent()}
    </div>
  )
}

export default ListInfoHoverCard
