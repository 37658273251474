import { FilterQueryParams } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { FormType } from '@complex/ListingPage/Utils/ListingPage.constants'
import { MIRRORED_LISTS_ID } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import { GetAllItemsQuery, GetRecentItemsQuery } from '@graphql/types/microservice/categorization-types'
import { ItemDto } from '@graphql/types/query-types'
import { ItemType } from '@utils/categorization'
import { Form } from '@utils/forms'
import { logNewRelicError } from '@utils/new-relic.utils'
import { FetchPromise } from '@utils/types'

export const disableRowByCriteria = (form: Form) => {
  return (form.formType !== undefined && form.formType !== FormType.NEW) || form.listId.startsWith(MIRRORED_LISTS_ID)
}

export const getSuggestedFormsRequestResultsUtils = async (
  getSuggestedFormsRequest: (search: string, params?: FilterQueryParams) => FetchPromise<GetRecentItemsQuery>,
  search: string,
  params?: FilterQueryParams
) => {
  try {
    const { data } = await getSuggestedFormsRequest(search, params)
    return { data: data && data.getRecentItems ? (data.getRecentItems as ItemDto[]) : [] }
  } catch (error) {
    logNewRelicError(error)
    return { data: [], error: 'Something went wrong on our end. Please try again.' }
  }
}

export const getAllItemsRequestResultsUtils = async (
  getAllItemsTemplatesRequest: (type: ItemType, search: string, params?: FilterQueryParams) => FetchPromise<GetAllItemsQuery>,
  type: ItemType,
  search: string,
  params?: FilterQueryParams
) => {
  try {
    const { data } = await getAllItemsTemplatesRequest(type, search, params)
    return { data: data && data.getAllItems ? (data.getAllItems as ItemDto[]) : [] }
  } catch (error) {
    logNewRelicError(error)
    return { data: [], error: 'Something went wrong on our end. Please try again.' }
  }
}
