import React, { FC, useCallback, useMemo, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import DropZone from '@components/DropZone/DropZone'
import Modal, { ModalBody, ModalHeader, ModalFooter } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Spinner from '@components/Spinner/Spinner'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { FormatFile } from '@const/globals'

import UnsupportedTypeModal from './components/UnsupportedTypeModal/UnsupportedTypeModal'
import { ACCEPT, checkFileBase64 } from './utils/LandingPagesUploadModal.utils'

import './LandingPagesUploadModal.css'

interface LandingPagesUploadModalProps {
  className?: string
  dataTest?: string
  isOpen: boolean
  loading: boolean
  onClose: () => void
  onSubmit: (file?: string, fileName?: string) => void
}

const MAX_FILE_SIZE = 1572864

const rootClass = 'landing-pages-upload-modal'

const LandingPagesUploadModal: FC<LandingPagesUploadModalProps> = (props: LandingPagesUploadModalProps) => {
  const { dataTest = rootClass, className = '', isOpen, loading, onSubmit, onClose } = props

  const { t } = useTranslation()
  const [file, setFile] = useState<string | undefined>()
  const [fileName, setFileName] = useState<string | undefined>()
  const [fileSize, setFileSize] = useState<number>(0)
  const [progress, setProgress] = useState<number | undefined>(undefined)
  const [unsupportedType, setUnsupportedType] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>('')
  const showDropZone = useMemo<boolean>(() => !file && progress === undefined, [file, progress])

  const handleImageSelect = useCallback(
    (files: File[]) => {
      if (files[0]) {
        setFileName(files[0].name)
        setFileSize(files[0].size)
        const reader = new FileReader()
        reader.addEventListener('loadstart', (e) => {
          setProgress(e.loaded / e.total)
        })
        reader.addEventListener('progress', (e) => {
          setProgress(e.loaded / e.total)
        })
        reader.addEventListener('load', (e) => {
          const fileBase64 = checkFileBase64(e.target?.result)
          setProgress(undefined)
          if (files[0].size > MAX_FILE_SIZE) {
            setIsError(true)
            setErrorMsg(t('File size exceeds maximum limit 1.5MB.'))
            return
          }

          if (fileBase64) {
            setFile(fileBase64)
          } else {
            setUnsupportedType(true)
          }
        })
        reader.readAsDataURL(files[0])
      }
    },
    [t]
  )

  const handleRemoveFile = useCallback(() => {
    setFile(undefined)
    setFileName('')
    setFileSize(0)
  }, [])

  const header = (
    <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__header`}>
      {t('LandingPages.UploadModal.Title')}
    </ModalHeader>
  )

  return (
    <Modal className={classNames(rootClass, className)} isOpen={isOpen} header={header}>
      <ModalBody className={`${rootClass}__body`}>
        <div className={`${rootClass}__container`} data-test={dataTest}>
          {loading ? (
            <Spinner
              className={`${rootClass}__loader`}
              headline="LandingPages.UploadModal.Loader.Headline"
              text="LandingPages.UploadModal.Loader.text"
            />
          ) : (
            <>
              <Typography text={t('LandingPages.UploadModal.Info')} type={TextType.BODY_TEXT_SMALL_LIGHT} className={`${rootClass}__info`} />
              {showDropZone ? (
                <DropZone
                  className={`${rootClass}__drop-zone`}
                  dataTest={`${rootClass}-drop-zone`}
                  selectFileText={t('Upload an HTML or ZIP file')}
                  maxSize="1.5MB"
                  onFileSelected={handleImageSelect}
                  accept={ACCEPT}
                  isError={isError}
                  errorMsg={errorMsg}
                />
              ) : file ? (
                <div className={`${rootClass}__file`}>
                  <Svg className={`${rootClass}__file-svg`} name={SvgNames.blankMessage} />
                  <div className={`${rootClass}__file-info`}>
                    <div className={`${rootClass}__file-name-wrapper`}>
                      <Typography
                        className={classNames(`${rootClass}__file-name`, 'ellip')}
                        text={fileName}
                        type={TextType.BODY_TEXT_SMALL}
                        weight={TextWeight.BOLD}
                        lineHeight={LineHeight.MEDIUM_SMALL}
                      />
                      <Button buttonType={ButtonType.ICON} className={`${rootClass}__file-remove-button`} onClick={() => handleRemoveFile()}>
                        <Svg className={`${rootClass}__file-remove-icon`} name={SvgNames.close} type={SvgType.SMALL_ICON} fill={SvgColor.TEXT_GRAY} />
                      </Button>
                    </div>
                    <Typography text={FormatFile.readableBytes(fileSize)} type={TextType.BODY_TEXT_LIGHT_TINY} lineHeight={LineHeight.SMALL} />
                  </div>
                </div>
              ) : (
                <div className={`${rootClass}-progress`}>
                  <progress className={`${rootClass}-progress-bar`} value={progress} max="1" />
                  <Typography text={t('Progress.Uploading.File', { fileName })} />
                </div>
              )}
            </>
          )}
          {unsupportedType && <UnsupportedTypeModal isOpen onClick={() => setUnsupportedType(false)} fileName={fileName} />}
        </div>
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form} className={`${rootClass}__footer`}>
        <Button buttonType={ButtonType.TERTIARY} onClick={() => onClose()} disabled={loading} dataTest={`${dataTest}-button-tertiary`}>
          {t('Back')}
        </Button>
        <Button
          buttonType={ButtonType.PRIMARY}
          onClick={() => onSubmit(file, fileName)}
          disabled={loading || !file}
          dataTest={`${dataTest}-button-primary`}
        >
          {t('Create')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default LandingPagesUploadModal
