import { gql } from '@apollo/client'

export default gql`
  fragment AdditionalEntity on ProgramAdditionalEntity {
    entityType
    entityRefKey
    entitySingular
    requiredFields
    fields {
      key
      value
    }
  }

  fragment Operations on ProgramSetFieldOperation {
    rule
    value
    fieldName
    campaignId
    scoreSheetId
  }

  fragment Schedule on ProgramSchedule {
    nextTS
    nextTime
    type
    hour
    minute
    ampm
    interval
    timestamp
    waitUntilDate
    waitUntilDateFormatted
    triggerAllowed
    isScheduled
    isUnscheduled
    isTriggered
    weekdaysOnly
    hasEndTime
    endHour
    endMinute
    endAmpm
  }

  fragment Steps on ProgramStep {
    stepType
    stepId
    displayName
    letter
    track
    depth
    ... on ProgramWaitStep {
      msecDelay
      delay
      delayUnit
    }
    ... on ProgramWaitUntilStep {
      schedule {
        ...Schedule
      }
    }
    ... on ProgramWaitUntilInSegmentStep {
      srcId
      not
      schedule {
        ...Schedule
      }
      waitForScheduledRun
    }
    ... on ProgramSendStep {
      defaultMsgId
      sendMode
      abThreshold
      sendChoices {
        msgId
        srcId
        not
        clicks
        sends
        opens
        optOut
      }
      clicks
      abWinner
    }
    ... on ProgramCopyStep {
      all
      not
      srcId
      listId
      update
      updateType
      pushToSf
      overrideSystemMap
      overrideSystemMapField
    }
    ... on ProgramCopyFromStep {
      srcId
      pruneRecords
    }
    ... on ProgramAppendToSegmentStep {
      all
      not
      srcId
      srcName
      listId
      listName
      pruneRecords
    }
    ... on ProgramWebExSyncStep {
      meetingId
      sessionKey
      serviceType
    }
    ... on ProgramCitrixSyncStep {
      meetingId
      serviceType
      webinarKey
    }
    ... on ProgramRecordSetStep {
      listId
      canPushSF
      pushToSf
      conditions {
        srcId
        not
        operations {
          ...Operations
        }
      }
      operations {
        ...Operations
      }
      rule
      fieldName
      value
    }
    ... on ProgramGoToSetStep {
      goToStepId
    }
    ... on ProgramBranchStep {
      condition
      srcId
      testSrcId
      not
      goToStepId
    }
    ... on ProgramAlertStep {
      message
      subject
      userIds
      emails
      crmOwner
      scoreSheetId
      hideContactData
      campaignId
    }
    ... on ProgramTaskStep {
      method
      ownerId
      ownerName
      subject
      comment
      status
      priority
      dateUnit
      dateAmount
      disabled
    }
    ... on ProgramCrmCreateStep {
      disabled
      pushNewRecords
      pushType
      updateExisting
      useListPushPolicy
      createType
      additionalEntities {
        ...AdditionalEntity
      }
      staticFields {
        key
        value
      }
      updateMultipleMatches
      insertAlways
      canUpdateExistingContacts
      updateOnlyBlankFields
    }

    ... on ProgramSForceCampaignStep {
      method
      dynamicCampaign
      status
      campaignName
      campaignId
      newCampaignStatus
      disabled
    }

    ... on ProgramWebRequestStep {
      method
      format
      headers
      custom {
        key
        value
      }
      httpHeaders {
        key
        value
      }
      url
    }

    ... on ProgramSmsSendStep {
      smsMessageId
    }

    ... on ProgramOutgoingWebhookStep {
      headers
      endpoints
    }
    ... on ProgramUpdateSubscriptionStep {
      subscribeCategories
      unsubscribeCategories
    }
  }
  query Program($id: String!) {
    program(id: $id) {
      isTemp
      id
      name
      description
      type
      sforceNote
      allowLooping
      suppressBadEmails
      allowReentrantAddresses
      useAlternateUniqueField
      allowBypassFatigueRules
      alternateUniqueFieldHeader
      historyDisabled
      historyDisabledStopped
      sourceList {
        id
        baseId
        name
        headers
        count
      }
      sources {
        id
        baseId
        name
        count
        headers
      }
      messages {
        id
        name
        isNewComposer
        beeComposer
      }
      conditionalMessages {
        id
        name
        isNewComposer
      }
      exit {
        stepId
        stepType
        exitChoices {
          displayName
          stepType
          srcId
          not
          listId
          update
          score
        }
      }
      firstTrackId
      tracks {
        id
        name
        letter
        steps {
          ...Steps
        }
        stepId
        stepType
        displayName
      }
      runStatus {
        valid
        isRunning
      }
      schedule {
        nextTS
        nextTime
        type
        hour
        minute
        ampm
        interval
        timestamp
        waitUntilDate
        waitUntilDateFormatted
        triggerAllowed
        isScheduled
        isUnscheduled
        isTriggered
        weekdaysOnly
        hasEndTime
        endHour
        endMinute
        endAmpm
      }
      timeZoneId
      timeZoneNames {
        id
        name
      }
      status
      stepTemplates {
        ...Steps
      }
      isReportingTooLarge
      crm {
        crmName
        crmListPrefix
        crmImageSmall
        crmImageLarge
        taskStepEnabled
        salesforceCampaignStepEnabled
        campaignDisplayName
        createStepEnabled
        crmType
        canCustomizeCampaignStatus
        canCreateCampaign
        canSyncProgramList
        isDynamicCampaign
        crmModelEnabled
        crmModelNotCurrent
        crmCampaignsAreLimited
        modelEntityLimit
        defaultTypes
        crmEnabled
      }
      users {
        id
        email
        firstName
        lastName
      }
      localSegmentDetails {
        id
        count
        countlimit
        name
        queryId
        any
        dateSelector
        idset
        whats
        profileFilters {
          header
          op
          value
          format
        }
        behaviorFilters {
          idSet
          whats
          isScore
        }
      }
      isNew
      lastUpdated
      lastEditedBy
      startedOn
      createdOn
      creator
      lastRowId
      hasActOnContactList
    }
  }
`
