import React, { FC, MutableRefObject, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { Delimiter, FirstLineOption, MergeStrategy, Quote, UpsertContactSetting } from '@complex/ImportContacts/ImportContacts.constants'
import ImportOptionsScreen, { ImportOptionsScreenProps } from '@complex/ImportContacts/ImportOptionsScreen/ImportOptionsScreen'
import ImportLog from '@complex/ImportLog/ImportLog'
import Button, { ButtonType } from '@components/Button'
import { ButtonWeight } from '@components/Button/Button'
import Caution from '@components/Caution/Caution'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import Container from '@components/Container'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import DropDown from '@components/DropDown'
import { DropDownType } from '@components/DropDown/DropDown'
import DropDownActions from '@components/DropDownActions/DropDownActions'
import Loader from '@components/Loader'
import PageContainer from '@components/PageContainer'
import PageHeader from '@components/PageHeader'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import ProgressBar, { ProgressBarStep } from '@components/ProgressBar/ProgressBar'
import SelectFtpFileWithSearchModal from '@components/SelectFtpFileWithSearchModal/SelectFtpFileWithSearchModal'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import StatusToast, { Status } from '@components/StatusToast/StatusToast'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { rootContext, useTranslation } from '@const/globals'
import { FtpFileDto } from '@graphql/types/microservice/entity-upload-types'
import { ProgramSchedule } from '@graphql/types/query-types'
import { IMPORT_SOURCE } from '@src/pages/ContactSegments/utils/ContactSegments.constants'
import MappingScreenContainer from '@src/pages/importcontacts/components/MappingScreen/MappingScreenContainer'
import { defaultMappingPreviewField, MappingPreview } from '@src/pages/importcontacts/context/ImportContactsContext'
import ImportSuccess from '@src/pages/ImportContactsV2/components/ImportSuccess/ImportSuccess'
import ReviewImport from '@src/pages/ImportContactsV2/components/ReviewImport/ReviewImport'
import SelectedFile from '@src/pages/ImportContactsV2/components/SelectedFile/SelectedFile'
import { useImportContactsV2Requests } from '@src/pages/ImportContactsV2/GraphQL/ImportContactsV2Request.graphQL'
import {
  ColumnMergeType,
  DataType,
  EXTERNAL_ID_FIELD_KEY,
  HEADER_TEXT,
  ImportContactsSources,
  ImportContactsSteps,
} from '@src/pages/ImportContactsV2/utils/ImportContactsV2.constants'
import { ImportContactsV2ContainerState, ImportContactsV2Context } from '@src/pages/ImportContactsV2/utils/ImportContactsV2.context'
import { getFilteredFTPFiles, renderFileSelection, renderImportFileInfoCards } from '@src/pages/ImportContactsV2/utils/ImportContactsV2.utils'
import { useAccountSettings } from '@utils/account/account.utils'

import './ImportContactsV2.css'

interface ImportContactsV2Props {
  clearSession: VoidFunction
  currentStep: ProgressBarStep
  dataTest?: string
  getDataFromSession: (partialValues?: Partial<ImportContactsV2ContainerState>) => void
  jobUrlId?: string
  hasToMatchFieldsWithHeaders: MutableRefObject<boolean>
  type: ImportContactsSources
}

const rootClass = 'import-contacts-v2'

const ImportContactsV2: FC<ImportContactsV2Props> = (props: ImportContactsV2Props) => {
  const { clearSession, currentStep, dataTest = rootClass, getDataFromSession, jobUrlId, hasToMatchFieldsWithHeaders, type } = props

  const {
    values: {
      addToSegment,
      segmentSelectedOption,
      validSegmentName,
      segmentOptions,
      objectType,
      externalIdMatch,
      contactPreference,
      fieldQuotes,
      fieldsSeparator,
      mergeRule,
      rejectRules,
      updateAction,
      useCustomPrefix,
      removeEntriesFromList,
      unifiedListFieldMappings = [],
      showAdvancedOptions,
      firstLineContains,
      isDeletingFile,
      isSelectingFile,
      indexRef,
      inputOnBlurTriggered,
      loading,
      mappingPreview,
      mergeStrategy,
      previewHeader,
      previewIndex,
      previewRecords,
      statusToast: { statusMessage, showStatusToast, status },
      validateAllTrigger,
      validationTrigger,
      visibleFields,
      schedule,
      selectedFile,
      steps,
      showExternalIdMatchError,
      showObjectTypeError,
      ftpFiles,
      loadingFtpFiles,
      loadingMappingScreen,
      importDefinitionId,
      importSyncJob,
    },
    isAValidSegmentName,
    createSegment,
    getSegmentOptions,
    handleOnSectionChange,
    closeStatusToast,
    validateColumn,
    update,
  } = useContext(ImportContactsV2Context)

  const [showImportLog, setShowImportLog] = useState(false)
  const [deleteDropdownOpen, setDeleteDropdownOpen] = useState(false)
  const [deletingSyncSettings, setDeletingSyncSettings] = useState(false)
  const [hasTemporalImportData, setHasTemporalImportData] = useState<boolean>(() => !!sessionStorage.getItem(`importContacts${type}`) && !jobUrlId)

  const { getFtpFilesRequest, deleteSyncSettingsRequest } = useImportContactsV2Requests({
    selectedFile,
    importDefinitionId,
    importSyncJob,
    schedule,
  })

  const { t } = useTranslation()
  const { userAllowedToCreate } = useAccountSettings()

  const temporalImportFileName = useMemo(() => {
    const temporalStateJsonString = sessionStorage.getItem(`importContacts${type}`)
    if (temporalStateJsonString) {
      const temporalState = JSON.parse(temporalStateJsonString) as ImportContactsV2ContainerState
      return temporalState.selectedFile?.name
    }
  }, [type])

  const showEmailMappingWarning = useMemo(() => {
    return mergeStrategy === MergeStrategy.Email && !mappingPreview.some((mapping) => mapping.dataType === 'EMAIL')
  }, [mergeStrategy, mappingPreview])

  const hasUnsavedImportChanges = useMemo<boolean>(() => {
    const visibleSteps = steps.filter(({ hidden }) => !hidden)
    const hasChanges = visibleSteps.some(({ isCompleted }) => isCompleted)
    const allStepsCompleted = visibleSteps.every(({ isCompleted }) => isCompleted)
    return !!importSyncJob || (hasChanges && !allStepsCompleted)
  }, [importSyncJob, steps])

  const onShowImportLogClick = useCallback(() => setShowImportLog(true), [])

  const onSelectFile = (selectedFile?: FtpFileDto) => {
    update({ selectedFile, isSelectingFile: false, externalIdMatch: null })
  }

  const onChangeWithFirstRowsUpdate = (field: string, value: FirstLineOption | Delimiter | Quote) => {
    update({ ...{ [field]: value }, hasToUpdateFirstRows: true, externalIdMatch: null, previewHeader: [] })
  }

  const onMergeRuleChange = (mergeRule: ColumnMergeType) => {
    update({
      mergeRule,
      ...(mappingPreview.length > 0 ? { mappingPreview: mappingPreview.map((mapping) => ({ ...mapping, merge: mergeRule })) } : {}),
    })
  }

  const onUpdateActionChange = (updateAction: UpsertContactSetting) => update({ updateAction })
  const onContactPreferenceChange = (option: SelectV2SingleOption | undefined) => update({ contactPreference: option })

  const onRemoveEntriesChange = (removeEntriesFromList: boolean) => update({ removeEntriesFromList })

  const onRejectRulesChange = (rule: string, checked: boolean) => update({ rejectRules: { ...rejectRules, [rule]: checked } })

  const onUseCustomPrefixChange = (dontUseCustomPrefix: boolean) => update({ useCustomPrefix: !dontUseCustomPrefix })

  const onObjectTypeChange = (objectType: string, showObjectTypeError: boolean) => update({ objectType, showObjectTypeError })

  const onSchedule = (schedule: ProgramSchedule) => update({ schedule })

  const externalIdField = useMemo(
    () => unifiedListFieldMappings.find(({ standardFieldKey }) => standardFieldKey === EXTERNAL_ID_FIELD_KEY),
    [unifiedListFieldMappings]
  )

  const onAddToSegmentChange = (checked: boolean) => {
    update({ addToSegment: checked, segmentSelectedOption: undefined })
  }

  const onSegmentSelectionChange = useCallback(
    (newSelectedOption?: SelectV2SingleOption) => {
      if (newSelectedOption) {
        update({ validSegmentName: true, segmentSelectedOption: newSelectedOption })
      } else {
        update({ segmentSelectedOption: undefined })
      }
    },
    [update]
  )

  const onNextClick = () => {
    const showObjectTypeError = mergeStrategy === MergeStrategy.ExternalId && !objectType
    const showExternalIdMatchError = mergeStrategy === MergeStrategy.ExternalId && !externalIdMatch
    if (showObjectTypeError || showExternalIdMatchError) {
      update({ showObjectTypeError, showExternalIdMatchError })
    } else {
      handleOnSectionChange(ImportContactsSteps.MAP_FIELDS, true)
    }
  }

  const onShowAdvancedOptionsChange = (showAdvancedOptions: boolean) => update({ showAdvancedOptions })

  const onMergeStrategyChange = (newMergeStrategy: MergeStrategy, newExternalIdMatch = externalIdMatch) => {
    const isUpdatingExternalIdField = newExternalIdMatch?.value !== externalIdMatch?.value
    let updatedMappingPreview = mappingPreview
    if (newMergeStrategy === MergeStrategy.ExternalId && isUpdatingExternalIdField && newExternalIdMatch && externalIdField) {
      updatedMappingPreview = mappingPreview.map<MappingPreview>((mapping, index) => {
        if (externalIdMatch && index === parseInt(externalIdMatch.extraOptions?.csvPosition ?? '-1')) {
          return {
            ...defaultMappingPreviewField,
            index,
          }
        } else if (index === parseInt(newExternalIdMatch.extraOptions?.csvPosition ?? '-1')) {
          return {
            ...defaultMappingPreviewField,
            csvColumn: newExternalIdMatch.value,
            dataType: DataType.Text,
            displayName: externalIdField.displayName ?? '',
            friendlyName: externalIdField.displayName ?? '',
            index,
            isCustom: false,
            mapping: EXTERNAL_ID_FIELD_KEY,
            mappingIndex: externalIdField.columnIndex,
          }
        }
        return mapping
      })
    }
    update({
      externalIdMatch: newExternalIdMatch,
      mappingPreview: updatedMappingPreview,
      mergeStrategy: newMergeStrategy,
      ...(isUpdatingExternalIdField && newExternalIdMatch?.extraOptions?.csvPosition
        ? {
            indexRef: parseInt(newExternalIdMatch.extraOptions.csvPosition),
            validationTrigger: !validationTrigger,
          }
        : {}),
      ...(newMergeStrategy === MergeStrategy.Email ? { showObjectTypeError: false } : { showExternalIdMatchError: !newExternalIdMatch }),
    })
  }

  const previewHeadersOptions = useMemo(
    () =>
      previewHeader.map<SelectV2SingleOption>((header, index) => ({
        label: header,
        value: header,
        extraOptions: {
          csvPosition: index.toString(),
        },
      })),
    [previewHeader]
  )

  useEffect(() => {
    if (previewHeadersOptions.length > 0 && !externalIdMatch && mergeStrategy === MergeStrategy.ExternalId) {
      update({ externalIdMatch: previewHeadersOptions[0] })
    }
  }, [previewHeadersOptions])

  const importOptionsProps: ImportOptionsScreenProps = {
    schedule,
    isFileSelected: !!selectedFile,
    addToSegment,
    segmentSelectedOption,
    validSegmentName,
    showObjectTypeError,
    showExternalIdMatchError,
    isAValidSegmentName,
    createSegment,
    segmentOptions,
    getSegmentOptions,
    mergeStrategy,
    objectType,
    externalIdMatch,
    loadingMappingScreen,
    contactPreference,
    fieldQuotes,
    firstLineContains,
    previewHeadersOptions,
    fieldsSeparator,
    mergeRule,
    rejectRules,
    updateAction,
    useCustomPrefix,
    removeEntriesFromList,
    showAdvancedOptions,
    onChangeWithFirstRowsUpdate,
    onMergeRuleChange,
    onMergeStrategyChange,
    onContactPreferenceChange,
    onUpdateActionChange,
    onRemoveEntriesChange,
    onRejectRulesChange,
    onUseCustomPrefixChange,
    onObjectTypeChange,
    onSegmentSelectionChange,
    onSchedule,
    onNextClick,
    onAddToSegmentChange,
    onShowAdvancedOptionsChange,
  }

  useEffect(() => {
    if (indexRef >= 0 && mappingPreview.length !== 0) {
      validateColumn(indexRef)
    }
  }, [validationTrigger])

  useEffect(() => {
    if (isSelectingFile) {
      getFtpFilesRequest().then((data) => update({ ftpFiles: getFilteredFTPFiles(data, ['.csv', '.gpg', '.pgp']), loadingFtpFiles: false }))
    }
  }, [isSelectingFile])

  useEffect(() => {
    if (showExternalIdMatchError && externalIdMatch) {
      update({ showExternalIdMatchError: false })
    }
  }, [externalIdMatch])

  return (
    <PageContainer className={rootClass} dataTest={dataTest}>
      {showImportLog && (
        <ImportLog isOpen={showImportLog} onClose={() => setShowImportLog(false)} hasUnsavedImportChanges={hasUnsavedImportChanges} />
      )}
      {showStatusToast && <StatusToast status={status} message={statusMessage} closeStatus={closeStatusToast} />}
      {isDeletingFile && (
        <DeleteConfirmationModal
          isOpen
          title={t('ImportContactsV2.DeleteFileModal.Title')}
          deleteButtonText={'Remove'}
          body={t('ImportContactsV2.DeleteFileModal.Body')}
          onAnswer={(answer) => {
            if (answer === YesNo.YES) {
              onSelectFile()
            }
            update({ isDeletingFile: false })
          }}
        />
      )}
      {isSelectingFile && (
        <SelectFtpFileWithSearchModal
          isOpen
          onClose={() => update({ isSelectingFile: false })}
          onAction={onSelectFile}
          loading={loadingFtpFiles}
          headerText={t('SelectFtpFileWithSearchModal.Header')}
          files={ftpFiles}
        />
      )}
      {deletingSyncSettings && (
        <DeleteConfirmationModal
          isOpen
          title={t('Are you sure?')}
          deleteButtonText={t('Delete')}
          body={t('ImportContactsV2.DeleteSync.Body')}
          onAnswer={(answer) => {
            setDeletingSyncSettings(false)
            if (answer === YesNo.YES) {
              update({ loading: true })
              deleteSyncSettingsRequest().then((result) => {
                if (result) {
                  window.open(`${rootContext}/importContactsV2/${IMPORT_SOURCE.FTP}`, '_self')
                } else {
                  update({
                    statusToast: {
                      statusMessage: t('Something went wrong on our end. Please try again.'),
                      status: Status.FAIL,
                      showStatusToast: true,
                    },
                    loading: false,
                  })
                }
              })
            }
          }}
        />
      )}
      <ConfirmationModal
        body={t('Recover draft import setup for {{fileName}}?', { fileName: temporalImportFileName })}
        yesButtonText={'Recover'}
        noButtonText={'Start a new import'}
        isYesNo
        isOpen={hasTemporalImportData}
        onAnswer={(answer) => {
          if (answer === YesNo.YES) {
            getDataFromSession({ isUpdatingFromSession: true })
            hasToMatchFieldsWithHeaders.current = false
          }
          setHasTemporalImportData(false)
          clearSession()
        }}
      />
      <PositionContainer>
        <PageHeader className={`${rootClass}__header`} primaryText={t(HEADER_TEXT[type])} leftContent>
          <Button
            className={`${rootClass}__import-log-button`}
            buttonType={ButtonType.TEXT_TEAL}
            weight={ButtonWeight.MEDIUM}
            onClick={onShowImportLogClick}
          >
            <Svg name={SvgNames.resources} type={SvgType.LARGER_ICON} />
            {t('View Import Log')}
          </Button>
          {importSyncJob && (
            <DropDown
              isOpen={deleteDropdownOpen}
              toggleOpen={() => setDeleteDropdownOpen(!deleteDropdownOpen)}
              type={DropDownType.STYLED}
              hasOverflowIcon
              className={`${rootClass}__dropdown-top`}
            >
              <DropDownActions
                menuItems={[
                  {
                    text: t('Delete sync settings'),
                    icon: SvgNames.delete,
                    onClick: () => setDeletingSyncSettings(true),
                  },
                ]}
                closeDropDown={() => setDeleteDropdownOpen(!deleteDropdownOpen)}
              />
            </DropDown>
          )}
        </PageHeader>
        {loading ? (
          <Loader center />
        ) : (
          <>
            <ProgressBar
              className={`${rootClass}__progress-bar`}
              steps={steps}
              onClick={(step) => handleOnSectionChange(step.key)}
              isDisabled={showObjectTypeError || showExternalIdMatchError}
            />
            {currentStep?.key === ImportContactsSteps.SELECT_FILE && (
              <>
                <Container>
                  {renderImportFileInfoCards(t)}
                  {!selectedFile ? (
                    renderFileSelection(t, update, userAllowedToCreate)
                  ) : (
                    <SelectedFile
                      className={`${rootClass}__selected-file`}
                      selectedFile={selectedFile}
                      onDelete={() => update({ isDeletingFile: true })}
                    />
                  )}
                </Container>
                {selectedFile && (
                  <div className={`${rootClass}__button-wrapper`}>
                    <Button buttonType={ButtonType.PRIMARY} onClick={() => handleOnSectionChange(ImportContactsSteps.IMPORT_OPTIONS, true)}>
                      {t('Next')}
                    </Button>
                  </div>
                )}
              </>
            )}
            {currentStep?.key === ImportContactsSteps.IMPORT_OPTIONS && <ImportOptionsScreen {...importOptionsProps} />}
            {currentStep?.key === ImportContactsSteps.MAP_FIELDS && (
              <>
                {loadingMappingScreen ? (
                  <Loader center />
                ) : (
                  <>
                    {showEmailMappingWarning && <Caution message={t('You have not mapped an email field yet.')} />}
                    <MappingScreenContainer
                      hasHeaders={firstLineContains === FirstLineOption.NAMES}
                      indexRef={indexRef}
                      inputOnBlurTriggered={inputOnBlurTriggered}
                      listName={selectedFile?.name ?? ''}
                      mappingPreview={mappingPreview}
                      previewHeader={previewHeader}
                      previewIndex={previewIndex}
                      previewRecords={previewRecords}
                      shouldChangeUrlPath={false}
                      shouldMatchFieldsWithHeaders={hasToMatchFieldsWithHeaders.current}
                      update={update}
                      validateAllTrigger={validateAllTrigger}
                      validationTrigger={validationTrigger}
                      visibleFields={visibleFields}
                      mergeStrategy={mergeStrategy}
                    />
                  </>
                )}
                <div className={`${rootClass}__button-wrapper`}>
                  <Button
                    disabled={!selectedFile || showEmailMappingWarning}
                    buttonType={ButtonType.PRIMARY}
                    onClick={() => {
                      if (selectedFile && !showEmailMappingWarning) {
                        handleOnSectionChange(ImportContactsSteps.REVIEW, true)
                      }
                    }}
                  >
                    {t('Next')}
                  </Button>
                </div>
              </>
            )}
            {currentStep?.key === ImportContactsSteps.REVIEW && <ReviewImport />}
            {currentStep?.key === ImportContactsSteps.IMPORT_IN_PROGRESS && <ImportSuccess />}
          </>
        )}
      </PositionContainer>
    </PageContainer>
  )
}

export default ImportContactsV2
