import React, { FC, useCallback, useContext, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Container from '@components/Container'
import StaticImage from '@components/StaticImage/StaticImage'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Svg, { SvgNames } from '@components/Svg'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { renderUpdatedSettingsToast } from '@src/pages/RecommendedSegments/components/SegmentsInsightsSettingsContainer/utils/SegmentsInsightsSettingsContainer.utils'
import SegmentsInsightsSettingsModal from '@src/pages/RecommendedSegments/components/SegmentsInsightsSettingsModal/SegmentsInsightsSettingsModal'
import { AI_AUDIENCE_HEADER_LINK } from '@src/pages/RecommendedSegments/utils/RecommendedSegments.constants'
import { RecommendedSegmentsContext } from '@src/pages/RecommendedSegments/utils/RecommendedSegments.context'

import './SegmentsInsightsSettingsContainer.css'

interface SegmentsInsightsSettingsContainerProps {
  className?: string
  dataTest?: string
}

const rootClass = 'segments-insights-settings-container'
export const rootTranslation = 'SegmentsInsightsSettings'

const SegmentsInsightsSettingsContainer: FC<SegmentsInsightsSettingsContainerProps> = (props: SegmentsInsightsSettingsContainerProps) => {
  const { dataTest = rootClass, className = '' } = props

  const {
    resetSettings,
    update,
    values: { hasUpdatedSettings },
  } = useContext(RecommendedSegmentsContext)

  const [showSettings, setShowSettings] = useState(false)

  const { t } = useTranslation()

  const switchShowSettings = useCallback(() => {
    setShowSettings(!showSettings)
    if (hasUpdatedSettings) {
      update({
        hasUpdatedSettings: false,
        status: renderUpdatedSettingsToast(),
      })
    }
  }, [showSettings, hasUpdatedSettings])

  return (
    <>
      <SegmentsInsightsSettingsModal onClose={switchShowSettings} isOpen={showSettings} />
      <Container className={classNames(rootClass, className)} dataTest={dataTest}>
        <div className={`${rootClass}__content`}>
          <div>
            <Typography text={t(`${rootTranslation}.Container.Header`)} type={TextType.PAGE_HEADER} />
            <Typography
              text={t(`${rootTranslation}.Container.Content`)}
              tagComponents={{ TextLink: <TextLink size={TextLinkSize.LARGE} hideIcon link={AI_AUDIENCE_HEADER_LINK} /> }}
              type={TextType.BODY_TEXT}
            />
          </div>
          <div className={`${rootClass}__button-container`}>
            <Button buttonType={ButtonType.PRIMARY} onClick={switchShowSettings}>
              <Svg name={SvgNames.settingsThreeLines} />
              {t(`${rootTranslation}.Container.Customize`)}
            </Button>
            <Button buttonType={ButtonType.TEXT} onClick={resetSettings}>
              {t(`${rootTranslation}.Container.Reset`)}
            </Button>
          </div>
        </div>
        <StaticImage className={`${rootClass}__image`} name={StaticImageNames.mlSegmentation} />
      </Container>
    </>
  )
}

export default SegmentsInsightsSettingsContainer
