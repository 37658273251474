import React, { FC, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import DropDown from '@components/DropDown'
import { DropDownType } from '@components/DropDown/DropDown'
import DropDownActions, { DropDownActionsType, MenuItem } from '@components/DropDownActions/DropDownActions'
import Svg, { SvgNames } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { ModalHeaderFormStyle, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { LogoItem } from '@graphql/types/query-types'

import './logoModalsHeader.css'

interface Props {
  logo?: LogoItem | undefined
  actions?: (logo: LogoItem) => MenuItem[]
  isDuplicate: boolean | undefined
  dataTest?: string
  isPreview?: boolean
}

const rootClass = 'logo-modals-header'

const LogoModalsHeader: FC<Props> = (props: Props) => {
  const { logo, actions, isDuplicate, dataTest = rootClass, isPreview } = props
  const { t } = useTranslation()
  const [showDropdown, setShowDropdown] = useState<boolean>(false)

  const getActionsLabel = (
    <Button
      iconPosition={ButtonIconPosition.RIGHT}
      noPadding
      buttonType={ButtonType.FLOAT}
      dataTest={`${dataTest}-actions-button`}
      className={`${rootClass}__actions-btn`}
    >
      {t('Actions')}
      <Svg name={SvgNames.caretFillDown} />
    </Button>
  )

  return (
    <div className={rootClass}>
      <div className={`${rootClass}__header`}>
        <Tooltip
          align="start"
          arrowOffset={10}
          trigger={
            <Typography
              className={classNames(`${rootClass}--title`, {
                [`${rootClass}--title-preview`]: isPreview,
              })}
              text={t(logo ? (isDuplicate ? `${logo.title} (copy)` : logo.title) : 'Add logo')}
              {...ModalHeaderFormStyle}
            />
          }
        >
          {t(logo ? (isDuplicate ? `${logo.title} (copy)` : logo.title) : 'Add logo')}
        </Tooltip>
        {(logo?.isDefault || isDuplicate) && (
          <Typography
            className={`${rootClass}--default`}
            type={TextType.MODAL_HEADER_SUB_INFO}
            text={`(${isDuplicate ? t('copy') : t('default')})`}
          />
        )}
      </div>
      <div>
        {logo && actions && (
          <DropDown isOpen={showDropdown} toggleOpen={(open: boolean) => setShowDropdown(open)} type={DropDownType.STYLED} trigger={getActionsLabel}>
            <DropDownActions menuItems={actions(logo)} type={DropDownActionsType.REGULAR} closeDropDown={() => setShowDropdown(!showDropdown)} />
          </DropDown>
        )}
      </div>
    </div>
  )
}

export default LogoModalsHeader
