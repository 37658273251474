import React, { FC } from 'react'

import { LinkTextButton } from '@components/LinkTextButton/LinkTextButton'
import SelectV2MenuFooterButtons, {
  SelectV2MenuFooterButtonsProps,
} from '@components/SelectV2/components/SelectV2MenuFooterButtons/SelectV2MenuFooterButtons'
import SelectV2MenuFooterToggle, {
  SelectV2MenuFooterToggleProps,
} from '@components/SelectV2/components/SelectV2MenuFooterToggle/SelectV2MenuFooterToggle'

import './SelectV2MenuFooter.css'

interface SelectV2MenuFooterLinkProps {
  label: string
  url: string
}

export interface SelectV2MenuFooterProps {
  buttons?: SelectV2MenuFooterButtonsProps
  toggle?: SelectV2MenuFooterToggleProps
  link?: SelectV2MenuFooterLinkProps
}

const rootClass = 'select-v2-menu-footer'

const SelectV2MenuFooter: FC<SelectV2MenuFooterProps> = (props: SelectV2MenuFooterProps) => {
  const { buttons, toggle, link } = props

  let component = null

  if (buttons) {
    component = <SelectV2MenuFooterButtons {...buttons} dataTest={`${rootClass}-button${buttons.cancelOnClick ? 's' : ''}`} />
  } else if (toggle) {
    component = <SelectV2MenuFooterToggle {...toggle} dataTest={`${rootClass}-toggle`} />
  } else if (link) {
    component = <LinkTextButton text={link.label} link={link.url} hideIconLeft dataTest={`${rootClass}-link`} className={`${rootClass}__link`} />
  }

  return component ? <div className={rootClass}>{component}</div> : null
}

export default SelectV2MenuFooter
