import { FC, useEffect } from 'react'

import { isDev, isProd, isStaging } from '@const/globals'
import { useAccountSettings } from '@utils/account/account.utils'
import windowUtils from '@utils/window'

// declare const apptrinsic: any

// @ts-ignore
const GainsightPX: FC = () => {
  const accountSettings = useAccountSettings()
  const { pendoCode, externalId } = accountSettings

  useEffect(() => {
    const envKey = isProd() ? '' : isStaging() ? '-2' : '-4'

    ;(function (n, t, a, e, co) {
      const i = 'aptrinsic' // @ts-ignore // @ts-ignore
      // @ts-ignore
      ;(n[i] =
        // @ts-ignore
        n[i] ||
        function () {
          // @ts-ignore
          // eslint-disable-next-line prefer-rest-params
          ;(n[i].q = n[i].q || []).push(arguments)
        }),
        // @ts-ignore
        (n[i].p = e)
      // @ts-ignore
      n[i].c = co
      const r = t.createElement('script')
      ;(r.async = !0), (r.src = a + '?a=' + e)
      const c = t.getElementsByTagName('script')[0]
      // @ts-ignore
      c.parentNode.insertBefore(r, c)
    })(window, document, 'https://web-sdk.aptrinsic.com/api/aptrinsic.js', `AP-OS0EIGRSXSCV-2${envKey}`)
  }, [])

  useEffect(() => {
    const initialize = () => {
      const userRole = accountSettings.userSettings.isAdministrator
        ? 'Admin User'
        : accountSettings.userSettings.userDesktop
        ? 'Sales User'
        : 'Marketing User'

      let crmEnabled = 'none'
      if (accountSettings.isSF) {
        crmEnabled = 'SalesForce'
      } else if (accountSettings.isMSDyn) {
        crmEnabled = 'Microsoft Dynamics'
      } else if (accountSettings.isSugar) {
        crmEnabled = 'Sugar'
      } else if (accountSettings.isNS) {
        crmEnabled = 'NetSuite'
      } else if (accountSettings.isSL) {
        crmEnabled = 'Infor'
      }

      const options = {
        user: {
          id: accountSettings.userId,
          email: accountSettings.userEmail,
          name: accountSettings.userName,
          role: userRole,
          timezone: accountSettings.timeZoneId,
          canPublish: accountSettings.canPublish,
          canShare: accountSettings.canShareAsset,
          canSubscribe: accountSettings.canSubscribe,
          canCreate: accountSettings.userAllowedToCreate,
          canDelete: accountSettings.userAllowedToDelete,
          canDownload: accountSettings.userAllowedToDownload,
          canDeleteContent: accountSettings.userAllowedToDeleteContent,
          canCreatePrograms: accountSettings.userAllowedToCreatePrograms,
        },
        account: {
          id: (isDev() ? 'tst_' : '') + accountSettings.accountId,
          name: accountSettings.accountName,
          planLevel: accountSettings.paymentType,
          domain: windowUtils.getHostName(),
          agency: accountSettings.isAgencyAccount,
          agencyClient: accountSettings.isClientAccount,
          accountTimezone: accountSettings.accountTimeZoneId,
          crm: crmEnabled,
          maxMarketingUsers: accountSettings.maxMarketingUsers,
          maxSalesUsers: accountSettings.maxSalesUsers,
          activeContacts: accountSettings.maxSends,
          creationDate: accountSettings.authorizedStartDate,
          ExternalId: externalId,
          parentGroupId: externalId,
        },
      }

      apptrinsic('identify', options.user, options.account)
    }

    // @ts-ignore
    const apptrinsic = window.aptrinsic
    if (pendoCode && typeof apptrinsic !== 'undefined') {
      initialize()
    }
  }, [pendoCode, accountSettings])

  return null
}

export default GainsightPX
