import { gql } from '@apollo/client'

export default gql`
  query getFormsByColumnName($offset: Int, $count: Int, $columnName: String) {
    getFormsByColumnName(offset: $offset, count: $count, columnName: $columnName) {
      id
      name
    }
  }
`
