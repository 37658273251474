import React, { useContext } from 'react'
import { IndicatorsContainerProps, components } from 'react-select'

import classNames from 'classnames'

import { selectRootClass } from '@components/SelectV2/SelectV2.utils'
import { css } from '@emotion/css'

import { SelectV2Context } from '../SelectV2.context'

const CustomIndicatorsContainer = (props: IndicatorsContainerProps<any>) => {
  const { getStyles, children } = props
  const {
    values: { isMulti, renderCustomIndicator },
  } = useContext(SelectV2Context)

  return (
    <components.IndicatorsContainer
      className={classNames(css(getStyles('indicatorsContainer', props)), `${selectRootClass}__indicators-container`, {
        [`${selectRootClass}__indicators-container--multi`]: isMulti,
        [`${selectRootClass}__indicators-container--hover`]: !!renderCustomIndicator,
      })}
      {...props}
    >
      {renderCustomIndicator && renderCustomIndicator()}
      {children}
    </components.IndicatorsContainer>
  )
}

export default CustomIndicatorsContainer
