import { gql } from '@apollo/client'

export default gql`
  query getRevenueImpactWonOpportunities($endDate: String, $timeZoneId: String, $startDate: String) {
    getRevenueImpactWonOpportunities(endDate: $endDate, timeZoneId: $timeZoneId, startDate: $startDate) {
      topIndustry {
        amount
        count
        industry
      }
      topRegion {
        amount
        count
        region
      }
      topSegment {
        amount
        count
        segment
      }
      total {
        amount
        count
        increase
      }
    }
  }
`
