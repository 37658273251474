import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import CaretIcon, { CaretIconDirection } from '@components/CaretIcon/CaretIcon'
import Checkbox from '@components/Checkbox/Checkbox'
import EmptyList from '@components/EmptyList/EmptyList'
import Loader from '@components/Loader/Loader'
import { getMessagePickerUtil, MessagePickerUtil } from '@components/MessagePicker/messagePickerUtil'
import PageError from '@components/PageError'
import Pill, { PillSize, PillType } from '@components/Pill/Pill'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { useTranslation } from '@const/globals'
import { MessageFolder } from '@interface/foldersLists/Folder'
import { Message } from '@interface/messages/Message'
import { formatDateWithAbbreviations } from '@utils/date'

import './messagePicker.css'

const rootClass = 'message-picker'

export enum CustomColumn {
  LAST_EDITED,
  SENT,
  SCHEDULED,
}

export interface MessagesState {
  messages?: Message[]
  loading?: boolean
  error?: boolean
}

export interface MessageFolderState {
  folders?: MessageFolder[]
  loading?: boolean
  error?: boolean
}

export interface State {
  collapsedFolders: string[]
  allSelected: boolean
}

export interface Props {
  messages?: MessagesState
  folders?: MessageFolderState
  customColumn?: CustomColumn
  dataTest?: string
  className?: string
  customScrollClass?: string
  selectedMessages: Message[]
  updateSelectedMessages(messages: Message[]): void
  multiSelect?: boolean
  messagePickerUtils?: MessagePickerUtil
  isProgramMessagePicker: boolean
  toggleMessagePreview(previewMessage?: Message): void
}

export function getCustomColumnTitle(t: Function, customColumn?: CustomColumn) {
  switch (customColumn) {
    case CustomColumn.LAST_EDITED:
      return t('LAST EDITED')
    case CustomColumn.SENT:
      return t('SENT')
    case CustomColumn.SCHEDULED:
      return t('WILL BE SENT ON')
  }
  return null
}

function isEmpty(messagePickerUtil: MessagePickerUtil) {
  const { isProgramMessagePicker, messages, folders } = messagePickerUtil.props()
  if (isProgramMessagePicker) {
    if (folders && folders.folders && folders.folders.length > 0) {
      return folders.folders.reduce((acc, folder) => acc + (folder.entries || []).length, 0) === 0
    }
    return true
  }
  return !(messages && messages.messages && messages.messages.length > 0)
}

export function getMessages(dataTest: string, messagePickerUtil: MessagePickerUtil, messages: Message[], t: Function): ReactNode[] {
  const { customColumn, selectedMessages, multiSelect = false, toggleMessagePreview } = messagePickerUtil.props()

  return messages.map((message, idx) => {
    let value: number | undefined
    switch (customColumn) {
      case CustomColumn.LAST_EDITED:
        value = message.modified
        break
      case CustomColumn.SENT:
      case CustomColumn.SCHEDULED:
        value = message.scheduled
        break
    }

    const isSelected = selectedMessages.map((sMsg) => sMsg.id).includes(message.id)

    return (
      <div
        key={`${message.id}-${idx}`}
        className={classNames(`${rootClass}__row`, {
          [`${rootClass}__col--has-custom`]: value,
        })}
      >
        <div className={`${rootClass}__col`}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label
            className={classNames({
              [`${rootClass}__col-hide`]: !multiSelect,
            })}
          >
            <Checkbox
              title={t('Select Message')}
              data-test={`${dataTest}-message-row-${message.id}`}
              checked={isSelected}
              onChange={() => messagePickerUtil.handleListChanged(message, !isSelected)}
            />
          </label>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
          <label
            className={classNames(`${rootClass}__icon-holder`, {
              [`${rootClass}__col-hide`]: multiSelect,
            })}
            onClick={() => messagePickerUtil.handleListChanged(message, !isSelected)}
          >
            {!isSelected && <Svg className={`${rootClass}__mail-icon`} name={SvgNames.letter} />}
            {isSelected && (
              <div className={`${rootClass}__checked-icon`}>
                <Svg name={SvgNames.check} />
                <Svg name={SvgNames.check} />
              </div>
            )}
          </label>
        </div>
        <div className={classNames(`${rootClass}__col`)}>
          <label className={`${rootClass}__message-title`} title={message.title ?? message.subject ?? undefined}>
            <span className={`${rootClass}__text`}>
              {message.title ?? message.subject}
              {!message.beeComposer && (
                <Pill text={t('Legacy')} type={PillType.GRAY} size={PillSize.EXTRA_SMALL} noMargin className={`${rootClass}__message-pill`} />
              )}
            </span>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div className={`${rootClass}__preview`} onClick={() => toggleMessagePreview(message)}>
              <Svg name={SvgNames.show} type={SvgType.ICON} />
            </div>
          </label>
        </div>
        {value && (
          <div className={`${rootClass}__col`}>
            <label title={formatDateWithAbbreviations(value, true)}>
              <span className={`${rootClass}__text`}>{formatDateWithAbbreviations(value)}</span>
            </label>
          </div>
        )}
      </div>
    )
  })
}

function getFolders(dataTest: string, messagePickerUtil: MessagePickerUtil, t: Function) {
  const state = messagePickerUtil.state()
  const props = messagePickerUtil.props()

  return props.folders?.folders?.map((folder) => {
    const folderCollapsed = folder.entries && folder.entries.length > 0 && state.collapsedFolders.includes(`${folder.id}`)

    return (
      <React.Fragment key={`${folder.id}`}>
        <div className={`${rootClass}__folder`}>
          <button
            data-test={`${dataTest}-folder-row-${folder.id}`}
            className={`${rootClass}__folder-row`}
            title={folderCollapsed ? t('Expand Row') : t('Collapse Row')}
            onClick={() => messagePickerUtil.handleCollapseFolder(folder.id, !folderCollapsed)}
          >
            <CaretIcon direction={folderCollapsed ? CaretIconDirection.RIGHT : CaretIconDirection.DOWN} />
            {folder.name}
          </button>
          {!folderCollapsed && folder.entries && getMessages(dataTest, messagePickerUtil, folder.entries, t)}
        </div>
      </React.Fragment>
    )
  })
}

function getBody(dataTest: string, t: Function, messagePickerUtil: MessagePickerUtil) {
  const { messages, folders, isProgramMessagePicker, customColumn, selectedMessages, updateSelectedMessages, multiSelect } = messagePickerUtil.props()
  if (messagePickerUtil.isError()) {
    return <PageError center />
  }

  if (messagePickerUtil.isLoading(isProgramMessagePicker)) {
    return <Loader center />
  }

  const customColumnTitle = getCustomColumnTitle(t, customColumn)

  const selectAllMessages = (checked: boolean) => {
    const isSelected = checked
    if (isSelected) {
      messages?.messages?.forEach((msg) => messagePickerUtil.handleListChanged(msg, isSelected))
    } else {
      updateSelectedMessages([])
    }
  }

  const selectedMessagesIds = selectedMessages.map((selMsg) => selMsg.id)
  const allSelected = messages?.messages?.reduce((acc: boolean, msg) => {
    if (!acc) {
      return false
    }
    return selectedMessagesIds.includes(msg.id)
  }, true)

  return (
    <>
      <div className={`${rootClass}__header`}>
        <div className={`${rootClass}__row`}>
          <div
            className={classNames(`${rootClass}__col`, {
              [`${rootClass}__col-hide`]: !multiSelect || !messages?.messages,
            })}
          >
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>
              <Checkbox checked={allSelected} title={t('Select All Messages')} onChange={selectAllMessages} />
            </label>
          </div>
          <div
            className={classNames(`${rootClass}__col`, {
              [`${rootClass}__col--folders`]: folders?.folders !== undefined,
            })}
          >
            <label>{t('MESSAGES')}</label>
          </div>
          {customColumnTitle && (
            <div className={`${rootClass}__col`}>
              <label>{customColumnTitle}</label>
            </div>
          )}
        </div>
      </div>
      {folders?.folders && getFolders(dataTest, messagePickerUtil, t)}
      {isEmpty(messagePickerUtil) && <EmptyList message={t('No messages in this folder')} isPicker />}
      {messages?.messages && getMessages(dataTest, messagePickerUtil, messages.messages, t)}
    </>
  )
}

const MessagePicker: FC<Props> = (props: Props) => {
  const { messagePickerUtils, customScrollClass, className, dataTest = 'message-picker' } = props

  const { t } = useTranslation()
  const [state, setState] = React.useState<State>({
    collapsedFolders: [],
    allSelected: false,
  })

  React.useEffect(() => {
    if (props.messages?.loading ?? props.folders?.loading) {
      setState({
        ...state,
        collapsedFolders: [],
      })
    }
  }, [props.messages, props.folders])

  const messagePickerUtil = messagePickerUtils ?? getMessagePickerUtil(props, state, setState)

  return (
    <div data-test={dataTest} className={classNames(rootClass, className, customScrollClass)}>
      {getBody(dataTest, t, messagePickerUtil)}
    </div>
  )
}

export default MessagePicker
