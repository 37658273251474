import { gql } from '@apollo/client'

export default gql`
  mutation deleteLandingPageTemplates($pageIds: [String!]!) {
    deleteLandingPageTemplates(pageIds: $pageIds) {
      status
      successIds {
        id
        name
      }
      failedIds {
        id
        name
        reason
      }
    }
  }
`
