import React, { FC, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import * as yup from 'yup'

import FormRow from '@components/FormRow/FormRow'
import Input from '@components/Input/Input'
import { ModalBody } from '@components/Modal'
import Radio from '@components/Radio/Radio'
import RadioGroup from '@components/RadioGroup/RadioGroup'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { Program, ProgramMessage, ProgramSource } from '@graphql/types/query-types'
import { yupResolver } from '@hookform/resolvers/yup'
import WaitStepDetail from '@src/pages/programs/dashboard/components/ProgramSteps/components/WaitStepDetail/WaitStepDetail'
import {
  buildWaitUntilInSegmentStepCondition,
  buildWaitUntilSpecificDateStepCondition,
} from '@src/pages/programs/dashboard/components/ProgramSteps/ProgramSteps.utils'
import {
  getIsListSegment,
  getNewSegment,
  getNewSegmentName,
  getSegmentAndNotValue,
  getUpdatedProgramMessages,
  getUpdatedProgramSources,
} from '@utils/program/program'
import { ProgramWaitStepExt, ProgramWaitUntilInSegmentStepExt, ProgramWaitUntilStepExt, Step, Track } from '@utils/program/program.constants'
import { ProgramWithStepData } from '@utils/program/ProgramSteps.constants'

import WaitStep from './waitStep'
import WaitUntilInSegmentStep from './waitUntilInSegmentStep'
import WaitUntilStep from './waitUntilStep'

import './editWaitStep.css'

const rootClass = 'edit-wait-step'

interface Props {
  step: Step
  program: Program
  tracks: Track[]
  isRunning: boolean
  saveStepAndProgram(step: Step | null, program?: Program): void
  submitId: string
  dataTest?: string
}

interface State {
  waitStep: ProgramWaitStepExt
  waitUntilStep: ProgramWaitUntilStepExt
  waitUntilInSegmentStep: ProgramWaitUntilInSegmentStepExt
  stepType: string
  displayName: string
}

const EditWaitStep: FC<Props> = (props: Props) => {
  const { step: baseStep, saveStepAndProgram, program, isRunning, submitId, dataTest = 'edit-wait-step' } = props
  const waitStep =
    baseStep.stepType === 'wait'
      ? ({ ...baseStep, stepType: 'wait' } as ProgramWaitStepExt)
      : ({
          displayName: baseStep.displayName,
          track: baseStep.track,
          depth: baseStep.depth,
          stepId: baseStep.stepId,
          stepType: 'wait',
        } as ProgramWaitStepExt)
  const waitUntilStep =
    baseStep.stepType === 'waitUntil'
      ? ({
          ...baseStep,
          stepType: 'waitUntil',
          schedule: { ...(baseStep as ProgramWaitUntilStepExt).schedule },
        } as ProgramWaitUntilStepExt)
      : ({
          displayName: baseStep.displayName,
          track: baseStep.track,
          depth: baseStep.depth,
          schedule: {
            ampm: 0,
            hour: 12,
            minute: 0,
          },
          stepId: baseStep.stepId,
          stepType: 'waitUntil',
        } as ProgramWaitUntilStepExt)
  const waitUntilInSegmentStep =
    baseStep.stepType === 'waitUntilInSegment'
      ? ({
          ...baseStep,
          stepType: 'waitUntilInSegment',
          schedule: { ...(baseStep as ProgramWaitUntilInSegmentStepExt).schedule },
        } as ProgramWaitUntilInSegmentStepExt)
      : ({
          displayName: baseStep.displayName,
          track: baseStep.track,
          depth: baseStep.depth,
          schedule: {
            ampm: 0,
            hour: 12,
            minute: 0,
          },
          stepId: baseStep.stepId,
          stepType: 'waitUntilInSegment',
        } as ProgramWaitUntilInSegmentStepExt)

  const schema = yup.object().shape({
    displayName: yup.string().required('Step Name is required.'),
    delay: yup.lazy(() => {
      if (state.stepType == 'wait') {
        return yup.number().typeError('Must be a number greater than 0.').required().min(1)
      }
      return yup.mixed().notRequired()
    }),
    delayUnit: yup.lazy(() => {
      if (state.stepType == 'wait') {
        return yup
          .string()
          .required()
          .test('delay', 'Wait must be greater than 10 minutes.', function (value) {
            const values: any = this.parent
            return !(value === 'minute' && values.delay < 10)
          })
      }
      return yup.mixed().notRequired()
    }),
  })

  const [state, setState] = useState<State>({
    waitStep,
    waitUntilStep,
    waitUntilInSegmentStep,
    stepType: baseStep.stepType,
    displayName: baseStep.displayName,
  })

  useEffect(() => {
    setState((state) => ({
      ...state,
      waitStep,
      waitUntilStep,
      waitUntilInSegmentStep,
      stepType: baseStep.stepType,
      displayName: baseStep.displayName,
    }))
  }, [])

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })
  const { t } = useTranslation()

  const onSubmit = (data: any) => {
    const { program, saveStepAndProgram } = props
    const { segmentMode, not } = getSegmentAndNotValue(data.segmentMode)
    const isListSegment = getIsListSegment(segmentMode)
    const interval =
      data.schedule?.interval && data.schedule.interval[data.schedule.type] ? parseInt(data.schedule.interval[data.schedule.type]) : undefined

    if (state.stepType == 'wait') {
      const newStep = {
        ...state.waitStep,
        ...data,
        letter: props.step.letter,
      }

      //Clean up step
      delete newStep['ampm']
      delete newStep['scheduledTime']
      delete newStep['endAmpm']
      delete newStep['scheduledEndTime']
      delete newStep['schedule']

      saveStepAndProgram(newStep)
    } else if (state.stepType == 'waitUntil') {
      const newStep = {
        ...state.waitUntilStep,
        ...data,
        schedule: {
          ...state.waitUntilStep.schedule,
          ...data.schedule,
          weekdaysOnly: data.schedule.weekdaysOnlyUntil ?? state.waitUntilStep.schedule.weekdaysOnly ?? false,
          hasEndTime: data.schedule.hasEndTime ?? state.waitUntilStep.schedule.hasEndTime ?? false,
          interval,
        },
        letter: props.step.letter,
      }

      //Clean up step
      delete newStep['ampm']
      delete newStep['scheduledTime']
      delete newStep['endAmpm']
      delete newStep['scheduledEndTime']
      delete newStep.schedule['weekdaysOnlyUntil']

      saveStepAndProgram(newStep)
    } else if (state.stepType == 'waitUntilInSegment') {
      if (!isListSegment && data.newSegment === 'true') {
        const localSegmentDetails = Array.isArray(program.localSegmentDetails) ? [...program.localSegmentDetails] : []
        const srcId = getNewSegmentName(program.localSegmentDetails)

        localSegmentDetails.push(getNewSegment(srcId, data.messageIds, segmentMode))

        const newStep = {
          ...state.waitUntilInSegmentStep,
          ...data,
          waitForScheduledRun: state.waitUntilInSegmentStep.waitForScheduledRun,
          schedule: {
            ...state.waitUntilInSegmentStep.schedule,
            ...data.schedule,
            weekdaysOnly: data.schedule.weekdaysOnlySegment ?? state.waitUntilInSegmentStep.schedule.weekdaysOnly ?? false,
            hasEndTime: data.schedule.hasEndTime ?? state.waitUntilInSegmentStep.schedule.hasEndTime ?? false,
            interval,
          },
          not,
          srcId,
          letter: props.step.letter,
        }

        const newProgram = {
          ...program,
          localSegmentDetails,
        }

        //Clean up step
        delete newStep['ampm']
        delete newStep['scheduledTime']
        delete newStep['endAmpm']
        delete newStep['scheduledEndTime']
        delete newStep.schedule['weekdaysOnlySegment']

        saveStepAndProgram(newStep, newProgram)
      } else {
        const newStep = {
          ...state.waitUntilInSegmentStep,
          ...data,
          waitForScheduledRun: state.waitUntilInSegmentStep.waitForScheduledRun,
          schedule: {
            ...state.waitUntilInSegmentStep.schedule,
            ...data.schedule,
            weekdaysOnly: state.waitUntilInSegmentStep.schedule.weekdaysOnly ?? data.schedule.weekdaysOnlySegment ?? false,
            hasEndTime: data.schedule.hasEndTime ?? state.waitUntilInSegmentStep.schedule.hasEndTime ?? false,
            interval,
          },
          not,
          srcId: data.srcId,
          letter: props.step.letter,
        }

        //Clean up step
        delete newStep['ampm']
        delete newStep['scheduledTime']
        delete newStep['endAmpm']
        delete newStep['scheduledEndTime']
        delete newStep.schedule['weekdaysOnlySegment']
        saveStepAndProgram(newStep)
      }
    }
  }

  const stepChange = useCallback<(event: React.ChangeEvent<HTMLInputElement>) => void>((e) => {
    const stepType = e.target.value

    setState((state) => ({
      ...state,
      stepType,
    }))
  }, [])

  const updateWaitStep = useCallback((waitStep: ProgramWaitStepExt) => {
    setState((state) => {
      return {
        ...state,
        waitStep: {
          ...state.waitStep,
          ...waitStep,
        },
      }
    })
  }, [])

  const updateWaitUntilStep = useCallback((waitUntilStep: ProgramWaitUntilStepExt) => {
    setState((state) => {
      return {
        ...state,
        waitUntilStep: {
          ...waitUntilStep,
          schedule: {
            ...waitUntilStep.schedule,
          },
        },
      }
    })
  }, [])

  const updateWaitUntilInSegmentStep = useCallback((waitUntilInSegmentStep: ProgramWaitUntilInSegmentStepExt) => {
    setState((state) => {
      return {
        ...state,
        waitUntilInSegmentStep: {
          ...waitUntilInSegmentStep,
          schedule: {
            ...waitUntilInSegmentStep.schedule,
          },
        },
      }
    })
  }, [])

  const onProgramSourcesUpdate = (sources: ProgramSource[]) => {
    saveStepAndProgram(null, getUpdatedProgramSources(program, sources))
  }

  const onMessageUpdate = (messages: ProgramMessage) => {
    saveStepAndProgram(null, getUpdatedProgramMessages(program, [messages]))
  }

  const renderForm = () => (
    <form data-test={dataTest} onSubmit={handleSubmit(onSubmit)}>
      <FormRow>
        <Input
          label={t('Step Name')}
          defaultValue={state.displayName}
          name="displayName"
          register={register('displayName')}
          className={`${rootClass}__step-name`}
        />
        {errors.displayName && <span className="error">{t(errors.displayName.message)}</span>}
      </FormRow>
      <FormRow>
        <RadioGroup verticalLayout>
          <Radio
            dataTest={`${dataTest}-radio-wait-step`}
            label={'Wait for a set period of time'}
            labelDisplay={'block'}
            checked={state.stepType === 'wait'}
            onChange={stepChange}
            name={'step.stepType'}
            value="wait"
          />
          <Radio
            dataTest={`${dataTest}-radio-wait-until-step`}
            label={'Wait until a specific date/time'}
            labelDisplay={'block'}
            checked={state.stepType === 'waitUntil'}
            onChange={stepChange}
            name={'step.stepType'}
            value="waitUntil"
          />
          <Radio
            dataTest={`${dataTest}-radio-wait-until-in-segment-step`}
            label={'Wait until contact meets a condition'}
            labelDisplay={'block'}
            checked={state.stepType === 'waitUntilInSegment'}
            onChange={stepChange}
            name={'step.stepType'}
            value="waitUntilInSegment"
          />
        </RadioGroup>
      </FormRow>
      {state.stepType === 'wait' && <WaitStep step={state.waitStep} register={register} errors={errors} updateWaitStep={updateWaitStep} />}
      {state.stepType === 'waitUntil' && (
        <WaitUntilStep step={state.waitUntilStep} program={program} register={register} errors={errors} updateWaitUntilStep={updateWaitUntilStep} />
      )}
      {state.stepType === 'waitUntilInSegment' && (
        <WaitUntilInSegmentStep
          step={state.waitUntilInSegmentStep}
          program={program}
          register={register}
          formData={getValues}
          formReset={reset}
          errors={errors}
          updateWaitUntilInSegmentStep={updateWaitUntilInSegmentStep}
          onProgramSourcesUpdate={onProgramSourcesUpdate}
          onMessageUpdate={onMessageUpdate}
        />
      )}
      <button type="submit" id={submitId} hidden data-test={`${dataTest}-submit-button`} />
    </form>
  )

  const getCondition = () => {
    switch (baseStep.stepType) {
      case 'wait':
        return <Typography text={`${t('Wait for')} ${state.waitStep.delay} ${t(state.waitStep.delayUnit)}${state.waitStep.delay > 1 ? 's' : ''}`} />
      case 'waitUntil':
        return buildWaitUntilSpecificDateStepCondition(state.waitUntilStep, t)
      case 'waitUntilInSegment':
        return buildWaitUntilInSegmentStepCondition(state.waitUntilInSegmentStep, program as ProgramWithStepData)
    }
  }

  const renderView = () => (
    <>
      <Typography text={baseStep.displayName} weight={TextWeight.MEDIUM} type={TextType.SECTION_HEADER} />
      <WaitStepDetail stepId={baseStep.stepId} condition={getCondition()} canMove={false} />
    </>
  )

  return <ModalBody className={rootClass}>{isRunning ? renderView() : renderForm()}</ModalBody>
}

export default EditWaitStep
