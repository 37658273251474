import { useApolloClient } from '@apollo/client'
import getAccountCategories from '@graphql/queries/getAccountCategories'
import { GetAccountCategoriesQuery, GetAccountCategoriesQueryVariables } from '@graphql/types/query-types'
import { FetchPromise } from '@utils/types'

export interface EditUpdateSubscriptionStepRequests {
  getSMAccountCategories: () => FetchPromise<GetAccountCategoriesQuery>
}

export const useEditUpdateSubscriptionStepRequests = (): EditUpdateSubscriptionStepRequests => {
  const actonClient = useApolloClient()

  const getSMAccountCategories = () => {
    return actonClient.query<GetAccountCategoriesQuery, GetAccountCategoriesQueryVariables>({
      query: getAccountCategories,
      fetchPolicy: 'network-only',
      variables: {},
    })
  }

  return {
    getSMAccountCategories,
  }
}
