import React, { FC, useContext, useEffect, useState } from 'react'
import { Row } from 'react-table'

import { buildSelectedListsTableProps } from '@complex/ListPickerModalV2/components/SelectedLists/utils/SelectedListsUtils'
import { SelectedListsType } from '@complex/ListPickerModalV2/utils/interfaces/ListPickerModalInterfaces'
import { ListPickerModalContext, selectedListsInitialState } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import ActionableNestedTable from '@components/ActionableNestedTable/ActionableNestedTable'
import ScrollArea from '@components/ScrollArea/ScrollArea'
import Typography, { LineHeight, TextWeight } from '@components/Typography/Typography'
import { GET_LIST_CATEGORIES, ListCategory } from '@utils/lists'

import './SelectedLists.css'

interface SelectedListsProps {
  multiSelect: boolean
  onRowSelectionChanged: (selectedRows: Row[], listType: ListCategory) => void
}

interface SelectedListsState {
  allLists: SelectedListsType
  init: boolean
  updatedSelectedLists: SelectedListsType
}

const rootClass = 'selected-lists'

const SelectedLists: FC<SelectedListsProps> = (props: SelectedListsProps) => {
  const { multiSelect, onRowSelectionChanged } = props

  const {
    values: { selectedLists, loading },
  } = useContext(ListPickerModalContext)

  const [state, setState] = useState<SelectedListsState>({
    allLists: selectedLists,
    init: true,
    updatedSelectedLists: selectedListsInitialState,
  })
  const { allLists, init, updatedSelectedLists } = state

  useEffect(() => {
    if (!loading) {
      setState((state) => ({ ...state, allLists: selectedLists }))
    }
  }, [loading])

  return (
    <div className={rootClass}>
      <ScrollArea className={`${rootClass}__scroll-area`}>
        {Object.entries(allLists)
          .filter((listValues) => listValues[1].length)
          .map((listValues) => {
            const { text, type } = GET_LIST_CATEGORIES().find(({ type }) => type === listValues[0]) || {}
            const listCategoryType = type as ListCategory

            const rowSelectionChanged = (selectedRows: Row[]) => {
              const selectedRowIds = selectedRows.map(({ original }) => (original as any).id)
              const selectedListsIds = updatedSelectedLists[listCategoryType].map(({ original }) => (original as any).id)
              const sameLists =
                selectedRowIds.every((id) => selectedListsIds.includes(id)) && selectedListsIds.every((id) => selectedRowIds.includes(id))
              if (!sameLists) {
                setState((state) => ({
                  ...state,
                  init: false,
                  updatedSelectedLists: { ...state.updatedSelectedLists, [listCategoryType]: selectedRows },
                }))
                onRowSelectionChanged(selectedRows, listCategoryType)
              }
            }

            return (
              <div className={`${rootClass}__list`} key={text}>
                <Typography text={text} weight={TextWeight.BOLD} lineHeight={LineHeight.MEDIUM} />
                <ActionableNestedTable
                  className={`${rootClass}__table`}
                  onRowSelectionChanged={rowSelectionChanged}
                  {...buildSelectedListsTableProps({
                    lists: listValues[1].map(({ original }) => ({ ...original, subRows: [] })),
                    listType: listCategoryType,
                    multiSelect,
                    selectedLists: init ? allLists : updatedSelectedLists,
                  })}
                />
              </div>
            )
          })}
      </ScrollArea>
    </div>
  )
}

export default SelectedLists
