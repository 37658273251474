import { gql } from '@apollo/client'

export default gql`
  mutation shareAssetToPeerAccounts($asset: [Asset!], $peers: [Int!]) {
    shareAssetToPeerAccounts(asset: $asset, peers: $peers) {
      creator
      message
      status
      successIds
      failedIds {
        id
        name
        reason
        accountId
      }
    }
  }
`
