import React, { FC, useState } from 'react'

import AssetPickerModal, { AssetPickerModalBaseProps, AssetPickerModalProps } from '@complex/AssetPickerModal/AssetPickerModal'
import { AssetPickerListingPageProps, AssetPickerSidebarProps, AssetPickerTableProps } from '@complex/AssetPickerModal/Context/AssetPicker.context'
import { FilterQueryParams } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { CustomSource, ListPageCommonState, RenderSearchColumnsV2, SetError, Update } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { FormType } from '@complex/ListingPage/Utils/ListingPage.constants'
import { useFormsAndTemplatesPickerRequests } from '@components/AssetPickers/FormsAndTemplatesPickerModal/GraphQL/FormsAndTemplatesPickerRequests.graphQL'
import {
  disableRowByCriteria,
  getAllItemsRequestResultsUtils,
  getSuggestedFormsRequestResultsUtils,
} from '@components/AssetPickers/FormsAndTemplatesPickerModal/utils/FormsAndTemplatesPickerModal.utils'
import { renderCustomFilters } from '@components/AssetPickers/FormsPickerModal/FormsPickerModal.sidebar'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { useTranslation } from '@const/globals'
import { ItemType } from '@utils/categorization'
import { allFormsFilter, allFormTemplatesFilter, CustomFilterDefinition, suggestedFormsFilter } from '@utils/filter'
import { Form, getFormsPreviewUrl, getFormsSubTypes } from '@utils/forms'
import useCRM, { CRMConnectorType } from '@utils/hooks/useCRM'

import {
  getDisabledTooltip,
  renderSearchTableColumns,
  renderSearchTemplatesTableColumns,
  renderTableColumns,
  renderTemplatesTableColumns,
} from './FormsAndTemplatesPickerModal.tables'

import './FormsAndTemplatesPickerModal.css'

const rootClass = 'forms-picker-modal'

export type FormsAndTemplatesPickerModalProps = AssetPickerModalBaseProps & { excludeClassic?: boolean; excludeNew?: boolean }

const FormsAndTemplatesPickerModal: FC<FormsAndTemplatesPickerModalProps> = (props) => {
  const { t } = useTranslation()
  const { hasCRMConnected, connectorType } = useCRM()
  const { excludeClassic, excludeNew } = props

  const { getFormPreviewRequest, getSuggestedFormsRequest, getAllItemsRequest } = useFormsAndTemplatesPickerRequests()
  const [itemType, setItemType] = useState(ItemType.FORM)

  const customPreviewItemCall = async (listPageValues: ListPageCommonState, update: Update, setError: SetError) => {
    const { selectedRows } = listPageValues
    const form = (selectedRows[0] as Form) ?? {}

    if (form.formType === FormType.NEW) {
      update({ previewUrl: getFormsPreviewUrl(form.externalId ?? '') })
    } else {
      update({ loading: true })
      const { data, errors } = await getFormPreviewRequest({ formId: form.externalId ?? '' })

      if (data) {
        update({ loading: false, previewHtml: data.getFormPreview })
      } else if (errors) {
        setError('Unexpected error', errors)
      }
    }
  }

  const getConnectorName = (type: CRMConnectorType): string => {
    if (type === CRMConnectorType.SALESFORCE) {
      return 'Salesforce'
    } else if (type === CRMConnectorType.NETSUITE) {
      return 'Netsuite'
    }
    return type
  }

  const renderSearchColumns: RenderSearchColumnsV2 = (
    _searchInAllItems: boolean | undefined,
    _currentFolder: FolderData,
    search: string,
    folders: FolderData[]
  ) => {
    return itemType === ItemType.FORM ? renderSearchTableColumns(search, folders, t) : renderSearchTemplatesTableColumns(search, folders, t)
  }

  const tableProps: AssetPickerTableProps = {
    columns: itemType === ItemType.FORM ? renderTableColumns(t) : renderTemplatesTableColumns(t),
    renderSearchColumns,
    listPage: 'Forms',
    sortingBy: [{ id: 'lastUpdated', desc: true }],
    rowDisabled: (row) => disableRowByCriteria(row.original),
    rowTooltip: ({ original }) => (disableRowByCriteria(original) ? getDisabledTooltip(original, t) : ''),
  }

  const customSources: CustomSource[] = [
    {
      allItemFilter: suggestedFormsFilter,
      customRequestFilters: [
        {
          filter: suggestedFormsFilter,
          request: (_currentPage?: number, params?: FilterQueryParams) => getSuggestedFormsRequestResultsUtils(getSuggestedFormsRequest, '', params),
          searchRequest: (query: string, _currentPage?: number, params?: FilterQueryParams) =>
            getSuggestedFormsRequestResultsUtils(getSuggestedFormsRequest, query, params),
        },
        {
          filter: allFormsFilter,
          request: (_currentPage?: number, params?: FilterQueryParams) =>
            getAllItemsRequestResultsUtils(getAllItemsRequest, ItemType.FORM, '', params),
          searchRequest: (query: string, _currentPage?: number, params?: FilterQueryParams) =>
            getAllItemsRequestResultsUtils(getAllItemsRequest, ItemType.FORM, query, params),
        },
      ],
      itemName: 'Form',
      label: 'Forms',
      mainColumnName: 'Form Title',
      itemType: ItemType.FORM,
      value: allFormsFilter.name,
    },
    {
      allItemFilter: allFormTemplatesFilter,
      customRequestFilters: [
        {
          filter: allFormTemplatesFilter,
          request: (_currentPage?: number, params?: FilterQueryParams) =>
            getAllItemsRequestResultsUtils(getAllItemsRequest, ItemType.FORM_TEMPLATE, '', params),
          searchRequest: (query: string, _currentPage?: number, params?: FilterQueryParams) =>
            getAllItemsRequestResultsUtils(getAllItemsRequest, ItemType.FORM_TEMPLATE, query, params),
        },
      ],
      itemName: 'Template',
      label: 'Templates',
      mainColumnName: 'Template Title',
      itemType: ItemType.FORM_TEMPLATE,
      value: allFormTemplatesFilter.name,
    },
  ]

  const formsCustomDefaultFilters: CustomFilterDefinition[] = [
    {
      ...allFormsFilter,
      position: 1,
    },
  ]

  const sidebarProps: AssetPickerSidebarProps = {
    hasRecent: false,
    hasCreatedByMe: true,
    hasFavorites: true,
    hideFilterCount: true,
    allItemFilter: suggestedFormsFilter,
    hasSalesUsersAction: true,
    customSources,
    customDefaultFilters: itemType === ItemType.FORM ? formsCustomDefaultFilters : undefined,
    renderCustomFilters:
      itemType === ItemType.FORM ? (params) => renderCustomFilters(params, hasCRMConnected ? getConnectorName(connectorType) : '', t) : undefined,
  }

  const subTypes = getFormsSubTypes(connectorType)

  const listingPageProps: AssetPickerListingPageProps = {
    customPreviewItemCall,
    sidebarProps,
    hasCustomRequests: true,
    subTypes,
  }

  const onSelectedCustomSourceChange = (selectedCustomSource: CustomSource) => {
    if (selectedCustomSource.value === allFormTemplatesFilter.name) {
      setItemType(ItemType.FORM_TEMPLATE)
    } else {
      setItemType(ItemType.FORM)
    }
  }

  const assetPickerProps: AssetPickerModalProps = {
    className: rootClass,
    listingPageProps,
    tableProps,
    hasTags: true,
    hasCustomFilters: itemType === ItemType.FORM,
    hasSidebar: true,
    hasPreview: true,
    itemType,
    onSelectedCustomSourceChange,
    defaultSubTypes: excludeClassic ? ['new'] : excludeNew ? ['classic'] : [],
    restrictSelectionToOneSource: false,
    ...props,
  }

  return <AssetPickerModal {...assetPickerProps} />
}

export default FormsAndTemplatesPickerModal
