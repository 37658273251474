import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Modal, { ModalBody } from '@components/Modal'
import StaticImage from '@components/StaticImage/StaticImage'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Typography, { LineHeight, TextAlign, TextType, TextWeight, TypographyProps } from '@components/Typography/Typography'

import './SuccessModal.css'

export interface SuccessModalProps {
  body: Partial<TypographyProps>
  className?: string
  dataTest?: string
  headline: Partial<TypographyProps>
  isOpen: boolean
  onPrimaryClick: VoidFunction
  onSecondaryClick?: VoidFunction
  primaryButtonText: string
  secondaryButtonText?: string
}

const rootClass = 'success-modal'

const SuccessModal: FC<SuccessModalProps> = (props: SuccessModalProps) => {
  const {
    body,
    className = '',
    dataTest = rootClass,
    headline,
    isOpen,
    onPrimaryClick,
    onSecondaryClick,
    primaryButtonText,
    secondaryButtonText,
  } = props

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} paddingV2>
      <ModalBody className={`${rootClass}__body`}>
        <StaticImage dataTest={`${dataTest}-image`} name={StaticImageNames.successCircle} />
        <Typography
          dataTest={`${dataTest}-headline`}
          type={TextType.MODAL_HEADLINE_LARGE}
          lineHeight={LineHeight.LARGER}
          weight={TextWeight.MEDIUM}
          {...headline}
        />
        <Typography dataTest={`${dataTest}-body`} type={TextType.BODY_TEXT_LIGHT} textAlign={TextAlign.CENTER} {...body} />
        <div className={`${rootClass}__buttons-container`}>
          <Button
            dataTest={`${dataTest}-primary-button`}
            className={`${rootClass}__buttons-container-button`}
            buttonType={ButtonType.PRIMARY}
            onClick={onPrimaryClick}
          >
            {primaryButtonText}
          </Button>
          {secondaryButtonText && secondaryButtonText && (
            <Button
              dataTest={`${dataTest}-secondary-button`}
              className={`${rootClass}__buttons-container-button`}
              buttonType={ButtonType.TRANSPARENT}
              onClick={onSecondaryClick}
            >
              {secondaryButtonText}
            </Button>
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}

export default SuccessModal
