import React, { FC } from 'react'

import classNames from 'classnames'

import Pill, { PillSize, PillType } from '@components/Pill/Pill'
import { SvgProps } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

export interface ChooserButtonProps {
  render?: boolean
  dataTest?: string
  disabled?: boolean
  className?: string
  onClick?(): void
  title: String
  tooltip?: string
  description?: String
  icon: React.ReactElement<SvgProps>
  customIcon?: boolean
  isNew?: boolean
}

const rootClass = 'chooser-button'

export const ChooserButton: FC<ChooserButtonProps> = (props: ChooserButtonProps) => {
  const {
    render = true,
    dataTest = 'chooser-button',
    className,
    onClick,
    icon,
    title,
    description,
    customIcon = false,
    tooltip,
    disabled,
    isNew,
  } = props

  const { t } = useTranslation()

  if (!render) return null

  const renderButton = () => (
    <button
      title={`${title} ${description ? description : ''}`}
      data-test={`${dataTest}-draft-button`}
      className={classNames(rootClass, className, {
        [`${rootClass}--style-icon`]: !customIcon,
        [`${rootClass}-disabled`]: disabled,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      <div className={`${rootClass}__icon`}>{icon}</div>
      <div className={`${rootClass}__text`}>
        <div className={`${rootClass}__tooltip`}>
          <Typography text={title} type={TextType.BODY_TEXT} weight={TextWeight.MEDIUM} lineHeight={LineHeight.MEDIUM} />
          {isNew && <Pill type={PillType.SECONDARY} text={t('NEW')} size={PillSize.SMALL} noMargin className={`${rootClass}__pill-new`} />}
        </div>
        {description && <Typography text={description} type={TextType.BODY_TEXT_SMALL_LIGHT} lineHeight={LineHeight.MEDIUM} />}
      </div>
    </button>
  )

  return tooltip ? <Tooltip trigger={renderButton()}>{tooltip}</Tooltip> : renderButton()
}

export default ChooserButton
