import React, { FC } from 'react'

import classNames from 'classnames'

import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './CreateInCRMDetail.css'

export interface CreateInCRMDetailProps {
  updateExisting: boolean
  useListPushPolicy: boolean
  updateMultipleMatches: boolean
  pushNewRecords: boolean
  insertAlways: boolean
  pushType?: string
  additionalEntities?: EntityFields[]
  staticFields?: StaticRecordFields[]
  className?: string
  dataTest?: string
}

export interface StaticRecordFields {
  key: string | boolean
  value: string
}

export interface EntityFields {
  entityType: string
  entityRefKey: string
  entitySingular: string
  requiredFields: string[]
}

const rootClass = 'create-in-crm-detail'

const CreateInCRMDetail: FC<CreateInCRMDetailProps> = (props: CreateInCRMDetailProps) => {
  const {
    updateExisting,
    pushNewRecords,
    useListPushPolicy,
    updateMultipleMatches,
    pushType,
    additionalEntities,
    insertAlways,
    staticFields,
    dataTest = rootClass,
    className = '',
  } = props
  const { t } = useTranslation()

  const renderFields = (fields?: StaticRecordFields[], showDash?: boolean) => {
    return (
      <div className={`${rootClass}__fields`}>
        {fields?.map((field: any, idx: any) => {
          return (
            field.value && (
              <div key={`${field.value}-${idx}`}>
                <Typography text={`${showDash ? '-' : `${field.key}:`} `} inline />
                {field.value !== '' && <Typography text={`${field.value}`} weight={TextWeight.MEDIUM} inline />}
                {field.value === '' && <Typography text={t('undefined')} weight={TextWeight.ITALIC} inline />}
              </div>
            )
          )
        })}
      </div>
    )
  }

  const renderCreateNewRecord = () => {
    return (
      pushNewRecords && (
        <>
          {insertAlways && (
            <Typography text={t('A new record will be created everytime this step runs (allowing duplicates)')} type={TextType.BODY_TEXT_LIGHT} />
          )}
          <div>
            <Typography text={`${t('Create new records as')}: `} inline />
            {pushType !== '' && <Typography text={pushType} weight={TextWeight.MEDIUM} inline />}
            {pushType === '' && <Typography text={t('undefined')} weight={TextWeight.ITALIC} inline />}
          </div>
          {staticFields && (
            <div>
              <Typography text={`${t('Fields to set on new records')}: `} inline />
              {staticFields && renderFields(staticFields)}
            </div>
          )}
        </>
      )
    )
  }

  const renderUpdateExistingRecord = () => {
    const updateRecordFields = [
      { key: useListPushPolicy, value: t("Follow list's push policy") },
      { key: updateMultipleMatches, value: t('Update when multiple matches are found in CRM') },
    ]
    return (
      updateExisting && (
        <div>
          <Typography text={`${t('When updating existing records')}: `} inline />
          {renderFields(updateRecordFields, true)}
        </div>
      )
    )
  }

  const renderEntities = () => {
    return (
      additionalEntities && (
        <div>
          <Typography text={`${t('Entities added')}: `} inline />
          <div className={`${rootClass}__fields`}>
            {additionalEntities?.map((entity: any, idx: any) => (
              <div key={`entity-${idx}`}>
                <Typography text={`- ${entity.entityType}`} weight={TextWeight.MEDIUM} inline />
              </div>
            ))}
          </div>
        </div>
      )
    )
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {!pushNewRecords && !updateExisting && !additionalEntities && (
        <Typography text={t('No fields have been set yet')} type={TextType.BODY_TEXT_LIGHT} />
      )}
      {renderCreateNewRecord()}
      {renderUpdateExistingRecord()}
      {renderEntities()}
    </div>
  )
}

export default CreateInCRMDetail
