import React from 'react'

import { t } from 'i18next'

import Button, { ButtonType } from '@components/Button'
import { ButtonWeight } from '@components/Button/Button'
import { TableV2Props, TableV2RowData } from '@components/TableV2/tableV2TS/interfaces'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { ColWithTooltip } from '@components/TableV2/utils/tableV2ColumnUtils'
import { goToFormReport } from '@src/pages/listingPages/Forms/utils/FormsListingPage.tables'

interface EmbeddedFormsTableRowData extends TableV2RowData<EmbeddedFormsTableRowData> {
  id: string
  name: string
}

const columns: ColumnDefWithAdditionalProps<EmbeddedFormsTableRowData>[] = [
  {
    accessorKey: 'name',
    textAlign: 'left',
    header: t('Form Name'),
    padding: { right: 0 },
    cell: (cell) => ColWithTooltip({ cell }),
  },
  {
    accessorKey: 'id',
    textAlign: 'right',
    header: undefined,
    enableCustomCellValue: true,
    maxSize: 150,
    cell: ({ row }) => (
      <Button
        buttonType={ButtonType.TEXT_TEAL}
        weight={ButtonWeight.MEDIUM}
        inline
        onClick={() =>
          goToFormReport({
            formId: row.original.id,
            target: '_blank',
          })
        }
      >
        {t('View report')}
      </Button>
    ),
  },
]

const getEmbeddedFormsTableProps: () => Omit<TableV2Props<EmbeddedFormsTableRowData>, 'data'> = () => ({
  columns,
  withoutBorders: true,
  enableCalculatedRow: true,
  styles: { minWidth: 'auto' },
})

export default getEmbeddedFormsTableProps
