import { gql } from '@apollo/client'

export default gql`
  query getRevenueImpactLeads($endDate: String, $timeZoneId: String, $startDate: String) {
    getRevenueImpactLeads(endDate: $endDate, timeZoneId: $timeZoneId, startDate: $startDate) {
      analytics {
        count
        topMonth
        topSource
        trend
      }
      byDate {
        count
        date
      }
      bySource {
        count
        name
      }
    }
  }
`
