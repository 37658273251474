import React, { useContext } from 'react'

import Modal, { ModalBody } from '@components/Modal'
import ModalFooterV2 from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { useTranslation } from '@utils/const/globals'

import './ReviewAndSendModal.css'

import { CommonComposerTab, SaveComposerCommonType } from '@utils/composer/commonComposer/CommonComposer.context'

const rootClass = 'review-and-send-modal'

const ReviewAndSendModal = ({ saveComposerEmail }: { saveComposerEmail: SaveComposerCommonType }) => {
  const {
    values,
    api: { update, onTabChange, onPreview },
  } = useContext(EmailComposerContext)
  const { t } = useTranslation()

  const handleSave = async () => {
    try {
      await saveComposerEmail(values)
      onTabChange(CommonComposerTab.REVIEW)
      onPreview()
      update({ isReviewAndSendModal: false })
    } catch {
      update({ isReviewAndSendModal: false })
    }
  }

  return (
    <Modal isOpen paddingV2 className={rootClass}>
      <ModalBody>
        <Svg name={SvgNames.warning} type={SvgType.VERY_LARGE_ICON} />
        <Typography text={t('Review your email?')} type={TextType.MODAL_HEADLINE_CONFIRMATION} weight={TextWeight.MEDIUM} />
        <Typography text={t('Please confirm that you want to review your email before sending.')} type={TextType.BODY_TEXT_LIGHT} />
      </ModalBody>
      <ModalFooterV2
        buttons={{ cancelButtonLabel: 'Cancel', actionButtonLabel: 'Review', actionButtonOnClick: handleSave }}
        onClose={() => {
          update({ isReviewAndSendModal: false })
        }}
      />
    </Modal>
  )
}

export default ReviewAndSendModal
