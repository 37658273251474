import React, { FC, MutableRefObject, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { ButtonWeight } from '@components/Button/Button'
import CaretIcon, { CaretIconDirection } from '@components/CaretIcon'
import Checkbox from '@components/Checkbox'
import ActonContactsFieldIcon from '@components/EditColumnsDropdown/components/ActonContactsFieldIcon/ActonContactsFieldIcon'
import InfoTooltip from '@components/InfoTooltip/InfoTooltip'
import Loader from '@components/Loader'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './SelectableColumnsSection.css'

interface SelectableColumnsSectionProps {
  className?: string
  dataTest?: string
  headers: string[]
  selectedHeaders: string[]
  sectionHeader: string
  tooltip?: string
  loading?: boolean
  isActOnContacts?: boolean
  onSelect: (fields: string[], isAdding: boolean) => void
  disableCollapse?: boolean
  scrollableContainerRef?: MutableRefObject<HTMLDivElement | undefined>
}

const rootClass = 'selectable-columns-section'

const SelectableColumnsSection: FC<SelectableColumnsSectionProps> = (props: SelectableColumnsSectionProps) => {
  const {
    dataTest = rootClass,
    className = '',
    sectionHeader,
    selectedHeaders,
    tooltip,
    loading,
    isActOnContacts = false,
    onSelect,
    disableCollapse,
    scrollableContainerRef,
    headers,
  } = props

  const [isOpen, setOpen] = useState(true)

  const headerRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()

  useEffect(() => {
    const scrollableContainer = scrollableContainerRef?.current
    const header = headerRef.current
    const container = containerRef.current

    const onScroll = () => {
      if (header && container) {
        const firstParentChild = container.parentNode?.firstElementChild
        const containerTopPadding = firstParentChild === container ? 0 : 18
        header.classList.toggle(`${rootClass}__header-sticky`, header.offsetTop - containerTopPadding - container.offsetTop > 1 && isOpen)
      }
    }

    if (header && container && scrollableContainer) {
      scrollableContainer.addEventListener('scroll', onScroll)
      return () => scrollableContainer.removeEventListener('scroll', onScroll)
    }
  }, [headerRef.current, containerRef.current, scrollableContainerRef?.current])

  const onSelectAll = () => {
    onSelect(headers, selectedHeaders.length < headers.length)
  }

  const renderField = (field: string) => (
    <Checkbox
      key={`checkbox-${field}-${sectionHeader}`}
      dataTest={`${dataTest}-field-${field}`}
      className={classNames(`${rootClass}__field`)}
      checked={selectedHeaders.includes(field)}
      onChange={(checked) => onSelect([field], checked)}
      title={''}
      label={
        <>
          {isActOnContacts && <ActonContactsFieldIcon />}
          <Typography text={field} type={TextType.BODY_TEXT} />
        </>
      }
      showTickOnDisabled
    />
  )

  const renderFieldsContainer = () => (
    <div className={`${rootClass}__fields-container`} data-test={`${dataTest}-fields-container`}>
      {headers.map(renderField)}
    </div>
  )

  const renderLoader = () => {
    return (
      <div className={`${rootClass}__loader-container`}>
        <Loader />
        <Typography text={t('Loading column fields...')} type={TextType.BODY_TEXT_LIGHT} />
      </div>
    )
  }

  const renderHeader = () => {
    return (
      <div className={`${rootClass}__header`} ref={headerRef}>
        <div
          className={classNames(`${rootClass}__header-clickable`, { [`${rootClass}__header-clickable-disabled`]: disableCollapse })}
          data-test={`${dataTest}-header`}
          onClick={() => !disableCollapse && setOpen(!isOpen)}
          role={'button'}
          tabIndex={0}
          onKeyDown={({ key }) => ((key === '' || key === 'Enter') && !disableCollapse ? setOpen(!isOpen) : undefined)}
        >
          {!disableCollapse && <CaretIcon direction={CaretIconDirection.RIGHT} toggle={isOpen} isFilled={false} />}
          <div className={`${rootClass}__header-title`}>
            <Typography text={sectionHeader} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
            {!disableCollapse && (
              <Typography
                text={`({{selected}} of {{total}} selected)`}
                values={{ selected: selectedHeaders.length, total: headers.length }}
                type={TextType.BODY_TEXT_LIGHT}
              />
            )}
            {tooltip && <InfoTooltip>{tooltip}</InfoTooltip>}
          </div>
        </div>
        {!disableCollapse && (
          <Button buttonType={ButtonType.TEXT_TEAL} weight={ButtonWeight.MEDIUM} onClick={onSelectAll} dataTest={`${dataTest}-select-all`}>
            {t(selectedHeaders.length === headers.length ? 'Unselect all' : 'Select all')}
          </Button>
        )}
      </div>
    )
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest} ref={containerRef}>
      {renderHeader()}
      {isOpen || disableCollapse ? (loading ? renderLoader() : renderFieldsContainer()) : null}
    </div>
  )
}

export default SelectableColumnsSection
