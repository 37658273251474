import { useApolloClient } from '@apollo/client'
import createSegment from '@graphql/mutations/createSegment'
import getAutomatedProgramCounts from '@graphql/queries/getAutomatedProgramCounts'
import listSchemaQuery from '@graphql/queries/listSchema'
import { CreateSegmentMutation, CreateSegmentMutationVariables } from '@graphql/types/mutation-types'
import {
  GetAutomatedProgramCountsQuery,
  ListSchemaQuery,
  ListSchemaQueryVariables,
  QueryGetAutomatedProgramCountsArgs,
} from '@graphql/types/query-types'
import { FetchPromise } from '@utils/types'

export interface GetListSchemaRequestParams {
  listIds: string[]
}

export type GetListSchemaRequestType = (params: GetListSchemaRequestParams) => FetchPromise<ListSchemaQuery>

interface UpgradeManagerClassicRequests {
  createSegmentRequest: (segmentName: string) => FetchPromise<CreateSegmentMutation>
  getListSchemaRequest: GetListSchemaRequestType
  getProgramContactsCount: (programId: string) => Promise<number>
}

export const useUpgradeManagerClassicRequests = (): UpgradeManagerClassicRequests => {
  const { query, mutate } = useApolloClient()

  const createSegmentRequest = (segmentName: string) => {
    return mutate<CreateSegmentMutation, CreateSegmentMutationVariables>({
      mutation: createSegment,
      errorPolicy: 'all',
      variables: {
        name: segmentName,
      },
    })
  }

  const getListSchemaRequest: GetListSchemaRequestType = ({ listIds }) =>
    query<ListSchemaQuery, ListSchemaQueryVariables>({
      query: listSchemaQuery,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        listIds,
      },
    })

  const getProgramContactsCount = async (programId: string): Promise<number> => {
    const { data } = await query<GetAutomatedProgramCountsQuery, QueryGetAutomatedProgramCountsArgs>({
      query: getAutomatedProgramCounts,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        programId,
      },
    })
    return data?.getAutomatedProgramCounts?.pendingSize ?? 0
  }

  return {
    createSegmentRequest,
    getListSchemaRequest,
    getProgramContactsCount,
  }
}
