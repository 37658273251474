import React, { FC, useCallback, useContext, useMemo } from 'react'

import { useTranslation } from '@const/globals'
import ValidationSection from '@src/pages/EmailComposer/components/common/ValidationSection/ValidationSection'
import { Validation, ValidationSectionStatus } from '@src/pages/EmailComposer/components/common/ValidationsList/ValidationsList'
import { useAccountSettings } from '@utils/account/account.utils'
import { CommonComposerTab } from '@utils/composer/commonComposer/CommonComposer.context'
import { EmailComposerContext, ValidationSectionType } from '@utils/composer/context/EmailComposer.context'
import {
  PERSONALIZED_FROM_ADDRESS_OPTION_VALUE,
  SALESFORCE_OWNER_OPTION_VALUE,
  SAME_AS_SENDER_OPTION_VALUE,
} from '@utils/composer/settings.constants'
import useCRM from '@utils/hooks/useCRM'

interface SenderDetailsSectionProps {
  dataTest?: string
  disableEditSenderBtn?: boolean
}

const rootClass = 'sender-details-section'

export const SenderDetailsSection: FC<SenderDetailsSectionProps> = ({ dataTest = rootClass, disableEditSenderBtn = false }) => {
  const {
    values: {
      emailSenders,
      message: { sender, replyToSender, fallbackSender },
      validations: {
        settingsValidations: { listsIncompatibleWithSalesforceOwner, listsIncompatibleWithPersonalizedFrom },
      },
      messageConfiguration: {
        reviewAndSend: { disableEditings },
      },
    },
    api: { onTabChange, onStatusChange },
  } = useContext(EmailComposerContext)
  const { t } = useTranslation()
  const { connectorType } = useCRM()

  const { userId, userName, userEmail } = useAccountSettings()
  const validations = useMemo<Validation[]>(() => {
    // Sender validation
    const isPS = sender === PERSONALIZED_FROM_ADDRESS_OPTION_VALUE
    const isSO = sender === SALESFORCE_OWNER_OPTION_VALUE
    const isLoggedInUser = sender === userId
    const senderObj =
      isPS || isSO
        ? undefined
        : isLoggedInUser || disableEditSenderBtn
        ? { name: userName, email: userEmail }
        : emailSenders.find(({ uuid }) => sender === uuid)
    const hasSender = isPS || isSO || senderObj
    const personalizeFromError = !!listsIncompatibleWithPersonalizedFrom.length
    const salesforceOwnerError = !!listsIncompatibleWithSalesforceOwner.length
    const senderStatus =
      (isPS && personalizeFromError) || (isSO && salesforceOwnerError) || !hasSender ? ValidationSectionStatus.ERROR : ValidationSectionStatus.SUCCESS
    const senderContext = isPS
      ? personalizeFromError
        ? 'personalizedError'
        : 'personalized'
      : isSO
      ? salesforceOwnerError
        ? 'ownerError'
        : 'owner'
      : senderObj
      ? 'email'
      : 'error'

    // Reply-to validation
    const sameAsSender = !replyToSender || replyToSender === SAME_AS_SENDER_OPTION_VALUE || replyToSender === sender
    const replayToObj = sameAsSender ? undefined : emailSenders.find(({ uuid }) => replyToSender === uuid)
    const replyToStatus = sameAsSender
      ? hasSender
        ? ValidationSectionStatus.SUCCESS
        : ValidationSectionStatus.WARNING
      : replayToObj
      ? ValidationSectionStatus.SUCCESS
      : ValidationSectionStatus.ERROR

    const replayToContext = sameAsSender ? 'same' : replayToObj ? 'email' : 'error'
    const validations: Validation[] = [
      {
        key: 'sender',
        status: senderStatus,
        title: 'EmailComposer.Sender.Details.Validation.Sender',
        titleProps: {
          values: { context: senderContext, name: senderObj?.name, email: senderObj?.email },
        },
        infoTooltipContent:
          senderContext === 'ownerError' ? t(`EmailComposer.Sender.Details.Validation.Sender.Tooltip`, { context: senderContext }) : undefined,
        infoToggletipProps: ['owner', 'personalized'].includes(senderContext)
          ? {
              title: t(`EmailComposer.Sender.Details.Validation.Sender.Toggletip.Title`, { context: senderContext }),
              description: t(`EmailComposer.Sender.Details.Validation.Sender.Toggletip.Desc`, { context: senderContext }),
              linkText: t('More info'),
              link: 'https://connect.act-on.com/hc/en-us/articles/360025707374-Email-From-Setup',
            }
          : undefined,
      },
      {
        key: 'replayTo',
        status: replyToStatus,
        title: 'EmailComposer.Sender.Details.Validation.ReplayTo',
        titleProps: {
          values: { context: replayToContext, name: replayToObj?.name, email: replayToObj?.email },
        },
      },
    ]

    if (isPS) {
      // Show Fallback validation only if is 'Personalize'
      const fallBackSenderObj = fallbackSender
        ? fallbackSender === userId
          ? { name: userName, email: userEmail }
          : emailSenders.find(({ uuid }) => fallbackSender === uuid)
        : undefined
      const fallbackStatus = fallBackSenderObj ? ValidationSectionStatus.SUCCESS : ValidationSectionStatus.ERROR

      validations.splice(1, 0, {
        key: 'fallback',
        status: fallbackStatus,
        title: 'EmailComposer.Sender.Details.Validation.Fallback',
        titleProps: {
          values: { context: fallbackStatus, name: fallBackSenderObj?.name, email: fallBackSenderObj?.email },
        },
      })
    }
    return validations
  }, [
    sender,
    replyToSender,
    fallbackSender,
    emailSenders,
    listsIncompatibleWithPersonalizedFrom.length,
    listsIncompatibleWithSalesforceOwner.length,
    userId,
    userName,
    userEmail,
  ])

  const handleClick = useCallback<VoidFunction>(
    () => onTabChange(CommonComposerTab.SETTINGS, { fromValidationSection: ValidationSectionType.SENDER }),
    [onTabChange]
  )

  return (
    <ValidationSection
      disableEditBtn={disableEditings || disableEditSenderBtn}
      tooltipContentKey={
        disableEditings ? t('EmailComposer.Resend.Disabled') : t('EmailComposer.Preview.Validations.Sender.Tooltip', { connectorType })
      }
      type={ValidationSectionType.SENDER}
      dataTest={dataTest}
      title="Sender details"
      buttonLabel="Edit sender"
      validations={validations}
      onClick={handleClick}
      onStatusChange={onStatusChange}
    />
  )
}
