import React, { FC, useMemo } from 'react'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import { getComplexityUIValues } from '../../utils/security.utils'

import './ComplexityValidationDisplay.css'

interface ComplexityValidationDisplayProps {
  dataTest?: string
  complexity: string
  showError?: boolean
  length: number
  value: string
}

const rootClass = 'complexity-validation-display'

const ComplexityValidationDisplay: FC<ComplexityValidationDisplayProps> = (props: ComplexityValidationDisplayProps) => {
  const { dataTest = rootClass, complexity, showError = false, value, length } = props

  const { t } = useTranslation()

  const getValidators = useMemo(
    () =>
      getComplexityUIValues(complexity, length).map((item) => {
        if (item.visible) {
          return (
            <div className={`${rootClass}__item`} data-test={`${dataTest}-item`} key={item.text}>
              <Svg
                name={showError && !item.validator(value) ? SvgNames.checkError : item.validator(value) ? SvgNames.checkSuccess : SvgNames.checkLight}
                type={SvgType.MEDIUM}
                className={`${rootClass}__item-icon`}
              />
              <Typography
                text={t(item.text)}
                type={showError && !item.validator(value) ? TextType.VALIDATION_ERROR : TextType.BODY_TEXT_SMALL}
                lineHeight={LineHeight.MEDIUM_SMALL}
                style={item.validator(value) || showError ? { opacity: '100%' } : { opacity: '50%' }}
                className={`${rootClass}__item-text`}
              />
            </div>
          )
        }
      }),
    [value, complexity, length, showError]
  )

  return (
    <div className={`${rootClass}`} data-test={dataTest}>
      {getValidators}
    </div>
  )
}

export default ComplexityValidationDisplay
