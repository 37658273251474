import { useCallback, useEffect, useRef, useState } from 'react'

import { Row } from '@tanstack/react-table'
import useWindowSize from '@utils/hooks/useWindowSize'

import { getStickyColsWidths } from '../utils/tableV2ColumnUtils'

export const useCalculatedRows = <T>(rows: Row<T>[]) => {
  const [preparedRows, setPreparedRows] = useState<Row<T>[]>([])

  const prepareIDs = useCallback((row: Row<T>, index: number, parentID: string, IDMap: Map<string, string>) => {
    parentID = row.depth === 0 ? '' : `${parentID}.`
    const changedID = `${parentID}${index}`
    IDMap.set(row.id, changedID)
    row.subRows.forEach((subRow: Row<T>, subIndex: number) => prepareIDs(subRow, subIndex, changedID, IDMap))
  }, [])

  const changeRowIDs = useCallback(
    (row: Row<T>, IDMap: Map<string, string>): Row<T> => ({
      ...row,
      id: IDMap.get(row.id) as string,
      subRows: row.subRows.map((subRow: Row<T>) => changeRowIDs(subRow, IDMap)),
    }),
    []
  )

  useEffect(() => {
    const IDMap = new Map<string, string>()
    const prepareIDs = (row: Row<T>, index: number, parentID: string) => {
      parentID = row.depth === 0 ? '' : `${parentID}.`
      const changedID = `${parentID}${index}`
      IDMap.set(row.id, changedID)
      row.subRows.forEach((subRow: Row<T>, subIndex: number) => prepareIDs(subRow, subIndex, changedID))
    }

    const changeRowIDs = (row: Row<T>): Row<T> => {
      return {
        ...row,
        id: IDMap.get(row.id) as string,
        subRows: row.subRows.map((subRow: Row<T>) => changeRowIDs(subRow)),
      }
    }

    rows.filter((row) => row.depth == 0).forEach((row, index) => prepareIDs(row, index, ''))

    const preParedRows = rows.map((row) => changeRowIDs(row))
    setPreparedRows(preParedRows)
  }, [rows])

  return preparedRows
}

export const useStickyColWidths = (stickyColumns?: string[]) => {
  const [stickyColsWidths, setStickyColsWidths] = useState<number[]>([])
  const windowSize = useWindowSize()
  const prevWinSize = useRef<{ width?: number; height?: number }>()

  useEffect(() => {
    if (stickyColumns && windowSize.width !== prevWinSize.current?.width) {
      prevWinSize.current = windowSize
      setStickyColsWidths(getStickyColsWidths(stickyColumns.length))
    }
  }, [stickyColumns, windowSize])

  return stickyColsWidths
}
