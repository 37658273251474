import { gql } from '@apollo/client'

export default gql`
  mutation createLandingPageFromEmailDraft($id: String!, $newName: String, $folderId: Int, $tags: [LabelDto]) {
    createLandingPageFromEmailDraft(id: $id, newName: $newName, folderId: $folderId, tags: $tags) {
      newPageId
      status
    }
  }
`
