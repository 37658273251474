import { gql } from '@apollo/client'

export default gql`
  query accountSettings {
    accountSettings {
      appLogo
      appTitle
      accountId
      accountName
      accountTimeZoneId
      accountBasedMarketing
      accountBasedMarketingForSales
      authorizedStartDate
      canHideObjectTypeCustomPrefix
      canPublish
      canShareAsset
      canSubscribe
      customCssInjection
      customCssVersion
      externalId
      hasEmailFatigueSuppressionRules
      hasAIAnalytics
      hasAIAudienceInsights
      hasAdvancedAnalytics
      hasEmbeddedAnalytics
      hasFTPMirrorImport
      hasOutgoingWebhookStep
      isClientAccount
      isCustomLogo
      isMicrosoftStartPage
      isMSDyn
      isNS
      isSF
      isSL
      isSugar
      isZendesk
      getConnectedCrm
      maxMarketingUsers
      maxSalesUsers
      maxSends
      paymentType
      timeZoneId
      userAllowedToCreate
      userAllowedToCreatePrograms
      userAllowedToDelete
      userAllowedToCreateContent
      userAllowedToDeleteContent
      userAllowedToDeletePrograms
      userAllowedToDownload
      userEmail
      userId
      userName
      contactSearch
      customLink
      dynamicNavUrl
      linkTriplet
      uiThemesSwitcher
      nextGenContactLists
      isAgencyAccount
      actonUI
      hasCustomSkin
      customSkinParams {
        navBackgroundColor
        navFontColor
        navSubBackgroundColor
        navSubDividerColor
        navFontActiveColor
        navFontHighlightColor
      }
      pendoCode
      parentId
      newLogo
      hidePoweredBy
      userSettings {
        userDesktop
        isAdministrator
      }
      accessNextGenUI
      agencyRefresh
      customLogos {
        customExpanded
        customCollapsed
        darkExpanded
        darkCollapsed
        lightExpanded
        lightCollapsed
      }
      isDemoHost
      crmServiceEnabled
      customEventsEnabled
      extendedListsSupported
      automaticQuarantine
      quarantineEnabled
      nimbusScoringUI
      subscriptionManagementEnabled
      isAccountAdmin
      disableBetaSearch
      landingPageListing
      useOnlyNewForms
      hideTwitterProspector
      seoEnabled
      adwordsEnabled
      isNewComposerOnly
      draftListing
      campaignsEnabled
      unifiedCampaigns
      connectedCampaignsEnabled
      projectsEnabled
      reportsEnabled
      isLocalHost
      revenueAttributionEnabled
      crmServiceInitialized
      revenueImpactEnabled
      funnelEnabled
      dataStudioEnabled
      isRootAccount
      hideAllFoldersEnabled
      oauthEnabled
      showStatusDashboard
      salesPortalMyMessagesOnly
      hideMyAction
      isALUV
      reenterProgramContacts
      multipleScoreSheets
      hasAOCWebinars
      hasAPDashboards
      hasReactLinkReporting
      hasAPDashboardsPreview
      hasAPListingReact
      hasAPManager
      hasAudienceCenterNav
      hasCheckDomainConfig
      hasContactFragmentsReact
      hasAutomatedProgramsUpgrade
      hasCopySegments
      hasCustomObjectsListingPage
      hasFormsUpgrade
      hasNewCRMStep
      hasNewListMaintenanceUI
      hasNewSegmentComposerUI
      hasReactProgramDashboard
      hasReactWrapper
      hasPurchasedSMS
      hasShowActOnContactsTab
      disableSMSForAll
      exclusivelyActOnContacts
      autoRefreshSummaryReports
      hasValidCrmCreds
      hasGlobalBCC
      hasShowMonthlySentModal
      hasSubscriptionManagementMultilanguage
      hasSubscriptionManagementMultilanguageAutoDetect
      hideSecurityTabForSalesUser
      enableUsersReactPage
      enableEmailDraftsReact
      hasAgencyWhiteLabel
      disableBrandingReactPage
      hasExternalUserAuth
      isSingleSignOn
      dataLakeEmailReport
      enableRevenueReportMappingTab
      enableFormsSectionReact
      enableLandingPageSectionReact
      enableChatGptComposerPlugin
      enableMlAudiencePlugin
      generativeAIEmailComposer
      enableMarketingNetworkNav
      hasEmailComposerUndo
      phase3FormSubmission
      isManager
      hasEmailTemplatesReact
      personalizedFrom
      hasAgencyAssignSalesUsers
      hasLandingPageReportV2
      enableForethoughtWidget
      enableMopoCopilot
      isMopoVisible
      useCrmModelPersonalization
      hideMarketingCalendar
      imagePickerViewType
      hasTransactionalEmailControl
      transactionalAllowNoOptOut
      allowNoOptOut
      websiteProspectorOwnerUCL
      showAgencyMessageUsage
      connections {
        litmus
      }
      enableGoldenChildToast
      enableEmailPerformanceTrueOpenFilter
      subscriptionManagementIsLive
      crmNoteCheckboxOffByDefault
      limitSalesUsersToOwnedContacts
      accountResponseServerName
      eventBasedEmail
      gaLegacyEmail
      isUsingExternalSegmentationService
      newFormFileUploadBlock
      disableSegmentModifications
      enableEmailListingTrueOpenFilter
      savedRowsSharing
      crmSelectEntitiestoSync
      crmSyncFilters
      newLPComposerCreateBlank
      messageNotePushStreamingPush
      newLPComposerFormBlock
      enableScoreFactorsForExternalSegmentation
      newLPComposerMapBlock
      newLPComposerTemplates
      crmSyncFilters
      crmSelectEntitiestoSync
    }
  }
`
