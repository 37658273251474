import React, { FC } from 'react'

import classNames from 'classnames'

import { DateRangeValueType } from '@components/DateRangePicker/DateRangePicker'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextAlign, TextType, TextWeight, TypographyProps } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { WonOpportunities } from '@graphql/types/microservice/data-lake-query-types'

import { renderArrow } from './DataCard.utils'
import getFormattedData from './utils/dataCards.formattedData'

import './DataCards.css'

export interface RevenueHeaderDataCard {
  header: string
  title?: string
  subtitle?: string
  value?: string
  tooltipTypographyProps?: TypographyProps
  trend?: { isPositive: boolean }
}

export interface RevenueImpactDataCardsProps {
  data: WonOpportunities
  range: DateRangeValueType
  loading: boolean
  className?: string
  dataTest?: string
}

const rootClass = 'data-cards'

const DataCards: FC<RevenueImpactDataCardsProps> = (props) => {
  const { data, range, loading, className, dataTest = rootClass } = props
  const { t } = useTranslation()

  const formattedData = getFormattedData({ data, t, range })

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {formattedData.map(({ header, title, subtitle, trend, value, tooltipTypographyProps }) => {
        const valueTypographyAdditionalProps = trend
          ? {
              className: classNames(`${rootClass}__card-trend-text`, {
                [`${rootClass}__card-trend-text__success`]: trend.isPositive,
              }),
              inlineBlock: true,
            }
          : {}
        const _value = <Typography text={value} weight={TextWeight.MEDIUM} {...valueTypographyAdditionalProps} />
        const valueNode = trend ? (
          <div>
            {renderArrow(trend.isPositive)}
            {_value}
          </div>
        ) : (
          _value
        )

        return (
          <div className={`${rootClass}__card`} data-test={`${dataTest}__card`} key={header}>
            <div className={`${rootClass}__card-header`}>
              <Typography text={header} type={TextType.DATA_CARD_TEXT_LIGHT} lineHeight={LineHeight.MEDIUM_LARGE} />
            </div>
            <div className={`${rootClass}__card-content`}>
              <TextWithTooltipOnEllip
                typographyProps={{
                  text: loading ? '' : title || t('No data'),
                  weight: title ? TextWeight.MEDIUM : TextWeight.ITALIC,
                  lineHeight: LineHeight.VERY_LARGE,
                  className: classNames({
                    [`${rootClass}__card-content-no-data`]: !title,
                  }),
                }}
              />
            </div>
            <div className={`${rootClass}__card-footer`}>
              {loading ? null : (
                <>
                  <Typography text={subtitle} type={TextType.BODY_TEXT_SMALL_LIGHT} lineHeight={LineHeight.MEDIUM_SMALL} />
                  {tooltipTypographyProps ? (
                    <Tooltip trigger={valueNode}>
                      <Typography
                        type={TextType.BODY_TEXT_WHITE_SMALL}
                        lineHeight={LineHeight.MEDIUM_SMALL}
                        textAlign={TextAlign.CENTER}
                        tagProps={{ bold: { weight: TextWeight.BOLD } }}
                        {...tooltipTypographyProps}
                      />
                    </Tooltip>
                  ) : (
                    valueNode
                  )}
                </>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default DataCards
