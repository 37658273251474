export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    DaySummary: ['EmailMessagesDaySummary', 'FormsAndMediaDaySummary', 'LandingPageDaySummary'],
    List: ['ListInfo'],
    MonthlySummary: ['EmailMessagesMonthlySummary', 'FormsAndMediaMonthlySummary', 'LandingPageMonthlySummary'],
    ProgramStep: [
      'ProgramAlertStep',
      'ProgramAppendToSegmentStep',
      'ProgramBaseStep',
      'ProgramBranchStep',
      'ProgramCitrixSyncStep',
      'ProgramCopyFromStep',
      'ProgramCopyStep',
      'ProgramCrmCreateStep',
      'ProgramFieldSetStep',
      'ProgramGoToSetStep',
      'ProgramOutgoingWebhookStep',
      'ProgramRecordSetStep',
      'ProgramSForceCampaignStep',
      'ProgramSendStep',
      'ProgramSmsSendStep',
      'ProgramTaskStep',
      'ProgramUpdateSubscriptionStep',
      'ProgramWaitStep',
      'ProgramWaitUntilInSegmentStep',
      'ProgramWaitUntilStep',
      'ProgramWebExSyncStep',
      'ProgramWebRequestStep',
    ],
    WeekdaySummary: ['EmailMessagesWeekdaySummary', 'FormsAndMediaWeekdaySummary', 'LandingPageWeekdaySummary'],
  },
}
export default result
