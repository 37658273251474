import { gql } from '@apollo/client'

export default gql`
  query crmDefinition($connectedCrmName: String) {
    crmDefinition(connectedCrmName: $connectedCrmName) {
      entityTypeDisplayInfoMap
      entityTypeWithCrmToStandardFieldMapList {
        crmToStandardFieldMapList {
          crmName
          standardName
        }
        entityTypeName
      }
      primaryEntityTypeNames
      unsupportedTypes {
        defaultDisplayName
        emailColumn
        idColumns
        name
        nameColumns
        pkColumn
        prefix
        visible
      }
    }
  }
`
