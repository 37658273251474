import React, { useCallback, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'
import equal from 'fast-deep-equal/es6/react'
import { useDebouncedCallback } from 'use-debounce'
import { v4 as scrollAreaKey } from 'uuid'

import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import ScrollArea from '@components/ScrollArea/ScrollArea'
import { TableV2Pagination } from '@components/TableV2/components/Pagination/TableV2Pagination'
import { TableV2ActionHeader } from '@components/TableV2/components/TableV2ActionHeader/TableV2ActionHeader'
import { TableV2HeaderMenuActions } from '@components/TableV2/components/TableV2HeaderMenuActions/TableV2HeaderMenuActions'
import { TBody } from '@components/TableV2/components/TBody/TBody'
import { TFoot } from '@components/TableV2/components/TFoot/TFoot'
import { THead } from '@components/TableV2/components/THead/THead'
import { useCalculatedRows } from '@components/TableV2/hook/tableV2.hook'
import { TableV2Props, TableV2RowData, VirtualScrollerProps } from '@components/TableV2/tableV2TS/interfaces'
import {
  caseInsensitive,
  handleScrollArea,
  headerActionCount,
  pageContainerScroll,
  statusDateTime,
  tableV2Checkbox,
  tableV2Radio,
  VIRTUAL_SCROLLER_ITEM_ESTIMATED_SIZE,
  VIRTUAL_SCROLLER_OVERSCAN_COUNT,
} from '@components/TableV2/utils/tableV2Utils'
import {
  ColumnDef,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  PaginationState,
  Row,
  RowSelectionState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import { usePrevious } from '@utils/hooks/usePrevious'

import './TableV2.css'

export const rootClass = 'tableV2'
export const TableV2 = <TData extends {}>({
  data,
  styles,
  columns,
  loading,
  canDrop,
  tagProps,
  allLoaded,
  sortingBy,
  emptyState,
  rowActions,
  loaderProps,
  customSorts,
  isDraggable,
  enableRadio,
  rowEmptyText,
  enableSubRow,
  enableFooter,
  resetSorting,
  bodyMaxHeight,
  stickyColumns,
  enableSorting,
  manualSorting,
  rowUniqueIdKey,
  enablePaginate,
  enableCheckbox,
  resetPageIndex,
  withoutBorders,
  showTopBorder,
  showBottomBorder,
  tableMenuItems,
  enableMultiSort,
  paginationState,
  stickyReference,
  pageSizeOptions,
  enablePagesInput,
  rowDisabledTitle,
  enableLazyLoading,
  enableSpacerBlock,
  enableOuterLoader,
  headerActions = [],
  enableInsideLoader,
  enableStickyHeader,
  defaultSelectedRows,
  defaultExpandedRows,
  enableCalculatedRow,
  hasDisabledRowStyles,
  enableSortingRemoval,
  scrollableElementRef,
  forceResetSelectedRows,
  headerCheckboxDisabled,
  manualPagination = true,
  stickyHeaderTopPosition,
  resetSelectedRowsOnChange,
  enableSubRowSelection = false,
  enableBodyHeightComputation,
  resetSelectionWhenPageChanges = true,
  onSort,
  fetchData,
  onLoading,
  onRowsSort,
  customClass,
  hasToExpand,
  rowDisabled,
  rowTooltip,
  onRowClicked,
  onRowExpanded,
  onRowCheckboxChange,
  overrideCheckboxCell,
  onRowSelectionChanged,
  onRowsChanged,
  onHeaderCheckboxChange,
  isRowSelectionDisabled,
  onRowSectionSelectionChanged,
  headerActionText = headerActionCount,
  showScrollArea = false,
  virtualScroller,
  preserveScrollOnPagination = false,
  dataTest,
  tooltipProps = {
    position: 'top',
    withoutTail: true,
  },
}: TableV2Props<TData>) => {
  const [expanded, setExpanded] = useState<ExpandedState>(defaultExpandedRows ?? {})
  const [selectedRowsById, setSelectedRowsById] = useState<Record<string, Row<TData>>>({})
  const [selectedRows, setSelectedRows] = useState<Row<TData>[]>([])
  const [sorting, setSorting] = useState<SortingState>(sortingBy ?? [])
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(defaultSelectedRows ?? {})
  const [rowSelectionChangedFromDefault, setRowSelectionChangedFromDefault] = useState(false)

  const previousDefaultSelectedRows = usePrevious(defaultSelectedRows)

  const debouncedLoading = useDebouncedCallback(onLoading || (() => null), 100, { leading: true, trailing: false })

  const tableWrapperRef = React.useRef<HTMLDivElement>(null)

  const isLoading = loaderProps?.loading || loading
  const rowSelectionKeys = Object.keys(rowSelection)
  const isLazyLoading = enableLazyLoading && isLoading && !!data.length && !allLoaded
  const isSortingEnabled = enableSorting || !!sortingBy?.length || !!customSorts
  const pageSelectedRowsState = resetSelectedRowsOnChange || forceResetSelectedRows
  const tbodyWithScrollArea = !!stickyColumns?.length
  const scrollAreaUniqueKey = scrollAreaKey()

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>(
    paginationState ?? {
      pageIndex: 0,
      pageSize: pageSizeOptions?.[0] || 10,
    }
  )

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  )

  const tableColumns = useMemo(() => {
    if (enableCheckbox) {
      return [
        tableV2Checkbox({
          selectedRowsCount: rowSelectionKeys.length,
          headerCheckboxDisabled,
          rowDisabledTitle,
          rowTooltip,
          onRowCheckboxChange,
          onHeaderCheckboxChange,
          overrideCheckboxCell,
        }),
        ...columns,
      ]
    }
    if (enableRadio) {
      return [
        tableV2Radio({
          rowDisabledTitle,
          rowTooltip,
          setRowSelection,
          tooltipProps,
        }),
        ...columns,
      ]
    }
    return columns
  }, [
    enableCheckbox,
    enableRadio,
    columns,
    rowSelectionKeys.length,
    headerCheckboxDisabled,
    rowDisabledTitle,
    rowDisabled,
    onRowCheckboxChange,
    onHeaderCheckboxChange,
    overrideCheckboxCell,
    isRowSelectionDisabled,
    rowSelection,
  ])

  const getRowId = useMemo<((originalRow: TData, index: number, parent?: Row<TData> | undefined) => string) | undefined>(() => {
    if (!rowUniqueIdKey) {
      return
    }

    return (originalRow, index, parent) => {
      if (typeof rowUniqueIdKey === 'function') {
        return rowUniqueIdKey(originalRow, index, parent)
      } else {
        const id = originalRow[rowUniqueIdKey]
        return typeof id === 'string' ? id : typeof id === 'number' ? `${id}` : `${index}`
      }
    }
  }, [rowUniqueIdKey])

  const table = useReactTable<TData>({
    data,
    columns: tableColumns as ColumnDef<TData, unknown>[],
    manualSorting,
    enableMultiSort,
    enableSortingRemoval: enableSortingRemoval || false,
    enableRowSelection: (row) =>
      !('disabled' in row.original && row.original.disabled) &&
      !(isRowSelectionDisabled && isRowSelectionDisabled(row)) &&
      !(rowDisabled && rowDisabled(row)),
    enableSubRowSelection,
    sortingFns: { caseInsensitive, statusDateTime, ...(customSorts || {}) },
    state: {
      rowSelection,
      ...(enablePaginate && { pagination }),
      ...(isSortingEnabled && { sorting }),
      ...(enableSubRow && { expanded }),
    },
    ...(enablePaginate && { pageCount: paginationState?.controlledPageCount }),
    ...(enablePaginate && { manualPagination }),
    ...(enablePaginate && { onPaginationChange: setPagination }),
    ...(isSortingEnabled && { getSortedRowModel: getSortedRowModel() }),
    ...(isSortingEnabled && { onSortingChange: setSorting }),
    ...(enableSubRow && { getSubRows: (row: TableV2RowData<TData>) => row.subRows }),
    ...(enableSubRow && { onExpandedChange: setExpanded }),
    ...(enableSubRow && { getExpandedRowModel: getExpandedRowModel() }),
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    getRowId,
  })

  const headerGroups = table.getHeaderGroups()
  const { rows: rowModel, rowsById: currentPageRowsById } = table.getRowModel()
  const { rowsById: currentPageSelectedRowsById } = table.getSelectedRowModel()

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const rows = enableCalculatedRow ? useCalculatedRows(rowModel) : rowModel
  const hasEmptyState = !!emptyState && !isLoading && !rows.length && !data.length
  const showHeaderAction = !!headerActions.length && !!rowSelectionKeys.length

  const onDeselectAll = useCallback(() => {
    table.toggleAllRowsSelected(false)
  }, [table.toggleAllRowsSelected])

  const getUpdatedSelectedRowsById = (rowSelection: RowSelectionState): Record<string, Row<TData>> => {
    return Object.keys(rowSelection).reduce((updatedSelectedRowsById: Record<string, Row<TData>>, id) => {
      if (currentPageRowsById[id]) {
        // If the row is in the current page, then we need to check if it is selected
        if (currentPageSelectedRowsById[id]) {
          updatedSelectedRowsById[id] = currentPageSelectedRowsById[id]
        } else {
          delete updatedSelectedRowsById[id]
        }
      }
      // If the row is not in the current page and resetSelectionWhenPageChanges is false, then we need to check if it was selected before
      if (selectedRowsById[id] && !resetSelectionWhenPageChanges) {
        updatedSelectedRowsById[id] = selectedRowsById[id]
      }
      return updatedSelectedRowsById
    }, {})
  }

  useEffect(() => {
    manualSorting && onSort?.(sorting)
  }, [sorting])

  useEffect(() => {
    onRowsChanged?.(rows)
  }, [onRowsChanged, rows])

  useEffect(() => {
    const selectedRowsById = getUpdatedSelectedRowsById(rowSelection)
    const selectedRows = Object.values(selectedRowsById)
    setSelectedRowsById(selectedRowsById)
    setSelectedRows(selectedRows)

    // This prevents onRowSelectionChanged from being triggered due to a change to default selected rows
    if (!equal(rowSelection, defaultSelectedRows) || rowSelectionChangedFromDefault) {
      onRowSectionSelectionChanged?.(selectedRows)
      if (onRowSelectionChanged) {
        const pushedRows = resetSelectionWhenPageChanges ? rows : [...new Set([...selectedRows, ...rows])]
        onRowSelectionChanged(rowSelectionKeys, pushedRows)
      }
      setRowSelectionChangedFromDefault(true)
    }
  }, [rowSelection])

  useEffect(() => {
    if (defaultSelectedRows) {
      setRowSelectionChangedFromDefault(false)
      if (previousDefaultSelectedRows !== undefined && !equal(previousDefaultSelectedRows, defaultSelectedRows)) {
        // If we've received a new value for defaultSelectedRows since the table mounted
        setRowSelection(defaultSelectedRows)
      }
    }
  }, [defaultSelectedRows])

  useEffect(() => {
    if (!!resetSelectedRowsOnChange?.length || forceResetSelectedRows) {
      setRowSelection({})
    }
  }, [...(resetSelectedRowsOnChange || []), forceResetSelectedRows])

  useEffect(() => {
    if (!enableLazyLoading && isLoading && !pageSelectedRowsState && !!rowSelectionKeys.length && resetSelectionWhenPageChanges) {
      setRowSelection({})
    }
  }, [enableLazyLoading, isLoading, pageSelectedRowsState])

  useEffect(() => {
    if (resetSorting && isLoading) {
      setSorting(sortingBy ?? [])
    }
  }, [resetSorting, sortingBy, isLoading])

  useEffect(() => {
    if (allLoaded) return
    const canLoadData = !isLoading && !isLazyLoading

    const scrollArea = scrollableElementRef?.current
    const pageContainer = document.getElementsByClassName('page-container')[0]

    const onContainerScroll = () => pageContainerScroll(pageContainer, debouncedLoading, canLoadData)
    const handleScroll = (ev: Event) => handleScrollArea(ev, debouncedLoading, canLoadData)

    if (scrollArea) {
      scrollArea.addEventListener('scroll', handleScroll)
      return () => scrollArea.removeEventListener('scroll', handleScroll)
    } else if (pageContainer) {
      pageContainer.addEventListener('scroll', onContainerScroll)
      return () => {
        pageContainer.removeEventListener('scroll', onContainerScroll)
      }
    }
  }, [allLoaded, isLoading, isLazyLoading, debouncedLoading, scrollableElementRef])

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableWrapperRef.current,
    estimateSize: () => virtualScroller?.estimatedHeight || VIRTUAL_SCROLLER_ITEM_ESTIMATED_SIZE,
    overscan: VIRTUAL_SCROLLER_OVERSCAN_COUNT,
  })

  // Show Outer loader
  if (!enableInsideLoader && !enableLazyLoading && isLoading) {
    return enableOuterLoader ? (
      <div className={`${rootClass}__loader-wrapper`}>
        {loaderProps?.component || <Loader className={`${rootClass}__loader`} blackout={false} loaderType={LoaderTypes.page} />}
      </div>
    ) : null
  }

  // Show Empty State
  if (hasEmptyState) {
    if (!React.isValidElement(emptyState)) {
      return <EmptyListing dataTest={`${rootClass}__empty`} size={EmptyListingSize.MEDIUM} {...emptyState} />
    } else {
      return emptyState
    }
  }

  const tableWrapper = (
    <div
      className={classNames(`${rootClass}__wrapper`, {
        [`${rootClass}__no-border`]: withoutBorders,
        [`${rootClass}__bottom-border`]: showBottomBorder,
        [`${rootClass}__top-border`]: showTopBorder,
        [`${rootClass}__sticky-columns`]: !!stickyColumns?.length,
        [`${rootClass}__virtual-scroller-wrapper`]: virtualScroller?.enabled,
      })}
      ref={tableWrapperRef}
      data-test={dataTest}
    >
      <table>
        {!showHeaderAction && (
          <THead
            headerGroups={headerGroups}
            stickyColumns={stickyColumns}
            enableSorting={isSortingEnabled}
            enableStickyHeader={enableStickyHeader}
            stickyHeaderTopPosition={stickyHeaderTopPosition}
          />
        )}
        <TBody
          data={data}
          rows={rows}
          canDrop={canDrop}
          tagProps={tagProps}
          isLoading={isLoading}
          rowActions={rowActions}
          isDraggable={isDraggable}
          enableSubRow={enableSubRow}
          rowEmptyText={rowEmptyText}
          selectedRows={selectedRows}
          stickyColumns={stickyColumns}
          bodyMaxHeight={bodyMaxHeight}
          enablePaginate={enablePaginate}
          columnsLength={tableColumns.length}
          enableSpacerBlock={enableSpacerBlock}
          hasRowDisabledTitle={!!rowDisabledTitle}
          hasDisabledRowStyles={hasDisabledRowStyles}
          isExpanded={!!Object.values(expanded).length}
          enableBodyHeightComputation={enableBodyHeightComputation}
          showInsideLoader={enableInsideLoader && !isLazyLoading && !!isLoading}
          onRowsSort={onRowsSort}
          customClass={customClass}
          hasToExpand={hasToExpand}
          rowDisabled={rowDisabled}
          onRowClicked={onRowClicked}
          onRowExpanded={onRowExpanded}
          showScrollArea={showScrollArea}
          toggleAllRowsSelected={table.toggleAllRowsSelected}
          virtualScroller={
            virtualScroller &&
            ({
              ...virtualScroller,
              virtualItems: virtualizer ? virtualizer.getVirtualItems() : [],
              containerHeight: virtualizer.getTotalSize(),
            } as VirtualScrollerProps)
          }
        />
        {enableFooter && <TFoot table={table} stickyColumns={stickyColumns} />}
      </table>
      {isLazyLoading && <Loader className={`${rootClass}__loading`} />}
    </div>
  )

  return (
    <div
      ref={stickyReference}
      className={classNames(`${rootClass}`, {
        [`${rootClass}__sticky`]: enableStickyHeader,
        [`${rootClass}__virtual-scroller`]: virtualScroller?.enabled,
      })}
      style={{ ...styles }}
    >
      {!!tableMenuItems?.length && <TableV2HeaderMenuActions loading={loading} menuItems={tableMenuItems} />}
      {showHeaderAction && (
        <TableV2ActionHeader
          actions={headerActions}
          selectedRows={selectedRows}
          headerGroups={headerGroups}
          stickyHeaderTopPosition={stickyHeaderTopPosition}
          onDeselectAll={onDeselectAll}
          headerActionCountText={headerActionText(rowSelectionKeys.length)}
        />
      )}

      {tbodyWithScrollArea ? (
        <ScrollArea key={scrollAreaUniqueKey} showOnEvent="always" hasHorizontalScroll className={`${rootClass}__scroll`}>
          {tableWrapper}
        </ScrollArea>
      ) : (
        tableWrapper
      )}

      {enablePaginate && (
        <TableV2Pagination
          table={table}
          resetPageIndex={resetPageIndex}
          pageSizeOptions={pageSizeOptions}
          enablePagesInput={enablePagesInput}
          fetchData={fetchData}
          insideModal={paginationState?.dropdownInsideModal}
          preserveScrollPosition={preserveScrollOnPagination}
          dropdownPosition={paginationState?.dropdownPosition}
        />
      )}
    </div>
  )
}
