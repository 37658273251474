import { InboxAsset, PreviewAssetType } from '@src/pages/MarketingNetwork/Inbox/Inbox.constants'
import { titleCase } from '@utils/strings'

export const onSearchUtils = (term: string, assets: InboxAsset[]) => {
  const search = term.toLowerCase()

  return assets.reduce((acc, curr) => {
    const nameMatch = curr.title.toLowerCase().includes(search)
    const typeMatch = curr.type.toLowerCase().includes(search)
    const fromMatch = curr.fromUser.toLowerCase().includes(search)
    if (nameMatch || typeMatch || fromMatch) {
      acc.push(curr)
    }
    return acc
  }, [] as InboxAsset[])
}

export const typeToText = (type: string, isPlural = true) => {
  if (type === PreviewAssetType.STATIONARY) {
    return titleCase(type)
  } else if (type === PreviewAssetType.IMAGE) {
    return 'Image Library'
  } else if (type === PreviewAssetType.DOC) {
    return 'Media Library'
  } else if (type === PreviewAssetType.LANDING_PAGE) {
    return `Landing Page${isPlural ? 's' : ''}`
  } else if (type === PreviewAssetType.LANDING_PAGE_TEMPLATE) {
    return `Landing Page Template${isPlural ? 's' : ''}`
  } else if (type === PreviewAssetType.EMAIL_TEMPLATE) {
    return `Email Template${isPlural ? 's' : ''}`
  } else if (type === PreviewAssetType.EMAIL_HEADER) {
    return `Email Header${isPlural ? 's' : ''}`
  } else if (type === PreviewAssetType.EMAIL_FOOTER) {
    return `Email Footer${isPlural ? 's' : ''}`
  } else if (type === PreviewAssetType.AUTORESPONSE_SHARE) {
    return `Auto Response${isPlural ? 's' : ''}`
  }
  if (type.includes('_')) {
    return type
      .split('_')
      .map((txt, idx) => `${titleCase(txt)}${isPlural && idx === 1 ? 's' : ''}`)
      .join(' ')
  } else {
    return `${titleCase(type)}${isPlural ? 's' : ''}`
  }
}
