import { createContext, ReactNode } from 'react'

import { ProgressBarStep } from '@components/ProgressBar/ProgressBar'
import { Status } from '@components/StatusToast/StatusToast'
import { rootContext } from '@const/globals'
import { FtpFileDto } from '@graphql/types/microservice/entity-upload-types'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { ProgramSchedule } from '@graphql/types/query-types'
import {
  Delimiter,
  ExportFirstLineOption,
  exportOptionsDefaultValues,
  Quote,
} from '@src/pages/ExportContacts/components/ExportOptionsScreen/utils/ExportOptionsScreen.constants'
import { ExportField } from '@src/pages/ExportContacts/utils/ExportContacts.utils'
import { Segment } from '@utils/contactSegments/contactSegments.utils'

export type ExportContactsContainerState = {
  steps: ProgressBarStep[]
  schedule: ProgramSchedule
  firstLineContains: ExportFirstLineOption
  fieldsSeparator: Delimiter
  fieldQuotes: Quote
  selectedFtpFile?: FtpFileDto
  selectingFtpFile: boolean
  visibleFields: UnifiedListFieldMapping[]
  exportFields: ExportField[]
  updateExportDefinition: boolean
  exportDefinitionId?: number
  exportSyncJobId?: number
  statusToast: { statusMessage: string | ReactNode; status: Status; showStatusToast: boolean }
  exportSegment?: Segment
  creatingFtpFile: boolean
  loading: boolean
  loadingExportFields: boolean
  backUrl: string
}

export const exportContactsContainerInitialState: ExportContactsContainerState = {
  steps: [],
  schedule: exportOptionsDefaultValues.schedule,
  firstLineContains: ExportFirstLineOption.NAMES,
  fieldsSeparator: Delimiter.Comma,
  fieldQuotes: Quote.SimpleQuote,
  selectingFtpFile: false,
  visibleFields: [],
  exportFields: [],
  updateExportDefinition: false,
  statusToast: { statusMessage: '', status: Status.FAIL, showStatusToast: false },
  creatingFtpFile: false,
  loading: true,
  loadingExportFields: true,
  backUrl: `${rootContext}/segments`,
}
export type Update = (values: Partial<ExportContactsContainerState>) => void

export interface ExportContactsAPI {
  values: ExportContactsContainerState
  closeStatusToast: VoidFunction
  handleOnSectionChange: (newStep: string, currentStepCompleted?: boolean) => void
  update: Update
}

export const ExportContactsContextMock: ExportContactsAPI = {
  values: {
    ...exportContactsContainerInitialState,
  },
  handleOnSectionChange: () => undefined,
  closeStatusToast: () => undefined,
  update: () => undefined,
}

export const ExportContactsContext = createContext<ExportContactsAPI>({ values: exportContactsContainerInitialState } as any)
