import { ApolloClient } from '@apollo/client'
import { Program } from '@graphql/types/query-types'
import { Action, PayloadAction } from '@interface/Action'
import actionTypes from '@utils/program/programActionTypes'

export interface LoadProgramPayload {
  id: string
  client: ApolloClient<object>
}

function loadProgram(id: string, client: ApolloClient<object>): PayloadAction<LoadProgramPayload> {
  return {
    type: actionTypes.loadProgram,
    payload: {
      id,
      client,
    },
  }
}

export interface LoadProgramCompletePayload {
  program: Program
}

export function loadProgramComplete(payload: LoadProgramCompletePayload) {
  return {
    type: actionTypes.loadProgramComplete,
    payload,
  }
}

export interface LoadProgramErrorPayload {
  error: any
}

export function loadProgramError(payload: LoadProgramErrorPayload) {
  return {
    type: actionTypes.loadProgramError,
    payload,
  }
}

export interface SaveProgramErrorPayload {
  error: any
}

export function saveProgramError(payload: SaveProgramErrorPayload) {
  return {
    type: actionTypes.saveProgramError,
    payload,
  }
}

export interface UpdateProgramPayload {
  program: Program
}

function updateProgram(program: Program): PayloadAction<UpdateProgramPayload> {
  return {
    type: actionTypes.updateProgram,
    payload: {
      program,
    },
  }
}

export interface SaveProgramPayload {
  program: Program
  client: ApolloClient<object>
}

export function saveProgram(payload: SaveProgramPayload): PayloadAction<SaveProgramPayload> {
  return {
    type: actionTypes.saveProgram,
    payload,
  }
}

export interface SaveProgramCompletePayload {
  status: string
}

export function saveProgramComplete(payload: SaveProgramCompletePayload) {
  return {
    type: actionTypes.saveProgramComplete,
    payload,
  }
}

export function clearProgramStatus() {
  return {
    type: actionTypes.clearProgramStatus,
  }
}

export interface SaveProgramFinalPayload {
  programId: string
  client: ApolloClient<object>
}

export function saveProgramFinal(payload: SaveProgramFinalPayload) {
  return {
    type: actionTypes.saveProgramFinal,
    payload,
  }
}

export function saveProgramFinalComplete(newProgramId: string) {
  return {
    type: actionTypes.saveProgramFinalComplete,
    payload: {
      newProgramId,
    },
  }
}

export function saveProgramFinalError(error: any) {
  return {
    type: actionTypes.saveProgramFinalError,
    payload: {
      error,
    },
  }
}

export function clearFinalSaveStatus() {
  return {
    type: actionTypes.clearFinalSaveStatus,
  }
}

export interface CreateProgramMessagePayload {
  client: ApolloClient<object>
  programId: string
  messageIds: string[]
}

export function createProgramMessage(payload: CreateProgramMessagePayload): Action<CreateProgramMessagePayload> {
  return {
    type: actionTypes.createProgramMessage,
    payload,
  }
}

export function createProgramMessageRequest(payload: CreateProgramMessagePayload): Action<CreateProgramMessagePayload> {
  return {
    type: actionTypes.createProgramMessageRequest,
    payload,
  }
}

export function clearProgramMessage() {
  return {
    type: actionTypes.clearProgramMessage,
  }
}

export function setHasAOContactsSource(payload: SaveProgramPayload) {
  return {
    type: actionTypes.setHasAOContactsSource,
    payload,
  }
}

export interface EditProgramActions {
  loadProgram(id: string, client: ApolloClient<object>): PayloadAction<LoadProgramPayload>
  saveProgram(payload: SaveProgramPayload): PayloadAction<SaveProgramPayload>
  updateProgram(program: Program): PayloadAction<UpdateProgramPayload>
  clearProgramStatus(): void
  saveProgramFinal(payload: SaveProgramFinalPayload): PayloadAction<SaveProgramFinalPayload>
  clearFinalSaveStatus(): void
  createProgramMessage(payload: CreateProgramMessagePayload): Action<CreateProgramMessagePayload>
  createProgramMessageRequest(payload: CreateProgramMessagePayload): Action<CreateProgramMessagePayload>
  clearProgramMessage(): void
  setHasAOContactsSource(payload: SaveProgramPayload): void
}

const programActions: EditProgramActions = {
  loadProgram,
  saveProgram,
  updateProgram,
  clearProgramStatus,
  saveProgramFinal,
  clearFinalSaveStatus,
  createProgramMessage,
  createProgramMessageRequest,
  clearProgramMessage,
  setHasAOContactsSource,
}

export default programActions
