import { gql } from '@apollo/client'

export default gql`
  query getProgramContacts(
    $programId: String!
    $stepId: String!
    $state: String!
    $perPage: Int!
    $currentPage: Int!
    $srcId: String
    $query: String
    $sortColumn: String!
    $sortDirection: Direction!
  ) {
    getProgramContacts(
      programId: $programId
      stepId: $stepId
      state: $state
      perPage: $perPage
      currentPage: $currentPage
      srcId: $srcId
      query: $query
      sortColumn: $sortColumn
      sortDirection: $sortDirection
    ) {
      programContacts {
        row
        email
        name
        listId
        listName
        count
        since
        recId
        deleted
        when
        reason
        reasonDetails
        currentStepId
        currentStepName
        exitedStepId
        exitedStepName
        earlyExitReasonId
        earlyExitReason
        status
      }
      page
      totalPages
      perPage
      totalRecords
      isSorted
    }
  }
`
