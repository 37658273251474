import React, { Component } from 'react'

import classNames from 'classnames'
import debounce from 'debounce'

import { ModalProps } from '@components/Modal/Modal'
import { MODAL_BASE_Z_INDEX } from '@components/Modal/modalUtils'
import globals from '@const/globals'
import documentUtils from '@utils/document'
import windowUtil from '@utils/window'

const rootClass = 'modal'

export interface ModalInstanceProps {
  /** current index of modal if we have multiple modals open */
  index: number
}

export interface State {
  index: number
  lastBodyHeight: number
}

const MODAL_PADDING_TOTAL = 48

export function getDivFromSelect(div: HTMLDivElement | null, selector: string): HTMLDivElement | null {
  if (!div) {
    return null
  }
  return div.querySelector(selector) as HTMLDivElement
}

export class ModalInstance extends Component<ModalInstanceProps & ModalProps, State> {
  state = {
    index: this.props.index,
    lastBodyHeight: 0,
  }

  private modalRef = React.createRef<HTMLDivElement>()
  private headerRef = React.createRef<HTMLDivElement>()
  private subHeaderRef = React.createRef<HTMLDivElement>()
  private interval: any
  private debouncedOnResize: any | undefined

  componentDidMount = () => {
    this.debouncedOnResize = debounce(this.onWindowResize, 200)

    if (this.debouncedOnResize) {
      windowUtil.addEventListener('resize', this.debouncedOnResize)
      this.interval = setInterval(() => {
        const bodyHeight = this.getBodyHeight()
        const lastHeight = this.state.lastBodyHeight ?? 0
        if (bodyHeight > lastHeight + 1 || bodyHeight < lastHeight - 1) {
          this.onWindowResize()
        }
      }, 200)
      this.onWindowResize()
    }
  }

  componentWillUnmount = () => {
    windowUtil.removeEventListener('resize', this.debouncedOnResize)
    clearInterval(this.interval)
  }

  componentDidUpdate = (prevProps: ModalProps) => {
    if (
      this.props.fitModalBody &&
      (this.props.fitModalBody.suggestedWidth !== prevProps.fitModalBody?.suggestedWidth ||
        this.props.fitModalBody.suggestedHeight !== prevProps.fitModalBody?.suggestedHeight)
    ) {
      this.fitModalBody()
    }
  }

  onWindowResize = () => {
    if (globals.isIE()) {
      const body = getDivFromSelect(this.modalRef.current, '.modal-body')
      if (this.modalRef.current && body) {
        const maxModalHeight = window.innerHeight - MODAL_PADDING_TOTAL
        const offset = this.getOffset()
        const bodyHeight = this.getBodyHeight()

        if (bodyHeight > 0 && maxModalHeight < bodyHeight + offset) {
          body.style.height = `${maxModalHeight - offset - 30}px`
        } else {
          body.style.height = ''
        }

        this.modalRef.current.style.top = `${(window.innerHeight - this.modalRef.current.offsetHeight) / 2}px`
        this.modalRef.current.style.left = `${(window.innerWidth - this.modalRef.current.offsetWidth) / 2}px`

        if (bodyHeight !== this.state.lastBodyHeight) {
          this.setState({
            lastBodyHeight: bodyHeight,
          })
        }
      }
    }
    this.fitModalBody()
  }

  fitModalBody() {
    if (this.props.fitModalBody) {
      const offset = this.getOffset()

      const maxModalHeight = window.innerHeight - MODAL_PADDING_TOTAL
      const maxModalWidth = window.innerWidth - MODAL_PADDING_TOTAL
      const maxInnerHeight = maxModalHeight - offset - (this.props.fitModalBody.verticalPadding ?? 0)
      const maxInnerWidth = maxModalWidth - (this.props.fitModalBody.horizontalPadding ?? 0)
      const element = document.getElementById(this.props.fitModalBody.id) as HTMLElement
      if (element) {
        let scrollBar = 0
        if (maxInnerHeight < this.props.fitModalBody.suggestedHeight) {
          scrollBar = 20 // max width of scroll bar in most browsers
          element.style.height = `${maxInnerHeight}px`
        } else {
          element.style.height = `${this.props.fitModalBody.suggestedHeight}px`
        }

        if (maxInnerWidth < this.props.fitModalBody.suggestedWidth + scrollBar) {
          element.style.width = `${maxInnerWidth}px`
        } else {
          element.style.width = `${this.props.fitModalBody.suggestedWidth + scrollBar}px`
        }
      }
    }
  }

  getOffset() {
    const footer = getDivFromSelect(this.modalRef.current, '.modal-footer')

    let offset = 0
    if (this.headerRef.current) {
      offset += this.headerRef.current.offsetHeight
    }
    if (this.subHeaderRef.current) {
      offset += this.subHeaderRef.current.offsetHeight
    }
    if (footer) {
      offset += footer.offsetHeight
    }
    return offset
  }

  getBodyHeight = () => {
    let bodyHeight = 0
    if (this.props.useCustomScrollAreas && this.modalRef.current) {
      const childDivs = Array.from(this.modalRef.current.querySelectorAll(`.${this.props.useCustomScrollAreas}`))
      for (const childDiv of childDivs) {
        if (documentUtils.isInstanceOfHtmlElement(childDiv) && childDiv.scrollHeight > bodyHeight) {
          bodyHeight = childDiv.scrollHeight
        }
      }
    } else {
      const bodyInner = getDivFromSelect(this.modalRef.current, '.modal-body__inner')
      if (bodyInner) {
        bodyHeight = bodyInner.scrollHeight + 30
      }
    }
    return bodyHeight
  }

  getModalInstanceBody = () => {
    const {
      fullScreen = false,
      allowFullScreen = false,
      dataTest = 'modal',
      className,
      header,
      subHeader,
      children,
      noPadding,
      useCustomScrollAreas,
      noScroll,
      clear,
      useLargeHeader,
      showOverflow = false,
      paddingV2 = false,
    } = this.props
    return (
      <div
        ref={this.modalRef}
        className={classNames(rootClass, className, {
          [`${rootClass}--full-screen`]: fullScreen,
          [`${rootClass}--allow-full-screen`]: allowFullScreen,
          [`${rootClass}--no-padding`]: noPadding,
          [`${rootClass}--no-scroll`]: noScroll,
          [`${rootClass}--custom-scroll-areas`]: useCustomScrollAreas !== undefined,
          [`${rootClass}--clear`]: clear,
          [`${rootClass}--show-overflow`]: showOverflow,
          [`${rootClass}--padding-v2`]: paddingV2,
        })}
        style={{ zIndex: MODAL_BASE_Z_INDEX + this.state.index + 1 }}
        data-test={dataTest}
        aria-modal="true"
      >
        {header && (
          <div
            ref={this.headerRef}
            className={classNames('modal-header', {
              [`${rootClass}--large-header`]: useLargeHeader,
            })}
          >
            {header}
          </div>
        )}
        {subHeader && (
          <div ref={this.subHeaderRef} className="modal-sub-header">
            {subHeader}
          </div>
        )}
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
        <span tabIndex={0}></span>
        {children}
      </div>
    )
  }

  render() {
    return this.getModalInstanceBody()
  }
}

export default ModalInstance
