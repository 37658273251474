import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Input, { InputType } from '@components/Input/Input'
import Loader, { LoaderTypes } from '@components/Loader/Loader'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { formatNumber, getFormattedNumber } from '@utils/numbers'

import './CreateAccountModal.css'

interface CreateAccountModalProps {
  isOpen: boolean
  maxContacts: number
  className?: string
  dataTest?: string
  onClose: () => void
  onAction: (accountInfo: CreateAccountModalState) => void
}

export interface CreateAccountModalState {
  parentAccountId: string
  name: string
  street1: string
  street2: string
  city: string
  state: string
  zipCode: string
  country: string
  activeContacts?: number
}

const defaultState: CreateAccountModalState = {
  parentAccountId: '-1',
  name: '',
  street1: '',
  street2: '',
  city: '',
  state: '',
  zipCode: '',
  country: '',
}

interface ErrorState {
  negativeError: boolean
  maxError: boolean
  nan: boolean
}

const MAX_NAME_LENGTH = 140

const rootClass = 'create-account-modal'

const CreateAccountModal: FC<CreateAccountModalProps> = (props: CreateAccountModalProps) => {
  const { dataTest = rootClass, className = '', onAction, onClose, isOpen, maxContacts } = props
  const [account, setAccount] = useState<CreateAccountModalState>(defaultState)
  const { name, street1, street2, city, state, country, zipCode, activeContacts } = account
  const [canSubmit, setCanSubmit] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorState, setErrorState] = useState<ErrorState>({
    negativeError: false,
    maxError: false,
    nan: false,
  })

  const { t } = useTranslation()

  useEffect(() => {
    const isSubmittable = name !== '' && activeContacts !== undefined && activeContacts > -1 && activeContacts <= maxContacts
    setCanSubmit(isSubmittable)
  }, [account, activeContacts, maxContacts, name])

  const handleAction = () => {
    setIsSubmitting(true)
    onAction(account)
  }

  const handleClose = () => {
    setAccount(defaultState)
    onClose()
  }

  const onChange = (field: keyof CreateAccountModalState, value: string) => {
    setAccount({ ...account, [field]: value })
  }

  const onChangeActiveContacts = (value: any) => {
    setAccount({ ...account, activeContacts: value })
    setErrorState({ negativeError: value < 0, maxError: value > maxContacts, nan: isNaN(value) })
  }

  const header = (
    <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__header`}>
      {t('Create new account')}
    </ModalHeader>
  )

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
      <ModalBody className={`${rootClass}__body`}>
        <Input
          value={name}
          required
          label={t('Account name')}
          placeholder={t('Account name')}
          maxlength={MAX_NAME_LENGTH}
          onChange={({ target: { value } }) => onChange('name', value)}
          className={`${rootClass}__first`}
          dataTest={`${dataTest}-name`}
        />
        {name.length === MAX_NAME_LENGTH && <Typography text={t('CreateAccount.Name.MaxLength')} type={TextType.ERROR} />}
        <Input
          value={street1}
          label={t('Street address')}
          placeholder={t('Street address line 1')}
          onChange={({ target: { value } }) => onChange('street1', value)}
        />
        <Input
          value={street2}
          label={t('Street address 2')}
          placeholder={t('Street address line 2')}
          onChange={({ target: { value } }) => onChange('street2', value)}
        />
        <div className={`${rootClass}__row`}>
          <Input
            value={city}
            label={t('City')}
            placeholder={t('City')}
            onChange={({ target: { value } }) => onChange('city', value)}
            className={`${rootClass}__row-input`}
          />
          <Input
            value={state}
            label={t('State')}
            placeholder={t('State')}
            onChange={({ target: { value } }) => onChange('state', value)}
            className={`${rootClass}__row-input`}
          />
        </div>
        <div className={`${rootClass}__row`}>
          <Input
            value={zipCode}
            label={t('ZIP code')}
            placeholder={t('ZIP/Postal code')}
            onChange={({ target: { value } }) => onChange('zipCode', value)}
            className={`${rootClass}__row-input`}
          />
          <Input
            value={country}
            label={t('Country')}
            placeholder={t('Country')}
            onChange={({ target: { value } }) => onChange('country', value)}
            className={`${rootClass}__row-input`}
          />
        </div>
        <div className={`${rootClass}__divider`} />
        <Input
          value={activeContacts?.toString()}
          required
          label={t('Assign active contacts')}
          inputType={InputType.QUANTITY}
          type={'number'}
          error={errorState.negativeError || errorState.maxError || errorState.nan}
          placeholder={t('Active contacts')}
          onChange={({ target: { value } }) => onChangeActiveContacts(parseInt(value))}
          dataTest={`${dataTest}-active-contacts`}
        >
          {!errorState.maxError && !errorState.negativeError && !errorState.nan && (
            <Typography
              text={t(`CreateAccount.ActiveContacts.Helper`, { count: getFormattedNumber(maxContacts) as any })}
              type={TextType.BODY_TEXT_SMALL_LIGHT}
              tagProps={{
                bold: { weight: TextWeight.BOLD, inline: true },
              }}
              className={`${rootClass}__helper`}
            />
          )}
          {errorState.nan && (
            <Typography
              text={t('AccountManagement.ActiveContacts.NaNError', { count: formatNumber(maxContacts) })}
              type={TextType.ERROR}
              tagProps={{
                bold: { weight: TextWeight.BOLD, inline: true },
              }}
              dataTest={`${dataTest}-nan-error`}
            />
          )}
          {errorState.negativeError && (
            <Typography
              text={t('AccountManagement.ActiveContacts.NegativeError')}
              type={TextType.ERROR}
              tagProps={{
                bold: { weight: TextWeight.BOLD, inline: true },
              }}
              dataTest={`${dataTest}-negative-error`}
            />
          )}
          {errorState.maxError && (
            <Typography
              text={t('AccountManagement.ActiveContacts.MaxError', { count: formatNumber(maxContacts) })}
              type={TextType.ERROR}
              tagProps={{
                bold: { weight: TextWeight.BOLD, inline: true },
              }}
              dataTest={`${dataTest}-max-error`}
            />
          )}
        </Input>
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form} className={`${rootClass}__footer`}>
        <Button buttonType={ButtonType.TERTIARY} disabled={isSubmitting} onClick={handleClose} dataTest={`${dataTest}-button-tertiary`}>
          {t('Cancel')}
        </Button>
        <Button
          buttonType={ButtonType.PRIMARY}
          onClick={handleAction}
          disabled={!canSubmit}
          dataTest={`${dataTest}-button-primary`}
          className={classNames(`${rootClass}__submit-button`, {
            [`${rootClass}__submit-button-submitting`]: isSubmitting,
          })}
        >
          {!isSubmitting && t('Create')}
          {isSubmitting && <Loader loaderType={LoaderTypes.row} className={`${rootClass}__submit-loader`} />}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CreateAccountModal
