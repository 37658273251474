import React, { FC, useCallback, useEffect, useState } from 'react'

import Input from '@components/Input/Input'
import Label from '@components/Label'
import Modal, { ModalBody } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import ModalFooterV2 from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import ModalHeaderV2 from '@components/Modal/components/ModalHeaderV2/ModalHeaderV2'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './URLGroupModal.css'

interface Props {
  isOpen: boolean
  onClose: () => void
  onAddEdit: (name: string, isEdit: boolean) => void
  name?: string
  dataTest?: string
}

const rootClass = 'url-group-modal'

const URLGroupModal: FC<Props> = ({ isOpen, name = '', onAddEdit, onClose, dataTest = rootClass }: Props) => {
  const { t } = useTranslation()
  const [_name, setName] = useState<string>(name)
  const isEdit = !!name
  const disableSave = !_name || _name === name

  useEffect(() => {
    isOpen && setName(name)
  }, [name, isOpen])

  const handleSave = useCallback(() => onAddEdit(_name, isEdit), [_name, onAddEdit, isEdit])
  const handleClose = useCallback(() => {
    setName(name)
    onClose()
  }, [onClose, name])

  const handleURLChange = useCallback((event: { target: { value: React.SetStateAction<string> } }) => setName(event.target.value), [])

  return (
    <Modal
      paddingV2
      isOpen={isOpen}
      className={rootClass}
      dataTest={dataTest}
      header={
        <ModalHeaderV2
          headerType={ModalHeaderType.Form}
          headerText={t(`${isEdit ? 'Edit' : 'Add'}.URL.Group.Modal.Title`)}
          dataTest={`${dataTest}-header`}
        />
      }
    >
      <ModalBody>
        <Typography text={t('URL.Group.Modal.Description')} type={TextType.BODY_TEXT_LIGHT} />
        <Label className={`${rootClass}-label`}>{t('Name of usage context')}</Label>
        <Input placeholder={t('e.g. Campaign Name')} defaultValue={name} onChange={handleURLChange} dataTest={`${dataTest}-input`} />
      </ModalBody>
      <ModalFooterV2
        footerType={ModalFooterType.Form}
        onClose={handleClose}
        buttons={{
          cancelButtonLabel: t('Cancel'),
          actionButtonLabel: t(isEdit ? 'Save' : 'Create URL group'),
          actionButtonDisabled: disableSave,
          actionButtonOnClick: handleSave,
        }}
        dataTest={`${dataTest}-footer`}
      />
    </Modal>
  )
}

export default URLGroupModal
