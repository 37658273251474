import {
  getUnifiedListAllSegmentsUtils,
  getUnifiedListCreatedByMeUtils,
  getUnifiedListCRMSourced,
  getUnifiedListFavoritesUtils,
  getUnifiedListRecentsUtils,
} from '@complex/ListPickerModalV2/utils/clients/ListPickerModalCategorizationClientUtils'
import {
  GetAllItemsRequestType,
  GetCreatedByMeSegmentsRequestType,
  GetFavoriteSegmentsRequestType,
  GetRecentSegmentsRequestType,
} from '@complex/ListPickerModalV2/utils/graphql/ListPickerModalCategorizationRequests.graphQL'
import { ListPickerEmptyListingOptions } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import { FilterTypes } from '@utils/filter'
import { LIST_CATEGORY_FILTERS, ListCategory } from '@utils/lists'

export const UCLEmptyListingFiltersOptions: { [key: string]: ListPickerEmptyListingOptions } = {
  [LIST_CATEGORY_FILTERS[ListCategory.UNIFIED_LIST]]: ListPickerEmptyListingOptions.EMPTY_OTHERS,
  [FilterTypes.FAVORITES]: ListPickerEmptyListingOptions.EMPTY_FAVORITES,
  [FilterTypes.RECENT]: ListPickerEmptyListingOptions.EMPTY_RECENT,
  [FilterTypes.CREATED_BY_ME]: ListPickerEmptyListingOptions.EMPTY_CREATED_BY_ME,
  [FilterTypes.CRM_SOURCED]: ListPickerEmptyListingOptions.EMPTY_CRM_SOURCED,
}

export const getFilterRequest = (
  name: string,
  getAllItemsRequest: GetAllItemsRequestType,
  getFavoriteSegmentsRequest: GetFavoriteSegmentsRequestType,
  getRecentSegmentsRequest: GetRecentSegmentsRequestType,
  getCreatedByMeSegmentsRequest: GetCreatedByMeSegmentsRequestType
) => {
  const filterRequests = {
    [LIST_CATEGORY_FILTERS[ListCategory.UNIFIED_LIST]]: getAllItemsRequest,
    [LIST_CATEGORY_FILTERS[ListCategory.FORM_SUBMISSIONS_UCL]]: getAllItemsRequest,
    [LIST_CATEGORY_FILTERS[ListCategory.WEBINAR_UCL]]: getAllItemsRequest,
    [FilterTypes.FAVORITES]: getFavoriteSegmentsRequest,
    [FilterTypes.RECENT]: getRecentSegmentsRequest,
    [FilterTypes.CREATED_BY_ME]: getCreatedByMeSegmentsRequest,
    [FilterTypes.CONTACT_PREFERENCES]: getAllItemsRequest,
    [FilterTypes.CRM_SOURCED]: getAllItemsRequest,
  }
  return filterRequests[name]
}

export const UCLFiltersActions: { [key: string]: Function } = {
  [LIST_CATEGORY_FILTERS[ListCategory.UNIFIED_LIST]]: getUnifiedListAllSegmentsUtils,
  [LIST_CATEGORY_FILTERS[ListCategory.FORM_SUBMISSIONS_UCL]]: getUnifiedListAllSegmentsUtils,
  [LIST_CATEGORY_FILTERS[ListCategory.WEBINAR_UCL]]: getUnifiedListAllSegmentsUtils,
  [FilterTypes.FAVORITES]: getUnifiedListFavoritesUtils,
  [FilterTypes.RECENT]: getUnifiedListRecentsUtils,
  [FilterTypes.CREATED_BY_ME]: getUnifiedListCreatedByMeUtils,
  [FilterTypes.CONTACT_PREFERENCES]: getUnifiedListAllSegmentsUtils,
  [FilterTypes.CRM_SOURCED]: getUnifiedListCRMSourced,
}

export const getEmptyListingFiltersOption = (filter: string) =>
  filter === FilterTypes.FAVORITES ? ListPickerEmptyListingOptions.EMPTY_FAVORITES : ListPickerEmptyListingOptions.EMPTY_OTHERS
