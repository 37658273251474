import { useCallback } from 'react'

import { useApolloClient } from '@apollo/client'
import createEmailContentEditSession from '@graphql/microservices/email-management/createEmailContentEditSession'
import deleteEmailComposerRow from '@graphql/microservices/email-management/deleteEmailComposerRow'
import getEmailComposerRows from '@graphql/microservices/email-management/getEmailComposerRows'
import getEmailComposerRowsCategories from '@graphql/microservices/email-management/getEmailComposerRowsCategories'
import getLastEmailContentEditSession from '@graphql/microservices/email-management/getLastEmailContentEditSession'
import saveEmailComposerRow from '@graphql/microservices/email-management/saveEmailComposerRow'
import updateEmailComposerRow from '@graphql/microservices/email-management/updateEmailComposerRow'
import updateEmailContentEditSession from '@graphql/microservices/email-management/updateEmailContentEditSession'
import getDataCards from '@graphql/microservices/segment/getDataCards'
import createActOnCampaign from '@graphql/mutations/createActOnCampaign'
import createEmptyFormAutoResponseBeeMessage from '@graphql/mutations/createEmptyFormAutoResponseBeeMessage'
import createNewMessage from '@graphql/mutations/createNewMessage'
import createSubscriptionCategory from '@graphql/mutations/createSubscriptionCategory'
import deleteEmailDraft from '@graphql/mutations/deleteEmailDraft'
import discardEmailChanges from '@graphql/mutations/discardEmailChanges'
import eteSendToDeveloper from '@graphql/mutations/eteSendToDeveloper'
import saveEmail from '@graphql/mutations/saveEmail'
import stopABTest from '@graphql/mutations/stopAbTestMessage'
import unscheduleWebinar from '@graphql/mutations/unScheduleWebinarMessage'
import updateMessageErrorsCount from '@graphql/mutations/updateMessageErrorsCount'
import campaigns from '@graphql/queries/campaigns'
import checkSubjectAndPreviewText from '@graphql/queries/checkSubjectAndPreviewText'
import deliverabilityConcern from '@graphql/queries/deliverabilityConcern'
import getAllSalesUsers from '@graphql/queries/getAllSalesUsers'
import getAnalyticsSettings from '@graphql/queries/getAnalyticsSettings'
import getContactsFromCrmAccounts from '@graphql/queries/getContactsFromCrmAccounts'
import getContactsFromCrmEmails from '@graphql/queries/getContactsFromCrmEmails'
import getContactsFromCrmFilter from '@graphql/queries/getContactsFromCrmFilter'
import getEmailContentValidations from '@graphql/queries/getEmailContentValidations'
import getETEEmailDetails from '@graphql/queries/getETEEmailDetails'
import getListContacts from '@graphql/queries/getListContacts'
import getLitmusPreviewBlockedImage from '@graphql/queries/getLitmusPreviewBlockedImage'
import getLitmusPreviews from '@graphql/queries/getLitmusPreviews'
import getMarketingUser from '@graphql/queries/getMarketingUser'
import getMessagePlainText from '@graphql/queries/getMessagePlainText'
import getOptOutLinks from '@graphql/queries/getOptOutLinks'
import getSubscriptionCategories from '@graphql/queries/getSubscriptionCategories'
import getViewInBrowserUrl from '@graphql/queries/getViewInBrowserUrl'
import loadFromAddresses from '@graphql/queries/loadFromAddressesPage'
import personalizeEmailMessage from '@graphql/queries/personalizeEmailMessage'
import retrieveClassicAssetFoldersByType from '@graphql/queries/retrieveClassicAssetFoldersByType'
import retrieveMessage from '@graphql/queries/retrieveMessage'
import sendMessage from '@graphql/queries/sendMessage'
import testUrls from '@graphql/queries/testUrls'
import validateEmailMessage from '@graphql/queries/validateEmailMessage'
import validateEmailSender from '@graphql/queries/validateEmailSender'
import {
  CreateEmailContentEditSessionMutation,
  CreateEmailContentEditSessionMutationVariables,
  DeleteEmailComposerRowMutation,
  DeleteEmailComposerRowMutationVariables,
  GetEmailComposerRowsCategoriesQuery,
  GetEmailComposerRowsCategoriesQueryVariables,
  GetEmailComposerRowsQuery,
  GetEmailComposerRowsQueryVariables,
  GetLastEmailContentEditSessionQuery,
  GetLastEmailContentEditSessionQueryVariables,
  MutationSaveEmailComposerRowArgs,
  SaveEmailComposerRowMutation,
  SaveEmailComposerRowMutationVariables,
  UpdateEmailComposerRowMutation,
  UpdateEmailComposerRowMutationVariables,
  UpdateEmailContentEditSessionMutation,
  UpdateEmailContentEditSessionMutationVariables,
} from '@graphql/types/microservice/email-management-types'
import { GetDataCardsQuery, GetDataCardsQueryVariables } from '@graphql/types/microservice/segment-types'
import {
  CreateActOnCampaignMutation,
  CreateActOnCampaignMutationVariables,
  CreateEmptyFormAutoResponseBeeMessageMutation,
  CreateEmptyFormAutoResponseBeeMessageMutationVariables,
  CreateNewMessageMutation,
  CreateNewMessageMutationVariables,
  CreateNewMessageResponse,
  CreateSubscriptionCategoryMutation,
  CreateSubscriptionCategoryMutationVariables,
  DeleteEmailDraftsMutation,
  DeleteEmailDraftsMutationVariables,
  DiscardEmailChangesMutation,
  DiscardEmailChangesMutationVariables,
  EteSendToDeveloperMutation,
  EteSendToDeveloperMutationVariables,
  SaveEmailMutation,
  StopAbTestMessageMutation,
  StopAbTestMessageMutationVariables,
  UnScheduleWebinarMessageMutation,
  UnScheduleWebinarMessageMutationVariables,
  UpdateMessageErrorsCountMutation,
  UpdateMessageErrorsCountMutationVariables,
} from '@graphql/types/mutation-types'
import {
  CampaignsQuery,
  CampaignsQueryVariables,
  CheckSubjectAndPreviewTextQuery,
  CheckSubjectAndPreviewTextQueryVariables,
  DeliverabilityConcernQuery,
  DeliverabilityConcernQueryVariables,
  EmailContentValidationsResponse,
  GetAllSalesUsersQuery,
  GetAllSalesUsersQueryVariables,
  GetAnalyticsSettingsQuery,
  GetAnalyticsSettingsQueryVariables,
  GetContactsFromCrmAccountsQuery,
  GetContactsFromCrmAccountsQueryVariables,
  GetContactsFromCrmEmailsQuery,
  GetContactsFromCrmEmailsQueryVariables,
  GetContactsFromCrmFilterQuery,
  GetContactsFromCrmFilterQueryVariables,
  GetEmailContentValidationsQuery,
  GetEmailContentValidationsQueryVariables,
  GetEteEmailDetailsQuery,
  GetEteEmailDetailsQueryVariables,
  GetListContactsQuery,
  GetLitmusPreviewBlockedImageQuery,
  GetLitmusPreviewBlockedImageQueryVariables,
  GetLitmusPreviewsQuery,
  GetLitmusPreviewsQueryVariables,
  GetMarketingUserQuery,
  GetMarketingUserQueryVariables,
  GetMessagePlainTextQuery,
  GetMessagePlainTextQueryVariables,
  GetOptOutLinksQuery,
  GetOptOutLinksQueryVariables,
  GetSubscriptionCategoriesQuery,
  GetSubscriptionCategoriesQueryVariables,
  GetViewInBrowserUrlQuery,
  GetViewInBrowserUrlQueryVariables,
  LoadFromAddressesPageQuery,
  LoadFromAddressesPageQueryVariables,
  PersonalizeEmailMessageQuery,
  PersonalizeEmailMessageQueryVariables,
  QueryGetListContactsArgs,
  RetrieveClassicAssetFoldersByTypeQuery,
  RetrieveClassicAssetFoldersByTypeQueryVariables,
  RetrieveMessageQuery,
  RetrieveMessageQueryVariables,
  SendMessageQuery,
  SendMessageQueryVariables,
  TestUrlsQuery,
  TestUrlsQueryVariables,
  ValidateEmailMessageQuery,
  ValidateEmailMessageQueryVariables,
  ValidateEmailSenderQuery,
  ValidateEmailSenderQueryVariables,
} from '@graphql/types/query-types'
import { ModifiedMutationSaveEmailArgs, OmitMessageType } from '@utils/composer/commonComposer/types'
import { CreateMessageProps } from '@utils/composer/emailComposer/types'
import { logError } from '@utils/env'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export interface EmailComposerRequests {
  loadFromAddressesRequest: (variables: LoadFromAddressesPageQueryVariables) => FetchPromise<LoadFromAddressesPageQuery>
  getAnalyticsSettingsRequest: (variables: GetAnalyticsSettingsQueryVariables) => FetchPromise<GetAnalyticsSettingsQuery>
  getCampaignsRequest: (variables: CampaignsQueryVariables) => FetchPromise<CampaignsQuery>
  getSubscriptionCategoriesRequest: (variables: GetSubscriptionCategoriesQueryVariables) => FetchPromise<GetSubscriptionCategoriesQuery>
  createSubscriptionCategoryRequest: (variables: CreateSubscriptionCategoryMutationVariables) => FetchPromise<CreateSubscriptionCategoryMutation>
  createCampaignRequest: (variables: CreateActOnCampaignMutationVariables) => FetchPromise<CreateActOnCampaignMutation>
  getPersonalizeEmailMessageRequest: (variables: PersonalizeEmailMessageQueryVariables) => FetchPromise<PersonalizeEmailMessageQuery>
  getLitmusPreviewsRequest: (variables: GetLitmusPreviewsQueryVariables) => FetchPromise<GetLitmusPreviewsQuery>
  getListContactsRequest: (variables: QueryGetListContactsArgs) => FetchPromise<GetListContactsQuery>
  validateEmailMessageRequest: (variables: ValidateEmailMessageQueryVariables) => FetchPromise<ValidateEmailMessageQuery>
  getMessagePlainTextRequest: (variables: GetMessagePlainTextQueryVariables) => FetchPromise<GetMessagePlainTextQuery>
  deliverabilityConcernRequest: (variables: DeliverabilityConcernQueryVariables) => FetchPromise<DeliverabilityConcernQuery>
  testUrlsRequest: (variables: TestUrlsQueryVariables) => FetchPromise<TestUrlsQuery>
  getActiveContactsRequest: () => FetchPromise<GetDataCardsQuery>
  getLastEmailContentEditSessionRequest: (
    variables: GetLastEmailContentEditSessionQueryVariables
  ) => FetchPromise<GetLastEmailContentEditSessionQuery>
  createEmailContentEditSessionRequest: (
    variables: CreateEmailContentEditSessionMutationVariables
  ) => FetchPromise<CreateEmailContentEditSessionMutation>
  saveComposerEmailRequest: (emailVariables: ModifiedMutationSaveEmailArgs) => Promise<boolean>
  createMessage: (props: CreateMessageProps) => Promise<CreateNewMessageResponse>
  getLitmusPreviewBlockedImageRequest: (variables: GetLitmusPreviewBlockedImageQueryVariables) => FetchPromise<GetLitmusPreviewBlockedImageQuery>
  deleteEmailDrafts: (draftIds: string | string[]) => Promise<DeleteEmailDraftsMutation | undefined>
  getEmailMessage: (messageId: string) => FetchPromise<RetrieveMessageQuery>
  sendMessageRequest: (variables: SendMessageQueryVariables) => FetchPromise<SendMessageQuery>
  validateSenderRequest: (variables: ValidateEmailSenderQueryVariables) => FetchPromise<ValidateEmailSenderQuery>
  checkSubjectAndPreviewTextRequest: (variables: CheckSubjectAndPreviewTextQueryVariables) => FetchPromise<CheckSubjectAndPreviewTextQuery>
  getMarketingUserRequest: () => FetchPromise<GetMarketingUserQuery>
  getAllSalesUsersRequest: () => FetchPromise<GetAllSalesUsersQuery>
  retrieveClassicAssetFoldersByTypeRequest: (
    variables: RetrieveClassicAssetFoldersByTypeQueryVariables
  ) => FetchPromise<RetrieveClassicAssetFoldersByTypeQuery>
  getOptOutLinksRequest: () => FetchPromise<GetOptOutLinksQuery>
  deleteSavedRowRequest: (id: number) => FetchPromise<DeleteEmailComposerRowMutation>
  updateSavedRowRequest: (id: number, name: string) => FetchPromise<UpdateEmailComposerRowMutation>
  getSavedRowCategoriesRequest: () => FetchPromise<GetEmailComposerRowsCategoriesQuery>
  getSavedRowsRequest: (categoryId: string, isDefaultCategory: boolean) => FetchPromise<GetEmailComposerRowsQuery>
  saveSavedRowRequest: (variables: MutationSaveEmailComposerRowArgs) => FetchPromise<SaveEmailComposerRowMutation>
  createEmptyFormAutoResponseBeeMsg: (id: string, isCustomHtml: boolean) => Promise<string | undefined>
  getEmailContentValidationsRequest: (vars: GetEmailContentValidationsQueryVariables) => Promise<EmailContentValidationsResponse>
  getContactsFromCrmFilterRequest: (vars: GetContactsFromCrmFilterQueryVariables) => FetchPromise<GetContactsFromCrmFilterQuery>
  getContactsFromCrmAccountsRequest: (vars: GetContactsFromCrmAccountsQueryVariables) => FetchPromise<GetContactsFromCrmAccountsQuery>
  getContactsFromCrmEmailsRequest: (vars: GetContactsFromCrmEmailsQueryVariables) => FetchPromise<GetContactsFromCrmEmailsQuery>
  stopAbTestMessageRequest: (messageId: string) => Promise<StopAbTestMessageMutation | undefined>
  discardEmailChangesRequest: (messageId: string) => Promise<DiscardEmailChangesMutation | undefined>
  unscheduleWebinarMessage: (variables: UnScheduleWebinarMessageMutationVariables) => Promise<UnScheduleWebinarMessageMutation | undefined>
  updateMessageErrorsCountRequest: (variables: UpdateMessageErrorsCountMutationVariables) => Promise<UpdateMessageErrorsCountMutation | undefined>
  sendApiDetailsToDeveloperRequest: (variables: EteSendToDeveloperMutationVariables) => Promise<EteSendToDeveloperMutation | undefined>
  getEteDetailsRequest: (variables: GetEteEmailDetailsQueryVariables) => FetchPromise<GetEteEmailDetailsQuery>
  updateEmailContentEditSessionRequest: (id: string) => FetchPromise<UpdateEmailContentEditSessionMutation>
  getViewInBrowserUrlRequest: () => FetchPromise<GetViewInBrowserUrlQuery>
}

export const useEmailComposerRequests = (): EmailComposerRequests => {
  const actonClient = useApolloClient()
  const { client: segmentServiceClient } = useMicroserviceClient({ serviceName: MicroserviceClients.SEGMENT })
  const { client: emailManagementClient } = useMicroserviceClient({ serviceName: MicroserviceClients.EMAIL_MANAGEMENT_PROXY })

  const loadFromAddressesRequest = useCallback(
    async (variables: LoadFromAddressesPageQueryVariables) =>
      actonClient.query<LoadFromAddressesPageQuery, LoadFromAddressesPageQueryVariables>({
        query: loadFromAddresses,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables,
      }),
    [actonClient]
  )

  const getCampaignsRequest = useCallback(
    async (variables: CampaignsQueryVariables) =>
      actonClient.query<CampaignsQuery, CampaignsQueryVariables>({
        query: campaigns,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables,
      }),
    [actonClient]
  )

  const createCampaignRequest = useCallback(
    async (variables: CreateActOnCampaignMutationVariables) =>
      actonClient.mutate<CreateActOnCampaignMutation, CreateActOnCampaignMutationVariables>({
        mutation: createActOnCampaign,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables,
      }),
    [actonClient]
  )

  const getSubscriptionCategoriesRequest = useCallback(
    async (variables: GetSubscriptionCategoriesQueryVariables) =>
      actonClient.query<GetSubscriptionCategoriesQuery, GetSubscriptionCategoriesQueryVariables>({
        query: getSubscriptionCategories,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables,
      }),
    [actonClient]
  )

  const createSubscriptionCategoryRequest = useCallback(
    async (variables: CreateSubscriptionCategoryMutationVariables) =>
      actonClient.mutate<CreateSubscriptionCategoryMutation, CreateSubscriptionCategoryMutationVariables>({
        mutation: createSubscriptionCategory,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables,
      }),
    [actonClient]
  )

  const getAnalyticsSettingsRequest = useCallback(
    async (variables: GetAnalyticsSettingsQueryVariables) =>
      actonClient.query<GetAnalyticsSettingsQuery, GetAnalyticsSettingsQueryVariables>({
        query: getAnalyticsSettings,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables,
      }),
    [actonClient]
  )

  const getPersonalizeEmailMessageRequest = useCallback(
    async (variables: PersonalizeEmailMessageQueryVariables) =>
      actonClient.query<PersonalizeEmailMessageQuery, PersonalizeEmailMessageQueryVariables>({
        query: personalizeEmailMessage,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables,
      }),
    [actonClient]
  )

  const getLitmusPreviewsRequest = useCallback(
    async (variables: GetLitmusPreviewsQueryVariables) =>
      actonClient.query<GetLitmusPreviewsQuery, GetLitmusPreviewsQueryVariables>({
        query: getLitmusPreviews,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables,
      }),
    [actonClient]
  )

  const getListContactsRequest = useCallback(
    async (variables: QueryGetListContactsArgs) =>
      actonClient.query<GetListContactsQuery, QueryGetListContactsArgs>({
        query: getListContacts,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables,
      }),
    [actonClient]
  )

  const validateEmailMessageRequest = useCallback(
    async (variables: ValidateEmailMessageQueryVariables) =>
      actonClient.query<ValidateEmailMessageQuery, ValidateEmailMessageQueryVariables>({
        query: validateEmailMessage,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables,
      }),
    [actonClient]
  )

  const getMessagePlainTextRequest = useCallback(
    async (variables: GetMessagePlainTextQueryVariables) =>
      actonClient.query<GetMessagePlainTextQuery, GetMessagePlainTextQueryVariables>({
        query: getMessagePlainText,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables,
      }),
    [actonClient]
  )

  const deliverabilityConcernRequest = useCallback(
    async (variables: DeliverabilityConcernQueryVariables) =>
      actonClient.query<DeliverabilityConcernQuery, DeliverabilityConcernQueryVariables>({
        query: deliverabilityConcern,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables,
      }),
    [actonClient]
  )

  const testUrlsRequest = useCallback(
    async (variables: TestUrlsQueryVariables) =>
      actonClient
        .query<TestUrlsQuery, TestUrlsQueryVariables>({
          query: testUrls,
          errorPolicy: 'all',
          fetchPolicy: 'network-only',
          variables,
        })
        .catch((errors) => {
          logError(errors)
          return { data: undefined, errors: errors }
        }),
    [actonClient]
  )

  const getActiveContactsRequest = useCallback(
    async () =>
      segmentServiceClient.query<GetDataCardsQuery, GetDataCardsQueryVariables>({
        query: getDataCards,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
      }),
    [segmentServiceClient]
  )

  const getLastEmailContentEditSessionRequest = useCallback(
    async (variables: GetLastEmailContentEditSessionQueryVariables) =>
      emailManagementClient.query<GetLastEmailContentEditSessionQuery, GetLastEmailContentEditSessionQueryVariables>({
        query: getLastEmailContentEditSession,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables,
      }),
    [emailManagementClient]
  )

  const createEmailContentEditSessionRequest = useCallback(
    async (variables: CreateEmailContentEditSessionMutationVariables) =>
      emailManagementClient.mutate<CreateEmailContentEditSessionMutation, CreateEmailContentEditSessionMutationVariables>({
        mutation: createEmailContentEditSession,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables,
      }),
    [emailManagementClient]
  )

  const createMessage = useCallback(
    async (props: CreateMessageProps) => {
      const {
        messageType,
        fromDefault = false,
        isPlainTextOnly = false,
        startId = '',
        beeBlankSetup = false,
        isCustomHtml = false,
        assetReportRecipientDetails,
      } = props
      const result = await actonClient
        .mutate<CreateNewMessageMutation, OmitMessageType<CreateNewMessageMutationVariables>>({
          mutation: createNewMessage,
          errorPolicy: 'all',
          fetchPolicy: 'network-only',
          variables: {
            messageType,
            fromDefault,
            isCustomHtml,
            isPlainTextOnly,
            startId: startId || undefined,
            beeBlankSetup,
            assetReportRecipientDetails,
          },
        })
        .catch((errors) => {
          logError(errors)
          return { data: undefined, errors: errors }
        })

      return result?.data?.createNewMessage ?? { id: '' }
    },
    [actonClient]
  )

  const createEmptyFormAutoResponseBeeMsg = useCallback(
    async (id: string, isCustomHtml: boolean) => {
      const result = await actonClient
        .mutate<CreateEmptyFormAutoResponseBeeMessageMutation, CreateEmptyFormAutoResponseBeeMessageMutationVariables>({
          mutation: createEmptyFormAutoResponseBeeMessage,
          errorPolicy: 'all',
          fetchPolicy: 'network-only',
          variables: { formId: id, isCustomHtml },
        })
        .catch((errors) => {
          logError(errors)
          return { data: undefined, errors: errors }
        })

      return result?.data?.createEmptyFormAutoResponseBeeMessage?.msgId
    },
    [actonClient]
  )

  const saveComposerEmailRequest = useCallback(
    async (emailVariables: ModifiedMutationSaveEmailArgs) => {
      const saveEmailContent = async (variables: ModifiedMutationSaveEmailArgs) => {
        const result = await actonClient
          .mutate<SaveEmailMutation, ModifiedMutationSaveEmailArgs>({
            mutation: saveEmail,
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            variables,
          })
          .catch((errors) => {
            logError(errors)
            return { data: undefined, errors }
          })

        return !!result.data?.saveEmail
      }

      try {
        return await saveEmailContent(emailVariables)
      } catch (error) {
        logError(error)
        return false
      }
    },
    [actonClient]
  )

  const getLitmusPreviewBlockedImageRequest = useCallback(
    async (variables: GetLitmusPreviewBlockedImageQueryVariables) =>
      actonClient.query<GetLitmusPreviewBlockedImageQuery, GetLitmusPreviewBlockedImageQueryVariables>({
        query: getLitmusPreviewBlockedImage,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables,
      }),
    [actonClient]
  )

  const deleteEmailDrafts = useCallback(
    async (draftIds: string | string[]) => {
      const result = await actonClient.mutate<DeleteEmailDraftsMutation, DeleteEmailDraftsMutationVariables>({
        mutation: deleteEmailDraft,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables: { draftIds },
      })

      if (result.data) {
        return result.data
      } else {
        logError(result.errors)
      }
    },
    [actonClient]
  )

  const getEmailMessage = useCallback(
    async (messageId: string) =>
      actonClient
        .query<RetrieveMessageQuery, RetrieveMessageQueryVariables>({
          query: retrieveMessage,
          errorPolicy: 'all',
          fetchPolicy: 'network-only',
          variables: { messageId },
        })
        .catch((errors) => {
          logError(errors)
          return { data: undefined, errors }
        }),
    [actonClient]
  )

  const sendMessageRequest = useCallback(
    async (variables: SendMessageQueryVariables) =>
      actonClient
        .query<SendMessageQuery, SendMessageQueryVariables>({
          query: sendMessage,
          errorPolicy: 'all',
          fetchPolicy: 'network-only',
          variables,
        })
        .catch((errors) => {
          logError(errors)
          return { data: undefined, errors: errors }
        }),
    [actonClient]
  )

  const validateSenderRequest = useCallback(
    async (variables: ValidateEmailSenderQueryVariables) =>
      actonClient
        .query<ValidateEmailSenderQuery, ValidateEmailSenderQueryVariables>({
          query: validateEmailSender,
          errorPolicy: 'all',
          fetchPolicy: 'network-only',
          variables,
        })
        .catch((errors) => {
          logError(errors)
          return { data: undefined, errors: errors }
        }),
    [actonClient]
  )

  const checkSubjectAndPreviewTextRequest = useCallback(
    async (variables: CheckSubjectAndPreviewTextQueryVariables) =>
      actonClient
        .query<CheckSubjectAndPreviewTextQuery, CheckSubjectAndPreviewTextQueryVariables>({
          query: checkSubjectAndPreviewText,
          errorPolicy: 'all',
          fetchPolicy: 'network-only',
          variables,
        })
        .catch(() => ({ data: undefined, errors: [] })),
    [actonClient]
  )

  const getMarketingUserRequest = useCallback(
    async () =>
      actonClient.query<GetMarketingUserQuery, GetMarketingUserQueryVariables>({
        query: getMarketingUser,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
      }),
    [actonClient]
  )

  const getAllSalesUsersRequest = useCallback(
    async () =>
      actonClient.query<GetAllSalesUsersQuery, GetAllSalesUsersQueryVariables>({
        query: getAllSalesUsers,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: { sortColumn: 'FULL_NAME', sortDirection: 'ASC' },
      }),
    [actonClient]
  )

  const retrieveClassicAssetFoldersByTypeRequest = useCallback(
    async (variables: RetrieveClassicAssetFoldersByTypeQueryVariables) =>
      actonClient.query<RetrieveClassicAssetFoldersByTypeQuery, RetrieveClassicAssetFoldersByTypeQueryVariables>({
        query: retrieveClassicAssetFoldersByType,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables,
      }),
    [actonClient]
  )

  const getOptOutLinksRequest = useCallback(
    async () =>
      actonClient.query<GetOptOutLinksQuery, GetOptOutLinksQueryVariables>({
        query: getOptOutLinks,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables: {},
      }),
    [actonClient]
  )

  const deleteSavedRowRequest = useCallback(
    async (id: number) => {
      return emailManagementClient.mutate<DeleteEmailComposerRowMutation, DeleteEmailComposerRowMutationVariables>({
        mutation: deleteEmailComposerRow,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables: { id },
      })
    },
    [emailManagementClient]
  )

  const updateSavedRowRequest = useCallback(
    async (id: number, name: string) => {
      return emailManagementClient.mutate<UpdateEmailComposerRowMutation, UpdateEmailComposerRowMutationVariables>({
        mutation: updateEmailComposerRow,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables: { id, name },
      })
    },
    [emailManagementClient]
  )

  const getSavedRowCategoriesRequest = useCallback(async () => {
    return emailManagementClient.query<GetEmailComposerRowsCategoriesQuery, GetEmailComposerRowsCategoriesQueryVariables>({
      query: getEmailComposerRowsCategories,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    })
  }, [emailManagementClient])

  const getSavedRowsRequest = useCallback(
    async (categoryId: string, isDefaultCategory: boolean) => {
      return emailManagementClient.query<GetEmailComposerRowsQuery, GetEmailComposerRowsQueryVariables>({
        query: getEmailComposerRows,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables: { categoryId, isDefaultCategory },
      })
    },
    [emailManagementClient]
  )

  const saveSavedRowRequest = useCallback(
    async (variables: MutationSaveEmailComposerRowArgs) => {
      return emailManagementClient.mutate<SaveEmailComposerRowMutation, SaveEmailComposerRowMutationVariables>({
        mutation: saveEmailComposerRow,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables,
      })
    },
    [emailManagementClient]
  )
  const getEmailContentValidationsRequest = useCallback(
    (variables: GetEmailContentValidationsQueryVariables) =>
      actonClient
        .query<GetEmailContentValidationsQuery, GetEmailContentValidationsQueryVariables>({
          query: getEmailContentValidations,
          errorPolicy: 'all',
          fetchPolicy: 'network-only',
          variables,
        })
        .then(({ data }) => (data?.getEmailContentValidations ? data.getEmailContentValidations : Promise.reject())),
    [actonClient]
  )

  const getContactsFromCrmFilterRequest = useCallback(
    async (variables: GetContactsFromCrmFilterQueryVariables) =>
      actonClient.query<GetContactsFromCrmFilterQuery, GetContactsFromCrmFilterQueryVariables>({
        query: getContactsFromCrmFilter,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables,
      }),
    [actonClient]
  )

  const getContactsFromCrmAccountsRequest = useCallback(
    async (variables: GetContactsFromCrmAccountsQueryVariables) =>
      actonClient.query<GetContactsFromCrmAccountsQuery, GetContactsFromCrmAccountsQueryVariables>({
        query: getContactsFromCrmAccounts,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables,
      }),
    [actonClient]
  )

  const getContactsFromCrmEmailsRequest = useCallback(
    async (variables: GetContactsFromCrmEmailsQueryVariables) =>
      actonClient.query<GetContactsFromCrmEmailsQuery, GetContactsFromCrmEmailsQueryVariables>({
        query: getContactsFromCrmEmails,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables,
      }),
    [actonClient]
  )

  const stopAbTestMessageRequest = useCallback(
    async (messageId: string) => {
      const result = await actonClient.mutate<StopAbTestMessageMutation, StopAbTestMessageMutationVariables>({
        mutation: stopABTest,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables: { messageId },
      })
      if (result.data) {
        return result.data
      } else {
        logError(result.errors)
      }
    },
    [actonClient]
  )

  const discardEmailChangesRequest = useCallback(
    async (messageId: string) => {
      const result = await actonClient.mutate<DiscardEmailChangesMutation, DiscardEmailChangesMutationVariables>({
        mutation: discardEmailChanges,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables: { messageId },
      })
      if (result.data) {
        return result.data
      } else {
        logError(result.errors)
      }
    },
    [actonClient]
  )

  const unscheduleWebinarMessage = useCallback(
    async (variables: UnScheduleWebinarMessageMutationVariables) => {
      const result = await actonClient.mutate<UnScheduleWebinarMessageMutation, UnScheduleWebinarMessageMutationVariables>({
        mutation: unscheduleWebinar,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables,
      })
      if (result.data) {
        return result.data
      } else {
        logError(result.errors)
      }
    },
    [actonClient]
  )

  const updateMessageErrorsCountRequest = useCallback(
    async (variables: UpdateMessageErrorsCountMutationVariables) => {
      const result = await actonClient.mutate<UpdateMessageErrorsCountMutation, UpdateMessageErrorsCountMutationVariables>({
        mutation: updateMessageErrorsCount,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables,
      })
      if (result.data) {
        return result.data
      } else {
        logError(result.errors)
      }
    },
    [actonClient]
  )

  const sendApiDetailsToDeveloperRequest = useCallback(
    async (variables: EteSendToDeveloperMutationVariables) => {
      const result = await actonClient.mutate<EteSendToDeveloperMutation, EteSendToDeveloperMutationVariables>({
        mutation: eteSendToDeveloper,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables,
      })
      if (result.data) {
        return result.data
      } else {
        logError(result.errors)
      }
    },
    [actonClient]
  )

  const getEteDetailsRequest = useCallback(
    async (variables: GetEteEmailDetailsQueryVariables) =>
      actonClient.query<GetEteEmailDetailsQuery, GetEteEmailDetailsQueryVariables>({
        query: getETEEmailDetails,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables,
      }),
    [actonClient]
  )

  const updateEmailContentEditSessionRequest = useCallback(
    async (id: string) =>
      emailManagementClient.mutate<UpdateEmailContentEditSessionMutation, UpdateEmailContentEditSessionMutationVariables>({
        mutation: updateEmailContentEditSession,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables: { id },
      }),
    [emailManagementClient]
  )

  const getViewInBrowserUrlRequest = useCallback(
    async () =>
      actonClient.query<GetViewInBrowserUrlQuery, GetViewInBrowserUrlQueryVariables>({
        query: getViewInBrowserUrl,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables: {},
      }),
    [actonClient]
  )

  return {
    createCampaignRequest,
    createSubscriptionCategoryRequest,
    getSubscriptionCategoriesRequest,
    getCampaignsRequest,
    loadFromAddressesRequest,
    getAnalyticsSettingsRequest,
    getPersonalizeEmailMessageRequest,
    getLitmusPreviewsRequest,
    getListContactsRequest,
    validateEmailMessageRequest,
    getMessagePlainTextRequest,
    deliverabilityConcernRequest,
    testUrlsRequest,
    getActiveContactsRequest,
    getLastEmailContentEditSessionRequest,
    createEmailContentEditSessionRequest,
    saveComposerEmailRequest,
    createMessage,
    getLitmusPreviewBlockedImageRequest,
    deleteEmailDrafts,
    getEmailMessage,
    sendMessageRequest,
    validateSenderRequest,
    checkSubjectAndPreviewTextRequest,
    getMarketingUserRequest,
    getAllSalesUsersRequest,
    retrieveClassicAssetFoldersByTypeRequest,
    getOptOutLinksRequest,
    deleteSavedRowRequest,
    updateSavedRowRequest,
    getSavedRowCategoriesRequest,
    getSavedRowsRequest,
    saveSavedRowRequest,
    createEmptyFormAutoResponseBeeMsg,
    getEmailContentValidationsRequest,
    getContactsFromCrmFilterRequest,
    getContactsFromCrmAccountsRequest,
    getContactsFromCrmEmailsRequest,
    stopAbTestMessageRequest,
    discardEmailChangesRequest,
    unscheduleWebinarMessage,
    updateMessageErrorsCountRequest,
    sendApiDetailsToDeveloperRequest,
    getEteDetailsRequest,
    updateEmailContentEditSessionRequest,
    getViewInBrowserUrlRequest,
  }
}
