import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/Button'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import RecipientInfo from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertRecipients/components/RecipientInfo/RecipientInfo'
import GroupHeader from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/GroupHeader/GroupHeader'
import { useAccountSettings } from '@utils/account/account.utils'

import './RecipientsList.css'

export enum RecipientsGroup {
  ADMIN = 'Admin group',
  MARKETING = 'Marketing group',
  SALES = 'Sales group',
}

interface RecipientAction {
  icon: SvgNames
  isSelected?: boolean
  tooltip: string
  onClick: () => void
}

export interface Recipient {
  id: number
  name: string
  email: string
  userType: string
  isMuted?: boolean
  group?: RecipientsGroup
}

interface RecipientsListProps {
  className?: string
  dataTest?: string
  recipients: Recipient[]
  onMute: (recipient: Recipient, mute: boolean) => void
  onRemove: (recipients: Recipient[]) => void
  readOnly?: boolean
}

const rootClass = 'recipients-list'

const RecipientsList: FC<RecipientsListProps> = (props: RecipientsListProps) => {
  const { dataTest = rootClass, className = '', recipients, readOnly = false, onMute, onRemove } = props

  const { userEmail } = useAccountSettings()

  const { t } = useTranslation()

  const renderActionButtons = (actions: RecipientAction[], recipientId: number) =>
    actions.map(({ icon, isSelected = false, tooltip, onClick }) => (
      <Tooltip
        key={`action-${icon}-${recipientId}`}
        position={'bottom'}
        trigger={
          <Button
            buttonType={ButtonType.ICON}
            className={classNames(`${rootClass}__action`, { [`${rootClass}__action-selected`]: isSelected })}
            onClick={onClick}
          >
            <Svg name={icon} type={SvgType.ICON} className={`${rootClass}__action-icon`} />
          </Button>
        }
      >
        {t(tooltip)}
      </Tooltip>
    ))

  const renderRecipientRow = (recipient: Recipient) => {
    const { id, name, email, isMuted = false, userType } = recipient

    const actions: RecipientAction[] = [
      {
        icon: isMuted ? SvgNames.bellOff : SvgNames.bellRing,
        isSelected: isMuted,
        tooltip: isMuted ? 'Unmute' : 'Mute alert',
        onClick: () => onMute(recipient, !isMuted),
      },
      {
        icon: SvgNames.close,
        tooltip: 'Remove',
        onClick: () => onRemove([recipient]),
      },
    ]

    return (
      <div className={`${rootClass}__recipient`} key={`recipient-${id}`}>
        <RecipientInfo
          className={`${rootClass}__recipient-info`}
          name={name}
          description={email}
          isMuted={isMuted}
          isCurrentUser={email === userEmail}
        />
        <div className={`${rootClass}__recipient-right-section`}>
          <Typography text={t(userType)} type={TextType.BODY_TEXT_SMALL_LIGHT} lineHeight={LineHeight.MEDIUM_SMALL} />
          {!readOnly && <div className={`${rootClass}__action-container`}>{renderActionButtons(actions, id)}</div>}
        </div>
      </div>
    )
  }

  const getSortedRecipientsMap = (recipients: Recipient[]) => {
    return recipients.reduce((recipients: Map<string, Recipient[]>, recipient: Recipient) => {
      const recipientGroup = recipient.group ?? 'default'
      return new Map(recipients.set(recipientGroup, [...(recipients.get(recipientGroup) ?? []), recipient]))
    }, new Map())
  }

  const renderRecipientsSortedList = () => {
    const sortedRecipientsMap = getSortedRecipientsMap(recipients)
    return Array.from(sortedRecipientsMap.entries()).map(([group, recipients]) => {
      const recipientsRows = recipients.map(renderRecipientRow)
      return group === 'default' ? (
        recipientsRows
      ) : (
        <GroupHeader
          className={`${rootClass}__group-header`}
          key={`group-header-${group}`}
          label={t(group).toUpperCase()}
          onReload={() => undefined}
          onDelete={() => onRemove(recipients)}
          defaultExpanded
          hasBoldLabel
          reloadTooltip={t(
            'This is a synced list. All users added to this group or user type will automatically be subscribed to receive this alert.'
          )}
          readOnly={readOnly}
        >
          {recipientsRows}
        </GroupHeader>
      )
    })
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {renderRecipientsSortedList()}
    </div>
  )
}

export default RecipientsList
