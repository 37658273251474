import React, { FC, ReactNode, useEffect, useState } from 'react'

import classNames from 'classnames'
import { useDebouncedCallback } from 'use-debounce'

import Button, { ButtonIconPosition, ButtonProps, ButtonType } from '@components/Button/Button'
import CaretIcon, { CaretIconDirection } from '@components/CaretIcon'
import DropDown from '@components/DropDown'
import { DropDownProps, DropDownType } from '@components/DropDown/DropDown'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight, TypographyProps } from '@components/Typography/Typography'
import { DropdownMenuItem, DropdownMenuLabel } from '@radix-ui/react-dropdown-menu'
import { toTrainCase } from '@utils/strings'

import './TriggerButton.css'

const rootClass = 'trigger-button'

export interface TriggerButtonOption {
  text: string
  label?: ReactNode
  icon?: SvgNames
  disabled?: boolean
  disabledTooltip?: string
  hidden?: boolean
  onClick: () => void
}

interface TriggerButtonProps extends Omit<ButtonProps, 'children'> {
  label: string
  options: TriggerButtonOption[]
  triggerOnHover?: boolean
  contentClassName?: string
  onDropDownToggle?: (isOpen: boolean) => void
  /** An offset to align dropdown with trigger **/
  alignRight?: boolean
  caretFill?: SvgColor
  typographyProps?: Partial<TypographyProps>
  dropDownProps?: Partial<DropDownProps>
  showCaretIcon?: boolean
  svgName?: SvgNames
  svgIcon?: SvgType
  useLabel?: boolean
  dropDownClassName?: string
}

const TriggerButton: FC<TriggerButtonProps> = (props: TriggerButtonProps) => {
  const {
    label,
    buttonType = ButtonType.SECONDARY,
    options,
    disabled,
    triggerOnHover = false,
    onDropDownToggle,
    className = '',
    dataTest = rootClass,
    dropDownClassName = '',
    contentClassName,
    alignRight,
    caretFill,
    typographyProps,
    dropDownProps,
    showCaretIcon = true,
    svgIcon,
    svgName,
    iconPosition = ButtonIconPosition.RIGHT,
    //use this to apply button default styles
    useLabel,
  } = props
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    onDropDownToggle && onDropDownToggle(isOpen)
  }, [isOpen])

  const onClick = (elemOnClick: () => void) => {
    setIsOpen(false)
    elemOnClick()
  }

  const trigger = (
    <Button
      buttonType={buttonType}
      iconPosition={iconPosition}
      forceHover={isOpen}
      className={classNames(className, { [`${rootClass}--open`]: isOpen })}
    >
      {svgName && svgIcon && <Svg name={svgName} type={svgIcon} />}
      {useLabel ? label : <Typography text={label} type={TextType.BODY_TEXT_WHITE} weight={TextWeight.MEDIUM} {...typographyProps} inline />}
      {showCaretIcon && <CaretIcon direction={CaretIconDirection.DOWN} className={`${rootClass}__caret-button`} fill={caretFill ?? SvgColor.WHITE} />}
    </Button>
  )

  const renderOption = (option: TriggerButtonOption) => (
    <DropdownMenuItem
      key={toTrainCase(option.text)}
      disabled={option.disabled}
      onSelect={() => onClick(option.onClick)}
      data-test={`${dataTest}-option-${toTrainCase(option.text)}`}
      className={`${rootClass}__menu-item`}
    >
      <DropdownMenuLabel className={classNames(`${rootClass}__dropdown-label`, [{ [`${rootClass}__dropdown-label-flex`]: option.label }])}>
        <div>
          {option.icon && <Svg name={option.icon} type={SvgType.LARGER_ICON} className={`${rootClass}__item-svg`} />}
          <Typography text={option.text} inline />
        </div>
        {option.label}
      </DropdownMenuLabel>
    </DropdownMenuItem>
  )

  const renderDisabledOption = (option: TriggerButtonOption) => (
    <Tooltip trigger={renderOption(option)} key={option.icon} position={'left'}>
      {option.disabledTooltip}
    </Tooltip>
  )

  // Avoid a race condition when MouseLeave triggers after MouseEnter
  const debouncedSetIsOpen = useDebouncedCallback((isOpen) => !disabled && setIsOpen(isOpen), 10)
  const hoverProps = triggerOnHover ? { onMouseEnter: () => debouncedSetIsOpen(true), onMouseLeave: () => debouncedSetIsOpen(false) } : {}

  return (
    <div {...hoverProps} className={`${rootClass}__pointer-event`}>
      <DropDown
        isOpen={isOpen}
        toggleOpen={(isOpen) => !triggerOnHover && setIsOpen(isOpen)}
        label={label}
        trigger={trigger}
        type={DropDownType.STYLED}
        alignRight={alignRight}
        className={classNames(rootClass, dropDownClassName)}
        contentClassName={classNames({ [`${contentClassName}`]: contentClassName })}
        dataTest={dataTest}
        {...dropDownProps}
      >
        {options.map((option) => (option.hidden ? undefined : option.disabled ? renderDisabledOption(option) : renderOption(option)))}
      </DropDown>
    </div>
  )
}

export default TriggerButton
