import React from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import ClickToCopy from '@components/ClickToCopy/ClickToCopy'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { RowAction } from '@components/TableV2/tableV2TS/interfaces'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Toggle from '@components/Toggle'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { IndexSignature } from '@interface/common'
import { encodingLabels, hashAlgorithmLabels, webhookSourcesValues } from '@src/pages/Webhooks/IncomingWebhooks/IncomingWebhooks.constants'
import {
  CustomWebhookSignatureOptions,
  Encoding,
  IncomingWebhook,
  IncomingWebhooksRowActions,
  SignatureHashAlgorithm,
  SignatureTemplate,
  SignatureTemplateList,
  Source,
} from '@src/pages/Webhooks/IncomingWebhooks/IncomingWebhooksContext'
import { Row } from '@tanstack/react-table'

const rootClass = 'incoming-webhooks'

export const orderByStatus = <T extends {}>(prev: Row<T>, curr: Row<T>, columnId: string) => {
  const prevName =
    prev.original[columnId as IndexSignature]?.toString().toLowerCase() > curr.original[columnId as IndexSignature]?.toString().toLowerCase()
  const currName =
    prev.original[columnId as IndexSignature]?.toString().toLowerCase() < curr.original[columnId as IndexSignature]?.toString().toLowerCase()
  const prevTested =
    prev.original['tested' as IndexSignature]?.toString().toLowerCase() < curr.original['tested' as IndexSignature]?.toString().toLowerCase()
  const actualTested =
    prev.original['tested' as IndexSignature]?.toString().toLowerCase() > curr.original['tested' as IndexSignature]?.toString().toLowerCase()

  const prevEnabled =
    prev.original['enabled' as IndexSignature]?.toString().toLowerCase() < curr.original['enabled' as IndexSignature]?.toString().toLowerCase()
  const actualEnabled =
    prev.original['enabled' as IndexSignature]?.toString().toLowerCase() > curr.original['enabled' as IndexSignature]?.toString().toLowerCase()

  if (prevTested && !actualTested) {
    return 1
  } else if (!prevTested && actualTested) {
    return -1
  } else if (prevEnabled && !actualEnabled) {
    return 1
  } else if (!prevEnabled && !actualEnabled) {
    return -1
  } else if (prevName && !currName) {
    return 1
  } else if (!prevName && currName) {
    return -1
  } else {
    return 0
  }
}

export const buildLastSuccessfulRequest = (timeZoneId: string | undefined, lastRequest: number) => {
  const timezone = timeZoneId ?? undefined
  const year = new Date(lastRequest).toLocaleDateString([], { year: 'numeric', ...(timezone !== undefined ? { timezone: timeZoneId } : undefined) })
  const date = new Date(lastRequest).toLocaleDateString([], {
    month: 'short',
    day: 'numeric',
    ...(timezone !== undefined ? { timezone: timeZoneId } : undefined),
  })

  const unformattedTz = new Date(lastRequest).toLocaleTimeString([], {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: timeZoneId ?? undefined,
  })

  return `${date}, ${year} at ${unformattedTz}`
}

export const getIncomingWebhooksColumns = (
  onStatusChange: Function,
  onTestClick: Function,
  t: Function,
  timeZoneId: string | undefined
): ColumnDefWithAdditionalProps<IncomingWebhook>[] => [
  {
    accessorKey: 'name',
    header: () => t('Name'),
    sortingFn: 'caseInsensitive',
    textAlign: 'left',
    maxSize: 150,
    cell: ({ row }) => getWebhookNameContent(row.original),
    enableClickableCell: true,
    enableCustomCellValue: true,
  },
  {
    accessorKey: 'enabled',
    header: () => t('Status'),
    sortingFn: 'caseInsensitive',
    textAlign: 'right',
    minSize: 100,
    cell: ({ row }) => getWebhookStatusContent(row, onStatusChange, t),
    enableClickableCell: true,
    enableCustomCellValue: true,
  },
  {
    accessorKey: 'url',
    header: () => t('Webhook URL'),
    textAlign: 'left',
    minSize: 420,
    cell: ({ row }) => (row.original.tested ? renderColCopyURL(row.original, row.original.url) : renderTestButton(row, onTestClick, t)),
    enableClickableCell: true,
    sortingFn: orderByStatus,
  },
  {
    accessorKey: 'source',
    header: () => t('Source'),
    textAlign: 'left',
    minSize: 140,
    cell: ({ row }) => renderColWithDisabled(row.original, getSourceLabel(row.original.source)),
    enableClickableCell: true,
  },
  {
    accessorKey: 'lastRequest',
    header: () => t('Last Successful Request'),
    textAlign: 'right',
    minSize: 150,
    cell: ({ row }) =>
      renderColWithDisabled(row.original, row.original.lastRequest ? buildLastSuccessfulRequest(timeZoneId, row.original.lastRequest) : ''),
    enableClickableCell: true,
  },
]

export const getIncomingWebhooksRowActions = (
  onActionClick: (action: IncomingWebhooksRowActions, row: Row<IncomingWebhook>) => void
): RowAction<IncomingWebhook>[] => [
  {
    label: IncomingWebhooksRowActions.EDIT,
    icon: SvgNames.pencil,
    onClick: (row) => onActionClick(IncomingWebhooksRowActions.EDIT, row as Row<IncomingWebhook>),
  },
  {
    label: IncomingWebhooksRowActions.TEST,
    icon: SvgNames.test,
    onClick: (row) => onActionClick(IncomingWebhooksRowActions.TEST, row as Row<IncomingWebhook>),
  },
  {
    label: IncomingWebhooksRowActions.COPY,
    icon: SvgNames.clipboardCheckColorless,
    inDropdown: true,
    hasTooltip: false,
    disabled: (row) => !(row as Row<IncomingWebhook>).original.tested,
    onClick: (row) => onActionClick(IncomingWebhooksRowActions.COPY, row as Row<IncomingWebhook>),
  },
  {
    label: IncomingWebhooksRowActions.DELETE,
    icon: SvgNames.delete,
    inDropdown: true,
    onClick: (row) => onActionClick(IncomingWebhooksRowActions.DELETE, row as Row<IncomingWebhook>),
  },
]

export const getWebhookStatusContent = (row: Row<IncomingWebhook>, onStatusChange: Function, t: Function) => {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={classNames(`${rootClass}__webhook-status-wrapper`, {
        [`${rootClass}__not-tested`]: !row.original.tested,
      })}
      onClick={(e) => e.stopPropagation()}
    >
      {!row.original.tested ? (
        renderNotTestedStatus(t)
      ) : (
        <Toggle isOn={row.original.enabled} onToggle={(enabled: boolean) => onStatusChange(row, enabled)} />
      )}
    </div>
  )
}

export const renderNotTestedStatus = (t: Function) => {
  const content = <Toggle isOn={false} onToggle={() => false} />
  return (
    <Tooltip className={`${rootClass}__not-tested-tooltip`} withoutTail trigger={content}>
      {t('Incoming.Webhooks.Not.Tested')}
    </Tooltip>
  )
}

export const getWebhookNameContent = (webhook: IncomingWebhook) => {
  return (
    <div
      className={classNames(`${rootClass}__webhook-name-wrapper`, {
        [`${rootClass}__disabled`]: !webhook.enabled || !webhook.tested,
      })}
    >
      <div className={classNames(`${rootClass}__webhook-icon`)}>
        <Svg type={SvgType.VERY_LARGE_ICON} name={getIcon(webhook.source)} />
      </div>
      <div className={classNames(`${rootClass}__webhook-name`, 'ellip')}>
        <Typography text={webhook.name} type={TextType.BODY_TEXT} weight={TextWeight.MEDIUM} className={'ellip'} />
        {webhook.description && (
          <Typography
            text={webhook.description}
            type={TextType.BODY_TEXT_SMALL_LIGHT}
            className={classNames(`${rootClass}__webhook-description`, `ellip`)}
          />
        )}
      </div>
    </div>
  )
}

export const renderColWithDisabled = (webhook: IncomingWebhook, text: string) => {
  return (
    <div className={classNames({ [`${rootClass}__disabled`]: !webhook.enabled || !webhook.tested })}>
      <TextWithTooltipOnEllip
        tooltipProps={{
          position: 'bottom',
        }}
        typographyProps={{
          text: text,
        }}
      />
    </div>
  )
}
export const renderColCopyURL = (webhook: IncomingWebhook, text: string) => {
  return (
    <div className={classNames({ [`${rootClass}__disabled`]: !webhook.enabled || !webhook.tested })}>
      <ClickToCopy
        value={text}
        copyClicked={(event) => {
          event.stopPropagation()
        }}
        copyText="Copy URL"
        text={<Typography className={'ellip'} text={text} inline weight={TextWeight.REGULAR} type={TextType.BODY_TEXT} />}
      />
    </div>
  )
}

export const renderTestButton = (row: Row<IncomingWebhook>, onTestClick: Function, t: Function) => {
  return (
    <Button
      className={`${rootClass}__test-button`}
      buttonType={ButtonType.INFO}
      onClick={(event) => {
        event.stopPropagation()
        onTestClick(row)
      }}
    >
      <Svg name={SvgNames.test} />
      {t('Incoming.Webhooks.RowActions.Test')}
    </Button>
  )
}

const getIcon = (source: String) => {
  switch (source) {
    case Source.Calendly:
      return SvgNames.calendlyLogo
    case Source.Custom:
      return SvgNames.webhooks
    default:
      return SvgNames.webhooks
  }
}

const getSourceLabel = (source: String) => {
  switch (source) {
    case Source.Calendly:
      return 'Calendly'
    case Source.Custom:
      return 'Custom'
    default:
      return 'Custom'
  }
}

export const buildSignatureTemplateList = (signatureTemplates: SignatureTemplate[]) =>
  signatureTemplates.reduce((templates, template) => {
    const source = webhookSourcesValues[template?.name as string]
    return { ...templates, [source]: { ...template, source } as SignatureTemplate }
  }, {}) as SignatureTemplateList

export const buildCustomWebhookSignatureOptions = ({ hashAlgorithms, keyEncodings }: SignatureTemplate): CustomWebhookSignatureOptions => {
  let hashAlgorithmOptions = hashAlgorithms?.map((hash) => ({
    label: hashAlgorithmLabels[hash as SignatureHashAlgorithm],
    value: hash,
  })) as SelectV2SingleOption[]
  const md5Index = hashAlgorithmOptions.findIndex(({ value }) => value === SignatureHashAlgorithm.Md5)
  hashAlgorithmOptions = [...hashAlgorithmOptions.slice(0, md5Index), ...hashAlgorithmOptions.slice(md5Index + 1), hashAlgorithmOptions[md5Index]]

  const encodingOptions = (keyEncodings ?? [])?.map((encoding) => ({
    label: encodingLabels[encoding as Encoding],
    value: encoding,
  })) as SelectV2SingleOption[]

  return { encodingOptions, hashAlgorithmOptions }
}
