import { gql } from '@apollo/client'

export default gql`
  query getOptOutLinks {
    getOptOutLinks {
      standard
      preferences
      direct
    }
  }
`
