import React, { FC, RefObject, useCallback, useContext, useMemo } from 'react'

import CollapsibleMenu from '@components/CollapsibleMenu/CollapsibleMenu'
import { useTranslation } from '@const/globals'
import { MENU_ITEM_INDEX } from '@src/pages/ContactSegments/utils/ContactSegments.constants'
import { getContactSegmentsMenuItems } from '@src/pages/ContactSegments/utils/ContactSegmentsMenuItems'
import { ContactSegmentsSession } from '@utils/contactSegments/contactSegments.utils'
import { ContactSegmentsContext } from '@utils/contactSegments/context/ContactSegmentsContext'
import { allSegmentsFilter, FilterTypes } from '@utils/filter'
import { getFolderCount } from '@utils/folderUtils'
import useCRM from '@utils/hooks/useCRM'
import { MenuActions } from '@utils/menuItems'
import { setItem } from '@utils/sessionStorage'

interface Props {
  className?: string
  dataTest?: string
  callMenuAction: (action: MenuActions, data?: any) => void
  hasCollapsedMenu: boolean
  outerScrollAreaRef?: RefObject<HTMLDivElement>
  reference?: RefObject<HTMLDivElement>
}

const rootClass = 'contact-segments-collapsible-menu'

const ContactSegmentsCollapsibleMenu: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '', hasCollapsedMenu, callMenuAction, outerScrollAreaRef, reference } = props
  const {
    values: { activeFolderId, activeTagId, filterActive, tags, folderPath, folders, hasToExpandFolders },
    setFilter,
    update,
  } = useContext(ContactSegmentsContext)

  const { t } = useTranslation()

  const { hasCRMConnected } = useCRM()

  const folderCount = useMemo(() => getFolderCount(folders), [folders])
  const defaultOpenedFolders = useMemo(() => folderPath.map(({ id }) => id).slice(0, -1), [folderPath])

  const clearFoldersToExpand = useCallback(() => update({ hasToExpandFolders: [] }), [])

  const itemMenuActive = activeFolderId
    ? MENU_ITEM_INDEX.FOLDERS
    : activeTagId
    ? MENU_ITEM_INDEX.TAGS
    : filterActive?.name === FilterTypes.CONTACT_PREFERENCES || filterActive?.name === FilterTypes.CRM_SOURCED
    ? MENU_ITEM_INDEX.MORE_FILTERS
    : MENU_ITEM_INDEX.FILTERS

  const items = (isCollapsed: boolean) =>
    getContactSegmentsMenuItems(
      {
        activeFolderId,
        activeTagId,
        customFilters: [allSegmentsFilter],
        dataTest,
        defaultOpenedFolders,
        hasToExpandFolders,
        clearFoldersToExpand,
        callMenuAction,
        filterAction: setFilter,
        filterActive,
        folderCount,
        folders,
        foldersEmptyListingText: 'Organize your segments with folders.',
        isCollapsed,
        rootClass: className,
        t,
        tags,
        tagsEmptyListingText: 'Keep track of your segments with tags.',
      },
      !hasCRMConnected
    )

  return (
    <CollapsibleMenu
      className={`${className}__menu`}
      header={'Segments'}
      indexActive={itemMenuActive}
      items={items}
      onCollapse={(collapsed) => setItem(ContactSegmentsSession.COLLAPSED_MENU, `${collapsed}`)}
      isCollapsed={hasCollapsedMenu}
      reference={reference}
      outerScrollAreaRef={outerScrollAreaRef}
      topMarginMin={84}
    />
  )
}

export default ContactSegmentsCollapsibleMenu
