import isAccountRegistered from '@graphql/microservices/ml-audience/isAccountRegistered'
import { IsAccountRegisteredQuery, IsAccountRegisteredQueryVariables } from '@graphql/types/microservice/ml-audience-types'
import { useAccountSettings } from '@utils/account/account.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'

interface UseAIAudienceInsightsResponse {
  isRegistered: boolean
  loading: boolean
}

export const useAIAudienceInsights = (): UseAIAudienceInsightsResponse => {
  const { accountId } = useAccountSettings()

  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.ML_AUDIENCE })

  const { data, loading } = useQueryOnMount<IsAccountRegisteredQuery, IsAccountRegisteredQueryVariables>(isAccountRegistered, {
    client,
    fetchPolicy: 'network-only',
    variables: {
      accountId,
    },
  })

  return {
    isRegistered: !!data?.isAccountRegistered,
    loading,
  }
}
