import {
  contactPreferencesOptions,
  Delimiter,
  FirstLineOption,
  ImportContactsRejectRules,
  MergeStrategy,
  Quote,
  UpsertContactSetting,
} from '@complex/ImportContacts/ImportContacts.constants'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { ProgramSchedule } from '@graphql/types/query-types'

export type ImportOptions = {
  addToSegment: boolean
  contactPreference: SelectV2SingleOption
  externalIdMatch?: SelectV2SingleOption | null
  fieldQuotes: Quote
  fieldsSeparator: Delimiter
  firstLineContains: FirstLineOption
  loadingMappingScreen?: boolean
  mergeStrategy: MergeStrategy
  mergeRule: ColumnMergeType
  objectType?: string
  rejectRules: ImportContactsRejectRules
  removeEntriesFromList: boolean
  schedule: ProgramSchedule
  segmentId?: string
  segmentOptions?: SelectV2SingleOption[]
  segmentSelectedOption?: SelectV2SingleOption
  updateAction: UpsertContactSetting
  useCustomPrefix: boolean
  validSegmentName: boolean
}

export enum ImportContactsSources {
  FTP = 'FTP',
  // For local .csv files or existing lists
  LOCAL = 'LOCAL',
}

export enum ImportContactsSteps {
  SELECT_FILE = 'Import file',
  IMPORT_OPTIONS = 'Import options',
  MAP_FIELDS = 'Map fields',
  REVIEW = 'Review import',
  IMPORT_IN_PROGRESS = 'Import in progress',
  UPLOAD_IN_PROGRESS = 'Upload in progress',
}

export const HEADER_TEXT: { [key: string]: string } = {
  [ImportContactsSources.FTP]: 'ImportContactsV2.Header.FTPText',
  [ImportContactsSources.LOCAL]: 'ImportContactsV2.Header.LocalText',
}

export enum DataType {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Email = 'EMAIL',
  MultiText = 'MULTI_TEXT',
  Number = 'NUMBER',
  Score = 'SCORE',
  Text = 'TEXT',
}

export enum ColumnMergeType {
  IfEmpty = 'IF_EMPTY',
  Overwrite = 'OVERWRITE',
}

export const EXTERNAL_ID_FIELD_KEY = 'ao.externalId'

export const importOptionsDefaultValues: ImportOptions = {
  addToSegment: false,
  validSegmentName: true,
  schedule: {
    ampm: 1,
    hour: 10,
    interval: 6,
    minute: 0,
    type: 'WEEKLY',
    weekdaysOnly: false,
    hasEndTime: false,
    isScheduled: true,
    isUnscheduled: false,
  },
  firstLineContains: FirstLineOption.NAMES,
  fieldsSeparator: Delimiter.Comma,
  fieldQuotes: Quote.SimpleQuote,
  updateAction: UpsertContactSetting.OldestContact,
  mergeRule: ColumnMergeType.IfEmpty,
  rejectRules: { rejectOptOut: false, rejectHardBounces: false, rejectSuppressedDomains: false },
  removeEntriesFromList: false,
  contactPreference: contactPreferencesOptions[0],
  segmentSelectedOption: undefined,
  loadingMappingScreen: undefined,
  mergeStrategy: MergeStrategy.Email,
  useCustomPrefix: true,
}
