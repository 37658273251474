import React, { FC, ReactNode, useContext, useEffect, useMemo } from 'react'
import { Row } from 'react-table'

import { DeleteConfirmationModals } from '@complex/ListingPage/Components/ListingPageModals/ListingPageModals'
import ListPageTable, { ListPageTableProps } from '@complex/ListingPage/Components/ListingPageTable/ListPageTable/ListPageTable'
import { ListPageRowActions, TableActions, TagManagerParams } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.constants'
import { getAllHeaderActions, getAllRowActions, isInvalidTagName } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.utils'
import { getFolderById } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { ListingPageCommonContext, OnSetFavoriteItems, TABLEV2_ENABLED_LISTINGS } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { useHeaderActionRequests } from '@complex/ListingPage/GraphQL/HeaderActions.graphQL'
import { useTagRequests } from '@complex/ListingPage/GraphQL/Tags.graphQL'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { Status } from '@components/StatusToast/StatusToast'
import { renderClickableCol } from '@components/Table/components/tableColumns'
import { TableColumn } from '@components/Table/Table'
import { useTranslation } from '@const/globals'
import { ItemDto, LabelDto } from '@graphql/types/microservice/categorization-types'
import { ItemType } from '@utils/categorization'
import { FilterTypes } from '@utils/filter'
import { getItem, removeItem, setItem } from '@utils/sessionStorage'

import { ListingPageSession } from '../../Utils/ListingPage.utils'

import './ListPageTableContainer.css'

interface ListPageTableContainerProps {
  className?: string
  canShareToCatalog?: boolean
  reference?: React.RefObject<HTMLDivElement>
  scrollableElement?: React.RefObject<HTMLDivElement>
}

const rootClass = 'list-page-table-container'

const ListPageTableContainer: FC<ListPageTableContainerProps> = (props: ListPageTableContainerProps) => {
  const { className = rootClass, reference, scrollableElement } = props
  const { applyTagsRequest, removeTagsRequest } = useTagRequests()
  const { setFavoriteItemsMutationRequest } = useHeaderActionRequests()
  const {
    onSearch,
    setFolder,
    setFilter,
    createTag,
    setError,
    onClickTagInRow,
    update,
    onColumnSort,
    toggleSubType,
    currentTag,
    values,
    values: {
      currentPage,
      itemType,
      folders,
      dataTest,
      selectedIds,
      items,
      tags,
      search,
      searchAll,
      searchItemsResults,
      folderPath,
      loading,
      allItemsLoaded,
      isProcessingAction,
      activeFolderId,
      activeTagId,
      filterActive,
      emptyListingProps,
      forceResetSelectedRows,
      selectedRows,
      selectedRowsRestore,
      restoreRowsOnModalClose,
      sortBy,
      filterCounts,
      activeSubTypes,
      defaultSubTypes,
      listingPageProps: {
        hasTabs,
        filterInfoHoverText,
        hideMoveItems,
        subTypes,
        sidebarProps: { allItemFilter },
        tableProps: {
          hasAutoSelectedRows,
          hasExpander,
          columns,
          onCustomTableAction,
          renderSearchColumns,
          rowActionCustomProps,
          clickableColumnOptions,
          onRowClicked,
          rowDisabled,
          isRowSelectionDisabled,
        },
      },
    },
  } = useContext(ListingPageCommonContext)

  const { t } = useTranslation()

  useEffect(() => {
    const showingModal =
      values.showBulkResponse ||
      values.showCustomModal ||
      values.showDeleteConfirmationModal ||
      values.showManageTag ||
      values.showMoveToFolder ||
      values.showPreview ||
      values.showRemoveFromFolder ||
      values.showSalesUsersEnabled ||
      values.showShare ||
      values.showShareToCatalog ||
      values.showDuplicate
    !showingModal && restoreRowsOnModalClose && update({ selectedRows: selectedRowsRestore, restoreRowsOnModalClose: false })
  }, [
    values.showBulkResponse,
    values.showCustomModal,
    values.showDeleteConfirmationModal,
    values.showManageTag,
    values.showMoveToFolder,
    values.showPreview,
    values.showRemoveFromFolder,
    values.showSalesUsersEnabled,
    values.showShare,
    values.showShareToCatalog,
    values.showDuplicate,
  ])

  const currentFolder = useMemo(() => (activeFolderId ? getFolderById(activeFolderId, folders) : undefined), [activeFolderId, folders])

  const searchForColumns = useMemo(
    () => renderSearchColumns(searchAll, currentFolder as FolderData, search, folders),
    [searchItemsResults, searchAll]
  )

  const onSelectRow = (rows: Row[], restoreRowsOnModalClose?: boolean) => {
    const newSelectedRows = rows.map((row) => row.original as ItemDto)
    const selectedIds = newSelectedRows.map(({ id }) => id)

    update({
      selectedRows: newSelectedRows,
      selectedRowsRestore: restoreRowsOnModalClose ? selectedRows : [],
      restoreRowsOnModalClose,
      selectedIds,
    })
  }

  const onRemoveTags = async (selectedIds: number[], tagsToRemove: number[], items: ItemDto[]) => {
    const { data, errors } = await removeTagsRequest(`${itemType}`, selectedIds, tagsToRemove)

    if (data?.removeLabels) {
      return items.map((item: any) => {
        if (selectedIds.includes(item.id)) {
          return { ...item, tags: item.tags?.filter((tag: any) => !tagsToRemove.includes(tag?.id)) }
        }
        return item
      })
    } else if (errors) {
      setError('ListPage.Common.Tags.Remove.Error', errors)
    }
  }

  const onCreateTag = (tag: LabelDto) => {
    if (isInvalidTagName(tags, tag)) {
      update({
        showManageTag: false,
        statusToast: {
          statusMessage: 'ListPage.Common.Tags.AlreadyExists',
          status: Status.FAIL,
          showStatusToast: true,
        },
      })
    } else {
      update({ isProcessingAction: true })
      createTag(tag)
    }
  }

  const applyAndRemoveTags = async (ids: number[], tagsToApply: LabelDto[], tagsToRemove: number[]) => {
    update({ isProcessingAction: true })

    const { data, errors } = await applyTagsRequest(`${itemType}`, ids, tagsToApply)

    if (data?.applyLabels) {
      const appliedItems = items.map((item: any) => {
        if (ids.includes(item.id)) {
          const currentTagIds = item.tags.map((tag: any) => tag?.id)
          const newTags = tagsToApply.filter((tag) => !currentTagIds?.includes(tag.id))

          return { ...item, tags: [...item.tags, ...newTags] }
        }
        return item
      })

      const updatedItems = await onRemoveTags(ids, tagsToRemove, appliedItems)
      update({ items: updatedItems, fetchTags: true, fetchItems: true })
    } else {
      setError('ListPage.Common.Tags.Apply.Error', errors)
    }
  }

  const tagManagerParams: TagManagerParams = {
    onApplyAndRemoveTags: applyAndRemoveTags,
    onCreateTag,
    selectedIds,
    t,
    tags,
  }

  const onRemoveFromFolder = (row?: ItemDto[]) => {
    update({
      showRemoveFromFolder: true,
      deleteConfirmationData: row ?? selectedRows,
      confirmationModal: DeleteConfirmationModals.DELETE_FROM_FOLDER,
    })
  }

  const onDelete = (row?: ItemDto[]) => {
    update({
      deleteConfirmationData: row ?? selectedRows,
      confirmationModal: DeleteConfirmationModals.DELETE_ITEMS,
      showDeleteConfirmationModal: true,
    })
  }

  const setFavorite = (modifiedItems: ItemDto[], items: ItemDto[]): ItemDto[] => {
    return items.reduce((items: ItemDto[], item: ItemDto) => {
      const modifiedItem = modifiedItems.find(({ id }) => id === item.id)
      return [
        ...items,
        {
          ...item,
          ...(modifiedItem ? { isFavorite: modifiedItem.isFavorite } : {}),
        },
      ]
    }, [])
  }

  const onSetFavoriteItems: OnSetFavoriteItems = async (selectedItems?: ItemDto[]) => {
    const filter = (selectedItems ?? selectedRows).filter((item) => !item.isFavorite)
    const setFavoriteItems = filter.length > 0
    const selected = selectedItems ? selectedItems.map(({ id }) => id) : selectedIds

    const { data, errors } = await setFavoriteItemsMutationRequest(`${itemType}`, selected, setFavoriteItems)

    if (data?.setFavoriteItem) {
      update({ listingPageProps: { ...values.listingPageProps, sidebarProps: { ...values.listingPageProps.sidebarProps, hasFavorites: true } } })
      if (filterActive?.name === FilterTypes.FAVORITES && !search) {
        update({
          isProcessingAction: true,
          fetchItems: true,
          fetchFilterCounts: true,
        })
      } else {
        const updatedItems = setFavorite(data?.setFavoriteItem as ItemDto[], search ? searchItemsResults : items)
        const updatedSelectedRows = updatedItems.filter((item) => selectedIds.includes(item.id))

        update(
          search === ''
            ? { items: updatedItems, selectedRows: updatedSelectedRows, fetchFilterCounts: true }
            : { searchItemsResults: updatedItems, selectedRows: updatedSelectedRows, fetchFilterCounts: true }
        )
      }
    } else {
      setError(t(`An unexpected error occurred while fetching ${itemType}s`), errors)
    }
  }

  const onAddToFolder = (selectedItems?: ItemDto[]) => {
    const addToFolder = !(selectedItems ?? selectedRows).some(({ folderId }) => folderId)
    update({ addToFolder, showMoveToFolder: true })
  }

  const rowActions = {
    ['PREVIEW']: (row: Row) => {
      onSelectRow([row], true)
      update({ showPreview: true })
    },
    ['SET_FAVORITE']: (row: Row) => {
      onSetFavoriteItems([row.original as ItemDto])
    },
    ['SHARE_TO_ACCOUNTS']: (row: Row) => {
      onSelectRow([row], true)
      update({ showShare: true })
    },
    ['DUPLICATE']: (row: Row) => {
      onSelectRow([row], true)
      update({ showDuplicate: true })
    },
    ['DELETE']: (row: Row) => {
      onSelectRow([row], true)
      onDelete([row.original as ItemDto])
    },
    ['ADD_TO_FOLDER']: (row: Row) => {
      onSelectRow([row], true)
      onAddToFolder([row.original as ItemDto])
    },
    ['SHARE_TO_CATALOG']: (row: Row) => {
      onSelectRow([row], true)
      update({ showShareToCatalog: true })
    },
    ['REMOVE_FROM_FOLDER']: (row: Row) => {
      onSelectRow([row], true)
      update({
        showRemoveFromFolder: true,
        deleteConfirmationData: [row.original as ItemDto],
        confirmationModal: DeleteConfirmationModals.DELETE_FROM_FOLDER,
      })
      onRemoveFromFolder([row])
    },
  }

  const tableActions: TableActions = {
    deleteItems: () => onDelete(),
    moveToFolder: () => onAddToFolder(),
    setFavoriteItems: () => onSetFavoriteItems(),
    deleteFromFolder: () => onRemoveFromFolder(),
    copyToCatalog: () => update({ showShareToCatalog: true }),
    clickRowAction: (row: Row, action: ListPageRowActions) => rowActions[action](row),
    customTableRowAction: (customTableAction, row) => {
      update({ selectedRows: [row.original as ItemDto] })
      if (onCustomTableAction) {
        update({ restoreRowsOnModalClose: true, selectedRowsRestore: selectedRows })
        onCustomTableAction(customTableAction, update, {
          ...values,
          selectedRows: [row.original as ItemDto],
        })
      }
    },
    customTableHeaderAction: (customTableAction) => onCustomTableAction && onCustomTableAction(customTableAction, update, values),
  }

  const getHeaderActions = useMemo(() => getAllHeaderActions(tableActions, tagManagerParams, values), [tableActions, tagManagerParams, values])

  const getRowActions = useMemo(
    () => getAllRowActions(tableActions, rowActionCustomProps, values),
    [activeFolderId, filterActive, items, selectedRows, rowActionCustomProps]
  )

  const getColumns = (columns: TableColumn[]) => {
    if (clickableColumnOptions) {
      const { colIndex, action } = clickableColumnOptions
      columns[colIndex] = {
        ...columns[colIndex],
        Cell: (row: any) =>
          renderClickableCol(
            row.value,
            row.cell.row,
            () => {
              if (action.customTableAction && onCustomTableAction) {
                onCustomTableAction(action.customTableAction, update, values, row.row.original as ItemDto)
              } else {
                update({ selectedRows: [row.row.original as ItemDto], ...action })
              }
            },
            columns[colIndex].useCustomRenderer as (col: any) => ReactNode,
            (columns[colIndex].hideTooltip ?? false) as boolean
          ),
      }

      return columns
    } else {
      return columns
    }
  }

  const tableHeaderProps = {
    folderPath,
    search,
    searchResults: searchItemsResults.length,
    filterActive,
    tags,
    onFolderClick: setFolder,
    activeTagId,
    currentFilter: filterActive,
    onChangeHandler: (search: string) => {
      const prevSearch = getItem(`${itemType}:${ListingPageSession.SEARCH_QUERY}`)
      if (search) {
        setItem(`${itemType}:${ListingPageSession.SEARCH_QUERY}`, JSON.stringify(search))
      } else {
        removeItem(`${itemType}:${ListingPageSession.SEARCH_QUERY}`)
      }
      // Don't re-evaluate searchAll unless search query was cleared
      const searchAllToggle = prevSearch ? searchAll : allItemFilter === filterActive
      update({ search, searchAll: prevSearch ? searchAll : searchAllToggle })
    },
    clearOnChange: [filterActive, activeFolderId, activeTagId],
  }

  const selectedSubTypes = subTypes?.filter(
    (subType) => !subType.behaveAsFilter && activeSubTypes.includes(subType.name) && !defaultSubTypes.includes(subType.name)
  )

  const onRemoveSubType = (name: string) => {
    toggleSubType?.(name)
  }

  const tableProps: ListPageTableProps = {
    ...emptyListingProps,
    ...tableHeaderProps,
    headerActions: getHeaderActions,
    data: search ? searchItemsResults : items,
    columns: search ? getColumns(searchForColumns) : (getColumns(columns as TableColumn[]) as any),
    hasDragDrop: !hideMoveItems,
    useCheckboxes: !hideMoveItems,
    onRowSelect: onSelectRow,
    onRowClicked,
    onFolderClick: setFolder,
    onRowSelectionChanged: (selectedRows) => onSelectRow(selectedRows),
    onSearch,
    onRowsSort: () => null,
    searchAllFilter: allItemFilter,
    currentFolder,
    canDrop: () => false,
    searchAll,
    loading,
    onLoading: () => update({ currentPage: currentPage + 1 }),
    allLoaded: allItemsLoaded,
    onApplyAndRemoveTags: applyAndRemoveTags,
    onCreateTag,
    tagAction: onClickTagInRow,
    autoResetSelectedRows: hasAutoSelectedRows,
    forceResetSelectedRows,
    hasExpander,
    currentTag,
    reference,
    scrollableElement,
    rowDisabled,
    isRowSelectionDisabled,
    itemType: `${itemType}`,
    resultsLabel: ``,
    resetSelectedRowsOnChange: [filterActive, activeFolderId, activeTagId, isProcessingAction, activeSubTypes],
    onAllItemsFilterClick: () => setFilter(allItemFilter, false),
    rowActions: getRowActions,
    onColumnSort: onColumnSort,
    enableSort: true,
    initialState: { sortBy },
    filterInfoHoverText,
    scrollFromPageContainer: hasTabs,
    filterCounts,
    enableTableV2: TABLEV2_ENABLED_LISTINGS.includes(itemType as ItemType),
    subTypes: selectedSubTypes,
    onRemoveSubType,
  }

  return <ListPageTable {...tableProps} className={className} dataTest={dataTest} />
}

export default ListPageTableContainer
