import React, { FC, useMemo, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Input, { InputType } from '@components/Input/Input'
import Loader from '@components/Loader/index'
import { LoaderTypes } from '@components/Loader/Loader'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Typography, { ModalBodyStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { formatNumber } from '@utils/numbers'

import './AssignContactsModal.css'

export interface AssignContactsModalProps {
  isOpen: boolean
  activeContacts: number
  maxContacts: number
  usedContacts: number
  className?: string
  dataTest?: string
  onAction: (activeContacts: number) => void
  onClose: () => void
}

interface AssignContactsModalState {
  activeContacts?: number
  negativeError?: boolean
  maxError?: boolean
  isSaving: boolean
}

const defaultState: AssignContactsModalState = {
  isSaving: false,
}

const rootClass = 'assign-contacts-modal'

const AssignContactsModal: FC<AssignContactsModalProps> = (props: AssignContactsModalProps) => {
  const { dataTest = rootClass, className = '', onAction, onClose, isOpen, activeContacts, maxContacts, usedContacts } = props
  const [state, setState] = useState<AssignContactsModalState>({ ...defaultState, activeContacts })
  const availableContacts = useMemo(() => maxContacts + activeContacts, [maxContacts, activeContacts])
  const canSubmit = !state.negativeError && !state.maxError && state.activeContacts !== activeContacts

  const [isSubmitting, setIsSubmitting] = useState(false)

  const { t } = useTranslation()

  const handleAction = () => {
    if (state.activeContacts !== undefined) {
      setIsSubmitting(true)
      onAction(state.activeContacts)
      setState({ ...state, isSaving: true })
    }
  }

  const handleClose = () => {
    setState({ ...state })
    onClose()
  }

  const onChange = (value: string) => {
    const newVal = parseInt(value)
    if (isNaN(newVal)) {
      return
    }
    setState({ ...state, activeContacts: newVal, negativeError: newVal < usedContacts, maxError: newVal > availableContacts })
  }

  const header = (
    <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__header`}>
      {t('Assign active contacts')}
    </ModalHeader>
  )

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
      <ModalBody className={`${rootClass}__body`}>
        <Typography text={t('AccountManagement.ActiveContacts.Subheader')} {...ModalBodyStyle} />
        <Input
          value={state.activeContacts?.toString()}
          placeholder={t('AccountManagement.ActiveContacts.ActiveContact.Placeholder')}
          label={t('Active contacts')}
          inputType={InputType.QUANTITY}
          type={'number'}
          disabled={state.isSaving}
          onChange={({ target: { value } }) => onChange(value)}
          className={`${rootClass}__input`}
          dataTest={`${dataTest}-input`}
          error={state.negativeError || state.maxError}
        />
        {state.negativeError && (
          <Typography
            text={t('AccountManagement.ActiveContacts.NegativeError', { min: formatNumber(usedContacts) })}
            type={TextType.ERROR}
            tagProps={{
              bold: { weight: TextWeight.BOLD, inline: true },
            }}
            dataTest={`${dataTest}-negative-error`}
          />
        )}
        {state.maxError && (
          <Typography
            text={t('AccountManagement.ActiveContacts.MaxError', { count: formatNumber(availableContacts) })}
            type={TextType.ERROR}
            tagProps={{
              bold: { weight: TextWeight.BOLD, inline: true },
            }}
            dataTest={`${dataTest}-max-error`}
          />
        )}
        {!state.negativeError && !state.maxError && (
          <Typography
            text={t('AccountManagement.ActiveContacts.Help', { count: formatNumber(availableContacts), min: formatNumber(usedContacts) })}
            type={TextType.BODY_TEXT_SMALL_LIGHT}
            tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
            dataTest={`${dataTest}-input-valid`}
          />
        )}
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form} className={`${rootClass}__footer`}>
        <Button
          buttonType={ButtonType.TERTIARY}
          disabled={state.isSaving || isSubmitting}
          onClick={handleClose}
          dataTest={`${dataTest}-button-tertiary`}
        >
          {t('Cancel')}
        </Button>
        <Button
          buttonType={ButtonType.PRIMARY}
          onClick={handleAction}
          disabled={!canSubmit || state.isSaving}
          dataTest={`${dataTest}-button-primary`}
          className={classNames(`${rootClass}__submit-button`, {
            [`${rootClass}__submit-button-submitting`]: isSubmitting,
          })}
        >
          {!isSubmitting && t('Save')}
          {isSubmitting && <Loader loaderType={LoaderTypes.row} className={`${rootClass}__submit-loader`} />}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default AssignContactsModal
