import React, { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react'

import { DatePicker as DatePickerAntd } from 'antd'
import { RangePickerProps } from 'antd/es/date-picker'
import { PickerDateProps } from 'antd/es/date-picker/generatePicker'
import en_US from 'antd/es/date-picker/locale/en_US'
import classNames from 'classnames'
import dayjs, { Dayjs } from 'dayjs'

import Button, { ButtonType } from '@components/Button/Button'
import { LabelV2 } from '@components/LabelV2/LabelV2'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@utils/const/globals'
import { getUUID } from '@utils/const/globals'

import './DatePicker.css'

export type DateValueType = Dayjs | null
export type DatePickerStatus = 'warning' | 'error'

export interface DatePickerProps {
  value?: Date
  minDate?: number
  format?: string
  isParentModal?: boolean
  allowClear?: boolean
  inlineError?: boolean
  leadingIcon?: boolean
  hasCancelButton?: boolean
  hasApplyButton?: boolean
  inputReadOnly?: boolean
  label?: string
  errorMessage?: string
  placeholder?: string
  onChange: (date?: Date) => void
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  renderCustomFooter?: (children: ReactNode, closeDropdown: () => void) => ReactNode
  menuPortalTarget?: PickerDateProps<Dayjs>['getPopupContainer']
  status?: DatePickerStatus
  hideErrorIcon?: boolean
  hideError?: boolean
  className?: string
  dataTest?: string
  required?: boolean
  picker?: 'time' | 'date' | 'week' | 'month' | 'quarter' | 'year'
}

const locale = {
  ...en_US,
  lang: {
    ...en_US.lang,
    monthFormat: 'MMMM',
  },
}

const rootClass = 'date-picker'

const DatePicker: FC<DatePickerProps> = (props: DatePickerProps) => {
  const {
    value,
    minDate,
    format = 'MMMM D, YYYY',
    isParentModal,
    leadingIcon,
    allowClear = true,
    hasCancelButton = false,
    hasApplyButton = false,
    inputReadOnly = false,
    onChange,
    onBlur,
    onFocus,
    renderCustomFooter,
    menuPortalTarget = (trigger) => trigger.parentElement ?? document.body,
    inlineError = false,
    errorMessage,
    dataTest = rootClass,
    className = '',
    placeholder,
    label,
    required = false,
    hideError,
    hideErrorIcon,
    status,
    picker,
  } = props
  const { t } = useTranslation()

  const [lastAppliedValue, setLastAppliedValue] = useState<DateValueType | null>(value ? dayjs(value) : null)
  const [lastChangedValue, setLastChangedValue] = useState<DateValueType | null>(null)
  const [panelChanged, setPanelChange] = useState<string | undefined>(undefined)
  const [disablePrevious, setDisablePrevious] = useState<boolean>(false)
  const [isTouched, setIsTouched] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const panelRef = useRef<HTMLDivElement>(null)
  const pickerRef = useRef<HTMLDivElement>(null)

  const handleOpenChange = useCallback((open: boolean) => open && setOpen(open), [])

  const disabledDates: RangePickerProps['disabledDate'] = (current) => {
    return current && current < dayjs(minDate).startOf('day')
  }

  const handleChange = (value: DateValueType, isPanelChange?: boolean) => {
    setPanelChange(`panel-changed: ${getUUID()}`)

    if (value !== lastAppliedValue) {
      setIsTouched(true)

      const newValue = value && disabledDates(value) && minDate ? dayjs(minDate) : value

      if (hasApplyButton) {
        setLastChangedValue(newValue)
      } else {
        handleApply(newValue, isPanelChange)
      }
    }
  }

  const handleApply = useCallback(
    (value: DateValueType, isPanelChange?: boolean) => {
      const newValue = value && disabledDates(value) && minDate ? dayjs(minDate) : value

      setLastChangedValue(null)
      setLastAppliedValue(newValue)
      onChange(newValue?.toDate() ?? undefined)
      !isPanelChange && setOpen(false)
    },
    [onChange]
  )

  const closeDropdown = () => {
    setLastAppliedValue(lastAppliedValue)
    setIsTouched(false)
    setOpen(false)
  }

  const detectClickOutside = useCallback(
    (e: MouseEvent) => {
      if (!e.target) {
        closeDropdown()
        return
      }

      const target = e.target as any
      if (!target?.getBoundingClientRect()?.height) {
        // if target is not in view.
        // This is possible in cases when selecting month/year
        return
      }
      const inPickerOrDropdown =
        pickerRef.current?.getElementsByClassName(`${rootClass}-input`)[0]?.contains(target) || panelRef.current?.contains(target)
      if (inPickerOrDropdown) {
        return
      }
      hasApplyButton && handleApply(lastChangedValue)
      closeDropdown()
    },
    [handleApply, hasApplyButton, lastAppliedValue, lastChangedValue]
  )

  useEffect(() => {
    document.querySelectorAll('.ant-picker-cell').forEach((elem) => elem.removeAttribute('title'))

    if (open && !hasCancelButton) {
      document.addEventListener('click', detectClickOutside)
    }
    return () => {
      if (open && !hasCancelButton) {
        document.removeEventListener('click', detectClickOutside)
      }
    }
  }, [open, detectClickOutside, hasCancelButton])

  useEffect(() => {
    open && setDisablePrevious(!!panelRef.current?.querySelector('.ant-picker-cell-disabled.ant-picker-cell-in-view'))
  }, [open, panelChanged])

  const renderInput = (params: any) => (
    <>
      {leadingIcon && (
        <div className="flex-justify-center">
          <span className={`${rootClass}-input-divider`} />
          <Button className={`${rootClass}-input-arrow-icon`} buttonType={ButtonType.TEXT}>
            <Svg name={SvgNames.caretDown} type={SvgType.ICON} />
          </Button>
        </div>
      )}
      <input {...params} title="" />
    </>
  )

  const renderFooter = () => (
    <div className={`${rootClass}__panel-footer`}>
      {hasCancelButton && (
        <Button
          buttonType={ButtonType.TERTIARY}
          dataTest={`${dataTest}-cancel-button`}
          onClick={() => {
            setLastChangedValue(null)
            setOpen(false)
          }}
        >
          {t('Cancel')}
        </Button>
      )}
      <Button
        buttonType={ButtonType.PRIMARY}
        dataTest={`${dataTest}-apply-button`}
        onClick={() => {
          handleApply(lastChangedValue)
        }}
        disabled={!isTouched}
      >
        {t('Apply')}
      </Button>
    </div>
  )

  const panelRender = useCallback((panel: ReactNode) => <div ref={panelRef}>{panel}</div>, [])
  const actionFooter = hasApplyButton ? renderFooter() : undefined

  return (
    <div className={classNames(rootClass, className, { [`${rootClass}__error`]: status === 'error' })} data-test={dataTest} ref={pickerRef}>
      {label && (
        <LabelV2 required={required}>
          <Typography text={t(label)} weight={TextWeight.MEDIUM} inline dataTest={`${dataTest}-label`} />
        </LabelV2>
      )}
      <DatePickerAntd
        picker={picker}
        open={open}
        onOpenChange={handleOpenChange}
        format={format}
        locale={locale}
        showToday={false}
        allowClear={allowClear}
        value={lastChangedValue || lastAppliedValue}
        defaultPickerValue={minDate ? dayjs(minDate) : dayjs()}
        inputReadOnly={inputReadOnly}
        placeholder={placeholder}
        inputRender={renderInput}
        status={status}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={(value) => handleChange(value)}
        onPanelChange={(value) => handleChange(value, true)}
        disabledDate={minDate ? (current) => disabledDates(current) : undefined}
        renderExtraFooter={() => renderCustomFooter?.(actionFooter, closeDropdown) ?? actionFooter}
        getPopupContainer={menuPortalTarget}
        nextIcon={<Svg name={SvgNames.caretRight} type={SvgType.ICON} />}
        superNextIcon={<Svg name={SvgNames.caretRigthDouble} type={SvgType.ICON} />}
        prevIcon={<Svg name={SvgNames.caretLeft} type={SvgType.ICON} />}
        superPrevIcon={<Svg name={SvgNames.caretLeftDouble} type={SvgType.ICON} />}
        suffixIcon={
          inlineError && status === 'error' ? (
            <Tooltip
              align="start"
              alignOffset={-9}
              sideOffset={-2}
              trigger={
                <div className={`${rootClass}__error-icon-container`}>
                  <Svg
                    className={`${rootClass}__error-icon`}
                    name={SvgNames.errorSolid}
                    type={SvgType.LARGER_ICON}
                    fill={SvgColor.REMOVE_RED}
                    dataTest={`${dataTest}-error-icon`}
                  />
                </div>
              }
            >
              {t(errorMessage)}
            </Tooltip>
          ) : (
            <Svg name={SvgNames.calendar} type={SvgType.LARGER_ICON} />
          )
        }
        clearIcon={
          <div className="ant-picker-clear-container">
            <Tooltip trigger={<Svg name={SvgNames.clearIndicator} type={SvgType.SMALLER_ICON} />} align="start" sideOffset={10} alignOffset={-6}>
              Clear
            </Tooltip>
          </div>
        }
        popupClassName={classNames(`${rootClass}__panel`, `${className}__panel`, {
          [`${rootClass}__parent-modal`]: isParentModal,
          [`${rootClass}__disable-previous`]: disablePrevious,
        })}
        panelRender={panelRender}
        className={classNames(`${rootClass}-input`, `${className}-input`, {
          [`${rootClass}-input-icon-start`]: leadingIcon,
        })}
        data-test={`${dataTest}-input`}
      />
      {status === 'error' && errorMessage && !hideError && !inlineError && (
        <div className={`${rootClass}__error-message flex-align-center`}>
          {!hideErrorIcon && (
            <Svg
              name={SvgNames.inputStatusInvalidNoFill}
              type={SvgType.ICON}
              fill={SvgColor.ERROR_TEXT}
              dataTest={`${dataTest}-error-icon`}
              className="push-left"
            />
          )}
          <Typography text={t(errorMessage)} type={TextType.ERROR_NEW} lineHeight={LineHeight.MEDIUM_SMALL} dataTest={`${dataTest}-error`} />
        </div>
      )}
    </div>
  )
}

export default DatePicker
