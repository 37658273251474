import { gql } from '@apollo/client'

export default gql`
  mutation copyPermissions($usersIds: [String!], $copyFromUserId: String!) {
    copyPermissions(usersIds: $usersIds, copyFromUserId: $copyFromUserId) {
      status
      message
      count
      deletedUsersIds
      newUsersIds
      importedUsers
      noImportedUsers
      duplicatedUsers
    }
  }
`
