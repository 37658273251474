import getAllCopiedSegmentsByLegacyExternalIds from '@graphql/microservices/segment/getAllCopiedSegmentsByLegacyExternalIds'
import {
  GetAllCopiedSegmentsByLegacyExternalIdsQuery,
  GetAllCopiedSegmentsByLegacyExternalIdsQueryVariables,
} from '@graphql/types/microservice/segment-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export interface GetAllCopiedSegmentsByLegacyExternalIdsRequestParams {
  legacyExternalIds: string[]
}

export type GetAllCopiedSegmentsByLegacyExternalIdsRequestType = (
  params: GetAllCopiedSegmentsByLegacyExternalIdsRequestParams
) => FetchPromise<GetAllCopiedSegmentsByLegacyExternalIdsQuery>

interface UpgradeManagerSegmentServiceRequests {
  getAllCopiedSegmentsByLegacyExternalIdsRequest: GetAllCopiedSegmentsByLegacyExternalIdsRequestType
}

export const useUpgradeManagerSegmentServiceRequests = (): UpgradeManagerSegmentServiceRequests => {
  const {
    client: { query },
  } = useMicroserviceClient({ serviceName: MicroserviceClients.SEGMENT })

  const getAllCopiedSegmentsByLegacyExternalIdsRequest: GetAllCopiedSegmentsByLegacyExternalIdsRequestType = ({ legacyExternalIds }) =>
    query<GetAllCopiedSegmentsByLegacyExternalIdsQuery, GetAllCopiedSegmentsByLegacyExternalIdsQueryVariables>({
      query: getAllCopiedSegmentsByLegacyExternalIds,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        legacyExternalIds,
      },
    })

  return {
    getAllCopiedSegmentsByLegacyExternalIdsRequest,
  }
}
