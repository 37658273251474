import React, { FC, useContext } from 'react'

import classNames from 'classnames'

import { UNIFIED_CONTACTS_ID } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import Loader from '@components/Loader'
import { Program, ProgramFieldSetStep, ProgramSource } from '@graphql/types/query-types'
import ListProgramActivities from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramDetails/components/ListProgramActivities/ListProgramActivities'
import ListProgramBasicDetailsEditor from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramDetails/components/ListProgramBasicDetailsEditor/ListProgramBasicDetailsEditor'
import ListProgramScheduleEditor from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramDetails/components/ListProgramScheduleEditor/ListProgramScheduleEditor'
import ListProgramSources from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramDetails/components/ListProgramSources/ListProgramSources'
import { ListMaintenanceProgramsContext } from '@src/pages/listingPages/ListMaintenancePrograms/context/ListMaintenancePrograms.context'
import { handleScheduleChange } from '@src/pages/listingPages/ListMaintenancePrograms/utils/Helpers'
import { ListProgramSource } from '@utils/listingPage/listMaintenancePrograms.utils'
import { ProgramStepType } from '@utils/program/program.constants'

import './ProgramDetails.css'

interface ProgramDetailsProps {
  className?: string
  dataTest?: string
  loading?: boolean
}

const rootClass = 'program-details'

const ProgramDetails: FC<ProgramDetailsProps> = (props: ProgramDetailsProps) => {
  const { dataTest = rootClass, className = '', loading = false } = props

  const {
    update,
    values: { hasMarketingSource, isEditing, programDetails, program },
  } = useContext(ListMaintenanceProgramsContext)

  const { sourceList = [], schedule, steps: programSteps = [] } = { ...programDetails }

  const getUpdatedSteps = ({ name, baseId, count }: ProgramSource) => {
    return programSteps.map((step) => {
      if (step.stepType !== ProgramStepType.FIELD_SET) {
        return step
      } else {
        const { forceUseOfSourceList = false } = step as ProgramFieldSetStep
        return { ...step, ...(forceUseOfSourceList && hasMarketingSource ? { listId: baseId, listName: name, listRecordCount: count } : {}) }
      }
    })
  }

  const isMarketingList = (source: ListProgramSource) => {
    return source.baseId !== UNIFIED_CONTACTS_ID && !source.itemType
  }

  const onChange = <T extends keyof Program>(field: T, value: Program[T]) => {
    const isChangingSourceList = field === 'sourceList' && Array.isArray(value)
    const steps = isChangingSourceList ? getUpdatedSteps(value[0] as ProgramSource) : programSteps
    update({
      programDetails: {
        ...(programDetails as Program),
        steps,
        ...(isChangingSourceList ? { doneCount: 0 } : {}),
        [field]: value,
      },
      ...(isChangingSourceList && value.length > 0 ? { hasMarketingSource: isMarketingList(value[0] as ListProgramSource) } : {}),
      ...(!isEditing ? { isEditing: true } : {}),
    })
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {!programDetails || !program || loading ? (
        <Loader center />
      ) : (
        <>
          <div className={`${rootClass}__main-content`}>
            <ListProgramSources
              dataTest={`${dataTest}-sources`}
              source={sourceList?.length ? sourceList[0] : undefined}
              onChange={(source) => onChange('sourceList', source ? [source as ProgramSource] : [])}
            />
            <ListProgramScheduleEditor
              dataTest={`${dataTest}-schedule`}
              schedule={schedule}
              onChange={(schedule) => onChange('schedule', handleScheduleChange(schedule))}
            />
            <ListProgramActivities dataTest={`${dataTest}-activities`} programId={program.externalId} />
          </div>
          <ListProgramBasicDetailsEditor dataTest={`${dataTest}-basic-details`} onChange={onChange} />
        </>
      )}
    </div>
  )
}

export default ProgramDetails
