import React from 'react'

import { AccordionDetailsModalSection } from '@components/AccordionDetailsModal/AccordionDetailsModal'
import { SvgNames } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import AlertFiltersList from '@src/pages/WebsiteProspectorAlerts/components/AlertFiltersList/AlertFiltersList'
import { AlertFilter } from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertFilters/utils/AlertFilters.utils'
import RecipientsList, {
  Recipient,
} from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertRecipients/components/RecipientsList/RecipientsList'

const rootClass = 'visitor-alerts'

export const getDetailsModalSections = (
  schedule: string,
  recipients: Recipient[],
  filters: AlertFilter[],
  t: Function
): AccordionDetailsModalSection[] => [
  {
    icon: SvgNames.clock,
    content: (
      <div>
        <Typography
          text={schedule === 'Immediately' ? t(schedule) : 'Weekly on Wednesdays'}
          type={TextType.DATA_CARD_MODAL_HEADER}
          weight={TextWeight.BOLD}
          dataTest={`${rootClass}-header-text`}
        />
        {schedule !== 'Immediately' && <Typography text={'at 8:00 AM'} type={TextType.BODY_TEXT_LIGHT} dataTest={`${rootClass}-header-text`} />}
      </div>
    ),
  },
  {
    icon: SvgNames.usersUnselectedFull,
    isAccordion: true,
    accordionTitle: t('Recipients ({{recipientsCount}})', { recipientsCount: recipients.length }),
    content: <RecipientsList recipients={recipients} onMute={() => undefined} onRemove={() => undefined} readOnly />,
  },
  {
    icon: SvgNames.settingsTwoLinesNoFill,
    isAccordion: true,
    accordionTitle: t('Filters ({{filtersCount}})', { filtersCount: filters.length }),
    content: <AlertFiltersList filters={filters} readOnly />,
  },
]
