import React, { ChangeEvent, FC, useContext } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import FormRow from '@components/FormRow'
import Input from '@components/Input/Input'
import { ModalBody, ModalFooter } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { useTranslation } from '@const/globals'
import { SMSContext, SMSContextAPI } from '@src/pages/sms/context/SMSContext'
import MessageComposer from '@src/pages/sms/edit/components/MessageComposer/MessageComposer'

import './SmsModal.css'

type Props = {
  closeModal: () => void
  className?: string
  dataTest?: string
}

const rootClass = 'sms-modal'

const SmsModal: FC<Props> = (props: Props) => {
  const { closeModal, dataTest = rootClass, className = '' } = props
  const context = useContext<SMSContextAPI>(SMSContext)
  const { values, sendSMSMessage, saveMessage } = context
  const { t } = useTranslation()

  const onTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedMsg = { ...values.smsMessage, title: e.target.value }
    saveMessage(updatedMsg)
  }

  return (
    <>
      <ModalBody className={classNames(rootClass, className)} data-test={`${dataTest}`}>
        <form data-test={dataTest}>
          <FormRow>
            <Input
              className={`${rootClass}__step-name`}
              label={t('Step Name')}
              onBlur={onTitleChange}
              defaultValue={values.smsMessage.title}
              name="displayName"
            />
          </FormRow>
          <FormRow>
            <MessageComposer context={context} showPreview={true} />
          </FormRow>
        </form>
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form}>
        <Button buttonType={ButtonType.TERTIARY} onClick={closeModal} dataTest={`${dataTest}-close-button`}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} dataTest={`${dataTest}-save-button`} onClick={sendSMSMessage}>
          {t('Submit')}
        </Button>
      </ModalFooter>
    </>
  )
}

export default SmsModal
