import { gql } from '@apollo/client'

export default gql`
  mutation createTemplateFromForm($formId: String!, $folderId: Int, $importSettings: Boolean) {
    createTemplateFromForm(formId: $formId, folderId: $folderId, importSettings: $importSettings) {
      id
      name
    }
  }
`
