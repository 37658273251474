import { ApolloClient } from '@apollo/client'
import programMutation from '@graphql/mutations/automatedPrograms'
import copyProgramMessage from '@graphql/mutations/copyProgramMessage'
import deleteProgramHistory from '@graphql/mutations/deleteProgramHistory'
import listingRunScheduledDripProgramNow from '@graphql/mutations/listingRunScheduledDripProgramNow'
import moveContactsToNextStep from '@graphql/mutations/moveContactsToNextStep'
import saveProgramFinalQuery from '@graphql/mutations/saveProgramFinal'
import startProgram from '@graphql/mutations/startProgram'
import stopProgram from '@graphql/mutations/stopProgram'
import programQuery from '@graphql/queries/automatedPrograms'
import getAutomatedProgramCounts from '@graphql/queries/getAutomatedProgramCounts'
import listSchemaQuery from '@graphql/queries/listSchema'
import programStatus from '@graphql/queries/programStatus'
import searchListsByIdsQuery from '@graphql/queries/searchListsByIds'
import {
  CopyProgramMessageMutation,
  CopyProgramMessageMutationVariables,
  DeleteProgramHistoryMutation,
  DeleteProgramHistoryMutationVariables,
  ListingRunScheduledDripProgramNowMutation,
  ListingRunScheduledDripProgramNowMutationVariables,
  MoveContactsToNextStepMutation,
  MoveContactsToNextStepMutationVariables,
  SaveProgramFinalMutation,
  SaveProgramFinalMutationVariables,
  SaveProgramMutation,
  SaveProgramMutationVariables,
  StartProgramMutation,
  StartProgramMutationVariables,
  StopProgramMutation,
  StopProgramMutationVariables,
} from '@graphql/types/mutation-types'
import {
  GetAutomatedProgramCountsQuery,
  ListSchemaQuery,
  ListSchemaQueryVariables,
  Program,
  ProgramQuery,
  ProgramQueryVariables,
  ProgramStatusQuery,
  ProgramStatusQueryVariables,
  QueryGetAutomatedProgramCountsArgs,
  SearchListsByIdsQuery,
  SearchListsByIdsQueryVariables,
} from '@graphql/types/query-types'
import { cleanProgram } from '@utils/program/sagasUtils'
import { FetchPromise } from '@utils/types'

interface GetProgramManagerQueriesResponse {
  loadProgramRequest: (programId: string) => FetchPromise<ProgramQuery>
  getStatusRequest: (programId: string) => FetchPromise<ProgramStatusQuery>
  getCountsRequest: (programId: string) => FetchPromise<GetAutomatedProgramCountsQuery>
  saveProgramRequest: (program: Program) => FetchPromise<SaveProgramMutation>
  saveProgramFinalRequest: (programUrlId: string) => FetchPromise<SaveProgramFinalMutation>
  clearProgramHistoryRequest: (programUrlId: string, allowReentrantAddresses: boolean) => FetchPromise<DeleteProgramHistoryMutation>
  startProgramRequest: (skipPending: boolean, lastRowId: number, programUrlId: string) => FetchPromise<StartProgramMutation>
  pauseProgramRequest: (programUrlId: string) => FetchPromise<StopProgramMutation>
  moveNextRequest: (stepId: string, programUrlId: string) => FetchPromise<MoveContactsToNextStepMutation>
  addOrExitPendingContactsRequest: (skipPending: boolean, programUrlId: string) => FetchPromise<ListingRunScheduledDripProgramNowMutation>
  getListSchemaRequest: (listIds: string[]) => FetchPromise<ListSchemaQuery>
  searchListByIdsRequest: (listId: string[]) => FetchPromise<SearchListsByIdsQuery>
  copyProgramMessageRequest: (programId: string, messageId: string) => FetchPromise<CopyProgramMessageMutation>
}

export const getProgramManagerQueries = (client: ApolloClient<any>): GetProgramManagerQueriesResponse => {
  const getCountsRequest = (programId: string) => {
    return client.query<GetAutomatedProgramCountsQuery, QueryGetAutomatedProgramCountsArgs>({
      query: getAutomatedProgramCounts,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        programId: programId,
      },
    })
  }

  const getStatusRequest = (programId: string) => {
    return client.query<ProgramStatusQuery, ProgramStatusQueryVariables>({
      query: programStatus,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        programId: programId,
      },
    })
  }

  const loadProgramRequest = (programUrlId: string) => {
    return client.query<ProgramQuery, ProgramQueryVariables>({
      query: programQuery,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        id: programUrlId === 'new' ? '' : programUrlId,
      },
    })
  }

  const saveProgramRequest = (program: Program) => {
    const cleanedProgram = cleanProgram(program)
    return client.mutate<SaveProgramMutation, SaveProgramMutationVariables>({
      mutation: programMutation,
      errorPolicy: 'all',
      variables: {
        program: cleanedProgram,
      },
    })
  }

  const saveProgramFinalRequest = (programUrlId: string) => {
    return client.mutate<SaveProgramFinalMutation, SaveProgramFinalMutationVariables>({
      mutation: saveProgramFinalQuery,
      errorPolicy: 'all',
      variables: {
        programId: programUrlId ?? '-1',
      },
    })
  }

  const clearProgramHistoryRequest = (programUrlId: string, allowReentrantAddresses: boolean) => {
    return client.mutate<DeleteProgramHistoryMutation, DeleteProgramHistoryMutationVariables>({
      mutation: deleteProgramHistory,
      errorPolicy: 'all',
      variables: {
        programId: programUrlId,
        duplicates: allowReentrantAddresses ? '1' : '0',
      },
    })
  }

  const startProgramRequest = (skipPending: boolean, lastRowId: number, programUrlId: string) => {
    return client.mutate<StartProgramMutation, StartProgramMutationVariables>({
      mutation: startProgram,
      errorPolicy: 'all',
      variables: {
        programId: programUrlId,
        confirmedStartParam: 1,
        skipPendingParam: skipPending ? 1 : 0,
        runNow: skipPending ? 0 : 1,
        lastRowIdString: lastRowId.toString(),
      },
    })
  }

  const pauseProgramRequest = (programUrlId: string) => {
    return client.mutate<StopProgramMutation, StopProgramMutationVariables>({
      mutation: stopProgram,
      errorPolicy: 'all',
      variables: {
        programId: programUrlId,
      },
    })
  }

  const moveNextRequest = (stepId: string, programUrlId: string) => {
    return client.mutate<MoveContactsToNextStepMutation, MoveContactsToNextStepMutationVariables>({
      mutation: moveContactsToNextStep,
      errorPolicy: 'all',
      variables: {
        programId: programUrlId,
        stepId,
      },
    })
  }

  const addOrExitPendingContactsRequest = (skipPending: boolean, programUrlId: string) => {
    return client.mutate<ListingRunScheduledDripProgramNowMutation, ListingRunScheduledDripProgramNowMutationVariables>({
      mutation: listingRunScheduledDripProgramNow,
      errorPolicy: 'all',
      variables: {
        programId: programUrlId,
        skipPending,
      },
    })
  }

  const getListSchemaRequest = (listIds: string[]) => {
    return client.query<ListSchemaQuery, ListSchemaQueryVariables>({
      query: listSchemaQuery,
      fetchPolicy: 'network-only',
      variables: {
        listIds,
      },
    })
  }

  const searchListByIdsRequest = (listIds: string[]) => {
    return client.query<SearchListsByIdsQuery, SearchListsByIdsQueryVariables>({
      query: searchListsByIdsQuery,
      fetchPolicy: 'network-only',
      variables: {
        ids: listIds.join(','),
      },
    })
  }

  const copyProgramMessageRequest = (programId: string, messageId: string) => {
    return client.mutate<CopyProgramMessageMutation, CopyProgramMessageMutationVariables>({
      mutation: copyProgramMessage,
      errorPolicy: 'all',
      variables: {
        programId,
        messageId,
      },
    })
  }

  return {
    loadProgramRequest,
    getStatusRequest,
    getCountsRequest,
    saveProgramRequest,
    saveProgramFinalRequest,
    clearProgramHistoryRequest,
    startProgramRequest,
    pauseProgramRequest,
    moveNextRequest,
    addOrExitPendingContactsRequest,
    getListSchemaRequest,
    searchListByIdsRequest,
    copyProgramMessageRequest,
  }
}
