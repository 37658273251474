import { gql } from '@apollo/client'

export default gql`
  query getRevenueImpactCampaigns($endDate: String, $timeZoneId: String, $startDate: String) {
    getRevenueImpactCampaigns(endDate: $endDate, timeZoneId: $timeZoneId, startDate: $startDate) {
      campaigns {
        name
        nameRows {
          name
          statusRowLost {
            stageRows {
              name
              opportunities
              pipeline
              revenue
              won
            }
          }
          statusRowOpen {
            stageRows {
              name
              opportunities
              pipeline
              revenue
              won
            }
          }
          statusRowWon {
            stageRows {
              name
              opportunities
              pipeline
              revenue
              won
            }
          }
        }
      }
    }
  }
`
