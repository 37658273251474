import { useEffect, useState } from 'react'

import getTopDirectSelectSegments from '@graphql/microservices/categorization/getTopDirectSelectSegments'
import { GetTopDirectSelectSegmentsQuery, GetTopDirectSelectSegmentsQueryVariables, ItemDto } from '@graphql/types/microservice/categorization-types'
import { SelectOption } from '@interface/Select'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { logNewRelicError } from '@utils/new-relic.utils'

export const useTopDirectSegments: (defaultOption: string) => SelectOption[] = (defaultOption) => {
  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })
  const [listOptions, setListOptions] = useState<SelectOption[]>([])

  useEffect(() => {
    categorizationClient
      .query<GetTopDirectSelectSegmentsQuery, GetTopDirectSelectSegmentsQueryVariables>({
        query: getTopDirectSelectSegments,
        fetchPolicy: 'network-only',
        variables: {
          type: 'SEGMENT',
        },
      })
      .then((data) => {
        updateData(data.data.getTopDirectSelectSegments)
      })
      .catch((e) => logNewRelicError(e))
  }, [])

  const updateData = (data: any) => {
    const aOContactOptions: SelectOption[] = data
      .map((entry: ItemDto) => {
        const item = JSON.parse(entry.item ?? '')
        return {
          label: item?.name,
          value: item?.id,
          filtered: false,
          selected: item?.id === defaultOption,
        }
      })
      .filter((entry: SelectOption) => entry.label !== '')

    setListOptions(aOContactOptions)
  }

  return listOptions
}
