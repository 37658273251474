import React from 'react'

import Typography, { TextType, TextWeight } from '@components/Typography/Typography'

const getAddInputInfoText = (t: Function, rootClass: string, withHyperlinkPrefix: boolean) => {
  return (
    <>
      <Typography
        className={`${rootClass}__modal-info`}
        text={t(`Your ${withHyperlinkPrefix ? 'form domain' : 'URL'} must:`)}
        type={TextType.NORMAL_TEXT_GRAY}
      />
      <div className={`${rootClass}__modal-info-item`}>
        <Typography
          text="Custom.Account.Settings.Add.Input.Info"
          inline
          type={TextType.NORMAL_TEXT_GRAY}
          tagProps={{ bold: { weight: TextWeight.MEDIUM } }}
        />
      </div>
      {withHyperlinkPrefix && (
        <Typography className={`${rootClass}__modal-info-item`} text={t('Not contain a path')} type={TextType.NORMAL_TEXT_GRAY} />
      )}
    </>
  )
}

export default getAddInputInfoText

export const STICKY_HEADER_POSITION = 73
