import { gql } from '@apollo/client'

export default gql`
  query getCountForFavoritesAndCreatedByMeAndRecent($folderSubTypes: [String], $days: Int, $subTypes: [String], $type: String) {
    getCountForFavoritesAndCreatedByMeAndRecent(folderSubTypes: $folderSubTypes, days: $days, subTypes: $subTypes, type: $type) {
      createdByMeCount
      favoritesCount
      folderSubType
      recentCount
      subType
    }
  }
`
