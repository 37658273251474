import { gql } from '@apollo/client'

export default gql`
  query getOptInForms($accountId: Long!, $sortDirection: Direction, $sortColumn: OptInFormsSortColumns, $size: Int!, $page: Int!) {
    getOptInForms(accountId: $accountId, sortDirection: $sortDirection, sortColumn: $sortColumn, size: $size, page: $page) {
      optInForms {
        formId
        formTitle
        latestOptInDate
        listDisplayName
        listId
        optInCount
      }
      totalCount
    }
  }
`
