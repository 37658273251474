import React, { FC } from 'react'

import ToggleSection from '@components/ToggleSection/ToggleSection'
import { useTranslation } from '@const/globals'

import './IncomingWebhookToggleSection.css'

interface IncomingWebhookToggleSectionProps {
  isToggleOn: boolean
  onToggle: (toggle: boolean) => void
  rootTranslation: string
  disabled?: boolean
}

const rootClass = 'incoming-webhook-toggle-section'

const IncomingWebhookToggleSection: FC<IncomingWebhookToggleSectionProps> = (props: IncomingWebhookToggleSectionProps) => {
  const { isToggleOn, onToggle, disabled = false, rootTranslation } = props
  const { t } = useTranslation()
  return (
    <ToggleSection
      title={t(`${rootTranslation}.Title`)}
      disabled={disabled}
      infoText={t(`${rootTranslation}.Text`)}
      isToggleOn={isToggleOn}
      onToggle={onToggle}
      dataTest={rootClass}
      className={rootClass}
    />
  )
}

export default IncomingWebhookToggleSection
