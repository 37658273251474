import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import classNames from 'classnames'

import { useApolloClient } from '@apollo/client'
import Button, { ButtonType } from '@components/Button'
import ButtonWithLoader from '@components/ButtonWithLoader/ButtonWithLoader'
import Container from '@components/Container'
import { LinkTextButton } from '@components/LinkTextButton/LinkTextButton'
import StaticImage from '@components/StaticImage/StaticImage'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { Status } from '@components/StatusToast/StatusToast'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextAlign, TextType } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import { StartProgramResult } from '@src/pages/listingPages/AutomatedPrograms/AutomatedProgramsListingPage.constants'
import { onStartProgram, startProgram } from '@src/pages/listingPages/AutomatedPrograms/AutomatedProgramsListingPage.helpers'
import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'
import { UpgradeManagerContext } from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'
import SuccessfullyUpgradedModal from '@src/pages/programs/UpgradePrograms/components/SuccessfullyUpgraded/components/SuccessfullyUpgradedModal/SuccessfullyUpgradedModal'

import './SuccessfullyUpgraded.css'

interface SuccessfullyUpgradedProps {
  contactsCount: number
}

const rootClass = 'successfully-upgraded'

export const SuccessfullyUpgraded: FC<SuccessfullyUpgradedProps> = (props: SuccessfullyUpgradedProps) => {
  const { contactsCount } = props

  const {
    values: { programUrlId, program },
  } = useContext(ProgramManagerContext)

  const { update } = useContext(UpgradeManagerContext)

  const [showSuccessfulRestartModal, setShowSuccessfulRestartModal] = useState(false)
  const [restartingProgram, setRestartingProgram] = useState(false)

  const alreadyRestartedRef = useRef(false)

  const { t } = useTranslation()

  const history = useHistory()

  const client = useApolloClient()

  const onContactsCountClick = useCallback(() => undefined, [])

  const onRestartProgramClick = useCallback(async () => {
    setRestartingProgram(true)
    const data = await onStartProgram(programUrlId, client)
    if (data) {
      const { pending = 0, lastRowId = 0 } = data.startAutomatedProgram
      const result = await startProgram(programUrlId, false, pending > 0, client, lastRowId)
      if (result === StartProgramResult.ADDED) {
        setShowSuccessfulRestartModal(true)
      } else if (result === StartProgramResult.FAILED) {
        update({
          statusToast: {
            status: Status.FAIL,
            message: t(`Your program could not be started`),
            showStatusToast: true,
          },
        })
      }
    }
    setRestartingProgram(false)
  }, [client, programUrlId, t])

  const onUpgradeAnotherProgramClick = useCallback(() => {
    history.push(`${rootContext}/upgrade/programs`)
  }, [history])

  const onBackToUpgradeAssistant = useCallback(() => {
    history.push(`${rootContext}/upgradeAssistant`)
  }, [history])

  useEffect(() => {
    if (contactsCount === 0 && !alreadyRestartedRef.current) {
      onRestartProgramClick()
      alreadyRestartedRef.current = true
    }
  }, [contactsCount, onRestartProgramClick])

  const renderRestartProgramButton = () => (
    <ButtonWithLoader onClick={onRestartProgramClick} loading={restartingProgram}>
      {t(`AutomatedPrograms.UpgradeManager.SuccessfullyUpgraded.Button.Restart`)}
    </ButtonWithLoader>
  )

  const renderUpgradeAnotherProgramButton = () => (
    <Button buttonType={contactsCount > 0 ? ButtonType.INFO : ButtonType.PRIMARY} onClick={onUpgradeAnotherProgramClick}>
      {t(`AutomatedPrograms.UpgradeManager.SuccessfullyUpgraded.Button.UpgradeAnother`)}
    </Button>
  )

  const renderBackToUpgradeAssistantButton = () => (
    <Button buttonType={ButtonType.SECONDARY} onClick={onBackToUpgradeAssistant}>
      <Svg name={SvgNames.arrowLeft} type={SvgType.ICON} />
      {t(`AutomatedPrograms.UpgradeManager.SuccessfullyUpgraded.Button.UpgradeAssistant`)}
    </Button>
  )

  return (
    <div className={classNames(rootClass)}>
      <SuccessfullyUpgradedModal
        onBackToUpgradeAssistant={onBackToUpgradeAssistant}
        isOpen={showSuccessfulRestartModal}
        contactsAdded={contactsCount}
        programId={programUrlId}
        programName={program.name}
      />
      <Container className={`${rootClass}__status-container`}>
        <div className={`${rootClass}__status`}>
          <StaticImage name={StaticImageNames.emptyCelebrateSmall} />
          <Typography type={TextType.PAGE_HEADLINE} text={t(`AutomatedPrograms.UpgradeManager.SuccessfullyUpgraded.Headline`)} />
          <Typography
            textAlign={TextAlign.CENTER}
            text={t(`AutomatedPrograms.UpgradeManager.SuccessfullyUpgraded.Body`, { count: contactsCount })}
            type={TextType.BODY_TEXT_LIGHT}
            tagComponents={{ Link: <LinkTextButton onClick={onContactsCountClick} hideIconLeft hideIconRight /> }}
            inline
          />
          {contactsCount > 0 ? (
            <>
              {renderRestartProgramButton()}
              {renderUpgradeAnotherProgramButton()}
            </>
          ) : (
            <>
              {renderUpgradeAnotherProgramButton()}
              {renderBackToUpgradeAssistantButton()}
            </>
          )}
        </div>
      </Container>
    </div>
  )
}
