import React from 'react'
import { Row } from 'react-table'

import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { RowAction, TableColumn } from '@components/Table/Table'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { ProgramSource } from '@graphql/types/query-types'

import { SourceListWithString, SourceListWithElement } from './ProgramGeneralSettings'

export const sourceListsColumns = (): TableColumn[] => {
  return [
    {
      Header: 'Source List/Segment',
      accessor: 'name',
      align: 'left',
      minWidth: 276,
      flexColumn: true,
      sortType: 'caseInsensitive',
    },
    {
      Header: 'Records',
      accessor: 'records',
      align: 'right',
      minWidth: 160,
      maxWidth: 160,
      sortType: 'caseInsensitive',
    },
  ]
}

export const sourceListsColumnsV2 = (
  sourceLists: ProgramSource[]
): ColumnDefWithAdditionalProps<SourceListWithString | SourceListWithElement, unknown>[] => {
  return [
    {
      header: 'Source List/Segment',
      accessorKey: 'name',
      textAlign: 'left',
      sortingFn: 'caseInsensitive',
      cell:
        sourceLists.length === 0
          ? () => (
              <div className={'program-general-settings__no-sources'}>
                <Typography text={'Source(s) not set'} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.ITALIC} />
                <Svg name={SvgNames.error} type={SvgType.ICON} />
              </div>
            )
          : (row) => row.getValue(),
    },
    {
      header: 'Records',
      accessorKey: 'records',
      textAlign: 'right',
      sortingFn: 'caseInsensitive',
    },
  ]
}

export const getSourceListData = (sourceLists: ProgramSource[], t: (val: string) => string): SourceListWithString[] | SourceListWithElement[] => {
  return sourceLists.length === 0
    ? [
        {
          name: (
            <div className={'program-general-settings__no-sources'}>
              <Typography text={t('Source(s) not set')} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.ITALIC} />
              <Svg name={SvgNames.error} type={SvgType.ICON} />
            </div>
          ),
          records: '-',
        },
      ]
    : sourceLists.map((list) => ({
        name: list.name,
        records: list.count,
      }))
}

export const rowActions = (deleteAction: (i: number) => void): RowAction[] => [
  {
    label: 'Delete',
    icon: SvgNames.delete,
    onClick: (row: Row) => deleteAction(parseInt(row.id)),
  },
]

export const getScheduleIntervalOptions = (t: Function) => {
  const options: SelectV2SingleOption[] = [
    {
      label: t('Once Daily'),
      value: '0',
    },
    {
      label: t('Every Hour'),
      value: '1',
    },
  ]
  for (let i = 2; i <= 12; i++) {
    options.push({
      label: `${t('Every')} ${i} ${t('Hours')}`,
      value: `${i}`,
    })
  }

  return options
}

export const getWeeklyScheduleOptions = (t: Function) => {
  return [
    { value: '1', label: t('Sundays') },
    { value: '2', label: t('Mondays') },
    { value: '3', label: t('Tuesdays') },
    { value: '4', label: t('Wednesdays') },
    { value: '5', label: t('Thursdays') },
    { value: '6', label: t('Fridays') },
    { value: '7', label: t('Saturdays') },
  ]
}

export const getMonthlyScheduleOptions = (t: Function) => {
  return [
    { value: '1', label: t('1st') },
    { value: '2', label: t('2nd') },
    { value: '3', label: t('3rd') },
    { value: '4', label: t('4th') },
    { value: '5', label: t('5th') },
    { value: '6', label: t('6th') },
    { value: '7', label: t('7th') },
    { value: '8', label: t('8th') },
    { value: '9', label: t('9th') },
    { value: '10', label: t('10th') },
    { value: '11', label: t('11th') },
    { value: '12', label: t('12th') },
    { value: '13', label: t('13th') },
    { value: '14', label: t('14th') },
    { value: '15', label: t('15th') },
    { value: '16', label: t('16th') },
    { value: '17', label: t('17th') },
    { value: '18', label: t('18th') },
    { value: '19', label: t('19th') },
    { value: '20', label: t('20th') },
    { value: '21', label: t('21st') },
    { value: '22', label: t('22nd') },
    { value: '23', label: t('23rd') },
    { value: '24', label: t('24th') },
    { value: '25', label: t('25th') },
    { value: '26', label: t('26th') },
    { value: '27', label: t('27th') },
    { value: '28', label: t('28th') },
    { value: '29', label: t('29th') },
    { value: '30', label: t('30th') },
    { value: '31', label: t('31st') },
  ]
}
