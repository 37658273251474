import React, { FC, ReactNode, useContext, useEffect } from 'react'
import { useHistory } from 'react-router'

import { useApolloClient } from '@apollo/client'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { Status } from '@components/StatusToast/StatusToast'
import { undefinedReturnedFunction, useTranslation } from '@const/globals'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { Program, ProgramExit, ProgramTrack } from '@graphql/types/query-types'
import { ProgramScheduleType } from '@src/pages/programs/dashboard/components/ProgramSettings/ProgramSettings'
import BuildProgramFlow from '@src/pages/programs/edit/components/ProgramFlow/buildProgramFlow'
import programFlowUtil from '@src/pages/programs/edit/components/ProgramFlow/programFlowUtil'
import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'
import { getProgramManagerQueries } from '@src/pages/programs/manager/graphQL/ProgramManager.graphQL'
import { createNewFieldMappings } from '@src/pages/programs/upgradeManager/components/steps/ChangeFieldValueUpgradeStep/components/FieldReplacement/FieldReplacement.utils'
import {
  BaseList,
  UpgradedEarlyExitCondition,
  UpgradedProgramAppendToSegmentStep,
  UpgradedProgramRecordSetStep,
  UpgradedProgramSendStep,
  UpgradedProgramStep,
  UpgradedSource,
  UpgradeManagerContainerInitialState,
  UpgradeManagerContainerState,
  UpgradeManagerContext,
  UpgradeSourcesStep,
  UpgradeStep,
} from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'
import { useUpgradeManagerClassicRequests } from '@src/pages/programs/upgradeManager/graphQL/UpgradeManagerRequests.classic.graphQL'
import { useUpgradeManagerSegmentServiceRequests } from '@src/pages/programs/upgradeManager/graphQL/UpgradeManagerRequests.segment.graphQL'
import { getListSchemaRequestUtils } from '@src/pages/programs/upgradeManager/graphQL/utils/UpgradeManagerRequests.classic.utils'
import { getAllCopiedSegmentsByLegacyExternalIdsUtils } from '@src/pages/programs/upgradeManager/graphQL/utils/UpgradeManagerRequests.segment.utils'
import { getStepsToUpgrade } from '@src/pages/programs/upgradeManager/helper/UpgradeManager.helper'
import { UpgradeManager } from '@src/pages/programs/upgradeManager/UpgradeManager'
import { ProgramFromItemDto } from '@src/pages/programs/UpgradePrograms/UpgradePrograms'
import UpgradeProgramsContainer from '@src/pages/programs/UpgradePrograms/UpgradeProgramsContainer'
import { UPGRADE_PROGRAMS_URL } from '@src/pages/UpgradeAssistant/UpgradeAssistant.contants'
import { filterNotEmptyArray } from '@utils/array'
import { useCategorizationService } from '@utils/hooks/microservices/useCategorizationService'
import { usePersistentState } from '@utils/hooks/usePersistentState'
import { useUnifiedContactList } from '@utils/hooks/useUnifiedContactList'
import { logNewRelicError } from '@utils/new-relic.utils'
import { ProgramFlowTree, ProgramStepType, Step } from '@utils/program/program.constants'

import './UpgradeManager.css'

interface UpgradeManagerContainerProps {
  children?: ReactNode
}

const rootClass = 'upgrade-manager'

export const UpgradeManagerContainer: FC<UpgradeManagerContainerProps> = ({ children }) => {
  const {
    values: { program, showUpgrade, programUrlId },
    openPopup,
  } = useContext(ProgramManagerContext)

  const [state, setState, { clearSessionData, updateSessionData }] = usePersistentState<UpgradeManagerContainerState>({
    key: programUrlId,
    initialValue: {
      ...UpgradeManagerContainerInitialState,
      upgradedProgram: program,
    },
  })

  const {
    activeUpgradeStep,
    isSuccessfullyUpgraded,
    isUpgrading,
    stepsToUpgrade,
    switchToManualStart,
    upgradedCurrentStep,
    upgradedEarlyExitConditions,
    upgradedEarlyExitSources,
    upgradedProgram,
    upgradedSources,
    upgradedSteps,
    upgradedStepSources,
    showUpgradeSidePanel,
  } = state

  const history = useHistory<{ openUpgradeSidePanel?: boolean; programToUpgrade?: ProgramFromItemDto }>()
  const { getAllCopiedSegmentsByLegacyExternalIdsRequest } = useUpgradeManagerSegmentServiceRequests()
  const { getListSchemaRequest, getProgramContactsCount } = useUpgradeManagerClassicRequests()
  const { unifiedListFieldMappings, getStandardFieldsRequest, createNewUCLFieldMapping } = useUnifiedContactList()
  const { allActOnContactsSegment } = useCategorizationService({ fetchAllActonContactsSegment: true })
  const client = useApolloClient()
  const { pauseProgramRequest, saveProgramRequest, saveProgramFinalRequest } = getProgramManagerQueries(client)

  const { sources, tracks } = program
  const { t } = useTranslation()

  useEffect(() => {
    if (history.location.state?.openUpgradeSidePanel) {
      history.replace({ ...history.location, state: { ...history.location.state, openUpgradeSidePanel: undefined } })
      update({ showUpgradeSidePanel: true })
    }

    return () => {
      const programId = history.location.state?.programToUpgrade?.externalId ?? programUrlId
      if (programId && history.location.pathname !== UPGRADE_PROGRAMS_URL && !history.location.pathname.includes(programId)) {
        clearSessionData(programId)
      }
    }
  }, [])

  useEffect(() => {
    if (!showUpgrade && activeUpgradeStep !== UpgradeStep.FIX_STEP_ISSUES && activeUpgradeStep !== UpgradeStep.EARLY_EXITS) {
      setState((state) => ({ ...state, activeUpgradeStep: UpgradeStep.CONTACT_SOURCES }))
    }
  }, [showUpgrade])

  useEffect(() => {
    if (sources.length !== 0) {
      getAllCopiedSegmentsByLegacyExternalIdsUtils({
        getAllCopiedSegmentsByLegacyExternalIdsRequest,
        legacyExternalIds: sources.map(({ id }) => id),
        t,
        update,
      })
    }
  }, [sources])

  useEffect(() => {
    if (tracks.length !== 0 && showUpgradeSidePanel) {
      const stepsToUpgrade = getStepsToUpgrade(tracks, program.sources)
      update({ stepsToUpgrade, upgradedSteps: upgradedSteps.length != 0 ? upgradedSteps : stepsToUpgrade })
    }
  }, [program, showUpgradeSidePanel])

  useEffect(() => {
    if (unifiedListFieldMappings?.length !== 0) {
      const allContactsFieldsOptions: SelectV2SingleOption[] =
        unifiedListFieldMappings
          ?.filter(({ readOnly }) => !readOnly)
          ?.map(({ displayName = '' }: UnifiedListFieldMapping) => ({
            label: displayName,
            value: displayName,
          })) ?? []
      update({ allContactsFieldsOptions })
    }
  }, [unifiedListFieldMappings])

  useEffect(() => {
    if (isSuccessfullyUpgraded) {
      setState((state) => ({ ...state, activeUpgradeStep: UpgradeStep.SUCCESSFULLY_UPGRADED }))
    }
  }, [isUpgrading])

  useEffect(() => {
    getStandardFieldsRequest().then((standardFields) => {
      update({ standardFields })
    })
  }, [])

  const update = (values: Partial<UpgradeManagerContainerState>) => setState((state) => ({ ...state, ...values }))

  const goToNextOrPreviousStep = (current: number) =>
    update({
      currentStepPosition: current,
      currentStep: stepsToUpgrade[current - 1],
      upgradedCurrentStep: upgradedSteps[current - 1],
    })

  const saveStep = () => {
    if (!!upgradedProgram?.tracks && !!upgradedCurrentStep) {
      const updatedUpgradedSteps = upgradedSteps?.map((step) => {
        if (step.stepId === upgradedCurrentStep?.stepId) {
          if (upgradedCurrentStep?.stepType === ProgramStepType.COPY) {
            const appendToSegmentStep: UpgradedProgramAppendToSegmentStep = {
              ...(upgradedCurrentStep as UpgradedProgramAppendToSegmentStep),
              __typename: 'ProgramAppendToSegmentStep',
              stepType: ProgramStepType.APPEND_TO_SEGMENT,
            }
            return appendToSegmentStep
          }
          return upgradedCurrentStep
        }
        return step
      })

      update({
        upgradedSteps: updatedUpgradedSteps,
      })
    }
  }

  const getUpgradedEarlyExitConditions = (upgradedProgram: Program, upgradedEarlyExitConditions?: UpgradedEarlyExitCondition[]): ProgramExit => {
    const { exit } = upgradedProgram
    return { ...exit, exitChoices: (upgradedEarlyExitConditions ?? []).filter(({ srcId }) => !!srcId) }
  }

  const getTracksWithRemovedSteps = (upgradedProgram: Program, upgradedSteps: UpgradedProgramStep[]) => {
    let tracks: ProgramTrack[] | undefined = upgradedProgram.tracks
    const removedSteps = upgradedSteps.filter(({ removed }) => removed)
    removedSteps.forEach((step) => {
      const tree: ProgramFlowTree = BuildProgramFlow.buildTrees(tracks ?? [], t, upgradedProgram.stepTemplates, undefinedReturnedFunction)
      tracks = programFlowUtil.deleteStep(step as Step, tree)
    })
    return tracks ?? []
  }

  const getUpgradedSendEmailMessageSteps = (upgradedSteps: UpgradedProgramStep[]): UpgradedProgramStep[] => {
    return upgradedSteps.map((step) => {
      if (step.stepType === ProgramStepType.SEND) {
        const sendEmailMessageStep = step as UpgradedProgramSendStep
        return {
          ...sendEmailMessageStep,
          sendChoices: sendEmailMessageStep.sendChoices.map((choice) => {
            const { newSrcId, choiceId: _choiceId, ...rest } = choice
            return { ...rest, srcId: newSrcId ?? choice.srcId }
          }),
        }
      }
      return step
    })
  }

  const getUpgradedChangeFieldValueSteps = (upgradedSteps: UpgradedProgramStep[]): UpgradedProgramStep[] => {
    return upgradedSteps.map((step) => {
      if (step.stepType === ProgramStepType.FIELD_SET) {
        const changeFieldValueStep = step as UpgradedProgramRecordSetStep
        return {
          ...changeFieldValueStep,
          listId: allActOnContactsSegment?.externalId,
          operations: changeFieldValueStep.operations.map((operation) => {
            const { newField, operationId: _, ...rest } = operation
            return { ...rest, fieldName: newField?.name }
          }),
          conditions: changeFieldValueStep.conditions.map((condition) => {
            const { operations, conditionId: _, upgraded: __, ...rest } = condition
            return {
              ...rest,
              operations: operations.map((operation) => {
                const { newField, operationId: _, ...rest } = operation
                return {
                  ...rest,
                  fieldName: newField?.name,
                }
              }),
            }
          }),
        }
      }
      return step
    })
  }

  const getUpgradedTracks = (upgradedProgram: Program, upgradedSteps: UpgradedProgramStep[]): ProgramTrack[] => {
    return upgradedProgram.tracks.map((track) => ({
      ...track,
      steps: track.steps.map((step) => {
        const stepFound = upgradedSteps.find((upgradedStep) => upgradedStep.stepId === step.stepId)
        if (stepFound) {
          return stepFound
        }
        return step
      }),
    }))
  }

  const doUpgradeSteps = (upgradedSteps: UpgradedProgramStep[]) => {
    let upgradedStepsTemp = upgradedSteps
    upgradedStepsTemp = getUpgradedSendEmailMessageSteps(upgradedStepsTemp)
    upgradedStepsTemp = getUpgradedChangeFieldValueSteps(upgradedStepsTemp)
    return upgradedStepsTemp
  }

  const doUpgradeProgram = (upgradedProgram: Program, upgradedSteps: UpgradedProgramStep[]) => {
    const upgradedProgramTemp = upgradedProgram
    upgradedProgramTemp.exit = getUpgradedEarlyExitConditions(upgradedProgramTemp, upgradedEarlyExitConditions)
    upgradedProgramTemp.tracks = getTracksWithRemovedSteps(upgradedProgramTemp, upgradedSteps)
    upgradedProgramTemp.tracks = getUpgradedTracks(upgradedProgramTemp, upgradedSteps)
    upgradedProgramTemp.isAOCUpgraded = true
    upgradedProgramTemp.srcId = programUrlId

    if (switchToManualStart) {
      upgradedProgramTemp.schedule = {
        ...upgradedProgramTemp.schedule,
        type: ProgramScheduleType.NEVER,
      }
    }

    return upgradedProgramTemp
  }

  const doUpgradeProcess = async () => {
    if (upgradedProgram) {
      doCreateNewFieldMappings()
      const upgradedStepsTemp = doUpgradeSteps(upgradedSteps)
      const upgradedProgramTemp = doUpgradeProgram(upgradedProgram, upgradedStepsTemp)
      update({ upgradedSteps: upgradedStepsTemp, upgradedProgram: upgradedProgramTemp })
      await saveProgram(upgradedProgramTemp)
      clearSessionData()
    }
  }

  const upgradeProgram = () => {
    if (program?.runStatus?.isRunning) {
      pauseProgramRequest(programUrlId).then(() => {
        doUpgradeProcess()
      })
    } else {
      doUpgradeProcess()
    }
  }

  // GraphQL Requests

  const upgradeSources = (step: UpgradeSourcesStep) => {
    let newSources: UpgradedSource[] = []
    let newStepSources: BaseList[] = []
    if (step === UpgradeStep.CONTACT_SOURCES) {
      newSources = upgradedSources ?? []
    } else if (step === UpgradeStep.EARLY_EXITS) {
      newSources = upgradedEarlyExitSources ?? []
    } else {
      newSources = upgradedEarlyExitSources ?? []
      newStepSources = Object.values(upgradedStepSources).flatMap((sources) => Object.values(sources))
    }
    const getListSchema = async () =>
      await getListSchemaRequestUtils({
        getListSchemaRequest,
        listIds:
          step === UpgradeStep.FIX_STEP_ISSUES
            ? [...newSources.map(({ newSource }) => newSource?.id), ...newStepSources.map((newSource) => newSource?.id)].filter(
                filterNotEmptyArray
              ) ?? []
            : newSources
                ?.filter(({ removed }) => !removed)
                .map(({ newSource }) => newSource?.id)
                .filter(filterNotEmptyArray) ?? [],
        t,
        update,
      })
    if (!!upgradedProgram) {
      getListSchema().then((newSources) => {
        if (step === UpgradeStep.CONTACT_SOURCES) {
          update({ upgradedProgram: { ...upgradedProgram, sourceList: newSources ?? [] } })
        } else if (step === UpgradeStep.EARLY_EXITS) {
          update({ upgradedProgram: { ...upgradedProgram, sources: [...upgradedProgram?.sourceList, ...(newSources ?? [])] } })
        } else {
          update({ upgradedProgram: { ...upgradedProgram, sources: [...upgradedProgram?.sources, ...(newSources ?? [])] } })
        }
      })
    }
  }

  const onSaveError = () => {
    if (showUpgrade) {
      update({
        statusToast: {
          message: t('An error occurred while trying to save your program.'),
          status: Status.FAIL,
          showStatusToast: true,
        },
      })
    } else {
      openPopup('saveError')
    }
  }

  const doCreateNewFieldMappings = async () => {
    const response = await createNewFieldMappings(upgradedSteps, createNewUCLFieldMapping)
    if (response?.errors) {
      logNewRelicError(response.errors)
      update({
        statusToast: {
          message: t('An error occurred while trying to create new fields.'),
          status: Status.FAIL,
          showStatusToast: true,
        },
      })
    }
  }

  const saveProgram = async (upgradedProgram: Program) => {
    update({ isUpgrading: true })
    const { data, errors } = await saveProgramRequest(upgradedProgram)
    if (errors) {
      logNewRelicError(errors)
      onSaveError()
    }

    if (data) {
      const { saveProgram: saveProgramResult } = data

      if (saveProgramResult.status === 'saved') {
        await saveProgramFinalRequest(upgradedProgram?.id ?? '')
        const contactsCount = await getProgramContactsCount(programUrlId)
        update({ isSuccessfullyUpgraded: true, contactsCount })
      } else {
        onSaveError()
      }
    }
    update({ isUpgrading: false })
  }

  return (
    <UpgradeManagerContext.Provider
      value={{
        values: state,
        goToNextOrPreviousStep,
        saveStep,
        upgradeSources,
        upgradeProgram,
        update,
      }}
    >
      {showUpgrade ? (
        <UpgradeProgramsContainer />
      ) : (
        <div className={showUpgradeSidePanel ? `${rootClass}__not-clickable-container` : undefined}>{children}</div>
      )}
      <UpgradeManager className={rootClass} clearSession={clearSessionData} updateSession={updateSessionData} />
    </UpgradeManagerContext.Provider>
  )
}
