import React, { FC, Fragment } from 'react'

import classNames from 'classnames'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { legacyActonContext, useTranslation } from '@const/globals'
import ProgramStepIcon from '@src/pages/programs/edit/components/ProgramFlow/components/ProgramStepIcon'
import { ProgramStepType } from '@utils/program/program.constants'

import './BranchStepDetail.css'

interface BranchItem {
  name: string
  id: string
}

export interface BranchStepDetailProps {
  programId: string
  condition: string
  messages?: BranchItem[]
  list?: BranchItem
  goToStepName: string
  goToStepType: ProgramStepType
  className?: string
  dataTest?: string
}

const rootClass = 'branch-step-detail'

const BranchStepDetail: FC<BranchStepDetailProps> = (props: BranchStepDetailProps) => {
  const { programId, condition, messages, list, goToStepName, goToStepType, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()

  const renderBranchItem = (item: BranchItem, icon: SvgNames, baseUrl: string, isLast: boolean, isFirst: boolean) => {
    const link = baseUrl ? `${baseUrl}${item.id}` : undefined
    const onClick = () => window.open(link, 'previewBranchItem', 'width=1000,height=600')

    return (
      <Fragment key={item.id}>
        {!isFirst && <Typography text={isLast ? ` ${t('or')}` : ','} inline />}
        <Svg className={`${rootClass}__svg`} name={icon} type={SvgType.ICON} />
        {item.name && <TextLink text={item.name} size={TextLinkSize.LARGE} hideIcon onClick={onClick} />}
        {!item.name && <Typography text={t('undefined')} weight={TextWeight.ITALIC} inline />}
      </Fragment>
    )
  }

  const renderMessages = () => {
    const messageUrl = `${legacyActonContext}/messages/messageSentFrame.jsp?id=${programId}&msgid=`
    return messages
      ? messages.map((message, idx) => {
          const isLast = idx === messages.length - 1
          const isFirst = idx === 0
          return renderBranchItem(message, SvgNames.letter, messageUrl, isLast, isFirst)
        })
      : undefined
  }

  const renderList = () => {
    const listUrl = `${legacyActonContext}/lists/viewList.jsp?id=`
    return list ? renderBranchItem(list, SvgNames.contacts, listUrl, false, true) : undefined
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__condition`}>
        <Typography text={condition} inline />
        {messages && renderMessages()}
        {list && renderList()}
      </div>
      <>
        <Typography text={t('Go to step')} inline className={`${rootClass}__go-to`} />{' '}
        <ProgramStepIcon className={`${rootClass}__icon`} stepType={goToStepType} iconOnly overrideColorAndHeight />
        {goToStepName !== '' && <Typography text={goToStepName} weight={TextWeight.MEDIUM} inline />}
        {goToStepName === '' && <Typography text={t('undefined')} weight={TextWeight.ITALIC} inline />}
      </>
    </div>
  )
}

export default BranchStepDetail
