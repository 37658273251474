import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { LabelProps, LabelType, LabelV2 } from '@components/LabelV2/LabelV2'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { a11yClick } from '@utils/a11y'

import './AssetSelect.css'

export interface AssetSelectProps {
  /** Displays as a standard Button if no `selectedAsset` is provided */
  isButtonLaunch?: boolean
  /** The text that displays for the Replace button if `isButtonLaunch` is `false`, or there is a `selectedAsset` provided */
  replaceText?: string
  onClick: () => void
  /** Single or multiple asset names */
  selectedAssets?: string | string[]
  /** Displays if no `selectedAsset` is provided */
  placeholder?: string
  /** Displays next to the `selectedAsset` or `placeholder` */
  svgIcon?: SvgNames
  /** If provided, a Label will show at the top */
  labelProps?: LabelProps
  /** If provided, this text will appear at the bottom */
  helperText?: string
  disabled?: boolean
  error?: boolean
  className?: string
  dataTest?: string
  /** Provides some flexibility in how the `selectedAsset` text is rendered, used only when there is a one-off variation in the design */
  renderCustomSelectedAsset?: () => ReactNode

  // Multiple Asset Select Props

  /** Alternate behavior which displays the number of assets selected (if > 1) and a secondary button to the left of the Replace button */
  allowMultiple?: boolean
  /** Text that displays on the secondary Button */
  secondaryText?: string
  onSecondaryClick?: () => void
  /** Displays i18n key `AssetSelect.Count_${assetType}` if multiple assets are selected */
  assetType?: string
  //secondaryEdit button is made to came after insert,and allow users to edit and not only replace selected media/file
  secondaryEditButton?: string
  onSecondaryEditButtonClick?: () => void
}

const rootClass = 'asset-select'

const AssetSelect: FC<AssetSelectProps> = (props: AssetSelectProps) => {
  const { t } = useTranslation()
  const {
    placeholder = t('Select asset'),
    svgIcon,
    onClick,
    labelProps,
    isButtonLaunch = false,
    allowMultiple,
    disabled,
    renderCustomSelectedAsset = (content: ReactNode) => content,
    className = rootClass,
    dataTest = rootClass,
    assetType = 'default',
    error,
    helperText,
    secondaryText = t('View'),
    onSecondaryClick = () => null,
    secondaryEditButton,
    onSecondaryEditButtonClick = () => null,
  } = props

  let selectedAssets: string[] = []
  if (Array.isArray(props.selectedAssets)) {
    selectedAssets = props.selectedAssets
  } else if (props.selectedAssets) {
    selectedAssets = [props.selectedAssets]
  }

  let replaceText = props.replaceText
  if (!replaceText) {
    if (!selectedAssets.length) {
      replaceText = t('Select')
    } else {
      replaceText = allowMultiple ? t('Edit') : t('Replace')
    }
  }
  const showButtonLaunch = isButtonLaunch && !selectedAssets.length

  const renderHelperText = () => {
    let icon = null
    let type = TextType.BODY_TEXT_SMALL_LIGHT

    if (error) {
      icon = <Svg name={SvgNames.inputStatusInvalidNoFill} type={SvgType.ICON} fill={SvgColor.ERROR_TEXT} />
      type = TextType.VALIDATION_ERROR
    }

    return helperText ? (
      <div className={`${rootClass}__helper-text`} data-test={`${dataTest}-helper-text`}>
        {icon}
        <Typography type={type}>{helperText}</Typography>
      </div>
    ) : null
  }

  const selectedAssetText =
    selectedAssets.length === 0
      ? undefined
      : selectedAssets.length === 1
      ? selectedAssets[0]
      : t(`AssetSelect.Count`, { context: assetType, count: selectedAssets.length })

  const selectedAssetTextType = disabled
    ? TextType.NORMAL_TEXT_DISABLED_LARGE
    : !selectedAssets.length
    ? TextType.BODY_TEXT_LIGHT
    : TextType.BODY_TEXT

  const buttonTypographyProps = {
    type: disabled ? TextType.NORMAL_TEXT_DISABLED : TextType.NORMAL_TEXT_TEAL,
    weight: TextWeight.MEDIUM,
    className: 'ellip',
  }

  return (
    <div className={classNames(className, rootClass)} data-test={dataTest}>
      {labelProps && <LabelV2 {...labelProps} labelType={LabelType.input} dataTest={`${dataTest}-label`} />}
      {showButtonLaunch ? (
        <Button
          className={classNames(`${rootClass}__button-launch`)}
          buttonType={ButtonType.OUTLINE}
          fullWidth
          disabled={disabled}
          dataTest={`${dataTest}-button`}
          {...a11yClick(onClick, disabled)}
        >
          {svgIcon && <Svg name={svgIcon} />}
          {t(placeholder)}
        </Button>
      ) : (
        <div
          role={'button'}
          className={classNames(`${rootClass}__default-launch`, {
            [`${rootClass}__default-launch--error`]: error,
            [`${rootClass}__default-launch--disabled`]: disabled,
          })}
          data-test={`${dataTest}-default`}
          {...a11yClick(onClick, disabled)}
        >
          <div className={`${rootClass}__default-launch__asset-label`} data-test={`${dataTest}-selected-asset`}>
            {svgIcon && (
              <Svg
                name={svgIcon}
                type={SvgType.LARGER_ICON}
                fill={disabled ? SvgColor.BUTTON_GRAY : error ? SvgColor.ERROR_TEXT : undefined}
                className={classNames(`${rootClass}__default-launch__asset-label__icon`, {
                  [`${rootClass}__default-launch__asset-label__icon--placeholder`]: !selectedAssets.length,
                  [`${rootClass}__default-launch__asset-label__icon--error`]: error,
                })}
              />
            )}
            {renderCustomSelectedAsset(<Typography type={selectedAssetTextType} text={selectedAssetText ?? placeholder} className={'ellip'} />)}
          </div>
          <div className={`${rootClass}__default-launch__button-container`}>
            {allowMultiple && selectedAssets.length > 1 && (
              <button
                className={classNames(`${rootClass}__default-launch__button`, `${rootClass}__default-launch__button--left`)}
                disabled={disabled}
                data-test={`${dataTest}-secondary-button`}
                onClick={(event) => {
                  event.stopPropagation()
                  event.preventDefault()
                  onSecondaryClick()
                }}
              >
                <Typography text={t(secondaryText)} {...buttonTypographyProps} />
              </button>
            )}
            {secondaryEditButton && !!selectedAssets.length && (
              <div
                className={classNames(`${rootClass}__default-launch__replace-container`, {
                  [`${rootClass}__default-launch__replace-container--disabled`]: disabled,
                })}
              >
                <div className={`${rootClass}__default-launch__replace-container__no__divider`}>
                  <button
                    className={classNames(`${rootClass}__default-launch__button`, `${rootClass}__default-launch__button--right`)}
                    data-test={`${dataTest}-replace-button`}
                    disabled={disabled}
                    onClick={(event) => {
                      event.stopPropagation()
                      event.preventDefault()
                      onSecondaryEditButtonClick()
                    }}
                  >
                    <Typography text={t(secondaryEditButton)} {...buttonTypographyProps} />
                  </button>
                </div>
              </div>
            )}
            <div
              className={classNames(`${rootClass}__default-launch__replace-container`, {
                [`${rootClass}__default-launch__replace-container--disabled`]: disabled,
              })}
            >
              <div className={`${rootClass}__default-launch__replace-container__divider`}>
                <button
                  className={classNames(`${rootClass}__default-launch__button`, `${rootClass}__default-launch__button--right`)}
                  data-test={`${dataTest}-replace-button`}
                  disabled={disabled}
                  onClick={(event) => {
                    event.stopPropagation()
                    event.preventDefault()
                    onClick()
                  }}
                >
                  <Typography text={t(secondaryEditButton && selectedAssets.length ? secondaryText : replaceText)} {...buttonTypographyProps} />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {renderHelperText()}
    </div>
  )
}

export default AssetSelect
