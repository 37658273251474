import React, { FC } from 'react'

import BackButton from '@components/BackButton/BackButton'
import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import { ModalHeader } from '@components/Modal'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Svg, { SvgNames } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { ModalHeaderFormStyle } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

interface Props {
  isAddSingle: boolean
  onClick: () => void
}

import './InviteUsersModalHeader.css'

const rootClass = 'invite-users-modal-header'
const dataTest = rootClass

const InviteUsersModalHeader: FC<Props> = ({ isAddSingle, onClick }) => {
  const { t } = useTranslation()

  return (
    <ModalHeader headerType={ModalHeaderType.Form} className={rootClass} dataTest={dataTest}>
      <div className={`${rootClass}__back`}>
        {!isAddSingle && <BackButton dataTest={`${dataTest}__back-button`} onClick={onClick} />}
        <Typography text={t('Invite.Users.Modal.Title', { context: isAddSingle ? 'single' : 'multiple' })} {...ModalHeaderFormStyle} inline />
      </div>
      {isAddSingle && (
        <Tooltip
          className={`${rootClass}__tooltip`}
          position="left"
          sideOffset={9}
          trigger={
            <Button
              buttonType={ButtonType.FLOAT}
              onClick={onClick}
              iconPosition={ButtonIconPosition.LEFT}
              dataTest={`${dataTest}-add-multiple-button`}
              className={`${rootClass}__add-multiple-button`}
            >
              <Svg name={SvgNames.plus} />
              {t('Add.Multiple.Users.Btn.Label')}
            </Button>
          }
        >
          {t('Add.Multiple.Users.Btn.Tooltip')}
        </Tooltip>
      )}
    </ModalHeader>
  )
}

export default InviteUsersModalHeader
