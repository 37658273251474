import { TFunction } from 'i18next'

import { RibbonMedals } from '@components/CarouselDataCard/CarouselDataCardUtils'
import { FormDataCards } from '@graphql/types/microservice/data-lake-query-types'
import { DATA_PERIOD, ribbonsData } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.constants'
import { getFormattedNumber } from '@utils/numbers'

export enum FormJoinViewDataCardsOption {
  SELECTED_OPTION = 'formJoinView:SelectedOption',
}

export const getTopPerformingForms = (forms: FormDataCards[], t: TFunction): RibbonMedals[] =>
  forms
    .filter((form) => form.totalInserts + form.totalUpdates != 0)
    .map((form, index) => {
      const count = form.totalInserts + form.totalUpdates
      return {
        title: form.formName as string,
        subTitle: t(`{{formattedCount}} submissions`, { count, formattedCount: getFormattedNumber(count) }),
        ...ribbonsData[index],
      }
    })

export const loadDataCards = (update: Function, days: DATA_PERIOD, getAllFormsData: Function, t: TFunction) => {
  getAllFormsData(days).then((data: any) => {
    update({
      topPerformingFormsCards: { cards: getTopPerformingForms(data.topForms, t), loading: false },
      submissionsOverViewsCard: { submissions: data.totalSubmissions, views: data.totalViews, loading: false },
      activeFormsCard: { totalSubmissions: data.totalForms, active: data.totalActiveForms, loading: false },
    })
  })
}
