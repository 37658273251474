import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { PersonalizationModals } from '@complex/Personalization/Personalization.constants'
import Button, { ButtonType } from '@components/Button'
import { ButtonIconPosition, ButtonSize, ButtonWeight } from '@components/Button/Button'
import HoverSVG from '@components/HoverSVG/HoverSVG'
import { SvgNames, SvgType } from '@components/Svg'
import Svg, { SvgColor } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'

import { PersonalizationModalContext } from '../utils/Personalization.context'

import './AddRecipientsBlock.css'

const rootClass = 'add-recipients-block'

export const AddRecipientsBlock = () => {
  const { t } = useTranslation()
  const { isAutomatedPrograms, update, selectedListIds, disableAddRecipientsButton } = useContext(PersonalizationModalContext)

  const AddRecipientsButton = (
    <Button
      cursorIsNotAllowed={disableAddRecipientsButton}
      className={`${rootClass}__button`}
      buttonType={ButtonType.INFO}
      buttonSize={ButtonSize.MEDIUM}
      weight={ButtonWeight.MEDIUM}
      iconPosition={ButtonIconPosition.LEFT}
      onClick={() => update({ activeModal: PersonalizationModals.ADD_RECIPIENTS })}
      dataTest={`${rootClass}-add-recipient-button`}
      disabled={disableAddRecipientsButton}
    >
      <Svg name={SvgNames.plus} type={SvgType.ICON} fill={SvgColor.TEXT_TEAL} dataTest={`${rootClass}-add-recipient-button-svg`} />
      <Typography text={t('Add recipients')} type={TextType.EMPHASIZED_TEXT_TEAL} weight={TextWeight.MEDIUM} lineHeight={LineHeight.MEDIUM} />
    </Button>
  )

  return (
    <div className={rootClass}>
      <div className={`${rootClass}__info`}>
        <div className={`${rootClass}__info-header`}>
          <Typography
            text={`${t(
              isAutomatedPrograms
                ? 'Personalization.addRecipientsBlock.automatedPrograms.text'
                : 'Personalization.addRecipientsBlock.emailComposer.text',
              {
                count: selectedListIds?.split(',').length,
              }
            )}`}
            weight={TextWeight.MEDIUM}
            lineHeight={LineHeight.MEDIUM}
            dataTest={`${rootClass}-current-lists/segments-typography`}
          />

          <Tooltip
            align="center"
            position="right"
            trigger={<HoverSVG svg={SvgNames.info} hoverSvg={SvgNames.infoHover} type={SvgType.LARGER_ICON} />}
          >
            {t(
              isAutomatedPrograms
                ? 'Personalization.addRecipientsBlock.automatedPrograms.tooltip'
                : 'Personalization.addRecipientsBlock.emailComposer.tooltip'
            )}
          </Tooltip>
        </div>
      </div>

      {!isAutomatedPrograms && (
        <Tooltip align="center" position="right" hide={!disableAddRecipientsButton} trigger={AddRecipientsButton}>
          {t('Personalization.addRecipientModal.listOrSegment.disabledTooltip')}
        </Tooltip>
      )}
    </div>
  )
}
