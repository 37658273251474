import React, { CSSProperties, FC, ReactNode, useEffect, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { ButtonWeight } from '@components/Button/Button'
import Typography from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './toast.css'

const rootClass = 'toast'
const TIMEOUT_TIME = 7000
export const TOAST_TEXT_CLASSNAME = `${rootClass}__text`

interface ToastProps {
  icon?: ReactNode
  text?: string | ReactNode
  closeText?: string
  onClose?(): void
  dataTest?: string
  noPadding?: boolean
  timeout?: boolean
  className?: string
  style?: CSSProperties
  customTimeoutTime?: number
}

export const Toast: FC<ToastProps> = (props: ToastProps) => {
  const {
    icon,
    text,
    closeText = 'Dismiss',
    onClose,
    className,
    noPadding = false,
    timeout = false,
    dataTest = 'toast',
    style,
    customTimeoutTime,
  } = props
  const { t } = useTranslation()
  const [timeoutSet, setTimeoutSet] = useState(false)
  const [slideout, setSlideout] = useState(false)

  useEffect(() => {
    if (timeout && onClose && !timeoutSet) {
      const onCloseTimeout = setTimeout(() => onClose(), customTimeoutTime || TIMEOUT_TIME)
      const setSlideoutTimeout = setTimeout(() => setSlideout(true), customTimeoutTime || TIMEOUT_TIME - 500)
      setTimeoutSet(true)

      return () => {
        clearTimeout(onCloseTimeout)
        clearTimeout(setSlideoutTimeout)
      }
    }
  }, [timeout])

  return (
    <div
      className={classNames(rootClass, className, {
        [`${rootClass}__slideout`]: slideout,
      })}
      data-test={dataTest}
      style={style}
    >
      <div
        className={classNames(`${rootClass}__toast`, {
          [`${rootClass}__no-padding`]: noPadding,
        })}
      >
        {icon}
        {typeof text === 'string' ? <Typography text={t(text)} className={TOAST_TEXT_CLASSNAME} /> : text}
        {!timeout && onClose && (
          <Button dataTest={`${dataTest}-close-button`} buttonType={ButtonType.TEXT_TEAL} weight={ButtonWeight.MEDIUM} onClick={onClose}>
            {t(closeText)}
          </Button>
        )}
      </div>
    </div>
  )
}

export default Toast
