import React from 'react'

import ButtonMenu, { ButtonMenuOption } from '@components/ButtonMenu/ButtonMenu'
import svgNames from '@components/Svg/SvgNames'
import { rootContext } from '@const/globals'
import { PrevPageParamsEnum } from '@src/pages/EmailComposer/utils/EmailComposer.types'

export const T_BEE_BLANK = 't-bee-blank'

const ASSET = {
  id: 'assetLink',
  text: 'Asset Link',
  image: svgNames.assetLink,
  imageHover: svgNames.assetLinkHover,
}

const IMAGE = {
  id: 'imageBlock',
  text: 'Image',
  image: svgNames.imageBlock,
  imageHover: svgNames.imageBlockHover,
}

const DYNAMIC_CONTENT = {
  id: 'dynamicContentBlock',
  text: 'Dynamic Content',
  image: svgNames.dynamicContentBlock,
  imageHover: svgNames.dynamicContentBlockHover,
  dividerAfter: true,
}

const HEADER = {
  id: 'headerBlock',
  text: 'Header',
  image: svgNames.headerBlock,
  imageHover: svgNames.headerBlockHover,
}

const FOOTER = {
  id: 'footerBlock',
  text: 'Footer',
  image: svgNames.footerBlock,
  imageHover: svgNames.footerBlockHover,
}

const RSS = {
  id: 'rss',
  text: 'RSS',
  image: svgNames.rss,
  imageHover: svgNames.rssHover,
  dividerAfter: true,
}

const getDefaultInsertOptions = (isPlainText: boolean) => {
  if (isPlainText) {
    return [ASSET, IMAGE, DYNAMIC_CONTENT, HEADER, FOOTER]
  } else {
    return [ASSET, IMAGE]
  }
}

const optionsWithRSS = [
  ASSET,
  IMAGE,
  {
    ...DYNAMIC_CONTENT,
    dividerAfter: false,
  },
  RSS,
  HEADER,
  FOOTER,
]

export const getButtonMenuProps = (showRss = false, isPlainText: boolean): React.ComponentProps<typeof ButtonMenu> => {
  return {
    triggerText: 'Insert',
    align: 'start',
    options: showRss ? optionsWithRSS : getDefaultInsertOptions(isPlainText),
    // eslint-disable-next-line no-console
    onSelect: (option: ButtonMenuOption) => console.log(`Selected option: ${option.text}`),
  }
}

export const prevPageLinks: Record<PrevPageParamsEnum, string> = {
  [PrevPageParamsEnum.classicDrafts]: `${rootContext}/classic/draftLists`,
  [PrevPageParamsEnum.default]: `${rootContext}/classic/dashboard`,
  [PrevPageParamsEnum.drafts]: `${rootContext}/outbound/drafts`,
  [PrevPageParamsEnum.emailTemplate]: `${rootContext}/content/emailTemplates`,
  [PrevPageParamsEnum.forms]: `${rootContext}/content/forms`,
  [PrevPageParamsEnum.abTest]: `${rootContext}/classic/if/messages/listingABTest.jsp`,
  [PrevPageParamsEnum.webinar]: `${rootContext}/classic/if/webinar/listing.jsp`,
  [PrevPageParamsEnum.rss]: `${rootContext}/classic/if/_rss/rssListing.jsp`,
  [PrevPageParamsEnum.landingPage]: `${rootContext}/content/landingPages`,
}

export const UPDATE_SESSION_DELAY = 20000

export const ANTI_SPAM_PHYSICAL_MAILING_LINK =
  'https://connect.act-on.com/hc/en-us/articles/360023943113-Setting-Profile-and-Account-Information#h_01HRWB3XDK45ZMK4R1VZHPTS60'
