import React, { FC, useContext } from 'react'

import classNames from 'classnames'

import Checkbox from '@components/Checkbox'
import Container from '@components/Container'
import MoreOptions from '@components/MoreOptions/MoreOptions'
import RadioWithOptions from '@components/RadioWithOptions/RadioWithOptions'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ImportContactsV2Context } from '@src/pages/ImportContactsV2/utils/ImportContactsV2.context'
import { convertScheduleToTime } from '@src/pages/programs/dashboard/components/ProgramSteps/ProgramSteps.utils'

import './ReviewImportOptions.css'

interface ReviewImportOptionsProps {
  className?: string
  dataTest?: string
}

const rootClass = 'review-import-options'

const ReviewImportOptions: FC<ReviewImportOptionsProps> = (props: ReviewImportOptionsProps) => {
  const { dataTest = rootClass, className = '' } = props

  const { t } = useTranslation()

  const {
    values: { addToSegment, segmentSelectedOption, schedule },
  } = useContext(ImportContactsV2Context)

  return (
    <Container className={classNames(rootClass, className)} dataTest={dataTest}>
      <Typography
        className={`${rootClass}__header`}
        text={t('Import options')}
        type={TextType.BODY_TEXT_LARGE}
        weight={TextWeight.MEDIUM}
        lineHeight={LineHeight.MEDIUM_LARGE}
      />
      <div>
        <div className={`${rootClass}__section`}>
          <Typography className={`${rootClass}__section-header`} text={t('Segment rules')} weight={TextWeight.MEDIUM} />
          <div className={`${rootClass}__section-content`}>
            <Checkbox label={t('Add records to a segment')} checked={addToSegment} disabled showTickOnDisabled />
            {addToSegment && (
              <MoreOptions className={`${rootClass}__segment-name`}>
                <Svg name={SvgNames.listsAndSegments} type={SvgType.LARGER_ICON} />
                <Typography text={segmentSelectedOption?.label} weight={TextWeight.MEDIUM} />
              </MoreOptions>
            )}
          </div>
        </div>
        <div className={`${rootClass}__section`}>
          <Typography className={`${rootClass}__section-header`} text={t('Sync frequency')} weight={TextWeight.MEDIUM} />
          <div className={`${rootClass}__section-content`}>
            <RadioWithOptions label={t(schedule.isScheduled ? 'On a schedule' : 'Off')} checked={schedule.isScheduled} disabled>
              <Typography text={convertScheduleToTime(schedule, t)} />
            </RadioWithOptions>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default ReviewImportOptions
