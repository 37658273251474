import React, { FC, KeyboardEvent, ReactNode } from 'react'
import { UseGlobalFiltersInstanceProps, UseGlobalFiltersState } from 'react-table'

import Input, { InputProps, InputType } from '@components/Input/Input'
import InputWithClear from '@components/InputWithClear/InputWithClear'

interface Props {
  placeholder?: string
  infoText?: string | ReactNode
  rootClass?: string
  canClear?: boolean
}
type GlobalFilterProps = UseGlobalFiltersState<{}> & Pick<UseGlobalFiltersInstanceProps<{}>, 'preGlobalFilteredRows' | 'setGlobalFilter'> & Props

const GlobalFilter: FC<GlobalFilterProps> = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  placeholder,
  infoText,
  rootClass = '',
  canClear = false,
}: GlobalFilterProps) => {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const [isUpdated, setIsUpdated] = React.useState(false)

  const triggerUpdate = () => {
    const oldValueLength = globalFilter?.length ?? 0
    const newValueLength = value?.length ?? 0
    if (newValueLength === 1 && newValueLength < oldValueLength) {
      setGlobalFilter(value || undefined)
    }
    if (newValueLength !== 1) {
      setGlobalFilter(value || undefined)
    }

    setIsUpdated(true)
  }

  const onClear = () => {
    setValue('')
    setGlobalFilter('')
  }

  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      triggerUpdate()
    }
  }

  const inputProps: InputProps = {
    inputAriaLabel: 'globalFilter',
    value: value || '',
    inputType: InputType.SEARCH,
    onChange: (e) => {
      setIsUpdated(false)
      setValue(e.target.value)
    },
    onKeyPress: (e) => {
      isUpdated || onKeyPress(e)
    },
    onBlur: () => {
      isUpdated || triggerUpdate()
    },
    placeholder: placeholder ?? `Search: ${count} records...`,
  }

  return (
    <div className={`${rootClass}__global-filter`}>
      <div className={`${rootClass}__global-filter-info-text`}>{infoText}</div>
      {canClear ? (
        <InputWithClear inputProps={{ ...inputProps, className: `${rootClass}__search` }} onClear={onClear} />
      ) : (
        <Input {...inputProps} className={`${rootClass}__search`} />
      )}
    </div>
  )
}

export default GlobalFilter
