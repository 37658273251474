import { FC, PropsWithChildren, useEffect } from 'react'
import { createPortal } from 'react-dom'

import './SingleSelectDropdownPortal.css'

interface SingleSelectDropdownPortalProps {
  selectElement: HTMLDivElement | null
  onClose: () => void
  scrollWrapperId?: string
  minWidth?: number
}

export const singleSelectDropdownRootClass = 'single-select-dropdown-root'

const dropdownContainer = document.createElement('div')
dropdownContainer.className = `${singleSelectDropdownRootClass}__dropdown-container`

const SingleSelectDropdownPortal: FC<PropsWithChildren<SingleSelectDropdownPortalProps>> = ({ children, selectElement, onClose, minWidth = 0 }) => {
  useEffect(() => {
    const keydownListener = (e: KeyboardEvent) => {
      if (e.key === 'Escape' || e.key === 'Tab') {
        onClose()
      }
    }
    const clickListener = (e: MouseEvent) => {
      if (!dropdownContainer.contains(e.target as Node)) {
        !selectElement?.contains(e.target as Node) && onClose()
      } else {
        e.stopPropagation()
        const evt = new CustomEvent('optionSelect', { bubbles: true, cancelable: true })
        e.target?.dispatchEvent(evt)
      }
    }

    document.addEventListener('keydown', keydownListener)
    window.addEventListener('click', clickListener, { capture: true })
    return () => {
      document.removeEventListener('keydown', keydownListener)
      window.removeEventListener('click', clickListener, { capture: true })
    }
  }, [onClose])

  useEffect(() => {
    let dropdownRoot = document.getElementById(singleSelectDropdownRootClass)
    if (!dropdownRoot) {
      dropdownRoot = document.createElement('div')
      dropdownRoot.id = singleSelectDropdownRootClass
      dropdownRoot.className = singleSelectDropdownRootClass
      document.body.appendChild(dropdownRoot)
    }
    dropdownRoot.appendChild(dropdownContainer)
    return () => {
      dropdownRoot?.removeChild(dropdownContainer)
    }
  }, [])

  useEffect(() => {
    const updatePosition = () => {
      if (selectElement?.getBoundingClientRect) {
        const rect = selectElement.getBoundingClientRect()
        dropdownContainer.style.left = `${rect.left}px`
        dropdownContainer.style.width = `${minWidth > rect.width ? minWidth : rect.width}px`
        if (dropdownContainer.clientHeight + rect.bottom < window.innerHeight) {
          dropdownContainer.style.top = `${rect.bottom}px`
          dropdownContainer.style.bottom = 'unset'
        } else {
          dropdownContainer.style.bottom = `-${rect.top}px`
          dropdownContainer.style.top = 'unset'
        }
      }
    }
    updatePosition()
    const pageContainer = document.getElementById('page-container')
    const modalBody = document.getElementById('modal-body')
    const scrollWrapper = modalBody?.contains(selectElement) ? modalBody : pageContainer
    scrollWrapper?.addEventListener('scroll', updatePosition)
    return () => {
      scrollWrapper?.removeEventListener('scroll', updatePosition)
    }
  }, [selectElement])

  return createPortal(children, dropdownContainer)
}

export default SingleSelectDropdownPortal
