import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Label from '@components/Label'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip, { TooltipProps } from '@components/Tooltip/Tooltip'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './LabelWithSvgTooltip.css'

interface Props extends Omit<TooltipProps, 'children' | 'trigger'> {
  label: ReactNode
  tooltipContent: ReactNode | ReactNode[]
  svgName?: SvgNames
  svgType?: SvgType
  changeOnHover?: boolean
  required?: boolean
  requiredTextType?: TextType
  disabledTrigger?: boolean
  className?: string
  dataTest?: string
}

const rootClass = 'label-with-svg-tooltip'

/**
 * @deprecated use <LabelV2 instead
 */
const LabelWithSvgTooltip: FC<Props> = (props: Props) => {
  const {
    label,
    tooltipContent,
    svgName = SvgNames.info,
    svgType = SvgType.ICON,
    changeOnHover = true,
    required,
    requiredTextType = TextType.BODY_TEXT_SMALL_LIGHT,
    align = 'start',
    alignOffset,
    dataTest = rootClass,
    className = rootClass,
    disabledTrigger,
    inline = false,
    ...rest
  } = props
  const { t } = useTranslation()

  const alignOffsetProp = alignOffset ? { alignOffset } : {}

  return (
    <Label
      className={classNames(rootClass, className, { [`${rootClass}__change-on-hover`]: changeOnHover, [`${rootClass}__disabled`]: disabledTrigger })}
    >
      {label}
      {required && <Typography text={`(${t('required')})`} inline type={requiredTextType} className={`${rootClass}-required`} />}
      <Tooltip
        inline={inline}
        {...rest}
        {...alignOffsetProp}
        align={align}
        trigger={<Svg name={svgName} type={svgType} className={`${rootClass}__svg`} dataTest={`${dataTest}__svg`} />}
        disabledTrigger={disabledTrigger}
        dataTest={`${dataTest}__tooltip`}
        className={`${rootClass}__tooltip ${className}__tooltip`}
      >
        {tooltipContent}
      </Tooltip>
    </Label>
  )
}

export default LabelWithSvgTooltip
