import React, { FC, useCallback } from 'react'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import Loader from '@components/Loader'
import Modal, { ModalHeader } from '@components/Modal'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import { useTranslation } from '@const/globals'
import updateSalesPermissions from '@graphql/mutations/updateSalesPermissions'
import getSalesPermissions from '@graphql/queries/getSalesPermissions'
import { MutationUpdateSalesPermissionsArgs, SalesPermissionsInput, UpdateSalesPermissionsMutation } from '@graphql/types/mutation-types'
import { GetSalesPermissionsQuery, GetSalesPermissionsQueryVariables } from '@graphql/types/query-types'
import { useAllUsers } from '@src/pages/Settings/Users/context/UsersContext'
import SalesPermissionsModalContent from '@src/pages/Settings/Users/tabs/SalesUsers/components/SalesPermissionModal/SalesPermissionsModalContent'
import { logNewRelicError } from '@utils/new-relic.utils'

import './SalesPermissionsModalContainer.css'

interface SalesPermissionModalProps {
  isOpen: boolean
  onCancel: () => void
  dataTest?: string
}

const rootClass = 'sales-permission-modal'

const SalesPermissionsModalContainer: FC<SalesPermissionModalProps> = (props: SalesPermissionModalProps) => {
  const { isOpen, onCancel, dataTest = rootClass } = props
  const { t } = useTranslation()
  const { setToastStatus } = useAllUsers()

  const client = useApolloClient()
  const { data, loading, error, refetch } = useQuery<GetSalesPermissionsQuery, GetSalesPermissionsQueryVariables>(getSalesPermissions, {
    client,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const [updatePermissions, { loading: saveLoading }] = useMutation<UpdateSalesPermissionsMutation, MutationUpdateSalesPermissionsArgs>(
    updateSalesPermissions,
    {
      client,
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    }
  )

  if (error) {
    logNewRelicError(error)
  }

  const onSave = useCallback(
    (data: SalesPermissionsInput) => {
      onCancel()
      updatePermissions({
        variables: {
          data,
        },
      })
        .then(() => {
          setToastStatus({
            showStatus: true,
            title: t('Success!'),
            statusMessage: t('Sales.Permissions.Change.Success.Message'),
            successStatus: true,
          })
          refetch()
        })
        .catch((error) => {
          setToastStatus({
            showStatus: true,
            statusMessage: t('Something went wrong on our end. Please try again.'),
            successStatus: false,
          })
          logNewRelicError(error)
        })
    },
    [updatePermissions]
  )

  return (
    <Modal
      isOpen={isOpen}
      className={rootClass}
      dataTest={dataTest}
      header={
        <ModalHeader headerType={ModalHeaderType.Form} dataTest={`${dataTest}-header`}>
          {t('Sales User Permissions')}
        </ModalHeader>
      }
    >
      {loading || saveLoading ? (
        <Loader center blackout />
      ) : (
        <SalesPermissionsModalContent
          isOpen={isOpen}
          permissions={data?.getSalesPermissions}
          onSave={onSave}
          onCancel={onCancel}
          dataTest={dataTest}
        />
      )}
    </Modal>
  )
}

export default SalesPermissionsModalContainer
