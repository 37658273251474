import { gql } from '@apollo/client'
import { allListsChildren } from '@graphql/queries/fragments/lists'

export default gql`
  ${allListsChildren}

  query FolderLists($type: String!, $id: String!, $noMirroredJoinLists: Boolean) {
    folderLists(type: $type, id: $id, noMirroredJoinLists: $noMirroredJoinLists) {
      ...AllListsChildren
    }
  }
`
