import React, { FC, useMemo, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import DropDown from '@components/DropDown'
import { DropDownType } from '@components/DropDown/DropDown'
import DropDownActions, { MenuItem } from '@components/DropDownActions/DropDownActions'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import { RowAction } from '@components/Table/Table'
import { ActionType } from '@components/TableV2/tableV2TS/enums'
import { splitActions } from '@components/TableV2/utils/tableV2Utils'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'
import { ComposerPreviewActions, previewDropDownActions } from '@src/pages/EmailComposer/components/EmailComposerPreview/EmailComposerPreview.utils'
import { getMessageConfigurationType } from '@src/pages/EmailComposer/utils/EmailComposer.utils'
import { ExtendedMessageType } from '@utils/composer/emailComposer/types'
import { toTrainCase } from '@utils/strings'

import './EmailComposerPreviewActions.css'

interface EmailComposerPreviewActionsProps {
  messageType: ExtendedMessageType
  className?: string
  dataTest?: string
  actions: RowAction[]
  isLandingPage?: boolean
  dropDownContent: (action: string) => MenuItem[] | React.ReactNode
}

const rootClass = 'email-composer-preview-actions'

const EmailComposerPreviewActions: FC<EmailComposerPreviewActionsProps> = ({
  dataTest = rootClass,
  className = '',
  actions = [],
  isLandingPage,
  dropDownContent,
}) => {
  const messageConfigurationType: string = getMessageConfigurationType()

  const [dropdownOpen, setDropdownOpen] = useState<string | undefined>()

  const { t } = useTranslation()

  const [primaryActions, secondaryActions] = useMemo(() => splitActions(actions, undefined, ActionType.FolderOrTag), [actions])
  const mainDropDownActions = secondaryActions.map(({ icon, label, topSection, disabled, tooltipMessage, hasTooltip, onClick }): MenuItem => {
    return { icon, onClick: (row) => onClick?.(row), text: `${label}`, topSection: !!topSection, disabled, tooltipMessage, hasTooltip }
  })

  const renderDropdown = (
    key: string,
    isOpen: boolean,
    trigger: React.ReactNode,
    dropDownContent: MenuItem[] | React.ReactNode,
    onOpen: (arg: boolean) => void,
    onClose: () => void
  ) => {
    return (
      <DropDown
        key={key}
        className={classNames({
          [`${rootClass}__drop-down`]: !isOpen,
          [`${rootClass}__drop-down-open`]: isOpen,
        })}
        isOpen={isOpen}
        toggleOpen={(open) => onOpen(open)}
        hasOverflowIcon
        overflowIconTooltipText={t('More actions')}
        type={DropDownType.STYLED}
        trigger={trigger}
        dataTest={`${rootClass}-drop-down`}
      >
        {Array.isArray(dropDownContent) ? (
          <DropDownActions className={`${rootClass}__drop-down-actions`} menuItems={dropDownContent} closeDropDown={onClose} />
        ) : (
          dropDownContent
        )}
      </DropDown>
    )
  }

  return (
    <div className={classNames(rootClass, className)}>
      <div className={`${rootClass}__container`} data-test={dataTest}>
        {primaryActions.map((action, index) => {
          if (!action.hidden) {
            const buttonDataTest = `${dataTest}-${toTrainCase(action.label as string)}-button`
            const isOpen = dropdownOpen === action.label
            const key = `${action.label}-${index}-action`
            const disabledButton = action.disabledType === messageConfigurationType
            const actionButton = (
              <Tooltip
                key={key}
                triggerClassName={`${rootClass}__tooltip`}
                position={'top'}
                trigger={
                  <Button
                    disabled={disabledButton}
                    dataTest={buttonDataTest}
                    className={`${rootClass}__button ${disabledButton ? `${rootClass}__disabled-button` : ''}`}
                    buttonType={ButtonType.TABLE_ACTION}
                    onClick={(e) => (action.onClick ? action.onClick(e as any) : null)}
                  >
                    {action.icon && (
                      <Svg
                        fill={disabledButton ? SvgColor.LIGHT_GRAY : SvgColor.TEXT_GRAY}
                        name={action.icon as SvgNames}
                        type={SvgType.LARGER_ICON}
                      />
                    )}
                  </Button>
                }
              >
                {disabledButton ? t('EmailComposer.Resend.Disabled') : t(action.label)}
              </Tooltip>
            )

            return previewDropDownActions.includes(action.label as ComposerPreviewActions)
              ? renderDropdown(
                  key,
                  isOpen,
                  actionButton,
                  dropDownContent(`${action.label}`),
                  (open) => setDropdownOpen(open ? `${action.label}` : undefined),
                  () => setDropdownOpen(undefined)
                )
              : actionButton
          }
        })}
        {!isLandingPage &&
          secondaryActions.length > 0 &&
          renderDropdown(
            'drop-down-actions',
            dropdownOpen === 'menu',
            undefined,
            mainDropDownActions,
            (open) => setDropdownOpen(open ? 'menu' : undefined),
            () => setDropdownOpen(undefined)
          )}
      </div>
    </div>
  )
}

export default EmailComposerPreviewActions
