import React from 'react'

import PillLabel, { PillLabelType } from '@components/PillLabel/PillLabel'
import { renderColWithTooltip } from '@components/Table/components/tableColumns'
import { TableColumn } from '@components/Table/Table'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { ColWithTooltip } from '@components/TableV2/utils/tableV2ColumnUtils'
import Typography, { TextType } from '@components/Typography/Typography'
import { UserResponse } from '@graphql/types/query-types'

export const tableColumns: (t: (s: string) => string) => TableColumn[] = (t) => [
  {
    Header: 'USER DETAILS',
    accessor: 'fullName',
    align: 'left',
    minWidth: 130,
    maxWidth: 210,
    sortType: 'caseInsensitive',
    Cell: (row: any) => renderColWithTooltip({ cell: { value: getUserDetails(row) } }),
  },
  {
    Header: 'SALES USER TYPE',
    accessor: 'isCRM',
    align: 'left',
    minWidth: 100,
    maxWidth: 130,
    sortType: (row: any) => {
      return row.original.isCRM ? 1 : -1
    },
    Cell: (row: any) => (row.row.original.isCRM ? 'CRM' : 'Portal'),
  },
  {
    Header: 'TITLE',
    accessor: 'title',
    align: 'left',
    minWidth: 130,
    maxWidth: 220,
    sortType: 'caseInsensitive',
    Cell: (row: any) => (row.row.original.title ? renderColWithTooltip(row) : <div className={'no-value-td'}>--</div>),
  },
  {
    Header: 'LOGIN',
    accessor: 'login',
    align: 'left',
    minWidth: 150,
    sortType: 'caseInsensitive',
    Cell: (row: any) => renderColWithTooltip(row),
  },
  {
    Header: 'STATUS',
    accessor: 'isVerified',
    align: 'left',
    minWidth: 100,
    maxWidth: 150,
    sortType: (row: any) => {
      return row.original.isVerified ? -1 : 1
    },
    Cell: (row: any) => {
      const isVerified = !!row.row.original.isVerified
      return <PillLabel text={t(isVerified ? 'Verified' : 'Unverified')} type={isVerified ? PillLabelType.SUCCESS : PillLabelType.ERROR} noMargin />
    },
  },
]

export const tableV2Columns = (t: Function): ColumnDefWithAdditionalProps<UserResponse, unknown>[] => [
  {
    header: 'USER DETAILS',
    accessorKey: 'fullName',
    textAlign: 'left',
    sortingFn: 'caseInsensitive',
    cell: (cell) => ColWithTooltip({ cell: { ...cell, getValue: () => getUserDetails(cell) } }),
  },
  {
    header: 'SALES USER TYPE',
    accessorKey: 'isCRM',
    textAlign: 'left',
    maxSize: 130,
    sortingFn: (cell) => (cell.original.isCRM ? 1 : -1),
    cell: (cell) => (cell.row.original.isCRM ? 'CRM' : 'Portal'),
  },
  {
    header: 'TITLE',
    accessorKey: 'title',
    textAlign: 'left',
    sortingFn: 'caseInsensitive',
    cell: (cell) => (cell.row.original.title ? ColWithTooltip({ cell }) : <div className={'no-value-td'}>--</div>),
  },
  {
    header: 'LOGIN',
    accessorKey: 'login',
    textAlign: 'left',
    sortingFn: 'caseInsensitive',
    cell: (cell) => ColWithTooltip({ cell }),
  },
  {
    header: 'STATUS',
    accessorKey: 'isVerified',
    textAlign: 'left',
    sortingFn: (cell) => {
      return cell.original.isVerified ? -1 : 1
    },
    cell: (cell) => {
      const isVerified = !!cell.row.original.isVerified
      return <PillLabel text={t(isVerified ? 'Verified' : 'Unverified')} type={isVerified ? PillLabelType.SUCCESS : PillLabelType.ERROR} noMargin />
    },
  },
]

const getUserDetails = (row: any) => {
  const { fullName, firstName = '', lastName = '', email } = row.row.original
  if (fullName) {
    return fullName
  }
  const result = firstName ? `${firstName}${lastName ? ` ${lastName}` : ''}` : lastName

  if (result) {
    return result
  }
  if (email) {
    return <Typography text={email} type={TextType.BODY_TEXT_LIGHT} />
  }
  return ''
}
