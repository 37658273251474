import { default as getSegmentData } from '@graphql/microservices/categorization/getItem'
import countNonDeliverable from '@graphql/microservices/segment/countNonDeliverable'
import countSuppressed from '@graphql/microservices/segment/countSuppressed'
import createSplits from '@graphql/microservices/segment/createSplits'
import { GetItemQuery, GetItemQueryVariables } from '@graphql/types/microservice/categorization-types'
import { CreateSplitsMutation, CreateSplitsMutationVariables, SplitInputDtoInput } from '@graphql/types/microservice/segment-types'
import {
  CountNonDeliverableQuery,
  CountNonDeliverableQueryVariables,
  CountSuppressedQuery,
  CountSuppressedQueryVariables,
} from '@graphql/types/microservice/segment-types'
import { ItemType } from '@utils/categorization'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

interface GetABSplitQueriesResponse {
  getSegmentDetailsRequest: (segmentId: string, type?: string) => FetchPromise<GetItemQuery>
  getNonDeliverableContacts: (segmentId: string) => FetchPromise<CountNonDeliverableQuery>
  getSuppressedContacts: (baseSegmentId: string, suppressedListId: string) => FetchPromise<CountSuppressedQuery>
  createSplitsRequest: (newSplitsData: SplitInputDtoInput, type?: string) => FetchPromise<CreateSplitsMutation>
}

export const useABSplitQueries = (): GetABSplitQueriesResponse => {
  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })
  const { client: segmentClient } = useMicroserviceClient({ serviceName: MicroserviceClients.SEGMENT })
  const getSegmentDetailsRequest = (segmentId: string, type: string = ItemType.SEGMENT) => {
    return categorizationClient.query<GetItemQuery, GetItemQueryVariables>({
      query: getSegmentData,
      fetchPolicy: 'network-only',
      variables: {
        externalId: segmentId,
        type,
      },
    })
  }

  const getNonDeliverableContacts = (segmentId: string) => {
    return segmentClient.query<CountNonDeliverableQuery, CountNonDeliverableQueryVariables>({
      query: countNonDeliverable,
      fetchPolicy: 'network-only',
      variables: {
        srcId: segmentId,
      },
    })
  }

  const getSuppressedContacts = (baseSegmentId: string, suppressedListId: string) => {
    return segmentClient.query<CountSuppressedQuery, CountSuppressedQueryVariables>({
      query: countSuppressed,
      fetchPolicy: 'network-only',
      variables: {
        srcId: baseSegmentId,
        excId: suppressedListId,
      },
    })
  }

  const createSplitsRequest = (newSplitsData: SplitInputDtoInput, type?: string) => {
    return segmentClient.mutate<CreateSplitsMutation, CreateSplitsMutationVariables>({
      mutation: createSplits,
      variables: {
        splitInput: {
          ...newSplitsData,
        },
        type,
      },
    })
  }

  return {
    getSegmentDetailsRequest,
    getNonDeliverableContacts,
    getSuppressedContacts,
    createSplitsRequest,
  }
}
