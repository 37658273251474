import React, { FC, useCallback, useEffect, useState } from 'react'

import { useApolloClient, useLazyQuery } from '@apollo/client'
import Button, { ButtonType } from '@components/Button'
import Label from '@components/Label'
import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import SingleSelectDropdown from '@components/SingleSelectDropdown/SingleSelectDropdown'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { getUUID, useTranslation } from '@const/globals'
import getAllMarketingUsers from '@graphql/queries/getAllMarketingUsers'
import { GetAllMarketingUsersQuery, GetAllMarketingUsersQueryVariables, PermissionsResponse } from '@graphql/types/query-types'
import { SelectOption } from '@interface/Select'
import { UserWithId } from '@src/pages/Settings/Users/hooks/useResendVerification'
import { reformatPermissions } from '@src/pages/Settings/Users/tabs/MarketingUsers/utils/MarketingUsers.tableColumns'
import { getCopyFromUsers } from '@src/pages/Settings/Users/tabs/MarketingUsers/utils/tableActions'
import { filterNotEmptyArray } from '@utils/array'
import { logNewRelicError } from '@utils/new-relic.utils'

import './CopyPermissions.css'

interface Props {
  isOpen: boolean
  selectedUsersIds?: string[]
  userIdToEditPermissions?: string | undefined
  onSave: (selectedCopyFromUser: CopyFromUser | undefined) => void
  onCancel: () => void
  dataTest?: string
}

export interface CopyFromUser extends SelectOption {
  id: string
  permissions?: PermissionsResponse
  launchApproval?: string
  launchApprovalId?: string
  isAdmin?: boolean
}

const rootClass = 'copy-permissions-modal'

const CopyPermissionsModal: FC<Props> = (props: Props) => {
  const { selectedUsersIds, userIdToEditPermissions, isOpen, onCancel, onSave, dataTest = rootClass } = props
  const { t } = useTranslation()
  const [selectedCopyFromUser, setSelectedCopyFromUser] = useState<CopyFromUser | undefined>(undefined)
  const [copyFromUsers, setCopyFromUsers] = useState<CopyFromUser[]>([])

  const client = useApolloClient()
  const [getAllUsers, { data, error, loading }] = useLazyQuery<GetAllMarketingUsersQuery, GetAllMarketingUsersQueryVariables>(getAllMarketingUsers, {
    client,
    fetchPolicy: 'no-cache',
    variables: { sortColumn: 'USER_DETAILS', sortDirection: 'ASC' },
  })

  useEffect(() => {
    isOpen && getAllUsers()
  }, [isOpen])

  useEffect(() => {
    if (!loading && data) {
      const users = getCopyFromUsers(
        (data?.getAllMarketingUsers?.marketingUsers ?? [])
          .filter(filterNotEmptyArray)
          .filter((user) => user.id && !selectedUsersIds?.includes(user.id)) as UserWithId[]
      )
      userIdToEditPermissions && users.filter((user) => user.id === userIdToEditPermissions)
      setCopyFromUsers(users)
    }
    if (error) {
      logNewRelicError(error)
    }
  }, [loading, data, error, selectedUsersIds, userIdToEditPermissions])

  const handleSelectAdmin = useCallback((_?: string, selectedUser?: CopyFromUser) => setSelectedCopyFromUser(selectedUser), [])

  return (
    <Modal
      isOpen={isOpen}
      className={rootClass}
      data-test={dataTest}
      header={
        <ModalHeader headerType={ModalHeaderType.Form} dataTest={`${dataTest}-header`}>
          {t('Copy Permissions')}
        </ModalHeader>
      }
    >
      <ModalBody>
        <Typography
          text={t('Copy.Permissions.Modal.Description')}
          type={TextType.BODY_TEXT_LIGHT}
          dataTest={`${dataTest}-description`}
          className={`${rootClass}__description`}
        />
        {loading ? (
          <Loader loaderType={LoaderTypes.row} />
        ) : (
          <>
            <Label>{t('Copy Permissions from')}</Label>
            <SingleSelectDropdown
              options={copyFromUsers}
              onSubmit={handleSelectAdmin}
              placeholder={t('Select a user')}
              className={`${rootClass}__select-user`}
              dataTest={`${dataTest}__select-user`}
            />
          </>
        )}
        {selectedCopyFromUser && (
          <div className={`${rootClass}__info`}>
            <Typography
              lineHeight={LineHeight.TINY}
              weight={TextWeight.MEDIUM}
              text={t('Preview permissions:')}
              className={`${rootClass}__info-title`}
            />
            {selectedCopyFromUser?.launchApproval && (
              <div className={`${rootClass}__info-list`}>
                <Typography
                  type={TextType.BODY_TEXT_LIGHT_TINY}
                  text={t('LAUNCH')}
                  weight={TextWeight.BOLD}
                  lineHeight={LineHeight.TINY}
                  className={`${rootClass}__info-list__title`}
                />
                <Typography
                  type={TextType.BODY_TEXT_SMALL}
                  text={selectedCopyFromUser?.launchApproval}
                  lineHeight={LineHeight.SMALL}
                  className={`${rootClass}__info-list__value`}
                />
              </div>
            )}
            {reformatPermissions(selectedCopyFromUser?.permissions).map((permission) => {
              return (
                <div key={getUUID()} className={`${rootClass}__info-list`}>
                  <Typography
                    type={TextType.BODY_TEXT_LIGHT_TINY}
                    text={permission.title}
                    weight={TextWeight.BOLD}
                    lineHeight={LineHeight.TINY}
                    className={`${rootClass}__info-list__title`}
                  />
                  <Typography
                    key={getUUID()}
                    type={TextType.BODY_TEXT_SMALL}
                    text={permission.values}
                    lineHeight={LineHeight.SMALL}
                    className={`${rootClass}__info-list__value`}
                  />
                </div>
              )
            })}
          </div>
        )}
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form}>
        <>
          {(selectedUsersIds?.length ?? 0) > 0 && (
            <Typography
              text={t('Copy.Permissions.Selected.Users.Count', { count: selectedUsersIds?.length })}
              className={`${rootClass}__users-count`}
            />
          )}
          <Button
            buttonType={ButtonType.TERTIARY}
            onClick={() => {
              onCancel()
              setSelectedCopyFromUser(undefined)
            }}
            dataTest={`${dataTest}-cancel-button`}
          >
            {t('Cancel')}
          </Button>
          <Button
            buttonType={ButtonType.PRIMARY}
            disabled={!selectedCopyFromUser}
            onClick={() => {
              onSave(selectedCopyFromUser)
              setSelectedCopyFromUser(undefined)
            }}
            dataTest={`${dataTest}-continue-button`}
          >
            {t('Continue')}
          </Button>
        </>
      </ModalFooter>
    </Modal>
  )
}

export default CopyPermissionsModal
