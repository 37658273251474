import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Menu, { MenuItemData } from '@components/Menu/Menu'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { getItem, setItem } from '@utils/sessionStorage'

import './ResizableMenu.css'
interface ResizableMenuProps {
  className?: string
  dataTest?: string
  menuItems: MenuItemData[]
  menuSize?: number
}

const rootClass = 'resizable-menu'
export enum ResizableMenuStorage {
  MENU_WIDTH = 'resizableMenu:MenuWidth',
  MENU_COLLAPSED = 'resizableMenu:Collapsed',
  BUTTON_POSITION = 'resizableMenu:ButtonPosition',
}

const ResizableMenu: FC<ResizableMenuProps> = (props: ResizableMenuProps) => {
  const { dataTest = rootClass, className = '', menuItems, menuSize = 300 } = props

  const [collapsed, setCollapsed] = useState(false)
  const [menuWidth, setMenuWidth] = useState(menuSize)
  const [isResizing, setIsResizing] = useState(false)
  const [doubleClick, setDoubleClick] = useState(false)
  const { t } = useTranslation()

  const toggleMenu = () => {
    setCollapsed(!collapsed)
    setItem(ResizableMenuStorage.MENU_COLLAPSED, collapsed ? 'false' : 'true')
  }

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === '[' && document.activeElement?.tagName !== 'INPUT' && document.activeElement?.tagName !== 'TEXTAREA') {
      toggleMenu()
    }
  }

  const handleMouseDown = () => {
    setIsResizing(true)
    if (doubleClick) {
      setMenuWidth(300)
      setItem(ResizableMenuStorage.MENU_WIDTH, '300')
      setItem(ResizableMenuStorage.MENU_COLLAPSED, 'false')
    } else {
      setDoubleClick(true)
      setTimeout(() => setDoubleClick(false), 300)
    }
  }

  const handleMouseUp = () => {
    setIsResizing(false)
  }

  const handleMouseMove = (e: MouseEvent) => {
    if (isResizing) {
      const element = document.querySelector('[data-test="resizable-menu"]')

      if (element) {
        const rect = element.getBoundingClientRect()
        const mouseX = e.clientX - rect.left

        const newMenuWidth = mouseX

        if (newMenuWidth >= 184 && newMenuWidth <= 480) {
          setMenuWidth(newMenuWidth)
          setItem(ResizableMenuStorage.MENU_WIDTH, newMenuWidth.toString())
          setItem(ResizableMenuStorage.MENU_COLLAPSED, 'false')
        }

        if (newMenuWidth < 180) {
          toggleMenu()
          handleMouseUp()
        }
      }
    }
  }
  const checkIfHasSaveWidth = () => {
    const savedWidth = getItem(ResizableMenuStorage.MENU_WIDTH)
    const savedCollapsed = getItem(ResizableMenuStorage.MENU_COLLAPSED)
    const savedButtonPosition = getItem(ResizableMenuStorage.BUTTON_POSITION)

    if (savedWidth !== null) {
      setMenuWidth(parseFloat(savedWidth))
    }

    if (savedCollapsed == 'true') {
      setCollapsed(true)
    }

    if (savedButtonPosition !== null) {
      const button = document.querySelector('.resizable-menu .button--navigation-button') as HTMLDivElement
      if (button) {
        button.style.top = savedButtonPosition
      }
    }
  }

  useEffect(() => {
    checkIfHasSaveWidth()
  }, [])

  useEffect(() => {
    const handleResize = () => {
      const modalFooter = document.querySelector('.modal-footer') as HTMLDivElement
      const modalHeader = document.querySelector('.modal-header') as HTMLDivElement
      const tooltipTrigger = document.querySelector('.resizable-menu__tooltip .tooltip__trigger') as HTMLDivElement
      const button = document.querySelector('.resizable-menu .button--navigation-button') as HTMLDivElement
      const collapsedButton = document.querySelector('.resizable-menu__toggle-button-collapsed') as HTMLDivElement

      if (modalFooter && modalHeader && tooltipTrigger && button) {
        const rectFooter = modalFooter.getBoundingClientRect()
        const rectHeader = modalHeader.getBoundingClientRect()
        const distance = rectFooter.top - rectHeader.bottom - 50

        tooltipTrigger.style.marginTop = distance + 'px'
        tooltipTrigger.style.top = distance + 'px'

        if (collapsedButton) {
          collapsedButton.style.top = distance + 65 + 'px'
        }

        if (distance <= 270) {
          tooltipTrigger.style.marginTop = 70 + 'px'
          tooltipTrigger.style.top = 180 + 'px'
          button.style.top = 135 + 'px'
        }
      }
      setItem(ResizableMenuStorage.BUTTON_POSITION, button.style.top)
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [collapsed])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)

    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])

  useEffect(() => {
    if (isResizing) {
      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)
    } else {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isResizing, handleMouseMove, handleMouseUp])

  return (
    <div className={classNames(rootClass, className, { [`${rootClass}__collapsed`]: collapsed })} data-test={dataTest}>
      <div className={classNames(`${rootClass}__menu-content`, { [`${rootClass}__collapsed`]: collapsed })}>
        <Menu items={menuItems} menuSize={`${menuWidth}px`} />
      </div>
      <div
        className={classNames(`${rootClass}__resize-bar`, {
          [`${rootClass}__collapsed`]: collapsed,
          [`${rootClass}__resizing`]: isResizing,
        })}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        draggable={false}
        role="presentation"
        style={{ left: menuWidth }}
      />
      <div className={`${rootClass}__tooltip`}>
        <Tooltip
          trigger={
            <Button
              tabIndex={-1}
              className={classNames(`${rootClass}__toggle-button`, { [`${rootClass}__toggle-button-collapsed`]: collapsed })}
              buttonType={ButtonType.NAVIGATION_BUTTON}
              type="button"
              onClick={toggleMenu}
            >
              <Svg name={collapsed ? SvgNames.caretRight : SvgNames.caretLeft} type={SvgType.MEDIUM_LARGE_ICON} />
            </Button>
          }
          position={'top'}
          align={'center'}
        >
          <div>
            {t(collapsed ? 'Expand' : 'Collapse')}{' '}
            <Typography text={'['} inline className={`${rootClass}__tooltip__description`} type={TextType.BODY_TEXT_SMALL_LIGHT} />
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

export default ResizableMenu
