import dayjs from 'dayjs'

import { ColumnChartProps } from '@components/ColumnChart/ColumnChart'
import { DateRangeValueType } from '@components/DateRangePicker/DateRangePicker'
import { LineChartProps } from '@components/LineChart/LineChart'
import { DataListByDate, LeadsSectionData } from '@graphql/types/microservice/data-lake-query-types'
import { fillMissingData, getMonthDifference } from '@src/pages/reports/revenueImpactNew/components/ChartSection/ChartSection.utils'
import { RevenueImpactDataAnalytics } from '@src/pages/reports/revenueImpactNew/components/DataAnalytics/DataAnalytics'
import {
  getRevenueRangeToMilliseconds,
  getPreviousSameLengthFormattedRange,
  getTrendInPercentage,
} from '@src/pages/reports/revenueImpactNew/RevenueImpactNew.utils'
import { filterNotEmptyArray } from '@utils/array'
import { getCountDisplay } from '@utils/numbers'

export type NewLeadsFormattedData = {
  analyticsData: RevenueImpactDataAnalytics[]
  bySource: ColumnChartProps
  byDate: LineChartProps
  startTime: number
  endTime: number
}

const getCombinedDateData: (data: (DataListByDate | undefined)[] | undefined, isOneMonth: boolean, isMoreThreeMonths: boolean) => number[][] = (
  data,
  isOneMonth,
  isMoreThreeMonths
) => {
  if (!data) {
    return []
  }

  if (isOneMonth) {
    return data.filter(filterNotEmptyArray).map((item) => [dayjs(item.date).valueOf(), Number(item.count)])
  }

  const datesMap = new Map<number, number>([])
  data.forEach((rec) => {
    if (!rec) {
      return
    }
    const { count, date } = rec
    const _date = dayjs(date)
    if (_date.isValid()) {
      const startOfPeriod = _date.startOf(isMoreThreeMonths ? 'month' : 'week').valueOf()
      const curCount = datesMap.get(startOfPeriod) ?? 0
      datesMap.set(startOfPeriod, curCount + (count ?? 0))
    }
  })
  return Array.from(datesMap)
}

const getNewLeadsFormattedData: (data: LeadsSectionData, range: DateRangeValueType, t: (s: any) => any) => NewLeadsFormattedData = (
  data,
  range,
  t
) => {
  const { analytics, bySource, byDate } = data
  const { startTime, endTime } = getRevenueRangeToMilliseconds(range)
  const monthDifference = getMonthDifference(startTime, endTime)
  const isOneMonth = monthDifference <= 1
  const isMoreThreeMonths = monthDifference >= 3
  const isMoreThanTwoMonths = monthDifference >= 2

  const analyticsData: RevenueImpactDataAnalytics[] = [
    { title: t('New leads'), value: getCountDisplay(analytics?.count) },
    {
      title: t('Lead trend'),
      value: getTrendInPercentage(analytics?.trend),
      trend: analytics?.trend ? { isPositive: analytics.trend > 0 } : undefined,
      tooltipTypographyProps: {
        text: t('Revenue.Won.Opportunities.Card.Tooltip'),
        values: { dateRange: getPreviousSameLengthFormattedRange(range) },
      },
    },
    { title: t('Top source'), value: analytics?.topSource ?? undefined, emptyValueText: analytics?.topSource === '' ? t('No source') : undefined },
  ]

  if (isMoreThanTwoMonths) {
    analyticsData.push({
      title: t('Top month'),
      value: analytics?.topMonth ?? undefined,
      emptyValueText: analytics?.topSource === '' ? t('No top month') : undefined,
    })
  }

  const bySourceSortedDescending = bySource ? bySource.filter(filterNotEmptyArray).sort((a, b) => Number(b.count) - Number(a.count)) : []

  return {
    analyticsData,
    bySource: {
      categories: bySourceSortedDescending.map((source) => `${source.name}`),
      fields: [
        {
          name: t('New Leads'),
          data: bySourceSortedDescending.map((source) => Number(source?.count)),
          color: '#50BDFF',
        },
      ],
    },
    byDate: {
      fields: [
        {
          name: t('New Leads'),
          data: fillMissingData(getCombinedDateData(byDate, isOneMonth, isMoreThreeMonths), isOneMonth, isMoreThreeMonths, startTime, endTime),
          color: '#50BDFF',
        },
      ],
    },
    startTime,
    endTime,
  }
}

export default getNewLeadsFormattedData
