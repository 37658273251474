import React, { FC, Fragment, useContext, useMemo } from 'react'

import Typography, { LineHeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { FieldSetOptions } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditFieldSetStep/EditFieldSetStep'
import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'
import { Box } from '@src/pages/programs/UpgradeCommonComponents/Box/Box'
import { hasSourceList } from '@src/pages/programs/upgradeManager/components/steps/ChangeFieldValueUpgradeStep/ChangeFieldValueUpgradeStep'
import { ConditionType } from '@src/pages/programs/upgradeManager/components/steps/Steps.utils'
import { UpgradedProgramRecordSetStep } from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'
import ReviewChangeFieldValueSourceUpgrade from '@src/pages/programs/UpgradePrograms/components/ReviewUpgrade/sections/FixStepIssuesSection/components/ReviewCurrentStep/steps/ReviewChangeFieldValueStep/components/ReviewChangeFieldValueSourceUpgrade/ReviewChangeFieldValueSourceUpgrade'
import { ReviewCondition } from '@src/pages/programs/UpgradePrograms/components/ReviewUpgrade/sections/FixStepIssuesSection/components/ReviewCurrentStep/steps/ReviewChangeFieldValueStep/components/ReviewCondition/ReviewCondition'
import { ReviewFieldReplacement } from '@src/pages/programs/UpgradePrograms/components/ReviewUpgrade/sections/FixStepIssuesSection/components/ReviewCurrentStep/steps/ReviewChangeFieldValueStep/components/ReviewFieldReplacement/ReviewFieldReplacement'

import './ReviewChangeFieldValueStep.css'

interface ReviewChangeFieldValueStepProps {
  step: UpgradedProgramRecordSetStep
}

const rootClass = 'review-change-field-value-step'
export const ReviewChangeFieldValueStep: FC<ReviewChangeFieldValueStepProps> = ({ step }: ReviewChangeFieldValueStepProps) => {
  const { t } = useTranslation()

  const {
    values: { program },
  } = useContext(ProgramManagerContext)

  const filteredOperations = useMemo(
    () => step.operations.filter((operation) => operation.fieldName !== FieldSetOptions.NOOP && operation.rule !== FieldSetOptions.NOOP),
    [step.operations]
  )

  return (
    <>
      {step?.conditions.length > 0 && (
        <div className={`${rootClass}__conditions`}>
          {step?.conditions?.map((condition, index) => {
            if (hasSourceList(condition, program)) {
              return (
                <Fragment key={condition.conditionId}>
                  <ReviewChangeFieldValueSourceUpgrade step={step} condition={condition} conditionIndex={index} />
                  <ReviewCondition key={condition.conditionId} condition={condition} type={index === 0 ? ConditionType.IF : ConditionType.ELSE_IF} />
                </Fragment>
              )
            } else {
              return (
                <ReviewCondition key={condition.conditionId} condition={condition} type={index === 0 ? ConditionType.IF : ConditionType.ELSE_IF} />
              )
            }
          })}
        </div>
      )}
      {filteredOperations?.length > 0 && (
        <Box>
          {step?.conditions?.length !== 0 && <Typography text={t('Otherwise')} lineHeight={LineHeight.MEDIUM} />}
          {filteredOperations?.map((operation) => (
            <ReviewFieldReplacement key={operation.operationId} operation={operation} />
          ))}
        </Box>
      )}
    </>
  )
}
