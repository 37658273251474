import React, { FC, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import CaretIcon, { CaretIconDirection } from '@components/CaretIcon'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { Segment } from '@graphql/types/microservice/ml-audience-types'
import CustomInsightsResultsRow from '@src/pages/RecommendedSegments/components/CustomInsightsResultsRow/CustomInsightsResultsRow'

import './CustomInsightsResultsSection.css'

interface CustomInsightsResultsSectionProps {
  canExpand?: boolean
  children?: ReactNode
  className?: string
  dataTest?: string
  header: string
  segments: Segment[]
  subheader?: ReactNode
}

const rootClass = 'custom-insights-results-section'

const CustomInsightsResultsSection: FC<CustomInsightsResultsSectionProps> = (props: CustomInsightsResultsSectionProps) => {
  const { dataTest = rootClass, className = '', segments, canExpand = false, header = '', subheader, children } = props

  const [isExpanded, setIsExpanded] = useState(true)

  const { t } = useTranslation()

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className={classNames(`${rootClass}__header`, { [`${rootClass}__header-expandable`]: canExpand })}
        onClick={() => canExpand && setIsExpanded(!isExpanded)}
      >
        <div className={`${rootClass}__header-title`}>
          {canExpand && <CaretIcon className={`${rootClass}__header-caret`} direction={CaretIconDirection.RIGHT} toggle={isExpanded} />}
          <Typography
            dataTest={`${dataTest}-label`}
            type={TextType.BODY_TEXT_LARGE}
            text={header}
            weight={TextWeight.MEDIUM}
            lineHeight={LineHeight.MEDIUM_LARGE}
            inline
          />
        </div>
        {subheader}
      </div>
      {children}
      {segments.length > 0 ? (
        <div className={classNames(`${rootClass}__segments`, { [`${rootClass}__segments-hidden`]: !isExpanded })}>
          {segments.map((segment) => (
            <CustomInsightsResultsRow
              dataTest={`${dataTest}-segment`}
              className={`${rootClass}__segments-row`}
              key={`segment-${segment.id}`}
              segment={segment}
            />
          ))}
        </div>
      ) : (
        <div className={`${rootClass}__empty-state`}>
          <Typography
            text={t(`Nothing here right now`)}
            type={TextType.BODY_TEXT_LARGE}
            weight={TextWeight.MEDIUM}
            lineHeight={LineHeight.MEDIUM_LARGE}
          />
          <Typography text={t(`Check back soon for new audience insights`)} />
        </div>
      )}
    </div>
  )
}

export default CustomInsightsResultsSection
