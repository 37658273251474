import { useEffect, useState } from 'react'

import { FilterCounts, ListingPageExternalApi, ListingPageItem } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { ScheduledMessagePickerUi } from '@graphql/types/query-types'
import { useAccountSettings } from '@utils/account/account.utils'
import { filterNotEmptyArray } from '@utils/array'
import { useTranslation } from '@utils/const/globals'
import { allEmailScheduledFilter, FilterDefinition, scheduledByMarketingFilter, scheduledByMeFilter, scheduledBySalesFilter } from '@utils/filter'
import { partialMatch } from '@utils/searchUtils'

import { emailScheduledPickerFilters, renderScheduledCustomFilters } from './EmailScheduledPicker.sidebar'
import { renderScheduledSearchTableColumns, renderScheduledTableColumns } from './EmailScheduledPicker.tables'
import { useEmailScheduledRequests } from './useEmailScheduledRequests'
import { EmailPickerProps, EmailType } from '../useEmailPickerProps'

export const useEmailScheduledPickerProps = (listingApi: ListingPageExternalApi | undefined) => {
  const { t } = useTranslation()
  const [filterCounts, setFilterCounts] = useState<FilterCounts>()
  const [salesUsers, setSalesUsers] = useState<string[]>()
  const { userName } = useAccountSettings()
  const request = useEmailScheduledRequests()

  const customFilterNames = emailScheduledPickerFilters.map((filter) => filter.name)

  useEffect(() => {
    if (listingApi && filterCounts !== undefined) {
      listingApi.updateFilterCounts(filterCounts)
    }
  }, [listingApi, filterCounts])

  const converEmailsToItemDto = (emails: (ScheduledMessagePickerUi | undefined)[]) => {
    return emails.filter(filterNotEmptyArray).map((email) => {
      const dto = email as ListingPageItem
      dto.name = email.msgTitle
      dto.externalId = email.id
      dto.item = JSON.stringify(dto)
      return dto
    })
  }

  useEffect(() => {
    const fetchSalesUsers = async () => {
      const users = await request.getAllSalesUsersRequest({ sortColumn: 'name', sortDirection: 'ASC' })
      if (users?.data?.getAllSalesUsers?.users) {
        const salesUsers = users.data.getAllSalesUsers.users.filter(filterNotEmptyArray).map((user) => user.fullName?.toLocaleLowerCase() ?? '')
        setSalesUsers(salesUsers)
      }
    }
    if (salesUsers === undefined) {
      fetchSalesUsers()
    }
  }, [])

  const isSalesUser = (name: string) => salesUsers?.includes(name.toLocaleLowerCase())
  const isMarketingUser = (name: string) => !isSalesUser(name)
  const isMyself = (name: string) => name.toLocaleLowerCase() === userName.toLocaleLowerCase()

  const filterByScheduler = (emails: ScheduledMessagePickerUi[], filter?: FilterDefinition) => {
    if (!filter) {
      return emails
    }
    const items = [...emails]
    if (filter.name === scheduledByMarketingFilter.name) {
      return items.filter((item) => isMarketingUser(item?.scheduledBy ?? ''))
    } else if (filter.name === scheduledBySalesFilter.name) {
      return items.filter((item) => isSalesUser(item?.scheduledBy ?? ''))
    } else if (filter.name === scheduledByMeFilter.name) {
      return items.filter((item) => isMyself(item?.scheduledBy ?? ''))
    }
    return []
  }

  const filterBySearch = (emails: ScheduledMessagePickerUi[], search: string) => {
    return emails.filter((email) => partialMatch(email, ['msgTitle', 'scheduledBy'], search))
  }

  const updateFilterCounts = (items: ScheduledMessagePickerUi[]) => {
    setFilterCounts({
      [allEmailScheduledFilter.name ?? '']: items.length,
      [scheduledByMarketingFilter.name]: filterByScheduler(items, scheduledByMarketingFilter).length,
      [scheduledBySalesFilter.name]: filterByScheduler(items, scheduledBySalesFilter).length,
      [scheduledByMeFilter.name]: filterByScheduler(items, scheduledByMeFilter).length,
    })
  }

  const filterRequest = async (filter: FilterDefinition, search?: string) => {
    const response = await request.getAllScheduledMessagesRequest({})
    if (response?.data?.getAllScheduledMessages) {
      let items = response.data.getAllScheduledMessages.filter(filterNotEmptyArray)
      if (search) {
        items = filterBySearch(items, search)
      }
      if (customFilterNames.includes(filter.name)) {
        items = filterByScheduler(items, filter)
      }
      if (filter.name === allEmailScheduledFilter.name) {
        updateFilterCounts(items)
      }
      return { data: converEmailsToItemDto(items) }
    }
    return { data: [], error: response?.errors?.join(', ') }
  }

  const config: EmailPickerProps = {
    customSources: {
      allItemFilter: allEmailScheduledFilter,
      itemName: 'Email',
      label: 'Scheduled emails',
      mainColumnName: 'Email Title',
      customRequestFilters: [
        {
          filter: allEmailScheduledFilter,
          request: async () => filterRequest(allEmailScheduledFilter),
          searchRequest: async (search) => filterRequest(allEmailScheduledFilter, search),
        },
        {
          filter: scheduledByMarketingFilter,
          request: async () => filterRequest(scheduledByMarketingFilter),
          searchRequest: async (search) => filterRequest(scheduledByMarketingFilter, search),
        },
        {
          filter: scheduledBySalesFilter,
          request: async () => filterRequest(scheduledBySalesFilter),
          searchRequest: async (search) => filterRequest(scheduledBySalesFilter, search),
        },
        {
          filter: scheduledByMeFilter,
          request: async () => filterRequest(scheduledByMeFilter),
          searchRequest: async (search) => filterRequest(scheduledByMeFilter, search),
        },
      ],
      value: EmailType.SCHEDULED,
      itemType: EmailType.SCHEDULED,
      sidebarProps: {
        hideFolders: true,
        hideTags: true,
      },
    },
    renderSearchColumns: (_, __, search, folders) => renderScheduledSearchTableColumns(search, folders, t),
    renderTableColumns: renderScheduledTableColumns(t),
    renderCustomFilters: (params) => renderScheduledCustomFilters(params, t),
    hasCustomFilters: true,
    hasTags: false,
    hasFavorites: false,
    hasCreatedByMe: false,
    subTypes: [],
    hideFolders: true,
    i18nListPageKey: 'Emails.Scheduled',
    externalDataLoading: salesUsers === undefined,
    enableSorting: true,
    sortingBy: [{ id: 'sentDate', desc: true }],
  }
  return config
}
