import getUnifiedListFieldMappings from '@graphql/microservices/list/getUnifiedListFieldMappings'
import getContacts from '@graphql/microservices/segment/getContacts'
import {
  GetUnifiedListFieldMappingsQuery,
  GetUnifiedListFieldMappingsQueryVariables,
  UnifiedListFieldMapping,
} from '@graphql/types/microservice/list-types'
import { GetContactsQuery, GetContactsQueryVariables, PageInput } from '@graphql/types/microservice/segment-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'

interface PreviewSegmentModalRequests {
  getListFields: () => Promise<UnifiedListFieldMapping[]>
  getContactsRequest: (page: PageInput) => Promise<{ headers: string[]; contacts: Object[] }>
}

export const useGetPreviewSegmentModalRequests = (): PreviewSegmentModalRequests => {
  const { client: listServiceClient } = useMicroserviceClient({ serviceName: MicroserviceClients.LIST })
  const { client: segmentClient } = useMicroserviceClient({ serviceName: MicroserviceClients.SEGMENT })

  const formatContacts = (contacts: Map<string, string[]>, headers: string[]) => {
    return Object.values(contacts ?? new Map()).map((contact) => {
      return (contact as string[]).reduce((contact, fieldValue, index) => {
        const header = (headers ?? [])[index]
        return header ? { ...contact, [header]: fieldValue } : contact
      }, {})
    })
  }

  const getListFields = (): Promise<UnifiedListFieldMapping[]> =>
    listServiceClient
      .query<GetUnifiedListFieldMappingsQuery, GetUnifiedListFieldMappingsQueryVariables>({
        query: getUnifiedListFieldMappings,
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => data.unifiedListFieldMappings as UnifiedListFieldMapping[])

  const getContactsRequest = (page: PageInput): Promise<{ headers: string[]; contacts: Object[] }> => {
    return segmentClient
      .query<GetContactsQuery, GetContactsQueryVariables>({
        query: getContacts,
        fetchPolicy: 'network-only',
        variables: {
          page,
          isSubmissionList: false,
        },
      })
      .then(({ data: { getContacts } }) => {
        const headers = (getContacts?.headers ?? []) as string[]
        const contacts = formatContacts(getContacts?.contacts, headers)
        return { contacts, headers }
      })
  }

  return {
    getListFields,
    getContactsRequest,
  }
}
