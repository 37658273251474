import { TFunction } from 'i18next'

import { PushPolicy } from '@graphql/types/microservice/crm-sync-types'
import { EntityTypeWithCrmToStandardFieldMap } from '@graphql/types/microservice/crm-types'
import { FormDto } from '@graphql/types/microservice/form-types'
import { IngestFieldMapping, UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import {
  ColumnType,
  CommonMappingColumn,
  DynamicListItems,
  DynamicListRowData,
  OptionType,
  RowType,
} from '@src/pages/datamanagement/components/CrmContacts/components/DynamicList/utils/DynamicList.interfaces'
import { DuplicateInfo, State } from '@src/pages/datamanagement/context/DataManagementContext'
import { FieldValidationState } from '@utils/crm.utils'
import { CRMConnectorType } from '@utils/hooks/useCRM'

export const populatePreDefinedMapping = (
  row: UnifiedListFieldMapping,
  crmDef: { entityTypes: string[]; crmToStdMapping?: EntityTypeWithCrmToStandardFieldMap[] },
  stdFields: OptionType[],
  connectorType: string,
  availableColumnsPerEntity: DynamicListItems[],
  stdRow?: DynamicListRowData
) => {
  // map ingestfield Mapping if row is selected for standard field
  if (
    row.standardFieldKey &&
    (row.ingestFieldMappings === undefined || (row.ingestFieldMappings && row.ingestFieldMappings?.length < crmDef.entityTypes.length))
  ) {
    // find missing ingest object type
    if (row.ingestFieldMappings === undefined) {
      row.ingestFieldMappings = []
    }
    insertUnmappedIngestField(row, crmDef, connectorType, availableColumnsPerEntity)
  } else if (row.ingestFieldMappings?.length && row.ingestFieldMappings?.length > 0 && !row.standardFieldKey) {
    const objectType = row.ingestFieldMappings[0]?.objectType
    const crmDisplayName = row.ingestFieldMappings[0]?.mapping
    let crmName = crmDisplayName
    const stdRowValues = stdRow?.columns.filter((col) => col.column === objectType).map((col) => col.values)
    if (stdRowValues && stdRowValues.length > 0) {
      const field = stdRowValues[0]?.filter((field) => field?.display === crmDisplayName)
      crmName = field && field.length > 0 ? field[0].key : crmName
    }

    const mappingPerEntityType = crmDef.crmToStdMapping?.filter((mapping) => mapping.entityTypeName === objectType)
    if (mappingPerEntityType && mappingPerEntityType.length > 0) {
      const mappingPerCrmName = mappingPerEntityType[0]?.crmToStandardFieldMapList?.filter((mapping) => mapping?.crmName === crmName)
      if (mappingPerCrmName && mappingPerCrmName.length > 0) {
        row.standardFieldKey = mappingPerCrmName[0]?.standardName ?? ''
        const stdFieldMapping = stdFields.filter((field) => field.key === row.standardFieldKey)
        if (stdFieldMapping && stdFieldMapping.length > 0) {
          row.standardFieldKey = stdFieldMapping[0].key
          row.displayName = stdFieldMapping[0].display
          row.deleted = false
          row.dataType = stdFieldMapping[0].dataType
          row.hidden = false
        }
      }
    }

    insertUnmappedIngestField(row, crmDef, connectorType, availableColumnsPerEntity)
  }
}

const insertUnmappedIngestField = (
  row: UnifiedListFieldMapping,
  crmDef: { entityTypes: string[]; crmToStdMapping?: EntityTypeWithCrmToStandardFieldMap[] },
  connectorType: string,
  availableColumnsPerEntity: DynamicListItems[]
) => {
  const existingIngestEntities = row.ingestFieldMappings?.map((ingestField) => {
    return ingestField ? ingestField?.objectType ?? '' : ''
  })

  const unmappedIngestEntityType: string[] = crmDef.entityTypes.map((entityType) => {
    return !existingIngestEntities?.includes(entityType) ? entityType : ''
  })

  unmappedIngestEntityType.forEach((entityType) => {
    const mappingPerEntityType = crmDef.crmToStdMapping?.filter((mapping) => mapping.entityTypeName === entityType)
    const noAvailableFields: OptionType[] = [
      {
        display: '',
        key: '',
      },
    ]
    const dynamicListItems: DynamicListItems[] = availableColumnsPerEntity.filter((columns) => columns.column === entityType)
    const availableFields: OptionType[] =
      dynamicListItems && dynamicListItems.length > 0 && dynamicListItems[0].values ? dynamicListItems[0].values : noAvailableFields

    if (mappingPerEntityType && mappingPerEntityType.length > 0) {
      const mappingName = mappingPerEntityType[0]?.crmToStandardFieldMapList?.filter((mapping) => mapping?.standardName === row.standardFieldKey)
      if (!row.ingestFieldMappings) {
        row.ingestFieldMappings = [] as IngestFieldMapping[]
      }
      const mappingKey = mappingName ? mappingName[0]?.crmName : ''
      const isMappingInAvailableField = availableFields.filter((fields) => fields.key === mappingKey).length > 0
      if (mappingKey !== undefined) {
        const format = getDateTimeFormat(connectorType, row.dataType as string)
        const ingestMap: IngestFieldMapping[] = [
          {
            mapping: mappingKey,
            objectType: entityType,
            source: 'CRM',
          },
        ]
        if (format !== '') {
          ingestMap[0].format = format
        }
        if (!isMappingInAvailableField) {
          ingestMap[0].mapping = '-1'
        }
        row.ingestFieldMappings = row.ingestFieldMappings?.concat(ingestMap)
      }
    }
  })
}

const getDateTimeFormat = (connectorType: string, dataType: string): string => {
  if (connectorType === CRMConnectorType.SALESFORCE) {
    if (dataType === 'DATE') {
      return 'yyyy-MM-dd'
    } else if (dataType === 'DATETIME') {
      return "yyyy-MM-dd'T'HH:mm:ss.SSSX"
    }
  } else if (connectorType === CRMConnectorType.NETSUITE) {
    if (dataType === 'DATE') {
      return 'yyyy-MM-dd'
    } else if (dataType === 'DATETIME') {
      return "yyyy-MM-dd'T'HH:mm:ss.SSSXXX"
    }
  }
  return ''
}

const getCurrentValue = (cellObj: DynamicListItems) => {
  switch (cellObj.type) {
    case ColumnType.STATIC:
      return cellObj.staticValue
    case ColumnType.OPTION:
      // find display name corresponding to selected value
      const displayName = cellObj.values?.filter((item) => item.key === cellObj.selected)

      if (displayName && displayName.length > 0) {
        return displayName[0].display
      } else {
        return cellObj.selected
      }

    default:
      return cellObj.selected
  }
}

export const duplicationCheck = (columnName: string, rowMap: DynamicListRowData[], currentDupes: DuplicateInfo[], language: string) => {
  const test: { name: string; rowId: number }[] = []
  const accumulatedDupes: number[] = []

  rowMap.forEach((row: { columns: any[]; id: number }) => {
    const foundColumn = row.columns.filter((col) => col.column === columnName)
    if (foundColumn[0].selected !== '-1') {
      const checkedValue = getCurrentValue(foundColumn[0])
      test.push({ name: checkedValue ?? foundColumn[0].staticValue ?? foundColumn[0].selected, rowId: row.id })

      const dupesFound = test.filter((item) =>
        localeCompare(item.name, checkedValue ?? foundColumn[0].staticValue ?? foundColumn[0].selected, language)
      )
      if (dupesFound.length > 1) {
        dupesFound.forEach((dupe) => {
          accumulatedDupes.push(dupe.rowId)
        })
      }
    }
  })

  return currentDupes.map((entry) => {
    if (accumulatedDupes.find((item) => item === entry.rowId)) {
      return {
        rowId: entry.rowId,
        type: columnName === 'ACT-ON CONTACTS' ? FieldValidationState.CUST_MATCH_STD : FieldValidationState.DUP_CRM_PRIMARY,
      }
    } else {
      return entry
    }
  })
}

export const localeCompare = (val1?: string, val2?: string, language?: string): boolean => {
  //Not valid to call function when val1 is null or undefined
  if (!val1 || !val2) {
    return false
  }
  val2 = val2 ? val2.trim() : val2
  const lang = !!language ? undefined : language
  return val1.trim().localeCompare(val2, lang, { sensitivity: 'accent' }) === 0
}

export const updatePushPolicyOnRowChange = (
  pushPolicy: PushPolicy,
  t: TFunction,
  update: (field: keyof State, value: any) => void,
  column: string,
  newValue: string,
  newRow: UnifiedListFieldMapping,
  previousDisplayName?: string
) => {
  const hasMappedCRM = newRow.ingestFieldMappings?.some((ingest) => ingest?.source === 'CRM')
  let enabledFields = []
  if (column === t(CommonMappingColumn.ACTON_CONTACTS)) {
    enabledFields = (pushPolicy?.enabledFields ?? []).map((field = '') => (previousDisplayName === field ? newRow.displayName ?? '' : field))
    if (!previousDisplayName && pushPolicy?.allowPush && hasMappedCRM) {
      enabledFields.push(newValue)
    }
    update(`pushPolicy`, { ...pushPolicy, enabledFields })
  } else if (!Object.values(CommonMappingColumn).includes(column as CommonMappingColumn)) {
    if (hasMappedCRM && pushPolicy?.allowPush && !pushPolicy?.enabledFields?.includes(newRow.displayName)) {
      enabledFields = [...(pushPolicy?.enabledFields ?? []), newRow.displayName]
      update(`pushPolicy`, { ...pushPolicy, enabledFields })
    }
  }
}

export const getEnabledFieldsFromMapping = (
  unifiedListFieldMappings: UnifiedListFieldMapping[],
  fieldMappings: UnifiedListFieldMapping[],
  enabledFields: string[],
  allowPush?: boolean
): string[] => {
  const getFieldMappingsByScore = (fieldMappings: UnifiedListFieldMapping[]) =>
    fieldMappings.filter(({ dataType, deleted, hidden }) => dataType === 'SCORE' && !hidden && !deleted)

  const getFieldsByMappedCRM = (fieldMappings: UnifiedListFieldMapping[]) =>
    fieldMappings.filter(({ ingestFieldMappings, hidden, deleted }) => {
      const existingField = !hidden && !deleted
      const hasMappedCRM = ingestFieldMappings?.some((ingest) => ingest?.source === 'CRM')
      return hasMappedCRM && existingField
    })

  const mappedFieldMappings = getFieldsByMappedCRM(unifiedListFieldMappings ?? [])

  const getFieldsWithUpdates = () => {
    const existingScoreFieldMappings =
      getFieldMappingsByScore(mappedFieldMappings).reduce((existingFieldMappings: string[], fieldMapping) => {
        const updatedFieldMapping = fieldMappings.find(({ columnIndex }) => fieldMapping.columnIndex === columnIndex)
        return updatedFieldMapping ? existingFieldMappings : [...existingFieldMappings, fieldMapping.displayName ?? '']
      }, []) ?? []
    const updatedScoreFieldMappings = getFieldMappingsByScore(getFieldsByMappedCRM(fieldMappings)).map(({ displayName }) => displayName ?? '') ?? []
    return [...new Set([...existingScoreFieldMappings, ...updatedScoreFieldMappings])]
  }

  if (allowPush !== undefined) {
    return [
      ...new Set(
        allowPush
          ? getFieldsByMappedCRM(mappedFieldMappings).map(({ displayName }) => displayName ?? '')
          : getFieldMappingsByScore(mappedFieldMappings).map(({ displayName }) => displayName ?? '')
      ),
    ]
  } else {
    return [...new Set([...enabledFields, ...getFieldsWithUpdates()])]
  }
}

export const removeTemporaryRow = (rows: DynamicListRowData[]) => {
  // look for previously inserted
  return rows.filter((row) => row.type != RowType.TEMPORARY)
}

/*
 * Check if the field is being used in any asset
 * @param {UnifiedListFieldMapping} row - the field to check
 * @returns {boolean} - true if the field is being used
 */
export const checkFieldUsage = async (
  fieldMapping: UnifiedListFieldMapping,
  update: <T extends keyof State>(field: T, value: State[T]) => void,
  getFormsByColumnNameRequest: (columnName: string) => Promise<FormDto[]>,
  fieldMappingUsageWarning?: State['fieldMappingUsageWarning']
): Promise<boolean> => {
  if (!fieldMapping.displayName) {
    return false
  }
  update('fieldMappingUsageWarning', {
    fieldMapping,
    forms: [],
    loading: true,
  })
  const responses = await Promise.all([getFormsByColumnNameRequest(fieldMapping.displayName)])
  const isBeingUsed = responses.some((response) => response.length > 0)
  const [forms] = responses
  update(
    'fieldMappingUsageWarning',
    fieldMappingUsageWarning || isBeingUsed
      ? {
          fieldMapping,
          forms,
          loading: false,
        }
      : undefined
  )
  return isBeingUsed
}
