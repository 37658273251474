import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import BackButton from '@components/BackButton/BackButton'
import Breadcrumbs from '@components/Breadcrumbs/Breadcrumbs'
import Button, { ButtonType } from '@components/Button'
import { YesNo } from '@components/ConfirmationModal'
import PaneDrawer, { PaneDrawerItem } from '@components/PaneDrawer/PaneDrawer'
import Svg, { SvgNames } from '@components/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { UrlGroup } from '@graphql/types/query-types'
import LandingPageURLGroup from '@src/pages/Content/LandingPages/LandingPagesManager/components/LandingPageURLGroup/LandingPageURLGroup'
import ManageURLsConfirmationModal from '@src/pages/Content/LandingPages/LandingPagesManager/components/ManageURLsConfirmationModals/ManageURLsConfirmationModal'
import SeoUrlModal from '@src/pages/Content/LandingPages/LandingPagesManager/components/SeoUrlModal/SeoUrlModal'
import URLGroupModal from '@src/pages/Content/LandingPages/LandingPagesManager/components/URLGroupModal/URLGroupModal'
import { useLandingPageContext } from '@src/pages/Content/LandingPages/LandingPagesManager/context/LandingPagesManager.context'
import useSeoUrlActions from '@src/pages/Content/LandingPages/LandingPagesManager/hooks/useSeoUrlActions'
import useShortUrlActions from '@src/pages/Content/LandingPages/LandingPagesManager/hooks/useShortUrlActions'
import useURLGroupActions from '@src/pages/Content/LandingPages/LandingPagesManager/hooks/useURLGroupActions'
import { filterNotEmptyArray } from '@utils/array'

import './LandingPageManageURLs.css'

interface LandingPageManageURlsProps {
  onBack?: () => void
  className?: string
  dataTest?: string
}

const rootClass = 'landing-page-manage-urls'

const LandingPageManageURLs: FC<LandingPageManageURlsProps> = ({ onBack, dataTest = rootClass, className = '' }: LandingPageManageURlsProps) => {
  const { t } = useTranslation()
  const {
    landingPage: { defaultUrlGroup, urlGroups, prettyURLPrefix },
    accountSlugs,
  } = useLandingPageContext()
  const { handleAdd, handleEdit, handleDelete } = useURLGroupActions()
  const { handleAddSeoUrl, handleEditSeoUrl, handleDeleteSeoUrl } = useSeoUrlActions()
  const { handleGenerateShortUrl, handleDeleteShortUrl } = useShortUrlActions()

  const [openURLGroupModal, setOpenURLGroupModal] = useState<boolean>(false)
  const [urlGroupToEdit, setUrlGroupToEdit] = useState<UrlGroup>()
  const [groupToDelete, setGroupToDelete] = useState<UrlGroup>()
  const [groupIdToDeleteShort, setGroupIdToDeleteShort] = useState<string>()
  const [groupIdToSetupShort, setGroupIdToSetupShort] = useState<string>()
  const [groupToSetupSeoUrl, setGroupToSetupSeoUrl] = useState<UrlGroup>()
  const [groupToDeleteSeoUrl, setGroupToDeleteSeoUrl] = useState<UrlGroup>()
  const [openGenerateLimitModal, setOpenGenerateLimitModal] = useState<boolean>(false)
  const [defaultOpenPane, setDefaultOpenPane] = useState<ReactNode | string>('')

  const handleAddURLGroup = useCallback(() => {
    setOpenURLGroupModal(true)
    setUrlGroupToEdit(undefined)
  }, [])

  const getOnEditName = useCallback(
    (group: UrlGroup) => () => {
      setOpenURLGroupModal(true)
      setUrlGroupToEdit(group)
    },
    []
  )

  const getOnDeleteGroup = useCallback((group: UrlGroup) => () => setGroupToDelete(group), [])
  const getShortUrlAction = useCallback(
    (group: UrlGroup) => () => {
      if (!group.shortUrl) {
        setGroupIdToSetupShort(group.id)
      } else {
        setGroupIdToDeleteShort(group.id)
      }
    },
    []
  )
  const onDeleteSeoUrl = useCallback(
    () =>
      setGroupToSetupSeoUrl((cur) => {
        setGroupToDeleteSeoUrl(cur)
        return undefined
      }),
    []
  )

  const getSeoUrlAction = useCallback((group: UrlGroup) => () => setGroupToSetupSeoUrl(group), [])

  const handleCloseModal = useCallback(() => setOpenURLGroupModal(false), [])
  const handleClosSeoUrlModal = useCallback(() => setGroupToSetupSeoUrl(undefined), [])

  const handleDeleteConfirmAnswer = useCallback(
    (answer: YesNo) => {
      setGroupToDelete(undefined)
      if (answer === YesNo.YES) {
        groupToDelete && handleDelete(groupToDelete)
        setDefaultOpenPane(panes[0].title)
      }
    },
    [groupToDelete, handleDelete]
  )

  const handleConfirmShortURLRemove = useCallback(
    (answer: YesNo) => {
      setGroupIdToDeleteShort((groupId) => {
        if (answer === YesNo.YES && groupId) {
          handleDeleteShortUrl(groupId)
        }
        return undefined
      })
    },
    [handleDeleteShortUrl]
  )

  const handleConfirmSeoURLRemove = useCallback(
    (answer: YesNo) => {
      setGroupToDeleteSeoUrl((cur) => {
        if (answer === YesNo.NO) {
          setGroupToSetupSeoUrl(cur)
        } else {
          cur && handleDeleteSeoUrl(cur.id)
        }
        return undefined
      })
    },
    [handleDeleteSeoUrl]
  )

  const handleSetupShortURL = useCallback(
    (answer: YesNo) => {
      setGroupIdToSetupShort((groupId) => {
        if (answer === YesNo.YES && groupId) {
          handleGenerateShortUrl(groupId, () => setOpenGenerateLimitModal(true))
        }
        return undefined
      })
    },
    [handleGenerateShortUrl]
  )

  const handleAddEditURLGroup = useCallback(
    (name: string, isEdit: boolean) => {
      setOpenURLGroupModal(false)
      if (isEdit) {
        urlGroupToEdit && handleEdit(name, urlGroupToEdit.id)
      } else {
        handleAdd(name)
        setDefaultOpenPane(name)
      }
    },
    [handleEdit, handleAdd, urlGroupToEdit]
  )

  const handleAddEditSeoURL = useCallback(
    (seoUrl: string, isEdit: boolean) => {
      setGroupToSetupSeoUrl((cur) => {
        if (cur) {
          if (isEdit) {
            handleEditSeoUrl(seoUrl, cur.id)
          } else {
            handleAddSeoUrl(seoUrl, cur.id)
          }
        }
        return undefined
      })
    },
    [handleAddSeoUrl, handleEditSeoUrl]
  )

  const handleCloseGenerateLimitModal = useCallback(() => setOpenGenerateLimitModal(false), [])

  const seoUrlToSetup = groupToSetupSeoUrl ? groupToSetupSeoUrl.seoFriendlyUrl ?? prettyURLPrefix : undefined
  const seoUrlToDelete = groupToDeleteSeoUrl ? groupToDeleteSeoUrl.seoFriendlyUrl : undefined
  const panes: PaneDrawerItem[] = useMemo(() => {
    const enhancedDefaultGroup: UrlGroup = {
      ...defaultUrlGroup,
      name: 'Default',
    }
    const groups = [enhancedDefaultGroup, ...(urlGroups?.filter(filterNotEmptyArray) ?? [])]

    return groups?.map((group) => ({
      content: (
        <LandingPageURLGroup
          key={group.id}
          {...group}
          onEdit={getOnEditName(group)}
          onDelete={getOnDeleteGroup(group)}
          onSeoURLClick={getSeoUrlAction(group)}
          onShortURLClick={getShortUrlAction(group)}
        />
      ),
      key: group.id,
      title:
        group.name === 'Default' ? (
          <Typography
            text={t('Landing.Page.Manage.URLs.Default.Title')}
            type={TextType.SECTION_HEADER}
            weight={TextWeight.MEDIUM}
            lineHeight={LineHeight.MEDIUM_LARGE}
            tagProps={{ light: { type: TextType.BODY_TEXT_LIGHT, inline: true } }}
          />
        ) : (
          group.name
        ),
      required: group.name === 'Default',
      dropdownMenuItems:
        group.name === 'Default'
          ? undefined
          : [
              { text: t('Edit group name'), icon: SvgNames.pencil, onClick: getOnEditName(group) },
              { text: t('Delete group'), icon: SvgNames.delete, onClick: getOnDeleteGroup(group) },
            ],
    }))
  }, [defaultUrlGroup, urlGroups, getOnEditName, getOnDeleteGroup, getSeoUrlAction, getShortUrlAction, t])

  useEffect(() => {
    setDefaultOpenPane(panes[0].title)
  }, [])

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__header`} data-test={`${dataTest}-header`}>
        {!!onBack && <BackButton onClick={onBack} dataTest={`${dataTest}-back-button`} />}
        {!!onBack ? (
          <Breadcrumbs
            className={`${rootClass}__header-breadcrumbs`}
            breadcrumbs={[
              { text: t('Details'), customTypography: { weight: TextWeight.MEDIUM, type: TextType.SECTION_HEADER } },
              { text: t('Public URL Manager'), customTypography: { weight: TextWeight.BOLD, type: TextType.SECTION_HEADER } },
            ]}
          />
        ) : (
          <Typography text={t('Public URL Manager')} type={TextType.SECTION_HEADER} weight={TextWeight.BOLD} />
        )}
        <Button buttonType={ButtonType.INFO} onClick={handleAddURLGroup} className={`${rootClass}__add-button`} dataTest={`${dataTest}-add-button`}>
          <Svg name={SvgNames.plus} />
          {t('Add URL group')}
        </Button>
      </div>
      <Typography text={t('Landing.Page.Manage.URLs.Sub.Header')} type={TextType.BODY_TEXT_LIGHT} className={`${rootClass}__sub-header`} />
      <PaneDrawer key={`${defaultOpenPane}`} panes={panes} defaultOpenPanes={[defaultOpenPane]} accordionType={'multiple'} />
      <URLGroupModal isOpen={openURLGroupModal} onClose={handleCloseModal} onAddEdit={handleAddEditURLGroup} name={urlGroupToEdit?.name} />
      <SeoUrlModal
        seoUrl={seoUrlToSetup}
        onClose={handleClosSeoUrlModal}
        onDelete={onDeleteSeoUrl}
        onAddEdit={handleAddEditSeoURL}
        accountSlugs={accountSlugs}
      />
      <ManageURLsConfirmationModal
        handleConfirmGroupDelete={handleDeleteConfirmAnswer}
        handleConfirmSeoURLRemove={handleConfirmSeoURLRemove}
        handleConfirmShortURLRemove={handleConfirmShortURLRemove}
        handleSetupShortURL={handleSetupShortURL}
        handleCloseGenerateLimitModal={handleCloseGenerateLimitModal}
        groupToDelete={groupToDelete}
        seoUrlToDelete={seoUrlToDelete}
        shortURLToDelete={groupIdToDeleteShort}
        groupIdToSetupShort={groupIdToSetupShort}
        openGenerateLimitModal={openGenerateLimitModal}
      />
    </div>
  )
}

export default LandingPageManageURLs
