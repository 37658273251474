import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import { useQuery, useApolloClient } from '@apollo/client'
import { renderLoader } from '@components/Loader/Loader'
import { useTranslation } from '@const/globals'
import getOptInForms from '@graphql/microservices/sms-management/getOptInForms'
import getSentInitialMessage from '@graphql/microservices/sms-management/getSentInitialMessage'
import isInitialMessageInSendingStatus from '@graphql/microservices/sms-management/isInitialMessageInSendingStatus'
import cancelScheduledSmsLaunch from '@graphql/mutations/cancelScheduledSmsLaunch'
import deleteMessageDrafts from '@graphql/mutations/deleteSMSMessages'
import getLaunchesForAccountPaginated from '@graphql/queries/getLaunchesForAccountPaginated'
import {
  GetSentInitialMessageQuery,
  GetSentInitialMessageQueryVariables,
  QueryGetOptInFormsArgs,
  Query,
  IsInitialMessageInSendingStatusQuery,
  IsInitialMessageInSendingStatusQueryVariables,
} from '@graphql/types/microservice/sms-management-types'
import {
  CancelScheduledSmsLaunchMutation,
  CancelScheduledSmsLaunchMutationVariables,
  DeleteMessageDraftsMutation,
  DeleteMessageDraftsMutationVariables,
} from '@graphql/types/mutation-types'
import { GetLaunchesForAccountPaginatedQuery, GetLaunchesForAccountPaginatedQueryVariables, LaunchListingEntry } from '@graphql/types/query-types'
import InitialMessageContainer from '@src/pages/sms/initialMessage'
import { formOptInsSortBy } from '@src/pages/sms/marketingListOptIns/MarketingListOptInsContainer'
import { useAccountSettings } from '@utils/account/account.utils'
import { getDayOfWeekDate, getStandardFormattedDate } from '@utils/date'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'
import { logNewRelicError } from '@utils/new-relic.utils'
import { Messages, Message } from '@utils/sms.utils'

import SMSListing from './SMSListing'

export enum Status {
  SENDING = 'Sending',
  DRAFT = 'Draft',
  SENT = 'Sent',
  LOCKED_DRAFT = 'Locked_Draft',
  SCHEDULED = 'Scheduled',
}

export const MapTabs = new Map([
  ['sent', 'SENT'],
  ['scheduled', 'SCHEDULED'],
  ['drafts', 'DRAFT'],
])

const defaultState: Messages = {
  messages: [],
  totalCount: 11,
  pageSize: 10,
  pageIndex: 0,
}

export interface SMSListingStatusToast {
  showStatus: boolean
  statusMessage: string
  successStatus: boolean
}

type AllProps = { location: { search: string; pathname: string; state?: { tab: string; status: SMSListingStatusToast } } }

export const emptySMSListingStatusToast: SMSListingStatusToast = { showStatus: false, statusMessage: '', successStatus: false }

export const SMSListingContainer: FC<AllProps> = (props: AllProps) => {
  const {
    location: { search, pathname, state },
  } = props

  const [paginationState, setPaginationState] = useState<Messages>({ ...defaultState })
  const defaultTab = state?.tab || 'sent'
  const [tab, setTab] = useState<string>(defaultTab)
  const [loading, setLoading] = useState(true)

  const client = useApolloClient()
  const {
    loading: lunchesForAccountPaginatedLoading,
    error: pageError,
    data,
    refetch,
  } = useQuery<GetLaunchesForAccountPaginatedQuery, GetLaunchesForAccountPaginatedQueryVariables>(getLaunchesForAccountPaginated, {
    client,
    fetchPolicy: 'network-only',
    variables: {
      page: paginationState.pageIndex,
      size: paginationState.pageSize,
      status: MapTabs.get(tab) || defaultTab,
    },
  })

  const { t } = useTranslation()
  const history = useHistory()

  const [messages, setMessages] = useState<Message[]>([])
  const [smsListingStatusToast, setToastMessage] = useState<SMSListingStatusToast>()
  const [sentInitialMessage, setSentInitialMessage] = useState<boolean>(false)
  const [formOptInsCount, setFormOptInsCount] = useState<number>(0)
  const [initialMessageInSendingStatus, setInitialMessageInSendingStatus] = useState<boolean>(false)
  const [loadingMutation, setLoadingMutation] = useState<boolean>(false)

  const { accountId } = useAccountSettings()
  const { client: smsClient } = useMicroserviceClient({ serviceName: MicroserviceClients.SMS_MANAGEMENT })

  const sortDirection: any = formOptInsSortBy.desc ? 'DESC' : 'ASC'
  const { data: formOptInsData, loading: formOptInsLoading } = useQueryOnMount<Query, QueryGetOptInFormsArgs>(getOptInForms, {
    client: smsClient,
    fetchPolicy: 'network-only',
    variables: {
      accountId,
      size: 10,
      page: 0,
      sortDirection,
      sortColumn: formOptInsSortBy.id as any,
    },
  })

  const { data: sentInitialMessageData, loading: sentInitialMessageLoading } = useQueryOnMount<
    GetSentInitialMessageQuery,
    GetSentInitialMessageQueryVariables
  >(getSentInitialMessage, {
    client: smsClient,
    fetchPolicy: 'network-only',
    variables: {
      accountId,
    },
  })

  const { data: isInitialMessageInSendingStatusData, loading: isInitialMessageInSendingStatusLoading } = useQueryOnMount<
    IsInitialMessageInSendingStatusQuery,
    IsInitialMessageInSendingStatusQueryVariables
  >(isInitialMessageInSendingStatus, {
    client: smsClient,
    fetchPolicy: 'network-only',
    variables: {
      accountId,
    },
  })

  useEffect(() => {
    setLoading(lunchesForAccountPaginatedLoading || loadingMutation)
  }, [loadingMutation, lunchesForAccountPaginatedLoading])

  useEffect(() => {
    setFormOptInsCount(formOptInsData?.getOptInForms.totalCount ?? 0)
  }, [formOptInsData])

  useEffect(() => {
    const totalCount = data?.getLaunchesForAccountPaginated.totalCount ?? 0
    setPaginationState({ ...paginationState, totalCount })
  }, [data])

  useEffect(() => {
    if (sentInitialMessageData?.getSentInitialMessage) {
      setSentInitialMessage(sentInitialMessageData.getSentInitialMessage)
    }
  }, [sentInitialMessageData])

  useEffect(() => {
    if (isInitialMessageInSendingStatusData?.isInitialMessageInSendingStatus) {
      setInitialMessageInSendingStatus(isInitialMessageInSendingStatusData.isInitialMessageInSendingStatus)
    }
  }, [isInitialMessageInSendingStatusData])

  useEffect(() => {
    setToastMessage(state?.status ?? emptySMSListingStatusToast)
  }, [search])

  useEffect(() => {
    if (data?.getLaunchesForAccountPaginated?.messages) {
      processMessages(data.getLaunchesForAccountPaginated?.messages)
    }
  }, [data])

  useEffect(() => {
    if (pageError) {
      logNewRelicError(pageError)
    }
  }, [pageError])

  useEffect(() => {
    if (!search) {
      history.replace({ pathname: location.pathname, search: `tab=${defaultTab}` })
    } else {
      setTab(search.split('tab=')[1])
    }
  }, [])

  const fetchData = (pageIndex: number, pageSize: number) => {
    setPaginationState({ ...paginationState, pageIndex, pageSize })
  }

  const closeStatus = useCallback(() => {
    setToastMessage((cur) => {
      if (cur?.statusMessage) {
        // Clear the sending state from URL to avoid re-rendering if page is reloaded
        window.history.replaceState({}, '', `${pathname}${history.location.search}`)
        return emptySMSListingStatusToast
      }
      return cur
    })
  }, [])

  const sortMessages = (a: Message, b: Message) => {
    if ((a.status === 'SENDING' && b.status === 'SENDING') || (a.status !== 'SENDING' && b.status !== 'SENDING')) {
      return a.lastModifiedTime > b.lastModifiedTime ? -1 : 1
    } else if (a.status === 'SENDING') {
      return -1
    }
    // means b.status === 'SENDING'
    return 1
  }

  const processMessages = (messages: LaunchListingEntry[]) => {
    switch (tab) {
      case 'sent':
        const sentMessages = messages
          .filter((msg: Message) => msg.status === 'SENDING' || msg.status === 'SENT')
          .map((msg: Message) => {
            const sentAt = msg.status === 'SENDING' ? `${Status[msg.status]}...` : getStandardFormattedDate(msg.lastModifiedTime as number)
            return { ...msg, sentAt }
          })
          .sort(sortMessages)
        setMessages(sentMessages)
        break
      case 'drafts':
        const draftMessages = messages
          .filter((msg) => msg.status === 'DRAFT' || msg.status === 'LOCKED_DRAFT')
          .sort((a: Message, b: Message) => (a.lastModifiedTime > b.lastModifiedTime ? -1 : 1))
          .map((msg) => {
            const title = msg.title === '' ? t('Untitled') : msg.title
            const lastModifiedTime = getStandardFormattedDate(msg.lastModifiedTime, true)
            return { ...msg, title, lastModifiedTime }
          })
        setMessages(draftMessages)
        break
      case 'scheduled':
        const scheduleedMessages = messages
          .filter((msg) => msg.status === 'SCHEDULED')
          .sort((a: Message, b: Message) => (a.lastModifiedTime > b.lastModifiedTime ? -1 : 1))
          .map((msg: Message) => {
            const scheduledSendTime = `${getDayOfWeekDate(msg.scheduledSendTime as number, true)}`
            return { ...msg, scheduledSendTime }
          })
        setMessages(scheduleedMessages)
        break
    }
  }

  const onTabChange = useCallback(
    (tab: string) => {
      history.replace({ pathname: location.pathname, search: `tab=${tab}` })
      setPaginationState(defaultState)
      setTab(tab)
      closeStatus()
    },
    [history, closeStatus]
  )

  const onDeleteDrafts = useCallback(
    async (msgDraftIds: string[]) => {
      setLoadingMutation(true)
      try {
        await client.mutate<DeleteMessageDraftsMutation, DeleteMessageDraftsMutationVariables>({
          mutation: deleteMessageDrafts,
          variables: { msgDraftIds },
        })
        await refetch()
        setToastMessage({
          showStatus: true,
          statusMessage: 'Messages were deleted.',
          successStatus: true,
        })
      } catch (_ex) {
        setToastMessage({
          showStatus: true,
          statusMessage: 'Something went wrong on our end. No messages were deleted.',
          successStatus: false,
        })
        logNewRelicError(_ex)
      }
      setLoadingMutation(false)
    },
    [client, refetch]
  )

  const onCancelSends = useCallback(
    async (launchIds: string[]) => {
      setLoadingMutation(true)
      try {
        const { data } = await client.mutate<CancelScheduledSmsLaunchMutation, CancelScheduledSmsLaunchMutationVariables>({
          mutation: cancelScheduledSmsLaunch,
          variables: {
            launchIds,
          },
        })

        if (data?.cancelScheduledSmsLaunch) {
          await refetch()
          setToastMessage({
            showStatus: true,
            statusMessage: 'Message successfully cancelled.',
            successStatus: true,
          })
        }
      } catch (_ex) {
        setToastMessage({
          showStatus: true,
          statusMessage: 'Something went wrong on our end. No messages were unscheduled.',
          successStatus: false,
        })
        logNewRelicError(_ex)
      }
      setLoadingMutation(false)
    },
    [client, refetch]
  )

  if (sentInitialMessageLoading || formOptInsLoading || isInitialMessageInSendingStatusLoading) {
    return renderLoader('loader--white-background')
  }

  return sentInitialMessage || formOptInsCount === 0 ? (
    <SMSListing
      loading={loading}
      isInitialMessageInSending={initialMessageInSendingStatus}
      messages={messages}
      pageError={pageError}
      smsListingStatusToast={smsListingStatusToast ?? emptySMSListingStatusToast}
      tab={tab}
      onTabChange={onTabChange}
      closeStatus={closeStatus}
      onDeleteDrafts={onDeleteDrafts}
      onCancelSends={onCancelSends}
      totalCount={paginationState.totalCount}
      pageSize={paginationState.pageSize}
      pageIndex={paginationState.pageIndex}
      fetchData={fetchData}
    />
  ) : (
    <InitialMessageContainer />
  )
}

export default SMSListingContainer
