import { Dispatch, SetStateAction } from 'react'

import { LazyQueryExecFunction } from '@apollo/client'
import { CheckFieldMappingQuery, GetFieldMappingQuery } from '@graphql/types/microservice/crm-types'
import { ScoreSheetsQuery, StandardFieldsQuery } from '@graphql/types/microservice/list-types'
import { InputMaybe } from '@graphql/types/mutation-types'
import { Exact } from '@graphql/types/query-types'
import { DynamicListRowData, RowType } from '@src/pages/datamanagement/components/CrmContacts/components/DynamicList/utils/DynamicList.interfaces'
import { CrmDefTypes } from '@src/pages/datamanagement/components/CrmContacts/CrmContacts'
import {
  CRMContactsAddRowFunction,
  useCRMContactsAddRowHandler,
} from '@src/pages/datamanagement/components/CrmContacts/utils/useCRMContactsRowHandler/useCRMContactsRowHandler.add'
import {
  CRMContactsDeleteRowFunction,
  useCRMContactsDeleteRowHandler,
} from '@src/pages/datamanagement/components/CrmContacts/utils/useCRMContactsRowHandler/useCRMContactsRowHandler.delete'
import {
  CRMContactsUpdateRowFunction,
  useCRMContactsUpdateRowHandler,
} from '@src/pages/datamanagement/components/CrmContacts/utils/useCRMContactsRowHandler/useCRMContactsRowHandler.update'
import { BaseRow } from '@src/pages/datamanagement/components/CrmContacts/utils/usePopulateRows/usePopulateRows'
import { CrmContactData } from '@src/pages/datamanagement/context/DataManagementContext'
import { TableType } from '@utils/crm.utils'

interface UseCRMContactsRowHandlerFunctions {
  deleteRow: CRMContactsDeleteRowFunction
  addRow: CRMContactsAddRowFunction
  updateRow: CRMContactsUpdateRowFunction
}

export interface UseCRMContactsRowHandlerProps {
  baseRow: BaseRow
  setUpdatedRow: Dispatch<
    SetStateAction<{
      rowId: number
      rowType?: RowType
      tableType?: TableType
    }>
  >
  standardFieldsList?: StandardFieldsQuery
  dataLoaded: CrmContactData
  crmDef: CrmDefTypes
  lookupFieldMappingData?: GetFieldMappingQuery
  lookupFieldMappingLoading: boolean
  scoresheetsList?: ScoreSheetsQuery
  scoresheetsLoading: boolean
  setDataLoaded: Dispatch<SetStateAction<CrmContactData>>
  checkRowFieldMappings: LazyQueryExecFunction<
    CheckFieldMappingQuery,
    Exact<{ aofield?: InputMaybe<string>; crmfields?: InputMaybe<InputMaybe<string> | InputMaybe<string>[]> }>
  >
  createScoreSheetRow: (rowId: number, rows: DynamicListRowData[]) => DynamicListRowData[]
  updateDisplayedIcons: (rowMap: DynamicListRowData[]) => void
}

export const useCRMContactsRowHandler = (props: UseCRMContactsRowHandlerProps): UseCRMContactsRowHandlerFunctions => {
  const { addRow } = useCRMContactsAddRowHandler(props)
  const { deleteRow } = useCRMContactsDeleteRowHandler(props)
  const { updateRow } = useCRMContactsUpdateRowHandler({ ...props, addRow })

  return {
    addRow,
    deleteRow,
    updateRow,
  }
}
