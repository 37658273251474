import { gql } from '@apollo/client'

export default gql`
  query problems($accountId: ID) {
    problems(accountId: $accountId) {
      description
      problemType
    }
  }
`
