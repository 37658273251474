import React, { FC, useCallback, useMemo, useState } from 'react'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { useTranslation } from '@const/globals'
import resetBrandingDomainDisplayedUrl from '@graphql/mutations/resetBrandingDomainDisplayedUrl'
import setBrandingDomainDisplayedUrl from '@graphql/mutations/setBrandingDomainDisplayedUrl'
import getBrandingDomainDisplayedUrls from '@graphql/queries/getBrandingDomainDisplayedUrls'
import {
  MutationSetBrandingDomainDisplayedUrlArgs,
  ResetBrandingDomainDisplayedUrlMutation,
  ResetBrandingDomainDisplayedUrlMutationVariables,
  SetBrandingDomainDisplayedUrlMutation,
} from '@graphql/types/mutation-types'
import { GetBrandingDomainDisplayedUrlsQuery, GetBrandingDomainDisplayedUrlsQueryVariables } from '@graphql/types/query-types'
import { StatusToastType } from '@interface/StatusToast'
import MarketingDomain from '@src/pages/Content/Branding/tabs/WebIdentity/MarketingDomain/MarketingDomain'
import { logNewRelicError } from '@utils/new-relic.utils'

interface Props {
  setToastStatus: (value: StatusToastType) => void
}

const MarketingDomainContainer: FC<Props> = ({ setToastStatus }) => {
  const { t } = useTranslation()
  const client = useApolloClient()
  const [invalidURL, setInvalidURL] = useState<string>('')

  const {
    data,
    loading,
    error,
    refetch: getDomain,
  } = useQuery<GetBrandingDomainDisplayedUrlsQuery, GetBrandingDomainDisplayedUrlsQueryVariables>(getBrandingDomainDisplayedUrls, {
    client,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const [setMutation, { loading: loadingSet }] = useMutation<SetBrandingDomainDisplayedUrlMutation, MutationSetBrandingDomainDisplayedUrlArgs>(
    setBrandingDomainDisplayedUrl,
    {
      client,
      fetchPolicy: 'no-cache',
    }
  )

  const [resetMutation, { loading: loadingReset }] = useMutation<
    ResetBrandingDomainDisplayedUrlMutation,
    ResetBrandingDomainDisplayedUrlMutationVariables
  >(resetBrandingDomainDisplayedUrl, {
    client,
    fetchPolicy: 'no-cache',
  })

  const proceedResponse = useCallback<(status?: string, isReset?: boolean) => void>(
    (status, isReset) => {
      if (status === 'ok') {
        getDomain()
        setInvalidURL('')
        setToastStatus({
          showStatus: true,
          title: t('Success!'),
          statusMessage: t(`${isReset ? 'Reset' : 'Set'}.Marketing.Domain.Success.Message`),
          successStatus: true,
        })
      } else {
        setToastStatus({
          showStatus: true,
          title: !isReset ? t('Set.Marketing.Domain.Error.Title') : undefined,
          statusMessage: t(`${isReset ? 'Reset' : 'Set'}.Marketing.Domain.Error.Message`),
          successStatus: false,
        })
      }
    },
    [getDomain, setToastStatus]
  )

  const handleSetURL = useCallback<(url: string) => void>(
    (url) => {
      setMutation({
        variables: {
          url,
          createLeCert: true,
        },
      })
        .then(({ data }) => {
          const status = data?.setBrandingDomainDisplayedUrl?.status
          proceedResponse(status)
          status !== 'ok' && setInvalidURL(url)
        })
        .catch((error) => {
          logNewRelicError(error)
        })
    },
    [t, setMutation, getDomain, setInvalidURL]
  )

  const handleResetURL = useCallback<() => void>(() => {
    resetMutation({
      variables: {},
    })
      .then(({ data }) => {
        proceedResponse(data?.resetBrandingDomainDisplayedUrl?.status, true)
      })
      .catch((error) => {
        logNewRelicError(error)
      })
  }, [t, resetMutation, getDomain])

  const onCancel = useCallback<() => void>(() => {
    setInvalidURL('')
  }, [setInvalidURL])

  if (error) {
    logNewRelicError(error)
  }

  const domain = useMemo<string>(() => {
    const { currentUrl, defaultUrl } = { ...data?.getBrandingDomainDisplayedUrls }
    return (currentUrl?.domain || defaultUrl?.domain) ?? ''
  }, [data])

  const url = useMemo<string>(() => {
    const { currentUrl, defaultUrl } = { ...data?.getBrandingDomainDisplayedUrls }
    return (currentUrl?.url || defaultUrl?.url) ?? ''
  }, [data])

  const isDefaultURL = useMemo<boolean>(
    () => data?.getBrandingDomainDisplayedUrls?.currentUrl.url === data?.getBrandingDomainDisplayedUrls?.defaultUrl.url,
    [data?.getBrandingDomainDisplayedUrls]
  )

  return (
    <MarketingDomain
      loading={loading || loadingSet || loadingReset}
      url={url}
      domain={domain}
      onSaveUrl={handleSetURL}
      onReset={handleResetURL}
      isDefaultURL={isDefaultURL}
      invalidURL={invalidURL}
      onCancel={onCancel}
    />
  )
}

export default MarketingDomainContainer
