import React, { FC, ReactNode } from 'react'
// wraps https://github.com/wojtekmaj/react-time-picker#readme
import TimePicker from 'react-time-picker'

import classNames from 'classnames'

import Label from '@components/Label'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'

import './TimeInput.css'

type Props = {
  label?: string | ReactNode
  value: string
  name?: string
  required?: boolean
  disabled?: boolean
  error?: boolean
  hideClear?: boolean
  onChange: (newValue: string | Date) => void
  className?: string
  dataTest?: string
}

const rootClass = 'time-input'

/**
 * @deprecated use <TimePickerV2 instead
 */
const TimeInput: FC<Props> = (props: Props) => {
  const { label, value, name, required = false, disabled, error, hideClear, onChange, dataTest = rootClass, className = '' } = props
  const clearIcon = hideClear ? null : <Svg name={SvgNames.cancelCircle} type={SvgType.ICON} />
  return (
    <span className={`${rootClass}__container`} data-test={dataTest}>
      {label && <Label>{label}</Label>}
      <TimePicker
        value={value}
        name={name}
        required={required}
        disabled={disabled}
        onChange={onChange}
        className={classNames(rootClass, className, {
          [`${rootClass}__error`]: error,
          [`${rootClass}__clear-hidden`]: hideClear,
          [`${rootClass}__disabled`]: disabled,
        })}
        data-test={dataTest}
        disableClock={true}
        format={'hh:mm a'}
        clearIcon={clearIcon}
        amPmAriaLabel={'time-time-picker-ampm'}
        hourAriaLabel={'time-time-picker-hour'}
        minuteAriaLabel={'time-time-picker-minute'}
      />
    </span>
  )
}

export default TimeInput
