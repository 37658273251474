import React, { FC, useState } from 'react'

import Container from '@components/Container'
import NumberedList from '@components/NumberedList/NumberedList'
import Typography, { TextAlign, TextType, TextWeight } from '@components/Typography/Typography'

import './NumberedListContainer.css'

import Button, { ButtonType } from '@components/Button/Button'

export type NumberedList = {
  label?: string
  data?: DataType[]
}

interface Props {
  data?: NumberedList
  tooltip?: string
  dataTest?: string
}

interface DataType {
  title: string
  items: any[]
}

const rootClass = 'numbered-list-container'

const NumberedListContainer: FC<Props> = (props: Props) => {
  const { data, dataTest = rootClass } = props
  const [toggle, setToggle] = useState(false)
  const maxNum = 9
  const buttonContent = !toggle ? 'Show More' : 'Show Less'
  const showButton = data?.data?.some((dataObj: any) => dataObj.items.length > maxNum)

  const renderContainer = data?.data?.map((data: any) => {
    const originalArr = data.items
    const slicedArr = data.items.slice(0, maxNum)
    const displayedArr = toggle ? originalArr : slicedArr
    const tooltip = data.items.some((item: any) => item.visits)

    return <NumberedList key={data.title} title={data.title} items={displayedArr} mainToolTip={tooltip ? 'visits' : undefined} />
  })
  return (
    <Container data-cy={dataTest}>
      <Typography text={data?.label} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
      <div className={`${rootClass}__list-columns`}>{renderContainer}</div>
      {showButton && (
        <Button
          buttonType={ButtonType.TEXT}
          fullWidth
          className={`${rootClass}__button`}
          onClick={() => (toggle ? setToggle(false) : setToggle(true))}
        >
          <Typography text={buttonContent} type={TextType.BODY_TEXT_SMALL} textAlign={TextAlign.CENTER} />
        </Button>
      )}
    </Container>
  )
}

export default NumberedListContainer
