import { ApolloClient, ApolloError } from '@apollo/client'
import getSummaryByLaunchId from '@graphql/microservices/sms-reporting/getSummaryByLaunchId'
import getSummaryByMessageId from '@graphql/microservices/sms-reporting/getSummaryByMessageId'
import getAutomatedProgramMessageDetails from '@graphql/queries/getAutomatedProgramMessageDetails'
import getMessageDetails from '@graphql/queries/getMessageDetails'
import getWelcomeSendMessageDetails from '@graphql/queries/getWelcomeSendMessageDetails'
import {
  GetSummaryByLaunchIdQuery,
  GetSummaryByLaunchIdQueryVariables,
  GetSummaryByMessageIdQuery,
  GetSummaryByMessageIdQueryVariables,
} from '@graphql/types/microservice/sms-reporting-types'
import {
  GetAutomatedProgramMessageDetailsQuery,
  GetAutomatedProgramMessageDetailsQueryVariables,
  GetMessageDetailsQuery,
  GetMessageDetailsQueryVariables,
  GetWelcomeSendMessageDetailsQuery,
  GetWelcomeSendMessageDetailsQueryVariables,
} from '@graphql/types/query-types'
import { logNewRelicError } from '@utils/new-relic.utils'

export const fetchLaunchDetails = (launchId: string, client: ApolloClient<any>, responseUpdate: (response: any) => void) => {
  responseUpdate({ messageLoading: true })
  client
    .query<GetMessageDetailsQuery, GetMessageDetailsQueryVariables>({
      query: getMessageDetails,
      fetchPolicy: 'network-only',
      variables: {
        launchId,
      },
    })
    .then(({ data }) => {
      if (data.getMessageDetails) {
        return responseUpdate({ messageDetails: data.getMessageDetails, messageLoading: false })
      }
    })
    .catch((e: ApolloError) => {
      logNewRelicError(e)
      responseUpdate({ messageLoading: false })
    })
}

export const fetchLaunch = (launchId: string, accountId: string, client: ApolloClient<any>, responseUpdate: (response: any) => void) => {
  responseUpdate({ loading: true })
  client
    .query<GetSummaryByLaunchIdQuery, GetSummaryByLaunchIdQueryVariables>({
      query: getSummaryByLaunchId,
      fetchPolicy: 'network-only',
      variables: {
        accountId,
        launchId,
      },
    })
    .then(({ data }) => {
      if (data) {
        return responseUpdate({ data, loading: false })
      }
    })
    .catch((e: ApolloError) => {
      logNewRelicError(e)
      responseUpdate({ loading: false })
    })
}

export const fetchSummaryByMessageIdQuery = (
  messageId: string,
  accountId: string,
  client: ApolloClient<any>,
  responseUpdate: (response: any) => void
) => {
  responseUpdate({ loading: true })
  client
    .query<GetSummaryByMessageIdQuery, GetSummaryByMessageIdQueryVariables>({
      query: getSummaryByMessageId,
      fetchPolicy: 'network-only',
      variables: {
        accountId,
        messageId,
      },
    })
    .then(({ data }) => {
      if (data) {
        return responseUpdate({ data, loading: false })
      }
    })
    .catch((e: ApolloError) => {
      responseUpdate({ loading: false })
      logNewRelicError(e)
    })
}

export const fetchAutomatedProgramMessageDetails = (
  messageId: number,
  programId: string,
  client: ApolloClient<any>,
  responseUpdate: (response: any) => void
) => {
  responseUpdate({ messageLoading: true })
  client
    .query<GetAutomatedProgramMessageDetailsQuery, GetAutomatedProgramMessageDetailsQueryVariables>({
      query: getAutomatedProgramMessageDetails,
      fetchPolicy: 'network-only',
      variables: {
        messageId,
        programId: programId === messageId.toString() ? '' : programId,
      },
    })
    .then(({ data }) => {
      if (data) {
        return responseUpdate({ messageDetails: data.getAutomatedProgramMessageDetails, messageLoading: false })
      }
    })
    .catch((e: ApolloError) => {
      responseUpdate({ messageLoading: false })
      logNewRelicError(e)
    })
}

export const fetchWelcomeSendMessageDetails = (messageId: number, client: ApolloClient<any>, responseUpdate: (response: any) => void) => {
  responseUpdate({ messageLoading: true })
  client
    .query<GetWelcomeSendMessageDetailsQuery, GetWelcomeSendMessageDetailsQueryVariables>({
      query: getWelcomeSendMessageDetails,
      fetchPolicy: 'network-only',
      variables: {
        messageId,
      },
    })
    .then(({ data }) => {
      if (data) {
        return responseUpdate({ messageDetails: data.getWelcomeSendMessageDetails, messageLoading: false })
      }
    })
    .catch((e: ApolloError) => {
      responseUpdate({ messageLoading: false })
      logNewRelicError(e)
    })
}
