import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Svg from '@components/Svg'
import Typography, { TextWeight } from '@components/Typography/Typography'

import SvgNames from '../Svg/SvgNames'

import './ErrorAlertBanner.css'

interface Props {
  className?: string
  dataTest?: string
  primaryButtonAction: () => void
  secondaryButtonAction: () => void
  textPrimary: string
  textSecondary: string
  primaryButtonText: string
  secondaryButtonText: string
}

const rootClass = 'error-alert-banner'

const ErrorAlertBanner: FC<Props> = (props: Props) => {
  const {
    primaryButtonAction,
    secondaryButtonAction,
    dataTest = rootClass,
    className = '',
    textPrimary,
    textSecondary,
    primaryButtonText,
    secondaryButtonText,
  } = props

  const renderMain = () => (
    <div className={`${rootClass}__main`}>
      <div className={`${rootClass}__position`}>
        <div className={`${rootClass}__content`}>
          <div className={`${rootClass}__nav`}>
            <Svg dataTest={`${dataTest}__icon`} name={SvgNames.errorAlert} />
          </div>
          <div className={`${rootClass}__title`}>
            <Typography text={textPrimary} weight={TextWeight.BOLD} className={`${rootClass}__top`} />
            {textSecondary && <Typography text={textSecondary} weight={TextWeight.MEDIUM_LIGHT} className={`${rootClass}__bottom`} />}
          </div>
          <div className={`${rootClass}__buttons`}>
            <Button buttonType={ButtonType.ERASE} dataTest={`${dataTest}__reconnect`} onClick={primaryButtonAction}>
              {primaryButtonText}
            </Button>
            <Button buttonType={ButtonType.DISMISS} dataTest={`${dataTest}__dismiss`} onClick={secondaryButtonAction}>
              {secondaryButtonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {renderMain()}
    </div>
  )
}

export default ErrorAlertBanner
