import React from 'react'
import { PlaceholderProps } from 'react-select'

import classNames from 'classnames'

import { selectRootClass } from '@components/SelectV2/SelectV2.utils'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { css } from '@emotion/css'

const CustomPlaceholder = (props: PlaceholderProps<any>) => {
  const { children, getStyles, innerProps } = props

  return (
    <div className={classNames(css(getStyles('placeholder', props)), `${selectRootClass}__placeholder`)} {...innerProps}>
      <Typography inline text={children} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.MEDIUM_LIGHT} />
    </div>
  )
}

export default CustomPlaceholder
