import React, { FC } from 'react'

// eslint-disable-next-line no-restricted-imports
import { format, set } from 'date-fns'

import {
  getVideoConferenceIconName,
  getVideoConferenceLabel,
} from '@src/pages/EmailComposer/EmailModals/components/EventModal/components/EventBlock/EventBlock.utils'
import { fontStyles, renderAddToCalendar, renderAddToCalendarIcons, renderDetails } from '@src/pages/EmailComposer/utils/Addons/customAddons.helpers'
import { CustomAddonModalForm } from '@src/pages/EmailComposer/utils/Addons/customAddons.types'

import { EventModalTabs, PLACEHOLDER_VIDEO_CONFERENCE_OPTION, WebinarType } from '../../utils/EventModal.utils'

interface EventBlockProps {
  data: CustomAddonModalForm
  eventStrings: {
    addToCalendar: string
    addEvent: string
    getDirections: string
  }
  messageId: string
  accountId: string
  calendarDownloadLink: string
  blockId: string
  companyName: string
  className?: string
  dataTest?: string
}

interface EventDetails {
  iconName: string
  mainText: string
  subText: string
  isLink?: boolean
  largeIcon?: boolean
}

const rootClass = 'event-block'

export const EventBlock: FC<EventBlockProps> = (props: EventBlockProps) => {
  const {
    data,
    data: {
      details: { eventName: name, allDay, timeZone, startDate, endDate, endTime, description, addVideoConferencing },
      locationDetails: { selectedAddress, roomDetails, includeRegistration, registrationLabel, registrationLink, includeMapToAddress },
      webinarDetails: { selectedWebinarType, webinarLink, webinarPasscode },
      activeTab,
    },
    eventStrings: { addToCalendar, addEvent, getDirections },
    companyName,
    accountId,
    blockId,
    calendarDownloadLink,
  } = props

  const isOnlineEvent = activeTab === EventModalTabs.ONLINE
  const showVideoConferencing = addVideoConferencing || (isOnlineEvent && selectedWebinarType !== PLACEHOLDER_VIDEO_CONFERENCE_OPTION)
  const showLocation = !!selectedAddress && !isOnlineEvent
  const formattedStartDate = startDate ? format(startDate, 'EEEE, MMMM dd, yyyy') : ''
  const formattedEndDate = endDate ? format(endDate, 'EEEE, MMMM dd, yyyy') : startDate ? format(startDate, 'EEEE, MMMM dd, yyyy') : ''
  const formattedStartTime = startDate ? format(startDate, 'h:mmaaa') : ''
  let formattedEndTime = ''

  if (allDay && endDate) {
    formattedEndTime = format(endDate, 'h:mmaaa')
  } else if (!allDay && startDate && endTime) {
    const [hours, minutes] = endTime.split(':')
    const newHours = endTime.includes('PM') ? parseInt(hours) + 12 : parseInt(hours)

    const date = set(startDate.getTime(), { hours: newHours, minutes: parseInt(minutes) })
    formattedEndTime = format(date, 'h:mmaaa')
  }

  const eventDetails: EventDetails[] = [
    {
      iconName: 'calendar',
      mainText:
        allDay && startDate && endDate && startDate.toDateString() !== endDate.toDateString()
          ? `${formattedStartDate} - ${formattedEndDate}`
          : formattedStartDate,
      subText: !allDay ? `${formattedStartTime} - ${formattedEndTime} ${timeZone.value}` : '',
    },
    ...(showLocation
      ? [
          {
            iconName: 'location',
            mainText:
              selectedAddress.label === 'Default Account Address'
                ? companyName
                : selectedAddress.label === 'User Address'
                ? 'Location'
                : selectedAddress.label,
            subText: selectedAddress.subText ?? '',
          },
        ]
      : []),
    ...(roomDetails ? [{ iconName: 'room', mainText: 'Room details', subText: roomDetails }] : []),
    ...(showVideoConferencing
      ? [
          {
            iconName: getVideoConferenceIconName(selectedWebinarType?.label ?? ''),
            mainText: getVideoConferenceLabel(selectedWebinarType?.label ?? ''),
            subText: webinarLink,
            isLink: true,
            largeIcon: selectedWebinarType?.label !== WebinarType.EXTERNAL_EVENT_LINK,
          },
        ]
      : []),
    ...(showVideoConferencing && webinarPasscode ? [{ iconName: 'lock', mainText: 'Passcode', subText: webinarPasscode }] : []),
  ]

  const renderMapToAddress = () => (
    <>
      <img
        style={{ width: '100%', borderRadius: '9px 9px 0 0' }}
        alt={'map'}
        src={`https://maps.googleapis.com/maps/api/staticmap?center=${encodeURIComponent(
          selectedAddress?.subText ?? ''
        )}&zoom=14&size=400x300&scale=2&markers=${encodeURIComponent(selectedAddress?.subText ?? '')}&key=${process.env.NX_GOOGLE_MAPS_API_KEY}`}
      />
      <div style={{ margin: '12px 6px', border: '1px solid #1F62E4', borderRadius: '6px' }}>
        <a
          style={{ textDecoration: 'none', color: '#1f62e4', display: 'block', padding: '10px 24px', textAlign: 'center' }}
          href={`https://www.google.com/maps/place/${encodeURIComponent(selectedAddress?.subText ?? '')}`}
          target={'_blank'}
          rel="noreferrer"
        >
          {getDirections}
        </a>
      </div>
    </>
  )

  return (
    <div id={rootClass} style={{ textAlign: 'left', padding: '10px' }}>
      <h1 style={{ margin: 0, textAlign: 'left', marginBottom: '10px', fontSize: '24px', wordBreak: 'break-all' }}>{name}</h1>
      {renderDetails(eventDetails)}
      {description && description != '' && (
        <div id={'event-description'} style={{ padding: '16px 0px 32px 3px' }}>
          <p style={{ ...fontStyles, display: 'inline-block', wordBreak: 'break-word', fontWeight: '400', textAlign: 'left' }}>{description}</p>
        </div>
      )}
      {(includeRegistration || isOnlineEvent) && (
        <div id={'event-registration'} style={{ marginTop: '10px' }}>
          <a
            style={{
              display: 'block',
              textAlign: 'center',
              color: '#FFF',
              backgroundColor: '#1F62E4',
              textDecoration: 'none',
              padding: '10px 24px',
              borderRadius: '6px',
              fontSize: '16px',
            }}
            href={isOnlineEvent ? webinarLink : registrationLink}
            target={'_blank'}
            rel="noreferrer"
          >
            {isOnlineEvent ? 'Join event' : registrationLabel}
          </a>
        </div>
      )}
      {includeMapToAddress && (
        <div id={'event-map-to-address'} style={{ margin: '12px 6px 0 6px', borderRadius: '10px', border: '1px solid #ddd' }}>
          {renderMapToAddress()}
        </div>
      )}
      {renderAddToCalendar(calendarDownloadLink, addEvent, addToCalendar)}
      <table align="center" id={'event-add-to-calendar-icons'} style={{ borderCollapse: 'separate', borderSpacing: '10px 10px' }}>
        <tbody>
          <tr>{renderAddToCalendarIcons(data, accountId, blockId, calendarDownloadLink, true)}</tr>
        </tbody>
      </table>
    </div>
  )
}
