import { MessageContentResponse } from '@graphql/types/query-types'
import { T_BEE_BLANK } from '@src/pages/EmailComposer/utils/EmailComposer.constants'
import { GetMessageById, HandleCreateOrGetBlankMessage, HandleCreateOrGetMessage } from '@src/pages/EmailComposer/utils/EmailComposer.types'
import { getMessageConfigurationType, handleInvalidMessageError, handleRetrieveFail } from '@src/pages/EmailComposer/utils/EmailComposer.utils'
import { detectEmailType, getComputedMessageType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { getAssetReportDetailsFromLocalStorage } from '@src/pages/EmailComposer/utils/EmailComposerRecipients.utils'
import { retrieveMessageHelper } from '@src/pages/EmailComposer/utils/retrieveMessageHelper'
import { caseInsensitiveAlphabeticSort } from '@utils/array'
import { OmitMessageType } from '@utils/composer/commonComposer/types'
import { DEFAULT_EMAIL_TITLE, EmailMessage, EmailSender } from '@utils/composer/context/EmailComposer.context'
import { ExtendedMessageType } from '@utils/composer/emailComposer/types'
import {
  FORM_DATA_AUTORESPONSE_KEY,
  FORM_ID_STORAGE_KEY,
  NOT_PERSONALIZED_FROM_ADDRESS_FALLBACK_SENDER_VALUE,
  PROGRAM_MESSAGE_STORAGE_KEY,
  SHOULD_FILL_MESSAGE_ID_STORAGE_KEY,
} from '@utils/composer/settings.constants'
import { logError } from '@utils/env'
import { logNewRelicError } from '@utils/new-relic.utils'
import { DeepPartial } from '@utils/types'
import { getLocalStorageItem, getSessionStorageItem, removeLocalStorageItem } from '@utils/window'

export const getClassicFormData = () => {
  const formJson = getLocalStorageItem(FORM_DATA_AUTORESPONSE_KEY)
  if (formJson !== null) {
    try {
      const formData: Record<string, Record<string, string>> = JSON.parse(formJson)
      const formStorageId = getSessionStorageItem(FORM_ID_STORAGE_KEY) ?? ''
      const formTitle = formData?.[formStorageId]?.title ?? ''
      const formDataId = formData?.[formStorageId]?.id ?? ''

      return { formStorageId, formTitle, formDataId }
    } catch (e) {
      logNewRelicError(e, 'Error parsing form data passed from previous page')
    }
  }
  return {}
}

export const getProgramData = () => {
  const programJson = getLocalStorageItem(PROGRAM_MESSAGE_STORAGE_KEY)
  if (programJson !== null) {
    try {
      const programData: Record<string, string> = JSON.parse(programJson)
      return {
        programTitle: programData?.programTitle ?? '',
        programSubject: programData?.programSubject ?? '',
        programId: programData?.programId ?? '',
      }
    } catch (e) {
      logNewRelicError(e, 'Error parsing program data passed from previous page')
    }
  }
  return {}
}

const updateForClassicFormData = (formTitle: string) => {
  return {
    fallbackSender: NOT_PERSONALIZED_FROM_ADDRESS_FALLBACK_SENDER_VALUE,
    subject: formTitle,
    title: formTitle,
  }
}

const getMessageById = ({
  userId,
  startId,
  history,
  messageId,
  accountId,
  isFromStart,
  messageType,
  isPlainTextOnly,
  setDefaultValues,
  containerValuesRef,
  enableEmailDraftsReact,
  update,
  getEmailMessage,
  changeLastSavedTemplate,
  messageContent,
}: GetMessageById) => {
  const { isEmailBlankMessage } = detectEmailType(messageType)
  const isFirstEdit = !!new URLSearchParams(history.location.search).get('firstEdit')

  if (messageId) {
    return getEmailMessage(messageId).then(({ data, errors }) => {
      if (data?.retrieveMessage) {
        update({ retrieveMessageSuccess: true })

        const message = containerValuesRef.current.detectedURLChanges.isNewAssetReportMessage
          ? {
              ...(data?.retrieveMessage as OmitMessageType<MessageContentResponse>),
              sendDetails: {
                ...data.retrieveMessage.sendDetails,
                sendToLists: messageContent?.sendDetails?.sendToLists,
                sendToContacts: messageContent?.sendDetails?.sendToContacts,
                assetReportRecipientDetails: messageContent?.sendDetails?.assetReportRecipientDetails,
              },
            }
          : (data?.retrieveMessage as OmitMessageType<MessageContentResponse>)

        const preparedData = retrieveMessageHelper({
          message,
          userId,
          accountId,
          containerValuesRef,
          setDefaultValues,
          isFirstEdit,
          isPlainTextOnly,
        })

        update({ ...preparedData })

        if (!!containerValuesRef.current.message.assetReportRecipientDetails) {
          update({
            ...preparedData,
            message: {
              ...preparedData.message,
              title: 'Untitled Email',
              campaignId: undefined,
            },
            campaigns: [],
          })
        }

        if (!isFromStart && preparedData.message?.templateJson) {
          changeLastSavedTemplate(preparedData.message)
        }

        return Promise.resolve(preparedData)
      } else {
        if (isEmailBlankMessage || startId === T_BEE_BLANK) {
          return Promise.resolve(null)
        } else {
          handleInvalidMessageError(errors) && handleRetrieveFail(messageId, history, enableEmailDraftsReact)
        }
      }
    })
  }
  return Promise.reject()
}

const handleCreateOrGetBlankMessage = ({
  data,
  computedMsgType,
  isPlainTextOnly,
  shouldCreateMessage,
  update,
  createMessage,
  getCommonCreateNewMessageFields,
}: HandleCreateOrGetBlankMessage) => {
  if (!data) {
    //* After retrieving t-bee-blank messages if data is null then need to create msg
    //* For Blank Messages We use TEMPLATE msg type but in FE we set configs as BLANK_MESSAGE
    if (shouldCreateMessage) {
      createMessage({
        messageType: computedMsgType,
        isPlainTextOnly,
        beeBlankSetup: shouldCreateMessage,
      }).then(({ id }) => {
        update({ ...getCommonCreateNewMessageFields(id), loading: false, createMessageSuccess: true })
      })
    }
  }
}

export const handleCreateOrGetMessage = ({
  userId,
  history,
  startId,
  accountId,
  messageId,
  messageType,
  isPlainTextOnly,
  isUploadHtmlOnly,
  containerValuesRef,
  shouldCreateMessage,
  shouldFillMessageData,
  enableEmailDraftsReact,
  update,
  createMessage,
  getEmailMessage,
  changeLastSavedTemplate,
  loadFromAddressesRequest,
  createEmptyFormAutoResponseBeeMsg,
  isFromDefault,
  assetReportLocalStorageKey,
}: HandleCreateOrGetMessage) => {
  const { isEmailTemplate, isEmailABTest, isEmailRSS, isEmailProgram, isEmailForm, isEmailResend, isOptInEmail } = detectEmailType(messageType)
  const { isEmailBlankMessage } = detectEmailType(containerValuesRef.current.messageConfiguration.messageType)

  try {
    const { formTitle, formDataId } = getClassicFormData()
    const { programTitle, programSubject, programId } = getProgramData()

    const commonGetMessageProps = {
      userId,
      history,
      startId,
      messageType,
      accountId,
      isPlainTextOnly,
      containerValuesRef,
      enableEmailDraftsReact,
      update,
      getEmailMessage,
      changeLastSavedTemplate,
    }

    const getCommonCreateNewMessageFields = (
      id: string,
      message?: DeepPartial<EmailMessage>
    ): Record<string, boolean | DeepPartial<EmailMessage>> => {
      const preparedMessage = message ? message : {}
      return {
        message: {
          ...preparedMessage,
          id,
          isPlainTextOnly,
          isUploadHtmlOnly,
          fallbackSender: NOT_PERSONALIZED_FROM_ADDRESS_FALLBACK_SENDER_VALUE,
        },
        isCreatedNewMessage: true,
      }
    }

    const loadAndSetSender = () => {
      return loadFromAddressesRequest({ sortColumn: 'name' }).then((data) => {
        if (data.data) {
          const senders: EmailSender[] = data.data.loadFromAddressesPage
            .filter((sender) => sender.isVerified)
            .map((sender) => ({
              name: sender.name ?? '',
              email: sender.email ?? '',
              uuid: sender.uuid ?? '',
              isDefault: sender.isDefault ?? false,
            }))
          const defaultSender = senders.find((sender) => sender.isDefault)
          update({
            emailSenders: senders.sort((a, b) => caseInsensitiveAlphabeticSort(a.name, b.name)),
            message: { sender: defaultSender?.uuid ?? '', senderUUID: defaultSender?.uuid ?? '' },
          })
        }
      })
    }

    if (shouldFillMessageData) {
      if (programId && isEmailProgram) {
        if (!startId && programTitle) {
          loadAndSetSender().finally(() => {
            update({
              startSession: true,
              message: {
                id: messageId,
                messageType,
                title: programTitle === '(Untitled)' ? DEFAULT_EMAIL_TITLE : programTitle,
                subject: programSubject,
                fallbackSender: NOT_PERSONALIZED_FROM_ADDRESS_FALLBACK_SENDER_VALUE,
              },
            })
          })
        }

        removeLocalStorageItem(SHOULD_FILL_MESSAGE_ID_STORAGE_KEY)
        removeLocalStorageItem(PROGRAM_MESSAGE_STORAGE_KEY)

        return
      }
    }

    const computedMsgType: ExtendedMessageType = getComputedMessageType(messageType, isEmailBlankMessage)

    if (shouldCreateMessage) {
      if (formDataId && isEmailForm) {
        const queryParams = new URLSearchParams(window.location.search)
        const isCustomHtml: boolean = !!queryParams.get('uploadHtml') || false
        //* For form messages
        createEmptyFormAutoResponseBeeMsg(formDataId, isCustomHtml).then((id) => {
          if (id) {
            //* If startId is present the getMessageById should handle loading state
            const setLoadingState = startId ? {} : { loading: false }
            update({
              ...setLoadingState,
              message: { id, messageType, isPlainTextOnly },
              isCreatedNewMessage: true,
            })
          }

          if (!startId && formTitle) {
            loadAndSetSender().finally(() => {
              update({ message: { ...updateForClassicFormData(formTitle) }, loading: false })
            })
          }
        })

        if (startId) {
          getMessageById({
            messageId: startId,
            ...commonGetMessageProps,
          })
        }

        return
      }

      const assetReportRecipientDetails = getAssetReportDetailsFromLocalStorage(assetReportLocalStorageKey)

      createMessage({
        messageType: computedMsgType,
        fromDefault: isFromDefault,
        isPlainTextOnly,
        isCustomHtml: isUploadHtmlOnly,
        startId: startId ?? '',
        assetReportRecipientDetails,
      }).then(({ id, messageContentResponse, publishId }) => {
        update({ createMessageSuccess: true })
        //* Handled case when quickStart param exists and need to retrieve existing template during create new one without calling retrieveMessage query
        if (isFromDefault && messageContentResponse) {
          //* If Starting from sample is from template page remove sendDetails property
          if (isEmailTemplate) {
            delete messageContentResponse['sendDetails']
          }

          const preparedData = retrieveMessageHelper({
            message: { ...messageContentResponse, messageType, lastEdited: undefined },
            userId,
            accountId,
            containerValuesRef,
          })

          update({ ...preparedData, ...getCommonCreateNewMessageFields(id, preparedData.message) })

          if (isOptInEmail) {
            update({ haveUnsavedChanges: true })
          }
        } else {
          //* If startId is present the getMessageById should handle loading state
          const setLoadingState = startId ? {} : { loading: !(isPlainTextOnly || isUploadHtmlOnly) }
          update({ ...getCommonCreateNewMessageFields(id), ...setLoadingState })

          if (isEmailRSS) {
            update({ message: { publishId } })
          }
        }

        if (startId) {
          //* When opening message from sent messages the type of composer should be draft
          const setDefaultValues = startId.startsWith('s') && !isEmailResend

          getMessageById({
            messageId: startId,
            isFromStart: true,
            setDefaultValues,
            messageContent: messageContentResponse as OmitMessageType<MessageContentResponse>,
            ...commonGetMessageProps,
          })
        }
      })
    } else {
      getMessageById({
        messageId,
        ...commonGetMessageProps,
      }).then((data) => {
        if (isEmailBlankMessage) {
          handleCreateOrGetBlankMessage({
            data,
            isPlainTextOnly,
            computedMsgType,
            shouldCreateMessage: isEmailBlankMessage,
            update,
            createMessage,
            getCommonCreateNewMessageFields,
          })
        }

        const messageConfigurationType = getMessageConfigurationType()
        const canPublish = data?.messageConfiguration?.canPublish || messageConfigurationType === 'blank_message'
        if ((isEmailABTest && messageId.startsWith('s')) || (isEmailRSS && messageId.startsWith('d')) || canPublish) {
          return
        } else {
          update({ message: { id: messageId } })
        }
      })
    }
  } catch (error) {
    logError(error)
  }
}
