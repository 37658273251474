import React, { FC, useContext, useEffect, useMemo } from 'react'

import classNames from 'classnames'

import {
  getViewRowSelectionState,
  onCustomSourceRowSelectionChangeInView,
  onRowSelectionChangeInView,
} from '@complex/AssetPickerModal/utils/AssetPickerModalUtil'
import { ItemDtoRow, ListingPageCommonContext } from '@complex/ListingPage/Context/ListingPageCommon.context'
import ScrollArea from '@components/ScrollArea/ScrollArea'
import { SvgNames, SvgType } from '@components/Svg'
import { TableV2 } from '@components/TableV2/TableV2'
import { RowAction, TableV2Props } from '@components/TableV2/tableV2TS/interfaces'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { Row } from '@tanstack/react-table'
import { useTranslation } from '@utils/const/globals'

import { AssetPickerContext } from '../../Context/AssetPicker.context'
import { filterTagsColumn } from '../Table/AssetPickerTable.utils'

import './AssetPickerViewTableContainer.css'

interface AssetPickerTableContainerProps {
  className?: string
  dataTest?: string
}

const rootClass = 'asset-picker-view-table-container'

const AssetPickerViewTableContainer: FC<AssetPickerTableContainerProps> = (props: AssetPickerTableContainerProps) => {
  const { className, dataTest = rootClass } = props

  const {
    update: updatePicker,
    values: {
      customSourceSelectedViewItems,
      loadingPreSelectedItems,
      selectedViewItems,
      defaultSelectedRows,
      hasTags,
      hasPreview,
      listingPageState: {
        listingPageProps: { hasCustomRequests },
      },
      tableProps: { listPage },
      viewTableProps: { renderSearchColumns, sortingBy },
    },
  } = useContext(AssetPickerContext)

  const {
    update: updateListing,
    values: {
      showPreview,
      folders,
      listingPageProps: {
        sidebarProps: { customSources },
      },
    },
  } = useContext(ListingPageCommonContext)

  const { t } = useTranslation()

  const viewItems = useMemo(() => [...selectedViewItems], [loadingPreSelectedItems])

  const customSourceViewItems = useMemo(() => ({ ...customSourceSelectedViewItems }), [loadingPreSelectedItems])

  const customSourcesSelectedItemsCount = useMemo(
    () => Object.values(customSourceViewItems).reduce((curr, items) => curr + items.length, 0),
    [customSourceViewItems]
  )

  const customSourceViewItemsKeysAmount = useMemo(() => Object.keys(customSourceViewItems).length, [customSourceViewItems])

  const sortSearchColumns: ColumnDefWithAdditionalProps<ItemDtoRow>[] = renderSearchColumns(false, folders[0], '', folders).map((column) => ({
    ...column,
    sortingFn: 'caseInsensitive',
  }))

  useEffect(() => {
    if (!loadingPreSelectedItems) {
      updatePicker({ defaultSelectedRows: getViewRowSelectionState(selectedViewItems.length || customSourcesSelectedItemsCount) })
    }
  }, [loadingPreSelectedItems])

  const rowActions: RowAction<ItemDtoRow>[] = hasPreview
    ? [
        {
          label: t(`AssetPicker.${listPage}.Table.Preview`),
          icon: SvgNames.zoom,
          iconSize: SvgType.ICON,
          onClick: (row: Row<ItemDtoRow>) => {
            updateListing({ showPreview: true, selectedRows: [row.original] })
          },
        },
      ]
    : []

  const tableProps: TableV2Props<ItemDtoRow> = {
    data: viewItems,
    columns: filterTagsColumn(sortSearchColumns, hasTags),
    loading: loadingPreSelectedItems,
    sortingBy,
    onLoading: undefined,
    enableCheckbox: true,
    enableInsideLoader: true,
    enableSorting: true,
    withoutBorders: true,
    defaultSelectedRows,
    rowActions,
    tagProps: { readOnlyTags: true },
  }

  const rowSelectionChanged = (rowIds: string[], rows: Row<ItemDtoRow>[]) => {
    onRowSelectionChangeInView(viewItems, updatePicker, rowIds, rows)
  }

  const customSourceGroups = Object.entries(customSourceViewItems)

  // If we are previewing, hide the table but don't unmount it so that items in the table remain the same
  const conditionalClasses = { [`${rootClass}__table--hidden`]: showPreview }

  return (
    <div className={classNames(`${rootClass}__table`, className, conditionalClasses)} data-test={dataTest}>
      {hasCustomRequests && customSourceViewItemsKeysAmount > 0 ? (
        <ScrollArea className={`${rootClass}__scroll-area`}>
          {customSourceGroups
            .filter((items) => items[1].length > 0)
            .map((items, index) => {
              const columnName = customSources?.find(({ label }) => label === items[0])?.mainColumnName as string

              const tableProps: TableV2Props<ItemDtoRow> = {
                data: items[1],
                columns: filterTagsColumn([{ ...sortSearchColumns[0], header: columnName }, ...sortSearchColumns.slice(1)], hasTags),
                loading: loadingPreSelectedItems,
                sortingBy,
                onLoading: undefined,
                enableCheckbox: true,
                enableInsideLoader: true,
                enableSorting: true,
                withoutBorders: true,
                defaultSelectedRows,
                tagProps: { readOnlyTags: true },
                rowActions,
              }

              const rowSelectionChanged = (rowIds: string[], rows: Row<ItemDtoRow>[]) =>
                onCustomSourceRowSelectionChangeInView(customSourceSelectedViewItems ?? {}, items[0], items[1], updatePicker, rowIds, rows)

              return (
                <div className={`${rootClass}__source-selected-items`} key={index}>
                  {customSourceGroups.length > 1 && (
                    <Typography
                      text={items[0]}
                      type={TextType.SECTION_HEADER}
                      weight={TextWeight.BOLD}
                      className={`${rootClass}__source-selected-items__title`}
                    />
                  )}
                  <TableV2
                    key={index}
                    {...tableProps}
                    onRowSelectionChanged={rowSelectionChanged}
                    showBottomBorder={index + 1 !== customSourceGroups.length}
                  />
                </div>
              )
            })}
        </ScrollArea>
      ) : (
        <TableV2 {...tableProps} onRowSelectionChanged={rowSelectionChanged} />
      )}
    </div>
  )
}

export default AssetPickerViewTableContainer
