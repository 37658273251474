import React from 'react'

import { TFunction } from 'i18next'

import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import InfoTooltip, { InfoTooltipIconSize } from '@components/InfoTooltip/InfoTooltip'
import PillLabel from '@components/PillLabel/PillLabel'
import { SvgNames, SvgType } from '@components/Svg'
import { RowAction } from '@components/TableV2/tableV2TS/interfaces'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import { EmailTemplateLayout } from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/graphQL/EmailLayoutsModal.graphQL'
import {
  EmailTemplateLayoutType,
  getSystemDefaultTooltipText,
  isSystemDefaultLayout,
} from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/utils/EmailLayoutsModal.utils'

const tooltipClass = 'email-layouts-modal-content__system-tooltip'

const LayoutTitle = ({
  t,
  layoutsType,
  title,
  id,
  isDefault,
  searchTerm,
}: {
  t: TFunction
  layoutsType: EmailTemplateLayoutType
  title?: string
  id?: number
  isDefault?: boolean
  searchTerm?: string
}) => {
  const text = title && searchTerm ? renderBoldTextOnMatch(title, searchTerm) : title
  const isSystem = isSystemDefaultLayout(id)

  return (
    <div className="flex-align-center">
      <TextWithTooltipOnEllip typographyProps={{ text, className: isSystem ? undefined : 'push-left' }} tooltipProps={{ children: title }} />
      {isSystem && (
        <InfoTooltip iconSize={InfoTooltipIconSize.SMALL} className={tooltipClass}>
          {getSystemDefaultTooltipText(t, layoutsType)}
        </InfoTooltip>
      )}
      {isDefault && <PillLabel text={t('Default')} noMargin />}
    </div>
  )
}

export const getEmailLayoutsTableColumns = (
  t: TFunction,
  layoutsType: EmailTemplateLayoutType,
  searchTerm?: string
): ColumnDefWithAdditionalProps<EmailTemplateLayout>[] => [
  {
    header: t('Email.Layout.Modal.Table.Title', { layoutsType }),
    accessorKey: 'title',
    textAlign: 'left',
    enableCustomCellValue: true,
    cell: (cell) => <LayoutTitle {...cell.row.original} t={t} layoutsType={layoutsType} searchTerm={searchTerm} />,
  },
]

export const getEmailLayoutsRowActions = (t: TFunction, onPreview: (item: EmailTemplateLayout) => void): RowAction<EmailTemplateLayout>[] => [
  {
    label: t('Preview'),
    icon: SvgNames.zoom,
    iconSize: SvgType.ICON,
    tooltipMessage: t('Preview'),
    onClick: (row) => onPreview(row.original),
  },
]
