import { REQUIRED_MESSAGE_KEY } from '@components/InputWithStatus/InputWithStatus'

export const MORE_INFO = 'https://connect.act-on.com/hc/en-us/articles/360023943933'
export const ERASE_MODAL_VALUE = 'ERASE'
export const MAX_CHARACTERS = 100

export const ERASE_CONTACT_VALIDATION_MESSAGES = {
  [REQUIRED_MESSAGE_KEY]: 'Provide a valid email address',
  emailInvalid: 'Provide a valid email address',
  exceededMaxCharacters: `Provide a value with maximum ${MAX_CHARACTERS} characters.`,
}
