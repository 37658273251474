import React, { FC, useCallback } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import SectionHeadline from '@components/SectionHeadline'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './BrandingSectionHeader.css'

interface Props {
  onEdit: (edit: boolean) => void
  isEditing: boolean
  titleKey: string
  descriptionKey: string
  moreInfoLink?: string
  className?: string
  dataTest?: string
}

const rootClass = 'branding-section__header'

const BrandingSectionHeader: FC<Props> = (props) => {
  const { onEdit, isEditing, titleKey, descriptionKey, moreInfoLink, className, dataTest = rootClass } = props
  const { t } = useTranslation()

  const handleEdit = useCallback(() => onEdit(true), [])

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}-content`}>
        <SectionHeadline className={`${rootClass}-title`}>{t(titleKey)}</SectionHeadline>
        <Typography
          text={t(descriptionKey)}
          tagComponents={
            moreInfoLink
              ? {
                  TextLink: <TextLink link={moreInfoLink} size={TextLinkSize.LARGE} dataTest={`${dataTest}-text-link`} />,
                }
              : undefined
          }
          dataTest={`${dataTest}-description`}
        />
      </div>
      <div className={`${rootClass}-edit-wrapper`}>
        {isEditing ? (
          <div className={`${rootClass}-status`}>
            <Svg name={SvgNames.pencil} type={SvgType.ICON} dataTest={`${dataTest}-pencil-svg`} />
            <Typography text={t('Editing')} type={TextType.BODY_TEXT_LIGHT} lineHeight={LineHeight.MEDIUM_LARGE} weight={TextWeight.MEDIUM} inline />
          </div>
        ) : (
          <Button buttonType={ButtonType.FLOAT_TEAL} onClick={handleEdit} className={`${rootClass}-edit-button`} dataTest={`${dataTest}-edit-button`}>
            {t('Edit')}
          </Button>
        )}
      </div>
    </div>
  )
}

export default BrandingSectionHeader
