import React, { ChangeEvent, FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import * as yup from 'yup'

import { useApolloClient, useQuery } from '@apollo/client'
import Button, { ButtonType } from '@components/Button'
import FormRow from '@components/FormRow/FormRow'
import Input from '@components/Input/Input'
import Loader from '@components/Loader/Loader'
import { ModalBody, ModalFooter } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import PageError from '@components/PageError/PageError'
import Select from '@components/Select/Select'
import Typography from '@components/Typography/Typography'
import { getUUID, useTranslation } from '@const/globals'
import crmProgramCampaign from '@graphql/queries/crmProgramCampaign'
import { CrmProgramCampaignsQuery, CrmProgramCampaignsQueryVariables, Program, ProgramCampaign, ProgramCrm } from '@graphql/types/query-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { logNewRelicError } from '@utils/new-relic.utils'
import { ProgramSForceCampaignStepExt, Step } from '@utils/program/program.constants'

import './editMSDynamicsStep.css'

const rootClass = 'edit-add-msdynamics-step'

const ADD_LIST = 'addList'

export interface ProgramCampaignStepExt extends ProgramSForceCampaignStepExt {
  newCampaignName?: string
}

export interface State {
  newValueNumber: number
  step: ProgramCampaignStepExt
  newProgramCampaign: ProgramCampaign | undefined
}

interface Props {
  step: Step
  dataTest?: string
  saveStepAndProgram(step: Step | null, program?: Program): void
  closeModal(): void
  crm: ProgramCrm
}

export const handleFormSubmit = (data: any, step: ProgramCampaignStepExt, props: Props, defaultTypes: string[]) => {
  const { saveStepAndProgram } = props

  let newCampaignStatus = ''
  if (defaultTypes) {
    newCampaignStatus = defaultTypes
      .map((defaultType) => {
        // For new lists, the back end accepts a format of name (string);responded(number);default(number)
        // There is no response, so it's always 0
        return `${defaultType};0;${step.status === defaultType ? 1 : 0}`
      })
      .join(';')
  }

  let updatedStep: ProgramCampaignStepExt
  if (step.campaignName === step.newCampaignName) {
    updatedStep = {
      ...step,
      newCampaignStatus,
    }
  } else {
    updatedStep = {
      ...step,
      newCampaignStatus: '',
    }
  }

  saveStepAndProgram({
    ...updatedStep,
    ...data,
  })
}

export const EditMSDynamicsStep: FC<Props> = (props: Props) => {
  const { step: baseStep, crm, dataTest = 'edit-add-msdynamics-step', closeModal } = props
  const client = useApolloClient()
  const dynamicsStep = baseStep as ProgramCampaignStepExt

  const { t } = useTranslation()

  const { loading, error, data } = useQuery<CrmProgramCampaignsQuery, CrmProgramCampaignsQueryVariables>(crmProgramCampaign, {
    client: client as any,
    fetchPolicy: 'network-only',
  })

  const crmProgramCampaigns: ProgramCampaign[] = data && data.crmProgramCampaigns && data.crmProgramCampaigns.length ? data.crmProgramCampaigns : []

  const [state, setState] = React.useState<State>(() => {
    return {
      step: dynamicsStep,
      newValueNumber: 1,
      newProgramCampaign: dynamicsStep.newCampaignName
        ? { id: getUUID(), name: dynamicsStep.newCampaignName, externalId: dynamicsStep.campaignId }
        : undefined,
    }
  })

  const { step, newValueNumber, newProgramCampaign } = state

  const getProgramCampaignOptions = () => {
    const programCampaigns = crmProgramCampaigns.reduce(
      (acc: any, cur) => {
        return [
          ...acc,
          <option key={cur.externalId} value={cur.externalId}>
            {`${cur.name}`}
          </option>,
        ]
      },
      [
        <option key="empty" value="empty">
          {`---${t('Select a Marketing List')}---`}
        </option>,
        <option disabled={newProgramCampaign && !!Object.keys(newProgramCampaign).length} key="empty-new" value={ADD_LIST}>
          {t('Create a New MS Dynamics Marketing List')}
        </option>,
        <option disabled key="empty-disabled" value="empty">
          {`---${t('Inactive Campaigns')}---`}
        </option>,
      ]
    )
    return newProgramCampaign
      ? [
          ...programCampaigns,
          <option key={newProgramCampaign.id} value={newProgramCampaign.externalId}>
            {newProgramCampaign.name}
          </option>,
        ]
      : programCampaigns
  }

  const getDefaultTypeOptions = () =>
    crm.defaultTypes ? (
      crm.defaultTypes.reduce(
        (acc: any, cur) => {
          return [
            ...acc,
            <option key={cur} value={cur}>
              {`${cur}`}
            </option>,
          ]
        },
        [
          <option key="empty" value="empty">
            {`---${t('Please make a selection')}---`}
          </option>,
        ]
      )
    ) : (
      <option key="empty" value={'empty'}>
        {t('No options found')}
      </option>
    )

  const schema = yup.object().shape({
    displayName: yup.string().required('Step Name is required.'),
    campaignId: yup.string().test('campaignId', 'A Marketing List must be selected or entered', (val) => val !== 'empty'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    if (error) {
      logNewRelicError(error)
    }
    if (errors) {
      logNewRelicError(errors)
    }
  }, [error, errors])

  if (crm?.crmModelNotCurrent) {
    return (
      <>
        <ModalBody>
          <Typography
            className={`${rootClass}__not-current`}
            dataTest={`${dataTest}__sync`}
            text={t(`Your MS Dynamics data is not yet synchronized. Please visit Data Management in Settings and set up a recurring sync.`)}
          />
        </ModalBody>
        <ModalFooter footerType={ModalFooterType.Form}>
          <Button buttonType={ButtonType.TERTIARY} onClick={closeModal} dataTest={`${dataTest}-close-button`}>
            {t('Cancel')}
          </Button>
        </ModalFooter>
      </>
    )
  }

  const onSubmit = (data: any) => {
    handleFormSubmit(data, step, props, crm.defaultTypes as string[])
  }

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const targetValue = e.target.value
    const targetDesc = e.target.selectedOptions[0].text
    if (targetValue === ADD_LIST) {
      setState({
        ...state,
        newValueNumber: newValueNumber + 1,
        step: {
          ...step,
          campaignName: `(MS Dynamics List) - ${newValueNumber}`,
          newCampaignName: `(MS Dynamics List) - ${newValueNumber}`,
          campaignId: 'new_campaign-0',
        },
        newProgramCampaign: {
          id: getUUID(),
          externalId: 'new_campaign-0',
          name: `(MS Dynamics List) - ${newValueNumber}`,
        },
      })
    } else {
      setState({
        ...state,
        step: {
          ...step,
          campaignName: targetDesc,
          campaignId: targetValue,
        },
      })
    }
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      step: {
        ...step,
        newCampaignName: e.target.value,
        campaignName: e.target.value,
      },
      newProgramCampaign: {
        ...newProgramCampaign,
        name: e.target.value,
      },
    })
  }

  const handleDefaultTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setState({
      ...state,
      step: {
        ...step,
        status: e.target.value,
      },
    })
  }

  return (
    <>
      {loading && <Loader center />}
      {error && <PageError />}
      {!loading && !error && (
        <>
          <ModalBody className={rootClass}>
            <form data-test={dataTest}>
              <FormRow className={`${rootClass}__row`}>
                <Input label={t('Step Name')} defaultValue={step.displayName} name="displayName" register={register('displayName')} />
                {errors?.displayName && <span className="error">{t(errors.displayName.message)}</span>}
              </FormRow>
              <FormRow className={`${rootClass}__row`}>
                <Select
                  label={t('Select a Marketing List to add members to:')}
                  className={`${rootClass}__select-list`}
                  dataTest={`${dataTest}__select-list`}
                  register={register('campaignId', { onChange: handleChange })}
                  name="campaignId"
                  value={step.campaignId ? step.campaignId : 'empty'}
                >
                  {getProgramCampaignOptions()}
                </Select>
                {errors?.campaignId && <span className="error">{t(errors.campaignId.message)}</span>}
              </FormRow>
              {newProgramCampaign && newProgramCampaign.name === step.campaignName && (
                <>
                  <FormRow className={`${rootClass}__row`}>
                    <Input
                      dataTest={`${dataTest}__name`}
                      label={t('Enter a Marketing List name:')}
                      value={step.campaignName}
                      onChange={handleInputChange}
                    />
                  </FormRow>
                  <FormRow className={`${rootClass}__row`}>
                    <Select
                      label={t('Add Marketing List member as:')}
                      className={`${rootClass}__add-status`}
                      dataTest={`${dataTest}__add-status`}
                      name="status"
                      value={step.status}
                      onChange={handleDefaultTypeChange}
                    >
                      {getDefaultTypeOptions()}
                    </Select>
                  </FormRow>
                </>
              )}
            </form>
          </ModalBody>
          <ModalFooter footerType={ModalFooterType.Form}>
            <Button buttonType={ButtonType.TERTIARY} onClick={closeModal} dataTest={`${dataTest}-close-button`}>
              {t('Cancel')}
            </Button>
            <Button buttonType={ButtonType.PRIMARY} dataTest={`${dataTest}-save-button`} onClick={handleSubmit(onSubmit)}>
              {t('Submit')}
            </Button>
          </ModalFooter>
        </>
      )}
    </>
  )
}

export default EditMSDynamicsStep
