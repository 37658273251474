import { gql } from '@apollo/client'

export default gql`
  mutation duplicateEmailTemplate($templateId: String!, $newName: String, $tags: [LabelDto], $folderId: Int) {
    duplicateEmailTemplate(templateId: $templateId, newName: $newName, tags: $tags, folderId: $folderId) {
      newId
      status
      message
      name
    }
  }
`
