import { IAddOnCallback } from '@src/pages/EmailComposer/utils/BeeEditor.types'

export const enum LengthTypes {
  SHORT = 'S',
  MEDIUM = 'M',
  LONG = 'L',
  NONE = 'N',
}

export const contentTypeOptions = [
  {
    id: 'Paragraph',
    value: 'Paragraph',
    label: 'Paragraph',
  },
  {
    id: 'fullEmail',
    value: 'Full email',
    label: 'Full email',
  },
  {
    id: 'Headline',
    value: 'Headline',
    label: 'Headline',
  },
]

export const marketingIntentOptions = [
  {
    id: 'Education',
    value: 'Education',
    label: 'Education',
  },
  {
    id: 'Event',
    value: 'Event',
    label: 'Event',
  },
  {
    id: 'formResponse',
    value: 'Form response',
    label: 'Form response',
  },
  {
    id: 'newCustomerWelcome',
    value: 'New customer welcome',
    label: 'New customer welcome',
  },
  {
    id: 'Newsletter',
    value: 'Newsletter',
    label: 'Newsletter',
  },
  {
    id: 'productAnnouncement',
    value: 'Product announcement',
    label: 'Product announcement',
  },
  {
    id: 'Promotional',
    value: 'Promotional',
    label: 'Promotional',
  },
  {
    id: 'serviceAnnouncement',
    value: 'Service announcement',
    label: 'Service announcement',
  },
  {
    id: 'Webinar',
    value: 'Webinar',
    label: 'Webinar',
  },
]

export const toneOptions = [
  {
    id: 'helpful',
    value: 'helpful',
    label: 'Helpful',
  },
  {
    id: 'Professional',
    value: 'Professional',
    label: 'Professional',
  },
  {
    id: 'witty',
    value: 'witty',
    label: 'Witty',
  },
  {
    id: 'simple',
    value: 'Simple',
    label: 'Simple',
  },
  {
    id: 'Casual',
    value: 'Casual',
    label: 'Casual',
  },
  {
    id: 'Respectful',
    value: 'Respectful',
    label: 'Respectful',
  },
  {
    id: 'friendly',
    value: 'friendly',
    label: 'Friendly',
  },
  {
    id: 'Exciting',
    value: 'Exciting',
    label: 'Exciting',
  },
  {
    id: 'noTone',
    value: 'No Tone',
    label: 'No Tone',
  },
]

export const MAX_SUMMARY_CHARACTERS = 600

export interface GenerativeEmailModalProps {
  isOpen: boolean
  subject: string
  onClose: () => void
  onAction: (prompt: string) => void
  className?: string
  dataTest?: string
  doneCallBack?: IAddOnCallback
}

export interface GenerativeEmailModalState {
  contentType: string
  marketingIntent: string
  tone: string
  companyName: string
  companyUrl: string
  summary: string
  includeSubject: boolean
  outputLength: LengthTypes
  output?: string
  contentGenerated: boolean
  generating: boolean
  generatedError?: {
    cause: string
    solution: string
  }
}

export const defaultState: GenerativeEmailModalState = {
  contentType: 'Full email',
  marketingIntent: '',
  tone: '',
  companyName: '',
  companyUrl: '',
  summary: '',
  includeSubject: true,
  outputLength: LengthTypes.MEDIUM,
  contentGenerated: false,
  generating: false,
}

type OutputLengthType = [LengthTypes, string]
export const OutputLengths: OutputLengthType[] = [
  [LengthTypes.SHORT, 'Short'],
  [LengthTypes.MEDIUM, 'Medium'],
  [LengthTypes.LONG, 'Long'],
]

export const OPEN_AI_API_ERRORS = [
  {
    id: 1,
    error_code: 'missing_api_key',
    cause: 'The API key is missing.',
    solution: 'Please enter a valid API key and try again.',
  },
  {
    id: 2,
    error_code: 'invalid_api_key',
    cause: 'The API key provided is invalid.',
    solution: 'Please verify that you are using a valid API key and try again.',
  },
  {
    id: 3,
    error_code: 'insufficient_permissions',
    cause: 'The API key provided does not have sufficient permissions to perform the requested action.',
    solution: 'Please verify that your API key has the necessary permissions and try again.',
  },
  {
    id: 4,
    error_code: 'model_not_found',
    cause: 'The requested model could not be found.',
    solution: 'Please verify that the model ID is correct and that the model exists in your account.',
  },
  {
    id: 5,
    error_code: 'invalid_request',
    cause: 'The request was invalid or malformed.',
    solution: 'Please verify that the request parameters are correct and try again.',
  },
  {
    id: 6,
    error_code: 'server_error',
    cause: 'An unexpected error occurred.',
    solution: 'Please check your network connection and try again.',
  },
  {
    id: 7,
    error_code: 'timeout_error',
    cause: 'Your request has timed out.',
    solution: 'Please check your network connection and try again.',
  },
  {
    id: 8,
    error_code: 'rate_limit_exceeded',
    cause: `Sorry, we've reached our limit.`,
    solution: 'Please give it a few minutes and try again.',
  },
  {
    id: 9,
    error_code: 'bad_gateway',
    cause: 'The server encountered a temporary error and could not complete your request.',
    solution: 'Please try again later.',
  },
  {
    id: 10,
    error_code: 'unauthorized_access',
    cause: 'You do not have permission to access this request. Your account may have restrictions that prevent you from accessing this feature.',
    solution: 'Please check your API key and try again.',
  },
  {
    id: 11,
    error_code: 'no_response',
    cause: 'There was no response from the OpenAI API.',
    solution: 'Please try again with a different prompt.',
  },
]

export const GetChatgptPromptMock = {
  body: `This is a mocked response for Storybook: Write me a form response full email in a professional tone about the following: Subject: NerfBlasters rule! This is the summary blah blah blah.`,
}

export const GetChatgptPromptErrorMock = [
  {
    message: 'INTERNAL_ERROR: Internal Server Error',
    locations: [],
    extensions: {
      code: 'invalid_api_key',
      statusCode: 'UNAUTHORIZED',
      errorType: 'invalid_request_error',
      classification: 'INTERNAL_ERROR',
    },
  },
]

export const GetAccountDetailsMock = {
  url: 'https://example.com',
  companyName: 'Act-On',
}
