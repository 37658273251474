import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'

import ButtonCopy from '@components/ButtonCopy/ButtonCopy'
import ButtonShowHide from '@components/ButtonShowHide/ButtonShowHide'
import acton from '@components/CodeBlockV2/theme/acton'
import Label from '@components/Label'
import Typography, { TextType } from '@components/Typography/Typography'

import './CodeBlockV2.css'

export enum CodeBlockInputType {
  SIMPLE = 'Simple',
  FORMATTED_CODE = 'Formatted code',
}
export enum CodeBlockBackgroundColor {
  WHITE = 'var(--white)',
  GRAY = 'var(--input-background)',
}

interface CodeBlockV2Props {
  content: string
  inputType?: CodeBlockInputType
  label?: ReactNode
  helperText?: ReactNode
  expandable?: boolean
  footer?: boolean
  backgroundColor?: CodeBlockBackgroundColor
  maxHeight?: number
  language?: string
  isRequired?: boolean
}

const rootClass = 'code-block-v2'
const contentMaxHeight = 96

const copyCode = (code: string) => {
  navigator.clipboard.writeText(code)
}

const CodeBlockV2: FC<CodeBlockV2Props> = (props: CodeBlockV2Props) => {
  const {
    content,
    inputType = CodeBlockInputType.SIMPLE,
    label,
    helperText,
    expandable = true,
    footer = true,
    backgroundColor = CodeBlockBackgroundColor.GRAY,
    maxHeight = expandable && contentMaxHeight,
    language,
    isRequired = true,
  } = props

  const [isShowing, setIsShowing] = useState<boolean>(false)
  const [showMore, setShowMore] = useState(true)
  const contentRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (contentRef.current) {
      setShowMore(contentRef.current.clientHeight > Number(maxHeight))
    }
  }, [contentRef, maxHeight])

  return (
    <div className={`${rootClass}`}>
      {label && (
        <Label required={isRequired} className={`${rootClass}__label`} requiredTextType={TextType.NORMAL_TEXT_GRAY_LARGE}>
          {label}
        </Label>
      )}
      <div className={`${rootClass}__content`}>
        <div
          className={`${rootClass}__content-inner`}
          style={{
            backgroundColor: backgroundColor,
            maxHeight: footer && !isShowing && showMore ? `${maxHeight}px` : 'none',
          }}
        >
          <div ref={contentRef}>
            {inputType === CodeBlockInputType.FORMATTED_CODE ? (
              <SyntaxHighlighter
                style={acton}
                showLineNumbers={true}
                showInlineLineNumbers={false}
                language={language}
                lineNumberContainerStyle={{
                  float: 'left',
                  textAlign: 'right',
                  color: 'var(--light-gray)',
                  paddingRight: '22px',
                  minWidth: '48px',
                }}
                customStyle={{
                  lineHeight: `var(--line-height-larger)`,
                  fontSize: 'var(--large-font-size)',
                  padding: 'var(--spacing-unit-x2)',
                  margin: 0,
                }}
              >
                {content}
              </SyntaxHighlighter>
            ) : (
              <Typography type={TextType.CODE_TEXT_MEDIUM} text={content} className={`typography__type-code-text-medium ${rootClass}__simple`} />
            )}
          </div>
        </div>
        {footer && (
          <div className={`${rootClass}__footer`}>
            <ButtonCopy onClick={() => copyCode(content)} />

            {expandable && showMore && (
              <ButtonShowHide
                isShowing={isShowing}
                hasIcon={false}
                onClick={() => setIsShowing(!isShowing)}
                showText="Show more"
                hideText="Show less"
              />
            )}
          </div>
        )}
      </div>
      {helperText && <div className={`${rootClass}__helper-text`}>{helperText}</div>}
    </div>
  )
}

export default CodeBlockV2
