import React, { Dispatch, FC, SetStateAction, useContext } from 'react'
import { Row } from 'react-table'

import ListPickerModal from '@complex/ListPickerModalV2/ListPickerModal'
import {
  CITRIX_ATTENDANCE_ID,
  DIRECT_SELECT_SEGMENTS_TYPE,
  EXTENSION_LIST_TYPE,
  WEBEX_ATTENDANCE_ID,
  WEBEX_ATTENDANCE_WEBINAR_ID,
  ZOOM_ATTENDANCE_ID,
} from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import { YesNo } from '@components/ConfirmationModal'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import { useTranslation } from '@const/globals'
import { List } from '@interface/foldersLists/List'
import { StepTwoState } from '@src/pages/ContactSegments/components/CopySegments/components/StepTwo/StepTwo'
import { stepBaseText } from '@src/pages/ContactSegments/components/CopySegments/constants/CopySegments.constants'
import { CopySegmentsContext } from '@src/pages/ContactSegments/components/CopySegments/context/CopySegments.context'

interface ModalHandlerProps {
  segmentToRemove?: List
  setStepTwoState: Dispatch<SetStateAction<StepTwoState>>
  showListPicker: boolean
}

const stepTwoText = `${stepBaseText}Two.`

const ModalHandler: FC<ModalHandlerProps> = ({ segmentToRemove, showListPicker, setStepTwoState }: ModalHandlerProps) => {
  const {
    values: { segments, lists },
    removeSegment,
    update,
  } = useContext(CopySegmentsContext)
  const { t } = useTranslation()

  const showTooltipByCriteria = (row: Row) => {
    const rowValue = row.original as any
    if (rowValue.id.startsWith('l-')) {
      if (rowValue?.children?.length === 0) {
        return t(`${stepTwoText}ListPicker.Info.List.NoSegments`)
      }
      return t(`${stepTwoText}ListPicker.Info.List`)
    } else if (rowValue.type.startsWith(DIRECT_SELECT_SEGMENTS_TYPE)) {
      return t(`${stepTwoText}ListPicker.Info.ExplicitlySelectedGroup`)
    } else if (rowValue.type.startsWith(EXTENSION_LIST_TYPE)) {
      return t(`${stepTwoText}ListPicker.Info.Extension`)
    } else if (
      rowValue.id.startsWith(WEBEX_ATTENDANCE_ID) ||
      rowValue.id.startsWith(CITRIX_ATTENDANCE_ID) ||
      rowValue.id.startsWith(WEBEX_ATTENDANCE_WEBINAR_ID) ||
      rowValue.id.startsWith(ZOOM_ATTENDANCE_ID)
    ) {
      return t(`${stepTwoText}ListPicker.Info.Attendance`)
    }
  }

  const checkIfEveryChildIsDisabled = (rowValue: any) =>
    rowValue?.children.every(
      (child: any) =>
        disableRowByCriteria(child) &&
        (child.children?.length !== 0 ? child?.children.every((subChild: any) => disableRowByCriteria(subChild)) : true)
    )

  const disableRowByCriteria = (value: any) =>
    value?.description === DIRECT_SELECT_SEGMENTS_TYPE ||
    value.description === EXTENSION_LIST_TYPE ||
    value.id.startsWith(WEBEX_ATTENDANCE_ID) ||
    value.id.startsWith(CITRIX_ATTENDANCE_ID) ||
    value.id.startsWith(WEBEX_ATTENDANCE_WEBINAR_ID) ||
    value.id.startsWith(ZOOM_ATTENDANCE_ID) ||
    (value.id.startsWith('l-') && (value?.children?.length === 0 || checkIfEveryChildIsDisabled(value)))

  return (
    <>
      {!!segmentToRemove && (
        <DeleteConfirmationModal
          isOpen
          title={t(`${stepTwoText}DeleteConfirmationModal.Title`)}
          deleteButtonText={t(`${stepTwoText}DeleteConfirmationModal.DeleteButtonText`)}
          body={t(`${stepTwoText}DeleteConfirmationModal.Body`)}
          onAnswer={(answer: YesNo) => {
            if (answer === YesNo.YES) {
              removeSegment(segmentToRemove)
            }
            setStepTwoState((state) => ({ ...state, segmentToRemove: undefined }))
          }}
        />
      )}
      {showListPicker && (
        <ListPickerModal
          isOpen
          submitLists={(items) => {
            update({ segments: items.filter(({ id }) => !id.startsWith('l-')), lists: items.filter(({ id }) => id.startsWith('l-')) })
            setStepTwoState((state) => ({ ...state, showListPicker: false }))
          }}
          defaultSelectedLists={[...segments, ...lists].map((item) => item.id)}
          closeModal={() => setStepTwoState((state) => ({ ...state, showListPicker: false }))}
          hideAllUCLListTypes
          hideLegacyListToggle
          disableRowByCriteria={disableRowByCriteria}
          showTooltipByCriteria={showTooltipByCriteria}
          headerTitle={t(`${stepTwoText}ListPicker.Header`)}
          selectAllSegmentsOfSelectedList
        />
      )}
    </>
  )
}

export default ModalHandler
