import React, { FC, useCallback, useState } from 'react'
import { useHistory } from 'react-router'

import classNames from 'classnames'

import {
  DuplicateListingPageItem,
  ListPageAPI,
  ListPageCommonState,
  PageHeaderProps,
  SetError,
  SetStatusToast,
  SidebarProps,
  Update,
} from '@complex/ListingPage/Context/ListingPageCommon.context'
import ListingPageContainer from '@complex/ListingPage/ListingPageContainer'
import { CatalogLink } from '@complex/ListingPage/Utils/ListingPage.constants'
import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import { Status } from '@components/StatusToast/StatusToast'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ItemDto } from '@graphql/types/query-types'
import { useDraftsListingPageRequests } from '@src/pages/listingPages/Drafts/GraphQL/useDraftsListingPageRequests.graphQL'
import { getCustomEmptyListingProps } from '@src/pages/listingPages/Drafts/utils/DraftsListingPage.filters'
import { renderCustomModal } from '@src/pages/listingPages/Drafts/utils/DraftsListingPage.modals'
import { tableProps } from '@src/pages/listingPages/Drafts/utils/DraftsListingPage.tables'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import { DUPLICATED_AS_DRAFT_ID, SAVED_AS_DRAFT_ID } from '@utils/composer/EmailModal.constants'
import { DraftBuilderMessage, isDraftBuilderMessage, openDraftsComposerWindow } from '@utils/drafts'
import { allDraftsFilter } from '@utils/filter'
import { UpdateState } from '@utils/types'

import './DraftsListingPageContainer.css'

interface DraftsListingPageContainerProps {
  className?: string
  dataTest?: string
}

interface DraftsListingPageContainerState {
  windowMessages: DraftBuilderMessage[]
}

const rootClass = 'drafts-listing-page-container'

const DraftsListingPageContainer: FC<DraftsListingPageContainerProps> = (props: DraftsListingPageContainerProps) => {
  const { dataTest = rootClass } = props

  const [containerValues, setContainerValues] = useState<DraftsListingPageContainerState>({
    windowMessages: [],
  })

  const { windowMessages } = containerValues

  const updateContainer: UpdateState<DraftsListingPageContainerState> = (fields) => {
    setContainerValues((containerValues) => ({ ...containerValues, ...fields }))
  }

  const { t } = useTranslation()
  const { userAllowedToCreateContent } = useAccountSettings()

  const history = useHistory<{ [SAVED_AS_DRAFT_ID]?: string; [DUPLICATED_AS_DRAFT_ID]?: string }>()

  const { deleteDraftsRequest, getDraftPreviewRequest, getItemRequest, duplicateDraftRequest } = useDraftsListingPageRequests()

  const getItemPreview = async (listPageValues: ListPageCommonState, update: Update, setError: SetError) => {
    const { selectedRows } = listPageValues

    const { data, errors } = await getDraftPreviewRequest({ id: selectedRows[0].externalId || '' })

    if (data) {
      update({ previewHtml: data.getEmailDraftPreview })
    } else if (errors) {
      setError(t('ListPage.Drafts.Preview.Error'), errors)
    }
  }

  const duplicateLandingPage = async (params: DuplicateListingPageItem, listPageAPI: ListPageAPI) => {
    const { listingPageItem, newName, tags, folderId, includeRecipients, includeSuppressionRules } = params
    const { update, setStatusToast, setError } = listPageAPI

    const { data, errors } = await duplicateDraftRequest({
      emailDraftDuplicateInput: { draftId: listingPageItem.externalId ?? '-1', newName, tags, folderId, includeRecipients, includeSuppressionRules },
    })

    if (data) {
      setStatusToast(t('ListPage.Drafts.Duplicate.SuccessToast'), Status.SUCCESS)
      update({ loading: true, fetchItems: true })
    } else {
      setError(t('ListPage.Drafts.Duplicate.FailToast'), errors)
    }
  }

  const deleteDrafts = async (items: ItemDto[]) => deleteDraftsRequest({ draftIds: items.map((item) => item.externalId ?? '').filter((id) => !!id) })

  const onPageLoad = useCallback(
    async (setStatusToast: SetStatusToast, _?: Update) => {
      if (history.location.state) {
        const savedDraftId = history.location.state[SAVED_AS_DRAFT_ID]
        const duplicatedDraftId = history.location.state[DUPLICATED_AS_DRAFT_ID]
        const state = savedDraftId ? SAVED_AS_DRAFT_ID : DUPLICATED_AS_DRAFT_ID
        const message = savedDraftId ? 'Draft saved' : 'Success! Your email has been duplicated'

        if (savedDraftId || duplicatedDraftId) {
          setStatusToast(<Typography text={t(message)} />, Status.SUCCESS)

          const currentState = { ...history.location.state }

          delete currentState[state]

          history.replace({
            ...history.location,
            state: currentState,
          })
        }
      }
    },
    [history, t]
  )

  const onWindowMessage = useCallback(
    async (event: MessageEvent<DraftBuilderMessage>) => {
      if (isDraftBuilderMessage(event.data)) {
        const eventExists = windowMessages.filter((message) => message.emailId === event.data.emailId).length
        !eventExists && updateContainer({ windowMessages: [...windowMessages, { ...event.data }] })
      }
    },
    [windowMessages]
  )

  const onWindowActive = useCallback(
    async (_event: Event, update: Update, setStatusToast: SetStatusToast) => {
      if (!windowMessages.length) {
        return
      }

      const draftData: ItemDto[] & { item: string }[] = []
      for (const message of windowMessages) {
        const data = await getItemRequest({ externalId: message.emailId, type: ItemType.EMAIL_DRAFT })
        data.data?.getItem && draftData.push(data.data?.getItem)
      }

      if (draftData.length && windowMessages.length) {
        const draftTitle = JSON.parse(draftData[0].item).name
        const isEditing = windowMessages[0].isEditing
        const message = (
          <Typography
            text={isEditing ? 'ListPage.Drafts.DraftEdited' : 'ListPage.Drafts.DraftCreated'}
            tagProps={{ bold: { weight: TextWeight.MEDIUM } }}
            values={{ draftTitle }}
            inline
          />
        )

        setStatusToast(message, Status.SUCCESS)
        update({ fetchFolders: true, fetchFilterCounts: true, fetchItems: true })
      }

      updateContainer({ windowMessages: [] })
    },
    [windowMessages]
  )

  const sidebarProps: SidebarProps = {
    sidebarHeader: 'Drafts Manager',
    hasRecent: false,
    hasCreatedByMe: true,
    allItemFilter: allDraftsFilter,
    customFilterSelected: false,
  }

  const renderCreateEmailButton = () => (
    <Button
      buttonType={ButtonType.PRIMARY}
      iconPosition={ButtonIconPosition.LEFT}
      onClick={() => openDraftsComposerWindow()}
      className={classNames(`${rootClass}__create-draft-button`)}
      dataTest={`${rootClass}-create-draft-button`}
    >
      <Svg type={SvgType.ICON} name={SvgNames.plus} />
      {t('Create email')}
    </Button>
  )

  const pageHeaderProps: PageHeaderProps = {
    pageTitle: 'Drafts',
    renderPageHeaderContent: renderCreateEmailButton,
  }

  const goToCatalog = () => window.open(CatalogLink.EMAIL_DRAFT, '_blank')

  return (
    <ListingPageContainer
      dataTest={dataTest}
      className={classNames(rootClass)}
      itemType={ItemType.EMAIL_DRAFT}
      listingPageProps={{
        sidebarProps,
        pageHeaderProps,
        canPreview: true,
        canDuplicate: true,
        canShareToCatalog: false,
        canShareToChildAccounts: false,
        tableProps: tableProps(t, userAllowedToCreateContent),
        onWindowActive: onWindowActive,
        onWindowMessage: onWindowMessage,
        onPageLoad,
        customDeleteItemsCall: deleteDrafts,
        customPreviewItemCall: getItemPreview,
        customDuplicateItem: duplicateLandingPage,
        getCustomEmptyListingProps: (setFilter, update, filter) => getCustomEmptyListingProps(setFilter, update, goToCatalog, filter),
        renderCustomModal,
        sortBy: [{ id: 'lastUpdated', desc: true }],
      }}
    />
  )
}

export default DraftsListingPageContainer
