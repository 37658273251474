import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { InputDefinition } from '@graphql/types/microservice/webhooks-incoming-management-types'
import { dateFormatsOptions } from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/components/IncomingWebhookContentMapping/components/CustomTouchPointBehaviorsContentMapping/components/CustomTouchPointBehaviorsBody/CustomTouchPointBehaviorsBody.constants'
import CustomTouchPointBehaviorsMappingField, {
  BehaviorField,
} from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/components/IncomingWebhookContentMapping/components/CustomTouchPointBehaviorsContentMapping/components/CustomTouchPointBehaviorsMappingField/CustomTouchPointBehaviorsMappingField'
import { renderInfoTooltip } from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/utils/CreateIncomingWebhookModal.utils'

import './CustomTouchPointBehaviorsBody.css'

const rootClass = 'custom-touch-point-behaviors-body'
const rootTranslation = 'Incoming.Webhooks.CreateModal.ContentMapping.CustomTouchPointBehaviors.Body'
const metaData = 'contentMappings.1.mappingMetadata'
const CustomTouchPointBehaviorsBody = () => {
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<InputDefinition>()
  const { t } = useTranslation()
  const error = (errors?.contentMappings ?? [])[1]

  const dateFormatSelectProps = useMemo(
    () => ({
      defaultValue: dateFormatsOptions.find(({ value }) => value === getValues(metaData)),
      error: !!error,
      insideModal: true,
      isClearable: false,
      isSearchable: false,
      options: dateFormatsOptions,
      placeholder: t(`${rootTranslation}.DateFormatSelect.Placeholder`),
    }),
    [error]
  )

  const fields: BehaviorField[] = useMemo(() => {
    const requiredPlaceholder = t('Map to this field (required)')
    const optionalPlaceholder = t('Map to this field (optional)')
    return [
      { label: t('Action'), placeholder: requiredPlaceholder, value: 'title' },
      { label: t('Date'), placeholder: requiredPlaceholder, value: 'date' },
      { label: t('Email'), placeholder: requiredPlaceholder, value: 'email' },
      { label: t('Notes'), placeholder: optionalPlaceholder, value: 'note' },
    ]
  }, [])

  return (
    <div className={rootClass} data-test={rootClass}>
      <div className={`${rootClass}__select`}>
        <div className={`${rootClass}__label`}>
          <span>
            <Typography
              text={t(`${rootTranslation}.DateFormatSelect.Label`)}
              tagProps={{ bold: { weight: TextWeight.MEDIUM, type: TextType.BODY_TEXT } }}
              type={TextType.BODY_TEXT_LIGHT}
              inline
            />
            {renderInfoTooltip(t(`${rootTranslation}.DateFormatSelect.Tooltip`))}
          </span>
        </div>
        <Controller
          control={control}
          name={metaData}
          render={({ field: { onBlur } }) => (
            <SelectV2
              {...dateFormatSelectProps}
              onChange={(option?: SelectV2SingleOption) => {
                setValue(metaData, option?.value ?? '', { shouldDirty: true })
              }}
              onBlur={onBlur}
            />
          )}
        />
      </div>
      {fields.map((field, index) => (
        <CustomTouchPointBehaviorsMappingField key={index} field={field} index={index} />
      ))}
    </div>
  )
}

export default CustomTouchPointBehaviorsBody
