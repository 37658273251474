import React, { FC, useMemo, useState } from 'react'

import classNames from 'classnames'

import { useFrontendFiltering, FilterFunctionMap } from '@complex/ListingPage/Hooks/useFrontendFiltering'
import Modal, { ModalBody } from '@components/Modal'
import ModalFooterV2 from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import ModalHeaderV2 from '@components/Modal/components/ModalHeaderV2/ModalHeaderV2'
import { PersistedRowStateValues } from '@components/TableV2/hooks/usePersistedRowState'
import { TableV2 } from '@components/TableV2/TableV2'
import { TableV2Props } from '@components/TableV2/tableV2TS/interfaces'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ListContactGq } from '@graphql/types/query-types'
import TestListRecipientsEmptyListing from '@src/pages/EmailComposer/EmailModals/components/SendTestEmailModal/components/TestListModal/TestListRecipientsEmptyListing'
import {
  getCommonTestListTableProps,
  getTestListContactsTableColumns,
} from '@src/pages/EmailComposer/EmailModals/components/SendTestEmailModal/components/TestListModal/TestListTable.utils'
import { Row, RowSelectionState } from '@tanstack/react-table'
import { partialMatch } from '@utils/searchUtils'

import { TestListModalContainerProps } from './TestListModalContainer'
import { TestSegment } from '../../graphQL/SendTestEmailModal.graphQL'

import './TestListRecipientsModal.css'

interface TestListRecipientsModalProps extends Omit<TestListModalContainerProps, 'onFinish'> {
  tableProps: PersistedRowStateValues<ListContactGq>['tableProps']
  activeSegment: TestSegment
  selectedIds: string[]
  onBack: VoidFunction
  onFinish: (isCancel?: boolean) => void
}

const rootClass = 'test-list-recipients-modal'

const TestListRecipientsModal: FC<TestListRecipientsModalProps> = ({
  onFinish,
  loading,
  dataTest = rootClass,
  segments,
  activeSegment,
  onBack,
  tableProps,
  selectedIds,
}) => {
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')
  const contacts = activeSegment?.contacts ?? []
  const defaultSelectedRows =
    tableProps.defaultSelectedRows ??
    contacts.reduce<RowSelectionState>((acc, contact) => (!!contact.email ? { ...acc, [contact.id]: true } : acc), {})
  const selectedCount = tableProps.defaultSelectedRows ? selectedIds.length : Object.keys(defaultSelectedRows).length

  const { data, filterLoading } = useFrontendFiltering({
    data: contacts,
    searchText: searchTerm,
    filterFunction: useMemo<FilterFunctionMap<ListContactGq>>(
      () => ({
        search: (item, search) => partialMatch(item, ['name', 'email'], search),
      }),
      []
    ),
    loading,
  })

  const renderTable = () => {
    const contactTable: TableV2Props<ListContactGq> = {
      data,
      columns: getTestListContactsTableColumns(t, searchTerm),
      sortingBy: [{ id: 'name', desc: false }],
      enableCheckbox: true,
      ...tableProps,
      defaultSelectedRows,
      rowDisabled: (row: Row<ListContactGq>) => !row.original.email,
      rowUniqueIdKey: 'id',
    }
    return <TableV2 {...getCommonTestListTableProps<ListContactGq>()} {...contactTable} />
  }

  return (
    <Modal
      className={rootClass}
      dataTest={dataTest}
      isOpen
      noPadding
      header={
        <ModalHeaderV2
          headerType={'list'}
          headerText={t(`${activeSegment.name} contacts`)}
          searchProps={{ placeholder: t('Search contacts'), onChangeHandler: setSearchTerm, incomingValue: searchTerm }}
          iconProps={{ hasBackButton: true, onClick: onBack }}
          className={`${rootClass}__header`}
          dataTest={`${rootClass}-header`}
        />
      }
    >
      <ModalBody className={`${rootClass}__body`}>
        {loading || filterLoading || data.length ? (
          <>
            {!!searchTerm && (
              <Typography
                className={classNames('inset-x4 inset', `${rootClass}__search-results`)}
                text={'AssetPicker.Search.Results'}
                values={{ count: data.length, searchTerm }}
                type={TextType.SECTION_HEADER}
                weight={TextWeight.BOLD}
                dataTest={`${rootClass}-results`}
              />
            )}
            {renderTable()}
          </>
        ) : (
          <TestListRecipientsEmptyListing noContacts={!segments[0].contacts.length} dataTest={`${dataTest}-empty-listing`} />
        )}
      </ModalBody>
      <ModalFooterV2
        footerType={'list'}
        textContent={{ text: t('Selected.Recipients.Count', { count: selectedCount }) }}
        buttons={{
          actionButtonLabel: t('Add recipients'),
          actionButtonOnClick: () => onFinish(),
          actionButtonDisabled: selectedCount === 0,
          cancelButtonLabel: t('Cancel'),
        }}
        onClose={() => onFinish(true)}
        showTopBorder
        className={`${rootClass}__footer`}
        dataTest={`${dataTest}-footer`}
      />
    </Modal>
  )
}

export default TestListRecipientsModal
