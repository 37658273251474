import { DateRangeValueType } from '@components/DateRangePicker/DateRangePicker'

export const BEFORE_PRINT_SIZE = 700

export const getDataLakeRequestQueryDates: (range: DateRangeValueType) => undefined | { startDate: string; endDate: string } = (range) => {
  if (!range || !range[0] || !range[1]) {
    return
  }
  const [start, end] = range
  return { startDate: start.format('YYYY-MM-DD'), endDate: end.format('YYYY-MM-DD') }
}

export const getPreviousSameLengthFormattedRange: (range: DateRangeValueType) => string = (range) => {
  if (!range || !range[0] || !range[1]) {
    return ''
  }
  const [start, end] = range
  const diff = end.diff(start, 'day')
  const endPrev = start.subtract(1, 'day')
  const startPrev = endPrev.subtract(diff, 'day')
  if (startPrev.year() === endPrev.year()) {
    return `${startPrev.format('MMM DD')} - ${endPrev.format('MMM DD, YYYY')}`
  }
  return `${startPrev.format('MMM DD, YYYY')} - ${endPrev.format('MMM DD, YYYY')}`
}

export const getRevenueRangeToMilliseconds: (range: DateRangeValueType) => { startTime: number; endTime: number } = (range) => {
  if (!range || !range[0] || !range[1]) {
    return {
      startTime: 0,
      endTime: 0,
    }
  }
  const [start, end] = range
  return {
    startTime: start.valueOf(),
    endTime: end.valueOf(),
  }
}

export const getTrendInPercentage: (trend?: number) => string | undefined = (trend) => {
  if (typeof trend !== 'number') {
    return undefined
  }
  const roundNumber = Math.round(trend * 10) / 10
  return `${roundNumber > 999 ? '>999' : roundNumber < -999 ? '<-999' : roundNumber}%`
}
