import React from 'react'

import { RenderCustomFiltersParams } from '@complex/ListingPage/Context/ListingPageCommon.context'
import Checkbox from '@components/Checkbox'
import CheckboxGroup from '@components/CheckboxGroup/CheckboxGroup'
import { SvgNames } from '@components/Svg'

const rootClass = 'webinars-picker-modal'

export const renderCustomFilters = ({ activeSubTypes, filterCounts, renderCustomFilterWithCount, menuActions, t }: RenderCustomFiltersParams) => [
  {
    header: 'More Filters',
    isOpen: true,
    icon: SvgNames.toggles,
    dataTest: 'menu-items',
    content: (
      <>
        <CheckboxGroup className={`listing-page__custom-filters`} verticalLayout>
          {renderCustomFilterWithCount(
            <Checkbox
              label={t(`Upcoming`)}
              checked={activeSubTypes.includes(`upcoming`)}
              onChange={() => menuActions.clickSubType(`upcoming`)}
              dataTest={`${rootClass}-upcoming-filter`}
            />,
            filterCounts['upcoming'],
            'upcoming'
          )}
          {renderCustomFilterWithCount(
            <Checkbox
              label={t(`Past`)}
              checked={activeSubTypes.includes(`past`)}
              onChange={() => menuActions.clickSubType(`past`)}
              dataTest={`${rootClass}-past-filter`}
            />,
            filterCounts['past'],
            'past'
          )}
        </CheckboxGroup>
      </>
    ),
  },
]
