import React, { FC, useMemo, useState } from 'react'

import classNames from 'classnames'
import { useDebouncedCallback } from 'use-debounce'

import { IPluginRow } from '@beefree.io/sdk/dist/types/bee'
import Button, { ButtonType } from '@components/Button'
import Caution from '@components/Caution/Caution'
import InputV2 from '@components/InputV2/InputV2'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import SelectV2 from '@components/SelectV2/SelectV2'
import Typography, { ModalHeaderStyle, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { EmailComposerRowCategoryDto } from '@graphql/types/microservice/email-management-types'
import { RowArgs } from '@src/pages/EmailComposer/BeeEditor/BeeEditorContainer'
import { ISaveRow } from '@src/pages/EmailComposer/utils/BeeEditor.types'

import './SavedRows.css'

interface SavedRowsProps {
  className?: string
  dataTest?: string
  onCancel: () => void
  onSave: (data: ISaveRow) => void
  resetSavedRowDuplicateError: () => void
  isEdit: boolean
  rowArgs?: RowArgs
  savedRowDuplicateError?: boolean
  savedRowCategories: EmailComposerRowCategoryDto[]
  savedRows: IPluginRow[]
}

const DEBOUNCE_TIME = 200
const rootClass = 'saved-rows'

const SavedRows: FC<SavedRowsProps> = (props: SavedRowsProps) => {
  const { t } = useTranslation()
  const {
    dataTest = rootClass,
    className = '',
    onCancel,
    onSave,
    resetSavedRowDuplicateError,
    isEdit,
    rowArgs,
    savedRowDuplicateError,
    savedRowCategories,
    savedRows,
  } = props
  const originalRowIndex = useMemo(() => {
    const oName = rowArgs?.row?.name
    return savedRows.findIndex((row) => row?.metadata?.name === oName)
  }, [rowArgs?.row?.name, savedRows])

  //  Hide categories for Open Beta
  const showCategories = false

  const [rowData, setRowData] = useState<ISaveRow>({ name: '', category: '', id: '' })
  const [showError, setShowError] = useState(false)
  const [tempName, setTempName] = useState(rowArgs?.row?.name)

  const onNameChange = useDebouncedCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: rawValue } = e.target
    const value = rawValue.trim()

    resetSavedRowDuplicateError()

    const isOriginalName = value === rowArgs?.row?.name
    if (isOriginalName) {
      setShowError(false)
      setRowData({ ...rowData, name: value })
      setTempName(value)
      return
    }

    const newRowIndex = savedRows.findIndex((row) => (row?.metadata?.name as string).toLocaleLowerCase() === value.toLocaleLowerCase())
    const nameExists = newRowIndex > -1 ? newRowIndex !== originalRowIndex : false
    setShowError(nameExists)
    if (nameExists) {
      setTempName(value)
    } else {
      setRowData({ ...rowData, name: value })
    }
  }, DEBOUNCE_TIME)

  const isDisabled = useMemo(
    () => rowData.name === '' || rowData.name === rowArgs?.row?.name || showError,
    [rowData.name, rowArgs?.row?.name, showError]
  )

  const headerText = isEdit ? 'SavedRows.Edit.Header' : 'SavedRows.Header'

  const header = (
    <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__header`}>
      <Typography text={t(headerText)} {...ModalHeaderStyle} />
    </ModalHeader>
  )

  const categories = savedRowCategories.map((category) => ({
    label: category.name ?? '',
    value: category.id.toString(),
    extraOptions: { id: category.id.toString() },
  }))

  const cautionMsg = (
    <Typography
      text={t('SavedRows.Input.Caution', { name: savedRowDuplicateError ? rowData.name : tempName })}
      tagProps={{ medium: { weight: TextWeight.MEDIUM, inline: true } }}
    />
  )

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen header={header}>
      <ModalBody>
        {(showError || savedRowDuplicateError) && <Caution message={cautionMsg} />}
        <InputV2
          className={`${rootClass}__row-name-input`}
          required
          /* eslint-disable-next-line jsx-a11y/no-autofocus */
          autoFocus={!showCategories}
          labelProps={{ label: t('SavedRows.Input.Label'), required: true, className: `${rootClass}__inputV2-label` }}
          placeholder={t('SavedRows.Input.Placeholder')}
          onChange={onNameChange}
          value={rowArgs ? rowArgs?.row?.name : undefined}
          error={showError || savedRowDuplicateError}
          inputInfo={{ errorText: t('SavedRows.Input.Error'), enabled: showError || !!savedRowDuplicateError }}
        />
        {showCategories && !isEdit && (
          <SelectV2
            isSearchable={false}
            insideModal
            label={t('SavedRows.Category.Input')}
            tooltipMessage={t('SavedRows.Category.Tooltip')}
            placeholder={'SavedRows.Category.Placeholder'}
            isClearable={false}
            onChange={(option) => setRowData({ ...rowData, category: option?.label ?? '', id: option?.extraOptions?.id ?? '' })}
            options={categories}
            isRequired
          />
        )}
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form}>
        <Button buttonType={ButtonType.TERTIARY} onClick={onCancel} disabled={false} dataTest={`${rootClass}-close-button`}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} dataTest={`${rootClass}-save-button`} onClick={() => onSave(rowData)} disabled={isDisabled}>
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default SavedRows
