import { gql } from '@apollo/client'

export default gql`
  mutation deleteLandingPages($landingPageIds: [String!]!) {
    deleteLandingPages(landingPageIds: $landingPageIds) {
      status
      successIds {
        id
        name
      }
      failedIds {
        id
        name
        reason
      }
    }
  }
`
