import React, { ComponentProps, FC, RefObject, useContext, useEffect, useMemo, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Row } from 'react-table'

import classNames from 'classnames'

import ActionableNestedTableWithEmptyListing from '@components/ActionableNestedTableWithEmptyListing/ActionableNestedTableWithEmptyListing'
import Button, { ButtonType } from '@components/Button'
import Container from '@components/Container'
import ContentExpander from '@components/ContentExpander/ContentExpander'
import DragLayer from '@components/DragLayer/DragLayer'
import { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { Program, ProgramStep } from '@graphql/types/query-types'
import { getUpdatedStepsWithInvalidValue } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/components/ListProgramStep/utils/ListProgramStep.utils'
import {
  EXPAND_ALL_STEPS_EVENT,
  EXPAND_STEP_EVENT,
  getProgramStepsListingColumns,
  getProgramStepsListingRowActions,
} from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/utils/ProgramStepsListing.constants'
import { getInitialExpandedSteps } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/utils/ProgramStepsListing.utils'
import { ListMaintenanceProgramsContext } from '@src/pages/listingPages/ListMaintenancePrograms/context/ListMaintenancePrograms.context'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'

import './ProgramStepsListing.css'

interface ProgramStepsListingProps {
  className?: string
  dataTest?: string
  loading?: boolean
  onAddStep: (position: number, step?: ProgramStep) => void
  onDeleteStep: (step: Row<ProgramStep>) => void
  onEditStep: (step: Row<ProgramStep>) => void
  steps: ProgramStep[]
  stepsRefs: RefObject<Map<number, HTMLDivElement>>
}

const rootClass = 'program-steps-listing'

const ProgramStepsListing: FC<ProgramStepsListingProps> = (props: ProgramStepsListingProps) => {
  const { dataTest = rootClass, className = '', loading = false, steps, onDeleteStep: onDeleteStepProp, onAddStep, onEditStep, stepsRefs } = props

  const {
    update,
    values: { isEditing, programDetails, stepBeingEditedIndex, program, stepsWithInvalidValue },
  } = useContext(ListMaintenanceProgramsContext)

  const { steps: temporalSteps = [] } = { ...programDetails }
  const actualSteps = isEditing ? temporalSteps : steps

  const [expandedSteps, setExpandedSteps] = useState<Map<string, boolean>>(getInitialExpandedSteps(program))

  const { t } = useTranslation()

  const { userAllowedToCreatePrograms } = useAccountSettings()

  const onDuplicateStep = ({ original, index }: Row<ProgramStep>) => {
    onAddStep(index, original)
  }

  const onStepSort = ([movingStep]: Row[], droppedOnStep: Row, above: boolean) => {
    const newPositionIndex = (movingStep.index > droppedOnStep.index ? droppedOnStep.index : droppedOnStep.index - 1) + (above ? 0 : 1)
    const stepsWithoutMovingStep = [...temporalSteps.slice(0, movingStep.index), ...temporalSteps.slice(movingStep.index + 1)]
    const steps = [...stepsWithoutMovingStep.slice(0, newPositionIndex), movingStep.original, ...stepsWithoutMovingStep.slice(newPositionIndex)]
    update({
      programDetails: { ...(programDetails as Program), steps: steps as ProgramStep[] },
      ...(!isEditing ? { isEditing: true } : {}),
    })

    if (stepBeingEditedIndex !== undefined && (stepBeingEditedIndex - movingStep.index) * (stepBeingEditedIndex - newPositionIndex) <= 0) {
      if (movingStep.index === stepBeingEditedIndex) {
        update({ stepBeingEditedIndex: newPositionIndex })
      } else {
        update({ stepBeingEditedIndex: movingStep.index > newPositionIndex ? stepBeingEditedIndex + 1 : stepBeingEditedIndex - 1 })
      }
    }
  }

  const onDeleteStep = (step: Row<ProgramStep>) => {
    const stepId = step.original.stepId
    if (stepId !== undefined) {
      const expandedStepsCopy = new Map(expandedSteps)
      expandedStepsCopy.delete(stepId)
      setExpandedSteps(expandedStepsCopy)
    }
    onDeleteStepProp(step)
    update({ stepsWithInvalidValue: getUpdatedStepsWithInvalidValue(stepsWithInvalidValue, step.index) })
  }

  const onStepExpand = (event: Event) => {
    const {
      detail: { stepId, expanded },
    } = event as CustomEvent
    const expandedStepsCopy = new Map(expandedSteps)
    expandedStepsCopy.set(stepId, expanded)
    setExpandedSteps(expandedStepsCopy)
  }

  const onExpandAllSteps = (expand: boolean) => {
    const expandedStepsCopy = new Map(actualSteps.map(({ stepId = '' }) => [stepId, expand]))
    setExpandedSteps(expandedStepsCopy)

    const event = new CustomEvent(EXPAND_ALL_STEPS_EVENT, { detail: { expand } })
    document.dispatchEvent(event)
  }

  useEffect(() => {
    if (!isEditing) {
      if (stepBeingEditedIndex !== undefined) {
        update({ stepBeingEditedIndex: undefined })
      }
      if (temporalSteps === steps) {
        setExpandedSteps(getInitialExpandedSteps(program))
      }
    }
  }, [isEditing])

  useEffect(() => {
    document.addEventListener(EXPAND_STEP_EVENT, onStepExpand)
    return () => document.removeEventListener(EXPAND_STEP_EVENT, onStepExpand)
  }, [expandedSteps])

  const columns = useMemo(() => getProgramStepsListingColumns(stepsRefs), [stepsRefs.current])

  const rowActions = useMemo(
    () => getProgramStepsListingRowActions(onDeleteStep, onDuplicateStep, onEditStep, userAllowedToCreatePrograms, stepBeingEditedIndex),
    [programDetails, stepBeingEditedIndex]
  )

  const tableProps = useMemo<Partial<ComponentProps<typeof ActionableNestedTableWithEmptyListing>>>(
    () =>
      userAllowedToCreatePrograms
        ? {
            text: t('List maintenance programs let you automated tasks to manage contact data. Start adding steps to customize your program.'),
            buttonText: t('Add a step'),
            buttonOnClick: () => onAddStep(temporalSteps.length),
          }
        : { text: t('List maintenance programs let you automated tasks to manage contact data.') },
    [programDetails, userAllowedToCreatePrograms]
  )

  return (
    <Container className={classNames(rootClass, className, { [`${rootClass}__not-empty`]: steps.length || loading })} data-test={dataTest}>
      <DndProvider backend={HTML5Backend}>
        <DragLayer />
        {actualSteps.length > 0 && (
          <div className={`${rootClass}__table-info`}>
            <Typography text={t('Steps')} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.MEDIUM} />
            <ContentExpander onChange={onExpandAllSteps} />
          </div>
        )}
        <ActionableNestedTableWithEmptyListing
          {...tableProps}
          className={`${rootClass}__table`}
          data={actualSteps}
          columns={columns}
          rowActions={rowActions}
          imgSrc={StaticImageNames.emptyContacts}
          headline={t('This program doesn’t have any steps!')}
          size={EmptyListingSize.MEDIUM}
          loading={loading}
          hasExpander={false}
          hasDragDrop={userAllowedToCreatePrograms && steps.length > 1}
          canDrop
          onRowsSort={onStepSort}
          itemsType={ItemType.PROGRAM_STEP}
        />
        {actualSteps.length > 0 && userAllowedToCreatePrograms && (
          <div className={`${rootClass}__add-step`}>
            <Button
              dataTest={`${dataTest}-add-step`}
              className={`${rootClass}__add-step-button`}
              buttonType={ButtonType.INFO}
              onClick={() => onAddStep(temporalSteps.length)}
            >
              <Svg type={SvgType.LARGER_ICON} name={SvgNames.plus} />
              <Typography text={t('Add a step')} type={TextType.NORMAL_TEXT_TEAL_LARGE} weight={TextWeight.MEDIUM} />
            </Button>
          </div>
        )}
      </DndProvider>
    </Container>
  )
}

export default ProgramStepsListing
