import equal from 'fast-deep-equal/es6/react'

import { ProgramTrack, ProgramStep } from '@graphql/types/query-types'
import { getLetter } from '@src/pages/programs/dashboard/ProgramSteps.utils'
import { getStepTemplate } from '@utils/program/program'
import {
  ActiveTrack,
  BuildTreeState,
  Direction,
  Position,
  ProgramBranchStepExt,
  ProgramFlowTree,
  ProgramGoToStepExt,
  ProgramStepType,
  Step,
  Track,
  TrackStep,
} from '@utils/program/program.constants'

import { getNewTrackNumber } from './programFlowUtil'

export function isOneBranchStepExitOrGoTo(step1: Step, step2: Step) {
  return (
    ((step1.stepType === 'goto' || step1.stepType === 'exit') && step2.stepType !== 'goto' && step2.stepType !== 'exit') ||
    ((step2.stepType === 'goto' || step2.stepType === 'exit') && step1.stepType !== 'goto' && step1.stepType !== 'exit')
  )
}

export function isStepExitOrGoTo(step: Step) {
  return step.stepType === 'exit' || step.stepType === 'goto'
}

export const addStepPosition = (trackStep: TrackStep, position: Position, state: BuildTreeState) => {
  const step = state.tracks[trackStep.trackIndex].steps[trackStep.stepIndex]
  step.position = { x: position.x, y: position.y }
  state.gridPositionToId[`${position.x}.${position.y}`] = trackStep
  return position.x
}

export const processGoToStep = (goToStep: TrackStep, curStep: TrackStep, curTrack: ActiveTrack, nextRow: number, state: BuildTreeState) => {
  addStepPosition(goToStep, { x: curTrack.position, y: nextRow }, state)
  addStepPosition(curStep, { x: curTrack.position, y: nextRow + 1 }, state)
}

export function getActiveTrackFromGoTo(trackId: string, position: number, state: BuildTreeState): ActiveTrack | null {
  for (let i = 0; i < state.tracks.length; i++) {
    if (state.tracks[i].id === trackId) {
      return {
        id: trackId,
        trackStep: {
          trackIndex: i,
          stepIndex: 0,
        },
        position,
      }
    }
  }
  return null
}

export function processActiveTrack(currentRow: number, activeTrack: ActiveTrack, activeTracks: ActiveTrack[], state: BuildTreeState): ActiveTrack[] {
  const nextActiveTracks = [...activeTracks]

  const nextRow = currentRow + 1
  const currentTrack = state.tracks[activeTrack.trackStep.trackIndex]
  const currentStep = currentTrack.steps[activeTrack.trackStep.stepIndex]
  // reset any values that may have been set from a previous state
  currentStep.offset = undefined
  currentStep.children = undefined
  currentStep.child = undefined
  currentStep.position = undefined
  let stepIndex = activeTrack.trackStep.stepIndex + 1
  if (currentTrack.id === 't0') {
    stepIndex--
  }

  currentStep.letter = `${currentTrack.letter}-${stepIndex}`
  if (currentRow === 0) {
    currentStep.first = true
  }
  if (!currentStep.stepId) {
    return nextActiveTracks
  }

  if (activeTrack.skip) {
    activeTrack.skip = false
    return nextActiveTracks
  }
  if (currentStep.stepType === 'branch' && currentStep.goToStepId) {
    const newTrack = getActiveTrackFromGoTo(currentStep.goToStepId, activeTrack.position, state) //getActiveTrackFromTrack(state.tracks[currentStep.goToStepId])
    if (!newTrack) {
      nextActiveTracks.splice(activeTrack.trackStep.trackIndex, 1)
      return nextActiveTracks
    }
    const rightStep: Step = state.tracks[newTrack.trackStep.trackIndex].steps[newTrack.trackStep.stepIndex]
    const leftStep: Step = currentTrack.steps[activeTrack.trackStep.stepIndex + 1]
    rightStep.letter = `${state.tracks[newTrack.trackStep.trackIndex].letter}-1`

    currentStep.children = {
      left: {
        ...activeTrack.trackStep,
        stepIndex: activeTrack.trackStep.stepIndex + 1,
      },
      right: {
        ...newTrack.trackStep,
      },
    }

    if (isOneBranchStepExitOrGoTo(leftStep, rightStep)) {
      if (isStepExitOrGoTo(rightStep)) {
        processGoToStep(currentStep.children.right, currentStep.children.left, activeTrack, nextRow, state)
        rightStep.offset = Direction.right

        //activeTrack.currentStep = activeTrack.currentStep + 1
        activeTrack.trackStep = {
          ...activeTrack.trackStep,
          stepIndex: activeTrack.trackStep.stepIndex + 1,
        }
        activeTrack.skip = true
      } else {
        leftStep.offset = Direction.left
        processGoToStep(currentStep.children.left, currentStep.children.right, activeTrack, nextRow, state)

        const curTrackIndex = nextActiveTracks.findIndex((activeTrack) => activeTrack.id === currentTrack.id)
        nextActiveTracks.splice(curTrackIndex, 1, {
          ...newTrack,
          trackStep: {
            ...newTrack.trackStep,
            stepIndex: 0,
          },
          position: activeTrack.position,
          skip: true,
        })
      }
    } else {
      const leftPosition = activeTrack.position - (leftStep.branchSize?.right ?? 0) - 1
      const rightPosition = activeTrack.position + (rightStep.branchSize?.left ?? 0) + 1
      addStepPosition(currentStep.children.right, { x: rightPosition, y: nextRow }, state)
      addStepPosition(currentStep.children.left, { x: leftPosition, y: nextRow }, state)

      activeTrack.position = leftPosition
      activeTrack.trackStep = {
        ...activeTrack.trackStep,
        stepIndex: activeTrack.trackStep.stepIndex + 1,
      }

      const newActiveTrack = {
        ...newTrack,
        trackStep: {
          ...newTrack.trackStep,
          stepIndex: 0,
        },
        position: rightPosition,
      }

      nextActiveTracks.push(newActiveTrack)
    }
  } else {
    if (currentStep.stepType !== 'goto' && currentTrack.steps.length > activeTrack.trackStep.stepIndex + 1) {
      // set position for next step to be directly below the current step because no branches
      const nextStepTrackStep = {
        ...activeTrack.trackStep,
        stepIndex: activeTrack.trackStep.stepIndex + 1,
      }
      currentStep.child = {
        ...nextStepTrackStep,
      }
      activeTrack.trackStep = {
        ...nextStepTrackStep,
      }
      activeTrack.position = addStepPosition(nextStepTrackStep, { x: activeTrack.position, y: nextRow }, state)
    } else {
      // No more steps in track, remove from active tracks
      const curTrackIndex = nextActiveTracks.findIndex((activeTrack) => activeTrack.id === currentTrack.id)
      nextActiveTracks.splice(curTrackIndex, 1)
    }
  }
  return nextActiveTracks
}

const processNextRow = (currentRow: number, state: BuildTreeState) => {
  const nextRow = currentRow + 1
  let nextActiveTracks: ActiveTrack[] = [...state.activeTracks]
  state.activeTracks.forEach((activeTrack) => {
    nextActiveTracks = processActiveTrack(currentRow, activeTrack, nextActiveTracks, state)
  })

  state.activeTracks = nextActiveTracks
  if (state.activeTracks.length > 0) {
    processNextRow(nextRow, state)
  }
}

const processTracks = (firstTrackIndex: number, state: BuildTreeState) => {
  const trackStep = {
    trackIndex: firstTrackIndex,
    stepIndex: 0,
  }
  addStepPosition(trackStep, { x: 0, y: 0 }, state)

  state.activeTracks.push({
    id: 't0',
    trackStep,
    position: 0,
  })

  processNextRow(0, state)
}

export function determineBranchSize(trackStep: TrackStep, state: BuildTreeState): number {
  const curTrack = state.tracks[trackStep.trackIndex]
  const step = state.tracks[trackStep.trackIndex].steps[trackStep.stepIndex]
  if (step.stepType === 'branch' && step.goToStepId) {
    const newTrack = getActiveTrackFromGoTo(step.goToStepId, 0, state)
    if (!newTrack) {
      return 1
    }
    const rightStep: Step = state.tracks[newTrack.trackStep.trackIndex].steps[newTrack.trackStep.stepIndex]
    const leftStep: Step = curTrack.steps[trackStep.stepIndex + 1]
    if (isOneBranchStepExitOrGoTo(leftStep, rightStep)) {
      if (isStepExitOrGoTo(rightStep)) {
        const branchSize = determineBranchSize(
          {
            ...trackStep,
            stepIndex: trackStep.stepIndex + 1,
          },
          state
        )
        step.branchSize = state.tracks[trackStep.trackIndex].steps[trackStep.stepIndex + 1].branchSize
        return branchSize
      } else {
        const branchSize = determineBranchSize(
          {
            ...newTrack.trackStep,
          },
          state
        )
        step.branchSize = state.tracks[newTrack.trackStep.trackIndex].steps[newTrack.trackStep.stepIndex].branchSize
        return branchSize
      }
    }
    const leftSize = determineBranchSize(
      {
        ...trackStep,
        stepIndex: trackStep.stepIndex + 1,
      },
      state
    )
    const rightSize = determineBranchSize(
      {
        ...newTrack.trackStep,
      },
      state
    )
    if (step.stepId === 's4') {
      //debugger
    }
    step.branchSize = {
      left: leftSize,
      right: rightSize,
    }
    return leftSize + rightSize + 1
  }
  if (trackStep.stepIndex + 1 < state.tracks[trackStep.trackIndex].steps.length) {
    const branchSize = determineBranchSize(
      {
        ...trackStep,
        stepIndex: trackStep.stepIndex + 1,
      },
      state
    )
    step.branchSize = state.tracks[trackStep.trackIndex].steps[trackStep.stepIndex + 1].branchSize
    return branchSize
  }
  return 1
}

const findTracksReachableFromTrack = (track: ProgramTrack, tracks: ProgramTrack[], ids: string[]) => {
  const branchStepIds = track.steps
    .filter((step) => step.stepType === ProgramStepType.BRANCH)
    .map((step) => (step as ProgramBranchStepExt)?.goToStepId ?? '')
    .filter((id) => id !== '')

  const updatedIds = new Set<string>(ids)
  if (branchStepIds) {
    branchStepIds.forEach((branch) => {
      updatedIds.add(branch)
      const currTrack = tracks.find((tr) => tr.id === branch)
      if (currTrack) {
        const updIds = findTracksReachableFromTrack(currTrack, tracks, Array.from(updatedIds))
        if (updIds) {
          updIds.forEach((id) => updatedIds.add(id))
        }
      }
    })
  }
  return updatedIds
}

export const getReachableTracks = (tracks: Array<ProgramTrack>) => {
  const reachableTracks = new Set<ProgramTrack>([tracks[0]])
  const reachTracks = findTracksReachableFromTrack(tracks[0], tracks, [])
  reachTracks.forEach((trackId) => {
    const currTrack = tracks.find((track) => track.id === trackId)
    if (currTrack) {
      reachableTracks.add(currTrack)
    }
  })

  return Array.from(reachableTracks)
}

export const enforceTerminalGoToStep = (tracks: ProgramTrack[]) => {
  /*
    This addresses a bug where a GoTo step might not be the last step in a track, which should never happen
    Root cause is still unknown
  */
  return tracks.map((track) => {
    return {
      ...track,
      steps: track.steps.filter((step, index) => {
        const isGoTo = step.stepType === ProgramStepType.GOTO
        const isTerminalGoTo = isGoTo && index === track.steps.length - 1
        return !isGoTo || isTerminalGoTo
      }),
    }
  })
}

export const enforceUniqueBranchTracks = (tracks: ProgramTrack[], stepTemplates?: ProgramStep[]) => {
  /* 
    This addresses a scenario where multiple branch steps were allowed to lead to the same track ID
    Root cause is still unknown
  */
  if (!stepTemplates) {
    return tracks
  }

  // Map which branch steps lead to which tracks
  const branchTracks: { [key: string]: string[] } = {}
  tracks.forEach((track) => {
    track.steps.forEach((step) => {
      if (step.stepType === ProgramStepType.BRANCH) {
        const goToStepId = (step as ProgramBranchStepExt).goToStepId ?? ''
        if (!(goToStepId in branchTracks)) {
          branchTracks[goToStepId] = []
        }
        branchTracks[goToStepId].push(step.stepId ?? '')
      }
    })
  })

  // Generate new track when a track has more than one branch leading to it
  let newTracks = [...tracks]
  let newStepId = 0
  Object.keys(branchTracks).forEach((trackId) => {
    if (branchTracks[trackId].length <= 1) {
      return
    }

    branchTracks[trackId].forEach((branchStepId, index) => {
      if (index === 0) {
        // First branch should still lead to original track
        return
      }
      const trackNumber = getNewTrackNumber(newTracks as Track[])
      const newTrackId = `t${trackNumber}`
      const trackLetter = getLetter(trackNumber)

      const firstStepInTrack = tracks.find((track) => track.id === trackId)?.steps[0]
      let newTrack: Track

      if (firstStepInTrack?.stepType === ProgramStepType.EXIT) {
        // Create a new track with an exit step
        const exitStepTemplate = getStepTemplate(stepTemplates, ProgramStepType.EXIT) as ProgramGoToStepExt
        newTrack = {
          id: newTrackId,
          letter: trackLetter,
          steps: [
            {
              ...exitStepTemplate,
              letter: `${trackLetter}-1`,
              stepId: `exit_${newTrackId}`,
              displayName: 'Exit the Program',
            },
          ],
        }
      } else {
        // Create new track with a GoTo step which leads to the first step of the original track
        const gotoStepTemplate = getStepTemplate(stepTemplates, ProgramStepType.GOTO) as ProgramGoToStepExt
        newTrack = {
          id: newTrackId,
          letter: trackLetter,
          steps: [
            {
              ...gotoStepTemplate,
              letter: `${trackLetter}-1`,
              stepId: `newgotostep${newStepId}`,
              displayName: 'Go to Another Step',
              goToStepId: firstStepInTrack?.stepId,
            },
          ],
        }
        newStepId += 1
      }

      // Assign branch step to point to the new track
      newTracks = [
        ...newTracks.map((track) => ({
          ...track,
          steps: track.steps.map((currentStep) => {
            if (currentStep.stepId === branchStepId) {
              return { ...currentStep, goToStepId: newTrackId }
            }
            return currentStep
          }),
        })),
        newTrack,
      ]
    })
  })
  return newTracks
}

export const buildTrees = (
  tracks: Array<ProgramTrack>,
  t: Function,
  stepTemplates?: ProgramStep[],
  updateTracks?: (tracks: Array<ProgramTrack>) => void
) => {
  let letterIndex = 65
  let indentIndex: number | undefined
  const getLetter = (): string => {
    const letter = `${indentIndex ? String.fromCharCode(indentIndex) : ''}${String.fromCharCode(letterIndex)}`
    letterIndex++
    if (letterIndex > 90) {
      letterIndex = 65
      indentIndex = indentIndex ? indentIndex + 1 : 65
    }
    return letter
  }

  const uniqueTracks = enforceUniqueBranchTracks(tracks, stepTemplates)
  const goToFixedTracks = enforceTerminalGoToStep(uniqueTracks)
  const reachableTracks = getReachableTracks(goToFixedTracks)
  if (updateTracks && !equal(tracks, reachableTracks)) {
    updateTracks([...reachableTracks])
  }

  const state: BuildTreeState = {
    gridPositionToId: {},
    tracks: reachableTracks.map(
      (track) =>
        ({
          ...track,
          letter: getLetter(),
          steps: track.steps.map((step) => ({
            ...step,
            branchSize: undefined,
            position: undefined,
            child: undefined,
            children: undefined,
          })),
        } as Track)
    ), // All tracks with id mapping to track
    activeTracks: [], // tracks that are currently being processed in the current row
  }

  const firstTrackIndex = state.tracks.findIndex((track) => track.id === 't0')

  if (state.tracks[firstTrackIndex].steps[0].stepType !== ProgramStepType.START) {
    // Add start step
    state.tracks[firstTrackIndex].steps = [
      {
        stepId: 'start',
        stepType: ProgramStepType.START,
        displayName: t('Start'),
      },
      ...state.tracks[firstTrackIndex].steps,
    ]
  }

  determineBranchSize({ trackIndex: firstTrackIndex, stepIndex: 0 }, state)

  processTracks(firstTrackIndex, state)

  // get grid size
  let left = 0,
    right = 0,
    bottom = 0
  Object.keys(state.gridPositionToId).forEach((gridPosition) => {
    const [xString, yString] = gridPosition.split('.')
    const x = parseInt(xString)
    const y = parseInt(yString)
    if (left > x) {
      left = x
    }
    if (right < x) {
      right = x
    }
    if (bottom < y) {
      bottom = y
    }
  })

  const tree: ProgramFlowTree = {
    nodePositions: state.gridPositionToId,
    tracks: state.tracks,
    gridSize: {
      top: 0,
      left,
      right,
      bottom,
    },
  }

  return tree
}

export default {
  buildTrees,
  processGoToStep,
  addStepPosition,
}
