import React, { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import * as yup from 'yup'

import { useApolloClient } from '@apollo/client'
import Button, { ButtonType } from '@components/Button'
import { Checkbox } from '@components/Checkbox/Checkbox'
import FormGroup from '@components/FormGroup/FormGroup'
import FormRow from '@components/FormRow/FormRow'
import Input from '@components/Input/Input'
import Loader from '@components/Loader'
import { ModalBody, ModalFooter } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import PageError from '@components/PageError'
import Radio from '@components/Radio/Radio'
import Select from '@components/Select/Select'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Typography from '@components/Typography/Typography'
import globals, { useTranslation } from '@const/globals'
import crmCampaignsQuery from '@graphql/queries/crmCampaigns'
import crmCampaignStatusQuery from '@graphql/queries/crmCampaignStatus'
import {
  CrmCampaign,
  CrmCampaignsQuery,
  CrmCampaignsQueryVariables,
  CrmCampaignStatusQuery,
  CrmCampaignStatusQueryVariables,
  Program,
} from '@graphql/types/query-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { logError } from '@utils/env'
import { logNewRelicError } from '@utils/new-relic.utils'
import { getUnionHeaders, hasAOContactsSource } from '@utils/program/program'
import { ProgramSForceCampaignStepExt, Step } from '@utils/program/program.constants'

import './editAddCrmSalesforceStep.css'

const rootClass = 'edit-add-crm-step'

export interface State {
  step: ProgramSForceCampaignStepExt
  newCampaign?: boolean
  dynamicCampaign?: boolean
  campaignStatuses?: string[]
  loadingCampaigns?: boolean
  campaignsError?: boolean
  loadingStatus?: boolean
  statusError?: boolean
  dynamicOptions?: JSX.Element[]
  statuses?: Status[]
  campaigns?: CrmCampaign[]
}

interface Props {
  step: Step
  program: Program
  closeModal(): void
  saveStepAndProgram(step: Step | null, program?: Program): void
  dataTest?: string
}

export const NEW_CAMPAIGN = 'newcampaign'
export const DYNAMIC_CAMPAIGN = 'dynamic'

export const handleFormSubmit = (data: any, step: ProgramSForceCampaignStepExt, props: Props, statuses?: Status[]) => {
  const { saveStepAndProgram } = props

  let newCampaignStatus = ''
  if (statuses) {
    newCampaignStatus = statuses
      .map((status) => {
        return `${status.name};${status.isResponded ? 1 : 0};${status.isDefault ? 1 : 0}`
      })
      .join(';')
  }

  saveStepAndProgram({
    ...step,
    ...data,
    newCampaignStatus,
    campaignId: step.campaignId,
  })
}

const schema = yup.object().shape({
  displayName: yup.string().required('Step Name is required.'),
  update: yup.boolean(),
})

export function getCampaignOptions(campaigns: CrmCampaign[], program: Program, t: Function) {
  const options = [
    <option key="" value="">
      --- {t('Select a')} {program.crm?.campaignDisplayName ?? 'Campaign'} ---
    </option>,
    <option key={NEW_CAMPAIGN} value={NEW_CAMPAIGN}>
      * {t('Create New')} {program.crm?.crmName ?? ''} {program.crm?.campaignDisplayName ?? 'Campaign'}
    </option>,
  ]
  if (program.crm?.isDynamicCampaign) {
    options.push(
      <option key={DYNAMIC_CAMPAIGN} value={DYNAMIC_CAMPAIGN}>
        * {t('Dynamic Campaign Selection')}
      </option>
    )
  }

  let foundActive = false
  let foundInactive = false
  for (const campaign of campaigns) {
    if (!foundActive && campaign.active) {
      options.push(
        <option key="active" value="" disabled>
          --- {t('Active Campaigns')} ---
        </option>
      )
      foundActive = true
    }
    if (!foundInactive && !campaign.active) {
      options.push(
        <option key="inactive" value="" disabled>
          --- {t('Inactive Campaigns')} ---
        </option>
      )
      foundInactive = true
    }
    options.push(
      <option key={campaign.externalId} value={campaign.externalId}>
        {campaign.name}
      </option>
    )
  }
  return options
}

export function getOptions(options: string[]) {
  return options.map((option, i) => (
    <option key={i} value={option}>
      {option}
    </option>
  ))
}

export function getDynamicCampaignOptions(program: Program, t: Function) {
  const options: JSX.Element[] = []
  if (program.sourceList.length > 0) {
    const unionHeaders = getUnionHeaders(program.sourceList)
    if (unionHeaders.length > 0) {
      options.push(
        <option key="" value="">
          --- {t('Select a field to use for the campaign ID')} ---
        </option>
      )
      for (const unionHeader of unionHeaders) {
        options.push(
          <option key={unionHeader} value={unionHeader}>
            {unionHeader}
          </option>
        )
      }
    } else {
      options.push(
        <option key="" value="">
          --- {t('There are no shared columns between all your source lists')} ---
        </option>
      )
    }
  } else {
    options.push(
      <option key="" value="">
        --- {t('No lists have been added to the program')} ---
      </option>
    )
  }
  return options
}

interface Status {
  name: string
  isDefault: boolean
  isResponded: boolean
  isEditable: boolean
  id: string
}

export function getStatusesFromString(status: string, program: Program) {
  const parts = status.split(';')
  const statuses: Status[] = []
  for (let i = 0; i < parts.length; ) {
    statuses.push({
      name: parts[i],
      isResponded: parts[i + 1] === '1',
      isDefault: parts[i + 2] === '1',
      isEditable: !program.crm?.defaultTypes?.includes(parts[i]),
      id: globals.getUUID(),
    })
    i = i + 3
  }
  return statuses
}

export const EditAddCrmSalesforceStep: FC<Props> = (props: Props) => {
  const { step: baseStep, program, closeModal, dataTest = 'edit-add-crm-step' } = props
  const client = useApolloClient()
  const { t } = useTranslation()

  const crmStep = baseStep as ProgramSForceCampaignStepExt
  const [state, setState] = useState<State>({
    step: crmStep,
    dynamicOptions: program.crm?.isDynamicCampaign ? getDynamicCampaignOptions(program, t) : undefined,
    newCampaign: !!crmStep.newCampaignStatus && crmStep.newCampaignStatus.length > 0,
    statuses: crmStep.newCampaignStatus ? getStatusesFromString(crmStep.newCampaignStatus, program) : undefined,
    loadingCampaigns: true,
  })
  const { step, campaignsError, statusError, loadingStatus, loadingCampaigns, campaigns, statuses } = state
  const error = campaignsError || statusError

  useEffect(() => {
    if (!program.crm?.crmModelNotCurrent) {
      client
        .query<CrmCampaignsQuery, CrmCampaignsQueryVariables>({
          query: crmCampaignsQuery,
        })
        .then(({ data }) => {
          setState({
            ...state,
            loadingCampaigns: false,
            campaigns: data.crmCampaigns,
            loadingStatus: true,
            statusError: undefined,
            campaignStatuses: undefined,
          })
        })
        .catch((error) => {
          logError(error)
          setState({
            ...state,
            loadingCampaigns: false,
            campaignsError: true,
          })
          logNewRelicError(error)
        })
    }
  }, [])

  useEffect(() => {
    if (state.loadingStatus && state.step.campaignId !== '' && program.crm?.crmType) {
      client
        .query<CrmCampaignStatusQuery, CrmCampaignStatusQueryVariables>({
          query: crmCampaignStatusQuery,
          variables: {
            type: program.crm.crmType,
            listId: state.newCampaign ? undefined : state.step.campaignId,
          },
        })
        .then(({ data }) => {
          setState({
            ...state,
            loadingStatus: false,
            campaignStatuses: data.crmCampaignStatus,
          })
        })
        .catch((error) => {
          logError(error)
          setState({
            ...state,
            loadingStatus: false,
            statusError: true,
          })
          logNewRelicError(error)
        })
    }
  }, [state.loadingStatus])

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    if (errors) {
      logNewRelicError(errors)
    }
  }, [errors])

  if (!program || !program.crm?.crmType) return null

  const submitId = globals.getUUID()

  const onSubmit = (data: any) => {
    handleFormSubmit(data, step, props, statuses)
  }

  const aOContactsSource = hasAOContactsSource(program)

  const getBody = () => {
    if (program.crm?.crmModelNotCurrent) {
      return (
        <Typography
          className={`${rootClass}__not-current`}
          text={t(`Your Salesforce data is not yet synchronized. Please visit Data Management in Settings and set up a recurring sync.`)}
        />
      )
    }

    if (loadingCampaigns) {
      return <Loader center />
    }

    if (campaignsError) {
      return <PageError />
    }

    if (!campaigns) return null

    return (
      <form data-test={dataTest} onSubmit={handleSubmit(onSubmit)}>
        <FormRow>
          <Input label={t('Step Name')} defaultValue={step.displayName} name="displayName" register={register('displayName')} />
          {errors?.displayName && <span className="error">{t(errors.displayName.message)}</span>}
        </FormRow>
        <FormRow>
          <Select
            label={t('Select a Campaign to add members to')}
            register={register('campaignId', {
              onChange: (event) => {
                if (event.target.value === NEW_CAMPAIGN) {
                  const newCampaignId = `new_campaign-${campaigns.length + 1}`
                  const newCampaignName = `(New ${program.crm?.campaignDisplayName}) - ${campaigns.length + 1}`
                  const statuses = program.crm?.defaultTypes?.map((defaultType, i) => ({
                    name: defaultType ?? '',
                    isDefault: i === 0,
                    isResponded: false,
                    isEditable: false,
                    id: globals.getUUID(),
                  }))

                  setState({
                    ...state,
                    step: {
                      ...step,
                      campaignId: newCampaignId,
                      campaignName: newCampaignName,
                    },
                    campaigns: [
                      ...campaigns,
                      {
                        name: newCampaignName,
                        rawName: newCampaignId,
                        id: newCampaignId,
                        externalId: newCampaignId,
                        active: true,
                      },
                    ],
                    loadingStatus: true,
                    newCampaign: true,
                    dynamicCampaign: false,
                    statuses,
                  })
                } else {
                  const campaignName =
                    event.target.value !== DYNAMIC_CAMPAIGN
                      ? campaigns.find((campaign) => campaign.id === event.target.value)?.name
                      : step.campaignName
                  setState({
                    ...state,
                    newCampaign: false,
                    loadingStatus: event.target.value !== DYNAMIC_CAMPAIGN,
                    statusError: undefined,
                    statuses: undefined,
                    step: {
                      ...step,
                      campaignId: event.target.value,
                      campaignName: campaignName,
                    },
                  })
                }
              },
            })}
            name="campaignId"
            defaultValue={step.campaignId}
            dataTest={`${dataTest}-campaign-select`}
          >
            {getCampaignOptions(campaigns, program, t)}
          </Select>
        </FormRow>
        {state.newCampaign && statuses && (
          <>
            <FormRow>
              <Input label={t('Campaign Name')} register={register('campaignName')} name="campaignName" defaultValue={step.campaignName} />
            </FormRow>
            <FormGroup>
              <div className={`${rootClass}__new-status`}>
                <div className={`${rootClass}__new-status-header`}>
                  <label>{t('Status Name')}</label>
                  <label>{t('Responded')}*</label>
                  <label>{t('Default')}*</label>
                </div>
                {statuses.map((status, i) => (
                  <div key={status.id} className={`${rootClass}__new-status-row`}>
                    <div className={`${rootClass}__new-status-col`}>
                      <Input
                        className={`${rootClass}__new-status-input`}
                        defaultValue={status.name}
                        disabled={!status.isEditable}
                        dataTest={`${dataTest}-status-input-${i}`}
                        onChange={(event) => {
                          setState({
                            ...state,
                            statuses: [
                              ...statuses.slice(0, i),
                              {
                                ...status,
                                name: event.target.value,
                              },
                              ...statuses.slice(i + 1),
                            ],
                          })
                        }}
                      />
                    </div>
                    <div className={`${rootClass}__new-status-col`}>
                      <Checkbox
                        checked={status.isResponded}
                        dataTest={`${dataTest}-status-checkbox-${i}`}
                        onChange={(checked) => {
                          setState({
                            ...state,
                            statuses: [
                              ...statuses.slice(0, i),
                              {
                                ...status,
                                isResponded: checked,
                              },
                              ...statuses.slice(i + 1),
                            ],
                          })
                        }}
                      />
                    </div>
                    <div className={`${rootClass}__new-status-col`}>
                      <Radio
                        dataTest={`${dataTest}-status-radio-${i}`}
                        value={status.id}
                        checked={status.isDefault}
                        onChange={() => {
                          setState({
                            ...state,
                            statuses: statuses.map((curStatus) => {
                              if (curStatus.id === status.id) {
                                return {
                                  ...curStatus,
                                  isDefault: true,
                                }
                              } else {
                                return {
                                  ...curStatus,
                                  isDefault: false,
                                }
                              }
                            }),
                          })
                        }}
                      />
                    </div>
                    {status.isEditable && (
                      <Button
                        data-test={`${dataTest}-delete-button-${i}`}
                        buttonType={ButtonType.REMOVE}
                        onClick={() => {
                          setState({
                            ...state,
                            statuses: [...statuses.slice(0, i), ...statuses.slice(i + 1)],
                          })
                        }}
                      >
                        <Svg name={SvgNames.delete} />
                      </Button>
                    )}
                  </div>
                ))}
                <Button
                  dataTest={`${dataTest}-add-button`}
                  buttonType={ButtonType.FLOAT}
                  onClick={() => {
                    setState({
                      ...state,
                      statuses: [
                        ...statuses,
                        {
                          name: '',
                          isDefault: false,
                          isResponded: false,
                          isEditable: true,
                          id: globals.getUUID(),
                        },
                      ],
                    })
                  }}
                >
                  <Svg type={SvgType.LARGER_ICON} name={SvgNames.plus} />
                  {t('Add')}
                </Button>
              </div>
            </FormGroup>
          </>
        )}

        {!aOContactsSource && step.campaignId === DYNAMIC_CAMPAIGN && (
          <FormRow className={`${rootClass}__status-row`}>
            <Select
              name="dynamicCampaign"
              register={register('dynamicCampaign')}
              label={t('Select a field that contains the campaign ids for the contacts/leads')}
              defaultValue={step.dynamicCampaign}
            >
              {state.dynamicOptions}
            </Select>
          </FormRow>
        )}
        {step.campaignId !== DYNAMIC_CAMPAIGN && (
          <FormRow className={`${rootClass}__status-row`}>
            {loadingStatus && <Loader center />}
            {statusError && <PageError center />}
            {state.campaignStatuses && (
              <Select name="status" register={register('status')} label={t('Set Campaign member status to')} defaultValue={step.status}>
                <option value="">--- {t('Use Default status')} ---</option>
                {getOptions(state.campaignStatuses)}
              </Select>
            )}
          </FormRow>
        )}

        <button type="submit" id={submitId} hidden />
      </form>
    )
  }

  return (
    <>
      <ModalBody className={rootClass}>{getBody()}</ModalBody>
      <ModalFooter footerType={ModalFooterType.Form} flexEnd>
        <Button buttonType={ButtonType.TERTIARY} onClick={closeModal} dataTest={`${dataTest}-close-button`}>
          {t('Close')}
        </Button>
        <Button
          render={!error && campaigns !== undefined}
          buttonType={ButtonType.PRIMARY}
          dataTest={`${dataTest}-save-button`}
          onClick={() => {
            document.getElementById(submitId)?.click()
          }}
        >
          {t('Submit')}
        </Button>
      </ModalFooter>
    </>
  )
}

export default EditAddCrmSalesforceStep
