import { createContext } from 'react'

export enum ComposerContextType {
  EMAIL = 'email',
  LANDING_PAGE = 'landingPage',
}

export type ComposerProviderContext = {
  type: ComposerContextType
}

export const defaultComposerContextState: ComposerProviderContext = {
  type: ComposerContextType.EMAIL,
}

export const ComposerContext = createContext<ComposerProviderContext>(defaultComposerContextState)
