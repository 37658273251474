import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import StaticImage from '@components/StaticImage/StaticImage'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Typography, { TextType } from '@components/Typography/Typography'

import './PeoplesChart.css'

type Props = {
  className?: string
  dataTest?: string
  pctReceiving: string
  label?: ReactNode
}

const rootClass = 'peoples-chart'

const PeoplesChart: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '', pctReceiving, label } = props
  return (
    <div className={classNames(`${rootClass}__contacts-reach`, className)} data-test={dataTest}>
      <div className={`${rootClass}__reach-pct`}>
        <span className={classNames(`${rootClass}__people`, `${rootClass}__people-main`)} style={{ width: `${pctReceiving}%` }} />
        <StaticImage name={StaticImageNames.peopleChart} className={classNames(`${rootClass}__people`, `${rootClass}__people-bg`)} />
      </div>
      {label && <Typography text={label} type={TextType.BODY_TEXT_TINY} className={`${rootClass}__label`} />}
    </div>
  )
}

export default PeoplesChart
