import React, { FC, useContext, useEffect, useState } from 'react'

import classNames from 'classnames'

import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2Option, SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { useTranslation } from '@const/globals'
import AssetPickerSelector from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/AssetPickerSelector/AssetPickerSelector'
import {
  BehaviorEmailOption,
  BehaviorEngagementOption,
  BehaviorExpressionConditionType,
  BehaviorProgramOption,
  BehaviorWebsiteOption,
} from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/BehaviorExpression/BehaviorExpression.constants'
import {
  AssetsCriteria,
  EngagementCriteria,
} from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/BehaviorExpression/BehaviorExpression.interfaces'
import {
  BehaviorCategory,
  getBehaviorCategory,
  getDefaultCategoryByBehavior,
  renderCategoryOptions,
} from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/BehaviorExpression/BehaviorExpression.utils'
import DateTimeframes from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/DateTimeframes/DateTimeframes'
import DeliverabilityStatus from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/DeliverabilityStatus/DeliverabilityStatus'
import EngagementActivitySelect from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/EngagementActivitySelect/EngagementActivitySelect'
import MultiTextInput from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/MultiTextInput/MultiTextInput'
import NumericCriteria, {
  NumericCriteriaType,
} from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/NumericCriteria/NumericCriteria'
import Quantity from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/Quantity/Quantity'
import { SegmentComposerContext } from '@src/pages/SegmentComposer/SegmentComposer.context'
import { AssetTypes } from '@utils/actonAssetTypes'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'
import { usePrevious } from '@utils/hooks/usePrevious'

import './BehaviorExpression.css'

interface BehaviorExpressionProps {
  className?: string
  dataTest?: string
}

const rootClass = 'behavior-expression'
const defaultBehaviorIndex = 0

interface BehaviorExpressionState {
  assetsCriteria?: AssetsCriteria
  behavior: SelectV2SingleOption
  category?: SelectV2SingleOption
  engagementCriteria: EngagementCriteria
  sendersCriteria?: SelectV2SingleOption[]
  numericCriteria: NumericCriteriaType
  deliverabilityStatus?: string
}

const BehaviorExpression: FC<BehaviorExpressionProps> = (props: BehaviorExpressionProps) => {
  const { dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const {
    values: { senderOptions },
  } = useContext(SegmentComposerContext)
  const behaviorOptions = getBehaviorCategory(t)
  const defaultBehavior = behaviorOptions[defaultBehaviorIndex]
  const [state, setState] = useState<BehaviorExpressionState>({
    behavior: defaultBehavior,
    category: getDefaultCategoryByBehavior[defaultBehavior.value as BehaviorCategory](t),
    engagementCriteria: { activities: [], numericCriteria: {}, quantityCriteria: {} },
    numericCriteria: {},
  })
  const { assetsCriteria, behavior, category, engagementCriteria, numericCriteria, deliverabilityStatus, sendersCriteria } = state

  const isBasicQuantity = (value: string) => {
    const categoriesWithLimitedOptions: string[] = [
      BehaviorEmailOption.FIRST_EMAIL_SENT_NAME,
      BehaviorEmailOption.LAST_EMAIL_SENT_NAME,
      BehaviorEngagementOption.TYPE_OF_FIRST_ENGAGEMENT,
      BehaviorEngagementOption.TYPE_OF_LAST_ENGAGEMENT,
      BehaviorProgramOption.LAST_PROGRAM_ENROLLED_IN,
      BehaviorWebsiteOption.FIRST_WEB_PAGE_VISITED,
      BehaviorWebsiteOption.LAST_WEB_PAGE_VISITED,
    ]
    return categoriesWithLimitedOptions.includes(value)
  }

  const assetType = category?.extraOptions?.assetType
  const previousAssetType = usePrevious(assetType)

  const update = useDeepUpdate(setState)

  const onBehaviorOptionChange = (behavior: SelectV2SingleOption) => {
    const defaultCategory = getDefaultCategoryByBehavior[behavior.value as BehaviorCategory](t)
    update({ behavior })
    onCategoryOptionChange(behavior.value as BehaviorCategory, defaultCategory)
  }

  const onCategoryOptionChange = (_category: BehaviorCategory, value: SelectV2SingleOption) => {
    update({
      category: {
        ...value,
        extraOptions: { ...value.extraOptions, noTimeframes: value.extraOptions?.noTimeframes, symbol: value.extraOptions?.symbol },
      },
    })
  }

  useEffect(() => {
    const hasNewAssetType = previousAssetType && previousAssetType !== assetType
    const hasSelectedAssets = assetsCriteria?.selectedAssets && assetsCriteria.selectedAssets.length > 0
    if (hasNewAssetType && hasSelectedAssets) {
      update({ assetsCriteria: { ...assetsCriteria, selectedAssets: [] } })
    }
  }, [assetType, assetsCriteria?.selectedAssets])

  const renderNextCondition = () => {
    if (category && category.extraOptions) {
      const { nextConditionType, assetType, isRequired, noTimeframes, symbol } = category.extraOptions
      switch (nextConditionType) {
        case BehaviorExpressionConditionType.QUANTITY_ASSETS:
          return (
            <>
              <Quantity
                dataTest={`${dataTest}-quantity-assets`}
                criteria={assetsCriteria?.quantityCriteria}
                onChange={(quantityCriteria) => update({ assetsCriteria: { ...assetsCriteria, quantityCriteria } })}
                isRequired={isRequired === 'true'}
                isBasic={isBasicQuantity(category.value)}
              >
                <AssetPickerSelector
                  selectedAssets={assetsCriteria?.selectedAssets}
                  onSelect={(selectedAssets) => update({ assetsCriteria: { ...assetsCriteria, selectedAssets } as AssetsCriteria })}
                  assetType={assetType as AssetTypes}
                />
              </Quantity>
              {noTimeframes === undefined && <DateTimeframes isBehavior />}
            </>
          )
        case BehaviorExpressionConditionType.QUANTITY_TEXT:
          return (
            <>
              <Quantity
                dataTest={`${dataTest}-quantity-text`}
                criteria={assetsCriteria?.quantityCriteria}
                onChange={(quantityCriteria) => update({ assetsCriteria: { ...assetsCriteria, quantityCriteria } })}
                isRequired={isRequired === 'true'}
                isBasic={isBasicQuantity(category.value)}
              >
                <MultiTextInput
                  selectedOptions={assetsCriteria?.searchTerms ?? []}
                  onChange={(options) => {
                    update({ assetsCriteria: { ...assetsCriteria, searchTerms: options } as AssetsCriteria })
                  }}
                  onCreate={(newTerm) => {
                    const newTermOption: SelectV2Option = { label: newTerm, value: newTerm }
                    update({
                      assetsCriteria: {
                        ...assetsCriteria,
                        searchTerms: [...(assetsCriteria?.searchTerms ?? []), newTermOption],
                      } as AssetsCriteria,
                    })
                  }}
                />
              </Quantity>
              {noTimeframes === undefined && <DateTimeframes isBehavior />}
            </>
          )
        case BehaviorExpressionConditionType.ENGAGEMENT_MULTISELECT:
        case BehaviorExpressionConditionType.ENGAGEMENT_MULTISELECT_WITH_NUMERIC:
          return (
            <>
              {nextConditionType === BehaviorExpressionConditionType.ENGAGEMENT_MULTISELECT_WITH_NUMERIC && (
                <NumericCriteria
                  numericCriteria={engagementCriteria.numericCriteria}
                  onChange={(numericCriteria) => update({ engagementCriteria: { ...engagementCriteria, numericCriteria } })}
                />
              )}
              <Quantity
                dataTest={`${dataTest}-quantity`}
                criteria={engagementCriteria.quantityCriteria}
                onChange={(quantityCriteria) => update({ engagementCriteria: { ...engagementCriteria, quantityCriteria } })}
                isRequired={isRequired === 'true'}
                isBasic={isBasicQuantity(category.value)}
              >
                <EngagementActivitySelect
                  dataTest={`${dataTest}-engagement-activity-select`}
                  defaultValues={engagementCriteria.activities}
                  onChange={(activities) => update({ engagementCriteria: { ...engagementCriteria, activities } })}
                />
              </Quantity>
              {noTimeframes === undefined && <DateTimeframes isBehavior />}
            </>
          )
        case BehaviorExpressionConditionType.MULTISELECT:
          return (
            <>
              <SelectV2
                dataTest={`${dataTest}-senders-select`}
                className={`${rootClass}__senders-select`}
                placeholder={t('Select senders')}
                options={senderOptions}
                value={sendersCriteria}
                onChangeMultiple={(sendersCriteria) => update({ sendersCriteria })}
                truncateMultiValues
                minSearchOptions={1}
              />
              {noTimeframes === undefined && <DateTimeframes isBehavior />}
            </>
          )
        case BehaviorExpressionConditionType.NUMERIC:
          return (
            <>
              <NumericCriteria numericCriteria={numericCriteria} onChange={(numericCriteria) => update({ numericCriteria })} symbol={symbol} />
              <DateTimeframes isBehavior />
            </>
          )
        case BehaviorExpressionConditionType.TIMEFRAMES:
          return <DateTimeframes isBehavior />
        case BehaviorExpressionConditionType.STATUS:
          return <DeliverabilityStatus status={deliverabilityStatus} onChange={(deliverabilityStatus) => update({ deliverabilityStatus })} />
      }
    }
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <SelectV2
        dataTest={`${dataTest}-behavior-select`}
        className={`${rootClass}__behavior-select`}
        options={behaviorOptions}
        defaultValue={behavior}
        onChange={(behavior) => onBehaviorOptionChange(behavior as SelectV2SingleOption)}
        isClearable={false}
        isSearchable={false}
      />
      {renderCategoryOptions[behavior.value as BehaviorCategory](t, onCategoryOptionChange, category)}
      {renderNextCondition()}
    </div>
  )
}

export default BehaviorExpression
