import { Dispatch, SetStateAction } from 'react'

import { Program, ProgramSchedule, ProgramSource, ProgramStepInput, ProgramTrackInput } from '@graphql/types/query-types'

import { ProgramGeneralSettingsProps, State } from './ProgramGeneralSettings'

export interface ProgramGeneralSettingsUtils {
  updateProgramField<T>(name: keyof Program, value: T): void
  updateProgramScheduleField<T>(name: keyof ProgramSchedule, value: T): void
  getTime(minute?: number, hour?: number, ampm?: number): string
  getTimeParts(
    hourStr: string | undefined,
    minuteStr: string | undefined
  ): { ampm: number | undefined; hour: number | undefined; minute: number | undefined }
  timeChange(value: string, isEnd?: boolean): void
  onClose(): void
  onAddList(): void
  onProgramSourcesUpdate(lists: ProgramSource[]): void
  deleteSourceList(i: number): ProgramSource[]
}

const getStepsWithList = (sourceId: string, program: Program) => {
  const stepsWithList: ProgramTrackInput[] = []
  const steps = program.tracks.flatMap((track) => track.steps)

  steps.forEach((currentStep: ProgramStepInput) => {
    if (currentStep) {
      if (currentStep.listId === sourceId || currentStep.srcId === sourceId) {
        stepsWithList.push(currentStep as ProgramTrackInput)
      } else if (currentStep.sendChoices) {
        if (currentStep.sendChoices.find((choice) => choice.srcId === sourceId)) {
          stepsWithList.push(currentStep as ProgramTrackInput)
        }
      }

      if (currentStep.conditions) {
        if (currentStep.conditions.find((choice) => choice.srcId === sourceId)) {
          stepsWithList.push(currentStep as ProgramTrackInput)
        }
      }
    }
  })

  return stepsWithList
}

export const getProgramGeneralSettingsUtils = (
  props: ProgramGeneralSettingsProps,
  setState: Dispatch<SetStateAction<State>>
): ProgramGeneralSettingsUtils => ({
  updateProgramField: function <T>(name: keyof Program, value: T) {
    props.updateProgram({
      ...props.program,
      [name]: value,
    })
  },
  updateProgramScheduleField: function <T>(name: keyof ProgramSchedule, value: T) {
    props.updateProgram({
      ...props.program,
      schedule: {
        ...props.program.schedule,
        [name]: value,
      },
    })
  },
  getTime: function (minute = 0, hour?: number, ampm?: number) {
    if (hour === undefined || hour === null) {
      return '12:00'
    }
    const hourString = ampm ? hour + 12 : hour === 12 ? 0 : hour
    const minuteString = minute > 9 ? `${minute}` : `0${minute}`
    return `${hourString}:${minuteString}`
  },
  getTimeParts: function (hourStr: string, minuteStr: string) {
    const hourNum = parseInt(hourStr)
    const ampm = hourNum < 12 ? 0 : 1
    const hour = hourNum > 12 ? hourNum - 12 : hourNum === 0 ? 12 : hourNum
    const minute = parseInt(minuteStr)
    return {
      ampm,
      hour,
      minute,
    }
  },
  timeChange: function (value: string, isEnd?: boolean) {
    const [hourStr, minuteStr] = value === '' || value === null ? ['0', '0'] : value.split(':')
    const { ampm, hour, minute } = this.getTimeParts(hourStr, minuteStr)
    if (isEnd) {
      props.updateProgram({
        ...props.program,
        schedule: {
          ...props.program.schedule,
          hasEndTime: true,
          endHour: hour,
          endMinute: minute,
          endAmpm: ampm,
        },
      })
    } else {
      props.updateProgram({
        ...props.program,
        schedule: {
          ...props.program.schedule,
          hour,
          minute,
          ampm,
        },
      })
    }
  },
  onClose: function () {
    setState((state) => ({
      ...state,
      showListPicker: false,
      loadingLists: false,
    }))
  },
  onAddList: function () {
    setState((state) => ({
      ...state,
      showListPicker: true,
    }))
  },
  onProgramSourcesUpdate: function (lists: ProgramSource[]) {
    const newSourceLists = lists.reduce((acc: ProgramSource[], cur) => {
      return [...acc, cur]
    }, [])
    const newSources = lists.reduce((acc: ProgramSource[], cur) => {
      if (props.program.sources.find((sourceList) => sourceList.id === cur.id)) {
        return acc
      }
      return [...acc, cur]
    }, [])
    setState((state) => ({
      ...state,
      showListPicker: false,
      loadingLists: false,
    }))

    props.updateProgram(
      {
        ...props.program,
        sourceList: [...newSourceLists],
        sources: [...props.program.sources, ...newSources],
      },
      true
    )
  },
  deleteSourceList: function (i: number) {
    const toBeDeleted = props.program.sourceList[i]
    const hasStepsWithList = getStepsWithList(toBeDeleted.id, props.program).length > 0
    const hasEarlyExits = props.program.exit.exitChoices.filter((choice) => choice.srcId === toBeDeleted.id).length > 0
    const sources = hasEarlyExits || hasStepsWithList ? props.program.sources : props.program.sources.filter((source) => source.id !== toBeDeleted.id)
    const newSourceList = [...props.program.sourceList.slice(0, i), ...props.program.sourceList.slice(i + 1)]

    props.updateProgram({
      ...props.program,
      sources,
      sourceList: newSourceList,
    })
    return newSourceList
  },
})

export default getProgramGeneralSettingsUtils
