import React, { FC, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'
import { ProgramManagerLocationStateProps } from '@src/pages/programs/manager/ProgramManager.constants'
import { toCamelCase } from '@utils/strings'

import ProgramPerformance, { ProgramPerformanceProps } from './ProgramPerformance'
import { DEFAULT_PERFORMANCE_TAB, ProgramPerformanceTab } from './ProgramPerformance.constants'

type ProgramPerformanceContainerProps = Omit<ProgramPerformanceProps, 'currentTab' | 'onTabChange'>

const isValidSubTab = (subTab: string) => Object.values(ProgramPerformanceTab).includes(toCamelCase(subTab) as ProgramPerformanceTab)

const ProgramPerformanceContainer: FC<ProgramPerformanceContainerProps> = () => {
  const {
    values: { subTab },
    subTabChange,
  } = useContext(ProgramManagerContext)
  const history = useHistory()

  const stateSubTab = subTab
    ? subTab
    : history.location.state
    ? ((history.location.state as ProgramManagerLocationStateProps)?.subTab as ProgramPerformanceTab)
    : DEFAULT_PERFORMANCE_TAB
  const [currentTab, setCurrentTab] = useState(isValidSubTab(stateSubTab) ? stateSubTab : DEFAULT_PERFORMANCE_TAB)

  const onTabChange = (newTab: string) => {
    const subTab = newTab === DEFAULT_PERFORMANCE_TAB ? '' : newTab
    subTabChange(subTab)
    setCurrentTab(newTab as ProgramPerformanceTab)
  }

  useEffect(() => onTabChange(currentTab), [])

  return <ProgramPerformance currentTab={toCamelCase(currentTab) as ProgramPerformanceTab} onTabChange={onTabChange} />
}

export default ProgramPerformanceContainer
