import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight } from '@components/Typography/Typography'

import './TagManagerBaseItem.css'

export interface TagManagerBaseItemProps {
  className?: string
  dataTest?: string
  action?: (name: string) => void
  children?: ReactNode
  color: string
  name: string
}

const rootClass = 'tag-manager-base-item'

const TagManagerBaseItem: FC<TagManagerBaseItemProps> = (props: TagManagerBaseItemProps) => {
  const { action, children, color, name, dataTest = rootClass, className = '' } = props
  return (
    <div
      role={'button'}
      tabIndex={0}
      onKeyDown={(keyDownEvent) => (keyDownEvent.key === ' ' ? (action && name ? action(name) : undefined) : undefined)}
      className={classNames(rootClass, className)}
      data-test={dataTest}
      onClick={() => (action && name ? action(name) : false)}
    >
      <div className={`${rootClass}__color`} style={{ backgroundColor: color }} />
      <div className={`${rootClass}__name`}>
        <Tooltip
          ellipOnTrigger
          position={'top'}
          trigger={<Typography className={'ellip'} inline lineHeight={LineHeight.MEDIUM_SMALL} text={name} />}
          triggerClassName={`${rootClass}__tooltip`}
        >
          {name}
        </Tooltip>
      </div>
      {children}
    </div>
  )
}

export default TagManagerBaseItem
