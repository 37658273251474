export const customFields = [
  { header: 'QuickAddContacts.firsName.label', placeholder: 'QuickAddContacts.firsName.placeholder', id: 'firstName', value: '' },
  { header: 'QuickAddContacts.lastName.label', placeholder: 'QuickAddContacts.lastName.placeHolder', id: 'lastName', value: '' },
  {
    header: 'QuickAddContacts.email.label',
    placeholder: 'QuickAddContacts.email.placeHolder',
    id: 'email',
    required: true,
    inputWithStatus: true,
    value: '',
  },
]
