import { IPluginRow } from '@beefree.io/sdk/dist/types/bee'
import { IEntityContentJsonExtended } from '@utils/composer/beeEditor/beeEditorTypes'
import { getUUID } from '@utils/const/globals'

export const MIN_IFRAME_WIDTH_PX = 396
export const MIN_EDITOR_WIDTH_PX = 396
export const MIN_FOOTER_WIDTH_PX = 460

const uuid = getUUID()

export const htmlToUploadHtmlJson = (html: string): IEntityContentJsonExtended => {
  return {
    page: {
      template: {
        name: '',
        type: '',
        version: '',
      },
      body: {
        type: 'mailup-bee-page-properties',
        content: {
          style: {
            'font-family': '',
            color: '',
          },
          computedStyle: {
            align: '',
            linkColor: '',
            messageBackgroundColor: '',
            messageWidth: '',
          },
        },
        container: {
          style: {
            'background-color': '',
          },
        },
        webFonts: [],
      },
      description: '',
      title: '',
      rows: [
        {
          columns: [
            {
              'grid-columns': 12,
              style: {},
              modules: [
                {
                  type: 'mailup-bee-newsletter-modules-html',
                  descriptor: {
                    computedStyle: {
                      hideContentOnAmp: false,
                      hideContentOnDesktop: false,
                      hideContentOnHtml: false,
                      hideContentOnMobile: false,
                    },
                    style: {},
                    html: {
                      html,
                      computedStyle: {},
                      style: {},
                    },
                  },
                  uuid: `${uuid}-module`,
                },
              ],
              uuid: `${uuid}-column`,
            },
          ],
          uuid,
          container: {},
        } as unknown as IPluginRow,
      ],
    },
    comments: {},
  }
}
