import crmDefinition from '@graphql/microservices/crm/crmDefinition'
import crmSegmentsInfo from '@graphql/microservices/crm/crmSegmentsInfo'
import entityType from '@graphql/microservices/crm/entityType'
import syncedSegmentDetails from '@graphql/microservices/crm/syncedSegmentDetails'
import {
  CrmDefinitionQuery,
  CrmDefinitionQueryVariables,
  CrmSegmentsInfoQuery,
  CrmSegmentsInfoQueryVariables,
  EntityTypeQuery,
  EntityTypeQueryVariables,
  SyncedSegmentDetailsQuery,
  SyncedSegmentDetailsQueryVariables,
} from '@graphql/types/microservice/crm-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

type GetCRMNotSupportedEntitiesRequestParams = { connectedCRM: string }
type GetCRMSegmentsInfoRequestParams = { segmentIdentifiers: string[] }
type SyncedSegmentDetailsRequestParams = GetCRMSegmentsInfoRequestParams

export type GetCRMNotSupportedEntitiesRequestType = (params: GetCRMNotSupportedEntitiesRequestParams) => FetchPromise<CrmDefinitionQuery>
export type GetEntityTypeCampaignRequestType = () => FetchPromise<EntityTypeQuery>
export type GetCRMSegmentsInfoRequestType = (params: GetCRMSegmentsInfoRequestParams) => FetchPromise<CrmSegmentsInfoQuery>
export type SyncedSegmentDetailsRequestType = (params: SyncedSegmentDetailsRequestParams) => FetchPromise<SyncedSegmentDetailsQuery>

interface UseListPickerModalCRMResponse {
  getCRMNotSupportedEntitiesRequest: GetCRMNotSupportedEntitiesRequestType
  getEntityTypeCampaignRequest: GetEntityTypeCampaignRequestType
  getCRMSegmentsInfoRequest: GetCRMSegmentsInfoRequestType
  syncedSegmentDetailsRequest: SyncedSegmentDetailsRequestType
}

export const useListPickerModalCRMRequests = (): UseListPickerModalCRMResponse => {
  const {
    client: { query },
  } = useMicroserviceClient({ serviceName: MicroserviceClients.CRM })

  const getCRMNotSupportedEntitiesRequest = async ({ connectedCRM }: GetCRMNotSupportedEntitiesRequestParams) =>
    await query<CrmDefinitionQuery, CrmDefinitionQueryVariables>({
      query: crmDefinition,
      fetchPolicy: 'network-only',
      variables: {
        connectedCrmName: connectedCRM,
      },
    })

  const getEntityTypeCampaignRequest = async () =>
    await query<EntityTypeQuery, EntityTypeQueryVariables>({
      query: entityType,
      fetchPolicy: 'network-only',
      variables: {
        entityType: 'Campaign',
      },
    })

  const getCRMSegmentsInfoRequest = async ({ segmentIdentifiers }: GetCRMSegmentsInfoRequestParams) =>
    await query<CrmSegmentsInfoQuery, CrmSegmentsInfoQueryVariables>({
      query: crmSegmentsInfo,
      fetchPolicy: 'network-only',
      variables: { segmentIdentifiers },
    })

  const syncedSegmentDetailsRequest = async ({ segmentIdentifiers }: SyncedSegmentDetailsRequestParams) =>
    await query<SyncedSegmentDetailsQuery, SyncedSegmentDetailsQueryVariables>({
      query: syncedSegmentDetails,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: { segmentIdentifiers },
    })

  return {
    getCRMNotSupportedEntitiesRequest,
    getEntityTypeCampaignRequest,
    getCRMSegmentsInfoRequest,
    syncedSegmentDetailsRequest,
  }
}
