import { useAccountSettings } from '@utils/account/account.utils'

import { ListingPageProps } from '../Context/ListingPageCommon.context'
import { isSalesUserAccount } from '../Utils/ListingPage.utils'

export const useFolderSubTypes = (props: ListingPageProps) => {
  const {
    sidebarProps: { hasSalesUsersAction },
  } = props

  const accountSettings = useAccountSettings()
  return hasSalesUsersAction ? (isSalesUserAccount(accountSettings) ? ['sales'] : []) : []
}
