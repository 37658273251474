import React, { FC, useState } from 'react'

import classNames from 'classnames'

import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { useTranslation } from '@const/globals'
import { getDeliverabilityStatusOptions } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/BehaviorExpression/BehaviorExpression.utils'
import DateTimeframes from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/DateTimeframes/DateTimeframes'

interface DeliverabilityStatusProps {
  className?: string
  dataTest?: string
  status?: string
  onChange: (status: string) => void
}

const rootClass = 'deliverability-status'
const defaultStatusOptionIndex = 0

const DeliverabilityStatus: FC<DeliverabilityStatusProps> = (props: DeliverabilityStatusProps) => {
  const { dataTest = rootClass, className = '', status, onChange } = props
  const { t } = useTranslation()
  const statusOptions = getDeliverabilityStatusOptions(t)
  const [statusOption, setStatusOption] = useState<SelectV2SingleOption>(
    statusOptions.find((option) => option.value === status) || statusOptions[defaultStatusOptionIndex]
  )

  const onStatusChange = (status: SelectV2SingleOption) => {
    setStatusOption(status)
    onChange(status.value)
  }

  const renderNextCondition = () => {
    const { extraOptions } = statusOption
    if (extraOptions?.nextConditionType === 'TIMEFRAMES') {
      return <DateTimeframes isBehavior />
    }
  }

  return (
    <>
      <SelectV2
        className={classNames(`${rootClass}__criteria-select`, className)}
        dataTest={`${dataTest}-criteria-select`}
        options={statusOptions}
        value={statusOption}
        onChange={(option) => onStatusChange(option as SelectV2SingleOption)}
        isClearable={false}
        isSearchable={false}
      />
      {renderNextCondition()}
    </>
  )
}

export default DeliverabilityStatus
