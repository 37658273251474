import { gql } from '@apollo/client'

export default gql`
  mutation changeDefaultFromAddress($uuid: String!, $action: String!) {
    setDefaultSender(uuid: $uuid, action: $action) {
      status
      message
    }
  }
`
