import React, { FC, useEffect, useMemo, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { Checkbox } from '@components/Checkbox/Checkbox'
import DraggableColumn, { Column, ColumnStatus } from '@components/ColumnsOrderModal/components/DraggableColumn/DraggableColumn'
import { dropColumn, hideColumn, isHidden, isLocked, lockColumn } from '@components/ColumnsOrderModal/utils/ColumnsOrderModal.utils'
import DragLayer from '@components/DragLayer/DragLayer'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { ModalBodyStyle } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './ColumnsOrderModal.css'

interface Props {
  className?: string
  dataTest?: string
  isOpen: boolean
  onClose: () => void
  onSave: (columns: Column[], checked: boolean) => void
  columns: Column[]
  checkboxLabel: string
  lockedLimit?: number
}

const DEFAULT_LOCKED_COLUMNS_LIMIT = 3

const rootClass = 'columns-order-modal'

const ColumnsOrderModal: FC<Props> = (props: Props) => {
  const {
    dataTest = rootClass,
    className = '',
    isOpen,
    onClose,
    onSave,
    columns = [],
    checkboxLabel = '',
    lockedLimit = DEFAULT_LOCKED_COLUMNS_LIMIT,
  } = props
  const [orderedColumns, setColumns] = useState<Column[]>([])
  const [checked, setChecked] = useState(false)

  const { t } = useTranslation()

  const lockedColumnsSize = useMemo(() => orderedColumns.filter((column) => isLocked(column)).length, [orderedColumns])
  const hiddenColumnsSize = useMemo(() => orderedColumns.filter((column) => isHidden(column)).length, [orderedColumns])

  useEffect(() => {
    if (orderedColumns.length === 0) {
      const orderedColumns = columns.sort((firstColumn, secondColumn) => (isLocked(secondColumn) ? 1 : isLocked(firstColumn) ? -1 : 0))
      setColumns(orderedColumns)
    }
  }, [columns])

  const onDrop = (column: Column, droppedAt: number) => setColumns(dropColumn(column, orderedColumns, droppedAt))

  const onLock = (column: Column) => setColumns(lockColumn(column, orderedColumns, lockedColumnsSize))

  const onHide = (column: Column) => setColumns(hideColumn(column, orderedColumns))

  const onReset = () => {
    const defaultColumns = columns
      .map((column) => ({ ...column, status: ColumnStatus.DEFAULT }))
      .sort(({ id: firstColumn }, { id: secondColumn }) => firstColumn - secondColumn)
    setColumns(defaultColumns)
  }

  const renderHeader = <ModalHeader headerType={ModalHeaderType.Form}>{t('Manage Column Order')}</ModalHeader>

  const renderColumns = () => {
    return orderedColumns.map((column, index) => (
      <DraggableColumn
        key={`${column.name}-${index}`}
        className={`${rootClass}__column`}
        dataTest={`${dataTest}__column`}
        column={{ ...column, index }}
        onDrop={onDrop}
        onLock={onLock}
        onHide={onHide}
        disableLock={lockedColumnsSize >= lockedLimit}
        disableHide={hiddenColumnsSize === orderedColumns.length - 1}
        listingPosition={index + 1}
      />
    ))
  }

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={renderHeader}>
      <DndProvider backend={HTML5Backend}>
        <DragLayer />
        <ModalBody className={`${rootClass}__body`}>
          <div className={`${rootClass}__body-title`}>
            <Typography text={t('Choose columns to show and drag to change order.')} {...ModalBodyStyle} />
            <TextLink
              dataTest={`${dataTest}__button-reset`}
              text={t('Reset to default settings')}
              size={TextLinkSize.LARGE}
              hideIcon
              onClick={onReset}
            />
          </div>
          {renderColumns()}
        </ModalBody>
        <ModalFooter footerType={ModalFooterType.Form}>
          <Checkbox
            dataTest={`${dataTest}__checkbox`}
            className={`${rootClass}__checkbox`}
            label={t(checkboxLabel)}
            checked={checked}
            onChange={setChecked}
          />
          <Button className={`${rootClass}__button-cancel`} buttonType={ButtonType.TERTIARY} onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button dataTest={`${dataTest}__button-save`} buttonType={ButtonType.PRIMARY} onClick={() => onSave(orderedColumns, checked)}>
            {t('Save')}
          </Button>
        </ModalFooter>
      </DndProvider>
    </Modal>
  )
}

export default ColumnsOrderModal
