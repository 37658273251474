import React from 'react'
import { NoticeProps } from 'react-select'

import Loader from '@components/Loader'
import { SelectV2Option } from '@components/SelectV2/SelectV2.props'
import { selectRootClass } from '@components/SelectV2/SelectV2.utils'

const CustomLoadingMessage = (props: NoticeProps<SelectV2Option>) => {
  const { innerProps } = props

  return (
    <div {...innerProps} className={`${selectRootClass}__menu-loader-container`}>
      <Loader center />
    </div>
  )
}

export default CustomLoadingMessage
