import { useApolloClient } from '@apollo/client'
import getItem from '@graphql/microservices/categorization/getItem'
import getItems from '@graphql/microservices/categorization/getItems'
import createTemplateFromForm from '@graphql/mutations/createTemplateFromForm'
import deleteForms from '@graphql/mutations/deleteForms'
import duplicateForm from '@graphql/mutations/duplicateForm'
import migrateClassicForms from '@graphql/mutations/migrateClassicForms'
import getFormPreview from '@graphql/queries/getFormPreview'
import { GetItemQuery, GetItemQueryVariables, GetItemsQuery, GetItemsQueryVariables } from '@graphql/types/microservice/categorization-types'
import { MigrateClassicFormsMutation, MigrateClassicFormsMutationVariables } from '@graphql/types/mutation-types'
import {
  CreateTemplateFromFormMutation,
  CreateTemplateFromFormMutationVariables,
  DeleteFormsMutation,
  DeleteFormsMutationVariables,
  DuplicateFormMutation,
  DuplicateFormMutationVariables,
} from '@graphql/types/mutation-types'
import { GetFormPreviewQuery, GetFormPreviewQueryVariables } from '@graphql/types/query-types'
import { ItemType } from '@utils/categorization'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export interface FormsListingPageRequests {
  createTemplateFromFormRequest: (variables: CreateTemplateFromFormMutationVariables) => FetchPromise<CreateTemplateFromFormMutation>
  deleteFormsRequest: (variables: DeleteFormsMutationVariables) => FetchPromise<DeleteFormsMutation>
  duplicateFormRequest: (variables: DuplicateFormMutationVariables) => FetchPromise<DuplicateFormMutation>
  migrateClassicFormsRequest: (variables: MigrateClassicFormsMutationVariables) => FetchPromise<MigrateClassicFormsMutation>
  getFormPreviewRequest: (variables: GetFormPreviewQueryVariables) => FetchPromise<GetFormPreviewQuery>
  getFormTemplateExists: () => FetchPromise<GetItemsQuery>
  getItemRequest: (variables: GetItemQueryVariables) => FetchPromise<GetItemQuery>
}

export const useGetFormsListingPageRequests = (): FormsListingPageRequests => {
  const actonClient = useApolloClient()
  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })

  const createTemplateFromFormRequest = async (variables: CreateTemplateFromFormMutationVariables) => {
    return await actonClient.mutate<CreateTemplateFromFormMutation, CreateTemplateFromFormMutationVariables>({
      mutation: createTemplateFromForm,
      errorPolicy: 'all',
      variables,
    })
  }

  const deleteFormsRequest = async (variables: DeleteFormsMutationVariables) => {
    return await actonClient.mutate<DeleteFormsMutation, DeleteFormsMutationVariables>({
      mutation: deleteForms,
      errorPolicy: 'all',
      variables,
    })
  }

  const duplicateFormRequest = async (variables: DuplicateFormMutationVariables) => {
    return await actonClient.mutate<DuplicateFormMutation, DuplicateFormMutationVariables>({
      mutation: duplicateForm,
      errorPolicy: 'all',
      variables,
    })
  }

  const migrateClassicFormsRequest = async (variables: MigrateClassicFormsMutationVariables) => {
    return await actonClient.mutate<MigrateClassicFormsMutation, MigrateClassicFormsMutationVariables>({
      mutation: migrateClassicForms,
      errorPolicy: 'all',
      variables,
    })
  }

  const getFormPreviewRequest = async (variables: GetFormPreviewQueryVariables) => {
    return await actonClient.query<GetFormPreviewQuery, GetFormPreviewQueryVariables>({
      query: getFormPreview,
      errorPolicy: 'all',
      variables,
      fetchPolicy: 'no-cache',
    })
  }

  const getItemRequest = async (variables: GetItemQueryVariables) => {
    return await categorizationClient.query<GetItemQuery, GetItemQueryVariables>({
      query: getItem,
      errorPolicy: 'all',
      variables,
      fetchPolicy: 'no-cache',
    })
  }

  const getFormTemplateExists = async () => {
    return await categorizationClient.query<GetItemsQuery, GetItemsQueryVariables>({
      query: getItems,
      errorPolicy: 'all',
      variables: {
        pageSize: 1,
        type: ItemType.FORM_TEMPLATE,
      },
    })
  }

  return {
    createTemplateFromFormRequest,
    deleteFormsRequest,
    duplicateFormRequest,
    migrateClassicFormsRequest,
    getFormPreviewRequest,
    getFormTemplateExists,
    getItemRequest,
  }
}
