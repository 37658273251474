import React, { CSSProperties } from 'react'

// eslint-disable-next-line no-restricted-imports
import { addDays, set } from 'date-fns'
// eslint-disable-next-line no-restricted-imports
import { zonedTimeToUtc } from 'date-fns-tz'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { t } from 'i18next'

import { getUUID } from '@utils/const/globals'
import { isValidUrl, stripHtml } from '@utils/utils'

import { CalendarOption } from '../../EmailModals/components/EventModal/components/EventBlock/EventBlock.utils'
import { EventModalTabs } from '../../EmailModals/components/EventModal/utils/EventModal.utils'
import { WebinarDetailsFields } from '../../EmailModals/components/ManageWebinarDetailsModal/components/WebinarBlock/WebinarBlock'
import { CustomAddonModalForm } from '../Addons/customAddons.types'

dayjs.extend(utc)
dayjs.extend(timezone)

export const fontStyles: Partial<CSSProperties> = { margin: '0', fontSize: '16px', fontWeight: '600', lineHeight: '24px', whiteSpace: 'pre-line' }
const phoneInfoTitleStyles = { fontWeight: 600, fontSize: '16px', lineHeight: '24px', margin: 0 }
const descStyle = { fontWeight: 400, fontSize: '14px', lineHeight: '21px', margin: 0 }

const GOOGLE_CALENDAR_LINK = 'https://calendar.google.com/calendar/render?action=TEMPLATE'
const OUTLOOK_CALENDAR_LINK = 'https://outlook.live.com/calendar/0/action/compose'
const YAHOO_CALENDAR_LINK = 'https://calendar.yahoo.com/'

const adjustEndDateForYahooOutlook = (startDate: Date, endDate: Date) => {
  const startLocal = dayjs(startDate)
  const endLocal = dayjs(endDate)

  if (endLocal.hour() < startLocal.hour()) {
    return endLocal.add(1, 'day').toDate()
  }

  return endDate
}

const getCalendarDateTimeString = (date: Date, timeZone: string, calendarOption: CalendarOption, allDay = false) => {
  const utcDate = zonedTimeToUtc(date, timeZone).toISOString()
  const dateString = calendarOption === CalendarOption.OUTLOOK ? utcDate.split('T') : utcDate.replaceAll('-', '').split('T')
  const [hour, min] = dateString[1].split(':')

  const getFormattedTime = () => {
    if (calendarOption === CalendarOption.OUTLOOK) {
      return `${hour}:${min}:00Z`
    } else if (calendarOption === CalendarOption.GOOGLE) {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      return `${year}${month}${day}T${hours}${minutes}00`
    } else if (calendarOption === CalendarOption.YAHOO) {
      return `${hour}${min}00Z`
    } else {
      return `${hour}${min}:00Z`
    }
  }

  return calendarOption === CalendarOption.GOOGLE ? getFormattedTime() : `${dateString[0]}${allDay ? '' : `T${getFormattedTime()}`}`
}

const encodeWithPlaceholders = (calendarType: CalendarOption, text: string) => {
  const placeholderPattern = /{{\s*[^{}]+\s*}}/g
  const isYahoo = calendarType === CalendarOption.YAHOO
  const cleanText = isYahoo ? stripHtml(text) : text

  let result = ''
  let lastIndex = 0

  const matches = [...cleanText.matchAll(placeholderPattern)]

  if (matches.length === 0) {
    return encodeURIComponent(cleanText)
  }

  for (const match of matches) {
    const placeholder = match[0]
    const index = match.index!

    const leftPart = cleanText.slice(lastIndex, index)
    result += encodeURIComponent(leftPart)

    result += placeholder

    lastIndex = index + placeholder.length
  }

  const rightPart = cleanText.slice(lastIndex)
  result += encodeURIComponent(rightPart)

  return result
}

const formatDescription = (
  calendarType: CalendarOption,
  joinUrl: string | undefined,
  description: string,
  phoneDetails?: { [key: string]: string },
  webinarId?: string
) => {
  const lineBreak = calendarType === CalendarOption.OUTLOOK ? '<br>' : '\n'
  const urlJoinText = 'Please click this URL to join'
  const noteText = 'This link should not be shared with others; it is unique to you.'
  const isYahoo = calendarType === CalendarOption.YAHOO
  const hasValidUrl = isValidUrl(joinUrl)
  let urlPart = ''

  const formattedPhoneDetails = Object.entries(phoneDetails ?? {})
    .map(([key, value]) => {
      const formattedKey = isYahoo ? key : `<strong>${key}</strong>`
      const formattedValue = isYahoo ? value.replace(/ or /g, ' or ') : value.replace(/ or /g, ' <em>or</em>').replace(/\n/g, lineBreak)
      return `${formattedKey}: ${formattedValue}`
    })
    .join(lineBreak)

  switch (true) {
    case joinUrl && hasValidUrl && calendarType === CalendarOption.YAHOO:
      urlPart = `${urlJoinText}: ${joinUrl}${lineBreak}`
      break

    case !!(joinUrl && hasValidUrl):
      urlPart = `${urlJoinText}, <a href="${joinUrl}" target="_blank">${joinUrl}</a>${lineBreak}`
      break

    case !!joinUrl:
      urlPart = `${urlJoinText}, ${joinUrl}${lineBreak}`
      break

    default:
      urlPart = ''
      break
  }

  const noteBoldText = `<strong>Note: </strong>${noteText}`
  const descriptionBoldText = `<strong>Description: </strong>${description}`
  const webinarIdBoldText = `<strong>Webinar ID: </strong>${webinarId}`

  const webinarIdText = webinarId ? (calendarType === CalendarOption.YAHOO ? stripHtml(webinarIdBoldText) : webinarIdBoldText) : ''
  const note = calendarType === CalendarOption.YAHOO ? stripHtml(noteBoldText) : noteBoldText
  const descriptionText = calendarType === CalendarOption.YAHOO ? stripHtml(descriptionBoldText) : descriptionBoldText

  let result = `Join from a PC, Mac, iPad, iPhone, or Android device:${lineBreak}${urlPart}${note}${lineBreak}${lineBreak}${descriptionText}${lineBreak}${lineBreak}${formattedPhoneDetails}${lineBreak}${lineBreak}${webinarIdText}`

  // Normalize spacing: Remove extra empty lines
  const emptyLinePattern = new RegExp(`(${lineBreak}){3,}`, 'g')
  result = result.replace(emptyLinePattern, `${lineBreak}${lineBreak}`)

  return result.trim()
}

export const getCalendarLink = (data: CustomAddonModalForm, calendar: CalendarOption, accountId: string, blockId: string, fromEventBlock = false) => {
  const {
    details: { eventName, startDate, endDate, allDay, description, timeZone, endTime, isWebinar, phoneDetails, joinUrl, webinarId },
  } = data

  let selectedAddressSubtext

  if (data.activeTab === EventModalTabs.IN_PERSON && data.locationDetails?.selectedAddress?.subText) {
    selectedAddressSubtext = data.locationDetails.selectedAddress.subText
  } else {
    selectedAddressSubtext = ''
  }

  let tempEndDate

  if (allDay && endDate) {
    tempEndDate = endDate
  } else if (startDate) {
    const [hours, minutes] = endTime.split(':')

    tempEndDate = fromEventBlock
      ? set(startDate.getTime(), { hours: minutes.includes('PM') ? parseInt(hours) + 12 : parseInt(hours), minutes: parseInt(minutes) })
      : set(startDate.getTime(), { hours: parseInt(hours), minutes: parseInt(minutes) })
  }

  let startDateTime
  let endDateTime

  if (startDate) {
    startDateTime = getCalendarDateTimeString(startDate, timeZone.value, calendar, fromEventBlock ? allDay : undefined)
  }

  if (tempEndDate) {
    const adjustedEndDate =
      (calendar === CalendarOption.YAHOO || calendar === CalendarOption.OUTLOOK) && startDate
        ? adjustEndDateForYahooOutlook(startDate, endDate ?? tempEndDate)
        : endDateTime ?? tempEndDate

    const end = allDay && tempEndDate && calendar !== CalendarOption.YAHOO ? addDays(tempEndDate, 1) : tempEndDate

    endDateTime = fromEventBlock
      ? getCalendarDateTimeString(end ?? tempEndDate, timeZone.value, calendar, allDay)
      : getCalendarDateTimeString(calendar === CalendarOption.GOOGLE ? endDate ?? tempEndDate : adjustedEndDate, timeZone.value, calendar)
  }
  const timeZoneMappings: { [key: string]: string } = {
    ['Zulu']: 'Etc/GMT',
  }

  const googleDescription = isWebinar
    ? encodeWithPlaceholders(CalendarOption.GOOGLE, formatDescription(CalendarOption.GOOGLE, joinUrl, description, phoneDetails, webinarId))
    : encodeURIComponent(description)
  const outlookDescription = isWebinar
    ? encodeWithPlaceholders(CalendarOption.OUTLOOK, formatDescription(CalendarOption.OUTLOOK, joinUrl, description, phoneDetails, webinarId))
    : encodeURIComponent(description)
  const yahooDescription = isWebinar
    ? encodeWithPlaceholders(CalendarOption.YAHOO, formatDescription(CalendarOption.YAHOO, joinUrl, description, phoneDetails, webinarId))
    : encodeURIComponent(description)

  switch (calendar) {
    case CalendarOption.GOOGLE:
      const getHours = (dateString: string | undefined) => (dateString ? dayjs(dateString, 'YYYYMMDDTHHmm:ss[Z]', true).hour() : 0)

      if (startDateTime && endDateTime && getHours(startDateTime) >= 12 && getHours(endDateTime) < 12) {
        endDateTime = dayjs(endDateTime).add(1, 'day').format('YYYYMMDDTHHmmss[Z]')
      }

      return `${GOOGLE_CALENDAR_LINK}&dates=${startDateTime}%2F${endDateTime}&details=${googleDescription}&location=${encodeURIComponent(
        selectedAddressSubtext
      )}&text=${encodeURIComponent(eventName)}&ctz=${encodeURIComponent(timeZoneMappings[timeZone.value] || timeZone.value)}`
    case CalendarOption.OUTLOOK:
      return `${OUTLOOK_CALENDAR_LINK}?allday=${allDay}&body=${outlookDescription}&enddt=${endDateTime}&location=${encodeURIComponent(
        selectedAddressSubtext
      )}&startdt=${startDateTime}&subject=${encodeURIComponent(eventName)}`
    case CalendarOption.YAHOO:
      return encodeURI(
        `${YAHOO_CALENDAR_LINK}?desc=${yahooDescription}&et=${endDateTime}&in_loc=${encodeURIComponent(
          selectedAddressSubtext
        )}&st=${startDateTime}&title=${encodeURIComponent(eventName)}&v=60&dur=${allDay ? 'allday' : ''}`
      )
    default:
      return `${window.origin}/acton/vcal/${accountId}/{{Env.MsgId}}/${blockId}/-/-/false`
  }
}

const getDetailsIconHeight = (detail: WebinarDetailsFields) => {
  if (detail.largeIcon) {
    return detail.iconName === 'zoom-logo' ? '32px' : '28px'
  }

  return detail.iconName === 'webinar-outline' ? '15px' : '20px'
}

const getDetailsStyles = (detail: WebinarDetailsFields) => {
  return {
    borderRadius: '4px',
    width: '50px',
    height: '50px',
    backgroundColor: detail.largeIcon ? 'transparent' : '#F5F6F7',
  }
}

export const renderDetails = (details: WebinarDetailsFields[], idAttribute?: string) => {
  return (
    <table id={idAttribute ?? 'details'} style={{ borderCollapse: 'separate', borderSpacing: '16px 12px' }}>
      {details.map((detail) => (
        <tr key={`${detail.iconName}`}>
          <td align="center" style={{ textAlign: 'center', ...getDetailsStyles(detail) }}>
            <img
              src={`${window.location.origin}/app/static/bee/icons/${detail.iconName}.png`}
              width={detail.largeIcon ? 32 : detail.iconName === 'location' ? 15 : 20}
              height={detail.largeIcon ? (detail.iconName === 'zoom-logo' ? 32 : 28) : detail.iconName === 'webinar-outline' ? 15 : 20}
              style={{
                width: detail.largeIcon ? '32px' : detail.iconName === 'location' ? '15px' : '20px',
                height: getDetailsIconHeight(detail),
              }}
              alt={`${detail.iconName}-icon`}
            />
          </td>
          <td style={{ textAlign: 'left', marginLeft: '12px' }}>
            <p style={{ ...fontStyles }}>{detail.mainText}</p>
            {detail.isLink ? (
              <a
                style={{
                  ...fontStyles,
                  fontWeight: '400',
                  maxWidth: '300px',
                  display: 'block',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
                href={`${detail.subText}`}
                target={'_blank'}
                rel="noreferrer"
              >
                {detail.subText}
              </a>
            ) : (
              <p style={{ ...fontStyles, fontWeight: '400', color: '#606060' }}>{detail.subText}</p>
            )}
          </td>
        </tr>
      ))}
    </table>
  )
}

export const renderAddToCalendar = (
  calendarDownloadLink: string,
  addToName = t('EmailComposer.EventBlock.AddEventToCalendar'),
  addToCalendar = t('EmailComposer.EventBlock.AddToCalendar')
) => (
  <>
    <p style={{ ...fontStyles, padding: '12px', fontSize: '18px', textAlign: 'center' }}>{addToName}</p>
    <table
      align="center"
      style={{
        borderSpacing: 0,
        borderCollapse: 'collapse',
      }}
    >
      <tbody>
        <tr>
          <td
            align="center"
            style={{
              textAlign: 'center',
              width: '169px',
              height: '38.5px',
              backgroundColor: '#F5F6F7',
              borderRadius: '6px',
            }}
          >
            {/* Your content here */}
            <a
              style={{
                color: 'black',
                textDecoration: 'none',
                display: 'block',
                fontSize: '16px',
                textAlign: 'center',
                fontWeight: '600',
              }}
              href={calendarDownloadLink}
              target={'_blank'}
              rel="noreferrer"
            >
              {addToCalendar}
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </>
)

export const renderAddToCalendarIcons = (
  data: Pick<CustomAddonModalForm, 'details'> | CustomAddonModalForm,
  accountId: string,
  blockId: string,
  calendarDownloadLink?: string,
  fromEventBlock = false
) =>
  [CalendarOption.APPLE, CalendarOption.GOOGLE, CalendarOption.OUTLOOK, CalendarOption.YAHOO].map((calendarOption) => (
    <td
      key={calendarOption}
      align="center"
      style={{
        textAlign: 'center',
      }}
    >
      <a
        key={`${calendarOption}-link`}
        href={
          calendarOption === CalendarOption.APPLE
            ? calendarDownloadLink
            : getCalendarLink(data as CustomAddonModalForm, calendarOption, accountId, blockId, fromEventBlock)
        }
        target={'_blank'}
        rel="noreferrer"
      >
        <img
          width={32}
          height={32}
          style={{ width: '32px', height: '32px' }}
          src={`${window.location.origin}/app/static/bee/icons/${calendarOption}-icon.png`}
          alt={`${calendarOption}-icon`}
        />
      </a>
    </td>
  ))

const italicizeOr = (text: string) => {
  const regex = /\sor\s/g
  const parts = text.split(regex)
  const result: JSX.Element[] = []

  parts.forEach((part, index) => {
    result.push(<span key={getUUID(true)}>{part}</span>)
    if (index < parts.length - 1) {
      result.push(<i key={`i${getUUID(true)}`}> or </i>)
    }
  })

  return result
}

export const getPhoneInfo = (phoneDetails: { [x: string]: string }, showDivider?: boolean) => (
  <>
    {Object.entries(phoneDetails).map((detail, i) => {
      const title = detail[0]
      const desc = detail[1]

      return (
        <div key={detail[0]} style={{ fontFamily: 'Open Sans', marginBottom: 20 }}>
          {i < 2 ? (
            <>
              <h3 style={phoneInfoTitleStyles}>{title}</h3>
              <div style={descStyle}>{desc}</div>
            </>
          ) : (
            <div style={descStyle}>
              <span style={phoneInfoTitleStyles}>{title}</span>: {italicizeOr(desc)}
            </div>
          )}
        </div>
      )
    })}
    {showDivider && (
      <div id={'divider'} style={{ padding: '10px', paddingBottom: '0px' }}>
        <hr style={{ height: '2px', width: '100%', backgroundColor: 'rgb(187, 187, 187)', borderStyle: 'unset' }} />
      </div>
    )}
  </>
)
