import { gql } from '@apollo/client'

export default gql`
  mutation deleteForms($formIds: [String!]!) {
    deleteForms(formIds: $formIds) {
      status
      successIds {
        id
        name
      }
      failedIds {
        id
        name
        reason
      }
    }
  }
`
