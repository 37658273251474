import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/Button'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import { SvgNames } from '@components/Svg'
import Svg, { SvgType } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ProgramAsset } from '@src/pages/listingPages/AutomatedPrograms/components/CreateProgramTemplateModal/CreateProgramTemplateModal'

import './CreateProgramFromTemplate.css'

import { ProgramAssetType } from '@utils/program/program.constants'

interface CreateProgramFromTemplateProps {
  assets: ProgramAsset[]
  isOpen: boolean
  onClose: () => void
  onCreate: () => void
  className?: string
  dataTest?: string
}

const MAX_DESCRIPTION_WITHOUT_TOOLTIP = 75

const rootClass = 'create-program-from-template'

const CreateProgramFromTemplate: FC<CreateProgramFromTemplateProps> = (props: CreateProgramFromTemplateProps) => {
  const { assets, isOpen, onClose, onCreate, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()

  const renderRawDescription = (title: string, description?: string) => (
    <Typography
      text={!description || description === '' ? title : description}
      weight={TextWeight.MEDIUM}
      className={classNames(`${rootClass}__asset-description`, 'ellip')}
      dataTest={`${dataTest}-asset-description-or-title`}
    />
  )

  const renderDescription = (asset: ProgramAsset) => {
    if (asset.description && asset.description.length > MAX_DESCRIPTION_WITHOUT_TOOLTIP) {
      return <Tooltip trigger={renderRawDescription(asset.title, asset.description)}>{asset.description}</Tooltip>
    } else {
      return renderRawDescription(asset.title, asset.description)
    }
  }

  const renderAssets = () => {
    const lastIndex = assets.length - 1
    return assets.map((asset, idx) => (
      <div key={asset.id} className={`${rootClass}__asset`}>
        <div
          className={classNames(`${rootClass}__asset-first`, {
            [`${rootClass}__asset-no-border`]: idx === lastIndex,
          })}
        >
          <Svg
            name={
              asset.type === ProgramAssetType.EMAIL
                ? SvgNames.emailSolid
                : asset.type === ProgramAssetType.SEGMENT
                ? SvgNames.segment
                : SvgNames.uploaded
            }
            type={SvgType.ICON}
          />
          {renderDescription(asset)}
        </div>
      </div>
    ))
  }

  return (
    <Modal isOpen={isOpen} className={classNames(rootClass, className)} data-test={dataTest}>
      <ModalHeader headerType={ModalHeaderType.Form}>{t('New Program From Template')}</ModalHeader>
      <ModalBody>
        <Typography
          type={TextType.BODY_TEXT_LIGHT}
          className={`${rootClass}__body-text`}
          text={t(
            'When you click “Create program,” a new program will be created from this template, and you will be redirected to the Automated Journey Builder.'
          )}
        />
        <Typography
          type={TextType.BODY_TEXT_LIGHT}
          className={`${rootClass}__body-text`}
          text={t(
            'The Automated Journey Builder will highlight what is needed to complete the program. Below is a description of the assets required by this program.'
          )}
        />
        {renderAssets()}
      </ModalBody>
      <ModalFooter>
        <Button buttonType={ButtonType.TERTIARY} onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} onClick={onCreate}>
          {t('Create program')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CreateProgramFromTemplate
