import React, { FC, ReactNode, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import classNames from 'classnames'

import PageContainer from '@components/PageContainer/PageContainer'
import Tab from '@components/TabsAO/TabAO'
import Tabs from '@components/TabsAO/TabsAO'

import './ListingPageWithTabs.css'

interface ListingPageWithTabsProps {
  className?: string
  dataTest?: string
  tabProps: TabProp[]
  hasDynamicHeight?: boolean
}

interface TabProp {
  component: ReactNode
  label: string
  pathName: string
  pathnameId: string
}

const rootClass = 'listing-page-with-tabs'

const ListingPageWithTabs: FC<ListingPageWithTabsProps> = (props: ListingPageWithTabsProps) => {
  const { className = '', tabProps, hasDynamicHeight } = props
  const history = useHistory()

  const [currentTabId, setCurrentTabId] = useState<string>()

  const getIdFromPathname = () => {
    const url = history.location.pathname.split('/')
    return url[url.length - 1]
  }

  useEffect(() => {
    const currentIndex = tabProps.findIndex((currentTab) => currentTab.pathnameId === getIdFromPathname())
    setCurrentTabId(currentIndex > -1 ? tabProps[currentIndex].pathnameId : tabProps[0].pathnameId)
  }, [])

  const items = tabProps.map((tab) => {
    return (
      <Tab index={tab.pathnameId} key={tab.pathnameId}>
        {tab.component}
      </Tab>
    )
  })

  const tabStructure = tabProps.map((tab) => {
    return { ...tab, index: tab.pathnameId, build: '' }
  })

  const tabChange = (tab: string) => {
    const currentIndex = tabProps.findIndex((currentTab) => currentTab.pathnameId === tab)
    history.replace(tabProps[currentIndex].pathName)
  }

  return (
    <PageContainer className={classNames(rootClass, className)} noPadding={true} hasDynamicHeight={hasDynamicHeight}>
      <Tabs defaultValue={currentTabId} onChange={tabChange} childData={tabStructure} className={`${rootClass}__tab-headers`}>
        {items}
      </Tabs>
    </PageContainer>
  )
}

export default ListingPageWithTabs
