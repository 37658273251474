import React, { FC, useState } from 'react'

import classNames from 'classnames'

import { Checkbox } from '@components/Checkbox/Checkbox'
import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import Search, { SearchType } from '@components/Search/Search'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'

import './FilterableList.css'

export interface FilterableListData {
  [key: string]: any
  selected: boolean
  id: number
  disabled?: boolean
  tooltip?: string
  value?: string
}

type Props = {
  accessor: string
  data: FilterableListData[]
  onItemSelection: (selectedItem: FilterableListData) => void
  selectedList?: boolean
  label?: string
  placeholder?: string
  emptyText?: string
  className?: string
  dataTest?: string
  loading?: boolean
}

const rootClass = 'filterable-list'

const FilterableList: FC<Props> = (props: Props) => {
  const {
    data,
    accessor,
    onItemSelection,
    label,
    selectedList = false,
    placeholder = '',
    emptyText = '',
    loading = false,
    className,
    dataTest,
  } = props
  const [term, setTerm] = useState<string>('')
  const [filtered, setFiltered] = useState<boolean>(false)

  const applySearchFilter = () => (filtered ? data.filter((item) => item[accessor].toLowerCase().includes(term.toLowerCase())) : data)

  const handleSearch = (newTerm: string) => {
    setTerm(newTerm)
    setFiltered(newTerm !== '')
  }

  const getItem = (item: FilterableListData) => {
    const isReadOnly = item.disabled && item.selected

    return (
      <div className={`${rootClass}__flex`}>
        <Checkbox
          className={classNames(`${rootClass}__checkbox`, {
            [`${rootClass}__checkbox-readonly`]: isReadOnly,
          })}
          disabled={item.disabled && !isReadOnly}
          checked={item.selected}
          dataTest={`${rootClass}-checkbox`}
          onChange={() => {
            if (!isReadOnly) {
              item.selected = !item.selected
              onItemSelection(item)
            }
          }}
        />
        <Typography text={item[accessor]} type={item.hasError ? TextType.ERROR : TextType.BODY_TEXT_SMALL} lineHeight={LineHeight.TINY} inline />
      </div>
    )
  }

  const renderItem = (item: FilterableListData) => {
    return item.FieldName ? (
      <Tooltip position={'right'} trigger={getItem(item)}>
        {item.FieldName}
      </Tooltip>
    ) : (
      getItem(item)
    )
  }

  const renderList = () => {
    const listItems = applySearchFilter()

    return listItems.length === 0 && filtered ? (
      <EmptyListing headline={emptyText} imgSrc={StaticImageNames.emptyMappedFields} size={EmptyListingSize.SMALL} />
    ) : (
      <>
        <ul className={classNames(`${rootClass}__list`, { [`${rootClass}__list-selected`]: selectedList })} data-test={dataTest}>
          {listItems.map((item: FilterableListData, index: number) => (
            <li key={index} className={classNames(`${rootClass}__list-item`)} data-test={`${rootClass}-item`}>
              {renderItem(item)}
            </li>
          ))}
        </ul>
      </>
    )
  }

  const renderHeader = () => {
    return label ? (
      <div className={classNames(`${rootClass}__title`, { [`${rootClass}__list-selected`]: selectedList })}>
        <Typography text={label} weight={TextWeight.MEDIUM} />
      </div>
    ) : (
      <Search
        incomingValue={''}
        className={`${rootClass}__search`}
        showIcon={false}
        placeholder={placeholder}
        searchType={SearchType.LARGE}
        onChangeHandler={handleSearch}
        canClear
      />
    )
  }

  const renderLoader = () => {
    return (
      <div
        className={classNames(`${rootClass}__flex`, `${rootClass}__flex-justify`, `${rootClass}__list`, {
          [`${rootClass}__list-selected`]: selectedList,
        })}
        data-test={`${rootClass}-loading`}
      >
        <Loader blackout={false} loaderType={LoaderTypes.page} />
      </div>
    )
  }

  return (
    <div className={classNames(rootClass, className)}>
      {renderHeader()}
      {loading ? renderLoader() : renderList()}
    </div>
  )
}

export default FilterableList
