import React, { createRef, FC, ReactNode, RefObject, useCallback, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import BaseTag, { BaseTagProps } from '@components/BaseTag/BaseTag'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'

import './TagWithName.css'

export type TagWithNameProps = BaseTagProps & {
  children?: ReactNode
  disabled?: boolean
  name: string
  tagNameRef?: RefObject<HTMLDivElement>
}

interface State {
  animationSpeed: number
  hasEllipsis: boolean
  hover: boolean
  tagNameWidth: number
  tagWidth: number
}

const rootClass = 'tag-with-name'
export const MARGIN = 8
const MAX_WIDTH = 100

const TagWithName: FC<TagWithNameProps> = (props: TagWithNameProps) => {
  const { children, disabled = false, name, onClick, onMouseEnter, tagNameRef = createRef<HTMLDivElement>(), className, dataTest = rootClass } = props
  const [state, setState] = useState<State>({
    animationSpeed: 1500,
    hasEllipsis: false,
    hover: false,
    tagNameWidth: 0,
    tagWidth: 0,
  })
  const { animationSpeed, hasEllipsis, hover, tagNameWidth, tagWidth } = state
  const mustScroll = hover && hasEllipsis && tagWidth - MARGIN * 2 < tagNameWidth
  const baseStyles = {
    textOverflow: hover ? 'unset' : 'ellipsis',
    transition: `color 0.05s linear, margin ${animationSpeed}ms ${hover ? 'linear' : ''}`,
  }
  const inlineStyles = mustScroll
    ? { ...baseStyles, marginLeft: `${tagWidth - tagNameWidth - MARGIN - 2}px`, paddingRight: `${MARGIN}px` }
    : { ...baseStyles }

  const tagRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (hover && onMouseEnter) {
      onMouseEnter()
    }
    if (tagNameRef.current) {
      const { scrollWidth, offsetWidth } = tagNameRef.current
      setState((state) => ({ ...state, hasEllipsis: scrollWidth > offsetWidth }))
    }
  }, [hover])

  const handleMouseEnter = useCallback(() => {
    if (tagNameRef.current && tagRef.current) {
      const tagNameWidth = tagNameRef.current.scrollWidth
      const tagWidth = tagRef.current.clientWidth
      const animationSpeed = tagNameWidth * 12
      setState((state) => ({ ...state, animationSpeed, hover: true, tagNameWidth, tagWidth }))
    }
  }, [tagRef, tagNameRef])

  const handleMouseLeave = useCallback(
    () => setState((state) => ({ ...state, animationSpeed: 1500, hover: false, tagNameWidth: 0, tagWidth: 0 })),
    []
  )

  return (
    <BaseTag
      {...props}
      className={classNames(rootClass, className, { [`${rootClass}__must-scroll`]: tagRef.current && tagRef.current.clientWidth >= MAX_WIDTH })}
      dataTest={dataTest}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
      reference={tagRef}
    >
      <Typography
        className={classNames(`${rootClass}__name`, 'ellip', { [`${rootClass}__name--selected`]: props.isSelected })}
        inline
        lineHeight={LineHeight.MEDIUM_SMALL}
        reference={tagNameRef}
        style={inlineStyles}
        text={name}
        type={disabled ? TextType.BODY_TEXT_SMALL_LIGHTER : TextType.BODY_TEXT_SMALL}
        weight={TextWeight.MEDIUM}
      />
      {children}
    </BaseTag>
  )
}

export default TagWithName
