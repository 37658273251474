import { gql } from '@apollo/client'

export default gql`
  query personalizeEmailMessage($msgId: String, $recId: String!, $subject: String, $previewText: String, $htmlOrPlainText: String) {
    personalizeEmailMessage(msgId: $msgId, recId: $recId, subject: $subject, previewText: $previewText, htmlOrPlainText: $htmlOrPlainText) {
      subject
      previewText
      htmlOrPlainText
      senderName
      senderEmail
    }
  }
`
