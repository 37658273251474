import unifiedListFieldMappings from '@graphql/microservices/list/getUnifiedListFieldMappings'
import { GetUnifiedListFieldMappingsQuery, GetUnifiedListFieldMappingsQueryVariables } from '@graphql/types/microservice/list-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

interface getContactOwnerSettingResponse {
  getUnifiedFieldsRequest: () => FetchPromise<GetUnifiedListFieldMappingsQuery>
}

export const useContactOwnerSettingQueries = (): getContactOwnerSettingResponse => {
  const { client: listService } = useMicroserviceClient({ serviceName: MicroserviceClients.LIST })

  const getUnifiedFieldsRequest = async () => {
    return await listService.query<GetUnifiedListFieldMappingsQuery, GetUnifiedListFieldMappingsQueryVariables>({
      fetchPolicy: 'network-only',
      query: unifiedListFieldMappings,
    })
  }

  return {
    getUnifiedFieldsRequest,
  }
}
