import { ApolloClient } from '@apollo/client'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { ColWithTooltip } from '@components/TableV2/utils/tableV2ColumnUtils'
import getOptInStatusByAccountId from '@graphql/microservices/sms-management/getOptInStatusByAccountId'
import { OptInStatus, Query, QueryGetOptInStatusByAccountIdArgs } from '@graphql/types/microservice/sms-management-types'
import { getStandardFormattedDate } from '@utils/date'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'

interface GetOptInsResults {
  client?: ApolloClient<any>
  accountId: string
  page: number
  size: number
  sortDirection: string
  sortColumn: string
  searchTerm: string
}

export const useOptInsResults = (params: GetOptInsResults) => {
  const { client, accountId, page, size, sortDirection, sortColumn, searchTerm } = params
  return useQueryOnMount<Query, QueryGetOptInStatusByAccountIdArgs>(getOptInStatusByAccountId, {
    client,
    fetchPolicy: 'network-only',
    variables: {
      accountId,
      page,
      size,
      sortDirection: sortDirection as any,
      sortColumn: sortColumn as any,
      searchTerm,
      optInState: 'OPT_IN' as any,
    },
  })
}

export const processOptInResults = (data: any): OptInStatus[] => {
  return (
    data?.getOptInStatusByAccountId?.optInStatus?.map((entry: OptInStatus) => {
      const contactName = entry.contactName !== null ? entry.contactName : ''
      const optInDate = getStandardFormattedDate(entry.optInDate as unknown as number, true)
      const optInSource = entry.optInSource === 'MARKETING_LIST' ? 'Marketing List' : entry.optInSource
      return { ...entry, contactName, optInDate, optInSource }
    }) || []
  )
}

export const optInsTableColumns: ColumnDefWithAdditionalProps<OptInStatus>[] = [
  {
    header: 'Name',
    accessorKey: 'contactName',
    textAlign: 'left',
    cell: (cell) => ColWithTooltip({ cell }),
    sortingFn: 'caseInsensitive',
    padding: { right: 12 },
  },
  {
    header: 'List/Segment',
    accessorKey: 'listName',
    textAlign: 'left',
    maxSize: 168,
    sortingFn: 'caseInsensitive',
    padding: { left: 0, right: 12 },
    cell: (cell) => ColWithTooltip({ cell }),
  },
  {
    header: 'Phone Number',
    accessorKey: 'phoneNumber',
    textAlign: 'left',
    maxSize: 144,
    padding: { left: 0, right: 12 },
    enableLongContent: true,
  },
  {
    header: 'Opt-In Source',
    accessorKey: 'optInSource',
    textAlign: 'left',
    maxSize: 120,
    padding: { left: 0, right: 12 },
    enableLongContent: true,
    cell: (cell) => ColWithTooltip({ cell }),
  },
  {
    header: 'Opt-In Date',
    accessorKey: 'optInDate',
    textAlign: 'left',
    maxSize: 184,
    sortingFn: 'statusDateTime',
    padding: { left: 0 },
    enableLongContent: true,
  },
]
