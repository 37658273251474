export const savedRowsCopyInfo = [
  { text: 'SavedRows.Copy.Info.header' },
  { text: 'SavedRows.Copy.Info.footer' },
  { text: 'SavedRows.Copy.Info.form' },
  { text: 'SavedRows.Copy.Info.signature' },
  { text: 'SavedRows.Copy.Info.webinar' },
  {
    text: 'SavedRows.Copy.Info.assets',
    subInfo: [
      { text: 'SavedRows.Copy.Info.assets.media' },
      { text: 'SavedRows.Copy.Info.assets.landingPage' },
      { text: 'SavedRows.Copy.Info.assets.optout' },
      { text: 'SavedRows.Copy.Info.assets.view' },
    ],
  },
]
