import React, { FC, useContext } from 'react'

import classNames from 'classnames'

import { useApolloClient } from '@apollo/client'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'
import stepHasActiveContact from '@graphql/queries/stepHasActiveContact'
import { ProgramErrors } from '@graphql/types/query-types'
import { UpgradeManagerContext } from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'
import { Direction, ProgramStepType, Step } from '@utils/program/program.constants'

import { EditState, EditStateAction } from '../../ProgramFlow'
import { ProgramFlowStepInterface } from '../../programFlowUtil'
import ProgramStepIcon from '../ProgramStepIcon/ProgramStepIcon'

import './programFlowStep.css'

const rootClass = 'program-flow-step'

export interface Props {
  step: ProgramFlowStepInterface
  programUrlId?: string
  stepErrors?: ProgramErrors[]
  addNewStep(step: Step, direction?: Direction): void
  editStep(step: Step): void
  editState?: EditState
  changeEditState(editState: EditState): void
  copyStep(parentStep: Step, direction?: Direction): void
  moveStep(parentStep: Step, direction?: Direction): void
  updateStep(step: Step): void
  aOContactsSource: boolean
  drawGoTo(stepId: string): void
  deleteGoTo(): void
  setCurrentTab: (newTab: number) => void
  dataTest?: string
  isViewOnly: boolean
}

export function renderPluses(props: Props, unavailable: string, t: Function) {
  const { step, addNewStep, isViewOnly, copyStep, moveStep, editState } = props
  if ((step.pluses?.length ?? 0) > 0) {
    return step.pluses?.map((plus) => {
      return (
        <button
          id={plus.id}
          data-test={`program-step-plus-${step.id}-${plus.yes}`}
          key={plus.id}
          className={classNames(`${rootClass}__plus`, {
            [`${rootClass}__plus--view-only`]: isViewOnly,
          })}
          style={{
            left: `${plus.left - 9.5}px`,
            top: `${plus.top - 9.5}px`,
          }}
          title={isViewOnly ? unavailable : t('Add new step')}
          onClick={() => {
            if (isViewOnly || editState?.action === EditStateAction.GOTO) return
            if (editState?.action === EditStateAction.COPY) {
              copyStep(step.step, plus.branchDirection)
            } else if (editState?.action === EditStateAction.MOVE) {
              moveStep(step.step, plus.branchDirection)
            } else {
              addNewStep(step.step, plus.branchDirection)
            }
          }}
        >
          {!isViewOnly && <Svg name={SvgNames.plus} />}
        </button>
      )
    })
  }
  return null
}

interface IconSet {
  goTo?: boolean
  exit?: boolean
  edit?: boolean
  copy?: boolean
  delete?: boolean
  changeGoTo?: boolean
  move?: boolean
  viewOnly?: boolean
}

export function stepCanGoTo(step: Step) {
  return step.stepType !== ProgramStepType.GOTO && step.stepType !== ProgramStepType.EXIT && step.stepType !== ProgramStepType.START
}

export function getIconSets(step: Step, isViewOnly: boolean): IconSet | null {
  if (step.stepType === ProgramStepType.START) {
    return null
  } else if (isViewOnly) {
    return step.stepType === ProgramStepType.EXIT ? null : { viewOnly: true }
  } else if (step.stepType === ProgramStepType.GOTO) {
    return {
      exit: true,
      edit: true,
      changeGoTo: true,
    }
  } else if (step.stepType === ProgramStepType.EXIT) {
    return {
      goTo: true,
    }
  } else if (step.stepType === ProgramStepType.BRANCH) {
    return {
      edit: true,
      copy: true,
      delete: true,
    }
  } else if (step.stepType === ProgramStepType.SMS) {
    return {
      edit: true,
      copy: false,
      delete: true,
    }
  }
  return {
    edit: true,
    copy: true,
    delete: true,
    move: true,
  }
}

export const ProgramFlowStep: FC<Props> = (props: Props) => {
  const {
    programUrlId,
    step,
    stepErrors,
    isViewOnly,
    aOContactsSource,
    changeEditState,
    dataTest = 'program-flow-step',
    editState,
    updateStep,
    drawGoTo,
    deleteGoTo,
  } = props

  const { t } = useTranslation()

  const client = useApolloClient()

  const {
    values: { currentStep },
  } = useContext(UpgradeManagerContext)

  const isStart = step.step.stepType === ProgramStepType.START
  const isExit = step.step.stepType === ProgramStepType.EXIT
  const hasError = stepErrors?.find((stepError) => stepError.id == step.step.stepId)

  const editStep = () => {
    if (isStart || isExit) {
      return
    }
    props.editStep(step.step)
  }

  const renderStep = () => {
    return isStart ? (
      <Tooltip
        trigger={
          <button
            onClick={() => props.setCurrentTab(0)}
            data-test={`${dataTest}-edit-button`}
            className={classNames(`${rootClass}__button`)}
            title={step.step.displayName}
          >
            <div tabIndex={-1} className={`${rootClass}__name`}>
              <label className={`${rootClass}__text`}>{step.step.displayName}</label>
            </div>
          </button>
        }
        position={'right'}
        align={'center'}
      >
        {t('Program settings')}
      </Tooltip>
    ) : (
      <button
        onClick={editStep}
        data-test={`${dataTest}-edit-button`}
        className={classNames(`${rootClass}__button`, {
          [`${rootClass}__button--exit-step`]: isExit,
        })}
        title={step.step.displayName}
      >
        <div tabIndex={-1} className={`${rootClass}__name`}>
          <div className={`${rootClass}__step-title`}>
            {hasError && <Svg name={SvgNames.error} type={SvgType.ICON} />}
            {!isStart && <label className={`${rootClass}__letter`}>{step.step.letter}</label>}
          </div>
          <label className={`${rootClass}__text`}>{step.step.displayName}</label>
        </div>
        {step.step.stepType === ProgramStepType.BRANCH && (
          <>
            <span className={`${rootClass}__no`}>{t('NO')}</span>
            <span className={`${rootClass}__yes`}>{t('YES')}</span>
          </>
        )}
      </button>
    )
  }

  const iconSets = getIconSets(step.step, isViewOnly)
  const canGoTo = stepCanGoTo(step.step)
  const unavailable = t('Unavailable while program is running')

  let isConvertingToGoTo = false
  if (editState?.action === EditStateAction.GOTO) {
    isConvertingToGoTo = editState?.step.stepId === step.step.stepId
  }

  return (
    <>
      <div
        data-test={dataTest}
        className={rootClass}
        style={{
          left: `${step.left}px`,
          top: `${step.top}px`,
          width: `${step.width}px`,
          height: `${step.height}px`,
        }}
      >
        <div
          id={step.id}
          data-test={`${dataTest}-${step.id}`}
          className={classNames(`${rootClass}__node`, {
            [`${rootClass}__node--start`]: isStart,
            [`${rootClass}__node--new`]: step.step.isNew,
            [`${rootClass}__node--can-go-to`]: canGoTo,
            [`${rootClass}__node--no-go-to`]: !canGoTo && !isConvertingToGoTo,
            [`${rootClass}__node--is-go-to`]: isConvertingToGoTo,
            [`${rootClass}__button-error`]: hasError,
            [`${rootClass}__node-selected`]: currentStep?.stepId && step.id.endsWith(currentStep?.stepId),
          })}
          onFocus={() => {
            if (editState && editState.action === EditStateAction.GOTO && canGoTo) {
              drawGoTo(step.id)
            }
          }}
          onBlur={() => {
            if (editState && editState.action === EditStateAction.GOTO && canGoTo) {
              deleteGoTo()
            }
          }}
          onMouseOver={() => {
            if (editState && editState.action === EditStateAction.GOTO && canGoTo) {
              drawGoTo(step.id)
            }
          }}
          onMouseOut={() => {
            if (editState && editState.action === EditStateAction.GOTO && canGoTo) {
              deleteGoTo()
            }
          }}
        >
          <div className={`${rootClass}__cover`} />
          <button
            className={`${rootClass}__action-button`}
            data-test={`${dataTest}-action-button`}
            onClick={() => {
              if (
                editState &&
                editState.action === EditStateAction.GOTO &&
                step.step.stepType !== ProgramStepType.GOTO &&
                step.step.stepType !== ProgramStepType.EXIT
              ) {
                deleteGoTo()
                const newStep = {
                  ...editState.step,
                  displayName: t('Go to Another Step'),
                  goToStepId: step.step.stepId,
                  stepType: ProgramStepType.GOTO,
                }
                updateStep(newStep)
              }
            }}
          />
          {iconSets !== null && (
            <div
              className={classNames(`${rootClass}__icons`, {
                [`${rootClass}__icons--base`]: Object.keys(iconSets).length <= 3,
                [`${rootClass}__icons--shifted`]: Object.keys(iconSets).length > 3,
              })}
            >
              {iconSets.viewOnly && (
                <div className={classNames(`${rootClass}__icon`, `${rootClass}__icon--view-only`)}>
                  <button
                    title={t('View step detail')}
                    data-test={`${dataTest}-view-only-button`}
                    onClick={editStep}
                    className={`${rootClass}__icon--view-only`}
                  >
                    <Svg name={SvgNames.show} />
                  </button>
                </div>
              )}
              {iconSets.move && (
                <div className={`${rootClass}__icon`}>
                  <button
                    title={isViewOnly ? unavailable : t('Move Step')}
                    data-test={`${dataTest}-move-button`}
                    onClick={() => {
                      if (isViewOnly) return
                      changeEditState({
                        step: step.step,
                        action: EditStateAction.MOVE,
                      })
                    }}
                  >
                    <Svg name={SvgNames.moveStep} />
                  </button>
                </div>
              )}
              {iconSets.copy && (
                <div className={`${rootClass}__icon`}>
                  <button
                    title={isViewOnly ? unavailable : t('Copy Step')}
                    data-test={`${dataTest}-copy-button`}
                    onClick={() => {
                      if (isViewOnly) return
                      changeEditState({
                        step: step.step,
                        action: EditStateAction.COPY,
                      })
                    }}
                  >
                    <Svg name={SvgNames.copyStep} />
                  </button>
                </div>
              )}
              {iconSets.delete && (
                <div className={`${rootClass}__icon`}>
                  <button
                    data-test={`${dataTest}-delete-button`}
                    title={isViewOnly ? unavailable : t('Delete Step')}
                    onClick={() => {
                      if (isViewOnly) return
                      client
                        .query({
                          query: stepHasActiveContact,
                          fetchPolicy: 'network-only',
                          variables: { programId: programUrlId, stepId: step.id.split('program-flow-node-')[1] },
                        })
                        .then(({ data }) => {
                          const { stepHasActiveContact } = data
                          if (stepHasActiveContact) {
                            changeEditState({
                              step: step.step,
                              action: EditStateAction.PREVENT_DELETE,
                            })
                          } else {
                            changeEditState({
                              step: step.step,
                              action: EditStateAction.DELETE,
                            })
                          }
                        })
                    }}
                  >
                    <Svg name={SvgNames.delete} />
                  </button>
                </div>
              )}
              {iconSets.changeGoTo && (
                <div className={`${rootClass}__icon`}>
                  <button
                    title={isViewOnly ? unavailable : t('Edit Destination')}
                    data-test={`${dataTest}-change-go-to-button`}
                    onClick={() => {
                      if (isViewOnly) return
                      changeEditState({
                        step: step.step,
                        action: EditStateAction.GOTO,
                      })
                    }}
                  >
                    <Svg name={SvgNames.arrowEmpty} />
                  </button>
                </div>
              )}
              {iconSets.goTo && (
                <div className={`${rootClass}__icon`}>
                  <button
                    title={isViewOnly ? unavailable : t('Change to Go To Step')}
                    data-test={`${dataTest}-go-to-button`}
                    onClick={() => {
                      if (isViewOnly) return
                      changeEditState({
                        step: step.step,
                        action: EditStateAction.GOTO,
                      })
                    }}
                  >
                    <Svg name={SvgNames.goto} />
                  </button>
                </div>
              )}
              {iconSets.exit && (
                <div className={`${rootClass}__icon`}>
                  <button
                    data-test={`${dataTest}-exit-button`}
                    title={isViewOnly ? unavailable : t('Change to Exit Step')}
                    onClick={() => {
                      if (isViewOnly) return
                      changeEditState({
                        step: {
                          ...step.step,
                          displayName: t('Exit the Program'),
                        },
                        action: EditStateAction.CONVERTTOEXIT,
                      })
                    }}
                  >
                    <Svg name={SvgNames.exit} />
                  </button>
                </div>
              )}
            </div>
          )}
          {!isStart && <ProgramStepIcon step={step.step} aOContactsSource={aOContactsSource} />}
          {renderStep()}
        </div>
      </div>
      {renderPluses(props, unavailable, t)}
    </>
  )
}

export default ProgramFlowStep
