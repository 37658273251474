import React, { useCallback } from 'react'

import { useApolloClient, useMutation } from '@apollo/client'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import addUrlGroup from '@graphql/mutations/addUrlGroup'
import deleteUrlGroup from '@graphql/mutations/deleteUrlGroup'
import editUrlGroup from '@graphql/mutations/editUrlGroup'
import {
  AddUrlGroupMutation,
  AddUrlGroupMutationVariables,
  EditUrlGroupMutation,
  EditUrlGroupMutationVariables,
  DeleteUrlGroupMutation,
  DeleteUrlGroupMutationVariables,
} from '@graphql/types/mutation-types'
import { UrlGroup } from '@graphql/types/query-types'
import { useLandingPageContext } from '@src/pages/Content/LandingPages/LandingPagesManager/context/LandingPagesManager.context'
import { logNewRelicError } from '@utils/new-relic.utils'

const useURLGroupActions = (): {
  loading: boolean
  handleAdd: (name: string) => void
  handleEdit: (name: string, groupId: string) => void
  handleDelete: (group: UrlGroup) => void
} => {
  const { t } = useTranslation()
  const client = useApolloClient()
  const {
    landingPage: { id },
    updateLandingPage,
    setToastStatus,
    refetchAccountSlugs,
  } = useLandingPageContext()

  const [addGroup, { loading: loadingAdd }] = useMutation<AddUrlGroupMutation, AddUrlGroupMutationVariables>(addUrlGroup, {
    client,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const [editGroup, { loading: loadingEdit }] = useMutation<EditUrlGroupMutation, EditUrlGroupMutationVariables>(editUrlGroup, {
    client,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const [deleteGroup, { loading: loadingDelete }] = useMutation<DeleteUrlGroupMutation, DeleteUrlGroupMutationVariables>(deleteUrlGroup, {
    client,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const handleSuccess = useCallback(
    ({ action, urlGroups, transProps }: { action: 'Add' | 'Edit' | 'Delete'; urlGroups: (UrlGroup | undefined)[]; transProps?: any }) => {
      // First group always is default one
      urlGroups.shift()
      updateLandingPage({ urlGroups })
      const statusMessage =
        action === 'Delete' ? (
          <Typography text="Delete.URL.Group.Success.Message" {...transProps} className="toast__text" />
        ) : (
          t(`${action}.URL.Group.Success.Message`, transProps)
        )

      setToastStatus({
        showStatus: true,
        statusMessage,
        successStatus: true,
      })
    },
    [updateLandingPage]
  )

  const handleError = useCallback(
    ({ action, errorMessage, transProps }: { action: 'Add' | 'Edit' | 'Delete'; errorMessage?: string; transProps?: any }) => {
      const statusMessage =
        action === 'Delete' ? (
          <Typography text="Delete.URL.Group.Error.Message" {...transProps} className="toast__text" />
        ) : (
          errorMessage ?? t(`${action}.URL.Group.Error.Message`, transProps)
        )

      setToastStatus({
        showStatus: true,
        title: errorMessage ? t('Error!') : undefined,
        statusMessage,
        successStatus: false,
      })
    },
    []
  )

  const handleAdd = useCallback<(name: string) => void>(
    (name) => {
      if (!name || !id) {
        return
      }
      addGroup({
        variables: { pageId: id, name },
      })
        .then(({ data, errors }) => {
          if (data?.addUrlGroup && Array.isArray(data.addUrlGroup)) {
            handleSuccess({ action: 'Add', urlGroups: data.addUrlGroup })
          } else {
            const error = Array.isArray(errors) ? errors[0] : undefined
            handleError({ action: 'Add', errorMessage: error?.message })
          }
        })
        .catch((error) => {
          handleError({ action: 'Add', errorMessage: error?.message })
          logNewRelicError(error)
        })
    },
    [id, handleSuccess, handleError]
  )

  const handleEdit = useCallback<(name: string, groupId: string) => void>(
    (name, groupId) => {
      if (!name || !groupId || !id) {
        return
      }
      editGroup({
        variables: { pageId: id, name, urlId: groupId },
      })
        .then(({ data, errors }) => {
          if (data?.editUrlGroup && Array.isArray(data.editUrlGroup)) {
            handleSuccess({ action: 'Edit', urlGroups: data.editUrlGroup })
          } else {
            const error = Array.isArray(errors) ? errors[0] : undefined
            handleError({ action: 'Edit', errorMessage: error?.message })
          }
        })
        .catch((error) => {
          handleError({ action: 'Edit', errorMessage: error?.message })
          logNewRelicError(error)
        })
    },
    [id, handleSuccess, handleError]
  )

  const handleDelete = useCallback<(group: UrlGroup) => void>(
    (group) => {
      if (!id) {
        return
      }
      const { name } = group
      const transProps = { tagProps: { b: { weight: TextWeight.BOLD, inline: true } }, values: { name } }
      deleteGroup({
        variables: { pageId: id, urlId: group.id },
      })
        .then(({ data }) => {
          if (data?.deleteUrlGroup && Array.isArray(data.deleteUrlGroup)) {
            handleSuccess({ action: 'Delete', urlGroups: data.deleteUrlGroup, transProps })
            refetchAccountSlugs()
          } else {
            handleError({ action: 'Delete', transProps })
          }
        })
        .catch((error) => {
          handleError({ action: 'Delete', transProps })
          logNewRelicError(error)
        })
    },
    [id, handleSuccess, handleError]
  )

  return { loading: loadingAdd || loadingEdit || loadingDelete, handleAdd, handleEdit, handleDelete }
}

export default useURLGroupActions
