import { gql } from '@apollo/client'

export default gql`
  query crmCampaigns {
    crmCampaigns {
      name
      rawName
      externalId
      id
      active
    }
  }
`
