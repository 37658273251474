import React, { ChangeEvent, FC, useContext, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import EditableContainer from '@components/EditableContainer/EditableContainer'
import Radio from '@components/Radio'
import RadioGroup from '@components/RadioGroup'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { rootTranslation } from '@src/pages/RecommendedSegments/components/SegmentsInsightsSettingsContainer/SegmentsInsightsSettingsContainer'
import { SegmentationInsightsTimeRange } from '@src/pages/RecommendedSegments/utils/RecommendedSegments.constants'
import { RecommendedSegmentsContext } from '@src/pages/RecommendedSegments/utils/RecommendedSegments.context'

import './SegmentsInsightsTimeSettings.css'

interface SegmentsInsightsTimeSettingsProps {
  className?: string
  dataTest?: string
}

const rootClass = 'segments-insights-time-settings'

const filterValueByTimeRange: { [key in SegmentationInsightsTimeRange]: number } = {
  [SegmentationInsightsTimeRange.ALL_TIME]: 0,
  [SegmentationInsightsTimeRange.LAST_SEMESTER]: 180,
  [SegmentationInsightsTimeRange.LAST_QUARTER]: 90,
  [SegmentationInsightsTimeRange.LAST_MONTH]: 30,
}

const DEFAULT_TIME_RANGE = SegmentationInsightsTimeRange.ALL_TIME

const SegmentsInsightsTimeSettings: FC<SegmentsInsightsTimeSettingsProps> = (props: SegmentsInsightsTimeSettingsProps) => {
  const { dataTest = rootClass, className = '' } = props

  const { saveSettings } = useContext(RecommendedSegmentsContext)

  const {
    values: { settings },
  } = useContext(RecommendedSegmentsContext)

  const { timeRange = DEFAULT_TIME_RANGE } = { ...settings }

  const [selectedTimeRange, setSelectedTimeRange] = useState(timeRange)

  const initialTimeRangeRef = useRef(timeRange)

  const { t } = useTranslation()

  useEffect(() => {
    setSelectedTimeRange(timeRange)
  }, [timeRange])

  const onRadioChange = ({ target: { id } }: ChangeEvent<HTMLInputElement>) => {
    if (id !== selectedTimeRange) {
      setSelectedTimeRange(id as SegmentationInsightsTimeRange)
    }
  }

  const onSave = async (timeRange?: SegmentationInsightsTimeRange) => {
    initialTimeRangeRef.current = timeRange ?? selectedTimeRange
    saveSettings({
      filter: filterValueByTimeRange[timeRange ?? selectedTimeRange],
    })
    if (timeRange) {
      setSelectedTimeRange(timeRange)
    }
  }

  const onCancel = () => {
    setSelectedTimeRange(timeRange)
  }

  const onReset = () => onSave(DEFAULT_TIME_RANGE)

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <EditableContainer onSave={onSave} onCancel={onCancel} onReset={onReset} saveDisabled={initialTimeRangeRef.current === selectedTimeRange}>
        {(isEditing) => (
          <>
            <Typography
              text={t(`${rootTranslation}.TimeRange.Header`)}
              type={TextType.BODY_TEXT_LARGE}
              weight={TextWeight.MEDIUM}
              lineHeight={LineHeight.MEDIUM_LARGE}
            />
            <Typography className={`${rootClass}__subheader`} text={t(`${rootTranslation}.TimeRange.Subheader`)} />
            {isEditing ? (
              <RadioGroup verticalLayout>
                {Object.values(SegmentationInsightsTimeRange).map((timeRange) => (
                  <Radio
                    key={`radio-${timeRange}`}
                    dataTest={`${dataTest}-${timeRange}`}
                    checked={selectedTimeRange === timeRange}
                    id={timeRange}
                    label={t(timeRange)}
                    onChange={onRadioChange}
                  />
                ))}
              </RadioGroup>
            ) : (
              <Typography text={t(timeRange)} type={TextType.BODY_TEXT_LIGHT} />
            )}
          </>
        )}
      </EditableContainer>
    </div>
  )
}

export default SegmentsInsightsTimeSettings
