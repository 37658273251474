import { gql } from '@apollo/client'

export default gql`
  query getLogos($pageNumber: Int, $pageSize: Int, $search: String, $sortColumn: String, $sortDirection: Direction) {
    getLogos(pageNumber: $pageNumber, pageSize: $pageSize, search: $search, sortColumn: $sortColumn, sortDirection: $sortDirection) {
      logos {
        id
        title
        url
        alt
        target
        tooltip
        isDefault
        isLocal
        duplicated
        dimensions
        size
      }
      totalRecords
    }
  }
`
