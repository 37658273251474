import React, { FC } from 'react'

import { ApolloError } from '@apollo/client'
import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import { ButtonSize } from '@components/Button/Button'
import { renderLoader } from '@components/Loader/Loader'
import PageContainer from '@components/PageContainer'
import PageError from '@components/PageError'
import PageHeader from '@components/PageHeader'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import StatusToast from '@components/StatusToast/StatusToast'
import Svg, { SvgNames, SvgType } from '@components/Svg/index'
import Tabs from '@components/TabsAO/TabsAO'
import { rootContext, useTranslation } from '@const/globals'
import InitialMessageSending from '@src/pages/sms/initialMessage/components/InitialMessageSending/InitialMessageSending'
import { Message } from '@utils/sms.utils'

import DraftMessagesTable from './components/DraftMessagesTable/DraftMessagesTable'
import ScheduledMessages from './components/ScheduledMessages/ScheduledMessages'
import SentMessageTable from './components/SentMessageTable/SentMessageTable'
import { SMSListingStatusToast } from './SMSListingContainer'

interface SMSListingProps {
  messages: Message[]
  closeStatus: () => void
  onDeleteDrafts: (msgIds: string[]) => void
  onCancelSends: (msgIds: string[]) => void
  loading?: boolean
  isInitialMessageInSending?: boolean
  tab?: string
  onTabChange?: (tab: string) => void
  smsListingStatusToast: SMSListingStatusToast
  pageError?: ApolloError
  dataTest?: string
  totalCount: number
  pageSize: number
  pageIndex: number
  fetchData: (pageIndex: number, pageSize: number) => void
}

type SMSListingAllProps = SMSListingProps

const SMSListing: FC<SMSListingAllProps> = (props: SMSListingAllProps) => {
  const {
    loading = false,
    isInitialMessageInSending,
    pageError,
    messages,
    smsListingStatusToast,
    closeStatus,
    onDeleteDrafts,
    onCancelSends,
    tab,
    onTabChange,
    totalCount,
    pageIndex,
    pageSize,
    fetchData,
  } = props
  const { t } = useTranslation()

  const { showStatus, statusMessage, successStatus } = smsListingStatusToast

  const listingTabs = [
    {
      index: 'sent',
      label: t('Sent Messages'),
      content: (
        <SentMessageTable
          messages={messages}
          loading={loading}
          fetchData={fetchData}
          totalCount={totalCount}
          pageSize={pageSize}
          pageIndex={pageIndex}
        />
      ),
    },
    {
      index: 'scheduled',
      label: t('Scheduled'),
      content: (
        <ScheduledMessages
          messages={messages}
          loading={loading}
          fetchData={fetchData}
          totalCount={totalCount}
          pageSize={pageSize}
          pageIndex={pageIndex}
          onCancelSends={onCancelSends}
        />
      ),
    },
    {
      index: 'drafts',
      label: t('Drafts'),
      content: (
        <DraftMessagesTable
          messages={messages}
          loading={loading}
          onDeleteDrafts={onDeleteDrafts}
          fetchData={fetchData}
          totalCount={totalCount}
          pageSize={pageSize}
          pageIndex={pageIndex}
        />
      ),
    },
  ]

  if (pageError) {
    return (
      <PageContainer>
        <PageError message={pageError.message} center />
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      {loading && renderLoader('loader--white-background')}
      {!loading && showStatus && <StatusToast isSuccess={successStatus} message={statusMessage} closeStatus={closeStatus} />}
      {!loading && isInitialMessageInSending && <InitialMessageSending />}
      <PositionContainer>
        <PageHeader primaryText={t('SMS Messages')} leftContent>
          <Button
            buttonType={ButtonType.PRIMARY}
            iconPosition={ButtonIconPosition.LEFT}
            buttonSize={ButtonSize.LARGE}
            isLink
            to={`${rootContext}/sms/new/edit`}
          >
            <Svg name={SvgNames.plus} type={SvgType.ICON} />
            {t('Create SMS message')}
          </Button>
        </PageHeader>
        <Tabs childData={listingTabs} defaultValue={tab} onChange={onTabChange} />
      </PositionContainer>
    </PageContainer>
  )
}

export default SMSListing
