import { gql } from '@apollo/client'

export default gql`
  query getAllLogos {
    getAllLogos {
      status
      errorMsg
      items {
        id
        title
        url
        alt
        target
        tooltip
        isDefault
        isLocal
        duplicated
        dimensions
        size
      }
    }
  }
`
