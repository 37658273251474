import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import { Checkbox } from '@components/Checkbox/Checkbox'
import FormRow from '@components/FormRow'
import Pill from '@components/Pill/Pill'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './ContentWarning.css'

interface Props {
  className?: string
  customAction?: ReactNode
  dataTest?: string
  hideCheckbox?: boolean
  asset: ItemAsset
  title: string
  onDeleteChange?: (objectId: string, deleteDecision: boolean) => void
  customCautionInfoMessage?: ReactNode
  checkboxLabel?: string
}

interface Asset {
  isCritical: boolean
  name: string
  path: string
  state: string
  type: string
}

export enum ItemAssetType {
  AUTOMATION = 'AUTOMATION',
  EMAIL = 'EMAIL',
  FORM = 'FORM',
  LIST_MAINTENANCE = 'LIST_MAINTENANCE',
}

export enum ItemAssetState {
  DRAFT = 'DRAFT',
  RUNNING = 'RUNNING',
  SCHEDULED = 'SCHEDULED',
  STOPPED = 'STOPPED',
  MANUAL = 'MANUAL',
  NONE = 'NONE',
}

export interface ItemAsset {
  body: Asset[]
  objectId: string
}

const rootClass = 'content-warning'

const getSectionName: { [key in ItemAssetType]: string } = {
  [ItemAssetType.AUTOMATION]: 'AUTOMATIONS',
  [ItemAssetType.EMAIL]: 'EMAILS',
  [ItemAssetType.FORM]: 'FORMS',
  [ItemAssetType.LIST_MAINTENANCE]: 'LIST MAINTENANCE',
}

const getState: { [key in ItemAssetState]: string } = {
  [ItemAssetState.DRAFT]: 'Draft',
  [ItemAssetState.MANUAL]: 'Manual',
  [ItemAssetState.RUNNING]: 'Running',
  [ItemAssetState.SCHEDULED]: 'Scheduled',
  [ItemAssetState.STOPPED]: 'Paused',
  [ItemAssetState.NONE]: '',
}

const ContentWarning: FC<Props> = (props: Props) => {
  const { asset, customAction, hideCheckbox = false, title, onDeleteChange, customCautionInfoMessage, checkboxLabel } = props
  const { t } = useTranslation()

  const [shouldDelete, setDelete] = useState(false)

  const { sections, containsCritical } = useMemo(
    () =>
      asset.body.reduce(
        (assets: { sections: { [key: string]: Asset[] }; containsCritical: boolean }, asset) => ({
          sections: {
            ...assets.sections,
            [asset.type]: [...(assets.sections[asset.type] ?? []), asset] as Asset[],
          },
          containsCritical: assets.containsCritical || asset.isCritical,
        }),
        { sections: {}, containsCritical: false }
      ),
    [asset]
  )

  useEffect(() => onDeleteChange?.(asset.objectId, shouldDelete), [shouldDelete])

  const renderSection = (sectionName: ItemAssetType, itemAsset: Asset[]) => (
    <div key={sectionName} className={`${rootClass}__section`}>
      <Typography text={t(getSectionName[sectionName]) || sectionName} type={TextType.SUB_HEADER} lineHeight={LineHeight.LARGER} />
      {itemAsset.map(({ isCritical, state, name, path }, index) => (
        <FormRow key={`${sectionName}_${index}`} className={`${rootClass}__section-row`}>
          <div className={`${rootClass}__icon-container`}>
            <Svg className={classNames(`${rootClass}__icon`)} name={isCritical ? SvgNames.fail : SvgNames.warningSolidNoFill} type={SvgType.ICON} />
          </div>
          <TextLink className={`${rootClass}__section-row-link`} text={name} onClick={() => window.open(path, `_blank`)} size={TextLinkSize.LARGE} />
          {state !== ItemAssetState.NONE && (
            <Pill
              className={classNames(`${rootClass}__pill`, `${rootClass}__pill-${state.toLowerCase()}`)}
              text={t(getState[state as ItemAssetState])}
            />
          )}
        </FormRow>
      ))}
    </div>
  )

  const renderInfoMessage = () => (
    <div className={`${rootClass}__message-info`}>
      {customCautionInfoMessage ?? (
        <>
          <Typography
            text={t('This segment cannot be deleted because of critical dependent content.')}
            type={TextType.BODY_TEXT_SMALL}
            lineHeight={LineHeight.MEDIUM_SMALL}
          />
          <Typography
            text={t('Disconnect the critical content to delete this segment.')}
            type={TextType.BODY_TEXT_SMALL}
            lineHeight={LineHeight.MEDIUM_SMALL}
          />
        </>
      )}
    </div>
  )

  return (
    <div className={rootClass}>
      <FormRow className={`${rootClass}__header`}>
        <Tooltip
          triggerClassName={classNames(`${rootClass}__header-element`, 'ellip')}
          trigger={<Typography className={'ellip'} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} text={title} />}
        >
          {title}
        </Tooltip>
        {!hideCheckbox && (
          <Checkbox
            checked={shouldDelete}
            label={checkboxLabel ?? t('Delete')}
            onChange={(checked) => setDelete(checked)}
            disabled={containsCritical}
          />
        )}
        {customAction}
      </FormRow>
      {containsCritical && renderInfoMessage()}
      {Object.entries(sections).map(([type, assets]) => renderSection(type as ItemAssetType, assets))}
    </div>
  )
}

export default ContentWarning
