import { RouteComponentProps } from 'react-router'

import Bee from '@beefree.io/sdk'
import {
  IAddOnResponseHTML,
  IAddOnResponseImage,
  IAddOnResponseMixed,
  IAddOnResponseRowAddOn,
  IBeeConfig,
  IMergeTag,
  IPluginFilePicker,
  IPluginModule,
  IPluginModuleParagraph,
  IPluginRow,
  ISpecialLink,
  RowLayoutType,
} from '@beefree.io/sdk/dist/types/bee'
import { EmailComposerRowCategoryDto } from '@graphql/types/microservice/email-management-types'
import { CustomFields } from '@src/pages/EmailComposer/utils/Addons/customAddons.types'
import { IEntityContentJsonExtended, IPluginDisplayConditionExtended } from '@utils/composer/beeEditor/beeEditorTypes'
import { EmailComposerAPI, EmailComposerState } from '@utils/composer/context/EmailComposer.context'
import { EmailComposerRequests } from '@utils/composer/emailComposer/GraphQL/EmailComposerRequests.graphQL'
import { ExtendedMessageType } from '@utils/composer/emailComposer/types'
import { ReactSetStateAction } from '@utils/interface/common'
import { DeepUpdateState, MatchParams } from '@utils/types'

import { EmailEditModalState, EmailModalsState } from '../EmailModals/EmailModals'

export type EmailComposerContainerProps = Omit<RouteComponentProps<MatchParams>, 'history' | 'location'> & {
  isStory?: boolean
  disabledFeatures?: Partial<EmailComposerState['disabledFeatures']>
}

export type ISaveRow = {
  name: string
  category: string
  id: string
}

export interface IAddOnResponseHTMLExtended<CF = Object> extends IAddOnResponseHTML {
  value: {
    html: string
    customFields?: CF
  }
}

export interface IAddOnResponseImageExtended<CF = Object> extends IAddOnResponseImage {
  value: {
    src: string
    alt: string
    href: string
    dynamicSrc: string
    customFields?: CF
  }
}

export type IAddOnResponse = IAddOnResponseImage | IAddOnResponseHTML | IAddOnResponseMixed | IAddOnResponseRowAddOn
export type ISpecialLinkCallback = (data: ISpecialLink | undefined) => void
export type IMergeTagCallback = (data: IMergeTag | undefined) => void
export type ISaveRowCallback = (data: ISaveRow | undefined) => void
export type IAddOnCallback = (data: IAddOnResponse | undefined) => void
export type IFilePickerCallback = (data: IPluginFilePicker | undefined) => void
export type IDisplayConditionCallback = (data: IPluginDisplayConditionExtended | undefined) => void

export type EmailModalArgs = IAddOnResponse &
  AddOnWithContentDialogId & { value?: Record<string, string> & { customFields?: Record<string, string> } }

export type PlainTextRSSResponse = {
  type: 'html'
  plainTextId: number
  value: {
    html: string
    customFields: Pick<CustomFields, 'rssCustomFields'>
  }
}

export interface BeeEditorActionDoneCallbacks {
  onSpecialLinkDone: ISpecialLinkCallback
  onSaveRowDone: ISaveRowCallback
  onMergeTagDone: IMergeTagCallback
  onAddOnDone: IAddOnCallback
  onFilePickerDone: IFilePickerCallback
  onDisplayConditionDone: IDisplayConditionCallback
  onEditRowDone: ISaveRowCallback
  onPreventMultipleLayoutsConfirm: VoidFunction
}

export type AddOnWithContentDialogId = IAddOnResponse & { contentDialogId: string }

// This was autogenerated by feeding response from messageGetJSON.jsp into:
// https://transform.tools/json-to-typescript
// It may not account for all possible fields

export interface ClassicMessageJson {
  content: Content
  logoHTML: string
  stationery: Stationery
  litmusIds?: string[]
  spamIds?: string[]
  testLists: TestList[]
  currentUserEmail: string
  lastModifiedTime: number
  testMessagePrefix?: string
}

interface Content {
  id: string
  approvalRequired: boolean
  header: number
  footer: number
  logo: number
  stationery: string
  blockPaddingTop: string
  blockPaddingLeft: string
  blockPaddingBottom: string
  blockPaddingRight: string
  blockPaddingAdvanced: boolean
  blockSpacing: string
  fontName?: string
  fontSize?: string
  lineHeight: string
  textColor: string
  linkColor: string
  linkUnderline: string
  backgroundColor: string
  innerBackgroundColor: string
  borderWidth: string
  borderColor: string
  borderRadius: string
  disableInlining: boolean
  headerFontName: string
  headerFontSize: string
  headerLineHeight: string
  headerTextColor: string
  headerLinkColor: string
  headerLinkUnderline: string
  headerBackgroundColor: string
  headerPaddingTop: string
  headerPaddingRight: string
  headerPaddingBottom: string
  headerPaddingLeft: string
  headerPaddingAdvanced: boolean
  headerDisableInlining: boolean
  footerFontName: string
  footerFontSize: string
  footerLineHeight: string
  footerTextColor: string
  footerLinkColor: string
  footerLinkUnderline: string
  footerBackgroundColor: string
  footerPaddingTop: string
  footerPaddingRight: string
  footerPaddingBottom: string
  footerPaddingLeft: string
  footerPaddingAdvanced: boolean
  footerDisableInlining: boolean
  title: string
  subject: string
  preview: string
  suppress_dups: boolean
  individualRecipientsSourceIds?: (string | undefined)[]
  overrideFatigueRules: boolean
  subscription_category?: string
  isTransactional: boolean
  isOptInCampaign: boolean
  utmCampaignValue: string
  utmMediumValue: string
  utmTermValue: string
  utmContentValue: string
  utmSourceValue: string
  cmVendorValue: string
  cmCategoryValue: string
  cmPlacementValue: string
  cmItemValue: string
  optOutRedirectUrl: string
  isEnvelopeLocked: boolean
  isSendContacts: boolean
  sendto_lists?: string[]
  suppress_lists?: string[]
  sendtype: string
  senddate: string
  sendtimezone: string
  staggertimezone: string
  staggerdatestart: string
  staggerdateend: string
  adaptivedatestart: string
  adaptivedateend: string
  senderName: string
  senderEmail: string
  sender: string
  replyToName: string
  replyToEmail: string
  replyto: string
  content_type: string
  type: string
  isAutoResponse: boolean
  limit_sends_period: string
  limit_sends_count: string
  isCrmLaunch: boolean
  isTemplateAvailableInEBE: boolean
  plainText: boolean
  layout: Layout
  columns: Column[]
  last_column: number
  last_row: number
  customTextPart: string
}

interface Layout {
  width: number
  responsive: boolean
  fullWidth: boolean
  logoAlignment: string
  messageCentered: boolean
}

interface Column {
  id: string
  width: string
  height: string
  spacing: string
  align: string
  color: string
  section: number
  col: number
  padding: Padding
  borderColor: BorderColor
  borderWidth: BorderWidth
  borderStyle: BorderStyle
  blocks: Block[]
}

interface Padding {
  top: string
  right: string
  bottom: string
  left: string
  advanced: boolean
}

interface BorderColor {
  top: string
  right: string
  bottom: string
  left: string
  advanced: boolean
}

interface BorderWidth {
  top: string
  right: string
  bottom: string
  left: string
  advanced: boolean
}

interface BorderStyle {
  top: string
  right: string
  bottom: string
  left: string
  advanced: boolean
}

interface Block {
  whenMissing?: string
  id: string
  type: string
  choices?: string[]
  selected?: number
  html?: string
  text?: string
  expires?: number
  doc_title?: string
  doc_link_image_id?: string
  link_name?: string
  doc_id?: string
  newForm?: boolean
  urls?: string[]
  form_image?: string
  prefill?: boolean
  form_id?: string
  label?: string
  deploy_id?: string
  condition?: Condition[]
  useHtmlText?: boolean
  refToImageIdMap?: any
  layout?: string
  padding?: string
  size?: string
  style?: string
  title?: string
  align?: string
  icons?: Icon[]
  prompt?: string
  url?: string
  paddingBottom?: string
  lineThickness?: string
  paddingRight?: string
  lineColor?: string
  paddingTop?: string
  paddingLeft?: string
  isPaddingAdvanced?: boolean
  commentLabel?: string
  oneOfN?: boolean
  publicVis?: boolean
  allowComment?: boolean
  accountId?: number
  images?: Image[]
  work_email?: string
  home_phone?: string
  work_city?: string
  website?: string
  work_phone?: string
  last_name?: string
  home_email?: string
  work_zip?: string
  home_zip?: string
  cell_phone?: string
  home_state?: string
  work_street?: string
  home_city?: string
  work_state?: string
  home_country?: string
  work_fax?: string
  company?: string
  work_country?: string
  department?: string
  home_street?: string
  first_name?: string
  instructions?: string
  ampm?: string
  year?: string
  timezone?: string
  durationUnits?: string
  csz?: string
  minute?: string
  duration?: string
  month?: string
  hour?: string
  phone?: string
  event_html?: string
  street?: string
  place?: string
  maplink?: boolean
  day?: string
  event_text?: string
  passcode?: string
  signature_id?: number
}

interface Condition {
  predicate: Predicate[]
  matchType: string
  description: string
  html: string
  text: string
  useHtmlText: boolean
}

interface Predicate {
  field: string
  dateFormat: string
  criteria: string
  type: string
  criteria_max: string
}

interface Icon {
  select: boolean
  shareLink: string
  type: string
  title: string
  imgSrc: string
}

interface Image {
  linkLabel: string
  size: string
  captionAlign: string
  alt: string
  linkURL: string
  caption: string
  description: string
  id: string
  imageAlign: string
  wrapText: boolean
}

interface Stationery {
  id: string
  name: string
  isSystem: boolean
  head: string
  body: string
  bodyNoStyles: string
  styles: string
  headStyles: string
  bodyStyles: string
  bodyAttributes: string
}

interface TestList {
  name: string
  listId: string
  size: string
}

export enum BeeSessionTypes {
  JOIN = 'JOIN',
  START = 'START',
}

export enum AddOnModal {
  EVENT_BLOCK = 'eventBlockHandler',
  GENERATIVE_EMAIL = 'generativeEmailHandler',
  SIGNATURE_BLOCK = 'signatureBlockHandler',
  HEADER_BLOCK = 'emailHeaderBlockHandler',
  FOOTER_BLOCK = 'emailFooterBlockHandler',
  RSS_BLOCK = 'rssBlockHandler',
  WEBINAR_BLOCK = 'webinarBlockHandler',
  POLL_BLOCK = 'pollBlockHandler',
  FORM_BLOCK = 'formBlockHandler',
  MAP_BLOCK = 'mapBlockHandler',
  STICKER_BLOCK = '686279a5-1006-47a2-8d7b-6a69004e18ab',
}

export enum IAddOnResponseTypes {
  IMAGE = 'image',
  HTML = 'html',
  MIXED = 'mixed',
  ROW_ADD_ON = 'rowAddon',
}

export const isParagraphModule = (module: IPluginModule): module is IPluginModuleParagraph => {
  return !!(module as IPluginModuleParagraph)?.descriptor?.paragraph
}

export interface ExtractSessionUserFields {
  username: string
  userId: string
}

export interface StartBeeSession {
  sessionId?: string
  beeFinalConfig: IBeeConfig
  templateJsonRef: React.MutableRefObject<IEntityContentJsonExtended | undefined>
  beeEditorRef: React.MutableRefObject<Bee | undefined>
}

interface User {
  userId: string
  username: string
  userColor: string
}

interface Change {
  type: 'USER_LEFT' | 'USER_JOINED'
  value: User
}

interface Users {
  [key: string]: User[]
}

export interface OnSessionChange {
  change: Change
  sessionData: Users
}

export type CoEditingInfoRef = { userIds: string[]; userNames: string[] }

export interface RenderAddOnModal {
  args: EmailModalArgs
  messageType: ExtendedMessageType
  templateJsonRef: React.MutableRefObject<IEntityContentJsonExtended<Object> | undefined>
  actionDoneCallbacks: React.MutableRefObject<BeeEditorActionDoneCallbacks>
  updateModal: EmailComposerAPI['updateModal']
  updateModalState: DeepUpdateState<EmailModalsState>
  updateEditModalState: DeepUpdateState<EmailEditModalState>
  setCustomRowMetaData: ReactSetStateAction<Record<string, string> | undefined>
}

export interface HandleSaveRow {
  args: IPluginRow
  newRowData: string
  name: string
  category: EmailComposerRowCategoryDto
  closeModals: () => void
  update: DeepUpdateState<EmailComposerState>
  updateModalState: DeepUpdateState<EmailModalsState>
  setRowData: (value: React.SetStateAction<IPluginRow[]>) => void
  saveSavedRowRequest: EmailComposerRequests['saveSavedRowRequest']
  logNewRelicError(error: any, contextDescription?: string): void
}

export interface HandleGetSavedRows {
  args: IPluginRow
  newValue: string
  update: DeepUpdateState<EmailComposerState>
  closeModals: () => void
  setRowData: (value: React.SetStateAction<IPluginRow[]>) => void
  updateModalState: DeepUpdateState<EmailModalsState>
  updateSavedRowRequest: EmailComposerRequests['updateSavedRowRequest']
}

export type RowLayoutValues = (typeof RowLayoutType)[keyof typeof RowLayoutType]
