import React, { FC, useCallback, useEffect, useState } from 'react'

import classNames from 'classnames'

import { ApolloQueryResult, FetchResult } from '@apollo/client'
import Button, { ButtonType } from '@components/Button'
import Container from '@components/Container'
import Toggle from '@components/Toggle'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { UpdateNotifyOnShareMutation } from '@graphql/types/microservice/thoughtspot-types'
import { NotificationPageResponse } from '@graphql/types/query-types'
import { StatusToastType } from '@interface/StatusToast'
import AnalyticsNotifications from '@src/pages/Profile/components/Notifications/AnalyticsNotifications/AnalyticsNotifications'
import { useAccountSettings } from '@utils/account/account.utils'

import './Notifications.css'

export interface NotificationsProps {
  dataTest?: string
  setToastStatus: (value: StatusToastType) => void
  saveLaunchNotification: (value: boolean) => Promise<FetchResult<{ setLaunchNotification: boolean }>>
  saveAlertsSubscribe: () => Promise<FetchResult<{ setAlertSubscribe: boolean }>>
  launchNotificationsData?: NotificationPageResponse
  loading: boolean
  onRefresh: () => Promise<ApolloQueryResult<{ loadProfileNotificationPage: NotificationPageResponse }>>
  getUserNotification: () => Promise<boolean>
  saveUserNotification: (value: boolean) => Promise<FetchResult<UpdateNotifyOnShareMutation>>
}

const rootClass = 'notifications'

const Notifications: FC<NotificationsProps> = (props: NotificationsProps) => {
  const {
    dataTest = rootClass,
    launchNotificationsData,
    loading,
    saveAlertsSubscribe,
    saveLaunchNotification,
    setToastStatus,
    onRefresh,
    getUserNotification,
    saveUserNotification,
  } = props
  const { t } = useTranslation()
  const { hasAdvancedAnalytics } = useAccountSettings()
  const [toggleValue, setToggleValue] = useState<boolean>(launchNotificationsData?.isLaunchNotificationSet ?? false)

  useEffect(() => {
    setToggleValue(launchNotificationsData?.isLaunchNotificationSet ?? false)
  }, [launchNotificationsData?.isLaunchNotificationSet])

  const onRemoveAlerts = useCallback(
    () =>
      saveAlertsSubscribe().then(() => {
        setToastStatus({
          showStatus: true,
          title: t('Success!'),
          statusMessage: t(`Text alert address has been removed`),
          successStatus: true,
        })
        onRefresh()
      }),
    [onRefresh, saveAlertsSubscribe, setToastStatus, t]
  )

  return (
    <>
      {!loading && launchNotificationsData && (
        <div className={rootClass} data-test={dataTest}>
          <Typography text={t('Notification settings')} type={TextType.PAGE_HEADER} lineHeight={LineHeight.LARGE} />
          <Typography text={t('Profile.Notifications.Description')} type={TextType.BODY_TEXT_LIGHT} className={`${rootClass}__description`} />
          <Container className={classNames(`${rootClass}__section-container`, 'flex-align-center')}>
            <div>
              <Typography
                text={t('Email notifications for launches')}
                type={TextType.SECTION_HEADER}
                className={`${rootClass}__section-item-title`}
              />
              <Typography text={t('Email.Notifications.Description')} type={TextType.BODY_TEXT_LIGHT} />
            </div>
            <Toggle
              large
              isOn={toggleValue}
              noRightMargin
              onToggle={(value) => {
                setToggleValue(value)
                saveLaunchNotification(value).then(
                  () => {
                    setToastStatus({
                      showStatus: true,
                      title: value ? t('Success!') : t('Email notifications disabled'),
                      statusMessage: value
                        ? t(`Your email notifications are now enabled.`)
                        : t(`You won’t receive notifications when messages are launched.`),
                      successStatus: true,
                    })
                  },
                  () => {
                    setToggleValue(!toggleValue)
                  }
                )
              }}
            />
          </Container>
          {launchNotificationsData.alertsEmail && (
            <Container className={classNames(`${rootClass}__section-container`, 'flex-align-center')}>
              <div>
                <Typography text={t('Text alerts')} type={TextType.SECTION_HEADER} className={`${rootClass}__section-item-title`} />
                <Typography
                  text={t(`Notifications.TextAlert.Removal`, { textAlertEmail: launchNotificationsData.alertsEmail })}
                  type={TextType.BODY_TEXT_LIGHT}
                />
              </div>
              <Button buttonType={ButtonType.ERASE} onClick={onRemoveAlerts} dataTest={`${dataTest}__button`}>
                {t('Remove')}
              </Button>
            </Container>
          )}
          {hasAdvancedAnalytics && (
            <AnalyticsNotifications setToastStatus={setToastStatus} getValueAPI={getUserNotification} onSave={saveUserNotification} />
          )}
        </div>
      )}
    </>
  )
}

export default Notifications
