import { gql } from '@apollo/client'

export default gql`
  query getSubmitsAndViews($listId: String) {
    getSubmitsAndViews(listId: $listId) {
      accountId
      formId
      formSubmissionName
      listId
      totalActiveForms
      totalForms
      totalSubmissions
      totalViews
    }
  }
`
