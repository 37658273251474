import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Tooltip from '@components/Tooltip/Tooltip'
import { Align, Side } from '@radix-ui/react-popper'
import * as ToggleRadix from '@radix-ui/react-toggle'

import './toggle.css'

const rootClass = 'toggle'

export enum ToggleLabelPosition {
  BEFORE = 'before',
  AFTER = 'after',
}

interface ToggleProps {
  isOn: boolean
  disabled?: boolean
  title?: string
  label?: ReactNode
  labelPosition?: ToggleLabelPosition
  noLeftMargin?: boolean
  noRightMargin?: boolean
  className?: string
  dataTest?: string
  large?: boolean
  tooltipText?: string
  tooltipPosition?: Side
  tooltipSideOffset?: number
  tooltipAlign?: Align
  onToggle(isOn: boolean): void
}

export const Toggle: FC<ToggleProps> = (props: ToggleProps) => {
  const {
    isOn,
    onToggle,
    title,
    label,
    labelPosition = ToggleLabelPosition.AFTER,
    disabled,
    noLeftMargin,
    noRightMargin,
    large,
    tooltipText,
    tooltipPosition = 'top',
    tooltipSideOffset = 3,
    tooltipAlign = 'end',
    className,
    dataTest = rootClass,
  } = props

  const ToggleRadixRoot = () => (
    <ToggleRadix.Root
      pressed={isOn}
      disabled={disabled}
      onPressedChange={(pressed: boolean) => onToggle(pressed)}
      data-test={dataTest}
      title={title}
      aria-label={rootClass}
      className={classNames(rootClass, `${rootClass}--${isOn ? 'on' : 'off'}`, {
        [`${rootClass}--no-left`]: noLeftMargin,
        [`${rootClass}--no-right`]: noRightMargin,
        [`${rootClass}--large`]: large,
      })}
    />
  )

  const renderToggle = () => {
    return tooltipText ? (
      <Tooltip
        withoutTail
        position={tooltipPosition}
        sideOffset={tooltipSideOffset}
        trigger={<ToggleRadixRoot />}
        className={`${rootClass}__disabled-quick-add`}
        align={tooltipAlign}
      >
        {tooltipText}
      </Tooltip>
    ) : (
      <ToggleRadixRoot />
    )
  }

  const renderLabel = () =>
    typeof label === 'string' ? (
      <label
        className={classNames(`${rootClass}--label`, `${rootClass}--${isOn ? 'label-on' : 'label-off'}`, { [`${rootClass}--large__label`]: large })}
      >
        {label}
      </label>
    ) : (
      label
    )

  if (label) {
    return (
      <div className={classNames(`${rootClass}--container`, className)}>
        {labelPosition === ToggleLabelPosition.BEFORE && renderLabel()}
        {renderToggle()}
        {labelPosition === ToggleLabelPosition.AFTER && renderLabel()}
      </div>
    )
  } else {
    return renderToggle()
  }
}

export default Toggle
