import React, { FC, useMemo } from 'react'

import classNames from 'classnames'

import { EmptyListingProps, EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import Loader from '@components/Loader/Loader'
import PageContainer from '@components/PageContainer/PageContainer'
import PageHeader from '@components/PageHeader/index'
import { PageHeaderType } from '@components/PageHeadline/PageHeadline'
import Search from '@components/Search/Search'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import StatusToast, { Status } from '@components/StatusToast/StatusToast'
import { TableV2 } from '@components/TableV2/TableV2'
import { TableV2RowData } from '@components/TableV2/tableV2TS/interfaces'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import ManagerAssignAccountsModal from '@src/pages/MarketingNetwork/Managers/components/ManagerAssignAccountsModal/ManagerAssignAccountsModal'
import { managerColumns, managerRowActions } from '@src/pages/MarketingNetwork/Managers/Managers.tables'
import { Row, SortingState } from '@tanstack/react-table'
import { VoidFunction } from '@utils/types'

import './Managers.css'

export interface MarketingNetworkManager extends TableV2RowData<MarketingNetworkManager> {
  id: string
  name: string
  email: string
  isAdministrator?: boolean
  accounts: string[]
}

export interface MarketingNetworkAccount extends TableV2RowData<MarketingNetworkAccount> {
  id: string
  name: string
}
export interface StatusResult {
  message: string
  type: Status
}

export interface ManagersProps {
  managers: MarketingNetworkManager[]
  accounts: MarketingNetworkAccount[]
  assignee?: MarketingNetworkManager
  search: string
  filter?: SelectV2SingleOption
  onFilter: (selected?: SelectV2SingleOption) => void
  onSearch: (term: string) => void
  onToggleAssignModal: (assignee?: MarketingNetworkManager) => void
  onAssign: (rows: MarketingNetworkAccount[]) => void
  statusResult?: StatusResult
  onCloseStatus: () => void
  onRowSelectionChanged?: (rowIds: string[], rows: Row<MarketingNetworkManager>[]) => void
  loading: boolean
  className?: string
  dataTest?: string
}

export const defaultSortingBy: SortingState = [
  {
    id: 'name',
    desc: false,
  },
]

export const getEmptyListingProps = (t: Function, buttonOnClick: VoidFunction, search?: string): EmptyListingProps => {
  const text = search ? t(`Managers.EmptyListing.Search.Text`) : t(`Managers.EmptyListing.Filter.Text`)
  const headline = search ? t(`Managers.EmptyListing.Search.Headline`, { search }) : t(`Managers.EmptyListing.Filter.Headline`)
  const imgSrc = search ? StaticImageNames.emptySearch : StaticImageNames.emptyFilter
  const buttonProps = { buttonText: t('Managers.Empty.Button'), buttonOnClick }

  return {
    headline,
    text,
    imgSrc,
    size: EmptyListingSize.MEDIUM,
    hideIcon: false,
    withoutBorder: true,
    ...buttonProps,
  }
}

const rootClass = 'managers'

const Managers: FC<ManagersProps> = (props: ManagersProps) => {
  const {
    managers,
    accounts,
    assignee,
    filter,
    onFilter,
    search,
    onSearch,
    onAssign,
    statusResult,
    onCloseStatus,
    onToggleAssignModal,
    onRowSelectionChanged,
    loading,
    dataTest = rootClass,
    className = '',
  } = props
  const { t } = useTranslation()

  const accountOptions = useMemo(() => {
    if (loading || !accounts || accounts.length < 1) {
      return []
    }

    return accounts
      .map((account) => ({
        label: account.name,
        value: account.id,
      }))
      .sort((a, b) => (a.label > b.label ? 1 : -1))
  }, [accounts, loading])

  const renderAssignModal = () =>
    assignee ? (
      <ManagerAssignAccountsModal accounts={accounts} manager={assignee} isOpen onClose={onToggleAssignModal} onAction={onAssign} />
    ) : undefined

  const renderStatusToast = () =>
    statusResult ? <StatusToast message={t(statusResult.message)} status={statusResult.type} closeStatus={onCloseStatus} /> : undefined

  if (loading) {
    return (
      <PageContainer>
        <Loader center />
      </PageContainer>
    )
  }

  return (
    <>
      {renderStatusToast()}
      {renderAssignModal()}
      <PageHeader
        headlineType={PageHeaderType.SUB_HEADER}
        leftContent
        primaryText={t('Managers.Header')}
        dataTest={`${dataTest}-page-header`}
        className={`${rootClass}__page-header`}
      />
      <PageContainer noPadding className={classNames(rootClass, className)} dataTest={dataTest}>
        <Typography
          text={t('Managers.SubHeader')}
          type={TextType.BODY_TEXT_LIGHT}
          className={`${rootClass}__sub-header-container`}
          dataTest={`${dataTest}-subheader`}
        />
        <div className={`${rootClass}__table-helpers`}>
          <div className={`${rootClass}__table-helpers-accounts`}>
            <SelectV2
              label={t('Managers.Accounts.Label')}
              options={accountOptions}
              value={filter}
              placeholder={t('Select account')}
              onChange={onFilter}
              className={`${rootClass}__table-helpers-accounts-select`}
            />
          </div>
          {(search || !!managers.length) && (
            <>
              <div className={`${rootClass}__table-helpers-filters`}></div>
              <Search
                incomingValue={search}
                placeholder={t('Search users')}
                canClear
                onChangeHandler={onSearch}
                className={`${rootClass}__table-helpers-search`}
              />
            </>
          )}
        </div>
        <div className={`${rootClass}__table`}>
          <TableV2
            data={managers}
            columns={managerColumns}
            enableSorting
            sortingBy={defaultSortingBy}
            emptyState={getEmptyListingProps(t, () => (search ? onSearch('') : onFilter()), search)}
            rowActions={managerRowActions({ onToggleAssignModal })}
            onRowSelectionChanged={onRowSelectionChanged}
            loading={loading}
          />
        </div>
      </PageContainer>
    </>
  )
}

export default Managers
