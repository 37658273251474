import { useContext } from 'react'

import createExportDefinition from '@graphql/microservices/entity-upload/createExportDefinition'
import createExportSyncJob from '@graphql/microservices/entity-upload/createExportSyncJob'
import deleteExportSyncJob from '@graphql/microservices/entity-upload/deleteExportSyncJob'
import getExportDefinition from '@graphql/microservices/entity-upload/getExportDefinition'
import getExportSyncJob from '@graphql/microservices/entity-upload/getExportSyncJob'
import getFtpFiles from '@graphql/microservices/entity-upload/getFtpFiles'
import updateExportDefinition from '@graphql/microservices/entity-upload/updateExportDefinition'
import updateExportSyncJob from '@graphql/microservices/entity-upload/updateExportSyncJob'
import getUnifiedListFieldMappings from '@graphql/microservices/list/getUnifiedListFieldMappings'
import {
  FtpFileDto,
  GetFtpFilesQuery,
  GetFtpFilesQueryVariables,
  ExportDefinitionDto,
  ExportDefinitionDtoInput,
  ImportDefinitionDtoInput,
  ExportSyncJobDtoInput,
  ExportSyncJobDto,
  GetExportSyncJobQuery,
  GetExportSyncJobQueryVariables,
  GetExportDefinitionQuery,
  GetExportDefinitionQueryVariables,
  CreateExportDefinitionMutation,
  CreateExportDefinitionMutationVariables,
  UpdateExportDefinitionMutation,
  UpdateExportDefinitionMutationVariables,
  CreateExportSyncJobMutation,
  CreateExportSyncJobMutationVariables,
  UpdateExportSyncJobMutation,
  UpdateExportSyncJobMutationVariables,
  DeleteExportSyncJobMutation,
  DeleteExportSyncJobMutationVariables,
} from '@graphql/types/microservice/entity-upload-types'
import {
  GetUnifiedListFieldMappingsQuery,
  GetUnifiedListFieldMappingsQueryVariables,
  UnifiedListFieldMapping,
} from '@graphql/types/microservice/list-types'
import { useAccountSettings } from '@utils/account/account.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'

import { getCronFromProgramSchedule } from '../components/ExportOptionsScreen/utils/ExportOptionsScreen.utils'
import { ExportContactsContext } from '../utils/ExportContacts.context'

export interface ExportContactsRequests {
  getFtpFilesRequest: () => Promise<FtpFileDto[]>
  getUnifiedListFieldMappingsRequest: (getDeletedMappings: boolean) => Promise<UnifiedListFieldMapping[]>
  createExportDefinitionRequest: (options: ExportDefinitionDtoInput) => Promise<ExportDefinitionDto>
  updateExportDefinitionRequest: (options: ExportDefinitionDtoInput) => Promise<ExportDefinitionDto>
  createExportSyncJobRequest: (exportSyncJob?: Partial<ExportSyncJobDtoInput>) => Promise<ExportSyncJobDto>
  updateExportSyncJobRequest: (exportSyncJob?: Partial<ExportSyncJobDtoInput>) => Promise<ExportSyncJobDto>
  getExportDefinitionRequest: (exportDefinitionId: number) => Promise<ExportDefinitionDto | null>
  getExportSyncJobRequest: (exportSyncJobId: number) => Promise<ExportSyncJobDto | null>
  deleteSyncSettingsRequest: () => Promise<Boolean>
}

export const useExportContactsRequest = (): ExportContactsRequests => {
  const { client: entityUploadClient } = useMicroserviceClient({ serviceName: MicroserviceClients.ENTITY_UPLOAD })
  const { client: listServiceClient } = useMicroserviceClient({ serviceName: MicroserviceClients.LIST })

  const { accountId, timeZoneId } = useAccountSettings()
  const {
    values: { selectedFtpFile, exportDefinitionId, exportSyncJobId, schedule, exportSegment },
  } = useContext(ExportContactsContext)

  const getExportSyncJobInput = (exportSyncJob?: Partial<ExportSyncJobDtoInput>): ExportSyncJobDtoInput => ({
    accountId,
    cron: !schedule.isScheduled ? undefined : getCronFromProgramSchedule(schedule),
    scheduleTimeZone: timeZoneId,
    enabled: true,
    isRunning: false,
    filePath: selectedFtpFile?.urlPath,
    hasToRunNow: !schedule.isScheduled,
    exportSegmentId: exportSegment?.externalId,
    exportDefinitionId,
    ...(exportSyncJobId ? { exportSyncJobId } : {}),
    ...exportSyncJob,
  })

  const getFtpFilesRequest = () =>
    entityUploadClient
      .query<GetFtpFilesQuery, GetFtpFilesQueryVariables>({
        query: getFtpFiles,
      })
      .then(({ data }) => data.getFtpFiles as FtpFileDto[])

  const getUnifiedListFieldMappingsRequest = (getDeletedMappings: boolean): Promise<UnifiedListFieldMapping[]> =>
    listServiceClient
      .query<GetUnifiedListFieldMappingsQuery, GetUnifiedListFieldMappingsQueryVariables>({
        query: getUnifiedListFieldMappings,
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        const mappings = (data.unifiedListFieldMappings ?? []) as UnifiedListFieldMapping[]
        return getDeletedMappings ? mappings : mappings.filter((mapping) => !mapping?.hidden && !mapping?.deleted)
      })

  const createExportDefinitionRequest = (options: ExportDefinitionDtoInput) =>
    entityUploadClient
      .mutate<CreateExportDefinitionMutation, CreateExportDefinitionMutationVariables>({
        mutation: createExportDefinition,
        variables: {
          exportDefinitionDTO: options,
        },
      })
      .then(({ data }) => data?.createExportDefinition as ExportDefinitionDto)

  const updateExportDefinitionRequest = (options: ImportDefinitionDtoInput) =>
    entityUploadClient
      .mutate<UpdateExportDefinitionMutation, UpdateExportDefinitionMutationVariables>({
        mutation: updateExportDefinition,
        variables: {
          exportDefinitionDTO: options,
        },
      })
      .then(({ data }) => {
        return data?.updateExportDefinition as ExportDefinitionDto
      })

  const createExportSyncJobRequest = (exportSyncJob?: Partial<ExportSyncJobDtoInput>) =>
    entityUploadClient
      .mutate<CreateExportSyncJobMutation, CreateExportSyncJobMutationVariables>({
        mutation: createExportSyncJob,
        variables: {
          exportSyncJobDTO: getExportSyncJobInput(exportSyncJob),
        },
      })
      .then(({ data }) => data?.createExportSyncJob as ExportSyncJobDto)

  const updateExportSyncJobRequest = (exportSyncJob?: Partial<ExportSyncJobDtoInput>) =>
    entityUploadClient
      .mutate<UpdateExportSyncJobMutation, UpdateExportSyncJobMutationVariables>({
        mutation: updateExportSyncJob,
        variables: {
          exportSyncJobDTO: getExportSyncJobInput(exportSyncJob),
        },
      })
      .then(({ data }) => data?.updateExportSyncJob as ExportSyncJobDto)

  const getExportSyncJobRequest = (exportSyncJobId: number) =>
    entityUploadClient
      .query<GetExportSyncJobQuery, GetExportSyncJobQueryVariables>({
        query: getExportSyncJob,
        variables: {
          exportSyncJobId,
        },
      })
      .then(({ data }) => data.getExportSyncJob as ExportSyncJobDto)

  const getExportDefinitionRequest = (exportDefinitionId: number) =>
    entityUploadClient
      .query<GetExportDefinitionQuery, GetExportDefinitionQueryVariables>({
        query: getExportDefinition,
        variables: {
          exportDefinitionId,
        },
      })
      .then(({ data }) => data.getExportDefinition as ExportDefinitionDto)

  const deleteSyncSettingsRequest = () =>
    entityUploadClient
      .mutate<DeleteExportSyncJobMutation, DeleteExportSyncJobMutationVariables>({
        mutation: deleteExportSyncJob,
        variables: {
          exportSyncJobId,
        },
      })
      .then(({ data }) => data?.deleteExportSyncJob as Boolean)

  return {
    getFtpFilesRequest,
    getUnifiedListFieldMappingsRequest,
    createExportDefinitionRequest,
    updateExportDefinitionRequest,
    createExportSyncJobRequest,
    updateExportSyncJobRequest,
    getExportSyncJobRequest,
    getExportDefinitionRequest,
    deleteSyncSettingsRequest,
  }
}
