import React, { FC, useEffect, useState } from 'react'
import { UseFormRegister } from 'react-hook-form'

import classNames from 'classnames'

import Container from '@components/Container'
import MoreOptions from '@components/MoreOptions/MoreOptions'
import Radio from '@components/Radio'
import Select from '@components/Select/Select'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { List } from '@interface/foldersLists/List'
import { useContactOwnerSettingQueries } from '@src/pages/listingPages/AutomatedPrograms/GraphQL/ContactOwnerSetting.graphQL'
import SalesSegments from '@src/pages/Settings/OtherSettings/ContactSettings/components/SalesSegments/SalesSegments'
import { ContactSettingsFields } from '@src/pages/Settings/OtherSettings/ContactSettings/ContactSettings.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import useCRM from '@utils/hooks/useCRM'

import { ContactSettings } from '../../ContactSettings'

import './ContactOwnerSetting.css'

interface Props {
  className?: string
  dataTest?: string
  register: UseFormRegister<ContactSettings>
}

interface ContactOwnerSettingState {
  unifiedListFieldMappings: UnifiedListFieldMapping[]
  opportunitiesList?: List
}

const rootClass = 'contact-owner-setting'

const ContactOwnerSetting: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '', register } = props

  const { t } = useTranslation()

  const { getUnifiedFieldsRequest } = useContactOwnerSettingQueries()
  const { websiteProspectorOwnerUCL } = useAccountSettings()
  const { hasCRMConnected } = useCRM()

  const [state, setState] = useState<ContactOwnerSettingState>({
    unifiedListFieldMappings: [],
  })
  const { unifiedListFieldMappings } = state

  const loadUnifiedFields = async () => {
    const { data } = await getUnifiedFieldsRequest()
    if (data?.unifiedListFieldMappings) {
      const fields = data.unifiedListFieldMappings.filter((mapping) => !mapping?.hidden && !mapping?.deleted) as UnifiedListFieldMapping[]
      setState((state) => ({ ...state, unifiedListFieldMappings: fields }))
    }
  }

  useEffect(() => {
    loadUnifiedFields()
  }, [])

  const renderOptions = (values: Partial<{ columnIndex: string; displayName: string }>[], emptyText: string) => {
    return values.length === 0 ? (
      <option disabled key={'no-option'}>
        {t(emptyText)}
      </option>
    ) : (
      values.map(({ columnIndex, displayName }) => (
        <option key={columnIndex} value={columnIndex}>
          {displayName}
        </option>
      ))
    )
  }

  const renderCRMOption = () => (
    <div className={`${className}__input-section`}>
      <Radio checked={true} disabled={true} label={t('Contact.Owner.Settings.CRM.Option')} />
    </div>
  )

  const renderNonCrmOwnerSection = () => (
    <div className={`${className}__input-section`}>
      <Typography text={t('Contact.Owner.Settings.ActOnContacts.Label')} type={TextType.BODY_TEXT} />
      <MoreOptions dataTest={`${dataTest}-score-options`} className={`${rootClass}__more-options`}>
        <Select
          label={t('Contact.Owner.Settings.Owner.Email')}
          className={`${rootClass}__owner-select`}
          register={register(ContactSettingsFields.CONTACT_OWNER_EMAIL)}
        >
          <option value={'-1'}>{t('Select Field')}</option>
          {renderOptions(unifiedListFieldMappings, 'Contact.Owner.Settings.Not.Defined')}
        </Select>
        <Select
          label={t('Contact.Owner.Settings.Owner.Name')}
          className={`${rootClass}__owner-select`}
          register={register(ContactSettingsFields.CONTACT_OWNER_NAME)}
        >
          <option value={'-1'}>{t('Select Field')}</option>
          {renderOptions(unifiedListFieldMappings, 'Contact.Owner.Settings.Not.Defined')}
        </Select>
      </MoreOptions>
    </div>
  )

  return (
    <Container className={classNames(`${className}__container`, `${rootClass}__container`)} dataTest={dataTest} noBottom>
      <Typography
        dataTest={`${dataTest}__header`}
        className={`${className}__header`}
        text={t('Contact Owner Setting')}
        type={TextType.SECTION_HEADER}
        weight={TextWeight.MEDIUM}
      />
      <Typography text={t('Contact.Owner.Settings.Description')} type={TextType.BODY_TEXT_LIGHT} />
      {hasCRMConnected && renderCRMOption()}
      {!hasCRMConnected && renderNonCrmOwnerSection()}
      {websiteProspectorOwnerUCL && <SalesSegments />}
    </Container>
  )
}

export default ContactOwnerSetting
