import React, { ComponentProps, FC, ReactNode } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import Svg, { SvgNames, SvgType } from '@components/Svg'

import './SelectOrCreateItem.css'

interface SelectOrCreateItemProps extends Omit<ComponentProps<typeof SelectV2>, 'onChange'> {
  setSelectedOption: (option?: SelectV2SingleOption) => void
  setShowCreateItemModal: (value: boolean) => void
  createItemModal: ReactNode
  showCreateItemModal: boolean
  buttonText?: string
  className?: string
  dataTest?: string
}

const rootClass = 'select-or-create-item'

const SelectOrCreateItem: FC<SelectOrCreateItemProps> = (props: SelectOrCreateItemProps) => {
  const {
    setSelectedOption,
    setShowCreateItemModal,
    createItemModal,
    showCreateItemModal,
    buttonText = 'New item',
    placeholder = 'Default segment',
    dataTest = rootClass,
    className = '',
    maxMenuHeight = 200,
    isAsync = true,
    ...restSelectProps
  } = props

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <SelectV2
        onChange={(option) => setSelectedOption(option)}
        placeholder={placeholder}
        isClearable={false}
        className={classNames(`${rootClass}__dropdown`, { [`${className}__dropdown`]: className })}
        dataTest={`${dataTest}__dropdown`}
        maxMenuHeight={maxMenuHeight}
        isAsync={isAsync}
        {...restSelectProps}
      />
      {showCreateItemModal ? (
        createItemModal
      ) : (
        <Button
          buttonType={ButtonType.INFO}
          onClick={() => {
            setShowCreateItemModal(true)
          }}
          noTopBottomPadding
          dataTest={`${dataTest}-show-create`}
        >
          <Svg name={SvgNames.plus} type={SvgType.ICON} className={`${rootClass}__svg`} />
          {buttonText}
        </Button>
      )}
    </div>
  )
}

export default SelectOrCreateItem
