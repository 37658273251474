import React, { FC, useContext, useEffect, useState } from 'react'

import classNames from 'classnames'

import { LazyQueryExecFunction } from '@apollo/client'
import Container from '@components/Container'
import InfoText from '@components/InfoText'
import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import SectionHeadline from '@components/SectionHeadline'
import { useTranslation } from '@const/globals'
import {
  CheckFieldMappingQuery,
  CrmDefinitionQuery,
  EntityTypeWithCrmToStandardFieldMap,
  GetFieldMappingQuery,
} from '@graphql/types/microservice/crm-types'
import { ScoreSheetsQuery, StandardFieldsQuery, UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { InputMaybe } from '@graphql/types/mutation-types'
import { Exact } from '@graphql/types/query-types'
import DynamicList from '@src/pages/datamanagement/components/CrmContacts/components/DynamicList/DynamicList'
import {
  ColumnType,
  DynamicListRowData,
  IconType,
  RowType,
} from '@src/pages/datamanagement/components/CrmContacts/components/DynamicList/utils/DynamicList.interfaces'
import { updateRowIcon } from '@src/pages/datamanagement/components/CrmContacts/components/DynamicList/utils/DynamicList.utils'
import FieldMappingUsageModal from '@src/pages/datamanagement/components/CrmContacts/components/FieldMappingUsage/FieldMappingUsageModal'
import {
  duplicationCheck,
  localeCompare,
  populatePreDefinedMapping,
  removeTemporaryRow,
} from '@src/pages/datamanagement/components/CrmContacts/CrmContactUtil'
import FirstTime from '@src/pages/datamanagement/components/CrmContacts/FirstTime/FirstTime'
import {
  useCRMContactsRowHandler,
  UseCRMContactsRowHandlerProps,
} from '@src/pages/datamanagement/components/CrmContacts/utils/useCRMContactsRowHandler/useCRMContactsRowHandler'
import usePopulateRows from '@src/pages/datamanagement/components/CrmContacts/utils/usePopulateRows/usePopulateRows'
import PushSetting from '@src/pages/datamanagement/components/PushSetting/PushSetting'
import { CrmContactData, DataManagementContext, DuplicateInfo } from '@src/pages/datamanagement/context/DataManagementContext'
import { RowHistoryState } from '@src/pages/datamanagement/utils/DataManagement.constants'
import { FieldValidationState, TableType } from '@utils/crm.utils'
import useCRM from '@utils/hooks/useCRM'

import './CrmContacts.css'

type Props = {
  className?: string
  dataTest?: string
  language: string
  lookupFieldMappingData?: GetFieldMappingQuery
  lookupFieldMappingLoading: boolean
  lookupFieldMappingList: LazyQueryExecFunction<GetFieldMappingQuery, Exact<{ field?: InputMaybe<InputMaybe<string> | InputMaybe<string>[]> }>>
  standardFieldsList?: StandardFieldsQuery
  standardFieldsLoading: boolean
  scoresheetsList?: ScoreSheetsQuery
  scoresheetsLoading: boolean
  crmDefinitions?: CrmDefinitionQuery
  crmDefinitionsLoading: boolean
  checkRowFieldMappingData?: CheckFieldMappingQuery
  checkRowFieldMappingLoading: boolean
  checkRowFieldMappings: LazyQueryExecFunction<
    CheckFieldMappingQuery,
    Exact<{ aofield?: InputMaybe<string>; crmfields?: InputMaybe<InputMaybe<string> | InputMaybe<string>[]> }>
  >
}

export interface CrmDefTypes {
  entityTypes: string[]
  crmToStdMapping?: EntityTypeWithCrmToStandardFieldMap[]
  displayMap: Map<string, string>
}

const crmDefs: CrmDefTypes = {
  entityTypes: [],
  crmToStdMapping: [],
  displayMap: new Map(),
}

export const getSessionStorageOrDefault = (key: string, defaultValue: any) => {
  const stored = sessionStorage.getItem('datamanagement/' + key)
  if (!stored) {
    return defaultValue
  }
  return JSON.parse(stored)
}

const rootClass = 'crm-contacts'

const CrmContacts: FC<Props> = (props: Props) => {
  const {
    className = '',
    dataTest = rootClass,
    language,
    lookupFieldMappingData,
    lookupFieldMappingLoading,
    lookupFieldMappingList,
    standardFieldsList,
    standardFieldsLoading,
    crmDefinitions,
    crmDefinitionsLoading,
    scoresheetsList,
    scoresheetsLoading,
    checkRowFieldMappingData,
    checkRowFieldMappingLoading,
    checkRowFieldMappings,
  } = props

  const [crmDef, setCrmDef] = useState<CrmDefTypes>(crmDefs)
  const [dataLoaded, setDataLoaded] = useState<CrmContactData>({
    done: false,
    standardRows: [],
    customRows: [],
    stdFields: [],
  })
  const [fieldValidationStates, setFieldValidationStates] = useState(new Map<number, number>()) // track field-field validation errors so we don't have to recheck them all every time
  const [runOnce, setRunOnce] = useState<boolean>(false)
  const [showFirstTimePage, setShowFirstTimePage] = useState(getSessionStorageOrDefault('showFirstTimePage', true))
  const [updatedRow, setUpdatedRow] = useState<{
    rowId: number
    rowType?: RowType
    tableType?: TableType
  }>({ rowId: -1 })

  const {
    userUpdates,
    update,
    values: { fieldMappings, fieldMappingStatus, rowHistoryStatus, crmContactData, pushPolicy, unifiedListFieldMappings, loadingULFMappings },
  } = useContext(DataManagementContext)

  // store a copy of the default rows
  const baseRow = usePopulateRows({ entityTypeList: crmDef.entityTypes, standardFields: dataLoaded.stdFields })

  const createScoreSheetRow = (rowId: number, rows: DynamicListRowData[]) => {
    const filteredRows = removeTemporaryRow(rows)
    const rowLocation = filteredRows.findIndex((row) => row.id === rowId)
    const currentRow = filteredRows.filter((row) => row.id === rowId)
    if (!currentRow[0].metadata || !currentRow[0].metadata?.scoreSheetId) {
      currentRow[0].metadata = {
        ...currentRow[0].metadata,
        scoreSheetId: 'ss-default',
      }
    }

    // figure out where to insert it
    filteredRows.splice(rowLocation + 1, 0, addRow(RowType.TEMPORARY, undefined, undefined, undefined, rowId, currentRow[0].metadata))

    return filteredRows
  }

  const updateDisplayedIcons = (rowMap: DynamicListRowData[]) => {
    if (rowMap.length > 0) {
      // reset everybody
      let currentDupes = rowMap.map((row) => {
        if (fieldValidationStates.get(row.id)) {
          return { rowId: row.id, type: fieldValidationStates.get(row.id) ?? FieldValidationState.OK }
        } else if (row.id === updatedRow.rowId) {
          // if field-field mapping is problematic set the icon here
          return { rowId: row.id, type: checkRowFieldMappingData?.checkFieldMapping.valueOf() ?? FieldValidationState.OK }
        } else {
          return { rowId: row.id, type: FieldValidationState.OK }
        }
      })

      const rowLocation = rowMap.findIndex((row) => row.id === updatedRow.rowId)
      if (rowLocation !== -1 && checkRowFieldMappingData) {
        currentDupes = duplicationCheck(t('ACT-ON CONTACTS'), rowMap, currentDupes, language)
      }
      crmDef.entityTypes.forEach((entity) => {
        currentDupes = duplicationCheck(t(entity), rowMap, currentDupes, language)
      })

      currentDupes.forEach((dupe) => {
        fieldMappingStatus.set(dupe.rowId, dupe.type)
      })

      // finally - update all the icons
      updateIcons(currentDupes, rowMap)
    }
  }

  const rowHandlerProps: UseCRMContactsRowHandlerProps = {
    baseRow,
    setUpdatedRow,
    dataLoaded,
    crmDef,
    checkRowFieldMappings,
    lookupFieldMappingData,
    lookupFieldMappingLoading,
    scoresheetsList,
    scoresheetsLoading,
    setDataLoaded,
    standardFieldsList,
    createScoreSheetRow,
    updateDisplayedIcons,
  }

  const { addRow, deleteRow, updateRow } = useCRMContactsRowHandler(rowHandlerProps)

  const { t } = useTranslation()

  const { connectorType } = useCRM()

  useEffect(() => {
    if (dataLoaded.customRows && dataLoaded.standardRows && (dataLoaded.customRows.length > 0 || dataLoaded.standardRows.length > 0)) {
      crmContactData.customRows = dataLoaded.customRows

      if (crmContactData.customRows[0].columns && !runOnce) {
        const dataTypeColumn = crmContactData.customRows[0].columns.filter(
          (find) => find.column !== t('DATA TYPE') && find.type === ColumnType.OPTION
        )
        if (dataTypeColumn && dataTypeColumn.length > 0) {
          const fields = dataTypeColumn[0].values?.reduce((acc: string[], field) => {
            return field.dataType ? [...acc, field.dataType] : [...acc]
          }, [])

          lookupFieldMappingList({ variables: { field: fields } })
          setRunOnce(true)
        }
      }

      crmContactData.dataAvailable = dataLoaded.dataAvailable
      crmContactData.done = dataLoaded.done
      crmContactData.standardRows = dataLoaded.standardRows
      crmContactData.stdFields = dataLoaded.stdFields
    }
  }, [dataLoaded])

  // For DATA TYPE / SCORE we add a temp row beneath for score sheet type
  const mouseOverRowCustom = (dynamicListRow: DynamicListRowData) => {
    const dataTypeColumn = dynamicListRow.columns.filter((col) => col.column === t('DATA TYPE'))
    const dataTypeSelected = dataTypeColumn[0].selected
    let filteredRows = []
    if (dataTypeSelected && dataTypeSelected === t('SCORE')) {
      filteredRows = createScoreSheetRow(dynamicListRow.id, dataLoaded.customRows)
    } else {
      filteredRows = removeTemporaryRow(dataLoaded.customRows)
    }
    setDataLoaded({
      ...dataLoaded,
      done: true,
      customRows: [...filteredRows],
    })
  }

  const mouseOverRowStd = (dynamicListRow: DynamicListRowData) => {
    const dataTypeColumn = dynamicListRow.columns.filter((col) => col.column === t('ACT-ON CONTACTS'))
    const dataTypeSelected = dataTypeColumn[0].staticValue
    let filteredRows: DynamicListRowData[] = []
    if (dataTypeSelected && dataTypeSelected === t('Act-On Primary Score')) {
      filteredRows = createScoreSheetRow(dynamicListRow.id, dataLoaded.standardRows)
    } else {
      filteredRows = removeTemporaryRow(dataLoaded.standardRows)
    }
    setDataLoaded({
      ...dataLoaded,
      done: true,
      standardRows: [...filteredRows],
    })
  }

  // save a (sorted) copy of standard fields for 'destination' column
  useEffect(() => {
    if (!standardFieldsLoading && standardFieldsList?.standardFields) {
      const fields = standardFieldsList.standardFields
        .filter((field) => !field?.unmappable && !field?.hidden)
        .map(
          (fieldInfo) => ({
            display: fieldInfo?.label as string,
            disabled: false,
            id: fieldInfo?.id,
            dataType: fieldInfo?.dataType,
            key: fieldInfo?.key,
            unmappable: fieldInfo?.unmappable,
          }),
          []
        )

      fields.sort((a, b) => (a.display < b.display ? -1 : 1))
      const sortedFields = [{ display: t('Select Destination'), disabled: true, value: '-1' }, ...fields]

      setDataLoaded({ ...dataLoaded, stdFields: sortedFields })
    }
  }, [standardFieldsList])

  // update display with appropriate icon for the requested mapping
  useEffect(() => {
    if (!checkRowFieldMappingLoading && dataLoaded.done) {
      // get all populated rows from both tables
      const allRows = dataLoaded.standardRows.concat(dataLoaded.customRows).filter((item) => item.id !== -1)

      const existingCustomFields = unifiedListFieldMappings.filter((field) => !field.standardFieldKey).map((field) => field.displayName)
      const arrayLoc = allRows.findIndex((item) => item.id === updatedRow.rowId)
      let rowValidation: FieldValidationState = FieldValidationState.OK
      allRows[arrayLoc]?.columns.forEach((val) => {
        if (val.type === ColumnType.EDIT) {
          // if there are any edit fields make sure they aren't empty
          if (!val.staticValue || val.staticValue?.length === 0) {
            fieldMappingStatus.set(updatedRow.rowId, FieldValidationState.MISSING_CUSTOM_NAME)
            rowValidation = FieldValidationState.MISSING_CUSTOM_NAME
          }

          // make sure we aren't reusing any std field names
          const stdFieldCheck = dataLoaded.stdFields.filter((item) => localeCompare(item.display, val.staticValue, language))

          if (val.staticValue && !existingCustomFields.includes(val.staticValue) && stdFieldCheck.length > 0) {
            fieldMappingStatus.set(updatedRow.rowId, FieldValidationState.CUST_MATCH_STD)
            rowValidation = FieldValidationState.CUST_MATCH_STD
          }
        }

        // if this is a custom row make sure data type is set
        if (updatedRow.tableType === TableType.CUSTOM && val.column === t('DATA TYPE') && val.selected === '-1') {
          fieldMappingStatus.set(updatedRow.rowId, FieldValidationState.MISSING_DATA_TYPE)
          rowValidation = FieldValidationState.MISSING_DATA_TYPE
        }
      })

      // track state of any updated rows - for better or worse
      setFieldValidationStates(
        fieldValidationStates.set(
          updatedRow.rowId,
          rowValidation !== FieldValidationState.OK ? rowValidation : checkRowFieldMappingData?.checkFieldMapping.valueOf() ?? FieldValidationState.OK
        )
      )

      updateDisplayedIcons(allRows)
    }
  }, [checkRowFieldMappingLoading])

  const updateIcons = (iconStates: DuplicateInfo[], rowMap: DynamicListRowData[]) => {
    const stdRows: DynamicListRowData[] = []
    const custRows: DynamicListRowData[] = []
    iconStates.forEach((dupe) => {
      // is this from std table?
      let rowLoc = dataLoaded.standardRows.findIndex((item) => item.id === dupe.rowId)
      if (rowLoc !== -1) {
        // find corresponding entry in rowmap
        const stdRowLoc = rowMap.findIndex((item) => item.id === dupe.rowId)
        stdRows.push(updateRowIcon(rowMap[stdRowLoc], translateValidationStatetoIcon(dupe.type), dupe.type))
      } else {
        rowLoc = dataLoaded.customRows.findIndex((item) => item.id === dupe.rowId)
        if (rowLoc !== -1) {
          const customRowLoc = rowMap.findIndex((item) => item.id === dupe.rowId)
          custRows.push(updateRowIcon(rowMap[customRowLoc], translateValidationStatetoIcon(dupe.type), dupe.type))
        }
      }
    })

    // (re)add the 'new row' (unassigned) (bottom) to each list
    stdRows.push(addRow())
    custRows.push(addRow(RowType.CUSTOM))

    setDataLoaded({
      ...dataLoaded,
      done: true,
      standardRows: [...stdRows],
      customRows: [...custRows],
    })
  }

  const translateValidationStatetoIcon = (state: FieldValidationState) => {
    switch (state) {
      case FieldValidationState.WARN:
        return IconType.WARNING
      case FieldValidationState.OK:
        return IconType.OK
      default:
        return IconType.ERROR
    }
  }

  useEffect(() => {
    if (crmDefinitions && !crmDefinitionsLoading) {
      const entityTypeList: string[] = []
      crmDefinitions.crmDefinition?.primaryEntityTypeNames?.forEach((entityType) => {
        if (entityType) {
          entityTypeList.push(entityType)
        }
      })

      const entityTypeDisplayMap = new Map()
      Object.keys(crmDefinitions.crmDefinition?.entityTypeDisplayInfoMap).forEach(function (key) {
        entityTypeDisplayMap.set(key, crmDefinitions.crmDefinition?.entityTypeDisplayInfoMap[key].singularDisplayName)
      })

      const stdMapping = crmDefinitions.crmDefinition?.entityTypeWithCrmToStandardFieldMapList?.reduce(
        (acc: EntityTypeWithCrmToStandardFieldMap[], mapping) => {
          return [
            ...acc,
            {
              entityTypeName: mapping?.entityTypeName,
              crmToStandardFieldMapList: mapping?.crmToStandardFieldMapList,
            },
          ]
        },
        []
      )
      setCrmDef({ ...crmDef, entityTypes: entityTypeList, crmToStdMapping: stdMapping, displayMap: entityTypeDisplayMap })
    }
  }, [crmDefinitionsLoading, crmDefinitions])

  useEffect(() => {
    if (!loadingULFMappings) {
      if (unifiedListFieldMappings?.length === 0 && fieldMappings.length === 0) {
        setShowFirstTimePage(true)
      } else {
        update('saveUCLChanges', true)
      }
    }
  }, [loadingULFMappings])

  useEffect(() => {
    if (!showFirstTimePage && unifiedListFieldMappings?.length === 0 && fieldMappings.length > 0 && pushPolicy?.allowPush) {
      const defaultPushedFields = fieldMappings.map(({ displayName }) => displayName)
      update('pushPolicy', { ...pushPolicy, enabledFields: defaultPushedFields })
    }
  }, [showFirstTimePage])

  useEffect(() => {
    const stdRows: DynamicListRowData[] = []
    const customRows: DynamicListRowData[] = []
    let dataAvailable = false

    if (crmContactData.standardRows && crmContactData.standardRows.length > 0 && crmContactData.customRows && crmContactData.customRows.length > 0) {
      setDataLoaded({
        customRows: crmContactData.customRows,
        dataAvailable: crmContactData.dataAvailable,
        done: crmContactData.done,
        standardRows: crmContactData.standardRows,
        stdFields: crmContactData.stdFields,
      })
    } else {
      // create a row for each saved / restored item and set displayed values and icons
      if (!loadingULFMappings && !dataLoaded.done && baseRow.standard.columns.length > 0) {
        // new account? create rows for each required field
        if (unifiedListFieldMappings?.length === 0 && fieldMappings.length === 0) {
          const requiredFields = standardFieldsList?.standardFields?.filter((entry) => !entry?.unmappable)
          requiredFields?.forEach((field) => {
            const selectPairs: Record<string, string> = {}
            let isRequired = false
            if (field && field.required) {
              isRequired = true
              selectPairs['static'] = field?.label as string
            } else {
              selectPairs[t('ACT-ON CONTACTS')] = field?.key as string
            }

            // for these initial rows we have to set them up as if they were previously loaded / edited
            const newRow: UnifiedListFieldMapping = {
              deleted: false,
              displayName: field?.label,
              dataType: field?.dataType,
              standardFieldKey: field?.key,
              columnIndex: field?.id,
            }

            populatePreDefinedMapping(newRow, crmDef, dataLoaded.stdFields, connectorType, baseRow.standard.columns)
            newRow.ingestFieldMappings?.forEach((fieldMappings) => {
              if (fieldMappings?.objectType) {
                selectPairs[fieldMappings.objectType] = fieldMappings.mapping as string
              }
            })

            if (isRequired || (!isRequired && newRow.ingestFieldMappings && newRow.ingestFieldMappings.length > 0)) {
              stdRows.push(
                updateRowIcon(
                  addRow(isRequired ? RowType.REQUIRED : RowType.STANDARD, field?.id, selectPairs, field?.dataType),
                  IconType.OK,
                  FieldValidationState.OK
                )
              )

              if (field?.dataType === 'SCORE') {
                newRow.typeMetaData = JSON.stringify({ scoreSheetId: 'ss-default' })
              }

              fieldMappings.push(newRow)
              fieldMappingStatus.set(field?.id, FieldValidationState.OK)
              rowHistoryStatus.set(field?.id, RowHistoryState.NEW)
            }
          })
        } else {
          dataAvailable = true
          setShowFirstTimePage(false)
          unifiedListFieldMappings?.forEach((mapping) => {
            if (mapping?.ingestFieldMappings && !(mapping.deleted || mapping.hidden)) {
              if (mapping.standardFieldKey) {
                // lookup corresponding standard field to get 'unmappable' attribute
                const stdFieldMap = dataLoaded.stdFields.filter((field) => field.key === mapping.standardFieldKey)
                if (stdFieldMap.length > 0 && !stdFieldMap[0].unmappable) {
                  const selectPairs: Record<string, string> = {}

                  mapping?.ingestFieldMappings.forEach((fieldMappings) => {
                    if (fieldMappings?.objectType) {
                      selectPairs[fieldMappings.objectType] = fieldMappings.mapping as string
                    }
                  })

                  // see if this is a required field
                  const reqFieldCheck = standardFieldsList?.standardFields?.filter(
                    (entry) => entry?.required && !entry?.unmappable && entry.label === mapping.displayName
                  )
                  let dynamicListRow: DynamicListRowData
                  if (reqFieldCheck && reqFieldCheck?.length > 0) {
                    selectPairs['static'] = mapping.displayName as string
                    dynamicListRow = addRow(RowType.REQUIRED, mapping.columnIndex, selectPairs, reqFieldCheck[0]?.dataType)
                  } else {
                    selectPairs[t('ACT-ON CONTACTS')] = mapping.standardFieldKey as string
                    dynamicListRow = addRow(RowType.STANDARD, mapping.columnIndex, selectPairs)
                  }

                  if (mapping.typeMetaData) {
                    dynamicListRow.metadata = JSON.parse(mapping.typeMetaData)
                  }
                  stdRows.push(updateRowIcon(dynamicListRow, IconType.OK, FieldValidationState.OK))

                  rowHistoryStatus.set(mapping.columnIndex, RowHistoryState.PREEXISTING)
                }
              } else {
                const selectPairs: Record<string, string> = {}

                mapping?.ingestFieldMappings.forEach((fieldMappings) => {
                  if (fieldMappings?.objectType) {
                    selectPairs[fieldMappings.objectType] = fieldMappings.mapping as string
                  }
                })

                selectPairs[t('DATA TYPE')] = mapping.dataType as string
                selectPairs['edit'] = mapping.displayName as string
                const newCustomRow = addRow(RowType.CUSTOM, mapping.columnIndex, selectPairs)
                if (mapping.typeMetaData) {
                  newCustomRow.metadata = JSON.parse(mapping.typeMetaData)
                }
                customRows.push(updateRowIcon(newCustomRow, IconType.OK, FieldValidationState.OK))
                rowHistoryStatus.set(mapping.columnIndex, RowHistoryState.PREEXISTING)
              }
            }
          })
        }
      }
      if (stdRows.length > 0) {
        setDataLoaded({
          ...dataLoaded,
          done: true,
          dataAvailable,
          standardRows: [...stdRows, addRow(RowType.STANDARD)],
          customRows: [...customRows, addRow(RowType.CUSTOM)],
        })
      }
    }
  }, [baseRow, loadingULFMappings])

  useEffect(() => {
    sessionStorage.setItem('datamanagement/showFirstTimePage', JSON.stringify(showFirstTimePage))
  }, [showFirstTimePage])

  return (
    <Container className={classNames(rootClass, className)}>
      <FieldMappingUsageModal dataTest={`${dataTest}-field-mapping-usage-modal`} />
      {!showFirstTimePage && <PushSetting />}
      {dataLoaded.done && showFirstTimePage && <FirstTime setShowFirstTimePage={setShowFirstTimePage} userUpdates={userUpdates} />}
      {!showFirstTimePage && (
        <div className={classNames(rootClass, className)}>
          <Container className={`${rootClass}__outer`}>
            <SectionHeadline>{t('Map CRM Fields to Act-On Standard Fields')}</SectionHeadline>

            {(standardFieldsLoading || crmDefinitionsLoading) && (
              <div className={`${rootClass}__empty_row`}>
                <Loader center={true} className={`${rootClass}__loader`} blackout={false} loaderType={LoaderTypes.page} />
              </div>
            )}

            {!(crmDefinitionsLoading && standardFieldsLoading) && dataLoaded.done && (
              <div className={`${rootClass}__standard`}>
                <InfoText className={`${rootClass}__subtext`}>
                  {t('For each CRM record type, select the equivalent fields and match them to the Act-On field.')}
                </InfoText>
                <div className={`${rootClass}__standard-list`}>
                  <DynamicList
                    mappings={dataLoaded.standardRows}
                    updateRow={updateRow}
                    extraInfo={{ tableType: TableType.STANDARD, rowType: RowType.STANDARD }}
                    deleteRow={deleteRow}
                    mouseOverRow={mouseOverRowStd}
                    headerMap={crmDef.displayMap}
                  />
                </div>
              </div>
            )}
          </Container>
          <Container className={`${rootClass}__outer`}>
            {!(crmDefinitionsLoading && standardFieldsLoading) && dataLoaded.done && (
              <div className={`${rootClass}__custom`}>
                <SectionHeadline>{t('Map to Act-On Custom Fields')}</SectionHeadline>
                <InfoText className={`${rootClass}__subtext`}>
                  {t('Create custom Act-On fields and match them to the equivalent fields for each CRM record type.')}
                </InfoText>
                <div className={`${rootClass}__custom-list`}>
                  <DynamicList
                    mappings={dataLoaded.customRows}
                    updateRow={updateRow}
                    extraInfo={{ tableType: TableType.CUSTOM, rowType: RowType.CUSTOM }}
                    deleteRow={deleteRow}
                    mouseOverRow={mouseOverRowCustom}
                    headerMap={crmDef.displayMap}
                  />
                </div>
              </div>
            )}
          </Container>
        </div>
      )}
    </Container>
  )
}

export default CrmContacts
