import React, { FC, useEffect, useRef } from 'react'

import PositionContainer from '@components/PositionContainer/PositionContainer'
import { legacyActonContext } from '@const/globals'
import { useDocumentVisibility } from '@utils/hooks/useDocumentVisibility'

import './LandingPageManagerReport.css'

interface LandingPageManagerReport {
  id: string
  dataTest?: string
}

const rootClass = 'landing-page-report'

const LandingPageReport: FC<LandingPageManagerReport> = ({ id, dataTest = rootClass }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const isVisible = useDocumentVisibility()

  useEffect(() => {
    const url = new URL(window.location.href)
    const isReportPage = url.pathname.includes('landingPages')
    if (isVisible && isReportPage && iframeRef.current) {
      iframeRef.current.src = iframeRef.current.src
    }
  }, [isVisible])

  return (
    <PositionContainer innerDivClassname={rootClass}>
      <iframe
        ref={iframeRef}
        title="landing page report"
        src={`${legacyActonContext}/messages/messageSentFrame.jsp?msgid=${id}&tab=1&closebutton=0`}
        className={`${rootClass}__iframe`}
        data-test={dataTest}
      />
    </PositionContainer>
  )
}

export default LandingPageReport
