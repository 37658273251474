import { escapeRegExp } from 'lodash'

import { filterNotEmptyArray } from '@utils/array'
import { IEntityContentJsonExtended, IPluginRowExtended, IPluginDisplayConditionExtended } from '@utils/composer/beeEditor/beeEditorTypes'
import { DynamicContentListItem, EmailMessage } from '@utils/composer/context/EmailComposer.context'

import { getDynamicContentDelimiter } from '../EmailModals/components/DynamicContent/DynamicContent.conversion'

export const detectDisplayConditionsInHeaderFooter = (templateJson: IEntityContentJsonExtended) => {
  const result = { header: false, footer: false }
  templateJson.page.rows.forEach((row) => {
    const extRow = row as IPluginRowExtended
    const rowId = extRow?.rowInternal?.uid
    const hasDisplayConditions = !!extRow.container.displayCondition?.condition?.length

    if (hasDisplayConditions && !result.header) {
      result.header = rowId === 'emailHeaderBlockHandler'
    }
    if (hasDisplayConditions && !result.footer) {
      result.footer = rowId === 'emailFooterBlockHandler'
    }
  })
  return result
}

export const getRowDisplayConditions = (message: EmailMessage, includePlainTextOnly = false): (IPluginDisplayConditionExtended | undefined)[] => {
  const plainTextConditions = includePlainTextOnly ? message.plainTextOnlyDynamicContent : []
  const rows = message.templateJson.page.rows
  return [...new Set([...plainTextConditions, ...rows.map((row) => row.container.displayCondition)])]
}

export const getDisplayConditionsCount = (message: EmailMessage) => {
  return getRowDisplayConditions(message, true).filter(filterNotEmptyArray).length
}

export const hasDisplayConditions = (message: EmailMessage) => {
  return getRowDisplayConditions(message).filter(filterNotEmptyArray).length > 0
}

export const findDisplayConditionForTextBlock = (text: string, dynamicContent: IPluginDisplayConditionExtended[]) => {
  return dynamicContent.find((content) => {
    const delim = getDynamicContentDelimiter(content.label ?? '')
    return text.trim().startsWith(delim) && text.trim().endsWith(delim)
  })
}

export const stripDisplayConditionDelimiters = (text: string, dynamicContent: IPluginDisplayConditionExtended) => {
  const result = text
    .trim()
    .replace(new RegExp(`${escapeRegExp(dynamicContent.before)}\n?`, 'g'), '')
    .replace(new RegExp(`\n?${escapeRegExp(dynamicContent.after)}`, 'g'), '')
  return result
}

export const getDisplayConditionsRegExp = (message: EmailMessage, includeTemporary = false) => {
  const patterns = getRowDisplayConditions(message, includeTemporary)
    .filter(filterNotEmptyArray)
    .map((condition) => `\n?${escapeRegExp(condition?.before)}[\\s\\S]*?${escapeRegExp(condition?.after)}\n?`)
  if (patterns.length === 0) {
    return undefined
  }

  const allPatterns = patterns.join('|')
  // Wrapping pattern in () causes .split() to return an array with both matched and non-matched text
  return new RegExp(`(${allPatterns})`, 'gm')
}

export const stripDisplayConditions = (message: EmailMessage, plainText: string) => {
  const pattern = getDisplayConditionsRegExp(message)
  return pattern ? plainText.replace(pattern, '') : plainText
}

export interface PlainTextPart {
  key: string
  text: string
  dynamicContent?: DynamicContentListItem
}

export const splitDynamicPlainTextParts = (
  message: EmailMessage,
  plainText: string,
  dynamicContentList: DynamicContentListItem[]
): PlainTextPart[] => {
  const isDynamicPart = (index: number) => !!(index % 2)

  const correctDuplicateRows = (parts: string[]) => {
    // This is a temp fix for an issue where the plain text row may be repeated
    // for every condition group that exists on the row (will be addressed in AO-92498)
    const dynamicRowsCount = parts.filter((part) => !!findDisplayConditionForTextBlock(part, dynamicContentList)).length
    if (dynamicRowsCount === dynamicContentList.length) {
      // No duplicates
      return parts
    }

    const uniqueOffsets = dynamicContentList.map((item) => {
      // How many text parts to skip (there will be an empty string between each group)
      return (item.condition?.length ?? 0) * 2 - 1
    })
    const result: string[] = []
    let curDynamicIndex = 0

    for (let index = 0; index < parts.length; ) {
      const isDynamic = !!findDisplayConditionForTextBlock(parts[index], dynamicContentList)
      result.push(parts[index])

      if (isDynamic) {
        index += uniqueOffsets[curDynamicIndex]
        ++curDynamicIndex
      } else {
        ++index
      }
    }
    return result
  }

  const pattern = getDisplayConditionsRegExp(message)
  const parts = correctDuplicateRows(pattern ? plainText.split(pattern) : [plainText])
  let dynamicIndex = -1
  return parts
    .map((part, index) => {
      const dynamicContent = isDynamicPart(index) ? dynamicContentList[++dynamicIndex] : undefined
      const key = dynamicContent?.uuid ?? `static-${index}`
      const displayText = dynamicContent ? stripDisplayConditionDelimiters(part, dynamicContent) : part
      return { key, text: displayText, dynamicContent }
    })
    .filter((_, index) => (isDynamicPart(index) ? dynamicContentList[Math.floor(index / 2)].isActive : true))
}
