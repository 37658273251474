import React, { FC } from 'react'

import classNames from 'classnames'
import dayjs from 'dayjs'

import Caution from '@components/Caution/Caution'
import { YesNo } from '@components/ConfirmationModal/index'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Toggletip from '@components/Toggletip/Toggletip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { LITMUS_TESTING_URL } from '@src/pages/EmailComposer/components/EmailComposerPreview/EmailComposerPreview.utils'
import { EmailComposerModalState } from '@utils/composer/context/EmailComposer.context'
import { ExtendedMessageType } from '@utils/composer/emailComposer/types'
import { useTranslation } from '@utils/const/globals'

import './EmailComposerPreviewTabs.css'

interface Tab {
  label: string
  value: string
  disabled: boolean
}

interface EmailComposerPreviewTabsProps {
  tabs: Tab[]
  activeTab: string
  haveUnsavedChanges?: boolean
  messageType?: ExtendedMessageType
  separateSaveAndClose?: boolean
  webinarScheduledTime?: number
  onChange: (value: string) => void
  onSave: VoidFunction
  updateModal: <T extends EmailComposerModalState, K extends keyof T>(modal: K, value: T[K], closeOthers?: boolean) => void
  dataTest?: string
}

const rootClass = 'email-composer-preview-tabs'

const EmailComposerPreviewTabs: FC<EmailComposerPreviewTabsProps> = ({
  tabs,
  activeTab,
  haveUnsavedChanges,
  messageType,
  separateSaveAndClose,
  webinarScheduledTime,
  onChange,
  onSave,
  updateModal,
  dataTest = rootClass,
}) => {
  const { t } = useTranslation()

  const onTabClick = (value: string) => {
    if (haveUnsavedChanges && separateSaveAndClose && value.toLocaleLowerCase() === 'inbox') {
      const isWebinarMessage = messageType === 'WEBINAR_MESSAGE'

      updateModal('confirmation', {
        title: t(`EmailComposer.Preview.UnsavedChanges.Modal.Title${isWebinarMessage ? `.${messageType}` : ''}`, {
          context: webinarScheduledTime ? 'scheduled' : 'default',
        }),
        body: (
          <>
            <Typography
              type={TextType.BODY_TEXT_LIGHT}
              text={t(`EmailComposer.Preview.UnsavedChanges.Modal.Body.${messageType}`, {
                context: !!webinarScheduledTime ? 'scheduled' : 'default',
                date: dayjs(webinarScheduledTime).format('MMMM D, YYYY'),
                time: dayjs(webinarScheduledTime).format('HH:MMA'),
              })}
              tagProps={{ medium: { weight: TextWeight.MEDIUM, inline: true } }}
              className="push-down-x2 push-up-x2"
            />
            <Caution
              message={
                <Typography
                  text={t(`EmailComposer.Preview.UnsavedChanges.Modal.Caution.${messageType}`)}
                  tagProps={{ medium: { weight: TextWeight.MEDIUM, inline: true } }}
                />
              }
            />
          </>
        ),
        onAnswer: (answer: YesNo) => {
          if (answer === YesNo.YES) {
            Promise.resolve(onSave()).then(() => onChange(value))
          }
          updateModal('confirmation', undefined)
        },
        isYesNo: true,
        yesButtonText: t('Save and continue'),
        noButtonText: t('Cancel'),
      })
    } else {
      onChange(value)
    }
  }

  return (
    <div className={rootClass} data-test={dataTest}>
      {tabs.map((tab) => {
        return (
          <Toggletip
            key={`${tab.label}-${tab.value}`}
            className={`${rootClass}__toggletip`}
            hide={!tab.disabled}
            isDismissable
            title={t('EmailComposer.Preview.Inbox.Tab.Tooltip.Title')}
            description={t('EmailComposer.Preview.Inbox.Tab.Tooltip.Description')}
            buttonText={t('EmailComposer.Preview.Inbox.Tab.Tooltip.Button.Text')}
            onButtonClick={() => window.open(LITMUS_TESTING_URL, '_blank')}
            tabIndex={-1}
            trigger={
              <div
                key={`${tab.label}-${tab.value}`}
                className={classNames(`${rootClass}__tab`, {
                  [`${rootClass}__tab--active`]: activeTab === tab.value,
                  [`${rootClass}__tab--disabled`]: tab.disabled,
                })}
                data-test={`${rootClass}-tab${activeTab === tab.value ? '--active' : ''}`}
                tabIndex={0}
                role={'button'}
                onClick={() => !tab.disabled && onTabClick(tab.value)}
                onKeyDown={(keyDownEvent) =>
                  !tab.disabled && (keyDownEvent.key === ' ' || keyDownEvent.key === 'Enter' ? onTabClick(tab.value) : undefined)
                }
              >
                {tab.disabled && <Svg name={SvgNames.lockLight} type={SvgType.LARGER_ICON} />}
                <Typography text={t(tab.label)} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.MEDIUM} lineHeight={LineHeight.MEDIUM_SMALL} />
              </div>
            }
          />
        )
      })}
    </div>
  )
}

export default EmailComposerPreviewTabs
