import React, { FC, useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import axios from 'axios'

import { SelectOption } from '@components/MultiSelect/MultiSelect'
import getFragments from '@graphql/microservices/email-event/getFragments'
import { FragmentNameClick, GetFragmentsQuery, QueryGetFragmentsArgs } from '@graphql/types/microservice/email-event-types'
import { useAccountSettings } from '@utils/account/account.utils'
import useMicroserviceClient, { useMicroserviceUrl, MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'
import { SortByState } from '@utils/sms.utils'
import { download } from '@utils/utils'

import FragmentsTab from './FragmentsTab'
import { LinkPerformanceLinkLocationState } from '../../utils/LinkPerformance.utils'
import { Direction, FragmentNameClickSortColumns } from '../../utils/LinkPerformance.utils'

const PAGE_SIZE = 10

const DEFAULT_SORT_BY: FragmentSortBy = {
  id: FragmentNameClickSortColumns.Fragmentname,
  direction: Direction.Asc,
}

export type FragmentSortBy = {
  id: FragmentNameClickSortColumns
  direction: Direction
}

type FragmentsTabContainerProps = {
  messageId: string
  allTags: SelectOption[]
  allFragments: SelectOption[]
}

const FragmentsTabContainer: FC<FragmentsTabContainerProps> = (props: FragmentsTabContainerProps) => {
  const { messageId, allTags, allFragments } = props

  const history = useHistory()
  const location = useLocation()
  const locationState: LinkPerformanceLinkLocationState = location?.state as LinkPerformanceLinkLocationState

  const { accountId } = useAccountSettings()

  const { client, token, aoAccountContext } = useMicroserviceClient({ serviceName: MicroserviceClients.EMAIL_EVENT })
  const downloadUrl = useMicroserviceUrl({ serviceName: MicroserviceClients.EMAIL_EVENT })

  const getSortColumn = (column: FragmentNameClickSortColumns) => {
    return column == FragmentNameClickSortColumns.Linkname
      ? 'linkName'
      : column === FragmentNameClickSortColumns.Fragmentname
      ? 'fragmentName'
      : column === FragmentNameClickSortColumns.Totalclicks
      ? 'totalClicks'
      : column === FragmentNameClickSortColumns.Uniqueclicks
      ? 'uniqueClicks'
      : ''
  }

  const [sortBy, setSortBy] = useState<SortByState[]>([
    {
      id: locationState?.fragmentSortColumn ? getSortColumn(locationState.fragmentSortColumn) : getSortColumn(DEFAULT_SORT_BY.id),
      desc:
        locationState?.fragmentSortDirection && locationState.fragmentSortDirection === Direction.Desc
          ? true
          : DEFAULT_SORT_BY.direction == Direction.Desc
          ? true
          : false,
    },
  ])

  const [fragmentTags, setFragmentTags] = useState<string[]>(locationState?.fragmentTags || [])
  const [fragmentFragments, setFragmentFragments] = useState<string[]>(locationState?.fragmentFragments || [])
  const [fragmentSortDirection, setFragmentSortDirection] = useState<Direction>(locationState?.fragmentSortDirection || DEFAULT_SORT_BY.direction)
  const [fragmemtSortColumn, setFragmentSortColumn] = useState<FragmentNameClickSortColumns>(locationState?.fragmentSortColumn || DEFAULT_SORT_BY.id)
  const [fragData, setFragData] = useState<FragmentNameClick[]>([])
  const [fragmentGroupBy, setFragmentGroupBy] = useState(locationState?.fragmentGroupBy || false)
  const [fragmentSize, setFragmentSize] = useState(locationState?.fragmentPageSize || PAGE_SIZE)
  const [fragmentPage, setFragmentPage] = useState(locationState?.fragmentPage || 0)
  const [fragmentTotalCount, setFragmentTotalCount] = useState(0)
  const [fragmentTotalClicks, setFragmentTotalClicks] = useState(0)
  const [fragmentTotalUniqueClicks, setFragmentTotalUniqueClicks] = useState(0)
  const [resetPageIndex, setResetPageIndex] = useState(false)

  const {
    loading,
    error: pageError,
    data,
  } = useQueryOnMount<GetFragmentsQuery, QueryGetFragmentsArgs>(getFragments, {
    client,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      accountId,
      messageId,
      sortDirection: fragmentSortDirection,
      sortColumn: fragmemtSortColumn,
      size: fragmentSize,
      page: fragmentPage,
      groupByFragment: fragmentGroupBy,
      tagNames: fragmentTags,
      fragmentNames: fragmentFragments,
    },
  })

  const updateData = (data: GetFragmentsQuery) => {
    const { getFragments } = data
    const { totalCount, totalClickCount, totalUniqueCount } = getFragments ?? { totalCount: 0, totalClickCount: 0, totalUniqueCount: 0 }

    const fragmentDataCleaned = getFragments?.fragmentNameLists
      ? getFragments.fragmentNameLists.map((fragment) => {
          return {
            fragmentName: fragment?.fragmentName || '',
            linkName: fragment?.linkName ? fragment.linkName : '',
            totalClicks: fragment?.totalClicks,
            uniqueClicks: fragment?.uniqueClicks,
          }
        })
      : []

    setFragData(fragmentDataCleaned)
    setFragmentTotalCount(totalCount)
    setFragmentTotalClicks(totalClickCount)
    setFragmentTotalUniqueClicks(totalUniqueCount)
    setResetPageIndex(false)
  }

  const fetchFragData = (pageIndex: number, pageSize: number) => {
    const locationState = {
      ...(history.location.state as Object),
      fragmentPageSize: pageSize,
      fragmentPage: pageIndex,
    }

    history.replace(location.pathname, locationState)
    setFragmentPage(pageIndex)
    setFragmentSize(pageSize)
  }

  const fragSort = (newSortBy: SortByState[]) => {
    const sortColumn =
      newSortBy && newSortBy[0]
        ? newSortBy[0].id === 'linkName'
          ? FragmentNameClickSortColumns.Linkname
          : newSortBy[0].id === 'fragmentName'
          ? FragmentNameClickSortColumns.Fragmentname
          : newSortBy[0].id === 'totalClicks'
          ? FragmentNameClickSortColumns.Totalclicks
          : newSortBy[0].id === 'uniqueClicks'
          ? FragmentNameClickSortColumns.Uniqueclicks
          : DEFAULT_SORT_BY.id
        : DEFAULT_SORT_BY.id

    const direction = newSortBy && newSortBy.length && newSortBy[0].desc ? Direction.Desc : Direction.Asc

    const newFragmentPage = sortBy[0].id !== newSortBy[0].id ? 0 : fragmentPage

    const locationState = {
      ...(history.location.state as Object),
      fragmentSortDirection: direction,
      fragmentSortColumn: sortColumn,
      fragmentPage: newFragmentPage,
    }

    history.replace(location.pathname, locationState)

    setFragmentSortColumn(sortColumn)
    setFragmentSortDirection(direction)
    setFragmentPage(newFragmentPage)
    setSortBy(newSortBy)
  }

  const toggleGroupByFragment = (checked: boolean) => {
    const locationState = {
      ...(history.location.state as Object),
      fragmentGroupBy: checked,
      fragmentPage: 0,
    }

    history.replace(location.pathname, locationState)

    setFragmentGroupBy(checked)
    setFragmentPage(0)
    setResetPageIndex(true)
  }

  const fragOnsubmit = (selected: string[]) => {
    const locationState = {
      ...(history.location.state as Object),
      fragmentFragments: selected,
      fragmentPage: 0,
    }

    history.replace(location.pathname, locationState)
    setFragmentFragments(selected)
    setFragmentPage(0)
    setResetPageIndex(true)
  }

  const tagsOnsubmit = (selected: string[]) => {
    const locationState = {
      ...(history.location.state as Object),
      fragmentTags: selected,
      fragmentPage: 0,
    }

    history.replace(location.pathname, locationState)
    setFragmentTags(selected)
    setFragmentPage(0)
    setResetPageIndex(true)
  }

  const onDownload = () => {
    axios({
      url: `${downloadUrl}/email/click/report/fragment/${accountId}/${messageId}`,
      method: 'POST',
      headers: { authorization: token, AOAccountContext: aoAccountContext, 'Content-Type': 'application/json' },
      responseType: 'blob',
      data: {
        fragmentNames: fragmentFragments,
        tagNames: fragmentTags,
        groupByFragment: fragmentGroupBy,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      download(url, 'results.csv')
    })
  }

  useEffect(() => {
    if (data) {
      updateData(data)
    }
  }, [data])

  return (
    <FragmentsTab
      data={fragData}
      loading={loading}
      fragOptions={allFragments.map((fragment) => ({
        ...fragment,
        selected: fragmentFragments.includes(fragment.value) ? true : false,
      }))}
      tagOptions={allTags.map((tag) => ({
        ...tag,
        selected: fragmentTags.includes(tag.value) ? true : false,
      }))}
      sortBy={sortBy}
      fragOnsubmit={fragOnsubmit}
      tagOnsubmit={tagsOnsubmit}
      onSort={fragSort}
      onDownload={onDownload}
      onCheck={toggleGroupByFragment}
      totalCount={fragmentTotalCount}
      totalClicks={fragmentTotalClicks}
      uniqueClicks={fragmentTotalUniqueClicks}
      pageSize={fragmentSize}
      pageIndex={fragmentPage}
      fetchData={fetchFragData}
      groupBy={fragmentGroupBy}
      pageError={pageError}
      resetPageIndex={resetPageIndex}
    ></FragmentsTab>
  )
}

export default FragmentsTabContainer
