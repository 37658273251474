import React from 'react'
import { Link, matchPath } from 'react-router-dom'

import globals, { rootContext } from '@const/globals'
import { SkinParams } from '@graphql/types/query-types'
import allPublicRoutes from '@src/components/Routes/allPublicRoutes'
import allRoutes, { RouteConfig } from '@src/components/Routes/allRoutes'
import documentUtils from '@utils/document'
import { THEME } from '@utils/theme'

const rootClassFlag = 'feature-flag'

export const renderDevFlag = () =>
  globals.isLocal() && (
    <Link className={`${rootClassFlag}`} to={`${rootContext}/features`}>
      🚩
    </Link>
  )

export function lightenDarkenColor(col: string, amt: number) {
  const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(col)

  if (rgb && rgb.length == 4) {
    const r = parseInt(rgb[1], 16) + amt > 255 ? 255 : parseInt(rgb[1], 16) + amt < 0 ? 0 : parseInt(rgb[1], 16) + amt
    const g = parseInt(rgb[2], 16) + amt > 255 ? 255 : parseInt(rgb[2], 16) + amt < 0 ? 0 : parseInt(rgb[2], 16) + amt
    const b = parseInt(rgb[3], 16) + amt > 255 ? 255 : parseInt(rgb[3], 16) + amt < 0 ? 0 : parseInt(rgb[3], 16) + amt
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`
  } else {
    return col
  }
}

export function toggleRootClassStyle(theme: THEME) {
  const root = documentUtils.getElementById('root')
  if (!root) return
  if (theme === THEME.DARK) {
    root.classList.add('root--theme-dark')
    root.classList.remove('root--theme-light')
    root.classList.remove('root--theme-custom')
  } else if (theme === THEME.LIGHT) {
    root.classList.remove('root--theme-dark')
    root.classList.add('root--theme-light')
    root.classList.remove('root--theme-custom')
  } else if (theme === THEME.CUSTOM) {
    root.classList.remove('root--theme-dark')
    root.classList.remove('root--theme-light')
    root.classList.add('root--theme-custom')
  }
}

export function renderCustomStylesheet(skinParams?: SkinParams) {
  if (!skinParams) return null
  const darkenedBackgroundColor = skinParams.navBackgroundColor ? lightenDarkenColor(skinParams.navBackgroundColor.replace('#', ''), -11) : undefined
  return (
    <style type="text/css">{`
    .root--theme-custom .navigation {
      border-right: 1px solid ${darkenedBackgroundColor};
      background-color: ${skinParams.navBackgroundColor};
    }
    
    .root--theme-custom .navigation__expander {
      border-top: 1px solid ${darkenedBackgroundColor};
      background-color: ${skinParams.navBackgroundColor};
    }
    
    .root--theme-custom .navigation__expander:hover {
      background-color: ${darkenedBackgroundColor};
    }

    .root--theme-custom .navigation__expander svg {
      fill: ${skinParams.navFontColor};
    }
    
    .root--theme-custom .navigation__expander span {
      color: ${skinParams.navFontColor};
    }
    
    .root--theme-custom .navigation__expander:hover span {
      color: ${skinParams.navFontActiveColor};
    }

    .root--theme-custom .navigation__expander:hover svg {
      fill: ${skinParams.navFontActiveColor};
    }
 
    .root--theme-custom .navigation__item-name label {
      color: ${skinParams.navFontColor};
    }
    
    .root--theme-custom .navigation__item-name--active > label {
      background-color: ${skinParams.navSubBackgroundColor};
      color: ${skinParams.navFontHighlightColor};
    }
    
    .root--theme-custom .navigation__item-name--root {
      background-color: ${skinParams.navBackgroundColor};
    }
    
    .root--theme-custom .navigation__item-name--root-active > label {
      color: ${skinParams.navFontActiveColor};
    }
    
    .root--theme-custom .navigation__item-name--root:hover > label {
      color: ${skinParams.navFontActiveColor};
    }
    
    .root--theme-custom .navigation__item-name--not-active:hover > label {
      color: ${skinParams.navFontActiveColor};
    }
    
    .root--theme-custom .navigation__item-name:hover label {
      color: ${skinParams.navFontActiveColor};
    }
    
    .root--theme-custom .navigation__item-name:hover svg {
      fill: ${skinParams.navFontActiveColor};
    }
    
    .root--theme-custom .navigation__item-name--active:hover > label {
      color: ${skinParams.navFontActiveColor};
    }

    .root--theme-custom .navigation__group--visible-hover.navigation__group--depth-1 {
      background-color: ${skinParams.navBackgroundColor};
    }
    
    .root--theme-custom .navigation__group--depth-1 .navigation__mobile-header {
      color: ${skinParams.navFontActiveColor};
    }
    
    .root--theme-custom .navigation__mobile-header-line {
      background-color: ${darkenedBackgroundColor};
    }
    
    .root--theme-custom .navigation__item-icon-default {
      fill: ${skinParams.navFontColor};
    }
    
    .root--theme-custom .navigation__item-icon-selected {
      fill: ${skinParams.navFontActiveColor};
    }
    
    .root--theme-custom .navigation__item-caret {
      fill: ${skinParams.navFontColor};
    }
    
    .root--theme-custom .navigation--disabled .navigation__item-name:hover svg {
      fill: ${skinParams.navFontColor};
    }
    
    .root--theme-custom .navigation--disabled a:hover,
    .root--theme-custom .navigation--disabled .navigation__item-name:hover > label {
      color: ${skinParams.navFontColor};
    }
    
    .root--theme-custom .navigation--disabled .navigation__item-name--active:hover > label {
      color: ${skinParams.navFontHighlightColor};
    }
    
    .root--theme-custom .navigation--disabled.navigation--expanded .navigation__item-name--root:hover svg {
      fill: ${skinParams.navFontColor};
    }
    
    .root--theme-custom .navigation--disabled.navigation--expanded .navigation__item-name--root-active > label {
      color: ${skinParams.navFontActiveColor};
    }
    
    .root--theme-custom .navigation--disabled.navigation--expanded .navigation__item-icon-selected {
       fill: ${skinParams.navFontActiveColor} !important;
    }
    `}</style>
  )
}

/**
 * Checks if the given route configuration matches the actual path.
 *
 * @param {RouteConfig} route - The route configuration object containing path and exact properties.
 * @param {string} actualPath - The actual path to match against the route configuration.
 * @returns {boolean} True if the route configuration matches the actual path, otherwise false.
 */
const isMatchingRoute = ({ path, exact }: RouteConfig, actualPath: string): boolean => {
  return !!matchPath(actualPath, { path, exact, strict: true })
}

/**
 * Checks if the given path matches any route configuration that has the `noNav` property set to true.
 *
 * @param {string} path - The path to check against the route configurations.
 * @returns {boolean} True if any route configuration with `noNav` set to true matches the given path, otherwise false.
 */
export const routeHasNavHidden = (path: string): boolean => {
  return allRoutes.some((route) => {
    return route.noNav && isMatchingRoute(route, path)
  })
}

/**
 * Checks if the given path matches any public route configuration.
 *
 * @param {string} path - The path to check against the public route configurations.
 * @returns {boolean} True if any public route configuration matches the given path, otherwise false.
 */
export const getIsRoutePublic = (path: string): boolean => {
  return allPublicRoutes.some((route) => {
    return isMatchingRoute(route, path)
  })
}
