import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'

interface Props<T> {
  key: string
  initialValue: T | (() => T)
  autoLoad?: boolean
}

interface AuxiliaryFunctions<T> {
  clearSessionData: (key?: string) => void
  getDataFromSession: (partialValues?: Partial<T>) => void
  updateSessionData: VoidFunction
}

export const usePersistentState = <T>(props: Props<T>): [T, Dispatch<SetStateAction<T>>, AuxiliaryFunctions<T>] => {
  const { autoLoad = true, key, initialValue } = props

  const [state, setState] = useState<T>(initialValue)

  const clearSessionData = useCallback(
    (actualKey = key) => {
      if (actualKey) {
        sessionStorage.removeItem(actualKey)
      }
    },
    [key]
  )

  const getDataFromSession = useCallback(
    (partialValues?: Partial<T>) => {
      const value = sessionStorage.getItem(key)
      if (value) {
        const newState = value === 'undefined' ? undefined : JSON.parse(value)
        setState(partialValues ? { ...newState, ...partialValues } : newState)
      }
    },
    [key]
  )

  const updateSessionData = useCallback(() => {
    if (key) {
      if (state) {
        sessionStorage.setItem(key, JSON.stringify(state))
      } else {
        clearSessionData()
      }
    }
  }, [clearSessionData, key, state])

  useEffect(() => {
    if (autoLoad) {
      getDataFromSession()
    }
  }, [key, getDataFromSession])

  return [
    state,
    setState,
    {
      clearSessionData,
      getDataFromSession,
      updateSessionData,
    },
  ]
}
