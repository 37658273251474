import { gql } from '@apollo/client'

export default gql`
  mutation setSelectedParentSegment($accountId: ID, $segmentId: ID) {
    setSelectedParentSegment(accountId: $accountId, segmentId: $segmentId) {
      accountId
      maximumRunTime
      memoryOverrideGB
      enabled
      selectedParentSegment
    }
  }
`
