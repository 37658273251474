import React from 'react'

import {
  AssetPickerTableType,
  renderDefaultLocationColumn,
  renderDefaultNameColumn,
  renderDefaultRelativeDateColumn,
  renderDefaultTagsColumn,
} from '@complex/AssetPickerModal/Components/Table/AssetPickerTable.utils'
import { ItemDtoRow } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import Pill, { PillSize, PillType } from '@components/Pill/Pill'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { allLandingPages } from '@utils/filter'

const rootClass = 'landing-pages-picker-modal'

const renderItemNameVal = (original: ItemDto, search: string, rootClass: string) => {
  const isBeeComposer = (original as any)?.beeComposer ?? ''
  const itemName = (original as any)?.name ?? ''
  const trigger = search ? renderBoldTextOnMatch(itemName, search) : itemName

  return (
    <div className={`${rootClass}__item-name ellip flex-align-center`}>
      <div className={`${rootClass}__item-name-typography push-left`}>
        <TextWithTooltipOnEllip
          typographyProps={{
            text: trigger,
          }}
          tooltipProps={{ children: itemName }}
        />
      </div>
      {isBeeComposer && <Pill text={'BETA'} type={PillType.SECONDARY} noMargin size={PillSize.SMALL} />}
    </div>
  )
}

export const renderTableColumns = (t: Function): ColumnDefWithAdditionalProps<ItemDtoRow, any>[] => [
  ...[renderDefaultNameColumn(t('Landing Page Title'), '', rootClass, renderItemNameVal)],
  ...[renderDefaultTagsColumn(AssetPickerTableType.DEFAULT, t('Tags'))],
  ...[renderDefaultRelativeDateColumn(AssetPickerTableType.DEFAULT, t('Last Updated'), 'lastUpdated')],
]

export const renderSearchTableColumns = (search: string, folders: FolderData[], t: Function): ColumnDefWithAdditionalProps<ItemDtoRow, any>[] => [
  ...[renderDefaultNameColumn(t('Landing Page Title'), search, rootClass, renderItemNameVal)],
  ...[renderDefaultLocationColumn(AssetPickerTableType.SEARCH, t('Location'), folders, t(allLandingPages.name))],
  ...[renderDefaultTagsColumn(AssetPickerTableType.SEARCH, t('Tags'))],
  ...[renderDefaultRelativeDateColumn(AssetPickerTableType.SEARCH, t('Last Updated'), 'lastUpdated')],
]
