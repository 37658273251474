import React, { FC } from 'react'

import classNames from 'classnames'

import Tag from '@components/TagsList/components/Tag/Tag'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import { TagRowActions } from '@utils/tags'

import './TagsList.css'

interface Props {
  className?: string
  dataTest?: string
  tags: LabelDto[]
  onClick: (tag: LabelDto) => void
  onActionClick?: (tag: LabelDto, action: TagRowActions) => void
  selectedId?: number
}

const rootClass = 'tags-list'

const TagsList: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, tags, onClick, onActionClick, selectedId, className = '' } = props

  return (
    <div id={rootClass} className={classNames(rootClass, className)} data-test={dataTest}>
      {tags.map((tag, index) => (
        <Tag
          className={`${rootClass}__tag`}
          key={index}
          dataTest={`tag-${tag.id}`}
          onActionClick={onActionClick && onActionClick}
          onClick={onClick}
          tag={tag}
          isSelected={tag.id === selectedId}
        />
      ))}
    </div>
  )
}

export default TagsList
