import { gql } from '@apollo/client'

export default gql`
  query retrieveMessage($messageId: String) {
    retrieveMessage(messageId: $messageId) {
      messageType
      beeEditorPage
      emailContentHtml
      settings {
        title
        subject
        preview
        senderName
        senderEmail
        replyToEmail
        replyToName
        replyToUUID
        senderUUID
        subscriptionCategoryId
        actOnCampaignId
        hasPrePopulatedUTMParams
        analyticsTrackingList {
          parameter
          value
        }
        analyticsTrackingListWithPersonalization {
          parameter
          value
        }
        isTransactionalMessage
        isTemplateAvailableInEBE
        fallbackSenderName
        fallbackSenderEmail
        fallbackSenderUUID
        customTextPart
        abTestIsRunning
        abTestsSent
        isPlainTextOnly
        webinarConfig {
          name
          type
          messageCategory
        }
        isCustomHtml
        lastUpdatedBy
      }
      sendDetails {
        sendToLists {
          srcId
          srcName
          isList
          baseId
          size
          parentId
          children {
            srcId
            srcName
            isList
            baseId
            size
            parentId
            children {
              srcId
              srcName
              isList
              baseId
              size
              parentId
            }
          }
        }
        suppressLists {
          srcId
          srcName
          isList
          baseId
          size
          parentId
          children {
            srcId
            srcName
            isList
            baseId
            size
            parentId
          }
        }
        restricteLists {
          srcId
          srcName
          isList
          baseId
          size
          parentId
          children {
            srcId
            srcName
            isList
            baseId
            size
            parentId
          }
        }
        sendToContacts {
          id
          name
          contacts {
            id
            name
            email
          }
        }
        individualRecipientsSourceIds
        suppressDuplicates
        overrideFatigueRules
        sendMethod {
          launchType
          scheduleTimestamp
          scheduleTimeZone
          staggeredLaunchStart
          staggeredLaunchEnd
          adaptiveLaunchEnd
          adaptiveLaunchStart
          sendingPeriodStartHour
          sendingPeriodEndHour
          sendingPeriodStartMinute
          sendingPeriodEndMinute
          isCreateCRMMsgSentNotes
        }
        assetReportRecipientDetails {
          unique
          title
          type
          column
          reportName
          reportType
          ignoreIPs
          showAllOpens
          key
          count
          showAll
          formTitle
          msgId
          contextPath
          fromEmail
          formViewFilter
          fileName
          formId
          deployType
          deployId
          formListId
          anonymous
        }
      }
      lastEdited
      isValidJson
      id
      errorsCount
      status
    }
  }
`
