import React, { KeyboardEvent, FC, ChangeEvent, useEffect, useState, useRef } from 'react'

import classNames from 'classnames'

import Input, { InputProps, InputType } from '@components/Input/Input'
import InputWithClear from '@components/InputWithClear/InputWithClear'
import { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'

import './Search.css'

export enum SearchType {
  LARGE = 'LARGE',
  DEFAULT = 'DEFAULT',
}

export type SearchProps = {
  incomingValue: string
  placeholder: string
  onChangeHandler: (value: string) => void
  showIcon?: boolean
  searchType?: SearchType
  className?: string
  dataTest?: string
  canClear?: boolean
  clearOnChange?: any[]
  instantChangeUpdate?: (value: string) => void
  isLoading?: boolean
  error?: boolean
}

const rootClass = 'search'

const Search: FC<SearchProps> = (props: SearchProps) => {
  const {
    incomingValue,
    placeholder,
    searchType = SearchType.DEFAULT,
    dataTest = rootClass,
    className = '',
    canClear = false,
    clearOnChange,
    showIcon = true,
    instantChangeUpdate,
    onChangeHandler,
    isLoading,
    error,
  } = props

  const inputRef = useRef<HTMLInputElement>()
  const [value, setValue] = useState<string>('')
  const [searchIcon, setSearchIcon] = useState<SvgNames>(SvgNames.search)
  const [clearIndicatorFill, setClearIndicatorFill] = useState<SvgColor>(SvgColor.TAB_GRAY)

  useEffect(() => setValue(incomingValue), [incomingValue])

  useEffect(() => {
    if (!incomingValue && clearOnChange?.length) {
      setValue('')
    }
  }, clearOnChange)

  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onChangeHandler(value)
      inputRef?.current?.blur()
    }
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value ?? ''
    setValue(newValue)
    if (instantChangeUpdate) {
      instantChangeUpdate(newValue)
    }
  }

  const onClear = () => {
    setValue('')
    onChangeHandler('')
  }

  const onFocus = () => {
    setSearchIcon(SvgNames.searchActive)
    setClearIndicatorFill(SvgColor.TEXT_GRAY)
  }

  const onBlur = () => {
    setSearchIcon(SvgNames.search)
    setClearIndicatorFill(SvgColor.TAB_GRAY)
  }

  const inputProps: InputProps = {
    value: value || '',
    dataTest,
    className: classNames(rootClass, className, {
      [`${rootClass}--large`]: searchType === SearchType.LARGE,
    }),
    inputType: InputType.SEARCH,
    register: inputRef,
    onChange,
    onKeyPress,
    onFocus,
    onBlur,
    placeholder: placeholder ?? 'Search...',
    inputAriaLabel: 'searchInput',
    icon: showIcon ? searchIcon : undefined,
    iconType: SvgType.LARGER_ICON,
    leadingIcon: searchIcon,
    error,
  }
  return canClear ? (
    <InputWithClear isLoading={isLoading} inputProps={{ ...inputProps }} onClear={onClear} clearIndicatorFill={clearIndicatorFill} />
  ) : (
    <Input {...inputProps} />
  )
}

export default React.memo(Search)
