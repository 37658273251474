import React, { FC, useCallback, useContext } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Container from '@components/Container'
import StaticImage from '@components/StaticImage/StaticImage'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ProgramSchedule } from '@graphql/types/query-types'
import { SEGMENTS_LIST_URL } from '@src/pages/ContactSegments/utils/ContactSegmentsSession.utils'
import { ImportContactsV2Context } from '@src/pages/ImportContactsV2/utils/ImportContactsV2.context'
import { useAccountSettings } from '@utils/account/account.utils'
import { dayOfWeek, dayWithOrdinal } from '@utils/date'

import './ImportSuccess.css'

interface ImportSuccessProps {
  className?: string
  dataTest?: string
}

const rootClass = 'import-success'
const rootText = 'ImportContactsV2.Sections.ImportSuccess'
const scheduleRootText = `${rootText}.Schedule`

const ImportSuccess: FC<ImportSuccessProps> = (props: ImportSuccessProps) => {
  const { dataTest = rootClass, className = '' } = props

  const {
    values: { importedNow, schedule },
  } = useContext(ImportContactsV2Context)

  const { hasShowActOnContactsTab } = useAccountSettings()

  const { t } = useTranslation()

  const weeklyScheduleToString = (time: string) => {
    return t(`${scheduleRootText}.Weekly`, { dayOfWeek: dayOfWeek(t, (schedule?.interval ?? 1) - 1), time })
  }

  const dailyScheduleToString = (time: string) => {
    if (schedule.weekdaysOnly) {
      return t(`${scheduleRootText}.DailyOnlyWeekdays`, { count: schedule.interval, time })
    } else {
      return t(`${scheduleRootText}.Daily`, { count: schedule.interval, time })
    }
  }

  const monthlyScheduleToString = (time: string) => {
    return t(`${scheduleRootText}.Monthly`, { day: dayWithOrdinal(schedule.interval), time })
  }

  const scheduleToString = (schedule: ProgramSchedule) => {
    if (!schedule || schedule.isUnscheduled) {
      return ''
    }

    const hour = schedule.hour === 0 ? 12 : schedule.hour
    const minute = (schedule.minute ?? 0) < 10 ? `0${schedule.minute}` : schedule.minute
    const ampm = schedule.ampm === 0 ? 'AM' : 'PM'
    const time = `${hour}:${minute} ${ampm}`

    switch (schedule.type) {
      case 'WEEKLY':
        return weeklyScheduleToString(time)
      case 'MONTHLY':
        return monthlyScheduleToString(time)
      case 'DAILY':
        return dailyScheduleToString(time)
      default:
        return ''
    }
  }

  const { image, headline, body } = (importedNow !== undefined ? importedNow : !schedule.isScheduled)
    ? {
        image: StaticImageNames.download2,
        headline: t('Import in progress'),
        body: t(
          "We’re importing your records. Please close this page and continue using Act-On as normal. Once we finish processing your import, you'll find it in All Contacts."
        ),
      }
    : {
        image: StaticImageNames.checkmark,
        headline: t('FTP import scheduled'),
        body: `${scheduleToString(schedule)}. ${t(
          'Once completed, your contacts will appear in the All Contacts list. You may check the import log for status updates.'
        )}`,
      }

  const onBackToAllContactsClick = useCallback(() => {
    window.open(SEGMENTS_LIST_URL, '_self')
  }, [])

  return (
    <Container className={classNames(rootClass, className)} dataTest={dataTest}>
      <StaticImage name={image} />
      <Typography text={headline} type={TextType.MODAL_HEADLINE_LARGE} weight={TextWeight.MEDIUM} lineHeight={LineHeight.LARGER} />
      <Typography text={body} type={TextType.BODY_TEXT_LIGHT} />
      {hasShowActOnContactsTab && (
        <Button buttonType={ButtonType.PRIMARY} onClick={onBackToAllContactsClick}>
          <Svg name={SvgNames.arrowLeft} type={SvgType.ICON} fill={SvgColor.WHITE} />
          {t('Back to All Contacts')}
        </Button>
      )}
    </Container>
  )
}

export default ImportSuccess
