import React, { FC, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import classNames from 'classnames'

import { useApolloClient, useQuery } from '@apollo/client'
import { MultipleTables } from '@components/TableV2/components/MultipleTables/MultipleTables'
import TableWithLoader from '@components/TableWithLoader/TableWithLoader'
import getProgramContacts from '@graphql/queries/getProgramContacts'
import { GetProgramContactsQuery, GetProgramContactsQueryVariables, ProgramContact } from '@graphql/types/query-types'
import ProgramPerformanceDetailEmptyTable from '@src/pages/programs/dashboard/components/ProgramPerformance/components/ProgramPerformanceDetail/components/ProgramPerformanceTableContainer/components/ProgramPerformanceDetailEmptyTable/ProgramPerformanceDetailEmptyTable'
import ProgramPerformanceTableHeader from '@src/pages/programs/dashboard/components/ProgramPerformance/components/ProgramPerformanceDetail/components/ProgramPerformanceTableContainer/components/ProgramPerformanceTableHeader/ProgramPerformanceTableHeader'
import {
  AllContactsData,
  DEFAULT_STATE,
  EarlyExitsContactsData,
  getColumns,
  getContactData,
  getContainerHeight,
  getDefaultSort,
  getSortColumn,
  getV2Columns,
  InProgramContactsData,
  StandardExistsContactsData,
} from '@src/pages/programs/dashboard/components/ProgramPerformance/components/ProgramPerformanceDetail/components/ProgramPerformanceTableContainer/ProgramPerformanceDetailTableContainer.utils'
import { ProgramManagerLocationStateProps } from '@src/pages/programs/manager/ProgramManager.constants'
import { ContactViewPanel, openContactView } from '@utils/contacts'
import { EmptyState } from '@utils/program/program.constants'
import { ProgramStep } from '@utils/program/ProgramSteps.constants'
import { SortByState } from '@utils/sms.utils'

import { ProgramPerformanceTab } from '../../../../ProgramPerformance.constants'

import './ProgramPerformanceDetailTableContainer.css'

export type ProgramPerformanceDetailTableContainerProps = {
  programId: string
  programPerformanceTab: ProgramPerformanceTab
  tableSubtitle: string
  tableTitle: string
  allowReentrantAddresses: boolean
  emptyState: EmptyState
  totalContacts: number
  programSteps?: ProgramStep[]
  className?: string
  dataTest?: string
  downloadUrl?: string
  activeTabModifier?: string
}

export interface ProgramPerformanceTableContainerState {
  tableData: (AllContactsData | InProgramContactsData | EarlyExitsContactsData | StandardExistsContactsData)[]
  pageSize: number
  pageIndex: number
  sortBy: SortByState[]
  searchTerm: string
  showToastErr: boolean
  totalRecords: number
  pageCount: number
  canPaginate: boolean
  showEmptyTable: boolean
  showEmptySearch: boolean
  showError: boolean
  resetPageIndex: boolean
}

const rootClass = 'program-performance-detail-table-container'
const ProgramPerformanceDetailTableContainer: FC<ProgramPerformanceDetailTableContainerProps> = (
  props: ProgramPerformanceDetailTableContainerProps
) => {
  const {
    programPerformanceTab,
    programId,
    programSteps,
    totalContacts,
    tableTitle,
    allowReentrantAddresses,
    emptyState,
    tableSubtitle,
    downloadUrl,
    className = '',
    dataTest = rootClass,
    activeTabModifier,
  } = props
  const client = useApolloClient()
  const history = useHistory()
  const location = useLocation()
  const locationState: ProgramManagerLocationStateProps = (location?.state as ProgramManagerLocationStateProps) ?? {}

  const [state, setState] = useState<ProgramPerformanceTableContainerState>({
    ...DEFAULT_STATE,
    pageSize: locationState[`${programPerformanceTab}PageSize`] ?? DEFAULT_STATE.pageSize,
    pageIndex: locationState[`${programPerformanceTab}PageIndex`] ?? DEFAULT_STATE.pageIndex,
    totalRecords: totalContacts,
    pageCount: Math.ceil(totalContacts / 10),
    showEmptyTable: totalContacts === 0,
    resetPageIndex: false,
    sortBy: getDefaultSort(programPerformanceTab, locationState),
  })

  const contactState =
    programPerformanceTab === ProgramPerformanceTab.IN_PROGRAM
      ? 'IN_PROGRAM'
      : programPerformanceTab === ProgramPerformanceTab.STANDARD_EXITS
      ? 'EXITED'
      : programPerformanceTab === ProgramPerformanceTab.EARLY_EXITS
      ? 'EARLY_EXIT'
      : 'ALL'

  const showReentryWarning = allowReentrantAddresses && programPerformanceTab !== ProgramPerformanceTab.IN_PROGRAM

  const {
    loading,
    error: pageError,
    data,
  } = useQuery<GetProgramContactsQuery, GetProgramContactsQueryVariables>(getProgramContacts, {
    client: client as any,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      programId: programId,
      stepId: '',
      state: contactState,
      perPage: state.pageSize,
      currentPage: state.pageIndex,
      query: state.searchTerm,
      sortColumn: getSortColumn(state.sortBy[0].id, programPerformanceTab),
      sortDirection: state.sortBy[0].desc ? 'DESC' : 'ASC',
    },
  })

  useEffect(() => {
    const updateData = (data: any) => {
      const { programContacts, page, perPage, totalPages } = data.getProgramContacts

      const tableData = getTableData(programContacts)

      setState({
        ...state,
        tableData,
        pageIndex: page,
        pageSize: perPage,
        pageCount: totalPages,
        showEmptyTable: tableData.length === 0,
        showEmptySearch: tableData.length === 0 && state.searchTerm !== '',
        resetPageIndex: false,
      })
    }

    if (!loading && !pageError) {
      updateData(data)
    } else if (pageError) {
      setState({ ...state, showError: true })
    }
  }, [loading, pageError])

  const getTableData = (programContacts: ProgramContact[]) => {
    return programContacts.map((programContact: ProgramContact) => {
      return getContactData(programContact, programPerformanceTab, programSteps)
    })
  }

  const onSort = (sortBy: SortByState[]) => {
    const validSortBy = sortBy.length > 0 ? sortBy : getDefaultSort(programPerformanceTab, locationState)

    const updatedLocationState = {
      ...(history.location.state as Object),
      [`${programPerformanceTab}SortColumn`]: validSortBy[0].id,
      [`${programPerformanceTab}SortDesc`]: validSortBy[0].desc,
    }

    history.replace(location.pathname, updatedLocationState)

    setState({ ...state, sortBy: validSortBy })
  }

  const fetchData = (pageIndex: number, pageSize: number) => {
    const locationState = {
      ...(history.location.state as Object),
      [`${programPerformanceTab}PageSize`]: pageSize,
      [`${programPerformanceTab}PageIndex`]: pageIndex,
    }

    history.replace(location.pathname, locationState)
    setState({ ...state, pageIndex, pageSize })
  }

  const onSearch = (filter: string) => {
    setState({ ...state, searchTerm: filter, resetPageIndex: true })
  }

  const onRowClicked = (row: any) => openContactView(row?.original.recId, ContactViewPanel.SUMMARY)

  const renderTableHeader = () => {
    return (state.showEmptyTable && !state.showEmptySearch) || pageError ? (
      <ProgramPerformanceTableHeader
        programPerformanceTab={programPerformanceTab}
        tableTitle={tableTitle}
        tableSubtitle={tableSubtitle}
        showReentryWarning={showReentryWarning}
        className={`${rootClass}__empty-table`}
      />
    ) : (
      <ProgramPerformanceTableHeader
        programPerformanceTab={programPerformanceTab}
        downloadUrl={downloadUrl}
        onSearch={onSearch}
        searchTerm={state.searchTerm}
        tableTitle={tableTitle}
        tableSubtitle={tableSubtitle}
        showReentryWarning={showReentryWarning}
      />
    )
  }

  //! delete after using table v2
  const [enableTableV2, setEnableTableV2] = useState(false)

  return (
    <>
      {/*//! delete after using table v2 */}
      {/* Converting to TableV2 is too much effort just to hide this div */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div style={{ display: 'none' }} onClick={() => setEnableTableV2((prev) => !prev)}>
        {' '}
      </div>
      <div
        className={classNames(rootClass, className, {
          [`${rootClass}--${activeTabModifier}`]: activeTabModifier != '',
        })}
        data-test={dataTest}
      >
        {
          <div className={classNames(`${rootClass}__table-wrapper`, { [`${rootClass}__loading`]: loading })}>
            {renderTableHeader()}
            {state.showError || state.showEmptyTable ? (
              <ProgramPerformanceDetailEmptyTable emptySearch={state.showEmptySearch} emptyState={emptyState} error={pageError} />
            ) : (
              <MultipleTables
                enableTableV2={enableTableV2}
                tableV2Props={{
                  loading,
                  data: state.tableData,
                  columns: getV2Columns(programPerformanceTab),
                  manualSorting: true,
                  withoutBorders: true,
                  enableInsideLoader: true,
                  resetPageIndex: state.resetPageIndex,
                  enablePaginate: state.pageCount > 1,
                  sortingBy: state.sortBy,
                  paginationState: { pageSize: state.pageSize, pageIndex: state.pageIndex, controlledPageCount: state.pageCount },
                  onSort,
                  fetchData,
                  onRowClicked,
                }}
                oldTable={
                  <div
                    style={{
                      position: 'relative',
                      minHeight: `${getContainerHeight(state, enableTableV2)}px`,
                      maxHeight: `${getContainerHeight(state, enableTableV2)}px`,
                      transition: 'all .2s ease-out',
                    }}
                  >
                    <TableWithLoader
                      loading={loading}
                      fixedHeight={getContainerHeight(state)}
                      data={state.tableData as any}
                      columns={getColumns(programPerformanceTab)}
                      fetchData={fetchData}
                      onSort={onSort}
                      resetPageIndex={state.resetPageIndex}
                      canPaginate={state.canPaginate}
                      controlledPageCount={state.pageCount}
                      manualSortBy
                      disableMultiSort
                      initialState={{ pageSize: state.pageSize, pageIndex: state.pageIndex, sortBy: state.sortBy }}
                      className={`${rootClass}__table`}
                      onRowClicked={onRowClicked}
                    />
                  </div>
                }
              />
            )}
          </div>
        }
      </div>
    </>
  )
}

export default ProgramPerformanceDetailTableContainer
