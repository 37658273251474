import React, { FC, Suspense } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'

import Loader from '@components/Loader/Loader'
import globals, { rootContext } from '@const/globals'
import { useAccountSettings } from '@utils/account/account.utils'

import allRoutes, { RouteConfig } from './allRoutes'
import CustomRoute from './CustomRoute'

export const getRoutes = (routes: RouteConfig[]) =>
  routes.map((route) => {
    if (route.noProd && globals.isProd()) return null

    if (route.component) {
      return <CustomRoute key={route.path} {...route} />
    }
    return <Route key={route.path} path={route.path} />
  })

const Routes: FC = () => {
  const {
    accountId,
    hasAPDashboards,
    hasAPManager,
    hasCustomObjectsListingPage,
    enableUsersReactPage,
    hasAPListingReact,
    hasContactFragmentsReact,
    hideTwitterProspector,
    enableFormsSectionReact,
    enableLandingPageSectionReact,
    enableEmailDraftsReact,
    hasEmailTemplatesReact,
    hideMarketingCalendar,
    showAgencyMessageUsage,
    enableMarketingNetworkNav,
    savedRowsSharing,
    isAccountAdmin,
  } = useAccountSettings()

  const location = useLocation()
  const programId = location.search.replace(/\?id=/, '')

  return (
    <Suspense fallback={<Loader center />}>
      <Switch>
        {hideMarketingCalendar && (
          <Route path={`${rootContext}/classic/of/mc`}>
            <Redirect to={`${rootContext}/classic`} />
          </Route>
        )}
        {enableMarketingNetworkNav && (
          <Route path={`/acton/agency2/marketingNetwork.jsp?tid=${accountId}`}>
            <Redirect to={`${rootContext}/marketingNetwork/accountManagement`} />
          </Route>
        )}
        {hasAPDashboards && !hasAPManager && (
          <Route path={`${rootContext}/classic/program/dashboard`}>
            <Redirect to={`${rootContext}/classic/program/dashboard?id=${programId}`} />
          </Route>
        )}
        {hasAPDashboards && !hasAPManager && (
          <Route path={`${rootContext}/classic/program`}>
            <Redirect to={`${rootContext}/programs/${programId}/dashboard`} />
          </Route>
        )}
        {!hasAPDashboards && hasAPManager && (
          <Route path={`${rootContext}/classic/program/dashboard`}>
            <Redirect to={`${rootContext}/classic/program/dashboard?id=${programId}`} />
          </Route>
        )}
        {!hasAPDashboards && hasAPManager && (
          <Route path={`${rootContext}/classic/program`}>
            <Redirect to={`${rootContext}/automation/programs/${programId}/program-performance`} />
          </Route>
        )}
        {hasAPListingReact && (
          <Route path={`${rootContext}/classic/programList`}>
            <Redirect to={`${rootContext}/automation/programs`} />
          </Route>
        )}
        {hasAPListingReact && (
          <Route path={`${rootContext}/classic/programTemplateList`}>
            <Redirect to={`${rootContext}/automation/programTemplates`} />
          </Route>
        )}
        {hasContactFragmentsReact && (
          <Route path={`${rootContext}/classic/fragmentsLists`}>
            <Redirect to={`${rootContext}/content/fragments`} />
          </Route>
        )}
        {enableUsersReactPage && (
          <Route path={`${rootContext}/classic/if/account/users.jsp`}>
            <Redirect to={`${rootContext}/settings/users`} />
          </Route>
        )}
        {hideTwitterProspector && (
          <Route path={`${rootContext}/classic/if/smedia/tweetDashboard.jsp`}>
            <Redirect to={`${rootContext}/classic/`} />
          </Route>
        )}
        {enableFormsSectionReact && (
          <Route path={`${rootContext}/classic/formsListing`}>
            <Redirect to={`${rootContext}/content/forms`} />
          </Route>
        )}
        {enableFormsSectionReact && (
          <Route path={`${rootContext}/classic/formTemplateLists`}>
            <Redirect to={`${rootContext}/content/formTemplates`} />
          </Route>
        )}
        {enableLandingPageSectionReact && (
          <Route path={`${rootContext}/classic/landingPageLists`}>
            <Redirect to={`${rootContext}/content/landingPages`} />
          </Route>
        )}
        {enableLandingPageSectionReact && (
          <Route path={`${rootContext}/classic/landingPageTemplates`}>
            <Redirect to={`${rootContext}/content/landingPageTemplates`} />
          </Route>
        )}
        {enableEmailDraftsReact && (
          <Route path={`${rootContext}/classic/draftLists`}>
            <Redirect to={`${rootContext}/outbound/drafts`} />
          </Route>
        )}
        {hasEmailTemplatesReact && (
          <Route path={`${rootContext}/classic/templateLists`}>
            <Redirect to={`${rootContext}/content/emailTemplates`} />
          </Route>
        )}
        {!showAgencyMessageUsage && (
          <Route path={`${rootContext}/marketingNetwork/messageUsage`}>
            <Redirect to={`${rootContext}/dashboard`} />
          </Route>
        )}
        {!hasCustomObjectsListingPage && (
          <Route path={`${rootContext}/customData`}>
            <Redirect to={`${rootContext}/classic/`} />
          </Route>
        )}
        {(!savedRowsSharing || !isAccountAdmin) && (
          <Route path={`${rootContext}/savedRowsCopy`}>
            <Redirect to={`${rootContext}/classic/`} />
          </Route>
        )}

        <Route path={`${rootContext}/classic/if/inbound/(websiteWatch|blogWatch|twitterWatch|mediaWatch|competitorsList).jsp`}>
          <Redirect to={`${rootContext}/classic/`} />
        </Route>
        {...getRoutes(allRoutes)}
        <Route path="/" exact={true}>
          <Redirect to={`${rootContext}/classic/`} />
        </Route>
        <Route path="" exact={true}>
          <Redirect to={`${rootContext}/classic/`} />
        </Route>
        <Route path="*">
          <Redirect to={`${rootContext}/classic/404`} />
        </Route>
      </Switch>
    </Suspense>
  )
}

export default Routes
