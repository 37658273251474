import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import ProgressBar, { ProgressBarStep } from '@components/ProgressBar/ProgressBar'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import FormsUpgradeConfigure from '@src/pages/UpgradeAssistant/components/FormsUpgradeModal/components/FormsUpgradeConfigure/FormsUpgradeConfigure'
import FormsUpgradeConfirmation from '@src/pages/UpgradeAssistant/components/FormsUpgradeModal/components/FormsUpgradeConfirmation/FormsUpgradeConfirmation'
import FormsUpgradeMapFields from '@src/pages/UpgradeAssistant/components/FormsUpgradeModal/components/FormsUpgradeMapFields/FormsUpgradeMapFields'
import FormsUpgradeReview from '@src/pages/UpgradeAssistant/components/FormsUpgradeModal/components/FormsUpgradeReview/FormsUpgradeReview'
import { SelectFormsStep } from '@src/pages/UpgradeAssistant/components/FormsUpgradeModal/components/SelectFormsStep/SelectFormsStep'
import {
  ArchiveSettings,
  FormFieldDataType,
  FormFieldUsage,
  FormsUpgradeModalContext,
  FormsUpgradeModalInitialState,
  FormsUpgradeModalState,
} from '@src/pages/UpgradeAssistant/components/FormsUpgradeModal/context/FormsUpgradeModal.context'
import {
  FormsUpgradeSteps,
  getConfirmationModalProps,
  getInitialSteps,
  getItemById,
  rootText,
} from '@src/pages/UpgradeAssistant/components/FormsUpgradeModal/FormsUpgradeModal.utils'
import { useFormsUpgradeModalRequests } from '@src/pages/UpgradeAssistant/components/FormsUpgradeModal/GraphQL/FormsUpgradeModalRequests.graphQL'
import FormsUpgradeWarningModal from '@src/pages/UpgradeAssistant/components/FormsUpgradeWarningModal/FormsUpgradeWarningModal'
import { ItemType } from '@utils/categorization'
import { Form } from '@utils/forms'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'
import { useUnifiedContactList } from '@utils/hooks/useUnifiedContactList'

import './FormsUpgradeModal.css'

interface FormsUpgradeModalProps {
  className?: string
  dataTest?: string
  onClose: VoidFunction
  isOpen: boolean
  externalId?: string
  externalIdType?: ItemType
}

const rootClass = 'forms-upgrade-modal'

const primaryButtonTextByStep: { [key in FormsUpgradeSteps]: string } = {
  [FormsUpgradeSteps.SELECT_FORMS]: 'Next',
  [FormsUpgradeSteps.MAP_FIELDS]: 'Next',
  [FormsUpgradeSteps.CONFIGURE]: 'Review',
  [FormsUpgradeSteps.REVIEW]: 'Upgrade now',
  [FormsUpgradeSteps.CONFIRMATION]: 'Done',
}

const FormsUpgradeModal: FC<FormsUpgradeModalProps> = (props: FormsUpgradeModalProps) => {
  const { dataTest = rootClass, className = '', onClose, isOpen, externalId, externalIdType = ItemType.FORM } = props

  const { t } = useTranslation()

  const [state, setState] = useState<FormsUpgradeModalState>({
    ...FormsUpgradeModalInitialState,
    steps: getInitialSteps(t),
  })

  const { archiveSetting, showDeleteConfirmationModal, showExitConfirmationModal, steps, formFields, forms, isWarningModalOpen, isWarningConfirmed } =
    state

  const { unifiedListFieldMappings = [], loading: loadingUnifiedListFieldMappings } = useUnifiedContactList()
  const { getItemByIdRequest } = useFormsUpgradeModalRequests()

  const update = useDeepUpdate(setState)

  const deleteConfirmationModalShownRef = useRef(false)

  const currentStep = useMemo(() => steps.find(({ isActive }) => isActive), [steps])
  const currentStepIndex = useMemo(() => steps.findIndex(({ isActive }) => isActive), [steps])

  const nextButtonDisabled = useMemo(() => {
    if (currentStep?.key === FormsUpgradeSteps.SELECT_FORMS) {
      return forms.length === 0
    }
    if (currentStep?.key === FormsUpgradeSteps.MAP_FIELDS) {
      return (
        formFields.length === 0 ||
        formFields.some(
          ({ isMapped, fieldMapping, isValid, dataType }) =>
            isMapped && (dataType !== FormFieldDataType.FileField ? !fieldMapping || !isValid : false)
        )
      )
    }
    return false
  }, [currentStep, formFields, forms])

  const primaryButtonTooltipByStep: { [key in FormsUpgradeSteps]: string } = useMemo(
    () => ({
      [FormsUpgradeSteps.SELECT_FORMS]: 'Select forms to continue',
      [FormsUpgradeSteps.MAP_FIELDS]: formFields?.some(({ isValid }) => !isValid) ? 'Fix errors to continue' : 'Map fields to continue',
      [FormsUpgradeSteps.CONFIGURE]: '',
      [FormsUpgradeSteps.REVIEW]: '',
      [FormsUpgradeSteps.CONFIRMATION]: '',
    }),
    [formFields]
  )
  const primaryButtonTooltip = primaryButtonTooltipByStep[currentStep?.key as FormsUpgradeSteps]

  const handleClose = () => {
    const visibleSteps = steps.filter(({ hidden }) => !hidden)
    const hasChanges = visibleSteps.some(({ isCompleted }) => isCompleted)
    const allStepsCompleted = visibleSteps.every(({ isCompleted }) => isCompleted)
    if (hasChanges && !allStepsCompleted) {
      update({ showExitConfirmationModal: true })
    } else {
      onClose()
    }
  }

  const onStepChange = useCallback(
    (newStep: ProgressBarStep, currentStepCompleted = false) => {
      if (
        currentStep?.key === FormsUpgradeSteps.CONFIGURE &&
        newStep.key === FormsUpgradeSteps.REVIEW &&
        archiveSetting === ArchiveSettings.DELETE &&
        !deleteConfirmationModalShownRef.current
      ) {
        update({ showDeleteConfirmationModal: true })
      } else if (currentStep?.key !== FormsUpgradeSteps.CONFIRMATION) {
        update({
          steps: steps.map((step) => {
            if (step.key === currentStep?.key) {
              return { ...step, isActive: false, isCompleted: step.isCompleted || currentStepCompleted }
            } else if (step.key === newStep.key) {
              return { ...step, isActive: true }
            } else {
              return step
            }
          }),
        })
      }
    },
    [archiveSetting, currentStep, steps, update]
  )

  const goToPreviousStep = useCallback(() => {
    update({ isWarningConfirmed: false, isWarningModalOpen: false })
    if (currentStepIndex > 0) {
      const newStepIndex = currentStepIndex - 1
      onStepChange(steps[newStepIndex])
    }
  }, [currentStepIndex, onStepChange, steps])

  const goToNextStep = useCallback(() => {
    if (currentStep?.key === FormsUpgradeSteps.MAP_FIELDS) {
      if (!isWarningConfirmed && shouldShowWarningModal) {
        update({ isWarningModalOpen: true })
        return
      }
    }

    if (currentStepIndex < steps.length - 1) {
      onStepChange(steps[currentStepIndex + 1], true)
    } else {
      onClose()
    }
  }, [currentStep, currentStepIndex, steps, onClose, isWarningConfirmed, formFields, update])

  const onReset = useCallback(() => {
    update({
      ...FormsUpgradeModalInitialState,
      selectedSources: undefined,
      steps: getInitialSteps(t),
    })
  }, [t, update])

  const onDeleteConfirmationModalAnswer = useCallback(
    (answer: YesNo) => {
      if (answer === YesNo.YES) {
        deleteConfirmationModalShownRef.current = true
        goToNextStep()
      }
      update({ showDeleteConfirmationModal: false })
    },
    [goToNextStep, update]
  )

  const onExitConfirmationModalAnswer = useCallback(
    (answer: YesNo) => {
      if (answer === YesNo.YES) {
        onClose()
      }
      update({ showExitConfirmationModal: false })
    },
    [onClose, update]
  )

  const goToConfigStep = useCallback(() => {
    if (currentStepIndex < steps.length - 1) {
      onStepChange(steps[currentStepIndex + 1], true)
    } else {
      onClose()
    }
  }, [currentStepIndex, onClose, onStepChange, steps])

  useEffect(() => {
    if (!isOpen) {
      onReset()
    }
  }, [isOpen])

  useEffect(() => {
    if (externalId) {
      getItemById(getItemByIdRequest, externalId, externalIdType, setState)
    }
  }, [externalId])

  const confirmationModalProps = useMemo(
    () =>
      getConfirmationModalProps(
        showExitConfirmationModal,
        showDeleteConfirmationModal,
        onDeleteConfirmationModalAnswer,
        onExitConfirmationModalAnswer,
        forms,
        t
      ),
    [forms, onDeleteConfirmationModalAnswer, onExitConfirmationModalAnswer, showDeleteConfirmationModal, showExitConfirmationModal, t]
  )

  const handleConfirmWarningModal = () => {
    update({ isWarningConfirmed: true, isWarningModalOpen: false })
    goToConfigStep()
  }
  const getInvalidForms = (forms: Form[], formFields: FormFieldUsage[]): Form[] => {
    return forms.filter(
      (form) =>
        !formFields.some(
          (field) => field.dataType === 'EMAIL' && Array.isArray(field.formsInfo) && field.formsInfo.some((info) => info?.id === form.externalId)
        )
    )
  }

  const header = (
    <ModalHeader headerType={ModalHeaderType.List} className={`${rootClass}__header`} closeButton={handleClose}>
      {t(`${rootText}Title`)}
    </ModalHeader>
  )

  const warningFormNames = useMemo(() => {
    return getInvalidForms(forms, formFields).map((form) => form.name)
  }, [forms, formFields])

  const shouldShowWarningModal = useMemo(() => {
    return getInvalidForms(forms, formFields).length > 0
  }, [forms, formFields])

  return (
    <FormsUpgradeModalContext.Provider value={{ values: state, update }}>
      <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header} fullScreen>
        <ConfirmationModal isDelete {...confirmationModalProps} />
        <ModalBody className={`${rootClass}__body`}>
          <ProgressBar steps={steps} onClick={onStepChange} />
          {currentStep?.key === FormsUpgradeSteps.SELECT_FORMS && <SelectFormsStep />}
          {currentStep?.key === FormsUpgradeSteps.MAP_FIELDS && (
            <FormsUpgradeMapFields
              unifiedListFieldMappings={unifiedListFieldMappings}
              loadingUnifiedListFieldMappings={loadingUnifiedListFieldMappings}
            />
          )}
          {currentStep?.key === FormsUpgradeSteps.CONFIGURE && <FormsUpgradeConfigure />}
          {currentStep?.key === FormsUpgradeSteps.REVIEW && <FormsUpgradeReview forms={forms} />}
          {currentStep?.key === FormsUpgradeSteps.CONFIRMATION && <FormsUpgradeConfirmation onReset={onReset} onClose={onClose} />}
        </ModalBody>
        <ModalFooter footerType={ModalFooterType.List} className={`${rootClass}__footer`}>
          {currentStep?.key !== FormsUpgradeSteps.CONFIRMATION ? (
            <>
              <div className={`${rootClass}__footer__extra`}>
                <Typography
                  text={t(`${rootText}Footer.Steps`, { currentStepIndex: currentStepIndex + 1, lastStep: steps.length - 1 })}
                  type={TextType.BODY_TEXT}
                  weight={TextWeight.MEDIUM}
                />
              </div>
              <Button
                buttonType={ButtonType.SECONDARY}
                onClick={goToPreviousStep}
                dataTest={`${dataTest}-button-tertiary`}
                disabled={steps[0].isActive}
              >
                {t('Back')}
              </Button>
            </>
          ) : (
            <></>
          )}
          <Tooltip
            hide={!nextButtonDisabled || !primaryButtonTooltip}
            trigger={
              <Button buttonType={ButtonType.PRIMARY} onClick={goToNextStep} dataTest={`${dataTest}-button-primary`} disabled={nextButtonDisabled}>
                {t(primaryButtonTextByStep[currentStep?.key as FormsUpgradeSteps])}
              </Button>
            }
          >
            {t(primaryButtonTooltip)}
          </Tooltip>
        </ModalFooter>
      </Modal>
      {isWarningModalOpen && <FormsUpgradeWarningModal onClose={handleConfirmWarningModal} isOpen formNames={warningFormNames} />}
    </FormsUpgradeModalContext.Provider>
  )
}

export default FormsUpgradeModal
