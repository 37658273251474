import React, { FC } from 'react'

import classNames from 'classnames'

import Svg, { SvgType } from '@components/Svg'
import SvgNames, { SvgNamesStrings } from '@components/Svg/SvgNames'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './Switch.css'

export enum SwitchOptions {
  GRAPH = 'graphLine',
  BAR = 'chartBar',
  PIE = 'pie',
  DESKTOP = 'computerSolid',
  MOBILE = 'cellphoneSolid',
  NUMBER = '#',
  PERCENT = '%',
}

export interface SwitchOption {
  label: string | SwitchOptions
  tooltip?: string
}

interface Props {
  className?: string
  dataTest?: string
  options: SwitchOption[]
  selected: string
  disabled?: boolean
  onClick: (label: string) => void
  isToggle?: boolean
  iconSize?: SvgType
}

const rootClass = 'switch'

const Switch: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '', selected, onClick, options, disabled, isToggle = false, iconSize = SvgType.ICON } = props

  const { t } = useTranslation()

  const renderButton = (switchOption: SwitchOption) => {
    const selectedTextType = isToggle ? TextType.BODY_TEXT_WHITE_SMALL : TextType.BODY_TEXT_WHITE
    const unselectedTextType = isToggle ? TextType.BODY_TEXT_SMALL : TextType.BODY_TEXT_LIGHT
    const isSelected = selected === switchOption.label

    return (
      <button
        onClick={() => onClick(switchOption.label)}
        key={`option-${switchOption.label}`}
        className={classNames(`${rootClass}__body`, {
          [`${rootClass}__selected`]: isSelected,
          [`${rootClass}__disabled`]: disabled,
          [`${rootClass}__is-toggle`]: isToggle,
        })}
        data-test={`${dataTest}-${switchOption.label}`}
        disabled={disabled}
      >
        {switchOption.label in SvgNames ? (
          <Svg name={SvgNames[switchOption.label as SvgNamesStrings]} type={iconSize} />
        ) : (
          <Typography
            text={`${t(switchOption.label)}`.toUpperCase()}
            type={isSelected ? selectedTextType : unselectedTextType}
            lineHeight={LineHeight.MEDIUM_SMALL}
            weight={isToggle ? TextWeight.MEDIUM : TextWeight.REGULAR}
          />
        )}
      </button>
    )
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {options.map(({ tooltip, ...option }, index) => {
        const button = renderButton(option)
        return tooltip ? (
          <Tooltip key={`tooltip-${index}`} trigger={button}>
            {tooltip}
          </Tooltip>
        ) : (
          button
        )
      })}
    </div>
  )
}

export default Switch
