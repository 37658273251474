import React, { FC, KeyboardEvent } from 'react'

import classNames from 'classnames'

import { isJest } from '@const/globals'

import SvgNames from './SvgNames'

import './svg.css'

if (!isJest()) {
  const files = require.context('../../../assets', true, /.*\.svg$/)
  files.keys().forEach(files)
}

export enum SvgType {
  TINY_ICON = 'TINY_ICON',
  VERY_SMALL_ICON = 'VERY_SMALL_ICON',
  SMALLER_ICON = 'SMALLER_ICON',
  LIGHT_SMALLER_ICON = 'LIGHT_SMALLER_ICON',
  SMALL_ICON = 'SMALL_ICON',
  ICON = 'ICON',
  ICON_MEDIUM = 'ICON_MEDIUM',
  MEDIUM = 'MEDIUM',
  LARGER_ICON = 'LARGER_ICON',
  STANDARD_BUTTON_ICON = 'STANDARD_BUTTON_ICON',
  MEDIUM_LARGE_ICON = 'MEDIUM_LARGE_ICON',
  LARGE_ICON = 'LARGE_ICON',
  VERY_LARGE_ICON = 'VERY_LARGE_ICON',
  EXTRA_LARGE_ICON = 'EXTRA_LARGE_ICON',
  SUPER_LARGE_ICON = 'SUPER_LARGE_ICON',
  HUGE_ICON = 'HUGE_ICON',
  SELECTV2_OPTION_ICON = 'SELECTV2_OPTION_ICON',
}

export enum SvgColor {
  BACKGROUND_GRAY = 'background-gray',
  BLACK = 'black',
  BUTTON_GRAY = 'button-gray',
  BUTTON_HOVER_GRAY = 'button-hover-gray',
  ICON_GRAY = 'icon-gray',
  DARK_BLUE = 'dark-blue',
  ERROR = 'error',
  ERROR_TEXT = 'error-text',
  HOVER_INDICATOR_BACKGROUND = 'hover-indicator-background',
  LIGHT_GRAY = 'light-gray',
  NAVIGATION_BUTTON_HOVER = 'navigation-button-hover',
  REMOVE_RED = 'remove-red',
  SUCCESS_GREEN = 'success-green',
  TAB_GRAY = 'tab-gray',
  TEAL = 'teal',
  TEAL_DARK = 'teal-dark',
  TEXT_GRAY = 'text-gray',
  TEXT_TEAL = 'text-teal',
  WHITE = 'white',
  YELLOW_CAUTION = 'yellow-caution',
  LIGHT_TEAL = 'light-teal',
  BORDER_GRAY = 'border-gray',
  LINK_BLUE = 'hover-create-option-icon',
}

export enum SvgContainerType {
  STANDARD = 'standard',
  LARGE = 'large',
}

const rootClass = 'svg'

export interface SvgProps {
  name: SvgNames
  type?: SvgType
  className?: string
  reference?: React.Ref<SVGSVGElement>
  dataTest?: string
  style?: React.CSSProperties
  tabIndex?: number
  fill?: SvgColor
  hoverFill?: SvgColor
  onKeyDown?: (event: KeyboardEvent<SVGSVGElement>) => void
  containerType?: SvgContainerType
}

const Svg: FC<SvgProps> = (props: SvgProps) => {
  const { name, className, reference, style, dataTest = 'svg', type, tabIndex, onKeyDown, fill, hoverFill, containerType } = props

  const onKeyDownHandler = (event: React.KeyboardEvent<SVGSVGElement>) => {
    if (event.keyCode === 32) {
      event.preventDefault()
      if (onKeyDown) {
        onKeyDown(event)
      }
    }
  }

  const renderSvg = () => (
    <svg
      ref={reference}
      tabIndex={tabIndex}
      role="img"
      aria-label={name}
      className={classNames(rootClass, className, {
        [`${rootClass}--hover-${hoverFill}`]: !!hoverFill,
        [`${rootClass}--fill-${fill}`]: fill,
        [`${rootClass}--tiny-icon`]: type === SvgType.TINY_ICON,
        [`${rootClass}--very-small-icon`]: type === SvgType.VERY_SMALL_ICON,
        [`${rootClass}--smaller-icon`]: type === SvgType.SMALLER_ICON,
        [`${rootClass}--light-smaller-icon`]: type === SvgType.LIGHT_SMALLER_ICON,
        [`${rootClass}--small-icon`]: type === SvgType.SMALL_ICON,
        [`${rootClass}--icon`]: type === SvgType.ICON,
        [`${rootClass}--icon-medium`]: type === SvgType.ICON_MEDIUM,
        [`${rootClass}--medium-icon`]: type === SvgType.MEDIUM,
        [`${rootClass}--standard-button-icon`]: type === SvgType.STANDARD_BUTTON_ICON,
        [`${rootClass}--larger-icon`]: type === SvgType.LARGER_ICON,
        [`${rootClass}--medium-large-icon`]: type === SvgType.MEDIUM_LARGE_ICON,
        [`${rootClass}--large-icon`]: type === SvgType.LARGE_ICON,
        [`${rootClass}--very-large-icon`]: type === SvgType.VERY_LARGE_ICON,
        [`${rootClass}--extra-large-icon`]: type === SvgType.EXTRA_LARGE_ICON,
        [`${rootClass}--super-large-icon`]: type === SvgType.SUPER_LARGE_ICON,
        [`${rootClass}--huge-icon`]: type === SvgType.HUGE_ICON,
        [`${rootClass}--selectv2-option-icon`]: type === SvgType.SELECTV2_OPTION_ICON,
      })}
      data-test={dataTest}
      style={style}
      onKeyDown={onKeyDownHandler}
    >
      <use xlinkHref={`#${name}`}></use>
    </svg>
  )

  return !!containerType ? (
    <div className={classNames(`${rootClass}__container`, `${rootClass}__container-${containerType}`)}>{renderSvg()}</div>
  ) : (
    renderSvg()
  )
}

export default Svg
