import React, { FC, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { YesNo } from '@components/ConfirmationModal'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import DropDown from '@components/DropDown'
import { DropDownType } from '@components/DropDown/DropDown'
import DropDownActions from '@components/DropDownActions/DropDownActions'
import Loader from '@components/Loader'
import PageContainer from '@components/PageContainer'
import PageHeader from '@components/PageHeader'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import ProgressBar, { ProgressBarStep } from '@components/ProgressBar/ProgressBar'
import StatusToast, { Status } from '@components/StatusToast/StatusToast'
import { SvgNames } from '@components/Svg'
import { useTranslation } from '@const/globals'
import ExportFileSelectionScreen from '@src/pages/ExportContacts/components/ExportFileSelectionScreen/ExportFileSelectionScreen'
import ExportOptionsScreen from '@src/pages/ExportContacts/components/ExportOptionsScreen/ExportOptionsScreen'
import ReviewExport from '@src/pages/ExportContacts/components/ReviewExport/ReviewExport'
import { SelectExportFieldsScreen } from '@src/pages/ExportContacts/components/SelectExportFieldsScreen/SelectExportFieldsScreen'
import { useExportContactsRequest } from '@src/pages/ExportContacts/GraphQL/ExportContacts.graphql'
import { ExportContactsSteps } from '@src/pages/ExportContacts/utils/ExportContacts.constants'
import { ExportContactsContext } from '@src/pages/ExportContacts/utils/ExportContacts.context'

import './ExportContacts.css'

interface ExportContactsProps {
  className?: string
  dataTest?: string
  currentStep: ProgressBarStep
}

const rootClass = 'export-contacts'

const ExportContacts: FC<ExportContactsProps> = (props: ExportContactsProps) => {
  const { dataTest = rootClass, currentStep } = props
  const { t } = useTranslation()
  const history = useHistory()
  const {
    values: {
      steps,
      statusToast: { statusMessage, showStatusToast, status },
      loading,
      exportSyncJobId,
    },
    closeStatusToast,
    handleOnSectionChange,
    update,
  } = useContext(ExportContactsContext)
  const { deleteSyncSettingsRequest } = useExportContactsRequest()
  const [deleteDropdownOpen, setDeleteDropdownOpen] = useState(false)
  const [deletingSyncSettings, setDeletingSyncSettings] = useState(false)
  return (
    <PageContainer className={rootClass} dataTest={dataTest}>
      {showStatusToast && <StatusToast status={status} message={statusMessage} closeStatus={closeStatusToast} />}
      <PositionContainer>
        <PageHeader className={`${rootClass}__header`} primaryText={t('ExportContacts.Header')} leftContent linkBack>
          {exportSyncJobId && (
            <DropDown
              isOpen={deleteDropdownOpen}
              toggleOpen={() => setDeleteDropdownOpen(!deleteDropdownOpen)}
              type={DropDownType.STYLED}
              hasOverflowIcon
              className={`${rootClass}__dropdown-top`}
            >
              <DropDownActions
                menuItems={[
                  {
                    text: t('Delete sync settings'),
                    icon: SvgNames.delete,
                    onClick: () => setDeletingSyncSettings(true),
                  },
                ]}
                closeDropDown={() => setDeleteDropdownOpen(!deleteDropdownOpen)}
              />
            </DropDown>
          )}
        </PageHeader>
        {loading ? (
          <Loader center />
        ) : (
          <>
            {deletingSyncSettings && (
              <DeleteConfirmationModal
                isOpen
                title={t('Are you sure?')}
                deleteButtonText={t('Delete')}
                body={t('ExportContacts.DeleteSync.Body')}
                onAnswer={(answer) => {
                  setDeletingSyncSettings(false)
                  if (answer === YesNo.YES) {
                    update({ loading: true })
                    deleteSyncSettingsRequest().then((result) => {
                      if (result) {
                        history.goBack()
                      } else {
                        update({
                          statusToast: {
                            statusMessage: t('Something went wrong on our end. Please try again.'),
                            status: Status.FAIL,
                            showStatusToast: true,
                          },
                          loading: false,
                        })
                      }
                    })
                  }
                }}
              />
            )}
            <ProgressBar className={`${rootClass}__progress-bar`} steps={steps} onClick={(step) => handleOnSectionChange(step.key)} />
            {currentStep?.key === ExportContactsSteps.SELECT_FILE && <ExportFileSelectionScreen />}
            {currentStep?.key === ExportContactsSteps.EXPORT_OPTIONS && <ExportOptionsScreen />}
            {currentStep?.key === ExportContactsSteps.CHOOSE_FIELDS && <SelectExportFieldsScreen />}
            {currentStep?.key === ExportContactsSteps.REVIEW_EXPORT && <ReviewExport />}
          </>
        )}
      </PositionContainer>
    </PageContainer>
  )
}

export default ExportContacts
