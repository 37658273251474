import React, { FC, useCallback, useRef, useState } from 'react'

import Button, { ButtonType } from '@components/Button'
import { useTranslation } from '@const/globals'
import FavIconExpanded from '@src/pages/Content/Branding/tabs/WebIdentity/FavIcon/components/FavIconExpanded/FavIconExpanded'

interface Props {
  url: string
  isDefaultURL: boolean
  isValidURL: boolean
  onSave: (url: string) => void
  onRemoveImage: () => void
  onCancel: () => void
  dataTest?: string
}

const rootClass = 'fav-icon__form'

const FavIconForm: FC<Props> = (props) => {
  const { url, isDefaultURL, isValidURL, onSave, onRemoveImage, onCancel, dataTest = rootClass } = props
  const { t } = useTranslation()
  const [disableSave, setDisableSave] = useState<boolean>(true)
  const urlRef = useRef<string>(url)

  const handleSave = useCallback(() => {
    onSave(urlRef.current)
  }, [])

  const handleImageChange = useCallback(
    (imageUrl: string) => {
      urlRef.current = imageUrl
      setDisableSave(imageUrl === url)
    },
    [url]
  )

  return (
    <div className={rootClass} data-test={dataTest}>
      <FavIconExpanded
        imageURL={url}
        onImageChange={handleImageChange}
        onRemoveImage={onRemoveImage}
        isDefaultURL={isDefaultURL}
        isValidURL={isValidURL}
      />
      <div className={`${rootClass}__actions`}>
        <Button buttonType={ButtonType.PRIMARY} onClick={handleSave} disabled={disableSave} dataTest={`${dataTest}-save-button`}>
          {t('Save')}
        </Button>
        <Button buttonType={ButtonType.TEXT} onClick={onCancel} dataTest={`${dataTest}-cancel-button`}>
          {t('Cancel')}
        </Button>
      </div>
    </div>
  )
}

export default FavIconForm
