import { EmptyListingProps, EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { TableWithLoaderProps } from '@components/TableWithLoader/TableWithLoader'

interface Props {
  t: (s: any, options?: any) => any
  haveData: boolean
  searchTerm: string
}

const getTableProps: (props: Props) => Partial<TableWithLoaderProps & EmptyListingProps> = ({ t, haveData, searchTerm }) => ({
  size: EmptyListingSize.MEDIUM,
  hideIcon: false,
  headline: !haveData ? t('Marketing.Users.Table.Empty.Search.Headline', { searchTerm }) : undefined,
  text: !haveData ? t('Marketing.Users.Table.Empty.Search.Text') : undefined,
  imgSrc: !haveData ? StaticImageNames.emptySearch : undefined,
})

export default getTableProps
