import { gql } from '@apollo/client'

export default gql`
  mutation duplicateForm($formId: String!, $newName: String, $tags: [LabelDto], $folderId: Int) {
    duplicateForm(formId: $formId, newName: $newName, tags: $tags, folderId: $folderId) {
      id
      name
    }
  }
`
