import React, { FC, useCallback, useMemo, useState } from 'react'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import BulkActionsModal from '@components/BulkActionsModal/BulkActionsModal'
import Modal from '@components/Modal'
import { useTranslation } from '@const/globals'
import addSforceUser from '@graphql/mutations/addSforceUser'
import loadCrmSalesUsers from '@graphql/queries/loadCrmSalesUsers'
import { AddSforceUserMutation, MutationAddSforceUserArgs } from '@graphql/types/mutation-types'
import { LoadCrmSalesUsersQuery, LoadCrmSalesUsersQueryVariables, SforceUserResponse } from '@graphql/types/query-types'
import { useSalesUsers } from '@src/pages/Settings/Users/context/SalesUsersContext'
import { useAllUsers } from '@src/pages/Settings/Users/context/UsersContext'
import ImportSalesUsersModalContent from '@src/pages/Settings/Users/tabs/SalesUsers/components/ImportSalesUsersModal/ImportSalesUsersModalContent'
import ImportSalesUsersModalHeader from '@src/pages/Settings/Users/tabs/SalesUsers/components/ImportSalesUsersModal/ImportSalesUsersModalHeader'
import { filterNotEmptyArray } from '@utils/array'
import { logNewRelicError } from '@utils/new-relic.utils'

import './ImportSalesUsersModal.css'

interface ImportSalesUsersModalProps {
  isOpen: boolean
  onCancel: () => void
  dataTest?: string
}

const getSforceUserName: (user: SforceUserResponse) => string = ({ firstName, lastName, email }) => {
  let result = ''
  if (firstName) {
    result += firstName
  }
  if (lastName) {
    result += result ? ` ${lastName}` : lastName
  }
  return result ?? email
}

const rootClass = 'import-sales-users-modal'

const ImportSalesUsersModalContainer: FC<ImportSalesUsersModalProps> = (props: ImportSalesUsersModalProps) => {
  const { isOpen, onCancel, dataTest = rootClass } = props
  const { t } = useTranslation()
  const { setToastStatus } = useAllUsers()
  const { refetchRefs } = useSalesUsers()
  const [importErrorInfo, setImportErrorInfo] = useState<{ imported: string[]; notImported: string[] } | undefined>(undefined)

  const client = useApolloClient()
  const { data, loading, refetch, error } = useQuery<LoadCrmSalesUsersQuery, LoadCrmSalesUsersQueryVariables>(loadCrmSalesUsers, {
    client,
    fetchPolicy: 'network-only',
    variables: { userTypes: '', forcePull: false },
    notifyOnNetworkStatusChange: true,
  })

  refetchRefs.refreshCRMUsers = refetch

  const users = useMemo<SforceUserResponse[]>(() => (data?.loadCrmSalesUsers?.sForceUsers ?? []).filter(filterNotEmptyArray), [data])
  const maxUsers = useMemo<number>(() => data?.loadCrmSalesUsers?.maxSforceSalesUsers || 0, [data?.loadCrmSalesUsers?.maxSforceSalesUsers])
  const numAvailable = useMemo<number>(
    () => data?.loadCrmSalesUsers?.numSforceSalesUsersAvailable || 0,
    [data?.loadCrmSalesUsers?.numSforceSalesUsersAvailable]
  )

  const [importUsers, { loading: saveLoading }] = useMutation<AddSforceUserMutation, MutationAddSforceUserArgs>(addSforceUser, {
    client,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  if (error) {
    logNewRelicError(error)
  }

  const onAdd = useCallback(
    (ids: string[]) => {
      onCancel()
      importUsers({
        variables: {
          ids,
        },
      })
        .then(({ data }) => {
          if (data?.addSforceUser?.status === 'ok') {
            const importedIds: string[] =
              (data?.addSforceUser?.sForceUsers?.map((user) => user?.userId).filter((id) => id && id !== '0') as string[]) ?? []
            if (ids.length > importedIds.length) {
              const importedUsers: SforceUserResponse[] = (data.addSforceUser.sForceUsers ?? []).filter(filterNotEmptyArray)
              const imported = importedUsers.filter(({ userId }) => importedIds.includes(userId ?? '')).map(getSforceUserName)
              const notImported = importedUsers.filter(({ userId }) => !importedIds.includes(userId ?? '')).map(getSforceUserName)
              setImportErrorInfo({ imported, notImported })
            } else {
              setToastStatus({
                showStatus: true,
                title: t('Success!'),
                statusMessage: t('Import.Sales.Users.Success.Message', { count: ids.length }),
                successStatus: true,
              })
              refetch()
              refetchRefs.refreshSalesUsers()
            }
          } else {
            setToastStatus({
              showStatus: true,
              statusMessage: t('Import.Sales.Users.Error.Message'),
              successStatus: false,
            })
          }
        })
        .catch((error) => {
          logNewRelicError(error)
        })
    },
    [importUsers, users]
  )

  const onBulkModalClose = useCallback(() => {
    setImportErrorInfo(undefined)
    refetch()
    refetchRefs.refreshSalesUsers()
  }, [refetch])

  const refreshCRM = useCallback(() => {
    refetch({ userTypes: '', forcePull: true })
      .then(({ data }) => {
        if (data?.loadCrmSalesUsers?.status === 'ok') {
          setToastStatus({
            showStatus: true,
            title: t('Success!'),
            statusMessage: t('Refresh.CRM.Data.Success.Message'),
            successStatus: true,
          })
        } else {
          setToastStatus({
            showStatus: true,
            statusMessage: t('Refresh.CRM.Data.Error.Message'),
            successStatus: false,
          })
        }
      })
      .catch((error) => {
        logNewRelicError(error)
      })
  }, [refetch])

  return (
    <>
      <Modal isOpen={isOpen} className={rootClass} dataTest={dataTest} header={<ImportSalesUsersModalHeader refreshCRM={refreshCRM} />}>
        <ImportSalesUsersModalContent
          loading={loading || saveLoading}
          users={users}
          maxUsers={maxUsers}
          numAvailable={numAvailable}
          isOpen={isOpen}
          onAdd={onAdd}
          onCancel={onCancel}
          refreshCRM={refreshCRM}
          dataTest={dataTest}
        />
      </Modal>
      <BulkActionsModal
        isOpen={!!importErrorInfo}
        errorMessages={importErrorInfo?.notImported ?? []}
        successMessages={importErrorInfo?.imported ?? []}
        title={t('Import.Sales.Users.Error.Modal.Title')}
        warnings={t('Import.Sales.Users.Error.Modal.Caution')}
        onClose={onBulkModalClose}
        successTitle={t('Import.Sales.Users.Error.Modal.Success.Title')}
        errorTitle={t('Import.Sales.Users.Error.Modal.Error.Title')}
        dataTest={`${dataTest}-error-modal`}
      />
    </>
  )
}

export default ImportSalesUsersModalContainer
