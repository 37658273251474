import React, { FC } from 'react'

import classNames from 'classnames'

import DataCardHeader, { DataCardHeaderProps } from '@components/DataCard/components/DataCardHeader/DataCardHeader'
import DataCardHeaderWithOptions, {
  DataCardHeaderOptionType,
} from '@components/DataCard/components/DataCardHeaderWithOptions/DataCardHeaderWithOptions'
import DataCard, { DataCardProps } from '@components/DataCard/DataCard'

type Props = DataCardHeaderProps &
  DataCardProps & {
    options?: DataCardHeaderOptionType[]
    onClick?: (dataCardId: string) => void
    defaultOption?: DataCardHeaderOptionType
  }

const rootClass = 'single-content-data-card'

const SingleContentDataCard: FC<Props> = (props: Props) => {
  const { children, headerText, defaultOption, options, dataTest = rootClass, className = '', ...dataCardProps } = props

  return (
    <DataCard {...dataCardProps} className={classNames(rootClass, className)} dataTest={dataTest}>
      {options && options.length > 0 ? (
        <DataCardHeaderWithOptions options={options} headerText={headerText} defaultOption={defaultOption} />
      ) : (
        <DataCardHeader headerText={headerText} />
      )}
      {children}
    </DataCard>
  )
}

export default SingleContentDataCard
