import { useApolloClient } from '@apollo/client'
import refreshRecordCount from '@graphql/microservices/segment/refreshRecordCount'
import upsertColumns from '@graphql/mutations/upsertColumns'
import { RefreshRecordCountMutation, RefreshRecordCountMutationVariables } from '@graphql/types/microservice/segment-types'
import { UpsertColumnsMutation, UpsertColumnsMutationVariables } from '@graphql/types/mutation-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

interface ContactsDetailsRequests {
  addFieldColumnsToList: (variables: UpsertColumnsMutationVariables) => FetchPromise<UpsertColumnsMutation>
  refreshRecordsCount: (ids: string[], isUCLList: boolean) => FetchPromise<RefreshRecordCountMutation>
}

const useContactsDetailsRequests = (): ContactsDetailsRequests => {
  const actonClient = useApolloClient()
  const { client: segmentClient } = useMicroserviceClient({ serviceName: MicroserviceClients.SEGMENT })

  const addFieldColumnsToList = (variables: UpsertColumnsMutationVariables) => {
    return actonClient.mutate<UpsertColumnsMutation, UpsertColumnsMutationVariables>({
      mutation: upsertColumns,
      variables,
    })
  }

  const refreshRecordsCount = (ids: string[], isUCLList: boolean) =>
    segmentClient.mutate<RefreshRecordCountMutation, RefreshRecordCountMutationVariables>({
      mutation: refreshRecordCount,
      variables: {
        segmentIds: ids,
        isUCLList,
      },
    })

  return {
    addFieldColumnsToList,
    refreshRecordsCount,
  }
}

export default useContactsDetailsRequests
