import { gql } from '@apollo/client'

export default gql`
  query getSegmentDefinitionFields($segmentIds: [String]) {
    getSegmentDefinitionFields(segmentIds: $segmentIds) {
      fieldName
      segmentInfo {
        segmentId
        segmentName
      }
    }
  }
`
