import { gql } from '@apollo/client'

export default gql`
  query GetOptouts($accountId: Long!, $page: Int!, $size: Int!) {
    getOptStatuses(accountId: $accountId, page: $page, size: $size, optState: OPT_OUT) {
      optStatuses {
        phoneNumber
        source
        status
        sourceUser
        sourceAttribution
        statusDate
      }
      totalCount
    }
  }
`
