import { call, put, takeLatest } from 'redux-saga/effects'

import { ApolloClient } from '@apollo/client'
import SvgNames, { SvgNamesStrings } from '@components/Svg/SvgNames'
import getUserSettings from '@graphql/microservices/user-settings/getUserSettings'
import accountSettingsQuery from '@graphql/queries/accountSettings'
import getAllMicroserviceURLs from '@graphql/queries/getAllMicroserviceURLs'
import userValidWithTokenQuery from '@graphql/queries/userValidWithToken'
import { GetUserSettingsQuery, GetUserSettingsQueryVariables } from '@graphql/types/microservice/user-settings-types'
import {
  AccountSettingsQuery,
  GetAllMicroserviceUrLsQuery,
  GetAllMicroserviceUrLsQueryVariables,
  Query,
  UserValidWithTokenQuery,
  UserValidWithTokenQueryVariables,
} from '@graphql/types/query-types'
import { Action } from '@interface/Action'
import { logError } from '@utils/env'
import { createMicroserviceClient, MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { defaultUserSettings, parseUserSettings, UserSettings } from '@utils/hooks/useUserSettings'
import { NavigationInterface, OldNavigationInterface } from '@utils/navigation/navigation.utils'
import { logNewRelicError } from '@utils/new-relic.utils'
import { clearSession } from '@utils/sessionStorage'
import runSagas from '@utils/store/sagas'

import {
  CheckUserAuthenticationPayload,
  loadAccountFailed,
  loadAccountReceived,
  LoadAccountRequest,
  logOutActions,
  SetThemeAction,
  updateUserAuthentication,
} from './actions'
import actionTypes from './actionTypes'
import { logOutActionTypes } from './reducer'
import accountServices from './service'
import { setTheme } from '../theme'

interface ResponseGenerator {
  data: any
}

export function convertOldNavToNew(oldNav: OldNavigationInterface[], index = ''): NavigationInterface[] {
  return oldNav.map((nav, i) => ({
    id: `dynamic-nav${index}-${i}`,
    icon: nav.faIcon ? SvgNames[nav.faIcon?.replace('-o', '') as SvgNamesStrings] : undefined,
    label: nav.label,
    oldType: nav.type,
    oldTarget: nav.target,
    items: nav.items ? convertOldNavToNew(nav.items, `-${i}`) : undefined,
    url: nav.href,
  }))
}

export function queryAccountSettings(action: Action<LoadAccountRequest>) {
  return action.payload?.client?.query<AccountSettingsQuery, AccountSettingsQuery>({ query: accountSettingsQuery, fetchPolicy: 'network-only' })
}

export function queryUserValidWithToken(action: Action<LoadAccountRequest>) {
  return action.payload?.client?.query<UserValidWithTokenQuery, UserValidWithTokenQueryVariables>({
    query: userValidWithTokenQuery,
    fetchPolicy: 'network-only',
  })
}

export function queryAllMicroserviceURLs(action: Action<LoadAccountRequest>) {
  return action.payload?.client?.query<GetAllMicroserviceUrLsQuery, GetAllMicroserviceUrLsQueryVariables>({ query: getAllMicroserviceURLs })
}

export function queryUserSettings(action: Action<LoadAccountRequest>) {
  return action.payload?.client?.query<GetUserSettingsQuery, GetUserSettingsQueryVariables>({ query: getUserSettings })
}

export function* loadAccountSettings(action: Action<LoadAccountRequest>) {
  try {
    const userValid: ResponseGenerator = yield call(queryUserValidWithToken, action)
    if (!userValid.data.userValidWithToken.valid) {
      yield put(
        loadAccountReceived({
          accountSettings: undefined,
          isAuthorized: false,
        })
      )
      clearSession()
      return
    }
    const results: ResponseGenerator = yield call(queryAccountSettings, action)
    if (!results.data.accountSettings) {
      throw new Error('Account settings is empty')
    }
    const {
      data: { accountSettings },
    } = results

    const microserviceUrlsResponse: ResponseGenerator = yield call(queryAllMicroserviceURLs, action)
    const microserviceUrlsData = microserviceUrlsResponse.data.getAllMicroserviceURLs as Query['getAllMicroserviceURLs']
    const microserviceUrls = microserviceUrlsData
      ? microserviceUrlsData.reduce((acc, curr) => {
          if (curr.key) {
            return { ...acc, [curr.key]: curr.value }
          }
          return acc
        }, {})
      : {}

    let userSettingsClient: ApolloClient<any> | undefined
    let userSettings: UserSettings
    try {
      userSettingsClient = createMicroserviceClient({
        microserviceUrls,
        serviceName: MicroserviceClients.USER_SETTINGS,
        accountId: accountSettings.accountId,
        userId: accountSettings.userId,
        token: userValid.data.userValidWithToken.token,
        aoAccountContext: userValid.data.userValidWithToken.accountContext,
      })

      const userSettingsResponse: ResponseGenerator = yield call(queryUserSettings, { ...action, payload: { client: userSettingsClient } })
      userSettings = parseUserSettings(userSettingsResponse.data.getUserSettings.userSettings)
    } catch (e) {
      userSettings = defaultUserSettings
      logNewRelicError(e, 'Fetching user settings')
    }

    let dynamicNav: NavigationInterface[] | undefined = undefined
    if (accountSettings.dynamicNavUrl) {
      try {
        const dynamicNavOld: OldNavigationInterface = yield call(accountServices.getDynamicNav, accountSettings.dynamicNavUrl)
        dynamicNav = convertOldNavToNew(dynamicNavOld as any)
      } catch (er) {
        logError(er)
      }
    }
    yield put(
      loadAccountReceived({
        token: userValid.data.userValidWithToken.token,
        accountContext: userValid.data.userValidWithToken.accountContext,
        accountSettings,
        isAuthorized: true,
        dynamicNav,
        microserviceUrls,
        userSettings,
      })
    )
  } catch (err) {
    logError(err)
    yield put(loadAccountFailed({ error: err }))
  }
}

export function* checkUserAuthentication(action: Action<CheckUserAuthenticationPayload>) {
  try {
    const userValid: ResponseGenerator = yield call(queryUserValidWithToken, action)
    if (!userValid.data.userValidWithToken.valid) {
      yield put(
        loadAccountReceived({
          accountSettings: undefined,
          isAuthorized: false,
        })
      )
      clearSession()
      return
    } else if (userValid.data.userValidWithToken.token && userValid.data.userValidWithToken.accountContext) {
      yield put(
        updateUserAuthentication({
          token: userValid.data.userValidWithToken.token,
          accountContext: userValid.data.userValidWithToken.accountContext,
          isAuthorized: userValid.data.userValidWithToken.valid,
        })
      )
    }
  } catch (err) {
    logError(err)
    yield put(loadAccountFailed({ error: err }))
  }
}

export function* setCurrentTheme(action: SetThemeAction) {
  if (action.payload) {
    yield call(setTheme, action.payload)
  }
}

export function* logout() {
  try {
    yield call(accountServices.logout)
    yield put(logOutActions.receive({ loggedOut: true }))
  } catch (err) {
    logError(err)
    yield put(logOutActions.fail({ error: err }))
  }
}

export function* addWatchers() {
  yield takeLatest(actionTypes.loadAccountSettings, loadAccountSettings)
  yield takeLatest(logOutActionTypes.REQUEST, logout)
  yield takeLatest(actionTypes.setTheme, setCurrentTheme)
  yield takeLatest(actionTypes.checkUserAuthentication, checkUserAuthentication)
}

runSagas(addWatchers)
