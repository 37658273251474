export enum LaunchApprovalValue {
  ALLOWED = 'allowed',
  NOT_ALLOWED = 'notallowed',
  REQUIRES_ADMIN = 'admin',
}

enum LaunchApprovalLabel {
  ALLOWED = 'Allowed',
  NOT_ALLOWED = 'Not allowed',
  REQUIRES_APPROVAL = 'Requires approval',
}

export const approvalsMap = new Map<string, LaunchApprovalValue>([
  ['Allowed to launch', LaunchApprovalValue.ALLOWED],
  ['Not allowed to launch', LaunchApprovalValue.NOT_ALLOWED],
])

export const options: { value: LaunchApprovalValue; label: LaunchApprovalLabel }[] = [
  { value: LaunchApprovalValue.ALLOWED, label: LaunchApprovalLabel.ALLOWED },
  { value: LaunchApprovalValue.NOT_ALLOWED, label: LaunchApprovalLabel.NOT_ALLOWED },
  { value: LaunchApprovalValue.REQUIRES_ADMIN, label: LaunchApprovalLabel.REQUIRES_APPROVAL },
]
