import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router'

import { rootContext } from '@const/globals'
import { UpgradePrograms } from '@src/pages/programs/UpgradePrograms/UpgradePrograms'
import { useAccountSettings } from '@utils/account/account.utils'

const UpgradeProgramsContainer: FC = () => {
  const { hasAutomatedProgramsUpgrade } = useAccountSettings()
  const history = useHistory()

  useEffect(() => {
    if (!hasAutomatedProgramsUpgrade) {
      history.push(`${rootContext}/classic/dashboard`)
    }
  }, [hasAutomatedProgramsUpgrade])

  return <>{hasAutomatedProgramsUpgrade && <UpgradePrograms />}</>
}

export default UpgradeProgramsContainer
