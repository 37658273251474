import React, { FC, MouseEventHandler, ReactNode } from 'react'

import classNames from 'classnames'

import Svg, { SvgNames, SvgType } from '@components/Svg'

import './SelectableRow.css'

import Button, { ButtonType } from '@components/Button/Button'

interface SelectableRowWithActionsProps {
  children?: ReactNode
  className?: string
  dataTest?: string
  isSelected?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  action?: ReactNode
}

const rootClass = 'selectable-row'

const SelectableRow: FC<SelectableRowWithActionsProps> = (props: SelectableRowWithActionsProps) => {
  const { dataTest = rootClass, children, className = '', isSelected, action, onClick } = props

  return (
    <Button
      buttonType={ButtonType.TEXT}
      fullWidth
      className={classNames(rootClass, className, { [`${rootClass}__selected`]: isSelected })}
      data-test={dataTest}
      onClick={onClick}
    >
      <div>{children}</div>
      {action ? (
        <div className={`${rootClass}__action`}>{action}</div>
      ) : (
        isSelected && (
          <Svg dataTest={`${dataTest}-selected-icon`} className={`${rootClass}__checked-icon`} name={SvgNames.check} type={SvgType.ICON} />
        )
      )}
    </Button>
  )
}

export default SelectableRow
