import React, { FC } from 'react'

import Chart from '@components/Chart/Chart'
import { ChartProps, LegendLocation, stackedOptions, topLegendProps } from '@const/Chart.constants'

const rootClass = 'stacked-column-chart'

const StackedColumnChart: FC<ChartProps> = (props: ChartProps) => {
  const { dataTest = rootClass, className = '', title = '', subtitle = '', categories, legendLocation = LegendLocation.BOTTOM, fields } = props

  const chartColumnData: Array<any> = categories
    ? fields
    : fields.map((field) => ({
        name: field.name,
        data: [field.data],
        color: field.color,
      }))

  const xAxis = {
    categories,
  }

  return (
    <Chart
      series={chartColumnData}
      chartType="column"
      title={title}
      subtitle={subtitle}
      xAxis={categories ? xAxis : undefined}
      legend={legendLocation === LegendLocation.TOP ? topLegendProps : undefined}
      legendLocation={legendLocation}
      plotOptions={{ column: stackedOptions }}
      dataTest={dataTest}
      className={className}
    />
  )
}

export default StackedColumnChart
