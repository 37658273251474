import { ListingPageSubType } from '../Context/ListingPageCommon.context'

export const findSubTypeWithName = (subTypes: ListingPageSubType[], name: string) => {
  return subTypes.find((subType) => subTypesEqual(subType.name, name))
}

export const toggleOffFilterSubTypes = (defaultSubTypes: string[], activeSubTypes: string[], allSubTypes?: ListingPageSubType[]) => {
  if (!allSubTypes) {
    return activeSubTypes
  }
  return activeSubTypes.filter((activeSubType) => {
    const foundSubType = findSubTypeWithName(allSubTypes, activeSubType)
    return !foundSubType || defaultSubTypes.includes(foundSubType.name) || !foundSubType.behaveAsFilter
  })
}

/** subTypes are case-sensitive and always lower case in the categorization service */
export const processSubTypeValues = (subTypes: string[]) => [...new Set(subTypes.map(processSubTypeValue))]
export const processSubTypeValue = (subType: string) => subType.toLowerCase()
export const subTypesEqual = (a: string, b: string) => processSubTypeValue(a) === processSubTypeValue(b)
