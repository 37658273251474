import React, { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import ListingPageWithTabs from '@complex/ListingPageWithTabs/ListingPageWithTabs'
import { LinkTextButton } from '@components/LinkTextButton/LinkTextButton'
import { SmallCollapsibleBanner } from '@components/SmallCollapsibleBanner/SmallCollapsibleBanner'
import { SvgNames } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import { learnMoreCopySegmentLink } from '@src/pages/ContactSegments/components/CopySegments/constants/CopySegments.constants'
import { AutomatedProgramsSession } from '@src/pages/listingPages/AutomatedPrograms/AutomatedProgramsListingPage.constants'
import AutomatedProgramsListingPageContainer from '@src/pages/listingPages/AutomatedPrograms/AutomatedProgramsListingPageContainer'
import AutomatedProgramTemplatesListingPageContainer from '@src/pages/listingPages/AutomatedProgramTemplates/AutomatedProgramTemplatesListingPageContainer'
import { useTabbedContainerRequests } from '@src/pages/listingPages/TabbedContainers/GraphQL/TabbedContainerRequests.graphQL'
import {
  UpgradeProgramsBannerContainerInitialState,
  UpgradeProgramsBannerContainerState,
  UpgradeProgramsBannerContext,
} from '@src/pages/listingPages/TabbedContainers/ProgramsAndTemplatesTabbedContainer/context/UpgradeProgramsBanner.context'
import { getAllContactsUpgradeStatus } from '@src/pages/listingPages/TabbedContainers/TabbedContainer.utils'
import { UpgradeItemType } from '@src/pages/UpgradeAssistant/UpgradeAssistant.contants'
import { useUpgradeAssistant } from '@src/pages/UpgradeAssistant/utils/useUpgradeAssistant'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'
import { getItem, setItem } from '@utils/sessionStorage'

import './ProgramsAndTemplatesTabbedContainer.css'

interface ProgramsAndTemplatesTabbedContainer {
  className?: string
  dataTest?: string
}

const rootClass = 'programs-and-templates-tabbed-container'
const rootText = 'ListPage.AutomatedPrograms.'

const ProgramsAndTemplatesTabbedContainer: FC<ProgramsAndTemplatesTabbedContainer> = () => {
  const [state, setState] = useState<UpgradeProgramsBannerContainerState>({
    ...UpgradeProgramsBannerContainerInitialState,
    showAutomatedProgramsUpgradeBanner: getItem(AutomatedProgramsSession.UPGRADE_PROGRAMS_BANNER) !== 'false',
  })
  const { showAutomatedProgramsUpgradeBanner, upgradeCompleted } = state
  const history = useHistory()
  const { hasAutomatedProgramsUpgrade, exclusivelyActOnContacts } = useAccountSettings()
  const { getAllContactsUpgrade } = useTabbedContainerRequests()
  const { t } = useTranslation()

  const { hasUpgradableItems } = useUpgradeAssistant(ItemType.PROGRAM)

  const update = useDeepUpdate(setState)

  const showProgramsBanner = hasAutomatedProgramsUpgrade && showAutomatedProgramsUpgradeBanner && hasUpgradableItems && !exclusivelyActOnContacts

  useEffect(() => {
    if (hasAutomatedProgramsUpgrade) {
      getAllContactsUpgradeStatus(getAllContactsUpgrade, UpgradeItemType.PROGRAMS, (isCompleted) =>
        setState((state) => ({ ...state, upgradeCompleted: isCompleted }))
      )
    }
  }, [])

  const tabProps = [
    {
      label: t('Programs'),
      component: <AutomatedProgramsListingPageContainer hasUpgradablePrograms={hasUpgradableItems} />,
      pathName: `${rootContext}/automation/programs`,
      pathnameId: 'programs',
    },
    {
      label: t('Templates'),
      component: <AutomatedProgramTemplatesListingPageContainer />,
      pathName: `${rootContext}/automation/programTemplates`,
      pathnameId: 'programTemplates',
    },
  ]

  const collapseAutomatedProgramsUpgradeBanner = () => {
    setItem(AutomatedProgramsSession.UPGRADE_PROGRAMS_BANNER, 'false')
    update({ showAutomatedProgramsUpgradeBanner: false })
  }

  const onUpgradeClicked = () => history.push(`${rootContext}/upgrade/programs`)

  return (
    <>
      {showProgramsBanner && !upgradeCompleted && (
        <SmallCollapsibleBanner
          className={`${rootClass}__upgrade-banner`}
          isDefaultExpanded={showAutomatedProgramsUpgradeBanner}
          body={
            <div className={`${rootClass}__upgrade-banner-body`}>
              <Typography
                className={`${rootClass}__upgrade-banner-info`}
                text={t(`${rootText}UpgradeBanner.Info`)}
                type={TextType.BODY_TEXT}
                tagProps={{ bold: { weight: TextWeight.BOLD } }}
              />
              <LinkTextButton
                className={`${rootClass}__upgrade-banner-link`}
                text={t(`${rootText}UpgradeBanner.Link`)}
                hideIconLeft
                link={learnMoreCopySegmentLink}
              />
            </div>
          }
          primaryButtonText={t(`${rootText}UpgradeBanner.Button.Upgrade`)}
          icon={SvgNames.lightBulbNoBackground}
          onCollapse={collapseAutomatedProgramsUpgradeBanner}
          onPrimaryButtonClick={onUpgradeClicked}
        />
      )}
      <UpgradeProgramsBannerContext.Provider
        value={{
          values: state,
          update,
        }}
      >
        <ListingPageWithTabs
          className={showProgramsBanner && !upgradeCompleted ? `${rootClass}__upgrade-banner-displayed` : undefined}
          tabProps={tabProps}
          hasDynamicHeight
        />
      </UpgradeProgramsBannerContext.Provider>
    </>
  )
}

export default ProgramsAndTemplatesTabbedContainer
