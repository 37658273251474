import React from 'react'

import { FilterCounts, RenderCustomFiltersParams } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { LandingPageStatus } from '@complex/ListingPage/Utils/ListingPage.constants'
import { ListingPageSession } from '@complex/ListingPage/Utils/ListingPage.utils'
import Checkbox from '@components/Checkbox'
import CheckboxGroup from '@components/CheckboxGroup/CheckboxGroup'
import { CollapsibleMenuItemWithHeaderData } from '@components/CollapsibleMenu/CollapsibleMenu'
import Radio from '@components/Radio'
import RadioGroup from '@components/RadioGroup'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextType } from '@components/Typography/Typography'
import { FilterTypes } from '@utils/filter'
import { getItem } from '@utils/sessionStorage'

const getMoreFilters = (filterCounts: FilterCounts) => {
  const allSubTypes = [{ name: 'contains_form', label: 'Contains a form' }]

  return allSubTypes
    .filter((subType) => {
      return (filterCounts[subType.name] ?? 0) > 0
    })
    .sort((a, b) => (a.name > b.name ? 1 : -1))
}

export const renderCustomFilters = ({
  activeSubTypes,
  menuActions,
  filterCounts,
  renderCustomFilterWithCount,
  t,
}: RenderCustomFiltersParams): CollapsibleMenuItemWithHeaderData[] => {
  const moreFilters = getMoreFilters(filterCounts)
  return [
    {
      className: 'filter-container',
      header: 'Status',
      isOpen: getItem(ListingPageSession.CUSTOM_MENU_SECTION_CLOSED) !== 'true',
      icon: SvgNames.toggles,
      dataTest: 'menu-items',
      content: (
        <RadioGroup className={`listing-page__custom-filters`} verticalLayout>
          {renderCustomFilterWithCount(
            <>
              <Radio
                className="landing-pages-listing-page-container__radio"
                labelChildren={
                  <div className="flex-align-center">
                    <Svg className="push-left" name={SvgNames.circleGreen} type={SvgType.SMALL_ICON} />
                    <Typography text={t(FilterTypes.ENABLED)} type={TextType.BODY_TEXT} />
                  </div>
                }
                checked={activeSubTypes.includes(LandingPageStatus.ENABLED)}
                onChange={() => menuActions.clickSubType(LandingPageStatus.ENABLED, [LandingPageStatus.DISABLED])}
                onClick={() => menuActions.clickSubType(LandingPageStatus.ENABLED, [LandingPageStatus.DISABLED])}
                boldOnChecked
              />
            </>,
            filterCounts[LandingPageStatus.ENABLED]
          )}
          {renderCustomFilterWithCount(
            <Radio
              className="landing-pages-listing-page-container__radio"
              labelChildren={
                <div className="flex-align-center">
                  <Svg className="push-left" name={SvgNames.circle} type={SvgType.SMALL_ICON} />
                  <Typography text={t(FilterTypes.DISABLED)} type={TextType.BODY_TEXT} />
                </div>
              }
              checked={activeSubTypes.includes(LandingPageStatus.DISABLED)}
              onChange={() => menuActions.clickSubType(LandingPageStatus.DISABLED, [LandingPageStatus.ENABLED])}
              onClick={() => menuActions.clickSubType(LandingPageStatus.DISABLED, [LandingPageStatus.ENABLED])}
              boldOnChecked
            />,
            filterCounts[LandingPageStatus.DISABLED]
          )}
        </RadioGroup>
      ),
    },
    ...(!moreFilters.length
      ? []
      : [
          {
            className: 'filter-container',
            header: t('More Filters'),
            isOpen: getItem(ListingPageSession.CUSTOM_MENU_SECTION_CLOSED) !== 'true',
            icon: SvgNames.toggles,
            dataTest: 'menu-items',
            content: (
              <CheckboxGroup className={`listing-page__custom-filters`} verticalLayout>
                {moreFilters.map((filter) =>
                  renderCustomFilterWithCount(
                    <Checkbox
                      label={t(filter.label)}
                      checked={activeSubTypes.includes(filter.name)}
                      onChange={() => menuActions.clickSubType(filter.name)}
                      dataTest={`list-page-container-section-filter`}
                    />,
                    filterCounts[filter.name],
                    filter.name
                  )
                )}
              </CheckboxGroup>
            ),
          },
        ]),
  ]
}
