import { useCallback } from 'react'

import { useApolloClient } from '@apollo/client'
import getListsFields from '@graphql/queries/getListsFields'
import getPersonalizationChoices from '@graphql/queries/getPersonalizationChoices'
import searchListsByIdsQuery from '@graphql/queries/searchListsByIds'
import {
  GetListsFieldsQuery,
  GetListsFieldsQueryVariables,
  GetPersonalizationChoicesQuery,
  GetPersonalizationChoicesQueryVariables,
  SearchListsByIdsQuery,
  SearchListsByIdsQueryVariables,
} from '@graphql/types/query-types'
import { logError } from '@utils/env'

export interface PersonalizationRequests {
  getPersonalizationsRequest: () => Promise<GetPersonalizationChoicesQuery | undefined>
  getListFieldsRequest: (listIdsSeparatedByComma: string) => Promise<GetListsFieldsQuery | undefined>
  searchListByIds: (listId: string) => Promise<SearchListsByIdsQuery | undefined>
}

export const usePersonalizationRequests = (): PersonalizationRequests => {
  const client = useApolloClient()

  const getPersonalizationsRequest = useCallback(() => {
    return client
      .query<GetPersonalizationChoicesQuery, GetPersonalizationChoicesQueryVariables>({
        query: getPersonalizationChoices,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        variables: {
          combineDateFunctions: true,
        },
      })
      .then(({ data, errors }) => {
        if (data?.getPersonalizationChoices) {
          return data
        } else {
          logError(errors)
        }
      })
  }, [client])

  const getListFieldsRequest = useCallback(
    (listIdsSeparatedByComma: string): Promise<GetListsFieldsQuery | undefined> => {
      return client
        .query<GetListsFieldsQuery, GetListsFieldsQueryVariables>({
          query: getListsFields,
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
          variables: {
            listIdsSeparatedByComma,
          },
        })
        .then(({ data, errors }) => {
          if (data?.getListsFields) {
            return data
          } else {
            logError(errors)
          }
        })
    },
    [client]
  )

  const searchListByIds = useCallback(
    (listId: string) => {
      return client
        .query<SearchListsByIdsQuery, SearchListsByIdsQueryVariables>({
          query: searchListsByIdsQuery,
          fetchPolicy: 'network-only',
          variables: {
            ids: listId,
          },
        })
        .then(({ data, errors }) => {
          if (data?.searchListsByIds) {
            return data
          } else {
            logError(errors)
          }
        })
    },
    [client]
  )

  return {
    getPersonalizationsRequest,
    getListFieldsRequest,
    searchListByIds,
  }
}
