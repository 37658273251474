import React, { FC, useMemo } from 'react'

import Button, { ButtonType } from '@components/Button'
import { ModalFooter } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { useTranslation } from '@const/globals'
import { UserTypeValue } from '@src/pages/Settings/Users/components/InviteUsersModal/const/userType.const'
import UsersCountInfo from '@src/pages/Settings/Users/components/UsersCountInfo/UsersCountInfo'
import { NEED_MORE_LINK } from '@src/pages/Settings/Users/constants/users.constants'

import './InviteUsersModalFooter.css'

interface Props {
  disableSave: boolean
  onCancel: () => void
  onSave: () => void
  userType: UserTypeValue
  availableRemaining: number
  maxUsers: number
  loading: boolean
}

const rootClass = 'invite-users-modal-footer'
const dataTest = rootClass

const InviteUsersModalFooter: FC<Props> = (props: Props) => {
  const { disableSave, onCancel, onSave, userType, availableRemaining, maxUsers, loading } = props
  const { t } = useTranslation()
  const type = useMemo(() => (userType === UserTypeValue.SALES ? 'Sales' : 'Marketing'), [userType])

  return (
    <ModalFooter footerType={ModalFooterType.Form} className={rootClass} dataTest={dataTest}>
      <UsersCountInfo
        labelKey={`${type}.Users.Left.Label`}
        tooltipKey={`${type}.Users.Left.Tooltip`}
        currentCount={Math.max(availableRemaining, 0)}
        maxCount={maxUsers}
        needMoreLink={NEED_MORE_LINK}
        loading={loading}
        isError={availableRemaining < 0}
        className={`${rootClass}__users-count`}
      />
      <Button buttonType={ButtonType.TERTIARY} onClick={onCancel} dataTest={`${dataTest}-cancel-button`}>
        {t('Cancel')}
      </Button>
      <Button buttonType={ButtonType.PRIMARY} onClick={onSave} disabled={disableSave} dataTest={`${dataTest}-save-button`}>
        {t('Invite')}
      </Button>
    </ModalFooter>
  )
}

export default InviteUsersModalFooter
