import { gql } from '@apollo/client'

export default gql`
  query getTagClicks(
    $accountId: Long!
    $sortDirection: Direction
    $sortColumn: TagNameClickSortColumns
    $size: Int!
    $messageId: String
    $page: Int!
    $tagNames: [String]
  ) {
    getTagClicks(
      accountId: $accountId
      sortDirection: $sortDirection
      sortColumn: $sortColumn
      size: $size
      messageId: $messageId
      page: $page
      tagNames: $tagNames
    ) {
      tagNameList {
        id
        tagName
        totalClicks
        uniqueClicks
      }
      totalClickCount
      totalCount
      totalUniqueCount
    }
  }
`
