import React, { FC } from 'react'

import classNames from 'classnames'

import {
  behaviorComparisons,
  behaviorTimePeriodOptions,
  behaviorTimeRange,
  DEFINITION_TYPE,
  expressionComparisons,
  profileConditionEmptiness,
  profileDateDirection,
  profileDateRange,
  scoreComparisons,
  smsFields,
  subscriptionFields,
  systemFields,
} from '@complex/ContactSegments/SegmentInfoHoverCardSegmentDefinition/SegmentInfoHoverCardSegmentDefinition.constants'
import { ListPickerModalAPI } from '@complex/ListPickerModalV2/utils/interfaces/ListPickerModalInterfaces'
import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import {
  BEHAVIOR_CONDITION_TYPE,
  BEHAVIOR_MONTH_TYPE,
  BEHAVIOR_TIME_PERIOD_OPTIONS,
  CONDITION_TYPE,
  PROFILE_DATE_VALUE,
  SegmentExpressionBehavior,
  SegmentExpressionCrm,
  SegmentExpressionProfile,
  SegmentExpressionProfileDateValue,
  SegmentExpressionScore,
  SegmentExpressionSms,
  SegmentExpressionSubscription,
  SegmentExpressionSystem,
} from '@utils/contactSegments/contactSegments.utils'
import { EXPRESSION_EVALUATION_TYPE, EXPRESSION_TYPE, SegmentDefinition, SegmentExpression } from '@utils/contactSegments/contactSegments.utils'
import { ContactSegmentsContextAPI } from '@utils/contactSegments/context/ContactSegmentsContext'
import { countries } from '@utils/countries'

import './SegmentInfoHoverCardSegmentDefinition.css'

export interface SegmentInfoHoverCardSegmentDefinitionData {
  crmMapping: Record<string, { displayName: string; entityFields: Record<string, string> }>
  subscriptionMapping: Record<string, string>
  campaigns: Record<string, string>
  scoreSheets: Record<string, string>
  supportedBehaviors: Record<string, string>
  metadataLoading: boolean
}

interface Props {
  className?: string
  dataTest?: string
  data: SegmentDefinition
  textType?: TextType
  contextValues: ContactSegmentsContextAPI['values'] | ListPickerModalAPI['values'] | SegmentInfoHoverCardSegmentDefinitionData
}

const rootClass = 'segment-info-hover-card-segment-definition'

const SegmentInfoHoverCardSegmentDefinition: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '', data, contextValues, textType = TextType.BODY_TEXT_SMALL } = props
  const { t } = useTranslation()
  const { crmMapping, subscriptionMapping, scoreSheets, campaigns, supportedBehaviors, metadataLoading } = contextValues
  const renderText = (text: string, inline = true, weight?: TextWeight, className?: string) => (
    <Typography text={text} className={className} inline={inline} type={textType} weight={weight} />
  )
  const renderDataUnavailableMessage = () => renderText(t('No data available'), true, TextWeight.MEDIUM, `${rootClass}__invalid`)

  const renderBehaviorFilters = (expression: SegmentExpressionBehavior) => {
    const conditionType = {
      [BEHAVIOR_CONDITION_TYPE.COUNT]: (
        <>
          {renderText(behaviorComparisons[expression.condition.comparison], true)}
          {renderText(`${expression.condition.count}`, true, TextWeight.MEDIUM)}
        </>
      ),
      [BEHAVIOR_CONDITION_TYPE.NONE]: <>{renderText(`${behaviorComparisons[expression.condition.comparison]}`, true, TextWeight.MEDIUM)}</>,
      [BEHAVIOR_CONDITION_TYPE.SOME]: <>{renderText(`${behaviorComparisons[expression.condition.comparison]}`, true, TextWeight.MEDIUM)}</>,
      [BEHAVIOR_CONDITION_TYPE.ASSETS]: (
        <>
          {renderText(behaviorComparisons[expression.condition.comparison], true)}
          <>
            {expression.condition.assetIds &&
              renderText(
                expression.condition.assetIds
                  .map((id) => (data.assetsDisplayNames ? (data.assetsDisplayNames[id] ? data.assetsDisplayNames[id] : id) : id))
                  .join(', '),
                true,
                TextWeight.MEDIUM
              )}
          </>
        </>
      ),
    }
    const monthType = {
      [BEHAVIOR_MONTH_TYPE.ABSOLUTE_MONTH]: (
        <>
          {renderText(behaviorTimePeriodOptions[expression.timePeriod.type], true)}
          {renderText(`${expression.timePeriod.selection?.month}/${expression.timePeriod.selection?.year}`, true, TextWeight.MEDIUM)}
        </>
      ),
      [BEHAVIOR_MONTH_TYPE.RELATIVE_MONTH]: (
        <>
          {renderText(behaviorTimePeriodOptions['sinceDay'], true)}
          {renderText(
            `${expression.timePeriod.selection?.monthsAgo} ${
              expression.timePeriod.selection ? behaviorTimeRange[expression.timePeriod.selection?.type] : ''
            }`,
            true,
            TextWeight.MEDIUM
          )}
        </>
      ),
    }
    const timePeriodType = {
      [BEHAVIOR_TIME_PERIOD_OPTIONS.SINCE_DAY]: (
        <>
          {renderText(behaviorTimePeriodOptions[expression.timePeriod.type], true)}
          {renderText(
            `${expression.timePeriod.selection?.daysAgo} ${
              expression.timePeriod.selection ? behaviorTimeRange[expression.timePeriod.selection?.type] : ''
            }`,
            true,
            TextWeight.MEDIUM
          )}
        </>
      ),
      [BEHAVIOR_TIME_PERIOD_OPTIONS.MONTH]: expression.timePeriod.selection && <>{monthType[expression.timePeriod.selection.type]}</>,
      [BEHAVIOR_TIME_PERIOD_OPTIONS.ALL_TIME]: <>{renderText(`${behaviorTimePeriodOptions[expression.timePeriod.type]}`, true, TextWeight.MEDIUM)}</>,
    }
    return (
      <div className={`${rootClass}__wrapper`}>
        {metadataLoading ? (
          <Loader loaderType={LoaderTypes.row} />
        ) : (
          <>
            {Object.keys(supportedBehaviors).length
              ? supportedBehaviors[expression.behavior]
                ? renderText(supportedBehaviors[expression.behavior], true, TextWeight.MEDIUM, `${rootClass}__field`)
                : renderText(t('Error: Behavior removed - please update the segment definition'), true, TextWeight.REGULAR, `${rootClass}__invalid`)
              : renderDataUnavailableMessage()}
            {conditionType[expression.condition.type]}
            {timePeriodType[expression.timePeriod.type]}
          </>
        )}
      </div>
    )
  }

  const renderScoreFilters = (expression: SegmentExpressionScore) => {
    const field = expression.campaignId ? t('Campaign score') : t('Overall behavior score')
    return (
      <div className={`${rootClass}__wrapper`}>
        {metadataLoading ? (
          <Loader loaderType={LoaderTypes.row} />
        ) : (
          <>
            {renderText(field, true, TextWeight.MEDIUM, `${rootClass}__field`)}
            {expression.scoreSheetId && (
              <>
                {Object.keys(scoreSheets).length
                  ? scoreSheets[expression.scoreSheetId]
                    ? renderText(`${scoreSheets[expression.scoreSheetId]}${expression.campaignId ? ',' : ''}`, true, TextWeight.MEDIUM)
                    : renderText(
                        t('Error: Score sheet removed - please update the segment definition'),
                        true,
                        TextWeight.REGULAR,
                        `${rootClass}__invalid`
                      )
                  : renderDataUnavailableMessage()}
              </>
            )}
            {expression.campaignId && (
              <>
                {Object.keys(campaigns).length
                  ? campaigns[expression.campaignId]
                    ? renderText(campaigns[expression.campaignId], true, TextWeight.MEDIUM)
                    : renderText(
                        t('Error: Campaign removed - please update the segment definition'),
                        true,
                        TextWeight.REGULAR,
                        `${rootClass}__invalid`
                      )
                  : renderDataUnavailableMessage()}
              </>
            )}
            {renderText(scoreComparisons[expression.condition.comparison], true)}
            {renderText(expression.condition.value, true, TextWeight.MEDIUM)}
          </>
        )}
      </div>
    )
  }

  const renderSystemFilters = (expression: SegmentExpressionSystem) => {
    return (
      <div className={`${rootClass}__wrapper`}>
        {renderText(systemFields[expression.field], true, TextWeight.MEDIUM, `${rootClass}__field`)}
        {processCondition[expression.condition.type](expression)}
      </div>
    )
  }

  const renderSmsFilters = (expression: SegmentExpressionSms) => {
    return (
      <div className={`${rootClass}__wrapper`}>
        {renderText(smsFields[expression.field], true, TextWeight.MEDIUM, `${rootClass}__field`)}
        {renderText(expression.field != 'PHONE_IN_COUNTRY' ? expression.condition : countries[expression.condition], false, TextWeight.MEDIUM)}
      </div>
    )
  }

  const renderCrmFilters = (expression: SegmentExpressionCrm) => {
    return (
      <div className={`${rootClass}__wrapper`}>
        {metadataLoading ? (
          <Loader loaderType={LoaderTypes.row} />
        ) : (
          <>
            {Object.keys(crmMapping).length ? (
              crmMapping[expression.entityType] ? (
                <>
                  {renderText(crmMapping[expression.entityType].displayName, true, TextWeight.MEDIUM, `${rootClass}__field`)}
                  {renderText(crmMapping[expression.entityType].entityFields[expression.field], true, TextWeight.MEDIUM)}
                  {processCondition[expression.condition.type as CONDITION_TYPE](expression)}
                </>
              ) : (
                renderText(t('Error: Crm field removed - please update the segment definition'), true, TextWeight.REGULAR, `${rootClass}__invalid`)
              )
            ) : (
              renderDataUnavailableMessage()
            )}
          </>
        )}
      </div>
    )
  }

  const renderExfieldFilters = () => (
    <div className={`${rootClass}__wrapper`}>
      {renderText(t('Ex. fields definitions are coming soon'), true, TextWeight.MEDIUM, `${rootClass}__field`)}
    </div>
  )

  const renderSubscriptionFilters = (expression: SegmentExpressionSubscription) => (
    <div className={`${rootClass}__wrapper`}>
      {metadataLoading ? (
        <Loader loaderType={LoaderTypes.row} />
      ) : (
        <>
          {Object.keys(subscriptionMapping).length ? (
            subscriptionMapping[expression.field] ? (
              <>
                {renderText(subscriptionMapping[expression.field], true, TextWeight.MEDIUM, `${rootClass}__field`)}
                {renderText(subscriptionFields[expression.value], true, TextWeight.MEDIUM)}
              </>
            ) : (
              renderText(
                t('Error: Subscription category removed - please update the segment definition'),
                true,
                TextWeight.REGULAR,
                `${rootClass}__invalid`
              )
            )
          ) : (
            renderDataUnavailableMessage()
          )}
        </>
      )}
    </div>
  )

  const processCondition = {
    [CONDITION_TYPE.TEXT_LIST]: (expression: any) => renderTextList(expression),
    [CONDITION_TYPE.TEXT_EMPTY]: (expression: any) => renderEmptiness(expression),
    [CONDITION_TYPE.TEXT_NOT_EMPTY]: (expression: any) => renderEmptiness(expression),
    [CONDITION_TYPE.NUM_VALUE]: (expression: any) => renderNumericCondition(expression),
    [CONDITION_TYPE.NUM_RANGE]: (expression: any) => renderNumericRange(expression),
    [CONDITION_TYPE.DATE_VALUE]: (expression: any) => renderDate(expression),
    [CONDITION_TYPE.DATE_RANGE]: (expression: any) => renderDateRange(expression),
  }

  const renderTextList = (expression: SegmentExpressionProfile) => {
    return (
      <>
        {renderText(expressionComparisons[expression.condition.comparison], true)}
        {expression.condition.values?.map((value, index) => {
          return (
            <div key={index} className={`${rootClass}__wrapper`}>
              {renderText(`${expression.type === EXPRESSION_TYPE.CRM ? campaigns[value] ?? value : value}`, true, TextWeight.MEDIUM)}
              {expression.condition.values && index != expression.condition.values.length - 1 && renderText(t('or'), true)}
            </div>
          )
        })}
      </>
    )
  }

  const renderEmptiness = (expression: SegmentExpressionProfile) => {
    return renderText(profileConditionEmptiness[expression.condition.type], true, TextWeight.MEDIUM)
  }

  const renderNumericCondition = (expression: any) => {
    return (
      <>
        {renderText(expressionComparisons[expression.condition.comparison], true)}
        {renderText(expression.condition.value, true, TextWeight.MEDIUM)}
      </>
    )
  }

  const renderNumericRange = (expression: SegmentExpressionProfile) => {
    return (
      <>
        {renderText(expressionComparisons[expression.condition.comparison], true)}
        {renderText(`${expression.condition.rangeStart}`, true, TextWeight.MEDIUM)}
        {renderText(t('and'), true)}
        {renderText(`${expression.condition.rangeEnd}`, true, TextWeight.MEDIUM)}
      </>
    )
  }

  const renderDate = (expression: SegmentExpressionProfile) => {
    const data = expression.condition.value?.relativePeriod
    const dateType = {
      [PROFILE_DATE_VALUE.ABSOLUTE]: <>{renderText(`${expression.condition.value?.textValue}`, false, TextWeight.MEDIUM)}</>,
      [PROFILE_DATE_VALUE.RELATIVE]: (
        <>
          {data?.type &&
            (data?.direction
              ? renderText(`${data?.count} ${profileDateRange[data?.type]} ${profileDateDirection[data?.direction]}`, false, TextWeight.MEDIUM)
              : renderText(`${profileDateRange[data?.type]}`, false, TextWeight.MEDIUM))}
        </>
      ),
    }
    return (
      <>
        {renderText(expressionComparisons[expression.condition.comparison], true)}
        {expression.condition.value?.type && dateType[expression.condition.value.type]}
      </>
    )
  }

  const renderDateRange = (expression: SegmentExpressionProfile) => {
    const dateType = (expression: SegmentExpressionProfileDateValue) =>
      expression.type === PROFILE_DATE_VALUE.ABSOLUTE ? (
        renderText(`${expression.textValue}`, false, TextWeight.MEDIUM)
      ) : (
        <>
          {expression.relativePeriod?.type &&
            (expression.relativePeriod?.direction
              ? renderText(
                  `${expression.relativePeriod?.count} ${profileDateRange[expression.relativePeriod?.type]} ${
                    profileDateDirection[expression.relativePeriod?.direction]
                  }`,
                  false,
                  TextWeight.MEDIUM
                )
              : renderText(`${profileDateRange[expression.relativePeriod?.type]}`, false, TextWeight.MEDIUM))}
        </>
      )

    return (
      <>
        {renderText(expressionComparisons[expression.condition.comparison], true)}
        {expression.condition.rangeStart && dateType(expression.condition.rangeStart)}
        {renderText(t('and'), true)}
        {expression.condition.rangeEnd && dateType(expression.condition.rangeEnd)}
      </>
    )
  }

  const renderProfileFilters = (expression: SegmentExpressionProfile) => {
    return (
      <div className={`${rootClass}__wrapper`}>
        {renderText(expression.field, true, TextWeight.MEDIUM, `${rootClass}__field`)}
        {processCondition[expression.condition.type](expression)}
      </div>
    )
  }

  const processExpression = {
    [EXPRESSION_TYPE.PROFILE]: (expression?: SegmentExpression) => renderProfileFilters(expression as unknown as SegmentExpressionProfile),
    [EXPRESSION_TYPE.BEHAVIOR]: (expression?: SegmentExpression) => renderBehaviorFilters(expression as unknown as SegmentExpressionBehavior),
    [EXPRESSION_TYPE.SCORE]: (expression?: SegmentExpression) => renderScoreFilters(expression as unknown as SegmentExpressionScore),
    [EXPRESSION_TYPE.SYSTEM]: (expression?: SegmentExpression) => renderSystemFilters(expression as unknown as SegmentExpressionSystem),
    [EXPRESSION_TYPE.SMS]: (expression?: SegmentExpression) => renderSmsFilters(expression as unknown as SegmentExpressionSms),
    [EXPRESSION_TYPE.CRM]: (expression?: SegmentExpression) => renderCrmFilters(expression as unknown as SegmentExpressionCrm),
    [EXPRESSION_TYPE.EXTENDED_FIELD]: () => renderExfieldFilters(),
    [EXPRESSION_TYPE.SUBSCRIPTION]: (expression?: SegmentExpression) =>
      renderSubscriptionFilters(expression as unknown as SegmentExpressionSubscription),
  }

  const renderSimpleEvaluationType = (definition: SegmentDefinition) => {
    return definition.expressions.map((expression: SegmentExpression, index: number) => {
      return (
        <div key={index} className={`${rootClass}__expression-wrapper`}>
          {index != 0 && renderText(definition.expressionEvaluationType, false)}
          {processExpression[expression.type](expression)}
        </div>
      )
    })
  }

  const renderCustomEvaluationType = (definition: SegmentDefinition) => {
    return (
      <div className={`${rootClass}__wrapper`}>
        {renderText(t('Combine expressions with'), true)}
        {renderText(`${definition.customBooleanExpression}`, true, TextWeight.MEDIUM)}
        <div className={classNames(`${rootClass}__wrapper`, `${rootClass}__wrapper-custom`)}>
          {definition.expressions.map((expression: SegmentExpression, index: number) => {
            return (
              <div key={index + 1} className={`${rootClass}__expression-wrapper`}>
                {renderText(`${index + 1}.`, false, TextWeight.REGULAR, `${rootClass}__expression-index`)}
                {processExpression[expression.type](expression)}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const processSearchDefinition = (definition: SegmentDefinition) => {
    return (
      definition.patterns &&
      definition.patterns.map((field: string, index: number) => {
        return (
          <div key={index} className={`${rootClass}__expression-wrapper`}>
            {index != 0 && renderText(t('OR'), false)}
            <div className={`${rootClass}__wrapper`}>
              {renderText(t('Search'), true, TextWeight.MEDIUM, `${rootClass}__field`)}
              {renderText(t('contains'), true)}
              {renderText(field, true, TextWeight.MEDIUM)}
            </div>
          </div>
        )
      })
    )
  }

  const processQueryDefinition = {
    [EXPRESSION_EVALUATION_TYPE.AND]: (definition: SegmentDefinition) => renderSimpleEvaluationType(definition),
    [EXPRESSION_EVALUATION_TYPE.OR]: (definition: SegmentDefinition) => renderSimpleEvaluationType(definition),
    [EXPRESSION_EVALUATION_TYPE.CUSTOM]: (definition: SegmentDefinition) => renderCustomEvaluationType(definition),
    [EXPRESSION_EVALUATION_TYPE.SYSTEM]: (definition: SegmentDefinition) => renderCustomEvaluationType(definition),
  }

  const processDirectSelectDefinition = (definition: SegmentDefinition) => (
    <div className={`${rootClass}__wrapper`}>{renderText(`${definition.recIds?.length} ${t('contacts selected')}`, false, TextWeight.MEDIUM)}</div>
  )

  const processSystemGeneratedDefinition = (definition: SegmentDefinition) =>
    definition.patterns && <div className={`${rootClass}__wrapper`}>{renderText(definition.patterns[0], false, TextWeight.MEDIUM)}</div>

  const renderSegmentDefinition = {
    [DEFINITION_TYPE.search]: (definition: SegmentDefinition) => processSearchDefinition(definition),
    [DEFINITION_TYPE.query]: (definition: SegmentDefinition) => processQueryDefinition[definition.expressionEvaluationType](definition),
    [DEFINITION_TYPE.directSelect]: (definition: SegmentDefinition) => processDirectSelectDefinition(definition),
    [DEFINITION_TYPE.systemGenerated]: (definition: SegmentDefinition) => processSystemGeneratedDefinition(definition),
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {data?.type && renderSegmentDefinition[data?.type as DEFINITION_TYPE](data)}
    </div>
  )
}

export default SegmentInfoHoverCardSegmentDefinition
