import React, { FC } from 'react'
import { useForm } from 'react-hook-form'

import * as yup from 'yup'

import Checkbox from '@components/Checkbox/Checkbox'
import FormGroup from '@components/FormGroup'
import FormRow from '@components/FormRow/FormRow'
import Input from '@components/Input/Input'
import KeyValuePicker from '@components/KeyValuePicker'
import Label from '@components/Label/Label'
import { ModalBody } from '@components/Modal'
import Select from '@components/Select/Select'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { Program } from '@graphql/types/query-types'
import { yupResolver } from '@hookform/resolvers/yup'
import APIStepDetail, { Header } from '@src/pages/programs/dashboard/components/ProgramSteps/components/APIStepDetail/APIStepDetail'
import {
  FORMAT_OPTIONS,
  FormatOptions,
  MethodOptions,
} from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditAPIStep/EditAPItep.constants'
import { getSharedHeaders } from '@utils/program/program'
import { ProgramWebRequestStepExt, Step } from '@utils/program/program.constants'
import '@utils/yupMethods'

import './editApiStep.css'

const rootClass = 'edit-api-step'

export interface Props {
  step: Step
  program: Program
  isRunning: boolean
  closeModal(): void
  submitId: string
  saveStepAndProgram(step: Step | null, program?: Program): void
  dataTest?: string
}

export interface State {
  columns: string[]
  step: ProgramWebRequestStepExt
}

const METHOD_OPTIONS = [MethodOptions.GET, MethodOptions.POST, MethodOptions.PUT, MethodOptions.DELETE]

export const handleFormSubmit = (data: any, step: ProgramWebRequestStepExt, props: Props) => {
  const { saveStepAndProgram } = props

  saveStepAndProgram({
    ...step,
    displayName: data.displayName,
    headers: step.headers,
    format: data.format ?? FormatOptions.FORM,
    method: data.method,
    url: data.url,
    custom: step.custom.map((field) => ({ value: field.value, key: field.key })) ?? [],
    httpHeaders: step.httpHeaders.map((field) => ({ value: field.value, key: field.key })) ?? [],
  })
}

const schema = yup.object().shape({
  displayName: yup.string().required('Step Name is required.'),
  httpHeaders: (
    yup.object().shape({
      key: yup.string(),
    }) as any
  ).uniqueProperty('key', 'ProgramManager.AJB.Step.API.UniqueHeaders'),
  custom: (
    yup.object().shape({
      key: yup.string(),
    }) as any
  ).uniqueProperty('key', 'ProgramManager.AJB.Step.API.UniqueFields'),
  update: yup.boolean(),
})

export function getPreview(step: ProgramWebRequestStepExt, columns: string[]) {
  const preview: string[] = step.url ? [step.url] : []
  let body: string | undefined = undefined
  let hasParams = false
  const checkParam = () => {
    preview.push(hasParams ? '&' : '?')
    hasParams = true
  }
  if ([MethodOptions.GET, MethodOptions.DELETE].includes(step.method as MethodOptions)) {
    step.headers.forEach((header) => {
      if (columns.includes(header)) {
        checkParam()
        preview.push(`${encodeURI(header)}=data`)
      }
    })
    step.custom.forEach((custom) => {
      if (!columns.includes(custom.key ?? '')) {
        checkParam()
        preview.push(`${encodeURI(custom.key ?? '')}=${encodeURI(custom.value ?? '')}`)
      }
    })
  } else {
    if (step.format === FormatOptions.FORM) {
      const formData: string[] = []
      step.headers.forEach((header) => {
        if (columns.includes(header)) {
          formData.push(`${encodeURI(header)}=data`)
        }
      })
      step.custom.forEach((custom) => {
        if (!step.headers.includes(custom.key ?? '')) {
          formData.push(`${encodeURI(custom.key ?? '')}=${encodeURI(custom.value ?? '')}`)
        }
      })
      body = formData.join('&')
    } else {
      const jsonData: { [key: string]: string } = {}
      step.headers.forEach((header) => {
        if (columns.includes(header)) {
          jsonData[`${header}`] = 'data'
        }
      })
      step.custom.forEach((custom) => {
        if (!step.headers.includes(custom.key ?? '')) {
          jsonData[`${custom.key ?? ''}`] = custom.value ?? ''
        }
      })
      body = JSON.stringify(jsonData)
    }
  }
  return {
    preview: preview.join(''),
    body,
  }
}

const EditAPIStep: FC<Props> = (props: Props) => {
  const { step: baseStep, program, isRunning, submitId, dataTest = 'edit-api-step' } = props

  const [state, setState] = React.useState<State>({
    columns: getSharedHeaders(program.sourceList),
    step: baseStep as ProgramWebRequestStepExt,
  })

  const { step, columns } = state

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })
  const { t } = useTranslation()

  const onSubmit = (data: any) => {
    handleFormSubmit(data, step, props)
  }

  const preview = getPreview(step, columns)

  const renderForm = () => (
    <form data-test={dataTest} onSubmit={handleSubmit(onSubmit)}>
      <FormRow>
        <Input
          className={`${rootClass}__step-name`}
          label={t('Step Name')}
          defaultValue={step.displayName}
          name="displayName"
          register={register('displayName')}
        />
        {errors?.displayName && <span className="error">{t(errors.displayName.message)}</span>}
      </FormRow>
      <FormRow>
        <Input label={t('URL')} defaultValue={step.url} name="url" register={register('url')} placeholder="http://api.example.com" />
      </FormRow>
      <FormGroup>
        <Label>{t('HTTP Headers')}</Label>
        <KeyValuePicker
          register={register}
          defaultValues={step.httpHeaders}
          groupedInputs={{
            name: 'httpHeaders',
          }}
          onChange={(keyValues) => {
            setState({
              ...state,
              step: {
                ...step,
                httpHeaders: keyValues,
              },
            })
          }}
        />
        {errors?.httpHeaders && <span className="error">{t(errors.httpHeaders.message)}</span>}
      </FormGroup>
      <FormRow className={`${rootClass}__row`}>
        <div className={`${rootClass}__col`}>
          <Select
            dataTest={`${dataTest}-method-select`}
            label={t('Method')}
            name="method"
            defaultValue={step.method}
            register={register('method', {
              onChange: (event) => {
                setState({
                  ...state,
                  step: {
                    ...step,
                    method: event.target.value,
                  },
                })
              },
            })}
          >
            {METHOD_OPTIONS.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
        </div>
        <div className={`${rootClass}__col`}>
          {[MethodOptions.PUT, MethodOptions.POST].includes(step.method as MethodOptions) && (
            <Select
              dataTest={`${dataTest}-format-select`}
              label={t('Format')}
              name="format"
              register={register('format', {
                onChange: (event) => {
                  setState({
                    ...state,
                    step: {
                      ...step,
                      format: event.target.value,
                    },
                  })
                },
              })}
              value={step.format}
            >
              {FORMAT_OPTIONS.map((option) => (
                <option key={option[0]} value={option[0]}>
                  {option[1]}
                </option>
              ))}
            </Select>
          )}
        </div>
      </FormRow>
      <FormRow>
        <Label>{t('Columns')}</Label>
        <div className={`${rootClass}__columns`}>
          {state.columns.map((column, i) => {
            const title = column.length > 15 ? { title: column } : {}
            return (
              <Checkbox
                {...title}
                dataTest={`${dataTest}-checkbox-${i}`}
                key={column}
                label={column}
                name="headers"
                value={column}
                checked={step.headers.includes(column)}
                register={register('headers')}
                onChange={(checked) => {
                  if (checked) {
                    setState({
                      ...state,
                      step: {
                        ...step,
                        headers: [...step.headers, column],
                      },
                    })
                  } else {
                    setState({
                      ...state,
                      step: {
                        ...step,
                        headers: step.headers.filter((header) => header !== column),
                      },
                    })
                  }
                }}
              />
            )
          })}
        </div>
      </FormRow>
      <FormGroup>
        <Label>{t('Custom Field')}</Label>
        <KeyValuePicker
          dataTest={`${dataTest}-custom-value-picker`}
          onChange={(keyValues) => {
            setState({
              ...state,
              step: {
                ...step,
                custom: keyValues,
              },
            })
          }}
          register={register}
          defaultValues={step.custom}
          groupedInputs={{
            name: 'custom',
          }}
        />
        {errors?.custom && <span className="error">{t(errors.custom.message)}</span>}
      </FormGroup>
      <FormRow className="inset-up-x4">
        <Label>{t('Preview')}</Label>
        {preview.preview && preview.preview.length > 70 && (
          <Tooltip
            trigger={
              <Typography type={TextType.BODY_TEXT_SMALL} className={`ellip ${rootClass}__preview`}>
                {preview.preview}
              </Typography>
            }
          >
            {preview.preview}
          </Tooltip>
        )}
        {preview.preview && preview.preview.length <= 70 && <div>{preview.preview}</div>}
        {preview.body && (
          <div>
            <Label>{t('body')}</Label>
            {preview.body}
          </div>
        )}
      </FormRow>
      <button type="submit" id={submitId} hidden />
    </form>
  )

  const renderView = () => (
    <>
      <Typography text={step.displayName} weight={TextWeight.MEDIUM} type={TextType.SECTION_HEADER} />
      <APIStepDetail
        customFields={step.custom as Header[]}
        format={step.format as FormatOptions}
        headers={step.headers}
        httpHeaders={step.httpHeaders as Header[]}
        method={step.method as MethodOptions}
        url={step.url ?? ''}
      />
    </>
  )

  return <ModalBody className={rootClass}>{isRunning ? renderView() : renderForm()}</ModalBody>
}

export default EditAPIStep
