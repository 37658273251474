import React, { FC, ReactNode, useState } from 'react'

import classNames from 'classnames'

import CollapsibleBannerCollapseButton from '@components/CollapsibleBanner/components/CollapsibleBannerCollapseButton/CollapsibleBannerCollapseButton'
import CollapsibleBannerCollapsedContent from '@components/CollapsibleBanner/components/CollapsibleBannerCollapsedContent/CollapsibleBannerCollapsedContent'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './CollapsibleBanner.css'

export interface CollapsibleBannerProps {
  className?: string
  dataTest?: string
  body: ReactNode
  defaultCollapsed?: boolean
  footer?: ReactNode
  hideCollapsedContent?: boolean
  icon: SvgNames
  iconType?: SvgType
  title: string | ReactNode
  toggleCollapse?: (collapse: boolean) => void
}

const rootClass = 'collapsible-banner'

const CollapsibleBanner: FC<CollapsibleBannerProps> = (props: CollapsibleBannerProps) => {
  const {
    dataTest = rootClass,
    className = '',
    defaultCollapsed = false,
    hideCollapsedContent = false,
    toggleCollapse,
    icon,
    iconType = SvgType.VERY_LARGE_ICON,
    title,
    body,
    footer,
  } = props
  const [state, setState] = useState({ collapsed: false })
  const { collapsed } = state
  const { t } = useTranslation()

  const collapseBanner = (collapse: boolean) => {
    toggleCollapse?.(collapse)
    setState((state) => ({ ...state, collapsed: collapse }))
  }

  return (
    <div
      className={classNames(rootClass, className, { [`${rootClass}__collapsed`]: collapsed, [`${rootClass}__expanded`]: !collapsed })}
      data-test={dataTest}
    >
      {collapsed || defaultCollapsed ? (
        !hideCollapsedContent && <CollapsibleBannerCollapsedContent collapsed={collapsed} onClick={collapseBanner} icon={icon} />
      ) : (
        <>
          <div className={`${rootClass}__title-container`}>
            <div className={`${rootClass}__title-with-icon`}>
              <Svg name={icon} type={iconType} />
              <div className={`${rootClass}__title`}>
                {typeof title === 'string' ? <Typography text={t(title)} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.BOLD} /> : title}
              </div>
            </div>
            <CollapsibleBannerCollapseButton onClick={collapseBanner} />
          </div>
          <div className={`${rootClass}__content`}>
            {body}
            {footer}
          </div>
        </>
      )}
    </div>
  )
}

export default CollapsibleBanner
