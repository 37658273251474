import React, { FC } from 'react'

import classNames from 'classnames'

import './BeeEditor.css'

interface BeeEditorProps {
  className?: string
  dataTest?: string
  anyModalOpen?: boolean
}

const rootClass = 'bee-editor'

const BeeEditor: FC<BeeEditorProps> = (props: BeeEditorProps) => {
  const { dataTest = rootClass, className = rootClass, anyModalOpen = false } = props

  return (
    <div
      data-test={dataTest}
      className={classNames(className, {
        [`${rootClass}__disable-focus`]: anyModalOpen,
      })}
    >
      <div id="bee-plugin-container" className={classNames(`${className}__editor`)} />
    </div>
  )
}

export default BeeEditor
