import React from 'react'

import { ApolloQueryResult } from '@apollo/client'
import { ListSchemaQuery, ProgramSource } from '@graphql/types/query-types'
import { logError } from '@utils/env'

import { ADD_LIST, Props, State } from './ListSelect'

export const getListSelectUtils = (props: Props, setState: React.Dispatch<React.SetStateAction<State>>) => ({
  listChange: function (event: React.ChangeEvent<HTMLSelectElement>) {
    if (event.target.value === ADD_LIST) {
      setState((state) => ({
        ...state,
        addingList: true,
      }))
    } else {
      props.onChange?.(event.target.value)
      setState((state) => ({
        ...state,
        previouslySelectedList: event.target.value,
      }))
    }
  },
  loadListSchemaSuccess: function (data: ApolloQueryResult<ListSchemaQuery>) {
    setState((state) => {
      const newProgramSources: ProgramSource[] = data.data.listSchemas.map((listSchema) => {
        const baseId = state.loadingLists?.find((list) => list.id == listSchema.listId)?.baseId
        return {
          id: listSchema.listId,
          baseId: baseId ?? '',
          name: listSchema.displayName,
          headers: listSchema.schema?.headers ?? [],
          __typename: 'ProgramSource',
        }
      })

      props.onProgramSourcesUpdate(newProgramSources)
      return {
        ...state,
        loadingLists: undefined,
      }
    })
  },
  loadListSchemaError: function (error: Error) {
    logError(error)
    setState((state) => ({
      ...state,
      loadingLists: undefined,
    }))
  },
})
