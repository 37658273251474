import React, { ReactNode } from 'react'

import { ApolloClient } from '@apollo/client'
import SvgNames from '@components/Svg/SvgNames'
import globals, { EXTERNAL_WINDOW_PATH, rootContext } from '@const/globals'
import { AccountSettings } from '@graphql/types/query-types'
import { Action } from '@interface/Action'
import { isClassicUrl, isSalesUser, isTransPerfectUser } from '@src/pages/IFrameView/iframeViewUtils'
import { LoadAccountRequest } from '@utils/account/actions'
import { AccountState } from '@utils/account/reducer'
import documentUtils from '@utils/document'
import iframe from '@utils/iframe/iframe'
import { NavigationInterface } from '@utils/navigation/navigation.utils'
import { ToggleModalOpenedPayload } from '@utils/navigation/state/actions'
import { LARGE_BREAKPOINT } from '@utils/sizes'
import utils from '@utils/utils'
import windowUtils from '@utils/window'

import salesNav from './conf/salesNav'
import standardNav from './conf/standardNav'
import transperfectNav from './conf/transperfectNav'
import { AnimationState, AnimationType, NavigationState } from './Navigation'

// The different navigation configurations

export const EXPANDING_TIME = 180

export interface NavItemWithIndex {
  navItem: NavigationInterface
  menuNotation: string
}

export function getCustomNavigationLink(accountSettings?: AccountSettings): NavigationInterface[] | null {
  if (!accountSettings || !accountSettings.customLink || (accountSettings.linkTriplet?.length || 0) < 3) return null
  const hidden = accountSettings.linkTriplet?.[3] === 'true'
  if (hidden) return null
  let icon = SvgNames.customLink
  const url = accountSettings.linkTriplet[0] ?? ''
  const hint = accountSettings.linkTriplet[1] ?? ''
  const title = accountSettings.linkTriplet[2] ?? ''
  if (title === 'CustomerCare') {
    icon = SvgNames.customerCare
  }
  let label = title
  if (label.length > 100) {
    label = label.substr(0, 100) + '...'
  }
  let smallFont = false
  if (label.length > 50) {
    smallFont = true
  }

  const openWindow = url
    ? {
        amc: true,
        isExternalUrl: true,
        url,
        name: `${title}${hint ? ': ' + hint : ''}`,
      }
    : undefined

  return [
    {
      id: 'custom-link',
      smallFont,
      icon: icon,
      title: hint || label,
      label: label,
      openWindow,
      topSection: true,
    },
  ]
}

export function getSelectedMenuNotation(curIndex: string, index: number): string {
  if (curIndex.length === 0) {
    return `${index}`
  }
  return `${curIndex}.${index}`
}

function matchesUrl(urls: string[], currentParts: string[]): boolean {
  return (
    urls.find((url) => {
      const parts = url.split(/[\/\?]+/)
      if (parts.length <= currentParts.length) {
        return parts.reduce((acc: boolean, cur: string, i: number) => {
          if (!acc) return false
          if (cur === '*') return true
          return cur === currentParts[i]
        }, true)
      }
    }) !== undefined
  )
}

export function checkIfNavItemMatchesUrl(currentURL: string, navItem: NavigationInterface) {
  if (navItem.url) {
    const navUrl = `${rootContext}/${navItem.url}`
    // Match nav item that may or may not have a query string attached
    const [currentPath, _] = currentURL.split('?')

    // Classic pages may have distinct nav entries for the same page with different query strings
    if (navUrl.includes('classic/') ? currentURL.startsWith(navUrl) : currentPath === navUrl) {
      return true
    }
  }
  const currentParts = currentURL.replace(`${rootContext}/`, '').split(/[\/\?]+/)
  let isExcluded = false
  if (navItem.excludeUrls) {
    isExcluded = matchesUrl(navItem.excludeUrls, currentParts)
  }
  if (navItem.alternateUrls) {
    return matchesUrl(navItem.alternateUrls, currentParts) && !isExcluded
  }
  return false
}

export function getNavItemFromCurrentUrl(
  currentMenuNotation = '',
  url: string,
  accountSettings: AccountSettings,
  navigation?: NavigationInterface[]
): NavItemWithIndex[] | null {
  if (!navigation) return null
  const navItems: NavItemWithIndex[] = []
  for (let i = 0; i < navigation.length; i++) {
    const nav = navigation[i]
    const isItemRendered = shouldRenderCurrentNavigationItem(nav.settingsAnd, accountSettings, nav.settings)

    if (!isItemRendered) {
      continue
    }

    const menuNotation = getSelectedMenuNotation(currentMenuNotation, i)
    if (nav?.url === url || checkIfNavItemMatchesUrl(url, nav)) {
      navItems.push({
        navItem: nav,
        menuNotation,
      })
    }
    if (nav.items) {
      const navItem = getNavItemFromCurrentUrl(menuNotation, url, accountSettings, nav.items)
      if (navItem) {
        navItems.push(...navItem)
      }
    }
  }
  return navItems.length > 0 ? navItems : null
}

export function getPageTitleFromURL(url: string, accountSettings: AccountSettings, navigation?: NavigationInterface[]): string | ReactNode | null {
  const navItems = getNavItemFromCurrentUrl(undefined, url, accountSettings, navigation)
  return navItems ? navItems[0].navItem?.label : null
}

export function checkIfContextMenuShouldBeClosed(expanded: boolean, state: NavigationState, setState: (state: NavigationState) => void) {
  if (!expanded && state.currentlySelectedMenu.length > 0) {
    setState({
      ...state,
      currentlySelectedMenu: '',
      stopIFrameFromOverridingState: true,
    })
  }
}

export function checkIfContextMenuShouldBeClosedByClick(
  event: Event,
  navigationRef: React.RefObject<HTMLDivElement>,
  expanded: boolean,
  state: NavigationState,
  setState: (state: NavigationState) => void
) {
  const target = event.target
  if (documentUtils.isInstanceOfNode(target) && !navigationRef.current?.contains(target as Node)) {
    checkIfContextMenuShouldBeClosed(expanded, state, setState)
  }
}

export function checkIfContextMenuShouldBeClosedByIframe(expanded: boolean, state: NavigationState, setState: (state: NavigationState) => void) {
  if (documentUtils.getActiveElement() === iframe.getIFrame()) {
    checkIfContextMenuShouldBeClosed(expanded, state, setState)
  }
}

export function toggleNavigation(expanded: boolean, state: NavigationState, setState: (state: NavigationState) => void, finalState?: AnimationState) {
  setState({
    ...state,
    animation: expanded ? AnimationType.menuIn : AnimationType.menuOut,
    animationFinalState: {
      ...finalState,
      animation: undefined,
    },
    animationTime: EXPANDING_TIME,
  })
}

export function toggleNavigationCollapseStateOnWindowResize(_expanded: boolean, state: NavigationState, setState: (state: NavigationState) => void) {
  if (state.userInitiatedNavigationCollapse) return
  const width = windowUtils.getWindowWidth()
  let expanded = _expanded
  let toggle = false
  let finalState = {}
  if (state.windowWidth) {
    // Window size transitioning from large to small
    if (state.windowWidth > LARGE_BREAKPOINT && width <= LARGE_BREAKPOINT) {
      toggle = true
      expanded = true
      finalState = {
        currentlySelectedMenu: '',
        lastSelectedMenu: state.currentlySelectedMenu,
        menuItemSelectedByUserClick: false,
        menuItemSelectedByUserHover: false,
      }

      // Window size transitioning from small to large
    } else if (state.windowWidth <= LARGE_BREAKPOINT && width > LARGE_BREAKPOINT) {
      toggle = true
      expanded = false
      finalState = {
        currentlySelectedMenu: state.lastSelectedMenu ?? state.currentlySelectedMenu,
        menuItemSelectedByUserClick: false,
        menuItemSelectedByUserHover: false,
      }
    }
  }
  if (toggle && state.animation === undefined) {
    toggleNavigation(
      expanded,
      {
        ...state,
        windowWidth: width,
      },
      setState,
      {
        ...finalState,
      }
    )
  } else {
    setState({
      ...state,
      windowWidth: width,
      animating: false,
    })
  }
}

export function getCurrentMenu(state: NavigationState, url: string, accountSettings: AccountSettings): string {
  let currentMenu = state.currentlySelectedMenu
  const newNavItems = getNavItemFromCurrentUrl(undefined, url, accountSettings, state.navigation)

  if (newNavItems) {
    if (newNavItems.length > 1 && state.clickedMenu) {
      currentMenu = newNavItems.find((item) => item.menuNotation === state.clickedMenu)?.menuNotation ?? newNavItems[0].menuNotation
    } else {
      if (state.currentlySelectedMenu !== newNavItems[0].menuNotation) {
        currentMenu = newNavItems[0].menuNotation
      }
    }
  }

  return currentMenu
}

export function handleMessagesReceivedFromIFrame(
  client: ApolloClient<object>,
  message: any,
  account: AccountState,
  state: NavigationState,
  setState: (state: NavigationState) => void,
  loadAccount: (payload?: LoadAccountRequest) => Action<LoadAccountRequest>,
  showNavigation: () => Action,
  toggleModalOpened: (modalOpen: boolean) => Action<ToggleModalOpenedPayload>,
  setPendingIFrameRequest: (iframeMessage?: any) => Action,
  visible: boolean,
  t: Function,
  history: any
) {
  if (!account || !message) return false

  // only accept messages from same domain
  if (!iframe.validateOrigin(message.origin)) return false

  const { accountSettings, loading, pendingIframeMessage, results } = account || {}
  const appTitle = accountSettings && !accountSettings.isAgencyAccount ? accountSettings.appTitle : undefined

  const isError = results?.error !== undefined
  if (message.data?.actonCurrentPage) {
    if (!accountSettings && !loading && !isError) {
      loadAccount({ client })
      return false
    }

    const cleanUrl = message.data.actonCurrentPage

    // If currently at root of application, or the current page is not for classic acton, then return
    if (!pendingIframeMessage && (cleanUrl === '/' || !window.location.pathname.startsWith(`${rootContext}/classic/`))) {
      return false
    }

    const fullUrl = `${rootContext}/classic${cleanUrl}`
    const title = t(getPageTitleFromURL(`classic${cleanUrl}`, accountSettings as AccountSettings, state.navigation) ?? '')
    const windowObject = window.location?.search.includes('crmIntegration=1') ? window.parent : window.top
    documentUtils.setTitle(title, appTitle)
    const isBackToParentReferrer = window.document.referrer.includes('backToParent')
    if (!isBackToParentReferrer) {
      windowObject?.history.replaceState('', documentUtils.getTitle(), fullUrl)
    }
    if (!visible) {
      showNavigation()
    }

    setState({
      ...state,
      currentURL: fullUrl,
      currentlySelectedMenu: getCurrentMenu(state, `classic${cleanUrl}`, accountSettings as AccountSettings),
    })
    if (pendingIframeMessage) {
      iframe.postMessage(pendingIframeMessage)
      setPendingIFrameRequest()
    }
    return true
  } else if (message.data?.actonOnLogin) {
    utils.sendLoginScreen()
    return true
  } else if (message.data?.actonModalOpen !== undefined) {
    // Hide navigation while a modal within the iframe is open
    if (message.data?.actonModalOpen) {
      toggleModalOpened(true)
    } else {
      toggleModalOpened(false)
    }
    return true
  } else if (message.data?.actonSendNewPage) {
    windowUtils.setLocationHref(message.data?.actonSendNewPage)
    return true
  } else if (message.data?.actonSendInternalPage) {
    history.push(`${rootContext}${message.data?.actonSendInternalPage}`)
    return true
  }
  return false
}

export function getNavigationConfiguration(accountSettings?: AccountSettings, dynamicNav?: NavigationInterface[]) {
  // determine navigation configuration based on user
  let navigationConfig = standardNav
  if (isSalesUser(accountSettings?.userSettings.userDesktop)) {
    navigationConfig = salesNav
  } else if (isTransPerfectUser(accountSettings?.userSettings.userDesktop)) {
    navigationConfig = transperfectNav
  }
  return [...(dynamicNav || []), ...navigationConfig, ...(getCustomNavigationLink(accountSettings) || [])]
}

export function clearContextMenuStyles() {
  const menus = documentUtils.querySelectorAll('.navigation__group--depth-1')
  menus.forEach((menu) => {
    ;(menu as HTMLUListElement).style.top = ''
    ;(menu as HTMLUListElement).style.height = ''
    ;(menu as HTMLUListElement).style.overflowY = ''
    ;(menu as HTMLUListElement).style.transform = ''
  })
}

export function adjustContextMenuToFitScreenForMobile(expanded: boolean, state: NavigationState, mobileBodyRef: HTMLDivElement | null) {
  clearContextMenuStyles()
  const menu = documentUtils.querySelector('.navigation__group--depth-1.navigation__group--visible-hover') as HTMLUListElement
  if (menu !== null) {
    if (!expanded && state.currentlySelectedMenu.length > 0) {
      menu.style.transform = `translateY(-${mobileBodyRef?.scrollTop ?? 0}px)`
      const paddingOffset = 36
      const offsetTop = menu.getBoundingClientRect().top
      const realHeight = menu.clientHeight + paddingOffset
      const windowHeight = windowUtils.getWindowHeight()
      if (offsetTop + realHeight > windowHeight) {
        let newTop = offsetTop - (windowHeight - realHeight)
        if (newTop > offsetTop) {
          const newHeight = windowHeight - paddingOffset
          menu.style.height = `${newHeight}px`
          menu.style.overflowY = 'auto'
          newTop = offsetTop
        }
        menu.style.top = `-${newTop}px`
      }
      return true
    }
  }
  return false
}

export function shouldRenderCurrentNavigationItem(settingsAnd = true, accountSettings: AccountSettings, settings?: string[]): boolean {
  if (!settings) return true
  return settings.reduce((acc: boolean, cur: string, index) => {
    if (acc && index > 0 && !settingsAnd) return true
    if (!acc && settingsAnd) return false
    if (cur.includes('.')) {
      const curParts = cur.split('.')
      if (cur.includes('!')) {
        return !(accountSettings as any)[curParts[0]][curParts[1]]
      }
      return !!(accountSettings as any)[curParts[0]][curParts[1]]
    } else {
      if (cur.includes('!')) {
        return !(accountSettings as any)[cur.replace('!', '')]
      }
      return !!(accountSettings as any)[cur]
    }
  }, true)
}

export function showRenderNavigationItemsChildren(menuNotation: string, state: NavigationState): boolean {
  if (menuNotation.length > state.currentlySelectedMenu.length) return false
  if (menuNotation.length === state.currentlySelectedMenu.length) return menuNotation === state.currentlySelectedMenu
  const navParts = menuNotation.split('.')
  const activeParts = state.currentlySelectedMenu.split('.')
  return navParts.reduce((acc: boolean, cur: string, index) => {
    if (!acc) return acc
    return cur === activeParts[index]
  }, true)
}

export function getDepth(menuNotation: string) {
  return Math.ceil(menuNotation.length / 2)
}

export function getTotalMaxHeightForSubMenu(
  expanded: boolean,
  items: NavigationInterface[],
  parentMenuNotation: string,
  accountSettings: AccountSettings,
  state: NavigationState
) {
  let total = 0
  const depth = getDepth(parentMenuNotation)
  if (depth === 1 && !expanded) {
    total += 58
  }
  items.forEach((navItem, index) => {
    if (shouldRenderCurrentNavigationItem(navItem.settingsAnd, accountSettings, navItem.settings)) {
      total += 39
    }
    const menuNotation = getSelectedMenuNotation(parentMenuNotation, index)
    if (navItem.items && showRenderNavigationItemsChildren(menuNotation, state)) {
      total += getTotalMaxHeightForSubMenu(expanded, navItem.items, menuNotation, accountSettings, state)
    }
  })
  return total
}

export function currentUrlBelongsToNavItemsChildren(items: NavigationInterface[], curUrl: string, accountSettings?: AccountSettings): boolean {
  if (curUrl.length <= 1) return false
  for (const item of items) {
    const isItemRendered = shouldRenderCurrentNavigationItem(item.settingsAnd, accountSettings as AccountSettings, item.settings)
    if (checkIfNavItemMatchesUrl(curUrl, item) && isItemRendered) {
      return true
    }
    if (item.items && currentUrlBelongsToNavItemsChildren(item.items, curUrl, accountSettings)) {
      return true
    }
  }
  return false
}

export function navigationItemWithChildrenSelected(
  expanded: boolean,
  menuNotation: string,
  state: NavigationState,
  setState: (val: NavigationState) => void,
  isRoot: boolean,
  userClick: boolean
) {
  if (menuNotation === state.currentlySelectedMenu) {
    if (!userClick) return
    let newActiveMenu = state.currentlySelectedMenu.substr(0, state.currentlySelectedMenu.lastIndexOf('.'))
    if (!expanded && !state.menuItemSelectedByUserClick) {
      newActiveMenu = state.currentlySelectedMenu
    }
    setState({
      ...state,
      currentlySelectedMenu: newActiveMenu,
      stopIFrameFromOverridingState: true,
      menuItemSelectedByUserClick: true,
      menuItemSelectedByUserHover: isRoot ? false : state.menuItemSelectedByUserHover,
    })
  } else {
    if (menuNotation.length < state.currentlySelectedMenu.length) {
      const navParts = menuNotation.split('.')
      const menuParts = state.currentlySelectedMenu.split('.')
      for (let i = navParts.length - 1; i >= 0; i--) {
        const newLength = navParts.length - (navParts.length - i)
        const newNavIndex = newLength > 0 ? navParts.slice(0, newLength).join('.') : ''
        if (navParts[i] == menuParts[i]) {
          setState({
            ...state,
            currentlySelectedMenu: newNavIndex,
            stopIFrameFromOverridingState: true,
            menuItemSelectedByUserClick: userClick,
          })
          return
        }
      }
    }
    setState({
      ...state,
      currentlySelectedMenu: menuNotation,
      stopIFrameFromOverridingState: true,
      menuItemSelectedByUserClick: userClick,
      menuItemSelectedByUserHover: isRoot ? !userClick : state.menuItemSelectedByUserHover,
      hideOverflow: !expanded && getDepth(menuNotation) >= 2 ? menuNotation : undefined,
    })
  }
}

export function navigationActionOpenWindowInIFrame(setPendingIFrameRequest: (iframeMessage?: any) => void, navItem?: NavigationInterface) {
  if (!!navItem?.openWindow?.externalWindowWrapper) {
    window.open(`${rootContext}/classic/if${EXTERNAL_WINDOW_PATH}/${navItem.openWindow.externalWindowWrapper}`, `_blank`)
  } else {
    if (isClassicUrl(window.location.pathname)) {
      iframe.postMessage(
        {
          actonOpenWindow: navItem?.openWindow,
        },
        setPendingIFrameRequest
      )
    } else if (navItem?.openWindow?.url) {
      const url = navItem?.openWindow.url.replace('..', 'acton')
      if (navItem.openWindow.isExternalUrl) {
        window.open(url, '_blank')?.focus()
      } else {
        window.open(`${window.location.origin}/${url}`, '_blank')?.focus()
      }
    }
  }
}

export function navigationActionHideListingFoldersInIFrame(setPendingIFrameRequest?: (iframeMessage?: any) => void) {
  iframe.postMessage(
    {
      actonHideAllListingFolders: true,
    },
    setPendingIFrameRequest
  )
}

export function checkIfMenuShouldBeExpandedFromFocus(navIndex: string, state: NavigationState, setState: (val: NavigationState) => void) {
  if (globals.isEventClick()) {
    return null
  }
  if (state.currentlySelectedMenu.length > navIndex.length || navIndex.length - 2 > state.currentlySelectedMenu.length) {
    setState({
      ...state,
      currentlySelectedMenu: navIndex.substr(0, navIndex.lastIndexOf('.')),
      stopIFrameFromOverridingState: true,
    })
  } else {
    const navIndexParts = navIndex.split('.')
    const menuParts = state.currentlySelectedMenu.split('.')
    for (let i = 0; i < menuParts.length; i++) {
      if (menuParts[i] !== navIndexParts[i]) {
        setState({
          ...state,
          currentlySelectedMenu: navIndex.substr(0, navIndex.lastIndexOf('.')),
          stopIFrameFromOverridingState: true,
        })
      }
    }
  }
}

export const handleClassicModalCall = (
  state: NavigationState,
  setState: Function,
  {
    origin,
    data: {
      modalData: { message, type },
    },
  }: any
) =>
  // only accept messages from same domain
  iframe.validateOrigin(origin) ? setState({ ...state, openModal: { showModal: true, messageFromClassic: message, type } }) : false

export default {
  getCustomNavigationLink,
  checkIfContextMenuShouldBeClosedByClick,
  checkIfContextMenuShouldBeClosedByIframe,
  getNavigationConfiguration,
  toggleNavigationCollapseStateOnWindowResize,
  handleMessagesReceivedFromIFrame,
  handleClassicModalCall,
  adjustContextMenuToFitScreenForMobile,
  clearContextMenuStyles,
  checkIfContextMenuShouldBeClosed,
  navigationItemWithChildrenSelected,
  checkIfMenuShouldBeExpandedFromFocus,
  navigationActionOpenWindowInIFrame,
  navigationActionHideListingFoldersInIFrame,
  getPageTitleFromURL,
  showRenderNavigationItemsChildren,
  currentUrlBelongsToNavItemsChildren,
  toggleNavigation,
  getDepth,
  getTotalMaxHeightForSubMenu,
  checkIfNavItemMatchesUrl,
}
