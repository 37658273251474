import React, { FC } from 'react'

import classNames from 'classnames'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './FavIconView.css'

interface Props {
  url: string
  isDefaultURL: boolean
  isValidURL: boolean
  className?: string
  dataTest?: string
}

const rootClass = 'fav-icon__view'

const FavIconView: FC<Props> = (props: Props) => {
  const { url, isDefaultURL, isValidURL, className, dataTest = rootClass } = props
  const { t } = useTranslation()

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__tabbar`} data-test={`${dataTest}-tabbar`}>
        <div className={`${rootClass}__tabbar-dots`}>
          <span /> <span /> <span />
        </div>
        <div className={`${rootClass}__tabbar-tab`} data-test={`${dataTest}-tabbar-tab`}>
          {isValidURL ? (
            <img alt="favicon" className={`${rootClass}__tabbar-image`} data-test={`${dataTest}-tabbar-image`} src={url} />
          ) : (
            <Svg name={SvgNames.globe} type={SvgType.LARGE_ICON} dataTest={`${dataTest}-globe-svg`} />
          )}
          <Typography
            text={t(isDefaultURL ? 'Default favicon' : 'Favicon preview')}
            inline
            lineHeight={LineHeight.MEDIUM_LARGE}
            className={`${rootClass}__tabbar-url ellip`}
            data-test={`${dataTest}-tabbar-url`}
          />
        </div>
      </div>
      <div className={`${rootClass}__source`} data-test={`${dataTest}-source`}>
        <Typography
          text={`${t('Source URL')}:`}
          lineHeight={LineHeight.MEDIUM_LARGE}
          weight={TextWeight.MEDIUM}
          className={`${rootClass}__source-title`}
        />
        <Typography text={isDefaultURL ? 'Not set' : url} lineHeight={LineHeight.MEDIUM_LARGE} className={`${rootClass}__source-url ellip`} />
        {isDefaultURL || !isValidURL ? (
          <Tooltip
            position="right"
            trigger={
              <Svg
                className={classNames({
                  [`${rootClass}__svg-invalid`]: !isDefaultURL,
                })}
                name={SvgNames[isDefaultURL ? 'info' : 'inputStatusInvalid']}
                type={SvgType.LARGER_ICON}
                dataTest={`${dataTest}-tooltip-svg`}
              />
            }
            triggerClassName={`${rootClass}__tooltip`}
          >
            {t('FavIcon.Not.Set.Tooltip', { context: isDefaultURL ? 'default' : 'invalid' })}
          </Tooltip>
        ) : (
          <a href={url} rel="noreferrer" target="_blank" data-test={`${dataTest}-image-link`}>
            <Svg name={SvgNames.externalLink} type={SvgType.ICON} fill={SvgColor.TEXT_TEAL} />
          </a>
        )}
      </div>
    </div>
  )
}

export default FavIconView
