import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { ColWithTooltip } from '@components/TableV2/utils/tableV2ColumnUtils'

import { FormDomainItem } from './FormDomains'

export const columnsV2: ColumnDefWithAdditionalProps<FormDomainItem, unknown>[] = [
  {
    header: 'FORM DOMAIN',
    accessorKey: 'domain',
    sortingFn: 'caseInsensitive',
    textAlign: 'left',
    cell: (cell) => ColWithTooltip({ cell }),
  },
]
