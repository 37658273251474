export const blockedBroadcastChannel = new BroadcastChannel('blocked-tab')

const sendBroadcastMessage = (message: any) => {
  blockedBroadcastChannel.postMessage(message)
}

const subscribeOnBroadcastMessage = (callback: (event: MessageEvent) => void) => {
  blockedBroadcastChannel.onmessage = (event) => {
    callback(event.data)
  }
}

const unsubscribeFromBroadcastMessage = () => {
  blockedBroadcastChannel.onmessage = null
}

export { sendBroadcastMessage, subscribeOnBroadcastMessage, unsubscribeFromBroadcastMessage }
