import React, { FC } from 'react'

import classNames from 'classnames'

import Typography, { TextWeight, TypographyProps } from '@components/Typography/Typography'

import './BreadcrumbsItem.css'

export interface BreadcrumbsItemProps {
  className?: string
  isActive?: boolean
  text: string
  charsLimit?: number
  customTypography?: Partial<TypographyProps>
}

const rootClass = 'breadcrumbs-item'

const BreadcrumbsItem: FC<BreadcrumbsItemProps> = (props: BreadcrumbsItemProps) => {
  const { isActive = false, text, className = '', charsLimit = text.length, customTypography } = props

  const breadcrumbText = !isActive && text.length > charsLimit ? `${text.slice(0, charsLimit)}...` : text

  return (
    <Typography
      className={classNames(rootClass, className, 'ellip', {
        [`${rootClass}--active`]: isActive,
        [`${rootClass}--truncate`]: text.length > charsLimit,
      })}
      weight={isActive ? TextWeight.BOLD : TextWeight.REGULAR}
      text={breadcrumbText}
      {...customTypography}
    />
  )
}

export default BreadcrumbsItem
