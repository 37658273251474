import { gql } from '@apollo/client'

export default gql`
  query getEmailMessagesReportSummary($startTime: String!, $endTime: String!, $onRefresh: Boolean!) {
    getEmailMessagesReportSummary(startTime: $startTime, endTime: $endTime, onRefresh: $onRefresh) {
      currentDate
      currentTime
      monthlySummary {
        name
        ... on EmailMessagesMonthlySummary {
          totalOpens
          totalSends
          totalClicks
          totalOptOuts
          totalBounces
        }
        days {
          dayOfWeek
          dayOfMonth
          name
          ... on EmailMessagesDaySummary {
            opens
            sends
            clicks
            optOuts
            bounces
          }
        }
      }
      weeklySummary {
        weekdayCounts {
          name
          ... on EmailMessagesWeekdaySummary {
            totalOpens
            totalSends
            totalClicks
            totalOptOuts
            totalBounces
          }
        }
      }
    }
  }
`
