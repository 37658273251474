import React, { FC, useCallback, useContext, useMemo } from 'react'

import { TextType } from '@components/Typography/Typography'
import ValidationSection from '@src/pages/EmailComposer/components/common/ValidationSection/ValidationSection'
import { Validation, ValidationSectionStatus } from '@src/pages/EmailComposer/components/common/ValidationsList/ValidationsList'
import { CommonComposerTab } from '@utils/composer/commonComposer/CommonComposer.context'
import { EmailComposerContext, ValidationSectionType } from '@utils/composer/context/EmailComposer.context'
import { useTranslation } from '@utils/const/globals'

interface EmailTrackingProps {
  rootClass: string
  dataTest?: string
}

const EmailTracking: FC<EmailTrackingProps> = ({ rootClass, dataTest = rootClass }) => {
  const {
    values: {
      message: { analyticsFields, subscriptionCategoryId, campaignId },
      campaigns,
      subscriptionCategories,
      validations: {
        settingsValidations: { isAnalyticsFieldsIncomplete },
      },
      messageConfiguration: {
        reviewAndSend: { disableEditings },
      },
      detectedURLChanges: { plainTextMode },
    },
    api: { onTabChange, onStatusChange },
  } = useContext(EmailComposerContext)

  const { t } = useTranslation()

  const handleEdit = useCallback(
    () => onTabChange(CommonComposerTab.SETTINGS, { fromValidationSection: ValidationSectionType.TRACKING }),
    [onTabChange]
  )

  const validations = useMemo(() => {
    const validations: Validation[] = []
    if (subscriptionCategoryId || campaignId) {
      const categoryName = subscriptionCategoryId ? subscriptionCategories.find(({ id }) => id === subscriptionCategoryId)?.name : undefined
      const campaignName = campaignId ? campaigns.find(({ id }) => id === campaignId)?.name : undefined

      if (categoryName || campaignName) {
        categoryName &&
          validations.push({
            key: 'category',
            title: 'EmailComposer.Email.Tracking.Block.Category',
            status: ValidationSectionStatus.SUCCESS,
            titleProps: { values: { name: categoryName } },
          })
        campaignName &&
          validations.push({
            key: 'campaign',
            title: 'EmailComposer.Email.Tracking.Block.Campaign',
            status: ValidationSectionStatus.SUCCESS,
            titleProps: { values: { name: campaignName } },
          })
      }
    }

    if (analyticsFields.length) {
      const status = isAnalyticsFieldsIncomplete ? ValidationSectionStatus.WARNING : ValidationSectionStatus.SUCCESS
      validations.push({
        key: 'analytics',
        status,
        title: 'EmailComposer.Email.Tracking.Block.Analytics',
        titleProps: { values: { context: status } },
      })
    }

    if (plainTextMode) {
      validations.push({
        key: 'noTrackPlainText',
        status: ValidationSectionStatus.WARNING,
        title: 'EmailComposer.Email.Tracking.PlainText.NoTrack',
      })
      validations.push({
        key: 'noClickPlainText',
        status: ValidationSectionStatus.WARNING,
        title: 'EmailComposer.Email.Tracking.PlainText.NoClick',
      })
    }

    return validations
  }, [subscriptionCategoryId, campaignId, analyticsFields.length, plainTextMode, subscriptionCategories, campaigns, isAnalyticsFieldsIncomplete])

  return (
    <div className={rootClass} data-test={dataTest}>
      <ValidationSection
        type={ValidationSectionType.TRACKING}
        dataTest={dataTest}
        status={validations.length === 0 ? ValidationSectionStatus.REST : undefined}
        title="Email tracking"
        subTitle={validations.length === 0 ? { text: t('EmailComposer.Email.Tracking.Block.NoTrack'), type: TextType.BODY_TEXT_LIGHT } : undefined}
        buttonLabel="Edit settings"
        validations={validations}
        onClick={handleEdit}
        tooltipContentKey={disableEditings ? t('EmailComposer.Resend.Disabled') : ''}
        disableEditBtn={disableEditings}
        onStatusChange={onStatusChange}
      />
    </div>
  )
}

export default EmailTracking
