import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import { useApolloClient, useQuery } from '@apollo/client'
import ColumnChart, { ColumnChartProps } from '@components/ColumnChart/ColumnChart'
import LineChart, { LineChartProps } from '@components/LineChart/LineChart'
import PieChart, { PieChartProps } from '@components/PieChart/PieChart'
import { SwitchOptions } from '@components/Switch/Switch'
import { ChartField, ChartFieldData, ChartSettingState, LegendLocation } from '@const/Chart.constants'
import getAutomatedProgramCounts from '@graphql/queries/getAutomatedProgramCounts'
import getAutomatedProgramEnterExitCounts from '@graphql/queries/getAutomatedProgramEnterExitCounts'
import getAutomatedProgramStandardExitCounts from '@graphql/queries/getAutomatedProgramStandardExitCounts'
import { GetAutomatedProgramCountsQuery } from '@graphql/types/query-types'
import ProgramEmptyChart from '@src/pages/programs/dashboard/components/ProgramEmptyChart/ProgramEmptyChart'
import ChartExtraElement from '@src/pages/programs/dashboard/components/ProgramPerformance/components/ChartExtraElement/ChartExtraElement'
import {
  ChartFieldClickCallback,
  ChartFieldVisibility,
  defaultChartFieldVisibility,
  getChartFields,
  getDateType,
  getDateTypeQuantity,
  getExitConditionFields,
  programPerformanceChartContainerDefaultState,
  QueryArgs,
  QueryResponse,
  sortEnterExitData,
} from '@src/pages/programs/dashboard/components/ProgramPerformance/components/ProgramPerformanceDetail/components/ProgramPerformanceChartContainer/ProgramPerformanceChartContainer.utils'
import {
  allContactsChartDataC,
  earlyExitsChartDataC,
  inProgramChartDataC,
  standardExitsChartDataC,
} from '@src/pages/programs/dashboard/components/ProgramPerformance/components/ProgramPerformanceDetail/ProgramPerformanceDetail.constants'
import { ProgramPerformanceTab } from '@src/pages/programs/dashboard/components/ProgramPerformance/ProgramPerformance.constants'
import { EarlyExitSegmentInfo } from '@src/pages/programs/dashboard/ProgramDashboard.constants'
import { DateType, getDateString } from '@utils/date'
import useUserSettings from '@utils/hooks/useUserSettings'
import { EmptyState } from '@utils/program/program.constants'

import './ProgramPerformanceChartContainer.css'

export interface ProgramPerformanceChartContainerProps {
  programId: string
  noUpdate: boolean
  programPerformanceTab: ProgramPerformanceTab
  earlyExitSegmentInfos: EarlyExitSegmentInfo[]
  totalContacts: number
  className?: string
  dataTest?: string
  activeTabModifier?: string
  emptyState: EmptyState
}

export interface ProgramPerformanceChartContainerState {
  dateType: DateType
  dateTypeQuantity: number
  chartProps: LineChartProps | ColumnChartProps | PieChartProps
  showEmptyChart: boolean
  chartSettings: ChartSettingState
  chartLoading: boolean
}

const rootClass = 'program-performance-chart-container'

const ProgramPerformanceChartContainer: FC<ProgramPerformanceChartContainerProps> = (props: ProgramPerformanceChartContainerProps) => {
  const {
    programPerformanceTab,
    noUpdate,
    programId,
    totalContacts,
    earlyExitSegmentInfos,
    dataTest = rootClass,
    className = '',
    activeTabModifier,
    emptyState,
  } = props

  const [chartFieldVisibility, setChartFieldVisibility] = useState<ChartFieldVisibility>(defaultChartFieldVisibility)

  const client = useApolloClient()
  const isEarlyExits = programPerformanceTab === ProgramPerformanceTab.EARLY_EXITS
  const isStandardExits = programPerformanceTab === ProgramPerformanceTab.STANDARD_EXITS
  const isAllContacts = programPerformanceTab === ProgramPerformanceTab.All_CONTACTS

  const showEarlyExits = isEarlyExits && earlyExitSegmentInfos.length > 0
  const { userSettings, setUserSetting } = useUserSettings()
  const [state, setState] = useState<ProgramPerformanceChartContainerState>({
    ...programPerformanceChartContainerDefaultState,
    showEmptyChart: totalContacts === 0 && !showEarlyExits,
    chartProps: isAllContacts
      ? { ...allContactsChartDataC, chartType: SwitchOptions.BAR }
      : programPerformanceTab === ProgramPerformanceTab.IN_PROGRAM
      ? { ...inProgramChartDataC }
      : isEarlyExits
      ? { ...earlyExitsChartDataC }
      : { ...standardExitsChartDataC },
  })

  useEffect(() => {
    if (state.chartLoading && userSettings !== undefined) {
      const getChart = userSettings['programManagerCharts']
      const {
        programPerformance,
        programPerformance: { allContacts },
      } = getChart
      setState({
        ...state,
        dateType: getDateType(programPerformanceTab, programPerformance),
        dateTypeQuantity: getDateTypeQuantity(programPerformanceTab, programPerformance),
        chartProps: isAllContacts
          ? {
              ...state.chartProps,
              chartType: allContacts.chartType as SwitchOptions,
            }
          : { ...state.chartProps },
        chartSettings: getChart,
        chartLoading: false,
      })
    }
  }, [userSettings])

  useEffect(() => {
    if (!state.chartLoading) {
      setUserSetting('programManagerCharts', state.chartSettings)
    }
  }, [state.chartSettings])

  const query = isEarlyExits
    ? getAutomatedProgramCounts
    : isStandardExits
    ? getAutomatedProgramStandardExitCounts
    : getAutomatedProgramEnterExitCounts
  const variables = isEarlyExits
    ? {
        programId,
      }
    : {
        programId,
        noUpdate: noUpdate,
        unitsAgo: state.dateTypeQuantity,
        groupBy: state.dateType.toUpperCase(),
      }

  const {
    loading,
    error: pageError,
    data,
  } = useQuery<QueryResponse, QueryArgs>(query, {
    client,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables,
  })

  const ChartComponent =
    state.chartProps.chartType === SwitchOptions.GRAPH ? LineChart : state.chartProps.chartType === SwitchOptions.BAR ? ColumnChart : PieChart
  const chartLocation =
    state.chartProps.chartType === SwitchOptions.GRAPH || state.chartProps.chartType === SwitchOptions.BAR
      ? { legendLocation: LegendLocation.TOP }
      : {}

  useEffect(() => {
    if (!loading && !pageError && !state.chartLoading) {
      if (isEarlyExits) {
        updateEarlyExitData(data as GetAutomatedProgramCountsQuery)
      } else {
        updateData(data)
      }
    }
  }, [loading, pageError, chartFieldVisibility, state.chartLoading])

  const updateEarlyExitData = (data: GetAutomatedProgramCountsQuery) => {
    const {
      getAutomatedProgramCounts: { exitConditions, programCounts },
    } = data
    const field = state.chartProps.fields[0]
    const programExitConditions =
      programCounts.deletedCount === 0
        ? exitConditions
        : [
            ...exitConditions,
            {
              evaluationId: 'deleted',
              count: programCounts.deletedCount,
              not: false,
              score: 0,
              listName: '',
              filterable: true,
            },
          ]
    const exitData: ChartFieldData[] = programExitConditions
      ? getExitConditionFields(programExitConditions, earlyExitSegmentInfos, programPerformanceTab, chartFieldVisibility, handleChartFieldClick)
      : []
    const updateFields: ChartField[] = [{ ...field, data: exitData }]

    setState({ ...state, chartProps: { ...state.chartProps, fields: updateFields } })
  }

  const updateData = (data: any) => {
    const enterDay = isStandardExits
      ? sortEnterExitData(data.getAutomatedProgramStandardExitCounts.standardExitDay, state.dateType)
      : sortEnterExitData(data.getAutomatedProgramEnterExitCounts.enterDay, state.dateType)
    const exitDay = isStandardExits
      ? sortEnterExitData(data.getAutomatedProgramStandardExitCounts.standardExitDay, state.dateType)
      : sortEnterExitData(data.getAutomatedProgramEnterExitCounts.exitDay, state.dateType)

    const chartDateRange = enterDay.map((entry: any) => getDateString(entry.date, state.dateType))
    const chartFields = getChartFields(state.chartProps.fields, enterDay, exitDay, programPerformanceTab, chartFieldVisibility, handleChartFieldClick)

    setState({
      ...state,
      chartProps: {
        ...state.chartProps,
        categories: chartDateRange,
        fields: chartFields,
      },
    })
  }

  const handleDateChange = (dateType: DateType, dateTypeQuantity: number) => {
    const chartSettings = {
      ...state.chartSettings,
      programPerformance: isAllContacts
        ? {
            ...state.chartSettings.programPerformance,
            allContacts: {
              ...state.chartSettings.programPerformance.allContacts,
              dateType: dateType,
              dateTypeQuantity: dateTypeQuantity,
            },
          }
        : {
            ...state.chartSettings.programPerformance,
            standardExits: {
              ...state.chartSettings.programPerformance.standardExits,
              dateType: dateType,
              dateTypeQuantity: dateTypeQuantity,
            },
          },
    }
    setState({ ...state, dateType: dateType, dateTypeQuantity: dateTypeQuantity, chartSettings })
  }

  const handleChartChange = (button: SwitchOptions) => {
    const chartSettings = {
      ...state.chartSettings,
      programPerformance: {
        ...state.chartSettings.programPerformance,
        allContacts: { ...state.chartSettings.programPerformance.allContacts, chartType: button },
      },
    }
    setState({ ...state, chartProps: { ...state.chartProps, chartType: button }, chartSettings })
  }

  const handleChartFieldClick: ChartFieldClickCallback = (chartTab: ProgramPerformanceTab, fieldIndex: number, visible: boolean) => {
    const visibility = { ...chartFieldVisibility }
    visibility[chartTab][fieldIndex] = visible
    setChartFieldVisibility(visibility)
  }

  return (
    <div
      className={classNames(rootClass, className, {
        [`${rootClass}--${activeTabModifier}`]: activeTabModifier != '',
      })}
      data-test={dataTest}
    >
      {(state.showEmptyChart || pageError) && !loading && !state.chartLoading ? (
        <ProgramEmptyChart error={pageError} emptyState={emptyState} />
      ) : (
        <ChartComponent
          {...state.chartProps}
          isDateTime={state.chartProps.chartType === SwitchOptions.GRAPH}
          tickInterval={state.chartProps.chartType === SwitchOptions.GRAPH ? 1 : 0}
          {...chartLocation}
          ExtraElement={
            state.chartProps.chartType === SwitchOptions.PIE || loading || state.chartLoading ? undefined : (
              <ChartExtraElement
                onDateChange={handleDateChange}
                onSwitchChartType={handleChartChange}
                dateType={state.dateType}
                dateTypeQuantity={state.dateTypeQuantity}
                chartType={state.chartProps.chartType ?? SwitchOptions.BAR}
                className={`${rootClass}__extra-element`}
                canSwitchChartType={isAllContacts}
              />
            )
          }
          disablePrint={true}
          loading={loading || state.chartLoading}
          error={pageError}
        />
      )}
    </div>
  )
}

export default ProgramPerformanceChartContainer
