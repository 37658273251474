import React, { FC } from 'react'

import classNames from 'classnames'

import { ButtonProps } from '@components/Button/Button'
import { ModalFooterActionProps } from '@components/Modal/components/ModalFooterAction'
import { ModalType } from '@components/Modal/modalUtils'

import './modalFooter.css'

export enum ModalFooterType {
  Basic = 'basic',
  List = 'list',
  Form = 'form',
}

export interface ModalFooterProps {
  className?: string
  children: React.ReactElement<ButtonProps | ModalFooterActionProps> | React.ReactElement<ButtonProps | ModalFooterActionProps>[]
  // this is used in several program step modals but therre is no corresponding CSS so not carrying over to MF V2
  flexEnd?: boolean
  /** shows a dividing line between modal content and the footer */
  showDivider?: boolean
  footerType?: ModalType
  dataTest?: string
}

const rootClass = 'modal-footer'

/**
 * @deprecated use <ModalFooterV2 instead
 */
export const ModalFooter: FC<ModalFooterProps> = (props: ModalFooterProps) => {
  const { children, footerType, showDivider = true, flexEnd, className = '', dataTest = 'modal-footer' } = props

  let multiple = false
  if (Array.isArray(children)) {
    const rendered = children.filter((child) => (child.props as any)?.render ?? true)
    multiple = rendered.length > 1
  }

  return (
    <div
      className={classNames(rootClass, className, {
        [`${rootClass}--form`]: footerType === ModalFooterType.Form,
        [`${rootClass}--list`]: footerType === ModalFooterType.List,
      })}
      data-test={dataTest}
    >
      <div
        className={classNames(`${rootClass}__inner`, {
          [`${rootClass}__inner--show-divider`]: !footerType && showDivider,
          [`${rootClass}__inner--multiple`]: multiple,
          [`${rootClass}__inner--single-flex-end`]: flexEnd, // this is used in several program step modals but therre is no corresponding CSS so not carrying over to MF V2
          [`${rootClass}__inner--list`]: footerType === ModalFooterType.List,
          [`${rootClass}__inner--form`]: footerType === ModalFooterType.Form,
          [`${rootClass}__inner--default`]: footerType === ModalFooterType.Basic,
        })}
      >
        {children}
      </div>
    </div>
  )
}

export default ModalFooter
