import { gql } from '@apollo/client'

export default gql`
  mutation setCustomLink($customLinkItemInput: CustomLinkItemInput!) {
    setCustomLink(customLinkItemInput: $customLinkItemInput) {
      status
      message
      url
      hint
      text
      hidden
    }
  }
`
