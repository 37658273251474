import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/Button'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { useTranslation } from '@const/globals'
import { MarketingListResponse } from '@graphql/types/query-types'

import './Recipients.css'

const rootClass = 'recipients'

interface RecipientListsProps {
  lists: MarketingListResponse[]
  onRemoveList: (list: MarketingListResponse) => void
}

const RecipientLists: FC<RecipientListsProps> = (props: RecipientListsProps) => {
  const { lists, onRemoveList } = props
  const { t } = useTranslation()

  return (
    <div className={`${rootClass}__lists`}>
      <div className={classNames(`${rootClass}__lists-row`, `${rootClass}__lists-header`)}>
        <div className={`${rootClass}__lists-col`}></div>
        <div className={`${rootClass}__lists-col`}>
          <label>{t('List/Segment')}</label>
        </div>
        <div className={`${rootClass}__lists-col`}>
          <label>{t('Records')}</label>
        </div>
        <div className={`${rootClass}__lists-col`} />
      </div>
      {lists &&
        lists.map((list) => (
          <div className={`${rootClass}__lists-row`} key={list.listId}>
            <div className={`${rootClass}__lists-col`}>
              <span>
                <Svg name={SvgNames.crm} />
              </span>
            </div>
            <div className={`${rootClass}__lists-col`}>{list.listName}</div>
            <div className={`${rootClass}__lists-col`}>{list.totalContacts}</div>
            <div className={`${rootClass}__lists-col`}>
              <Button buttonType={ButtonType.REMOVE} dataTest={`${rootClass}-delete`} title={t('Remove list')} onClick={() => onRemoveList(list)}>
                <Svg name={SvgNames.delete} type={SvgType.ICON} />
              </Button>
            </div>
          </div>
        ))}
    </div>
  )
}

export default RecipientLists
