import { gql } from '@apollo/client'

export default gql`
  query getAutomatedProgramMessageDetails($messageId: Float!, $programId: String!) {
    getAutomatedProgramMessageDetails(messageId: $messageId, programId: $programId) {
      messageText
      messageTitle
      createdBy
      createdOn
      contactLists {
        listName
        listType
      }
      noPhoneNumbers
      duplicates
      messagesSent
      notOptIns
      billableCredits
      failedPersonalizationCount
      recipientsPerCreditMultiplier {
        creditMultiplier
        contacts
        contactsPerCountry {
          country
          contacts
        }
      }
      creditsCharged
      suppressedInternationalContacts
    }
  }
`
