import { gql } from '@apollo/client'

export default gql`
  query getAllSentMessages {
    getAllSentMessages {
      id
      msgTitle
      sentCount
      senderName
      sentTime
      previewUrl
    }
  }
`
