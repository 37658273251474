import { gql } from '@apollo/client'

export default gql`
  query userValidWithToken {
    userValidWithToken {
      valid
      token
      accountContext
    }
  }
`
