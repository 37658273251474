import React, { FC, ReactEventHandler, useState } from 'react'

import classNames from 'classnames'

import MediaCard, { MediaCardProps } from '@components/MediaCard/MediaCard'
import Spinner from '@components/Spinner/Spinner'
import { SvgNames } from '@components/Svg'
import { useTranslation } from '@utils/const/globals'

import './htmlMediaCard.css'

export interface HTMLMediaCardProps extends Omit<MediaCardProps, 'onMainAction' | 'bodyContent'> {
  onSelect: () => void
  onPreview: () => void
  html: string
}
const rootClass = 'html-media-card'

const HTMLMediaCard: FC<HTMLMediaCardProps> = (props: HTMLMediaCardProps) => {
  const { t } = useTranslation()
  const { html, onSelect, onPreview, className, dataTest = rootClass } = props
  const [iframeLoading, setIframeLoading] = useState<boolean>(true)

  const onLoad: ReactEventHandler<HTMLIFrameElement> = (event) => {
    const iframe = event.target as HTMLIFrameElement
    const style = document.createElement('style')
    style.textContent = `
    img {
      max-width: 100%;
      height: auto;
    }
    body {
      font-family: 'Open Sans', Helvetica, Arial, sans-serif;
      font-size: 9px;
    }`
    iframe.width = String(iframe.contentWindow?.document.body.scrollWidth)
    iframe.contentDocument?.head.appendChild(style)
    iframe.height = String(iframe.contentWindow?.document.body.scrollHeight)
    setIframeLoading(false)
  }

  const bodyContent = (
    <>
      {iframeLoading && <Spinner />}
      <iframe
        className={classNames(`${rootClass}__body-iframe`, {
          [`${rootClass}__body-iframe--hidden`]: iframeLoading,
        })}
        title="iframe"
        srcDoc={html}
        scrolling="no"
        onLoad={onLoad}
        data-test={`${dataTest}-iframe`}
        tabIndex={-1}
      />
    </>
  )

  const mediaCardProps: MediaCardProps = {
    onMainAction: onSelect,
    bodyContent,
    actions: [{ key: 'preview', icon: SvgNames.zoom, onClick: onPreview, tooltip: t('Preview') }],
    dataTest,
    ...props,
    className: classNames(rootClass, className),
  }
  return <MediaCard {...mediaCardProps} />
}

export default HTMLMediaCard
