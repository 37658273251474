import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Accordion from '@components/Accordion/Accordion'
import { renderActionButtons } from '@components/AccordionDetailsModal/utils/AccordionDetailsModal.utils'
import Modal, { ModalBody } from '@components/Modal'
import ScrollArea from '@components/ScrollArea/ScrollArea'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './AccordionDetailsModal.css'

export interface AccordionDetailsModalProps {
  className?: string
  dataTest?: string
  isOpen: boolean
  closeModal: () => void
  customHeaderActions?: AccordionDetailsModalHeaderActions[]
  sections: AccordionDetailsModalSection[]
  header: string | ReactNode
  subHeader?: string | ReactNode
}

export interface AccordionDetailsModalHeaderActions {
  icon: SvgNames
  tooltip: string
  onClick: () => void
}

export interface AccordionDetailsModalSection {
  icon: SvgNames
  content: ReactNode
  isAccordion?: boolean
  accordionTitle?: string
  accordionTooltips?: { openTooltip: string; closeTooltip: string }
}

const rootClass = 'accordion-details-modal'

const AccordionDetailsModal: FC<AccordionDetailsModalProps> = (props: AccordionDetailsModalProps) => {
  const { dataTest = rootClass, className = '', customHeaderActions, isOpen, header, sections, subHeader, closeModal } = props
  const { t } = useTranslation()
  const actions: AccordionDetailsModalHeaderActions[] = [
    ...(customHeaderActions ?? []),
    {
      icon: SvgNames.close,
      tooltip: 'Close',
      onClick: () => closeModal(),
    },
  ]
  const textHeader = typeof header === 'string'
  return (
    <div data-test={dataTest} className={className}>
      <Modal isOpen={isOpen} className={rootClass}>
        <div className={classNames(`${rootClass}__header`, { [`${rootClass}__header-text`]: textHeader })}>
          {textHeader ? (
            <Typography text={header} type={TextType.DATA_CARD_MODAL_HEADER} weight={TextWeight.BOLD} dataTest={`${dataTest}-header-text`} />
          ) : (
            header
          )}
          <div className={`${rootClass}__actions`}>{renderActionButtons(actions, t)}</div>
        </div>
        {subHeader &&
          (typeof subHeader === 'string' ? (
            <Typography
              text={subHeader}
              type={TextType.BODY_TEXT_LIGHT}
              className={`${rootClass}__subheader`}
              dataTest={`${dataTest}-subheader-text`}
            />
          ) : (
            subHeader
          ))}
        <ScrollArea showOnEvent={'always'} className={`${rootClass}__scroll`}>
          <ModalBody className={`${rootClass}__body`}>
            {sections.map((section, index) =>
              section.isAccordion ? (
                <div key={index} className={classNames(`${rootClass}__section`, `${rootClass}__accordion`)}>
                  <Accordion
                    childData={[
                      {
                        index: '0',
                        header: (
                          <div className={`${rootClass}__accordion-header`}>
                            <Svg name={section.icon} type={SvgType.LARGE_ICON} className={`${rootClass}__icon`} />
                            <Typography text={section.accordionTitle} type={TextType.DATA_CARD_MODAL_HEADER} />
                          </div>
                        ),
                        content: section.content,
                        chevronTooltips: section.accordionTooltips,
                      },
                    ]}
                    dataTest={`${rootClass}-accordion`}
                    className={`${rootClass}__accordion-arrow`}
                    chevronFillColor={SvgColor.LIGHT_GRAY}
                  />
                </div>
              ) : (
                <div key={index} className={`${rootClass}__section`}>
                  <div className={`${rootClass}__section-regular`}>
                    <Svg name={section.icon} type={SvgType.LARGE_ICON} className={`${rootClass}__icon`} />
                    {section.content}
                  </div>
                </div>
              )
            )}
          </ModalBody>
        </ScrollArea>
      </Modal>
    </div>
  )
}

export default AccordionDetailsModal
