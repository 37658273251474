import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import ColorPicker, { Color } from '@components/ColorPicker/ColorPicker'
import InputWithMaxLength, { InputWithMaxLengthProps } from '@components/InputWithMaxLength/InputWithMaxLength'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { NO_COLOR } from '@utils/tags'

import './InputWithColorPicker.css'

type Props = {
  colorPicked: (color: string) => void
  colorPickerIcon: SvgNames
  colors: Color[]
  defaultColor?: string
  inputWithMaxLengthProps: InputWithMaxLengthProps
}

interface State {
  colorPickerOpen: boolean
  iconColor: string
}

const rootClass = 'input-with-color-picker'

const InputWithColorPicker: FC<Props> = (props: Props) => {
  const { colorPicked, colorPickerIcon, colors, defaultColor, inputWithMaxLengthProps } = props
  const { inputProps } = inputWithMaxLengthProps
  const { dataTest = rootClass, className = '' } = inputProps || {}

  const [state, setState] = useState<State>({ colorPickerOpen: false, iconColor: defaultColor || NO_COLOR })
  const { colorPickerOpen, iconColor } = state

  useEffect(() => {
    colorPicked(iconColor)
  }, [iconColor])

  const pickColor = (color: string) => setState({ ...state, iconColor: color })

  const renderTrigger = () => {
    return (
      <div className={`${rootClass}__color-picker-trigger`} data-test={`${dataTest}-trigger`}>
        <Svg className={`${rootClass}__color-picker-icon`} name={colorPickerIcon} type={SvgType.MEDIUM} style={{ fill: iconColor }} />
        <Svg
          className={classNames(`${rootClass}__color-picker-caret`, { [`${rootClass}__color-picker-caret-open`]: colorPickerOpen })}
          name={SvgNames.caretFillDown}
        />
      </div>
    )
  }

  return (
    <div className={classNames(rootClass, className)}>
      <ColorPicker
        className={`${rootClass}__color-picker`}
        colors={colors}
        pickColor={pickColor}
        selectedColor={iconColor}
        trigger={renderTrigger()}
      />
      <InputWithMaxLength {...inputWithMaxLengthProps} className={`${rootClass}__input`} data-test={`${dataTest}-input`} />
    </div>
  )
}

export default InputWithColorPicker
