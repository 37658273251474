import addTag from '@graphql/microservices/categorization/addTag'
import applyTags from '@graphql/microservices/categorization/applyTags'
import deleteTags from '@graphql/microservices/categorization/deleteTags'
import getItemTypesByLabel from '@graphql/microservices/categorization/getItemTypesByLabel'
import getTags from '@graphql/microservices/categorization/getTags'
import removeTags from '@graphql/microservices/categorization/removeTags'
import {
  AddTagMutation,
  AddTagMutationVariables,
  ApplyTagsMutation,
  ApplyTagsMutationVariables,
  DeleteTagsMutation,
  DeleteTagsMutationVariables,
  GetItemTypesByLabelQuery,
  GetItemTypesByLabelQueryVariables,
  GetTagsQuery,
  GetTagsQueryVariables,
  LabelDto,
  LabelDtoInput,
  RemoveTagsMutation,
  RemoveTagsMutationVariables,
} from '@graphql/types/microservice/categorization-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

interface GetTagRequests {
  getAllTagsRequest: (types: string[], subTypes: string[]) => FetchPromise<GetTagsQuery>
  getItemTypesUsedRequest: (tagId: number) => FetchPromise<GetItemTypesByLabelQuery>
  createTagRequest: (type: string, tag: LabelDtoInput) => FetchPromise<AddTagMutation>
  deleteTagRequest: (type: string, tag: LabelDto) => FetchPromise<DeleteTagsMutation>
  applyTagsRequest: (type: string, ids: number[], labels: LabelDtoInput[]) => FetchPromise<ApplyTagsMutation>
  removeTagsRequest: (type: string, ids: number[], tagsToRemove: number[]) => FetchPromise<RemoveTagsMutation>
}

export const useTagRequests = (): GetTagRequests => {
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })

  const getAllTagsRequest = async (types: string[], subTypes: string[]) =>
    await client.query<GetTagsQuery, GetTagsQueryVariables>({
      query: getTags,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        types,
        subTypes,
      },
    })

  const getItemTypesUsedRequest = async (tagId: number) =>
    await client.query<GetItemTypesByLabelQuery, GetItemTypesByLabelQueryVariables>({
      query: getItemTypesByLabel,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        labelId: tagId,
      },
    })

  const createTagRequest = (type: string, tag: LabelDtoInput) =>
    client.mutate<AddTagMutation, AddTagMutationVariables>({
      mutation: addTag,
      errorPolicy: 'all',
      variables: {
        type,
        tag,
      },
    })

  const deleteTagRequest = (type: string, tag: LabelDto) =>
    client.mutate<DeleteTagsMutation, DeleteTagsMutationVariables>({
      mutation: deleteTags,
      errorPolicy: 'all',
      variables: {
        type,
        tagsId: [tag.id],
      },
    })

  const applyTagsRequest = async (type: string, items: number[], labels: LabelDtoInput[]) =>
    await client.mutate<ApplyTagsMutation, ApplyTagsMutationVariables>({
      mutation: applyTags,
      errorPolicy: 'all',
      variables: {
        type,
        items,
        labels,
      },
    })

  const removeTagsRequest = async (type: string, items: number[], labels: number[]) =>
    await client.mutate<RemoveTagsMutation, RemoveTagsMutationVariables>({
      mutation: removeTags,
      errorPolicy: 'all',
      variables: {
        type,
        items,
        labels,
      },
    })

  return {
    getAllTagsRequest,
    getItemTypesUsedRequest,
    createTagRequest,
    deleteTagRequest,
    applyTagsRequest,
    removeTagsRequest,
  }
}
