import { TFunction } from 'i18next'

import { SvgNames } from '@components/Svg'
import { RowAction } from '@components/TableV2/tableV2TS/interfaces'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'

export interface AnalyticsVariable {
  name: string
  description: string
}

export enum AnalyticsVariables {
  INTERNAL_EMAIL_TITLE = '{{internal_email_title}}',
  SUBJECT_LINE = '{{subject_line}}',
  SUBSCRIPTION_CATEGORY = '{{subscription_category}}',
  ACT_ON_CAMPAIGN = '{{act-on_campaign}}',
  ENV_CLICKTHROUGH_LINK_NAME = '{{Env.clickthrough_link_name}}',
}

export const getAnalyticsVariableData = (t: TFunction): AnalyticsVariable[] => [
  { name: AnalyticsVariables.INTERNAL_EMAIL_TITLE, description: t('EmailComposer.Settings.AnalyticsTracking.Variable.InternalEmailTitle') },
  { name: AnalyticsVariables.SUBJECT_LINE, description: t('EmailComposer.Settings.AnalyticsTracking.Variable.SubjectLine') },
  { name: AnalyticsVariables.SUBSCRIPTION_CATEGORY, description: t('EmailComposer.Settings.AnalyticsTracking.Variable.SubscriptionCategory') },
  { name: AnalyticsVariables.ACT_ON_CAMPAIGN, description: t('EmailComposer.Settings.AnalyticsTracking.Variable.ActOnCampaign') },
  { name: AnalyticsVariables.ENV_CLICKTHROUGH_LINK_NAME, description: t('EmailComposer.Settings.AnalyticsTracking.Variable.ClickthroughLinkName') },
]

export const getAnalyticsVariableColumns = (t: TFunction): ColumnDefWithAdditionalProps<AnalyticsVariable, any>[] => [
  {
    header: t('EmailComposer.Settings.AnalyticsTracking.Variable.NameHeader'),
    accessorKey: 'name',
    textAlign: 'left',
    minSize: 224,
  },
  {
    header: t('EmailComposer.Settings.AnalyticsTracking.Variable.DescriptionHeader'),
    accessorKey: 'description',
    textAlign: 'left',
  },
]

export const getAnalyticsVariableRowActions = (t: TFunction): RowAction<AnalyticsVariable>[] => [
  {
    label: t('EmailComposer.Settings.AnalyticsTracking.Variable.CopyText'),
    icon: SvgNames.clipboardCheckColorless,
    onClick: (row) => navigator.clipboard.writeText(row.original.name),
  },
]
