import { gql } from '@apollo/client'

export default gql`
  query getLitmusSpamResults($msgId: String, $testId: String) {
    getLitmusSpamResults(msgId: $msgId, testId: $testId) {
      accountId
      createdAt
      error
      errorMessage
      msgId
      subject
      testDurationMs
      testId
      id
      spamTests {
        status
        appNameLong
        appNameShort
        resultType
        description
        score
        state
        pass
        supportsContentBlocking
        isDesktop
        isBusiness
        foundInSpam
        litmusId
        completed
        reasons {
          reason
          rating
        }
      }
    }
  }
`
