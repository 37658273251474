import React, { FC } from 'react'

import Highcharts, { OptionsTickmarkPlacementValue, ResponsiveOptions, YAxisOptions } from 'highcharts'

import Chart from '@components/Chart/Chart'
import { ChartProps, LegendLocation } from '@const/Chart.constants'

import './SimpleLineChart.css'

export const MAX_SIMPLE_LINE_CHART_FIELD_COUNT = 12

const rootClass = 'simple-line-chart'

//Make legend on line charts square instead of a line
// @ts-ignore
Highcharts.seriesTypes.line.prototype.drawLegendSymbol = Highcharts.seriesTypes.bar.prototype.drawLegendSymbol

export interface SimpleLineChartProps extends ChartProps {
  isDateTime?: boolean
}

const LineChart: FC<SimpleLineChartProps> = (props: SimpleLineChartProps) => {
  const { fields, tickInterval, dataTest = rootClass, className = '', loading, error, chartWidth = 150, chartHeight = 50, horizontalAlign } = props

  if (fields.length > MAX_SIMPLE_LINE_CHART_FIELD_COUNT) {
    throw new Error(`Max number of fields allowed is ${MAX_SIMPLE_LINE_CHART_FIELD_COUNT}, you sent ${fields.length}`)
  }

  const chartColumnData: Array<any> = fields

  const xAxisTickInterval = tickInterval ? { tickInterval } : {}

  const xAxis = {
    ...xAxisTickInterval,
    crosshair: { width: 2, color: '#E3E3E3' },
    tickmarkPlacement: 'on' as OptionsTickmarkPlacementValue,
    tickWidth: 0,
    gridLineColor: '#fff',
    labels: { enabled: false },
    visible: false,
  }

  const yAxis = {
    gridLineColor: '#fff',
    labels: { enabled: false },
    title: { enabled: false },
  } as YAxisOptions

  const tooltip = {
    backgroundColor: 'rgba(247, 247, 247, 0.85)',
    borderRadius: 8,
    borderWidth: 1.5,
    pointFormat: '<b style="color:{point.color}; font-size: larger">&#x25cf; </b><b> {point.y} contacts </b><br>{point.name}',
    headerFormat: '',
    outside: true,
    shared: true,
  }

  const plotOptions = {
    series: {
      lineWidth: 3,
      marker: { enabled: false, symbol: 'circle', radius: 4 },
    },
    spline: { pointPlacement: 'on' },
    dataLabels: { enabled: false },
  }

  const responsive: ResponsiveOptions = {
    rules: [
      {
        condition: {
          minWidth: 60,
          maxWidth: 96,
        },
      },
    ],
  }

  return (
    <div className={`${rootClass}__chart-container`}>
      <div className={`${rootClass}__wrapper`}>
        <Chart
          noContainer
          series={chartColumnData}
          chartType="spline"
          chartMarginTop={0}
          chartWidth={chartWidth}
          chartHeight={chartHeight}
          horizontalAlign={horizontalAlign}
          xAxis={xAxis}
          yAxis={yAxis}
          plotOptions={plotOptions}
          legend={{ enabled: false }}
          legendLocation={LegendLocation.TOP}
          responsive={responsive}
          tooltip={tooltip}
          disableMenu={true}
          dataTest={dataTest}
          className={className}
          loading={loading}
          error={error}
        />
      </div>
    </div>
  )
}

export default LineChart
