import React, { FC, ReactNode, RefObject, useMemo, useRef } from 'react'

import classNames from 'classnames'

import scrollbarWidth from '@components/Table/utils/scrollbarWidth'

import './ListPageScrollArea.css'

interface Props {
  className?: string
  dataTest?: string
  children: (headerRef: RefObject<HTMLDivElement>, menuRef: RefObject<HTMLDivElement>, scrollAreaRef: RefObject<HTMLDivElement>) => ReactNode
}

const rootClass = 'list-page-scroll-area'
const DEFAULT_TOP_DISTANCE = 84

const ListPageScrollArea: FC<Props> = (props: Props) => {
  const { className = '', dataTest = rootClass, children } = props

  const topBarReference = useRef<HTMLDivElement>(null)
  const tableReference = useRef<HTMLDivElement>(null)
  const menuReference = useRef<HTMLDivElement>(null)
  const scrollAreaRef = useRef<HTMLDivElement>(null)

  const topBarRightMargin = useMemo(() => ({ right: `${scrollbarWidth()}px` }), [])

  const stickyDistance = () => {
    if (topBarReference.current) {
      const { top, height } = topBarReference.current.getBoundingClientRect()
      return top + height
    }
    return DEFAULT_TOP_DISTANCE
  }

  const handleScroll = () => {
    if (menuReference.current && topBarReference.current && tableReference.current) {
      const { current: menu } = menuReference
      const { current: topBar } = topBarReference
      const { current: header } = tableReference
      const top = menu.getBoundingClientRect().top

      // Assigning classes with js to avoid using states and expensive re-rendering when scrolling.
      if (top <= stickyDistance()) {
        topBar.classList.add(`${rootClass}__top-bar-visible`)
        header.classList.add(`${rootClass}__thead-shadow`)
      } else {
        topBar.classList.remove(`${rootClass}__top-bar-visible`)
        header.classList.remove(`${rootClass}__thead-shadow`)
      }
    }
  }

  return (
    <div className={classNames(rootClass, className)}>
      <div className={classNames(`${rootClass}__top-bar`)} style={topBarRightMargin} ref={topBarReference} />
      <div
        className={classNames(`${rootClass}__viewport`, { [`${className}__viewport`]: className })}
        data-test={dataTest}
        ref={scrollAreaRef}
        onScroll={handleScroll}
      >
        {children(tableReference, menuReference, scrollAreaRef)}
      </div>
    </div>
  )
}

export default ListPageScrollArea
