import React, { FC, useContext } from 'react'

import classNames from 'classnames'

import CrmPushErrorsModal from '@complex/CrmPushErrorsModal/CrmPushErrorsModal'
import { YesNo } from '@components/ConfirmationModal'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import Loader from '@components/Loader'
import PageContainer from '@components/PageContainer/PageContainer'
import PageHeader from '@components/PageHeader'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import SelectAssetV2 from '@components/SelectAssetV2/SelectAssetV2'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import StatusToast, { Status } from '@components/StatusToast/StatusToast'
import Tab from '@components/TabsAO/TabAO'
import Tabs, { TabsType } from '@components/TabsAO/TabsAO'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { legacyActonContext, rootContext, useTranslation } from '@const/globals'
import CreateEditEmbedCodeModal from '@src/pages/Content/Forms/FormManager/components/CreateEditEmbedCodeModal/CreateEditEmbedCodeModal'
import EmbedCodeModal from '@src/pages/Content/Forms/FormManager/components/EmbedCodeModal/EmbedCodeModal'
import FormManagerDetails from '@src/pages/Content/Forms/FormManager/components/FormManagerDetails/FormManagerDetails'
import FormManagerHeader from '@src/pages/Content/Forms/FormManager/components/FormManagerHeader/FormManagerHeader'
import { FormManagerContext } from '@src/pages/Content/Forms/FormManager/context/FormManager.context'
import { FormManagerTabs, getFormManagerTabStructure } from '@src/pages/Content/Forms/FormManager/FormManager.constants'

import { DownloadXLSOptions, getModalRowItems } from './Utils/FormManager.utils'

import './FormManager.css'

interface FormManagerProps {
  className?: string
  dataTest?: string
}

const rootClass = 'form-manager'

const FormManager: FC<FormManagerProps> = (props: FormManagerProps) => {
  const { dataTest = rootClass, className = '' } = props
  const {
    tabChange,
    toggleModal,
    clearPerformanceReport,
    deleteEmbedCode,
    createEditEmbedCode,
    downloadSubmittedData,
    values: { loading, formNotFound, tab, formId, submissionDetails, modals, row, reportIFrameRef },
  } = useContext(FormManagerContext)
  const { t } = useTranslation()

  if (loading) {
    return (
      <PageContainer>
        <Loader center />
      </PageContainer>
    )
  }

  const tabStructure = getFormManagerTabStructure(t)

  const clearPerformanceBody = (
    <>
      <Typography text={t('Forms.ClearPerformance.Body.1')} type={TextType.BODY_TEXT_LIGHT} className={`${rootClass}__clear-first`} />
      <Typography text={t('Forms.ClearPerformance.Body.2')} type={TextType.BODY_TEXT_LIGHT} />
    </>
  )

  const renderClearPerformance = () => (
    <DeleteConfirmationModal
      isOpen
      title={t('Clear form performance report')}
      deleteButtonText={t('Clear past data')}
      body={clearPerformanceBody}
      onAnswer={(answer) => {
        if (answer === YesNo.YES) {
          clearPerformanceReport()
        } else {
          toggleModal('clearPerformanceReport')
        }
      }}
    />
  )

  const deleteEmbedBody = (
    <Typography
      text={t('FormManager.Modals.DeleteEmbedCode.Body', { name: row?.name })}
      tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
      type={TextType.BODY_TEXT_LIGHT}
    />
  )

  const renderDeleteEmbedCode = () => (
    <DeleteConfirmationModal
      isOpen
      title={t('Delete Embed Code')}
      deleteButtonText={t('Delete')}
      body={deleteEmbedBody}
      onAnswer={(answer) => {
        if (answer === YesNo.YES && row) {
          deleteEmbedCode(row.id)
        }
        toggleModal('deleteEmbedCode')
      }}
    />
  )

  const renderEmbedCode = () => <EmbedCodeModal onClose={() => toggleModal('embedCode')} isOpen />

  const renderCrmPushErrors = () => (
    <CrmPushErrorsModal isOpen onClose={() => toggleModal('crmPushErrors')} origin={formId} listId={submissionDetails.id} />
  )

  const renderCreateEditEmbedCode = (isCreate: boolean) => (
    <CreateEditEmbedCodeModal
      isOpen
      isCreate={isCreate}
      onClose={() => toggleModal(isCreate ? 'createEmbedCode' : 'editEmbedCode')}
      onAction={(name: string, externalUrl: string, embedCodeId?: string) => createEditEmbedCode(name, externalUrl, embedCodeId)}
    />
  )

  const renderEditEmbedCodeToast = (success: boolean) => {
    const msg = success ? t('Forms.EditEmbedCode.Success') : t('Forms.EditEmbedCode.Error')
    const closeStatus = () => toggleModal(success ? 'editEmbedCodeSuccess' : 'editEmbedCodeError')
    const status = success ? Status.SUCCESS : Status.FAIL
    return <StatusToast message={msg} status={status} closeStatus={closeStatus} />
  }

  const renderCreateEmbedCodeToast = (success: boolean) => {
    const msg = success ? t('Forms.CreateEmbedCode.Success') : t('Forms.CreateEmbedCode.Error')
    const closeStatus = () => toggleModal(success ? 'createEmbedCodeSuccess' : 'createEmbedCodeError')
    const status = success ? Status.SUCCESS : Status.FAIL
    return <StatusToast message={msg} status={status} closeStatus={closeStatus} />
  }

  const renderDeleteEmbedCodeToast = (success: boolean) => {
    const msg = success ? t('Forms.DeleteEmbedCode.Success') : t('Forms.DeleteEmbedCode.Error')
    const closeStatus = () => toggleModal(success ? 'deleteEmbedCodeSuccess' : 'deleteEmbedCodeError')
    const status = success ? Status.SUCCESS : Status.FAIL
    return <StatusToast message={msg} status={status} closeStatus={closeStatus} />
  }

  const renderClearPerformanceToast = (success: boolean) => {
    const msg = success ? t('Forms.ClearPerformance.Success') : t('Forms.ClearPerformance.Error')
    const closeStatus = () => toggleModal(success ? 'clearPerformanceReportSuccess' : 'clearPerformanceReportError')
    const status = success ? Status.SUCCESS : Status.FAIL
    return <StatusToast message={msg} status={status} closeStatus={closeStatus} />
  }

  const renderPushErrsErr = () => (
    <StatusToast
      message={t('There was an error retrieving the CRM Push Errors data.')}
      status={Status.FAIL}
      closeStatus={() => toggleModal('pushErrorsErr')}
    />
  )

  const renderDownloadOptionsModal = () => (
    <SelectAssetV2
      primaryButtonText={t('Download')}
      isOpen
      className={`${rootClass}__download-modal`}
      headerText={t('Download')}
      subHeaderText={t('ListPage.FormsJoinView.DownloadModal.ExtraContent')}
      onAction={(row) => {
        const isZipDownload = row === DownloadXLSOptions.DOWNLOAD_AS_XLS_WITH_UPLOADED
        downloadSubmittedData(isZipDownload)
        toggleModal('downloadOptions')
      }}
      rowItems={getModalRowItems(t)}
      onCancel={() => toggleModal('downloadOptions')}
      initialSelectedAsset={DownloadXLSOptions.DOWNLOAD_AS_XLS}
    />
  )

  return (
    <>
      {modals.clearPerformanceReport && renderClearPerformance()}
      {modals.clearPerformanceReportSuccess && renderClearPerformanceToast(true)}
      {modals.clearPerformanceReportError && renderClearPerformanceToast(false)}
      {modals.deleteEmbedCodeSuccess && renderDeleteEmbedCodeToast(true)}
      {modals.deleteEmbedCodeError && renderDeleteEmbedCodeToast(false)}
      {modals.editEmbedCodeSuccess && renderEditEmbedCodeToast(true)}
      {modals.editEmbedCodeError && renderEditEmbedCodeToast(false)}
      {modals.createEmbedCodeSuccess && renderCreateEmbedCodeToast(true)}
      {modals.createEmbedCodeError && renderCreateEmbedCodeToast(false)}
      {modals.deleteEmbedCode && renderDeleteEmbedCode()}
      {modals.embedCode && renderEmbedCode()}
      {modals.crmPushErrors && renderCrmPushErrors()}
      {modals.editEmbedCode && renderCreateEditEmbedCode(false)}
      {modals.createEmbedCode && renderCreateEditEmbedCode(true)}
      {modals.pushErrorsErr && renderPushErrsErr()}
      {modals.downloadOptions && renderDownloadOptionsModal()}
      <PageContainer noPadding noPrintClass className={classNames(rootClass, className)} dataTest={dataTest}>
        {formNotFound && (
          <PositionContainer>
            <EmptyListing
              headline={t(`We couldn't find that form ID`)}
              text={t(`Sorry, there's nothing to see here`)}
              imgSrc={StaticImageNames.errorNothingFound}
              buttonText={t('View forms')}
              buttonURL={`${rootContext}/content/forms`}
              size={EmptyListingSize.LARGE}
            />
          </PositionContainer>
        )}
        {!formNotFound && (
          <>
            <FormManagerHeader />
            <Tabs
              defaultValue={tab}
              tabsType={TabsType.UNIFIED}
              onChange={tabChange}
              childData={tabStructure}
              className={`${rootClass}__header-tabs`}
            >
              <Tab index={FormManagerTabs.DETAILS}>
                <PositionContainer>
                  <FormManagerDetails />
                </PositionContainer>
              </Tab>
              <Tab index={FormManagerTabs.PERFORMANCE}>
                <PositionContainer>
                  <PageHeader primaryText={t('Form Performance')} />
                  <iframe
                    title={'form source report'}
                    src={`${legacyActonContext}/forms/formSourceReport2.jsp?formid=${formId}&hideTitle=true`}
                    className={`${rootClass}__iframe`}
                    ref={reportIFrameRef}
                  />
                </PositionContainer>
              </Tab>
              <Tab index={FormManagerTabs.RESPONSES}>
                <PositionContainer>
                  <PageHeader primaryText={t('Form Responses')} />
                  <iframe
                    title={'form survey report'}
                    src={`${legacyActonContext}/forms/surveyReport.jsp?id=${formId}&closebutton=0&hideTitle=true`}
                    className={`${rootClass}__iframe`}
                  />
                </PositionContainer>
              </Tab>
            </Tabs>
          </>
        )}
      </PageContainer>
    </>
  )
}

export default FormManager
