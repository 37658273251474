const NAMESPACE = 'editProgram'

export default {
  loadProgram: `${NAMESPACE}/LOAD_PROGRAM`,
  loadProgramComplete: `${NAMESPACE}/LOAD_PROGRAM_COMPLETE`,
  loadProgramError: `${NAMESPACE}/LOAD_PROGRAM_ERROR`,
  updateProgram: `${NAMESPACE}/UPDATE_PROGRAM`,
  saveProgram: `${NAMESPACE}/SAVE_PROGRAM`,
  saveProgramComplete: `${NAMESPACE}/SAVE_PROGRAM_COMPLETE`,
  saveProgramError: `${NAMESPACE}/SAVE_PROGRAM_ERROR`,
  clearProgramStatus: `${NAMESPACE}/CLEAR_PROGRAM_STATUS`,
  saveProgramFinal: `${NAMESPACE}/SAVE_PROGRAM_FINAL`,
  saveProgramFinalComplete: `${NAMESPACE}/SAVE_PROGRAM_FINAL_COMPLETE`,
  saveProgramFinalError: `${NAMESPACE}/SAVE_PROGRAM_FINAL_ERROR`,
  clearFinalSaveStatus: `${NAMESPACE}/CLEAR_FINAL_SAVE_STATUS`,
  createProgramMessage: `${NAMESPACE}/CREATE_PROGRAM_MESSAGE`,
  createProgramMessageRequest: `${NAMESPACE}/CREATE_PROGRAM_MESSAGE_REQUEST`,
  createProgramMessageComplete: `${NAMESPACE}/CREATE_PROGRAM_MESSAGE_COMPLETE`,
  createProgramMessageError: `${NAMESPACE}/CREATE_PROGRAM_MESSAGE_ERROR`,
  clearProgramMessage: `${NAMESPACE}/CLEAR_PROGRAM_MESSAGE`,
  setHasAOContactsSource: `${NAMESPACE}/HAS_AOCONTACTS_SOURCE`,
}
