import React from 'react'

import { t, TFunction } from 'i18next'

import {
  PersonalizationItem,
  PersonalizationTableColumnsType,
  PersonalizationTableProps,
  SetupToaster,
} from '@complex/Personalization/utils/Personalization.context'
import { EmptyListingProps, EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import HoverSVG from '@components/HoverSVG/HoverSVG'
import InfoTooltip, { InfoTooltipIconSize } from '@components/InfoTooltip/InfoTooltip'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { SvgNames, SvgType } from '@components/Svg'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import {
  GetListContactsQuery,
  GetListsFieldsQuery,
  GetPersonalizationChoicesQuery,
  ListInfo,
  SearchListsByIdsQuery,
} from '@graphql/types/query-types'
import { rootContext } from '@utils/const/globals'
import { FilterDefinition, FilterTypes } from '@utils/filter'
import { titleCase, toTrainCase } from '@utils/strings'
import { UpdateState } from '@utils/types'

import { PersonalizationModals } from '../Personalization.constants'
import { PersonalizationContainerState } from '../PersonalizationContainer'

export interface CollectedFields {
  listSegmentName?: string
  fields?: string[]
}

interface ProcessPersonalizationFields {
  fields?: GetPersonalizationChoicesQuery
  isAutomatedPrograms?: boolean
  selectedListFields?: GetListsFieldsQuery | null
  specificListContactsData?: GetListContactsQuery | null
  searchByIdsField?: SearchListsByIdsQuery | null | undefined
  hideAccountPersonalizations?: boolean
}

export const getRecipientSrcIds = <SendToList extends { srcId?: string }>(
  individualRecipientsSourceIds?: (string | undefined)[],
  sendto_lists?: SendToList[]
): string[] | undefined => {
  if (individualRecipientsSourceIds?.length || sendto_lists?.length) {
    const lists = sendto_lists ?? []
    const individualSourceIds = individualRecipientsSourceIds?.map((id) => id?.split('::')[1] ?? id?.split(':')[0]) || []
    return [...new Set([...[...lists].map(({ srcId }) => srcId ?? ''), ...individualSourceIds])].filter((srcId) => !!srcId) as string[]
  }
}

export const processPersonalizationFields = ({
  fields,
  selectedListFields,
  specificListContactsData,
  searchByIdsField,
  hideAccountPersonalizations = false,
  isAutomatedPrograms = false,
}: ProcessPersonalizationFields) => {
  const contactFields =
    fields?.getPersonalizationChoices.accountVCMaps?.map((field, idx): PersonalizationItem => {
      return {
        id: `${toTrainCase(FilterTypes.CONTACT_FIELDS)}-${idx}`,
        title: field.name,
        group: FilterTypes.CONTACT_FIELDS,
      }
    }) ?? []

  const accountFields = hideAccountPersonalizations
    ? []
    : fields?.getPersonalizationChoices.vcFields?.map(
        (field, idx): PersonalizationItem => ({
          id: `${toTrainCase(field.groupId)}-${idx}`,
          title: field.label ? field.label.replace(/&#39;/g, "'") : field.value.replace(/&#39;/g, "'"),
          category: field.groupId as FilterTypes,
          group: field.groupId === 'Custom Account Fields' ? FilterTypes.CUSTOM_ACCOUNT_FIELDS : FilterTypes.SENDER_FIELDS,
          mapping: field.value,
        })
      ) ?? []

  const crmFields =
    fields?.getPersonalizationChoices.crmFields
      ?.filter((field) => field.value !== '' && field.label !== '')
      .map(
        (field, idx): PersonalizationItem => ({
          id: `${toTrainCase(FilterTypes.CRM_FIELDS)}-${idx}`,
          title: field.label,
          group: FilterTypes.CRM_FIELDS,
          mapping: field.value,
        })
      ) ?? []

  const collectedFields: CollectedFields[] = []
  const arrayOfFields: string[][] = []
  const uniqueFieldNames: string[] = []

  selectedListFields?.getListsFields?.forEach((field) => {
    field?.fields && arrayOfFields.push(field?.fields)
  })
  selectedListFields?.getListsFields?.forEach((field) => {
    selectedListFields?.getListsFields && collectedFields.push({ listSegmentName: field?.listName, fields: field?.fields })

    arrayOfFields.forEach((element) => {
      element.forEach((fieldName) => {
        if (!uniqueFieldNames.includes(fieldName)) {
          uniqueFieldNames.push(fieldName)
        }
      })
    })
  })

  const AllActOnContactNamesWithChildren =
    (searchByIdsField?.searchListsByIds?.unifiedLists?.[0]?.entries.length &&
      extractAllNamesFromSegment(searchByIdsField?.searchListsByIds?.unifiedLists?.[0]?.entries as ListInfo[])) ||
    []
  const oneSegmentSelected = collectedFields.length === 1
  const listFields: PersonalizationItem[] = uniqueFieldNames.map((field: string, idx) => {
    const isInAllListSegments = collectedFields.map(({ fields }) => fields).every((fields) => fields?.includes(field))
    const segmentsThatHasField = collectedFields
      .filter(({ fields }) => fields && fields.includes(field))
      .map(({ listSegmentName }) => listSegmentName)

    const someFieldsInActOnContacts = segmentsThatHasField.some(
      (segment) => segment && AllActOnContactNamesWithChildren?.some((name) => name == segment)
    )
    const withoutActonContacts = segmentsThatHasField.filter((segment) => segment && !AllActOnContactNamesWithChildren?.includes(segment)).join(', ')

    let listSegmentName
    switch (true) {
      case isInAllListSegments && !withoutActonContacts.length:
        listSegmentName = 'Act-On Contacts'
        break
      case isInAllListSegments:
        listSegmentName = 'All'
        break
      case someFieldsInActOnContacts && !!withoutActonContacts.length:
        listSegmentName = 'Act-On Contacts, ' + withoutActonContacts
        break
      case someFieldsInActOnContacts && !isInAllListSegments:
        listSegmentName = 'Act-On Contacts'
        break
      default:
        listSegmentName = withoutActonContacts
    }
    return {
      id: `${toTrainCase(field)}-${idx}`,
      title: field,
      listSegmentName: oneSegmentSelected ? collectedFields[0].listSegmentName ?? '' : listSegmentName,
      hasFieldWarningIcon: !oneSegmentSelected && !isInAllListSegments,
      group: isAutomatedPrograms ? FilterTypes.SOURCE_LIST_FIELDS : FilterTypes.RECIPIENT_LIST_FIELDS,
    }
  })

  const specificListContacts =
    specificListContactsData?.getListContacts?.map((field) => ({
      id: field?.id ?? '',
      title: field?.name || '',
      email: field?.email ?? '',
      group: FilterTypes.RECIPIENT_SPECIFIC_LIST_CONTACTS,
    })) ?? []

  return {
    [FilterTypes.CRM_FIELDS]: crmFields,
    [FilterTypes.RECIPIENT_LIST_FIELDS]: listFields,
    [FilterTypes.CUSTOM_ACCOUNT_FIELDS]: accountFields,
    [FilterTypes.RECIPIENT_SPECIFIC_LIST_CONTACTS]: specificListContacts,
    [FilterTypes.CONTACT_FIELDS]: contactFields,
  }
}

function extractAllNamesFromSegment(data: ListInfo[]) {
  const result: string[][] = []

  function extractNames(obj: ListInfo, parentNames: string[] = []) {
    const currentName: string = obj.name
    const allNames = [...parentNames, currentName]
    result.push(allNames)

    if (obj.hasOwnProperty('children') && Array.isArray(obj.children)) {
      obj.children.forEach((child: ListInfo) => {
        extractNames(child, allNames)
      })
    }
  }

  data.forEach((datum) => extractNames(datum))

  return Array.from(new Set(result.flat()))
}

export const getPersonalizationEmptyListingProps = (
  searchText: string,
  activeFilter: string,
  t: TFunction,
  update: UpdateState<PersonalizationContainerState>,
  programId?: string,
  crm?: FilterDefinition
): EmptyListingProps | undefined => {
  switch (true) {
    case !!searchText:
      return {
        imgSrc: StaticImageNames.emptySearch,
        headline: t('No results found'),
        text: t('There were no results matching your search.'),
        size: EmptyListingSize.LARGE,
        withoutBorder: true,
        fullHeight: true,
      }

    case activeFilter === FilterTypes.SOURCE_LIST_FIELDS:
      return {
        imgSrc: StaticImageNames.emptyContacts,
        headline: t('Personalization.sourceListField.headLine'),
        text: t('Personalization.sourceListField.description'),
        size: EmptyListingSize.MEDIUM,
        withoutBorder: true,
        fullHeight: true,
        ...(programId
          ? {
              buttonText: t('Add a source list or segment'),
              buttonOnClick: () => window.open(`${rootContext}/automation/programs/${programId}/settings`),
              buttonPlusIcon: true,
            }
          : {}),
      }

    case activeFilter === FilterTypes.RECIPIENT_LIST_FIELDS:
      return {
        imgSrc: StaticImageNames.envelope,
        headline: t('Personalization.recipientField.headLine'),
        text: t('Personalization.recipientField.description'),
        size: EmptyListingSize.MEDIUM,
        withoutBorder: true,
        fullHeight: true,
        buttonText: t('Add recipients to email'),
        buttonOnClick: () => update({ activeModal: PersonalizationModals.ADD_RECIPIENTS }),
        buttonPlusIcon: true,
      }

    case activeFilter === FilterTypes.CUSTOM_ACCOUNT_FIELDS:
      return {
        imgSrc: StaticImageNames.emptySearch,
        headline: t('No custom account fields'),
        text: t('PersonalizationModal.CustomAccountFields'),
        size: EmptyListingSize.LARGE,
        withoutBorder: true,
        fullHeight: true,
      }

    case crm?.name == activeFilter:
      return {
        imgSrc: StaticImageNames.warning,
        headline: t(`PersonalizationModal.CRM.name`, { crm: crm?.name }),
        text: t('PersonalizationModal.CRM.description'),
        tagProps: { inline: true },
        size: EmptyListingSize.MEDIUM,
        withoutBorder: true,
        buttonText: t('Go to Sync Schedule'),

        buttonOnClick: () => window.open(`${rootContext}/datamanagement/sync-schedule`, `_blank`),
        hideIcon: false,
        link: 'https://connect.act-on.com/hc/en-us/articles/360033436074-How-to-Personalize-Email-Content-with-CRM-Data#Error_message:_Please_visit_CRM_Administration_in_Settings_and_set_up_a_recurring_sync_to_enable_Field_Name_Personalization',
        linkText: 'More info',
        fullHeight: true,
      }

    default:
      return
  }
}

export const CellWithWarning = ({ t, value, rootClass }: { t: Function; value: string; rootClass: string }) => {
  return (
    <div className={`${rootClass}__table-cell-warning`}>
      <Tooltip
        align="center"
        position="top"
        trigger={
          <HoverSVG
            style={{ width: 'var(--spacing-unit-x2)' }}
            svg={SvgNames.warningSolid}
            hoverSvg={SvgNames.warningSolid}
            type={SvgType.LARGER_ICON}
          />
        }
      >
        <Typography
          text={t('PersonalizationModal.field.warning')}
          tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
          type={TextType.BODY_TEXT_WHITE}
          weight={TextWeight.REGULAR}
          lineHeight={LineHeight.MEDIUM}
        />
      </Tooltip>

      <TextWithTooltipOnEllip typographyProps={{ children: value }} tooltipProps={{ children: value }} />
    </div>
  )
}

export const setupToast = ({ showStatus = true, successStatus, typographyProps, setToastStatus }: SetupToaster) => {
  setToastStatus({
    showStatus,
    successStatus,
    statusMessage: <Typography type={TextType.BODY_TEXT_GRAY} tagProps={{ bold: { weight: TextWeight.BOLD } }} inline {...typographyProps} />,
  })
}

const tableColumns: PersonalizationTableColumnsType = {
  senderColumn: {
    header: 'Category',
    accessorKey: 'category',
    textAlign: 'left',
    sortingFn: 'caseInsensitive',
    cell: (cell) => (cell.row.original.group == FilterTypes.SENDER_FIELDS ? titleCase(cell.getValue()) : '-'),
  },
  [FilterTypes.RECIPIENT_LIST_FIELDS]: {
    header: 'List/Segment Name',
    accessorKey: 'listSegmentName',
    textAlign: 'left',
    sortingFn: 'caseInsensitive',
    cell: (cell) => {
      const value = cell.getValue()
      return <TextWithTooltipOnEllip typographyProps={{ children: value }} tooltipProps={{ children: value }} />
    },
  },
}

const getAccountDateContent = (value: string, isOffset: boolean) => (
  <div style={{ display: 'flex', gap: 'var(--spacing-unit)', alignItems: 'center' }}>
    <Typography text={value} type={TextType.BODY_TEXT} />
    <InfoTooltip iconSize={InfoTooltipIconSize.SMALL}>
      {t(isOffset ? 'PersonalizationModal.AccountDateWithOffset.Tooltip' : 'PersonalizationModal.AccountDate.Tooltip')}
    </InfoTooltip>
  </div>
)

export const personalizationTableColumns = ({
  activeFilter,
  allData,
  isChoosingMultipleContacts,
  isRecipientListFieldsSelected,
  rootClass,
  searchText,
}: PersonalizationTableProps): ColumnDefWithAdditionalProps<PersonalizationItem, any>[] => {
  if (isChoosingMultipleContacts) {
    return [
      {
        header: 'Contact name',
        accessorKey: 'title',
        maxSize: 300,
        textAlign: 'left',
        sortingFn: 'caseInsensitive',
        cell: (cell) => {
          const value = cell.getValue()
          const typographyProps = (value as string).includes('Account Date')
            ? { children: getAccountDateContent(value, (value as string).includes('Offset')) }
            : { children: value }
          return <TextWithTooltipOnEllip typographyProps={typographyProps} tooltipProps={{ children: value }} />
        },
      },
      {
        header: 'Email',
        accessorKey: 'email',
        textAlign: 'left',
        sortingFn: 'caseInsensitive',
        cell: (cell) => {
          const value = cell.getValue()
          return <TextWithTooltipOnEllip typographyProps={{ children: value }} tooltipProps={{ children: value }} />
        },
      },
    ]
  }

  if (searchText) {
    const senderCol: ColumnDefWithAdditionalProps<PersonalizationItem, string>[] = allData.some((fld) => fld.group === FilterTypes.SENDER_FIELDS)
      ? [tableColumns['senderColumn']]
      : []
    return [
      {
        header: 'Field Name',
        accessorKey: 'title',
        textAlign: 'left',
        sortingFn: 'caseInsensitive',
        cell: (cell) => {
          const value = cell.getValue()
          const typographyProps = (value as string).includes('Account Date')
            ? { children: getAccountDateContent(value, (value as string).includes('Offset')) }
            : { children: value }
          return cell.row.original.hasFieldWarningIcon ? (
            <CellWithWarning rootClass={rootClass} t={t} value={value} />
          ) : (
            <TextWithTooltipOnEllip typographyProps={typographyProps} tooltipProps={{ children: value }} />
          )
        },
      },
      {
        header: 'Type',
        accessorKey: 'group',
        textAlign: 'left',
        sortingFn: 'caseInsensitive',
        maxSize: senderCol.length ? 200 : undefined,
        cell: (cell) => cell.getValue().substring(0, cell.getValue().lastIndexOf('s')),
      },
      ...senderCol,
    ]
  } else {
    const isSender = activeFilter === FilterTypes.SENDER_FIELDS
    const senderCol = isSender ? [tableColumns['senderColumn']] : []
    const recipientCol = isRecipientListFieldsSelected ? [tableColumns[FilterTypes.RECIPIENT_LIST_FIELDS]] : []

    return [
      {
        header: 'Field Name',
        accessorKey: 'title',
        textAlign: 'left',
        cell: (cell) => {
          const value = cell.getValue()
          const typographyProps = (value as string).includes('Account Date')
            ? { children: getAccountDateContent(value, (value as string).includes('Offset')) }
            : { children: value }
          return cell.row.original.hasFieldWarningIcon ? (
            <CellWithWarning rootClass={rootClass} t={t} value={value} />
          ) : (
            <TextWithTooltipOnEllip typographyProps={typographyProps} tooltipProps={{ children: value }} />
          )
        },
      },
      ...senderCol,
      ...recipientCol,
    ]
  }
}
