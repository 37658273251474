import { gql } from '@apollo/client'

export default gql`
  query getIgnoreIps {
    getIgnoreIps {
      ips
      maxNumberEntries
    }
  }
`
