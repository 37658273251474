import { MediaItem } from '@components/AssetPickers/MediaPickerModal/MediaPickerModal.context'
import { SvgNames } from '@components/Svg'
import { AssetLinkTypes } from '@utils/composer/EmailModal.constants'
import { MediaFileFlow } from '@utils/media'

import { AddAssetFlow } from '../SelectAssetModal/SelectAssetModal'

export const mediaFileAssetFlows: AddAssetFlow[] = [
  {
    icon: SvgNames.fileUpload,
    text: 'Upload media file',
    type: AssetLinkTypes.FILE,
    actionName: MediaFileFlow.UPLOAD,
  },
  {
    icon: SvgNames.url,
    text: 'Add media link',
    type: AssetLinkTypes.FILE,
    actionName: MediaFileFlow.LINK,
  },
]

export const SESSION_MEDIA_SELECTED = 'EmailComposer:MediaFile:Selected'

export const getSessionMediaSelected = () => {
  const sessionValue = sessionStorage.getItem(SESSION_MEDIA_SELECTED) ?? '{}'
  try {
    const parsed = JSON.parse(sessionValue) as unknown
    if (parsed instanceof Object && 'id' in parsed) {
      return parsed as MediaItem
    }
  } catch {
    return undefined
  }
}
