import SvgNames from '@components/Svg/SvgNames'
import { NavigationInterface } from '@utils/navigation/navigation.utils'

const navigation: NavigationInterface[] = [
  {
    id: 'home',
    icon: SvgNames.home,
    iconSelected: SvgNames.homeSelected,
    label: 'Home',
    items: [
      {
        id: 'home-website-prospector',
        label: 'Website Prospector',
        url: 'classic/if/_wvt/index.jsp',
        settings: ['!salesPortalMyMessagesOnly'],
      },
      {
        id: 'home-sent-messages',
        label: 'Sent Messages',
        url: 'classic/if/messages/listingSent.jsp?type=SENT&sforce=1',
      },
      {
        id: 'home-scheduled-messages',
        label: 'Scheduled Messages',
        url: 'classic/if/messages/listingScheduled.jsp?mineOnly=1',
      },
      {
        id: 'home-activity',
        label: 'Activity',
        url: 'classic/if/home/actonToday.jsp',
        settings: ['!hideMyAction', '!salesPortalMyMessagesOnly'],
      },
      {
        id: 'home-accounts',
        label: 'Accounts',
        url: 'classic/entity/filters?model=abm&namespace=abm&type=Account',
        settings: ['accountBasedMarketing', 'crmServiceEnabled', 'accountBasedMarketingForSales'],
      },
    ],
  },
  {
    id: 'settings',
    icon: SvgNames.settingsThreeLines,
    iconSelected: SvgNames.settingsThreeLinesSelected,
    label: 'Settings',
    items: [
      {
        id: 'settings-profile',
        label: 'Profile',
        url: 'profile',
      },
      {
        id: 'settings-signature',
        label: 'Signature',
        url: 'classic/if/account/signature.jsp',
      },
      {
        id: 'settings-website-prospector-alerts',
        label: 'Website Prospector Alerts',
        url: 'classic/if/beacon/alerts.jsp',
        settings: ['!salesPortalMyMessagesOnly', '!limitSalesUsersToOwnedContacts'],
      },
      {
        id: 'settings-website-prospector-report',
        label: 'Website Prospector Report',
        url: 'classic/if/beacon/beaconUserSettings.jsp?all=0',
        settings: ['!salesPortalMyMessagesOnly', '!limitSalesUsersToOwnedContacts'],
      },
    ],
  },
  {
    id: 'resources',
    icon: SvgNames.resources,
    iconSelected: SvgNames.resourcesSelected,
    label: 'Resources',
    settings: ['!salesPortalMyMessagesOnly'],
    items: [
      {
        id: 'resources-contact-us',
        label: 'Contact Us',
        openWindow: {
          url: 'https://www.act-on.com/contact-us',
          amc: true,
        },
      },
      {
        id: 'resources-outlook-plugin',
        label: 'Outlook Plugin',
        url: 'classic/if/outlook/outlookInstallPage.jsp',
      },
      {
        id: 'resources-aluv',
        label: 'Act-On Advocacy (ALUV)',
        openWindow: {
          url: 'https://acton.influitive.com/users/sign_in',
          amc: true,
        },
        settings: ['isALUV'],
      },
    ],
  },
]

export default navigation
