import React, { FC, useState } from 'react'

import Button, { ButtonType } from '@components/Button'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal/ConfirmationModal'
import Loader from '@components/Loader'
import { ModalHeader } from '@components/Modal'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Typography, { ModalBodyStyle, ModalHeaderStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { SMSContextAPI } from '@src/pages/sms/context/SMSContext'
import ScheduledSendModal from '@src/pages/sms/edit/components/ScheduledSendModal/ScheduledSendModal'
import { AllowedSendWindowProcessed } from '@src/pages/sms/edit/utils/EditSMSUtils'
import { OPT_IN_PERSONALIZATION } from '@src/pages/sms/utils/sms.utils'

import { MAX_MESSAGE_LENGTH } from '../../EditSMSContainer'

import './Sendability.css'

const rootClass = 'sendability'

interface Props {
  context: SMSContextAPI
  recipients: number
  sufficientBalance: boolean
  deliveryTime: number
  allowedSendWindow: AllowedSendWindowProcessed
  canSchedule: boolean
  canSend: boolean
  confirmSendClicked: (answer: YesNo) => void
  sendConfirmationOpen: boolean
  openSendConfirmation: () => void
  sendingLoader: boolean
  isInitialMessage: boolean
}

interface State {
  isScheduleModalOpen: boolean
}

const defaultState: State = {
  isScheduleModalOpen: false,
}

const Sendability: FC<Props> = ({
  context,
  recipients,
  sufficientBalance,
  deliveryTime,
  allowedSendWindow,
  canSchedule,
  canSend,
  sendConfirmationOpen,
  openSendConfirmation,
  confirmSendClicked,
  sendingLoader,
  isInitialMessage,
}: Props) => {
  const {
    values: { smsMessage, errors, sentInitialMessage },
  } = context
  const [state, setState] = useState<State>(defaultState)
  const { t } = useTranslation()

  const getSpecificNotSendable = (sendable: boolean, text: string, dataTest: string) => {
    const iconName = sendable ? SvgNames.pass : SvgNames.fail
    return (
      <div className={`${rootClass}__specific-error`} data-test={`${rootClass}-${dataTest}-${iconName}`}>
        <Svg name={iconName} type={SvgType.ICON} />
        <Typography text={text} inline type={TextType.BODY_TEXT_SMALL} />
      </div>
    )
  }

  const getNotSendable = () => {
    const hasRecipients = recipients > 0
    const messagelength = smsMessage.messageText.length > 0 && smsMessage.messageText.length <= MAX_MESSAGE_LENGTH && !errors.incompatibleCharacter
    const sufficientCredits = sufficientBalance
    const title = smsMessage.title.length > 0
    const optInDate = isInitialMessage ? messagelength && smsMessage.messageText.includes(OPT_IN_PERSONALIZATION) : true
    const hasInitialMessageBeenSent = isInitialMessage ? true : sentInitialMessage ?? false

    return (
      <div className={`${rootClass}__not-sendable`}>
        {!isInitialMessage && getSpecificNotSendable(title, t('Message title'), 'result-title')}
        {!isInitialMessage && getSpecificNotSendable(hasRecipients, t('Recipients added'), 'result-recipients')}
        {getSpecificNotSendable(messagelength, t('Message text'), 'result-messageLength')}
        {!sufficientCredits && getSpecificNotSendable(false, t('Sufficient Credits'), 'result-sufficientCredits')}
        {isInitialMessage && getSpecificNotSendable(optInDate, t('Opt-in date'), 'result-optinDate')}
        {!hasInitialMessageBeenSent && getSpecificNotSendable(hasInitialMessageBeenSent, t('Initial Message not sent'), 'result-initialMessage')}
      </div>
    )
  }

  const getSendable = () => (
    <div className={`${rootClass}__sendable`}>
      <Svg name={SvgNames.pass} type={SvgType.ICON} />
      <Typography text={t('This message is ready to send')} inline type={TextType.BODY_TEXT_SMALL} />
    </div>
  )

  const getSchedulable = () => (
    <div className={`${rootClass}__sendable`}>
      <Svg name={SvgNames.pass} type={SvgType.ICON} />
      <Typography text={t('This message can be scheduled for sending')} inline type={TextType.BODY_TEXT_SMALL} />
    </div>
  )

  const getConfirmationBody = () => {
    if (sendingLoader) {
      return <Loader center />
    }

    const deliveryTimeText = deliveryTime < 2 ? `${t('a minute')}` : `${deliveryTime} ${t('minutes')}`
    const windowTime = `${allowedSendWindow.startText} - ${allowedSendWindow.endText}`

    const estimatedTime = (
      <Typography
        text={t('Sendability.Confirmation.Body', { deliveryTimeText, windowTime })}
        {...ModalBodyStyle}
        tagProps={{ medium: { weight: TextWeight.MEDIUM, inline: true } }}
        inline
      />
    )

    const confirmSend = <Typography text={t('Are you sure you want to schedule this time?')} {...ModalBodyStyle} inline />

    const zeroOutside = (
      <>
        {estimatedTime} {confirmSend}
      </>
    )

    const numberOutside = (
      <Typography
        text={t('Sendability.Confirm.NumberOutside', { count: allowedSendWindow.recipientsOutsideAllowedWindow, windowTime })}
        {...ModalBodyStyle}
        tagProps={{ medium: { weight: TextWeight.MEDIUM, inline: true } }}
        inline
      />
    )

    const numberOutsideOverridesOn = (
      <>
        {numberOutside}. {estimatedTime} {confirmSend}
      </>
    )

    const numberOutsideOverridesOff = (
      <>
        {numberOutside},{' '}
        <Typography
          text={t('this message will be sent to them once they are within the allowed sending window')}
          type={TextType.BODY_TEXT_LIGHT}
          inline
        />
        . {estimatedTime}
      </>
    )

    return allowedSendWindow.recipientsOutsideAllowedWindow === 0
      ? zeroOutside
      : allowedSendWindow.overrideAllowed
      ? numberOutsideOverridesOn
      : numberOutsideOverridesOff
  }

  const onScheduleClicked = () => {
    setState({ ...state, isScheduleModalOpen: true })
  }

  const closeScheduleModal = () => {
    setState({ ...state, isScheduleModalOpen: false })
  }

  const scheduleTitle = canSchedule ? {} : { title: t(`You don't have enough credits to schedule this message`) }

  const headerWithImage = (
    <ModalHeader hideBorder>
      <div className={`${rootClass}__confirmation-header`}>
        <div className={`${rootClass}__confirmation-header-image`}>
          <Svg name={SvgNames.afterHoursMoon} type={SvgType.VERY_LARGE_ICON} />
        </div>
        <Typography text={t('Send Now')} {...ModalHeaderStyle} />
      </div>
    </ModalHeader>
  )

  const headerContent = allowedSendWindow.recipientsOutsideAllowedWindow === 0 ? t('Send Now') : headerWithImage

  return (
    <div className={`${rootClass}`} data-test={rootClass}>
      {sendConfirmationOpen && (
        <ConfirmationModal
          dataTest={`${rootClass}-confirmation-modal`}
          isOpen
          isYesNo
          yesButtonText="Send Now"
          body={getConfirmationBody()}
          onAnswer={confirmSendClicked}
          disabledPrimary={!canSend}
          className={`${rootClass}__confirmation-body`}
          title={headerContent}
        />
      )}
      {state.isScheduleModalOpen && (
        <ScheduledSendModal context={context} deliveryTime={deliveryTime} isOpen={state.isScheduleModalOpen} close={closeScheduleModal} />
      )}
      <Button disabled={!canSend} buttonType={ButtonType.PRIMARY} dataTest={`${rootClass}-submit-button`} fullWidth onClick={openSendConfirmation}>
        {t('Send message')}
      </Button>
      <Button
        disabled={!(canSend || canSchedule)}
        buttonType={ButtonType.WHITE}
        dataTest={`${rootClass}-schedule-button`}
        fullWidth
        onClick={onScheduleClicked}
        {...scheduleTitle}
      >
        {t('Schedule send')}
      </Button>
      {canSend && getSendable()}
      {(canSend || canSchedule) && getSchedulable()}
      {!canSend && !canSchedule && getNotSendable()}
    </div>
  )
}

export default Sendability
