import React, { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { ConfigProvider, DatePicker as AntdDatePicker } from 'antd'
import type { RangePickerProps } from 'antd/es/date-picker'
import { RangePickerDateProps } from 'antd/es/date-picker/generatePicker'
import classNames from 'classnames'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'

import Button, { ButtonType } from '@components/Button'
import {
  dateRangePickerDefaultFormat,
  dateRangePresets,
  rangePickerThemeProps,
} from '@components/DateRangePicker/constants/DateRangePicker.constants'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import { TimePickerV2ErrorMessage } from '@components/TimePickerV2/components/TimePickerV2.components'
import Tooltip from '@components/Tooltip/Tooltip'
import { getUUID, useTranslation } from '@const/globals'
import { filterNotEmptyArray } from '@utils/array'

import './DateRangePicker.css'

const { RangePicker } = AntdDatePicker

export type DateRangeValueType = [Dayjs | null, Dayjs | null] | null

export interface DateRangePickerProps {
  onCalendarSubmit: (dates: DateRangeValueType) => void
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  renderCustomFooter?: (children: ReactNode) => ReactNode
  defaultRange?: DateRangeValueType
  // If both 'onlyFutureDates' and 'allowFutureDates' flags are set to true, 'onlyFutureDates' takes priority
  onlyFutureDates?: boolean
  allowFutureDates?: boolean
  allowAnyDate?: boolean
  highlightToday?: boolean
  // by default both dates will be required for apply
  allowPartialSelection?: boolean
  errorMessage?: string | React.ReactNode
  hideError?: boolean
  hideErrorIcon?: boolean
  allowDays?: number
  className?: string
  dataTest?: string
  format?: string
  showValueTooltip?: boolean
  menuPortalTarget?: RangePickerDateProps<Dayjs>['getPopupContainer']
}

const rootClass = 'date-range-picker'

const DateRangePicker: FC<DateRangePickerProps> = (props: DateRangePickerProps) => {
  const {
    onCalendarSubmit,
    onFocus,
    onBlur,
    renderCustomFooter,
    menuPortalTarget = (trigger) => trigger.parentElement ?? document.body,
    showValueTooltip,
    onlyFutureDates = false,
    allowFutureDates = false,
    allowDays,
    allowAnyDate,
    defaultRange = null,
    highlightToday = false,
    allowPartialSelection = false,
    errorMessage,
    hideError,
    hideErrorIcon,
    dataTest = rootClass,
    className = '',
    format = dateRangePickerDefaultFormat,
  } = props

  const { t } = useTranslation()
  const [rangeValue, setRangeValue] = useState<DateRangeValueType>(defaultRange)
  const [activeInput, setActiveInput] = useState<boolean[]>([false, false])
  const [lastAppliedValue, setLastAppliedValue] = useState<DateRangeValueType>(defaultRange)
  const [open, setOpen] = useState<boolean>(false)
  const [isTouched, setIsTouched] = useState<boolean>(false)
  const uuid = useRef(getUUID())
  const presets = useMemo(
    () => (onlyFutureDates ? undefined : dateRangePresets.map(({ label, value }) => ({ label: t(label), value }))),
    [onlyFutureDates, t]
  )
  const bothRangesSelected = !(!rangeValue || !rangeValue[0] || !rangeValue[1])

  const getDisabledDate: RangePickerProps['disabledDate'] = useCallback(
    (current: Dayjs) => {
      if (allowAnyDate) {
        return false
      }
      if (allowDays && (activeInput[0] || (rangeValue?.[0] && rangeValue?.[1] && !activeInput[0] && !activeInput[1]))) {
        return (
          current < dayjs().startOf('day') ||
          current < dayjs(rangeValue?.[0]).startOf('day') ||
          current > dayjs(rangeValue?.[0]).add(allowDays, 'day').endOf('day')
        )
      }
      if (allowDays && activeInput[1]) {
        return (
          current < dayjs().startOf('day') ||
          current > dayjs(rangeValue?.[1]).endOf('day') ||
          current < dayjs(rangeValue?.[1]).add(-allowDays, 'day').startOf('day')
        )
      }
      if (onlyFutureDates) {
        return current < dayjs().startOf('day')
      } else {
        return (!allowFutureDates && current > dayjs().endOf('day')) || current < dayjs().subtract(27, 'month').startOf('day')
      }
    },
    [allowFutureDates, onlyFutureDates, allowDays, rangeValue, activeInput]
  )
  const handleApply = useCallback(() => {
    setOpen(false)
    setIsTouched(false)
    setActiveInput([false, false])
    onCalendarSubmit(rangeValue)
    setLastAppliedValue(rangeValue)
  }, [rangeValue, onCalendarSubmit])
  const handleOpenChange = useCallback((open: boolean) => open && setOpen(open), [])

  const handleReset = useCallback(() => {
    setRangeValue(null)
    setIsTouched(false)
    setOpen(false)
    setActiveInput([false, false])
    onCalendarSubmit(null)
  }, [])

  const handleCalendarChange = useCallback(
    (dates: DateRangeValueType) => {
      const rangeToSet = dates
      if (rangeToSet) {
        if (rangeToSet[0]) {
          rangeToSet[0] = rangeToSet[0]?.startOf('day')
          !activeInput[1] && setActiveInput([true, false])
        }
        if (rangeToSet[1]) {
          rangeToSet[1] = rangeToSet[1]?.endOf('day')
          !activeInput[0] && setActiveInput([false, true])
        }
      }
      setIsTouched(true)
      setRangeValue(rangeToSet)
    },
    [activeInput]
  )

  const detectClickOutside = useCallback(
    (e: MouseEvent) => {
      const closeDropdown = () => {
        setRangeValue(lastAppliedValue)
        setIsTouched(false)
        setOpen(false)
      }
      if (!e.target) {
        closeDropdown()
        return
      }
      const target = e.target as any
      const rangePicker = document.querySelector(`.${rootClass}__input-${uuid.current}`)
      const rangePickerDropdowns = [...document?.querySelectorAll('.ant-picker-dropdown')]
      const inPickerOrDropdown: boolean = !!rangePicker?.contains(target) || rangePickerDropdowns.some((picker) => !!picker?.contains(target))
      const hasAntPickerClassName = typeof target.className === 'string' && target.className.includes('ant-picker')
      if (hasAntPickerClassName || inPickerOrDropdown) {
        return
      }
      closeDropdown()
    },
    [lastAppliedValue]
  )

  useEffect(() => {
    if (open) {
      document.addEventListener('click', detectClickOutside)
    }
    return () => {
      if (open) {
        document.removeEventListener('click', detectClickOutside)
      }
    }
  }, [open, detectClickOutside])

  const renderFooter = useCallback(
    () => (
      <div className={`${rootClass}__panel-footer`}>
        <Button
          onClick={handleReset}
          buttonType={ButtonType.TEXT_TEAL}
          className={`${rootClass}__panel-footer-reset-button`}
          dataTest={`${rootClass}-reset-button`}
        >
          {t('Clear all')}
        </Button>
        <Button
          buttonType={ButtonType.PRIMARY}
          dataTest={`${dataTest}-apply-button`}
          onClick={handleApply}
          disabled={!isTouched || (!allowPartialSelection && !bothRangesSelected)}
        >
          {t('Apply')}
        </Button>
      </div>
    ),
    [handleApply, handleReset, bothRangesSelected, allowPartialSelection, isTouched, t, dataTest]
  )

  const renderPanel = useCallback<(panel: ReactNode) => ReactNode>((Panel) => <div data-test={`${dataTest}-panel`}>{Panel}</div>, [dataTest])

  const activePresetIndex: number = bothRangesSelected
    ? dateRangePresets.findIndex(
        ({ value }) => value[0].format(format) === rangeValue[0]?.format(format) && value[1].format(format) === rangeValue[1]?.format(format)
      ) + 1
    : 0

  const isError = errorMessage && !open

  const renderRangePicker = () => {
    const rangePicker = (
      <RangePicker
        popupClassName={classNames(`${rootClass}__panel`, `${className}__panel`, {
          [`${rootClass}__panel-highlight-today`]: highlightToday,
          [`${rootClass}__panel-active-preset-${activePresetIndex}`]: !!activePresetIndex,
        })}
        disabledDate={getDisabledDate}
        open={open}
        onOpenChange={handleOpenChange}
        value={rangeValue}
        onCalendarChange={handleCalendarChange}
        onFocus={onFocus}
        onBlur={onBlur}
        presets={presets}
        panelRender={renderPanel}
        renderExtraFooter={() => renderCustomFooter?.(renderFooter()) ?? renderFooter()}
        format={format}
        allowClear={false}
        separator={<Svg name={SvgNames.datePickerArrow} type={SvgType.ICON} />}
        suffixIcon={<Svg name={SvgNames.calendar} type={SvgType.LARGER_ICON} fill={isError ? SvgColor.REMOVE_RED : undefined} />}
        status={isError ? 'error' : undefined}
        data-test={`${rootClass}-input`}
        className={classNames(`${rootClass}__input`, `${rootClass}__input-${uuid.current}`)}
        getPopupContainer={menuPortalTarget}
      />
    )
    const tooltipText = showValueTooltip
      ? rangeValue
          ?.map((date) => (date ? date.format(format) : undefined))
          .filter(filterNotEmptyArray)
          .join(' - ')
      : ''

    return !tooltipText ? rangePicker : <Tooltip trigger={rangePicker}>{tooltipText}</Tooltip>
  }

  return (
    <ConfigProvider
      theme={{
        token: rangePickerThemeProps,
      }}
    >
      <div className={classNames(rootClass, className)} data-test={dataTest}>
        {renderRangePicker()}
        {!hideError && <TimePickerV2ErrorMessage errorMessage={errorMessage} dataTest={dataTest} hideErrorIcon={hideErrorIcon} />}
      </div>
    </ConfigProvider>
  )
}

export default DateRangePicker
