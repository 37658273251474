import React, { FC, ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Row } from 'react-table'

import { ListPickerBodyBaseProps, listPickerModalBodyRootClass } from '@complex/ListPickerModalV2/components/ListPickerModalBody/ListPickerModalBody'
import {
  ALL_LISTS,
  getAllListsResultsTablesUtils,
  getMainFilterOption,
  getSearchEmptyStates,
  getSearchOptions,
  getSearchTableProps,
  getUCLSearchTableProps,
} from '@complex/ListPickerModalV2/components/SearchResults/utils/SearchResultsUtils'
import { ListPickerModalContext, selectedListsInitialState, unifiedListTypes } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import ActionableNestedTableWithEmptyListing from '@components/ActionableNestedTableWithEmptyListing/ActionableNestedTableWithEmptyListing'
import Caution from '@components/Caution/Caution'
import EmptyListing from '@components/EmptyListing/EmptyListing'
import FolderSearchFilterButtons from '@components/FolderSearch/components/FolderSearchFilterButtons/FolderSearchFilterButtons'
import Loader from '@components/Loader'
import ScrollArea from '@components/ScrollArea/ScrollArea'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { useAccountSettings } from '@utils/account/account.utils'
import { FilterTypes } from '@utils/filter'
import { GET_LIST_CATEGORIES, ListCategory } from '@utils/lists'

import './SearchResults.css'

interface SearchResultsProps extends ListPickerBodyBaseProps {
  checkboxCellOverride?: (row: Row) => ReactNode
  hideAllListsOption?: boolean
  onRowSelectionChanged: (selectedRows: Row[], rows?: Row[]) => void
  onRowSelectionChangedInAllLists: (selectedRows: Row[], rows: Row[], listCategory: ListCategory) => void
}

const rootClass = 'search-results'

const SearchResults: FC<SearchResultsProps> = (props: SearchResultsProps) => {
  const {
    checkboxCellOverride,
    onRowSelectionChanged,
    onRowSelectionChangedInAllLists,
    restrictMixingLegacyListsAndUCL,
    hideAllListsOption = false,
  } = props
  const { exclusivelyActOnContacts } = useAccountSettings()
  const {
    values: {
      disabledLists,
      disableLegacyLists,
      disableUnifiedLists,
      disabledListTooltipText,
      listType,
      currentFilter,
      currentFolder,
      currentTag,
      hideUCL,
      hideAllUCLListTypes,
      multiSelect,
      search,
      selectedLists,
    },
    values,
    performSearch,
    showTooltipByCriteria,
    disableRowByCriteria,
  } = useContext(ListPickerModalContext)
  const { lists: searchLists = { ...selectedListsInitialState }, filterLoading, value: searchValue } = search || {}
  const [activeFilter, setActiveFilter] = useState<string>(
    (currentFilter &&
      (Object.values(FilterTypes).includes(currentFilter.name as FilterTypes)
        ? currentFilter.name
        : getMainFilterOption(currentFilter?.name, GET_LIST_CATEGORIES().find(({ type }) => type === listType)?.text as string, listType).name)) ||
      (currentFolder && currentFolder.name) ||
      (currentTag && currentTag.name) ||
      ''
  )

  useEffect(() => {
    if (!!searchLists) {
      if (exclusivelyActOnContacts) {
        delete searchLists.MARKETING
      }

      if (hideUCL) {
        delete searchLists.UNIFIED_LIST
      }

      if (hideAllUCLListTypes) {
        delete searchLists.UNIFIED_LIST
        delete searchLists.FORM_SUBMISSIONS
        delete searchLists.WEBINAR_REGISTRATION
      }
    }
  }, [searchLists])

  const lastSelectedRef = useRef<string>('')
  const { t } = useTranslation()

  const selectedListsEntries = Object.entries(selectedLists)
  const hasUnifiedListSelected = selectedListsEntries.some((list) => unifiedListTypes.includes(list[0] as ListCategory) && !!list[1].length)
  const hasLegacyListSelected = selectedListsEntries.some((list) => !unifiedListTypes.includes(list[0] as ListCategory) && list[1].length)

  const searchListsEntries = Object.entries(searchLists)
  const hasUnifiedListSearchLists = searchListsEntries.some((list) => unifiedListTypes.includes(list[0] as ListCategory) && !!list[1].length)
  const hasLegacyListSearchLists = searchListsEntries.some((list) => !unifiedListTypes.includes(list[0] as ListCategory) && !!list[1].length)

  const showCaution =
    restrictMixingLegacyListsAndUCL && ((hasUnifiedListSelected && hasLegacyListSearchLists) || (hasLegacyListSelected && hasUnifiedListSearchLists))
  const disableLists = unifiedListTypes.includes(listType) ? disableUnifiedLists : disableLegacyLists

  const mainTableProps = useMemo(() => {
    const params = {
      disableCheckboxHeader: disableLegacyLists || disableUnifiedLists,
      disabledListTooltipText: (!disableLists && disabledListTooltipText) as string,
      lists: searchLists[listType] as any[],
      listType,
      multiSelect,
      onRowSelectionChanged,
      values,
      showTooltipByCriteria,
    }
    return {
      ...getSearchEmptyStates(t),
      ...(unifiedListTypes.includes(listType as ListCategory) ? getUCLSearchTableProps(params) : getSearchTableProps(params)),
    }
  }, [activeFilter, disableLists, search, selectedLists])

  const allListsResultsTables = useMemo(
    () =>
      getAllListsResultsTablesUtils({
        activeFilter,
        checkboxCellOverride,
        disabledLists,
        disabledListTooltipText,
        lastSelectedRef,
        multiSelect,
        onRowSelectionChangedInAllLists,
        restrictMixingLegacyListsAndUCL,
        rootClass,
        values,
        disableRowByCriteria,
      }),
    [lastSelectedRef.current, activeFilter, search, selectedLists, disableLists]
  )

  const searchOptions = useMemo(
    () =>
      getSearchOptions(
        {
          activeFilter,
          currentFilter,
          currentFolder,
          currentTag,
          listType,
        },
        !hideAllListsOption
      ),
    [activeFilter, currentFilter, currentFolder, currentTag, hideAllListsOption, listType]
  )

  const allListsResults =
    search?.count ?? 0 > 0 ? <div className={`${rootClass}__all-lists`}>{allListsResultsTables}</div> : <EmptyListing {...getSearchEmptyStates(t)} />

  const onChange = (searchAll: boolean, name?: string) => {
    performSearch(searchAll, name === ALL_LISTS)
    setActiveFilter(() => name as string)
  }

  return (
    <div className={rootClass} data-test={rootClass}>
      {showCaution && <Caution message={t('Act-On Contacts cannot be combined with legacy lists or segments.')} />}

      <Typography
        className={`${rootClass}__label`}
        text={`${search?.count} ${t(`result${search?.count !== 1 ? 's' : ''} for`)} "${searchValue}"`}
        type={TextType.BODY_TEXT_LARGE}
        weight={TextWeight.BOLD}
      />
      <div className={`${rootClass}__content`}>
        <div className={`${rootClass}__filters`}>
          <Typography text={t('Search in:')} type={TextType.BODY_TEXT_SMALL_LIGHT} weight={TextWeight.MEDIUM} />
          <FolderSearchFilterButtons onChange={onChange} options={searchOptions} />
        </div>
        <ScrollArea className={`${rootClass}__scroll-area`}>
          {filterLoading ? (
            <Loader center />
          ) : activeFilter === ALL_LISTS ? (
            allListsResults
          ) : (
            <ActionableNestedTableWithEmptyListing
              className={`${listPickerModalBodyRootClass}__table`}
              checkboxCellOverride={checkboxCellOverride}
              fixedHeader
              {...mainTableProps}
            />
          )}
        </ScrollArea>
      </div>
    </div>
  )
}
export default SearchResults
