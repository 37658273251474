import { useApolloClient } from '@apollo/client'
import saveSubscriptionEntitiesChannels from '@graphql/mutations/saveSubscriptionEntitiesChannels'
import getWebhooksEndpointsWithAPStepEvent from '@graphql/queries/getWebhooksEndpointsWithAPStepEvent'
import {
  SaveSubscriptionEntitiesChannelsMutation,
  SaveSubscriptionEntitiesChannelsMutationVariables,
  WebhookSubscriptionInput,
} from '@graphql/types/mutation-types'
import { GetWebhooksEndpointsWithApStepEventQuery, GetWebhooksEndpointsWithApStepEventQueryVariables } from '@graphql/types/query-types'
import { FetchPromise } from '@utils/types'

export interface EditOutgoingWebhookStepRequests {
  getEndpoints: () => FetchPromise<GetWebhooksEndpointsWithApStepEventQuery>
  saveWebhookEndpoints: (subscriptions: WebhookSubscriptionInput[]) => FetchPromise<SaveSubscriptionEntitiesChannelsMutation>
}

export const useEditOutgoingWebhookStepRequests = (): EditOutgoingWebhookStepRequests => {
  const actonClient = useApolloClient()

  const saveWebhookEndpoints = (subscriptions: WebhookSubscriptionInput[]) => {
    return actonClient.mutate<SaveSubscriptionEntitiesChannelsMutation, SaveSubscriptionEntitiesChannelsMutationVariables>({
      mutation: saveSubscriptionEntitiesChannels,
      variables: {
        subscriptions,
      },
    })
  }

  const getEndpoints = () => {
    return actonClient.query<GetWebhooksEndpointsWithApStepEventQuery, GetWebhooksEndpointsWithApStepEventQueryVariables>({
      query: getWebhooksEndpointsWithAPStepEvent,
      fetchPolicy: 'network-only',
    })
  }

  return {
    getEndpoints,
    saveWebhookEndpoints,
  }
}
