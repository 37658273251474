import { gql } from '@apollo/client'

export default gql`
  mutation setBrandingDomainDisplayedUrl($url: String!, $createLeCert: Boolean!) {
    setBrandingDomainDisplayedUrl(url: $url, createLeCert: $createLeCert) {
      status
      errorMsg
      urlItem {
        url
        path
        domain
        protocol
        isDefault
      }
    }
  }
`
