import React, { FC, useEffect, useRef, useState } from 'react'

import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import { ButtonSize, ButtonWeight } from '@components/Button/Button'
import HoverSVG from '@components/HoverSVG/HoverSVG'
import { SvgNames } from '@components/Svg'
import { useTranslation } from '@const/globals'

interface ShowHidePasswordButtonProps {
  className: string
  dataTest: string
  value: boolean
  onClick: (value: boolean) => void
}

const ShowHidePasswordButton: FC<ShowHidePasswordButtonProps> = ({ className, dataTest, onClick, value }: ShowHidePasswordButtonProps) => {
  const [toggleShowHide, setToggleShowHide] = useState<boolean>(value)

  useEffect(() => {
    setToggleShowHide(value)
  }, [value])

  const buttonRef = useRef<HTMLButtonElement>()

  const { t } = useTranslation()

  return (
    <Button
      buttonType={ButtonType.TEXT_TEAL}
      className={`${className}__button-show`}
      dataTest={`${dataTest}-button-show`}
      iconPosition={ButtonIconPosition.LEFT}
      buttonSize={ButtonSize.SMALL}
      weight={ButtonWeight.MEDIUM}
      register={buttonRef}
      onClick={() => {
        setToggleShowHide(!toggleShowHide)
        onClick(!toggleShowHide)
      }}
    >
      <HoverSVG
        svg={toggleShowHide ? SvgNames.hideGreen : SvgNames.showGreen}
        hoverSvg={toggleShowHide ? SvgNames.hideDark : SvgNames.showDark}
        parentRef={buttonRef}
      />
      {toggleShowHide ? t('Hide all') : t('Show all')}
    </Button>
  )
}

export default ShowHidePasswordButton
