import React, { FC, useState } from 'react'

import classNames from 'classnames'

import DropDown from '@components/DropDown'
import { DropDownType } from '@components/DropDown/DropDown'
import DropDownActions, { MenuItem } from '@components/DropDownActions/DropDownActions'
import { RowAction } from '@components/Table/Table'
import { useTranslation } from '@const/globals'

import './TableRowDropDownActions.css'

interface Props {
  dataTest?: string
  onToggleDropDown?: (open: boolean) => void
  rowActions: RowAction[]
  row: any
}

const rootClass = 'table-row-drop-down-actions'

const TableRowDropDownActions: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, onToggleDropDown, rowActions, row } = props
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const { t } = useTranslation()

  const dropdownActions: MenuItem[] = rowActions.map(({ disabled, hidden, tooltipMessage, icon, topSection, label, onClick, hasTooltip }) => {
    return { disabled, tooltipMessage, hasTooltip, icon, onClick, hidden, topSection, row, text: label } as MenuItem
  })

  const closeDropDown = (open: boolean) => {
    onToggleDropDown && onToggleDropDown(open)
    setDropdownOpen(open)
  }

  return (
    <DropDown
      dataTest={dataTest}
      isOpen={dropdownOpen}
      toggleOpen={closeDropDown}
      hasOverflowIcon
      overflowIconTooltipText={t('More')}
      type={DropDownType.STYLED}
      className={classNames(rootClass, { [`${rootClass}__open`]: dropdownOpen })}
      sideOffset={16}
    >
      <DropDownActions menuItems={dropdownActions} closeDropDown={() => closeDropDown(false)} />
    </DropDown>
  )
}

export default TableRowDropDownActions
