import { gql } from '@apollo/client'

export default gql`
  query getListMaintenanceProgram($programId: String!) {
    getListMaintenanceProgram(programId: $programId) {
      __typename
      schedule {
        __typename
        nextTS
        type
        hour
        minute
        ampm
        interval
        timestamp
        weekdaysOnly
        hasEndTime
        endHour
        endMinute
        endAmpm
      }
      sourceList {
        __typename
        id
        baseId
        name
        count
      }
      allowReentrantAddresses
      useAlternateUniqueField
      alternateUniqueFieldHeader
      allowLooping
      allowBypassFatigueRules
      timeZoneId
      suppressBadEmails
      hasActOnContactList
      historyDisabled
      historyDisabledStarted
      historyDisabledStopped
      creator
      createdOn
      doneCount
      lastUpdated
      name
      description
      steps {
        ...ListProgramSteps
      }
      runStatus {
        __typename
        valid
        isRunning
      }
      startedOn
      lastRun
    }
  }

  fragment ListProgramSteps on ProgramStep {
    stepType
    stepId
    displayName
    ... on ProgramCopyStep {
      all
      not
      srcId
      srcName
      srcRecordCount
      listId
      listName
      listRecordCount
      update
      updateType
      pushToSf
      overrideSystemMap
      overrideSystemMapField
    }
    ... on ProgramAppendToSegmentStep {
      all
      not
      srcId
      srcName
      listId
      listName
      pruneRecords
    }
    ... on ProgramCopyFromStep {
      srcId
      srcName
      srcRecordCount
      pruneRecords
      updateType
    }
    ... on ProgramFieldSetStep {
      srcId
      srcName
      srcRecordCount
      not
      listId
      listName
      listRecordCount
      fieldName
      forceUseOfSourceList
      value
      rule
      canPushSF
      pushToSf
      campaignId
      scoreSheetId
    }
  }
`
