import React, { FC } from 'react'

import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'

import LandingPageComposerProperties from './LandingPageComposerSettings'

const LandingPageComposerSettingsContainer: FC = () => {
  const {
    values: { loading, isStory },
  } = useComposerContext()

  return !loading || isStory ? <LandingPageComposerProperties /> : null
}

export default LandingPageComposerSettingsContainer
