import { FieldDefinition, StringKeyValue } from '@graphql/types/query-types'
import { MATCH_UPPERCASE_LETTERS_REGEX } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/EditCRMStepV2'
import {
  DetailsDataType,
  msDynamicsIconsByEntity,
  netsuiteIconsByEntity,
  salesforceIconsByEntity,
  sugarIconsByEntity,
  zendeskIconsByEntity,
} from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.constants'
import { ensureFirstLetterIsCapitalized } from '@utils/strings'

export const getIconByEntity = (entityType: string, crmType: string) => {
  switch (crmType) {
    case 'Salesforce':
      return salesforceIconsByEntity[entityType]
    case 'Sugar':
      return sugarIconsByEntity[entityType]
    case 'Zendesk':
      return zendeskIconsByEntity[entityType]
    case 'NetSuite':
      return netsuiteIconsByEntity[entityType]
    case 'MS Dynamics':
      return msDynamicsIconsByEntity[entityType]
  }
}

export const findFieldByKey = (key: string, fields: FieldDefinition[]) => fields.find(({ fieldName }) => key === fieldName)

export const findKeyValueByFieldName = (fieldName: string, fields: StringKeyValue[]): StringKeyValue | undefined =>
  fields.find(({ key = '' }) => key === fieldName)

export const getEntityTitle = (entity: string, lowerCase = false): string => {
  const title = entity.replace(MATCH_UPPERCASE_LETTERS_REGEX, ' $1').trim() // Add space before capital letters
  return lowerCase ? title.toLowerCase() : ensureFirstLetterIsCapitalized(title) ?? title
}

export const hasValidFields = (fields: FieldDefinition[], values: StringKeyValue[]): boolean => {
  const isValidField = ({ dataType }: FieldDefinition, value?: string) => {
    return dataType === DetailsDataType.DATE || dataType === DetailsDataType.DATETIME || !!value
  }
  const requiredFields = fields.filter(({ required }) => required)
  return requiredFields.every((field) => values.some(({ key, value }) => key === field.fieldName && isValidField(field, value)))
}
