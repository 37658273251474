import React, { FC, useEffect, useState } from 'react'

import getWelcomeMessage from '@graphql/microservices/sms-management/getWelcomeMessage'
import upsertMessage from '@graphql/microservices/sms-management/upsertMessage'
import {
  GetWelcomeMessageQuery,
  GetWelcomeMessageQueryVariables,
  MutationUpsertMessageArgs,
  UpsertMessageMutation,
} from '@graphql/types/microservice/sms-management-types'
import { SMSContext } from '@src/pages/sms/context/SMSContext'
import { smsStateDefaultValues } from '@src/pages/sms/context/smsStateDefaultValues'
import { SMSMessage, State } from '@src/pages/sms/edit/EditSMSContainer'
import { canScheduleUtil, canSendUtil, updateMessageUtil } from '@src/pages/sms/edit/utils/EditSMSContainerUtils'
import { replacePersonalizationWithX } from '@src/pages/sms/edit/utils/personalizationReplacement'
import SegmentedMessage from '@src/pages/sms/edit/utils/segmentationCalculator'
import WelcomeMessageEditor from '@src/pages/sms/marketingListOptIns/components/WelcomeMessageEditor/WelcomeMessageEditor'
import { MessageStatus } from '@src/pages/sms/marketingListOptIns/MarketingListOptIns.constants'
import { useAccountSettings } from '@utils/account/account.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { logNewRelicError } from '@utils/new-relic.utils'

interface Props {
  onSave: () => void
  closeModal: () => void
}

const defaultState: State = smsStateDefaultValues

const rootClass = 'welcome-message-editor-container'

const WelcomeMessageEditorContainer: FC<Props> = ({ closeModal, onSave }: Props) => {
  const [containerValues, setContainerValues] = useState<State>(defaultState)
  const { accountId, userId } = useAccountSettings()
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.SMS_MANAGEMENT })

  useEffect(() => {
    client
      .query<GetWelcomeMessageQuery, GetWelcomeMessageQueryVariables>({
        query: getWelcomeMessage,
        variables: { accountId },
      })
      .then((data) => {
        if (data.data.getWelcomeMessage?.smsMessage) {
          setContainerValues({
            ...containerValues,
            smsMessage: {
              ...data.data.getWelcomeMessage.smsMessage,
              messageText: data.data.getWelcomeMessage.smsMessage.messageText ?? '',
              title: data.data.getWelcomeMessage.smsMessage.title ?? '',
              description: data.data.getWelcomeMessage.smsMessage.description ?? '',
              launchId: defaultState.smsMessage.launchId,
              recipients: defaultState.smsMessage.recipients,
              recipientTimezones: [],
            },
          })
        }
      })
      .catch((error) => logNewRelicError(error))
  }, [])

  const scheduleMessageLaunch = () => false
  const sendSMSMessage = () => {
    const { smsMessage } = containerValues
    client
      .mutate<UpsertMessageMutation, MutationUpsertMessageArgs>({
        mutation: upsertMessage,
        variables: {
          isWelcomeMessage: true,
          accountId,
          userId: userId,
          smsMessage: {
            title: smsMessage.title,
            messageText: smsMessage.messageText,
            smsMessageId: smsMessage.smsMessageId,
            accountId: accountId,
            status: MessageStatus.WELCOME_USER,
          },
        },
      })
      .then(() => onSave())
      .catch((error) => logNewRelicError(error))
  }

  const saveMessage = (smsMessage: SMSMessage, messageCaretPosition?: number) => {
    const updatedCaretPosition = messageCaretPosition ? { messageCaretPosition } : {}
    setContainerValues({
      ...containerValues,
      ...updatedCaretPosition,
      smsMessage,
      segmentedMessage: new SegmentedMessage(replacePersonalizationWithX(smsMessage.messageText)),
    })
  }

  const updateMessage = (message: string) => updateMessageUtil(message, containerValues, setContainerValues)

  const updater = (field: string, value: any) => {
    setContainerValues({ ...containerValues, [field]: value })
  }

  const canSend = () => canSendUtil(containerValues)
  const canSchedule = () => canScheduleUtil(containerValues)

  return (
    <SMSContext.Provider
      value={{ values: containerValues, updater, saveMessage, sendSMSMessage, canSend, canSchedule, updateMessage, scheduleMessageLaunch }}
      data-test={rootClass}
    >
      <WelcomeMessageEditor closeModal={closeModal} />
    </SMSContext.Provider>
  )
}

export default WelcomeMessageEditorContainer
