import React from 'react'

import { getFolderById } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { DeleteConfirmationProps } from '@components/DeleteConfirmation/DeleteConfirmation'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { Folder } from '@interface/Folder'
import { ItemType, ITEM_TYPES } from '@utils/categorization'

export enum DeleteConfirmationModals {
  DELETE_FOLDER = 'DELETE_FOLDER',
  DELETE_FOLDER_WITH_PARENT = 'DELETE_FOLDER_WITH_PARENT',
  DELETE_ALL_CONTACTS = 'DELETE_ALL_CONTACTS',
  DELETE_ITEMS_SINGLE = 'DELETE_ITEMS_SINGLE',
  DELETE_ITEMS_PLURAL = 'DELETE_ITEMS_PLURAL',
  DELETE_FRAGMENTS_SINGLE = 'DELETE_FRAGMENTS_SINGLE',
  DELETE_FRAGMENTS_PLURAL = 'DELETE_FRAGMENTS_PLURAL',
  DELETE_LIST_PROGRAMS_PLURAL = 'DELETE_LIST_PROGRAMS_PLURAL',
  DELETE_LIST_PROGRAMS_SINGLE = 'DELETE_LIST_PROGRAMS_SINGLE',
  DELETE_SEGMENTS_SINGLE = 'DELETE_SEGMENTS_SINGLE',
  DELETE_SEGMENTS_PLURAL = 'DELETE_SEGMENTS_PLURAL',
  DELETE_TAG = 'DELETE_TAG',
  DELETE_FROM_FOLDER = 'DELETE_FROM_FOLDER',
  DELETE_FROM_FOLDER_PLURAL = 'DELETE_FROM_FOLDER_PLURAL',
}

const onClose = (update: Function) => {
  update({
    deleteConfirmationData: undefined,
    confirmationModal: undefined,
  })
}

const onDelete = (action: Function, deleteConfirmationData: any, update: Function) => {
  action(deleteConfirmationData)
  onClose(update)
}

interface GetConfirmationModalParams {
  deleteListPrograms?: Function
  deleteFolder?: Function
  deleteAllContacts?: Function
  deleteSegments?: Function
  deleteContentFragment?: Function
  deleteTag?: Function
  deleteFromFolder?: Function
  itemTypesUsed: Record<string, number>
  update: Function
  deleteConfirmationData: any
  folders: Folder[]
  t: Function
  confirmationModal: any
  itemType: ItemType
}

export const getDeleteConfirmationModalUtils = ({
  deleteListPrograms,
  deleteFolder,
  deleteAllContacts,
  deleteSegments,
  deleteContentFragment,
  deleteTag,
  deleteFromFolder,
  deleteConfirmationData,
  itemTypesUsed,
  update,
  folders,
  t,
  confirmationModal,
  itemType,
}: GetConfirmationModalParams) => {
  const { capitalText, listPage } = ITEM_TYPES[itemType]

  const getDeleteFromFolderProps = () => {
    const items = Array.isArray(deleteConfirmationData) ? deleteConfirmationData : []

    const haveDifferentFolders = [...new Set(items.map(({ folderId }) => folderId))].length > 1 ? '.DifferentFolders' : ''
    const { name = '', folderId }: { name: string; folderId: number | undefined } = items.length ? items[0] : {}
    const values = {
      count: items.length,
      sectionName: t(`All ${capitalText}`),
      itemName: name,
      ...(!haveDifferentFolders ? { folderName: folderId ? getFolderById(folderId, folders)?.name : '' } : {}),
    }

    return {
      title: t(`ListPage${listPage}.DeleteFromFolder.Title`, { count: items.length }),
      isRemove: true,
      deleteAction: () => deleteFromFolder && onDelete(deleteFromFolder, items, update),
      onClose: () => onClose(update),
      content: (
        <Typography
          type={TextType.BODY_TEXT_LIGHT}
          inline
          text={`ListPage${listPage}.DeleteFromFolder${haveDifferentFolders}`}
          tagProps={{ bold: { weight: TextWeight.BOLD } }}
          values={values}
        />
      ),
    }
  }

  const getDeleteTagProps = () => {
    const itemTypesUsedEntries = Object.entries(itemTypesUsed)
    let text
    if (!!itemTypesUsedEntries.length) {
      text = itemTypesUsedEntries.reduce(
        (curr, entry, index) =>
          `${curr + (index > 0 ? (index === itemTypesUsedEntries.length - 1 ? ` ${t('and')}` : ',') : '')} ${t(
            ITEM_TYPES[entry[0] as ItemType]?.completeItemText
          )} (${entry[1] as number})${index === itemTypesUsedEntries.length - 1 ? '. ' : ''}`,
        t('ListPage.Common.Tags.Delete.Used')
      )
      text += t('ListPage.Common.Tags.Delete.Used.Info')
    } else {
      text = 'ListPage.Common.Tags.Delete.NotUsed'
    }

    return {
      title: 'Are you sure?',
      text,
      deleteAction: () => deleteTag && onDelete(deleteTag, deleteConfirmationData, update),
      onClose: () => onClose(update),
    }
  }

  const deleteConfirmationModalsItems: { [key: string]: DeleteConfirmationProps } = {
    [DeleteConfirmationModals.DELETE_FOLDER]: {
      title: 'Delete this folder',
      text: `The folder and its sub-folders will be removed. `,
      textBold: `${capitalText} in this folder will appear in All ${capitalText}.`,
      deleteAction: () => deleteFolder && onDelete(deleteFolder, deleteConfirmationData, update),
      onClose: () => onClose(update),
    },
    [DeleteConfirmationModals.DELETE_FOLDER_WITH_PARENT]: {
      title: 'Delete this folder',
      text: `The folder and its sub-folders will be removed. `,
      textBold: `${capitalText} in this folder will appear in the parent folder.`,
      deleteAction: () => deleteFolder && onDelete(deleteFolder, deleteConfirmationData, update),
      onClose: () => onClose(update),
    },
    [DeleteConfirmationModals.DELETE_ALL_CONTACTS]: {
      title: 'Delete these contacts',
      text: `The contacts’ records and activity histories will be deleted from Act-On Contacts and all Segments. `,
      textBold: 'You cannot undo this action.',
      deleteAction: () => deleteAllContacts && onDelete(deleteAllContacts, deleteConfirmationData, update),
      onClose: () => onClose(update),
    },
    [DeleteConfirmationModals.DELETE_SEGMENTS_SINGLE]: {
      title: 'Delete this segment',
      text: 'This segment and its sub-segments will be removed from use in Act-On. ',
      textBold: 'Contacts in this segment will not be deleted.',
      deleteAction: () => deleteSegments && onDelete(deleteSegments, deleteConfirmationData, update),
      onClose: () => onClose(update),
    },
    [DeleteConfirmationModals.DELETE_SEGMENTS_PLURAL]: {
      title: 'Delete these segments',
      text: 'These segments and their sub-segments will be removed from use in Act-On. ',
      textBold: 'Contacts in these segments will not be deleted.',
      deleteAction: () => deleteSegments && onDelete(deleteSegments, deleteConfirmationData, update),
      onClose: () => onClose(update),
    },
    [DeleteConfirmationModals.DELETE_FRAGMENTS_SINGLE]: {
      title: t('Delete fragment'),
      text: t('This content fragment will be permanently deleted.'),
      textBold: t('You cannot undo this action.'),
      deleteAction: () => deleteContentFragment && onDelete(deleteContentFragment, deleteConfirmationData, update),
      onClose: () => onClose(update),
    },
    [DeleteConfirmationModals.DELETE_FRAGMENTS_PLURAL]: {
      title: t('Delete fragments'),
      text: t('These content fragments will be permanently deleted.'),
      textBold: t('You cannot undo this action.'),
      deleteAction: () => deleteContentFragment && onDelete(deleteContentFragment, deleteConfirmationData, update),
      onClose: () => onClose(update),
    },
    [DeleteConfirmationModals.DELETE_LIST_PROGRAMS_PLURAL]: {
      title: t('Delete programs'),
      text: t('These list maintenance programs will be permanently deleted.'),
      textBold: t('You cannot undo this action.'),
      deleteAction: () => deleteListPrograms && onDelete(deleteListPrograms, deleteConfirmationData, update),
      onClose: () => onClose(update),
    },
    [DeleteConfirmationModals.DELETE_LIST_PROGRAMS_SINGLE]: {
      title: t('Delete program'),
      text: t('This list maintenance program will be permanently deleted.'),
      textBold: t('You cannot undo this action.'),
      deleteAction: () => deleteListPrograms && onDelete(deleteListPrograms, deleteConfirmationData, update),
      onClose: () => onClose(update),
    },
    [DeleteConfirmationModals.DELETE_TAG]: getDeleteTagProps(),
    [DeleteConfirmationModals.DELETE_FROM_FOLDER]: getDeleteFromFolderProps(),
    [DeleteConfirmationModals.DELETE_FROM_FOLDER_PLURAL]: getDeleteFromFolderProps(),
  }
  return deleteConfirmationModalsItems[confirmationModal]
}
