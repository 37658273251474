import React from 'react'

import { TableV2Row } from '@components/TableV2/components/TableV2Row/TableV2Row'
import { TableV2RowProps, VirtualScrollerProps } from '@components/TableV2/tableV2TS/interfaces'
import { getCaretCellID } from '@components/TableV2/utils/tableV2Utils'
import { Row } from '@tanstack/react-table'
import { VirtualItem } from '@tanstack/virtual-core/src'

type ExcludedTableV2RowProps = 'rows' | 'row' | 'caretCellID'
type VirtualScrollerTableV2RowProps<T extends {} = {}> = Omit<TableV2RowProps<T>, ExcludedTableV2RowProps>

interface VirtualScrollerAutoSizerProps<T extends {}> {
  rows: Row<T>[]
  virtualScroller: VirtualScrollerProps
  rowProps: VirtualScrollerTableV2RowProps<T>
}

export const TableV2VirtualScroller = <T extends {}>({ rows, virtualScroller, rowProps }: VirtualScrollerAutoSizerProps<T>) => {
  if (!virtualScroller.virtualItems) {
    return null
  }

  return (
    <>
      {virtualScroller.virtualItems.map((virtualRow: VirtualItem, index: number) => {
        const row = rows[virtualRow.index] as Row<T>
        return (
          <TableV2Row
            key={row.id}
            style={{
              transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`,
            }}
            row={row}
            rows={rows}
            caretCellID={getCaretCellID(row)}
            {...rowProps}
          />
        )
      })}
    </>
  )
}
