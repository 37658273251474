import React, { FC, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import InfoHoverCard from '@components/InfoHoverCard/InfoHoverCard'
import InfoHoverHeader from '@components/InfoHoverHeader/InfoHoverHeader'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { formatSendTSDate, relativeDate } from '@utils/date'
import { ListMaintenanceProgramDto } from '@utils/listingPage/listMaintenancePrograms.utils'

import './ListProgramInfoHover.css'

interface ListProgramInfoHoverProps {
  className?: string
  dataTest?: string
  program: ListMaintenanceProgramDto
  isHidden?: boolean
}

interface ListProgramInfoHoverState {
  hasLongDescription: boolean
  open: boolean
  showMore: boolean
}

const listProgramInfoHoverDefaultState: ListProgramInfoHoverState = {
  hasLongDescription: false,
  open: false,
  showMore: true,
}

const rootClass = 'list-program-info-hover'
const DESCRIPTION_MAX_HEIGHT = 42

const ListProgramInfoHover: FC<ListProgramInfoHoverProps> = (props: ListProgramInfoHoverProps) => {
  const {
    dataTest = rootClass,
    className = '',
    isHidden = false,
    program: { description, updatedTime, name, lastDoneTS, lastEditedBy, srcNames },
  } = props

  const [state, setState] = useState<ListProgramInfoHoverState>(listProgramInfoHoverDefaultState)
  const { hasLongDescription, open, showMore } = state

  const descriptionRef = useRef<HTMLDivElement>(null)
  const source = (Array.isArray(srcNames) ? srcNames[0] : srcNames) || ''

  const { t } = useTranslation()

  useEffect(() => {
    if (descriptionRef.current && descriptionRef.current.offsetHeight > DESCRIPTION_MAX_HEIGHT) {
      setState((state) => ({ ...state, showMore: false, hasLongDescription: true }))
    }
  }, [description, open])

  const lastModifiedDate = relativeDate(updatedTime, true)
  const header = (
    <InfoHoverHeader
      dataTest={`${dataTest}-header`}
      className={`${rootClass}__header`}
      title={<Typography text={name} weight={TextWeight.MEDIUM} type={TextType.BODY_TEXT_WHITE} className={classNames({ ['ellip-clamp']: true })} />}
      subtitle={
        <>
          <Svg name={SvgNames.lastModified} type={SvgType.ICON} />
          <Typography
            className={`ellip`}
            text={lastEditedBy ? `Last modified: {{time}} by {{user}}` : `Last modified: {{time}}`}
            values={{ time: lastModifiedDate, user: lastEditedBy }}
            title={`${lastModifiedDate}${lastEditedBy ? ` ${t('by')} ${lastEditedBy}` : ''}`}
            type={TextType.BODY_TEXT_LIGHTEST_GRAY_SMALL}
            tagProps={{ bold: { weight: TextWeight.MEDIUM, type: TextType.BODY_TEXT_WHITE_SMALL } }}
            inline
          />
        </>
      }
    />
  )

  const footer = lastDoneTS ? (
    <>
      <Typography text={t('Last ran:')} type={TextType.BODY_TEXT_SMALL} inline />
      <Typography
        text={formatSendTSDate(lastDoneTS, { dateFormat: "EEEE, MMM d 'at' hh:mm a" })}
        type={TextType.BODY_TEXT_SMALL}
        weight={TextWeight.MEDIUM}
        inline
      />
    </>
  ) : null

  return (
    <InfoHoverCard
      className={classNames({ [`${rootClass}__expanded`]: hasLongDescription && showMore }, rootClass, className)}
      dataTest={dataTest}
      header={header}
      openDelay={350}
      closeDelay={150}
      sideOffset={6}
      footer={footer}
      isHidden={isHidden}
      side={'top'}
      avoidCollisions={false}
      onOpenChange={(open) => setState((state) => ({ ...state, open }))}
    >
      <Typography text={t('APPLIES TO CONTACTS IN')} type={TextType.BODY_TEXT_LIGHT_TINY} weight={TextWeight.BOLD} inline />
      <div className={`${rootClass}__source`}>
        <Svg type={SvgType.MEDIUM} name={SvgNames.contacts} />
        <Typography text={source} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.MEDIUM} />
      </div>
      {description && (
        <div className={`${rootClass}__description-container`}>
          <Typography text={t('ABOUT THIS PROGRAM')} type={TextType.BODY_TEXT_LIGHT_TINY} weight={TextWeight.BOLD} inline />
          <Typography
            className={classNames(`${rootClass}__description`, { [`${rootClass}__description-extended`]: showMore })}
            text={description}
            type={TextType.BODY_TEXT_SMALL}
            reference={descriptionRef}
            lineHeight={LineHeight.MEDIUM_SMALL}
          />
        </div>
      )}
      {hasLongDescription && (
        <Button
          className={classNames(`${rootClass}__show-more`, { [`${rootClass}__show-more-expanded`]: showMore })}
          buttonType={ButtonType.TEXT_TEAL}
          onClick={() => setState(({ showMore, ...state }) => ({ ...state, showMore: !showMore }))}
        >
          <Typography
            lineHeight={LineHeight.EXTRA_TINY}
            text={t(showMore ? 'Show less' : 'Show more')}
            type={TextType.NORMAL_TEXT_TEAL}
            weight={TextWeight.MEDIUM}
          />
        </Button>
      )}
    </InfoHoverCard>
  )
}

export default ListProgramInfoHover
