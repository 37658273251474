import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'

import './AssetPickerInfo.css'

interface AssetPickerPreviewProps {
  className?: string
  dataTest?: string
  children: ReactNode
  title: string
}

const rootClass = 'asset-picker-info'

const AssetPickerInfo: FC<AssetPickerPreviewProps> = (props) => {
  const { dataTest = rootClass, className = '', children, title } = props

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Typography text={title} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.MEDIUM} lineHeight={LineHeight.MEDIUM_LARGE} />
      {children}
    </div>
  )
}

export default AssetPickerInfo
