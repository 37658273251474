import { createContext } from 'react'

import { KeyValue } from '@components/KeyValuePicker/KeyValuePicker'
import { FieldDefinition, Program, StringKeyValue } from '@graphql/types/query-types'
import { editCRMStepV2InitialState } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.constants'
import { EditCRMStepV2EntityStep } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.interfaces'
import { DeepUpdateState } from '@utils/types'

export interface EditCRMStepV2State {
  entities: KeyValue[]
  entitiesReferences: Map<string, StringKeyValue[]>
  fieldDefinitionsByEntity: Map<string, FieldDefinition[]>
  loadingInitialValues: boolean
  loadingReferences: boolean
  recordTypes: KeyValue[]
  showSteps: boolean
  stepToRemove?: EditCRMStepV2EntityStep
  steps: EditCRMStepV2EntityStep[]
  showErrorBanner: boolean
}

export interface EditCRMStepV2API {
  values: EditCRMStepV2State
  update: DeepUpdateState<EditCRMStepV2State>
  removeStep: (step: EditCRMStepV2EntityStep) => void
  program: Program
}

export const EditCRMStepV2Context = createContext<EditCRMStepV2API>({ values: editCRMStepV2InitialState } as any)
