import React, { FC, useCallback, useRef, useState } from 'react'

import {
  AdvancedImportOptions,
  AdvancedImportOptionsProps,
} from '@complex/ImportContacts/ImportOptionsScreen/components/AdvancedImportOptions/AdvancedImportOptions'
import ScheduleSelector from '@complex/ScheduleSelector/ScheduleSelector'
import ButtonWithLoader from '@components/ButtonWithLoader/ButtonWithLoader'
import Checkbox from '@components/Checkbox'
import Container from '@components/Container'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2Option, SelectV2Props, SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { SvgNames } from '@components/Svg'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ProgramSchedule } from '@graphql/types/query-types'
import { ItemType } from '@utils/categorization'

import './ImportOptionsScreen.css'

const rootClass = 'import-options-screen'

export type ImportOptionsScreenProps = AdvancedImportOptionsProps & {
  addToSegment: boolean
  schedule?: ProgramSchedule
  segmentOptions?: SelectV2SingleOption[]
  isFileSelected?: boolean
  validSegmentName: boolean
  hasAdvancedOptions?: boolean
  isAValidSegmentName: (segmentName: string) => Promise<boolean>
  createSegment: (segmentName: string) => void
  getSegmentOptions: (currentPage?: number) => Promise<SelectV2Option[]>
  onSegmentSelectionChange: (newSelectedOption?: SelectV2SingleOption) => void
  onSchedule?: (newSchedule: ProgramSchedule) => void
  onAddToSegmentChange: (checked: boolean) => void
  onNextClick: () => void
}

const ImportOptionsScreen: FC<ImportOptionsScreenProps> = (props: ImportOptionsScreenProps) => {
  const {
    schedule,
    isFileSelected,
    addToSegment,
    segmentSelectedOption,
    validSegmentName,
    showObjectTypeError = false,
    showExternalIdMatchError = false,
    segmentOptions,
    mergeStrategy,
    objectType,
    externalIdMatch,
    loadingMappingScreen,
    contactPreference,
    fieldQuotes,
    firstLineContains,
    fieldsSeparator,
    previewHeadersOptions,
    updateAction,
    mergeRule,
    useCustomPrefix,
    removeEntriesFromList,
    rejectRules,
    showAdvancedOptions,
    hasAdvancedOptions = true,
    isAValidSegmentName,
    createSegment,
    getSegmentOptions,
    onObjectTypeChange,
    onChangeWithFirstRowsUpdate,
    onMergeRuleChange,
    onContactPreferenceChange,
    onUpdateActionChange,
    onMergeStrategyChange,
    onRemoveEntriesChange,
    onRejectRulesChange,
    onUseCustomPrefixChange,
    onSegmentSelectionChange,
    onSchedule,
    onNextClick,
    onAddToSegmentChange,
    onShowAdvancedOptionsChange,
  } = props

  const { t } = useTranslation()
  const [hasError, setHasError] = useState(!validSegmentName)

  const tempScheduleRef = useRef(schedule)

  const canMoveForward =
    isFileSelected && (!addToSegment || (segmentSelectedOption && validSegmentName)) && !showObjectTypeError && !showExternalIdMatchError

  const onCreateNewSegment = useCallback(
    async (inputValue: string) => {
      const trimmedValue = inputValue.trim()
      const valid = await isAValidSegmentName(trimmedValue)
      valid && createSegment(trimmedValue)
    },
    [createSegment, isAValidSegmentName]
  )

  const selectV2Props: SelectV2Props = {
    isAsync: true,
    isClearable: true,
    isSearchable: true,
    isDisabled: !addToSegment,
    maxMenuHeight: 250,
    itemType: ItemType.SEGMENT,
    placeholder: 'Select or create a segment',
    inputIcon: SvgNames.listsAndSegments,
    defaultValue: segmentSelectedOption,
    options: segmentOptions,
    error: hasError,
    errorMessage: hasError ? 'This segment name is already in use' : undefined,
    loadNewOptions: (nextPage) => getSegmentOptions(nextPage),
    onCreate: onCreateNewSegment,
    onChange: onSegmentSelectionChange,
    onInputChange: () => setHasError(false),
  }

  const advancedImportOptionsProps: AdvancedImportOptionsProps = {
    firstLineContains,
    contactPreference,
    fieldsSeparator,
    fieldQuotes,
    externalIdMatch,
    showExternalIdMatchError,
    mergeStrategy,
    previewHeadersOptions,
    updateAction,
    mergeRule,
    loadingMappingScreen,
    useCustomPrefix,
    segmentSelectedOption,
    removeEntriesFromList,
    rejectRules,
    showObjectTypeError,
    objectType,
    showAdvancedOptions,
    onChangeWithFirstRowsUpdate,
    onMergeRuleChange,
    onContactPreferenceChange,
    onUpdateActionChange,
    onMergeStrategyChange,
    onRemoveEntriesChange,
    onRejectRulesChange,
    onUseCustomPrefixChange,
    onObjectTypeChange,
    onShowAdvancedOptionsChange,
  }

  const onScheduleChange = useCallback(
    (newSchedule: ProgramSchedule) => {
      const isUnscheduled = newSchedule.type === 'NEVER'
      const recoveringSchedule = schedule?.type === 'NEVER' && tempScheduleRef.current?.type !== 'NEVER'
      if (isUnscheduled) {
        tempScheduleRef.current = schedule
      }
      onSchedule &&
        onSchedule({
          ...(isUnscheduled || !recoveringSchedule ? newSchedule : tempScheduleRef.current),
          isScheduled: !isUnscheduled,
        })
    },
    [schedule]
  )

  return (
    <div className={rootClass}>
      <Container title={'Import options'} description={t('ImportContactsV2.ImportOptions.Subtitle')}>
        <div className={`${rootClass}__segment-rules`}>
          <Typography text={t('Segment rules')} className={`${rootClass}__segment-rules-label`} weight={TextWeight.MEDIUM} />
          <div className={`${rootClass}__segment-rules-content`}>
            <Checkbox label={t('Add records to a segment')} checked={addToSegment} onChange={onAddToSegmentChange} />
            <SelectV2 className={`${rootClass}__select`} {...selectV2Props} key={`${selectV2Props.options?.length}-${selectV2Props.defaultValue}`} />
          </div>
        </div>
        {schedule && (
          <>
            <div className={`${rootClass}__divider`} />
            <div className={`${rootClass}__segment-rules`}>
              <Typography
                text={t('ImportContactsV2.ImportOptions.SyncOptions')}
                className={`${rootClass}__segment-rules-label`}
                weight={TextWeight.MEDIUM}
              />
              <ScheduleSelector
                defaultValue={schedule}
                onChange={onScheduleChange}
                manualLabel={t('Off')}
                manualScheduleTooltip={{ show: false }}
                showHeader={false}
              />
            </div>
          </>
        )}
      </Container>
      {hasAdvancedOptions && <AdvancedImportOptions {...advancedImportOptionsProps} />}
      <div className={`${rootClass}__policy`}>
        <Typography
          text={t('ImportContactsV2.ImportOptions.Policy')}
          tagComponents={{
            TextLink: (
              <TextLink
                className={`${rootClass}__policy-link`}
                link={'https://act-on.com/wp-content/uploads/2023/04/Acceptable-Use-Policy_CLEAN_25-APR-2023.docx.pdf'}
                weight={TextWeight.MEDIUM}
                size={TextLinkSize.LARGE}
                hideIcon
              />
            ),
          }}
          inline
        />
      </div>
      <div className={`${rootClass}__button-next`}>
        <ButtonWithLoader loading={loadingMappingScreen} disabled={!canMoveForward} onClick={onNextClick}>
          {t('Next')}
        </ButtonWithLoader>
      </div>
    </div>
  )
}

export default ImportOptionsScreen
