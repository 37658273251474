import { SvgNames } from '@components/Svg'

export type CampaignPanelFields = {
  title: string
  content: React.JSX.Element
  icon: SvgNames
}
interface GetComputedCampaignPanelParams {
  hideInternalTracking?: boolean
  isEmailTemplate?: boolean
  subscriptionManagementEnabled: boolean
  subscriptionManagementIsLive: boolean
  campaignPanel: CampaignPanelFields
}

export const getSidebarStatusIcon = (conditions: boolean[], errorCondition?: boolean) => {
  let result: SvgNames

  switch (true) {
    case errorCondition:
      result = SvgNames.spamCheckWarning
      break

    case conditions.every((value) => value):
      result = SvgNames.checkboxTeal
      break

    case conditions.some((value) => value):
      result = SvgNames.circleIndeterminate
      break

    default:
      result = SvgNames.spamCheckRest
      break
  }

  return result
}
export const getComputedPanel = ({
  hideInternalTracking,
  isEmailTemplate,
  subscriptionManagementEnabled,
  subscriptionManagementIsLive,
  campaignPanel,
}: GetComputedCampaignPanelParams): CampaignPanelFields[] => {
  let computedPanel: CampaignPanelFields[]

  switch (true) {
    case hideInternalTracking:
      computedPanel = []
      break

    case !isEmailTemplate:
      computedPanel = [campaignPanel]
      break

    case subscriptionManagementEnabled && subscriptionManagementIsLive:
      computedPanel = [campaignPanel]
      break

    default:
      computedPanel = []
      break
  }

  return computedPanel
}
