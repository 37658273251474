import { Dispatch, SetStateAction } from 'react'

import { CustomSourceItems, ExtendedItemDto } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { parseItemDtoResult } from '@complex/ListingPage/Utils/ListingPage.utils'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { Status } from '@components/StatusToast/StatusToast'
import { SyncedSegment, SyncedSegmentEntityFactor, SyncedSegmentQueryFactor } from '@graphql/types/microservice/crm-types'
import {
  AddContactsFromCRMContainerState,
  RADIO_OPTION,
  SourceIdentifiersType,
  SourcedSegmentDefaultValues,
  Update,
} from '@src/pages/ContactSegments/components/AddContactsFromCRM/AddContactsFromCRMContext'
import { CRMSourceOption, getSourceTitle } from '@utils/crm.utils'

interface StatusToastInfoParams {
  statusMessage: string
  update: Update
}

interface StatusToastParams extends StatusToastInfoParams {
  status: Status
}

export const showFailedStatusToast = (statusToastInfoParams: StatusToastInfoParams) =>
  showStatusToast({ ...statusToastInfoParams, status: Status.FAIL })

const showStatusToast = ({ update, ...statusToast }: StatusToastParams) =>
  update({
    statusToast,
  })

export const convertToExtendedItemDto = (id: string, name: string) =>
  ({
    name,
    id,
    labels: [],
    position: 0,
    isFavorite: false,
    item: JSON.stringify({ name }),
  } as ExtendedItemDto)

export const getSourcesFromEntityFactors = (factors: SyncedSegmentEntityFactor[]) =>
  factors.map(({ label, segmentCriteriaIdentifier }) => convertToExtendedItemDto(segmentCriteriaIdentifier as string, label as string))

export const getSourcesFromQueryFactors = (factors: SyncedSegmentQueryFactor[]) =>
  factors.map(({ label, queryIdentifier }) => convertToExtendedItemDto(queryIdentifier as string, label as string))

export const discardChanges = (getSegmentDetail: Function, options: SelectV2SingleOption[], segmentId: string, update: Update) => {
  !!segmentId
    ? getSegmentDetail(true, segmentId)
    : update({ isOngoingSync: false, selectedSourcedSegment: undefined, sources: {}, pickedSources: {} })
  update({
    isDirty: false,
    radioOption: !!segmentId ? RADIO_OPTION.EDIT : RADIO_OPTION.CREATE,
    segmentName: '',
    selectedOption: options.find(({ value }) => value === segmentId),
  })
}

export const removeCRMSource = (source: ExtendedItemDto, setState: Dispatch<SetStateAction<AddContactsFromCRMContainerState>>) =>
  setState((state) => {
    const { pickedSources, selectedSourcedSegment, sources } = state
    const pickedSourcesKey = Object.keys(pickedSources).find((key) => pickedSources[key].some(({ id }) => id === source.id)) as string
    let updatedOriginalSources = {}
    if (!!selectedSourcedSegment) {
      const { originalSources } = selectedSourcedSegment
      const selectedSourcedSegmentKey = Object.keys(originalSources).find((key) => originalSources[key].some(({ id }) => id === source.id)) as string
      updatedOriginalSources = !!selectedSourcedSegmentKey
        ? { ...originalSources, [selectedSourcedSegmentKey]: originalSources[selectedSourcedSegmentKey].filter(({ id }) => id != source.id) }
        : originalSources
    }

    const key = Object.keys(sources).find((key) => sources[key].some(({ id }) => id === source.id)) as string
    return {
      ...state,
      pickedSources: !!pickedSourcesKey
        ? { ...pickedSources, [pickedSourcesKey]: pickedSources[pickedSourcesKey].filter(({ id }) => id != source.id) }
        : pickedSources,
      selectedSourcedSegment: !!selectedSourcedSegment
        ? {
            ...selectedSourcedSegment,
            originalSources: updatedOriginalSources,
          }
        : undefined,
      sources: !!key ? { ...sources, [key]: sources[key].filter(({ id }) => id != source.id) } : sources,
    }
  })

export const setSegmentName = (isAValidSegmentName: Function, sources: CustomSourceItems, setState: Function) => {
  const itemValues = Object.values(sources ?? {})
  let firstSourceName = ''
  if (!!itemValues?.length && !!itemValues[0]?.length) {
    firstSourceName = itemValues[0][0].name as string
  }

  setState((state: AddContactsFromCRMContainerState) => ({ ...state, segmentName: !!state.segmentName ? state.segmentName : firstSourceName }))
  if (!!firstSourceName) {
    isAValidSegmentName(firstSourceName)
  }
}

export const validateSourcesOnChange = async (
  validateSources: Function,
  sources: CustomSourceItems,
  update: Update,
  crmSourceOptions: CRMSourceOption[]
) => {
  const sourceIdentifiers = Object.entries(sources).reduce(
    (total: SourceIdentifiersType, source) => [
      ...total,
      { [crmSourceOptions.find(({ title }) => title === source[0])?.name ?? '']: source[1].map(({ id }) => id) },
    ],
    []
  )
  if (!!sourceIdentifiers.length) {
    await sourceIdentifiers.forEach(async (source, index) => {
      const entries = Object.entries(source)[0]
      await validateSources(entries[0], entries[1])
      index === sourceIdentifiers.length - 1 && update({ validatingSources: false })
    })
  } else {
    update({ validatingSources: false })
  }
}

export const getInitialSourcesValuesUtils = (
  optionsDetails: SyncedSegment[],
  selectedSourcedSegment: SourcedSegmentDefaultValues,
  crmSourceOptions: CRMSourceOption[]
) => {
  const initialSegment = optionsDetails.find(
    (details) => details?.segmentIdentifier === selectedSourcedSegment?.segmentIdentifier
  ) as SourcedSegmentDefaultValues
  const { sourceType, syncedSegmentEntityFactors, syncedSegmentQueryFactors } = initialSegment
  const crmSourceType = getSourceTitle(sourceType ?? '', crmSourceOptions)
  const initialSources: CustomSourceItems = {
    [crmSourceType]: parseItemDtoResult(
      !!syncedSegmentEntityFactors
        ? getSourcesFromEntityFactors(syncedSegmentEntityFactors as SyncedSegmentEntityFactor[])
        : getSourcesFromQueryFactors(syncedSegmentQueryFactors as SyncedSegmentQueryFactor[])
    ),
  }
  const initialSegmentState = { ...initialSegment, originalSources: initialSources }

  return Object.values(initialSegmentState?.originalSources ?? {})
    .filter((sources) => !!sources.length)
    .flatMap((items) => items)
}
