import React, { FC } from 'react'

import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { AddressResponse } from '@graphql/types/query-types'
import { addressToString } from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Company/components/AddressesList/AddressesList'
import CompanyInfoSection from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Company/components/CompanyInfoSection/CompanyInfoSection'
import { CAN_SPAM_ACT_LINK } from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Company/constants/company.constants'

interface Props {
  defaultAddress: AddressResponse
  onEdit: (address: AddressResponse) => void
}

const DefaultAddress: FC<Props> = (props: Props) => {
  const { defaultAddress, onEdit } = props
  const { t } = useTranslation()

  return (
    <CompanyInfoSection
      title={t('Account addresses')}
      required
      description={
        <>
          <Typography text={t('Company.Account.Default.Address.1stSentence')} inline />
          <TextLink text={t('CAN-SPAM Act')} link={CAN_SPAM_ACT_LINK} hideIcon size={TextLinkSize.LARGE} />
          .&nbsp;
          <Typography text={t('Company.Account.Default.Address.2ndSentence')} inline />
        </>
      }
      value={addressToString(defaultAddress)}
      onEdit={() => onEdit(defaultAddress)}
      dataTest="company-default-address__section"
    />
  )
}

export default DefaultAddress
