import React, { FC } from 'react'

import Button, { ButtonType } from '@components/Button'
import Container from '@components/Container'
import PageContainer from '@components/PageContainer'
import PageHeader from '@components/PageHeader'
import PageHeadline from '@components/PageHeadline'
import { PageHeaderType } from '@components/PageHeadline/PageHeadline'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import StaticImage from '@components/StaticImage/StaticImage'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './InitialMessage.css'

interface Props {
  showEditor: () => void
}

const rootClass = 'initial-message-splash'

const InitialMessageSplash: FC<Props> = ({ showEditor }: Props) => {
  const { t } = useTranslation()

  return (
    <PageContainer className={rootClass}>
      <PositionContainer>
        <PageHeader primaryText={t('SMS Opt-In Confirmation')} leftContent dataTest={rootClass} />
        <Container data-test={rootClass}>
          <div className={`${rootClass}__image`}>
            <StaticImage name={StaticImageNames.emptySmsOptInConfirmation} altText={t('Send a confirmation SMS to previously opted-in contacts')} />
          </div>
          <PageHeadline type={PageHeaderType.LARGE_HEADER}>{t('Send Your First SMS Opt-In Message!')}</PageHeadline>
          <div className={`${rootClass}__info-ctr`}>
            <div className={`${rootClass}__info`}>
              <Typography
                text={t(
                  'Start by sending a message to all SMS Recipients to inform them they have Opted In. Then, you can create a Welcome Message that will automatically send to new Contacts as they Opt-In to SMS.'
                )}
                lineHeight={LineHeight.MEDIUM}
                type={TextType.BODY_TEXT_LIGHT}
              />
            </div>
            <div className={`${rootClass}__action`}>
              <Button buttonType={ButtonType.PRIMARY} onClick={() => showEditor()}>
                {t(`Set Up Now`)}
              </Button>
            </div>
          </div>
        </Container>
      </PositionContainer>
    </PageContainer>
  )
}

export default InitialMessageSplash
