import { AnyAction } from 'redux'
import { DebouncedState } from 'use-debounce/lib/useDebouncedCallback'

import { SvgNames } from '@components/Svg'

import {
  CommonInputProps,
  GetTooltipTextProps,
  HandleOnChange,
  InitialInputState,
  InputProps,
  InputReducerType,
  OnInputFocusProps,
  RefreshStatusProps,
} from './InputTS/interface'
import { ClipboardCopy, PickHandleChange, StatusProps } from './InputTS/type'
import { DEFAULT_MESSAGE_KEY } from './InputV2'

export const onClearHandler = (dispatch?: React.Dispatch<AnyAction>, onClear?: InputProps['onClear']) => {
  dispatch?.(updateInputReducer('SHOW_CLEAR_BUTTON', false))
  dispatch?.(updateInputReducer('CURRENT_VALUE', ''))

  onClear?.()
}

export const onKeyDownHandlerSVG = (event: React.KeyboardEvent<SVGSVGElement>) => {
  if (event.keyCode === 32) {
    onClearHandler()
  }
}

const getErrorKey = async (validityFunctions: StatusProps['validityFunctions'], value?: string) => {
  let error

  if (validityFunctions) {
    for (const f of validityFunctions) {
      error = await f(value)
    }
  }

  return error ? error.errorMessageKey : ''
}

export const setIcon = (dispatch: (value: AnyAction) => void, isValid?: boolean, showIconOnValid?: boolean, value?: string, error?: boolean) => {
  if (!isValid) {
    dispatch(updateInputReducer('WITH_STATUS', { activeIcon: SvgNames.inputStatusInvalid }))
    return
  }
  if (!value?.length && !error) {
    dispatch(updateInputReducer('WITH_STATUS', { activeIcon: SvgNames.inputStatusActive }))
    return
  }

  if (isValid && showIconOnValid) {
    dispatch(updateInputReducer('WITH_STATUS', { activeIcon: SvgNames.inputStatusSuccess }))
    return
  }

  if (isValid === undefined && showIconOnValid) {
    dispatch(updateInputReducer('WITH_STATUS', { activeIcon: SvgNames.inputStatusActive }))
    return
  }
}

export const cancelDelayedValidation = (
  showErrorOnChange: StatusProps['showErrorOnChange'],
  errorValidationDelayed: DebouncedState<(errorKey: string) => void>,
  validationDelayed: DebouncedState<(isLoading: boolean, isValid?: boolean, errorKey?: string) => void>
) => {
  showErrorOnChange && errorValidationDelayed.cancel()
  validationDelayed.cancel()
}

const errorValidation = async ({
  value,
  showErrorOnChange,
  validationDelayed,
  validityFunctions,
  errorValidationDelayed,
  dispatch,
}: CommonInputProps) => {
  cancelDelayedValidation(showErrorOnChange, errorValidationDelayed, validationDelayed)
  dispatch(updateInputReducer('WITH_STATUS', { loading: true, activeIcon: undefined }))

  const errorKey = (await getErrorKey(validityFunctions, value)) ?? ''
  errorValidationDelayed(errorKey)
}

export const refreshStatus = async ({
  event,
  value,
  error,
  isTyping,
  required,
  validityFunctions,
  showErrorOnChange,
  validationDelayed,
  errorValidationDelayed,
  dispatch,
}: RefreshStatusProps) => {
  const inputValue = event?.target.value || value
  const errorKey = (await getErrorKey(validityFunctions, inputValue)) ?? ''
  dispatch(updateInputReducer('WITH_STATUS', { loading: true }))

  if (showErrorOnChange && !!errorKey) {
    dispatch(updateInputReducer('WITH_STATUS', { errorKey }))

    errorValidation({
      required,
      value: inputValue,
      showErrorOnChange,
      validationDelayed,
      validityFunctions,
      errorValidationDelayed,
      dispatch,
    })
  } else {
    isTyping && !error && dispatch(updateInputReducer('WITH_STATUS', { loading: true, isTouched: true }))
    dispatch(updateInputReducer('WITH_STATUS', { activeIcon: undefined, errorKey }))
    validationDelayed(false, !errorKey, errorKey)
  }
}

export const getStatusTooltipText = ({ t, errorKey, customTooltipErrorMessages, error, tooltipErrorMessages }: GetTooltipTextProps) => {
  const customMessage = typeof customTooltipErrorMessages === 'string' ? t(customTooltipErrorMessages) : customTooltipErrorMessages
  const key = errorKey || DEFAULT_MESSAGE_KEY
  const message =
    errorKey == DEFAULT_MESSAGE_KEY
      ? 'Provide a valid value'
      : typeof tooltipErrorMessages?.[key] === 'string'
      ? t(tooltipErrorMessages[key] as string)
      : tooltipErrorMessages?.[key]

  return error && (customMessage || message)
}

const handleInputChangesWithStatus = async ({
  value,
  error,
  required,
  showStatus,
  showErrorOnChange,
  validationDelayed,
  validityFunctions,
  errorValidationDelayed,
  dispatch,
}: CommonInputProps) => {
  if (!showStatus) {
    return
  }

  cancelDelayedValidation(showErrorOnChange, errorValidationDelayed, validationDelayed)
  const invalid = validityFunctions?.some((f) => !!f(value))

  if (!invalid && !error) {
    dispatch(updateInputReducer('WITH_STATUS', { activeIcon: undefined, loading: true }))

    validationDelayed(false, true)
  } else if (showErrorOnChange) {
    errorValidation({
      value,
      required,
      showErrorOnChange,
      validationDelayed,
      validityFunctions,
      errorValidationDelayed,
      dispatch,
    })
  } else {
    validationDelayed(false, undefined, await getErrorKey(validityFunctions, value))
  }
}

const handleChange = ({ trim, event, onChangeDebounce, debouncedOnChange, onChange, dispatch }: Pick<HandleOnChange, PickHandleChange>) => {
  const eventValue = event.target.value
  const newValue = trim ? eventValue.trim() : eventValue

  dispatch(updateInputReducer('CURRENT_VALUE', newValue))

  if (onChangeDebounce && onChange) {
    debouncedOnChange({ ...event })
  } else if (onChange) {
    onChange(event)
  }
}

export const handleOnChange = ({
  trim,
  event,
  error,
  required,
  hasClear,
  showStatus,
  onChangeDebounce,
  debouncedOnChange,
  showErrorOnChange,
  validationDelayed,
  validityFunctions,
  hasValidationStatus,
  onlyValidateOnBlur,
  errorValidationDelayed,
  onChange,
  dispatch,
}: Omit<HandleOnChange, 'value'>): void => {
  if (hasValidationStatus && !onlyValidateOnBlur) {
    handleInputChangesWithStatus({
      error,
      required,
      showStatus,
      showErrorOnChange,
      validationDelayed,
      validityFunctions,
      errorValidationDelayed,
      value: event?.target.value,
      dispatch,
    })
  }

  if (hasClear || (hasValidationStatus && !onlyValidateOnBlur)) {
    dispatch(updateInputReducer('IS_TYPING', true))
    hasValidationStatus && dispatch(updateInputReducer('WITH_STATUS', { loading: true }))
  }

  if (hasClear) {
    dispatch(updateInputReducer('SHOW_CLEAR_BUTTON', event?.target.value !== ''))
  }

  handleChange({ trim, event, debouncedOnChange, onChangeDebounce, onChange, dispatch })
}

export const onInputFocus = ({
  event,
  value,
  error,
  required,
  showStatus,
  statusVisible,
  showErrorOnChange,
  validityFunctions,
  validationDelayed,
  hasValidationStatus,
  errorValidationDelayed,
  onFocus,
  dispatch,
}: OnInputFocusProps) => {
  if (hasValidationStatus) {
    if (!statusVisible) {
      return
    }

    if (validityFunctions?.some((f) => !!f(value))) {
      const showError = showErrorOnChange

      dispatch(
        updateInputReducer('WITH_STATUS', {
          activeIcon: showError ? SvgNames.inputStatusInvalid : SvgNames.inputStatusActive,
          isValid: showError ? false : undefined,
        })
      )

      return
    }

    if (!error) {
      handleInputChangesWithStatus({
        value,
        error,
        required,
        showStatus,
        showErrorOnChange,
        validationDelayed,
        validityFunctions,
        errorValidationDelayed,
        dispatch,
      })
    }
  }

  onFocus?.(event)
}

export const updateInputReducer = (
  type: InputReducerType,
  payload: string | boolean | undefined | ClipboardCopy | { [k in keyof Partial<InitialInputState>]: boolean | string | undefined }
) => ({ type, payload })

export const inputReducer = (state: InitialInputState, action: AnyAction) => {
  const { type, payload } = action

  switch (type) {
    case 'IS_TYPING':
      return { ...state, isTyping: payload }
    case 'SHOW_PASSWORD':
      return { ...state, showPassword: payload }
    case 'PASSWORD_VALIDATION_ERROR':
      return { ...state, passwordValidationError: payload }
    case 'WITH_STATUS':
      return { ...state, ...payload }
    case 'SHOW_CLEAR_BUTTON':
      return { ...state, showClearBtn: payload }
    case 'CURRENT_VALUE':
      return { ...state, currentValue: payload }
    case 'COPY_TO_CLIPBOARD_STATUS':
      return { ...state, copyToClipBoardStatus: payload }

    default:
      return state
  }
}
