import React, { ReactNode } from 'react'
import { components, MenuProps } from 'react-select'

import { SelectV2Option } from '@components/SelectV2/SelectV2.props'

const rootClass = 'custom-menu-list'

const CustomMenu = (props: MenuProps<SelectV2Option> & { renderMenuAction?: () => ReactNode }) => {
  const {
    children,
    selectProps: { menuActionRef, renderMenuAction },
  } = props

  return (
    <div className={rootClass}>
      <components.Menu {...props}>
        {children}
        {renderMenuAction && <div ref={menuActionRef}>{renderMenuAction()}</div>}
      </components.Menu>
    </div>
  )
}

export default CustomMenu
