import React from 'react'

import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { ColWithTooltip } from '@components/TableV2/utils/tableV2ColumnUtils'
import { RecordError } from '@graphql/types/microservice/crm-sync-types'

export const syncErrorsUtilsColumn: ColumnDefWithAdditionalProps<RecordError>[] = [
  {
    header: 'FIRST NAME',
    accessorKey: 'firstName',
    textAlign: 'left',
    minSize: 450,
    sortingFn: 'caseInsensitive',
    cell: (cell) => <ColWithTooltip cell={cell} />,
  },
  {
    header: 'EMAIL ADDRESS',
    accessorKey: 'email',
    textAlign: 'left',
    minSize: 250,
    sortingFn: 'caseInsensitive',
    cell: (cell) => <ColWithTooltip cell={cell} />,
  },
  {
    header: 'ERROR',
    accessorKey: 'error',
    textAlign: 'left',
    sortingFn: 'caseInsensitive',
    cell: (cell) => <ColWithTooltip cell={cell} />,
  },
]
