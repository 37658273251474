import React, { FC, ReactNode, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { ButtonWeight } from '@components/Button/Button'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './EditableContainer.css'

interface EditableContainerProps {
  className?: string
  dataTest?: string
  onSave: VoidFunction
  onCancel?: VoidFunction
  onReset?: VoidFunction
  children: (isEditing: boolean) => ReactNode
  saveDisabled?: boolean
  resetDisabled?: boolean
  fullWidth?: boolean
}

const rootClass = 'editable-container'

const EditableContainer: FC<EditableContainerProps> = (props: EditableContainerProps) => {
  const { dataTest = rootClass, className = '', onSave, onReset, onCancel, children, saveDisabled, resetDisabled, fullWidth } = props

  const [isEditing, setIsEditing] = useState(false)

  const { t } = useTranslation()

  const onCancelClick = () => {
    setIsEditing(false)
    onCancel && onCancel()
  }

  const onSaveClick = () => {
    if (!saveDisabled) {
      setIsEditing(false)
      onSave()
    }
  }

  const onResetClick = () => {
    if (!resetDisabled) {
      setIsEditing(false)
      onReset && onReset()
    }
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={classNames(`${rootClass}__top`, { [`${rootClass}__top-full-width`]: fullWidth })}>
        <div className={`${className}-container`}>{children(isEditing)}</div>
        {isEditing ? (
          <div className={`${rootClass}__label`}>
            <Svg name={SvgNames.pencil} type={SvgType.ICON} />
            <Typography text={t('Editing')} type={TextType.BODY_TEXT_LIGHT} inline />
          </div>
        ) : (
          <Button
            dataTest={`${dataTest}-edit-button`}
            className={`${rootClass}__top-edit-button`}
            buttonType={ButtonType.FLOAT_TEAL}
            onClick={() => setIsEditing(true)}
            weight={ButtonWeight.MEDIUM}
          >
            {t('Edit')}
          </Button>
        )}
      </div>
      {isEditing && (
        <div className={`${rootClass}__bottom`}>
          <Button buttonType={ButtonType.PRIMARY} onClick={onSaveClick} disabled={saveDisabled}>
            {t('Save')}
          </Button>
          <Button buttonType={onReset ? ButtonType.SECONDARY : ButtonType.TERTIARY} onClick={onCancelClick}>
            {t('Cancel')}
          </Button>
          {onReset && (
            <Button buttonType={ButtonType.TEXT} onClick={onResetClick} disabled={resetDisabled}>
              {t('Reset to defaults')}
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

export default EditableContainer
