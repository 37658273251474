import getItemsByExternalIds from '@graphql/microservices/categorization/getItemsByExternalIds'
import getAssetAudits from '@graphql/microservices/data-lake-query/getAssetAudits'
import { GetItemsByExternalIdsQuery, GetItemsByExternalIdsQueryVariables } from '@graphql/types/microservice/categorization-types'
import { GetAssetAuditsQuery, GetAssetAuditsQueryVariables } from '@graphql/types/microservice/data-lake-query-types'
import { UpgradeItemType } from '@src/pages/UpgradeAssistant/UpgradeAssistant.contants'
import { ItemType } from '@utils/categorization'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export interface UpgradeLogModalRequests {
  getUpgradeLogRequest: (type: UpgradeItemType, page: number, pageSize: number) => FetchPromise<GetAssetAuditsQuery>
  getItemsByExternalIdsResquest: (externalIds: string[], type: ItemType) => FetchPromise<GetItemsByExternalIdsQuery>
}
export const useUpgradeLogModalRequests = (): UpgradeLogModalRequests => {
  const { client: dataLakeClient } = useMicroserviceClient({ serviceName: MicroserviceClients.DATA_LAKE_QUERY })
  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })

  const getAssetTypes = (type: UpgradeItemType) => {
    const assetTypes: { [key in UpgradeItemType]: string[] } = {
      [UpgradeItemType.CONTACTS]: [''],
      [UpgradeItemType.SEGMENTS]: [''],
      [UpgradeItemType.PROGRAMS]: ['AUTOMATED_PROGRAM'],
      [UpgradeItemType.FORMS]: ['FORM', 'FORM_TEMPLATE'],
    }
    return assetTypes[type]
  }

  const getItemsByExternalIdsResquest = async (externalIds: string[], type: ItemType) => {
    return await categorizationClient.query<GetItemsByExternalIdsQuery, GetItemsByExternalIdsQueryVariables>({
      query: getItemsByExternalIds,
      fetchPolicy: 'network-only',
      variables: {
        externalIds,
        type,
        returnDeletedItems: true,
      },
    })
  }
  const getUpgradeLogRequest = (type: UpgradeItemType, page: number, pageSize: number) => {
    return dataLakeClient.query<GetAssetAuditsQuery, GetAssetAuditsQueryVariables>({
      query: getAssetAudits,
      fetchPolicy: 'network-only',
      variables: {
        assetTypes: getAssetTypes(type),
        orderBy: 'CREATED_TIME',
        direction: 'DESC',
        pageSize,
        page,
        events: ['UPGRADE', 'FAILED UPGRADE', 'REVERTED UPGRADE', 'FAILED REVERT UPGRADE'],
      },
    })
  }

  return {
    getUpgradeLogRequest,
    getItemsByExternalIdsResquest,
  }
}
