import React, { FC, useEffect, useMemo, useRef, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { Column, ColumnStatus, ColumnType } from '@components/ColumnsOrderModal/components/DraggableColumn/DraggableColumn'
import { dropColumn, hideColumn, isLocked, lockColumn } from '@components/ColumnsOrderModal/utils/ColumnsOrderModal.utils'
import DropDown from '@components/DropDown'
import { DropDownType } from '@components/DropDown/DropDown'
import EditColumnsListing, { EditColumnsAction } from '@components/EditColumnsDropdown/components/EditColumnsListing/EditColumnsListing'
import EditColumnsModal from '@components/EditColumnsDropdown/components/EditColumnsModal/EditColumnsModal'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'

import './EditColumnsDropdown.css'

interface ContactsColumnsOrderProps {
  className?: string
  dataTest?: string
  onSave: (columns: Column[], checked: boolean) => void
  columns: Column[]
  isOpen?: boolean
}

const rootClass = 'edit-columns-dropdown'

const EditColumnsDropdown: FC<ContactsColumnsOrderProps> = (props: ContactsColumnsOrderProps) => {
  const { dataTest = rootClass, className = '', onSave, columns = [], isOpen = false } = props

  const [showManageColumns, setShowManageColumns] = useState(false)
  const [orderedColumns, setOrderedColumns] = useState<Column[]>([])
  const [open, setOpen] = useState(false)

  const { t } = useTranslation()

  const isDirty = useRef(false)

  const lockedColumnsSize = useMemo(() => orderedColumns.filter((column) => isLocked(column)).length, [orderedColumns])

  const resetColumnsToDefault = () => {
    const orderedColumns = columns.sort((firstColumn, secondColumn) => (isLocked(secondColumn) ? 1 : isLocked(firstColumn) ? -1 : 0))
    setOrderedColumns(orderedColumns)
  }

  useEffect(() => {
    if (orderedColumns.length === 0 || (!isOpen && !open && !showManageColumns)) {
      resetColumnsToDefault()
    }
  }, [columns, isOpen, open, showManageColumns])

  const cancelHandler = () => {
    setOpen(false)
    isDirty.current = false
  }

  const saveHandler = () => {
    onSave(orderedColumns, true)
    isDirty.current = false
  }

  const onModalCancel = () => {
    cancelHandler()
    resetColumnsToDefault()
    setShowManageColumns(false)
  }

  const onModalSave = () => {
    saveHandler()
    setShowManageColumns(false)
  }

  const onReset = () => {
    const defaultColumns = columns
      .map((column) => ({ ...column, status: ColumnStatus.DEFAULT }))
      .sort(({ id: firstColumn }, { id: secondColumn }) => firstColumn - secondColumn)
    setOrderedColumns(defaultColumns)
    isDirty.current = true
  }

  const onDrop = (column: Column, droppedAt: number) => setOrderedColumns(dropColumn(column, orderedColumns, droppedAt))

  const onLock = (column: Column) => setOrderedColumns(lockColumn(column, orderedColumns, lockedColumnsSize))

  const onHide = (column: Column) => {
    if (column.newColumn) {
      const updatedColumns = orderedColumns.reduce((updatedColumns: Column[], { index, id, ...currentColumn }) => {
        const updatedColumn: Column = {
          ...currentColumn,
          index: id > column.id ? id - 1 : index,
          id: id > column.id ? id - 1 : id,
        }
        return id === column.id ? updatedColumns : [...updatedColumns, updatedColumn]
      }, [])
      setOrderedColumns(updatedColumns)
    } else {
      setOrderedColumns(hideColumn(column, orderedColumns))
    }
  }

  const onChangeListingHandler = (column: Column, action: EditColumnsAction, droppedAt = 0) => {
    isDirty.current = true
    switch (action) {
      case EditColumnsAction.DROP:
        onDrop(column, droppedAt)
        break
      case EditColumnsAction.PIN:
        onLock(column)
        break
      case EditColumnsAction.REMOVE:
        onHide(column)
        break
    }
  }

  const setColumn = (field: string, isAdded: boolean, columnsList: Column[], fieldMapping?: UnifiedListFieldMapping) => {
    const matchingColumnIndex = columnsList.findIndex(({ name }) => name === field)
    const matchingColumn = { ...columnsList[matchingColumnIndex], index: matchingColumnIndex }
    if (isAdded) {
      if (matchingColumnIndex === -1) {
        const newIndex = columnsList.filter(({ type }) => type === ColumnType.ACTON_CONTACTS).length
        const newColumn: Column = {
          name: field,
          id: newIndex,
          index: newIndex,
          status: ColumnStatus.DEFAULT,
          type: ColumnType.ACTON_CONTACTS,
          ...(fieldMapping
            ? {
                newColumn: {
                  columnId: fieldMapping.columnIndex,
                  hidden: fieldMapping.hidden,
                  standardName: fieldMapping.standardFieldKey ?? '',
                  label: fieldMapping.displayName,
                  readOnly: fieldMapping.readOnly,
                },
              }
            : {}),
        }
        const updatedOrderedColumns = columnsList.map(({ index, ...column }) => {
          const newId = index >= newIndex ? index + 1 : index
          return { ...column, index: newId, id: newId } as Column
        })
        return [...updatedOrderedColumns, newColumn]
      } else {
        if (matchingColumn.status === ColumnStatus.HIDDEN) {
          return hideColumn(matchingColumn, columnsList)
        }
      }
    } else {
      if (matchingColumn) {
        return hideColumn(matchingColumn, columnsList)
      }
    }
    return columnsList
  }

  const onSelectFields = (fields: { name: string; fieldMapping?: UnifiedListFieldMapping }[], isAdding: boolean) => {
    const updatedColumns = fields.reduce((columns: Column[], { name, fieldMapping }) => {
      return setColumn(name, isAdding, columns, fieldMapping)
    }, orderedColumns)
    setOrderedColumns(updatedColumns)
    isDirty.current = true
  }

  const renderTrigger = () => (
    <Tooltip
      trigger={
        <div className={`${rootClass}__trigger`}>
          <Svg name={SvgNames.editContactsColumns} type={SvgType.LARGE_ICON} />
          <div className={`${rootClass}__trigger-caret`}>
            <Svg name={SvgNames.caretDown} type={SvgType.ICON_MEDIUM} />
          </div>
        </div>
      }
    >
      {t(`Edit columns`)}
    </Tooltip>
  )

  return (
    <>
      <EditColumnsModal
        onSelectField={onSelectFields}
        onCancel={onModalCancel}
        onSave={onModalSave}
        onOrderChange={onChangeListingHandler}
        onReset={onReset}
        isOpen={showManageColumns}
        columns={orderedColumns}
        saveDisabled={!isDirty.current}
      />
      <DropDown
        dataTest={`${dataTest}-dropdown`}
        className={classNames(rootClass, className)}
        isOpen={open || isOpen}
        toggleOpen={setOpen}
        trigger={renderTrigger()}
        align={'end'}
        type={DropDownType.STYLED}
      >
        <EditColumnsListing className={`${rootClass}__listing`} columns={orderedColumns} onChange={onChangeListingHandler} />
        <div className={`${rootClass}__footer`}>
          <TextLink
            text={t(`Manage columns`)}
            onClick={() => {
              setShowManageColumns(true)
              setOpen(false)
            }}
            hideIcon
            size={TextLinkSize.LARGE}
          />
          <Button className={`${rootClass}__button-cancel`} buttonType={ButtonType.TERTIARY} onClick={cancelHandler}>
            {t('Cancel')}
          </Button>
          <Button dataTest={`${dataTest}__button-save`} buttonType={ButtonType.PRIMARY} onClick={saveHandler} disabled={!isDirty.current}>
            {t('Save')}
          </Button>
        </div>
      </DropDown>
    </>
  )
}

export default EditColumnsDropdown
