import React, { FC } from 'react'

import ImageMediaCard from '@components/ImageMediaCard/ImageMediaCard'
import Spinner, { LoaderSize } from '@components/Spinner/Spinner'
import { BeeTemplate } from '@graphql/types/microservice/email-management-types'
import { useTranslation } from '@utils/const/globals'

import { useTemplateCatalogModalContentContext } from '../../TemplateCatalogModal.context'

import './TemplateCatalogModalGridView.css'

const rootClass = 'template-catalog-modal-content__grid'

interface TemplateCatalogModalGridViewProps {
  dataTest?: string
  onSelect: (item: BeeTemplate) => void
}

const TemplateCatalogModalGridView: FC<TemplateCatalogModalGridViewProps> = ({ dataTest = rootClass, onSelect }) => {
  const { templatesLoading, catalogItems, selectedItem, onPreview } = useTemplateCatalogModalContentContext()
  const { t } = useTranslation()

  if (templatesLoading) {
    return <Spinner size={LoaderSize.MEDIUM} className={`${rootClass}__spinner`} dataTest={`${dataTest}-spinner`} />
  }

  return (
    <div className={rootClass} data-test={dataTest}>
      {catalogItems.map((item) => {
        const { title, thumbnail, id } = item
        return (
          <ImageMediaCard
            key={id}
            imageSrc={thumbnail ?? ''}
            title={title ?? ''}
            dataTest={`${dataTest}-card-${id}`}
            onSelect={() => onSelect(item)}
            onPreview={() => onPreview(item)}
            mainActionLabel={t('Select template')}
            isSelected={selectedItem?.id === id}
            imageFill
          />
        )
      })}
    </div>
  )
}

export default TemplateCatalogModalGridView
