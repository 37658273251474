import React, { FC } from 'react'

import classNames from 'classnames'

import Modal, { ModalBody } from '@components/Modal'
import { CheckCardStatus, CheckCardType } from '@src/pages/EmailComposer/components/common/CheckStatusCard/CheckStatusCard.utils'
import { CheckStatusCardContainerProps } from '@src/pages/EmailComposer/components/common/CheckStatusCard/CheckStatusCardContainer'
import { CheckStatusModalHeader } from '@src/pages/EmailComposer/components/common/CheckStatusCard/CheckStatusModal/components/CheckStatusModalHeader'
import { CheckStatusModalMessage } from '@src/pages/EmailComposer/components/common/CheckStatusCard/CheckStatusModal/components/CheckStatusModalMessage'
import { PersonalizationCheckErrors } from '@src/pages/EmailComposer/components/common/CheckStatusCard/CheckStatusModal/components/PersonalizationCheckResults/PersonalizationCheckErrors'
import { SpamCheckResults } from '@src/pages/EmailComposer/components/common/CheckStatusCard/CheckStatusModal/components/SpamCheckResults/SpamCheckResults'

import './CheckStatusModal.css'

export interface CheckStatusModalProps
  extends Pick<CheckStatusCardContainerProps, 'status' | 'type' | 'spamTests' | 'personalizationErrors' | 'issuesCount' | 'lastChecked'> {
  onCheck: VoidFunction
  onDone: VoidFunction
  className?: string
  dataTest?: string
}

const rootClass = 'check-status-modal'

export const CheckStatusModal: FC<CheckStatusModalProps> = ({
  onCheck,
  onDone,
  type = CheckCardType.SPAM,
  status = CheckCardStatus.DEFAULT,
  issuesCount = 0,
  spamTests,
  personalizationErrors,
  lastChecked,
  className,
  dataTest = rootClass,
}) => {
  const isSpam = type === CheckCardType.SPAM
  const isPersonalization = type === CheckCardType.PERSONALIZATION
  const isError = status === CheckCardStatus.FAILURE
  const noContent = status === CheckCardStatus.NO_CONTENT
  const loading = status === CheckCardStatus.LOADING

  const showMessage = loading || (isPersonalization && ((!isError && !personalizationErrors) || noContent))

  return (
    <Modal
      isOpen
      className={classNames(rootClass, className)}
      dataTest={dataTest}
      header={
        <CheckStatusModalHeader
          onCheck={onCheck}
          onDone={onDone}
          className={`${rootClass}__header`}
          dataTest={`${dataTest}-header`}
          lastChecked={lastChecked}
          type={type}
          status={status}
        />
      }
    >
      <ModalBody className={`${rootClass}__body`}>
        {showMessage ? (
          <CheckStatusModalMessage type={type} status={status} className={`${rootClass}__message`} />
        ) : isSpam ? (
          <SpamCheckResults status={status} issuesCount={issuesCount} results={spamTests} dataTest={`${dataTest}-results`} />
        ) : (
          <PersonalizationCheckErrors errors={personalizationErrors} dataTest={`${dataTest}-errors`} />
        )}
      </ModalBody>
    </Modal>
  )
}
