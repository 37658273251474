import React, { FC, useState } from 'react'

import classNames from 'classnames'

import { FormType } from '@complex/ListingPage/Utils/ListingPage.constants'
import Button, { ButtonType } from '@components/Button/Button'
import InfoHoverCard from '@components/InfoHoverCard/InfoHoverCard'
import InfoHoverHeader from '@components/InfoHoverHeader/InfoHoverHeader'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { getStandardFormattedDate } from '@utils/date'
import { titleCase } from '@utils/strings'

import './FormInfoHover.css'

interface FormInfoHoverProps {
  name: string
  formType?: FormType
  description: string
  submitsTo: string
  createdOn: number
  createdBy: string
  lastUpdated: number
  updatedBy: string
  className?: string
  dataTest?: string
}

const DESCRIPTION_EXPANDER_MIN_LENGTH = 100 // per discussion with design

const rootClass = 'form-info-hover'

const FormInfoHover: FC<FormInfoHoverProps> = (props: FormInfoHoverProps) => {
  const { name, description, formType, createdOn, createdBy, lastUpdated, updatedBy, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  const longDescription = description.length > DESCRIPTION_EXPANDER_MIN_LENGTH

  const toggleExpanded = () => setExpanded(!expanded)

  const header = (
    <InfoHoverHeader
      title={<Typography text={name} weight={TextWeight.MEDIUM} type={TextType.BODY_TEXT_WHITE} className={classNames({ ['ellip-clamp']: true })} />}
      subtitle={
        formType && (
          <Typography
            text={t('Forms.InfoHover.FormType', { formType: t(titleCase(formType)) })}
            type={TextType.BODY_TEXT_WHITE_SMALL}
            tagProps={{ bold: { weight: TextWeight.MEDIUM, inline: true } }}
          />
        )
      }
    />
  )

  const renderDescription = (
    <div className={`${rootClass}__body-section`}>
      <Typography text={t('Description')} type={TextType.TABLE_HEADER} className={`${rootClass}__body-section-title`} />
      <Typography
        text={expanded ? description : description.substring(0, DESCRIPTION_EXPANDER_MIN_LENGTH)}
        type={TextType.BODY_TEXT_SMALL}
        className={classNames(`${rootClass}__body-section-line`, {
          [`${rootClass}__description-long`]: longDescription,
          [`${rootClass}__description-long-extended`]: longDescription && expanded,
        })}
      />
      {longDescription && (
        <Button buttonType={ButtonType.TEXT_TEAL} className={`${rootClass}__show-link`} onClick={toggleExpanded}>
          <Typography text={`${t(expanded ? 'Show less' : 'Show more')}`} type={TextType.NORMAL_TEXT_TEAL} weight={TextWeight.MEDIUM} inline />
        </Button>
      )}
    </div>
  )

  const renderList = null
  /* Note: Removed due to lack of reliable data in categorization service, may be restored later
    (
    <div className={`${rootClass}__body-section`}>
      <Typography text={t('Forms.InfoHover.Submits')} type={TextType.TABLE_HEADER} className={`${rootClass}__body-section-title`} />
      <div className={`${rootClass}__body-section-line`}>
        <Svg name={SvgNames.contacts} type={SvgType.ICON} />
        <Typography text={submitsTo} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.BOLD} inline />
      </div>
    </div>
    )
  )*/

  const footer = (
    <div className={`${rootClass}__body-section`}>
      <Typography text={t('History')} type={TextType.TABLE_HEADER} className={`${rootClass}__body-section-title`} />
      <div className={classNames(`${rootClass}__footer-section-line`, `${rootClass}__body-section-line-first`, 'ellip')}>
        <Typography
          text={createdBy ? `Forms.InfoHover.Footer.Created` : `Forms.InfoHover.Footer.Created.NoName`}
          values={{ createdOn: getStandardFormattedDate(createdOn, true), createdBy }}
          tagProps={{ medium: { weight: TextWeight.MEDIUM } }}
          type={TextType.BODY_TEXT_SMALL}
          className={'ellip'}
          inline
        />
      </div>
      <div className={classNames(`${rootClass}__footer-section-line`, 'ellip')}>
        <Typography
          text={updatedBy ? `Forms.InfoHover.Footer.Updated` : `Forms.InfoHover.Footer.Updated.NoName`}
          values={{ lastUpdated: getStandardFormattedDate(lastUpdated, true), updatedBy }}
          tagProps={{ medium: { weight: TextWeight.MEDIUM } }}
          type={TextType.BODY_TEXT_SMALL}
          className={'ellip'}
          inline
        />
      </div>
    </div>
  )

  const body = (
    <div>
      {description && renderDescription}
      {renderList}
      {footer}
    </div>
  )

  return (
    <InfoHoverCard header={header} sideOffset={2} openDelay={350} closeDelay={150} className={classNames(rootClass, className)} data-test={dataTest}>
      {body}
    </InfoHoverCard>
  )
}

export default FormInfoHover
