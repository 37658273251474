import { gql } from '@apollo/client'

export default gql`
  query unifiedListFieldMappings {
    unifiedListFieldMappings {
      columnIndex
      dataType
      deleted
      displayName
      hidden
      ingestFieldMappings {
        format
        mapping
        objectType
        source
        sourceId
      }
      readOnly
      standardFieldKey
      typeMetaData
    }
  }
`
