import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { AssetPickerModalBaseProps } from '@complex/AssetPickerModal/AssetPickerModal'
import { AssetPickerInfoType } from '@complex/AssetPickerModal/Components/Info/AssetPickerInfoContainer'
import { ExtendedItemDto, ListingPageItem } from '@complex/ListingPage/Context/ListingPageCommon.context'
import FormsPickerModal, { FormsPickerModalProps } from '@components/AssetPickers/FormsPickerModal/FormsPickerModal'
import LandingPagesPickerModal from '@components/AssetPickers/LandingPagesPickerModal/LandingPagesPickerModal'
import AssetSelect from '@components/AssetSelect/AssetSelect'
import BackButton from '@components/BackButton/BackButton'
import Button, { ButtonType } from '@components/Button'
import Label from '@components/Label'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2GroupedOption, SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { SvgNames } from '@components/Svg'
import Typography, { TextType } from '@components/Typography/Typography'
import { AssetLinkTypes, isMediaItem } from '@utils/composer/EmailModal.constants'

import './AssetLinkModal.css'

export type AssetNameAndLink = {
  name?: string
  url?: string
}

interface AssetLinkModalProps {
  isOpen: boolean
  assetType: AssetLinkTypes.FORM | AssetLinkTypes.LANDING_PAGES
  urlOptions: SelectV2GroupedOption[]
  handleBackClick: () => void
  handleCancel: () => void
  handleInsert: (asset: AssetNameAndLink) => void
  handleSelectAsset: (item: ListingPageItem) => void
  handleOnChange: (option?: SelectV2SingleOption) => void
  selectedAsset?: ListingPageItem
  selectedUrl?: SelectV2SingleOption
  className?: string
  dataTest?: string
}

const rootClass = 'asset-link-modal'

const AssetLinkModal: FC<AssetLinkModalProps> = (props: AssetLinkModalProps) => {
  const {
    isOpen,
    selectedAsset,
    selectedUrl,
    handleBackClick,
    handleCancel,
    handleInsert,
    handleSelectAsset,
    handleOnChange,
    assetType,
    urlOptions,
    dataTest = rootClass,
  } = props

  const assetText = assetType === AssetLinkTypes.FORM ? 'Form' : 'Landing page'

  const { t } = useTranslation()
  const [pickerModalOpen, setPickerModalOpen] = useState<boolean>(!selectedAsset)

  const getCommonPickerProps = (): AssetPickerModalBaseProps => ({
    preSelectedRowIds: selectedAsset ? [`${!isMediaItem(selectedAsset) ? selectedAsset?.externalId : ''}`] : [],
    primaryButtonText: 'Select',
    secondaryButtonText: 'Back',
    isSingleSelect: true,
    isOpen: true,
    onSubmit: (items: ExtendedItemDto[]) => {
      handleSelectAsset(items[0])
      setPickerModalOpen(false)
    },
    onClose: selectedAsset ? () => setPickerModalOpen(false) : handleBackClick,
    onBack: handleBackClick,
  })

  const formsPickerProps: FormsPickerModalProps = {
    ...getCommonPickerProps(),
    excludeNew: true,
    infoBanner: {
      values: {
        title: '',
        text: 'EmailComposer.AssetLinks.Form.AssetSelect.InfoBanner',
        type: AssetPickerInfoType.INFO,
      },
    },
  }
  const renderPickerModal = () =>
    assetType === AssetLinkTypes.FORM ? <FormsPickerModal {...formsPickerProps} /> : <LandingPagesPickerModal {...getCommonPickerProps()} />

  const getAssetIcon = () => {
    if (assetType === AssetLinkTypes.LANDING_PAGES) {
      return selectedAsset ? SvgNames.page : SvgNames.appendedList
    } else {
      return SvgNames.formSimple
    }
  }

  const renderBody = () => (
    <>
      <Label className={`${rootClass}__label`}>{t(assetText)}</Label>
      <AssetSelect
        selectedAssets={selectedAsset?.name}
        placeholder={t(`Select a ${assetText.toLowerCase()}...`)}
        svgIcon={getAssetIcon()}
        onClick={() => setPickerModalOpen(true)}
      />
      <Label className={classNames(`${rootClass}__label`, `${rootClass}__url-section`)}>{t('URL type')}</Label>
      <SelectV2
        key={`${selectedAsset?.id}`}
        value={selectedUrl}
        isDisabled={!selectedAsset}
        containerTooltipMessage={t(`Select a ${assetText.toLowerCase()} to change the URL type`)}
        insideModal
        groupedOptions={urlOptions}
        onChange={handleOnChange}
        className={`${rootClass}__selectV2`}
        isClearable={false}
      />
    </>
  )

  const renderFooter = () => (
    <>
      <Button buttonType={ButtonType.TERTIARY} onClick={handleCancel} disabled={false} dataTest={`${rootClass}-close-button`}>
        {t('Cancel')}
      </Button>
      <Button
        buttonType={ButtonType.PRIMARY}
        dataTest={`${rootClass}-save-button`}
        disabled={!selectedAsset || !selectedUrl}
        onClick={() => handleInsert({ name: isMediaItem(selectedAsset) ? selectedAsset?.title : selectedAsset?.name, url: selectedUrl?.value })}
      >
        {t('Insert')}
      </Button>
    </>
  )

  const modalTitle =
    assetType === AssetLinkTypes.FORM
      ? 'Insert form link'
      : assetType === AssetLinkTypes.LANDING_PAGES
      ? 'Insert landing page link'
      : 'Insert asset link'

  return (
    <Modal
      isOpen={isOpen}
      header={
        <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__modal-header`}>
          <BackButton className={`${rootClass}__back-button-svg`} onClick={() => setPickerModalOpen(true)} />
          <Typography text={t(modalTitle)} type={TextType.DATA_CARD_MODAL_HEADER} />
        </ModalHeader>
      }
      dataTest={dataTest}
      className={rootClass}
    >
      <ModalBody>
        {pickerModalOpen && renderPickerModal()}
        {renderBody()}
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form}>{renderFooter()}</ModalFooter>
    </Modal>
  )
}

export default AssetLinkModal
