import React, { FC, useContext, useState } from 'react'

import classNames from 'classnames'

import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { useTranslation } from '@const/globals'
import { getSubscriptionStatusOptions } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/SubscriptionExpression/SubscriptionExpression.utils'
import { SegmentComposerContext } from '@src/pages/SegmentComposer/SegmentComposer.context'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'

import './SubscriptionExpression.css'

interface SubscriptionExpressionProps {
  className?: string
  dataTest?: string
}

interface SubscriptionExpressionState {
  subscriptionStatus: SelectV2SingleOption
  subscriptionCategory: SelectV2SingleOption[]
}

const rootClass = 'subscription-expression'
const defaultSubscriptionStatusIndex = 0

const SubscriptionExpression: FC<SubscriptionExpressionProps> = (props: SubscriptionExpressionProps) => {
  const { dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const {
    values: { subscriptionCategories },
  } = useContext(SegmentComposerContext)
  const subscriptionStatusOptions = getSubscriptionStatusOptions(t)
  const defaultSubscriptionStatus = subscriptionStatusOptions[defaultSubscriptionStatusIndex]

  const [state, setState] = useState<SubscriptionExpressionState>({
    subscriptionStatus: defaultSubscriptionStatus,
    subscriptionCategory: [],
  })

  const { subscriptionStatus, subscriptionCategory } = state
  const update = useDeepUpdate(setState)

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <SelectV2
        dataTest={`${dataTest}-status-select`}
        className={`${rootClass}__status-select`}
        options={subscriptionStatusOptions}
        defaultValue={subscriptionStatus}
        onChange={(subscriptionStatus?: SelectV2SingleOption) => update({ subscriptionStatus })}
        isClearable={false}
        isSearchable={false}
        truncateOptions
      />
      <SelectV2
        dataTest={`${dataTest}-category-select`}
        className={`${rootClass}__category-select`}
        placeholder={t('Select category')}
        options={subscriptionCategories}
        value={subscriptionCategory}
        onChangeMultiple={(subscriptionCategory) => update({ subscriptionCategory })}
        truncateMultiValues
        truncateOptions
        minSearchOptions={1}
      />
    </div>
  )
}

export default SubscriptionExpression
