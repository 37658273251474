import React, { FC } from 'react'

import classNames from 'classnames'

import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import TableList from '@components/TableList/TableList'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { MessageType } from '@src/pages/sms/edit/components/MessagePreview/MessagePreview'
import { displayMessage } from '@src/pages/sms/edit/components/MessagePreview/MessagePreviewVisual'
import SegmentedMessage from '@src/pages/sms/edit/utils/segmentationCalculator'
import { MessageDetailsType } from '@src/pages/sms/report/SMSReport.constants'
import { getCreditsByCreditMultiplierCells } from '@src/pages/sms/utils/sms.utils'
import { getDayOfWeekDate } from '@utils/date'

import './MessageDetails.css'

type Props = {
  messageDetails?: MessageDetailsType
  segmentedMessage: SegmentedMessage
  isLaunch: boolean
  dataTest?: string
}

interface ContactRow {
  label: string
  field: string
  count: number
}

const contactRows: ContactRow[] = [
  {
    label: 'Selected recipients',
    field: 'selectedRecipients',
    count: 0,
  },
  {
    label: 'Recipients without phone',
    field: 'noPhoneNumbers',
    count: 0,
  },
  {
    label: 'Recipients not on opt-in list',
    field: 'notOptIns',
    count: 0,
  },
  {
    label: 'Duplicate phone numbers',
    field: 'duplicates',
    count: 0,
  },
  {
    label: 'Could not personalize',
    field: 'failedPersonalizationCount',
    count: 0,
  },
  {
    label: 'Numbers outside US/CA',
    field: 'suppressedInternationalContacts',
    count: 0,
  },
]

const rootClass = 'message-details'

const MessageDetails: FC<Props> = (props: Props) => {
  const { messageDetails, segmentedMessage, isLaunch, dataTest = rootClass } = props
  const { t } = useTranslation()

  if (!messageDetails) {
    return null
  }

  const selectedRecipientsCount = isLaunch
    ? messageDetails.contactLists?.reduce((acc, curr) => acc + curr.totalContacts, 0) ?? 0
    : messageDetails.messagesSent + messageDetails.noPhoneNumbers + messageDetails.notOptIns + messageDetails.duplicates

  const selectedRecipientsRow = contactRows.find((row) => row.field === 'selectedRecipients')
  const selectedRecipientsLabel = isLaunch ? 'Selected recipients' : 'Completed'
  const updatedRecipientRows: ContactRow[] = [
    { ...(selectedRecipientsRow as ContactRow), label: selectedRecipientsLabel, count: selectedRecipientsCount },
  ]
  const noPhoneNumbersRow = contactRows.find((row) => row.field === 'noPhoneNumbers')
  updatedRecipientRows.push({ ...(noPhoneNumbersRow as ContactRow), count: messageDetails.noPhoneNumbers })
  const notOptInsRow = contactRows.find((row) => row.field === 'notOptIns')
  updatedRecipientRows.push({ ...(notOptInsRow as ContactRow), count: messageDetails.notOptIns })
  const duplicatesRow = contactRows.find((row) => row.field === 'duplicates')
  updatedRecipientRows.push({ ...(duplicatesRow as ContactRow), count: messageDetails.duplicates })
  const failedPersonalizationRow = contactRows.find((row) => row.field === 'failedPersonalizationCount')
  updatedRecipientRows.push({ ...(failedPersonalizationRow as ContactRow), count: messageDetails.failedPersonalizationCount ?? 0 })

  if (messageDetails.suppressedInternationalContacts > 0) {
    const suppressedInternationalContactsRow = contactRows.find((row) => row.field === 'suppressedInternationalContacts')
    updatedRecipientRows.push({ ...(suppressedInternationalContactsRow as ContactRow), count: messageDetails.suppressedInternationalContacts })
  }

  const getRecipientsCells = () => {
    const rows = updatedRecipientRows.map((row) => {
      const showMinus = row.field !== 'selectedRecipients' && row.count > 0
      const value = `${showMinus ? '-' : ''}${Intl.NumberFormat().format(row.count)}`
      return {
        left: <Typography text={t(row.label)} type={TextType.BODY_TEXT_SMALL} />,
        right: <Typography text={value} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.MEDIUM} />,
      }
    })

    return {
      rows,
      hasBorder: false,
      totalRow: {
        left: <Typography text={t('Total Recipients')} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.MEDIUM} />,
        right: <Typography text={Intl.NumberFormat().format(messageDetails.messagesSent)} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.BOLD} />,
      },
    }
  }

  const renderRecipientLists = () => {
    if (!messageDetails.contactLists) return null

    const rows = messageDetails.contactLists.map((list) => {
      return {
        left: (
          <div className={`${rootClass}__data`}>
            <Svg name={SvgNames.crm} type={SvgType.ICON} />
            <Typography text={list.listName} type={TextType.BODY_TEXT} inline />
          </div>
        ),
        right: isLaunch ? <Typography text={Intl.NumberFormat().format(list.totalContacts)} type={TextType.BODY_TEXT_SMALL} /> : null,
      }
    })
    return (
      <>
        <div className={classNames(`${rootClass}__section-title`, `${rootClass}__section`, `${rootClass}__lined`)}>
          <Typography text={t(`${isLaunch ? 'Recipient' : 'Program Source'} Lists`)} weight={TextWeight.BOLD} />
        </div>
        {<TableList rows={rows} hasBorder={false} className={`${rootClass}__lists-table`} />}
      </>
    )
  }

  const renderCreditSummary = () => {
    const rows = getCreditsByCreditMultiplierCells({
      recipientsPerCreditMultiplier: messageDetails.recipientsPerCreditMultiplier,
      t,
      segmentedMessage,
      rootClass,
    })
    const totalRow = {
      left: <Typography text={t('Credits Charged')} weight={TextWeight.BOLD} />,
      right: <Typography text={messageDetails.billableCredits} weight={TextWeight.BOLD} />,
    }
    return <TableList rows={rows} totalRow={totalRow} />
  }

  return (
    <div className={rootClass} data-test={dataTest}>
      <div className={`${rootClass}__section-title`}>
        <Typography text={t('Message Text')} weight={TextWeight.BOLD} />
      </div>
      <div className={`${rootClass}__message-text`}>
        <Typography text={displayMessage(messageDetails.messageText, MessageType.CONCATENATED, '', rootClass, 'message-preview-visual')} />
      </div>
      <div className={classNames(`${rootClass}__section-title`, `${rootClass}__section`)}>
        <Typography text={t(isLaunch ? 'Message Details' : 'Program Details')} weight={TextWeight.BOLD} />
      </div>
      <div className={`${rootClass}__message-details`}>
        <div className={classNames(`${rootClass}__detail`, `${rootClass}__row-separator`)}>
          <Typography
            className={`${rootClass}__detail-label`}
            text={t(`${isLaunch ? 'Sent' : 'Created'} by`)}
            type={TextType.BODY_TEXT_SMALL}
            weight={TextWeight.MEDIUM}
          />
          <Typography text={messageDetails.sentBy ?? messageDetails.createdBy} type={TextType.BODY_TEXT_SMALL} />
        </div>
        {messageDetails.sentDate && (
          <div className={classNames(`${rootClass}__detail`, `${rootClass}__row-separator`)}>
            <Typography
              className={`${rootClass}__detail-label`}
              text={t(`${isLaunch ? 'Sent' : 'Created'} on`)}
              type={TextType.BODY_TEXT_SMALL}
              weight={TextWeight.MEDIUM}
            />
            <Typography text={getDayOfWeekDate(messageDetails.sentDate, true)} type={TextType.BODY_TEXT_SMALL} />
          </div>
        )}
        <Typography
          className={classNames(`${rootClass}__detail-label`, `${rootClass}__label-separator`)}
          text={t('Description')}
          type={TextType.BODY_TEXT_SMALL}
          weight={TextWeight.MEDIUM}
        />
        {messageDetails.description && (
          <Typography
            className={classNames(`${rootClass}__detail-description`, `${rootClass}__row-separator`)}
            text={messageDetails.description}
            type={TextType.BODY_TEXT_SMALL}
          />
        )}
      </div>
      {renderRecipientLists()}
      <div className={classNames(`${rootClass}__section-title`, `${rootClass}__section`, `${rootClass}__lined`)}>
        <Typography text={t(isLaunch ? 'Recipient Summary' : 'SMS Step Details')} weight={TextWeight.BOLD} />
      </div>
      <TableList {...getRecipientsCells()} />
      <div className={classNames(`${rootClass}__section-title`, `${rootClass}__section-spacer`, `${rootClass}__section`, `${rootClass}__lined`)}>
        <Typography text={t('Credit Summary')} weight={TextWeight.BOLD} />
      </div>
      {messageDetails.recipientsPerCreditMultiplier && renderCreditSummary()}
    </div>
  )
}

export default MessageDetails
