import { GetUnifiedListFieldMappingsRequestType } from '@src/pages/ContactSegments/components/AddContactsFromCRM/GraphQL/AddContactsFromCRMRequests.list.graphQL'
import { logNewRelicError } from '@utils/new-relic.utils'

interface GetUnifiedListFieldMappingsUtilsParams {
  getUnifiedListFieldMappingsRequest: GetUnifiedListFieldMappingsRequestType
}

export const getUnifiedListFieldMappingsUtils = async ({ getUnifiedListFieldMappingsRequest }: GetUnifiedListFieldMappingsUtilsParams) => {
  try {
    const { data } = await getUnifiedListFieldMappingsRequest()
    return data?.unifiedListFieldMappings
  } catch (error) {
    logNewRelicError(error)
    return []
  }
}
