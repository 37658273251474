import React, { FC, useEffect, useMemo, useState } from 'react'

import { useApolloClient, useMutation } from '@apollo/client'
import { ButtonType } from '@components/Button'
import Caution from '@components/Caution/Caution'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import Input from '@components/Input/Input'
import PageContainer from '@components/PageContainer'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import StatusToast from '@components/StatusToast/StatusToast'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { LineHeight, ModalBodyStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import eraseContact from '@graphql/mutations/eraseContact'
import { StatusToastType } from '@interface/StatusToast'
import { ERASE_MODAL_VALUE, MORE_INFO } from '@src/pages/Settings/OtherSettings/EraseContact/utils/EraseContact.constants'
import useCRM from '@utils/hooks/useCRM'
import useUserSettings from '@utils/hooks/useUserSettings'
import { logNewRelicError } from '@utils/new-relic.utils'

import EraseConfirmInputs from './components/EraseConfirmInputs'

import './eraseContact.css'

const rootClass = 'erase-contact'

const EraseContact: FC = () => {
  const { userSettings, setUserSetting } = useUserSettings()
  const [toast, setToastStatus] = useState<StatusToastType>()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [eraseValue, setEraseValue] = useState('')
  const [confirmValue, setConfirmValue] = useState('')
  const [modalInput, setModalInput] = useState<string>('')
  const [emptyInput, setEmptyInput] = useState<boolean>(false)
  const { t } = useTranslation()
  const client = useApolloClient()
  const { hasCRMConnected } = useCRM()

  const [erase] = useMutation(eraseContact, {
    client,
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (userSettings) {
      setConfirmValue(userSettings['lastConfirm'])
    }
  }, [userSettings])

  const onModalInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setModalInput(value.toUpperCase())
  }

  const modalDescription = () => {
    return (
      <div>
        By clicking Erase contact, you will permanently erase all data associated with <span>{eraseValue}</span> from your account. This includes, but
        is not limited to, all entries in lists, segments, subscription and opt-in preferences, and behavior history.{' '}
        <span>Erased data cannot be recovered.</span>
      </div>
    )
  }

  const toastStatusSuccess = () => {
    return (
      <div className={`${rootClass}__confirmation-toast`}>
        <Typography text={'Success! We’ve got your request to erase this contact.'} />
        <Typography text={'We’ll send a confirmation email once it’s complete.'} />
      </div>
    )
  }

  const toastStatusError = () => {
    return (
      <div className={`${rootClass}__confirmation-toast`}>
        <Typography text={'Something doesn’t look right.'} />
        <Typography text={'We couldn’t process your request right now. Please try again.'} />
      </div>
    )
  }

  const confirmationBody = (
    <>
      <Typography text={modalDescription()} {...ModalBodyStyle} className={`${rootClass}__confirmation-body`} />
      <div className={`${rootClass}__confirmation-body-input`}>
        <Typography
          text={t('Type ERASE to confirm')}
          className={`${rootClass}__confirmation-body-input-text`}
          {...ModalBodyStyle}
          type={TextType.BODY_TEXT_SMALL}
          lineHeight={LineHeight.TINY}
          weight={TextWeight.MEDIUM}
        />
        <Input value={modalInput} onChange={(event) => onModalInputChange(event)} />
      </div>
    </>
  )

  const variables = useMemo(
    () => ({
      eraseEmail: eraseValue,
      confirmEmail: confirmValue,
    }),
    [eraseValue, confirmValue]
  )

  return (
    <>
      {showConfirmation && (
        <ConfirmationModal
          isOpen
          isDelete
          title={t('Are you sure?')}
          body={confirmationBody}
          deleteButtonText={t('Erase contact')}
          deleteButtonType={ButtonType.ERASE}
          deleteDisabled={modalInput !== ERASE_MODAL_VALUE}
          onAnswer={(answer) => {
            if (answer === YesNo.YES) {
              erase({
                variables,
              })
                .then(() => {
                  setToastStatus({
                    showStatus: true,
                    statusMessage: toastStatusSuccess(),
                    successStatus: true,
                  })
                  setShowConfirmation(false)
                  setModalInput('')
                  setEraseValue('')
                  setUserSetting('lastConfirm', confirmValue)
                  setEmptyInput(true)
                })
                .catch((error) => {
                  setToastStatus({
                    showStatus: true,
                    statusMessage: toastStatusError(),
                    successStatus: false,
                  })
                  setShowConfirmation(false)
                  logNewRelicError(error)
                })
            } else {
              setShowConfirmation(false)
              setModalInput('')
            }
          }}
          className={`${rootClass}__confirmation`}
        />
      )}
      {toast?.showStatus && (
        <StatusToast
          isSuccess={toast.successStatus}
          message={toast.statusMessage}
          title={toast.title}
          closeStatus={() => {
            setToastStatus({ showStatus: false })
          }}
        />
      )}
      <PageContainer className={rootClass}>
        <PositionContainer>
          <Typography className={`${rootClass}__title`} text={'Erase Contact'} type={TextType.PAGE_HEADER} />
          <div className={`${rootClass}__sub-header-container`}>
            <Typography
              className={`${rootClass}__sub-header`}
              text={t(
                "Permanently erase all data in Act-On associated with a contact's email address. This will delete all list, segment, subscription and opt-in preferences, and behavior history. Data deletion is GDPR compliant and can't be recovered."
              )}
              type={TextType.BODY_TEXT_LIGHT}
            />
            <TextLink text={t('More info')} size={TextLinkSize.LARGE} link={MORE_INFO} className={`${rootClass}__more-info`} />
          </div>
          {hasCRMConnected && (
            <Caution
              message={t(
                'Erasing a contact in Act-On will not erase a contact from your CRM. If your account syncs with a CRM or other database, erase your contact there first. Otherwise, the contact data could re-enter Act-On during a sync.'
              )}
            />
          )}
          <EraseConfirmInputs
            eraseValue={eraseValue}
            setEraseValue={setEraseValue}
            confirmValue={confirmValue}
            setConfirmValue={setConfirmValue}
            showConfirmation={setShowConfirmation}
            setEmptyInput={setEmptyInput}
            emptyInput={emptyInput}
          />
        </PositionContainer>
      </PageContainer>
    </>
  )
}

export default EraseContact
