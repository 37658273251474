import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Chip from '@components/Chip/Chip'
import Svg, { SvgColor, SvgNames, SvgType } from '@components/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { LabelDto } from '@graphql/types/query-types'
import { FilterDefinition } from '@utils/filter'
import { Folder } from '@utils/interface/Folder'
import { ellip, toTrainCase } from '@utils/strings'

import './AssetPickerSearchHeader.css'

export interface SearchHeaderFilter {
  name: string
  onRemove: () => void
  /** User can't remove the filter */
  readOnly?: boolean
}

type BaseFilter = FilterDefinition | Folder | LabelDto

export interface AssetPickerSearchHeaderProps {
  /** A sidebar top filter, folder, or tag */
  baseFilter?: FilterDefinition | Folder | LabelDto
  /** Display as chips with option to remove */
  filters: SearchHeaderFilter[]
  searchTerm: string
  searchCount: number
  onRemoveAllFilters: () => void
  className?: string
  dataTest?: string
}

const rootClass = 'asset-picker-search-header'

const AssetPickerSearchHeader: FC<AssetPickerSearchHeaderProps> = (props: AssetPickerSearchHeaderProps) => {
  const { baseFilter, filters, searchTerm, searchCount, onRemoveAllFilters, dataTest = rootClass, className = rootClass } = props
  const { t } = useTranslation()

  const isFilter = (filter: BaseFilter): filter is FilterDefinition => 'svgSelected' in filter
  const isTag = (filter: BaseFilter): filter is LabelDto => 'color' in filter

  const getSvg = (baseFilter: BaseFilter) => {
    if (isFilter(baseFilter)) {
      return baseFilter.svgSelected
    } else if (isTag(baseFilter)) {
      return SvgNames.tag
    } else {
      return SvgNames.folderOpened
    }
  }

  const resultTypography = {
    type: TextType.BODY_TEXT_LARGE,
    weight: TextWeight.BOLD,
    lineHeight: LineHeight.MEDIUM_LARGE,
    inline: true,
  }

  const hasReadOnly = filters.some((filter) => filter.readOnly)
  const sortedFilters = [...filters].sort((a) => (a.readOnly ? -1 : 1))

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={classNames(`${className}__results`, { [`${className}__results--no-filters`]: !filters.length })}>
        <Typography
          {...resultTypography}
          text={t('AssetPicker.Search.Results', {
            count: searchCount,
            searchTerm: ellip(searchTerm, 50),
            context: hasReadOnly ? undefined : 'AppliedFilter',
          })}
          dataTest={`${dataTest}-search-results`}
        />
        {baseFilter && (
          <div className={`${className}__results__base-filter`}>
            <Svg type={SvgType.LARGER_ICON} name={getSvg(baseFilter)} fill={SvgColor.TEAL} dataTest={`${dataTest}-base-filter-svg`} />
            <Typography {...resultTypography} text={baseFilter.name} dataTest={`${dataTest}-base-filter-name`} />
          </div>
        )}
      </div>
      {sortedFilters.length ? (
        <div className={`${className}__filters`}>
          <div className={`${className}__filters__left`}>
            <Typography
              text={t(hasReadOnly ? 'Search in:' : 'Filters:')}
              type={TextType.BODY_TEXT_SMALL_LIGHT}
              weight={TextWeight.BOLD}
              lineHeight={LineHeight.MEDIUM_SMALL}
            />
            <div className={`${className}__filters__chips`}>
              {sortedFilters.map((filter) => (
                <Chip
                  key={filter.name}
                  id={filter.name}
                  name={filter.name}
                  onRemove={filter.onRemove}
                  fixed={filter.readOnly}
                  dataTest={`${dataTest}-filter-${toTrainCase(filter.name)}`}
                />
              ))}
            </div>
          </div>
          {!hasReadOnly && (
            <div className={`${className}__filters__right`}>
              <Button buttonType={ButtonType.TEXT} dataTest={`${dataTest}-clear-all`} onClick={onRemoveAllFilters}>
                <Typography type={TextType.BODY_TEXT} weight={TextWeight.MEDIUM}>
                  {t('Clear all')}
                </Typography>
              </Button>
            </div>
          )}
        </div>
      ) : null}
    </div>
  )
}

export default AssetPickerSearchHeader
