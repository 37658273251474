import { gql } from 'apollo-boost'

export default gql`
  query getUnifiedListFieldMappings {
    unifiedListFieldMappings {
      standardFieldKey
      columnIndex
      dataType
      displayName
      deleted
      hidden
      readOnly
      typeMetaData
      ingestFieldMappings {
        source
        sourceId
        objectType
        mapping
        format
      }
    }
  }
`
