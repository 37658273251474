import { gql } from '@apollo/client'

export default gql`
  query entityTypes {
    entityTypes {
      display
      identifier
      nameColumns
      pkColumn
      recordCount
      visible
    }
  }
`
