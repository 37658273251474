import { List } from '@interface/foldersLists/List'
import listsUtils, { checkIfAllListsAreSelected, checkIfAllListsInFoldersAreSelected, getFolderListIds, getListIds, ListCategory } from '@utils/lists'

import { Props, State } from './ListPicker'

export interface ListPickerUtil {
  handleListChanged(listId: string | null, checked: boolean): void
  handleCollapseRow(listId: string, collapse: boolean): void
  handleCollapseFolder(folderId: string, collapse: boolean): void
  getAllSelected(): boolean
  isDisabled(list: List, category: ListCategory): boolean
  isLoading(): boolean
  isError(): boolean
  props(): Props
  state(): State
}

export const getListPickerUtil = (state: State, setState: (state: State) => void, props: Props): ListPickerUtil => ({
  handleListChanged: function (listId: string | null, checked: boolean) {
    const { multiSelect = true } = props
    if (listId === null) {
      if (checked) {
        let allListIds: string[] = []
        if (props.lists?.lists) {
          if (props.smsCompatibleLists) {
            const filteredLists = props.lists.lists.filter((list: List) => list.isSmsCompatible && (list.size ?? 0) > 0)
            allListIds = getListIds(filteredLists)
          } else {
            allListIds = getListIds(props.lists.lists)
          }
        } else if (props.folders?.folders) {
          allListIds = getFolderListIds(props.folders.folders)
        }
        props.updateSelectedLists(allListIds)
      } else {
        props.updateSelectedLists([])
      }
    } else {
      if (checked) {
        if (multiSelect) {
          props.updateSelectedLists([...props.selectedLists, listId])
        } else {
          props.updateSelectedLists([listId])
        }
      } else {
        if (multiSelect) {
          props.updateSelectedLists(props.selectedLists.filter((list) => list !== listId))
        } else {
          props.updateSelectedLists([])
        }
      }
    }
  },
  handleCollapseRow: function (listId: string, collapse: boolean) {
    setState({
      ...state,
      collapsedRows: listsUtils.handleCollapse(listId, collapse, state.collapsedRows),
    })
  },
  handleCollapseFolder: function (folderId: string, collapse: boolean) {
    setState({
      ...state,
      collapsedFolders: listsUtils.handleCollapse(folderId, collapse, state.collapsedFolders),
    })
  },
  getAllSelected: function () {
    if (props.lists?.lists && (props.lists?.lists?.length ?? 0) > 0) {
      return checkIfAllListsAreSelected(props.lists.lists, props.selectedLists)
    } else if (props.folders?.folders && (props.folders?.folders?.length ?? 0) > 0) {
      return checkIfAllListsInFoldersAreSelected(props.folders.folders, props.selectedLists)
    } else {
      return false
    }
  },
  isDisabled: function (list: List, category: ListCategory) {
    return (
      (props.cannotAddLists ||
        (category !== ListCategory.UNIFIED_LIST && props.unifiedLists.length > 0) ||
        (category === ListCategory.UNIFIED_LIST && props.actonClassicLists.length > 0) ||
        (props.smsCompatibleLists && (!list.isSmsCompatible || list.size === 0)) ||
        (props.importUnifiedCompatibleLists && (category === ListCategory.UNIFIED_LIST || list.isCrm))) ??
      false
    )
  },
  isError: function () {
    return (props.lists?.error || props.folders?.error || props.search?.error) ?? false
  },
  isLoading: function () {
    return (props.lists?.loading || props.folders?.loading || props.search?.loading) ?? false
  },
  props() {
    return props
  },
  state() {
    return state
  },
})
