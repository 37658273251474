export const SAME_AS_SENDER_OPTION_VALUE = 'sameAsSenderValue'
export const SALESFORCE_OWNER_OPTION_VALUE = 'SalesforceOwner'
export const PERSONALIZED_FROM_ADDRESS_OPTION_VALUE = 'Personalized From'
export const PERSONALIZED_FROM_ADDRESS_UNSPECIFIED_VALUE = 'Unspecified'
export const NOT_PERSONALIZED_FROM_ADDRESS_FALLBACK_SENDER_VALUE = 'none'
export const UNSPECIFIED_SENDER_OPTION_VALUE = 'Unspecified'
export const FORM_DATA_AUTORESPONSE_KEY = 'formData'
export const FORM_AUTORESPONSE_EMAIL_IDS = 'confirmationEmailIds'
export const FORM_ID_STORAGE_KEY = 'formid'
export const PROGRAM_MESSAGE_STORAGE_KEY = 'programData'
export const SHOULD_FILL_MESSAGE_ID_STORAGE_KEY = 'shouldFillMessageId'
