import React, { FC, ReactNode, useMemo } from 'react'

import Accordion, { AccordionItemData } from '@components/Accordion/Accordion'
import DisplayToggleCard, { DisplayToggleCardProps } from '@components/DisplayToggleCard/DisplayToggleCard'
import { ToggletipSide } from '@components/Toggletip/Toggletip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './ComposerConditionToggleCard.css'

interface Props extends Omit<DisplayToggleCardProps, 'toggletipProps'> {
  title: string
  description: string
  content: ReactNode
  conditions: ReactNode
}

const rootClass = 'condition-toggle-card'

const accordionIndexes = ['0', '1']

const ComposerConditionToggleCard: FC<Props> = ({ title, description, content, conditions, dataTest = rootClass, ...rest }) => {
  const { t } = useTranslation()

  const accordionData: AccordionItemData[] = useMemo(
    () => [
      {
        index: accordionIndexes[0],
        header: <Typography text={t('Dynamic content preview')} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />,
        content: content,
        chevronTooltips: { openTooltip: t('Hide preview'), closeTooltip: t('Show preview') },
        contentClassName: `${rootClass}__toggletip__wrapper-content`,
      },
      {
        index: accordionIndexes[1],
        header: <Typography text={t('Display conditions')} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />,
        content: conditions,
        chevronTooltips: { openTooltip: t('Hide conditions'), closeTooltip: t('Show conditions') },
        contentClassName: `${rootClass}__toggletip__wrapper-conditions`,
      },
    ],
    [t, content, conditions]
  )

  return (
    <DisplayToggleCard
      toggletipProps={{
        title: t(title),
        description: t(description),
        content: (
          <Accordion defaultValue={accordionIndexes} type="multiple" childData={accordionData} className={`${rootClass}__toggletip--accordion`} />
        ),
        side: ToggletipSide.LEFT,
        sideOffset: 80,
        className: `${rootClass}__toggletip`,
      }}
      showIconTooltip={t('Hide conditional content')}
      hideIconTooltip={t('Show conditional content')}
      dataTest={dataTest}
      {...rest}
    />
  )
}

export default ComposerConditionToggleCard
