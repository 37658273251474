import React, { FC, useEffect, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import ListPickerModal from '@complex/ListPickerModal/ListPickerModal'
import { ListPickerModal as ListPickerModalV2 } from '@complex/ListPickerModalV2/ListPickerModal'
import Loader from '@components/Loader/Loader'
import { useTranslation } from '@const/globals'
import getWelcomeMessage from '@graphql/microservices/sms-management/getWelcomeMessage'
import addMarketingListOptInSource from '@graphql/mutations/addMarketingListOptInSource'
import listSchemaQuery from '@graphql/queries/listSchema'
import { Query, QueryGetWelcomeMessageArgs } from '@graphql/types/microservice/sms-management-types'
import { Mutation, MutationAddMarketingListOptInSourceArgs } from '@graphql/types/mutation-types'
import { ListSchemaQuery, ListSchemaQueryVariables } from '@graphql/types/query-types'
import { List } from '@interface/foldersLists/List'
import MapContacts from '@src/pages/sms/marketingListOptIns/components/MapContacts/MapContacts'
import { AddListStatus } from '@src/pages/sms/marketingListOptIns/components/OptInsSourceLists/OptInsSourceListsContainer'
import WelcomeMessageModal from '@src/pages/sms/marketingListOptIns/components/WelcomeMessageModal/WelcomeMessageModal'
import { useAccountSettings } from '@utils/account/account.utils'
import { logError } from '@utils/env'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'
import { logNewRelicError } from '@utils/new-relic.utils'

interface Props {
  closeModal: (status?: AddListStatus) => void
}

export interface ColumnWithPreview {
  columnName: string
  columnIndex: number
  isPhoneNumber: boolean
  previewRows: string[]
}

export interface ListProperties {
  id: string
  name: string
  headers: string[]
  headersWithPreviews: ColumnWithPreview[]
}

export interface MarketingListMapping {
  id: string
  displayName: string
  cellPhoneColNum: number
  optInSourceColNum: number
  optInDateColNum: number
  optInDateFormat: string
  sendInitialWelcomeMessage?: boolean
  sendOngoingWelcomeMessage?: boolean
}

interface State {
  transferContacts: boolean
  mapContacts: boolean
  welcomeContacts: boolean
  lists: string[]
  loadingLists: boolean
  schemas: ListProperties[]
  mappings: MarketingListMapping[]
  submitted: boolean
}

const defaultState: State = {
  transferContacts: true,
  mapContacts: false,
  welcomeContacts: false,
  loadingLists: false,
  lists: [],
  schemas: [],
  mappings: [],
  submitted: false,
}

const MapContactsContainer: FC<Props> = (props: Props) => {
  const { closeModal } = props
  const client = useApolloClient()
  const { client: smsClient } = useMicroserviceClient({ serviceName: MicroserviceClients.SMS_MANAGEMENT })
  const [state, setState] = useState<State>(defaultState)
  const { t } = useTranslation()

  const { accountId, hasShowActOnContactsTab } = useAccountSettings()

  const { data, error } = useQueryOnMount<Query, QueryGetWelcomeMessageArgs>(getWelcomeMessage, {
    client: smsClient,
    fetchPolicy: 'network-only',
    variables: {
      accountId,
    },
  })
  useEffect(() => {
    if (error) {
      logNewRelicError(error)
    }
  }, [error])

  useEffect(() => {
    if (state.submitted) {
      client
        .mutate<Mutation, MutationAddMarketingListOptInSourceArgs>({
          mutation: addMarketingListOptInSource,
          variables: {
            marketingListOptInRequest: {
              listMappings: state.mappings,
            },
          },
        })
        .then(() => {
          closeModal(AddListStatus.success)
        })
        .catch((ex) => {
          logError(ex)
          logNewRelicError(ex)
          closeModal(AddListStatus.error)
        })
    }
  }, [state.submitted])

  const backToPicker = () => {
    setState({
      ...state,
      transferContacts: true,
      mapContacts: false,
    })
  }

  const onSubmitLists = (lists: List[]) => {
    const listIds = lists.map((list) => list.id)
    client
      .query<ListSchemaQuery, ListSchemaQueryVariables>({
        query: listSchemaQuery,
        fetchPolicy: 'network-only',
        variables: {
          listIds,
        },
      })
      .then((data) => {
        const schemas: ListProperties[] = data.data.listSchemas.map((listSchema) => {
          const headersWithPreviews = listSchema.schema
            ? listSchema.schema.headersWithPreviews.filter((item) => !item.columnName.startsWith('_'))
            : []
          return {
            id: listSchema.listId,
            name: listSchema.displayName,
            headers: headersWithPreviews.map((withPreview) => withPreview.columnName),
            headersWithPreviews,
          }
        })

        setState({
          ...state,
          lists: listIds,
          schemas,
          transferContacts: false,
          mapContacts: true,
        })
      })
      .catch((error) => {
        logError(error)
        logNewRelicError(error)
      })
  }

  const onSubmitMapsWelcomeMsg = (sendInitialWelcomeMessage: boolean, sendOngoingWelcomeMessage: boolean) => {
    const newMappings: MarketingListMapping[] = state.mappings.map((map) => {
      return {
        ...map,
        sendInitialWelcomeMessage: sendInitialWelcomeMessage,
        sendOngoingWelcomeMessage: sendOngoingWelcomeMessage,
      }
    })
    setState({
      ...state,
      mappings: newMappings,
      welcomeContacts: false,
      submitted: true,
    })
  }

  const onNextMapping = (mappings: MarketingListMapping[]) => {
    setState({
      ...state,
      mappings,
      welcomeContacts: true,
      mapContacts: false,
    })
  }

  const onCloseModal = () => {
    closeModal()
  }

  const onCloseWelcomeMsgModal = () => {
    setState({ ...state, welcomeContacts: false })
  }

  const onBackWelcomeMsgModal = () => {
    setState({ ...state, welcomeContacts: false, mapContacts: true })
  }

  if (state.transferContacts) {
    return hasShowActOnContactsTab ? (
      <ListPickerModalV2
        isOpen={state.transferContacts}
        closeModal={onCloseModal}
        submitLists={onSubmitLists}
        submitButtonText={t('Map Fields')}
        submitDisabledWithoutSelection
      />
    ) : (
      <ListPickerModal
        isOpen={state.transferContacts}
        closeModal={onCloseModal}
        submitLists={onSubmitLists}
        submitButtonText={t('Map Fields')}
        submitDisabledWithoutSelection
      />
    )
  } else if (state.mapContacts) {
    return <MapContacts backToPicker={backToPicker} onClose={onCloseModal} listSchemas={state.schemas} onNextMapping={onNextMapping} />
  } else if (state.welcomeContacts) {
    return (
      <WelcomeMessageModal
        message={data?.getWelcomeMessage?.smsMessage?.messageText ?? ''}
        onClose={onCloseWelcomeMsgModal}
        onSubmit={onSubmitMapsWelcomeMsg}
        onBack={onBackWelcomeMsgModal}
      />
    )
  } else {
    return <Loader center />
  }
}

export default MapContactsContainer
