import React, { FC, useCallback, useContext } from 'react'

import classNames from 'classnames'

import BackButton from '@components/BackButton/BackButton'
import Button, { ButtonType } from '@components/Button'
import { ModalHeader } from '@components/Modal'
import Search from '@components/Search/Search'
import Svg, { SvgNames } from '@components/Svg'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { LineHeight, ModalHeaderFormStyle } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import { EmailTemplateLayoutType } from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/utils/EmailLayoutsModal.utils'
import { detectEmailType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'

interface EmailLayoutsModalHeaderProps {
  onBack: () => void
  onSearch: (term: string) => void
  searchTerm: string
  layoutsType: EmailTemplateLayoutType
  isReplaceFlow: boolean
  className: string
  previewItemTitle?: string
  dataTest?: string
}

const getLayoutsPage = (layoutsType: EmailTemplateLayoutType) => `${rootContext}/classic/if/account/${layoutsType}s.jsp`

const EmailLayoutsModalHeader: FC<EmailLayoutsModalHeaderProps> = ({
  onBack,
  onSearch,
  searchTerm,
  layoutsType,
  isReplaceFlow,
  className,
  previewItemTitle,
  dataTest = className,
}) => {
  const { t } = useTranslation()

  const handleManage = useCallback(() => window.open(getLayoutsPage(layoutsType), '_blank'), [layoutsType])

  const {
    values: {
      message: { messageType },
    },
  } = useContext(EmailComposerContext)

  const { isEmailCRM } = detectEmailType(messageType)

  return (
    <ModalHeader
      className={classNames(className, {
        [`${className}--preview`]: previewItemTitle,
      })}
      dataTest={dataTest}
    >
      {!!previewItemTitle && <BackButton onClick={onBack} noXMargin className="push-left-x2" dataTest={`${dataTest}-button-back`} />}
      <TextWithTooltipOnEllip
        typographyProps={{
          text: t(previewItemTitle ? 'Preview.Asset.Modal.Title' : 'Email.Layout.Modal.Title', {
            title: previewItemTitle,
            layoutsType,
            context: isReplaceFlow ? 'replace' : undefined,
          }),
          ...ModalHeaderFormStyle,
          dataTest: `${dataTest}-title`,
        }}
      />
      <div className={`${className}__right flex-align-center push-right-x4`}>
        {!previewItemTitle && (
          <Search
            placeholder={t('Email.Layout.Modal.Search.Placeholder', { layoutsType })}
            onChangeHandler={onSearch}
            incomingValue={searchTerm}
            className={`${className}__search`}
            canClear
            dataTest={`${dataTest}-search`}
          />
        )}
        {!isEmailCRM && (
          <Button
            buttonType={ButtonType.FLOAT}
            onClick={handleManage}
            className={`${className}__manage-button`}
            dataTest={`${dataTest}-button-manage`}
          >
            <Svg name={SvgNames.gear} />
            <Typography text={t('Email.Layout.Modal.Manage.Label', { layoutsType })} lineHeight={LineHeight.SMALL} />
          </Button>
        )}
      </div>
    </ModalHeader>
  )
}

export default EmailLayoutsModalHeader
