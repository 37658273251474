import React, { FC } from 'react'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './AccountDetails.css'

interface AccountDetailsProps {
  dataTest?: string
  account: string
  userLogin: string
  userType: string
  isAdmin: boolean
}

const rootClass = 'account-details'

const AccountDetails: FC<AccountDetailsProps> = (props: AccountDetailsProps) => {
  const { account, userLogin, userType, isAdmin } = props

  const { t } = useTranslation()

  return (
    <>
      <Typography text={t('Account details')} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
      <Typography
        className={`${rootClass}-description`}
        text={
          isAdmin
            ? t('This information is set and managed in account settings. Some details related to your user account cannot be changed.')
            : t('This information is set and managed by your admin. Some details related to your user account cannot be changed.')
        }
        type={TextType.BODY_TEXT_LIGHT}
      />
      <div className={`${rootClass}-item`}>
        <div className={`${rootClass}-item-info`}>
          <div className={`${rootClass}-item-header`}>
            <Typography text={t('Account')} weight={TextWeight.MEDIUM} className={`${rootClass}-item-header__text`} />
            <Tooltip
              trigger={<Svg name={SvgNames.info} className={`${rootClass}-item-header__svg`} type={SvgType.MEDIUM} />}
              position="top"
              align="center"
            >
              {t(
                'The account name and unique Act-On ID refers to the organization that you’re part of. This information is set and managed by admins with access to Custom Account Settings.'
              )}
            </Tooltip>
          </div>
          <Typography text={account} />
        </div>
        <Tooltip
          trigger={<Svg name={SvgNames.lockLight} className={`${rootClass}-item-icon`} type={SvgType.LARGE_ICON} />}
          position="top"
          align="start"
          alignOffset={5}
          sideOffset={2}
        >
          {t(
            isAdmin
              ? 'Your company name is managed in Custom Account Settings.'
              : 'Your company name is managed by your admin in Custom Account Settings.'
          )}
        </Tooltip>
      </div>
      <div className="divider"></div>
      <div className={`${rootClass}-item`}>
        <div className={`${rootClass}-item-info`}>
          <div className={`${rootClass}-item-header`}>
            <Typography text={t('User login')} className={`${rootClass}-item-header__text`} weight={TextWeight.MEDIUM} />
            <Tooltip
              trigger={<Svg name={SvgNames.info} className={`${rootClass}-item-header__svg`} type={SvgType.MEDIUM} />}
              position="top"
              align="center"
            >
              {t('This email is used to log in to Act-On. Your account login cannot be changed.')}
            </Tooltip>
          </div>
          <Typography text={userLogin} />
        </div>
        <Tooltip
          trigger={<Svg name={SvgNames.lockLight} className={`${rootClass}-item-icon`} type={SvgType.LARGE_ICON} />}
          position="top"
          align="start"
          alignOffset={5}
          sideOffset={2}
        >
          {t('Your account login cannot be changed.')}
        </Tooltip>
      </div>
      <div className="divider"></div>
      <div className={`${rootClass}-item`}>
        <div className={`${rootClass}-item-info`}>
          <div className={`${rootClass}-item-header`}>
            <Typography className={`${rootClass}-item-header__text`} text={t('User type')} weight={TextWeight.MEDIUM} />
            <Tooltip
              trigger={<Svg name={SvgNames.info} className={`${rootClass}-item-header__svg`} type={SvgType.MEDIUM} />}
              position="top"
              align="center"
            >
              {t('Your user type grants you different permissions within Act-On. Your user type cannot be changed.')}
            </Tooltip>
          </div>
          <Typography text={t(isAdmin ? 'Admin' : userType)} />
        </div>
        <Tooltip
          trigger={<Svg name={SvgNames.lockLight} className={`${rootClass}-item-icon`} type={SvgType.LARGE_ICON} />}
          position="top"
          align="start"
          alignOffset={5}
          sideOffset={2}
        >
          {t('Your user type cannot be changed.')}
        </Tooltip>
      </div>
    </>
  )
}

export default AccountDetails
