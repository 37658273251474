import React, { ReactNode } from 'react'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { RegistrationsActivities } from '@src/pages/listingPages/FormsJoinView/components/WebinarRegistrationInfoHoverCard/utils/WebinarRegistrationsInfoHoverCard.constants'
import { webinarRegistrationInfoHoverCardRootClass } from '@src/pages/listingPages/FormsJoinView/components/WebinarRegistrationInfoHoverCard/WebinarRegistrationInfoHoverCard'
import { formatDateWithAbbreviations } from '@utils/date'

export const renderField = (label: ReactNode, date: number, index: number) => {
  return (
    <div key={index} className={`${webinarRegistrationInfoHoverCardRootClass}__activities-element`}>
      <div className={`${webinarRegistrationInfoHoverCardRootClass}__activities-name`}>
        <Svg name={SvgNames.clock} type={SvgType.ICON} />
        {label}
      </div>
      <Typography text={formatDateWithAbbreviations(date)} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.MEDIUM_LIGHT} />
    </div>
  )
}

const renderUserActivity = (activityDescription: string, userName: string, t: Function) => (
  <Typography
    text={t('ListPage.FormsJoinView.WEBINAR_REGISTRATION.InfoHover.RecentActivities.UserActivity')}
    values={{ activityDescription, userName }}
    tagProps={{ bold: { weight: TextWeight.BOLD } }}
    type={TextType.BODY_TEXT_SMALL}
    className={`${webinarRegistrationInfoHoverCardRootClass}__activities-name-compound`}
  />
)

const renderFirstAndLastRegistrations = (t: Function, isFirst = false) => (
  <Typography
    text={t(`ListPage.FormsJoinView.WEBINAR_REGISTRATION.InfoHover.RecentActivities.${isFirst ? 'First' : 'Last'}Registration`)}
    tagProps={{ bold: { weight: TextWeight.BOLD } }}
    type={TextType.BODY_TEXT_SMALL}
    className={`${webinarRegistrationInfoHoverCardRootClass}__activities-name-compound`}
  />
)

export const registrationActivityByDescription: { [key in RegistrationsActivities]: (userName: string, t: Function) => ReactNode } = {
  [RegistrationsActivities.SPLIT]: (userName: string, t: Function) => renderUserActivity('A/B split', userName, t),
  [RegistrationsActivities.CREATED]: (userName: string, t: Function) => renderUserActivity('Webinar created', userName, t),
  [RegistrationsActivities.RECORDS_CLEARED]: (userName: string, t: Function) => renderUserActivity('Records cleared', userName, t),
  [RegistrationsActivities.SEGMENT_CREATED]: (userName: string, t: Function) => renderUserActivity('Segment created', userName, t),
  [RegistrationsActivities.FIRST_REGISTRATION]: (_userName: string, t: Function) => renderFirstAndLastRegistrations(t, true),
  [RegistrationsActivities.LAST_REGISTRATION]: (_userName: string, t: Function) => renderFirstAndLastRegistrations(t),
}
