import React, { FC } from 'react'

import classNames from 'classnames'

import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { Form } from '@utils/forms'

import './FormsUpgradeReview.css'

interface FormsUpgradeReviewProps {
  className?: string
  dataTest?: string
  forms: Form[]
}

const rootClass = 'forms-upgrade-review'
const FORMS_UPGRADE_REVIEW_TEXT = 'Forms.Upgrade.Review'

const FormsUpgradeReview: FC<FormsUpgradeReviewProps> = (props: FormsUpgradeReviewProps) => {
  const { dataTest = rootClass, className = '', forms = [] } = props

  const { t } = useTranslation()

  return (
    <EmptyListing
      className={classNames(rootClass, className)}
      dataTest={dataTest}
      size={EmptyListingSize.MEDIUM}
      imgSrc={StaticImageNames.uploadFormActive}
      headline={t(`${FORMS_UPGRADE_REVIEW_TEXT}.Header`, { count: forms.length })}
      headlineLineHeight={LineHeight.LARGER}
      headlineType={TextType.PAGE_HEADER}
      text={`${FORMS_UPGRADE_REVIEW_TEXT}.Text`}
      tagProps={{ weight: TextWeight.BOLD, inline: true }}
    />
  )
}

export default FormsUpgradeReview
