import React, { FC, useContext, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

import { getRecipientSrcIds } from '@complex/Personalization/utils/Personalization.utils'
import Container from '@components/Container'
import InfoTooltip, { InfoTooltipIconSize } from '@components/InfoTooltip/InfoTooltip'
import Label from '@components/Label'
import PersonalizationTagInput from '@components/PersonalizationTagInput/PersonalizationTagInput'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType } from '@components/Typography/Typography'
import { detectEmailType, detectWebinarType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { CommonComposerTab } from '@utils/composer/commonComposer/CommonComposer.context'
import { ComposerHistoryState, EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { useTranslation } from '@utils/const/globals'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'

import {
  getTextValidationStatus,
  PREVIEW_RECOMMENDED_LENGTH,
  renderValidation,
  SettingsFormChangeHandler,
  SUBJECT_RECOMMENDED_EMOJI_COUNT,
  SUBJECT_RECOMMENDED_LENGTH,
} from '../../EmailComposerSettings.utils'

interface EmailComposerSettingsSubjectProps {
  className?: string
  dataTest?: string
  onFieldChange: SettingsFormChangeHandler
}

const rootClass = 'email-composer-settings-subject'

const EmailComposerSettingsSubject: FC<EmailComposerSettingsSubjectProps> = (props: EmailComposerSettingsSubjectProps) => {
  const { className = '', dataTest = className, onFieldChange } = props
  const { t } = useTranslation()
  const {
    values: {
      validations: {
        settingsValidations: { alwaysShowInlineValidations },
        settingsValidations,
      },
      personalizations,
      message: { id, subject, previewText, sendto_contacts, sendto_lists, individualRecipientsSourceIds, webinarConfig },
      messageConfiguration: {
        messageType,
        settings: { hidePreviewText, disableEditings },
      },
      detectedURLChanges: { plainTextMode, uploadHtmlMode },
    },
    api: { updateValidations },
  } = useContext(EmailComposerContext)

  const { isEmailBlankMessage, isEmailTemplate, isEmailABTest, isEmailProgram, isEmailForm } = detectEmailType(messageType)

  const { isAccepted, isPending, isRejected, isDeclined } = detectWebinarType(webinarConfig)

  const disableAddRecipientsButton = !!sendto_contacts?.length
  const specificIdsFromEmailComposer = useMemo(
    () => getRecipientSrcIds(individualRecipientsSourceIds, sendto_lists),
    [individualRecipientsSourceIds, sendto_lists]
  )
  const history = useHistory<ComposerHistoryState>()
  const userVisitedReview = history.location.state?.visitedTabs?.[CommonComposerTab.REVIEW]

  const [isDirty, setDirtyState] = useState({
    subject: userVisitedReview || alwaysShowInlineValidations.subject,
    previewText: userVisitedReview || alwaysShowInlineValidations.previewText,
  })
  const [hasPersonalizationError, setPersonalizationErrorState] = useState({ subject: false, previewText: false })

  const updateDirty = useDeepUpdate(setDirtyState)
  const setHasPersonalizationError = useDeepUpdate(setPersonalizationErrorState)

  const setDirty: typeof updateDirty = (values) => {
    const alwaysShowField = values.subject ? 'subject' : values.previewText ? 'previewText' : ''
    if (alwaysShowField) {
      updateValidations({ settingsValidations: { alwaysShowInlineValidations: { [alwaysShowField]: true } } })
    }
    updateDirty(values)
  }

  const setPersonalizationError: typeof setHasPersonalizationError = (values) => {
    setDirty({
      subject: values.subject ? true : isDirty.subject,
      previewText: values.previewText ? true : isDirty.previewText,
    })
    setHasPersonalizationError(values)
  }

  const {
    subjectEmojiCountExceeded,
    subjectLengthExceeded,
    previewLengthExceeded,
    subjectMissing,
    previewPersonalizationErrors,
    subjectPersonalizationErrors,
    loadingPersonalizationErrors: loading,
  } = settingsValidations

  const previewTextStatus = getTextValidationStatus(previewLengthExceeded, previewText)
  const subjectStatus = {
    recommendedLength: getTextValidationStatus(subjectLengthExceeded, subject),
    recommendedEmojiCount: getTextValidationStatus(subjectEmojiCountExceeded, subject),
  }

  const isSubjectInvalid = subjectMissing || hasPersonalizationError.subject
  const isPreviewInvalid = hasPersonalizationError.previewText || hasPersonalizationError.previewText

  const disableSegmentFields = isEmailABTest || isEmailForm || isEmailProgram || isAccepted || isPending || isRejected || isDeclined

  return (
    <Container data-test={dataTest}>
      <div className={`${className}__subsection`}>
        <Label className={`${className}__subject-label`} required={!isEmailBlankMessage}>
          {t('EmailComposer.Settings.Subject')}
        </Label>
        <Tooltip
          hide={!disableEditings}
          position={'top'}
          trigger={
            <PersonalizationTagInput
              className={`${className}__subject-input`}
              dataTest={`${dataTest}-subject-input`}
              loading={loading && personalizations === undefined}
              recommendedLength={SUBJECT_RECOMMENDED_LENGTH}
              onChange={(text) => onFieldChange('subject', text)}
              onFocus={isEmailBlankMessage ? undefined : () => setDirty({ subject: false })}
              onBlur={isEmailBlankMessage ? undefined : () => setDirty({ subject: true })}
              onTagsChange={(tags) => setPersonalizationError({ subject: !!tags?.some((tag) => !tag.valid) })}
              allPersonalizations={personalizations}
              personalizationErrors={subjectPersonalizationErrors}
              defaultValue={id ? subject : undefined}
              includeEmoji
              error={isDirty.subject && isSubjectInvalid}
              disabled={disableEditings}
              personalizationModalProps={{
                disableAddRecipientsButton,
                specificIds: specificIdsFromEmailComposer,
                disableListOrSegmentFields: disableSegmentFields,
                disableListOrSegmentFieldsForTemplate: isEmailBlankMessage || isEmailTemplate,
              }}
            />
          }
        >
          <Typography text={t('EmailComposer.Resend.Disabled')} type={TextType.BODY_TEXT_WHITE} />
        </Tooltip>
        {isDirty.subject &&
          isSubjectInvalid &&
          renderValidation(
            t('EmailComposer.Settings.Subject.Invalid', {
              context: subjectMissing ? 'missing' : 'personalizations',
              length: SUBJECT_RECOMMENDED_LENGTH,
            }),
            'ERROR',
            `${rootClass}-error`
          )}
        <div className={`${className}__validation-recommendations`}>
          {renderValidation(
            t('EmailComposer.Settings.Errors.Subject.RecommendedLength', { length: SUBJECT_RECOMMENDED_LENGTH }),
            subjectStatus.recommendedLength,
            `${rootClass}-recommendation-length`
          )}
          {renderValidation(
            t('EmailComposer.Settings.Errors.Subject.RecommendedEmojiCount', {
              length: SUBJECT_RECOMMENDED_EMOJI_COUNT,
            }),
            subjectStatus.recommendedEmojiCount,
            `${rootClass}-recommendation-emoji`
          )}
        </div>
      </div>
      {!hidePreviewText && (
        <div className={`${className}__subsection`}>
          <div className={`${className}__text-row`}>
            <Label>{t('EmailComposer.Settings.Preview')}</Label>
            {!plainTextMode && !uploadHtmlMode && (
              <InfoTooltip minimalPadding={false} position={'right'} iconSize={InfoTooltipIconSize.MEDIUM}>
                {t('EmailComposer.Settings.Preview.Tooltip')}
              </InfoTooltip>
            )}
          </div>
          {plainTextMode || uploadHtmlMode ? (
            <Typography
              text={t(`EmailComposer.Settings.Preview${uploadHtmlMode ? '.Upload' : ''}.Caution`)}
              type={TextType.BODY_TEXT_SMALL_LIGHT}
              dataTest={`${dataTest}-preview-caution`}
            />
          ) : (
            <>
              <Tooltip
                hide={!disableEditings}
                position={'top'}
                trigger={
                  <PersonalizationTagInput
                    className={`${className}__preview-input`}
                    dataTest={`${dataTest}-preview-input`}
                    loading={loading && personalizations === undefined}
                    recommendedLength={PREVIEW_RECOMMENDED_LENGTH}
                    onFocus={isEmailBlankMessage ? undefined : () => setDirty({ previewText: false })}
                    onBlur={isEmailBlankMessage ? undefined : () => setDirty({ previewText: true })}
                    onChange={(text) => onFieldChange('previewText', text)}
                    onTagsChange={(tags) => setPersonalizationError({ previewText: !!tags?.some((tag) => !tag.valid) })}
                    allPersonalizations={personalizations}
                    personalizationErrors={previewPersonalizationErrors}
                    defaultValue={id ? previewText : undefined}
                    error={isDirty.previewText && isPreviewInvalid}
                    disabled={disableEditings}
                    personalizationModalProps={{
                      disableAddRecipientsButton,
                      specificIds: specificIdsFromEmailComposer,
                      disableListOrSegmentFields: disableSegmentFields,
                      disableListOrSegmentFieldsForTemplate: isEmailBlankMessage || isEmailTemplate,
                    }}
                  />
                }
              >
                <Typography text={t('EmailComposer.Resend.Disabled')} type={TextType.BODY_TEXT_WHITE} />
              </Tooltip>
              {isDirty.previewText &&
                isPreviewInvalid &&
                renderValidation(
                  t('EmailComposer.Settings.Subject.Invalid', {
                    context: 'personalizations',
                  }),
                  'ERROR',
                  `${rootClass}-preview-recommendation-emoji`
                )}
              <div className={`${className}__validation-recommendations`}>
                {renderValidation(
                  t('EmailComposer.Settings.Errors.Preview.RecommendedLength', { length: PREVIEW_RECOMMENDED_LENGTH }),
                  previewTextStatus,
                  `${rootClass}-preview-recommendation-length`
                )}
              </div>
            </>
          )}
        </div>
      )}
    </Container>
  )
}

export default EmailComposerSettingsSubject
