import { gql } from '@apollo/client'

export default gql`
  mutation resendUsersVerification($ids: [String]) {
    resendUsersVerification(ids: $ids) {
      status
      message
      count
      deletedUsersIds
      newUsersIds
      importedUsers
      noImportedUsers
      duplicatedUsers
    }
  }
`
