import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'

import InputV2 from '@components/InputV2/InputV2'
import Label from '@components/Label'
import LabelWithSvgTooltip from '@components/LabelWithTooltipIcon/LabelWithSvgTooltip'
import Modal, { ModalBody } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import ModalFooterV2 from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import ModalHeaderV2 from '@components/Modal/components/ModalHeaderV2/ModalHeaderV2'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import {
  CUSTOM_URL_SLUG_VALIDATION_MESSAGES,
  SlugRegExp,
} from '@src/pages/Content/LandingPages/LandingPagesManager/components/SeoUrlModal/constants/SeoUrlModal.constants'
import useSeoUrl from '@src/pages/Content/LandingPages/LandingPagesManager/hooks/useSeoUrl'

import './SeoUrlModal.css'

interface Props {
  onClose: () => void
  onDelete: () => void
  onAddEdit: (name: string, isEdit: boolean) => void
  seoUrl?: string
  dataTest?: string
  accountSlugs?: Array<string | undefined>
}

const rootClass = 'seo-url-modal'

const SeoUrlModal: FC<Props> = ({ onClose, onDelete, onAddEdit, seoUrl = '', dataTest = rootClass, accountSlugs = [] }: Props) => {
  const { t } = useTranslation()
  const { prettyURLPrefix, seoUrl: _seoUrl } = useSeoUrl(seoUrl)
  const isEdit = !!_seoUrl
  const [url, setUrl] = useState<string>(_seoUrl)
  const [error, setError] = useState<string | undefined>(undefined)
  const [isDisabled, setIsDisabled] = useState<boolean>(!(!!error && url.toLowerCase() !== _seoUrl.toLowerCase()))
  const inputRef = React.createRef<HTMLInputElement>()

  useEffect(() => {
    setUrl(_seoUrl)
    setIsDisabled(true)
  }, [_seoUrl])

  const handleSave = useCallback(() => {
    onAddEdit(url, isEdit)
    setUrl(_seoUrl)
  }, [onAddEdit, url, isEdit, _seoUrl])

  const handleClose = useCallback(() => {
    setUrl(_seoUrl)
    onClose()
  }, [onClose, _seoUrl])

  const getErrors = (value: string) => {
    setError(undefined)
    if (accountSlugs.filter((slug) => !isEdit || slug !== _seoUrl).findIndex((slug) => slug?.toLowerCase() === value.toLowerCase()) > -1) {
      setError('isUnique')
      return { errorMessageKey: 'isUnique' }
    }
    const slugifiedUrl = value?.toLowerCase().replace(/\s+/g, '-')
    if (value && !SlugRegExp.test(slugifiedUrl)) {
      setError('invalidCharacters')
      return { errorMessageKey: 'invalidCharacters' }
    }
  }

  const handleURLChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newUrl = event.target.value?.replace(/\s+/g, '-')
      setUrl(newUrl)
      const newError = getErrors(newUrl)
      setIsDisabled(!(newError === undefined && newUrl.toLowerCase() !== _seoUrl.toLowerCase()))
      inputRef.current?.blur()
      inputRef.current?.focus()
    },
    [_seoUrl, getErrors, inputRef]
  )

  const getErrorText = () => {
    if (error === 'isUnique') {
      return CUSTOM_URL_SLUG_VALIDATION_MESSAGES.isUnique
    } else if (error === 'invalidCharacters') {
      return CUSTOM_URL_SLUG_VALIDATION_MESSAGES.invalidCharacters
    }
    return undefined
  }

  return (
    <Modal
      isOpen={!!seoUrl}
      className={rootClass}
      dataTest={dataTest}
      paddingV2
      header={
        <ModalHeaderV2
          headerText={t(`${isEdit ? 'Edit' : 'Add'}.SEO.URL.Modal.Title`)}
          headerType={ModalHeaderType.Form}
          dataTest={`${dataTest}-header`}
        />
      }
    >
      <ModalBody>
        <Typography
          text={t('SEO.URL.Modal.Description', { context: _seoUrl ? 'note' : 'default' })}
          type={TextType.BODY_TEXT_LIGHT}
          tagProps={{ bold: { weight: TextWeight.BOLD } }}
          inline
        />
        <LabelWithSvgTooltip
          className={`${rootClass}__label`}
          label={t('Custom URL slug')}
          tooltipContent={t('SEO.URL.Modal.Tooltip.Message')}
          align="center"
          alignTextCenter
        />
        <div className={`${rootClass}__input`}>
          <InputV2
            placeholder={t('e.g. seo-friendly-url')}
            value={url}
            required
            ref={inputRef}
            statusProps={{
              showErrorOnChange: true,
              validityFunctions: [getErrors],
              tooltipErrorMessages: CUSTOM_URL_SLUG_VALIDATION_MESSAGES,
            }}
            inputInfo={{
              enabled: true,
              helperText: t('SEO.URL.Modal.Input.Help'),
              errorText: !!error ? getErrorText() : undefined,
            }}
            onChange={handleURLChange}
            dataTest={`${dataTest}-input`}
          />
        </div>
        <Label className={`${rootClass}__label`}>{t('URL preview')}</Label>
        <Typography text={prettyURLPrefix} type={TextType.BODY_TEXT_SMALL} inline lineHeight={LineHeight.MEDIUM_SMALL} />
        {url && (
          <Typography
            className={`${rootClass}__url`}
            text={url}
            type={TextType.NORMAL_TEXT_TEAL}
            weight={TextWeight.BOLD}
            inline
            lineHeight={LineHeight.MEDIUM_SMALL}
          />
        )}
      </ModalBody>
      <ModalFooterV2
        footerType={ModalFooterType.Form}
        deleteButton={isEdit ? { onDelete: onDelete, deleteText: t('Delete URL') } : undefined}
        onClose={handleClose}
        buttons={{
          actionButtonDisabled: isDisabled,
          actionButtonLabel: t(isEdit ? 'Save' : 'Create'),
          actionButtonOnClick: handleSave,
          cancelButtonLabel: t('Cancel'),
        }}
      />
    </Modal>
  )
}

export default SeoUrlModal
