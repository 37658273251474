import React, { useState } from 'react'

import { ApolloQueryResult, useApolloClient, useQuery } from '@apollo/client'
import { DirectionOptions } from '@components/TimespanPicker/TimespanPicker'
import { ChartField } from '@const/Chart.constants'
import { isProd, useTranslation } from '@const/globals'
import getWebsiteVisitorsReport from '@graphql/queries/getWebsiteVisitorsReport'
import { GetWebsiteVisitorsReportQuery, QueryGetWebsiteVisitorsReportArgs, WebsiteVisitorsReport } from '@graphql/types/query-types'
import { useAccountSettings, useGetFeatureFlag } from '@utils/account/account.utils'
import { dateYesterdayMidnight, dayOfWeek, getDate, getStandardFormattedDate, monthsForLocale, startTime } from '@utils/date'
import { logNewRelicError } from '@utils/new-relic.utils'
import {
  DailyReportFieldTypes,
  MonthlyReportFieldTypes,
  ReportPropsCommon,
  getDailyVisitors,
  getMapData,
  getReportActivityByDay,
  getWebsiteVisitorsMonthlyComparison,
  handleDateChange,
  numberedListFormatter,
  setStartMonth,
} from '@utils/reports/reports.utils'

import WebsiteVisitors from './WebsiteVisitors'

const months = monthsForLocale('en-US', 'short')

export interface WebsiteVisitorsReportStateState extends ReportPropsCommon {
  dailyVisitors: { fields: ChartField[] }
  topSearchTerms: NumListData
  topVisitingComps: NumListData
  topReferringSites: NumListData
  socialMediaReferrals: NumListData
  monthlyPageDetails: {
    categories: string[]
    fields: ChartField[]
  }
  usMap: MapData[]
  worldMap: MapData[]
  startTimePresentation: string
  endTimePresentation: string
}

interface MapData {
  name: string
  data: any[]
}

interface NumListData {
  label?: string
  data?: any[]
}

const defaultState: WebsiteVisitorsReportStateState = {
  topSearchTerms: {},
  topVisitingComps: {},
  topReferringSites: {},
  socialMediaReferrals: {},
  usMap: [],
  worldMap: [],
  monthlyComparison: {
    rows: [],
    columns: [],
  },
  dailyVisitors: {
    fields: [],
  },
  monthlyPageDetails: {
    categories: [],
    fields: [],
  },
  viewsByDay: {
    categories: [],
    fields: [],
  },
  clicksByDay: {
    categories: [],
    fields: [],
  },
  activityByDay: {
    fields: [],
  },
  startTime: startTime(),
  startTimePresentation: months[setStartMonth(dateYesterdayMidnight)],
  endTime: new Date(dateYesterdayMidnight).toISOString().substring(0, 10),
  endTimePresentation: months[dateYesterdayMidnight.getMonth()],
  nextDisabled: true,
  prevDisabled: false,
  updatedDate: '',
}

const WebsiteVisitorsContainer = () => {
  const [state, setState] = useState<WebsiteVisitorsReportStateState>(defaultState)
  const { accountTimeZoneId } = useAccountSettings()
  const autoRefreshSummaryReports = useGetFeatureFlag('autoRefreshSummaryReports')

  const { t } = useTranslation()
  const client = useApolloClient()

  const {
    loading,
    error: pageError,
    data,
    refetch,
  } = useQuery<GetWebsiteVisitorsReportQuery, QueryGetWebsiteVisitorsReportArgs>(getWebsiteVisitorsReport, {
    client: client,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      startTime: state.startTime,
      endTime: state.endTime,
      onRefresh: false,
    },
  })

  const onDateChange = (direction: DirectionOptions) => {
    handleDateChange(state, setState, direction)
  }

  const getDebugReport = () => {
    if (window.location.search === '?debug' && !isProd()) {
      const response = JSON.parse(localStorage.getItem('WebsiteVisitorsReport:debugReportJson') ?? '{}') as ApolloQueryResult<WebsiteVisitorsReport>
      if ('data' in response && 'getWebsiteVisitorsReport' in response.data) {
        return response.data
      }
    }
  }

  const updateData = (data: any) => {
    if (!data) {
      logNewRelicError(pageError, 'Fetching website visitors report data failed')
      return defaultState
    }
    const dailyFieldInfo = [
      {
        name: t('Anonymous Visitors'),
        fieldName: DailyReportFieldTypes.ANONYMOUS,
        color: '#B0BADB',
      },
      {
        name: t('Known Prospects'),
        fieldName: DailyReportFieldTypes.KNOWN,
        color: '#00DCE8',
      },
      {
        name: t('Companies'),
        fieldName: DailyReportFieldTypes.COMPANIES,
        color: '#FFA27A',
      },
    ]
    const monthlySummary = data.getWebsiteVisitorsReport.map((report: any) => {
      const monthYear = report.monthName
      const [month, year] = monthYear.split(' ')
      const counts = report.counts.map((counts: any) => {
        const name = getDate(`${month} ${counts.dayOfMonth}, ${year}`, 0, counts.dayOfMonth)
        return { ...counts, name }
      })
      return { ...report, days: counts }
    })

    const weeklySumFields = [
      {
        name: t('Anonymous Visitors'),
        fieldName: MonthlyReportFieldTypes.ANONYMOUS,
        color: '#B1BAD9',
      },
      {
        name: t('Known Prospects'),
        fieldName: MonthlyReportFieldTypes.KNOWN,
        color: '#00DCE8',
      },
    ]

    const weeklySummary: any = {
      weekdayCounts: Array.from(Array(7)).map((_, index) => ({ name: dayOfWeek(t, index), anonymous: 0, known: 0 })),
    }
    monthlySummary.map((month: any) => {
      month.days.map((day: any) => {
        const dayOfWeekZeroIndex = day.dayOfWeek - 1
        weeklySummary.weekdayCounts[dayOfWeekZeroIndex] = {
          name: dayOfWeek(t, dayOfWeekZeroIndex),
          anonymous:
            typeof weeklySummary.weekdayCounts[dayOfWeekZeroIndex] !== 'undefined'
              ? weeklySummary.weekdayCounts[dayOfWeekZeroIndex].anonymous + day.anonymous
              : day.anonymous,
          known:
            typeof weeklySummary.weekdayCounts[dayOfWeekZeroIndex] !== 'undefined'
              ? weeklySummary.weekdayCounts[dayOfWeekZeroIndex].known + day.known
              : day.known,
        }
      })
    })

    const dailyVisitors = getDailyVisitors(monthlySummary, dailyFieldInfo)
    const monthlyFieldInfo = [
      {
        name: t('People Visiting'),
        fieldName: MonthlyReportFieldTypes.TOTALVIEWS,
      },
      {
        name: t('Conversions'),
        fieldName: MonthlyReportFieldTypes.CONVERSIONS,
        title: 'Number of Previously Anonymous Visitors Who Became Known',
      },
      {
        name: t('Bounces'),
        fieldName: MonthlyReportFieldTypes.BOUNCESTOTAL,
        title: 'Number of People Leaving After Visiting Just 1 Page',
        isHighlighted: true,
      },
      {
        name: t('Companies'),
        fieldName: MonthlyReportFieldTypes.COMPANYTOTAL,
      },
    ]

    const childrenFieldInfo = [
      {
        name: t('Known'),
      },
      {
        name: t('Anonymous'),
      },
    ]

    const monthlyComparison = getWebsiteVisitorsMonthlyComparison(data.getWebsiteVisitorsReport, monthlyFieldInfo, childrenFieldInfo)
    const worldMap = getMapData(data.getWebsiteVisitorsReport, 'countries')
    const usMap = getMapData(data.getWebsiteVisitorsReport, 'states')
    const topVisitingComps = numberedListFormatter(data.getWebsiteVisitorsReport, 'companies', 'name', t('Top Companies Visiting'))
    const socialMediaReferrals = numberedListFormatter(data.getWebsiteVisitorsReport, 'socialMedia', 'site', t('Referrals From Social Media Sites'))
    const topSearchTerms = numberedListFormatter(data.getWebsiteVisitorsReport, 'searches', 'key', t('Top Search Terms'))
    const topReferringSites = numberedListFormatter(data.getWebsiteVisitorsReport, 'referrers', 'site', t('Top Referring Sites'))
    const activityByDay = getReportActivityByDay(weeklySummary, weeklySumFields)

    return {
      monthlyComparison,
      dailyVisitors,
      activityByDay,
      worldMap,
      usMap,
      topReferringSites,
      topVisitingComps,
      socialMediaReferrals,
      topSearchTerms,
      updatedDate: data.getWebsiteVisitorsReport[0].asof.asofTime,
    }
  }

  const refreshOnClick = !autoRefreshSummaryReports
    ? () => {
        refetch({ onRefresh: true })
      }
    : undefined

  const responseData = updateData(getDebugReport() ?? data)
  const formattedData = !loading && !pageError ? responseData : defaultState

  return (
    <WebsiteVisitors
      monthlyComparison={formattedData.monthlyComparison}
      topVisitingComps={formattedData.topVisitingComps}
      topReferringSites={formattedData.topReferringSites}
      topSearchTerms={formattedData.topSearchTerms}
      referrals={formattedData.socialMediaReferrals}
      usMap={formattedData.usMap ?? []}
      worldMap={formattedData.worldMap ?? []}
      activityByDayOfWeek={formattedData.activityByDay}
      activityByDay={formattedData.activityByDay}
      loading={loading}
      dailyVisitors={formattedData.dailyVisitors}
      updatedDate={
        formattedData.updatedDate && formattedData.updatedDate !== '0'
          ? getStandardFormattedDate(new Date(parseInt(formattedData.updatedDate)).valueOf(), true, accountTimeZoneId)
          : getStandardFormattedDate(dateYesterdayMidnight.valueOf(), true, accountTimeZoneId)
      }
      startTime={state.startTimePresentation}
      endTime={state.endTimePresentation}
      onDateChange={onDateChange}
      nextDisabled={state.nextDisabled}
      prevDisabled={state.prevDisabled}
      refreshOnClick={refreshOnClick}
    />
  )
}

export default WebsiteVisitorsContainer
