import React, { FC, useState } from 'react'

import classNames from 'classnames'

import ListPickerModal from '@complex/ListPickerModalV2/ListPickerModal'
import { ListPickerModalProps } from '@complex/ListPickerModalV2/utils/ListPickerModal.props'
import Button, { ButtonType } from '@components/Button'
import Svg, { SvgNames } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { List } from '@interface/foldersLists/List'

import './ListSelector.css'

interface ListSelectorProps {
  className?: string
  dataTest?: string
  onShowListPickerChange?: (isShown: boolean) => void
  onSubmit: (list: List) => void
  onRemove?: () => void
  selectedList?: List
  buttonText?: string
  buttonType?: ButtonType
  defaultInfoText?: string
  listPickerOverrideProps?: Partial<ListPickerModalProps>
}

const rootClass = 'list-selector'

const ListSelector: FC<ListSelectorProps> = (props: ListSelectorProps) => {
  const {
    dataTest = rootClass,
    className = '',
    buttonText = 'Select a segment',
    buttonType = ButtonType.SECONDARY,
    selectedList,
    onShowListPickerChange: onShowListPickerChangeProp,
    onSubmit,
    onRemove,
    defaultInfoText,
    listPickerOverrideProps = {},
  } = props

  const [showListPicker, setShowListPicker] = useState(false)

  const { t } = useTranslation()

  const onShowListPickerChange = (showListPicker: boolean) => {
    onShowListPickerChangeProp?.(showListPicker)
    setShowListPicker(showListPicker)
  }

  const submitLists = ([list]: List[]) => {
    onSubmit(list)
    onShowListPickerChange(false)
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Button dataTest={`${dataTest}-button`} className={`${rootClass}__button`} buttonType={buttonType} onClick={() => onShowListPickerChange(true)}>
        <Svg name={SvgNames.listCheckNoFill} />
        {t(buttonText)}
      </Button>
      {selectedList || defaultInfoText ? (
        <div className={`${rootClass}__info`}>
          <div className={`${rootClass}__info-text`}>
            {selectedList ? (
              <>
                <Typography
                  className={'ellip'}
                  text={selectedList.name}
                  type={TextType.BODY_TEXT_SMALL}
                  weight={TextWeight.MEDIUM}
                  lineHeight={LineHeight.MEDIUM_SMALL}
                />
                <Typography
                  dataTest={`${dataTest}-records-amount`}
                  text={`${selectedList.size} ${t('contacts')}`}
                  type={TextType.BODY_TEXT_SMALL_LIGHT}
                  lineHeight={LineHeight.MEDIUM_SMALL}
                />
              </>
            ) : (
              <Typography
                className={'ellip'}
                text={defaultInfoText}
                type={TextType.BODY_TEXT_SMALL}
                weight={TextWeight.MEDIUM}
                lineHeight={LineHeight.MEDIUM_SMALL}
              />
            )}
          </div>
          {!!onRemove && (
            <Tooltip
              triggerClassName={`${rootClass}__delete-source`}
              trigger={
                <Button dataTest={`${dataTest}-delete-source`} buttonType={ButtonType.ICON} onClick={onRemove}>
                  <Svg name={SvgNames.delete} />
                </Button>
              }
            >
              {t('Remove')}
            </Tooltip>
          )}
        </div>
      ) : (
        <div className={`${rootClass}__no-list-selected`}>
          <Typography text={t(`No list selected`)} weight={TextWeight.ITALIC} type={TextType.BODY_TEXT_LIGHT} />
        </div>
      )}
      {showListPicker && (
        <ListPickerModal
          closeModal={() => onShowListPickerChange(false)}
          submitLists={submitLists}
          multiSelect={false}
          defaultSelectedLists={[...(selectedList ? [selectedList.id] : [])]}
          hideLegacyLists
          hideLegacyListToggle
          isOpen
          {...listPickerOverrideProps}
        />
      )}
    </div>
  )
}

export default ListSelector
