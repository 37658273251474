import { gql } from '@apollo/client'

export default gql`
  query getMessageRollupReadyResponse(
    $programName: String!
    $campaignId: String
    $msgIds: [String]
    $fromEmail: String
    $startDate: String
    $endDate: String
    $fileType: String
  ) {
    getMessageRollupReadyResponse(
      programName: $programName
      campaignId: $campaignId
      msgIds: $msgIds
      fromEmail: $fromEmail
      startDate: $startDate
      endDate: $endDate
      fileType: $fileType
    ) {
      status
      message
      result
      xml
    }
  }
`
