import React, { FC, useCallback, useContext } from 'react'

import classNames from 'classnames'

import ListPickerModal from '@complex/ListPickerModalV2/ListPickerModal'
import Button, { ButtonType } from '@components/Button'
import { Checkbox } from '@components/Checkbox/Checkbox'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import Container from '@components/Container'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import Loader from '@components/Loader'
import PageContainer from '@components/PageContainer'
import PageHeader from '@components/PageHeader'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import { SwitchOptions } from '@components/Switch/Switch'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { List } from '@interface/foldersLists/List'
import { isNumberOfSplitsValid } from '@src/pages/ContactSegments/components/ABSplit/ABSplit.utils'
import { ABSplitContext } from '@src/pages/ContactSegments/components/ABSplit/ABSplitContext'
import ABSplitFirstSection from '@src/pages/ContactSegments/components/ABSplit/components/ABSplitFirstSection/ABSplitFirstSection'
import ABSplitSecondSection from '@src/pages/ContactSegments/components/ABSplit/components/ABSplitSecondSection/ABSplitSecondSection'
import ABSplitSummary from '@src/pages/ContactSegments/components/ABSplit/components/ABSplitSummary/ABSplitSummary'
import { VoidFunction } from '@utils/types'

import './ABSplit.css'

export interface ABSplitProps {
  className?: string
  dataTest?: string
  onSubmit: VoidFunction
}

const rootClass = 'ab-split'

const ABSplit: FC<ABSplitProps> = (props: ABSplitProps) => {
  const { dataTest = rootClass, onSubmit } = props
  const { t } = useTranslation()
  const {
    values: { numberOfSplits, splitName, selectedDistribution, changeDistribution, inputError, showListPicker, sources, sourceToRemove, baseSegment },
    update,
    onAddOrRemoveSource,
  } = useContext(ABSplitContext)
  const closeListPicker = useCallback(() => update('showListPicker', false), [])

  const onSubmitLists = useCallback(
    (lists: List[]) => {
      onAddOrRemoveSource(
        lists.map(({ id, name, size }) => {
          return { id, name, size, suppressed: 0 }
        })
      )
      closeListPicker()
    },
    [sources, baseSegment]
  )

  const renderListPicker = () => (
    <ListPickerModal
      dataTest={`${dataTest}__list-picker`}
      closeModal={closeListPicker}
      isOpen={showListPicker}
      submitLists={onSubmitLists}
      defaultSelectedLists={sources.map(({ id }) => id)}
      disabledLists={baseSegment ? [baseSegment.externalId] : undefined}
    />
  )
  const canSubmit = splitName.length !== 0 && isNumberOfSplitsValid(numberOfSplits) && !inputError
  return (
    <PageContainer className={rootClass} dataTest={dataTest}>
      {showListPicker && renderListPicker()}
      {sourceToRemove && (
        <DeleteConfirmationModal
          isOpen
          title={t('Are you sure you want to remove this source?')}
          deleteButtonText={t('Remove source')}
          body={t('This source will be removed from the list. You can always add it back by clicking on Select segments to suppress.')}
          onAnswer={(answer: YesNo) => {
            if (answer === YesNo.YES) {
              onAddOrRemoveSource(sources.filter(({ id }) => id != sourceToRemove.id))
            }
            update('sourceToRemove', undefined)
          }}
        />
      )}
      {changeDistribution && (
        <ConfirmationModal
          isOpen
          isYesNo
          title={t('Are you sure you want to change the distribution?')}
          cancelButtonText={'Cancel'}
          body={t(
            'Changing the distribution will restore {{numberOfSplits}} equal splits.  Any custom modifications to the split amounts will be reset.',
            { numberOfSplits: numberOfSplits }
          )}
          onAnswer={(answer: YesNo) => {
            if (answer === YesNo.YES) {
              update('selectedDistribution', selectedDistribution === SwitchOptions.NUMBER ? SwitchOptions.PERCENT : SwitchOptions.NUMBER)
            }
            update('changeDistribution', false)
          }}
        />
      )}
      {!baseSegment ? (
        <Loader className={`${rootClass}__loader`} />
      ) : (
        <PositionContainer>
          <PageHeader
            className={classNames(`${rootClass}__header`, `${rootClass}__header-main`)}
            primaryText={t('A/B split - {{segmentName}}', { segmentName: baseSegment?.name })}
            linkBack
            leftContent
          />
          <div className={`${rootClass}__subheader`}>
            <Typography text={t('Split your segment for A/B testing.')} type={TextType.BODY_TEXT_LIGHT} inline />
            <TextLink text={t('More info')} link={'https://connect.act-on.com'} size={TextLinkSize.LARGE} />
          </div>
          <div className={`${rootClass}__content`}>
            <div className={`${rootClass}__content-left`}>
              <form noValidate>
                <ABSplitFirstSection className={`${rootClass}__section`} />
                <ABSplitSecondSection className={`${rootClass}__section`} />
                <Container className={`${rootClass}__section`}>
                  <Typography
                    className={`${rootClass}__header-regenerate`}
                    text={t('3. Regenerate splits')}
                    type={TextType.BODY_TEXT_LARGE}
                    weight={TextWeight.MEDIUM}
                  />
                  <Checkbox label={t('Allow splits to be regenerated on demand')} onChange={(checked) => update('doRegenerate', checked)} />
                </Container>
              </form>
              <Button buttonType={ButtonType.PRIMARY} disabled={!canSubmit} onClick={onSubmit}>
                {t('Submit')}
              </Button>
            </div>
            <div className={`${rootClass}__content-right`}>
              <ABSplitSummary segmentName={baseSegment?.name} />
            </div>
          </div>
        </PositionContainer>
      )}
    </PageContainer>
  )
}

export default ABSplit
