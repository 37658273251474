import React, { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import classNames from 'classnames'
import * as yup from 'yup'

import Button, { ButtonIconPosition, ButtonType } from '@components/Button/Button'
import Checkbox from '@components/Checkbox'
import FormRow from '@components/FormRow/FormRow'
import Input from '@components/Input/Input'
import Label from '@components/Label/Label'
import { ModalBody } from '@components/Modal'
import MoreOptions from '@components/MoreOptions/MoreOptions'
import Select from '@components/Select/Select'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import TextArea from '@components/TextArea/TextArea'
import Typography, { TextAlign, TextType, TextWeight } from '@components/Typography/Typography'
import { CrmNames } from '@const/crmNames'
import { useTranslation } from '@const/globals'
import { ScoreSheet } from '@graphql/types/microservice/list-types'
import { Campaign, Program } from '@graphql/types/query-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { useGetEditAlertStepQueries } from '@src/pages/listingPages/AutomatedPrograms/GraphQL/EditAlertStep.graphQL'
import SendAlertDetail from '@src/pages/programs/dashboard/components/ProgramSteps/components/SendAlertDetail/SendAlertDetail'
import { convertUserIdsToNames, findMessageName } from '@src/pages/programs/dashboard/components/ProgramSteps/ProgramSteps.utils'
import { logError } from '@utils/env'
import { ProgramAlertStepExt, Step, Track } from '@utils/program/program.constants'
import { ProgramWithStepData } from '@utils/program/ProgramSteps.constants'

import './editAlertStep.css'

const rootClass = 'edit-alert-step'

interface Props {
  step: Step
  tracks: Track[]
  isRunning: boolean
  saveStepAndProgram(step: Step): void
  submitId: string
  dataTest?: string
  program: Program
  className?: string
}

interface State {
  userIds: number[]
  emails: string[]
  rule: string
  campaigns: Campaign[]
  scoreSheets: ScoreSheet[]
  selectedCampaign?: string
  selectedScoreSheet?: string
  hideContactData?: boolean
  crmOwner?: boolean
}

const schema = yup.object().shape({
  displayName: yup.string().required('Step Name is required.'),
})

function getUserOptions(program: Program, t: Function) {
  return program.users?.reduce(
    (acc, cur) => {
      return [
        ...acc,
        <option key={cur.id} value={cur.id}>
          {`${cur.firstName} ${cur.lastName}`}
        </option>,
      ]
    },
    [
      <option key="empty" value="">
        --- {t('Select User')} ---
      </option>,
    ]
  )
}

const EditAlertStep: FC<Props> = (props: Props) => {
  const { step, program, isRunning, saveStepAndProgram, submitId, dataTest = 'edit-alert-step' } = props

  const { getScoreSheetsRequest, getCampaigns } = useGetEditAlertStepQueries()

  const alertStep = step as ProgramAlertStepExt
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })

  enum ChangeFieldRulesCustom {
    SET_BEHAVIORAL_SCORE = 'SCORE',
    SET_CAMPAIGN_SCORE = 'CAMPAIGN',
  }

  const ChangeFieldRulesLabelsCustom: { [key in ChangeFieldRulesCustom]: string } = {
    [ChangeFieldRulesCustom.SET_BEHAVIORAL_SCORE]: 'Set value to behavioral score',
    [ChangeFieldRulesCustom.SET_CAMPAIGN_SCORE]: 'Set value to campaign score',
  }

  const [state, setState] = useState<State>({
    userIds: alertStep.userIds && alertStep.userIds.length > 0 ? alertStep.userIds : [],
    emails: alertStep.emails && alertStep.emails.length > 0 ? alertStep.emails : [],
    rule: ChangeFieldRulesCustom.SET_BEHAVIORAL_SCORE,
    campaigns: [],
    scoreSheets: [],
    selectedCampaign: alertStep.campaignId ? alertStep.campaignId : '',
    selectedScoreSheet: alertStep.scoreSheetId ? alertStep.scoreSheetId : '',
    hideContactData: alertStep.hideContactData ? alertStep.hideContactData : false,
    crmOwner: alertStep.crmOwner ? alertStep.crmOwner : false,
  })

  const onRuleChange = (selectedValue: string) => {
    setState({
      ...state,
      rule:
        selectedValue === ChangeFieldRulesCustom.SET_BEHAVIORAL_SCORE
          ? ChangeFieldRulesCustom.SET_BEHAVIORAL_SCORE
          : ChangeFieldRulesCustom.SET_CAMPAIGN_SCORE,
      selectedCampaign: selectedValue === ChangeFieldRulesCustom.SET_BEHAVIORAL_SCORE ? '' : state.campaigns[0].id,
    })
  }

  const onChange = (selectedValue: string | boolean, key: string) => {
    setState({
      ...state,
      [key]: selectedValue,
    })
  }
  const onChangeCheck = (checked: boolean) => {
    setState({ ...state, hideContactData: checked })
  }

  useEffect(() => {
    Promise.all([getScoreSheetsRequest(), getCampaigns()]).then(([{ data: scoreSheetsData }, { data: campaignsData }]) => {
      const scoreSheets = scoreSheetsData?.scoreSheets as ScoreSheet[]
      setState((state) => ({
        ...state,
        scoreSheets,
        campaigns: campaignsData ? campaignsData.campaigns : [],
        selectedScoreSheet: alertStep.scoreSheetId || scoreSheets[0].id,
        rule: state.selectedCampaign == '' ? ChangeFieldRulesCustom.SET_BEHAVIORAL_SCORE : ChangeFieldRulesCustom.SET_CAMPAIGN_SCORE,
        hideContactData: state.hideContactData,
      }))
    })
  }, [])

  const renderOptions = (values: Partial<{ id: string; name: string }>[], emptyText: string) => {
    return values.length === 0 ? (
      <option disabled key={'no-option'}>
        {t(emptyText)}
      </option>
    ) : (
      values.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))
    )
  }

  const onSubmit = (data: any) => {
    const newStep = {
      ...step,
      ...data,
      userIds: getUserIds(state.userIds.map((userId) => String(userId))),
      emails: state.emails || [],
      scoreSheetId: state.selectedScoreSheet,
      campaignId: state.selectedCampaign,
      hideContactData: state.hideContactData,
      crmOwner: state.crmOwner,
    }
    saveStepAndProgram(newStep)
  }

  const { t } = useTranslation()

  const getUserIds = (userIds?: string[]) => {
    if (userIds) {
      try {
        return userIds
          .map((userId) => {
            if (userId?.trim().length > 0) {
              return parseInt(userId)
            }
            return null
          })
          .filter((value) => !!value && value !== -1)
      } catch (e) {
        logError(e)
      }
    }
    return []
  }

  const update = (i: number, e: React.ChangeEvent<HTMLSelectElement>) => {
    const updatedIds = [...state.userIds.slice(0, i), parseInt(e.target.value), ...state.userIds.slice(i + 1)]
    setState({ ...state, userIds: updatedIds })
  }

  const updateEmails = (i: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedEmails = [...state.emails.slice(0, i), e.target.value, ...state.emails.slice(i + 1)]
    setState({ ...state, emails: updatedEmails })
  }

  const remove = (i: number, stateArray: any[], field: string) => {
    const updatedIds = [...stateArray.slice(0, i), ...stateArray.slice(i + 1)]
    setState({ ...state, [field]: updatedIds })
  }

  const renderForm = () => (
    <form data-test={dataTest} onSubmit={handleSubmit(onSubmit)} className={rootClass}>
      <FormRow>
        <Input label={t('Step Name')} defaultValue={step.displayName} name="displayName" register={register('displayName')} />
        {errors.displayName && <span className="error">{t(errors.displayName.message)}</span>}
      </FormRow>
      <FormRow className={`${rootClass}__border ${rootClass}__row`}>
        <Label>{t('Send to Users')}</Label>
        {state.userIds.map((userId, i) => {
          const canDelete = i !== 0
          return (
            <div key={i} className={`${rootClass}__row-select`}>
              <Select
                className={`${rootClass}__users`}
                dataTest={`userId-select`}
                name={`userIds[${i}]`}
                register={register(`userIds[${i}]`, { onChange: (event) => update(i, event) })}
                value={`${userId}`}
              >
                {getUserOptions(program, t)}
              </Select>
              {canDelete && (
                <Button
                  data-test={`${dataTest}-delete-user-${i}`}
                  buttonType={ButtonType.REMOVE}
                  iconPosition={ButtonIconPosition.FLOAT}
                  title={t('Delete User')}
                  onClick={() => remove(i, state.userIds, 'userIds')}
                >
                  <Svg name={SvgNames.delete} type={SvgType.ICON} />
                </Button>
              )}
            </div>
          )
        })}
        <div>
          <Button
            data-test={`${dataTest}-add-user`}
            buttonType={ButtonType.FLOAT}
            title={t('Add User')}
            onClick={() => {
              setState({ ...state, userIds: [...state.userIds, -1] })
            }}
          >
            <Svg name={SvgNames.plus} type={SvgType.ICON} />
            {t('Add User')}
          </Button>
        </div>
      </FormRow>
      <FormRow className={`${rootClass}__border ${rootClass}__row`}>
        <Label>{t('Send to Emails')}</Label>
        {state.emails.map((email, i) => {
          return (
            <div key={i} className={`${rootClass}__row-input`}>
              <Input
                className={`${rootClass}__emails`}
                dataTest={`email-input`}
                register={register(`emails[${i}]`, { onChange: (e) => updateEmails(i, e) })}
                value={email}
              />
              <Button
                data-test={`${dataTest}-delete-email-${i}`}
                buttonType={ButtonType.REMOVE}
                iconPosition={ButtonIconPosition.FLOAT}
                title={t('Delete Email')}
                onClick={() => remove(i, state.emails, 'emails')}
              >
                <Svg name={SvgNames.delete} type={SvgType.ICON} />
              </Button>
            </div>
          )
        })}
        {errors.emails && <span className="error">{t(errors.emails.message)}</span>}
        <div>
          <Button
            data-test={`${dataTest}-add-user`}
            buttonType={ButtonType.FLOAT}
            title={t('Add Email')}
            onClick={() => {
              setState({ ...state, emails: [...state.emails, ''] })
            }}
          >
            <Svg name={SvgNames.plus} type={SvgType.ICON} />
            {t('Add Email')}
          </Button>
        </div>
      </FormRow>
      {program.crm &&
        [
          CrmNames.SUGAR.valueOf(),
          CrmNames.SALESFORCE.valueOf(),
          CrmNames.MSDYNAMICS.valueOf(),
          CrmNames.NETSUITE.valueOf(),
          CrmNames.NETSUITECRM.valueOf(),
        ].includes(program.crm?.crmName) && (
          <Checkbox
            dataTest={`${dataTest}__send-crm`}
            defaultChecked={alertStep.crmOwner}
            onChange={(value) => onChange(value, 'crmOwner')}
            label={t('Send to CRM Owners')}
          />
        )}
      <div className={`${rootClass}__update-options`}>
        <div className={classNames(`${rootClass}__update-options-select`, { [`${rootClass}__update-options-select-wider`]: '' })}>
          <Select
            dataTest={`${dataTest}-update-value-select`}
            value={state.rule}
            label={t('Select Score Sheet to Use')}
            onChange={({ target: { value } }) => onRuleChange(value)}
          >
            {Object.entries(ChangeFieldRulesCustom).map(([key, value]) => (
              <option key={`option-${key}`} value={value}>
                {t(ChangeFieldRulesLabelsCustom[value])}
              </option>
            ))}
          </Select>
        </div>
      </div>
      <div />
      {(state.rule === ChangeFieldRulesCustom.SET_CAMPAIGN_SCORE || state.rule === ChangeFieldRulesCustom.SET_BEHAVIORAL_SCORE) && (
        <MoreOptions dataTest={`${dataTest}-score-options`} className={`${rootClass}__more-options`}>
          <Select
            label={t('Choose score sheet')}
            value={state.selectedScoreSheet}
            onChange={({ target: { value } }) => onChange(value, 'selectedScoreSheet')}
          >
            {renderOptions(state.scoreSheets, 'No score sheets have been defined')}
          </Select>
          {(state.rule === ChangeFieldRulesCustom.SET_CAMPAIGN_SCORE || state.selectedCampaign !== '') && (
            <Select
              value={state.selectedCampaign}
              label={t('Campaign name')}
              onChange={({ target: { value } }) => onChange(value, 'selectedCampaign')}
            >
              {renderOptions(state.campaigns, 'No campaigns have been defined')}
            </Select>
          )}
        </MoreOptions>
      )}
      <FormRow>
        <Input
          label={t('Subject')}
          register={register('subject')}
          defaultValue={`${[...(alertStep.subject ? [alertStep.subject] : [])].join(' ')}`}
          name="subject"
        />
      </FormRow>
      <FormRow>
        <TextArea
          className={`${rootClass}__message`}
          register={register('message')}
          label={t('Message')}
          name="message"
          defaultValue={alertStep.message ?? ''}
          placeholder={t("Share a note, we'll pass along all the details...")}
        />
      </FormRow>
      <div className={`${rootClass}__disclaimer`}>
        <Typography
          text={t(
            '* The alert email will include a report link. Click the report link and log in to see the people who passed through this program step. The alert email will be sent no more than once an hour.'
          )}
          type={TextType.BODY_TEXT_SMALL_LIGHT}
          textAlign={TextAlign.CENTER}
        />
      </div>
      <FormRow>
        <Checkbox
          defaultChecked={alertStep.hideContactData}
          onChange={(checked) => onChangeCheck(checked)}
          label={t('Do not include contact data on email alert')}
        />
      </FormRow>
      <button type="submit" id={submitId} hidden />
    </form>
  )

  const renderView = () => (
    <>
      <Typography text={step.displayName} weight={TextWeight.MEDIUM} type={TextType.SECTION_HEADER} />
      <SendAlertDetail
        message={findMessageName(alertStep.message ?? '', program.messages)}
        crmOwner={alertStep.crmOwner ?? false}
        emails={alertStep.emails ?? []}
        subject={alertStep.subject ?? ''}
        users={convertUserIdsToNames(program as ProgramWithStepData, alertStep.userIds)}
      />
    </>
  )

  return <ModalBody>{isRunning ? renderView() : renderForm()}</ModalBody>
}

export default EditAlertStep
