import { gql } from '@apollo/client'

export default gql`
  mutation createNewBlankMessage($programId: String, $beeComposer: Boolean) {
    createNewBlankMessage(programId: $programId, beeComposer: $beeComposer) {
      id
      title
      isBeeComposer
    }
  }
`
