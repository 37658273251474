import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import classNames from 'classnames'

import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import { InputProps } from '@components/Input/Input'
import InputWithClear from '@components/InputWithClear/InputWithClear'
import SectionWithHeader from '@components/SectionWithHeader/SectionWithHeader'
import SingleSelectDropdownOption from '@components/SingleSelectDropdown/components/option/SingleSelectDropdownOption'
import SingleSelectDropdownPortal from '@components/SingleSelectDropdown/components/portal/SingleSelectDropdownPortal'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import SINGLE_SELECT_DROPDOWN_SCROLLABLE_CONTAINER from '@const/singleSelectDropdown'
import { SelectOption } from '@interface/Select'
import { querySelector } from '@utils/document'

import './SingleSelectDropdown.css'

export interface SingleSelectDropdownProps<T extends SelectOption> {
  options: T[]
  onSubmit: (value?: T['value'], option?: T) => void
  // To reset selection, controlled 'value' can be passed as null
  value?: string | null
  defaultValue?: string
  placeholder?: string
  index?: number
  allowDeselect?: boolean
  className?: string
  dataTest?: string
  hasError?: boolean
  disabled?: boolean
}

const rootClass = 'single-select-dropdown'
const optionLimit = 5

const SingleSelectDropdown = <T extends SelectOption>(props: SingleSelectDropdownProps<T>) => {
  const { t } = useTranslation()
  const {
    options = [],
    onSubmit,
    value,
    defaultValue,
    placeholder = t('Select Option'),
    index,
    allowDeselect = true,
    className = '',
    dataTest = rootClass,
    hasError = false,
    disabled = false,
  } = props
  const ref = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [isActive, setIsActive] = useState<boolean>(false)
  const [currentValue, setCurrentValue] = useState<string | undefined>(defaultValue)
  const _selectedValue = value === undefined ? currentValue : value
  const title =
    typeof _selectedValue === 'string'
      ? options.find((option) => option.value === _selectedValue)?.label ?? (_selectedValue || placeholder)
      : placeholder

  const filteredOptions = useMemo(
    () =>
      options.reduce((options: Map<string, T[]>, option) => {
        const { label, value, category = '' } = option
        const query = searchQuery.toLowerCase()
        if (label?.toLowerCase().includes(query) || value.toLowerCase().includes(query)) {
          const categoryOptions = options.get(category) ?? []
          return options.set(category, [...categoryOptions, option])
        }
        return options
      }, new Map()),
    [options, searchQuery]
  )
  const filteredOptionsArray = useMemo(() => Array.from(filteredOptions).flatMap(([_, options]) => options), [filteredOptions])

  const optionLimitCheck = !!(searchQuery || filteredOptionsArray.length >= optionLimit)

  useEffect(() => {
    if (isActive && inputRef.current) {
      inputRef.current?.focus()
    }
  }, [isActive])

  useEffect(() => {
    let scrollableContainer = querySelector(`[data-test=${SINGLE_SELECT_DROPDOWN_SCROLLABLE_CONTAINER}]`)
    if (!scrollableContainer) {
      scrollableContainer = document.getElementById('modal-body')
    }
    if (!scrollableContainer) {
      scrollableContainer = document.getElementById('page-container')
    }
    const closeDropdown = () => setIsActive(false)

    window.addEventListener('resize', closeDropdown)
    scrollableContainer?.addEventListener('scroll', closeDropdown)

    return () => {
      window.removeEventListener('resize', closeDropdown)
      scrollableContainer?.removeEventListener('scroll', closeDropdown)
    }
  }, [])

  const searchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value), [])
  const clearSearch = useCallback(() => setSearchQuery(''), [])
  const handleDropdownClose = useCallback(() => setIsActive(false), [])
  const toggleSelect = useCallback(() => setIsActive((current) => !current), [])

  const submitSelected = useCallback(
    (option: T, isDeselect: boolean) => {
      clearSearch()
      setIsActive(false)
      if (isDeselect && !allowDeselect) {
        return
      }
      const selectedValue = option.value
      setCurrentValue(isDeselect ? undefined : selectedValue)
      isDeselect ? onSubmit() : onSubmit(selectedValue, option)
    },
    [allowDeselect, onSubmit]
  )

  const renderOptions = (options: T[]) =>
    options.map((option) => (
      <SingleSelectDropdownOption
        option={option}
        selected={_selectedValue === option.value}
        onClick={submitSelected}
        key={option.value}
        dataTest={dataTest}
      />
    ))

  const renderMenu = () => (
    <div className={classNames(`${rootClass}__options`)} data-test={`${dataTest}-options`}>
      {Array.from(filteredOptions).map(([category, options]) =>
        category ? (
          <SectionWithHeader key={category} className={`${rootClass}__category-options`} header={category}>
            {renderOptions(options)}
          </SectionWithHeader>
        ) : (
          renderOptions(options)
        )
      )}
    </div>
  )

  const inputProps: InputProps = {
    value: searchQuery,
    className: `${rootClass}__search`,
    placeholder: t('Search...'),
    register: inputRef,
    icon: SvgNames.search,
    dataTest,
    inputAriaLabel: 'searchInput',
    onChange: searchChange,
  }
  const indexProp = index !== undefined ? { tabIndex: index } : {}

  return (
    <div
      className={classNames(rootClass, className, { [`${rootClass}__disabled`]: disabled })}
      data-test={dataTest}
      ref={ref}
      aria-label={'single-select-dropdown'}
    >
      <div className={classNames(`${rootClass}__wrapper`, { [`${rootClass}__wrapper-disabled`]: disabled })}>
        {isActive && optionLimitCheck ? (
          <div className={`${rootClass}__search-wrapper`}>
            <InputWithClear inputProps={inputProps} onClear={clearSearch} />
          </div>
        ) : (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <div
            {...indexProp}
            className={classNames(`${rootClass}__faux-select`, {
              [`${rootClass}__faux-select--active`]: isActive,
              [`${rootClass}__faux-select--error`]: hasError,
              [`${rootClass}__faux-select--disabled`]: disabled,
            })}
            data-test={`${dataTest}-select`}
            onClick={toggleSelect}
            onFocus={toggleSelect}
            onMouseDown={(e) => e.preventDefault()}
            aria-label={'single-select-option'}
          >
            {title}
            <Svg name={SvgNames.caretFillDown} type={SvgType.LIGHT_SMALLER_ICON} fill={SvgColor.LIGHT_GRAY} />
          </div>
        )}
        {isActive && (
          <SingleSelectDropdownPortal selectElement={ref.current} onClose={handleDropdownClose}>
            {filteredOptionsArray.length ? (
              renderMenu()
            ) : (
              <EmptyListing
                text={t('No results found')}
                size={EmptyListingSize.SMALL}
                textType={TextType.BODY_TEXT_SMALL_LIGHT}
                className={`${rootClass}__empty`}
                withoutBorder
              />
            )}
          </SingleSelectDropdownPortal>
        )}
      </div>
    </div>
  )
}

export default SingleSelectDropdown
