import { useApolloClient } from '@apollo/client'
import standardFields from '@graphql/microservices/list/standardFields'
import getPersonalizationChoices from '@graphql/queries/getPersonalizationChoices'
import { StandardFieldsQuery, StandardFieldsQueryVariables, StandardField } from '@graphql/types/microservice/list-types'
import { GetPersonalizationChoicesQuery, GetPersonalizationChoicesQueryVariables, LabelValueField } from '@graphql/types/query-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'

export interface UsePersonalizationFieldsModalRequests {
  getStandardFieldsRequest: () => Promise<StandardField[]>
  getPersonalizationChoicesRequest: () => Promise<{ accountFields: LabelValueField[]; crmFields: LabelValueField[] }>
}

export const useUsePersonalizationFieldsModalRequests = (): UsePersonalizationFieldsModalRequests => {
  const { client: listServiceClient } = useMicroserviceClient({ serviceName: MicroserviceClients.LIST })
  const actonClient = useApolloClient()

  const getStandardFieldsRequest = () => {
    return listServiceClient
      .query<StandardFieldsQuery, StandardFieldsQueryVariables>({
        query: standardFields,
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => data.standardFields as StandardField[])
  }

  const getAccountFields = (fields: LabelValueField[]) => {
    const filterByGroup = ['Account (Company) Fields', 'Date Fields', 'Custom Account Fields']
    const fullFormatDateField = 'Account Date (Full Format) [Optional 2nd argument for locale ex: fr-FR gives French (all date functions)]'
    const fullFormatDateFieldAbbreviated = 'Account Date (Full Format)'
    return fields.reduce((fields: LabelValueField[], { label, ...field }: LabelValueField) => {
      return filterByGroup.includes(field.groupId) && !field.value.includes('DATEWITHOFFSET')
        ? [...fields, { ...field, label: label === fullFormatDateField ? fullFormatDateFieldAbbreviated : label }]
        : fields
    }, [])
  }

  const getPersonalizationChoicesRequest = () => {
    return actonClient
      .query<GetPersonalizationChoicesQuery, GetPersonalizationChoicesQueryVariables>({
        query: getPersonalizationChoices,
        fetchPolicy: 'network-only',
      })
      .then(({ data: { getPersonalizationChoices } }) => {
        const accountFields = getAccountFields(getPersonalizationChoices.vcFields ?? [])
        const crmFields = getPersonalizationChoices.crmFields?.filter((field) => field.label && field.value) ?? []
        return { accountFields, crmFields }
      })
  }

  return {
    getStandardFieldsRequest,
    getPersonalizationChoicesRequest,
  }
}
