import { gql } from '@apollo/client'

export default gql`
  query getWebinars {
    getWebinars {
      id
      title
      type
      duration
      date
    }
  }
`
