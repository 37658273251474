import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import BackButton from '@components/BackButton/BackButton'
import Button, { ButtonType } from '@components/Button/index'
import { EmptyListingProps, EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal/index'
import Search, { SearchType } from '@components/Search/Search'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { TableV2 } from '@components/TableV2/TableV2'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import Typography, { ModalBodyStyle, ModalHeaderStyle, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ListFieldResponse } from '@graphql/types/query-types'
import { SelectedDynamicImage } from '@src/pages/EmailComposer/EmailModals/components/ImageFileModal/InsertImageModalContainer'
import { RowSelectionState } from '@tanstack/react-table'

import './DynamicImageFieldsModal.css'

interface DynamicData {
  id: string
  name: string
}

interface DynamicImageFieldsModalProps {
  className?: string
  dataTest?: string
  onClose: () => void
  onAction: (selectedRecipients: DynamicData | null) => void
  handleBack: () => void
  isOpen: boolean
  data?: ListFieldResponse
  selectedRow?: SelectedDynamicImage
}

const rootClass = 'dynamic-image-fields-modal'

export const dynamicImageColumns: ColumnDefWithAdditionalProps<DynamicData>[] = [
  {
    header: 'Field name',
    accessorKey: 'name',
    textAlign: 'left',
    sortingFn: 'auto',
  },
]

export const DynamicImageFieldsModal: FC<DynamicImageFieldsModalProps> = (props: DynamicImageFieldsModalProps) => {
  const { dataTest = rootClass, className = '', onAction, onClose, handleBack, data, isOpen, selectedRow } = props
  const { t } = useTranslation()

  const [state, setState] = useState<{
    searchText: string
    selectedItem: DynamicData | null
    tableData: DynamicData[]
    isTableLoading: boolean
    defaultSelectedRow?: RowSelectionState
  }>({
    searchText: '',
    tableData: [],
    selectedItem: null,
    isTableLoading: true,
    defaultSelectedRow: undefined,
  })

  useEffect(() => {
    if (selectedItem?.name || selectedRow?.title) {
      setState((prevState) => ({
        ...prevState,
        defaultSelectedRow: { [selectedItem?.name || selectedRow?.title || '']: true },
      }))
    }
  }, [selectedRow?.title, state.selectedItem?.name, data?.fields?.length])

  const { searchText, tableData, selectedItem } = state

  const emptyStateProps: EmptyListingProps = useMemo(
    () => ({
      headline: t('SendDetails.Recipients.ListPicker.EmptySearch.Headline', { searchTerm: searchText }),
      imgSrc: StaticImageNames.emptySearch,
      size: EmptyListingSize.MEDIUM,
      hideIcon: false,
      withoutBorder: true,
      fullHeight: true,
    }),
    [searchText, t]
  )

  const handleSearchChange = useCallback(
    (value: string) => {
      setState((prevState) => {
        const filteredData =
          value.trim() === ''
            ? data?.fields?.map((name) => ({ id: data?.listId, name })) ?? []
            : data?.fields
                ?.filter((fieldName) => fieldName.toLowerCase().includes(value.toLowerCase()))
                .map((name) => ({ id: data?.listId, name })) ?? []

        return {
          ...prevState,
          searchText: value,
          tableData: filteredData,
        }
      })
    },
    [data]
  )

  const handleAction = useCallback(() => onAction(selectedItem), [onAction, selectedItem])

  const onRowSelectionChanged = useCallback(
    (rowIds: string[]) => {
      const selectedRow = tableData.find((row) => row.name === rowIds[0])
      setState((prevState) => ({ ...prevState, selectedItem: selectedRow ?? null }))
    },
    [tableData]
  )

  useEffect(() => {
    if (data) {
      const initialData = data.fields?.map((name) => ({ id: data?.listId ?? '', name })) ?? []
      setState((prevState) => ({ ...prevState, tableData: initialData, isTableLoading: false }))
    }
  }, [data])

  return (
    <Modal
      className={classNames(rootClass, className)}
      data-test={dataTest}
      isOpen={isOpen}
      header={
        <ModalHeader headerType={ModalHeaderType.List} className={`${rootClass}__header`}>
          <BackButton onClick={handleBack} className={`${rootClass}__header__back-button`} dataTest={`${dataTest}-button-back`} />
          <Typography text={t('Select field')} {...ModalHeaderStyle} />
          <div className={`${rootClass}__header__extra`}>
            <Search
              canClear
              incomingValue={searchText}
              placeholder={t('Search fields')}
              searchType={SearchType.LARGE}
              onChangeHandler={handleSearchChange}
              dataTest={`${dataTest}-search`}
              className={`${rootClass}__search-input`}
            />
          </div>
        </ModalHeader>
      }
    >
      <ModalBody className={`${rootClass}__body`}>
        <TableV2
          enableRadio
          withoutBorders
          data={tableData}
          enableInsideLoader
          rowUniqueIdKey="name"
          emptyState={emptyStateProps}
          columns={dynamicImageColumns}
          loading={state.isTableLoading}
          defaultSelectedRows={state.defaultSelectedRow}
          onRowSelectionChanged={onRowSelectionChanged}
        />
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.List} className={`${rootClass}__footer`}>
        <div className={`${rootClass}__footer__extra`}>
          {!state.isTableLoading && (selectedItem?.name || selectedRow?.title) && (
            <Typography
              text={t('DynamicImage.FieldsModal.footerText', { selectedItem: selectedItem?.name || selectedRow?.title })}
              {...ModalBodyStyle}
              tagProps={{ bold: { weight: TextWeight.MEDIUM, inline: true } }}
            />
          )}
        </div>
        <Button buttonType={ButtonType.TERTIARY} onClick={onClose} dataTest={`${dataTest}-button-tertiary`}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} disabled={!selectedItem?.name} onClick={handleAction} dataTest={`${dataTest}-button-primary`}>
          {t('Submit')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
