import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import './DataCard.css'

export interface DataCardProps {
  className?: string
  dataTest?: string
  image?: string
  imageVerticalAlign?: 'top' | 'center' | 'bottom'
  imageHorizontalAlign?: 'left' | 'center' | 'right'
  children?: ReactNode
  onClick?: VoidFunction
  isClickable?: boolean
}

const rootClass = 'data-card'

const DataCard: FC<DataCardProps> = (props: DataCardProps) => {
  const {
    dataTest = rootClass,
    className = '',
    children,
    onClick,
    image,
    imageVerticalAlign = 'top',
    imageHorizontalAlign = 'right',
    isClickable = !!onClick,
  } = props

  const background: React.CSSProperties = {
    backgroundImage: `url("${image}")`,
    backgroundPosition: `${imageVerticalAlign} ${imageHorizontalAlign}`,
  }

  return (
    <div className={classNames(rootClass, className, { [`${rootClass}__clickable`]: isClickable })} data-test={dataTest}>
      <div
        role={isClickable ? 'button' : undefined}
        tabIndex={isClickable ? 0 : -1}
        onKeyDown={(keyDownEvent) => (keyDownEvent.key === ' ' && onClick ? onClick() : undefined)}
        className={`${rootClass}__background`}
        style={image ? background : {}}
        onClick={onClick}
      >
        {!!children && children}
      </div>
    </div>
  )
}

export default DataCard
