import { createContext } from 'react'

import { TableV2RowData } from '@components/TableV2/tableV2TS/interfaces'
import { StatusToastType } from '@interface/StatusToast'
import { AlertFilter } from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertFilters/utils/AlertFilters.utils'
import { Recipient } from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertRecipients/components/RecipientsList/RecipientsList'
import { WebsiteProspectorAlertsMock } from '@src/pages/WebsiteProspectorAlerts/WebsiteProspectorAlerts.mocks'

export interface Alert extends TableV2RowData<Alert> {
  id: number
  name: string
  description: string
  recipients: Recipient[]
  schedule: string
  filters: AlertFilter[]
  owner: string
  ownerName: string
  lastModified: string
  enabled: boolean
  muted: boolean
}

export interface WebsiteProspectorContainerState {
  alerts: Alert[]
  showCreateAlertModal: boolean
  alertToRemove: Alert | undefined
  statusToast?: StatusToastType
}

export const WebsiteProspectorContainerInitialState: WebsiteProspectorContainerState = {
  alerts: WebsiteProspectorAlertsMock,
  showCreateAlertModal: false,
  alertToRemove: undefined,
}

type Update = (fields: Partial<WebsiteProspectorContainerState>) => void
type OnEnableChange = (alert: Alert, enabled: boolean) => void
type OnMuteChange = (alert: Alert) => void
type OnDelete = (alert: Alert) => void

export interface WebsiteProspectorAPI {
  values: WebsiteProspectorContainerState
  update: Update
  onEnableChange: OnEnableChange
  onMuteChange: OnMuteChange
  onDelete: OnDelete
}

export const WebsiteProspectorContextMock: WebsiteProspectorAPI = {
  values: {
    alerts: WebsiteProspectorAlertsMock,
    showCreateAlertModal: false,
    alertToRemove: undefined,
  },
  update: () => undefined,
  onEnableChange: () => undefined,
  onMuteChange: () => undefined,
  onDelete: () => undefined,
}

export const WebsiteProspectorContext = createContext<WebsiteProspectorAPI>({ values: WebsiteProspectorContainerInitialState } as any)
