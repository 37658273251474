import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { LinkTextButton } from '@components/LinkTextButton/LinkTextButton'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './PopoverCard.css'

interface ButtonProps {
  text: string
  onClick: () => void
}
interface PopoverCardProps {
  className?: string
  dataTest?: string
  headerIcon?: SvgNames
  headerText: string
  content: ReactNode
  onCancel?: () => void
  footerLink?: string
  footerLinkText?: string
  secondaryButton?: ButtonProps
  primaryButton: ButtonProps
}

const rootClass = 'popover-card'

const PopoverCard: FC<PopoverCardProps> = (props: PopoverCardProps) => {
  const {
    dataTest = rootClass,
    className = '',
    headerIcon,
    headerText,
    content,
    onCancel,
    footerLink,
    footerLinkText = 'Learn more',
    primaryButton,
    secondaryButton,
  } = props
  const { t } = useTranslation()
  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={classNames(`${rootClass}__header`, { [`${rootClass}__header-has-icon`]: headerIcon })}>
        {headerIcon && (
          <div className={`${rootClass}__header-icon`}>
            <Svg name={headerIcon} fill={SvgColor.TEXT_GRAY} type={SvgType.LARGER_ICON} />
          </div>
        )}
        <Typography text={headerText} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.MEDIUM} lineHeight={LineHeight.LARGER} />
      </div>
      <div className={`${rootClass}__content`}>{content}</div>
      <div className={`${rootClass}__footer`}>
        <div className={`${rootClass}__footer-left`}>
          {footerLink && <LinkTextButton hideIconLeft text={t(footerLinkText)} iconNameRight={SvgNames.externalLinkSelected} link={footerLink} />}
          {secondaryButton && !footerLink && (
            <Button buttonType={ButtonType.INFO} onClick={secondaryButton.onClick} dataTest={`${dataTest}-secondary-button`}>
              {secondaryButton.text}
            </Button>
          )}
        </div>
        <div className={`${rootClass}__footer-right`}>
          {onCancel && (
            <Button buttonType={ButtonType.FLOAT_GRAY} onClick={onCancel} dataTest={`${dataTest}-cancel-button`}>
              {t('Cancel')}
            </Button>
          )}
          <Button buttonType={ButtonType.PRIMARY} onClick={primaryButton.onClick} dataTest={`${dataTest}-action-button`}>
            {t(primaryButton.text)}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default PopoverCard
