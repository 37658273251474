import { SvgNames } from '@components/Svg'
import { ItemDto } from '@graphql/types/microservice/categorization-types'

export enum CommonFilters {
  FAVORITES = 'Favorites',
  CREATED_BY_ME = 'Created by me',
  RECENT = 'Recent',
}

export enum FilterTypes {
  EXTENSION = 'Extension',
  ALL_ACCOUNT_LISTS = 'All account lists',
  ALL_ACCOUNTS = 'All accounts',
  ALL_CUSTOM_DATA = 'All custom data',
  ALL_MARKETING_LISTS = 'All marketing lists',
  ALL_FORM_SUBMISSIONS = 'All form submissions',
  ALL_FORMS = 'All forms',
  ALL_FORM_TEMPLATES = 'All templates',
  ALL_IMAGES = 'All images',
  ALL_LOGOS = 'All logos',
  ALL_WEBINAR_LISTS = 'All webinar lists',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  ALL_EMAIL_TEMPLATES = 'All templates',
  ALL_EMAIL_TRIGGERED = 'All emails',
  ALL_EMAIL_SCHEDULED = 'All scheduled emails',
  ALL_EMAIL_SENT = 'All sent emails',
  ALL_PROGRAM_TEMPLATES = 'All program templates',
  ALL_PROGRAMS = 'All programs',
  ALL_SALESFORCE_CAMPAIGNS = 'All Salesforce campaigns',
  ALL_SALESFORCE_SAVED_REPORTS = 'All Salesforce saved reports',
  ALL_SEGMENTS = 'All Segments',
  ALL_FRAGMENTS = 'All Fragments',
  ALL_LANDING_PAGES = 'All landing pages',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  ALL_LANDING_PAGE_TEMPLATES = 'All templates',
  ALL_MEDIA_FILES = 'All media files',
  ALL_MEDIA = 'All media',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  ALL_CATALOG_TEMPLATES = 'All templates',
  ALL_DRAFTS = 'All drafts',
  ALL_WEBINAR_SUBMISSIONS = 'All webinar registrations',
  ALL_PERSONALIZATION_FIELDS = 'All field types',
  ALL_SIGNATURES = 'All signatures',
  MY_SIGNATURES = 'My signatures',
  ACCOUNT_SIGNATURES = 'Account signatures',
  AVAILABLE_LISTS = 'Available lists',
  FAVORITES = 'Favorites',
  MANUAL = 'Manual',
  MY_ACCOUNTS = 'My accounts',
  UNASSIGNED_ACCOUNTS = 'No manager assigned',
  SECTIONS = 'Sections',
  SCHEDULED = 'Scheduled',
  SOURCE_LIST_FIELDS = 'Source list fields',
  BLOCKS = 'Blocks',
  CREATED_BY_ME = 'Created by me',
  RECENT = 'Recent',
  RECENTLY_VIEWED = 'Recently viewed',
  RECOMMENDED = 'Recommended',
  CONTACT_PREFERENCES = 'Contact Preferences',
  CRM_SOURCED = 'CRM Sourced',
  RUNNING = 'Running',
  PAUSED = 'Paused',
  DRAFT = 'Draft with errors',
  HAS_ERRORS = 'Has Errors',
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
  EVENT_TRIGGERED_EMAILS = 'Event triggered email',
  MEDIA_FILE = 'Files',
  MEDIA_LINK = 'Links',
  CONTACT_FIELDS = 'Contact fields',
  RECIPIENT_LIST_FIELDS = 'Recipient list fields',
  RECIPIENT_SPECIFIC_LIST_CONTACTS = 'Recipient specific list contacts',
  SENDER_FIELDS = 'Sender fields',
  CUSTOM_ACCOUNT_FIELDS = 'Custom account fields',
  CRM_FIELDS = 'CRM fields',
  SALESFORCE_FIELDS = 'Salesforce fields',
  NETSUITE_FIELDS = 'NetSuite CRM fields',
  MSDYNAMICS_FIELDS = 'MS Dynamics fields',
  SUGGESTED_AUTOMATED_PROGRAMS = 'Suggested programs',
  SUGGESTED_FORMS = 'Suggested forms',
  SUGAR_CRM_FIELDS = 'SugarCRM fields',
  ZENDESK_FIELDS = 'Zendesk Sell fields',
  SENT_BY_MARKETING = 'Sent by marketing users',
  SENT_BY_SALES = 'Sent by sales users',
  SENT_BY_ME = 'Sent by me',
  SCHEDULED_BY_MARKETING = 'Marketing users',
  SCHEDULED_BY_SALES = 'Sales users',
  SCHEDULED_BY_ME = 'Myself',
  ALL_WEBINARS = 'All webinars',
}

export interface FilterDefinition {
  name: string
  /** The text that will display.  If not specified `name` will be used */
  label?: string
  svgSelected: SvgNames
  svgUnselected: SvgNames
  filterAction?: () => void
  tooltip?: string
  tooltipIcon?: SvgNames
  disabled?: boolean
}

export interface CustomFilterDefinition extends FilterDefinition {
  position: number
  extraFilter?: (items: ItemDto[]) => ItemDto[]
  subTypeName?: string
}

export const contactPreferencesFilter: FilterDefinition = {
  name: FilterTypes.CONTACT_PREFERENCES,
  svgUnselected: SvgNames.togglesOutline,
  svgSelected: SvgNames.toggles,
}
export const crmSourcedFilter: FilterDefinition = {
  name: FilterTypes.CRM_SOURCED,
  svgUnselected: SvgNames.togglesOutline,
  svgSelected: SvgNames.toggles,
}

export const recommendedFilter: FilterDefinition = {
  name: FilterTypes.RECOMMENDED,
  svgSelected: SvgNames.thumbsUpSelected,
  svgUnselected: SvgNames.thumbsUpUnselected,
}

export const favoriteFilter: FilterDefinition = {
  name: FilterTypes.FAVORITES,
  svgSelected: SvgNames.favoritesSelected,
  svgUnselected: SvgNames.favoritesUnselected,
}

export const createdByMeFilter: FilterDefinition = {
  name: FilterTypes.CREATED_BY_ME,
  svgSelected: SvgNames.userSelected,
  svgUnselected: SvgNames.userUnselected,
}

export const recentFilter: FilterDefinition = {
  name: FilterTypes.RECENT,
  svgSelected: SvgNames.clockSelected,
  svgUnselected: SvgNames.clock,
}

export const recentlyViewedFilter: FilterDefinition = {
  name: FilterTypes.RECENTLY_VIEWED,
  svgSelected: SvgNames.clockSelected,
  svgUnselected: SvgNames.clock,
}

export const allAccountsFilter: FilterDefinition = {
  name: FilterTypes.ALL_ACCOUNTS,
  svgUnselected: SvgNames.segmentsUnselected,
  svgSelected: SvgNames.segmentsSelected,
}

export const allCustomDataFilter: FilterDefinition = {
  name: FilterTypes.ALL_CUSTOM_DATA,
  svgUnselected: SvgNames.segmentsUnselected,
  svgSelected: SvgNames.segmentsSelected,
}

export const allFragmentsFilter: FilterDefinition = {
  name: FilterTypes.ALL_FRAGMENTS,
  svgUnselected: SvgNames.segmentFiltersUnselected,
  svgSelected: SvgNames.segmentFilters,
}

export const allSegmentsFilter: FilterDefinition = {
  name: FilterTypes.ALL_SEGMENTS,
  svgUnselected: SvgNames.segmentsUnselected,
  svgSelected: SvgNames.segmentsSelected,
}

export const allProgramsFilter: FilterDefinition = {
  name: FilterTypes.ALL_PROGRAMS,
  svgUnselected: SvgNames.segmentsUnselected,
  svgSelected: SvgNames.segmentsSelected,
}

export const allFormsFilter: FilterDefinition = {
  name: FilterTypes.ALL_FORMS,
  svgUnselected: SvgNames.segmentsUnselected,
  svgSelected: SvgNames.segmentsSelected,
}

export const allFormTemplatesFilter: FilterDefinition = {
  name: FilterTypes.ALL_FORM_TEMPLATES,
  svgUnselected: SvgNames.segmentsUnselected,
  svgSelected: SvgNames.segmentsSelected,
}

export const allEmailTemplatesFilter: FilterDefinition = {
  name: FilterTypes.ALL_EMAIL_TEMPLATES,
  svgUnselected: SvgNames.segmentsUnselected,
  svgSelected: SvgNames.segmentsSelected,
}

export const allEmailSentFilter: FilterDefinition = {
  name: FilterTypes.ALL_EMAIL_SENT,
  label: 'All emails',
  svgUnselected: SvgNames.segmentsUnselected,
  svgSelected: SvgNames.segmentsSelected,
}

export const allEmailDraftsFilter: FilterDefinition = {
  name: FilterTypes.ALL_DRAFTS,
  svgUnselected: SvgNames.segmentsUnselected,
  svgSelected: SvgNames.segmentsSelected,
}

export const allEmailScheduledFilter: FilterDefinition = {
  name: FilterTypes.ALL_EMAIL_SCHEDULED,
  svgUnselected: SvgNames.segmentsUnselected,
  svgSelected: SvgNames.segmentsSelected,
}

export const allEmailTriggeredFilter: FilterDefinition = {
  name: FilterTypes.ALL_EMAIL_TRIGGERED,
  svgUnselected: SvgNames.segmentsUnselected,
  svgSelected: SvgNames.segmentsSelected,
}

export const fragmentBlocksFilter: FilterDefinition = {
  name: FilterTypes.BLOCKS,
  svgUnselected: SvgNames.togglesOutline,
  svgSelected: SvgNames.toggles,
}

export const fragmentSectionsFilter: FilterDefinition = {
  name: FilterTypes.SECTIONS,
  svgUnselected: SvgNames.togglesOutline,
  svgSelected: SvgNames.toggles,
}

export const runningProgramsFilter: FilterDefinition = {
  name: FilterTypes.RUNNING,
  svgUnselected: SvgNames.togglesOutline,
  svgSelected: SvgNames.toggles,
}

export const pausedProgramsFilter: FilterDefinition = {
  name: FilterTypes.PAUSED,
  svgUnselected: SvgNames.togglesOutline,
  svgSelected: SvgNames.toggles,
}

export const draftProgramsFilter: FilterDefinition = {
  name: FilterTypes.DRAFT,
  svgUnselected: SvgNames.togglesOutline,
  svgSelected: SvgNames.toggles,
}

export const allTemplatesFilter: FilterDefinition = {
  name: FilterTypes.ALL_PROGRAM_TEMPLATES,
  svgUnselected: SvgNames.segmentsUnselected,
  svgSelected: SvgNames.segmentsSelected,
}

export const allCatalogTemplatesFilter: FilterDefinition = {
  name: FilterTypes.ALL_CATALOG_TEMPLATES,
  svgUnselected: SvgNames.segmentsUnselected,
  svgSelected: SvgNames.segmentsSelected,
}

export const allSalesforceCampaignsFilter: FilterDefinition = {
  name: FilterTypes.ALL_SALESFORCE_CAMPAIGNS,
  svgUnselected: SvgNames.segmentsUnselected,
  svgSelected: SvgNames.segmentsSelected,
}

export const suggestedAutomatedProgramsFilter: FilterDefinition = {
  name: FilterTypes.SUGGESTED_AUTOMATED_PROGRAMS,
  svgUnselected: SvgNames.lightBulbUnselected,
  svgSelected: SvgNames.lightBulbSelected,
}

export const suggestedFormsFilter: FilterDefinition = {
  name: FilterTypes.SUGGESTED_FORMS,
  svgUnselected: SvgNames.lightBulbUnselected,
  svgSelected: SvgNames.lightBulbSelected,
}

export const scheduledProgramFilter: FilterDefinition = {
  name: FilterTypes.SCHEDULED,
  svgUnselected: SvgNames.togglesOutline,
  svgSelected: SvgNames.toggles,
}

export const manualProgramFilter: FilterDefinition = {
  name: FilterTypes.MANUAL,
  svgUnselected: SvgNames.togglesOutline,
  svgSelected: SvgNames.toggles,
}

export const myAccountsFilter: FilterDefinition = {
  name: FilterTypes.MY_ACCOUNTS,
  svgUnselected: SvgNames.userUnselected,
  svgSelected: SvgNames.userSelected,
}

export const unassignedAccountsFilter: FilterDefinition = {
  name: FilterTypes.UNASSIGNED_ACCOUNTS,
  svgUnselected: SvgNames.userOff,
  svgSelected: SvgNames.userOffSelected,
}

export const allLandingPages: FilterDefinition = {
  name: FilterTypes.ALL_LANDING_PAGES,
  svgUnselected: SvgNames.segmentsUnselected,
  svgSelected: SvgNames.segmentsSelected,
}

export const allLandingPageTemplates: FilterDefinition = {
  name: FilterTypes.ALL_LANDING_PAGE_TEMPLATES,
  svgUnselected: SvgNames.segmentsUnselected,
  svgSelected: SvgNames.segmentsSelected,
}

export const allFormSubmissionsFilter: FilterDefinition = {
  name: FilterTypes.ALL_FORM_SUBMISSIONS,
  svgUnselected: SvgNames.formSimple,
  svgSelected: SvgNames.formSimpleSelected,
}

export const allWebinarSubmissionsFilter: FilterDefinition = {
  name: FilterTypes.ALL_WEBINAR_SUBMISSIONS,
  svgUnselected: SvgNames.webinarOutline,
  svgSelected: SvgNames.webinar,
}

export const availableListsFilter: FilterDefinition = {
  name: FilterTypes.AVAILABLE_LISTS,
  svgUnselected: SvgNames.segmentsUnselected,
  svgSelected: SvgNames.segmentsSelected,
}

export const landingPagesEnabledStatusFilter: FilterDefinition = {
  name: FilterTypes.ENABLED,
  svgUnselected: SvgNames.togglesOutline,
  svgSelected: SvgNames.toggles,
}

export const landingPagesDisabledStatusFilter: FilterDefinition = {
  name: FilterTypes.DISABLED,
  svgUnselected: SvgNames.togglesOutline,
  svgSelected: SvgNames.toggles,
}

export const eventTriggeredEmailsFilter: FilterDefinition = {
  name: FilterTypes.EVENT_TRIGGERED_EMAILS,
  svgUnselected: SvgNames.togglesOutline,
  svgSelected: SvgNames.toggles,
}

export const allDraftsFilter: FilterDefinition = {
  name: FilterTypes.ALL_DRAFTS,
  svgUnselected: SvgNames.segmentsUnselected,
  svgSelected: SvgNames.segmentsSelected,
}

export const allMediaFilesFilter: FilterDefinition = {
  name: FilterTypes.ALL_MEDIA_FILES,
  svgUnselected: SvgNames.segmentsUnselected,
  svgSelected: SvgNames.segmentsSelected,
}

export const allMediaFilter: FilterDefinition = {
  name: FilterTypes.ALL_MEDIA,
  svgUnselected: SvgNames.segmentsUnselected,
  svgSelected: SvgNames.segmentsSelected,
}

export const allImagesFilter: FilterDefinition = {
  name: FilterTypes.ALL_IMAGES,
  svgUnselected: SvgNames.segmentsUnselected,
  svgSelected: SvgNames.segmentsSelected,
}

export const allLogosFilter: FilterDefinition = {
  name: FilterTypes.ALL_LOGOS,
  svgUnselected: SvgNames.segmentLogos,
  svgSelected: SvgNames.segmentLogos,
}

export const mediaFileFilter: FilterDefinition = {
  name: FilterTypes.MEDIA_FILE,
  svgUnselected: SvgNames.togglesOutline,
  svgSelected: SvgNames.toggles,
}

export const mediaLinkFilter: FilterDefinition = {
  name: FilterTypes.MEDIA_LINK,
  svgUnselected: SvgNames.togglesOutline,
  svgSelected: SvgNames.toggles,
}

export const allPersonalizationFieldsFilter: FilterDefinition = {
  name: FilterTypes.ALL_PERSONALIZATION_FIELDS,
  svgSelected: SvgNames.segmentsSelected,
  svgUnselected: SvgNames.segmentsUnselected,
}

export const allSignaturesFilter: FilterDefinition = {
  name: FilterTypes.ALL_SIGNATURES,
  svgSelected: SvgNames.segmentsSelected,
  svgUnselected: SvgNames.segmentsUnselected,
}

export const mySignaturesFilter: FilterDefinition = {
  name: FilterTypes.MY_SIGNATURES,
  svgSelected: SvgNames.userSelected,
  svgUnselected: SvgNames.userUnselected,
}

export const accountSignaturesFilter: FilterDefinition = {
  name: FilterTypes.ACCOUNT_SIGNATURES,
  svgSelected: SvgNames.threeUsersSelected,
  svgUnselected: SvgNames.threeUsers,
}

export const contactFieldsFilter: FilterDefinition = {
  name: FilterTypes.CONTACT_FIELDS,
  svgSelected: SvgNames.usersSelectedFull,
  svgUnselected: SvgNames.usersUnselectedFull,
  tooltip: 'Filter.Tooltip.Contact.Fields',
}

export const sourceListFieldsFilter: FilterDefinition = {
  name: FilterTypes.SOURCE_LIST_FIELDS,
  svgSelected: SvgNames.segment,
  svgUnselected: SvgNames.listsAndSegments,
  tooltip: 'Filter.Tooltip.SourceList.Fields',
}

export const listOrSegmentFieldsFilter: FilterDefinition = {
  name: FilterTypes.RECIPIENT_LIST_FIELDS,
  svgSelected: SvgNames.mailboxSolid,
  svgUnselected: SvgNames.mailboxLine,
  tooltip: 'Filter.Tooltip.List.Or.Segment',
}

export const listOrSegmentFieldsFilterDisabled: FilterDefinition = {
  name: FilterTypes.RECIPIENT_LIST_FIELDS,
  svgSelected: SvgNames.mailboxSolid,
  svgUnselected: SvgNames.mailboxLineDisabled,
  tooltip: 'Filter.Tooltip.List.Or.Segment.Disabled',
  disabled: true,
}

export const listOrSegmentFieldsFilterDisabledTemplate: FilterDefinition = {
  name: FilterTypes.RECIPIENT_LIST_FIELDS,
  svgSelected: SvgNames.mailboxSolid,
  svgUnselected: SvgNames.mailboxLineDisabled,
  tooltip: 'Filter.Tooltip.List.Or.Segment.Disabled.Template',
  disabled: true,
}

export const senderFieldsFilter: FilterDefinition = {
  name: FilterTypes.SENDER_FIELDS,
  svgSelected: SvgNames.sendSolid,
  svgUnselected: SvgNames.sendLine,
  tooltip: 'Filter.Tooltip.Sender.Fields',
}

export const contentPersonalizationFieldsFilter: FilterDefinition = {
  name: FilterTypes.CUSTOM_ACCOUNT_FIELDS,
  svgSelected: SvgNames.customAccountFieldsSelected,
  svgUnselected: SvgNames.customAccountFields,
  tooltip: 'Filter.Tooltip.Personalization.Fields',
}

export const crmFieldsFilter: FilterDefinition = {
  name: FilterTypes.CRM_FIELDS,
  svgSelected: SvgNames.crmCloudSolid,
  svgUnselected: SvgNames.crmCloudLine,
  tooltip: 'Filter.Tooltip.Crm.Fields',
  tooltipIcon: SvgNames.lock,
  disabled: true,
}

export const salesforceFieldsFilter: FilterDefinition = {
  name: FilterTypes.SALESFORCE_FIELDS,
  svgSelected: SvgNames.salesforceSolid,
  svgUnselected: SvgNames.salesforceLine,
  tooltip: '',
}

export const netsuiteFieldsFilter: FilterDefinition = {
  name: FilterTypes.NETSUITE_FIELDS,
  svgSelected: SvgNames.netsuiteSolid,
  svgUnselected: SvgNames.netsuiteLine,
  tooltip: '',
}

export const msdynamicsFieldsFilter: FilterDefinition = {
  name: FilterTypes.MSDYNAMICS_FIELDS,
  svgSelected: SvgNames.msDynamicsSolid,
  svgUnselected: SvgNames.msDynamicsLine,
  tooltip: '',
}

export const zendeskFieldsFilter: FilterDefinition = {
  name: FilterTypes.ZENDESK_FIELDS,
  svgSelected: SvgNames.zendeskSolid,
  svgUnselected: SvgNames.zendeskLine,
  tooltip: '',
}

export const sugarCRMFieldsFilter: FilterDefinition = {
  name: FilterTypes.SUGAR_CRM_FIELDS,
  svgSelected: SvgNames.sugarSolid,
  svgUnselected: SvgNames.sugarLine,
  tooltip: '',
}

export const sentByMarketingFilter: FilterDefinition = {
  name: FilterTypes.SENT_BY_MARKETING,
  svgUnselected: SvgNames.togglesOutline,
  svgSelected: SvgNames.toggles,
}

export const sentBySalesFilter: FilterDefinition = {
  name: FilterTypes.SENT_BY_SALES,
  svgUnselected: SvgNames.togglesOutline,
  svgSelected: SvgNames.toggles,
}

export const sentByMeFilter: FilterDefinition = {
  name: FilterTypes.SENT_BY_ME,
  svgUnselected: SvgNames.togglesOutline,
  svgSelected: SvgNames.toggles,
}

export const scheduledByMarketingFilter: FilterDefinition = {
  name: FilterTypes.SCHEDULED_BY_MARKETING,
  svgUnselected: SvgNames.togglesOutline,
  svgSelected: SvgNames.toggles,
}

export const scheduledBySalesFilter: FilterDefinition = {
  name: FilterTypes.SCHEDULED_BY_SALES,
  svgUnselected: SvgNames.togglesOutline,
  svgSelected: SvgNames.toggles,
}

export const scheduledByMeFilter: FilterDefinition = {
  name: FilterTypes.SCHEDULED_BY_ME,
  svgUnselected: SvgNames.togglesOutline,
  svgSelected: SvgNames.toggles,
}

export const allWebinarsFilter: FilterDefinition = {
  name: FilterTypes.ALL_WEBINARS,
  svgUnselected: SvgNames.segmentsUnselected,
  svgSelected: SvgNames.segmentsSelected,
}

export const commonFilters: FilterDefinition[] = [favoriteFilter, createdByMeFilter, recentFilter]
