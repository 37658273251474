import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import './modalSubHeader.css'

export interface ModalSubHeaderProps {
  children: ReactNode
  className?: string
  hideBorder?: boolean
}

const rootClass = 'modal-sub-header'

export const ModalSubHeader: FC<ModalSubHeaderProps> = (props: ModalSubHeaderProps) => {
  const { hideBorder, children, className } = props

  return (
    <div
      className={classNames(`${rootClass}__inner`, className, {
        [`${rootClass}__inner-no-border`]: hideBorder,
      })}
    >
      {children}
    </div>
  )
}

export default ModalSubHeader
