import { gql } from '@apollo/client'

export default gql`
  query unifiedContactValidateRecord($columnIndexDataValueList: [ColumnIndexDataValueInput]) {
    unifiedContactValidateRecord(columnIndexDataValueList: $columnIndexDataValueList) {
      VALIDATION_ERR_BOOLEAN_PROPERTY
      VALIDATION_ERR_DATETIME_PROPERTY
      VALIDATION_ERR_DATE_PROPERTY
      VALIDATION_ERR_EMAIL_PROPERTY
      VALIDATION_ERR_NUMERIC_PROPERTY
      VALIDATION_ERR_SCORE_PROPERTY
      VALIDATION_SUCCESS_PROPERTY
      columnValidationResultList {
        column
        formattedValue
        validationResult
      }
      id
      message
      status
      validationMessagesMap
    }
  }
`
