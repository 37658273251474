import React, { useContext } from 'react'

import MappingField from '@src/pages/ContactSegments/components/CopySegments/components/StepThree/components/MappingField/MappingField'

import { CopySegmentsContext } from '../../../../context/CopySegments.context'

import './MappingFieldsList.css'

const rootClass = 'step-three-mapping-fields-list'

const MappingFieldsList = () => {
  const {
    values: { mappedFields },
  } = useContext(CopySegmentsContext)

  return (
    <div className={rootClass}>
      {mappedFields
        .filter(({ automaticallyMapped }) => !automaticallyMapped)
        .map((field) => (
          <MappingField key={field.marketingListField} field={field} />
        ))}
    </div>
  )
}

export default MappingFieldsList
