import React from 'react'

import classNames from 'classnames'
import { TFunction } from 'i18next'

import { Delimiter, Quote } from '@complex/ImportContacts/ImportContacts.constants'
import { ProgressBarStep } from '@components/ProgressBar/ProgressBar'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { Status } from '@components/StatusToast/StatusToast'
import { TOAST_TEXT_CLASSNAME } from '@components/Toast/Toast'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { ImportEntityMutationVariables, ImportListMutationVariables } from '@graphql/types/microservice/entity-upload-types'
import { GetImportStatusQuery, StandardField, UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import {
  DataTypeList,
  defaultMappingPreviewField,
  ImportContactsContainerValues,
  MappingPreview,
  Update,
  VALIDATION_MESSAGE,
} from '@src/pages/importcontacts/context/ImportContactsContext'
import {
  GetTopDirectSelectSegmentsRequestType,
  SearchDirectSelectSegmentsRequestType,
} from '@src/pages/importcontacts/GraphQL/ImportContactsRequests.categorization.graphQL'
import {
  CreateSegmentRequestType,
  GetListSchemaRequestType,
  IsAValidSegmentNameRequestType,
} from '@src/pages/importcontacts/GraphQL/ImportContactsRequests.classic.graphQL'
import { ImportEntityRequestType, ImportListRequestType } from '@src/pages/importcontacts/GraphQL/ImportContactsRequests.entityUpload.graphQL'
import { GetInProgressImportsRequestType, GetStandardFieldsRequestType } from '@src/pages/importcontacts/GraphQL/ImportContactsRequests.list.graphQL'
import { ImportContactsSteps } from '@src/pages/ImportContactsV2/utils/ImportContactsV2.constants'
import { ListProperties } from '@src/pages/sms/marketingListOptIns/components/MapContacts/MapContactsContainer'
import { logNewRelicError } from '@utils/new-relic.utils'
import { DeepUpdateState } from '@utils/types'

export const systemGeneratedFields = [
  '_AO_UUID',
  '_BROWSER',
  '_CAMPAIGN',
  '_CAMPAIGNID',
  '_EMAIL_REFERRER',
  '_FORM',
  '_FORM_URL',
  '_IPADDR',
  '_JSTZO',
  '_REFERRER',
  '_SEARCH',
  '_TIME',
  '_GEO_CITY',
  '_GEO_COUNTRY',
  '_GEO_COUNTRY_CODE',
  '_GEO_NAME',
  '_GEO_POSTAL_CODE',
  '_GEO_STATE',
]

export const getUpdatedMappingField = (mappingField: MappingPreview, fieldMapping: UnifiedListFieldMapping): MappingPreview => {
  let updatedMappingField = { ...mappingField }
  if (fieldMapping !== undefined) {
    const { standardFieldKey, displayName, columnIndex, dataType } = fieldMapping
    updatedMappingField = {
      ...updatedMappingField,
      mapping: standardFieldKey != null ? standardFieldKey : displayName,
      displayName,
      mappingIndex: columnIndex,
      friendlyName: '',
      dataType: dataType,
      dataFormat: '',
      state: null,
    }
  } else {
    updatedMappingField = {
      ...defaultMappingPreviewField,
      index: updatedMappingField.index,
    }
  }
  return updatedMappingField
}

export const loadStandardFieldsUtils = async (
  getStandardFieldsRequest: GetStandardFieldsRequestType,
  update: DeepUpdateState<ImportContactsContainerValues>
) => {
  const { data } = await getStandardFieldsRequest()
  try {
    if (data?.standardFields) {
      update({
        standardFields: data.standardFields as StandardField[],
      })
    }
  } catch (error) {
    update({
      statusToast: { statusMessage: 'Error while trying to get standard fields.', showStatusToast: true, status: Status.FAIL },
    })
    logNewRelicError(error)
  }
}

export const getListSchemaUtils = async (
  getListSchemaRequest: GetListSchemaRequestType,
  listIds: string[],
  count: number,
  update: DeepUpdateState<ImportContactsContainerValues>,
  goToImportOptions: () => void
) => {
  const { data } = await getListSchemaRequest(listIds, count)
  try {
    if (data?.listSchemas) {
      const schemas: ListProperties[] = data?.listSchemas.map((listSchema) => {
        const headersWithPreviews = listSchema.schema
          ? listSchema.schema.headersWithPreviews
              .slice()
              .sort((a, b) => (a.columnIndex < b.columnIndex ? -1 : 1))
              .filter((header) => !systemGeneratedFields.includes(header.columnName))
          : []
        return {
          id: listSchema.listId,
          name: listSchema.displayName,
          headers: headersWithPreviews.map((withPreview) => withPreview.columnName),
          headersWithPreviews,
        }
      })

      // Only 1 list can be selected
      const previewHeader = schemas[0].headers.filter((header) => !systemGeneratedFields.includes(header))
      const previewRecords: { value: string; hasError: boolean }[][] = []
      const mappingPreview: MappingPreview[] = []

      schemas[0].headersWithPreviews.forEach((header, index) => {
        mappingPreview.push({
          index,
          marketingColumnName: header.columnName,
          ...defaultMappingPreviewField,
        })
        header.previewRows.forEach((value, index) => {
          if (!previewRecords[index]) {
            previewRecords[index] = []
          }
          previewRecords[index].push({ value, hasError: false })
        })
      })

      update({
        columnCount: previewHeader.length ? previewHeader.length : 0,
        previewHeader,
        previewRecords,
        mappingPreview,
        mappingId: schemas[0].id,
        listName: schemas[0].name,
        importingList: true,
      })
      goToImportOptions()
    }
  } catch (error) {
    update({
      statusToast: {
        statusMessage: 'Error while trying to get list data.',
        showStatusToast: true,
        status: Status.FAIL,
      },
    })
    logNewRelicError(error)
  }
}

const getImportDetail = ({ importDetailsStatus }: GetImportStatusQuery, t: Function) => {
  if (importDetailsStatus?.list?.length === 1) {
    const metaData = importDetailsStatus.list[0]?.typeMetaData
    if (metaData) {
      try {
        const data = JSON.parse(metaData)
        return data.name || ''
      } catch {
        return ''
      }
    }
  } else {
    return t(`{{count}} lists`, { count: importDetailsStatus?.list?.length })
  }
  return ''
}

export const getInProgressImportsUtils = async (
  getInProgressImportsRequest: GetInProgressImportsRequestType,
  update: DeepUpdateState<ImportContactsContainerValues>,
  t: TFunction
) => {
  const { data } = await getInProgressImportsRequest()
  try {
    if (data?.importDetailsStatus?.list?.length) {
      const text = getImportDetail(data, t)
      update({
        statusToast: {
          statusMessage: (
            <div className={classNames(TOAST_TEXT_CLASSNAME, 'import-contacts__import-status-toast')}>
              <Typography text={t("We're still working on importing") + ' '} type={TextType.BODY_TEXT_GRAY} inline />
              <Typography text={text} type={TextType.BODY_TEXT_GRAY} weight={TextWeight.BOLD} inline />
            </div>
          ),
          showStatusToast: true,
          status: Status.WARNING,
        },
      })
    }
  } catch (error) {
    update({
      statusToast: {
        statusMessage: 'Error while trying to get Import status.',
        showStatusToast: true,
        status: Status.FAIL,
      },
    })
    logNewRelicError(error)
  }
}

export const importListUtils = (
  importListRequest: ImportListRequestType,
  variables: ImportListMutationVariables,
  update: DeepUpdateState<ImportContactsContainerValues>
) => {
  try {
    importListRequest(variables)
  } catch (error) {
    update({
      statusToast: {
        statusMessage: 'Error while trying to import the data.',
        showStatusToast: true,
        status: Status.FAIL,
      },
    })
    logNewRelicError(error)
  }
}

export const importEntityUtils = (
  importEntityRequest: ImportEntityRequestType,
  variables: ImportEntityMutationVariables,
  update: DeepUpdateState<ImportContactsContainerValues>
) => {
  try {
    importEntityRequest(variables)
  } catch (error) {
    update({
      statusToast: {
        statusMessage: 'Error while trying to import the data.',
        showStatusToast: true,
        status: Status.FAIL,
      },
    })
    logNewRelicError(error)
  }
}

export const getMappingValidationMessageUtils = (
  mappingPreview: MappingPreview[],
  inProgress: boolean,
  getDuplicates: (mappingPreview: MappingPreview[], excludeIndex?: number) => string[],
  getNewCustomFieldsInUse: (mappings?: UnifiedListFieldMapping[]) => string[]
) => {
  if (inProgress) {
    return VALIDATION_MESSAGE.VALIDATION_IN_PROGRESS
  }

  const duplicateFields = getDuplicates(mappingPreview)
  if (duplicateFields.length) {
    return VALIDATION_MESSAGE.DUPLICATED_FIELDS
  }

  const newCustomFieldsInUse = getNewCustomFieldsInUse()
  if (newCustomFieldsInUse.length) {
    return VALIDATION_MESSAGE.CUSTOM_FIELD_IN_USE
  }

  const email = mappingPreview.find(({ mapping }) => mapping === 'email')
  if (!email) {
    return VALIDATION_MESSAGE.EMAIL_MISSING
  }

  const emptyCustomFieldsInput = mappingPreview.filter(({ isCustom, friendlyName }) => isCustom && friendlyName === '')
  if (emptyCustomFieldsInput.length) {
    return VALIDATION_MESSAGE.EMPTY_CUSTOM_FIELD
  }

  const emptyDataFormat = mappingPreview.filter(
    ({ dataFormat, dataType }) => dataType && DataTypeList.find((type) => type.id === dataType)?.formatOptions && !dataFormat
  )
  if (emptyDataFormat.length) {
    return VALIDATION_MESSAGE.EMPTY_DATA_FORMAT
  }

  const emptyCustomFieldsDatatype = mappingPreview.filter(({ isCustom, dataType }) => isCustom && !dataType)
  if (emptyCustomFieldsDatatype.length) {
    return VALIDATION_MESSAGE.CUSTOM_FIELD_DATA_TYPE
  }

  return VALIDATION_MESSAGE.VALIDATION_SUCCESFUL
}

export const matchFieldsWithHeadersUtil = (
  mappingPreview: MappingPreview[],
  previewHeader: string[],
  visibleFields: UnifiedListFieldMapping[],
  getUpdatedMappingField: (mappingField: MappingPreview, fieldMapping: UnifiedListFieldMapping) => MappingPreview
): MappingPreview[] => {
  const matchingFields = previewHeader
    .map((header, index) => {
      const normalizedHeader: string = header.toLowerCase().trim()
      const field = !header.startsWith('_')
        ? visibleFields.find(({ displayName }) => (displayName?.toLowerCase().trim() || '') === normalizedHeader) ||
          visibleFields.find(({ displayName, standardFieldKey }) => {
            const normalizedDisplayName = displayName?.toLowerCase().trim() || ''
            return (
              standardFieldKey &&
              (normalizedHeader.includes('email') || normalizedHeader.includes('e-mail')) &&
              (normalizedDisplayName.includes('email') || normalizedDisplayName.includes('e-mail'))
            )
          })
        : undefined
      return { field, index }
    })
    .filter(({ field }) => field !== undefined)

  return mappingPreview.map((mappingField, index) => {
    if (mappingField.mapping) {
      return mappingField
    }
    const { field: matchingField } = matchingFields.find(({ index }) => index === mappingField.index) || {}
    const updated = getUpdatedMappingField(mappingField, matchingField as UnifiedListFieldMapping)
    const isFirstDuplicate =
      matchingFields.find(({ field }) =>
        matchingField?.standardFieldKey === null
          ? field?.displayName?.toLowerCase().trim() === matchingField?.displayName?.toLowerCase().trim()
          : field?.standardFieldKey === matchingField?.standardFieldKey
      )?.index === index

    return matchingField
      ? ({
          ...mappingField,
          ...updated,
          dataType: isFirstDuplicate ? updated.dataType : '',
          mappingIndex: isFirstDuplicate ? updated.mappingIndex : undefined,
        } as MappingPreview)
      : mappingField
  })
}

export const getTopDirectSelectSegmentOptionsUtils = async (
  getTopDirectSelectSegmentsRequest: GetTopDirectSelectSegmentsRequestType,
  currentPage: number
) => {
  const { data } = await getTopDirectSelectSegmentsRequest(currentPage)
  if (data?.getTopDirectSelectSegments) {
    const items = data.getTopDirectSelectSegments.map((item) => JSON.parse(item?.item ?? ''))
    return items.map(({ id, name }) => ({ label: name, value: id }))
  } else {
    return []
  }
}

export const isAValidSegmentNameUtils = async (isAValidSegmentNameRequest: IsAValidSegmentNameRequestType, segmentName: string, update: Update) => {
  const { data } = await isAValidSegmentNameRequest({ segmentName })
  update({ validSegmentName: data?.isAValidSegmentName ?? true })
  return data?.isAValidSegmentName ?? true
}

export const createSegmentUtils = async (createSegmentRequest: CreateSegmentRequestType, segmentName: string, setContainerValues: Function) => {
  const { data } = await createSegmentRequest({ segmentName })
  if (data?.createSegment) {
    const segmentCreatedOption: SelectV2SingleOption = { label: segmentName, value: data.createSegment.id }
    setContainerValues((containerValues: ImportContactsContainerValues) => ({
      ...containerValues,
      selectOptions: [segmentCreatedOption, ...containerValues.selectOptions],
      segmentSelectedOption: segmentCreatedOption,
    }))
  }
}

export const searchSegmentUtils = async (searchDirectSelectSegmentsRequest: SearchDirectSelectSegmentsRequestType, search: string) => {
  const { data } = await searchDirectSelectSegmentsRequest(search)
  if (data?.getAllItems) {
    const items = (data.getAllItems?.map((item) => JSON.parse(item?.item ?? '')) ?? []).filter(({ parent }) => !parent)
    return items.map(({ id, name }) => ({ label: name, value: id }))
  } else {
    return []
  }
}

export const IMPORT_CONTACTS_URL = `/importContacts`

export const getImportContactsSteps = (t: TFunction, allCompleted: boolean, sectionParam = 'file'): ProgressBarStep[] => {
  const activeSectionByParam: { [key: string]: ImportContactsSteps } = {
    file: ImportContactsSteps.SELECT_FILE,
    options: ImportContactsSteps.IMPORT_OPTIONS,
    mapping: ImportContactsSteps.MAP_FIELDS,
    review: ImportContactsSteps.REVIEW,
  }

  return [
    {
      key: ImportContactsSteps.SELECT_FILE,
      header: t('ImportContactsV2.Sections.ImportFile'),
      isCompleted: allCompleted,
    },
    {
      key: ImportContactsSteps.IMPORT_OPTIONS,
      header: t('ImportContactsV2.Sections.ImportOptions'),
      isCompleted: allCompleted,
    },
    {
      key: ImportContactsSteps.MAP_FIELDS,
      header: t('ImportContactsV2.Sections.MapFields'),
      isCompleted: allCompleted,
    },
    {
      key: ImportContactsSteps.REVIEW,
      header: t('ImportContactsV2.Sections.ReviewImport'),
      isCompleted: allCompleted,
    },
    {
      key: ImportContactsSteps.IMPORT_IN_PROGRESS,
      isCompleted: allCompleted,
      hidden: true,
    },
    {
      key: ImportContactsSteps.UPLOAD_IN_PROGRESS,
      isCompleted: allCompleted,
      hidden: true,
    },
  ].map((step) => ({ ...step, isActive: step.key === activeSectionByParam[sectionParam] }))
}

export const convertDelimiter = (delimiter: Delimiter) => {
  const delimiterMap: { [key: string]: string } = {
    [Delimiter.Comma]: ',',
    [Delimiter.SemiColon]: ';',
    [Delimiter.Tab]: '\t',
  }
  return delimiterMap[delimiter]
}

export const convertQuoteChar = (quoteChar: Quote) => {
  const quoteCharMap: { [key: string]: string } = {
    [Quote.Quote]: '"',
    [Quote.SimpleQuote]: "'",
  }
  return quoteCharMap[quoteChar]
}
