import React, { FC, useEffect, useState } from 'react'
import { Cell } from 'react-table'

import classNames from 'classnames'

import ActionableNestedTable from '@components/ActionableNestedTable/ActionableNestedTable'
import Container from '@components/Container'
import { TableColumn } from '@components/Table/Table'
import { MultipleTables } from '@components/TableV2/components/MultipleTables/MultipleTables'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { AuditDto } from '@graphql/types/microservice/categorization-types'
import { useProgramStepsAndDetailsQueries } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/utils/ProgramStepsAndDetails.graphQL'
import { formatSendTSDate } from '@utils/date'
import { logNewRelicError } from '@utils/new-relic.utils'

import './ListProgramActivities.css'

interface ListProgramActivitiesProps {
  className?: string
  dataTest?: string
  programId: string
  enableTableV2?: boolean
  setEnableTableV2?: React.Dispatch<React.SetStateAction<boolean>>
}

enum ProgramActivity {
  EDITED = 'EDITED',
  CREATED = 'CREATED',
}

const rootClass = 'list-program-activities'
const DEFAULT_PAGE_SIZE = 20
const ListProgramActivities: FC<ListProgramActivitiesProps> = (props: ListProgramActivitiesProps) => {
  const { dataTest = rootClass, className = '', programId } = props

  const [activities, setActivities] = useState<AuditDto[]>([])
  const [loading, setLoading] = useState(true)
  const [enableTableV2, setEnableTableV2] = useState(false)

  const { getActivities } = useProgramStepsAndDetailsQueries()

  const { t } = useTranslation()

  const fetchActivities = (pageIndex: number, pageSize: number) => {
    getActivities(programId, pageIndex, pageSize)
      .then(({ data }) => {
        if (data?.auditList) {
          setActivities(data.auditList as AuditDto[])
        }
      })
      .catch((error) => {
        logNewRelicError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchActivities(INITIAL_PAGE, DEFAULT_PAGE_SIZE)
  }, [])

  const activityTypes: { [key in ProgramActivity]: string } = {
    [ProgramActivity.EDITED]: t('Program edited'),
    [ProgramActivity.CREATED]: t('Program created'),
  }

  const activityColumns: TableColumn[] = [
    {
      Header: 'Activity',
      accessor: 'auditType',
      align: 'left',
      flexColumn: true,
      Cell: ({ value }: Cell<AuditDto>) => activityTypes[value as ProgramActivity],
    },
    {
      Header: 'By',
      accessor: 'username',
      align: 'left',
      maxWidth: 200,
      Cell: ({ value }: Cell<AuditDto>) => <Typography className={'ellip'} text={value} title={value} />,
    },
    {
      Header: 'Time',
      accessor: 'createdTime',
      align: 'left',
      maxWidth: 160,
      Cell: ({ value }: Cell<AuditDto>) => formatSendTSDate(value, { dateFormat: 'MMM d, yyyy', includeTime: true }),
    },
  ]

  const activityColumnsV2: ColumnDefWithAdditionalProps<AuditDto>[] = [
    {
      header: 'Activity',
      accessorKey: 'auditType',
      textAlign: 'left',
      cell: ({ getValue }) => activityTypes[getValue<ProgramActivity>()],
    },
    {
      header: 'By',
      accessorKey: 'username',
      textAlign: 'left',
      maxSize: 200,
      cell: ({ getValue }) => <Typography className={'ellip'} text={getValue<ProgramActivity>()} title={getValue<ProgramActivity>()} />,
    },
    {
      header: 'Time',
      accessorKey: 'createdTime',
      textAlign: 'left',
      maxSize: 200,
      cell: ({ getValue }) => formatSendTSDate(getValue<number>(), { dateFormat: 'MMM d, yyyy', includeTime: true }),
    },
  ]

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className="push-down-x2" onClick={() => setEnableTableV2?.((prev) => !prev)}>
        &nbsp;
      </div>
      <Container className={classNames(rootClass, className)} dataTest={dataTest} noSidePadding>
        <Typography text={t('Activity')} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
        <MultipleTables
          enableTableV2={enableTableV2}
          tableV2Props={{
            columns: activityColumnsV2,
            data: activities,
            withoutBorders: true,
            styles: { minWidth: 'auto' },
          }}
          oldTable={
            <ActionableNestedTable
              className={`${rootClass}__table`}
              dataTest={`${dataTest}-table`}
              data={activities}
              columns={activityColumns}
              loading={loading}
            />
          }
        />
      </Container>
    </>
  )
}

const INITIAL_PAGE = 0

export default ListProgramActivities
