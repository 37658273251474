import React, { FC, useMemo, useState } from 'react'

import classNames from 'classnames'
import { TFunction } from 'i18next'

import { LabelType, LabelV2 } from '@components/LabelV2/LabelV2'
import NumberBadge, { NumberBadgeType } from '@components/NumberBadge/NumberBadge'
import { SvgNames } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import { TableV2 } from '@components/TableV2/TableV2'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { caseInsensitive } from '@components/TableV2/utils/tableV2Utils'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ListErrors, PersonalizationFieldErrors } from '@graphql/types/query-types'
import { getPersonalizationErrorsCount } from '@src/pages/EmailComposer/components/common/PersonalizationCheckCard/utils/PersonalizationCheck.utils'

import './PersonalizationCheckErrors.css'

interface PersonalizationCheckResultsProps {
  errors?: PersonalizationFieldErrors[]
  dataTest?: string
}

const rootClass = 'personalization-check-errors'

export const PersonalizationCheckErrors: FC<PersonalizationCheckResultsProps> = ({ errors, dataTest = rootClass }) => {
  const { t } = useTranslation()

  const [selectedError, setSelectedError] = useState<PersonalizationFieldErrors | undefined>(errors ? errors[0] : undefined)

  if (!errors) return null

  return (
    <div className={rootClass} data-test={dataTest}>
      <div className={`${rootClass}__sidebar`}>
        <LabelV2 labelType={LabelType.section} className={`${rootClass}__sidebar-title`} withoutMargin>
          {t('Personalization fields with errors')}
        </LabelV2>
        <div className={`${rootClass}__sidebar-filters`}>
          {errors.map((error, index) => (
            <ErrorFilter
              key={error.field}
              isSelected={selectedError?.field === error.field}
              onClick={setSelectedError}
              error={error}
              dataTest={dataTest}
              index={index}
            />
          ))}
        </div>
      </div>
      {selectedError && <PersonalizationErrorDetails error={selectedError} dataTest={`${dataTest}-details`} />}
    </div>
  )
}

interface ErrorFilterProps {
  onClick: (error: PersonalizationFieldErrors) => void
  isSelected: boolean
  error: PersonalizationFieldErrors
  dataTest: string
  index: number
}
const missingOrInvalidText = 'Missing or invalid field'

const ErrorFilter: FC<ErrorFilterProps> = ({ onClick, isSelected, error, dataTest, index }) => {
  const { field, fieldType, hasFallbackText } = error
  const displayText = fieldType === missingOrInvalidText || hasFallbackText ? `{{${field}}}` : field
  const _dataTest = `${dataTest}-sidebar-filter-${index}`
  const className = `${rootClass}__sidebar__filter`
  const errorsCount = getPersonalizationErrorsCount(error)

  if (!errorsCount) {
    return null
  }

  return (
    <button className={classNames(className, { [`${className}-selected`]: isSelected })} onClick={() => onClick(error)} data-test={_dataTest}>
      <TextWithTooltipOnEllip
        typographyProps={{ text: displayText, weight: TextWeight.MEDIUM, className: `${className}-title`, dataTest: `${_dataTest}-title` }}
      />
      <Typography text={fieldType} type={TextType.NORMAL_TEXT_GRAY} className={`${className}-type`} dataTest={`${_dataTest}-type`} />
      <NumberBadge
        className={`${className}-count`}
        value={errorsCount}
        type={NumberBadgeType.IMPORTANT}
        dataTest={`${_dataTest}-count`}
        withoutXMargin
      />
    </button>
  )
}

const getColumns: (t: TFunction, impacted?: number, total?: number) => ColumnDefWithAdditionalProps<ListErrors & { errorDescription: string }>[] = (
  t,
  impacted = 0,
  total = 0
) => [
  {
    header: 'Recipient list',
    accessorKey: 'name',
    textAlign: 'left',
    sortingFn: caseInsensitive,
    cell: ({ row: { original } }) => (
      <>
        <Typography weight={TextWeight.MEDIUM} text={original.name} className="push-up-half" />
        <Typography text={original.listType} type={TextType.NORMAL_TEXT_GRAY} />
      </>
    ),
    footer: () => <Typography text={t('Total recipients impacted:')} weight={TextWeight.MEDIUM} />,
  },
  {
    header: 'Error Description',
    accessorKey: 'errorDescription',
    textAlign: 'left',
    enableLongContent: true,
    minSize: 348,
  },
  {
    header: 'Recipients impacted',
    accessorKey: 'errorCount',
    textAlign: 'right',
    footer: () => <Typography text={t('Impacted.Recipients.Count', { impacted, total })} weight={TextWeight.MEDIUM} />,
  },
]

const defaultSortBy = [
  {
    id: 'name',
    desc: false,
  },
]

interface PersonalizationErrorDetailsProps {
  error: PersonalizationFieldErrors
  dataTest: string
}

const PersonalizationErrorDetails: FC<PersonalizationErrorDetailsProps> = ({ error, dataTest }) => {
  const { t } = useTranslation()
  const { field, listErrors, totalErrorCount, totalContactCount, hasFallbackText } = error
  const hasFieldError = !!listErrors.length
  const errorsCount = getPersonalizationErrorsCount(error)

  const columns = useMemo(() => getColumns(t, totalErrorCount, totalContactCount), [t, totalErrorCount, totalContactCount])

  const infoLabels = useMemo(() => {
    const fallBackLabels = !hasFallbackText ? ['fallback'] : []
    const fieldLabels = hasFieldError ? (hasFallbackText ? ['fieldMissingHasFallback'] : ['fieldMissing', 'notReceive']) : []
    return [...fallBackLabels, ...fieldLabels]
  }, [hasFallbackText, hasFieldError])

  const data = listErrors.map((error) => ({
    ...error,
    errorDescription:
      error.contactCount === error.errorCount
        ? 'Personalization field column is missing. None of these recipients will receive your email.'
        : 'Some recipients on this list are missing required Personalization field values.',
  }))

  return (
    <div className={`${rootClass}__details`} data-test={dataTest}>
      <LabelV2 labelType={LabelType.large} label={field} dataTest={`${dataTest}-title`} />
      {hasFieldError && (
        <Typography
          text={t('EmailComposer.Check.Status.Personalization.Modal.Error.Description', {
            totalErrorCount,
            totalContactCount,
            count: errorsCount,
          })}
          tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
          type={TextType.BODY_TEXT_LIGHT}
          className="push-down-x2"
          dataTest={`${dataTest}-info-text`}
        />
      )}
      {errorsCount && (
        <div className="push-down-x4 push-up-x4">
          <LabelV2 labelType={LabelType.section} label={t('Personalization errors:')} className="push-up-x2" />
          {infoLabels.map((key) => {
            const isError = key === 'fieldMissing'
            const iconSvgName = isError ? SvgNames.spamCheckFailNoFill : SvgNames.spamCheckWarningNoFill
            const iconFill = isError ? SvgColor.ERROR_TEXT : SvgColor.YELLOW_CAUTION
            return (
              <LabelV2
                key={key}
                labelType={LabelType.standard}
                label={
                  <Typography
                    text="EmailComposer.Check.Status.Personalization.Modal.Error"
                    tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
                    values={{
                      context: key,
                      errorsCount: totalErrorCount,
                      count: totalContactCount,
                    }}
                  />
                }
                tooltip={{
                  content: 'EmailComposer.Check.Status.Personalization.Modal.Error.Tooltip',
                  childrenProps: { tagProps: { bold: { weight: TextWeight.BOLD, inline: true } }, values: { context: key } },
                  align: 'start',
                  minimalPadding: false,
                }}
                iconSvgProps={{ name: iconSvgName, fill: iconFill }}
                dataTest={`${dataTest}-${key}-error`}
              />
            )
          })}
        </div>
      )}
      {hasFieldError && <TableV2 data={data} columns={columns} styles={{ minWidth: 'auto' }} sortingBy={defaultSortBy} enableFooter />}
    </div>
  )
}
