import React, { FC } from 'react'

import Button, { ButtonType } from '@components/Button'
import StaticImage from '@components/StaticImage/StaticImage'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { TextAlign, TextType, TextWeight } from '@components/Typography/Typography'
import { LitmusPreviewGroups } from '@src/pages/EmailComposer/components/EmailComposerPreview/EmailComposerPreview.utils'
import { useTranslation } from '@utils/const/globals'

import './InboxSidebarEmptyState.css'

interface InboxSidebarEmptyStateProps {
  type?: LitmusPreviewGroups
  isChild?: boolean
  isSearch?: boolean
  onClearSearch?: () => void
}

const EMPTY_ITEM_TITLE = 'EmailComposer.Preview.Inbox.Sidebar.Empty.Item.Title'
const EMPTY_ITEM_DESC = 'EmailComposer.Preview.Inbox.Sidebar.Empty.Item.Desc'
const EMPTY_SEARCH_ITEM_TITLE = 'EmailComposer.Preview.Inbox.Sidebar.Empty.Search.Item.Title'
const EMPTY_SEARCH_ITEM_DESC = 'EmailComposer.Preview.Inbox.Sidebar.Empty.Search.Item.Desc'
const EMPTY_SEARCH_TITLE = 'EmailComposer.Preview.Inbox.Sidebar.Empty.Search.Title'
const EMPTY_SEARCH_DESC = 'EmailComposer.Preview.Inbox.Sidebar.Empty.Search.Desc'

const rootClass = 'inbox-sidebar-empty-state'

export const InboxSidebarEmptyState: FC<InboxSidebarEmptyStateProps> = ({ isChild, isSearch, type, onClearSearch }) => {
  const { t } = useTranslation()

  return (
    <div className={rootClass} data-test={rootClass}>
      {isChild && isSearch ? (
        <StaticImage className={`${rootClass}__image push-up`} name={StaticImageNames.emptyBox} />
      ) : (
        <Svg className={`${rootClass}__svg push-up`} name={SvgNames.previewOff} type={SvgType.VERY_LARGE_ICON} />
      )}
      <Typography
        text={t(isChild ? (isSearch ? EMPTY_SEARCH_ITEM_TITLE : EMPTY_ITEM_TITLE) : EMPTY_SEARCH_TITLE, { type: type?.toLocaleLowerCase() })}
        type={TextType.BODY_TEXT_LARGE}
        weight={TextWeight.MEDIUM}
        textAlign={TextAlign.CENTER}
      />
      <Typography
        text={t(isChild ? (isSearch ? EMPTY_SEARCH_ITEM_DESC : EMPTY_ITEM_DESC) : EMPTY_SEARCH_DESC, { type: type?.toLocaleLowerCase() })}
        textAlign={TextAlign.CENTER}
      />
      {isChild ? (
        <TextLink className="push-down" size={TextLinkSize.LARGE} text={t(`Manage clients`)} onClick={() => null} />
      ) : (
        <Button buttonType={ButtonType.FLOAT_TEAL} onClick={onClearSearch}>
          {t(`Clear search`)}
        </Button>
      )}
    </div>
  )
}
