import { gql } from '@apollo/client'

export default gql`
  query getEmailTemplatePreview($templateId: String!) {
    getEmailTemplatePreview(templateId: $templateId) {
      status
      message
      html
    }
  }
`
