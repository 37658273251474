import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'

import { DateRangeValueType } from '@components/DateRangePicker/DateRangePicker'
import { DataListByDateWithRevenue, DataListBySourceWithRevenue, OpportunitiesSectionData } from '@graphql/types/microservice/data-lake-query-types'
import { fillMissingData, getMonthDifference } from '@src/pages/reports/revenueImpactNew/components/ChartSection/ChartSection.utils'
import { RevenueImpactDataAnalytics } from '@src/pages/reports/revenueImpactNew/components/DataAnalytics/DataAnalytics'
import {
  OpportunitiesSecondOption,
  OpportunitiesTabIndex,
} from '@src/pages/reports/revenueImpactNew/components/Opportunities/Opportunities.constants'
import {
  getRevenueRangeToMilliseconds,
  getPreviousSameLengthFormattedRange,
  getTrendInPercentage,
} from '@src/pages/reports/revenueImpactNew/RevenueImpactNew.utils'
import { filterNotEmptyArray } from '@utils/array'
import { getCountDisplay } from '@utils/numbers'

type ByDateChartDataProps = {
  [key in OpportunitiesSecondOption]: { name: string; data: number[][]; color: string }[]
}

export interface OpportunitiesDataColumnChartField {
  name: string
  data: number[]
  color: string
}

type CombinedDataProps = {
  [key in OpportunitiesTabIndex]: {
    [key in OpportunitiesSecondOption]: {
      categories: string[]
      data: OpportunitiesDataColumnChartField[]
    }
  }
}

export type OpportunitiesFormattedData = {
  analytics: { [key in OpportunitiesTabIndex]: RevenueImpactDataAnalytics[] }
  bySource: CombinedDataProps
  byCampaign: CombinedDataProps
  byDate: ByDateChartDataProps
  startTime: number
  endTime: number
}

const isNumber: (num: any) => boolean = (num) => typeof num === 'number'

const getOpportunitiesFormattedData: (data: OpportunitiesSectionData, range: DateRangeValueType, t: (s: any) => any) => OpportunitiesFormattedData = (
  data,
  range,
  t
) => {
  const { allOpportunities, newOpportunities, wonOpportunities } = data
  const { startTime, endTime } = getRevenueRangeToMilliseconds(range)
  const monthDifference = getMonthDifference(startTime, endTime)
  const isOneMonth = monthDifference <= 1
  const isMoreThreeMonths = monthDifference >= 3
  const isMoreThanTwoMonths = monthDifference >= 2

  const {
    count: allCount,
    revenue: allRevenue,
    trend: allTrend,
    topMonth: allTopMonth,
    topSource: allTopSource,
    topCampaign: allTopCampaign,
  } = { ...allOpportunities }

  const {
    count: newCount,
    revenue: newRevenue,
    trend: newTrend,
    topMonth: newTopMonth,
    topSource: newTopSource,
    topCampaign: newTopCampaign,
  } = { ...newOpportunities?.analytics }

  const {
    count: wonCount,
    revenue: wonRevenue,
    trend: wonTrend,
    topMonth: wonTopMonth,
    topSource: wonTopSource,
    topCampaign: wonTopCampaign,
  } = { ...wonOpportunities?.analytics }

  const { byCampaign: newByCampaign = [], byDate: newByDate = [], bySource: newBySource = [] } = { ...newOpportunities }
  const { byCampaign: wonByCampaign = [], byDate: wonByDate = [], bySource: wonBySource = [] } = { ...wonOpportunities }

  const analytics: { [key in OpportunitiesTabIndex]: RevenueImpactDataAnalytics[] } = {
    all: [
      { title: t('All opportunities'), value: getCountDisplay(allCount) },
      {
        title: t('All revenue'),
        value: isNumber(allRevenue) ? `$${getCountDisplay(allRevenue)}` : '0',
        tooltipTypographyProps: {
          text: t('Expected and won revenue'),
        },
      },
      {
        title: t('Revenue trend'),
        value: getTrendInPercentage(allTrend),
        trend: allTrend ? { isPositive: allTrend > 0 } : undefined,
        tooltipTypographyProps: {
          text: t('Revenue.Opportunities.Trend.Tooltip'),
          values: { dateRange: getPreviousSameLengthFormattedRange(range) },
        },
      },
      {
        title: t('Top lead source'),
        value: allTopSource ?? undefined,
        emptyValueText: allTopSource === '' ? t('No lead source') : undefined,
      },
      {
        title: t('Top campaign type'),
        value: allTopCampaign ?? undefined,
        emptyValueText: allTopCampaign === '' ? t('No campaign type') : undefined,
      },
    ],
    newOp: [
      { title: t('New opportunities'), value: isNumber(newCount) ? getCountDisplay(newCount) : undefined },
      {
        title: t('Expected revenue'),
        value: isNumber(newRevenue) ? `$${getCountDisplay(newRevenue)}` : undefined,
      },
      {
        title: t('Revenue trend'),
        value: getTrendInPercentage(newTrend),
        trend: newTrend ? { isPositive: newTrend > 0 } : undefined,
        tooltipTypographyProps: newTrend
          ? {
              text: t('Revenue.Opportunities.Trend.Tooltip'),
              values: { dateRange: getPreviousSameLengthFormattedRange(range) },
            }
          : undefined,
      },
      { title: t('Top lead source'), value: newTopSource || undefined },
      { title: t('Top campaign type'), value: newTopCampaign || undefined },
    ],
    wonOp: [
      { title: t('Won opportunities'), value: isNumber(wonCount) ? getCountDisplay(wonCount) : undefined },
      {
        title: t('Won revenue'),
        value: isNumber(wonRevenue) ? `$${getCountDisplay(wonRevenue)}` : undefined,
      },
      {
        title: t('Revenue trend'),
        value: getTrendInPercentage(wonTrend),
        trend: wonTrend ? { isPositive: wonTrend > 0 } : undefined,
        tooltipTypographyProps: wonTrend
          ? {
              text: t('Revenue.Opportunities.Trend.Tooltip'),
              values: { dateRange: getPreviousSameLengthFormattedRange(range) },
            }
          : undefined,
      },
      { title: t('Top lead source'), value: wonTopSource || undefined },
      { title: t('Top campaign type'), value: wonTopCampaign || undefined },
    ],
  }

  if (isMoreThanTwoMonths) {
    analytics.all.push({ title: t('Top month'), value: allTopMonth ?? undefined, emptyValueText: allTopMonth === '' ? t('No top month') : undefined })
    analytics.newOp.push({ title: t('Top month'), value: newTopMonth || undefined })
    analytics.wonOp.push({ title: t('Top month'), value: wonTopMonth || undefined })
  }
  return {
    analytics,
    byCampaign: getCountAndRevenue(newByCampaign, wonByCampaign, t),
    bySource: getCountAndRevenue(newBySource, wonBySource, t),
    byDate: getDataByDate(newByDate, wonByDate, t, isOneMonth, isMoreThreeMonths, startTime, endTime),
    startTime,
    endTime,
  }
}

export default getOpportunitiesFormattedData

const getCountAndRevenue: (
  newOpData: (DataListBySourceWithRevenue | undefined)[],
  wonOpData: (DataListBySourceWithRevenue | undefined)[],
  t: (s: any) => any
) => CombinedDataProps = (newOpData, wonOpData, t) => {
  const allDataMap = new Map<
    string,
    {
      newOp: { count: number; revenue: number }
      wonOp: { count: number; revenue: number }
    }
  >([])

  const filteredNewOp = newOpData.filter(filterNotEmptyArray)
  const filteredWonOp = wonOpData.filter(filterNotEmptyArray)

  filteredNewOp.forEach(
    ({ name, count, revenue }) =>
      (name || name === '') && allDataMap.set(name, { newOp: { count: count ?? 0, revenue: revenue ?? 0 }, wonOp: { count: 0, revenue: 0 } })
  )

  filteredWonOp.forEach(({ name, count, revenue }) => {
    if (typeof name !== 'string') {
      return
    }
    const campaign = allDataMap.get(name)
    if (!campaign) {
      allDataMap.set(name, { wonOp: { count: count ?? 0, revenue: revenue ?? 0 }, newOp: { count: 0, revenue: 0 } })
    } else {
      allDataMap.set(name, { wonOp: { count: count ?? 0, revenue: revenue ?? 0 }, newOp: campaign.newOp })
    }
  })

  // Sorting all data by 'New opportunities' because it's on left
  const allDataSortedByCount = Array.from(allDataMap).sort((a, b) => b[1].newOp.count - a[1].newOp.count)
  const allDataSortedByRevenue = Array.from(allDataMap).sort((a, b) => b[1].newOp.revenue - a[1].newOp.revenue)
  const newOpSortedByCount = filteredNewOp
    .filter(({ name }) => name || name === '')
    .sort((a, b) => (b.count ?? 0) - (a.count ?? 0)) as (DataListBySourceWithRevenue & { name: string })[]
  const newOpSortedByRevenue = filteredNewOp
    .filter(({ name }) => name || name === '')
    .sort((a, b) => (b.revenue ?? 0) - (a.revenue ?? 0)) as (DataListBySourceWithRevenue & { name: string })[]
  const wonOpSortedByCount = filteredWonOp
    .filter(({ name }) => name || name === '')
    .sort((a, b) => (b.count ?? 0) - (a.count ?? 0)) as (DataListBySourceWithRevenue & { name: string })[]
  const wonOpSortedByRevenue = filteredWonOp
    .filter(({ name }) => name || name === '')
    .sort((a, b) => (b.revenue ?? 0) - (a.revenue ?? 0)) as (DataListBySourceWithRevenue & { name: string })[]

  const newOpColProps = { name: t('New Opportunities'), color: '#A084DD' }
  const wonOpColProps = { name: t('Won Opportunities'), color: '#36C9B6' }

  return {
    [OpportunitiesTabIndex.All]: {
      [OpportunitiesSecondOption.COUNT]: {
        categories: allDataSortedByCount.map(([category]) => category),
        data: [
          {
            data: allDataSortedByCount.map(([, { newOp }]) => newOp.count),
            ...newOpColProps,
          },
          {
            data: allDataSortedByCount.map(([, { wonOp }]) => wonOp.count),
            ...wonOpColProps,
          },
        ],
      },
      [OpportunitiesSecondOption.REVENUE]: {
        categories: allDataSortedByRevenue.map(([category]) => category),
        data: [
          {
            data: allDataSortedByRevenue.map(([, { newOp }]) => newOp.revenue),
            ...newOpColProps,
          },
          {
            data: allDataSortedByRevenue.map(([, { wonOp }]) => wonOp.revenue),
            ...wonOpColProps,
          },
        ],
      },
    },
    [OpportunitiesTabIndex.NEW_OP]: {
      [OpportunitiesSecondOption.COUNT]: {
        categories: newOpSortedByCount.map(({ name }) => name),
        data: [
          {
            data: newOpSortedByCount.map(({ count }) => count ?? 0),
            ...newOpColProps,
          },
        ],
      },
      [OpportunitiesSecondOption.REVENUE]: {
        categories: newOpSortedByRevenue.map(({ name }) => name),
        data: [
          {
            data: newOpSortedByRevenue.map(({ revenue }) => revenue ?? 0),
            ...newOpColProps,
          },
        ],
      },
    },
    [OpportunitiesTabIndex.WON_OP]: {
      [OpportunitiesSecondOption.COUNT]: {
        categories: wonOpSortedByCount.map(({ name }) => name),
        data: [
          {
            data: wonOpSortedByCount.map(({ count }) => count ?? 0),
            ...wonOpColProps,
          },
        ],
      },
      [OpportunitiesSecondOption.REVENUE]: {
        categories: wonOpSortedByRevenue.map(({ name }) => name),
        data: [
          {
            data: wonOpSortedByRevenue.map(({ revenue }) => revenue ?? 0),
            ...wonOpColProps,
          },
        ],
      },
    },
  }
}

const getStartPeriod = (date: Dayjs, isOneMonth: boolean, isMoreThreeMonths: boolean): number => {
  return isOneMonth ? date.valueOf() : date.startOf(isMoreThreeMonths ? 'month' : 'week').valueOf()
}

const getDataByDate: (
  newOpData: (DataListByDateWithRevenue | undefined)[],
  wonOpData: (DataListByDateWithRevenue | undefined)[],
  t: (s: any) => any,
  isOneMonth: boolean,
  isMoreThreeMonths: boolean,
  startTime: number,
  endTime: number
) => ByDateChartDataProps = (newOpData, wonOpData, t, isOneMonth, isMoreThreeMonths, startTime, endTime) => {
  const dataMap = new Map<
    number,
    {
      newOp: { count: number; revenue: number }
      wonOp: { count: number; revenue: number }
    }
  >([])

  newOpData.filter(filterNotEmptyArray).forEach(({ date, count, revenue }) => {
    const _date = dayjs(date)
    if (!_date.isValid()) {
      return
    }
    const dateValue = getStartPeriod(_date, isOneMonth, isMoreThreeMonths)
    const _count = count ?? 0
    const _revenue = revenue ?? 0

    const campaign = dataMap.get(dateValue) ?? { newOp: { count: 0, revenue: 0 }, wonOp: { count: 0, revenue: 0 } }
    dataMap.set(dateValue, { newOp: { count: campaign.newOp.count + _count, revenue: campaign.newOp.revenue + _revenue }, wonOp: campaign.wonOp })
  })

  wonOpData.filter(filterNotEmptyArray).forEach(({ date, count, revenue }) => {
    const _date = dayjs(date)
    if (!_date.isValid()) {
      return
    }
    const dateValue = getStartPeriod(_date, isOneMonth, isMoreThreeMonths)
    const _count = count ?? 0
    const _revenue = revenue ?? 0
    const campaign = dataMap.get(dateValue) ?? { newOp: { count: 0, revenue: 0 }, wonOp: { count: 0, revenue: 0 } }
    dataMap.set(dateValue, { wonOp: { count: _count + campaign.wonOp.count, revenue: _revenue + campaign.wonOp.revenue }, newOp: campaign.newOp })
  })
  const sortedDataMap = new Map([...dataMap.entries()].sort((a, b) => a[0] - b[0]))
  const dataValues = Array.from(sortedDataMap.values())
  const dates = Array.from(sortedDataMap.keys())
  return {
    [OpportunitiesSecondOption.COUNT]: [
      {
        name: t('New Opportunities'),
        data: fillMissingData(
          dataValues.map(({ newOp }, index) => [dates[index], newOp.count]),
          isOneMonth,
          isMoreThreeMonths,
          startTime,
          endTime
        ),
        color: '#A084DD',
      },
      {
        name: t('Won Opportunities'),
        data: fillMissingData(
          dataValues.map(({ wonOp }, index) => [dates[index], wonOp.count]),
          isOneMonth,
          isMoreThreeMonths,
          startTime,
          endTime
        ),
        color: '#36C9B6',
      },
    ],
    [OpportunitiesSecondOption.REVENUE]: [
      {
        name: t('New Opportunities'),
        data: fillMissingData(
          dataValues.map(({ newOp }, index) => [dates[index], newOp.revenue]),
          isOneMonth,
          isMoreThreeMonths,
          startTime,
          endTime
        ),
        color: '#A084DD',
      },
      {
        name: t('Won Opportunities'),
        data: fillMissingData(
          dataValues.map(({ wonOp }, index) => [dates[index], wonOp.revenue]),
          isOneMonth,
          isMoreThreeMonths,
          startTime,
          endTime
        ),
        color: '#36C9B6',
      },
    ],
  }
}
