import React from 'react'
import { Link } from 'react-router-dom'

import classNames from 'classnames'

import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { Cell, CellContext, Header } from '@tanstack/react-table'
import { Message } from '@utils/sms.utils'

import { rootClass } from '../TableV2'
import {
  ColWithTitleLinkProps,
  ColWithTitleProps,
  ColWithTooltipProps,
  EnhancedColWithTitleProps,
  EnhancedColWithTooltipProps,
  MutedColProps,
} from '../tableV2TS/interfaces'
import { ColumnDefWithAdditionalProps } from '../tableV2TS/types'

export const renderMutedColV2 = <T extends {}>(cell: CellContext<T, unknown>) => <MutedCol cell={cell} />

const MutedCol = <T extends { status?: string }>({ cell }: MutedColProps<T>) => {
  const isColumnMuted = cell.row.original.status === 'SENDING'
  const columnDef = cell.column.columnDef as ColumnDefWithAdditionalProps<T>
  return (
    <Typography
      className={classNames({ ['ellip']: !columnDef.enableLongContent })}
      text={cell.cell.getValue<string>()}
      key={cell.row.id}
      type={isColumnMuted ? TextType.BODY_TEXT_LIGHT : TextType.BODY_TEXT}
    />
  )
}

export const EnhancedColWithTitle = <T,>({ cell, linkTo, extraContent, target, title }: EnhancedColWithTitleProps<T>) => {
  const cellText = cell.cell.getValue<string>()
  const columnDef = cell.column.columnDef as ColumnDefWithAdditionalProps<T>
  return (
    <>
      <div className={classNames({ ['ellip']: !columnDef.enableLongContent })} title={title ? title : cell.cell.getValue<string>()}>
        {linkTo && (
          <Link className={classNames({ ['ellip']: !columnDef.enableLongContent })} to={linkTo} target={target}>
            {cellText}
          </Link>
        )}
        {!linkTo && cellText}
      </div>
      {extraContent}
    </>
  )
}

export const ColWithTitle = <T extends {}>({ cell, title }: ColWithTitleProps<T>) => {
  const rowValue = cell.getValue<string>()
  const columnDef = cell.column.columnDef as ColumnDefWithAdditionalProps<T>
  const finalTitle = title ?? rowValue
  const value = rowValue ? rowValue : columnDef.fallback ? columnDef.fallback : ''

  return (
    <div className={classNames({ ['ellip']: !columnDef.enableLongContent }, `${rootClass}__ellip`)}>
      <div className={classNames({ ['ellip']: !columnDef.enableLongContent }, `${rootClass}__ellip__wrapper`)} title={finalTitle}>
        {value}
      </div>
    </div>
  )
}

export const renderColWithTitleV2 = <T extends { tooltip?: string } | {}>(cell: ColWithTitleProps<T>['cell']) => <ColWithTitle cell={cell} />

export const ColWithTitleLink = <T,>({ row, linkTo, titleCol }: ColWithTitleLinkProps) => {
  const {
    cell: { getValue },
    row: { original },
  } = row
  const value = getValue<string>()

  const text = value === 'Untitled' ? <em>{value}</em> : value
  const title = titleCol ? `${value}: ${original[titleCol as keyof Message]}` : value
  const columnDef = row.column.columnDef as ColumnDefWithAdditionalProps<T>

  return (
    <Link className={classNames({ ['ellip']: !columnDef.enableLongContent })} to={linkTo} title={title}>
      <Typography
        className={classNames({ ['ellip']: !columnDef.enableLongContent })}
        text={text}
        weight={TextWeight.MEDIUM}
        type={TextType.BODY_TEXT_GRAY}
      />
    </Link>
  )
}

export const getStickyColsWidths = (columnsLength?: number): number[] => {
  const widths: number[] = [0]
  const ths = document.querySelectorAll<HTMLTableCellElement>('[data-sticky]')

  if (ths.length && columnsLength) {
    for (let i = 0; i < columnsLength; i++) {
      widths.push(widths[widths.length - 1] + +ths[i]?.clientWidth)
    }
  }

  return widths
}

export const isColumnIdEqual = <T extends {}>(stickyColumns: string[], cell: Cell<T, unknown> | Header<T, unknown>) => {
  return stickyColumns.some((colID) => colID === cell.column.id)
}

export const ColWithTooltip = <T,>({
  cell,
  tooltipTitle,
  typographySpecifiedTitle,
  weight,
  type,
  tooltipProps,
  typographyProps,
}: ColWithTooltipProps<T>) => {
  const cellValue = cell.cell.getValue<string | string[]>()
  const value = (Array.isArray(cellValue) ? cellValue[0] : cellValue) ?? ''

  return (
    <TextWithTooltipOnEllip
      typographyProps={{ text: typographySpecifiedTitle ?? value, weight, type, ...typographyProps }}
      tooltipProps={{ children: tooltipTitle, ...tooltipProps }}
    />
  )
}

export const EnhancedColWithTooltip = <T,>({ cell, extraContent, tooltipTitle, rootClass }: EnhancedColWithTooltipProps<T>) => {
  return (
    <div className={classNames(rootClass)}>
      <ColWithTooltip cell={cell} tooltipTitle={tooltipTitle} />
      {extraContent}
    </div>
  )
}

export const withTooltipAlwaysEnabled = (text: string, tooltipText: string) => (
  <Tooltip alwaysShowOnHover trigger={<Typography text={text} type={TextType.BODY_TEXT} />}>
    {tooltipText}
  </Tooltip>
)
