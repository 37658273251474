/**
 * Enum representing the different search types available in the TopBarSearch component.
 *
 * @enum {string}
 * @readonly
 */
export enum SearchType {
  CONTACTS = 'contacts',
  ASSETS = 'assets',
  MARKETING = 'marketingListing',
  FORM_SUBMISSIONS = 'formSubmissionListing',
  WEBINAR = 'webinarListing',
  TRASH = 'recyclebinListing',
  IMAGE_LIBRARY = 'imageLibrary',
  MEDIA_FILES = 'mediaListing',
  SEO_WEB_PAGES = 'seoListing',
  SEO_LANDING_PAGES = 'seoLPListing',
  SEO_FORMS = 'seoFormsListing',
  DRAFTS = 'draftLists',
  SENT_MESSAGES = 'sentMessageListing',
  RSS = 'rssListing',
  PAGE = 'filters',
}

/**
 * Interface representing a search option in the TopBarSearch component.
 *
 * @property {string} label - The display label for the search option.
 * @property {SearchType} value - The value corresponding to the search type.
 */
export interface SearchOption {
  label: string
  value: SearchType
}

/**
 * An object containing all available search types and their labels.
 *
 * @type {Record<SearchType, SearchOption>}
 */
export const searchTypes: Record<SearchType, SearchOption> = {
  [SearchType.MARKETING]: {
    label: 'TopBar.Search.Marketing',
    value: SearchType.MARKETING,
  },
  [SearchType.FORM_SUBMISSIONS]: {
    label: 'TopBar.Search.FormSubmissions',
    value: SearchType.FORM_SUBMISSIONS,
  },
  [SearchType.WEBINAR]: {
    label: 'TopBar.Search.Webinar',
    value: SearchType.WEBINAR,
  },
  [SearchType.TRASH]: {
    label: 'TopBar.Search.Trash',
    value: SearchType.TRASH,
  },
  [SearchType.IMAGE_LIBRARY]: {
    label: 'TopBar.Search.Images',
    value: SearchType.IMAGE_LIBRARY,
  },
  [SearchType.MEDIA_FILES]: {
    label: 'TopBar.Search.MediaFiles',
    value: SearchType.MEDIA_FILES,
  },
  [SearchType.SEO_WEB_PAGES]: {
    label: 'TopBar.Search.SEO.WebPages',
    value: SearchType.SEO_WEB_PAGES,
  },
  [SearchType.SEO_LANDING_PAGES]: {
    label: 'TopBar.Search.SEO.LandingPages',
    value: SearchType.SEO_LANDING_PAGES,
  },
  [SearchType.SEO_FORMS]: {
    label: 'TopBar.Search.SEO.Forms',
    value: SearchType.SEO_FORMS,
  },
  [SearchType.DRAFTS]: {
    label: 'TopBar.Search.Drafts',
    value: SearchType.DRAFTS,
  },
  [SearchType.SENT_MESSAGES]: {
    label: 'TopBar.Search.SentMessages',
    value: SearchType.SENT_MESSAGES,
  },
  [SearchType.RSS]: {
    label: 'TopBar.Search.RSS',
    value: SearchType.RSS,
  },
  [SearchType.CONTACTS]: {
    label: 'TopBar.Search.Contacts',
    value: SearchType.CONTACTS,
  },
  [SearchType.ASSETS]: {
    label: 'TopBar.Search.Assets',
    value: SearchType.ASSETS,
  },
  [SearchType.PAGE]: {
    label: 'TopBar.Search.Page',
    value: SearchType.PAGE,
  },
}

/**
 * Default search options available in the TopBarSearch component.
 *
 * @type {SearchOption[]}
 */
export const DEFAULT_SEARCH_OPTIONS: SearchOption[] = [searchTypes[SearchType.CONTACTS], searchTypes[SearchType.ASSETS]]
