import { useEffect, useMemo, useState } from 'react'

import { useApolloClient, useLazyQuery } from '@apollo/client'
import getAllMarketingUsers from '@graphql/queries/getAllMarketingUsers'
import getAllSalesUsers from '@graphql/queries/getAllSalesUsers'
import {
  GetAllMarketingUsersQuery,
  GetAllMarketingUsersQueryVariables,
  GetAllSalesUsersQuery,
  QueryGetAllSalesUsersArgs,
} from '@graphql/types/query-types'
import { UserTypeValue } from '@src/pages/Settings/Users/components/InviteUsersModal/const/userType.const'
import { logNewRelicError } from '@utils/new-relic.utils'

interface ReturnProps {
  availableCount: number
  maxCount: number
  loading: boolean
}

const useInviteUsersCount = (userType: UserTypeValue) => {
  const [countInfo, setCountInfo] = useState<ReturnProps>({ availableCount: 0, maxCount: 0, loading: true })

  const baseUserType = useMemo<UserTypeValue>(() => (userType === UserTypeValue.SALES ? UserTypeValue.SALES : UserTypeValue.MARKETING), [userType])
  const client = useApolloClient()
  const [getMarketingUsers, { data: dataMark, loading: loadMark, error: errorMark }] = useLazyQuery<
    GetAllMarketingUsersQuery,
    GetAllMarketingUsersQueryVariables
  >(getAllMarketingUsers, {
    client,
    fetchPolicy: 'no-cache',
    variables: { sortColumn: 'FULL_NAME', sortDirection: 'ASC' },
  })

  const [getSalesUsers, { data: dataSales, loading: loadSales, error: errorSales }] = useLazyQuery<GetAllSalesUsersQuery, QueryGetAllSalesUsersArgs>(
    getAllSalesUsers,
    {
      client,
      fetchPolicy: 'no-cache',
      variables: { sortColumn: 'FULL_NAME', sortDirection: 'ASC' },
    }
  )

  useEffect(() => {
    baseUserType === UserTypeValue.SALES ? getSalesUsers() : getMarketingUsers()
    setCountInfo((cur) => ({ ...cur, loading: true }))
  }, [baseUserType])

  useEffect(() => {
    if (baseUserType === UserTypeValue.SALES) {
      if (!loadSales && dataSales?.getAllSalesUsers) {
        const { users = [], maxAllowedCountForPortal: maxCount = 0 } = dataSales.getAllSalesUsers
        const salesUsersCount = users.filter((user) => !!user && user.isCRM === false).length
        setCountInfo({
          availableCount: Math.max(maxCount - salesUsersCount, 0),
          maxCount,
          loading: false,
        })
      }
      if (errorMark) {
        logNewRelicError(errorSales)
      }
    } else {
      if (!loadMark && dataMark?.getAllMarketingUsers) {
        const { marketingUsers = [], maxUsers = 0 } = dataMark?.getAllMarketingUsers
        const usersCount = marketingUsers.filter((user) => !user?.isSingleSignOn).length
        setCountInfo({
          availableCount: Math.max(maxUsers - usersCount, 0),
          maxCount: maxUsers,
          loading: false,
        })
      }
      if (errorMark) {
        logNewRelicError(errorMark)
      }
    }
  }, [baseUserType, dataMark, dataSales, loadMark, loadSales, errorMark, errorSales])

  return { ...countInfo }
}

export default useInviteUsersCount
