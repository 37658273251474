import { Delimiter, Quote } from '@complex/ImportContacts/ImportContacts.constants'
import getItem from '@graphql/microservices/categorization/getItem'
import getTopDirectSelectSegments from '@graphql/microservices/categorization/getTopDirectSelectSegments'
import createImportDefinition from '@graphql/microservices/entity-upload/createImportDefinition'
import createImportSyncJob from '@graphql/microservices/entity-upload/createImportSyncJob'
import deleteImportSyncJob from '@graphql/microservices/entity-upload/deleteImportSyncJob'
import getFtpFiles from '@graphql/microservices/entity-upload/getFtpFiles'
import GetFTPFirstRows from '@graphql/microservices/entity-upload/getFTPFirstRows'
import getImportDefinition from '@graphql/microservices/entity-upload/getImportDefinition'
import getImportSyncJob from '@graphql/microservices/entity-upload/getImportSyncJob'
import updateImportDefinition from '@graphql/microservices/entity-upload/updateImportDefinition'
import updateImportSyncJob from '@graphql/microservices/entity-upload/updateImportSyncJob'
import {
  GetItemQuery,
  GetItemQueryVariables,
  GetTopDirectSelectSegmentsQuery,
  GetTopDirectSelectSegmentsQueryVariables,
  ItemDto,
} from '@graphql/types/microservice/categorization-types'
import {
  CreateImportDefinitionMutation,
  CreateImportDefinitionMutationVariables,
  FtpFileDto,
  GetFtpFilesQuery,
  GetFtpFilesQueryVariables,
  GetFtpFirstRowsQuery,
  GetFtpFirstRowsQueryVariables,
  ImportSyncJobDtoInput,
  ImportDefinitionDto,
  ImportDefinitionDtoInput,
  PreviewContactsDto,
  UpdateImportDefinitionMutation,
  UpdateImportDefinitionMutationVariables,
  GetImportDefinitionQuery,
  GetImportDefinitionQueryVariables,
  GetImportSyncJobQuery,
  GetImportSyncJobQueryVariables,
  ImportSyncJobDto,
  UpdateImportSyncJobMutation,
  UpdateImportSyncJobMutationVariables,
  DeleteImportSyncJobMutation,
  DeleteImportSyncJobMutationVariables,
  CreateImportSyncJobMutation,
  CreateImportSyncJobMutationVariables,
} from '@graphql/types/microservice/entity-upload-types'
import { ImportContactsV2ContainerState } from '@src/pages/ImportContactsV2/utils/ImportContactsV2.context'
import { getCronFromProgramSchedule } from '@src/pages/ImportContactsV2/utils/ImportContactsV2.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export interface ImportContactsV2Requests {
  createImportSyncJobRequest: (importSyncJob?: Partial<ImportSyncJobDtoInput>) => FetchPromise<CreateImportSyncJobMutation>
  getFtpFilesRequest: () => Promise<FtpFileDto[]>
  getImportDefinitionRequest: (importDefinitionId: number) => Promise<ImportDefinitionDto | null>
  getImportSyncJobRequest: (importSyncJobId: number) => Promise<ImportSyncJobDto | null>
  getTopDirectSelectSegmentsRequest: (currentPage: number) => FetchPromise<GetTopDirectSelectSegmentsQuery>
  getSegmentById: (segmentId: string) => Promise<ItemDto | undefined>
  getFtpFirstRowsRequest: (path: string, hasHeader?: boolean, delimiter?: Delimiter, quote?: Quote) => Promise<PreviewContactsDto[]>
  createImportDefinitionRequest: (options: ImportDefinitionDtoInput) => FetchPromise<CreateImportDefinitionMutation>
  updateImportDefinitionRequest: (options: ImportDefinitionDtoInput) => FetchPromise<UpdateImportDefinitionMutation>
  updateImportSyncJobRequest: (importSyncJob?: Partial<ImportSyncJobDtoInput>) => FetchPromise<UpdateImportSyncJobMutation>
  deleteSyncSettingsRequest: () => Promise<Boolean>
}

type UseImportContactsV2RequestsProps = Pick<ImportContactsV2ContainerState, 'selectedFile' | 'importDefinitionId' | 'schedule' | 'importSyncJob'>

export const useImportContactsV2Requests = (props: UseImportContactsV2RequestsProps): ImportContactsV2Requests => {
  const { selectedFile, importDefinitionId, schedule, importSyncJob } = props

  const { client: entityUploadClient } = useMicroserviceClient({ serviceName: MicroserviceClients.ENTITY_UPLOAD })
  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })

  const { accountId, timeZoneId } = useAccountSettings()

  const getImportSyncJobInput = (importSyncJobValues?: Partial<ImportSyncJobDtoInput>): ImportSyncJobDtoInput => ({
    accountId,
    cron: !schedule.isScheduled ? undefined : getCronFromProgramSchedule(schedule),
    enabled: true,
    filePath: selectedFile?.urlPath,
    hasToRunNow: !schedule.isScheduled,
    importDefinitionId,
    isRunning: false,
    scheduleTimeZone: timeZoneId,
    ...(importSyncJob
      ? {
          importSyncJobId: importSyncJob.importSyncJobId,
          lastExecution: importSyncJob.lastExecution,
          createdTime: importSyncJob.createdTime,
        }
      : {}),
    ...importSyncJobValues,
  })

  const createImportSyncJobRequest = (importSyncJob?: Partial<ImportSyncJobDtoInput>) => {
    return entityUploadClient.mutate<CreateImportSyncJobMutation, CreateImportSyncJobMutationVariables>({
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      mutation: createImportSyncJob,
      variables: {
        importSyncJobDTO: getImportSyncJobInput(importSyncJob),
      },
    })
  }

  const getFtpFilesRequest = () =>
    entityUploadClient
      .query<GetFtpFilesQuery, GetFtpFilesQueryVariables>({
        query: getFtpFiles,
      })
      .then(({ data }) => data.getFtpFiles as FtpFileDto[])

  const getImportDefinitionRequest = (importDefinitionId: number) =>
    entityUploadClient
      .query<GetImportDefinitionQuery, GetImportDefinitionQueryVariables>({
        fetchPolicy: 'network-only',
        query: getImportDefinition,
        variables: {
          importDefinitionId,
        },
      })
      .then(({ data }) => data.getImportDefinition as ImportDefinitionDto)

  const getImportSyncJobRequest = (importSyncJobId: number) =>
    entityUploadClient
      .query<GetImportSyncJobQuery, GetImportSyncJobQueryVariables>({
        fetchPolicy: 'network-only',
        query: getImportSyncJob,
        variables: {
          importSyncJobId,
        },
      })
      .then(({ data }) => data.getImportSyncJob as ImportSyncJobDto)

  const getTopDirectSelectSegmentsRequest = async (currentPage = 0) =>
    await categorizationClient.query<GetTopDirectSelectSegmentsQuery, GetTopDirectSelectSegmentsQueryVariables>({
      query: getTopDirectSelectSegments,
      errorPolicy: 'all',
      variables: {
        type: ItemType.SEGMENT,
        fieldType: 'type',
        pageNumber: currentPage,
        pageSize: 50,
      },
    })

  const getSegmentById = async (segmentId: string) => {
    return categorizationClient
      .query<GetItemQuery, GetItemQueryVariables>({
        query: getItem,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        variables: {
          externalId: segmentId,
          type: ItemType.SEGMENT,
        },
      })
      .then(({ data }) => {
        if (data.getItem) {
          return data.getItem as ItemDto
        }
      })
  }

  const getFtpFirstRowsRequest = (path: string, hasHeader = true, delimiter = Delimiter.Comma, quote = Quote.Quote) =>
    entityUploadClient
      .query<GetFtpFirstRowsQuery, GetFtpFirstRowsQueryVariables>({
        query: GetFTPFirstRows,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        variables: {
          path,
          hasHeader,
          delimiter,
          quote,
        },
      })
      .then(({ data }) => data.getFTPFirstRows as PreviewContactsDto[])

  const createImportDefinitionRequest = (options: ImportDefinitionDtoInput) =>
    entityUploadClient.mutate<CreateImportDefinitionMutation, CreateImportDefinitionMutationVariables>({
      mutation: createImportDefinition,
      variables: {
        importDefinitionDTO: options,
      },
    })

  const updateImportDefinitionRequest = (options: ImportDefinitionDtoInput) =>
    entityUploadClient.mutate<UpdateImportDefinitionMutation, UpdateImportDefinitionMutationVariables>({
      mutation: updateImportDefinition,
      variables: {
        importDefinitionDTO: options,
      },
    })

  const updateImportSyncJobRequest = (importSyncJob?: ImportSyncJobDtoInput) =>
    entityUploadClient.mutate<UpdateImportSyncJobMutation, UpdateImportSyncJobMutationVariables>({
      mutation: updateImportSyncJob,
      variables: {
        importSyncJobDTO: getImportSyncJobInput(importSyncJob),
      },
    })

  const deleteSyncSettingsRequest = () =>
    entityUploadClient
      .mutate<DeleteImportSyncJobMutation, DeleteImportSyncJobMutationVariables>({
        mutation: deleteImportSyncJob,
        variables: {
          importSyncJobId: importSyncJob?.importSyncJobId,
        },
      })
      .then(({ data }) => data?.deleteImportSyncJob as Boolean)

  return {
    createImportSyncJobRequest,
    getFtpFilesRequest,
    getImportDefinitionRequest,
    getImportSyncJobRequest,
    getTopDirectSelectSegmentsRequest,
    getSegmentById,
    getFtpFirstRowsRequest,
    createImportDefinitionRequest,
    updateImportDefinitionRequest,
    updateImportSyncJobRequest,
    deleteSyncSettingsRequest,
  }
}
