import React, { FC } from 'react'

import TagManagerTrigger, { TagManagerTriggerProps } from '@components/TagManagerTrigger/TagManagerTrigger'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

type Props = TagManagerTriggerProps & {
  text?: string
}

const TagManagerTriggerWithText: FC<Props> = (props: Props) => {
  const { text } = props
  const { t } = useTranslation()
  return (
    <TagManagerTrigger {...props}>
      <Typography
        lineHeight={LineHeight.MEDIUM_SMALL}
        text={text ? t(text) : `+ ${t('add tag')}`}
        type={TextType.BODY_TEXT_SMALL_LIGHT}
        weight={TextWeight.MEDIUM}
      />
    </TagManagerTrigger>
  )
}

export default TagManagerTriggerWithText
