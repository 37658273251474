import React from 'react'

import { AccordionDetailsModalSection } from '@components/AccordionDetailsModal/AccordionDetailsModal'
import ActivityCard from '@components/ActivityCard/ActivityCard'
import { ACTIVITY_STATUS, activityStatus } from '@components/ActivityCard/utils/ActivityCard.constants'
import Loader from '@components/Loader'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import TextLink from '@components/TextLink/TextLink'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { ContactRecentActivity } from '@graphql/types/microservice/entity-join-types'
import SubmissionDetails, {
  FieldsData,
} from '@src/pages/listingPages/FormsJoinView/components/DetailsCardWrapper/components/SubmissionDetails/SubmissionDetails'
import {
  ContactInformationField,
  detailsCardWrapperRootClass,
} from '@src/pages/listingPages/FormsJoinView/components/DetailsCardWrapper/DetailsCardWrapper'
import { ItemType } from '@utils/categorization'
import { Contact } from '@utils/contact/contact.constants'
import { ContactInformationFields, sendEmailToContact } from '@utils/contact/contact.utils'
import { ContactViewPanel, openContactView } from '@utils/contacts'

export interface DetailDataItem {
  formName: string
  formDetails: FieldsData[]
}

export enum WebinarType {
  Citrix = 'CITRIX',
  Webex = 'WEBEX',
  Zoom = 'ZOOM',
}

export const getContactsCardHeader = (contactInformation?: ContactInformationFields | undefined) => {
  if (contactInformation) {
    const firstName = contactInformation['First Name'] ?? ''
    const lastName = contactInformation['Last Name'] ?? ''
    return (
      <div className={`${detailsCardWrapperRootClass}__contact-card-header`}>
        <Svg name={SvgNames.userCircle} type={SvgType.SUPER_LARGE_ICON} />
        <div className={`${detailsCardWrapperRootClass}__contact-card-header-info`}>
          <Typography text={`${firstName} ${lastName}`.trim()} weight={TextWeight.BOLD} type={TextType.DATA_CARD_MODAL_HEADER} />
          <Typography text={contactInformation['E-mail Address']} type={TextType.BODY_TEXT_GRAY_SMALL} />
        </div>
      </div>
    )
  } else {
    return <Loader className={`${detailsCardWrapperRootClass}__loader`} />
  }
}

const getContactInformationArray = (data: ContactInformationFields): ContactInformationField[] => {
  return [
    {
      tooltip: 'Act-On primary score',
      value: data['Act-On Primary Score'] ? `Act-On Primary Score: ${data['Act-On Primary Score']}` : '',
      icon: SvgNames.gauge,
    },
    {
      tooltip: 'E-mail address',
      value: data['E-mail Address'],
      icon: SvgNames.letter,
    },
    {
      tooltip: 'Phone number',
      value: data['Cell Phone'],
      icon: SvgNames.phone,
    },
    {
      tooltip: 'Job title',
      value: data['Job Title'],
      icon: SvgNames.jobTitle,
    },
    {
      tooltip: 'Company name',
      value: data.Company,
      icon: SvgNames.office,
    },
    {
      tooltip: 'Contact address',
      value: data['Business Street'],
      icon: SvgNames.location,
    },
  ]
}

export const renderContactInformation = (contactInformation: ContactInformationFields) => {
  return (
    <div className={`${detailsCardWrapperRootClass}__contact-card-information`}>
      {getContactInformationArray(contactInformation).map(
        (field, index) =>
          field.value && (
            <Tooltip
              trigger={
                <div className={`${detailsCardWrapperRootClass}__contact-card-information-field`}>
                  <Svg name={field.icon} type={SvgType.MEDIUM_LARGE_ICON} />
                  <Typography text={field.value} />
                </div>
              }
              key={index}
            >
              {field.tooltip}
            </Tooltip>
          )
      )}
    </div>
  )
}

export const renderContactActivities = (
  recentActivities: ContactRecentActivity[],
  selectedContact: Contact,
  linkText: string,
  itemType: ItemType,
  onActivityPreview: (externalId: string, formName: string, status: ACTIVITY_STATUS) => void
) => (
  <div className={`${detailsCardWrapperRootClass}__contact-card-activities`}>
    {recentActivities.map((activity) => {
      const formName = activity.formName?.substring(activity.formName?.indexOf(':') + 2) as string
      const status: ACTIVITY_STATUS = activityStatus[activity.formName?.slice(0, activity.formName.indexOf(':')) as string]
      return (
        <ActivityCard
          key={activity.formId}
          date={activity.activityTime}
          title={formName}
          status={status}
          onPreview={() => onActivityPreview((activity.formId as string).substring((activity.formId as string).indexOf(':') + 2), formName, status)}
          itemType={itemType}
        />
      )
    })}
    <TextLink hideIcon text={linkText} onClick={() => openContactView(selectedContact.recId, ContactViewPanel.TIMELINE)} />
  </div>
)

export const getContactsCardSections = (
  t: Function,
  recentActivities: ContactRecentActivity[],
  selectedContact: Contact,
  onActivityPreview: (externalId: string, formName: string, status: ACTIVITY_STATUS) => void,
  submissionDetails: DetailDataItem[],
  webinarRegistrationDetails: DetailDataItem,
  itemType: ItemType,
  contactInformation?: ContactInformationFields | undefined
): AccordionDetailsModalSection[] => [
  {
    icon: SvgNames.info,
    content: contactInformation && renderContactInformation(contactInformation),
    isAccordion: true,
    accordionTitle: 'Contact Information',
    accordionTooltips: {
      openTooltip: t(`ListPage.FormsJoinView.${itemType}.DetailsCard.ContactInformation.Open`),
      closeTooltip: t(`ListPage.FormsJoinView.${itemType}.DetailsCard.ContactInformation.Close`),
    },
  },
  {
    icon: itemType == ItemType.WEBINAR_SUBMISSION ? SvgNames.webinarOutline : SvgNames.formSimple,
    content: (
      <div className={`${detailsCardWrapperRootClass}__contact-card-details`}>
        {itemType == ItemType.WEBINAR_SUBMISSION ? (
          <SubmissionDetails
            key={`${webinarRegistrationDetails.formName}`}
            formName={webinarRegistrationDetails.formName}
            data={webinarRegistrationDetails.formDetails}
            itemType={itemType}
            icon={SvgNames.webinarAttended}
          />
        ) : (
          submissionDetails.map((item, index) => (
            <SubmissionDetails key={`${item.formName}-${index}`} formName={item.formName} data={item.formDetails} itemType={itemType} />
          ))
        )}
      </div>
    ),
    isAccordion: true,
    accordionTitle: t(
      `ListPage.FormsJoinView.${itemType}.DetailsCard.${itemType === ItemType.WEBINAR_SUBMISSION ? 'Registration' : 'Submission'}Details.Title`
    ),
    accordionTooltips: {
      openTooltip: t(
        `ListPage.FormsJoinView.${itemType}.DetailsCard.${itemType === ItemType.WEBINAR_SUBMISSION ? 'Registration' : 'Submission'}Details.Open`
      ),
      closeTooltip: t(
        `ListPage.FormsJoinView.${itemType}.DetailsCard.${itemType === ItemType.WEBINAR_SUBMISSION ? 'Registration' : 'Submission'}Details.Close`
      ),
    },
  },
  {
    icon: SvgNames.activities,
    content: renderContactActivities(
      recentActivities,
      selectedContact,
      t(`ListPage.FormsJoinView.${itemType}.DetailsCard.FormActivities.ShowMore`),
      itemType,
      onActivityPreview
    ),
    isAccordion: true,
    accordionTitle: t(`ListPage.FormsJoinView.${itemType}.DetailsCard.FormActivities.Title`),
    accordionTooltips: {
      openTooltip: t(`ListPage.FormsJoinView.${itemType}.DetailsCard.FormActivities.Open`),
      closeTooltip: t(`ListPage.FormsJoinView.${itemType}.DetailsCard.FormActivities.Close`),
    },
  },
]

export const getContactsCardActions = (contact: Contact) => [
  {
    icon: SvgNames.letter,
    tooltip: 'Send email',
    onClick: () => sendEmailToContact(contact.recId),
  },
  {
    icon: SvgNames.contactReport,
    tooltip: 'Contact report',
    onClick: () => openContactView(contact.recId, ContactViewPanel.SUMMARY),
  },
]
