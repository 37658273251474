export enum UserTypeValue {
  MARKETING = 'marketing user',
  SALES = 'sales user',
  ADMIN = 'administrator',
}

enum UserTypeLabel {
  MARKETING = 'Marketing user',
  SALES = 'Sales user',
  ADMIN = 'Administrator',
}

export const userTypeOptions: { value: UserTypeValue; label: UserTypeLabel }[] = [
  { value: UserTypeValue.MARKETING, label: UserTypeLabel.MARKETING },
  { value: UserTypeValue.SALES, label: UserTypeLabel.SALES },
  { value: UserTypeValue.ADMIN, label: UserTypeLabel.ADMIN },
]
