import { useCallback, useEffect, useRef, useState } from 'react'

import equal from 'fast-deep-equal/es6'

import { flattenSubRows } from '@components/TableV2/utils/commonUtils'
import { ColumnSort } from '@tanstack/react-table'
import { UpdateState } from '@utils/types'

import { FilterCounts } from '../Context/ListingPageCommon.context'

export type FilterFunction<T extends {}> = ((item: T, search?: string | number) => boolean) | undefined

export type FilterFunctionMap<T extends {}> = {
  [key: string]: FilterFunction<T>
  search?: FilterFunction<T>
  folder?: FilterFunction<T>
}

type StrictColumnSort<T extends {}> = ColumnSort & { id: keyof T }

export type StrictSortingState<T extends {}> = StrictColumnSort<T>[]

type FrontendFilteringProps<T extends {}> = {
  /** Must be memoized */
  data: T[]
  searchText: string
  activeFilter?: string
  activeSubFilters?: string[]
  activeFolder?: number
  /** Must be memoized */
  filterFunction: FilterFunctionMap<T>
  itemsPerPage?: number
  searchAllData?: boolean
  /** Must be memoized */
  sortingBy?: StrictSortingState<T>
  loading?: boolean
  maxSubRowDepth?: number
  loadingDuration?: number
  excludeGroups?: string
}

type FrontendFilteringState<T extends {}> = {
  data: T[]
  flatData: T[]
  allItemsLoaded: boolean
  filterLoading: boolean
  currentPage: number
  filterCounts: FilterCounts
}

export type FrontendFilteringAPI<T extends {}> = FrontendFilteringState<T> & {
  onLoadNextPage: () => void
  resetCurrentPage?: () => void
}

export const getDefaultFilteringAPI = <T extends {}>(): FrontendFilteringAPI<T> => ({
  data: [] as T[],
  flatData: [] as T[],
  allItemsLoaded: false,
  filterLoading: true,
  currentPage: 0,
  filterCounts: {},
  onLoadNextPage: () => null,
  resetCurrentPage: () => null,
})

const DEFAULT_ITEMS_PER_PAGE = 10000
const DEFAULT_LOADING_DURATION = 100
const DEFAULT_MAX_SUB_ROW_DEPTH = 10

export const useFrontendFiltering = <T extends {}>(props: FrontendFilteringProps<T>): FrontendFilteringAPI<T> => {
  const {
    data: allData,
    searchText,
    activeFilter,
    activeSubFilters,
    activeFolder,
    filterFunction,
    itemsPerPage = DEFAULT_ITEMS_PER_PAGE,
    sortingBy,
    loading,
    searchAllData: searchAll = false,
    maxSubRowDepth = DEFAULT_MAX_SUB_ROW_DEPTH,
    loadingDuration = DEFAULT_LOADING_DURATION,
    excludeGroups,
  } = props
  const searchAllData = searchAll && searchText

  const [state, setState] = useState<FrontendFilteringState<T>>({
    data: allData,
    flatData: flattenSubRows(allData, 0, maxSubRowDepth),
    allItemsLoaded: false,
    filterLoading: true,
    currentPage: 0,
    filterCounts: {},
  })

  const { currentPage } = state

  const update: UpdateState<FrontendFilteringState<T>> = (fields) => {
    setState((state) => ({ ...state, ...fields }))
  }

  const prevFilters = useRef<Partial<FrontendFilteringProps<T>>>({ activeFilter, activeSubFilters, activeFolder, searchText, sortingBy })

  useEffect(() => {
    if (loading) {
      return
    }

    const filterCounts: FilterCounts = Object.entries(filterFunction).reduce((prev, cur) => {
      const [filterName, filterFunction] = cur
      if (['folder', 'search'].includes(filterName) || !filterFunction) {
        return { ...prev }
      }
      return { ...prev, [filterName]: allData.filter((item) => filterFunction(item)).length }
    }, {})
    update({ filterCounts })
  }, [allData, loading])

  const shouldResetPage = () => {
    const filters: (keyof FrontendFilteringProps<T>)[] = ['activeFilter', 'activeFolder', 'searchText', 'sortingBy', 'activeSubFilters']
    const isFilterChanged = filters.some((filter) => !equal(props[filter], prevFilters.current[filter]))

    if (isFilterChanged) {
      update({ filterLoading: true, data: [] })
      if (currentPage !== 0) {
        setTimeout(() => update({ currentPage: 0 }), loadingDuration)
      } else {
        setTimeout(() => refreshItems(), loadingDuration)
      }
    }
    return isFilterChanged
  }

  const onLoadNextPage = useCallback(() => {
    update({ currentPage: currentPage + 1 })
  }, [currentPage])

  const resetCurrentPage = useCallback(() => {
    update({ currentPage: 0, filterLoading: true })
  }, [])

  const refreshItems = () => {
    let resultData = searchText ? [...state.flatData] : [...allData]

    if (excludeGroups) {
      resultData = resultData.filter((item) => (item as { group?: string }).group !== excludeGroups)
    }

    if (!searchAllData) {
      if (filterFunction.folder) {
        resultData = resultData.filter((item) => filterFunction.folder?.(item, activeFolder))
      }

      if (activeFilter && filterFunction?.[activeFilter]) {
        resultData = resultData.filter((item) => filterFunction[activeFilter]?.(item))
      }

      activeSubFilters?.forEach((filter) => {
        if (filterFunction[filter]) {
          resultData = resultData.filter((item) => filterFunction[filter]?.(item))
        }
      })
    }

    if (filterFunction.search) {
      resultData = resultData.filter((item) => filterFunction.search?.(item, searchText))
    }

    sortingBy?.forEach((sortCriteria) =>
      resultData.sort((a, b) => {
        const valA = String(a[sortCriteria.id]).toLowerCase()
        const valB = String(b[sortCriteria.id]).toLowerCase()
        const sortVal = valA > valB ? 1 : -1
        return sortCriteria.desc ? sortVal * -1 : sortVal
      })
    )

    if (!searchText) {
      resultData = resultData.slice(0, (currentPage + 1) * itemsPerPage)
    }

    const allItemsLoaded = searchText ? true : (currentPage + 1) * itemsPerPage >= resultData.length
    update({ data: resultData, flatData: flattenSubRows(allData, 0, maxSubRowDepth), allItemsLoaded, filterLoading: false })

    prevFilters.current = { activeFilter, activeSubFilters, activeFolder, searchText, sortingBy }
  }

  useEffect(() => {
    if (loading || shouldResetPage()) {
      return
    }
    refreshItems()
  }, [
    allData,
    loading,
    sortingBy,
    searchText,
    activeFilter,
    activeFolder,
    activeSubFilters,
    filterFunction,
    currentPage,
    itemsPerPage,
    searchAllData,
  ])

  return { ...state, onLoadNextPage, resetCurrentPage }
}
