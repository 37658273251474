import { Row } from 'react-table'

import { ListPickerModalState, PreProcessedList } from '@complex/ListPickerModalV2/utils/interfaces/ListPickerModalInterfaces'
import { matchSearchTerm } from '@complex/ListPickerModalV2/utils/ListPickerModalUtils'

const hasSelectedSubRows = (rows: Row[], selectedRowsIds: string[]): boolean => {
  const expand = rows.some(({ original }) => selectedRowsIds.includes((original as any).id))
  if (!expand) {
    const subRows = rows.flatMap(({ subRows }) => subRows)
    return subRows.length ? hasSelectedSubRows(subRows, selectedRowsIds) : false
  }
  return true
}

const checkIfSubRowsContainsSearchTerm = (subRows: Row[], searchQuery: string): boolean =>
  subRows.some(
    (subRow) =>
      matchSearchTerm((subRow.original as PreProcessedList).name, searchQuery) ||
      (!!subRow.subRows.length && checkIfSubRowsContainsSearchTerm(subRow.subRows, searchQuery))
  )

export const getHasToExpandFunction =
  ({ hasToExpandRows, listType, selectedLists, search }: ListPickerModalState) =>
  (row: Row) => {
    const subRowsAreSelected =
      !!row?.subRows.length &&
      hasSelectedSubRows(
        row.subRows,
        selectedLists[listType].map(({ original }) => original.id)
      )
    const subRowsContainsSearchTerm = !!search && checkIfSubRowsContainsSearchTerm(row.subRows, search?.query ?? '')
    const expand = subRowsAreSelected || subRowsContainsSearchTerm || row.isExpanded
    return (
      (hasToExpandRows[listType] && hasToExpandRows[listType]?.some(({ original }) => (original as any).id === (row.original as any).id)) || expand
    )
  }

const getRow = (id: string, rows: Row[]): Row => rows.find(({ original }) => (original as any).id === id) as Row

const getParentsTree = (rows: Row[], parents: Row[]): Row[] => {
  const hasToExpand = parents
    .reduce((curr: Row[], parent: Row) => [...curr, getRow((parent.original as any).parent, rows)], [])
    .filter((parent) => parent)
  const parentsLeft = hasToExpand.filter(({ original }) => (original as any).parent)
  return parentsLeft.length ? [...hasToExpand, ...getParentsTree(rows, parentsLeft)] : hasToExpand
}

export const getHasToExpandListsUpdated = (updatedLists: Row[], rows: Row[]) => {
  const hasParents = updatedLists.filter(({ original }) => (original as any).parent)
  return getParentsTree(rows, hasParents)
}

export const buildToRemoveFromHasToExpandRowsArray = (parents: string[], rows: Row[]): Row[] => {
  const parentsRows = parents.reduce((parents: Row[], parent) => {
    const row = getRow(parent, rows)
    return row ? [...parents, row] : parents
  }, [])
  const parentsLeft = parentsRows.map(({ original }) => (original as any).parent).filter((parent) => parent)
  return parentsLeft.length ? [...parentsRows, ...buildToRemoveFromHasToExpandRowsArray(parentsLeft, rows)] : parentsRows
}
