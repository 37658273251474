import React, { FC } from 'react'

import classNames from 'classnames'

import BulkActionsModal from '@components/BulkActionsModal/BulkActionsModal'
import Loader from '@components/Loader'
import Modal, { ModalBody } from '@components/Modal'
import PageContainer from '@components/PageContainer/PageContainer'
import PageHeader from '@components/PageHeader'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import Search from '@components/Search/Search'
import StatusToast, { Status } from '@components/StatusToast/StatusToast'
import { TableV2 } from '@components/TableV2/TableV2'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import InboxImportDeclineModal from '@src/pages/MarketingNetwork/Inbox/components/InboxImportDeclineModal/InboxImportDeclineModal'
import PreviewAndImportModalContainer from '@src/pages/MarketingNetwork/Inbox/components/PreviewAndImportModal/PreviewAndImportModalContainer'
import {
  defaultSortingBy,
  getEmptyListingProps,
  ImportDeclineOptions,
  InboxAsset,
  InboxModals,
  InboxProps,
  NoPreviewTypes,
} from '@src/pages/MarketingNetwork/Inbox/Inbox.constants'
import { headerRowActions, inboxColumns, inboxRowActions } from '@src/pages/MarketingNetwork/Inbox/Inbox.tables'
import { Row } from '@tanstack/react-table'
import { AssetTypes } from '@utils/actonAssetTypes'

import './Inbox.css'

const rootClass = 'inbox'

const Inbox: FC<InboxProps> = (props: InboxProps) => {
  const {
    loading,
    assets,
    selectedRows,
    search = '',
    onSearch,
    onShowModal,
    successToast,
    closeSuccessToast,
    onImport,
    onDecline,
    showImport = false,
    showDecline = false,
    actionErrors,
    onCloseErrorModal,
    onRowSelectionChanged,
    dataTest = rootClass,
    className = '',
    getPreviewItem,
    loadingPreview,
  } = props
  const { t } = useTranslation()

  const onImportPreview = (row?: Row<InboxAsset>) => onShowModal(InboxModals.IMPORT, row)

  const onDeclinePreview = (row?: Row<InboxAsset>) => onShowModal(InboxModals.DECLINE, row)

  const renderDecline = () =>
    selectedRows.length === 1 && !NoPreviewTypes.includes(selectedRows[0].type as AssetTypes) ? (
      <PreviewAndImportModalContainer
        {...selectedRows[0]}
        isOpen={showDecline ?? false}
        showAction
        actionType={ImportDeclineOptions.DECLINE}
        onClose={() => onShowModal(InboxModals.DECLINE)}
        onAction={onDecline}
      />
    ) : (
      <InboxImportDeclineModal
        getPreviewItem={getPreviewItem}
        isOpen={showDecline}
        onClose={() => onShowModal(InboxModals.DECLINE)}
        onAction={onDecline}
        assets={selectedRows}
        actionType={ImportDeclineOptions.DECLINE}
      />
    )

  const renderImport = () =>
    selectedRows.length === 1 && !NoPreviewTypes.includes(selectedRows[0].type as AssetTypes) ? (
      <PreviewAndImportModalContainer
        {...selectedRows[0]}
        isOpen={showImport ?? false}
        showAction
        actionType={ImportDeclineOptions.IMPORT}
        onClose={() => onShowModal(InboxModals.IMPORT)}
        onAction={onImport}
      />
    ) : (
      <InboxImportDeclineModal
        getPreviewItem={getPreviewItem}
        isOpen={showImport}
        onClose={() => onShowModal(InboxModals.IMPORT)}
        onAction={onImport}
        assets={selectedRows}
        actionType={ImportDeclineOptions.IMPORT}
      />
    )

  const renderSuccessToast = () => (
    <StatusToast title={t('Success!')} message={successToast} status={Status.SUCCESS} closeStatus={closeSuccessToast} />
  )

  const renderErrorModal = () => {
    const title = t('Inbox.Errors.Title', { type: actionErrors?.actionType.toLocaleLowerCase() })
    const warning = actionErrors?.actionType === ImportDeclineOptions.IMPORT ? 'Inbox.Errors.Caution.Importing' : 'Inbox.Errors.Caution.Declining'
    const errorWarning = (
      <Typography
        text={t(warning)}
        tagComponents={{
          TextLink: <TextLink link={'https://connect.act-on.com/hc/en-us'} size={TextLinkSize.LARGE} />,
        }}
      />
    )

    const verb = actionErrors?.actionType === ImportDeclineOptions.IMPORT ? 'imported' : 'declined'
    const successTitle = t('Inbox.Errors.Success', {
      count: actionErrors?.successes.length,
      verb,
    })

    const errorTitle = t('Inbox.Errors.Errors', {
      count: actionErrors?.errors.length,
      verb,
    })

    return (
      <BulkActionsModal
        isOpen={!!actionErrors}
        errorMessages={actionErrors?.errors ?? []}
        successMessages={actionErrors?.successes ?? []}
        title={title}
        warnings={errorWarning}
        onClose={onCloseErrorModal}
        successTitle={successTitle}
        errorTitle={errorTitle}
        className={rootClass}
        dataTest={dataTest}
      />
    )
  }

  const renderPreviewLoader = () => (
    <Modal isOpen={true}>
      <ModalBody>
        <Loader />
      </ModalBody>
    </Modal>
  )

  return (
    <PageContainer className={classNames(rootClass, className)} dataTest={dataTest}>
      {loadingPreview && renderPreviewLoader()}
      {successToast !== '' && renderSuccessToast()}
      {actionErrors && renderErrorModal()}
      <PositionContainer>
        {showDecline && renderDecline()}
        {showImport && renderImport()}
        <PageHeader primaryText={t('Inbox')} leftContent />
        <Typography
          text={t('Inbox.SubHeader')}
          type={TextType.BODY_TEXT_LIGHT}
          className={`${rootClass}__sub-header-container`}
          dataTest={'Inbox-SubHeader'}
        />
        <div className={`${rootClass}__table-helpers`}>
          {(search || !!assets.length) && (
            <>
              <div className={`${rootClass}__table-helpers-filters`}></div>
              <Search
                incomingValue={search}
                placeholder={t('Search inbox')}
                canClear
                onChangeHandler={onSearch}
                className={`${rootClass}__table-helpers-search`}
              />
            </>
          )}
        </div>
        <TableV2
          data={assets}
          columns={inboxColumns}
          enableCheckbox
          enableSorting
          enableOuterLoader
          sortingBy={defaultSortingBy}
          emptyState={getEmptyListingProps(t, search, () => onSearch(''))}
          rowActions={inboxRowActions({ onImportPreview, onDeclinePreview })}
          headerActions={headerRowActions(onShowModal)}
          onRowSelectionChanged={onRowSelectionChanged}
          loading={loading}
          rowUniqueIdKey="id"
        />
      </PositionContainer>
    </PageContainer>
  )
}

export default Inbox
