import { gql } from '@apollo/client'

export default gql`
  query getSummaryByMessageId($accountId: Long!, $messageId: Long!) {
    getSummaryByMessageId(accountId: $accountId, messageId: $messageId) {
      smsAveRate {
        aveClickRate
        aveDeliveryRate
        aveOptOutRate
        aveReplyRate
      }
      smsFailedDetails {
        failedReasonToCount
        failedTotal
      }
      smsTotalCount {
        clicked
        replied
        sent
      }
      smsUniqueCount {
        clicked
        delivered
        failed
        optedOut
        replied
        sent
      }
    }
  }
`
