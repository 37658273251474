import crmDefinition from '@graphql/microservices/crm/crmDefinition'
import setHadSuccessfulSync from '@graphql/microservices/crm/setHadSuccessfulSync'
import syncedSegmentDetails from '@graphql/microservices/crm/syncedSegmentDetails'
import syncNow from '@graphql/microservices/crm/syncNow'
import listExportSyncJobBySegmentId from '@graphql/microservices/entity-upload/listExportSyncJobBySegmentId'
import listImportSyncJobBySegmentId from '@graphql/microservices/entity-upload/listImportSyncJobBySegmentId'
import getSegmentFilter from '@graphql/microservices/segment/getSegmentFilter'
import getSegmentMetadata from '@graphql/microservices/segment/getSegmentMetadata'
import {
  CrmDefinitionQuery,
  CrmDefinitionQueryVariables,
  SetHadSuccessfulSyncMutation,
  SetHadSuccessfulSyncMutationVariables,
  SyncedSegmentDetailsQuery,
  SyncedSegmentDetailsQueryVariables,
  SyncNowQuery,
  SyncNowQueryVariables,
} from '@graphql/types/microservice/crm-types'
import {
  ExportSyncJobDto,
  ImportSyncJobDto,
  ListExportSyncJobBySegmentIdQuery,
  ListExportSyncJobBySegmentIdQueryVariables,
  ListImportSyncJobBySegmentIdQuery,
  ListImportSyncJobBySegmentIdQueryVariables,
} from '@graphql/types/microservice/entity-upload-types'
import {
  GetSegmentFilterQuery,
  GetSegmentFilterQueryVariables,
  GetSegmentMetadataQuery,
  GetSegmentMetadataQueryVariables,
} from '@graphql/types/microservice/segment-types'
import { filterNotEmptyArray } from '@utils/array'
import { SegmentDefinition } from '@utils/contactSegments/contactSegments.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

type GetCRMNotSupportedEntitiesRequestParams = { connectedCRM: string }

type SyncNowRequestType = () => FetchPromise<SyncNowQuery>
type SyncedSegmentDetailsRequestParams = { segmentIdentifiers: string[] }
type SyncedSegmentDetailsRequestType = (params: SyncedSegmentDetailsRequestParams) => FetchPromise<SyncedSegmentDetailsQuery>
type SegmentDefinitionRequestType = (segmentExternalId: string) => Promise<SegmentDefinition>
type SetSuccessfulSyncFlagRequestType = (params: SetHadSuccessfulSyncMutationVariables) => FetchPromise<SetHadSuccessfulSyncMutation>
type GetCRMNotSupportedEntitiesRequestType = (params: GetCRMNotSupportedEntitiesRequestParams) => FetchPromise<CrmDefinitionQuery>

export interface SegmentInfoHoverCardRequests {
  syncedSegmentDetailsRequest: SyncedSegmentDetailsRequestType
  segmentDefinitionRequest: SegmentDefinitionRequestType
  setSuccessfulSyncFlagRequest: SetSuccessfulSyncFlagRequestType
  syncNowRequest: SyncNowRequestType
  getSegmentsMetadata: () => Promise<any>
  getCRMNotSupportedEntitiesRequest: GetCRMNotSupportedEntitiesRequestType
  getImportSyncJobListRequest: (segmentId?: string) => Promise<ImportSyncJobDto[]>
  getExportSyncJobListRequest: (segmentId?: string) => Promise<ExportSyncJobDto[]>
}

export const useSegmentInfoHoverCardRequests = (): SegmentInfoHoverCardRequests => {
  const { client: crmClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CRM })
  const { client: segmentClient } = useMicroserviceClient({ serviceName: MicroserviceClients.SEGMENT })
  const { client: entityUploadClient } = useMicroserviceClient({ serviceName: MicroserviceClients.ENTITY_UPLOAD })

  const setSuccessfulSyncFlagRequest = async (variables: SetHadSuccessfulSyncMutationVariables) =>
    await crmClient.mutate<SetHadSuccessfulSyncMutation, SetHadSuccessfulSyncMutationVariables>({
      mutation: setHadSuccessfulSync,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables,
    })

  const syncNowRequest = async () =>
    await crmClient.query<SyncNowQuery, SyncNowQueryVariables>({
      query: syncNow,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    })

  const syncedSegmentDetailsRequest = async ({ segmentIdentifiers }: SyncedSegmentDetailsRequestParams) =>
    await crmClient.query<SyncedSegmentDetailsQuery, SyncedSegmentDetailsQueryVariables>({
      query: syncedSegmentDetails,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables: { segmentIdentifiers },
    })

  const segmentDefinitionRequest = (segmentExternalId: string) =>
    segmentClient
      .query<GetSegmentFilterQuery, GetSegmentFilterQueryVariables>({
        query: getSegmentFilter,
        variables: {
          segmentId: segmentExternalId,
        },
      })
      .then(({ data }) => JSON.parse(data.getSegmentFilter?.body) as SegmentDefinition)

  const getSegmentsMetadata = () =>
    segmentClient
      .query<GetSegmentMetadataQuery, GetSegmentMetadataQueryVariables>({
        query: getSegmentMetadata,
        variables: {
          segmentId: 'l-unified-contacts',
        },
      })
      .then(({ data }) => JSON.parse(data.getSegmentMetadata?.body))

  const getCRMNotSupportedEntitiesRequest = async ({ connectedCRM }: GetCRMNotSupportedEntitiesRequestParams) =>
    await crmClient.query<CrmDefinitionQuery, CrmDefinitionQueryVariables>({
      query: crmDefinition,
      fetchPolicy: 'network-only',
      variables: {
        connectedCrmName: connectedCRM,
      },
    })

  const getImportSyncJobListRequest = (segmentId?: string) =>
    entityUploadClient
      .query<ListImportSyncJobBySegmentIdQuery, ListImportSyncJobBySegmentIdQueryVariables>({
        query: listImportSyncJobBySegmentId,
        variables: {
          segmentId,
        },
      })
      .then(({ data }) => data.listImportSyncJobBySegmentId as ImportSyncJobDto[])

  const getExportSyncJobListRequest = (segmentId?: string) =>
    entityUploadClient
      .query<ListExportSyncJobBySegmentIdQuery, ListExportSyncJobBySegmentIdQueryVariables>({
        query: listExportSyncJobBySegmentId,
        variables: {
          segmentId,
        },
      })
      .then(({ data }) => data.listExportSyncJobBySegmentId?.filter(filterNotEmptyArray) ?? [])

  return {
    setSuccessfulSyncFlagRequest,
    syncedSegmentDetailsRequest,
    syncNowRequest,
    segmentDefinitionRequest,
    getSegmentsMetadata,
    getCRMNotSupportedEntitiesRequest,
    getImportSyncJobListRequest,
    getExportSyncJobListRequest,
  }
}
