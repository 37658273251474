import React, { FC, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Link } from 'react-router-dom'

import classNames from 'classnames'

import DropDown, { DropdownMenuItem, DropdownMenuLabel, DropDownRadioItem } from '@components/DropDown'
import MessageBox from '@components/MessageBox'
import Modal, { ModalBody } from '@components/Modal'
import Svg from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import { SetLanguageAction } from '@utils/account/actions'
import { AccountState } from '@utils/account/reducer'
import useUserSettings from '@utils/hooks/useUserSettings'
import iframe from '@utils/iframe/iframe'
import { clearSession } from '@utils/sessionStorage'
import { THEME } from '@utils/theme'

import languages from './languages'
import AccountDetailsModal from '../AccountDetailsModal/AccountDetailsModal'

import './profileDropDown.css'

const rootClass = 'profile-drop-down'

interface ProfileDropDownProps {
  account?: AccountState
  setLanguage: (language: string) => SetLanguageAction
  setTheme: (theme: THEME) => void
  logOut: () => void

  setPendingIFrameRequest(iframeMessage?: undefined): void
}

export interface State {
  showAccountDetails: boolean
  showSignOut: boolean
  showDropDown: boolean
  dropDownWidth?: number
  languageIsOpen: boolean
  userName: string
}

const defaultState: State = {
  showAccountDetails: false,
  showSignOut: false,
  showDropDown: false,
  dropDownWidth: 200,
  languageIsOpen: false,
  userName: '',
}

export const ProfileDropDown: FC<ProfileDropDownProps> = (props: ProfileDropDownProps) => {
  const { account, setLanguage, setTheme, logOut, setPendingIFrameRequest } = props
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { accountSettings, theme } = account || {}
  const dropDownRef = useRef<HTMLDivElement>(null)
  const { userSettings, setUserSetting } = useUserSettings()

  const [state, setState] = useState<State>(defaultState)
  const history = useHistory()

  const clearCookies = () => {
    const cookies = document.cookie.split(';')
    cookies.forEach((cookie) => {
      document.cookie = cookie + '=; expires=' + new Date(0).toUTCString()
    })
  }

  useEffect(() => {
    if (accountSettings) {
      const userName = accountSettings.userName === '' ? accountSettings.userEmail : accountSettings?.userName
      if (dropDownRef.current?.clientWidth) {
        setState({
          ...state,
          dropDownWidth: dropDownRef.current.clientWidth,
          userName,
        })
      } else {
        setState({
          ...state,
          userName,
        })
      }
    }
  }, [accountSettings])

  useEffect(() => {
    if (userSettings) {
      setLanguage(userSettings['i18nextLng'])
      setTheme(userSettings['ui-theme'] as THEME)
    }
  }, [userSettings])

  if (!accountSettings) return null

  const getAccountName = () => {
    const name = accountSettings?.isClientAccount ? accountSettings.accountName : state.userName
    return <TextWithTooltipOnEllip typographyProps={{ text: name }} />
  }

  const getThemeSwitcher = () => {
    return (
      <div className={`${rootClass}__theme`}>
        <DropdownMenuLabel>
          <Typography text={`${t('Theme')}`} type={TextType.BODY_TEXT_GRAY} />
        </DropdownMenuLabel>
        <DropdownMenuItem
          data-test="profile-drop-down-light-theme"
          className={classNames(`${rootClass}__theme-button`, `${rootClass}__menu-item`, `${rootClass}__theme-button--light`, [
            {
              [`${rootClass}__theme-button--current`]: theme === THEME.LIGHT,
            },
          ])}
          onSelect={() => {
            setTheme(THEME.LIGHT)
            setUserSetting('ui-theme', THEME.LIGHT)
          }}
        >
          <DropdownMenuLabel />
        </DropdownMenuItem>
        <DropdownMenuItem
          data-test="profile-drop-down-dark-theme"
          className={classNames(`${rootClass}__theme-button`, `${rootClass}__menu-item`, `${rootClass}__theme-button--dark`, [
            {
              [`${rootClass}__theme-button--current`]: theme === THEME.DARK,
            },
          ])}
          onSelect={() => {
            setTheme(THEME.DARK)
            setUserSetting('ui-theme', THEME.DARK)
          }}
        >
          <DropdownMenuLabel />
        </DropdownMenuItem>
        {accountSettings?.hasCustomSkin && (
          <DropdownMenuItem
            data-test="profile-drop-down-custom-theme"
            className={classNames(`${rootClass}__theme-button`, `${rootClass}__theme-button--custom`, [
              {
                [`${rootClass}__theme-button--current`]: theme === THEME.CUSTOM,
              },
            ])}
            onSelect={() => {
              setTheme(THEME.CUSTOM)
              setUserSetting('ui-theme', THEME.CUSTOM)
            }}
          >
            <DropdownMenuLabel style={{ backgroundColor: accountSettings.customSkinParams?.navBackgroundColor ?? undefined }} />
          </DropdownMenuItem>
        )}
      </div>
    )
  }

  const toggleOpen = (showDropDown: boolean) => {
    const languageIsOpen = !showDropDown ? false : state.languageIsOpen
    setState({ ...state, showDropDown, languageIsOpen })
  }

  const toggleLanguage = (open: boolean) => {
    setState({
      ...state,
      languageIsOpen: open,
    })
  }

  const renderProfileItem = () =>
    pathname.includes('profile') ? (
      <DropdownMenuItem className={`${rootClass}__menu-item`} disabled>
        <DropdownMenuLabel className={`${rootClass}__label`}>
          <Typography dataTest="profile-drop-down-profile-link" text={t('Profile')} type={TextType.BODY_TEXT_GRAY} />
        </DropdownMenuLabel>
      </DropdownMenuItem>
    ) : (
      <DropdownMenuItem className={classNames(`${rootClass}__menu-item`, `${rootClass}__menu-link`)}>
        <Link to={`${rootContext}/profile`} onClick={() => toggleOpen(false)}>
          <DropdownMenuLabel className={`${rootClass}__label`}>
            <Typography
              dataTest="profile-drop-down-profile-link"
              className={`${rootClass}__label-link`}
              text={t('Profile')}
              type={TextType.BODY_TEXT_GRAY}
            />
          </DropdownMenuLabel>
        </Link>
      </DropdownMenuItem>
    )

  const openAccountDetailsModal = () => {
    window.setTimeout(
      () =>
        setState({
          ...state,
          showAccountDetails: true,
          showDropDown: false,
        }),
      0
    )
  }

  const closeAccountDetails = () => {
    setState({
      ...state,
      showAccountDetails: false,
    })
  }

  const currentLanguage = languages.find((lang) => lang.langCode === account?.language)

  return (
    <>
      <Modal isOpen={state.showSignOut} allowFullScreen noPadding noScroll>
        <ModalBody>
          <MessageBox>
            <div data-test="profile-drop-down-sign-out-message" className={`${rootClass}__sign-out`}>
              <div className={`${rootClass}__sign-out-inside`}>
                <span className={`${rootClass}__sign-out-title`}>
                  <Svg name={SvgNames.logOutArrow} /> {t('Sign out')}?
                </span>
                <div className={`${rootClass}__sign-out-message`}>
                  {t('Great! You just made marketing magic! Come back to check on your results.')}
                </div>
                <div className={`${rootClass}__sign-out-buttons`}>
                  <button
                    tabIndex={0}
                    data-test="profile-drop-down-yes-button"
                    onClick={() => {
                      setState({
                        ...state,
                        showSignOut: false,
                      })
                      clearSession()
                      clearCookies()
                      logOut()
                    }}
                  >
                    <label>{t('Yes')}</label>
                  </button>
                  <button
                    tabIndex={0}
                    data-test="profile-drop-down-no-button"
                    onClick={() => {
                      setState({
                        ...state,
                        showSignOut: false,
                      })
                    }}
                  >
                    <label>{t('No')}</label>
                  </button>
                </div>
              </div>
            </div>
          </MessageBox>
        </ModalBody>
      </Modal>
      {state.showAccountDetails && (
        <AccountDetailsModal isOpen={state.showAccountDetails} closeModal={closeAccountDetails} accountSettings={accountSettings} />
      )}
      <DropDown
        dataTest="profile-drop-down"
        reference={dropDownRef}
        label={getAccountName()}
        className={`${rootClass}__menu`}
        isOpen={state.showDropDown}
        toggleOpen={toggleOpen}
        modal={false}
      >
        <DropdownMenuLabel className={`${rootClass}__label`}>
          <Typography dataTest={`${rootClass}-user-name`} text={state.userName} type={TextType.BODY_TEXT_GRAY} weight={TextWeight.BOLD} />
        </DropdownMenuLabel>
        <DropdownMenuLabel className={`${rootClass}__label`}>
          <Typography dataTest={`${rootClass}-account-name`} text={account?.accountSettings?.accountName} type={TextType.BODY_TEXT_GRAY} />
        </DropdownMenuLabel>
        <DropDown
          isOpen={state.languageIsOpen}
          toggleOpen={toggleLanguage}
          label={currentLanguage?.translation ?? 'English'}
          modal={false}
          className={`${rootClass}__languages-item`}
        >
          {languages.map((language) => (
            <DropDownRadioItem
              value={language.langCode}
              key={language.langCode}
              className={`${rootClass}__language`}
              data-test={`profile-drop-down-language-select-${language.langCode}`}
              onSelect={() => {
                setLanguage(language.langCode)
                setUserSetting('i18nextLng', language.langCode)
                toggleOpen(false)
              }}
            >
              {account?.language !== language.langCode && <span className={`${rootClass}__language-spacer`} />}
              <Typography
                text={language.translation}
                type={TextType.BODY_TEXT_GRAY}
                inline
                dataTest={`profile-drop-down-language-select-${language.langCode}-text`}
              />
            </DropDownRadioItem>
          ))}
        </DropDown>
        {accountSettings?.userSettings?.userDesktop !== 'cust/top.jsp' && renderProfileItem()}
        {accountSettings.uiThemesSwitcher && getThemeSwitcher()}
        {accountSettings.accessNextGenUI && accountSettings.nextGenContactLists !== null && (
          <DropdownMenuItem
            className={`${rootClass}__menu-item`}
            data-test="profile-drop-down-switch-amp-link"
            onSelect={() => {
              iframe.postMessage(
                {
                  actonGoAmp: accountSettings.nextGenContactLists,
                },
                setPendingIFrameRequest
              )
            }}
          >
            <DropdownMenuLabel className={classNames(`${rootClass}__label`, `${rootClass}__label-link`)}>
              <Typography text={t('Switch To AMP')} type={TextType.BODY_TEXT_GRAY} />
            </DropdownMenuLabel>
          </DropdownMenuItem>
        )}
        {accountSettings.isAgencyAccount && !accountSettings.userSettings?.userDesktop && !accountSettings.enableMarketingNetworkNav && (
          <DropdownMenuItem
            className={`${rootClass}__menu-item`}
            data-test="profile-drop-down-marketing-link"
            onSelect={() => {
              iframe.postMessage(
                {
                  actonGoAgencyDashboard: true,
                },
                setPendingIFrameRequest
              )
            }}
          >
            <DropdownMenuLabel className={classNames(`${rootClass}__label`, `${rootClass}__label-link`)}>
              <Typography text={t('Marketing Network')} type={TextType.BODY_TEXT_GRAY} />
            </DropdownMenuLabel>
          </DropdownMenuItem>
        )}
        {accountSettings.isClientAccount && !accountSettings.enableMarketingNetworkNav && (
          <DropdownMenuItem
            className={`${rootClass}__menu-item`}
            data-test="profile-drop-down-parent-marketing-link"
            onSelect={() => {
              history.push(`${rootContext}/backToParent`)
            }}
          >
            <DropdownMenuLabel className={classNames(`${rootClass}__label`, `${rootClass}__label-link`)}>
              <Typography text={t('Parent Marketing Network')} type={TextType.BODY_TEXT_GRAY} />{' '}
            </DropdownMenuLabel>
          </DropdownMenuItem>
        )}
        {accountSettings.isAgencyAccount && accountSettings.agencyRefresh && (
          <DropdownMenuItem className={`${rootClass}__menu-item`}>
            <Link to={`${rootContext}/classic/agency`}>
              <DropdownMenuLabel className={classNames(`${rootClass}__label`, `${rootClass}__label-link`)} data-test="profile-drop-down-agency-link">
                <Typography text={t('Agency')} type={TextType.BODY_TEXT_GRAY} />
                <sup> {t('ALPHA')}</sup>
              </DropdownMenuLabel>
            </Link>
          </DropdownMenuItem>
        )}
        {accountSettings.isClientAccount && accountSettings.agencyRefresh && (
          <DropdownMenuItem
            className={`${rootClass}__menu-item`}
            data-test="profile-drop-down-agency-button"
            onSelect={() => {
              iframe.postMessage(
                {
                  actonGoAgencyDashboardBeta: true,
                },
                setPendingIFrameRequest
              )
            }}
          >
            <DropdownMenuLabel className={classNames(`${rootClass}__label`, `${rootClass}__label-link`)}>
              <Typography text={t('Agency')} type={TextType.BODY_TEXT_GRAY} /> <sup> {t('ALPHA')}</sup>
            </DropdownMenuLabel>
          </DropdownMenuItem>
        )}
        <DropdownMenuItem className={`${rootClass}__menu-item`} data-test="profile-drop-down-account-details" onSelect={openAccountDetailsModal}>
          <DropdownMenuLabel className={classNames(`${rootClass}__label`, `${rootClass}__label-link`)}>
            <Typography text={t('Account Details')} type={TextType.BODY_TEXT_GRAY} />
          </DropdownMenuLabel>
        </DropdownMenuItem>
        <DropdownMenuItem
          className={`${rootClass}__sign-out-button`}
          data-test="profile-drop-down-sign-out-button"
          onSelect={() => {
            window.setTimeout(() => {
              setState({
                ...state,
                showSignOut: true,
                showDropDown: false,
              })
            }, 0)
          }}
        >
          <DropdownMenuLabel>
            <Typography text={t('Sign Out')} type={TextType.BODY_TEXT_GRAY} />
          </DropdownMenuLabel>
        </DropdownMenuItem>
      </DropDown>
    </>
  )
}

export default ProfileDropDown
