import { useState, useEffect, useRef, useCallback } from 'react'

import { TOP_BAR_HEIGHT } from '@const/globals'
const useSticky = (defaultSticky = false) => {
  const [isSticky, setIsSticky] = useState(defaultSticky)
  const scrollingAreaRef = useRef<HTMLDivElement>(null)
  const toggleSticky = useCallback(
    (top?: number) => {
      if (top && top > 2 * TOP_BAR_HEIGHT) {
        !isSticky && setIsSticky(true)
      } else {
        isSticky && setIsSticky(false)
      }
    },
    [isSticky]
  )

  useEffect(() => {
    const handleScroll = () => {
      toggleSticky(scrollingAreaRef.current?.scrollTop)
    }
    scrollingAreaRef?.current?.addEventListener('scroll', handleScroll)
    return () => {
      scrollingAreaRef?.current?.removeEventListener('scroll', handleScroll)
    }
  }, [toggleSticky, scrollingAreaRef.current])

  return { scrollingAreaRef, isSticky }
}
export default useSticky
