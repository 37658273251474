import { gql } from '@apollo/client'

export default gql`
  query getSegmentFilter($segmentId: String) {
    getSegmentFilter(segmentId: $segmentId) {
      body
      message
      objectId
      statusCode
    }
  }
`
