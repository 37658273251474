import { gql } from '@apollo/client'

export default gql`
  query campaigns {
    campaigns {
      id
      name
    }
  }
`
