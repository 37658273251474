import React, { FC } from 'react'

import TagManagerTrigger, { TagManagerTriggerProps } from '@components/TagManagerTrigger/TagManagerTrigger'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'

type TagManagerTriggerWithNumberProps = TagManagerTriggerProps & {
  number: number
}

const TagManagerTriggerWithNumber: FC<TagManagerTriggerWithNumberProps> = (props: TagManagerTriggerWithNumberProps) => {
  const { number, ...tagManagerTriggerProps } = props

  return (
    <TagManagerTrigger {...tagManagerTriggerProps}>
      <Typography lineHeight={LineHeight.MEDIUM_SMALL} text={`+${number}`} type={TextType.BODY_TEXT_LIGHT_TINY} weight={TextWeight.MEDIUM} />
    </TagManagerTrigger>
  )
}

export default TagManagerTriggerWithNumber
