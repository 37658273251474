import React, { FC, MouseEvent, ReactElement, ReactNode, RefObject, UIEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Row,
  SortByFn,
  TableState,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useExpanded,
  Column,
} from 'react-table'
import { useSticky } from 'react-table-sticky'
import { FixedSizeList } from 'react-window'

import classNames from 'classnames'

import { ButtonType } from '@components/Button'
import CaretIcon, { CaretIconDirection } from '@components/CaretIcon'
import { Checkbox } from '@components/Checkbox/Checkbox'
import { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import TableActionHeader from '@components/Table/components/TableActionHeader'
import { getColumnAlignClass, renderColumnHeader } from '@components/Table/components/tableColumns'
import TableRowAction from '@components/Table/components/TableRowAction/TableRowAction'
import {
  getCheckboxColumnUtils,
  getStickyColumnUtils,
  renderCheckboxUtils,
  showHeaderActionsUtils,
  toggleSelectedRowsUtils,
} from '@components/Table/utils/TableUtils'
import Typography, { TextType } from '@components/Typography/Typography'
import { Align } from '@radix-ui/react-popper'
import { SortingFieldType } from '@utils/types'

import GlobalFilter from './components/GlobalFilter'
import Paginator from './components/Paginator'
import scrollbarWidth from './utils/scrollbarWidth'

import './Table.css'

export type TableColumnAlign = 'left' | 'center' | 'right'

export interface TableColumn {
  Header: string | FC | ReactElement
  accessor: string
  align: TableColumnAlign
  isSortedDesc?: boolean
  isSorted?: boolean
  sortType?: string | SortByFn<object>
  sticky?: string
  flexColumn?: boolean
  Cell?: (col: any) => ReactNode
  fieldType?: SortingFieldType
  [key: string]: string | number | boolean | ((col: any) => ReactNode) | SortByFn<object> | undefined | object
}

interface Action {
  disabled?: ((row?: any) => boolean) | boolean
  tooltipMessage?: ((row?: any) => string) | string
  label?: ((row?: any) => string) | string
  icon?: ((row?: any) => ReactNode) | SvgNames
  iconSize?: SvgType
  hasTooltip?: boolean
  inDropdown?: boolean
  link?: string
  topSection?: ((row?: any) => boolean) | boolean
  hidden?: ((row?: any) => boolean) | boolean
  tooltipAlign?: Align
  tooltipAlignOffset?: number
}

export interface HeaderAction extends Action {
  deselectAllAction?: boolean
  embeddedComponent?: ReactNode
  onClick?: (event: MouseEvent<HTMLButtonElement> | Event) => void
  position?: number
  buttonType?: ButtonType
}

export interface RowAction extends Action {
  onClick: (row: any) => void
  position?: number
}

export interface TableProps {
  data: any[]
  columns: TableColumn[]
  className?: string
  bodyRef?: RefObject<HTMLDivElement>
  headerRef?: RefObject<HTMLDivElement>
  noBlanks?: boolean
  canFilter?: boolean
  filterPlaceholder?: string
  infoText?: string | ReactNode
  emptyText?: string
  useCheckboxes?: boolean
  checkboxLabel?: string
  checkboxWidth?: number
  useHeaderCheckbox?: boolean
  useStickyColumns?: boolean
  maxHeight?: string
  hasOverflow?: boolean
  hasHeadersWithEllipsis?: boolean
  virtualize?: boolean
  canPaginate?: boolean
  controlledPageCount?: number
  canPreviousPage?: boolean
  canNextPage?: boolean
  canLastPage?: boolean
  fetchData?: (pageIndex: number, pageSize: number) => void
  resetPageIndex?: boolean
  manualPagination?: boolean
  pageSizeOptions?: number[]
  headerActions?: HeaderAction[]
  hasFooter?: boolean
  disableSortRemove?: boolean
  dataTest?: string
  initialState?: Partial<
    TableState<{
      sortBy?: {
        id: string
        desc: boolean
      }[]
      selectedRowIds: string[]
      pageSize?: number
      pageIndex?: number
      globalFilter?: string
    }>
  >
  onRowSelectionChanged?: (rowIds: string[], rows: any) => void
  onRowClicked?: (row: any, rows?: any) => void
  checkboxCellOverride?: (row: any) => ReactNode
  rowDisabled?: (row: any) => boolean
  handleSearch?: (searchTerm: any) => void
  canClear?: boolean
  disablePaginator?: boolean
  manualSortBy?: boolean
  onSort?: (sortBy: Array<any>) => void
  onScroll?: (e: UIEvent<HTMLDivElement>) => void
  disableMultiSort?: boolean
  rowActions?: RowAction[]
  updatePageOnKeyPress?: boolean
  showPages?: boolean
  headerAddMargin?: boolean
  selectedRowsCount?: number
}
const rootClass = 'table'
const VIRTUALIZED_ROW_HEIGHT = 30
const FIXED_SIZE_LIST_HEIGHT = 400

export const renderCheckboxCell = (row: Row<any>, disabledCheckboxTooltipText?: string) => {
  const disabled = row.original.disabled
  const onChange = (checked: boolean) => {
    row.toggleRowSelected(checked)
  }
  const baseToggleRowSelectedPropOverride = row.getToggleRowSelectedProps()
  const { title } = baseToggleRowSelectedPropOverride
  const toggleRowSelectedPropOverride = {
    ...baseToggleRowSelectedPropOverride,
    title: disabled ? disabledCheckboxTooltipText || title : title,
    onChange,
  }
  return <Checkbox {...toggleRowSelectedPropOverride} disabled={disabled} dataTest={`${rootClass}__table-checkbox`} />
}

const onKeyPressed = (e: KeyboardEvent, row: any, onRowClicked: (row: any) => void) => {
  if (e.key === 'Enter') {
    onRowClicked(row)
  }
}

/**
 * @deprecated use <TableV2 instead
 */
const Table: FC<TableProps> = ({
  data,
  columns,
  className,
  bodyRef,
  headerRef,
  initialState,
  controlledPageCount = 1,
  resetPageIndex,
  canFilter = false,
  filterPlaceholder,
  infoText,
  canPaginate = false,
  canLastPage = true,
  fetchData,
  useCheckboxes = false,
  useHeaderCheckbox = false,
  useStickyColumns = false,
  checkboxLabel,
  checkboxWidth = 54,
  maxHeight,
  hasOverflow = false,
  hasHeadersWithEllipsis = false,
  virtualize = false,
  headerActions = [],
  manualPagination = true,
  pageSizeOptions,
  dataTest,
  onRowSelectionChanged,
  onRowClicked,
  checkboxCellOverride,
  rowDisabled,
  emptyText,
  handleSearch,
  disableSortRemove = true,
  canClear = false,
  disablePaginator = false,
  manualSortBy = false,
  disableMultiSort = true,
  hasFooter = false,
  rowActions = [],
  onSort,
  onScroll,
  updatePageOnKeyPress = false,
  showPages = true,
  headerAddMargin = false,
  selectedRowsCount,
}: TableProps) => {
  const selectedRowIdsRef = useRef<Record<string, boolean>>({})
  const toggleSelectedRows = () => {
    // This will guarantee that toggleAllRowSelect will be call with current selectedRows
    toggleSelectedRowsUtils(selectedRowIdsRef.current, toggleAllRowsSelected)
  }

  const renderCheckboxHeader = ({ getToggleAllRowsSelectedProps }: any) => {
    return renderCheckboxUtils(useHeaderCheckbox, getToggleAllRowsSelectedProps, toggleSelectedRows, checkboxLabel)
  }

  const CheckboxColumn = getCheckboxColumnUtils(
    renderCheckboxHeader,
    useHeaderCheckbox,
    useStickyColumns,
    rootClass,
    checkboxCellOverride,
    checkboxWidth
  )

  const tableColumns = useMemo(() => {
    if (useCheckboxes && !columns.find((col) => col.accessor === 'selection')) {
      return [CheckboxColumn, ...columns]
    }
    if (useStickyColumns) {
      return [getStickyColumnUtils(rootClass), ...columns]
    }
    return columns
  }, [columns, useCheckboxes, useStickyColumns])

  const caseInsensitive = (prev: Row, curr: Row, columnId: string) => {
    // @ts-ignore
    const prevFirst = prev.original[columnId].toLowerCase() > curr.original[columnId].toLowerCase()
    // @ts-ignore
    const currFirst = prev.original[columnId].toLowerCase() < curr.original[columnId].toLowerCase()
    if (prevFirst) {
      return 1
    } else if (currFirst) {
      return -1
    } else {
      return 0
    }
  }

  const statusDateTime = (prev: Row, curr: Row, columnId: string) => {
    // @ts-ignore
    const prevDate = new Date(prev.original[columnId])
    const prevIsDate = !isNaN(prevDate.valueOf())
    // @ts-ignore
    const currDate = new Date(curr.original[columnId])
    const currIsDate = !isNaN(currDate.valueOf())
    if (prevIsDate && currIsDate) {
      return prevDate > currDate ? 1 : -1
    } else {
      if (!prevIsDate) {
        return !currIsDate ? 0 : 1
      } else {
        return 1
      }
    }
  }

  const tableInstance = useTable(
    {
      columns: tableColumns as Column<object>[],
      data,
      initialState,
      manualPagination,
      manualSortBy,
      disableMultiSort,
      disableSortRemove,
      pageCount: controlledPageCount,
      sortTypes: { caseInsensitive, statusDateTime },
    },
    useGlobalFilter,
    useSortBy,
    useFlexLayout,
    useExpanded,
    usePagination,
    useRowSelect,
    useSticky
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // @ts-ignore: poor types in react-table
    preGlobalFilteredRows,
    // @ts-ignore: poor types in react-table
    setGlobalFilter,
    // @ts-ignore: poor types in react-table
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { sortBy, globalFilter, selectedRowIds, pageIndex, pageSize },
    toggleAllRowsSelected,
    footerGroups,
  } = tableInstance
  useEffect(() => {
    onSort?.(sortBy)
  }, [sortBy])

  useEffect(() => {
    if (onRowSelectionChanged) {
      onRowSelectionChanged(
        Object.keys(selectedRowIds).filter((key) => selectedRowIds[key]),
        rows
      )
    }
    selectedRowIdsRef.current = selectedRowIds
  }, [selectedRowIds])
  const totalRowCellWidth = rows.length ? columns.reduce((a, c) => a + ((c.maxWidth as number) ?? 0), 0) : 0
  const [tableWidth, setTableWidth] = useState<number>(totalRowCellWidth)
  const tableRef = useRef(null)

  const checkTableWidth = () => {
    const curr = tableRef.current || { offsetWidth: 0 }
    if (curr.offsetWidth) {
      setTableWidth(curr.offsetWidth)
    } else {
      setTimeout(() => {
        setTableWidth(curr.offsetWidth)
      })
    }
  }
  useEffect(checkTableWidth, [tableRef])
  useEffect(() => window.addEventListener('resize', checkTableWidth), [])

  const scrollBarSize = useMemo(() => scrollbarWidth(), [])
  const virtualizeRowWidth = totalRowCellWidth + scrollBarSize
  const tableHolderStyle = virtualize ? { width: `${virtualizeRowWidth}px)`, minWidth: 'auto', maxWidth: 'auto' } : undefined
  const flexColBaseWidth = tableWidth - totalRowCellWidth - scrollBarSize - 2

  const renderGlobalFilter = () => (
    <div className={`tr ${rootClass}__global-filter-container`} data-test={'render-global-filter'}>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={globalFilter}
        setGlobalFilter={handleSearch ?? setGlobalFilter}
        placeholder={filterPlaceholder}
        infoText={infoText}
        rootClass={rootClass}
        canClear={canClear}
      />
    </div>
  )

  const renderPaginator = () => (
    <div className={`${rootClass}__paginator`}>
      <Paginator
        pageCount={controlledPageCount}
        resetPageIndex={resetPageIndex}
        previousPage={previousPage}
        nextPage={nextPage}
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        canLastPage={canLastPage}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageSizeOptions={pageSizeOptions}
        pageOptions={pageOptions}
        setPageSize={setPageSize}
        fetchData={fetchData}
        disablePaginator={disablePaginator}
        updateOnKeyPress={updatePageOnKeyPress}
        showPages={showPages}
      />
    </div>
  )

  const RenderRow = useCallback(
    ({ index, style }: { index: number; style: {} }) => {
      const row = rows[index]
      prepareRow(row)
      const rowProps = row.getRowProps({
        style,
      })
      if (rowProps.style) rowProps.style.maxWidth = virtualizeRowWidth
      return (
        <div
          {...rowProps}
          className={classNames('tr', {
            [`${rootClass}__disabled`]: rowDisabled ? rowDisabled(row) : false,
            [`${rootClass}__actionable`]: onRowClicked,
          })}
        >
          {row.cells.map((cell) => {
            const cellProps = !onRowClicked
              ? cell.getCellProps()
              : {
                  ...cell.getCellProps(),
                  onClick: () => {
                    onRowClicked(row, rows)
                  },
                }
            // @ts-ignore
            const isFlexedCol = cell.column.flexColumn && tableWidth - totalRowCellWidth > 0
            const newWidth = `${isFlexedCol ? flexColBaseWidth : cell.column.maxWidth}px`

            if (cellProps.style) {
              cellProps.style.maxWidth = newWidth
              cellProps.style.width = newWidth
              cellProps.style.display = 'flex'
            }
            return (
              <div {...cellProps} className={classNames(`td`, getColumnAlignClass(columns, cell.column.id))} key={`${row.index}-${cell.column.id}, `}>
                {cell.render('Cell')}
              </div>
            )
          })}
        </div>
      )
    },
    [rows.map((row) => row.isSelected)]
  )

  const showHeaderActions = showHeaderActionsUtils(selectedRowIds, headerActions)

  const emptyBody = rows.length === 0
  const emptyTextHeight = virtualize ? { height: `${FIXED_SIZE_LIST_HEIGHT}px` } : undefined
  const tbodyStyle = maxHeight ? { overflow: 'auto', maxHeight } : {}
  const twoREM = 24

  return (
    <>
      <div
        className={classNames(`${rootClass}__table-holder`, {
          [`${className}__table-holder`]: className,
          [`${rootClass}__has-pagination`]: canPaginate && (fetchData || !manualPagination),
        })}
        style={tableHolderStyle}
        data-test={dataTest}
        onScroll={(e) => onScroll && onScroll(e)}
      >
        <div
          {...getTableProps()}
          className={classNames(rootClass, className, 'table', {
            [`${rootClass}__use-checkboxes`]: useCheckboxes,
            [`${rootClass}__use-sticky-columns`]: useStickyColumns,
            [`${rootClass}__multi-column`]: rows.length && rows[0].cells.length > 1,
            [`${rootClass}__has-overflow`]: hasOverflow,
            [`${rootClass}__header-add-margin`]: headerAddMargin,
          })}
          ref={tableRef}
        >
          <div className={`thead`} ref={headerRef}>
            {canFilter && renderGlobalFilter()}
            {!emptyText &&
              headerGroups.map((headerGroup, idx) => {
                const headerRowMaxWidth = tableRef.current ? tableWidth : totalRowCellWidth
                const headerGroupProps = headerGroup.getHeaderGroupProps()
                if (headerGroupProps.style) {
                  headerGroupProps.style.maxWidth = headerRowMaxWidth
                }
                // @ts-ignore
                const headerClassName = headerGroup.headers[0].className
                if (headerClassName === `${rootClass}__no-header-checkbox`) {
                  if (checkboxLabel) {
                    return (
                      <div className={`${rootClass}__single-title`} key="single-title">
                        {checkboxLabel}
                      </div>
                    )
                  } else {
                    return null
                  }
                }
                if (showHeaderActions) {
                  return (
                    <TableActionHeader
                      count={Object.keys(selectedRowIds).length}
                      selectedRowsCount={selectedRowsCount}
                      total={rows.length}
                      selectedRows={rows.filter((row) => Object.keys(selectedRowIds).includes(row.id))}
                      actions={headerActions}
                      onDeselectAll={toggleSelectedRows}
                      onChange={toggleSelectedRows}
                      useStickyActionHeader={useStickyColumns}
                      key="TableActionHeader"
                      {...headerGroup}
                    />
                  )
                }

                return (
                  <div className={`tr`} {...headerGroupProps} key={idx} aria-label="table-row">
                    {headerGroup.headers.map((column, idx) => {
                      let customHeaderProps = {}
                      if (idx === headerGroup.headers.length - 1 || (!useCheckboxes && idx === 0)) {
                        const style = {
                          minWidth: column.minWidth ? column.minWidth + twoREM : twoREM,
                          maxWidth: column.maxWidth ? column.maxWidth + twoREM : twoREM,
                        }

                        customHeaderProps = {
                          ...customHeaderProps,
                          style,
                        }
                      }
                      const headerSortByProps = column.getSortByToggleProps()
                      // @ts-ignore
                      delete headerSortByProps.title
                      const headerProps = { ...headerSortByProps, ...column.getHeaderProps(customHeaderProps) }
                      if (headerProps.style) {
                        // @ts-ignore
                        headerProps.style.maxWidth = `${column.flexColumn ? flexColBaseWidth : column.maxWidth}px`
                      }
                      return (
                        <th
                          tabIndex={column.disableSortBy ? -1 : 0}
                          onKeyPress={() => column.toggleSortBy()}
                          {...headerProps}
                          className={classNames(`th`, getColumnAlignClass(columns, column.id, false), {
                            [`th-unsorted`]: column.disableSortBy,
                          })}
                          key={column.id}
                        >
                          {renderColumnHeader({ column, hasEllipsis: hasHeadersWithEllipsis })}
                          {column.isSorted && (
                            <div
                              aria-live="polite"
                              aria-label={`Sorted by column ${column.Header} in ${column.isSortedDesc ? 'descending' : 'ascending'} order`}
                            >
                              {column.isSortedDesc ? (
                                <span className={classNames(`${rootClass}__sort-span`)}>
                                  <CaretIcon direction={CaretIconDirection.DOWN} />
                                </span>
                              ) : (
                                <span className={classNames(`${rootClass}__sort-span`)}>
                                  <CaretIcon direction={CaretIconDirection.UP} />
                                </span>
                              )}
                            </div>
                          )}
                        </th>
                      )
                    })}
                  </div>
                )
              })}
          </div>
          <div className="tbody" style={tbodyStyle} {...getTableBodyProps()} ref={bodyRef}>
            {emptyBody && (
              <div className={`${rootClass}__empty-text`} style={emptyTextHeight}>
                <Typography text={emptyText} type={TextType.BODY_TEXT_SMALL_LIGHT} inline />
              </div>
            )}
            {!emptyBody && virtualize && (
              <FixedSizeList height={FIXED_SIZE_LIST_HEIGHT} itemCount={rows.length} itemSize={VIRTUALIZED_ROW_HEIGHT} width={'100%'}>
                {RenderRow}
              </FixedSizeList>
            )}
            {canPaginate &&
              page.map((row) => {
                prepareRow(row)
                const rowProps = row.getRowProps()
                if (rowProps.style) {
                  rowProps.style.maxWidth = tableWidth
                }
                const tabIndex = onRowClicked ? { tabIndex: 0 } : {}
                return (
                  <tr
                    className={classNames('tr', {
                      [`${rootClass}__disabled`]: rowDisabled ? rowDisabled(row) : false,
                      [`${rootClass}__actionable`]: onRowClicked,
                    })}
                    onKeyPress={onRowClicked ? (e) => onKeyPressed(e as unknown as KeyboardEvent, row, onRowClicked) : undefined}
                    {...rowProps}
                    {...tabIndex}
                    key={row.index}
                  >
                    {row.cells.map((cell) => {
                      const cellProps = !onRowClicked
                        ? cell.getCellProps()
                        : {
                            ...cell.getCellProps(),
                            onClick: () => {
                              onRowClicked(row, rows)
                            },
                          }

                      // @ts-ignore
                      const isFlexedCol = cell.column.flexColumn && tableWidth - totalRowCellWidth > 0
                      if (cellProps.style) {
                        cellProps.style.maxWidth = isFlexedCol ? flexColBaseWidth : cell.column.maxWidth
                        cellProps.style.borderTop = row.depth > 0 ? 'none' : ''
                        // @ts-ignore
                        if (cell.value === '' && cell.column.fallback) cellProps.style = { ...cellProps.style, ...cell.column.style }
                      }
                      return (
                        <div
                          {...cellProps}
                          className={classNames(`td`, getColumnAlignClass(columns, cell.column.id))}
                          key={`${row.index}-${cell.column.id}`}
                        >
                          {cell.render('Cell')}
                        </div>
                      )
                    })}
                    {rowActions?.length > 0 && <TableRowAction className={`${rootClass}__row-actions`} rowActions={rowActions} row={row} />}
                  </tr>
                )
              })}
            {!emptyBody &&
              !virtualize &&
              !canPaginate &&
              rows.map((row) => {
                prepareRow(row)
                const rowProps = row.getRowProps()
                if (rowProps.style) {
                  rowProps.style.maxWidth = tableWidth
                }
                const tabIndex = onRowClicked ? { tabIndex: 0 } : {}
                return (
                  <tr
                    {...rowProps}
                    {...tabIndex}
                    className={classNames('tr', {
                      [`${rootClass}__disabled`]: rowDisabled ? rowDisabled(row) : false,
                      [`${rootClass}__actionable`]: onRowClicked,
                    })}
                    // eslint-disable-next-line
                    onKeyPress={onRowClicked ? (e) => onKeyPressed(e as unknown as KeyboardEvent, row, onRowClicked) : undefined}
                    aria-label="table-row"
                    key={row.index}
                    data-test={`${dataTest}-row-${row.index}`}
                  >
                    {row.cells.map((cell) => {
                      const cellProps = !onRowClicked
                        ? cell.getCellProps()
                        : {
                            ...cell.getCellProps(),
                            onClick: () => {
                              onRowClicked(row, rows)
                            },
                          }

                      // @ts-ignore
                      const isFlexedCol = cell.column.flexColumn && tableWidth - totalRowCellWidth > 0
                      if (cellProps.style) {
                        cellProps.style.maxWidth = isFlexedCol ? flexColBaseWidth : cell.column.maxWidth
                        cellProps.style.borderTop = row.depth > 0 ? 'none' : ''
                      }
                      return (
                        <div
                          {...cellProps}
                          className={classNames(`td`, getColumnAlignClass(columns, cell.column.id))}
                          key={`${row.index}-${cell.column.id}`}
                        >
                          {cell.render('Cell')}
                        </div>
                      )
                    })}
                    {rowActions?.length > 0 && <TableRowAction className={`${rootClass}__row-actions`} rowActions={rowActions} row={row} />}
                  </tr>
                )
              })}
          </div>
          {hasFooter && (
            <div className={'tfoot'}>
              {footerGroups.map((group, idx) => {
                return (
                  <div {...group.getFooterGroupProps()} className={'tr'} key={idx}>
                    {group.headers.map((column, idx) => {
                      const headerProps = { ...column.getFooterProps() }
                      if (headerProps.style) {
                        // @ts-ignore
                        headerProps.style.maxWidth = `${column.flexColumn ? flexColBaseWidth : column.maxWidth}px`
                      }
                      return (
                        <div {...headerProps} key={idx} className={classNames(`td`, getColumnAlignClass(columns, column.id, true))}>
                          {column.render('Footer')}
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
      {canPaginate && (fetchData || !manualPagination) && renderPaginator()}
    </>
  )
}

export default Table
