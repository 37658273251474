import React, { useCallback, useEffect, useMemo } from 'react'

import { useTranslation } from '@const/globals'
import { SecurityOptionEditContentRefType } from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Security/components/SecuritySection/SecuritySection'
import SecuritySettingDropdown from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Security/components/SecuritySettingDropdown/SecuritySettingDropdown'
import {
  SecurityOptionType,
  timeoutOptionsInHours,
} from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Security/constants/security.options'

export interface AutomaticLogoutFormProps {
  contentRef: SecurityOptionEditContentRefType
  setIsEnabled: (enabled: boolean) => void
  timeoutBySec?: string
  dataTest?: string
}

const rootClass = 'automatic-logout-form'
const dropdownName = 'autoLogout'

const AutomaticLogoutForm = (props: AutomaticLogoutFormProps) => {
  const { contentRef, setIsEnabled, timeoutBySec, dataTest = rootClass } = props
  const { t } = useTranslation()
  const value = useMemo(() => (Number(timeoutBySec) / 3600).toString(), [timeoutBySec])

  useEffect(() => {
    setIsEnabled(false)
    contentRef.data = timeoutBySec
  }, [timeoutBySec, setIsEnabled])

  const onDropdownSelect = useCallback(
    (keyValue: { [key: string]: string }) => {
      const timeoutBySec = (Number(keyValue[dropdownName]) * 3600).toString()
      setIsEnabled(true)
      contentRef.data = timeoutBySec
    },
    [setIsEnabled]
  )

  const options = useMemo<SecurityOptionType[]>(
    () =>
      timeoutOptionsInHours.map((option) => ({
        label: t('Timeout.Option.Label', { count: option }),
        id: option.toString(),
      })),
    [t]
  )

  return (
    <div data-test={dataTest} className={rootClass}>
      <SecuritySettingDropdown options={options} onSelect={onDropdownSelect} name={dropdownName} label={t('Timeout')} value={value} />
    </div>
  )
}

export default AutomaticLogoutForm
