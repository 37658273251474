import React, { FC } from 'react'

import classNames from 'classnames'

import { ButtonType } from '@components/Button'
import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { TextAlign, TextType } from '@components/Typography/Typography'

interface NestedDropdownEmptyListingProps {
  isRootSearch: boolean
  onClick: () => void
  className?: string
  dataTest?: string
}

const rootClass = 'nested-dropdown-empty-listing'

const NestedDropdownEmptyListing: FC<NestedDropdownEmptyListingProps> = (props: NestedDropdownEmptyListingProps) => {
  const { isRootSearch, onClick, dataTest = rootClass, className = '' } = props
  return (
    <EmptyListing
      className={classNames(className)}
      dataTest={dataTest}
      text={isRootSearch ? 'Nested.DropDown.Empty.Listing.Msg.Root' : 'Nested.DropDown.Empty.Listing.Msg.Folder'}
      textType={TextType.BODY_TEXT}
      textAlign={TextAlign.CENTER}
      imgSrc={StaticImageNames.emptySearch}
      size={EmptyListingSize.SMALL}
      buttonOnClick={onClick}
      buttonType={ButtonType.TEXT_TEAL}
      buttonText={isRootSearch ? 'Nested.DropDown.Empty.Listing.Btn.Text.Root' : 'Nested.DropDown.Empty.Listing.Btn.Text.Folder'}
    />
  )
}

export default NestedDropdownEmptyListing
