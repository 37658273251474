import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Row as ReactTableRow } from 'react-table'

import CrmPushErrorsModal from '@complex/CrmPushErrorsModal/CrmPushErrorsModal'
import ImportLog from '@complex/ImportLog/ImportLog'
import {
  CustomSource,
  ListPageAPI,
  ListPageCommonState,
  SetError,
  SidebarProps,
  Update,
} from '@complex/ListingPage/Context/ListingPageCommon.context'
import ListingPageContainer from '@complex/ListingPage/ListingPageContainer'
import { ExportToFtpFileModal } from '@components/ExportToFtpFileModal/ExportToFtpFileModal'
import { renderLoader } from '@components/Loader/Loader'
import { getAllSegmentStats } from '@components/SegmentDetails/SegmentDetails.utils'
import SegmentDetailsContainer from '@components/SegmentDetails/SegmentDetailsContainer'
import SelectAssetV2 from '@components/SelectAssetV2/SelectAssetV2'
import SelectFtpFileWithSearchModal from '@components/SelectFtpFileWithSearchModal/SelectFtpFileWithSearchModal'
import StatusToast from '@components/StatusToast/StatusToast'
import { legacyActonContext, useTranslation } from '@const/globals'
import { ItemDto, LabelDto } from '@graphql/types/microservice/categorization-types'
import { ABSplitSession } from '@src/pages/ContactSegments/components/ABSplit/ABSplit.constants'
import { getFilteredFTPFiles } from '@src/pages/ImportContactsV2/utils/ImportContactsV2.utils'
import DetailsCardWrapper from '@src/pages/listingPages/FormsJoinView/components/DetailsCardWrapper/DetailsCardWrapper'
import FormJoinViewDataCards from '@src/pages/listingPages/FormsJoinView/components/FormJoinViewDataCards/FormJoinViewDataCards'
import { FilesData, FormUploadFileModal } from '@src/pages/listingPages/FormsJoinView/components/FormUploadFileModal/FormUploadFileModal'
import RelatedFormsList from '@src/pages/listingPages/FormsJoinView/components/RelatedFormsList/RelatedFormsList'
import WebinarsJoinViewDataCards from '@src/pages/listingPages/FormsJoinView/components/WebinarsJoinViewDataCards/WebinarsJoinViewDataCards'
import {
  customContactsHeaderActions,
  customContactsRowActions,
  FormsJoinViewListingPageContainerInitialState,
  getPageHeaderProps,
  getSegmentDetailsPerformanceCards,
  getWebinarSegmentDetailsPerformanceCards,
} from '@src/pages/listingPages/FormsJoinView/FormsJoinViewListingPageContainer.constants'
import {
  FormsJoinViewListingPageContainerProps,
  FormsJoinViewListingPageContainerState,
} from '@src/pages/listingPages/FormsJoinView/FormsJoinViewListingPageContainer.constants'
import { useFormsJoinViewListingPageCRMRequests } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.crm.graphQL'
import { useFormsJoinViewListingPageEntityJoinRequests } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.entityJoin.graphQL'
import {
  getCustomDefaultEmptyListingProps,
  getCustomEmptyListingProps,
} from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.filters'
import { useFormsJoinViewListingPageRequests } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.graphQL'
import {
  renderCustomModal,
  renderDetailsPageCustomModal,
  tableProps,
} from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.tables'
import {
  getCrmPushErrors,
  getDetailsPageMenuActions,
  getListsRelatedForms,
  getPreviewMenuActions,
  getRecentRequestResultsUtils,
  getWebinarListsRelatedForms,
} from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.utils'
import {
  customPreviewItemCall,
  getCustomFilterCountsUtils,
  getExportToFtpFileProps,
  getForms,
  getFormWithCrmPushErrors,
  getInitialStatusToast,
  getSelectExportActionProps,
  getSelectFtpExportFileProps,
  getSelectFtpExportSyncProps,
  getWebinarStatusAndData,
  onDuplicateSegmentUtils,
  onExport,
  onTableRowClickedUtils,
} from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPageContainer.utils'
import { useFormsJoinViewDataByItemType } from '@src/pages/listingPages/FormsJoinView/utils/useFormsJoinViewDataByItemType'
import { Row } from '@tanstack/react-table'
import { useAccountSettings } from '@utils/account/account.utils'
import { BouncesSegments, bouncesVerbs, ItemType } from '@utils/categorization'
import { Contact } from '@utils/contact/contact.constants'
import { Segment } from '@utils/contactSegments/contactSegments.utils'
import { dateFormatFromString, DateFormats } from '@utils/date/dateUtils'
import { recentFilter } from '@utils/filter'
import { FileUplaodModalType } from '@utils/formUtils'
import useCRM from '@utils/hooks/useCRM'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { getItem, setItem } from '@utils/sessionStorage'
import { download, parseJsonSafely } from '@utils/utils'

import './FormsJoinViewListingPageContainer.css'

const rootClass = 'forms-join-view-listing-page-container'

const FormsJoinViewListingPageContainer: FC<FormsJoinViewListingPageContainerProps> = (props: FormsJoinViewListingPageContainerProps) => {
  const { dataTest = rootClass, storyUrl } = props

  const { t } = useTranslation()
  const { hasCRMConnected, connectorType } = useCRM()
  const [state, setState] = useState<FormsJoinViewListingPageContainerState>(FormsJoinViewListingPageContainerInitialState)
  const {
    showDetails,
    selectedContact,
    activeSubmission,
    activeSubmissionSendableContacts,
    activeSubmissionPerformanceData,
    relatedForms,
    activeSubmissionConversionRate,
    statusToast,
    segmentToExport,
    ftpExportSegmentsExecutionDetails,
    ftpFileImportLogTarget,
    ftpSegmentsExecutionDetails,
    selectingExportAction,
    selectingFtpFile,
    creatingFtpFile,
    crmPushErrorsModalProps,
    formsWithCrmPushErrors,
    ftpFiles,
    loadingFtpFiles,
    loadingFtpExportSyncs,
    ftpExportSyncJobs,
    selectingFtpExports,
    loading,
    webinarAttendanceData,
    isFTPConnectorActive,
    attachmentsData,
    attachmentsLoading,
    initialAttachmentsData,
    contactHeaders,
  } = state

  const [showPreviewOrDownloadModal, setShowPreviewOrDownloadModal] = useState(false)
  const [fileModalType, setFileModalType] = useState<FileUplaodModalType>(FileUplaodModalType.PREVIEW)
  const [fileRequestLoading, setFileRequestLoading] = useState<boolean>(false)
  const [fileModalHeader, setFileModalHeader] = useState<string>('')

  const accountSettings = useAccountSettings()
  const { hasReactWrapper, newFormFileUploadBlock } = accountSettings

  const history = useHistory()

  const itemTypeData = useFormsJoinViewDataByItemType(storyUrl)
  const { itemType, sidebarProps } = itemTypeData
  const isWebinarSubmissions = itemType === ItemType.WEBINAR_SUBMISSION
  const isFormSubmission = itemType != ItemType.SEGMENT && !activeSubmission?.parentId && itemType === ItemType.FORM_SUBMISSION

  const {
    getFormPreview,
    regenerateSplit,
    refreshRecords,
    duplicateSegment,
    getSendableContactsRequest,
    getExportSyncJobListRequest,
    getLastSegmentExecutionRequest,
    getLastSegmentExportExecutionRequest,
    getFtpConnectionStatusRequest,
    getFtpFilesRequest,
    getWebinarCompletedRequest,
    getItemDetailsRequest,
    retrieveAttachmentsById,
    getPreviewOrDownloadUrl,
    getSegmentContactsRequest,
  } = useFormsJoinViewListingPageRequests()

  const {
    deleteLists,
    getRecentFormSubmissionsRequest,
    getRecentWebinarRegistrationsRequest,
    getCountForRecentFormSubmissionsRequest,
    getCountForRecentWebinarRegistrationsRequest,
    getConversionRateRequest,
    getRelatedForms,
    getSubmitsAndViewsRequest,
    getWebinarRelatedFormsRequest,
    getWebinarAttendanceDataRequest,
  } = useFormsJoinViewListingPageEntityJoinRequests()

  const { getCrmPushErrorsRequest } = useFormsJoinViewListingPageCRMRequests()
  const { getListsRelatedFormsRequest, getWebinarListsRelatedFormsRequest } = useFormsJoinViewListingPageEntityJoinRequests()

  const { client: segmentClient } = useMicroserviceClient({ serviceName: MicroserviceClients.SEGMENT })

  const [attachmentRequestParams, setAttachmentRequestParams] = useState<{ ids: number[] | undefined } | null>(null)
  const [fileRequestParams, setFileRequestParams] = useState<{ fileIds: number[]; type?: FileUplaodModalType; isDownload?: boolean } | null>(null)

  const update = (values: Partial<FormsJoinViewListingPageContainerState>) => {
    setState((state) => ({ ...state, ...values }))
  }

  const onCRMPushErrorsClick = (origin: string, listId: string) => {
    update({ crmPushErrorsModalProps: { origin, listId } })
  }

  useEffect(() => {
    const showDetails = history.location.pathname.includes('/details')
    update({ showDetails })
    if (showDetails && !activeSubmission?.externalId) {
      const search = new URLSearchParams(window.location.search)
      const id = search.get('id') || undefined
      const externalId = search.get('externalId') || undefined
      const type = search.get('type') as ItemType
      if ((id || externalId) && type) {
        getItemDetailsRequest(type as ItemType, id, externalId).then(({ data }) => {
          update({ activeSubmission: data?.getItem })
        })
      }
    }
  }, [history.location])

  useEffect(() => {
    if (activeSubmission && showDetails) {
      const search = new URLSearchParams(window.location.search)
      const id = search.get('externalId') ?? activeSubmission.externalId
      if (id) {
        const isInactiveBounceSegment = id === bouncesVerbs[BouncesSegments.INACTIVE]
        getSegmentContactsRequest(id, itemType, isInactiveBounceSegment).then((headers) => update({ contactHeaders: headers }))
        getAllSegmentStats(id, segmentClient, update)
        getSendableContactsRequest(id).then((sendableContactsStats) => update({ activeSubmissionSendableContacts: sendableContactsStats }))
        getForms(id, isWebinarSubmissions, activeSubmission, getRelatedForms, getWebinarRelatedFormsRequest)?.then((relatedForms) =>
          update({ relatedForms: relatedForms })
        )
        if (isFormSubmission) {
          getSubmitsAndViewsRequest(id).then((performanceData) => update({ activeSubmissionPerformanceData: performanceData }))
          getConversionRateRequest(id).then((conversionRate) => update({ activeSubmissionConversionRate: conversionRate }))
        } else {
          getWebinarStatusAndData(id, activeSubmission, update, getWebinarCompletedRequest, getWebinarAttendanceDataRequest, webinarAttendanceData)
        }
      }
    }
  }, [activeSubmission?.externalId, showDetails])

  useEffect(() => {
    const ABSplitToast = getItem(ABSplitSession.TOAST)
    if (ABSplitToast) {
      update({ statusToast: JSON.parse(ABSplitToast) })
      setItem(ABSplitSession.TOAST, '')
    }
    getLastSegmentExecutionRequest().then((data) => update({ ftpSegmentsExecutionDetails: data }))
    getLastSegmentExportExecutionRequest().then((data) => update({ ftpExportSegmentsExecutionDetails: data }))
    getInitialStatusToast(update)
  }, [])

  useEffect(() => {
    if (selectingFtpFile) {
      getFtpFilesRequest().then((data) => update({ ftpFiles: getFilteredFTPFiles(data), loadingFtpFiles: false }))
    }
  }, [selectingFtpFile])

  const onDuplicateSegment = (
    newName: string,
    tags: LabelDto[],
    folderId: number | undefined,
    sourceId: string,
    listingPageValues: ListPageCommonState,
    listingPageAPI: ListPageAPI
  ) => onDuplicateSegmentUtils(newName, tags, folderId, sourceId, listingPageValues, listingPageAPI, t, duplicateSegment)

  const onTableRowClicked = (row: ReactTableRow<ItemDto>) => onTableRowClickedUtils(row, update, history, itemType)

  const onContactRowClicked = (row: Row<Contact>, uuid: string) => {
    update({ ...state, selectedContact: { ...row.original, uuid } })
  }

  const getCustomSource = useCallback((): CustomSource => {
    const recentRequest = itemType === ItemType.FORM_SUBMISSION ? getRecentFormSubmissionsRequest : getRecentWebinarRegistrationsRequest
    return {
      customRequestFilters: [
        {
          filter: recentFilter,
          request: (currentPage?: number) => getRecentRequestResultsUtils(recentRequest, '', currentPage),
          searchRequest: (query: string, currentPage?: number) => getRecentRequestResultsUtils(recentRequest, query, currentPage),
        },
      ],
      itemName: '',
      label: 'recent',
      mainColumnName: '',
      value: 'Recent',
    }
  }, [itemType])

  const customSidebarProps: SidebarProps = {
    ...sidebarProps,
    customSources: [getCustomSource()],
    getCustomFilterCounts: () =>
      getCustomFilterCountsUtils(itemType, getCountForRecentFormSubmissionsRequest, getCountForRecentWebinarRegistrationsRequest),
  }

  const detailsActivityCustomSections = useMemo(
    () => [
      {
        title: t('Related forms'),
        children: <RelatedFormsList relatedForms={relatedForms} />,
      },
    ],
    [relatedForms]
  )

  const renderDataCards = () => (isWebinarSubmissions ? <WebinarsJoinViewDataCards /> : <FormJoinViewDataCards />)

  const selectExportActionProps = useMemo(
    () =>
      getSelectExportActionProps(
        update,
        t,
        segmentToExport,
        ftpExportSegmentsExecutionDetails,
        getExportSyncJobListRequest,
        newFormFileUploadBlock,
        isFTPConnectorActive
      ),
    [segmentToExport]
  )

  const selectFtpExportFileProps = useMemo(
    () => getSelectFtpExportFileProps(update, t, segmentToExport, history, isWebinarSubmissions, rootClass, ftpFiles, loadingFtpFiles),
    [segmentToExport, ftpFiles, loadingFtpFiles]
  )

  const selectFtpExportSyncProps = useMemo(
    () => getSelectFtpExportSyncProps(update, segmentToExport, history, loadingFtpExportSyncs, ftpExportSyncJobs),
    [segmentToExport, ftpFiles, loadingFtpFiles]
  )

  const exportToFtpFileProps = useMemo(() => getExportToFtpFileProps(segmentToExport, update, history), [segmentToExport])

  const onDeleteLists = (items: ItemDto[]) => deleteLists(items, itemType)

  const customPerformanceCards = () =>
    isFormSubmission
      ? getSegmentDetailsPerformanceCards(activeSubmissionSendableContacts, activeSubmissionPerformanceData, activeSubmissionConversionRate, t)
      : isWebinarSubmissions && activeSubmission && !activeSubmission.parentId && !(activeSubmission as Segment).parent
      ? getWebinarSegmentDetailsPerformanceCards(activeSubmissionSendableContacts, webinarAttendanceData, t)
      : undefined

  const addCustomData = async (items: ItemDto[]) => {
    let crmPushErrors: string[] = []
    if (formsWithCrmPushErrors === undefined) {
      crmPushErrors = await getCrmPushErrors(getCrmPushErrorsRequest, update, t)
      update({ formsWithCrmPushErrors: crmPushErrors })
    } else {
      crmPushErrors = formsWithCrmPushErrors
    }

    const listIds = items.map((item) => item.externalId) as string[]
    const relatedForms = isWebinarSubmissions
      ? await getWebinarListsRelatedForms(getWebinarListsRelatedFormsRequest, listIds, update, t)
      : await getListsRelatedForms(getListsRelatedFormsRequest, listIds, update, t)
    if (crmPushErrors !== undefined && crmPushErrors.length > 0) {
      return items.map((item) => ({ ...item, formWithCrmPushErrors: getFormWithCrmPushErrors(item, relatedForms, crmPushErrors) }))
    } else {
      return items
    }
  }

  const toggleDownloadOrPreviewModal = useCallback((type: FileUplaodModalType, contact?: string[], headerText?: string, ids?: number[]) => {
    if (headerText) {
      setFileModalHeader(headerText)
    }

    const initialAttachmentsData =
      contact
        ?.map((item) => parseJsonSafely(item))
        .filter((parsed) => parsed?.type === 'FILE')
        .flatMap((attachment) => attachment.data) ?? []

    update({ attachmentsLoading: true, initialAttachmentsData })

    const filteredIds =
      contact
        ?.map((item) => parseJsonSafely(item))
        .filter((parsed) => parsed?.type === 'FILE')
        .flatMap((attachment) => attachment.data?.map((item: { id: number }) => item.id) ?? []) ?? []

    setFileModalType(type)
    update({ attachmentsLoading: true })

    setAttachmentRequestParams({ ids: ids ?? filteredIds })
  }, [])

  useEffect(() => {
    update({ attachmentsData: [] })
    if (!attachmentRequestParams) return

    const fetchAttachments = async () => {
      const { data } = await retrieveAttachmentsById({ attachmentIds: attachmentRequestParams.ids })

      if (data) {
        const formattedData = data?.retrieveAttachments?.map((attachment) => ({
          id: attachment?.id ?? '',
          name: attachment?.name ?? '',
          date: dateFormatFromString(attachment?.createdAt, DateFormats.LONG),
        }))

        update({ attachmentsData: formattedData })
        setShowPreviewOrDownloadModal(true)
      }
      update({ attachmentsLoading: false })
    }

    fetchAttachments()
  }, [attachmentRequestParams])

  const handleDownloadOrPreviewFile = useCallback(
    (fileIds: number[], type?: FileUplaodModalType, initialAttachmentsData?: Omit<FilesData, 'url' | 'date'>[]) => {
      if (type === FileUplaodModalType.PREVIEW || (type === FileUplaodModalType.DOWNLOAD && fileIds.length === 1)) {
        setFileRequestLoading(true)
        setFileRequestParams({ fileIds, type, isDownload: type === FileUplaodModalType.DOWNLOAD })
      } else if (type === FileUplaodModalType.DOWNLOAD) {
        setFileRequestLoading(true)

        const attachmentInfo = (initialAttachmentsData ?? []).filter((attachment) => fileIds.some((id) => attachment.id === id))
        fetch(`${legacyActonContext}/downloadFile`, {
          method: 'POST',
          body: JSON.stringify({ attachmentInfo }),
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob)
            download(url, attachmentsData?.[0]?.name.split('.')[0] ?? 'files')
            window.URL.revokeObjectURL(url)
          })
          .finally(() => {
            setFileRequestLoading(false)
          })
      }
    },
    [attachmentsData, fileModalType]
  )

  useEffect(() => {
    if (!fileRequestParams) return

    const fetchFileData = async () => {
      const { fileIds, type, isDownload } = fileRequestParams
      const { data } = await getPreviewOrDownloadUrl({ attachmentId: fileIds[0], isDownload })

      if (data && data.download?.downloadUrl) {
        update({ attachmentsLoading: false })
        setFileRequestLoading(false)

        if (type === FileUplaodModalType.PREVIEW) {
          window.open(data.download.downloadUrl)
        } else if (type === FileUplaodModalType.DOWNLOAD) {
          download(data.download.downloadUrl, attachmentsData?.find((attachment) => attachment.id === fileIds[0])?.name ?? 'file')
        }
      }
    }

    fetchFileData()
  }, [fileRequestParams])

  return (
    <>
      {showPreviewOrDownloadModal && (
        <FormUploadFileModal
          handlePrimaryButton={(selectedFileIndex, type) => {
            handleDownloadOrPreviewFile(selectedFileIndex, type, initialAttachmentsData)
          }}
          filesData={attachmentsData ?? []}
          type={fileModalType}
          handleCancel={() => {
            setShowPreviewOrDownloadModal(false)
            setFileModalHeader('')
          }}
          loading={fileRequestLoading}
          headerText={fileModalHeader}
        />
      )}
      {!!ftpFileImportLogTarget && (
        <ImportLog isOpen importFileId={ftpFileImportLogTarget} onClose={() => update({ ftpFileImportLogTarget: undefined })} />
      )}
      {selectingExportAction && <SelectAssetV2 {...selectExportActionProps} onCancel={() => update({ selectingExportAction: false })} />}
      {selectingFtpFile && <SelectFtpFileWithSearchModal {...selectFtpExportFileProps} onClose={() => update({ selectingFtpFile: false })} />}
      {creatingFtpFile && <ExportToFtpFileModal {...exportToFtpFileProps} onClose={() => update({ creatingFtpFile: false })} />}
      {crmPushErrorsModalProps && (
        <CrmPushErrorsModal {...crmPushErrorsModalProps} isOpen onClose={() => update({ crmPushErrorsModalProps: undefined })} />
      )}
      {!loadingFtpExportSyncs && ftpExportSyncJobs.length > 1 && selectingFtpExports && (
        <SelectAssetV2 {...selectFtpExportSyncProps} onCancel={() => update({ selectingFtpExports: false })} />
      )}

      {statusToast?.showStatusToast && (
        <StatusToast
          message={statusToast?.statusMessage}
          status={statusToast.status}
          closeStatus={() => update({ statusToast: { ...statusToast, showStatusToast: false } })}
        />
      )}
      {selectedContact && activeSubmission && (
        <DetailsCardWrapper
          selectedContact={selectedContact}
          activeSubmission={activeSubmission}
          itemType={itemType}
          onCloseModal={() => update({ ...state, selectedContact: undefined })}
          contactHeaders={contactHeaders ?? []}
        />
      )}
      {(loading || attachmentsLoading) && renderLoader('loader--white-background')}
      {showDetails ? (
        <SegmentDetailsContainer
          renderCustomModal={(customAction, item, update) =>
            renderDetailsPageCustomModal(customAction, { ...item, subTypeDTO: activeSubmission?.subTypeDTO }, update, itemType, hasReactWrapper)
          }
          detailsActivityCustomSections={isFormSubmission ? detailsActivityCustomSections : []}
          customDropdownItems={getDetailsPageMenuActions}
          customContactsTableRowActions={(unifiedListFieldMappings, contactHeaders, newFormFileUploadBlock, segmentContacts, userAllowedToDownload) =>
            customContactsRowActions(
              unifiedListFieldMappings,
              contactHeaders,
              newFormFileUploadBlock,
              segmentContacts,
              userAllowedToDownload,
              toggleDownloadOrPreviewModal,
              handleDownloadOrPreviewFile
            )
          }
          customContactsTableHeaderActions={customContactsHeaderActions}
          customPerformanceCards={customPerformanceCards()}
          contactsTableLabelSuffix={t('submissions')}
          customTabsTitles={{ contacts: 'Submissions', details: 'Details' }}
          onContactRowClicked={(row, uuid) => onContactRowClicked(row, uuid)}
          previousCount={(activeSubmission as Segment)?.recordsCount}
          toggleDownloadModal={toggleDownloadOrPreviewModal}
          handleDownloadOrPreviewFile={handleDownloadOrPreviewFile}
        />
      ) : (
        <ListingPageContainer
          listingPageProps={{
            renderDataCards,
            hasTabs: true,
            hideDeleteItems: true,
            customPreviewItemCall: ({ selectedRows }: ListPageCommonState, update: Update, setError: SetError) =>
              customPreviewItemCall(selectedRows, update, setError, getFormPreview),
            customDeleteItemsCall: onDeleteLists,
            getCustomEmptyListingProps: (setFilter, update, filter) => getCustomEmptyListingProps(setFilter, update, itemType, filter),
            getCustomDefaultEmptyListingProps: (filter, setFilter, t) => getCustomDefaultEmptyListingProps(filter, setFilter, t, itemType),
            getPreviewMenuActions: (form) => getPreviewMenuActions(form, hasReactWrapper),
            renderCustomModal: (customTableAction, listPageValues, listPageAPI) =>
              renderCustomModal(customTableAction, listPageValues, listPageAPI, onDuplicateSegment, hasReactWrapper),
            sidebarProps: customSidebarProps,
            hasCustomRequests: true,
            pageHeaderProps: getPageHeaderProps(itemType),
            tableProps: tableProps(
              t,
              onTableRowClicked,
              history.push,
              regenerateSplit,
              (ids: string[]) => refreshRecords(ids, itemType),
              itemType,
              connectorType,
              isWebinarSubmissions,
              (segment: Segment) => onExport(segment, update, t, getFtpConnectionStatusRequest, newFormFileUploadBlock),
              ftpSegmentsExecutionDetails,
              ftpExportSegmentsExecutionDetails,
              update,
              accountSettings,
              onCRMPushErrorsClick
            ),
            addCustomData: hasCRMConnected ? addCustomData : undefined,
            sortBy: [{ id: 'lastUpdated', desc: true }],
          }}
          className={rootClass}
          dataTest={dataTest}
          itemType={itemType}
        />
      )}
    </>
  )
}

export default FormsJoinViewListingPageContainer
