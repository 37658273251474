import React, { FC, useState } from 'react'

import classNames from 'classnames'

import { SEGMENTS_COLORS } from '@components/SegmentedSingleLineChart/utils/SegmentedSingleLineChart.constants'
import { mapAndLimitArray } from '@components/SegmentedSingleLineChart/utils/SegmentedSingleLineChart.utils'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { highChartColors } from '@const/Chart.constants'
import { useTranslation } from '@const/globals'
import { getFormattedNumber } from '@utils/numbers'

import './SegmentedSingleLineChart.css'

interface Props {
  className?: string
  dataTest?: string
  data: LineSegmentData[]
  maxElements?: number
  colors?: string[]
  subtitleSuffix: string
  elementName: string
  title?: string | React.ReactNode
  smallerTotal?: boolean
  noTitle?: boolean
  emptyTitle?: string
}

export interface LineSegmentData {
  amount: number
  tooltipTitle: string
}

const rootClass = 'segmented-single-line-chart'

const SegmentedSingleLineChart: FC<Props> = (props: Props) => {
  const {
    data,
    dataTest = rootClass,
    className = '',
    colors = highChartColors,
    maxElements = data.length,
    subtitleSuffix,
    elementName,
    title,
    smallerTotal,
    noTitle,
    emptyTitle,
  } = props
  const [hover, setHover] = useState<boolean>(false)
  const { t } = useTranslation()
  const isTitleString = typeof title === 'string'

  const totalSum = data.reduce((total, element) => {
    total += element.amount
    return total
  }, 0)

  const allEmpty = data.every((item) => item.amount == 0)
  const visibleData = maxElements < data.length || allEmpty ? mapAndLimitArray(data, maxElements, elementName) : data

  const getColor = (index: number) => {
    if (allEmpty) {
      return SEGMENTS_COLORS.ALL_EMPTY
    }
    if (maxElements < data.length && index === visibleData.length - 1) {
      return SEGMENTS_COLORS.EXTRA_ELEMENTS
    }
    return colors[index % colors.length]
  }

  const renderSegment = (index: number) => {
    return (
      <div
        className={classNames(`${rootClass}__segment`, {
          [`${rootClass}__segment-first`]: index === 0,
          [`${rootClass}__segment-last`]: index === visibleData.length - 1,
        })}
        data-test={`${rootClass}-element-${index}`}
        style={{
          opacity: hover ? 0.5 : undefined,
          backgroundColor: getColor(index),
        }}
      />
    )
  }

  return (
    <>
      {!noTitle &&
        (data.length === 0 ? (
          <Typography text={emptyTitle} type={TextType.BODY_TEXT_SMALL_LIGHT} className={`${rootClass}__title`} />
        ) : title ? (
          isTitleString ? (
            <Typography text={title} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.MEDIUM} className={`${rootClass}__title`} />
          ) : (
            title
          )
        ) : (
          <div className={`${rootClass}__title`}>
            <Typography text={getFormattedNumber(totalSum)} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.BOLD} inline />
            <Typography
              text={` from ${getFormattedNumber(data.length)} ${elementName}`}
              type={smallerTotal ? TextType.BODY_TEXT_SMALL_LIGHT : TextType.BODY_TEXT_LIGHT}
              weight={TextWeight.MEDIUM}
              inline
            />
          </div>
        ))}
      {data.length === 0 ? (
        <Tooltip
          position={'top'}
          align={'start'}
          alignArrowToStart
          inline={false}
          trigger={<div className={classNames(rootClass, className, `${rootClass}__empty`)} data-test={dataTest} />}
        >
          {t('Nothing to see here')}
        </Tooltip>
      ) : (
        <div
          className={classNames(rootClass, className, { [`${rootClass}__empty`]: visibleData.length === 0 })}
          data-test={dataTest}
          onMouseOver={() => setHover(true)}
          onFocus={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onBlur={() => setHover(false)}
        >
          {visibleData.map(({ amount, tooltipTitle }, index) => (
            <div
              key={index}
              className={`${rootClass}__wrapper`}
              style={{ width: allEmpty ? `${100 / data.length}%` : `${(amount * 100) / totalSum}%` }}
            >
              <Tooltip
                position={'top'}
                trigger={renderSegment(index)}
                triggerClassName={`${rootClass}__tooltip-trigger`}
                className={`${rootClass}__tooltip`}
                arrowClassName={`${rootClass}__tooltip-arrow`}
                borderColor={getColor(index)}
              >
                <div className={`${rootClass}__tooltip-content`}>
                  <div className={`${rootClass}__tooltip-content-title`}>
                    <div className={`${rootClass}__tooltip-content-dot`} style={{ backgroundColor: getColor(index) }} />
                    <Typography text={tooltipTitle} weight={TextWeight.BOLD} />
                  </div>
                  <Typography text={`${amount} ${subtitleSuffix}`} className={`${rootClass}__tooltip-content-subtitle`} />
                </div>
              </Tooltip>
            </div>
          ))}
        </div>
      )}
    </>
  )
}
export default SegmentedSingleLineChart
