import dayjs from 'dayjs'

import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { LandingPageRedirectUrl } from '@utils/composer/context/LandingPageComposer.context'

export const formatTimestamp = (timestamp: number) => {
  const timestampInSeconds = timestamp.toString().length === 13 ? Math.floor(timestamp / 1000) : timestamp

  const date = dayjs.unix(timestampInSeconds)

  return date.format('MMM D, YYYY [at] h:mm A [ET]')
}

export const getRedirectUrlOptions = (t: Function): SelectV2SingleOption[] => {
  return [
    {
      label: t('LandingPageComposer.Settings.Redirect.Url.Title', { context: LandingPageRedirectUrl.GLOBAL }),
      value: LandingPageRedirectUrl.GLOBAL,
    },
    {
      label: t('LandingPageComposer.Settings.Redirect.Url.Title', { context: LandingPageRedirectUrl.INDIVIDUAL }),
      value: LandingPageRedirectUrl.INDIVIDUAL,
    },
    {
      label: t('LandingPageComposer.Settings.Redirect.Url.Title', { context: LandingPageRedirectUrl.LANDING_PAGE }),
      value: LandingPageRedirectUrl.LANDING_PAGE,
    },
  ]
}
