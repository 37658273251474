import SvgNames from '@components/Svg/SvgNames'
import {
  ColumnType,
  CommonMappingColumn,
  DynamicListItems,
  DynamicListRowData,
  Icon,
  IconType,
  OptionType,
} from '@src/pages/datamanagement/components/CrmContacts/components/DynamicList/utils/DynamicList.interfaces'
import { FieldValidationState } from '@utils/crm.utils'

export function buildOptionItem(column: string, values: OptionType[], selected?: string): DynamicListItems {
  return { type: ColumnType.OPTION, column, values, selected }
}

export function buildIconItem(icon: IconType, dupStatus: FieldValidationState): DynamicListItems {
  return { type: ColumnType.ICON, icon, dupStatus }
}

export function buildStaticItem(column: string, staticValue: string, dataType?: string): DynamicListItems {
  return { type: ColumnType.STATIC, staticValue, column, dataType }
}

export function buildEditItem(column: string, staticValue?: string): DynamicListItems {
  return { type: ColumnType.EDIT, staticValue, column }
}

export function updateRowIcon(row: DynamicListRowData, newIcon: IconType, dupStatus: FieldValidationState) {
  const columns = row.columns.map((column) => {
    if (column.type !== ColumnType.BLANK && column.type !== ColumnType.ICON) {
      return column
    } else {
      return buildIconItem(newIcon, dupStatus)
    }
  })

  const newRow: DynamicListRowData = { id: row.id, columns: columns, key: row.key, type: row.type, metadata: row.metadata }
  return newRow
}

export const getIcon = (iconItem: IconType = IconType.ERROR, dupStatus: FieldValidationState = FieldValidationState.OK, rootClass = ''): Icon => {
  switch (iconItem) {
    case IconType.OK:
      return {
        image: SvgNames.circleGreen,
        class: `${rootClass}__check-circle`,
        tooltip: 'This mapping is successfully configured.',
      }
    case IconType.WARNING:
      return {
        image: SvgNames.warningSolidNoFill,
        class: `${rootClass}__warning`,
        tooltip:
          'These fields have different data types and will be converted to Text. Features specific to data types will be unavailable in Act-On.',
      }
    case IconType.ERROR:
      const errorProps = { image: SvgNames.errorSolid, class: `${rootClass}__error` }
      switch (dupStatus) {
        case FieldValidationState.DUP_STD:
          return { ...errorProps, tooltip: 'Act-On Standard fields can only be mapped one time.' }
        case FieldValidationState.DUP_CUST:
          return { ...errorProps, tooltip: 'Custom fields must be distinct.' }
        case FieldValidationState.MISSING_DATA_TYPE:
          return { ...errorProps, tooltip: 'Custom field mappings must have a data type.' }
        case FieldValidationState.MISSING_CUSTOM_NAME:
          return { ...errorProps, tooltip: 'Custom field mappings must have a destination field name set.' }
        case FieldValidationState.DUP_CRM_PRIMARY:
          return { ...errorProps, tooltip: 'A CRM field can only be mapped one time.' }
        case FieldValidationState.CUST_MATCH_STD:
          return { ...errorProps, tooltip: 'Custom fields must be unique from Act-On Standard fields.' }
        default:
          return { ...errorProps, tooltip: 'The data types of the selected CRM fields are incompatible.' }
      }
  }
}

export const rowHasMappedCRM = (columns: DynamicListItems[]) => {
  const commonColumns = Object.values(CommonMappingColumn)
  const contactColumn = columns.filter(
    ({ column = '', selected }) => selected !== undefined && !commonColumns.includes(column as CommonMappingColumn)
  )
  return contactColumn ? contactColumn.some(({ selected }) => selected !== '-1') : true
}
