import React, { FC, ReactNode, useContext, useEffect, useState } from 'react'

import classNames from 'classnames'

import { useApolloClient, useQuery } from '@apollo/client'
import ListingPageShareModal from '@complex/ListingPage/Components/ListingPageModals/ListingPageShareModal/ListingPageShareModal'
import { ListingPageCommonContext } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { FilterableListData } from '@components/FilterableList/FilterableList'
import { Status } from '@components/StatusToast/StatusToast'
import Typography, { TextWeight } from '@components/Typography/Typography'
import shareAssetToPeerAccounts from '@graphql/mutations/shareAssetToPeerAccounts'
import getPeerAccounts from '@graphql/queries/getPeerAccounts'
import { ShareAssetToPeerAccountsMutation, ShareAssetToPeerAccountsMutationVariables } from '@graphql/types/mutation-types'
import { GetPeerAccountsQuery, GetPeerAccountsQueryVariables, PeerAccount, PeerAccountsResponse } from '@graphql/types/query-types'

export interface ListingPageShareModalContainerProps {
  isOpen: boolean
  onClose: () => void
  className?: string
  dataTest?: string
}

const rootClass = 'listing-page-share-modal-container'

const ListingPageShareModalContainer: FC<ListingPageShareModalContainerProps> = (props: ListingPageShareModalContainerProps) => {
  const { isOpen, onClose, className, dataTest } = props

  const {
    setStatusToast,
    setError,
    values: {
      selectedRows,
      listingPageProps: {
        tableProps: { listPage, actonAssetType },
        canPreview,
      },
    },
  } = useContext(ListingPageCommonContext)
  const client = useApolloClient()
  const [shareAccounts, setShareAccounts] = useState<FilterableListData[]>([{ id: 0, selected: false }])

  const { data, loading, error } = useQuery<GetPeerAccountsQuery, GetPeerAccountsQueryVariables>(getPeerAccounts, {
    client,
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  })

  useEffect(() => {
    if (data) {
      updateData(data.getPeerAccounts)
    }
  }, [data])

  const updateData = (data: PeerAccountsResponse) => {
    const { peers } = data
    const shareAccounts = peers?.map((peer: PeerAccount) => ({
      id: parseInt(peer.id ?? ''),
      name: peer.name,
      selected: false,
    }))

    setShareAccounts(shareAccounts ?? [])
  }

  const shareItemsToShareAccountsMutation = async (shareAccountIds: number[]) => {
    const { data, errors } = await client.mutate<ShareAssetToPeerAccountsMutation, ShareAssetToPeerAccountsMutationVariables>({
      mutation: shareAssetToPeerAccounts,
      errorPolicy: 'all',
      variables: {
        asset: [
          {
            type: actonAssetType,
            id: selectedRows[0].externalId,
          },
        ],
        peers: shareAccountIds,
      },
    })

    if (data?.shareAssetToPeerAccounts) {
      const { status, failedIds } = data.shareAssetToPeerAccounts
      const accountNames = shareAccounts.filter((shareAccount) => shareAccountIds.includes(shareAccount.id)).map((account) => account.name)
      if (status === 'success') {
        setStatusToast(renderStatusMessage(accountNames, true), Status.SUCCESS)
      } else if (status === 'error') {
        setError(`Could not share asset`, errors)
      } else if (status === 'settingsNotCompatible') {
        const failedAccountNames =
          failedIds?.reduce((names: string[], failed) => {
            const accountId = failed?.accountId
            if (accountId) {
              const matchedAccount = shareAccounts.find((account) => account.id === parseInt(accountId, 10))
              if (matchedAccount?.name) {
                names.push(matchedAccount.name)
              }
            }
            return names
          }, []) ?? []
        setStatusToast(renderErrorMessage(failedAccountNames), Status.FAIL)
      }
    } else {
      setError(`ListPage.${listPage}.ShareToChildAccounts.FailToast`, errors)
    }
  }

  const renderStatusMessage = (accountNames: string[], success: boolean): ReactNode => {
    const rest = accountNames.slice(3, accountNames.length + 1)
    return success ? (
      <div className={`${rootClass}__status-toast`}>
        <Typography
          text={`ListPage.${listPage}.ShareToChildAccounts.SuccessToast${rest.length > 0 ? '.Multiple' : ''}`}
          inline
          values={{
            count: accountNames.length,
            restCount: rest.length,
            accountName1: accountNames[0],
            accountName2: accountNames[1],
          }}
          tagProps={{ bold: { weight: TextWeight.BOLD } }}
        />
      </div>
    ) : (
      <div className={`${rootClass}__status-toast`}>
        <Typography text={`ListPage.${listPage}.ShareToChildAccounts.FailToast`} inline />
      </div>
    )
  }

  const renderErrorMessage = (failedAccountNames: string[]): ReactNode => {
    const rest = failedAccountNames.slice(3, failedAccountNames.length + 1)

    return (
      <div className={`${rootClass}__status-toast`}>
        <Typography
          text={`ListPage.${listPage}.ShareToChildAccounts.SettingsNotCompatibleToast${failedAccountNames.length >= 2 ? '.Multiple' : ''}`}
          inline
          values={{
            count: failedAccountNames.length,
            restCount: rest.length,
            accountName1: failedAccountNames[0] ?? '',
            accountName2: failedAccountNames[1] ?? '',
          }}
          tagProps={{ bold: { weight: TextWeight.BOLD } }}
        />
      </div>
    )
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <ListingPageShareModal
        updateSharedAccounts={shareItemsToShareAccountsMutation}
        shareAccounts={shareAccounts}
        canPreview={canPreview ?? false}
        loading={loading}
        pageError={error}
        isOpen={isOpen}
        onClose={onClose}
      />
    </div>
  )
}

export default ListingPageShareModalContainer
