import React, { FC, ReactNode, useMemo } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { ColumnStatus } from '@components/ColumnsOrderModal/components/DraggableColumn/DraggableColumn'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'

import './DraggableColumnActions.css'

interface Action {
  tooltip: string
  render: () => ReactNode
}

interface Props {
  className?: string
  dataTest?: string
  status: ColumnStatus
  disableLock?: boolean
  disableHide?: boolean
  onLock: () => void
  onHide?: () => void
}

const rootClass = 'draggable-column-actions'

const DraggableColumnActions: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '', status, onHide, onLock, disableLock = false, disableHide = false } = props

  const { t } = useTranslation()

  const actions: { [key: string]: Action[] } = {
    [ColumnStatus.DEFAULT]: [
      {
        tooltip: disableLock ? 'You can only pin up to 3 fields' : 'Pin this field to the left side of your list',
        render: () => renderButton(disableLock ? SvgNames.unpinnedDisabled : SvgNames.unpinned, { onClick: !disableLock ? onLock : undefined }),
      },
      ...(onHide
        ? [
            {
              tooltip: disableHide ? 'You cannot hide all fields' : 'Hide field from view in list',
              render: () => renderButton(SvgNames.hide, { onClick: !disableHide ? onHide : undefined }),
            },
          ]
        : []),
    ],
    [ColumnStatus.LOCKED]: [
      { tooltip: 'Unpin field', render: () => renderButton(SvgNames.pinned, { onClick: onLock, className: `${rootClass}__action-fixed` }) },
      ...(onHide ? [{ tooltip: 'Locked fields cannot be hidden', render: () => renderButton(SvgNames.hide) }] : []),
    ],
    [ColumnStatus.HIDDEN]: [
      { tooltip: 'Hidden fields cannot be locked', render: () => renderButton(SvgNames.unpinnedDisabled) },
      ...(onHide ? [{ tooltip: 'Show field', render: () => renderButton(SvgNames.show, { onClick: onHide }) }] : []),
    ],
  }

  const renderButton = (icon: SvgNames, options?: { onClick?: VoidFunction; className?: string }) => {
    const { onClick, className } = { ...options }
    const renderIcon = <Svg name={icon} type={SvgType.MEDIUM} />
    return onClick ? (
      <Button className={className} dataTest={`${dataTest}__button`} buttonType={ButtonType.ICON} onClick={onClick}>
        {renderIcon}
      </Button>
    ) : (
      <div data-test={`${dataTest}__button-disabled`} className={`${rootClass}__disabled`}>
        {renderIcon}
      </div>
    )
  }

  const renderActions = useMemo(
    () =>
      actions[status].map(({ tooltip, render }, index) => (
        <Tooltip key={`action-${index}`} position={'top'} trigger={render()}>
          {t(tooltip)}
        </Tooltip>
      )),
    [status, disableLock, disableHide, onHide, onLock]
  )

  return (
    <div
      className={classNames(rootClass, className, {
        [`${rootClass}__locked`]: status === ColumnStatus.LOCKED,
        [`${rootClass}__hidden`]: status === ColumnStatus.HIDDEN,
      })}
      data-test={dataTest}
    >
      {renderActions}
    </div>
  )
}

export default DraggableColumnActions
