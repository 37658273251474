export function appendChildToBody(elm: HTMLElement) {
  return document.body.appendChild(elm)
}

export function createElement(elm: string) {
  return document.createElement(elm)
}

export function getActiveElement() {
  return document.activeElement
}

export function isInstanceOfNode(target: EventTarget | null) {
  return target !== null && target instanceof Node
}

export function isInstanceOfElement(target: EventTarget | null) {
  return target !== null && target instanceof Element
}

export function isInstanceOfHtmlElement(div: any) {
  return div instanceof HTMLElement
}

export function querySelectorAll(query: string) {
  return document.querySelectorAll(query)
}

export function querySelector(query: string) {
  return document.querySelector(query)
}

export function urlSearchParams(search: string) {
  return new URLSearchParams(search)
}

export function getElementById(id: string) {
  return document.getElementById(id)
}

export function setTitle(title?: string, managedAppTitle?: string) {
  if (!title) return
  const windowObject = window.location?.search.includes('crmIntegration=1') ? window.parent : window.top
  if (windowObject) {
    windowObject.document.title = `${managedAppTitle && managedAppTitle !== 'Act-On Service' ? managedAppTitle : 'Act-On'} :: ${title}`
  }
}

export function getTitle() {
  const windowObject = window.location?.search.includes('crmIntegration=1') ? window.parent : window.top

  return windowObject?.document.title ?? ''
}

export function clearInterval(handle?: number) {
  window.clearInterval(handle)
}

export function getDocumentBody() {
  return window.location?.search.includes('crmIntegration=1')
    ? window.parent?.document.querySelector('body')
    : window.top?.document.querySelector('body')
}

export function addEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions) {
  document.addEventListener(type, listener, options)
}

export function removeEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void {
  document.removeEventListener(type, listener, options)
}

export function getBody() {
  return document.body
}

export function getColorValue(colorName: string) {
  return getComputedStyle(document.documentElement).getPropertyValue(colorName)
}

export default {
  appendChildToBody,
  createElement,
  getActiveElement,
  isInstanceOfNode,
  querySelectorAll,
  querySelector,
  urlSearchParams,
  getElementById,
  setTitle,
  getTitle,
  clearInterval,
  getDocumentBody,
  addEventListener,
  removeEventListener,
  isInstanceOfElement,
  isInstanceOfHtmlElement,
  getBody,
  getColorValue,
}
