import React, { FC, useMemo, useState } from 'react'

import classNames from 'classnames'

import ScrollArea from '@components/ScrollArea/ScrollArea'
import Tabs, { TabItemData, TabStyle } from '@components/TabsAO/TabsAO'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { TestResponseEntity } from '@graphql/types/microservice/webhooks-incoming-management-types'
import StatusMessage from '@src/pages/Webhooks/IncomingWebhooks/components/StatusMessage/StatusMessage'
import TestWebhookURL from '@src/pages/Webhooks/IncomingWebhooks/components/TestWebhookModal/components/TestWebhookURL/TestWebhookURL'

import './TestWebhookResponse.css'

interface TestWebhookResponseProps {
  className?: string
  dataTest?: string
  success: boolean
  url: string
  testResponse?: TestResponseEntity
  onEditClick: () => void
}

const rootClass = 'test-webhook-response'

const TestWebhookResponse: FC<TestWebhookResponseProps> = (props: TestWebhookResponseProps) => {
  const { dataTest = rootClass, className = '', success, url, testResponse, onEditClick } = props
  const [currentTab, setCurrentTab] = useState<string>(success ? 'request' : 'response')

  const { t } = useTranslation()

  const renderRequest = () => {
    return (
      <ScrollArea className={`${rootClass}__request`} hasHorizontalScroll>
        <pre>
          {testResponse?.request?.body && (
            <Typography text={JSON.stringify(JSON.parse(testResponse.request.body), null, 2)} type={TextType.CODE_TEXT_MEDIUM} />
          )}
        </pre>
      </ScrollArea>
    )
  }

  // Response type to be defined once we have the backend integration
  const renderResponse = () => {
    return (
      <ScrollArea className={`${rootClass}__request`}>
        <Typography text={t('Response Details')} type={TextType.CODE_TEXT_LARGE} weight={TextWeight.MEDIUM} />
        <Typography text={`${t('Response Code:')} ${testResponse?.statusCode}`} type={TextType.CODE_TEXT_MEDIUM} />
        <Typography text={`${t('Response Message:')} ${testResponse?.message}`} type={TextType.CODE_TEXT_MEDIUM} />
        <Typography text={t('Webhook Headers')} type={TextType.CODE_TEXT_LARGE} weight={TextWeight.MEDIUM} />
        {testResponse?.request?.headers?.split('\n').map((header, index) => (
          <Typography key={index} text={header} type={TextType.CODE_TEXT_MEDIUM} />
        ))}
      </ScrollArea>
    )
  }

  const tabsData = useMemo<TabItemData[]>(
    () => [
      {
        index: 'request',
        label: t('Received Request'),
        content: renderRequest(),
        disabled: !success,
      },
      {
        index: 'response',
        label: t('Response'),
        content: renderResponse(),
      },
    ],
    [t]
  )

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <StatusMessage
        header={t(success ? 'Your test was a success!' : 'Your test failed...')}
        subheader={t(`Incoming.Webhooks.Test.${success ? 'Success' : 'Failure'}.Text`)}
        success={success}
        action={{
          text: t('Edit webhook'),
          onClick: onEditClick,
        }}
      />
      <TestWebhookURL url={url} />
      <Tabs
        className={`${rootClass}__tabs-container`}
        tabStyle={TabStyle.SOLID}
        childData={tabsData}
        defaultValue={currentTab}
        onChange={setCurrentTab}
      />
    </div>
  )
}

export default TestWebhookResponse
