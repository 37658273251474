import { Program, ProgramStep } from '@graphql/types/query-types'

const Z_TRACK = 25
const A_LETTER = 65
export const getLetter = (trackIndex: number) => {
  if (trackIndex <= Z_TRACK) {
    return String.fromCharCode(trackIndex + A_LETTER)
  } else {
    const firstLetter = String.fromCharCode(Math.floor(trackIndex / Z_TRACK) - 1 + A_LETTER)
    const secondLetter = String.fromCharCode(trackIndex - Z_TRACK - 1 + A_LETTER)
    return `${firstLetter}${secondLetter}`
  }
}

export const getUIStepName = (program: Program, stepId: string) => {
  let stepName = ''
  const { tracks } = program
  tracks.forEach((track) => {
    for (const [stepIndex, step] of track.steps.entries()) {
      if (step.stepId === stepId) {
        stepName = `${step.letter}-${stepIndex + 1}`
      }
    }
  })
  return stepName
}

export const getStepId = (program: Program, stepName: string) => {
  const { tracks } = program
  let stepId = ''

  tracks.forEach((track) => {
    track.steps.forEach((step) => {
      if (step.stepId === stepName) {
        stepId = step.stepId
      } else {
        if (step.letter === stepName) {
          stepId = step.stepId ?? ''
        }
      }
    })
  })
  return stepId
}

const A_AS_NUM = 65
export const getStepFromStepLetter = (stepLetter: string) => {
  const [trackLetter, stepNumber] = stepLetter.split('-')
  const trackNumber = trackLetter.charCodeAt(0) - A_AS_NUM
  return [trackNumber, parseInt(stepNumber) - 1]
}

export const getProgramStepsWithLetters = (program: Program, startingIndex = 1) => {
  const tracks = program.tracks.map((track) => {
    return {
      ...track,
      steps: track.steps.map((step, idx) => ({
        ...step,
        letter: `${track.letter}-${idx + startingIndex}`,
      })),
    }
  })

  return tracks.flatMap((track: any) => track.steps)
}

export const getStep = (program: Program, stepId: string): ProgramStep | null => {
  const track = program.tracks.reduce((acc, curr) => {
    return { ...acc, steps: [...acc.steps, ...curr.steps] }
  })
  const steps = track.steps.filter((step) => step.stepId === stepId)
  return steps.length > 0 ? steps[0] : null
}
