import { REQUIRED_MESSAGE_KEY } from '@components/InputWithStatus/InputWithStatus'

export const FORM_DOMAINS_MORE_INFO_LINK =
  'https://success.act-on.com/acton/attachment/9883/u-d4d3b5a2-019c-42a1-908c-8d31bf23f3c5/0/-/-/-/-/In-App%3A%20Form%20Domains'

export const FORM_DOMAINS_VALIDATION_MESSAGES = {
  [REQUIRED_MESSAGE_KEY]: 'Provide a valid URL',
  invalidUrl: 'Provide a valid URL',
  isUnique: 'This URL has been added already',
}
