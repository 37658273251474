import { useContext } from 'react'

import { useDebouncedCallback } from 'use-debounce'

import { useApolloClient } from '@apollo/client'
import checkMessageContentPersonalization from '@graphql/queries/checkMessageContentPersonalization'
import { CheckMessageContentPersonalizationQuery, CheckMessageContentPersonalizationQueryVariables } from '@graphql/types/query-types'
import { filterNotEmptyArray } from '@utils/array'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'

import { useAddDynamicImagePersonalizationToHtml } from './useAddDynamicImagePersonalizationToHtml'

const DEBOUNCE_TIME = 500

const useCheckMessageContentPersonalizationRequest = () => {
  const {
    values: {
      message: { id },
    },
    api: { updateValidations },
  } = useContext(EmailComposerContext)
  const client = useApolloClient()

  const updatedDynamicHtml = useAddDynamicImagePersonalizationToHtml()

  //* used debounce to wait for the most recent Dynamic HTML
  return useDebouncedCallback(() => {
    const handleError = () => updateValidations({ contentPersonalizationErrors: undefined })

    return client
      .query<CheckMessageContentPersonalizationQuery, CheckMessageContentPersonalizationQueryVariables>({
        query: checkMessageContentPersonalization,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        variables: { msgId: id, html: updatedDynamicHtml.replace(/\s+/g, ' ') },
      })
      .then(({ data }) => {
        if (!!data?.checkMessageContentPersonalization?.length) {
          updateValidations({
            contentPersonalizationErrors: data.checkMessageContentPersonalization.filter(filterNotEmptyArray),
          })
        } else {
          handleError()
        }
      })
      .catch(() => handleError())
  }, DEBOUNCE_TIME)
}

export default useCheckMessageContentPersonalizationRequest
