import React, { FC, ReactNode, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import classNames from 'classnames'
import * as yup from 'yup'

import AddButton from '@components/AddButton/AddButton'
import FormGroup from '@components/FormGroup'
import FormRow from '@components/FormRow'
import InfoStatus, { InfoStatusTypes } from '@components/InfoStatus/InfoStatus'
import InputV2 from '@components/InputV2/InputV2'
import { LabelType, LabelV2 } from '@components/LabelV2/LabelV2'
import { ModalBody } from '@components/Modal'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2Props, SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { Status } from '@components/StatusToast/StatusToast'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { Program } from '@graphql/types/query-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEditUpdateSubscriptionStepRequests } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditUpdateSubscriptionStep/utils/EditUpdateSubscriptionStep.graphQL'
import {
  filterSelectedCategories,
  getSubscriptionManagementCategories,
  hasDuplicates,
  markDuplicates,
  toSelectOption,
} from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditUpdateSubscriptionStep/utils/EditUpdateSubscriptionStep.utils'
import { ProgramUpdateSubscriptionStepExt, Step } from '@utils/program/program.constants'

import './EditUpdateSubscriptionStep.css'

interface EditUpdateSubscriptionStepProps {
  className?: string
  dataTest?: string
  closeModal: VoidFunction
  step: Step
  isRunning: boolean
  saveStepAndProgram: (step: Step | null, program?: Program) => void
  submitId: string
}

export interface CategoryOption {
  categoryName: string
  option?: boolean
  duplicated?: boolean
  exist?: boolean
}

interface FormData {
  stepName: string
  categoryOptions: CategoryOption[]
}

export interface EditUpdateSubscriptionStepState {
  statusToast: { statusMessage: string | ReactNode; status: Status; showStatusToast: boolean }
  loading?: boolean
  accountCategories: string[]
}

const rootClass = 'edit-update-subscription-step'

const EditUpdateSubscriptionStep: FC<EditUpdateSubscriptionStepProps> = (props: EditUpdateSubscriptionStepProps) => {
  const { dataTest = rootClass, className = '', isRunning, step: baseStep, submitId, saveStepAndProgram } = props
  const step = baseStep as ProgramUpdateSubscriptionStepExt
  const [state, setState] = useState<EditUpdateSubscriptionStepState>({
    accountCategories: [],
    loading: false,
    statusToast: { statusMessage: '', status: Status.SUCCESS, showStatusToast: false },
  })
  const { accountCategories } = state
  const { getSMAccountCategories } = useEditUpdateSubscriptionStepRequests()

  useEffect(() => {
    setState((state: EditUpdateSubscriptionStepState) => ({ ...state, loading: true }))
    getSubscriptionManagementCategories(getSMAccountCategories, setState)
  }, [])

  const checkIfExists = (categoryOptions: CategoryOption[]): CategoryOption[] => {
    const options = categoryOptions.map((item) => ({
      ...item,
      exist: !item.categoryName || item.categoryName.trim() === '' || accountCategories.includes(item.categoryName),
    }))
    return options
  }

  const schema = yup.object().shape({
    stepName: yup.string().required('Step name is a required field. Please add a step name to continue.'),
    categoryOptions: yup
      .array()
      .of(
        yup.object().shape({
          categoryName: yup.string().required('Subscription category is missing or invalid.'),
          option: yup.boolean().nullable().required('Option is required.'),
          duplicated: yup.boolean(),
          exist: yup.boolean(),
        })
      )
      .test('check-exist', 'Some categories no longer exist.', function () {
        const categoryOptions = this.parent.categoryOptions
        if (!categoryOptions) return true

        const hasNonExistCategories = categoryOptions.some((category: CategoryOption) => category.exist === false)
        this.parent.hasNonExistCategories = hasNonExistCategories
        return !hasNonExistCategories
      }),
    hasDuplicates: yup
      .boolean()
      .nullable()
      .test('check-duplicates', 'At least one category is duplicated.', function () {
        const categoryOptions = this.parent.categoryOptions
        if (!categoryOptions) return false

        const hasDuplicateCategories = hasDuplicates(categoryOptions)
        return !hasDuplicateCategories
      })
      .required('There are duplicated categories.'),
    hasNonExistCategories: yup.boolean(),
  })

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: {
      stepName: step.displayName,
      categoryOptions: filterSelectedCategories(step) || [],
      hasDuplicates: false,
      hasNonExistCategories: false,
    },
  })

  const categoryOptions = watch('categoryOptions', [])
  const hasNonExistCategories = watch('hasNonExistCategories', false)

  useEffect(() => {
    if (accountCategories.length > 0) {
      const currentCategoryOptions = getValues('categoryOptions')
      if (categoryOptions.length === 1) {
        const category = currentCategoryOptions[0]
        if (category.categoryName) {
          const updatedCategoryOptions = checkIfExists(currentCategoryOptions)
          setValue('categoryOptions', updatedCategoryOptions, { shouldValidate: true, shouldDirty: true })
        }
      } else {
        const updatedCategoryOptions = checkIfExists(currentCategoryOptions)
        setValue('categoryOptions', updatedCategoryOptions, { shouldValidate: true, shouldDirty: true })
      }
    }
  }, [accountCategories, setValue, getValues])

  const { t } = useTranslation()

  const onSubmit = (data: FormData) => {
    const displayName = data.stepName

    const subscribeCategories = data.categoryOptions
      .filter((category: CategoryOption) => category.option === true && category.categoryName)
      .map((category: CategoryOption) => category.categoryName)

    const unsubscribeCategories = data.categoryOptions
      .filter((category: CategoryOption) => category.option === false && category.categoryName)
      .map((category: CategoryOption) => category.categoryName)
    saveStepAndProgram({
      ...step,
      displayName,
      subscribeCategories,
      unsubscribeCategories,
    })
  }

  const categoryOptionsData: SelectV2SingleOption[] = accountCategories.map((category) => ({
    label: category,
    value: category,
  }))

  const booleanOptions: SelectV2SingleOption[] = [
    { label: t(`Set to true`), value: 'true' },
    { label: t(`Set to false`), value: 'false' },
  ]

  const addEmptyCategory = () => {
    const newCategory: CategoryOption = { categoryName: '', option: undefined }
    const currentCategories = getValues('categoryOptions')
    const updatedCategories = [...currentCategories, newCategory]

    setValue('categoryOptions', markDuplicates(updatedCategories), { shouldValidate: false, shouldDirty: true })
  }

  const removeCategory = (index: number) => {
    const currentCategories = getValues('categoryOptions')
    const updatedCategories = currentCategories.filter((_, i: number) => i !== index)

    setValue('categoryOptions', markDuplicates(updatedCategories), { shouldValidate: true, shouldDirty: true })
  }

  const updateCategory = (index: number, updatedCategory: CategoryOption) => {
    const currentCategories = getValues('categoryOptions')
    const updatedCategories = currentCategories.map((category, i) => (i === index ? updatedCategory : category))
    const categoriesWithDuplicates = markDuplicates(updatedCategories)
    const categoriesWithExistence = checkIfExists(categoriesWithDuplicates)

    setValue('categoryOptions', categoriesWithExistence, { shouldValidate: true, shouldDirty: true })

    const hasNonExistCategories = categoriesWithExistence.some((category) => !category.exist)
    const hasDuplicateCategories = categoriesWithExistence.some((category) => category.duplicated)

    setValue('hasNonExistCategories', hasNonExistCategories, { shouldValidate: true })
    setValue('hasDuplicates', hasDuplicateCategories, { shouldValidate: true })
  }

  const renderErrorInfo = () => {
    const nonExistCategories = getValues('categoryOptions')
      .filter((category) => category.exist === false)
      .map((category) => category.categoryName)

    return (
      <InfoStatus
        status={InfoStatusTypes.Warning}
        message={
          <div className={`${rootClass}__caution-default-logo`}>
            {hasNonExistCategories && (
              <>
                <Typography weight={TextWeight.BOLD} text={t(`Fix the errors below to continue:`)} />
                {nonExistCategories.map((categoryName) => (
                  <div key={categoryName} className={`${rootClass}__category-error`}>
                    <Typography lineHeight={LineHeight.MEDIUM} weight={TextWeight.BOLD} text={`• ${categoryName}`} />
                    <Typography
                      text={t(`Error: Subscription category no longer exists.`)}
                      weight={TextWeight.REGULAR}
                      lineHeight={LineHeight.MEDIUM}
                    />
                  </div>
                ))}
              </>
            )}
            {errors?.hasDuplicates && (
              <div className={hasNonExistCategories ? `${rootClass}__category-duplicate-with-margin` : `${rootClass}__category-duplicate`}>
                <Typography
                  text={t(
                    `You have selected the same subscription category more than once. Please remove or change the repeated categories to continue.`
                  )}
                  weight={TextWeight.REGULAR}
                  lineHeight={LineHeight.MEDIUM}
                />
              </div>
            )}
          </div>
        }
        svgName={SvgNames.errorAlert}
      />
    )
  }

  const renderRowOptions = () =>
    categoryOptions.map((category, index) => {
      const categorySelectProps: SelectV2Props = {
        className: `${rootClass}__category-selector`,
        value: category.categoryName === undefined ? null : toSelectOption(category.categoryName),
        error: (category.categoryName && category.exist === false) || !!errors?.categoryOptions?.[index]?.categoryName || category.duplicated,
        isSearchable: true,
        insideModal: true,
        placeholder: t(`Select a subscription category`),
        dataTest: `${rootClass}__category-test`,
        options: categoryOptionsData,
        onChange: (selectedOption) => {
          if (selectedOption) {
            updateCategory(index, { ...category, categoryName: selectedOption.value })
          } else {
            updateCategory(index, { ...category, categoryName: '' })
          }
        },
      }

      const optionSelectProps: SelectV2Props = {
        className: `${rootClass}__option-selector`,
        value: category.option === undefined ? null : booleanOptions.find((option) => option.value === String(category.option)),
        error: !!errors?.categoryOptions?.[index]?.option,
        insideModal: true,
        isClearable: true,
        options: booleanOptions,
        placeholder: t(`Select an action`),
        onChange: (selectedOption) => {
          if (selectedOption) {
            updateCategory(index, { ...category, option: selectedOption.value === 'true' })
          } else {
            updateCategory(index, { ...category, option: undefined })
          }
        },
      }

      return (
        <div key={index} className={`${rootClass}__row-option`}>
          <div>
            <SelectV2 {...categorySelectProps} />
            {(errors?.categoryOptions?.[index]?.categoryName || category.duplicated) && (
              <div className={`${rootClass}__error-name`}>
                <Svg name={SvgNames.errorSolid} type={SvgType.LARGER_ICON} />
                <Typography
                  text={
                    category.duplicated
                      ? t(`Error: Subscription category is missing or invalid`)
                      : t(errors?.categoryOptions?.[index]?.categoryName?.message)
                  }
                  dataTest={`${rootClass}__bottom-info-error-text`}
                  type={TextType.ERROR_NEW}
                  lineHeight={LineHeight.MEDIUM_SMALL}
                />
              </div>
            )}
          </div>
          <div>
            <SelectV2 {...optionSelectProps} />
          </div>
          <AddButton
            isDelete
            dataTest={`${rootClass}__delete-action`}
            onClick={() => removeCategory(index)}
            disabled={categoryOptions.length === 1}
          />
        </div>
      )
    })

  const renderCategorySelector = () => (
    <div>
      <LabelV2 label={t(`Subscription categories to update`)} labelType={LabelType.medium}></LabelV2>
      <FormGroup className={`${rootClass}__form-radius`}>
        {renderRowOptions()}
        <div className={`${rootClass}__add-category`}>
          <AddButton
            label={t(`Add another category`)}
            dataTest={`${rootClass}__add-category`}
            className={`${rootClass}__add-button`}
            onClick={addEmptyCategory}
          ></AddButton>
        </div>
      </FormGroup>
    </div>
  )

  const renderForm = () => (
    <form data-test={dataTest} onSubmit={handleSubmit(onSubmit)}>
      <FormRow>
        {(hasNonExistCategories || errors?.hasDuplicates) && renderErrorInfo()}
        <LabelV2 className={`${rootClass}__label-name`} label={t(`Step name`)} required labelType={LabelType.medium}></LabelV2>
        <InputV2 className={`${rootClass}__step-name`} name="stepName" handleValueChangeFromOuter register={register('stepName')} />
        {errors?.stepName && (
          <div className={`${rootClass}__error-name`}>
            <Svg name={SvgNames.errorSolid} type={SvgType.LARGER_ICON} />
            <Typography
              text={t(errors.stepName.message)}
              dataTest={`${rootClass}__bottom-info-error-text`}
              type={TextType.ERROR_NEW}
              lineHeight={LineHeight.MEDIUM_SMALL}
            />
          </div>
        )}
      </FormRow>
      <FormRow className={`${rootClass}__form-row`}>{renderCategorySelector()}</FormRow>
      <button type="submit" id={submitId} hidden />
    </form>
  )

  const renderOptionsView = () => {
    const categoryOptions = [
      ...step.subscribeCategories.map((categoryName: string) => ({ categoryName, option: true })),
      ...step.unsubscribeCategories.map((categoryName: string) => ({ categoryName, option: false })),
    ]

    return (
      <div>
        {categoryOptions.map((category, index) => {
          return (
            <div key={index} className={`${rootClass}__option-view`}>
              <Typography
                text={`Set {{category}} to {{option}}`}
                values={{ category: category.categoryName || '(undefined)', option: category.option }}
                tagProps={{
                  medium: { weight: TextWeight.MEDIUM },
                  italic: { weight: category.categoryName ? TextWeight.MEDIUM : TextWeight.ITALIC },
                }}
                className={`${rootClass}__row-option-view`}
              />
            </div>
          )
        })}
      </div>
    )
  }

  const renderView = () => (
    <FormRow>
      <Typography text={step.displayName} weight={TextWeight.MEDIUM} type={TextType.SECTION_HEADER} />
      <FormRow className={`${rootClass}__form-row`}>{renderOptionsView()}</FormRow>
    </FormRow>
  )

  return (
    <ModalBody className={classNames(rootClass, className)} data-test={dataTest}>
      {isRunning ? renderView() : renderForm()}
    </ModalBody>
  )
}

export default EditUpdateSubscriptionStep
