import React, { FC, useState } from 'react'

import classNames from 'classnames'

import { ApolloError } from '@apollo/client'
import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import Caution from '@components/Caution/Caution'
import Container from '@components/Container'
import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import TextBubble, { BackgroundColor } from '@components/TextBubble/TextBubble'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import WelcomeMessageEditorContainer from '@src/pages/sms/marketingListOptIns/components/WelcomeMessageEditor/WelcomeMessageEditorContainer'
import { MessageStatus } from '@src/pages/sms/marketingListOptIns/MarketingListOptIns.constants'

import './WelcomeMessage.css'

interface Props {
  className?: string
  dataTest?: string
  isAdmin: boolean
  message: string
  launchId: string
  status?: MessageStatus
  onSave: () => void
  loading: boolean
  error?: ApolloError
}

const rootClass = 'welcome-message'

const WelcomeMessage: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '', isAdmin, message, launchId, status, loading, onSave } = props
  const [isModalOpen, setIsOpenModal] = useState(false)
  const { t } = useTranslation()

  const closeModal = () => setIsOpenModal(false)

  const saveAndClose = () => {
    setIsOpenModal(false)
    onSave()
  }

  const cautionMessage = isAdmin
    ? [
        { span: `${t(`You have not personalized your message yet. Click`)}\u00A0` },
        { span: `${t('Edit Message')}\u00A0`, weight: TextWeight.BOLD },
        { span: t(`below to create a custom welcome. `) },
        { span: t('A default message will be sent until you personalize the welcome message.') },
      ]
    : undefined

  return loading ? (
    <Loader loaderType={LoaderTypes.row} />
  ) : (
    <Container
      className={classNames(rootClass, className)}
      data-test={dataTest}
      title={'Welcome Message'}
      description={"This message is automatically sent to a contact after they've opted in. Neat!"}
    >
      {isModalOpen && <WelcomeMessageEditorContainer closeModal={closeModal} onSave={saveAndClose} />}
      {message && status === MessageStatus.WELCOME_USER ? (
        <TextBubble text={message} smallerMargin={!isAdmin} className={`${rootClass}__text-bubble`} backgroundColor={BackgroundColor.TEAL} />
      ) : cautionMessage ? (
        <Caution textLines={cautionMessage} />
      ) : null}
      {message && !isAdmin && (
        <Typography
          text={t('*Contact your account administrator to make changes to the message text.')}
          type={TextType.BODY_TEXT_SMALL_LIGHT}
          className={`${rootClass}__text-info`}
        />
      )}
      {isAdmin && (
        <Button
          buttonType={ButtonType.TEXT}
          iconPosition={ButtonIconPosition.LEFT}
          className={`${rootClass}__buttons`}
          onClick={() => setIsOpenModal(true)}
        >
          <Svg name={SvgNames.pencil} type={SvgType.ICON} />
          {t('Edit Message')}
        </Button>
      )}
      {message && (
        <Button
          buttonType={ButtonType.TEXT}
          isLink
          to={`${rootContext}/sms/${launchId}/report/summary?welcome-message=true`}
          iconPosition={ButtonIconPosition.LEFT}
          className={`${rootClass}__buttons`}
        >
          <Svg name={SvgNames.reports} type={SvgType.ICON} />
          {t('View message report')}
        </Button>
      )}
    </Container>
  )
}

export default WelcomeMessage
