import React, { FC } from 'react'

import classNames from 'classnames'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import { TabItemData } from '@components/TabsAO/TabsAO'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@utils/const/globals'

import './DisabledTabWithTooltip.css'

export interface DisabledTabWithTooltipProps {
  icon?: string
  className?: string
  dataTest?: string
  item?: TabItemData
  tabsRef?: React.MutableRefObject<Record<string, HTMLButtonElement | null>>
  tooltipText: string
  svgName: SvgNames
  svgType: SvgType
  child?: React.ReactNode
}

const rootClass = 'disabled-tab_with-tooltip'

const DisabledTabWithTooltip: FC<DisabledTabWithTooltipProps> = (props: DisabledTabWithTooltipProps) => {
  const { className, dataTest = rootClass, tooltipText, svgName, svgType, child } = props
  const { t } = useTranslation()

  return (
    <div className={classNames(`${rootClass}__wrapper`, className)} data-test={dataTest}>
      <Tooltip
        trigger={
          <div className={`${rootClass}_container`}>
            <Typography
              text={child}
              type={TextType.BODY_TEXT_GRAY}
              className={`${rootClass}__tab-dot-label disabled_locked_tab`}
              dataTest={`${dataTest}-tab`}
              weight={TextWeight.MEDIUM}
            />
            <div className={`${rootClass}_icon`} data-test={`${dataTest}-tooltip-trigger`}>
              <Svg name={svgName} type={svgType} dataTest={`${dataTest}-icon`} fill={SvgColor.BLACK} />
            </div>
          </div>
        }
        position={'right'}
        dataTest={`${dataTest}-tooltip`}
      >
        {tooltipText || t('EmailComposer.Resend.LockedTab')}
      </Tooltip>
    </div>
  )
}

export default DisabledTabWithTooltip
