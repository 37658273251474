import React, { useContext, useMemo } from 'react'

import classNames from 'classnames'

import Accordion from '@components/Accordion/Accordion'
import Button, { ButtonType } from '@components/Button'
import InfoTooltip from '@components/InfoTooltip/InfoTooltip'
import Spinner, { LoaderSize } from '@components/Spinner/Spinner'
import Svg from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import AntiSpamValidations from '@src/pages/EmailComposer/components/common/AntiSpamValidations/AntiSpamValidations'
import ContentValidations from '@src/pages/EmailComposer/components/common/ContentValidations/ContentValidations'
import DeliverabilityValidations from '@src/pages/EmailComposer/components/common/DeliverabilityValidations/DeliverabilityValidations'
import { SpamCheckCard } from '@src/pages/EmailComposer/components/common/SpamCheckCard/SpamCheckCard'
import { ValidationSectionStatus } from '@src/pages/EmailComposer/components/common/ValidationsList/ValidationsList'
import { getStatusIcon } from '@src/pages/EmailComposer/components/EmailComposerPreview/EmailComposerPreview.utils'
import { useEmailValidations } from '@src/pages/EmailComposer/hooks/useEmailValidations'
import { CommonComposerTab } from '@utils/composer/commonComposer/CommonComposer.context'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { useTranslation } from '@utils/const/globals'

import './EmailComposerPreviewValidations.css'

const rootClass = 'email-composer-preview-validations'

const EmailComposerPreviewValidations = () => {
  const {
    values: {
      validations: { isValidationsLoading },
      messageConfiguration: {
        reviewAndSend: { disableEditings },
      },
    },
    api: { update },
  } = useContext(EmailComposerContext)

  const { t } = useTranslation()

  const {
    contentFailsCount,
    contentWarningCount,
    antiSpamFailsCount,
    antiSpamValidationsList,
    deliverabilityValidationsList,
    contentValidationsList,
    getContentStatus,
    getAntiSpamStatus,
    getDeliverabilityStatus,
  } = useEmailValidations()

  const contentContextCount =
    contentFailsCount === 1 && contentWarningCount === 1
      ? { context: getContentStatus(), count: 1 }
      : contentFailsCount === 1 && contentWarningCount !== 1
      ? { context: `${getContentStatus()}_fails`, count: 1 }
      : contentWarningCount === 1 && contentFailsCount > 1
      ? { context: `${getContentStatus()}_warnings`, count: 1 }
      : { context: getContentStatus() }

  const items = useMemo(
    () => [
      {
        isLoading: isValidationsLoading,
        name: t(`EmailComposer.Preview.Validations.Content.Title`),
        description: t(`EmailComposer.Preview.Validations.Content.Desc`, {
          failsCount: contentFailsCount,
          warningsCount: contentWarningCount,
          ...contentContextCount,
        }),
        status: getContentStatus(),
        content: <ContentValidations getValidations={contentValidationsList} />,
        hideOnPass: false,
      },
      {
        isLoading: isValidationsLoading,
        name: t(`EmailComposer.Preview.Validations.Anti.Spam.Title`),
        description: getAntiSpamStatus() === 'success' ? t(`EmailComposer.Preview.Validations.Anti.Spam.Desc`) : '',
        status: getAntiSpamStatus(),
        content: <AntiSpamValidations validations={antiSpamValidationsList} />,
        hideOnPass: false,
        disabled: getAntiSpamStatus() === 'success',
      },
      {
        isLoading: isValidationsLoading,
        name: t(`EmailComposer.Preview.Validations.Deliverability.Title`),
        description: t(`EmailComposer.Preview.Validations.Deliverability.Desc`),
        status: getDeliverabilityStatus(),
        content: <DeliverabilityValidations getValidations={deliverabilityValidationsList} />,
        hideOnPass: true,
      },
    ],
    [
      isValidationsLoading,
      t,
      getContentStatus,
      contentFailsCount,
      contentWarningCount,
      contentValidationsList,
      getAntiSpamStatus,
      antiSpamFailsCount,
      antiSpamValidationsList,
      getDeliverabilityStatus,
      deliverabilityValidationsList,
    ]
  )

  return (
    <div className={rootClass} data-test={rootClass}>
      <div className={`${rootClass}__header`}>
        <div className={`${rootClass}__header-title`}>
          <Typography
            text={t('EmailComposer.Preview.Validations.Title')}
            type={TextType.DATA_CARD_MODAL_HEADER}
            dataTest={`${rootClass}-validation-title-typography`}
          />
          <InfoTooltip minimalPadding={false} dataTest={`${rootClass}-info-tooltip`}>
            {t(`EmailComposer.Preview.Validations.Title.Tooltip`)}
          </InfoTooltip>
        </div>
        <Tooltip
          hide={!disableEditings}
          position={'left'}
          trigger={
            <Button
              buttonType={ButtonType.OUTLINE}
              onClick={() => update({ isPreview: false, tab: CommonComposerTab.DESIGN })}
              dataTest={`${rootClass}-edit-button`}
              disabled={disableEditings}
            >
              {t(`EmailComposer.Preview.Validations.Edit.Button`)}
            </Button>
          }
        >
          <Typography text={t('EmailComposer.Resend.Disabled')} type={TextType.BODY_TEXT_WHITE} />
        </Tooltip>
      </div>
      <div className={`${rootClass}__validations`}>
        {items.map((item) =>
          item.hideOnPass && [ValidationSectionStatus.REST, ValidationSectionStatus.SUCCESS].includes(item.status) ? null : (
            <Accordion
              key={item.name}
              className={classNames(`${rootClass}__accordion`)}
              type={'multiple'}
              childData={[
                {
                  header: (
                    <div className={`${rootClass}__accordion-header`}>
                      {item.isLoading ? <Spinner size={LoaderSize.MEDIUM} unsizeWrapper /> : <Svg name={getStatusIcon(item.status)} />}
                      <div className={`${rootClass}__accordion-header-content`}>
                        <Typography text={item.name} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} lineHeight={LineHeight.MEDIUM_LARGE} />
                        {!isValidationsLoading && (
                          <Typography
                            text={item.description}
                            type={TextType.BODY_TEXT_LIGHT}
                            tagProps={{ medium: { weight: TextWeight.MEDIUM } }}
                            inline
                          />
                        )}
                      </div>
                    </div>
                  ),
                  content: item.content,
                  index: item.name,
                  disabled: item.disabled,
                },
              ]}
            />
          )
        )}
      </div>
      <SpamCheckCard isInPreview />
    </div>
  )
}

export default EmailComposerPreviewValidations
