import { CampaignTableFilters, CampaignTableRowData } from '@src/pages/reports/revenueImpactNew/components/campaignTable/CampaignTable.types'
import { calculateSubRowsStats } from '@src/pages/reports/revenueImpactNew/components/campaignTable/utils/campaignTable.formattedData'

export const allowAllOptionsKey = 'allowAll'

export const CAMPAIGN_STATUSES_OPTIONS = new Map<string, string>([
  ['open', 'Open'],
  ['closed-won', 'Closed/Won'],
  ['closed-lost', 'Closed/Lost'],
])

const filterByStatus: (data: CampaignTableRowData[], statusFilter: string) => CampaignTableRowData[] = (data, statusFilter) => {
  const dataFilteredByStatus: CampaignTableRowData[] = []
  data.forEach((campaignRow) => {
    const subRows = campaignRow.subRows
      ? campaignRow.subRows
          .map((typeRow) => {
            const statusRows = typeRow.subRows ? typeRow.subRows.filter(({ status }) => status === statusFilter) : []
            return { ...typeRow, ...calculateSubRowsStats(statusRows), subRows: statusRows }
          })
          .filter((typeRow) => !!typeRow.subRows?.length)
      : []
    if (subRows.length) {
      dataFilteredByStatus.push({ ...campaignRow, ...calculateSubRowsStats(subRows), subRows })
    }
  })
  return dataFilteredByStatus
}

const deepSearchDataBySearchTerm: (row: CampaignTableRowData, term: string, parentPath?: string[]) => CampaignTableRowData[] = (
  row,
  term,
  parentPath
) => {
  const { name, status, subRows } = row
  const result: CampaignTableRowData[] = []

  const passedSearch = name?.toLowerCase().includes(term.toLowerCase()) || status?.toLowerCase().includes(term.toLowerCase())

  if (passedSearch) {
    result.push({ ...row, parentPath })
  }
  const displayLabel = name || status
  const childParentPath: string[] | undefined = parentPath
    ? displayLabel
      ? [...parentPath, displayLabel]
      : parentPath
    : displayLabel
    ? [displayLabel]
    : undefined

  if (subRows) {
    subRows.forEach((row) => {
      deepSearchDataBySearchTerm(row, term, childParentPath).forEach((r) => result.push(r))
    })
  }
  return result
}

const getFilteredData: (data: CampaignTableRowData[], filters: CampaignTableFilters, searchTerm: string) => CampaignTableRowData[] = (
  data,
  filters,
  term
) => {
  const { status, campaign } = filters
  const filteredByCampaign = !campaign || campaign === allowAllOptionsKey ? data : data.filter(({ name }) => name === campaign)
  const filterStatus = status ? CAMPAIGN_STATUSES_OPTIONS.get(status) : ''
  const filteredByStatus = filterStatus ? filterByStatus(filteredByCampaign, filterStatus) : filteredByCampaign

  if (!term) {
    return filteredByStatus
  }
  const dataFilteredByTerm: CampaignTableRowData[] = []
  filteredByStatus.forEach((row) => {
    deepSearchDataBySearchTerm(row, term).forEach((r) => dataFilteredByTerm.push(r))
  })
  return dataFilteredByTerm
}

export default getFilteredData
