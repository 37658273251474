import React, { FC, ReactNode, useState } from 'react'

import classNames from 'classnames'

import Container from '@components/Container/Container'
import DropDown, { DropDownType } from '@components/DropDown/DropDown'
import DropDownActions, { DropDownActionsType, MenuItem } from '@components/DropDownActions/DropDownActions'
import Modal, { ModalBody, ModalHeader } from '@components/Modal'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Svg from '@components/Svg'
import Typography, { ModalHeaderFormStyle, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import SvgNames from '../Svg/SvgNames'

import './DataCardsModal.css'

export interface DataCardsModalProps {
  className?: string
  dataTest?: string
  isOpen: boolean
  closeModal?: VoidFunction
  dropDownValue: string | null
  dropDownOptions: MenuItem[]
  chart?: ReactNode
  textFooter?: ReactNode
  textHeader?: ReactNode
  title?: string
}

interface State {
  showDropdown: boolean
}

const rootClass = 'data-cards-modal'

const DataCardsModal: FC<DataCardsModalProps> = (props: DataCardsModalProps) => {
  const {
    className = '',
    closeModal,
    dataTest = rootClass,
    dropDownValue,
    chart,
    dropDownOptions,
    isOpen = false,
    textFooter,
    textHeader,
    title,
  } = props
  const defaultState = {
    showDropdown: false,
  }

  const [state, setState] = useState<State>(defaultState)
  const { t } = useTranslation()
  const renderHeader = (
    <ModalHeader headerType={ModalHeaderType.Form} closeButton={closeModal} className={`${rootClass}__modal-title`}>
      <Typography text={t(title)} {...ModalHeaderFormStyle} />
      <div className={`${rootClass}__drop-down`}>
        <DropDown
          isOpen={state.showDropdown}
          toggleOpen={(open: boolean) => setState({ ...state, showDropdown: open })}
          type={DropDownType.STYLED}
          label={t(dropDownValue)}
        >
          <DropDownActions
            menuItems={dropDownOptions}
            type={DropDownActionsType.REGULAR}
            closeDropDown={() => setState({ ...state, showDropdown: !state.showDropdown })}
          />
        </DropDown>
      </div>
    </ModalHeader>
  )

  const renderTitle = (
    <>
      <div className={`${rootClass}__title`}>
        <Typography text={t('Overview')} type={TextType.DATA_CARD_MODAL_HEADER} />
        <div className={`${rootClass}__header-text`}>{textHeader}</div>
      </div>
    </>
  )

  const renderFooter = (
    <Container className={`${rootClass}__container`}>
      <Svg className={`${rootClass}__logo`} name={SvgNames.lightBulbIcon} />
      <div className={`${rootClass}__text-footer`}>{textFooter}</div>
    </Container>
  )

  return (
    <Modal className={classNames(rootClass, className)} dataTest={dataTest} isOpen={isOpen} header={renderHeader}>
      <ModalBody className={`${rootClass}__body`}>
        {renderTitle}
        {chart}
        {renderFooter}
      </ModalBody>
    </Modal>
  )
}

export default DataCardsModal
