import React, { FC, RefObject } from 'react'

import Modal, { ModalBody } from '@components/Modal'
import EmailSizeWarningModal from '@src/pages/EmailComposer/EmailModals/components/EmailSizeWarningModal/EmailSizeWarningModal'
import { ViewHtmlCodeModal } from '@src/pages/EmailComposer/EmailModals/components/ViewHtmlCodeModal/ViewHtmlCodeModal'
import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'
import { EmailPreviewPlatform } from '@utils/composer/context/EmailComposer.context'

import EmailComposerPreviewContent from './components/EmailComposerPreviewContent/EmailComposerPreviewContent'
import EmailComposerPreviewHeader from './components/EmailComposerPreviewHeader/EmailComposerPreviewHeader'
import EmailComposerPreviewInbox from './components/EmailComposerPreviewInbox/EmailComposerPreviewInbox'

export interface EmailComposerPreviewProps {
  previewFrameRef?: RefObject<HTMLIFrameElement>
  isCustomCodePreview?: boolean
}

const EmailComposerPreview: FC<EmailComposerPreviewProps> = ({ previewFrameRef }) => {
  const {
    values: {
      preview: { isCodeModalOpen, platform },
      showEmailTooLargeWarning,
      validations: {
        contentValidations: { htmlSize },
      },
    },
  } = useComposerContext()

  const isInbox = platform === EmailPreviewPlatform.INBOX

  return (
    <Modal isOpen fullScreen noPadding noScroll>
      <ModalBody>
        {showEmailTooLargeWarning && <EmailSizeWarningModal htmlSize={htmlSize} />}
        <EmailComposerPreviewHeader />
        {isInbox ? <EmailComposerPreviewInbox /> : <EmailComposerPreviewContent previewFrameRef={previewFrameRef} />}
        {isCodeModalOpen && <ViewHtmlCodeModal />}
      </ModalBody>
    </Modal>
  )
}

export default EmailComposerPreview
