import React, { FC, useEffect, useState } from 'react'

import ListPickerWrapper from '@complex/ListPickerModalV2/ListPickerWrapper'
import Button, { ButtonType } from '@components/Button'
import ConfirmationModal from '@components/ConfirmationModal'
import Container from '@components/Container/Container'
import EmptyList from '@components/EmptyList/EmptyList'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Typography, { TextType } from '@components/Typography/Typography'
import { legacyActonContext, useTranslation } from '@const/globals'
import { Program, ProgramSource, ProgramStepInput, ProgramTrackInput } from '@graphql/types/query-types'
import { getSourceIcon } from '@src/pages/programs/edit/components/ProgramEarlyExitConditions/ProgramEarlyExitConditions.utils'
import { hasAOContactsSource, hasLegacyListSource } from '@utils/program/program'

import './programListsAndSegments.css'

interface ProgramListsAndSegmentsProps {
  program: Program
  dataTest?: string
  isEditing: boolean
  updateProgram(program: Program): void
}

export interface State {
  addingSource: boolean
}

const rootClass = 'program-lists-and-segments'

const defaultAlertDetails = {
  show: false,
  text: '',
}

const ProgramListsAndSegments: FC<ProgramListsAndSegmentsProps> = (props: ProgramListsAndSegmentsProps) => {
  const { program, isEditing, dataTest = 'program-lists-and-segments' } = props
  const { sources } = program
  const { t } = useTranslation()
  const [state, setState] = useState<State>({
    addingSource: false,
  })

  const [alertDetails, setAlertDetails] = useState(defaultAlertDetails)

  const [selected, setSelected] = useState(() => program.sources.map((list) => list.id))
  useEffect(() => {
    setSelected(() => [...program.sources.map((list) => list.id)])
  }, [state.addingSource])

  const getStepsWithList = (sourceId: string) => {
    const stepsWithList: ProgramTrackInput[] = []
    const steps = program.tracks.flatMap((track) => track.steps)

    steps.forEach((currentStep: ProgramStepInput) => {
      if (currentStep) {
        if (currentStep.listId === sourceId || currentStep.srcId === sourceId) {
          stepsWithList.push(currentStep as ProgramTrackInput)
        } else if (currentStep.sendChoices) {
          if (currentStep.sendChoices.find((choice) => choice.srcId === sourceId)) {
            stepsWithList.push(currentStep as ProgramTrackInput)
          }
        }

        if (currentStep.conditions) {
          if (currentStep.conditions.find((choice) => choice.srcId === sourceId)) {
            stepsWithList.push(currentStep as ProgramTrackInput)
          }
        }
      }
    })

    return stepsWithList
  }

  const getStepDetails = (stepsWithList: ProgramTrackInput[]) => {
    return stepsWithList.map((currentStep) => `${currentStep.stepType} ${currentStep.letter}`).join(', ')
  }

  const removeSource = (sourceId: string, i: number) => {
    const sourceList = program.sourceList.find((sourceList) => sourceList.id === sourceId)

    if (sourceList) {
      setAlertDetails({
        show: true,
        text: `${sourceList.name} ${t(
          ' is currently a Program Source List. The list must be removed from the "General Settings" tab before it can be removed from the program.'
        )}`,
      })
      return
    }

    const stepsWithList = getStepsWithList(sourceId)
    if (stepsWithList.length > 0) {
      setAlertDetails({
        show: true,
        text: `${t(`This list is currently in use by the following program steps: `)} ${getStepDetails(stepsWithList)}${t(
          `. Uses of this list must be removed before it can be deleted from the program.`
        )}`,
      })
      return
    }

    const exitList = program.exit.exitChoices.find((exitChoice) => exitChoice.srcId === sourceId)
    if (exitList) {
      const list = program.sources.find((src) => src.id === exitList.srcId)
      const listName = list?.name ?? 'List'
      setAlertDetails({
        show: true,
        text: `${listName} ${t(
          ' is currently a Program Early Exit List. The list must be removed from the "Early Exits" tab before it can be removed from the program.'
        )}`,
      })
      return
    }

    const updatedSources = [...sources.slice(0, i), ...sources.slice(i + 1)]
    props.updateProgram({
      ...props.program,
      sources: updatedSources,
    })
  }

  const openSource = (id: string) => {
    window.open(`${legacyActonContext}/lists/viewList.jsp?id=${id}`, 'previewSource', 'width=1000,height=600')
  }

  const onProgramSourcesUpdate = (programSources: ProgramSource[]) => {
    const newSources = programSources.reduce((acc: ProgramSource[], cur) => {
      return [...acc, cur]
    }, [])
    props.updateProgram({
      ...props.program,
      sources: newSources,
    })
    setState({
      ...state,
      addingSource: false,
    })
  }

  const containerDescription = (
    <>
      <Typography
        type={TextType.BODY_TEXT_LIGHT}
        text={t(
          'This is where lists & segments associated with this program are shown. This includes lists & segments that this program writes to, as well as any lists or segments used in program conditional logic.'
        )}
      />
      <Typography
        type={TextType.BODY_TEXT_LIGHT}
        text={t('You can set them up here ahead of time, and refer to them while setting up the program flow.')}
      />
    </>
  )

  const onCloseAlert = () => setAlertDetails(defaultAlertDetails)

  return (
    <>
      {alertDetails.show && <ConfirmationModal isOpen body={alertDetails.text} closeModal={onCloseAlert} title={t('List cannot be removed')} />}
      {state.addingSource && (
        <ListPickerWrapper
          programSources={program.sources}
          dataTest={`${dataTest}-source-picker-modal`}
          isOpen={state.addingSource}
          defaultSelectedLists={selected}
          onClose={() => {
            setState({
              ...state,
              addingSource: false,
            })
          }}
          disableLegacyLists={hasAOContactsSource(program)}
          disableUnifiedLists={hasLegacyListSource(program)}
          onProgramSourcesUpdate={onProgramSourcesUpdate}
          restrictMixingLegacyListsAndUCL
        />
      )}
      <div className={`${rootClass}`}>
        <Container noBottom title={'Lists and Segments'} description={containerDescription}>
          {sources.length === 0 && <EmptyList message={t('No Lists or Segments have been added yet.')} />}
          {sources.length > 0 && <div className={`${rootClass}__source-top`}></div>}
          {sources.map((source, i) => (
            <div key={i} className={`${rootClass}__source-row`}>
              <div
                role={'button'}
                tabIndex={0}
                onKeyDown={(keyDownEvent) => (keyDownEvent.key === ' ' ? openSource(source.id) : undefined)}
                className={`${rootClass}__source-row--subject`}
                onClick={() => openSource(source.id)}
              >
                <button onClick={() => openSource(source.id)} data-test={`${dataTest}-preview-source-${i}`} type="button" title={t('Preview List')}>
                  <Svg name={SvgNames[getSourceIcon(source.id)]} type={SvgType.ICON} />
                </button>
                <span className={`${rootClass}__source-row--subject-name`}>{source.name}</span>
              </div>
              <Button
                onClick={() => openSource(source.id)}
                dataTest={`${dataTest}-preview-source-menu-${i}`}
                buttonType={ButtonType.FLOAT}
                title={t('Open List')}
                className={`${rootClass}__row-button`}
              >
                <Svg name={SvgNames.show} type={SvgType.ICON} />
                {t('Open')}
              </Button>
              {isEditing && (
                <Button
                  onClick={() => removeSource(source.id, i)}
                  dataTest={`${dataTest}-delete-source-${i}`}
                  buttonType={ButtonType.REMOVE}
                  title={t('Remove List')}
                  className={`${rootClass}__row-button`}
                >
                  <Svg name={SvgNames.delete} type={SvgType.ICON} />
                  {t('Remove')}
                </Button>
              )}
            </div>
          ))}
          {isEditing && (
            <div className={`${rootClass}__list-button`}>
              <Button
                dataTest={`${dataTest}-add-source`}
                buttonType={ButtonType.FLOAT}
                title={t('Add List')}
                onClick={() => {
                  setState({ ...state, addingSource: true })
                }}
              >
                <Svg name={SvgNames.plus} type={SvgType.ICON} />
                {t('Add List or Segment')}
              </Button>
            </div>
          )}
        </Container>
      </div>
    </>
  )
}

export default ProgramListsAndSegments
