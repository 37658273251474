import React from 'react'

import { TFunction } from 'i18next'

import { AssetPickerInfoType } from '@complex/AssetPickerModal/Components/Info/AssetPickerInfoContainer'
import { CustomSourceItems, ExtendedItemDto, ItemDtoRow } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { CellContext } from '@tanstack/react-table'
import { CRMSourceOption } from '@utils/crm.utils'
import { FilterDefinition, FilterTypes } from '@utils/filter'

const rootClass = 'crm-sources-picker-modal'

const SALESFORCE_SOURCES_LIMIT = 5000

export const getInfoBanner = (items: CustomSourceItems, _filterActive: FilterDefinition, t: TFunction, crmSourceOption: CRMSourceOption) => {
  const getWarning = () => {
    const type = `${crmSourceOption.title}s`
    if (items[type] && items[type].length >= SALESFORCE_SOURCES_LIMIT) {
      return {
        values: {
          text: <Typography text={t('AssetPicker.AddContactsFromCRM.Salesforce.Warning')} tagProps={{ bold: { weight: TextWeight.BOLD } }} inline />,
          title: `All ${crmSourceOption.title}s`,
          type: AssetPickerInfoType.WARNING,
        },
      }
    }
  }

  return {
    [FilterTypes.RECOMMENDED]: {
      values: {
        text: 'AssetPicker.AddContactsFromCRM.Recommended.Text',
        title: 'AssetPicker.AddContactsFromCRM.Recommended.Title',
        type: AssetPickerInfoType.DEFAULT,
      },
    },
    [FilterTypes.ALL_SALESFORCE_CAMPAIGNS]: getWarning(),
    [FilterTypes.ALL_SALESFORCE_SAVED_REPORTS]: getWarning(),
  }
}

const renderItemNameVal = (original: ExtendedItemDto, search: string, _rootClass: string, disabled?: boolean) => {
  const { name: itemName, id } = original
  const overridingProps = !!disabled ? { type: TextType.BODY_TEXT_LIGHT } : {}
  const trigger = search ? renderBoldTextOnMatch(itemName ?? '', search, true, overridingProps) : itemName
  return (
    <Tooltip trigger={<div className={'ellip'}>{trigger}</div>} align={'start'} inline={false} alignArrowToStart>
      {`${id} - ${itemName}`}
    </Tooltip>
  )
}

export const renderTableColumns = (mainColumnName: string, search = ''): ColumnDefWithAdditionalProps<ItemDtoRow, any>[] => [
  {
    header: mainColumnName,
    accessorKey: 'name',
    textAlign: 'left',
    minSize: 600,
    maxSize: 600,
    disableSorting: true,
    cell: (cell: CellContext<ItemDtoRow, string>) => renderItemNameVal(cell.row.original, search, rootClass, cell.row.original?.disabled),
  },
]
