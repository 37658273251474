import { Dispatch, SetStateAction } from 'react'
import { Row } from 'react-table'

import { DEFAULT_PAGE_NUMBER, getBouncesUtils } from '@complex/ListPickerModalV2/utils/clients/ListPickerModalCategorizationClientUtils'
import { GetAllItemsRequestType } from '@complex/ListPickerModalV2/utils/graphql/ListPickerModalCategorizationRequests.graphQL'
import {
  GetFavoritesListsRequestType,
  GetFolderListsByTypeRequestType,
  GetFolderListsRequestType,
  GetFoldersWithCountRequestType,
  ListPollRequestType,
  SearchLegacyListsByIdsRequestType,
  SearchListsRequestType,
} from '@complex/ListPickerModalV2/utils/graphql/ListPickerModalClassicRequests.graphQL'
import {
  DisableRowByCriteria,
  ListPickerModalState,
  PreProcessedList,
  SearchResultType,
  SearchResultTypes,
} from '@complex/ListPickerModalV2/utils/interfaces/ListPickerModalInterfaces'
import {
  getSearchListsBasedOnListCategory,
  buildLegacyLists,
  buildListCategoriesRows,
  buildLists,
  getArrayWithSubRows,
  getLegacyListsOnSearch,
  loadFavoritesSuccess,
  loadFoldersSuccess,
  loadListsSuccess,
  loadSearchSuccess,
  showFailStatusToast,
} from '@complex/ListPickerModalV2/utils/ListPickerModalUtils'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { AccountSettings, FolderListInfo, ListInfo, SearchListsInfo } from '@graphql/types/query-types'
import { Folder } from '@interface/Folder'
import { availableListsFilter, favoriteFilter } from '@utils/filter'
import { ListCategory } from '@utils/lists'
import { logNewRelicError } from '@utils/new-relic.utils'

interface SegmentIdParam {
  segmentId: string
}

interface SetStateParam {
  setState: Dispatch<SetStateAction<ListPickerModalState>>
}

interface ListTypeParam {
  listType: ListCategory
}

export interface ClientUtilsBaseParams extends SetStateParam, ListTypeParam {
  disabledLists: string[]
  disableLists: boolean
  isListsOnly: boolean
}

export const getUnifiedListUtils = ({ setState }: SetStateParam, getFoldersWithCountRequest: GetFoldersWithCountRequestType) =>
  getFoldersWithCountRequest({ listType: ListCategory.UNIFIED_LIST })
    .then(({ data }) => {
      if (!!data) {
        setState((state) => ({ ...state, unifiedListData: data }))
      }
    })
    .catch(() => showFailStatusToast(setState, 'An unexpected error occurred while fetching Unified List data.'))

export const getFoldersWithCountUtils = (
  { listType, setState, disabledLists, disableLists, isListsOnly }: ClientUtilsBaseParams,
  getFoldersWithCountRequest: GetFoldersWithCountRequestType,
  disableRowByCriteria: DisableRowByCriteria | undefined
) =>
  getFoldersWithCountRequest({ listType })
    .then(({ data }) => {
      if (!!data) {
        loadFoldersSuccess(setState, data, disabledLists, disableLists, isListsOnly, disableRowByCriteria)
      }
    })
    .catch(() => showFailStatusToast(setState, 'An unexpected error occurred while fetching folders.'))

export const checkAndSetFavoritesLists = async (
  { listType, setState, disabledLists, disableLists, isListsOnly }: ClientUtilsBaseParams,
  getFavoritesListsRequest: GetFavoritesListsRequestType,
  disabledRowByCriteria?: DisableRowByCriteria
) => {
  try {
    const { data } = await getFavoritesListsRequest({ listType })
    if (!!data?.getFavoritesLists && data.getFavoritesLists.length > 0) {
      setState((state) => ({ ...state, currentFilter: favoriteFilter, loading: false }))
      loadFavoritesSuccess(setState, data, disabledLists, disableLists, isListsOnly, disabledRowByCriteria)
      return true
    }
    return false
  } catch (e) {
    showFailStatusToast(setState, 'An unexpected error occurred while fetching favorite lists.')
    logNewRelicError(e)
    return false
  }
}

export const getFavoritesListsUtils = (
  { listType, setState, disabledLists, disableLists, isListsOnly }: ClientUtilsBaseParams,
  getFavoritesListsRequest: GetFavoritesListsRequestType,
  disabledRowByCriteria?: DisableRowByCriteria
) => {
  getFavoritesListsRequest({ listType })
    .then(({ data }) => {
      if (!!data) {
        loadFavoritesSuccess(setState, data, disabledLists, disableLists, isListsOnly, disabledRowByCriteria)
      }
    })
    .catch(() => showFailStatusToast(setState, 'An unexpected error occurred while fetching favorite lists.'))
}

export const getMainFilterListUtils = (
  { listType, setState, disabledLists, disableLists, isListsOnly }: ClientUtilsBaseParams,
  pageNumber: number,
  getFolderListsByTypeRequest: GetFolderListsByTypeRequestType,
  disableRowByCriteria: DisableRowByCriteria | undefined
) =>
  getFolderListsByTypeRequest({ listType, pageNumber })
    .then(({ data }) => {
      if (!!data) {
        loadListsSuccess(setState, data.getFolderListsByType, pageNumber, disabledLists, disableLists, isListsOnly, disableRowByCriteria)
      }
    })
    .catch(() => showFailStatusToast(setState, 'An unexpected error occurred while fetching lists.'))

export const getFoldersListUtils = (
  { listType, setState, disabledLists, disableLists, isListsOnly }: ClientUtilsBaseParams,
  currentFolder: Folder | undefined,
  getFolderListsRequest: GetFolderListsRequestType,
  disableRowByCriteria: DisableRowByCriteria | undefined
) =>
  getFolderListsRequest({ folder: currentFolder, listType })
    .then(({ data }) => {
      if (!!data) {
        loadListsSuccess(setState, data.folderLists, DEFAULT_PAGE_NUMBER, disabledLists, disableLists, isListsOnly, disableRowByCriteria)
      }
    })
    .catch(() => showFailStatusToast(setState, 'An unexpected error occurred while fetching folder lists.'))

export const searchRestrictedLegacyLists = (
  searchLegacyListsByIdsRequest: SearchLegacyListsByIdsRequestType,
  setState: Dispatch<SetStateAction<ListPickerModalState>>,
  defaultSelectedLists: string[],
  listsIds: string[],
  disableRowByCriteria: DisableRowByCriteria | undefined,
  isLegacyForms = false
) => {
  const listsString = listsIds.join(',')
  searchLegacyListsByIdsRequest({ listsString })
    .then(({ data }) => {
      if (!!data) {
        const selectedLists = buildListCategoriesRows(data.searchListsByIds as SearchListsInfo, defaultSelectedLists, disableRowByCriteria, true)
        let listType: ListCategory
        const total = Object.values(selectedLists).reduce((total, curr) => total + curr.length, 0)
        if (!!total) {
          listType = Object.entries(selectedLists).filter((entry) => !!entry[1].length)[0][0] as ListCategory
        } else {
          const restrictedLists = buildListCategoriesRows(data.searchListsByIds as SearchListsInfo, listsIds, disableRowByCriteria, true)
          listType = Object.entries(restrictedLists).filter((entry) => !!entry[1].length)[0][0] as ListCategory
        }
        const builtLists = getSearchListsBasedOnListCategory(data.searchListsByIds as SearchListsInfo, disableRowByCriteria, true)[listType]
        setState((state: ListPickerModalState) => ({
          ...state,
          allLoaded: true,
          currentFilter: availableListsFilter,
          isLegacyForms,
          listType,
          listsState: {
            lists: builtLists,
            listsLoading: false,
          },
          selectedLists,
          lazyLoading: false,
          loading: false,
        }))
      }
    })
    .catch((error) => {
      logNewRelicError(error)
      showFailStatusToast(setState, 'An unexpected error occurred while searching lists.')
    })
}

export const searchDefaultSelectedListsUtils = (
  searchLegacyListsByIdsRequest: SearchLegacyListsByIdsRequestType,
  getAllItemsRequest: GetAllItemsRequestType,
  setState: Dispatch<SetStateAction<ListPickerModalState>>,
  defaultSelectedListsIds: string[],
  listType: ListCategory,
  disableRowByCriteria: DisableRowByCriteria | undefined,
  accountSettings: AccountSettings
) => {
  const listsString = defaultSelectedListsIds.join(',')
  searchLegacyListsByIdsRequest({ listsString })
    .then(({ data }) => {
      if (!!data) {
        let selectedLists = buildListCategoriesRows(data.searchListsByIds as SearchListsInfo, defaultSelectedListsIds, disableRowByCriteria)
        getBouncesUtils(getAllItemsRequest, setState, accountSettings)
          .then((bounces) => {
            const selectedBounces = buildLists(bounces as ItemDto[], listType)?.filter(({ id }) => defaultSelectedListsIds.includes(id))
            const bouncesRows = selectedBounces.map((bounce) => ({ original: bounce } as unknown as Row))
            selectedLists = {
              ...selectedLists,
              [ListCategory.UNIFIED_LIST]: [...selectedLists[ListCategory.UNIFIED_LIST], ...bouncesRows],
            }
          })
          .finally(() =>
            setState((state: ListPickerModalState) => {
              const defaultSelectedList = Object.keys(selectedLists).find((key) => selectedLists[key as ListCategory].length > 0) as ListCategory
              if (!!defaultSelectedList) {
                return {
                  ...state,
                  selectedLists,
                  listType: defaultSelectedList,
                  isLegacyForms:
                    defaultSelectedList === ListCategory.FORM_SUBMISSIONS
                      ? true
                      : defaultSelectedList === ListCategory.FORM_SUBMISSIONS_UCL
                      ? false
                      : state.isLegacyForms,
                  preSelectedListsInit: true,
                }
              }
              return { ...state, selectedLists, preSelectedListsInit: true }
            })
          )
      }
    })
    .catch(() => showFailStatusToast(setState, 'An unexpected error occurred while searching default selected lists.'))
}

export const searchListsUtils = async (
  { setState, disabledLists, isListsOnly }: ClientUtilsBaseParams,
  state: ListPickerModalState,
  searchAll = false,
  restrictMixingLegacyListsAndUCL: boolean,
  searchListsRequest: SearchListsRequestType,
  disableRowByCriteria: DisableRowByCriteria | undefined
) => {
  try {
    const { data } = await searchListsRequest({ search: state.search })
    if (data) {
      loadSearchSuccess(setState, state, data, searchAll, disabledLists, isListsOnly, restrictMixingLegacyListsAndUCL, disableRowByCriteria)
    }
  } catch (e) {
    logNewRelicError(e)
    showFailStatusToast(setState, 'An unexpected error occurred while searching lists.')
  }
}

export const searchLegacyListsUtils = async (
  { setState, disabledLists }: ClientUtilsBaseParams,
  state: ListPickerModalState,
  restrictMixingLegacyListsAndUCL: boolean,
  searchListsRequest: SearchListsRequestType,
  disableRowByCriteria: DisableRowByCriteria | undefined
) => {
  try {
    const { data } = await searchListsRequest({ search: state.search })
    if (data) {
      return getLegacyListsOnSearch(state, data, disabledLists, restrictMixingLegacyListsAndUCL, disableRowByCriteria)
    }
  } catch (e) {
    logNewRelicError(e)
    showFailStatusToast(setState, 'An unexpected error occurred while searching lists.')
    return {}
  }
}

interface SetClassicSegmentAncestorsTreeUtilsParams extends SetStateParam, ListTypeParam {
  segmentId: string
  searchLegacyListsByIdsRequest: SearchLegacyListsByIdsRequestType
  disableRowByCriteria: DisableRowByCriteria | undefined
}

export const setClassicSegmentAncestorsTreeUtils = ({
  segmentId,
  setState,
  listType,
  searchLegacyListsByIdsRequest,
  disableRowByCriteria,
}: SetClassicSegmentAncestorsTreeUtilsParams) =>
  searchLegacyListsByIdsRequest({ listsString: segmentId })
    .then(({ data }) =>
      setState((state) => ({
        ...state,
        segmentTreePage: {
          ...state.segmentTreePage,
          segmentAncestors: getArrayWithSubRows(
            (listType === ListCategory.ACCOUNTS
              ? data?.searchListsByIds[SearchResultType[listType as SearchResultTypes]]
              : data?.searchListsByIds[SearchResultType[listType as SearchResultTypes]]?.flatMap((list) => (list as FolderListInfo).entries)
            )?.map((list) => buildLegacyLists(list as ListInfo, [], false, disableRowByCriteria)) as PreProcessedList[]
          ),
        },
      }))
    )
    .catch((e) => logNewRelicError(e))

interface SetClassicSegmentAncestorsTreeUtilsParams extends SegmentIdParam, SetStateParam, ListTypeParam {
  searchLegacyListsByIdsRequest: SearchLegacyListsByIdsRequestType
}

interface SetClassicSegmentChildrenTreeUtilsParams extends SegmentIdParam, SetStateParam, ListTypeParam {
  listPollRequest: ListPollRequestType
  disableRowByCriteria: DisableRowByCriteria | undefined
}

export const setClassicSegmentChildrenTreeUtils = ({
  segmentId,
  setState,
  listPollRequest,
  disableRowByCriteria,
}: SetClassicSegmentChildrenTreeUtilsParams) =>
  listPollRequest({ segmentId })
    .then(({ data }) =>
      setState((state) => ({
        ...state,
        segmentTreePage: {
          ...state.segmentTreePage,
          segmentChildren: data?.listPoll.map((list) =>
            buildLegacyLists(list as ListInfo, state.disabledLists, state.disableUnifiedLists || state.disableLegacyLists, disableRowByCriteria)
          ),
        },
      }))
    )
    .catch((e) => logNewRelicError(e))
