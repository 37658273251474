import React from 'react'

import { TFunction } from 'i18next'

import { renderDefaultNameColumn } from '@complex/AssetPickerModal/Components/Table/AssetPickerTable.utils'
import { ItemDtoRow } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import Tooltip from '@components/Tooltip/Tooltip'
import { PagePickerUi } from '@graphql/types/query-types'

const rootClass = 'web-page-picker-modal'

const mediumColumnWidth = {
  minSize: 300,
  maxSize: 300,
}

const largeColumnWidth = {
  minSize: 427,
  maxSize: 427,
}

const renderItemNameVal = (itemName: string, search: string, rootClass: string) => {
  const trigger = search ? renderBoldTextOnMatch(itemName, search) : itemName

  return (
    <div className={`${rootClass}__item-name ellip`}>
      <div className={`${rootClass}__item-name-typography`}>
        <Tooltip alwaysShowOnHover trigger={trigger} minimalPadding ellipOnTrigger>
          {itemName}
        </Tooltip>
      </div>
    </div>
  )
}

export const renderWebPageTableColumns = (t: TFunction): ColumnDefWithAdditionalProps<ItemDtoRow, any>[] => [
  {
    ...renderDefaultNameColumn(t('Web page name'), '', rootClass, (row) =>
      renderItemNameVal((row as unknown as PagePickerUi).name ?? '', '', rootClass)
    ),
    ...mediumColumnWidth,
  },
  {
    id: 'url',
    header: t('Web page url'),
    accessorKey: 'url',
    textAlign: 'left',
    cell: ({ row: { original } }) => renderItemNameVal((original as unknown as PagePickerUi).url ?? '', '', rootClass),
    ...largeColumnWidth,
  },
]

export const renderWebPageSearchTableColumns = (
  search: string,
  _folders: FolderData[],
  t: TFunction
): ColumnDefWithAdditionalProps<ItemDtoRow, any>[] => [
  {
    ...renderDefaultNameColumn(t('Web page name'), search, rootClass, (row) =>
      renderItemNameVal((row as unknown as PagePickerUi).name ?? '', search, rootClass)
    ),
    ...mediumColumnWidth,
  },
  {
    id: 'url',
    header: t('Web page url'),
    accessorKey: 'url',
    textAlign: 'left',
    cell: ({ row: { original } }) => {
      return renderItemNameVal((original as unknown as PagePickerUi).url ?? '', search, rootClass)
    },
    ...largeColumnWidth,
  },
]

export const renderDomainTableColumns = (t: TFunction): ColumnDefWithAdditionalProps<ItemDtoRow, any>[] => [
  renderDefaultNameColumn(t('Domain filter'), '', rootClass, (row) => {
    return renderItemNameVal((row as unknown as PagePickerUi).name ?? '', '', rootClass)
  }),
]

export const renderDomainSearchTableColumns = (
  search: string,
  _folders: FolderData[],
  t: TFunction
): ColumnDefWithAdditionalProps<ItemDtoRow, any>[] => [
  renderDefaultNameColumn(t('Domain filter'), search, rootClass, (row) =>
    renderItemNameVal((row as unknown as PagePickerUi).name ?? '', search, rootClass)
  ),
]
