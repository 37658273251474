import { gql } from '@apollo/client'

export default gql`
  query getWelcomeSendMessageDetails($messageId: Float!) {
    getWelcomeSendMessageDetails(messageId: $messageId) {
      messageText
      messageTitle
      createdBy
      createdOn
      contactLists {
        listName
        listType
      }
      noPhoneNumbers
      duplicates
      messagesSent
      notOptIns
      billableCredits
      recipientsPerCreditMultiplier {
        creditMultiplier
        contacts
        contactsPerCountry {
          country
          contacts
        }
      }
      creditsCharged
      suppressedInternationalContacts
    }
  }
`
