import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import { Align } from '@radix-ui/react-popper'

import Button, { ButtonProps, ButtonType } from '../Button/Button'
import Tooltip from '../Tooltip/Tooltip'

interface Props extends Omit<ButtonProps, 'buttonType'> {
  title?: string
  align?: Align
  buttonType?: ButtonType
  children: ReactNode
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  hideTooltip?: boolean
  dataTest?: string
  className?: string
}

const rootClass = 'tooltip-button'

const TooltipButton: FC<Props> = (props: Props) => {
  const {
    onClick,
    title,
    buttonType = ButtonType.ICON,
    align = 'center',
    dataTest = 'button',
    className,
    hideTooltip = false,
    ...buttonProps
  } = props

  return (
    <Tooltip
      className={`${rootClass}__tooltip`}
      align={align}
      trigger={
        <Button
          {...buttonProps}
          dataTest={dataTest}
          buttonType={buttonType}
          onClick={onClick}
          className={classNames(`${rootClass} __table-actions__search-container__button`, className)}
        >
          {props.children}
        </Button>
      }
      position={'top'}
      hide={hideTooltip}
    >
      {title}
    </Tooltip>
  )
}

export default TooltipButton
