import { gql } from '@apollo/client'

export default gql`
  query getSummaryByLaunchId($launchId: String!, $accountId: Long!) {
    getSummaryByLaunchId(launchId: $launchId, accountId: $accountId) {
      smsAveRate {
        aveClickRate
        aveDeliveryRate
        aveOptOutRate
        aveReplyRate
      }
      smsFailedDetails {
        failedReasonToCount
        failedTotal
      }
      smsTotalCount {
        clicked
        replied
        sent
      }
      smsUniqueCount {
        clicked
        delivered
        failed
        optedOut
        replied
        sent
      }
    }
  }
`
