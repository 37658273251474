import { alertsFiltersMock } from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertFilters/utils/AlertFilters.mock'
import { recipientsListMock } from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertRecipients/components/RecipientsList/utils/RecipientsList.mock'
import { Alert } from '@src/pages/WebsiteProspectorAlerts/WebsiteProspectorAlertsContext'

export const WebsiteProspectorAlertsMock: Alert[] = [
  {
    id: 0,
    name: 'Acme Corp',
    description:
      'These are the weekly leads from Acme Corp. This is a really important account for us, so please follow up on these leads as soon as you can.',
    recipients: [recipientsListMock[0], recipientsListMock[2], recipientsListMock[4]],
    schedule: 'Wednesdays at 8:00 AM',
    filters: alertsFiltersMock,
    owner: '0',
    ownerName: '',
    lastModified: 'Nov 2, 2022',
    enabled: false,
    muted: false,
  },
  {
    id: 1,
    name: 'Bethany’s Leads',
    description: 'My leads and stuff',
    recipients: [],
    schedule: 'Mondays at 8:00 AM',
    filters: [alertsFiltersMock[1], alertsFiltersMock[2]],
    owner: '2',
    ownerName: 'Bethany McNeil',
    lastModified: 'Feb 15, 2021',
    enabled: true,
    muted: false,
  },
  {
    id: 2,
    name: 'Needs Attention',
    description: 'These known marketing prospects need attention. Please follow up on these leads and opportunities ASAP!',
    recipients: recipientsListMock,
    schedule: 'Immediately',
    filters: alertsFiltersMock,
    owner: '6',
    ownerName: 'Skylar Culhane',
    lastModified: 'Jun 30, 2021',
    enabled: true,
    muted: false,
  },
  {
    id: 3,
    name: 'Most Qualified Leads',
    description: 'Super qualified leads',
    recipients: [recipientsListMock[5], recipientsListMock[6]],
    schedule: 'Immediately',
    filters: [],
    owner: '6',
    ownerName: 'Skylar Culhane',
    lastModified: 'Jan 4, 2023',
    enabled: true,
    muted: true,
  },
]
