import React, { FC, useRef } from 'react'
import { connect } from 'react-redux'

import { bindActionCreators, compose } from 'redux'

import { useGetImages, useImageLibraryFolders, useUpdateViewType } from '@components/AssetPickers/ImagePickerModal/graphQL/ImagePickerModal.graphQL'
import ImagePickerModal from '@components/AssetPickers/ImagePickerModal/ImagePickerModal'
import {
  DynamicImageModalStates,
  ImagePickerModalInsertCallback,
  ImagePickerView,
} from '@components/AssetPickers/ImagePickerModal/utils/ImagePickerModal.utils'
import { SplitButtonOptionsProps } from '@components/SplitButton/SplitButton'
import { useAccountSettings } from '@utils/account/account.utils'
import { AccountActions, loadAccount } from '@utils/account/actions'
import { ReactSetStateAction } from '@utils/interface/common'
import mapStateToProps from '@utils/navigation/state/mapStateToProps'

export interface ImagePickerModalContainerProps {
  onImageInsert: ImagePickerModalInsertCallback
  headerActions?: SplitButtonOptionsProps[]
  urlIdToReplace?: string
  isFromLogos?: boolean
  isStory?: boolean
  showInsertButtonEnabled?: boolean
  showImagePickerBackButton?: boolean
  setDynamicImageModalStates?: ReactSetStateAction<DynamicImageModalStates>
  imageMaxSize?: number
  accept?: string
  tooltipText?: string
  allowSvg?: boolean
  isLandingPage?: boolean
}

interface AllProps extends ImagePickerModalContainerProps {
  loadAccount: AccountActions['loadAccount']
}

const ImagePickerModalContainer: FC<AllProps> = ({ loadAccount, ...props }) => {
  const { imagePickerViewType } = useAccountSettings()
  const viewTypeRef = useRef<ImagePickerView>(imagePickerViewType === ImagePickerView.GRID ? ImagePickerView.GRID : ImagePickerView.LIST)
  const { folders, loading: loadingFolders } = useImageLibraryFolders(props.isLandingPage)
  const { getImages, getLogos, getImageById, getLogoById } = useGetImages()
  useUpdateViewType(viewTypeRef, imagePickerViewType, loadAccount)

  return (
    <ImagePickerModal
      {...props}
      folders={folders}
      loadingFolders={loadingFolders}
      onGetImages={getImages}
      onGetLogos={getLogos}
      onGetImageById={getImageById}
      onGetLogoById={getLogoById}
      viewTypeRef={viewTypeRef}
    />
  )
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({ loadAccount }, dispatch)

export default compose<React.ComponentType<ImagePickerModalContainerProps>>(connect(mapStateToProps, mapDispatchToProps))(ImagePickerModalContainer)
