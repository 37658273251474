import React, { FC, useEffect, useState } from 'react'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import manageProfile from '@graphql/mutations/manageProfile'
import loadOverviewPage from '@graphql/queries/loadOverviewPage'
import { MutationManageProfileArgs } from '@graphql/types/mutation-types'
import { OverviewResponse } from '@graphql/types/query-types'
import { StatusToastType } from '@interface/StatusToast'
import { logNewRelicError } from '@utils/new-relic.utils'

import OverviewVM from './models/overview.view-model'
import Overview from './Overview'

interface OverviewContainerProps {
  setToastStatus: (value: StatusToastType) => void
}

const OverviewContainer: FC<OverviewContainerProps> = (props: OverviewContainerProps) => {
  const { setToastStatus } = props
  const [overviewData, setOverviewData] = useState<OverviewVM | undefined>()

  const client = useApolloClient()
  const { data, loading, error, refetch } = useQuery<{ loadOverviewPage: OverviewResponse }>(loadOverviewPage, {
    client,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!loading && data?.loadOverviewPage) {
      setOverviewData(new OverviewVM(data?.loadOverviewPage))
    }
    if (error) {
      logNewRelicError(error)
      setToastStatus({
        showStatus: true,
        statusMessage: 'Something went wrong on our end.',
        successStatus: false,
      })
    }
  }, [loading, error, data])

  const [saveProfileOverviewData] = useMutation<{ manageProfile: boolean }, MutationManageProfileArgs>(manageProfile, {
    client,
    fetchPolicy: 'no-cache',
  })

  return (
    <Overview
      overviewData={overviewData}
      onSave={(req) => saveProfileOverviewData({ variables: req })}
      onRefresh={() => refetch()}
      loading={loading}
      setToastStatus={setToastStatus}
    />
  )
}

export default OverviewContainer
