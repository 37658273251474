import { SvgNames } from '@components/Svg'

export enum ACTIVITY_STATUS {
  VIEWED = 'Viewed',
  SUBMITTED = 'Submitted',
  REGISTERED = 'Registered',
  ATTENDED = 'Attended',
}

export const StatusIcons: { [key: string]: SvgNames } = {
  [ACTIVITY_STATUS.VIEWED]: SvgNames.formViewed,
  [ACTIVITY_STATUS.SUBMITTED]: SvgNames.formSubmitted,
  [ACTIVITY_STATUS.REGISTERED]: SvgNames.webinarRegistered,
  [ACTIVITY_STATUS.ATTENDED]: SvgNames.webinarAttended,
}

export const activityStatus: { [key: string]: ACTIVITY_STATUS } = {
  Viewed: ACTIVITY_STATUS.VIEWED,
  Submitted: ACTIVITY_STATUS.SUBMITTED,
  Registered: ACTIVITY_STATUS.REGISTERED,
  Attended: ACTIVITY_STATUS.ATTENDED,
}

export const activityPreviewTooltip: { [key: string]: string } = {
  [ACTIVITY_STATUS.VIEWED]: 'Preview form',
  [ACTIVITY_STATUS.SUBMITTED]: 'Preview submission',
  [ACTIVITY_STATUS.REGISTERED]: 'Preview webinar form',
  [ACTIVITY_STATUS.ATTENDED]: 'Preview webinar form',
}

export const ActivityPreviewIcon: { [key: string]: SvgNames } = {
  [ACTIVITY_STATUS.VIEWED]: SvgNames.previewOn,
  [ACTIVITY_STATUS.SUBMITTED]: SvgNames.previewOn,
  [ACTIVITY_STATUS.REGISTERED]: SvgNames.zoom,
  [ACTIVITY_STATUS.ATTENDED]: SvgNames.zoom,
}
