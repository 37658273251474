import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IngestFieldMapping, UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import {
  ColumnType,
  DynamicListItems,
  DynamicListRowData,
  ExtraInfo,
  Metadata,
  RowType,
} from '@src/pages/datamanagement/components/CrmContacts/components/DynamicList/utils/DynamicList.interfaces'
import { populatePreDefinedMapping, updatePushPolicyOnRowChange } from '@src/pages/datamanagement/components/CrmContacts/CrmContactUtil'
import { UseCRMContactsRowHandlerProps } from '@src/pages/datamanagement/components/CrmContacts/utils/useCRMContactsRowHandler/useCRMContactsRowHandler'
import { CRMContactsAddRowFunction } from '@src/pages/datamanagement/components/CrmContacts/utils/useCRMContactsRowHandler/useCRMContactsRowHandler.add'
import { DataManagementContext } from '@src/pages/datamanagement/context/DataManagementContext'
import { RowHistoryState } from '@src/pages/datamanagement/utils/DataManagement.constants'
import { TableType } from '@utils/crm.utils'
import useCRM from '@utils/hooks/useCRM'

export type CRMContactsUpdateRowFunction = (rowId: string, columnName: string, value: string, extraInfo?: ExtraInfo, metadata?: Metadata) => void

type UseCRMContactsUpdateRowHandlerFunction = {
  updateRow: CRMContactsUpdateRowFunction
}

type UseCRMContactsUpdateRowHandlerProps = Pick<
  UseCRMContactsRowHandlerProps,
  | 'baseRow'
  | 'setUpdatedRow'
  | 'dataLoaded'
  | 'crmDef'
  | 'lookupFieldMappingData'
  | 'lookupFieldMappingLoading'
  | 'setDataLoaded'
  | 'checkRowFieldMappings'
  | 'createScoreSheetRow'
> & { addRow: CRMContactsAddRowFunction }

export const useCRMContactsUpdateRowHandler = (props: UseCRMContactsUpdateRowHandlerProps): UseCRMContactsUpdateRowHandlerFunction => {
  const {
    addRow,
    baseRow,
    setUpdatedRow,
    dataLoaded,
    crmDef,
    lookupFieldMappingData,
    lookupFieldMappingLoading,
    setDataLoaded,
    checkRowFieldMappings,
    createScoreSheetRow,
  } = props
  const {
    userUpdates,
    update,
    values: { fieldMappings, fieldMappingStatus, pushPolicy, unifiedListFieldMappings, rowHistoryStatus },
  } = useContext(DataManagementContext)

  const { t } = useTranslation()

  const { connectorType } = useCRM()

  const [maxRow, setMaxRow] = useState<number>(1000)
  const [dataTypeMap, setDataTypeMap] = useState(new Map())

  const lookupBaseRowValue = (selectedString: string, colName: string) => {
    const column = baseRow.custom.columns.filter((field) => field.column === colName)
    if (column && column.length > 0) {
      const lookupValue = column[0].values?.filter((field) => field.key === selectedString)
      if (lookupValue && lookupValue.length > 0) {
        return { type: lookupValue[0].dataType, label: lookupValue[0].display }
      }
    }

    return {}
  }

  const getAlreadyDeletedRow = (columnName: string, value: string): UnifiedListFieldMapping[] => {
    let alreadyDeletedRow: UnifiedListFieldMapping[] = []
    if (columnName === t('ACT-ON CONTACTS')) {
      alreadyDeletedRow = unifiedListFieldMappings?.filter(
        (entry) => entry?.standardFieldKey === value && entry?.deleted
      ) as UnifiedListFieldMapping[]
    } else {
      const crmMapping = crmDef.crmToStdMapping?.filter((entityMap) => entityMap.entityTypeName === columnName)
      if (crmMapping && crmMapping?.length > 0) {
        const stdFieldName = crmMapping[0].crmToStandardFieldMapList
          ?.filter((mapping) => mapping?.crmName === value)
          .map((mapping) => mapping?.standardName)

        if (stdFieldName && stdFieldName.length > 0) {
          alreadyDeletedRow = unifiedListFieldMappings?.filter(
            (entry) => entry?.standardFieldKey === stdFieldName[0] && entry?.deleted
          ) as UnifiedListFieldMapping[]
        }
      }
    }
    return alreadyDeletedRow
  }

  // figure out data type for selected item
  const getSelectedItemDatatype = (selectedItem: string) => {
    const item = dataLoaded.stdFields.filter(
      (field) => field.key?.toLowerCase() === selectedItem.toLowerCase() || field.display.toLowerCase() === selectedItem.toLowerCase()
    )
    return item[0] ? item[0].dataType : 'unknown'
  }

  // update our local copy
  const updateDisplay = (
    rowIdInt: number,
    columnName: string,
    value: string,
    ingestFieldMappings: IngestFieldMapping[],
    stdFieldKey?: string,
    extraInfo?: ExtraInfo,
    metadata?: Metadata,
    displayNameUpdate?: IngestFieldMapping
  ) => {
    let rowMap: DynamicListRowData[] = []

    if (extraInfo?.tableType === TableType.STANDARD) {
      rowMap = dataLoaded.standardRows
    } else {
      rowMap = dataLoaded.customRows
    }

    let newRowId = -1
    const selectPairs: Record<string, string> = {}

    let typeList: (string | undefined)[] = []
    let updatedRows = rowMap.reduce((acc: DynamicListRowData[], row) => {
      if (row.id === rowIdInt) {
        const newLocalRow: DynamicListRowData = [] as unknown as DynamicListRowData
        newLocalRow.key = row.key
        newLocalRow.type = row.type
        newLocalRow.metadata = metadata

        if (rowIdInt === -1) {
          newLocalRow.id = maxRow
          setMaxRow(maxRow + 1)
        } else {
          newLocalRow.id = rowIdInt
        }

        newRowId = newLocalRow.id

        newLocalRow.columns = row.columns.reduce((acc: DynamicListItems[], savedColumn) => {
          if (savedColumn.column === columnName) {
            savedColumn.selected = value
          }

          if (savedColumn.column === 'ACT-ON CONTACTS' && (savedColumn.selected || savedColumn.staticValue)) {
            if (extraInfo && extraInfo.tableType === TableType.CUSTOM) {
              selectPairs['edit'] = savedColumn.selected ?? savedColumn.staticValue ?? value
            } else {
              if (savedColumn.selected === '-1' && stdFieldKey) {
                selectPairs[t('ACT-ON CONTACTS')] = stdFieldKey
                savedColumn.selected = stdFieldKey
              } else if (savedColumn.staticValue) {
                selectPairs[t('static')] = savedColumn.staticValue
              } else {
                selectPairs[t('ACT-ON CONTACTS')] = savedColumn.selected ?? ''
              }
            }
          }

          ingestFieldMappings.forEach(({ objectType = '', mapping }) => {
            if (objectType === savedColumn.column && savedColumn.selected === '-1') {
              const displayCol = savedColumn.values?.filter((val) => val.key === mapping)
              if (displayCol && displayCol.length > 0) {
                savedColumn.selected = displayCol[0].key
                selectPairs[t(objectType)] = displayCol[0].key ?? '-1'
              }
            } else if (objectType === savedColumn.column && savedColumn.selected && savedColumn.selected !== '-1') {
              selectPairs[t(objectType)] = savedColumn.selected
            }
          })

          return [...acc, savedColumn]
        }, [])

        // set row icon
        // get list of data types
        typeList = row.columns.reduce((acc: (string | undefined)[], column) => {
          if (column.type === ColumnType.OPTION) {
            if (column.column === t('DATA TYPE') && column.selected) {
              selectPairs[t('DATA TYPE')] = column.selected
              return [...acc, column.selected]
            }
            if (column.selected && column.values) {
              const selectedItem = column.values.filter((item) => item.key === column.selected)
              // required row? if so, only 1 pair is needed - its ok to leave a column empty
              if (row.type === RowType.REQUIRED) {
                if (selectedItem.length > 0 && selectedItem[0].dataType) {
                  return [...acc, selectedItem[0].dataType]
                } else {
                  return [...acc]
                }
              }
              // if nothing is selected the mapping is invalid
              else if (column.selected === '-1') {
                return [...acc, 'unknown']
              } else {
                if (selectedItem.length > 0) {
                  return [...acc, selectedItem[0].dataType]
                } else {
                  return [...acc, getSelectedItemDatatype(column.selected)]
                }
              }
            } else {
              return [...acc]
            }
          } else if (column.type === ColumnType.STATIC) {
            return [...acc, column.dataType]
          } else {
            return [...acc]
          }
        }, [])

        return [...acc, newLocalRow]
      } else {
        return [...acc, row]
      }
    }, [])

    if ((rowIdInt === -1 || rowIdInt === newRowId) && extraInfo && updatedRows.filter((row) => row.id === newRowId).length > 0) {
      const arrayLocation = updatedRows.findIndex((row) => row.id === rowIdInt)
      let rowType = updatedRows
        .filter((row) => row.id === rowIdInt)
        .map((row) => row.type)
        .pop()
      updatedRows = updatedRows.filter((row) => row.id !== newRowId && row.id !== -1)
      if (extraInfo.tableType === TableType.CUSTOM) {
        rowType = RowType.CUSTOM
      }

      if (arrayLocation === -1) {
        const updatedRow = addRow(rowType, newRowId, selectPairs)
        if (displayNameUpdate) {
          const lookupValues = lookupBaseRowValue(displayNameUpdate.mapping ?? '', displayNameUpdate.objectType ?? '')

          // find matching entry in fieldMappings
          const storedEntry = fieldMappings.filter((item) => item.columnIndex === newRowId)
          updatedRow.columns = updatedRow.columns.map((column) => {
            if (column.type === ColumnType.EDIT) {
              column.staticValue = lookupValues.label ?? ''
              if (storedEntry.length > 0) {
                storedEntry[0].displayName = column.staticValue
              }
            } else if (column.type === ColumnType.OPTION && column.column === t('DATA TYPE') && dataTypeMap) {
              column.selected = dataTypeMap.get(lookupValues.type)
              typeList[typeList.length - 1] = dataTypeMap.get(lookupValues.type)
              if (storedEntry.length > 0) {
                storedEntry[0].dataType = typeList[typeList.length - 1]
              }
            }
            return column
          })
        }
        updatedRows.push(updatedRow)
      } else {
        if (rowType === RowType.REQUIRED) {
          // we need to get the datatype from the static field so it will be added to the updated row
          const originalRow = rowMap.filter((row) => row.id === rowIdInt).pop()
          const originalDatatype = originalRow?.columns.filter((column) => column.type === ColumnType.STATIC).pop()
          updatedRows.splice(arrayLocation, 0, addRow(rowType, newRowId, selectPairs, originalDatatype?.dataType, 0, metadata))
        } else {
          updatedRows.splice(arrayLocation, 0, addRow(rowType, newRowId, selectPairs, '', 0, metadata))
        }
      }

      updatedRows.push(addRow(extraInfo.rowType))
    }

    if (typeList.length > 0) {
      // save the row id so we know what to update when the field mappings check call returns
      if (rowIdInt === -1) {
        setUpdatedRow({ rowId: maxRow, tableType: extraInfo?.tableType })
        rowHistoryStatus.set(maxRow, RowHistoryState.NEW)
      } else {
        setUpdatedRow({ rowId: rowIdInt, tableType: extraInfo?.tableType })
      }

      // check the row against itself for field compatibility
      const standardField = typeList.splice(-1, 1)
      checkRowFieldMappings({ variables: { crmfields: typeList, aofield: standardField[0] ?? 'unknown' } })
    }

    // insert temp row for score type?
    if (columnName === t('DATA TYPE') && value === t('SCORE')) {
      updatedRows = createScoreSheetRow(rowIdInt === -1 ? newRowId : rowIdInt, updatedRows)
    }

    if (extraInfo?.tableType === TableType.STANDARD) {
      setDataLoaded({
        ...dataLoaded,
        done: true,
        standardRows: [...updatedRows],
      })
    } else {
      setDataLoaded({
        ...dataLoaded,
        done: true,
        customRows: [...updatedRows],
      })
    }
  }

  const updateRow = (rowId: string, columnName: string, value: string, extraInfo?: ExtraInfo, metadata?: Metadata) => {
    let rowIdInt = parseInt(rowId)
    let newRow: UnifiedListFieldMapping = {} as UnifiedListFieldMapping

    // check if a row has been previously deleted
    let undeleteRow = false

    const alreadyDeletedRow: UnifiedListFieldMapping[] = getAlreadyDeletedRow(columnName, value)
    if (alreadyDeletedRow.length === 1) {
      rowIdInt = alreadyDeletedRow[0].columnIndex
      undeleteRow = true
      const rowIndex = parseInt(rowId)

      if (rowIndex === -1) {
        const stdRow = dataLoaded.standardRows.filter((entry) => entry.id === rowIndex)
        if (stdRow.length > 0) {
          stdRow[0].id = rowIdInt
        }
      } else if (rowIndex >= 1000) {
        const localRow = fieldMappings.filter((entry) => entry?.columnIndex === rowIndex)
        const stdRow = dataLoaded.standardRows.filter((entry) => entry.id === rowIndex)
        if (stdRow.length > 0) {
          stdRow[0].id = rowIdInt
        }

        if (localRow.length > 0) {
          localRow[0].columnIndex = rowIdInt
        }
      }
    }

    // check local copy first
    const localRow = fieldMappings.filter((entry) => entry?.columnIndex === rowIdInt)
    if (localRow.length > 0) {
      newRow = { ...localRow[0] }
    } else {
      // find the matching entry in unifiedFieldsListMapping
      const row = unifiedListFieldMappings?.filter((entry) => entry?.columnIndex === rowIdInt)

      if (row.length > 0) {
        // make a copy so we can edit it
        newRow = { ...row[0] }
      }
      if (undeleteRow) {
        newRow.deleted = false
      }
    }

    // if we change any column in a non-temp row we have to preserve metadata
    if (!metadata && newRow.typeMetaData) {
      metadata = JSON.parse(newRow.typeMetaData)
    }

    // check score sheet meta data
    if (columnName === t('ACT-ON CONTACTS') || columnName === t('DATA TYPE')) {
      if (value !== t('SCORE') && value !== t('Act-On Primary Score') && metadata?.scoreSheetId) {
        metadata = {
          ...metadata,
          scoreSheetId: undefined,
        }
      } else if ((value === t('SCORE') || value === t('Act-On Primary Score')) && (!metadata || !metadata?.scoreSheetId)) {
        metadata = {
          ...metadata,
          scoreSheetId: 'ss-default',
        }
      }
    }

    const previousDisplayName = newRow?.displayName

    // if a select has changed
    // right hand column
    if (columnName === t('ACT-ON CONTACTS')) {
      if (extraInfo?.tableType === TableType.STANDARD) {
        // get values from dataLoaded.stdFields
        const standardField = dataLoaded.stdFields.filter((row) => row.key === value)
        if (standardField) {
          newRow.displayName = standardField[0].display
          newRow.dataType = standardField[0].dataType
          newRow.standardFieldKey = standardField[0].key
        }
      } else {
        newRow.displayName = value
      }
    } else if (columnName === t('DATA TYPE')) {
      newRow.dataType = value

      // check score sheet meta data
      if (value !== t('SCORE') && metadata?.scoreSheetId) {
        metadata = {
          ...metadata,
          scoreSheetId: undefined,
        }
      } else if (value === t('SCORE') && (!metadata || !metadata?.scoreSheetId)) {
        metadata = {
          ...metadata,
          scoreSheetId: 'ss-default',
        }
      }
    } else {
      // left hand select has changed
      if (newRow.ingestFieldMappings && newRow.ingestFieldMappings?.find((entry) => entry?.objectType === columnName)) {
        newRow.ingestFieldMappings = newRow.ingestFieldMappings.map((entry) => {
          if (entry && entry.objectType === columnName) {
            const newEntry = { ...entry }
            newEntry.mapping = value
            return newEntry
          } else {
            return entry
          }
        })
      } else {
        // no preexisting mappings
        const newMapping: IngestFieldMapping = { mapping: value, source: 'CRM', objectType: columnName, __typename: 'IngestFieldMapping' }
        if (!newRow.ingestFieldMappings || newRow.ingestFieldMappings.length == 0) {
          newRow.ingestFieldMappings = [newMapping]
        } else {
          newRow.ingestFieldMappings = Object.assign([], newRow.ingestFieldMappings)
          newRow.ingestFieldMappings.push(newMapping)
        }
      }
    }

    // new row?
    if (rowIdInt === -1) {
      newRow.columnIndex = maxRow
    }

    let displayNameUpdate

    if (extraInfo?.tableType === TableType.STANDARD && extraInfo?.rowType !== RowType.REQUIRED) {
      const workingRow = dataLoaded.standardRows.filter((row) => row.id === rowIdInt)
      populatePreDefinedMapping(newRow, crmDef, dataLoaded.stdFields, connectorType, baseRow.standard.columns, workingRow[0])
    } else if (extraInfo?.tableType === TableType.CUSTOM && rowIdInt === -1 && newRow.ingestFieldMappings) {
      displayNameUpdate = newRow.ingestFieldMappings[0]
    }

    // metadata
    newRow.typeMetaData = JSON.stringify(metadata)

    // insert it into our array to-be-written
    const entryLoc = fieldMappings.findIndex((item) => item.columnIndex === rowIdInt)

    if (entryLoc === -1) {
      fieldMappings.push(newRow)
    } else {
      fieldMappings[entryLoc] = newRow
    }

    const ingestMappings: IngestFieldMapping[] =
      newRow?.ingestFieldMappings?.map((mapping) => {
        const ingestMap: IngestFieldMapping = {
          __typename: 'IngestFieldMapping',
          format: mapping?.format ?? '',
          mapping: mapping?.mapping ?? '',
          objectType: mapping?.objectType ?? '',
          source: mapping?.source ?? '',
        }
        return ingestMap
      }) || []

    updateDisplay(rowIdInt, columnName, value, ingestMappings, newRow.standardFieldKey, extraInfo, metadata, displayNameUpdate)

    // check fieldmappings list for spurious entry
    const rowIndex = parseInt(rowId)
    if (rowIdInt !== -1 && rowIndex >= 1000) {
      fieldMappingStatus.delete(rowIndex)
    }

    if (pushPolicy) {
      updatePushPolicyOnRowChange(pushPolicy, t, update, columnName, value, newRow, previousDisplayName)
    }

    // make the 'save' drop down happen
    userUpdates('updateRow', true)
  }

  useEffect(() => {
    if (!lookupFieldMappingLoading && lookupFieldMappingData) {
      setDataTypeMap((curDataMap) => {
        Object.entries(lookupFieldMappingData).forEach((val) => {
          val.forEach((val2) => {
            if (val2 !== 'getFieldMapping') {
              Object.entries(val2).forEach((val) => {
                curDataMap.set(val[0], val[1])
              })
            }
          })
        })
        return curDataMap
      })
    }
  }, [lookupFieldMappingLoading])

  return { updateRow }
}
