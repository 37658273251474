import { useEffect, useState } from 'react'

import { ISpecialLink } from '@beefree.io/sdk/dist/types/bee'
import { useEmailComposerRequests } from '@utils/composer/emailComposer/GraphQL/EmailComposerRequests.graphQL'
import { AssetLinkTypes } from '@utils/composer/EmailModal.constants'
import { useTranslation } from '@utils/const/globals'
import { logNewRelicError } from '@utils/new-relic.utils'

export type OptOutLinkType = 'STANDARD' | 'PREFERENCES' | 'DIRECT'

export const useOptOutLinks = (): { [key in OptOutLinkType]: ISpecialLink } | undefined => {
  const { getOptOutLinksRequest } = useEmailComposerRequests()
  const { t } = useTranslation()

  const [links, setLinks] = useState<Record<OptOutLinkType, string> | undefined>({ STANDARD: '', PREFERENCES: '', DIRECT: '' })

  useEffect(() => {
    getOptOutLinksRequest()
      .then((response) => {
        const data = response.data?.getOptOutLinks
        if (data) {
          setLinks({
            STANDARD: data.standard,
            PREFERENCES: data.preferences,
            DIRECT: data.direct,
          })
        } else {
          setLinks(undefined)
        }
      })
      .catch((e) => {
        logNewRelicError(e, 'Error fetching opt-out links')
        setLinks(undefined)
      })
  }, [])

  if (!links) {
    return undefined
  }

  return {
    STANDARD: {
      label: t('EmailComposer.AssetLinks.OptOut.STANDARD'),
      link: links.STANDARD,
      type: AssetLinkTypes.OPT_OUT,
    },
    PREFERENCES: {
      link: links.PREFERENCES,
      label: t('EmailComposer.AssetLinks.OptOut.PREFERENCES'),
      type: AssetLinkTypes.OPT_OUT,
    },
    DIRECT: {
      link: links.DIRECT,
      label: t('EmailComposer.AssetLinks.OptOut.DIRECT'),
      type: AssetLinkTypes.OPT_OUT,
    },
  }
}
