import React, { FC, ReactNode, useEffect, useState } from 'react'

import classNames from 'classnames'

import Svg, { SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'
import { NO_COLOR } from '@utils/tags'

import SvgNames from '../Svg/SvgNames'

import './ColorPicker.css'

import Button, { ButtonType } from '@components/Button/Button'

interface Props {
  className?: string
  dataTest?: string
  colors: Color[]
  pickColor: (color: string) => void
  selectedColor?: string
  trigger: ReactNode
}

export type Color = {
  color: string
  name: string
}

const rootClass = 'color-picker'

const ColorPicker: FC<Props> = (props: Props) => {
  const { colors, pickColor, selectedColor = NO_COLOR, trigger, className, dataTest = rootClass } = props
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (open) {
      document.addEventListener('click', detectClickOutside)
    }
    return () => document.removeEventListener('click', detectClickOutside)
  }, [open])

  const colorPicked = (color: string) => {
    pickColor(color)
    setOpen(!open)
  }

  const detectClickOutside = (e: any) => {
    const clickBox = document.getElementById(rootClass)
    if ((!clickBox || !clickBox?.contains(e.target)) && open) {
      setOpen(!open)
    }
  }

  return (
    <div id={rootClass} className={classNames(rootClass, className)} data-test={dataTest}>
      <Tooltip
        position={'top'}
        trigger={
          <Button buttonType={ButtonType.ICON} dataTest={`${dataTest}-trigger`} onClick={() => setOpen(!open)} className={`${rootClass}__trigger`}>
            {trigger}
          </Button>
        }
        triggerClassName={classNames({ [`${rootClass}__trigger-open`]: open })}
      >
        {t('Choose color')}
      </Tooltip>
      {open && (
        <div className={`${rootClass}__colors`} data-test={`${dataTest}-colors`}>
          {colors.map(({ color, name }) => {
            const noColor = color === NO_COLOR
            const isSelected = selectedColor === color
            return (
              <Tooltip
                key={color}
                position={'top'}
                trigger={
                  <button className={isSelected ? `${rootClass}__color-wrapper` : ''} onClick={() => colorPicked(color)}>
                    <div
                      className={classNames(`${rootClass}__color`, { [`${rootClass}__no-color`]: noColor })}
                      data-test={`${dataTest}-color-${name}`}
                      style={{ backgroundColor: noColor ? '#FFF' : color }}
                    >
                      {isSelected && (
                        <Svg className={`${rootClass}__color-selected${noColor ? '-no-color' : ''}`} name={SvgNames.check} type={SvgType.ICON} />
                      )}
                    </div>
                  </button>
                }
              >
                {t(name)}
              </Tooltip>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default ColorPicker
