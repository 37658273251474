export enum ChangeFieldRules {
  CLEAR_VALUE = 'CLEAR',
  DECREMENT_BY = 'DECR',
  INCREMENT_BY = 'INCR',
  SET_BEHAVIORAL_SCORE = 'SCORE',
  SET_CAMPAIGN_SCORE = 'CAMPAIGN',
  SET_CURRENT_DATE = 'CURRENT_DATE',
  SET_TIMEZONE = 'TIMEZONE',
  SET_VALUE = 'CONST',
  UNIQUE_NUMBER = 'UID',
}

export const ChangeFieldRulesLabels: { [key in ChangeFieldRules]: string } = {
  [ChangeFieldRules.CLEAR_VALUE]: 'Clear the value',
  [ChangeFieldRules.DECREMENT_BY]: 'Decrement value by',
  [ChangeFieldRules.INCREMENT_BY]: 'Increment value by',
  [ChangeFieldRules.SET_BEHAVIORAL_SCORE]: 'Set value to behavioral score',
  [ChangeFieldRules.SET_CAMPAIGN_SCORE]: 'Set value to campaign score',
  [ChangeFieldRules.SET_CURRENT_DATE]: 'Set field to current date',
  [ChangeFieldRules.SET_TIMEZONE]: 'Set value to time zone',
  [ChangeFieldRules.SET_VALUE]: 'Set value to',
  [ChangeFieldRules.UNIQUE_NUMBER]: 'Assign unique number in list',
}
