import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import AutoSavingStatus, { SavingMessage } from '@components/AutoSavingStatus/AutoSavingStatus'
import Loader from '@components/Loader/Loader'
import PageContainer from '@components/PageContainer'
import PageHeader from '@components/PageHeader'
import { PageHeaderType } from '@components/PageHeadline/PageHeadline'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import StatusToast from '@components/StatusToast/StatusToast'
import Tabs, { TabItemData } from '@components/TabsAO/TabsAO'
import { useTranslation } from '@const/globals'

import CreditsSummary from './components/CreditsSummary/CreditsSummary'
import MessageComposer from './components/MessageComposer/MessageComposer'
import MessageOverview from './components/MessageOverview/MessageOverview'
import MessagePreview from './components/MessagePreview/MessagePreview'
import Recipients from './components/Recipients/Recipients'
import RecipientsSummary from './components/RecipientsSummary/RecipientsSummary'
import SendabilityContainer from './components/Sendability/SendabilityContainer'
import { SMSContext } from '../context/SMSContext'

import './editSMS.css'

const rootClass = 'edit-sms'

interface EditSMSProps {
  isNew: boolean
  loading?: boolean
}

export interface State {
  tab: string
  canSend: boolean
  deliveryTime: number
  showError: boolean
}

interface ListingState {
  tab: string
}

const EditSMS: FC<EditSMSProps> = ({ isNew, loading = false }: EditSMSProps) => {
  const context = useContext(SMSContext)
  const { values, canSend, updater } = context
  const location = useLocation()
  const locationState: ListingState = location.state as ListingState

  const history = useHistory()
  const { t } = useTranslation()
  const [state, setState] = useState<State>({
    tab: locationState?.tab || isNew ? 'Recipients' : 'Message',
    canSend: false,
    deliveryTime: 0,
    showError: false,
  })
  const recipientsRef = useRef<HTMLDivElement>(null)
  const messageRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setState({
      ...state,
      showError: values.errors.addRecipientsError,
      canSend: canSend(),
    })
  }, [values])

  const closeError = () => {
    updater('errors', {})
  }

  const recipientsContent = (
    <div className={`${rootClass}__main-container`} ref={recipientsRef}>
      <div className={`${rootClass}__left-container`}>
        <MessageOverview context={context} />
        <Recipients context={context} />
      </div>
      <div className={`${rootClass}__right-container`}>
        <RecipientsSummary context={context} />
      </div>
    </div>
  )

  const messageContent = (
    <div className={`${rootClass}__main-container`} ref={messageRef}>
      <div className={`${rootClass}__left-container`}>
        <MessageComposer context={context} />
        <MessagePreview context={context} />
      </div>
      <div className={`${rootClass}__right-container`}>
        <CreditsSummary context={context} />
        <SendabilityContainer context={context} />
      </div>
    </div>
  )

  const tabsData = useMemo<TabItemData[]>(
    () => [
      { index: 'Recipients', label: t('Recipients'), content: recipientsContent },
      { index: 'Message', label: t('Message'), content: messageContent },
    ],
    [t, context, messageRef, recipientsRef]
  )

  const onTabChange = (tab: string) => {
    const locationState = { ...(history.location.state as Object), tab: tab }
    history.replace(location.pathname, locationState)
    setState({
      ...state,
      tab,
    })
  }

  return (
    <PageContainer className={rootClass}>
      {loading && <Loader center />}
      {state.showError && (
        <StatusToast
          isSuccess={false}
          message={t('Unable to add the selected recipients. Only contact lists with a mobile number field can be used.')}
          closeStatus={closeError}
        />
      )}
      {!loading && (
        <PositionContainer>
          <PageHeader
            linkBack
            primaryText={values.smsMessage.title ? values.smsMessage.title : t('New Message')}
            leftContent
            headlineType={PageHeaderType.SUB_HEADER}
            dataTest={rootClass}
          >
            {values.savingMessage !== SavingMessage.NONE && <AutoSavingStatus savingStatus={values.savingMessage} />}
          </PageHeader>
          <Tabs
            childData={tabsData}
            defaultValue={state.tab}
            onChange={(tab) => {
              setTimeout(() => onTabChange(tab), 20)
            }}
          />
        </PositionContainer>
      )}
    </PageContainer>
  )
}

export default EditSMS
