import Cookies from 'js-cookie'

import { logError } from '@utils/env'
import windowUtils from '@utils/window'

import { CurrentToggleConfig } from './currentToggleConfig'
import { State } from './NewFeatureToggle'
import newFeatureToggleService from './service'

const errorOccurred = (state: State, setState: (state: State) => void) => {
  windowUtils.showAlert('Sorry, but we are currently having issues turning off this feature')
  setState({
    ...state,
    isOn: true,
    loading: false,
  })
}

export async function toggleFeature(currentToggle: CurrentToggleConfig, state: State, setState: (state: State) => void) {
  if (state.loading) {
    try {
      const ts = new Date().getTime()
      Cookies.set('labsChange', `${ts}`, { path: '/' })
      const results = await newFeatureToggleService.toggleLabsFeature(currentToggle.feature, state.isOn, ts)
      Cookies.remove('labsChange', { path: '/' })
      if (results.status === 'ok') {
        windowUtils.redirectToClassic()
      } else {
        errorOccurred(state, setState)
      }
    } catch (e) {
      logError(e)
      errorOccurred(state, setState)
    }
  }
}

export default {
  toggleFeature,
}
