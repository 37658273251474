import React, { useContext, useEffect, useRef } from 'react'

import { t } from 'i18next'

import HTMLMediaCard from '@components/HTMLMediaCard/HTMLMediaCard'
import SortDropDown from '@components/SortDropDown/SortDropDown'
import Spinner, { LoaderSize } from '@components/Spinner/Spinner'
import { SignatureItem } from '@graphql/types/query-types'
import { ColumnSort } from '@tanstack/react-table'

import { SignaturesModalContext } from './SignaturesModal.context'
import { sortOptions } from './SignaturesModal.utils'

import './signaturesModal.css'

const rootClass = 'signatures-modal'

const SignaturesModalContentContainer = () => {
  const {
    update,
    loadNextPage,
    values: { loading, nextPageLoading, data, selectedSignature, selectedSort, currentPage, activeFilter, filterCounts },
  } = useContext(SignaturesModalContext)

  const loaderRef = useRef(null)

  const signaturesCount = (filterCounts && filterCounts[activeFilter]) || 0
  const allLoaded = data.length >= signaturesCount

  const onSortSelect = (selectedSort: ColumnSort) => {
    update({ selectedSort, currentPage: 0 })
  }

  const onSignatureSelect = (signature: SignatureItem) => {
    update({ selectedSignature: signature })
  }

  const onSignaturePreview = (signature: SignatureItem) => {
    update({ previewSignature: signature })
  }

  useEffect(() => {
    let observerRefValue: Element | null = null
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0]
      if (target.isIntersecting && !nextPageLoading && !allLoaded) {
        update({ currentPage: currentPage + 1 })
        loadNextPage(currentPage + 1)
      }
    })

    if (loaderRef.current) {
      observer.observe(loaderRef.current)
      observerRefValue = loaderRef.current
    }

    return () => {
      if (observerRefValue) observer.unobserve(observerRefValue)
    }
  }, [currentPage, nextPageLoading, allLoaded, loadNextPage])

  return (
    <div className={`${rootClass}__content`} data-test={`${rootClass}-content`}>
      {loading ? (
        <Spinner dataTest={'signatures-modal-spinner'} size={LoaderSize.MEDIUM} />
      ) : (
        <>
          <SortDropDown options={sortOptions} onSelect={onSortSelect} selectedOption={selectedSort} dataTest={`${rootClass}-sort`} />
          <div className={`${rootClass}__grid-container`}>
            {data.map((signature) => {
              if (signature) {
                const isSystem = signature.ID === 0
                const isDefault = signature.isUserDefault || signature.isAccountDefault
                const isSelected = selectedSignature?.ID === signature.ID
                const title = `EmailComposer.Signatures.Modal.${isSystem ? 'SystemPreset' : signature.isUser ? 'MySignature' : 'AccountSignature'}`

                return (
                  <HTMLMediaCard
                    key={`${signature.ID}`}
                    title={t(title)}
                    html={`${signature.html}`}
                    isDefault={isDefault}
                    isSelected={isSelected}
                    onSelect={() => onSignatureSelect(signature)}
                    onPreview={() => onSignaturePreview(signature)}
                    mainActionLabel={t('Select signature')}
                    infoTooltip={isSystem ? t('EmailComposer.Signatures.MediaCard.Tooltip') : undefined}
                    dataTest="signature-media-card"
                  />
                )
              }
            })}
          </div>
          {!allLoaded && (
            <div ref={loaderRef}>
              <Spinner size={LoaderSize.SMALL} />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default SignaturesModalContentContainer
