import getAssets from '@graphql/microservices/segment/getAssets'
import getProgramsUsingLists from '@graphql/microservices/segment/getProgramsUsingLists'
import {
  GetAssetsQuery,
  GetAssetsQueryVariables,
  GetProgramsUsingListsQuery,
  GetProgramsUsingListsQueryVariables,
} from '@graphql/types/microservice/segment-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export interface DependenciesContentRequests {
  getSegmentAssetsRequest: (itemIds: string[]) => FetchPromise<GetAssetsQuery>
  getListAssetsRequest: (itemIds: string[]) => FetchPromise<GetAssetsQuery>
}
export const useDependenciesContentRequests = (): DependenciesContentRequests => {
  const {
    client: { query },
  } = useMicroserviceClient({ serviceName: MicroserviceClients.SEGMENT })

  const getSegmentAssetsRequest = (itemIds: string[]) =>
    query<GetAssetsQuery, GetAssetsQueryVariables>({
      query: getAssets,
      fetchPolicy: 'network-only',
      variables: {
        segmentIds: itemIds,
      },
    })

  const getListAssetsRequest = (itemIds: string[]) =>
    query<GetProgramsUsingListsQuery, GetProgramsUsingListsQueryVariables>({
      query: getProgramsUsingLists,
      fetchPolicy: 'network-only',
      variables: {
        listIds: itemIds,
      },
    })

  return {
    getSegmentAssetsRequest,
    getListAssetsRequest,
  }
}
