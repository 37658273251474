import React, { FC, useContext } from 'react'

import classNames from 'classnames'

import Container from '@components/Container/Container'
import SectionHeadline from '@components/SectionHeadline'
import { SvgNames, SvgType } from '@components/Svg'
import Svg from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { FormManagerContext } from '@src/pages/Content/Forms/FormManager/context/FormManager.context'

import './CappedSignups.css'

interface CappedSignupsProps {
  className?: string
  dataTest?: string
}

const rootClass = 'capped-signups'

const CappedSignups: FC<CappedSignupsProps> = (props: CappedSignupsProps) => {
  const { dataTest = rootClass, className = '' } = props
  const {
    values: {
      cappedSignups: { total, current },
    },
  } = useContext(FormManagerContext)
  const { t } = useTranslation()

  return (
    <Container className={classNames(rootClass, className)} data-test={dataTest}>
      <SectionHeadline>{t('Capped Sign-ups')}</SectionHeadline>
      <Typography
        text={t('The number of form submissions received out of the total allowed in your form settings.')}
        type={TextType.BODY_TEXT_LIGHT}
      />
      <div className={`${rootClass}__submissions`} data-test={`${dataTest}-submissions`}>
        <Typography
          text={t('FormManager.CappedSignups.Submissions', { current, total })}
          tagProps={{ bold: { weight: TextWeight.BOLD, dataTest: `${dataTest}-submissions-count` } }}
          inline
        />
        <Tooltip triggerClassName={`${rootClass}__submissions__info`} trigger={<Svg name={SvgNames.info} type={SvgType.ICON} />}>
          {t('FormManager.CappedSignups.Submissions.Tooltip')}
        </Tooltip>
      </div>
    </Container>
  )
}

export default CappedSignups
