import React, { FC, useContext, useState } from 'react'

import { YesNo } from '@components/ConfirmationModal'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import DependenciesContentModal, {
  DependenciesContentModalItem,
  DependenciesItemType,
} from '@components/DependenciesContentModal/DependenciesContentModal'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import FieldMatchingWarningModal from '@src/pages/ContactSegments/components/CopySegments/components/FieldMatchingWarningModal/FieldMatchingWarningModal'
import ReviewSegmentsModal from '@src/pages/ContactSegments/components/CopySegments/components/ReviewSegmentsModal/ReviewSegmentsModal'
import { rootText } from '@src/pages/ContactSegments/components/CopySegments/constants/CopySegments.constants'
import { CopySegmentsContext, SegmentActions } from '@src/pages/ContactSegments/components/CopySegments/context/CopySegments.context'
import { useUnmappedFieldSegments } from '@src/pages/ContactSegments/components/CopySegments/utils/UseUnmappedFieldSegments'

interface ModalHandlerState {
  showDeleteConfirmationModal: boolean
}

const modalText = `${rootText}Modal.`

const ModalHandler: FC = () => {
  const {
    values: { itemsToRemove, lists, segmentAction, segments, showDependenciesModal, showFieldMatchingWarningModal, showReviewSegmentsModal },
    update,
  } = useContext(CopySegmentsContext)
  const { t } = useTranslation()
  const [state, setState] = useState<ModalHandlerState>({ showDeleteConfirmationModal: false })
  const { showDeleteConfirmationModal } = state
  const isArchiveSelected = segmentAction === SegmentActions.ARCHIVE
  const itemType = isArchiveSelected ? DependenciesItemType.LIST : DependenciesItemType.SEGMENT
  const unmappedFieldsSegments = useUnmappedFieldSegments()
  const filteredSegments = segments.filter((segment) =>
    unmappedFieldsSegments.every((unmappedFieldsSegment) => unmappedFieldsSegment.id !== segment.id)
  )
  const selectedItems = isArchiveSelected ? lists : filteredSegments

  const onCancel = () => update({ showDependenciesModal: false })

  const onDelete = (items: DependenciesContentModalItem[]) => {
    if (segmentAction === SegmentActions.DELETE) {
      setState((state) => ({ ...state, showDeleteConfirmationModal: true }))
    } else {
      update({ showDependenciesModal: false, showReviewSegmentsModal: true })
    }
    update({ itemsToRemove: items.map(({ id }) => id) })
  }

  const onAnswer = (answer: YesNo) => {
    setState((state) => ({ ...state, showDeleteConfirmationModal: false }))
    update({ showDependenciesModal: false })
    if (answer === YesNo.YES) {
      update({ showReviewSegmentsModal: true })
    }
  }

  const postDeleteAction = (items?: DependenciesContentModalItem[]) => {
    if (!items) {
      onDelete([])
    }
  }

  const cautionInfoMessage = (
    <>
      <Typography
        text={t(`${modalText}DependenciesContentModal.CautionInfoMessage1`)}
        type={TextType.BODY_TEXT_SMALL}
        lineHeight={LineHeight.MEDIUM_SMALL}
      />
      <Typography
        text={t(`${modalText}DependenciesContentModal.CautionInfoMessage2`)}
        type={TextType.BODY_TEXT_SMALL}
        lineHeight={LineHeight.MEDIUM_SMALL}
      />
    </>
  )

  return (
    <>
      {showDeleteConfirmationModal && (
        <DeleteConfirmationModal
          isOpen
          title={t(`${modalText}DeleteConfirmationModal.Title`)}
          deleteButtonText={t(`${modalText}DeleteConfirmationModal.Button.Text`)}
          body={t(`${modalText}DeleteConfirmationModal.Body`, { count: itemsToRemove.length })}
          onAnswer={onAnswer}
        />
      )}
      {showDependenciesModal && (
        <DependenciesContentModal
          onCancel={onCancel}
          itemType={itemType}
          onDelete={onDelete}
          postDeleteAction={postDeleteAction}
          selectedItems={selectedItems.map(({ id, name }) => ({ id, name } as DependenciesContentModalItem))}
          customCautionMessage={
            isArchiveSelected ? t(`${modalText}DependenciesContentModal.MoveToTrash`) : t(`${modalText}DependenciesContentModal.Delete.Label`)
          }
          customCautionInfoMessage={isArchiveSelected ? cautionInfoMessage : undefined}
          checkboxLabel={
            isArchiveSelected
              ? t(`${modalText}DependenciesContentModal.MoveToTrash.Label`)
              : t(`${modalText}DependenciesContentModal.Delete.Checkbox.Label`)
          }
        />
      )}
      {showFieldMatchingWarningModal && <FieldMatchingWarningModal />}
      {showReviewSegmentsModal && <ReviewSegmentsModal />}
    </>
  )
}

export default ModalHandler
