import { gql } from '@apollo/client'

export default gql`
  query getContactsFromBounce($page: PageInput) {
    getContactsFromBounce(page: $page) {
      contacts
      hasNextPage
      headers
      id
      pageNumber
      pageSize
      rowIds
      search
      totalCount
    }
  }
`
