import React, { FC, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { useApolloClient, useQuery } from '@apollo/client'
import Caution from '@components/Caution/Caution'
import getBrandingFavicon from '@graphql/queries/getBrandingFavicon'
import { GetBrandingFaviconQuery, GetBrandingFaviconQueryVariables } from '@graphql/types/query-types'
import { updateOrCreateMetaTag } from '@src/pages/EmailComposer/utils/EmailComposerAPI.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'
import { ComposerHistoryState } from '@utils/composer/context/EmailComposer.context'
import { LandingPageVisibilityStatus } from '@utils/composer/context/LandingPageComposer.context'
import { SAVED_AS_COPY_ID } from '@utils/composer/EmailModal.constants'

import LandingPageComposerSettingsTitle from './components/LandingPageComposerSettingsTitle/LandingPageComposerSettingsTitle'
import { LandingPageComposerSettingsURLManagerContainer } from './components/LandingPageComposerSettingsURLManager/LandingPageComposerSettingsURLManagerContainer'
import MetaDataPane from './components/MetaDataPane/MetaDataPane'
import { MetaDataPaneV2 } from './components/MetaDataPaneV2/MetaDataPaneV2'
import PageStatusPane from './components/PageStatusPane/PageStatusPane'
import { SettingsForm } from './utils/LandingPageComposerSettings.utils'

import './LandingPageComposerSettings.css'

export interface LandingPageComposerSettingsProps {}

const rootClass = 'landing-page-composer-settings'

const LandingPageComposerSettings: FC<LandingPageComposerSettingsProps> = () => {
  const { t } = useTranslation()
  const history = useHistory<ComposerHistoryState>()

  const {
    values: {
      loading,
      message: { templateHtml },
      landingPage: {
        landingPageMetaImage,
        hasUnpublishedChanges,
        landingPageMetaTitle,
        landingPageMetaDescription,
        landingPageVisibility,
        isLandingPagePublished,
      },
      brandingFavicon,
    },
    api: { updateModal, update },
  } = useComposerContext()

  const { newLPComposerCreateBlank } = useAccountSettings()
  const client = useApolloClient()

  const { data: brandingFaviconData } = useQuery<GetBrandingFaviconQuery, GetBrandingFaviconQueryVariables>(getBrandingFavicon, {
    client,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    update({ brandingFavicon: brandingFaviconData?.getBrandingFavicon?.item?.url || '' })
  }, [brandingFaviconData])

  // Html string with meta tags
  let updatedTemplateHtmlString = templateHtml ?? ''

  const handleUpdateOrCreateMetaTag = (tag: string, metaType: string, metaNameOrProperty: string, contentValue: string) => {
    updatedTemplateHtmlString = updateOrCreateMetaTag(updatedTemplateHtmlString, tag, metaType, metaNameOrProperty, contentValue)
  }

  const onFieldChange = useCallback(
    <T extends keyof SettingsForm>(field: T, value: SettingsForm[T]) => update({ landingPage: { [field]: value } }),
    [t, update, updateModal]
  )

  useEffect(() => {
    handleUpdateOrCreateMetaTag('meta', 'property', 'og:image', landingPageMetaImage?.url || '')
    handleUpdateOrCreateMetaTag('meta', 'name', 'twitter:image', landingPageMetaImage?.url || '')

    handleUpdateOrCreateMetaTag('title', '', '', landingPageMetaTitle || '')
    handleUpdateOrCreateMetaTag('meta', 'property', 'og:title', landingPageMetaTitle || '')
    handleUpdateOrCreateMetaTag('meta', 'property', 'twitter:title', landingPageMetaTitle || '')

    handleUpdateOrCreateMetaTag('meta', 'name', 'description', landingPageMetaDescription || '')
    handleUpdateOrCreateMetaTag('meta', 'property', 'og:description', landingPageMetaDescription || '')
    handleUpdateOrCreateMetaTag('meta', 'name', 'twitter:description', landingPageMetaDescription || '')

    handleUpdateOrCreateMetaTag('link', 'rel', 'icon', brandingFavicon || '')

    handleUpdateOrCreateMetaTag('meta', 'name', 'ROBOTS', landingPageVisibility === LandingPageVisibilityStatus.HIDE ? 'NOINDEX, NOFOLLOW' : '')

    update({ message: { templateHtml: updatedTemplateHtmlString } })
  }, [landingPageMetaImage, landingPageMetaTitle, landingPageMetaDescription, landingPageVisibility, brandingFavicon])

  useEffect(() => {
    if (history.location.state) {
      const savedAsCopyId = history.location.state[SAVED_AS_COPY_ID]

      if (savedAsCopyId && !loading) {
        const currentState = { ...history.location.state }

        delete currentState[SAVED_AS_COPY_ID]

        history.replace({
          ...history.location,
          state: currentState,
        })
      }
    }
  }, [history, loading, t, updateModal])

  return (
    <div className={rootClass}>
      <div className={`${rootClass}__left`}>
        <div className={`${rootClass}__left__section`}>
          <LandingPageComposerSettingsTitle className={rootClass} />
        </div>
        <div className={`${rootClass}__left__section`}>
          <LandingPageComposerSettingsURLManagerContainer />
        </div>
        <div className={`${rootClass}__left__section`}>
          {newLPComposerCreateBlank ? (
            <MetaDataPaneV2 dataTest={rootClass} onFieldChange={onFieldChange} />
          ) : (
            <MetaDataPane onFieldChange={onFieldChange} />
          )}
        </div>
      </div>
      <div className={`${rootClass}__right`}>
        <div className={`${rootClass}__right__section`}>
          {newLPComposerCreateBlank && isLandingPagePublished && hasUnpublishedChanges && (
            <Caution className={`${rootClass}__caution`} message={t('LandingPage.caution')} />
          )}
          <PageStatusPane onFieldChange={onFieldChange} />
        </div>
      </div>
    </div>
  )
}

export default LandingPageComposerSettings
