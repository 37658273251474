import React, { useEffect, useRef, useState } from 'react'

import { MediaPickerModalType } from '@components/AssetPickers/MediaPickerModal/MediaPickerModal.utils'
import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'

import LandingPageComposerCustomEditor from '../LandingPageComposerCustomEditor/LandingPageComposerCustomEditor'

import './LandingPageComposerCustomCodeJavaScript.css'

const LandingPageComposerCustomCodeJavaScript = () => {
  const {
    values: {
      landingPage: { customJavaScript },
      customTabFirstRender,
    },
    api: { update },
  } = useComposerContext()

  const [customJavaScriptCode, setCustomJavaScriptCode] = useState<string>(customJavaScript || '')

  const isFirstRender = useRef(true)

  useEffect(() => {
    update({ landingPage: { customJavaScript: customJavaScriptCode } })
  }, [customJavaScriptCode])

  useEffect(() => {
    if (isFirstRender.current) {
      setCustomJavaScriptCode(customJavaScript || '')
      if (customJavaScript) {
        isFirstRender.current = false
      }
    }
  }, [customJavaScript])

  const onCodeChange = (code: string) => {
    if (customTabFirstRender) {
      update({ customTabFirstRender: false })
    }
    setCustomJavaScriptCode(code)
  }

  const onSelectButtonClick = () => {
    update({ showMediaPickerModal: true, mediaPickerModalType: MediaPickerModalType.JS })
  }

  return (
    <div>
      <LandingPageComposerCustomEditor
        mode="javascript"
        value={customJavaScriptCode || ''}
        onChange={onCodeChange}
        onSelectButtonClick={onSelectButtonClick}
        title="LandingPageComposer.CustomCode.JavaScript.Title"
        description="LandingPageComposer.CustomCode.JavaScript.Desc"
        editorTitle="LandingPageComposer.CustomCode.JavaScript.Editor.Title"
        buttonText="LandingPageComposer.CustomCode.JavaScript.Button"
        copyText="Copy"
      />
    </div>
  )
}

export default LandingPageComposerCustomCodeJavaScript
