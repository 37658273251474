import React, { FC, ReactNode, RefObject, useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'

interface NestedDropDownPortalProps {
  rootClass: string
  isActive: boolean
  children: ReactNode
  elementRef: RefObject<HTMLDivElement>
  parentRef?: RefObject<HTMLDivElement>
  close: () => void
}

export const NestedDropDownPortal: FC<NestedDropDownPortalProps> = ({ children, elementRef, parentRef, isActive, rootClass, close }) => {
  const portalRootRef = useRef(document.createElement('div'))
  const portalRoot = portalRootRef.current

  useEffect(() => {
    const parentReferenceElement = parentRef?.current
    ;(parentReferenceElement || document.body).appendChild(portalRoot)
    return () => {
      ;(parentReferenceElement || document.body).removeChild(portalRoot)
    }
  }, [])

  useEffect(() => {
    const keydownListener = (e: KeyboardEvent) => {
      if (e.key === 'Escape' || e.key === 'Tab') {
        close()
      }
    }

    const clickListener = (e: MouseEvent) => {
      const targetElement = e.target as HTMLElement
      const isInteractive = targetElement.closest('.portal-container')

      if (!isInteractive) {
        close()
        if (targetElement.closest(`.${rootClass}__wrapper`) && isActive) e.stopPropagation()
      }
    }

    const interactiveElementClickListener = (e: MouseEvent) => e.stopPropagation()

    document.addEventListener('keydown', keydownListener)
    window.addEventListener('click', clickListener, { capture: true })
    window.addEventListener('resize', close)

    portalRoot.addEventListener('click', interactiveElementClickListener)

    return () => {
      document.removeEventListener('keydown', keydownListener)
      window.removeEventListener('click', clickListener, { capture: true })
      window.removeEventListener('resize', close)

      portalRoot.removeEventListener('click', interactiveElementClickListener)
    }
  }, [close])

  const referenceElement = elementRef.current
  const parentReferenceElement = parentRef?.current

  if (!referenceElement) {
    return null
  }

  const referenceRect = referenceElement.getBoundingClientRect()
  const { left, bottom, width } = referenceRect

  const portalContent = (
    <div
      className="portal-container"
      style={{
        position: 'absolute',
        left: parentReferenceElement ? '' : `${left}px`,
        top: parentReferenceElement ? '' : `${bottom}px`,
        width: `${width}px`,
      }}
    >
      {children}
    </div>
  )

  return createPortal(portalContent, portalRootRef.current)
}
