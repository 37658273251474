import React from 'react'

import classNames from 'classnames'

import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { useTranslation } from '@const/globals'

import './printButton.css'

interface PrintButtonProps {
  rootClass?: string
  text?: string
}

const PrintButton = (props: PrintButtonProps) => {
  const { rootClass, text = 'Print' } = props

  const { t } = useTranslation()

  const print = () => {
    window.focus()
    window.print()
  }

  return (
    <>
      <button
        onClick={print}
        className={classNames(`${rootClass}__print-button`, 'print-button', 'no-print')}
        data-test={`${rootClass}__print-button`}
      >
        <Svg name={SvgNames.print} type={SvgType.LARGER_ICON} dataTest={`${rootClass}__print-button--svg`} />
        {t(text)}
      </button>
    </>
  )
}

export default PrintButton
