import * as yup from 'yup'

import { SvgNames } from '@components/Svg'
import { rootContext } from '@const/globals'
import { FieldDefinition, ProgramAdditionalEntity, StringKeyValue } from '@graphql/types/query-types'
import { EditCRMStepV2State } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.context'
import { hasValidFields } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.utils'

export const salesforceIconsByEntity: { [key: string]: SvgNames } = {
  ['Account']: SvgNames.officeNoFill,
  ['CampaignMember']: SvgNames.megaphoneLineNoFill,
  ['DealContact']: SvgNames.crmCloudLineNoFill,
  ['Event']: SvgNames.calendarAddNoFill,
  ['Note']: SvgNames.editClassic,
  ['Deal']: SvgNames.opportunityCrm,
  ['Task']: SvgNames.signUpNoFill,
}

export const sugarIconsByEntity: { [key: string]: SvgNames } = {
  ['Task']: SvgNames.signUpNoFill,
  ['Meeting']: SvgNames.calendarAddNoFill,
  ['Call']: SvgNames.crmCloudLineNoFill,
  ['Account']: SvgNames.officeNoFill,
  ['Note']: SvgNames.editClassic,
}

export const zendeskIconsByEntity: { [key: string]: SvgNames } = {
  ['Task']: SvgNames.signUpNoFill,
  ['Owner']: SvgNames.userUnselected,
  ['Note']: SvgNames.editClassic,
}

export const netsuiteIconsByEntity: { [key: string]: SvgNames } = {
  ['Note']: SvgNames.editClassic,
}

export const msDynamicsIconsByEntity: { [key: string]: SvgNames } = {
  ['Task']: SvgNames.signUpNoFill,
  ['Account']: SvgNames.officeNoFill,
}

export const iconByRecordType: { [key: string]: SvgNames } = {
  ['Contacts']: SvgNames.signUpNoFill,
  ['Leads']: SvgNames.twoUsersNoFill,
  ['Targets']: SvgNames.target,
}

export const editCRMStepV2InitialState: Omit<EditCRMStepV2State, 'steps'> = {
  entities: [],
  entitiesReferences: new Map(),
  fieldDefinitionsByEntity: new Map(),
  loadingInitialValues: true,
  loadingReferences: false,
  recordTypes: [],
  showSteps: false,
  showErrorBanner: false,
}

export enum CRMStepDateOption {
  NONE = 'None',
  IMMEDIATELY = 'Now',
  RELATIVE = 'Relative date',
}

export const CRMStepSpecificDateOptions: { [key: string]: string } = {
  day_1: 'One day into the future',
  day_2: 'Two days into the future',
  day_3: 'Three days into the future',
  week_1: 'One week into the future',
  week_2: 'Two weeks into the future',
  week_3: 'Three weeks into the future',
  month_1: 'One month into the future',
  month_2: 'Two months into the future',
  month_3: 'Three months into the future',
}

export enum DetailsDataType {
  PRIMARYKEY = 'PRIMARYKEY',
  REFERENCE = 'REFERENCE',
  STRING = 'STRING',
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  BOOLEAN = 'BOOLEAN',
  INTEGER = 'INTEGER',
  NUMERIC = 'NUMERIC',
  CURRENCY = 'CURRENCY',
  PERCENT = 'PERCENT',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  URL = 'URL',
  PICKLIST = 'PICKLIST',
  MUILTIPICKLIST = 'MULTIPICKLIST',
  IMAGE = 'IMAGE',
}

export const STEP_DETAILS_KEY = 'step-details'
export const SYNC_SCHEDULE_URL = `${rootContext}/datamanagement/sync-schedule`

export const IMMEDIATELY_KEY = 'day_0'
export const DEFAULT_DUE_DATE = 'day_1'

export const opportunitiesEntities = ['Deal', 'DealContact']
export const primaryEntitiesWithHiddenFields = ['Lead', 'Contact']

export const getSchema = (fieldDefinitionsByEntity: Map<string, FieldDefinition[]>) => {
  return yup.object().shape({
    displayName: yup.string().required(),
    disabled: yup.boolean(),
    stepId: yup.string(),
    stepType: yup.string(),
    pushType: yup.string().required(),
    pushNewRecords: yup.boolean().required(),
    additionalEntities: yup.array().of(
      yup.object().test('required fields', '', (value) => {
        const { entityType = '', fields = [] } = value as ProgramAdditionalEntity
        const entityFields = fieldDefinitionsByEntity.get(entityType)
        if (entityFields) {
          return hasValidFields(entityFields, fields)
        }
        return false
      })
    ),
    staticFields: yup.array().when(['pushType', 'pushNewRecords'], (pushType, pushNewRecords, schema) => {
      return schema.test({
        name: 'required static fields',
        test: (staticFields: StringKeyValue[]) => {
          if (pushType && pushNewRecords) {
            const allAvailableStaticFields = fieldDefinitionsByEntity.get(pushType)
            if (allAvailableStaticFields) {
              return hasValidFields(allAvailableStaticFields, staticFields)
            }
          } else {
            return staticFields.length === 0
          }
        },
      })
    }),
  })
}
