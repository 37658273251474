import React, { FC, Fragment } from 'react'

import DropDownActionItem, { DropDownActionItemProps } from '@components/DropDownActionItem/DropDownActionItem'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'

interface Props {
  className?: string
  itemProps: DropDownActionItemProps
  tooltipMessage?: ((row?: any) => string) | string
}

const DropDownActionItemWithTooltip: FC<Props> = (props: Props) => {
  const { className, itemProps, tooltipMessage } = props
  const { t } = useTranslation()
  const { text, isDisabled } = itemProps

  return (
    <Fragment key={text}>
      {isDisabled ? (
        <Tooltip
          alwaysShowOnHover
          disabledTrigger={isDisabled}
          triggerClassName={className}
          position={'left'}
          trigger={<DropDownActionItem {...itemProps} />}
        >
          {t(tooltipMessage)}
        </Tooltip>
      ) : (
        <DropDownActionItem {...itemProps} />
      )}
    </Fragment>
  )
}

export default DropDownActionItemWithTooltip
