import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import classNames from 'classnames'

import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import { YesNo } from '@components/ConfirmationModal'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import DropDown from '@components/DropDown'
import { DropDownType } from '@components/DropDown/DropDown'
import DropDownActions, { MenuItem } from '@components/DropDownActions/DropDownActions'
import { EmptyListingProps, EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import PageContainer from '@components/PageContainer'
import PageHeader from '@components/PageHeader'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import StatusToast, { Status } from '@components/StatusToast/StatusToast'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { TableV2 } from '@components/TableV2/TableV2'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { TOAST_TEXT_CLASSNAME } from '@components/Toast/Toast'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import CreateIncomingWebhookModal, {
  OnWebhookCreatedParams,
} from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/CreateIncomingWebhookModal'
import TestWebhookModal from '@src/pages/Webhooks/IncomingWebhooks/components/TestWebhookModal/TestWebhookModal'
import WebhookInfoModal from '@src/pages/Webhooks/IncomingWebhooks/components/WebhookInfoModal/WebhookInfoModal'
import { useIncomingWebhooksRequests } from '@src/pages/Webhooks/IncomingWebhooks/GraphQL/IncomingWebhooks.graphQL'
import {
  buildCustomWebhookSignatureOptions,
  buildSignatureTemplateList,
  getIncomingWebhooksColumns,
  getIncomingWebhooksRowActions,
} from '@src/pages/Webhooks/IncomingWebhooks/IncomingWebhooks.utils'
import {
  IncomingWebhook,
  IncomingWebhooksContext,
  IncomingWebhooksRowActions,
  SignatureTemplate,
  SignatureTemplateList,
} from '@src/pages/Webhooks/IncomingWebhooks/IncomingWebhooksContext'
import { Row, SortingState } from '@tanstack/react-table'
import { useAccountSettings } from '@utils/account/account.utils'

import './IncomingWebhooks.css'

interface IncomingWebhooksProps {
  className?: string
  dataTest?: string
  isStory?: boolean
}

interface IncomingWebhooksState {
  dropDownOpen: boolean
}

const rootClass = 'incoming-webhooks'

const IncomingWebhooks: FC<IncomingWebhooksProps> = (props: IncomingWebhooksProps) => {
  const { dataTest = rootClass, className = '', isStory } = props
  const {
    values: {
      isTestingNewWebhook,
      loading,
      showCreateWebhooksModal,
      showInfoWebhookModal,
      showTestWebhookModal,
      statusToast,
      webhookToEdit,
      webhookToTest,
      webhookToViewInfo,
      webhookToDelete,
      webhooks,
    },
    update,
  } = useContext(IncomingWebhooksContext)
  const [state, setState] = useState<IncomingWebhooksState>({ dropDownOpen: false })
  const { dropDownOpen } = state

  const {
    userSettings: { isAdministrator },
    timeZoneId = undefined,
  } = useAccountSettings()

  const { getWebhooksActivityData, getWebhooksList, deleteWebhook, getSignatureList, updateWebhookEnabledStatus } = useIncomingWebhooksRequests()

  const { t } = useTranslation()
  const history = useHistory()

  const loadWebhooks = () => {
    update({ loading: true })
    return getWebhooksList()
      .then((webhooks) => {
        update({ webhooks, loading: false })
        return webhooks
      })
      .catch(() => update({ loading: false }))
  }

  const loadSignatureTemplateList = () =>
    getSignatureList().then(({ data }) => {
      if (data?.getSignatureTemplateList) {
        const signatureTemplateList: SignatureTemplateList = buildSignatureTemplateList(data.getSignatureTemplateList as SignatureTemplate[])
        const customWebhookSignatureOptions = buildCustomWebhookSignatureOptions(signatureTemplateList.CUSTOM as SignatureTemplate)
        update({ signatureTemplateList, customWebhookSignatureOptions })
      }
    })

  const onWebhookDelete = (row: Row<IncomingWebhook>) => {
    update({ webhookToDelete: row })
  }

  const onDelete = (row: Row<IncomingWebhook>) => {
    deleteWebhook(row.original.id).then(() => {
      update({
        webhooks: [...webhooks.slice(0, row.index), ...webhooks.slice(row.index + 1)],
        webhookToViewInfo: undefined,
      })
    })
  }

  const onCreate = () => {
    update({ showCreateWebhooksModal: true })
  }

  const onWebhookCreated = async ({ triggerTest, webhookId, toastMessage }: OnWebhookCreatedParams) => {
    loadWebhooks().then((webhooks) => {
      if (triggerTest) {
        update({
          showTestWebhookModal: true,
          webhookToTest: webhooks?.find(({ id }) => webhookId === id),
          isTestingNewWebhook: !webhookToEdit,
        })
      }
    })
    const action = !!webhookToEdit ? 'Updated' : 'Created'
    const statusToastMessage = webhookId ? `Webhook${action}` : `WebhookNot${action}`
    update({
      webhookToEdit: undefined,
      enableContentMapping: false,
      enableAuthentication: false,
      statusToast: {
        status: webhookId ? Status.SUCCESS : Status.FAIL,
        statusMessage: (
          <Typography
            className={TOAST_TEXT_CLASSNAME}
            text={t(toastMessage) || `Incoming.Webhooks.Toast.${statusToastMessage}`}
            tagProps={{ bold: { weight: TextWeight.BOLD } }}
            inline
          />
        ),
      },
    })
  }

  const onWebhookCreationCancel = () => {
    update({ showCreateWebhooksModal: false, webhookToEdit: undefined, enableContentMapping: false, enableAuthentication: false })
  }

  const onRowClicked = (webhookRow: Row<IncomingWebhook>) => {
    update({ showInfoWebhookModal: true, webhookToViewInfo: webhookRow })
  }

  const onStatusChange = (row: Row<IncomingWebhook>, enabled: boolean) => {
    updateWebhookEnabledStatus(row.original.id, enabled).then(() => {
      update({ webhooks: [...webhooks.slice(0, row.index), { ...row.original, enabled }, ...webhooks.slice(row.index + 1)] })
    })
  }

  const getNoTestedClass = ({ original }: Row<IncomingWebhook>) => {
    return !original.tested ? 'no-tested' : ''
  }

  const onWebhookEdit = ({ original }: Row<IncomingWebhook>) => {
    update({
      showCreateWebhooksModal: true,
      webhookToEdit: original,
      webhookToViewInfo: undefined,
    })
  }

  const onEditFromTest = (webhook: IncomingWebhook) => {
    update({
      showCreateWebhooksModal: true,
      showTestWebhookModal: false,
      webhookToEdit: webhook,
      webhookToViewInfo: undefined,
    })
  }

  const onWebhookTest = ({ original }: Row<IncomingWebhook>) => {
    update({
      showTestWebhookModal: true,
      webhookToTest: original,
      webhookToViewInfo: undefined,
    })
  }

  const updateTestStatus = (tested: boolean) => {
    if (webhookToTest) {
      const webhookIndex = webhooks.findIndex(({ id }) => webhookToTest.id === id)
      update({
        webhooks: [...webhooks.slice(0, webhookIndex), { ...webhookToTest, tested }, ...webhooks.slice(webhookIndex + 1)],
      })
    }
  }

  const onWebhookTestCancel = (inTest: boolean) => {
    if (inTest) {
      updateTestStatus(false)
    }
    closeTestModal()
  }

  const closeTestModal = () => {
    update({
      showTestWebhookModal: false,
      webhookToTest: undefined,
      isTestingNewWebhook: false,
    })
  }

  const onWebhookTestDone = (tested: boolean) => {
    updateTestStatus(tested)
    closeTestModal()
  }

  const onWebhookCopy = (row: Row<IncomingWebhook>) => {
    navigator.clipboard.writeText(row.original.url)
    update({ statusToast: { status: Status.SUCCESS, statusMessage: 'Incoming.Webhooks.Toast.CopyUrl' } })
  }

  const onDeleteConfirmationModalAnswer = (answer: YesNo) => {
    if (answer === YesNo.YES && webhookToDelete) {
      onDelete(webhookToDelete)
    }
    update({ webhookToDelete: undefined })
  }

  const tableRowActions: { [key in IncomingWebhooksRowActions]: (row: Row<IncomingWebhook>) => void } = {
    [IncomingWebhooksRowActions.EDIT]: (row) => onWebhookEdit(row),
    [IncomingWebhooksRowActions.TEST]: (row) => onWebhookTest(row),
    [IncomingWebhooksRowActions.COPY]: (row) => onWebhookCopy(row),
    [IncomingWebhooksRowActions.DELETE]: (row) => onWebhookDelete(row),
  }

  const callTableRowAction = (action: IncomingWebhooksRowActions, row: Row<IncomingWebhook>) => {
    if (tableRowActions.hasOwnProperty(action)) {
      tableRowActions[action](row)
    }
  }

  const closeStatusToast = () => update({ statusToast: undefined })
  const columns: ColumnDefWithAdditionalProps<IncomingWebhook>[] = useMemo(
    () => getIncomingWebhooksColumns(onStatusChange, onWebhookTest, t, timeZoneId),
    [webhooks]
  )
  const { rowActions, webhookInfoModalActions } = useMemo(() => {
    const rowActions = getIncomingWebhooksRowActions(callTableRowAction)
    return { rowActions, webhookInfoModalActions: rowActions.filter(({ label }) => label !== IncomingWebhooksRowActions.COPY) }
  }, [webhooks])

  const restrictedState: EmptyListingProps = {
    headline: 'Access restricted',
    text: 'Incoming.Webhooks.Restricted.text',
    linkText: 'Incoming.Webhooks.Restricted.linkText',
    hideIcon: false,
    imgSrc: StaticImageNames.emptyRestrictedContent,
    onLinkClick: () => history.push(`${rootContext}/classic/if/_account/connectors.jsp`),
    className: `${rootClass}__restricted`,
    size: EmptyListingSize.MEDIUM,
    isStory,
  }

  const emptyState: EmptyListingProps = {
    headline: 'Incoming.Webhooks.Empty.headline',
    text: 'Incoming.Webhooks.Empty.text',
    buttonText: 'Create webhook',
    buttonPlusIcon: true,
    buttonType: ButtonType.PRIMARY,
    imgSrc: StaticImageNames.emptyWebhooks,
    buttonOnClick: onCreate,
    className: `${rootClass}__empty`,
    size: EmptyListingSize.MEDIUM,
    isStory,
  }

  const defaultSortingBy: SortingState = [
    {
      id: 'name',
      desc: false,
    },
  ]

  const renderCreateWebhookButton = () => (
    <Button
      buttonType={ButtonType.PRIMARY}
      iconPosition={ButtonIconPosition.LEFT}
      className={classNames(`${rootClass}__button-add`, {
        [`${rootClass}__button-add-disabled`]: !isAdministrator,
      })}
      dataTest={`${rootClass}-button__add`}
      onClick={isAdministrator ? onCreate : undefined}
    >
      <Svg name={SvgNames.plus} type={SvgType.LARGER_ICON} />
      {t('Create webhook')}
    </Button>
  )

  const downloadActivityDataCSV = () => {
    getWebhooksActivityData().then(({ data }) => {
      if (data?.getWebhooksHistoricalData) {
        window.open(data.getWebhooksHistoricalData, '_self')
      }
    })
  }

  const dropDownMenuItems: MenuItem[] = [
    {
      text: t('Download activity data'),
      icon: SvgNames.download,
      onClick: downloadActivityDataCSV,
    },
  ]

  useEffect(() => {
    if (isAdministrator) {
      loadWebhooks()
      loadSignatureTemplateList()
    }
  }, [])

  return (
    <PageContainer className={classNames(rootClass, className)} dataTest={dataTest}>
      {statusToast && <StatusToast message={statusToast.statusMessage} status={statusToast.status} closeStatus={closeStatusToast} />}
      {webhookToViewInfo && (
        <WebhookInfoModal
          onClose={() => update({ webhookToViewInfo: undefined, showInfoWebhookModal: false })}
          isOpen={showInfoWebhookModal}
          webhook={webhookToViewInfo}
          rowActions={webhookInfoModalActions}
        />
      )}
      <DeleteConfirmationModal
        isOpen={!!webhookToDelete}
        title={t('Are you sure?')}
        cancelButtonText={t('Cancel')}
        deleteButtonText={t('Incoming.Webhooks.RowActions.Delete')}
        body={
          <>
            <Typography text={t('Incoming.Webhooks.DeleteConfirmation.Text_1')} type={TextType.BODY_TEXT_LIGHT} />
            <br />
            <br />
            <Typography
              text={t('Incoming.Webhooks.DeleteConfirmation.Text_2')}
              type={TextType.BODY_TEXT_LIGHT}
              tagProps={{ bold: { weight: TextWeight.BOLD } }}
              inline
            />
          </>
        }
        onAnswer={onDeleteConfirmationModalAnswer}
      />
      <CreateIncomingWebhookModal
        onCancel={onWebhookCreationCancel}
        onWebhookCreated={onWebhookCreated}
        isOpen={showCreateWebhooksModal}
        webhook={webhookToEdit}
      />
      {webhookToTest && (
        <TestWebhookModal
          webhook={webhookToTest}
          onCancel={onWebhookTestCancel}
          onDone={onWebhookTestDone}
          isOpen={showTestWebhookModal}
          isNew={isTestingNewWebhook}
          onEdit={onEditFromTest}
        />
      )}
      <PageHeader className={`${rootClass}__header`} primaryText={t('Incoming Webhooks')} leftContent linkBack>
        {isAdministrator ? (
          renderCreateWebhookButton()
        ) : (
          <Tooltip
            align={'end'}
            alignTextCenter={true}
            position={'bottom'}
            trigger={renderCreateWebhookButton()}
            className={`${rootClass}__button-add-tooltip`}
          >
            {t('Incoming.Webhooks.Restricted.tooltip')}
          </Tooltip>
        )}
        <DropDown
          type={DropDownType.STYLED}
          hasOverflowIcon
          isOpen={dropDownOpen}
          toggleOpen={(value) => setState((state) => ({ ...state, dropDownOpen: value }))}
          className={classNames({ [`${rootClass}__drop-down-open`]: dropDownOpen })}
        >
          <DropDownActions menuItems={dropDownMenuItems} closeDropDown={() => setState((state) => ({ ...state, dropDownOpen: false }))} />
        </DropDown>
      </PageHeader>
      <TableV2
        loading={loading}
        data={webhooks}
        columns={columns}
        rowActions={rowActions}
        data-test={dataTest}
        emptyState={isAdministrator ? emptyState : restrictedState}
        onRowClicked={(row) => onRowClicked(row as Row<IncomingWebhook>)}
        enableInsideLoader
        customClass={getNoTestedClass}
        sortingBy={defaultSortingBy}
        stickyColumns={['name', 'enabled']}
      />
    </PageContainer>
  )
}

export default IncomingWebhooks
