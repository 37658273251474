import React, { FC, MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react'

import ImagePickerModalContent from '@components/AssetPickers/ImagePickerModal/components/ImagePickerModalContent/ImagePickerModalContent'
import ImagePickerModalFooter from '@components/AssetPickers/ImagePickerModal/components/ImagePickerModalFooter'
import ImagePickerModalHeader from '@components/AssetPickers/ImagePickerModal/components/ImagePickerModalHeader'
import ImagePickerModalPreview from '@components/AssetPickers/ImagePickerModal/components/ImagePickerModalPreview/ImagePickerModalPreview'
import ImagePickerModalSidebar from '@components/AssetPickers/ImagePickerModal/components/ImagePickerModalSidebar'
import { IMAGES_DEFAULT_PAGE_SIZE } from '@components/AssetPickers/ImagePickerModal/constants/ImagePickerModal.constants'
import ImagePickerModalContentContext from '@components/AssetPickers/ImagePickerModal/context/ImagePickerModalContent.context'
import {
  GetImagePromiseType,
  GetImagesPromiseType,
  GetLogoPromiseType,
  GetLogosPromiseType,
} from '@components/AssetPickers/ImagePickerModal/graphQL/ImagePickerModal.graphQL'
import { ImagePickerModalContainerProps } from '@components/AssetPickers/ImagePickerModal/ImagePickerModalContainer'
import {
  DynamicImageModalStates,
  IImagePickerFilterState,
  IImagePickerItem,
  ImagePickerSource,
  ImagePickerView,
  getInitialImagePickerFilterState,
  initialImagePickerSortStateLibrary,
  initialImagePickerSortStateLogos,
} from '@components/AssetPickers/ImagePickerModal/utils/ImagePickerModal.utils'
import Modal, { ModalBody } from '@components/Modal'
import { legacyActonContext } from '@const/globals'
import { ImagesFolderResponse } from '@graphql/types/query-types'
import { ColumnSort } from '@tanstack/react-table'
import { SortDirection } from '@utils/common'
import { FilterTypes } from '@utils/filter'
import { ReactSetStateAction } from '@utils/interface/common'
import { removeItem } from '@utils/sessionStorage'

import './ImagePickerModal.css'

interface ImagePickerModalProps extends ImagePickerModalContainerProps {
  folders: ImagesFolderResponse[]
  onGetImages: GetImagesPromiseType
  onGetLogos: GetLogosPromiseType
  onGetImageById: GetImagePromiseType
  onGetLogoById: GetLogoPromiseType
  viewTypeRef: MutableRefObject<ImagePickerView>
  loadingFolders: boolean
  dataTest?: string
  showImagePickerBackButton?: boolean
  setDynamicImageModalStates?: ReactSetStateAction<DynamicImageModalStates>
  imageMaxSize?: number
  accept?: string
  allowSvg?: boolean
  isLandingPage?: boolean
}

const rootClass = 'image-picker-modal'

const saveScrollSessionKey = `${rootClass}-scroll-top`

const editUrl = `${legacyActonContext}/imageEditor/editor.jsp?`

const ImagePickerModal: FC<ImagePickerModalProps> = ({
  onImageInsert,
  headerActions,
  folders,
  onGetImages,
  onGetLogos,
  onGetImageById,
  onGetLogoById,
  viewTypeRef,
  loadingFolders,
  isFromLogos,
  urlIdToReplace,
  showInsertButtonEnabled,
  dataTest = rootClass,
  isStory,
  showImagePickerBackButton,
  setDynamicImageModalStates,
  imageMaxSize,
  accept,
  allowSvg,
  isLandingPage,
}) => {
  const [selectedImage, setSelectedImage] = useState<IImagePickerItem>()
  const [imageToPreview, setImageToPreview] = useState<IImagePickerItem | undefined>()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [filterState, setFilterState] = useState<IImagePickerFilterState>(getInitialImagePickerFilterState(isFromLogos))
  const [sortBy, setSortBy] = useState<ColumnSort>(isFromLogos ? initialImagePickerSortStateLogos : initialImagePickerSortStateLibrary)
  const [loadingData, setLoadingData] = useState<boolean>(true)
  const [loadingNextData, setLoadingNextData] = useState<boolean>(false)
  const [imagesData, setImagesData] = useState<IImagePickerItem[]>([])
  const [logosData, setLogosData] = useState<IImagePickerItem[]>([])
  const [logosTotalCount, setLogosTotalCount] = useState<number | undefined>(undefined)
  const [currentFolderAllItemsCount, setCurrentFolderAllItemsCount] = useState<number | undefined>(undefined)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [allLoaded, setAllLoaded] = useState<boolean>(false)
  const [editorWindow, setEditorWindow] = useState<Window | null>()
  const isReplaceFlow = !!urlIdToReplace
  const imageToInsert = imageToPreview ?? selectedImage
  const disableReplace = !showInsertButtonEnabled && !!urlIdToReplace && imageToInsert?.id === urlIdToReplace

  const isLogos = useMemo<boolean>(
    () => filterState.activeSource === ImagePickerSource.LOGOS || filterState.activeSource === ImagePickerSource.DYNAMIC_LOGO,
    [filterState.activeSource]
  )

  const activeFolderName = useMemo<string | undefined>(
    () => (typeof filterState.activeFolder === 'number' ? folders.find(({ folderId }) => folderId === filterState.activeFolder)?.name : undefined),
    [filterState.activeFolder, folders]
  )
  const imageItems = isLogos ? logosData : imagesData

  useEffect(() => {
    if (!urlIdToReplace || !imageItems.length || selectedImage) {
      return
    }
    // Try to preselect image that much urlIdToReplace, if images loaded and user didn't select one yet
    const preselectImage = imageItems.find(({ id }) => id === urlIdToReplace)
    if (preselectImage) {
      setSelectedImage(preselectImage)
    }
  }, [selectedImage, imageItems, urlIdToReplace])

  useEffect(() => {
    const { activeSource, activeFolder, activeFilter } = filterState
    const { desc, id: sortColumn } = sortBy
    const commonProps = {
      pageNumber: 0,
      search: searchTerm,
      sortColumn,
      sortDirection: desc ? SortDirection.DESC : SortDirection.ASC,
    }
    setLoadingData(true)
    setCurrentPage(0)
    if (activeSource === ImagePickerSource.LIBRARY) {
      onGetImages({
        folderId: activeFolder,
        onlyFavorite: activeFilter?.name === FilterTypes.FAVORITES,
        includeSvg: !!isLandingPage,
        ...commonProps,
      })
        .then((data) => {
          setImagesData(data)
          setAllLoaded(data.length < IMAGES_DEFAULT_PAGE_SIZE)
        })
        .finally(() => setLoadingData(false))
    } else {
      onGetLogos(commonProps)
        .then(({ totalCount, logos }) => {
          setLogosData(logos)
          setLogosTotalCount(totalCount)
          setAllLoaded(logos.length < IMAGES_DEFAULT_PAGE_SIZE)
        })
        .finally(() => setLoadingData(false))
    }
  }, [filterState, onGetImages, onGetLogos, searchTerm, sortBy])

  useEffect(() => {
    // remove session data on Modal unmount
    return () => {
      removeItem(saveScrollSessionKey)
    }
  }, [])

  const handleEditImage = useCallback(
    (id: string) => !isStory && setEditorWindow(window.open(`${editUrl}type=${isLogos ? 'logo' : 'image'}&id=${id}`)),
    [isLogos, isStory]
  )

  const updateEditedImage = useCallback(() => {
    if (!imageToPreview) {
      return
    }
    const { activeSource } = filterState
    const isLogo = activeSource === ImagePickerSource.LOGOS

    const commonProps = { id: imageToPreview.id }
    const findAndReplaceEditedImage = (image: IImagePickerItem, setData: React.Dispatch<React.SetStateAction<IImagePickerItem[]>>) => {
      // Checking if imageToPreview is modified. Logos doesn't have 'modified', but have timestamp in url.
      const isEdited = isLogo ? image.url !== imageToPreview.url : image.modified !== imageToPreview.modified

      if (isEdited) {
        setImageToPreview(image)
        setData((curData) => {
          const indexToReplace = curData.findIndex(({ id }) => id === image.id)
          if (indexToReplace > -1) {
            curData.splice(indexToReplace, 1, image)
          }
          return curData
        })
      }
    }
    setLoadingData(true)
    if (!isLogo) {
      onGetImageById(commonProps)
        .then((image) => findAndReplaceEditedImage(image, setImagesData))
        .finally(() => setLoadingData(false))
    } else {
      onGetLogoById(commonProps)
        .then((logo) => findAndReplaceEditedImage(logo, setLogosData))
        .finally(() => setLoadingData(false))
    }
  }, [filterState, onGetImageById, onGetLogoById, imageToPreview])

  useEffect(() => {
    const checkEditorWindowClosed = (checkWithDelay = true) => {
      if (!editorWindow) {
        return
      }

      if (editorWindow.closed) {
        setEditorWindow(null)
        updateEditedImage()
      } else {
        // In some cases, when a new window is closed automatically
        // the editorWindow.closed property might not update immediately
        checkWithDelay && setTimeout(() => checkEditorWindowClosed(false), 300)
      }
    }
    const onPageFocus = () => {
      checkEditorWindowClosed()
    }

    window.addEventListener('focus', onPageFocus)
    return () => window.removeEventListener('focus', onPageFocus)
  }, [editorWindow, updateEditedImage])

  const handleInsert = useCallback(() => {
    onImageInsert(imageToPreview || selectedImage, isLogos)
  }, [onImageInsert, selectedImage, imageToPreview, isLogos])

  const handleCancel = useCallback(() => (imageToPreview ? setImageToPreview(undefined) : onImageInsert(undefined)), [imageToPreview, onImageInsert])
  const handlePreview = useCallback(() => selectedImage && setImageToPreview(selectedImage), [selectedImage])
  const handleBack = useCallback(() => {
    if (showImagePickerBackButton) {
      setDynamicImageModalStates?.((prevState) => ({ ...prevState, showDynamicImageModal: true }))
    }
    setImageToPreview(undefined)
  }, [showImagePickerBackButton])

  const handleSearch = useCallback((term: string) => setSearchTerm(term), [])
  const updateFilterState = useCallback((state: Partial<IImagePickerFilterState>) => {
    setLoadingData(true)
    setFilterState((cur) => ({ ...cur, ...state }))
  }, [])

  const handleLoadNext = useCallback(() => {
    const { activeSource, activeFolder, activeFilter } = filterState

    if (!allLoaded) {
      const { desc, id: sortColumn } = sortBy
      const commonProps = {
        pageNumber: currentPage + 1,
        search: searchTerm,
        sortColumn,
        sortDirection: desc ? SortDirection.DESC : SortDirection.ASC,
      }
      if (activeSource === ImagePickerSource.LIBRARY) {
        setLoadingNextData(true)
        return onGetImages({
          folderId: activeFolder,
          onlyFavorite: activeFilter?.name === FilterTypes.FAVORITES,
          includeSvg: !!isLandingPage,
          ...commonProps,
        })
          .then((images) => {
            setCurrentPage(currentPage + 1)
            setImagesData((cur) => [...cur, ...images])
            setAllLoaded(images.length < IMAGES_DEFAULT_PAGE_SIZE)
          })
          .finally(() => setLoadingNextData(false))
      } else {
        setLoadingNextData(true)
        return onGetLogos(commonProps)
          .then(({ totalCount, logos }) => {
            setLogosData((cur) => [...cur, ...logos])
            setCurrentPage(currentPage + 1)
            setLogosTotalCount(totalCount)
            setAllLoaded(logos.length < IMAGES_DEFAULT_PAGE_SIZE)
          })
          .finally(() => setLoadingNextData(false))
      }
    }
    return Promise.resolve()
  }, [filterState, allLoaded, onGetImages, onGetLogos, currentPage, searchTerm, sortBy])

  return (
    <Modal
      className={rootClass}
      dataTest={dataTest}
      isOpen
      noPadding
      header={
        <ImagePickerModalHeader
          onBack={handleBack}
          onSearch={handleSearch}
          searchTerm={searchTerm}
          previewImageTitle={imageToPreview?.title}
          actions={headerActions}
          isReplaceFlow={isReplaceFlow}
          isLogos={isLogos}
          dataTest={`${dataTest}-header`}
          showImagePickerBackButton={showImagePickerBackButton}
        />
      }
    >
      <ModalBody className={`${rootClass}__body`}>
        {imageToPreview ? (
          <ImagePickerModalPreview image={imageToPreview} isLogo={isLogos} onEdit={handleEditImage} />
        ) : (
          <>
            <ImagePickerModalSidebar
              folders={folders}
              loading={loadingFolders}
              filtersState={filterState}
              setFiltersState={setFilterState}
              onSortColumnChange={setSortBy}
              setCurrentFolderAllItemsCount={setCurrentFolderAllItemsCount}
              setDataLoading={setLoadingData}
              logosTotalCount={logosTotalCount}
              allowSvg={allowSvg}
              dataTest={`${dataTest}-sidebar`}
            />
            <ImagePickerModalContentContext.Provider
              value={{
                imageItems,
                isLogos,
                loading: loadingData,
                loadingNextData: loadingNextData,
                filterState,
                updateFilterState,
                sortBy,
                onSortColumnChange: setSortBy,
                onSelect: setSelectedImage,
                onPreview: setImageToPreview,
                accept,
                searchTerm,
                activeFolderName,
                currentFolderAllItemsCount,
                selectedId: selectedImage?.id,
                onUploadImage: headerActions && headerActions[0] ? headerActions[0].onClick : undefined,
                imageMaxSize,
              }}
            >
              <ImagePickerModalContent
                viewTypeRef={viewTypeRef}
                onLoadNext={handleLoadNext}
                allLoaded={allLoaded}
                saveScrollSessionKey={saveScrollSessionKey}
              />
            </ImagePickerModalContentContext.Provider>
          </>
        )}
      </ModalBody>
      <ImagePickerModalFooter
        onInsert={handleInsert}
        onCancel={handleCancel}
        onPreview={handlePreview}
        isPreview={!!imageToPreview}
        selectedTitle={selectedImage?.title}
        isReplaceFlow={isReplaceFlow}
        disableReplace={disableReplace}
        dataTest={`${dataTest}-footer`}
      />
    </Modal>
  )
}

export default ImagePickerModal
