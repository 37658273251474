import { MappingPreview } from '@src/pages/importcontacts/context/ImportContactsContext'

export const getDuplicates = (mappingPreview: MappingPreview[], excludeIndex?: number) =>
  mappingPreview
    .filter(({ friendlyName, displayName, mappingIndex, isCustom }, index, processed) => {
      const name = index === excludeIndex ? '' : friendlyName || displayName || ''
      return (
        processed.map(({ friendlyName, displayName }, index) => (index === excludeIndex ? '' : friendlyName || displayName || '')).indexOf(name) !==
          index &&
        name !== '' &&
        ((mappingIndex !== undefined && mappingIndex !== '') || isCustom)
      )
    })
    .map(({ friendlyName, displayName }, index) => (index === excludeIndex ? '' : friendlyName || displayName || ''))
