import StaticImageNames from '@components/StaticImage/StaticImageNames'
import {
  Program,
  ProgramAlertStep,
  ProgramAppendToSegmentStep,
  ProgramBaseStep,
  ProgramBranchStep,
  ProgramCopyStep,
  ProgramCrmCreateStep,
  ProgramGoToSetStep,
  ProgramOutgoingWebhookStep,
  ProgramRecordSetStep,
  ProgramSendStep,
  ProgramSForceCampaignStep,
  ProgramSmsSendStep,
  ProgramStatus,
  ProgramTaskStep,
  ProgramTrack,
  ProgramUpdateSubscriptionStep,
  ProgramWaitStep,
  ProgramWaitUntilInSegmentStep,
  ProgramWaitUntilStep,
  ProgramWebRequestStep,
} from '@graphql/types/query-types'

export enum ProgramAssetType {
  EMAIL = 'email',
  LIST = 'list',
  SEGMENT = 'segment',
}

export enum ProgramStepType {
  ALERT = 'alert',
  APPEND_TO_SEGMENT = 'appendToSegment',
  BRANCH = 'branch',
  CITRIX_SYNC = 'citrixSync',
  COPY = 'copy',
  COPY_FROM = 'copyFrom',
  CRM_CAMPAIGN = 'salesforceCampaign',
  CRM_CREATE = 'crmCreate',
  EXIT = 'exit',
  EXTERNAL_API = 'externalApi',
  FIELD_SET = 'fieldSet',
  GOTO = 'goto',
  OPT_OUT = 'optOut',
  OUTGOING_WEBHOOK = 'outgoingWebhook',
  SEND = 'send',
  SMS = 'smsSend',
  START = 'start',
  TASK = 'task',
  WAIT = 'wait',
  WAIT_UNTIL = 'waitUntil',
  WAIT_UNTIL_IN_SEGMENT = 'waitUntilInSegment',
  WEBEX_SYNC = 'webexSync',
  UPDATE_SUBSCRIPTION = 'updateSubscription',
}

export enum ProgramStepTypeLabel {
  ALERT = 'Send Alert',
  APPEND_TO_SEGMENT = 'Append to Segment',
  BRANCH = 'Branch',
  CITRIX_SYNC = 'Citrix Sync',
  COPY = 'Copy',
  COPY_FROM = 'Copy From',
  CRM_CAMPAIGN = 'Salesforce Campaign',
  CRM_CREATE = 'Create in CRM',
  EXIT = 'Exit',
  EXTERNAL_API = 'External Api',
  FIELD_SET = 'Change Fields',
  GOTO = 'Go To',
  OPT_OUT = 'Opt Out',
  OUTGOING_WEBHOOK = 'Outgoing Webhook',
  SEND = 'Send Email',
  SMS = 'Send SMS',
  START = 'Start',
  TASK = 'Create Task',
  WAIT = 'Wait',
  WAIT_UNTIL = 'Wait Until',
  WAIT_UNTIL_IN_SEGMENT = 'Wait Until In Segment',
  WEBEX_SYNC = 'Webex Sync',
}

export enum Direction {
  left = 'left',
  right = 'right',
}

export enum SEGMENT_TYPES {
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  MESSAGE_OPEN = 'MESSAGE_OPEN',
  MESSAGE_CLICK = 'MESSAGE_CLICK',
  MESSAGE_SENT_NOT_OPEN_CLICK = 'MESSAGE_SENT_NOT_OPEN_CLICK',
  MESSAGE_SENT_NOT_CLICK = 'MESSAGE_SENT_NOT_CLICK',
  MESSAGE_SENT_NOT_OPEN = 'MESSAGE_SENT_NOT_OPEN',
  MESSAGE_SEND = 'MESSAGE_SEND',
  NOT_MESSAGE_SEND = 'NOT_MESSAGE_SEND',
}

export enum ProgramState {
  RUNNING = 'running',
  PAUSED = 'stopped',
  DRAFT = 'draft',
  ERROR = 'error',
}

export interface BranchSize {
  left: number
  right: number
}

export interface StepChildren {
  left: TrackStep
  right: TrackStep
}

export interface ProgramAlertStepExt extends ProgramAlertStep, TrackSteps {
  stepId: string
}

export interface EmptyState {
  imgSrc: StaticImageNames
  headline: string
  text: string
  linkText: string
  link: string
}

export interface ProgramFlowTree {
  nodePositions: { [key: string]: TrackStep }
  tracks: Track[]
  gridSize: {
    bottom: number
    left: number
    right: number
    top: number
  }
}

export interface Position {
  x: number
  y: number
}

export interface TrackStep {
  trackIndex: number
  stepIndex: number
}

export interface ActiveTrack {
  id: string
  trackStep: TrackStep
  skip?: boolean
  position: number
}

export interface TrackSteps {
  stepId: string
  stepType: string
  child?: TrackStep
  position?: Position
  offset?: Direction
  children?: StepChildren
  first?: boolean
  branchSize?: BranchSize
  goToStepId?: string
  letter?: string
  isNew?: boolean
}

export interface ProgramBaseStepExt extends ProgramBaseStep, TrackSteps {
  stepId: string
}

export interface ProgramWaitStepExt extends ProgramWaitStep, TrackSteps {
  stepId: string
}
export interface ProgramWaitUntilStepExt extends ProgramWaitUntilStep, TrackSteps {
  stepId: string
}
export interface ProgramWaitUntilInSegmentStepExt extends ProgramWaitUntilInSegmentStep, TrackSteps {
  stepId: string
}

export interface ProgramGoToStepExt extends ProgramGoToSetStep, TrackSteps {
  stepId: string
}

export interface ProgramBranchStepExt extends ProgramBranchStep, TrackSteps {
  stepId: string
}

export interface ProgramCopyStepExt extends ProgramCopyStep, TrackSteps {
  stepId: string
}

export interface ProgramAppendToSegmentStepExt extends ProgramAppendToSegmentStep, TrackSteps {
  stepId: string
}

export interface ProgramSendStepExt extends ProgramSendStep, TrackSteps {
  stepId: string
}

export interface ProgramTaskStepExt extends ProgramTaskStep, TrackSteps {
  stepId: string
}

export interface ProgramWebRequestStepExt extends ProgramWebRequestStep, TrackSteps {
  stepId: string
}

export interface ProgramCreateInCRMStepExt extends ProgramCrmCreateStep, TrackSteps {
  stepId: string
}
export interface ProgramRecordSetStepExt extends ProgramRecordSetStep, TrackSteps {
  stepId: string
}

export interface ProgramSForceCampaignStepExt extends ProgramSForceCampaignStep, TrackSteps {
  stepId: string
}

export interface ProgramSmsSendStepExt extends ProgramSmsSendStep, TrackSteps {
  stepId: string
}

export interface ProgramOutgoingWebhookStepExt extends ProgramOutgoingWebhookStep, TrackSteps {
  stepId: string
}

export interface ProgramUpdateSubscriptionStepExt extends ProgramUpdateSubscriptionStep, TrackSteps {
  stepId: string
}

export interface Track extends ProgramTrack {
  letter?: string
  steps: Step[]
}

export type Step =
  | ProgramBaseStepExt
  | ProgramWaitStepExt
  | ProgramWaitUntilStepExt
  | ProgramWaitUntilInSegmentStepExt
  | ProgramGoToStepExt
  | ProgramBranchStepExt
  | ProgramCopyStepExt
  | ProgramAppendToSegmentStepExt
  | ProgramAlertStepExt
  | ProgramSendStepExt
  | ProgramWebRequestStepExt
  | ProgramTaskStepExt
  | ProgramCreateInCRMStepExt
  | ProgramSForceCampaignStepExt
  | ProgramRecordSetStepExt
  | ProgramSmsSendStepExt
  | ProgramOutgoingWebhookStepExt
  | ProgramUpdateSubscriptionStepExt

export const ADJUST_PARENTS = false

export interface ProgramFlowTree {
  nodePositions: { [key: string]: TrackStep }
  tracks: Track[]
  gridSize: {
    bottom: number
    left: number
    right: number
    top: number
  }
}

export interface TrackStep {
  trackIndex: number
  stepIndex: number
}

export interface ActiveTrack {
  id: string
  trackStep: TrackStep
  skip?: boolean
  position: number
}

export interface ProgramBaseStepExt extends ProgramBaseStep, TrackSteps {
  stepId: string
}

export interface ProgramWaitStepExt extends ProgramWaitStep, TrackSteps {
  stepId: string
}
export interface ProgramWaitUntilStepExt extends ProgramWaitUntilStep, TrackSteps {
  stepId: string
}
export interface ProgramWaitUntilInSegmentStepExt extends ProgramWaitUntilInSegmentStep, TrackSteps {
  stepId: string
}

export interface ProgramGoToStepExt extends ProgramGoToSetStep, TrackSteps {
  stepId: string
}

export interface ProgramBranchStepExt extends ProgramBranchStep, TrackSteps {
  stepId: string
}

export interface ProgramCopyStepExt extends ProgramCopyStep, TrackSteps {
  stepId: string
}

export interface ProgramAppendToSegmentStepExt extends ProgramAppendToSegmentStep, TrackSteps {
  stepId: string
}

export interface ProgramAlertStepExt extends ProgramAlertStep, TrackSteps {
  stepId: string
}

export interface ProgramSendStepExt extends ProgramSendStep, TrackSteps {
  stepId: string
}

export interface ProgramTaskStepExt extends ProgramTaskStep, TrackSteps {
  stepId: string
}

export interface ProgramWebRequestStepExt extends ProgramWebRequestStep, TrackSteps {
  stepId: string
}

export interface ProgramCreateInCRMStepExt extends ProgramCrmCreateStep, TrackSteps {
  stepId: string
}
export interface ProgramRecordSetStepExt extends ProgramRecordSetStep, TrackSteps {
  stepId: string
}

export interface ProgramSForceCampaignStepExt extends ProgramSForceCampaignStep, TrackSteps {
  stepId: string
}

export interface ProgramSmsSendStepExt extends ProgramSmsSendStep, TrackSteps {
  stepId: string
}

export interface Track extends ProgramTrack {
  letter?: string
  steps: Step[]
}

export interface BuildTreeState {
  gridPositionToId: { [key: string]: TrackStep }
  tracks: Track[] // All tracks with id mapping to track
  activeTracks: ActiveTrack[] // tracks that are currently being processed in the current row
}

export interface ProgramErrors {
  mainErrors: string[]
  exitErrors: string[]
  stepErrors: ProgramStepError[]
}

export interface ProgramStepError {
  id: string
  name: string
  type: string
  errors: string[]
  stepType?: ProgramStepType
  showAOContactsIcon?: boolean
}

export interface CreateMessageResult {
  newId: string
  originalId: string
  title: string
  status: string
}

export interface CreatedMessage {
  message?: {
    results: CreateMessageResult[]
  }
  error?: any
  loading?: boolean
}

export interface EditProgramState {
  program?: Program
  error?: any
  loading: boolean
  saveStatus?: ProgramStatus
  saveFinal?: boolean
  newProgramId?: string
  createdMessage?: CreatedMessage
  hasAOContactsSource?: boolean
}
