import React, { FC } from 'react'

import { ComponserHeaderTabsButtonProps } from '@complex/GlobalComposerHeader/GlobalComposerHeader'
import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import Svg, { SvgNames } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'

import './ReviewBar.css'

interface ReviewBarProps {
  dataTest?: string
  onBackClick: () => void
  backButtonText: string
  floatButtons?: ComponserHeaderTabsButtonProps[]
  primaryActionButton?: ComponserHeaderTabsButtonProps
}

const rootClass = 'global-composer-header-review-bar'

const ReviewBar: FC<ReviewBarProps> = (props: ReviewBarProps) => {
  const { dataTest = rootClass, backButtonText, onBackClick, floatButtons, primaryActionButton } = props
  const { t } = useTranslation()

  const renderFloatButtons = () => {
    return floatButtons?.map(({ text, icon, tooltipContent, disabled, onClick }, index) => (
      <Tooltip
        position={'bottom'}
        key={index}
        hide={!tooltipContent}
        trigger={
          <Button
            buttonType={ButtonType.FLOAT_DARK_BG}
            onClick={onClick}
            disabled={disabled}
            className={`${rootClass}__header-button`}
            dataTest={`${dataTest}-float-button-${index}`}
          >
            {icon && <Svg name={icon} />}
            {text}
          </Button>
        }
      >
        {tooltipContent}
      </Tooltip>
    ))
  }

  const renderPrimaryButton = () => {
    if (!primaryActionButton) return null
    const { text, icon, tooltipContent, disabled, onClick } = primaryActionButton
    return (
      <Tooltip
        position="left"
        arrowOffset={0}
        minimalPadding={false}
        hide={!tooltipContent}
        trigger={
          <Button
            buttonType={ButtonType.PRIMARY}
            iconPosition={ButtonIconPosition.LEFT}
            onClick={onClick}
            disabled={disabled}
            dataTest={`${dataTest}-primary-button`}
          >
            {icon && <Svg name={icon} fill={SvgColor.WHITE} />}
            {text}
          </Button>
        }
      >
        {tooltipContent}
      </Tooltip>
    )
  }

  return (
    <div className={rootClass} data-test={dataTest}>
      <div className={`${rootClass}__left`}>
        <Button buttonType={ButtonType.FLOAT_DARK_BG} onClick={onBackClick} dataTest={`${dataTest}-back-button`}>
          <Svg name={SvgNames.arrowLeft} />
          {t(backButtonText)}
        </Button>
      </div>
      <div className={`${rootClass}__right`}>
        {floatButtons && renderFloatButtons()}
        {primaryActionButton && renderPrimaryButton()}
      </div>
    </div>
  )
}

export default ReviewBar
