import { gql } from '@apollo/client'

export default gql`
  query checkSubjectAndPreviewText($msgId: String!) {
    checkSubjectAndPreviewText(msgId: $msgId) {
      subject {
        field
        isMissingForAll
        isCrmField
        isInvalidFormat
        lists
        hasFallbackText
      }
      previewText {
        field
        isMissingForAll
        isCrmField
        isInvalidFormat
        lists
        hasFallbackText
      }
    }
  }
`
