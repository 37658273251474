import { RefObject } from 'react'
import { DropTargetMonitor } from 'react-dnd'

import { Column, ColumnStatus } from '@components/ColumnsOrderModal/components/DraggableColumn/DraggableColumn'

export const isLocked = (column: Column) => column.status === ColumnStatus.LOCKED
export const isHidden = (column: Column) => column.status === ColumnStatus.HIDDEN

export const moveColumn = (column: Column, droppedFrom: number, droppedAt: number, columns: Column[]) => {
  const columnAdded = [...columns.slice(0, droppedAt), column, ...columns.slice(droppedAt)]
  columnAdded.splice(droppedFrom + (droppedFrom < droppedAt ? 0 : 1), 1)
  return columnAdded
}

export const dropColumn = (column: Column, columns: Column[], droppedAt: number) => {
  return moveColumn(column, column.index, droppedAt, columns)
}

export const lockColumn = (column: Column, columns: Column[], lockedColumnsSize: number) => {
  return moveColumn({ ...column, status: isLocked(column) ? ColumnStatus.DEFAULT : ColumnStatus.LOCKED }, column.index, lockedColumnsSize, columns)
}

export const hideColumn = (column: Column, columns: Column[]) => {
  const { index, status } = column
  return [
    ...columns.slice(0, index),
    { ...column, status: status === ColumnStatus.HIDDEN ? ColumnStatus.DEFAULT : ColumnStatus.HIDDEN },
    ...columns.slice(index + 1),
  ]
}

export const isOverUpperHalf = (ref: RefObject<HTMLDivElement>, monitor: DropTargetMonitor) => {
  const hoverBoundingRect = ref.current?.getBoundingClientRect()
  // Get vertical middle
  if (hoverBoundingRect) {
    const midY = hoverBoundingRect.top + (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
    // Determine mouse position
    const pointerOffset = monitor.getClientOffset()
    return (pointerOffset && pointerOffset.y < midY) || false
  }
  return false
}
