import React, { FC } from 'react'

import { Program } from '@graphql/types/query-types'
import { EditCRMStepV2Container } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/EditCRMStepV2Container'
import { ProgramCreateInCRMStepExt, Step } from '@utils/program/program.constants'

interface EditCreateInCRMStepV2Props {
  dataTest?: string
  step: Step
  saveStepAndProgram: (step: Step | null, program?: Program) => void
  closeModal: VoidFunction
  program: Program
  isRunning: boolean
}

const EditCreateInCRMStepV2: FC<EditCreateInCRMStepV2Props> = (props: EditCreateInCRMStepV2Props) => {
  const { dataTest, step, saveStepAndProgram, closeModal, program, isRunning } = props

  const crmStep = step as ProgramCreateInCRMStepExt

  const onSubmit = (newData: ProgramCreateInCRMStepExt) => {
    saveStepAndProgram(newData)
  }

  return (
    <EditCRMStepV2Container
      dataTest={dataTest}
      step={crmStep}
      onSubmit={onSubmit}
      closeModal={closeModal}
      program={program}
      isRunning={isRunning}
      isModal
    />
  )
}

export default EditCreateInCRMStepV2
