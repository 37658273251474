import React, { ReactNode } from 'react'

import { History } from 'history'
import { TFunction } from 'i18next'

import {
  CustomSource,
  CustomSourceItems,
  ItemDtoWithBeeComposer,
  ListingPageProps,
  ListPageCommonState,
  SetError,
  SetFilter,
  Update,
} from '@complex/ListingPage/Context/ListingPageCommon.context'
import { listPageContextCommonValues, PAGE_SIZE, SEARCH_PAGE_SIZE } from '@complex/ListingPage/Utils/ListingPage.constants'
import { BulkModalProps } from '@components/BulkActionsModal/BulkActionsModal'
import { EmptyListingProps } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { Status } from '@components/StatusToast/StatusToast'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography from '@components/Typography/Typography'
import { ItemDto, LabelDto, SubTypeDto } from '@graphql/types/microservice/categorization-types'
import { AccountSettings, BulkAssetMutationResponse, Maybe } from '@graphql/types/query-types'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import { SortDirection } from '@utils/common'
import { FilterDefinition, FilterTypes } from '@utils/filter'
import { buildComplexFoldersTreeHeader } from '@utils/folderUtils'
import { logNewRelicError } from '@utils/new-relic.utils'
import { getItem, removeItem, setItem } from '@utils/sessionStorage'
import { ellip } from '@utils/strings'

import { findSubTypeWithName, processSubTypeValues, toggleOffFilterSubTypes } from './ListingPage.subTypes.utils'
import { FilterQueryParams } from '../Components/Sidebar/Utils/Sidebar.utils'

export interface SearchRequestParams {
  type: string
  field: string
  fields: string[]
  query: string
  allItems?: boolean
  folder?: number
  activeTagId?: number
  subTypes: string[]
  pageNumber: number
  pageSize: number
}

export enum ListingPageSession {
  COLLAPSED_MENU = 'listingPage:collapsedMenu',
  CUSTOM_MENU_SECTION_CLOSED = 'listingPage:customMenuSectionClosed',
  CUSTOM_FILTER_SELECTED = 'listingPage:customFilter',
  FILTER = 'listingPage:filter',
  FOLDER = 'listingPage:folder',
  FOLDER_MENU_SECTION_CLOSED = 'listingPage:folderMenuSectionClosed',
  SALES_USERS_ENABLED_CONFIRMATION = 'listingPage:salesUsersEnabledConfirmation',
  SEARCH_QUERY = 'listingPage:searchQuery',
  SEARCH_SHOW_ALL = 'listingPage:showAll',
  SUBTYPES = 'listingPage:subtypes',
  TAG = 'listingPage:tag',
  TAG_MENU_SECTION_CLOSED = 'listingPage:tagMenuSectionClosed',
}

export const tagCommonFields = {
  showManageTag: false,
  isProcessingAction: false,
  fetchTags: true,
  fetchItems: true,
  search: '',
  searchItemsResults: [],
}

export const isSalesUserAccount = (accountSettings: AccountSettings) => !accountSettings.isAccountAdmin && !!accountSettings.userSettings.userDesktop

interface ListingPageSessionData {
  sessionCustomFilterSelected: boolean
  sessionFilter: string
  sessionFolderId: number
  sessionSalesUsersEnabledConfirmation: boolean
  sessionSearch: string
  sessionShowAll: boolean
  sessionSubTypes: string[]
  sessionTagId: number
}

export const getSessionData = (itemType: string, disableSessionData = false): ListingPageSessionData => {
  const searchShowAll = getItem(`${itemType}:${ListingPageSession.SEARCH_SHOW_ALL}`)
  const sessionSubTypes = getItem(`${itemType}:${ListingPageSession.SUBTYPES}`)
  return disableSessionData
    ? {
        sessionCustomFilterSelected: false,
        sessionFilter: '',
        sessionFolderId: 0,
        sessionSalesUsersEnabledConfirmation: true,
        sessionSearch: '',
        sessionShowAll: true,
        sessionSubTypes: [],
        sessionTagId: 0,
      }
    : {
        sessionCustomFilterSelected: JSON.parse(getItem(`${itemType}:${ListingPageSession.CUSTOM_FILTER_SELECTED}`) ?? '""') === 'true',
        sessionFilter: JSON.parse(getItem(`${itemType}:${ListingPageSession.FILTER}`) ?? '""'),
        sessionFolderId: parseInt(JSON.parse(getItem(`${itemType}:${ListingPageSession.FOLDER}`) ?? '""')),
        sessionSalesUsersEnabledConfirmation: ['true', undefined].includes(getItem(ListingPageSession.SALES_USERS_ENABLED_CONFIRMATION) ?? undefined),
        sessionSearch: JSON.parse(getItem(`${itemType}:${ListingPageSession.SEARCH_QUERY}`) ?? '""'),
        sessionShowAll: searchShowAll !== null ? JSON.parse(searchShowAll ?? '""') === 'true' : true,
        sessionSubTypes: sessionSubTypes ? sessionSubTypes.split(',') : [''],
        sessionTagId: parseInt(JSON.parse(getItem(`${itemType}:${ListingPageSession.TAG}`) ?? '""')),
      }
}

export const onSetFolder = (values: ListPageCommonState, update: Update, folderId: number) => {
  const {
    folders,
    itemType,
    defaultSubTypes,
    activeSubTypes,
    search,
    listingPageProps: { alwaysPreserveSearchTerm, disableSessionData, sidebarProps, subTypes },
  } = values

  const folderPath = buildComplexFoldersTreeHeader(folderId, folders)
  const newSubTypes = toggleOffFilterSubTypes(defaultSubTypes, activeSubTypes, subTypes)

  update({
    listingPageProps: {
      ...values.listingPageProps,
      sidebarProps: {
        ...sidebarProps,
        customFilterSelected: false,
      },
    },
    folderPath,
    activeFolderId: folderId,
    loading: true,
    fetchItems: true,
    searchAll: false,
    activeTagId: undefined,
    filterActive: undefined,
    activeSubTypes: newSubTypes,
    search: alwaysPreserveSearchTerm ? search : '',
    searchItemsResults: [],
    currentPage: 0,
  })

  if (!disableSessionData) {
    removeItem(`${itemType}:${ListingPageSession.FILTER}`)
    removeItem(`${itemType}:${ListingPageSession.TAG}`)
    removeItem(`${itemType}:${ListingPageSession.SEARCH_QUERY}`)
    removeItem(`${itemType}:${ListingPageSession.SEARCH_SHOW_ALL}`)
    removeItem(`${itemType}:${ListingPageSession.CUSTOM_FILTER_SELECTED}`)
    setItem(`${itemType}:${ListingPageSession.SUBTYPES}`, newSubTypes.join(','))
    setItem(`${itemType}:${ListingPageSession.FOLDER}`, JSON.stringify(folderId))
  }
}

export const onSetFilter = (
  values: ListPageCommonState,
  update: Update,
  filter: FilterDefinition,
  customFilterSelected: boolean,
  clearSubTypes = false
) => {
  const {
    listingPageProps,
    itemType,
    defaultSubTypes,
    listingPageProps: {
      disableSessionData,
      sidebarProps,
      sidebarProps: { allItemFilter },
    },
  } = values

  const activeSubTypes = clearSubTypes ? { activeSubTypes: [...defaultSubTypes] } : {}

  update({
    ...activeSubTypes,
    filterActive: filter,
    fetchItems: true,
    searchAll: filter === allItemFilter,
    activeFolderId: undefined,
    currentPage: 0,
    searchItemsResults: [],
    activeTagId: undefined,
    loading: true,
    listingPageProps: {
      ...listingPageProps,
      sidebarProps: {
        ...sidebarProps,
        customFilterSelected,
      },
    },
  })

  if (!disableSessionData) {
    if (customFilterSelected) {
      setItem(`${itemType}:${ListingPageSession.CUSTOM_FILTER_SELECTED}`, JSON.stringify('true'))
    } else {
      setItem(`${itemType}:${ListingPageSession.CUSTOM_FILTER_SELECTED}`, JSON.stringify('false'))
    }

    if (filter === allItemFilter) {
      setItem(`${itemType}:${ListingPageSession.SEARCH_SHOW_ALL}`, JSON.stringify('true'))
    } else {
      removeItem(`${itemType}:${ListingPageSession.SEARCH_SHOW_ALL}`)
    }

    setItem(`${itemType}:${ListingPageSession.FILTER}`, JSON.stringify(filter.name))
    removeItem(`${itemType}:${ListingPageSession.FOLDER}`)
    removeItem(`${itemType}:${ListingPageSession.SEARCH_QUERY}`)
    removeItem(`${itemType}:${ListingPageSession.SUBTYPES}`)
    removeItem(`${itemType}:${ListingPageSession.TAG}`)
  }
}

const getActiveFilterProps = (values: ListPageCommonState, behaveAsFilter?: FilterDefinition) => {
  const {
    activeFolderId,
    activeTagId,
    filterActive,
    search,
    listingPageProps: {
      sidebarProps: { allItemFilter },
    },
  } = values

  let activeFilterProps = {
    filterActive,
    activeFolderId,
    activeTagId,
    ...(search ? {} : { searchAll: false }),
  }

  if (behaveAsFilter) {
    activeFilterProps = { ...activeFilterProps, activeFolderId: undefined, activeTagId: undefined, filterActive: behaveAsFilter }
  } else if (activeFolderId) {
    activeFilterProps = { ...activeFilterProps, activeFolderId }
  } else if (activeTagId) {
    activeFilterProps = { ...activeFilterProps, activeTagId }
  } else if (!filterActive && !behaveAsFilter) {
    activeFilterProps = { ...activeFilterProps, filterActive: allItemFilter, searchAll: true }
  }

  return activeFilterProps
}

const setActiveFilterSessionData = (activeFilterProps: Partial<ListPageCommonState>, itemType?: ItemType) => {
  if (activeFilterProps.filterActive) {
    setItem(`${itemType}:${ListingPageSession.FILTER}`, JSON.stringify(activeFilterProps.filterActive.name))
  }
  if ('activeFolderId' in activeFilterProps) {
    removeItem(`${itemType}:${ListingPageSession.FOLDER}`)
  }
  if ('activeTagId' in activeFilterProps) {
    removeItem(`${itemType}:${ListingPageSession.TAG}`)
  }
}

export const onSetSubType = (values: ListPageCommonState, update: Update, subType: string, toggleOffSubTypes?: string[]) => {
  const {
    itemType,
    activeSubTypes,
    search,
    searchAll,
    currentPage,
    listingPageProps: { alwaysPreserveSearchTerm, subTypes, disableSessionData },
  } = values

  const behaveAsFilter = subTypes && findSubTypeWithName(subTypes, subType)?.behaveAsFilter

  const newSubTypes = behaveAsFilter
    ? [subType]
    : activeSubTypes.includes(subType)
    ? activeSubTypes.filter((curSubType) => curSubType !== subType)
    : [...activeSubTypes.filter((curSubType) => !toggleOffSubTypes?.includes(curSubType)), ...(subType !== '' ? [subType] : [])]

  const activeFilterProps = getActiveFilterProps(values, behaveAsFilter)

  const shouldFetch = !(search && searchAll)

  update({
    search: alwaysPreserveSearchTerm ? search : '',
    activeSubTypes: newSubTypes,
    fetchItems: true,
    currentPage: shouldFetch ? 0 : currentPage,
    ...activeFilterProps,
  })

  if (!disableSessionData) {
    setItem(`${itemType}:${ListingPageSession.SUBTYPES}`, newSubTypes.join(','))

    if (activeFilterProps) {
      setActiveFilterSessionData(activeFilterProps, itemType)
    }

    if (newSubTypes[0] !== '') {
      removeItem(`${itemType}:${ListingPageSession.SEARCH_SHOW_ALL}`)
    }
  }
}

export const onSetTag = (containerValues: ListPageCommonState, update: Update, tag: LabelDto, clicked = false) => {
  const {
    activeTagId,
    itemType,
    activeSubTypes,
    defaultSubTypes,
    search,
    listingPageProps: { alwaysPreserveSearchTerm, subTypes, disableSessionData },
  } = containerValues

  if (clicked) {
    const newSubTypes = toggleOffFilterSubTypes(defaultSubTypes, activeSubTypes, subTypes)
    if (!disableSessionData) {
      removeItem(`${itemType}:${ListingPageSession.FOLDER}`)
      removeItem(`${itemType}:${ListingPageSession.SEARCH_QUERY}`)
      removeItem(`${itemType}:${ListingPageSession.SEARCH_SHOW_ALL}`)
      removeItem(`${itemType}:${ListingPageSession.FILTER}`)
      removeItem(`${itemType}:${ListingPageSession.CUSTOM_FILTER_SELECTED}`)
      setItem(`${itemType}:${ListingPageSession.SUBTYPES}`, newSubTypes.join(','))
      setItem(`${itemType}:${ListingPageSession.TAG}`, JSON.stringify(tag.id))
    }

    update({
      folderPath: [],
      activeTagId: tag.id,
      searchAll: false,
      fetchItems: true,
      activeFolderId: undefined,
      showManageTag: false,
      filterActive: undefined,
      activeSubTypes: newSubTypes,
      search: alwaysPreserveSearchTerm ? search : '',
      searchItemsResults: [],
      currentPage: 0,
      loading: true,
    })
    return
  }

  if (activeTagId) {
    update({
      ...tagCommonFields,
      isEditingTag: false,
    })
  } else {
    update({
      ...tagCommonFields,
      isEditingTag: !tag.id,
      fetchItems: false,
      fetchNextPageItems: false,
    })
  }
}

export const onChangeCustomSource = (containerValues: ListPageCommonState, update: Update, customSource: CustomSource) => {
  const {
    itemType,
    selectedCustomSource,
    listingPageProps,
    listingPageProps: {
      disableSessionData,
      sidebarProps,
      sidebarProps: { allItemFilter, hideFolders, hideTags },
    },
  } = containerValues

  if (customSource.label === selectedCustomSource?.label) {
    return
  }

  if (!disableSessionData) {
    removeItem(`${itemType}:${ListingPageSession.CUSTOM_FILTER_SELECTED}`)
    removeItem(`${itemType}:${ListingPageSession.FILTER}`)
    removeItem(`${itemType}:${ListingPageSession.FOLDER}`)
    removeItem(`${itemType}:${ListingPageSession.SEARCH_QUERY}`)
    removeItem(`${itemType}:${ListingPageSession.SEARCH_SHOW_ALL}`)
    removeItem(`${itemType}:${ListingPageSession.SUBTYPES}`)
    removeItem(`${itemType}:${ListingPageSession.TAG}`)
  }

  const filterActive = customSource.filterTrigger ?? customSource.allItemFilter

  update({
    activeSubTypes: [...(customSource?.listingPageProps?.defaultSubTypes ?? []), ...(customSource?.listingPageProps?.defaultActiveSubTypes ?? [])],
    selectedCustomSource: customSource,
    filterActive,
    fetchFolders: true,
    fetchTags: true,
    loading: true,
    itemType: (customSource.itemType as ItemType) ?? itemType,
    listingPageProps: {
      ...listingPageProps,
      sidebarProps: {
        ...sidebarProps,
        allItemFilter: customSource.allItemFilter ?? allItemFilter,
        hideTags: customSource.sidebarProps?.hideTags ?? hideTags,
        hideFolders: customSource.sidebarProps?.hideFolders ?? hideFolders,
      },
    },
  })
}

export const onSetError = (update: Update, message: string | ReactNode, error: unknown) => {
  update({
    loading: false,
    initialPageLoading: false,
    isEditingTag: false,
    statusToast: {
      statusMessage: message,
      status: Status.FAIL,
      showStatusToast: true,
    },
  })
  logNewRelicError(error)
}

export const onSetStatusToast = (update: Update, message: string | ReactNode, status: Status, folderName?: string, hasTimeout = true) => {
  update({
    hoveredFolder: undefined,
    creatingFolder: false,
    fetchFolders: true,
    statusToast: {
      status,
      statusMessage: message,
      showStatusToast: true,
      folderName,
      hasTimeout,
    },
  })
}

export const bulkResponseToProps = (update: Update, bulkResponse: BulkAssetMutationResponse, bulkActionKey?: string): BulkModalProps => {
  const errorMessages = bulkResponse?.failedIds?.map((asset) => asset?.name ?? '').filter((asset) => !!asset) ?? []
  const successMessages = bulkResponse?.successIds?.map((asset) => asset?.name ?? '').filter((asset) => !!asset) ?? []
  const errorDescriptions = bulkResponse?.failedIds?.filter((asset) => !!asset.name).map((asset) => asset?.reason ?? '') ?? []

  return {
    isOpen: true,
    errorMessages,
    successMessages,
    errorDescriptions,
    successTitle: `${bulkActionKey}.SuccessTitle`,
    errorTitle: `${bulkActionKey}.ErrorTitle`,
    title: `${bulkActionKey}.Title`,
    warnings: (
      <Typography
        text={`${bulkActionKey}.Warnings`}
        tagComponents={{ TextLink: <TextLink link={'https://connect.act-on.com/hc/en-us'} size={TextLinkSize.LARGE} /> }}
        inline
      />
    ),
    onClose: () => update({ showBulkResponse: false, fetchItems: true, forceResetSelectedRows: true }),
  }
}

export const onSetBulkActionsModal = (
  update: Update,
  bulkResponse: BulkAssetMutationResponse,
  bulkActionKey?: string,
  customProps?: Partial<BulkModalProps>
) => {
  const bulkResponseProps = { ...bulkResponseToProps(update, bulkResponse, bulkActionKey), ...customProps }
  update({ showBulkResponse: true, bulkResponseProps })
}

export const parseItemDto = <T extends ItemDto>(item: ItemDto): T => {
  return {
    ...JSON.parse(item.item ?? '{}'),
    id: item.id,
    tags: item.labels,
    folderId: item.folderId,
    position: item.position,
    externalId: item.externalId,
    isFavorite: item.isFavorite,
    createdTime: `${new Date(item.createdTime)}`,
    updatedTime: item.updatedTime,
    subTypeDTO: item.subTypeDTO,
  }
}

export const parseItemDtoResult = (items: ItemDto[]): ItemDtoWithBeeComposer[] => {
  return items?.map(parseItemDto) ?? []
}

export const onSetItems = async (update: Update, setError: SetError, containerValues: ListPageCommonState, newItems: ItemDto[]) => {
  const {
    listingPageProps: {
      onItemsFetched,
      addCustomData,
      tableProps: { buildSubRows },
    },
    items,
    search,
    searchItemsResults,
    currentPage,
    fetchNextPageItems,
  } = containerValues

  const existingItems = !!search ? searchItemsResults : items
  const parsedItems = addCustomData ? await addCustomData(parseItemDtoResult(newItems)) : parseItemDtoResult(newItems)
  const parsedSubRowItems = buildSubRows ? buildSubRows(parsedItems) : parsedItems

  const data = fetchNextPageItems ? [...existingItems, ...parsedSubRowItems] : parsedSubRowItems

  const updateCommon = {
    currentPage,
    searchResults: parsedItems?.length,
    allItemsLoaded: parsedItems.length < PAGE_SIZE,
    fetchItems: false,
    fetchNextPageItems: false,
    loading: false,
    isEditingTag: false,
    initialPageLoading: false,
    isProcessingAction: false,
    forceResetSelectedRows: false,
  }

  if (!!search) {
    update({
      ...updateCommon,
      searchItemsResults: data,
    })
  } else {
    update({
      ...updateCommon,
      items: data,
    })
  }
  onItemsFetched?.(parsedItems, setError)
}

export const filterOutSelectedItems = (items: ItemDto[], selectedIds: number[]) => items.filter((item) => !selectedIds.includes(item.id))

export const onSetCustomSourceItems = (
  update: Update,
  newItems: ItemDto[],
  containerValues: ListPageCommonState,
  customSourceItems: CustomSourceItems,
  { label: source }: CustomSource,
  currentPage: number,
  isSearch?: boolean
) => {
  const {
    listingPageProps: {
      tableProps: { buildSubRows },
    },
  } = containerValues

  const parsedItems = parseItemDtoResult(newItems)
  const buildedItems = buildSubRows ? buildSubRows(parsedItems) : parsedItems

  const data = currentPage === 0 ? parsedItems : [...customSourceItems[source], ...parsedItems]
  const updateCommon = {
    currentPage,
    searchResults: parsedItems?.length,
    allItemsLoaded: parsedItems.length < PAGE_SIZE,
    fetchItems: false,
    fetchNextPageItems: false,
    loading: false,
    isEditingTag: false,
    initialPageLoading: false,
    isProcessingAction: false,
    forceResetSelectedRows: false,
  }

  if (isSearch) {
    update({
      ...updateCommon,
      searchItemsResults: parsedItems,
    })
  } else {
    update({
      ...updateCommon,
      customSourceItems: { ...customSourceItems, [source]: data },
      items: currentPage === 0 ? buildedItems : [...customSourceItems[source], ...buildedItems],
    })
  }
}

const getPagination = (values: ListPageCommonState) => {
  const { fetchNextPageItems, currentPage, search } = values
  const fetchPageSize = !!search ? SEARCH_PAGE_SIZE : PAGE_SIZE
  const pageNumber = fetchNextPageItems ? currentPage : 0
  const pageSize = fetchNextPageItems ? fetchPageSize : fetchPageSize * (currentPage + 1)
  return { pageNumber, pageSize }
}

export const getCommonSearchParams = (values: ListPageCommonState): SearchRequestParams => {
  const { itemType, search, activeSubTypes, defaultSubTypes, searchAll, searchFields } = values
  return {
    type: `${itemType}`,
    field: 'name',
    fields: searchFields,
    query: search,
    allItems: searchAll ?? false,
    subTypes: searchAll ? processSubTypeValues(defaultSubTypes) : processSubTypeValues(activeSubTypes),
    ...getPagination(values),
  }
}

export const getCommonFetchParams = (values: ListPageCommonState): FilterQueryParams => {
  const { itemType, sortBy, search, activeSubTypes } = values

  return {
    type: `${itemType}`,
    fieldType: sortBy?.[0].fieldType ?? 'string',
    orderBy: sortBy?.[0].id,
    direction: sortBy?.[0].desc ? SortDirection.DESC : SortDirection.ASC,
    value: search,
    field: 'name',
    subTypes: processSubTypeValues(activeSubTypes),
    ...getPagination(values),
  }
}

export const hasSubType = (value?: string, subTypes?: Maybe<SubTypeDto>[]) => {
  return subTypes?.map((subType) => subType?.name).includes(value) ?? false
}

export const useGetDefaultValues = (listingPageProps: ListingPageProps, dataTest: string, itemType?: ItemType): ListPageCommonState => {
  const { sessionCustomFilterSelected, sessionSearch, sessionFolderId, sessionTagId, sessionShowAll, sessionSubTypes } = getSessionData(
    `${itemType}`,
    listingPageProps.disableSessionData
  )

  const {
    sortBy,
    canDeleteItems = true,
    canShareToChildAccounts = true,
    disableShareToChildAccountsTooltip,
    canShareToCatalog = true,
    canEdit = true,
    canCreate = true,
    hideDeleteItems = false,
    hideMoveItems = false,
    defaultSubTypes = [],
    searchFields = ['name'],
    defaultActiveSubTypes = [],
    tableProps: { listPage },
  } = listingPageProps
  const { canShareAsset: featureFlagCanShareAsset, canPublish: featureFlagCanPublish } = useAccountSettings()

  const activeSessionSubTypes = sessionSubTypes[0] === '' ? [] : sessionSubTypes
  const activeSubTypes = [...new Set([...activeSessionSubTypes, ...defaultSubTypes, ...defaultActiveSubTypes])]

  const disableShareToChildAccountsTooltipObj = disableShareToChildAccountsTooltip ? { disableShareToChildAccountsTooltip } : {}

  return {
    ...listPageContextCommonValues,
    ...disableShareToChildAccountsTooltipObj,
    activeSubTypes,
    defaultSubTypes,
    search: sessionSearch,
    searchFields,
    listingPageProps: {
      ...listPageContextCommonValues.listingPageProps,
      ...listingPageProps,
      filterInfoHoverText: {
        ...listingPageProps.filterInfoHoverText,
        ...{ [FilterTypes.RECENT]: `ListPage.${listPage}.Table.FilterInfoHover.Recent` },
      },
      sidebarProps: {
        ...listPageContextCommonValues.listingPageProps.sidebarProps,
        ...listingPageProps.sidebarProps,
        customFilterSelected: sessionCustomFilterSelected,
      },
    },
    activeFolderId: sessionFolderId,
    activeTagId: sessionTagId,
    searchAll: sessionShowAll,
    canDeleteItems,
    canEdit,
    canCreate,
    canShareToChildAccounts: canShareToChildAccounts && featureFlagCanPublish,
    canShareToCatalog: canShareToCatalog && featureFlagCanShareAsset,
    hideDeleteItems,
    hideMoveItems,

    sortBy: sortBy ? sortBy : [{ id: '', desc: true }],
    itemType,
    dataTest,
  }
}

export const getDefaultEmptyListingProps = (values: ListPageCommonState, setFilter: SetFilter, t: TFunction): EmptyListingProps | undefined => {
  const {
    activeTagId,
    activeFolderId,
    search,
    searchItemsResults,
    activeSubTypes,
    defaultSubTypes,
    readOnlyFolders,
    listingPageProps: {
      sidebarProps: { allItemFilter },
      tableProps: { listPage },
    },
  } = values

  if (activeSubTypes.filter((subType) => !defaultSubTypes.includes(subType)).length > 0) {
    return {
      imgSrc: StaticImageNames.emptyFilter,
      headline: `ListPage.Common.Table.EmptyListing.SubTypes.Headline`,
      text: `ListPage.${listPage}.Table.EmptyListing.SubTypes.Text`,
      buttonText: `ListPage.${listPage}.Table.EmptyListing.SubTypes.ButtonText`,
      buttonOnClick: () => setFilter(allItemFilter, false, true),
    }
  } else if (search && searchItemsResults.length === 0) {
    return {
      imgSrc: StaticImageNames.emptySearch,
      headline: t(`No results found for your search term “{{search}}”`, { search: ellip(search ?? '', 25) }),
      text: t('There were no results matching your search'),
      buttonOnClick: () => setFilter(allItemFilter, false),
      withoutBorder: true,
    }
  } else if (activeTagId) {
    return {
      imgSrc: StaticImageNames.emptyTag,
      headline: `ListPage.${listPage}.Table.Tag.EmptyListing.Headline`,
      text: `ListPage.${listPage}.Table.Tag.EmptyListing.Text`,
      buttonText: `ListPage.${listPage}.Table.Tag.EmptyListing.ButtonText`,
      buttonOnClick: () => setFilter(allItemFilter, false),
    }
  } else if (activeFolderId) {
    const onClickProps = readOnlyFolders ? {} : { text: `ListPage.${listPage}.Table.Folder.EmptyListing.Text` }
    return {
      ...onClickProps,
      imgSrc: StaticImageNames.emptyFolder,
      headline: 'This folder is empty!',
      buttonText: `ListPage.${listPage}.Table.Folder.EmptyListing.ButtonText`,
      buttonOnClick: () => setFilter(allItemFilter, false),
    }
  }
}

export const getPreviewIdFromUrl = (history: History) => {
  const searchParams = new URLSearchParams(history.location.search)
  return searchParams.get('preview') ?? ''
}

export const removeParamFromUrl = (history: History, params: Array<string>) => {
  const currentPath = history.location.pathname
  const searchParams = new URLSearchParams(history.location.search)

  params.forEach((param) => {
    searchParams.delete(param)
  })

  history.replace({
    pathname: currentPath,
    search: searchParams.toString(),
  })
}
