import React, { FC } from 'react'

import classNames from 'classnames'

import { ListingPageSession } from '@complex/ListingPage/Utils/ListingPage.utils'
import Button, { ButtonType } from '@components/Button'
import Svg, { SvgNames } from '@components/Svg'
import Typography, { TextType } from '@components/Typography/Typography'
import { setItem } from '@utils/sessionStorage'

import './FolderSearchFilterButtons.css'

export interface FolderSearchOption {
  name: string
  icon: SvgNames
  color?: string
  active: boolean
  searchAll?: boolean
}

interface Props {
  className?: string
  itemType?: string
  dataTest?: string
  onChange: (allFolders: boolean, name?: string) => void
  options: FolderSearchOption[]
}

const rootClass = 'folder-search-filter-buttons'

const FolderSearchFilterButtons: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '', onChange, options, itemType } = props

  const renderButton = ({ name, color, searchAll, icon, active }: FolderSearchOption, index: number) => (
    <Button
      key={name}
      dataTest={`${dataTest}__button-${index}`}
      className={classNames(`${rootClass}__button`, { [`${rootClass}__button-inactive`]: !active })}
      buttonType={active ? ButtonType.ACTIVE_FILTER : ButtonType.WHITE}
      onClick={() => {
        setItem(`${itemType}:${ListingPageSession.SEARCH_SHOW_ALL}`, JSON.stringify(searchAll ? 'true' : 'false'))
        !active && onChange(!!searchAll, name)
      }}
    >
      <Svg style={color ? { fill: color } : {}} name={icon} />
      <Typography text={name} type={active ? TextType.BODY_TEXT : TextType.BODY_TEXT_LIGHT} />
      {active && <Svg className={`${rootClass}__check`} name={SvgNames.check} />}
    </Button>
  )

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {options.map(renderButton)}
    </div>
  )
}

export default FolderSearchFilterButtons
