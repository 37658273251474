// List of countries that are part of High charts
// https://github.com/highcharts/map-collection-dist/tree/master/countries
export const countriesNotInHighCharts = [
  { name: 'Aland Islands', code: 'AX' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bonaire, Sint Eustatius and Saba', code: 'BQ' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Congo', code: 'CG' },
  { name: 'Cook Islands', code: 'CK' },
  { name: "Cote D'Ivoire (Ivory Coast)", code: 'CI' },
  { name: 'Curaçao', code: 'CW' },
  { name: 'Falkland Islands', code: 'FK' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guernsey and Alderney', code: 'GG' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Heard Island and McDonald Islands', code: 'HM' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'ISLE OF MAN', code: 'IM' },
  { name: 'South Georgia', code: 'GS' },
  { name: 'Palestine', code: 'PS' },
  { name: 'Macao', code: 'MO' },
  { name: 'Saint Martin', code: 'MF' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'North Macedonia', code: 'MK' },
  { name: 'Réunion', code: 'RE' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'British Virgin Islands', code: 'VG' },
  { name: 'Wallis and Futana', code: 'WF' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Saint Barthélemy', code: 'BL' },
  { name: 'Netherlands Antilles', code: 'AN' },
]
