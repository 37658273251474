import { NavigationState, getNavigation } from '@utils/navigation/navigation.utils'

export interface PageContainerStateProps {
  navigation: NavigationState
}

function mapStateToProps(state: any): PageContainerStateProps {
  return {
    navigation: getNavigation(state),
  }
}

export default mapStateToProps
