import { Dispatch, SetStateAction } from 'react'
import { Row } from 'react-table'

import {
  doSearchUCLListsByFilterUtils,
  doSearchUCLListsByTagUtils,
  doSearchUCLListsUtils,
  PAGE_SIZE,
  searchUCLListsUtils,
} from '@complex/ListPickerModalV2/utils/clients/ListPickerModalCategorizationClientUtils'
import { ClientUtilsBaseParams, searchLegacyListsUtils, searchListsUtils } from '@complex/ListPickerModalV2/utils/clients/ListPickerModalClientUtils'
import {
  SearchSegmentsByFilterRequestType,
  SearchSegmentsByTagsRequestType,
  SearchSegmentsRequestType,
} from '@complex/ListPickerModalV2/utils/graphql/ListPickerModalCategorizationRequests.graphQL'
import { SearchListsRequestType } from '@complex/ListPickerModalV2/utils/graphql/ListPickerModalClassicRequests.graphQL'
import {
  GetCRMSegmentsInfoRequestType,
  SyncedSegmentDetailsRequestType,
} from '@complex/ListPickerModalV2/utils/graphql/ListPickerModalCRMRequests.graphQL'
import {
  DisableRowByCriteria,
  ListPickerModalState,
  PreProcessedList,
  SEARCH_RESULT_LISTS,
  SearchResultsListCategory,
  SelectedListsType,
} from '@complex/ListPickerModalV2/utils/interfaces/ListPickerModalInterfaces'
import { buildToRemoveFromHasToExpandRowsArray, getHasToExpandListsUpdated } from '@complex/ListPickerModalV2/utils/ListPickerModal.utils.expand'
import {
  DIRECT_SELECT_SEGMENTS_TYPE,
  ListPickerEmptyListingOptions,
  MIRRORED_LISTS_ID,
  selectedListsInitialState,
  UNIFIED_CONTACTS_ID,
  unifiedListTypes,
} from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import { getEmptyListingFiltersOption, UCLEmptyListingFiltersOptions } from '@complex/ListPickerModalV2/utils/ListPickerModalFiltersUtils'
import { getCRMListsInfoUtils, getMainFilter, setOngoingSyncStatusUtils } from '@complex/ListPickerModalV2/utils/ListPickerModalMenuUtils'
import { Status } from '@components/StatusToast/StatusToast'
import { rootContext } from '@const/globals'
import { FolderDto, ItemDto, LabelDto, SearchSegmentsQuery, SubTypeDto } from '@graphql/types/microservice/categorization-types'
import {
  AccountSettings,
  FavoritesListsQuery,
  FolderListInfo,
  FoldersWithCountQuery,
  ListInfo,
  SearchListsInfo,
  SearchListsQuery,
} from '@graphql/types/query-types'
import { Folder } from '@interface/Folder'
import { Folder as FormerFolderType } from '@interface/foldersLists/Folder'
import {
  BouncesSegments,
  getSegmentName,
  isBounceSegment,
  ITEM_SUB_TYPE_FROM_DTO,
  ItemSubTypeDtoName,
  ItemType,
  smsBouncesVerbs,
} from '@utils/categorization'
import { formatDate } from '@utils/date'
import { FilterDefinition, FilterTypes } from '@utils/filter'
import { LIST_CATEGORY_FILTERS, ListCategory } from '@utils/lists'
import { escapeRegExp } from '@utils/strings'

const DEFAULT_FOLDER = 'Default Folder'
const FOLDER_EXCEPTIONS = ['Favorites', 'Attention Needed']

export type BaseHierarchyType<T> = { id: string; name: string; parent?: string; subRows?: T[]; children?: T[]; description?: string }

export const findHierarchy = <T extends BaseHierarchyType<T>>(list: T, hierarchy: T[] = []): T[] => {
  if (!list?.parent) {
    return []
  }

  const parent = hierarchy.find(({ id }) => list.parent === id) as T
  return findHierarchy(parent, hierarchy).concat(parent)
}

export const buildListsHierarchy = <T extends BaseHierarchyType<T>>(lists: T[]): T[] => {
  const toAnalyzeLists = lists.filter(({ parent }) => !parent)
  const toAnalyzeListsChildren = lists.filter(({ parent }) => parent)
  let orphanSegments: T[] = []
  toAnalyzeListsChildren.forEach((child: T) => {
    const foundParentIndex = toAnalyzeLists.findIndex(({ id }) => id === child.parent)
    if (foundParentIndex >= 0) {
      toAnalyzeLists[foundParentIndex].subRows = [...(toAnalyzeLists[foundParentIndex].subRows ?? []), child]
    } else {
      const foundParentIndex = toAnalyzeListsChildren.findIndex(({ id }) => id === child.parent)
      if (foundParentIndex >= 0) {
        toAnalyzeListsChildren[foundParentIndex].subRows = [...(toAnalyzeListsChildren[foundParentIndex].subRows ?? []), child]
      } else {
        orphanSegments = [...orphanSegments, child]
      }
    }
  })
  return [...toAnalyzeLists, ...orphanSegments]
}

const getLegacyHierarchyValues = <T extends BaseHierarchyType<T>>(
  legacyLists: Partial<SelectedListsType>,
  isListsOnly: boolean,
  searchQuery: string
) => {
  const filteredLists = isListsOnly ? removeSegments(legacyLists) : legacyLists

  const hierarchyLists = Object.values(filteredLists).flatMap((values) => values) as T[]
  const arrayWithSubRows = getArrayWithSubRows(hierarchyLists)
  const legacyDoesNotIncludesSearch = arrayWithSubRows.filter((value) => !matchSearchTerm(value.name, searchQuery)) ?? []
  const legacyCount = (arrayWithSubRows.filter((value) => matchSearchTerm(value.name, searchQuery)) ?? []).length
  const updatedLegacyResults = Object.entries(filteredLists).reduce(
    (lists, list) => ({ ...lists, [list[0]]: removeParents(list[1] as T[], searchQuery) }),
    {}
  )
  const listsWithoutParents = removeParents(hierarchyLists, searchQuery)

  const legacyParentsHierarchy = listsWithoutParents.reduce((hierarchies, list: T) => {
    const hierarchy = findHierarchy(list, legacyDoesNotIncludesSearch)
    if (hierarchy.length) {
      return { ...hierarchies, [list.id]: hierarchy }
    } else {
      return hierarchies
    }
  }, {})

  return { legacyCount, legacyParentsHierarchy, updatedLegacyResults }
}

const getUCLHierarchyValues = (
  UCLLists: PreProcessedList[],
  searchQuery: string,
  disabledLists: string[],
  disableLists: boolean,
  disableRowByCriteria: DisableRowByCriteria | undefined
) => {
  const UCLListsWithoutParents = removeParents(buildListsHierarchy(UCLLists), searchQuery)
  const UCLDoesNotIncludesSearch = UCLLists.filter(({ name }) => !matchSearchTerm(name, searchQuery)) ?? []
  const UCLCount = (UCLLists.filter(({ name }) => matchSearchTerm(name, searchQuery)) ?? []).length
  const UCLParentsHierarchy = UCLListsWithoutParents.reduce((hierarchies, list) => {
    const hierarchy = findHierarchy(list, UCLDoesNotIncludesSearch)
    if (hierarchy.length) {
      return { ...hierarchies, [list.id]: hierarchy }
    } else {
      return hierarchies
    }
  }, {})
  const updatedUCLResults = disableListUtils(UCLListsWithoutParents, disabledLists, disableLists, disableRowByCriteria)
  return { UCLCount, UCLParentsHierarchy, updatedUCLResults }
}
export const hasLegacyListsSelectedUtils = (selectedLists: SelectedListsType) =>
  !!Object.entries(selectedLists).reduce((curr, list) => curr + (unifiedListTypes.includes(list[0] as ListCategory) ? 0 : list[1].length), 0)

export const areUnifiedListsDisabled = (
  listType: ListCategory,
  disableUnifiedLists: boolean,
  restrictMixingLegacyListsAndUCL: boolean,
  hasLegacyListsSelected: boolean
) => unifiedListTypes.includes(listType) && (disableUnifiedLists || (restrictMixingLegacyListsAndUCL && hasLegacyListsSelected))

export const areLegacyListsDisabled = (
  listType: ListCategory,
  disableLegacyLists: boolean,
  restrictMixingLegacyListsAndUCL: boolean,
  selectedLists: SelectedListsType
) =>
  !unifiedListTypes.includes(listType) &&
  (disableLegacyLists ||
    (restrictMixingLegacyListsAndUCL &&
      (!!selectedLists[ListCategory.UNIFIED_LIST].length || !!selectedLists[ListCategory.FORM_SUBMISSIONS_UCL].length)))

export const disableListUtils = (
  lists: PreProcessedList[],
  disabledLists: string[],
  disableLists: boolean,
  disableRowByCriteria: DisableRowByCriteria | undefined
): PreProcessedList[] =>
  lists.map((list) => ({
    ...list,
    disabled: disableLists || disabledLists.includes(list.id) || !!disableRowByCriteria?.(list),
    subRows: list.subRows?.length ? disableListUtils(list.subRows, disabledLists, disableLists, disableRowByCriteria) : [],
  }))

export const listChangedUtils = (setState: Function, listType: string, clearSearch?: VoidFunction) => {
  clearSearch && clearSearch()
  setState((state: ListPickerModalState) => ({
    ...state,
    listType,
    foldersState: { folders: [], foldersLoading: false },
  }))
}

export const loadFoldersSuccess = (
  setState: Function,
  { foldersWithCount }: FoldersWithCountQuery,
  disabledLists: string[],
  disableLists: boolean,
  isListsOnly: boolean,
  disableRowByCriteria: DisableRowByCriteria | undefined
) =>
  setState((state: ListPickerModalState) => {
    const { listType, listsState } = state
    const isAccountList = listType === ListCategory.ACCOUNTS
    return {
      ...state,
      foldersState: {
        folders: !isAccountList
          ? mapFoldersToFolderData(
              foldersWithCount
                ?.filter(({ name, isContacts, isSpecial }) => !(isSpecial && FOLDER_EXCEPTIONS.includes(name)) && !isContacts)
                .map((folder) => (folder.isDefault ? { ...folder, name: DEFAULT_FOLDER } : folder))
            )
          : [],
        foldersLoading: false,
      },
      listsState: isAccountList
        ? {
            lists: foldersWithCount
              .flatMap(({ entries }) => entries)
              .map((list) =>
                buildLegacyLists((isListsOnly ? { ...list, children: [] } : list) as ListInfo, disabledLists, disableLists, disableRowByCriteria)
              ),
            loadingLists: false,
          }
        : listsState,
    }
  })

export const buildLegacyLists = (
  list: ListInfo,
  disabledLists: string[],
  disableLists: boolean,
  disableRowByCriteria?: DisableRowByCriteria
): any => ({
  ...list,
  disabled: disableLists || disabledLists.includes(list?.id) || disableRowByCriteria?.(list),
  type: list?.description,
  subRows: list?.children?.map((child) => ({
    ...buildLegacyLists(child as ListInfo, disabledLists, disableLists, disableRowByCriteria),
    parent: list?.id,
  })),
})

const buildFavoriteLegacyListChildren = (
  children: ListInfo[],
  disabledLists: string[],
  disableLists: boolean,
  disableRowByCriteria?: DisableRowByCriteria
) =>
  children.reduce((curr: ListInfo[], list: ListInfo) => {
    const { children: listChildren } = list
    const childChildren = (
      listChildren?.length > 0
        ? buildFavoriteLegacyListChildren(
            listChildren.map((child) => ({ ...child, parent: list.id })) as unknown as ListInfo[],
            disabledLists,
            disableLists,
            disableRowByCriteria
          )
        : []
    ) as ListInfo[]
    return list.isFavorite
      ? [
          ...curr,
          {
            ...list,
            type: list.description,
            subRows: childChildren,
            disabled: disableLists || disabledLists.includes(list.id) || disableRowByCriteria?.(list),
          },
        ]
      : [...curr, ...childChildren]
  }, [])

const buildFavoriteLegacyLists = (
  lists: ListInfo[],
  disabledLists: string[],
  disableLists: boolean,
  disableRowByCriteria?: DisableRowByCriteria
): any =>
  lists.flatMap((list) =>
    buildFavoriteLegacyListChildren(list.isFavorite ? [list] : list.children, disabledLists, disableLists, disableRowByCriteria)
  )

export const loadFavoritesSuccess = (
  setState: Dispatch<SetStateAction<ListPickerModalState>>,
  { getFavoritesLists }: FavoritesListsQuery,
  disabledLists: string[],
  disableLists: boolean,
  isListsOnly: boolean,
  disabledRowByCriteria?: DisableRowByCriteria
) => {
  let favorites = getFavoritesLists.flatMap(({ entries }) => entries) as ListInfo[]
  favorites = isListsOnly ? favorites.map((list) => ({ ...list, children: [] })) : favorites
  setState((state: ListPickerModalState) => ({
    ...state,
    listsState: {
      lists: buildFavoriteLegacyLists(favorites, disabledLists, disableLists, disabledRowByCriteria),
      listsLoading: false,
    },
  }))
}

export const loadListsSuccess = (
  setState: Dispatch<SetStateAction<ListPickerModalState>>,
  lists: any[],
  pageNumber: number,
  disabledLists: string[],
  disableLists: boolean,
  isListsOnly: boolean,
  disableRowByCriteria: DisableRowByCriteria | undefined
) =>
  setState((state: ListPickerModalState) => {
    const builtLists = lists.map((list) =>
      buildLegacyLists((isListsOnly ? { ...list, children: [] } : list) as ListInfo, disabledLists, disableLists, disableRowByCriteria)
    )
    return {
      ...state,
      allLoaded: lists.length < PAGE_SIZE,
      currentPage: pageNumber,
      lazyLoading: false,
      listsState: {
        lists: pageNumber === 0 ? builtLists : [...(state.listsState.lists as unknown as ListInfo[]), ...builtLists],
        loadingLists: false,
      },
    }
  })

const getEntries = (list: FolderListInfo[], searchAll: boolean, currentFolder?: FolderDto) =>
  (currentFolder && !searchAll ? list.filter(({ id }) => id === currentFolder.id) : list).flatMap((list) => list.entries)

export const matchSearchTerm = (name: string, searchQuery: string) => {
  const matcher = new RegExp(escapeRegExp(searchQuery), 'i')
  return name.match(matcher)
}

const buildSearchListSubRows = <T extends BaseHierarchyType<T>>(
  children: T[],
  searchQuery: string,
  disabledLists: string[],
  disableLists: boolean,
  disableRowByCriteria?: DisableRowByCriteria,
  parentId?: string
): T[] =>
  children.reduce((curr: T[], child: T) => {
    const children: T[] =
      child.children?.length ?? 0 > 0
        ? buildSearchListSubRows(child.children ?? [], searchQuery, disabledLists, disableLists, disableRowByCriteria, child.id)
        : []
    const parent = {
      ...child,
      children,
      type: child.description ?? '',
      disabled: disableLists || disabledLists.includes(child.id) || disableRowByCriteria?.(child),
      subRows: children,
      parent: parentId ?? '',
    }

    return [...curr, parent]
  }, [])

const buildSearchLists = (
  lists: ListInfo[],
  searchQuery: string,
  selectedLists: SelectedListsType,
  listType: ListCategory,
  disabledLists: string[],
  restrictMixingLegacyListsAndUCL: boolean,
  disableRowByCriteria?: DisableRowByCriteria
): ListInfo[] => {
  const hasLegacyListsSelected = !!Object.entries(selectedLists).reduce(
    (curr, list) => curr + (unifiedListTypes.includes(list[0] as ListCategory) ? 0 : list[1].length),
    0
  )
  const isUnifiedListTypeSelected = unifiedListTypes.includes(listType)
  const disableUnifiedLists = restrictMixingLegacyListsAndUCL && hasLegacyListsSelected && isUnifiedListTypeSelected
  const hasSelectedUnifiedLists = !!selectedLists[ListCategory.UNIFIED_LIST].length || !!selectedLists[ListCategory.FORM_SUBMISSIONS_UCL].length
  const disableLegacyLists = restrictMixingLegacyListsAndUCL && hasSelectedUnifiedLists && !isUnifiedListTypeSelected
  const disableLists = isUnifiedListTypeSelected ? disableUnifiedLists : disableLegacyLists

  return buildListsHierarchy(buildSearchListSubRows(lists, searchQuery, disabledLists, disableLists, disableRowByCriteria))
}

const removeSegments = <T extends BaseHierarchyType<T>>(list: Partial<SelectedListsType>) => {
  const listEntries = Object.entries(list)
  return listEntries.reduce((curr, list) => ({ ...curr, [list[0]]: list[1].filter(({ id }: T) => id.startsWith('l-')) }), {})
}

const buildSearchResultsLists = (
  searchLists: SearchListsInfo,
  searchQuery: string,
  searchAll: boolean,
  selectedLists: SelectedListsType,
  disabledLists: string[],
  restrictMixingLegacyListsAndUCL: boolean,
  disableRowByCriteria?: DisableRowByCriteria,
  currentFolder?: FolderDto
) =>
  Object.entries(searchLists)
    .filter((list) => Object.values(SEARCH_RESULT_LISTS).includes(list[0] as SEARCH_RESULT_LISTS) && list[1])
    .reduce(
      (curr, list) => ({
        ...curr,
        [SearchResultsListCategory[list[0] as SEARCH_RESULT_LISTS]]:
          SearchResultsListCategory[list[0] as SEARCH_RESULT_LISTS] === ListCategory.ACCOUNTS
            ? buildSearchLists(
                list[1] as ListInfo[],
                searchQuery,
                selectedLists,
                SearchResultsListCategory[list[0] as SEARCH_RESULT_LISTS],
                disabledLists,
                restrictMixingLegacyListsAndUCL,
                disableRowByCriteria
              )
            : buildSearchLists(
                getEntries(list[1] as FolderListInfo[], searchAll, currentFolder),
                searchQuery,
                selectedLists,
                SearchResultsListCategory[list[0] as SEARCH_RESULT_LISTS],
                disabledLists,
                restrictMixingLegacyListsAndUCL,
                disableRowByCriteria
              ),
      }),
      { ...selectedListsInitialState }
    ) as { [key in ListCategory]: ListInfo[] }

export const loadSearchSuccess = (
  setState: Dispatch<SetStateAction<ListPickerModalState>>,
  { currentFilter, currentFolder, listType, search, selectedLists }: ListPickerModalState,
  { searchLists }: SearchListsQuery,
  searchAll: boolean,
  disabledLists: string[],
  isListsOnly: boolean,
  restrictMixingLegacyListsAndUCL: boolean,
  disableRowByCriteria: DisableRowByCriteria | undefined
) => {
  const classicLists = buildSearchResultsLists(
    searchLists as SearchListsInfo,
    search?.query as string,
    searchAll,
    selectedLists,
    disabledLists,
    restrictMixingLegacyListsAndUCL,
    disableRowByCriteria,
    currentFolder
  )
  const filteredList =
    currentFilter?.name === FilterTypes.FAVORITES && !searchAll
      ? classicLists[listType].filter(({ isFavorite }) => isFavorite)
      : classicLists[listType]
  loadSearchLists(setState, { [listType]: filteredList }, isListsOnly)
}

export const getLegacyListsOnSearch = (
  { search, selectedLists }: ListPickerModalState,
  { searchLists }: SearchListsQuery,
  disabledLists: string[],
  restrictMixingLegacyListsAndUCL: boolean,
  disableRowByCriteria: DisableRowByCriteria | undefined
) =>
  buildSearchResultsLists(
    searchLists as SearchListsInfo,
    search?.query as string,
    true,
    selectedLists,
    disabledLists,
    restrictMixingLegacyListsAndUCL,
    disableRowByCriteria
  )

const loadSearchLists = <T extends BaseHierarchyType<T>>(
  setState: Dispatch<SetStateAction<ListPickerModalState>>,
  lists: Partial<SelectedListsType>,
  isListsOnly: boolean
) => {
  setState((state: ListPickerModalState) => {
    const { legacyCount, legacyParentsHierarchy, updatedLegacyResults } = getLegacyHierarchyValues(lists, isListsOnly, state.search?.query ?? '')

    return {
      ...state,
      search: {
        ...state.search,
        count: legacyCount,
        loading: false,
        filterLoading: false,
        lists: { ...updatedLegacyResults },
        parentsHierarchy: legacyParentsHierarchy,
      },
    }
  })
}

export const mapFoldersToFolderData = (folders: FormerFolderType[]): Folder[] =>
  folders?.map(({ count, id, name }) => {
    return {
      id,
      itemCount: count,
      name,
      position: 0,
    }
  }) as Folder[]

const parseItem = (dataItem: any) => {
  const { item } = dataItem || {}
  return JSON.parse(item ?? '')
}

const getBaseId = (listType: ListCategory) => {
  if (listType === ListCategory.UNIFIED_LIST) {
    return UNIFIED_CONTACTS_ID
  } else if (listType === ListCategory.FORM_SUBMISSIONS_UCL) {
    return MIRRORED_LISTS_ID
  }
  return ''
}
export const buildLists = (dataList: ItemDto[], listType: ListCategory): PreProcessedList[] =>
  dataList?.map((dataItem: any): PreProcessedList => {
    const { isEditable, name, parent, recordsCount, type, id: classicExternalId } = parseItem(dataItem)
    const { id: categorizationId, labels, position, updatedTime, externalId } = dataItem
    let id = externalId === 'st' ? smsBouncesVerbs[BouncesSegments.SMS_OPT_OUT] : externalId
    const isBounce = isBounceSegment(id)
    id = classicExternalId
    const list = {
      id,
      baseId: getBaseId(listType),
      categorizationId,
      crmStatus: dataItem.crmStatus,
      disabled: false,
      name: getSegmentName(id, name),
      parent,
      position,
      size: parseInt(recordsCount),
      tags: labels,
      type,
      updatedTime: formatDate(`${updatedTime.toString().length === 10 ? updatedTime * 1000 : updatedTime}`),
    }
    const subTypes = dataItem.subTypeDTO?.map(({ name }: SubTypeDto) => ITEM_SUB_TYPE_FROM_DTO[name as ItemSubTypeDtoName])
    return { ...list, itemType: isBounce ? ItemType.BOUNCE : ItemType.SEGMENT, isEditable: isEditable && !isBounce, subTypes }
  }) || []

export const buildRows = (lists: ListInfo[], disableRowByCriteria: DisableRowByCriteria | undefined, disabledLists: string[] = []) =>
  lists.map((list) => ({ original: buildLegacyLists(list, disabledLists, false, disableRowByCriteria) } as Row))

export const buildListCategoriesRows = (
  { unifiedLists, marketing, contacts, formSubmissions, webinar, extension }: SearchListsInfo,
  listIds: string[],
  disableRowByCriteria: DisableRowByCriteria | undefined,
  isRestrictedToLegacyLists = false
) => {
  const formSubmissionsEntries = getDefaultSelectedListsEntries(formSubmissions as FolderListInfo[])
  const unifiedFormSubmissionsEntries = formSubmissionsEntries.filter(({ id }) => id.startsWith(MIRRORED_LISTS_ID))
  const legacyFormSubmissionsEntries = formSubmissionsEntries.filter(({ id }) => !id.startsWith(MIRRORED_LISTS_ID))
  const webinarRegistrationsEntries = getDefaultSelectedListsEntries(webinar as FolderListInfo[])
  const unifiedWebinarRegistrationsEntries = webinarRegistrationsEntries.filter(({ id }) => id.startsWith(MIRRORED_LISTS_ID))
  const legacyWebinarRegistrationsEntries = webinarRegistrationsEntries.filter(({ id }) => !id.startsWith(MIRRORED_LISTS_ID))

  return {
    [ListCategory.UNIFIED_LIST]: isRestrictedToLegacyLists
      ? []
      : buildRows(getDefaultSelectedLists(getDefaultSelectedListsEntries(unifiedLists as FolderListInfo[]), listIds), disableRowByCriteria),
    [ListCategory.MARKETING]: buildRows(
      getDefaultSelectedLists(getDefaultSelectedListsEntries(marketing as FolderListInfo[]), listIds),
      disableRowByCriteria
    ),
    [ListCategory.EXTENSION]: buildRows(
      getDefaultSelectedLists(getDefaultSelectedListsEntries(extension as FolderListInfo[]), listIds),
      disableRowByCriteria
    ),
    [ListCategory.ACCOUNTS]: buildRows(getDefaultSelectedLists(contacts || [], listIds), disableRowByCriteria),
    [ListCategory.FORM_SUBMISSIONS]: buildRows(getDefaultSelectedLists(legacyFormSubmissionsEntries, listIds), disableRowByCriteria),
    [ListCategory.WEBINAR]: buildRows(getDefaultSelectedLists(legacyWebinarRegistrationsEntries, listIds), disableRowByCriteria),
    [ListCategory.WEBINAR_UCL]: buildRows(getDefaultSelectedLists(unifiedWebinarRegistrationsEntries, listIds), disableRowByCriteria),
    [ListCategory.FORM_SUBMISSIONS_UCL]: buildRows(getDefaultSelectedLists(unifiedFormSubmissionsEntries, listIds), disableRowByCriteria),
  }
}

export const getSearchListsBasedOnListCategory = (
  { unifiedLists, marketing, contacts, formSubmissions, webinar, extension }: SearchListsInfo,
  disableRowByCriteria: DisableRowByCriteria | undefined,
  isRestrictedToLegacyLists = false
) => {
  const formSubmissionsEntries = getDefaultSelectedListsEntries(formSubmissions as FolderListInfo[])
  const unifiedFormSubmissionsEntries = formSubmissionsEntries.filter(({ id }) => id.startsWith(MIRRORED_LISTS_ID))
  const legacyFormSubmissionsEntries = formSubmissionsEntries.filter(({ id }) => !id.startsWith(MIRRORED_LISTS_ID))
  const webinarRegistrationsEntries = getDefaultSelectedListsEntries(webinar as FolderListInfo[])
  const unifiedWebinarRegistrationsEntries = webinarRegistrationsEntries.filter(({ id }) => id.startsWith(MIRRORED_LISTS_ID))
  const legacyWebinarRegistrationsEntries = webinarRegistrationsEntries.filter(({ id }) => !id.startsWith(MIRRORED_LISTS_ID))

  return {
    [ListCategory.UNIFIED_LIST]: isRestrictedToLegacyLists
      ? []
      : getDefaultSelectedListsEntries(unifiedLists as FolderListInfo[]).map((list) =>
          buildLegacyLists(list as ListInfo, [], false, disableRowByCriteria)
        ),
    [ListCategory.MARKETING]: getDefaultSelectedListsEntries(marketing as FolderListInfo[]).map((list) =>
      buildLegacyLists(list as ListInfo, [], false, disableRowByCriteria)
    ),
    [ListCategory.EXTENSION]: getDefaultSelectedListsEntries(extension as FolderListInfo[]).map((list) =>
      buildLegacyLists(list as ListInfo, [], false, disableRowByCriteria)
    ),
    [ListCategory.ACCOUNTS]: contacts?.map((list) => buildLegacyLists(list as ListInfo, [], false, disableRowByCriteria)),
    [ListCategory.FORM_SUBMISSIONS]: legacyFormSubmissionsEntries.map((list) => buildLegacyLists(list as ListInfo, [], false, disableRowByCriteria)),
    [ListCategory.WEBINAR]: legacyWebinarRegistrationsEntries.map((list) => buildLegacyLists(list as ListInfo, [], false, disableRowByCriteria)),
    [ListCategory.WEBINAR_UCL]: unifiedWebinarRegistrationsEntries.map((list) => buildLegacyLists(list as ListInfo, [], false, disableRowByCriteria)),
    [ListCategory.FORM_SUBMISSIONS_UCL]: unifiedFormSubmissionsEntries.map((list) =>
      buildLegacyLists(list as ListInfo, [], false, disableRowByCriteria)
    ),
  }
}

export const getDefaultSelectedListsEntries = (legacyList: FolderListInfo[]) => legacyList?.flatMap(({ entries }) => entries) || []

export const getDefaultSelectedLists = (legacyList: ListInfo[], defaultSelectedLists: string[]) =>
  legacyList.reduce((curr: ListInfo[], list) => {
    const children = (list.children.length > 0 ? getDefaultSelectedLists(list.children, defaultSelectedLists) : []) as ListInfo[]
    return defaultSelectedLists.includes(list.id) ? [...curr, ...children, list] : [...curr, ...children]
  }, [])

export const getArrayWithSubRows = <T extends BaseHierarchyType<T>>(lists: T[]) => {
  const getSubRows = (list: T): T[] => [list].concat(...(list?.subRows?.map(getSubRows) || []))

  return lists.map(getSubRows).flat()
}

const setCRMStatus = async (
  newLists: any[] = [],
  syncedSegmentDetailsRequest: SyncedSegmentDetailsRequestType,
  getCRMSegmentsInfoRequest: GetCRMSegmentsInfoRequestType
) => {
  const updatedLists = await setOngoingSyncStatusUtils(newLists, syncedSegmentDetailsRequest)
  return getCRMListsInfoUtils(updatedLists, getCRMSegmentsInfoRequest)
}

const modifyListHierarchy = (existingLists: PreProcessedList[], modifiedLists: PreProcessedList[]): PreProcessedList[] => {
  return existingLists.map((existingList) => {
    const foundList = modifiedLists.find((list) => list.id === existingList.id) ?? existingList
    return existingList.subRows ? { ...foundList, subRows: modifyListHierarchy(existingList.subRows, modifiedLists) } : foundList
  })
}

const setUpdatedLists = (
  setState: Function,
  newLists: PreProcessedList[],
  pageNumber: number,
  disabledLists: string[],
  disableLists: boolean,
  disableRowByCriteria: DisableRowByCriteria | undefined,
  modifyExistingLists: boolean
) =>
  setState((state: ListPickerModalState) => {
    const existingLists = state.listsState.lists ?? []
    const listsToAdd = !modifyExistingLists ? (pageNumber === 0 ? newLists : [...existingLists, ...newLists]) : []
    const listsToModify = modifyExistingLists ? modifyListHierarchy(existingLists, newLists) : []
    const lists = modifyExistingLists ? listsToModify : buildListsHierarchy(listsToAdd)

    return {
      ...state,
      allLoaded: newLists.length < PAGE_SIZE,
      currentPage: modifyExistingLists ? state.currentPage : pageNumber,
      lazyLoading: false,
      listsState: {
        lists: disableListUtils(lists, disabledLists, disableLists, disableRowByCriteria),
        listsLoading: false,
      },
    }
  })

export const setLists = async (
  setState: Function,
  syncedSegmentDetailsRequest: SyncedSegmentDetailsRequestType,
  getCRMSegmentsInfoRequest: GetCRMSegmentsInfoRequestType,
  hasCRMConnected: boolean,
  listType: ListCategory,
  newLists: ItemDto[],
  pageNumber: number,
  disabledLists: string[],
  disableLists: boolean,
  disableRowByCriteria: DisableRowByCriteria | undefined
) => {
  const lists = buildLists(newLists, listType)
  const setNewLists = (lists: PreProcessedList[], modify = false) =>
    setUpdatedLists(setState, lists, pageNumber, disabledLists, disableLists, disableRowByCriteria, modify)

  if (hasCRMConnected) {
    const FETCH_TIMEOUT = 3000
    const timeout = new Promise<string>((resolve) => setTimeout(() => resolve('timeout'), FETCH_TIMEOUT))
    const startTime = new Date().valueOf()

    const fetchMetaData = setCRMStatus(lists, syncedSegmentDetailsRequest, getCRMSegmentsInfoRequest).then(async (updatedLists) => {
      const timedOut = new Date().valueOf() - startTime > FETCH_TIMEOUT
      setNewLists(updatedLists, timedOut)
    })

    const result = await Promise.race([timeout, fetchMetaData])
    if (result === 'timeout') {
      // We can display the lists now and the crm data will update later
      setNewLists(lists)
    }
  } else {
    setNewLists(lists)
  }
}

export const setFilter = (setState: Function, filter: FilterDefinition) =>
  setState((state: ListPickerModalState) => ({
    ...state,
    currentFilter: filter,
    currentFolder: undefined,
    currentTag: undefined,
    listsState: { ...state.listsState, loadingLists: true },
    loading: false,
    emptyListingOption:
      state.listType === ListCategory.UNIFIED_LIST ? UCLEmptyListingFiltersOptions[filter.name] : getEmptyListingFiltersOption(filter.name),
  }))

export const setFolder = (setState: Function, folder: Folder) =>
  setState((state: ListPickerModalState) => ({
    ...state,
    currentFilter: undefined,
    currentFolder: folder,
    currentTag: undefined,
    loading: false,
    emptyListingOption: ListPickerEmptyListingOptions.EMPTY_FOLDER,
  }))

const removeParents = <T extends BaseHierarchyType<T>>(lists: T[], search: string): T[] =>
  lists.flatMap((list) => (matchSearchTerm(list.name, search) ? list : !!list.subRows?.length ? removeParents(list.subRows, search) : []))

export const setSearchResults = (
  setState: Dispatch<SetStateAction<ListPickerModalState>>,
  searchItems: PreProcessedList[],
  type: ItemType,
  disableRowByCriteria: DisableRowByCriteria | undefined
) =>
  setState((state: ListPickerModalState) => {
    const { UCLCount, UCLParentsHierarchy, updatedUCLResults } = getUCLHierarchyValues(
      searchItems,
      state.search?.query ?? '',
      state.disabledLists,
      state.disableUnifiedLists || state.disableLegacyLists,
      disableRowByCriteria
    )
    return {
      ...state,
      search: {
        ...state.search,
        count: UCLCount,
        loading: false,
        filterLoading: false,
        parentsHierarchy: UCLParentsHierarchy,
        lists: {
          ...state.search?.lists,
          [type === ItemType.FORM_SUBMISSION
            ? ListCategory.FORM_SUBMISSIONS_UCL
            : type === ItemType.WEBINAR_SUBMISSION
            ? ListCategory.WEBINAR_UCL
            : ListCategory.UNIFIED_LIST]: updatedUCLResults,
        },
      },
    }
  })

export const setSearchResultsInAllLists = <T extends BaseHierarchyType<T>>(
  setState: Dispatch<SetStateAction<ListPickerModalState>>,
  UCLLists: PreProcessedList[],
  unifiedFormSubmissions: PreProcessedList[] | undefined,
  legacyLists: Partial<SelectedListsType>,
  isListsOnly: boolean,
  disableRowByCriteria: DisableRowByCriteria | undefined
) =>
  setState((state: ListPickerModalState) => {
    if (unifiedFormSubmissions !== undefined) {
      delete legacyLists[ListCategory.FORM_SUBMISSIONS]
    }

    const { legacyCount, legacyParentsHierarchy, updatedLegacyResults } = getLegacyHierarchyValues(
      legacyLists,
      isListsOnly,
      state.search?.query ?? ''
    )

    const { UCLCount, UCLParentsHierarchy, updatedUCLResults } = getUCLHierarchyValues(
      UCLLists,
      state.search?.query ?? '',
      state.disabledLists,
      state.disableUnifiedLists || state.disableLegacyLists,
      disableRowByCriteria
    )

    let unifiedFormSubmissionsCount = 0
    let unifiedFormSubmissionsParentsHierarchy: { [p: string]: PreProcessedList[] } = {}
    let unifiedFormSubmissionsResults: PreProcessedList[] = []
    if (unifiedFormSubmissions !== undefined) {
      const values = getUCLHierarchyValues(
        unifiedFormSubmissions ?? [],
        state.search?.query ?? '',
        state.disabledLists,
        state.disableUnifiedLists || state.disableLegacyLists,
        disableRowByCriteria
      )
      unifiedFormSubmissionsCount = values.UCLCount
      unifiedFormSubmissionsParentsHierarchy = values.UCLParentsHierarchy
      unifiedFormSubmissionsResults = values.updatedUCLResults
    }

    return {
      ...state,
      search: {
        ...state.search,
        count: UCLCount + legacyCount + unifiedFormSubmissionsCount,
        loading: false,
        filterLoading: false,
        parentsHierarchy: {
          ...legacyParentsHierarchy,
          ...UCLParentsHierarchy,
          ...unifiedFormSubmissionsParentsHierarchy,
        },
        lists: {
          ...updatedLegacyResults,
          [ListCategory.UNIFIED_LIST]: updatedUCLResults,
          [ListCategory.FORM_SUBMISSIONS_UCL]: unifiedFormSubmissionsResults,
        },
      },
    }
  })

export const setTag = (setState: Function, tag: LabelDto) =>
  setState((state: ListPickerModalState) => ({
    ...state,
    currentFilter: undefined,
    currentFolder: undefined,
    currentTag: tag,
    emptyListingOption: ListPickerEmptyListingOptions.EMPTY_TAG,
  }))

export const showFailStatusToast = (setState: Function, message: string) =>
  setState((state: ListPickerModalState) => ({ ...state, statusToast: { status: Status.FAIL, message, showToast: true } }))

export const closeStatusToast = (setState: Function) =>
  setState((state: ListPickerModalState) => ({ ...state, statusToast: { ...state.statusToast, message: '', showToast: false } }))

export const getOnClickTagInRowFunction =
  (setState: Function, { currentTag, listType }: ListPickerModalState, tags: LabelDto[]) =>
  (name: string) => {
    if (name === currentTag?.name) {
      setFilter(setState, getMainFilter(listType))
    } else {
      const tagFound = tags.find(({ name: tagName }) => name === tagName) as LabelDto
      setTag(setState, tagFound)
    }
  }

const getFlatSubRows = (lists: PreProcessedList[]): PreProcessedList[] =>
  lists.filter(({ subRows }) => subRows?.length).flatMap(({ subRows }) => subRows) as PreProcessedList[]

const checkSubRowsDisabled = (subRows: PreProcessedList[]): boolean => {
  const allChildrenDisabled = subRows.every(({ disabled }) => disabled)
  if (allChildrenDisabled) {
    const subSubRows = getFlatSubRows(subRows)
    return !!subSubRows.length ? checkSubRowsDisabled(subSubRows) : subRows?.every((list) => list.disabled)
  }
  return false
}

export const checkAllListsDisabledUtils = (lists: PreProcessedList[]) =>
  lists.every(({ disabled }) => disabled) && checkSubRowsDisabled(getFlatSubRows(lists))

export const removeDuplicates = ({ original }: any, index: number, arr: any[]) =>
  arr.findIndex(({ original: originalDuplicated }) => originalDuplicated.id === original.id) === index

const selectSubRows = (rows: Row<any>[]): Row<any>[] =>
  rows
    .reduce((curr: Row<any>[], row) => (row.subRows.length ? [...curr, row, ...selectSubRows(row.subRows)] : [...curr, row]), [])
    .filter(({ original }) => !original.disabled)

export const getOnRowSelectionChangedFunction =
  (setState: Function, state: ListPickerModalState, multiSelect: boolean) => (selectedRows: Row[], rows?: Row[], selectedRowIds?: any) => {
    const { hasToExpandRows, listType, segmentTreePage, selectedLists } = state
    const { listType: segmentTreeListType } = segmentTreePage || {}
    const rowIds = (rows && rows.map(({ original }) => (original as any).id)) || []
    const allSelectedWithSubRowsTriggered = selectedRows?.length !== Object.keys(selectedRowIds)?.length
    let rowsToSelect = selectedRows
    if (allSelectedWithSubRowsTriggered) {
      rowsToSelect = selectSubRows(rows as Row[])
    }
    const selectedRowsWithoutSubRows = rowsToSelect
      .map((row) => ({
        ...row,
        original: { ...row.original, subRows: [] },
      }))
      .filter(({ id }) => Object.keys(selectedRowIds).includes(id))
    if (
      !multiSelect &&
      selectedLists[segmentTreeListType ?? listType].length &&
      selectedRowsWithoutSubRows.length &&
      selectedRowsWithoutSubRows[0].id === selectedLists[listType][0].id
    ) {
      return
    }
    const selectedRowOriginalIds = selectedRowsWithoutSubRows.map(({ original }) => (original as any).id)
    const oldSelected = Object.values(selectedLists[segmentTreeListType ?? listType]).filter(({ original }) =>
      rowIds.includes(original.id) ? selectedRowOriginalIds.includes(original.id) : true
    )

    const updatedLists = multiSelect
      ? [...selectedRowsWithoutSubRows, ...oldSelected].filter(removeDuplicates)
      : [...selectedRowsWithoutSubRows, ...(rowIds.includes(selectedRowOriginalIds[0]) ? [] : oldSelected)]
    const updatedListsIds = updatedLists.map(({ original }) => (original as any).id)

    const hasToExpand = getHasToExpandListsUpdated(updatedLists, rows as Row[])
    const toRemoveFromHasToExpandRows = state.selectedLists[segmentTreeListType ?? listType]
      .filter(({ original }) => rowIds.includes((original as any).id) && !updatedListsIds.includes((original as any).id))
      .map(({ original }) => original.parent)
      .filter((parent) => parent)
    const toRemoveFromHasToExpandRowsIds = buildToRemoveFromHasToExpandRowsArray(toRemoveFromHasToExpandRows, rows as Row[]).map(
      ({ original }) => (original as any).id
    )

    const updatedHasToExpandRows = [
      ...(hasToExpandRows[segmentTreeListType ?? listType]
        ? hasToExpandRows[segmentTreeListType ?? listType]?.filter(({ original }) => !toRemoveFromHasToExpandRowsIds.includes((original as any).id))
        : []),
      ...hasToExpand,
    ]
    const updatedHasToExpandRowsWithoutDuplicates = updatedHasToExpandRows.filter(removeDuplicates)

    const currentLists = selectedLists[segmentTreeListType ?? listType]?.length
    ;(currentLists || (!currentLists && selectedRowsWithoutSubRows.length)) &&
      setState((state: ListPickerModalState) => ({
        ...state,
        hasToExpandRows: {
          ...(multiSelect ? hasToExpandRows : selectedListsInitialState),
          [segmentTreeListType ?? listType]: updatedHasToExpandRowsWithoutDuplicates,
        },
        selectedLists: { ...(multiSelect ? selectedLists : selectedListsInitialState), [segmentTreeListType ?? listType]: updatedLists },
      }))
  }

export const goEditSegment = (segment: PreProcessedList, currentUrl: string) =>
  segment.isEditable && window.open(`${rootContext}/classic/segment/${segment.id}/${segment.id}?back=${currentUrl}`, '_self')

export const doSearchUtils = (state: ListPickerModalState, setState: Function, query?: string) => {
  if (!query || query.trim().length === 0) {
    return
  }
  const lowercaseQuery = query.toLocaleLowerCase()
  setState({
    ...state,
    search: {
      query: lowercaseQuery,
      loading: true,
      value: query,
    },
  })
}

export const onRowSelectionChangeInAllListsUtils = (
  state: ListPickerModalState,
  setState: Dispatch<SetStateAction<ListPickerModalState>>,
  selectedRows: Row[],
  rows: Row[],
  type: ListCategory,
  multiSelect: boolean
) => {
  const { selectedLists } = state
  const selectedRowIds = selectedRows.map(({ original }) => (original as any).id)
  const selectedListsIds = selectedLists[type].map(({ original }) => (original as any).id)
  const sameLists = selectedRowIds.every((id) => selectedListsIds.includes(id)) && selectedListsIds.every((id) => selectedRowIds.includes(id))
  const rowsIds = rows.map(({ original }) => (original as any).id)
  const updatedRows = selectedLists[type].filter((row) => !selectedRowIds.includes(row.original.id) && !rowsIds.includes(row.original.id))
  const updatedSelectedLists = [...updatedRows, ...selectedRows]
  !sameLists &&
    setState((state) => ({
      ...state,
      selectedLists: {
        ...(multiSelect ? selectedLists : selectedListsInitialState),
        [type]: !multiSelect && updatedSelectedLists.length > 1 ? selectedRows : updatedSelectedLists,
      },
    }))
}

export const onRowSelectionChangeInSelectViewUtils = (
  state: ListPickerModalState,
  setState: Dispatch<SetStateAction<ListPickerModalState>>,
  selectedRows: Row[],
  type: ListCategory,
  multiSelect: boolean
) => {
  const { selectedLists } = state
  const selectedRowIds = selectedRows.map(({ original }) => (original as any).id)
  const selectedListsIds = selectedLists[type].map(({ original }) => (original as any).id)
  const sameLists = selectedRowIds.every((id) => selectedListsIds.includes(id)) && selectedListsIds.every((id) => selectedRowIds.includes(id))
  !sameLists &&
    setState({
      ...state,
      selectedLists: { ...(multiSelect ? selectedLists : selectedListsInitialState), [type]: selectedRows },
    })
}

export const searchUCLUtils = (
  state: ListPickerModalState,
  setState: Dispatch<SetStateAction<ListPickerModalState>>,
  searchSegmentsRequest: SearchSegmentsRequestType,
  searchSegmentsByFilterRequest: SearchSegmentsByFilterRequestType,
  searchSegmentsByTagsRequest: SearchSegmentsByTagsRequestType,
  search: string,
  type: ItemType,
  disableRowByCriteria: DisableRowByCriteria | undefined,
  accountSettings: AccountSettings,
  searchAll?: boolean
) => {
  const { currentFilter, currentFolder, currentTag, listType } = state
  if (currentFilter || (!currentFilter && searchAll)) {
    currentFilter?.name === LIST_CATEGORY_FILTERS[ListCategory.UNIFIED_LIST] ||
    currentFilter?.name === LIST_CATEGORY_FILTERS[ListCategory.FORM_SUBMISSIONS_UCL] ||
    currentFilter?.name === LIST_CATEGORY_FILTERS[ListCategory.WEBINAR_UCL] ||
    searchAll
      ? doSearchUCLListsUtils({ setState, searchSegmentsRequest, search, type, listType, disableRowByCriteria })
      : doSearchUCLListsByFilterUtils(
          { setState, searchSegmentsByFilterRequest, search, type, listType, disableRowByCriteria },
          currentFilter?.name as FilterTypes,
          accountSettings
        )
  }
  if (!searchAll) {
    currentFolder && doSearchUCLListsUtils({ setState, searchSegmentsRequest, search, type, listType, disableRowByCriteria }, currentFolder)
    currentTag && doSearchUCLListsByTagUtils({ setState, searchSegmentsByTagsRequest, search, type, listType, disableRowByCriteria }, currentTag)
  }
}

export const searchInAllListsUtils = async (
  baseParams: ClientUtilsBaseParams,
  state: ListPickerModalState,
  search: string,
  hideUCL: boolean,
  hideAllUCLListTypes: boolean,
  hideLegacyLists: boolean,
  restrictMixingLegacyListsAndUCL: boolean,
  hasUnifiedFormSubmissions: boolean,
  hasUnifiedWebinars: boolean,
  type: ItemType,
  searchSegmentsRequest: SearchSegmentsRequestType,
  searchListsRequest: SearchListsRequestType,
  disableRowByCriteria: DisableRowByCriteria | undefined
) => {
  const { isListsOnly, setState } = baseParams
  const searchUCL = (!hideUCL && !hideAllUCLListTypes && !isListsOnly) || type === ItemType.FORM_SUBMISSION
  if (!hideLegacyLists) {
    if (searchUCL) {
      const legacyLists =
        (await searchLegacyListsUtils(baseParams, state, restrictMixingLegacyListsAndUCL, searchListsRequest, disableRowByCriteria)) ?? {}
      const searchSegmentsQuery = (await searchUCLListsUtils({
        ...baseParams,
        searchSegmentsRequest,
        search,
        disableRowByCriteria,
      })) as SearchSegmentsQuery
      let searchFormSubmissionsQuery
      if (hasUnifiedFormSubmissions || hasUnifiedWebinars) {
        searchFormSubmissionsQuery = (await searchUCLListsUtils({
          ...baseParams,
          searchSegmentsRequest,
          search,
          type: hasUnifiedFormSubmissions ? ItemType.FORM_SUBMISSION : ItemType.WEBINAR_SUBMISSION,
          disableRowByCriteria,
        })) as SearchSegmentsQuery
      }
      setSearchResultsInAllLists(
        setState,
        buildLists(searchSegmentsQuery?.search?.items as ItemDto[], ListCategory.UNIFIED_LIST),
        searchFormSubmissionsQuery
          ? buildLists(searchFormSubmissionsQuery?.search?.items as ItemDto[], ListCategory.FORM_SUBMISSIONS_UCL)
          : undefined,
        legacyLists,
        baseParams.isListsOnly,
        disableRowByCriteria
      )
    } else {
      searchListsUtils(baseParams, state, false, restrictMixingLegacyListsAndUCL, searchListsRequest, disableRowByCriteria)
    }
  } else if (searchUCL) {
    doSearchUCLListsUtils({ ...baseParams, searchSegmentsRequest, search, type, disableRowByCriteria })
  }
}

export const getDirectSelectSegments = (lists: PreProcessedList[]): PreProcessedList[] =>
  lists.reduce(
    (total: PreProcessedList[], current) => [
      ...total,
      ...(!!current.subRows?.length ? getDirectSelectSegments(current.subRows) : []),
      ...(current.type === DIRECT_SELECT_SEGMENTS_TYPE ? [{ ...current, subRows: [] }] : []),
    ],
    []
  )
