import React, { useContext } from 'react'

import classNames from 'classnames'

import { ListingPageCommonContext } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { renderRelativeDate } from '@components/Table/components/tableColumns'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import Tooltip from '@components/Tooltip/Tooltip'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { Program } from '@graphql/types/query-types'
import AutomatedProgramsInfoHover from '@src/pages/listingPages/AutomatedPrograms/components/AutomatedProgramsInfoHover/AutomatedProgramsInfoHover'
import { ProgramScheduleType } from '@src/pages/programs/dashboard/components/ProgramSettings/ProgramSettings'
import { ItemType } from '@utils/categorization'
import { getStandardFormattedDate, relativeDate } from '@utils/date'
import { getFormattedNumber } from '@utils/numbers'
import { getCurrentProgramStatus } from '@utils/program/program'
import { ProgramState } from '@utils/program/program.constants'
import { renderPathToFolder } from '@utils/searchUtils'
import { titleCase } from '@utils/strings'

import './AutomatedProgramsListingPageContainer.css'

const rootClass = 'automated-programs-listing-page-container'

const renderProgramState = (programState: ProgramState) => {
  const trigger =
    programState === ProgramState.ERROR ? (
      <Svg name={SvgNames.error} type={SvgType.SMALLER_ICON} className={classNames(`${rootClass}__program-state-${programState}`)} />
    ) : (
      <div className={classNames(`${rootClass}__program-state`, `${rootClass}__program-state-${programState}`)}>&nbsp;</div>
    )
  return <Tooltip trigger={trigger}>{titleCase(programState === ProgramState.PAUSED ? 'paused' : programState)}</Tooltip>
}

const useRenderItemNameVal = (original: ItemDto, search: string, rootClass: string) => {
  const {
    update,
    values,
    values: {
      listingPageProps: {
        tableProps: { onCustomTableAction, clickableColumnOptions },
      },
    },
  } = useContext(ListingPageCommonContext)

  if (!original) {
    return ''
  }

  const { action } = clickableColumnOptions || {}

  const asset = original as Program & {
    externalId: string
  }

  const programState = getCurrentProgramStatus(asset)

  const handleClick = () => {
    if (action?.customTableAction && onCustomTableAction) {
      onCustomTableAction(action.customTableAction, update, values, original)
    } else {
      update({ selectedRows: [original as ItemDto], ...action })
    }
  }

  return (
    <div
      role={'button'}
      tabIndex={0}
      onKeyDown={(keyDownEvent) => (keyDownEvent.key === 'Enter' ? handleClick() : undefined)}
      className={`${rootClass}__program-name`}
      onClick={handleClick}
    >
      {renderProgramState(programState)}
      <Tooltip
        ellipOnTrigger
        trigger={
          <div className={`${rootClass}__program-name-typography`}>
            <div className={'ellip'}>{search ? renderBoldTextOnMatch(asset.name, search) : asset.name}</div>
          </div>
        }
      >
        {asset.name}
      </Tooltip>

      <AutomatedProgramsInfoHover
        name={asset.name}
        lastUpdated={asset.lastUpdated}
        updatedBy={asset.lastEditedBy ?? ''}
        description={asset.description}
        scheduleType={(asset.schedule?.type as ProgramScheduleType) ?? ProgramScheduleType.NEVER}
        scheduleInterval={asset.schedule?.interval ?? 0}
        scheduleWeekdaysOnly={asset.schedule?.weekdaysOnly ?? false}
        nextScheduledAddDate={asset.schedule?.nextTime ?? ''}
      />
    </div>
  )
}

const renderContactCount = (original: ItemDto & { visits?: string }) => {
  return getFormattedNumber(parseInt(original.visits ?? '0'))
}

export const renderSearchColumnsUtilV2 = (
  searchInAllItems: boolean | undefined,
  currentFolder: FolderData,
  search: string,
  folders: FolderData[],
  t: Function
): ColumnDefWithAdditionalProps<ItemDto & Pick<Program, 'lastUpdated'>>[] => {
  return [
    {
      header: 'Program Name',
      accessorKey: 'name',
      textAlign: 'left',
      hideTooltip: true,
      disableSorting: true,
      cell: ({ row: { original } }) => (() => useRenderItemNameVal(original, search, rootClass))(),
    },
    {
      header: 'Tags',
      accessorKey: 'tags',
      textAlign: 'left',
      maxSize: 180,
      disableSorting: true,
      enableCustomCellValue: true,
    },
    {
      header: 'Location',
      accessorKey: 'folderId',
      textAlign: 'left',
      disableSorting: true,
      maxSize: 120,
      cell: (row: any) => renderPathToFolder(ItemType.PROGRAM, row.cell.value, folders, searchInAllItems, rootClass, t, currentFolder),
    },
    {
      header: 'Contacts',
      accessorKey: 'visits',
      textAlign: 'right',
      disableSorting: true,
      maxSize: 100,
      className: 'automated-programs-listing-page-container__contacts',
      cell: ({ row: { original } }) => renderContactCount(original),
    },
    {
      header: 'Last Updated',
      accessorKey: 'updatedTime',
      textAlign: 'right',
      disableSorting: true,
      maxSize: 120,
      cell: ({
        cell: {
          row: {
            original: { lastUpdated },
          },
        },
      }) => (lastUpdated ? renderRelativeDate(relativeDate(lastUpdated, true, false), getStandardFormattedDate(lastUpdated)) : ''),
    },
  ]
}

export const automatedProgramsListingPageTableV2Columns: ColumnDefWithAdditionalProps<ItemDto & Pick<Program, 'lastUpdated'>>[] = [
  {
    header: 'Program Name',
    accessorKey: 'name',
    textAlign: 'left',
    enableClickableCell: true,
    hideTooltip: true,
    cell: ({ row: { original } }) => (() => useRenderItemNameVal(original, '', rootClass))(),
  },
  {
    header: 'Tags',
    accessorKey: 'tags',
    textAlign: 'left',
    maxSize: 180,
    disableSorting: true,
    enableCustomCellValue: true,
  },
  {
    header: 'Contacts',
    accessorKey: 'visits',
    textAlign: 'right',
    maxSize: 100,
    disableSorting: true,
    cell: ({ row: { original } }) => renderContactCount(original),
  },
  {
    header: 'Last Updated',
    accessorKey: 'lastUpdated',
    textAlign: 'left',
    maxSize: 150,
    sortingFn: 'statusDateTime',
    cell: ({
      cell: {
        row: {
          original: { lastUpdated },
        },
      },
    }) => (lastUpdated ? renderRelativeDate(relativeDate(lastUpdated, true, false), getStandardFormattedDate(lastUpdated)) : ''),
  },
]
