import { gql } from '@apollo/client'

export default gql`
  query getPollResponsePage($accountId: String!, $messageId: String!, $blockName: String!, $recId: String, $srcId: String) {
    getPollResponsePage(accountId: $accountId, messageId: $messageId, blockName: $blockName, recId: $recId, srcId: $srcId) {
      choicesResponses {
        name
        total
      }
      yourChoices {
        indexOfChoices
        nameOfChoices
        comment
        time
        contactName
      }
      otherChoices {
        indexOfChoices
        nameOfChoices
        comment
        time
        contactName
      }
      status
      message
      image
      showImage
      prompt
      allowComment
      allowOnlyOne
      commentPrompt
      allowRecipientResponseVisibility
    }
  }
`
