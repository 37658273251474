import React, { FC, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import Caution from '@components/Caution/Caution'
import { LinkTextButton } from '@components/LinkTextButton/LinkTextButton'
import { Status } from '@components/StatusToast/StatusToast'
import Typography from '@components/Typography/Typography'
import { detectEmailType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { ComposerHistoryState, EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { SAVED_AS_COPY_ID } from '@utils/composer/EmailModal.constants'

import EmailComposerSettingsSender from './components/EmailComposerSettingsSender/EmailComposerSettingsSender'
import EmailComposerSettingsSidebar from './components/EmailComposerSettingsSidebar/EmailComposerSettingsSidebar'
import EmailComposerSettingsSubject from './components/EmailComposerSettingsSubject/EmailComposerSettingsSubject'
import EmailComposerSettingsTitle from './components/EmailComposerSettingsTitle/EmailComposerSettingsTitle'
import { PLAIN_TEXT_URL, SettingsFormChangeHandler } from './EmailComposerSettings.utils'

import './EmailComposerSettings.css'

export interface EmailComposerSettingsProps {
  onFieldChange: SettingsFormChangeHandler
  onConvertToTemplate: (discardDraft: boolean) => void
  onSendAPIDetails: (email: string, details: string, note: string) => Promise<void>
}

const rootClass = 'email-composer-settings'

const EmailComposerSettings: FC<EmailComposerSettingsProps> = (props) => {
  const { onFieldChange, onConvertToTemplate, onSendAPIDetails } = props

  const { t } = useTranslation()
  const history = useHistory<ComposerHistoryState>()

  const {
    values: {
      loading,
      messageConfiguration: {
        messageType,
        settings: { hideMessageTitle, hideSender, senderReadOnly },
      },
      detectedURLChanges: { plainTextMode },
    },
    api: { updateModal },
  } = useContext(EmailComposerContext)
  const { isEmailBlankMessage } = detectEmailType(messageType)
  const showSettingsTitle = isEmailBlankMessage ? true : !hideMessageTitle

  useEffect(() => {
    if (history.location.state) {
      const savedAsCopyId = history.location.state[SAVED_AS_COPY_ID]

      if (savedAsCopyId && !loading) {
        updateModal('statusToast', { message: t('Saved as copy'), status: Status.SUCCESS })
        const currentState = { ...history.location.state }

        delete currentState[SAVED_AS_COPY_ID]

        history.replace({
          ...history.location,
          state: currentState,
        })
      }
    }
  }, [history, loading, t, updateModal])

  return (
    <div className={rootClass}>
      <div className={`${rootClass}__left`}>
        {plainTextMode && (
          <Caution
            message={
              <Typography
                text={t('EmailComposer.Settings.PlainText.Caution')}
                tagComponents={{ LinkTextButton: <LinkTextButton link={PLAIN_TEXT_URL} hideIconLeft /> }}
              />
            }
            className={`${rootClass}__plain-text-caution`}
            dataTest={`${rootClass}-caution`}
          />
        )}
        {showSettingsTitle && (
          <div className={`${rootClass}__left__section`}>
            <EmailComposerSettingsTitle className={rootClass} />
          </div>
        )}
        <div className={`${rootClass}__left__section`}>
          <EmailComposerSettingsSubject className={rootClass} onFieldChange={onFieldChange} />
        </div>
        {!hideSender && (
          <div className={`${rootClass}__left__section`}>
            <EmailComposerSettingsSender senderReadOnly={senderReadOnly} className={rootClass} onFieldChange={onFieldChange} />
          </div>
        )}
      </div>
      <div className={`${rootClass}__right`}>
        <div className={`${rootClass}__right__section`}>
          <EmailComposerSettingsSidebar
            className={rootClass}
            onFieldChange={onFieldChange}
            onConvertToTemplate={onConvertToTemplate}
            onSendAPIDetails={onSendAPIDetails}
          />
        </div>
      </div>
    </div>
  )
}

export default EmailComposerSettings
