import React, { FC } from 'react'

import classNames from 'classnames'

import Label from '@components/Label/Label'

import { CheckboxProps } from '../Checkbox/Checkbox'

import './checkboxgroup.css'

const rootClass = 'checkboxgroup'

interface CheckboxGroupProps {
  children: React.ReactElement<CheckboxProps>[]
  className?: string
  verticalLayout?: boolean
  label?: string
}

export const CheckboxGroup: FC<CheckboxGroupProps> = (props: CheckboxGroupProps) => {
  const { label, children, verticalLayout, className } = props

  return (
    <div className={classNames(rootClass, className)} data-test={'checkbox-group'}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <div className={classNames(`${rootClass}__container`, { [`${rootClass}__container--vertical`]: verticalLayout })}>{children}</div>
    </div>
  )
}

export default CheckboxGroup
