import { useApolloClient } from '@apollo/client'
import isSourceIdTriggerable from '@graphql/queries/isSourceIdTriggerable'
import { IsSourceIdTriggerableQuery, IsSourceIdTriggerableQueryVariables } from '@graphql/types/query-types'

interface ProgramGeneralSettingsRequests {
  isSourceTriggerableRequest: (sourceId: string) => Promise<boolean>
}

export const useProgramGeneralSettingsRequests = (): ProgramGeneralSettingsRequests => {
  const client = useApolloClient()

  const isSourceTriggerableRequest = async (sourceId: string) => {
    return client
      .query<IsSourceIdTriggerableQuery, IsSourceIdTriggerableQueryVariables>({
        query: isSourceIdTriggerable,
        errorPolicy: 'all',
        variables: {
          sourceId,
        },
      })
      .then(({ data }) => {
        return data.isSourceIdTriggerable
      })
  }

  return {
    isSourceTriggerableRequest,
  }
}
