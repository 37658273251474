import React, { FC } from 'react'

import Button, { ButtonType } from '@components/Button'
import Svg, { SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import OverviewVM from '@src/pages/Profile/components/Overview/models/overview.view-model'

import { getOverviewManageProfileUIValues, ManageProfileUIItem, ManageProfileUIValues } from '../../utils/overview.utils'

import './ManageProfile.css'

interface ManageProfileProps {
  dataTest?: string
  profileData: OverviewVM
  onAction: () => void
}

const rootClass = 'manage-profile'

const ManageProfile: FC<ManageProfileProps> = (props: ManageProfileProps) => {
  const { dataTest = rootClass, onAction, profileData } = props

  const { t } = useTranslation()

  return (
    <>
      <Typography
        text={profileData.getTitle()}
        className={`${rootClass}__main-header`}
        type={TextType.PAGE_HEADER_SEMI_BOLD}
        lineHeight={LineHeight.LARGE}
        weight={TextWeight.BOLD}
      />
      <Typography text={profileData.getUserInfo()} type={TextType.SECTION_HEADER} />
      <Button
        className={`${rootClass}__button`}
        dataTest={`${dataTest}__button`}
        buttonType={ButtonType.PRIMARY}
        fullWidth
        onClick={() => {
          onAction()
        }}
      >
        {t('Manage profile')}
      </Button>
      <div className="divider"></div>
      {getOverviewManageProfileUIValues(profileData).map((section: ManageProfileUIValues, mainIndex) => (
        <div key={mainIndex}>
          <Typography text={t(section.headerTitle)} className={`${rootClass}__header`} type={TextType.SECTION_HEADER} />
          {section.items.map((item: ManageProfileUIItem, index: number) => {
            if (item.value) {
              return (
                <div key={`${mainIndex}-${index}`} className={`${rootClass}__item`}>
                  <Tooltip
                    trigger={<Svg name={item.icon} className={`${rootClass}__item-icon`} type={SvgType.LARGE_ICON} />}
                    position="top"
                    align="start"
                    alignOffset={5}
                    sideOffset={2}
                  >
                    {t(item.tooltip)}
                  </Tooltip>
                  <Typography text={item.value} className={`${rootClass}__item-value`} />
                </div>
              )
            }
          })}
          {section.divider && <div className="divider"></div>}
        </div>
      ))}
    </>
  )
}

export default ManageProfile
