import { useApolloClient } from '@apollo/client'
import accountData from '@graphql/microservices/account/accountData'
import getEmailComposerRowsCount from '@graphql/microservices/email-management/getEmailComposerRowsCount'
import sharedSavedRows from '@graphql/mutations/sharedSavedRows'
import { AccountDataQuery, AccountDataQueryVariables } from '@graphql/types/microservice/account-types'
import { GetEmailComposerRowsCountQuery } from '@graphql/types/microservice/email-management-types'
import { SharedSavedRowsMutation } from '@graphql/types/mutation-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export interface SavedRowsCopyRequests {
  getAccountDataRequest: () => FetchPromise<AccountDataQuery>
  getSavedRowsCountRequest: () => FetchPromise<GetEmailComposerRowsCountQuery>
  pushToChildAccountRequest: () => FetchPromise<SharedSavedRowsMutation>
}

export const useSavedRowsCopyRequests = (userId: string, parentAccountId: string): SavedRowsCopyRequests => {
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.ACCOUNT })
  const { client: emailManagementClient } = useMicroserviceClient({ serviceName: MicroserviceClients.EMAIL_MANAGEMENT_PROXY })
  const actonClient = useApolloClient()

  const getAccountDataRequest = () => {
    return client.query<AccountDataQuery, AccountDataQueryVariables>({
      query: accountData,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        parentAccountId,
        userId,
      },
    })
  }

  const getSavedRowsCountRequest = () => {
    return emailManagementClient.query<GetEmailComposerRowsCountQuery>({
      query: getEmailComposerRowsCount,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {},
    })
  }

  const pushToChildAccountRequest = () => {
    return actonClient.mutate<SharedSavedRowsMutation>({
      mutation: sharedSavedRows,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {},
    })
  }

  return {
    getAccountDataRequest,
    getSavedRowsCountRequest,
    pushToChildAccountRequest,
  }
}
