import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/Button'
import SectionHeadline from '@components/SectionHeadline'
import { SvgNames } from '@components/Svg'
import Svg from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { useLandingPageContext } from '@src/pages/Content/LandingPages/LandingPagesManager/context/LandingPagesManager.context'
import { formatDateWithAbbreviations } from '@utils/date'

import './LandingPageProperties.css'

interface LandingPagePropertiesBlock {
  label: string
  text: string
  key: string
}

interface LandingPagePropertiesProps {
  className?: string
  dataTest?: string
}

const rootClass = 'landing-page-properties'

const LandingPageProperties: FC<LandingPagePropertiesProps> = (props: LandingPagePropertiesProps) => {
  const { dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const {
    landingPage: { name, canEdit, createdAt, createdBy, lastUpdated, lastUpdatedBy },
    onEdit,
  } = useLandingPageContext()

  const data: LandingPagePropertiesBlock[] = []
  if (name) {
    data.push({ label: 'Title', text: name, key: 'title' })
  }

  if (createdAt) {
    const date = formatDateWithAbbreviations(createdAt)
    data.push({ label: 'Created', text: createdBy ? t('UserTimestamp', { date, user: createdBy }) : date, key: 'created' })
  }

  if (lastUpdated) {
    const date = formatDateWithAbbreviations(lastUpdated)
    data.push({ label: 'Last updated', text: lastUpdatedBy ? t('UserTimestamp', { date, user: lastUpdatedBy }) : date, key: 'updated' })
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <SectionHeadline dataTest={`${dataTest}-headline`} className={`${rootClass}__headline`}>
        {t('Landing Page Properties')}
      </SectionHeadline>
      {data.map(({ label, text, key }) => (
        <div className={`${rootClass}__block`} key={key}>
          <Typography text={t(label)} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.MEDIUM} className={`${rootClass}__block-label`} />
          <Typography text={text} type={TextType.BODY_TEXT_LIGHT} dataTest={`${dataTest}-block-${key}`} />
        </div>
      ))}
      <Tooltip
        hide={canEdit}
        position="left"
        trigger={
          <Button buttonType={ButtonType.INFO} disabled={!canEdit} onClick={onEdit} dataTest={`${dataTest}-button`}>
            <Svg name={SvgNames.pencil} className={`${rootClass}__button-svg`} />
            {t('Edit landing page')}
          </Button>
        }
      >
        {t('Ask your administrator for permission to do this')}
      </Tooltip>
    </div>
  )
}

export default LandingPageProperties
