import React, { FC, useEffect, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { MediaItemResponse } from '@components/AssetPickers/MediaPickerModal/MediaPickerModal.context'
import { useMediaPickerModalRequests } from '@components/AssetPickers/MediaPickerModal/MediaPickerModal.graphQL'
import { getMediaItemsFromResponse } from '@components/AssetPickers/MediaPickerModal/MediaPickerModal.utils'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import addMediaLink from '@graphql/mutations/addMediaLink'
import uploadMediaFile from '@graphql/mutations/uploadMediaFile'
import {
  AddMediaLinkMutation,
  AddMediaLinkMutationVariables,
  UploadMediaFileMutation,
  UploadMediaFileMutationVariables,
} from '@graphql/types/mutation-types'
import MediaFileModal from '@src/pages/EmailComposer/EmailModals/components/MediaFileModal/MediaFileModal'
import { transformFolders } from '@src/pages/EmailComposer/utils/BeeEditor.utils'
import { logNewRelicError } from '@utils/new-relic.utils'

import { MediaLinkInsertArgs } from './MediaLink/MediaLink'

interface MediaFileModalProps {
  isOpen: boolean
  handleClickBack: () => void
  handleCancel: () => void
  handleInsert: (item?: MediaLinkInsertArgs) => void
}

interface MediaFileModalState {
  folders: SelectV2SingleOption[]
  mediaItem: MediaItemResponse
}

const initialState: MediaFileModalState = {
  folders: [],
  mediaItem: {
    id: '',
    title: '',
    previewUrl: '',
    linkUrl: '',
    docType: '',
    modified: 0,
    created: 0,
    isFavorite: false,
    size: '',
  },
}

const rootClass = 'asset-link-modal'

const MediaFileModalContainer: FC<MediaFileModalProps> = (props: MediaFileModalProps) => {
  const { isOpen, handleClickBack, handleCancel, handleInsert } = props

  const [state, setState] = useState(initialState)

  const actonClient = useApolloClient()
  const { retrieveMediaFilesByFolderRequest } = useMediaPickerModalRequests()

  const fetchItems = async () => {
    const { data, errors } = await retrieveMediaFilesByFolderRequest()

    if (data) {
      const { folders } = getMediaItemsFromResponse(data)
      setState({ ...state, folders: transformFolders(folders) })
    } else if (errors) {
      logNewRelicError(errors)
    }
  }

  const uploadMedia = async (variables: UploadMediaFileMutationVariables | undefined) => {
    const { data, errors } = await actonClient.mutate<UploadMediaFileMutation, UploadMediaFileMutationVariables>({
      mutation: uploadMediaFile,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables,
    })
    if (data) {
      setState({ ...state, mediaItem: { ...state.mediaItem, ...data.uploadMediaFile } })
      handleInsert({ url: data.uploadMediaFile.downloadLink || data.uploadMediaFile.previewUrl, title: data.uploadMediaFile.title })
    } else if (errors) {
      logNewRelicError(errors)
      return Promise.reject()
    }
  }

  const addLinkMutation = async (variables: AddMediaLinkMutationVariables) => {
    const { data, errors } = await actonClient.mutate<AddMediaLinkMutation, AddMediaLinkMutationVariables>({
      mutation: addMediaLink,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables,
    })
    if (data) {
      return data // will update in another pr
    } else if (errors) {
      logNewRelicError(errors)
    }
  }

  useEffect(() => {
    fetchItems()
  }, [])

  return (
    <div className={rootClass}>
      <MediaFileModal
        handleAddLink={addLinkMutation}
        uploadMediaFile={uploadMedia}
        handleClickBack={handleClickBack}
        handleCancel={handleCancel}
        handleInsert={handleInsert}
        isOpen={isOpen}
        folders={state.folders}
      />
    </div>
  )
}

export default MediaFileModalContainer
