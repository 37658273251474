import { useCallback, useEffect, useState } from 'react'

import { DocumentNode } from 'graphql'

import { useLazyQuery, OperationVariables, QueryLazyOptions } from '@apollo/client'
import { QueryHookOptions, QueryTuple } from '@apollo/client/react/types/types'
import { TypedDocumentNode } from '@graphql-typed-document-node/core'

// This custom useLazyQueryOnMount hook will prevent execution when options will change.
// It will be triggered only on _queryFunction call

// useLazyQueryOnMount has same type as useLazyQuery
function useLazyQueryOnMount<TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
): QueryTuple<TData, TVariables> {
  const [_options, setOptions] = useState<QueryHookOptions<TData, TVariables> | undefined>(options)
  const [_, setVars] = useState<QueryLazyOptions<TVariables> | undefined>()
  const [queryFunction, mountedQuery] = useLazyQuery<TData, TVariables>(query, {
    ..._options,
  })

  const _queryFunction = useCallback(
    (vars?: QueryLazyOptions<TVariables>) => {
      // Updating options
      setOptions({ ...options })
      setVars(vars)
      return queryFunction(vars) // Return the result of queryFunction
    },
    [options, queryFunction]
  )

  useEffect(() => {
    // calling _queryFunction will change _options.
    // After that we will call queryFunction in this useEffect, to be sure, that
    // queryFunction will be called with last updated options
    setVars((curVars) => {
      curVars && queryFunction(curVars)
      return curVars
    })
  }, [_options, queryFunction])

  return [_queryFunction, mountedQuery]
}

export default useLazyQueryOnMount
