import React, { ComponentProps, FC } from 'react'

import classNames from 'classnames'

import Svg from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import SvgNames from '@components/Svg/SvgNames'

import './caretIcon.css'

export const rootClass = 'caret-icon'

export enum CaretIconDirection {
  DOWN = 'DOWN',
  RIGHT = 'RIGHT',
  UP = 'UP',
}

type SvgProps = Omit<ComponentProps<typeof Svg>, 'name'>

interface CaretIconProps extends SvgProps {
  /** Which direction the caret is pointing */
  direction: CaretIconDirection
  /** Additional classname to be added to root element */
  className?: string
  toggle?: boolean
  isFilled?: boolean
}

export const CaretIcon: FC<CaretIconProps> = (props: CaretIconProps) => {
  const { direction, toggle, type, className, fill, isFilled = true } = props
  const toggled = { [`${rootClass}--toggled`]: toggle }

  if (!direction) {
    return null
  }

  const directions = {
    [CaretIconDirection.DOWN]: { name: `${rootClass}--down`, svg: isFilled ? SvgNames.caretFillDown : SvgNames.caretDown },
    [CaretIconDirection.RIGHT]: { name: `${rootClass}--right`, svg: isFilled ? SvgNames.caretFillRight : SvgNames.caretRight },
    [CaretIconDirection.UP]: { name: `${rootClass}--up`, svg: SvgNames.caretFillUp },
  }
  const { name, svg } = directions[direction]

  return <Svg fill={fill ?? SvgColor.TEXT_GRAY} className={classNames(rootClass, { [name]: !type }, toggled, className)} type={type} name={svg} />
}

export default CaretIcon
