import React from 'react'

import { ApolloClient } from '@apollo/client'
import SegmentInfoHoverCard from '@complex/ContactSegments/SegmentInfoHoverCard/SegmentInfoHoverCard'
import SegmentNameWithCrmSyncAndInfoHover from '@complex/ContactSegments/SegmentNameWithCrmSyncAndInfoHover/SegmentNameWithCrmSyncAndInfoHover'
import SegmentNameWithFtpSyncAndInfoHover from '@complex/ContactSegments/SegmentNameWithFtpSyncAndInfoHover/SegmentNameWithFtpSyncAndInfoHover'
import SegmentNameWithInfoHover from '@complex/ContactSegments/SegmentNameWithInfoHover/SegmentNameWithInfoHover'
import Tooltip from '@components/Tooltip/Tooltip'
import entityType from '@graphql/microservices/crm/entityType'
import getSegmentMetadata from '@graphql/microservices/segment/getSegmentMetadata'
import { EntityTypeQuery, EntityTypeQueryVariables } from '@graphql/types/microservice/crm-types'
import { GetSegmentMetadataQuery, GetSegmentMetadataQueryVariables } from '@graphql/types/microservice/segment-types'
import { ListPageStatusToast } from '@interface/ListPage.context'
import { ItemType } from '@utils/categorization'
import {
  Campaign,
  CrmCampaign,
  CrmEntity,
  CrmEntityField,
  ScoreSheet,
  Segment,
  SubscriptionField,
  SupportedBehavior,
} from '@utils/contactSegments/contactSegments.utils'
import { Update } from '@utils/contactSegments/context/ContactSegmentsContext'
import { CRM_STATUS, CRMSourceOption } from '@utils/crm.utils'

const validateCrmStatus = ({ crmStatus }: Segment) => !!crmStatus && Object.keys(CRM_STATUS).includes(crmStatus)

export const renderFtpSegmentWithInfoHover = (
  segment: Segment,
  update: Update,
  showStatusToast: (statusToast: ListPageStatusToast) => void,
  crmSourceOptions?: CRMSourceOption[]
) => {
  const hasFTPExport = !!segment.ftpData?.export.executionStatus
  return (
    <SegmentNameWithFtpSyncAndInfoHover
      segmentData={segment}
      cardBody={
        <SegmentInfoHoverCard
          segmentData={segment}
          syncsWithFtp
          onFtpViewLogAction={(target) => update && update({ ftpFileImportLogTarget: target })}
          hasExport={hasFTPExport}
          showStatusToast={showStatusToast}
          crmSourceOptions={crmSourceOptions}
        />
      }
    />
  )
}

export const renderSegmentNameWithInfoHover = (
  segment: Segment,
  options?: {
    body?: React.ReactNode
    footer?: React.ReactNode
    className?: string
    showStatusToast?: (statusToast: ListPageStatusToast) => void
    crmSourceOptions?: CRMSourceOption[]
  },
  itemType = ItemType.SEGMENT
) => {
  const { body, footer, className, showStatusToast, crmSourceOptions } = { ...options }
  return validateCrmStatus(segment) ? (
    <SegmentNameWithCrmSyncAndInfoHover
      segmentData={segment}
      cardBody={
        <SegmentInfoHoverCard
          className={`segment-name-with-crm-sync-and-info-hover__card-body`}
          segmentData={segment}
          showStatusToast={showStatusToast}
          crmSourceOptions={crmSourceOptions}
        />
      }
    />
  ) : (
    <>
      <Tooltip triggerClassName={'contact-segments__infohover-segment-name'} position={'top'} trigger={segment.name} ellipOnTrigger>
        {segment.name}
      </Tooltip>
      <SegmentNameWithInfoHover
        segmentData={segment}
        cardBody={body ?? <SegmentInfoHoverCard segmentData={segment} showStatusToast={showStatusToast} crmSourceOptions={crmSourceOptions} />}
        cardFooter={footer}
        className={className}
        itemType={itemType}
      />
    </>
  )
}

export const setSegmentsMetadataUtils = (update: Function, client: ApolloClient<any>, setCampaigns: Function) => {
  update({
    metadataLoading: true,
  })
  client
    .query<GetSegmentMetadataQuery, GetSegmentMetadataQueryVariables>({
      query: getSegmentMetadata,
      fetchPolicy: 'network-only',
      variables: {
        segmentId: 'l-unified-contacts',
      },
    })
    .then(({ data }) => {
      const response = JSON.parse(data.getSegmentMetadata?.body)
      if (response) {
        update({
          crmMapping: response.supportedCrmEntities?.reduce(
            (mappedEntities: Record<string, { displayName: string; entityFields: Record<string, string> }>, element: CrmEntity) => {
              mappedEntities[element.entityType] = {
                displayName: element.displayName,
                entityFields: mapEntityFields(element.entityFields),
              }
              return mappedEntities
            },
            {}
          ),
          subscriptionMapping: response.subscriptionFields?.reduce((mappedFields: Record<string, string>, element: SubscriptionField) => {
            mappedFields[element.fieldName] = element.displayName
            return mappedFields
          }, {}),
          scoreSheets: response.scoreSheets.reduce((scoreSheets: Record<string, string>, element: ScoreSheet) => {
            scoreSheets[element.id] = element.name
            return scoreSheets
          }, {}),
          supportedBehaviors: response.supportedBehaviors.reduce((behaviors: Record<string, string>, element: SupportedBehavior) => {
            behaviors[element.behaviorType] = element.displayName
            return behaviors
          }, {}),
        })
        setCampaigns(
          response.campaigns.reduce((campaigns: Record<string, string>, element: Campaign) => {
            campaigns[element.id] = element.name
            return campaigns
          }, {})
        )
      }
      update({
        metadataLoading: false,
      })
    })
}

const mapEntityFields = (fields: CrmEntityField[]) => {
  return fields.reduce((mappedFields: Record<string, string>, element: CrmEntityField) => {
    mappedFields[element.fieldName] = element.displayName
    return mappedFields
  }, {})
}

export const setCRMCampaignsUtils = (update: Function, client: ApolloClient<any>, setCampaigns: Function) => {
  update({
    metadataLoading: true,
  })
  client
    .query<EntityTypeQuery, EntityTypeQueryVariables>({
      query: entityType,
      fetchPolicy: 'network-only',
      variables: {
        entityType: 'Campaign',
      },
    })
    .then(({ data }) => {
      setCampaigns(
        data.entityType?.entityTypeDataMapping?.reduce((campaigns: Record<string, string>, element: CrmCampaign) => {
          campaigns[element.Id] = element.Name
          return campaigns
        }, {})
      )
    })
  update({
    metadataLoading: false,
  })
}

export enum WebinarType {
  Citrix = 'CITRIX',
  Webex = 'WEBEX',
  Zoom = 'ZOOM',
}
