import React, { ChangeEvent, FC, Key, useContext, useEffect, useMemo } from 'react'

// eslint-disable-next-line no-restricted-imports
import { format } from 'date-fns'

import Breadcrumbs, { BreadcrumbsItemType } from '@components/Breadcrumbs/Breadcrumbs'
import Container from '@components/Container'
import InputV2 from '@components/InputV2/InputV2'
import { LabelType } from '@components/LabelV2/LabelV2'
import RadioCard from '@components/RadioCard/RadioCard'
import RadioCardGroup from '@components/RadioCardGroup/RadioCardGroup'
import { getSegmentInputError, SegmentError } from '@components/SegmentSaveModal/SegmentSaveModal'
import TextArea from '@components/TextArea/TextArea'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { INPUT_DEBOUNCE_TIME, useTranslation } from '@const/globals'
import { SegmentInput, SegmentSyncType } from '@interface/Segment'
import { SegmentComposerContext } from '@src/pages/SegmentComposer/SegmentComposer.context'
import { getSegmentPathBreadcrumb } from '@src/pages/SegmentComposer/SegmentComposer.utils'
import { useAccountSettings } from '@utils/account/account.utils'

import './SegmentComposerSettings.css'

interface SegmentComposerSettingsProps {
  dataTest?: string
}

const rootClass = 'segment-composer-settings'
const DESCRIPTION_MAX_CHARACTERS = 600
const DATE_FORMAT = 'M/dd/yyyy'

export const SegmentComposerSettings: FC<SegmentComposerSettingsProps> = (props: SegmentComposerSettingsProps) => {
  const { dataTest = rootClass } = props

  const { t } = useTranslation()

  const {
    values: { errors, segment, segmentPath },
    update,
  } = useContext(SegmentComposerContext)

  const { userName } = useAccountSettings()

  const { name, description, isDirectSelect, syncType, createdBy, lastModifiedBy, lastModifiedDate, createdDate } = segment

  const inputError = getSegmentInputError(errors)

  const segmentTreeBreadcrumbs = useMemo<BreadcrumbsItemType[]>(() => getSegmentPathBreadcrumb(segmentPath, name), [name, segmentPath])

  const updateSegment = (values: Partial<SegmentInput>) => {
    update({ segment: { ...segment, ...values }, hasUnsavedChanges: true })
  }

  const onSegmentNameBlur = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value.trim()) {
      update({ errors: errors.add(SegmentError.NAME_REQUIRED) })
    }
  }

  const onSegmentNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value.trim()
    updateSegment({ name })
  }

  const onSegmentDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    updateSegment({ description: event.target.value })
  }

  const onSyncTypeChange = (selectedOption: Key) => {
    updateSegment({ syncType: selectedOption as SegmentSyncType })
  }

  useEffect(() => {
    if ((errors.has(SegmentError.NAME_REQUIRED) || errors.has(SegmentError.DUPLICATE_NAME)) && segment.name) {
      const updatedErrors = new Set([...errors].filter((error) => error !== SegmentError.NAME_REQUIRED && error !== SegmentError.DUPLICATE_NAME))
      update({ errors: updatedErrors })
    }
  }, [segment.name])

  return (
    <div className={rootClass} data-test={dataTest}>
      <div className={`${rootClass}__left`}>
        <Container dataTest={`${dataTest}-name-and-description-container`}>
          <InputV2
            dataTest={`${dataTest}-segment-name-input`}
            placeholder={''}
            value={name}
            onChange={onSegmentNameChange}
            onChangeDebounce={INPUT_DEBOUNCE_TIME}
            onBlur={onSegmentNameBlur}
            labelProps={{ label: t('Segment name'), labelType: LabelType.medium, required: true }}
            inputInfo={{
              enabled: true,
              helperText: t('SegmentComposer.Settings.Name.Helper'),
              errorText: t(inputError),
              hasIcon: true,
            }}
            error={!!inputError}
            handleValueChangeFromOuter
          />
          <div>
            <TextArea
              className={`${rootClass}__segment-description`}
              name={'description'}
              label={t('Description')}
              labelType={LabelType.medium}
              placeholder={t('SegmentComposer.Settings.Description.Placeholder')}
              maxCharacterProps={{ maxLength: DESCRIPTION_MAX_CHARACTERS }}
              value={description}
              onChange={onSegmentDescriptionChange}
            />
          </div>
        </Container>
        {/* TODO: enable this back once it is supported*/}
        {!isDirectSelect && false && (
          <Container dataTest={`${dataTest}-segment-sync-type-container`}>
            <Typography text={t('SegmentComposer.Settings.SyncType.Header')} weight={TextWeight.MEDIUM} />
            <RadioCardGroup selectedOption={syncType} onSelect={onSyncTypeChange}>
              <RadioCard
                key={SegmentSyncType.DYNAMIC}
                title={t('SegmentComposer.Settings.SyncType.Dynamic.Title')}
                description={t('SegmentComposer.Settings.SyncType.Dynamic.Description')}
              />
              <RadioCard
                key={SegmentSyncType.STATIC}
                title={t('SegmentComposer.Settings.SyncType.Static.Title')}
                description={t('SegmentComposer.Settings.SyncType.Static.Description')}
              />
            </RadioCardGroup>
          </Container>
        )}
      </div>
      <div className={`${rootClass}__segment-details`} data-test={`${dataTest}-segment-details-container`}>
        <Typography text={t('Segment Details')} type={TextType.PAGE_HEADER} weight={TextWeight.MEDIUM} lineHeight={LineHeight.LARGE} />
        <div data-test={`${dataTest}-segment-details-created`}>
          <Typography text={t('Created')} weight={TextWeight.MEDIUM} />
          <Typography
            text={t(`SegmentComposer.Settings.Details.Date`, {
              date: format(createdDate ?? Date.now(), DATE_FORMAT),
              user: createdBy ?? userName,
            })}
            lineHeight={LineHeight.LARGER}
          />
        </div>
        <div data-test={`${dataTest}-segment-details-last-updated`}>
          <Typography text={t('Last updated')} weight={TextWeight.MEDIUM} />
          <Typography
            text={t('SegmentComposer.Settings.Details.Date', {
              date: format(lastModifiedDate ?? Date.now(), DATE_FORMAT),
              user: lastModifiedBy ?? userName,
            })}
            lineHeight={LineHeight.LARGER}
          />
        </div>
        <div data-test={`${dataTest}-segment-details-segment-tree`}>
          <Typography text={t('Segment tree')} weight={TextWeight.MEDIUM} />
          <Breadcrumbs className={`${rootClass}__segment-details-breadcrumbs`} breadcrumbs={segmentTreeBreadcrumbs} />
        </div>
      </div>
    </div>
  )
}
