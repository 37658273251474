import React, { FC } from 'react'

import classNames from 'classnames'

import { ANALYTICS_INVISIBLE_ACTIONS } from '@src/pages/reports/Analytics/Analytics.constants'
import { Action } from '@thoughtspot/visual-embed-sdk'
import { useEmbedRef, SearchEmbed } from '@thoughtspot/visual-embed-sdk/react'

import './ExploreData.css'

interface ExploreDataProps {
  hasAIAnalytics: boolean
  detailPath: string
  className?: string
  dataTest?: string
}

const rootClass = 'explore-data'

const ExploreData: FC<ExploreDataProps> = (props: ExploreDataProps) => {
  const { detailPath, hasAIAnalytics, dataTest = rootClass, className = '' } = props
  const embedRef = useEmbedRef<typeof SearchEmbed>()

  const aiAction = !hasAIAnalytics ? [Action.SpotIQAnalyze] : []
  const allActions = [...ANALYTICS_INVISIBLE_ACTIONS, ...aiAction]

  const path = detailPath.includes('answer') ? { answerId: detailPath.replace('answer/', '') } : {}

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <SearchEmbed
        {...path}
        frameParams={{ height: '100%' }}
        dataPanelV2
        hiddenActions={allActions}
        ref={embedRef}
        className={`${rootClass}__embed`}
      />
    </div>
  )
}

export default ExploreData
