import { UNIFIED_CONTACTS_ID } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import { ProgramStepInput } from '@graphql/types/mutation-types'
import { ProgramAppendToSegmentStep, ProgramCopyFromStep, ProgramCopyStep, ProgramFieldSetStep } from '@graphql/types/query-types'
import { ProgramStepType } from '@utils/program/program.constants'

const getModifiedCopyFromStep = (step: ProgramCopyFromStep): ProgramStepInput => ({
  copyFromSrcId: step.srcId,
  stepType: step.stepType,
  stepId: step.stepId,
  displayName: step.displayName,
  srcId: step.srcId,
  pruneRecords: step.pruneRecords,
  updateType: step.updateType,
})

const getModifiedCopyToStep = (step: ProgramCopyStep): ProgramStepInput => ({
  stepType: step.stepType,
  stepId: step.stepId,
  displayName: step.displayName,
  srcId: step.srcId,
  all: step.all,
  not: step.not,
  listId: step.listId,
  update: step.update,
  updateType: step.updateType,
})

const getModifiedAppendToSegmentStep = (step: ProgramAppendToSegmentStep): ProgramStepInput => ({
  stepType: step.stepType,
  stepId: step.stepId,
  displayName: step.displayName,
  srcId: step.srcId,
  all: step.all,
  not: step.not,
  listId: step.listId,
  pruneRecords: step.pruneRecords,
})

const getModifiedFieldSetStep = (step: ProgramFieldSetStep, hasMarketingSource?: boolean): ProgramStepInput => ({
  stepType: step.stepType,
  stepId: step.stepId,
  displayName: step.displayName,
  srcId: step.srcId,
  not: step.not,
  listId: hasMarketingSource ? step.listId : UNIFIED_CONTACTS_ID,
  fieldName: step.fieldName,
  valueString: step.value,
  rule: step.rule,
  campaignId: step.campaignId,
  scoreSheetId: step.scoreSheetId,
  canPushSF: step.canPushSF,
  forceUseOfSourceList: step.forceUseOfSourceList || !hasMarketingSource,
})

export const getModifiedSteps = (
  steps: (ProgramCopyFromStep | ProgramCopyStep | ProgramAppendToSegmentStep | ProgramFieldSetStep)[],
  hasMarketingSource?: boolean
): ProgramStepInput[] => {
  return steps.reduce((stepInputs: ProgramStepInput[], step) => {
    if (step.stepType) {
      return [
        ...stepInputs,
        ...(hasMarketingSource
          ? [
              ...(step.stepType === ProgramStepType.COPY_FROM ? [getModifiedCopyFromStep(step as ProgramCopyFromStep)] : []),
              ...(step.stepType === ProgramStepType.COPY ? [getModifiedCopyToStep(step as ProgramCopyStep)] : []),
            ]
          : [...(step.stepType === ProgramStepType.APPEND_TO_SEGMENT ? [getModifiedAppendToSegmentStep(step as ProgramAppendToSegmentStep)] : [])]),
        ...(step.stepType === ProgramStepType.FIELD_SET ? [getModifiedFieldSetStep(step as ProgramFieldSetStep, hasMarketingSource)] : []),
      ]
    }
    return stepInputs
  }, [])
}
