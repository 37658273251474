import { gql } from '@apollo/client'

export default gql`
  mutation createExportDefinition($exportDefinitionDTO: ExportDefinitionDTOInput) {
    createExportDefinition(exportDefinitionDTO: $exportDefinitionDTO) {
      accountId
      createdTime
      createdTimeExecutionOffsetDateTime
      delimiter
      exportDefinitionId
      exportDefinitionName
      hasHeader
      isEnabled
      mappings {
        actonColumn
        actonColumnId
        exportDefinitionId
        exportMappingId
      }
      quote
      updatedTime
      updatedTimeExecutionOffsetDateTime
      userId
      userName
    }
  }
`
