import React, { FC, ReactNode, Fragment } from 'react'

import classNames from 'classnames'

import Svg from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'

import './Caution.css'

export type MultiStyledStrings = {
  span: string
  weight?: TextWeight
  type?: TextType
}

type Props = {
  message?: string | ReactNode
  textLines?: MultiStyledStrings[]
  alignTop?: boolean
  children?: ReactNode
  className?: string
  dataTest?: string
  isError?: boolean
}

const rootClass = 'caution'

export const renderTextLines = (textLines: MultiStyledStrings[]) => {
  if (textLines) {
    if (textLines.length === 1) {
      const msg = textLines[0]
      const type = msg.type ? { type: msg.type } : {}
      return <Typography text={msg.span} inline weight={msg.weight} className={`${rootClass}__text`} {...type} />
    } else {
      return (
        <div>
          {textLines.map((msg: MultiStyledStrings, index) => {
            const type = msg.type ? { type: msg.type } : {}
            return (
              <Fragment key={index}>
                <Typography text={msg.span} inline weight={msg.weight} className={`${rootClass}__text`} {...type} />
              </Fragment>
            )
          })}
        </div>
      )
    }
  }
}

const Caution: FC<Props> = (props: Props) => {
  const { message, children, alignTop, dataTest = rootClass, className = '', textLines, isError } = props

  return (
    <div
      data-test={`${dataTest}__caution`}
      className={classNames(rootClass, className, {
        [`${rootClass}-top`]: alignTop,
        [`${dataTest}__error`]: isError,
      })}
    >
      <Svg className={`${rootClass}__icon`} name={isError ? SvgNames.errorAlert : SvgNames.cautionYellow} />
      {textLines && renderTextLines(textLines)}
      {message && !textLines && <Typography text={message} />}
      {children ?? null}
    </div>
  )
}

export default Caution
