import React, { ChangeEvent, FC, useState } from 'react'
import { Row } from 'react-table'

import classNames from 'classnames'

import { ApolloError } from '@apollo/client'
import ColumnChart from '@components/ColumnChart/ColumnChart'
import LineChart from '@components/LineChart/LineChart'
import NestedTable from '@components/NestedTable/NestedTable'
import PageContainer from '@components/PageContainer/PageContainer'
import PieChart from '@components/PieChart/PieChart'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import Radio from '@components/Radio'
import RadioGroup from '@components/RadioGroup'
import ReportHeader, { AltViewOption } from '@components/ReportHeader/ReportHeader'
import { TableColumn } from '@components/Table/Table'
import { DirectionOptions } from '@components/TimespanPicker/TimespanPicker'
import { ChartField, ChartProps } from '@const/Chart.constants'
import { useTranslation } from '@const/globals'
import withLoadingAndError from '@hoc/withLoadingAndError/withLoadingAndError'
import { colorLabels } from '@src/pages/reports/revenueImpact/RevenueImpactContainer.constants'

import './RevenueImpact.css'

interface Props {
  leadsConvertedText: string
  trendChartTitle: string
  netChangeColChartTitle: string
  updatedDate: string
  startTime: string
  nextDisabled: boolean
  prevDisabled: boolean
  onDateChange: (direction: DirectionOptions) => void
  opportunityTrends: ChartProps
  opportunitiesNetChange: ChartProps
  revenueBooked: ChartProps
  outcomes: ChartProps
  leadsConverted: { fields: ChartField[] }
  leadsCreated: { fields: ChartField[] }
  chartHeight?: number
  altView: boolean
  loading: boolean
  pageError?: ApolloError
  handleRadioChange: (countSelected: boolean) => void
  altViewOptions: AltViewOption[]
  altViewDropDownChange: (event: ChangeEvent<HTMLSelectElement>) => void
  tableData: Row[]
  columns: TableColumn[]
  className?: string
  dataTest?: string
}

const rootClass = 'revenue-impact'

const RevenueImpact: FC<Props> = (props: Props) => {
  const {
    leadsConvertedText,
    trendChartTitle,
    netChangeColChartTitle,
    updatedDate,
    altView,
    altViewOptions,
    altViewDropDownChange,
    prevDisabled,
    nextDisabled,
    startTime,
    onDateChange,
    handleRadioChange,
    leadsConverted,
    leadsCreated,
    chartHeight = 500,
    opportunityTrends,
    opportunitiesNetChange,
    revenueBooked,
    outcomes,
    tableData,
    columns,
    dataTest = rootClass,
    className = '',
  } = props
  const { t } = useTranslation()
  const [countSelected, setCountSelected] = useState(true)
  const onRadioChange = (isCount: boolean) => {
    setCountSelected(isCount)
    handleRadioChange(isCount)
  }

  const renderRadioGroup = (
    <RadioGroup className="extra-element">
      <Radio label={'Count'} checked={countSelected} name={'Count'} value={'Count'} onChange={() => onRadioChange(true)} />
      <Radio label={'Revenue'} checked={!countSelected} name={'Revenue'} value={'Revenue'} onChange={() => onRadioChange(false)} />
    </RadioGroup>
  )

  return (
    <PageContainer className={classNames(rootClass, className)} data-test={dataTest}>
      <PositionContainer>
        <ReportHeader
          reportType={t('Revenue Impact Summary Report')}
          startTime={startTime}
          prevDisabled={prevDisabled}
          nextDisabled={nextDisabled}
          updatedDate={updatedDate}
          handleChange={onDateChange}
          altView={altView}
          altViewOptions={altViewOptions}
          altViewDropdownChange={altViewDropDownChange}
        />

        <div className={`${rootClass}__container`}>
          <div>
            <PieChart title={t('New Leads Generated')} chartHeight={chartHeight} fields={leadsCreated.fields} isDonut />
          </div>
          <div>
            <PieChart title={leadsConvertedText} chartHeight={chartHeight} fields={leadsConverted.fields} isDonut />
          </div>
        </div>
        <ColumnChart
          title={t('Outcomes')}
          categories={outcomes.categories}
          fields={outcomes.fields}
          outcomesByIncome={!countSelected}
          sharedTooltip
          isBar
          ExtraElement={renderRadioGroup}
          useToolTipNoHeader={true}
        />
        <NestedTable className={'nested-table__report'} data={tableData} columns={columns} coloredLabels={colorLabels} />
        <LineChart categories={opportunityTrends.categories} fields={opportunityTrends.fields} title={trendChartTitle} isDateTime tickInterval={1} />
        <ColumnChart
          title={netChangeColChartTitle}
          categories={opportunitiesNetChange.categories}
          fields={opportunitiesNetChange.fields}
          showTicks
          tickInterval={1}
        />
        <LineChart categories={revenueBooked.categories} fields={revenueBooked.fields} title={t('Revenue Booked')} isDateTime tickInterval={1} />
      </PositionContainer>
    </PageContainer>
  )
}

export default withLoadingAndError(RevenueImpact)
