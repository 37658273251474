import { gql } from '@apollo/client'

export default gql`
  mutation refreshRecordCount($listIds: [String!]!, $type: String!) {
    refreshRecordCount(listIds: $listIds, type: $type) {
      objectId
      statusCode
      message
    }
  }
`
