import React, { FC, useMemo, useRef, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { Column, ColumnStatus, ColumnType } from '@components/ColumnsOrderModal/components/DraggableColumn/DraggableColumn'
import EditColumnsListing, { EditColumnsAction } from '@components/EditColumnsDropdown/components/EditColumnsListing/EditColumnsListing'
import SelectableColumnsSection from '@components/EditColumnsDropdown/components/SelectableColumnsSection/SelectableColumnsSection'
import { formGeoIPFields, formSubmissionFields } from '@components/EditColumnsDropdown/utils/EditColumnsDropdown.constants'
import InputV2 from '@components/InputV2/InputV2'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import ScrollArea from '@components/ScrollArea/ScrollArea'
import { SvgNames } from '@components/Svg'
import { useTranslation } from '@const/globals'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { useUnifiedContactList } from '@utils/hooks/useUnifiedContactList'

import './EditColumnsModal.css'

interface EditColumnsModalProps {
  className?: string
  dataTest?: string
  onSelectField: (fields: { name: string; fieldMapping?: UnifiedListFieldMapping }[], isAdding: boolean) => void
  onCancel: VoidFunction
  onSave: (columns: Column[], checked: boolean) => void
  onOrderChange: (column: Column, action: EditColumnsAction, droppedAt?: number) => void
  onReset: VoidFunction
  saveDisabled?: boolean
  isOpen: boolean
  columns: Column[]
}

const rootClass = 'edit-columns-modal'
const INPUT_DEBOUNCE_TIME = 300

const EditColumnsModal: FC<EditColumnsModalProps> = (props: EditColumnsModalProps) => {
  const { dataTest = rootClass, className = '', onSave, onCancel, isOpen, columns, onOrderChange, onReset, onSelectField, saveDisabled } = props

  const { t } = useTranslation()

  const { unifiedListFieldMappings = [], loading } = useUnifiedContactList()

  const [searchFilter, setSearchFilter] = useState('')

  const scrollableContainerRef = useRef<HTMLDivElement>()

  const isSearchResult = (name: string) => name.toLowerCase().includes(searchFilter.toLowerCase())

  const columnsNames = useMemo<Map<ColumnType, string[]>>(() => {
    const setColumnSectionMap = (columnsSections: Map<ColumnType, string[]>, column: string, columnType: ColumnType) => {
      return !searchFilter || isSearchResult(column)
        ? columnsSections.set(columnType, [...(columnsSections.get(columnType) ?? []), column])
        : columnsSections
    }

    const defaultMap: Map<ColumnType, string[]> = new Map()
    const actonContactsFields = unifiedListFieldMappings.map(({ displayName = '' }) => displayName)
    const filteredActonContactsFields = searchFilter ? actonContactsFields.filter((name) => isSearchResult(name)) : actonContactsFields

    if (filteredActonContactsFields.length) {
      defaultMap.set(ColumnType.ACTON_CONTACTS, filteredActonContactsFields)
    }

    return columns.reduce((columnsSections: Map<ColumnType, string[]>, { name: columnName }) => {
      if (formGeoIPFields.includes(columnName)) {
        return setColumnSectionMap(columnsSections, columnName, ColumnType.GEOIP)
      } else if (formSubmissionFields.includes(columnName)) {
        return setColumnSectionMap(columnsSections, columnName, ColumnType.FORM_SUBMISSION)
      } else if (!actonContactsFields.includes(columnName)) {
        return setColumnSectionMap(columnsSections, columnName, ColumnType.FORM)
      }
      return columnsSections
    }, defaultMap)
  }, [unifiedListFieldMappings, searchFilter, columns])

  const selectedColumns = useMemo(() => {
    return Array.from(columnsNames.entries()).reduce((selectedColumns: Map<ColumnType, string[]>, [columnType, headers]) => {
      const selectedHeaders = headers.filter((header) =>
        columns.find(({ name, type, status }) => type === columnType && name === header && status !== ColumnStatus.HIDDEN)
      )
      return selectedColumns.set(columnType, selectedHeaders)
    }, new Map())
  }, [columns, columnsNames])

  const onSelect = (fields: string[], isAdding: boolean) => {
    const fieldsWithMappings: { name: string; fieldMapping?: UnifiedListFieldMapping }[] = fields.map((field) => ({
      name: field,
      fieldMapping: unifiedListFieldMappings.find(({ displayName = '' }) => displayName === field),
    }))
    onSelectField(fieldsWithMappings, isAdding)
  }

  const handleAction = () => {
    onSave(columns, true)
  }

  const handleClose = () => {
    onCancel()
  }

  const header = (
    <ModalHeader headerType={ModalHeaderType.List} className={`${rootClass}__header`}>
      {t('Manage columns')}
    </ModalHeader>
  )

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header} noPadding>
      <ModalBody className={`${rootClass}__body`}>
        <div>
          <InputV2
            className={`${rootClass}__search`}
            leadingIcon={SvgNames.search}
            placeholder={t('Search columns')}
            onChange={({ target: { value } }) => setSearchFilter(value)}
            onChangeDebounce={INPUT_DEBOUNCE_TIME}
          />
          <ScrollArea className={`${rootClass}__available-fields`} showOnEvent={'scroll'} register={scrollableContainerRef}>
            {Array.from(columnsNames.entries()).map(([columnType, headers]) => (
              <SelectableColumnsSection
                key={columnType}
                onSelect={onSelect}
                headers={headers}
                selectedHeaders={selectedColumns.get(columnType) ?? []}
                sectionHeader={t(columnType)}
                loading={loading}
                disableCollapse={!!searchFilter.length}
                scrollableContainerRef={scrollableContainerRef}
                isActOnContacts={columnType === ColumnType.ACTON_CONTACTS}
              />
            ))}
          </ScrollArea>
        </div>
        <div className={`${rootClass}__separator`} />
        <EditColumnsListing
          dataTest={`${dataTest}-columns-listing`}
          className={`${rootClass}__columns-listing`}
          columns={columns}
          onChange={onOrderChange}
          onReset={onReset}
          inDropdown={false}
        />
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.List} className={`${rootClass}__footer`}>
        <Button buttonType={ButtonType.TERTIARY} onClick={handleClose} dataTest={`${dataTest}-button-tertiary`}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} onClick={handleAction} dataTest={`${dataTest}-button-primary`} disabled={saveDisabled}>
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default EditColumnsModal
