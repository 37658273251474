import React, { ReactNode } from 'react'

import { getFolderById } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import {
  DuplicateListingPageItem,
  GetFilterItemsWithCustomRequests,
  ListingPageProps,
  ListPageAPI,
  ListPageCommonState,
  SortBy,
} from '@complex/ListingPage/Context/ListingPageCommon.context'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { Status } from '@components/StatusToast/StatusToast'
import SvgNames from '@components/Svg/SvgNames'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { legacyActonContext, rootContext } from '@const/globals'
import { FolderDto, ItemDto, LabelDto } from '@graphql/types/microservice/categorization-types'
import { LabelDtoInput } from '@graphql/types/microservice/segment-types'
import { BulkAssetMutationResponse } from '@graphql/types/query-types'
import { Folder } from '@interface/Folder'
import { ItemType } from '@utils/categorization'
import { FilterDefinition } from '@utils/filter'

export interface ListingPageStatusToast {
  statusMessage: string | ReactNode
  status: Status
  showStatusToast: boolean
  folderName?: string
  hasTimeout?: boolean
}

export const FORM_COMPOSER_LINK = `${legacyActonContext}/ng-ui/editor/#!/form-builder/start`
export const COMMON_DROPPABLE_TYPES = [ItemType.FRAGMENT, ItemType.SEGMENT, ItemType.UNSORTABLE_SEGMENT, ItemType.FORM, ItemType.FORM_TEMPLATE]
export const PAGE_SIZE = 50
export const SEARCH_PAGE_SIZE = 100

export enum FormType {
  CLASSIC = 'classic',
  NEW = 'new',
}

export const catalogUrl = `${rootContext}/classic/sharing`

export const CatalogLink = {
  CONTENT_FRAGMENT: `${catalogUrl}?type=CONTENT_FRAGMENT`,
  PROGRAM: `${catalogUrl}?type=PROGRAM`,
  LANDING_PAGE: `${catalogUrl}?type=LANDING_PAGE`,
  FORM: `${catalogUrl}?type=FORM`,
  EMAIL_TEMPLATE: `${catalogUrl}?type=TEMPLATE`,
  EMAIL_DRAFT: `${catalogUrl}?type=EMAIL_DRAFT`,
}

export enum LandingPageStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export const listingPageMockAPI: ListPageAPI = {
  // eslint-disable-next-line no-console
  onColumnSort: (sortBy: SortBy[]) => console.log(`sort by ${sortBy}`),
  // eslint-disable-next-line no-console
  shareToCatalog: () => console.log('share to catalog'),
  // eslint-disable-next-line no-console
  removeItemsFromFolder: () => console.log('remove items from folder'),
  // eslint-disable-next-line no-console
  moveItemsToFolder: (folderId) => console.log(`move folder ${folderId}`),
  // eslint-disable-next-line no-console
  deleteItems: (items) => console.log(`delete items ${items}`),
  // eslint-disable-next-line no-console
  getTagItems: () => console.log('get tag items'),
  // eslint-disable-next-line no-console
  getItemTypesUsedInTags: () => Promise.resolve({}),
  // eslint-disable-next-line no-console
  getFolderItems: () => console.log('get folder items'),
  // eslint-disable-next-line no-console
  getFilterItems: () => console.log('get filter items'),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-console
  getFilterItemsWithCustomRequests: (_requests: GetFilterItemsWithCustomRequests) => console.log('get filter items with custom requests'),
  // eslint-disable-next-line no-console
  setError: (message, error) => console.log(`set error ${message} ${error}`),
  setStatusToast: (message: string | ReactNode, status: Status, folderName?: string, hasTimeout?: boolean) =>
    // eslint-disable-next-line no-console
    console.log(`set status toast ${message} ${status} ${folderName} ${hasTimeout}`),
  // eslint-disable-next-line no-console
  setTag: (tag: LabelDto, clicked?: boolean) => console.log(`set tag ${tag} ${clicked}`),
  // eslint-disable-next-line no-console
  setItems: (items) => console.log(`set Items ${items}`),
  // eslint-disable-next-line no-console
  update: (fields) => console.log(`update ${fields}`),
  // eslint-disable-next-line no-console
  setFolder: (folderId: number) => console.log(`set folder ${folderId}`),
  // eslint-disable-next-line no-console
  onSearch: (showAllItems: boolean) => console.log(`to Search ${showAllItems}`),
  // eslint-disable-next-line no-console
  setFilter: (filter: FilterDefinition, customFilterSelected: boolean) => console.log(`${filter} ${customFilterSelected}`),
  // eslint-disable-next-line no-console
  createFolder: (folder: FolderDto) => console.log(folder),
  // eslint-disable-next-line no-console
  renameFolder: (folder: FolderDto) => console.log(folder),
  // eslint-disable-next-line no-console
  deleteFolder: (folder: FolderData) => console.log(folder),
  // eslint-disable-next-line no-console
  createTag: (tag: LabelDtoInput) => console.log(tag),
  // eslint-disable-next-line no-console
  deleteTag: (tag: LabelDto) => console.log(tag),
  // eslint-disable-next-line no-console
  duplicateItem: (params: DuplicateListingPageItem, _listPageAPI: ListPageAPI) => console.log(params),
  // eslint-disable-next-line no-console
  setBulkResponseModal: (bulkResponse: BulkAssetMutationResponse) => console.log(bulkResponse),
  // eslint-disable-next-line no-console
  onClickTagInRow: (value: string) => console.log(value),
  // eslint-disable-next-line no-console
  onShouldFetch: () => console.log('onShouldFetch'),
  // eslint-disable-next-line no-console
  toggleSubType: () => console.log('toggleSubType'),
}

export const defaultListingPageValues: ListingPageProps = {
  renderDataCards: () => <></>,
  getCustomFilterParams: () => ({ field: '', query: '' }),
  getCustomEmptyListingProps: () => ({ text: '' }),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getCustomActiveFilter: (_filter: string) => undefined,
  sidebarProps: {
    sidebarHeader: '',
    hasFavorites: true,
    hasRecent: false,
    hasRecommended: false,
    hasCreatedByMe: false,
    allItemFilter: {
      name: '',
      svgUnselected: SvgNames.segmentsUnselected,
      svgSelected: SvgNames.segmentsSelected,
    },
    renderCustomFilters: () => [
      {
        icon: SvgNames.icon,
        header: '',
        isOpen: true,
        content: undefined,
      },
    ],
    customFilterSelected: false,
  },
  pageHeaderProps: {
    pageTitle: '',
    renderPageHeaderContent: () => <></>,
  },
  tableProps: {
    hasAutoSelectedRows: false,
    hasExpander: false,
    listPage: '',
    columns: [],
    headerActionCustomProps: [],
    renderSearchColumns: () => [],
    rowActionCustomProps: [],
    onCustomTableAction: () => undefined,
  },
}

export const listPageContextCommonValues: ListPageCommonState = {
  selectedIds: [],
  emptyListingProps: {},
  actionSelectedRow: [],
  addToFolder: false,
  creatingFolder: false,
  showPreview: false,
  isEditingFolder: false,
  initialPageLoading: true,
  creatingTag: false,
  canShareToChildAccounts: false,
  disableShareToChildAccountsTooltip: '',
  isEditingTag: false,
  fetchTags: true,
  fetchItems: false,
  fetchNextPageItems: false,
  fetchFolders: true,
  fetchFilterCounts: true,
  listingPageProps: { ...defaultListingPageValues },
  dataTest: '',
  showDeleteConfirmationModal: false,
  filterActive: undefined,
  activeSubTypes: [],
  defaultSubTypes: [],
  filterCounts: {},
  canShareToCatalog: false,
  canDeleteItems: true,
  canCreate: true,
  canEdit: true,
  activeFolderId: undefined,
  hasToExpandFolders: [],
  folders: [],
  tags: [],
  errors: {},
  folderPath: [],
  readOnlyFolders: false,
  deleteConfirmationData: [],
  confirmationModal: undefined,
  loading: true,
  pageError: false,
  saved: false,
  search: '',
  searchFields: ['name'],
  searchItemsResults: [],
  searchItemsLoading: false,
  itemsToMove: [],
  selectedRows: [],
  selectedRowsRestore: [],
  restoreRowsOnModalClose: false,
  items: [],
  itemTypesUsed: {},
  isProcessingAction: false,
  showCustomModal: false,
  refetchCountsOnAction: false,
  customTableAction: '',
  movingItem: false,
  showManageTag: false,
  statusToast: { statusMessage: '', status: Status.FAIL, showStatusToast: false },
  currentPage: 0,
  allItemsLoaded: false,
  infoHoverLoading: false,
  showShareToCatalog: false,
  itemsId: [],
  sortBy: [{ id: 'name', desc: true }],
  isTableSortInitialized: false,
  isSalesUser: false,
}

export const removeItemFromFolderMessage = (
  selectedRows: ItemDto[],
  folders: Folder[],
  text: string,
  haveDifferentFolders?: string,
  activeFolderId?: number
) => {
  const name = selectedRows.length ? (selectedRows[0] as any)?.name : ''

  const values = {
    itemName: name,
    count: selectedRows.length,
    ...(!haveDifferentFolders ? { folderName: activeFolderId ? getFolderById(activeFolderId, folders)?.name : '' } : {}),
  }

  return <Typography type={TextType.BODY_TEXT_LIGHT} inline tagProps={{ bold: { weight: TextWeight.BOLD } }} text={text} values={values} />
}
