import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Svg, { SvgNames, SvgType } from '@components/Svg'

import './TagManagerTrigger.css'

export interface TagManagerTriggerProps {
  className?: string
  dataTest?: string
  children?: ReactNode
  onClick?: () => void
  svgType?: SvgType
}

const rootClass = 'tag-manager-trigger'

const TagManagerTrigger: FC<TagManagerTriggerProps> = (props: TagManagerTriggerProps) => {
  const { children, onClick, svgType = SvgType.SMALLER_ICON, dataTest = rootClass, className = '' } = props
  return (
    <div
      role={'button'}
      tabIndex={0}
      onKeyDown={(keyDownEvent) => (keyDownEvent.key === ' ' ? onClick && onClick() : undefined)}
      className={classNames(rootClass, className)}
      data-test={dataTest}
      onClick={() => onClick && onClick()}
    >
      <Svg className={`${rootClass}__icon`} name={SvgNames.tag} type={svgType} />
      {children && children}
    </div>
  )
}

export default TagManagerTrigger
