import { gql } from '@apollo/client'

export default gql`
  mutation setActonLabs($enabled: Boolean!, $feature: String) {
    setActonLabs(enabled: $enabled, feature: $feature) {
      status
      message
      enabled
    }
  }
`
