import React, { FC, Fragment, useContext } from 'react'

import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { UpgradeManagerContext } from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'
import { ReviewCurrentStep } from '@src/pages/programs/UpgradePrograms/components/ReviewUpgrade/sections/FixStepIssuesSection/components/ReviewCurrentStep/ReviewCurrentStep'

import './FixStepIssuesSection.css'

const rootClass = 'fix-step-issues-section'

export const FixStepIssuesSection: FC = () => {
  const {
    values: { upgradedSteps },
  } = useContext(UpgradeManagerContext)
  const { t } = useTranslation()

  return (
    <div className={rootClass}>
      <Typography
        text={t('AutomatedPrograms.UpgradeManager.SidebarMenu.Progress.FixIssues')}
        type={TextType.BODY_TEXT}
        weight={TextWeight.MEDIUM}
        lineHeight={LineHeight.MEDIUM}
      />
      {upgradedSteps
        ?.filter(({ removed }) => !removed)
        .map((step, index) => (
          <Fragment key={`review-step-${step.stepId}`}>
            {index !== 0 && <div className={'divider'} />}
            <ReviewCurrentStep step={step} />
          </Fragment>
        ))}
    </div>
  )
}
