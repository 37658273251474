import { TFunction } from 'i18next'

export const getBeeTranslations = (t: TFunction, isLandingPage?: boolean) => ({
  'mailup-bee-newsletter-collaboration': {
    'locked-module-warning-message': t('This module is being edited by another user and therefore its content cannot be edited.'),
    'locked-module-warning-title': t('This module is locked'),
    'locked-row-warning-message': t('This row is being edited by another user and therefore its content cannot be edited.'),
    'locked-row-warning-title': t('This row is locked'),
    'row-delete-confirm-title': t('Are you sure you want to delete this row?'),
    'row-delete-confirm-message': t(
      'Another user is currently editing a module inside this row. By deleting it, you might cause their work to be lost.'
    ),
    'lock-rejected-title': t('Element was locked by another user.'),
    'lock-rejected-message': t('The element is currently being edited by another user. Please wait until they are finished with their changes.'),
  },
  'mailup-bee-common-widgets-heading': {
    'default-text': t(`Add title text`),
  },
  'mailup-bee-common-widgets-paragraph': { 'default-text': t(`Add paragraph text`) },
  'mailup-bee-common-widgets-list': { 'default-html': `<ul><li>${t('Add an ordered or unordered list')}</li></ul>` },
  'bee-newsletter-modules-html': {
    html: t('Add HTML content'),
    'widget-warning-desc': isLandingPage
      ? 'Using your own code may affect how the landing page is rendered. Make sure to use correct and responsive HTML.'
      : 'Using your own code may affect how the message is rendered. Make sure to use correct and responsive HTML.',
  },
  'bee-common-widget-bar': {
    body: t('Global styles'),
  },
  'mailup-bee-common-widgets-font-size': { label: t('Font size (pixels)') },
})
