import React, { FC, ReactNode, useEffect, useState } from 'react'
import { Prompt, useHistory } from 'react-router-dom'

import { Location } from 'history'

import { ConfirmationModal, YesNo } from '@components/ConfirmationModal/ConfirmationModal'
import { useTranslation } from '@const/globals'

import './CustomPrompt.css'

interface State {
  modalVisible: boolean
  confirmedNavigation: boolean
  location: Location | null
  documentTitle: string
  newTitle: string
  triggered: boolean
  searchPerformed: boolean
}

type Props = {
  className?: string
  dataTest?: string
  when: boolean
  title: string | undefined
  body: string | ReactNode
  cancelButtonText?: string
  okButtonText?: string
  searchAction?: () => void
  onConfirm?: () => void
  searchPerformed?: boolean
  showOnLocationChange?: (nextLocation: Location) => boolean
}

const rootClass = 'custom-prompt'

const CustomPrompt: FC<Props> = (props: Props) => {
  const {
    when,
    title,
    body,
    cancelButtonText,
    okButtonText = 'Confirm',
    searchAction,
    onConfirm,
    searchPerformed,
    dataTest = rootClass,
    showOnLocationChange,
  } = props
  const [state, setState] = useState<State>({
    modalVisible: searchPerformed || false,
    confirmedNavigation: false,
    location: null,
    documentTitle: document.title,
    newTitle: '',
    triggered: false,
    searchPerformed: searchPerformed ?? false,
  })
  const { modalVisible, triggered } = state
  const history = useHistory()
  const { t } = useTranslation()

  const handlePromptNavigation = (nextLocation: Location) => {
    const { confirmedNavigation, documentTitle } = state
    const showPrompt = !showOnLocationChange || showOnLocationChange(nextLocation)
    if (showPrompt) {
      setState((state) => ({
        ...state,
        location: nextLocation,
        modalVisible: !confirmedNavigation,
        newTitle: document.title,
      }))
      document.title = documentTitle
    }
    return confirmedNavigation
  }

  const cancelAction = () => {
    const { triggered } = state
    setState({ ...state, confirmedNavigation: false, modalVisible: false, searchPerformed: false, triggered: !triggered })
  }

  const okAction = () => {
    const { triggered } = state
    setState({
      ...state,
      confirmedNavigation: true,
      modalVisible: false,
      searchPerformed: true,
      triggered: !triggered,
    })
    onConfirm && onConfirm()
  }

  useEffect(() => {
    const { confirmedNavigation, location: newLocation, newTitle, searchPerformed } = state
    if (confirmedNavigation && searchAction && searchPerformed) {
      searchAction()
    } else if (confirmedNavigation && newLocation) {
      history.push(newLocation.pathname, newLocation.state)
      document.title = newTitle
    }
  }, [triggered])

  return (
    <>
      <Prompt when={when} message={handlePromptNavigation} />
      <ConfirmationModal
        className={rootClass}
        dataTest={dataTest}
        isOpen={modalVisible}
        onAnswer={(answer) => (answer === YesNo.YES ? okAction() : cancelAction())}
        title={t(title)}
        body={t(body)}
        cancelButtonText={cancelButtonText}
        deleteButtonText={okButtonText}
        isDelete
      />
    </>
  )
}

export default CustomPrompt
