import React, { FC, ReactNode } from 'react'

import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'

export interface DeleteConfirmationModalProps {
  isOpen: boolean
  title: string
  deleteButtonText: string
  cancelButtonText?: string
  body: ReactNode
  onAnswer: (answer: YesNo) => void
  className?: string
  dataTest?: string
  isRemove?: boolean
}

const DeleteConfirmationModal: FC<DeleteConfirmationModalProps> = (props: DeleteConfirmationModalProps) => (
  <ConfirmationModal isYesNo isDelete={!props.isRemove} {...props} />
)

export default DeleteConfirmationModal
