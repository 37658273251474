import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Container from '@components/Container/Container'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './StepContent.css'

export interface StepContentProps {
  className?: string
  dataTest?: string
  title: string
  subTitle?: string
  children: ReactNode
  onClick?: VoidFunction
  buttonText?: string
}

const rootClass = 'step-content'

const StepContent: FC<StepContentProps> = (props: StepContentProps) => {
  const { dataTest = rootClass, className = '', buttonText, children, onClick, title, subTitle } = props
  const { t } = useTranslation()

  return (
    <Container className={classNames(rootClass, className)} data-test={dataTest}>
      <Typography text={t(title)} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} inline />
      {!!subTitle && <Typography text={t(subTitle)} type={TextType.BODY_TEXT_LIGHT} />}
      <div className={`${rootClass}__content`}>{children}</div>
      {!!onClick && !!buttonText && (
        <Button className={`${rootClass}__button`} buttonType={ButtonType.SECONDARY} onClick={onClick}>
          <Svg name={SvgNames.plus} type={SvgType.ICON} />
          {t(buttonText)}
        </Button>
      )}
    </Container>
  )
}

export default StepContent
