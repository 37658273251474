import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import NumberInput from '@components/NumberInput/NumberInput'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './NumberRangeInput.css'

export interface NumberRangeInputProps {
  className?: string
  dataTest?: string
  minValue?: number
  maxValue?: number
  onMinChange: (value?: number) => void
  onMaxChange: (value?: number) => void
  onErrorStatusChange?: (error: string | undefined) => void
  handleErrorFromOuter?: boolean
  symbol?: string
}

const rootClass = 'number-range-input'

interface NumberRangeInputState {
  error?: string
  minFocused?: boolean
  maxFocused?: boolean
}

const NumberRangeInput: FC<NumberRangeInputProps> = (props: NumberRangeInputProps) => {
  const {
    dataTest = rootClass,
    className = '',
    minValue,
    maxValue,
    onMinChange,
    onMaxChange,
    onErrorStatusChange,
    handleErrorFromOuter,
    symbol,
  } = props
  const [state, setState] = useState<NumberRangeInputState>({})
  const { error, minFocused, maxFocused } = state
  const { t } = useTranslation()

  const validate = () => {
    if (minValue !== undefined && maxValue !== undefined && minValue >= maxValue) {
      setState({ ...state, error: 'NumberRangeInput.Error.MinExceed' })
      return
    }

    if (!minFocused && !maxFocused) {
      if (minValue === undefined && maxValue === undefined) {
        setState({ ...state, error: 'NumberRangeInput.Error.BothRequired' })
        return
      }

      if (minValue === undefined) {
        setState({ ...state, error: 'NumberRangeInput.Error.MinRequired' })
        return
      }

      if (maxValue === undefined) {
        setState({ ...state, error: 'NumberRangeInput.Error.MaxRequired' })
        return
      }
    }
    setState({ ...state, error: undefined })
  }

  useEffect(() => {
    onErrorStatusChange && onErrorStatusChange(error)
  }, [error])

  useEffect(() => {
    if (minFocused !== undefined || maxFocused !== undefined) {
      validate()
    }
  }, [minFocused, maxFocused])

  const showError = !!error && !handleErrorFromOuter

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={classNames(`${rootClass}__container`, { [`${rootClass}__container-error`]: showError })}>
        <NumberInput
          className={classNames(`${rootClass}__min`, { [`${rootClass}__min-empty`]: minValue === undefined })}
          value={minValue}
          onChange={onMinChange}
          placeholder={t('Min')}
          dataTest={`${dataTest}-min`}
          onBlur={() => setState({ ...state, minFocused: false })}
          onFocus={() => setState({ ...state, minFocused: true })}
        />
        <div className={`${rootClass}__icon`}>
          <Svg name={SvgNames.datePickerArrow} type={SvgType.ICON} />
        </div>
        <NumberInput
          className={classNames(`${rootClass}__max`, { [`${rootClass}__max-empty`]: maxValue === undefined })}
          value={maxValue}
          onChange={onMaxChange}
          placeholder={t('Max')}
          dataTest={`${dataTest}-max`}
          onBlur={() => setState({ ...state, maxFocused: false })}
          onFocus={() => setState({ ...state, maxFocused: true })}
          symbol={symbol}
        />
      </div>
      {showError && (
        <div className={`${rootClass}__error`} data-test={`${dataTest}-error`}>
          <Svg name={SvgNames.inputStatusInvalidNoFill} type={SvgType.ICON} fill={SvgColor.ERROR_TEXT} />
          <Typography text={t(error)} type={TextType.ERROR_NEW} lineHeight={LineHeight.MEDIUM_SMALL} inline />
        </div>
      )}
    </div>
  )
}

export default NumberRangeInput
