import { ClickthroughLinkInput } from '@graphql/types/mutation-types'
import { ClickthroughLink } from '@graphql/types/query-types'
import { StatusToastType } from '@interface/StatusToast'
import { createSafeContext, useSafeContext } from '@utils/account/account.utils'
import { LandingPageType } from '@utils/landingPages'

export interface LandingPagesManagerContextAPI {
  landingPage: LandingPageType
  clickthroughLinks: ClickthroughLink[]
  setToastStatus: (value: StatusToastType) => void
  updateLandingPage: (landingPage: Partial<LandingPageType>) => void
  onEdit: () => void
  dirtyClickthroughLinks: boolean
  onSaveLinks: () => void
  onCancelLinkChanges: () => void
  onClickthroughLinkChange: (link: ClickthroughLinkInput) => void
  accountSlugs: Array<string | undefined>
  refetchAccountSlugs: () => void
}

const LandingPagesManagerContext = createSafeContext<LandingPagesManagerContextAPI>()
export const useLandingPageContext = () => useSafeContext(LandingPagesManagerContext)

export default LandingPagesManagerContext
