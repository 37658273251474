import { FC, useContext, useEffect, useRef } from 'react'
import React from 'react'

import checkFieldMappings from '@graphql/microservices/crm/checkFieldMapping'
import crmDefinition from '@graphql/microservices/crm/crmDefinition'
import getFieldMapping from '@graphql/microservices/crm/getFieldMapping'
import scoreSheets from '@graphql/microservices/list/scoreSheets'
import standardFields from '@graphql/microservices/list/standardFields'
import {
  CheckFieldMappingQuery,
  CheckFieldMappingQueryVariables,
  CrmDefinitionQuery,
  CrmDefinitionQueryVariables,
  GetFieldMappingQuery,
  GetFieldMappingQueryVariables,
} from '@graphql/types/microservice/crm-types'
import {
  ScoreSheetsQuery,
  ScoreSheetsQueryVariables,
  StandardFieldsQuery,
  StandardFieldsQueryVariables,
} from '@graphql/types/microservice/list-types'
import CrmContacts from '@src/pages/datamanagement/components/CrmContacts/CrmContacts'
import { useCrmContactsRequests } from '@src/pages/datamanagement/components/CrmContacts/utils/CrmContacts.graphQL'
import { DataManagementContext } from '@src/pages/datamanagement/context/DataManagementContext'
import { useAccountSettings } from '@utils/account/account.utils'
import useLazyQueryOnMount from '@utils/hooks/useLazyQueryOnMount'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'
import { logNewRelicError } from '@utils/new-relic.utils'

type Props = {
  className?: string
  dataTest?: string
  language: string
}

const CrmContactsContainer: FC<Props> = (props: Props) => {
  const {
    savePushPolicy,
    update,
    values: {
      clients: { listSrvClient, crmSyncSrvClient },
    },
  } = useContext(DataManagementContext)

  const { getConnectedCrm } = useAccountSettings()

  const { getPushPolicy, getUnifiedListFieldMappings } = useCrmContactsRequests()

  const { client: crmClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CRM })

  const dataLoadedRef = useRef(false)

  const { loading: standardFieldsLoading, data: standardFieldsList } = useQueryOnMount<StandardFieldsQuery, StandardFieldsQueryVariables>(
    standardFields,
    {
      client: listSrvClient,
      fetchPolicy: 'network-only',
    }
  )

  const { loading: scoresheetsLoading, data: scoresheetsList } = useQueryOnMount<ScoreSheetsQuery, ScoreSheetsQueryVariables>(scoreSheets, {
    client: listSrvClient,
    fetchPolicy: 'network-only',
  })

  const { loading: crmDefinitionsLoading, data: crmDefinitions } = useQueryOnMount<CrmDefinitionQuery, CrmDefinitionQueryVariables>(crmDefinition, {
    client: crmSyncSrvClient,
    fetchPolicy: 'network-only',
    variables: {
      connectedCrmName: getConnectedCrm,
    },
  })

  const [lookupFieldMappingList, { loading: lookupFieldMappingLoading, data: lookupFieldMappingData }] = useLazyQueryOnMount<
    GetFieldMappingQuery,
    GetFieldMappingQueryVariables
  >(getFieldMapping, { client: crmClient, fetchPolicy: 'network-only' })

  // calls gql endpoint with list of fields to see whether the requested mapping is valid or not
  const [checkRowFieldMappings, { loading: checkRowFieldMappingLoading, data: checkRowFieldMappingData }] = useLazyQueryOnMount<
    CheckFieldMappingQuery,
    CheckFieldMappingQueryVariables
  >(checkFieldMappings, {
    client: crmSyncSrvClient,
    fetchPolicy: 'network-only',
  })

  const loadPushPolicy = async () => {
    try {
      update('pushPolicyLoading', true)
      const { data, errors } = await getPushPolicy()
      if (data?.pushPolicy) {
        update('pushPolicy', data?.pushPolicy)
      } else if (!errors) {
        await savePushPolicy(true)
        const { data: updatedData } = await getPushPolicy()
        update('pushPolicy', updatedData?.pushPolicy)
      }
    } catch (error) {
      logNewRelicError(error)
    } finally {
      update('pushPolicyLoading', false)
    }
  }

  useEffect(() => {
    if (!dataLoadedRef.current) {
      dataLoadedRef.current = true
      update('loadingULFMappings', true)
      getUnifiedListFieldMappings()
        .then((unifiedListFieldMappings) => {
          update('unifiedListFieldMappings', unifiedListFieldMappings)
        })
        .finally(async () => {
          await loadPushPolicy()
          update('loadingULFMappings', false)
        })
    }
  }, [])

  return (
    <CrmContacts
      {...props}
      lookupFieldMappingData={lookupFieldMappingData}
      lookupFieldMappingLoading={lookupFieldMappingLoading}
      lookupFieldMappingList={lookupFieldMappingList}
      crmDefinitions={crmDefinitions}
      crmDefinitionsLoading={crmDefinitionsLoading}
      standardFieldsList={standardFieldsList}
      standardFieldsLoading={standardFieldsLoading}
      scoresheetsLoading={scoresheetsLoading}
      scoresheetsList={scoresheetsList}
      checkRowFieldMappingData={checkRowFieldMappingData}
      checkRowFieldMappingLoading={checkRowFieldMappingLoading}
      checkRowFieldMappings={checkRowFieldMappings}
    />
  )
}

export default CrmContactsContainer
