import React, { FC } from 'react'
import { useForm } from 'react-hook-form'

import * as yup from 'yup'

import FormRow from '@components/FormRow/FormRow'
import Input from '@components/Input/Input'
import { ModalBody } from '@components/Modal'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { Program } from '@graphql/types/query-types'
import { yupResolver } from '@hookform/resolvers/yup'
import OptOutStepDetail from '@src/pages/programs/dashboard/components/ProgramSteps/components/OptOutStepDetail/OptOutStepDetail'
import { ProgramBaseStepExt, Step, Track } from '@utils/program/program.constants'

import './editOptOutStep.css'

const rootClass = 'edit-optout-step'

interface Props {
  step: Step
  tracks: Track[]
  isRunning: boolean
  saveStepAndProgram(step: Step | null, program?: Program): void
  submitId: string
  dataTest?: string
}

const schema = yup.object().shape({
  displayName: yup.string().required('Step Name is required.'),
})

const EditOptOutStep: FC<Props> = (props: Props) => {
  const { step: baseStep, isRunning, saveStepAndProgram, submitId, dataTest = 'edit-optout-step' } = props

  const step = baseStep as ProgramBaseStepExt

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })
  const { t } = useTranslation()

  const onSubmit = (data: any) => {
    const newStep = {
      ...step,
      ...data,
    }
    saveStepAndProgram(newStep)
  }

  const renderForm = () => (
    <form data-test={dataTest} onSubmit={handleSubmit(onSubmit)} className={rootClass}>
      <FormRow>
        <Input label={t('Step Name')} defaultValue={step.displayName} name="displayName" register={register('displayName')} />
        {errors.displayName && <span className="error">{t(errors.displayName.message)}</span>}
      </FormRow>
      <div className={`${rootClass}__description`}>
        <div>{t('The contact will be added to your account Opt-Out List,')}</div>
        <div>{t('but the contact will remain in the program.')}</div>
      </div>
      <button type="submit" id={submitId} hidden />
    </form>
  )

  const renderView = () => (
    <>
      <Typography text={step.displayName} weight={TextWeight.MEDIUM} type={TextType.SECTION_HEADER} />
      <OptOutStepDetail />
    </>
  )

  return <ModalBody>{isRunning ? renderView() : renderForm()}</ModalBody>
}

export default EditOptOutStep
