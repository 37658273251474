import React from 'react'

// eslint-disable-next-line no-restricted-imports
import { format } from 'date-fns'

import Button, { ButtonType } from '@components/Button'
import Svg, { SvgColor, SvgType } from '@components/Svg/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { ColWithTooltip } from '@components/TableV2/utils/tableV2ColumnUtils'
import Typography from '@components/Typography/Typography'
import { GetItemsByExternalIdsQuery } from '@graphql/types/microservice/categorization-types'
import { GetAssetAuditsQuery } from '@graphql/types/microservice/data-lake-query-types'
import {
  AssetAuditDTO,
  AssetType,
  EventType,
  LogItem,
  rootClass,
  UpgradeLogModalState,
} from '@src/pages/UpgradeAssistant/components/UpgradeLogModal/UpgradeLogModal.constants'
import { errorStatusToast, UpgradeItemType } from '@src/pages/UpgradeAssistant/UpgradeAssistant.contants'
import { CellContext } from '@tanstack/react-table'
import { ItemType } from '@utils/categorization'
import { Form } from '@utils/forms'
import { logNewRelicError } from '@utils/new-relic.utils'
import { ensureFirstLetterIsCapitalized } from '@utils/strings'
import { FetchPromise } from '@utils/types'

const lastModifiedDateFormat = "MMM d, yyyy 'at' h:mm aa"

/**
 * Function to get form items by external ids and merge its name with the asset
 * @param getItemsByExternalIdsResquest - function to get items by external ids
 * @param externalIds - external ids of the items
 * @param assets - assets to merge the items
 * @param totalItems
 * @param setState - function to set the state
 * @returns void
 */
const getFormItems = (
  getItemsByExternalIdsResquest: (externalIds: string[], type: ItemType) => FetchPromise<GetItemsByExternalIdsQuery>,
  externalIds: string[],
  assets: AssetAuditDTO[],
  totalItems: number,
  setState: Function
) => {
  Promise.all([getItemsByExternalIdsResquest(externalIds, ItemType.FORM), getItemsByExternalIdsResquest(externalIds, ItemType.FORM_TEMPLATE)]).then(
    ([{ data: formsResponse }, { data: templatesResponse }]) => {
      const forms = formsResponse?.getItemsByExternalIds?.map((item) => ({ ...item, ...JSON.parse(item?.item || '{}') } as Form))
      const templates = templatesResponse?.getItemsByExternalIds?.map((item) => ({ ...item, ...JSON.parse(item?.item || '{}') } as Form))
      const newItems = assets.map((asset) => {
        const item =
          asset.assetType === AssetType.FORM
            ? forms?.find((item) => item?.externalId === asset?.assetId)
            : templates?.find((item) => item?.externalId === asset?.assetId)
        return { ...asset, name: item?.name || '', assetDeleted: !item?.enabled }
      })
      setState((prevState: UpgradeLogModalState) => ({
        ...prevState,
        items: newItems,
        totalItems,
        loading: false,
      }))
    }
  )
}

const getProgramItems = (
  getItemsByExternalIdsResquest: (externalIds: string[], type: ItemType) => FetchPromise<GetItemsByExternalIdsQuery>,
  externalIds: string[],
  assets: AssetAuditDTO[],
  totalItems: number,
  setState: Function
) => {
  getItemsByExternalIdsResquest(externalIds, ItemType.PROGRAM).then(({ data }) => {
    const programs = data?.getItemsByExternalIds?.map((item) => ({ ...item, ...JSON.parse(item?.item || '{}') }))
    const newItems = assets.map((asset) => {
      const item = programs?.find((item) => item?.externalId === asset?.assetId)
      return { ...asset, name: item?.name || '', assetDeleted: !item?.enabled, status: ensureFirstLetterIsCapitalized(item?.status) }
    })
    setState((prevState: UpgradeLogModalState) => ({
      ...prevState,
      items: newItems,
      totalItems,
      loading: false,
    }))
  })
}

export const getAssetItems = (
  type: UpgradeItemType,
  assets: AssetAuditDTO[],
  totalCount: number,
  getItemsByExternalIdsResquest: (externalIds: string[], type: ItemType) => FetchPromise<GetItemsByExternalIdsQuery>,
  setState: Function
) => {
  const externalIds = assets.map((asset) => asset?.assetId || '')
  const assetItems: { [key in UpgradeItemType]: Function } = {
    [UpgradeItemType.FORMS]: () => getFormItems(getItemsByExternalIdsResquest, externalIds, assets, totalCount, setState),
    [UpgradeItemType.PROGRAMS]: () => getProgramItems(getItemsByExternalIdsResquest, externalIds, assets, totalCount, setState),
    [UpgradeItemType.CONTACTS]: () => undefined,
    [UpgradeItemType.SEGMENTS]: () => undefined,
  }
  assetItems[type]()
}

export const getUpgradeLog = (
  getUpgradeLog: (type: UpgradeItemType, pageIndex: number, pageSize: number) => FetchPromise<GetAssetAuditsQuery>,
  getItemsByExternalIdsResquest: (externalIds: string[], type: ItemType) => FetchPromise<GetItemsByExternalIdsQuery>,
  pageIndex: number,
  pageSize: number,
  type: UpgradeItemType,
  setState: Function
) => {
  setState((prevState: UpgradeLogModalState) => ({ ...prevState, loading: true }))
  getUpgradeLog(type, pageIndex, pageSize)
    .then((response) => {
      const assets = response?.data?.getAssetAudits?.assetAuditDTOS as AssetAuditDTO[]
      const totalCount = response?.data?.getAssetAudits?.totalCount || 0

      if (assets && assets.length > 0) {
        getAssetItems(type, assets, totalCount, getItemsByExternalIdsResquest, setState)
      } else {
        setState((prevState: UpgradeLogModalState) => ({ ...prevState, items: [], loading: false }))
      }
    })
    .catch((e) => {
      logNewRelicError(e)
      setState((prevState: UpgradeLogModalState) => ({ ...prevState, statusToast: errorStatusToast, loading: false }))
    })
}

const renderActionColumn = (logItem: LogItem, onClick: (logItem: LogItem, action: string) => void, t: Function) => {
  return (
    <div className={`${rootClass}__table-column-button`}>
      {logItem.event === EventType.UPGRADE ? (
        <Button buttonType={ButtonType.OUTLINE} onClick={() => onClick(logItem, 'revert')}>
          <Svg name={SvgNames.lastModified} type={SvgType.LARGE_ICON} />
          {t('Revert')}
        </Button>
      ) : (
        <Button buttonType={ButtonType.PRIMARY} onClick={() => onClick(logItem, 'upgrade')}>
          <Svg name={SvgNames.arrowUpPlain} fill={SvgColor.WHITE} type={SvgType.LARGER_ICON} />
          {t('Upgrade')}
        </Button>
      )}
    </div>
  )
}

const resultValues: { [key in EventType]: { icon: SvgNames; text: string } } = {
  [EventType.UPGRADE]: { icon: SvgNames.checkSuccess, text: 'Completed' },
  [EventType.FAILED_UPGRADE]: { icon: SvgNames.errorSolid, text: 'Failed' },
  [EventType.REVERTED_UPGRADE]: { icon: SvgNames.lastModified, text: 'Reverted' },
  [EventType.FAILED_REVERTED_UPGRADE]: { icon: SvgNames.errorSolid, text: 'Failed' },
}

const renderResultColumn = (status: EventType) => {
  const data = resultValues[status]
  return (
    <div className={`${rootClass}__table-column-result`}>
      <Svg name={data.icon} type={SvgType.ICON} />
      <Typography text={data.text} inline />
    </div>
  )
}

export const formsTableColumns = (
  onActionClick: (logItem: LogItem, action: string) => void,
  t: Function
): ColumnDefWithAdditionalProps<LogItem>[] => [
  {
    header: t('Form Name'),
    accessorKey: 'name',
    textAlign: 'left',
    cell: (cell: CellContext<LogItem, any>) => <ColWithTooltip cell={cell} />,
  },
  {
    header: t('Upgrade result'),
    accessorKey: 'event',
    textAlign: 'left',
    maxSize: 230,
    cell: ({ cell }: CellContext<LogItem, any>) => renderResultColumn(cell.row.original.event),
  },
  {
    header: t('Last modified date'),
    accessorKey: 'createdTime',
    textAlign: 'left',
    maxSize: 210,
    cell: ({ cell }: CellContext<LogItem, any>) => format(cell.row.original.createdTime, lastModifiedDateFormat),
  },
  {
    header: t('Modified by'),
    accessorKey: 'userName',
    textAlign: 'left',
    maxSize: 180,
  },
  {
    header: '',
    accessorKey: '',
    textAlign: 'right',
    maxSize: 180,
    id: 'actions',
    cell: ({ cell }: CellContext<LogItem, any>) =>
      cell.row.original.assetDeleted ? t('Form deleted') : renderActionColumn(cell.row.original, onActionClick, t),
  },
]

export const programsTableColumns = (
  onActionClick: (logItem: LogItem, action: string) => void,
  t: Function
): ColumnDefWithAdditionalProps<LogItem>[] => [
  {
    header: t('Program'),
    accessorKey: 'name',
    textAlign: 'left',
    cell: (cell: CellContext<LogItem, any>) => <ColWithTooltip cell={cell} />,
  },
  {
    header: t('Upgrade result'),
    accessorKey: 'event',
    textAlign: 'left',
    maxSize: 200,
    cell: ({ cell }: CellContext<LogItem, any>) => renderResultColumn(cell.row.original.event),
  },
  {
    header: t('Program status'),
    accessorKey: 'status',
    textAlign: 'left',
    maxSize: 180,
  },
  {
    header: t('Last modified date'),
    accessorKey: 'createdTime',
    textAlign: 'left',
    maxSize: 210,
    cell: ({ cell }: CellContext<LogItem, any>) => format(cell.row.original.createdTime, lastModifiedDateFormat),
  },
  {
    header: t('Modified by'),
    accessorKey: 'userName',
    textAlign: 'left',
    maxSize: 180,
  },
  {
    header: '',
    accessorKey: '',
    textAlign: 'right',
    maxSize: 180,
    id: 'actions',
    cell: ({ cell }: CellContext<LogItem, any>) =>
      cell.row.original.assetDeleted ? t('Program deleted') : renderActionColumn(cell.row.original, onActionClick, t),
  },
]

const tableColumns: {
  [key in UpgradeItemType]: (onActionClick: (logItem: LogItem, action: string) => void, t: Function) => ColumnDefWithAdditionalProps<LogItem>[]
} = {
  [UpgradeItemType.FORMS]: formsTableColumns,
  [UpgradeItemType.PROGRAMS]: programsTableColumns,
  [UpgradeItemType.SEGMENTS]: () => [],
  [UpgradeItemType.CONTACTS]: () => [],
}

export const getTableColumns = (type: UpgradeItemType, onActionClick: (logItem: LogItem, action: string) => void, t: Function) =>
  tableColumns[type](onActionClick, t)
