import { gql } from '@apollo/client'

export default gql`
  query getFormSalesForcePushErrors($formId: String!, $pageNumber: Int!, $pageSize: Int, $sortColumn: String, $sortDirection: Direction) {
    getFormSalesForcePushErrors(
      formId: $formId
      pageNumber: $pageNumber
      pageSize: $pageSize
      sortColumn: $sortColumn
      sortDirection: $sortDirection
    ) {
      pushErrors {
        timestamp
        errorType
        email
        status
        description
      }
      page
      totalPages
      perPage
      totalRecords
      sortDirection
      sortColumn
    }
  }
`
