import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import './InfoHoverHeader.css'

interface Props {
  className?: string
  dataTest?: string
  title: ReactNode
  subtitle?: ReactNode
}

const rootClass = 'info-hover-header'

const InfoHoverHeader: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '', title, subtitle } = props
  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__title`}>{title}</div>
      {subtitle && <div className={`${rootClass}__subtitle`}>{subtitle}</div>}
    </div>
  )
}

export default InfoHoverHeader
