import React, { FC } from 'react'

import AssetPickerModal, { AssetPickerModalBaseProps, AssetPickerModalProps } from '@complex/AssetPickerModal/AssetPickerModal'
import { AssetPickerListingPageProps, AssetPickerSidebarProps, AssetPickerTableProps } from '@complex/AssetPickerModal/Context/AssetPicker.context'
import { ListPageCommonState, RenderSearchColumnsV2, Update } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { useTranslation } from '@const/globals'
import { ItemType } from '@utils/categorization'
import { allLandingPages } from '@utils/filter'
import { getLandingPagePreviewUrl, landingPageSubTypes } from '@utils/landingPages'

import { renderSearchTableColumns, renderTableColumns } from './LandingPagesPickerModal.tables'
import { renderCustomFilters } from './utils/LandingPagesPickerModal.helpers.helpers'

const rootClass = 'landing-pages-picker-modal'

export const createTemplateFromLandingPageModalProps: AssetPickerModalBaseProps = {
  isOpen: true,
  isSingleSelect: true,
  titleText: 'ListPage.LandingPageTemplates.LandingPagePicker.Title',
  primaryButtonText: 'ListPage.LandingPageTemplates.LandingPagePicker.PrimaryButton',
  secondaryButtonText: 'ListPage.LandingPageTemplates.LandingPagePicker.SecondaryButton',
  viewSelectedLabel: 'ListPage.LandingPageTemplates.LandingPagePicker.ViewSelectedLabel',
  disabledListTooltipText: 'ListPage.LandingPageTemplates.LandingPagePicker.DisabledRowTooltipText',
}

const LandingPagesPickerModal: FC<AssetPickerModalBaseProps> = (props) => {
  const { t } = useTranslation()

  const customPreviewItemCall = async (listPageValues: ListPageCommonState, update: Update) => {
    const { selectedRows } = listPageValues
    update({ previewUrl: getLandingPagePreviewUrl(selectedRows[0].externalId ?? '') })
  }

  const renderSearchColumns: RenderSearchColumnsV2 = (
    _searchInAllItems: boolean | undefined,
    _currentFolder: FolderData,
    search: string,
    folders: FolderData[]
  ) => renderSearchTableColumns(search, folders, t)

  const tableProps: AssetPickerTableProps = {
    columns: renderTableColumns(t),
    renderSearchColumns,
    listPage: 'LandingPages',
    sortingBy: [{ id: 'lastUpdated', desc: true }],
  }

  const sidebarProps: AssetPickerSidebarProps = {
    hasRecent: false,
    hasCreatedByMe: true,
    allItemFilter: allLandingPages,
    renderCustomFilters: (params) => renderCustomFilters(params),
  }

  const listingPageProps: AssetPickerListingPageProps = {
    sidebarProps,
    subTypes: landingPageSubTypes,
    customPreviewItemCall,
  }

  const assetPickerProps: AssetPickerModalProps = {
    className: rootClass,
    listingPageProps,
    tableProps,
    hasTags: true,
    hasCustomFilters: true,
    hasSidebar: true,
    hasPreview: true,
    itemType: ItemType.LANDING_PAGE,
    ...props,
  }

  return <AssetPickerModal {...assetPickerProps} />
}

export default LandingPagesPickerModal
