import React, { FC, useCallback, useState } from 'react'
import { useHistory, useParams } from 'react-router'

import classNames from 'classnames'

import Caution from '@components/Caution/Caution'
import Container from '@components/Container'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import SectionHeadline from '@components/SectionHeadline'
import { TableV2 } from '@components/TableV2/TableV2'
import Typography, { TextType } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import getEmbeddedFormsTableProps from '@src/pages/Content/LandingPages/LandingPagesManager/components/LandingPageManagerDetails/embeddedForms.tableProps'
import LandingPageManageURLs from '@src/pages/Content/LandingPages/LandingPagesManager/components/LandingPageManageURLs/LandingPageManageURLs'
import LandingPageProperties from '@src/pages/Content/LandingPages/LandingPagesManager/components/LandingPageProperties/LandingPageProperties'
import LandingPageSettings from '@src/pages/Content/LandingPages/LandingPagesManager/components/LandingPageSettings/LandingPageSettings'
import { useLandingPageContext } from '@src/pages/Content/LandingPages/LandingPagesManager/context/LandingPagesManager.context'
import { LandingPageManagerSubTab, LandingPageManagerTab } from '@src/pages/Content/LandingPages/LandingPagesManager/LandingPageManager.utils'
import { useAccountSettings } from '@utils/account/account.utils'

import './LandingPageManagerDetails.css'

interface LandingPageManagerDetailsProps {
  className?: string
  dataTest?: string
}

interface RouteParams {
  id: string
  subTab?: string
}

const rootClass = 'landing-page-manager-details'

const baseUrl = `${rootContext}/content/landingPages`

const LandingPageManagerDetails: FC<LandingPageManagerDetailsProps> = ({ dataTest = rootClass, className = '' }) => {
  const { t } = useTranslation()
  const {
    landingPage: { embeddedForms, publishInfo: { isLandingPagePublished, hasUnpublishedChanges } = {} },
  } = useLandingPageContext()

  const { newLPComposerCreateBlank } = useAccountSettings()

  const history = useHistory()
  const { id, subTab } = useParams<RouteParams>()

  const [isManageUrlsOpen, setIsManageUrlsOpen] = useState<boolean>(subTab === LandingPageManagerSubTab.MANAGE_URLS)

  const handleManageURLs = useCallback(() => {
    setIsManageUrlsOpen(true)
    history.push(`${baseUrl}/${id}/${LandingPageManagerTab.DETAILS}/${LandingPageManagerSubTab.MANAGE_URLS}`)
  }, [history, id])

  const handleBack = useCallback(() => {
    setIsManageUrlsOpen(false)
    history.push(`${baseUrl}/${id}/${LandingPageManagerTab.DETAILS}`)
  }, [history, id])

  return (
    <PositionContainer innerDivClassname={`${rootClass}__wrapper`}>
      {isManageUrlsOpen ? (
        <LandingPageManageURLs onBack={handleBack} />
      ) : (
        <div className={classNames(rootClass, className)} data-test={dataTest}>
          <div className={`${rootClass}__container-left`}>
            {newLPComposerCreateBlank && isLandingPagePublished && hasUnpublishedChanges && (
              <Caution className={`${rootClass}__caution`} message={t('LandingPage.caution')} />
            )}
            <LandingPageSettings manageUrls={handleManageURLs} />
            {!!embeddedForms.length && (
              <Container className={`${rootClass}__embedded-forms`} dataTest={`${dataTest}-embedded-forms`}>
                <div className={`${rootClass}__embedded-forms-header`}>
                  <SectionHeadline className={`${rootClass}__headline`}>{t('Embedded Forms')}</SectionHeadline>
                  <Typography text={t('Embedded.Forms.Sub.Header')} type={TextType.BODY_TEXT_LIGHT} />
                </div>
                <TableV2 data={embeddedForms} {...getEmbeddedFormsTableProps()} />
              </Container>
            )}
          </div>
          <div className={`${rootClass}__container-right`}>
            <LandingPageProperties />
          </div>
        </div>
      )}
    </PositionContainer>
  )
}

export default LandingPageManagerDetails
