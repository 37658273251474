import React, { FC } from 'react'

import classNames from 'classnames'

import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './AutoSavingStatus.css'

export enum SavingMessage {
  NONE = 'none',
  SAVING = 'saving',
  SAVED = 'saved',
}

type Props = {
  savingStatus: SavingMessage
  className?: string
  dataTest?: string
}

const rootClass = 'auto-saving-status'

const AutoSavingStatus: FC<Props> = (props: Props) => {
  const { savingStatus, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()

  const renderInterior = () => {
    if (savingStatus === SavingMessage.NONE) {
      return null
    } else if (savingStatus === SavingMessage.SAVED) {
      return (
        <>
          <Svg name={SvgNames.check} type={SvgType.SMALLER_ICON} />
          <Typography text={t('Changes saved')} type={TextType.BODY_TEXT_LIGHT} inline />
        </>
      )
    } else {
      return (
        <>
          <Loader loaderType={LoaderTypes.row} />
          <Typography text={t('Saving changes')} type={TextType.BODY_TEXT_LIGHT} inline />
        </>
      )
    }
  }

  return (
    <div
      className={classNames(rootClass, className, {
        [`${rootClass}__saved`]: savingStatus === SavingMessage.SAVED,
      })}
      data-test={dataTest}
    >
      {renderInterior()}
    </div>
  )
}

export default AutoSavingStatus
