import React, { FC, MouseEventHandler, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import CaretIcon, { CaretIconDirection } from '@components/CaretIcon'
import Input from '@components/Input'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { INPUT_DEBOUNCE_TIME, useTranslation } from '@const/globals'
import {
  Program,
  ProgramAppendToSegmentStep,
  ProgramCopyFromStep,
  ProgramCopyStep,
  ProgramFieldSetStep,
  ProgramStep,
} from '@graphql/types/query-types'
import ChangeFieldStepEditor from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/components/ListProgramStep/components/ChangeFieldStepEditor/ChangeFieldStepEditor'
import { ChangeFieldRules } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/components/ListProgramStep/components/ChangeFieldStepEditor/utils/ChangeFieldStepEditor.constants'
import CopyFromSegmentDetails from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/components/ListProgramStep/components/CopyFromSegmentDetails/CopyFromSegmentDetails'
import CopyFromSegmentStepEditor from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/components/ListProgramStep/components/CopyFromSegmentStepEditor/CopyFromSegmentStepEditor'
import CopyToListStepEditor from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/components/ListProgramStep/components/CopyToListStepEditor/CopyToListStepEditor'
import StepTypeSelector from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/components/ListProgramStep/components/StepTypeSelector/StepTypeSelector'
import {
  deletedFieldError,
  getStepErrors,
  getStepExpandedState,
  setStepExpandedState,
  StepErrors,
} from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/components/ListProgramStep/utils/ListProgramStep.utils'
import {
  EDIT_STEP_EVENT,
  EXPAND_ALL_STEPS_EVENT,
  EXPAND_STEP_EVENT,
  SAVE_PROGRAM_EVENT,
} from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/utils/ProgramStepsListing.constants'
import { ListMaintenanceProgramsContext } from '@src/pages/listingPages/ListMaintenancePrograms/context/ListMaintenancePrograms.context'
import ChangeFieldStepDetail from '@src/pages/programs/dashboard/components/ProgramSteps/components/ChangeFieldStepDetail/ChangeFieldStepDetail'
import {
  CFSConditionType,
  FieldSetOptions,
} from '@src/pages/programs/dashboard/components/ProgramSteps/components/ChangeFieldStepDetail/ChangeFieldStepDetail.constants'
import CopyToListStep from '@src/pages/programs/dashboard/components/ProgramSteps/components/CopyToListStep/CopyToListStep'
import ProgramStepIcon from '@src/pages/programs/edit/components/ProgramFlow/components/ProgramStepIcon'
import { ProgramStepType } from '@utils/program/program.constants'

import './ListProgramStep.css'

interface ListProgramStepProps {
  className?: string
  dataTest?: string
  position: number
  step: ProgramStep
  register?: (ref: HTMLDivElement | null) => void
}

const rootClass = 'list-program-step'
const CARET_ICON_ID = `${rootClass}-expander`

const ListProgramStep: FC<ListProgramStepProps> = (props: ListProgramStepProps) => {
  const { dataTest = rootClass, className = '', step, position = 0, register } = props

  const {
    update,
    values: { program, programDetails, campaigns, scoreSheets, programSourceFields, stepBeingEditedIndex, programStatus, hasMarketingSource = false },
  } = useContext(ListMaintenanceProgramsContext)

  const [isExpanded, setIsExpanded] = useState(getStepExpandedState(step.stepId, program?.id))
  const [hasDisplayName, setHasDisplayName] = useState(true)
  const [showNameError, setShowNameError] = useState(false)

  const { sourceList = [], steps = [] } = { ...programDetails }

  const { t } = useTranslation()

  const stepNamePlaceholder = useMemo(() => (!step.stepId?.includes('newStep') ? step.displayName : t('New step name')), [step.stepId])
  const isEditing = stepBeingEditedIndex === position || !step.stepType

  const stepErrors = useMemo<StepErrors>(() => getStepErrors(step, programStatus?.stepErrors ?? []), [step, programStatus, position])

  const inputError = !hasDisplayName && showNameError

  const onStepEdit = (newStepData: Partial<ProgramStep>) => {
    update({
      programDetails: {
        ...(programDetails as Program),
        steps: [...steps.slice(0, position), { ...steps[position], ...newStepData }, ...steps.slice(position + 1)],
      },
    })
  }

  const expandStep = (expand: boolean, triggerEvent = true) => {
    if (step.stepId) {
      setStepExpandedState(step.stepId, expand, program?.id)
    }
    setIsExpanded(expand)
    if (triggerEvent) {
      const event = new CustomEvent(EXPAND_STEP_EVENT, { detail: { stepId: step.stepId, expanded: expand } })
      document.dispatchEvent(event)
    }
  }

  const onExpandAllSteps = (event: Event) => {
    const {
      detail: { expand },
    } = event as CustomEvent
    expandStep(expand, false)
  }

  const onSaveProgram = () => {
    setShowNameError(true)
  }

  const onEditStepEventHandler = (event: Event) => {
    const {
      detail: { stepPosition },
    } = event as CustomEvent
    if (stepPosition === position && !isExpanded) {
      expandStep(true)
    }
  }

  useEffect(() => {
    document.addEventListener(EXPAND_ALL_STEPS_EVENT, onExpandAllSteps)
    document.addEventListener(SAVE_PROGRAM_EVENT, onSaveProgram)
    return () => {
      document.removeEventListener(EXPAND_ALL_STEPS_EVENT, onExpandAllSteps)
      document.removeEventListener(SAVE_PROGRAM_EVENT, onSaveProgram)
    }
  }, [])

  useEffect(() => {
    document.addEventListener(EDIT_STEP_EVENT, onEditStepEventHandler)
    return () => document.removeEventListener(EDIT_STEP_EVENT, onEditStepEventHandler)
  }, [isExpanded, position])

  const onInputBlur = () => {
    const hasDisplayName = !!step.displayName
    setHasDisplayName(hasDisplayName)
    if (hasDisplayName) {
      setShowNameError(false)
    }
  }

  const onExpandClick: MouseEventHandler<HTMLDivElement> = useCallback(
    ({ currentTarget: { id } }) => {
      const isCaretOnClick = id === CARET_ICON_ID
      if ((isCaretOnClick && isEditing && isExpanded) || (!isCaretOnClick && (!isEditing || !isExpanded))) {
        expandStep(!isExpanded)
      }
    },
    [isExpanded, isEditing]
  )

  useEffect(() => {
    if (step.stepType) {
      setHasDisplayName(!!step.displayName)
    }
  }, [step])

  if (!program) {
    return null
  }

  const renderStepEditor = () => {
    const types: { [key: string]: JSX.Element } = {
      [ProgramStepType.FIELD_SET]: <ChangeFieldStepEditor step={step} onChange={onStepEdit} stepErrors={stepErrors} position={position} />,
      [ProgramStepType.COPY]: <CopyToListStepEditor step={step} onChange={onStepEdit} stepErrors={stepErrors} />,
      [ProgramStepType.COPY_FROM]: <CopyFromSegmentStepEditor step={step} onChange={onStepEdit} stepErrors={stepErrors} />,
      [ProgramStepType.APPEND_TO_SEGMENT]: <CopyToListStepEditor step={step} onChange={onStepEdit} stepErrors={stepErrors} />,
    }

    return (
      <div data-test={`${dataTest}-edit-container`} className={`${rootClass}__edit-container`}>
        {step.stepType ? (
          types[step.stepType]
        ) : (
          <StepTypeSelector
            onClick={onStepEdit}
            isMarketingList={hasMarketingSource}
            defaultFieldName={programSourceFields.length ? programSourceFields[0].field : ''}
            programSource={sourceList.length > 0 ? sourceList[0] : undefined}
          />
        )}
      </div>
    )
  }

  const renderStepDetails = () => {
    const getChangeFieldStepValue = ({ rule, value, scoreSheetId, campaignId }: ProgramFieldSetStep) => {
      if (rule === ChangeFieldRules.DECREMENT_BY || rule === ChangeFieldRules.INCREMENT_BY || rule === ChangeFieldRules.SET_VALUE) {
        return value
      }
      if (rule === ChangeFieldRules.SET_BEHAVIORAL_SCORE) {
        return scoreSheets.find(({ id }) => id === scoreSheetId)?.name
      }
      if (rule === ChangeFieldRules.SET_CAMPAIGN_SCORE) {
        return campaigns.find(({ id }) => id === campaignId)?.name
      }
    }

    const types: { [key: string]: (step: ProgramStep) => JSX.Element } = {
      [ProgramStepType.FIELD_SET]: ({ forceUseOfSourceList, ...step }: ProgramFieldSetStep) => {
        const operation = {
          fieldName: step.fieldName?.replace('.deleted_', '') ?? '',
          rule: step.rule as FieldSetOptions,
          value: getChangeFieldStepValue(step) ?? '',
          error: stepErrors.deletedField ? `${t(deletedFieldError)}. ${t('Please select another one')}` : false,
        }
        const condition = {
          condition: t(`If contact is ${step.not ? 'not ' : ' '}in`),
          type: CFSConditionType.LIST,
          name: step.srcName ?? '',
          msgId: '',
          operations: [operation],
        }
        return (
          <ChangeFieldStepDetail
            programId={program.externalId}
            listName={(forceUseOfSourceList || !hasMarketingSource ? sourceList[0]?.name : step.listName) ?? ''}
            operations={step.srcName ? [] : [operation]}
            conditions={step.srcName ? [condition] : []}
          />
        )
      },
      [ProgramStepType.COPY_FROM]: (step: ProgramCopyFromStep) => <CopyFromSegmentDetails step={step} />,
      [ProgramStepType.COPY]: ({ listName = '', listId = '', update = false }: ProgramCopyStep) => (
        <CopyToListStep listName={listName} listId={listId} update={update} isCopy />
      ),
      [ProgramStepType.APPEND_TO_SEGMENT]: ({ listName = '', listId = '' }: ProgramAppendToSegmentStep) => (
        <CopyToListStep listName={listName} listId={listId} update={false} isCopy={false} />
      ),
    }
    const stepDetails = types[step.stepType]

    return (
      <div data-test={`${dataTest}-details-container`} className={`${rootClass}__step-details`}>
        {stepDetails && stepDetails(step)}
      </div>
    )
  }

  return (
    <div
      className={classNames(rootClass, className, { [`${rootClass}--is-editing`]: isEditing })}
      data-test={dataTest}
      ref={(ref) => register && register(ref)}
    >
      <div className={`${rootClass}__row`}>
        <ProgramStepIcon
          className={`${rootClass}__step-icon`}
          stepType={(step.stepType as ProgramStepType) ?? ProgramStepType.FIELD_SET}
          aOContactsSource={!(hasMarketingSource && step.stepType === ProgramStepType.COPY)}
        />
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div role={'button'} tabIndex={0} className={classNames(`${rootClass}__row-header`, `${rootClass}__centered`)} onClick={onExpandClick}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <div
            role={'button'}
            tabIndex={0}
            data-test={`${dataTest}-row-caret`}
            className={`${rootClass}__row-header-caret`}
            id={CARET_ICON_ID}
            onClick={onExpandClick}
          >
            <CaretIcon toggle={isExpanded} direction={CaretIconDirection.RIGHT} />
          </div>
          {isEditing && isExpanded ? (
            <Input
              dataTest={`${dataTest}-edit-input`}
              className={`${rootClass}__edit-input`}
              value={step.displayName}
              placeholder={stepNamePlaceholder}
              onChange={({ target: { value } }) => onStepEdit({ displayName: value })}
              onChangeDebounce={INPUT_DEBOUNCE_TIME}
              /* eslint-disable-next-line jsx-a11y/no-autofocus */
              autoFocus={!step.displayName}
              error={inputError}
              onBlur={onInputBlur}
            />
          ) : (
            <>
              {(!stepErrors.isValid || !hasDisplayName) && (
                <Svg className={`${rootClass}__row-header-error-icon`} name={SvgNames.warningSolid} type={SvgType.ICON} />
              )}
              <Typography
                className={classNames(`${rootClass}__row-header-step-name`, 'ellip')}
                dataTest={`${dataTest}-step-description`}
                text={step.displayName || `(${t('name missing')})`}
                weight={step.displayName ? TextWeight.MEDIUM : TextWeight.ITALIC}
              />
            </>
          )}
        </div>
        {(inputError || (isEditing && isExpanded)) && (
          <Typography
            className={classNames(`${rootClass}__edit-input-error`, { [`${rootClass}__edit-input-error-visible`]: inputError })}
            type={TextType.ERROR}
            text={t('Please enter a name')}
          />
        )}
      </div>
      {isExpanded && (isEditing || !step.stepType ? renderStepEditor() : renderStepDetails())}
    </div>
  )
}

export default ListProgramStep
