import React, { FC, useCallback, useEffect, useState } from 'react'

import classNames from 'classnames'

import ButtonIcon, { ButtonIconSize, ButtonIconType } from '@components/ButtonIcon/ButtonIcon'
import InputWithStatus from '@components/InputWithStatus/InputWithStatus'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'
import { ClickthroughLink, ClickthroughLinkInput, ClickthroughLinkTestResponse } from '@graphql/types/query-types'
import { TestLinkCBs } from '@src/pages/Content/LandingPages/LandingPagesManager/components/LandingPageManagerClickthroughLinks/ClickthroughLinks'
import { useLandingPageContext } from '@src/pages/Content/LandingPages/LandingPagesManager/context/LandingPagesManager.context'

import './ClickthroughLinkBlock.css'

interface ClickthroughLinkBlockProps extends ClickthroughLink {
  onTestLink: (link: ClickthroughLinkInput) => Promise<ClickthroughLinkTestResponse>
  testLinksCbRef: TestLinkCBs
}

const rootClass = 'clickthrough-link-block'

const ClickthroughLinkBlock: FC<ClickthroughLinkBlockProps> = ({ code, name, link, onTestLink, testLinksCbRef }) => {
  const { t } = useTranslation()
  const { onClickthroughLinkChange, dirtyClickthroughLinks } = useLandingPageContext()
  const [_name, setName] = useState<string>(name ?? '')
  const [_link, setLink] = useState<string>(link ?? '')
  const [lastTestedLink, setLastTestedLink] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState<boolean | undefined>(undefined)
  const [hasWarning, setHasWarning] = useState<boolean>(false)

  useEffect(() => {
    if (!dirtyClickthroughLinks) {
      setName(name ?? '')
    }
  }, [name, dirtyClickthroughLinks])

  useEffect(() => {
    if (!dirtyClickthroughLinks) {
      setLink(link ?? '')
    }
  }, [link, dirtyClickthroughLinks])

  const handleTest = useCallback(() => {
    setLoading(true)
    return onTestLink({ code, link: _link })
      .then((resp) => {
        const { completed, hasWarning } = { ...resp }
        setLastTestedLink(_link)
        setCompleted(completed)
        setHasWarning(hasWarning)
      })
      .finally(() => setLoading(false))
  }, [onTestLink, _link, code])

  useEffect(() => {
    testLinksCbRef[code] = handleTest
  }, [code, handleTest, testLinksCbRef])

  const handleVisit = useCallback(() => window.open(_link, '_blank', 'popup=true,width=800,height=500'), [_link])

  const handleLinkChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const link = e.target.value
      setLink(link)
      onClickthroughLinkChange({ code, link })
    },
    [onClickthroughLinkChange, code]
  )

  const handleNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.target.value
      setName(name)
      onClickthroughLinkChange({ code, name })
    },
    [onClickthroughLinkChange, code]
  )

  const isLastTestedLink = lastTestedLink === _link

  const dataTest = `${rootClass}-${code}`
  return (
    <div className={classNames(rootClass, 'flex-align-center')} data-test={dataTest}>
      <InputWithStatus value={_name} onChange={handleNameChange} showIconOnValid={false} disabled={loading} dataTest={`${dataTest}-input-name`} />
      <Svg name={SvgNames.arrowRight} type={SvgType.LARGER_ICON} fill={SvgColor.LIGHT_GRAY} />
      <InputWithStatus
        value={_link}
        onChange={handleLinkChange}
        icon={hasWarning ? SvgNames.warning : undefined}
        showIconOnValid={isLastTestedLink && completed}
        hasCustomError={isLastTestedLink && completed === false && !hasWarning}
        customTooltipErrorMessages={'Not a valid link'}
        dataTest={`${dataTest}-input-link`}
        disabled={loading}
      />
      <div className={classNames('flex-align-center', `${rootClass}__actions`)}>
        <Tooltip
          trigger={
            <ButtonIcon
              type={ButtonIconType.TERTIARY}
              icon={SvgNames.externalLink}
              size={ButtonIconSize.LARGE}
              onClick={handleVisit}
              disabled={loading}
              dataTest={`${dataTest}-link-svg`}
            />
          }
        >
          {t('Visit link')}
        </Tooltip>
        <Tooltip
          trigger={
            <ButtonIcon
              onClick={handleTest}
              type={ButtonIconType.TERTIARY}
              icon={SvgNames.test}
              size={ButtonIconSize.LARGE}
              disabled={loading}
              dataTest={`${dataTest}-test-svg`}
            />
          }
        >
          {t('Test link')}
        </Tooltip>
      </div>
    </div>
  )
}

export default ClickthroughLinkBlock
