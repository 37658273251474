import { gql } from '@apollo/client'

export default gql`
  mutation createLandingPageFromEmailTemplate($id: String, $newName: String, $tags: [LabelDto], $folderId: Int) {
    createLandingPageFromEmailTemplate(id: $id, newName: $newName, tags: $tags, folderId: $folderId) {
      newPageId
      status
    }
  }
`
