import { FC, useContext } from 'react'

import { ListPickerModalContext } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'

interface InfoHoverCardWrapperProps {
  children: (loading: boolean) => JSX.Element
}

const InfoHoverCardWrapper: FC<InfoHoverCardWrapperProps> = (props: InfoHoverCardWrapperProps) => {
  const { children } = props
  const {
    values: { infoHoverLoading },
  } = useContext(ListPickerModalContext)
  return children(infoHoverLoading)
}

export default InfoHoverCardWrapper
