import React, { FC, useContext, useState } from 'react'
import { FormProvider, useController, useFieldArray, useForm, useWatch } from 'react-hook-form'

import classNames from 'classnames'

import { PersonalizationContainer, PersonalizationContainerProps } from '@complex/Personalization/PersonalizationContainer'
import { getRecipientSrcIds } from '@complex/Personalization/utils/Personalization.utils'
import Button, { ButtonType } from '@components/Button'
import { YesNo } from '@components/ConfirmationModal'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import InlineEditing, { INLINE_EDITING_TYPE } from '@components/InlineEditing/InlineEditing'
import { LabelType } from '@components/LabelV2/LabelV2'
import { LinkTextButton } from '@components/LinkTextButton/LinkTextButton'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import StaticImage from '@components/StaticImage/StaticImage'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import Svg, { SvgNames } from '@components/Svg'
import TextArea, { TextAreaResizeDirection } from '@components/TextArea/TextArea'
import Typography, { ModalBodyStyle, ModalHeaderStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { yupResolver } from '@hookform/resolvers/yup'
import { MAX_SUMMARY_CHARACTERS } from '@src/pages/Content/Email/Editor/components/GenerativeEmailModal/GenerativeEmailModal.constants'
import { detectEmailType, detectWebinarType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { formatNumber } from '@utils/numbers'

import DisplayConditionsBuilder from './components/DisplayConditions/components/DisplayConditionsBuilder'
import { DisplayConditionsForm, displayConditionsFormSchema } from './components/DisplayConditions/DisplayConditions.types'
import { processDisplayConditionForm, processDisplayConditionRow } from './DynamicContent.conversion'

import './DynamicContent.css'

import { IPluginDisplayConditionExtended } from '@utils/composer/beeEditor/beeEditorTypes'

interface DynamicContentProps {
  numberOfExistingConditions: number
  className?: string
  dataTest?: string
  displayConditions?: IPluginDisplayConditionExtended
  onSubmit: (displayConditions: IPluginDisplayConditionExtended) => void
  onCancel: () => void
  isStory?: boolean
}

const CONNECT_LINK = 'https://connect.act-on.com/hc/en-us/articles/23019780205719-Dynamic-Content-new-Email-Composer-Beta'

const rootClass = 'dynamic-content'

const DynamicContent: FC<DynamicContentProps> = (props: DynamicContentProps) => {
  const { onSubmit, displayConditions, onCancel, dataTest = rootClass, numberOfExistingConditions, className = '', isStory = false } = props
  const [showRemoveAllConfirmation, setShowRemoveAllConfirmation] = useState(false)
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false)
  const [personalizationProps, setPersonalizationProps] = useState<PersonalizationContainerProps['tagInputProps']>(undefined)
  const [headerBoxShadow, setHeaderBoxShadow] = useState<boolean>(false)

  const {
    values: {
      message: { sendto_contacts, sendto_lists, individualRecipientsSourceIds, webinarConfig },
      messageConfiguration: { messageType },
    },
  } = useContext(EmailComposerContext)

  const { t } = useTranslation()

  const form = useForm<DisplayConditionsForm>({
    mode: 'onChange',
    resolver: yupResolver(displayConditionsFormSchema(t)),
    defaultValues: { ...processDisplayConditionRow(numberOfExistingConditions, displayConditions) },
  })
  const headerController = useController({ control: form.control, name: 'header' })
  const descriptionController = useController({ control: form.control, name: 'description' })
  const groupController = useFieldArray({ control: form.control, name: `groups` })
  useWatch({ control: form.control, name: 'groups' })

  const isZeroGroupsInitially = displayConditions?.condition?.length === 0
  const { isEmailBlankMessage, isEmailTemplate, isEmailABTest, isEmailForm, isEmailProgram } = detectEmailType(messageType)
  const { isAccepted, isPending, isRejected, isDeclined } = detectWebinarType(webinarConfig)

  const handleSubmit = (data: DisplayConditionsForm) => {
    onSubmit(processDisplayConditionForm(data))
  }

  const renderLink = () => (
    <LinkTextButton
      hideIconLeft
      text={t('EmailComposer.DynamicContent.Empty.BodyLink')}
      iconNameRight={SvgNames.externalLinkSelected}
      link={CONNECT_LINK}
      className={`${rootClass}__connect-link`}
    />
  )

  const onRemoveAllConfirm = (answer: YesNo) => {
    if (answer === YesNo.YES) {
      form.reset({ ...form.getValues(), groups: [] })
    }
    setShowRemoveAllConfirmation(false)
  }

  const onCancelConfirm = (answer: YesNo) => {
    if (answer === YesNo.YES) {
      onCancel()
    }
    setShowCancelConfirmation(false)
  }

  const confirmTypography = { ...ModalBodyStyle, inline: true, tagProps: { bold: { weight: TextWeight.MEDIUM } } }

  const renderRemoveModal = () => (
    <DeleteConfirmationModal
      title={t('EmailComposer.DynamicContent.Confirm.RemoveAll.Header')}
      body={
        <>
          <Typography text={t('EmailComposer.DynamicContent.Confirm.RemoveAll.Body.1')} {...confirmTypography} inline={false} />
          <br />
          <Typography text={t('EmailComposer.DynamicContent.Confirm.RemoveAll.Body.2')} {...confirmTypography} />
        </>
      }
      deleteButtonText={'Confirm removal'}
      onAnswer={onRemoveAllConfirm}
      isOpen
    />
  )

  const renderCancelModal = () => (
    <DeleteConfirmationModal
      title={'Are you sure?'}
      body={<Typography text={'If you cancel now, you’ll lose all unsaved changes. You cannot undo this action.'} {...confirmTypography} />}
      cancelButtonText={'Continue editing'}
      deleteButtonText={'Yes, cancel'}
      onAnswer={onCancelConfirm}
      isOpen
    />
  )

  const disableSegmentFields = isEmailABTest || isEmailForm || isEmailProgram || isAccepted || isPending || isRejected || isDeclined

  const renderPersonalizationModal = () => (
    <PersonalizationContainer
      isOpen
      title={'Add a condition'}
      closePersonalization={() => {
        // Remove the last group if it's empty
        const groups = form.getValues().groups
        const groupLen = groups.length
        const lastEl = groups[groupLen - 1]
        const hasEmptyLastEl = lastEl?.fields[0]?.values.length === 0
        if (hasEmptyLastEl) {
          groups.pop()
          form.setValue('groups', groups)
        } else {
          const lastFieldPos = lastEl?.fields.length - 1
          const lastField = lastEl?.fields[lastFieldPos]
          if (lastField?.personalization === undefined) {
            groups[groupLen - 1].fields = lastEl.fields.slice(0, -1)
            form.setValue('groups', groups)
          }
        }
        setPersonalizationProps(undefined)
      }}
      tagInputProps={personalizationProps}
      disableAddRecipientsButton={!!sendto_contacts?.length}
      specificIds={getRecipientSrcIds(individualRecipientsSourceIds, sendto_lists)}
      skipFallbackText
      hideAccountPersonalizations
      disableListOrSegmentFields={disableSegmentFields}
      disableListOrSegmentFieldsForTemplate={isEmailBlankMessage || isEmailTemplate}
    />
  )

  const hasGroups = !!groupController.fields.length
  const showingSubModal = showRemoveAllConfirmation || showCancelConfirmation || personalizationProps

  const header = (
    <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__header`}>
      <Typography text={t('EmailComposer.DynamicContent.Header')} {...ModalHeaderStyle} />
    </ModalHeader>
  )

  return (
    <Modal className={classNames(rootClass, className, { [`${rootClass}--hidden`]: showingSubModal })} data-test={dataTest} isOpen header={header}>
      {showRemoveAllConfirmation && renderRemoveModal()}
      {showCancelConfirmation && renderCancelModal()}
      {personalizationProps && renderPersonalizationModal()}
      <ModalBody onScroll={(ref) => setHeaderBoxShadow(!!(ref?.current?.scrollTop && ref?.current?.scrollTop > 0))}>
        <div className={classNames(`${rootClass}__body-header`, { [`${rootClass}__body-header-shadow`]: headerBoxShadow })}>
          <InlineEditing
            title={headerController.field.value}
            onChange={() => null}
            onCancel={() => null}
            onSave={(value) => headerController.field.onChange(value)}
            highlightOnFocus
            className={`${rootClass}__inline-editor`}
            maxWidth={1000}
            minWidth={310}
            maxLength={100}
            dataTest={dataTest}
            type={INLINE_EDITING_TYPE.FLEX}
          />
        </div>
        <div className={`${rootClass}__body-content`}>
          <div className={`${rootClass}__sub-header`}>
            <Typography
              text={hasGroups ? t('EmailComposer.DynamicContent.SubText') : t(`EmailComposer.DynamicContent.Empty.SubText`)}
              type={TextType.NORMAL_TEXT_GRAY_LARGE}
              className={classNames(`${rootClass}__subtext`, { [`${rootClass}__subtext--empty`]: !hasGroups })}
            />
            {hasGroups && renderLink()}
          </div>
          <TextArea
            label={t('Description')}
            labelType={LabelType.medium}
            placeholder={t('Add a few details here')}
            disableNativeMaxLength
            maxCharacterProps={{ maxLength: 600 }}
            defaultValue={descriptionController.field.value}
            name={descriptionController.field.name}
            register={descriptionController.field.ref}
            onChange={descriptionController.field.onChange}
            className={classNames(`${rootClass}__description`, { [`${rootClass}__description-errors`]: form.formState.errors.description })}
            resizeDirection={TextAreaResizeDirection.VERTICAL}
          />
          <div className={classNames(`${rootClass}__error-message`, { [`${rootClass}__error-message-text`]: form.formState.errors.description })}>
            {form.formState.errors.description && (
              <Typography
                text={t('EmailComposer.DynamicContent.Error.Description', {
                  maxChars: MAX_SUMMARY_CHARACTERS,
                  overage: formatNumber(descriptionController.field.value.length - MAX_SUMMARY_CHARACTERS),
                })}
                tagProps={{ medium: { weight: TextWeight.MEDIUM, inline: true } }}
                type={TextType.ERROR}
              />
            )}
          </div>
          {!hasGroups && (
            <div className={`${rootClass}__empty-body-text-description`}>
              <StaticImage
                className={`${rootClass}__empty-body-text-description__image`}
                dataTest={`${dataTest}__static__image`}
                name={StaticImageNames.emptySegmentIllustrationV2}
                altText={rootClass}
                isStory={isStory}
              />
              <div className={`${rootClass}__body-description-text`}>
                <Typography text={t('EmailComposer.DynamicContent.Empty.Header')} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
                <Typography
                  text={t('EmailComposer.DynamicContent.Empty.BodyDescription')}
                  className={`${rootClass}__body-description__middle-text`}
                />
                {renderLink()}
              </div>
            </div>
          )}
          <FormProvider {...form}>
            <DisplayConditionsBuilder groupController={groupController} togglePersonalizations={(props) => setPersonalizationProps(props)} />
          </FormProvider>
        </div>
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form} className={`${rootClass}__footer`}>
        <div className={`${rootClass}__footer__inner`}>
          <div className={`${rootClass}__footer__section`}>
            {hasGroups && (
              <Button
                buttonType={ButtonType.DELETE_OUTLINE}
                onClick={() => setShowRemoveAllConfirmation(true)}
                dataTest={`${dataTest}-remove-all`}
                className={`${rootClass}__remove-all`}
              >
                <div className={`${rootClass}__remove-all-svg-container`}>
                  <Svg name={SvgNames.delete} />
                </div>
                {t('Remove all')}
              </Button>
            )}
          </div>
          <div className={`${rootClass}__footer__section`}>
            <Button
              buttonType={ButtonType.TERTIARY}
              dataTest={`${dataTest}-button-tertiary`}
              className={`${rootClass}__footer-button-cancel`}
              onClick={() => setShowCancelConfirmation(true)}
            >
              {t('Cancel')}
            </Button>
            <Button
              className={`${rootClass}__footer-button-add`}
              buttonType={ButtonType.PRIMARY}
              disabled={!hasGroups || isZeroGroupsInitially || !form.formState.isValid}
              onClick={() => form.handleSubmit(handleSubmit, () => form.trigger())()}
              dataTest={`${dataTest}-button-primary`}
            >
              {t('Save')}
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default DynamicContent
