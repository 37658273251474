import React, { FC, useCallback, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import KeyValuePicker from '@components/KeyValuePicker'
import { CustomField } from '@components/KeyValuePicker/KeyValuePicker'
import Loader from '@components/Loader'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { SvgNames } from '@components/Svg'
import Typography, { ModalHeaderStyle, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { customFields } from '@src/pages/EmailComposer/components/SendDetails/components/QuickAddContact/QuickAddContact.utils'
import { MessageRecipient } from '@src/pages/EmailComposer/utils/EmailComposer.types'
import { filterNotEmptyArray } from '@utils/array'
import { emailRegExp } from '@utils/formUtils'

import './QuickAddContact.css'

interface QuickAddContactProps {
  handleCancel: () => void
  options: MessageRecipient[]
  handleAddContacts: (contacts: CustomField[][], listId?: string) => void
  loading?: boolean
  className?: string
  dataTest?: string
}

const rootClass = 'quick-add-contact'

const QuickAddContact: FC<QuickAddContactProps> = (props: QuickAddContactProps) => {
  const { dataTest = rootClass, className = '', handleCancel, options, loading, handleAddContacts } = props
  const { t } = useTranslation()

  const [disableAddContacts, setDisableAddContacts] = useState(true)
  const [contacts, setContacts] = useState<CustomField[][]>([])
  const [listId, setListId] = useState(options[0]?.id)

  const header = (
    <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__header`}>
      <Typography text={t('QuickAddContacts.header')} {...ModalHeaderStyle} />
    </ModalHeader>
  )

  const transformOptions: SelectV2SingleOption[] = options
    .map((option) => {
      if (!option.id.includes('q-') && !option.id.includes('s-')) {
        return { label: option.name, value: option.id }
      }
    })
    .filter(filterNotEmptyArray)

  const checkData = useCallback((rows: CustomField[][] | undefined) => {
    if (!rows) {
      setDisableAddContacts(true)
      return
    }
    const someRowInvalid = rows.some((row) => {
      const email = row.find(({ id }) => id === 'email')?.value
      return !emailRegExp.test(email ?? '')
    })
    setDisableAddContacts(someRowInvalid)
    setContacts(rows)
  }, [])

  const handleListChange = useCallback((option?: SelectV2SingleOption) => option?.value && setListId(option.value), [])

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen header={header}>
      <ModalBody>
        {loading && <Loader blackout className={`${rootClass}__loader`} />}
        <Typography text={t('QuickAddContacts.subHeader')} type={TextType.BODY_TEXT_LIGHT} inline />
        <KeyValuePicker
          customFields={customFields}
          minRowCount={1}
          addButtonText={t('Add new')}
          customFieldsOnchange={checkData}
          className={`${dataTest}__key-value-picker`}
        />
        <SelectV2
          className={`${dataTest}__selectV2`}
          insideModal
          inputIcon={SvgNames.createSubSegmentActive}
          label={t('Add to list')}
          tooltipMessage={t('QuickAddContacts.dropdown.label.hoverText')}
          isClearable={false}
          onChange={handleListChange}
          defaultValue={transformOptions[0]}
          options={transformOptions}
          helperMessage={t('QuickAddContacts.helperMessage')}
          isDisabled={!options}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={handleCancel}
          buttonType={ButtonType.TERTIARY}
          dataTest={`${dataTest}-button-tertiary`}
          className={`${rootClass}__footer-button-cancel`}
        >
          {t('Cancel')}
        </Button>
        <Button
          onClick={() => handleAddContacts(contacts, listId)}
          className={`${rootClass}__footer-button-add`}
          buttonType={ButtonType.PRIMARY}
          dataTest={`${dataTest}-button-primary`}
          disabled={disableAddContacts}
        >
          {t('Add Contacts')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default QuickAddContact
