// To enable render checking in dev, uncomment this line
// import './wdyr'

import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, useHistory } from 'react-router-dom'

import classNames from 'classnames'
import { i18n } from 'i18next'
import { bindActionCreators, compose } from 'redux'

import { useApolloClient } from '@apollo/client'
import Loader from '@components/Loader/Loader'
import PageError from '@components/PageError'
import { EXTERNAL_WINDOW_PATH, bindComposerResponse, bindGlobalEvents } from '@const/globals'
import CustomCSS from '@src/components/CustomCSS'
import GainsightPX from '@src/components/GainsightPX/GainsightPX'
import TopBar from '@src/components/TopBar'
import IFrameView from '@src/pages/IFrameView'
import '@styles/reset.css'
import AccountProvider from '@utils/account/account.context'
import actions, { AccountActions } from '@utils/account/actions'
import { FEATURE_FLAGS } from '@utils/const/features/availableFeatures'
import featureFlags from '@utils/const/features/featureFlags'
import cookie from '@utils/cookie'
import documentUtils from '@utils/document'
import { runForethought } from '@utils/forethought'
import { MicroserviceClients, useMicroserviceUrl } from '@utils/hooks/useMicroserviceClient'
import iframe from '@utils/iframe/iframe'
import { CLASSIC_WINDOW_URLS } from '@utils/iframeWrapperUrls'
import { THEME } from '@utils/theme'
import utils from '@utils/utils'
import { getLocalStorageItem } from '@utils/window'

import { renderCustomStylesheet, routeHasNavHidden, getIsRoutePublic, toggleRootClassStyle } from './appUtils'
import mapStateToProps, { AppStateProps } from './state/mapStateToProps'
import Navigation from '../Navigation'
import Routes from '../Routes'
import PublicRoutes from '../Routes/PubicRoutes'

import './app.css'

const rootClass = 'app'

interface AppProps {
  i18n: i18n
}

export type AllAppProps = AppProps & AccountActions & AppStateProps

export const App: React.FC<AllAppProps> = (props: AllAppProps) => {
  const {
    loadAccount,
    account,
    navigation: { visible: navigationVisible, modalOpen, expanded, globalLoading },
    i18n,
  } = props
  const history = useHistory()
  const client = useApolloClient()

  // The logout mechanism exists mostly outside of React components, so make this globally available
  window.mfaLogoutRedirect = useMicroserviceUrl({ serviceName: MicroserviceClients.SSO_AUTH_PROXY })

  const URL_WITH_HASH = getLocalStorageItem('URL_WITH_HASH')
  const { loading, results, accountSettings, language, theme, loggedOut, isAuthorized } = account
  const { enableForethoughtWidget, hasAdvancedAnalytics } = accountSettings || {}

  const isError = results?.error !== undefined

  const visible = !location.href.includes(EXTERNAL_WINDOW_PATH) && !Object.values(CLASSIC_WINDOW_URLS).some((url) => location.href.includes(url))
  const showNavigation = !routeHasNavHidden(location.pathname)
  const showTopBar = showNavigation && visible
  const isRoutePublic = getIsRoutePublic(location.pathname)

  useEffect(() => {
    const params = documentUtils.urlSearchParams(window.location.search)
    if (params.get('useMocks') === 'true') {
      featureFlags.setFeatureFlag(FEATURE_FLAGS.MOCK, true)
    }
    const mockUser = params.get('mockUser')
    if (mockUser) {
      featureFlags.setFeatureFlagValue(FEATURE_FLAGS.MOCK_USER, mockUser)
    }
    if (client && !isError) {
      loadAccount({ client })
    }
    toggleRootClassStyle(theme)
    bindGlobalEvents()
    // check every 10 minutes that user is still authenticated
    setInterval(() => {
      if (client) {
        props.checkUserAuthentication({ client })
      }
    }, 10 * 60000)
    // log out if user token expires
    cookie.listenCookieChange((value) => {
      if (!cookie.getCookie(value, 'uc')) {
        utils.sendLoginScreen()
      }
    })

    bindComposerResponse()
    localStorage.removeItem('backToParent')
  }, [])

  useEffect(() => {
    if (theme) {
      toggleRootClassStyle(theme)
    }
  }, [theme])

  useEffect(() => {
    if (loggedOut === true) {
      utils.sendLoginScreen()
    }
  }, [loggedOut])

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language)
      iframe.postMessage({
        actonSetLanguage: language,
      })
    }
  }, [language])

  useEffect(() => {
    const forethoughtWidget = document.getElementById('forethought-chat')
    const config = {
      FORETHOUGHT_API_KEY: process.env.NX_FORETHOUGHT_API_KEY,
      userId: accountSettings?.userId,
      userName: accountSettings?.userName,
      userEmail: accountSettings?.userEmail,
    }

    if (enableForethoughtWidget && isAuthorized && showTopBar && !forethoughtWidget && !isRoutePublic) {
      runForethought(config)
    }
  }, [isAuthorized, isRoutePublic])

  if (isAuthorized === false && !isRoutePublic) {
    utils.sendLoginScreen(!loggedOut)
    return <Loader center />
  }

  if (!isError && (loading || (accountSettings === undefined && isAuthorized === undefined))) {
    return <Loader center />
  }

  if (isError) {
    return <PageError center />
  }

  if (!client) return null

  if (URL_WITH_HASH && hasAdvancedAnalytics) {
    history.replace(URL_WITH_HASH)
    localStorage.removeItem('URL_WITH_HASH')
  }

  return (
    <>
      {globalLoading && <Loader blackout />}
      {visible && modalOpen && (
        <>
          <div className={`${rootClass}__top-bar-cover`} />
          <div
            className={classNames(`${rootClass}__navigation-cover`, {
              [`${rootClass}__navigation-cover--collapsed`]: !expanded,
            })}
          />
        </>
      )}
      {isRoutePublic && (
        <Router>
          <PublicRoutes />
        </Router>
      )}
      {!isRoutePublic && (
        <AccountProvider accountSettings={accountSettings ?? undefined}>
          <Router>
            {showNavigation && <Navigation />}
            <div className={`${rootClass}__right`}>
              <GainsightPX />
              {showTopBar && <TopBar />}
              <div id="body">
                <Routes />
                <IFrameView account={account} navigationVisible={navigationVisible && visible} />
              </div>
            </div>
            <CustomCSS account={account} />
          </Router>
        </AccountProvider>
      )}
      {accountSettings?.hasCustomSkin && theme === THEME.CUSTOM && renderCustomStylesheet(accountSettings?.customSkinParams ?? undefined)}
    </>
  )
}

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: any) => bindActionCreators({ ...actions }, dispatch)

export default compose<React.ComponentType<AppProps>>(connect(mapStateToProps, mapDispatchToProps))(App)
