import { gql } from '@apollo/client'

export default gql`
  query getWebinarsByFilter($scheduleFilter: WebinarScheduleFilter) {
    getWebinarsByFilter(scheduleFilter: $scheduleFilter) {
      id
      title
      type
      duration
      date
    }
  }
`
