import React, { ReactNode } from 'react'

import classNames from 'classnames'

interface SingleRowProps {
  trClassName?: string
  tdClassName?: string
  trStyles?: React.CSSProperties
  rootClass?: string
  columnsLength: number
  children: ReactNode
}

export const SingleRow = ({ rootClass, columnsLength, trClassName, tdClassName, trStyles, children }: SingleRowProps) => {
  return (
    <tr className={classNames({ [`${trClassName}`]: !!trClassName })} style={trStyles}>
      <td colSpan={columnsLength} className={classNames({ [`${rootClass}__td`]: true, [`${tdClassName}`]: !!tdClassName })}>
        {children}
      </td>
    </tr>
  )
}
