import React, { useCallback, useEffect, useState } from 'react'

import { getFolderById } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { ListPageAPI, ListPageCommonState } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { useFolderRequests } from '@complex/ListingPage/GraphQL/Folders.graphQL'
import { useTagRequests } from '@complex/ListingPage/GraphQL/Tags.graphQL'
import { useFolderSubTypes } from '@complex/ListingPage/Hooks/useFolderSubTypes'
import DuplicateFolderModal from '@components/DuplicateModal/DuplicateFolderModal'
import { Status } from '@components/StatusToast/StatusToast'
import { SvgNames } from '@components/Svg'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import { Folder } from '@interface/Folder'
import { useCreateFromDraftRequests } from '@src/pages/listingPages/Drafts/GraphQL/useCreateFromDraftRequests.graphQL'
import { filterNotEmptyArray } from '@utils/array'
import { ItemType } from '@utils/categorization'
import { sortFoldersByName } from '@utils/folderUtils'

interface CreateFromDraftModal {
  listPageAPI: ListPageAPI
  listPageValues: ListPageCommonState
  itemType: ItemType.EMAIL_TEMPLATE | ItemType.LANDING_PAGE
}

const CreateFromDraftModal = ({ listPageAPI, listPageValues, itemType }: CreateFromDraftModal) => {
  const { t } = useTranslation()
  const { createTagRequest, getAllTagsRequest } = useTagRequests()
  const { update, setStatusToast, setError } = listPageAPI
  const { listingPageProps, selectedRows } = listPageValues
  const selectedRow = selectedRows[0]
  const folderSubTypes = useFolderSubTypes(listingPageProps)
  const { getAllFoldersRequest } = useFolderRequests(folderSubTypes)
  const { createLandingPageFromDraftRequest, createEmailTemplateFromDraftRequest } = useCreateFromDraftRequests()

  const [tags, setTags] = useState<LabelDto[]>([])
  const [folders, setFolders] = useState<Folder[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const isTemplate = itemType === ItemType.EMAIL_TEMPLATE
  const asset = isTemplate ? 'EmailTemplate' : 'LandingPage'

  const handleCancel = useCallback(() => update({ showCustomModal: false }), [update])

  const getTags = useCallback(() => {
    const showError = (errors: any) => setError(t('An unexpected error occurred while fetching tags.'), errors)
    getAllTagsRequest([itemType], [])
      .then(({ data, errors }) => {
        if (data?.getLabels) {
          setTags(data.getLabels.filter(filterNotEmptyArray))
        } else {
          showError(errors)
        }
      })
      .catch((errors) => {
        showError(errors)
      })
  }, [t, setError, getAllTagsRequest, itemType])

  const getFolders = useCallback(() => {
    setLoading(true)
    const showError = (errors: any) => setError(t('An unexpected error occurred while fetching folders.'), errors)
    getAllFoldersRequest(itemType, [], true)
      .then(({ data, errors }) => {
        if (data?.getFolders) {
          setFolders(sortFoldersByName(data.getFolders))
        } else {
          showError(errors)
        }
      })
      .catch((errors) => {
        showError(errors)
      })
      .finally(() => setLoading(false))
  }, [t, setError, getAllFoldersRequest, itemType])

  const handleCreateTag = useCallback(
    (tag: LabelDto) => {
      const showError = () => setError('ListPage.Common.Tags.Added.Error', Status.FAIL)
      createTagRequest(itemType, tag)
        .then(({ errors }) => {
          if (errors) {
            showError()
          } else {
            setStatusToast('ListPage.Common.Tags.Added', Status.SUCCESS)
            getTags()
          }
        })
        .catch(() => {
          showError()
        })
    },
    [createTagRequest, setError, setStatusToast, getTags, itemType]
  )

  const handleCreateAction = useCallback<(name: string, tags: LabelDto[], folderIdToClone: number | undefined) => Promise<void>>(
    (newName, tags, folderId) => {
      const showError = () =>
        setError(
          isTemplate ? (
            <Typography
              text="ListPage.Drafts.CreateEmailTemplateFromDraft.Error"
              inline
              values={{ itemName: newName }}
              tagProps={{ bold: { weight: TextWeight.BOLD } }}
            />
          ) : (
            'ListPage.Drafts.CreateLandingPageFromDraft.Error'
          ),
          Status.FAIL
        )
      if (!selectedRow.externalId) {
        showError()
        return Promise.resolve()
      }

      const selectedFolder = getFolderById(folderId, folders)
      const variables = { newName, tags, folderId, id: selectedRow.externalId }

      const request = isTemplate
        ? createEmailTemplateFromDraftRequest(variables).then((resp) => {
            const { errors, data } = resp
            if (errors || !data?.createEmailTemplateFromDraft.newId) {
              return Promise.reject()
            }
            return resp
          })
        : createLandingPageFromDraftRequest(variables).then((resp) => {
            const { errors, data } = resp
            if (errors || !data?.createLandingPageFromEmailDraft.newPageId) {
              return Promise.reject()
            }
            return resp
          })
      return request
        .then(() => {
          setStatusToast(
            <Typography
              text={`ListPage.Drafts.Create${asset}FromDraft.Success`}
              inline
              values={{
                itemName: newName,
                folderName: selectedFolder?.name,
              }}
              tagProps={{ bold: { weight: TextWeight.BOLD } }}
            />,
            Status.SUCCESS
          )
        })
        .catch(() => {
          showError()
        })
    },
    [createLandingPageFromDraftRequest, setError, setStatusToast, selectedRow.externalId, folders, isTemplate]
  )

  useEffect(() => {
    getTags()
    getFolders()
  }, [])

  return (
    <DuplicateFolderModal
      headerTitleKey={`ListPage.Drafts.Create${asset}FromDraft.Title`}
      folders={folders}
      tags={tags}
      namePlaceholder={isTemplate ? 'Template title' : 'Landing page title'}
      itemName={selectedRow?.name ?? ''}
      defaultName={selectedRow?.name}
      descriptionKey={`ListPage.Drafts.Create${asset}FromDraft.Description`}
      onSubmitText={isTemplate ? 'Create as template' : 'Create landing page'}
      onClone={handleCreateAction}
      onTagCreate={handleCreateTag}
      onCancel={handleCancel}
      loading={loading}
      inputSvgIcon={isTemplate ? SvgNames.mailAdd : SvgNames.appendedList}
      dropDownSvgIcon={isTemplate ? SvgNames.folderNestedGray : undefined}
    />
  )
}

export default CreateFromDraftModal
