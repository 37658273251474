import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './DataCardHeader.css'

export interface DataCardHeaderProps {
  className?: string
  dataTest?: string
  children?: ReactNode
  headerText: string
}

const rootClass = 'data-card-header'

const DataCardHeader: FC<DataCardHeaderProps> = (props: DataCardHeaderProps) => {
  const { children, headerText, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Typography className={`${rootClass}__text`} text={t(headerText)} type={TextType.BODY_TEXT_GRAY_MEDIUM} weight={TextWeight.MEDIUM} />
      {!!children && children}
    </div>
  )
}

export default DataCardHeader
