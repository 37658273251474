import React, { FC } from 'react'
import { useForm } from 'react-hook-form'

import * as yup from 'yup'

import FormRow from '@components/FormRow/FormRow'
import Input from '@components/Input/Input'
import { ModalBody } from '@components/Modal'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { Program } from '@graphql/types/query-types'
import { yupResolver } from '@hookform/resolvers/yup'
import GoToStepDetail from '@src/pages/programs/dashboard/components/ProgramSteps/components/GoToStepDetail/GoToStepDetail'
import { ProgramGoToStepExt, ProgramStepType, Step, Track } from '@utils/program/program.constants'

import StepSelector from '../../../StepSelector'

import './editGoToStep.css'

const rootClass = 'edit-go-to-step'

interface EditGoToStepProps {
  step: Step
  tracks: Track[]
  isRunning: boolean
  saveStepAndProgram(step: Step | null, program?: Program): void
  submitId: string
  dataTest?: string
}

const schema = yup.object().shape({
  displayName: yup.string().required('Step Name is required.'),
})

const EditGotoStep: FC<EditGoToStepProps> = (props: EditGoToStepProps) => {
  const { step: baseStep, tracks, isRunning, saveStepAndProgram, submitId, dataTest = 'edit-go-to-step' } = props

  const step = baseStep as ProgramGoToStepExt

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })
  const { t } = useTranslation()

  const onSubmit = (data: any) => {
    const newStep = {
      ...step,
      ...data,
    }
    saveStepAndProgram(newStep)
  }

  const renderForm = () => (
    <form data-test={dataTest} onSubmit={handleSubmit(onSubmit)}>
      <FormRow>
        <Input label={t('Step Name')} defaultValue={step.displayName} name="displayName" register={register('displayName')} />
        {errors.displayName && <span className="error">{t(errors.displayName.message)}</span>}
      </FormRow>
      <FormRow>
        <StepSelector name="goToStepId" step={step} tracks={tracks} register={register('goToStepId')} />
      </FormRow>
      <button type="submit" id={submitId} hidden />
    </form>
  )

  const getGotStepName = () => {
    const gotoTrack = tracks.find((track) => track.steps.find((tStep) => tStep.stepId === step.goToStepId))
    if (!gotoTrack) {
      return ''
    }
    const gotoStep = gotoTrack.steps.find((tStep) => tStep.stepId === step.goToStepId)
    return gotoStep?.displayName ?? ''
  }

  const renderView = () => {
    const gotoStepName = getGotStepName()
    return (
      <>
        <Typography text={step.displayName} weight={TextWeight.MEDIUM} type={TextType.SECTION_HEADER} />
        <GoToStepDetail stepName={gotoStepName} stepType={ProgramStepType.GOTO} />
      </>
    )
  }

  return <ModalBody className={rootClass}>{isRunning ? renderView() : renderForm()}</ModalBody>
}

export default EditGotoStep
