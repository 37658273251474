import React, { FC, useCallback, useContext } from 'react'

import { useTranslation } from '@const/globals'
import ValidationSection from '@src/pages/EmailComposer/components/common/ValidationSection/ValidationSection'
import { ValidationSectionStatus } from '@src/pages/EmailComposer/components/common/ValidationsList/ValidationsList'
import { CommonComposerTab } from '@utils/composer/commonComposer/CommonComposer.context'
import { EmailComposerContext, ValidationSectionType } from '@utils/composer/context/EmailComposer.context'

interface TransactionEmailSectionProps {
  dataTest?: string
}

const rootClass = 'transactional-email-section'

export const TransactionalEmailSection: FC<TransactionEmailSectionProps> = ({ dataTest = rootClass }) => {
  const {
    values: {
      message: { isTransactional },
    },
    api: { onTabChange, onStatusChange },
  } = useContext(EmailComposerContext)
  const { t } = useTranslation()

  const handleClick = useCallback<VoidFunction>(() => onTabChange(CommonComposerTab.SETTINGS), [onTabChange])

  if (!isTransactional) {
    return null
  }

  return (
    <ValidationSection
      type={ValidationSectionType.TRANSACTION_EMAIL}
      dataTest={dataTest}
      title="Transactional email"
      subTitle={{ text: t('EmailComposer.Transactional.Email.Validation.SubTitle') }}
      buttonLabel="Edit email type"
      onClick={handleClick}
      onStatusChange={onStatusChange}
      status={ValidationSectionStatus.SUCCESS}
    />
  )
}
