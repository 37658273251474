import { gql } from '@apollo/client'

export default gql`
  query CRMProgramCampaigns {
    crmProgramCampaigns {
      name
      rawname
      externalId
      memberType
    }
  }
`
