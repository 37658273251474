import { gql } from '@apollo/client'

export default gql`
  query getAllPersonalizations($sortColumn: String, $sortDirection: Direction) {
    getAllPersonalizations(sortColumn: $sortColumn, sortDirection: $sortDirection) {
      key
      value
      accessor
    }
  }
`
