import { gql } from '@apollo/client'

export default gql`
  mutation deletePrograms($items: [ItemDTO]) {
    deletePrograms(items: $items) {
      status
      message
    }
  }
`
