import React, { FC, useContext } from 'react'

import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'
import { Box } from '@src/pages/programs/UpgradeCommonComponents/Box/Box'
import { getConditionText } from '@src/pages/programs/upgradeManager/components/steps/Steps.utils'
import { UpgradedProgramSendStep } from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'

import './ReviewRemovedStepEmailMessageAction.css'

interface ReviewSendEmailMessageStepProps {
  step: UpgradedProgramSendStep
}

const rootClass = 'review-removed-step-email-message-action'

export const ReviewRemovedStepEmailMessageAction: FC<ReviewSendEmailMessageStepProps> = ({ step }: ReviewSendEmailMessageStepProps) => {
  const {
    values: {
      program: { sources, messages },
    },
  } = useContext(ProgramManagerContext)
  const { t } = useTranslation()

  return (
    <div className={rootClass}>
      {step?.sendChoices?.map(({ not, msgId, srcId }, index) => {
        const programOriginalSource = sources.find((source) => source.id === srcId ?? '')
        if (!programOriginalSource) {
          return
        }
        const message = messages.find((message) => message.id === msgId)
        return (
          <Box key={index} className={`${rootClass}__box`}>
            <Typography
              text={t(index === 0 ? getConditionText(undefined, not) : getConditionText(undefined, not).replace('If', 'Else if'))}
              lineHeight={LineHeight.MEDIUM}
            />
            <div className={`${rootClass}__action-container`}>
              <div className={`${rootClass}__value-container`}>
                <Typography text={t('Source list')} weight={TextWeight.MEDIUM} lineHeight={LineHeight.MEDIUM} />
                <Box isCurrent isSourceValue>
                  <TextWithTooltipOnEllip
                    typographyProps={{
                      text: programOriginalSource?.name,
                    }}
                  />
                  <Typography
                    text={t(`AutomatedPrograms.UpgradeManager.SourceReplacement.Contacts`, { count: programOriginalSource?.count })}
                    type={TextType.BODY_TEXT_LIGHT}
                  />
                </Box>
              </div>
              <div className={`${rootClass}__message-container`}>
                <Typography text={t('Send')} lineHeight={LineHeight.MEDIUM} />
                <TextWithTooltipOnEllip
                  typographyProps={{
                    className: `${rootClass}__message`,
                    text: message?.name,
                    type: TextType.BODY_TEXT_SMALL,
                    lineHeight: LineHeight.MEDIUM_SMALL,
                  }}
                />
              </div>
            </div>
          </Box>
        )
      })}
    </div>
  )
}
