import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { TableV2 } from '@components/TableV2/TableV2'
import { useTranslation } from '@const/globals'
import syncRecordErrors from '@graphql/microservices/crm-sync/syncRecordErrors'
import { RecordError, SyncRecordErrorsQuery, SyncRecordErrorsQueryVariables } from '@graphql/types/microservice/crm-sync-types'
import { syncErrorsUtilsColumn } from '@src/pages/datamanagement/components/SyncErrors/utils/SyncErrors.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'

import './SyncErrors.css'

type Props = {
  className?: string
}

const rootClass = 'sync-errors'

interface State {
  recordRows?: RecordError[]
  pageIndex: number
  pageSize: number
  recordCount: number
  imgSrc?: StaticImageNames
  pageCount: number
}

const SyncErrors: FC<Props> = (props: Props) => {
  const { className = '' } = props

  const { t } = useTranslation()

  const [state, setState] = useState<State>({
    pageIndex: 0,
    pageSize: 20,
    recordCount: 0,
    pageCount: 0,
  })

  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.CRM_SYNC })

  const { data, loading } = useQueryOnMount<SyncRecordErrorsQuery, SyncRecordErrorsQueryVariables>(syncRecordErrors, {
    client,
    fetchPolicy: 'network-only',
    variables: {
      offset: state.pageIndex,
      count: state.pageSize,
    },
  })

  const fetchData = (pageIndex: number, pageSize: number) => {
    setState({ ...state, pageIndex, pageSize })
  }

  useEffect(() => {
    if (data && !loading) {
      const errorRecs = data.recordErrors?.map((rec) => {
        const errorRec: RecordError = {
          firstName: rec?.firstName,
          error: rec?.error,
          email: rec?.email,
          pushRecordErrorId: rec?.pushRecordErrorId,
        }
        return errorRec
      })
      setState({
        ...state,
        recordCount: data.recordErrorsCount ?? 0,
        recordRows: errorRecs,
        imgSrc: StaticImageNames.emptyCelebrate,
      })
    }
    if (loading) {
      setState({ ...state, imgSrc: undefined })
    }
  }, [data, loading])

  useEffect(() => {
    const count = state.recordCount / state.pageSize !== 0 ? Math.trunc(state.recordCount / state.pageSize) + 1 : state.recordCount / state.pageSize
    setState({ ...state, pageCount: count })
  }, [state.pageSize, state.recordCount])

  return (
    <div className={classNames(rootClass, className)}>
      <div className={`${rootClass}__title-section`}>
        <div className={`${rootClass}__title-text`}>{t('Act-On to CRM Synchronization Errors')}</div>
      </div>
      <TableV2
        paginationState={{
          pageIndex: state.pageIndex,
          pageSize: state.pageSize,
          controlledPageCount: Math.ceil(state.pageCount / state.pageSize),
        }}
        data={state.recordRows ?? []}
        columns={syncErrorsUtilsColumn}
        fetchData={fetchData}
        loading={loading}
        enableInsideLoader
        withoutBorders
      />
    </div>
  )
}

export default SyncErrors
