import React, { FC, useCallback } from 'react'
import { useHistory } from 'react-router'

import Container from '@components/Container'
import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import InfoAction, { InfoActionType } from '@components/InfoAction/InfoAction'
import { renderLoader } from '@components/Loader/Loader'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { SvgNames } from '@components/Svg'
import { rootContext, useTranslation } from '@const/globals'
import { CrmEntityType } from '@graphql/types/microservice/crm-entity-mapping-types'
import ReportMappingHeader from '@src/pages/datamanagement/components/ReportMapping/components/ReportMappingHeader'
import { ReportMappingsData, SelectOptionsType } from '@src/pages/datamanagement/context/DataManagementContext'

import MappingFieldsList from './components/MappingFieldsList'

import './ReportMapping.css'

export interface ReportMappingProps {
  dataTest?: string
  className?: string
  reportMappingsData?: ReportMappingsData[]
  loading: boolean
  updateRow: (rowId: number, entityType: CrmEntityType, option?: SelectOptionsType) => void
}

const rootClass = 'report-mapping'

const ReportMapping: FC<ReportMappingProps> = (props: ReportMappingProps) => {
  const { reportMappingsData, updateRow, loading, dataTest = rootClass } = props
  const { t } = useTranslation()
  const history = useHistory()

  const renderListing = useCallback(() => {
    return (
      <>
        <InfoAction
          svgName={SvgNames.lightBulb}
          type={InfoActionType.NORMAL}
          message={t('Any changes to your CRM field mappings will be synced during your next scheduled sync.')}
          secondaryButton={{
            label: t('Manage Sync Schedule'),
            onClick: () => history.push(`${rootContext}/datamanagement/sync-schedule`),
          }}
          className={`${rootClass}__info-section`}
          dataTest={dataTest}
        />
        <Container noSidePadding>
          <ReportMappingHeader />
          {reportMappingsData?.map((reportMapping) => {
            return <MappingFieldsList reportMapping={reportMapping} key={reportMapping.entityType} updateRow={updateRow} />
          })}
        </Container>
      </>
    )
  }, [reportMappingsData, updateRow])

  return loading ? (
    renderLoader('loader--white-background')
  ) : reportMappingsData?.length ? (
    renderListing()
  ) : (
    <EmptyListing
      dataTest={`${dataTest}-empty-listing`}
      headline={t(`You’ve not added any fields to map yet`)}
      imgSrc={StaticImageNames.emptyMappedFields}
      size={EmptyListingSize.MEDIUM}
      buttonOnClick={() => {
        return
      }}
    />
  )
}

export default ReportMapping
