import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import Grid from '@components/Grid/Grid'
import { ModalBody } from '@components/Modal'
import SINGLE_SELECT_DROPDOWN_SCROLLABLE_CONTAINER from '@const/singleSelectDropdown'
import { UserInput, UserPermissionsInput } from '@graphql/types/mutation-types'
import { UserTypeValue } from '@src/pages/Settings/Users/components/InviteUsersModal/const/userType.const'
import useInviteUsersCount from '@src/pages/Settings/Users/components/InviteUsersModal/hooks/useInviteUsersCount'
import InviteBulkUsersForm from '@src/pages/Settings/Users/components/InviteUsersModal/InviteBulkUsersForm/InviteBulkUsersForm'
import InviteSingleUserForm from '@src/pages/Settings/Users/components/InviteUsersModal/InviteSingleUserForm/InviteSingleUserForm'
import InviteUsersModalFooter from '@src/pages/Settings/Users/components/InviteUsersModal/InviteUsersModalFooter/InviteUsersModalFooter'
import PermissionsForm from '@src/pages/Settings/Users/components/InviteUsersModal/InviteUsersPermissionsForm/InviteUsersPermissionsForm'
import { LaunchApprovalValue } from '@src/pages/Settings/Users/components/UserLaunchApprovalSelect/launchApproval.const'
import { useSalesUsers } from '@src/pages/Settings/Users/context/SalesUsersContext'
import { checkEmailValidity } from '@utils/formUtils'
import { timeZoneData } from '@utils/timezones'

export interface InviteUsersForm {
  userType: UserTypeValue
  email?: string[]
  firstName?: string
  lastName?: string
  title?: string
  timeZone?: string
  customWelcomeMessage?: string
  permissions?: UserPermissionsInput
  launchApproval?: string
  hasEmailError?: boolean
}

const fromDefaultValues = {
  userType: UserTypeValue.MARKETING,
  firstName: '',
  lastName: '',
  launchApproval: LaunchApprovalValue.ALLOWED,
  timeZone: timeZoneData[0].id,
}

export type InviteUsersValueChangesFuncType = (values: Partial<InviteUsersForm>) => void

interface Props {
  isAddSingle: boolean
  onSave: (userInput: UserInput) => void
  onCancel: () => void
  dataTest?: string
}
const rootClass = 'invite-users-modal__content'

const InviteUsersModalContent: FC<Props> = (props: Props) => {
  const { isAddSingle, onSave, onCancel, dataTest = rootClass } = props
  const { enableLaunch } = useSalesUsers()
  const [formValues, setFormValues] = useState<InviteUsersForm>(fromDefaultValues)
  const { availableCount, maxCount, loading: loadingCount } = useInviteUsersCount(formValues.userType)
  const availableRemaining = useMemo<number>(
    () => (isAddSingle ? availableCount : availableCount - (formValues.email?.length ?? 0)),
    [isAddSingle, availableCount, formValues.email?.length]
  )

  const isValidState = useMemo<boolean>(
    () => !!formValues.launchApproval && !formValues.hasEmailError && !!formValues.email && !checkEmailValidity(formValues.email[0] ?? ''),
    [formValues.email, formValues.launchApproval, formValues.hasEmailError]
  )
  const disableSave = useMemo<boolean>(
    () => !isValidState || !availableCount || availableRemaining < 0,
    [isValidState, availableCount, availableRemaining]
  )

  // If user moves to Add multiple after filling out some fields,
  // persist the user type and custom message info.
  useEffect(
    () =>
      setFormValues(({ userType, customWelcomeMessage, permissions, launchApproval }) => ({
        ...fromDefaultValues,
        userType,
        customWelcomeMessage,
        permissions,
        launchApproval,
      })),
    [isAddSingle]
  )

  const handleValuesChange = useCallback<InviteUsersValueChangesFuncType>((values) => {
    setFormValues((cur) => ({ ...cur, ...values }))
  }, [])

  const handleSave = useCallback<() => void>(() => {
    const valuesToSend = { ...formValues }
    if (valuesToSend.userType !== UserTypeValue.MARKETING) {
      //sending permissions only for Marketing user
      delete valuesToSend.permissions
    }
    if (!enableLaunch && valuesToSend.userType === UserTypeValue.SALES) {
      delete valuesToSend.launchApproval
    }
    delete valuesToSend.hasEmailError
    onSave(valuesToSend)
  }, [formValues, onSave, isAddSingle])

  return (
    <>
      <ModalBody dataTest={dataTest} className={rootClass}>
        <Grid row className={`${rootClass}__grid`}>
          <Grid size={60} className={`${rootClass}__grid-column`}>
            {isAddSingle ? (
              <InviteSingleUserForm onValueChange={handleValuesChange} values={formValues} />
            ) : (
              <InviteBulkUsersForm onValueChange={handleValuesChange} values={formValues} availableRemaining={availableRemaining} />
            )}
          </Grid>
          <Grid size={40} className={`${rootClass}__grid-column`} dataTest={SINGLE_SELECT_DROPDOWN_SCROLLABLE_CONTAINER}>
            <PermissionsForm
              onValueChange={handleValuesChange}
              userType={formValues.userType}
              maxCount={maxCount}
              haveAvailable={!!availableCount}
              loading={loadingCount}
            />
          </Grid>
        </Grid>
      </ModalBody>
      <InviteUsersModalFooter
        availableRemaining={availableRemaining}
        maxUsers={maxCount}
        userType={formValues.userType}
        onCancel={onCancel}
        disableSave={disableSave}
        onSave={handleSave}
        loading={loadingCount}
      />
    </>
  )
}

export default InviteUsersModalContent
