import { gql } from '@apollo/client'

export default gql`
  query getFormPublicURLs($formId: String!) {
    getFormPublicURLs(formId: $formId) {
      name
      url
      prefilledUrl
      shortUrl
      seoUrl
      prettyUrl
    }
  }
`
