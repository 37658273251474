import { useCallback, useState } from 'react'

const useLoadingMutation = () => {
  const [loadingSales, setLoadingSales] = useState<boolean>(false)
  const [loadingMarketing, setLoadingMarketing] = useState<boolean>(false)

  const setLoadingMutation = useCallback<(loading: boolean, isSales: boolean) => void>(
    (loading, isSales) => (isSales ? setLoadingSales(loading) : setLoadingMarketing(loading)),
    []
  )

  return { setLoadingMutation, loadingSales, loadingMarketing }
}

export default useLoadingMutation
