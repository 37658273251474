import React, { ChangeEvent, useEffect, useState } from 'react'

import { ButtonIconPosition, ButtonType } from '@components/Button'
import { ButtonWeight } from '@components/Button/Button'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import FormRow from '@components/FormRow'
import InputWithStatus from '@components/InputWithStatus/InputWithStatus'
import LabelWithSvgTooltip from '@components/LabelWithTooltipIcon/LabelWithSvgTooltip'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import TooltipButton from '@components/TooltipButton/TooltipButton'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { SecurityOptionEditContentRefType } from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Security/components/SecuritySection/SecuritySection'
import { checkEmailValidity } from '@utils/formUtils'

import './GlobalBccPoliciesEditContent.css'

export interface GlobalBccPoliciesEditContentProps {
  contentRef: SecurityOptionEditContentRefType
  setIsEnabled: (enabled: boolean) => void
  onReset?: (data: any) => void
  data: string
  dataTest?: string
}

const rootClass = 'global-bcc-policies'

const GlobalBccPoliciesEditContent = (props: GlobalBccPoliciesEditContentProps) => {
  const { data, contentRef, setIsEnabled, onReset, dataTest = rootClass } = props
  const [inputValue, setInputValue] = useState<string>(data)
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false)
  const { t } = useTranslation()

  useEffect(() => {
    setIsEnabled(false)
    contentRef.data = data
  }, [data])

  const globalBccValidation = (email: string) => {
    if (email.length < 0) {
      return
    }

    return checkEmailValidity(email)
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsEnabled(!globalBccValidation(e.target.value))
    setInputValue(e.target.value)
    contentRef.data = e.target.value
  }

  return (
    <div data-test={dataTest}>
      {confirmModalOpen && (
        <ConfirmationModal
          isYesNo
          isOpen={confirmModalOpen}
          title={t('Are you sure?')}
          yesButtonText={'Reset'}
          body={
            <>
              <Typography text={data} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.MEDIUM} inline />
              &nbsp;
              <Typography text={t('Global.BCC.Confirmation.Text')} type={TextType.BODY_TEXT_LIGHT} inline />
            </>
          }
          onAnswer={(answer: YesNo) => {
            if (answer === YesNo.YES) {
              onReset?.('')
            }
            setConfirmModalOpen(false)
          }}
        />
      )}
      <FormRow>
        <div className={`${rootClass}__label`}>
          <LabelWithSvgTooltip
            label={t('Email address')}
            tooltipContent={t('Global.BCC.Input.Label')}
            className={`${rootClass}__label-tooltip`}
            svgType={SvgType.ICON}
          />
          {data.length > 0 && (
            <TooltipButton
              buttonType={ButtonType.TEXT_TEAL}
              iconPosition={ButtonIconPosition.LEFT}
              align={'end'}
              weight={ButtonWeight.MEDIUM}
              title={t('Global.BCC.Reset.Tooltip')}
              onClick={() => {
                setConfirmModalOpen(true)
              }}
            >
              <Svg name={SvgNames.reset} type={SvgType.SMALLER_ICON} />
              {t('Reset')}
            </TooltipButton>
          )}
        </div>
        <InputWithStatus
          className={`${rootClass}-form-input`}
          placeholder={t('Global.BCC.Input.Placeholder')}
          dataTest={`${dataTest}-form-input`}
          value={inputValue}
          validityFunctions={[globalBccValidation]}
          tooltipProps={{ position: 'top', align: 'end', alignOffset: 14 }}
          tooltipErrorMessages={{ emailInvalid: 'Provide a valid email address' }}
          onChange={onChange}
        />
      </FormRow>
    </div>
  )
}

export default GlobalBccPoliciesEditContent
