import { createContext } from 'react'

import { ApolloClient } from '@apollo/client'
import { ReportField, ReportFieldMappingDefinitionKey } from '@graphql/types/microservice/crm-entity-mapping-types'
import { PushPolicy } from '@graphql/types/microservice/crm-sync-types'
import { Column, EntityTypeDisplay, OptInOutVisibility } from '@graphql/types/microservice/crm-types'
import { FormDto } from '@graphql/types/microservice/form-types'
import { ScoreSheet, StandardField, UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { Errors } from '@interface/common'
import { SelectOption } from '@interface/Select'
import { DynamicListRowData, OptionType } from '@src/pages/datamanagement/components/CrmContacts/components/DynamicList/utils/DynamicList.interfaces'
import {
  ColumnDataType,
  FrequencyType,
  RowHistoryState,
  ScheduleAMPM,
  ScheduleType,
  SyncResult,
} from '@src/pages/datamanagement/utils/DataManagement.constants'
import { FieldValidationState } from '@utils/crm.utils'

import { mockStateValues } from './DataManagement.mocks'

export function getSyncStatusReadable(status: string) {
  switch (status) {
    case 'Canceling':
      return 'Canceling sync...'
    case 'FetchingData':
      return 'Fetching data from {{crm}}...'
    case 'MergingData':
      return 'Merging data from {{crm}} into Act-On...'
    case 'OptOutPull':
      return 'Pulling Opt-Out data from {{crm}}...'
    case 'OptOutPush':
      return 'Pushing Opt-Out data to {{crm}}...'
    case 'Scheduled':
      return 'Request has been submitted...'
    default:
      return 'Unknown'
  }
}

export interface Entity {
  displayName: string
  entityTypeIdentifier: string
  visible: boolean
  nameColumns: string[]
  primaryKeyColumn: string
  recordCount: number
}

export interface EntityColumn {
  name: string
  label: string
  dataType: string
  pickListValues: string[]
  required: boolean
  selected: boolean
  used: boolean
  usedBy: SubscriptionInfo[]
}

export interface SubscriptionInfo {
  subscriber: String
  description: String
}

export interface Cell {
  [key: string]: string
}

export interface EntityTypeData {
  entityTypeDataMapping: any
}
export interface EntityTable {
  crmName: String
  entityType: Entity
  nameColumns: String[]
  primaryKeyColumn: String
  entityDisplayName: String
  columns: EntityColumn[]
  rows: EntityTypeData[]
  maxSelectedColumns: number
  numSelectedColumns: number
  message: String
  [key: string]: any
}

export interface ScheduledTime {
  hour?: number
  minute?: number
  ampm?: string
}
export interface SyncSchedule {
  frequency?: string
  period?: string
  scheduledTime: ScheduledTime
  schedule?: string
  dayOfTheWeek?: string
  dayOfTheMonth?: string
  pullOptOut?: boolean
  pushOptOut?: boolean
  isOptInOutSupported?: boolean
  optInOutVisibility: OptInOutVisibility
}

export interface DuplicateInfo {
  rowId: number
  type: FieldValidationState
}

export interface RecordModalState {
  openRecordModal: boolean
  modalEntityName: string
  modalEntityIdentifier: string
  modalRecordCount: number
}

export interface FieldsByEntityType {
  entity: string
  columns: Column[]
}

export interface ResultPerEntity {
  entityType?: string
  isSaved?: boolean
  saveError?: boolean
}

export interface LastSyncResult {
  syncResult: SyncResult | null
  showWarning: boolean
}

export interface MutationResultState {
  resultPerEntity: ResultPerEntity[]
  isSyncScheduleSaved: boolean
  isOptInOutSaved: boolean
  areFieldMappingsSaved: boolean
  areReportMappingsSaved: boolean
  attemptToSaveScheduleChanges: boolean
  attemptToSaveOptInOutChanges: boolean
  attemptToSaveEntityChanges?: string[]
  attemptToSaveFieldMappingChanges: boolean
  attemptToSaveReportMappingsChanges: boolean
}

export interface RunningSyncStatus {
  isSyncingNow: boolean
  syncState: string
  isCanceling: boolean
}

export interface CrmContactData {
  done: boolean
  standardRows: DynamicListRowData[]
  customRows: DynamicListRowData[]
  stdFields: OptionType[]
  dataAvailable?: boolean
}
export interface Clients {
  listSrvClient: ApolloClient<any>
  crmSyncSrvClient: ApolloClient<any>
  hotProspectsClient: ApolloClient<any>
  crmEntityMappingClient: ApolloClient<any>
}

export interface Row {
  id: number
  mapping: UnifiedListFieldMapping
  status: FieldValidationState
}

export interface NonCrmContactsData {
  standardRows: Row[]
  customRows: Row[]
  index: number
}

export interface SelectOptionsType extends SelectOption {
  id: string
  value: string
  dataType?: ColumnDataType
}

export interface ReportMappingsData extends ReportFieldMappingDefinitionKey {
  mappingList: ListDataType[]
}

export interface ListDataType {
  id: number
  options: SelectOptionsType[]
  selectedOption?: SelectOptionsType
  defaultOption?: SelectOptionsType
  reportField: ReportField
  mappingStatus: string
}

export interface State {
  currentTab?: string
  fieldMappingUsageWarning?: {
    fieldMapping: UnifiedListFieldMapping
    forms: FormDto[]
    loading: boolean
  }
  loading: boolean
  loadingULFMappings: boolean
  errors: Errors
  pageError: boolean
  saved: boolean
  hasUpdates: boolean
  openDiscardModal: boolean
  syncInfo: EntityTypeDisplay[]
  entityTable: FieldsByEntityType[]
  syncSchedule: SyncSchedule
  originalSyncSchedule: SyncSchedule
  recordModal: RecordModalState
  token: string
  accountContext: string
  lastSync: number
  lastSyncResult: LastSyncResult
  mutationResultState: MutationResultState
  runningSyncStatus: RunningSyncStatus
  accountTimeZone: string
  connectorType: string
  scoreSheetsList: ScoreSheet[]
  fieldMappings: UnifiedListFieldMapping[]
  standardFieldsMap: Map<string, StandardField>
  fieldMappingStatus: Map<number, FieldValidationState>
  rowHistoryStatus: Map<number, RowHistoryState>
  crmContactData: CrmContactData
  nonCrmContactsData: NonCrmContactsData
  showCrmContactValidationToast: { open: boolean; text: string }
  clients: Clients
  saveUCLChanges: boolean
  reportMappingsData: ReportMappingsData[]
  saveReportMappingChanges: boolean
  fetchNonCRMData: boolean
  pushPolicy?: PushPolicy
  pushPolicyLoading?: boolean
  unifiedListFieldMappings: UnifiedListFieldMapping[]
  selectingImportContactsSource: boolean
  importContactsSource: string
  [key: string]: any
}

type Update = <T extends keyof State>(field: T, value: State[T]) => void
type UserUpdates = (field: string, value: any) => void
type NestedUpdate = (parent: string, field: string, value: any, isSystemUpdate: boolean) => void
type Discard = () => void
type Save = () => void
type GetLastSyncResult = () => void

export interface DataManagementContextAPI {
  values: State
  update: Update
  nestedUpdate: NestedUpdate
  save: Save
  discard: Discard
  userUpdates: UserUpdates
  getLastSyncResult: GetLastSyncResult
  updateEntityTypes: VoidFunction
  savePushPolicy: (allowPush?: boolean) => Promise<void>
  refreshCRMData: VoidFunction
}

const frequency = FrequencyType.DAILY
const period = ''
const hour = 0
const minute = 0
const ampm = ScheduleAMPM.AM
const schedule = ScheduleType.OFF
const dayOfTheWeek = ''
const dayOfTheMonth = ''
const pushOptOut = false
const pullOptOut = false
const isOptInOutSupported = true
const loading = false
const errors = {}
const pageError = false
const saved = false
const syncInfo = mockStateValues?.syncInfo
const entityTable = {} as any
const recordModalState = {} as any
const optInOutVisibility = {} as OptInOutVisibility

export const values: State = {
  syncSchedule: {
    frequency,
    period,
    scheduledTime: {
      hour,
      minute,
      ampm,
    } as ScheduledTime,
    schedule,
    dayOfTheWeek,
    dayOfTheMonth,
    pushOptOut,
    pullOptOut,
    isOptInOutSupported,
    optInOutVisibility,
  },
  originalSyncSchedule: {} as SyncSchedule,
  loading,
  loadingULFMappings: false,
  errors,
  pageError,
  saved,
  syncInfo,
  entityTable,
  recordModal: recordModalState,
  hasUpdates: false,
  openDiscardModal: false,
  token: '',
  accountContext: '',
  lastSync: 0,
  lastSyncResult: {
    syncResult: SyncResult.Unavailable,
    showWarning: false,
  },
  accountTimeZone: '',
  mutationResultState: {
    resultPerEntity: [],
    isOptInOutSaved: false,
    isSyncScheduleSaved: false,
    areFieldMappingsSaved: false,
    areReportMappingsSaved: false,
    attemptToSaveEntityChanges: [],
    attemptToSaveOptInOutChanges: false,
    attemptToSaveScheduleChanges: false,
    attemptToSaveFieldMappingChanges: false,
    attemptToSaveReportMappingsChanges: false,
  },
  runningSyncStatus: {
    isSyncingNow: false,
    syncState: '',
    isCanceling: false,
  },
  connectorType: '',
  scoreSheetsList: [],
  fieldMappings: [],
  standardFieldsMap: new Map(),
  fieldMappingStatus: new Map(),
  rowHistoryStatus: new Map(),
  crmContactData: {
    done: false,
    standardRows: [],
    customRows: [],
    stdFields: [],
    dataAvailable: false,
  },
  nonCrmContactsData: {
    standardRows: [],
    customRows: [],
    index: 0,
  },
  showCrmContactValidationToast: { open: false, text: '' },
  clients: {
    listSrvClient: {} as ApolloClient<any>,
    crmSyncSrvClient: {} as ApolloClient<any>,
    hotProspectsClient: {} as ApolloClient<any>,
    crmEntityMappingClient: {} as ApolloClient<any>,
  },
  duplicatedFieldsError: { show: false, message: '' },
  saveUCLChanges: false,
  reportMappingsData: [],
  saveReportMappingChanges: false,
  fetchNonCRMData: false,
  unifiedListFieldMappings: [],
  selectingImportContactsSource: false,
  importContactsSource: '',
}

export const DataManagementContext = createContext<DataManagementContextAPI>({ values } as any)
