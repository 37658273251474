import { gql } from '@apollo/client'

export default gql`
  query loadProfileNotificationPage {
    loadProfileNotificationPage {
      isLaunchNotificationSet
      alertsEmail
      dailyLimit
    }
  }
`
