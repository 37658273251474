import React, { FC, useContext, useEffect, useState } from 'react'

import Caution from '@components/Caution/Caution'
import { LinkTextButton } from '@components/LinkTextButton/LinkTextButton'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { List } from '@interface/foldersLists/List'
import { ProgramScheduleType } from '@src/pages/programs/edit/components/ProgramGeneralSettings/ProgramGeneralSettings'
import { useProgramGeneralSettingsRequests } from '@src/pages/programs/edit/components/ProgramGeneralSettings/ProgramGeneralSettings.graphQL'
import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'
import { SourceReplacement } from '@src/pages/programs/upgradeManager/components/SourceReplacement/SourceReplacement'
import { UpgradedSource, UpgradeManagerContext } from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'

const upgradeManagerClass = 'upgrade-manager'
const rootText = 'AutomatedPrograms.UpgradeManager.SourcesUpgrade.'
const WARNING_MESSAGE_LINK =
  'https://connect.act-on.com/hc/en-us/articles/4405812677399-Contacts-in-Automated-Programs-FAQs-Troubleshooting-Guide#h_01FE50BY8C4XSQN3BRFCAAM6SS'

export const SourcesUpgrade: FC = () => {
  const {
    values: { recommendedSegments, switchToManualStart, upgradedSources },
    update,
  } = useContext(UpgradeManagerContext)
  const {
    values: { program },
  } = useContext(ProgramManagerContext)

  const [sourcesToUpgrade, setSourcesToUpgrade] = useState<UpgradedSource[]>(
    upgradedSources ||
      program?.sourceList.map(({ id, name, count }, index) => {
        const recommended = Object.entries(recommendedSegments)?.find(([key]) => key === id)
        return {
          source: { id, sourceName: name ?? '', count: count as number },
          recommended: recommended ? recommended[1] : undefined,
          removed: false,
          upgradeId: `program-source-${index}`,
        }
      })
  )

  const { isSourceTriggerableRequest } = useProgramGeneralSettingsRequests()

  const { t } = useTranslation()

  const checkForTriggerableSources = async () => {
    if (program.schedule?.type === ProgramScheduleType.TRIGGERED) {
      const sourcesToUpgradeWithoutRemoved = sourcesToUpgrade.filter(({ removed }) => !removed)
      if (sourcesToUpgradeWithoutRemoved.length === 1 && sourcesToUpgradeWithoutRemoved[0].newSource) {
        const isTriggerable = await isSourceTriggerableRequest(sourcesToUpgradeWithoutRemoved[0].newSource.id)
        update({ switchToManualStart: !isTriggerable })
      } else {
        update({ switchToManualStart: false })
      }
    }
  }

  useEffect(() => {
    checkForTriggerableSources()
    const sourcesToUpgradeWithoutRemoved = sourcesToUpgrade.filter(({ removed }) => !removed)
    const submitButtonDisabled = sourcesToUpgradeWithoutRemoved.length === 0 || sourcesToUpgradeWithoutRemoved.some(({ newSource }) => !newSource)
    if (submitButtonDisabled) {
      update({ submitButtonDisabled })
    } else {
      update({ submitButtonDisabled, upgradedSources: sourcesToUpgrade })
    }
  }, [sourcesToUpgrade])

  const onCheckboxChange = (id: string, isChecked: boolean) =>
    setSourcesToUpgrade(sourcesToUpgrade.map((source) => (source.source.id === id ? { ...source, removed: isChecked } : source)))

  const onSubmitSource = (id: string, newSource?: List) =>
    setSourcesToUpgrade(sourcesToUpgrade.map((source) => (source.source.id === id ? { ...source, newSource } : source)))

  return (
    <div className={`${upgradeManagerClass}__step`}>
      <div className={`${upgradeManagerClass}__title-container`}>
        <Typography text={t(`${rootText}Title`)} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.MEDIUM} />
        <Typography text={t(`${rootText}Description`)} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.REGULAR} />
      </div>
      {switchToManualStart && (
        <Caution className={`${upgradeManagerClass}__warning-message`}>
          <Typography
            text={t(
              'Your selected segment is not compatible with immediate program start. The program will be set to require manual start. <LinkTextButton>Learn More</LinkTextButton>.'
            )}
            tagComponents={{ LinkTextButton: <LinkTextButton link={WARNING_MESSAGE_LINK} hideIcons /> }}
          />
        </Caution>
      )}
      <div className={`${upgradeManagerClass}__content-container`}>
        {sourcesToUpgrade?.map((sourceToUpgrade) => (
          <SourceReplacement
            key={sourceToUpgrade.source.id}
            sourceToUpgrade={sourceToUpgrade}
            onCheckboxChange={onCheckboxChange}
            onSubmitSource={onSubmitSource}
          />
        ))}
      </div>
    </div>
  )
}
