import { useApolloClient } from '@apollo/client'
import crmCreateTypes from '@graphql/queries/crmCreateTypes'
import crmIntersectionSchema from '@graphql/queries/crmIntersectionSchema'
import crmPeopleFields from '@graphql/queries/crmPeopleFields'
import crmProgramStepDependentPicklistValues from '@graphql/queries/crmProgramStepDependentPicklistValues'
import crmReferenceEntity from '@graphql/queries/crmReferenceEntity'
import {
  CrmCreateTypesQuery,
  CrmCreateTypesQueryVariables,
  CrmIntersectionSchemaQuery,
  CrmIntersectionSchemaQueryVariables,
  CrmPeopleFieldsQuery,
  CrmPeopleFieldsQueryVariables,
  CrmProgramStepDependentPicklistValuesQuery,
  CrmProgramStepDependentPicklistValuesQueryVariables,
  CrmReferenceEntityQuery,
  CrmReferenceEntityQueryVariables,
} from '@graphql/types/query-types'
import { logError } from '@utils/env'

interface EditCRMStepV2Requests {
  getPeopleFields: () => Promise<CrmPeopleFieldsQuery>
  getCreateTypes: () => Promise<CrmCreateTypesQuery>
  getIntersectionSchema: (entity: string) => Promise<CrmIntersectionSchemaQuery>
  getReferenceEntities: (type: string) => Promise<CrmReferenceEntityQuery>
  getProgramStepDependentPicklistValues: (
    entity: string,
    filterField: string,
    filterValue: string,
    picklistField: string
  ) => Promise<CrmProgramStepDependentPicklistValuesQuery>
}

export const useEditCRMStepV2Requests = (): EditCRMStepV2Requests => {
  const client = useApolloClient()

  const getProgramStepDependentPicklistValues = async (entity: string, filterField: string, filterValue: string, picklistField: string) => {
    const { data, error } = await client.query<CrmProgramStepDependentPicklistValuesQuery, CrmProgramStepDependentPicklistValuesQueryVariables>({
      query: crmProgramStepDependentPicklistValues,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables: { entity, filterField, filterValue, picklistField },
    })
    if (error) {
      logError(error)
    }
    return data
  }

  const getPeopleFields = async () => {
    const { data, error } = await client.query<CrmPeopleFieldsQuery, CrmPeopleFieldsQueryVariables>({
      query: crmPeopleFields,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    })
    if (error) {
      logError(error)
    }
    return data
  }

  const getCreateTypes = async () => {
    const { data, error } = await client.query<CrmCreateTypesQuery, CrmCreateTypesQueryVariables>({
      query: crmCreateTypes,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    })
    if (error) {
      logError(error)
    }
    return data
  }

  const getIntersectionSchema = async (entity: string) => {
    const { data, error } = await client.query<CrmIntersectionSchemaQuery, CrmIntersectionSchemaQueryVariables>({
      query: crmIntersectionSchema,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables: { entity },
    })
    if (error) {
      logError(error)
    }
    return data
  }

  const getReferenceEntities = async (type: string) => {
    const { data, error } = await client.query<CrmReferenceEntityQuery, CrmReferenceEntityQueryVariables>({
      query: crmReferenceEntity,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables: { type },
    })
    if (error) {
      logError(error)
    }
    return data
  }

  return {
    getPeopleFields,
    getCreateTypes,
    getIntersectionSchema,
    getReferenceEntities,
    getProgramStepDependentPicklistValues,
  }
}
