import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Tooltip from '@components/Tooltip/Tooltip'

interface Props {
  className?: string
  dataTest?: string
  text: ReactNode
  trigger: ReactNode
}

const rootClass = 'breadcrumbs-item-with-tooltip'

const BreadcrumbsItemWithTooltip: FC<Props> = (props: Props) => {
  const { text, trigger, className = '' } = props
  return (
    <Tooltip trigger={trigger} alwaysShowOnHover triggerClassName={classNames(rootClass, className, 'ellip')} position={'top'}>
      {text}
    </Tooltip>
  )
}

export default BreadcrumbsItemWithTooltip
