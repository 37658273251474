import React from 'react'

import Button, { ButtonType } from '@components/Button'
import Modal, { ModalBody } from '@components/Modal'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './UnsupportedTypeModal.css'

interface UnsupportedTypeModalProps {
  isOpen: boolean
  fileName?: string
  onClick: () => void
}

const rootClass = 'unsupported-type-modal'

const UnsupportedTypeModal = (props: UnsupportedTypeModalProps) => {
  const { isOpen, fileName, onClick } = props

  const { t } = useTranslation()

  return (
    <Modal isOpen={isOpen}>
      <ModalBody className={`${rootClass}__body`}>
        <div className={`${rootClass}__title`}>
          <Svg name={SvgNames.warning} type={SvgType.VERY_LARGE_ICON} />
          <Typography
            text={t('LandingPages.UploadModal.UnsupportedTypeModal.Title')}
            type={TextType.PAGE_HEADER}
            lineHeight={LineHeight.MEDIUM_LARGE}
          />
        </div>
        <Typography
          text={t('LandingPages.UploadModal.UnsupportedTypeModal.Description', {
            fileName,
          })}
          tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
          type={TextType.BODY_TEXT_LIGHT}
          lineHeight={LineHeight.MEDIUM}
        />
        <div className={`${rootClass}__button`}>
          <Button buttonType={ButtonType.PRIMARY} onClick={() => onClick()}>
            {t('Got it')}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default UnsupportedTypeModal
