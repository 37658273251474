import React, { FC } from 'react'

import classNames from 'classnames'

import { ButtonType } from '@components/Button'
import { Checkbox } from '@components/Checkbox/Checkbox'
import Container from '@components/Container'
import Radio from '@components/Radio'
import RadioGroup from '@components/RadioGroup'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ProgramCopyFromStep } from '@graphql/types/query-types'
import ListProgramSourceSelector from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramDetails/components/ListProgramSources/components/ListProgramSourceSelector/ListProgramSourceSelector'
import { updateTypes } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/components/ListProgramStep/components/CopyFromSegmentDetails/CopyFromSegmentDetails'
import { UpdateType } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/components/ListProgramStep/components/StepTypeSelector/utils/StepTypeSelector.constants'
import { StepErrors } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/components/ListProgramStep/utils/ListProgramStep.utils'

import './CopyFromSegmentStepEditor.css'

interface CopyFromSegmentStepEditorProps {
  className?: string
  dataTest?: string
  step: ProgramCopyFromStep
  onChange: (step: Partial<ProgramCopyFromStep>) => void
  stepErrors?: StepErrors
}

const rootClass = 'copy-from-segment-step-editor'
const rootTranslation = 'ListPage.ListMaintenancePrograms.CopyFromSegmentStepEditor'

const CopyFromSegmentStepEditor: FC<CopyFromSegmentStepEditorProps> = (props: CopyFromSegmentStepEditorProps) => {
  const { dataTest = rootClass, className = '', step, onChange, stepErrors } = props
  const { srcId = '', srcName = '', srcRecordCount = 0, updateType, pruneRecords = false } = step

  const { missingSource = false } = { ...stepErrors }

  const { t } = useTranslation()

  const { mergeOnly, update } = updateTypes[updateType as UpdateType]

  const changeUpdateType = (mergeOnly: boolean, update: boolean) => {
    if (mergeOnly) {
      onChange({ updateType: update ? UpdateType.MERGE_WITH_UPDATE : UpdateType.MERGE })
    } else {
      onChange({ updateType: update ? UpdateType.APPEND_AND_MERGE_WITH_UPDATE : UpdateType.APPEND_AND_MERGE })
    }
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Container>
        <Typography text={t(`${rootTranslation}.Header`)} />
        <ListProgramSourceSelector
          className={`${rootClass}__source-selector`}
          onSubmit={(list) => list && onChange({ srcId: list.id, srcName: list.name, srcRecordCount: list.size })}
          defaultSource={srcId ? { id: srcId, name: srcName, baseId: '', count: srcRecordCount } : undefined}
          buttonType={ButtonType.SECONDARY}
          isEditing
          showErrorOnUnselectedSource={missingSource}
        />
        <RadioGroup verticalLayout>
          <div className={`${rootClass}__inline`}>
            <Radio
              checked={!mergeOnly}
              dataTest={`${dataTest}-append-and-merge`}
              label={t(`${rootTranslation}.AppendAndMerge`)}
              onChange={() => changeUpdateType(false, update)}
            />
            <Tooltip trigger={<Svg name={SvgNames.info} type={SvgType.LARGER_ICON} />}>{t(`${rootTranslation}.AppendAndMerge.Tooltip`)}</Tooltip>
          </div>
          <div className={`${rootClass}__inline`}>
            <Radio
              checked={mergeOnly}
              dataTest={`${dataTest}-merge`}
              label={t(`${rootTranslation}.Merge`)}
              onChange={() => changeUpdateType(true, update)}
            />
            <Tooltip trigger={<Svg name={SvgNames.info} type={SvgType.LARGER_ICON} />}>{t(`${rootTranslation}.Merge.Tooltip`)}</Tooltip>
          </div>
        </RadioGroup>
      </Container>
      <Container>
        <div className={classNames(`${rootClass}__inline`, `${rootClass}__update-records`)}>
          <Checkbox
            checked={update}
            dataTest={`${dataTest}-update-records`}
            label={t(`${rootTranslation}.UpdateRecords`)}
            onChange={(update) => changeUpdateType(mergeOnly, update)}
          />
          <Tooltip trigger={<Svg name={SvgNames.info} type={SvgType.LARGER_ICON} />}>{t(`${rootTranslation}.UpdateRecords.Tooltip`)}</Tooltip>
        </div>
        <div className={`${rootClass}__inline`}>
          <Checkbox
            checked={pruneRecords}
            dataTest={`${dataTest}-prune-records`}
            label={t(`${rootTranslation}.PruneRecords`)}
            onChange={(pruneRecords) => onChange({ pruneRecords })}
          />
          <Tooltip trigger={<Svg name={SvgNames.info} type={SvgType.LARGER_ICON} />}>{t(`${rootTranslation}.PruneRecords.Tooltip`)}</Tooltip>
        </div>
      </Container>
    </div>
  )
}

export default CopyFromSegmentStepEditor
