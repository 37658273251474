import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Svg, { SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'

import './TextLink.css'

export enum TextLinkSize {
  REGULAR = 'REGULAR',
  SMALL = 'SMALL',
  LARGE = 'LARGE',
}

interface Props {
  text?: string
  children?: ReactNode
  link?: string
  size?: TextLinkSize
  target?: string
  iconName?: SvgNames
  iconLeft?: boolean
  hideIcon?: boolean
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
  lineHeight?: LineHeight
  weight?: TextWeight
  isWhite?: boolean
  underline?: boolean
  className?: string
  dataTest?: string
}

const rootClass = 'text-link'

const TextLink: FC<Props> = (props: Props) => {
  const {
    text,
    children,
    link,
    size = TextLinkSize.REGULAR,
    iconName = SvgNames.externalLink,
    hideIcon,
    target = '_blank',
    onClick,
    lineHeight,
    weight = TextWeight.MEDIUM,
    isWhite,
    underline,
    dataTest = rootClass,
    className = '',
    iconLeft,
  } = props

  const onClickProp = onClick ? { onClick } : {}
  const updatedTarget = onClick ? {} : { target }
  const href = link ? { href: link } : {}
  const typePrefix: 'LINK' | 'LINK_WHITE' = `LINK${isWhite ? '_WHITE' : ''}`
  const type =
    size === TextLinkSize.REGULAR
      ? TextType[`${typePrefix}_SMALL`]
      : size === TextLinkSize.LARGE
      ? TextType[typePrefix]
      : TextType[`${typePrefix}_TINY`]

  const renderIcon = (
    <Svg
      className={classNames(`${rootClass}__icon`, { [`${rootClass}__icon--left`]: iconLeft })}
      name={iconName}
      type={iconLeft && size === TextLinkSize.LARGE ? SvgType.MEDIUM : SvgType.SMALLER_ICON}
      fill={isWhite ? SvgColor.WHITE : SvgColor.TEXT_TEAL}
    />
  )

  return (
    <a
      className={classNames(rootClass, className, {
        [`${rootClass}__large`]: size === TextLinkSize.LARGE,
        [`${rootClass}__small`]: size === TextLinkSize.SMALL,
        [`${rootClass}__underline`]: underline,
      })}
      {...onClickProp}
      rel="noreferrer"
      data-test={dataTest}
      {...href}
      {...updatedTarget}
    >
      {!hideIcon && iconLeft && renderIcon}
      <Typography text={children ?? text} type={type} lineHeight={lineHeight} inline weight={weight} />
      {!hideIcon && !iconLeft && renderIcon}
    </a>
  )
}

/**
 * @deprecated use <LinkTextButton instead
 */
export default TextLink
