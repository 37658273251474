import React, { FC } from 'react'

import classNames from 'classnames'

import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'

import './DragPreview.css'

interface Props {
  className?: string
  dataTest?: string
  text: string
  values?: { [key: string]: any }
  disabled?: boolean
}

const rootClass = 'drag-preview'

const DragPreview: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, text, className = '', disabled = false, values } = props
  return (
    <div className={classNames(rootClass, className, { [`${rootClass}__disabled`]: disabled })} data-test={dataTest}>
      <Typography text={text} values={values} type={TextType.BODY_TEXT_WHITE} weight={TextWeight.BOLD} lineHeight={LineHeight.MEDIUM_LARGE} />
    </div>
  )
}

export default DragPreview
