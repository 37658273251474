import { useCallback } from 'react'

import _ from 'lodash'

import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'
import { ClickthroughLinks } from '@utils/composer/context/EmailComposer.context'
import { useEmailComposerRequests } from '@utils/composer/emailComposer/GraphQL/EmailComposerRequests.graphQL'
import { logNewRelicError } from '@utils/new-relic.utils'

import { findClickthroughLinks, findClickthroughLinksText } from '../components/EmailComposerPreview/EmailComposerPreview.utils'

export const useClickthroughLinksValidations = () => {
  const {
    values: {
      message: { templateHtml, id },
      preview: { plainText },
      landingPage: { isLandingPage },
    },
    api: { updateValidations },
  } = useComposerContext()

  const { testUrlsRequest } = useEmailComposerRequests()

  const testUrls = useCallback(
    async (urls: string[]) => {
      const { data, errors } = await testUrlsRequest({ urls })
      if (errors) {
        logNewRelicError(errors, 'Testing external Urls')
        updateValidations({ contentValidations: { isClickthroughError: true } })
        return []
      } else if (data?.testUrls) {
        return data?.testUrls
      }
    },
    [testUrlsRequest, updateValidations]
  )

  return useCallback(async () => {
    updateValidations({ contentValidations: { isClickthroughLoading: true, isClickthroughError: false } })
    const clickthroughLinks = findClickthroughLinks(templateHtml)
    const clickthroughLinksText = findClickthroughLinksText(plainText)

    const extendedListRegex = /{{[^}]*:[^}]*}}/
    const actonLinkRegex = /(?:\/\/)?(?:[^/]+\/acton\/)([0-9a-zA-Z\-_]+)(\/\S*)/

    const allLinks = _.uniqWith([...clickthroughLinks, ...clickthroughLinksText], (arrVal, othVal) => arrVal?.url === othVal?.url)

    const externalUrls: string[] = []
    const validatedLinks: ClickthroughLinks[] = []

    const optOutRedirectUrlInput = document.querySelector('input[data-path="optOutRedirectUrl"]')
    const optOutRedirectUrlValue = optOutRedirectUrlInput instanceof HTMLInputElement ? optOutRedirectUrlInput.value : undefined

    const decodeBraces = (url: string) => {
      return url?.replace(/%7B/g, '{').replace(/%7D/g, '}')
    }

    if (optOutRedirectUrlValue && optOutRedirectUrlValue.trim()) {
      allLinks.push({
        type: '',
        label: '<i>opt-out redirect</i>',
        url: optOutRedirectUrlValue,
      })
    }
    allLinks.forEach((link) => {
      if (link?.url) {
        const match = actonLinkRegex.exec(link.url)

        if (extendedListRegex.test(link.url)) {
          validatedLinks.push({
            ...link,
            url: decodeBraces(link.url),
            valid: false,
          })
        }

        if (match) {
          const pathSegment = match[1]
          const urlTail = match[2]
          const hasBadLink =
            ('ct' === pathSegment && urlTail.indexOf('/{{Env.MsgId}}') === -1 && urlTail.indexOf('/' + id) === -1) ||
            ('is' === pathSegment && urlTail.indexOf('/i-') !== -1) ||
            ('fs' === pathSegment && urlTail.indexOf('/i-') !== -1)

          if (hasBadLink) {
            validatedLinks.push({
              ...link,
              url: decodeBraces(link.url),
              valid: false,
            })
          } else if (
            link.url.indexOf('/acton/fs/optinout') !== -1 ||
            link.url.indexOf('/acton/rif/') !== -1 ||
            link.url.indexOf('/acton/vcal/') !== -1
          ) {
            validatedLinks.push({
              ...link,
              url: decodeBraces(link.url),
              valid: !isLandingPage,
            })
          } else {
            externalUrls.push(link.url)
          }
        } else {
          externalUrls.push(link.url)
        }
      }
    })

    if (!!externalUrls.length) {
      const validatedUrls = await testUrls(externalUrls)

      if (validatedUrls) {
        validatedUrls.forEach((item) => {
          const validatedLink = allLinks?.find((link) => link?.url === item?.url)

          if (validatedLink) {
            validatedLinks.push({
              ...validatedLink,
              url: validatedLink.url && decodeBraces(validatedLink.url),
              valid: item?.isValid ?? false,
            })
          }
        })
      }
    }

    updateValidations({
      contentValidations: {
        clickthroughLinks: validatedLinks,
        invalidClickthroughLinks: validatedLinks.filter((link) => !link.valid),
        isClickthroughLoading: false,
      },
    })
  }, [templateHtml, id, plainText, testUrls, updateValidations])
}
