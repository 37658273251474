import { createContext, ReactNode } from 'react'

import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { Status } from '@components/StatusToast/StatusToast'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { DataType, SegmentDefinitionResponse } from '@graphql/types/microservice/segment-types'
import { List } from '@interface/foldersLists/List'
import { GetCopySegmentLogRequestParams } from '@src/pages/ContactSegments/components/CopySegments/GraphQL/CopySegmentsRequests.segment.graphQL'
import { SegmentType } from '@utils/segments'

export enum Steps {
  ONE,
  TWO,
  THREE,
  FOUR,
}

export enum SegmentActions {
  ARCHIVE = 'ARCHIVE',
  DELETE = 'DELETE',
  NOTHING = 'NOTHING',
}

export enum FieldStatusType {
  SUCCESS = 'SUCCESS',
  DUPLICATE = 'DUPLICATE',
  EMPTY_DATA_TYPE = 'EMPTY_DATA_TYPE',
  EMPTY_DATA_FORMAT = 'EMPTY_DATA_FORMAT',
  EMPTY_NEW_FIELD = 'EMPTY_NEW_FIELD',
  NEW_FIELD_DELETED = 'NEW_FIELD_DELETED',
  NEW_FIELD_IN_USE = 'NEW_FIELD_IN_USE',
  SEGMENT_WITH_UNMAPPED_FIELDS = 'SEGMENT_WITH_UNMAPPED_FIELDS',
}

export enum CopyLogField {
  Date = 'DATE',
  Name = 'NAME',
  ParentName = 'PARENT_NAME',
  Status = 'STATUS',
  Username = 'USERNAME',
}

export const CopyLogFieldValues: { [key in string]: CopyLogField } = {
  ['date']: CopyLogField.Date,
  ['parent']: CopyLogField.ParentName,
  ['status']: CopyLogField.Status,
  ['userName']: CopyLogField.Username,
}

export interface ColumnAttributes {
  columnId?: number
  dataFormat?: string
  dataType?: DataType
  displayName: string
  standardName: string
}

export interface MappedField {
  columnId?: number
  automaticallyMapped?: boolean
  marketingListField: string
  allContactsField?: string
  isNew: boolean
  dataType?: DataType
  dataFormat?: string
  fieldStatus?: FieldStatusType
}

export enum LogStatus {
  DONE = 'Done',
  FAILED = 'Failed',
  PARTIALLY_FAILED = 'Partially failed',
  IN_PROGRESS = 'In progress',
}

export enum LogStatusMapped {
  SUCCESS = 'Success',
  FAILED = 'Failed',
  PARTIALLY_FAILED = 'Partially failed',
  IN_PROGRESS = 'In Progress',
}

export const LogStatusValues: { [key in LogStatus]: string } = {
  [LogStatus.DONE]: LogStatusMapped.SUCCESS,
  [LogStatus.FAILED]: LogStatusMapped.FAILED,
  [LogStatus.PARTIALLY_FAILED]: LogStatusMapped.PARTIALLY_FAILED,
  [LogStatus.IN_PROGRESS]: LogStatusMapped.IN_PROGRESS,
}

export interface SegmentsCopiedHistoryLog {
  copyId: number
  date: string
  parent: string
  segmentsInfo: string
  status: LogStatusMapped
  fails: string[]
  success: string[]
  inProgress: string[]
  userName: string
}

export interface SegmentWithFilterExpression {
  segmentId: string
  value: boolean
}

export interface CopySegmentsContainerState {
  parentLocation?: List
  parentType: SegmentType.SEGMENT | SegmentType.SUBMISSION
  allContactsFields: UnifiedListFieldMapping[]
  allContactsFieldsOptions: SelectV2SingleOption[]
  copyCount?: number
  deletedAllContactsFields: UnifiedListFieldMapping[]
  fieldNamesBySegment: { [key: string]: string[] }
  historyLog: SegmentsCopiedHistoryLog[]
  isMigrating: boolean
  itemsToRemove: string[]
  loadingFields: boolean
  loadingHistoryLog: boolean
  mappedFields: MappedField[]
  segmentAction: SegmentActions
  segmentsCopied: (string | undefined)[]
  segmentNamesByField: SegmentDefinitionResponse[]
  segments: List[]
  lists: List[]
  segmentsWithFilterExpressions: SegmentWithFilterExpression[]
  segmentsWithUnmappedFields: List[]
  showDependenciesModal: boolean
  showFieldMatchingWarningModal: boolean
  showReviewSegmentsModal: boolean
  showSegmentCopyLog: boolean
  statusToast?: {
    message: string | ReactNode
    status: Status
    showStatusToast: boolean
  }
  unifiedListFieldMappings: UnifiedListFieldMapping[]
}

export const CopySegmentsContainerInitialState: CopySegmentsContainerState = {
  segments: [],
  lists: [],
  allContactsFields: [],
  allContactsFieldsOptions: [],
  deletedAllContactsFields: [],
  fieldNamesBySegment: {},
  historyLog: [],
  isMigrating: false,
  itemsToRemove: [],
  loadingFields: false,
  loadingHistoryLog: false,
  mappedFields: [],
  parentType: SegmentType.SEGMENT,
  segmentAction: SegmentActions.ARCHIVE,
  segmentsCopied: [],
  segmentNamesByField: [],
  segmentsWithFilterExpressions: [],
  segmentsWithUnmappedFields: [],
  showDependenciesModal: false,
  showFieldMatchingWarningModal: false,
  showReviewSegmentsModal: false,
  showSegmentCopyLog: false,
  unifiedListFieldMappings: [],
}

type GetCopySegmentLog = (paginationParams: GetCopySegmentLogRequestParams) => void
type RemoveSegment = (segment: List) => void
type UndoCopySegments = (copyId: number, paginationParams: GetCopySegmentLogRequestParams) => void
type UpdateMappedField = (mappedField: MappedField) => void
export type Update = (fieldsToUpdate: Partial<CopySegmentsContainerState>) => void

export interface CopySegmentsAPI {
  values: CopySegmentsContainerState
  checkForUnmappedSegmentFields: VoidFunction
  getCopySegmentCount: VoidFunction
  getCopySegmentLog: GetCopySegmentLog
  getUnifiedListFieldMappings: VoidFunction
  getSegmentDefinitionFields: VoidFunction
  getUnifiedListFieldsFromSource: VoidFunction
  migrateClassicSegments: VoidFunction
  removeSegment: RemoveSegment
  undoCopySegments: UndoCopySegments
  updateMappedField: UpdateMappedField
  update: Update
}

export const CopySegmentsContext = createContext<CopySegmentsAPI>({ values: CopySegmentsContainerInitialState } as any)
