import React, { ComponentProps, FC, useMemo, useState } from 'react'

import classNames from 'classnames'

import AddButton from '@components/AddButton/AddButton'
import Button, { ButtonType } from '@components/Button'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import VisitorsConditionsEditor from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AddAlertFilterModal/components/VisitorsConditionsEditor/VisitorsConditionsEditor'
import {
  CHECK_CONDITIONS_STATUS_EVENT,
  defaultVisitorsCondition,
} from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AddAlertFilterModal/utils/AddAlertFilterModal.constants'
import {
  AlertFilter,
  AlertFilterCondition,
  AlertFilterType,
  LogicalCondition,
  visitorsFilters,
} from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertFilters/utils/AlertFilters.utils'
import FilterTypeSelect from '@src/pages/WebsiteProspectorAlerts/components/FilterTypeSelect/FilterTypeSelect'

import './AddAlertFilterModal.css'

interface AddAlertFilterModalProps {
  className?: string
  dataTest?: string
  selectedFilter?: AlertFilter
  onClose: () => void
  onSaveFilter: (filter: AlertFilter) => void
  isOpen: boolean
}

interface AddAlertFilterModalState {
  filter: AlertFilterType
  conditions: AlertFilterCondition[]
  isCanceling: boolean
}

const rootClass = 'add-alert-filter-modal'

const AddAlertFilterModal: FC<AddAlertFilterModalProps> = (props: AddAlertFilterModalProps) => {
  const { dataTest = rootClass, className = '', onSaveFilter, onClose, isOpen, selectedFilter } = props

  const [state, setState] = useState<AddAlertFilterModalState>({
    filter: selectedFilter?.type ?? AlertFilterType.ALL_VISITORS,
    conditions: selectedFilter?.conditions ?? [defaultVisitorsCondition],
    isCanceling: false,
  })
  const { filter, conditions, isCanceling } = state

  const { t } = useTranslation()

  const { isVisitorsType, isMediaDownloads, isMembership } = useMemo(
    () => ({
      isVisitorsType: visitorsFilters.includes(filter),
      isMediaDownloads: filter === AlertFilterType.MEDIA_DOWNLOADS,
      isMembership: filter === AlertFilterType.MEMBERSHIP,
      isPageVisits: filter === AlertFilterType.PAGE_VISITS,
    }),
    [filter]
  )

  const handleAction = () => {
    const event = new CustomEvent(CHECK_CONDITIONS_STATUS_EVENT)
    document.dispatchEvent(event)
  }

  const onSaveConditionsHandler = (hasValidConditions: boolean) => {
    if (hasValidConditions) {
      onSaveFilter({ type: filter, conditions })
    }
  }

  const editCondition = (index: number, condition?: AlertFilterCondition) => {
    const modifiedFilterConditions = [...conditions.slice(0, index), ...(condition ? [condition] : []), ...conditions.slice(index + 1)]
    setState((state) => ({ ...state, conditions: modifiedFilterConditions }))
  }

  const onChangeCondition = (conditionData: Partial<AlertFilterCondition>, index: number) => {
    editCondition(index, { ...conditions[index], ...conditionData })
  }

  const onRemoveCondition = (index: number) => {
    editCondition(index)
  }

  const onAddCondition = () => {
    const newCondition = { ...defaultVisitorsCondition, ...(conditions.length > 0 ? { logicalCondition: LogicalCondition.AND } : {}) }
    setState(({ conditions, ...state }) => ({ ...state, conditions: [...conditions, newCondition] }))
  }

  const confirmationModalProps: ComponentProps<typeof ConfirmationModal> = {
    isOpen: isCanceling,
    title: t('Are you sure?'),
    body: (
      <Typography
        text={'If you cancel now, you’ll lose all unsaved changes. You cannot undo this action.'}
        type={TextType.BODY_TEXT_LIGHT}
        tagProps={{ bold: { weight: TextWeight.BOLD } }}
      />
    ),
    cancelButtonText: t('Continue editing'),
    deleteButtonText: t('Yes, cancel'),
    isDelete: true,
    onAnswer: (answer: YesNo) => {
      setState((state) => ({ ...state, isCanceling: false }))
      if (answer === YesNo.YES) {
        onClose()
      }
    },
  }

  const header = (
    <ModalHeader headerType={ModalHeaderType.List} className={`${rootClass}__header`}>
      {t(`${selectedFilter ? 'Edit' : 'Add'} alert filter`)}
    </ModalHeader>
  )

  return (
    <>
      <ConfirmationModal {...confirmationModalProps} />
      <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen && !isCanceling} header={header} noPadding>
        <ModalBody className={`${rootClass}__body`}>
          <div className={`${rootClass}__filter`}>
            <Typography text={t('Filter by')} weight={TextWeight.MEDIUM} type={TextType.BODY_TEXT} />
            <FilterTypeSelect selectedFilter={filter} onChange={(selectedFilter) => setState((state) => ({ ...state, filter: selectedFilter }))} />
          </div>
          <div className={`${rootClass}__conditions-container`}>
            <Typography text={t('Visitors match the following conditions:')} weight={TextWeight.MEDIUM} type={TextType.BODY_TEXT} />
            <div className={`${rootClass}__conditions`}>
              <div className={`${rootClass}__conditions-body`}>
                {isVisitorsType && (
                  <VisitorsConditionsEditor
                    conditions={conditions}
                    onChange={onChangeCondition}
                    onRemove={onRemoveCondition}
                    onSaveConditions={onSaveConditionsHandler}
                  />
                )}
              </div>
              <div className={`${rootClass}__conditions-footer`}>
                <AddButton
                  label={t(isMembership ? 'Select lists and segments' : isMediaDownloads ? 'Select media files' : 'Add condition')}
                  onClick={onAddCondition}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter footerType={ModalFooterType.List} className={`${rootClass}__footer`}>
          <Button
            buttonType={ButtonType.TERTIARY}
            onClick={() => setState((state) => ({ ...state, isCanceling: true }))}
            dataTest={`${dataTest}-button-tertiary`}
          >
            {t('Cancel')}
          </Button>
          <Button buttonType={ButtonType.PRIMARY} onClick={handleAction} dataTest={`${dataTest}-button-primary`}>
            {t(selectedFilter ? 'Save' : 'Add')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default AddAlertFilterModal
