import React, { FC, useEffect, useRef, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'

import Loader from '@components/Loader'
import PageContainer from '@components/PageContainer/PageContainer'
import { SegmentError } from '@components/SegmentSaveModal/SegmentSaveModal'
import { Status } from '@components/StatusToast/StatusToast'
import { useTranslation } from '@const/globals'
import { ListPageStatusToast } from '@interface/ListPage.context'
import { SegmentInput } from '@interface/Segment'
import { SEGMENTS_LIST_URL } from '@src/pages/ContactSegments/utils/ContactSegmentsSession.utils'
import { defaultExpression } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/ProfileExpression/ProfileExpression.constants'
import { isSegmentComposerRow } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/utils/SegmentComposerBuild.utils'
import { SegmentComposer } from '@src/pages/SegmentComposer/SegmentComposer'
import { SaveType, SEGMENT_COMPOSER_URL, segmentInputInitialValues } from '@src/pages/SegmentComposer/SegmentComposer.constants'
import {
  OnSaveClickParams,
  SegmentComposerContext,
  segmentComposerInitialState,
  SegmentComposerState,
} from '@src/pages/SegmentComposer/SegmentComposer.context'
import { ExpressionGroup, ExpressionRow } from '@src/pages/SegmentComposer/SegmentComposer.interfaces'
import { useLoadAllAssets } from '@src/pages/SegmentComposer/SegmentComposer.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import { ContactSegmentsSession } from '@utils/contactSegments/contactSegments.utils'
import { setItem } from '@utils/sessionStorage'

type SegmentComposerContainerProps = RouteComponentProps<{ id?: string; type?: string }>

const DEFAULT_SEGMENT_NAME = 'SegmentComposer.Settings.Name.Default'
const rootClass = 'segment-composer'

const SegmentComposerContainer: FC<SegmentComposerContainerProps> = ({ match }: SegmentComposerContainerProps) => {
  const { t } = useTranslation()

  const [state, setState] = useState<SegmentComposerState>({
    ...segmentComposerInitialState,
    segment: {
      ...segmentInputInitialValues,
      name: t(DEFAULT_SEGMENT_NAME),
      isDirectSelect: match.params.type !== undefined ? match.params.type === 'direct' : segmentInputInitialValues.isDirectSelect,
    },
  })
  const { errors, loadingAssets, segment, segmentDefinition } = state

  const { groups } = segmentDefinition

  const { userName } = useAccountSettings()

  const update = (newState: Partial<SegmentComposerState>) => {
    setState((prevState) => ({ ...prevState, ...newState }))
  }

  const history = useHistory<{ statusToast?: ListPageStatusToast }>()

  const isNew = history.location.pathname.includes(`${SEGMENT_COMPOSER_URL}/new`)
  const segmentId = match.params.id

  const { loadAllAssets } = useLoadAllAssets(segment, update, isNew)

  const saveTypeRef = useRef<SaveType>(SaveType.SAVE)

  const doSaveSegment = (segment: SegmentInput): Promise<SegmentInput | undefined> => {
    // TODO make call to the backend to actually save the segment
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve({
          ...segment,
          id: 'q-004f',
          createdBy: segment.createdBy ?? userName,
          createdDate: segment.createdDate ?? Date.now(),
          lastModifiedBy: userName,
          lastModifiedDate: Date.now(),
        })
      }, 2000)
    )
  }

  const save = async (segment: SegmentInput) => {
    const savedSegment = await doSaveSegment(segment)
    if (savedSegment) {
      update({
        statusToast: {
          showStatusToast: true,
          status: Status.SUCCESS,
          statusMessage: t('SegmentComposer.StatusToast.Save.SuccessMessage', { name: segment.name }),
        },
      })
      const redirectUrl = `${SEGMENT_COMPOSER_URL}/${savedSegment.id}`
      history.push(redirectUrl)
    }
    return savedSegment
  }

  const saveAndClose = async (segment: SegmentInput) => {
    const savedSegment = await doSaveSegment(segment)
    const statusToast: ListPageStatusToast = {
      showStatusToast: true,
      status: Status.SUCCESS,
      statusMessage: t('SegmentComposer.StatusToast.Save.SuccessMessage', { name: segment.name }),
    }
    setItem(ContactSegmentsSession.INCOMING_STATUS_TOAST, JSON.stringify(statusToast))
    history.push(SEGMENTS_LIST_URL)
    return savedSegment
  }

  const saveAsCopy = async (segment: SegmentInput) => {
    const savedSegment = await doSaveSegment(segment)
    if (savedSegment) {
      const redirectUrl = `${SEGMENT_COMPOSER_URL}/${savedSegment.id}`
      history.push(redirectUrl, {
        statusToast: {
          showStatusToast: true,
          status: Status.SUCCESS,
          statusMessage: t(`SegmentComposer.StatusToast.SaveAsCopy.SuccessMessage`, savedSegment),
        },
      })
      window.open(redirectUrl, '_self')
    }
    return savedSegment
  }

  const onSaveClick = async ({ isSavingFromModal = false, type = saveTypeRef.current, segmentInput = segment }: OnSaveClickParams) => {
    const showSaveModal = () => update({ showFirstSaveModal: true })
    const showErrorsModal = () => update({ showErrorsModal: true })

    const isNameRequiredError = errors.size === 1 && errors.has(SegmentError.NAME_REQUIRED)
    const isNewSegment = !segmentInput.id

    if (!isSavingFromModal) {
      saveTypeRef.current = type
      if (errors.size > 0) {
        isNameRequiredError && isNewSegment ? showSaveModal() : showErrorsModal()
        return
      }
      if (isNewSegment) {
        showSaveModal()
        return
      }
    }

    const callbackBySaveType: { [key in SaveType]: (segment: SegmentInput) => Promise<SegmentInput | undefined> } = {
      [SaveType.SAVE]: save,
      [SaveType.SAVE_AND_CLOSE]: saveAndClose,
      [SaveType.SAVE_AS_COPY]: saveAsCopy,
    }

    update({ isSaving: true })
    const savedSegment = await callbackBySaveType[type](segmentInput)
    update({
      isSaving: false,
      hasUnsavedChanges: false,
      segment: savedSegment,
      showFirstSaveModal: false,
    })
    return savedSegment
  }

  const onAddLinkedRow = (parentId: string) => {
    const findAndAddSubRow = (rows: (ExpressionGroup | ExpressionRow)[]): (ExpressionGroup | ExpressionRow)[] => {
      return rows.map((row: ExpressionGroup | ExpressionRow) => {
        if (isSegmentComposerRow(row)) {
          if (row.factor.id === parentId) {
            if (!row.factor.subRows) {
              row.factor.subRows = []
            }
            row.factor.subRows.push({ parentId, ...defaultExpression })
            return { ...row }
          }
        } else {
          return {
            ...row,
            rows: findAndAddSubRow(row.rows),
          }
        }
        return row
      })
    }
    const updatedRows = findAndAddSubRow(groups[0].rows)
    update({
      segmentDefinition: {
        ...segmentDefinition,
        groups: [
          {
            ...groups[0],
            rows: updatedRows,
          },
        ],
      },
    })
  }

  useEffect(() => {
    const statusToast = history.location.state?.statusToast
    if (statusToast) {
      update({ statusToast })
      return () => {
        const currentHistoryState = history.location.state
        delete currentHistoryState.statusToast
        history.replace({ ...history.location, state: currentHistoryState })
      }
    }
  }, [history.location.state, update])

  useEffect(() => {
    loadAllAssets(segmentId)
  }, [])

  return (
    <SegmentComposerContext.Provider value={{ onSaveClick, onAddLinkedRow, values: { ...state, isNew, segmentId }, update }}>
      <PageContainer className={`${rootClass}__page`} noChrome>
        {loadingAssets ? <Loader center /> : <SegmentComposer />}
      </PageContainer>
    </SegmentComposerContext.Provider>
  )
}

export default SegmentComposerContainer
