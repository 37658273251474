import React, { FC, useContext, useEffect, useRef, useState } from 'react'

import { useTranslation } from '@const/globals'
import runningSyncStatus from '@graphql/microservices/crm/runningSyncStatus'
import { RunningSyncStatusQuery, RunningSyncStatusQueryVariables } from '@graphql/types/microservice/crm-types'
import LastSyncEndTime from '@src/pages/datamanagement/components/LastSyncEndTime/LastSyncEndTime'
import { DataManagementContext, getSyncStatusReadable, RunningSyncStatus } from '@src/pages/datamanagement/context/DataManagementContext'
import { syncRunningStatuses } from '@src/pages/datamanagement/utils/DataManagement.constants'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { logNewRelicError } from '@utils/new-relic.utils'

type Props = {
  className?: string
  dataTest?: string
}

const SYNC_STATUS_QUERY_INTERVAL = 10000
const rootClass = 'reload-sync-status'
const runningSyncStatusDefault: RunningSyncStatus = {
  isSyncingNow: false,
  syncState: '',
  isCanceling: false,
}

const ReloadSyncStatus: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const [callLastSync, setCallLastSync] = useState(false)
  const {
    values: { connectorType },
    update,
  } = useContext(DataManagementContext)
  const syncIntervalRef = useRef<NodeJS.Timeout | undefined>()

  const { client, token } = useMicroserviceClient({ serviceName: MicroserviceClients.CRM })

  const updateSyncStatus = async () => {
    return client
      .query<RunningSyncStatusQuery, RunningSyncStatusQueryVariables>({
        query: runningSyncStatus,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      })
      .then((data) => processSyncStatus(data.data))
      .catch((error) => processSyncError(error))
  }

  useEffect(() => {
    syncIntervalRef.current = setInterval(updateSyncStatus, SYNC_STATUS_QUERY_INTERVAL)
    return () => {
      if (syncIntervalRef.current) {
        clearInterval(syncIntervalRef.current)
      }
    }
  }, [client, token])

  const processSyncError = (error: any) => {
    const runningSyncStatus = { ...runningSyncStatusDefault }
    runningSyncStatus.syncState = ''
    runningSyncStatus.isSyncingNow = false
    update('runningSyncStatus', runningSyncStatus)
    setCallLastSync(true)
    logNewRelicError(error, 'Fetching CRM sync status')
  }

  const processSyncStatus = (data: RunningSyncStatusQuery) => {
    const runningSyncStatus = { ...runningSyncStatusDefault }
    if (data.syncStatus) {
      setCallLastSync(false)
      if (syncRunningStatuses.includes(data.syncStatus) && data.syncStatus !== 'Idle') {
        runningSyncStatus.isSyncingNow = true
        runningSyncStatus.syncState = t(getSyncStatusReadable(data.syncStatus.toString()), {
          crm: connectorType,
        })
        if ('Canceling' === data.syncStatus.toString()) {
          runningSyncStatus.isSyncingNow = false
          runningSyncStatus.isCanceling = true
        }
      } else {
        setCallLastSync(true)
      }
      update('runningSyncStatus', runningSyncStatus)
    }
  }

  return (
    <div className={className} data-test={dataTest}>
      {callLastSync && <LastSyncEndTime />}
    </div>
  )
}

export default ReloadSyncStatus
