import { gql } from '@apollo/client'

export default gql`
  query loadSecurityPage {
    loadSecurityPage {
      passwordChangeDataMs
      passwordExpirationPolicy
    }
  }
`
