import React, { FC } from 'react'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import TagManagerBaseItem, { TagManagerBaseItemProps } from '@components/TagManager/components/TagManagerBaseItem/TagManagerBaseItem'

import './TagManagerAppliedItem.css'

type Props = TagManagerBaseItemProps & {
  applied: boolean
}

const rootClass = 'tag-manager-applied-item'

const TagManagerAppliedItem: FC<Props> = (props: Props) => {
  const { applied, dataTest = rootClass } = props

  return (
    <TagManagerBaseItem {...props} className={applied ? rootClass : ''} dataTest={dataTest}>
      {applied && <Svg className={`${rootClass}__icon`} name={SvgNames.check} type={SvgType.SMALLER_ICON} />}
    </TagManagerBaseItem>
  )
}

export default TagManagerAppliedItem
