import React, { FC } from 'react'
import { FieldValues, UseFormGetValues, UseFormRegister, UseFormReset } from 'react-hook-form'

import { MessageType } from '@complex/MessagePickerModal/messagePickerModalUtils'
import Checkbox from '@components/Checkbox/Checkbox'
import FormGroup from '@components/FormGroup/FormGroup'
import FormRow from '@components/FormRow/FormRow'
import Input from '@components/Input/Input'
import Label from '@components/Label/Label'
import Select from '@components/Select/Select'
import TimeInput from '@components/TimeInput/TimeInput'
import { useTranslation } from '@const/globals'
import { Program, ProgramMessage, ProgramSource } from '@graphql/types/query-types'
import { getTimeParts } from '@utils/date'
import { ProgramWaitUntilInSegmentStepExt } from '@utils/program/program.constants'

import ConditionalSelect from '../../../ConditionalSelect/ConditionalSelect'

import './waitUntilInSegmentStep.css'

const rootClass = 'wait-until-in-segment-step'

interface Props {
  step: ProgramWaitUntilInSegmentStepExt
  program: Program
  register: UseFormRegister<FieldValues>
  formReset: UseFormReset<FieldValues>
  formData: UseFormGetValues<FieldValues>
  errors: any
  updateWaitUntilInSegmentStep: (step: ProgramWaitUntilInSegmentStepExt) => void
  onProgramSourcesUpdate: (sources: ProgramSource[]) => void
  onMessageUpdate: (messages: ProgramMessage) => void
  dataTest?: string
}

export interface State {
  scheduleType: string
  scheduleInterval?: number
  scheduledTime: string
  scheduledEndTime: string
  hasEndTime: boolean
  ampm?: number
  endAmpm?: number
}

export enum ScheduleType {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

const WaitUntilInSegmentStep: FC<Props> = (props: Props) => {
  const {
    step,
    register,
    formData,
    formReset,
    errors,
    program,
    dataTest = 'edit-wait-until-in-segment-step',
    updateWaitUntilInSegmentStep,
    onProgramSourcesUpdate,
    onMessageUpdate,
  } = props

  const convertStateTime = (hour?: number, minute?: number, ampm?: number) => {
    if (hour == undefined || minute === undefined) {
      return '0:00'
    }
    const hourStr = (ampm === 1 ? (hour === 12 ? hour : hour + 12) : hour === 12 ? 0 : hour).toString()
    const minuteStr = minute !== undefined && minute < 10 ? `0${minute}` : minute.toString()
    return `${hourStr}:${minuteStr}`
  }

  const [state, setState] = React.useState<State>({
    scheduleType: step.schedule.type || ScheduleType.DAILY,
    scheduleInterval: step.schedule.interval,
    scheduledTime: convertStateTime(step.schedule.hour, step.schedule.minute, step.schedule.ampm),
    scheduledEndTime: convertStateTime(step.schedule.endHour, step.schedule.endMinute, step.schedule.endAmpm),
    hasEndTime: step.schedule.hasEndTime || false,
    ampm: step.schedule.ampm,
    endAmpm: step.schedule.endAmpm,
  })

  const { t } = useTranslation()

  const scheduleChange = (e: any) => {
    const type = e.target.value

    setState({
      ...state,
      scheduleType: type,
    })

    updateWaitUntilInSegmentStep({ ...step, schedule: { ...step.schedule, type } })
  }

  const intervalChange = (e: any) => {
    const { value } = e.target

    setState({
      ...state,
      scheduleInterval: parseInt(value),
    })

    updateWaitUntilInSegmentStep({ ...step, schedule: { ...step.schedule, interval: parseInt(value) } })
  }

  const notAfterToggle = () => {
    const hasEndTime = !state.hasEndTime
    const [hourStr, minuteStr] = state.scheduledEndTime.split(':')
    const { ampm, hour, minute } = getTimeParts(hourStr, minuteStr)

    setState({
      ...state,
      hasEndTime,
    })

    updateWaitUntilInSegmentStep({
      ...step,
      schedule: { ...step.schedule, hasEndTime, endHour: hour, endMinute: minute, endAmpm: ampm },
    })
  }

  const timeChange = (value: string | Date) => {
    const [hourStr, minuteStr] = value === '' || value === null ? ['0', '0'] : (value as string).split(':')
    const { ampm, hour, minute } = getTimeParts(hourStr, minuteStr)

    setState({
      ...state,
      scheduledTime: value as string,
    })

    updateWaitUntilInSegmentStep({ ...step, schedule: { ...step.schedule, hour, minute, ampm } })
  }

  const endTimeChange = (value: string | Date) => {
    const [hourStr, minuteStr] = value === '' || value === null ? ['0', '0'] : (value as string).split(':')
    const { ampm, hour, minute } = getTimeParts(hourStr, minuteStr)

    setState({
      ...state,
      scheduledEndTime: value as string,
    })

    updateWaitUntilInSegmentStep({ ...step, schedule: { ...step.schedule, endHour: hour, endMinute: minute, endAmpm: ampm } })
  }

  return (
    <div className={`${rootClass}`} data-test={dataTest}>
      <FormGroup>
        <ConditionalSelect
          dataTest={`${dataTest}-conditional-select`}
          step={step}
          register={register}
          formReset={formReset}
          formData={formData}
          program={program}
          onProgramSourcesUpdate={onProgramSourcesUpdate}
          onMessageUpdate={onMessageUpdate}
          disableAddMessage={true}
          allowedMessageCategories={[MessageType.SENT, MessageType.SCHEDULED, MessageType.PROGRAM]}
        />
      </FormGroup>
      <FormRow>
        <Label>{t('Wait Until')}</Label>
        <div className={`${rootClass}__row`}>
          <Select
            dataTest={`${dataTest}-schedule-select`}
            className={`${rootClass}__select-large`}
            name="schedule.type"
            defaultValue={state.scheduleType || ScheduleType.DAILY}
            register={register('schedule.type', {
              onChange: scheduleChange,
            })}
          >
            <option value={ScheduleType.DAILY}>{t('Specific Times Every Day')}</option>
            <option value={ScheduleType.WEEKLY}>{t('Specific Day Of Week')}</option>
            <option value={ScheduleType.MONTHLY}>{t('Specific Day Of Month')}</option>
          </Select>
          {state.scheduleType === ScheduleType.DAILY && (
            // eslint-disable-next-line jsx-a11y/label-has-associated-control
            <label className={`${rootClass}__label-checkbox`}>
              <Checkbox
                dataTest={`${dataTest}-checkbox-weekdays`}
                register={register('schedule.weekdaysOnlySegment')}
                onChange={(checked: boolean) => {
                  updateWaitUntilInSegmentStep({ ...step, schedule: { ...step.schedule, weekdaysOnly: checked } })
                }}
                defaultChecked={step.schedule.weekdaysOnly === true || false}
                label={t('Weekdays Only')}
                name="schedule.weekdaysOnlySegment"
              />
            </label>
          )}
        </div>
      </FormRow>
      {state.scheduleType === 'DAILY' && (
        <>
          <FormRow>
            <div className={`${rootClass}__row`}>
              <div className={`${rootClass}__col`}>
                <Label>{t('Proceed On')}</Label>
                <Select
                  dataTest={`${dataTest}-interval-select`}
                  className={`${rootClass}__select-large`}
                  name="schedule.interval.DAILY"
                  defaultValue={state.scheduleInterval?.toString() || '0'}
                  register={register('schedule.interval.DAILY', {
                    onChange: intervalChange,
                  })}
                >
                  <option value="0">{t('Once Daily')}</option>
                  <option value="1">{t('Every Hour')}</option>
                  <option value="2">{t('Every 2 Hours')}</option>
                  <option value="3">{t('Every 3 Hours')}</option>
                  <option value="4">{t('Every 4 Hours')}</option>
                  <option value="5">{t('Every 5 Hours')}</option>
                  <option value="6">{t('Every 6 Hours')}</option>
                  <option value="7">{t('Every 7 Hours')}</option>
                  <option value="8">{t('Every 8 Hours')}</option>
                  <option value="9">{t('Every 9 Hours')}</option>
                  <option value="10">{t('Every 10 Hours')}</option>
                  <option value="11">{t('Every 11 Hours')}</option>
                  <option value="12">{t('Every 12 Hours')}</option>
                </Select>
              </div>
              <div className={`${rootClass}__col-notfirst`}>
                <TimeInput value={state.scheduledTime} onChange={timeChange} required label={t('Starting At')} name="scheduledTime" />
              </div>
              <div className={`${rootClass}__col-notfirst`}>
                <Label>{t('Timezone')}</Label>
                <Input defaultValue={program.timeZoneId} readOnly={true} />
              </div>
            </div>
            <div className={`${rootClass}__row`}>{errors.scheduledTime && <span className="error">{t(errors.scheduledTime.message)}</span>}</div>
            <div className={`${rootClass}__row`}>{errors.ampm && <span className="error">{t(errors.ampm.message)}</span>}</div>
          </FormRow>
          {!!state.scheduleInterval && state.scheduleInterval >= 1 && (
            <>
              <FormRow>
                <div className={`${rootClass}__row`}>
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className={`${rootClass}__label-checkbox`}>
                    <Checkbox
                      register={register('hasEndTime')}
                      defaultChecked={step.schedule.hasEndTime === true ?? false}
                      label={t('Not After')}
                      name="hasEndTime"
                      onChange={notAfterToggle}
                      dataTest={`${dataTest}-not-after`}
                    />
                  </label>
                </div>
              </FormRow>
              <FormRow>
                <div className={`${rootClass}__row`}>
                  <div className={`${rootClass}__col-notfirst`}>
                    <TimeInput
                      value={state.scheduledEndTime}
                      onChange={endTimeChange}
                      required={state.hasEndTime}
                      disabled={!state.hasEndTime}
                      name="scheduledEndTime"
                    />
                  </div>
                </div>
                <div className={`${rootClass}__row`}>
                  {errors.scheduledEndTime && <span className="error">{t(errors.scheduledEndTime.message)}</span>}
                </div>
                <div className={`${rootClass}__row`}>{errors.endAmpm && <span className="error">{t(errors.endAmpm.message)}</span>}</div>
              </FormRow>
            </>
          )}
        </>
      )}
      {state.scheduleType === ScheduleType.WEEKLY && (
        <FormRow>
          <div className={`${rootClass}__row`}>
            <div className={`${rootClass}__col`}>
              <Label>{t('Proceed On')}</Label>
              <Select
                dataTest={`${dataTest}-interval-select`}
                className={`${rootClass}__select-large`}
                name="schedule.interval.WEEKLY"
                defaultValue={state.scheduleInterval?.toString() || '1'}
                register={register('schedule.interval.WEEKLY', {
                  onChange: intervalChange,
                })}
              >
                <option value="1">{t('Sundays')}</option>
                <option value="2">{t('Mondays')}</option>
                <option value="3">{t('Tuesdays')}</option>
                <option value="4">{t('Wednesdays')}</option>
                <option value="5">{t('Thursdays')}</option>
                <option value="6">{t('Fridays')}</option>
                <option value="7">{t('Saturdays')}</option>
              </Select>
            </div>
            <div className={`${rootClass}__col-notfirst`}>
              <TimeInput value={state.scheduledTime} onChange={timeChange} required label={t('At')} name="scheduledTime" />
            </div>
            <div className={`${rootClass}__col-notfirst`}>
              <Label>{t('Timezone')}</Label>
              <Input defaultValue={program.timeZoneId} readOnly={true} />
            </div>
          </div>
          <div className={`${rootClass}__row`}>{errors.scheduledTime && <span className="error">{t(errors.scheduledTime.message)}</span>}</div>
          <div className={`${rootClass}__row`}>{errors.ampm && <span className="error">{t(errors.ampm.message)}</span>}</div>
        </FormRow>
      )}
      {state.scheduleType === ScheduleType.MONTHLY && (
        <>
          <FormRow>
            <div className={`${rootClass}__row`}>
              <div className={`${rootClass}__col`}>
                <Label>{t('Proceed On')}</Label>
                <Select
                  dataTest={`${dataTest}-interval-select`}
                  className={`${rootClass}__select-large`}
                  name="schedule.interval.MONTHLY"
                  defaultValue={state.scheduleInterval?.toString() || '1'}
                  register={register('schedule.interval.MONTHLY', {
                    onChange: intervalChange,
                  })}
                >
                  <option value="1">{t('1st')}</option>
                  <option value="2">{t('2nd')}</option>
                  <option value="3">{t('3rd')}</option>
                  <option value="4">{t('4th')}</option>
                  <option value="5">{t('5th')}</option>
                  <option value="6">{t('6th')}</option>
                  <option value="7">{t('7th')}</option>
                  <option value="8">{t('8th')}</option>
                  <option value="9">{t('9th')}</option>
                  <option value="10">{t('10th')}</option>
                  <option value="11">{t('11th')}</option>
                  <option value="12">{t('12th')}</option>
                  <option value="13">{t('13th')}</option>
                  <option value="14">{t('14th')}</option>
                  <option value="15">{t('15th')}</option>
                  <option value="16">{t('16th')}</option>
                  <option value="17">{t('17th')}</option>
                  <option value="18">{t('18th')}</option>
                  <option value="19">{t('19th')}</option>
                  <option value="20">{t('20th')}</option>
                  <option value="21">{t('21st')}</option>
                  <option value="22">{t('22nd')}</option>
                  <option value="23">{t('23rd')}</option>
                  <option value="24">{t('24th')}</option>
                  <option value="25">{t('25th')}</option>
                  <option value="26">{t('26th')}</option>
                  <option value="27">{t('27th')}</option>
                  <option value="28">{t('28th')}</option>
                  <option value="29">{t('29th')}</option>
                  <option value="30">{t('30th')}</option>
                  <option value="31">{t('31st')}</option>
                </Select>
              </div>
              <div className={`${rootClass}__col-notfirst`}>
                <TimeInput value={state.scheduledTime} onChange={timeChange} required label={t('At')} name="scheduledTime" />
              </div>
              <div className={`${rootClass}__col-notfirst`}>
                <Label>{t('Timezone')}</Label>
                <Input defaultValue={program.timeZoneId} readOnly={true} />
              </div>
            </div>
            <div className={`${rootClass}__row`}>{errors.scheduledTime && <span className="error">{t(errors.scheduledTime.message)}</span>}</div>
            <div className={`${rootClass}__row`}>{errors.ampm && <span className="error">{t(errors.ampm.message)}</span>}</div>
          </FormRow>
        </>
      )}
      <FormRow>
        <div className={`${rootClass}__row`}>
          <Checkbox
            register={register('waitForScheduledRun')}
            onChange={(checked: boolean) => {
              updateWaitUntilInSegmentStep({
                ...step,
                waitForScheduledRun: checked,
              })
            }}
            defaultChecked={step.waitForScheduledRun === true ?? false}
            label={t(
              'If a contact meets the condition when they enter the step, do not proceed immediately. Wait until the scheduled time for them to proceed.'
            )}
            name="waitForScheduledRun"
            multiline
          />
        </div>
      </FormRow>
    </div>
  )
}

export default WaitUntilInSegmentStep
