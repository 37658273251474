import { Row } from 'react-table'

import { getFavoriteProps, getFavoriteSvg } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.utils'
import SvgNames from '@components/Svg/SvgNames'
import { HeaderAction, RowAction } from '@components/Table/Table'
import { SegmentRowActions, SegmentTableActions } from '@src/pages/ContactSegments/components/ContactSegmentsActions/Segments.actions'
import { Segment } from '@utils/contactSegments/contactSegments.utils'
export const getBouncesSegmentsHeaderActions = (
  rootClass: string,
  selectedSegments: Segment[],
  doTableAction: Function,
  hasRegularSegmentsSelected = false
): HeaderAction[] => [
  ...(!hasRegularSegmentsSelected
    ? [
        {
          ...getFavoriteProps(selectedSegments, rootClass),
          hasTooltip: true,
          onClick: () => doTableAction(SegmentTableActions.SET_FAVORITE),
        },
      ]
    : []),
  {
    label: 'Refresh count',
    hasTooltip: true,
    icon: SvgNames.refresh,
    onClick: () => doTableAction(SegmentTableActions.REFRESH_COUNT),
  },
]

export const getBouncesSegmentsRowActions = (
  rootClass: string,
  onActionClick: (action: SegmentRowActions, row: Row<Segment>) => void,
  allowToDelete: boolean,
  allowToDownload: boolean
): RowAction[] => [
  {
    label: ({ original: { isFavorite } }: Row<Segment>) => (isFavorite ? 'Remove Favorite' : 'Favorite'),
    icon: ({ original: { isFavorite } }: Row<Segment>) => getFavoriteSvg(isFavorite, rootClass),
    onClick: (row) => onActionClick(SegmentRowActions.SET_FAVORITE, row),
  },
  {
    label: 'Refresh count',
    icon: SvgNames.refresh,
    onClick: (row) => onActionClick(SegmentRowActions.REFRESH_COUNT, row),
  },
  {
    disabled: !allowToDownload,
    tooltipMessage: 'Ask your administrator for permission to do this',
    label: 'Export',
    icon: SvgNames.export,
    inDropdown: true,
    onClick: (row) => onActionClick(SegmentRowActions.EXPORT, row),
  },
  {
    disabled: !allowToDelete,
    tooltipMessage: allowToDelete ? 'Cannot delete all contacts of a system generated segment' : 'Ask your administrator for permission to do this',
    hasTooltip: true,
    label: 'Delete all contacts',
    icon: SvgNames.deleteAllContacts,
    inDropdown: true,
    onClick: (row) => onActionClick(SegmentRowActions.DELETE_ALL_CONTACTS, row),
  },
]
