import React, { FC, useState } from 'react'

import classNames from 'classnames'

import { ALL_ACTON_CONTACTS_SEGMENT_NAME } from '@complex/ContactSegments/SegmentInfoHoverCard/utils/SegmentInfoHoverCard.utils'
import { LinkTextButton, LinkTextButtonSize } from '@components/LinkTextButton/LinkTextButton'
import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import listExportSyncJobBySegmentId from '@graphql/microservices/entity-upload/listExportSyncJobBySegmentId'
import listImportSyncJobBySegmentId from '@graphql/microservices/entity-upload/listImportSyncJobBySegmentId'
import {
  ExportSyncJobDto,
  ImportSyncJobDto,
  ListExportSyncJobBySegmentIdQuery,
  ListExportSyncJobBySegmentIdQueryVariables,
  ListImportSyncJobBySegmentIdQuery,
  ListImportSyncJobBySegmentIdQueryVariables,
} from '@graphql/types/microservice/entity-upload-types'
import { filterNotEmptyArray } from '@utils/array'
import { ExecutionStatus, Segment } from '@utils/contactSegments/contactSegments.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'

import './FTPSyncSegmentDefinition.css'

interface FTPSyncSegmentDefinitionProps {
  className?: string
  dataTest?: string
  hasExport: boolean
  onFtpViewLogAction?: (target: string | undefined) => void
  segment: Segment
}

const rootClass = 'ftp-sync-segment-definition'
const MAX_FILES_TO_DISPLAY = 3

const FTPSyncSegmentDefinition: FC<FTPSyncSegmentDefinitionProps> = (props: FTPSyncSegmentDefinitionProps) => {
  const { dataTest = rootClass, className = '', hasExport, onFtpViewLogAction, segment } = props

  const [importSectionExpanded, setImportSectionExpanded] = useState(false)
  const [exportSectionExpanded, setExportSectionExpanded] = useState(false)

  const { client: entityUploadClient } = useMicroserviceClient({ serviceName: MicroserviceClients.ENTITY_UPLOAD })

  const { t } = useTranslation()

  const externalId = segment.name !== ALL_ACTON_CONTACTS_SEGMENT_NAME ? segment.externalId : undefined

  const { data: importData, loading: loadingImportData } = useQueryOnMount<
    ListImportSyncJobBySegmentIdQuery,
    ListImportSyncJobBySegmentIdQueryVariables
  >(listImportSyncJobBySegmentId, {
    client: entityUploadClient,
    variables: {
      segmentId: externalId,
    },
  })

  const { data: exportData, loading: loadingExportData } = useQueryOnMount<
    ListExportSyncJobBySegmentIdQuery,
    ListExportSyncJobBySegmentIdQueryVariables
  >(listExportSyncJobBySegmentId, {
    client: entityUploadClient,
    variables: {
      segmentId: externalId,
    },
  })

  const getImportLogSection = (job: ImportSyncJobDto, onFtpViewLogAction?: (importFileId: string) => void) => {
    const filteredJobExecutions = job.importSyncJobExecutionDTOS?.filter(filterNotEmptyArray).filter(({ importFileId }) => !!importFileId)
    if (filteredJobExecutions && filteredJobExecutions.length > 0 && onFtpViewLogAction) {
      const mostRecentJobExecutionImportFileId = filteredJobExecutions[0].importFileId
      if (mostRecentJobExecutionImportFileId) {
        return (
          <div className={`${rootClass}__ftp-sync-error`}>
            <div className={`${rootClass}__ftp-sync-error-text`}>
              <Typography text={job.statusMessage} type={TextType.BODY_TEXT_GRAY_SMALL} />
              <Svg name={SvgNames.fail} type={SvgType.ICON} />
            </div>
            <LinkTextButton
              text={t('View import log')}
              className={`${rootClass}__ftp-sync-error-link`}
              dataTest={`${dataTest}-view-log-button`}
              hideIconLeft
              size={LinkTextButtonSize.SMALL}
              onClick={() => onFtpViewLogAction(mostRecentJobExecutionImportFileId)}
            />
          </div>
        )
      }
    }
  }

  const getFtpSyncSection = (loadingSyncJobs: boolean, ftpSyncJobs: ImportSyncJobDto[] | ExportSyncJobDto[], isExport: boolean) => {
    const isExpanded = isExport ? exportSectionExpanded : importSectionExpanded
    const filesToRender = !isExpanded ? ftpSyncJobs.slice(0, MAX_FILES_TO_DISPLAY) : ftpSyncJobs

    return loadingSyncJobs ? (
      <>
        <Loader loaderType={LoaderTypes.row} />
        <div className={`${rootClass}__divider`} />
      </>
    ) : (
      filesToRender.length !== 0 && (
        <div className={`${rootClass}__ftp-sync`}>
          <Typography
            text={isExport ? 'EXPORT' : 'IMPORT'}
            type={TextType.BODY_TEXT_LIGHT_TINY}
            weight={TextWeight.MEDIUM}
            inline
            className={classNames(`${rootClass}__section-title`, `${rootClass}__ftp-sync-title`)}
          />
          {filesToRender.map((job) => (
            <div key={job.filePath}>
              <div className={`${rootClass}__ftp-sync-item`}>
                <Svg name={SvgNames.csvFile} type={SvgType.ICON} />
                <Typography
                  className={`${rootClass}__ftp-sync-item-text`}
                  text={t(
                    job.cron
                      ? `ListPage.ContactSegments.InfoHover.FtpSync${isExport ? '.Export' : ''}`
                      : 'ListPage.ContactSegments.InfoHover.FtpSync.Off'
                  )}
                  weight={TextWeight.MEDIUM}
                  tagComponents={{
                    Typography: (
                      <Typography
                        className={classNames('ellip', `${rootClass}__ftp-sync-item-path`)}
                        weight={TextWeight.MEDIUM}
                        text={(job.filePath || '').substring(1)}
                      />
                    ),
                  }}
                  inline
                />
                <Svg className={`${rootClass}__ftp-sync-status-icon`} name={job.cron ? SvgNames.reloadNoFill : SvgNames.optOut} type={SvgType.ICON} />
              </div>
              {!isExport && job.status === ExecutionStatus.Failed && getImportLogSection(job as ImportSyncJobDto, onFtpViewLogAction)}
            </div>
          ))}
          {ftpSyncJobs.length > MAX_FILES_TO_DISPLAY && (
            <LinkTextButton
              className={`${rootClass}__ftp-sync-error-link`}
              text={isExpanded ? t('- Show less') : t('+ Show more')}
              onClick={() => {
                if (isExport) {
                  setExportSectionExpanded(!exportSectionExpanded)
                } else {
                  setImportSectionExpanded(!importSectionExpanded)
                }
              }}
              dataTest={`${dataTest}-control`}
              hideIcons
              size={LinkTextButtonSize.SMALL}
            />
          )}
          <div className={`${rootClass}__divider`} />
        </div>
      )
    )
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {getFtpSyncSection(loadingImportData, (importData?.listImportSyncJobBySegmentId ?? []) as ImportSyncJobDto[], false)}
      {hasExport && getFtpSyncSection(loadingExportData, (exportData?.listExportSyncJobBySegmentId ?? []) as ExportSyncJobDto[], true)}
    </div>
  )
}

export default FTPSyncSegmentDefinition
