import { createContext, ReactNode } from 'react'

import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { Status } from '@components/StatusToast/StatusToast'
import { TableV2RowData } from '@components/TableV2/tableV2TS/interfaces'
import { SignatureAlgorithm } from '@graphql/types/microservice/webhooks-incoming-management-types'
import { Row } from '@tanstack/react-table'

export enum Source {
  Calendly = 'CALENDLY',
  Custom = 'CUSTOM',
}

export enum Channel {
  ActOnContacts = 'ACT_ON_CONTACTS',
  CustomTouchPoints = 'CUSTOM_TOUCH_POINTS',
}

export enum AuthenticationType {
  None = 'NONE',
  Basic = 'BASIC',
  Bearer = 'BEARER',
  Signature = 'SIGNATURE',
}

export enum Encoding {
  Base64 = 'BASE64',
  Hex = 'HEX',
  Plain = 'PLAIN',
}

export enum TimestampFormat {
  Rfc3339 = 'RFC3339',
  Unix = 'UNIX',
}

export type InputSignature = {
  hashAlgorithm: SignatureHashAlgorithm
  headerFormat: string
  headerName: string
  key: string
  keyEncoding: Encoding
  payloadFormat: string
  signatureAlgorithm: SignatureAlgorithm
  signatureEncoding?: Encoding
  timestampCheck?: boolean
  timestampFormat?: TimestampFormat
  timestampHeaderFormat?: string
  timestampHeaderName?: string
  timestampLeeway?: string
}

export enum SignatureHashAlgorithm {
  Md5 = 'MD5',
  Sha1 = 'SHA1',
  Sha256 = 'SHA256',
  Sha384 = 'SHA384',
  Sha512 = 'SHA512',
}

export interface ContentMapping {
  sourceField: string
  destinationField: string
  mappingFunction: string
  mappingFunctionParams: string
  mappingMetadata: string
}

export interface InputAuthentication {
  type: AuthenticationType
  username?: string
  password?: string
  token?: string
  signature?: InputSignature
}

export interface Authentication {
  type: AuthenticationType
  username?: string
  passwordHash?: string
  tokenHash?: string
  signature?: InputSignature
}

export interface IncomingWebhook extends TableV2RowData<IncomingWebhook> {
  id: string
  name: string
  description: string
  enabled: boolean
  tested: boolean
  url: string
  source: Source
  lastRequest: number
  authentication: Authentication
  channel: Channel
  contentMappings: ContentMapping[]
}

export enum IncomingWebhooksRowActions {
  EDIT = 'Edit webhook',
  TEST = 'Incoming.Webhooks.RowActions.Test',
  COPY = 'Incoming.Webhooks.RowActions.Copy',
  DELETE = 'Incoming.Webhooks.RowActions.Delete',
}

export interface CustomWebhookSignatureOptions {
  encodingOptions: SelectV2SingleOption[]
  hashAlgorithmOptions: SelectV2SingleOption[]
}

export type SignatureTemplate = {
  algorithms?: SignatureAlgorithm[]
  allAlgorithms?: SignatureAlgorithm[]
  allHashAlgorithms?: SignatureHashAlgorithm[]
  allKeyEncodings?: Encoding[]
  allTimestampFormats?: TimestampFormat[]
  description?: string
  hashAlgorithms?: SignatureHashAlgorithm[]
  headerFormat?: string
  headerName?: string
  keyEncodings?: Encoding[]
  name?: string
  payloadFormat?: string
  signatureEncoding?: Encoding
  signatureTemplateId?: string
  source?: Source
  timestampCheck?: boolean
  timestampFormat?: TimestampFormat
  timestampHeaderFormat?: string
  timestampHeaderName?: string
  timestampLeeway?: string
}

export type SignatureTemplateList = { [key in Source]: SignatureTemplate }

export interface IncomingWebhooksContainerState {
  isTestingNewWebhook?: boolean
  webhooks: IncomingWebhook[]
  showCreateWebhooksModal: boolean
  showInfoWebhookModal: boolean
  showTestWebhookModal: boolean
  enableAuthentication?: boolean
  enableContentMapping?: boolean
  signatureTemplateList?: SignatureTemplateList
  customWebhookSignatureOptions?: CustomWebhookSignatureOptions
  loading: boolean
  statusToast?: { statusMessage: string | ReactNode; status: Status }
  webhookToEdit?: IncomingWebhook
  webhookToTest?: IncomingWebhook
  webhookToViewInfo?: Row<IncomingWebhook>
  webhookToDelete?: Row<IncomingWebhook>
}

export const IncomingWebhooksContainerInitialState: IncomingWebhooksContainerState = {
  webhooks: [],
  showCreateWebhooksModal: false,
  showInfoWebhookModal: false,
  showTestWebhookModal: false,
  loading: false,
}

type Update = (fields: Partial<IncomingWebhooksContainerState>) => void

export interface IncomingWebhooksAPI {
  values: IncomingWebhooksContainerState
  update: Update
}

export const IncomingWebhooksContext = createContext<IncomingWebhooksAPI>({ values: IncomingWebhooksContainerInitialState } as any)
