import React, { ComponentProps, FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'

import './ButtonWithLoader.css'

interface ButtonWithLoaderProps extends Omit<ComponentProps<typeof Button>, 'buttonType'> {
  loading?: boolean
  buttonType?: ButtonType
}

const rootClass = 'button-with-loader'

const ButtonWithLoader: FC<ButtonWithLoaderProps> = (props: ButtonWithLoaderProps) => {
  const { loading, className, children, buttonType, onClick, ...buttonProps } = props
  return (
    <Button
      className={classNames(rootClass, className)}
      buttonType={buttonType || ButtonType.PRIMARY}
      onClick={(event) => !loading && onClick?.(event)}
      {...buttonProps}
    >
      {loading ? <Loader className={`${rootClass}__loader`} loaderType={LoaderTypes.row} /> : children}
    </Button>
  )
}

export default ButtonWithLoader
