import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'

import * as yup from 'yup'

import Button, { ButtonType } from '@components/Button/Button'
import FormRow from '@components/FormRow/FormRow'
import Input from '@components/Input/Input'
import { ModalBody, ModalFooter } from '@components/Modal'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { CrmNames } from '@const/crmNames'
import globals, { useTranslation } from '@const/globals'
import { Program, ProgramAdditionalEntity } from '@graphql/types/query-types'
import { yupResolver } from '@hookform/resolvers/yup'
import CreateInCRMDetail, {
  EntityFields,
  StaticRecordFields,
} from '@src/pages/programs/dashboard/components/ProgramSteps/components/CreateInCRMDetail/CreateInCRMDetail'
import { hasAOContactsSource } from '@utils/program/program'
import { ProgramCreateInCRMStepExt, Step } from '@utils/program/program.constants'

import EditCRMStep from './crmSteps/editCRM/EditCRMStep'

import './editCreateInCRMStep.css'

const rootClass = 'edit-create-in-crm-step'

export interface KeyValueWithId {
  key?: string
  value?: string
  id?: string
}

export interface AdditionalEntityWithId extends ProgramAdditionalEntity {
  id?: string
  fields?: KeyValueWithId[]
}

export interface ProgramCreateInCrmStepExtKV extends ProgramCreateInCRMStepExt {
  staticFields: KeyValueWithId[]
  additionalEntities: AdditionalEntityWithId[]
}

export interface State {
  step: ProgramCreateInCrmStepExtKV
}

interface Props {
  step: Step
  program: Program
  isRunning: boolean
  closeModal(): void
  submitId: string
  saveStepAndProgram(step: Step | null, program?: Program): void
  dataTest?: string
}

const schema = yup.object().shape({
  displayName: yup.string().required('Step Name is required.'),
})

export const handleFormSubmit = (data: any, step: ProgramCreateInCRMStepExt, props: Props) => {
  const { saveStepAndProgram } = props

  const newStep = {
    ...step,
    staticFields: step.staticFields ? step.staticFields.filter((val) => val.key !== undefined) : [],
    additionalEntities: step.additionalEntities
      ? step.additionalEntities
          .filter((val) => val.entityType !== undefined)
          .map((entity) => ({
            ...entity,
            fields: entity.fields ? entity.fields.filter((val) => val.key !== undefined) : [],
          }))
      : [],
  }

  saveStepAndProgram({
    ...newStep,
    ...data,
  })
}

const EditCreateInCRMStep: FC<Props> = (props: Props) => {
  const { step: baseStep, program, isRunning, closeModal, submitId, dataTest = 'edit-create-in-crm-step' } = props

  const createInCRMStep = baseStep as ProgramCreateInCRMStepExt

  const { t } = useTranslation()

  const [state, setState] = useState<State>({
    step: {
      ...createInCRMStep,
      pushType: createInCRMStep.pushType === 'Leads' && program.crm?.crmName === CrmNames.SALESFORCE ? 'Lead' : createInCRMStep.pushType,
      staticFields: createInCRMStep.staticFields
        ? createInCRMStep.staticFields.map((crmStep) => ({
            ...crmStep,
            id: globals.getUUID(),
          }))
        : [],
      additionalEntities: createInCRMStep.additionalEntities
        ? createInCRMStep.additionalEntities.map((additionalEntity) => ({
            ...additionalEntity,
            id: globals.getUUID(),
            fields: additionalEntity.fields
              ? additionalEntity.fields.map((field) => ({
                  ...field,
                  id: globals.getUUID(),
                }))
              : [],
          }))
        : [],
    },
  })

  const { step } = state

  const onSubmit = (data: any) => {
    handleFormSubmit(data, step, props)
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })

  if (!program || !program.crm?.crmName) return null

  const updateStep = (key: keyof ProgramCreateInCrmStepExtKV, value: any) => {
    setState({
      ...state,
      step: {
        ...step,
        [key]: value,
      },
    })
  }

  const updateStepWithMultipleValues = (fields: {}) => {
    setState({
      ...state,
      step: {
        ...step,
        ...fields,
      },
    })
  }

  const getBody = () => {
    return (
      <EditCRMStep
        step={step}
        updateStep={updateStep}
        updateStepWithMultipleValues={updateStepWithMultipleValues}
        register={register}
        hasAOContactsSource={hasAOContactsSource(program)}
      />
    )
  }

  if (program.crm?.crmModelNotCurrent) {
    return (
      <>
        <ModalBody className={rootClass}>
          <Typography
            text={t(`Your {{crmName}} data is not yet synchronized. Please visit Data Management in Settings and set up a recurring sync.`, {
              crmName: program.crm?.crmName,
            })}
            className={`${rootClass}__not-current`}
            dataTest={`${dataTest}__sync-warning`}
          />
        </ModalBody>
        <ModalFooter flexEnd>
          <Button buttonType={ButtonType.TERTIARY} onClick={closeModal} dataTest={`${dataTest}-close-button`}>
            {t('Close')}
          </Button>
        </ModalFooter>
      </>
    )
  }

  const renderForm = () => (
    <form data-test={dataTest} onSubmit={handleSubmit(onSubmit)}>
      <FormRow>
        <Input
          className={`${rootClass}__step-name`}
          label={t('Step Name')}
          defaultValue={step.displayName}
          name="displayName"
          register={register('displayName')}
        />
        {errors?.displayName && <span className="error">{t(errors.displayName.message)}</span>}
      </FormRow>
      {getBody()}
      <button type="submit" id={submitId} hidden />
    </form>
  )

  const renderView = () => (
    <>
      <Typography text={step.displayName} weight={TextWeight.MEDIUM} type={TextType.SECTION_HEADER} />
      <CreateInCRMDetail
        pushType={step.pushType}
        additionalEntities={step.additionalEntities as EntityFields[]}
        staticFields={step.staticFields as StaticRecordFields[]}
        insertAlways={step.insertAlways ?? false}
        pushNewRecords={step.pushNewRecords ?? false}
        updateExisting={step.updateExisting ?? false}
        updateMultipleMatches={step.updateMultipleMatches ?? false}
        useListPushPolicy={step.useListPushPolicy ?? false}
      />
    </>
  )

  return <ModalBody className={rootClass}>{isRunning ? renderView() : renderForm()}</ModalBody>
}

export default EditCreateInCRMStep
