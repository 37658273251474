import React from 'react'
import { useTranslation } from 'react-i18next'

import { SvgNames } from '@components/Svg'
import Svg, { SvgColor, SvgType } from '@components/Svg/Svg'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'

import { InputInfoProps } from '../InputTS/interface'
const defaultErrMessage = 'Error message'

export const InputInfo = ({ rootClass, error, inputInfo, errorKey }: InputInfoProps) => {
  const { errorText, helperText, hasIcon } = inputInfo ?? {}
  const { t } = useTranslation()

  if (error && !inputInfo?.enabled) {
    return (
      <Typography
        text={t(errorKey ? errorKey : defaultErrMessage)}
        dataTest={`${rootClass}__bottom-info-error-text`}
        type={TextType.ERROR_NEW}
        lineHeight={LineHeight.MEDIUM_SMALL}
      />
    )
  }

  return (
    <>
      {helperText && !error ? (
        <Typography
          text={helperText}
          dataTest={`${rootClass}__bottom-info`}
          className={`${rootClass}__bottom-info`}
          type={TextType.NORMAL_TEXT_GRAY}
        />
      ) : (
        error && (
          <div className={`${rootClass}__bottom-info-tooltip-wrapper`} data-test={`${rootClass}__bottom-info-tooltip-wrapper`}>
            {hasIcon && <Svg name={SvgNames.infoHover} fill={SvgColor.ERROR_TEXT} type={SvgType.LARGER_ICON} />}

            <Typography
              text={t(errorText || defaultErrMessage)}
              dataTest={`${rootClass}__bottom-info-error-text`}
              type={TextType.ERROR_NEW}
              lineHeight={LineHeight.MEDIUM_SMALL}
            />
          </div>
        )
      )}
    </>
  )
}
