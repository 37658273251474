import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import DropDown from '@components/DropDown'
import { DropDownType } from '@components/DropDown/DropDown'
import DropDownActions, { MenuItem } from '@components/DropDownActions/DropDownActions'
import InfoTooltip from '@components/InfoTooltip/InfoTooltip'
import InputV2 from '@components/InputV2/InputV2'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import TooltipButton from '@components/TooltipButton/TooltipButton'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { FieldDefinition } from '@graphql/types/mutation-types'
import { ProgramAdditionalEntity, StringKeyValue } from '@graphql/types/query-types'
import { FieldDefinitionWithValue } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.interfaces'
import { findFieldByKey } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/EditCRMStepV2.utils'
import { useFieldInputRenderer } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditCreateInCRMStep/crmSteps/components/EditCRMStepV2/utils/useFieldInputRenderer'

import './CRMFieldsSelector.css'

interface CRMFieldsSelectorProps {
  addFieldButtonText?: string
  availableFields: FieldDefinition[]
  className?: string
  dataTest?: string
  hasHelpers?: boolean
  header?: {
    title: string
    tooltip?: string
    onRemoveAll?: VoidFunction
  }
  noBackground?: boolean
  onAddField: (field: FieldDefinition) => void
  onChange: (field: FieldDefinitionWithValue) => void
  onRemoveField: (key: string) => void
  selectedFields?: StringKeyValue[]
  entityIndex?: number
  entity?: ProgramAdditionalEntity
}

const rootClass = 'crm-fields-selector'
const REMOVE_LAST_FIELD_REFERENCE_TIMEOUT = 500

const CRMFieldsSelector: FC<CRMFieldsSelectorProps> = (props: CRMFieldsSelectorProps) => {
  const {
    addFieldButtonText = 'EditCrmStepV2.FieldsSelector.AddField',
    availableFields,
    dataTest = rootClass,
    className = '',
    hasHelpers = false,
    header,
    noBackground = false,
    onAddField,
    onChange,
    onRemoveField,
    selectedFields = [],
    entityIndex = -1,
    entity,
  } = props

  const { t } = useTranslation()

  const [isFieldsDropdownOpen, setIsFieldsDropdownOpen] = useState(false)
  const [fieldSearch, setFieldSearch] = useState<string>()

  const lastInputFieldAddedIndexRef = useRef<number>()

  const fieldRenderer = useFieldInputRenderer({
    className: `${rootClass}__field`,
    dataTest,
    hasHelpers,
    entityIndex,
    entity,
  })

  const dropdownOptions = useMemo(() => {
    const isBeingSearched = (field: FieldDefinition) => !fieldSearch || field.displayName.toLowerCase().includes(fieldSearch.toLowerCase())
    const isAlreadySelected = ({ fieldName }: FieldDefinition) => selectedFields.some(({ key }) => key === fieldName)
    return availableFields.map<MenuItem>((field) => ({
      onClick: () => {
        onAddField(field)
        lastInputFieldAddedIndexRef.current = selectedFields.length
      },
      row: field,
      text: field.displayName,
      hidden: !isBeingSearched(field) || isAlreadySelected(field),
    }))
  }, [availableFields, fieldSearch, onAddField, selectedFields])

  const isDisabled = useMemo(() => dropdownOptions.every(({ hidden }) => hidden), [dropdownOptions])

  const onDropdownToggle = (isOpen: boolean) => {
    if (!isDisabled || !isOpen) {
      setIsFieldsDropdownOpen(isOpen)
    }
  }

  const registerInputField = useCallback(
    (index: number) => (element: HTMLElement | null) => {
      if (element && lastInputFieldAddedIndexRef.current === index) {
        element.focus()
        setTimeout(() => {
          lastInputFieldAddedIndexRef.current = undefined
        }, REMOVE_LAST_FIELD_REFERENCE_TIMEOUT)
      }
    },
    []
  )

  const renderNewFieldButton = () => {
    const toggleOpen = () => setIsFieldsDropdownOpen(!isFieldsDropdownOpen)
    return (
      <DropDown
        dataTest={`${dataTest}-dropdown`}
        className={`${rootClass}__dropdown`}
        isOpen={isFieldsDropdownOpen}
        toggleOpen={onDropdownToggle}
        type={DropDownType.STYLED}
        trigger={
          <Tooltip
            hide={!isDisabled}
            trigger={
              <Button buttonType={ButtonType.FLOAT_TEAL} disabled={isDisabled}>
                <Svg name={SvgNames.addRoundedNoFill} type={SvgType.ICON} />
                {t(selectedFields.length === 0 ? addFieldButtonText : 'EditCrmStepV2.FieldsSelector.AddAnotherField')}
              </Button>
            }
          >
            {t('EditCrmStepV2.FieldsSelector.AddAnotherField.Empty.Tooltip')}
          </Tooltip>
        }
      >
        <div className={`${rootClass}__dropdown-input-container`}>
          <InputV2
            className={`${rootClass}__dropdown-input`}
            placeholder={t('EditCrmStepV2.FieldsSelector.Search')}
            onChange={(event) => {
              setFieldSearch(event.target.value.trim())
            }}
          />
        </div>
        <DropDownActions className={`${rootClass}__dropdown-actions`} closeDropDown={toggleOpen} menuItems={dropdownOptions} />
      </DropDown>
    )
  }

  const renderField = ({ key = '', value = '' }: StringKeyValue, index: number) => {
    const field = findFieldByKey(key, availableFields)
    if (field) {
      return (
        <div className={`${rootClass}__field`} key={`field-${key}`} data-test={`${dataTest}-field-${index}`}>
          {fieldRenderer({ field, value, onChange, register: registerInputField(index) })}
          {!field.required && (
            <TooltipButton
              className={`${rootClass}__field-remove-button`}
              title={t('EditCrmStepV2.FieldsSelector.Input.Remove')}
              dataTest={`${dataTest}-field-${index}-remove-button`}
              buttonType={ButtonType.ICON}
              onClick={() => onRemoveField(key ?? '')}
            >
              <Svg name={SvgNames.deleteRounded} type={SvgType.ICON} fill={SvgColor.BLACK} />
            </TooltipButton>
          )}
        </div>
      )
    }
  }

  useEffect(() => {
    if (!isFieldsDropdownOpen) {
      setFieldSearch('')
    }
  }, [isFieldsDropdownOpen])

  if (selectedFields.length === 0) {
    return renderNewFieldButton()
  }

  return (
    <div className={classNames(rootClass, className, { [`${rootClass}__no-background`]: noBackground })} data-test={dataTest}>
      {header && (
        <div className={`${rootClass}__header`}>
          <div className={`${rootClass}__header-title`}>
            <Typography text={header.title} type={TextType.BODY_TEXT_LARGE} lineHeight={LineHeight.MEDIUM_LARGE} weight={TextWeight.MEDIUM} />
            {header.tooltip && <InfoTooltip text={header.tooltip} />}
          </div>
          {header.onRemoveAll && (
            <div className={`${rootClass}__header-action`}>
              <TooltipButton
                className={`${rootClass}__remove-all-button`}
                dataTest={`${dataTest}-remove-all-button`}
                onClick={header.onRemoveAll}
                title={t('EditCrmStepV2.FieldsSelector.RemoveAll')}
              >
                <Svg name={SvgNames.delete} type={SvgType.ICON} fill={SvgColor.BLACK} />
              </TooltipButton>
            </div>
          )}
        </div>
      )}
      <div className={`${rootClass}__body`}>
        <div className={`${rootClass}__body-fields-container`}>{selectedFields.map(renderField)}</div>
        <div className={`${rootClass}__body-button-container`}>{renderNewFieldButton()}</div>
      </div>
    </div>
  )
}

export default CRMFieldsSelector
