import React, { FC, useContext, useEffect, useState } from 'react'

import classNames from 'classnames'

import Accordion, { AccordionItemData } from '@components/Accordion/Accordion'
import Button, { ButtonType } from '@components/Button/Button'
import Container from '@components/Container'
import InfoText from '@components/InfoText'
import NumberBadge, { NumberBadgeType } from '@components/NumberBadge/NumberBadge'
import SectionHeadline from '@components/SectionHeadline'
import { SvgColor } from '@components/Svg/Svg'
import Toggle from '@components/Toggle'
import { useTranslation } from '@const/globals'
import { EntityTypeDisplay } from '@graphql/types/microservice/crm-types'
import { useAccountSettings } from '@utils/account/account.utils'
import useCRM from '@utils/hooks/useCRM'

import { DataManagementContext, RecordModalState } from '../../context/DataManagementContext'
import EntityLoader from '../EntityLoader/EntityLoader'

import './CrmEntity.css'

type Props = {
  className?: string
  dataTest?: string
  entities: EntityTypeDisplay[]
  updateEntities: () => void
}

const rootClass = 'crm-entity'

const CrmEntity: FC<Props> = (props: Props) => {
  const { entities, dataTest = rootClass, className = rootClass, updateEntities } = props
  const { t } = useTranslation()
  const { update } = useContext(DataManagementContext)
  const { crmSelectEntitiestoSync } = useAccountSettings()
  const { connectorType } = useCRM()
  const [initialPageLoad, setPageLoad] = useState(true)
  const [numSelectedColumns, setNumSelectedColumns] = useState<number>(0)
  const [maxColumns, setMaxColumns] = useState<number>(0)
  useEffect(() => {
    if (!initialPageLoad) {
      updateEntities()
    } else {
      setPageLoad(false)
    }
  }, [initialPageLoad])

  const updateModal = (openRecordModal: boolean, modalEntityName: string, modalEntityIdentifier: string, modalRecordCount: number) => {
    const modalState: RecordModalState = { openRecordModal, modalEntityName, modalEntityIdentifier, modalRecordCount }
    update('recordModal', modalState)
  }

  const accordionContentNode = (entityType: string) => (
    <EntityLoader
      entityType={entityType}
      onMaxColumnsChange={(maxColumns: number, totalColumns: number) => {
        setNumSelectedColumns(maxColumns)
        setMaxColumns(totalColumns)
      }}
    />
  )
  const accordionData: AccordionItemData[] = entities
    .filter((entity) => entity.visible)
    .map((entity, index) => {
      return {
        index: index.toString(),
        header: (
          <div className={`${className}__row`}>
            <div className={`${className}__entity-display`}>
              {entity.display}
              {!crmSelectEntitiestoSync && (
                <Button
                  buttonType={ButtonType.TEXT}
                  className={`${rootClass}__pill`}
                  onClick={() => {
                    updateModal(true, entity.display ? entity.display : '', entity.identifier ? entity.identifier : '', entity.recordCount)
                  }}
                >
                  <NumberBadge value={entity.recordCount} maxValue={false} type={NumberBadgeType.PRIMARY} roundValue={false} />
                </Button>
              )}
              {crmSelectEntitiestoSync && (
                <div className={`${className}__selected-fields-count`}>
                  <span>
                    {t('CRM.EntityFieldsSelected', {
                      count: numSelectedColumns,
                      total: maxColumns,
                    })}
                  </span>
                </div>
              )}

              {crmSelectEntitiestoSync && (
                <Button
                  buttonType={ButtonType.TEXT}
                  className={`${rootClass}__pill`}
                  onClick={() => {
                    updateModal(true, entity.display ? entity.display : '', entity.identifier ? entity.identifier : '', entity.recordCount)
                  }}
                >
                  <div className={`${rootClass}__pill-count`}>{t('{{count}} records', { count: entity.recordCount })}</div>
                </Button>
              )}
            </div>
            {crmSelectEntitiestoSync && (
              <Toggle
                className={`${rootClass}__toggle`}
                large
                isOn={true}
                onToggle={() => {
                  //updateEntity(entity.identifier, !entity.enabled)
                }}
              />
            )}
          </div>
        ),
        disabled: false,
        content: accordionContentNode(entity.identifier ? entity.identifier : ''),
      }
    })
  return (
    <div
      className={classNames(rootClass, className, {
        'crm-entity--redesigned': crmSelectEntitiestoSync,
      })}
      data-test={dataTest}
    >
      <Container className={`${className}__outer`}>
        <SectionHeadline>{t('CRM Entity Field Selection')}</SectionHeadline>
        <div className={`${rootClass}__field_selection`}>
          <InfoText className={`${rootClass}__info-text`}>
            {crmSelectEntitiestoSync
              ? t('CRM.EntityFieldsSelected.Desc', { CRM: connectorType })
              : t('Set up entities from your CRM to make fields available in Act-On.')}
          </InfoText>
          <hr className={`${rootClass}__divider`} />
          <Accordion childData={accordionData} defaultValue={'0'} className={`${rootClass}__entity-data`} chevronFillColor={SvgColor.LIGHT_GRAY} />
        </div>
      </Container>
    </div>
  )
}

export default CrmEntity
