import React, { FC } from 'react'

import ModalHeaderV2 from '@components/Modal/components/ModalHeaderV2/ModalHeaderV2'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import { ModalHeaderFormStyle } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

interface TemplateCatalogModalHeader {
  onBack: () => void
  onSearch: (term: string) => void
  searchTerm: string
  isPreview?: boolean
  dataTest?: string
  hideSearch?: boolean
}

const rootClass = 'template-catalog-modal__header'

const TemplateCatalogModalHeader: FC<TemplateCatalogModalHeader> = ({
  onBack,
  onSearch,
  searchTerm,
  isPreview,
  hideSearch,
  dataTest = rootClass,
}) => {
  const { t } = useTranslation()

  return (
    <ModalHeaderV2
      iconProps={{ hasBackButton: isPreview, onClick: onBack }}
      headerType="list"
      className={rootClass}
      headerText={
        <TextWithTooltipOnEllip
          typographyProps={{
            text: t(isPreview ? 'Preview.Template.Modal.Title' : 'Template.Catalog'),
            ...ModalHeaderFormStyle,
            className: `${rootClass}__title`,
            dataTest: `${dataTest}-title`,
          }}
        />
      }
      {...(!hideSearch && {
        searchProps: {
          placeholder: t('Search template catalog'),
          incomingValue: searchTerm,
          onChangeHandler: onSearch,
          canClear: true,
          className: `${rootClass}__search`,
        },
      })}
    />
  )
}

export default TemplateCatalogModalHeader
