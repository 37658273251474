import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { RenderDetailComponent } from '@src/pages/programs/dashboard/components/ProgramSteps/ProgramSteps.utils'
import { ExpandableSteps } from '@src/pages/programs/dashboard/components/ProgramSteps/ProgramStepsContainer'
import ProgramStepIcon from '@src/pages/programs/edit/components/ProgramFlow/components/ProgramStepIcon'
import { ProgramBaseStepExt, ProgramStepType, Step } from '@utils/program/program.constants'
import { ProgramWithStepData } from '@utils/program/ProgramSteps.constants'

import './ExpandableStepDescription.css'

interface Props {
  step: Step
  errorMessage?: string
  program: ProgramWithStepData
  moveNext: (stepId: string) => void
  className?: string
  dataTest?: string
  expansion?: ExpandableSteps
  handleSetAbWinner?: (programId: string, stepId: string, msgId: string, name: string) => void
  handleResetAbTest?: (programId: string, stepId: string) => void
}

const rootClass = 'expandable-step-description'

const ExpandableStepDescription: FC<Props> = (props: Props) => {
  const { step, errorMessage, program, moveNext, expansion = {}, handleSetAbWinner, handleResetAbTest } = props
  const isExit = step.stepType === ProgramStepType.EXIT
  const [isExpanded, setExpanded] = useState(false)

  useEffect(() => {
    Object.entries(expansion).forEach(([key, value]) => {
      if (key === step.stepId) {
        setExpanded(value)
      }
    })
  }, [expansion])

  // NOTE: This is an artifical use of the hook, to avoid getting 'different hooks called from previous render' error
  const { t } = useTranslation()

  const onExpanderClicked = () => {
    setExpanded(!isExpanded)
  }

  return (
    <div className={`${rootClass}`}>
      <div className={`${rootClass}__description`}>
        <div className={`${rootClass}__step-svg`}>
          <ProgramStepIcon step={{ stepType: step.stepType } as ProgramBaseStepExt} />
        </div>
        {isExit && <Typography text={t(step.displayName)} weight={TextWeight.MEDIUM} inline className={`${rootClass}__step-exit`} />}
        {!isExit && (
          <button className={`${rootClass}__expander`} onClick={onExpanderClicked} data-test={`${rootClass}-expander`}>
            <Svg
              className={isExpanded ? `${rootClass}__expand-toggled` : `${rootClass}__expand-toggle`}
              name={SvgNames.caretFillRight}
              type={SvgType.VERY_SMALL_ICON}
              dataTest={isExpanded ? `${rootClass}-expand-toggled` : `${rootClass}-expand-not-toggled`}
            />
            <Tooltip trigger={<Typography text={step.displayName} weight={TextWeight.MEDIUM} inline />} position={'top'}>
              {step.displayName}
            </Tooltip>
          </button>
        )}
        {errorMessage && (
          <div className={`${rootClass}__error`}>
            <Tooltip trigger={<Svg name={SvgNames.error} type={SvgType.ICON} />} position={'top'}>
              {errorMessage}
            </Tooltip>
          </div>
        )}
      </div>
      <div
        className={classNames(`${rootClass}__expansion`, {
          [`${rootClass}-show`]: isExpanded,
          [`${rootClass}-no-show`]: !isExpanded,
        })}
      >
        <RenderDetailComponent step={step} program={program} moveNext={moveNext} setAbWinner={handleSetAbWinner} resetAbTest={handleResetAbTest} />
      </div>
    </div>
  )
}

export default ExpandableStepDescription
