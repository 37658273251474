import React, { FC, Key, useCallback, useContext, useMemo } from 'react'

import classNames from 'classnames'

import Container from '@components/Container'
import RadioCard, { RadioCardProps, STYLE } from '@components/RadioCard/RadioCard'
import RadioCardGroup from '@components/RadioCardGroup/RadioCardGroup'
import Toggle from '@components/Toggle'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ArchiveSettings, FormsUpgradeModalContext } from '@src/pages/UpgradeAssistant/components/FormsUpgradeModal/context/FormsUpgradeModal.context'

import './FormsUpgradeConfigure.css'

interface FormsUpgradeConfigureProps {
  className?: string
  dataTest?: string
}

const rootClass = 'forms-upgrade-configure'
const FORMS_UPGRADE_CONFIGURE_TEXT = 'Forms.Upgrade.Configure.'

const FormsUpgradeConfigure: FC<FormsUpgradeConfigureProps> = (props: FormsUpgradeConfigureProps) => {
  const { dataTest = rootClass, className = '' } = props
  const {
    update,
    values: { archiveSetting, createSegment },
  } = useContext(FormsUpgradeModalContext)
  const { t } = useTranslation()

  const onCreateSegmentToggle = useCallback((isOn: boolean) => update({ createSegment: isOn }), [update])

  const onSelect = useCallback((key: Key) => update({ archiveSetting: key as ArchiveSettings }), [update])

  const archiveSettings: (RadioCardProps & { key: ArchiveSettings })[] = useMemo(
    () => [
      {
        key: ArchiveSettings.NOTHING,
        title: t(`${FORMS_UPGRADE_CONFIGURE_TEXT}Nothing.Title`),
        description: t(`${FORMS_UPGRADE_CONFIGURE_TEXT}Nothing.Description`),
      },
      {
        key: ArchiveSettings.ARCHIVE,
        title: t(`${FORMS_UPGRADE_CONFIGURE_TEXT}Archive.Title`),
        description: t(`${FORMS_UPGRADE_CONFIGURE_TEXT}Archive.Description`),
      },
      {
        key: ArchiveSettings.DELETE,
        title: t(`${FORMS_UPGRADE_CONFIGURE_TEXT}Delete.Title`),
        description: t(`${FORMS_UPGRADE_CONFIGURE_TEXT}Delete.Description`),
      },
    ],
    [t]
  )

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Container>
        <Typography text={t(`${FORMS_UPGRADE_CONFIGURE_TEXT}ContactDataSettings.Title`)} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
        <Typography
          className={`${rootClass}__subtitle`}
          text={t(`${FORMS_UPGRADE_CONFIGURE_TEXT}ContactDataSettings.Subtitle`)}
          type={TextType.BODY_TEXT_LIGHT}
        />
        <div className={`${rootClass}__toggle-container`}>
          <Toggle
            isOn={createSegment}
            onToggle={onCreateSegmentToggle}
            label={t(`${FORMS_UPGRADE_CONFIGURE_TEXT}ContactDataSettings.CreateSegment`)}
            large
          />
          {/* TODO the new toggle should be added here, inside the toggle-container div and right below the first toggle */}
        </div>
      </Container>
      <Container>
        <Typography text={t(`${FORMS_UPGRADE_CONFIGURE_TEXT}ArchiveSettings.Title`)} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
        <Typography
          className={`${rootClass}__subtitle`}
          text={t(`${FORMS_UPGRADE_CONFIGURE_TEXT}ArchiveSettings.Subtitle`)}
          type={TextType.BODY_TEXT_LIGHT}
        />
        <RadioCardGroup onSelect={onSelect} selectedOption={archiveSetting}>
          {archiveSettings.map(({ key, ...option }) => (
            <RadioCard key={key} {...option} style={STYLE.RADIO_OUT} titleWeight={TextWeight.MEDIUM} />
          ))}
        </RadioCardGroup>
      </Container>
    </div>
  )
}

export default FormsUpgradeConfigure
