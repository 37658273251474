import { gql } from '@apollo/client'

export default gql`
  mutation copyClassicSegment($segmentMapping: SegmentMappingInput, $type: String, $username: String) {
    copyClassicSegment(segmentMapping: $segmentMapping, type: $type, username: $username) {
      newExternalIds
      segmentCopyLogs {
        copyId
        dateTime
        fails
        inProgress
        names
        parent
        status
        success
        user
      }
    }
  }
`
