import React, { MutableRefObject, ReactNode } from 'react'
import { Row } from 'react-table'

import equal from 'fast-deep-equal/es6/react'

import { getHasToExpandFunction } from '@complex/ListPickerModalV2/utils/ListPickerModal.utils.expand'
import { selectedListsInitialState, unifiedListTypes } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import { customSubRowSelection, getColumns, getUCLColumns, rowFinder } from '@complex/ListPickerModalV2/utils/ListPickerModalTableUtils'
import { disableListUtils, hasLegacyListsSelectedUtils } from '@complex/ListPickerModalV2/utils/ListPickerModalUtils'
import ActionableNestedTable from '@components/ActionableNestedTable/ActionableNestedTable'
import { ActionableNestedTableWithLazyLoadingProps } from '@components/ActionableNestedTableWithLazyLoading/ActionableNestedTableWithLazyLoading'
import { FolderSearchOption } from '@components/FolderSearch/components/FolderSearchFilterButtons/FolderSearchFilterButtons'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { SvgNames } from '@components/Svg'
import Typography, { LineHeight, TextWeight } from '@components/Typography/Typography'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import { Folder } from '@interface/Folder'
import { CommonFilters, FilterDefinition, FilterTypes } from '@utils/filter'
import { GET_LIST_CATEGORIES, LIST_CATEGORY_FILTERS, ListCategory } from '@utils/lists'

import {
  DisableRowByCriteria,
  ListPickerModalState,
  PreProcessedList,
  ShowTooltipByCriteria,
} from '../../../utils/interfaces/ListPickerModalInterfaces'

export const ALL_LISTS = 'All Lists'
export const getSearchAllListsOption = (active: boolean): FolderSearchOption => ({
  name: ALL_LISTS,
  icon: SvgNames.segmentsUnselected,
  active,
})

export const getSearchEmptyStates = (t: Function) => ({
  headline: t('No results found'),
  imgSrc: StaticImageNames.emptySearch,
  text: t('There were no results matching your search.'),
})

interface GetSearchOptions {
  activeFilter: string
  currentFilter?: FilterDefinition
  currentFolder?: Folder
  currentTag?: LabelDto
  listType: ListCategory
}

export const getMainFilterOption = (activeFilter: string, filterName: string, listType: ListCategory) => {
  const mainFilterName =
    listType === ListCategory.UNIFIED_LIST
      ? FilterTypes.ALL_SEGMENTS
      : listType === ListCategory.FORM_SUBMISSIONS_UCL
      ? FilterTypes.ALL_FORM_SUBMISSIONS
      : filterName
  return {
    name: mainFilterName,
    icon: SvgNames.segmentsUnselected,
    active: activeFilter.toLowerCase().includes(mainFilterName.toLowerCase()),
    searchAll: true,
  }
}

const getBaseOptions = ({ activeFilter, currentFilter, currentFolder, currentTag, listType }: GetSearchOptions, filterName: string) => ({
  [typeof currentFolder]: {
    name: currentFolder?.name === '&nbsp;' ? 'Default Folder' : currentFolder?.name ?? '',
    icon: SvgNames.folder,
    active: activeFilter ? activeFilter === currentFolder?.name : true,
  },
  [typeof currentTag]: {
    name: currentTag?.name ?? '',
    icon: SvgNames.tag,
    active: activeFilter ? activeFilter === currentTag?.name : true,
    color: currentTag?.color,
  },
  [typeof currentFilter]: {
    name:
      listType === ListCategory.UNIFIED_LIST || Object.values(FilterTypes).includes(currentFilter?.name as FilterTypes)
        ? (currentFilter?.name as string)
        : filterName,
    icon: currentFilter?.svgUnselected ?? SvgNames.segmentsUnselected,
    active: !activeFilter || activeFilter === currentFilter?.name,
  },
})

export const getSearchOptions = (values: GetSearchOptions, showAllListsOption = true): FolderSearchOption[] => {
  const { activeFilter, currentFilter, currentFolder, currentTag, listType } = values
  const filter = currentTag ?? currentFolder ?? currentFilter
  if (filter && !equal(filter, LIST_CATEGORY_FILTERS[listType])) {
    const filterName = GET_LIST_CATEGORIES().find(({ type }) => type === listType)?.text as string
    const mainFilterOption = getMainFilterOption(activeFilter, filterName, listType)

    const options: { [key: string]: FolderSearchOption } = getBaseOptions(values, filterName)

    const showMainFilter =
      currentTag || currentFolder || [...Object.values(CommonFilters), FilterTypes.CONTACT_PREFERENCES].includes(currentFilter?.name as any)
    return [
      ...(showMainFilter ? [options[typeof filter]] : []),
      mainFilterOption,
      ...(showAllListsOption ? [getSearchAllListsOption(activeFilter === ALL_LISTS)] : []),
    ]
  }
  return []
}

interface GetSearchTablePropsParams {
  disableCheckboxHeader: boolean
  disabledListTooltipText?: string
  lists: PreProcessedList[]
  listType: ListCategory
  multiSelect: boolean
  onRowSelectionChanged: (selectedRows: Row[], rows?: Row[]) => void
  values: ListPickerModalState
  showTooltipByCriteria?: ShowTooltipByCriteria
}

export const getSearchTableProps = ({
  disabledListTooltipText,
  disableCheckboxHeader,
  lists,
  listType,
  multiSelect,
  onRowSelectionChanged,
  values: { selectedLists, search, selectAllSegmentsOfSelectedList },
  values,
  showTooltipByCriteria,
}: GetSearchTablePropsParams): ActionableNestedTableWithLazyLoadingProps => ({
  allLoaded: true,
  autoResetSelectedRows: false,
  checkboxWidth: 16,
  columns: unifiedListTypes.includes(listType)
    ? getUCLColumns({ searchValue: search?.query, listType, searchHierarchy: search?.parentsHierarchy, showTooltipByCriteria })
    : getColumns({ searchValue: search?.query, listType, searchHierarchy: search?.parentsHierarchy, showTooltipByCriteria }),
  customRowFinder: rowFinder,
  customSubRowSelection: selectAllSegmentsOfSelectedList ? customSubRowSelection : undefined,
  data: lists,
  defaultSelectedRows: Object.values(selectedLists[listType]),
  disableCheckboxHeader,
  disabledCheckboxTooltipText: disabledListTooltipText,
  hasToExpand: getHasToExpandFunction(values),
  isSingleSelect: !multiSelect,
  loading: search?.loading ?? false,
  onLoading: () => false,
  onRowSelectionChanged,
  useHeaderCheckbox: multiSelect,
  useCheckboxes: true,
})

type GetUCLSearchTablePropsParams = GetSearchTablePropsParams

export const getUCLSearchTableProps = ({ values, ...rest }: GetUCLSearchTablePropsParams): ActionableNestedTableWithLazyLoadingProps => {
  const { currentTag, tagsState } = values
  const baseProps = getSearchTableProps({ values, ...rest })
  return {
    ...baseProps,
    readOnlyTags: true,
    selectedTagId: currentTag?.id,
    tags: tagsState.tags,
  }
}

interface GetAllListsResultsTablesUtilsParams {
  activeFilter: string
  checkboxCellOverride?: (row: Row) => ReactNode
  disabledLists: string[]
  disabledListTooltipText?: string
  disableRowByCriteria: DisableRowByCriteria | undefined
  lastSelectedRef: MutableRefObject<string>
  multiSelect: boolean
  onRowSelectionChangedInAllLists: Function
  restrictMixingLegacyListsAndUCL: boolean
  rootClass: string
  values: ListPickerModalState
}

export const getAllListsResultsTablesUtils = ({
  activeFilter,
  checkboxCellOverride,
  disabledLists,
  disabledListTooltipText = '',
  disableRowByCriteria,
  lastSelectedRef,
  multiSelect,
  onRowSelectionChangedInAllLists,
  restrictMixingLegacyListsAndUCL,
  rootClass,
  values,
}: GetAllListsResultsTablesUtilsParams) => {
  const { search, selectedLists } = values
  const { lists: searchLists = { ...selectedListsInitialState } } = search || {}
  return Object.entries(searchLists)
    .filter((listValues: [string, any[]]) => listValues[1].length)
    .map((listValues: [string, any[]]) => {
      const { text, type } = GET_LIST_CATEGORIES().find(({ type }) => type === listValues[0]) || {}
      const listCategoryType = type as ListCategory
      const disableLegacyLists =
        !unifiedListTypes.includes(listCategoryType) &&
        (!!selectedLists[ListCategory.UNIFIED_LIST].length || !!selectedLists[ListCategory.FORM_SUBMISSIONS_UCL].length)
      const disableLists =
        restrictMixingLegacyListsAndUCL &&
        (unifiedListTypes.includes(listCategoryType) ? hasLegacyListsSelectedUtils(selectedLists) : disableLegacyLists)

      const rowSelectionChanged = (selectedRows: Row[], rows?: Row[]) =>
        onRowSelectionChangedInAllLists(selectedRows, rows as Row[], listCategoryType)

      const params = {
        activeFilter,
        disableCheckboxHeader: disableLists,
        disabledListTooltipText: (!disableLists && disabledListTooltipText) as string,
        lists: disableListUtils(listValues[1] as any[], disabledLists, disableLists, disableRowByCriteria),
        listType: listCategoryType,
        multiSelect,
        onRowSelectionChanged: rowSelectionChanged,
        values,
      }

      lastSelectedRef.current = Object.entries(selectedLists)
        .filter((list) => list[1].length)
        .map((list) => list[0])[0]

      const tableProps = {
        ...(unifiedListTypes.includes(listCategoryType) ? getUCLSearchTableProps(params) : getSearchTableProps(params)),
        checkboxCellOverride,
        forceResetSelectedRows: !multiSelect && !!lastSelectedRef.current && lastSelectedRef.current !== listCategoryType,
      }

      return (
        <div className={`${rootClass}__list`} key={type}>
          <Typography className={`${rootClass}__list-label`} text={text} weight={TextWeight.BOLD} lineHeight={LineHeight.MEDIUM} />
          <ActionableNestedTable className={`${rootClass}__table`} {...tableProps} />
        </div>
      )
    })
}
