import { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { BeeEmailContentDto, BeeTemplatesCategory, GetBeeTemplateCategoriesQuery } from '@graphql/types/microservice/email-management-types'
import { BeeTemplate } from '@graphql/types/query-types'
import { rootContext } from '@utils/const/globals'

export const TEMPLATES_DEFAULT_PAGE_SIZE = 50

export interface CatalogFolderItem {
  parent: string
  categories: BeeTemplatesCategory[] | undefined
}

export const getCategoriesFromResponse = (data: GetBeeTemplateCategoriesQuery): CatalogFolderItem[] => {
  const result =
    data.getBeeTemplateCategories?.result?.reduce<CatalogFolderItem[]>((acc, curr) => {
      if (curr) {
        acc.push({
          parent: curr.parent ?? '',
          categories: curr.categories?.filter((category): category is BeeTemplatesCategory => category !== undefined) || undefined,
        })
      }
      return acc
    }, []) || []

  return result
}

export const navigateToBeeComposer = (id: string) => {
  window.open(`${rootContext}/content/emails/${id}?messageConfigurationType=template`)
}

export const labelProps = {
  lineHeight: LineHeight.MEDIUM,
  weight: TextWeight.MEDIUM,
}

export const buttonProps = {
  type: TextType.BODY_TEXT_SMALL,
  lineHeight: LineHeight.MEDIUM_SMALL,
  weight: TextWeight.MEDIUM,
}

export const mapToBeeTemplate = (item: BeeEmailContentDto): BeeTemplate => {
  return {
    id: item.templateId,
    title: item.title,
    thumbnail: item.thumbnailUrl,
    thumbnail_large: item.thumbnailLargeUrl,
    published_at: item.publishAt,
    categories: item.searchTerms,
  }
}
