import React, { FC, Key, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Modal, { ModalBody, ModalHeader, ModalFooter } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import RadioCard from '@components/RadioCard/RadioCard'
import RadioCardGroup from '@components/RadioCardGroup/RadioCardGroup'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { ModalBodyStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './CreateFormModal.css'

interface CreateFormModalProps {
  className?: string
  dataTest?: string
  onCancel: () => void
  onCreate: (method: CreateFormMethod) => void
  isOpen: boolean
  showClassicOptions?: boolean
  disableTemplateOption?: boolean
}

export enum CreateFormMethod {
  NEW = 'new',
  TEMPLATE = 'template',
  MIGRATE = 'migrate',
  CLASSIC = 'classic',
}

const rootClass = 'create-form-modal'

const CreateFormModal: FC<CreateFormModalProps> = (props: CreateFormModalProps) => {
  const { showClassicOptions, dataTest = rootClass, className = '', onCreate, onCancel, isOpen, disableTemplateOption } = props
  const [method, setMethod] = useState<Key>(CreateFormMethod.NEW)

  const { t } = useTranslation()

  const header = (
    <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__header`}>
      {t('CreateFormModal.Title')}
    </ModalHeader>
  )

  const renderClassicTitle = () => (
    <div className={`${rootClass}__title-with-icon`}>
      <Typography text={t('CreateFormModal.Options.Classic')} weight={TextWeight.MEDIUM} />
      <Tooltip
        alignTextCenter
        position={'right'}
        trigger={<Svg name={SvgNames.warningSolidNoFill} type={SvgType.MEDIUM} fill={SvgColor.YELLOW_CAUTION} />}
        className={`${rootClass}__tooltip`}
      >
        <Typography text={t('CreateFormModal.Options.Classic.Tooltip')} inline type={TextType.BODY_TEXT_WHITE} />
      </Tooltip>
    </div>
  )

  const createButtonText = [CreateFormMethod.MIGRATE, CreateFormMethod.TEMPLATE].includes(method as CreateFormMethod) ? 'Next' : 'Create'

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
      <ModalBody className={`${rootClass}__body`}>
        <Typography text={t('CreateFormModal.Info')} {...ModalBodyStyle} className={`${rootClass}__info`} />
        <RadioCardGroup selectedOption={method} showAdditionalOptions={showClassicOptions} onSelect={(key: Key) => setMethod(key)}>
          <RadioCard
            title={t('CreateFormModal.Options.New')}
            description={t('CreateFormModal.Options.New.Description')}
            svgName={SvgNames.createForm}
            hoverSvgName={SvgNames.createFormActive}
            key={CreateFormMethod.NEW}
            className={`${rootClass}__new-option`}
          />
          <RadioCard
            title={t('CreateFormModal.Options.Template')}
            description={t('CreateFormModal.Options.Template.Description')}
            svgName={SvgNames.createFormFromTemplate}
            hoverSvgName={SvgNames.createFormFromTemplateActive}
            disabledSvgName={SvgNames.createFormFromTemplateDisabled}
            key={CreateFormMethod.TEMPLATE}
            className={`${rootClass}__template-option`}
            tooltipText={disableTemplateOption && t('CreateFormModal.Options.Template.Tooltip.Disabled')}
            disabled={disableTemplateOption}
          />
          <RadioCard
            title={t('CreateFormModal.Options.Migrate')}
            description={t('CreateFormModal.Options.Migrate.Description')}
            key={CreateFormMethod.MIGRATE}
            className={`${rootClass}__migrate-option`}
            isAdditionalOption
          />
          <RadioCard
            title={renderClassicTitle()}
            description={t('CreateFormModal.Options.Classic.Description')}
            key={CreateFormMethod.CLASSIC}
            className={`${rootClass}__classic-option`}
            isAdditionalOption
          />
        </RadioCardGroup>
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form} className={`${rootClass}__footer`}>
        <Button buttonType={ButtonType.TERTIARY} onClick={() => onCancel()} dataTest={`${dataTest}-button-tertiary`}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} onClick={() => onCreate(method as CreateFormMethod)} dataTest={`${dataTest}-button-primary`}>
          {t(createButtonText)}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CreateFormModal
