import React, { FC, Key, ReactElement } from 'react'

import classNames from 'classnames'

import { CheckboxCardProps } from '@components/CheckboxCard/CheckboxCard'

import './CheckboxCardGroup.css'

interface CheckboxCardGroupProps {
  className?: string
  dataTest?: string
  children: ReactElement<CheckboxCardProps>[]
  selectedOption?: Key | Key[]
  onSelect: (selectedOption: Key) => void
}

const rootClass = 'checkbox-card-group'

export const CheckboxCardGroup: FC<CheckboxCardGroupProps> = (props: CheckboxCardGroupProps) => {
  const { dataTest = rootClass, className = '', children, selectedOption, onSelect } = props

  const mainOptions = () => {
    return children.map((child) => {
      if (child.key) {
        const isSelected = Array.isArray(selectedOption) ? selectedOption.includes(child.key) : child.key === selectedOption
        const props = {
          ...child.props,
          onSelect: () => child.key && onSelect(child.key),
          isSelected,
        }
        return { ...child, props }
      } else {
        return child
      }
    })
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {mainOptions()}
    </div>
  )
}
