import { TFunction } from 'i18next'
import debounce from 'lodash/debounce'

import { isAOATest, isCypress, isDev, isProd } from './const/globals'

const MESSAGE_PREFIX = 'Missing Translation Key'

/* eslint-disable no-console -- Requires console functions to work, but logging will not be done in Prod */

/**
 * Provides a wrapper for the i18next t() function that logs missing keys to the console
 */
export class TLogger {
  /** Current en.json object */
  private enJson: Record<string, string> = {}
  /** Tracks previous return values so we can avoid false-positives when a string gets passed to t() multiple times */
  private translatedValues: Set<string> = new Set()
  /** Only enabled in DEV mode or in Cypress tests */
  private isEnabled = !isProd() && (isDev() || isCypress())
  /** Logging function that wraps t(), only created once per app instance */
  private tWrapper: TFunction | null = null

  constructor() {
    if (this.isEnabled) {
      if (isAOATest()) {
        this.enJson = require('../../apps/aoa/static/locales/en.json')
      } else {
        this.enJson = require('../../apps/acton-web/static/locales/en.json')
      }
    }
  }

  /** Outputs missing key message with stack trace so that the origin can be determined */
  private log(message: string | undefined) {
    if (!message) {
      return
    }
    console.groupCollapsed(`${MESSAGE_PREFIX}: ${message}`)
    console.trace()
    console.groupEnd()
    this.showJson()
  }

  /** Shows an updated en.json object in the console so that it can be easily copied and pasted. Debounced to avoid flooding the log */
  private showJson = debounce(() => console.log('Updated en.json:', this.enJson), 1000, { trailing: true })

  /** Accounts for possible variations on the key provided if `context`/`count` is supplied */
  private getPossibleKeys(key: string, options: Record<string, any>) {
    const possibleKeys = [key]
    if (!options) {
      return possibleKeys
    }
    if ('context' in options) {
      possibleKeys.push(`${key}_${options.context}`)
    }
    if ('count' in options) {
      const countKeys = ['zero', 'one', 'two', 'other']
      countKeys.forEach((countKey) => {
        possibleKeys.push(`${key}_${countKey}`)
        if ('context' in options) {
          possibleKeys.push(`${key}_${options.context}_${countKey}`)
        }
      })
    }
    return possibleKeys
  }

  /**
   * Inserts `key` entry into en.json object and sorts the object keys alphabetically
   */
  private insertKey = (newKey: string) => {
    if (newKey in this.enJson) {
      return
    }
    this.enJson[newKey] = newKey

    const keys = Object.keys(this.enJson).sort((a, b) => {
      const valA = a.toLowerCase()
      const valB = b.toLowerCase()
      return valA === valB ? 0 : valA < valB ? -1 : 1
    })

    const newJson: Record<string, string> = {}
    keys.forEach((key) => {
      newJson[key] = this.enJson[key]
    })
    this.enJson = newJson
  }

  private evalMissingKey = async (key: string, options: Record<string, any>, returnValue: string) => {
    if (typeof options !== 'object') {
      this.log(`For key '${key}': 'options' parameter provided to t() is not an object, values and/or context may not be properly handled`)
    }
    const possibleKeys = this.getPossibleKeys(key, options)
    let logMessage = key
    const isMissing = possibleKeys.every((possibleKey) => !(possibleKey in this.enJson))
    const isAlreadyTranslated = this.translatedValues.has(key)

    if (!isAlreadyTranslated && isMissing) {
      if (possibleKeys.length > 1) {
        logMessage = `${key}, context: ${options?.context}, count: ${options?.count}. Cannot show updated enJson automatically.`
      } else {
        this.insertKey(key)
      }
      return logMessage
    }
    if (!this.translatedValues.has(returnValue)) {
      this.translatedValues.add(returnValue)
    }
  }

  /** Returns wrapper for the t() function that performs key checks, or the original t() function if logger is not enabled */
  wrap<T extends TFunction>(t: T) {
    if (!this.isEnabled) {
      return t
    }
    if (this.tWrapper) {
      return this.tWrapper
    }

    const logger = (...args: any) => {
      const [key, options] = args
      if (!key) {
        return ''
      }
      const returnValue = t(...args)
      // Do this async so that it does not block returning the value of t()
      this.evalMissingKey(key, options ?? {}, returnValue).then((message) => this.log(message))
      return returnValue
    }
    this.tWrapper = logger as T
    return logger as T
  }
}
