import { gql } from '@apollo/client'

export default gql`
  mutation updateUserPassword($confirmationPassword: String, $newPassword: String, $id: String) {
    updateUserPassword(confirmationPassword: $confirmationPassword, newPassword: $newPassword, id: $id) {
      status
      message
      errorTypes
    }
  }
`
