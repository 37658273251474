import React, { FC, useState } from 'react'

import classNames from 'classnames'

import CaretIcon, { CaretIconDirection } from '@components/CaretIcon'
import DropDown, { DropdownMenuItem, DropdownMenuLabel, DropDownType } from '@components/DropDown/DropDown'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import LastUpdated from '@src/pages/programs/dashboard/components/LastUpdated/LastUpdated'

import './EmailSMSHeader.css'

interface Props {
  className?: string
  dataTest?: string
  lastUpdated: number
  onRefresh: () => void
  messageReport: () => void
  recipientReport: () => void
  emailPerformance: boolean
}

interface State {
  showDropdown: boolean
}

const defaultState: State = {
  showDropdown: false,
}

const rootClass = 'email-sms-header'

const EmailSMSHeader: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '', lastUpdated, onRefresh, messageReport, recipientReport, emailPerformance } = props
  const { t } = useTranslation()
  const [state, setState] = useState<State>(defaultState)

  const dropDownLabel = () => {
    return (
      <div className={`${rootClass}__drop-label`}>
        <Svg name={SvgNames.download} type={SvgType.ICON} className={`${rootClass}__download`} />
        <Typography text={t('Download')} inline />
        <CaretIcon direction={CaretIconDirection.DOWN} className={classNames({ [`${rootClass}__open`]: state.showDropdown })} />
      </div>
    )
  }

  const renderDropdown = () => (
    <DropDown
      trigger={dropDownLabel()}
      isOpen={state.showDropdown}
      type={DropDownType.STYLED}
      toggleOpen={(open: boolean) => setState({ ...state, showDropdown: open })}
      alignContentEnd={true}
      dataTest={`${dataTest}-download`}
    >
      <DropdownMenuItem key={'message-report'} data-test={'message-report'} onSelect={messageReport}>
        <DropdownMenuLabel className={`${rootClass}__dropdown-label`}>
          <Svg name={SvgNames.letter} type={SvgType.ICON} />
          <Typography text={t('Message Report (CSV)')} inline />
        </DropdownMenuLabel>
      </DropdownMenuItem>
      <DropdownMenuItem key={'recipient-report'} data-test={'recipient-report'} onSelect={recipientReport}>
        <DropdownMenuLabel className={`${rootClass}__dropdown-label`}>
          <Svg name={SvgNames.userList} type={SvgType.ICON} />
          <Typography text={t('Recipient Report (CSV)')} inline />
        </DropdownMenuLabel>
      </DropdownMenuItem>
    </DropDown>
  )

  const renderHeader = () => (
    <div className={`${rootClass}__header`}>
      <LastUpdated lastUpdated={lastUpdated} onRefresh={onRefresh} />
      {emailPerformance && renderDropdown()}
    </div>
  )

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {renderHeader()}
    </div>
  )
}

export default EmailSMSHeader
