import React, { FC, useEffect, useState } from 'react'

import StatusToast from '@components/StatusToast/StatusToast'
import { useTranslation } from '@const/globals'
import { recipientsListMock } from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertRecipients/components/RecipientsList/utils/RecipientsList.mock'
import WebsiteProspectorAlerts from '@src/pages/WebsiteProspectorAlerts/WebsiteProspectorAlerts'
import {
  Alert,
  WebsiteProspectorContainerInitialState,
  WebsiteProspectorContainerState,
  WebsiteProspectorContext,
} from '@src/pages/WebsiteProspectorAlerts/WebsiteProspectorAlertsContext'
import { useAccountSettings } from '@utils/account/account.utils'

const WebsiteProspectorAlertsContainer: FC = () => {
  const [containerValues, setContainerValues] = useState<WebsiteProspectorContainerState>(WebsiteProspectorContainerInitialState)
  const { alerts, statusToast } = containerValues
  const { userId, userName } = useAccountSettings()
  const { t } = useTranslation()

  useEffect(() => {
    const newAlerts = [
      { ...alerts[0], owner: userId, ownerName: userName },
      { ...alerts[1], recipients: [{ ...recipientsListMock[0], id: Number(userId) }] },
      ...alerts.slice(2),
    ]
    update({ alerts: newAlerts })
  }, [])

  const update = (fieldsToUpdate: Partial<WebsiteProspectorContainerState>) => {
    setContainerValues((containerValues) => ({
      ...containerValues,
      ...fieldsToUpdate,
    }))
  }

  const onMuteChange = (alert: Alert) => {
    const alertIndex = alerts.findIndex(({ id }) => id === alert.id)
    const newAlerts = [...alerts.slice(0, alertIndex), { ...alerts[alertIndex], muted: !alert.muted }, ...alerts.slice(alertIndex + 1)]
    update({ alerts: newAlerts })
  }

  const onEnableChange = (alert: Alert, enabled: boolean) => {
    const alertIndex = alerts.findIndex(({ id }) => id === alert.id)
    const newAlerts = [...alerts.slice(0, alertIndex), { ...alerts[alertIndex], enabled }, ...alerts.slice(alertIndex + 1)]
    update({ alerts: newAlerts })
  }

  const onDelete = (alert: Alert) => {
    update({
      alerts: alerts.filter(({ id }) => id !== alert.id),
      statusToast: {
        statusMessage: t('{{alertName}} visitor alert has been deleted.', { alertName: alert.name }),
        title: 'Success!',
        successStatus: true,
        showStatus: true,
      },
    })
  }

  return (
    <WebsiteProspectorContext.Provider
      value={{
        values: containerValues,
        update,
        onMuteChange,
        onEnableChange,
        onDelete,
      }}
    >
      {statusToast?.showStatus && (
        <StatusToast
          isSuccess={statusToast.successStatus}
          message={statusToast.statusMessage}
          title={statusToast.title}
          closeStatus={() => {
            update({ statusToast: { showStatus: false } })
          }}
        />
      )}
      <WebsiteProspectorAlerts />
    </WebsiteProspectorContext.Provider>
  )
}

export default WebsiteProspectorAlertsContainer
