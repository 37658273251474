import React, { FC, useEffect, useRef, useState } from 'react'

import { useDebouncedCallback } from 'use-debounce'

import Input from '@components/Input/Input'
import Select from '@components/Select/Select'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'

import Typography from '../../Typography/Typography'

import './paginator.css'

interface Props {
  pageCount: number
  previousPage: () => void
  nextPage: () => void
  gotoPage: (pageNum: number) => void
  canPreviousPage: boolean
  canNextPage: boolean
  canLastPage: boolean
  pageIndex: number
  pageOptions: any
  pageSize: number
  setPageSize: (size: number) => void
  pageSizeOptions?: any[]
  fetchData?: (pageIndex: number, pageSize: number) => void
  resetPageIndex?: boolean
  disablePaginator?: boolean
  dataTest?: string
  updateOnKeyPress?: boolean
  showPages?: boolean
}

const rootClass = 'paginator'
const DEBOUNCE_TIME = 200

const emptyIndicator = '--'
const Paginator: FC<Props> = (props: Props) => {
  const {
    pageCount,
    previousPage,
    nextPage,
    gotoPage,
    canPreviousPage,
    canNextPage,
    canLastPage,
    resetPageIndex = false,
    pageIndex,
    pageOptions,
    fetchData,
    pageSize,
    setPageSize,
    pageSizeOptions = [10, 20, 50, 100],
    disablePaginator = false,
    dataTest = 'paginator',
    updateOnKeyPress = false,
    showPages = true,
  } = props

  const { t } = useTranslation()

  const inputRef = useRef<HTMLInputElement | null>(null)

  const [isEmpty, setIsEmpty] = useState(false)

  const debouncedGotoPage = useDebouncedCallback((newPage: number) => {
    gotoPage(newPage)
    setIsEmpty(false)
  }, DEBOUNCE_TIME)

  const debouncedNextPage = useDebouncedCallback(() => {
    nextPage()
    setIsEmpty(false)
  }, DEBOUNCE_TIME)

  const debouncedPreviousPage = useDebouncedCallback(() => {
    previousPage()
    setIsEmpty(false)
  }, DEBOUNCE_TIME)

  useEffect(() => {
    if (resetPageIndex) {
      gotoPage(0)
    }
  }, [resetPageIndex])

  useEffect(() => {
    fetchData?.(pageIndex, pageSize)
  }, [pageIndex, pageSize])

  const getOptions = () => {
    if (disablePaginator) {
      return (
        <option key={emptyIndicator} value={emptyIndicator}>
          {emptyIndicator}
        </option>
      )
    }
    return pageSizeOptions.map((pageSize: string | number) => (
      <option key={pageSize} value={pageSize}>
        {pageSize}
      </option>
    ))
  }

  const onInputChange = (value = '') => {
    if (value === '') {
      setIsEmpty(true)
    } else {
      const maxPage = pageOptions.length - 1
      const pageValue = Number(value) - 1
      const page = pageValue > maxPage ? maxPage : pageValue < 0 ? 0 : pageValue
      gotoPage(page)
      setIsEmpty(false)
    }
  }

  return (
    <div className={rootClass}>
      <div className={`${rootClass}__left-controls`}>
        <label htmlFor={'recordsPerPage'}>
          <Typography text={t('Records per page:')} className={`${rootClass}__records`} />
        </label>
        <Select
          id={'recordsPerPage'}
          dataTest={`${dataTest}-list-change-select`}
          className={`${rootClass}__results-per-page`}
          value={disablePaginator ? emptyIndicator : pageSize.toString()}
          disabled={disablePaginator}
          onChange={(e) => {
            setPageSize(Number(e.target.value))
          }}
        >
          {getOptions()}
        </Select>
      </div>
      <div className={`${rootClass}__right-controls`}>
        <Tooltip
          className={`${rootClass}__tooltip`}
          trigger={
            <button
              data-test={`${dataTest}-first-button`}
              className={`${rootClass}__nav-button`}
              onClick={() => debouncedGotoPage(0)}
              disabled={!canPreviousPage || disablePaginator}
            >
              <Svg name={SvgNames.paginationControlsStart} type={SvgType.LARGER_ICON} className={`${rootClass}__start`} />
            </button>
          }
          position={'top'}
        >
          {t('First page')}
        </Tooltip>
        <Tooltip
          className={`${rootClass}__tooltip`}
          trigger={
            <button
              data-test={`${dataTest}-back-button`}
              className={`${rootClass}__nav-button`}
              onClick={() => debouncedPreviousPage()}
              disabled={!canPreviousPage || disablePaginator}
            >
              <Svg name={SvgNames.paginationControlsBack} type={SvgType.LARGER_ICON} className={`${rootClass}__back`} />
            </button>
          }
          position={'top'}
        >
          {t('Previous page')}
        </Tooltip>
        {showPages && (
          <>
            <Typography text={t('Page')} className={`${rootClass}__page-text-left`} />
            <label>
              <Input
                className={`${rootClass}__page`}
                dataTest={`${dataTest}__page`}
                ref={inputRef}
                disabled={disablePaginator}
                type={'number'}
                min={1}
                max={pageOptions.length}
                value={isEmpty ? '' : (pageIndex + 1).toString()}
                onChange={(e) => {
                  if (!updateOnKeyPress) {
                    onInputChange(e.target.value)
                  }
                }}
                onKeyPress={(e) => {
                  if (updateOnKeyPress && e.key === 'Enter') {
                    onInputChange(inputRef.current?.value)
                  }
                }}
                onChangeDebounce={DEBOUNCE_TIME}
                onBlur={(e) => {
                  if (e.target.value === '') {
                    setIsEmpty(false)
                  }
                }}
              />
            </label>
            <Typography text={`${t('of')} ${disablePaginator ? 1 : pageOptions.length}`} className={`${rootClass}__page-text-right`} />
          </>
        )}
        <Tooltip
          className={`${rootClass}__tooltip`}
          trigger={
            <button
              data-test={`${dataTest}-next-button`}
              className={`${rootClass}__nav-button`}
              onClick={() => debouncedNextPage()}
              disabled={!canNextPage || disablePaginator}
            >
              <Svg name={SvgNames.paginationControlsNext} type={SvgType.LARGER_ICON} className={`${rootClass}__next`} />
            </button>
          }
          position={'top'}
        >
          {t('Next page')}
        </Tooltip>
        <Tooltip
          className={`${rootClass}__tooltip`}
          trigger={
            <button
              data-test={`${dataTest}-last-button`}
              className={`${rootClass}__nav-button`}
              onClick={() => debouncedGotoPage(pageCount - 1)}
              disabled={!canNextPage || disablePaginator || !canLastPage}
            >
              <Svg name={SvgNames.paginationControlsEnd} type={SvgType.LARGER_ICON} className={`${rootClass}__end`} />
            </button>
          }
          position={'top'}
        >
          {t('Last page')}
        </Tooltip>
      </div>
    </div>
  )
}

export default Paginator
