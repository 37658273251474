import React, { FC, useState } from 'react'

import classNames from 'classnames'

import Popover from '@components/Popover/Popover'
import PopoverCard from '@components/PopoverCard/PopoverCard'
import PopoverTriggerButton from '@components/PopoverTriggerButton/PopoverTriggerButton'
import { SvgNames } from '@components/Svg'
import Toggle from '@components/Toggle'
import Typography from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './IgnoreYearPopover.css'

export interface IgnoreYearPopoverProps {
  className?: string
  dataTest?: string
  ignoreYear: boolean
  onIgnoreYearChange: (ignoreYear: boolean) => void
}

const rootClass = 'ignore-year-popover'
const LEARN_MORE_LINK =
  'https://success.act-on.com/acton/attachment/9883/u-2a66828d-1a86-4588-94db-023c07af4e25/0/-/-/-/-/In%20App%3A%20%20Learn%20More%20about%20Segmentation%20Dates%20with%20no%20year'

const IgnoreYearPopover: FC<IgnoreYearPopoverProps> = (props: IgnoreYearPopoverProps) => {
  const { dataTest = rootClass, className = '', ignoreYear, onIgnoreYearChange } = props
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [toggleState, setToggleState] = useState<boolean>(false)
  const { t } = useTranslation()

  const onPopoverAction = () => {
    setIsOpen(false)
    onIgnoreYearChange(toggleState)
  }

  const onOpenChange = (open: boolean) => {
    setIsOpen(open)
    setToggleState(ignoreYear)
  }
  const renderPopoverTrigger = () => (
    <PopoverTriggerButton
      isOpen={isOpen}
      isSelected={ignoreYear}
      icon={SvgNames.calendarSync}
      tooltip={t('SegmentComposer.Build.IgnoreYearPopover.Trigger.Tooltip')}
    />
  )

  const renderPopoverContent = () => (
    <div className={`${rootClass}__popover-content`}>
      <Typography text={t('SegmentComposer.Build.IgnoreYearPopover.Content.Line_1')} />
      <Typography text={t('SegmentComposer.Build.IgnoreYearPopover.Content.Line_2')} />
      <Toggle isOn={toggleState} onToggle={(isOn) => setToggleState(isOn)} label={t('SegmentComposer.Build.IgnoreYearPopover.Content.ToggleLabel')} />
    </div>
  )

  return (
    <Popover
      dataTest={dataTest}
      align={'end'}
      position={'bottom'}
      trigger={renderPopoverTrigger()}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      className={classNames(rootClass, className)}
    >
      <PopoverCard
        headerIcon={SvgNames.calendarSync}
        headerText={t('SegmentComposer.Build.IgnoreYearPopover.Header')}
        content={renderPopoverContent()}
        onCancel={() => onOpenChange(false)}
        primaryButton={{ text: t('Apply'), onClick: onPopoverAction }}
        footerLink={LEARN_MORE_LINK}
      />
    </Popover>
  )
}

export default IgnoreYearPopover
