import { Row } from 'react-table'

import { isNonSendableSegment } from '@components/SegmentDetails/SegmentDetails.utils'
import { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { HeaderAction, RowAction } from '@components/Table/Table'
import { ContactsTableActions } from '@src/pages/ContactSegments/components/ContactSegmentsActions/Contacts.actions'
import { BouncesSegments } from '@utils/categorization'
import { Contact } from '@utils/contact/contact.constants'
import { sendEmailToContact } from '@utils/contact/contact.utils'
import { ContactViewPanel, openContactView } from '@utils/contacts'
import { Segment } from '@utils/contactSegments/contactSegments.utils'

const getRemoveFromBounceSegmentProps = (segment: Segment, isBulk = false) => {
  const props: { [key: string]: HeaderAction | RowAction } = {
    [BouncesSegments.OPT_OUT]: { label: 'Remove opt-out', hidden: isBulk },
    [BouncesSegments.HARD_BOUNCES]: { label: 'Change to soft bounce', hidden: isBulk },
    [BouncesSegments.SOFT_BOUNCES]: { label: 'Change to hard bounce' },
  }
  return props[segment.name] ?? { hidden: true }
}

export const getBouncesContactsHeaderActions = (
  segment: Segment,
  doTableAction: (action: ContactsTableActions) => void,
  allowToDelete: boolean,
  disableSegmentModifications?: boolean
): HeaderAction[] => [
  {
    disabled: disableSegmentModifications,
    tooltipMessage: disableSegmentModifications ? 'Segment.Tooltip.Disabled.Migration' : undefined,
    hidden: isNonSendableSegment(segment),
    label: 'Send Email',
    icon: SvgNames.letter,
    hasTooltip: true,
    onClick: () => doTableAction(ContactsTableActions.SEND_EMAIL),
  },
  {
    disabled: !allowToDelete || disableSegmentModifications,
    hidden: !allowToDelete,
    tooltipMessage: disableSegmentModifications ? 'Segment.Tooltip.Disabled.Migration' : 'Ask your administrator for permission to do this',
    label: 'Delete',
    icon: SvgNames.delete,
    iconSize: SvgType.ICON,
    hasTooltip: true,
    onClick: () => doTableAction(ContactsTableActions.DELETE),
  },
  {
    ...getRemoveFromBounceSegmentProps(segment, true),
    icon: SvgNames.removeContact,
    hasTooltip: true,
    onClick: () => doTableAction(ContactsTableActions.REMOVE_FROM_BOUNCE),
  },
]

export const getBouncesContactsRowActions = (
  segment: Segment,
  onDeleteContactClick: (row: Row<Contact>) => void,
  onRemoveFromBounceClick: (contacts: Contact[]) => void,
  allowToDelete: boolean,
  disableSegmentModifications?: boolean
): RowAction[] => [
  {
    hidden: isNonSendableSegment(segment),
    label: 'Send Email',
    icon: SvgNames.letter,
    onClick: ({ original: { recId } }: Row<Contact>) => sendEmailToContact(recId),
  },
  {
    label: 'Contact Report',
    icon: SvgNames.contactReport,
    onClick: ({ original: { recId } }: Row<Contact>) => openContactView(recId, ContactViewPanel.SUMMARY),
  },
  {
    label: 'Edit contact',
    icon: SvgNames.pencil,
    inDropdown: true,
    disabled: disableSegmentModifications,
    hasTooltip: disableSegmentModifications,
    tooltipMessage: disableSegmentModifications ? 'Segment.Tooltip.Disabled.Migration' : undefined,
    onClick: ({ original: { recId } }: Row<Contact>) => openContactView(recId, ContactViewPanel.INFO),
  },
  {
    disabled: disableSegmentModifications || !allowToDelete,
    tooltipMessage: disableSegmentModifications ? 'Segment.Tooltip.Disabled.Migration' : 'Ask your administrator for permission to do this',
    label: 'Delete contact',
    icon: SvgNames.delete,
    inDropdown: true,
    hasTooltip: disableSegmentModifications,
    onClick: onDeleteContactClick,
  },
  {
    ...getRemoveFromBounceSegmentProps(segment),
    icon: SvgNames.removeContact,
    inDropdown: true,
    disabled: disableSegmentModifications,
    hasTooltip: disableSegmentModifications,
    tooltipMessage: disableSegmentModifications ? 'Segment.Tooltip.Disabled.Migration' : undefined,
    onClick: ({ original: contact }: Row<Contact>) => onRemoveFromBounceClick([contact]),
  },
]
