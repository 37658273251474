import React, { CSSProperties, forwardRef, ReactNode, useEffect, useState } from 'react'
import { RefCallBack } from 'react-hook-form'

import classNames from 'classnames'
import { useDebouncedCallback } from 'use-debounce'

import Label from '@components/Label/Label'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { getUUID } from '@const/globals'

import './input.css'

export enum InputType {
  SEARCH,
  QUANTITY,
}

export enum InputSize {
  REGULAR = 'REGULAR',
  SMALL = 'SMALL',
}

export interface InputProps {
  label?: string | ReactNode
  value?: string
  defaultValue?: string
  type?: string
  min?: number | string
  max?: number | string
  id?: string
  index?: number
  name?: string
  register?: any
  ref?: React.MutableRefObject<HTMLInputElement | null> | RefCallBack
  className?: string
  placeholder?: string
  inputType?: InputType
  dataTest?: string
  readOnly?: boolean | false
  labelDisplay?: string
  readOnlyAllowCopy?: boolean | false
  disabled?: boolean
  error?: boolean
  showSuccess?: boolean
  onChangeDebounce?: number
  style?: CSSProperties
  inputAriaLabel?: string
  required?: boolean
  autoFocus?: boolean
  trim?: boolean
  size?: InputSize
  autoComplete?: string
  maxlength?: number
  children?: ReactNode
  icon?: SvgNames
  iconType?: SvgType
  leadingIcon?: SvgNames
  extraLabelText?: ReactNode
  showClearOverride?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlurCapture?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onFocusCapture?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const rootClass = 'input'

const Input = forwardRef((props: InputProps, ref) => {
  const {
    type = 'text',
    min,
    max,
    id,
    index,
    label,
    register,
    className,
    inputType,
    dataTest = 'input',
    error,
    showSuccess,
    readOnly,
    labelDisplay,
    readOnlyAllowCopy,
    onChange,
    onChangeDebounce,
    onBlur,
    onFocus,
    onBlurCapture,
    onFocusCapture,
    inputAriaLabel,
    required,
    size,
    maxlength,
    children,
    icon,
    iconType = SvgType.MEDIUM,
    leadingIcon,
    value,
    defaultValue,
    autoFocus = false,
    trim = true,
    extraLabelText,
    ...rest
  } = props
  const inputId = id || getUUID()
  const [currentValue, setCurrentValue] = useState(value ?? defaultValue)
  useEffect(() => setCurrentValue((current) => (trim && (current ?? '').trim() === value ? current : value)), [value, trim])

  const debouncedOnChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event)
  }, onChangeDebounce)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventValue = event.target.value
    const newValue = trim ? eventValue.trim() : eventValue
    const isTrimmedValueChanged = newValue !== (currentValue ?? '').trim()

    setCurrentValue(eventValue)

    if (trim && !isTrimmedValueChanged && newValue !== '') {
      // Nothing to do if trimmed value doesn't change
      // However, we do want to trigger onChange if newValue is blank
      return
    }
    const caretPos = event.target.selectionStart
    event.target.value = newValue
    if (onChangeDebounce && onChange) {
      debouncedOnChange({ ...event })
    } else if (onChange) {
      onChange(event)
    }
    event.target.value = eventValue
    // setting newValue directly (event.target.value = newValue) may cause cursor jumps, so need to fix caret position
    if (caretPos !== null) {
      event.target.setSelectionRange(caretPos, caretPos)
    }
  }

  const indexProp = index !== undefined ? { tabIndex: index } : {}
  const valueProp = value !== undefined ? { value: currentValue } : defaultValue !== undefined ? { defaultValue } : {}

  const onChangeProp = type === 'number' ? { onInput: handleChange } : { onChange: handleChange }

  return (
    <span
      className={classNames(`${rootClass}__container`, {
        [`${rootClass}__container-labelled`]: label,
        [`${rootClass}__container-icon`]: icon,
        [`${className}__container`]: className,
      })}
      data-test={`${dataTest}-container`}
    >
      {label && (
        <Label htmlFor={inputId} required={required} display={labelDisplay} dataTest={`${dataTest}-label`}>
          {label}
        </Label>
      )}
      {extraLabelText && extraLabelText}
      {icon && (
        <Svg
          className={classNames(`${rootClass}__icon`, {
            [`${rootClass}__icon__small`]: size === InputSize.SMALL,
            [`${rootClass}__icon-leading`]: leadingIcon,
            [`${rootClass}__icon-error`]: error,
            [`${rootClass}__icon-success`]: !error && showSuccess,
          })}
          name={icon}
          type={iconType}
          dataTest={`${dataTest}-svg-${icon}`}
        />
      )}
      <input
        {...indexProp}
        aria-label={inputAriaLabel}
        data-test={dataTest}
        id={inputId}
        /* eslint-disable-next-line jsx-a11y/no-autofocus */
        autoFocus={autoFocus}
        className={classNames(rootClass, className, {
          [`${rootClass}--search`]: inputType === InputType.SEARCH,
          [`${rootClass}--quantity`]: inputType === InputType.QUANTITY,
          [`${rootClass}__small`]: size === InputSize.SMALL,
          [`${rootClass}__error`]: error,
          [`${rootClass}--read-only`]: readOnlyAllowCopy,
        })}
        type={type}
        min={min}
        max={max}
        onBlur={onBlur}
        onFocus={onFocus}
        onBlurCapture={onBlurCapture}
        onFocusCapture={onFocusCapture}
        ref={ref}
        required={required}
        readOnly={readOnly}
        maxLength={maxlength}
        {...onChangeProp}
        {...valueProp}
        {...rest}
        {...register}
      />
      {children}
    </span>
  )
})

/**
 * @deprecated use <InputV2 instead
 */
export default React.memo(Input)
