import React, { FC, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import foldersSVG from '@assets/inline/folders.svg'
import { Checkbox } from '@components/Checkbox/Checkbox'
import CheckboxGroup from '@components/CheckboxGroup/CheckboxGroup'
import FormRow from '@components/FormRow'
import InputV2 from '@components/InputV2/InputV2'
import { LabelV2 } from '@components/LabelV2/LabelV2'
import Modal, { ModalBody } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import ModalFooterV2 from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import ModalHeaderV2 from '@components/Modal/components/ModalHeaderV2/ModalHeaderV2'
import NestedDropDown from '@components/NestedDropDown/NestedDropDown'
import RemovableTag from '@components/RemovableTag/RemovableTag'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import Spinner from '@components/Spinner/Spinner'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import TagManager from '@components/TagManager/TagManager'
import TagManagerTriggerWithText from '@components/TagManagerTriggerWithText/TagManagerTriggerWithText'
import Toggle from '@components/Toggle'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { ModalBodyStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import { getFolderDropDownOptions } from '@utils/folderUtils'
import { NO_COLOR } from '@utils/tags'

import { ModalHeaderType } from '../Modal/components/ModalHeader'

import './DuplicateFolderModal.css'

export interface DuplicateFolderModalProps {
  headerTitleKey: string
  isBee?: boolean
  isSaveFailed?: boolean
  newLPComposerCreateBlank?: boolean
  preventAutoClosingModal?: boolean
  isLandingPageComposer?: boolean
  isLandingListingPage?: boolean
  itemNameLabel?: string
  itemNameRequired?: boolean
  itemNameDescription?: string
  activeFolderId?: number
  folders: FolderData[]
  tags: LabelDto[]
  defaultName?: string
  namePlaceholder: string
  itemToCloneTags?: LabelDto[]
  itemName: string
  descriptionKey?: string
  onClone: (
    name: string,
    tags: LabelDto[],
    folderIdToClone: number | undefined,
    includeRecipients?: boolean,
    includeSuppressionRules?: boolean,
    includeUnPublishedChanges?: boolean
  ) => Promise<any> | void
  onTagCreate: (tag: LabelDto) => void
  onCancel: () => void
  onSubmitText?: string
  folderAllOptionLabel?: string
  addToFolder?: boolean
  includeEmailSettings?: boolean
  loading?: boolean
  hideTags?: boolean
  inputSvgIcon?: SvgNames
  dropDownSvgIcon?: SvgNames
  asChild?: boolean
  className?: string
  dataTest?: string
  hideIcon?: boolean
  showLPToggleToIncludeChanges?: boolean
  includeUnpublishedChanges?: boolean
  disableSaveButton?: boolean
  onIncludeUnpublishedChangesToggle?: (include: boolean) => void
  onInputChange?: (value: string) => void
  includeUnpublishedChangesLabel?: string
}

interface State {
  folderIdToClone?: number
  tagsToClone: LabelDto[]
  duplicateName: string
  includeRecipients?: boolean
  includeSuppressionRules?: boolean
}

const rootClass = 'duplicate-modal'

const DuplicateFolderModal: FC<DuplicateFolderModalProps> = (props: DuplicateFolderModalProps) => {
  const {
    dataTest = rootClass,
    className = '',
    headerTitleKey,
    itemNameLabel,
    itemNameRequired,
    itemNameDescription,
    activeFolderId,
    folders,
    itemName,
    defaultName = `${itemName} (copy)`,
    namePlaceholder,
    itemToCloneTags,
    tags,
    onSubmitText = 'Duplicate',
    descriptionKey,
    includeEmailSettings = false,
    hideTags = false,
    loading,
    inputSvgIcon,
    dropDownSvgIcon,
    asChild,
    hideIcon,
    isBee,
    isSaveFailed,
    isLandingPageComposer,
    isLandingListingPage,
    preventAutoClosingModal,
    newLPComposerCreateBlank,
    showLPToggleToIncludeChanges = false,
    includeUnpublishedChanges = true,
    disableSaveButton = false,
    includeUnpublishedChangesLabel,
    onClone,
    onCancel,
    onTagCreate,
    onInputChange,
    onIncludeUnpublishedChangesToggle,
  } = props

  const [isToggleOn, setIsToggleOn] = useState(includeUnpublishedChanges)
  const includeUnPublishedChangesRef = useRef(isToggleOn)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [state, setState] = useState<State>({
    folderIdToClone: activeFolderId,
    tagsToClone: itemToCloneTags ?? [],
    duplicateName: defaultName,
    includeRecipients: false,
    includeSuppressionRules: false,
  })

  const { folderIdToClone, tagsToClone, duplicateName, includeRecipients, includeSuppressionRules } = state

  const selectRef = useRef<HTMLSelectElement | null>(null)

  useEffect(() => {
    if (selectRef.current) {
      selectRef.current.style.backgroundImage = `${selectRef.current.style.backgroundImage}, url(${foldersSVG})`
    }
  }, [])

  useEffect(() => {
    if (newLPComposerCreateBlank && (activeFolderId || itemToCloneTags)) {
      setState((prevState) => ({ ...prevState, folderIdToClone: activeFolderId, tagsToClone: itemToCloneTags as LabelDto[] }))
    }
  }, [newLPComposerCreateBlank, activeFolderId, itemToCloneTags])

  const onFolderSelect = (folderIdToClone: number) => {
    setState({ ...state, folderIdToClone })
  }

  const onNameChanges = (value: string) => {
    setState((prevState) => ({ ...prevState, duplicateName: value }))
    onInputChange?.(value)
  }

  const onRemoveTag = (tagName: string) => {
    const index = tagsToClone.findIndex(({ name }) => name === tagName)
    setState({ ...state, tagsToClone: [...tagsToClone.slice(0, index), ...tagsToClone.slice(index + 1)] })
  }

  const onApplyAndRemoveTags = (tagsToApply: LabelDto[], tagsToRemove: number[]) => {
    const tags = tagsToClone.filter((tag) => !tagsToRemove.includes(tag.id))
    setState({ ...state, tagsToClone: tags.length > 0 ? [...tags, ...tagsToApply] : [...tagsToApply] })
  }

  const onRecipientChange = (checked: boolean) => {
    setState({ ...state, includeRecipients: checked })
  }

  const onSuppressionRulesChange = (checked: boolean) => {
    setState({ ...state, includeSuppressionRules: checked })
  }

  const actionButtonClicked = async () => {
    setIsLoading(true)
    const message = await onClone(
      duplicateName,
      tagsToClone,
      folderIdToClone,
      includeRecipients,
      includeSuppressionRules,
      includeUnPublishedChangesRef.current
    )
    if (!preventAutoClosingModal) {
      // We should handle the closing modal from the outside in this instance.
      !message && onCancel()
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (isSaveFailed && (isLoading || loading)) {
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }, [isSaveFailed, isLoading, loading])

  const { t } = useTranslation()

  // Avoids the dropdown repositioning while the user is typing,
  // safe because dropdown should never be significantly taller than this modal
  const dropDownContentProps = {
    align: 'start',
    side: 'top',
    avoidCollisions: false,
  } as const

  const _loading = isLoading || loading

  return (
    <Modal
      className={classNames(rootClass, className)}
      dataTest={dataTest}
      isOpen
      paddingV2
      header={<ModalHeaderV2 className={`${rootClass}__modal-header`} headerType={ModalHeaderType.Form} headerText={t(headerTitleKey)} />}
    >
      <ModalBody className={classNames({ [`${rootClass}__body-fix-height`]: isLandingPageComposer && _loading })}>
        {_loading ? (
          <Spinner className={`${rootClass}__loader`} />
        ) : (
          <>
            {descriptionKey && (
              <div className={`${rootClass}__criteria`}>
                <Typography
                  text={descriptionKey}
                  {...ModalBodyStyle}
                  className={classNames(`${rootClass}__criteria-word-break`)}
                  tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
                  values={{ itemName, interpolation: { escapeValue: true } }}
                  shouldUnescape
                />
              </div>
            )}
            <FormRow className={`${rootClass}__form-row`}>
              <div className={classNames({ [`${`${rootClass}__input-wrapper`}`]: showLPToggleToIncludeChanges })}>
                <InputV2
                  labelProps={{ label: itemNameLabel || t('Save as'), required: itemNameRequired }}
                  className={`${rootClass}__input`}
                  value={defaultName}
                  placeholder={t(namePlaceholder)}
                  name={'defaultName'}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus={!defaultName}
                  onChange={(event) => onNameChanges(event.target.value.trim())}
                  {...(!hideIcon && { leadingIcon: inputSvgIcon ?? SvgNames.cloneSegment })}
                  iconType={SvgType.LARGER_ICON}
                  {...(isBee && isLandingListingPage && { maxCharacterProps: { maxLength: 400 } })}
                />
                {itemNameDescription && (
                  <Typography
                    text={itemNameDescription}
                    type={TextType.BODY_TEXT_SMALL_LIGHT}
                    className={classNames(`${rootClass}__input-description`, {
                      [`${rootClass}__input-description-margin-bottom`]: showLPToggleToIncludeChanges,
                    })}
                  />
                )}
              </div>

              {showLPToggleToIncludeChanges && (
                <div className={classNames({ [`${rootClass}__toggle-margin-bottom`]: showLPToggleToIncludeChanges })}>
                  <LabelV2 label={t('LandingPage.IncludeUnpublish.Changes')} />
                  <Toggle
                    noLeftMargin
                    isOn={isToggleOn}
                    label={includeUnpublishedChangesLabel || t('LandingPage.IncludeUnpublish.Changes.toggle.template')}
                    onToggle={(isOnUpdate: boolean) => {
                      setIsToggleOn(isOnUpdate)
                      includeUnPublishedChangesRef.current = isOnUpdate
                      onIncludeUnpublishedChangesToggle?.(isOnUpdate)
                    }}
                  />
                </div>
              )}
            </FormRow>
            <FormRow className={`${rootClass}__form-row`}>
              <LabelV2>{t('Location')}</LabelV2>
              <NestedDropDown
                // This key helps to update the component when the folderIdToClone changes; this fixes cid8 issue when folder is not selected
                key={folderIdToClone}
                options={getFolderDropDownOptions(folders, true)}
                footerPrimaryButtonText={t('Add here')}
                onSubmit={(selected) => selected && onFolderSelect(parseInt(selected))}
                placeholderIcon={dropDownSvgIcon ?? SvgNames.moveFolder}
                title={t('Select a folder')}
                withTitle
                withFooter
                className={`${rootClass}__folder-drop-down`}
                defaultSelected={`${folderIdToClone}`}
                asChild={asChild}
              />
            </FormRow>
            {includeEmailSettings && (
              <FormRow className={`${rootClass}__form-row`}>
                <LabelV2>{t('Email settings')}</LabelV2>
                <CheckboxGroup className={`${rootClass}__email-settings`}>
                  <Checkbox checked={includeRecipients} label={<Typography text={t('Include recipients')} />} onChange={onRecipientChange} title="" />
                  <Checkbox
                    className={`${rootClass}__email-settings-suppression`}
                    checked={includeSuppressionRules}
                    label={<Typography text={t('Include suppression rules')} />}
                    onChange={onSuppressionRulesChange}
                    title=""
                  />
                  <Tooltip
                    alignTextCenter
                    trigger={<Svg name={SvgNames.info} type={SvgType.LARGER_ICON} className={`${rootClass}__svg`} />}
                    className={`${rootClass}__email-settings-tooltip`}
                    inline={false}
                  >
                    {t('Include.Suppression.Rules.Tooltip')}
                  </Tooltip>
                </CheckboxGroup>
              </FormRow>
            )}
            {!hideTags && (
              <FormRow className={`${rootClass}__form-row`}>
                <LabelV2>{t('Tags')}</LabelV2>
                <div className={`${rootClass}__tags`}>
                  {tagsToClone?.length > 0 &&
                    tagsToClone.map((tag) => {
                      return (
                        <RemovableTag
                          className={`${rootClass}__tag`}
                          key={tag.name}
                          onRemove={onRemoveTag}
                          color={tag.color ?? NO_COLOR}
                          name={tag.name ?? 'No Name'}
                        />
                      )
                    })}
                  <TagManager
                    appliedTags={tagsToClone}
                    tags={tags}
                    trigger={<TagManagerTriggerWithText className={`${rootClass}__tag`} />}
                    onApplyAndRemove={(tagsToApply: LabelDto[], tagsToRemove: number[]) => onApplyAndRemoveTags(tagsToApply, tagsToRemove)}
                    onCreate={(color, tagName) => onTagCreate({ color, name: tagName })}
                    title={'Manage Tags'}
                    dropDownContentProps={dropDownContentProps}
                  />
                </div>
              </FormRow>
            )}
          </>
        )}
      </ModalBody>
      <ModalFooterV2
        footerType={ModalFooterType.Form}
        onClose={onCancel}
        buttons={{
          actionButtonDisabled: disableSaveButton || duplicateName.length < 1 || _loading,
          actionButtonLabel: t(onSubmitText),
          actionButtonOnClick: actionButtonClicked,
          actionButtonLoading: preventAutoClosingModal && isLandingPageComposer && isLoading,
          cancelButtonLabel: t('Cancel'),
          actionButtonDataProperties: { 'data-product': 'create_from_scratch_new' },
        }}
      />
    </Modal>
  )
}

export default DuplicateFolderModal
