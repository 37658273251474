import React, { FC, ReactNode, useMemo } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/Button'
import ScrollArea from '@components/ScrollArea/ScrollArea'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import AddUserButton from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertRecipients/components/AddUserButton/AddUserButton'
import RecipientInfo from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertRecipients/components/RecipientInfo/RecipientInfo'
import { Recipient } from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertRecipients/components/RecipientsList/RecipientsList'

import { ALL_RECIPIENTS_LIST } from '../../RecipientSelect'

import './RecipientSelectList.css'

interface RecipientSelectListProps {
  className?: string
  dataTest?: string
  recipients: Recipient[]
  selectedGroup?: string
  onSelectGroup: (group: string) => void
  search?: string
}

const rootClass = 'recipient-select-list'

const RecipientSelectList: FC<RecipientSelectListProps> = (props: RecipientSelectListProps) => {
  const { dataTest = rootClass, className = '', recipients, selectedGroup, onSelectGroup, search } = props

  const { t } = useTranslation()

  const recipientsByGroup = useMemo(() => {
    let filteredRecipients = recipients
    if (search) {
      const searchLowerCase = search.toLowerCase()
      filteredRecipients = filteredRecipients.filter(
        ({ name, email }) => name.toLowerCase().includes(searchLowerCase) || email.toLowerCase().includes(searchLowerCase)
      )
    }
    return filteredRecipients.reduce((recipients: Map<string, Recipient[]>, recipient: Recipient) => {
      const recipientGroup = recipient.group ?? 'default'
      return new Map(recipients.set(recipientGroup, [...(recipients.get(recipientGroup) ?? []), recipient]))
    }, new Map())
  }, [recipients, search])

  const renderGroups = () => {
    return Array.from(recipientsByGroup.entries())
      .filter(([group]) => group !== 'default')
      .map(([group, recipients]) => (
        <div data-test={`${dataTest}-group`} className={`${rootClass}__row`} key={`group-${group}`}>
          <RecipientInfo name={group} description={`${recipients.length} users`} isGroup />
          <Button
            buttonType={ButtonType.TEXT}
            className={classNames(`${rootClass}__view-users-button`, `${rootClass}__row-action`)}
            onClick={() => onSelectGroup(group)}
          >
            <Typography text={t('View users')} type={TextType.NORMAL_TEXT_TEAL} lineHeight={LineHeight.MEDIUM_SMALL} weight={TextWeight.MEDIUM} />
          </Button>
        </div>
      ))
  }

  const renderCategoryLabel = (label: string, recipientsAmount: number) => (
    <div data-test={`${dataTest}-group-label`} className={`${rootClass}__category-label`}>
      <Typography
        text={`${t(label).toUpperCase()} (${recipientsAmount})`}
        type={TextType.NORMAL_TEXT_TEAL_SMALL}
        lineHeight={LineHeight.MEDIUM_LARGE}
        weight={TextWeight.BOLD}
      />
      {!!selectedGroup && (
        <div className={`${rootClass}__category-label-action`}>
          <Svg name={SvgNames.reloadNoFill} type={SvgType.ICON} />{' '}
        </div>
      )}
    </div>
  )

  const renderRecipientRow = (id: number, name: string, description: string) => (
    <div data-test={`${dataTest}-recipient`} className={`${rootClass}__row`} key={`recipient-${id}`}>
      <RecipientInfo name={name} description={description} />
      <AddUserButton className={`${rootClass}__row-action`} />
    </div>
  )

  const renderRows = (filterByGroup = ALL_RECIPIENTS_LIST) => {
    const recipientsByGroupEntries = Array.from(recipientsByGroup)
    const groupsToRender =
      filterByGroup === ALL_RECIPIENTS_LIST ? recipientsByGroupEntries : recipientsByGroupEntries.filter(([group]) => filterByGroup === group)
    return Array.from(groupsToRender).reduce((rows: ReactNode[], [group, recipients]) => {
      const recipientsRows = recipients.map(({ id, name, email }) => renderRecipientRow(id, name, email))
      const groupSection = (
        <div className={`${rootClass}__category-section`} key={`section-${group}`}>
          {group !== 'default' ? renderCategoryLabel(group, recipients.length) : undefined}
          {recipientsRows}
        </div>
      )
      return [...rows, groupSection]
    }, [])
  }

  return (
    <div className={className}>
      <ScrollArea className={rootClass} dataTest={dataTest}>
        {selectedGroup ? renderRows(selectedGroup) : renderGroups()}
      </ScrollArea>
    </div>
  )
}

export default RecipientSelectList
