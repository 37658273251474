import React, { FC, useCallback, useEffect, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import { DropDownContent, DropDownMenuRoot, DropDownTriggerItem } from '@components/DropDown'
import Svg, { SvgNames } from '@components/Svg'
import { SvgColor, SvgType } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextAlign, TextType, TextWeight } from '@components/Typography/Typography'

import './SplitButton.css'

export interface SplitButtonOptionsProps {
  key: string
  title: string | JSX.Element
  icon?: SvgNames
  disabled?: boolean
  disabledTooltip?: string
  onClick?: () => void
}

export enum SplitButtonType {
  PRIMARY = 'Primary',
  SECONDARY = 'Secondary',
  TERTIARY = 'Tertiary',
  DESTRUCTIVE = 'Destructive',
}
export interface SplitButtonProps {
  options: SplitButtonOptionsProps[]
  lineHeight?: LineHeight
  weight?: TextWeight
  optionLineHeight?: LineHeight
  optionTextWeight?: TextWeight
  buttonType?: SplitButtonType
  disabled?: boolean
  className?: string
  dataTest?: string
  dropdownCaretFill?: boolean
  largeDropdown?: boolean
}

interface ButtonTypeValues {
  buttonType: ButtonType
  typographyType: TextType
  iconFill: SvgColor
}

const getButtonTypeValues = (buttonType: SplitButtonType): ButtonTypeValues => {
  const values: Record<SplitButtonType, ButtonTypeValues> = {
    [SplitButtonType.PRIMARY]: {
      buttonType: ButtonType.PRIMARY,
      typographyType: TextType.BODY_TEXT_WHITE,
      iconFill: SvgColor.WHITE,
    },
    [SplitButtonType.SECONDARY]: {
      buttonType: ButtonType.WHITE,
      typographyType: TextType.BODY_TEXT,
      iconFill: SvgColor.TEXT_GRAY,
    },
    [SplitButtonType.TERTIARY]: {
      buttonType: ButtonType.OUTLINE,
      typographyType: TextType.NORMAL_TEXT_TEAL_LARGE,
      iconFill: SvgColor.TEXT_TEAL,
    },
    [SplitButtonType.DESTRUCTIVE]: {
      buttonType: ButtonType.DELETE_OUTLINE,
      typographyType: TextType.ERROR_LARGE_MEDIUM,
      iconFill: SvgColor.REMOVE_RED,
    },
  }

  return values[buttonType]
}

const rootClass = 'split'

const SplitButton: FC<SplitButtonProps> = ({
  className,
  options,
  lineHeight,
  optionLineHeight,
  optionTextWeight = TextWeight.MEDIUM_LIGHT,
  weight = TextWeight.MEDIUM,
  buttonType = SplitButtonType.SECONDARY,
  disabled,
  dropdownCaretFill = false,
  largeDropdown = false,
  dataTest = rootClass,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [contentWidth, setContentWidth] = useState<number>(0)
  const [focusedElementIndex, setFocusedElementIndex] = useState<number>(0)

  const buttonTypeValues = getButtonTypeValues(buttonType)

  const handleToggle = useCallback(() => {
    setIsOpen((cur) => !cur)
    setFocusedElementIndex(0)
  }, [])

  const handleItemClick = useCallback(
    (onClick?: () => void) => () => {
      onClick && onClick()
      setIsOpen(false)
    },
    []
  )

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'ArrowUp') {
        e.preventDefault()
        setFocusedElementIndex((cur) => (cur > 1 ? cur - 1 : options.length - 1))
      }
      if (e.key === 'ArrowDown') {
        e.preventDefault()
        setFocusedElementIndex((cur) => (cur < options.length - 1 ? cur + 1 : 1))
      }
      if (e.key === 'Enter' && focusedElementIndex) {
        options[focusedElementIndex].onClick?.()
        handleToggle()
      }
    },
    [focusedElementIndex, handleToggle, options]
  )

  useEffect(() => {
    const updateContentElementWidth = () => {
      const button = document.querySelector('[data-container]')
      if (button) {
        setContentWidth(button.getBoundingClientRect().width)
      }
    }

    updateContentElementWidth()
    window.addEventListener('resize', updateContentElementWidth)

    return () => {
      window.removeEventListener('resize', updateContentElementWidth)
    }
  }, [isOpen])

  const renderDefaultButton = (isSingleButton?: boolean) => {
    const defaultButton = options[0]

    return (
      <Button
        buttonType={buttonTypeValues.buttonType}
        iconPosition={ButtonIconPosition.LEFT}
        disabled={disabled}
        className={classNames(`${rootClass}__button-main ${rootClass}__button`, {
          [`${rootClass}__button-single`]: isSingleButton,
          [`${rootClass}__button-${buttonTypeValues.buttonType.toLowerCase()}`]: !!buttonType,
        })}
        onClick={() => defaultButton.onClick?.()}
        dataTest={`${dataTest}-option-${defaultButton.key}`}
      >
        {defaultButton.icon && (
          <div className={`${rootClass}__svg-container`}>
            <Svg name={defaultButton.icon} fill={buttonTypeValues.iconFill} type={SvgType.LARGER_ICON} dataTest={`${dataTest}-svg`} />
          </div>
        )}
        <Typography
          className={`${rootClass}__button-label`}
          text={defaultButton.title}
          type={buttonTypeValues.typographyType}
          lineHeight={lineHeight}
          weight={weight}
          textAlign={TextAlign.LEFT}
        />
      </Button>
    )
  }

  if (!options.length) {
    return null
  }

  if (options.length === 1) {
    return renderDefaultButton(true)
  }

  return (
    <DropDownMenuRoot open={isOpen} onOpenChange={setIsOpen}>
      <div className={classNames(rootClass, className)} data-test={dataTest} data-container>
        {/*This trigger needed to align Dropdown Content*/}
        {!largeDropdown && <DropDownTriggerItem className={`${rootClass}__button-trigger`} />}
        {renderDefaultButton()}
        <Button
          buttonType={buttonTypeValues.buttonType}
          disabled={disabled}
          className={classNames(`${rootClass}__button-toggle`, `${rootClass}__button`, {
            [`${rootClass}__button-${buttonTypeValues.buttonType.toLowerCase()}`]: !!buttonType,
            [`${rootClass}__button-toggle-${buttonTypeValues.buttonType.toLowerCase()}`]: !!buttonType,
            [`${rootClass}__button-toggle-${buttonTypeValues.buttonType.toLowerCase()}-open`]: isOpen,
          })}
          onClick={handleToggle}
          dataTest={`${dataTest}-toggle-button`}
        >
          <Svg
            name={dropdownCaretFill ? SvgNames.caretFillDown : SvgNames.caretDown}
            fill={buttonTypeValues.iconFill}
            type={SvgType.SMALLER_ICON}
            dataTest={`${dataTest}-toggle-svg`}
          />
        </Button>
        {largeDropdown && <DropDownTriggerItem className={`${rootClass}__button-trigger`} />}
      </div>
      <DropDownContent className={`${rootClass}__options__wrapper`} align={largeDropdown ? 'end' : 'start'} onKeyDown={handleKeyDown}>
        <div className={`${rootClass}__options`} data-test={`${dataTest}-options`} style={{ minWidth: contentWidth }}>
          {options.map(({ title, key, onClick, icon, disabled, disabledTooltip }, index) => {
            const isSelected = index === focusedElementIndex
            if (index !== 0) {
              const button = (
                <Button
                  key={key}
                  buttonType={ButtonType.ACTIVE_FILTER}
                  iconPosition={ButtonIconPosition.LEFT}
                  disabled={disabled}
                  className={classNames(`${rootClass}__option`, { [`${rootClass}__option-selected`]: isSelected })}
                  onClick={handleItemClick(onClick)}
                  dataTest={`${dataTest}-option-${key}`}
                >
                  {icon && <Svg name={icon} fill={SvgColor.TEXT_GRAY} type={SvgType.SMALLER_ICON} dataTest={`${dataTest}-option-svg`} />}
                  <Typography className={`${rootClass}__option-label`} text={title} lineHeight={optionLineHeight} weight={optionTextWeight} />
                </Button>
              )

              return !!disabledTooltip ? (
                <Tooltip trigger={button} key={key}>
                  {disabledTooltip}
                </Tooltip>
              ) : (
                button
              )
            }
            return null
          })}
        </div>
      </DropDownContent>
    </DropDownMenuRoot>
  )
}

export default SplitButton
