import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Row } from 'react-table'

import { Status } from '@components/StatusToast/StatusToast'
import { TOAST_TEXT_CLASSNAME } from '@components/Toast/Toast'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { ScoreSheet } from '@graphql/types/microservice/list-types'
import { ListMaintenanceProgramInput, Program, ProgramStatus as ListProgramStatus, ProgramStep } from '@graphql/types/query-types'
import { setStepExpandedState } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramStepsListing/components/ListProgramStep/utils/ListProgramStep.utils'
import ProgramStepsAndDetails from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/ProgramStepsAndDetails'
import { useListProgramSaveHandler } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/utils/ListProgramSaveHandler'
import { useProgramStepsAndDetailsQueries } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/utils/ProgramStepsAndDetails.graphQL'
import {
  getDeletedFieldErrors,
  getFormattedURL,
  getDeletedListError,
  getUpdatedStepErrors,
  isFormSubmissionSource,
  setProgramErrors,
  triggerStepEditEvent,
} from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/utils/ProgramStepsAndDetails.utils'
import { ListMaintenanceProgramsContext } from '@src/pages/listingPages/ListMaintenancePrograms/context/ListMaintenancePrograms.context'
import { LIST_MAINTENANCE_PROGRAMS_URL } from '@src/pages/listingPages/ListMaintenancePrograms/utils/Helpers'
import { ListField } from '@utils/listingPage/listMaintenancePrograms.utils'
import { logNewRelicError } from '@utils/new-relic.utils'

interface ProgramStepsAndDetailsProps {
  className?: string
  dataTest?: string
}

export interface ProgramStepsAndDetailsContainerState {
  deletedSteps: ProgramStep[]
  loading: boolean
}

const ProgramStepsAndDetailsContainer: FC<ProgramStepsAndDetailsProps> = (props: ProgramStepsAndDetailsProps) => {
  const { dataTest = '', className = '' } = props

  const {
    setProgram,
    update,
    values: { program, isEditing, programDetails, stepBeingEditedIndex, hasMarketingSource, newProgramId, programStatus, programSourceFields },
  } = useContext(ListMaintenanceProgramsContext)

  const [state, setState] = useState<ProgramStepsAndDetailsContainerState>({
    deletedSteps: [],
    loading: true,
  })
  const { deletedSteps, loading } = state

  const programSource = programDetails?.sourceList ?? []

  const { listen } = useHistory()

  const backToURL = useRef(getFormattedURL(LIST_MAINTENANCE_PROGRAMS_URL))
  const programDetailsBackup = useRef<Program | undefined>()
  const addedSteps = useRef(0)
  const stepsDeletedFieldChecked = useRef(false)
  const stepsMissedListChecked = useRef(false)
  const {
    getListMaintenanceProgramRequest,
    getScoreSheetsRequest,
    getListSchemaRequest,
    getCampaigns,
    getUnifiedFieldsRequest,
    getProgramErrorsRequest,
  } = useProgramStepsAndDetailsQueries()

  const onDeleteStep = ({ original, index }: Row<ProgramStep>) => {
    setState((state) => ({ ...state, deletedSteps: [...deletedSteps, original] }))
    const steps = programDetails?.steps?.filter((step) => step !== original)
    update({
      programDetails: { ...(programDetails as Program), steps },
      ...(stepBeingEditedIndex === index ? { stepBeingEditedIndex: undefined } : {}),
      ...(stepBeingEditedIndex !== undefined && stepBeingEditedIndex > index ? { stepBeingEditedIndex: stepBeingEditedIndex - 1 } : {}),
      ...(!isEditing ? { isEditing: true } : {}),
    })
  }

  const onEditStep = ({ index }: Row<ProgramStep>) => {
    triggerStepEditEvent(index)
    update({
      stepBeingEditedIndex: stepBeingEditedIndex === index ? undefined : index,
      ...(!isEditing ? { isEditing: true } : {}),
    })
  }

  const onAddStep = (position = 0, step?: ProgramStep) => {
    const currentSteps = programDetails?.steps ?? []
    const newStepId = `newStep-${addedSteps.current}`
    const steps = [...currentSteps.slice(0, position), { ...step, stepId: newStepId }, ...currentSteps.slice(position)]
    addedSteps.current = addedSteps.current + 1
    setStepExpandedState(newStepId, true, program?.id)
    update({
      stepBeingEditedIndex: position,
      programDetails: { ...(programDetails as Program), steps: steps as ProgramStep[] },
      ...(!isEditing ? { isEditing: true } : {}),
    })
  }

  const showSuccessToast = () => {
    update({
      statusToast: {
        statusMessage: (
          <Typography
            className={TOAST_TEXT_CLASSNAME}
            text={'Success! Your changes have been saved'}
            tagProps={{ bold: { weight: TextWeight.BOLD } }}
            inline
          />
        ),
        status: Status.SUCCESS,
        showStatusToast: true,
      },
    })
  }

  const getProgramErrors = async (temporalProgram?: ListMaintenanceProgramInput): Promise<ListProgramStatus | undefined> => {
    if (program || temporalProgram) {
      const programErrors = await getProgramErrorsRequest(temporalProgram ?? program?.externalId ?? '')

      if (programErrors) {
        const programSteps = (temporalProgram?.steps as ProgramStep[]) ?? programDetails?.steps ?? []
        const deletedFieldErrors = getDeletedFieldErrors(programSteps, programSourceFields, hasMarketingSource)

        const stepErrors = programErrors.stepErrors.map((stepError) => {
          const deletedFieldError = deletedFieldErrors.find(({ id }) => stepError.id === id)
          if (!!deletedFieldError) {
            return {
              ...stepError,
              errors: [...stepError.errors, ...(deletedFieldError ? deletedFieldError.errors : [])],
            }
          }
          return stepError
        })

        deletedFieldErrors.forEach((deletedFieldError) => {
          if (!stepErrors.find(({ id }) => id === deletedFieldError.id)) {
            stepErrors.push(deletedFieldError)
          }
        })

        return {
          ...programErrors,
          stepErrors,
          valid: programErrors.valid && stepErrors.length === 0,
        }
      }
    }
  }

  const loadProgram = (useCache = true) => {
    if (program) {
      setState((state) => ({ ...state, loading: true }))
      Promise.all([getProgramErrors(), getListMaintenanceProgramRequest(program.externalId, useCache)])
        .then(([errorsResponse, programDetails]) => {
          const hasMarketingSource = !programDetails.hasActOnContactList && !isFormSubmissionSource(programDetails.sourceList[0])
          if (errorsResponse) {
            setProgramErrors(errorsResponse, hasMarketingSource, update, stepsDeletedFieldChecked, stepsMissedListChecked)
          }
          if (programDetails) {
            update({ programDetails, hasMarketingSource })
            programDetailsBackup.current = programDetails
          }
        })
        .catch((error) => logNewRelicError(error))
        .finally(() => setState((state) => ({ ...state, loading: false })))
    }
  }

  const loadStepsEditingData = () => {
    Promise.all([getScoreSheetsRequest(), getCampaigns()])
      .then((responses) => {
        const [{ data: scoreSheetsData }, campaigns = []] = responses
        update({
          scoreSheets: (scoreSheetsData?.scoreSheets as ScoreSheet[]) ?? [],
          campaigns,
        })
      })
      .catch((error) => {
        logNewRelicError(error)
        update({
          statusToast: {
            statusMessage: 'Something went wrong on our end.',
            status: Status.FAIL,
            showStatusToast: true,
          },
        })
      })
  }

  const { onSave, onCancel } = useListProgramSaveHandler({
    programDetailsBackup: programDetailsBackup.current,
    deletedSteps,
    loadProgram,
    getProgramErrors,
    showSuccessToast,
    setState,
    stepsDeletedFieldChecked,
    stepsMissedListChecked,
  })

  useEffect(() => {
    if (
      programDetails?.steps &&
      programSourceFields.length &&
      programStatus &&
      !stepsDeletedFieldChecked.current &&
      !stepsMissedListChecked.current
    ) {
      stepsDeletedFieldChecked.current = true
      stepsMissedListChecked.current = true
      const deletedFieldErrors = getDeletedFieldErrors(programDetails.steps, programSourceFields, hasMarketingSource)
      const deletedListErrors = getDeletedListError(programDetails.steps, programSourceFields, hasMarketingSource)

      if (deletedFieldErrors.length || deletedListErrors.length) {
        const updatedStepErrors = getUpdatedStepErrors(programStatus, [...deletedFieldErrors, ...deletedListErrors])
        update({ programStatus: { ...programStatus, stepErrors: updatedStepErrors, valid: false } as ListProgramStatus })
      }
    }
  }, [programDetails, programSourceFields, programStatus])

  useEffect(() => {
    loadProgram(false)
    loadStepsEditingData()
    update({ statusToast: undefined })

    const unregisterListen = listen(({ search }) => {
      const searchParams = new URLSearchParams(search)
      backToURL.current = getFormattedURL(searchParams.get('backTo') || LIST_MAINTENANCE_PROGRAMS_URL)
      if (!searchParams.has('programId')) {
        setProgram(undefined)
      }
    })

    return () => {
      unregisterListen()
      update({
        isEditing: false,
        programStatus: undefined,
        programDetails: undefined,
        programSourceFields: [],
        statusToast: undefined,
        stepBeingEditedIndex: undefined,
        newProgramId: undefined,
      })
    }
  }, [])

  useEffect(() => {
    const isNewProgram = newProgramId === program?.externalId
    if (!loading && programDetails && isNewProgram && programDetails.steps?.length === 0) {
      onAddStep()
      update({ newProgramId: undefined })
    }
  }, [loading])

  useEffect(() => {
    if (programSource.length > 0) {
      if (hasMarketingSource) {
        getListSchemaRequest(programSource[0].id).then(({ data }) => {
          const headers = [...(data?.listSchemas[0].schema?.headers ?? [])].sort().map((field) => ({ field, type: 'TEXT' })) as ListField[]
          update({ programSourceFields: headers })
        })
      } else {
        getUnifiedFieldsRequest().then(({ data }) => {
          if (data?.unifiedListFieldMappings) {
            const fields = data.unifiedListFieldMappings
              .filter((mapping) => !mapping?.deleted && !mapping?.readOnly && !mapping?.hidden)
              .map((mapping) => ({ field: mapping?.displayName || '', type: mapping?.dataType || 'TEXT' }))
              .sort((a, b) => (a.field > b.field ? 1 : -1)) as ListField[]
            update({ programSourceFields: fields })
          }
        })
      }
    }
  }, [programSource])

  return (
    <ProgramStepsAndDetails
      className={className}
      dataTest={dataTest}
      onAddStep={onAddStep}
      onEditStep={onEditStep}
      onDeleteStep={onDeleteStep}
      onSaveChanges={onSave}
      onCancelChanges={onCancel}
      reloadProgram={loadProgram}
      loading={loading}
      backUrlRef={backToURL}
    />
  )
}

export default ProgramStepsAndDetailsContainer
