import { gql } from '@apollo/client'

export default gql`
  query GetEntityRecordTable($type: String, $count: Int, $offset: Int, $sort: Boolean) {
    entityType(entityType: $type, count: $count, offset: $offset, sort: $sort) {
      entityTypeFields {
        name
        label
      }
      entityTypeDataMapping
    }
  }
`
