import React from 'react'

import classNames from 'classnames'

import { ListingPageSubType } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { RenderCustomFiltersParams } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { ListingPageSession } from '@complex/ListingPage/Utils/ListingPage.utils'
import Checkbox from '@components/Checkbox'
import CheckboxGroup from '@components/CheckboxGroup/CheckboxGroup'
import { CollapsibleMenuItemWithHeaderData } from '@components/CollapsibleMenu/CollapsibleMenu'
import Svg, { SvgColor, SvgNames, SvgType } from '@components/Svg'
import TextLink from '@components/TextLink/TextLink'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType } from '@components/Typography/Typography'
import { eventTriggeredEmailsFilter } from '@utils/filter'
import { getItem } from '@utils/sessionStorage'

export const emailTemplatesSubTypes: ListingPageSubType[] = [{ name: 'event_triggered', label: 'Event triggered email' }]

export const renderCustomFilters = (
  { activeSubTypes, menuActions, filterCounts, renderCustomFilterWithCount, t }: RenderCustomFiltersParams,
  rootClass: string
): CollapsibleMenuItemWithHeaderData[] =>
  filterCounts['event_triggered'] === 0 || filterCounts['event_triggered'] === undefined
    ? []
    : [
        {
          header: t('Filter by'),
          isOpen: getItem(ListingPageSession.CUSTOM_MENU_SECTION_CLOSED) !== 'true',
          icon: SvgNames.toggles,
          dataTest: 'menu-items',
          content: (
            <CheckboxGroup key={'filter-by'} className={classNames(`${rootClass}__custom-filter`, `listing-page__custom-filters`)} verticalLayout>
              {renderCustomFilterWithCount(
                <>
                  <Checkbox
                    label={t(eventTriggeredEmailsFilter.name)}
                    checked={activeSubTypes.includes('event_triggered')}
                    onChange={() => menuActions.clickSubType('event_triggered')}
                    dataTest={`list-page-container-ete-filter`}
                    className={`ellip`}
                  />
                  {rootClass.includes('listing-page') && (
                    <Tooltip
                      trigger={<Svg name={SvgNames.info} type={SvgType.ICON} fill={SvgColor.LIGHT_GRAY} />}
                      triggerClassName={`${rootClass}__tooltip`}
                      isPopover
                    >
                      <Typography
                        text={t('ListPage.EmailTemplates.TextLink')}
                        inline
                        tagComponents={{
                          TextLink: (
                            <TextLink
                              link={'https://developer.act-on.com/hc/en-us/articles/11914053567767-Event-Triggered-Email-Overview'}
                              text={t('Learn More')}
                              isWhite
                              underline
                              className={`${rootClass}__text-link`}
                            />
                          ),
                        }}
                        type={TextType.BODY_TEXT_WHITE}
                      />
                    </Tooltip>
                  )}
                </>,
                filterCounts['event_triggered']
              )}
              <></>
            </CheckboxGroup>
          ),
        },
      ]
