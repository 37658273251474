import React, { FC, useState } from 'react'

import DropDown from '@components/DropDown'
import { DropDownType } from '@components/DropDown/DropDown'
import DropDownActions, { MenuItem } from '@components/DropDownActions/DropDownActions'

import './BreadcrumbsDropDown.css'

interface Props {
  menuItems: MenuItem[]
  onDropDownOpen: (open: boolean) => void
}

const rootClass = 'breadcrumbs-drop-down'

const BreadcrumbsDropDown: FC<Props> = ({ menuItems, onDropDownOpen }: Props) => {
  const [dropDownOpen, setDropDownOpen] = useState<boolean>(false)

  return (
    <DropDown
      className={rootClass}
      type={DropDownType.STYLED}
      hasOverflowIcon
      isOpen={dropDownOpen}
      toggleOpen={(open) => {
        onDropDownOpen?.(open)
        setDropDownOpen(() => open)
      }}
      contentClassName={`${rootClass}__content`}
    >
      <DropDownActions className={`${rootClass}__actions`} menuItems={menuItems} closeDropDown={() => setDropDownOpen(() => false)} hasEllipsis />
    </DropDown>
  )
}

export default BreadcrumbsDropDown
