import React, { FC } from 'react'

import classNames from 'classnames'

import { SelectV2MenuFooterProps } from '@components/SelectV2/components/SelectV2MenuFooter/SelectV2MenuFooter'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2Option } from '@components/SelectV2/SelectV2.props'
import { useTranslation } from '@const/globals'
import { EngagementActivity } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/BehaviorExpression/BehaviorExpression.constants'

import './EngagementActivitySelect.css'

interface EngagementActivitySelectProps {
  className?: string
  dataTest?: string
  defaultValues?: SelectV2Option[]
  onChange: (activities: SelectV2Option[]) => void
}

const rootClass = 'engagement-activity-select'

const EngagementActivitySelect: FC<EngagementActivitySelectProps> = (props: EngagementActivitySelectProps) => {
  const { dataTest = rootClass, className = '', defaultValues, onChange } = props
  const { t } = useTranslation()

  const options = Object.values(EngagementActivity).map((activity) => ({
    value: activity,
    label: t(`SegmentComposer.Build.EngagementActivity`, { context: activity }),
  }))
  const allOptionsSelected = defaultValues?.length === options.length

  const selectFooter: SelectV2MenuFooterProps = {
    buttons: {
      primaryButtonLabel: t(allOptionsSelected ? 'Unselect all' : 'Select all'),
      primaryButtonOnClick: () => {
        onChange(allOptionsSelected ? [] : options)
      },
    },
  }

  return (
    <SelectV2
      className={classNames(rootClass, className)}
      dataTest={dataTest}
      value={defaultValues}
      options={options}
      onChangeMultiple={onChange}
      footer={selectFooter}
      truncateMultiValues
      minSearchOptions={1}
    />
  )
}

export default EngagementActivitySelect
