import { gql } from '@apollo/client'

export default gql`
  query crmSyncResults {
    lastSync {
      started
      ended
      pullOptInDeleteCount
      pushOptInDeleteCount
      pullOptOutAppendCount
      pushOptOutAppendCount
      entitySyncKeyValueList {
        modelEntityTypeString
        entitySyncDetailBean {
          appendCount
          updateCount
          deleteCount
        }
      }
    }
  }
`
