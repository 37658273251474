import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import { MediaItem } from '@components/AssetPickers/MediaPickerModal/MediaPickerModal.context'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { SvgNames } from '@components/Svg'
import { useTranslation } from '@const/globals'
import { AddMediaLinkMutation, AddMediaLinkMutationVariables } from '@graphql/types/mutation-types'
import SelectAssetModal from '@src/pages/EmailComposer/EmailModals/components/SelectAssetModal/SelectAssetModal'
import { AssetLinkTypes, isMediaItem } from '@utils/composer/EmailModal.constants'
import { MediaFileFlow } from '@utils/media'

import { SESSION_MEDIA_SELECTED, getSessionMediaSelected, mediaFileAssetFlows } from './MediaFileModal.constants'
import MediaLink, { MediaLinkInsertArgs } from './MediaLink/MediaLink'
import UploadFile, { UploadArgs } from './UploadFile/UploadFile'

interface MediaFileModalProps {
  className?: string
  dataTest?: string
  handleClickBack: () => void
  handleCancel: () => void
  handleInsert: (item?: MediaLinkInsertArgs) => void
  isOpen: boolean
  folders: SelectV2SingleOption[]
  uploadMediaFile: (args: UploadArgs | undefined) => Promise<void>
  handleAddLink: (variables: AddMediaLinkMutationVariables) => Promise<AddMediaLinkMutation | undefined>
}

const rootClass = 'media-file-modal'

const MediaFileModal: FC<MediaFileModalProps> = (props: MediaFileModalProps) => {
  const { dataTest = rootClass, className = '', handleCancel, handleClickBack, handleInsert, folders, uploadMediaFile, handleAddLink, isOpen } = props
  const [pickerModalOpen, setPickerModalOpen] = useState(true)
  const [flowName, setFlowName] = useState<MediaFileFlow | undefined>(undefined)
  const [selectedItem, setSelectedItem] = useState<MediaItem | undefined>(getSessionMediaSelected())

  const { t } = useTranslation()

  const buttonSvg = !selectedItem ? SvgNames.mediaLibrary : selectedItem?.docType === 'URL' ? SvgNames.url : SvgNames.blankMessage

  const modalTitle = selectedItem?.docType === 'FILE' ? 'Insert media file' : 'Insert media link'

  useEffect(() => {
    sessionStorage.setItem(SESSION_MEDIA_SELECTED, JSON.stringify(selectedItem))
  }, [selectedItem])

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <MediaLink
        isOpen={flowName === MediaFileFlow.LINK}
        handleBack={() => setFlowName(undefined)}
        handleCancel={handleCancel}
        folders={folders}
        handleAddLink={handleAddLink}
        handleInsert={handleInsert}
      />
      <UploadFile
        isOpen={flowName === MediaFileFlow.UPLOAD}
        handleBack={() => setFlowName(undefined)}
        handleCancel={handleCancel}
        folders={folders}
        onFileUpload={uploadMediaFile}
      />
      <SelectAssetModal
        isOpen={isOpen && !flowName}
        handleInsert={() => {
          if (selectedItem) {
            handleInsert({ url: selectedItem?.downloadLink ?? selectedItem.previewUrl, title: selectedItem.title })
          }
        }}
        handleBack={() => {
          setPickerModalOpen(true)
        }}
        handlePickerBack={handleClickBack}
        handlePickerClose={() => {
          selectedItem ? setPickerModalOpen(false) : handleClickBack()
        }}
        headerText={t(modalTitle)}
        mainButtonSvg={buttonSvg}
        mainButtonText={t('Select from media library')}
        handleChange={(item) => {
          if (isMediaItem(item)) {
            setPickerModalOpen(false)
            setSelectedItem(item)
          }
        }}
        pickerModalOpen={pickerModalOpen}
        handlePickerModalOpen={() => {
          setPickerModalOpen(true)
        }}
        assetType={AssetLinkTypes.FILE}
        description={t('EmailComposer.AssetLinks.MediaFile.Modal.Description')}
        handleClose={handleCancel}
        assetFlows={mediaFileAssetFlows}
        handleFlow={(flowName) => setFlowName(flowName as MediaFileFlow)}
        selectedAsset={selectedItem}
      />
    </div>
  )
}

export default MediaFileModal
