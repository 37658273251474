import React, { ChangeEvent, ReactNode, useCallback, useEffect, useState } from 'react'

import classNames from 'classnames'

import FormRow from '@components/FormRow'
import Label from '@components/Label'
import Select from '@components/Select/Select'
import { SecurityOptionType } from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Security/constants/security.options'

interface Props {
  options: SecurityOptionType[]
  onSelect: ({ name }: { [key: string]: string }) => void
  name: string
  label: string | ReactNode
  value?: string
  defaultValue?: string
  dataTest?: string
}

const rootClass = 'security-setting-dropdown'

const SecuritySettingDropdown = (props: Props) => {
  const { options, onSelect, name, value, label, dataTest = rootClass, ...rest } = props
  const [currentValue, setCurrentValue] = useState<string | undefined>(value)

  useEffect(() => setCurrentValue(value), [value])

  const handleSubmit = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const stringVal = e.target.value
      onSelect({ [name]: stringVal })
      setCurrentValue(stringVal)
    },
    [onSelect, name]
  )

  return (
    <FormRow>
      {typeof label === 'string' ? <Label>{label}</Label> : label}
      <Select onChange={handleSubmit} value={currentValue} className={classNames(rootClass, 'ellip')} dataTest={`${dataTest}__${name}`} {...rest}>
        {options.map(({ id, label }) => (
          <option key={id} value={id}>
            {label}
          </option>
        ))}
      </Select>
    </FormRow>
  )
}

export default SecuritySettingDropdown
