import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import React from 'react'

import { renderCheckboxCell } from '@components/Table/Table'
import { OverrideCheckboxCellReturnType } from '@components/TableV2/tableV2TS/interfaces'
import { IndeterminateCheckbox } from '@components/TableV2/utils/tableV2Utils'
import { SforceUserResponse } from '@graphql/types/query-types'
import { Row } from '@tanstack/react-table'

interface Props {
  onAdd: (ids: string[]) => void
  availableUsersNumber: number
  isOpen: boolean
}

interface ReturnProps {
  onRowSelectionChanged: (ids: string[], rows: { original: SforceUserResponse; id: string }[]) => void
  onSave: () => void
  disableSave: boolean
  invalidSelection: boolean
  availableRemaining: number
  selectedCount: number
  checkboxCellOverride: (row: any) => ReactNode
  overrideCheckboxCell: (row: Row<SforceUserResponse>) => OverrideCheckboxCellReturnType
}

const useSelectImportSalesUsers: (props: Props) => ReturnProps = ({ onAdd, availableUsersNumber, isOpen }) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [availableRemaining, setAvailableRemaining] = useState<number>(0)
  const disableOptionsRef = useRef<{ canSelectMore: boolean; selectedIds: string[] }>({ canSelectMore: true, selectedIds })

  useEffect(() => {
    if (isOpen) {
      setSelectedIds([])
      setAvailableRemaining(availableUsersNumber)
      disableOptionsRef.current.canSelectMore = !availableUsersNumber
      disableOptionsRef.current.selectedIds = []
    }
  }, [isOpen, availableUsersNumber])

  const onRowSelectionChanged = useCallback(
    (ids: string[], rows: { original: SforceUserResponse; id: string }[]) => {
      const newSelectedIds = rows
        .filter(({ id, original }) => !original.role && original.externalId && ids.includes(id))
        .map(({ original }) => original.externalId)
      const newAvailableUsersNumber = Math.max(availableUsersNumber - newSelectedIds.length, 0)
      setSelectedIds(newSelectedIds as string[])
      setAvailableRemaining(newAvailableUsersNumber)

      disableOptionsRef.current.selectedIds = newSelectedIds as string[]
      disableOptionsRef.current.canSelectMore = newAvailableUsersNumber <= 0
    },
    [availableUsersNumber]
  )

  const onSave = useCallback<() => void>(() => onAdd(selectedIds), [selectedIds, onAdd])
  const selectedCount = useMemo<number>(() => selectedIds.length, [selectedIds.length])
  const invalidSelection = useMemo<boolean>(() => selectedIds.length > availableUsersNumber, [selectedIds.length, availableUsersNumber])
  const disableSave = useMemo<boolean>(() => !selectedIds.length || invalidSelection, [selectedIds.length, invalidSelection])

  const checkboxCellOverride = useCallback<(row: any) => ReactNode>((row: any) => {
    /** Using disableOptionsRef to bbe sure that checkboxCellOverride will work as expected */
    const disabled =
      row.original.role === 'Sales' ||
      (disableOptionsRef.current.canSelectMore &&
        !!row.original.externalId &&
        !disableOptionsRef.current.selectedIds.includes(row.original.externalId))
    const original = { ...row?.original, disabled }

    return renderCheckboxCell({ ...row, original })
  }, [])

  const overrideCheckboxCell = useCallback((row: Row<SforceUserResponse>) => {
    const disabled =
      row.original.role === 'Sales' ||
      (disableOptionsRef.current.canSelectMore &&
        !!row.original.externalId &&
        !disableOptionsRef.current.selectedIds.includes(row.original.externalId))
    const original = { ...row.original, disabled }
    const changedRow = { ...row, original }

    return {
      cellStatus: disabled,
      cellValue: (
        <IndeterminateCheckbox
          checked={changedRow.getIsSelected()}
          indeterminate={!changedRow.original.disabled && changedRow.getIsSomeSelected()}
          onChange={changedRow.getToggleSelectedHandler()}
          disabled={changedRow.original.disabled}
        />
      ),
    }
  }, [])

  return {
    onRowSelectionChanged,
    overrideCheckboxCell,
    onSave,
    disableSave,
    availableRemaining,
    selectedCount,
    checkboxCellOverride,
    invalidSelection,
  }
}

export default useSelectImportSalesUsers
