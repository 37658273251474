import { useTranslation } from '@utils/const/globals'
import { allEmailTemplatesFilter } from '@utils/filter'
import { emailTemplatesSubTypes, renderCustomFilters as renderTemplatesCustomFilters } from '@utils/listingPage/emailTemplates'

import {
  renderSearchTableColumns as renderTemplatesSearchColumns,
  renderTableColumns as renderTemplatesTableColumns,
} from '../../EmailTemplatesPickerModal/EmailTemplatesPickerModal.tables'
import { EmailPickerProps, EmailType } from '../useEmailPickerProps'

export const useEmailTemplatesPickerProps = () => {
  const { t } = useTranslation()

  const config: EmailPickerProps = {
    customSources: {
      allItemFilter: allEmailTemplatesFilter,
      itemName: 'Email',
      label: 'Email templates',
      mainColumnName: 'Email Title',
      value: EmailType.TEMPLATE,
      itemType: EmailType.TEMPLATE,
      sidebarProps: {
        hideTags: false,
        hideFolders: false,
      },
    },
    renderSearchColumns: (_searchAllItems, _currentFolder, search, folders) => renderTemplatesSearchColumns(search, folders, t),
    renderTableColumns: renderTemplatesTableColumns(t),
    renderCustomFilters: (params) => renderTemplatesCustomFilters(params, 'email-picker-modal'),
    sortingBy: [{ id: 'lastUpdated', desc: true }],
    hasCustomFilters: true,
    hasTags: true,
    hasFavorites: true,
    hasCreatedByMe: true,
    subTypes: emailTemplatesSubTypes,
    i18nListPageKey: 'Emails.DraftsAndTemplates',
  }
  return config
}
