import { ApolloClient } from '@apollo/client'
import getStatSendableContacts from '@graphql/microservices/segment/getStatSendableContacts'
import { StatValueDto } from '@graphql/types/microservice/segment-types'
import { getFormattedNumber } from '@utils/numbers'

enum SegmentStats {
  SENDABLE_CONTACTS = 'Sendable Contacts',
}

export const segmentPerformanceCards = (totalContacts: number, sendableContacts: StatValueDto, t: Function) => ({
  cardsTitle: t('Performance'),
  cardsSubTitle: '',
  cards: [
    {
      label: t('Total Contacts'),
      labelContent: {
        primary: getFormattedNumber(totalContacts),
      },
    },
    {
      label: t(SegmentStats.SENDABLE_CONTACTS),
      tooltip: t('Total contacts in this segment who are eligible to receive email'),
      labelContent: {
        primary: `${sendableContacts.percentageValue}%`,
        secondary: sendableContacts.value.toString(),
      },
    },
  ],
})

export const getAllSegmentStats = (externalId: string, client: ApolloClient<any>, setState: Function) => {
  Object.values(SegmentStats).forEach((stat) => getSegmentStat(externalId, stat, client, setState))
}

const getSegmentStat = (externalId: string, stat: SegmentStats, client: ApolloClient<any>, setState: Function) => {
  const getStatsQuery = {
    [SegmentStats.SENDABLE_CONTACTS]: { query: getStatSendableContacts, field: 'getStatSendableContacts', stateValue: 'sendableContacts' },
  }
  const { query, field, stateValue } = getStatsQuery[stat]

  client
    .query({ query, fetchPolicy: 'network-only', variables: { externalId } })
    .then(({ data }) => setState((state: any) => ({ ...state, [stateValue]: data[field] })))
}
