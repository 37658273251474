import React, { FC, useCallback, useContext, useMemo } from 'react'

import Button, { ButtonType } from '@components/Button'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal/ConfirmationModal'
import CustomPrompt from '@components/CustomPrompt/CustomPrompt'
import DirtyBanner from '@components/DirtyBanner/DirtyBanner'
import PageContainer from '@components/PageContainer'
import PageHeader from '@components/PageHeader'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import StatusToast from '@components/StatusToast/StatusToast'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { AddContactsFromCRMContext, RADIO_OPTION } from '@src/pages/ContactSegments/components/AddContactsFromCRM/AddContactsFromCRMContext'
import CRMSegmentDetails from '@src/pages/ContactSegments/components/AddContactsFromCRM/components/CRMSegmentDetails/CRMSegmentDetails'
import SelectCRMSources from '@src/pages/ContactSegments/components/AddContactsFromCRM/components/SelectCRMSources/SelectCRMSources'
import { showFailedStatusToast } from '@src/pages/ContactSegments/components/AddContactsFromCRM/Helpers/AddContactsFromCRM.crm.helper'
import { CRM_STATUS } from '@utils/crm.utils'

import './AddContactsFromCRM.css'

export interface AddContactsFromCRMProps {
  dataTest?: string
  onDiscard: () => void
  onSave: (syncNowTriggered: boolean) => void
  onSyncNow: () => void
}

const rootClass = 'add-contacts-from-crm'

const AddContactsFromCRM: FC<AddContactsFromCRMProps> = (props: AddContactsFromCRMProps) => {
  const { dataTest = rootClass, onDiscard, onSave, onSyncNow } = props

  const {
    values: {
      invalidSources,
      isDirty,
      radioOption,
      segmentName,
      selectedOption,
      showDifferentTypeError,
      showSyncNowModal,
      sources,
      statusToast,
      validatingSources,
      validSegmentName,
    },
    update,
    isAValidSegmentName,
  } = useContext(AddContactsFromCRMContext)

  const { t } = useTranslation()

  const isNewSegment = radioOption === RADIO_OPTION.CREATE

  const [saveButtonText, discardButtonText, headerText] = useMemo(
    () =>
      isNewSegment
        ? [t('Create segment'), t('Discard changes'), t('Add Segment from CRM')]
        : [t('Save changes'), t('Discard'), t('Edit CRM segment')],
    [isNewSegment]
  )

  const closeStatusToast = useCallback(() => update({ statusToast: undefined }), [statusToast])

  const onShowSyncNowModal = async () => {
    const validSegmentName = await isAValidSegmentName(segmentName ?? '')

    validSegmentName
      ? update({ showSyncNowModal: true })
      : showFailedStatusToast({ statusMessage: 'AddContactsFromCRM.DirtyBanner.Not.Valid.SegmentName.SaveButton.Toast', update })
  }

  const saveButtonTooltipText = useMemo(() => {
    if (validatingSources) {
      return 'AddContactsFromCRM.DirtyBanner.ValidatingSources'
    }

    const invalidSourceStatuses = Object.values(invalidSources)
      .flatMap((source) => source)
      .map(({ status }) => status)

    if (
      invalidSourceStatuses.some((status) =>
        [
          CRM_STATUS.SF_REPORT_ID_FIELD_MISSING,
          CRM_STATUS.SF_REPORT_UNSUPPORTED_TYPE,
          CRM_STATUS.SF_REPORT_UNSUPPORTED_FORMAT,
          CRM_STATUS.SF_REPORT_MAX_RECORDS_REACHED,
        ].includes(status)
      )
    ) {
      return 'AddContactsFromCRM.DirtyBanner.InvalidSources.Tooltip'
    }

    const hasNonEmptySource = Object.values(sources).some((source) => source.length > 0)
    if (!hasNonEmptySource) {
      return 'AddContactsFromCRM.DirtyBanner.Empty.Sources.SaveButton.Tooltip'
    }

    if (showDifferentTypeError) {
      return 'AddContactsFromCRM.CRMSegmentDetails.Select.DifferentType.Tooltip'
    }

    if (isNewSegment) {
      if (!segmentName) {
        return 'AddContactsFromCRM.DirtyBanner.Empty.SegmentName.SaveButton.Tooltip'
      }
      if (!validSegmentName) {
        return 'AddContactsFromCRM.DirtyBanner.Not.Valid.SegmentName.SaveButton.Tooltip'
      }
    }

    if (radioOption === RADIO_OPTION.EDIT && !selectedOption) {
      return 'AddContactsFromCRM.DirtyBanner.Empty.SelectedOption.SaveButton.Tooltip'
    }

    return ''
  }, [invalidSources, radioOption, segmentName, selectedOption, showDifferentTypeError, sources, validatingSources, validSegmentName])

  const syncNow = useCallback(() => {
    update({ isDirty: false, showSyncNowModal: false })
    onSave(true)
    onSyncNow()
  }, [onSave, onSyncNow, update])

  const cancelSyncNowModal = useCallback(() => update({ showSyncNowModal: false }), [showSyncNowModal])

  const syncLater = useCallback(() => {
    update({ isDirty: false, showSyncNowModal: false })
    onSave(false)
  }, [onSave, update])

  const onAnswer = (answer: YesNo) => (answer === YesNo.YES ? syncNow() : cancelSyncNowModal())

  return (
    <PageContainer className={rootClass} dataTest={dataTest}>
      <CustomPrompt
        when={isDirty}
        title={t('You have unsaved changes')}
        body={t('Leave.And.Lose.Changes.Message')}
        cancelButtonText={t('Keep editing')}
        okButtonText={t('Discard changes')}
        showOnLocationChange={({ search }) => !search.includes('/contacts/crm')}
      />
      {statusToast && <StatusToast message={statusToast.statusMessage} status={statusToast.status} closeStatus={closeStatusToast} />}
      {isDirty && (
        <DirtyBanner
          expanded
          onSave={onShowSyncNowModal}
          onDiscard={onDiscard}
          saveDisabled={!!saveButtonTooltipText}
          discardButtonText={discardButtonText}
          saveButtonText={t(saveButtonText)}
          saveButtonTooltipText={saveButtonTooltipText}
        />
      )}
      <ConfirmationModal
        body={
          <Typography
            className={`${rootClass}__sync-now-modal-body-text`}
            text={t(
              'Sync now to populate your segments instantly. Otherwise, contacts will be added to your segments during the next scheduled sync.'
            )}
            type={TextType.BODY_TEXT_LIGHT}
          />
        }
        title={t('Sync your CRM segments now?')}
        isOpen={showSyncNowModal}
        isYesNo
        yesButtonText={'Sync now'}
        onAnswer={onAnswer}
        footerActionElement={
          <Button buttonType={ButtonType.INFO} onClick={syncLater}>
            {t('Sync later')}
          </Button>
        }
      />
      <PositionContainer noOverflow>
        <PageHeader primaryText={t(headerText)} leftContent linkBack />
        <Typography
          className={`${rootClass}__subheader`}
          text={t('Follow the steps below to segment contacts from your CRM lists & reports.')}
          type={TextType.BODY_TEXT_LIGHT}
        />
        <SelectCRMSources />
        <CRMSegmentDetails />
      </PositionContainer>
    </PageContainer>
  )
}

export default AddContactsFromCRM
