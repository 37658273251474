import React from 'react'

import BaseTag from '@components/BaseTag/BaseTag'
import TagManager from '@components/TagManager/TagManager'
import TagViewer from '@components/TagViewer/TagViewer'
import TagWithName from '@components/TagWithName/TagWithName'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import { sortTagsByName } from '@utils/tags'
import { NO_COLOR } from '@utils/tags'

import { TableTagState, TagColProps } from '../../../tableV2TS/interfaces'
import { renderTagManagerTriggerWithTooltip } from '../../../utils/tableV2Utils'

export const RenderTags = <
  T extends {
    [x: string]: number
  }
>({
  t,
  row,
  tags,
  tagsRef,
  disabled = false,
  rootClass,
  appliedTags,
  readOnlyTags,
  selectedTagId,
  waitingTagRef,
  numberOfTagsDisplayed = 3,
  tagAction,
  setTagState,
  onCreateTag,
  onApplyAndRemoveTags,
}: TagColProps<T>) => {
  const title = readOnlyTags ? 'View Tags' : 'Manage Tags'
  const sortedAppliedTags = sortTagsByName(appliedTags)
  let tagsDisplayed = sortedAppliedTags.slice(0, numberOfTagsDisplayed)

  let selectedTagFound: LabelDto
  if (selectedTagId) {
    selectedTagFound = appliedTags.find(({ id }) => selectedTagId === id) as LabelDto
    if (selectedTagFound) {
      const filteredTags = tagsDisplayed.filter(({ id }) => selectedTagId !== id)
      tagsDisplayed = [selectedTagFound, ...filteredTags.slice(0, numberOfTagsDisplayed - 1)]
    }
  }

  const onToggleDropdown = (hover: boolean) =>
    setTagState((state: TableTagState) => {
      return { ...state, hover, openedRowID: row.id }
    })

  return (
    <>
      {sortedAppliedTags[numberOfTagsDisplayed] && (
        <TagWithName
          key={numberOfTagsDisplayed}
          className={`${rootClass}__hidden-tag`}
          tagNameRef={waitingTagRef}
          color={sortedAppliedTags[numberOfTagsDisplayed].color as string}
          name={sortedAppliedTags[numberOfTagsDisplayed].name as string}
        />
      )}
      <div ref={tagsRef} className={`${rootClass}__tags`}>
        {tagsDisplayed.map(({ color = NO_COLOR, name }) => {
          const isSelected = selectedTagId !== 0 && selectedTagFound?.name === name
          return tagsDisplayed.length != 1 && !isSelected ? (
            <BaseTag key={name} color={color} name={name as string} onClick={tagAction} />
          ) : (
            <TagWithName key={name} color={color} name={name as string} onClick={tagAction} disabled={disabled} />
          )
        })}
        {readOnlyTags && appliedTags.length > tagsDisplayed.length ? (
          <TagViewer
            onToggleDropDown={onToggleDropdown}
            tags={sortTagsByName(appliedTags)}
            trigger={renderTagManagerTriggerWithTooltip(t(title), { appliedTags, numberOfTagsDisplayed, rootClass })}
          />
        ) : !readOnlyTags ? (
          <TagManager
            appliedTags={appliedTags}
            tags={tags}
            trigger={renderTagManagerTriggerWithTooltip(t(title), { appliedTags, numberOfTagsDisplayed, rootClass })}
            onApplyAndRemove={(tagsToApply: LabelDto[], tagsToRemove: number[]) =>
              onApplyAndRemoveTags ? onApplyAndRemoveTags([row.original.id], tagsToApply, tagsToRemove) : false
            }
            onCreate={(color, tagName) => (onCreateTag ? onCreateTag({ color, name: tagName }) : false)}
            onToggleDropDown={onToggleDropdown}
            title={title}
          />
        ) : null}
      </div>
    </>
  )
}
