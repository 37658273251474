import React, { FC } from 'react'

import classNames from 'classnames'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './SendAlertDetail.css'

export interface SendAlertDetailProps {
  // stepId: string
  emails: string[]
  users: string[]
  crmOwner: boolean
  subject: string
  message: string
  className?: string
  dataTest?: string
}

const rootClass = 'send-alert-detail'

const SendAlertDetail: FC<SendAlertDetailProps> = (props: SendAlertDetailProps) => {
  const { emails, users, crmOwner, subject, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()

  const userText = (
    <>
      <Svg name={SvgNames.contacts} type={SvgType.ICON} />
      {users.length > 0 && <Typography text={users.join(', ')} weight={TextWeight.MEDIUM} inline />}
      {users.length === 0 && <Typography text={t('undefined')} weight={TextWeight.ITALIC} inline />}
    </>
  )

  const emailsText = (
    <>
      <Svg name={SvgNames.letter} type={SvgType.ICON} />
      {emails.length > 0 && <Typography text={emails.join(', ')} weight={TextWeight.MEDIUM} inline />}
      {emails.length === 0 && <Typography text={t('undefined')} weight={TextWeight.ITALIC} inline />}
    </>
  )

  const crmOwnerText = (
    <>
      <Svg name={SvgNames.crm} type={SvgType.ICON} /> <Typography text={t('CRM owner of contact')} weight={TextWeight.MEDIUM} inline />
    </>
  )

  const renderRecipients = () => {
    if (users.length === 0 && emails.length === 0) {
      if (crmOwner) {
        return crmOwnerText
      } else {
        return <Typography text={t('No contacts or emails selected')} type={TextType.BODY_TEXT_LIGHT} inline />
      }
    }

    if (users.length === 0) {
      return crmOwner ? (
        <>
          <div>{emailsText}</div>
          <div>{crmOwnerText}</div>
        </>
      ) : (
        <div>{emailsText}</div>
      )
    }

    if (emails.length === 0) {
      return crmOwner ? (
        <>
          <div>{userText}</div>
          <div>{crmOwnerText}</div>
        </>
      ) : (
        <div>{userText}</div>
      )
    }

    return (
      <>
        <div>{userText}</div>
        <div>{emailsText}</div>
        {crmOwner && <div>{crmOwnerText}</div>}
      </>
    )
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__send-letter-container`}>
        <Typography text={`Send`} inline /> <Svg name={SvgNames.letter} type={SvgType.ICON} />{' '}
        {subject !== '' && <Typography text={subject} weight={TextWeight.MEDIUM} inline />}
        {subject === '' && <Typography text={t('undefined')} weight={TextWeight.ITALIC} inline />} <Typography text={`to`} inline />
      </div>
      <div className={`${rootClass}__recipients`}>{renderRecipients()}</div>
    </div>
  )
}

export default SendAlertDetail
