import { gql } from '@apollo/client'

export default gql`
  query crmSourceOptions($connectedCrmName: String) {
    crmSourceOptions(connectedCrmName: $connectedCrmName) {
      entityType
      liveValidate
      lookupEntityType
      lookupNameField
      matchIdField
      name
      queryEntityTypeField
      queryIdField
      queryType
      title
      type
    }
  }
`
