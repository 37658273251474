import { CampaignName, CampaignSectionData, CampaignStatus } from '@graphql/types/microservice/data-lake-query-types'
import { CampaignTableRowData } from '@src/pages/reports/revenueImpactNew/components/campaignTable/CampaignTable.types'
import { CAMPAIGN_STATUSES_OPTIONS } from '@src/pages/reports/revenueImpactNew/components/campaignTable/utils/campaignTable.filterData'
import { filterNotEmptyArray } from '@utils/array'

type RowStats = {
  opportunities: number
  won: number
  wonPercentage: number
  pipeline: number
  revenue: number
  avgRevenue: number
}

export const addAvgRevenueAndWonPercentage: <T extends Omit<CampaignTableRowData, 'id'>>(
  stats: T
) => T & { avgRevenue: number; wonPercentage: number } = (stats) => {
  const wonPercentage = stats.opportunities ? Math.round(((stats.won || 0) * 100) / stats.opportunities) : 100
  const avgRevenue = Math.round((stats?.revenue || 0) / (stats.won || 1))
  return { ...stats, wonPercentage, avgRevenue }
}

export const calculateSubRowsStats: (subRows: Omit<CampaignTableRowData, 'id'>[]) => RowStats = (subRows) => {
  const stats = subRows.reduce(
    (row: RowStats, acc) => {
      const keys: ('opportunities' | 'won' | 'revenue' | 'pipeline')[] = ['opportunities', 'won', 'revenue', 'pipeline']
      const result: RowStats = { opportunities: 0, won: 0, revenue: 0, pipeline: 0, wonPercentage: 0, avgRevenue: 0 }
      keys.forEach((key) => {
        result[key] = Number(row[key] ?? 0) + Number(acc[key] ?? 0)
      })
      return result
    },
    { opportunities: 0, won: 0, revenue: 0, pipeline: 0, wonPercentage: 0, avgRevenue: 0 }
  )

  return addAvgRevenueAndWonPercentage(stats)
}

const getStatusRows: (rows: (CampaignStatus & { status: string })[]) => CampaignTableRowData[] = (nameRow) =>
  nameRow.map((row) => {
    const { status, stageRows } = row
    const filteredStageRows = (stageRows?.filter(filterNotEmptyArray) ?? []).map((stageRow) => {
      return addAvgRevenueAndWonPercentage(stageRow)
    })
    const stats = calculateSubRowsStats(filteredStageRows)
    const subRows = filteredStageRows.map(({ name, ...rest }, index) => ({
      id: name ?? index.toString(),
      name: name || 'No campaign stage',
      missingName: !name ? true : undefined,
      ...rest,
    }))
    return { id: status, status, subRows, ...stats }
  })

const getNameRows: (rows: CampaignName[]) => CampaignTableRowData[] = (nameRow) =>
  nameRow.map((row, index) => {
    const { name, statusRowOpen, statusRowWon, statusRowLost } = row
    const statusRows: (CampaignStatus & { status: string })[] = []
    const statuses = Array.from(CAMPAIGN_STATUSES_OPTIONS)
    if (statusRowOpen) {
      statusRows.push({ ...statusRowOpen, status: statuses[0][1] })
    }
    if (statusRowWon) {
      statusRows.push({ ...statusRowWon, status: statuses[1][1] })
    }
    if (statusRowLost) {
      statusRows.push({ ...statusRowLost, status: statuses[2][1] })
    }
    const formattedNameRows: CampaignTableRowData[] = getStatusRows(statusRows)
    const stats = calculateSubRowsStats(formattedNameRows)
    return {
      id: name ?? index.toString(),
      name: name || 'No campaign name',
      missingName: !name ? true : undefined,
      subRows: formattedNameRows,
      ...stats,
    }
  })

const getFormattedData: (data: CampaignSectionData) => CampaignTableRowData[] = (data) =>
  (data.campaigns ?? []).map((row, index) => {
    const { name, nameRows } = { ...row }
    const formattedNameRows: CampaignTableRowData[] = getNameRows(nameRows?.filter(filterNotEmptyArray) ?? [])
    const stats = calculateSubRowsStats(formattedNameRows)
    return {
      id: name ?? index.toString(),
      name: name || 'No campaign type',
      missingName: !name ? true : undefined,
      subRows: formattedNameRows,
      ...stats,
    }
  })

export default getFormattedData
