import { useCallback } from 'react'

import { useApolloClient } from '@apollo/client'
import createOrUpdateBeeEmailContent from '@graphql/microservices/email-management/createOrUpdateBeeEmailContent'
import getBeeRecentlyTemplates from '@graphql/microservices/email-management/getBeeRecentlyTemplates'
import getBeeTemplateCategories from '@graphql/microservices/email-management/getBeeTemplateCategories'
import getBeeTemplates from '@graphql/microservices/email-management/getBeeTemplates'
import getSingleBeeTemplate from '@graphql/microservices/email-management/getSingleBeeTemplate'
import createOrUpdateBeeTemplatesRecentlyView from '@graphql/microservices/user-settings/createOrUpdateBeeTemplatesRecentlyView'
import getBeeTemplatesRecentlyView from '@graphql/microservices/user-settings/getBeeTemplatesRecentlyView'
import createBeeTemplateMessage from '@graphql/mutations/createBeeTemplateMessage'
import {
  CreateOrUpdateBeeEmailContentMutation,
  CreateOrUpdateBeeEmailContentMutationVariables,
  GetBeeRecentlyTemplatesQuery,
  GetBeeRecentlyTemplatesQueryVariables,
  GetBeeTemplateCategoriesQuery,
  GetBeeTemplatesQuery,
  GetBeeTemplatesQueryVariables,
  GetSingleBeeTemplateQuery,
  GetSingleBeeTemplateQueryVariables,
} from '@graphql/types/microservice/email-management-types'
import {
  CreateOrUpdateBeeTemplatesRecentlyViewMutation,
  CreateOrUpdateBeeTemplatesRecentlyViewMutationVariables,
  GetBeeTemplatesRecentlyViewQuery,
  GetBeeTemplatesRecentlyViewQueryVariables,
} from '@graphql/types/microservice/user-settings-types'
import { CreateBeeTemplateMessageMutation, CreateBeeTemplateMessageMutationVariables } from '@graphql/types/mutation-types'
import { BeeTemplateCategoriesQuery, BeeTemplateCategoriesQueryVariables } from '@graphql/types/query-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export type GetCategoriesPromiseType = () => FetchPromise<GetBeeTemplateCategoriesQuery>
export type GetTemplatesPromiseType = (variables: GetBeeTemplatesQueryVariables) => FetchPromise<GetBeeTemplatesQuery>
export type CreateBeeTemplateMessagePromiseType = (templateId: string, signal?: AbortSignal) => FetchPromise<CreateBeeTemplateMessageMutation>
export type GetSingleBeeTemplatePromiseType = (id: string) => FetchPromise<GetSingleBeeTemplateQuery>
export type GetBeeTemplatesRecentlyViewedByIdsPromiseType = () => FetchPromise<GetBeeTemplatesRecentlyViewQuery>
export type UpdateBeeTemplatesRecentlyViewedPromiseType = (templateId: string) => FetchPromise<CreateOrUpdateBeeTemplatesRecentlyViewMutation>
export type UpdateEmailManagementBeeTemplatesRecentlyViewedPromiseType = (
  variables: CreateOrUpdateBeeEmailContentMutationVariables
) => FetchPromise<CreateOrUpdateBeeEmailContentMutation>
export type GetRecentlyViewedTemplatesPromiseType = (variables: GetBeeRecentlyTemplatesQueryVariables) => FetchPromise<GetBeeRecentlyTemplatesQuery>

export interface TemplateCatalogModalRequests {
  getTemplateCatalogCategories: GetCategoriesPromiseType
  getTemplateCatalogItems: GetTemplatesPromiseType
  createMessage: CreateBeeTemplateMessagePromiseType
  getBeeTemplate: GetSingleBeeTemplatePromiseType
  getRecentlyViewedIds: GetBeeTemplatesRecentlyViewedByIdsPromiseType
  getRecentlyViewedTemplates: GetRecentlyViewedTemplatesPromiseType
  updateRecentlyViewed: UpdateBeeTemplatesRecentlyViewedPromiseType
  updateEmailManagementRecentlyViewed: UpdateEmailManagementBeeTemplatesRecentlyViewedPromiseType
}

export const useTemplateCatalogModalRequests = (): TemplateCatalogModalRequests => {
  const actonClient = useApolloClient()

  const { client: emailManagementClient } = useMicroserviceClient({ serviceName: MicroserviceClients.EMAIL_MANAGEMENT_PROXY })

  const { client: userSettingsClient } = useMicroserviceClient({ serviceName: MicroserviceClients.USER_SETTINGS })

  const getTemplateCatalogCategories = useCallback(
    async () =>
      await emailManagementClient.query<BeeTemplateCategoriesQuery, BeeTemplateCategoriesQueryVariables>({
        query: getBeeTemplateCategories,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        variables: {},
      }),
    [emailManagementClient]
  )

  const getTemplateCatalogItems = useCallback(
    async (variables: GetBeeTemplatesQueryVariables) =>
      await emailManagementClient.query<GetBeeTemplatesQuery, GetBeeTemplatesQueryVariables>({
        query: getBeeTemplates,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        variables,
      }),
    [emailManagementClient]
  )

  const createMessage = useCallback(
    async (templateId: string, signal?: AbortSignal) =>
      await actonClient.mutate<CreateBeeTemplateMessageMutation, CreateBeeTemplateMessageMutationVariables>({
        mutation: createBeeTemplateMessage,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        variables: { templateId },
        context: { fetchOptions: { signal } },
      }),
    [actonClient]
  )

  const getBeeTemplate = useCallback(
    async (id: string) =>
      await emailManagementClient.query<GetSingleBeeTemplateQuery, GetSingleBeeTemplateQueryVariables>({
        query: getSingleBeeTemplate,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        variables: { id },
      }),
    [emailManagementClient]
  )

  const getRecentlyViewedIds = useCallback(
    async () =>
      await userSettingsClient.query<GetBeeTemplatesRecentlyViewQuery, GetBeeTemplatesRecentlyViewQueryVariables>({
        query: getBeeTemplatesRecentlyView,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        variables: {},
      }),
    [userSettingsClient]
  )

  const getRecentlyViewedTemplates = useCallback(
    async (variables: GetBeeRecentlyTemplatesQueryVariables) =>
      await emailManagementClient.query<GetBeeRecentlyTemplatesQuery, GetBeeRecentlyTemplatesQueryVariables>({
        query: getBeeRecentlyTemplates,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        variables,
      }),
    [emailManagementClient]
  )

  const updateRecentlyViewed = useCallback(
    async (templateId: string) =>
      await userSettingsClient.mutate<CreateOrUpdateBeeTemplatesRecentlyViewMutation, CreateOrUpdateBeeTemplatesRecentlyViewMutationVariables>({
        mutation: createOrUpdateBeeTemplatesRecentlyView,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        variables: { templateId },
      }),
    [userSettingsClient]
  )

  const updateEmailManagementRecentlyViewed = useCallback(
    async (variables: CreateOrUpdateBeeEmailContentMutationVariables) =>
      await emailManagementClient.mutate<CreateOrUpdateBeeEmailContentMutation, CreateOrUpdateBeeEmailContentMutationVariables>({
        mutation: createOrUpdateBeeEmailContent,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        variables,
      }),
    [emailManagementClient]
  )

  return {
    getTemplateCatalogCategories,
    getTemplateCatalogItems,
    createMessage,
    getBeeTemplate,
    getRecentlyViewedIds,
    updateRecentlyViewed,
    updateEmailManagementRecentlyViewed,
    getRecentlyViewedTemplates,
  }
}
