import React, { FC, useContext, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Checkbox from '@components/Checkbox'
import CheckboxGroup from '@components/CheckboxGroup/CheckboxGroup'
import ContentExpander from '@components/ContentExpander/ContentExpander'
import FormRow from '@components/FormRow'
import Input from '@components/Input'
import Loader from '@components/Loader'
import { ModalBody } from '@components/Modal'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextType } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import { Program } from '@graphql/types/query-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { outgoingWebhookStepSchema } from '@src/pages/programs/edit/components/ProgramFlow/components/EditStepModal/steps/EditOutgoingWebhookStep/utils/EditOutgoingWebhookStep.constants'
import { ProgramOutgoingWebhooksContext } from '@src/pages/programs/edit/components/ProgramOutgoingWebhooks/ProgramOutgoingWebhooks.context'
import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'
import { ItemType } from '@utils/categorization'
import { getSharedHeaders } from '@utils/program/program'
import { ProgramOutgoingWebhookStepExt, Step } from '@utils/program/program.constants'

import './EditOutgoingWebhookStep.css'

interface EditOutgoingWebhookStepProps {
  className?: string
  dataTest?: string
  closeModal: VoidFunction
  step: Step
  isRunning: boolean
  saveStepAndProgram: (step: Step | null, program?: Program) => void
  submitId: string
}

enum SvixPages {
  NEW_ENDPOINT = '/endpoints/new',
  ENDPOINT_DASHBOARD = `/endpoints/{{endpointId}}`,
}

const rootClass = 'edit-outgoing-webhook-step'
const OUTGOING_WEBHOOKS_CLASSIC_URL = `${rootContext}/classic/if/webhooks/webhooks.jsp`

enum OutgoingWebhookStepAttribute {
  ENDPOINTS = 'endpoints',
  DISPLAY_NAME = 'displayName',
  HEADERS = 'headers',
}

const EditOutgoingWebhookStep: FC<EditOutgoingWebhookStepProps> = (props: EditOutgoingWebhookStepProps) => {
  const { dataTest = rootClass, className = '', saveStepAndProgram, step: baseStep, submitId, isRunning } = props
  const step = baseStep as ProgramOutgoingWebhookStepExt

  const {
    values: { programUrlId, program },
  } = useContext(ProgramManagerContext)

  const {
    values: { loadingEndpoints, endpoints },
  } = useContext(ProgramOutgoingWebhooksContext)

  const { t } = useTranslation()

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    resetField,
    setValue,
    watch,
  } = useForm<ProgramOutgoingWebhookStepExt>({
    resolver: yupResolver(outgoingWebhookStepSchema),
    reValidateMode: 'onChange',
    defaultValues: step,
  })

  const headers = useMemo(() => getSharedHeaders(program.sourceList), [program])
  const stepEndpointChannel = `${programUrlId}_${step.stepId}`

  const getOutgoingWebhooksClassicUrl = (svixUrl?: SvixPages, endpointId?: string) => {
    if (svixUrl) {
      const redirectUrl = encodeURIComponent(svixUrl.replace(`{{endpointId}}`, endpointId ?? ''))
      return `${OUTGOING_WEBHOOKS_CLASSIC_URL}?redirect=${redirectUrl}`
    }
    return OUTGOING_WEBHOOKS_CLASSIC_URL
  }

  const endpointOptions = useMemo(() => {
    return endpoints
      ? endpoints.map<SelectV2SingleOption>(({ url = '', endpointId = '', description = '', channels = [] }) => {
          const isDisabled = channels.filter((channel) => channel !== stepEndpointChannel).length >= 10
          return {
            label: url,
            value: endpointId,
            subText: isDisabled ? t('This webhook is already in use in the limit of 10 channels') : description,
            isDisabled,
          }
        })
      : []
  }, [endpoints, t, stepEndpointChannel])

  const defaultSelectedEndpoints = useMemo<SelectV2SingleOption[]>(() => {
    let endpointsInitialValues: string[] | undefined
    if (!step.endpoints || step.endpoints.length === 0) {
      endpointsInitialValues = endpoints?.reduce((endpointsIds: string[], { endpointId = '', channels = [] }) => {
        return channels?.includes(`${programUrlId}_${step.stepId}`) ? [...endpointsIds, endpointId] : endpointsIds
      }, [])
    } else {
      endpointsInitialValues = step.endpoints
    }
    return endpointOptions.filter(({ value }) => endpointsInitialValues?.includes(value))
  }, [endpointOptions, endpoints, programUrlId, step.endpoints, step.stepId])

  const onSubmit = ({ displayName, endpoints, headers }: ProgramOutgoingWebhookStepExt) => {
    saveStepAndProgram({
      ...step,
      displayName,
      endpoints,
      headers,
    })
  }

  const onColumnCheckAll = (checked: boolean) => {
    const newColumns = checked ? headers : []
    setValue(OutgoingWebhookStepAttribute.HEADERS, newColumns, {
      shouldDirty: true,
    })
  }

  const onColumnCheck = (checked: boolean, onChange: (headers: string[]) => void, displayName = '') => {
    const currentHeaders: string[] = watch(OutgoingWebhookStepAttribute.HEADERS)
    if (checked) {
      onChange([...currentHeaders, displayName])
    } else {
      onChange(currentHeaders.filter((header) => header !== displayName))
    }
  }

  useEffect(() => {
    if (!loadingEndpoints) {
      resetField(OutgoingWebhookStepAttribute.ENDPOINTS, { defaultValue: defaultSelectedEndpoints.map(({ value }) => value) })
      if (step.headers.length === 0) {
        resetField(OutgoingWebhookStepAttribute.HEADERS, { defaultValue: headers })
      }
    }
  }, [headers, defaultSelectedEndpoints])

  const renderErrorMessage = (message: string) => (
    <div className={`${rootClass}__error-message`}>
      <Svg className={classNames({ [`${rootClass}__error-message-icon`]: !!message })} name={SvgNames.inputStatusInvalid} type={SvgType.ICON} />
      <Typography type={TextType.ERROR} text={t(message)} />
    </div>
  )

  const renderEndpointsListing = () =>
    defaultSelectedEndpoints.map((endpoint) => (
      <div key={endpoint.value} className={`${rootClass}__read-only-endpoint`}>
        <div>
          <Typography text={endpoint.label} />
          <Typography text={endpoint.subText} type={TextType.BODY_TEXT_SMALL_LIGHT} />
        </div>
        <Button
          buttonType={ButtonType.TEXT_TEAL}
          onClick={() => window.open(getOutgoingWebhooksClassicUrl(SvixPages.ENDPOINT_DASHBOARD, endpoint.value))}
        >
          {t(`Edit`)}
        </Button>
      </div>
    ))

  const renderForm = () => {
    return (
      <form data-test={dataTest} onSubmit={handleSubmit(onSubmit)}>
        <FormRow>
          <Input
            dataTest={`${dataTest}-input`}
            label={t(`Step Name`)}
            register={register(OutgoingWebhookStepAttribute.DISPLAY_NAME)}
            error={!!errors?.displayName}
          />
          {!!errors?.displayName?.message && renderErrorMessage(errors.displayName.message)}
        </FormRow>
        <FormRow>
          <Typography className={'label'} text={t(`Select Endpoint`)} />
          <Controller
            control={control}
            name={OutgoingWebhookStepAttribute.ENDPOINTS}
            render={({ field: { onChange, onBlur } }) => (
              <SelectV2
                dataTest={`${dataTest}-select`}
                options={endpointOptions}
                insideModal
                onBlur={onBlur}
                defaultValue={defaultSelectedEndpoints}
                error={!!errors?.endpoints}
                errorMessage={errors?.endpoints?.message ? t(errors.endpoints.message) : undefined}
                onChangeMultiple={(selectedOptions) => {
                  onChange(selectedOptions?.map(({ value = '' }) => value))
                }}
                minSearchOptions={0}
                onCreate={() => window.open(getOutgoingWebhooksClassicUrl(SvixPages.NEW_ENDPOINT))}
                itemType={ItemType.OUTGOING_WEBHOOK_ENDPOINT}
                enableCreateOptionWithoutTyping
              />
            )}
          />
          {defaultSelectedEndpoints.length > 0 && <div className={`${rootClass}__read-only-endpoint-container`}>{renderEndpointsListing()}</div>}
        </FormRow>
        <FormRow>
          <CheckboxGroup label={t(`Columns`)}>
            {headers.map((header) => (
              <Controller
                control={control}
                key={`checkbox-${header}`}
                name={OutgoingWebhookStepAttribute.HEADERS}
                render={({ field: { onChange } }) => (
                  <Checkbox
                    dataTest={`${dataTest}-checkbox-${header}`}
                    label={header}
                    checked={watch(OutgoingWebhookStepAttribute.HEADERS)?.includes(header)}
                    onChange={(checked) => onColumnCheck(checked, onChange, header)}
                    ellipOnLabel
                  />
                )}
              />
            ))}
          </CheckboxGroup>
          {!!errors?.headers?.message && renderErrorMessage(errors.headers.message)}
          <ContentExpander
            className={`${rootClass}__check-all-container`}
            onChange={onColumnCheckAll}
            expandButtonText={t('Check all')}
            collapseButtonText={t('Uncheck all')}
          />
        </FormRow>
        <button type="submit" id={submitId} hidden />
      </form>
    )
  }

  const renderReadOnlyMode = () => (
    <div className={`${rootClass}__read-only`}>
      <Typography className={`label`} text={t('Step Name')} />
      <Typography text={step.displayName} />
      <Typography className={`label`} text={t('Selected Endpoints')} />
      {renderEndpointsListing()}
      <Typography className={`label`} text={t('Columns')} />
      <div className={`${rootClass}__read-only-columns-container`}>
        {step.headers.map((header) => (
          <Typography className={'ellip'} key={header} text={header} />
        ))}
      </div>
    </div>
  )

  return (
    <ModalBody className={classNames(rootClass, className)} data-test={dataTest}>
      {loadingEndpoints ? <Loader className={`${rootClass}__loader`} /> : isRunning ? renderReadOnlyMode() : renderForm()}
    </ModalBody>
  )
}

export default EditOutgoingWebhookStep
