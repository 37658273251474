import { useEffect, useMemo, useState } from 'react'

import { useApolloClient, useQuery } from '@apollo/client'
import getAllPermissions from '@graphql/queries/getAllPermissions'
import { GetAllPermissionsQuery, GetAllPermissionsQueryVariables, PermissionsResponse } from '@graphql/types/query-types'
import {
  PermissionOption,
  PermissionOptionsType,
  PermissionsGroupItem,
  PermissionsGroupName,
  PermissionItemPropsMapping,
  getPermissionOptionOrder,
} from '@src/pages/Settings/Users/components/AllPermissionsForm/allPermissionsData'
import { filterNotEmptyArray } from '@utils/array'
import { logNewRelicError } from '@utils/new-relic.utils'

interface Props {
  setPermissions: (data: PermissionsResponse) => void
  currentPermissions?: PermissionsResponse
}

interface ReturnProps {
  allPermissionsData: PermissionsGroupItem[]
  loading: boolean
}

const useAllPermissionsData = (props: Props): ReturnProps => {
  const { setPermissions, currentPermissions } = props
  const [allPermissions, setAllPermissions] = useState<PermissionsGroupItem[] | undefined>(undefined)
  const client = useApolloClient()
  const { data, loading, error } = useQuery<GetAllPermissionsQuery, GetAllPermissionsQueryVariables>(getAllPermissions, {
    client,
    fetchPolicy: 'network-only',
  })

  if (error) {
    logNewRelicError(error)
  }

  useEffect(() => {
    if (!loading && data?.getAllPermissions?.permissions) {
      const { __typename, launchApproval, ...loadedPermissions } = data.getAllPermissions.permissions
      !currentPermissions && setPermissions(loadedPermissions)
      const perms: PermissionsGroupItem[] = []
      Object.entries(loadedPermissions).forEach(([name, _options]) => {
        const permissionItem = PermissionItemPropsMapping.get(name as PermissionsGroupName)
        if (permissionItem && Array.isArray(_options)) {
          const options: PermissionOptionsType = {}
          _options
            .filter(filterNotEmptyArray)
            .forEach((option) => (options[option as PermissionOption] = { value: true, order: getPermissionOptionOrder(option as PermissionOption) }))
          perms.push({ name: name as PermissionsGroupName, ...permissionItem, options })
        }
      })
      setAllPermissions(perms)
    }
  }, [data, loading, setPermissions])

  const allPermissionsData = useMemo<PermissionsGroupItem[]>(() => {
    if (!allPermissions) {
      return []
    }
    if (!currentPermissions) {
      return allPermissions
    }
    const perm: PermissionsGroupItem[] = []
    allPermissions.forEach((permission) => {
      const { name, options } = permission
      const _options = currentPermissions[name]
      if (!Array.isArray(_options)) {
        const deselectedOptions = { ...options }
        Object.keys(deselectedOptions).forEach(
          (key) => (deselectedOptions[key as PermissionOption] = { value: false, order: getPermissionOptionOrder(key as PermissionOption) })
        )
        perm.push({ ...permission, options: deselectedOptions })
      } else {
        const newOptions = { ...options }
        Object.keys(newOptions).forEach(
          (key) => (newOptions[key as PermissionOption] = { value: _options.includes(key), order: getPermissionOptionOrder(key as PermissionOption) })
        )
        perm.push({ ...permission, options: newOptions })
      }
    })
    return perm
  }, [allPermissions, currentPermissions])

  return { loading, allPermissionsData }
}

export default useAllPermissionsData
