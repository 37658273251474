import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { IMergeTag } from '@beefree.io/sdk/dist/types/bee'
import { FilterFunctionMap, StrictSortingState, useFrontendFiltering } from '@complex/ListingPage/Hooks/useFrontendFiltering'
import { PersonalizationFallbackModal } from '@complex/Personalization/components/PersonalizationFallbackModal/PersonalizationFallbackModal'
import Personalization from '@complex/Personalization/Personalization'
import {
  defaultState,
  personalizationFilterFunctions,
  PersonalizationItem,
  PersonalizationModalContext,
  PersonalizationSelection,
} from '@complex/Personalization/utils/Personalization.context'
import { PersonalizationTag } from '@components/PersonalizationTagInput/PersonalizationTagInput.utils'
import { SvgNames } from '@components/Svg'
import { useAccountSettings } from '@utils/account/account.utils'
import {
  FilterDefinition,
  FilterTypes,
  msdynamicsFieldsFilter,
  netsuiteFieldsFilter,
  salesforceFieldsFilter,
  sugarCRMFieldsFilter,
  zendeskFieldsFilter,
} from '@utils/filter'
import useCRM, { CRMConnectorType } from '@utils/hooks/useCRM'
import { getPersonalizationCommon } from '@utils/personalization'
import { UpdateState } from '@utils/types'

import { useFetchPersonalizations } from './hooks/useFetchPersonalizations'
import { LP_PERSONALIZATION_LEARN_MORE, PersonalizationModals } from './Personalization.constants'

export interface PersonalizationContainerTagInputProps {
  onInsertTag?: (data: Omit<PersonalizationTag, 'value'>) => void
  activeSvg?: SvgNames
  preSelectedItem?: PersonalizationItem
  isFallbackModalActive?: boolean
}

export interface PersonalizationContainerProps {
  isLandingPage?: boolean
  isAutomatedPrograms?: boolean
  isOpen: boolean
  disableAddRecipientsButton?: boolean
  programId?: string
  specificIds?: string[]
  hideAccountPersonalizations?: boolean
  hideListOrSegmentFields?: boolean
  hideContactsFieldsFilter?: boolean
  defaultActiveFilter?: FilterTypes
  hideSenderFieldsFilter?: boolean
  disableListOrSegmentFields?: boolean
  disableListOrSegmentFieldsForTemplate?: boolean
  closePersonalization: () => void
  doneCallback?: (data?: IMergeTag) => void
  tagInputProps?: PersonalizationContainerTagInputProps
  isStory?: boolean
  skipFallbackText?: boolean
  title?: string
}

const setupCrm = (type: CRMConnectorType): FilterDefinition | undefined => {
  if (type === CRMConnectorType.SALESFORCE) {
    return salesforceFieldsFilter
  } else if (type === CRMConnectorType.NETSUITE) {
    return netsuiteFieldsFilter
  } else if (type === CRMConnectorType.SUGAR) {
    return sugarCRMFieldsFilter
  } else if (type === CRMConnectorType.MS_DYNAMICS) {
    return msdynamicsFieldsFilter
  } else if (type === CRMConnectorType.ZENDESK) {
    return zendeskFieldsFilter
  }
}

export interface PersonalizationContainerState {
  isLandingPage?: boolean
  isAutomatedPrograms?: boolean
  selection: PersonalizationSelection
  isOpen: boolean
  loading: boolean
  programId?: string
  canSubmit: boolean
  activeModal: PersonalizationModals
  activeFilter: string
  activeFilterSvg?: SvgNames
  searchText: string
  crm?: FilterDefinition
  selectedListIds?: string
  allData: PersonalizationItem[]
  searchAllData: boolean
  selectedItem?: PersonalizationItem
  sortingBy: StrictSortingState<PersonalizationItem>
  disableAddRecipientsButton?: boolean
  specificIds?: string[]
  skipFallbackText?: boolean
  hideAccountPersonalizations?: boolean
  hideListOrSegmentFields?: boolean
  hideSenderFieldsFilter?: boolean
  hideContactsFieldsFilter?: boolean
  disableListOrSegmentFields?: boolean
  disableListOrSegmentFieldsForTemplate?: boolean
}

export const PersonalizationContainer: FC<PersonalizationContainerProps> = (props: PersonalizationContainerProps) => {
  const {
    isLandingPage,
    isAutomatedPrograms = false,
    programId,
    isOpen,
    skipFallbackText,
    disableAddRecipientsButton,
    hideAccountPersonalizations,
    hideListOrSegmentFields,
    hideSenderFieldsFilter,
    hideContactsFieldsFilter,
    defaultActiveFilter,
    closePersonalization,
    doneCallback,
    tagInputProps,
    specificIds = [],
    disableListOrSegmentFields = false,
    disableListOrSegmentFieldsForTemplate = false,
    isStory,
    title,
  } = props

  const { preSelectedItem, isFallbackModalActive = false, activeSvg, onInsertTag } = tagInputProps ?? {}
  const { useCrmModelPersonalization } = useAccountSettings()
  const { hasCRMConnected, connectorType } = useCRM()

  const [state, setState] = useState<PersonalizationContainerState>({
    ...defaultState,
    isLandingPage,
    isAutomatedPrograms,
    programId,
    isOpen,
    activeModal: isFallbackModalActive ? PersonalizationModals.FALLBACK : PersonalizationModals.DEFAULT,
    selectedItem: preSelectedItem,
    activeFilter: defaultActiveFilter ?? preSelectedItem?.group ?? defaultState.activeFilter,
    activeFilterSvg: activeSvg,
    skipFallbackText,
    hideAccountPersonalizations,
    hideListOrSegmentFields,
    hideSenderFieldsFilter,
    hideContactsFieldsFilter,
    disableListOrSegmentFields,
    disableListOrSegmentFieldsForTemplate,
  })

  const { selectedItem, activeModal, selectedListIds } = state

  const update: UpdateState<PersonalizationContainerState> = useCallback((fields) => {
    setState((state) => ({ ...state, ...fields }))
  }, [])

  useEffect(() => {
    if (specificIds?.length) {
      update({ selectedListIds: specificIds.join(',') })
    }
  }, [specificIds, update])

  const fetchPersonalizations = useFetchPersonalizations(isAutomatedPrograms, hideAccountPersonalizations)

  useEffect(() => {
    update({ loading: true })
    fetchPersonalizations(selectedListIds)
      .then((allData) => update({ allData }))
      .finally(() => update({ loading: false }))
  }, [selectedListIds, update])

  useEffect(() => {
    if (hasCRMConnected && useCrmModelPersonalization && !isAutomatedPrograms) {
      const crm = setupCrm(connectorType)
      update({ crm })
    }
  }, [connectorType, hasCRMConnected, update, useCrmModelPersonalization])

  const handleInsertAction = useCallback(
    (fallbackText?: string, dateFormat?: string, locale?: string, offset?: string) => {
      if (!selectedItem || (!onInsertTag && !doneCallback)) {
        closePersonalization()
        return
      }

      if (dateFormat) {
        if (selectedItem.mapping) {
          const mappingParts = selectedItem.mapping.split('(')
          const offsetValue = offset !== undefined ? `, ${offset}` : ''
          selectedItem.mapping = `${mappingParts[0]}(${dateFormat}${offsetValue}, ${locale})`
        } else {
          selectedItem.mapping = `${selectedItem.title}(${dateFormat}, ${offset}, ${locale})`
        }
      }

      const data = getPersonalizationCommon({ ...selectedItem, fallbackText })
      if (onInsertTag) {
        onInsertTag({
          ...data,
          valid: true,
        })
      } else if (doneCallback) {
        doneCallback({
          name: data.displayText || data.mappingName,
          value: data.syntax,
        })
      }
    },
    [selectedItem, onInsertTag, doneCallback, closePersonalization]
  )

  const filterFunctions: FilterFunctionMap<PersonalizationItem> = useMemo(() => personalizationFilterFunctions, [])

  const filteringAPI = useFrontendFiltering({
    data: state.allData,
    searchText: state.searchText,
    activeFolder: undefined,
    activeFilter: state.activeFilter,
    filterFunction: filterFunctions,
    sortingBy: state.sortingBy,
    itemsPerPage: 50,
    searchAllData: state.searchAllData,
    excludeGroups: isLandingPage ? FilterTypes.SENDER_FIELDS : undefined,
  })

  const handleSearchChange = (value: string) => {
    update({ searchText: value })
  }

  const openDefaultPersonalizationModal = () => update({ activeModal: PersonalizationModals.DEFAULT })

  return (
    <PersonalizationModalContext.Provider value={{ ...filteringAPI, ...state, disableAddRecipientsButton, update }}>
      {activeModal === PersonalizationModals.FALLBACK ? (
        <PersonalizationFallbackModal
          selectedItem={selectedItem}
          activeFilterSvg={state.activeFilterSvg}
          onInsert={handleInsertAction}
          onBack={openDefaultPersonalizationModal}
          onPickerModalOpen={openDefaultPersonalizationModal}
          onCancel={!!onInsertTag ? closePersonalization : openDefaultPersonalizationModal}
          isStory={isStory}
          learnMoreLink={isLandingPage ? LP_PERSONALIZATION_LEARN_MORE : ''}
        />
      ) : (
        <Personalization title={title} onSearch={handleSearchChange} onClose={closePersonalization} onInsert={handleInsertAction} />
      )}
    </PersonalizationModalContext.Provider>
  )
}
