import { gql } from '@apollo/client'

export default gql`
  mutation AddTag($tag: LabelDTOInput, $type: String) {
    addLabels(itemId: null, type: $type, labels: [$tag]) {
      accountId
      color
      createdTime
      enabled
      id
      itemCount
      name
      updatedTime
      views
    }
  }
`
