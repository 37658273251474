import { gql } from '@apollo/client'

export default gql`
  mutation duplicateEmailDraft($emailDraftDuplicateInput: EmailDraftDuplicateInput!) {
    duplicateEmailDraft(emailDraftDuplicateInput: $emailDraftDuplicateInput) {
      id
      name
    }
  }
`
