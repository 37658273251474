import React, { FC, useContext, useState } from 'react'

import classNames from 'classnames'

import AccordionDetailsModal, { AccordionDetailsModalHeaderActions } from '@components/AccordionDetailsModal/AccordionDetailsModal'
import Button, { ButtonType } from '@components/Button'
import { YesNo } from '@components/ConfirmationModal'
import Container from '@components/Container'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import Search, { SearchType } from '@components/Search/Search'
import SelectFilterButton from '@components/SelectFilterButton/SelectFilterButton'
import { SvgNames } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import CreateAlertModal from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/CreateAlertModal'
import VisitorAlertsTable from '@src/pages/WebsiteProspectorAlerts/components/VisitorAlerts/components/VisitorAlertsTable/VisitorAlertsTable'
import { getDetailsModalSections } from '@src/pages/WebsiteProspectorAlerts/components/VisitorAlerts/utils/VisitorAlerts.utils'
import { Alert, WebsiteProspectorContext } from '@src/pages/WebsiteProspectorAlerts/WebsiteProspectorAlertsContext'

import './VisitorAlerts.css'

interface VisitorAlertsProps {
  className?: string
  dataTest?: string
}

const rootClass = 'visitor-alerts'

const VisitorAlerts: FC<VisitorAlertsProps> = (props: VisitorAlertsProps) => {
  const { dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  const [alertToDisplay, setAlertToDisplay] = useState<Alert>()
  const {
    values: { showCreateAlertModal, alertToRemove },
    onDelete,
    update,
  } = useContext(WebsiteProspectorContext)

  const detailsModalHeaderActions: AccordionDetailsModalHeaderActions[] = [
    {
      icon: SvgNames.pencil,
      tooltip: 'Edit alert',
      onClick: () => undefined,
    },
    {
      icon: SvgNames.delete,
      tooltip: 'Remove alert',
      onClick: () => () => update({ alertToRemove: alertToDisplay }),
    },
  ]

  return (
    <>
      {showCreateAlertModal && <CreateAlertModal onCancel={() => update({ showCreateAlertModal: false })} />}
      {alertToRemove && (
        <DeleteConfirmationModal
          isOpen
          title={t('Are you sure?')}
          deleteButtonText={t('Delete')}
          body={t(
            'Deleting will permanently remove this alert from the system and subscribed users will no longer receive email notifications about these visitors. You cannot undo this action.'
          )}
          onAnswer={(answer: YesNo) => {
            if (answer === YesNo.YES) {
              onDelete(alertToRemove)
            }
            update({ alertToRemove: undefined })
            setAlertToDisplay(undefined)
          }}
        />
      )}
      {alertToDisplay && (
        <AccordionDetailsModal
          isOpen={!!alertToDisplay}
          closeModal={() => setAlertToDisplay(undefined)}
          sections={getDetailsModalSections(alertToDisplay.schedule, alertToDisplay.recipients, alertToDisplay.filters, t)}
          header={alertToDisplay.name}
          subHeader={alertToDisplay.description}
          customHeaderActions={detailsModalHeaderActions}
        />
      )}
      <Container className={classNames(rootClass, className)} data-test={dataTest}>
        <div className={`${rootClass}__header`}>
          <Typography text={t('Visitor alerts')} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
          <div className={`${rootClass}__header-search`}>
            <Search placeholder={t('Search')} searchType={SearchType.LARGE} canClear incomingValue={''} onChangeHandler={() => undefined} />
            <Button buttonType={ButtonType.PRIMARY} onClick={() => update({ showCreateAlertModal: true })}>
              {t('Create alert')}
            </Button>
          </div>
        </div>
        <div className={`${rootClass}__filters`}>
          <SelectFilterButton label={t('Recipients')} isRounded isSelected={false} onDiscard={() => alert('Recipients clicked!')} isOpen={false} />
          <SelectFilterButton label={t('Schedule')} isRounded isSelected={false} onDiscard={() => alert('Schedule clicked!')} isOpen={false} />
          <SelectFilterButton label={t('Owner')} isRounded isSelected={false} onDiscard={() => alert('Owner clicked!')} isOpen={false} />
          <SelectFilterButton
            label={t('Last Modified')}
            isRounded
            isSelected={false}
            onDiscard={() => alert('Last modified clicked!')}
            isOpen={false}
          />
          <Button buttonType={ButtonType.PILL} className={`${rootClass}__filters-clear`}>
            {t('Clear all')}
          </Button>
        </div>
        <VisitorAlertsTable onRowClicked={(row) => setAlertToDisplay(row.original)} />
      </Container>
    </>
  )
}

export default VisitorAlerts
