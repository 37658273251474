import React, { FC, ReactNode } from 'react'

import Button, { ButtonType } from '@components/Button'
import InfoHoverCard from '@components/InfoHoverCard/InfoHoverCard'

export interface BreadcrumbInfoHoverContent {
  body: ReactNode
  header: ReactNode
  footer: ReactNode
}

interface BreadcrumbsItemWithInfoHoverProps {
  className?: string
  dataTest?: string
  text: ReactNode
  infoHoverContent: BreadcrumbInfoHoverContent
}

const BreadcrumbsItemWithInfoHover: FC<BreadcrumbsItemWithInfoHoverProps> = (props: BreadcrumbsItemWithInfoHoverProps) => {
  const { className = '', text, infoHoverContent } = props
  const { header, footer, body } = infoHoverContent

  return (
    <InfoHoverCard
      className={className}
      icon={
        <Button buttonType={ButtonType.FLOAT} dataTest={`${text}-trigger`}>
          {text}
        </Button>
      }
      header={header}
      footer={footer}
      openDelay={350}
      closeDelay={150}
    >
      {body}
    </InfoHoverCard>
  )
}

export default BreadcrumbsItemWithInfoHover
