import { gql } from '@apollo/client'

export default gql`
  mutation uploadLandingPageUrl($url: String) {
    uploadLandingPageUrl(url: $url) {
      id
      status
      msg
    }
  }
`
