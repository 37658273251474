import React, { FC, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/index'
import { DropDownType } from '@components/DropDown/DropDown'
import DropDown from '@components/DropDown/index'
import DropDownActions, { DropDownActionsType, MenuItem } from '@components/DropDownActions/DropDownActions'
import Svg, { SvgNames } from '@components/Svg/index'
import { useTranslation } from '@const/globals'
import { useOptOutLinks } from '@src/pages/EmailComposer/EmailModals/components/OptOutLinkModal/useOptOutLinks'
import { useAccountSettings } from '@utils/account/account.utils'

import './OptOutLinkSelector.css'

interface OptOutLinkSelectorProps {
  showLoading: boolean
  includeLabel?: boolean
  insert: (text: string) => void
  onDropDownToggle: (isOpen: boolean) => void
  className?: string
  dataTest?: string
}

const rootClass = 'opt-out-link-selector'

const OptOutLinkSelector: FC<OptOutLinkSelectorProps> = (props: OptOutLinkSelectorProps) => {
  const { insert, onDropDownToggle, showLoading, includeLabel, dataTest = rootClass, className = '' } = props
  const { subscriptionManagementEnabled, subscriptionManagementIsLive } = useAccountSettings()
  const [isOptinsOpen, setOptinsOpen] = useState(false)
  const { t } = useTranslation()
  const optOutLinks = useOptOutLinks()

  const button = (
    <Button
      buttonType={ButtonType.FLOAT_TEAL}
      disabled={showLoading}
      forceHover={isOptinsOpen}
      minimalPadding
      className={classNames(`${rootClass}__button`, {
        [`${rootClass}__open`]: isOptinsOpen,
      })}
      dataTest={`${dataTest}-optouts-button`}
    >
      <Svg name={SvgNames.optOut} />
      {t('Opt-out link')}
    </Button>
  )

  const items: MenuItem[] = optOutLinks
    ? Object.keys(optOutLinks)
        .filter((k) => {
          if (subscriptionManagementEnabled && subscriptionManagementIsLive) {
            return true
          } else {
            return k === 'STANDARD'
          }
        })
        .map((k) => {
          const key = k as keyof typeof optOutLinks
          return {
            onClick: () => {
              // Interacting with the dropdown interferes with the focus of the textarea
              setTimeout(() => {
                const insertText = includeLabel ? `\n\n${optOutLinks[key].label}: ${optOutLinks[key].link} ` : optOutLinks[key].link
                insert(insertText)
              }, 50)
            },
            title: t(`EmailComposer.Preview.Plain.Text.Edit.OptOut.${key}.Label`),
            text: t(`EmailComposer.Preview.Plain.Text.Edit.OptOut.${key}.Description`),
          }
        })
    : [{ title: t('Error'), text: t('There was a problem retrieving your opt-out links.'), onClick: () => null }]

  return (
    <DropDown
      type={DropDownType.STYLED}
      isOpen={isOptinsOpen}
      toggleOpen={(isOpen) => {
        onDropDownToggle(isOpen)
        setOptinsOpen(isOpen)
      }}
      contentClassName={`${className}__drop-down`}
      dataTest={`${rootClass}__drop-down`}
      trigger={button}
    >
      <DropDownActions menuItems={items} closeDropDown={() => setOptinsOpen(false)} type={DropDownActionsType.SMALL} />
    </DropDown>
  )
}

export default OptOutLinkSelector
