import { gql } from '@apollo/client'

export default gql`
  mutation setItemPosition($itemIds: [Long], $position: Int, $type: String) {
    setItemPosition(itemIds: $itemIds, position: $position, type: $type) {
      accountId
      authorId
      createdTime
      enabled
      externalId
      favoriteUsers
      folderId
      hasToAudit
      id
      isFavorite
      item
      itemLevel
      labels {
        accountId
        color
        createdTime
        enabled
        id
        itemCount
        name
        updatedTime
        views
      }
      parentId
      position
      rootParent
      subTypeDTO {
        count
        itemType
        name
      }
      updatedTime
      username
      views
    }
  }
`
