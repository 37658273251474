import React, { FC, useCallback, useContext } from 'react'

import { YesNo } from '@components/ConfirmationModal'
import { LoaderSize } from '@components/Spinner/Spinner'
import { Status } from '@components/StatusToast/StatusToast'
import { SvgNames } from '@components/Svg'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { useEmailComposerRequests } from '@utils/composer/emailComposer/GraphQL/EmailComposerRequests.graphQL'
import { SettingsForm } from '@utils/composer/emailComposer/types'
import { useTranslation } from '@utils/const/globals'
import { logNewRelicError } from '@utils/new-relic.utils'

import EmailComposerSettings from './EmailComposerSettings'
import { modifyJsonToEteDetails } from './EmailComposerSettings.utils'

const EmailComposerSettingsContainer: FC = () => {
  const {
    values: {
      message: { title, id, publishId, apiDetailsPayload },
      loading,
      isStory,
    },
    api: { update, updateModal, onConvertToTemplate },
  } = useContext(EmailComposerContext)
  const { t } = useTranslation()

  const { sendApiDetailsToDeveloperRequest, getEteDetailsRequest } = useEmailComposerRequests()

  const onFieldChange = useCallback(
    <T extends keyof SettingsForm>(field: T, value: SettingsForm[T]) => {
      update({ message: { [field]: value } })
      if (field === 'isTransactional') {
        updateModal('statusToast', { message: t('EmailComposer.Settings.TransactionalEmail.SuccessToast'), status: Status.SUCCESS })
      } else if (field === 'isTriggered') {
        const toastProps =
          value === true
            ? {
                title: t(`EmailComposer.Settings.EventTriggered.Toggle.On.Title`),
                message: t(`EmailComposer.Settings.EventTriggered.Toggle.On.Description`),
                hasTimeout: false,
              }
            : { message: t(`EmailComposer.Settings.EventTriggered.Toggle.Off`) }
        updateModal('statusToast', {
          ...toastProps,
          status: Status.SUCCESS,
        })
      }
    },
    [t, update, updateModal]
  )

  const handleConvertToTemplate = useCallback(
    async (discardDraft: boolean) => {
      updateModal('spinner', {
        headline: t(`EmailComposer.Settings.EventTriggered.ConvertModal.Title`),
        text: t(`EmailComposer.Settings.EventTriggered.ConvertModal.Description`),
        size: LoaderSize.LARGE,
      })
      const { errors } = await onConvertToTemplate(discardDraft)
      if (errors) {
        updateModal('confirmation', {
          titleIcon: SvgNames.warning,
          title: t(`EmailComposer.Settings.EventTriggered.ConvertModal.Error.Title`),
          body: t(`EmailComposer.Settings.EventTriggered.ConvertModal.Error.Description`),
          noButtonText: t('Canel'),
          yesButtonText: t('Retry'),
          yesButtonIcon: SvgNames.refresh,
          onAnswer: (answer) => {
            if (answer === YesNo.YES) {
              handleConvertToTemplate(discardDraft)
            } else {
              updateModal('confirmation', undefined)
            }
          },
          isYesNo: true,
        })
      } else {
        updateModal('statusToast', {
          title: t(`EmailComposer.Settings.EventTriggered.ConvertModal.Success.Title`),
          message: t(`EmailComposer.Settings.EventTriggered.ConvertModal.Success.Description`),
          hasTimeout: false,
          status: Status.SUCCESS,
        })
      }
    },
    [updateModal, onConvertToTemplate, t]
  )

  const handleSendAPIDetails = useCallback(
    async (email: string, details: string, note: string) => {
      try {
        // Check if apiDetailsPayload is available, use it directly
        let modifiedJson = apiDetailsPayload ? modifyJsonToEteDetails(apiDetailsPayload, publishId ?? id) : null

        // If no apiDetailsPayload, make a request to get the details
        if (!modifiedJson) {
          const templateIdObject = { templateId: publishId ?? id }
          const eteSendDetailsJson = await getEteDetailsRequest(templateIdObject)

          if (eteSendDetailsJson?.data?.getETEEmailDetails) {
            const eteDetails = eteSendDetailsJson.data.getETEEmailDetails
            modifiedJson = modifyJsonToEteDetails(eteDetails, publishId ?? id)
          }
        }

        const res = await sendApiDetailsToDeveloperRequest({
          addressTo: email,
          details: modifiedJson || details,
          note,
        })

        if (!res?.eteSendToDeveloper) {
          logNewRelicError({ email, note })
          updateModal('statusToast', {
            message: t('EmailComposer.Settings.EventTriggered.API.Error'),
            status: Status.FAIL,
          })
        } else {
          updateModal('statusToast', {
            message: t('EmailComposer.Settings.EventTriggered.API.Success'),
            status: Status.SUCCESS,
          })
        }
      } catch (error) {
        logNewRelicError({ email, note, error })
        updateModal('statusToast', {
          message: t('EmailComposer.Settings.EventTriggered.API.Error'),
          status: Status.FAIL,
        })
      }
    },
    [apiDetailsPayload, getEteDetailsRequest, id, sendApiDetailsToDeveloperRequest, updateModal, t, title]
  )

  return !loading || isStory ? (
    <EmailComposerSettings onFieldChange={onFieldChange} onConvertToTemplate={handleConvertToTemplate} onSendAPIDetails={handleSendAPIDetails} />
  ) : null
}

export default EmailComposerSettingsContainer
