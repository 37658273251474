import { useEffect, useRef, useState } from 'react'

import equal from 'fast-deep-equal/es6/react'

import { FilterQueryParams } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { CustomRequestFilter, ListingPageExternalApi, ListingPageItem } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { FolderDto } from '@graphql/types/microservice/categorization-types'
import { FilterType, GetAllSentMessagesByFilterQueryVariables, SentMessagePickerUi } from '@graphql/types/query-types'
import { filterNotEmptyArray } from '@utils/array'
import { SortDirection } from '@utils/common'
import { useTranslation } from '@utils/const/globals'
import { allEmailSentFilter, favoriteFilter, sentByMarketingFilter, sentByMeFilter, sentBySalesFilter } from '@utils/filter'
import { SortingFieldType } from '@utils/types'

import { emailSentPickerSubTypes, renderSentCustomFilters } from './EmailSentPicker.sidebar'
import { renderSentSearchTableColumns, renderSentTableColumns } from './EmailSentPicker.tables'
import { useEmailSentPickerRequests } from './useEmailSentRequests'
import { EmailPickerProps, EmailType } from '../useEmailPickerProps'
import { paginateEmailResults, searchEmailsWrapper } from '../utils/EmailPicker.filtering.utils'

type QueryVars = Required<GetAllSentMessagesByFilterQueryVariables>
const ALL_EMAILS_VARS: QueryVars = { filter: 'ALL', hidetoSpecificPeople: true, isFavorites: false, folder: '' }
const MARKETING_VARS: QueryVars = { filter: 'MKTG', hidetoSpecificPeople: true, isFavorites: false, folder: '' }
const SALES_VARS: QueryVars = { filter: 'SALES', hidetoSpecificPeople: true, isFavorites: false, folder: '' }
const SELF_VARS: QueryVars = { filter: 'SELF', hidetoSpecificPeople: true, isFavorites: false, folder: '' }
const FAVORITES_VARS: QueryVars = { filter: 'ALL', hidetoSpecificPeople: true, isFavorites: true, folder: '' }

const FETCH_ALL = -1
const SEARCH_FIELDS: (keyof ListingPageItem)[] = ['name', 'senderName' as keyof ListingPageItem]

export const useEmailSentPickerProps = (listingApi?: ListingPageExternalApi) => {
  const { t } = useTranslation()
  const lastQueryVars = useRef<QueryVars>(ALL_EMAILS_VARS)
  const [allItemsCount, setAllItemsCount] = useState<number>()
  const requests = useEmailSentPickerRequests()

  const hiddenToSpecificPeopleSubType = emailSentPickerSubTypes[0].name
  const defaultActiveSubTypes = [hiddenToSpecificPeopleSubType]

  useEffect(() => {
    if (listingApi && allItemsCount !== undefined) {
      listingApi.updateFilterCounts({ [allEmailSentFilter.name ?? '']: allItemsCount })
    }
  }, [listingApi, allItemsCount])

  const convertMessagesToItemDto = (messages: (SentMessagePickerUi | undefined)[]) => {
    return messages.filter(filterNotEmptyArray).map((message) => {
      const dto = { ...message } as ListingPageItem
      dto.name = message.msgTitle
      dto.externalId = message.id
      dto.item = JSON.stringify(dto)
      return dto
    })
  }

  const getFolderValue = (folder: number | string | string[] | undefined) => (folder ? `${folder}` : '')

  const isHiddenToSpecificPeople = (params?: FilterQueryParams) => !!params?.subTypes?.includes(hiddenToSpecificPeopleSubType)

  // Generate a request to be used when we have custom filters and/or subtypes selected
  const filterRequest = (filter: FilterType = 'ALL', isFavorites = false) => {
    const callback: CustomRequestFilter['request'] = async (_currentPage, params) => {
      const variables: QueryVars = {
        filter,
        isFavorites,
        hidetoSpecificPeople: isHiddenToSpecificPeople(params),
        folder: getFolderValue(params?.folder),
      }
      const response = await requests.getAllSentMessagesByFilterRequest(variables)
      const data = convertMessagesToItemDto(response?.data?.getAllSentMessagesByFilter ?? [])
      const error = response?.errors?.map((value) => value.message).join(', ')

      if (equal(variables, ALL_EMAILS_VARS)) {
        setAllItemsCount(data.length)
      }
      const processedData = paginateEmailResults(data, params)
      return { data: processedData, error }
    }
    return callback
  }

  const customRequestGeneral: EmailPickerProps['customSources']['customRequestGeneral'] = {
    getAllFoldersRequest: async () => {
      const { data, errors } = (await requests.sentMessageDatesRequest({})) ?? {}
      if (data?.sentMessageDates) {
        const folderDtos: FolderDto[] = data.sentMessageDates.map((folder) => ({
          id: Number(folder.code),
          name: folder.name,
          itemCount: folder.count,
        }))
        return { data: { getFolders: folderDtos } }
      }
      return { errors }
    },
    getItemsInFolderRequest: async (params) => {
      if (params.subTypes.length) {
        const response = await filterRequest('ALL')(undefined, params)
        if (response?.data) {
          return { data: { getItemsInFolder: response?.data } }
        }
        return { error: response?.error }
      }

      const folder = getFolderValue(params.folder)
      const response = await requests.getSentMessagesByFolderRequest({ folder: folder })
      if (response?.data?.getSentMessagesByFolder) {
        const data = paginateEmailResults(convertMessagesToItemDto(response.data.getSentMessagesByFolder), params)
        return { data: { getItemsInFolder: data } }
      } else {
        return { errors: response.errors }
      }
    },
    // This would only be called if we're in a folder or tag, and we don't have tags on this picker
    searchItemsRequest: async (params) => {
      const request = filterRequest(lastQueryVars.current.filter, lastQueryVars.current.isFavorites)
      const unusedSearchParams = {
        type: '',
        direction: SortDirection.ASC,
        orderBy: 'name',
        value: '',
        field: '',
        fieldType: 'string' as SortingFieldType,
      }
      const data = (
        await request(0, {
          ...unusedSearchParams,
          pageNumber: 0,
          pageSize: FETCH_ALL,
          subTypes: lastQueryVars.current.hidetoSpecificPeople ? [hiddenToSpecificPeopleSubType] : [],
          folder: lastQueryVars.current.folder,
        })
      ).data
      return await searchEmailsWrapper(data as ListingPageItem[], params.query, SEARCH_FIELDS, (data) => {
        return {
          data: { searchByMultipleFields: { items: data, totalCount: data.length } },
        }
      })
    },
    getCountForFavoritesAndCreatedByMeAndRecentRequest: async () => {
      const response = await requests.getCountFavoriteSentMessagesRequest({ filter: 'ALL', hidetoSpecificPeople: true })
      if (response?.data) {
        return { data: { getCountForFavoritesAndCreatedByMeAndRecent: [{ favoritesCount: response.data.getCountFavoriteSentMessages }] } }
      }
      return { error: response?.errors }
    },
    // Unused; we're calculating some counts on the frontend and the items may not be fetched by the time the listing page makes these requests
    getSubTypesByTypesRequest: async () => ({}),
    getCountQueryRequest: async () => ({}),
  }

  const doSearch = async (params: QueryVars, query: string) => {
    const unusedSearchParams = {
      type: '',
      field: '',
      fields: [],
      allItems: false,
    }
    // Since the searchItemsRequest has to match the signature of the listing page's search function,
    // we don't really have a good way of passing the latest query vars without this ref
    lastQueryVars.current = params
    const response = await customRequestGeneral.searchItemsRequest!({
      ...unusedSearchParams,
      query,
      folder: Number(params.folder),
      subTypes: params.hidetoSpecificPeople ? [hiddenToSpecificPeopleSubType] : [],
      pageNumber: 0,
      pageSize: FETCH_ALL,
    })
    return {
      data: response.data?.searchByMultipleFields?.items?.filter(filterNotEmptyArray) ?? [],
      error: response.errors?.join(', '),
    }
  }

  const customRequestFilters: EmailPickerProps['customSources']['customRequestFilters'] = [
    {
      filter: allEmailSentFilter,
      request: async (_currentPage, params) => {
        if (params?.subTypes.length) {
          return await filterRequest('ALL')(undefined, params)
        }

        let error = undefined
        const response = await requests.getAllSentMessagesRequest({})
        if (response.data?.getAllSentMessages) {
          const data = convertMessagesToItemDto(response.data.getAllSentMessages)
          return { data: paginateEmailResults(data, params) }
        } else {
          error = response?.errors?.map((value) => value.message).join(', ')
          return { data: [], error }
        }
      },
      searchRequest: (query, _currentPage, params) => doSearch({ ...ALL_EMAILS_VARS, hidetoSpecificPeople: isHiddenToSpecificPeople(params) }, query),
    },
    {
      filter: sentByMarketingFilter,
      request: filterRequest('MKTG'),
      searchRequest: (query, _currentPage, params) => doSearch({ ...MARKETING_VARS, hidetoSpecificPeople: isHiddenToSpecificPeople(params) }, query),
    },
    {
      filter: sentBySalesFilter,
      request: filterRequest('SALES'),
      searchRequest: (query, _currentPage, params) => doSearch({ ...SALES_VARS, hidetoSpecificPeople: isHiddenToSpecificPeople(params) }, query),
    },
    {
      filter: sentByMeFilter,
      request: filterRequest('SELF'),
      searchRequest: (query, _currentPage, params) => doSearch({ ...SELF_VARS, hidetoSpecificPeople: isHiddenToSpecificPeople(params) }, query),
    },
    {
      filter: favoriteFilter,
      request: async (_currentPage, params) => {
        if (params?.subTypes?.length) {
          return filterRequest('ALL', true)(undefined, params)
        }

        const response = await requests.getAllFavoriteSentMessagesRequest({})
        if (response.data?.getAllFavoriteSentMessages) {
          const data = convertMessagesToItemDto(response.data.getAllFavoriteSentMessages)
          return { data: paginateEmailResults(data, params) }
        } else {
          const error = response?.errors?.map((value) => value.message).join(', ')
          return { data: [], error }
        }
      },
      searchRequest: (query, _currentPage, params) => doSearch({ ...FAVORITES_VARS, hidetoSpecificPeople: isHiddenToSpecificPeople(params) }, query),
    },
  ]

  const config: EmailPickerProps = {
    customSources: {
      allItemFilter: allEmailSentFilter,
      itemName: 'Email',
      label: 'Sent emails',
      mainColumnName: 'Email Title',
      value: EmailType.SENT,
      itemType: EmailType.SENT,
      customRequestGeneral,
      customRequestFilters,
      listingPageProps: {
        defaultActiveSubTypes,
      },
      sidebarProps: {
        hideTags: true,
        hideFolders: false,
      },
    },
    renderSearchColumns: (_searchAllItems, _currentFolder, search, folders) => renderSentSearchTableColumns(search, folders, t),
    renderTableColumns: renderSentTableColumns(t),
    renderCustomFilters: (params) => renderSentCustomFilters(params, t),
    hasCustomFilters: true,
    hasTags: false,
    hasFavorites: true,
    hasCreatedByMe: false,
    defaultActiveSubTypes,
    subTypes: emailSentPickerSubTypes,
    i18nListPageKey: 'Emails.Sent',
    sortingBy: [{ id: 'sentTime', desc: true }],
  }
  return config
}
