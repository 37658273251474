import checkForUnmappedSegmentFields from '@graphql/microservices/segment/checkForUnmappedSegmentFields'
import copyClassicSegment from '@graphql/microservices/segment/copyClassicSegment'
import deleteClassicSegments from '@graphql/microservices/segment/deleteClassicSegments'
import deleteSegmentByCopyId from '@graphql/microservices/segment/deleteSegmentByCopyId'
import getCopySegmentCount from '@graphql/microservices/segment/getCopySegmentCount'
import getCopySegmentLog from '@graphql/microservices/segment/getCopySegmentLog'
import getSegmentDefinitionFields from '@graphql/microservices/segment/getSegmentDefinitionFields'
import getUnifiedListFieldsFromSource from '@graphql/microservices/segment/getUnifiedListFieldsFromSource'
import moveToTrash from '@graphql/microservices/segment/moveToTrash'
import {
  CheckForUnmappedSegmentFieldsQuery,
  CheckForUnmappedSegmentFieldsQueryVariables,
  CopyClassicSegmentMutation,
  CopyClassicSegmentMutationVariables,
  DeleteClassicSegmentsMutation,
  DeleteClassicSegmentsMutationVariables,
  DeleteSegmentByCopyIdMutation,
  DeleteSegmentByCopyIdMutationVariables,
  GetCopySegmentCountQuery,
  GetCopySegmentCountQueryVariables,
  GetCopySegmentLogQuery,
  GetCopySegmentLogQueryVariables,
  GetSegmentDefinitionFieldsQuery,
  GetSegmentDefinitionFieldsQueryVariables,
  GetUnifiedListFieldsFromSourceQuery,
  GetUnifiedListFieldsFromSourceQueryVariables,
  MoveToTrashMutation,
  MoveToTrashMutationVariables,
  SegmentMappingInput,
} from '@graphql/types/microservice/segment-types'
import { CopyLogField } from '@src/pages/ContactSegments/components/CopySegments/context/CopySegments.context'
import { SortDirection } from '@utils/common'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { SegmentType } from '@utils/segments'
import { FetchPromise } from '@utils/types'

export interface GetSegmentDefinitionFieldsRequestParams {
  segmentIds: string[]
}

export interface GetUnifiedListFieldsFromSourceRequestParams {
  sourceId: string
}

export type DeleteClassicSegmentsRequestParams = GetSegmentDefinitionFieldsRequestParams
export type CheckForUnmappedSegmentFieldsRequestParams = GetSegmentDefinitionFieldsRequestParams

export interface CopyClassicSegmentsRequestParams {
  segmentMapping: SegmentMappingInput
  type: SegmentType.SEGMENT | SegmentType.SUBMISSION
  username: string
}

export interface MoveToTrashRequestParams {
  listIds: string[]
}

export interface DeleteSegmentByCopyIdRequestParams {
  copyId: number
}

export interface GetCopySegmentLogRequestParams {
  direction?: SortDirection
  pageNumber: number
  pageSize: number
  orderBy: CopyLogField
}

export type CheckForUnmappedSegmentFieldsRequestType = (
  params: DeleteClassicSegmentsRequestParams
) => FetchPromise<CheckForUnmappedSegmentFieldsQuery>
export type DeleteSegmentByCopyIdRequestType = (params: DeleteSegmentByCopyIdRequestParams) => FetchPromise<DeleteSegmentByCopyIdMutation>
export type DeleteClassicSegmentsRequestType = (params: DeleteClassicSegmentsRequestParams) => FetchPromise<DeleteClassicSegmentsMutation>
export type GetCopySegmentCountRequestType = () => FetchPromise<GetCopySegmentCountQuery>
export type GetCopySegmentLogRequestType = (params: GetCopySegmentLogRequestParams) => FetchPromise<GetCopySegmentLogQuery>
export type GetSegmentDefinitionFieldsRequestType = (params: GetSegmentDefinitionFieldsRequestParams) => FetchPromise<GetSegmentDefinitionFieldsQuery>
export type GetUnifiedListFieldsFromSourceRequestType = (
  params: GetUnifiedListFieldsFromSourceRequestParams
) => FetchPromise<GetUnifiedListFieldsFromSourceQuery>
export type CopyClassicSegmentsRequestType = (params: CopyClassicSegmentsRequestParams) => FetchPromise<CopyClassicSegmentMutation>
export type MoveToTrashRequestType = (params: MoveToTrashRequestParams) => FetchPromise<MoveToTrashMutation>

export interface CopySegmentsSegmentServiceRequests {
  checkForUnmappedSegmentFieldsRequest: CheckForUnmappedSegmentFieldsRequestType
  deleteSegmentByCopyIdRequest: DeleteSegmentByCopyIdRequestType
  deleteClassicSegmentsRequest: DeleteClassicSegmentsRequestType
  getCopySegmentCountRequest: GetCopySegmentCountRequestType
  getCopySegmentLogRequest: GetCopySegmentLogRequestType
  getSegmentDefinitionFieldsRequest: GetSegmentDefinitionFieldsRequestType
  getUnifiedListFieldsFromSourceRequest: GetUnifiedListFieldsFromSourceRequestType
  migrateClassicSegmentsRequest: CopyClassicSegmentsRequestType
  moveToTrashRequest: MoveToTrashRequestType
}

export const useCopySegmentsSegmentServiceRequests = (): CopySegmentsSegmentServiceRequests => {
  const {
    client: { query, mutate },
  } = useMicroserviceClient({ serviceName: MicroserviceClients.SEGMENT })

  const checkForUnmappedSegmentFieldsRequest = async ({ segmentIds }: CheckForUnmappedSegmentFieldsRequestParams) =>
    await query<CheckForUnmappedSegmentFieldsQuery, CheckForUnmappedSegmentFieldsQueryVariables>({
      query: checkForUnmappedSegmentFields,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables: {
        segmentIds,
      },
    })

  const deleteClassicSegmentsRequest = async ({ segmentIds }: DeleteClassicSegmentsRequestParams) =>
    await mutate<DeleteClassicSegmentsMutation, DeleteClassicSegmentsMutationVariables>({
      mutation: deleteClassicSegments,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables: {
        segmentIds,
      },
    })

  const deleteSegmentByCopyIdRequest = async ({ copyId }: DeleteSegmentByCopyIdRequestParams) =>
    await mutate<DeleteSegmentByCopyIdMutation, DeleteSegmentByCopyIdMutationVariables>({
      mutation: deleteSegmentByCopyId,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables: {
        copyId,
      },
    })

  const getCopySegmentCountRequest = () =>
    query<GetCopySegmentCountQuery, GetCopySegmentCountQueryVariables>({
      query: getCopySegmentCount,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    })

  const getCopySegmentLogRequest = async (paginationParams: GetCopySegmentLogRequestParams) =>
    await query<GetCopySegmentLogQuery, GetCopySegmentLogQueryVariables>({
      query: getCopySegmentLog,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables: {
        ...paginationParams,
      },
    })

  const getSegmentDefinitionFieldsRequest = async ({ segmentIds }: GetSegmentDefinitionFieldsRequestParams) =>
    await query<GetSegmentDefinitionFieldsQuery, GetSegmentDefinitionFieldsQueryVariables>({
      query: getSegmentDefinitionFields,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables: {
        segmentIds,
      },
    })

  const getUnifiedListFieldsFromSourceRequest = async ({ sourceId }: GetUnifiedListFieldsFromSourceRequestParams) =>
    await query<GetUnifiedListFieldsFromSourceQuery, GetUnifiedListFieldsFromSourceQueryVariables>({
      query: getUnifiedListFieldsFromSource,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables: {
        sourceId,
      },
    })

  const migrateClassicSegmentsRequest = async ({ segmentMapping, type, username }: CopyClassicSegmentsRequestParams) =>
    await mutate<CopyClassicSegmentMutation, CopyClassicSegmentMutationVariables>({
      mutation: copyClassicSegment,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables: {
        segmentMapping,
        type,
        username,
      },
    })

  const moveToTrashRequest = async ({ listIds }: MoveToTrashRequestParams) =>
    await mutate<MoveToTrashMutation, MoveToTrashMutationVariables>({
      mutation: moveToTrash,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables: {
        listIds,
      },
    })

  return {
    checkForUnmappedSegmentFieldsRequest,
    deleteClassicSegmentsRequest,
    deleteSegmentByCopyIdRequest,
    getCopySegmentCountRequest,
    getCopySegmentLogRequest,
    getSegmentDefinitionFieldsRequest,
    getUnifiedListFieldsFromSourceRequest,
    migrateClassicSegmentsRequest,
    moveToTrashRequest,
  }
}
