import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router'

import { ComposerHeaderProps } from '@complex/GlobalComposerHeader/components/ComposerHeader/ComposerHeader'
import { ComposerHeaderBottomProps } from '@complex/GlobalComposerHeader/components/ComposerHeaderBottom/ComposerHeaderBottom'
import GlobalComposerHeader from '@complex/GlobalComposerHeader/GlobalComposerHeader'
import { ButtonType } from '@components/Button'
import Container from '@components/Container/Container'
import Loader, { LoaderTypes, renderLoader } from '@components/Loader/Loader'
import { SplitButtonType } from '@components/SplitButton/SplitButton'
import { Status } from '@components/StatusToast/StatusToast'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { LabelDto } from '@graphql/types/query-types'
import BeeEditorContainer from '@src/pages/EmailComposer/BeeEditor/BeeEditorContainer'
import EmailComposerModalsState from '@src/pages/EmailComposer/components/EmailComposerModalState/EmailComposerModalsState'
import {
  useEmailSenderValidations,
  useEmailValidations,
  useSettingsValidations,
  useSubjectAndPreviewTextValidations,
} from '@src/pages/EmailComposer/hooks/useEmailValidations'
import { useValidationSelectionsCount } from '@src/pages/EmailComposer/hooks/useValidationSelectionsCount'
import { useAccountSettings } from '@utils/account/account.utils'
import { CommonComposerTab } from '@utils/composer/commonComposer/CommonComposer.context'
import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'
import { LandingPageComposerState } from '@utils/composer/context/LandingPageComposer.context'
import { DuplicateModalProps } from '@utils/composer/landingPage/types'
import { detailedDateFormat } from '@utils/date/dateUtils'

import LandingPageComposerCustomCode from './components/LandingPageComposerCustomCode/LandingPageComposerCustomCode'
import LandingPageComposerSettingsContainer from './components/LandingPageComposerSettings/LandingPageComposerSettingsContainer'
import { landingPageConfirmationModal } from './utils/LandingPageComposer.utils'
import LandingPageReport from '../Content/LandingPages/LandingPagesManager/components/LandingPageManagerReport/LandingPageManagerReport'

import './LandingPageComposer.css'

const rootClass = 'landing-page-composer'

interface EmailComposerProps {
  isStory?: boolean
  editorClassname?: string
  isBeeEditorLoading: boolean
}

export const LandingPageComposer: FC<EmailComposerProps> = (props) => {
  const { isStory, editorClassname, isBeeEditorLoading } = props
  const {
    values: {
      tab,
      loading,
      isInitializeBeeEditor,
      isSaving,
      haveUnsavedChanges: hasUnsavedChanges,
      validations: { isContentMissing },
      message: { id, title, messageType },
      landingPage: { hasUnpublishedChanges, isLandingPagePublished, isLandingPageStatusChanged, isLandingPageEnabled, lastPublishedTime },
      disabledFeatures: { autoSave: isAutoSaveDisabled },
    },
    api: { onPreview, onTabChange, onSave, onSaveAndClose, onClose, update, onAutoSave, updateModal, onSaveAsCopy },
  } = useComposerContext<LandingPageComposerState>()

  const history = useHistory()

  const { newLPComposerCreateBlank } = useAccountSettings()

  const { errors, warnings } = useValidationSelectionsCount()
  useEmailSenderValidations()
  useSubjectAndPreviewTextValidations()
  useSettingsValidations()
  useEmailValidations()

  const { t } = useTranslation()

  const handlePreview = useCallback(() => {
    update({ isPreview: true })
    onPreview()
  }, [onPreview, update])

  const handleTabChange = useCallback((tab: string) => onTabChange(tab as CommonComposerTab), [onTabChange])

  useEffect(() => {
    update({ validations: { errorsCount: errors, warningsCount: warnings } })
  }, [errors, update, warnings])

  const hasCredentials = !!process.env.NX_BEE_CLIENT_ID_LANDING && !!process.env.NX_BEE_CLIENT_SECRET_LANDING

  const saveButtonText = isLandingPageStatusChanged ? (isLandingPageEnabled ? 'Save and enable' : 'Save and disable') : 'Save'

  const enabledStatus = isLandingPageStatusChanged ? !isLandingPageEnabled : isLandingPageEnabled

  const headerStatusText = newLPComposerCreateBlank ? (isLandingPagePublished ? 'LandingPage.Live' : 'Draft') : enabledStatus ? 'Enabled' : 'Disabled'

  const onSaveAsCopyClick = useCallback(() => {
    let newTitle = ''
    const copyTitle = `${title} (copy)`
    const duplicateModalFields: DuplicateModalProps = {
      itemName: copyTitle,
      defaultName: copyTitle,
      headerTitleKey: t('Save as copy'),
      onSubmitText: t('Save'),
      isLandingPageComposer: true,
      onInputChange: (value) => {
        newTitle = value
      },
      onDuplicateCancel: () => {
        updateModal('duplicateModal', undefined)
      },
      handleDuplicateCreate: (name: string, tags: LabelDto[], folderIdToClone?: number) => {
        update({ landingPage: { createLandingPageFields: { name, tags, folderIdToClone } } })
        onSaveAsCopy(newTitle)
      },
    }

    updateModal('duplicateModal', duplicateModalFields)
  }, [onSaveAsCopy, t, title, update, updateModal, hasUnpublishedChanges])

  const options = useMemo(() => {
    if (newLPComposerCreateBlank) {
      return [
        {
          key: 'save',
          title: t('Save and close'),
          onClick: () => onSaveAndClose(),
        },
        {
          key: 'copy',
          title: t('Save as copy'),
          onClick: onSaveAsCopyClick,
        },
      ]
    }

    return [
      {
        key: 'save',
        title: saveButtonText,
        onClick: () => {
          update({ lastEdited: new Date().valueOf(), landingPage: { isPublishSaveCalled: true } })
          onSave().then(() => {
            update({ landingPage: { isPublishSaveCalled: false } })
          })
        },
      },
      { key: 'saveClose', title: 'Save and close', onClick: () => onSaveAndClose() },
    ]
  }, [newLPComposerCreateBlank, saveButtonText, t, onSaveAsCopyClick, onSaveAndClose, update])

  const onPopoverCardButtonClick = useCallback(() => {
    update?.({ isIndicatingAutoSave: true })
    onAutoSave?.(true)
    updateModal?.('statusToast', undefined)
  }, [onAutoSave, update, updateModal])

  const onIndicatingAutoSaveEnds = useCallback(() => {
    update?.({ isIndicatingAutoSave: false, loading: false, lastEdited: Date.now() })
  }, [update])

  const isLandingPageTemplate = messageType === 'LANDING_PAGE_TEMPLATE'

  const headerTopProps: ComposerHeaderProps = useMemo(
    () => ({
      logoLink: 'content/landingPages',
      splitButtonType: newLPComposerCreateBlank ? SplitButtonType.SECONDARY : SplitButtonType.PRIMARY,
      splitButtonOptions: options,
      closeButtonProps: newLPComposerCreateBlank ? undefined : { type: ButtonType.WHITE, text: 'Close', onClick: onClose },
      hasSaveIndicator: true,
      hasUnsavedChanges,
      hasPopoverCard: newLPComposerCreateBlank,
      hasPublishStatus: newLPComposerCreateBlank && isLandingPagePublished,
      isAutoSaveDisabled,
      isSaving: isSaving,
      inlineEditingProps: {
        title,
        onChange: () => undefined,
        onSave: (value: string) => update({ message: { title: value }, haveUnsavedChanges: true }),
      },
      headerStatus: !isLandingPageTemplate ? (
        <div className={`${rootClass}__status-container`}>
          {newLPComposerCreateBlank ? (
            loading ? (
              <Loader className={`${rootClass}__status-loader`} loaderType={LoaderTypes.row} />
            ) : (
              <>
                <Svg
                  name={
                    newLPComposerCreateBlank
                      ? isLandingPagePublished
                        ? SvgNames.circleGreen
                        : SvgNames.circle
                      : enabledStatus
                      ? SvgNames.circleGreen
                      : SvgNames.circle
                  }
                  type={SvgType.SMALLER_ICON}
                />
                <Typography
                  dataTest={`${rootClass}-header-status`}
                  text={t(headerStatusText, {
                    name: t('LandingPage.Live'),
                    context: newLPComposerCreateBlank && hasUnpublishedChanges ? 'unpublishedChanges' : '',
                  })}
                  type={TextType.BODY_TEXT}
                  weight={isLandingPagePublished ? undefined : TextWeight.MEDIUM}
                  tagProps={{ b: { weight: TextWeight.BOLD, inline: true } }}
                  inline
                />
              </>
            )
          ) : (
            <>
              <Svg
                name={
                  newLPComposerCreateBlank
                    ? isLandingPagePublished
                      ? SvgNames.circleGreen
                      : SvgNames.circle
                    : enabledStatus
                    ? SvgNames.circleGreen
                    : SvgNames.circle
                }
                type={SvgType.SMALLER_ICON}
              />
              <Typography
                dataTest={`${rootClass}-header-status`}
                text={t(headerStatusText, {
                  name: t('LandingPage.Live'),
                  context: newLPComposerCreateBlank && hasUnpublishedChanges ? 'unpublishedChanges' : '',
                })}
                type={TextType.BODY_TEXT}
                weight={isLandingPagePublished ? undefined : TextWeight.MEDIUM}
                tagProps={{ b: { weight: TextWeight.BOLD, inline: true } }}
                inline
              />
            </>
          )}
        </div>
      ) : undefined,
      onPopoverCardButtonClick,
      onIndicatingAutoSaveEnds,
    }),
    [
      newLPComposerCreateBlank,
      options,
      onClose,
      loading,
      hasUnsavedChanges,
      isAutoSaveDisabled,
      isSaving,
      title,
      isLandingPagePublished,
      enabledStatus,
      t,
      headerStatusText,
      hasUnpublishedChanges,
      onPopoverCardButtonClick,
      onIndicatingAutoSaveEnds,
      update,
    ]
  )

  useEffect(() => {
    const firstEdit = new URLSearchParams(history.location.search).get('firstEdit')
    if (firstEdit && !loading) {
      updateModal('statusToast', {
        status: Status.SUCCESS,
        message: <Typography text={t('LandingPage.Save.AsCopy', { title })} tagProps={{ bold: { weight: TextWeight.BOLD } }} inline />,
      })
    }
  }, [update, loading, isBeeEditorLoading])

  const headerBottomProps: ComposerHeaderBottomProps = useMemo(() => {
    const floatButtons: ComposerHeaderBottomProps['floatButtons'] = [
      {
        text: t('Preview'),
        icon: SvgNames.zoom,
        disabled: isContentMissing,
        onClick: handlePreview,
        tooltipContent: isContentMissing ? (
          <>
            <Typography
              text={t('EmailComposer.Design.PreviewDisabled.Tooltip.HeaderText')}
              weight={TextWeight.BOLD}
              type={TextType.BODY_TEXT_WHITE}
            />
            <Typography text={t('EmailComposer.Design.PreviewDisabled.Tooltip.BodyText')} type={TextType.BODY_TEXT_WHITE} />
          </>
        ) : undefined,
      },
      {
        text: t('Test links'),
        icon: SvgNames.test,
        disabled: false,
        onClick: () => {
          update({ isClickthroughLinks: true })
        },
      },
    ]

    if (newLPComposerCreateBlank && !isLandingPageTemplate) {
      floatButtons.push({
        hasLoader: true,
        text: isLandingPagePublished ? t('LandingPage.PublishChanges') : t('LandingPage.PublishPage'),
        buttonType: ButtonType.PRIMARY,
        disabled: false,
        onClick: () => {
          landingPageConfirmationModal({
            t,
            title: isLandingPagePublished ? 'LandingPage.PublishChanges' : 'LandingPage.PublishPage',
            bodyText: isLandingPagePublished
              ? t('LandingPage.PublishChanges.Confirmation.bodyText', { time: lastPublishedTime && detailedDateFormat(lastPublishedTime) })
              : 'LandingPage.PublishPage.Confirmation.bodyText',
            updateModal,
            onConfirm: async () => {
              await update({
                landingPage: { isLandingPagePublished: true, isPublishSaveCalled: true, hasUnpublishedChanges: false, lastPublishedTime: Date.now() },
              })
              onSave(true, false, true)?.then((status) => {
                if (status === undefined) {
                  //This may happen in the beginning when pressed publish and there is no page title
                  update({ landingPage: { isLandingPagePublished: false, hasUnpublishedChanges: false, lastPublishedTime: undefined } })
                  return
                }

                if (status) {
                  updateModal('statusToast', {
                    status: Status.SUCCESS,
                    message: (
                      <Typography
                        text={
                          isLandingPagePublished
                            ? t('LandingPag.Changes.HaveBeen.Unpublished', { name: title })
                            : t('LandingPag.HasBeen.Published', { name: title })
                        }
                        tagProps={{ b: { weight: TextWeight.BOLD } }}
                        inline
                      />
                    ),
                  })
                } else {
                  updateModal('statusToast', {
                    status: Status.FAIL,
                    title: t('LandingPageComposer.Unable.Publish', { publishType: 'publish', name: isLandingPagePublished ? 'changes' : 'page' }),
                    message: t('LandingPageComposer.Unable.Publish.desc', {
                      publishType: 'publishing',
                      name: isLandingPagePublished ? 'changes' : 'page',
                    }),
                  })

                  update({
                    landingPage: {
                      isLandingPagePublished: isLandingPagePublished ?? false,
                      hasUnpublishedChanges: hasUnpublishedChanges ?? false,
                      lastPublishedTime: lastPublishedTime,
                    },
                  })
                }

                update({ landingPage: { isPublishSaveCalled: false } })
              })
            },
          })
        },
      })
    }

    return {
      loading,
      floatButtons: floatButtons,
      tabsProps: {
        defaultValue: tab,
        onChange: handleTabChange,
        childData: [
          ...(!isLandingPageTemplate
            ? [
                {
                  index: CommonComposerTab.SETTINGS,
                  label: t('Settings'),
                  content: <LandingPageComposerSettingsContainer />,
                },
              ]
            : []),
          {
            alwaysRender: true,
            index: CommonComposerTab.DESIGN,
            label: t('Design'),
            content: (
              <>
                {!isStory && (loading || isBeeEditorLoading) && renderLoader('loader--white-background')}
                {!hasCredentials ? (
                  <Typography text={'Unable to load editor, missing credentials'} dataTest={`bee-editor-placeholder`} />
                ) : isInitializeBeeEditor ? (
                  <BeeEditorContainer className={editorClassname} />
                ) : null}
              </>
            ),
          },
          ...(newLPComposerCreateBlank
            ? []
            : [
                {
                  index: CommonComposerTab.REPORT,
                  label: t('Report'),
                  content: isStory ? (
                    <div style={{ margin: '2rem', fontSize: '20px' }}>
                      <Container>Report cannot render in Storybook</Container>
                    </div>
                  ) : (
                    <LandingPageReport id={id} />
                  ),
                },
              ]),
          ...(newLPComposerCreateBlank
            ? [
                {
                  index: CommonComposerTab.CUSTOM_CODE,
                  label: t('Custom Code'),
                  content: <LandingPageComposerCustomCode />,
                },
              ]
            : []),
        ],
      },
    }
  }, [
    editorClassname,
    handlePreview,
    handleTabChange,
    hasCredentials,
    hasUnpublishedChanges,
    id,
    isBeeEditorLoading,
    isContentMissing,
    isInitializeBeeEditor,
    isLandingPagePublished,
    isStory,
    lastPublishedTime,
    loading,
    newLPComposerCreateBlank,
    onSave,
    t,
    tab,
    title,
    update,
    updateModal,
  ])

  return (
    <>
      <EmailComposerModalsState />
      <GlobalComposerHeader dataTest={rootClass} headerProps={headerTopProps} headerBottomProps={headerBottomProps} />
    </>
  )
}
