import React, { FC, useEffect, useMemo, useState, useCallback } from 'react'

import classNames from 'classnames'

import { ApolloQueryResult, FetchResult, MutationFunctionOptions } from '@apollo/client'
import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import LabelWithSvgTooltip from '@components/LabelWithTooltipIcon/LabelWithSvgTooltip'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Svg, { SvgNames } from '@components/Svg'
import Toggle from '@components/Toggle'
import Typography, { ModalHeaderFormStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import {
  EditPermissionsMutation,
  SetAdminPermissionMutation,
  SetAdminPermissionMutationVariables,
  UserPermissionsInput,
} from '@graphql/types/mutation-types'
import { Exact, GetAllMarketingUsersQuery, Maybe, PermissionsResponse, UserResponse } from '@graphql/types/query-types'
import AllPermissionsForm from '@src/pages/Settings/Users/components/AllPermissionsForm/AllPermissionsForm'
import { CopyFromUser } from '@src/pages/Settings/Users/components/CopyPermissionsModal/CopyPermissionsModal'
import { approvalsMap } from '@src/pages/Settings/Users/components/UserLaunchApprovalSelect/launchApproval.const'
import UserLaunchApprovalSelect from '@src/pages/Settings/Users/components/UserLaunchApprovalSelect/UserLaunchApprovalSelect'
import UsersContext from '@src/pages/Settings/Users/context/UsersContext'
import { useSafeContext } from '@utils/account/account.utils'
import { logNewRelicError } from '@utils/new-relic.utils'
import { FetchPromise } from '@utils/types'

import './EditPermissionsModal.css'

interface Props {
  onCopyPermissions: () => void
  user: UserResponse | undefined
  copyFromUser: CopyFromUser | undefined
  editPermissions: (
    options?:
      | MutationFunctionOptions<
          EditPermissionsMutation,
          Exact<{ id: string; permissions?: Maybe<UserPermissionsInput>; launchApproval?: Maybe<string> }>
        >
      | undefined
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>
  setAdmin: (
    options?: MutationFunctionOptions<SetAdminPermissionMutation, SetAdminPermissionMutationVariables> | undefined
  ) => FetchPromise<SetAdminPermissionMutation>
  refetch: () => Promise<ApolloQueryResult<GetAllMarketingUsersQuery>>
  onCancel: () => void
  dataTest?: string
}

const rootClass = 'edit-permissions-modal'

const EditPermissionsModal: FC<Props> = (props: Props) => {
  const { onCopyPermissions, user, copyFromUser, editPermissions, setAdmin, refetch, onCancel, dataTest = rootClass } = props
  const [toggleOn, setToggleOn] = useState<boolean>(false)
  const [permissions, setPermissions] = useState<PermissionsResponse | undefined>()
  const [launchApproval, setLaunchApproval] = useState<{ value?: string; id?: string }>({ value: '', id: '' })
  const [approvalsToSend, setApprovalToSend] = useState<string | undefined>(undefined)
  const [touched, setTouched] = useState<boolean>(false)
  const defaultPermissions = useMemo<PermissionsResponse | undefined>(() => copyFromUser?.permissions ?? user?.permissions, [copyFromUser, user])
  const excludeUserIds = useMemo<string[] | undefined>(() => (user?.id ? [user.id] : undefined), [user])
  const { setToastStatus } = useSafeContext(UsersContext)
  const { t } = useTranslation()

  const resetState = useCallback(() => {
    const userToUseValues = copyFromUser || user
    if (userToUseValues) {
      setToggleOn(!!userToUseValues.isAdmin)
      setPermissions(userToUseValues.permissions)
      setLaunchApproval({ value: userToUseValues.launchApproval, id: userToUseValues.launchApprovalId })
      setApprovalToSend(approvalsMap.get(userToUseValues.launchApproval || '') || userToUseValues.launchApprovalId)
    }
    setTouched(!!copyFromUser)
  }, [user, copyFromUser])

  useEffect(() => resetState(), [resetState])

  const handleResponse = useCallback(
    (status: string | undefined, errors: any) => {
      if (status === 'ok') {
        setToastStatus({
          showStatus: true,
          title: t('User permissions updated!'),
          statusMessage: t('Marketing.Users.Edit.Permissions.Success', { user: user?.fullName }),
          successStatus: true,
        })
      } else {
        setToastStatus({
          showStatus: true,
          title: t('We encountered an error:'),
          statusMessage: t('Marketing.Users.Edit.Permissions.Error', { user: user?.fullName }),
          successStatus: true,
        })
        errors?.map((error: any) => logNewRelicError(error.message))
      }
      refetch()
    },
    [user?.fullName, t]
  )

  // const handleCancel = useCallback(() => onCancel(), [onCancel])

  const handleSave = useCallback(() => {
    onCancel()
    const { accountWideEmails, allContent, automatedPrograms, contactLists } = permissions ?? {}
    const permissionsToSend = {
      accountWideEmails,
      allContent,
      automatedPrograms,
      contactLists,
    }
    const launchApproval = approvalsToSend ?? ''
    const id = user?.id
    if (!id) {
      return
    }
    if (toggleOn) {
      setAdmin({
        variables: {
          ids: [id],
          value: true,
          launchApproval,
        },
      }).then((response) => {
        handleResponse(response?.data?.setAdminPermission?.status, response.errors)
      })
    } else {
      if (user?.isAdmin) {
        setAdmin({
          variables: {
            ids: [id],
            value: false,
            launchApproval,
          },
        }).then((response) => {
          if (response.data?.setAdminPermission?.status === 'ok') {
            editPermissions({
              variables: {
                id,
                permissions: permissionsToSend,
                launchApproval,
              },
            }).then((response) => {
              handleResponse(response.data.editPermissions.status, response.errors)
            })
          }
        })
      } else {
        editPermissions({
          variables: {
            id,
            permissions: permissionsToSend,
            launchApproval,
          },
        }).then((response) => {
          handleResponse(response.data.editPermissions.status, response.errors)
        })
      }
    }
  }, [toggleOn, onCancel, user?.id, approvalsToSend, permissions, handleResponse])

  const handleToggle = useCallback(
    (isOn: boolean) => {
      resetState()
      setToggleOn(isOn)
      setTouched(true)
    },
    [resetState]
  )

  const handleSelectLaunch = useCallback((optionOrUserId?: string) => {
    setApprovalToSend(optionOrUserId)
    setTouched(!!optionOrUserId)
  }, [])

  const handlePermissionsChange = useCallback(({ permissions, launchApproval }: { permissions: UserPermissionsInput; launchApproval?: string }) => {
    setPermissions(permissions)
    setApprovalToSend(launchApproval)
    setTouched(!!launchApproval)
  }, [])

  const handleCancel = useCallback(() => onCancel(), [onCancel])

  return (
    <Modal
      isOpen={!!user}
      className={classNames(rootClass, {
        [`${rootClass}__unset-overflow`]: toggleOn,
      })}
      dataTest={dataTest}
      header={
        <ModalHeader headerType={ModalHeaderType.Form}>
          <Typography text={t('Edit Permissions')} {...ModalHeaderFormStyle} />
          <div className={`${rootClass}__header`}>
            <Button
              iconPosition={ButtonIconPosition.LEFT}
              noPadding
              buttonType={ButtonType.FLOAT}
              onClick={onCopyPermissions}
              dataTest={`${dataTest}-copy-permissions-button`}
              className={`${rootClass}__header-copy-btn`}
            >
              <Svg name={SvgNames.cloneSegment} />
              {t('Copy permissions')}
            </Button>
          </div>
        </ModalHeader>
      }
    >
      <ModalBody>
        <div className={`${rootClass}__body`}>
          <div className={`${rootClass}__body__admin-access`}>
            <div>
              <Typography
                text={t('Has administrator access')}
                weight={TextWeight.MEDIUM}
                className={`${rootClass}__body__admin-access__description`}
              />
              <Typography text={t('Marketing.Users.Edit.Permissions.Toggle.Description')} type={TextType.NORMAL_TEXT_GRAY} />
            </div>
            <div>
              <Toggle large isOn={toggleOn} onToggle={handleToggle} dataTest={`${dataTest}-toggle`} />
            </div>
          </div>
          {toggleOn ? (
            <div>
              <div className={`${rootClass}__content`}>
                <LabelWithSvgTooltip
                  label={t('Allowed to launch')}
                  tooltipContent={t('Allowed.To.Launch.Tooltip')}
                  className={`${rootClass}__content-title`}
                  alignTextCenter
                />
                <UserLaunchApprovalSelect
                  onSubmit={handleSelectLaunch}
                  launchApproval={launchApproval.value}
                  launchApprovalId={launchApproval.id}
                  excludeUserIds={excludeUserIds}
                />
              </div>
            </div>
          ) : (
            <AllPermissionsForm
              onValueChange={handlePermissionsChange}
              permissions={defaultPermissions}
              launchApproval={launchApproval?.value}
              launchApprovalId={launchApproval?.id}
              excludeUserIds={excludeUserIds}
            />
          )}
        </div>
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form}>
        <Button buttonType={ButtonType.TERTIARY} onClick={handleCancel} dataTest={`${dataTest}-cancel-button`}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} onClick={handleSave} disabled={!touched} dataTest={`${dataTest}-save-button`}>
          {t('Save Changes')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default EditPermissionsModal
