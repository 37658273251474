import { gql } from '@apollo/client'

export default gql`
  mutation saveFormEmbedCode($embedCode: FormEmbedCodeInput!) {
    saveFormEmbedCode(embedCode: $embedCode) {
      formId
      embedCodeId
      deploymentId
      name
      externalURL
      sourceHtml
      prefilledHtml
      noCssHtml
      noCssAndPrefilledHtml
    }
  }
`
