import React, { FC, useState } from 'react'

import Button, { ButtonType } from '@components/Button'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import PillLabel, { PillLabelType } from '@components/PillLabel/PillLabel'
import SingleSelectDropdown from '@components/SingleSelectDropdown/SingleSelectDropdown'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ListDataType, SelectOptionsType } from '@src/pages/datamanagement/context/DataManagementContext'
import { CrmEntityType, MappingStatus } from '@src/pages/datamanagement/utils/DataManagement.constants'

import './MappingField.css'

export interface MappingFieldProps {
  data: ListDataType
  updateRow: (rowId: number, entityType: CrmEntityType, option?: SelectOptionsType) => void
  entityType?: CrmEntityType
  dataTest?: string
}

export enum Status {
  DEFAULT = 'Default',
  NOT_SET = 'Not set',
  ERROR = 'Error',
  CUSTOM = 'Custom',
}

const MapPillLabelType = new Map<string, PillLabelType>([
  ['Default', PillLabelType.DEFAULT],
  ['Not set', PillLabelType.NOT_SET],
  ['Error', PillLabelType.ERROR],
  ['Custom', PillLabelType.CUSTOM],
])

const rootClass = 'mapping-field'

const MappingField: FC<MappingFieldProps> = (props) => {
  const { data, entityType, updateRow, dataTest = rootClass } = props
  const { id, options, defaultOption, selectedOption, reportField, mappingStatus } = data

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { t } = useTranslation()

  const isMarketSegment = reportField.fieldName === 'MarketSegment'
  const status =
    mappingStatus === MappingStatus.Valid
      ? !selectedOption?.value && !defaultOption?.value
        ? Status.NOT_SET
        : defaultOption?.value && !selectedOption?.value
        ? Status.DEFAULT
        : Status.CUSTOM
      : Status.ERROR

  return (
    <div className={rootClass} data-test={dataTest}>
      <div className={`${rootClass}__select`} data-test={`${dataTest}-select`}>
        <SingleSelectDropdown
          options={options}
          value={selectedOption?.value ?? defaultOption?.value ?? null}
          placeholder={t('Select field to map')}
          className={`${rootClass}__select-dropdown`}
          dataTest={`${dataTest}-select-dropdown`}
          onSubmit={(_, option) => {
            entityType && updateRow(id, entityType, option)
          }}
        />
        <Svg
          name={SvgNames.arrowMapping}
          type={SvgType.LARGER_ICON}
          className={`${rootClass}__select-separator`}
          dataTest={`${dataTest}-select-separator`}
        />
      </div>
      <div className={`${rootClass}__report-field`} data-test={`${dataTest}-report-field`}>
        <Typography text={reportField.displayName} type={TextType.BODY_TEXT} />
        {isMarketSegment && <Tooltip trigger={<Svg name={SvgNames.info} type={SvgType.ICON} />}>{t('Company size')}</Tooltip>}
      </div>
      <div className={`${rootClass}__status`} data-test={`${dataTest}-status`}>
        <Tooltip
          className={`${rootClass}__status-tooltip`}
          trigger={<PillLabel noMargin type={MapPillLabelType.get(status)} text={status} dataTest={`${dataTest}-pill-label`} />}
          alignTextCenter
          hide={status !== Status.ERROR}
        >
          {t('This field can no longer be found in your CRM')}
        </Tooltip>
        {[Status.CUSTOM, Status.ERROR].includes(status) && (
          <div className={`${rootClass}__actions`} data-test={`${rootClass}-actions`}>
            <Tooltip
              trigger={
                <Button
                  buttonType={ButtonType.TABLE_ACTION}
                  onClick={() => setIsOpen(true)}
                  className={`${rootClass}__actions-reset`}
                  dataTest={`${rootClass}-action-reset`}
                >
                  <Svg name={SvgNames.reset} type={SvgType.MEDIUM} />
                </Button>
              }
            >
              {t('Reset')}
            </Tooltip>
          </div>
        )}
      </div>
      <ConfirmationModal
        className={`${rootClass}__confirmation-modal`}
        dataTest={`${dataTest}-confirmation-modal`}
        isYesNo
        isOpen={isOpen}
        yesButtonText={t('Confirm')}
        title={t('Are you sure?')}
        body={t(
          !defaultOption?.value
            ? 'Resetting this field will result in missing data in your report, and the field will revert back to its original "not set" state.'
            : 'Field mapping will be reverted to default if this field is reset.'
        )}
        onAnswer={(answer: YesNo) => {
          if (answer === YesNo.YES) {
            entityType && updateRow(id, entityType)
          }
          setIsOpen(false)
        }}
      />
    </div>
  )
}

export default MappingField
