import React, { FC, RefObject, useContext, useState } from 'react'

import ListingPageHelpSection from '@complex/ListingPage/Components/ListingPageHelpSection/ListingPageHelpSection'
import { DeleteConfirmationModals } from '@complex/ListingPage/Components/ListingPageModals/ListingPageModals'
import { getSidebarMenuItems } from '@complex/ListingPage/Components/Sidebar/MenuItems/SidebarMenuItems'
import {
  FolderActions,
  MAX_FOLDER_DEPTH,
  MenuActions,
  TagRowActions,
  getFlattenedSubFolderIds,
  isCustomFilterSelected,
} from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { getFolderById } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { ListingPageCommonContext } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { useFolderRequests } from '@complex/ListingPage/GraphQL/Folders.graphQL'
import { useFolderSubTypes } from '@complex/ListingPage/Hooks/useFolderSubTypes'
import { getSessionData, ListingPageSession } from '@complex/ListingPage/Utils/ListingPage.utils'
import CollapsibleMenu from '@components/CollapsibleMenu/CollapsibleMenu'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { Status } from '@components/StatusToast/StatusToast'
import { useTranslation } from '@const/globals'
import { FolderDto, LabelDto, SubTypeDto, UpdateSubTypeByFolderIdsMutationVariables } from '@graphql/types/microservice/categorization-types'
import { ItemType } from '@utils/categorization'
import { FilterDefinition } from '@utils/filter'
import { Folder } from '@utils/interface/Folder'
import { getItem, removeItem, setItem } from '@utils/sessionStorage'

import { useSidebarBase } from '../../Hooks/useSidebarBase'

import './SidebarContainer.css'

const rootClass = 'sidebar-container'

interface SidebarContainer {
  menuReference?: RefObject<HTMLDivElement>
  outerScrollAreaRef?: RefObject<HTMLDivElement>
  className?: string
}

const SidebarContainer: FC<SidebarContainer> = (props: SidebarContainer) => {
  const { menuReference, outerScrollAreaRef } = props
  const {
    update,
    getItemTypesUsedInTags,
    moveItemsToFolder,
    setFolder,
    setFilter,
    toggleSubType,
    setTag,
    setError,
    onShouldFetch,
    values: {
      dataTest,
      folders,
      filterActive,
      activeFolderId,
      showSalesUsersControls,
      itemType,
      listingPageProps,
      listingPageProps: {
        showHelpSection,
        sidebarProps: { sidebarHeader, allItemFilter },
      },
    },
  } = useContext(ListingPageCommonContext)

  const { t } = useTranslation()

  const { sessionSalesUsersEnabledConfirmation } = getSessionData(`${itemType}`)

  const [hasCollapsedMenu, setHasCollapsedMenu] = useState<boolean>(getItem(ListingPageSession.COLLAPSED_MENU) === 'true')

  const folderSubTypes = useFolderSubTypes(listingPageProps)
  const { updateSubTypeByFolderIdsRequest } = useFolderRequests(folderSubTypes)

  const onAddFolder = () => {
    const folder = activeFolderId ? getFolderById(activeFolderId, folders) : undefined
    if (folder?.depth === MAX_FOLDER_DEPTH) {
      update({
        statusToast: {
          statusMessage: t('ListPage.Common.Folders.MaxDepth', { folder: folder }),
          showStatusToast: true,
          status: Status.FAIL,
        },
      })
    } else {
      update({
        creatingFolder: true,
      })
    }
  }

  const onToggleSalesUsersControls = () => {
    update({
      showSalesUsersControls: !showSalesUsersControls,
    })
  }

  const onToggleSalesUsersEnabled = async (folderId: number, isEnabled: boolean) => {
    const type = itemType ?? ItemType.FRAGMENT
    const folder = getFolderById(folderId, folders)
    if (folder) {
      const folderIds = getFlattenedSubFolderIds(folderId, folders)
      const subTypeProps: UpdateSubTypeByFolderIdsMutationVariables = isEnabled
        ? { subTypesToAdd: [{ itemType: type, name: 'sales' }] }
        : { subTypesToBeRemoved: [{ itemType: type, name: 'sales' }] }
      const { data, errors } = await updateSubTypeByFolderIdsRequest({
        type,
        folderIds: folderIds,
        ...subTypeProps,
      })

      const updatedFolders = (folders as FolderDto[]).map((folder) => {
        if (!folderIds.includes(folder.id)) {
          return folder
        } else {
          const subTypes = (folder.subTypeDTOS as SubTypeDto[]) ?? []
          if (!isEnabled) {
            return { ...folder, subTypeDTOS: subTypes.filter((subType) => subType?.name !== 'sales') }
          } else {
            return { ...folder, subTypeDTOS: [...subTypes, { name: 'sales' }] }
          }
        }
      })

      if (data) {
        update({ folders: updatedFolders as Folder[], showSalesUsersEnabled: isEnabled && sessionSalesUsersEnabledConfirmation })
      }

      if (errors) {
        setError(t('There was a problem enabling sales users for this folder.'), errors)
      }
    }
  }

  const onAddTag = () => {
    update({
      showManageTag: true,
      tagToEdit: undefined,
    })
  }

  const folderRowActions = {
    [FolderActions.RENAME]: (folder: FolderData) => {
      update({
        hoveredFolder: folder,
        isEditingFolder: true,
      })
    },
    [FolderActions.DELETE]: (folder: FolderData) => {
      update({
        deleteConfirmationData: folder,
        confirmationModal: folder.parentId ? DeleteConfirmationModals.DELETE_FOLDER_WITH_PARENT : DeleteConfirmationModals.DELETE_FOLDER,
        showDeleteConfirmationModal: true,
      })
    },
    [FolderActions.ADD_SUBFOLDER]: (folder: FolderData) => {
      update({
        hoveredFolder: folder,
        creatingFolder: true,
      })
    },
  }

  const tagRowActions = {
    [TagRowActions.EDIT]: (tag: LabelDto) => {
      update({ tagToEdit: tag, showManageTag: true, isEditingTag: true })
    },
    [TagRowActions.DELETE]: async (tag: LabelDto) => {
      const itemTypesUsedInTags = await getItemTypesUsedInTags(tag.id)
      update({
        deleteConfirmationData: tag,
        confirmationModal: DeleteConfirmationModals.DELETE_TAG,
        itemTypesUsed: itemTypesUsedInTags,
        showDeleteConfirmationModal: true,
      })
    },
  }

  const menuActions: MenuActions = {
    addFolder: () => onAddFolder(),
    toggleSalesUsersControls: () => onToggleSalesUsersControls(),
    clickFolder: (folderId: number) => {
      update({ items: [] })
      setFolder(folderId)
    },
    toggleSalesUsersEnabled: onToggleSalesUsersEnabled,
    clickFolderAction: (folder: FolderData, action: FolderActions) => folderRowActions[action](folder),
    clickFilter: (filter: FilterDefinition) => {
      update({ items: [] })
      setFilter(filter, isCustomFilterSelected(allItemFilter, filter), filter.name === allItemFilter.name)
    },
    clickSubType: (subType: string, toggleOffSubTypes?: string[]) => {
      onShouldFetch()
      toggleSubType?.(subType, toggleOffSubTypes)
    },
    clickCustomFilter: (filter: FilterDefinition) => {
      onShouldFetch()
      if (filter === filterActive) {
        setFilter(allItemFilter, false)
      } else {
        setFilter(filter, true)
      }
    },
    addTag: () => onAddTag(),
    clickTag: (tag) => {
      update({ items: [] })
      setTag(tag, true)
    },
    clickTagAction: (tag: LabelDto, action: TagRowActions) => tagRowActions[action](tag),
    dropItem: (folder: FolderData) => moveItemsToFolder(folder.id),
  }

  const { getActiveMenuItemIndex, menuItems } = useSidebarBase(menuActions, getSidebarMenuItems)

  const renderMenu = () => {
    return (
      <CollapsibleMenu
        header={sidebarHeader}
        indexActive={getActiveMenuItemIndex()}
        items={menuItems}
        onCollapse={(collapsed) => {
          if (hasCollapsedMenu) {
            removeItem(ListingPageSession.COLLAPSED_MENU)
          } else {
            setItem(ListingPageSession.COLLAPSED_MENU, `${collapsed}`)
          }
          setHasCollapsedMenu(!hasCollapsedMenu)
        }}
        isCollapsed={hasCollapsedMenu}
        reference={menuReference}
        outerScrollAreaRef={outerScrollAreaRef}
        dataTest={dataTest}
        className={rootClass}
      />
    )
  }

  return showHelpSection && !hasCollapsedMenu ? (
    <div>
      {renderMenu()}
      {<ListingPageHelpSection />}
    </div>
  ) : (
    renderMenu()
  )
}

export default SidebarContainer
