import originsWithErrors from '@graphql/microservices/crm-sync/originsWithErrors'
import { OriginsWithErrorsQuery, OriginsWithErrorsQueryVariables } from '@graphql/types/microservice/crm-sync-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export interface FormsJoinViewListingPageCRMRequests {
  getCrmPushErrorsRequest: () => FetchPromise<OriginsWithErrorsQuery>
}

export const useFormsJoinViewListingPageCRMRequests = (): FormsJoinViewListingPageCRMRequests => {
  const { client: crmSyncClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CRM_SYNC })

  const getCrmPushErrorsRequest = () =>
    crmSyncClient.query<OriginsWithErrorsQuery, OriginsWithErrorsQueryVariables>({
      query: originsWithErrors,
      variables: {
        sourceType: 'FORM',
      },
    })

  return {
    getCrmPushErrorsRequest,
  }
}
