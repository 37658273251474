import { ApolloClient } from '@apollo/client'
import createProgramFromTemplate from '@graphql/mutations/createProgramFromTemplate'
import createProgramTemplate from '@graphql/mutations/createProgramTemplate'
import deleteProgramTemplates from '@graphql/mutations/deleteProgramTemplates'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import {
  CreateProgramFromTemplateMutation,
  CreateProgramFromTemplateMutationVariables,
  CreateProgramTemplateMutation,
  CreateProgramTemplateMutationVariables,
  DeleteProgramTemplatesMutation,
  DeleteProgramTemplatesMutationVariables,
  TemplateIdDescription,
} from '@graphql/types/mutation-types'
import { FetchPromise } from '@utils/types'

export interface AutomatedProgramTemplatesListingPageRequests {
  deleteItemsRequest: (items: ItemDto[]) => FetchPromise<DeleteProgramTemplatesMutation>
  createTemplateRequest: (
    programId: string,
    title: string,
    descriptionMapping: TemplateIdDescription[]
  ) => FetchPromise<CreateProgramTemplateMutation>
  createProgramFromTemplateRequest: (id: string, mapping: TemplateIdDescription[]) => FetchPromise<CreateProgramFromTemplateMutation>
}

export const getAutomatedProgramTemplatesListingPageRequests = (actonClient: ApolloClient<any>): AutomatedProgramTemplatesListingPageRequests => {
  const deleteItemsRequest = async (items: ItemDto[]) => {
    const templateIds = items.map(({ externalId }) => externalId) ?? []
    return await actonClient.mutate<DeleteProgramTemplatesMutation, DeleteProgramTemplatesMutationVariables>({
      mutation: deleteProgramTemplates,
      errorPolicy: 'all',
      variables: {
        templateIds,
      },
    })
  }

  const createTemplateRequest = async (programId: string, title: string, descriptionMapping: TemplateIdDescription[]) => {
    return await actonClient.mutate<CreateProgramTemplateMutation, CreateProgramTemplateMutationVariables>({
      mutation: createProgramTemplate,
      errorPolicy: 'all',
      variables: {
        programId,
        title,
        descriptionMapping,
      },
    })
  }

  const createProgramFromTemplateRequest = async (id: string, mapping: TemplateIdDescription[]) => {
    return await actonClient.mutate<CreateProgramFromTemplateMutation, CreateProgramFromTemplateMutationVariables>({
      mutation: createProgramFromTemplate,
      errorPolicy: 'all',
      variables: {
        id,
        mapping,
      },
    })
  }

  return {
    deleteItemsRequest,
    createTemplateRequest,
    createProgramFromTemplateRequest,
  }
}
