import { gql } from '@apollo/client'

export default gql`
  query getAutomatedProgramStandardExitCounts($programId: String!, $noUpdate: Boolean!, $unitsAgo: Int!, $groupBy: String!) {
    getAutomatedProgramStandardExitCounts(programId: $programId, noUpdate: $noUpdate, unitsAgo: $unitsAgo, groupBy: $groupBy) {
      standardExitDay {
        date
        count
      }
      totalStandardExits
    }
  }
`
