import React, { FC, useContext, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import ButtonShowHide, { ButtonShowHideSize } from '@components/ButtonShowHide/ButtonShowHide'
import Checkbox from '@components/Checkbox'
import InfoTooltip, { InfoTooltipIconSize } from '@components/InfoTooltip/InfoTooltip'
import KeyValuePicker from '@components/KeyValuePicker'
import { KeyValue } from '@components/KeyValuePicker/KeyValuePicker'
import { LinkTextButton, LinkTextButtonColor } from '@components/LinkTextButton/LinkTextButton'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { detectEmailType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'
import { useTranslation } from '@utils/const/globals'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'
import { useDetectInteraction } from '@utils/hooks/useDetectInteraction'

import { ANALYTICS_SETTINGS_LINK, UTM_PARAMS, formatDomain, keyValueProps, validateAnalyticsFields } from './AnalyticsTrackingPane.utils'
import AnalyticsVariablesModal from './components/AnalyticsVariablesModal/AnalyticsVariablesModal'
import { SettingsFormChangeHandler } from '../../../../EmailComposerSettings.utils'

import './AnalyticsTrackingPane.css'

interface AnalyticsTrackingProps {
  className?: string
  dataTest?: string
  onFieldChange: SettingsFormChangeHandler
  onActive: () => void
  onInactive: () => void
}

const rootClass = 'analytics-tracking-pane'

const AnalyticsTrackingPane: FC<AnalyticsTrackingProps> = (props) => {
  const { dataTest = rootClass, onFieldChange, onActive, onInactive } = props
  const paneRef = useRef<HTMLDivElement>(null)
  const {
    values: {
      analyticsDomains,
      message: { analyticsFields, hasPrePopulatedUTMParams },
      messageConfiguration: {
        messageType,
        settings: { disableAnalyticsTracking },
      },
    },
    api: { updateModal, update: updateComposer },
  } = useContext(EmailComposerContext)
  const isMounted = useRef(false)
  const [state, setState] = useState({
    isShowingDomains: false,
    isShowingVariables: false,
    isDirty: true,
    localFields: analyticsFields,
  })
  const { localFields, isShowingDomains, isShowingVariables, isDirty } = state
  const update = useDeepUpdate(setState)

  const { t } = useTranslation()

  const { isEmailBlankMessage } = detectEmailType(messageType)

  useEffect(() => {
    if (isMounted.current) {
      onFieldChange(
        'analyticsFields',
        localFields.filter((field) => !!field.key || !!field.value)
      )
    } else {
      isMounted.current = true
    }
  }, [onFieldChange, localFields])

  useDetectInteraction({
    containerRef: paneRef,
    // Timeouts to prevent this from triggering before onChange
    onOutside: () => {
      setTimeout(() => onInactive(), 100)
      update({ isDirty: true })
    },
    onInside: () => {
      setTimeout(() => onActive(), 100)
      update({ isDirty: false })
    },
  })

  const handleUTMChange = (isChecked: boolean) => {
    const newEntries: KeyValue[] = []
    const currentKeys = localFields.map((keyValue) => keyValue.key)
    const utmKeys = UTM_PARAMS.map((keyValue) => keyValue.key)
    const utmValues = UTM_PARAMS.map((keyValue) => keyValue.value)

    if (isChecked) {
      UTM_PARAMS.forEach((param) => {
        if (!currentKeys.includes(param.key)) {
          newEntries.push(param)
        }
      })
      update({ localFields: [...localFields, ...newEntries].filter((keyValue) => !!keyValue.key || !!keyValue.value) })
    } else {
      update({
        localFields: localFields
          .filter((keyValue) => {
            const utmIndex = utmKeys.indexOf(keyValue.key)
            return utmIndex === -1 || (utmIndex !== -1 && keyValue.value !== utmValues[utmIndex])
          })
          .filter((keyValue) => !!keyValue.key || !!keyValue.value),
      })
    }
  }

  const domainList = analyticsDomains.map((domain) => formatDomain(domain)).join(', ')

  return (
    <div className={`${rootClass}`} ref={paneRef}>
      {isShowingVariables && <AnalyticsVariablesModal onClose={() => update({ isShowingVariables: false })} isOpen />}
      <Typography
        type={TextType.BODY_TEXT_LIGHT}
        text={'EmailComposer.Settings.AnalyticsTracking.UTM.Description'}
        tagComponents={{
          TextLink: (
            <TextLink
              size={TextLinkSize.LARGE}
              onClick={() => {
                updateModal('statusToast', undefined)
                update({ isShowingVariables: true })
              }}
              hideIcon
            />
          ),
        }}
        inline
      />
      <Tooltip
        hide={!disableAnalyticsTracking}
        position={'top'}
        trigger={
          <Checkbox
            label={t('EmailComposer.Settings.AnalyticsTracking.UTM.Prepopulate')}
            onChange={(isChecked) => {
              updateComposer({ message: { hasPrePopulatedUTMParams: isChecked } })
              handleUTMChange(isChecked)
            }}
            disabled={disableAnalyticsTracking}
            dataTest={`${dataTest}-utm-prepopulate`}
            checked={hasPrePopulatedUTMParams}
          />
        }
      >
        <Typography text={t('EmailComposer.Resend.Disabled')} type={TextType.BODY_TEXT_WHITE} />
      </Tooltip>

      <KeyValuePicker
        {...keyValueProps}
        disableAdd={disableAnalyticsTracking}
        disabled={disableAnalyticsTracking}
        dataTest={`${dataTest}-parameters`}
        values={localFields}
        onChange={(keyValues) => update({ localFields: keyValues })}
        validityFunctions={isDirty && !isEmailBlankMessage ? validateAnalyticsFields : undefined}
      />
      <div className={`${rootClass}__domains`}>
        <div className={`${rootClass}__domains__header`}>
          <div className={`${rootClass}__domains__header__text`}>
            <Typography
              text={t('EmailComposer.Settings.AnalyticsTracking.Domains.Header', { count: analyticsDomains.length })}
              weight={TextWeight.MEDIUM}
              type={TextType.BODY_TEXT_SMALL}
            />

            <InfoTooltip minimalPadding={false} iconSize={InfoTooltipIconSize.MEDIUM} className={`${rootClass}__domains__toggletip`} isPopover>
              <>
                <Typography type={TextType.BODY_TEXT_WHITE} text={`${t(`EmailComposer.Settings.AnalyticsTracking.Domains.Tooltip`)} `} />
                <LinkTextButton color={LinkTextButtonColor.WHITE} link={ANALYTICS_SETTINGS_LINK} hideIconLeft>
                  {t('EmailComposer.Settings.AnalyticsTracking.Domains.Tooltip.Link')}
                </LinkTextButton>
              </>
            </InfoTooltip>
          </div>
          <ButtonShowHide
            dataTest={`${dataTest}-show-domains`}
            size={ButtonShowHideSize.SMALL}
            isShowing={isShowingDomains}
            disabled={!analyticsDomains.length}
            onClick={() => update({ isShowingDomains: !isShowingDomains })}
          />
        </div>
        {isShowingDomains && (
          <div data-test={`${dataTest}-domain-list`} className={classNames(`${rootClass}__domains__list`)}>
            {domainList}
          </div>
        )}
      </div>
    </div>
  )
}

export default AnalyticsTrackingPane
