import React, { Dispatch, ReactNode, SetStateAction } from 'react'

import classNames from 'classnames'
import { TFunction } from 'i18next'

import { getFolderById } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { ActivityTypes, CustomActivityTypes, ItemActivityState } from '@components/ItemActivity/ItemActivity'
import { SegmentDetailsContainerState } from '@components/SegmentDetails/context/SegmentDetails.context'
import { Status } from '@components/StatusToast/StatusToast'
import { renderIconTextCol, renderTextIconLinkCol } from '@components/Table/components/tableColumns'
import { TOAST_TEXT_CLASSNAME } from '@components/Toast/Toast'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { legacyActonContext, rootContext } from '@const/globals'
import { ItemDto, LabelDtoInput } from '@graphql/types/microservice/categorization-types'
import { SegmentActivityDto } from '@graphql/types/microservice/segment-types'
import { Folder } from '@interface/Folder'
import { SetContainerValues } from '@interface/ListPage.context'
import { ItemType } from '@utils/categorization'

import SvgNames from '../Svg/SvgNames'

const LIST_MAINTENANCE_PROGRAMS_URL = `${rootContext}/automation/listMaintenance`

export const getAllActivityUtils = (
  setState: Dispatch<SetStateAction<ItemActivityState>>,
  getAllActivityRequest: (externalId: string, type: ItemType) => Promise<SegmentActivityDto[]>,
  segmentId: string,
  type: ItemType
) => {
  setState((state: ItemActivityState) => ({
    ...state,
    loadingAllActivity: true,
  }))
  getAllActivityRequest(segmentId, type)
    .then((allActivity) => {
      setState((state) => ({
        ...state,
        allActivity,
        loadingAllActivity: false,
      }))
    })
    .catch(() => {
      setState((state: ItemActivityState) => ({
        ...state,
        loadingAllActivity: false,
      }))
    })
}

const goToMessageDashboard = (id: string) => {
  window.open(`${legacyActonContext}/messages/sentMessage.jsp?msgid=${id}`, 'messageDashboard', 'width=1000,height=600')
}

export const getSegmentActivities = (
  t: TFunction,
  hasParent: boolean,
  itemType: ItemType
): { [key in ActivityTypes]: (activity: SegmentActivityDto) => ReactNode } => ({
  [ActivityTypes.AUTOMATED_PROGRAMS]: ({ activityIdentifier, activityName = '' }) => {
    return renderTextIconLinkCol(
      t('Used in'),
      SvgNames.automation,
      activityName,
      `${rootContext}/automation/programs/${activityIdentifier}/program-performance`,
      '_self'
    )
  },
  [ActivityTypes.CREATED]: () => {
    return itemType === ItemType.FORM_SUBMISSION && !hasParent
      ? renderIconTextCol(SvgNames.signUp, t('Form submission list created'))
      : renderIconTextCol(SvgNames.createSubsegment, t('Segment created'))
  },
  [ActivityTypes.EDITED]: () => {
    return renderIconTextCol(SvgNames.pencil, t('Segment edited'))
  },
  [ActivityTypes.EXPORT]: () => {
    return t('Segment exported')
  },
  [ActivityTypes.FORM]: ({ activityName = '' }) => {
    return renderTextIconLinkCol(t('Destination for'), SvgNames.signUpForm, activityName, '#')
  },
  [ActivityTypes.FTP_EXPORT]: () => {
    return t('Segment exported')
  },
  [ActivityTypes.FTP_IMPORT]: () => {
    return t('Segment imported')
  },
  [ActivityTypes.IMPORT]: ({ activityName = '' }) => {
    return renderTextIconLinkCol(t('Appended'), SvgNames.appendedList, activityName, `${rootContext}/importContacts`)
  },
  [ActivityTypes.LIST_MAINTENANCE_PROGRAMS]: ({ activityIdentifier, activityName = '' }) => {
    return renderTextIconLinkCol(
      t('Used in'),
      SvgNames.listMaintenance,
      activityName,
      `${LIST_MAINTENANCE_PROGRAMS_URL}?programId=${activityIdentifier}&tab=details&backTo=${window.location.pathname}`,
      '_self'
    )
  },
  [ActivityTypes.MESSAGE]: ({ activityName = '', activityIdentifier = '' }) => {
    return renderTextIconLinkCol(t('Sent'), SvgNames.letter, activityName, '#', '', () => goToMessageDashboard(activityIdentifier))
  },
  [ActivityTypes.SMS]: ({ activityIdentifier, activityName = '' }) => {
    return renderTextIconLinkCol(t('Sent'), SvgNames.sendSms, activityName, `${rootContext}/sms/${activityIdentifier}/report`, '_self')
  },
  [ActivityTypes.SMS_SCHEDULED]: ({ activityName = '', activityIdentifier }) => {
    return renderTextIconLinkCol(t('Scheduled'), SvgNames.sendSms, activityName, `${rootContext}/sms/${activityIdentifier}/report`, '_self')
  },
  [ActivityTypes.WEBINAR]: ({ activityName = '' }) => {
    return renderTextIconLinkCol(t('Destination for'), SvgNames.webinarOutline, activityName, '#')
  },
  [ActivityTypes.CUSTOM]: ({ activityName = '' }) => {
    const customActivityMapping: Record<string, { icon: string; text: string }> = {
      [CustomActivityTypes.SEGMENT_CREATED]: {
        icon: SvgNames.createSubsegment,
        text: t('Segment created'),
      },
      [CustomActivityTypes.AB_SPLIT]: {
        icon: SvgNames.abSplit,
        text: t('A/B split'),
      },
      [CustomActivityTypes.RECORD_CLEARED]: {
        icon: SvgNames.databaseClear,
        text: t('Records cleared'),
      },
    }

    const customActivity = customActivityMapping[activityName] || {
      icon: '',
      text: '',
    }

    return renderIconTextCol(customActivity.icon as SvgNames, customActivity.text)
  },
})

export const moveItemsIntoFolder = (
  segments: ItemDto[],
  folderId: number,
  addToFolder: boolean,
  folders: Folder[],
  rootClass: string,
  t: Function,
  setContainerValues: (fields: Partial<SegmentDetailsContainerState>) => void,
  setActivityState: SetContainerValues<ItemActivityState>,
  moveItemsIntoFolderRequest: Function,
  itemType: ItemType
) => {
  const cannotMove = segments.every((segment) => segment.folderId === folderId)
  if (cannotMove) {
    const folderName = getFolderById(folderId, folders)?.name
    const statusMessage = (
      <div className={TOAST_TEXT_CLASSNAME}>
        <Typography
          text={`${t(`Segment${segments.length === 1 ? ' is' : 's are'} already in target folder`)}`}
          inline
          className={`${rootClass}__toast-folder-move`}
        />
        <Typography text={folderName} weight={TextWeight.MEDIUM} inline />
      </div>
    )
    setContainerValues({
      statusToast: {
        statusMessage,
        showStatusToast: true,
        status: Status.SUCCESS,
      },
    })
  } else {
    const segmentsIds = segments.map((segment) => segment.id)
    moveItemsIntoFolderRequest(segmentsIds, folderId, itemType).then(() => {
      const folder = getFolderById(folderId, folders)
      setActivityState((state) => ({ ...state, movingItem: false }))
      setContainerValues({
        statusToast: {
          statusMessage: (
            <div className={classNames(TOAST_TEXT_CLASSNAME, `contact-segments__toast`)}>
              <Typography
                text={`${t(addToFolder ? `Added` : `Moved`)} ${segmentsIds.length} ${t(`segment${segmentsIds.length > 1 ? 's' : ''} to`)} `}
              />
              <Typography className={'ellip'} text={folder?.name} weight={TextWeight.MEDIUM} />
            </div>
          ),
          showStatusToast: true,
          status: Status.SUCCESS,
        },
      })
    })
  }
}

export const createTagUtils = (
  update: (fields: Partial<SegmentDetailsContainerState>) => void,
  tag: LabelDtoInput,
  createTagsRequest: Function,
  itemType: ItemType
) => {
  createTagsRequest(tag, itemType).then(() => {
    update({
      statusToast: {
        statusMessage: `Tag has been ${tag.id ? 'updated' : 'added'}`,
        showStatusToast: true,
        status: Status.SUCCESS,
      },
    })
  })
}
