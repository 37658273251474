import { RefObject } from 'react'
import { GroupBase, MultiValue, OptionsOrGroups, SingleValue } from 'react-select'

import classNames from 'classnames'
import { SelectComponents } from 'react-select/dist/declarations/src/components'

import CustomClearIndicator from '@components/SelectV2/components/CustomClearIndicator'
import CustomDropdownIndicator from '@components/SelectV2/components/CustomDropdownIndicator'
import CustomGroupHeading from '@components/SelectV2/components/CustomGroupHeading'
import CustomIndicatorsContainer from '@components/SelectV2/components/CustomIndicatorsContainer'
import CustomInput from '@components/SelectV2/components/CustomInput/CustomInput'
import CustomLoadingMessage from '@components/SelectV2/components/CustomLoadingMessage'
import CustomMenu from '@components/SelectV2/components/CustomMenu/CustomMenu'
import CustomMenuList from '@components/SelectV2/components/CustomMenuList/CustomMenuList'
import CustomMultiValue from '@components/SelectV2/components/CustomMultiValue'
import CustomNoOptionsMessage from '@components/SelectV2/components/CustomNoOptionsMessage'
import CustomOption from '@components/SelectV2/components/CustomOption/CustomOption'
import CustomPlaceholder from '@components/SelectV2/components/CustomPlaceholder'
import CustomSelectContainer from '@components/SelectV2/components/CustomSelectContainer'
import CustomSingleValue from '@components/SelectV2/components/CustomSingleValue'
import CustomValueContainer from '@components/SelectV2/components/CustomValueContainer'
import { Update } from '@components/SelectV2/SelectV2.context'
import { isStringArray } from '@utils/strings'

import { SelectV2GroupedOption, SelectV2Option, SelectV2Props, SelectV2SingleOption } from './SelectV2.props'

export const selectRootClass = 'selectv2'
const INPUT_EXTRA_WITDH = 50
const HIDDEN_CLASS = 'hidden'
const ONLY_CHIP_CLASS = 'only-chip'

interface GetCustomComponentsParams {
  hideDropdownIndicator: boolean
  isPaginator: boolean
  truncateMultiValues?: boolean
}

interface GetCustomClassNamesParams extends GetCustomComponentsParams {
  error: boolean
  hasSearchOnClick: boolean
  insideModal: boolean
  isClearable: boolean
  isSearchable: boolean
  truncateInputValueInNewOption: boolean
  truncateOptions: boolean
  showGroupsWithoutLabel: boolean
  hasFooter: boolean
}

export const getCustomComponents = ({
  hideDropdownIndicator,
  isPaginator,
  truncateMultiValues,
}: GetCustomComponentsParams): Partial<SelectComponents<SelectV2Option, boolean, GroupBase<SelectV2Option>>> => ({
  ClearIndicator: CustomClearIndicator,
  DropdownIndicator: hideDropdownIndicator ? null : CustomDropdownIndicator,
  LoadingMessage: CustomLoadingMessage,
  NoOptionsMessage: isPaginator ? () => null : CustomNoOptionsMessage,
  Option: CustomOption,
  SelectContainer: CustomSelectContainer,
  Placeholder: CustomPlaceholder,
  SingleValue: CustomSingleValue,
  GroupHeading: CustomGroupHeading,
  ValueContainer: CustomValueContainer,
  MultiValue: CustomMultiValue,
  IndicatorsContainer: CustomIndicatorsContainer,
  MenuList: CustomMenuList,
  Menu: CustomMenu,
  ...(truncateMultiValues ? { Input: CustomInput } : {}),
})

export const getCustomClassNames = ({
  error,
  hasSearchOnClick,
  hideDropdownIndicator,
  insideModal,
  isClearable,
  isPaginator,
  isSearchable,
  truncateInputValueInNewOption,
  truncateOptions,
  showGroupsWithoutLabel,
  hasFooter,
}: GetCustomClassNamesParams) => ({
  control: ({ isLoading, menuIsOpen, isFocused, options }: any) =>
    classNames(`${selectRootClass}__control`, {
      [`${selectRootClass}__control-loader`]: isLoading && !options.length,
      [`${selectRootClass}__control-menu-open`]: menuIsOpen,
      [`${selectRootClass}__control-menu-open-error`]: error,
      [`${selectRootClass}__control-paginator`]: isPaginator,
      [`${selectRootClass}__control-focus`]: isFocused,
      [`${selectRootClass}__control-searchable`]: isSearchable && hasSearchOnClick,
    }),
  indicatorSeparator: () => `${selectRootClass}__indicator-separator`,
  input: () => `${selectRootClass}__input`,
  inputContainer: () => classNames({ [`${selectRootClass}__input-container-error`]: error }),
  loadingIndicator: () => `${selectRootClass}__loading-indicator`,
  menu: () =>
    classNames(`${selectRootClass}__menu`, {
      [`${selectRootClass}__menu-paginator`]: isPaginator,
      [`${selectRootClass}__menu-with-footer`]: hasFooter,
    }),
  menuList: ({ isLoading, options }: any) =>
    classNames(`${selectRootClass}__menu-list`, {
      [`${selectRootClass}__menu-list-loader`]: isLoading && !options.length,
      [`${selectRootClass}__menu-list-paginator`]: isPaginator,
      [`${selectRootClass}__menu-list--groups-without-label`]: showGroupsWithoutLabel,
      [`${selectRootClass}__menu-list-with-footer`]: hasFooter,
    }),
  menuPortal: () =>
    classNames({
      [`${selectRootClass}__menu-portal`]: !insideModal,
      [`${selectRootClass}__menu-portal-inside-modal`]: insideModal,
    }),
  option: ({ data, isSelected }: any) =>
    classNames(`${selectRootClass}__option`, {
      [`${selectRootClass}__option-disabled`]: data.isDisabled,
      [`${selectRootClass}__option-selected`]: isSelected,
      [`${selectRootClass}__option-truncated`]: truncateOptions,
      [`${selectRootClass}__option-new-truncated`]: !!data.__isNew__ && truncateInputValueInNewOption,
    }),
  valueContainer: () =>
    classNames(`${selectRootClass}__value-container`, {
      [`${selectRootClass}__value-container-no-clearable`]: !isClearable && !hideDropdownIndicator,
    }),
})

export const isGroupedOptionArrayType = (
  options: SelectV2Option[] | MultiValue<SelectV2Option> | SingleValue<SelectV2Option> | OptionsOrGroups<SelectV2Option, GroupBase<SelectV2Option>>
): options is SelectV2GroupedOption[] => {
  const option = Array.isArray(options) ? options[0] : undefined
  return option ? 'options' in option : false
}

export const isGroupedOptionType = (options: SingleValue<SelectV2Option> | MultiValue<SelectV2Option>): options is SelectV2GroupedOption => {
  return options ? 'options' in options : false
}

export const isMultiValueType = (options: SingleValue<SelectV2Option> | MultiValue<SelectV2Option>): options is MultiValue<SelectV2Option> => {
  return Array.isArray(options)
}

export const isSingleValueType = (options: SingleValue<SelectV2Option> | MultiValue<SelectV2Option>): options is SelectV2SingleOption => {
  return options === null || (!isMultiValueType(options) && !isGroupedOptionType(options))
}

export const isSingleValueArrayType = (
  options: SingleValue<SelectV2Option>[] | MultiValue<SelectV2Option> | OptionsOrGroups<SelectV2Option, GroupBase<SelectV2Option>>
): options is SelectV2SingleOption[] => {
  return Array.isArray(options) && isSingleValueType(options[0])
}

export const findGroupContainingOption = (currentOptions: SelectV2Option[], optionValue?: string) => {
  if (!optionValue) {
    return
  }
  if (isGroupedOptionArrayType(currentOptions)) {
    return currentOptions.find((group) => {
      return group.options.find((option) => option.value === optionValue)
    })
  }
}

export const validateDefaultValue = (props: SelectV2Props) => {
  const { defaultValue, groupedOptions, options } = props
  if (!defaultValue) {
    return
  }

  if (isStringArray(defaultValue)) {
    if (groupedOptions) {
      return groupedOptions?.flatMap((group) => group.options).filter((option) => defaultValue.includes(option.value))
    } else if (options) {
      return options?.filter((option) => defaultValue.includes(option.value))
    }
  } else if (typeof defaultValue === 'string') {
    if (groupedOptions) {
      return groupedOptions?.flatMap((group) => group.options).find((option) => option.value === defaultValue)
    } else if (options) {
      return options?.find((option) => option.value === defaultValue)
    }
  } else {
    return defaultValue
  }
}

export const findGroupWithLabel = (currentOptions: SelectV2Option[], label: string) => {
  return isGroupedOptionArrayType(currentOptions) ? currentOptions.find((option) => option.label === label) : undefined
}

export const findGroupIndexWithLabel = (currentOptions: SelectV2Option[], label: string) => {
  return isGroupedOptionArrayType(currentOptions) ? currentOptions.findIndex((option) => option.label === label) : undefined
}

export const truncateValues = (containerRef: RefObject<HTMLDivElement>, update: Update) => {
  if (containerRef.current) {
    let usedWidth = 0
    let chipsLeft = 0
    const container = containerRef.current.getElementsByClassName(`${selectRootClass}__value-container`)[0]
    const counter = container.getElementsByClassName('custom-input__counter')[0]
    const counterWidth = counter ? counter.getBoundingClientRect().width : 0
    const chips = container.getElementsByClassName('chip')
    // Remove hidden class from all chips to get the current WIDTH
    for (let i = 0; i < chips.length; i++) {
      chips[i].classList.remove(HIDDEN_CLASS, ONLY_CHIP_CLASS)
    }

    const innerWidth = container.getBoundingClientRect().width - counterWidth - INPUT_EXTRA_WITDH
    if (chips.length > 1 && container.clientWidth < container.scrollWidth) {
      for (let i = 0; i < chips.length; i++) {
        const chip = chips[i]
        const chipStyle = getComputedStyle(chip)
        const chipWidth = parseFloat(chipStyle.marginRight) + parseFloat(chipStyle.marginLeft) + parseFloat(chipStyle.width)

        usedWidth += chipWidth
        if (usedWidth >= innerWidth) {
          chip.classList.add('hidden')
          usedWidth -= chipWidth
          chipsLeft++
        }
      }
    } else if (chips.length === 1) {
      chips[0].classList.add(ONLY_CHIP_CLASS)
    }

    update({ hiddenChipsCount: chipsLeft })
  }
}
