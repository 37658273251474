import React, { FC, useMemo, useRef, useState } from 'react'

import classNames from 'classnames'

import { ButtonType } from '@components/Button'
import Container from '@components/Container'
import MoreOptions from '@components/MoreOptions/MoreOptions'
import Radio from '@components/Radio'
import RadioGroup from '@components/RadioGroup'
import SelectV2 from '@components/SelectV2/SelectV2'
import { useTranslation } from '@const/globals'
import { ProgramStep } from '@graphql/types/query-types'
import ListProgramSourceSelector from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/components/ProgramDetails/components/ListProgramSources/components/ListProgramSourceSelector/ListProgramSourceSelector'

import './StepSourceSelector.css'

interface StepSourceSelectorProps {
  className?: string
  dataTest?: string
  initiallyShowListPicker?: boolean
  hasSelect?: boolean
  noSourceRadioText: string
  notInSegment?: boolean
  onChange: (step: Partial<ProgramStep & { not: boolean }>, firstOption: boolean) => void
  selectSourceRadioText: string
  sourceId: { [key: string]: string }
  sourceName: { [key: string]: string }
  recordsCount: { [key: string]: number }
  isListsOnly?: boolean
  showErrorOnUnselectedSource?: boolean
}

const rootClass = 'step-source-selector'

const StepSourceSelector: FC<StepSourceSelectorProps> = (props: StepSourceSelectorProps) => {
  const {
    className = '',
    dataTest = rootClass,
    initiallyShowListPicker = false,
    hasSelect = false,
    noSourceRadioText,
    notInSegment = false,
    onChange,
    selectSourceRadioText,
    showErrorOnUnselectedSource = true,
    sourceId: sourceIdProp,
    sourceName: sourceNameProp,
    recordsCount: recordsCountProp,
    isListsOnly = false,
  } = props

  const [sourceIdField, sourceId] = Object.entries(sourceIdProp)[0]
  const [sourceNameField, sourceName] = Object.entries(sourceNameProp)[0]
  const [recordsCountField, recordsCount] = Object.entries(recordsCountProp)[0]

  const [showSourceSelector, setShowSourceSelector] = useState(initiallyShowListPicker)

  const selectedSource = useRef<Partial<ProgramStep> | null>(
    initiallyShowListPicker ? { [sourceIdField]: sourceId, [sourceNameField]: sourceName, [recordsCountField]: recordsCount } : null
  )

  const { t } = useTranslation()

  const options = useMemo(
    () => [
      { label: t('is in segment'), value: 'false' },
      { label: t('is not in segment'), value: 'true' },
    ],
    []
  )

  const selectedOption = options.find(({ value }) => value === `${notInSegment}`)

  const onRadioChange = (firstOption = true) => {
    setShowSourceSelector(!firstOption)
    onChange(
      !firstOption && selectedSource.current
        ? selectedSource.current
        : { [sourceIdField]: undefined, [sourceNameField]: undefined, [recordsCountField]: undefined },
      firstOption
    )
  }

  return (
    <Container dataTest={dataTest} className={classNames(rootClass, className)}>
      <RadioGroup className={`${rootClass}__radio-container`} verticalLayout>
        <Radio
          dataTest={`${dataTest}-hide-source-selector`}
          checked={!showSourceSelector}
          onChange={() => onRadioChange()}
          label={noSourceRadioText}
        />
        <div className={`${rootClass}__show-source-selector-container`}>
          <Radio
            dataTest={`${dataTest}-show-source-selector`}
            className={`${rootClass}__show-source-selector-radio`}
            checked={showSourceSelector}
            onChange={() => onRadioChange(false)}
            label={selectSourceRadioText}
          />
          {hasSelect && (
            <SelectV2
              dataTest={`${dataTest}-is-in-segment-select`}
              options={options}
              defaultValue={selectedOption}
              hideCheckMark
              truncateOptions
              isClearable={false}
              isDisabled={!showSourceSelector}
              containerTooltipMessage={selectedOption?.label}
              onChange={(option) => onChange({ not: option?.value === 'true' }, false)}
            />
          )}
        </div>
      </RadioGroup>
      {showSourceSelector && (
        <MoreOptions className={`${rootClass}__source-selector-container`}>
          <ListProgramSourceSelector
            dataTest={`${dataTest}-source-selector`}
            onSubmit={(list) => {
              const newStepSource = { [sourceIdField]: list?.id, [sourceNameField]: list?.name, [recordsCountField]: list?.size }
              onChange(newStepSource, false)
              selectedSource.current = newStepSource
            }}
            buttonType={ButtonType.SECONDARY}
            defaultSource={sourceId ? { id: sourceId, name: sourceName, baseId: '', count: recordsCount } : undefined}
            isEditing
            isListsOnly={isListsOnly}
            showErrorOnUnselectedSource={showErrorOnUnselectedSource}
          />
        </MoreOptions>
      )}
    </Container>
  )
}

export default StepSourceSelector
